import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { map, mergeMap, Observable, startWith } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Widget } from '../../core/domain/widget';
import { UserService } from '../../core/services/user.service';
import { convertStateToAbbr, STATES } from '../domain/scheduler-lookup';
import { WidgetFieldConfig } from '../../core/domain/widget-field-config';
import { lookup_ca_codes } from '../domain/ca-codes';
import { lookup_us_codes } from '../domain/us-codes';
import { ActivatedRoute } from '@angular/router';

declare var google;

@Component({
  selector: 'app-page-one',
  templateUrl: './page-one.component.html',
  styleUrls: ['./page-one.component.scss'],
})
export class PageOneComponent implements OnInit {
  @Input()
  formGroup: FormGroup;
  @Input()
  secondFormGroup: FormGroup;
  @Input()
  auxiliaryFormGroup: FormGroup;
  public zillowUrl: string | undefined;
  inspectionServices: string[];
  chosenInspectionService: string;
  filteredStates: Observable<string[]>;
  isLocalWidget = false;
  widget: Widget;
  widgetLoaded: boolean;
  widgetUserAddress: any;
  widgetIndexData: any = {};
  serviceAreaWarning = false;
  addressFound = false;
  addressVerified = false;
  addressValid = false;
  addressHint: string | undefined;
  addressConflict = false;
  addressLookupBusy = false;
  addressLookupPause = true;

  addressTimeout: NodeJS.Timeout | undefined;

  constructor(
    private userService: UserService,
    private cd: ChangeDetectorRef,
    private zone: NgZone,
    private afFunctions: AngularFireFunctions,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.formGroup.controls['region'].setValidators([this.checkRegion]);
    this.formGroup.controls['region'].updateValueAndValidity();

    this.filteredStates = this.formGroup.controls['region'].valueChanges.pipe(
      startWith(''),
      map((state) => (state ? this._filterStates(state) : STATES.slice())),
    );

    //Is this a global widget or a specific one?
    this.userService.getWidgetLocalitySubject().subscribe((isLocal) => {
      this.isLocalWidget = isLocal;
    });

    this.userService.getWidgetSubject().subscribe((widget) => {
      this.widget = widget;

      if (
        this.isLocalWidget &&
        this.inspectionServices &&
        this.inspectionServices.length > 1
      ) {
        this.reduceInspectionTypeArray();
      }

      if (widget) {
        this.widgetLoaded = true;
      } else {
        this.widgetLoaded = false;
      }
    });

    this.userService.getWidgetIndexSubject().pipe(
      mergeMap(widgetIndex =>
        this.route.params.pipe(
          map(params => {
            return { params, widgetIndex };
          })
        )
      )
    )
    .subscribe(({ params, widgetIndex }) => {
      if (widgetIndex === null || widgetIndex === undefined) {
        return;
      }
      this.inspectionServices = widgetIndex.map(
        (index) => index.inspectionType
      );

      let i = this.inspectionServices.indexOf('Residential');

      if (
        params.localWidgetKey !== undefined &&
        params.localWidgetKey !== null
      ) {
        i = widgetIndex.findIndex(widget => widget.widgetId === params.localWidgetKey);
      }

      if (i < 0) i = 0;

      this.setInspectionType(this.inspectionServices[i]);
    });

    this.userService
      .userWidgetDoc()
      .valueChanges()
      .subscribe((widgetData: any) => {
        if (widgetData) {
          this.widgetUserAddress = widgetData.serviceAreaStreetAddress;
          this.widgetIndexData = widgetData;
        }
      });
  }

  get isValid(): boolean {
    return (
      this.formGroup.valid &&
      this.formGroup.touched &&
      !this.serviceAreaWarning &&
      this.addressValid
    );
  }

  reduceInspectionTypeArray() {
    const insType = this.userService.getInspectionType();

    if (insType && this.inspectionServices) {
      const i = this.inspectionServices.indexOf(insType);

      if (i > -1) {
        this.inspectionServices = [this.inspectionServices[i]];
        this.setInspectionType(this.inspectionServices[0]);
      }
    }
  }

  checkZip(event: Event) {
    (event.target as HTMLInputElement).value = (
      event.target as HTMLInputElement
    ).value.toUpperCase();

    const value = (event.target as HTMLInputElement).value;

    let res;

    // If values starts with a number, it is a US postal code.
    if (/^\d$/.test(value[0])) {
      res = lookup_us_codes(value);
    }
    // If value starts with a letter, it is a CA postal code.
    if (/[a-zA-Z]/.test(value[0])) {
      const lookup_code = value.substring(0, 3);
      res = lookup_ca_codes(lookup_code);
    }

    if (res) {
      this.formGroup.controls['city'].setValue(res.city);
      this.formGroup.controls['region'].setValue(res.state);
    } else {
      this.formGroup.controls['city'].setValue(null);
      this.formGroup.controls['region'].setValue(null);
    }

    if (value && res) {
      this.checkServiceAreaRadius();
    }
  }

  checkRegion(fc: any) {
    if (fc.value) {
      if (fc.value.length === 1) {
        return { invalidState: true };
      } else if (fc.value.length > 2) {
        const stateAbbr = convertStateToAbbr(fc.value);

        if (stateAbbr) {
          const validState = STATES.filter((state) => state === stateAbbr);

          if (validState.length > 0) {
            fc.setValue(stateAbbr);
            return null;
          }
        }
      } else {
        const validState = STATES.filter((state) => state === fc.value);

        if (validState.length > 0) {
          return null;
        }
      }

      return { invalidState: true };
    }

    return null;
  }

  private _filterStates(value: string): string[] {
    const filterValue = value.toLowerCase();
    return STATES.filter(
      (state) => state.toLowerCase().indexOf(filterValue) === 0,
    );
  }

  changeInspectionService(changeInspectionServiceEvent: any): void {
    this.chosenInspectionService = changeInspectionServiceEvent.value;
    this.setInspectionType(this.chosenInspectionService);
  }

  private setInspectionType(chosenInspectionService: string): void {
    this.chosenInspectionService = chosenInspectionService;
    this.auxiliaryFormGroup.controls['inspectionType'].setValue(
      chosenInspectionService,
    );
    this.userService.setInspectionType(chosenInspectionService);

    this.userService.loadWidget();
  }

  fieldIsRequired(fieldName: string): boolean {
    return this.formGroup.controls[fieldName].hasError('required');
  }

  checkServiceAreaRadius(nopause?: boolean) {
    this.addressFound = false;
    this.serviceAreaWarning = false;
    this.addressVerified = false;

    this.zillowUrl = undefined;

    if (!nopause) {
      if (this.addressTimeout) clearTimeout(this.addressTimeout);
      this.addressTimeout = setTimeout(
        () => this.checkServiceAreaRadius(true),
        500,
      );
      return;
    } else {
      this.addressTimeout = undefined;
    }

    if (this.userService.userTier !== 'Pro') {
      this.addressValid = true;
      return;
    }

    const streetAddress: string =
      this.formGroup.controls['streetAddress'].value;
    const city: string = this.formGroup.controls['city'].value;
    const region: string = this.formGroup.controls['region'].value;

    if (!streetAddress || !city || !region) return;

    const params = {
      address: streetAddress,
      citystatezip: city + ', ' + region,
    };

    const apiZillowDeepSearch = this.afFunctions.httpsCallable(
      'apiZillowDeepSearch',
    );

    apiZillowDeepSearch(params).subscribe({
      next: (result) => {
        if (!result || !result.data || !result.data.data) return;

        if (result.data.data.finishedSqFt) {
          const data = result.data.data;

          const timeFactor = this.widget.propertySizeAreaTime || 0;
          const priceFactor = this.widget.propertySizeAreaPrice || 0;
          const area: number = data.finishedSqFt || 0;

          const basePrice = parseFloat((area * priceFactor).toFixed(2));
          const baseTime = parseFloat(((area * timeFactor) / 60).toFixed(2));

          this.secondFormGroup.controls.propertySizeArea.setValue(area);
          this.secondFormGroup.controls['propertySizeName'].setValue(
            area.toString() + ' sq. feet',
          );
          this.secondFormGroup.controls['propertySizeBasePrice'].setValue(
            basePrice,
          );
          this.secondFormGroup.controls['propertySizeBaseTime'].setValue(
            baseTime,
          );

          this.setTotalPrice();

          this.zillowUrl = data.link;
        }
      },
      error: (e) => {},
    });

    if (!this.widgetUserAddress) {
      this.addressValid = true;
      return;
    }

    if (this.widgetIndexData.serviceAreaRadius.length !== 0) {
      this.addressLookupBusy = true;

      let addressInput =
        this.formGroup.controls['streetAddress'].value +
        ',' +
        this.formGroup.controls['city'].value +
        ',' +
        this.formGroup.controls['region'].value;

      if (this.formGroup.controls['postalCode'].value) {
        addressInput += ',' + this.formGroup.controls['postalCode'].value;
      }

      this.getTravelDistance(this.widgetUserAddress, addressInput)
        .then(() => {
          //this.addressLookupBusy = false;
        })
        .catch((e) => {
          this.addressLookupBusy = false;
          console.error(e);
        });
    } else {
      this.addressValid = true;
    }
  }

  async getTravelDistance(origin: any, destination: any) {
    const inputData = {
      origins: [origin],
      destinations: [destination],
      travelMode: 'DRIVING',
    };

    try {
      await new google.maps.DistanceMatrixService().getDistanceMatrix(
        inputData,
        (results: any) => {
          this.addressLookupBusy = false;

          if (results.rows[0].elements[0].status === 'OK') {
            this.addressFound = true;

            this.verifyAddressResult(results);

            const meter = results.rows[0].elements[0].distance.value;
            let mile = meter / 1609.344;
            mile = Math.round(mile);
            this.getTravelSurcharge(mile);
          } else {
            this.addressVerified = false;
            this.addressFound = false;
            this.addressValid = true;
          }
        },
      );

      return;
    } catch (e) {
      this.addressVerified = false;
      this.addressFound = false;
      this.addressValid = true;
      this.addressLookupBusy = false;

      console.error(e);
      //throw e;
    }
  }

  updateAddress() {
    this.formGroup.controls.streetAddress.setValue(this.addressHint);
    this.ignoreAddress();
  }

  ignoreAddress() {
    this.addressConflict = false;
    this.addressHint = undefined;
  }

  verifyAddressResult(results) {
    let streetAddress: string = this.formGroup.controls.streetAddress.value;
    let postalCode: string = this.formGroup.controls.postalCode.value;
    let city: string = this.formGroup.controls.city.value;

    streetAddress = streetAddress.toLowerCase();
    city = city.toLowerCase();
    if (postalCode) postalCode = postalCode.toLowerCase();

    let dest: string = results.destinationAddresses[0];

    let destSplit: string[] = dest.split(',');

    const addressHint = destSplit[0];

    destSplit = [...destSplit.map((item) => item.toLowerCase())];
    dest = dest.toLowerCase();

    const streetSplit: string[] = destSplit[0].split(' ');

    this.addressVerified = false;

    if (!isNaN(+streetSplit[0])) {
      if (dest.includes(city)) {
        if (postalCode) {
          if (dest.includes(postalCode)) {
          } else return;
        }
      } else return;
    } else return;

    this.addressVerified = true;

    if (!destSplit[0].includes(streetAddress)) {
      this.addressConflict = true;
      this.addressHint = addressHint;
    }
  }

  getTravelSurcharge(mile: number) {
    let tempData: { cost: number; miles: number } = {
      cost: 0,
      miles: 0,
    };

    const serviceAreaRadiusAsc = this.widgetIndexData.serviceAreaRadius.sort(
      (a, b) => parseFloat(a.miles) - b.miles,
    );
    const mostExpensiveServiceArea =
      serviceAreaRadiusAsc[serviceAreaRadiusAsc.length - 1].miles || 0;

    for (const element of serviceAreaRadiusAsc) {
      if (mile >= element.miles) {
        tempData = {
          cost: parseFloat(element.cost),
          miles: parseFloat(element.miles),
        };
      }
    }

    this.zone.run(() => {
      if (
        this.widgetIndexData.serviceAreaBlockRequest &&
        mile >= mostExpensiveServiceArea
      ) {
        this.cd.markForCheck();

        this.serviceAreaWarning = true;
        this.addressValid = false;

        this.formGroup.markAsPristine();
        this.cd.detectChanges();
      } else {
        this.auxiliaryFormGroup.controls['travelDistance'].setValue(mile);
        this.auxiliaryFormGroup.controls['travelSurcharge'].setValue(
          tempData.cost,
        );

        this.setTotalPrice();

        this.cd.markForCheck();

        this.serviceAreaWarning = false;
        this.addressValid = true;

        this.formGroup.markAsPristine();
        this.cd.detectChanges();
      }
    });
  }

  onAddServicesChange(selectedOptions: any): void {
    if (selectedOptions.length === 0) {
      this.formGroup.controls['addServices'].setValue([]);
      this.formGroup.controls['addServicesPrice'].setValue(0);
      this.formGroup.controls['addServicesTime'].setValue(0);
    } else {
      const selectedValues: string[] = selectedOptions.map(
        (option) => option.value,
      );

      const fields: WidgetFieldConfig[] = this.widget.addServices.filter(
        (service) =>
          selectedValues.filter((val) => val === service.name).length > 0,
      );

      this.formGroup.controls['addServices'].setValue(fields);

      const prices: any[] = fields.map((service) => service.addPrice);
      const time: any[] = fields.map((service) => service.addTime);

      let totalPrice = 0;
      let totalTime = 0;

      for (let i = 0; i < prices.length; i++) {
        totalPrice += parseFloat(prices[i]);
      }

      for (let i = 0; i < time.length; i++) {
        totalTime += parseFloat(time[i] || 0);
      }

      this.formGroup.controls['addServicesPrice'].setValue(totalPrice);
      this.formGroup.controls['addServicesTime'].setValue(totalTime);
    }

    this.setTotalPrice();
  }

  private setTotalPrice() {
    let totalPrice = 0;
    let totalTime = 0;

    totalPrice += parseFloat(this.formGroup.controls['addServicesPrice'].value);
    totalPrice += parseFloat(
      this.auxiliaryFormGroup.controls['travelSurcharge'].value,
    );

    totalPrice += parseFloat(
      this.secondFormGroup.controls['propertyTypeBasePrice'].value,
    );
    totalPrice += parseFloat(
      this.secondFormGroup.controls['propertySizeBasePrice'].value,
    );
    totalPrice += parseFloat(
      this.secondFormGroup.controls['addInfoPrice'].value,
    );

    this.auxiliaryFormGroup.controls['totalPrice'].setValue(totalPrice);

    totalTime += parseFloat(
      this.formGroup.controls['addServicesTime'].value || 0,
    );

    totalTime += parseFloat(
      this.secondFormGroup.controls['propertyTypeBaseTime'].value || 0,
    );
    totalTime += parseFloat(
      this.secondFormGroup.controls['propertySizeBaseTime'].value || 0,
    );
    totalTime += parseFloat(
      this.secondFormGroup.controls['addInfoTime'].value || 0,
    );

    this.auxiliaryFormGroup.controls['totalTime'].setValue(totalTime);
  }
}
