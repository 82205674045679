import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoadingService } from '../../core/services/loading.service';
import { UserService } from '../../core/services/user.service';
import { RequestService } from '../../core/services/request.service';
import { UserWidget } from '../../core/domain/user-widget';
import { map, firstValueFrom } from 'rxjs';
import { SummaryPageComponent } from '../summary-page/summary-page.component';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit {
  @ViewChild(MatStepper, { static: false }) stepper!: MatStepper;

  // form variables
  isLinear = true;
  editable = true;

  errorAlerts: any[] = [];

  // form groups
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  auxiliaryFormGroup: FormGroup;

  // firestore collections
  inspectionCollection: AngularFirestoreCollection<any>;

  private userId: string;
  private widgetId: string;
  private originalAppointmentDateTime: Date;

  warning: boolean;
  pageTwoLoaded: boolean = false;

  // modals
  bsModalRef: BsModalRef;

  widgetLoaded: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private angularFireStore: AngularFirestore,
    private loadingService: LoadingService,
    private userService: UserService,
    private requestService: RequestService,
    private router: Router,
    private modalService: BsModalService,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.userId = params.globalWidgetKey;

      this.inspectionCollection =
        this.angularFireStore.collection<any>(`inspection`);

      if (!this.userId) {
        this.loadingService.stopLoading();
        this.router.navigateByUrl('error');

        return;
      }

      this.userService.setUserId(this.userId);

      this.widgetId = params.localWidgetKey;

      let path: string = `widget/${this.userId}`;

      const widgetObservable = this.angularFireStore
        .doc<UserWidget>(path)
        .snapshotChanges()
        .pipe(
          map((widgetDoc) => {
            const data = widgetDoc.payload.data() as UserWidget;
            const id = widgetDoc.payload.id;
            return { id, ...data };
          }),
        );

      widgetObservable.subscribe((widget) => {
        if (widget) {
          this.widgetLoaded = true;

          this.userService.useTeamAppointmentIndex =
            widget.useTeamAppointmentIndex;

          if (this.widgetId) {
            this.userService.loadWidgetById(this.widgetId);
            this.userService.setWidgetLocality(true);
            this.userService.setWidgetIndex(widget.widgetIndex);
          } else {
            this.userService.setWidgetIndex(widget.widgetIndex);
          }
        } else this.widgetLoaded = false;

        this.loadingService.stopLoading();
      });
    });

    this.initializeFormGroups();
    this.setDefaultFormValues();
  }

  /**
   * Initialize the 3 different form groups
   */

  private initializeFormGroups(): void {
    this.firstFormGroup = this.formBuilder.group({
      addServices: [[]],
      addServicesPrice: [0],
      addServicesTime: [0],

      streetAddress: ['', Validators.required],
      extendedAddress: [''],
      city: ['', Validators.required],
      region: ['', Validators.required],
      postalCode: ['', Validators.required],
      country: ['', Validators.required],
    });

    this.secondFormGroup = this.formBuilder.group({
      appointmentDateTime: ['', Validators.required],
      appointmentTime: ['', Validators.required],

      propertyTypeName: ['', Validators.required],
      propertyTypeBasePrice: [0],
      propertyTypeBaseTime: [0],

      propertySizeName: ['', Validators.required],
      propertySizeBasePrice: [0],
      propertySizeBaseTime: [0],
      propertySizeArea: [0],

      addInfo: [[]],
      addInfoPrice: [0],
      addInfoTime: [0],
    });

    this.thirdFormGroup = this.formBuilder.group({
      contactPhone: ['', Validators.required],
      contactEmail: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],
      contactName: ['', Validators.required],

      clientNotes: [''],

      buyerAgentPhone: [''],
      buyerAgentEmail: ['', Validators.email],
      buyerAgentName: [''],

      listingAgentPhone: [''],
      listingAgentEmail: ['', Validators.email],
      listingAgentName: [''],

      clientSmsConsent: [true, Validators.required],
    });

    this.auxiliaryFormGroup = this.formBuilder.group({
      //From Init
      contactType: [''],
      timezone: [''],
      timezoneOffset: [''],
      userId: [''],
      cleanData: [],

      //From page 1
      inspectionType: [''],
      travelDistance: [''],
      travelSurcharge: [''],

      //From page 2
      appointmentDateTime: [''],
      totalPrice: [''],
      totalTime: [''],
      //teamMemberUid: [''],

      //From page 3
      requestDateTime: [''],
    });
  }

  private setDefaultFormValues(): void {
    const tzDate = new Date();

    // default page 1
    this.firstFormGroup.controls['addServicesPrice'].setValue(0);
    this.firstFormGroup.controls['addServicesTime'].setValue(0);

    this.firstFormGroup.controls['country'].setValue('United States');

    // default page 2
    this.secondFormGroup.controls['propertyTypeBasePrice'].setValue(0);
    this.secondFormGroup.controls['propertyTypeBaseTime'].setValue(0);

    this.secondFormGroup.controls['propertySizeBasePrice'].setValue(0);
    this.secondFormGroup.controls['propertySizeBaseTime'].setValue(0);

    this.secondFormGroup.controls['addInfoPrice'].setValue(0);
    this.secondFormGroup.controls['addInfoTime'].setValue(0);

    // default page 3
    // None

    //default aux
    // This just captures the timezone of the user's browser
    this.auxiliaryFormGroup.controls['timezoneOffset'].setValue(
      tzDate.getTimezoneOffset(),
    );

    this.auxiliaryFormGroup.controls['travelDistance'].setValue(0);
    this.auxiliaryFormGroup.controls['travelSurcharge'].setValue(0);

    this.auxiliaryFormGroup.controls['totalPrice'].setValue(0);
    this.auxiliaryFormGroup.controls['totalTime'].setValue(0);

    this.auxiliaryFormGroup.controls['userId'].setValue(this.userId);
    this.auxiliaryFormGroup.controls['contactType'].setValue('Owner');
  }

  submit(event: Event) {
    event.preventDefault();
    this.loadingService.startLoading();
    this.setAdditionalDetails();

    let result: any = {};

    result = Object.assign(result, this.firstFormGroup.value);
    result = Object.assign(result, this.secondFormGroup.value);
    result = Object.assign(result, this.thirdFormGroup.value);
    result = Object.assign(result, this.auxiliaryFormGroup.value);

    this.saveSubmit(result);
  }

  private setAdditionalDetails() {
    this.setRequestDateTime();
    this.setInspectionType();
    this.adjustTimeByOffset();

    const sender = this.thirdFormGroup.controls['contactName'].value;
    this.auxiliaryFormGroup.controls['cleanData'].setValue({ sender });
  }

  private setInspectionType() {
    this.auxiliaryFormGroup.controls['inspectionType'].setValue(
      this.userService.getInspectionType(),
    );
  }

  private setRequestDateTime(): void {
    const today = new Date();
    this.auxiliaryFormGroup.controls['requestDateTime'].setValue(today);
  }

  /**
   * We need to compensate for differences in the widget user timezone
   * and the user (inspector) timezone. Additionally we need to check
   * if we cross DST between now and the appointment date.
   *
   * NOTE: one limitation of the approach below is that we have to assume
   * that both the inspector and the widget user will be affected by the DST
   * change if one is detected. This may not always be the case, but should be a rare occurence.
   */
  private adjustTimeByOffset() {
    const userTimezoneOffset = this.userService.timezoneOffset; // NY 240
    const appDateTime: Date =
      this.auxiliaryFormGroup.controls['appointmentDateTime'].value;
    const widgetTimezoneOffset = appDateTime.getTimezoneOffset(); // CA 420; LU -120
    const dstOffset = widgetTimezoneOffset - new Date().getTimezoneOffset(); // CA 0; LU 0

    if (appDateTime instanceof Date) {
      this.originalAppointmentDateTime = new Date(appDateTime);
      const diff = userTimezoneOffset - widgetTimezoneOffset + dstOffset; // CA -180; LU 360
      if (diff && diff !== 0) {
        appDateTime.setMinutes(appDateTime.getMinutes() + diff);
        this.auxiliaryFormGroup.controls['appointmentDateTime'].setValue(
          appDateTime,
        );
      }
    }
  }

  private saveSubmit(request: any) {
    this.requestService.setRequest(request);
    this.editable = false;
    this.requestService.triggerEvent(true);

    firstValueFrom(
      this.httpClient.post<HttpResponse<any>>(
        environment.functions.widgetRequestInspectionUrl,
        JSON.stringify(request),
        { observe: 'response' },
      ),
    )
      .then(() => {
        this.loadingService.stopLoading();
        request.appointmentDateTime = this.originalAppointmentDateTime;
        this.openModal(request);
      })
      .catch((err) => {
        this.loadingService.stopLoading();
        this.errorAlerts.push({
          type: 'danger',
          msg: `Sorry, something went wrong! If the issue persists please refresh the page.`,
          timeout: 5000,
        });
        console.error('Server returned a ' + err?.status + ' status code.');
      });
  }

  openModal(request) {
    const hidePricing = this.userService.getWidget().hidePricing;

    const initialState = {
      request: request,
      hidePricing,
    };

    const modalSettings = Object.assign(
      {},
      { backdrop: true },
      { class: 'summary-modal', initialState },
    );
    this.bsModalRef = this.modalService.show(
      SummaryPageComponent,
      modalSettings,
    );
  }

  stepChanged(event) {
    if (event.selectedIndex === 1) this.pageTwoLoaded = true;
  }
}
