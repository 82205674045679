import {Component} from '@angular/core';
import {LoadingService} from "../../core/services/loading.service";

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent {
  constructor(private loadingService: LoadingService) {
    this.loadingService.stopLoading();
  }
}
