<div class="container my-4" *ngIf="request">

  <div class="d-flex justify-content-center align-items-center mt-2 p-2">
    <mat-icon class="mr-3 success-icon text-success">assignment_turned_in</mat-icon>
    <h4>Your request was submitted successfully!</h4>
  </div>

  <mat-card class="col-12 my-4">

    <mat-card-content>
      <div class="d-flex justify-content-start align-items-center bg-light p-2">
        <mat-icon class="mr-3 header-icon">home_work</mat-icon>
        <div>{{ request.inspectionType }} Inspection</div>
      </div>
      <div class="d-flex justify-content-start align-items-center p-2">
        <mat-icon class="mr-3 header-icon">my_location</mat-icon>
        <div>{{ request.streetAddress }}, {{ request.city }} {{ request.region }}</div>
      </div>
      <div class="d-flex justify-content-start align-items-center bg-light p-2">
        <mat-icon class="mr-3 header-icon">today</mat-icon>
        <div>{{ request.appointmentDateTime | date : 'medium' }}</div>
      </div>
      <div *ngIf="request.totalTime > 0" class="d-flex justify-content-start align-items-center p-2">
        <mat-icon class="mr-3 header-icon">access_time</mat-icon>
        <div>approx. {{ getDuration() }}</div>
      </div>
      <div *ngIf="!hidePricing" class="d-flex justify-content-start align-items-center bg-light p-2">
        <mat-icon class="mr-3 header-icon">account_balance_wallet</mat-icon>
        <div>${{ request.totalPrice | number : '1.2-2' }}</div>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="d-flex justify-content-center align-items-center mb-4 p-2">
    <mat-icon class="mr-3 text-muted">email</mat-icon>
    <h5 class="m-0">Please keep an eye on your email for more information</h5>
  </div>

  <div class="d-flex justify-content-center">
    <button mat-raised-button color="primary" (click)="reset()" type="button">Done</button>
  </div>

</div>
