import {Component, Input, OnInit} from '@angular/core';
import {UserService} from "../../core/services/user.service";
import {FormGroup} from "@angular/forms";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {Widget} from "../../core/domain/widget";
import {MaterialDateTimeComponent} from "../../material-date-time/material-date-time/material-date-time.component";
import {WidgetFieldConfig} from "../../core/domain/widget-field-config";
import {MatListOption} from '@angular/material/list';

@Component({
  selector: 'app-page-two',
  templateUrl: './page-two.component.html',
  styleUrls: ['./page-two.component.scss']
})
export class PageTwoComponent implements OnInit {

  @Input() formGroup: FormGroup;
  @Input() firstFormGroup: FormGroup;
  @Input() auxiliaryFormGroup: FormGroup;
  @Input() pageTwoLoaded: boolean;
  @Input() zillowUrl: string | undefined;

  defaultAttendee: any = ['Client'];
  chosenAttendee: any = ['Client'];

  exportTime = {hour: 7, minute: 15, meriden: 'PM', format: 12};
  appointmentDate: Date;
  availableTimes: string[] = [];

  today = new Date();

  bsModalRef: BsModalRef;

  dateTouched: boolean = false;
  shakeButton: boolean = true;

  widget: Widget;

  constructor(
    private modalService: BsModalService,
    private userService: UserService) {

  }

  ngOnInit() {

    this.userService.getWidgetSubject().subscribe(widget => {
      this.widget = widget;
      this.setTotalPrice();
    });

  }

  get isValid(): boolean {

    const cntls = this.formGroup.controls;
    return cntls.propertyTypeName.value
      && ((this.widget.propertySizePricingMethod === 'tier' && cntls.propertySizeName.value)
        || (this.widget.propertySizePricingMethod === 'area' && cntls.propertySizeArea.value))
      && cntls.appointmentDateTime.value

  }

  get showPrice(): boolean {

    return this.widget && !this.widget.hidePricing;

  }

  emailIsInvalid(fieldName: string): boolean {
    return this.formGroup.controls[fieldName].hasError('email');
  }

  fieldIsRequired(fieldName: string): boolean {
    return this.formGroup.controls[fieldName].hasError('required');
  }

  formatTime(time: string): Date {
    const timeSlot: Date = new Date();
    timeSlot.setHours(parseInt(time.split(':')[0], 10), parseInt(time.split(':')[1], 10));

    return timeSlot;
  }

  openDatePicker(): void {

    this.shakeButton = false;

    const initialState = {
      formGroup: this.formGroup
    };

    const modalSettings = Object.assign({}, {backdrop: true}, {class: 'date-time-modal', initialState});
    this.modalService.onHide.subscribe(() => this.dateTouched = true);
    this.bsModalRef = this.modalService.show(MaterialDateTimeComponent, modalSettings);

    this.bsModalRef.content.appointmentDateTimeSubject.subscribe(date => {
      this.appointmentDate = date;

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.formGroup.controls['appointmentDateTime'].setValue(this.appointmentDate);
      this.auxiliaryFormGroup.controls['appointmentDateTime'].setValue(this.appointmentDate);
      this.auxiliaryFormGroup.controls['timezone'].setValue(timezone);
      this.auxiliaryFormGroup.controls['timezoneOffset'].setValue(this.appointmentDate.getTimezoneOffset());
    });

  }

  typeChange(event: any): void {
    const field: WidgetFieldConfig = this.widget.propertyType.filter(type => type.name === event.value)[0];
    this.formGroup.controls['propertyTypeName'].setValue(field.name);
    this.formGroup.controls['propertyTypeBasePrice'].setValue(field.basePrice);
    this.formGroup.controls['propertyTypeBaseTime'].setValue(field.baseTime || 0);
    this.setTotalPrice();
  }

  sizeChange(event: any): void {
    if (this.widget.propertySize) {
      const field: WidgetFieldConfig = this.widget.propertySize.filter(size => size.name === event.value)[0];
      this.formGroup.controls['propertySizeName'].setValue(field.name);
      this.formGroup.controls['propertySizeBasePrice'].setValue(field.addPrice);
      this.formGroup.controls['propertySizeBaseTime'].setValue(field.addTime || 0);
      this.setTotalPrice();
    }
  }

  sizeAreaChange(event: any): void {

    const timeFactor = this.widget.propertySizeAreaTime || 0;
    const priceFactor = this.widget.propertySizeAreaPrice || 0;
    const area: number = event.target.value || 0;

    const basePrice = parseFloat((area * priceFactor).toFixed(2));
    const baseTime = parseFloat((area * timeFactor / 60).toFixed(2));

    this.formGroup.controls['propertySizeName'].setValue(area.toString() + ' sq. feet');
    this.formGroup.controls['propertySizeBasePrice'].setValue(basePrice);
    this.formGroup.controls['propertySizeBaseTime'].setValue(baseTime);

    this.setTotalPrice();

  }

  onAdditionalFieldChange(selectedOptions: MatListOption[], targetField: string, priceField: string, timeField: string): void {
    if (selectedOptions.length === 0) {
      this.formGroup.controls[targetField].setValue([]);
      this.formGroup.controls[priceField].setValue(0);
      this.formGroup.controls[timeField].setValue(0);
    } else {
      const selectedValues: string[] = selectedOptions.filter(option => option.selected).map(option => option.getLabel().trim());
      const fields: WidgetFieldConfig[] = this.widget[targetField].filter(service =>
        selectedValues.filter(val => val === service.name).length > 0);
      this.formGroup.controls[targetField].setValue(fields);
      const prices: any[] = fields.map(service => service.addPrice);
      const time: any[] = fields.map(service => service.addTime);
      let totalPrice = 0;
      let totalTime = 0;
      for (let i = 0; i < prices.length; i++) {
        totalPrice += parseFloat(prices[i]);
      }
      for (let i = 0; i < time.length; i++) {
        totalTime += parseFloat(time[i] || 0);
      }
      this.formGroup.controls[priceField].setValue(totalPrice);
      this.formGroup.controls[timeField].setValue(totalTime);
    }
    this.setTotalPrice();
  }

  private setTotalPrice() {

    let totalPrice = 0;
    let totalTime = 0;

    totalPrice += parseFloat(this.firstFormGroup.controls['addServicesPrice'].value);
    totalPrice += parseFloat(this.auxiliaryFormGroup.controls['travelSurcharge'].value);

    totalPrice += parseFloat(this.formGroup.controls['propertyTypeBasePrice'].value);
    totalPrice += parseFloat(this.formGroup.controls['propertySizeBasePrice'].value);
    totalPrice += parseFloat(this.formGroup.controls['addInfoPrice'].value);

    this.auxiliaryFormGroup.controls['totalPrice'].setValue(totalPrice);

    totalTime += parseFloat(this.firstFormGroup.controls['addServicesTime'].value || 0);

    totalTime += parseFloat(this.formGroup.controls['propertyTypeBaseTime'].value || 0);
    totalTime += parseFloat(this.formGroup.controls['propertySizeBaseTime'].value || 0);
    totalTime += parseFloat(this.formGroup.controls['addInfoTime'].value || 0);

    this.auxiliaryFormGroup.controls['totalTime'].setValue(totalTime);

  }

}
