import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {ItbRequest} from "../../core/domain/itb-request";

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent implements OnInit {

  // request
  request: ItbRequest;
  loading: boolean = true;
  hidePricing: boolean = false;

  constructor(private bsModalRef: BsModalRef) {

  }

  ngOnInit() {
  }

  getDuration() {

    const hours = Math.floor(this.request.totalTime / 60);
    const minutes = Math.floor(this.request.totalTime / 60 % 1 * 60);

    let formattedHours: string = (hours > 0) ? hours.toString() : '';
    if (hours > 0) formattedHours += (hours > 1) ? ' hours' : ' hour';

    let formattedMinutes: string = (minutes > 0) ? minutes.toString() : '';
    if (minutes > 0) formattedMinutes += (minutes > 1) ? ' minutes' : ' minute';

    return formattedHours + ((minutes > 0) ? ' and ' + formattedMinutes : '');

  }

  reset() {

    window.location.reload();

  }

}
