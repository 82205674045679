<form [formGroup]="formGroup" *ngIf="formGroup">

  <ng-container *ngIf="inspectionServices && inspectionServices.length > 0">

    <div class="d-flex justify-content-start align-items-center mb-1">
      <mat-icon class="mr-3 header-icon">home_work</mat-icon>
      <div class="sub-heading mt-2">
        <h5>What type of Inspection do you need? </h5>
      </div>
    </div>

    <div class="field-group container">
      <mat-spinner *ngIf="!widgetLoaded" diameter="32" class="mt-4" color="secondary"></mat-spinner>
      <mat-button-toggle-group *ngIf="widgetLoaded" value="{{chosenInspectionService}}"
                               (change)="changeInspectionService($event)">
        <mat-button-toggle value="{{service}}" class="col-sm" *ngFor="let service of inspectionServices">
          {{service}}
        </mat-button-toggle>
        <div class="w-80"></div>
      </mat-button-toggle-group>
    </div>

  </ng-container>

  <ng-container *ngIf="widget?.addServices && widget.addServices.length > 0">

    <div class="d-flex justify-content-start align-items-center mt-4">
      <mat-icon class="mr-3 header-icon">post_add</mat-icon>
      <div class="sub-heading mt-2">
        <h5>{{ widget.addServicesHeading }}</h5>
      </div>
    </div>

    <div class="field-group reverse-checkbox ml-xs-0 ml-sm-2 ml-md-4" *ngIf="widget">
      <mat-selection-list #additionalServicesControl class="p-0"
                          (selectionChange)="onAddServicesChange(additionalServicesControl.selectedOptions.selected)">
        <mat-list-option togglePosition="before" [value]="service.name" *ngFor="let service of widget.addServices">
          {{service.name}}
        </mat-list-option>
      </mat-selection-list>
    </div>

  </ng-container>

  <ng-container>
    <div class="d-flex justify-content-start align-items-center mt-4 mb-2">
      <mat-icon class="mr-3 header-icon">my_location</mat-icon>
      <div class="sub-heading mt-2">
        <h5>What is the property address? </h5>
      </div>
      <mat-spinner class="ml-3" diameter="20" *ngIf="addressLookupBusy"></mat-spinner>
      <mat-icon *ngIf="addressVerified && !serviceAreaWarning" class="ml-3 text-success"
                matTooltip="Address verified. Good job!">beenhere
      </mat-icon>
      <mat-icon #addressAlert="matTooltip" *ngIf="addressFound && !addressVerified && !serviceAreaWarning"
                class="ml-3 text-warning"
                matTooltip="We couldn't verify your address. However, if it is correct please ignore this alert and continue."
                (click)="addressAlert.show()">warning
      </mat-icon>
      <mat-icon *ngIf="serviceAreaWarning" class="ml-3 text-danger">backspace</mat-icon>
    </div>

    <div class="field-group ml-xs-0 ml-sm-2 ml-md-4">
      <mat-form-field class="col-12 col-sm-6 mb-2">

        <input matInput placeholder="Street Address" name="streetAddress" formControlName="streetAddress"
               (change)='checkServiceAreaRadius()' required>

        <mat-hint class="text-primary" *ngIf="addressConflict">
          We found: {{ addressHint }}. Is this correct? <a
          (click)="updateAddress()" style="cursor: pointer;"><u>Yes</u></a>&nbsp;&nbsp;<a (click)="ignoreAddress()"
                                                                                          style="cursor: pointer;"><u>No</u></a>
        </mat-hint>

        <mat-error *ngIf="fieldIsRequired('streetAddress')">
          Street Address is <strong>required</strong>
        </mat-error>

      </mat-form-field>

      <mat-form-field class="col-12 col-sm-6 pt-2 pt-sm-0">

        <input matInput placeholder="Zip/Postal Code" name="postalCode" formControlName="postalCode" required
               (change)="checkZip($event)">

        <mat-error *ngIf="fieldIsRequired('postalCode')">
          Zip/Postal Code is <strong>required</strong>
        </mat-error>

      </mat-form-field>

    </div>

    <div class="field-group ml-xs-0 ml-sm-2 ml-md-4">

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="City" name="city" formControlName="city" required
               (change)='checkServiceAreaRadius()'>
      </mat-form-field>

      <mat-form-field class="col-12 col-sm-6">

        <input matInput placeholder="Province/State" aria-label="Province/State" [matAutocomplete]="auto" name="region"
               formControlName="region" required #input [value]="input.value.toUpperCase()"
               (change)='checkServiceAreaRadius()'>

        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
          <mat-option *ngFor="let state of filteredStates | async" [value]="state">
            <span>{{state}}</span>
          </mat-option>
        </mat-autocomplete>

        <mat-error *ngIf="fieldIsRequired('region')">
          Select a valid Province/State
        </mat-error>

        <mat-error *ngIf="formGroup.controls?.region?.hasError('invalidState')">
          Invalid Province/State
        </mat-error>

      </mat-form-field>

      <mat-error *ngIf="serviceAreaWarning" class="text-center">
        Sorry! This address is not in our service area.
      </mat-error>

    </div>
  </ng-container>

</form>
