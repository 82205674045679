<article [hidden]="!widgetLoaded || !pageOne.widgetLoaded">
    <mat-horizontal-stepper
        [linear]="true"
        #stepper
        (selectionChange)="stepChanged($event)"
    >
        <mat-step
            [stepControl]="firstFormGroup"
            [completed]="false"
            [editable]="editable"
        >
            <app-page-one
                #pageOne
                [formGroup]="firstFormGroup"
                [secondFormGroup]="secondFormGroup"
                [auxiliaryFormGroup]="auxiliaryFormGroup"
            >
            </app-page-one>

            <div class="d-flex justify-content-end mt-4">
                <button
                    mat-raised-button
                    color="primary"
                    matStepperNext
                    [disabled]="!pageOne.isValid"
                >
                    Next
                </button>
            </div>
        </mat-step>

        <mat-step
            [stepControl]="secondFormGroup"
            [completed]="false"
            [editable]="editable"
        >
            <app-page-two
                #pageTwo
                [formGroup]="secondFormGroup"
                [zillowUrl]="pageOne.zillowUrl"
                [firstFormGroup]="firstFormGroup"
                [auxiliaryFormGroup]="auxiliaryFormGroup"
                [pageTwoLoaded]="pageTwoLoaded"
            >
            </app-page-two>

            <div class="d-flex justify-content-between mt-4">
                <button mat-raised-button color="primary" matStepperPrevious>
                    Back
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    matStepperNext
                    [disabled]="!pageTwo.isValid"
                >
                    Next
                </button>
            </div>
        </mat-step>

        <mat-step
            [stepControl]="thirdFormGroup"
            [completed]="false"
            [editable]="editable"
        >
            <app-page-three
                #pageThree
                [formGroup]="thirdFormGroup"
                [auxiliaryFormGroup]="auxiliaryFormGroup"
            >
            </app-page-three>

            <div class="d-flex justify-content-between mt-4">
                <button mat-raised-button color="primary" matStepperPrevious>
                    Back
                </button>
                <button
                    mat-raised-button
                    color="primary"
                    [ngClass]="pageThree.isValid ? 'bg-success text-white' : ''"
                    (click)="submit($event)"
                    [disabled]="!pageThree.isValid"
                >
                    Submit
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</article>

<footer class="col-12 footer" [hidden]="!widgetLoaded || !pageOne.widgetLoaded">
    <h6>
        <small
            >Powered by
            <a href="https://inspectortoolbelt.com/" target="_blank"
                >Inspector Toolbelt</a
            ></small
        >
    </h6>
    <div *ngFor="let alert of errorAlerts">
        <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"
            >{{alert.msg}}</alert
        >
    </div>
    <h6 *ngIf="pageOne.zillowUrl && stepper.selectedIndex === 1">
        <small
            >See more details for {{ firstFormGroup.controls.streetAddress.value
            }} on
            <a [href]="pageOne.zillowUrl" target="_blank">Zillow</a></small
        >
    </h6>
</footer>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Inspection Message</h4>
        <button
            type="button"
            class="close pull-right"
            aria-label="Close"
            (click)="bsModalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">Successfully Saved Referral.</div>
</ng-template>
