const codes = {
  '10001': {
    zip: '10001',
    city: 'New York',
    state: 'NY',
    latitude: '40.75205',
    longitude: '-73.994517',
    timeZoneId: 'America/New_York',
  },
  '10002': {
    zip: '10002',
    city: 'New York',
    state: 'NY',
    latitude: '40.715523',
    longitude: '-73.988379',
    timeZoneId: 'America/New_York',
  },
  '10003': {
    zip: '10003',
    city: 'New York',
    state: 'NY',
    latitude: '40.732373',
    longitude: '-73.989477',
    timeZoneId: 'America/New_York',
  },
  '10004': {
    zip: '10004',
    city: 'New York',
    state: 'NY',
    latitude: '40.704169',
    longitude: '-74.012311',
    timeZoneId: 'America/New_York',
  },
  '10005': {
    zip: '10005',
    city: 'New York',
    state: 'NY',
    latitude: '40.708119',
    longitude: '-74.012238',
    timeZoneId: 'America/New_York',
  },
  '10006': {
    zip: '10006',
    city: 'New York',
    state: 'NY',
    latitude: '40.71123',
    longitude: '-74.012908',
    timeZoneId: 'America/New_York',
  },
  '10007': {
    zip: '10007',
    city: 'New York',
    state: 'NY',
    latitude: '40.712808',
    longitude: '-74.009507',
    timeZoneId: 'America/New_York',
  },
  '10008': {
    zip: '10008',
    city: 'New York',
    state: 'NY',
    latitude: '40.712215',
    longitude: '-74.006968',
    timeZoneId: 'America/New_York',
  },
  '10009': {
    zip: '10009',
    city: 'New York',
    state: 'NY',
    latitude: '40.724608',
    longitude: '-73.980332',
    timeZoneId: 'America/New_York',
  },
  '10010': {
    zip: '10010',
    city: 'New York',
    state: 'NY',
    latitude: '40.742272',
    longitude: '-73.987907',
    timeZoneId: 'America/New_York',
  },
  '10011': {
    zip: '10011',
    city: 'New York',
    state: 'NY',
    latitude: '40.74179',
    longitude: '-73.999332',
    timeZoneId: 'America/New_York',
  },
  '10012': {
    zip: '10012',
    city: 'New York',
    state: 'NY',
    latitude: '40.724324',
    longitude: '-73.996124',
    timeZoneId: 'America/New_York',
  },
  '10013': {
    zip: '10013',
    city: 'New York',
    state: 'NY',
    latitude: '40.720024',
    longitude: '-74.003648',
    timeZoneId: 'America/New_York',
  },
  '10014': {
    zip: '10014',
    city: 'New York',
    state: 'NY',
    latitude: '40.73396',
    longitude: '-74.005276',
    timeZoneId: 'America/New_York',
  },
  '10016': {
    zip: '10016',
    city: 'New York',
    state: 'NY',
    latitude: '40.745154',
    longitude: '-73.980751',
    timeZoneId: 'America/New_York',
  },
  '10017': {
    zip: '10017',
    city: 'New York',
    state: 'NY',
    latitude: '40.751186',
    longitude: '-73.972138',
    timeZoneId: 'America/New_York',
  },
  '10018': {
    zip: '10018',
    city: 'New York',
    state: 'NY',
    latitude: '40.755284',
    longitude: '-73.992008',
    timeZoneId: 'America/New_York',
  },
  '10019': {
    zip: '10019',
    city: 'New York',
    state: 'NY',
    latitude: '40.764248',
    longitude: '-73.987442',
    timeZoneId: 'America/New_York',
  },
  '10020': {
    zip: '10020',
    city: 'New York',
    state: 'NY',
    latitude: '40.758933',
    longitude: '-73.978818',
    timeZoneId: 'America/New_York',
  },
  '10021': {
    zip: '10021',
    city: 'New York',
    state: 'NY',
    latitude: '40.768479',
    longitude: '-73.960718',
    timeZoneId: 'America/New_York',
  },
  '10022': {
    zip: '10022',
    city: 'New York',
    state: 'NY',
    latitude: '40.756416',
    longitude: '-73.970295',
    timeZoneId: 'America/New_York',
  },
  '10023': {
    zip: '10023',
    city: 'New York',
    state: 'NY',
    latitude: '40.777277',
    longitude: '-73.982489',
    timeZoneId: 'America/New_York',
  },
  '10024': {
    zip: '10024',
    city: 'New York',
    state: 'NY',
    latitude: '40.786538',
    longitude: '-73.978644',
    timeZoneId: 'America/New_York',
  },
  '10025': {
    zip: '10025',
    city: 'New York',
    state: 'NY',
    latitude: '40.799621',
    longitude: '-73.970312',
    timeZoneId: 'America/New_York',
  },
  '10026': {
    zip: '10026',
    city: 'New York',
    state: 'NY',
    latitude: '40.80343',
    longitude: '-73.952625',
    timeZoneId: 'America/New_York',
  },
  '10027': {
    zip: '10027',
    city: 'New York',
    state: 'NY',
    latitude: '40.81207',
    longitude: '-73.954377',
    timeZoneId: 'America/New_York',
  },
  '10028': {
    zip: '10028',
    city: 'New York',
    state: 'NY',
    latitude: '40.778345',
    longitude: '-73.955085',
    timeZoneId: 'America/New_York',
  },
  '10029': {
    zip: '10029',
    city: 'New York',
    state: 'NY',
    latitude: '40.790284',
    longitude: '-73.946781',
    timeZoneId: 'America/New_York',
  },
  '10030': {
    zip: '10030',
    city: 'New York',
    state: 'NY',
    latitude: '40.818115',
    longitude: '-73.944054',
    timeZoneId: 'America/New_York',
  },
  '10031': {
    zip: '10031',
    city: 'New York',
    state: 'NY',
    latitude: '40.827379',
    longitude: '-73.948071',
    timeZoneId: 'America/New_York',
  },
  '10032': {
    zip: '10032',
    city: 'New York',
    state: 'NY',
    latitude: '40.83668',
    longitude: '-73.939944',
    timeZoneId: 'America/New_York',
  },
  '10033': {
    zip: '10033',
    city: 'New York',
    state: 'NY',
    latitude: '40.847553',
    longitude: '-73.936012',
    timeZoneId: 'America/New_York',
  },
  '10034': {
    zip: '10034',
    city: 'New York',
    state: 'NY',
    latitude: '40.867888',
    longitude: '-73.919847',
    timeZoneId: 'America/New_York',
  },
  '10035': {
    zip: '10035',
    city: 'New York',
    state: 'NY',
    latitude: '40.803001',
    longitude: '-73.940497',
    timeZoneId: 'America/New_York',
  },
  '10036': {
    zip: '10036',
    city: 'New York',
    state: 'NY',
    latitude: '40.759011',
    longitude: '-73.989876',
    timeZoneId: 'America/New_York',
  },
  '10037': {
    zip: '10037',
    city: 'New York',
    state: 'NY',
    latitude: '40.814876',
    longitude: '-73.938236',
    timeZoneId: 'America/New_York',
  },
  '10038': {
    zip: '10038',
    city: 'New York',
    state: 'NY',
    latitude: '40.706088',
    longitude: '-74.004447',
    timeZoneId: 'America/New_York',
  },
  '10039': {
    zip: '10039',
    city: 'New York',
    state: 'NY',
    latitude: '40.826414',
    longitude: '-73.936319',
    timeZoneId: 'America/New_York',
  },
  '10040': {
    zip: '10040',
    city: 'New York',
    state: 'NY',
    latitude: '40.85837',
    longitude: '-73.927473',
    timeZoneId: 'America/New_York',
  },
  '10041': {
    zip: '10041',
    city: 'New York',
    state: 'NY',
    latitude: '40.707642',
    longitude: '-74.013523',
    timeZoneId: 'America/New_York',
  },
  '10043': {
    zip: '10043',
    city: 'New York',
    state: 'NY',
    latitude: '40.759473',
    longitude: '-73.973389',
    timeZoneId: 'America/New_York',
  },
  '10044': {
    zip: '10044',
    city: 'New York',
    state: 'NY',
    latitude: '40.760799',
    longitude: '-73.945729',
    timeZoneId: 'America/New_York',
  },
  '10045': {
    zip: '10045',
    city: 'New York',
    state: 'NY',
    latitude: '40.690858',
    longitude: '-74.011905',
    timeZoneId: 'America/New_York',
  },
  '10055': {
    zip: '10055',
    city: 'New York',
    state: 'NY',
    latitude: '40.759658',
    longitude: '-73.96858',
    timeZoneId: 'America/New_York',
  },
  '10060': {
    zip: '10060',
    city: 'New York',
    state: 'NY',
    latitude: '40.748746',
    longitude: '-73.997962',
    timeZoneId: 'America/New_York',
  },
  '10065': {
    zip: '10065',
    city: 'New York',
    state: 'NY',
    latitude: '40.765326',
    longitude: '-73.963802',
    timeZoneId: 'America/New_York',
  },
  '10069': {
    zip: '10069',
    city: 'New York',
    state: 'NY',
    latitude: '40.770156',
    longitude: '-73.988079',
    timeZoneId: 'America/New_York',
  },
  '10075': {
    zip: '10075',
    city: 'New York',
    state: 'NY',
    latitude: '40.776185',
    longitude: '-73.963945',
    timeZoneId: 'America/New_York',
  },
  '10080': {
    zip: '10080',
    city: 'New York',
    state: 'NY',
    latitude: '40.698283',
    longitude: '-73.981208',
    timeZoneId: 'America/New_York',
  },
  '10081': {
    zip: '10081',
    city: 'New York',
    state: 'NY',
    latitude: '40.710424',
    longitude: '-74.006157',
    timeZoneId: 'America/New_York',
  },
  '10087': {
    zip: '10087',
    city: 'New York',
    state: 'NY',
    latitude: '40.755837',
    longitude: '-73.973221',
    timeZoneId: 'America/New_York',
  },
  '10090': {
    zip: '10090',
    city: 'New York',
    state: 'NY',
    latitude: '40.754817',
    longitude: '-73.994447',
    timeZoneId: 'America/New_York',
  },
  '10101': {
    zip: '10101',
    city: 'New York',
    state: 'NY',
    latitude: '40.766708',
    longitude: '-73.990523',
    timeZoneId: 'America/New_York',
  },
  '10102': {
    zip: '10102',
    city: 'New York',
    state: 'NY',
    latitude: '40.764955',
    longitude: '-73.982933',
    timeZoneId: 'America/New_York',
  },
  '10103': {
    zip: '10103',
    city: 'New York',
    state: 'NY',
    latitude: '40.756711',
    longitude: '-73.975074',
    timeZoneId: 'America/New_York',
  },
  '10104': {
    zip: '10104',
    city: 'New York',
    state: 'NY',
    latitude: '40.761151',
    longitude: '-73.977549',
    timeZoneId: 'America/New_York',
  },
  '10105': {
    zip: '10105',
    city: 'New York',
    state: 'NY',
    latitude: '40.762235',
    longitude: '-73.975175',
    timeZoneId: 'America/New_York',
  },
  '10106': {
    zip: '10106',
    city: 'New York',
    state: 'NY',
    latitude: '40.766517',
    longitude: '-73.983784',
    timeZoneId: 'America/New_York',
  },
  '10107': {
    zip: '10107',
    city: 'New York',
    state: 'NY',
    latitude: '40.768216',
    longitude: '-73.987951',
    timeZoneId: 'America/New_York',
  },
  '10108': {
    zip: '10108',
    city: 'New York',
    state: 'NY',
    latitude: '40.758763',
    longitude: '-73.992621',
    timeZoneId: 'America/New_York',
  },
  '10109': {
    zip: '10109',
    city: 'New York',
    state: 'NY',
    latitude: '40.760712',
    longitude: '-73.989366',
    timeZoneId: 'America/New_York',
  },
  '10110': {
    zip: '10110',
    city: 'New York',
    state: 'NY',
    latitude: '40.724052',
    longitude: '-73.980266',
    timeZoneId: 'America/New_York',
  },
  '10111': {
    zip: '10111',
    city: 'New York',
    state: 'NY',
    latitude: '40.758985',
    longitude: '-73.978625',
    timeZoneId: 'America/New_York',
  },
  '10112': {
    zip: '10112',
    city: 'New York',
    state: 'NY',
    latitude: '40.76066',
    longitude: '-73.981818',
    timeZoneId: 'America/New_York',
  },
  '10113': {
    zip: '10113',
    city: 'New York',
    state: 'NY',
    latitude: '40.742669',
    longitude: '-73.999421',
    timeZoneId: 'America/New_York',
  },
  '10114': {
    zip: '10114',
    city: 'New York',
    state: 'NY',
    latitude: '40.743057',
    longitude: '-74.000308',
    timeZoneId: 'America/New_York',
  },
  '10115': {
    zip: '10115',
    city: 'New York',
    state: 'NY',
    latitude: '40.815047',
    longitude: '-73.953521',
    timeZoneId: 'America/New_York',
  },
  '10116': {
    zip: '10116',
    city: 'New York',
    state: 'NY',
    latitude: '40.814261',
    longitude: '-73.952564',
    timeZoneId: 'America/New_York',
  },
  '10117': {
    zip: '10117',
    city: 'New York',
    state: 'NY',
    latitude: '40.752294',
    longitude: '-73.995082',
    timeZoneId: 'America/New_York',
  },
  '10118': {
    zip: '10118',
    city: 'New York',
    state: 'NY',
    latitude: '40.748827',
    longitude: '-73.996353',
    timeZoneId: 'America/New_York',
  },
  '10119': {
    zip: '10119',
    city: 'New York',
    state: 'NY',
    latitude: '40.751108',
    longitude: '-73.998842',
    timeZoneId: 'America/New_York',
  },
  '10120': {
    zip: '10120',
    city: 'New York',
    state: 'NY',
    latitude: '40.748958',
    longitude: '-73.997849',
    timeZoneId: 'America/New_York',
  },
  '10121': {
    zip: '10121',
    city: 'New York',
    state: 'NY',
    latitude: '40.750095',
    longitude: '-73.998867',
    timeZoneId: 'America/New_York',
  },
  '10122': {
    zip: '10122',
    city: 'New York',
    state: 'NY',
    latitude: '40.750153',
    longitude: '-73.997056',
    timeZoneId: 'America/New_York',
  },
  '10123': {
    zip: '10123',
    city: 'New York',
    state: 'NY',
    latitude: '40.750161',
    longitude: '-74.000838',
    timeZoneId: 'America/New_York',
  },
  '10124': {
    zip: '10124',
    city: 'New York',
    state: 'NY',
    latitude: '40.757053',
    longitude: '-73.970342',
    timeZoneId: 'America/New_York',
  },
  '10125': {
    zip: '10125',
    city: 'New York',
    state: 'NY',
    latitude: '40.753195',
    longitude: '-74.000885',
    timeZoneId: 'America/New_York',
  },
  '10126': {
    zip: '10126',
    city: 'New York',
    state: 'NY',
    latitude: '40.760726',
    longitude: '-73.970532',
    timeZoneId: 'America/New_York',
  },
  '10128': {
    zip: '10128',
    city: 'New York',
    state: 'NY',
    latitude: '40.779563',
    longitude: '-73.949559',
    timeZoneId: 'America/New_York',
  },
  '10129': {
    zip: '10129',
    city: 'New York',
    state: 'NY',
    latitude: '40.755624',
    longitude: '-73.990453',
    timeZoneId: 'America/New_York',
  },
  '10130': {
    zip: '10130',
    city: 'New York',
    state: 'NY',
    latitude: '40.754891',
    longitude: '-74.003971',
    timeZoneId: 'America/New_York',
  },
  '10131': {
    zip: '10131',
    city: 'New York',
    state: 'NY',
    latitude: '40.785617',
    longitude: '-73.949816',
    timeZoneId: 'America/New_York',
  },
  '10132': {
    zip: '10132',
    city: 'New York',
    state: 'NY',
    latitude: '40.785292',
    longitude: '-73.981536',
    timeZoneId: 'America/New_York',
  },
  '10133': {
    zip: '10133',
    city: 'New York',
    state: 'NY',
    latitude: '40.776988',
    longitude: '-73.983519',
    timeZoneId: 'America/New_York',
  },
  '10138': {
    zip: '10138',
    city: 'New York',
    state: 'NY',
    latitude: '40.756075',
    longitude: '-73.989193',
    timeZoneId: 'America/New_York',
  },
  '10150': {
    zip: '10150',
    city: 'New York',
    state: 'NY',
    latitude: '40.757563',
    longitude: '-73.972925',
    timeZoneId: 'America/New_York',
  },
  '10151': {
    zip: '10151',
    city: 'New York',
    state: 'NY',
    latitude: '40.76436',
    longitude: '-73.97649',
    timeZoneId: 'America/New_York',
  },
  '10152': {
    zip: '10152',
    city: 'New York',
    state: 'NY',
    latitude: '40.759241',
    longitude: '-73.971816',
    timeZoneId: 'America/New_York',
  },
  '10153': {
    zip: '10153',
    city: 'New York',
    state: 'NY',
    latitude: '40.763133',
    longitude: '-73.972541',
    timeZoneId: 'America/New_York',
  },
  '10154': {
    zip: '10154',
    city: 'New York',
    state: 'NY',
    latitude: '40.756535',
    longitude: '-73.9706',
    timeZoneId: 'America/New_York',
  },
  '10155': {
    zip: '10155',
    city: 'New York',
    state: 'NY',
    latitude: '40.760757',
    longitude: '-73.965739',
    timeZoneId: 'America/New_York',
  },
  '10156': {
    zip: '10156',
    city: 'New York',
    state: 'NY',
    latitude: '40.746242',
    longitude: '-73.980923',
    timeZoneId: 'America/New_York',
  },
  '10157': {
    zip: '10157',
    city: 'New York',
    state: 'NY',
    latitude: '40.743962',
    longitude: '-73.977191',
    timeZoneId: 'America/New_York',
  },
  '10158': {
    zip: '10158',
    city: 'New York',
    state: 'NY',
    latitude: '40.755219',
    longitude: '-73.966956',
    timeZoneId: 'America/New_York',
  },
  '10159': {
    zip: '10159',
    city: 'New York',
    state: 'NY',
    latitude: '40.738556',
    longitude: '-73.984332',
    timeZoneId: 'America/New_York',
  },
  '10160': {
    zip: '10160',
    city: 'New York',
    state: 'NY',
    latitude: '40.739111',
    longitude: '-73.983857',
    timeZoneId: 'America/New_York',
  },
  '10162': {
    zip: '10162',
    city: 'New York',
    state: 'NY',
    latitude: '40.766659',
    longitude: '-73.958508',
    timeZoneId: 'America/New_York',
  },
  '10163': {
    zip: '10163',
    city: 'New York',
    state: 'NY',
    latitude: '40.755007',
    longitude: '-73.967791',
    timeZoneId: 'America/New_York',
  },
  '10164': {
    zip: '10164',
    city: 'New York',
    state: 'NY',
    latitude: '40.751519',
    longitude: '-73.977272',
    timeZoneId: 'America/New_York',
  },
  '10165': {
    zip: '10165',
    city: 'New York',
    state: 'NY',
    latitude: '40.755416',
    longitude: '-73.980319',
    timeZoneId: 'America/New_York',
  },
  '10166': {
    zip: '10166',
    city: 'New York',
    state: 'NY',
    latitude: '40.754811',
    longitude: '-73.977146',
    timeZoneId: 'America/New_York',
  },
  '10167': {
    zip: '10167',
    city: 'New York',
    state: 'NY',
    latitude: '40.75556',
    longitude: '-73.974469',
    timeZoneId: 'America/New_York',
  },
  '10168': {
    zip: '10168',
    city: 'New York',
    state: 'NY',
    latitude: '40.750979',
    longitude: '-73.977238',
    timeZoneId: 'America/New_York',
  },
  '10169': {
    zip: '10169',
    city: 'New York',
    state: 'NY',
    latitude: '40.797792',
    longitude: '-73.946351',
    timeZoneId: 'America/New_York',
  },
  '10170': {
    zip: '10170',
    city: 'New York',
    state: 'NY',
    latitude: '40.749247',
    longitude: '-73.976527',
    timeZoneId: 'America/New_York',
  },
  '10171': {
    zip: '10171',
    city: 'New York',
    state: 'NY',
    latitude: '40.758429',
    longitude: '-73.972144',
    timeZoneId: 'America/New_York',
  },
  '10172': {
    zip: '10172',
    city: 'New York',
    state: 'NY',
    latitude: '40.741194',
    longitude: '-73.987354',
    timeZoneId: 'America/New_York',
  },
  '10173': {
    zip: '10173',
    city: 'New York',
    state: 'NY',
    latitude: '40.754208',
    longitude: '-73.980058',
    timeZoneId: 'America/New_York',
  },
  '10174': {
    zip: '10174',
    city: 'New York',
    state: 'NY',
    latitude: '40.749366',
    longitude: '-73.976646',
    timeZoneId: 'America/New_York',
  },
  '10175': {
    zip: '10175',
    city: 'New York',
    state: 'NY',
    latitude: '40.754254',
    longitude: '-73.983164',
    timeZoneId: 'America/New_York',
  },
  '10176': {
    zip: '10176',
    city: 'New York',
    state: 'NY',
    latitude: '40.752612',
    longitude: '-73.981704',
    timeZoneId: 'America/New_York',
  },
  '10177': {
    zip: '10177',
    city: 'New York',
    state: 'NY',
    latitude: '40.738338',
    longitude: '-73.985633',
    timeZoneId: 'America/New_York',
  },
  '10178': {
    zip: '10178',
    city: 'New York',
    state: 'NY',
    latitude: '40.752881',
    longitude: '-73.97856',
    timeZoneId: 'America/New_York',
  },
  '10179': {
    zip: '10179',
    city: 'New York',
    state: 'NY',
    latitude: '40.754518',
    longitude: '-73.976308',
    timeZoneId: 'America/New_York',
  },
  '10185': {
    zip: '10185',
    city: 'New York',
    state: 'NY',
    latitude: '40.757484',
    longitude: '-73.991365',
    timeZoneId: 'America/New_York',
  },
  '10199': {
    zip: '10199',
    city: 'New York',
    state: 'NY',
    latitude: '40.726252',
    longitude: '-73.979012',
    timeZoneId: 'America/New_York',
  },
  '10203': {
    zip: '10203',
    city: 'New York',
    state: 'NY',
    latitude: '40.711931',
    longitude: '-74.011806',
    timeZoneId: 'America/New_York',
  },
  '10211': {
    zip: '10211',
    city: 'New York',
    state: 'NY',
    latitude: '40.731775',
    longitude: '-73.989953',
    timeZoneId: 'America/New_York',
  },
  '10212': {
    zip: '10212',
    city: 'New York',
    state: 'NY',
    latitude: '40.713127',
    longitude: '-74.01013',
    timeZoneId: 'America/New_York',
  },
  '10213': {
    zip: '10213',
    city: 'New York',
    state: 'NY',
    latitude: '40.71844',
    longitude: '-74.006344',
    timeZoneId: 'America/New_York',
  },
  '10242': {
    zip: '10242',
    city: 'New York',
    state: 'NY',
    latitude: '40.715181',
    longitude: '-74.009474',
    timeZoneId: 'America/New_York',
  },
  '10249': {
    zip: '10249',
    city: 'New York',
    state: 'NY',
    latitude: '40.714735',
    longitude: '-74.008556',
    timeZoneId: 'America/New_York',
  },
  '10256': {
    zip: '10256',
    city: 'New York',
    state: 'NY',
    latitude: '40.706232',
    longitude: '-74.010903',
    timeZoneId: 'America/New_York',
  },
  '10258': {
    zip: '10258',
    city: 'New York',
    state: 'NY',
    latitude: '40.71372',
    longitude: '-74.007559',
    timeZoneId: 'America/New_York',
  },
  '10259': {
    zip: '10259',
    city: 'New York',
    state: 'NY',
    latitude: '40.754165',
    longitude: '-73.973439',
    timeZoneId: 'America/New_York',
  },
  '10260': {
    zip: '10260',
    city: 'New York',
    state: 'NY',
    latitude: '40.703143',
    longitude: '-74.012787',
    timeZoneId: 'America/New_York',
  },
  '10261': {
    zip: '10261',
    city: 'New York',
    state: 'NY',
    latitude: '40.750339',
    longitude: '-73.975338',
    timeZoneId: 'America/New_York',
  },
  '10265': {
    zip: '10265',
    city: 'New York',
    state: 'NY',
    latitude: '40.703113',
    longitude: '-74.013325',
    timeZoneId: 'America/New_York',
  },
  '10268': {
    zip: '10268',
    city: 'New York',
    state: 'NY',
    latitude: '40.70694',
    longitude: '-74.011574',
    timeZoneId: 'America/New_York',
  },
  '10269': {
    zip: '10269',
    city: 'New York',
    state: 'NY',
    latitude: '40.701869',
    longitude: '-74.010178',
    timeZoneId: 'America/New_York',
  },
  '10270': {
    zip: '10270',
    city: 'New York',
    state: 'NY',
    latitude: '40.703355',
    longitude: '-74.007485',
    timeZoneId: 'America/New_York',
  },
  '10271': {
    zip: '10271',
    city: 'New York',
    state: 'NY',
    latitude: '40.706437',
    longitude: '-74.006835',
    timeZoneId: 'America/New_York',
  },
  '10272': {
    zip: '10272',
    city: 'New York',
    state: 'NY',
    latitude: '40.760631',
    longitude: '-74.005451',
    timeZoneId: 'America/New_York',
  },
  '10273': {
    zip: '10273',
    city: 'New York',
    state: 'NY',
    latitude: '40.711049',
    longitude: '-74.003348',
    timeZoneId: 'America/New_York',
  },
  '10274': {
    zip: '10274',
    city: 'New York',
    state: 'NY',
    latitude: '40.70592',
    longitude: '-74.012392',
    timeZoneId: 'America/New_York',
  },
  '10275': {
    zip: '10275',
    city: 'New York',
    state: 'NY',
    latitude: '40.701876',
    longitude: '-74.013967',
    timeZoneId: 'America/New_York',
  },
  '10276': {
    zip: '10276',
    city: 'New York',
    state: 'NY',
    latitude: '40.733951',
    longitude: '-73.989188',
    timeZoneId: 'America/New_York',
  },
  '10277': {
    zip: '10277',
    city: 'New York',
    state: 'NY',
    latitude: '40.718432',
    longitude: '-74.015507',
    timeZoneId: 'America/New_York',
  },
  '10278': {
    zip: '10278',
    city: 'New York',
    state: 'NY',
    latitude: '40.711322',
    longitude: '-74.00888',
    timeZoneId: 'America/New_York',
  },
  '10279': {
    zip: '10279',
    city: 'New York',
    state: 'NY',
    latitude: '40.713759',
    longitude: '-74.009621',
    timeZoneId: 'America/New_York',
  },
  '10280': {
    zip: '10280',
    city: 'New York',
    state: 'NY',
    latitude: '40.711844',
    longitude: '-74.019133',
    timeZoneId: 'America/New_York',
  },
  '10281': {
    zip: '10281',
    city: 'New York',
    state: 'NY',
    latitude: '40.71668',
    longitude: '-74.012932',
    timeZoneId: 'America/New_York',
  },
  '10282': {
    zip: '10282',
    city: 'New York',
    state: 'NY',
    latitude: '40.714419',
    longitude: '-74.008255',
    timeZoneId: 'America/New_York',
  },
  '10285': {
    zip: '10285',
    city: 'New York',
    state: 'NY',
    latitude: '40.710807',
    longitude: '-74.015235',
    timeZoneId: 'America/New_York',
  },
  '10286': {
    zip: '10286',
    city: 'New York',
    state: 'NY',
    latitude: '40.705704',
    longitude: '-74.007966',
    timeZoneId: 'America/New_York',
  },
  '10301': {
    zip: '10301',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.628781',
    longitude: '-74.094599',
    timeZoneId: 'America/New_York',
  },
  '10302': {
    zip: '10302',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.629815',
    longitude: '-74.138408',
    timeZoneId: 'America/New_York',
  },
  '10303': {
    zip: '10303',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.629027',
    longitude: '-74.162595',
    timeZoneId: 'America/New_York',
  },
  '10304': {
    zip: '10304',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.603229',
    longitude: '-74.09021',
    timeZoneId: 'America/New_York',
  },
  '10305': {
    zip: '10305',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.594867',
    longitude: '-74.0778',
    timeZoneId: 'America/New_York',
  },
  '10306': {
    zip: '10306',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.566903',
    longitude: '-74.120829',
    timeZoneId: 'America/New_York',
  },
  '10307': {
    zip: '10307',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.509906',
    longitude: '-74.239854',
    timeZoneId: 'America/New_York',
  },
  '10308': {
    zip: '10308',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.553407',
    longitude: '-74.150326',
    timeZoneId: 'America/New_York',
  },
  '10309': {
    zip: '10309',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.530762',
    longitude: '-74.213351',
    timeZoneId: 'America/New_York',
  },
  '10310': {
    zip: '10310',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.630863',
    longitude: '-74.117886',
    timeZoneId: 'America/New_York',
  },
  '10311': {
    zip: '10311',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.604748',
    longitude: '-74.180644',
    timeZoneId: 'America/New_York',
  },
  '10312': {
    zip: '10312',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.542579',
    longitude: '-74.178991',
    timeZoneId: 'America/New_York',
  },
  '10313': {
    zip: '10313',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.614826',
    longitude: '-74.124364',
    timeZoneId: 'America/New_York',
  },
  '10314': {
    zip: '10314',
    city: 'Staten Island',
    state: 'NY',
    latitude: '40.606393',
    longitude: '-74.152402',
    timeZoneId: 'America/New_York',
  },
  '10451': {
    zip: '10451',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.820195',
    longitude: '-73.920218',
    timeZoneId: 'America/New_York',
  },
  '10452': {
    zip: '10452',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.841676',
    longitude: '-73.921773',
    timeZoneId: 'America/New_York',
  },
  '10453': {
    zip: '10453',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.852613',
    longitude: '-73.914221',
    timeZoneId: 'America/New_York',
  },
  '10454': {
    zip: '10454',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.808965',
    longitude: '-73.916818',
    timeZoneId: 'America/New_York',
  },
  '10455': {
    zip: '10455',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.812989',
    longitude: '-73.903711',
    timeZoneId: 'America/New_York',
  },
  '10456': {
    zip: '10456',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.830175',
    longitude: '-73.906791',
    timeZoneId: 'America/New_York',
  },
  '10457': {
    zip: '10457',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.846394',
    longitude: '-73.902732',
    timeZoneId: 'America/New_York',
  },
  '10458': {
    zip: '10458',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.859035',
    longitude: '-73.890784',
    timeZoneId: 'America/New_York',
  },
  '10459': {
    zip: '10459',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.827628',
    longitude: '-73.896045',
    timeZoneId: 'America/New_York',
  },
  '10460': {
    zip: '10460',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.837082',
    longitude: '-73.880734',
    timeZoneId: 'America/New_York',
  },
  '10461': {
    zip: '10461',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.844984',
    longitude: '-73.843255',
    timeZoneId: 'America/New_York',
  },
  '10462': {
    zip: '10462',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.840999',
    longitude: '-73.85721',
    timeZoneId: 'America/New_York',
  },
  '10463': {
    zip: '10463',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.881512',
    longitude: '-73.907521',
    timeZoneId: 'America/New_York',
  },
  '10464': {
    zip: '10464',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.852713',
    longitude: '-73.790565',
    timeZoneId: 'America/New_York',
  },
  '10465': {
    zip: '10465',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.823241',
    longitude: '-73.826218',
    timeZoneId: 'America/New_York',
  },
  '10466': {
    zip: '10466',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.889675',
    longitude: '-73.845608',
    timeZoneId: 'America/New_York',
  },
  '10467': {
    zip: '10467',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.873256',
    longitude: '-73.870576',
    timeZoneId: 'America/New_York',
  },
  '10468': {
    zip: '10468',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.868013',
    longitude: '-73.896417',
    timeZoneId: 'America/New_York',
  },
  '10469': {
    zip: '10469',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.868747',
    longitude: '-73.849303',
    timeZoneId: 'America/New_York',
  },
  '10470': {
    zip: '10470',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.901193',
    longitude: '-73.860637',
    timeZoneId: 'America/New_York',
  },
  '10471': {
    zip: '10471',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.903491',
    longitude: '-73.903466',
    timeZoneId: 'America/New_York',
  },
  '10472': {
    zip: '10472',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.830485',
    longitude: '-73.870026',
    timeZoneId: 'America/New_York',
  },
  '10473': {
    zip: '10473',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.818719',
    longitude: '-73.85718',
    timeZoneId: 'America/New_York',
  },
  '10474': {
    zip: '10474',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.809555',
    longitude: '-73.885815',
    timeZoneId: 'America/New_York',
  },
  '10475': {
    zip: '10475',
    city: 'Bronx',
    state: 'NY',
    latitude: '40.876918',
    longitude: '-73.830136',
    timeZoneId: 'America/New_York',
  },
  '10501': {
    zip: '10501',
    city: 'Amawalk',
    state: 'NY',
    latitude: '41.294884',
    longitude: '-73.762148',
    timeZoneId: 'America/New_York',
  },
  '10502': {
    zip: '10502',
    city: 'Ardsley',
    state: 'NY',
    latitude: '41.015609',
    longitude: '-73.844361',
    timeZoneId: 'America/New_York',
  },
  '10503': {
    zip: '10503',
    city: 'Ardsley on Hudson',
    state: 'NY',
    latitude: '41.025309',
    longitude: '-73.868081',
    timeZoneId: 'America/New_York',
  },
  '10504': {
    zip: '10504',
    city: 'Armonk',
    state: 'NY',
    latitude: '41.139871',
    longitude: '-73.700165',
    timeZoneId: 'America/New_York',
  },
  '10505': {
    zip: '10505',
    city: 'Baldwin Place',
    state: 'NY',
    latitude: '41.342445',
    longitude: '-73.747115',
    timeZoneId: 'America/New_York',
  },
  '10506': {
    zip: '10506',
    city: 'Bedford',
    state: 'NY',
    latitude: '41.1852',
    longitude: '-73.64088',
    timeZoneId: 'America/New_York',
  },
  '10507': {
    zip: '10507',
    city: 'Bedford Hills',
    state: 'NY',
    latitude: '41.231606',
    longitude: '-73.690681',
    timeZoneId: 'America/New_York',
  },
  '10509': {
    zip: '10509',
    city: 'Brewster',
    state: 'NY',
    latitude: '41.422678',
    longitude: '-73.599511',
    timeZoneId: 'America/New_York',
  },
  '10510': {
    zip: '10510',
    city: 'Briarcliff Manor',
    state: 'NY',
    latitude: '41.145661',
    longitude: '-73.833747',
    timeZoneId: 'America/New_York',
  },
  '10511': {
    zip: '10511',
    city: 'Buchanan',
    state: 'NY',
    latitude: '41.258401',
    longitude: '-73.939031',
    timeZoneId: 'America/New_York',
  },
  '10512': {
    zip: '10512',
    city: 'Carmel',
    state: 'NY',
    latitude: '41.449121',
    longitude: '-73.679279',
    timeZoneId: 'America/New_York',
  },
  '10514': {
    zip: '10514',
    city: 'Chappaqua',
    state: 'NY',
    latitude: '41.173209',
    longitude: '-73.76922',
    timeZoneId: 'America/New_York',
  },
  '10516': {
    zip: '10516',
    city: 'Cold Spring',
    state: 'NY',
    latitude: '41.42238',
    longitude: '-73.95072',
    timeZoneId: 'America/New_York',
  },
  '10517': {
    zip: '10517',
    city: 'Crompond',
    state: 'NY',
    latitude: '41.295589',
    longitude: '-73.861835',
    timeZoneId: 'America/New_York',
  },
  '10518': {
    zip: '10518',
    city: 'Cross River',
    state: 'NY',
    latitude: '41.268446',
    longitude: '-73.602238',
    timeZoneId: 'America/New_York',
  },
  '10519': {
    zip: '10519',
    city: 'Croton Falls',
    state: 'NY',
    latitude: '41.345613',
    longitude: '-73.660066',
    timeZoneId: 'America/New_York',
  },
  '10520': {
    zip: '10520',
    city: 'Croton on Hudson',
    state: 'NY',
    latitude: '41.21676',
    longitude: '-73.89672',
    timeZoneId: 'America/New_York',
  },
  '10521': {
    zip: '10521',
    city: 'Croton on Hudson',
    state: 'NY',
    latitude: '41.234521',
    longitude: '-73.924343',
    timeZoneId: 'America/New_York',
  },
  '10522': {
    zip: '10522',
    city: 'Dobbs Ferry',
    state: 'NY',
    latitude: '41.011736',
    longitude: '-73.865605',
    timeZoneId: 'America/New_York',
  },
  '10523': {
    zip: '10523',
    city: 'Elmsford',
    state: 'NY',
    latitude: '41.055081',
    longitude: '-73.816734',
    timeZoneId: 'America/New_York',
  },
  '10524': {
    zip: '10524',
    city: 'Garrison',
    state: 'NY',
    latitude: '41.369283',
    longitude: '-73.919944',
    timeZoneId: 'America/New_York',
  },
  '10526': {
    zip: '10526',
    city: 'Goldens Bridge',
    state: 'NY',
    latitude: '41.295831',
    longitude: '-73.666608',
    timeZoneId: 'America/New_York',
  },
  '10527': {
    zip: '10527',
    city: 'Granite Springs',
    state: 'NY',
    latitude: '41.317032',
    longitude: '-73.76449',
    timeZoneId: 'America/New_York',
  },
  '10528': {
    zip: '10528',
    city: 'Harrison',
    state: 'NY',
    latitude: '40.980573',
    longitude: '-73.724601',
    timeZoneId: 'America/New_York',
  },
  '10530': {
    zip: '10530',
    city: 'Hartsdale',
    state: 'NY',
    latitude: '41.021378',
    longitude: '-73.805692',
    timeZoneId: 'America/New_York',
  },
  '10532': {
    zip: '10532',
    city: 'Hawthorne',
    state: 'NY',
    latitude: '41.107363',
    longitude: '-73.793734',
    timeZoneId: 'America/New_York',
  },
  '10533': {
    zip: '10533',
    city: 'Irvington',
    state: 'NY',
    latitude: '41.04176',
    longitude: '-73.855752',
    timeZoneId: 'America/New_York',
  },
  '10535': {
    zip: '10535',
    city: 'Jefferson Valley',
    state: 'NY',
    latitude: '41.339561',
    longitude: '-73.798456',
    timeZoneId: 'America/New_York',
  },
  '10536': {
    zip: '10536',
    city: 'Katonah',
    state: 'NY',
    latitude: '41.27238',
    longitude: '-73.68924',
    timeZoneId: 'America/New_York',
  },
  '10537': {
    zip: '10537',
    city: 'Lake Peekskill',
    state: 'NY',
    latitude: '41.337997',
    longitude: '-73.885707',
    timeZoneId: 'America/New_York',
  },
  '10538': {
    zip: '10538',
    city: 'Larchmont',
    state: 'NY',
    latitude: '40.936489',
    longitude: '-73.755346',
    timeZoneId: 'America/New_York',
  },
  '10540': {
    zip: '10540',
    city: 'Lincolndale',
    state: 'NY',
    latitude: '41.342061',
    longitude: '-73.721539',
    timeZoneId: 'America/New_York',
  },
  '10541': {
    zip: '10541',
    city: 'Mahopac',
    state: 'NY',
    latitude: '41.378032',
    longitude: '-73.749712',
    timeZoneId: 'America/New_York',
  },
  '10542': {
    zip: '10542',
    city: 'Mahopac Falls',
    state: 'NY',
    latitude: '41.372926',
    longitude: '-73.759834',
    timeZoneId: 'America/New_York',
  },
  '10543': {
    zip: '10543',
    city: 'Mamaroneck',
    state: 'NY',
    latitude: '40.955281',
    longitude: '-73.737657',
    timeZoneId: 'America/New_York',
  },
  '10545': {
    zip: '10545',
    city: 'Maryknoll',
    state: 'NY',
    latitude: '41.18353',
    longitude: '-73.829151',
    timeZoneId: 'America/New_York',
  },
  '10546': {
    zip: '10546',
    city: 'Millwood',
    state: 'NY',
    latitude: '41.198444',
    longitude: '-73.801573',
    timeZoneId: 'America/New_York',
  },
  '10547': {
    zip: '10547',
    city: 'Mohegan Lake',
    state: 'NY',
    latitude: '41.317149',
    longitude: '-73.84668',
    timeZoneId: 'America/New_York',
  },
  '10548': {
    zip: '10548',
    city: 'Montrose',
    state: 'NY',
    latitude: '41.247304',
    longitude: '-73.934623',
    timeZoneId: 'America/New_York',
  },
  '10549': {
    zip: '10549',
    city: 'Mount Kisco',
    state: 'NY',
    latitude: '41.20716',
    longitude: '-73.71732',
    timeZoneId: 'America/New_York',
  },
  '10550': {
    zip: '10550',
    city: 'Mount Vernon',
    state: 'NY',
    latitude: '40.907698',
    longitude: '-73.838538',
    timeZoneId: 'America/New_York',
  },
  '10551': {
    zip: '10551',
    city: 'Mount Vernon',
    state: 'NY',
    latitude: '40.901727',
    longitude: '-73.823937',
    timeZoneId: 'America/New_York',
  },
  '10552': {
    zip: '10552',
    city: 'Mount Vernon',
    state: 'NY',
    latitude: '40.922381',
    longitude: '-73.829403',
    timeZoneId: 'America/New_York',
  },
  '10553': {
    zip: '10553',
    city: 'Mount Vernon',
    state: 'NY',
    latitude: '40.904734',
    longitude: '-73.8246',
    timeZoneId: 'America/New_York',
  },
  '10560': {
    zip: '10560',
    city: 'North Salem',
    state: 'NY',
    latitude: '41.330181',
    longitude: '-73.607527',
    timeZoneId: 'America/New_York',
  },
  '10562': {
    zip: '10562',
    city: 'Ossining',
    state: 'NY',
    latitude: '41.174335',
    longitude: '-73.845908',
    timeZoneId: 'America/New_York',
  },
  '10566': {
    zip: '10566',
    city: 'Peekskill',
    state: 'NY',
    latitude: '41.29968',
    longitude: '-73.90609',
    timeZoneId: 'America/New_York',
  },
  '10567': {
    zip: '10567',
    city: 'Cortlandt Manor',
    state: 'NY',
    latitude: '41.279156',
    longitude: '-73.87283',
    timeZoneId: 'America/New_York',
  },
  '10570': {
    zip: '10570',
    city: 'Pleasantville',
    state: 'NY',
    latitude: '41.134752',
    longitude: '-73.784806',
    timeZoneId: 'America/New_York',
  },
  '10573': {
    zip: '10573',
    city: 'Port Chester',
    state: 'NY',
    latitude: '41.011395',
    longitude: '-73.678286',
    timeZoneId: 'America/New_York',
  },
  '10576': {
    zip: '10576',
    city: 'Pound Ridge',
    state: 'NY',
    latitude: '41.224542',
    longitude: '-73.57148',
    timeZoneId: 'America/New_York',
  },
  '10577': {
    zip: '10577',
    city: 'Purchase',
    state: 'NY',
    latitude: '41.041406',
    longitude: '-73.715358',
    timeZoneId: 'America/New_York',
  },
  '10578': {
    zip: '10578',
    city: 'Purdys',
    state: 'NY',
    latitude: '41.320695',
    longitude: '-73.679913',
    timeZoneId: 'America/New_York',
  },
  '10579': {
    zip: '10579',
    city: 'Putnam Valley',
    state: 'NY',
    latitude: '41.390553',
    longitude: '-73.846426',
    timeZoneId: 'America/New_York',
  },
  '10580': {
    zip: '10580',
    city: 'Rye',
    state: 'NY',
    latitude: '40.982377',
    longitude: '-73.689314',
    timeZoneId: 'America/New_York',
  },
  '10583': {
    zip: '10583',
    city: 'Scarsdale',
    state: 'NY',
    latitude: '40.989799',
    longitude: '-73.80007',
    timeZoneId: 'America/New_York',
  },
  '10587': {
    zip: '10587',
    city: 'Shenorock',
    state: 'NY',
    latitude: '41.32713',
    longitude: '-73.736741',
    timeZoneId: 'America/New_York',
  },
  '10588': {
    zip: '10588',
    city: 'Shrub Oak',
    state: 'NY',
    latitude: '41.330384',
    longitude: '-73.83412',
    timeZoneId: 'America/New_York',
  },
  '10589': {
    zip: '10589',
    city: 'Somers',
    state: 'NY',
    latitude: '41.335949',
    longitude: '-73.6941',
    timeZoneId: 'America/New_York',
  },
  '10590': {
    zip: '10590',
    city: 'South Salem',
    state: 'NY',
    latitude: '41.251239',
    longitude: '-73.543589',
    timeZoneId: 'America/New_York',
  },
  '10591': {
    zip: '10591',
    city: 'Tarrytown',
    state: 'NY',
    latitude: '41.077148',
    longitude: '-73.857479',
    timeZoneId: 'America/New_York',
  },
  '10594': {
    zip: '10594',
    city: 'Thornwood',
    state: 'NY',
    latitude: '41.117322',
    longitude: '-73.773933',
    timeZoneId: 'America/New_York',
  },
  '10595': {
    zip: '10595',
    city: 'Valhalla',
    state: 'NY',
    latitude: '41.088128',
    longitude: '-73.777872',
    timeZoneId: 'America/New_York',
  },
  '10596': {
    zip: '10596',
    city: 'Verplanck',
    state: 'NY',
    latitude: '41.256445',
    longitude: '-73.95933',
    timeZoneId: 'America/New_York',
  },
  '10597': {
    zip: '10597',
    city: 'Waccabuc',
    state: 'NY',
    latitude: '41.297002',
    longitude: '-73.600825',
    timeZoneId: 'America/New_York',
  },
  '10598': {
    zip: '10598',
    city: 'Yorktown Heights',
    state: 'NY',
    latitude: '41.294911',
    longitude: '-73.797703',
    timeZoneId: 'America/New_York',
  },
  '10601': {
    zip: '10601',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.030604',
    longitude: '-73.76394',
    timeZoneId: 'America/New_York',
  },
  '10602': {
    zip: '10602',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.032214',
    longitude: '-73.76525',
    timeZoneId: 'America/New_York',
  },
  '10603': {
    zip: '10603',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.050315',
    longitude: '-73.777527',
    timeZoneId: 'America/New_York',
  },
  '10604': {
    zip: '10604',
    city: 'West Harrison',
    state: 'NY',
    latitude: '41.03502',
    longitude: '-73.7436',
    timeZoneId: 'America/New_York',
  },
  '10605': {
    zip: '10605',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.006687',
    longitude: '-73.746222',
    timeZoneId: 'America/New_York',
  },
  '10606': {
    zip: '10606',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.031329',
    longitude: '-73.779151',
    timeZoneId: 'America/New_York',
  },
  '10607': {
    zip: '10607',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.040477',
    longitude: '-73.809229',
    timeZoneId: 'America/New_York',
  },
  '10610': {
    zip: '10610',
    city: 'White Plains',
    state: 'NY',
    latitude: '41.022482',
    longitude: '-73.812614',
    timeZoneId: 'America/New_York',
  },
  '10701': {
    zip: '10701',
    city: 'Yonkers',
    state: 'NY',
    latitude: '40.941652',
    longitude: '-73.877562',
    timeZoneId: 'America/New_York',
  },
  '10702': {
    zip: '10702',
    city: 'Yonkers',
    state: 'NY',
    latitude: '40.932578',
    longitude: '-73.902251',
    timeZoneId: 'America/New_York',
  },
  '10703': {
    zip: '10703',
    city: 'Yonkers',
    state: 'NY',
    latitude: '40.953777',
    longitude: '-73.88101',
    timeZoneId: 'America/New_York',
  },
  '10704': {
    zip: '10704',
    city: 'Yonkers',
    state: 'NY',
    latitude: '40.919089',
    longitude: '-73.861638',
    timeZoneId: 'America/New_York',
  },
  '10705': {
    zip: '10705',
    city: 'Yonkers',
    state: 'NY',
    latitude: '40.918301',
    longitude: '-73.891148',
    timeZoneId: 'America/New_York',
  },
  '10706': {
    zip: '10706',
    city: 'Hastings on Hudson',
    state: 'NY',
    latitude: '40.97052',
    longitude: '-73.8753',
    timeZoneId: 'America/New_York',
  },
  '10707': {
    zip: '10707',
    city: 'Tuckahoe',
    state: 'NY',
    latitude: '40.955489',
    longitude: '-73.813597',
    timeZoneId: 'America/New_York',
  },
  '10708': {
    zip: '10708',
    city: 'Bronxville',
    state: 'NY',
    latitude: '40.940639',
    longitude: '-73.822578',
    timeZoneId: 'America/New_York',
  },
  '10709': {
    zip: '10709',
    city: 'Eastchester',
    state: 'NY',
    latitude: '40.955972',
    longitude: '-73.807475',
    timeZoneId: 'America/New_York',
  },
  '10710': {
    zip: '10710',
    city: 'Yonkers',
    state: 'NY',
    latitude: '40.965847',
    longitude: '-73.845926',
    timeZoneId: 'America/New_York',
  },
  '10801': {
    zip: '10801',
    city: 'New Rochelle',
    state: 'NY',
    latitude: '40.916607',
    longitude: '-73.785532',
    timeZoneId: 'America/New_York',
  },
  '10802': {
    zip: '10802',
    city: 'New Rochelle',
    state: 'NY',
    latitude: '40.91314',
    longitude: '-73.779734',
    timeZoneId: 'America/New_York',
  },
  '10803': {
    zip: '10803',
    city: 'Pelham',
    state: 'NY',
    latitude: '40.905342',
    longitude: '-73.804941',
    timeZoneId: 'America/New_York',
  },
  '10804': {
    zip: '10804',
    city: 'New Rochelle',
    state: 'NY',
    latitude: '40.949073',
    longitude: '-73.785808',
    timeZoneId: 'America/New_York',
  },
  '10805': {
    zip: '10805',
    city: 'New Rochelle',
    state: 'NY',
    latitude: '40.902888',
    longitude: '-73.777829',
    timeZoneId: 'America/New_York',
  },
  '10901': {
    zip: '10901',
    city: 'Suffern',
    state: 'NY',
    latitude: '41.127671',
    longitude: '-74.111934',
    timeZoneId: 'America/New_York',
  },
  '10910': {
    zip: '10910',
    city: 'Arden',
    state: 'NY',
    latitude: '41.38879',
    longitude: '-74.165439',
    timeZoneId: 'America/New_York',
  },
  '10911': {
    zip: '10911',
    city: 'Bear Mountain',
    state: 'NY',
    latitude: '41.306188',
    longitude: '-73.99218',
    timeZoneId: 'America/New_York',
  },
  '10912': {
    zip: '10912',
    city: 'Bellvale',
    state: 'NY',
    latitude: '41.247332',
    longitude: '-74.303862',
    timeZoneId: 'America/New_York',
  },
  '10913': {
    zip: '10913',
    city: 'Blauvelt',
    state: 'NY',
    latitude: '41.060202',
    longitude: '-73.961158',
    timeZoneId: 'America/New_York',
  },
  '10914': {
    zip: '10914',
    city: 'Blooming Grove',
    state: 'NY',
    latitude: '41.382548',
    longitude: '-74.179202',
    timeZoneId: 'America/New_York',
  },
  '10915': {
    zip: '10915',
    city: 'Bullville',
    state: 'NY',
    latitude: '41.541982',
    longitude: '-74.362554',
    timeZoneId: 'America/New_York',
  },
  '10916': {
    zip: '10916',
    city: 'Campbell Hall',
    state: 'NY',
    latitude: '41.437341',
    longitude: '-74.250632',
    timeZoneId: 'America/New_York',
  },
  '10917': {
    zip: '10917',
    city: 'Central Valley',
    state: 'NY',
    latitude: '41.333541',
    longitude: '-74.134438',
    timeZoneId: 'America/New_York',
  },
  '10918': {
    zip: '10918',
    city: 'Chester',
    state: 'NY',
    latitude: '41.352758',
    longitude: '-74.266372',
    timeZoneId: 'America/New_York',
  },
  '10919': {
    zip: '10919',
    city: 'Circleville',
    state: 'NY',
    latitude: '41.513113',
    longitude: '-74.37616',
    timeZoneId: 'America/New_York',
  },
  '10920': {
    zip: '10920',
    city: 'Congers',
    state: 'NY',
    latitude: '41.147711',
    longitude: '-73.941171',
    timeZoneId: 'America/New_York',
  },
  '10921': {
    zip: '10921',
    city: 'Florida',
    state: 'NY',
    latitude: '41.329376',
    longitude: '-74.347207',
    timeZoneId: 'America/New_York',
  },
  '10922': {
    zip: '10922',
    city: 'Fort Montgomery',
    state: 'NY',
    latitude: '41.338977',
    longitude: '-73.987248',
    timeZoneId: 'America/New_York',
  },
  '10923': {
    zip: '10923',
    city: 'Garnerville',
    state: 'NY',
    latitude: '41.203736',
    longitude: '-74.000708',
    timeZoneId: 'America/New_York',
  },
  '10924': {
    zip: '10924',
    city: 'Goshen',
    state: 'NY',
    latitude: '41.396494',
    longitude: '-74.319687',
    timeZoneId: 'America/New_York',
  },
  '10925': {
    zip: '10925',
    city: 'Greenwood Lake',
    state: 'NY',
    latitude: '41.21895',
    longitude: '-74.294664',
    timeZoneId: 'America/New_York',
  },
  '10926': {
    zip: '10926',
    city: 'Harriman',
    state: 'NY',
    latitude: '41.307707',
    longitude: '-74.150676',
    timeZoneId: 'America/New_York',
  },
  '10927': {
    zip: '10927',
    city: 'Haverstraw',
    state: 'NY',
    latitude: '41.199797',
    longitude: '-73.969661',
    timeZoneId: 'America/New_York',
  },
  '10928': {
    zip: '10928',
    city: 'Highland Falls',
    state: 'NY',
    latitude: '41.368496',
    longitude: '-73.970013',
    timeZoneId: 'America/New_York',
  },
  '10930': {
    zip: '10930',
    city: 'Highland Mills',
    state: 'NY',
    latitude: '41.353753',
    longitude: '-74.130068',
    timeZoneId: 'America/New_York',
  },
  '10931': {
    zip: '10931',
    city: 'Hillburn',
    state: 'NY',
    latitude: '41.121428',
    longitude: '-74.169527',
    timeZoneId: 'America/New_York',
  },
  '10932': {
    zip: '10932',
    city: 'Howells',
    state: 'NY',
    latitude: '41.480603',
    longitude: '-74.463537',
    timeZoneId: 'America/New_York',
  },
  '10933': {
    zip: '10933',
    city: 'Johnson',
    state: 'NY',
    latitude: '41.365309',
    longitude: '-74.507791',
    timeZoneId: 'America/New_York',
  },
  '10940': {
    zip: '10940',
    city: 'Middletown',
    state: 'NY',
    latitude: '41.4744',
    longitude: '-74.41098',
    timeZoneId: 'America/New_York',
  },
  '10941': {
    zip: '10941',
    city: 'Middletown',
    state: 'NY',
    latitude: '41.463349',
    longitude: '-74.421398',
    timeZoneId: 'America/New_York',
  },
  '10949': {
    zip: '10949',
    city: 'Monroe',
    state: 'NY',
    latitude: '41.336262',
    longitude: '-74.185852',
    timeZoneId: 'America/New_York',
  },
  '10950': {
    zip: '10950',
    city: 'Monroe',
    state: 'NY',
    latitude: '41.338274',
    longitude: '-74.185886',
    timeZoneId: 'America/New_York',
  },
  '10952': {
    zip: '10952',
    city: 'Monsey',
    state: 'NY',
    latitude: '41.114522',
    longitude: '-74.07751',
    timeZoneId: 'America/New_York',
  },
  '10953': {
    zip: '10953',
    city: 'Mountainville',
    state: 'NY',
    latitude: '41.401699',
    longitude: '-74.079126',
    timeZoneId: 'America/New_York',
  },
  '10954': {
    zip: '10954',
    city: 'Nanuet',
    state: 'NY',
    latitude: '41.098338',
    longitude: '-74.009393',
    timeZoneId: 'America/New_York',
  },
  '10956': {
    zip: '10956',
    city: 'New City',
    state: 'NY',
    latitude: '41.144539',
    longitude: '-73.996493',
    timeZoneId: 'America/New_York',
  },
  '10958': {
    zip: '10958',
    city: 'New Hampton',
    state: 'NY',
    latitude: '41.344004',
    longitude: '-74.451815',
    timeZoneId: 'America/New_York',
  },
  '10959': {
    zip: '10959',
    city: 'New Milford',
    state: 'NY',
    latitude: '41.234545',
    longitude: '-74.413065',
    timeZoneId: 'America/New_York',
  },
  '10960': {
    zip: '10960',
    city: 'Nyack',
    state: 'NY',
    latitude: '41.09368',
    longitude: '-73.93213',
    timeZoneId: 'America/New_York',
  },
  '10962': {
    zip: '10962',
    city: 'Orangeburg',
    state: 'NY',
    latitude: '41.047289',
    longitude: '-73.963065',
    timeZoneId: 'America/New_York',
  },
  '10963': {
    zip: '10963',
    city: 'Otisville',
    state: 'NY',
    latitude: '41.465261',
    longitude: '-74.537215',
    timeZoneId: 'America/New_York',
  },
  '10964': {
    zip: '10964',
    city: 'Palisades',
    state: 'NY',
    latitude: '41.012718',
    longitude: '-73.924838',
    timeZoneId: 'America/New_York',
  },
  '10965': {
    zip: '10965',
    city: 'Pearl River',
    state: 'NY',
    latitude: '41.062979',
    longitude: '-74.018824',
    timeZoneId: 'America/New_York',
  },
  '10968': {
    zip: '10968',
    city: 'Piermont',
    state: 'NY',
    latitude: '41.039429',
    longitude: '-73.921498',
    timeZoneId: 'America/New_York',
  },
  '10969': {
    zip: '10969',
    city: 'Pine Island',
    state: 'NY',
    latitude: '41.291561',
    longitude: '-74.492649',
    timeZoneId: 'America/New_York',
  },
  '10970': {
    zip: '10970',
    city: 'Pomona',
    state: 'NY',
    latitude: '41.184834',
    longitude: '-74.046948',
    timeZoneId: 'America/New_York',
  },
  '10973': {
    zip: '10973',
    city: 'Slate Hill',
    state: 'NY',
    latitude: '41.374755',
    longitude: '-74.486379',
    timeZoneId: 'America/New_York',
  },
  '10974': {
    zip: '10974',
    city: 'Sloatsburg',
    state: 'NY',
    latitude: '41.160697',
    longitude: '-74.189154',
    timeZoneId: 'America/New_York',
  },
  '10975': {
    zip: '10975',
    city: 'Southfields',
    state: 'NY',
    latitude: '41.259946',
    longitude: '-74.175595',
    timeZoneId: 'America/New_York',
  },
  '10976': {
    zip: '10976',
    city: 'Sparkill',
    state: 'NY',
    latitude: '41.031057',
    longitude: '-73.927325',
    timeZoneId: 'America/New_York',
  },
  '10977': {
    zip: '10977',
    city: 'Spring Valley',
    state: 'NY',
    latitude: '41.120211',
    longitude: '-74.046736',
    timeZoneId: 'America/New_York',
  },
  '10979': {
    zip: '10979',
    city: 'Sterling Forest',
    state: 'NY',
    latitude: '41.182254',
    longitude: '-74.315748',
    timeZoneId: 'America/New_York',
  },
  '10980': {
    zip: '10980',
    city: 'Stony Point',
    state: 'NY',
    latitude: '41.23644',
    longitude: '-74.01126',
    timeZoneId: 'America/New_York',
  },
  '10981': {
    zip: '10981',
    city: 'Sugar Loaf',
    state: 'NY',
    latitude: '41.323014',
    longitude: '-74.285588',
    timeZoneId: 'America/New_York',
  },
  '10982': {
    zip: '10982',
    city: 'Tallman',
    state: 'NY',
    latitude: '41.106544',
    longitude: '-74.09865',
    timeZoneId: 'America/New_York',
  },
  '10983': {
    zip: '10983',
    city: 'Tappan',
    state: 'NY',
    latitude: '41.0291',
    longitude: '-73.952176',
    timeZoneId: 'America/New_York',
  },
  '10984': {
    zip: '10984',
    city: 'Thiells',
    state: 'NY',
    latitude: '41.206448',
    longitude: '-74.015747',
    timeZoneId: 'America/New_York',
  },
  '10985': {
    zip: '10985',
    city: 'Thompson Ridge',
    state: 'NY',
    latitude: '41.62692',
    longitude: '-74.6343',
    timeZoneId: 'America/New_York',
  },
  '10986': {
    zip: '10986',
    city: 'Tomkins Cove',
    state: 'NY',
    latitude: '41.259488',
    longitude: '-73.990449',
    timeZoneId: 'America/New_York',
  },
  '10987': {
    zip: '10987',
    city: 'Tuxedo Park',
    state: 'NY',
    latitude: '41.200012',
    longitude: '-74.216239',
    timeZoneId: 'America/New_York',
  },
  '10988': {
    zip: '10988',
    city: 'Unionville',
    state: 'NY',
    latitude: '41.303157',
    longitude: '-74.565904',
    timeZoneId: 'America/New_York',
  },
  '10989': {
    zip: '10989',
    city: 'Valley Cottage',
    state: 'NY',
    latitude: '41.12327',
    longitude: '-73.942049',
    timeZoneId: 'America/New_York',
  },
  '10990': {
    zip: '10990',
    city: 'Warwick',
    state: 'NY',
    latitude: '41.263712',
    longitude: '-74.36706',
    timeZoneId: 'America/New_York',
  },
  '10992': {
    zip: '10992',
    city: 'Washingtonville',
    state: 'NY',
    latitude: '41.42496',
    longitude: '-74.17146',
    timeZoneId: 'America/New_York',
  },
  '10993': {
    zip: '10993',
    city: 'West Haverstraw',
    state: 'NY',
    latitude: '41.205428',
    longitude: '-73.983291',
    timeZoneId: 'America/New_York',
  },
  '10994': {
    zip: '10994',
    city: 'West Nyack',
    state: 'NY',
    latitude: '41.099095',
    longitude: '-73.973848',
    timeZoneId: 'America/New_York',
  },
  '10996': {
    zip: '10996',
    city: 'West Point',
    state: 'NY',
    latitude: '41.38867',
    longitude: '-73.982179',
    timeZoneId: 'America/New_York',
  },
  '10997': {
    zip: '10997',
    city: 'West Point',
    state: 'NY',
    latitude: '41.3615',
    longitude: '-74.027051',
    timeZoneId: 'America/New_York',
  },
  '10998': {
    zip: '10998',
    city: 'Westtown',
    state: 'NY',
    latitude: '41.324124',
    longitude: '-74.543313',
    timeZoneId: 'America/New_York',
  },
  '11001': {
    zip: '11001',
    city: 'Floral Park',
    state: 'NY',
    latitude: '40.719635',
    longitude: '-73.706913',
    timeZoneId: 'America/New_York',
  },
  '11002': {
    zip: '11002',
    city: 'Floral Park',
    state: 'NY',
    latitude: '40.720452',
    longitude: '-73.707429',
    timeZoneId: 'America/New_York',
  },
  '11003': {
    zip: '11003',
    city: 'Elmont',
    state: 'NY',
    latitude: '40.699109',
    longitude: '-73.703659',
    timeZoneId: 'America/New_York',
  },
  '11004': {
    zip: '11004',
    city: 'Glen Oaks',
    state: 'NY',
    latitude: '40.747846',
    longitude: '-73.710498',
    timeZoneId: 'America/New_York',
  },
  '11005': {
    zip: '11005',
    city: 'Floral Park',
    state: 'NY',
    latitude: '40.755594',
    longitude: '-73.715036',
    timeZoneId: 'America/New_York',
  },
  '11010': {
    zip: '11010',
    city: 'Franklin Square',
    state: 'NY',
    latitude: '40.705094',
    longitude: '-73.674202',
    timeZoneId: 'America/New_York',
  },
  '11020': {
    zip: '11020',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.786686',
    longitude: '-73.72577',
    timeZoneId: 'America/New_York',
  },
  '11021': {
    zip: '11021',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.788775',
    longitude: '-73.728665',
    timeZoneId: 'America/New_York',
  },
  '11022': {
    zip: '11022',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.787695',
    longitude: '-73.731862',
    timeZoneId: 'America/New_York',
  },
  '11023': {
    zip: '11023',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.798973',
    longitude: '-73.733349',
    timeZoneId: 'America/New_York',
  },
  '11024': {
    zip: '11024',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.80408',
    longitude: '-73.74012',
    timeZoneId: 'America/New_York',
  },
  '11026': {
    zip: '11026',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.791618',
    longitude: '-73.729669',
    timeZoneId: 'America/New_York',
  },
  '11027': {
    zip: '11027',
    city: 'Great Neck',
    state: 'NY',
    latitude: '40.798205',
    longitude: '-73.738285',
    timeZoneId: 'America/New_York',
  },
  '11030': {
    zip: '11030',
    city: 'Manhasset',
    state: 'NY',
    latitude: '40.795533',
    longitude: '-73.684954',
    timeZoneId: 'America/New_York',
  },
  '11040': {
    zip: '11040',
    city: 'New Hyde Park',
    state: 'NY',
    latitude: '40.742887',
    longitude: '-73.676015',
    timeZoneId: 'America/New_York',
  },
  '11042': {
    zip: '11042',
    city: 'New Hyde Park',
    state: 'NY',
    latitude: '40.757711',
    longitude: '-73.697796',
    timeZoneId: 'America/New_York',
  },
  '11050': {
    zip: '11050',
    city: 'Port Washington',
    state: 'NY',
    latitude: '40.843056',
    longitude: '-73.715278',
    timeZoneId: 'America/New_York',
  },
  '11051': {
    zip: '11051',
    city: 'Port Washington',
    state: 'NY',
    latitude: '40.841029',
    longitude: '-73.694052',
    timeZoneId: 'America/New_York',
  },
  '11052': {
    zip: '11052',
    city: 'Port Washington',
    state: 'NY',
    latitude: '40.844414',
    longitude: '-73.701934',
    timeZoneId: 'America/New_York',
  },
  '11053': {
    zip: '11053',
    city: 'Port Washington',
    state: 'NY',
    latitude: '40.839189',
    longitude: '-73.703262',
    timeZoneId: 'America/New_York',
  },
  '11054': {
    zip: '11054',
    city: 'Port Washington',
    state: 'NY',
    latitude: '40.831342',
    longitude: '-73.683314',
    timeZoneId: 'America/New_York',
  },
  '11055': {
    zip: '11055',
    city: 'Port Washington',
    state: 'NY',
    latitude: '40.840893',
    longitude: '-73.704913',
    timeZoneId: 'America/New_York',
  },
  '11096': {
    zip: '11096',
    city: 'Inwood',
    state: 'NY',
    latitude: '40.618459',
    longitude: '-73.748859',
    timeZoneId: 'America/New_York',
  },
  '11101': {
    zip: '11101',
    city: 'Long Island City',
    state: 'NY',
    latitude: '40.74847',
    longitude: '-73.93918',
    timeZoneId: 'America/New_York',
  },
  '11102': {
    zip: '11102',
    city: 'Astoria',
    state: 'NY',
    latitude: '40.770391',
    longitude: '-73.923967',
    timeZoneId: 'America/New_York',
  },
  '11103': {
    zip: '11103',
    city: 'Astoria',
    state: 'NY',
    latitude: '40.762281',
    longitude: '-73.910107',
    timeZoneId: 'America/New_York',
  },
  '11104': {
    zip: '11104',
    city: 'Sunnyside',
    state: 'NY',
    latitude: '40.744648',
    longitude: '-73.917792',
    timeZoneId: 'America/New_York',
  },
  '11105': {
    zip: '11105',
    city: 'Astoria',
    state: 'NY',
    latitude: '40.778285',
    longitude: '-73.910175',
    timeZoneId: 'America/New_York',
  },
  '11106': {
    zip: '11106',
    city: 'Astoria',
    state: 'NY',
    latitude: '40.761688',
    longitude: '-73.930747',
    timeZoneId: 'America/New_York',
  },
  '11109': {
    zip: '11109',
    city: 'Long Island City',
    state: 'NY',
    latitude: '40.742578',
    longitude: '-73.949048',
    timeZoneId: 'America/New_York',
  },
  '11120': {
    zip: '11120',
    city: 'Long Island City',
    state: 'NY',
    latitude: '40.747242',
    longitude: '-73.949347',
    timeZoneId: 'America/New_York',
  },
  '11201': {
    zip: '11201',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.695472',
    longitude: '-73.992723',
    timeZoneId: 'America/New_York',
  },
  '11202': {
    zip: '11202',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.692402',
    longitude: '-73.991159',
    timeZoneId: 'America/New_York',
  },
  '11203': {
    zip: '11203',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.649673',
    longitude: '-73.937666',
    timeZoneId: 'America/New_York',
  },
  '11204': {
    zip: '11204',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.620189',
    longitude: '-73.985706',
    timeZoneId: 'America/New_York',
  },
  '11205': {
    zip: '11205',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.694985',
    longitude: '-73.965575',
    timeZoneId: 'America/New_York',
  },
  '11206': {
    zip: '11206',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.703008',
    longitude: '-73.943208',
    timeZoneId: 'America/New_York',
  },
  '11207': {
    zip: '11207',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.669311',
    longitude: '-73.895528',
    timeZoneId: 'America/New_York',
  },
  '11208': {
    zip: '11208',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.673964',
    longitude: '-73.872964',
    timeZoneId: 'America/New_York',
  },
  '11209': {
    zip: '11209',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.623738',
    longitude: '-74.033806',
    timeZoneId: 'America/New_York',
  },
  '11210': {
    zip: '11210',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.628777',
    longitude: '-73.945814',
    timeZoneId: 'America/New_York',
  },
  '11211': {
    zip: '11211',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.714112',
    longitude: '-73.952354',
    timeZoneId: 'America/New_York',
  },
  '11212': {
    zip: '11212',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.663426',
    longitude: '-73.912354',
    timeZoneId: 'America/New_York',
  },
  '11213': {
    zip: '11213',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.671767',
    longitude: '-73.935934',
    timeZoneId: 'America/New_York',
  },
  '11214': {
    zip: '11214',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.59848',
    longitude: '-73.996892',
    timeZoneId: 'America/New_York',
  },
  '11215': {
    zip: '11215',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.666364',
    longitude: '-73.985457',
    timeZoneId: 'America/New_York',
  },
  '11216': {
    zip: '11216',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.679695',
    longitude: '-73.950292',
    timeZoneId: 'America/New_York',
  },
  '11217': {
    zip: '11217',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.684381',
    longitude: '-73.980437',
    timeZoneId: 'America/New_York',
  },
  '11218': {
    zip: '11218',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.641581',
    longitude: '-73.975901',
    timeZoneId: 'America/New_York',
  },
  '11219': {
    zip: '11219',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.631209',
    longitude: '-73.998416',
    timeZoneId: 'America/New_York',
  },
  '11220': {
    zip: '11220',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.642875',
    longitude: '-74.015564',
    timeZoneId: 'America/New_York',
  },
  '11221': {
    zip: '11221',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.693561',
    longitude: '-73.926509',
    timeZoneId: 'America/New_York',
  },
  '11222': {
    zip: '11222',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.727965',
    longitude: '-73.949305',
    timeZoneId: 'America/New_York',
  },
  '11223': {
    zip: '11223',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.59646',
    longitude: '-73.972916',
    timeZoneId: 'America/New_York',
  },
  '11224': {
    zip: '11224',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.577387',
    longitude: '-73.994493',
    timeZoneId: 'America/New_York',
  },
  '11225': {
    zip: '11225',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.662208',
    longitude: '-73.95527',
    timeZoneId: 'America/New_York',
  },
  '11226': {
    zip: '11226',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.647083',
    longitude: '-73.954915',
    timeZoneId: 'America/New_York',
  },
  '11228': {
    zip: '11228',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.620567',
    longitude: '-74.015141',
    timeZoneId: 'America/New_York',
  },
  '11229': {
    zip: '11229',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.598356',
    longitude: '-73.939325',
    timeZoneId: 'America/New_York',
  },
  '11230': {
    zip: '11230',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.619153',
    longitude: '-73.965619',
    timeZoneId: 'America/New_York',
  },
  '11231': {
    zip: '11231',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.680052',
    longitude: '-74.004522',
    timeZoneId: 'America/New_York',
  },
  '11232': {
    zip: '11232',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.657218',
    longitude: '-74.005878',
    timeZoneId: 'America/New_York',
  },
  '11233': {
    zip: '11233',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.671472',
    longitude: '-73.924489',
    timeZoneId: 'America/New_York',
  },
  '11234': {
    zip: '11234',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.619061',
    longitude: '-73.923116',
    timeZoneId: 'America/New_York',
  },
  '11235': {
    zip: '11235',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.582828',
    longitude: '-73.951811',
    timeZoneId: 'America/New_York',
  },
  '11236': {
    zip: '11236',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.640108',
    longitude: '-73.904411',
    timeZoneId: 'America/New_York',
  },
  '11237': {
    zip: '11237',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.704643',
    longitude: '-73.921609',
    timeZoneId: 'America/New_York',
  },
  '11238': {
    zip: '11238',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.678989',
    longitude: '-73.961819',
    timeZoneId: 'America/New_York',
  },
  '11239': {
    zip: '11239',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.647564',
    longitude: '-73.877052',
    timeZoneId: 'America/New_York',
  },
  '11241': {
    zip: '11241',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.696629',
    longitude: '-73.993851',
    timeZoneId: 'America/New_York',
  },
  '11242': {
    zip: '11242',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.692087',
    longitude: '-73.988815',
    timeZoneId: 'America/New_York',
  },
  '11243': {
    zip: '11243',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.679972',
    longitude: '-73.981434',
    timeZoneId: 'America/New_York',
  },
  '11245': {
    zip: '11245',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.693148',
    longitude: '-73.985871',
    timeZoneId: 'America/New_York',
  },
  '11247': {
    zip: '11247',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.675485',
    longitude: '-73.966404',
    timeZoneId: 'America/New_York',
  },
  '11249': {
    zip: '11249',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.750696',
    longitude: '-74.00069',
    timeZoneId: 'America/New_York',
  },
  '11251': {
    zip: '11251',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.702428',
    longitude: '-73.96741',
    timeZoneId: 'America/New_York',
  },
  '11252': {
    zip: '11252',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.624546',
    longitude: '-74.031281',
    timeZoneId: 'America/New_York',
  },
  '11256': {
    zip: '11256',
    city: 'Brooklyn',
    state: 'NY',
    latitude: '40.663916',
    longitude: '-73.860508',
    timeZoneId: 'America/New_York',
  },
  '11351': {
    zip: '11351',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.781091',
    longitude: '-73.830914',
    timeZoneId: 'America/New_York',
  },
  '11352': {
    zip: '11352',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.753276',
    longitude: '-73.820637',
    timeZoneId: 'America/New_York',
  },
  '11354': {
    zip: '11354',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.770167',
    longitude: '-73.825967',
    timeZoneId: 'America/New_York',
  },
  '11355': {
    zip: '11355',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.754005',
    longitude: '-73.821132',
    timeZoneId: 'America/New_York',
  },
  '11356': {
    zip: '11356',
    city: 'College Point',
    state: 'NY',
    latitude: '40.786',
    longitude: '-73.84407',
    timeZoneId: 'America/New_York',
  },
  '11357': {
    zip: '11357',
    city: 'Whitestone',
    state: 'NY',
    latitude: '40.783792',
    longitude: '-73.812287',
    timeZoneId: 'America/New_York',
  },
  '11358': {
    zip: '11358',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.760434',
    longitude: '-73.799188',
    timeZoneId: 'America/New_York',
  },
  '11359': {
    zip: '11359',
    city: 'Bayside',
    state: 'NY',
    latitude: '40.789436',
    longitude: '-73.777382',
    timeZoneId: 'America/New_York',
  },
  '11360': {
    zip: '11360',
    city: 'Bayside',
    state: 'NY',
    latitude: '40.776438',
    longitude: '-73.78038',
    timeZoneId: 'America/New_York',
  },
  '11361': {
    zip: '11361',
    city: 'Bayside',
    state: 'NY',
    latitude: '40.76476',
    longitude: '-73.77751',
    timeZoneId: 'America/New_York',
  },
  '11362': {
    zip: '11362',
    city: 'Little Neck',
    state: 'NY',
    latitude: '40.76185',
    longitude: '-73.729718',
    timeZoneId: 'America/New_York',
  },
  '11363': {
    zip: '11363',
    city: 'Little Neck',
    state: 'NY',
    latitude: '40.773215',
    longitude: '-73.746899',
    timeZoneId: 'America/New_York',
  },
  '11364': {
    zip: '11364',
    city: 'Oakland Gardens',
    state: 'NY',
    latitude: '40.7465',
    longitude: '-73.761707',
    timeZoneId: 'America/New_York',
  },
  '11365': {
    zip: '11365',
    city: 'Fresh Meadows',
    state: 'NY',
    latitude: '40.738549',
    longitude: '-73.790001',
    timeZoneId: 'America/New_York',
  },
  '11366': {
    zip: '11366',
    city: 'Fresh Meadows',
    state: 'NY',
    latitude: '40.729445',
    longitude: '-73.797794',
    timeZoneId: 'America/New_York',
  },
  '11367': {
    zip: '11367',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.72816',
    longitude: '-73.818582',
    timeZoneId: 'America/New_York',
  },
  '11368': {
    zip: '11368',
    city: 'Corona',
    state: 'NY',
    latitude: '40.752572',
    longitude: '-73.854759',
    timeZoneId: 'America/New_York',
  },
  '11369': {
    zip: '11369',
    city: 'East Elmhurst',
    state: 'NY',
    latitude: '40.765278',
    longitude: '-73.817778',
    timeZoneId: 'America/New_York',
  },
  '11370': {
    zip: '11370',
    city: 'East Elmhurst',
    state: 'NY',
    latitude: '40.760333',
    longitude: '-73.890833',
    timeZoneId: 'America/New_York',
  },
  '11371': {
    zip: '11371',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.775842',
    longitude: '-73.874904',
    timeZoneId: 'America/New_York',
  },
  '11372': {
    zip: '11372',
    city: 'Jackson Heights',
    state: 'NY',
    latitude: '40.749542',
    longitude: '-73.883319',
    timeZoneId: 'America/New_York',
  },
  '11373': {
    zip: '11373',
    city: 'Elmhurst',
    state: 'NY',
    latitude: '40.738937',
    longitude: '-73.87916',
    timeZoneId: 'America/New_York',
  },
  '11374': {
    zip: '11374',
    city: 'Rego Park',
    state: 'NY',
    latitude: '40.727079',
    longitude: '-73.861454',
    timeZoneId: 'America/New_York',
  },
  '11375': {
    zip: '11375',
    city: 'Forest Hills',
    state: 'NY',
    latitude: '40.721216',
    longitude: '-73.85021',
    timeZoneId: 'America/New_York',
  },
  '11377': {
    zip: '11377',
    city: 'Woodside',
    state: 'NY',
    latitude: '40.743107',
    longitude: '-73.906134',
    timeZoneId: 'America/New_York',
  },
  '11378': {
    zip: '11378',
    city: 'Maspeth',
    state: 'NY',
    latitude: '40.725746',
    longitude: '-73.903899',
    timeZoneId: 'America/New_York',
  },
  '11379': {
    zip: '11379',
    city: 'Middle Village',
    state: 'NY',
    latitude: '40.71587',
    longitude: '-73.882583',
    timeZoneId: 'America/New_York',
  },
  '11380': {
    zip: '11380',
    city: 'Elmhurst',
    state: 'NY',
    latitude: '40.742946',
    longitude: '-73.884618',
    timeZoneId: 'America/New_York',
  },
  '11381': {
    zip: '11381',
    city: 'Flushing',
    state: 'NY',
    latitude: '40.715918',
    longitude: '-73.875773',
    timeZoneId: 'America/New_York',
  },
  '11385': {
    zip: '11385',
    city: 'Ridgewood',
    state: 'NY',
    latitude: '40.70286',
    longitude: '-73.87974',
    timeZoneId: 'America/New_York',
  },
  '11386': {
    zip: '11386',
    city: 'Ridgewood',
    state: 'NY',
    latitude: '40.700392',
    longitude: '-73.894731',
    timeZoneId: 'America/New_York',
  },
  '11405': {
    zip: '11405',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.699453',
    longitude: '-73.788562',
    timeZoneId: 'America/New_York',
  },
  '11411': {
    zip: '11411',
    city: 'Cambria Heights',
    state: 'NY',
    latitude: '40.694491',
    longitude: '-73.737461',
    timeZoneId: 'America/New_York',
  },
  '11412': {
    zip: '11412',
    city: 'Saint Albans',
    state: 'NY',
    latitude: '40.694225',
    longitude: '-73.760504',
    timeZoneId: 'America/New_York',
  },
  '11413': {
    zip: '11413',
    city: 'Springfield Gardens',
    state: 'NY',
    latitude: '40.67182',
    longitude: '-73.751834',
    timeZoneId: 'America/New_York',
  },
  '11414': {
    zip: '11414',
    city: 'Howard Beach',
    state: 'NY',
    latitude: '40.658635',
    longitude: '-73.843765',
    timeZoneId: 'America/New_York',
  },
  '11415': {
    zip: '11415',
    city: 'Kew Gardens',
    state: 'NY',
    latitude: '40.708943',
    longitude: '-73.826125',
    timeZoneId: 'America/New_York',
  },
  '11416': {
    zip: '11416',
    city: 'Ozone Park',
    state: 'NY',
    latitude: '40.684494',
    longitude: '-73.850427',
    timeZoneId: 'America/New_York',
  },
  '11417': {
    zip: '11417',
    city: 'Ozone Park',
    state: 'NY',
    latitude: '40.67426',
    longitude: '-73.843796',
    timeZoneId: 'America/New_York',
  },
  '11418': {
    zip: '11418',
    city: 'Richmond Hill',
    state: 'NY',
    latitude: '40.69852',
    longitude: '-73.835575',
    timeZoneId: 'America/New_York',
  },
  '11419': {
    zip: '11419',
    city: 'South Richmond Hill',
    state: 'NY',
    latitude: '40.688921',
    longitude: '-73.823304',
    timeZoneId: 'America/New_York',
  },
  '11420': {
    zip: '11420',
    city: 'South Ozone Park',
    state: 'NY',
    latitude: '40.674361',
    longitude: '-73.813156',
    timeZoneId: 'America/New_York',
  },
  '11421': {
    zip: '11421',
    city: 'Woodhaven',
    state: 'NY',
    latitude: '40.692189',
    longitude: '-73.856141',
    timeZoneId: 'America/New_York',
  },
  '11422': {
    zip: '11422',
    city: 'Rosedale',
    state: 'NY',
    latitude: '40.663176',
    longitude: '-73.733191',
    timeZoneId: 'America/New_York',
  },
  '11423': {
    zip: '11423',
    city: 'Hollis',
    state: 'NY',
    latitude: '40.716723',
    longitude: '-73.76841',
    timeZoneId: 'America/New_York',
  },
  '11424': {
    zip: '11424',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.70655',
    longitude: '-73.828147',
    timeZoneId: 'America/New_York',
  },
  '11425': {
    zip: '11425',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.674365',
    longitude: '-73.771894',
    timeZoneId: 'America/New_York',
  },
  '11426': {
    zip: '11426',
    city: 'Bellerose',
    state: 'NY',
    latitude: '40.733204',
    longitude: '-73.722052',
    timeZoneId: 'America/New_York',
  },
  '11427': {
    zip: '11427',
    city: 'Queens Village',
    state: 'NY',
    latitude: '40.728919',
    longitude: '-73.748586',
    timeZoneId: 'America/New_York',
  },
  '11428': {
    zip: '11428',
    city: 'Queens Village',
    state: 'NY',
    latitude: '40.71999',
    longitude: '-73.740969',
    timeZoneId: 'America/New_York',
  },
  '11429': {
    zip: '11429',
    city: 'Queens Village',
    state: 'NY',
    latitude: '40.711559',
    longitude: '-73.742091',
    timeZoneId: 'America/New_York',
  },
  '11430': {
    zip: '11430',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.661453',
    longitude: '-73.81183',
    timeZoneId: 'America/New_York',
  },
  '11431': {
    zip: '11431',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.717893',
    longitude: '-73.793487',
    timeZoneId: 'America/New_York',
  },
  '11432': {
    zip: '11432',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.715744',
    longitude: '-73.794362',
    timeZoneId: 'America/New_York',
  },
  '11433': {
    zip: '11433',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.697305',
    longitude: '-73.789271',
    timeZoneId: 'America/New_York',
  },
  '11434': {
    zip: '11434',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.677047',
    longitude: '-73.778208',
    timeZoneId: 'America/New_York',
  },
  '11435': {
    zip: '11435',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.699477',
    longitude: '-73.808819',
    timeZoneId: 'America/New_York',
  },
  '11436': {
    zip: '11436',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.678055',
    longitude: '-73.797848',
    timeZoneId: 'America/New_York',
  },
  '11437': {
    zip: '11437',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.678055',
    longitude: '-73.797848',
    timeZoneId: 'America/New_York',
  },
  '11439': {
    zip: '11439',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.722867',
    longitude: '-73.788363',
    timeZoneId: 'America/New_York',
  },
  '11451': {
    zip: '11451',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.699605',
    longitude: '-73.799982',
    timeZoneId: 'America/New_York',
  },
  '11499': {
    zip: '11499',
    city: 'Jamaica',
    state: 'NY',
    latitude: '40.649401',
    longitude: '-73.783733',
    timeZoneId: 'America/New_York',
  },
  '11501': {
    zip: '11501',
    city: 'Mineola',
    state: 'NY',
    latitude: '40.745251',
    longitude: '-73.64111',
    timeZoneId: 'America/New_York',
  },
  '11507': {
    zip: '11507',
    city: 'Albertson',
    state: 'NY',
    latitude: '40.772577',
    longitude: '-73.652127',
    timeZoneId: 'America/New_York',
  },
  '11509': {
    zip: '11509',
    city: 'Atlantic Beach',
    state: 'NY',
    latitude: '40.592092',
    longitude: '-73.728017',
    timeZoneId: 'America/New_York',
  },
  '11510': {
    zip: '11510',
    city: 'Baldwin',
    state: 'NY',
    latitude: '40.655358',
    longitude: '-73.607376',
    timeZoneId: 'America/New_York',
  },
  '11514': {
    zip: '11514',
    city: 'Carle Place',
    state: 'NY',
    latitude: '40.752349',
    longitude: '-73.615237',
    timeZoneId: 'America/New_York',
  },
  '11516': {
    zip: '11516',
    city: 'Cedarhurst',
    state: 'NY',
    latitude: '40.627307',
    longitude: '-73.720608',
    timeZoneId: 'America/New_York',
  },
  '11518': {
    zip: '11518',
    city: 'East Rockaway',
    state: 'NY',
    latitude: '40.641066',
    longitude: '-73.666046',
    timeZoneId: 'America/New_York',
  },
  '11520': {
    zip: '11520',
    city: 'Freeport',
    state: 'NY',
    latitude: '40.652023',
    longitude: '-73.588242',
    timeZoneId: 'America/New_York',
  },
  '11530': {
    zip: '11530',
    city: 'Garden City',
    state: 'NY',
    latitude: '40.719167',
    longitude: '-73.688889',
    timeZoneId: 'America/New_York',
  },
  '11531': {
    zip: '11531',
    city: 'Garden City',
    state: 'NY',
    latitude: '40.721588',
    longitude: '-73.632436',
    timeZoneId: 'America/New_York',
  },
  '11542': {
    zip: '11542',
    city: 'Glen Cove',
    state: 'NY',
    latitude: '40.865104',
    longitude: '-73.62706',
    timeZoneId: 'America/New_York',
  },
  '11545': {
    zip: '11545',
    city: 'Glen Head',
    state: 'NY',
    latitude: '40.816389',
    longitude: '-73.6375',
    timeZoneId: 'America/New_York',
  },
  '11547': {
    zip: '11547',
    city: 'Glenwood Landing',
    state: 'NY',
    latitude: '40.829786',
    longitude: '-73.644155',
    timeZoneId: 'America/New_York',
  },
  '11548': {
    zip: '11548',
    city: 'Greenvale',
    state: 'NY',
    latitude: '40.793611',
    longitude: '-73.6275',
    timeZoneId: 'America/New_York',
  },
  '11549': {
    zip: '11549',
    city: 'Hempstead',
    state: 'NY',
    latitude: '40.705686',
    longitude: '-73.618785',
    timeZoneId: 'America/New_York',
  },
  '11550': {
    zip: '11550',
    city: 'Hempstead',
    state: 'NY',
    latitude: '40.708785',
    longitude: '-73.624573',
    timeZoneId: 'America/New_York',
  },
  '11551': {
    zip: '11551',
    city: 'Hempstead',
    state: 'NY',
    latitude: '40.703341',
    longitude: '-73.628577',
    timeZoneId: 'America/New_York',
  },
  '11552': {
    zip: '11552',
    city: 'West Hempstead',
    state: 'NY',
    latitude: '40.685278',
    longitude: '-73.653056',
    timeZoneId: 'America/New_York',
  },
  '11553': {
    zip: '11553',
    city: 'Uniondale',
    state: 'NY',
    latitude: '40.703352',
    longitude: '-73.590714',
    timeZoneId: 'America/New_York',
  },
  '11554': {
    zip: '11554',
    city: 'East Meadow',
    state: 'NY',
    latitude: '40.715698',
    longitude: '-73.556154',
    timeZoneId: 'America/New_York',
  },
  '11555': {
    zip: '11555',
    city: 'Uniondale',
    state: 'NY',
    latitude: '40.705942',
    longitude: '-73.595749',
    timeZoneId: 'America/New_York',
  },
  '11556': {
    zip: '11556',
    city: 'Uniondale',
    state: 'NY',
    latitude: '40.701858',
    longitude: '-73.620203',
    timeZoneId: 'America/New_York',
  },
  '11557': {
    zip: '11557',
    city: 'Hewlett',
    state: 'NY',
    latitude: '40.638779',
    longitude: '-73.693176',
    timeZoneId: 'America/New_York',
  },
  '11558': {
    zip: '11558',
    city: 'Island Park',
    state: 'NY',
    latitude: '40.600901',
    longitude: '-73.654227',
    timeZoneId: 'America/New_York',
  },
  '11559': {
    zip: '11559',
    city: 'Lawrence',
    state: 'NY',
    latitude: '40.611795',
    longitude: '-73.732344',
    timeZoneId: 'America/New_York',
  },
  '11560': {
    zip: '11560',
    city: 'Locust Valley',
    state: 'NY',
    latitude: '40.8771',
    longitude: '-73.61142',
    timeZoneId: 'America/New_York',
  },
  '11561': {
    zip: '11561',
    city: 'Long Beach',
    state: 'NY',
    latitude: '40.585753',
    longitude: '-73.657497',
    timeZoneId: 'America/New_York',
  },
  '11563': {
    zip: '11563',
    city: 'Lynbrook',
    state: 'NY',
    latitude: '40.659727',
    longitude: '-73.675918',
    timeZoneId: 'America/New_York',
  },
  '11565': {
    zip: '11565',
    city: 'Malverne',
    state: 'NY',
    latitude: '40.678396',
    longitude: '-73.671004',
    timeZoneId: 'America/New_York',
  },
  '11566': {
    zip: '11566',
    city: 'Merrick',
    state: 'NY',
    latitude: '40.685085',
    longitude: '-73.56071',
    timeZoneId: 'America/New_York',
  },
  '11568': {
    zip: '11568',
    city: 'Old Westbury',
    state: 'NY',
    latitude: '40.78278',
    longitude: '-73.59024',
    timeZoneId: 'America/New_York',
  },
  '11569': {
    zip: '11569',
    city: 'Point Lookout',
    state: 'NY',
    latitude: '40.593413',
    longitude: '-73.580963',
    timeZoneId: 'America/New_York',
  },
  '11570': {
    zip: '11570',
    city: 'Rockville Centre',
    state: 'NY',
    latitude: '40.665099',
    longitude: '-73.637798',
    timeZoneId: 'America/New_York',
  },
  '11571': {
    zip: '11571',
    city: 'Rockville Centre',
    state: 'NY',
    latitude: '40.657461',
    longitude: '-73.648593',
    timeZoneId: 'America/New_York',
  },
  '11572': {
    zip: '11572',
    city: 'Oceanside',
    state: 'NY',
    latitude: '40.658611',
    longitude: '-73.641667',
    timeZoneId: 'America/New_York',
  },
  '11575': {
    zip: '11575',
    city: 'Roosevelt',
    state: 'NY',
    latitude: '40.679893',
    longitude: '-73.59143',
    timeZoneId: 'America/New_York',
  },
  '11576': {
    zip: '11576',
    city: 'Roslyn',
    state: 'NY',
    latitude: '40.774722',
    longitude: '-73.656111',
    timeZoneId: 'America/New_York',
  },
  '11577': {
    zip: '11577',
    city: 'Roslyn Heights',
    state: 'NY',
    latitude: '40.785766',
    longitude: '-73.637013',
    timeZoneId: 'America/New_York',
  },
  '11579': {
    zip: '11579',
    city: 'Sea Cliff',
    state: 'NY',
    latitude: '40.84499',
    longitude: '-73.642613',
    timeZoneId: 'America/New_York',
  },
  '11580': {
    zip: '11580',
    city: 'Valley Stream',
    state: 'NY',
    latitude: '40.674214',
    longitude: '-73.709056',
    timeZoneId: 'America/New_York',
  },
  '11581': {
    zip: '11581',
    city: 'Valley Stream',
    state: 'NY',
    latitude: '40.65543',
    longitude: '-73.712757',
    timeZoneId: 'America/New_York',
  },
  '11582': {
    zip: '11582',
    city: 'Valley Stream',
    state: 'NY',
    latitude: '40.664547',
    longitude: '-73.704277',
    timeZoneId: 'America/New_York',
  },
  '11590': {
    zip: '11590',
    city: 'Westbury',
    state: 'NY',
    latitude: '40.757311',
    longitude: '-73.577498',
    timeZoneId: 'America/New_York',
  },
  '11596': {
    zip: '11596',
    city: 'Williston Park',
    state: 'NY',
    latitude: '40.757885',
    longitude: '-73.644633',
    timeZoneId: 'America/New_York',
  },
  '11598': {
    zip: '11598',
    city: 'Woodmere',
    state: 'NY',
    latitude: '40.633418',
    longitude: '-73.714284',
    timeZoneId: 'America/New_York',
  },
  '11599': {
    zip: '11599',
    city: 'Garden City',
    state: 'NY',
    latitude: '40.724849',
    longitude: '-73.632559',
    timeZoneId: 'America/New_York',
  },
  '11690': {
    zip: '11690',
    city: 'Far Rockaway',
    state: 'NY',
    latitude: '40.601071',
    longitude: '-73.762108',
    timeZoneId: 'America/New_York',
  },
  '11691': {
    zip: '11691',
    city: 'Far Rockaway',
    state: 'NY',
    latitude: '40.602157',
    longitude: '-73.760226',
    timeZoneId: 'America/New_York',
  },
  '11692': {
    zip: '11692',
    city: 'Arverne',
    state: 'NY',
    latitude: '40.590874',
    longitude: '-73.796576',
    timeZoneId: 'America/New_York',
  },
  '11693': {
    zip: '11693',
    city: 'Far Rockaway',
    state: 'NY',
    latitude: '40.589797',
    longitude: '-73.813567',
    timeZoneId: 'America/New_York',
  },
  '11694': {
    zip: '11694',
    city: 'Rockaway Park',
    state: 'NY',
    latitude: '40.576767',
    longitude: '-73.84416',
    timeZoneId: 'America/New_York',
  },
  '11695': {
    zip: '11695',
    city: 'Far Rockaway',
    state: 'NY',
    latitude: '40.558349',
    longitude: '-73.911454',
    timeZoneId: 'America/New_York',
  },
  '11697': {
    zip: '11697',
    city: 'Breezy Point',
    state: 'NY',
    latitude: '40.556389',
    longitude: '-73.926667',
    timeZoneId: 'America/New_York',
  },
  '11701': {
    zip: '11701',
    city: 'Amityville',
    state: 'NY',
    latitude: '40.686293',
    longitude: '-73.412179',
    timeZoneId: 'America/New_York',
  },
  '11702': {
    zip: '11702',
    city: 'Babylon',
    state: 'NY',
    latitude: '40.694282',
    longitude: '-73.327359',
    timeZoneId: 'America/New_York',
  },
  '11703': {
    zip: '11703',
    city: 'North Babylon',
    state: 'NY',
    latitude: '40.732426',
    longitude: '-73.324916',
    timeZoneId: 'America/New_York',
  },
  '11704': {
    zip: '11704',
    city: 'West Babylon',
    state: 'NY',
    latitude: '40.709193',
    longitude: '-73.371464',
    timeZoneId: 'America/New_York',
  },
  '11705': {
    zip: '11705',
    city: 'Bayport',
    state: 'NY',
    latitude: '40.746015',
    longitude: '-73.053348',
    timeZoneId: 'America/New_York',
  },
  '11706': {
    zip: '11706',
    city: 'Bay Shore',
    state: 'NY',
    latitude: '40.636111',
    longitude: '-73.197222',
    timeZoneId: 'America/New_York',
  },
  '11707': {
    zip: '11707',
    city: 'West Babylon',
    state: 'NY',
    latitude: '40.7004',
    longitude: '-73.327799',
    timeZoneId: 'America/New_York',
  },
  '11709': {
    zip: '11709',
    city: 'Bayville',
    state: 'NY',
    latitude: '40.907634',
    longitude: '-73.560621',
    timeZoneId: 'America/New_York',
  },
  '11710': {
    zip: '11710',
    city: 'Bellmore',
    state: 'NY',
    latitude: '40.679031',
    longitude: '-73.534402',
    timeZoneId: 'America/New_York',
  },
  '11713': {
    zip: '11713',
    city: 'Bellport',
    state: 'NY',
    latitude: '40.769052',
    longitude: '-72.9426',
    timeZoneId: 'America/New_York',
  },
  '11714': {
    zip: '11714',
    city: 'Bethpage',
    state: 'NY',
    latitude: '40.73854',
    longitude: '-73.488866',
    timeZoneId: 'America/New_York',
  },
  '11715': {
    zip: '11715',
    city: 'Blue Point',
    state: 'NY',
    latitude: '40.750866',
    longitude: '-73.034128',
    timeZoneId: 'America/New_York',
  },
  '11716': {
    zip: '11716',
    city: 'Bohemia',
    state: 'NY',
    latitude: '40.770237',
    longitude: '-73.112442',
    timeZoneId: 'America/New_York',
  },
  '11717': {
    zip: '11717',
    city: 'Brentwood',
    state: 'NY',
    latitude: '40.78092',
    longitude: '-73.25454',
    timeZoneId: 'America/New_York',
  },
  '11718': {
    zip: '11718',
    city: 'Brightwaters',
    state: 'NY',
    latitude: '40.719074',
    longitude: '-73.267235',
    timeZoneId: 'America/New_York',
  },
  '11719': {
    zip: '11719',
    city: 'Brookhaven',
    state: 'NY',
    latitude: '40.779227',
    longitude: '-72.915009',
    timeZoneId: 'America/New_York',
  },
  '11720': {
    zip: '11720',
    city: 'Centereach',
    state: 'NY',
    latitude: '40.871518',
    longitude: '-73.084611',
    timeZoneId: 'America/New_York',
  },
  '11721': {
    zip: '11721',
    city: 'Centerport',
    state: 'NY',
    latitude: '40.890176',
    longitude: '-73.372822',
    timeZoneId: 'America/New_York',
  },
  '11722': {
    zip: '11722',
    city: 'Central Islip',
    state: 'NY',
    latitude: '40.77912',
    longitude: '-73.19142',
    timeZoneId: 'America/New_York',
  },
  '11724': {
    zip: '11724',
    city: 'Cold Spring Harbor',
    state: 'NY',
    latitude: '40.866028',
    longitude: '-73.450581',
    timeZoneId: 'America/New_York',
  },
  '11725': {
    zip: '11725',
    city: 'Commack',
    state: 'NY',
    latitude: '40.837825',
    longitude: '-73.281154',
    timeZoneId: 'America/New_York',
  },
  '11726': {
    zip: '11726',
    city: 'Copiague',
    state: 'NY',
    latitude: '40.680826',
    longitude: '-73.393642',
    timeZoneId: 'America/New_York',
  },
  '11727': {
    zip: '11727',
    city: 'Coram',
    state: 'NY',
    latitude: '40.885609',
    longitude: '-73.006703',
    timeZoneId: 'America/New_York',
  },
  '11729': {
    zip: '11729',
    city: 'Deer Park',
    state: 'NY',
    latitude: '40.759058',
    longitude: '-73.325501',
    timeZoneId: 'America/New_York',
  },
  '11730': {
    zip: '11730',
    city: 'East Islip',
    state: 'NY',
    latitude: '40.728521',
    longitude: '-73.186687',
    timeZoneId: 'America/New_York',
  },
  '11731': {
    zip: '11731',
    city: 'East Northport',
    state: 'NY',
    latitude: '40.864288',
    longitude: '-73.317866',
    timeZoneId: 'America/New_York',
  },
  '11732': {
    zip: '11732',
    city: 'East Norwich',
    state: 'NY',
    latitude: '40.846511',
    longitude: '-73.536189',
    timeZoneId: 'America/New_York',
  },
  '11733': {
    zip: '11733',
    city: 'East Setauket',
    state: 'NY',
    latitude: '40.93285',
    longitude: '-73.108934',
    timeZoneId: 'America/New_York',
  },
  '11735': {
    zip: '11735',
    city: 'Farmingdale',
    state: 'NY',
    latitude: '40.725297',
    longitude: '-73.444737',
    timeZoneId: 'America/New_York',
  },
  '11737': {
    zip: '11737',
    city: 'Farmingdale',
    state: 'NY',
    latitude: '40.734914',
    longitude: '-73.4456',
    timeZoneId: 'America/New_York',
  },
  '11738': {
    zip: '11738',
    city: 'Farmingville',
    state: 'NY',
    latitude: '40.837113',
    longitude: '-73.044352',
    timeZoneId: 'America/New_York',
  },
  '11739': {
    zip: '11739',
    city: 'Great River',
    state: 'NY',
    latitude: '40.726303',
    longitude: '-73.157742',
    timeZoneId: 'America/New_York',
  },
  '11740': {
    zip: '11740',
    city: 'Greenlawn',
    state: 'NY',
    latitude: '40.859896',
    longitude: '-73.362616',
    timeZoneId: 'America/New_York',
  },
  '11741': {
    zip: '11741',
    city: 'Holbrook',
    state: 'NY',
    latitude: '40.798188',
    longitude: '-73.076253',
    timeZoneId: 'America/New_York',
  },
  '11742': {
    zip: '11742',
    city: 'Holtsville',
    state: 'NY',
    latitude: '40.810612',
    longitude: '-73.043845',
    timeZoneId: 'America/New_York',
  },
  '11743': {
    zip: '11743',
    city: 'Huntington',
    state: 'NY',
    latitude: '40.869083',
    longitude: '-73.4093',
    timeZoneId: 'America/New_York',
  },
  '11746': {
    zip: '11746',
    city: 'Huntington Station',
    state: 'NY',
    latitude: '40.817398',
    longitude: '-73.369148',
    timeZoneId: 'America/New_York',
  },
  '11747': {
    zip: '11747',
    city: 'Melville',
    state: 'NY',
    latitude: '40.803692',
    longitude: '-73.402157',
    timeZoneId: 'America/New_York',
  },
  '11749': {
    zip: '11749',
    city: 'Islandia',
    state: 'NY',
    latitude: '40.809863',
    longitude: '-73.179536',
    timeZoneId: 'America/New_York',
  },
  '11751': {
    zip: '11751',
    city: 'Islip',
    state: 'NY',
    latitude: '40.732034',
    longitude: '-73.213359',
    timeZoneId: 'America/New_York',
  },
  '11752': {
    zip: '11752',
    city: 'Islip Terrace',
    state: 'NY',
    latitude: '40.751159',
    longitude: '-73.184614',
    timeZoneId: 'America/New_York',
  },
  '11753': {
    zip: '11753',
    city: 'Jericho',
    state: 'NY',
    latitude: '40.784785',
    longitude: '-73.5355',
    timeZoneId: 'America/New_York',
  },
  '11754': {
    zip: '11754',
    city: 'Kings Park',
    state: 'NY',
    latitude: '40.886446',
    longitude: '-73.244857',
    timeZoneId: 'America/New_York',
  },
  '11755': {
    zip: '11755',
    city: 'Lake Grove',
    state: 'NY',
    latitude: '40.858261',
    longitude: '-73.117533',
    timeZoneId: 'America/New_York',
  },
  '11756': {
    zip: '11756',
    city: 'Levittown',
    state: 'NY',
    latitude: '40.726315',
    longitude: '-73.517312',
    timeZoneId: 'America/New_York',
  },
  '11757': {
    zip: '11757',
    city: 'Lindenhurst',
    state: 'NY',
    latitude: '40.688951',
    longitude: '-73.376317',
    timeZoneId: 'America/New_York',
  },
  '11758': {
    zip: '11758',
    city: 'Massapequa',
    state: 'NY',
    latitude: '40.686884',
    longitude: '-73.469397',
    timeZoneId: 'America/New_York',
  },
  '11760': {
    zip: '11760',
    city: 'Melville',
    state: 'NY',
    latitude: '40.816034',
    longitude: '-73.212013',
    timeZoneId: 'America/New_York',
  },
  '11762': {
    zip: '11762',
    city: 'Massapequa Park',
    state: 'NY',
    latitude: '40.683043',
    longitude: '-73.446969',
    timeZoneId: 'America/New_York',
  },
  '11763': {
    zip: '11763',
    city: 'Medford',
    state: 'NY',
    latitude: '40.82592',
    longitude: '-72.9861',
    timeZoneId: 'America/New_York',
  },
  '11764': {
    zip: '11764',
    city: 'Miller Place',
    state: 'NY',
    latitude: '40.946583',
    longitude: '-72.995207',
    timeZoneId: 'America/New_York',
  },
  '11765': {
    zip: '11765',
    city: 'Mill Neck',
    state: 'NY',
    latitude: '40.885753',
    longitude: '-73.553815',
    timeZoneId: 'America/New_York',
  },
  '11766': {
    zip: '11766',
    city: 'Mount Sinai',
    state: 'NY',
    latitude: '40.931723',
    longitude: '-73.012963',
    timeZoneId: 'America/New_York',
  },
  '11767': {
    zip: '11767',
    city: 'Nesconset',
    state: 'NY',
    latitude: '40.843134',
    longitude: '-73.147736',
    timeZoneId: 'America/New_York',
  },
  '11768': {
    zip: '11768',
    city: 'Northport',
    state: 'NY',
    latitude: '40.91382',
    longitude: '-73.3335',
    timeZoneId: 'America/New_York',
  },
  '11769': {
    zip: '11769',
    city: 'Oakdale',
    state: 'NY',
    latitude: '40.739823',
    longitude: '-73.133122',
    timeZoneId: 'America/New_York',
  },
  '11770': {
    zip: '11770',
    city: 'Ocean Beach',
    state: 'NY',
    latitude: '40.647098',
    longitude: '-73.151777',
    timeZoneId: 'America/New_York',
  },
  '11771': {
    zip: '11771',
    city: 'Oyster Bay',
    state: 'NY',
    latitude: '40.866007',
    longitude: '-73.526305',
    timeZoneId: 'America/New_York',
  },
  '11772': {
    zip: '11772',
    city: 'Patchogue',
    state: 'NY',
    latitude: '40.77504',
    longitude: '-72.89574',
    timeZoneId: 'America/New_York',
  },
  '11773': {
    zip: '11773',
    city: 'Melville',
    state: 'NY',
    latitude: '40.828208',
    longitude: '-73.503625',
    timeZoneId: 'America/New_York',
  },
  '11775': {
    zip: '11775',
    city: 'Melville',
    state: 'NY',
    latitude: '40.793182',
    longitude: '-73.41794',
    timeZoneId: 'America/New_York',
  },
  '11776': {
    zip: '11776',
    city: 'Port Jefferson Station',
    state: 'NY',
    latitude: '40.90984',
    longitude: '-73.046164',
    timeZoneId: 'America/New_York',
  },
  '11777': {
    zip: '11777',
    city: 'Port Jefferson',
    state: 'NY',
    latitude: '40.925278',
    longitude: '-73.047778',
    timeZoneId: 'America/New_York',
  },
  '11778': {
    zip: '11778',
    city: 'Rocky Point',
    state: 'NY',
    latitude: '40.949705',
    longitude: '-72.93642',
    timeZoneId: 'America/New_York',
  },
  '11779': {
    zip: '11779',
    city: 'Ronkonkoma',
    state: 'NY',
    latitude: '40.81812',
    longitude: '-73.1208',
    timeZoneId: 'America/New_York',
  },
  '11780': {
    zip: '11780',
    city: 'Saint James',
    state: 'NY',
    latitude: '40.89144',
    longitude: '-73.16928',
    timeZoneId: 'America/New_York',
  },
  '11782': {
    zip: '11782',
    city: 'Sayville',
    state: 'NY',
    latitude: '40.746049',
    longitude: '-73.080662',
    timeZoneId: 'America/New_York',
  },
  '11783': {
    zip: '11783',
    city: 'Seaford',
    state: 'NY',
    latitude: '40.679021',
    longitude: '-73.487144',
    timeZoneId: 'America/New_York',
  },
  '11784': {
    zip: '11784',
    city: 'Selden',
    state: 'NY',
    latitude: '40.871531',
    longitude: '-73.043571',
    timeZoneId: 'America/New_York',
  },
  '11786': {
    zip: '11786',
    city: 'Shoreham',
    state: 'NY',
    latitude: '40.949365',
    longitude: '-72.891529',
    timeZoneId: 'America/New_York',
  },
  '11787': {
    zip: '11787',
    city: 'Smithtown',
    state: 'NY',
    latitude: '40.855292',
    longitude: '-73.209738',
    timeZoneId: 'America/New_York',
  },
  '11788': {
    zip: '11788',
    city: 'Hauppauge',
    state: 'NY',
    latitude: '40.816902',
    longitude: '-73.210989',
    timeZoneId: 'America/New_York',
  },
  '11789': {
    zip: '11789',
    city: 'Sound Beach',
    state: 'NY',
    latitude: '40.955253',
    longitude: '-72.973851',
    timeZoneId: 'America/New_York',
  },
  '11790': {
    zip: '11790',
    city: 'Stony Brook',
    state: 'NY',
    latitude: '40.905287',
    longitude: '-73.125351',
    timeZoneId: 'America/New_York',
  },
  '11791': {
    zip: '11791',
    city: 'Syosset',
    state: 'NY',
    latitude: '40.813797',
    longitude: '-73.502844',
    timeZoneId: 'America/New_York',
  },
  '11792': {
    zip: '11792',
    city: 'Wading River',
    state: 'NY',
    latitude: '40.94544',
    longitude: '-72.83502',
    timeZoneId: 'America/New_York',
  },
  '11793': {
    zip: '11793',
    city: 'Wantagh',
    state: 'NY',
    latitude: '40.685767',
    longitude: '-73.51402',
    timeZoneId: 'America/New_York',
  },
  '11794': {
    zip: '11794',
    city: 'Stony Brook',
    state: 'NY',
    latitude: '40.914561',
    longitude: '-73.125169',
    timeZoneId: 'America/New_York',
  },
  '11795': {
    zip: '11795',
    city: 'West Islip',
    state: 'NY',
    latitude: '40.709304',
    longitude: '-73.299311',
    timeZoneId: 'America/New_York',
  },
  '11796': {
    zip: '11796',
    city: 'West Sayville',
    state: 'NY',
    latitude: '40.732739',
    longitude: '-73.100528',
    timeZoneId: 'America/New_York',
  },
  '11797': {
    zip: '11797',
    city: 'Woodbury',
    state: 'NY',
    latitude: '40.81381',
    longitude: '-73.468323',
    timeZoneId: 'America/New_York',
  },
  '11798': {
    zip: '11798',
    city: 'Wyandanch',
    state: 'NY',
    latitude: '40.756804',
    longitude: '-73.364139',
    timeZoneId: 'America/New_York',
  },
  '11801': {
    zip: '11801',
    city: 'Hicksville',
    state: 'NY',
    latitude: '40.766663',
    longitude: '-73.51647',
    timeZoneId: 'America/New_York',
  },
  '11802': {
    zip: '11802',
    city: 'Hicksville',
    state: 'NY',
    latitude: '40.764323',
    longitude: '-73.531618',
    timeZoneId: 'America/New_York',
  },
  '11803': {
    zip: '11803',
    city: 'Plainview',
    state: 'NY',
    latitude: '40.780517',
    longitude: '-73.478813',
    timeZoneId: 'America/New_York',
  },
  '11804': {
    zip: '11804',
    city: 'Old Bethpage',
    state: 'NY',
    latitude: '40.768333',
    longitude: '-73.525556',
    timeZoneId: 'America/New_York',
  },
  '11815': {
    zip: '11815',
    city: 'Hicksville',
    state: 'NY',
    latitude: '40.768318',
    longitude: '-73.514148',
    timeZoneId: 'America/New_York',
  },
  '11853': {
    zip: '11853',
    city: 'Jericho',
    state: 'NY',
    latitude: '40.786573',
    longitude: '-73.542199',
    timeZoneId: 'America/New_York',
  },
  '11901': {
    zip: '11901',
    city: 'Riverhead',
    state: 'NY',
    latitude: '40.928084',
    longitude: '-72.65583',
    timeZoneId: 'America/New_York',
  },
  '11930': {
    zip: '11930',
    city: 'Amagansett',
    state: 'NY',
    latitude: '40.976299',
    longitude: '-72.13367',
    timeZoneId: 'America/New_York',
  },
  '11931': {
    zip: '11931',
    city: 'Aquebogue',
    state: 'NY',
    latitude: '40.946526',
    longitude: '-72.598308',
    timeZoneId: 'America/New_York',
  },
  '11932': {
    zip: '11932',
    city: 'Bridgehampton',
    state: 'NY',
    latitude: '40.962338',
    longitude: '-72.306224',
    timeZoneId: 'America/New_York',
  },
  '11933': {
    zip: '11933',
    city: 'Calverton',
    state: 'NY',
    latitude: '40.93446',
    longitude: '-72.76914',
    timeZoneId: 'America/New_York',
  },
  '11934': {
    zip: '11934',
    city: 'Center Moriches',
    state: 'NY',
    latitude: '40.800258',
    longitude: '-72.796529',
    timeZoneId: 'America/New_York',
  },
  '11935': {
    zip: '11935',
    city: 'Cutchogue',
    state: 'NY',
    latitude: '41.015049',
    longitude: '-72.472897',
    timeZoneId: 'America/New_York',
  },
  '11937': {
    zip: '11937',
    city: 'East Hampton',
    state: 'NY',
    latitude: '41.004735',
    longitude: '-72.178804',
    timeZoneId: 'America/New_York',
  },
  '11939': {
    zip: '11939',
    city: 'East Marion',
    state: 'NY',
    latitude: '41.128421',
    longitude: '-72.341905',
    timeZoneId: 'America/New_York',
  },
  '11940': {
    zip: '11940',
    city: 'East Moriches',
    state: 'NY',
    latitude: '40.80849',
    longitude: '-72.75228',
    timeZoneId: 'America/New_York',
  },
  '11941': {
    zip: '11941',
    city: 'Eastport',
    state: 'NY',
    latitude: '40.830437',
    longitude: '-72.731567',
    timeZoneId: 'America/New_York',
  },
  '11942': {
    zip: '11942',
    city: 'East Quogue',
    state: 'NY',
    latitude: '40.847122',
    longitude: '-72.573666',
    timeZoneId: 'America/New_York',
  },
  '11944': {
    zip: '11944',
    city: 'Greenport',
    state: 'NY',
    latitude: '41.102895',
    longitude: '-72.370978',
    timeZoneId: 'America/New_York',
  },
  '11946': {
    zip: '11946',
    city: 'Hampton Bays',
    state: 'NY',
    latitude: '40.883963',
    longitude: '-72.532083',
    timeZoneId: 'America/New_York',
  },
  '11947': {
    zip: '11947',
    city: 'Jamesport',
    state: 'NY',
    latitude: '40.946167',
    longitude: '-72.583098',
    timeZoneId: 'America/New_York',
  },
  '11948': {
    zip: '11948',
    city: 'Laurel',
    state: 'NY',
    latitude: '40.963392',
    longitude: '-72.548573',
    timeZoneId: 'America/New_York',
  },
  '11949': {
    zip: '11949',
    city: 'Manorville',
    state: 'NY',
    latitude: '40.85218',
    longitude: '-72.788976',
    timeZoneId: 'America/New_York',
  },
  '11950': {
    zip: '11950',
    city: 'Mastic',
    state: 'NY',
    latitude: '40.806599',
    longitude: '-72.840193',
    timeZoneId: 'America/New_York',
  },
  '11951': {
    zip: '11951',
    city: 'Mastic Beach',
    state: 'NY',
    latitude: '40.764527',
    longitude: '-72.843517',
    timeZoneId: 'America/New_York',
  },
  '11952': {
    zip: '11952',
    city: 'Mattituck',
    state: 'NY',
    latitude: '40.993754',
    longitude: '-72.539624',
    timeZoneId: 'America/New_York',
  },
  '11953': {
    zip: '11953',
    city: 'Middle Island',
    state: 'NY',
    latitude: '40.87772',
    longitude: '-72.955011',
    timeZoneId: 'America/New_York',
  },
  '11954': {
    zip: '11954',
    city: 'Montauk',
    state: 'NY',
    latitude: '41.041068',
    longitude: '-71.950598',
    timeZoneId: 'America/New_York',
  },
  '11955': {
    zip: '11955',
    city: 'Moriches',
    state: 'NY',
    latitude: '40.804137',
    longitude: '-72.820828',
    timeZoneId: 'America/New_York',
  },
  '11956': {
    zip: '11956',
    city: 'New Suffolk',
    state: 'NY',
    latitude: '40.992282',
    longitude: '-72.477555',
    timeZoneId: 'America/New_York',
  },
  '11957': {
    zip: '11957',
    city: 'Orient',
    state: 'NY',
    latitude: '41.15424',
    longitude: '-72.24975',
    timeZoneId: 'America/New_York',
  },
  '11958': {
    zip: '11958',
    city: 'Peconic',
    state: 'NY',
    latitude: '41.04158',
    longitude: '-72.458859',
    timeZoneId: 'America/New_York',
  },
  '11959': {
    zip: '11959',
    city: 'Quogue',
    state: 'NY',
    latitude: '40.823016',
    longitude: '-72.601639',
    timeZoneId: 'America/New_York',
  },
  '11960': {
    zip: '11960',
    city: 'Remsenburg',
    state: 'NY',
    latitude: '40.812687',
    longitude: '-72.695052',
    timeZoneId: 'America/New_York',
  },
  '11961': {
    zip: '11961',
    city: 'Ridge',
    state: 'NY',
    latitude: '40.8969',
    longitude: '-72.88434',
    timeZoneId: 'America/New_York',
  },
  '11962': {
    zip: '11962',
    city: 'Sagaponack',
    state: 'NY',
    latitude: '40.950826',
    longitude: '-72.287798',
    timeZoneId: 'America/New_York',
  },
  '11963': {
    zip: '11963',
    city: 'Sag Harbor',
    state: 'NY',
    latitude: '40.97244',
    longitude: '-72.32454',
    timeZoneId: 'America/New_York',
  },
  '11964': {
    zip: '11964',
    city: 'Shelter Island',
    state: 'NY',
    latitude: '41.067294',
    longitude: '-72.337204',
    timeZoneId: 'America/New_York',
  },
  '11965': {
    zip: '11965',
    city: 'Shelter Island Heights',
    state: 'NY',
    latitude: '41.075213',
    longitude: '-72.354368',
    timeZoneId: 'America/New_York',
  },
  '11967': {
    zip: '11967',
    city: 'Shirley',
    state: 'NY',
    latitude: '40.795028',
    longitude: '-72.869264',
    timeZoneId: 'America/New_York',
  },
  '11968': {
    zip: '11968',
    city: 'Southampton',
    state: 'NY',
    latitude: '40.907565',
    longitude: '-72.411077',
    timeZoneId: 'America/New_York',
  },
  '11969': {
    zip: '11969',
    city: 'Southampton',
    state: 'NY',
    latitude: '40.965942',
    longitude: '-72.357568',
    timeZoneId: 'America/New_York',
  },
  '11970': {
    zip: '11970',
    city: 'South Jamesport',
    state: 'NY',
    latitude: '40.936611',
    longitude: '-72.579128',
    timeZoneId: 'America/New_York',
  },
  '11971': {
    zip: '11971',
    city: 'Southold',
    state: 'NY',
    latitude: '41.057698',
    longitude: '-72.415683',
    timeZoneId: 'America/New_York',
  },
  '11972': {
    zip: '11972',
    city: 'Speonk',
    state: 'NY',
    latitude: '40.819732',
    longitude: '-72.706941',
    timeZoneId: 'America/New_York',
  },
  '11973': {
    zip: '11973',
    city: 'Upton',
    state: 'NY',
    latitude: '40.849836',
    longitude: '-72.889667',
    timeZoneId: 'America/New_York',
  },
  '11975': {
    zip: '11975',
    city: 'Wainscott',
    state: 'NY',
    latitude: '40.952826',
    longitude: '-72.249776',
    timeZoneId: 'America/New_York',
  },
  '11976': {
    zip: '11976',
    city: 'Water Mill',
    state: 'NY',
    latitude: '40.930979',
    longitude: '-72.35279',
    timeZoneId: 'America/New_York',
  },
  '11977': {
    zip: '11977',
    city: 'Westhampton',
    state: 'NY',
    latitude: '40.819853',
    longitude: '-72.666788',
    timeZoneId: 'America/New_York',
  },
  '11978': {
    zip: '11978',
    city: 'Westhampton Beach',
    state: 'NY',
    latitude: '40.811711',
    longitude: '-72.645053',
    timeZoneId: 'America/New_York',
  },
  '11980': {
    zip: '11980',
    city: 'Yaphank',
    state: 'NY',
    latitude: '40.838524',
    longitude: '-72.933522',
    timeZoneId: 'America/New_York',
  },
  '12007': {
    zip: '12007',
    city: 'Alcove',
    state: 'NY',
    latitude: '42.464479',
    longitude: '-73.921582',
    timeZoneId: 'America/New_York',
  },
  '12008': {
    zip: '12008',
    city: 'Alplaus',
    state: 'NY',
    latitude: '42.854388',
    longitude: '-73.904169',
    timeZoneId: 'America/New_York',
  },
  '12009': {
    zip: '12009',
    city: 'Altamont',
    state: 'NY',
    latitude: '42.697778',
    longitude: '-74.025728',
    timeZoneId: 'America/New_York',
  },
  '12010': {
    zip: '12010',
    city: 'Amsterdam',
    state: 'NY',
    latitude: '42.92364',
    longitude: '-74.17086',
    timeZoneId: 'America/New_York',
  },
  '12015': {
    zip: '12015',
    city: 'Athens',
    state: 'NY',
    latitude: '42.262647',
    longitude: '-73.810372',
    timeZoneId: 'America/New_York',
  },
  '12016': {
    zip: '12016',
    city: 'Auriesville',
    state: 'NY',
    latitude: '42.928783',
    longitude: '-74.319642',
    timeZoneId: 'America/New_York',
  },
  '12017': {
    zip: '12017',
    city: 'Austerlitz',
    state: 'NY',
    latitude: '42.316394',
    longitude: '-73.458542',
    timeZoneId: 'America/New_York',
  },
  '12018': {
    zip: '12018',
    city: 'Averill Park',
    state: 'NY',
    latitude: '42.64062',
    longitude: '-73.5495',
    timeZoneId: 'America/New_York',
  },
  '12019': {
    zip: '12019',
    city: 'Ballston Lake',
    state: 'NY',
    latitude: '42.92172',
    longitude: '-73.8906',
    timeZoneId: 'America/New_York',
  },
  '12020': {
    zip: '12020',
    city: 'Ballston Spa',
    state: 'NY',
    latitude: '43.005586',
    longitude: '-73.860404',
    timeZoneId: 'America/New_York',
  },
  '12022': {
    zip: '12022',
    city: 'Berlin',
    state: 'NY',
    latitude: '42.694299',
    longitude: '-73.370325',
    timeZoneId: 'America/New_York',
  },
  '12023': {
    zip: '12023',
    city: 'Berne',
    state: 'NY',
    latitude: '42.60636',
    longitude: '-74.1438',
    timeZoneId: 'America/New_York',
  },
  '12024': {
    zip: '12024',
    city: 'Brainard',
    state: 'NY',
    latitude: '42.482968',
    longitude: '-73.53439',
    timeZoneId: 'America/New_York',
  },
  '12025': {
    zip: '12025',
    city: 'Broadalbin',
    state: 'NY',
    latitude: '43.057254',
    longitude: '-74.198122',
    timeZoneId: 'America/New_York',
  },
  '12027': {
    zip: '12027',
    city: 'Burnt Hills',
    state: 'NY',
    latitude: '42.916475',
    longitude: '-73.901761',
    timeZoneId: 'America/New_York',
  },
  '12028': {
    zip: '12028',
    city: 'Buskirk',
    state: 'NY',
    latitude: '42.962677',
    longitude: '-73.447303',
    timeZoneId: 'America/New_York',
  },
  '12029': {
    zip: '12029',
    city: 'Canaan',
    state: 'NY',
    latitude: '42.391057',
    longitude: '-73.400428',
    timeZoneId: 'America/New_York',
  },
  '12031': {
    zip: '12031',
    city: 'Carlisle',
    state: 'NY',
    latitude: '42.746768',
    longitude: '-74.45741',
    timeZoneId: 'America/New_York',
  },
  '12032': {
    zip: '12032',
    city: 'Caroga Lake',
    state: 'NY',
    latitude: '43.161683',
    longitude: '-74.49371',
    timeZoneId: 'America/New_York',
  },
  '12033': {
    zip: '12033',
    city: 'Castleton on Hudson',
    state: 'NY',
    latitude: '42.535438',
    longitude: '-73.70907',
    timeZoneId: 'America/New_York',
  },
  '12035': {
    zip: '12035',
    city: 'Central Bridge',
    state: 'NY',
    latitude: '42.716078',
    longitude: '-74.361941',
    timeZoneId: 'America/New_York',
  },
  '12036': {
    zip: '12036',
    city: 'Charlotteville',
    state: 'NY',
    latitude: '42.541441',
    longitude: '-74.657149',
    timeZoneId: 'America/New_York',
  },
  '12037': {
    zip: '12037',
    city: 'Chatham',
    state: 'NY',
    latitude: '42.359632',
    longitude: '-73.589364',
    timeZoneId: 'America/New_York',
  },
  '12040': {
    zip: '12040',
    city: 'Cherry Plain',
    state: 'NY',
    latitude: '42.634921',
    longitude: '-73.355054',
    timeZoneId: 'America/New_York',
  },
  '12041': {
    zip: '12041',
    city: 'Clarksville',
    state: 'NY',
    latitude: '42.560143',
    longitude: '-73.980356',
    timeZoneId: 'America/New_York',
  },
  '12042': {
    zip: '12042',
    city: 'Climax',
    state: 'NY',
    latitude: '42.372564',
    longitude: '-73.865815',
    timeZoneId: 'America/New_York',
  },
  '12043': {
    zip: '12043',
    city: 'Cobleskill',
    state: 'NY',
    latitude: '42.679057',
    longitude: '-74.487086',
    timeZoneId: 'America/New_York',
  },
  '12045': {
    zip: '12045',
    city: 'Coeymans',
    state: 'NY',
    latitude: '42.472313',
    longitude: '-73.793901',
    timeZoneId: 'America/New_York',
  },
  '12046': {
    zip: '12046',
    city: 'Coeymans Hollow',
    state: 'NY',
    latitude: '42.49842',
    longitude: '-73.91226',
    timeZoneId: 'America/New_York',
  },
  '12047': {
    zip: '12047',
    city: 'Cohoes',
    state: 'NY',
    latitude: '42.774939',
    longitude: '-73.715732',
    timeZoneId: 'America/New_York',
  },
  '12050': {
    zip: '12050',
    city: 'Columbiaville',
    state: 'NY',
    latitude: '42.315037',
    longitude: '-73.748815',
    timeZoneId: 'America/New_York',
  },
  '12051': {
    zip: '12051',
    city: 'Coxsackie',
    state: 'NY',
    latitude: '42.357823',
    longitude: '-73.807318',
    timeZoneId: 'America/New_York',
  },
  '12052': {
    zip: '12052',
    city: 'Cropseyville',
    state: 'NY',
    latitude: '42.753953',
    longitude: '-73.4773',
    timeZoneId: 'America/New_York',
  },
  '12053': {
    zip: '12053',
    city: 'Delanson',
    state: 'NY',
    latitude: '42.72258',
    longitude: '-74.17158',
    timeZoneId: 'America/New_York',
  },
  '12054': {
    zip: '12054',
    city: 'Delmar',
    state: 'NY',
    latitude: '42.60522',
    longitude: '-73.87008',
    timeZoneId: 'America/New_York',
  },
  '12055': {
    zip: '12055',
    city: 'Dormansville',
    state: 'NY',
    latitude: '42.43842',
    longitude: '-74.20308',
    timeZoneId: 'America/New_York',
  },
  '12056': {
    zip: '12056',
    city: 'Duanesburg',
    state: 'NY',
    latitude: '42.76614',
    longitude: '-74.13954',
    timeZoneId: 'America/New_York',
  },
  '12057': {
    zip: '12057',
    city: 'Eagle Bridge',
    state: 'NY',
    latitude: '42.95664',
    longitude: '-73.36152',
    timeZoneId: 'America/New_York',
  },
  '12058': {
    zip: '12058',
    city: 'Earlton',
    state: 'NY',
    latitude: '42.349583',
    longitude: '-73.902962',
    timeZoneId: 'America/New_York',
  },
  '12059': {
    zip: '12059',
    city: 'East Berne',
    state: 'NY',
    latitude: '42.617126',
    longitude: '-74.058841',
    timeZoneId: 'America/New_York',
  },
  '12060': {
    zip: '12060',
    city: 'East Chatham',
    state: 'NY',
    latitude: '42.41262',
    longitude: '-73.49604',
    timeZoneId: 'America/New_York',
  },
  '12061': {
    zip: '12061',
    city: 'East Greenbush',
    state: 'NY',
    latitude: '42.595189',
    longitude: '-73.693628',
    timeZoneId: 'America/New_York',
  },
  '12062': {
    zip: '12062',
    city: 'East Nassau',
    state: 'NY',
    latitude: '42.531602',
    longitude: '-73.502202',
    timeZoneId: 'America/New_York',
  },
  '12063': {
    zip: '12063',
    city: 'East Schodack',
    state: 'NY',
    latitude: '42.569709',
    longitude: '-73.61451',
    timeZoneId: 'America/New_York',
  },
  '12064': {
    zip: '12064',
    city: 'East Worcester',
    state: 'NY',
    latitude: '42.557719',
    longitude: '-74.64435',
    timeZoneId: 'America/New_York',
  },
  '12065': {
    zip: '12065',
    city: 'Clifton Park',
    state: 'NY',
    latitude: '42.85146',
    longitude: '-73.79898',
    timeZoneId: 'America/New_York',
  },
  '12066': {
    zip: '12066',
    city: 'Esperance',
    state: 'NY',
    latitude: '42.7947',
    longitude: '-74.33466',
    timeZoneId: 'America/New_York',
  },
  '12067': {
    zip: '12067',
    city: 'Feura Bush',
    state: 'NY',
    latitude: '42.565899',
    longitude: '-73.913257',
    timeZoneId: 'America/New_York',
  },
  '12068': {
    zip: '12068',
    city: 'Fonda',
    state: 'NY',
    latitude: '42.94236',
    longitude: '-74.4303',
    timeZoneId: 'America/New_York',
  },
  '12069': {
    zip: '12069',
    city: 'Fort Hunter',
    state: 'NY',
    latitude: '42.941659',
    longitude: '-74.279913',
    timeZoneId: 'America/New_York',
  },
  '12070': {
    zip: '12070',
    city: 'Fort Johnson',
    state: 'NY',
    latitude: '42.962988',
    longitude: '-74.251122',
    timeZoneId: 'America/New_York',
  },
  '12071': {
    zip: '12071',
    city: 'Fultonham',
    state: 'NY',
    latitude: '42.591388',
    longitude: '-74.393816',
    timeZoneId: 'America/New_York',
  },
  '12072': {
    zip: '12072',
    city: 'Fultonville',
    state: 'NY',
    latitude: '42.944695',
    longitude: '-74.376969',
    timeZoneId: 'America/New_York',
  },
  '12073': {
    zip: '12073',
    city: 'Gallupville',
    state: 'NY',
    latitude: '42.662066',
    longitude: '-74.229925',
    timeZoneId: 'America/New_York',
  },
  '12074': {
    zip: '12074',
    city: 'Galway',
    state: 'NY',
    latitude: '43.032199',
    longitude: '-74.036378',
    timeZoneId: 'America/New_York',
  },
  '12075': {
    zip: '12075',
    city: 'Ghent',
    state: 'NY',
    latitude: '42.292747',
    longitude: '-73.702189',
    timeZoneId: 'America/New_York',
  },
  '12076': {
    zip: '12076',
    city: 'Gilboa',
    state: 'NY',
    latitude: '42.410715',
    longitude: '-74.390183',
    timeZoneId: 'America/New_York',
  },
  '12077': {
    zip: '12077',
    city: 'Glenmont',
    state: 'NY',
    latitude: '42.596487',
    longitude: '-73.794423',
    timeZoneId: 'America/New_York',
  },
  '12078': {
    zip: '12078',
    city: 'Gloversville',
    state: 'NY',
    latitude: '43.054502',
    longitude: '-74.33833',
    timeZoneId: 'America/New_York',
  },
  '12082': {
    zip: '12082',
    city: 'Grafton',
    state: 'NY',
    latitude: '42.768623',
    longitude: '-73.447387',
    timeZoneId: 'America/New_York',
  },
  '12083': {
    zip: '12083',
    city: 'Greenville',
    state: 'NY',
    latitude: '42.416705',
    longitude: '-74.011373',
    timeZoneId: 'America/New_York',
  },
  '12084': {
    zip: '12084',
    city: 'Guilderland',
    state: 'NY',
    latitude: '42.699325',
    longitude: '-73.89971',
    timeZoneId: 'America/New_York',
  },
  '12085': {
    zip: '12085',
    city: 'Guilderland Center',
    state: 'NY',
    latitude: '42.702864',
    longitude: '-73.968446',
    timeZoneId: 'America/New_York',
  },
  '12086': {
    zip: '12086',
    city: 'Hagaman',
    state: 'NY',
    latitude: '42.974141',
    longitude: '-74.154373',
    timeZoneId: 'America/New_York',
  },
  '12087': {
    zip: '12087',
    city: 'Hannacroix',
    state: 'NY',
    latitude: '42.422544',
    longitude: '-73.886598',
    timeZoneId: 'America/New_York',
  },
  '12089': {
    zip: '12089',
    city: 'Hoosick',
    state: 'NY',
    latitude: '42.864306',
    longitude: '-73.327143',
    timeZoneId: 'America/New_York',
  },
  '12090': {
    zip: '12090',
    city: 'Hoosick Falls',
    state: 'NY',
    latitude: '42.88026',
    longitude: '-73.35906',
    timeZoneId: 'America/New_York',
  },
  '12092': {
    zip: '12092',
    city: 'Howes Cave',
    state: 'NY',
    latitude: '42.711693',
    longitude: '-74.36353',
    timeZoneId: 'America/New_York',
  },
  '12093': {
    zip: '12093',
    city: 'Jefferson',
    state: 'NY',
    latitude: '42.493692',
    longitude: '-74.604434',
    timeZoneId: 'America/New_York',
  },
  '12094': {
    zip: '12094',
    city: 'Johnsonville',
    state: 'NY',
    latitude: '42.932662',
    longitude: '-73.506739',
    timeZoneId: 'America/New_York',
  },
  '12095': {
    zip: '12095',
    city: 'Johnstown',
    state: 'NY',
    latitude: '43.02114',
    longitude: '-74.3955',
    timeZoneId: 'America/New_York',
  },
  '12106': {
    zip: '12106',
    city: 'Kinderhook',
    state: 'NY',
    latitude: '42.395508',
    longitude: '-73.711549',
    timeZoneId: 'America/New_York',
  },
  '12107': {
    zip: '12107',
    city: 'Knox',
    state: 'NY',
    latitude: '42.669807',
    longitude: '-74.119886',
    timeZoneId: 'America/New_York',
  },
  '12108': {
    zip: '12108',
    city: 'Lake Pleasant',
    state: 'NY',
    latitude: '43.469089',
    longitude: '-74.416358',
    timeZoneId: 'America/New_York',
  },
  '12110': {
    zip: '12110',
    city: 'Latham',
    state: 'NY',
    latitude: '42.747077',
    longitude: '-73.767886',
    timeZoneId: 'America/New_York',
  },
  '12115': {
    zip: '12115',
    city: 'Malden Bridge',
    state: 'NY',
    latitude: '42.45604',
    longitude: '-73.591434',
    timeZoneId: 'America/New_York',
  },
  '12116': {
    zip: '12116',
    city: 'Maryland',
    state: 'NY',
    latitude: '42.542837',
    longitude: '-74.899432',
    timeZoneId: 'America/New_York',
  },
  '12117': {
    zip: '12117',
    city: 'Mayfield',
    state: 'NY',
    latitude: '43.102843',
    longitude: '-74.263232',
    timeZoneId: 'America/New_York',
  },
  '12118': {
    zip: '12118',
    city: 'Mechanicville',
    state: 'NY',
    latitude: '42.913913',
    longitude: '-73.707978',
    timeZoneId: 'America/New_York',
  },
  '12120': {
    zip: '12120',
    city: 'Medusa',
    state: 'NY',
    latitude: '42.435003',
    longitude: '-74.147921',
    timeZoneId: 'America/New_York',
  },
  '12121': {
    zip: '12121',
    city: 'Melrose',
    state: 'NY',
    latitude: '42.843234',
    longitude: '-73.618514',
    timeZoneId: 'America/New_York',
  },
  '12122': {
    zip: '12122',
    city: 'Middleburgh',
    state: 'NY',
    latitude: '42.54036',
    longitude: '-74.27082',
    timeZoneId: 'America/New_York',
  },
  '12123': {
    zip: '12123',
    city: 'Nassau',
    state: 'NY',
    latitude: '42.521046',
    longitude: '-73.625267',
    timeZoneId: 'America/New_York',
  },
  '12124': {
    zip: '12124',
    city: 'New Baltimore',
    state: 'NY',
    latitude: '42.444613',
    longitude: '-73.787763',
    timeZoneId: 'America/New_York',
  },
  '12125': {
    zip: '12125',
    city: 'New Lebanon',
    state: 'NY',
    latitude: '42.483586',
    longitude: '-73.377729',
    timeZoneId: 'America/New_York',
  },
  '12128': {
    zip: '12128',
    city: 'Newtonville',
    state: 'NY',
    latitude: '42.724446',
    longitude: '-73.750908',
    timeZoneId: 'America/New_York',
  },
  '12130': {
    zip: '12130',
    city: 'Niverville',
    state: 'NY',
    latitude: '42.442797',
    longitude: '-73.657282',
    timeZoneId: 'America/New_York',
  },
  '12131': {
    zip: '12131',
    city: 'North Blenheim',
    state: 'NY',
    latitude: '42.487846',
    longitude: '-74.429697',
    timeZoneId: 'America/New_York',
  },
  '12132': {
    zip: '12132',
    city: 'North Chatham',
    state: 'NY',
    latitude: '42.471422',
    longitude: '-73.633263',
    timeZoneId: 'America/New_York',
  },
  '12133': {
    zip: '12133',
    city: 'North Hoosick',
    state: 'NY',
    latitude: '42.924518',
    longitude: '-73.345704',
    timeZoneId: 'America/New_York',
  },
  '12134': {
    zip: '12134',
    city: 'Northville',
    state: 'NY',
    latitude: '43.234942',
    longitude: '-74.156837',
    timeZoneId: 'America/New_York',
  },
  '12136': {
    zip: '12136',
    city: 'Old Chatham',
    state: 'NY',
    latitude: '42.469547',
    longitude: '-73.55641',
    timeZoneId: 'America/New_York',
  },
  '12137': {
    zip: '12137',
    city: 'Pattersonville',
    state: 'NY',
    latitude: '42.85572',
    longitude: '-74.1279',
    timeZoneId: 'America/New_York',
  },
  '12138': {
    zip: '12138',
    city: 'Petersburg',
    state: 'NY',
    latitude: '42.7371',
    longitude: '-73.36266',
    timeZoneId: 'America/New_York',
  },
  '12139': {
    zip: '12139',
    city: 'Piseco',
    state: 'NY',
    latitude: '43.390766',
    longitude: '-74.606599',
    timeZoneId: 'America/New_York',
  },
  '12140': {
    zip: '12140',
    city: 'Poestenkill',
    state: 'NY',
    latitude: '42.695254',
    longitude: '-73.578996',
    timeZoneId: 'America/New_York',
  },
  '12141': {
    zip: '12141',
    city: 'Quaker Street',
    state: 'NY',
    latitude: '42.732998',
    longitude: '-74.186598',
    timeZoneId: 'America/New_York',
  },
  '12143': {
    zip: '12143',
    city: 'Ravena',
    state: 'NY',
    latitude: '42.47172',
    longitude: '-73.820415',
    timeZoneId: 'America/New_York',
  },
  '12144': {
    zip: '12144',
    city: 'Rensselaer',
    state: 'NY',
    latitude: '42.6246',
    longitude: '-73.71012',
    timeZoneId: 'America/New_York',
  },
  '12147': {
    zip: '12147',
    city: 'Rensselaerville',
    state: 'NY',
    latitude: '42.50466',
    longitude: '-74.14734',
    timeZoneId: 'America/New_York',
  },
  '12148': {
    zip: '12148',
    city: 'Rexford',
    state: 'NY',
    latitude: '42.84564',
    longitude: '-73.84902',
    timeZoneId: 'America/New_York',
  },
  '12149': {
    zip: '12149',
    city: 'Richmondville',
    state: 'NY',
    latitude: '42.632159',
    longitude: '-74.56396',
    timeZoneId: 'America/New_York',
  },
  '12150': {
    zip: '12150',
    city: 'Rotterdam Junction',
    state: 'NY',
    latitude: '42.87668',
    longitude: '-74.043438',
    timeZoneId: 'America/New_York',
  },
  '12151': {
    zip: '12151',
    city: 'Round Lake',
    state: 'NY',
    latitude: '42.92352',
    longitude: '-73.7829',
    timeZoneId: 'America/New_York',
  },
  '12153': {
    zip: '12153',
    city: 'Sand Lake',
    state: 'NY',
    latitude: '42.6371',
    longitude: '-73.491691',
    timeZoneId: 'America/New_York',
  },
  '12154': {
    zip: '12154',
    city: 'Schaghticoke',
    state: 'NY',
    latitude: '42.897909',
    longitude: '-73.589565',
    timeZoneId: 'America/New_York',
  },
  '12155': {
    zip: '12155',
    city: 'Schenevus',
    state: 'NY',
    latitude: '42.59718',
    longitude: '-74.81892',
    timeZoneId: 'America/New_York',
  },
  '12156': {
    zip: '12156',
    city: 'Schodack Landing',
    state: 'NY',
    latitude: '42.47952',
    longitude: '-73.72518',
    timeZoneId: 'America/New_York',
  },
  '12157': {
    zip: '12157',
    city: 'Schoharie',
    state: 'NY',
    latitude: '42.668153',
    longitude: '-74.311743',
    timeZoneId: 'America/New_York',
  },
  '12158': {
    zip: '12158',
    city: 'Selkirk',
    state: 'NY',
    latitude: '42.551716',
    longitude: '-73.817741',
    timeZoneId: 'America/New_York',
  },
  '12159': {
    zip: '12159',
    city: 'Slingerlands',
    state: 'NY',
    latitude: '42.642796',
    longitude: '-73.877696',
    timeZoneId: 'America/New_York',
  },
  '12160': {
    zip: '12160',
    city: 'Sloansville',
    state: 'NY',
    latitude: '42.76266',
    longitude: '-74.372274',
    timeZoneId: 'America/New_York',
  },
  '12161': {
    zip: '12161',
    city: 'South Bethlehem',
    state: 'NY',
    latitude: '42.535245',
    longitude: '-73.89339',
    timeZoneId: 'America/New_York',
  },
  '12164': {
    zip: '12164',
    city: 'Speculator',
    state: 'NY',
    latitude: '43.557902',
    longitude: '-74.357613',
    timeZoneId: 'America/New_York',
  },
  '12165': {
    zip: '12165',
    city: 'Spencertown',
    state: 'NY',
    latitude: '42.320381',
    longitude: '-73.548607',
    timeZoneId: 'America/New_York',
  },
  '12166': {
    zip: '12166',
    city: 'Sprakers',
    state: 'NY',
    latitude: '42.8496',
    longitude: '-74.47638',
    timeZoneId: 'America/New_York',
  },
  '12167': {
    zip: '12167',
    city: 'Stamford',
    state: 'NY',
    latitude: '42.407598',
    longitude: '-74.619982',
    timeZoneId: 'America/New_York',
  },
  '12168': {
    zip: '12168',
    city: 'Stephentown',
    state: 'NY',
    latitude: '42.56064',
    longitude: '-73.4517',
    timeZoneId: 'America/New_York',
  },
  '12169': {
    zip: '12169',
    city: 'Stephentown',
    state: 'NY',
    latitude: '42.562',
    longitude: '-73.367385',
    timeZoneId: 'America/New_York',
  },
  '12170': {
    zip: '12170',
    city: 'Stillwater',
    state: 'NY',
    latitude: '42.93396',
    longitude: '-73.69038',
    timeZoneId: 'America/New_York',
  },
  '12172': {
    zip: '12172',
    city: 'Stottville',
    state: 'NY',
    latitude: '42.287538',
    longitude: '-73.742015',
    timeZoneId: 'America/New_York',
  },
  '12173': {
    zip: '12173',
    city: 'Stuyvesant',
    state: 'NY',
    latitude: '42.34482',
    longitude: '-73.75644',
    timeZoneId: 'America/New_York',
  },
  '12174': {
    zip: '12174',
    city: 'Stuyvesant Falls',
    state: 'NY',
    latitude: '42.351815',
    longitude: '-73.733223',
    timeZoneId: 'America/New_York',
  },
  '12175': {
    zip: '12175',
    city: 'Summit',
    state: 'NY',
    latitude: '42.533016',
    longitude: '-74.53883',
    timeZoneId: 'America/New_York',
  },
  '12176': {
    zip: '12176',
    city: 'Surprise',
    state: 'NY',
    latitude: '42.359083',
    longitude: '-73.950092',
    timeZoneId: 'America/New_York',
  },
  '12177': {
    zip: '12177',
    city: 'Tribes Hill',
    state: 'NY',
    latitude: '42.95363',
    longitude: '-74.291341',
    timeZoneId: 'America/New_York',
  },
  '12180': {
    zip: '12180',
    city: 'Troy',
    state: 'NY',
    latitude: '42.744',
    longitude: '-73.60512',
    timeZoneId: 'America/New_York',
  },
  '12181': {
    zip: '12181',
    city: 'Troy',
    state: 'NY',
    latitude: '42.731923',
    longitude: '-73.686799',
    timeZoneId: 'America/New_York',
  },
  '12182': {
    zip: '12182',
    city: 'Troy',
    state: 'NY',
    latitude: '42.775992',
    longitude: '-73.660085',
    timeZoneId: 'America/New_York',
  },
  '12183': {
    zip: '12183',
    city: 'Troy',
    state: 'NY',
    latitude: '42.744346',
    longitude: '-73.695668',
    timeZoneId: 'America/New_York',
  },
  '12184': {
    zip: '12184',
    city: 'Valatie',
    state: 'NY',
    latitude: '42.4407',
    longitude: '-73.67964',
    timeZoneId: 'America/New_York',
  },
  '12185': {
    zip: '12185',
    city: 'Valley Falls',
    state: 'NY',
    latitude: '42.88714',
    longitude: '-73.544884',
    timeZoneId: 'America/New_York',
  },
  '12186': {
    zip: '12186',
    city: 'Voorheesville',
    state: 'NY',
    latitude: '42.62478',
    longitude: '-73.9698',
    timeZoneId: 'America/New_York',
  },
  '12187': {
    zip: '12187',
    city: 'Warnerville',
    state: 'NY',
    latitude: '42.614747',
    longitude: '-74.475755',
    timeZoneId: 'America/New_York',
  },
  '12188': {
    zip: '12188',
    city: 'Waterford',
    state: 'NY',
    latitude: '42.81954',
    longitude: '-73.70262',
    timeZoneId: 'America/New_York',
  },
  '12189': {
    zip: '12189',
    city: 'Watervliet',
    state: 'NY',
    latitude: '42.726113',
    longitude: '-73.713355',
    timeZoneId: 'America/New_York',
  },
  '12190': {
    zip: '12190',
    city: 'Wells',
    state: 'NY',
    latitude: '43.475882',
    longitude: '-74.270426',
    timeZoneId: 'America/New_York',
  },
  '12192': {
    zip: '12192',
    city: 'West Coxsackie',
    state: 'NY',
    latitude: '42.38328',
    longitude: '-73.8243',
    timeZoneId: 'America/New_York',
  },
  '12193': {
    zip: '12193',
    city: 'Westerlo',
    state: 'NY',
    latitude: '42.540237',
    longitude: '-74.045892',
    timeZoneId: 'America/New_York',
  },
  '12194': {
    zip: '12194',
    city: 'West Fulton',
    state: 'NY',
    latitude: '42.53856',
    longitude: '-74.454716',
    timeZoneId: 'America/New_York',
  },
  '12195': {
    zip: '12195',
    city: 'West Lebanon',
    state: 'NY',
    latitude: '42.490534',
    longitude: '-73.448183',
    timeZoneId: 'America/New_York',
  },
  '12196': {
    zip: '12196',
    city: 'West Sand Lake',
    state: 'NY',
    latitude: '42.627847',
    longitude: '-73.632094',
    timeZoneId: 'America/New_York',
  },
  '12197': {
    zip: '12197',
    city: 'Worcester',
    state: 'NY',
    latitude: '42.517778',
    longitude: '-74.750278',
    timeZoneId: 'America/New_York',
  },
  '12198': {
    zip: '12198',
    city: 'Wynantskill',
    state: 'NY',
    latitude: '42.67272',
    longitude: '-73.64184',
    timeZoneId: 'America/New_York',
  },
  '12201': {
    zip: '12201',
    city: 'Albany',
    state: 'NY',
    latitude: '42.648322',
    longitude: '-73.751608',
    timeZoneId: 'America/New_York',
  },
  '12202': {
    zip: '12202',
    city: 'Albany',
    state: 'NY',
    latitude: '42.639674',
    longitude: '-73.769661',
    timeZoneId: 'America/New_York',
  },
  '12203': {
    zip: '12203',
    city: 'Albany',
    state: 'NY',
    latitude: '42.69156',
    longitude: '-73.82784',
    timeZoneId: 'America/New_York',
  },
  '12204': {
    zip: '12204',
    city: 'Albany',
    state: 'NY',
    latitude: '42.67698',
    longitude: '-73.73748',
    timeZoneId: 'America/New_York',
  },
  '12205': {
    zip: '12205',
    city: 'Albany',
    state: 'NY',
    latitude: '42.714746',
    longitude: '-73.821155',
    timeZoneId: 'America/New_York',
  },
  '12206': {
    zip: '12206',
    city: 'Albany',
    state: 'NY',
    latitude: '42.67297',
    longitude: '-73.77601',
    timeZoneId: 'America/New_York',
  },
  '12207': {
    zip: '12207',
    city: 'Albany',
    state: 'NY',
    latitude: '42.652365',
    longitude: '-73.754418',
    timeZoneId: 'America/New_York',
  },
  '12208': {
    zip: '12208',
    city: 'Albany',
    state: 'NY',
    latitude: '42.652812',
    longitude: '-73.807109',
    timeZoneId: 'America/New_York',
  },
  '12209': {
    zip: '12209',
    city: 'Albany',
    state: 'NY',
    latitude: '42.641762',
    longitude: '-73.788358',
    timeZoneId: 'America/New_York',
  },
  '12210': {
    zip: '12210',
    city: 'Albany',
    state: 'NY',
    latitude: '42.654387',
    longitude: '-73.75738',
    timeZoneId: 'America/New_York',
  },
  '12211': {
    zip: '12211',
    city: 'Albany',
    state: 'NY',
    latitude: '42.707382',
    longitude: '-73.772002',
    timeZoneId: 'America/New_York',
  },
  '12212': {
    zip: '12212',
    city: 'Albany',
    state: 'NY',
    latitude: '42.67967',
    longitude: '-73.840113',
    timeZoneId: 'America/New_York',
  },
  '12214': {
    zip: '12214',
    city: 'Albany',
    state: 'NY',
    latitude: '42.686317',
    longitude: '-73.846929',
    timeZoneId: 'America/New_York',
  },
  '12220': {
    zip: '12220',
    city: 'Albany',
    state: 'NY',
    latitude: '42.664897',
    longitude: '-73.883261',
    timeZoneId: 'America/New_York',
  },
  '12222': {
    zip: '12222',
    city: 'Albany',
    state: 'NY',
    latitude: '42.693234',
    longitude: '-73.838124',
    timeZoneId: 'America/New_York',
  },
  '12223': {
    zip: '12223',
    city: 'Albany',
    state: 'NY',
    latitude: '42.651781',
    longitude: '-73.757949',
    timeZoneId: 'America/New_York',
  },
  '12224': {
    zip: '12224',
    city: 'Albany',
    state: 'NY',
    latitude: '42.664656',
    longitude: '-73.841729',
    timeZoneId: 'America/New_York',
  },
  '12225': {
    zip: '12225',
    city: 'Albany',
    state: 'NY',
    latitude: '42.682259',
    longitude: '-73.838108',
    timeZoneId: 'America/New_York',
  },
  '12226': {
    zip: '12226',
    city: 'Albany',
    state: 'NY',
    latitude: '42.684962',
    longitude: '-73.807547',
    timeZoneId: 'America/New_York',
  },
  '12227': {
    zip: '12227',
    city: 'Albany',
    state: 'NY',
    latitude: '42.689555',
    longitude: '-73.845984',
    timeZoneId: 'America/New_York',
  },
  '12228': {
    zip: '12228',
    city: 'Albany',
    state: 'NY',
    latitude: '42.645681',
    longitude: '-73.756663',
    timeZoneId: 'America/New_York',
  },
  '12229': {
    zip: '12229',
    city: 'Albany',
    state: 'NY',
    latitude: '42.650418',
    longitude: '-73.814413',
    timeZoneId: 'America/New_York',
  },
  '12230': {
    zip: '12230',
    city: 'Albany',
    state: 'NY',
    latitude: '42.6547',
    longitude: '-73.757766',
    timeZoneId: 'America/New_York',
  },
  '12231': {
    zip: '12231',
    city: 'Albany',
    state: 'NY',
    latitude: '42.656061',
    longitude: '-73.760129',
    timeZoneId: 'America/New_York',
  },
  '12232': {
    zip: '12232',
    city: 'Albany',
    state: 'NY',
    latitude: '42.652603',
    longitude: '-73.754055',
    timeZoneId: 'America/New_York',
  },
  '12233': {
    zip: '12233',
    city: 'Albany',
    state: 'NY',
    latitude: '42.649243',
    longitude: '-73.755554',
    timeZoneId: 'America/New_York',
  },
  '12234': {
    zip: '12234',
    city: 'Albany',
    state: 'NY',
    latitude: '42.671814',
    longitude: '-73.736501',
    timeZoneId: 'America/New_York',
  },
  '12235': {
    zip: '12235',
    city: 'Albany',
    state: 'NY',
    latitude: '42.649742',
    longitude: '-73.756061',
    timeZoneId: 'America/New_York',
  },
  '12236': {
    zip: '12236',
    city: 'Albany',
    state: 'NY',
    latitude: '42.651877',
    longitude: '-73.758413',
    timeZoneId: 'America/New_York',
  },
  '12237': {
    zip: '12237',
    city: 'Albany',
    state: 'NY',
    latitude: '42.645888',
    longitude: '-73.797658',
    timeZoneId: 'America/New_York',
  },
  '12238': {
    zip: '12238',
    city: 'Albany',
    state: 'NY',
    latitude: '42.652025',
    longitude: '-73.761447',
    timeZoneId: 'America/New_York',
  },
  '12239': {
    zip: '12239',
    city: 'Albany',
    state: 'NY',
    latitude: '42.650821',
    longitude: '-73.758546',
    timeZoneId: 'America/New_York',
  },
  '12240': {
    zip: '12240',
    city: 'Albany',
    state: 'NY',
    latitude: '42.651487',
    longitude: '-73.7569',
    timeZoneId: 'America/New_York',
  },
  '12241': {
    zip: '12241',
    city: 'Albany',
    state: 'NY',
    latitude: '42.630394',
    longitude: '-73.761975',
    timeZoneId: 'America/New_York',
  },
  '12242': {
    zip: '12242',
    city: 'Albany',
    state: 'NY',
    latitude: '42.652754',
    longitude: '-73.757551',
    timeZoneId: 'America/New_York',
  },
  '12243': {
    zip: '12243',
    city: 'Albany',
    state: 'NY',
    latitude: '42.653793',
    longitude: '-73.746424',
    timeZoneId: 'America/New_York',
  },
  '12244': {
    zip: '12244',
    city: 'Albany',
    state: 'NY',
    latitude: '42.651521',
    longitude: '-73.761175',
    timeZoneId: 'America/New_York',
  },
  '12245': {
    zip: '12245',
    city: 'Albany',
    state: 'NY',
    latitude: '42.671218',
    longitude: '-73.772898',
    timeZoneId: 'America/New_York',
  },
  '12246': {
    zip: '12246',
    city: 'Albany',
    state: 'NY',
    latitude: '42.689007',
    longitude: '-73.821157',
    timeZoneId: 'America/New_York',
  },
  '12247': {
    zip: '12247',
    city: 'Albany',
    state: 'NY',
    latitude: '42.68227',
    longitude: '-73.727879',
    timeZoneId: 'America/New_York',
  },
  '12248': {
    zip: '12248',
    city: 'Albany',
    state: 'NY',
    latitude: '42.653981',
    longitude: '-73.758374',
    timeZoneId: 'America/New_York',
  },
  '12249': {
    zip: '12249',
    city: 'Albany',
    state: 'NY',
    latitude: '42.697178',
    longitude: '-73.994306',
    timeZoneId: 'America/New_York',
  },
  '12250': {
    zip: '12250',
    city: 'Albany',
    state: 'NY',
    latitude: '42.673005',
    longitude: '-73.735244',
    timeZoneId: 'America/New_York',
  },
  '12255': {
    zip: '12255',
    city: 'Albany',
    state: 'NY',
    latitude: '42.654469',
    longitude: '-73.759719',
    timeZoneId: 'America/New_York',
  },
  '12257': {
    zip: '12257',
    city: 'Albany',
    state: 'NY',
    latitude: '42.653571',
    longitude: '-73.757472',
    timeZoneId: 'America/New_York',
  },
  '12260': {
    zip: '12260',
    city: 'Albany',
    state: 'NY',
    latitude: '42.669387',
    longitude: '-73.773554',
    timeZoneId: 'America/New_York',
  },
  '12261': {
    zip: '12261',
    city: 'Albany',
    state: 'NY',
    latitude: '42.679058',
    longitude: '-73.782986',
    timeZoneId: 'America/New_York',
  },
  '12288': {
    zip: '12288',
    city: 'Albany',
    state: 'NY',
    latitude: '42.728717',
    longitude: '-73.847757',
    timeZoneId: 'America/New_York',
  },
  '12301': {
    zip: '12301',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.813236',
    longitude: '-73.939399',
    timeZoneId: 'America/New_York',
  },
  '12302': {
    zip: '12302',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.856481',
    longitude: '-73.956093',
    timeZoneId: 'America/New_York',
  },
  '12303': {
    zip: '12303',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.783122',
    longitude: '-73.938595',
    timeZoneId: 'America/New_York',
  },
  '12304': {
    zip: '12304',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.780295',
    longitude: '-73.909969',
    timeZoneId: 'America/New_York',
  },
  '12305': {
    zip: '12305',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.814529',
    longitude: '-73.943411',
    timeZoneId: 'America/New_York',
  },
  '12306': {
    zip: '12306',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.792392',
    longitude: '-73.984961',
    timeZoneId: 'America/New_York',
  },
  '12307': {
    zip: '12307',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.802385',
    longitude: '-73.93657',
    timeZoneId: 'America/New_York',
  },
  '12308': {
    zip: '12308',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.820842',
    longitude: '-73.91998',
    timeZoneId: 'America/New_York',
  },
  '12309': {
    zip: '12309',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.79578',
    longitude: '-73.86744',
    timeZoneId: 'America/New_York',
  },
  '12325': {
    zip: '12325',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.814167',
    longitude: '-73.94',
    timeZoneId: 'America/New_York',
  },
  '12345': {
    zip: '12345',
    city: 'Schenectady',
    state: 'NY',
    latitude: '42.815656',
    longitude: '-73.942324',
    timeZoneId: 'America/New_York',
  },
  '12401': {
    zip: '12401',
    city: 'Kingston',
    state: 'NY',
    latitude: '41.932171',
    longitude: '-74.019531',
    timeZoneId: 'America/New_York',
  },
  '12402': {
    zip: '12402',
    city: 'Kingston',
    state: 'NY',
    latitude: '41.932386',
    longitude: '-74.032331',
    timeZoneId: 'America/New_York',
  },
  '12404': {
    zip: '12404',
    city: 'Accord',
    state: 'NY',
    latitude: '41.81046',
    longitude: '-74.24982',
    timeZoneId: 'America/New_York',
  },
  '12405': {
    zip: '12405',
    city: 'Acra',
    state: 'NY',
    latitude: '42.332936',
    longitude: '-74.093813',
    timeZoneId: 'America/New_York',
  },
  '12406': {
    zip: '12406',
    city: 'Arkville',
    state: 'NY',
    latitude: '42.108167',
    longitude: '-74.530179',
    timeZoneId: 'America/New_York',
  },
  '12407': {
    zip: '12407',
    city: 'Ashland',
    state: 'NY',
    latitude: '42.295009',
    longitude: '-74.355618',
    timeZoneId: 'America/New_York',
  },
  '12409': {
    zip: '12409',
    city: 'Bearsville',
    state: 'NY',
    latitude: '42.03738',
    longitude: '-74.17518',
    timeZoneId: 'America/New_York',
  },
  '12410': {
    zip: '12410',
    city: 'Big Indian',
    state: 'NY',
    latitude: '42.09294',
    longitude: '-74.46186',
    timeZoneId: 'America/New_York',
  },
  '12411': {
    zip: '12411',
    city: 'Bloomington',
    state: 'NY',
    latitude: '41.84796',
    longitude: '-74.07906',
    timeZoneId: 'America/New_York',
  },
  '12412': {
    zip: '12412',
    city: 'Boiceville',
    state: 'NY',
    latitude: '42.004425',
    longitude: '-74.261329',
    timeZoneId: 'America/New_York',
  },
  '12413': {
    zip: '12413',
    city: 'Cairo',
    state: 'NY',
    latitude: '42.32514',
    longitude: '-74.02992',
    timeZoneId: 'America/New_York',
  },
  '12414': {
    zip: '12414',
    city: 'Catskill',
    state: 'NY',
    latitude: '42.224593',
    longitude: '-73.874142',
    timeZoneId: 'America/New_York',
  },
  '12416': {
    zip: '12416',
    city: 'Chichester',
    state: 'NY',
    latitude: '42.090912',
    longitude: '-74.268664',
    timeZoneId: 'America/New_York',
  },
  '12417': {
    zip: '12417',
    city: 'Connelly',
    state: 'NY',
    latitude: '41.911892',
    longitude: '-73.994188',
    timeZoneId: 'America/New_York',
  },
  '12418': {
    zip: '12418',
    city: 'Cornwallville',
    state: 'NY',
    latitude: '42.379172',
    longitude: '-74.126219',
    timeZoneId: 'America/New_York',
  },
  '12419': {
    zip: '12419',
    city: 'Cottekill',
    state: 'NY',
    latitude: '41.850267',
    longitude: '-74.115043',
    timeZoneId: 'America/New_York',
  },
  '12420': {
    zip: '12420',
    city: 'Cragsmoor',
    state: 'NY',
    latitude: '41.670081',
    longitude: '-74.385271',
    timeZoneId: 'America/New_York',
  },
  '12421': {
    zip: '12421',
    city: 'Denver',
    state: 'NY',
    latitude: '42.270333',
    longitude: '-74.531212',
    timeZoneId: 'America/New_York',
  },
  '12422': {
    zip: '12422',
    city: 'Durham',
    state: 'NY',
    latitude: '42.409931',
    longitude: '-74.182742',
    timeZoneId: 'America/New_York',
  },
  '12423': {
    zip: '12423',
    city: 'East Durham',
    state: 'NY',
    latitude: '42.388331',
    longitude: '-74.13671',
    timeZoneId: 'America/New_York',
  },
  '12424': {
    zip: '12424',
    city: 'East Jewett',
    state: 'NY',
    latitude: '42.235',
    longitude: '-74.145833',
    timeZoneId: 'America/New_York',
  },
  '12427': {
    zip: '12427',
    city: 'Elka Park',
    state: 'NY',
    latitude: '42.185227',
    longitude: '-74.13212',
    timeZoneId: 'America/New_York',
  },
  '12428': {
    zip: '12428',
    city: 'Ellenville',
    state: 'NY',
    latitude: '41.6985',
    longitude: '-74.50512',
    timeZoneId: 'America/New_York',
  },
  '12429': {
    zip: '12429',
    city: 'Esopus',
    state: 'NY',
    latitude: '41.837603',
    longitude: '-73.995484',
    timeZoneId: 'America/New_York',
  },
  '12430': {
    zip: '12430',
    city: 'Fleischmanns',
    state: 'NY',
    latitude: '42.195',
    longitude: '-74.550556',
    timeZoneId: 'America/New_York',
  },
  '12431': {
    zip: '12431',
    city: 'Freehold',
    state: 'NY',
    latitude: '42.377411',
    longitude: '-74.056683',
    timeZoneId: 'America/New_York',
  },
  '12432': {
    zip: '12432',
    city: 'Glasco',
    state: 'NY',
    latitude: '42.01853',
    longitude: '-73.961443',
    timeZoneId: 'America/New_York',
  },
  '12433': {
    zip: '12433',
    city: 'Glenford',
    state: 'NY',
    latitude: '42.008101',
    longitude: '-74.15142',
    timeZoneId: 'America/New_York',
  },
  '12434': {
    zip: '12434',
    city: 'Grand Gorge',
    state: 'NY',
    latitude: '42.343615',
    longitude: '-74.476158',
    timeZoneId: 'America/New_York',
  },
  '12435': {
    zip: '12435',
    city: 'Greenfield Park',
    state: 'NY',
    latitude: '41.74878',
    longitude: '-74.52318',
    timeZoneId: 'America/New_York',
  },
  '12436': {
    zip: '12436',
    city: 'Haines Falls',
    state: 'NY',
    latitude: '42.194504',
    longitude: '-74.11845',
    timeZoneId: 'America/New_York',
  },
  '12438': {
    zip: '12438',
    city: 'Halcottsville',
    state: 'NY',
    latitude: '42.208259',
    longitude: '-74.60121',
    timeZoneId: 'America/New_York',
  },
  '12439': {
    zip: '12439',
    city: 'Hensonville',
    state: 'NY',
    latitude: '42.2586',
    longitude: '-74.22498',
    timeZoneId: 'America/New_York',
  },
  '12440': {
    zip: '12440',
    city: 'High Falls',
    state: 'NY',
    latitude: '41.820309',
    longitude: '-74.126454',
    timeZoneId: 'America/New_York',
  },
  '12441': {
    zip: '12441',
    city: 'Highmount',
    state: 'NY',
    latitude: '42.143627',
    longitude: '-74.489371',
    timeZoneId: 'America/New_York',
  },
  '12442': {
    zip: '12442',
    city: 'Hunter',
    state: 'NY',
    latitude: '42.23142',
    longitude: '-74.19942',
    timeZoneId: 'America/New_York',
  },
  '12443': {
    zip: '12443',
    city: 'Hurley',
    state: 'NY',
    latitude: '41.924537',
    longitude: '-74.067379',
    timeZoneId: 'America/New_York',
  },
  '12444': {
    zip: '12444',
    city: 'Jewett',
    state: 'NY',
    latitude: '42.270415',
    longitude: '-74.31588',
    timeZoneId: 'America/New_York',
  },
  '12446': {
    zip: '12446',
    city: 'Kerhonkson',
    state: 'NY',
    latitude: '41.81292',
    longitude: '-74.29488',
    timeZoneId: 'America/New_York',
  },
  '12448': {
    zip: '12448',
    city: 'Lake Hill',
    state: 'NY',
    latitude: '42.072851',
    longitude: '-74.210051',
    timeZoneId: 'America/New_York',
  },
  '12449': {
    zip: '12449',
    city: 'Lake Katrine',
    state: 'NY',
    latitude: '41.997672',
    longitude: '-74.003214',
    timeZoneId: 'America/New_York',
  },
  '12450': {
    zip: '12450',
    city: 'Lanesville',
    state: 'NY',
    latitude: '42.134503',
    longitude: '-74.264765',
    timeZoneId: 'America/New_York',
  },
  '12451': {
    zip: '12451',
    city: 'Leeds',
    state: 'NY',
    latitude: '42.305672',
    longitude: '-73.944172',
    timeZoneId: 'America/New_York',
  },
  '12452': {
    zip: '12452',
    city: 'Lexington',
    state: 'NY',
    latitude: '42.24021',
    longitude: '-74.364377',
    timeZoneId: 'America/New_York',
  },
  '12453': {
    zip: '12453',
    city: 'Malden on Hudson',
    state: 'NY',
    latitude: '42.094637',
    longitude: '-73.933788',
    timeZoneId: 'America/New_York',
  },
  '12454': {
    zip: '12454',
    city: 'Maplecrest',
    state: 'NY',
    latitude: '42.289106',
    longitude: '-74.147239',
    timeZoneId: 'America/New_York',
  },
  '12455': {
    zip: '12455',
    city: 'Margaretville',
    state: 'NY',
    latitude: '42.151087',
    longitude: '-74.653789',
    timeZoneId: 'America/New_York',
  },
  '12456': {
    zip: '12456',
    city: 'Mount Marion',
    state: 'NY',
    latitude: '42.0366',
    longitude: '-73.99884',
    timeZoneId: 'America/New_York',
  },
  '12457': {
    zip: '12457',
    city: 'Mount Tremper',
    state: 'NY',
    latitude: '42.04216',
    longitude: '-74.258837',
    timeZoneId: 'America/New_York',
  },
  '12458': {
    zip: '12458',
    city: 'Napanoch',
    state: 'NY',
    latitude: '41.813621',
    longitude: '-74.413644',
    timeZoneId: 'America/New_York',
  },
  '12459': {
    zip: '12459',
    city: 'New Kingston',
    state: 'NY',
    latitude: '42.211411',
    longitude: '-74.683175',
    timeZoneId: 'America/New_York',
  },
  '12460': {
    zip: '12460',
    city: 'Oak Hill',
    state: 'NY',
    latitude: '42.411793',
    longitude: '-74.148999',
    timeZoneId: 'America/New_York',
  },
  '12461': {
    zip: '12461',
    city: 'Olivebridge',
    state: 'NY',
    latitude: '41.89878',
    longitude: '-74.25738',
    timeZoneId: 'America/New_York',
  },
  '12463': {
    zip: '12463',
    city: 'Palenville',
    state: 'NY',
    latitude: '42.174316',
    longitude: '-74.021686',
    timeZoneId: 'America/New_York',
  },
  '12464': {
    zip: '12464',
    city: 'Phoenicia',
    state: 'NY',
    latitude: '41.997088',
    longitude: '-74.334644',
    timeZoneId: 'America/New_York',
  },
  '12465': {
    zip: '12465',
    city: 'Pine Hill',
    state: 'NY',
    latitude: '42.129525',
    longitude: '-74.481912',
    timeZoneId: 'America/New_York',
  },
  '12466': {
    zip: '12466',
    city: 'Port Ewen',
    state: 'NY',
    latitude: '41.904729',
    longitude: '-73.979877',
    timeZoneId: 'America/New_York',
  },
  '12468': {
    zip: '12468',
    city: 'Prattsville',
    state: 'NY',
    latitude: '42.25962',
    longitude: '-74.3607',
    timeZoneId: 'America/New_York',
  },
  '12469': {
    zip: '12469',
    city: 'Preston Hollow',
    state: 'NY',
    latitude: '42.4566',
    longitude: '-74.22066',
    timeZoneId: 'America/New_York',
  },
  '12470': {
    zip: '12470',
    city: 'Purling',
    state: 'NY',
    latitude: '42.270473',
    longitude: '-74.008879',
    timeZoneId: 'America/New_York',
  },
  '12471': {
    zip: '12471',
    city: 'Rifton',
    state: 'NY',
    latitude: '41.832741',
    longitude: '-74.03745',
    timeZoneId: 'America/New_York',
  },
  '12472': {
    zip: '12472',
    city: 'Rosendale',
    state: 'NY',
    latitude: '41.846911',
    longitude: '-74.073956',
    timeZoneId: 'America/New_York',
  },
  '12473': {
    zip: '12473',
    city: 'Round Top',
    state: 'NY',
    latitude: '42.270121',
    longitude: '-74.070612',
    timeZoneId: 'America/New_York',
  },
  '12474': {
    zip: '12474',
    city: 'Roxbury',
    state: 'NY',
    latitude: '42.287672',
    longitude: '-74.561318',
    timeZoneId: 'America/New_York',
  },
  '12475': {
    zip: '12475',
    city: 'Ruby',
    state: 'NY',
    latitude: '42.019102',
    longitude: '-74.010805',
    timeZoneId: 'America/New_York',
  },
  '12477': {
    zip: '12477',
    city: 'Saugerties',
    state: 'NY',
    latitude: '42.07752',
    longitude: '-74.0136',
    timeZoneId: 'America/New_York',
  },
  '12480': {
    zip: '12480',
    city: 'Shandaken',
    state: 'NY',
    latitude: '42.12996',
    longitude: '-74.38464',
    timeZoneId: 'America/New_York',
  },
  '12481': {
    zip: '12481',
    city: 'Shokan',
    state: 'NY',
    latitude: '41.98362',
    longitude: '-74.22102',
    timeZoneId: 'America/New_York',
  },
  '12482': {
    zip: '12482',
    city: 'South Cairo',
    state: 'NY',
    latitude: '42.274889',
    longitude: '-73.952337',
    timeZoneId: 'America/New_York',
  },
  '12483': {
    zip: '12483',
    city: 'Spring Glen',
    state: 'NY',
    latitude: '41.664498',
    longitude: '-74.428203',
    timeZoneId: 'America/New_York',
  },
  '12484': {
    zip: '12484',
    city: 'Stone Ridge',
    state: 'NY',
    latitude: '41.87634',
    longitude: '-74.17554',
    timeZoneId: 'America/New_York',
  },
  '12485': {
    zip: '12485',
    city: 'Tannersville',
    state: 'NY',
    latitude: '42.194261',
    longitude: '-74.134684',
    timeZoneId: 'America/New_York',
  },
  '12486': {
    zip: '12486',
    city: 'Tillson',
    state: 'NY',
    latitude: '41.841672',
    longitude: '-74.05452',
    timeZoneId: 'America/New_York',
  },
  '12487': {
    zip: '12487',
    city: 'Ulster Park',
    state: 'NY',
    latitude: '41.866568',
    longitude: '-73.99021',
    timeZoneId: 'America/New_York',
  },
  '12489': {
    zip: '12489',
    city: 'Wawarsing',
    state: 'NY',
    latitude: '41.759074',
    longitude: '-74.357613',
    timeZoneId: 'America/New_York',
  },
  '12490': {
    zip: '12490',
    city: 'West Camp',
    state: 'NY',
    latitude: '42.122589',
    longitude: '-73.934344',
    timeZoneId: 'America/New_York',
  },
  '12491': {
    zip: '12491',
    city: 'West Hurley',
    state: 'NY',
    latitude: '42.006495',
    longitude: '-74.105729',
    timeZoneId: 'America/New_York',
  },
  '12492': {
    zip: '12492',
    city: 'West Kill',
    state: 'NY',
    latitude: '42.191667',
    longitude: '-74.308056',
    timeZoneId: 'America/New_York',
  },
  '12493': {
    zip: '12493',
    city: 'West Park',
    state: 'NY',
    latitude: '41.810969',
    longitude: '-74.003333',
    timeZoneId: 'America/New_York',
  },
  '12494': {
    zip: '12494',
    city: 'West Shokan',
    state: 'NY',
    latitude: '41.945647',
    longitude: '-74.291909',
    timeZoneId: 'America/New_York',
  },
  '12495': {
    zip: '12495',
    city: 'Willow',
    state: 'NY',
    latitude: '42.091198',
    longitude: '-74.202183',
    timeZoneId: 'America/New_York',
  },
  '12496': {
    zip: '12496',
    city: 'Windham',
    state: 'NY',
    latitude: '42.31008',
    longitude: '-74.28888',
    timeZoneId: 'America/New_York',
  },
  '12498': {
    zip: '12498',
    city: 'Woodstock',
    state: 'NY',
    latitude: '42.032936',
    longitude: '-74.116116',
    timeZoneId: 'America/New_York',
  },
  '12501': {
    zip: '12501',
    city: 'Amenia',
    state: 'NY',
    latitude: '41.848904',
    longitude: '-73.54105',
    timeZoneId: 'America/New_York',
  },
  '12502': {
    zip: '12502',
    city: 'Ancram',
    state: 'NY',
    latitude: '42.10722',
    longitude: '-73.67952',
    timeZoneId: 'America/New_York',
  },
  '12503': {
    zip: '12503',
    city: 'Ancramdale',
    state: 'NY',
    latitude: '42.037624',
    longitude: '-73.62789',
    timeZoneId: 'America/New_York',
  },
  '12504': {
    zip: '12504',
    city: 'Annandale on Hudson',
    state: 'NY',
    latitude: '42.012397',
    longitude: '-73.898328',
    timeZoneId: 'America/New_York',
  },
  '12506': {
    zip: '12506',
    city: 'Bangall',
    state: 'NY',
    latitude: '41.875132',
    longitude: '-73.690376',
    timeZoneId: 'America/New_York',
  },
  '12507': {
    zip: '12507',
    city: 'Barrytown',
    state: 'NY',
    latitude: '41.995',
    longitude: '-73.875833',
    timeZoneId: 'America/New_York',
  },
  '12508': {
    zip: '12508',
    city: 'Beacon',
    state: 'NY',
    latitude: '41.48784',
    longitude: '-73.94904',
    timeZoneId: 'America/New_York',
  },
  '12510': {
    zip: '12510',
    city: 'Billings',
    state: 'NY',
    latitude: '41.672319',
    longitude: '-73.762471',
    timeZoneId: 'America/New_York',
  },
  '12511': {
    zip: '12511',
    city: 'Castle Point',
    state: 'NY',
    latitude: '41.528219',
    longitude: '-73.963443',
    timeZoneId: 'America/New_York',
  },
  '12512': {
    zip: '12512',
    city: 'Chelsea',
    state: 'NY',
    latitude: '41.554815',
    longitude: '-73.965035',
    timeZoneId: 'America/New_York',
  },
  '12513': {
    zip: '12513',
    city: 'Claverack',
    state: 'NY',
    latitude: '42.22224',
    longitude: '-73.734878',
    timeZoneId: 'America/New_York',
  },
  '12514': {
    zip: '12514',
    city: 'Clinton Corners',
    state: 'NY',
    latitude: '41.87598',
    longitude: '-73.7724',
    timeZoneId: 'America/New_York',
  },
  '12515': {
    zip: '12515',
    city: 'Clintondale',
    state: 'NY',
    latitude: '41.673',
    longitude: '-74.05992',
    timeZoneId: 'America/New_York',
  },
  '12516': {
    zip: '12516',
    city: 'Copake',
    state: 'NY',
    latitude: '42.118527',
    longitude: '-73.556302',
    timeZoneId: 'America/New_York',
  },
  '12517': {
    zip: '12517',
    city: 'Copake Falls',
    state: 'NY',
    latitude: '42.139997',
    longitude: '-73.500933',
    timeZoneId: 'America/New_York',
  },
  '12518': {
    zip: '12518',
    city: 'Cornwall',
    state: 'NY',
    latitude: '41.432794',
    longitude: '-74.045095',
    timeZoneId: 'America/New_York',
  },
  '12520': {
    zip: '12520',
    city: 'Cornwall on Hudson',
    state: 'NY',
    latitude: '41.442299',
    longitude: '-74.015486',
    timeZoneId: 'America/New_York',
  },
  '12521': {
    zip: '12521',
    city: 'Craryville',
    state: 'NY',
    latitude: '42.18132',
    longitude: '-73.65564',
    timeZoneId: 'America/New_York',
  },
  '12522': {
    zip: '12522',
    city: 'Dover Plains',
    state: 'NY',
    latitude: '41.7177',
    longitude: '-73.58208',
    timeZoneId: 'America/New_York',
  },
  '12523': {
    zip: '12523',
    city: 'Elizaville',
    state: 'NY',
    latitude: '42.0726',
    longitude: '-73.792544',
    timeZoneId: 'America/New_York',
  },
  '12524': {
    zip: '12524',
    city: 'Fishkill',
    state: 'NY',
    latitude: '41.541709',
    longitude: '-73.902683',
    timeZoneId: 'America/New_York',
  },
  '12525': {
    zip: '12525',
    city: 'Gardiner',
    state: 'NY',
    latitude: '41.662675',
    longitude: '-74.149433',
    timeZoneId: 'America/New_York',
  },
  '12526': {
    zip: '12526',
    city: 'Germantown',
    state: 'NY',
    latitude: '42.13404',
    longitude: '-73.85034',
    timeZoneId: 'America/New_York',
  },
  '12527': {
    zip: '12527',
    city: 'Glenham',
    state: 'NY',
    latitude: '41.521756',
    longitude: '-73.937292',
    timeZoneId: 'America/New_York',
  },
  '12528': {
    zip: '12528',
    city: 'Highland',
    state: 'NY',
    latitude: '41.71752',
    longitude: '-74.00562',
    timeZoneId: 'America/New_York',
  },
  '12529': {
    zip: '12529',
    city: 'Hillsdale',
    state: 'NY',
    latitude: '42.19512',
    longitude: '-73.56714',
    timeZoneId: 'America/New_York',
  },
  '12530': {
    zip: '12530',
    city: 'Hollowville',
    state: 'NY',
    latitude: '42.225766',
    longitude: '-73.677514',
    timeZoneId: 'America/New_York',
  },
  '12531': {
    zip: '12531',
    city: 'Holmes',
    state: 'NY',
    latitude: '41.5047',
    longitude: '-73.66164',
    timeZoneId: 'America/New_York',
  },
  '12533': {
    zip: '12533',
    city: 'Hopewell Junction',
    state: 'NY',
    latitude: '41.57359',
    longitude: '-73.796075',
    timeZoneId: 'America/New_York',
  },
  '12534': {
    zip: '12534',
    city: 'Hudson',
    state: 'NY',
    latitude: '42.248254',
    longitude: '-73.774447',
    timeZoneId: 'America/New_York',
  },
  '12537': {
    zip: '12537',
    city: 'Hughsonville',
    state: 'NY',
    latitude: '41.583149',
    longitude: '-73.927217',
    timeZoneId: 'America/New_York',
  },
  '12538': {
    zip: '12538',
    city: 'Hyde Park',
    state: 'NY',
    latitude: '41.78268',
    longitude: '-73.89132',
    timeZoneId: 'America/New_York',
  },
  '12540': {
    zip: '12540',
    city: 'Lagrangeville',
    state: 'NY',
    latitude: '41.66832',
    longitude: '-73.7439',
    timeZoneId: 'America/New_York',
  },
  '12541': {
    zip: '12541',
    city: 'Livingston',
    state: 'NY',
    latitude: '42.141513',
    longitude: '-73.7782',
    timeZoneId: 'America/New_York',
  },
  '12542': {
    zip: '12542',
    city: 'Marlboro',
    state: 'NY',
    latitude: '41.608715',
    longitude: '-73.992141',
    timeZoneId: 'America/New_York',
  },
  '12543': {
    zip: '12543',
    city: 'Maybrook',
    state: 'NY',
    latitude: '41.486349',
    longitude: '-74.212862',
    timeZoneId: 'America/New_York',
  },
  '12544': {
    zip: '12544',
    city: 'Mellenville',
    state: 'NY',
    latitude: '42.250628',
    longitude: '-73.666472',
    timeZoneId: 'America/New_York',
  },
  '12545': {
    zip: '12545',
    city: 'Millbrook',
    state: 'NY',
    latitude: '41.76984',
    longitude: '-73.66416',
    timeZoneId: 'America/New_York',
  },
  '12546': {
    zip: '12546',
    city: 'Millerton',
    state: 'NY',
    latitude: '41.997293',
    longitude: '-73.543272',
    timeZoneId: 'America/New_York',
  },
  '12547': {
    zip: '12547',
    city: 'Milton',
    state: 'NY',
    latitude: '41.655535',
    longitude: '-73.977058',
    timeZoneId: 'America/New_York',
  },
  '12548': {
    zip: '12548',
    city: 'Modena',
    state: 'NY',
    latitude: '41.655818',
    longitude: '-74.112788',
    timeZoneId: 'America/New_York',
  },
  '12549': {
    zip: '12549',
    city: 'Montgomery',
    state: 'NY',
    latitude: '41.525576',
    longitude: '-74.236316',
    timeZoneId: 'America/New_York',
  },
  '12550': {
    zip: '12550',
    city: 'Newburgh',
    state: 'NY',
    latitude: '41.51748',
    longitude: '-74.06022',
    timeZoneId: 'America/New_York',
  },
  '12551': {
    zip: '12551',
    city: 'Newburgh',
    state: 'NY',
    latitude: '41.540771',
    longitude: '-74.046731',
    timeZoneId: 'America/New_York',
  },
  '12552': {
    zip: '12552',
    city: 'Newburgh',
    state: 'NY',
    latitude: '41.538018',
    longitude: '-74.049012',
    timeZoneId: 'America/New_York',
  },
  '12553': {
    zip: '12553',
    city: 'New Windsor',
    state: 'NY',
    latitude: '41.4702',
    longitude: '-74.07006',
    timeZoneId: 'America/New_York',
  },
  '12555': {
    zip: '12555',
    city: 'Newburgh',
    state: 'NY',
    latitude: '41.508604',
    longitude: '-74.013373',
    timeZoneId: 'America/New_York',
  },
  '12561': {
    zip: '12561',
    city: 'New Paltz',
    state: 'NY',
    latitude: '41.740002',
    longitude: '-74.095014',
    timeZoneId: 'America/New_York',
  },
  '12563': {
    zip: '12563',
    city: 'Patterson',
    state: 'NY',
    latitude: '41.503033',
    longitude: '-73.577046',
    timeZoneId: 'America/New_York',
  },
  '12564': {
    zip: '12564',
    city: 'Pawling',
    state: 'NY',
    latitude: '41.58714',
    longitude: '-73.58946',
    timeZoneId: 'America/New_York',
  },
  '12565': {
    zip: '12565',
    city: 'Philmont',
    state: 'NY',
    latitude: '42.24815',
    longitude: '-73.649027',
    timeZoneId: 'America/New_York',
  },
  '12566': {
    zip: '12566',
    city: 'Pine Bush',
    state: 'NY',
    latitude: '41.605775',
    longitude: '-74.305189',
    timeZoneId: 'America/New_York',
  },
  '12567': {
    zip: '12567',
    city: 'Pine Plains',
    state: 'NY',
    latitude: '41.99316',
    longitude: '-73.66674',
    timeZoneId: 'America/New_York',
  },
  '12568': {
    zip: '12568',
    city: 'Plattekill',
    state: 'NY',
    latitude: '41.612267',
    longitude: '-74.06228',
    timeZoneId: 'America/New_York',
  },
  '12569': {
    zip: '12569',
    city: 'Pleasant Valley',
    state: 'NY',
    latitude: '41.746335',
    longitude: '-73.817266',
    timeZoneId: 'America/New_York',
  },
  '12570': {
    zip: '12570',
    city: 'Poughquag',
    state: 'NY',
    latitude: '41.644619',
    longitude: '-73.674092',
    timeZoneId: 'America/New_York',
  },
  '12571': {
    zip: '12571',
    city: 'Red Hook',
    state: 'NY',
    latitude: '42.00888',
    longitude: '-73.84014',
    timeZoneId: 'America/New_York',
  },
  '12572': {
    zip: '12572',
    city: 'Rhinebeck',
    state: 'NY',
    latitude: '41.926105',
    longitude: '-73.894669',
    timeZoneId: 'America/New_York',
  },
  '12574': {
    zip: '12574',
    city: 'Rhinecliff',
    state: 'NY',
    latitude: '41.910591',
    longitude: '-73.951351',
    timeZoneId: 'America/New_York',
  },
  '12575': {
    zip: '12575',
    city: 'Rock Tavern',
    state: 'NY',
    latitude: '41.473305',
    longitude: '-74.146634',
    timeZoneId: 'America/New_York',
  },
  '12577': {
    zip: '12577',
    city: 'Salisbury Mills',
    state: 'NY',
    latitude: '41.433031',
    longitude: '-74.1214',
    timeZoneId: 'America/New_York',
  },
  '12578': {
    zip: '12578',
    city: 'Salt Point',
    state: 'NY',
    latitude: '41.796696',
    longitude: '-73.794052',
    timeZoneId: 'America/New_York',
  },
  '12580': {
    zip: '12580',
    city: 'Staatsburg',
    state: 'NY',
    latitude: '41.846035',
    longitude: '-73.910551',
    timeZoneId: 'America/New_York',
  },
  '12581': {
    zip: '12581',
    city: 'Stanfordville',
    state: 'NY',
    latitude: '41.890719',
    longitude: '-73.666476',
    timeZoneId: 'America/New_York',
  },
  '12582': {
    zip: '12582',
    city: 'Stormville',
    state: 'NY',
    latitude: '41.543279',
    longitude: '-73.725207',
    timeZoneId: 'America/New_York',
  },
  '12583': {
    zip: '12583',
    city: 'Tivoli',
    state: 'NY',
    latitude: '42.0573',
    longitude: '-73.88676',
    timeZoneId: 'America/New_York',
  },
  '12584': {
    zip: '12584',
    city: 'Vails Gate',
    state: 'NY',
    latitude: '41.466225',
    longitude: '-74.049239',
    timeZoneId: 'America/New_York',
  },
  '12585': {
    zip: '12585',
    city: 'Verbank',
    state: 'NY',
    latitude: '41.719922',
    longitude: '-73.70086',
    timeZoneId: 'America/New_York',
  },
  '12586': {
    zip: '12586',
    city: 'Walden',
    state: 'NY',
    latitude: '41.562389',
    longitude: '-74.173819',
    timeZoneId: 'America/New_York',
  },
  '12588': {
    zip: '12588',
    city: 'Walker Valley',
    state: 'NY',
    latitude: '41.633499',
    longitude: '-74.377182',
    timeZoneId: 'America/New_York',
  },
  '12589': {
    zip: '12589',
    city: 'Wallkill',
    state: 'NY',
    latitude: '41.617824',
    longitude: '-74.149503',
    timeZoneId: 'America/New_York',
  },
  '12590': {
    zip: '12590',
    city: 'Wappingers Falls',
    state: 'NY',
    latitude: '41.595832',
    longitude: '-73.88745',
    timeZoneId: 'America/New_York',
  },
  '12592': {
    zip: '12592',
    city: 'Wassaic',
    state: 'NY',
    latitude: '41.78198',
    longitude: '-73.545429',
    timeZoneId: 'America/New_York',
  },
  '12594': {
    zip: '12594',
    city: 'Wingdale',
    state: 'NY',
    latitude: '41.669591',
    longitude: '-73.558676',
    timeZoneId: 'America/New_York',
  },
  '12601': {
    zip: '12601',
    city: 'Poughkeepsie',
    state: 'NY',
    latitude: '41.701664',
    longitude: '-73.916805',
    timeZoneId: 'America/New_York',
  },
  '12602': {
    zip: '12602',
    city: 'Poughkeepsie',
    state: 'NY',
    latitude: '41.706233',
    longitude: '-73.92854',
    timeZoneId: 'America/New_York',
  },
  '12603': {
    zip: '12603',
    city: 'Poughkeepsie',
    state: 'NY',
    latitude: '41.69706',
    longitude: '-73.8624',
    timeZoneId: 'America/New_York',
  },
  '12604': {
    zip: '12604',
    city: 'Poughkeepsie',
    state: 'NY',
    latitude: '41.685996',
    longitude: '-73.896069',
    timeZoneId: 'America/New_York',
  },
  '12701': {
    zip: '12701',
    city: 'Monticello',
    state: 'NY',
    latitude: '41.68932',
    longitude: '-74.6808',
    timeZoneId: 'America/New_York',
  },
  '12719': {
    zip: '12719',
    city: 'Barryville',
    state: 'NY',
    latitude: '41.483085',
    longitude: '-74.910653',
    timeZoneId: 'America/New_York',
  },
  '12720': {
    zip: '12720',
    city: 'Bethel',
    state: 'NY',
    latitude: '41.662164',
    longitude: '-74.907661',
    timeZoneId: 'America/New_York',
  },
  '12721': {
    zip: '12721',
    city: 'Bloomingburg',
    state: 'NY',
    latitude: '41.551493',
    longitude: '-74.452492',
    timeZoneId: 'America/New_York',
  },
  '12722': {
    zip: '12722',
    city: 'Burlingham',
    state: 'NY',
    latitude: '41.589021',
    longitude: '-74.383635',
    timeZoneId: 'America/New_York',
  },
  '12723': {
    zip: '12723',
    city: 'Callicoon',
    state: 'NY',
    latitude: '41.781826',
    longitude: '-74.983676',
    timeZoneId: 'America/New_York',
  },
  '12724': {
    zip: '12724',
    city: 'Callicoon Center',
    state: 'NY',
    latitude: '41.839043',
    longitude: '-74.946526',
    timeZoneId: 'America/New_York',
  },
  '12725': {
    zip: '12725',
    city: 'Claryville',
    state: 'NY',
    latitude: '41.974754',
    longitude: '-74.541152',
    timeZoneId: 'America/New_York',
  },
  '12726': {
    zip: '12726',
    city: 'Cochecton',
    state: 'NY',
    latitude: '41.75964',
    longitude: '-74.79288',
    timeZoneId: 'America/New_York',
  },
  '12727': {
    zip: '12727',
    city: 'Cochecton',
    state: 'NY',
    latitude: '41.640608',
    longitude: '-74.96813',
    timeZoneId: 'America/New_York',
  },
  '12729': {
    zip: '12729',
    city: 'Cuddebackville',
    state: 'NY',
    latitude: '41.485047',
    longitude: '-74.627581',
    timeZoneId: 'America/New_York',
  },
  '12732': {
    zip: '12732',
    city: 'Eldred',
    state: 'NY',
    latitude: '41.526668',
    longitude: '-74.889314',
    timeZoneId: 'America/New_York',
  },
  '12733': {
    zip: '12733',
    city: 'Fallsburg',
    state: 'NY',
    latitude: '41.735601',
    longitude: '-74.613484',
    timeZoneId: 'America/New_York',
  },
  '12734': {
    zip: '12734',
    city: 'Ferndale',
    state: 'NY',
    latitude: '41.726667',
    longitude: '-74.762909',
    timeZoneId: 'America/New_York',
  },
  '12736': {
    zip: '12736',
    city: 'Fremont Center',
    state: 'NY',
    latitude: '41.848718',
    longitude: '-75.02435',
    timeZoneId: 'America/New_York',
  },
  '12737': {
    zip: '12737',
    city: 'Glen Spey',
    state: 'NY',
    latitude: '41.476622',
    longitude: '-74.789321',
    timeZoneId: 'America/New_York',
  },
  '12738': {
    zip: '12738',
    city: 'Glen Wild',
    state: 'NY',
    latitude: '41.63425',
    longitude: '-74.594325',
    timeZoneId: 'America/New_York',
  },
  '12740': {
    zip: '12740',
    city: 'Grahamsville',
    state: 'NY',
    latitude: '41.888409',
    longitude: '-74.521026',
    timeZoneId: 'America/New_York',
  },
  '12741': {
    zip: '12741',
    city: 'Hankins',
    state: 'NY',
    latitude: '41.826399',
    longitude: '-75.100729',
    timeZoneId: 'America/New_York',
  },
  '12742': {
    zip: '12742',
    city: 'Harris',
    state: 'NY',
    latitude: '41.718078',
    longitude: '-74.72847',
    timeZoneId: 'America/New_York',
  },
  '12743': {
    zip: '12743',
    city: 'Highland Lake',
    state: 'NY',
    latitude: '41.526306',
    longitude: '-74.846729',
    timeZoneId: 'America/New_York',
  },
  '12745': {
    zip: '12745',
    city: 'Hortonville',
    state: 'NY',
    latitude: '41.787313',
    longitude: '-75.028169',
    timeZoneId: 'America/New_York',
  },
  '12746': {
    zip: '12746',
    city: 'Huguenot',
    state: 'NY',
    latitude: '41.412347',
    longitude: '-74.641586',
    timeZoneId: 'America/New_York',
  },
  '12747': {
    zip: '12747',
    city: 'Hurleyville',
    state: 'NY',
    latitude: '41.769272',
    longitude: '-74.658509',
    timeZoneId: 'America/New_York',
  },
  '12748': {
    zip: '12748',
    city: 'Jeffersonville',
    state: 'NY',
    latitude: '41.74548',
    longitude: '-74.74686',
    timeZoneId: 'America/New_York',
  },
  '12749': {
    zip: '12749',
    city: 'Kauneonga Lake',
    state: 'NY',
    latitude: '41.690769',
    longitude: '-74.844513',
    timeZoneId: 'America/New_York',
  },
  '12750': {
    zip: '12750',
    city: 'Kenoza Lake',
    state: 'NY',
    latitude: '41.778207',
    longitude: '-74.93345',
    timeZoneId: 'America/New_York',
  },
  '12751': {
    zip: '12751',
    city: 'Kiamesha Lake',
    state: 'NY',
    latitude: '41.680968',
    longitude: '-74.674693',
    timeZoneId: 'America/New_York',
  },
  '12752': {
    zip: '12752',
    city: 'Lake Huntington',
    state: 'NY',
    latitude: '41.687177',
    longitude: '-74.991409',
    timeZoneId: 'America/New_York',
  },
  '12754': {
    zip: '12754',
    city: 'Liberty',
    state: 'NY',
    latitude: '41.798793',
    longitude: '-74.749431',
    timeZoneId: 'America/New_York',
  },
  '12758': {
    zip: '12758',
    city: 'Livingston Manor',
    state: 'NY',
    latitude: '41.912654',
    longitude: '-74.754347',
    timeZoneId: 'America/New_York',
  },
  '12759': {
    zip: '12759',
    city: 'Loch Sheldrake',
    state: 'NY',
    latitude: '41.78436',
    longitude: '-74.6598',
    timeZoneId: 'America/New_York',
  },
  '12760': {
    zip: '12760',
    city: 'Long Eddy',
    state: 'NY',
    latitude: '41.869262',
    longitude: '-75.080306',
    timeZoneId: 'America/New_York',
  },
  '12762': {
    zip: '12762',
    city: 'Mongaup Valley',
    state: 'NY',
    latitude: '41.688622',
    longitude: '-74.802774',
    timeZoneId: 'America/New_York',
  },
  '12763': {
    zip: '12763',
    city: 'Mountain Dale',
    state: 'NY',
    latitude: '41.691362',
    longitude: '-74.525101',
    timeZoneId: 'America/New_York',
  },
  '12764': {
    zip: '12764',
    city: 'Narrowsburg',
    state: 'NY',
    latitude: '41.587155',
    longitude: '-74.978315',
    timeZoneId: 'America/New_York',
  },
  '12765': {
    zip: '12765',
    city: 'Neversink',
    state: 'NY',
    latitude: '41.853274',
    longitude: '-74.62189',
    timeZoneId: 'America/New_York',
  },
  '12766': {
    zip: '12766',
    city: 'North Branch',
    state: 'NY',
    latitude: '41.807528',
    longitude: '-74.998309',
    timeZoneId: 'America/New_York',
  },
  '12767': {
    zip: '12767',
    city: 'Obernburg',
    state: 'NY',
    latitude: '41.846503',
    longitude: '-75.007705',
    timeZoneId: 'America/New_York',
  },
  '12768': {
    zip: '12768',
    city: 'Parksville',
    state: 'NY',
    latitude: '41.868458',
    longitude: '-74.721907',
    timeZoneId: 'America/New_York',
  },
  '12769': {
    zip: '12769',
    city: 'Phillipsport',
    state: 'NY',
    latitude: '41.649429',
    longitude: '-74.439099',
    timeZoneId: 'America/New_York',
  },
  '12770': {
    zip: '12770',
    city: 'Pond Eddy',
    state: 'NY',
    latitude: '41.454785',
    longitude: '-74.852456',
    timeZoneId: 'America/New_York',
  },
  '12771': {
    zip: '12771',
    city: 'Port Jervis',
    state: 'NY',
    latitude: '41.373715',
    longitude: '-74.665744',
    timeZoneId: 'America/New_York',
  },
  '12775': {
    zip: '12775',
    city: 'Rock Hill',
    state: 'NY',
    latitude: '41.616893',
    longitude: '-74.583219',
    timeZoneId: 'America/New_York',
  },
  '12776': {
    zip: '12776',
    city: 'Roscoe',
    state: 'NY',
    latitude: '41.966965',
    longitude: '-74.930453',
    timeZoneId: 'America/New_York',
  },
  '12777': {
    zip: '12777',
    city: 'Forestburgh',
    state: 'NY',
    latitude: '41.5653',
    longitude: '-74.60598',
    timeZoneId: 'America/New_York',
  },
  '12778': {
    zip: '12778',
    city: 'Smallwood',
    state: 'NY',
    latitude: '41.663676',
    longitude: '-74.820124',
    timeZoneId: 'America/New_York',
  },
  '12779': {
    zip: '12779',
    city: 'South Fallsburg',
    state: 'NY',
    latitude: '41.709537',
    longitude: '-74.635185',
    timeZoneId: 'America/New_York',
  },
  '12780': {
    zip: '12780',
    city: 'Sparrow Bush',
    state: 'NY',
    latitude: '41.432778',
    longitude: '-74.720524',
    timeZoneId: 'America/New_York',
  },
  '12781': {
    zip: '12781',
    city: 'Summitville',
    state: 'NY',
    latitude: '41.61928',
    longitude: '-74.448438',
    timeZoneId: 'America/New_York',
  },
  '12783': {
    zip: '12783',
    city: 'Swan Lake',
    state: 'NY',
    latitude: '41.740653',
    longitude: '-74.792651',
    timeZoneId: 'America/New_York',
  },
  '12784': {
    zip: '12784',
    city: 'Thompsonville',
    state: 'NY',
    latitude: '41.671144',
    longitude: '-74.626556',
    timeZoneId: 'America/New_York',
  },
  '12785': {
    zip: '12785',
    city: 'Westbrookville',
    state: 'NY',
    latitude: '41.495716',
    longitude: '-74.561461',
    timeZoneId: 'America/New_York',
  },
  '12786': {
    zip: '12786',
    city: 'White Lake',
    state: 'NY',
    latitude: '41.648614',
    longitude: '-74.870751',
    timeZoneId: 'America/New_York',
  },
  '12787': {
    zip: '12787',
    city: 'White Sulphur Springs',
    state: 'NY',
    latitude: '41.7898',
    longitude: '-74.82839',
    timeZoneId: 'America/New_York',
  },
  '12788': {
    zip: '12788',
    city: 'Woodbourne',
    state: 'NY',
    latitude: '41.807448',
    longitude: '-74.609122',
    timeZoneId: 'America/New_York',
  },
  '12789': {
    zip: '12789',
    city: 'Woodridge',
    state: 'NY',
    latitude: '41.693208',
    longitude: '-74.58433',
    timeZoneId: 'America/New_York',
  },
  '12790': {
    zip: '12790',
    city: 'Wurtsboro',
    state: 'NY',
    latitude: '41.590168',
    longitude: '-74.526249',
    timeZoneId: 'America/New_York',
  },
  '12791': {
    zip: '12791',
    city: 'Youngsville',
    state: 'NY',
    latitude: '41.80509',
    longitude: '-74.885943',
    timeZoneId: 'America/New_York',
  },
  '12792': {
    zip: '12792',
    city: 'Yulan',
    state: 'NY',
    latitude: '41.521354',
    longitude: '-74.929301',
    timeZoneId: 'America/New_York',
  },
  '12801': {
    zip: '12801',
    city: 'Glens Falls',
    state: 'NY',
    latitude: '43.3089',
    longitude: '-73.6446',
    timeZoneId: 'America/New_York',
  },
  '12803': {
    zip: '12803',
    city: 'South Glens Falls',
    state: 'NY',
    latitude: '43.28052',
    longitude: '-73.63002',
    timeZoneId: 'America/New_York',
  },
  '12804': {
    zip: '12804',
    city: 'Queensbury',
    state: 'NY',
    latitude: '43.37136',
    longitude: '-73.6761',
    timeZoneId: 'America/New_York',
  },
  '12808': {
    zip: '12808',
    city: 'Adirondack',
    state: 'NY',
    latitude: '43.733474',
    longitude: '-73.757263',
    timeZoneId: 'America/New_York',
  },
  '12809': {
    zip: '12809',
    city: 'Argyle',
    state: 'NY',
    latitude: '43.23678',
    longitude: '-73.45698',
    timeZoneId: 'America/New_York',
  },
  '12810': {
    zip: '12810',
    city: 'Athol',
    state: 'NY',
    latitude: '43.491212',
    longitude: '-73.896855',
    timeZoneId: 'America/New_York',
  },
  '12811': {
    zip: '12811',
    city: 'Bakers Mills',
    state: 'NY',
    latitude: '43.612146',
    longitude: '-74.025481',
    timeZoneId: 'America/New_York',
  },
  '12812': {
    zip: '12812',
    city: 'Blue Mountain Lake',
    state: 'NY',
    latitude: '43.835368',
    longitude: '-74.426553',
    timeZoneId: 'America/New_York',
  },
  '12814': {
    zip: '12814',
    city: 'Bolton Landing',
    state: 'NY',
    latitude: '43.5897',
    longitude: '-73.66848',
    timeZoneId: 'America/New_York',
  },
  '12815': {
    zip: '12815',
    city: 'Brant Lake',
    state: 'NY',
    latitude: '43.67155',
    longitude: '-73.76924',
    timeZoneId: 'America/New_York',
  },
  '12816': {
    zip: '12816',
    city: 'Cambridge',
    state: 'NY',
    latitude: '43.04088',
    longitude: '-73.3806',
    timeZoneId: 'America/New_York',
  },
  '12817': {
    zip: '12817',
    city: 'Chestertown',
    state: 'NY',
    latitude: '43.63599',
    longitude: '-73.803616',
    timeZoneId: 'America/New_York',
  },
  '12819': {
    zip: '12819',
    city: 'Clemons',
    state: 'NY',
    latitude: '43.639502',
    longitude: '-73.441071',
    timeZoneId: 'America/New_York',
  },
  '12820': {
    zip: '12820',
    city: 'Cleverdale',
    state: 'NY',
    latitude: '43.471968',
    longitude: '-73.639216',
    timeZoneId: 'America/New_York',
  },
  '12821': {
    zip: '12821',
    city: 'Comstock',
    state: 'NY',
    latitude: '43.462256',
    longitude: '-73.370925',
    timeZoneId: 'America/New_York',
  },
  '12822': {
    zip: '12822',
    city: 'Corinth',
    state: 'NY',
    latitude: '43.242443',
    longitude: '-73.838394',
    timeZoneId: 'America/New_York',
  },
  '12823': {
    zip: '12823',
    city: 'Cossayuna',
    state: 'NY',
    latitude: '43.17348',
    longitude: '-73.4115',
    timeZoneId: 'America/New_York',
  },
  '12824': {
    zip: '12824',
    city: 'Diamond Point',
    state: 'NY',
    latitude: '43.4853',
    longitude: '-73.69194',
    timeZoneId: 'America/New_York',
  },
  '12827': {
    zip: '12827',
    city: 'Fort Ann',
    state: 'NY',
    latitude: '43.4316',
    longitude: '-73.50918',
    timeZoneId: 'America/New_York',
  },
  '12828': {
    zip: '12828',
    city: 'Fort Edward',
    state: 'NY',
    latitude: '43.271395',
    longitude: '-73.58739',
    timeZoneId: 'America/New_York',
  },
  '12831': {
    zip: '12831',
    city: 'Gansevoort',
    state: 'NY',
    latitude: '43.19844',
    longitude: '-73.67826',
    timeZoneId: 'America/New_York',
  },
  '12832': {
    zip: '12832',
    city: 'Granville',
    state: 'NY',
    latitude: '43.38618',
    longitude: '-73.28058',
    timeZoneId: 'America/New_York',
  },
  '12833': {
    zip: '12833',
    city: 'Greenfield Center',
    state: 'NY',
    latitude: '43.14192',
    longitude: '-73.85586',
    timeZoneId: 'America/New_York',
  },
  '12834': {
    zip: '12834',
    city: 'Greenwich',
    state: 'NY',
    latitude: '43.090326',
    longitude: '-73.50008',
    timeZoneId: 'America/New_York',
  },
  '12835': {
    zip: '12835',
    city: 'Hadley',
    state: 'NY',
    latitude: '43.336182',
    longitude: '-73.985758',
    timeZoneId: 'America/New_York',
  },
  '12836': {
    zip: '12836',
    city: 'Hague',
    state: 'NY',
    latitude: '43.788709',
    longitude: '-73.497895',
    timeZoneId: 'America/New_York',
  },
  '12837': {
    zip: '12837',
    city: 'Hampton',
    state: 'NY',
    latitude: '43.519469',
    longitude: '-73.252209',
    timeZoneId: 'America/New_York',
  },
  '12838': {
    zip: '12838',
    city: 'Hartford',
    state: 'NY',
    latitude: '43.335141',
    longitude: '-73.402119',
    timeZoneId: 'America/New_York',
  },
  '12839': {
    zip: '12839',
    city: 'Hudson Falls',
    state: 'NY',
    latitude: '43.32084',
    longitude: '-73.5768',
    timeZoneId: 'America/New_York',
  },
  '12841': {
    zip: '12841',
    city: 'Huletts Landing',
    state: 'NY',
    latitude: '43.640008',
    longitude: '-73.506355',
    timeZoneId: 'America/New_York',
  },
  '12842': {
    zip: '12842',
    city: 'Indian Lake',
    state: 'NY',
    latitude: '43.746756',
    longitude: '-74.262085',
    timeZoneId: 'America/New_York',
  },
  '12843': {
    zip: '12843',
    city: 'Johnsburg',
    state: 'NY',
    latitude: '43.59355',
    longitude: '-73.974572',
    timeZoneId: 'America/New_York',
  },
  '12844': {
    zip: '12844',
    city: 'Kattskill Bay',
    state: 'NY',
    latitude: '43.515833',
    longitude: '-73.629722',
    timeZoneId: 'America/New_York',
  },
  '12845': {
    zip: '12845',
    city: 'Lake George',
    state: 'NY',
    latitude: '43.41696',
    longitude: '-73.6848',
    timeZoneId: 'America/New_York',
  },
  '12846': {
    zip: '12846',
    city: 'Lake Luzerne',
    state: 'NY',
    latitude: '43.32678',
    longitude: '-73.82022',
    timeZoneId: 'America/New_York',
  },
  '12847': {
    zip: '12847',
    city: 'Long Lake',
    state: 'NY',
    latitude: '43.968517',
    longitude: '-74.418773',
    timeZoneId: 'America/New_York',
  },
  '12848': {
    zip: '12848',
    city: 'Middle Falls',
    state: 'NY',
    latitude: '43.102413',
    longitude: '-73.526359',
    timeZoneId: 'America/New_York',
  },
  '12849': {
    zip: '12849',
    city: 'Middle Granville',
    state: 'NY',
    latitude: '43.451548',
    longitude: '-73.301373',
    timeZoneId: 'America/New_York',
  },
  '12850': {
    zip: '12850',
    city: 'Middle Grove',
    state: 'NY',
    latitude: '43.1187',
    longitude: '-73.9875',
    timeZoneId: 'America/New_York',
  },
  '12851': {
    zip: '12851',
    city: 'Minerva',
    state: 'NY',
    latitude: '43.787706',
    longitude: '-73.997818',
    timeZoneId: 'America/New_York',
  },
  '12852': {
    zip: '12852',
    city: 'Newcomb',
    state: 'NY',
    latitude: '44.017069',
    longitude: '-74.127361',
    timeZoneId: 'America/New_York',
  },
  '12853': {
    zip: '12853',
    city: 'North Creek',
    state: 'NY',
    latitude: '43.721592',
    longitude: '-73.920875',
    timeZoneId: 'America/New_York',
  },
  '12854': {
    zip: '12854',
    city: 'North Granville',
    state: 'NY',
    latitude: '43.507018',
    longitude: '-73.335407',
    timeZoneId: 'America/New_York',
  },
  '12855': {
    zip: '12855',
    city: 'North Hudson',
    state: 'NY',
    latitude: '44.002417',
    longitude: '-73.80035',
    timeZoneId: 'America/New_York',
  },
  '12856': {
    zip: '12856',
    city: 'North River',
    state: 'NY',
    latitude: '43.740463',
    longitude: '-74.050649',
    timeZoneId: 'America/New_York',
  },
  '12857': {
    zip: '12857',
    city: 'Olmstedville',
    state: 'NY',
    latitude: '43.827352',
    longitude: '-73.937295',
    timeZoneId: 'America/New_York',
  },
  '12858': {
    zip: '12858',
    city: 'Paradox',
    state: 'NY',
    latitude: '43.89409',
    longitude: '-73.638656',
    timeZoneId: 'America/New_York',
  },
  '12859': {
    zip: '12859',
    city: 'Porter Corners',
    state: 'NY',
    latitude: '43.17366',
    longitude: '-73.88976',
    timeZoneId: 'America/New_York',
  },
  '12860': {
    zip: '12860',
    city: 'Pottersville',
    state: 'NY',
    latitude: '43.697539',
    longitude: '-73.757282',
    timeZoneId: 'America/New_York',
  },
  '12861': {
    zip: '12861',
    city: 'Putnam Station',
    state: 'NY',
    latitude: '43.749622',
    longitude: '-73.417889',
    timeZoneId: 'America/New_York',
  },
  '12862': {
    zip: '12862',
    city: 'Riparius',
    state: 'NY',
    latitude: '43.66166',
    longitude: '-73.897245',
    timeZoneId: 'America/New_York',
  },
  '12863': {
    zip: '12863',
    city: 'Rock City Falls',
    state: 'NY',
    latitude: '43.06261',
    longitude: '-73.924524',
    timeZoneId: 'America/New_York',
  },
  '12864': {
    zip: '12864',
    city: 'Sabael',
    state: 'NY',
    latitude: '43.728403',
    longitude: '-74.305522',
    timeZoneId: 'America/New_York',
  },
  '12865': {
    zip: '12865',
    city: 'Salem',
    state: 'NY',
    latitude: '43.152744',
    longitude: '-73.342735',
    timeZoneId: 'America/New_York',
  },
  '12866': {
    zip: '12866',
    city: 'Saratoga Springs',
    state: 'NY',
    latitude: '43.075993',
    longitude: '-73.774821',
    timeZoneId: 'America/New_York',
  },
  '12870': {
    zip: '12870',
    city: 'Schroon Lake',
    state: 'NY',
    latitude: '43.8396',
    longitude: '-73.76022',
    timeZoneId: 'America/New_York',
  },
  '12871': {
    zip: '12871',
    city: 'Schuylerville',
    state: 'NY',
    latitude: '43.08846',
    longitude: '-73.62276',
    timeZoneId: 'America/New_York',
  },
  '12872': {
    zip: '12872',
    city: 'Severance',
    state: 'NY',
    latitude: '43.884025',
    longitude: '-73.72439',
    timeZoneId: 'America/New_York',
  },
  '12873': {
    zip: '12873',
    city: 'Shushan',
    state: 'NY',
    latitude: '43.127464',
    longitude: '-73.30747',
    timeZoneId: 'America/New_York',
  },
  '12874': {
    zip: '12874',
    city: 'Silver Bay',
    state: 'NY',
    latitude: '43.6675',
    longitude: '-73.514444',
    timeZoneId: 'America/New_York',
  },
  '12878': {
    zip: '12878',
    city: 'Stony Creek',
    state: 'NY',
    latitude: '43.426602',
    longitude: '-73.962388',
    timeZoneId: 'America/New_York',
  },
  '12879': {
    zip: '12879',
    city: 'Newcomb',
    state: 'NY',
    latitude: '44.018002',
    longitude: '-74.12899',
    timeZoneId: 'America/New_York',
  },
  '12883': {
    zip: '12883',
    city: 'Ticonderoga',
    state: 'NY',
    latitude: '43.843857',
    longitude: '-73.431459',
    timeZoneId: 'America/New_York',
  },
  '12884': {
    zip: '12884',
    city: 'Victory Mills',
    state: 'NY',
    latitude: '43.088703',
    longitude: '-73.594357',
    timeZoneId: 'America/New_York',
  },
  '12885': {
    zip: '12885',
    city: 'Warrensburg',
    state: 'NY',
    latitude: '43.500656',
    longitude: '-73.787701',
    timeZoneId: 'America/New_York',
  },
  '12886': {
    zip: '12886',
    city: 'Wevertown',
    state: 'NY',
    latitude: '43.642397',
    longitude: '-73.900698',
    timeZoneId: 'America/New_York',
  },
  '12887': {
    zip: '12887',
    city: 'Whitehall',
    state: 'NY',
    latitude: '43.55448',
    longitude: '-73.40196',
    timeZoneId: 'America/New_York',
  },
  '12901': {
    zip: '12901',
    city: 'Plattsburgh',
    state: 'NY',
    latitude: '44.72796',
    longitude: '-73.4631',
    timeZoneId: 'America/New_York',
  },
  '12903': {
    zip: '12903',
    city: 'Plattsburgh',
    state: 'NY',
    latitude: '44.707491',
    longitude: '-73.475457',
    timeZoneId: 'America/New_York',
  },
  '12910': {
    zip: '12910',
    city: 'Altona',
    state: 'NY',
    latitude: '44.882237',
    longitude: '-73.662504',
    timeZoneId: 'America/New_York',
  },
  '12911': {
    zip: '12911',
    city: 'Keeseville',
    state: 'NY',
    latitude: '44.533729',
    longitude: '-73.50029',
    timeZoneId: 'America/New_York',
  },
  '12912': {
    zip: '12912',
    city: 'Au Sable Forks',
    state: 'NY',
    latitude: '44.459424',
    longitude: '-73.713927',
    timeZoneId: 'America/New_York',
  },
  '12913': {
    zip: '12913',
    city: 'Bloomingdale',
    state: 'NY',
    latitude: '44.398502',
    longitude: '-74.070744',
    timeZoneId: 'America/New_York',
  },
  '12914': {
    zip: '12914',
    city: 'Bombay',
    state: 'NY',
    latitude: '44.953343',
    longitude: '-74.599279',
    timeZoneId: 'America/New_York',
  },
  '12915': {
    zip: '12915',
    city: 'Brainardsville',
    state: 'NY',
    latitude: '44.857117',
    longitude: '-74.034613',
    timeZoneId: 'America/New_York',
  },
  '12916': {
    zip: '12916',
    city: 'Brushton',
    state: 'NY',
    latitude: '44.827852',
    longitude: '-74.530013',
    timeZoneId: 'America/New_York',
  },
  '12917': {
    zip: '12917',
    city: 'Burke',
    state: 'NY',
    latitude: '44.926563',
    longitude: '-74.176701',
    timeZoneId: 'America/New_York',
  },
  '12918': {
    zip: '12918',
    city: 'Cadyville',
    state: 'NY',
    latitude: '44.672587',
    longitude: '-73.664389',
    timeZoneId: 'America/New_York',
  },
  '12919': {
    zip: '12919',
    city: 'Champlain',
    state: 'NY',
    latitude: '44.96514',
    longitude: '-73.4343',
    timeZoneId: 'America/New_York',
  },
  '12920': {
    zip: '12920',
    city: 'Chateaugay',
    state: 'NY',
    latitude: '44.92315',
    longitude: '-74.082893',
    timeZoneId: 'America/New_York',
  },
  '12921': {
    zip: '12921',
    city: 'Chazy',
    state: 'NY',
    latitude: '44.889987',
    longitude: '-73.466593',
    timeZoneId: 'America/New_York',
  },
  '12922': {
    zip: '12922',
    city: 'Childwold',
    state: 'NY',
    latitude: '44.272016',
    longitude: '-74.712447',
    timeZoneId: 'America/New_York',
  },
  '12923': {
    zip: '12923',
    city: 'Churubusco',
    state: 'NY',
    latitude: '44.952809',
    longitude: '-73.933845',
    timeZoneId: 'America/New_York',
  },
  '12924': {
    zip: '12924',
    city: 'Keeseville',
    state: 'NY',
    latitude: '44.473734',
    longitude: '-73.589677',
    timeZoneId: 'America/New_York',
  },
  '12926': {
    zip: '12926',
    city: 'Constable',
    state: 'NY',
    latitude: '44.94762',
    longitude: '-74.3373',
    timeZoneId: 'America/New_York',
  },
  '12927': {
    zip: '12927',
    city: 'Cranberry Lake',
    state: 'NY',
    latitude: '44.220251',
    longitude: '-74.832828',
    timeZoneId: 'America/New_York',
  },
  '12928': {
    zip: '12928',
    city: 'Crown Point',
    state: 'NY',
    latitude: '43.96698',
    longitude: '-73.46178',
    timeZoneId: 'America/New_York',
  },
  '12929': {
    zip: '12929',
    city: 'Dannemora',
    state: 'NY',
    latitude: '44.723692',
    longitude: '-73.72178',
    timeZoneId: 'America/New_York',
  },
  '12930': {
    zip: '12930',
    city: 'Dickinson Center',
    state: 'NY',
    latitude: '44.779444',
    longitude: '-74.498056',
    timeZoneId: 'America/New_York',
  },
  '12932': {
    zip: '12932',
    city: 'Elizabethtown',
    state: 'NY',
    latitude: '44.226627',
    longitude: '-73.624732',
    timeZoneId: 'America/New_York',
  },
  '12933': {
    zip: '12933',
    city: 'Ellenburg',
    state: 'NY',
    latitude: '44.894704',
    longitude: '-73.837993',
    timeZoneId: 'America/New_York',
  },
  '12934': {
    zip: '12934',
    city: 'Ellenburg Center',
    state: 'NY',
    latitude: '44.829883',
    longitude: '-73.859154',
    timeZoneId: 'America/New_York',
  },
  '12935': {
    zip: '12935',
    city: 'Ellenburg Depot',
    state: 'NY',
    latitude: '44.9268',
    longitude: '-73.80012',
    timeZoneId: 'America/New_York',
  },
  '12936': {
    zip: '12936',
    city: 'Essex',
    state: 'NY',
    latitude: '44.261839',
    longitude: '-73.374299',
    timeZoneId: 'America/New_York',
  },
  '12937': {
    zip: '12937',
    city: 'Fort Covington',
    state: 'NY',
    latitude: '44.956408',
    longitude: '-74.489215',
    timeZoneId: 'America/New_York',
  },
  '12939': {
    zip: '12939',
    city: 'Gabriels',
    state: 'NY',
    latitude: '44.432868',
    longitude: '-74.178382',
    timeZoneId: 'America/New_York',
  },
  '12941': {
    zip: '12941',
    city: 'Jay',
    state: 'NY',
    latitude: '44.353596',
    longitude: '-73.71053',
    timeZoneId: 'America/New_York',
  },
  '12942': {
    zip: '12942',
    city: 'Keene',
    state: 'NY',
    latitude: '44.257018',
    longitude: '-73.784256',
    timeZoneId: 'America/New_York',
  },
  '12943': {
    zip: '12943',
    city: 'Keene Valley',
    state: 'NY',
    latitude: '44.190442',
    longitude: '-73.785574',
    timeZoneId: 'America/New_York',
  },
  '12944': {
    zip: '12944',
    city: 'Keeseville',
    state: 'NY',
    latitude: '44.493052',
    longitude: '-73.480994',
    timeZoneId: 'America/New_York',
  },
  '12945': {
    zip: '12945',
    city: 'Lake Clear',
    state: 'NY',
    latitude: '44.36656',
    longitude: '-74.24733',
    timeZoneId: 'America/New_York',
  },
  '12946': {
    zip: '12946',
    city: 'Lake Placid',
    state: 'NY',
    latitude: '44.281966',
    longitude: '-73.988816',
    timeZoneId: 'America/New_York',
  },
  '12949': {
    zip: '12949',
    city: 'Lawrenceville',
    state: 'NY',
    latitude: '44.758806',
    longitude: '-74.662758',
    timeZoneId: 'America/New_York',
  },
  '12950': {
    zip: '12950',
    city: 'Lewis',
    state: 'NY',
    latitude: '44.314607',
    longitude: '-73.582276',
    timeZoneId: 'America/New_York',
  },
  '12952': {
    zip: '12952',
    city: 'Lyon Mountain',
    state: 'NY',
    latitude: '44.689167',
    longitude: '-73.949444',
    timeZoneId: 'America/New_York',
  },
  '12953': {
    zip: '12953',
    city: 'Malone',
    state: 'NY',
    latitude: '44.845371',
    longitude: '-74.269912',
    timeZoneId: 'America/New_York',
  },
  '12955': {
    zip: '12955',
    city: 'Lyon Mountain',
    state: 'NY',
    latitude: '44.793668',
    longitude: '-73.965388',
    timeZoneId: 'America/New_York',
  },
  '12956': {
    zip: '12956',
    city: 'Mineville',
    state: 'NY',
    latitude: '44.078155',
    longitude: '-73.517787',
    timeZoneId: 'America/New_York',
  },
  '12957': {
    zip: '12957',
    city: 'Moira',
    state: 'NY',
    latitude: '44.86812',
    longitude: '-74.56398',
    timeZoneId: 'America/New_York',
  },
  '12958': {
    zip: '12958',
    city: 'Mooers',
    state: 'NY',
    latitude: '44.961265',
    longitude: '-73.581139',
    timeZoneId: 'America/New_York',
  },
  '12959': {
    zip: '12959',
    city: 'Mooers Forks',
    state: 'NY',
    latitude: '44.965896',
    longitude: '-73.694298',
    timeZoneId: 'America/New_York',
  },
  '12960': {
    zip: '12960',
    city: 'Moriah',
    state: 'NY',
    latitude: '44.037103',
    longitude: '-73.516791',
    timeZoneId: 'America/New_York',
  },
  '12961': {
    zip: '12961',
    city: 'Moriah Center',
    state: 'NY',
    latitude: '44.053032',
    longitude: '-73.53527',
    timeZoneId: 'America/New_York',
  },
  '12962': {
    zip: '12962',
    city: 'Morrisonville',
    state: 'NY',
    latitude: '44.70354',
    longitude: '-73.57572',
    timeZoneId: 'America/New_York',
  },
  '12964': {
    zip: '12964',
    city: 'New Russia',
    state: 'NY',
    latitude: '44.147061',
    longitude: '-73.607718',
    timeZoneId: 'America/New_York',
  },
  '12965': {
    zip: '12965',
    city: 'Nicholville',
    state: 'NY',
    latitude: '44.705556',
    longitude: '-74.715278',
    timeZoneId: 'America/New_York',
  },
  '12966': {
    zip: '12966',
    city: 'North Bangor',
    state: 'NY',
    latitude: '44.81898',
    longitude: '-74.415',
    timeZoneId: 'America/New_York',
  },
  '12967': {
    zip: '12967',
    city: 'North Lawrence',
    state: 'NY',
    latitude: '44.761693',
    longitude: '-74.654141',
    timeZoneId: 'America/New_York',
  },
  '12969': {
    zip: '12969',
    city: 'Owls Head',
    state: 'NY',
    latitude: '44.7051',
    longitude: '-74.11182',
    timeZoneId: 'America/New_York',
  },
  '12970': {
    zip: '12970',
    city: 'Paul Smiths',
    state: 'NY',
    latitude: '44.434675',
    longitude: '-74.276736',
    timeZoneId: 'America/New_York',
  },
  '12972': {
    zip: '12972',
    city: 'Peru',
    state: 'NY',
    latitude: '44.57022',
    longitude: '-73.54716',
    timeZoneId: 'America/New_York',
  },
  '12973': {
    zip: '12973',
    city: 'Piercefield',
    state: 'NY',
    latitude: '44.22765',
    longitude: '-74.568181',
    timeZoneId: 'America/New_York',
  },
  '12974': {
    zip: '12974',
    city: 'Port Henry',
    state: 'NY',
    latitude: '44.045341',
    longitude: '-73.465362',
    timeZoneId: 'America/New_York',
  },
  '12975': {
    zip: '12975',
    city: 'Port Kent',
    state: 'NY',
    latitude: '44.527154',
    longitude: '-73.412205',
    timeZoneId: 'America/New_York',
  },
  '12976': {
    zip: '12976',
    city: 'Rainbow Lake',
    state: 'NY',
    latitude: '44.489959',
    longitude: '-74.121297',
    timeZoneId: 'America/New_York',
  },
  '12977': {
    zip: '12977',
    city: 'Ray Brook',
    state: 'NY',
    latitude: '44.299084',
    longitude: '-74.086284',
    timeZoneId: 'America/New_York',
  },
  '12978': {
    zip: '12978',
    city: 'Redford',
    state: 'NY',
    latitude: '44.609012',
    longitude: '-73.805908',
    timeZoneId: 'America/New_York',
  },
  '12979': {
    zip: '12979',
    city: 'Rouses Point',
    state: 'NY',
    latitude: '44.990534',
    longitude: '-73.367171',
    timeZoneId: 'America/New_York',
  },
  '12980': {
    zip: '12980',
    city: 'Saint Regis Falls',
    state: 'NY',
    latitude: '44.656564',
    longitude: '-74.564467',
    timeZoneId: 'America/New_York',
  },
  '12981': {
    zip: '12981',
    city: 'Saranac',
    state: 'NY',
    latitude: '44.679046',
    longitude: '-73.824306',
    timeZoneId: 'America/New_York',
  },
  '12983': {
    zip: '12983',
    city: 'Saranac Lake',
    state: 'NY',
    latitude: '44.31672',
    longitude: '-74.13354',
    timeZoneId: 'America/New_York',
  },
  '12985': {
    zip: '12985',
    city: 'Schuyler Falls',
    state: 'NY',
    latitude: '44.57898',
    longitude: '-73.71612',
    timeZoneId: 'America/New_York',
  },
  '12986': {
    zip: '12986',
    city: 'Tupper Lake',
    state: 'NY',
    latitude: '44.23505',
    longitude: '-74.46496',
    timeZoneId: 'America/New_York',
  },
  '12987': {
    zip: '12987',
    city: 'Upper Jay',
    state: 'NY',
    latitude: '44.329626',
    longitude: '-73.840421',
    timeZoneId: 'America/New_York',
  },
  '12989': {
    zip: '12989',
    city: 'Vermontville',
    state: 'NY',
    latitude: '44.469526',
    longitude: '-74.044265',
    timeZoneId: 'America/New_York',
  },
  '12992': {
    zip: '12992',
    city: 'West Chazy',
    state: 'NY',
    latitude: '44.78096',
    longitude: '-73.505322',
    timeZoneId: 'America/New_York',
  },
  '12993': {
    zip: '12993',
    city: 'Westport',
    state: 'NY',
    latitude: '44.229722',
    longitude: '-73.460833',
    timeZoneId: 'America/New_York',
  },
  '12995': {
    zip: '12995',
    city: 'Whippleville',
    state: 'NY',
    latitude: '44.808327',
    longitude: '-74.261237',
    timeZoneId: 'America/New_York',
  },
  '12996': {
    zip: '12996',
    city: 'Willsboro',
    state: 'NY',
    latitude: '44.37414',
    longitude: '-73.4172',
    timeZoneId: 'America/New_York',
  },
  '12997': {
    zip: '12997',
    city: 'Wilmington',
    state: 'NY',
    latitude: '44.388992',
    longitude: '-73.8437',
    timeZoneId: 'America/New_York',
  },
  '12998': {
    zip: '12998',
    city: 'Witherbee',
    state: 'NY',
    latitude: '44.086571',
    longitude: '-73.536128',
    timeZoneId: 'America/New_York',
  },
  '13020': {
    zip: '13020',
    city: 'Apulia Station',
    state: 'NY',
    latitude: '42.818065',
    longitude: '-76.07347',
    timeZoneId: 'America/New_York',
  },
  '13021': {
    zip: '13021',
    city: 'Auburn',
    state: 'NY',
    latitude: '42.920644',
    longitude: '-76.559964',
    timeZoneId: 'America/New_York',
  },
  '13022': {
    zip: '13022',
    city: 'Auburn',
    state: 'NY',
    latitude: '42.919963',
    longitude: '-76.55292',
    timeZoneId: 'America/New_York',
  },
  '13024': {
    zip: '13024',
    city: 'Auburn',
    state: 'NY',
    latitude: '42.920638',
    longitude: '-76.552564',
    timeZoneId: 'America/New_York',
  },
  '13026': {
    zip: '13026',
    city: 'Aurora',
    state: 'NY',
    latitude: '42.743286',
    longitude: '-76.652204',
    timeZoneId: 'America/New_York',
  },
  '13027': {
    zip: '13027',
    city: 'Baldwinsville',
    state: 'NY',
    latitude: '43.166729',
    longitude: '-76.339805',
    timeZoneId: 'America/New_York',
  },
  '13028': {
    zip: '13028',
    city: 'Bernhards Bay',
    state: 'NY',
    latitude: '43.264959',
    longitude: '-75.989179',
    timeZoneId: 'America/New_York',
  },
  '13029': {
    zip: '13029',
    city: 'Brewerton',
    state: 'NY',
    latitude: '43.22688',
    longitude: '-76.141632',
    timeZoneId: 'America/New_York',
  },
  '13030': {
    zip: '13030',
    city: 'Bridgeport',
    state: 'NY',
    latitude: '43.160577',
    longitude: '-75.98454',
    timeZoneId: 'America/New_York',
  },
  '13031': {
    zip: '13031',
    city: 'Camillus',
    state: 'NY',
    latitude: '43.044375',
    longitude: '-76.289134',
    timeZoneId: 'America/New_York',
  },
  '13032': {
    zip: '13032',
    city: 'Canastota',
    state: 'NY',
    latitude: '43.083843',
    longitude: '-75.768034',
    timeZoneId: 'America/New_York',
  },
  '13033': {
    zip: '13033',
    city: 'Cato',
    state: 'NY',
    latitude: '43.193419',
    longitude: '-76.565257',
    timeZoneId: 'America/New_York',
  },
  '13034': {
    zip: '13034',
    city: 'Cayuga',
    state: 'NY',
    latitude: '42.927604',
    longitude: '-76.720691',
    timeZoneId: 'America/New_York',
  },
  '13035': {
    zip: '13035',
    city: 'Cazenovia',
    state: 'NY',
    latitude: '42.96876',
    longitude: '-75.82002',
    timeZoneId: 'America/New_York',
  },
  '13036': {
    zip: '13036',
    city: 'Central Square',
    state: 'NY',
    latitude: '43.3068',
    longitude: '-76.17828',
    timeZoneId: 'America/New_York',
  },
  '13037': {
    zip: '13037',
    city: 'Chittenango',
    state: 'NY',
    latitude: '43.05729',
    longitude: '-75.872483',
    timeZoneId: 'America/New_York',
  },
  '13039': {
    zip: '13039',
    city: 'Cicero',
    state: 'NY',
    latitude: '43.17216',
    longitude: '-76.07172',
    timeZoneId: 'America/New_York',
  },
  '13040': {
    zip: '13040',
    city: 'Cincinnatus',
    state: 'NY',
    latitude: '42.562547',
    longitude: '-75.928525',
    timeZoneId: 'America/New_York',
  },
  '13041': {
    zip: '13041',
    city: 'Clay',
    state: 'NY',
    latitude: '43.171563',
    longitude: '-76.13194',
    timeZoneId: 'America/New_York',
  },
  '13042': {
    zip: '13042',
    city: 'Cleveland',
    state: 'NY',
    latitude: '43.233646',
    longitude: '-75.881359',
    timeZoneId: 'America/New_York',
  },
  '13043': {
    zip: '13043',
    city: 'Clockville',
    state: 'NY',
    latitude: '43.039802',
    longitude: '-75.744094',
    timeZoneId: 'America/New_York',
  },
  '13044': {
    zip: '13044',
    city: 'Constantia',
    state: 'NY',
    latitude: '43.266484',
    longitude: '-76.009432',
    timeZoneId: 'America/New_York',
  },
  '13045': {
    zip: '13045',
    city: 'Cortland',
    state: 'NY',
    latitude: '42.60276',
    longitude: '-76.17186',
    timeZoneId: 'America/New_York',
  },
  '13051': {
    zip: '13051',
    city: 'Delphi Falls',
    state: 'NY',
    latitude: '42.878158',
    longitude: '-75.912999',
    timeZoneId: 'America/New_York',
  },
  '13052': {
    zip: '13052',
    city: 'De Ruyter',
    state: 'NY',
    latitude: '42.7602',
    longitude: '-75.89232',
    timeZoneId: 'America/New_York',
  },
  '13053': {
    zip: '13053',
    city: 'Dryden',
    state: 'NY',
    latitude: '42.48891',
    longitude: '-76.296223',
    timeZoneId: 'America/New_York',
  },
  '13054': {
    zip: '13054',
    city: 'Durhamville',
    state: 'NY',
    latitude: '43.1418',
    longitude: '-75.67002',
    timeZoneId: 'America/New_York',
  },
  '13056': {
    zip: '13056',
    city: 'East Homer',
    state: 'NY',
    latitude: '42.672626',
    longitude: '-76.096831',
    timeZoneId: 'America/New_York',
  },
  '13057': {
    zip: '13057',
    city: 'East Syracuse',
    state: 'NY',
    latitude: '43.07553',
    longitude: '-76.05182',
    timeZoneId: 'America/New_York',
  },
  '13060': {
    zip: '13060',
    city: 'Elbridge',
    state: 'NY',
    latitude: '43.024371',
    longitude: '-76.418761',
    timeZoneId: 'America/New_York',
  },
  '13061': {
    zip: '13061',
    city: 'Erieville',
    state: 'NY',
    latitude: '42.866074',
    longitude: '-75.751527',
    timeZoneId: 'America/New_York',
  },
  '13062': {
    zip: '13062',
    city: 'Etna',
    state: 'NY',
    latitude: '42.482285',
    longitude: '-76.390876',
    timeZoneId: 'America/New_York',
  },
  '13063': {
    zip: '13063',
    city: 'Fabius',
    state: 'NY',
    latitude: '42.855288',
    longitude: '-75.987549',
    timeZoneId: 'America/New_York',
  },
  '13064': {
    zip: '13064',
    city: 'Fair Haven',
    state: 'NY',
    latitude: '43.319427',
    longitude: '-76.702751',
    timeZoneId: 'America/New_York',
  },
  '13065': {
    zip: '13065',
    city: 'Fayette',
    state: 'NY',
    latitude: '42.821298',
    longitude: '-76.812129',
    timeZoneId: 'America/New_York',
  },
  '13066': {
    zip: '13066',
    city: 'Fayetteville',
    state: 'NY',
    latitude: '43.027198',
    longitude: '-76.013016',
    timeZoneId: 'America/New_York',
  },
  '13068': {
    zip: '13068',
    city: 'Freeville',
    state: 'NY',
    latitude: '42.502142',
    longitude: '-76.354802',
    timeZoneId: 'America/New_York',
  },
  '13069': {
    zip: '13069',
    city: 'Fulton',
    state: 'NY',
    latitude: '43.29618',
    longitude: '-76.40352',
    timeZoneId: 'America/New_York',
  },
  '13071': {
    zip: '13071',
    city: 'Genoa',
    state: 'NY',
    latitude: '42.673961',
    longitude: '-76.541497',
    timeZoneId: 'America/New_York',
  },
  '13072': {
    zip: '13072',
    city: 'Georgetown',
    state: 'NY',
    latitude: '42.763862',
    longitude: '-75.753162',
    timeZoneId: 'America/New_York',
  },
  '13073': {
    zip: '13073',
    city: 'Groton',
    state: 'NY',
    latitude: '42.585725',
    longitude: '-76.393025',
    timeZoneId: 'America/New_York',
  },
  '13074': {
    zip: '13074',
    city: 'Hannibal',
    state: 'NY',
    latitude: '43.28592',
    longitude: '-76.53258',
    timeZoneId: 'America/New_York',
  },
  '13076': {
    zip: '13076',
    city: 'Hastings',
    state: 'NY',
    latitude: '43.351755',
    longitude: '-76.149799',
    timeZoneId: 'America/New_York',
  },
  '13077': {
    zip: '13077',
    city: 'Homer',
    state: 'NY',
    latitude: '42.676667',
    longitude: '-76.18953',
    timeZoneId: 'America/New_York',
  },
  '13078': {
    zip: '13078',
    city: 'Jamesville',
    state: 'NY',
    latitude: '42.96744',
    longitude: '-76.06074',
    timeZoneId: 'America/New_York',
  },
  '13080': {
    zip: '13080',
    city: 'Jordan',
    state: 'NY',
    latitude: '43.09332',
    longitude: '-76.46556',
    timeZoneId: 'America/New_York',
  },
  '13081': {
    zip: '13081',
    city: 'King Ferry',
    state: 'NY',
    latitude: '42.662592',
    longitude: '-76.62677',
    timeZoneId: 'America/New_York',
  },
  '13082': {
    zip: '13082',
    city: 'Kirkville',
    state: 'NY',
    latitude: '43.083758',
    longitude: '-75.970572',
    timeZoneId: 'America/New_York',
  },
  '13083': {
    zip: '13083',
    city: 'Lacona',
    state: 'NY',
    latitude: '43.647853',
    longitude: '-76.060983',
    timeZoneId: 'America/New_York',
  },
  '13084': {
    zip: '13084',
    city: 'La Fayette',
    state: 'NY',
    latitude: '42.88746',
    longitude: '-76.1193',
    timeZoneId: 'America/New_York',
  },
  '13087': {
    zip: '13087',
    city: 'Little York',
    state: 'NY',
    latitude: '42.705528',
    longitude: '-76.157984',
    timeZoneId: 'America/New_York',
  },
  '13088': {
    zip: '13088',
    city: 'Liverpool',
    state: 'NY',
    latitude: '43.111538',
    longitude: '-76.195307',
    timeZoneId: 'America/New_York',
  },
  '13089': {
    zip: '13089',
    city: 'Liverpool',
    state: 'NY',
    latitude: '43.139774',
    longitude: '-76.22722',
    timeZoneId: 'America/New_York',
  },
  '13090': {
    zip: '13090',
    city: 'Liverpool',
    state: 'NY',
    latitude: '43.149327',
    longitude: '-76.223372',
    timeZoneId: 'America/New_York',
  },
  '13092': {
    zip: '13092',
    city: 'Locke',
    state: 'NY',
    latitude: '42.65898',
    longitude: '-76.42404',
    timeZoneId: 'America/New_York',
  },
  '13093': {
    zip: '13093',
    city: 'Lycoming',
    state: 'NY',
    latitude: '43.501366',
    longitude: '-76.388574',
    timeZoneId: 'America/New_York',
  },
  '13101': {
    zip: '13101',
    city: 'Mc Graw',
    state: 'NY',
    latitude: '42.597772',
    longitude: '-76.078518',
    timeZoneId: 'America/New_York',
  },
  '13102': {
    zip: '13102',
    city: 'Mc Lean',
    state: 'NY',
    latitude: '42.554414',
    longitude: '-76.293544',
    timeZoneId: 'America/New_York',
  },
  '13103': {
    zip: '13103',
    city: 'Mallory',
    state: 'NY',
    latitude: '43.324996',
    longitude: '-76.098694',
    timeZoneId: 'America/New_York',
  },
  '13104': {
    zip: '13104',
    city: 'Manlius',
    state: 'NY',
    latitude: '42.994893',
    longitude: '-75.970433',
    timeZoneId: 'America/New_York',
  },
  '13107': {
    zip: '13107',
    city: 'Maple View',
    state: 'NY',
    latitude: '43.46048',
    longitude: '-76.147002',
    timeZoneId: 'America/New_York',
  },
  '13108': {
    zip: '13108',
    city: 'Marcellus',
    state: 'NY',
    latitude: '42.97008',
    longitude: '-76.32408',
    timeZoneId: 'America/New_York',
  },
  '13110': {
    zip: '13110',
    city: 'Marietta',
    state: 'NY',
    latitude: '42.888966',
    longitude: '-76.283957',
    timeZoneId: 'America/New_York',
  },
  '13111': {
    zip: '13111',
    city: 'Martville',
    state: 'NY',
    latitude: '43.27008',
    longitude: '-76.6224',
    timeZoneId: 'America/New_York',
  },
  '13112': {
    zip: '13112',
    city: 'Memphis',
    state: 'NY',
    latitude: '43.09872',
    longitude: '-76.41426',
    timeZoneId: 'America/New_York',
  },
  '13113': {
    zip: '13113',
    city: 'Meridian',
    state: 'NY',
    latitude: '43.162731',
    longitude: '-76.538014',
    timeZoneId: 'America/New_York',
  },
  '13114': {
    zip: '13114',
    city: 'Mexico',
    state: 'NY',
    latitude: '43.461264',
    longitude: '-76.233234',
    timeZoneId: 'America/New_York',
  },
  '13115': {
    zip: '13115',
    city: 'Minetto',
    state: 'NY',
    latitude: '43.398509',
    longitude: '-76.493251',
    timeZoneId: 'America/New_York',
  },
  '13116': {
    zip: '13116',
    city: 'Minoa',
    state: 'NY',
    latitude: '43.077436',
    longitude: '-76.009926',
    timeZoneId: 'America/New_York',
  },
  '13117': {
    zip: '13117',
    city: 'Montezuma',
    state: 'NY',
    latitude: '43.009921',
    longitude: '-76.700005',
    timeZoneId: 'America/New_York',
  },
  '13118': {
    zip: '13118',
    city: 'Moravia',
    state: 'NY',
    latitude: '42.73044',
    longitude: '-76.3806',
    timeZoneId: 'America/New_York',
  },
  '13119': {
    zip: '13119',
    city: 'Mottville',
    state: 'NY',
    latitude: '42.973215',
    longitude: '-76.441929',
    timeZoneId: 'America/New_York',
  },
  '13120': {
    zip: '13120',
    city: 'Nedrow',
    state: 'NY',
    latitude: '42.948',
    longitude: '-76.17684',
    timeZoneId: 'America/New_York',
  },
  '13121': {
    zip: '13121',
    city: 'New Haven',
    state: 'NY',
    latitude: '43.479969',
    longitude: '-76.313034',
    timeZoneId: 'America/New_York',
  },
  '13122': {
    zip: '13122',
    city: 'New Woodstock',
    state: 'NY',
    latitude: '42.855055',
    longitude: '-75.914421',
    timeZoneId: 'America/New_York',
  },
  '13123': {
    zip: '13123',
    city: 'North Bay',
    state: 'NY',
    latitude: '43.228347',
    longitude: '-75.748595',
    timeZoneId: 'America/New_York',
  },
  '13124': {
    zip: '13124',
    city: 'North Pitcher',
    state: 'NY',
    latitude: '42.638277',
    longitude: '-75.820988',
    timeZoneId: 'America/New_York',
  },
  '13126': {
    zip: '13126',
    city: 'Oswego',
    state: 'NY',
    latitude: '43.451477',
    longitude: '-76.501703',
    timeZoneId: 'America/New_York',
  },
  '13131': {
    zip: '13131',
    city: 'Parish',
    state: 'NY',
    latitude: '43.42338',
    longitude: '-76.0785',
    timeZoneId: 'America/New_York',
  },
  '13132': {
    zip: '13132',
    city: 'Pennellville',
    state: 'NY',
    latitude: '43.252751',
    longitude: '-76.232419',
    timeZoneId: 'America/New_York',
  },
  '13134': {
    zip: '13134',
    city: 'Peterboro',
    state: 'NY',
    latitude: '42.968836',
    longitude: '-75.687569',
    timeZoneId: 'America/New_York',
  },
  '13135': {
    zip: '13135',
    city: 'Phoenix',
    state: 'NY',
    latitude: '43.2615',
    longitude: '-76.32522',
    timeZoneId: 'America/New_York',
  },
  '13136': {
    zip: '13136',
    city: 'Pitcher',
    state: 'NY',
    latitude: '42.6154',
    longitude: '-75.853885',
    timeZoneId: 'America/New_York',
  },
  '13137': {
    zip: '13137',
    city: 'Plainville',
    state: 'NY',
    latitude: '43.151646',
    longitude: '-76.446002',
    timeZoneId: 'America/New_York',
  },
  '13138': {
    zip: '13138',
    city: 'Pompey',
    state: 'NY',
    latitude: '42.901818',
    longitude: '-76.013663',
    timeZoneId: 'America/New_York',
  },
  '13139': {
    zip: '13139',
    city: 'Poplar Ridge',
    state: 'NY',
    latitude: '42.740575',
    longitude: '-76.597936',
    timeZoneId: 'America/New_York',
  },
  '13140': {
    zip: '13140',
    city: 'Port Byron',
    state: 'NY',
    latitude: '43.05276',
    longitude: '-76.65528',
    timeZoneId: 'America/New_York',
  },
  '13141': {
    zip: '13141',
    city: 'Preble',
    state: 'NY',
    latitude: '42.738644',
    longitude: '-76.158017',
    timeZoneId: 'America/New_York',
  },
  '13142': {
    zip: '13142',
    city: 'Pulaski',
    state: 'NY',
    latitude: '43.557794',
    longitude: '-76.128407',
    timeZoneId: 'America/New_York',
  },
  '13143': {
    zip: '13143',
    city: 'Red Creek',
    state: 'NY',
    latitude: '43.235287',
    longitude: '-76.731148',
    timeZoneId: 'America/New_York',
  },
  '13144': {
    zip: '13144',
    city: 'Richland',
    state: 'NY',
    latitude: '43.561546',
    longitude: '-76.014406',
    timeZoneId: 'America/New_York',
  },
  '13145': {
    zip: '13145',
    city: 'Sandy Creek',
    state: 'NY',
    latitude: '43.656766',
    longitude: '-76.114729',
    timeZoneId: 'America/New_York',
  },
  '13146': {
    zip: '13146',
    city: 'Savannah',
    state: 'NY',
    latitude: '43.103753',
    longitude: '-76.760166',
    timeZoneId: 'America/New_York',
  },
  '13147': {
    zip: '13147',
    city: 'Scipio Center',
    state: 'NY',
    latitude: '42.78444',
    longitude: '-76.56684',
    timeZoneId: 'America/New_York',
  },
  '13148': {
    zip: '13148',
    city: 'Seneca Falls',
    state: 'NY',
    latitude: '42.92028',
    longitude: '-76.78488',
    timeZoneId: 'America/New_York',
  },
  '13152': {
    zip: '13152',
    city: 'Skaneateles',
    state: 'NY',
    latitude: '42.88434',
    longitude: '-76.35126',
    timeZoneId: 'America/New_York',
  },
  '13153': {
    zip: '13153',
    city: 'Skaneateles Falls',
    state: 'NY',
    latitude: '42.994912',
    longitude: '-76.452863',
    timeZoneId: 'America/New_York',
  },
  '13154': {
    zip: '13154',
    city: 'South Butler',
    state: 'NY',
    latitude: '43.133174',
    longitude: '-76.762628',
    timeZoneId: 'America/New_York',
  },
  '13155': {
    zip: '13155',
    city: 'South Otselic',
    state: 'NY',
    latitude: '42.665692',
    longitude: '-75.760212',
    timeZoneId: 'America/New_York',
  },
  '13156': {
    zip: '13156',
    city: 'Sterling',
    state: 'NY',
    latitude: '43.317187',
    longitude: '-76.70096',
    timeZoneId: 'America/New_York',
  },
  '13157': {
    zip: '13157',
    city: 'Sylvan Beach',
    state: 'NY',
    latitude: '43.207563',
    longitude: '-75.730526',
    timeZoneId: 'America/New_York',
  },
  '13158': {
    zip: '13158',
    city: 'Truxton',
    state: 'NY',
    latitude: '42.695444',
    longitude: '-76.01425',
    timeZoneId: 'America/New_York',
  },
  '13159': {
    zip: '13159',
    city: 'Tully',
    state: 'NY',
    latitude: '42.80886',
    longitude: '-76.1436',
    timeZoneId: 'America/New_York',
  },
  '13160': {
    zip: '13160',
    city: 'Union Springs',
    state: 'NY',
    latitude: '42.842257',
    longitude: '-76.688186',
    timeZoneId: 'America/New_York',
  },
  '13162': {
    zip: '13162',
    city: 'Verona Beach',
    state: 'NY',
    latitude: '43.192705',
    longitude: '-75.728282',
    timeZoneId: 'America/New_York',
  },
  '13163': {
    zip: '13163',
    city: 'Wampsville',
    state: 'NY',
    latitude: '43.08309',
    longitude: '-75.705865',
    timeZoneId: 'America/New_York',
  },
  '13164': {
    zip: '13164',
    city: 'Warners',
    state: 'NY',
    latitude: '43.090879',
    longitude: '-76.311279',
    timeZoneId: 'America/New_York',
  },
  '13165': {
    zip: '13165',
    city: 'Waterloo',
    state: 'NY',
    latitude: '42.907458',
    longitude: '-76.877046',
    timeZoneId: 'America/New_York',
  },
  '13166': {
    zip: '13166',
    city: 'Weedsport',
    state: 'NY',
    latitude: '43.055291',
    longitude: '-76.559355',
    timeZoneId: 'America/New_York',
  },
  '13167': {
    zip: '13167',
    city: 'West Monroe',
    state: 'NY',
    latitude: '43.284609',
    longitude: '-76.076927',
    timeZoneId: 'America/New_York',
  },
  '13201': {
    zip: '13201',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.04092',
    longitude: '-76.15264',
    timeZoneId: 'America/New_York',
  },
  '13202': {
    zip: '13202',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.050827',
    longitude: '-76.1483',
    timeZoneId: 'America/New_York',
  },
  '13203': {
    zip: '13203',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.060624',
    longitude: '-76.139954',
    timeZoneId: 'America/New_York',
  },
  '13204': {
    zip: '13204',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.052042',
    longitude: '-76.177065',
    timeZoneId: 'America/New_York',
  },
  '13205': {
    zip: '13205',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.006472',
    longitude: '-76.148241',
    timeZoneId: 'America/New_York',
  },
  '13206': {
    zip: '13206',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.070202',
    longitude: '-76.106706',
    timeZoneId: 'America/New_York',
  },
  '13207': {
    zip: '13207',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.017354',
    longitude: '-76.167626',
    timeZoneId: 'America/New_York',
  },
  '13208': {
    zip: '13208',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.072766',
    longitude: '-76.147309',
    timeZoneId: 'America/New_York',
  },
  '13209': {
    zip: '13209',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.0854',
    longitude: '-76.25172',
    timeZoneId: 'America/New_York',
  },
  '13210': {
    zip: '13210',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.034249',
    longitude: '-76.126286',
    timeZoneId: 'America/New_York',
  },
  '13211': {
    zip: '13211',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.098535',
    longitude: '-76.13219',
    timeZoneId: 'America/New_York',
  },
  '13212': {
    zip: '13212',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.11096',
    longitude: '-76.13928',
    timeZoneId: 'America/New_York',
  },
  '13214': {
    zip: '13214',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.036965',
    longitude: '-76.073608',
    timeZoneId: 'America/New_York',
  },
  '13215': {
    zip: '13215',
    city: 'Syracuse',
    state: 'NY',
    latitude: '42.97512',
    longitude: '-76.22634',
    timeZoneId: 'America/New_York',
  },
  '13217': {
    zip: '13217',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.051842',
    longitude: '-76.123159',
    timeZoneId: 'America/New_York',
  },
  '13218': {
    zip: '13218',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.047082',
    longitude: '-76.148417',
    timeZoneId: 'America/New_York',
  },
  '13219': {
    zip: '13219',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.040139',
    longitude: '-76.21702',
    timeZoneId: 'America/New_York',
  },
  '13220': {
    zip: '13220',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.125489',
    longitude: '-76.129731',
    timeZoneId: 'America/New_York',
  },
  '13221': {
    zip: '13221',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.048798',
    longitude: '-76.149827',
    timeZoneId: 'America/New_York',
  },
  '13224': {
    zip: '13224',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.042678',
    longitude: '-76.101087',
    timeZoneId: 'America/New_York',
  },
  '13225': {
    zip: '13225',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.12951',
    longitude: '-76.131355',
    timeZoneId: 'America/New_York',
  },
  '13235': {
    zip: '13235',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.04875',
    longitude: '-76.148002',
    timeZoneId: 'America/New_York',
  },
  '13244': {
    zip: '13244',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.041575',
    longitude: '-76.133784',
    timeZoneId: 'America/New_York',
  },
  '13250': {
    zip: '13250',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.122569',
    longitude: '-76.132866',
    timeZoneId: 'America/New_York',
  },
  '13251': {
    zip: '13251',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.123742',
    longitude: '-76.135103',
    timeZoneId: 'America/New_York',
  },
  '13252': {
    zip: '13252',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.048582',
    longitude: '-76.156564',
    timeZoneId: 'America/New_York',
  },
  '13261': {
    zip: '13261',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.050083',
    longitude: '-76.154526',
    timeZoneId: 'America/New_York',
  },
  '13290': {
    zip: '13290',
    city: 'Syracuse',
    state: 'NY',
    latitude: '43.05011',
    longitude: '-76.150573',
    timeZoneId: 'America/New_York',
  },
  '13301': {
    zip: '13301',
    city: 'Alder Creek',
    state: 'NY',
    latitude: '43.414193',
    longitude: '-75.21042',
    timeZoneId: 'America/New_York',
  },
  '13302': {
    zip: '13302',
    city: 'Altmar',
    state: 'NY',
    latitude: '43.505683',
    longitude: '-75.992926',
    timeZoneId: 'America/New_York',
  },
  '13303': {
    zip: '13303',
    city: 'Ava',
    state: 'NY',
    latitude: '43.306203',
    longitude: '-75.43564',
    timeZoneId: 'America/New_York',
  },
  '13304': {
    zip: '13304',
    city: 'Barneveld',
    state: 'NY',
    latitude: '43.23156',
    longitude: '-75.15756',
    timeZoneId: 'America/New_York',
  },
  '13305': {
    zip: '13305',
    city: 'Beaver Falls',
    state: 'NY',
    latitude: '43.887639',
    longitude: '-75.425266',
    timeZoneId: 'America/New_York',
  },
  '13308': {
    zip: '13308',
    city: 'Blossvale',
    state: 'NY',
    latitude: '43.241534',
    longitude: '-75.652043',
    timeZoneId: 'America/New_York',
  },
  '13309': {
    zip: '13309',
    city: 'Boonville',
    state: 'NY',
    latitude: '43.45308',
    longitude: '-75.336',
    timeZoneId: 'America/New_York',
  },
  '13310': {
    zip: '13310',
    city: 'Bouckville',
    state: 'NY',
    latitude: '42.897902',
    longitude: '-75.574398',
    timeZoneId: 'America/New_York',
  },
  '13312': {
    zip: '13312',
    city: 'Brantingham',
    state: 'NY',
    latitude: '43.685598',
    longitude: '-75.289871',
    timeZoneId: 'America/New_York',
  },
  '13313': {
    zip: '13313',
    city: 'Bridgewater',
    state: 'NY',
    latitude: '42.878013',
    longitude: '-75.249924',
    timeZoneId: 'America/New_York',
  },
  '13314': {
    zip: '13314',
    city: 'Brookfield',
    state: 'NY',
    latitude: '42.807903',
    longitude: '-75.342084',
    timeZoneId: 'America/New_York',
  },
  '13315': {
    zip: '13315',
    city: 'Burlington Flats',
    state: 'NY',
    latitude: '42.75348',
    longitude: '-75.16962',
    timeZoneId: 'America/New_York',
  },
  '13316': {
    zip: '13316',
    city: 'Camden',
    state: 'NY',
    latitude: '43.354631',
    longitude: '-75.746699',
    timeZoneId: 'America/New_York',
  },
  '13317': {
    zip: '13317',
    city: 'Canajoharie',
    state: 'NY',
    latitude: '42.8937',
    longitude: '-74.57418',
    timeZoneId: 'America/New_York',
  },
  '13318': {
    zip: '13318',
    city: 'Cassville',
    state: 'NY',
    latitude: '42.940901',
    longitude: '-75.285826',
    timeZoneId: 'America/New_York',
  },
  '13319': {
    zip: '13319',
    city: 'Chadwicks',
    state: 'NY',
    latitude: '43.023165',
    longitude: '-75.269856',
    timeZoneId: 'America/New_York',
  },
  '13320': {
    zip: '13320',
    city: 'Cherry Valley',
    state: 'NY',
    latitude: '42.796521',
    longitude: '-74.756366',
    timeZoneId: 'America/New_York',
  },
  '13321': {
    zip: '13321',
    city: 'Clark Mills',
    state: 'NY',
    latitude: '43.091712',
    longitude: '-75.378449',
    timeZoneId: 'America/New_York',
  },
  '13322': {
    zip: '13322',
    city: 'Clayville',
    state: 'NY',
    latitude: '42.97391',
    longitude: '-75.243398',
    timeZoneId: 'America/New_York',
  },
  '13323': {
    zip: '13323',
    city: 'Clinton',
    state: 'NY',
    latitude: '43.056309',
    longitude: '-75.375791',
    timeZoneId: 'America/New_York',
  },
  '13324': {
    zip: '13324',
    city: 'Cold Brook',
    state: 'NY',
    latitude: '43.32077',
    longitude: '-74.963299',
    timeZoneId: 'America/New_York',
  },
  '13325': {
    zip: '13325',
    city: 'Constableville',
    state: 'NY',
    latitude: '43.577189',
    longitude: '-75.564678',
    timeZoneId: 'America/New_York',
  },
  '13326': {
    zip: '13326',
    city: 'Cooperstown',
    state: 'NY',
    latitude: '42.716131',
    longitude: '-74.906704',
    timeZoneId: 'America/New_York',
  },
  '13327': {
    zip: '13327',
    city: 'Croghan',
    state: 'NY',
    latitude: '43.899822',
    longitude: '-75.381978',
    timeZoneId: 'America/New_York',
  },
  '13328': {
    zip: '13328',
    city: 'Deansboro',
    state: 'NY',
    latitude: '42.997626',
    longitude: '-75.428706',
    timeZoneId: 'America/New_York',
  },
  '13329': {
    zip: '13329',
    city: 'Dolgeville',
    state: 'NY',
    latitude: '43.1037',
    longitude: '-74.74098',
    timeZoneId: 'America/New_York',
  },
  '13331': {
    zip: '13331',
    city: 'Eagle Bay',
    state: 'NY',
    latitude: '43.841882',
    longitude: '-74.878424',
    timeZoneId: 'America/New_York',
  },
  '13332': {
    zip: '13332',
    city: 'Earlville',
    state: 'NY',
    latitude: '42.742625',
    longitude: '-75.566682',
    timeZoneId: 'America/New_York',
  },
  '13333': {
    zip: '13333',
    city: 'East Springfield',
    state: 'NY',
    latitude: '42.831907',
    longitude: '-74.761141',
    timeZoneId: 'America/New_York',
  },
  '13334': {
    zip: '13334',
    city: 'Eaton',
    state: 'NY',
    latitude: '42.827651',
    longitude: '-75.654124',
    timeZoneId: 'America/New_York',
  },
  '13335': {
    zip: '13335',
    city: 'Edmeston',
    state: 'NY',
    latitude: '42.697595',
    longitude: '-75.238939',
    timeZoneId: 'America/New_York',
  },
  '13337': {
    zip: '13337',
    city: 'Fly Creek',
    state: 'NY',
    latitude: '42.734651',
    longitude: '-74.991943',
    timeZoneId: 'America/New_York',
  },
  '13338': {
    zip: '13338',
    city: 'Forestport',
    state: 'NY',
    latitude: '43.44296',
    longitude: '-75.207302',
    timeZoneId: 'America/New_York',
  },
  '13339': {
    zip: '13339',
    city: 'Fort Plain',
    state: 'NY',
    latitude: '42.95874',
    longitude: '-74.57826',
    timeZoneId: 'America/New_York',
  },
  '13340': {
    zip: '13340',
    city: 'Frankfort',
    state: 'NY',
    latitude: '43.040165',
    longitude: '-75.082322',
    timeZoneId: 'America/New_York',
  },
  '13341': {
    zip: '13341',
    city: 'Franklin Springs',
    state: 'NY',
    latitude: '43.034678',
    longitude: '-75.390751',
    timeZoneId: 'America/New_York',
  },
  '13342': {
    zip: '13342',
    city: 'Garrattsville',
    state: 'NY',
    latitude: '42.64075',
    longitude: '-75.226719',
    timeZoneId: 'America/New_York',
  },
  '13343': {
    zip: '13343',
    city: 'Glenfield',
    state: 'NY',
    latitude: '43.73778',
    longitude: '-75.3279',
    timeZoneId: 'America/New_York',
  },
  '13345': {
    zip: '13345',
    city: 'Greig',
    state: 'NY',
    latitude: '43.679971',
    longitude: '-75.311066',
    timeZoneId: 'America/New_York',
  },
  '13346': {
    zip: '13346',
    city: 'Hamilton',
    state: 'NY',
    latitude: '42.82722',
    longitude: '-75.52956',
    timeZoneId: 'America/New_York',
  },
  '13348': {
    zip: '13348',
    city: 'Hartwick',
    state: 'NY',
    latitude: '42.69588',
    longitude: '-75.06618',
    timeZoneId: 'America/New_York',
  },
  '13350': {
    zip: '13350',
    city: 'Herkimer',
    state: 'NY',
    latitude: '43.030105',
    longitude: '-74.984973',
    timeZoneId: 'America/New_York',
  },
  '13352': {
    zip: '13352',
    city: 'Hinckley',
    state: 'NY',
    latitude: '43.2745',
    longitude: '-75.18046',
    timeZoneId: 'America/New_York',
  },
  '13353': {
    zip: '13353',
    city: 'Hoffmeister',
    state: 'NY',
    latitude: '43.468973',
    longitude: '-74.727687',
    timeZoneId: 'America/New_York',
  },
  '13354': {
    zip: '13354',
    city: 'Holland Patent',
    state: 'NY',
    latitude: '43.25364',
    longitude: '-75.28452',
    timeZoneId: 'America/New_York',
  },
  '13355': {
    zip: '13355',
    city: 'Hubbardsville',
    state: 'NY',
    latitude: '42.815696',
    longitude: '-75.457847',
    timeZoneId: 'America/New_York',
  },
  '13357': {
    zip: '13357',
    city: 'Ilion',
    state: 'NY',
    latitude: '43.012843',
    longitude: '-75.045183',
    timeZoneId: 'America/New_York',
  },
  '13360': {
    zip: '13360',
    city: 'Inlet',
    state: 'NY',
    latitude: '43.732253',
    longitude: '-74.73682',
    timeZoneId: 'America/New_York',
  },
  '13361': {
    zip: '13361',
    city: 'Jordanville',
    state: 'NY',
    latitude: '42.89477',
    longitude: '-74.81734',
    timeZoneId: 'America/New_York',
  },
  '13362': {
    zip: '13362',
    city: 'Knoxboro',
    state: 'NY',
    latitude: '42.984335',
    longitude: '-75.519009',
    timeZoneId: 'America/New_York',
  },
  '13363': {
    zip: '13363',
    city: 'Lee Center',
    state: 'NY',
    latitude: '43.31442',
    longitude: '-75.5121',
    timeZoneId: 'America/New_York',
  },
  '13364': {
    zip: '13364',
    city: 'Leonardsville',
    state: 'NY',
    latitude: '42.808457',
    longitude: '-75.253988',
    timeZoneId: 'America/New_York',
  },
  '13365': {
    zip: '13365',
    city: 'Little Falls',
    state: 'NY',
    latitude: '43.08642',
    longitude: '-74.904',
    timeZoneId: 'America/New_York',
  },
  '13367': {
    zip: '13367',
    city: 'Lowville',
    state: 'NY',
    latitude: '43.7871',
    longitude: '-75.47691',
    timeZoneId: 'America/New_York',
  },
  '13368': {
    zip: '13368',
    city: 'Lyons Falls',
    state: 'NY',
    latitude: '43.63092',
    longitude: '-75.34848',
    timeZoneId: 'America/New_York',
  },
  '13401': {
    zip: '13401',
    city: 'Mc Connellsville',
    state: 'NY',
    latitude: '43.29132',
    longitude: '-75.66852',
    timeZoneId: 'America/New_York',
  },
  '13402': {
    zip: '13402',
    city: 'Madison',
    state: 'NY',
    latitude: '42.915824',
    longitude: '-75.493148',
    timeZoneId: 'America/New_York',
  },
  '13403': {
    zip: '13403',
    city: 'Marcy',
    state: 'NY',
    latitude: '43.150854',
    longitude: '-75.25208',
    timeZoneId: 'America/New_York',
  },
  '13404': {
    zip: '13404',
    city: 'Martinsburg',
    state: 'NY',
    latitude: '43.738157',
    longitude: '-75.468625',
    timeZoneId: 'America/New_York',
  },
  '13406': {
    zip: '13406',
    city: 'Middleville',
    state: 'NY',
    latitude: '43.139808',
    longitude: '-74.969563',
    timeZoneId: 'America/New_York',
  },
  '13407': {
    zip: '13407',
    city: 'Mohawk',
    state: 'NY',
    latitude: '42.99114',
    longitude: '-74.98056',
    timeZoneId: 'America/New_York',
  },
  '13408': {
    zip: '13408',
    city: 'Morrisville',
    state: 'NY',
    latitude: '42.905156',
    longitude: '-75.67763',
    timeZoneId: 'America/New_York',
  },
  '13409': {
    zip: '13409',
    city: 'Munnsville',
    state: 'NY',
    latitude: '42.9507',
    longitude: '-75.56898',
    timeZoneId: 'America/New_York',
  },
  '13410': {
    zip: '13410',
    city: 'Nelliston',
    state: 'NY',
    latitude: '42.932952',
    longitude: '-74.608756',
    timeZoneId: 'America/New_York',
  },
  '13411': {
    zip: '13411',
    city: 'New Berlin',
    state: 'NY',
    latitude: '42.624636',
    longitude: '-75.330038',
    timeZoneId: 'America/New_York',
  },
  '13413': {
    zip: '13413',
    city: 'New Hartford',
    state: 'NY',
    latitude: '43.066052',
    longitude: '-75.290055',
    timeZoneId: 'America/New_York',
  },
  '13415': {
    zip: '13415',
    city: 'New Lisbon',
    state: 'NY',
    latitude: '42.599137',
    longitude: '-75.18746',
    timeZoneId: 'America/New_York',
  },
  '13416': {
    zip: '13416',
    city: 'Newport',
    state: 'NY',
    latitude: '43.189629',
    longitude: '-75.018362',
    timeZoneId: 'America/New_York',
  },
  '13417': {
    zip: '13417',
    city: 'New York Mills',
    state: 'NY',
    latitude: '43.100821',
    longitude: '-75.290988',
    timeZoneId: 'America/New_York',
  },
  '13418': {
    zip: '13418',
    city: 'North Brookfield',
    state: 'NY',
    latitude: '42.851796',
    longitude: '-75.374964',
    timeZoneId: 'America/New_York',
  },
  '13420': {
    zip: '13420',
    city: 'Old Forge',
    state: 'NY',
    latitude: '43.650231',
    longitude: '-74.989248',
    timeZoneId: 'America/New_York',
  },
  '13421': {
    zip: '13421',
    city: 'Oneida',
    state: 'NY',
    latitude: '43.06422',
    longitude: '-75.64818',
    timeZoneId: 'America/New_York',
  },
  '13424': {
    zip: '13424',
    city: 'Oriskany',
    state: 'NY',
    latitude: '43.154445',
    longitude: '-75.332472',
    timeZoneId: 'America/New_York',
  },
  '13425': {
    zip: '13425',
    city: 'Oriskany Falls',
    state: 'NY',
    latitude: '42.939058',
    longitude: '-75.459424',
    timeZoneId: 'America/New_York',
  },
  '13426': {
    zip: '13426',
    city: 'Orwell',
    state: 'NY',
    latitude: '43.560573',
    longitude: '-75.993101',
    timeZoneId: 'America/New_York',
  },
  '13428': {
    zip: '13428',
    city: 'Palatine Bridge',
    state: 'NY',
    latitude: '42.909641',
    longitude: '-74.57437',
    timeZoneId: 'America/New_York',
  },
  '13431': {
    zip: '13431',
    city: 'Poland',
    state: 'NY',
    latitude: '43.2051',
    longitude: '-75.0726',
    timeZoneId: 'America/New_York',
  },
  '13433': {
    zip: '13433',
    city: 'Port Leyden',
    state: 'NY',
    latitude: '43.588855',
    longitude: '-75.279411',
    timeZoneId: 'America/New_York',
  },
  '13435': {
    zip: '13435',
    city: 'Prospect',
    state: 'NY',
    latitude: '43.303611',
    longitude: '-75.148084',
    timeZoneId: 'America/New_York',
  },
  '13436': {
    zip: '13436',
    city: 'Raquette Lake',
    state: 'NY',
    latitude: '43.780901',
    longitude: '-74.669391',
    timeZoneId: 'America/New_York',
  },
  '13437': {
    zip: '13437',
    city: 'Redfield',
    state: 'NY',
    latitude: '43.595477',
    longitude: '-75.813913',
    timeZoneId: 'America/New_York',
  },
  '13438': {
    zip: '13438',
    city: 'Remsen',
    state: 'NY',
    latitude: '43.333117',
    longitude: '-75.200513',
    timeZoneId: 'America/New_York',
  },
  '13439': {
    zip: '13439',
    city: 'Richfield Springs',
    state: 'NY',
    latitude: '42.85141',
    longitude: '-74.988541',
    timeZoneId: 'America/New_York',
  },
  '13440': {
    zip: '13440',
    city: 'Rome',
    state: 'NY',
    latitude: '43.21386',
    longitude: '-75.45954',
    timeZoneId: 'America/New_York',
  },
  '13441': {
    zip: '13441',
    city: 'Rome',
    state: 'NY',
    latitude: '43.212972',
    longitude: '-75.41017',
    timeZoneId: 'America/New_York',
  },
  '13442': {
    zip: '13442',
    city: 'Rome',
    state: 'NY',
    latitude: '43.217615',
    longitude: '-75.447424',
    timeZoneId: 'America/New_York',
  },
  '13449': {
    zip: '13449',
    city: 'Rome',
    state: 'NY',
    latitude: '43.225494',
    longitude: '-75.492278',
    timeZoneId: 'America/New_York',
  },
  '13450': {
    zip: '13450',
    city: 'Roseboom',
    state: 'NY',
    latitude: '42.69442',
    longitude: '-74.819739',
    timeZoneId: 'America/New_York',
  },
  '13452': {
    zip: '13452',
    city: 'Saint Johnsville',
    state: 'NY',
    latitude: '43.03776',
    longitude: '-74.6364',
    timeZoneId: 'America/New_York',
  },
  '13454': {
    zip: '13454',
    city: 'Salisbury Center',
    state: 'NY',
    latitude: '43.231609',
    longitude: '-74.791568',
    timeZoneId: 'America/New_York',
  },
  '13455': {
    zip: '13455',
    city: 'Sangerfield',
    state: 'NY',
    latitude: '42.916248',
    longitude: '-75.381705',
    timeZoneId: 'America/New_York',
  },
  '13456': {
    zip: '13456',
    city: 'Sauquoit',
    state: 'NY',
    latitude: '43.00056',
    longitude: '-75.26976',
    timeZoneId: 'America/New_York',
  },
  '13457': {
    zip: '13457',
    city: 'Schuyler Lake',
    state: 'NY',
    latitude: '42.779466',
    longitude: '-75.026726',
    timeZoneId: 'America/New_York',
  },
  '13459': {
    zip: '13459',
    city: 'Sharon Springs',
    state: 'NY',
    latitude: '42.791988',
    longitude: '-74.614106',
    timeZoneId: 'America/New_York',
  },
  '13460': {
    zip: '13460',
    city: 'Sherburne',
    state: 'NY',
    latitude: '42.68097',
    longitude: '-75.49917',
    timeZoneId: 'America/New_York',
  },
  '13461': {
    zip: '13461',
    city: 'Sherrill',
    state: 'NY',
    latitude: '43.071891',
    longitude: '-75.595941',
    timeZoneId: 'America/New_York',
  },
  '13464': {
    zip: '13464',
    city: 'Smyrna',
    state: 'NY',
    latitude: '42.691125',
    longitude: '-75.632663',
    timeZoneId: 'America/New_York',
  },
  '13465': {
    zip: '13465',
    city: 'Solsville',
    state: 'NY',
    latitude: '42.91064',
    longitude: '-75.521019',
    timeZoneId: 'America/New_York',
  },
  '13468': {
    zip: '13468',
    city: 'Springfield Center',
    state: 'NY',
    latitude: '42.844523',
    longitude: '-74.841105',
    timeZoneId: 'America/New_York',
  },
  '13469': {
    zip: '13469',
    city: 'Stittville',
    state: 'NY',
    latitude: '43.22122',
    longitude: '-75.285717',
    timeZoneId: 'America/New_York',
  },
  '13470': {
    zip: '13470',
    city: 'Stratford',
    state: 'NY',
    latitude: '43.189457',
    longitude: '-74.635488',
    timeZoneId: 'America/New_York',
  },
  '13471': {
    zip: '13471',
    city: 'Taberg',
    state: 'NY',
    latitude: '43.395357',
    longitude: '-75.611063',
    timeZoneId: 'America/New_York',
  },
  '13472': {
    zip: '13472',
    city: 'Thendara',
    state: 'NY',
    latitude: '43.839312',
    longitude: '-74.998485',
    timeZoneId: 'America/New_York',
  },
  '13473': {
    zip: '13473',
    city: 'Turin',
    state: 'NY',
    latitude: '43.6556',
    longitude: '-75.428792',
    timeZoneId: 'America/New_York',
  },
  '13475': {
    zip: '13475',
    city: 'Van Hornesville',
    state: 'NY',
    latitude: '42.895',
    longitude: '-74.828056',
    timeZoneId: 'America/New_York',
  },
  '13476': {
    zip: '13476',
    city: 'Vernon',
    state: 'NY',
    latitude: '43.076874',
    longitude: '-75.533524',
    timeZoneId: 'America/New_York',
  },
  '13477': {
    zip: '13477',
    city: 'Vernon Center',
    state: 'NY',
    latitude: '43.044137',
    longitude: '-75.524912',
    timeZoneId: 'America/New_York',
  },
  '13478': {
    zip: '13478',
    city: 'Verona',
    state: 'NY',
    latitude: '43.15362',
    longitude: '-75.58602',
    timeZoneId: 'America/New_York',
  },
  '13479': {
    zip: '13479',
    city: 'Washington Mills',
    state: 'NY',
    latitude: '43.056604',
    longitude: '-75.273986',
    timeZoneId: 'America/New_York',
  },
  '13480': {
    zip: '13480',
    city: 'Waterville',
    state: 'NY',
    latitude: '42.930337',
    longitude: '-75.378181',
    timeZoneId: 'America/New_York',
  },
  '13482': {
    zip: '13482',
    city: 'West Burlington',
    state: 'NY',
    latitude: '42.694287',
    longitude: '-75.193783',
    timeZoneId: 'America/New_York',
  },
  '13483': {
    zip: '13483',
    city: 'Westdale',
    state: 'NY',
    latitude: '43.415556',
    longitude: '-75.815422',
    timeZoneId: 'America/New_York',
  },
  '13484': {
    zip: '13484',
    city: 'West Eaton',
    state: 'NY',
    latitude: '42.851757',
    longitude: '-75.662247',
    timeZoneId: 'America/New_York',
  },
  '13485': {
    zip: '13485',
    city: 'West Edmeston',
    state: 'NY',
    latitude: '42.78784',
    longitude: '-75.318973',
    timeZoneId: 'America/New_York',
  },
  '13486': {
    zip: '13486',
    city: 'Westernville',
    state: 'NY',
    latitude: '43.34721',
    longitude: '-75.324999',
    timeZoneId: 'America/New_York',
  },
  '13488': {
    zip: '13488',
    city: 'Westford',
    state: 'NY',
    latitude: '42.676969',
    longitude: '-74.769177',
    timeZoneId: 'America/New_York',
  },
  '13489': {
    zip: '13489',
    city: 'West Leyden',
    state: 'NY',
    latitude: '43.473901',
    longitude: '-75.53277',
    timeZoneId: 'America/New_York',
  },
  '13490': {
    zip: '13490',
    city: 'Westmoreland',
    state: 'NY',
    latitude: '43.113737',
    longitude: '-75.416336',
    timeZoneId: 'America/New_York',
  },
  '13491': {
    zip: '13491',
    city: 'West Winfield',
    state: 'NY',
    latitude: '42.881365',
    longitude: '-75.189026',
    timeZoneId: 'America/New_York',
  },
  '13492': {
    zip: '13492',
    city: 'Whitesboro',
    state: 'NY',
    latitude: '43.11864',
    longitude: '-75.32934',
    timeZoneId: 'America/New_York',
  },
  '13493': {
    zip: '13493',
    city: 'Williamstown',
    state: 'NY',
    latitude: '43.422128',
    longitude: '-75.88823',
    timeZoneId: 'America/New_York',
  },
  '13494': {
    zip: '13494',
    city: 'Woodgate',
    state: 'NY',
    latitude: '43.540179',
    longitude: '-75.120878',
    timeZoneId: 'America/New_York',
  },
  '13495': {
    zip: '13495',
    city: 'Yorkville',
    state: 'NY',
    latitude: '43.114065',
    longitude: '-75.274396',
    timeZoneId: 'America/New_York',
  },
  '13501': {
    zip: '13501',
    city: 'Utica',
    state: 'NY',
    latitude: '43.08437',
    longitude: '-75.234461',
    timeZoneId: 'America/New_York',
  },
  '13502': {
    zip: '13502',
    city: 'Utica',
    state: 'NY',
    latitude: '43.12746',
    longitude: '-75.21426',
    timeZoneId: 'America/New_York',
  },
  '13503': {
    zip: '13503',
    city: 'Utica',
    state: 'NY',
    latitude: '43.100145',
    longitude: '-75.23291',
    timeZoneId: 'America/New_York',
  },
  '13504': {
    zip: '13504',
    city: 'Utica',
    state: 'NY',
    latitude: '43.085213',
    longitude: '-75.188377',
    timeZoneId: 'America/New_York',
  },
  '13505': {
    zip: '13505',
    city: 'Utica',
    state: 'NY',
    latitude: '43.100405',
    longitude: '-75.230473',
    timeZoneId: 'America/New_York',
  },
  '13599': {
    zip: '13599',
    city: 'Utica',
    state: 'NY',
    latitude: '43.097704',
    longitude: '-75.229256',
    timeZoneId: 'America/New_York',
  },
  '13601': {
    zip: '13601',
    city: 'Watertown',
    state: 'NY',
    latitude: '43.98444',
    longitude: '-75.90054',
    timeZoneId: 'America/New_York',
  },
  '13602': {
    zip: '13602',
    city: 'Fort Drum',
    state: 'NY',
    latitude: '44.054057',
    longitude: '-75.750531',
    timeZoneId: 'America/New_York',
  },
  '13603': {
    zip: '13603',
    city: 'Watertown',
    state: 'NY',
    latitude: '44.04216',
    longitude: '-75.79152',
    timeZoneId: 'America/New_York',
  },
  '13605': {
    zip: '13605',
    city: 'Adams',
    state: 'NY',
    latitude: '43.8231',
    longitude: '-76.03686',
    timeZoneId: 'America/New_York',
  },
  '13606': {
    zip: '13606',
    city: 'Adams Center',
    state: 'NY',
    latitude: '43.858497',
    longitude: '-76.009224',
    timeZoneId: 'America/New_York',
  },
  '13607': {
    zip: '13607',
    city: 'Alexandria Bay',
    state: 'NY',
    latitude: '44.331922',
    longitude: '-75.917693',
    timeZoneId: 'America/New_York',
  },
  '13608': {
    zip: '13608',
    city: 'Antwerp',
    state: 'NY',
    latitude: '44.198498',
    longitude: '-75.607636',
    timeZoneId: 'America/New_York',
  },
  '13611': {
    zip: '13611',
    city: 'Belleville',
    state: 'NY',
    latitude: '43.788347',
    longitude: '-76.114594',
    timeZoneId: 'America/New_York',
  },
  '13612': {
    zip: '13612',
    city: 'Black River',
    state: 'NY',
    latitude: '44.00621',
    longitude: '-75.796688',
    timeZoneId: 'America/New_York',
  },
  '13613': {
    zip: '13613',
    city: 'Brasher Falls',
    state: 'NY',
    latitude: '44.860326',
    longitude: '-74.725235',
    timeZoneId: 'America/New_York',
  },
  '13614': {
    zip: '13614',
    city: 'Brier Hill',
    state: 'NY',
    latitude: '44.541243',
    longitude: '-75.688955',
    timeZoneId: 'America/New_York',
  },
  '13615': {
    zip: '13615',
    city: 'Brownville',
    state: 'NY',
    latitude: '44.006764',
    longitude: '-75.986222',
    timeZoneId: 'America/New_York',
  },
  '13616': {
    zip: '13616',
    city: 'Calcium',
    state: 'NY',
    latitude: '44.02437',
    longitude: '-75.858123',
    timeZoneId: 'America/New_York',
  },
  '13617': {
    zip: '13617',
    city: 'Canton',
    state: 'NY',
    latitude: '44.58378',
    longitude: '-75.14382',
    timeZoneId: 'America/New_York',
  },
  '13618': {
    zip: '13618',
    city: 'Cape Vincent',
    state: 'NY',
    latitude: '44.130167',
    longitude: '-76.32961',
    timeZoneId: 'America/New_York',
  },
  '13619': {
    zip: '13619',
    city: 'Carthage',
    state: 'NY',
    latitude: '43.980552',
    longitude: '-75.620903',
    timeZoneId: 'America/New_York',
  },
  '13620': {
    zip: '13620',
    city: 'Castorland',
    state: 'NY',
    latitude: '43.888863',
    longitude: '-75.459474',
    timeZoneId: 'America/New_York',
  },
  '13621': {
    zip: '13621',
    city: 'Chase Mills',
    state: 'NY',
    latitude: '44.859073',
    longitude: '-75.069656',
    timeZoneId: 'America/New_York',
  },
  '13622': {
    zip: '13622',
    city: 'Chaumont',
    state: 'NY',
    latitude: '44.064254',
    longitude: '-76.129074',
    timeZoneId: 'America/New_York',
  },
  '13623': {
    zip: '13623',
    city: 'Chippewa Bay',
    state: 'NY',
    latitude: '44.440362',
    longitude: '-75.753985',
    timeZoneId: 'America/New_York',
  },
  '13624': {
    zip: '13624',
    city: 'Clayton',
    state: 'NY',
    latitude: '44.239325',
    longitude: '-76.08045',
    timeZoneId: 'America/New_York',
  },
  '13625': {
    zip: '13625',
    city: 'Colton',
    state: 'NY',
    latitude: '44.538104',
    longitude: '-74.928858',
    timeZoneId: 'America/New_York',
  },
  '13626': {
    zip: '13626',
    city: 'Copenhagen',
    state: 'NY',
    latitude: '43.895883',
    longitude: '-75.707121',
    timeZoneId: 'America/New_York',
  },
  '13627': {
    zip: '13627',
    city: 'Deer River',
    state: 'NY',
    latitude: '43.92915',
    longitude: '-75.590454',
    timeZoneId: 'America/New_York',
  },
  '13628': {
    zip: '13628',
    city: 'Deferiet',
    state: 'NY',
    latitude: '44.033049',
    longitude: '-75.677888',
    timeZoneId: 'America/New_York',
  },
  '13630': {
    zip: '13630',
    city: 'De Kalb Junction',
    state: 'NY',
    latitude: '44.483181',
    longitude: '-75.298577',
    timeZoneId: 'America/New_York',
  },
  '13631': {
    zip: '13631',
    city: 'Denmark',
    state: 'NY',
    latitude: '43.900363',
    longitude: '-75.58529',
    timeZoneId: 'America/New_York',
  },
  '13632': {
    zip: '13632',
    city: 'Depauville',
    state: 'NY',
    latitude: '44.140916',
    longitude: '-76.063848',
    timeZoneId: 'America/New_York',
  },
  '13633': {
    zip: '13633',
    city: 'De Peyster',
    state: 'NY',
    latitude: '44.541079',
    longitude: '-75.41894',
    timeZoneId: 'America/New_York',
  },
  '13634': {
    zip: '13634',
    city: 'Dexter',
    state: 'NY',
    latitude: '44.00616',
    longitude: '-76.07107',
    timeZoneId: 'America/New_York',
  },
  '13635': {
    zip: '13635',
    city: 'Edwards',
    state: 'NY',
    latitude: '44.30802',
    longitude: '-75.25608',
    timeZoneId: 'America/New_York',
  },
  '13636': {
    zip: '13636',
    city: 'Ellisburg',
    state: 'NY',
    latitude: '43.74209',
    longitude: '-76.12062',
    timeZoneId: 'America/New_York',
  },
  '13637': {
    zip: '13637',
    city: 'Evans Mills',
    state: 'NY',
    latitude: '44.090947',
    longitude: '-75.804665',
    timeZoneId: 'America/New_York',
  },
  '13638': {
    zip: '13638',
    city: 'Felts Mills',
    state: 'NY',
    latitude: '44.019385',
    longitude: '-75.745627',
    timeZoneId: 'America/New_York',
  },
  '13639': {
    zip: '13639',
    city: 'Fine',
    state: 'NY',
    latitude: '44.242623',
    longitude: '-75.140161',
    timeZoneId: 'America/New_York',
  },
  '13640': {
    zip: '13640',
    city: 'Wellesley Island',
    state: 'NY',
    latitude: '44.317415',
    longitude: '-76.008175',
    timeZoneId: 'America/New_York',
  },
  '13641': {
    zip: '13641',
    city: 'Fishers Landing',
    state: 'NY',
    latitude: '44.278171',
    longitude: '-76.00835',
    timeZoneId: 'America/New_York',
  },
  '13642': {
    zip: '13642',
    city: 'Gouverneur',
    state: 'NY',
    latitude: '44.30244',
    longitude: '-75.41568',
    timeZoneId: 'America/New_York',
  },
  '13643': {
    zip: '13643',
    city: 'Great Bend',
    state: 'NY',
    latitude: '44.034705',
    longitude: '-75.72152',
    timeZoneId: 'America/New_York',
  },
  '13645': {
    zip: '13645',
    city: 'Hailesboro',
    state: 'NY',
    latitude: '44.305254',
    longitude: '-75.451418',
    timeZoneId: 'America/New_York',
  },
  '13646': {
    zip: '13646',
    city: 'Hammond',
    state: 'NY',
    latitude: '44.4501',
    longitude: '-75.6864',
    timeZoneId: 'America/New_York',
  },
  '13647': {
    zip: '13647',
    city: 'Hannawa Falls',
    state: 'NY',
    latitude: '44.612465',
    longitude: '-74.970045',
    timeZoneId: 'America/New_York',
  },
  '13648': {
    zip: '13648',
    city: 'Harrisville',
    state: 'NY',
    latitude: '44.20218',
    longitude: '-75.29706',
    timeZoneId: 'America/New_York',
  },
  '13649': {
    zip: '13649',
    city: 'Helena',
    state: 'NY',
    latitude: '44.921541',
    longitude: '-74.724428',
    timeZoneId: 'America/New_York',
  },
  '13650': {
    zip: '13650',
    city: 'Henderson',
    state: 'NY',
    latitude: '43.849649',
    longitude: '-76.228306',
    timeZoneId: 'America/New_York',
  },
  '13651': {
    zip: '13651',
    city: 'Henderson Harbor',
    state: 'NY',
    latitude: '43.86944',
    longitude: '-76.193826',
    timeZoneId: 'America/New_York',
  },
  '13652': {
    zip: '13652',
    city: 'Hermon',
    state: 'NY',
    latitude: '44.472545',
    longitude: '-75.234487',
    timeZoneId: 'America/New_York',
  },
  '13654': {
    zip: '13654',
    city: 'Heuvelton',
    state: 'NY',
    latitude: '44.5821',
    longitude: '-75.44016',
    timeZoneId: 'America/New_York',
  },
  '13655': {
    zip: '13655',
    city: 'Hogansburg',
    state: 'NY',
    latitude: '44.982946',
    longitude: '-74.655861',
    timeZoneId: 'America/New_York',
  },
  '13656': {
    zip: '13656',
    city: 'La Fargeville',
    state: 'NY',
    latitude: '44.19102',
    longitude: '-75.95556',
    timeZoneId: 'America/New_York',
  },
  '13657': {
    zip: '13657',
    city: 'Limerick',
    state: 'NY',
    latitude: '44.027898',
    longitude: '-76.050991',
    timeZoneId: 'America/New_York',
  },
  '13658': {
    zip: '13658',
    city: 'Lisbon',
    state: 'NY',
    latitude: '44.725973',
    longitude: '-75.323276',
    timeZoneId: 'America/New_York',
  },
  '13659': {
    zip: '13659',
    city: 'Lorraine',
    state: 'NY',
    latitude: '43.791138',
    longitude: '-75.824986',
    timeZoneId: 'America/New_York',
  },
  '13660': {
    zip: '13660',
    city: 'Madrid',
    state: 'NY',
    latitude: '44.749316',
    longitude: '-75.132378',
    timeZoneId: 'America/New_York',
  },
  '13661': {
    zip: '13661',
    city: 'Mannsville',
    state: 'NY',
    latitude: '43.709861',
    longitude: '-76.079638',
    timeZoneId: 'America/New_York',
  },
  '13662': {
    zip: '13662',
    city: 'Massena',
    state: 'NY',
    latitude: '44.91816',
    longitude: '-74.93598',
    timeZoneId: 'America/New_York',
  },
  '13664': {
    zip: '13664',
    city: 'Morristown',
    state: 'NY',
    latitude: '44.583173',
    longitude: '-75.646493',
    timeZoneId: 'America/New_York',
  },
  '13665': {
    zip: '13665',
    city: 'Natural Bridge',
    state: 'NY',
    latitude: '44.099422',
    longitude: '-75.476914',
    timeZoneId: 'America/New_York',
  },
  '13666': {
    zip: '13666',
    city: 'Newton Falls',
    state: 'NY',
    latitude: '44.196954',
    longitude: '-74.978374',
    timeZoneId: 'America/New_York',
  },
  '13667': {
    zip: '13667',
    city: 'Norfolk',
    state: 'NY',
    latitude: '44.799692',
    longitude: '-74.994368',
    timeZoneId: 'America/New_York',
  },
  '13668': {
    zip: '13668',
    city: 'Norwood',
    state: 'NY',
    latitude: '44.76282',
    longitude: '-74.99124',
    timeZoneId: 'America/New_York',
  },
  '13669': {
    zip: '13669',
    city: 'Ogdensburg',
    state: 'NY',
    latitude: '44.66628',
    longitude: '-75.49944',
    timeZoneId: 'America/New_York',
  },
  '13670': {
    zip: '13670',
    city: 'Oswegatchie',
    state: 'NY',
    latitude: '44.179515',
    longitude: '-75.082431',
    timeZoneId: 'America/New_York',
  },
  '13671': {
    zip: '13671',
    city: 'Antwerp',
    state: 'NY',
    latitude: '44.284315',
    longitude: '-75.618389',
    timeZoneId: 'America/New_York',
  },
  '13672': {
    zip: '13672',
    city: 'Parishville',
    state: 'NY',
    latitude: '44.601041',
    longitude: '-74.809679',
    timeZoneId: 'America/New_York',
  },
  '13673': {
    zip: '13673',
    city: 'Philadelphia',
    state: 'NY',
    latitude: '44.157023',
    longitude: '-75.711455',
    timeZoneId: 'America/New_York',
  },
  '13674': {
    zip: '13674',
    city: 'Pierrepont Manor',
    state: 'NY',
    latitude: '43.735379',
    longitude: '-76.058567',
    timeZoneId: 'America/New_York',
  },
  '13675': {
    zip: '13675',
    city: 'Plessis',
    state: 'NY',
    latitude: '44.277977',
    longitude: '-75.850834',
    timeZoneId: 'America/New_York',
  },
  '13676': {
    zip: '13676',
    city: 'Potsdam',
    state: 'NY',
    latitude: '44.65482',
    longitude: '-74.95554',
    timeZoneId: 'America/New_York',
  },
  '13677': {
    zip: '13677',
    city: 'Pyrites',
    state: 'NY',
    latitude: '44.515099',
    longitude: '-75.185572',
    timeZoneId: 'America/New_York',
  },
  '13678': {
    zip: '13678',
    city: 'Raymondville',
    state: 'NY',
    latitude: '44.842211',
    longitude: '-74.981315',
    timeZoneId: 'America/New_York',
  },
  '13679': {
    zip: '13679',
    city: 'Redwood',
    state: 'NY',
    latitude: '44.304223',
    longitude: '-75.80961',
    timeZoneId: 'America/New_York',
  },
  '13680': {
    zip: '13680',
    city: 'Rensselaer Falls',
    state: 'NY',
    latitude: '44.591957',
    longitude: '-75.320308',
    timeZoneId: 'America/New_York',
  },
  '13681': {
    zip: '13681',
    city: 'Richville',
    state: 'NY',
    latitude: '44.412057',
    longitude: '-75.390018',
    timeZoneId: 'America/New_York',
  },
  '13682': {
    zip: '13682',
    city: 'Rodman',
    state: 'NY',
    latitude: '43.847382',
    longitude: '-75.859853',
    timeZoneId: 'America/New_York',
  },
  '13683': {
    zip: '13683',
    city: 'Rooseveltown',
    state: 'NY',
    latitude: '44.973162',
    longitude: '-74.730968',
    timeZoneId: 'America/New_York',
  },
  '13684': {
    zip: '13684',
    city: 'Russell',
    state: 'NY',
    latitude: '44.37588',
    longitude: '-75.06918',
    timeZoneId: 'America/New_York',
  },
  '13685': {
    zip: '13685',
    city: 'Sackets Harbor',
    state: 'NY',
    latitude: '43.943471',
    longitude: '-76.111664',
    timeZoneId: 'America/New_York',
  },
  '13687': {
    zip: '13687',
    city: 'South Colton',
    state: 'NY',
    latitude: '44.501899',
    longitude: '-74.85662',
    timeZoneId: 'America/New_York',
  },
  '13690': {
    zip: '13690',
    city: 'Star Lake',
    state: 'NY',
    latitude: '44.153984',
    longitude: '-75.02951',
    timeZoneId: 'America/New_York',
  },
  '13691': {
    zip: '13691',
    city: 'Theresa',
    state: 'NY',
    latitude: '44.197024',
    longitude: '-75.7963',
    timeZoneId: 'America/New_York',
  },
  '13692': {
    zip: '13692',
    city: 'Thousand Island Park',
    state: 'NY',
    latitude: '44.284887',
    longitude: '-76.02992',
    timeZoneId: 'America/New_York',
  },
  '13693': {
    zip: '13693',
    city: 'Three Mile Bay',
    state: 'NY',
    latitude: '44.04071',
    longitude: '-76.244192',
    timeZoneId: 'America/New_York',
  },
  '13694': {
    zip: '13694',
    city: 'Waddington',
    state: 'NY',
    latitude: '44.863217',
    longitude: '-75.200794',
    timeZoneId: 'America/New_York',
  },
  '13695': {
    zip: '13695',
    city: 'Wanakena',
    state: 'NY',
    latitude: '44.211318',
    longitude: '-75.083927',
    timeZoneId: 'America/New_York',
  },
  '13696': {
    zip: '13696',
    city: 'West Stockholm',
    state: 'NY',
    latitude: '44.734551',
    longitude: '-74.887585',
    timeZoneId: 'America/New_York',
  },
  '13697': {
    zip: '13697',
    city: 'Winthrop',
    state: 'NY',
    latitude: '44.745875',
    longitude: '-74.805347',
    timeZoneId: 'America/New_York',
  },
  '13699': {
    zip: '13699',
    city: 'Potsdam',
    state: 'NY',
    latitude: '44.67116',
    longitude: '-74.986199',
    timeZoneId: 'America/New_York',
  },
  '13730': {
    zip: '13730',
    city: 'Afton',
    state: 'NY',
    latitude: '42.20964',
    longitude: '-75.49026',
    timeZoneId: 'America/New_York',
  },
  '13731': {
    zip: '13731',
    city: 'Andes',
    state: 'NY',
    latitude: '42.13094',
    longitude: '-74.7835',
    timeZoneId: 'America/New_York',
  },
  '13732': {
    zip: '13732',
    city: 'Apalachin',
    state: 'NY',
    latitude: '42.057017',
    longitude: '-76.137903',
    timeZoneId: 'America/New_York',
  },
  '13733': {
    zip: '13733',
    city: 'Bainbridge',
    state: 'NY',
    latitude: '42.30444',
    longitude: '-75.48018',
    timeZoneId: 'America/New_York',
  },
  '13734': {
    zip: '13734',
    city: 'Barton',
    state: 'NY',
    latitude: '42.076679',
    longitude: '-76.40872',
    timeZoneId: 'America/New_York',
  },
  '13736': {
    zip: '13736',
    city: 'Berkshire',
    state: 'NY',
    latitude: '42.3138',
    longitude: '-76.19064',
    timeZoneId: 'America/New_York',
  },
  '13737': {
    zip: '13737',
    city: 'Bible School Park',
    state: 'NY',
    latitude: '42.078376',
    longitude: '-76.096835',
    timeZoneId: 'America/New_York',
  },
  '13738': {
    zip: '13738',
    city: 'Blodgett Mills',
    state: 'NY',
    latitude: '42.565864',
    longitude: '-76.125222',
    timeZoneId: 'America/New_York',
  },
  '13739': {
    zip: '13739',
    city: 'Bloomville',
    state: 'NY',
    latitude: '42.364324',
    longitude: '-74.803449',
    timeZoneId: 'America/New_York',
  },
  '13740': {
    zip: '13740',
    city: 'Bovina Center',
    state: 'NY',
    latitude: '42.261944',
    longitude: '-74.785',
    timeZoneId: 'America/New_York',
  },
  '13743': {
    zip: '13743',
    city: 'Candor',
    state: 'NY',
    latitude: '42.217072',
    longitude: '-76.337592',
    timeZoneId: 'America/New_York',
  },
  '13744': {
    zip: '13744',
    city: 'Castle Creek',
    state: 'NY',
    latitude: '42.225902',
    longitude: '-75.928088',
    timeZoneId: 'America/New_York',
  },
  '13745': {
    zip: '13745',
    city: 'Chenango Bridge',
    state: 'NY',
    latitude: '42.285719',
    longitude: '-75.910766',
    timeZoneId: 'America/New_York',
  },
  '13746': {
    zip: '13746',
    city: 'Chenango Forks',
    state: 'NY',
    latitude: '42.26958',
    longitude: '-75.83958',
    timeZoneId: 'America/New_York',
  },
  '13747': {
    zip: '13747',
    city: 'Colliersville',
    state: 'NY',
    latitude: '42.492615',
    longitude: '-74.982708',
    timeZoneId: 'America/New_York',
  },
  '13748': {
    zip: '13748',
    city: 'Conklin',
    state: 'NY',
    latitude: '42.074345',
    longitude: '-75.819022',
    timeZoneId: 'America/New_York',
  },
  '13749': {
    zip: '13749',
    city: 'Corbettsville',
    state: 'NY',
    latitude: '42.018867',
    longitude: '-75.790964',
    timeZoneId: 'America/New_York',
  },
  '13750': {
    zip: '13750',
    city: 'Davenport',
    state: 'NY',
    latitude: '42.46908',
    longitude: '-74.8074',
    timeZoneId: 'America/New_York',
  },
  '13751': {
    zip: '13751',
    city: 'Davenport Center',
    state: 'NY',
    latitude: '42.45662',
    longitude: '-74.90075',
    timeZoneId: 'America/New_York',
  },
  '13752': {
    zip: '13752',
    city: 'Delancey',
    state: 'NY',
    latitude: '42.164722',
    longitude: '-74.91024',
    timeZoneId: 'America/New_York',
  },
  '13753': {
    zip: '13753',
    city: 'Delhi',
    state: 'NY',
    latitude: '42.282872',
    longitude: '-74.910178',
    timeZoneId: 'America/New_York',
  },
  '13754': {
    zip: '13754',
    city: 'Deposit',
    state: 'NY',
    latitude: '42.0639',
    longitude: '-75.44922',
    timeZoneId: 'America/New_York',
  },
  '13755': {
    zip: '13755',
    city: 'Downsville',
    state: 'NY',
    latitude: '42.067456',
    longitude: '-75.036591',
    timeZoneId: 'America/New_York',
  },
  '13756': {
    zip: '13756',
    city: 'East Branch',
    state: 'NY',
    latitude: '42.023889',
    longitude: '-75.113056',
    timeZoneId: 'America/New_York',
  },
  '13757': {
    zip: '13757',
    city: 'East Meredith',
    state: 'NY',
    latitude: '42.415691',
    longitude: '-74.888542',
    timeZoneId: 'America/New_York',
  },
  '13758': {
    zip: '13758',
    city: 'East Pharsalia',
    state: 'NY',
    latitude: '42.555016',
    longitude: '-75.716571',
    timeZoneId: 'America/New_York',
  },
  '13760': {
    zip: '13760',
    city: 'Endicott',
    state: 'NY',
    latitude: '42.141',
    longitude: '-76.07526',
    timeZoneId: 'America/New_York',
  },
  '13761': {
    zip: '13761',
    city: 'Endicott',
    state: 'NY',
    latitude: '42.100447',
    longitude: '-76.048838',
    timeZoneId: 'America/New_York',
  },
  '13762': {
    zip: '13762',
    city: 'Endwell',
    state: 'NY',
    latitude: '42.107199',
    longitude: '-76.023903',
    timeZoneId: 'America/New_York',
  },
  '13763': {
    zip: '13763',
    city: 'Endicott',
    state: 'NY',
    latitude: '42.094477',
    longitude: '-76.065139',
    timeZoneId: 'America/New_York',
  },
  '13774': {
    zip: '13774',
    city: 'Fishs Eddy',
    state: 'NY',
    latitude: '41.963583',
    longitude: '-75.173944',
    timeZoneId: 'America/New_York',
  },
  '13775': {
    zip: '13775',
    city: 'Franklin',
    state: 'NY',
    latitude: '42.340614',
    longitude: '-75.16093',
    timeZoneId: 'America/New_York',
  },
  '13776': {
    zip: '13776',
    city: 'Gilbertsville',
    state: 'NY',
    latitude: '42.4344',
    longitude: '-75.36258',
    timeZoneId: 'America/New_York',
  },
  '13777': {
    zip: '13777',
    city: 'Glen Aubrey',
    state: 'NY',
    latitude: '42.239707',
    longitude: '-76.010116',
    timeZoneId: 'America/New_York',
  },
  '13778': {
    zip: '13778',
    city: 'Greene',
    state: 'NY',
    latitude: '42.331969',
    longitude: '-75.768267',
    timeZoneId: 'America/New_York',
  },
  '13780': {
    zip: '13780',
    city: 'Guilford',
    state: 'NY',
    latitude: '42.405278',
    longitude: '-75.4625',
    timeZoneId: 'America/New_York',
  },
  '13782': {
    zip: '13782',
    city: 'Hamden',
    state: 'NY',
    latitude: '42.218008',
    longitude: '-75.012581',
    timeZoneId: 'America/New_York',
  },
  '13783': {
    zip: '13783',
    city: 'Hancock',
    state: 'NY',
    latitude: '41.94588',
    longitude: '-75.23886',
    timeZoneId: 'America/New_York',
  },
  '13784': {
    zip: '13784',
    city: 'Harford',
    state: 'NY',
    latitude: '42.41974',
    longitude: '-76.231397',
    timeZoneId: 'America/New_York',
  },
  '13786': {
    zip: '13786',
    city: 'Harpersfield',
    state: 'NY',
    latitude: '42.434722',
    longitude: '-74.723889',
    timeZoneId: 'America/New_York',
  },
  '13787': {
    zip: '13787',
    city: 'Harpursville',
    state: 'NY',
    latitude: '42.1893',
    longitude: '-75.68478',
    timeZoneId: 'America/New_York',
  },
  '13788': {
    zip: '13788',
    city: 'Hobart',
    state: 'NY',
    latitude: '42.381997',
    longitude: '-74.681185',
    timeZoneId: 'America/New_York',
  },
  '13790': {
    zip: '13790',
    city: 'Johnson City',
    state: 'NY',
    latitude: '42.120267',
    longitude: '-75.972895',
    timeZoneId: 'America/New_York',
  },
  '13794': {
    zip: '13794',
    city: 'Killawog',
    state: 'NY',
    latitude: '42.399761',
    longitude: '-76.021494',
    timeZoneId: 'America/New_York',
  },
  '13795': {
    zip: '13795',
    city: 'Kirkwood',
    state: 'NY',
    latitude: '42.067223',
    longitude: '-75.796242',
    timeZoneId: 'America/New_York',
  },
  '13796': {
    zip: '13796',
    city: 'Laurens',
    state: 'NY',
    latitude: '42.54372',
    longitude: '-75.1407',
    timeZoneId: 'America/New_York',
  },
  '13797': {
    zip: '13797',
    city: 'Lisle',
    state: 'NY',
    latitude: '42.334546',
    longitude: '-76.047179',
    timeZoneId: 'America/New_York',
  },
  '13801': {
    zip: '13801',
    city: 'Mc Donough',
    state: 'NY',
    latitude: '42.517672',
    longitude: '-75.776919',
    timeZoneId: 'America/New_York',
  },
  '13802': {
    zip: '13802',
    city: 'Maine',
    state: 'NY',
    latitude: '42.202719',
    longitude: '-76.056278',
    timeZoneId: 'America/New_York',
  },
  '13803': {
    zip: '13803',
    city: 'Marathon',
    state: 'NY',
    latitude: '42.465051',
    longitude: '-76.017549',
    timeZoneId: 'America/New_York',
  },
  '13804': {
    zip: '13804',
    city: 'Masonville',
    state: 'NY',
    latitude: '42.22237',
    longitude: '-75.371063',
    timeZoneId: 'America/New_York',
  },
  '13806': {
    zip: '13806',
    city: 'Meridale',
    state: 'NY',
    latitude: '42.376987',
    longitude: '-74.975118',
    timeZoneId: 'America/New_York',
  },
  '13807': {
    zip: '13807',
    city: 'Milford',
    state: 'NY',
    latitude: '42.619949',
    longitude: '-74.983832',
    timeZoneId: 'America/New_York',
  },
  '13808': {
    zip: '13808',
    city: 'Morris',
    state: 'NY',
    latitude: '42.547176',
    longitude: '-75.24308',
    timeZoneId: 'America/New_York',
  },
  '13809': {
    zip: '13809',
    city: 'Mount Upton',
    state: 'NY',
    latitude: '42.406126',
    longitude: '-75.409032',
    timeZoneId: 'America/New_York',
  },
  '13810': {
    zip: '13810',
    city: 'Mount Vision',
    state: 'NY',
    latitude: '42.612677',
    longitude: '-75.133564',
    timeZoneId: 'America/New_York',
  },
  '13811': {
    zip: '13811',
    city: 'Newark Valley',
    state: 'NY',
    latitude: '42.23238',
    longitude: '-76.17642',
    timeZoneId: 'America/New_York',
  },
  '13812': {
    zip: '13812',
    city: 'Nichols',
    state: 'NY',
    latitude: '42.04296',
    longitude: '-76.3587',
    timeZoneId: 'America/New_York',
  },
  '13813': {
    zip: '13813',
    city: 'Nineveh',
    state: 'NY',
    latitude: '42.15474',
    longitude: '-75.54276',
    timeZoneId: 'America/New_York',
  },
  '13814': {
    zip: '13814',
    city: 'North Norwich',
    state: 'NY',
    latitude: '42.616122',
    longitude: '-75.526146',
    timeZoneId: 'America/New_York',
  },
  '13815': {
    zip: '13815',
    city: 'Norwich',
    state: 'NY',
    latitude: '42.530787',
    longitude: '-75.524876',
    timeZoneId: 'America/New_York',
  },
  '13820': {
    zip: '13820',
    city: 'Oneonta',
    state: 'NY',
    latitude: '42.48102',
    longitude: '-75.07044',
    timeZoneId: 'America/New_York',
  },
  '13825': {
    zip: '13825',
    city: 'Otego',
    state: 'NY',
    latitude: '42.394454',
    longitude: '-75.172478',
    timeZoneId: 'America/New_York',
  },
  '13826': {
    zip: '13826',
    city: 'Ouaquaga',
    state: 'NY',
    latitude: '42.111157',
    longitude: '-75.645249',
    timeZoneId: 'America/New_York',
  },
  '13827': {
    zip: '13827',
    city: 'Owego',
    state: 'NY',
    latitude: '42.09456',
    longitude: '-76.24986',
    timeZoneId: 'America/New_York',
  },
  '13830': {
    zip: '13830',
    city: 'Oxford',
    state: 'NY',
    latitude: '42.4425',
    longitude: '-75.62328',
    timeZoneId: 'America/New_York',
  },
  '13832': {
    zip: '13832',
    city: 'Plymouth',
    state: 'NY',
    latitude: '42.631654',
    longitude: '-75.62093',
    timeZoneId: 'America/New_York',
  },
  '13833': {
    zip: '13833',
    city: 'Port Crane',
    state: 'NY',
    latitude: '42.20052',
    longitude: '-75.77346',
    timeZoneId: 'America/New_York',
  },
  '13834': {
    zip: '13834',
    city: 'Portlandville',
    state: 'NY',
    latitude: '42.510775',
    longitude: '-74.963599',
    timeZoneId: 'America/New_York',
  },
  '13835': {
    zip: '13835',
    city: 'Richford',
    state: 'NY',
    latitude: '42.409488',
    longitude: '-76.227768',
    timeZoneId: 'America/New_York',
  },
  '13838': {
    zip: '13838',
    city: 'Sidney',
    state: 'NY',
    latitude: '42.3054',
    longitude: '-75.390406',
    timeZoneId: 'America/New_York',
  },
  '13839': {
    zip: '13839',
    city: 'Sidney Center',
    state: 'NY',
    latitude: '42.220441',
    longitude: '-75.323336',
    timeZoneId: 'America/New_York',
  },
  '13840': {
    zip: '13840',
    city: 'Smithboro',
    state: 'NY',
    latitude: '42.098839',
    longitude: '-76.364903',
    timeZoneId: 'America/New_York',
  },
  '13841': {
    zip: '13841',
    city: 'Smithville Flats',
    state: 'NY',
    latitude: '42.39744',
    longitude: '-75.83658',
    timeZoneId: 'America/New_York',
  },
  '13842': {
    zip: '13842',
    city: 'South Kortright',
    state: 'NY',
    latitude: '42.381469',
    longitude: '-74.724897',
    timeZoneId: 'America/New_York',
  },
  '13843': {
    zip: '13843',
    city: 'South New Berlin',
    state: 'NY',
    latitude: '42.537177',
    longitude: '-75.347849',
    timeZoneId: 'America/New_York',
  },
  '13844': {
    zip: '13844',
    city: 'South Plymouth',
    state: 'NY',
    latitude: '42.608333',
    longitude: '-75.701944',
    timeZoneId: 'America/New_York',
  },
  '13845': {
    zip: '13845',
    city: 'Tioga Center',
    state: 'NY',
    latitude: '42.057895',
    longitude: '-76.350624',
    timeZoneId: 'America/New_York',
  },
  '13846': {
    zip: '13846',
    city: 'Treadwell',
    state: 'NY',
    latitude: '42.340556',
    longitude: '-75.165556',
    timeZoneId: 'America/New_York',
  },
  '13847': {
    zip: '13847',
    city: 'Trout Creek',
    state: 'NY',
    latitude: '42.206605',
    longitude: '-75.27959',
    timeZoneId: 'America/New_York',
  },
  '13848': {
    zip: '13848',
    city: 'Tunnel',
    state: 'NY',
    latitude: '42.216349',
    longitude: '-75.728147',
    timeZoneId: 'America/New_York',
  },
  '13849': {
    zip: '13849',
    city: 'Unadilla',
    state: 'NY',
    latitude: '42.323762',
    longitude: '-75.319978',
    timeZoneId: 'America/New_York',
  },
  '13850': {
    zip: '13850',
    city: 'Vestal',
    state: 'NY',
    latitude: '42.05556',
    longitude: '-76.02456',
    timeZoneId: 'America/New_York',
  },
  '13851': {
    zip: '13851',
    city: 'Vestal',
    state: 'NY',
    latitude: '42.035931',
    longitude: '-76.019579',
    timeZoneId: 'America/New_York',
  },
  '13856': {
    zip: '13856',
    city: 'Walton',
    state: 'NY',
    latitude: '42.246944',
    longitude: '-75.189167',
    timeZoneId: 'America/New_York',
  },
  '13859': {
    zip: '13859',
    city: 'Wells Bridge',
    state: 'NY',
    latitude: '42.363079',
    longitude: '-75.249368',
    timeZoneId: 'America/New_York',
  },
  '13860': {
    zip: '13860',
    city: 'West Davenport',
    state: 'NY',
    latitude: '42.446027',
    longitude: '-74.965139',
    timeZoneId: 'America/New_York',
  },
  '13861': {
    zip: '13861',
    city: 'West Oneonta',
    state: 'NY',
    latitude: '42.446255',
    longitude: '-75.111607',
    timeZoneId: 'America/New_York',
  },
  '13862': {
    zip: '13862',
    city: 'Whitney Point',
    state: 'NY',
    latitude: '42.35784',
    longitude: '-75.93816',
    timeZoneId: 'America/New_York',
  },
  '13863': {
    zip: '13863',
    city: 'Willet',
    state: 'NY',
    latitude: '42.450705',
    longitude: '-75.904204',
    timeZoneId: 'America/New_York',
  },
  '13864': {
    zip: '13864',
    city: 'Willseyville',
    state: 'NY',
    latitude: '42.276942',
    longitude: '-76.378237',
    timeZoneId: 'America/New_York',
  },
  '13865': {
    zip: '13865',
    city: 'Windsor',
    state: 'NY',
    latitude: '42.06402',
    longitude: '-75.64038',
    timeZoneId: 'America/New_York',
  },
  '13901': {
    zip: '13901',
    city: 'Binghamton',
    state: 'NY',
    latitude: '42.15666',
    longitude: '-75.89052',
    timeZoneId: 'America/New_York',
  },
  '13902': {
    zip: '13902',
    city: 'Binghamton',
    state: 'NY',
    latitude: '42.107412',
    longitude: '-75.868335',
    timeZoneId: 'America/New_York',
  },
  '13903': {
    zip: '13903',
    city: 'Binghamton',
    state: 'NY',
    latitude: '42.0975',
    longitude: '-75.89424',
    timeZoneId: 'America/New_York',
  },
  '13904': {
    zip: '13904',
    city: 'Binghamton',
    state: 'NY',
    latitude: '42.12906',
    longitude: '-75.82182',
    timeZoneId: 'America/New_York',
  },
  '13905': {
    zip: '13905',
    city: 'Binghamton',
    state: 'NY',
    latitude: '42.16062',
    longitude: '-75.93648',
    timeZoneId: 'America/New_York',
  },
  '14001': {
    zip: '14001',
    city: 'Akron',
    state: 'NY',
    latitude: '43.016719',
    longitude: '-78.510497',
    timeZoneId: 'America/New_York',
  },
  '14004': {
    zip: '14004',
    city: 'Alden',
    state: 'NY',
    latitude: '42.901992',
    longitude: '-78.524759',
    timeZoneId: 'America/New_York',
  },
  '14005': {
    zip: '14005',
    city: 'Alexander',
    state: 'NY',
    latitude: '42.918368',
    longitude: '-78.252988',
    timeZoneId: 'America/New_York',
  },
  '14006': {
    zip: '14006',
    city: 'Angola',
    state: 'NY',
    latitude: '42.634072',
    longitude: '-79.051514',
    timeZoneId: 'America/New_York',
  },
  '14008': {
    zip: '14008',
    city: 'Appleton',
    state: 'NY',
    latitude: '43.306841',
    longitude: '-78.637519',
    timeZoneId: 'America/New_York',
  },
  '14009': {
    zip: '14009',
    city: 'Arcade',
    state: 'NY',
    latitude: '42.569872',
    longitude: '-78.406548',
    timeZoneId: 'America/New_York',
  },
  '14010': {
    zip: '14010',
    city: 'Athol Springs',
    state: 'NY',
    latitude: '42.773247',
    longitude: '-78.865948',
    timeZoneId: 'America/New_York',
  },
  '14011': {
    zip: '14011',
    city: 'Attica',
    state: 'NY',
    latitude: '42.835713',
    longitude: '-78.280907',
    timeZoneId: 'America/New_York',
  },
  '14012': {
    zip: '14012',
    city: 'Barker',
    state: 'NY',
    latitude: '43.338643',
    longitude: '-78.531618',
    timeZoneId: 'America/New_York',
  },
  '14013': {
    zip: '14013',
    city: 'Basom',
    state: 'NY',
    latitude: '43.071553',
    longitude: '-78.387693',
    timeZoneId: 'America/New_York',
  },
  '14020': {
    zip: '14020',
    city: 'Batavia',
    state: 'NY',
    latitude: '42.994565',
    longitude: '-78.190625',
    timeZoneId: 'America/New_York',
  },
  '14021': {
    zip: '14021',
    city: 'Batavia',
    state: 'NY',
    latitude: '43.018919',
    longitude: '-78.163893',
    timeZoneId: 'America/New_York',
  },
  '14024': {
    zip: '14024',
    city: 'Bliss',
    state: 'NY',
    latitude: '42.574673',
    longitude: '-78.2611',
    timeZoneId: 'America/New_York',
  },
  '14025': {
    zip: '14025',
    city: 'Boston',
    state: 'NY',
    latitude: '42.626575',
    longitude: '-78.720978',
    timeZoneId: 'America/New_York',
  },
  '14026': {
    zip: '14026',
    city: 'Bowmansville',
    state: 'NY',
    latitude: '42.941598',
    longitude: '-78.690474',
    timeZoneId: 'America/New_York',
  },
  '14027': {
    zip: '14027',
    city: 'Brant',
    state: 'NY',
    latitude: '42.580316',
    longitude: '-79.012869',
    timeZoneId: 'America/New_York',
  },
  '14028': {
    zip: '14028',
    city: 'Burt',
    state: 'NY',
    latitude: '43.319563',
    longitude: '-78.724973',
    timeZoneId: 'America/New_York',
  },
  '14029': {
    zip: '14029',
    city: 'Centerville',
    state: 'NY',
    latitude: '42.481516',
    longitude: '-78.251815',
    timeZoneId: 'America/New_York',
  },
  '14030': {
    zip: '14030',
    city: 'Chaffee',
    state: 'NY',
    latitude: '42.570274',
    longitude: '-78.505479',
    timeZoneId: 'America/New_York',
  },
  '14031': {
    zip: '14031',
    city: 'Clarence',
    state: 'NY',
    latitude: '42.982268',
    longitude: '-78.617411',
    timeZoneId: 'America/New_York',
  },
  '14032': {
    zip: '14032',
    city: 'Clarence Center',
    state: 'NY',
    latitude: '43.045445',
    longitude: '-78.631521',
    timeZoneId: 'America/New_York',
  },
  '14033': {
    zip: '14033',
    city: 'Colden',
    state: 'NY',
    latitude: '42.657503',
    longitude: '-78.685041',
    timeZoneId: 'America/New_York',
  },
  '14034': {
    zip: '14034',
    city: 'Collins',
    state: 'NY',
    latitude: '42.502473',
    longitude: '-78.860926',
    timeZoneId: 'America/New_York',
  },
  '14035': {
    zip: '14035',
    city: 'Collins Center',
    state: 'NY',
    latitude: '42.493611',
    longitude: '-78.851667',
    timeZoneId: 'America/New_York',
  },
  '14036': {
    zip: '14036',
    city: 'Corfu',
    state: 'NY',
    latitude: '42.972006',
    longitude: '-78.383842',
    timeZoneId: 'America/New_York',
  },
  '14037': {
    zip: '14037',
    city: 'Cowlesville',
    state: 'NY',
    latitude: '42.818365',
    longitude: '-78.455883',
    timeZoneId: 'America/New_York',
  },
  '14038': {
    zip: '14038',
    city: 'Crittenden',
    state: 'NY',
    latitude: '42.948673',
    longitude: '-78.476057',
    timeZoneId: 'America/New_York',
  },
  '14039': {
    zip: '14039',
    city: 'Dale',
    state: 'NY',
    latitude: '42.837927',
    longitude: '-78.171008',
    timeZoneId: 'America/New_York',
  },
  '14040': {
    zip: '14040',
    city: 'Darien Center',
    state: 'NY',
    latitude: '42.89356',
    longitude: '-78.377354',
    timeZoneId: 'America/New_York',
  },
  '14041': {
    zip: '14041',
    city: 'Dayton',
    state: 'NY',
    latitude: '42.423665',
    longitude: '-78.98032',
    timeZoneId: 'America/New_York',
  },
  '14042': {
    zip: '14042',
    city: 'Delevan',
    state: 'NY',
    latitude: '42.471072',
    longitude: '-78.516154',
    timeZoneId: 'America/New_York',
  },
  '14043': {
    zip: '14043',
    city: 'Depew',
    state: 'NY',
    latitude: '42.902602',
    longitude: '-78.706762',
    timeZoneId: 'America/New_York',
  },
  '14047': {
    zip: '14047',
    city: 'Derby',
    state: 'NY',
    latitude: '42.69462',
    longitude: '-78.987802',
    timeZoneId: 'America/New_York',
  },
  '14048': {
    zip: '14048',
    city: 'Dunkirk',
    state: 'NY',
    latitude: '42.479473',
    longitude: '-79.328465',
    timeZoneId: 'America/New_York',
  },
  '14051': {
    zip: '14051',
    city: 'East Amherst',
    state: 'NY',
    latitude: '43.033131',
    longitude: '-78.69326',
    timeZoneId: 'America/New_York',
  },
  '14052': {
    zip: '14052',
    city: 'East Aurora',
    state: 'NY',
    latitude: '42.771162',
    longitude: '-78.563272',
    timeZoneId: 'America/New_York',
  },
  '14054': {
    zip: '14054',
    city: 'East Bethany',
    state: 'NY',
    latitude: '42.91559',
    longitude: '-78.135091',
    timeZoneId: 'America/New_York',
  },
  '14055': {
    zip: '14055',
    city: 'East Concord',
    state: 'NY',
    latitude: '42.553172',
    longitude: '-78.608173',
    timeZoneId: 'America/New_York',
  },
  '14056': {
    zip: '14056',
    city: 'East Pembroke',
    state: 'NY',
    latitude: '42.997572',
    longitude: '-78.317822',
    timeZoneId: 'America/New_York',
  },
  '14057': {
    zip: '14057',
    city: 'Eden',
    state: 'NY',
    latitude: '42.64529',
    longitude: '-78.871317',
    timeZoneId: 'America/New_York',
  },
  '14058': {
    zip: '14058',
    city: 'Elba',
    state: 'NY',
    latitude: '43.085396',
    longitude: '-78.156946',
    timeZoneId: 'America/New_York',
  },
  '14059': {
    zip: '14059',
    city: 'Elma',
    state: 'NY',
    latitude: '42.831055',
    longitude: '-78.639808',
    timeZoneId: 'America/New_York',
  },
  '14060': {
    zip: '14060',
    city: 'Farmersville Station',
    state: 'NY',
    latitude: '42.43674',
    longitude: '-78.30222',
    timeZoneId: 'America/New_York',
  },
  '14061': {
    zip: '14061',
    city: 'Farnham',
    state: 'NY',
    latitude: '42.592321',
    longitude: '-79.088492',
    timeZoneId: 'America/New_York',
  },
  '14062': {
    zip: '14062',
    city: 'Forestville',
    state: 'NY',
    latitude: '42.452584',
    longitude: '-79.157422',
    timeZoneId: 'America/New_York',
  },
  '14063': {
    zip: '14063',
    city: 'Fredonia',
    state: 'NY',
    latitude: '42.425716',
    longitude: '-79.34028',
    timeZoneId: 'America/New_York',
  },
  '14065': {
    zip: '14065',
    city: 'Freedom',
    state: 'NY',
    latitude: '42.47664',
    longitude: '-78.31644',
    timeZoneId: 'America/New_York',
  },
  '14066': {
    zip: '14066',
    city: 'Gainesville',
    state: 'NY',
    latitude: '42.637334',
    longitude: '-78.190857',
    timeZoneId: 'America/New_York',
  },
  '14067': {
    zip: '14067',
    city: 'Gasport',
    state: 'NY',
    latitude: '43.207686',
    longitude: '-78.563528',
    timeZoneId: 'America/New_York',
  },
  '14068': {
    zip: '14068',
    city: 'Getzville',
    state: 'NY',
    latitude: '43.023243',
    longitude: '-78.753686',
    timeZoneId: 'America/New_York',
  },
  '14069': {
    zip: '14069',
    city: 'Glenwood',
    state: 'NY',
    latitude: '42.598702',
    longitude: '-78.639675',
    timeZoneId: 'America/New_York',
  },
  '14070': {
    zip: '14070',
    city: 'Gowanda',
    state: 'NY',
    latitude: '42.45302',
    longitude: '-78.939188',
    timeZoneId: 'America/New_York',
  },
  '14072': {
    zip: '14072',
    city: 'Grand Island',
    state: 'NY',
    latitude: '43.013771',
    longitude: '-78.958496',
    timeZoneId: 'America/New_York',
  },
  '14075': {
    zip: '14075',
    city: 'Hamburg',
    state: 'NY',
    latitude: '42.733234',
    longitude: '-78.834422',
    timeZoneId: 'America/New_York',
  },
  '14080': {
    zip: '14080',
    city: 'Holland',
    state: 'NY',
    latitude: '42.640921',
    longitude: '-78.538651',
    timeZoneId: 'America/New_York',
  },
  '14081': {
    zip: '14081',
    city: 'Irving',
    state: 'NY',
    latitude: '42.556143',
    longitude: '-79.122894',
    timeZoneId: 'America/New_York',
  },
  '14082': {
    zip: '14082',
    city: 'Java Center',
    state: 'NY',
    latitude: '42.657968',
    longitude: '-78.385303',
    timeZoneId: 'America/New_York',
  },
  '14083': {
    zip: '14083',
    city: 'Java Village',
    state: 'NY',
    latitude: '42.671187',
    longitude: '-78.432902',
    timeZoneId: 'America/New_York',
  },
  '14085': {
    zip: '14085',
    city: 'Lake View',
    state: 'NY',
    latitude: '42.719062',
    longitude: '-78.934684',
    timeZoneId: 'America/New_York',
  },
  '14086': {
    zip: '14086',
    city: 'Lancaster',
    state: 'NY',
    latitude: '42.905877',
    longitude: '-78.627625',
    timeZoneId: 'America/New_York',
  },
  '14091': {
    zip: '14091',
    city: 'Lawtons',
    state: 'NY',
    latitude: '42.539825',
    longitude: '-78.865691',
    timeZoneId: 'America/New_York',
  },
  '14092': {
    zip: '14092',
    city: 'Lewiston',
    state: 'NY',
    latitude: '43.171111',
    longitude: '-79.018833',
    timeZoneId: 'America/New_York',
  },
  '14094': {
    zip: '14094',
    city: 'Lockport',
    state: 'NY',
    latitude: '43.1649',
    longitude: '-78.70248',
    timeZoneId: 'America/New_York',
  },
  '14095': {
    zip: '14095',
    city: 'Lockport',
    state: 'NY',
    latitude: '43.171046',
    longitude: '-78.688835',
    timeZoneId: 'America/New_York',
  },
  '14098': {
    zip: '14098',
    city: 'Lyndonville',
    state: 'NY',
    latitude: '43.337372',
    longitude: '-78.377353',
    timeZoneId: 'America/New_York',
  },
  '14101': {
    zip: '14101',
    city: 'Machias',
    state: 'NY',
    latitude: '42.407012',
    longitude: '-78.51734',
    timeZoneId: 'America/New_York',
  },
  '14102': {
    zip: '14102',
    city: 'Marilla',
    state: 'NY',
    latitude: '42.836658',
    longitude: '-78.553177',
    timeZoneId: 'America/New_York',
  },
  '14103': {
    zip: '14103',
    city: 'Medina',
    state: 'NY',
    latitude: '43.217164',
    longitude: '-78.385469',
    timeZoneId: 'America/New_York',
  },
  '14105': {
    zip: '14105',
    city: 'Middleport',
    state: 'NY',
    latitude: '43.209014',
    longitude: '-78.492391',
    timeZoneId: 'America/New_York',
  },
  '14107': {
    zip: '14107',
    city: 'Model City',
    state: 'NY',
    latitude: '43.193133',
    longitude: '-78.98327',
    timeZoneId: 'America/New_York',
  },
  '14108': {
    zip: '14108',
    city: 'Newfane',
    state: 'NY',
    latitude: '43.26296',
    longitude: '-78.726232',
    timeZoneId: 'America/New_York',
  },
  '14109': {
    zip: '14109',
    city: 'Niagara University',
    state: 'NY',
    latitude: '43.13435',
    longitude: '-79.03593',
    timeZoneId: 'America/New_York',
  },
  '14110': {
    zip: '14110',
    city: 'North Boston',
    state: 'NY',
    latitude: '42.684526',
    longitude: '-78.774487',
    timeZoneId: 'America/New_York',
  },
  '14111': {
    zip: '14111',
    city: 'North Collins',
    state: 'NY',
    latitude: '42.58351',
    longitude: '-78.908047',
    timeZoneId: 'America/New_York',
  },
  '14112': {
    zip: '14112',
    city: 'North Evans',
    state: 'NY',
    latitude: '42.697844',
    longitude: '-78.98493',
    timeZoneId: 'America/New_York',
  },
  '14113': {
    zip: '14113',
    city: 'North Java',
    state: 'NY',
    latitude: '42.67487',
    longitude: '-78.338627',
    timeZoneId: 'America/New_York',
  },
  '14120': {
    zip: '14120',
    city: 'North Tonawanda',
    state: 'NY',
    latitude: '43.07124',
    longitude: '-78.81414',
    timeZoneId: 'America/New_York',
  },
  '14125': {
    zip: '14125',
    city: 'Oakfield',
    state: 'NY',
    latitude: '43.078642',
    longitude: '-78.272493',
    timeZoneId: 'America/New_York',
  },
  '14126': {
    zip: '14126',
    city: 'Olcott',
    state: 'NY',
    latitude: '43.332652',
    longitude: '-78.718556',
    timeZoneId: 'America/New_York',
  },
  '14127': {
    zip: '14127',
    city: 'Orchard Park',
    state: 'NY',
    latitude: '42.764164',
    longitude: '-78.742821',
    timeZoneId: 'America/New_York',
  },
  '14129': {
    zip: '14129',
    city: 'Perrysburg',
    state: 'NY',
    latitude: '42.469663',
    longitude: '-79.006594',
    timeZoneId: 'America/New_York',
  },
  '14130': {
    zip: '14130',
    city: 'Pike',
    state: 'NY',
    latitude: '42.554652',
    longitude: '-78.144859',
    timeZoneId: 'America/New_York',
  },
  '14131': {
    zip: '14131',
    city: 'Ransomville',
    state: 'NY',
    latitude: '43.228804',
    longitude: '-78.911638',
    timeZoneId: 'America/New_York',
  },
  '14132': {
    zip: '14132',
    city: 'Sanborn',
    state: 'NY',
    latitude: '43.147891',
    longitude: '-78.872724',
    timeZoneId: 'America/New_York',
  },
  '14133': {
    zip: '14133',
    city: 'Sandusky',
    state: 'NY',
    latitude: '42.499653',
    longitude: '-78.378886',
    timeZoneId: 'America/New_York',
  },
  '14134': {
    zip: '14134',
    city: 'Sardinia',
    state: 'NY',
    latitude: '42.534136',
    longitude: '-78.52412',
    timeZoneId: 'America/New_York',
  },
  '14135': {
    zip: '14135',
    city: 'Sheridan',
    state: 'NY',
    latitude: '42.487157',
    longitude: '-79.240878',
    timeZoneId: 'America/New_York',
  },
  '14136': {
    zip: '14136',
    city: 'Silver Creek',
    state: 'NY',
    latitude: '42.53635',
    longitude: '-79.168058',
    timeZoneId: 'America/New_York',
  },
  '14138': {
    zip: '14138',
    city: 'South Dayton',
    state: 'NY',
    latitude: '42.399405',
    longitude: '-79.100331',
    timeZoneId: 'America/New_York',
  },
  '14139': {
    zip: '14139',
    city: 'South Wales',
    state: 'NY',
    latitude: '42.718999',
    longitude: '-78.536856',
    timeZoneId: 'America/New_York',
  },
  '14140': {
    zip: '14140',
    city: 'Spring Brook',
    state: 'NY',
    latitude: '42.801249',
    longitude: '-78.665071',
    timeZoneId: 'America/New_York',
  },
  '14141': {
    zip: '14141',
    city: 'Springville',
    state: 'NY',
    latitude: '42.521734',
    longitude: '-78.704161',
    timeZoneId: 'America/New_York',
  },
  '14143': {
    zip: '14143',
    city: 'Stafford',
    state: 'NY',
    latitude: '42.981048',
    longitude: '-78.080897',
    timeZoneId: 'America/New_York',
  },
  '14144': {
    zip: '14144',
    city: 'Stella Niagara',
    state: 'NY',
    latitude: '43.19788',
    longitude: '-79.046406',
    timeZoneId: 'America/New_York',
  },
  '14145': {
    zip: '14145',
    city: 'Strykersville',
    state: 'NY',
    latitude: '42.73854',
    longitude: '-78.42456',
    timeZoneId: 'America/New_York',
  },
  '14150': {
    zip: '14150',
    city: 'Tonawanda',
    state: 'NY',
    latitude: '43.001575',
    longitude: '-78.861741',
    timeZoneId: 'America/New_York',
  },
  '14151': {
    zip: '14151',
    city: 'Tonawanda',
    state: 'NY',
    latitude: '43.016876',
    longitude: '-78.878188',
    timeZoneId: 'America/New_York',
  },
  '14166': {
    zip: '14166',
    city: 'Van Buren Point',
    state: 'NY',
    latitude: '42.450113',
    longitude: '-79.417318',
    timeZoneId: 'America/New_York',
  },
  '14167': {
    zip: '14167',
    city: 'Varysburg',
    state: 'NY',
    latitude: '42.737511',
    longitude: '-78.317594',
    timeZoneId: 'America/New_York',
  },
  '14168': {
    zip: '14168',
    city: 'Versailles',
    state: 'NY',
    latitude: '42.518902',
    longitude: '-78.991091',
    timeZoneId: 'America/New_York',
  },
  '14169': {
    zip: '14169',
    city: 'Wales Center',
    state: 'NY',
    latitude: '42.766324',
    longitude: '-78.527795',
    timeZoneId: 'America/New_York',
  },
  '14170': {
    zip: '14170',
    city: 'West Falls',
    state: 'NY',
    latitude: '42.704094',
    longitude: '-78.670831',
    timeZoneId: 'America/New_York',
  },
  '14171': {
    zip: '14171',
    city: 'West Valley',
    state: 'NY',
    latitude: '42.417525',
    longitude: '-78.638025',
    timeZoneId: 'America/New_York',
  },
  '14172': {
    zip: '14172',
    city: 'Wilson',
    state: 'NY',
    latitude: '43.276519',
    longitude: '-78.809408',
    timeZoneId: 'America/New_York',
  },
  '14173': {
    zip: '14173',
    city: 'Yorkshire',
    state: 'NY',
    latitude: '42.527409',
    longitude: '-78.47335',
    timeZoneId: 'America/New_York',
  },
  '14174': {
    zip: '14174',
    city: 'Youngstown',
    state: 'NY',
    latitude: '43.249215',
    longitude: '-79.02603',
    timeZoneId: 'America/New_York',
  },
  '14201': {
    zip: '14201',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.89797',
    longitude: '-78.884047',
    timeZoneId: 'America/New_York',
  },
  '14202': {
    zip: '14202',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.882757',
    longitude: '-78.877245',
    timeZoneId: 'America/New_York',
  },
  '14203': {
    zip: '14203',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.88215',
    longitude: '-78.867026',
    timeZoneId: 'America/New_York',
  },
  '14204': {
    zip: '14204',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.881129',
    longitude: '-78.864058',
    timeZoneId: 'America/New_York',
  },
  '14205': {
    zip: '14205',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.892353',
    longitude: '-78.868805',
    timeZoneId: 'America/New_York',
  },
  '14206': {
    zip: '14206',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.869763',
    longitude: '-78.796792',
    timeZoneId: 'America/New_York',
  },
  '14207': {
    zip: '14207',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.945566',
    longitude: '-78.896412',
    timeZoneId: 'America/New_York',
  },
  '14208': {
    zip: '14208',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.912922',
    longitude: '-78.851613',
    timeZoneId: 'America/New_York',
  },
  '14209': {
    zip: '14209',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.917486',
    longitude: '-78.86498',
    timeZoneId: 'America/New_York',
  },
  '14210': {
    zip: '14210',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.863725',
    longitude: '-78.827208',
    timeZoneId: 'America/New_York',
  },
  '14211': {
    zip: '14211',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.909859',
    longitude: '-78.82294',
    timeZoneId: 'America/New_York',
  },
  '14212': {
    zip: '14212',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.86202',
    longitude: '-78.81858',
    timeZoneId: 'America/New_York',
  },
  '14213': {
    zip: '14213',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.917852',
    longitude: '-78.889241',
    timeZoneId: 'America/New_York',
  },
  '14214': {
    zip: '14214',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.941449',
    longitude: '-78.836451',
    timeZoneId: 'America/New_York',
  },
  '14215': {
    zip: '14215',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.933454',
    longitude: '-78.81184',
    timeZoneId: 'America/New_York',
  },
  '14216': {
    zip: '14216',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.947283',
    longitude: '-78.859475',
    timeZoneId: 'America/New_York',
  },
  '14217': {
    zip: '14217',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.972758',
    longitude: '-78.876061',
    timeZoneId: 'America/New_York',
  },
  '14218': {
    zip: '14218',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.810099',
    longitude: '-78.781921',
    timeZoneId: 'America/New_York',
  },
  '14219': {
    zip: '14219',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.785549',
    longitude: '-78.822312',
    timeZoneId: 'America/New_York',
  },
  '14220': {
    zip: '14220',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.833786',
    longitude: '-78.79777',
    timeZoneId: 'America/New_York',
  },
  '14221': {
    zip: '14221',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.980183',
    longitude: '-78.734894',
    timeZoneId: 'America/New_York',
  },
  '14222': {
    zip: '14222',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.919299',
    longitude: '-78.876334',
    timeZoneId: 'America/New_York',
  },
  '14223': {
    zip: '14223',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.97422',
    longitude: '-78.85626',
    timeZoneId: 'America/New_York',
  },
  '14224': {
    zip: '14224',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.841518',
    longitude: '-78.761265',
    timeZoneId: 'America/New_York',
  },
  '14225': {
    zip: '14225',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.9273',
    longitude: '-78.74748',
    timeZoneId: 'America/New_York',
  },
  '14226': {
    zip: '14226',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.969504',
    longitude: '-78.798576',
    timeZoneId: 'America/New_York',
  },
  '14227': {
    zip: '14227',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.876735',
    longitude: '-78.744457',
    timeZoneId: 'America/New_York',
  },
  '14228': {
    zip: '14228',
    city: 'Buffalo',
    state: 'NY',
    latitude: '43.02588',
    longitude: '-78.79536',
    timeZoneId: 'America/New_York',
  },
  '14231': {
    zip: '14231',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.98039',
    longitude: '-78.737853',
    timeZoneId: 'America/New_York',
  },
  '14233': {
    zip: '14233',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.875099',
    longitude: '-78.869022',
    timeZoneId: 'America/New_York',
  },
  '14240': {
    zip: '14240',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.87884',
    longitude: '-78.80962',
    timeZoneId: 'America/New_York',
  },
  '14241': {
    zip: '14241',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.927923',
    longitude: '-78.74765',
    timeZoneId: 'America/New_York',
  },
  '14260': {
    zip: '14260',
    city: 'Buffalo',
    state: 'NY',
    latitude: '43.003074',
    longitude: '-78.785924',
    timeZoneId: 'America/New_York',
  },
  '14261': {
    zip: '14261',
    city: 'Buffalo',
    state: 'NY',
    latitude: '43.00245',
    longitude: '-78.781965',
    timeZoneId: 'America/New_York',
  },
  '14263': {
    zip: '14263',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.873378',
    longitude: '-78.869243',
    timeZoneId: 'America/New_York',
  },
  '14264': {
    zip: '14264',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.887206',
    longitude: '-78.823444',
    timeZoneId: 'America/New_York',
  },
  '14265': {
    zip: '14265',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.978557',
    longitude: '-78.750032',
    timeZoneId: 'America/New_York',
  },
  '14267': {
    zip: '14267',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.916696',
    longitude: '-78.877078',
    timeZoneId: 'America/New_York',
  },
  '14269': {
    zip: '14269',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.86514',
    longitude: '-78.869492',
    timeZoneId: 'America/New_York',
  },
  '14270': {
    zip: '14270',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.87',
    longitude: '-78.832208',
    timeZoneId: 'America/New_York',
  },
  '14272': {
    zip: '14272',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.872268',
    longitude: '-78.870254',
    timeZoneId: 'America/New_York',
  },
  '14273': {
    zip: '14273',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.870716',
    longitude: '-78.869699',
    timeZoneId: 'America/New_York',
  },
  '14276': {
    zip: '14276',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.88568',
    longitude: '-78.87116',
    timeZoneId: 'America/New_York',
  },
  '14280': {
    zip: '14280',
    city: 'Buffalo',
    state: 'NY',
    latitude: '42.886929',
    longitude: '-78.871237',
    timeZoneId: 'America/New_York',
  },
  '14301': {
    zip: '14301',
    city: 'Niagara Falls',
    state: 'NY',
    latitude: '43.093678',
    longitude: '-79.041792',
    timeZoneId: 'America/New_York',
  },
  '14302': {
    zip: '14302',
    city: 'Niagara Falls',
    state: 'NY',
    latitude: '43.087809',
    longitude: '-79.061883',
    timeZoneId: 'America/New_York',
  },
  '14303': {
    zip: '14303',
    city: 'Niagara Falls',
    state: 'NY',
    latitude: '43.087115',
    longitude: '-79.037731',
    timeZoneId: 'America/New_York',
  },
  '14304': {
    zip: '14304',
    city: 'Niagara Falls',
    state: 'NY',
    latitude: '43.10016',
    longitude: '-78.9552',
    timeZoneId: 'America/New_York',
  },
  '14305': {
    zip: '14305',
    city: 'Niagara Falls',
    state: 'NY',
    latitude: '43.117629',
    longitude: '-79.02919',
    timeZoneId: 'America/New_York',
  },
  '14410': {
    zip: '14410',
    city: 'Adams Basin',
    state: 'NY',
    latitude: '43.192205',
    longitude: '-77.855545',
    timeZoneId: 'America/New_York',
  },
  '14411': {
    zip: '14411',
    city: 'Albion',
    state: 'NY',
    latitude: '43.239907',
    longitude: '-78.202239',
    timeZoneId: 'America/New_York',
  },
  '14413': {
    zip: '14413',
    city: 'Alton',
    state: 'NY',
    latitude: '43.208705',
    longitude: '-76.983214',
    timeZoneId: 'America/New_York',
  },
  '14414': {
    zip: '14414',
    city: 'Avon',
    state: 'NY',
    latitude: '42.897668',
    longitude: '-77.736093',
    timeZoneId: 'America/New_York',
  },
  '14415': {
    zip: '14415',
    city: 'Bellona',
    state: 'NY',
    latitude: '42.756258',
    longitude: '-77.019723',
    timeZoneId: 'America/New_York',
  },
  '14416': {
    zip: '14416',
    city: 'Bergen',
    state: 'NY',
    latitude: '43.07373',
    longitude: '-77.985432',
    timeZoneId: 'America/New_York',
  },
  '14418': {
    zip: '14418',
    city: 'Branchport',
    state: 'NY',
    latitude: '42.613768',
    longitude: '-77.211323',
    timeZoneId: 'America/New_York',
  },
  '14420': {
    zip: '14420',
    city: 'Brockport',
    state: 'NY',
    latitude: '43.214548',
    longitude: '-77.929502',
    timeZoneId: 'America/New_York',
  },
  '14422': {
    zip: '14422',
    city: 'Byron',
    state: 'NY',
    latitude: '43.07615',
    longitude: '-78.071607',
    timeZoneId: 'America/New_York',
  },
  '14423': {
    zip: '14423',
    city: 'Caledonia',
    state: 'NY',
    latitude: '42.941518',
    longitude: '-77.843078',
    timeZoneId: 'America/New_York',
  },
  '14424': {
    zip: '14424',
    city: 'Canandaigua',
    state: 'NY',
    latitude: '42.843523',
    longitude: '-77.301225',
    timeZoneId: 'America/New_York',
  },
  '14425': {
    zip: '14425',
    city: 'Farmington',
    state: 'NY',
    latitude: '42.95838',
    longitude: '-77.31024',
    timeZoneId: 'America/New_York',
  },
  '14427': {
    zip: '14427',
    city: 'Castile',
    state: 'NY',
    latitude: '42.642063',
    longitude: '-78.046828',
    timeZoneId: 'America/New_York',
  },
  '14428': {
    zip: '14428',
    city: 'Churchville',
    state: 'NY',
    latitude: '43.052222',
    longitude: '-77.815',
    timeZoneId: 'America/New_York',
  },
  '14429': {
    zip: '14429',
    city: 'Clarendon',
    state: 'NY',
    latitude: '43.192479',
    longitude: '-78.063625',
    timeZoneId: 'America/New_York',
  },
  '14430': {
    zip: '14430',
    city: 'Clarkson',
    state: 'NY',
    latitude: '43.235446',
    longitude: '-77.925235',
    timeZoneId: 'America/New_York',
  },
  '14432': {
    zip: '14432',
    city: 'Clifton Springs',
    state: 'NY',
    latitude: '42.963132',
    longitude: '-77.147698',
    timeZoneId: 'America/New_York',
  },
  '14433': {
    zip: '14433',
    city: 'Clyde',
    state: 'NY',
    latitude: '43.08127',
    longitude: '-76.869374',
    timeZoneId: 'America/New_York',
  },
  '14435': {
    zip: '14435',
    city: 'Conesus',
    state: 'NY',
    latitude: '42.724523',
    longitude: '-77.677278',
    timeZoneId: 'America/New_York',
  },
  '14437': {
    zip: '14437',
    city: 'Dansville',
    state: 'NY',
    latitude: '42.572707',
    longitude: '-77.731377',
    timeZoneId: 'America/New_York',
  },
  '14441': {
    zip: '14441',
    city: 'Dresden',
    state: 'NY',
    latitude: '42.688633',
    longitude: '-76.955886',
    timeZoneId: 'America/New_York',
  },
  '14443': {
    zip: '14443',
    city: 'East Bloomfield',
    state: 'NY',
    latitude: '42.890395',
    longitude: '-77.446504',
    timeZoneId: 'America/New_York',
  },
  '14445': {
    zip: '14445',
    city: 'East Rochester',
    state: 'NY',
    latitude: '43.11255',
    longitude: '-77.489182',
    timeZoneId: 'America/New_York',
  },
  '14449': {
    zip: '14449',
    city: 'East Williamson',
    state: 'NY',
    latitude: '43.232738',
    longitude: '-77.150121',
    timeZoneId: 'America/New_York',
  },
  '14450': {
    zip: '14450',
    city: 'Fairport',
    state: 'NY',
    latitude: '43.087492',
    longitude: '-77.432058',
    timeZoneId: 'America/New_York',
  },
  '14452': {
    zip: '14452',
    city: 'Fancher',
    state: 'NY',
    latitude: '43.243195',
    longitude: '-78.087864',
    timeZoneId: 'America/New_York',
  },
  '14453': {
    zip: '14453',
    city: 'Fishers',
    state: 'NY',
    latitude: '43.012541',
    longitude: '-77.462743',
    timeZoneId: 'America/New_York',
  },
  '14454': {
    zip: '14454',
    city: 'Geneseo',
    state: 'NY',
    latitude: '42.788901',
    longitude: '-77.771652',
    timeZoneId: 'America/New_York',
  },
  '14456': {
    zip: '14456',
    city: 'Geneva',
    state: 'NY',
    latitude: '42.862982',
    longitude: '-76.997939',
    timeZoneId: 'America/New_York',
  },
  '14461': {
    zip: '14461',
    city: 'Gorham',
    state: 'NY',
    latitude: '42.801791',
    longitude: '-77.130502',
    timeZoneId: 'America/New_York',
  },
  '14462': {
    zip: '14462',
    city: 'Groveland',
    state: 'NY',
    latitude: '42.680605',
    longitude: '-77.749793',
    timeZoneId: 'America/New_York',
  },
  '14463': {
    zip: '14463',
    city: 'Hall',
    state: 'NY',
    latitude: '42.797345',
    longitude: '-77.069035',
    timeZoneId: 'America/New_York',
  },
  '14464': {
    zip: '14464',
    city: 'Hamlin',
    state: 'NY',
    latitude: '43.316716',
    longitude: '-77.921667',
    timeZoneId: 'America/New_York',
  },
  '14466': {
    zip: '14466',
    city: 'Hemlock',
    state: 'NY',
    latitude: '42.77155',
    longitude: '-77.559436',
    timeZoneId: 'America/New_York',
  },
  '14467': {
    zip: '14467',
    city: 'Henrietta',
    state: 'NY',
    latitude: '43.053743',
    longitude: '-77.609162',
    timeZoneId: 'America/New_York',
  },
  '14468': {
    zip: '14468',
    city: 'Hilton',
    state: 'NY',
    latitude: '43.295049',
    longitude: '-77.789588',
    timeZoneId: 'America/New_York',
  },
  '14469': {
    zip: '14469',
    city: 'Bloomfield',
    state: 'NY',
    latitude: '42.877952',
    longitude: '-77.456375',
    timeZoneId: 'America/New_York',
  },
  '14470': {
    zip: '14470',
    city: 'Holley',
    state: 'NY',
    latitude: '43.253056',
    longitude: '-78.066111',
    timeZoneId: 'America/New_York',
  },
  '14471': {
    zip: '14471',
    city: 'Honeoye',
    state: 'NY',
    latitude: '42.761985',
    longitude: '-77.496841',
    timeZoneId: 'America/New_York',
  },
  '14472': {
    zip: '14472',
    city: 'Honeoye Falls',
    state: 'NY',
    latitude: '42.970369',
    longitude: '-77.596338',
    timeZoneId: 'America/New_York',
  },
  '14475': {
    zip: '14475',
    city: 'Ionia',
    state: 'NY',
    latitude: '42.934998',
    longitude: '-77.511407',
    timeZoneId: 'America/New_York',
  },
  '14476': {
    zip: '14476',
    city: 'Kendall',
    state: 'NY',
    latitude: '43.333198',
    longitude: '-78.04693',
    timeZoneId: 'America/New_York',
  },
  '14477': {
    zip: '14477',
    city: 'Kent',
    state: 'NY',
    latitude: '43.340537',
    longitude: '-78.14236',
    timeZoneId: 'America/New_York',
  },
  '14478': {
    zip: '14478',
    city: 'Keuka Park',
    state: 'NY',
    latitude: '42.587954',
    longitude: '-77.12449',
    timeZoneId: 'America/New_York',
  },
  '14479': {
    zip: '14479',
    city: 'Knowlesville',
    state: 'NY',
    latitude: '43.237153',
    longitude: '-78.309111',
    timeZoneId: 'America/New_York',
  },
  '14480': {
    zip: '14480',
    city: 'Lakeville',
    state: 'NY',
    latitude: '42.834968',
    longitude: '-77.704898',
    timeZoneId: 'America/New_York',
  },
  '14481': {
    zip: '14481',
    city: 'Leicester',
    state: 'NY',
    latitude: '42.777475',
    longitude: '-77.909721',
    timeZoneId: 'America/New_York',
  },
  '14482': {
    zip: '14482',
    city: 'Le Roy',
    state: 'NY',
    latitude: '42.96432',
    longitude: '-77.9808',
    timeZoneId: 'America/New_York',
  },
  '14485': {
    zip: '14485',
    city: 'Lima',
    state: 'NY',
    latitude: '42.89156',
    longitude: '-77.614458',
    timeZoneId: 'America/New_York',
  },
  '14486': {
    zip: '14486',
    city: 'Linwood',
    state: 'NY',
    latitude: '42.892704',
    longitude: '-77.920284',
    timeZoneId: 'America/New_York',
  },
  '14487': {
    zip: '14487',
    city: 'Livonia',
    state: 'NY',
    latitude: '42.811143',
    longitude: '-77.668155',
    timeZoneId: 'America/New_York',
  },
  '14488': {
    zip: '14488',
    city: 'Livonia Center',
    state: 'NY',
    latitude: '42.818813',
    longitude: '-77.64226',
    timeZoneId: 'America/New_York',
  },
  '14489': {
    zip: '14489',
    city: 'Lyons',
    state: 'NY',
    latitude: '43.087298',
    longitude: '-76.996974',
    timeZoneId: 'America/New_York',
  },
  '14502': {
    zip: '14502',
    city: 'Macedon',
    state: 'NY',
    latitude: '43.087022',
    longitude: '-77.333368',
    timeZoneId: 'America/New_York',
  },
  '14504': {
    zip: '14504',
    city: 'Manchester',
    state: 'NY',
    latitude: '42.969996',
    longitude: '-77.2299',
    timeZoneId: 'America/New_York',
  },
  '14505': {
    zip: '14505',
    city: 'Marion',
    state: 'NY',
    latitude: '43.152501',
    longitude: '-77.172245',
    timeZoneId: 'America/New_York',
  },
  '14506': {
    zip: '14506',
    city: 'Mendon',
    state: 'NY',
    latitude: '42.998096',
    longitude: '-77.499835',
    timeZoneId: 'America/New_York',
  },
  '14507': {
    zip: '14507',
    city: 'Middlesex',
    state: 'NY',
    latitude: '42.704465',
    longitude: '-77.268077',
    timeZoneId: 'America/New_York',
  },
  '14508': {
    zip: '14508',
    city: 'Morton',
    state: 'NY',
    latitude: '43.327041',
    longitude: '-77.999192',
    timeZoneId: 'America/New_York',
  },
  '14510': {
    zip: '14510',
    city: 'Mount Morris',
    state: 'NY',
    latitude: '42.68874',
    longitude: '-77.89518',
    timeZoneId: 'America/New_York',
  },
  '14511': {
    zip: '14511',
    city: 'Mumford',
    state: 'NY',
    latitude: '42.991585',
    longitude: '-77.860852',
    timeZoneId: 'America/New_York',
  },
  '14512': {
    zip: '14512',
    city: 'Naples',
    state: 'NY',
    latitude: '42.657539',
    longitude: '-77.413799',
    timeZoneId: 'America/New_York',
  },
  '14513': {
    zip: '14513',
    city: 'Newark',
    state: 'NY',
    latitude: '43.052762',
    longitude: '-77.096188',
    timeZoneId: 'America/New_York',
  },
  '14514': {
    zip: '14514',
    city: 'North Chili',
    state: 'NY',
    latitude: '43.114248',
    longitude: '-77.792506',
    timeZoneId: 'America/New_York',
  },
  '14515': {
    zip: '14515',
    city: 'North Greece',
    state: 'NY',
    latitude: '43.255445',
    longitude: '-77.72991',
    timeZoneId: 'America/New_York',
  },
  '14516': {
    zip: '14516',
    city: 'North Rose',
    state: 'NY',
    latitude: '43.193625',
    longitude: '-76.907679',
    timeZoneId: 'America/New_York',
  },
  '14517': {
    zip: '14517',
    city: 'Nunda',
    state: 'NY',
    latitude: '42.587711',
    longitude: '-77.911103',
    timeZoneId: 'America/New_York',
  },
  '14518': {
    zip: '14518',
    city: 'Oaks Corners',
    state: 'NY',
    latitude: '42.930174',
    longitude: '-77.012457',
    timeZoneId: 'America/New_York',
  },
  '14519': {
    zip: '14519',
    city: 'Ontario',
    state: 'NY',
    latitude: '43.235743',
    longitude: '-77.302362',
    timeZoneId: 'America/New_York',
  },
  '14520': {
    zip: '14520',
    city: 'Ontario Center',
    state: 'NY',
    latitude: '43.227431',
    longitude: '-77.306963',
    timeZoneId: 'America/New_York',
  },
  '14521': {
    zip: '14521',
    city: 'Ovid',
    state: 'NY',
    latitude: '42.67249',
    longitude: '-76.808623',
    timeZoneId: 'America/New_York',
  },
  '14522': {
    zip: '14522',
    city: 'Palmyra',
    state: 'NY',
    latitude: '43.068259',
    longitude: '-77.224663',
    timeZoneId: 'America/New_York',
  },
  '14525': {
    zip: '14525',
    city: 'Pavilion',
    state: 'NY',
    latitude: '42.898777',
    longitude: '-78.030271',
    timeZoneId: 'America/New_York',
  },
  '14526': {
    zip: '14526',
    city: 'Penfield',
    state: 'NY',
    latitude: '43.141702',
    longitude: '-77.455684',
    timeZoneId: 'America/New_York',
  },
  '14527': {
    zip: '14527',
    city: 'Penn Yan',
    state: 'NY',
    latitude: '42.658509',
    longitude: '-77.061185',
    timeZoneId: 'America/New_York',
  },
  '14529': {
    zip: '14529',
    city: 'Perkinsville',
    state: 'NY',
    latitude: '42.533783',
    longitude: '-77.626665',
    timeZoneId: 'America/New_York',
  },
  '14530': {
    zip: '14530',
    city: 'Perry',
    state: 'NY',
    latitude: '42.735814',
    longitude: '-78.015498',
    timeZoneId: 'America/New_York',
  },
  '14532': {
    zip: '14532',
    city: 'Phelps',
    state: 'NY',
    latitude: '42.967137',
    longitude: '-77.044133',
    timeZoneId: 'America/New_York',
  },
  '14533': {
    zip: '14533',
    city: 'Piffard',
    state: 'NY',
    latitude: '42.829598',
    longitude: '-77.897767',
    timeZoneId: 'America/New_York',
  },
  '14534': {
    zip: '14534',
    city: 'Pittsford',
    state: 'NY',
    latitude: '43.069507',
    longitude: '-77.514294',
    timeZoneId: 'America/New_York',
  },
  '14536': {
    zip: '14536',
    city: 'Portageville',
    state: 'NY',
    latitude: '42.550639',
    longitude: '-78.081712',
    timeZoneId: 'America/New_York',
  },
  '14537': {
    zip: '14537',
    city: 'Port Gibson',
    state: 'NY',
    latitude: '43.035448',
    longitude: '-77.158404',
    timeZoneId: 'America/New_York',
  },
  '14538': {
    zip: '14538',
    city: 'Pultneyville',
    state: 'NY',
    latitude: '43.278042',
    longitude: '-77.1722',
    timeZoneId: 'America/New_York',
  },
  '14539': {
    zip: '14539',
    city: 'Retsof',
    state: 'NY',
    latitude: '42.835036',
    longitude: '-77.880566',
    timeZoneId: 'America/New_York',
  },
  '14541': {
    zip: '14541',
    city: 'Romulus',
    state: 'NY',
    latitude: '42.750765',
    longitude: '-76.87068',
    timeZoneId: 'America/New_York',
  },
  '14542': {
    zip: '14542',
    city: 'Rose',
    state: 'NY',
    latitude: '43.156346',
    longitude: '-76.873817',
    timeZoneId: 'America/New_York',
  },
  '14543': {
    zip: '14543',
    city: 'Rush',
    state: 'NY',
    latitude: '42.994532',
    longitude: '-77.664004',
    timeZoneId: 'America/New_York',
  },
  '14544': {
    zip: '14544',
    city: 'Rushville',
    state: 'NY',
    latitude: '42.742889',
    longitude: '-77.245455',
    timeZoneId: 'America/New_York',
  },
  '14545': {
    zip: '14545',
    city: 'Scottsburg',
    state: 'NY',
    latitude: '42.663666',
    longitude: '-77.713631',
    timeZoneId: 'America/New_York',
  },
  '14546': {
    zip: '14546',
    city: 'Scottsville',
    state: 'NY',
    latitude: '43.029866',
    longitude: '-77.765882',
    timeZoneId: 'America/New_York',
  },
  '14547': {
    zip: '14547',
    city: 'Seneca Castle',
    state: 'NY',
    latitude: '42.904938',
    longitude: '-77.126529',
    timeZoneId: 'America/New_York',
  },
  '14548': {
    zip: '14548',
    city: 'Shortsville',
    state: 'NY',
    latitude: '42.96318',
    longitude: '-77.238668',
    timeZoneId: 'America/New_York',
  },
  '14549': {
    zip: '14549',
    city: 'Silver Lake',
    state: 'NY',
    latitude: '42.699111',
    longitude: '-78.02408',
    timeZoneId: 'America/New_York',
  },
  '14550': {
    zip: '14550',
    city: 'Silver Springs',
    state: 'NY',
    latitude: '42.67299',
    longitude: '-78.09439',
    timeZoneId: 'America/New_York',
  },
  '14551': {
    zip: '14551',
    city: 'Sodus',
    state: 'NY',
    latitude: '43.20012',
    longitude: '-77.0385',
    timeZoneId: 'America/New_York',
  },
  '14555': {
    zip: '14555',
    city: 'Sodus Point',
    state: 'NY',
    latitude: '43.266985',
    longitude: '-76.988411',
    timeZoneId: 'America/New_York',
  },
  '14556': {
    zip: '14556',
    city: 'Sonyea',
    state: 'NY',
    latitude: '42.679657',
    longitude: '-77.826587',
    timeZoneId: 'America/New_York',
  },
  '14557': {
    zip: '14557',
    city: 'South Byron',
    state: 'NY',
    latitude: '43.046795',
    longitude: '-78.066225',
    timeZoneId: 'America/New_York',
  },
  '14558': {
    zip: '14558',
    city: 'South Lima',
    state: 'NY',
    latitude: '42.853824',
    longitude: '-77.677651',
    timeZoneId: 'America/New_York',
  },
  '14559': {
    zip: '14559',
    city: 'Spencerport',
    state: 'NY',
    latitude: '43.189174',
    longitude: '-77.807274',
    timeZoneId: 'America/New_York',
  },
  '14560': {
    zip: '14560',
    city: 'Springwater',
    state: 'NY',
    latitude: '42.711095',
    longitude: '-77.553933',
    timeZoneId: 'America/New_York',
  },
  '14561': {
    zip: '14561',
    city: 'Stanley',
    state: 'NY',
    latitude: '42.823591',
    longitude: '-77.098747',
    timeZoneId: 'America/New_York',
  },
  '14563': {
    zip: '14563',
    city: 'Union Hill',
    state: 'NY',
    latitude: '43.223103',
    longitude: '-77.371355',
    timeZoneId: 'America/New_York',
  },
  '14564': {
    zip: '14564',
    city: 'Victor',
    state: 'NY',
    latitude: '42.979988',
    longitude: '-77.424394',
    timeZoneId: 'America/New_York',
  },
  '14568': {
    zip: '14568',
    city: 'Walworth',
    state: 'NY',
    latitude: '43.142109',
    longitude: '-77.287732',
    timeZoneId: 'America/New_York',
  },
  '14569': {
    zip: '14569',
    city: 'Warsaw',
    state: 'NY',
    latitude: '42.739675',
    longitude: '-78.16465',
    timeZoneId: 'America/New_York',
  },
  '14571': {
    zip: '14571',
    city: 'Waterport',
    state: 'NY',
    latitude: '43.347169',
    longitude: '-78.247079',
    timeZoneId: 'America/New_York',
  },
  '14572': {
    zip: '14572',
    city: 'Wayland',
    state: 'NY',
    latitude: '42.541439',
    longitude: '-77.588869',
    timeZoneId: 'America/New_York',
  },
  '14580': {
    zip: '14580',
    city: 'Webster',
    state: 'NY',
    latitude: '43.220645',
    longitude: '-77.464013',
    timeZoneId: 'America/New_York',
  },
  '14585': {
    zip: '14585',
    city: 'West Bloomfield',
    state: 'NY',
    latitude: '42.908597',
    longitude: '-77.552853',
    timeZoneId: 'America/New_York',
  },
  '14586': {
    zip: '14586',
    city: 'West Henrietta',
    state: 'NY',
    latitude: '43.043209',
    longitude: '-77.679662',
    timeZoneId: 'America/New_York',
  },
  '14588': {
    zip: '14588',
    city: 'Willard',
    state: 'NY',
    latitude: '42.681759',
    longitude: '-76.872085',
    timeZoneId: 'America/New_York',
  },
  '14589': {
    zip: '14589',
    city: 'Williamson',
    state: 'NY',
    latitude: '43.246564',
    longitude: '-77.18323',
    timeZoneId: 'America/New_York',
  },
  '14590': {
    zip: '14590',
    city: 'Wolcott',
    state: 'NY',
    latitude: '43.245974',
    longitude: '-76.832666',
    timeZoneId: 'America/New_York',
  },
  '14591': {
    zip: '14591',
    city: 'Wyoming',
    state: 'NY',
    latitude: '42.827666',
    longitude: '-78.08667',
    timeZoneId: 'America/New_York',
  },
  '14592': {
    zip: '14592',
    city: 'York',
    state: 'NY',
    latitude: '42.873962',
    longitude: '-77.884221',
    timeZoneId: 'America/New_York',
  },
  '14602': {
    zip: '14602',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.168234',
    longitude: '-77.601928',
    timeZoneId: 'America/New_York',
  },
  '14603': {
    zip: '14603',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.160031',
    longitude: '-77.605267',
    timeZoneId: 'America/New_York',
  },
  '14604': {
    zip: '14604',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.157946',
    longitude: '-77.604476',
    timeZoneId: 'America/New_York',
  },
  '14605': {
    zip: '14605',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.165247',
    longitude: '-77.600096',
    timeZoneId: 'America/New_York',
  },
  '14606': {
    zip: '14606',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.167126',
    longitude: '-77.687832',
    timeZoneId: 'America/New_York',
  },
  '14607': {
    zip: '14607',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.151853',
    longitude: '-77.593546',
    timeZoneId: 'America/New_York',
  },
  '14608': {
    zip: '14608',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.15275',
    longitude: '-77.625651',
    timeZoneId: 'America/New_York',
  },
  '14609': {
    zip: '14609',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.174537',
    longitude: '-77.559782',
    timeZoneId: 'America/New_York',
  },
  '14610': {
    zip: '14610',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.140249',
    longitude: '-77.545983',
    timeZoneId: 'America/New_York',
  },
  '14611': {
    zip: '14611',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.148324',
    longitude: '-77.643809',
    timeZoneId: 'America/New_York',
  },
  '14612': {
    zip: '14612',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.256923',
    longitude: '-77.669136',
    timeZoneId: 'America/New_York',
  },
  '14613': {
    zip: '14613',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.177983',
    longitude: '-77.63461',
    timeZoneId: 'America/New_York',
  },
  '14614': {
    zip: '14614',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.155018',
    longitude: '-77.609665',
    timeZoneId: 'America/New_York',
  },
  '14615': {
    zip: '14615',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.207456',
    longitude: '-77.649511',
    timeZoneId: 'America/New_York',
  },
  '14616': {
    zip: '14616',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.22904',
    longitude: '-77.68074',
    timeZoneId: 'America/New_York',
  },
  '14617': {
    zip: '14617',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.20642',
    longitude: '-77.63532',
    timeZoneId: 'America/New_York',
  },
  '14618': {
    zip: '14618',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.119768',
    longitude: '-77.556663',
    timeZoneId: 'America/New_York',
  },
  '14619': {
    zip: '14619',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.133202',
    longitude: '-77.64409',
    timeZoneId: 'America/New_York',
  },
  '14620': {
    zip: '14620',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.132792',
    longitude: '-77.600284',
    timeZoneId: 'America/New_York',
  },
  '14621': {
    zip: '14621',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.182834',
    longitude: '-77.602085',
    timeZoneId: 'America/New_York',
  },
  '14622': {
    zip: '14622',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.216211',
    longitude: '-77.562561',
    timeZoneId: 'America/New_York',
  },
  '14623': {
    zip: '14623',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.081673',
    longitude: '-77.633588',
    timeZoneId: 'America/New_York',
  },
  '14624': {
    zip: '14624',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.120619',
    longitude: '-77.729488',
    timeZoneId: 'America/New_York',
  },
  '14625': {
    zip: '14625',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.13424',
    longitude: '-77.4996',
    timeZoneId: 'America/New_York',
  },
  '14626': {
    zip: '14626',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.20974',
    longitude: '-77.703726',
    timeZoneId: 'America/New_York',
  },
  '14627': {
    zip: '14627',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.122454',
    longitude: '-77.669125',
    timeZoneId: 'America/New_York',
  },
  '14638': {
    zip: '14638',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.15714',
    longitude: '-77.606676',
    timeZoneId: 'America/New_York',
  },
  '14639': {
    zip: '14639',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.158018',
    longitude: '-77.605115',
    timeZoneId: 'America/New_York',
  },
  '14642': {
    zip: '14642',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.122242',
    longitude: '-77.625204',
    timeZoneId: 'America/New_York',
  },
  '14643': {
    zip: '14643',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.155367',
    longitude: '-77.607291',
    timeZoneId: 'America/New_York',
  },
  '14644': {
    zip: '14644',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.153845',
    longitude: '-77.603654',
    timeZoneId: 'America/New_York',
  },
  '14646': {
    zip: '14646',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.151509',
    longitude: '-77.605675',
    timeZoneId: 'America/New_York',
  },
  '14647': {
    zip: '14647',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.157199',
    longitude: '-77.608041',
    timeZoneId: 'America/New_York',
  },
  '14649': {
    zip: '14649',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.157109',
    longitude: '-77.604365',
    timeZoneId: 'America/New_York',
  },
  '14650': {
    zip: '14650',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.162172',
    longitude: '-77.621673',
    timeZoneId: 'America/New_York',
  },
  '14651': {
    zip: '14651',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.160651',
    longitude: '-77.619606',
    timeZoneId: 'America/New_York',
  },
  '14652': {
    zip: '14652',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.150617',
    longitude: '-77.728304',
    timeZoneId: 'America/New_York',
  },
  '14653': {
    zip: '14653',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.15213',
    longitude: '-77.623329',
    timeZoneId: 'America/New_York',
  },
  '14692': {
    zip: '14692',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.084621',
    longitude: '-77.598456',
    timeZoneId: 'America/New_York',
  },
  '14694': {
    zip: '14694',
    city: 'Rochester',
    state: 'NY',
    latitude: '43.088842',
    longitude: '-77.599683',
    timeZoneId: 'America/New_York',
  },
  '14701': {
    zip: '14701',
    city: 'Jamestown',
    state: 'NY',
    latitude: '42.094174',
    longitude: '-79.251081',
    timeZoneId: 'America/New_York',
  },
  '14702': {
    zip: '14702',
    city: 'Jamestown',
    state: 'NY',
    latitude: '42.096597',
    longitude: '-79.234978',
    timeZoneId: 'America/New_York',
  },
  '14706': {
    zip: '14706',
    city: 'Allegany',
    state: 'NY',
    latitude: '42.103002',
    longitude: '-78.493761',
    timeZoneId: 'America/New_York',
  },
  '14707': {
    zip: '14707',
    city: 'Allentown',
    state: 'NY',
    latitude: '42.090634',
    longitude: '-78.062163',
    timeZoneId: 'America/New_York',
  },
  '14708': {
    zip: '14708',
    city: 'Alma',
    state: 'NY',
    latitude: '42.013652',
    longitude: '-78.025114',
    timeZoneId: 'America/New_York',
  },
  '14709': {
    zip: '14709',
    city: 'Angelica',
    state: 'NY',
    latitude: '42.310729',
    longitude: '-78.024817',
    timeZoneId: 'America/New_York',
  },
  '14710': {
    zip: '14710',
    city: 'Ashville',
    state: 'NY',
    latitude: '42.078716',
    longitude: '-79.373737',
    timeZoneId: 'America/New_York',
  },
  '14711': {
    zip: '14711',
    city: 'Belfast',
    state: 'NY',
    latitude: '42.341095',
    longitude: '-78.119974',
    timeZoneId: 'America/New_York',
  },
  '14712': {
    zip: '14712',
    city: 'Bemus Point',
    state: 'NY',
    latitude: '42.152037',
    longitude: '-79.357012',
    timeZoneId: 'America/New_York',
  },
  '14714': {
    zip: '14714',
    city: 'Black Creek',
    state: 'NY',
    latitude: '42.285552',
    longitude: '-78.256921',
    timeZoneId: 'America/New_York',
  },
  '14715': {
    zip: '14715',
    city: 'Bolivar',
    state: 'NY',
    latitude: '42.075166',
    longitude: '-78.157392',
    timeZoneId: 'America/New_York',
  },
  '14716': {
    zip: '14716',
    city: 'Brocton',
    state: 'NY',
    latitude: '42.38762',
    longitude: '-79.43515',
    timeZoneId: 'America/New_York',
  },
  '14717': {
    zip: '14717',
    city: 'Caneadea',
    state: 'NY',
    latitude: '42.372527',
    longitude: '-78.210567',
    timeZoneId: 'America/New_York',
  },
  '14718': {
    zip: '14718',
    city: 'Cassadaga',
    state: 'NY',
    latitude: '42.348088',
    longitude: '-79.292917',
    timeZoneId: 'America/New_York',
  },
  '14719': {
    zip: '14719',
    city: 'Cattaraugus',
    state: 'NY',
    latitude: '42.329564',
    longitude: '-78.86951',
    timeZoneId: 'America/New_York',
  },
  '14720': {
    zip: '14720',
    city: 'Celoron',
    state: 'NY',
    latitude: '42.10848',
    longitude: '-79.28304',
    timeZoneId: 'America/New_York',
  },
  '14721': {
    zip: '14721',
    city: 'Ceres',
    state: 'NY',
    latitude: '42.011321',
    longitude: '-78.271295',
    timeZoneId: 'America/New_York',
  },
  '14722': {
    zip: '14722',
    city: 'Chautauqua',
    state: 'NY',
    latitude: '42.207332',
    longitude: '-79.466845',
    timeZoneId: 'America/New_York',
  },
  '14723': {
    zip: '14723',
    city: 'Cherry Creek',
    state: 'NY',
    latitude: '42.311195',
    longitude: '-79.141523',
    timeZoneId: 'America/New_York',
  },
  '14724': {
    zip: '14724',
    city: 'Clymer',
    state: 'NY',
    latitude: '42.052487',
    longitude: '-79.669917',
    timeZoneId: 'America/New_York',
  },
  '14726': {
    zip: '14726',
    city: 'Conewango Valley',
    state: 'NY',
    latitude: '42.238377',
    longitude: '-79.060848',
    timeZoneId: 'America/New_York',
  },
  '14727': {
    zip: '14727',
    city: 'Cuba',
    state: 'NY',
    latitude: '42.211225',
    longitude: '-78.268103',
    timeZoneId: 'America/New_York',
  },
  '14728': {
    zip: '14728',
    city: 'Dewittville',
    state: 'NY',
    latitude: '42.241482',
    longitude: '-79.401184',
    timeZoneId: 'America/New_York',
  },
  '14729': {
    zip: '14729',
    city: 'East Otto',
    state: 'NY',
    latitude: '42.409324',
    longitude: '-78.725794',
    timeZoneId: 'America/New_York',
  },
  '14730': {
    zip: '14730',
    city: 'East Randolph',
    state: 'NY',
    latitude: '42.17125',
    longitude: '-78.948137',
    timeZoneId: 'America/New_York',
  },
  '14731': {
    zip: '14731',
    city: 'Ellicottville',
    state: 'NY',
    latitude: '42.292555',
    longitude: '-78.658909',
    timeZoneId: 'America/New_York',
  },
  '14732': {
    zip: '14732',
    city: 'Ellington',
    state: 'NY',
    latitude: '42.219861',
    longitude: '-79.103575',
    timeZoneId: 'America/New_York',
  },
  '14733': {
    zip: '14733',
    city: 'Falconer',
    state: 'NY',
    latitude: '42.128136',
    longitude: '-79.190161',
    timeZoneId: 'America/New_York',
  },
  '14735': {
    zip: '14735',
    city: 'Fillmore',
    state: 'NY',
    latitude: '42.4533',
    longitude: '-78.087607',
    timeZoneId: 'America/New_York',
  },
  '14736': {
    zip: '14736',
    city: 'Findley Lake',
    state: 'NY',
    latitude: '42.107656',
    longitude: '-79.73234',
    timeZoneId: 'America/New_York',
  },
  '14737': {
    zip: '14737',
    city: 'Franklinville',
    state: 'NY',
    latitude: '42.333354',
    longitude: '-78.431625',
    timeZoneId: 'America/New_York',
  },
  '14738': {
    zip: '14738',
    city: 'Frewsburg',
    state: 'NY',
    latitude: '42.053607',
    longitude: '-79.134602',
    timeZoneId: 'America/New_York',
  },
  '14739': {
    zip: '14739',
    city: 'Friendship',
    state: 'NY',
    latitude: '42.203341',
    longitude: '-78.139046',
    timeZoneId: 'America/New_York',
  },
  '14740': {
    zip: '14740',
    city: 'Gerry',
    state: 'NY',
    latitude: '42.198621',
    longitude: '-79.226713',
    timeZoneId: 'America/New_York',
  },
  '14741': {
    zip: '14741',
    city: 'Great Valley',
    state: 'NY',
    latitude: '42.21258',
    longitude: '-78.5907',
    timeZoneId: 'America/New_York',
  },
  '14742': {
    zip: '14742',
    city: 'Greenhurst',
    state: 'NY',
    latitude: '42.120268',
    longitude: '-79.305125',
    timeZoneId: 'America/New_York',
  },
  '14743': {
    zip: '14743',
    city: 'Hinsdale',
    state: 'NY',
    latitude: '42.20112',
    longitude: '-78.4098',
    timeZoneId: 'America/New_York',
  },
  '14744': {
    zip: '14744',
    city: 'Houghton',
    state: 'NY',
    latitude: '42.416695',
    longitude: '-78.167853',
    timeZoneId: 'America/New_York',
  },
  '14745': {
    zip: '14745',
    city: 'Hume',
    state: 'NY',
    latitude: '42.470999',
    longitude: '-78.137723',
    timeZoneId: 'America/New_York',
  },
  '14747': {
    zip: '14747',
    city: 'Kennedy',
    state: 'NY',
    latitude: '42.155243',
    longitude: '-79.101242',
    timeZoneId: 'America/New_York',
  },
  '14748': {
    zip: '14748',
    city: 'Kill Buck',
    state: 'NY',
    latitude: '42.149646',
    longitude: '-78.639987',
    timeZoneId: 'America/New_York',
  },
  '14750': {
    zip: '14750',
    city: 'Lakewood',
    state: 'NY',
    latitude: '42.10072',
    longitude: '-79.322878',
    timeZoneId: 'America/New_York',
  },
  '14751': {
    zip: '14751',
    city: 'Leon',
    state: 'NY',
    latitude: '42.293682',
    longitude: '-79.017978',
    timeZoneId: 'America/New_York',
  },
  '14752': {
    zip: '14752',
    city: 'Lily Dale',
    state: 'NY',
    latitude: '42.350044',
    longitude: '-79.326995',
    timeZoneId: 'America/New_York',
  },
  '14753': {
    zip: '14753',
    city: 'Limestone',
    state: 'NY',
    latitude: '42.035199',
    longitude: '-78.640416',
    timeZoneId: 'America/New_York',
  },
  '14754': {
    zip: '14754',
    city: 'Little Genesee',
    state: 'NY',
    latitude: '42.024649',
    longitude: '-78.18395',
    timeZoneId: 'America/New_York',
  },
  '14755': {
    zip: '14755',
    city: 'Little Valley',
    state: 'NY',
    latitude: '42.248809',
    longitude: '-78.800866',
    timeZoneId: 'America/New_York',
  },
  '14756': {
    zip: '14756',
    city: 'Maple Springs',
    state: 'NY',
    latitude: '42.196953',
    longitude: '-79.424756',
    timeZoneId: 'America/New_York',
  },
  '14757': {
    zip: '14757',
    city: 'Mayville',
    state: 'NY',
    latitude: '42.250678',
    longitude: '-79.500191',
    timeZoneId: 'America/New_York',
  },
  '14758': {
    zip: '14758',
    city: 'Niobe',
    state: 'NY',
    latitude: '42.011416',
    longitude: '-79.450406',
    timeZoneId: 'America/New_York',
  },
  '14760': {
    zip: '14760',
    city: 'Olean',
    state: 'NY',
    latitude: '42.081747',
    longitude: '-78.426015',
    timeZoneId: 'America/New_York',
  },
  '14766': {
    zip: '14766',
    city: 'Otto',
    state: 'NY',
    latitude: '42.349305',
    longitude: '-78.828802',
    timeZoneId: 'America/New_York',
  },
  '14767': {
    zip: '14767',
    city: 'Panama',
    state: 'NY',
    latitude: '42.063643',
    longitude: '-79.499075',
    timeZoneId: 'America/New_York',
  },
  '14769': {
    zip: '14769',
    city: 'Portland',
    state: 'NY',
    latitude: '42.377963',
    longitude: '-79.471958',
    timeZoneId: 'America/New_York',
  },
  '14770': {
    zip: '14770',
    city: 'Portville',
    state: 'NY',
    latitude: '42.037594',
    longitude: '-78.334611',
    timeZoneId: 'America/New_York',
  },
  '14772': {
    zip: '14772',
    city: 'Randolph',
    state: 'NY',
    latitude: '42.146804',
    longitude: '-78.981588',
    timeZoneId: 'America/New_York',
  },
  '14774': {
    zip: '14774',
    city: 'Richburg',
    state: 'NY',
    latitude: '42.089324',
    longitude: '-78.155021',
    timeZoneId: 'America/New_York',
  },
  '14775': {
    zip: '14775',
    city: 'Ripley',
    state: 'NY',
    latitude: '42.26458',
    longitude: '-79.707146',
    timeZoneId: 'America/New_York',
  },
  '14777': {
    zip: '14777',
    city: 'Rushford',
    state: 'NY',
    latitude: '42.387916',
    longitude: '-78.242115',
    timeZoneId: 'America/New_York',
  },
  '14778': {
    zip: '14778',
    city: 'Saint Bonaventure',
    state: 'NY',
    latitude: '42.080151',
    longitude: '-78.474616',
    timeZoneId: 'America/New_York',
  },
  '14779': {
    zip: '14779',
    city: 'Salamanca',
    state: 'NY',
    latitude: '42.210897',
    longitude: '-78.746912',
    timeZoneId: 'America/New_York',
  },
  '14781': {
    zip: '14781',
    city: 'Sherman',
    state: 'NY',
    latitude: '42.158808',
    longitude: '-79.597595',
    timeZoneId: 'America/New_York',
  },
  '14782': {
    zip: '14782',
    city: 'Sinclairville',
    state: 'NY',
    latitude: '42.275424',
    longitude: '-79.244039',
    timeZoneId: 'America/New_York',
  },
  '14783': {
    zip: '14783',
    city: 'Steamburg',
    state: 'NY',
    latitude: '42.106997',
    longitude: '-78.904621',
    timeZoneId: 'America/New_York',
  },
  '14784': {
    zip: '14784',
    city: 'Stockton',
    state: 'NY',
    latitude: '42.325907',
    longitude: '-79.365843',
    timeZoneId: 'America/New_York',
  },
  '14785': {
    zip: '14785',
    city: 'Stow',
    state: 'NY',
    latitude: '42.157319',
    longitude: '-79.404216',
    timeZoneId: 'America/New_York',
  },
  '14786': {
    zip: '14786',
    city: 'West Clarksville',
    state: 'NY',
    latitude: '42.125215',
    longitude: '-78.229883',
    timeZoneId: 'America/New_York',
  },
  '14787': {
    zip: '14787',
    city: 'Westfield',
    state: 'NY',
    latitude: '42.324371',
    longitude: '-79.570661',
    timeZoneId: 'America/New_York',
  },
  '14788': {
    zip: '14788',
    city: 'Westons Mills',
    state: 'NY',
    latitude: '42.060494',
    longitude: '-78.380393',
    timeZoneId: 'America/New_York',
  },
  '14801': {
    zip: '14801',
    city: 'Addison',
    state: 'NY',
    latitude: '42.107463',
    longitude: '-77.230985',
    timeZoneId: 'America/New_York',
  },
  '14802': {
    zip: '14802',
    city: 'Alfred',
    state: 'NY',
    latitude: '42.255151',
    longitude: '-77.785384',
    timeZoneId: 'America/New_York',
  },
  '14803': {
    zip: '14803',
    city: 'Alfred Station',
    state: 'NY',
    latitude: '42.256785',
    longitude: '-77.788411',
    timeZoneId: 'America/New_York',
  },
  '14804': {
    zip: '14804',
    city: 'Almond',
    state: 'NY',
    latitude: '42.327793',
    longitude: '-77.824559',
    timeZoneId: 'America/New_York',
  },
  '14805': {
    zip: '14805',
    city: 'Alpine',
    state: 'NY',
    latitude: '42.362451',
    longitude: '-76.731395',
    timeZoneId: 'America/New_York',
  },
  '14806': {
    zip: '14806',
    city: 'Andover',
    state: 'NY',
    latitude: '42.155375',
    longitude: '-77.804776',
    timeZoneId: 'America/New_York',
  },
  '14807': {
    zip: '14807',
    city: 'Arkport',
    state: 'NY',
    latitude: '42.427575',
    longitude: '-77.668725',
    timeZoneId: 'America/New_York',
  },
  '14808': {
    zip: '14808',
    city: 'Atlanta',
    state: 'NY',
    latitude: '42.552703',
    longitude: '-77.480345',
    timeZoneId: 'America/New_York',
  },
  '14809': {
    zip: '14809',
    city: 'Avoca',
    state: 'NY',
    latitude: '42.41712',
    longitude: '-77.45664',
    timeZoneId: 'America/New_York',
  },
  '14810': {
    zip: '14810',
    city: 'Bath',
    state: 'NY',
    latitude: '42.34203',
    longitude: '-77.318938',
    timeZoneId: 'America/New_York',
  },
  '14812': {
    zip: '14812',
    city: 'Beaver Dams',
    state: 'NY',
    latitude: '42.307691',
    longitude: '-76.988302',
    timeZoneId: 'America/New_York',
  },
  '14813': {
    zip: '14813',
    city: 'Belmont',
    state: 'NY',
    latitude: '42.230607',
    longitude: '-78.017742',
    timeZoneId: 'America/New_York',
  },
  '14814': {
    zip: '14814',
    city: 'Big Flats',
    state: 'NY',
    latitude: '42.145843',
    longitude: '-76.947025',
    timeZoneId: 'America/New_York',
  },
  '14815': {
    zip: '14815',
    city: 'Bradford',
    state: 'NY',
    latitude: '42.390147',
    longitude: '-77.083086',
    timeZoneId: 'America/New_York',
  },
  '14816': {
    zip: '14816',
    city: 'Breesport',
    state: 'NY',
    latitude: '42.172988',
    longitude: '-76.730149',
    timeZoneId: 'America/New_York',
  },
  '14817': {
    zip: '14817',
    city: 'Brooktondale',
    state: 'NY',
    latitude: '42.370141',
    longitude: '-76.365201',
    timeZoneId: 'America/New_York',
  },
  '14818': {
    zip: '14818',
    city: 'Burdett',
    state: 'NY',
    latitude: '42.437057',
    longitude: '-76.828291',
    timeZoneId: 'America/New_York',
  },
  '14819': {
    zip: '14819',
    city: 'Cameron',
    state: 'NY',
    latitude: '42.201213',
    longitude: '-77.462912',
    timeZoneId: 'America/New_York',
  },
  '14820': {
    zip: '14820',
    city: 'Cameron Mills',
    state: 'NY',
    latitude: '42.233846',
    longitude: '-77.337118',
    timeZoneId: 'America/New_York',
  },
  '14821': {
    zip: '14821',
    city: 'Campbell',
    state: 'NY',
    latitude: '42.243643',
    longitude: '-77.195401',
    timeZoneId: 'America/New_York',
  },
  '14822': {
    zip: '14822',
    city: 'Canaseraga',
    state: 'NY',
    latitude: '42.453413',
    longitude: '-77.787943',
    timeZoneId: 'America/New_York',
  },
  '14823': {
    zip: '14823',
    city: 'Canisteo',
    state: 'NY',
    latitude: '42.25975',
    longitude: '-77.584644',
    timeZoneId: 'America/New_York',
  },
  '14824': {
    zip: '14824',
    city: 'Cayuta',
    state: 'NY',
    latitude: '42.276635',
    longitude: '-76.70193',
    timeZoneId: 'America/New_York',
  },
  '14825': {
    zip: '14825',
    city: 'Chemung',
    state: 'NY',
    latitude: '42.046766',
    longitude: '-76.61945',
    timeZoneId: 'America/New_York',
  },
  '14826': {
    zip: '14826',
    city: 'Cohocton',
    state: 'NY',
    latitude: '42.498624',
    longitude: '-77.510139',
    timeZoneId: 'America/New_York',
  },
  '14827': {
    zip: '14827',
    city: 'Coopers Plains',
    state: 'NY',
    latitude: '42.178306',
    longitude: '-77.14086',
    timeZoneId: 'America/New_York',
  },
  '14830': {
    zip: '14830',
    city: 'Corning',
    state: 'NY',
    latitude: '42.14317',
    longitude: '-77.052149',
    timeZoneId: 'America/New_York',
  },
  '14831': {
    zip: '14831',
    city: 'Corning',
    state: 'NY',
    latitude: '42.147148',
    longitude: '-77.056466',
    timeZoneId: 'America/New_York',
  },
  '14836': {
    zip: '14836',
    city: 'Dalton',
    state: 'NY',
    latitude: '42.543587',
    longitude: '-77.919905',
    timeZoneId: 'America/New_York',
  },
  '14837': {
    zip: '14837',
    city: 'Dundee',
    state: 'NY',
    latitude: '42.525317',
    longitude: '-76.973125',
    timeZoneId: 'America/New_York',
  },
  '14838': {
    zip: '14838',
    city: 'Erin',
    state: 'NY',
    latitude: '42.19726',
    longitude: '-76.684904',
    timeZoneId: 'America/New_York',
  },
  '14839': {
    zip: '14839',
    city: 'Greenwood',
    state: 'NY',
    latitude: '42.144281',
    longitude: '-77.644114',
    timeZoneId: 'America/New_York',
  },
  '14840': {
    zip: '14840',
    city: 'Hammondsport',
    state: 'NY',
    latitude: '42.450089',
    longitude: '-77.184976',
    timeZoneId: 'America/New_York',
  },
  '14841': {
    zip: '14841',
    city: 'Hector',
    state: 'NY',
    latitude: '42.498593',
    longitude: '-76.873441',
    timeZoneId: 'America/New_York',
  },
  '14842': {
    zip: '14842',
    city: 'Himrod',
    state: 'NY',
    latitude: '42.592474',
    longitude: '-76.947431',
    timeZoneId: 'America/New_York',
  },
  '14843': {
    zip: '14843',
    city: 'Hornell',
    state: 'NY',
    latitude: '42.328714',
    longitude: '-77.656438',
    timeZoneId: 'America/New_York',
  },
  '14845': {
    zip: '14845',
    city: 'Horseheads',
    state: 'NY',
    latitude: '42.180601',
    longitude: '-76.841295',
    timeZoneId: 'America/New_York',
  },
  '14846': {
    zip: '14846',
    city: 'Hunt',
    state: 'NY',
    latitude: '42.558498',
    longitude: '-78.003025',
    timeZoneId: 'America/New_York',
  },
  '14847': {
    zip: '14847',
    city: 'Interlaken',
    state: 'NY',
    latitude: '42.614405',
    longitude: '-76.729358',
    timeZoneId: 'America/New_York',
  },
  '14850': {
    zip: '14850',
    city: 'Ithaca',
    state: 'NY',
    latitude: '42.445573',
    longitude: '-76.496428',
    timeZoneId: 'America/New_York',
  },
  '14851': {
    zip: '14851',
    city: 'Ithaca',
    state: 'NY',
    latitude: '42.447569',
    longitude: '-76.503192',
    timeZoneId: 'America/New_York',
  },
  '14852': {
    zip: '14852',
    city: 'Ithaca',
    state: 'NY',
    latitude: '42.507018',
    longitude: '-76.471133',
    timeZoneId: 'America/New_York',
  },
  '14853': {
    zip: '14853',
    city: 'Ithaca',
    state: 'NY',
    latitude: '42.439636',
    longitude: '-76.487351',
    timeZoneId: 'America/New_York',
  },
  '14854': {
    zip: '14854',
    city: 'Jacksonville',
    state: 'NY',
    latitude: '42.502545',
    longitude: '-76.610456',
    timeZoneId: 'America/New_York',
  },
  '14855': {
    zip: '14855',
    city: 'Jasper',
    state: 'NY',
    latitude: '42.103259',
    longitude: '-77.528682',
    timeZoneId: 'America/New_York',
  },
  '14856': {
    zip: '14856',
    city: 'Kanona',
    state: 'NY',
    latitude: '42.334564',
    longitude: '-77.320273',
    timeZoneId: 'America/New_York',
  },
  '14857': {
    zip: '14857',
    city: 'Lakemont',
    state: 'NY',
    latitude: '42.518741',
    longitude: '-76.928255',
    timeZoneId: 'America/New_York',
  },
  '14858': {
    zip: '14858',
    city: 'Lindley',
    state: 'NY',
    latitude: '42.059605',
    longitude: '-77.146474',
    timeZoneId: 'America/New_York',
  },
  '14859': {
    zip: '14859',
    city: 'Lockwood',
    state: 'NY',
    latitude: '42.090387',
    longitude: '-76.579355',
    timeZoneId: 'America/New_York',
  },
  '14860': {
    zip: '14860',
    city: 'Lodi',
    state: 'NY',
    latitude: '42.603278',
    longitude: '-76.842228',
    timeZoneId: 'America/New_York',
  },
  '14861': {
    zip: '14861',
    city: 'Lowman',
    state: 'NY',
    latitude: '42.136889',
    longitude: '-76.727834',
    timeZoneId: 'America/New_York',
  },
  '14863': {
    zip: '14863',
    city: 'Mecklenburg',
    state: 'NY',
    latitude: '42.455504',
    longitude: '-76.711047',
    timeZoneId: 'America/New_York',
  },
  '14864': {
    zip: '14864',
    city: 'Millport',
    state: 'NY',
    latitude: '42.2671',
    longitude: '-76.840236',
    timeZoneId: 'America/New_York',
  },
  '14865': {
    zip: '14865',
    city: 'Montour Falls',
    state: 'NY',
    latitude: '42.34496',
    longitude: '-76.837674',
    timeZoneId: 'America/New_York',
  },
  '14867': {
    zip: '14867',
    city: 'Newfield',
    state: 'NY',
    latitude: '42.359059',
    longitude: '-76.599327',
    timeZoneId: 'America/New_York',
  },
  '14869': {
    zip: '14869',
    city: 'Odessa',
    state: 'NY',
    latitude: '42.350105',
    longitude: '-76.776852',
    timeZoneId: 'America/New_York',
  },
  '14870': {
    zip: '14870',
    city: 'Painted Post',
    state: 'NY',
    latitude: '42.155826',
    longitude: '-77.115626',
    timeZoneId: 'America/New_York',
  },
  '14871': {
    zip: '14871',
    city: 'Pine City',
    state: 'NY',
    latitude: '42.050672',
    longitude: '-76.866098',
    timeZoneId: 'America/New_York',
  },
  '14872': {
    zip: '14872',
    city: 'Pine Valley',
    state: 'NY',
    latitude: '42.227341',
    longitude: '-76.852554',
    timeZoneId: 'America/New_York',
  },
  '14873': {
    zip: '14873',
    city: 'Prattsburgh',
    state: 'NY',
    latitude: '42.524871',
    longitude: '-77.249474',
    timeZoneId: 'America/New_York',
  },
  '14874': {
    zip: '14874',
    city: 'Pulteney',
    state: 'NY',
    latitude: '42.531331',
    longitude: '-77.171206',
    timeZoneId: 'America/New_York',
  },
  '14876': {
    zip: '14876',
    city: 'Reading Center',
    state: 'NY',
    latitude: '42.433722',
    longitude: '-76.950153',
    timeZoneId: 'America/New_York',
  },
  '14877': {
    zip: '14877',
    city: 'Rexville',
    state: 'NY',
    latitude: '42.075702',
    longitude: '-77.667295',
    timeZoneId: 'America/New_York',
  },
  '14878': {
    zip: '14878',
    city: 'Rock Stream',
    state: 'NY',
    latitude: '42.469548',
    longitude: '-76.930728',
    timeZoneId: 'America/New_York',
  },
  '14879': {
    zip: '14879',
    city: 'Savona',
    state: 'NY',
    latitude: '42.307167',
    longitude: '-77.182785',
    timeZoneId: 'America/New_York',
  },
  '14880': {
    zip: '14880',
    city: 'Scio',
    state: 'NY',
    latitude: '42.166475',
    longitude: '-77.984285',
    timeZoneId: 'America/New_York',
  },
  '14881': {
    zip: '14881',
    city: 'Slaterville Springs',
    state: 'NY',
    latitude: '42.40152',
    longitude: '-76.32228',
    timeZoneId: 'America/New_York',
  },
  '14882': {
    zip: '14882',
    city: 'Lansing',
    state: 'NY',
    latitude: '42.56316',
    longitude: '-76.55484',
    timeZoneId: 'America/New_York',
  },
  '14883': {
    zip: '14883',
    city: 'Spencer',
    state: 'NY',
    latitude: '42.25146',
    longitude: '-76.4808',
    timeZoneId: 'America/New_York',
  },
  '14884': {
    zip: '14884',
    city: 'Swain',
    state: 'NY',
    latitude: '42.454043',
    longitude: '-77.895389',
    timeZoneId: 'America/New_York',
  },
  '14885': {
    zip: '14885',
    city: 'Troupsburg',
    state: 'NY',
    latitude: '42.066319',
    longitude: '-77.564807',
    timeZoneId: 'America/New_York',
  },
  '14886': {
    zip: '14886',
    city: 'Trumansburg',
    state: 'NY',
    latitude: '42.521119',
    longitude: '-76.643605',
    timeZoneId: 'America/New_York',
  },
  '14887': {
    zip: '14887',
    city: 'Tyrone',
    state: 'NY',
    latitude: '42.402468',
    longitude: '-77.069893',
    timeZoneId: 'America/New_York',
  },
  '14889': {
    zip: '14889',
    city: 'Van Etten',
    state: 'NY',
    latitude: '42.215462',
    longitude: '-76.579551',
    timeZoneId: 'America/New_York',
  },
  '14891': {
    zip: '14891',
    city: 'Watkins Glen',
    state: 'NY',
    latitude: '42.372716',
    longitude: '-76.913044',
    timeZoneId: 'America/New_York',
  },
  '14892': {
    zip: '14892',
    city: 'Waverly',
    state: 'NY',
    latitude: '42.015892',
    longitude: '-76.533539',
    timeZoneId: 'America/New_York',
  },
  '14893': {
    zip: '14893',
    city: 'Wayne',
    state: 'NY',
    latitude: '42.466821',
    longitude: '-77.111453',
    timeZoneId: 'America/New_York',
  },
  '14894': {
    zip: '14894',
    city: 'Wellsburg',
    state: 'NY',
    latitude: '42.010247',
    longitude: '-76.730286',
    timeZoneId: 'America/New_York',
  },
  '14895': {
    zip: '14895',
    city: 'Wellsville',
    state: 'NY',
    latitude: '42.11197',
    longitude: '-77.945146',
    timeZoneId: 'America/New_York',
  },
  '14897': {
    zip: '14897',
    city: 'Whitesville',
    state: 'NY',
    latitude: '42.035503',
    longitude: '-77.777158',
    timeZoneId: 'America/New_York',
  },
  '14898': {
    zip: '14898',
    city: 'Woodhull',
    state: 'NY',
    latitude: '42.077279',
    longitude: '-77.409549',
    timeZoneId: 'America/New_York',
  },
  '14901': {
    zip: '14901',
    city: 'Elmira',
    state: 'NY',
    latitude: '42.098491',
    longitude: '-76.808792',
    timeZoneId: 'America/New_York',
  },
  '14902': {
    zip: '14902',
    city: 'Elmira',
    state: 'NY',
    latitude: '42.10412',
    longitude: '-76.812185',
    timeZoneId: 'America/New_York',
  },
  '14903': {
    zip: '14903',
    city: 'Elmira',
    state: 'NY',
    latitude: '42.12774',
    longitude: '-76.86966',
    timeZoneId: 'America/New_York',
  },
  '14904': {
    zip: '14904',
    city: 'Elmira',
    state: 'NY',
    latitude: '42.07222',
    longitude: '-76.805797',
    timeZoneId: 'America/New_York',
  },
  '14905': {
    zip: '14905',
    city: 'Elmira',
    state: 'NY',
    latitude: '42.090047',
    longitude: '-76.839445',
    timeZoneId: 'America/New_York',
  },
  '15001': {
    zip: '15001',
    city: 'Aliquippa',
    state: 'PA',
    latitude: '40.60372',
    longitude: '-80.285814',
    timeZoneId: 'America/New_York',
  },
  '15003': {
    zip: '15003',
    city: 'Ambridge',
    state: 'PA',
    latitude: '40.582123',
    longitude: '-80.214863',
    timeZoneId: 'America/New_York',
  },
  '15004': {
    zip: '15004',
    city: 'Atlasburg',
    state: 'PA',
    latitude: '40.383783',
    longitude: '-80.389826',
    timeZoneId: 'America/New_York',
  },
  '15005': {
    zip: '15005',
    city: 'Baden',
    state: 'PA',
    latitude: '40.643059',
    longitude: '-80.201665',
    timeZoneId: 'America/New_York',
  },
  '15006': {
    zip: '15006',
    city: 'Bairdford',
    state: 'PA',
    latitude: '40.633087',
    longitude: '-79.885255',
    timeZoneId: 'America/New_York',
  },
  '15007': {
    zip: '15007',
    city: 'Bakerstown',
    state: 'PA',
    latitude: '40.653132',
    longitude: '-79.934695',
    timeZoneId: 'America/New_York',
  },
  '15009': {
    zip: '15009',
    city: 'Beaver',
    state: 'PA',
    latitude: '40.7028',
    longitude: '-80.35566',
    timeZoneId: 'America/New_York',
  },
  '15010': {
    zip: '15010',
    city: 'Beaver Falls',
    state: 'PA',
    latitude: '40.763952',
    longitude: '-80.341536',
    timeZoneId: 'America/New_York',
  },
  '15012': {
    zip: '15012',
    city: 'Belle Vernon',
    state: 'PA',
    latitude: '40.122188',
    longitude: '-79.844637',
    timeZoneId: 'America/New_York',
  },
  '15014': {
    zip: '15014',
    city: 'Brackenridge',
    state: 'PA',
    latitude: '40.609876',
    longitude: '-79.740604',
    timeZoneId: 'America/New_York',
  },
  '15015': {
    zip: '15015',
    city: 'Bradfordwoods',
    state: 'PA',
    latitude: '40.638488',
    longitude: '-80.084285',
    timeZoneId: 'America/New_York',
  },
  '15017': {
    zip: '15017',
    city: 'Bridgeville',
    state: 'PA',
    latitude: '40.355855',
    longitude: '-80.113491',
    timeZoneId: 'America/New_York',
  },
  '15018': {
    zip: '15018',
    city: 'Buena Vista',
    state: 'PA',
    latitude: '40.266596',
    longitude: '-79.798318',
    timeZoneId: 'America/New_York',
  },
  '15019': {
    zip: '15019',
    city: 'Bulger',
    state: 'PA',
    latitude: '40.403803',
    longitude: '-80.330763',
    timeZoneId: 'America/New_York',
  },
  '15020': {
    zip: '15020',
    city: 'Bunola',
    state: 'PA',
    latitude: '40.239015',
    longitude: '-79.953531',
    timeZoneId: 'America/New_York',
  },
  '15021': {
    zip: '15021',
    city: 'Burgettstown',
    state: 'PA',
    latitude: '40.4127',
    longitude: '-80.43126',
    timeZoneId: 'America/New_York',
  },
  '15022': {
    zip: '15022',
    city: 'Charleroi',
    state: 'PA',
    latitude: '40.13466',
    longitude: '-79.91604',
    timeZoneId: 'America/New_York',
  },
  '15024': {
    zip: '15024',
    city: 'Cheswick',
    state: 'PA',
    latitude: '40.565385',
    longitude: '-79.831793',
    timeZoneId: 'America/New_York',
  },
  '15025': {
    zip: '15025',
    city: 'Clairton',
    state: 'PA',
    latitude: '40.295525',
    longitude: '-79.91635',
    timeZoneId: 'America/New_York',
  },
  '15026': {
    zip: '15026',
    city: 'Clinton',
    state: 'PA',
    latitude: '40.515445',
    longitude: '-80.354016',
    timeZoneId: 'America/New_York',
  },
  '15027': {
    zip: '15027',
    city: 'Conway',
    state: 'PA',
    latitude: '40.661278',
    longitude: '-80.235402',
    timeZoneId: 'America/New_York',
  },
  '15028': {
    zip: '15028',
    city: 'Coulters',
    state: 'PA',
    latitude: '40.297884',
    longitude: '-79.801998',
    timeZoneId: 'America/New_York',
  },
  '15030': {
    zip: '15030',
    city: 'Creighton',
    state: 'PA',
    latitude: '40.584756',
    longitude: '-79.779785',
    timeZoneId: 'America/New_York',
  },
  '15031': {
    zip: '15031',
    city: 'Cuddy',
    state: 'PA',
    latitude: '40.351382',
    longitude: '-80.169707',
    timeZoneId: 'America/New_York',
  },
  '15032': {
    zip: '15032',
    city: 'Curtisville',
    state: 'PA',
    latitude: '40.636097',
    longitude: '-79.841254',
    timeZoneId: 'America/New_York',
  },
  '15033': {
    zip: '15033',
    city: 'Donora',
    state: 'PA',
    latitude: '40.177612',
    longitude: '-79.862742',
    timeZoneId: 'America/New_York',
  },
  '15034': {
    zip: '15034',
    city: 'Dravosburg',
    state: 'PA',
    latitude: '40.354283',
    longitude: '-79.891157',
    timeZoneId: 'America/New_York',
  },
  '15035': {
    zip: '15035',
    city: 'East Mc Keesport',
    state: 'PA',
    latitude: '40.380214',
    longitude: '-79.80676',
    timeZoneId: 'America/New_York',
  },
  '15037': {
    zip: '15037',
    city: 'Elizabeth',
    state: 'PA',
    latitude: '40.267795',
    longitude: '-79.859115',
    timeZoneId: 'America/New_York',
  },
  '15038': {
    zip: '15038',
    city: 'Elrama',
    state: 'PA',
    latitude: '40.255228',
    longitude: '-79.922068',
    timeZoneId: 'America/New_York',
  },
  '15042': {
    zip: '15042',
    city: 'Freedom',
    state: 'PA',
    latitude: '40.678979',
    longitude: '-80.223616',
    timeZoneId: 'America/New_York',
  },
  '15043': {
    zip: '15043',
    city: 'Georgetown',
    state: 'PA',
    latitude: '40.686564',
    longitude: '-80.355979',
    timeZoneId: 'America/New_York',
  },
  '15044': {
    zip: '15044',
    city: 'Gibsonia',
    state: 'PA',
    latitude: '40.625976',
    longitude: '-79.953769',
    timeZoneId: 'America/New_York',
  },
  '15045': {
    zip: '15045',
    city: 'Glassport',
    state: 'PA',
    latitude: '40.327068',
    longitude: '-79.890734',
    timeZoneId: 'America/New_York',
  },
  '15046': {
    zip: '15046',
    city: 'Crescent',
    state: 'PA',
    latitude: '40.548807',
    longitude: '-80.217723',
    timeZoneId: 'America/New_York',
  },
  '15047': {
    zip: '15047',
    city: 'Greenock',
    state: 'PA',
    latitude: '40.314017',
    longitude: '-79.796017',
    timeZoneId: 'America/New_York',
  },
  '15049': {
    zip: '15049',
    city: 'Harwick',
    state: 'PA',
    latitude: '40.557931',
    longitude: '-79.806002',
    timeZoneId: 'America/New_York',
  },
  '15050': {
    zip: '15050',
    city: 'Hookstown',
    state: 'PA',
    latitude: '40.580261',
    longitude: '-80.458899',
    timeZoneId: 'America/New_York',
  },
  '15051': {
    zip: '15051',
    city: 'Indianola',
    state: 'PA',
    latitude: '40.566841',
    longitude: '-79.871404',
    timeZoneId: 'America/New_York',
  },
  '15052': {
    zip: '15052',
    city: 'Industry',
    state: 'PA',
    latitude: '40.671192',
    longitude: '-80.43003',
    timeZoneId: 'America/New_York',
  },
  '15053': {
    zip: '15053',
    city: 'Joffre',
    state: 'PA',
    latitude: '40.382702',
    longitude: '-80.359702',
    timeZoneId: 'America/New_York',
  },
  '15054': {
    zip: '15054',
    city: 'Langeloth',
    state: 'PA',
    latitude: '40.365148',
    longitude: '-80.409787',
    timeZoneId: 'America/New_York',
  },
  '15055': {
    zip: '15055',
    city: 'Lawrence',
    state: 'PA',
    latitude: '40.30476',
    longitude: '-80.127553',
    timeZoneId: 'America/New_York',
  },
  '15056': {
    zip: '15056',
    city: 'Leetsdale',
    state: 'PA',
    latitude: '40.567417',
    longitude: '-80.206685',
    timeZoneId: 'America/New_York',
  },
  '15057': {
    zip: '15057',
    city: 'Mc Donald',
    state: 'PA',
    latitude: '40.363602',
    longitude: '-80.232468',
    timeZoneId: 'America/New_York',
  },
  '15059': {
    zip: '15059',
    city: 'Midland',
    state: 'PA',
    latitude: '40.661882',
    longitude: '-80.471984',
    timeZoneId: 'America/New_York',
  },
  '15060': {
    zip: '15060',
    city: 'Midway',
    state: 'PA',
    latitude: '40.367883',
    longitude: '-80.295614',
    timeZoneId: 'America/New_York',
  },
  '15061': {
    zip: '15061',
    city: 'Monaca',
    state: 'PA',
    latitude: '40.671386',
    longitude: '-80.29259',
    timeZoneId: 'America/New_York',
  },
  '15062': {
    zip: '15062',
    city: 'Monessen',
    state: 'PA',
    latitude: '40.152768',
    longitude: '-79.881219',
    timeZoneId: 'America/New_York',
  },
  '15063': {
    zip: '15063',
    city: 'Monongahela',
    state: 'PA',
    latitude: '40.195465',
    longitude: '-79.933138',
    timeZoneId: 'America/New_York',
  },
  '15064': {
    zip: '15064',
    city: 'Morgan',
    state: 'PA',
    latitude: '40.356794',
    longitude: '-80.148263',
    timeZoneId: 'America/New_York',
  },
  '15065': {
    zip: '15065',
    city: 'Natrona Heights',
    state: 'PA',
    latitude: '40.63776',
    longitude: '-79.72074',
    timeZoneId: 'America/New_York',
  },
  '15066': {
    zip: '15066',
    city: 'New Brighton',
    state: 'PA',
    latitude: '40.73466',
    longitude: '-80.289254',
    timeZoneId: 'America/New_York',
  },
  '15067': {
    zip: '15067',
    city: 'New Eagle',
    state: 'PA',
    latitude: '40.205814',
    longitude: '-79.953185',
    timeZoneId: 'America/New_York',
  },
  '15068': {
    zip: '15068',
    city: 'New Kensington',
    state: 'PA',
    latitude: '40.576451',
    longitude: '-79.72398',
    timeZoneId: 'America/New_York',
  },
  '15069': {
    zip: '15069',
    city: 'New Kensington',
    state: 'PA',
    latitude: '40.569677',
    longitude: '-79.749317',
    timeZoneId: 'America/New_York',
  },
  '15071': {
    zip: '15071',
    city: 'Oakdale',
    state: 'PA',
    latitude: '40.391111',
    longitude: '-80.198889',
    timeZoneId: 'America/New_York',
  },
  '15072': {
    zip: '15072',
    city: 'Pricedale',
    state: 'PA',
    latitude: '40.14046',
    longitude: '-79.859271',
    timeZoneId: 'America/New_York',
  },
  '15074': {
    zip: '15074',
    city: 'Rochester',
    state: 'PA',
    latitude: '40.708963',
    longitude: '-80.270064',
    timeZoneId: 'America/New_York',
  },
  '15075': {
    zip: '15075',
    city: 'Rural Ridge',
    state: 'PA',
    latitude: '40.585809',
    longitude: '-79.823173',
    timeZoneId: 'America/New_York',
  },
  '15076': {
    zip: '15076',
    city: 'Russellton',
    state: 'PA',
    latitude: '40.608758',
    longitude: '-79.836455',
    timeZoneId: 'America/New_York',
  },
  '15077': {
    zip: '15077',
    city: 'Shippingport',
    state: 'PA',
    latitude: '40.617677',
    longitude: '-80.425854',
    timeZoneId: 'America/New_York',
  },
  '15078': {
    zip: '15078',
    city: 'Slovan',
    state: 'PA',
    latitude: '40.362051',
    longitude: '-80.406778',
    timeZoneId: 'America/New_York',
  },
  '15081': {
    zip: '15081',
    city: 'South Heights',
    state: 'PA',
    latitude: '40.576453',
    longitude: '-80.23747',
    timeZoneId: 'America/New_York',
  },
  '15082': {
    zip: '15082',
    city: 'Sturgeon',
    state: 'PA',
    latitude: '40.413799',
    longitude: '-80.193818',
    timeZoneId: 'America/New_York',
  },
  '15083': {
    zip: '15083',
    city: 'Sutersville',
    state: 'PA',
    latitude: '40.238648',
    longitude: '-79.791917',
    timeZoneId: 'America/New_York',
  },
  '15084': {
    zip: '15084',
    city: 'Tarentum',
    state: 'PA',
    latitude: '40.607623',
    longitude: '-79.775387',
    timeZoneId: 'America/New_York',
  },
  '15085': {
    zip: '15085',
    city: 'Trafford',
    state: 'PA',
    latitude: '40.38672',
    longitude: '-79.7415',
    timeZoneId: 'America/New_York',
  },
  '15086': {
    zip: '15086',
    city: 'Warrendale',
    state: 'PA',
    latitude: '40.661262',
    longitude: '-80.083168',
    timeZoneId: 'America/New_York',
  },
  '15087': {
    zip: '15087',
    city: 'Webster',
    state: 'PA',
    latitude: '40.18798',
    longitude: '-79.850222',
    timeZoneId: 'America/New_York',
  },
  '15088': {
    zip: '15088',
    city: 'West Elizabeth',
    state: 'PA',
    latitude: '40.272744',
    longitude: '-79.89535',
    timeZoneId: 'America/New_York',
  },
  '15089': {
    zip: '15089',
    city: 'West Newton',
    state: 'PA',
    latitude: '40.200567',
    longitude: '-79.720301',
    timeZoneId: 'America/New_York',
  },
  '15090': {
    zip: '15090',
    city: 'Wexford',
    state: 'PA',
    latitude: '40.616768',
    longitude: '-80.064826',
    timeZoneId: 'America/New_York',
  },
  '15091': {
    zip: '15091',
    city: 'Wildwood',
    state: 'PA',
    latitude: '40.596238',
    longitude: '-79.964462',
    timeZoneId: 'America/New_York',
  },
  '15095': {
    zip: '15095',
    city: 'Warrendale',
    state: 'PA',
    latitude: '40.643305',
    longitude: '-80.109507',
    timeZoneId: 'America/New_York',
  },
  '15096': {
    zip: '15096',
    city: 'Warrendale',
    state: 'PA',
    latitude: '40.651942',
    longitude: '-80.093281',
    timeZoneId: 'America/New_York',
  },
  '15101': {
    zip: '15101',
    city: 'Allison Park',
    state: 'PA',
    latitude: '40.567823',
    longitude: '-79.97027',
    timeZoneId: 'America/New_York',
  },
  '15102': {
    zip: '15102',
    city: 'Bethel Park',
    state: 'PA',
    latitude: '40.324521',
    longitude: '-80.03844',
    timeZoneId: 'America/New_York',
  },
  '15104': {
    zip: '15104',
    city: 'Braddock',
    state: 'PA',
    latitude: '40.406423',
    longitude: '-79.863859',
    timeZoneId: 'America/New_York',
  },
  '15106': {
    zip: '15106',
    city: 'Carnegie',
    state: 'PA',
    latitude: '40.405231',
    longitude: '-80.093696',
    timeZoneId: 'America/New_York',
  },
  '15108': {
    zip: '15108',
    city: 'Coraopolis',
    state: 'PA',
    latitude: '40.51053',
    longitude: '-80.182409',
    timeZoneId: 'America/New_York',
  },
  '15110': {
    zip: '15110',
    city: 'Duquesne',
    state: 'PA',
    latitude: '40.371863',
    longitude: '-79.852119',
    timeZoneId: 'America/New_York',
  },
  '15112': {
    zip: '15112',
    city: 'East Pittsburgh',
    state: 'PA',
    latitude: '40.403876',
    longitude: '-79.837675',
    timeZoneId: 'America/New_York',
  },
  '15116': {
    zip: '15116',
    city: 'Glenshaw',
    state: 'PA',
    latitude: '40.523761',
    longitude: '-79.94612',
    timeZoneId: 'America/New_York',
  },
  '15120': {
    zip: '15120',
    city: 'Homestead',
    state: 'PA',
    latitude: '40.394809',
    longitude: '-79.903215',
    timeZoneId: 'America/New_York',
  },
  '15122': {
    zip: '15122',
    city: 'West Mifflin',
    state: 'PA',
    latitude: '40.377383',
    longitude: '-79.892562',
    timeZoneId: 'America/New_York',
  },
  '15123': {
    zip: '15123',
    city: 'West Mifflin',
    state: 'PA',
    latitude: '40.34232',
    longitude: '-79.95468',
    timeZoneId: 'America/New_York',
  },
  '15126': {
    zip: '15126',
    city: 'Imperial',
    state: 'PA',
    latitude: '40.445351',
    longitude: '-80.240735',
    timeZoneId: 'America/New_York',
  },
  '15127': {
    zip: '15127',
    city: 'Ingomar',
    state: 'PA',
    latitude: '40.585',
    longitude: '-80.054465',
    timeZoneId: 'America/New_York',
  },
  '15129': {
    zip: '15129',
    city: 'South Park',
    state: 'PA',
    latitude: '40.296259',
    longitude: '-79.999358',
    timeZoneId: 'America/New_York',
  },
  '15131': {
    zip: '15131',
    city: 'McKeesport',
    state: 'PA',
    latitude: '40.33062',
    longitude: '-79.80618',
    timeZoneId: 'America/New_York',
  },
  '15132': {
    zip: '15132',
    city: 'McKeesport',
    state: 'PA',
    latitude: '40.339533',
    longitude: '-79.842159',
    timeZoneId: 'America/New_York',
  },
  '15133': {
    zip: '15133',
    city: 'McKeesport',
    state: 'PA',
    latitude: '40.332947',
    longitude: '-79.865183',
    timeZoneId: 'America/New_York',
  },
  '15134': {
    zip: '15134',
    city: 'McKeesport',
    state: 'PA',
    latitude: '40.341563',
    longitude: '-79.843904',
    timeZoneId: 'America/New_York',
  },
  '15135': {
    zip: '15135',
    city: 'McKeesport',
    state: 'PA',
    latitude: '40.303777',
    longitude: '-79.815605',
    timeZoneId: 'America/New_York',
  },
  '15136': {
    zip: '15136',
    city: 'Mc Kees Rocks',
    state: 'PA',
    latitude: '40.472555',
    longitude: '-80.088822',
    timeZoneId: 'America/New_York',
  },
  '15137': {
    zip: '15137',
    city: 'North Versailles',
    state: 'PA',
    latitude: '40.392941',
    longitude: '-79.833489',
    timeZoneId: 'America/New_York',
  },
  '15139': {
    zip: '15139',
    city: 'Oakmont',
    state: 'PA',
    latitude: '40.518707',
    longitude: '-79.834203',
    timeZoneId: 'America/New_York',
  },
  '15140': {
    zip: '15140',
    city: 'Pitcairn',
    state: 'PA',
    latitude: '40.405817',
    longitude: '-79.773696',
    timeZoneId: 'America/New_York',
  },
  '15142': {
    zip: '15142',
    city: 'Presto',
    state: 'PA',
    latitude: '40.378033',
    longitude: '-80.121426',
    timeZoneId: 'America/New_York',
  },
  '15143': {
    zip: '15143',
    city: 'Sewickley',
    state: 'PA',
    latitude: '40.553334',
    longitude: '-80.152982',
    timeZoneId: 'America/New_York',
  },
  '15144': {
    zip: '15144',
    city: 'Springdale',
    state: 'PA',
    latitude: '40.540695',
    longitude: '-79.782399',
    timeZoneId: 'America/New_York',
  },
  '15145': {
    zip: '15145',
    city: 'Turtle Creek',
    state: 'PA',
    latitude: '40.408017',
    longitude: '-79.82506',
    timeZoneId: 'America/New_York',
  },
  '15146': {
    zip: '15146',
    city: 'Monroeville',
    state: 'PA',
    latitude: '40.425925',
    longitude: '-79.764398',
    timeZoneId: 'America/New_York',
  },
  '15147': {
    zip: '15147',
    city: 'Verona',
    state: 'PA',
    latitude: '40.492029',
    longitude: '-79.833802',
    timeZoneId: 'America/New_York',
  },
  '15148': {
    zip: '15148',
    city: 'Wilmerding',
    state: 'PA',
    latitude: '40.38858',
    longitude: '-79.79694',
    timeZoneId: 'America/New_York',
  },
  '15201': {
    zip: '15201',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.470679',
    longitude: '-79.954348',
    timeZoneId: 'America/New_York',
  },
  '15202': {
    zip: '15202',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.501271',
    longitude: '-80.066474',
    timeZoneId: 'America/New_York',
  },
  '15203': {
    zip: '15203',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.424202',
    longitude: '-79.975101',
    timeZoneId: 'America/New_York',
  },
  '15204': {
    zip: '15204',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.452082',
    longitude: '-80.056352',
    timeZoneId: 'America/New_York',
  },
  '15205': {
    zip: '15205',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.437059',
    longitude: '-80.074137',
    timeZoneId: 'America/New_York',
  },
  '15206': {
    zip: '15206',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.466864',
    longitude: '-79.915908',
    timeZoneId: 'America/New_York',
  },
  '15207': {
    zip: '15207',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.40382',
    longitude: '-79.92606',
    timeZoneId: 'America/New_York',
  },
  '15208': {
    zip: '15208',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.456388',
    longitude: '-79.897619',
    timeZoneId: 'America/New_York',
  },
  '15209': {
    zip: '15209',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.496241',
    longitude: '-79.979064',
    timeZoneId: 'America/New_York',
  },
  '15210': {
    zip: '15210',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.4049',
    longitude: '-79.97262',
    timeZoneId: 'America/New_York',
  },
  '15211': {
    zip: '15211',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.43274',
    longitude: '-79.94568',
    timeZoneId: 'America/New_York',
  },
  '15212': {
    zip: '15212',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.46988',
    longitude: '-80.0157',
    timeZoneId: 'America/New_York',
  },
  '15213': {
    zip: '15213',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.444993',
    longitude: '-79.952662',
    timeZoneId: 'America/New_York',
  },
  '15214': {
    zip: '15214',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.481784',
    longitude: '-80.018549',
    timeZoneId: 'America/New_York',
  },
  '15215': {
    zip: '15215',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.50378',
    longitude: '-79.91202',
    timeZoneId: 'America/New_York',
  },
  '15216': {
    zip: '15216',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.402514',
    longitude: '-80.038122',
    timeZoneId: 'America/New_York',
  },
  '15217': {
    zip: '15217',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.428877',
    longitude: '-79.923209',
    timeZoneId: 'America/New_York',
  },
  '15218': {
    zip: '15218',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.426383',
    longitude: '-79.888677',
    timeZoneId: 'America/New_York',
  },
  '15219': {
    zip: '15219',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.447423',
    longitude: '-79.979867',
    timeZoneId: 'America/New_York',
  },
  '15220': {
    zip: '15220',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.425951',
    longitude: '-80.048689',
    timeZoneId: 'America/New_York',
  },
  '15221': {
    zip: '15221',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.43796',
    longitude: '-79.9557',
    timeZoneId: 'America/New_York',
  },
  '15222': {
    zip: '15222',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.448765',
    longitude: '-79.991418',
    timeZoneId: 'America/New_York',
  },
  '15223': {
    zip: '15223',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.46874',
    longitude: '-79.9572',
    timeZoneId: 'America/New_York',
  },
  '15224': {
    zip: '15224',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.465933',
    longitude: '-79.946627',
    timeZoneId: 'America/New_York',
  },
  '15225': {
    zip: '15225',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.51512',
    longitude: '-80.08776',
    timeZoneId: 'America/New_York',
  },
  '15226': {
    zip: '15226',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.396692',
    longitude: '-80.01539',
    timeZoneId: 'America/New_York',
  },
  '15227': {
    zip: '15227',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.42674',
    longitude: '-79.96914',
    timeZoneId: 'America/New_York',
  },
  '15228': {
    zip: '15228',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.375682',
    longitude: '-80.040038',
    timeZoneId: 'America/New_York',
  },
  '15229': {
    zip: '15229',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.517677',
    longitude: '-80.040063',
    timeZoneId: 'America/New_York',
  },
  '15230': {
    zip: '15230',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.51202',
    longitude: '-80.0789',
    timeZoneId: 'America/New_York',
  },
  '15231': {
    zip: '15231',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.502772',
    longitude: '-80.189169',
    timeZoneId: 'America/New_York',
  },
  '15232': {
    zip: '15232',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.451373',
    longitude: '-79.932761',
    timeZoneId: 'America/New_York',
  },
  '15233': {
    zip: '15233',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.457777',
    longitude: '-80.026332',
    timeZoneId: 'America/New_York',
  },
  '15234': {
    zip: '15234',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.367019',
    longitude: '-80.019091',
    timeZoneId: 'America/New_York',
  },
  '15235': {
    zip: '15235',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.44504',
    longitude: '-79.91376',
    timeZoneId: 'America/New_York',
  },
  '15236': {
    zip: '15236',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.34718',
    longitude: '-79.97695',
    timeZoneId: 'America/New_York',
  },
  '15237': {
    zip: '15237',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.549733',
    longitude: '-80.039957',
    timeZoneId: 'America/New_York',
  },
  '15238': {
    zip: '15238',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.530285',
    longitude: '-79.876419',
    timeZoneId: 'America/New_York',
  },
  '15239': {
    zip: '15239',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.479721',
    longitude: '-79.74982',
    timeZoneId: 'America/New_York',
  },
  '15240': {
    zip: '15240',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.445977',
    longitude: '-79.962428',
    timeZoneId: 'America/New_York',
  },
  '15241': {
    zip: '15241',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.35762',
    longitude: '-80.05998',
    timeZoneId: 'America/New_York',
  },
  '15242': {
    zip: '15242',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.417412',
    longitude: '-80.045004',
    timeZoneId: 'America/New_York',
  },
  '15243': {
    zip: '15243',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.383333',
    longitude: '-80.066667',
    timeZoneId: 'America/New_York',
  },
  '15244': {
    zip: '15244',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.445184',
    longitude: '-80.143578',
    timeZoneId: 'America/New_York',
  },
  '15250': {
    zip: '15250',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.448293',
    longitude: '-79.951894',
    timeZoneId: 'America/New_York',
  },
  '15251': {
    zip: '15251',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.444752',
    longitude: '-79.951563',
    timeZoneId: 'America/New_York',
  },
  '15252': {
    zip: '15252',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.440966',
    longitude: '-79.998271',
    timeZoneId: 'America/New_York',
  },
  '15253': {
    zip: '15253',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.460709',
    longitude: '-80.020311',
    timeZoneId: 'America/New_York',
  },
  '15254': {
    zip: '15254',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441088',
    longitude: '-79.998276',
    timeZoneId: 'America/New_York',
  },
  '15255': {
    zip: '15255',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.455892',
    longitude: '-80.021962',
    timeZoneId: 'America/New_York',
  },
  '15257': {
    zip: '15257',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441554',
    longitude: '-79.997787',
    timeZoneId: 'America/New_York',
  },
  '15258': {
    zip: '15258',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441892',
    longitude: '-79.997266',
    timeZoneId: 'America/New_York',
  },
  '15259': {
    zip: '15259',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441403',
    longitude: '-79.99816',
    timeZoneId: 'America/New_York',
  },
  '15260': {
    zip: '15260',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441234',
    longitude: '-79.9457',
    timeZoneId: 'America/New_York',
  },
  '15261': {
    zip: '15261',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441907',
    longitude: '-79.956393',
    timeZoneId: 'America/New_York',
  },
  '15262': {
    zip: '15262',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.473575',
    longitude: '-79.988919',
    timeZoneId: 'America/New_York',
  },
  '15264': {
    zip: '15264',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.44422',
    longitude: '-79.955987',
    timeZoneId: 'America/New_York',
  },
  '15265': {
    zip: '15265',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.44238',
    longitude: '-79.999942',
    timeZoneId: 'America/New_York',
  },
  '15267': {
    zip: '15267',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.467425',
    longitude: '-80.037466',
    timeZoneId: 'America/New_York',
  },
  '15268': {
    zip: '15268',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.486226',
    longitude: '-79.735977',
    timeZoneId: 'America/New_York',
  },
  '15270': {
    zip: '15270',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.403948',
    longitude: '-80.034848',
    timeZoneId: 'America/New_York',
  },
  '15272': {
    zip: '15272',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441848',
    longitude: '-80.005017',
    timeZoneId: 'America/New_York',
  },
  '15274': {
    zip: '15274',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.440293',
    longitude: '-79.954512',
    timeZoneId: 'America/New_York',
  },
  '15275': {
    zip: '15275',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.439193',
    longitude: '-80.096656',
    timeZoneId: 'America/New_York',
  },
  '15276': {
    zip: '15276',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.438026',
    longitude: '-80.093764',
    timeZoneId: 'America/New_York',
  },
  '15277': {
    zip: '15277',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.441394',
    longitude: '-80.0978',
    timeZoneId: 'America/New_York',
  },
  '15278': {
    zip: '15278',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.511937',
    longitude: '-80.079685',
    timeZoneId: 'America/New_York',
  },
  '15279': {
    zip: '15279',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.465513',
    longitude: '-80.038303',
    timeZoneId: 'America/New_York',
  },
  '15281': {
    zip: '15281',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.440025',
    longitude: '-79.99865',
    timeZoneId: 'America/New_York',
  },
  '15282': {
    zip: '15282',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.43629',
    longitude: '-79.991277',
    timeZoneId: 'America/New_York',
  },
  '15283': {
    zip: '15283',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.422089',
    longitude: '-80.046367',
    timeZoneId: 'America/New_York',
  },
  '15286': {
    zip: '15286',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.458733',
    longitude: '-80.031221',
    timeZoneId: 'America/New_York',
  },
  '15289': {
    zip: '15289',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.438309',
    longitude: '-79.997452',
    timeZoneId: 'America/New_York',
  },
  '15290': {
    zip: '15290',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.508153',
    longitude: '-80.076287',
    timeZoneId: 'America/New_York',
  },
  '15295': {
    zip: '15295',
    city: 'Pittsburgh',
    state: 'PA',
    latitude: '40.442411',
    longitude: '-79.996664',
    timeZoneId: 'America/New_York',
  },
  '15301': {
    zip: '15301',
    city: 'Washington',
    state: 'PA',
    latitude: '40.175267',
    longitude: '-80.251514',
    timeZoneId: 'America/New_York',
  },
  '15310': {
    zip: '15310',
    city: 'Aleppo',
    state: 'PA',
    latitude: '39.82327',
    longitude: '-80.462303',
    timeZoneId: 'America/New_York',
  },
  '15311': {
    zip: '15311',
    city: 'Amity',
    state: 'PA',
    latitude: '40.031825',
    longitude: '-80.19166',
    timeZoneId: 'America/New_York',
  },
  '15312': {
    zip: '15312',
    city: 'Avella',
    state: 'PA',
    latitude: '40.272892',
    longitude: '-80.45101',
    timeZoneId: 'America/New_York',
  },
  '15313': {
    zip: '15313',
    city: 'Beallsville',
    state: 'PA',
    latitude: '40.06295',
    longitude: '-80.027147',
    timeZoneId: 'America/New_York',
  },
  '15314': {
    zip: '15314',
    city: 'Bentleyville',
    state: 'PA',
    latitude: '40.133934',
    longitude: '-80.012058',
    timeZoneId: 'America/New_York',
  },
  '15315': {
    zip: '15315',
    city: 'Bobtown',
    state: 'PA',
    latitude: '39.759713',
    longitude: '-79.986975',
    timeZoneId: 'America/New_York',
  },
  '15316': {
    zip: '15316',
    city: 'Brave',
    state: 'PA',
    latitude: '39.72184',
    longitude: '-80.260459',
    timeZoneId: 'America/New_York',
  },
  '15317': {
    zip: '15317',
    city: 'Canonsburg',
    state: 'PA',
    latitude: '40.269407',
    longitude: '-80.150995',
    timeZoneId: 'America/New_York',
  },
  '15320': {
    zip: '15320',
    city: 'Carmichaels',
    state: 'PA',
    latitude: '39.87408',
    longitude: '-79.98288',
    timeZoneId: 'America/New_York',
  },
  '15321': {
    zip: '15321',
    city: 'Cecil',
    state: 'PA',
    latitude: '40.317508',
    longitude: '-80.190389',
    timeZoneId: 'America/New_York',
  },
  '15322': {
    zip: '15322',
    city: 'Clarksville',
    state: 'PA',
    latitude: '39.964498',
    longitude: '-80.049715',
    timeZoneId: 'America/New_York',
  },
  '15323': {
    zip: '15323',
    city: 'Claysville',
    state: 'PA',
    latitude: '40.134267',
    longitude: '-80.390629',
    timeZoneId: 'America/New_York',
  },
  '15324': {
    zip: '15324',
    city: 'Cokeburg',
    state: 'PA',
    latitude: '40.100061',
    longitude: '-80.063337',
    timeZoneId: 'America/New_York',
  },
  '15325': {
    zip: '15325',
    city: 'Crucible',
    state: 'PA',
    latitude: '39.946871',
    longitude: '-79.964267',
    timeZoneId: 'America/New_York',
  },
  '15327': {
    zip: '15327',
    city: 'Dilliner',
    state: 'PA',
    latitude: '39.755215',
    longitude: '-79.996804',
    timeZoneId: 'America/New_York',
  },
  '15329': {
    zip: '15329',
    city: 'Prosperity',
    state: 'PA',
    latitude: '40.024107',
    longitude: '-80.251263',
    timeZoneId: 'America/New_York',
  },
  '15330': {
    zip: '15330',
    city: 'Eighty Four',
    state: 'PA',
    latitude: '40.18223',
    longitude: '-80.088968',
    timeZoneId: 'America/New_York',
  },
  '15331': {
    zip: '15331',
    city: 'Ellsworth',
    state: 'PA',
    latitude: '40.110058',
    longitude: '-80.020917',
    timeZoneId: 'America/New_York',
  },
  '15332': {
    zip: '15332',
    city: 'Finleyville',
    state: 'PA',
    latitude: '40.25532',
    longitude: '-80.006933',
    timeZoneId: 'America/New_York',
  },
  '15333': {
    zip: '15333',
    city: 'Fredericktown',
    state: 'PA',
    latitude: '40.028384',
    longitude: '-80.018794',
    timeZoneId: 'America/New_York',
  },
  '15334': {
    zip: '15334',
    city: 'Garards Fort',
    state: 'PA',
    latitude: '39.862387',
    longitude: '-80.002418',
    timeZoneId: 'America/New_York',
  },
  '15336': {
    zip: '15336',
    city: 'Gastonville',
    state: 'PA',
    latitude: '40.263798',
    longitude: '-80.007512',
    timeZoneId: 'America/New_York',
  },
  '15337': {
    zip: '15337',
    city: 'Graysville',
    state: 'PA',
    latitude: '39.896059',
    longitude: '-80.400627',
    timeZoneId: 'America/New_York',
  },
  '15338': {
    zip: '15338',
    city: 'Greensboro',
    state: 'PA',
    latitude: '39.816173',
    longitude: '-79.948032',
    timeZoneId: 'America/New_York',
  },
  '15339': {
    zip: '15339',
    city: 'Hendersonville',
    state: 'PA',
    latitude: '40.29892',
    longitude: '-80.154703',
    timeZoneId: 'America/New_York',
  },
  '15340': {
    zip: '15340',
    city: 'Hickory',
    state: 'PA',
    latitude: '40.294868',
    longitude: '-80.309223',
    timeZoneId: 'America/New_York',
  },
  '15341': {
    zip: '15341',
    city: 'Holbrook',
    state: 'PA',
    latitude: '39.843791',
    longitude: '-80.34422',
    timeZoneId: 'America/New_York',
  },
  '15342': {
    zip: '15342',
    city: 'Houston',
    state: 'PA',
    latitude: '40.240723',
    longitude: '-80.216694',
    timeZoneId: 'America/New_York',
  },
  '15344': {
    zip: '15344',
    city: 'Jefferson',
    state: 'PA',
    latitude: '39.918783',
    longitude: '-80.058301',
    timeZoneId: 'America/New_York',
  },
  '15345': {
    zip: '15345',
    city: 'Marianna',
    state: 'PA',
    latitude: '40.02414',
    longitude: '-80.10184',
    timeZoneId: 'America/New_York',
  },
  '15346': {
    zip: '15346',
    city: 'Mather',
    state: 'PA',
    latitude: '39.936455',
    longitude: '-80.077771',
    timeZoneId: 'America/New_York',
  },
  '15347': {
    zip: '15347',
    city: 'Meadow Lands',
    state: 'PA',
    latitude: '40.215751',
    longitude: '-80.230219',
    timeZoneId: 'America/New_York',
  },
  '15348': {
    zip: '15348',
    city: 'Millsboro',
    state: 'PA',
    latitude: '39.991943',
    longitude: '-79.993148',
    timeZoneId: 'America/New_York',
  },
  '15349': {
    zip: '15349',
    city: 'Mount Morris',
    state: 'PA',
    latitude: '39.74562',
    longitude: '-80.0928',
    timeZoneId: 'America/New_York',
  },
  '15350': {
    zip: '15350',
    city: 'Muse',
    state: 'PA',
    latitude: '40.294494',
    longitude: '-80.201573',
    timeZoneId: 'America/New_York',
  },
  '15351': {
    zip: '15351',
    city: 'Nemacolin',
    state: 'PA',
    latitude: '39.87879',
    longitude: '-79.923804',
    timeZoneId: 'America/New_York',
  },
  '15352': {
    zip: '15352',
    city: 'New Freeport',
    state: 'PA',
    latitude: '39.757908',
    longitude: '-80.461303',
    timeZoneId: 'America/New_York',
  },
  '15353': {
    zip: '15353',
    city: 'Nineveh',
    state: 'PA',
    latitude: '39.969209',
    longitude: '-80.305861',
    timeZoneId: 'America/New_York',
  },
  '15357': {
    zip: '15357',
    city: 'Rices Landing',
    state: 'PA',
    latitude: '39.948975',
    longitude: '-79.995111',
    timeZoneId: 'America/New_York',
  },
  '15358': {
    zip: '15358',
    city: 'Richeyville',
    state: 'PA',
    latitude: '40.05364',
    longitude: '-79.997261',
    timeZoneId: 'America/New_York',
  },
  '15359': {
    zip: '15359',
    city: 'Rogersville',
    state: 'PA',
    latitude: '39.906384',
    longitude: '-80.287245',
    timeZoneId: 'America/New_York',
  },
  '15360': {
    zip: '15360',
    city: 'Scenery Hill',
    state: 'PA',
    latitude: '40.076136',
    longitude: '-80.074788',
    timeZoneId: 'America/New_York',
  },
  '15361': {
    zip: '15361',
    city: 'Southview',
    state: 'PA',
    latitude: '40.329204',
    longitude: '-80.259561',
    timeZoneId: 'America/New_York',
  },
  '15362': {
    zip: '15362',
    city: 'Spraggs',
    state: 'PA',
    latitude: '39.765273',
    longitude: '-80.279959',
    timeZoneId: 'America/New_York',
  },
  '15363': {
    zip: '15363',
    city: 'Strabane',
    state: 'PA',
    latitude: '40.249108',
    longitude: '-80.198615',
    timeZoneId: 'America/New_York',
  },
  '15364': {
    zip: '15364',
    city: 'Sycamore',
    state: 'PA',
    latitude: '39.952121',
    longitude: '-80.224504',
    timeZoneId: 'America/New_York',
  },
  '15365': {
    zip: '15365',
    city: 'Taylorstown',
    state: 'PA',
    latitude: '40.161997',
    longitude: '-80.379282',
    timeZoneId: 'America/New_York',
  },
  '15366': {
    zip: '15366',
    city: 'Van Voorhis',
    state: 'PA',
    latitude: '40.158092',
    longitude: '-79.972806',
    timeZoneId: 'America/New_York',
  },
  '15367': {
    zip: '15367',
    city: 'Venetia',
    state: 'PA',
    latitude: '40.269984',
    longitude: '-80.060582',
    timeZoneId: 'America/New_York',
  },
  '15368': {
    zip: '15368',
    city: 'Vestaburg',
    state: 'PA',
    latitude: '40.018518',
    longitude: '-79.989583',
    timeZoneId: 'America/New_York',
  },
  '15370': {
    zip: '15370',
    city: 'Waynesburg',
    state: 'PA',
    latitude: '39.894897',
    longitude: '-80.174254',
    timeZoneId: 'America/New_York',
  },
  '15376': {
    zip: '15376',
    city: 'West Alexander',
    state: 'PA',
    latitude: '40.112468',
    longitude: '-80.489517',
    timeZoneId: 'America/New_York',
  },
  '15377': {
    zip: '15377',
    city: 'West Finley',
    state: 'PA',
    latitude: '39.982714',
    longitude: '-80.445036',
    timeZoneId: 'America/New_York',
  },
  '15378': {
    zip: '15378',
    city: 'Westland',
    state: 'PA',
    latitude: '40.275938',
    longitude: '-80.270353',
    timeZoneId: 'America/New_York',
  },
  '15379': {
    zip: '15379',
    city: 'West Middletown',
    state: 'PA',
    latitude: '40.24213',
    longitude: '-80.426974',
    timeZoneId: 'America/New_York',
  },
  '15380': {
    zip: '15380',
    city: 'Wind Ridge',
    state: 'PA',
    latitude: '39.889366',
    longitude: '-80.466955',
    timeZoneId: 'America/New_York',
  },
  '15401': {
    zip: '15401',
    city: 'Uniontown',
    state: 'PA',
    latitude: '39.86496',
    longitude: '-79.75938',
    timeZoneId: 'America/New_York',
  },
  '15410': {
    zip: '15410',
    city: 'Adah',
    state: 'PA',
    latitude: '39.887124',
    longitude: '-79.890722',
    timeZoneId: 'America/New_York',
  },
  '15411': {
    zip: '15411',
    city: 'Addison',
    state: 'PA',
    latitude: '39.777122',
    longitude: '-79.30695',
    timeZoneId: 'America/New_York',
  },
  '15412': {
    zip: '15412',
    city: 'Allenport',
    state: 'PA',
    latitude: '40.07907',
    longitude: '-79.854384',
    timeZoneId: 'America/New_York',
  },
  '15413': {
    zip: '15413',
    city: 'Allison',
    state: 'PA',
    latitude: '39.986358',
    longitude: '-79.879281',
    timeZoneId: 'America/New_York',
  },
  '15415': {
    zip: '15415',
    city: 'Brier Hill',
    state: 'PA',
    latitude: '39.977744',
    longitude: '-79.82886',
    timeZoneId: 'America/New_York',
  },
  '15416': {
    zip: '15416',
    city: 'Brownfield',
    state: 'PA',
    latitude: '39.858474',
    longitude: '-79.712651',
    timeZoneId: 'America/New_York',
  },
  '15417': {
    zip: '15417',
    city: 'Brownsville',
    state: 'PA',
    latitude: '40.03104',
    longitude: '-79.9299',
    timeZoneId: 'America/New_York',
  },
  '15419': {
    zip: '15419',
    city: 'California',
    state: 'PA',
    latitude: '40.063892',
    longitude: '-79.894161',
    timeZoneId: 'America/New_York',
  },
  '15420': {
    zip: '15420',
    city: 'Cardale',
    state: 'PA',
    latitude: '39.955894',
    longitude: '-79.866137',
    timeZoneId: 'America/New_York',
  },
  '15421': {
    zip: '15421',
    city: 'Chalk Hill',
    state: 'PA',
    latitude: '39.843749',
    longitude: '-79.616111',
    timeZoneId: 'America/New_York',
  },
  '15422': {
    zip: '15422',
    city: 'Chestnut Ridge',
    state: 'PA',
    latitude: '39.9783',
    longitude: '-79.81014',
    timeZoneId: 'America/New_York',
  },
  '15423': {
    zip: '15423',
    city: 'Coal Center',
    state: 'PA',
    latitude: '40.08754',
    longitude: '-79.914422',
    timeZoneId: 'America/New_York',
  },
  '15424': {
    zip: '15424',
    city: 'Confluence',
    state: 'PA',
    latitude: '39.82164',
    longitude: '-79.35828',
    timeZoneId: 'America/New_York',
  },
  '15425': {
    zip: '15425',
    city: 'Connellsville',
    state: 'PA',
    latitude: '40.02732',
    longitude: '-79.58874',
    timeZoneId: 'America/New_York',
  },
  '15427': {
    zip: '15427',
    city: 'Daisytown',
    state: 'PA',
    latitude: '40.073433',
    longitude: '-79.969493',
    timeZoneId: 'America/New_York',
  },
  '15428': {
    zip: '15428',
    city: 'Dawson',
    state: 'PA',
    latitude: '40.07185',
    longitude: '-79.669366',
    timeZoneId: 'America/New_York',
  },
  '15429': {
    zip: '15429',
    city: 'Denbo',
    state: 'PA',
    latitude: '40.004049',
    longitude: '-79.935234',
    timeZoneId: 'America/New_York',
  },
  '15430': {
    zip: '15430',
    city: 'Dickerson Run',
    state: 'PA',
    latitude: '40.030051',
    longitude: '-79.663331',
    timeZoneId: 'America/New_York',
  },
  '15431': {
    zip: '15431',
    city: 'Dunbar',
    state: 'PA',
    latitude: '39.957225',
    longitude: '-79.609197',
    timeZoneId: 'America/New_York',
  },
  '15432': {
    zip: '15432',
    city: 'Dunlevy',
    state: 'PA',
    latitude: '40.076962',
    longitude: '-79.864922',
    timeZoneId: 'America/New_York',
  },
  '15433': {
    zip: '15433',
    city: 'East Millsboro',
    state: 'PA',
    latitude: '39.971652',
    longitude: '-79.964711',
    timeZoneId: 'America/New_York',
  },
  '15434': {
    zip: '15434',
    city: 'Elco',
    state: 'PA',
    latitude: '40.078597',
    longitude: '-79.875182',
    timeZoneId: 'America/New_York',
  },
  '15435': {
    zip: '15435',
    city: 'Fairbank',
    state: 'PA',
    latitude: '39.942726',
    longitude: '-79.849407',
    timeZoneId: 'America/New_York',
  },
  '15436': {
    zip: '15436',
    city: 'Fairchance',
    state: 'PA',
    latitude: '39.814848',
    longitude: '-79.724357',
    timeZoneId: 'America/New_York',
  },
  '15437': {
    zip: '15437',
    city: 'Farmington',
    state: 'PA',
    latitude: '39.792451',
    longitude: '-79.565992',
    timeZoneId: 'America/New_York',
  },
  '15438': {
    zip: '15438',
    city: 'Fayette City',
    state: 'PA',
    latitude: '40.09891',
    longitude: '-79.838617',
    timeZoneId: 'America/New_York',
  },
  '15439': {
    zip: '15439',
    city: 'Gans',
    state: 'PA',
    latitude: '39.733333',
    longitude: '-79.866667',
    timeZoneId: 'America/New_York',
  },
  '15440': {
    zip: '15440',
    city: 'Gibbon Glade',
    state: 'PA',
    latitude: '39.738983',
    longitude: '-79.569951',
    timeZoneId: 'America/New_York',
  },
  '15442': {
    zip: '15442',
    city: 'Grindstone',
    state: 'PA',
    latitude: '40.01353',
    longitude: '-79.829571',
    timeZoneId: 'America/New_York',
  },
  '15443': {
    zip: '15443',
    city: 'Hibbs',
    state: 'PA',
    latitude: '39.93082',
    longitude: '-79.885064',
    timeZoneId: 'America/New_York',
  },
  '15444': {
    zip: '15444',
    city: 'Hiller',
    state: 'PA',
    latitude: '40.010782',
    longitude: '-79.903624',
    timeZoneId: 'America/New_York',
  },
  '15445': {
    zip: '15445',
    city: 'Hopwood',
    state: 'PA',
    latitude: '39.87245',
    longitude: '-79.659576',
    timeZoneId: 'America/New_York',
  },
  '15446': {
    zip: '15446',
    city: 'Indian Head',
    state: 'PA',
    latitude: '40.022294',
    longitude: '-79.392471',
    timeZoneId: 'America/New_York',
  },
  '15447': {
    zip: '15447',
    city: 'Isabella',
    state: 'PA',
    latitude: '39.944876',
    longitude: '-79.936449',
    timeZoneId: 'America/New_York',
  },
  '15448': {
    zip: '15448',
    city: 'Jacobs Creek',
    state: 'PA',
    latitude: '40.135526',
    longitude: '-79.740385',
    timeZoneId: 'America/New_York',
  },
  '15449': {
    zip: '15449',
    city: 'Keisterville',
    state: 'PA',
    latitude: '39.961316',
    longitude: '-79.783509',
    timeZoneId: 'America/New_York',
  },
  '15450': {
    zip: '15450',
    city: 'La Belle',
    state: 'PA',
    latitude: '39.96673',
    longitude: '-79.943086',
    timeZoneId: 'America/New_York',
  },
  '15451': {
    zip: '15451',
    city: 'Lake Lynn',
    state: 'PA',
    latitude: '39.73806',
    longitude: '-79.816691',
    timeZoneId: 'America/New_York',
  },
  '15454': {
    zip: '15454',
    city: 'Leckrone',
    state: 'PA',
    latitude: '39.860912',
    longitude: '-79.869033',
    timeZoneId: 'America/New_York',
  },
  '15455': {
    zip: '15455',
    city: 'Leisenring',
    state: 'PA',
    latitude: '39.999339',
    longitude: '-79.640051',
    timeZoneId: 'America/New_York',
  },
  '15456': {
    zip: '15456',
    city: 'Lemont Furnace',
    state: 'PA',
    latitude: '39.922596',
    longitude: '-79.787657',
    timeZoneId: 'America/New_York',
  },
  '15458': {
    zip: '15458',
    city: 'Mc Clellandtown',
    state: 'PA',
    latitude: '39.886898',
    longitude: '-79.858211',
    timeZoneId: 'America/New_York',
  },
  '15459': {
    zip: '15459',
    city: 'Markleysburg',
    state: 'PA',
    latitude: '39.767494',
    longitude: '-79.454381',
    timeZoneId: 'America/New_York',
  },
  '15460': {
    zip: '15460',
    city: 'Martin',
    state: 'PA',
    latitude: '39.806688',
    longitude: '-79.912558',
    timeZoneId: 'America/New_York',
  },
  '15461': {
    zip: '15461',
    city: 'Masontown',
    state: 'PA',
    latitude: '39.832222',
    longitude: '-79.918889',
    timeZoneId: 'America/New_York',
  },
  '15462': {
    zip: '15462',
    city: 'Melcroft',
    state: 'PA',
    latitude: '40.062918',
    longitude: '-79.356337',
    timeZoneId: 'America/New_York',
  },
  '15463': {
    zip: '15463',
    city: 'Merrittstown',
    state: 'PA',
    latitude: '39.963805',
    longitude: '-79.875373',
    timeZoneId: 'America/New_York',
  },
  '15464': {
    zip: '15464',
    city: 'Mill Run',
    state: 'PA',
    latitude: '39.966',
    longitude: '-79.457136',
    timeZoneId: 'America/New_York',
  },
  '15465': {
    zip: '15465',
    city: 'Mount Braddock',
    state: 'PA',
    latitude: '39.940953',
    longitude: '-79.645633',
    timeZoneId: 'America/New_York',
  },
  '15466': {
    zip: '15466',
    city: 'Newell',
    state: 'PA',
    latitude: '40.071342',
    longitude: '-79.88831',
    timeZoneId: 'America/New_York',
  },
  '15467': {
    zip: '15467',
    city: 'New Geneva',
    state: 'PA',
    latitude: '39.787757',
    longitude: '-79.909079',
    timeZoneId: 'America/New_York',
  },
  '15468': {
    zip: '15468',
    city: 'New Salem',
    state: 'PA',
    latitude: '39.937191',
    longitude: '-79.822519',
    timeZoneId: 'America/New_York',
  },
  '15469': {
    zip: '15469',
    city: 'Normalville',
    state: 'PA',
    latitude: '40.027712',
    longitude: '-79.405194',
    timeZoneId: 'America/New_York',
  },
  '15470': {
    zip: '15470',
    city: 'Ohiopyle',
    state: 'PA',
    latitude: '39.830011',
    longitude: '-79.447971',
    timeZoneId: 'America/New_York',
  },
  '15472': {
    zip: '15472',
    city: 'Oliver',
    state: 'PA',
    latitude: '39.921503',
    longitude: '-79.716332',
    timeZoneId: 'America/New_York',
  },
  '15473': {
    zip: '15473',
    city: 'Perryopolis',
    state: 'PA',
    latitude: '40.087523',
    longitude: '-79.750761',
    timeZoneId: 'America/New_York',
  },
  '15474': {
    zip: '15474',
    city: 'Point Marion',
    state: 'PA',
    latitude: '39.73771',
    longitude: '-79.900557',
    timeZoneId: 'America/New_York',
  },
  '15475': {
    zip: '15475',
    city: 'Republic',
    state: 'PA',
    latitude: '39.966207',
    longitude: '-79.876501',
    timeZoneId: 'America/New_York',
  },
  '15476': {
    zip: '15476',
    city: 'Ronco',
    state: 'PA',
    latitude: '39.871721',
    longitude: '-79.923226',
    timeZoneId: 'America/New_York',
  },
  '15477': {
    zip: '15477',
    city: 'Roscoe',
    state: 'PA',
    latitude: '40.078404',
    longitude: '-79.863306',
    timeZoneId: 'America/New_York',
  },
  '15478': {
    zip: '15478',
    city: 'Smithfield',
    state: 'PA',
    latitude: '39.784827',
    longitude: '-79.787692',
    timeZoneId: 'America/New_York',
  },
  '15479': {
    zip: '15479',
    city: 'Smithton',
    state: 'PA',
    latitude: '40.139958',
    longitude: '-79.727931',
    timeZoneId: 'America/New_York',
  },
  '15480': {
    zip: '15480',
    city: 'Smock',
    state: 'PA',
    latitude: '39.987169',
    longitude: '-79.777218',
    timeZoneId: 'America/New_York',
  },
  '15482': {
    zip: '15482',
    city: 'Star Junction',
    state: 'PA',
    latitude: '40.062397',
    longitude: '-79.758628',
    timeZoneId: 'America/New_York',
  },
  '15483': {
    zip: '15483',
    city: 'Stockdale',
    state: 'PA',
    latitude: '40.082257',
    longitude: '-79.847836',
    timeZoneId: 'America/New_York',
  },
  '15484': {
    zip: '15484',
    city: 'Uledi',
    state: 'PA',
    latitude: '39.892013',
    longitude: '-79.788687',
    timeZoneId: 'America/New_York',
  },
  '15485': {
    zip: '15485',
    city: 'Ursina',
    state: 'PA',
    latitude: '39.815167',
    longitude: '-79.333622',
    timeZoneId: 'America/New_York',
  },
  '15486': {
    zip: '15486',
    city: 'Vanderbilt',
    state: 'PA',
    latitude: '40.023658',
    longitude: '-79.702799',
    timeZoneId: 'America/New_York',
  },
  '15488': {
    zip: '15488',
    city: 'Waltersburg',
    state: 'PA',
    latitude: '39.98394',
    longitude: '-79.750425',
    timeZoneId: 'America/New_York',
  },
  '15489': {
    zip: '15489',
    city: 'West Leisenring',
    state: 'PA',
    latitude: '39.952923',
    longitude: '-79.702222',
    timeZoneId: 'America/New_York',
  },
  '15490': {
    zip: '15490',
    city: 'White',
    state: 'PA',
    latitude: '40.071746',
    longitude: '-79.421919',
    timeZoneId: 'America/New_York',
  },
  '15492': {
    zip: '15492',
    city: 'Wickhaven',
    state: 'PA',
    latitude: '40.120343',
    longitude: '-79.77104',
    timeZoneId: 'America/New_York',
  },
  '15501': {
    zip: '15501',
    city: 'Somerset',
    state: 'PA',
    latitude: '40.012148',
    longitude: '-79.124952',
    timeZoneId: 'America/New_York',
  },
  '15502': {
    zip: '15502',
    city: 'Hidden Valley',
    state: 'PA',
    latitude: '40.011575',
    longitude: '-79.078207',
    timeZoneId: 'America/New_York',
  },
  '15510': {
    zip: '15510',
    city: 'Somerset',
    state: 'PA',
    latitude: '40.009196',
    longitude: '-79.080641',
    timeZoneId: 'America/New_York',
  },
  '15520': {
    zip: '15520',
    city: 'Acosta',
    state: 'PA',
    latitude: '40.110851',
    longitude: '-79.071739',
    timeZoneId: 'America/New_York',
  },
  '15521': {
    zip: '15521',
    city: 'Alum Bank',
    state: 'PA',
    latitude: '40.19442',
    longitude: '-78.630714',
    timeZoneId: 'America/New_York',
  },
  '15522': {
    zip: '15522',
    city: 'Bedford',
    state: 'PA',
    latitude: '40.018179',
    longitude: '-78.506817',
    timeZoneId: 'America/New_York',
  },
  '15530': {
    zip: '15530',
    city: 'Berlin',
    state: 'PA',
    latitude: '39.918162',
    longitude: '-78.954499',
    timeZoneId: 'America/New_York',
  },
  '15531': {
    zip: '15531',
    city: 'Boswell',
    state: 'PA',
    latitude: '40.160776',
    longitude: '-79.0274',
    timeZoneId: 'America/New_York',
  },
  '15532': {
    zip: '15532',
    city: 'Boynton',
    state: 'PA',
    latitude: '39.788051',
    longitude: '-79.016618',
    timeZoneId: 'America/New_York',
  },
  '15533': {
    zip: '15533',
    city: 'Breezewood',
    state: 'PA',
    latitude: '39.987619',
    longitude: '-78.243556',
    timeZoneId: 'America/New_York',
  },
  '15534': {
    zip: '15534',
    city: 'Buffalo Mills',
    state: 'PA',
    latitude: '39.922098',
    longitude: '-78.709191',
    timeZoneId: 'America/New_York',
  },
  '15535': {
    zip: '15535',
    city: 'Clearville',
    state: 'PA',
    latitude: '39.834884',
    longitude: '-78.451629',
    timeZoneId: 'America/New_York',
  },
  '15536': {
    zip: '15536',
    city: 'Crystal Spring',
    state: 'PA',
    latitude: '39.929488',
    longitude: '-78.212411',
    timeZoneId: 'America/New_York',
  },
  '15537': {
    zip: '15537',
    city: 'Everett',
    state: 'PA',
    latitude: '40.013698',
    longitude: '-78.370151',
    timeZoneId: 'America/New_York',
  },
  '15538': {
    zip: '15538',
    city: 'Fairhope',
    state: 'PA',
    latitude: '39.9036',
    longitude: '-78.83268',
    timeZoneId: 'America/New_York',
  },
  '15539': {
    zip: '15539',
    city: 'Fishertown',
    state: 'PA',
    latitude: '40.125076',
    longitude: '-78.587631',
    timeZoneId: 'America/New_York',
  },
  '15540': {
    zip: '15540',
    city: 'Fort Hill',
    state: 'PA',
    latitude: '39.863584',
    longitude: '-79.247684',
    timeZoneId: 'America/New_York',
  },
  '15541': {
    zip: '15541',
    city: 'Friedens',
    state: 'PA',
    latitude: '40.005681',
    longitude: '-78.895887',
    timeZoneId: 'America/New_York',
  },
  '15542': {
    zip: '15542',
    city: 'Garrett',
    state: 'PA',
    latitude: '39.867239',
    longitude: '-79.062657',
    timeZoneId: 'America/New_York',
  },
  '15544': {
    zip: '15544',
    city: 'Gray',
    state: 'PA',
    latitude: '40.133087',
    longitude: '-79.093554',
    timeZoneId: 'America/New_York',
  },
  '15545': {
    zip: '15545',
    city: 'Hyndman',
    state: 'PA',
    latitude: '39.815212',
    longitude: '-78.728884',
    timeZoneId: 'America/New_York',
  },
  '15546': {
    zip: '15546',
    city: 'Jenners',
    state: 'PA',
    latitude: '40.141601',
    longitude: '-79.04811',
    timeZoneId: 'America/New_York',
  },
  '15547': {
    zip: '15547',
    city: 'Jennerstown',
    state: 'PA',
    latitude: '40.161553',
    longitude: '-79.066352',
    timeZoneId: 'America/New_York',
  },
  '15548': {
    zip: '15548',
    city: 'Kantner',
    state: 'PA',
    latitude: '40.104448',
    longitude: '-78.939815',
    timeZoneId: 'America/New_York',
  },
  '15549': {
    zip: '15549',
    city: 'Listie',
    state: 'PA',
    latitude: '40.029295',
    longitude: '-79.01269',
    timeZoneId: 'America/New_York',
  },
  '15550': {
    zip: '15550',
    city: 'Manns Choice',
    state: 'PA',
    latitude: '39.971222',
    longitude: '-78.655072',
    timeZoneId: 'America/New_York',
  },
  '15551': {
    zip: '15551',
    city: 'Markleton',
    state: 'PA',
    latitude: '39.872698',
    longitude: '-79.292074',
    timeZoneId: 'America/New_York',
  },
  '15552': {
    zip: '15552',
    city: 'Meyersdale',
    state: 'PA',
    latitude: '39.807027',
    longitude: '-79.028327',
    timeZoneId: 'America/New_York',
  },
  '15553': {
    zip: '15553',
    city: 'New Baltimore',
    state: 'PA',
    latitude: '39.984516',
    longitude: '-78.770524',
    timeZoneId: 'America/New_York',
  },
  '15554': {
    zip: '15554',
    city: 'New Paris',
    state: 'PA',
    latitude: '40.120057',
    longitude: '-78.59828',
    timeZoneId: 'America/New_York',
  },
  '15555': {
    zip: '15555',
    city: 'Quecreek',
    state: 'PA',
    latitude: '40.091217',
    longitude: '-79.079943',
    timeZoneId: 'America/New_York',
  },
  '15557': {
    zip: '15557',
    city: 'Rockwood',
    state: 'PA',
    latitude: '39.916771',
    longitude: '-79.153621',
    timeZoneId: 'America/New_York',
  },
  '15558': {
    zip: '15558',
    city: 'Salisbury',
    state: 'PA',
    latitude: '39.751569',
    longitude: '-79.088316',
    timeZoneId: 'America/New_York',
  },
  '15559': {
    zip: '15559',
    city: 'Schellsburg',
    state: 'PA',
    latitude: '40.044408',
    longitude: '-78.668155',
    timeZoneId: 'America/New_York',
  },
  '15560': {
    zip: '15560',
    city: 'Shanksville',
    state: 'PA',
    latitude: '40.01887',
    longitude: '-78.904616',
    timeZoneId: 'America/New_York',
  },
  '15561': {
    zip: '15561',
    city: 'Sipesville',
    state: 'PA',
    latitude: '40.101376',
    longitude: '-79.094471',
    timeZoneId: 'America/New_York',
  },
  '15562': {
    zip: '15562',
    city: 'Springs',
    state: 'PA',
    latitude: '39.742433',
    longitude: '-79.096794',
    timeZoneId: 'America/New_York',
  },
  '15563': {
    zip: '15563',
    city: 'Stoystown',
    state: 'PA',
    latitude: '40.242859',
    longitude: '-78.844748',
    timeZoneId: 'America/New_York',
  },
  '15564': {
    zip: '15564',
    city: 'Wellersburg',
    state: 'PA',
    latitude: '39.734737',
    longitude: '-78.850995',
    timeZoneId: 'America/New_York',
  },
  '15565': {
    zip: '15565',
    city: 'West Salisbury',
    state: 'PA',
    latitude: '39.752803',
    longitude: '-79.096393',
    timeZoneId: 'America/New_York',
  },
  '15601': {
    zip: '15601',
    city: 'Greensburg',
    state: 'PA',
    latitude: '40.29676',
    longitude: '-79.542457',
    timeZoneId: 'America/New_York',
  },
  '15605': {
    zip: '15605',
    city: 'Greensburg',
    state: 'PA',
    latitude: '40.348276',
    longitude: '-79.518797',
    timeZoneId: 'America/New_York',
  },
  '15606': {
    zip: '15606',
    city: 'Greensburg',
    state: 'PA',
    latitude: '40.301688',
    longitude: '-79.538092',
    timeZoneId: 'America/New_York',
  },
  '15610': {
    zip: '15610',
    city: 'Acme',
    state: 'PA',
    latitude: '40.124197',
    longitude: '-79.460907',
    timeZoneId: 'America/New_York',
  },
  '15611': {
    zip: '15611',
    city: 'Adamsburg',
    state: 'PA',
    latitude: '40.314024',
    longitude: '-79.649385',
    timeZoneId: 'America/New_York',
  },
  '15612': {
    zip: '15612',
    city: 'Alverton',
    state: 'PA',
    latitude: '40.129489',
    longitude: '-79.563541',
    timeZoneId: 'America/New_York',
  },
  '15613': {
    zip: '15613',
    city: 'Apollo',
    state: 'PA',
    latitude: '40.540093',
    longitude: '-79.603839',
    timeZoneId: 'America/New_York',
  },
  '15615': {
    zip: '15615',
    city: 'Ardara',
    state: 'PA',
    latitude: '40.358953',
    longitude: '-79.738286',
    timeZoneId: 'America/New_York',
  },
  '15616': {
    zip: '15616',
    city: 'Armbrust',
    state: 'PA',
    latitude: '40.242673',
    longitude: '-79.551029',
    timeZoneId: 'America/New_York',
  },
  '15617': {
    zip: '15617',
    city: 'Arona',
    state: 'PA',
    latitude: '40.270674',
    longitude: '-79.657323',
    timeZoneId: 'America/New_York',
  },
  '15618': {
    zip: '15618',
    city: 'Avonmore',
    state: 'PA',
    latitude: '40.528479',
    longitude: '-79.4655',
    timeZoneId: 'America/New_York',
  },
  '15619': {
    zip: '15619',
    city: 'Bovard',
    state: 'PA',
    latitude: '40.318301',
    longitude: '-79.504647',
    timeZoneId: 'America/New_York',
  },
  '15620': {
    zip: '15620',
    city: 'Bradenville',
    state: 'PA',
    latitude: '40.30625',
    longitude: '-79.262009',
    timeZoneId: 'America/New_York',
  },
  '15621': {
    zip: '15621',
    city: 'Calumet',
    state: 'PA',
    latitude: '40.2112',
    longitude: '-79.485814',
    timeZoneId: 'America/New_York',
  },
  '15622': {
    zip: '15622',
    city: 'Champion',
    state: 'PA',
    latitude: '40.040231',
    longitude: '-79.323715',
    timeZoneId: 'America/New_York',
  },
  '15623': {
    zip: '15623',
    city: 'Claridge',
    state: 'PA',
    latitude: '40.363355',
    longitude: '-79.619134',
    timeZoneId: 'America/New_York',
  },
  '15624': {
    zip: '15624',
    city: 'Crabtree',
    state: 'PA',
    latitude: '40.360325',
    longitude: '-79.469644',
    timeZoneId: 'America/New_York',
  },
  '15625': {
    zip: '15625',
    city: 'Darragh',
    state: 'PA',
    latitude: '40.258382',
    longitude: '-79.673932',
    timeZoneId: 'America/New_York',
  },
  '15626': {
    zip: '15626',
    city: 'Delmont',
    state: 'PA',
    latitude: '40.406329',
    longitude: '-79.574177',
    timeZoneId: 'America/New_York',
  },
  '15627': {
    zip: '15627',
    city: 'Derry',
    state: 'PA',
    latitude: '40.351649',
    longitude: '-79.289656',
    timeZoneId: 'America/New_York',
  },
  '15628': {
    zip: '15628',
    city: 'Donegal',
    state: 'PA',
    latitude: '40.096836',
    longitude: '-79.382949',
    timeZoneId: 'America/New_York',
  },
  '15629': {
    zip: '15629',
    city: 'East Vandergrift',
    state: 'PA',
    latitude: '40.598731',
    longitude: '-79.564877',
    timeZoneId: 'America/New_York',
  },
  '15631': {
    zip: '15631',
    city: 'Everson',
    state: 'PA',
    latitude: '40.092692',
    longitude: '-79.585609',
    timeZoneId: 'America/New_York',
  },
  '15632': {
    zip: '15632',
    city: 'Export',
    state: 'PA',
    latitude: '40.430536',
    longitude: '-79.608959',
    timeZoneId: 'America/New_York',
  },
  '15633': {
    zip: '15633',
    city: 'Forbes Road',
    state: 'PA',
    latitude: '40.358425',
    longitude: '-79.524654',
    timeZoneId: 'America/New_York',
  },
  '15634': {
    zip: '15634',
    city: 'Grapeville',
    state: 'PA',
    latitude: '40.322571',
    longitude: '-79.608097',
    timeZoneId: 'America/New_York',
  },
  '15635': {
    zip: '15635',
    city: 'Hannastown',
    state: 'PA',
    latitude: '40.348969',
    longitude: '-79.497991',
    timeZoneId: 'America/New_York',
  },
  '15636': {
    zip: '15636',
    city: 'Harrison City',
    state: 'PA',
    latitude: '40.382751',
    longitude: '-79.651174',
    timeZoneId: 'America/New_York',
  },
  '15637': {
    zip: '15637',
    city: 'Herminie',
    state: 'PA',
    latitude: '40.262202',
    longitude: '-79.715588',
    timeZoneId: 'America/New_York',
  },
  '15638': {
    zip: '15638',
    city: 'Hostetter',
    state: 'PA',
    latitude: '40.268335',
    longitude: '-79.398814',
    timeZoneId: 'America/New_York',
  },
  '15639': {
    zip: '15639',
    city: 'Hunker',
    state: 'PA',
    latitude: '40.205661',
    longitude: '-79.58577',
    timeZoneId: 'America/New_York',
  },
  '15640': {
    zip: '15640',
    city: 'Hutchinson',
    state: 'PA',
    latitude: '40.227397',
    longitude: '-79.732599',
    timeZoneId: 'America/New_York',
  },
  '15641': {
    zip: '15641',
    city: 'Hyde Park',
    state: 'PA',
    latitude: '40.631488',
    longitude: '-79.590143',
    timeZoneId: 'America/New_York',
  },
  '15642': {
    zip: '15642',
    city: 'Irwin',
    state: 'PA',
    latitude: '40.328956',
    longitude: '-79.721348',
    timeZoneId: 'America/New_York',
  },
  '15644': {
    zip: '15644',
    city: 'Jeannette',
    state: 'PA',
    latitude: '40.333621',
    longitude: '-79.614905',
    timeZoneId: 'America/New_York',
  },
  '15646': {
    zip: '15646',
    city: 'Jones Mills',
    state: 'PA',
    latitude: '40.066173',
    longitude: '-79.317064',
    timeZoneId: 'America/New_York',
  },
  '15647': {
    zip: '15647',
    city: 'Larimer',
    state: 'PA',
    latitude: '40.342614',
    longitude: '-79.725305',
    timeZoneId: 'America/New_York',
  },
  '15650': {
    zip: '15650',
    city: 'Latrobe',
    state: 'PA',
    latitude: '40.307482',
    longitude: '-79.383971',
    timeZoneId: 'America/New_York',
  },
  '15655': {
    zip: '15655',
    city: 'Laughlintown',
    state: 'PA',
    latitude: '40.190921',
    longitude: '-79.165971',
    timeZoneId: 'America/New_York',
  },
  '15656': {
    zip: '15656',
    city: 'Leechburg',
    state: 'PA',
    latitude: '40.629851',
    longitude: '-79.604848',
    timeZoneId: 'America/New_York',
  },
  '15658': {
    zip: '15658',
    city: 'Ligonier',
    state: 'PA',
    latitude: '40.22418',
    longitude: '-79.21674',
    timeZoneId: 'America/New_York',
  },
  '15660': {
    zip: '15660',
    city: 'Lowber',
    state: 'PA',
    latitude: '40.251892',
    longitude: '-79.754051',
    timeZoneId: 'America/New_York',
  },
  '15661': {
    zip: '15661',
    city: 'Loyalhanna',
    state: 'PA',
    latitude: '40.318587',
    longitude: '-79.354068',
    timeZoneId: 'America/New_York',
  },
  '15662': {
    zip: '15662',
    city: 'Luxor',
    state: 'PA',
    latitude: '40.335324',
    longitude: '-79.479293',
    timeZoneId: 'America/New_York',
  },
  '15663': {
    zip: '15663',
    city: 'Madison',
    state: 'PA',
    latitude: '40.248142',
    longitude: '-79.687152',
    timeZoneId: 'America/New_York',
  },
  '15664': {
    zip: '15664',
    city: 'Mammoth',
    state: 'PA',
    latitude: '40.323784',
    longitude: '-79.671735',
    timeZoneId: 'America/New_York',
  },
  '15665': {
    zip: '15665',
    city: 'Manor',
    state: 'PA',
    latitude: '40.334924',
    longitude: '-79.664623',
    timeZoneId: 'America/New_York',
  },
  '15666': {
    zip: '15666',
    city: 'Mount Pleasant',
    state: 'PA',
    latitude: '40.151156',
    longitude: '-79.54306',
    timeZoneId: 'America/New_York',
  },
  '15668': {
    zip: '15668',
    city: 'Murrysville',
    state: 'PA',
    latitude: '40.449365',
    longitude: '-79.68196',
    timeZoneId: 'America/New_York',
  },
  '15670': {
    zip: '15670',
    city: 'New Alexandria',
    state: 'PA',
    latitude: '40.414581',
    longitude: '-79.384148',
    timeZoneId: 'America/New_York',
  },
  '15671': {
    zip: '15671',
    city: 'New Derry',
    state: 'PA',
    latitude: '40.350897',
    longitude: '-79.315882',
    timeZoneId: 'America/New_York',
  },
  '15672': {
    zip: '15672',
    city: 'New Stanton',
    state: 'PA',
    latitude: '40.223191',
    longitude: '-79.605531',
    timeZoneId: 'America/New_York',
  },
  '15673': {
    zip: '15673',
    city: 'North Apollo',
    state: 'PA',
    latitude: '40.591389',
    longitude: '-79.55693',
    timeZoneId: 'America/New_York',
  },
  '15674': {
    zip: '15674',
    city: 'Norvelt',
    state: 'PA',
    latitude: '40.208944',
    longitude: '-79.496395',
    timeZoneId: 'America/New_York',
  },
  '15675': {
    zip: '15675',
    city: 'Penn',
    state: 'PA',
    latitude: '40.333591',
    longitude: '-79.643377',
    timeZoneId: 'America/New_York',
  },
  '15676': {
    zip: '15676',
    city: 'Pleasant Unity',
    state: 'PA',
    latitude: '40.237179',
    longitude: '-79.451409',
    timeZoneId: 'America/New_York',
  },
  '15677': {
    zip: '15677',
    city: 'Rector',
    state: 'PA',
    latitude: '40.175826',
    longitude: '-79.226021',
    timeZoneId: 'America/New_York',
  },
  '15678': {
    zip: '15678',
    city: 'Rillton',
    state: 'PA',
    latitude: '40.286776',
    longitude: '-79.715708',
    timeZoneId: 'America/New_York',
  },
  '15679': {
    zip: '15679',
    city: 'Ruffs Dale',
    state: 'PA',
    latitude: '40.154056',
    longitude: '-79.636506',
    timeZoneId: 'America/New_York',
  },
  '15680': {
    zip: '15680',
    city: 'Salina',
    state: 'PA',
    latitude: '40.524991',
    longitude: '-79.495127',
    timeZoneId: 'America/New_York',
  },
  '15681': {
    zip: '15681',
    city: 'Saltsburg',
    state: 'PA',
    latitude: '40.488961',
    longitude: '-79.449454',
    timeZoneId: 'America/New_York',
  },
  '15682': {
    zip: '15682',
    city: 'Schenley',
    state: 'PA',
    latitude: '40.685401',
    longitude: '-79.66234',
    timeZoneId: 'America/New_York',
  },
  '15683': {
    zip: '15683',
    city: 'Scottdale',
    state: 'PA',
    latitude: '40.104074',
    longitude: '-79.592703',
    timeZoneId: 'America/New_York',
  },
  '15684': {
    zip: '15684',
    city: 'Slickville',
    state: 'PA',
    latitude: '40.46575',
    longitude: '-79.504047',
    timeZoneId: 'America/New_York',
  },
  '15685': {
    zip: '15685',
    city: 'Southwest',
    state: 'PA',
    latitude: '40.149546',
    longitude: '-79.541551',
    timeZoneId: 'America/New_York',
  },
  '15686': {
    zip: '15686',
    city: 'Spring Church',
    state: 'PA',
    latitude: '40.622281',
    longitude: '-79.446624',
    timeZoneId: 'America/New_York',
  },
  '15687': {
    zip: '15687',
    city: 'Stahlstown',
    state: 'PA',
    latitude: '40.13522',
    longitude: '-79.297697',
    timeZoneId: 'America/New_York',
  },
  '15688': {
    zip: '15688',
    city: 'Tarrs',
    state: 'PA',
    latitude: '40.158722',
    longitude: '-79.572591',
    timeZoneId: 'America/New_York',
  },
  '15689': {
    zip: '15689',
    city: 'United',
    state: 'PA',
    latitude: '40.217074',
    longitude: '-79.4893',
    timeZoneId: 'America/New_York',
  },
  '15690': {
    zip: '15690',
    city: 'Vandergrift',
    state: 'PA',
    latitude: '40.598718',
    longitude: '-79.570417',
    timeZoneId: 'America/New_York',
  },
  '15691': {
    zip: '15691',
    city: 'Wendel',
    state: 'PA',
    latitude: '40.292363',
    longitude: '-79.687605',
    timeZoneId: 'America/New_York',
  },
  '15692': {
    zip: '15692',
    city: 'Westmoreland City',
    state: 'PA',
    latitude: '40.330504',
    longitude: '-79.679891',
    timeZoneId: 'America/New_York',
  },
  '15693': {
    zip: '15693',
    city: 'Whitney',
    state: 'PA',
    latitude: '40.253047',
    longitude: '-79.405238',
    timeZoneId: 'America/New_York',
  },
  '15695': {
    zip: '15695',
    city: 'Wyano',
    state: 'PA',
    latitude: '40.198702',
    longitude: '-79.697361',
    timeZoneId: 'America/New_York',
  },
  '15696': {
    zip: '15696',
    city: 'Youngstown',
    state: 'PA',
    latitude: '40.277657',
    longitude: '-79.368507',
    timeZoneId: 'America/New_York',
  },
  '15697': {
    zip: '15697',
    city: 'Youngwood',
    state: 'PA',
    latitude: '40.236948',
    longitude: '-79.577624',
    timeZoneId: 'America/New_York',
  },
  '15698': {
    zip: '15698',
    city: 'Yukon',
    state: 'PA',
    latitude: '40.21326',
    longitude: '-79.684888',
    timeZoneId: 'America/New_York',
  },
  '15701': {
    zip: '15701',
    city: 'Indiana',
    state: 'PA',
    latitude: '40.619062',
    longitude: '-79.160083',
    timeZoneId: 'America/New_York',
  },
  '15705': {
    zip: '15705',
    city: 'Indiana',
    state: 'PA',
    latitude: '40.616606',
    longitude: '-79.153926',
    timeZoneId: 'America/New_York',
  },
  '15710': {
    zip: '15710',
    city: 'Alverda',
    state: 'PA',
    latitude: '40.629375',
    longitude: '-78.85826',
    timeZoneId: 'America/New_York',
  },
  '15711': {
    zip: '15711',
    city: 'Anita',
    state: 'PA',
    latitude: '41.169633',
    longitude: '-78.912621',
    timeZoneId: 'America/New_York',
  },
  '15712': {
    zip: '15712',
    city: 'Arcadia',
    state: 'PA',
    latitude: '40.7855',
    longitude: '-78.855219',
    timeZoneId: 'America/New_York',
  },
  '15713': {
    zip: '15713',
    city: 'Aultman',
    state: 'PA',
    latitude: '40.56792',
    longitude: '-79.264547',
    timeZoneId: 'America/New_York',
  },
  '15714': {
    zip: '15714',
    city: 'Northern Cambria',
    state: 'PA',
    latitude: '40.663026',
    longitude: '-78.778938',
    timeZoneId: 'America/New_York',
  },
  '15715': {
    zip: '15715',
    city: 'Big Run',
    state: 'PA',
    latitude: '40.9705',
    longitude: '-78.879764',
    timeZoneId: 'America/New_York',
  },
  '15716': {
    zip: '15716',
    city: 'Black Lick',
    state: 'PA',
    latitude: '40.470582',
    longitude: '-79.199693',
    timeZoneId: 'America/New_York',
  },
  '15717': {
    zip: '15717',
    city: 'Blairsville',
    state: 'PA',
    latitude: '40.437223',
    longitude: '-79.250535',
    timeZoneId: 'America/New_York',
  },
  '15720': {
    zip: '15720',
    city: 'Brush Valley',
    state: 'PA',
    latitude: '40.535496',
    longitude: '-79.061267',
    timeZoneId: 'America/New_York',
  },
  '15721': {
    zip: '15721',
    city: 'Burnside',
    state: 'PA',
    latitude: '40.809399',
    longitude: '-78.792096',
    timeZoneId: 'America/New_York',
  },
  '15722': {
    zip: '15722',
    city: 'Carrolltown',
    state: 'PA',
    latitude: '40.595688',
    longitude: '-78.706957',
    timeZoneId: 'America/New_York',
  },
  '15723': {
    zip: '15723',
    city: 'Chambersville',
    state: 'PA',
    latitude: '40.705759',
    longitude: '-79.158229',
    timeZoneId: 'America/New_York',
  },
  '15724': {
    zip: '15724',
    city: 'Cherry Tree',
    state: 'PA',
    latitude: '40.768993',
    longitude: '-78.848701',
    timeZoneId: 'America/New_York',
  },
  '15725': {
    zip: '15725',
    city: 'Clarksburg',
    state: 'PA',
    latitude: '40.500035',
    longitude: '-79.369477',
    timeZoneId: 'America/New_York',
  },
  '15727': {
    zip: '15727',
    city: 'Clune',
    state: 'PA',
    latitude: '40.548374',
    longitude: '-79.30778',
    timeZoneId: 'America/New_York',
  },
  '15728': {
    zip: '15728',
    city: 'Clymer',
    state: 'PA',
    latitude: '40.670525',
    longitude: '-79.012538',
    timeZoneId: 'America/New_York',
  },
  '15729': {
    zip: '15729',
    city: 'Commodore',
    state: 'PA',
    latitude: '40.697107',
    longitude: '-78.898421',
    timeZoneId: 'America/New_York',
  },
  '15730': {
    zip: '15730',
    city: 'Coolspring',
    state: 'PA',
    latitude: '40.964684',
    longitude: '-78.925325',
    timeZoneId: 'America/New_York',
  },
  '15731': {
    zip: '15731',
    city: 'Coral',
    state: 'PA',
    latitude: '40.502807',
    longitude: '-79.17697',
    timeZoneId: 'America/New_York',
  },
  '15732': {
    zip: '15732',
    city: 'Creekside',
    state: 'PA',
    latitude: '40.682141',
    longitude: '-79.214813',
    timeZoneId: 'America/New_York',
  },
  '15733': {
    zip: '15733',
    city: 'De Lancey',
    state: 'PA',
    latitude: '40.983892',
    longitude: '-78.973673',
    timeZoneId: 'America/New_York',
  },
  '15734': {
    zip: '15734',
    city: 'Dixonville',
    state: 'PA',
    latitude: '40.714085',
    longitude: '-79.005137',
    timeZoneId: 'America/New_York',
  },
  '15736': {
    zip: '15736',
    city: 'Elderton',
    state: 'PA',
    latitude: '40.693042',
    longitude: '-79.339389',
    timeZoneId: 'America/New_York',
  },
  '15737': {
    zip: '15737',
    city: 'Elmora',
    state: 'PA',
    latitude: '40.600755',
    longitude: '-78.747979',
    timeZoneId: 'America/New_York',
  },
  '15738': {
    zip: '15738',
    city: 'Emeigh',
    state: 'PA',
    latitude: '40.694023',
    longitude: '-78.785167',
    timeZoneId: 'America/New_York',
  },
  '15739': {
    zip: '15739',
    city: 'Ernest',
    state: 'PA',
    latitude: '40.678859',
    longitude: '-79.16458',
    timeZoneId: 'America/New_York',
  },
  '15741': {
    zip: '15741',
    city: 'Gipsy',
    state: 'PA',
    latitude: '40.807761',
    longitude: '-78.862651',
    timeZoneId: 'America/New_York',
  },
  '15742': {
    zip: '15742',
    city: 'Glen Campbell',
    state: 'PA',
    latitude: '40.858839',
    longitude: '-78.856834',
    timeZoneId: 'America/New_York',
  },
  '15744': {
    zip: '15744',
    city: 'Hamilton',
    state: 'PA',
    latitude: '40.920264',
    longitude: '-79.095187',
    timeZoneId: 'America/New_York',
  },
  '15745': {
    zip: '15745',
    city: 'Heilwood',
    state: 'PA',
    latitude: '40.622231',
    longitude: '-78.914064',
    timeZoneId: 'America/New_York',
  },
  '15746': {
    zip: '15746',
    city: 'Hillsdale',
    state: 'PA',
    latitude: '40.7547',
    longitude: '-78.884292',
    timeZoneId: 'America/New_York',
  },
  '15747': {
    zip: '15747',
    city: 'Home',
    state: 'PA',
    latitude: '40.752433',
    longitude: '-79.146315',
    timeZoneId: 'America/New_York',
  },
  '15748': {
    zip: '15748',
    city: 'Homer City',
    state: 'PA',
    latitude: '40.52592',
    longitude: '-79.16214',
    timeZoneId: 'America/New_York',
  },
  '15750': {
    zip: '15750',
    city: 'Josephine',
    state: 'PA',
    latitude: '40.47545',
    longitude: '-79.182367',
    timeZoneId: 'America/New_York',
  },
  '15752': {
    zip: '15752',
    city: 'Kent',
    state: 'PA',
    latitude: '40.541631',
    longitude: '-79.283176',
    timeZoneId: 'America/New_York',
  },
  '15753': {
    zip: '15753',
    city: 'La Jose',
    state: 'PA',
    latitude: '40.81629',
    longitude: '-78.625144',
    timeZoneId: 'America/New_York',
  },
  '15754': {
    zip: '15754',
    city: 'Lucernemines',
    state: 'PA',
    latitude: '40.544689',
    longitude: '-79.1562',
    timeZoneId: 'America/New_York',
  },
  '15756': {
    zip: '15756',
    city: 'Mc Intyre',
    state: 'PA',
    latitude: '40.569874',
    longitude: '-79.299788',
    timeZoneId: 'America/New_York',
  },
  '15757': {
    zip: '15757',
    city: 'Mahaffey',
    state: 'PA',
    latitude: '40.90098',
    longitude: '-78.7257',
    timeZoneId: 'America/New_York',
  },
  '15758': {
    zip: '15758',
    city: 'Marchand',
    state: 'PA',
    latitude: '40.874307',
    longitude: '-79.045535',
    timeZoneId: 'America/New_York',
  },
  '15759': {
    zip: '15759',
    city: 'Marion Center',
    state: 'PA',
    latitude: '40.786688',
    longitude: '-79.03189',
    timeZoneId: 'America/New_York',
  },
  '15760': {
    zip: '15760',
    city: 'Marsteller',
    state: 'PA',
    latitude: '40.644933',
    longitude: '-78.812916',
    timeZoneId: 'America/New_York',
  },
  '15761': {
    zip: '15761',
    city: 'Mentcle',
    state: 'PA',
    latitude: '40.63795',
    longitude: '-78.88494',
    timeZoneId: 'America/New_York',
  },
  '15762': {
    zip: '15762',
    city: 'Nicktown',
    state: 'PA',
    latitude: '40.591372',
    longitude: '-78.810834',
    timeZoneId: 'America/New_York',
  },
  '15763': {
    zip: '15763',
    city: 'Northpoint',
    state: 'PA',
    latitude: '40.901792',
    longitude: '-79.12132',
    timeZoneId: 'America/New_York',
  },
  '15764': {
    zip: '15764',
    city: 'Oliveburg',
    state: 'PA',
    latitude: '41.000222',
    longitude: '-79.038536',
    timeZoneId: 'America/New_York',
  },
  '15765': {
    zip: '15765',
    city: 'Penn Run',
    state: 'PA',
    latitude: '40.563004',
    longitude: '-79.007721',
    timeZoneId: 'America/New_York',
  },
  '15767': {
    zip: '15767',
    city: 'Punxsutawney',
    state: 'PA',
    latitude: '40.944773',
    longitude: '-78.978341',
    timeZoneId: 'America/New_York',
  },
  '15770': {
    zip: '15770',
    city: 'Ringgold',
    state: 'PA',
    latitude: '40.993752',
    longitude: '-79.150635',
    timeZoneId: 'America/New_York',
  },
  '15771': {
    zip: '15771',
    city: 'Rochester Mills',
    state: 'PA',
    latitude: '40.82125',
    longitude: '-79.006895',
    timeZoneId: 'America/New_York',
  },
  '15772': {
    zip: '15772',
    city: 'Rossiter',
    state: 'PA',
    latitude: '40.893457',
    longitude: '-78.932651',
    timeZoneId: 'America/New_York',
  },
  '15773': {
    zip: '15773',
    city: 'Saint Benedict',
    state: 'PA',
    latitude: '40.608504',
    longitude: '-78.736951',
    timeZoneId: 'America/New_York',
  },
  '15774': {
    zip: '15774',
    city: 'Shelocta',
    state: 'PA',
    latitude: '40.640132',
    longitude: '-79.278556',
    timeZoneId: 'America/New_York',
  },
  '15775': {
    zip: '15775',
    city: 'Spangler',
    state: 'PA',
    latitude: '40.654687',
    longitude: '-78.777941',
    timeZoneId: 'America/New_York',
  },
  '15776': {
    zip: '15776',
    city: 'Sprankle Mills',
    state: 'PA',
    latitude: '41.021239',
    longitude: '-79.085612',
    timeZoneId: 'America/New_York',
  },
  '15777': {
    zip: '15777',
    city: 'Starford',
    state: 'PA',
    latitude: '40.699952',
    longitude: '-78.979228',
    timeZoneId: 'America/New_York',
  },
  '15778': {
    zip: '15778',
    city: 'Timblin',
    state: 'PA',
    latitude: '40.964525',
    longitude: '-79.198212',
    timeZoneId: 'America/New_York',
  },
  '15779': {
    zip: '15779',
    city: 'Torrance',
    state: 'PA',
    latitude: '40.420266',
    longitude: '-79.221407',
    timeZoneId: 'America/New_York',
  },
  '15780': {
    zip: '15780',
    city: 'Valier',
    state: 'PA',
    latitude: '40.922566',
    longitude: '-79.084851',
    timeZoneId: 'America/New_York',
  },
  '15781': {
    zip: '15781',
    city: 'Walston',
    state: 'PA',
    latitude: '40.964245',
    longitude: '-78.993278',
    timeZoneId: 'America/New_York',
  },
  '15783': {
    zip: '15783',
    city: 'West Lebanon',
    state: 'PA',
    latitude: '40.602258',
    longitude: '-79.354004',
    timeZoneId: 'America/New_York',
  },
  '15784': {
    zip: '15784',
    city: 'Worthville',
    state: 'PA',
    latitude: '41.026469',
    longitude: '-79.140778',
    timeZoneId: 'America/New_York',
  },
  '15801': {
    zip: '15801',
    city: 'Du Bois',
    state: 'PA',
    latitude: '41.124856',
    longitude: '-78.763496',
    timeZoneId: 'America/New_York',
  },
  '15821': {
    zip: '15821',
    city: 'Benezett',
    state: 'PA',
    latitude: '41.31596',
    longitude: '-78.333781',
    timeZoneId: 'America/New_York',
  },
  '15822': {
    zip: '15822',
    city: 'Brandy Camp',
    state: 'PA',
    latitude: '41.318909',
    longitude: '-78.68736',
    timeZoneId: 'America/New_York',
  },
  '15823': {
    zip: '15823',
    city: 'Brockport',
    state: 'PA',
    latitude: '41.300154',
    longitude: '-78.729739',
    timeZoneId: 'America/New_York',
  },
  '15824': {
    zip: '15824',
    city: 'Brockway',
    state: 'PA',
    latitude: '41.24753',
    longitude: '-78.76265',
    timeZoneId: 'America/New_York',
  },
  '15825': {
    zip: '15825',
    city: 'Brookville',
    state: 'PA',
    latitude: '41.1741',
    longitude: '-79.0674',
    timeZoneId: 'America/New_York',
  },
  '15827': {
    zip: '15827',
    city: 'Byrnedale',
    state: 'PA',
    latitude: '41.294787',
    longitude: '-78.50446',
    timeZoneId: 'America/New_York',
  },
  '15828': {
    zip: '15828',
    city: 'Clarington',
    state: 'PA',
    latitude: '41.325957',
    longitude: '-79.150142',
    timeZoneId: 'America/New_York',
  },
  '15829': {
    zip: '15829',
    city: 'Corsica',
    state: 'PA',
    latitude: '41.189361',
    longitude: '-79.172819',
    timeZoneId: 'America/New_York',
  },
  '15831': {
    zip: '15831',
    city: 'Dagus Mines',
    state: 'PA',
    latitude: '41.352895',
    longitude: '-78.606185',
    timeZoneId: 'America/New_York',
  },
  '15832': {
    zip: '15832',
    city: 'Driftwood',
    state: 'PA',
    latitude: '41.366719',
    longitude: '-78.167946',
    timeZoneId: 'America/New_York',
  },
  '15834': {
    zip: '15834',
    city: 'Emporium',
    state: 'PA',
    latitude: '41.513262',
    longitude: '-78.233322',
    timeZoneId: 'America/New_York',
  },
  '15840': {
    zip: '15840',
    city: 'Falls Creek',
    state: 'PA',
    latitude: '41.144291',
    longitude: '-78.806764',
    timeZoneId: 'America/New_York',
  },
  '15841': {
    zip: '15841',
    city: 'Force',
    state: 'PA',
    latitude: '41.260072',
    longitude: '-78.499772',
    timeZoneId: 'America/New_York',
  },
  '15845': {
    zip: '15845',
    city: 'Johnsonburg',
    state: 'PA',
    latitude: '41.493465',
    longitude: '-78.682904',
    timeZoneId: 'America/New_York',
  },
  '15846': {
    zip: '15846',
    city: 'Kersey',
    state: 'PA',
    latitude: '41.353722',
    longitude: '-78.614239',
    timeZoneId: 'America/New_York',
  },
  '15847': {
    zip: '15847',
    city: 'Knox Dale',
    state: 'PA',
    latitude: '41.162256',
    longitude: '-79.081754',
    timeZoneId: 'America/New_York',
  },
  '15848': {
    zip: '15848',
    city: 'Luthersburg',
    state: 'PA',
    latitude: '41.03697',
    longitude: '-78.741205',
    timeZoneId: 'America/New_York',
  },
  '15849': {
    zip: '15849',
    city: 'Penfield',
    state: 'PA',
    latitude: '41.163297',
    longitude: '-78.561053',
    timeZoneId: 'America/New_York',
  },
  '15851': {
    zip: '15851',
    city: 'Reynoldsville',
    state: 'PA',
    latitude: '41.095215',
    longitude: '-78.886043',
    timeZoneId: 'America/New_York',
  },
  '15853': {
    zip: '15853',
    city: 'Ridgway',
    state: 'PA',
    latitude: '41.45736',
    longitude: '-78.72666',
    timeZoneId: 'America/New_York',
  },
  '15856': {
    zip: '15856',
    city: 'Rockton',
    state: 'PA',
    latitude: '41.090624',
    longitude: '-78.634352',
    timeZoneId: 'America/New_York',
  },
  '15857': {
    zip: '15857',
    city: 'Saint Marys',
    state: 'PA',
    latitude: '41.433156',
    longitude: '-78.550932',
    timeZoneId: 'America/New_York',
  },
  '15860': {
    zip: '15860',
    city: 'Sigel',
    state: 'PA',
    latitude: '41.359757',
    longitude: '-78.990878',
    timeZoneId: 'America/New_York',
  },
  '15861': {
    zip: '15861',
    city: 'Sinnamahoning',
    state: 'PA',
    latitude: '41.384232',
    longitude: '-78.051775',
    timeZoneId: 'America/New_York',
  },
  '15863': {
    zip: '15863',
    city: 'Stump Creek',
    state: 'PA',
    latitude: '41.012719',
    longitude: '-78.835273',
    timeZoneId: 'America/New_York',
  },
  '15864': {
    zip: '15864',
    city: 'Summerville',
    state: 'PA',
    latitude: '41.089924',
    longitude: '-79.171113',
    timeZoneId: 'America/New_York',
  },
  '15865': {
    zip: '15865',
    city: 'Sykesville',
    state: 'PA',
    latitude: '41.05158',
    longitude: '-78.819613',
    timeZoneId: 'America/New_York',
  },
  '15866': {
    zip: '15866',
    city: 'Troutville',
    state: 'PA',
    latitude: '41.024504',
    longitude: '-78.785531',
    timeZoneId: 'America/New_York',
  },
  '15868': {
    zip: '15868',
    city: 'Weedville',
    state: 'PA',
    latitude: '41.266572',
    longitude: '-78.493803',
    timeZoneId: 'America/New_York',
  },
  '15870': {
    zip: '15870',
    city: 'Wilcox',
    state: 'PA',
    latitude: '41.573931',
    longitude: '-78.622841',
    timeZoneId: 'America/New_York',
  },
  '15901': {
    zip: '15901',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.325297',
    longitude: '-78.910953',
    timeZoneId: 'America/New_York',
  },
  '15902': {
    zip: '15902',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.309671',
    longitude: '-78.891648',
    timeZoneId: 'America/New_York',
  },
  '15904': {
    zip: '15904',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.286167',
    longitude: '-78.866154',
    timeZoneId: 'America/New_York',
  },
  '15905': {
    zip: '15905',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.308022',
    longitude: '-78.940967',
    timeZoneId: 'America/New_York',
  },
  '15906': {
    zip: '15906',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.348727',
    longitude: '-78.934313',
    timeZoneId: 'America/New_York',
  },
  '15907': {
    zip: '15907',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.328729',
    longitude: '-78.91891',
    timeZoneId: 'America/New_York',
  },
  '15909': {
    zip: '15909',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.357965',
    longitude: '-78.876651',
    timeZoneId: 'America/New_York',
  },
  '15915': {
    zip: '15915',
    city: 'Johnstown',
    state: 'PA',
    latitude: '40.308331',
    longitude: '-78.859292',
    timeZoneId: 'America/New_York',
  },
  '15920': {
    zip: '15920',
    city: 'Armagh',
    state: 'PA',
    latitude: '40.446149',
    longitude: '-79.01058',
    timeZoneId: 'America/New_York',
  },
  '15921': {
    zip: '15921',
    city: 'Beaverdale',
    state: 'PA',
    latitude: '40.315204',
    longitude: '-78.697283',
    timeZoneId: 'America/New_York',
  },
  '15922': {
    zip: '15922',
    city: 'Belsano',
    state: 'PA',
    latitude: '40.520963',
    longitude: '-78.870228',
    timeZoneId: 'America/New_York',
  },
  '15923': {
    zip: '15923',
    city: 'Bolivar',
    state: 'PA',
    latitude: '40.357223',
    longitude: '-79.170216',
    timeZoneId: 'America/New_York',
  },
  '15924': {
    zip: '15924',
    city: 'Cairnbrook',
    state: 'PA',
    latitude: '40.156081',
    longitude: '-78.7118',
    timeZoneId: 'America/New_York',
  },
  '15925': {
    zip: '15925',
    city: 'Cassandra',
    state: 'PA',
    latitude: '40.409397',
    longitude: '-78.641724',
    timeZoneId: 'America/New_York',
  },
  '15926': {
    zip: '15926',
    city: 'Central City',
    state: 'PA',
    latitude: '40.102784',
    longitude: '-78.812442',
    timeZoneId: 'America/New_York',
  },
  '15927': {
    zip: '15927',
    city: 'Colver',
    state: 'PA',
    latitude: '40.540795',
    longitude: '-78.790981',
    timeZoneId: 'America/New_York',
  },
  '15928': {
    zip: '15928',
    city: 'Davidsville',
    state: 'PA',
    latitude: '40.224988',
    longitude: '-78.93135',
    timeZoneId: 'America/New_York',
  },
  '15929': {
    zip: '15929',
    city: 'Dilltown',
    state: 'PA',
    latitude: '40.469995',
    longitude: '-79.002136',
    timeZoneId: 'America/New_York',
  },
  '15930': {
    zip: '15930',
    city: 'Dunlo',
    state: 'PA',
    latitude: '40.291746',
    longitude: '-78.718451',
    timeZoneId: 'America/New_York',
  },
  '15931': {
    zip: '15931',
    city: 'Ebensburg',
    state: 'PA',
    latitude: '40.483822',
    longitude: '-78.732605',
    timeZoneId: 'America/New_York',
  },
  '15934': {
    zip: '15934',
    city: 'Elton',
    state: 'PA',
    latitude: '40.279621',
    longitude: '-78.800745',
    timeZoneId: 'America/New_York',
  },
  '15935': {
    zip: '15935',
    city: 'Hollsopple',
    state: 'PA',
    latitude: '40.203772',
    longitude: '-78.924453',
    timeZoneId: 'America/New_York',
  },
  '15936': {
    zip: '15936',
    city: 'Hooversville',
    state: 'PA',
    latitude: '40.150303',
    longitude: '-78.912825',
    timeZoneId: 'America/New_York',
  },
  '15937': {
    zip: '15937',
    city: 'Jerome',
    state: 'PA',
    latitude: '40.207079',
    longitude: '-78.993522',
    timeZoneId: 'America/New_York',
  },
  '15938': {
    zip: '15938',
    city: 'Lilly',
    state: 'PA',
    latitude: '40.423002',
    longitude: '-78.628976',
    timeZoneId: 'America/New_York',
  },
  '15940': {
    zip: '15940',
    city: 'Loretto',
    state: 'PA',
    latitude: '40.506522',
    longitude: '-78.640492',
    timeZoneId: 'America/New_York',
  },
  '15942': {
    zip: '15942',
    city: 'Mineral Point',
    state: 'PA',
    latitude: '40.39329',
    longitude: '-78.85404',
    timeZoneId: 'America/New_York',
  },
  '15943': {
    zip: '15943',
    city: 'Nanty Glo',
    state: 'PA',
    latitude: '40.47068',
    longitude: '-78.840081',
    timeZoneId: 'America/New_York',
  },
  '15944': {
    zip: '15944',
    city: 'New Florence',
    state: 'PA',
    latitude: '40.330323',
    longitude: '-79.081322',
    timeZoneId: 'America/New_York',
  },
  '15945': {
    zip: '15945',
    city: 'Parkhill',
    state: 'PA',
    latitude: '40.326667',
    longitude: '-78.922222',
    timeZoneId: 'America/New_York',
  },
  '15946': {
    zip: '15946',
    city: 'Portage',
    state: 'PA',
    latitude: '40.39536',
    longitude: '-78.6906',
    timeZoneId: 'America/New_York',
  },
  '15948': {
    zip: '15948',
    city: 'Revloc',
    state: 'PA',
    latitude: '40.488532',
    longitude: '-78.763286',
    timeZoneId: 'America/New_York',
  },
  '15949': {
    zip: '15949',
    city: 'Robinson',
    state: 'PA',
    latitude: '40.414591',
    longitude: '-79.139751',
    timeZoneId: 'America/New_York',
  },
  '15951': {
    zip: '15951',
    city: 'Saint Michael',
    state: 'PA',
    latitude: '40.335698',
    longitude: '-78.781204',
    timeZoneId: 'America/New_York',
  },
  '15952': {
    zip: '15952',
    city: 'Salix',
    state: 'PA',
    latitude: '40.297776',
    longitude: '-78.772054',
    timeZoneId: 'America/New_York',
  },
  '15953': {
    zip: '15953',
    city: 'Seanor',
    state: 'PA',
    latitude: '40.207425',
    longitude: '-78.89745',
    timeZoneId: 'America/New_York',
  },
  '15954': {
    zip: '15954',
    city: 'Seward',
    state: 'PA',
    latitude: '40.40376',
    longitude: '-79.02522',
    timeZoneId: 'America/New_York',
  },
  '15955': {
    zip: '15955',
    city: 'Sidman',
    state: 'PA',
    latitude: '40.331988',
    longitude: '-78.735586',
    timeZoneId: 'America/New_York',
  },
  '15956': {
    zip: '15956',
    city: 'South Fork',
    state: 'PA',
    latitude: '40.365677',
    longitude: '-78.792021',
    timeZoneId: 'America/New_York',
  },
  '15957': {
    zip: '15957',
    city: 'Strongstown',
    state: 'PA',
    latitude: '40.559611',
    longitude: '-78.917699',
    timeZoneId: 'America/New_York',
  },
  '15958': {
    zip: '15958',
    city: 'Summerhill',
    state: 'PA',
    latitude: '40.375776',
    longitude: '-78.761417',
    timeZoneId: 'America/New_York',
  },
  '15959': {
    zip: '15959',
    city: 'Tire Hill',
    state: 'PA',
    latitude: '40.269128',
    longitude: '-78.917762',
    timeZoneId: 'America/New_York',
  },
  '15960': {
    zip: '15960',
    city: 'Twin Rocks',
    state: 'PA',
    latitude: '40.498305',
    longitude: '-78.865075',
    timeZoneId: 'America/New_York',
  },
  '15961': {
    zip: '15961',
    city: 'Vintondale',
    state: 'PA',
    latitude: '40.477503',
    longitude: '-78.908359',
    timeZoneId: 'America/New_York',
  },
  '15962': {
    zip: '15962',
    city: 'Wilmore',
    state: 'PA',
    latitude: '40.386427',
    longitude: '-78.720253',
    timeZoneId: 'America/New_York',
  },
  '15963': {
    zip: '15963',
    city: 'Windber',
    state: 'PA',
    latitude: '40.224797',
    longitude: '-78.809802',
    timeZoneId: 'America/New_York',
  },
  '16001': {
    zip: '16001',
    city: 'Butler',
    state: 'PA',
    latitude: '40.858537',
    longitude: '-79.902516',
    timeZoneId: 'America/New_York',
  },
  '16002': {
    zip: '16002',
    city: 'Butler',
    state: 'PA',
    latitude: '40.807304',
    longitude: '-79.866026',
    timeZoneId: 'America/New_York',
  },
  '16003': {
    zip: '16003',
    city: 'Butler',
    state: 'PA',
    latitude: '40.857115',
    longitude: '-79.894752',
    timeZoneId: 'America/New_York',
  },
  '16016': {
    zip: '16016',
    city: 'Boyers',
    state: 'PA',
    latitude: '41.106482',
    longitude: '-79.897055',
    timeZoneId: 'America/New_York',
  },
  '16017': {
    zip: '16017',
    city: 'Boyers',
    state: 'PA',
    latitude: '41.09144',
    longitude: '-79.90777',
    timeZoneId: 'America/New_York',
  },
  '16018': {
    zip: '16018',
    city: 'Boyers',
    state: 'PA',
    latitude: '41.09137',
    longitude: '-79.908947',
    timeZoneId: 'America/New_York',
  },
  '16020': {
    zip: '16020',
    city: 'Boyers',
    state: 'PA',
    latitude: '41.110539',
    longitude: '-79.900944',
    timeZoneId: 'America/New_York',
  },
  '16021': {
    zip: '16021',
    city: 'Branchton',
    state: 'PA',
    latitude: '41.070185',
    longitude: '-79.986051',
    timeZoneId: 'America/New_York',
  },
  '16022': {
    zip: '16022',
    city: 'Bruin',
    state: 'PA',
    latitude: '41.054644',
    longitude: '-79.729304',
    timeZoneId: 'America/New_York',
  },
  '16023': {
    zip: '16023',
    city: 'Cabot',
    state: 'PA',
    latitude: '40.778889',
    longitude: '-79.773333',
    timeZoneId: 'America/New_York',
  },
  '16024': {
    zip: '16024',
    city: 'Callery',
    state: 'PA',
    latitude: '40.740036',
    longitude: '-80.035826',
    timeZoneId: 'America/New_York',
  },
  '16025': {
    zip: '16025',
    city: 'Chicora',
    state: 'PA',
    latitude: '40.948847',
    longitude: '-79.76435',
    timeZoneId: 'America/New_York',
  },
  '16027': {
    zip: '16027',
    city: 'Connoquenessing',
    state: 'PA',
    latitude: '40.814001',
    longitude: '-80.014343',
    timeZoneId: 'America/New_York',
  },
  '16028': {
    zip: '16028',
    city: 'East Brady',
    state: 'PA',
    latitude: '41.221154',
    longitude: '-79.439479',
    timeZoneId: 'America/New_York',
  },
  '16029': {
    zip: '16029',
    city: 'East Butler',
    state: 'PA',
    latitude: '40.880385',
    longitude: '-79.84527',
    timeZoneId: 'America/New_York',
  },
  '16030': {
    zip: '16030',
    city: 'Eau Claire',
    state: 'PA',
    latitude: '41.1338',
    longitude: '-79.795252',
    timeZoneId: 'America/New_York',
  },
  '16033': {
    zip: '16033',
    city: 'Evans City',
    state: 'PA',
    latitude: '40.790505',
    longitude: '-80.043651',
    timeZoneId: 'America/New_York',
  },
  '16034': {
    zip: '16034',
    city: 'Fenelton',
    state: 'PA',
    latitude: '40.861011',
    longitude: '-79.715212',
    timeZoneId: 'America/New_York',
  },
  '16035': {
    zip: '16035',
    city: 'Forestville',
    state: 'PA',
    latitude: '41.105975',
    longitude: '-80.00686',
    timeZoneId: 'America/New_York',
  },
  '16036': {
    zip: '16036',
    city: 'Foxburg',
    state: 'PA',
    latitude: '41.155559',
    longitude: '-79.653411',
    timeZoneId: 'America/New_York',
  },
  '16037': {
    zip: '16037',
    city: 'Harmony',
    state: 'PA',
    latitude: '40.852733',
    longitude: '-80.128984',
    timeZoneId: 'America/New_York',
  },
  '16038': {
    zip: '16038',
    city: 'Harrisville',
    state: 'PA',
    latitude: '41.136102',
    longitude: '-80.004545',
    timeZoneId: 'America/New_York',
  },
  '16039': {
    zip: '16039',
    city: 'Herman',
    state: 'PA',
    latitude: '40.840488',
    longitude: '-79.811967',
    timeZoneId: 'America/New_York',
  },
  '16040': {
    zip: '16040',
    city: 'Hilliards',
    state: 'PA',
    latitude: '41.091111',
    longitude: '-79.848962',
    timeZoneId: 'America/New_York',
  },
  '16041': {
    zip: '16041',
    city: 'Karns City',
    state: 'PA',
    latitude: '41.009844',
    longitude: '-79.704538',
    timeZoneId: 'America/New_York',
  },
  '16045': {
    zip: '16045',
    city: 'Lyndora',
    state: 'PA',
    latitude: '40.853036',
    longitude: '-79.917677',
    timeZoneId: 'America/New_York',
  },
  '16046': {
    zip: '16046',
    city: 'Mars',
    state: 'PA',
    latitude: '40.69778',
    longitude: '-80.065812',
    timeZoneId: 'America/New_York',
  },
  '16048': {
    zip: '16048',
    city: 'North Washington',
    state: 'PA',
    latitude: '41.046265',
    longitude: '-79.815559',
    timeZoneId: 'America/New_York',
  },
  '16049': {
    zip: '16049',
    city: 'Parker',
    state: 'PA',
    latitude: '41.109388',
    longitude: '-79.694213',
    timeZoneId: 'America/New_York',
  },
  '16050': {
    zip: '16050',
    city: 'Petrolia',
    state: 'PA',
    latitude: '41.043097',
    longitude: '-79.765048',
    timeZoneId: 'America/New_York',
  },
  '16051': {
    zip: '16051',
    city: 'Portersville',
    state: 'PA',
    latitude: '40.961226',
    longitude: '-80.153974',
    timeZoneId: 'America/New_York',
  },
  '16052': {
    zip: '16052',
    city: 'Prospect',
    state: 'PA',
    latitude: '40.906532',
    longitude: '-80.072945',
    timeZoneId: 'America/New_York',
  },
  '16053': {
    zip: '16053',
    city: 'Renfrew',
    state: 'PA',
    latitude: '40.813319',
    longitude: '-79.980066',
    timeZoneId: 'America/New_York',
  },
  '16054': {
    zip: '16054',
    city: 'Saint Petersburg',
    state: 'PA',
    latitude: '41.158592',
    longitude: '-79.655355',
    timeZoneId: 'America/New_York',
  },
  '16055': {
    zip: '16055',
    city: 'Sarver',
    state: 'PA',
    latitude: '40.712165',
    longitude: '-79.750278',
    timeZoneId: 'America/New_York',
  },
  '16056': {
    zip: '16056',
    city: 'Saxonburg',
    state: 'PA',
    latitude: '40.725323',
    longitude: '-79.839817',
    timeZoneId: 'America/New_York',
  },
  '16057': {
    zip: '16057',
    city: 'Slippery Rock',
    state: 'PA',
    latitude: '41.063241',
    longitude: '-80.053604',
    timeZoneId: 'America/New_York',
  },
  '16058': {
    zip: '16058',
    city: 'Turkey City',
    state: 'PA',
    latitude: '41.187943',
    longitude: '-79.613578',
    timeZoneId: 'America/New_York',
  },
  '16059': {
    zip: '16059',
    city: 'Valencia',
    state: 'PA',
    latitude: '40.703547',
    longitude: '-79.917583',
    timeZoneId: 'America/New_York',
  },
  '16061': {
    zip: '16061',
    city: 'West Sunbury',
    state: 'PA',
    latitude: '41.015217',
    longitude: '-79.876934',
    timeZoneId: 'America/New_York',
  },
  '16063': {
    zip: '16063',
    city: 'Zelienople',
    state: 'PA',
    latitude: '40.774935',
    longitude: '-80.119536',
    timeZoneId: 'America/New_York',
  },
  '16066': {
    zip: '16066',
    city: 'Cranberry Township',
    state: 'PA',
    latitude: '40.707441',
    longitude: '-80.107217',
    timeZoneId: 'America/New_York',
  },
  '16101': {
    zip: '16101',
    city: 'New Castle',
    state: 'PA',
    latitude: '40.989418',
    longitude: '-80.336151',
    timeZoneId: 'America/New_York',
  },
  '16102': {
    zip: '16102',
    city: 'New Castle',
    state: 'PA',
    latitude: '40.972448',
    longitude: '-80.37159',
    timeZoneId: 'America/New_York',
  },
  '16103': {
    zip: '16103',
    city: 'New Castle',
    state: 'PA',
    latitude: '40.992916',
    longitude: '-80.294581',
    timeZoneId: 'America/New_York',
  },
  '16105': {
    zip: '16105',
    city: 'New Castle',
    state: 'PA',
    latitude: '41.03119',
    longitude: '-80.343765',
    timeZoneId: 'America/New_York',
  },
  '16107': {
    zip: '16107',
    city: 'New Castle',
    state: 'PA',
    latitude: '40.988668',
    longitude: '-80.293105',
    timeZoneId: 'America/New_York',
  },
  '16108': {
    zip: '16108',
    city: 'New Castle',
    state: 'PA',
    latitude: '40.988263',
    longitude: '-80.295406',
    timeZoneId: 'America/New_York',
  },
  '16110': {
    zip: '16110',
    city: 'Adamsville',
    state: 'PA',
    latitude: '41.630097',
    longitude: '-80.210844',
    timeZoneId: 'America/New_York',
  },
  '16111': {
    zip: '16111',
    city: 'Atlantic',
    state: 'PA',
    latitude: '41.533678',
    longitude: '-80.277496',
    timeZoneId: 'America/New_York',
  },
  '16112': {
    zip: '16112',
    city: 'Bessemer',
    state: 'PA',
    latitude: '40.977035',
    longitude: '-80.493394',
    timeZoneId: 'America/New_York',
  },
  '16113': {
    zip: '16113',
    city: 'Clark',
    state: 'PA',
    latitude: '41.279695',
    longitude: '-80.423889',
    timeZoneId: 'America/New_York',
  },
  '16114': {
    zip: '16114',
    city: 'Clarks Mills',
    state: 'PA',
    latitude: '41.434108',
    longitude: '-80.32843',
    timeZoneId: 'America/New_York',
  },
  '16115': {
    zip: '16115',
    city: 'Darlington',
    state: 'PA',
    latitude: '40.766528',
    longitude: '-80.46653',
    timeZoneId: 'America/New_York',
  },
  '16116': {
    zip: '16116',
    city: 'Edinburg',
    state: 'PA',
    latitude: '41.038058',
    longitude: '-80.485481',
    timeZoneId: 'America/New_York',
  },
  '16117': {
    zip: '16117',
    city: 'Ellwood City',
    state: 'PA',
    latitude: '40.861599',
    longitude: '-80.268252',
    timeZoneId: 'America/New_York',
  },
  '16120': {
    zip: '16120',
    city: 'Enon Valley',
    state: 'PA',
    latitude: '40.841548',
    longitude: '-80.460448',
    timeZoneId: 'America/New_York',
  },
  '16121': {
    zip: '16121',
    city: 'Farrell',
    state: 'PA',
    latitude: '41.211841',
    longitude: '-80.49052',
    timeZoneId: 'America/New_York',
  },
  '16123': {
    zip: '16123',
    city: 'Fombell',
    state: 'PA',
    latitude: '40.813183',
    longitude: '-80.198449',
    timeZoneId: 'America/New_York',
  },
  '16124': {
    zip: '16124',
    city: 'Fredonia',
    state: 'PA',
    latitude: '41.323452',
    longitude: '-80.261997',
    timeZoneId: 'America/New_York',
  },
  '16125': {
    zip: '16125',
    city: 'Greenville',
    state: 'PA',
    latitude: '41.40486',
    longitude: '-80.36958',
    timeZoneId: 'America/New_York',
  },
  '16127': {
    zip: '16127',
    city: 'Grove City',
    state: 'PA',
    latitude: '41.162731',
    longitude: '-80.085407',
    timeZoneId: 'America/New_York',
  },
  '16130': {
    zip: '16130',
    city: 'Hadley',
    state: 'PA',
    latitude: '41.436056',
    longitude: '-80.265258',
    timeZoneId: 'America/New_York',
  },
  '16131': {
    zip: '16131',
    city: 'Hartstown',
    state: 'PA',
    latitude: '41.555386',
    longitude: '-80.440331',
    timeZoneId: 'America/New_York',
  },
  '16132': {
    zip: '16132',
    city: 'Hillsville',
    state: 'PA',
    latitude: '41.007866',
    longitude: '-80.500237',
    timeZoneId: 'America/New_York',
  },
  '16133': {
    zip: '16133',
    city: 'Jackson Center',
    state: 'PA',
    latitude: '41.276916',
    longitude: '-80.083724',
    timeZoneId: 'America/New_York',
  },
  '16134': {
    zip: '16134',
    city: 'Jamestown',
    state: 'PA',
    latitude: '41.49792',
    longitude: '-80.44902',
    timeZoneId: 'America/New_York',
  },
  '16136': {
    zip: '16136',
    city: 'Koppel',
    state: 'PA',
    latitude: '40.836741',
    longitude: '-80.322809',
    timeZoneId: 'America/New_York',
  },
  '16137': {
    zip: '16137',
    city: 'Mercer',
    state: 'PA',
    latitude: '41.246287',
    longitude: '-80.227378',
    timeZoneId: 'America/New_York',
  },
  '16140': {
    zip: '16140',
    city: 'New Bedford',
    state: 'PA',
    latitude: '41.095717',
    longitude: '-80.505156',
    timeZoneId: 'America/New_York',
  },
  '16141': {
    zip: '16141',
    city: 'New Galilee',
    state: 'PA',
    latitude: '40.832918',
    longitude: '-80.398275',
    timeZoneId: 'America/New_York',
  },
  '16142': {
    zip: '16142',
    city: 'New Wilmington',
    state: 'PA',
    latitude: '41.118893',
    longitude: '-80.336897',
    timeZoneId: 'America/New_York',
  },
  '16143': {
    zip: '16143',
    city: 'Pulaski',
    state: 'PA',
    latitude: '41.089529',
    longitude: '-80.441994',
    timeZoneId: 'America/New_York',
  },
  '16145': {
    zip: '16145',
    city: 'Sandy Lake',
    state: 'PA',
    latitude: '41.349979',
    longitude: '-80.084405',
    timeZoneId: 'America/New_York',
  },
  '16146': {
    zip: '16146',
    city: 'Sharon',
    state: 'PA',
    latitude: '41.231776',
    longitude: '-80.490019',
    timeZoneId: 'America/New_York',
  },
  '16148': {
    zip: '16148',
    city: 'Hermitage',
    state: 'PA',
    latitude: '41.22996',
    longitude: '-80.4405',
    timeZoneId: 'America/New_York',
  },
  '16150': {
    zip: '16150',
    city: 'Sharpsville',
    state: 'PA',
    latitude: '41.268681',
    longitude: '-80.455152',
    timeZoneId: 'America/New_York',
  },
  '16151': {
    zip: '16151',
    city: 'Sheakleyville',
    state: 'PA',
    latitude: '41.444815',
    longitude: '-80.208292',
    timeZoneId: 'America/New_York',
  },
  '16153': {
    zip: '16153',
    city: 'Stoneboro',
    state: 'PA',
    latitude: '41.329861',
    longitude: '-80.115692',
    timeZoneId: 'America/New_York',
  },
  '16154': {
    zip: '16154',
    city: 'Transfer',
    state: 'PA',
    latitude: '41.330766',
    longitude: '-80.441553',
    timeZoneId: 'America/New_York',
  },
  '16155': {
    zip: '16155',
    city: 'Villa Maria',
    state: 'PA',
    latitude: '41.072335',
    longitude: '-80.510213',
    timeZoneId: 'America/New_York',
  },
  '16156': {
    zip: '16156',
    city: 'Volant',
    state: 'PA',
    latitude: '41.087993',
    longitude: '-80.223055',
    timeZoneId: 'America/New_York',
  },
  '16157': {
    zip: '16157',
    city: 'Wampum',
    state: 'PA',
    latitude: '40.895009',
    longitude: '-80.344547',
    timeZoneId: 'America/New_York',
  },
  '16159': {
    zip: '16159',
    city: 'West Middlesex',
    state: 'PA',
    latitude: '41.184971',
    longitude: '-80.454618',
    timeZoneId: 'America/New_York',
  },
  '16160': {
    zip: '16160',
    city: 'West Pittsburg',
    state: 'PA',
    latitude: '40.93374',
    longitude: '-80.363091',
    timeZoneId: 'America/New_York',
  },
  '16161': {
    zip: '16161',
    city: 'Wheatland',
    state: 'PA',
    latitude: '41.201592',
    longitude: '-80.492161',
    timeZoneId: 'America/New_York',
  },
  '16172': {
    zip: '16172',
    city: 'New Wilmington',
    state: 'PA',
    latitude: '41.12271',
    longitude: '-80.336002',
    timeZoneId: 'America/New_York',
  },
  '16201': {
    zip: '16201',
    city: 'Kittanning',
    state: 'PA',
    latitude: '40.818568',
    longitude: '-79.52479',
    timeZoneId: 'America/New_York',
  },
  '16210': {
    zip: '16210',
    city: 'Adrian',
    state: 'PA',
    latitude: '40.903141',
    longitude: '-79.507012',
    timeZoneId: 'America/New_York',
  },
  '16211': {
    zip: '16211',
    city: 'Beyer',
    state: 'PA',
    latitude: '40.784514',
    longitude: '-79.203666',
    timeZoneId: 'America/New_York',
  },
  '16212': {
    zip: '16212',
    city: 'Cadogan',
    state: 'PA',
    latitude: '40.753301',
    longitude: '-79.583737',
    timeZoneId: 'America/New_York',
  },
  '16213': {
    zip: '16213',
    city: 'Callensburg',
    state: 'PA',
    latitude: '41.124635',
    longitude: '-79.561',
    timeZoneId: 'America/New_York',
  },
  '16214': {
    zip: '16214',
    city: 'Clarion',
    state: 'PA',
    latitude: '41.211652',
    longitude: '-79.382697',
    timeZoneId: 'America/New_York',
  },
  '16217': {
    zip: '16217',
    city: 'Cooksburg',
    state: 'PA',
    latitude: '41.337377',
    longitude: '-79.188795',
    timeZoneId: 'America/New_York',
  },
  '16218': {
    zip: '16218',
    city: 'Cowansville',
    state: 'PA',
    latitude: '40.929888',
    longitude: '-79.590168',
    timeZoneId: 'America/New_York',
  },
  '16220': {
    zip: '16220',
    city: 'Crown',
    state: 'PA',
    latitude: '41.392264',
    longitude: '-79.268025',
    timeZoneId: 'America/New_York',
  },
  '16221': {
    zip: '16221',
    city: 'Curllsville',
    state: 'PA',
    latitude: '41.22037',
    longitude: '-79.505296',
    timeZoneId: 'America/New_York',
  },
  '16222': {
    zip: '16222',
    city: 'Dayton',
    state: 'PA',
    latitude: '40.878895',
    longitude: '-79.240426',
    timeZoneId: 'America/New_York',
  },
  '16223': {
    zip: '16223',
    city: 'Distant',
    state: 'PA',
    latitude: '40.973111',
    longitude: '-79.357155',
    timeZoneId: 'America/New_York',
  },
  '16224': {
    zip: '16224',
    city: 'Fairmount City',
    state: 'PA',
    latitude: '41.06618',
    longitude: '-79.273214',
    timeZoneId: 'America/New_York',
  },
  '16225': {
    zip: '16225',
    city: 'Fisher',
    state: 'PA',
    latitude: '41.266056',
    longitude: '-79.251978',
    timeZoneId: 'America/New_York',
  },
  '16226': {
    zip: '16226',
    city: 'Ford City',
    state: 'PA',
    latitude: '40.765717',
    longitude: '-79.528243',
    timeZoneId: 'America/New_York',
  },
  '16228': {
    zip: '16228',
    city: 'Ford Cliff',
    state: 'PA',
    latitude: '40.760255',
    longitude: '-79.536766',
    timeZoneId: 'America/New_York',
  },
  '16229': {
    zip: '16229',
    city: 'Freeport',
    state: 'PA',
    latitude: '40.716671',
    longitude: '-79.659656',
    timeZoneId: 'America/New_York',
  },
  '16230': {
    zip: '16230',
    city: 'Hawthorn',
    state: 'PA',
    latitude: '41.015961',
    longitude: '-79.279887',
    timeZoneId: 'America/New_York',
  },
  '16232': {
    zip: '16232',
    city: 'Knox',
    state: 'PA',
    latitude: '41.238308',
    longitude: '-79.542215',
    timeZoneId: 'America/New_York',
  },
  '16233': {
    zip: '16233',
    city: 'Leeper',
    state: 'PA',
    latitude: '41.360556',
    longitude: '-79.293356',
    timeZoneId: 'America/New_York',
  },
  '16234': {
    zip: '16234',
    city: 'Limestone',
    state: 'PA',
    latitude: '41.136206',
    longitude: '-79.295772',
    timeZoneId: 'America/New_York',
  },
  '16235': {
    zip: '16235',
    city: 'Lucinda',
    state: 'PA',
    latitude: '41.31087',
    longitude: '-79.384903',
    timeZoneId: 'America/New_York',
  },
  '16236': {
    zip: '16236',
    city: 'Mc Grann',
    state: 'PA',
    latitude: '40.781649',
    longitude: '-79.522667',
    timeZoneId: 'America/New_York',
  },
  '16238': {
    zip: '16238',
    city: 'Manorville',
    state: 'PA',
    latitude: '40.787779',
    longitude: '-79.521007',
    timeZoneId: 'America/New_York',
  },
  '16239': {
    zip: '16239',
    city: 'Marienville',
    state: 'PA',
    latitude: '41.471105',
    longitude: '-79.12533',
    timeZoneId: 'America/New_York',
  },
  '16240': {
    zip: '16240',
    city: 'Mayport',
    state: 'PA',
    latitude: '40.976669',
    longitude: '-79.25127',
    timeZoneId: 'America/New_York',
  },
  '16242': {
    zip: '16242',
    city: 'New Bethlehem',
    state: 'PA',
    latitude: '41.006608',
    longitude: '-79.33119',
    timeZoneId: 'America/New_York',
  },
  '16244': {
    zip: '16244',
    city: 'Nu Mine',
    state: 'PA',
    latitude: '40.800793',
    longitude: '-79.272037',
    timeZoneId: 'America/New_York',
  },
  '16245': {
    zip: '16245',
    city: 'Oak Ridge',
    state: 'PA',
    latitude: '41.009961',
    longitude: '-79.298811',
    timeZoneId: 'America/New_York',
  },
  '16246': {
    zip: '16246',
    city: 'Plumville',
    state: 'PA',
    latitude: '40.791984',
    longitude: '-79.177525',
    timeZoneId: 'America/New_York',
  },
  '16248': {
    zip: '16248',
    city: 'Rimersburg',
    state: 'PA',
    latitude: '41.041825',
    longitude: '-79.501697',
    timeZoneId: 'America/New_York',
  },
  '16249': {
    zip: '16249',
    city: 'Rural Valley',
    state: 'PA',
    latitude: '40.797436',
    longitude: '-79.320877',
    timeZoneId: 'America/New_York',
  },
  '16250': {
    zip: '16250',
    city: 'Sagamore',
    state: 'PA',
    latitude: '40.78078',
    longitude: '-79.225637',
    timeZoneId: 'America/New_York',
  },
  '16253': {
    zip: '16253',
    city: 'Seminole',
    state: 'PA',
    latitude: '40.955566',
    longitude: '-79.341934',
    timeZoneId: 'America/New_York',
  },
  '16254': {
    zip: '16254',
    city: 'Shippenville',
    state: 'PA',
    latitude: '41.244548',
    longitude: '-79.431978',
    timeZoneId: 'America/New_York',
  },
  '16255': {
    zip: '16255',
    city: 'Sligo',
    state: 'PA',
    latitude: '41.109821',
    longitude: '-79.494153',
    timeZoneId: 'America/New_York',
  },
  '16256': {
    zip: '16256',
    city: 'Smicksburg',
    state: 'PA',
    latitude: '40.826103',
    longitude: '-79.16761',
    timeZoneId: 'America/New_York',
  },
  '16257': {
    zip: '16257',
    city: 'Snydersburg',
    state: 'PA',
    latitude: '41.334198',
    longitude: '-79.357913',
    timeZoneId: 'America/New_York',
  },
  '16258': {
    zip: '16258',
    city: 'Strattanville',
    state: 'PA',
    latitude: '41.257668',
    longitude: '-79.237595',
    timeZoneId: 'America/New_York',
  },
  '16259': {
    zip: '16259',
    city: 'Templeton',
    state: 'PA',
    latitude: '40.948599',
    longitude: '-79.452462',
    timeZoneId: 'America/New_York',
  },
  '16260': {
    zip: '16260',
    city: 'Vowinckel',
    state: 'PA',
    latitude: '41.372565',
    longitude: '-79.256668',
    timeZoneId: 'America/New_York',
  },
  '16261': {
    zip: '16261',
    city: 'Widnoon',
    state: 'PA',
    latitude: '40.964275',
    longitude: '-79.469826',
    timeZoneId: 'America/New_York',
  },
  '16262': {
    zip: '16262',
    city: 'Worthington',
    state: 'PA',
    latitude: '40.83588',
    longitude: '-79.64442',
    timeZoneId: 'America/New_York',
  },
  '16263': {
    zip: '16263',
    city: 'Yatesboro',
    state: 'PA',
    latitude: '40.798817',
    longitude: '-79.333413',
    timeZoneId: 'America/New_York',
  },
  '16301': {
    zip: '16301',
    city: 'Oil City',
    state: 'PA',
    latitude: '41.433864',
    longitude: '-79.705846',
    timeZoneId: 'America/New_York',
  },
  '16311': {
    zip: '16311',
    city: 'Carlton',
    state: 'PA',
    latitude: '41.481763',
    longitude: '-80.032308',
    timeZoneId: 'America/New_York',
  },
  '16312': {
    zip: '16312',
    city: 'Chandlers Valley',
    state: 'PA',
    latitude: '41.932606',
    longitude: '-79.304327',
    timeZoneId: 'America/New_York',
  },
  '16313': {
    zip: '16313',
    city: 'Clarendon',
    state: 'PA',
    latitude: '41.812195',
    longitude: '-79.157992',
    timeZoneId: 'America/New_York',
  },
  '16314': {
    zip: '16314',
    city: 'Cochranton',
    state: 'PA',
    latitude: '41.51684',
    longitude: '-80.049158',
    timeZoneId: 'America/New_York',
  },
  '16316': {
    zip: '16316',
    city: 'Conneaut Lake',
    state: 'PA',
    latitude: '41.62218',
    longitude: '-80.30952',
    timeZoneId: 'America/New_York',
  },
  '16317': {
    zip: '16317',
    city: 'Cooperstown',
    state: 'PA',
    latitude: '41.500971',
    longitude: '-79.871006',
    timeZoneId: 'America/New_York',
  },
  '16319': {
    zip: '16319',
    city: 'Cranberry',
    state: 'PA',
    latitude: '41.337921',
    longitude: '-79.721721',
    timeZoneId: 'America/New_York',
  },
  '16321': {
    zip: '16321',
    city: 'East Hickory',
    state: 'PA',
    latitude: '41.583889',
    longitude: '-79.401944',
    timeZoneId: 'America/New_York',
  },
  '16322': {
    zip: '16322',
    city: 'Endeavor',
    state: 'PA',
    latitude: '41.560795',
    longitude: '-79.37486',
    timeZoneId: 'America/New_York',
  },
  '16323': {
    zip: '16323',
    city: 'Franklin',
    state: 'PA',
    latitude: '41.38494',
    longitude: '-79.81314',
    timeZoneId: 'America/New_York',
  },
  '16326': {
    zip: '16326',
    city: 'Fryburg',
    state: 'PA',
    latitude: '41.367227',
    longitude: '-79.415303',
    timeZoneId: 'America/New_York',
  },
  '16327': {
    zip: '16327',
    city: 'Guys Mills',
    state: 'PA',
    latitude: '41.63423',
    longitude: '-79.955731',
    timeZoneId: 'America/New_York',
  },
  '16328': {
    zip: '16328',
    city: 'Hydetown',
    state: 'PA',
    latitude: '41.651238',
    longitude: '-79.724503',
    timeZoneId: 'America/New_York',
  },
  '16329': {
    zip: '16329',
    city: 'Irvine',
    state: 'PA',
    latitude: '41.84463',
    longitude: '-79.274369',
    timeZoneId: 'America/New_York',
  },
  '16331': {
    zip: '16331',
    city: 'Kossuth',
    state: 'PA',
    latitude: '41.289342',
    longitude: '-79.591132',
    timeZoneId: 'America/New_York',
  },
  '16332': {
    zip: '16332',
    city: 'Lickingville',
    state: 'PA',
    latitude: '41.378581',
    longitude: '-79.370701',
    timeZoneId: 'America/New_York',
  },
  '16333': {
    zip: '16333',
    city: 'Ludlow',
    state: 'PA',
    latitude: '41.734068',
    longitude: '-78.919513',
    timeZoneId: 'America/New_York',
  },
  '16334': {
    zip: '16334',
    city: 'Marble',
    state: 'PA',
    latitude: '41.331223',
    longitude: '-79.444935',
    timeZoneId: 'America/New_York',
  },
  '16335': {
    zip: '16335',
    city: 'Meadville',
    state: 'PA',
    latitude: '41.64096',
    longitude: '-80.16972',
    timeZoneId: 'America/New_York',
  },
  '16340': {
    zip: '16340',
    city: 'Pittsfield',
    state: 'PA',
    latitude: '41.936865',
    longitude: '-79.383061',
    timeZoneId: 'America/New_York',
  },
  '16341': {
    zip: '16341',
    city: 'Pleasantville',
    state: 'PA',
    latitude: '41.589223',
    longitude: '-79.572581',
    timeZoneId: 'America/New_York',
  },
  '16342': {
    zip: '16342',
    city: 'Polk',
    state: 'PA',
    latitude: '41.340932',
    longitude: '-79.932203',
    timeZoneId: 'America/New_York',
  },
  '16343': {
    zip: '16343',
    city: 'Reno',
    state: 'PA',
    latitude: '41.410444',
    longitude: '-79.747292',
    timeZoneId: 'America/New_York',
  },
  '16344': {
    zip: '16344',
    city: 'Rouseville',
    state: 'PA',
    latitude: '41.470084',
    longitude: '-79.691752',
    timeZoneId: 'America/New_York',
  },
  '16345': {
    zip: '16345',
    city: 'Russell',
    state: 'PA',
    latitude: '41.94882',
    longitude: '-79.10448',
    timeZoneId: 'America/New_York',
  },
  '16346': {
    zip: '16346',
    city: 'Seneca',
    state: 'PA',
    latitude: '41.388298',
    longitude: '-79.705',
    timeZoneId: 'America/New_York',
  },
  '16347': {
    zip: '16347',
    city: 'Sheffield',
    state: 'PA',
    latitude: '41.699399',
    longitude: '-79.036324',
    timeZoneId: 'America/New_York',
  },
  '16350': {
    zip: '16350',
    city: 'Sugar Grove',
    state: 'PA',
    latitude: '41.95602',
    longitude: '-79.33272',
    timeZoneId: 'America/New_York',
  },
  '16351': {
    zip: '16351',
    city: 'Tidioute',
    state: 'PA',
    latitude: '41.684188',
    longitude: '-79.403836',
    timeZoneId: 'America/New_York',
  },
  '16352': {
    zip: '16352',
    city: 'Tiona',
    state: 'PA',
    latitude: '41.846172',
    longitude: '-79.145309',
    timeZoneId: 'America/New_York',
  },
  '16353': {
    zip: '16353',
    city: 'Tionesta',
    state: 'PA',
    latitude: '41.493407',
    longitude: '-79.458434',
    timeZoneId: 'America/New_York',
  },
  '16354': {
    zip: '16354',
    city: 'Titusville',
    state: 'PA',
    latitude: '41.624676',
    longitude: '-79.685623',
    timeZoneId: 'America/New_York',
  },
  '16360': {
    zip: '16360',
    city: 'Townville',
    state: 'PA',
    latitude: '41.682223',
    longitude: '-79.875279',
    timeZoneId: 'America/New_York',
  },
  '16361': {
    zip: '16361',
    city: 'Tylersburg',
    state: 'PA',
    latitude: '41.385417',
    longitude: '-79.323079',
    timeZoneId: 'America/New_York',
  },
  '16362': {
    zip: '16362',
    city: 'Utica',
    state: 'PA',
    latitude: '41.486308',
    longitude: '-79.9311',
    timeZoneId: 'America/New_York',
  },
  '16364': {
    zip: '16364',
    city: 'Venus',
    state: 'PA',
    latitude: '41.371566',
    longitude: '-79.505515',
    timeZoneId: 'America/New_York',
  },
  '16365': {
    zip: '16365',
    city: 'Warren',
    state: 'PA',
    latitude: '41.854253',
    longitude: '-79.159439',
    timeZoneId: 'America/New_York',
  },
  '16366': {
    zip: '16366',
    city: 'Warren',
    state: 'PA',
    latitude: '41.843956',
    longitude: '-79.141103',
    timeZoneId: 'America/New_York',
  },
  '16367': {
    zip: '16367',
    city: 'Warren',
    state: 'PA',
    latitude: '41.84644',
    longitude: '-79.146417',
    timeZoneId: 'America/New_York',
  },
  '16368': {
    zip: '16368',
    city: 'Warren',
    state: 'PA',
    latitude: '41.848758',
    longitude: '-79.146681',
    timeZoneId: 'America/New_York',
  },
  '16369': {
    zip: '16369',
    city: 'Warren',
    state: 'PA',
    latitude: '41.847491',
    longitude: '-79.145887',
    timeZoneId: 'America/New_York',
  },
  '16370': {
    zip: '16370',
    city: 'West Hickory',
    state: 'PA',
    latitude: '41.559158',
    longitude: '-79.529382',
    timeZoneId: 'America/New_York',
  },
  '16371': {
    zip: '16371',
    city: 'Youngsville',
    state: 'PA',
    latitude: '41.854699',
    longitude: '-79.318469',
    timeZoneId: 'America/New_York',
  },
  '16372': {
    zip: '16372',
    city: 'Clintonville',
    state: 'PA',
    latitude: '41.202094',
    longitude: '-79.872501',
    timeZoneId: 'America/New_York',
  },
  '16373': {
    zip: '16373',
    city: 'Emlenton',
    state: 'PA',
    latitude: '41.178788',
    longitude: '-79.709264',
    timeZoneId: 'America/New_York',
  },
  '16374': {
    zip: '16374',
    city: 'Kennerdell',
    state: 'PA',
    latitude: '41.284958',
    longitude: '-79.744073',
    timeZoneId: 'America/New_York',
  },
  '16375': {
    zip: '16375',
    city: 'Lamartine',
    state: 'PA',
    latitude: '41.221081',
    longitude: '-79.637892',
    timeZoneId: 'America/New_York',
  },
  '16388': {
    zip: '16388',
    city: 'Meadville',
    state: 'PA',
    latitude: '41.648956',
    longitude: '-80.147265',
    timeZoneId: 'America/New_York',
  },
  '16401': {
    zip: '16401',
    city: 'Albion',
    state: 'PA',
    latitude: '41.884381',
    longitude: '-80.379163',
    timeZoneId: 'America/New_York',
  },
  '16402': {
    zip: '16402',
    city: 'Bear Lake',
    state: 'PA',
    latitude: '41.968751',
    longitude: '-79.476934',
    timeZoneId: 'America/New_York',
  },
  '16403': {
    zip: '16403',
    city: 'Cambridge Springs',
    state: 'PA',
    latitude: '41.800913',
    longitude: '-80.061183',
    timeZoneId: 'America/New_York',
  },
  '16404': {
    zip: '16404',
    city: 'Centerville',
    state: 'PA',
    latitude: '41.724924',
    longitude: '-79.892407',
    timeZoneId: 'America/New_York',
  },
  '16405': {
    zip: '16405',
    city: 'Columbus',
    state: 'PA',
    latitude: '41.94061',
    longitude: '-79.58052',
    timeZoneId: 'America/New_York',
  },
  '16406': {
    zip: '16406',
    city: 'Conneautville',
    state: 'PA',
    latitude: '41.76121',
    longitude: '-80.36772',
    timeZoneId: 'America/New_York',
  },
  '16407': {
    zip: '16407',
    city: 'Corry',
    state: 'PA',
    latitude: '41.923367',
    longitude: '-79.657272',
    timeZoneId: 'America/New_York',
  },
  '16410': {
    zip: '16410',
    city: 'Cranesville',
    state: 'PA',
    latitude: '41.919',
    longitude: '-80.316949',
    timeZoneId: 'America/New_York',
  },
  '16411': {
    zip: '16411',
    city: 'East Springfield',
    state: 'PA',
    latitude: '41.98387',
    longitude: '-80.436629',
    timeZoneId: 'America/New_York',
  },
  '16412': {
    zip: '16412',
    city: 'Edinboro',
    state: 'PA',
    latitude: '41.885747',
    longitude: '-80.143902',
    timeZoneId: 'America/New_York',
  },
  '16413': {
    zip: '16413',
    city: 'Elgin',
    state: 'PA',
    latitude: '41.902099',
    longitude: '-79.744053',
    timeZoneId: 'America/New_York',
  },
  '16415': {
    zip: '16415',
    city: 'Fairview',
    state: 'PA',
    latitude: '42.040104',
    longitude: '-80.238339',
    timeZoneId: 'America/New_York',
  },
  '16416': {
    zip: '16416',
    city: 'Garland',
    state: 'PA',
    latitude: '41.816258',
    longitude: '-79.444502',
    timeZoneId: 'America/New_York',
  },
  '16417': {
    zip: '16417',
    city: 'Girard',
    state: 'PA',
    latitude: '41.987875',
    longitude: '-80.313199',
    timeZoneId: 'America/New_York',
  },
  '16420': {
    zip: '16420',
    city: 'Grand Valley',
    state: 'PA',
    latitude: '41.772126',
    longitude: '-79.548546',
    timeZoneId: 'America/New_York',
  },
  '16421': {
    zip: '16421',
    city: 'Harborcreek',
    state: 'PA',
    latitude: '42.170427',
    longitude: '-79.94233',
    timeZoneId: 'America/New_York',
  },
  '16422': {
    zip: '16422',
    city: 'Harmonsburg',
    state: 'PA',
    latitude: '41.660739',
    longitude: '-80.316811',
    timeZoneId: 'America/New_York',
  },
  '16423': {
    zip: '16423',
    city: 'Lake City',
    state: 'PA',
    latitude: '42.023251',
    longitude: '-80.342156',
    timeZoneId: 'America/New_York',
  },
  '16424': {
    zip: '16424',
    city: 'Linesville',
    state: 'PA',
    latitude: '41.6439',
    longitude: '-80.45208',
    timeZoneId: 'America/New_York',
  },
  '16426': {
    zip: '16426',
    city: 'Mc Kean',
    state: 'PA',
    latitude: '41.990841',
    longitude: '-80.144052',
    timeZoneId: 'America/New_York',
  },
  '16427': {
    zip: '16427',
    city: 'Mill Village',
    state: 'PA',
    latitude: '41.875887',
    longitude: '-79.967552',
    timeZoneId: 'America/New_York',
  },
  '16428': {
    zip: '16428',
    city: 'North East',
    state: 'PA',
    latitude: '42.201742',
    longitude: '-79.82757',
    timeZoneId: 'America/New_York',
  },
  '16430': {
    zip: '16430',
    city: 'North Springfield',
    state: 'PA',
    latitude: '41.999861',
    longitude: '-80.428728',
    timeZoneId: 'America/New_York',
  },
  '16432': {
    zip: '16432',
    city: 'Riceville',
    state: 'PA',
    latitude: '41.779227',
    longitude: '-79.799551',
    timeZoneId: 'America/New_York',
  },
  '16433': {
    zip: '16433',
    city: 'Saegertown',
    state: 'PA',
    latitude: '41.718121',
    longitude: '-80.142619',
    timeZoneId: 'America/New_York',
  },
  '16434': {
    zip: '16434',
    city: 'Spartansburg',
    state: 'PA',
    latitude: '41.826335',
    longitude: '-79.688422',
    timeZoneId: 'America/New_York',
  },
  '16435': {
    zip: '16435',
    city: 'Springboro',
    state: 'PA',
    latitude: '41.798277',
    longitude: '-80.369185',
    timeZoneId: 'America/New_York',
  },
  '16436': {
    zip: '16436',
    city: 'Spring Creek',
    state: 'PA',
    latitude: '41.844751',
    longitude: '-79.565546',
    timeZoneId: 'America/New_York',
  },
  '16438': {
    zip: '16438',
    city: 'Union City',
    state: 'PA',
    latitude: '41.924956',
    longitude: '-79.85653',
    timeZoneId: 'America/New_York',
  },
  '16440': {
    zip: '16440',
    city: 'Venango',
    state: 'PA',
    latitude: '41.945363',
    longitude: '-79.974932',
    timeZoneId: 'America/New_York',
  },
  '16441': {
    zip: '16441',
    city: 'Waterford',
    state: 'PA',
    latitude: '41.95111',
    longitude: '-79.987648',
    timeZoneId: 'America/New_York',
  },
  '16442': {
    zip: '16442',
    city: 'Wattsburg',
    state: 'PA',
    latitude: '42.025336',
    longitude: '-79.82494',
    timeZoneId: 'America/New_York',
  },
  '16443': {
    zip: '16443',
    city: 'West Springfield',
    state: 'PA',
    latitude: '41.939334',
    longitude: '-80.469219',
    timeZoneId: 'America/New_York',
  },
  '16444': {
    zip: '16444',
    city: 'Edinboro',
    state: 'PA',
    latitude: '41.87595',
    longitude: '-80.130328',
    timeZoneId: 'America/New_York',
  },
  '16475': {
    zip: '16475',
    city: 'Albion',
    state: 'PA',
    latitude: '41.892661',
    longitude: '-80.377278',
    timeZoneId: 'America/New_York',
  },
  '16501': {
    zip: '16501',
    city: 'Erie',
    state: 'PA',
    latitude: '42.123266',
    longitude: '-80.084213',
    timeZoneId: 'America/New_York',
  },
  '16502': {
    zip: '16502',
    city: 'Erie',
    state: 'PA',
    latitude: '42.111146',
    longitude: '-80.101825',
    timeZoneId: 'America/New_York',
  },
  '16503': {
    zip: '16503',
    city: 'Erie',
    state: 'PA',
    latitude: '42.124801',
    longitude: '-80.063248',
    timeZoneId: 'America/New_York',
  },
  '16504': {
    zip: '16504',
    city: 'Erie',
    state: 'PA',
    latitude: '42.111025',
    longitude: '-80.049695',
    timeZoneId: 'America/New_York',
  },
  '16505': {
    zip: '16505',
    city: 'Erie',
    state: 'PA',
    latitude: '42.096961',
    longitude: '-80.16988',
    timeZoneId: 'America/New_York',
  },
  '16506': {
    zip: '16506',
    city: 'Erie',
    state: 'PA',
    latitude: '42.072704',
    longitude: '-80.153452',
    timeZoneId: 'America/New_York',
  },
  '16507': {
    zip: '16507',
    city: 'Erie',
    state: 'PA',
    latitude: '42.1351',
    longitude: '-80.085131',
    timeZoneId: 'America/New_York',
  },
  '16508': {
    zip: '16508',
    city: 'Erie',
    state: 'PA',
    latitude: '42.097111',
    longitude: '-80.09407',
    timeZoneId: 'America/New_York',
  },
  '16509': {
    zip: '16509',
    city: 'Erie',
    state: 'PA',
    latitude: '42.072512',
    longitude: '-80.063237',
    timeZoneId: 'America/New_York',
  },
  '16510': {
    zip: '16510',
    city: 'Erie',
    state: 'PA',
    latitude: '42.140278',
    longitude: '-80.015278',
    timeZoneId: 'America/New_York',
  },
  '16511': {
    zip: '16511',
    city: 'Erie',
    state: 'PA',
    latitude: '42.157917',
    longitude: '-80.011344',
    timeZoneId: 'America/New_York',
  },
  '16512': {
    zip: '16512',
    city: 'Erie',
    state: 'PA',
    latitude: '42.108958',
    longitude: '-79.960153',
    timeZoneId: 'America/New_York',
  },
  '16514': {
    zip: '16514',
    city: 'Erie',
    state: 'PA',
    latitude: '42.109107',
    longitude: '-79.957242',
    timeZoneId: 'America/New_York',
  },
  '16515': {
    zip: '16515',
    city: 'Erie',
    state: 'PA',
    latitude: '42.107601',
    longitude: '-79.956036',
    timeZoneId: 'America/New_York',
  },
  '16522': {
    zip: '16522',
    city: 'Erie',
    state: 'PA',
    latitude: '42.125235',
    longitude: '-80.080625',
    timeZoneId: 'America/New_York',
  },
  '16530': {
    zip: '16530',
    city: 'Erie',
    state: 'PA',
    latitude: '42.132056',
    longitude: '-80.08361',
    timeZoneId: 'America/New_York',
  },
  '16531': {
    zip: '16531',
    city: 'Erie',
    state: 'PA',
    latitude: '42.156495',
    longitude: '-80.011362',
    timeZoneId: 'America/New_York',
  },
  '16534': {
    zip: '16534',
    city: 'Erie',
    state: 'PA',
    latitude: '42.124628',
    longitude: '-80.085466',
    timeZoneId: 'America/New_York',
  },
  '16538': {
    zip: '16538',
    city: 'Erie',
    state: 'PA',
    latitude: '42.129086',
    longitude: '-80.085881',
    timeZoneId: 'America/New_York',
  },
  '16541': {
    zip: '16541',
    city: 'Erie',
    state: 'PA',
    latitude: '42.124753',
    longitude: '-80.089452',
    timeZoneId: 'America/New_York',
  },
  '16544': {
    zip: '16544',
    city: 'Erie',
    state: 'PA',
    latitude: '42.110099',
    longitude: '-80.103108',
    timeZoneId: 'America/New_York',
  },
  '16546': {
    zip: '16546',
    city: 'Erie',
    state: 'PA',
    latitude: '42.109206',
    longitude: '-80.048241',
    timeZoneId: 'America/New_York',
  },
  '16550': {
    zip: '16550',
    city: 'Erie',
    state: 'PA',
    latitude: '42.133326',
    longitude: '-80.087856',
    timeZoneId: 'America/New_York',
  },
  '16553': {
    zip: '16553',
    city: 'Erie',
    state: 'PA',
    latitude: '42.126405',
    longitude: '-80.082939',
    timeZoneId: 'America/New_York',
  },
  '16563': {
    zip: '16563',
    city: 'Erie',
    state: 'PA',
    latitude: '42.122676',
    longitude: '-79.983259',
    timeZoneId: 'America/New_York',
  },
  '16565': {
    zip: '16565',
    city: 'Erie',
    state: 'PA',
    latitude: '42.069063',
    longitude: '-80.102633',
    timeZoneId: 'America/New_York',
  },
  '16601': {
    zip: '16601',
    city: 'Altoona',
    state: 'PA',
    latitude: '40.5159',
    longitude: '-78.403203',
    timeZoneId: 'America/New_York',
  },
  '16602': {
    zip: '16602',
    city: 'Altoona',
    state: 'PA',
    latitude: '40.499797',
    longitude: '-78.388319',
    timeZoneId: 'America/New_York',
  },
  '16603': {
    zip: '16603',
    city: 'Altoona',
    state: 'PA',
    latitude: '40.516049',
    longitude: '-78.37439',
    timeZoneId: 'America/New_York',
  },
  '16611': {
    zip: '16611',
    city: 'Alexandria',
    state: 'PA',
    latitude: '40.556814',
    longitude: '-78.096428',
    timeZoneId: 'America/New_York',
  },
  '16613': {
    zip: '16613',
    city: 'Ashville',
    state: 'PA',
    latitude: '40.558541',
    longitude: '-78.541297',
    timeZoneId: 'America/New_York',
  },
  '16616': {
    zip: '16616',
    city: 'Beccaria',
    state: 'PA',
    latitude: '40.759126',
    longitude: '-78.507769',
    timeZoneId: 'America/New_York',
  },
  '16617': {
    zip: '16617',
    city: 'Bellwood',
    state: 'PA',
    latitude: '40.603182',
    longitude: '-78.332859',
    timeZoneId: 'America/New_York',
  },
  '16619': {
    zip: '16619',
    city: 'Blandburg',
    state: 'PA',
    latitude: '40.690699',
    longitude: '-78.441786',
    timeZoneId: 'America/New_York',
  },
  '16620': {
    zip: '16620',
    city: 'Brisbin',
    state: 'PA',
    latitude: '40.835418',
    longitude: '-78.355642',
    timeZoneId: 'America/New_York',
  },
  '16621': {
    zip: '16621',
    city: 'Broad Top',
    state: 'PA',
    latitude: '40.202053',
    longitude: '-78.142889',
    timeZoneId: 'America/New_York',
  },
  '16622': {
    zip: '16622',
    city: 'Calvin',
    state: 'PA',
    latitude: '40.306084',
    longitude: '-78.032555',
    timeZoneId: 'America/New_York',
  },
  '16623': {
    zip: '16623',
    city: 'Cassville',
    state: 'PA',
    latitude: '40.293418',
    longitude: '-78.028929',
    timeZoneId: 'America/New_York',
  },
  '16624': {
    zip: '16624',
    city: 'Chest Springs',
    state: 'PA',
    latitude: '40.577027',
    longitude: '-78.606818',
    timeZoneId: 'America/New_York',
  },
  '16625': {
    zip: '16625',
    city: 'Claysburg',
    state: 'PA',
    latitude: '40.289416',
    longitude: '-78.452288',
    timeZoneId: 'America/New_York',
  },
  '16627': {
    zip: '16627',
    city: 'Coalport',
    state: 'PA',
    latitude: '40.751583',
    longitude: '-78.533246',
    timeZoneId: 'America/New_York',
  },
  '16629': {
    zip: '16629',
    city: 'Coupon',
    state: 'PA',
    latitude: '40.537592',
    longitude: '-78.513159',
    timeZoneId: 'America/New_York',
  },
  '16630': {
    zip: '16630',
    city: 'Cresson',
    state: 'PA',
    latitude: '40.46235',
    longitude: '-78.590149',
    timeZoneId: 'America/New_York',
  },
  '16631': {
    zip: '16631',
    city: 'Curryville',
    state: 'PA',
    latitude: '40.276636',
    longitude: '-78.338693',
    timeZoneId: 'America/New_York',
  },
  '16633': {
    zip: '16633',
    city: 'Defiance',
    state: 'PA',
    latitude: '40.161667',
    longitude: '-78.229935',
    timeZoneId: 'America/New_York',
  },
  '16634': {
    zip: '16634',
    city: 'Dudley',
    state: 'PA',
    latitude: '40.222518',
    longitude: '-78.165792',
    timeZoneId: 'America/New_York',
  },
  '16635': {
    zip: '16635',
    city: 'Duncansville',
    state: 'PA',
    latitude: '40.426696',
    longitude: '-78.431599',
    timeZoneId: 'America/New_York',
  },
  '16636': {
    zip: '16636',
    city: 'Dysart',
    state: 'PA',
    latitude: '40.603615',
    longitude: '-78.504444',
    timeZoneId: 'America/New_York',
  },
  '16637': {
    zip: '16637',
    city: 'East Freedom',
    state: 'PA',
    latitude: '40.335097',
    longitude: '-78.432871',
    timeZoneId: 'America/New_York',
  },
  '16638': {
    zip: '16638',
    city: 'Entriken',
    state: 'PA',
    latitude: '40.334395',
    longitude: '-78.195693',
    timeZoneId: 'America/New_York',
  },
  '16639': {
    zip: '16639',
    city: 'Fallentimber',
    state: 'PA',
    latitude: '40.663089',
    longitude: '-78.489248',
    timeZoneId: 'America/New_York',
  },
  '16640': {
    zip: '16640',
    city: 'Flinton',
    state: 'PA',
    latitude: '40.70616',
    longitude: '-78.470165',
    timeZoneId: 'America/New_York',
  },
  '16641': {
    zip: '16641',
    city: 'Gallitzin',
    state: 'PA',
    latitude: '40.480478',
    longitude: '-78.552746',
    timeZoneId: 'America/New_York',
  },
  '16644': {
    zip: '16644',
    city: 'Glasgow',
    state: 'PA',
    latitude: '40.685011',
    longitude: '-78.43746',
    timeZoneId: 'America/New_York',
  },
  '16645': {
    zip: '16645',
    city: 'Glen Hope',
    state: 'PA',
    latitude: '40.79473',
    longitude: '-78.501098',
    timeZoneId: 'America/New_York',
  },
  '16646': {
    zip: '16646',
    city: 'Hastings',
    state: 'PA',
    latitude: '40.663534',
    longitude: '-78.710124',
    timeZoneId: 'America/New_York',
  },
  '16647': {
    zip: '16647',
    city: 'Hesston',
    state: 'PA',
    latitude: '40.39111',
    longitude: '-78.113027',
    timeZoneId: 'America/New_York',
  },
  '16648': {
    zip: '16648',
    city: 'Hollidaysburg',
    state: 'PA',
    latitude: '40.435661',
    longitude: '-78.390414',
    timeZoneId: 'America/New_York',
  },
  '16650': {
    zip: '16650',
    city: 'Hopewell',
    state: 'PA',
    latitude: '40.111339',
    longitude: '-78.301938',
    timeZoneId: 'America/New_York',
  },
  '16651': {
    zip: '16651',
    city: 'Houtzdale',
    state: 'PA',
    latitude: '40.823943',
    longitude: '-78.349798',
    timeZoneId: 'America/New_York',
  },
  '16652': {
    zip: '16652',
    city: 'Huntingdon',
    state: 'PA',
    latitude: '40.496445',
    longitude: '-78.012809',
    timeZoneId: 'America/New_York',
  },
  '16654': {
    zip: '16654',
    city: 'Huntingdon',
    state: 'PA',
    latitude: '40.487207',
    longitude: '-78.009794',
    timeZoneId: 'America/New_York',
  },
  '16655': {
    zip: '16655',
    city: 'Imler',
    state: 'PA',
    latitude: '40.248513',
    longitude: '-78.539222',
    timeZoneId: 'America/New_York',
  },
  '16656': {
    zip: '16656',
    city: 'Irvona',
    state: 'PA',
    latitude: '40.830282',
    longitude: '-78.566973',
    timeZoneId: 'America/New_York',
  },
  '16657': {
    zip: '16657',
    city: 'James Creek',
    state: 'PA',
    latitude: '40.339173',
    longitude: '-78.18561',
    timeZoneId: 'America/New_York',
  },
  '16659': {
    zip: '16659',
    city: 'Loysburg',
    state: 'PA',
    latitude: '40.177999',
    longitude: '-78.37912',
    timeZoneId: 'America/New_York',
  },
  '16660': {
    zip: '16660',
    city: 'Mc Connellstown',
    state: 'PA',
    latitude: '40.449212',
    longitude: '-78.082861',
    timeZoneId: 'America/New_York',
  },
  '16661': {
    zip: '16661',
    city: 'Madera',
    state: 'PA',
    latitude: '40.829157',
    longitude: '-78.44194',
    timeZoneId: 'America/New_York',
  },
  '16662': {
    zip: '16662',
    city: 'Martinsburg',
    state: 'PA',
    latitude: '40.312769',
    longitude: '-78.328001',
    timeZoneId: 'America/New_York',
  },
  '16663': {
    zip: '16663',
    city: 'Morann',
    state: 'PA',
    latitude: '40.786803',
    longitude: '-78.367068',
    timeZoneId: 'America/New_York',
  },
  '16664': {
    zip: '16664',
    city: 'New Enterprise',
    state: 'PA',
    latitude: '40.193674',
    longitude: '-78.423234',
    timeZoneId: 'America/New_York',
  },
  '16665': {
    zip: '16665',
    city: 'Newry',
    state: 'PA',
    latitude: '40.394638',
    longitude: '-78.438216',
    timeZoneId: 'America/New_York',
  },
  '16666': {
    zip: '16666',
    city: 'Osceola Mills',
    state: 'PA',
    latitude: '40.851689',
    longitude: '-78.271985',
    timeZoneId: 'America/New_York',
  },
  '16667': {
    zip: '16667',
    city: 'Osterburg',
    state: 'PA',
    latitude: '40.160648',
    longitude: '-78.508616',
    timeZoneId: 'America/New_York',
  },
  '16668': {
    zip: '16668',
    city: 'Patton',
    state: 'PA',
    latitude: '40.632325',
    longitude: '-78.651183',
    timeZoneId: 'America/New_York',
  },
  '16669': {
    zip: '16669',
    city: 'Petersburg',
    state: 'PA',
    latitude: '40.570589',
    longitude: '-78.047614',
    timeZoneId: 'America/New_York',
  },
  '16670': {
    zip: '16670',
    city: 'Queen',
    state: 'PA',
    latitude: '40.256461',
    longitude: '-78.509722',
    timeZoneId: 'America/New_York',
  },
  '16671': {
    zip: '16671',
    city: 'Ramey',
    state: 'PA',
    latitude: '40.801988',
    longitude: '-78.400187',
    timeZoneId: 'America/New_York',
  },
  '16672': {
    zip: '16672',
    city: 'Riddlesburg',
    state: 'PA',
    latitude: '40.162589',
    longitude: '-78.253993',
    timeZoneId: 'America/New_York',
  },
  '16673': {
    zip: '16673',
    city: 'Roaring Spring',
    state: 'PA',
    latitude: '40.334211',
    longitude: '-78.39628',
    timeZoneId: 'America/New_York',
  },
  '16674': {
    zip: '16674',
    city: 'Robertsdale',
    state: 'PA',
    latitude: '40.180671',
    longitude: '-78.115002',
    timeZoneId: 'America/New_York',
  },
  '16675': {
    zip: '16675',
    city: 'Saint Boniface',
    state: 'PA',
    latitude: '40.66673',
    longitude: '-78.68297',
    timeZoneId: 'America/New_York',
  },
  '16677': {
    zip: '16677',
    city: 'Sandy Ridge',
    state: 'PA',
    latitude: '40.901849',
    longitude: '-78.083555',
    timeZoneId: 'America/New_York',
  },
  '16678': {
    zip: '16678',
    city: 'Saxton',
    state: 'PA',
    latitude: '40.216087',
    longitude: '-78.24737',
    timeZoneId: 'America/New_York',
  },
  '16679': {
    zip: '16679',
    city: 'Six Mile Run',
    state: 'PA',
    latitude: '40.154383',
    longitude: '-78.202269',
    timeZoneId: 'America/New_York',
  },
  '16680': {
    zip: '16680',
    city: 'Smithmill',
    state: 'PA',
    latitude: '40.758358',
    longitude: '-78.398916',
    timeZoneId: 'America/New_York',
  },
  '16681': {
    zip: '16681',
    city: 'Smokerun',
    state: 'PA',
    latitude: '40.80032',
    longitude: '-78.427661',
    timeZoneId: 'America/New_York',
  },
  '16682': {
    zip: '16682',
    city: 'Sproul',
    state: 'PA',
    latitude: '40.273208',
    longitude: '-78.455958',
    timeZoneId: 'America/New_York',
  },
  '16683': {
    zip: '16683',
    city: 'Spruce Creek',
    state: 'PA',
    latitude: '40.615408',
    longitude: '-78.138082',
    timeZoneId: 'America/New_York',
  },
  '16684': {
    zip: '16684',
    city: 'Tipton',
    state: 'PA',
    latitude: '40.634837',
    longitude: '-78.302127',
    timeZoneId: 'America/New_York',
  },
  '16685': {
    zip: '16685',
    city: 'Todd',
    state: 'PA',
    latitude: '40.265213',
    longitude: '-78.107904',
    timeZoneId: 'America/New_York',
  },
  '16686': {
    zip: '16686',
    city: 'Tyrone',
    state: 'PA',
    latitude: '40.671083',
    longitude: '-78.246566',
    timeZoneId: 'America/New_York',
  },
  '16689': {
    zip: '16689',
    city: 'Waterfall',
    state: 'PA',
    latitude: '40.07915',
    longitude: '-78.055028',
    timeZoneId: 'America/New_York',
  },
  '16691': {
    zip: '16691',
    city: 'Wells Tannery',
    state: 'PA',
    latitude: '40.091995',
    longitude: '-78.142574',
    timeZoneId: 'America/New_York',
  },
  '16692': {
    zip: '16692',
    city: 'Westover',
    state: 'PA',
    latitude: '40.775807',
    longitude: '-78.746208',
    timeZoneId: 'America/New_York',
  },
  '16693': {
    zip: '16693',
    city: 'Williamsburg',
    state: 'PA',
    latitude: '40.459604',
    longitude: '-78.203477',
    timeZoneId: 'America/New_York',
  },
  '16694': {
    zip: '16694',
    city: 'Wood',
    state: 'PA',
    latitude: '40.166846',
    longitude: '-78.135206',
    timeZoneId: 'America/New_York',
  },
  '16695': {
    zip: '16695',
    city: 'Woodbury',
    state: 'PA',
    latitude: '40.211078',
    longitude: '-78.361129',
    timeZoneId: 'America/New_York',
  },
  '16698': {
    zip: '16698',
    city: 'Houtzdale',
    state: 'PA',
    latitude: '40.828332',
    longitude: '-78.352347',
    timeZoneId: 'America/New_York',
  },
  '16699': {
    zip: '16699',
    city: 'Cresson',
    state: 'PA',
    latitude: '40.465506',
    longitude: '-78.588583',
    timeZoneId: 'America/New_York',
  },
  '16701': {
    zip: '16701',
    city: 'Bradford',
    state: 'PA',
    latitude: '41.953308',
    longitude: '-78.645295',
    timeZoneId: 'America/New_York',
  },
  '16720': {
    zip: '16720',
    city: 'Austin',
    state: 'PA',
    latitude: '41.637959',
    longitude: '-78.005164',
    timeZoneId: 'America/New_York',
  },
  '16724': {
    zip: '16724',
    city: 'Crosby',
    state: 'PA',
    latitude: '41.698976',
    longitude: '-78.371156',
    timeZoneId: 'America/New_York',
  },
  '16725': {
    zip: '16725',
    city: 'Custer City',
    state: 'PA',
    latitude: '41.905132',
    longitude: '-78.650729',
    timeZoneId: 'America/New_York',
  },
  '16726': {
    zip: '16726',
    city: 'Cyclone',
    state: 'PA',
    latitude: '41.8218',
    longitude: '-78.56112',
    timeZoneId: 'America/New_York',
  },
  '16727': {
    zip: '16727',
    city: 'Derrick City',
    state: 'PA',
    latitude: '41.980673',
    longitude: '-78.552131',
    timeZoneId: 'America/New_York',
  },
  '16728': {
    zip: '16728',
    city: 'De Young',
    state: 'PA',
    latitude: '41.574389',
    longitude: '-78.912109',
    timeZoneId: 'America/New_York',
  },
  '16729': {
    zip: '16729',
    city: 'Duke Center',
    state: 'PA',
    latitude: '41.952283',
    longitude: '-78.505999',
    timeZoneId: 'America/New_York',
  },
  '16730': {
    zip: '16730',
    city: 'East Smethport',
    state: 'PA',
    latitude: '41.80956',
    longitude: '-78.4176',
    timeZoneId: 'America/New_York',
  },
  '16731': {
    zip: '16731',
    city: 'Eldred',
    state: 'PA',
    latitude: '41.950881',
    longitude: '-78.38023',
    timeZoneId: 'America/New_York',
  },
  '16732': {
    zip: '16732',
    city: 'Gifford',
    state: 'PA',
    latitude: '41.857157',
    longitude: '-78.57669',
    timeZoneId: 'America/New_York',
  },
  '16733': {
    zip: '16733',
    city: 'Hazel Hurst',
    state: 'PA',
    latitude: '41.705754',
    longitude: '-78.585227',
    timeZoneId: 'America/New_York',
  },
  '16734': {
    zip: '16734',
    city: 'James City',
    state: 'PA',
    latitude: '41.545864',
    longitude: '-78.880068',
    timeZoneId: 'America/New_York',
  },
  '16735': {
    zip: '16735',
    city: 'Kane',
    state: 'PA',
    latitude: '41.66364',
    longitude: '-78.79086',
    timeZoneId: 'America/New_York',
  },
  '16738': {
    zip: '16738',
    city: 'Lewis Run',
    state: 'PA',
    latitude: '41.871524',
    longitude: '-78.662892',
    timeZoneId: 'America/New_York',
  },
  '16740': {
    zip: '16740',
    city: 'Mount Jewett',
    state: 'PA',
    latitude: '41.726583',
    longitude: '-78.638691',
    timeZoneId: 'America/New_York',
  },
  '16743': {
    zip: '16743',
    city: 'Port Allegany',
    state: 'PA',
    latitude: '41.81817',
    longitude: '-78.285029',
    timeZoneId: 'America/New_York',
  },
  '16744': {
    zip: '16744',
    city: 'Rew',
    state: 'PA',
    latitude: '41.895778',
    longitude: '-78.519135',
    timeZoneId: 'America/New_York',
  },
  '16745': {
    zip: '16745',
    city: 'Rixford',
    state: 'PA',
    latitude: '41.925648',
    longitude: '-78.497127',
    timeZoneId: 'America/New_York',
  },
  '16746': {
    zip: '16746',
    city: 'Roulette',
    state: 'PA',
    latitude: '41.769999',
    longitude: '-78.142998',
    timeZoneId: 'America/New_York',
  },
  '16748': {
    zip: '16748',
    city: 'Shinglehouse',
    state: 'PA',
    latitude: '41.94762',
    longitude: '-78.1662',
    timeZoneId: 'America/New_York',
  },
  '16749': {
    zip: '16749',
    city: 'Smethport',
    state: 'PA',
    latitude: '41.807779',
    longitude: '-78.449022',
    timeZoneId: 'America/New_York',
  },
  '16750': {
    zip: '16750',
    city: 'Turtlepoint',
    state: 'PA',
    latitude: '41.88291',
    longitude: '-78.326584',
    timeZoneId: 'America/New_York',
  },
  '16801': {
    zip: '16801',
    city: 'State College',
    state: 'PA',
    latitude: '40.796516',
    longitude: '-77.844795',
    timeZoneId: 'America/New_York',
  },
  '16802': {
    zip: '16802',
    city: 'University Park',
    state: 'PA',
    latitude: '40.870717',
    longitude: '-77.83415',
    timeZoneId: 'America/New_York',
  },
  '16803': {
    zip: '16803',
    city: 'State College',
    state: 'PA',
    latitude: '40.804602',
    longitude: '-77.897038',
    timeZoneId: 'America/New_York',
  },
  '16804': {
    zip: '16804',
    city: 'State College',
    state: 'PA',
    latitude: '40.795681',
    longitude: '-77.83679',
    timeZoneId: 'America/New_York',
  },
  '16805': {
    zip: '16805',
    city: 'State College',
    state: 'PA',
    latitude: '40.787354',
    longitude: '-77.900156',
    timeZoneId: 'America/New_York',
  },
  '16820': {
    zip: '16820',
    city: 'Aaronsburg',
    state: 'PA',
    latitude: '40.862757',
    longitude: '-77.391287',
    timeZoneId: 'America/New_York',
  },
  '16821': {
    zip: '16821',
    city: 'Allport',
    state: 'PA',
    latitude: '40.970492',
    longitude: '-78.218066',
    timeZoneId: 'America/New_York',
  },
  '16822': {
    zip: '16822',
    city: 'Beech Creek',
    state: 'PA',
    latitude: '41.093511',
    longitude: '-77.523777',
    timeZoneId: 'America/New_York',
  },
  '16823': {
    zip: '16823',
    city: 'Bellefonte',
    state: 'PA',
    latitude: '40.93806',
    longitude: '-77.72172',
    timeZoneId: 'America/New_York',
  },
  '16825': {
    zip: '16825',
    city: 'Bigler',
    state: 'PA',
    latitude: '40.98339',
    longitude: '-78.308698',
    timeZoneId: 'America/New_York',
  },
  '16826': {
    zip: '16826',
    city: 'Blanchard',
    state: 'PA',
    latitude: '41.064333',
    longitude: '-77.597197',
    timeZoneId: 'America/New_York',
  },
  '16827': {
    zip: '16827',
    city: 'Boalsburg',
    state: 'PA',
    latitude: '40.780424',
    longitude: '-77.791762',
    timeZoneId: 'America/New_York',
  },
  '16828': {
    zip: '16828',
    city: 'Centre Hall',
    state: 'PA',
    latitude: '40.844957',
    longitude: '-77.684908',
    timeZoneId: 'America/New_York',
  },
  '16829': {
    zip: '16829',
    city: 'Clarence',
    state: 'PA',
    latitude: '41.088694',
    longitude: '-77.87466',
    timeZoneId: 'America/New_York',
  },
  '16830': {
    zip: '16830',
    city: 'Clearfield',
    state: 'PA',
    latitude: '41.023121',
    longitude: '-78.441626',
    timeZoneId: 'America/New_York',
  },
  '16832': {
    zip: '16832',
    city: 'Coburn',
    state: 'PA',
    latitude: '40.869439',
    longitude: '-77.73578',
    timeZoneId: 'America/New_York',
  },
  '16833': {
    zip: '16833',
    city: 'Curwensville',
    state: 'PA',
    latitude: '40.974008',
    longitude: '-78.52645',
    timeZoneId: 'America/New_York',
  },
  '16834': {
    zip: '16834',
    city: 'Drifting',
    state: 'PA',
    latitude: '41.022289',
    longitude: '-78.107768',
    timeZoneId: 'America/New_York',
  },
  '16835': {
    zip: '16835',
    city: 'Fleming',
    state: 'PA',
    latitude: '40.912329',
    longitude: '-77.931374',
    timeZoneId: 'America/New_York',
  },
  '16836': {
    zip: '16836',
    city: 'Frenchville',
    state: 'PA',
    latitude: '41.145604',
    longitude: '-78.2429',
    timeZoneId: 'America/New_York',
  },
  '16837': {
    zip: '16837',
    city: 'Glen Richey',
    state: 'PA',
    latitude: '40.935362',
    longitude: '-78.478297',
    timeZoneId: 'America/New_York',
  },
  '16838': {
    zip: '16838',
    city: 'Grampian',
    state: 'PA',
    latitude: '40.999687',
    longitude: '-78.603237',
    timeZoneId: 'America/New_York',
  },
  '16839': {
    zip: '16839',
    city: 'Grassflat',
    state: 'PA',
    latitude: '41.00504',
    longitude: '-78.134037',
    timeZoneId: 'America/New_York',
  },
  '16840': {
    zip: '16840',
    city: 'Hawk Run',
    state: 'PA',
    latitude: '40.941977',
    longitude: '-78.219061',
    timeZoneId: 'America/New_York',
  },
  '16841': {
    zip: '16841',
    city: 'Howard',
    state: 'PA',
    latitude: '41.017669',
    longitude: '-77.658874',
    timeZoneId: 'America/New_York',
  },
  '16843': {
    zip: '16843',
    city: 'Hyde',
    state: 'PA',
    latitude: '41.002709',
    longitude: '-78.466463',
    timeZoneId: 'America/New_York',
  },
  '16844': {
    zip: '16844',
    city: 'Julian',
    state: 'PA',
    latitude: '40.86227',
    longitude: '-77.939689',
    timeZoneId: 'America/New_York',
  },
  '16845': {
    zip: '16845',
    city: 'Karthaus',
    state: 'PA',
    latitude: '41.137729',
    longitude: '-78.016718',
    timeZoneId: 'America/New_York',
  },
  '16847': {
    zip: '16847',
    city: 'Kylertown',
    state: 'PA',
    latitude: '40.993392',
    longitude: '-78.168491',
    timeZoneId: 'America/New_York',
  },
  '16848': {
    zip: '16848',
    city: 'Lamar',
    state: 'PA',
    latitude: '41.016265',
    longitude: '-77.531108',
    timeZoneId: 'America/New_York',
  },
  '16849': {
    zip: '16849',
    city: 'Lanse',
    state: 'PA',
    latitude: '40.972668',
    longitude: '-78.129891',
    timeZoneId: 'America/New_York',
  },
  '16850': {
    zip: '16850',
    city: 'Lecontes Mills',
    state: 'PA',
    latitude: '41.081857',
    longitude: '-78.280359',
    timeZoneId: 'America/New_York',
  },
  '16851': {
    zip: '16851',
    city: 'Lemont',
    state: 'PA',
    latitude: '40.811791',
    longitude: '-77.814403',
    timeZoneId: 'America/New_York',
  },
  '16852': {
    zip: '16852',
    city: 'Madisonburg',
    state: 'PA',
    latitude: '40.941285',
    longitude: '-77.498441',
    timeZoneId: 'America/New_York',
  },
  '16853': {
    zip: '16853',
    city: 'Milesburg',
    state: 'PA',
    latitude: '40.941151',
    longitude: '-77.791263',
    timeZoneId: 'America/New_York',
  },
  '16854': {
    zip: '16854',
    city: 'Millheim',
    state: 'PA',
    latitude: '40.892507',
    longitude: '-77.473279',
    timeZoneId: 'America/New_York',
  },
  '16855': {
    zip: '16855',
    city: 'Mineral Springs',
    state: 'PA',
    latitude: '41.001571',
    longitude: '-78.363433',
    timeZoneId: 'America/New_York',
  },
  '16856': {
    zip: '16856',
    city: 'Mingoville',
    state: 'PA',
    latitude: '40.910906',
    longitude: '-77.781305',
    timeZoneId: 'America/New_York',
  },
  '16858': {
    zip: '16858',
    city: 'Morrisdale',
    state: 'PA',
    latitude: '41.015138',
    longitude: '-78.225217',
    timeZoneId: 'America/New_York',
  },
  '16859': {
    zip: '16859',
    city: 'Moshannon',
    state: 'PA',
    latitude: '41.024152',
    longitude: '-78.021894',
    timeZoneId: 'America/New_York',
  },
  '16860': {
    zip: '16860',
    city: 'Munson',
    state: 'PA',
    latitude: '40.968776',
    longitude: '-78.182212',
    timeZoneId: 'America/New_York',
  },
  '16861': {
    zip: '16861',
    city: 'New Millport',
    state: 'PA',
    latitude: '40.884741',
    longitude: '-78.486115',
    timeZoneId: 'America/New_York',
  },
  '16863': {
    zip: '16863',
    city: 'Olanta',
    state: 'PA',
    latitude: '40.905935',
    longitude: '-78.501934',
    timeZoneId: 'America/New_York',
  },
  '16864': {
    zip: '16864',
    city: 'Orviston',
    state: 'PA',
    latitude: '41.085359',
    longitude: '-77.656066',
    timeZoneId: 'America/New_York',
  },
  '16865': {
    zip: '16865',
    city: 'Pennsylvania Furnace',
    state: 'PA',
    latitude: '40.7258',
    longitude: '-77.945698',
    timeZoneId: 'America/New_York',
  },
  '16866': {
    zip: '16866',
    city: 'Philipsburg',
    state: 'PA',
    latitude: '40.894163',
    longitude: '-78.213044',
    timeZoneId: 'America/New_York',
  },
  '16868': {
    zip: '16868',
    city: 'Pine Grove Mills',
    state: 'PA',
    latitude: '40.730879',
    longitude: '-77.886053',
    timeZoneId: 'America/New_York',
  },
  '16870': {
    zip: '16870',
    city: 'Port Matilda',
    state: 'PA',
    latitude: '40.801567',
    longitude: '-78.03023',
    timeZoneId: 'America/New_York',
  },
  '16871': {
    zip: '16871',
    city: 'Pottersdale',
    state: 'PA',
    latitude: '41.210847',
    longitude: '-78.022444',
    timeZoneId: 'America/New_York',
  },
  '16872': {
    zip: '16872',
    city: 'Rebersburg',
    state: 'PA',
    latitude: '40.956168',
    longitude: '-77.402514',
    timeZoneId: 'America/New_York',
  },
  '16873': {
    zip: '16873',
    city: 'Shawville',
    state: 'PA',
    latitude: '41.068825',
    longitude: '-78.356857',
    timeZoneId: 'America/New_York',
  },
  '16874': {
    zip: '16874',
    city: 'Snow Shoe',
    state: 'PA',
    latitude: '41.028036',
    longitude: '-77.945971',
    timeZoneId: 'America/New_York',
  },
  '16875': {
    zip: '16875',
    city: 'Spring Mills',
    state: 'PA',
    latitude: '40.864802',
    longitude: '-77.571544',
    timeZoneId: 'America/New_York',
  },
  '16876': {
    zip: '16876',
    city: 'Wallaceton',
    state: 'PA',
    latitude: '40.961446',
    longitude: '-78.2922',
    timeZoneId: 'America/New_York',
  },
  '16877': {
    zip: '16877',
    city: 'Warriors Mark',
    state: 'PA',
    latitude: '40.735071',
    longitude: '-78.085504',
    timeZoneId: 'America/New_York',
  },
  '16878': {
    zip: '16878',
    city: 'West Decatur',
    state: 'PA',
    latitude: '40.948226',
    longitude: '-78.36041',
    timeZoneId: 'America/New_York',
  },
  '16879': {
    zip: '16879',
    city: 'Winburne',
    state: 'PA',
    latitude: '40.969762',
    longitude: '-78.160371',
    timeZoneId: 'America/New_York',
  },
  '16881': {
    zip: '16881',
    city: 'Woodland',
    state: 'PA',
    latitude: '41.01675',
    longitude: '-78.326243',
    timeZoneId: 'America/New_York',
  },
  '16882': {
    zip: '16882',
    city: 'Woodward',
    state: 'PA',
    latitude: '40.925398',
    longitude: '-77.309132',
    timeZoneId: 'America/New_York',
  },
  '16901': {
    zip: '16901',
    city: 'Wellsboro',
    state: 'PA',
    latitude: '41.7775',
    longitude: '-77.309722',
    timeZoneId: 'America/New_York',
  },
  '16910': {
    zip: '16910',
    city: 'Alba',
    state: 'PA',
    latitude: '41.705551',
    longitude: '-76.828782',
    timeZoneId: 'America/New_York',
  },
  '16911': {
    zip: '16911',
    city: 'Arnot',
    state: 'PA',
    latitude: '41.661491',
    longitude: '-77.120959',
    timeZoneId: 'America/New_York',
  },
  '16912': {
    zip: '16912',
    city: 'Blossburg',
    state: 'PA',
    latitude: '41.679554',
    longitude: '-77.064192',
    timeZoneId: 'America/New_York',
  },
  '16914': {
    zip: '16914',
    city: 'Columbia Cross Roads',
    state: 'PA',
    latitude: '41.848609',
    longitude: '-76.790408',
    timeZoneId: 'America/New_York',
  },
  '16915': {
    zip: '16915',
    city: 'Coudersport',
    state: 'PA',
    latitude: '41.772095',
    longitude: '-78.018822',
    timeZoneId: 'America/New_York',
  },
  '16917': {
    zip: '16917',
    city: 'Covington',
    state: 'PA',
    latitude: '41.757299',
    longitude: '-77.067597',
    timeZoneId: 'America/New_York',
  },
  '16920': {
    zip: '16920',
    city: 'Elkland',
    state: 'PA',
    latitude: '41.989723',
    longitude: '-77.31373',
    timeZoneId: 'America/New_York',
  },
  '16921': {
    zip: '16921',
    city: 'Gaines',
    state: 'PA',
    latitude: '41.67138',
    longitude: '-77.55258',
    timeZoneId: 'America/New_York',
  },
  '16922': {
    zip: '16922',
    city: 'Galeton',
    state: 'PA',
    latitude: '41.71284',
    longitude: '-77.66202',
    timeZoneId: 'America/New_York',
  },
  '16923': {
    zip: '16923',
    city: 'Genesee',
    state: 'PA',
    latitude: '41.915978',
    longitude: '-77.886047',
    timeZoneId: 'America/New_York',
  },
  '16925': {
    zip: '16925',
    city: 'Gillett',
    state: 'PA',
    latitude: '41.9517',
    longitude: '-76.77018',
    timeZoneId: 'America/New_York',
  },
  '16926': {
    zip: '16926',
    city: 'Granville Summit',
    state: 'PA',
    latitude: '41.666415',
    longitude: '-76.720268',
    timeZoneId: 'America/New_York',
  },
  '16927': {
    zip: '16927',
    city: 'Harrison Valley',
    state: 'PA',
    latitude: '41.951385',
    longitude: '-77.702976',
    timeZoneId: 'America/New_York',
  },
  '16928': {
    zip: '16928',
    city: 'Knoxville',
    state: 'PA',
    latitude: '41.954882',
    longitude: '-77.440143',
    timeZoneId: 'America/New_York',
  },
  '16929': {
    zip: '16929',
    city: 'Lawrenceville',
    state: 'PA',
    latitude: '41.993999',
    longitude: '-77.123186',
    timeZoneId: 'America/New_York',
  },
  '16930': {
    zip: '16930',
    city: 'Liberty',
    state: 'PA',
    latitude: '41.55',
    longitude: '-77.1',
    timeZoneId: 'America/New_York',
  },
  '16932': {
    zip: '16932',
    city: 'Mainesburg',
    state: 'PA',
    latitude: '41.785028',
    longitude: '-76.962034',
    timeZoneId: 'America/New_York',
  },
  '16933': {
    zip: '16933',
    city: 'Mansfield',
    state: 'PA',
    latitude: '41.773056',
    longitude: '-77.200833',
    timeZoneId: 'America/New_York',
  },
  '16935': {
    zip: '16935',
    city: 'Middlebury Center',
    state: 'PA',
    latitude: '41.855833',
    longitude: '-77.2375',
    timeZoneId: 'America/New_York',
  },
  '16936': {
    zip: '16936',
    city: 'Millerton',
    state: 'PA',
    latitude: '41.931667',
    longitude: '-76.936111',
    timeZoneId: 'America/New_York',
  },
  '16937': {
    zip: '16937',
    city: 'Mills',
    state: 'PA',
    latitude: '41.904583',
    longitude: '-77.756618',
    timeZoneId: 'America/New_York',
  },
  '16938': {
    zip: '16938',
    city: 'Morris',
    state: 'PA',
    latitude: '41.544444',
    longitude: '-77.285833',
    timeZoneId: 'America/New_York',
  },
  '16939': {
    zip: '16939',
    city: 'Morris Run',
    state: 'PA',
    latitude: '41.679295',
    longitude: '-77.018305',
    timeZoneId: 'America/New_York',
  },
  '16940': {
    zip: '16940',
    city: 'Nelson',
    state: 'PA',
    latitude: '41.973794',
    longitude: '-77.227277',
    timeZoneId: 'America/New_York',
  },
  '16941': {
    zip: '16941',
    city: 'Genesee',
    state: 'PA',
    latitude: '41.981572',
    longitude: '-77.76998',
    timeZoneId: 'America/New_York',
  },
  '16942': {
    zip: '16942',
    city: 'Osceola',
    state: 'PA',
    latitude: '41.985496',
    longitude: '-77.345771',
    timeZoneId: 'America/New_York',
  },
  '16943': {
    zip: '16943',
    city: 'Sabinsville',
    state: 'PA',
    latitude: '41.843054',
    longitude: '-77.545629',
    timeZoneId: 'America/New_York',
  },
  '16945': {
    zip: '16945',
    city: 'Sylvania',
    state: 'PA',
    latitude: '41.805044',
    longitude: '-76.854875',
    timeZoneId: 'America/New_York',
  },
  '16946': {
    zip: '16946',
    city: 'Tioga',
    state: 'PA',
    latitude: '41.913008',
    longitude: '-77.14338',
    timeZoneId: 'America/New_York',
  },
  '16947': {
    zip: '16947',
    city: 'Troy',
    state: 'PA',
    latitude: '41.783653',
    longitude: '-76.791605',
    timeZoneId: 'America/New_York',
  },
  '16948': {
    zip: '16948',
    city: 'Ulysses',
    state: 'PA',
    latitude: '41.84652',
    longitude: '-77.70972',
    timeZoneId: 'America/New_York',
  },
  '16950': {
    zip: '16950',
    city: 'Westfield',
    state: 'PA',
    latitude: '41.91972',
    longitude: '-77.51712',
    timeZoneId: 'America/New_York',
  },
  '17001': {
    zip: '17001',
    city: 'Camp Hill',
    state: 'PA',
    latitude: '40.23947',
    longitude: '-76.918627',
    timeZoneId: 'America/New_York',
  },
  '17002': {
    zip: '17002',
    city: 'Allensville',
    state: 'PA',
    latitude: '40.601427',
    longitude: '-77.722706',
    timeZoneId: 'America/New_York',
  },
  '17003': {
    zip: '17003',
    city: 'Annville',
    state: 'PA',
    latitude: '40.33128',
    longitude: '-76.54806',
    timeZoneId: 'America/New_York',
  },
  '17004': {
    zip: '17004',
    city: 'Belleville',
    state: 'PA',
    latitude: '40.603109',
    longitude: '-77.720588',
    timeZoneId: 'America/New_York',
  },
  '17005': {
    zip: '17005',
    city: 'Berrysburg',
    state: 'PA',
    latitude: '40.604532',
    longitude: '-76.809901',
    timeZoneId: 'America/New_York',
  },
  '17006': {
    zip: '17006',
    city: 'Blain',
    state: 'PA',
    latitude: '40.306622',
    longitude: '-77.513142',
    timeZoneId: 'America/New_York',
  },
  '17007': {
    zip: '17007',
    city: 'Boiling Springs',
    state: 'PA',
    latitude: '40.147806',
    longitude: '-77.116428',
    timeZoneId: 'America/New_York',
  },
  '17009': {
    zip: '17009',
    city: 'Burnham',
    state: 'PA',
    latitude: '40.63691',
    longitude: '-77.568491',
    timeZoneId: 'America/New_York',
  },
  '17010': {
    zip: '17010',
    city: 'Campbelltown',
    state: 'PA',
    latitude: '40.274565',
    longitude: '-76.580954',
    timeZoneId: 'America/New_York',
  },
  '17011': {
    zip: '17011',
    city: 'Camp Hill',
    state: 'PA',
    latitude: '40.240066',
    longitude: '-76.930386',
    timeZoneId: 'America/New_York',
  },
  '17013': {
    zip: '17013',
    city: 'Carlisle',
    state: 'PA',
    latitude: '40.203056',
    longitude: '-77.313611',
    timeZoneId: 'America/New_York',
  },
  '17014': {
    zip: '17014',
    city: 'Cocolamus',
    state: 'PA',
    latitude: '40.659121',
    longitude: '-77.103424',
    timeZoneId: 'America/New_York',
  },
  '17015': {
    zip: '17015',
    city: 'Carlisle',
    state: 'PA',
    latitude: '40.201481',
    longitude: '-77.189072',
    timeZoneId: 'America/New_York',
  },
  '17016': {
    zip: '17016',
    city: 'Cornwall',
    state: 'PA',
    latitude: '40.276374',
    longitude: '-76.408476',
    timeZoneId: 'America/New_York',
  },
  '17017': {
    zip: '17017',
    city: 'Dalmatia',
    state: 'PA',
    latitude: '40.647411',
    longitude: '-76.877332',
    timeZoneId: 'America/New_York',
  },
  '17018': {
    zip: '17018',
    city: 'Dauphin',
    state: 'PA',
    latitude: '40.370886',
    longitude: '-76.931434',
    timeZoneId: 'America/New_York',
  },
  '17019': {
    zip: '17019',
    city: 'Dillsburg',
    state: 'PA',
    latitude: '40.098851',
    longitude: '-77.022403',
    timeZoneId: 'America/New_York',
  },
  '17020': {
    zip: '17020',
    city: 'Duncannon',
    state: 'PA',
    latitude: '40.391761',
    longitude: '-77.045102',
    timeZoneId: 'America/New_York',
  },
  '17021': {
    zip: '17021',
    city: 'East Waterford',
    state: 'PA',
    latitude: '40.360534',
    longitude: '-77.674744',
    timeZoneId: 'America/New_York',
  },
  '17022': {
    zip: '17022',
    city: 'Elizabethtown',
    state: 'PA',
    latitude: '40.148743',
    longitude: '-76.597557',
    timeZoneId: 'America/New_York',
  },
  '17023': {
    zip: '17023',
    city: 'Elizabethville',
    state: 'PA',
    latitude: '40.555025',
    longitude: '-76.826794',
    timeZoneId: 'America/New_York',
  },
  '17024': {
    zip: '17024',
    city: 'Elliottsburg',
    state: 'PA',
    latitude: '40.361616',
    longitude: '-77.268375',
    timeZoneId: 'America/New_York',
  },
  '17025': {
    zip: '17025',
    city: 'Enola',
    state: 'PA',
    latitude: '40.289593',
    longitude: '-76.959427',
    timeZoneId: 'America/New_York',
  },
  '17026': {
    zip: '17026',
    city: 'Fredericksburg',
    state: 'PA',
    latitude: '40.4475',
    longitude: '-76.4148',
    timeZoneId: 'America/New_York',
  },
  '17027': {
    zip: '17027',
    city: 'Grantham',
    state: 'PA',
    latitude: '40.152071',
    longitude: '-77.002719',
    timeZoneId: 'America/New_York',
  },
  '17028': {
    zip: '17028',
    city: 'Grantville',
    state: 'PA',
    latitude: '40.37496',
    longitude: '-76.69482',
    timeZoneId: 'America/New_York',
  },
  '17029': {
    zip: '17029',
    city: 'Granville',
    state: 'PA',
    latitude: '40.546874',
    longitude: '-77.604665',
    timeZoneId: 'America/New_York',
  },
  '17030': {
    zip: '17030',
    city: 'Gratz',
    state: 'PA',
    latitude: '40.608917',
    longitude: '-76.718596',
    timeZoneId: 'America/New_York',
  },
  '17032': {
    zip: '17032',
    city: 'Halifax',
    state: 'PA',
    latitude: '40.463333',
    longitude: '-76.842778',
    timeZoneId: 'America/New_York',
  },
  '17033': {
    zip: '17033',
    city: 'Hershey',
    state: 'PA',
    latitude: '40.27884',
    longitude: '-76.63068',
    timeZoneId: 'America/New_York',
  },
  '17034': {
    zip: '17034',
    city: 'Highspire',
    state: 'PA',
    latitude: '40.20634',
    longitude: '-76.789304',
    timeZoneId: 'America/New_York',
  },
  '17035': {
    zip: '17035',
    city: 'Honey Grove',
    state: 'PA',
    latitude: '40.434041',
    longitude: '-77.587129',
    timeZoneId: 'America/New_York',
  },
  '17036': {
    zip: '17036',
    city: 'Hummelstown',
    state: 'PA',
    latitude: '40.27758',
    longitude: '-76.7088',
    timeZoneId: 'America/New_York',
  },
  '17037': {
    zip: '17037',
    city: 'Ickesburg',
    state: 'PA',
    latitude: '40.432478',
    longitude: '-77.353347',
    timeZoneId: 'America/New_York',
  },
  '17038': {
    zip: '17038',
    city: 'Jonestown',
    state: 'PA',
    latitude: '40.410665',
    longitude: '-76.486339',
    timeZoneId: 'America/New_York',
  },
  '17039': {
    zip: '17039',
    city: 'Kleinfeltersville',
    state: 'PA',
    latitude: '40.30102',
    longitude: '-76.249244',
    timeZoneId: 'America/New_York',
  },
  '17040': {
    zip: '17040',
    city: 'Landisburg',
    state: 'PA',
    latitude: '40.311569',
    longitude: '-77.333589',
    timeZoneId: 'America/New_York',
  },
  '17041': {
    zip: '17041',
    city: 'Lawn',
    state: 'PA',
    latitude: '40.21815',
    longitude: '-76.541899',
    timeZoneId: 'America/New_York',
  },
  '17042': {
    zip: '17042',
    city: 'Lebanon',
    state: 'PA',
    latitude: '40.333597',
    longitude: '-76.423855',
    timeZoneId: 'America/New_York',
  },
  '17043': {
    zip: '17043',
    city: 'Lemoyne',
    state: 'PA',
    latitude: '40.2543',
    longitude: '-76.89804',
    timeZoneId: 'America/New_York',
  },
  '17044': {
    zip: '17044',
    city: 'Lewistown',
    state: 'PA',
    latitude: '40.602729',
    longitude: '-77.569859',
    timeZoneId: 'America/New_York',
  },
  '17045': {
    zip: '17045',
    city: 'Liverpool',
    state: 'PA',
    latitude: '40.574018',
    longitude: '-76.985771',
    timeZoneId: 'America/New_York',
  },
  '17046': {
    zip: '17046',
    city: 'Lebanon',
    state: 'PA',
    latitude: '40.357882',
    longitude: '-76.432449',
    timeZoneId: 'America/New_York',
  },
  '17047': {
    zip: '17047',
    city: 'Loysville',
    state: 'PA',
    latitude: '40.369113',
    longitude: '-77.43745',
    timeZoneId: 'America/New_York',
  },
  '17048': {
    zip: '17048',
    city: 'Lykens',
    state: 'PA',
    latitude: '40.59522',
    longitude: '-76.7139',
    timeZoneId: 'America/New_York',
  },
  '17049': {
    zip: '17049',
    city: 'Mc Alisterville',
    state: 'PA',
    latitude: '40.64682',
    longitude: '-77.26452',
    timeZoneId: 'America/New_York',
  },
  '17050': {
    zip: '17050',
    city: 'Mechanicsburg',
    state: 'PA',
    latitude: '40.216649',
    longitude: '-77.008727',
    timeZoneId: 'America/New_York',
  },
  '17051': {
    zip: '17051',
    city: 'Mc Veytown',
    state: 'PA',
    latitude: '40.497914',
    longitude: '-77.743817',
    timeZoneId: 'America/New_York',
  },
  '17052': {
    zip: '17052',
    city: 'Mapleton Depot',
    state: 'PA',
    latitude: '40.378094',
    longitude: '-77.971536',
    timeZoneId: 'America/New_York',
  },
  '17053': {
    zip: '17053',
    city: 'Marysville',
    state: 'PA',
    latitude: '40.337522',
    longitude: '-76.983731',
    timeZoneId: 'America/New_York',
  },
  '17054': {
    zip: '17054',
    city: 'Mattawana',
    state: 'PA',
    latitude: '40.495215',
    longitude: '-77.730114',
    timeZoneId: 'America/New_York',
  },
  '17055': {
    zip: '17055',
    city: 'Mechanicsburg',
    state: 'PA',
    latitude: '40.219237',
    longitude: '-77.001047',
    timeZoneId: 'America/New_York',
  },
  '17056': {
    zip: '17056',
    city: 'Mexico',
    state: 'PA',
    latitude: '40.536642',
    longitude: '-77.354394',
    timeZoneId: 'America/New_York',
  },
  '17057': {
    zip: '17057',
    city: 'Middletown',
    state: 'PA',
    latitude: '40.204159',
    longitude: '-76.734268',
    timeZoneId: 'America/New_York',
  },
  '17058': {
    zip: '17058',
    city: 'Mifflin',
    state: 'PA',
    latitude: '40.565928',
    longitude: '-77.406815',
    timeZoneId: 'America/New_York',
  },
  '17059': {
    zip: '17059',
    city: 'Mifflintown',
    state: 'PA',
    latitude: '40.573604',
    longitude: '-77.387497',
    timeZoneId: 'America/New_York',
  },
  '17060': {
    zip: '17060',
    city: 'Mill Creek',
    state: 'PA',
    latitude: '40.445595',
    longitude: '-77.904057',
    timeZoneId: 'America/New_York',
  },
  '17061': {
    zip: '17061',
    city: 'Millersburg',
    state: 'PA',
    latitude: '40.56702',
    longitude: '-76.9176',
    timeZoneId: 'America/New_York',
  },
  '17062': {
    zip: '17062',
    city: 'Millerstown',
    state: 'PA',
    latitude: '40.57062',
    longitude: '-77.13168',
    timeZoneId: 'America/New_York',
  },
  '17063': {
    zip: '17063',
    city: 'Milroy',
    state: 'PA',
    latitude: '40.716078',
    longitude: '-77.590384',
    timeZoneId: 'America/New_York',
  },
  '17064': {
    zip: '17064',
    city: 'Mount Gretna',
    state: 'PA',
    latitude: '40.245217',
    longitude: '-76.468668',
    timeZoneId: 'America/New_York',
  },
  '17065': {
    zip: '17065',
    city: 'Mount Holly Springs',
    state: 'PA',
    latitude: '40.117148',
    longitude: '-77.192329',
    timeZoneId: 'America/New_York',
  },
  '17066': {
    zip: '17066',
    city: 'Mount Union',
    state: 'PA',
    latitude: '40.38606',
    longitude: '-77.87406',
    timeZoneId: 'America/New_York',
  },
  '17067': {
    zip: '17067',
    city: 'Myerstown',
    state: 'PA',
    latitude: '40.377563',
    longitude: '-76.31874',
    timeZoneId: 'America/New_York',
  },
  '17068': {
    zip: '17068',
    city: 'New Bloomfield',
    state: 'PA',
    latitude: '40.418905',
    longitude: '-77.189502',
    timeZoneId: 'America/New_York',
  },
  '17069': {
    zip: '17069',
    city: 'New Buffalo',
    state: 'PA',
    latitude: '40.456714',
    longitude: '-76.97108',
    timeZoneId: 'America/New_York',
  },
  '17070': {
    zip: '17070',
    city: 'New Cumberland',
    state: 'PA',
    latitude: '40.21014',
    longitude: '-76.86936',
    timeZoneId: 'America/New_York',
  },
  '17071': {
    zip: '17071',
    city: 'New Germantown',
    state: 'PA',
    latitude: '40.282231',
    longitude: '-77.577883',
    timeZoneId: 'America/New_York',
  },
  '17072': {
    zip: '17072',
    city: 'New Kingstown',
    state: 'PA',
    latitude: '40.26981',
    longitude: '-76.984102',
    timeZoneId: 'America/New_York',
  },
  '17073': {
    zip: '17073',
    city: 'Newmanstown',
    state: 'PA',
    latitude: '40.30872',
    longitude: '-76.2528',
    timeZoneId: 'America/New_York',
  },
  '17074': {
    zip: '17074',
    city: 'Newport',
    state: 'PA',
    latitude: '40.476',
    longitude: '-77.1555',
    timeZoneId: 'America/New_York',
  },
  '17075': {
    zip: '17075',
    city: 'Newton Hamilton',
    state: 'PA',
    latitude: '40.393403',
    longitude: '-77.835133',
    timeZoneId: 'America/New_York',
  },
  '17076': {
    zip: '17076',
    city: 'Oakland Mills',
    state: 'PA',
    latitude: '40.608663',
    longitude: '-77.316236',
    timeZoneId: 'America/New_York',
  },
  '17077': {
    zip: '17077',
    city: 'Ono',
    state: 'PA',
    latitude: '40.40431',
    longitude: '-76.534928',
    timeZoneId: 'America/New_York',
  },
  '17078': {
    zip: '17078',
    city: 'Palmyra',
    state: 'PA',
    latitude: '40.299264',
    longitude: '-76.586642',
    timeZoneId: 'America/New_York',
  },
  '17080': {
    zip: '17080',
    city: 'Pillow',
    state: 'PA',
    latitude: '40.641215',
    longitude: '-76.8047',
    timeZoneId: 'America/New_York',
  },
  '17081': {
    zip: '17081',
    city: 'Plainfield',
    state: 'PA',
    latitude: '40.204234',
    longitude: '-77.290805',
    timeZoneId: 'America/New_York',
  },
  '17082': {
    zip: '17082',
    city: 'Port Royal',
    state: 'PA',
    latitude: '40.52088',
    longitude: '-77.4144',
    timeZoneId: 'America/New_York',
  },
  '17083': {
    zip: '17083',
    city: 'Quentin',
    state: 'PA',
    latitude: '40.281931',
    longitude: '-76.437757',
    timeZoneId: 'America/New_York',
  },
  '17084': {
    zip: '17084',
    city: 'Reedsville',
    state: 'PA',
    latitude: '40.67762',
    longitude: '-77.606927',
    timeZoneId: 'America/New_York',
  },
  '17085': {
    zip: '17085',
    city: 'Rexmont',
    state: 'PA',
    latitude: '40.275485',
    longitude: '-76.383567',
    timeZoneId: 'America/New_York',
  },
  '17086': {
    zip: '17086',
    city: 'Richfield',
    state: 'PA',
    latitude: '40.698624',
    longitude: '-77.126607',
    timeZoneId: 'America/New_York',
  },
  '17087': {
    zip: '17087',
    city: 'Richland',
    state: 'PA',
    latitude: '40.360624',
    longitude: '-76.26432',
    timeZoneId: 'America/New_York',
  },
  '17088': {
    zip: '17088',
    city: 'Schaefferstown',
    state: 'PA',
    latitude: '40.298697',
    longitude: '-76.299045',
    timeZoneId: 'America/New_York',
  },
  '17089': {
    zip: '17089',
    city: 'Camp Hill',
    state: 'PA',
    latitude: '40.253878',
    longitude: '-76.927333',
    timeZoneId: 'America/New_York',
  },
  '17090': {
    zip: '17090',
    city: 'Shermans Dale',
    state: 'PA',
    latitude: '40.34144',
    longitude: '-77.177825',
    timeZoneId: 'America/New_York',
  },
  '17093': {
    zip: '17093',
    city: 'Summerdale',
    state: 'PA',
    latitude: '40.294042',
    longitude: '-76.929935',
    timeZoneId: 'America/New_York',
  },
  '17094': {
    zip: '17094',
    city: 'Thompsontown',
    state: 'PA',
    latitude: '40.564068',
    longitude: '-77.23709',
    timeZoneId: 'America/New_York',
  },
  '17097': {
    zip: '17097',
    city: 'Wiconisco',
    state: 'PA',
    latitude: '40.572367',
    longitude: '-76.688285',
    timeZoneId: 'America/New_York',
  },
  '17098': {
    zip: '17098',
    city: 'Williamstown',
    state: 'PA',
    latitude: '40.578618',
    longitude: '-76.628591',
    timeZoneId: 'America/New_York',
  },
  '17099': {
    zip: '17099',
    city: 'Yeagertown',
    state: 'PA',
    latitude: '40.640569',
    longitude: '-77.579797',
    timeZoneId: 'America/New_York',
  },
  '17101': {
    zip: '17101',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.262172',
    longitude: '-76.880556',
    timeZoneId: 'America/New_York',
  },
  '17102': {
    zip: '17102',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.25898',
    longitude: '-76.86294',
    timeZoneId: 'America/New_York',
  },
  '17103': {
    zip: '17103',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.25958',
    longitude: '-76.86018',
    timeZoneId: 'America/New_York',
  },
  '17104': {
    zip: '17104',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.260352',
    longitude: '-76.855909',
    timeZoneId: 'America/New_York',
  },
  '17105': {
    zip: '17105',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.299869',
    longitude: '-76.799562',
    timeZoneId: 'America/New_York',
  },
  '17106': {
    zip: '17106',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.233872',
    longitude: '-76.819088',
    timeZoneId: 'America/New_York',
  },
  '17107': {
    zip: '17107',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.296479',
    longitude: '-76.874607',
    timeZoneId: 'America/New_York',
  },
  '17108': {
    zip: '17108',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.281473',
    longitude: '-76.846974',
    timeZoneId: 'America/New_York',
  },
  '17109': {
    zip: '17109',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.30128',
    longitude: '-76.79994',
    timeZoneId: 'America/New_York',
  },
  '17110': {
    zip: '17110',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.30893',
    longitude: '-76.874147',
    timeZoneId: 'America/New_York',
  },
  '17111': {
    zip: '17111',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.27044',
    longitude: '-76.80546',
    timeZoneId: 'America/New_York',
  },
  '17112': {
    zip: '17112',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.33806',
    longitude: '-76.80378',
    timeZoneId: 'America/New_York',
  },
  '17113': {
    zip: '17113',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.24728',
    longitude: '-76.84248',
    timeZoneId: 'America/New_York',
  },
  '17120': {
    zip: '17120',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.267283',
    longitude: '-76.881635',
    timeZoneId: 'America/New_York',
  },
  '17121': {
    zip: '17121',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.314697',
    longitude: '-76.874265',
    timeZoneId: 'America/New_York',
  },
  '17122': {
    zip: '17122',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.263288',
    longitude: '-76.881597',
    timeZoneId: 'America/New_York',
  },
  '17123': {
    zip: '17123',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.265958',
    longitude: '-76.880448',
    timeZoneId: 'America/New_York',
  },
  '17124': {
    zip: '17124',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.266009',
    longitude: '-76.881673',
    timeZoneId: 'America/New_York',
  },
  '17125': {
    zip: '17125',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.266171',
    longitude: '-76.880912',
    timeZoneId: 'America/New_York',
  },
  '17126': {
    zip: '17126',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.26539',
    longitude: '-76.880316',
    timeZoneId: 'America/New_York',
  },
  '17127': {
    zip: '17127',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.263282',
    longitude: '-76.876977',
    timeZoneId: 'America/New_York',
  },
  '17128': {
    zip: '17128',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.263931',
    longitude: '-76.8834',
    timeZoneId: 'America/New_York',
  },
  '17129': {
    zip: '17129',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.263185',
    longitude: '-76.882694',
    timeZoneId: 'America/New_York',
  },
  '17130': {
    zip: '17130',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.273135',
    longitude: '-76.888847',
    timeZoneId: 'America/New_York',
  },
  '17140': {
    zip: '17140',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.259652',
    longitude: '-76.807717',
    timeZoneId: 'America/New_York',
  },
  '17177': {
    zip: '17177',
    city: 'Harrisburg',
    state: 'PA',
    latitude: '40.290362',
    longitude: '-76.812588',
    timeZoneId: 'America/New_York',
  },
  '17201': {
    zip: '17201',
    city: 'Chambersburg',
    state: 'PA',
    latitude: '39.93348',
    longitude: '-77.67456',
    timeZoneId: 'America/New_York',
  },
  '17202': {
    zip: '17202',
    city: 'Chambersburg',
    state: 'PA',
    latitude: '39.941877',
    longitude: '-77.67391',
    timeZoneId: 'America/New_York',
  },
  '17210': {
    zip: '17210',
    city: 'Amberson',
    state: 'PA',
    latitude: '40.197059',
    longitude: '-77.651373',
    timeZoneId: 'America/New_York',
  },
  '17211': {
    zip: '17211',
    city: 'Artemas',
    state: 'PA',
    latitude: '39.767488',
    longitude: '-78.406126',
    timeZoneId: 'America/New_York',
  },
  '17212': {
    zip: '17212',
    city: 'Big Cove Tannery',
    state: 'PA',
    latitude: '39.88248',
    longitude: '-78.0204',
    timeZoneId: 'America/New_York',
  },
  '17213': {
    zip: '17213',
    city: 'Blairs Mills',
    state: 'PA',
    latitude: '40.254967',
    longitude: '-77.776007',
    timeZoneId: 'America/New_York',
  },
  '17214': {
    zip: '17214',
    city: 'Blue Ridge Summit',
    state: 'PA',
    latitude: '39.730375',
    longitude: '-77.470658',
    timeZoneId: 'America/New_York',
  },
  '17215': {
    zip: '17215',
    city: 'Burnt Cabins',
    state: 'PA',
    latitude: '40.06331',
    longitude: '-77.905418',
    timeZoneId: 'America/New_York',
  },
  '17217': {
    zip: '17217',
    city: 'Concord',
    state: 'PA',
    latitude: '40.255335',
    longitude: '-77.699826',
    timeZoneId: 'America/New_York',
  },
  '17219': {
    zip: '17219',
    city: 'Doylesburg',
    state: 'PA',
    latitude: '40.21794',
    longitude: '-77.7135',
    timeZoneId: 'America/New_York',
  },
  '17220': {
    zip: '17220',
    city: 'Dry Run',
    state: 'PA',
    latitude: '40.179521',
    longitude: '-77.749505',
    timeZoneId: 'America/New_York',
  },
  '17221': {
    zip: '17221',
    city: 'Fannettsburg',
    state: 'PA',
    latitude: '40.064497',
    longitude: '-77.82426',
    timeZoneId: 'America/New_York',
  },
  '17222': {
    zip: '17222',
    city: 'Fayetteville',
    state: 'PA',
    latitude: '39.895994',
    longitude: '-77.531945',
    timeZoneId: 'America/New_York',
  },
  '17223': {
    zip: '17223',
    city: 'Fort Littleton',
    state: 'PA',
    latitude: '40.060812',
    longitude: '-77.970494',
    timeZoneId: 'America/New_York',
  },
  '17224': {
    zip: '17224',
    city: 'Fort Loudon',
    state: 'PA',
    latitude: '39.98052',
    longitude: '-77.89872',
    timeZoneId: 'America/New_York',
  },
  '17225': {
    zip: '17225',
    city: 'Greencastle',
    state: 'PA',
    latitude: '39.789267',
    longitude: '-77.752975',
    timeZoneId: 'America/New_York',
  },
  '17228': {
    zip: '17228',
    city: 'Harrisonville',
    state: 'PA',
    latitude: '39.977362',
    longitude: '-78.082703',
    timeZoneId: 'America/New_York',
  },
  '17229': {
    zip: '17229',
    city: 'Hustontown',
    state: 'PA',
    latitude: '40.120009',
    longitude: '-77.992025',
    timeZoneId: 'America/New_York',
  },
  '17231': {
    zip: '17231',
    city: 'Lemasters',
    state: 'PA',
    latitude: '39.856695',
    longitude: '-77.860814',
    timeZoneId: 'America/New_York',
  },
  '17232': {
    zip: '17232',
    city: 'Lurgan',
    state: 'PA',
    latitude: '40.093194',
    longitude: '-77.632098',
    timeZoneId: 'America/New_York',
  },
  '17233': {
    zip: '17233',
    city: 'Mc Connellsburg',
    state: 'PA',
    latitude: '39.95694',
    longitude: '-77.96364',
    timeZoneId: 'America/New_York',
  },
  '17235': {
    zip: '17235',
    city: 'Marion',
    state: 'PA',
    latitude: '39.860111',
    longitude: '-77.698187',
    timeZoneId: 'America/New_York',
  },
  '17236': {
    zip: '17236',
    city: 'Mercersburg',
    state: 'PA',
    latitude: '39.81342',
    longitude: '-77.95746',
    timeZoneId: 'America/New_York',
  },
  '17237': {
    zip: '17237',
    city: 'Mont Alto',
    state: 'PA',
    latitude: '39.841479',
    longitude: '-77.558453',
    timeZoneId: 'America/New_York',
  },
  '17238': {
    zip: '17238',
    city: 'Needmore',
    state: 'PA',
    latitude: '39.8733',
    longitude: '-78.14052',
    timeZoneId: 'America/New_York',
  },
  '17239': {
    zip: '17239',
    city: 'Neelyton',
    state: 'PA',
    latitude: '40.130165',
    longitude: '-77.856685',
    timeZoneId: 'America/New_York',
  },
  '17240': {
    zip: '17240',
    city: 'Newburg',
    state: 'PA',
    latitude: '40.116059',
    longitude: '-77.602385',
    timeZoneId: 'America/New_York',
  },
  '17241': {
    zip: '17241',
    city: 'Newville',
    state: 'PA',
    latitude: '40.168351',
    longitude: '-77.382711',
    timeZoneId: 'America/New_York',
  },
  '17243': {
    zip: '17243',
    city: 'Orbisonia',
    state: 'PA',
    latitude: '40.23942',
    longitude: '-77.90712',
    timeZoneId: 'America/New_York',
  },
  '17244': {
    zip: '17244',
    city: 'Orrstown',
    state: 'PA',
    latitude: '40.07852',
    longitude: '-77.650599',
    timeZoneId: 'America/New_York',
  },
  '17246': {
    zip: '17246',
    city: 'Pleasant Hall',
    state: 'PA',
    latitude: '40.057748',
    longitude: '-77.675087',
    timeZoneId: 'America/New_York',
  },
  '17247': {
    zip: '17247',
    city: 'Quincy',
    state: 'PA',
    latitude: '39.802622',
    longitude: '-77.573943',
    timeZoneId: 'America/New_York',
  },
  '17249': {
    zip: '17249',
    city: 'Rockhill Furnace',
    state: 'PA',
    latitude: '40.24298',
    longitude: '-77.900687',
    timeZoneId: 'America/New_York',
  },
  '17250': {
    zip: '17250',
    city: 'Rouzerville',
    state: 'PA',
    latitude: '39.735149',
    longitude: '-77.526368',
    timeZoneId: 'America/New_York',
  },
  '17251': {
    zip: '17251',
    city: 'Roxbury',
    state: 'PA',
    latitude: '40.110564',
    longitude: '-77.660331',
    timeZoneId: 'America/New_York',
  },
  '17252': {
    zip: '17252',
    city: 'Saint Thomas',
    state: 'PA',
    latitude: '39.915118',
    longitude: '-77.808351',
    timeZoneId: 'America/New_York',
  },
  '17253': {
    zip: '17253',
    city: 'Saltillo',
    state: 'PA',
    latitude: '40.211755',
    longitude: '-78.00519',
    timeZoneId: 'America/New_York',
  },
  '17254': {
    zip: '17254',
    city: 'Scotland',
    state: 'PA',
    latitude: '39.967955',
    longitude: '-77.584588',
    timeZoneId: 'America/New_York',
  },
  '17255': {
    zip: '17255',
    city: 'Shade Gap',
    state: 'PA',
    latitude: '40.178946',
    longitude: '-77.864435',
    timeZoneId: 'America/New_York',
  },
  '17256': {
    zip: '17256',
    city: 'Shady Grove',
    state: 'PA',
    latitude: '39.777763',
    longitude: '-77.668729',
    timeZoneId: 'America/New_York',
  },
  '17257': {
    zip: '17257',
    city: 'Shippensburg',
    state: 'PA',
    latitude: '40.038795',
    longitude: '-77.553738',
    timeZoneId: 'America/New_York',
  },
  '17260': {
    zip: '17260',
    city: 'Shirleysburg',
    state: 'PA',
    latitude: '40.321076',
    longitude: '-77.848669',
    timeZoneId: 'America/New_York',
  },
  '17261': {
    zip: '17261',
    city: 'South Mountain',
    state: 'PA',
    latitude: '39.834092',
    longitude: '-77.474209',
    timeZoneId: 'America/New_York',
  },
  '17262': {
    zip: '17262',
    city: 'Spring Run',
    state: 'PA',
    latitude: '40.150278',
    longitude: '-77.744679',
    timeZoneId: 'America/New_York',
  },
  '17263': {
    zip: '17263',
    city: 'State Line',
    state: 'PA',
    latitude: '39.722985',
    longitude: '-77.719158',
    timeZoneId: 'America/New_York',
  },
  '17264': {
    zip: '17264',
    city: 'Three Springs',
    state: 'PA',
    latitude: '40.17438',
    longitude: '-77.98818',
    timeZoneId: 'America/New_York',
  },
  '17265': {
    zip: '17265',
    city: 'Upperstrasburg',
    state: 'PA',
    latitude: '40.052056',
    longitude: '-77.753164',
    timeZoneId: 'America/New_York',
  },
  '17266': {
    zip: '17266',
    city: 'Walnut Bottom',
    state: 'PA',
    latitude: '40.104396',
    longitude: '-77.525358',
    timeZoneId: 'America/New_York',
  },
  '17267': {
    zip: '17267',
    city: 'Warfordsburg',
    state: 'PA',
    latitude: '39.776683',
    longitude: '-78.204091',
    timeZoneId: 'America/New_York',
  },
  '17268': {
    zip: '17268',
    city: 'Waynesboro',
    state: 'PA',
    latitude: '39.761199',
    longitude: '-77.570415',
    timeZoneId: 'America/New_York',
  },
  '17271': {
    zip: '17271',
    city: 'Willow Hill',
    state: 'PA',
    latitude: '40.110786',
    longitude: '-77.793814',
    timeZoneId: 'America/New_York',
  },
  '17272': {
    zip: '17272',
    city: 'Zullinger',
    state: 'PA',
    latitude: '39.767422',
    longitude: '-77.624697',
    timeZoneId: 'America/New_York',
  },
  '17301': {
    zip: '17301',
    city: 'Abbottstown',
    state: 'PA',
    latitude: '39.885968',
    longitude: '-76.997348',
    timeZoneId: 'America/New_York',
  },
  '17302': {
    zip: '17302',
    city: 'Airville',
    state: 'PA',
    latitude: '39.768902',
    longitude: '-76.372021',
    timeZoneId: 'America/New_York',
  },
  '17303': {
    zip: '17303',
    city: 'Arendtsville',
    state: 'PA',
    latitude: '39.921479',
    longitude: '-77.297065',
    timeZoneId: 'America/New_York',
  },
  '17304': {
    zip: '17304',
    city: 'Aspers',
    state: 'PA',
    latitude: '39.978814',
    longitude: '-77.237058',
    timeZoneId: 'America/New_York',
  },
  '17306': {
    zip: '17306',
    city: 'Bendersville',
    state: 'PA',
    latitude: '39.981919',
    longitude: '-77.252284',
    timeZoneId: 'America/New_York',
  },
  '17307': {
    zip: '17307',
    city: 'Biglerville',
    state: 'PA',
    latitude: '39.934161',
    longitude: '-77.293238',
    timeZoneId: 'America/New_York',
  },
  '17309': {
    zip: '17309',
    city: 'Brogue',
    state: 'PA',
    latitude: '39.87062',
    longitude: '-76.451716',
    timeZoneId: 'America/New_York',
  },
  '17310': {
    zip: '17310',
    city: 'Cashtown',
    state: 'PA',
    latitude: '39.888603',
    longitude: '-77.358894',
    timeZoneId: 'America/New_York',
  },
  '17311': {
    zip: '17311',
    city: 'Codorus',
    state: 'PA',
    latitude: '39.822382',
    longitude: '-76.84359',
    timeZoneId: 'America/New_York',
  },
  '17312': {
    zip: '17312',
    city: 'Craley',
    state: 'PA',
    latitude: '39.945887',
    longitude: '-76.510486',
    timeZoneId: 'America/New_York',
  },
  '17313': {
    zip: '17313',
    city: 'Dallastown',
    state: 'PA',
    latitude: '39.901404',
    longitude: '-76.648789',
    timeZoneId: 'America/New_York',
  },
  '17314': {
    zip: '17314',
    city: 'Delta',
    state: 'PA',
    latitude: '39.73',
    longitude: '-76.313333',
    timeZoneId: 'America/New_York',
  },
  '17315': {
    zip: '17315',
    city: 'Dover',
    state: 'PA',
    latitude: '40.01382',
    longitude: '-76.81584',
    timeZoneId: 'America/New_York',
  },
  '17316': {
    zip: '17316',
    city: 'East Berlin',
    state: 'PA',
    latitude: '39.960645',
    longitude: '-77.023324',
    timeZoneId: 'America/New_York',
  },
  '17317': {
    zip: '17317',
    city: 'East Prospect',
    state: 'PA',
    latitude: '39.96824',
    longitude: '-76.521232',
    timeZoneId: 'America/New_York',
  },
  '17318': {
    zip: '17318',
    city: 'Emigsville',
    state: 'PA',
    latitude: '40.01766',
    longitude: '-76.73166',
    timeZoneId: 'America/New_York',
  },
  '17319': {
    zip: '17319',
    city: 'Etters',
    state: 'PA',
    latitude: '40.152317',
    longitude: '-76.794239',
    timeZoneId: 'America/New_York',
  },
  '17320': {
    zip: '17320',
    city: 'Fairfield',
    state: 'PA',
    latitude: '39.7551',
    longitude: '-77.37629',
    timeZoneId: 'America/New_York',
  },
  '17321': {
    zip: '17321',
    city: 'Fawn Grove',
    state: 'PA',
    latitude: '39.75228',
    longitude: '-76.44744',
    timeZoneId: 'America/New_York',
  },
  '17322': {
    zip: '17322',
    city: 'Felton',
    state: 'PA',
    latitude: '39.860272',
    longitude: '-76.566178',
    timeZoneId: 'America/New_York',
  },
  '17323': {
    zip: '17323',
    city: 'Franklintown',
    state: 'PA',
    latitude: '40.074158',
    longitude: '-77.031429',
    timeZoneId: 'America/New_York',
  },
  '17324': {
    zip: '17324',
    city: 'Gardners',
    state: 'PA',
    latitude: '40.011966',
    longitude: '-77.195076',
    timeZoneId: 'America/New_York',
  },
  '17325': {
    zip: '17325',
    city: 'Gettysburg',
    state: 'PA',
    latitude: '39.851653',
    longitude: '-77.260693',
    timeZoneId: 'America/New_York',
  },
  '17327': {
    zip: '17327',
    city: 'Glen Rock',
    state: 'PA',
    latitude: '39.798447',
    longitude: '-76.722134',
    timeZoneId: 'America/New_York',
  },
  '17329': {
    zip: '17329',
    city: 'Glenville',
    state: 'PA',
    latitude: '39.75786',
    longitude: '-76.87122',
    timeZoneId: 'America/New_York',
  },
  '17331': {
    zip: '17331',
    city: 'Hanover',
    state: 'PA',
    latitude: '39.79662',
    longitude: '-77.00142',
    timeZoneId: 'America/New_York',
  },
  '17332': {
    zip: '17332',
    city: 'Hanover',
    state: 'PA',
    latitude: '39.798105',
    longitude: '-76.988197',
    timeZoneId: 'America/New_York',
  },
  '17333': {
    zip: '17333',
    city: 'Hanover',
    state: 'PA',
    latitude: '39.824208',
    longitude: '-76.990704',
    timeZoneId: 'America/New_York',
  },
  '17334': {
    zip: '17334',
    city: 'Hanover',
    state: 'PA',
    latitude: '39.807897',
    longitude: '-76.965787',
    timeZoneId: 'America/New_York',
  },
  '17335': {
    zip: '17335',
    city: 'Hanover',
    state: 'PA',
    latitude: '39.807895',
    longitude: '-76.965786',
    timeZoneId: 'America/New_York',
  },
  '17337': {
    zip: '17337',
    city: 'Idaville',
    state: 'PA',
    latitude: '40.013198',
    longitude: '-77.20289',
    timeZoneId: 'America/New_York',
  },
  '17339': {
    zip: '17339',
    city: 'Lewisberry',
    state: 'PA',
    latitude: '40.12608',
    longitude: '-76.87656',
    timeZoneId: 'America/New_York',
  },
  '17340': {
    zip: '17340',
    city: 'Littlestown',
    state: 'PA',
    latitude: '39.7632',
    longitude: '-77.11716',
    timeZoneId: 'America/New_York',
  },
  '17342': {
    zip: '17342',
    city: 'Loganville',
    state: 'PA',
    latitude: '39.852555',
    longitude: '-76.707028',
    timeZoneId: 'America/New_York',
  },
  '17343': {
    zip: '17343',
    city: 'Mc Knightstown',
    state: 'PA',
    latitude: '39.864464',
    longitude: '-77.336847',
    timeZoneId: 'America/New_York',
  },
  '17344': {
    zip: '17344',
    city: 'Mc Sherrystown',
    state: 'PA',
    latitude: '39.804478',
    longitude: '-77.019713',
    timeZoneId: 'America/New_York',
  },
  '17345': {
    zip: '17345',
    city: 'Manchester',
    state: 'PA',
    latitude: '40.07368',
    longitude: '-76.730034',
    timeZoneId: 'America/New_York',
  },
  '17347': {
    zip: '17347',
    city: 'Mount Wolf',
    state: 'PA',
    latitude: '40.054598',
    longitude: '-76.690743',
    timeZoneId: 'America/New_York',
  },
  '17349': {
    zip: '17349',
    city: 'New Freedom',
    state: 'PA',
    latitude: '39.737783',
    longitude: '-76.683516',
    timeZoneId: 'America/New_York',
  },
  '17350': {
    zip: '17350',
    city: 'New Oxford',
    state: 'PA',
    latitude: '39.88848',
    longitude: '-77.0772',
    timeZoneId: 'America/New_York',
  },
  '17352': {
    zip: '17352',
    city: 'New Park',
    state: 'PA',
    latitude: '39.75192',
    longitude: '-76.49568',
    timeZoneId: 'America/New_York',
  },
  '17353': {
    zip: '17353',
    city: 'Orrtanna',
    state: 'PA',
    latitude: '39.880603',
    longitude: '-77.384367',
    timeZoneId: 'America/New_York',
  },
  '17355': {
    zip: '17355',
    city: 'Railroad',
    state: 'PA',
    latitude: '39.76115',
    longitude: '-76.697707',
    timeZoneId: 'America/New_York',
  },
  '17356': {
    zip: '17356',
    city: 'Red Lion',
    state: 'PA',
    latitude: '39.903715',
    longitude: '-76.60791',
    timeZoneId: 'America/New_York',
  },
  '17358': {
    zip: '17358',
    city: 'Rossville',
    state: 'PA',
    latitude: '40.074374',
    longitude: '-76.929624',
    timeZoneId: 'America/New_York',
  },
  '17360': {
    zip: '17360',
    city: 'Seven Valleys',
    state: 'PA',
    latitude: '39.856827',
    longitude: '-76.768562',
    timeZoneId: 'America/New_York',
  },
  '17361': {
    zip: '17361',
    city: 'Shrewsbury',
    state: 'PA',
    latitude: '39.762201',
    longitude: '-76.678536',
    timeZoneId: 'America/New_York',
  },
  '17362': {
    zip: '17362',
    city: 'Spring Grove',
    state: 'PA',
    latitude: '39.874292',
    longitude: '-76.864491',
    timeZoneId: 'America/New_York',
  },
  '17363': {
    zip: '17363',
    city: 'Stewartstown',
    state: 'PA',
    latitude: '39.757217',
    longitude: '-76.601719',
    timeZoneId: 'America/New_York',
  },
  '17364': {
    zip: '17364',
    city: 'Thomasville',
    state: 'PA',
    latitude: '39.926966',
    longitude: '-76.885934',
    timeZoneId: 'America/New_York',
  },
  '17365': {
    zip: '17365',
    city: 'Wellsville',
    state: 'PA',
    latitude: '40.057136',
    longitude: '-76.938746',
    timeZoneId: 'America/New_York',
  },
  '17366': {
    zip: '17366',
    city: 'Windsor',
    state: 'PA',
    latitude: '39.92354',
    longitude: '-76.574778',
    timeZoneId: 'America/New_York',
  },
  '17368': {
    zip: '17368',
    city: 'Wrightsville',
    state: 'PA',
    latitude: '40.015953',
    longitude: '-76.528879',
    timeZoneId: 'America/New_York',
  },
  '17370': {
    zip: '17370',
    city: 'York Haven',
    state: 'PA',
    latitude: '40.117401',
    longitude: '-76.775929',
    timeZoneId: 'America/New_York',
  },
  '17371': {
    zip: '17371',
    city: 'York New Salem',
    state: 'PA',
    latitude: '39.9016',
    longitude: '-76.791415',
    timeZoneId: 'America/New_York',
  },
  '17372': {
    zip: '17372',
    city: 'York Springs',
    state: 'PA',
    latitude: '40.003154',
    longitude: '-77.111235',
    timeZoneId: 'America/New_York',
  },
  '17375': {
    zip: '17375',
    city: 'Peach Glen',
    state: 'PA',
    latitude: '39.973828',
    longitude: '-77.231585',
    timeZoneId: 'America/New_York',
  },
  '17401': {
    zip: '17401',
    city: 'York',
    state: 'PA',
    latitude: '39.961048',
    longitude: '-76.728662',
    timeZoneId: 'America/New_York',
  },
  '17402': {
    zip: '17402',
    city: 'York',
    state: 'PA',
    latitude: '39.97683',
    longitude: '-76.685313',
    timeZoneId: 'America/New_York',
  },
  '17403': {
    zip: '17403',
    city: 'York',
    state: 'PA',
    latitude: '39.950251',
    longitude: '-76.710456',
    timeZoneId: 'America/New_York',
  },
  '17404': {
    zip: '17404',
    city: 'York',
    state: 'PA',
    latitude: '39.971839',
    longitude: '-76.774584',
    timeZoneId: 'America/New_York',
  },
  '17405': {
    zip: '17405',
    city: 'York',
    state: 'PA',
    latitude: '39.971413',
    longitude: '-76.677719',
    timeZoneId: 'America/New_York',
  },
  '17406': {
    zip: '17406',
    city: 'York',
    state: 'PA',
    latitude: '40.0152',
    longitude: '-76.59744',
    timeZoneId: 'America/New_York',
  },
  '17407': {
    zip: '17407',
    city: 'York',
    state: 'PA',
    latitude: '39.93804',
    longitude: '-76.66608',
    timeZoneId: 'America/New_York',
  },
  '17408': {
    zip: '17408',
    city: 'York',
    state: 'PA',
    latitude: '40.015315',
    longitude: '-76.597579',
    timeZoneId: 'America/New_York',
  },
  '17501': {
    zip: '17501',
    city: 'Akron',
    state: 'PA',
    latitude: '40.156398',
    longitude: '-76.203555',
    timeZoneId: 'America/New_York',
  },
  '17502': {
    zip: '17502',
    city: 'Bainbridge',
    state: 'PA',
    latitude: '40.12186',
    longitude: '-76.654353',
    timeZoneId: 'America/New_York',
  },
  '17503': {
    zip: '17503',
    city: 'Bart',
    state: 'PA',
    latitude: '39.935972',
    longitude: '-76.084154',
    timeZoneId: 'America/New_York',
  },
  '17504': {
    zip: '17504',
    city: 'Bausman',
    state: 'PA',
    latitude: '40.02564',
    longitude: '-76.328043',
    timeZoneId: 'America/New_York',
  },
  '17505': {
    zip: '17505',
    city: 'Bird in Hand',
    state: 'PA',
    latitude: '40.053862',
    longitude: '-76.178815',
    timeZoneId: 'America/New_York',
  },
  '17506': {
    zip: '17506',
    city: 'Blue Ball',
    state: 'PA',
    latitude: '40.116423',
    longitude: '-76.050361',
    timeZoneId: 'America/New_York',
  },
  '17507': {
    zip: '17507',
    city: 'Bowmansville',
    state: 'PA',
    latitude: '40.195667',
    longitude: '-76.013395',
    timeZoneId: 'America/New_York',
  },
  '17508': {
    zip: '17508',
    city: 'Brownstown',
    state: 'PA',
    latitude: '40.12796',
    longitude: '-76.211265',
    timeZoneId: 'America/New_York',
  },
  '17509': {
    zip: '17509',
    city: 'Christiana',
    state: 'PA',
    latitude: '39.9126',
    longitude: '-76.04382',
    timeZoneId: 'America/New_York',
  },
  '17512': {
    zip: '17512',
    city: 'Columbia',
    state: 'PA',
    latitude: '40.036944',
    longitude: '-76.480322',
    timeZoneId: 'America/New_York',
  },
  '17516': {
    zip: '17516',
    city: 'Conestoga',
    state: 'PA',
    latitude: '39.9255',
    longitude: '-76.36608',
    timeZoneId: 'America/New_York',
  },
  '17517': {
    zip: '17517',
    city: 'Denver',
    state: 'PA',
    latitude: '40.241302',
    longitude: '-76.137348',
    timeZoneId: 'America/New_York',
  },
  '17518': {
    zip: '17518',
    city: 'Drumore',
    state: 'PA',
    latitude: '39.818303',
    longitude: '-76.244889',
    timeZoneId: 'America/New_York',
  },
  '17519': {
    zip: '17519',
    city: 'East Earl',
    state: 'PA',
    latitude: '40.127373',
    longitude: '-76.024073',
    timeZoneId: 'America/New_York',
  },
  '17520': {
    zip: '17520',
    city: 'East Petersburg',
    state: 'PA',
    latitude: '40.101393',
    longitude: '-76.349047',
    timeZoneId: 'America/New_York',
  },
  '17521': {
    zip: '17521',
    city: 'Elm',
    state: 'PA',
    latitude: '40.20251',
    longitude: '-76.350523',
    timeZoneId: 'America/New_York',
  },
  '17522': {
    zip: '17522',
    city: 'Ephrata',
    state: 'PA',
    latitude: '40.17096',
    longitude: '-76.1673',
    timeZoneId: 'America/New_York',
  },
  '17527': {
    zip: '17527',
    city: 'Gap',
    state: 'PA',
    latitude: '40.01058',
    longitude: '-75.99204',
    timeZoneId: 'America/New_York',
  },
  '17528': {
    zip: '17528',
    city: 'Goodville',
    state: 'PA',
    latitude: '40.125083',
    longitude: '-76.006228',
    timeZoneId: 'America/New_York',
  },
  '17529': {
    zip: '17529',
    city: 'Gordonville',
    state: 'PA',
    latitude: '40.040039',
    longitude: '-76.107124',
    timeZoneId: 'America/New_York',
  },
  '17532': {
    zip: '17532',
    city: 'Holtwood',
    state: 'PA',
    latitude: '39.8565',
    longitude: '-76.30008',
    timeZoneId: 'America/New_York',
  },
  '17533': {
    zip: '17533',
    city: 'Hopeland',
    state: 'PA',
    latitude: '40.234524',
    longitude: '-76.267836',
    timeZoneId: 'America/New_York',
  },
  '17534': {
    zip: '17534',
    city: 'Intercourse',
    state: 'PA',
    latitude: '40.05542',
    longitude: '-76.123247',
    timeZoneId: 'America/New_York',
  },
  '17535': {
    zip: '17535',
    city: 'Kinzers',
    state: 'PA',
    latitude: '40.007451',
    longitude: '-76.04253',
    timeZoneId: 'America/New_York',
  },
  '17536': {
    zip: '17536',
    city: 'Kirkwood',
    state: 'PA',
    latitude: '39.849151',
    longitude: '-76.087262',
    timeZoneId: 'America/New_York',
  },
  '17537': {
    zip: '17537',
    city: 'Lampeter',
    state: 'PA',
    latitude: '39.993237',
    longitude: '-76.245313',
    timeZoneId: 'America/New_York',
  },
  '17538': {
    zip: '17538',
    city: 'Landisville',
    state: 'PA',
    latitude: '40.100343',
    longitude: '-76.427234',
    timeZoneId: 'America/New_York',
  },
  '17540': {
    zip: '17540',
    city: 'Leola',
    state: 'PA',
    latitude: '40.095709',
    longitude: '-76.185401',
    timeZoneId: 'America/New_York',
  },
  '17543': {
    zip: '17543',
    city: 'Lititz',
    state: 'PA',
    latitude: '40.162966',
    longitude: '-76.293604',
    timeZoneId: 'America/New_York',
  },
  '17545': {
    zip: '17545',
    city: 'Manheim',
    state: 'PA',
    latitude: '40.170819',
    longitude: '-76.411581',
    timeZoneId: 'America/New_York',
  },
  '17547': {
    zip: '17547',
    city: 'Marietta',
    state: 'PA',
    latitude: '40.061561',
    longitude: '-76.559472',
    timeZoneId: 'America/New_York',
  },
  '17549': {
    zip: '17549',
    city: 'Martindale',
    state: 'PA',
    latitude: '40.151513',
    longitude: '-76.090144',
    timeZoneId: 'America/New_York',
  },
  '17550': {
    zip: '17550',
    city: 'Maytown',
    state: 'PA',
    latitude: '40.069679',
    longitude: '-76.581371',
    timeZoneId: 'America/New_York',
  },
  '17551': {
    zip: '17551',
    city: 'Millersville',
    state: 'PA',
    latitude: '40.000624',
    longitude: '-76.358814',
    timeZoneId: 'America/New_York',
  },
  '17552': {
    zip: '17552',
    city: 'Mount Joy',
    state: 'PA',
    latitude: '40.108548',
    longitude: '-76.508883',
    timeZoneId: 'America/New_York',
  },
  '17554': {
    zip: '17554',
    city: 'Mountville',
    state: 'PA',
    latitude: '40.043724',
    longitude: '-76.425577',
    timeZoneId: 'America/New_York',
  },
  '17555': {
    zip: '17555',
    city: 'Narvon',
    state: 'PA',
    latitude: '40.133056',
    longitude: '-75.965',
    timeZoneId: 'America/New_York',
  },
  '17557': {
    zip: '17557',
    city: 'New Holland',
    state: 'PA',
    latitude: '40.101003',
    longitude: '-76.079601',
    timeZoneId: 'America/New_York',
  },
  '17560': {
    zip: '17560',
    city: 'New Providence',
    state: 'PA',
    latitude: '39.926389',
    longitude: '-76.2525',
    timeZoneId: 'America/New_York',
  },
  '17562': {
    zip: '17562',
    city: 'Paradise',
    state: 'PA',
    latitude: '39.97434',
    longitude: '-76.10742',
    timeZoneId: 'America/New_York',
  },
  '17563': {
    zip: '17563',
    city: 'Peach Bottom',
    state: 'PA',
    latitude: '39.766461',
    longitude: '-76.182262',
    timeZoneId: 'America/New_York',
  },
  '17564': {
    zip: '17564',
    city: 'Penryn',
    state: 'PA',
    latitude: '40.16289',
    longitude: '-76.36785',
    timeZoneId: 'America/New_York',
  },
  '17565': {
    zip: '17565',
    city: 'Pequea',
    state: 'PA',
    latitude: '39.926389',
    longitude: '-76.307222',
    timeZoneId: 'America/New_York',
  },
  '17566': {
    zip: '17566',
    city: 'Quarryville',
    state: 'PA',
    latitude: '39.879899',
    longitude: '-76.148321',
    timeZoneId: 'America/New_York',
  },
  '17567': {
    zip: '17567',
    city: 'Reamstown',
    state: 'PA',
    latitude: '40.207163',
    longitude: '-76.120458',
    timeZoneId: 'America/New_York',
  },
  '17568': {
    zip: '17568',
    city: 'Refton',
    state: 'PA',
    latitude: '39.94493',
    longitude: '-76.232889',
    timeZoneId: 'America/New_York',
  },
  '17569': {
    zip: '17569',
    city: 'Reinholds',
    state: 'PA',
    latitude: '40.276083',
    longitude: '-76.123914',
    timeZoneId: 'America/New_York',
  },
  '17570': {
    zip: '17570',
    city: 'Rheems',
    state: 'PA',
    latitude: '40.128687',
    longitude: '-76.56992',
    timeZoneId: 'America/New_York',
  },
  '17572': {
    zip: '17572',
    city: 'Ronks',
    state: 'PA',
    latitude: '40.014588',
    longitude: '-76.14982',
    timeZoneId: 'America/New_York',
  },
  '17573': {
    zip: '17573',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.02885',
    longitude: '-76.167332',
    timeZoneId: 'America/New_York',
  },
  '17575': {
    zip: '17575',
    city: 'Silver Spring',
    state: 'PA',
    latitude: '40.07402',
    longitude: '-76.430012',
    timeZoneId: 'America/New_York',
  },
  '17576': {
    zip: '17576',
    city: 'Smoketown',
    state: 'PA',
    latitude: '40.035728',
    longitude: '-76.199636',
    timeZoneId: 'America/New_York',
  },
  '17578': {
    zip: '17578',
    city: 'Stevens',
    state: 'PA',
    latitude: '40.241389',
    longitude: '-76.174444',
    timeZoneId: 'America/New_York',
  },
  '17579': {
    zip: '17579',
    city: 'Strasburg',
    state: 'PA',
    latitude: '39.967797',
    longitude: '-76.177226',
    timeZoneId: 'America/New_York',
  },
  '17580': {
    zip: '17580',
    city: 'Talmage',
    state: 'PA',
    latitude: '40.115482',
    longitude: '-76.211364',
    timeZoneId: 'America/New_York',
  },
  '17581': {
    zip: '17581',
    city: 'Terre Hill',
    state: 'PA',
    latitude: '40.158653',
    longitude: '-76.047759',
    timeZoneId: 'America/New_York',
  },
  '17582': {
    zip: '17582',
    city: 'Washington Boro',
    state: 'PA',
    latitude: '39.996531',
    longitude: '-76.452756',
    timeZoneId: 'America/New_York',
  },
  '17583': {
    zip: '17583',
    city: 'West Willow',
    state: 'PA',
    latitude: '39.971229',
    longitude: '-76.288109',
    timeZoneId: 'America/New_York',
  },
  '17584': {
    zip: '17584',
    city: 'Willow Street',
    state: 'PA',
    latitude: '39.962325',
    longitude: '-76.274759',
    timeZoneId: 'America/New_York',
  },
  '17585': {
    zip: '17585',
    city: 'Witmer',
    state: 'PA',
    latitude: '40.045774',
    longitude: '-76.212436',
    timeZoneId: 'America/New_York',
  },
  '17601': {
    zip: '17601',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.0614',
    longitude: '-76.32204',
    timeZoneId: 'America/New_York',
  },
  '17602': {
    zip: '17602',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.02925',
    longitude: '-76.265633',
    timeZoneId: 'America/New_York',
  },
  '17603': {
    zip: '17603',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.045868',
    longitude: '-76.354179',
    timeZoneId: 'America/New_York',
  },
  '17604': {
    zip: '17604',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.099859',
    longitude: '-76.278485',
    timeZoneId: 'America/New_York',
  },
  '17605': {
    zip: '17605',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.014997',
    longitude: '-76.248254',
    timeZoneId: 'America/New_York',
  },
  '17606': {
    zip: '17606',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.07674',
    longitude: '-76.30914',
    timeZoneId: 'America/New_York',
  },
  '17607': {
    zip: '17607',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.030565',
    longitude: '-76.347703',
    timeZoneId: 'America/New_York',
  },
  '17608': {
    zip: '17608',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.023731',
    longitude: '-76.331711',
    timeZoneId: 'America/New_York',
  },
  '17611': {
    zip: '17611',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.023731',
    longitude: '-76.331711',
    timeZoneId: 'America/New_York',
  },
  '17622': {
    zip: '17622',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.023731',
    longitude: '-76.331711',
    timeZoneId: 'America/New_York',
  },
  '17699': {
    zip: '17699',
    city: 'Lancaster',
    state: 'PA',
    latitude: '40.041872',
    longitude: '-76.30012',
    timeZoneId: 'America/New_York',
  },
  '17701': {
    zip: '17701',
    city: 'Williamsport',
    state: 'PA',
    latitude: '41.2674',
    longitude: '-77.03508',
    timeZoneId: 'America/New_York',
  },
  '17702': {
    zip: '17702',
    city: 'Williamsport',
    state: 'PA',
    latitude: '41.22888',
    longitude: '-77.013929',
    timeZoneId: 'America/New_York',
  },
  '17703': {
    zip: '17703',
    city: 'Williamsport',
    state: 'PA',
    latitude: '41.23784',
    longitude: '-77.001639',
    timeZoneId: 'America/New_York',
  },
  '17705': {
    zip: '17705',
    city: 'Williamsport',
    state: 'PA',
    latitude: '41.24061',
    longitude: '-77.039118',
    timeZoneId: 'America/New_York',
  },
  '17720': {
    zip: '17720',
    city: 'Antes Fort',
    state: 'PA',
    latitude: '41.239683',
    longitude: '-77.050439',
    timeZoneId: 'America/New_York',
  },
  '17721': {
    zip: '17721',
    city: 'Avis',
    state: 'PA',
    latitude: '41.184921',
    longitude: '-77.321914',
    timeZoneId: 'America/New_York',
  },
  '17723': {
    zip: '17723',
    city: 'Cammal',
    state: 'PA',
    latitude: '41.3916',
    longitude: '-77.47128',
    timeZoneId: 'America/New_York',
  },
  '17724': {
    zip: '17724',
    city: 'Canton',
    state: 'PA',
    latitude: '41.65445',
    longitude: '-76.852356',
    timeZoneId: 'America/New_York',
  },
  '17726': {
    zip: '17726',
    city: 'Castanea',
    state: 'PA',
    latitude: '41.121972',
    longitude: '-77.433801',
    timeZoneId: 'America/New_York',
  },
  '17727': {
    zip: '17727',
    city: 'Cedar Run',
    state: 'PA',
    latitude: '41.497301',
    longitude: '-77.490054',
    timeZoneId: 'America/New_York',
  },
  '17728': {
    zip: '17728',
    city: 'Cogan Station',
    state: 'PA',
    latitude: '41.32548',
    longitude: '-77.0469',
    timeZoneId: 'America/New_York',
  },
  '17729': {
    zip: '17729',
    city: 'Cross Fork',
    state: 'PA',
    latitude: '41.505883',
    longitude: '-77.735689',
    timeZoneId: 'America/New_York',
  },
  '17730': {
    zip: '17730',
    city: 'Dewart',
    state: 'PA',
    latitude: '41.110074',
    longitude: '-76.875058',
    timeZoneId: 'America/New_York',
  },
  '17731': {
    zip: '17731',
    city: 'Eagles Mere',
    state: 'PA',
    latitude: '41.409817',
    longitude: '-76.585625',
    timeZoneId: 'America/New_York',
  },
  '17735': {
    zip: '17735',
    city: 'Grover',
    state: 'PA',
    latitude: '41.614555',
    longitude: '-76.864981',
    timeZoneId: 'America/New_York',
  },
  '17737': {
    zip: '17737',
    city: 'Hughesville',
    state: 'PA',
    latitude: '41.25774',
    longitude: '-76.71378',
    timeZoneId: 'America/New_York',
  },
  '17739': {
    zip: '17739',
    city: 'Jersey Mills',
    state: 'PA',
    latitude: '41.408022',
    longitude: '-77.457577',
    timeZoneId: 'America/New_York',
  },
  '17740': {
    zip: '17740',
    city: 'Jersey Shore',
    state: 'PA',
    latitude: '41.200306',
    longitude: '-77.271326',
    timeZoneId: 'America/New_York',
  },
  '17742': {
    zip: '17742',
    city: 'Lairdsville',
    state: 'PA',
    latitude: '41.233339',
    longitude: '-76.588125',
    timeZoneId: 'America/New_York',
  },
  '17744': {
    zip: '17744',
    city: 'Linden',
    state: 'PA',
    latitude: '41.271905',
    longitude: '-77.162229',
    timeZoneId: 'America/New_York',
  },
  '17745': {
    zip: '17745',
    city: 'Lock Haven',
    state: 'PA',
    latitude: '41.134686',
    longitude: '-77.449701',
    timeZoneId: 'America/New_York',
  },
  '17747': {
    zip: '17747',
    city: 'Loganton',
    state: 'PA',
    latitude: '41.02992',
    longitude: '-77.317212',
    timeZoneId: 'America/New_York',
  },
  '17748': {
    zip: '17748',
    city: 'Mc Elhattan',
    state: 'PA',
    latitude: '41.149722',
    longitude: '-77.365556',
    timeZoneId: 'America/New_York',
  },
  '17749': {
    zip: '17749',
    city: 'Mc Ewensville',
    state: 'PA',
    latitude: '41.073697',
    longitude: '-76.821257',
    timeZoneId: 'America/New_York',
  },
  '17750': {
    zip: '17750',
    city: 'Mackeyville',
    state: 'PA',
    latitude: '41.055114',
    longitude: '-77.446721',
    timeZoneId: 'America/New_York',
  },
  '17751': {
    zip: '17751',
    city: 'Mill Hall',
    state: 'PA',
    latitude: '41.106295',
    longitude: '-77.488495',
    timeZoneId: 'America/New_York',
  },
  '17752': {
    zip: '17752',
    city: 'Montgomery',
    state: 'PA',
    latitude: '41.173173',
    longitude: '-76.873541',
    timeZoneId: 'America/New_York',
  },
  '17754': {
    zip: '17754',
    city: 'Montoursville',
    state: 'PA',
    latitude: '41.257765',
    longitude: '-76.927599',
    timeZoneId: 'America/New_York',
  },
  '17756': {
    zip: '17756',
    city: 'Muncy',
    state: 'PA',
    latitude: '41.199614',
    longitude: '-76.786403',
    timeZoneId: 'America/New_York',
  },
  '17758': {
    zip: '17758',
    city: 'Muncy Valley',
    state: 'PA',
    latitude: '41.371851',
    longitude: '-76.496126',
    timeZoneId: 'America/New_York',
  },
  '17760': {
    zip: '17760',
    city: 'North Bend',
    state: 'PA',
    latitude: '41.352627',
    longitude: '-77.700989',
    timeZoneId: 'America/New_York',
  },
  '17762': {
    zip: '17762',
    city: 'Picture Rocks',
    state: 'PA',
    latitude: '41.278642',
    longitude: '-76.711572',
    timeZoneId: 'America/New_York',
  },
  '17763': {
    zip: '17763',
    city: 'Ralston',
    state: 'PA',
    latitude: '41.507838',
    longitude: '-76.966963',
    timeZoneId: 'America/New_York',
  },
  '17764': {
    zip: '17764',
    city: 'Renovo',
    state: 'PA',
    latitude: '41.33586',
    longitude: '-77.73792',
    timeZoneId: 'America/New_York',
  },
  '17765': {
    zip: '17765',
    city: 'Roaring Branch',
    state: 'PA',
    latitude: '41.555628',
    longitude: '-76.892895',
    timeZoneId: 'America/New_York',
  },
  '17768': {
    zip: '17768',
    city: 'Shunk',
    state: 'PA',
    latitude: '41.533107',
    longitude: '-76.746568',
    timeZoneId: 'America/New_York',
  },
  '17769': {
    zip: '17769',
    city: 'Slate Run',
    state: 'PA',
    latitude: '41.497576',
    longitude: '-77.494553',
    timeZoneId: 'America/New_York',
  },
  '17771': {
    zip: '17771',
    city: 'Trout Run',
    state: 'PA',
    latitude: '41.4228',
    longitude: '-77.0307',
    timeZoneId: 'America/New_York',
  },
  '17772': {
    zip: '17772',
    city: 'Turbotville',
    state: 'PA',
    latitude: '41.100974',
    longitude: '-76.773819',
    timeZoneId: 'America/New_York',
  },
  '17774': {
    zip: '17774',
    city: 'Unityville',
    state: 'PA',
    latitude: '41.242038',
    longitude: '-76.522091',
    timeZoneId: 'America/New_York',
  },
  '17776': {
    zip: '17776',
    city: 'Waterville',
    state: 'PA',
    latitude: '41.33189',
    longitude: '-77.344196',
    timeZoneId: 'America/New_York',
  },
  '17777': {
    zip: '17777',
    city: 'Watsontown',
    state: 'PA',
    latitude: '41.092752',
    longitude: '-76.861603',
    timeZoneId: 'America/New_York',
  },
  '17778': {
    zip: '17778',
    city: 'Westport',
    state: 'PA',
    latitude: '41.309029',
    longitude: '-77.972308',
    timeZoneId: 'America/New_York',
  },
  '17779': {
    zip: '17779',
    city: 'Woolrich',
    state: 'PA',
    latitude: '41.188628',
    longitude: '-77.374922',
    timeZoneId: 'America/New_York',
  },
  '17801': {
    zip: '17801',
    city: 'Sunbury',
    state: 'PA',
    latitude: '40.84698',
    longitude: '-76.7715',
    timeZoneId: 'America/New_York',
  },
  '17810': {
    zip: '17810',
    city: 'Allenwood',
    state: 'PA',
    latitude: '41.11786',
    longitude: '-77.022795',
    timeZoneId: 'America/New_York',
  },
  '17812': {
    zip: '17812',
    city: 'Beaver Springs',
    state: 'PA',
    latitude: '40.7701',
    longitude: '-77.247271',
    timeZoneId: 'America/New_York',
  },
  '17813': {
    zip: '17813',
    city: 'Beavertown',
    state: 'PA',
    latitude: '40.752664',
    longitude: '-77.170638',
    timeZoneId: 'America/New_York',
  },
  '17814': {
    zip: '17814',
    city: 'Benton',
    state: 'PA',
    latitude: '41.2347',
    longitude: '-76.34742',
    timeZoneId: 'America/New_York',
  },
  '17815': {
    zip: '17815',
    city: 'Bloomsburg',
    state: 'PA',
    latitude: '41.007884',
    longitude: '-76.445829',
    timeZoneId: 'America/New_York',
  },
  '17820': {
    zip: '17820',
    city: 'Catawissa',
    state: 'PA',
    latitude: '40.94478',
    longitude: '-76.44756',
    timeZoneId: 'America/New_York',
  },
  '17821': {
    zip: '17821',
    city: 'Danville',
    state: 'PA',
    latitude: '40.9743',
    longitude: '-76.6134',
    timeZoneId: 'America/New_York',
  },
  '17822': {
    zip: '17822',
    city: 'Danville',
    state: 'PA',
    latitude: '40.95835',
    longitude: '-76.609495',
    timeZoneId: 'America/New_York',
  },
  '17823': {
    zip: '17823',
    city: 'Dornsife',
    state: 'PA',
    latitude: '40.757282',
    longitude: '-76.762227',
    timeZoneId: 'America/New_York',
  },
  '17824': {
    zip: '17824',
    city: 'Elysburg',
    state: 'PA',
    latitude: '40.864589',
    longitude: '-76.553163',
    timeZoneId: 'America/New_York',
  },
  '17827': {
    zip: '17827',
    city: 'Freeburg',
    state: 'PA',
    latitude: '40.763907',
    longitude: '-76.942194',
    timeZoneId: 'America/New_York',
  },
  '17829': {
    zip: '17829',
    city: 'Hartleton',
    state: 'PA',
    latitude: '40.863377',
    longitude: '-77.236848',
    timeZoneId: 'America/New_York',
  },
  '17830': {
    zip: '17830',
    city: 'Herndon',
    state: 'PA',
    latitude: '40.690745',
    longitude: '-76.78971',
    timeZoneId: 'America/New_York',
  },
  '17831': {
    zip: '17831',
    city: 'Hummels Wharf',
    state: 'PA',
    latitude: '40.773405',
    longitude: '-76.964768',
    timeZoneId: 'America/New_York',
  },
  '17832': {
    zip: '17832',
    city: 'Marion Heights',
    state: 'PA',
    latitude: '40.806027',
    longitude: '-76.461774',
    timeZoneId: 'America/New_York',
  },
  '17833': {
    zip: '17833',
    city: 'Kreamer',
    state: 'PA',
    latitude: '40.803174',
    longitude: '-76.964675',
    timeZoneId: 'America/New_York',
  },
  '17834': {
    zip: '17834',
    city: 'Kulpmont',
    state: 'PA',
    latitude: '40.791597',
    longitude: '-76.472043',
    timeZoneId: 'America/New_York',
  },
  '17835': {
    zip: '17835',
    city: 'Laurelton',
    state: 'PA',
    latitude: '40.951972',
    longitude: '-77.192721',
    timeZoneId: 'America/New_York',
  },
  '17836': {
    zip: '17836',
    city: 'Leck Kill',
    state: 'PA',
    latitude: '40.711436',
    longitude: '-76.629823',
    timeZoneId: 'America/New_York',
  },
  '17837': {
    zip: '17837',
    city: 'Lewisburg',
    state: 'PA',
    latitude: '40.97274',
    longitude: '-76.91844',
    timeZoneId: 'America/New_York',
  },
  '17840': {
    zip: '17840',
    city: 'Locust Gap',
    state: 'PA',
    latitude: '40.773793',
    longitude: '-76.43707',
    timeZoneId: 'America/New_York',
  },
  '17841': {
    zip: '17841',
    city: 'Mc Clure',
    state: 'PA',
    latitude: '40.70832',
    longitude: '-77.3637',
    timeZoneId: 'America/New_York',
  },
  '17842': {
    zip: '17842',
    city: 'Middleburg',
    state: 'PA',
    latitude: '40.794122',
    longitude: '-77.026747',
    timeZoneId: 'America/New_York',
  },
  '17843': {
    zip: '17843',
    city: 'Beaver Springs',
    state: 'PA',
    latitude: '40.76172',
    longitude: '-77.25674',
    timeZoneId: 'America/New_York',
  },
  '17844': {
    zip: '17844',
    city: 'Mifflinburg',
    state: 'PA',
    latitude: '40.91526',
    longitude: '-77.046496',
    timeZoneId: 'America/New_York',
  },
  '17845': {
    zip: '17845',
    city: 'Millmont',
    state: 'PA',
    latitude: '40.864071',
    longitude: '-77.239421',
    timeZoneId: 'America/New_York',
  },
  '17846': {
    zip: '17846',
    city: 'Millville',
    state: 'PA',
    latitude: '41.13528',
    longitude: '-76.51932',
    timeZoneId: 'America/New_York',
  },
  '17847': {
    zip: '17847',
    city: 'Milton',
    state: 'PA',
    latitude: '41.014839',
    longitude: '-76.846934',
    timeZoneId: 'America/New_York',
  },
  '17850': {
    zip: '17850',
    city: 'Montandon',
    state: 'PA',
    latitude: '40.972467',
    longitude: '-76.850729',
    timeZoneId: 'America/New_York',
  },
  '17851': {
    zip: '17851',
    city: 'Mount Carmel',
    state: 'PA',
    latitude: '40.80168',
    longitude: '-76.4307',
    timeZoneId: 'America/New_York',
  },
  '17853': {
    zip: '17853',
    city: 'Mount Pleasant Mills',
    state: 'PA',
    latitude: '40.696004',
    longitude: '-77.008001',
    timeZoneId: 'America/New_York',
  },
  '17855': {
    zip: '17855',
    city: 'New Berlin',
    state: 'PA',
    latitude: '40.883152',
    longitude: '-76.982883',
    timeZoneId: 'America/New_York',
  },
  '17856': {
    zip: '17856',
    city: 'New Columbia',
    state: 'PA',
    latitude: '41.04372',
    longitude: '-76.8849',
    timeZoneId: 'America/New_York',
  },
  '17857': {
    zip: '17857',
    city: 'Northumberland',
    state: 'PA',
    latitude: '40.89352',
    longitude: '-76.797765',
    timeZoneId: 'America/New_York',
  },
  '17858': {
    zip: '17858',
    city: 'Numidia',
    state: 'PA',
    latitude: '40.878382',
    longitude: '-76.40328',
    timeZoneId: 'America/New_York',
  },
  '17859': {
    zip: '17859',
    city: 'Orangeville',
    state: 'PA',
    latitude: '41.076734',
    longitude: '-76.413424',
    timeZoneId: 'America/New_York',
  },
  '17860': {
    zip: '17860',
    city: 'Paxinos',
    state: 'PA',
    latitude: '40.833938',
    longitude: '-76.639647',
    timeZoneId: 'America/New_York',
  },
  '17861': {
    zip: '17861',
    city: 'Paxtonville',
    state: 'PA',
    latitude: '40.76911',
    longitude: '-77.084627',
    timeZoneId: 'America/New_York',
  },
  '17862': {
    zip: '17862',
    city: 'Penns Creek',
    state: 'PA',
    latitude: '40.859673',
    longitude: '-77.067203',
    timeZoneId: 'America/New_York',
  },
  '17864': {
    zip: '17864',
    city: 'Port Trevorton',
    state: 'PA',
    latitude: '40.70232',
    longitude: '-76.9098',
    timeZoneId: 'America/New_York',
  },
  '17865': {
    zip: '17865',
    city: 'Potts Grove',
    state: 'PA',
    latitude: '40.996303',
    longitude: '-76.789962',
    timeZoneId: 'America/New_York',
  },
  '17866': {
    zip: '17866',
    city: 'Coal Township',
    state: 'PA',
    latitude: '40.786065',
    longitude: '-76.555465',
    timeZoneId: 'America/New_York',
  },
  '17867': {
    zip: '17867',
    city: 'Rebuck',
    state: 'PA',
    latitude: '40.71271',
    longitude: '-76.735861',
    timeZoneId: 'America/New_York',
  },
  '17868': {
    zip: '17868',
    city: 'Riverside',
    state: 'PA',
    latitude: '40.957337',
    longitude: '-76.628912',
    timeZoneId: 'America/New_York',
  },
  '17870': {
    zip: '17870',
    city: 'Selinsgrove',
    state: 'PA',
    latitude: '40.83168',
    longitude: '-76.90362',
    timeZoneId: 'America/New_York',
  },
  '17872': {
    zip: '17872',
    city: 'Shamokin',
    state: 'PA',
    latitude: '40.82034',
    longitude: '-76.55568',
    timeZoneId: 'America/New_York',
  },
  '17876': {
    zip: '17876',
    city: 'Shamokin Dam',
    state: 'PA',
    latitude: '40.848931',
    longitude: '-76.824586',
    timeZoneId: 'America/New_York',
  },
  '17877': {
    zip: '17877',
    city: 'Snydertown',
    state: 'PA',
    latitude: '40.876221',
    longitude: '-76.666737',
    timeZoneId: 'America/New_York',
  },
  '17878': {
    zip: '17878',
    city: 'Stillwater',
    state: 'PA',
    latitude: '41.153486',
    longitude: '-76.375467',
    timeZoneId: 'America/New_York',
  },
  '17880': {
    zip: '17880',
    city: 'Swengel',
    state: 'PA',
    latitude: '40.94079',
    longitude: '-77.074448',
    timeZoneId: 'America/New_York',
  },
  '17881': {
    zip: '17881',
    city: 'Trevorton',
    state: 'PA',
    latitude: '40.780986',
    longitude: '-76.676793',
    timeZoneId: 'America/New_York',
  },
  '17882': {
    zip: '17882',
    city: 'Troxelville',
    state: 'PA',
    latitude: '40.804238',
    longitude: '-77.208749',
    timeZoneId: 'America/New_York',
  },
  '17883': {
    zip: '17883',
    city: 'Vicksburg',
    state: 'PA',
    latitude: '40.937764',
    longitude: '-76.990613',
    timeZoneId: 'America/New_York',
  },
  '17884': {
    zip: '17884',
    city: 'Washingtonville',
    state: 'PA',
    latitude: '41.050669',
    longitude: '-76.673774',
    timeZoneId: 'America/New_York',
  },
  '17885': {
    zip: '17885',
    city: 'Weikert',
    state: 'PA',
    latitude: '40.859144',
    longitude: '-77.298172',
    timeZoneId: 'America/New_York',
  },
  '17886': {
    zip: '17886',
    city: 'West Milton',
    state: 'PA',
    latitude: '41.022009',
    longitude: '-76.870147',
    timeZoneId: 'America/New_York',
  },
  '17887': {
    zip: '17887',
    city: 'White Deer',
    state: 'PA',
    latitude: '41.077886',
    longitude: '-76.875075',
    timeZoneId: 'America/New_York',
  },
  '17888': {
    zip: '17888',
    city: 'Wilburton',
    state: 'PA',
    latitude: '40.813401',
    longitude: '-76.376948',
    timeZoneId: 'America/New_York',
  },
  '17889': {
    zip: '17889',
    city: 'Winfield',
    state: 'PA',
    latitude: '40.91834',
    longitude: '-76.860052',
    timeZoneId: 'America/New_York',
  },
  '17901': {
    zip: '17901',
    city: 'Pottsville',
    state: 'PA',
    latitude: '40.67556',
    longitude: '-76.23264',
    timeZoneId: 'America/New_York',
  },
  '17920': {
    zip: '17920',
    city: 'Aristes',
    state: 'PA',
    latitude: '40.819609',
    longitude: '-76.340066',
    timeZoneId: 'America/New_York',
  },
  '17921': {
    zip: '17921',
    city: 'Ashland',
    state: 'PA',
    latitude: '40.779226',
    longitude: '-76.336594',
    timeZoneId: 'America/New_York',
  },
  '17922': {
    zip: '17922',
    city: 'Auburn',
    state: 'PA',
    latitude: '40.594751',
    longitude: '-76.154011',
    timeZoneId: 'America/New_York',
  },
  '17923': {
    zip: '17923',
    city: 'Branchdale',
    state: 'PA',
    latitude: '40.674527',
    longitude: '-76.324675',
    timeZoneId: 'America/New_York',
  },
  '17925': {
    zip: '17925',
    city: 'Brockton',
    state: 'PA',
    latitude: '40.748486',
    longitude: '-76.067276',
    timeZoneId: 'America/New_York',
  },
  '17929': {
    zip: '17929',
    city: 'Cressona',
    state: 'PA',
    latitude: '40.628948',
    longitude: '-76.193868',
    timeZoneId: 'America/New_York',
  },
  '17930': {
    zip: '17930',
    city: 'Cumbola',
    state: 'PA',
    latitude: '40.709559',
    longitude: '-76.138383',
    timeZoneId: 'America/New_York',
  },
  '17931': {
    zip: '17931',
    city: 'Frackville',
    state: 'PA',
    latitude: '40.77931',
    longitude: '-76.233926',
    timeZoneId: 'America/New_York',
  },
  '17932': {
    zip: '17932',
    city: 'Frackville',
    state: 'PA',
    latitude: '40.78269',
    longitude: '-76.232139',
    timeZoneId: 'America/New_York',
  },
  '17933': {
    zip: '17933',
    city: 'Friedensburg',
    state: 'PA',
    latitude: '40.600739',
    longitude: '-76.239609',
    timeZoneId: 'America/New_York',
  },
  '17934': {
    zip: '17934',
    city: 'Gilberton',
    state: 'PA',
    latitude: '40.800835',
    longitude: '-76.217462',
    timeZoneId: 'America/New_York',
  },
  '17935': {
    zip: '17935',
    city: 'Girardville',
    state: 'PA',
    latitude: '40.790036',
    longitude: '-76.280325',
    timeZoneId: 'America/New_York',
  },
  '17936': {
    zip: '17936',
    city: 'Gordon',
    state: 'PA',
    latitude: '40.748751',
    longitude: '-76.339174',
    timeZoneId: 'America/New_York',
  },
  '17938': {
    zip: '17938',
    city: 'Hegins',
    state: 'PA',
    latitude: '40.655278',
    longitude: '-76.446944',
    timeZoneId: 'America/New_York',
  },
  '17941': {
    zip: '17941',
    city: 'Klingerstown',
    state: 'PA',
    latitude: '40.726516',
    longitude: '-76.605887',
    timeZoneId: 'America/New_York',
  },
  '17943': {
    zip: '17943',
    city: 'Lavelle',
    state: 'PA',
    latitude: '40.763136',
    longitude: '-76.380483',
    timeZoneId: 'America/New_York',
  },
  '17944': {
    zip: '17944',
    city: 'Llewellyn',
    state: 'PA',
    latitude: '40.677452',
    longitude: '-76.276883',
    timeZoneId: 'America/New_York',
  },
  '17945': {
    zip: '17945',
    city: 'Locustdale',
    state: 'PA',
    latitude: '40.692963',
    longitude: '-76.260764',
    timeZoneId: 'America/New_York',
  },
  '17946': {
    zip: '17946',
    city: 'Lost Creek',
    state: 'PA',
    latitude: '40.809304',
    longitude: '-76.24826',
    timeZoneId: 'America/New_York',
  },
  '17948': {
    zip: '17948',
    city: 'Mahanoy City',
    state: 'PA',
    latitude: '40.809592',
    longitude: '-76.138532',
    timeZoneId: 'America/New_York',
  },
  '17949': {
    zip: '17949',
    city: 'Mahanoy Plane',
    state: 'PA',
    latitude: '40.794784',
    longitude: '-76.243733',
    timeZoneId: 'America/New_York',
  },
  '17951': {
    zip: '17951',
    city: 'Mar Lin',
    state: 'PA',
    latitude: '40.677881',
    longitude: '-76.239672',
    timeZoneId: 'America/New_York',
  },
  '17952': {
    zip: '17952',
    city: 'Mary D',
    state: 'PA',
    latitude: '40.7603',
    longitude: '-76.055326',
    timeZoneId: 'America/New_York',
  },
  '17953': {
    zip: '17953',
    city: 'Middleport',
    state: 'PA',
    latitude: '40.727354',
    longitude: '-76.086539',
    timeZoneId: 'America/New_York',
  },
  '17954': {
    zip: '17954',
    city: 'Minersville',
    state: 'PA',
    latitude: '40.690509',
    longitude: '-76.261655',
    timeZoneId: 'America/New_York',
  },
  '17957': {
    zip: '17957',
    city: 'Muir',
    state: 'PA',
    latitude: '40.595974',
    longitude: '-76.518548',
    timeZoneId: 'America/New_York',
  },
  '17959': {
    zip: '17959',
    city: 'New Philadelphia',
    state: 'PA',
    latitude: '40.738984',
    longitude: '-76.109152',
    timeZoneId: 'America/New_York',
  },
  '17960': {
    zip: '17960',
    city: 'New Ringgold',
    state: 'PA',
    latitude: '40.681547',
    longitude: '-75.998948',
    timeZoneId: 'America/New_York',
  },
  '17961': {
    zip: '17961',
    city: 'Orwigsburg',
    state: 'PA',
    latitude: '40.6452',
    longitude: '-76.07844',
    timeZoneId: 'America/New_York',
  },
  '17963': {
    zip: '17963',
    city: 'Pine Grove',
    state: 'PA',
    latitude: '40.54902',
    longitude: '-76.40412',
    timeZoneId: 'America/New_York',
  },
  '17964': {
    zip: '17964',
    city: 'Pitman',
    state: 'PA',
    latitude: '40.702567',
    longitude: '-76.512376',
    timeZoneId: 'America/New_York',
  },
  '17965': {
    zip: '17965',
    city: 'Port Carbon',
    state: 'PA',
    latitude: '40.699019',
    longitude: '-76.165915',
    timeZoneId: 'America/New_York',
  },
  '17966': {
    zip: '17966',
    city: 'Ravine',
    state: 'PA',
    latitude: '40.559216',
    longitude: '-76.330734',
    timeZoneId: 'America/New_York',
  },
  '17967': {
    zip: '17967',
    city: 'Ringtown',
    state: 'PA',
    latitude: '40.849233',
    longitude: '-76.231076',
    timeZoneId: 'America/New_York',
  },
  '17968': {
    zip: '17968',
    city: 'Sacramento',
    state: 'PA',
    latitude: '40.643889',
    longitude: '-76.636667',
    timeZoneId: 'America/New_York',
  },
  '17970': {
    zip: '17970',
    city: 'Saint Clair',
    state: 'PA',
    latitude: '40.746',
    longitude: '-76.20846',
    timeZoneId: 'America/New_York',
  },
  '17972': {
    zip: '17972',
    city: 'Schuylkill Haven',
    state: 'PA',
    latitude: '40.63272',
    longitude: '-76.15512',
    timeZoneId: 'America/New_York',
  },
  '17974': {
    zip: '17974',
    city: 'Seltzer',
    state: 'PA',
    latitude: '40.696244',
    longitude: '-76.234875',
    timeZoneId: 'America/New_York',
  },
  '17976': {
    zip: '17976',
    city: 'Shenandoah',
    state: 'PA',
    latitude: '40.816695',
    longitude: '-76.202555',
    timeZoneId: 'America/New_York',
  },
  '17978': {
    zip: '17978',
    city: 'Spring Glen',
    state: 'PA',
    latitude: '40.635438',
    longitude: '-76.645327',
    timeZoneId: 'America/New_York',
  },
  '17979': {
    zip: '17979',
    city: 'Summit Station',
    state: 'PA',
    latitude: '40.576003',
    longitude: '-76.192712',
    timeZoneId: 'America/New_York',
  },
  '17980': {
    zip: '17980',
    city: 'Tower City',
    state: 'PA',
    latitude: '40.591905',
    longitude: '-76.538022',
    timeZoneId: 'America/New_York',
  },
  '17981': {
    zip: '17981',
    city: 'Tremont',
    state: 'PA',
    latitude: '40.6266',
    longitude: '-76.40712',
    timeZoneId: 'America/New_York',
  },
  '17982': {
    zip: '17982',
    city: 'Tuscarora',
    state: 'PA',
    latitude: '40.766589',
    longitude: '-76.04185',
    timeZoneId: 'America/New_York',
  },
  '17983': {
    zip: '17983',
    city: 'Valley View',
    state: 'PA',
    latitude: '40.646385',
    longitude: '-76.546473',
    timeZoneId: 'America/New_York',
  },
  '17985': {
    zip: '17985',
    city: 'Zion Grove',
    state: 'PA',
    latitude: '40.9413',
    longitude: '-76.218',
    timeZoneId: 'America/New_York',
  },
  '18001': {
    zip: '18001',
    city: 'Lehigh Valley',
    state: 'PA',
    latitude: '40.580333',
    longitude: '-75.474981',
    timeZoneId: 'America/New_York',
  },
  '18002': {
    zip: '18002',
    city: 'Lehigh Valley',
    state: 'PA',
    latitude: '40.584058',
    longitude: '-75.47032',
    timeZoneId: 'America/New_York',
  },
  '18003': {
    zip: '18003',
    city: 'Lehigh Valley',
    state: 'PA',
    latitude: '40.644716',
    longitude: '-75.434684',
    timeZoneId: 'America/New_York',
  },
  '18011': {
    zip: '18011',
    city: 'Alburtis',
    state: 'PA',
    latitude: '40.510612',
    longitude: '-75.604922',
    timeZoneId: 'America/New_York',
  },
  '18012': {
    zip: '18012',
    city: 'Aquashicola',
    state: 'PA',
    latitude: '40.808358',
    longitude: '-75.772424',
    timeZoneId: 'America/New_York',
  },
  '18013': {
    zip: '18013',
    city: 'Bangor',
    state: 'PA',
    latitude: '40.873341',
    longitude: '-75.187858',
    timeZoneId: 'America/New_York',
  },
  '18014': {
    zip: '18014',
    city: 'Bath',
    state: 'PA',
    latitude: '40.757035',
    longitude: '-75.401241',
    timeZoneId: 'America/New_York',
  },
  '18015': {
    zip: '18015',
    city: 'Bethlehem',
    state: 'PA',
    latitude: '40.601395',
    longitude: '-75.369112',
    timeZoneId: 'America/New_York',
  },
  '18016': {
    zip: '18016',
    city: 'Bethlehem',
    state: 'PA',
    latitude: '40.622362',
    longitude: '-75.375164',
    timeZoneId: 'America/New_York',
  },
  '18017': {
    zip: '18017',
    city: 'Bethlehem',
    state: 'PA',
    latitude: '40.653231',
    longitude: '-75.336849',
    timeZoneId: 'America/New_York',
  },
  '18018': {
    zip: '18018',
    city: 'Bethlehem',
    state: 'PA',
    latitude: '40.624548',
    longitude: '-75.405334',
    timeZoneId: 'America/New_York',
  },
  '18020': {
    zip: '18020',
    city: 'Bethlehem',
    state: 'PA',
    latitude: '40.626883',
    longitude: '-75.36957',
    timeZoneId: 'America/New_York',
  },
  '18025': {
    zip: '18025',
    city: 'Bethlehem',
    state: 'PA',
    latitude: '40.625072',
    longitude: '-75.371201',
    timeZoneId: 'America/New_York',
  },
  '18030': {
    zip: '18030',
    city: 'Bowmanstown',
    state: 'PA',
    latitude: '40.80303',
    longitude: '-75.658473',
    timeZoneId: 'America/New_York',
  },
  '18031': {
    zip: '18031',
    city: 'Breinigsville',
    state: 'PA',
    latitude: '40.555364',
    longitude: '-75.660049',
    timeZoneId: 'America/New_York',
  },
  '18032': {
    zip: '18032',
    city: 'Catasauqua',
    state: 'PA',
    latitude: '40.650574',
    longitude: '-75.468644',
    timeZoneId: 'America/New_York',
  },
  '18034': {
    zip: '18034',
    city: 'Center Valley',
    state: 'PA',
    latitude: '40.548288',
    longitude: '-75.425567',
    timeZoneId: 'America/New_York',
  },
  '18035': {
    zip: '18035',
    city: 'Cherryville',
    state: 'PA',
    latitude: '40.752413',
    longitude: '-75.541641',
    timeZoneId: 'America/New_York',
  },
  '18036': {
    zip: '18036',
    city: 'Coopersburg',
    state: 'PA',
    latitude: '40.515752',
    longitude: '-75.396205',
    timeZoneId: 'America/New_York',
  },
  '18037': {
    zip: '18037',
    city: 'Coplay',
    state: 'PA',
    latitude: '40.68312',
    longitude: '-75.51222',
    timeZoneId: 'America/New_York',
  },
  '18038': {
    zip: '18038',
    city: 'Danielsville',
    state: 'PA',
    latitude: '40.792991',
    longitude: '-75.486968',
    timeZoneId: 'America/New_York',
  },
  '18039': {
    zip: '18039',
    city: 'Durham',
    state: 'PA',
    latitude: '40.584196',
    longitude: '-75.227453',
    timeZoneId: 'America/New_York',
  },
  '18040': {
    zip: '18040',
    city: 'Easton',
    state: 'PA',
    latitude: '40.719',
    longitude: '-75.22026',
    timeZoneId: 'America/New_York',
  },
  '18041': {
    zip: '18041',
    city: 'East Greenville',
    state: 'PA',
    latitude: '40.415749',
    longitude: '-75.510509',
    timeZoneId: 'America/New_York',
  },
  '18042': {
    zip: '18042',
    city: 'Easton',
    state: 'PA',
    latitude: '40.685899',
    longitude: '-75.240754',
    timeZoneId: 'America/New_York',
  },
  '18043': {
    zip: '18043',
    city: 'Easton',
    state: 'PA',
    latitude: '40.688694',
    longitude: '-75.219639',
    timeZoneId: 'America/New_York',
  },
  '18044': {
    zip: '18044',
    city: 'Easton',
    state: 'PA',
    latitude: '40.68463',
    longitude: '-75.22238',
    timeZoneId: 'America/New_York',
  },
  '18045': {
    zip: '18045',
    city: 'Easton',
    state: 'PA',
    latitude: '40.698',
    longitude: '-75.27342',
    timeZoneId: 'America/New_York',
  },
  '18046': {
    zip: '18046',
    city: 'East Texas',
    state: 'PA',
    latitude: '40.547616',
    longitude: '-75.560939',
    timeZoneId: 'America/New_York',
  },
  '18049': {
    zip: '18049',
    city: 'Emmaus',
    state: 'PA',
    latitude: '40.534857',
    longitude: '-75.505965',
    timeZoneId: 'America/New_York',
  },
  '18051': {
    zip: '18051',
    city: 'Fogelsville',
    state: 'PA',
    latitude: '40.594749',
    longitude: '-75.651281',
    timeZoneId: 'America/New_York',
  },
  '18052': {
    zip: '18052',
    city: 'Whitehall',
    state: 'PA',
    latitude: '40.658588',
    longitude: '-75.491844',
    timeZoneId: 'America/New_York',
  },
  '18053': {
    zip: '18053',
    city: 'Germansville',
    state: 'PA',
    latitude: '40.692506',
    longitude: '-75.655145',
    timeZoneId: 'America/New_York',
  },
  '18054': {
    zip: '18054',
    city: 'Green Lane',
    state: 'PA',
    latitude: '40.342495',
    longitude: '-75.438474',
    timeZoneId: 'America/New_York',
  },
  '18055': {
    zip: '18055',
    city: 'Hellertown',
    state: 'PA',
    latitude: '40.579925',
    longitude: '-75.333249',
    timeZoneId: 'America/New_York',
  },
  '18056': {
    zip: '18056',
    city: 'Hereford',
    state: 'PA',
    latitude: '40.410555',
    longitude: '-75.761626',
    timeZoneId: 'America/New_York',
  },
  '18058': {
    zip: '18058',
    city: 'Kunkletown',
    state: 'PA',
    latitude: '40.930437',
    longitude: '-75.478013',
    timeZoneId: 'America/New_York',
  },
  '18059': {
    zip: '18059',
    city: 'Laurys Station',
    state: 'PA',
    latitude: '40.727455',
    longitude: '-75.535044',
    timeZoneId: 'America/New_York',
  },
  '18060': {
    zip: '18060',
    city: 'Limeport',
    state: 'PA',
    latitude: '40.507213',
    longitude: '-75.445457',
    timeZoneId: 'America/New_York',
  },
  '18062': {
    zip: '18062',
    city: 'Macungie',
    state: 'PA',
    latitude: '40.527398',
    longitude: '-75.560135',
    timeZoneId: 'America/New_York',
  },
  '18063': {
    zip: '18063',
    city: 'Martins Creek',
    state: 'PA',
    latitude: '40.782042',
    longitude: '-75.187733',
    timeZoneId: 'America/New_York',
  },
  '18064': {
    zip: '18064',
    city: 'Nazareth',
    state: 'PA',
    latitude: '40.74585',
    longitude: '-75.320102',
    timeZoneId: 'America/New_York',
  },
  '18065': {
    zip: '18065',
    city: 'Neffs',
    state: 'PA',
    latitude: '40.69682',
    longitude: '-75.609024',
    timeZoneId: 'America/New_York',
  },
  '18066': {
    zip: '18066',
    city: 'New Tripoli',
    state: 'PA',
    latitude: '40.676249',
    longitude: '-75.744159',
    timeZoneId: 'America/New_York',
  },
  '18067': {
    zip: '18067',
    city: 'Northampton',
    state: 'PA',
    latitude: '40.700621',
    longitude: '-75.486647',
    timeZoneId: 'America/New_York',
  },
  '18068': {
    zip: '18068',
    city: 'Old Zionsville',
    state: 'PA',
    latitude: '40.487427',
    longitude: '-75.521144',
    timeZoneId: 'America/New_York',
  },
  '18069': {
    zip: '18069',
    city: 'Orefield',
    state: 'PA',
    latitude: '40.618409',
    longitude: '-75.594982',
    timeZoneId: 'America/New_York',
  },
  '18070': {
    zip: '18070',
    city: 'Palm',
    state: 'PA',
    latitude: '40.433608',
    longitude: '-75.542027',
    timeZoneId: 'America/New_York',
  },
  '18071': {
    zip: '18071',
    city: 'Palmerton',
    state: 'PA',
    latitude: '40.808319',
    longitude: '-75.616978',
    timeZoneId: 'America/New_York',
  },
  '18072': {
    zip: '18072',
    city: 'Pen Argyl',
    state: 'PA',
    latitude: '40.860324',
    longitude: '-75.257256',
    timeZoneId: 'America/New_York',
  },
  '18073': {
    zip: '18073',
    city: 'Pennsburg',
    state: 'PA',
    latitude: '40.3926',
    longitude: '-75.4824',
    timeZoneId: 'America/New_York',
  },
  '18074': {
    zip: '18074',
    city: 'Perkiomenville',
    state: 'PA',
    latitude: '40.319879',
    longitude: '-75.517672',
    timeZoneId: 'America/New_York',
  },
  '18076': {
    zip: '18076',
    city: 'Red Hill',
    state: 'PA',
    latitude: '40.377723',
    longitude: '-75.486191',
    timeZoneId: 'America/New_York',
  },
  '18077': {
    zip: '18077',
    city: 'Riegelsville',
    state: 'PA',
    latitude: '40.559099',
    longitude: '-75.236806',
    timeZoneId: 'America/New_York',
  },
  '18078': {
    zip: '18078',
    city: 'Schnecksville',
    state: 'PA',
    latitude: '40.678413',
    longitude: '-75.609307',
    timeZoneId: 'America/New_York',
  },
  '18079': {
    zip: '18079',
    city: 'Slatedale',
    state: 'PA',
    latitude: '40.742918',
    longitude: '-75.653119',
    timeZoneId: 'America/New_York',
  },
  '18080': {
    zip: '18080',
    city: 'Slatington',
    state: 'PA',
    latitude: '40.734472',
    longitude: '-75.615565',
    timeZoneId: 'America/New_York',
  },
  '18081': {
    zip: '18081',
    city: 'Springtown',
    state: 'PA',
    latitude: '40.554746',
    longitude: '-75.289503',
    timeZoneId: 'America/New_York',
  },
  '18083': {
    zip: '18083',
    city: 'Stockertown',
    state: 'PA',
    latitude: '40.757945',
    longitude: '-75.261381',
    timeZoneId: 'America/New_York',
  },
  '18084': {
    zip: '18084',
    city: 'Sumneytown',
    state: 'PA',
    latitude: '40.323169',
    longitude: '-75.45624',
    timeZoneId: 'America/New_York',
  },
  '18085': {
    zip: '18085',
    city: 'Tatamy',
    state: 'PA',
    latitude: '40.737579',
    longitude: '-75.255429',
    timeZoneId: 'America/New_York',
  },
  '18086': {
    zip: '18086',
    city: 'Treichlers',
    state: 'PA',
    latitude: '40.738471',
    longitude: '-75.546571',
    timeZoneId: 'America/New_York',
  },
  '18087': {
    zip: '18087',
    city: 'Trexlertown',
    state: 'PA',
    latitude: '40.550503',
    longitude: '-75.597132',
    timeZoneId: 'America/New_York',
  },
  '18088': {
    zip: '18088',
    city: 'Walnutport',
    state: 'PA',
    latitude: '40.761638',
    longitude: '-75.56357',
    timeZoneId: 'America/New_York',
  },
  '18091': {
    zip: '18091',
    city: 'Wind Gap',
    state: 'PA',
    latitude: '40.828909',
    longitude: '-75.330959',
    timeZoneId: 'America/New_York',
  },
  '18092': {
    zip: '18092',
    city: 'Zionsville',
    state: 'PA',
    latitude: '40.452403',
    longitude: '-75.510005',
    timeZoneId: 'America/New_York',
  },
  '18098': {
    zip: '18098',
    city: 'Emmaus',
    state: 'PA',
    latitude: '40.52744',
    longitude: '-75.500014',
    timeZoneId: 'America/New_York',
  },
  '18099': {
    zip: '18099',
    city: 'Emmaus',
    state: 'PA',
    latitude: '40.533963',
    longitude: '-75.497936',
    timeZoneId: 'America/New_York',
  },
  '18101': {
    zip: '18101',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.599392',
    longitude: '-75.472595',
    timeZoneId: 'America/New_York',
  },
  '18102': {
    zip: '18102',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.604201',
    longitude: '-75.481145',
    timeZoneId: 'America/New_York',
  },
  '18103': {
    zip: '18103',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.592173',
    longitude: '-75.475434',
    timeZoneId: 'America/New_York',
  },
  '18104': {
    zip: '18104',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.603189',
    longitude: '-75.532165',
    timeZoneId: 'America/New_York',
  },
  '18105': {
    zip: '18105',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.568941',
    longitude: '-75.536719',
    timeZoneId: 'America/New_York',
  },
  '18106': {
    zip: '18106',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.5816',
    longitude: '-75.52758',
    timeZoneId: 'America/New_York',
  },
  '18109': {
    zip: '18109',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.581201',
    longitude: '-75.472059',
    timeZoneId: 'America/New_York',
  },
  '18195': {
    zip: '18195',
    city: 'Allentown',
    state: 'PA',
    latitude: '40.582121',
    longitude: '-75.471549',
    timeZoneId: 'America/New_York',
  },
  '18201': {
    zip: '18201',
    city: 'Hazleton',
    state: 'PA',
    latitude: '40.961962',
    longitude: '-75.978971',
    timeZoneId: 'America/New_York',
  },
  '18202': {
    zip: '18202',
    city: 'Hazleton',
    state: 'PA',
    latitude: '40.960185',
    longitude: '-75.976455',
    timeZoneId: 'America/New_York',
  },
  '18210': {
    zip: '18210',
    city: 'Albrightsville',
    state: 'PA',
    latitude: '40.996515',
    longitude: '-75.572713',
    timeZoneId: 'America/New_York',
  },
  '18211': {
    zip: '18211',
    city: 'Andreas',
    state: 'PA',
    latitude: '40.740448',
    longitude: '-75.826332',
    timeZoneId: 'America/New_York',
  },
  '18212': {
    zip: '18212',
    city: 'Ashfield',
    state: 'PA',
    latitude: '40.836142',
    longitude: '-75.710075',
    timeZoneId: 'America/New_York',
  },
  '18214': {
    zip: '18214',
    city: 'Barnesville',
    state: 'PA',
    latitude: '40.828007',
    longitude: '-76.045192',
    timeZoneId: 'America/New_York',
  },
  '18216': {
    zip: '18216',
    city: 'Beaver Meadows',
    state: 'PA',
    latitude: '40.929263',
    longitude: '-75.912405',
    timeZoneId: 'America/New_York',
  },
  '18218': {
    zip: '18218',
    city: 'Coaldale',
    state: 'PA',
    latitude: '40.820684',
    longitude: '-75.912612',
    timeZoneId: 'America/New_York',
  },
  '18219': {
    zip: '18219',
    city: 'Conyngham',
    state: 'PA',
    latitude: '40.989156',
    longitude: '-76.061544',
    timeZoneId: 'America/New_York',
  },
  '18220': {
    zip: '18220',
    city: 'Delano',
    state: 'PA',
    latitude: '40.840825',
    longitude: '-76.071899',
    timeZoneId: 'America/New_York',
  },
  '18221': {
    zip: '18221',
    city: 'Drifton',
    state: 'PA',
    latitude: '41.005641',
    longitude: '-75.90461',
    timeZoneId: 'America/New_York',
  },
  '18222': {
    zip: '18222',
    city: 'Drums',
    state: 'PA',
    latitude: '41.035414',
    longitude: '-75.952009',
    timeZoneId: 'America/New_York',
  },
  '18223': {
    zip: '18223',
    city: 'Ebervale',
    state: 'PA',
    latitude: '40.983094',
    longitude: '-75.957942',
    timeZoneId: 'America/New_York',
  },
  '18224': {
    zip: '18224',
    city: 'Freeland',
    state: 'PA',
    latitude: '41.01646',
    longitude: '-75.898898',
    timeZoneId: 'America/New_York',
  },
  '18225': {
    zip: '18225',
    city: 'Harleigh',
    state: 'PA',
    latitude: '40.983978',
    longitude: '-75.974463',
    timeZoneId: 'America/New_York',
  },
  '18229': {
    zip: '18229',
    city: 'Jim Thorpe',
    state: 'PA',
    latitude: '40.878328',
    longitude: '-75.737345',
    timeZoneId: 'America/New_York',
  },
  '18230': {
    zip: '18230',
    city: 'Junedale',
    state: 'PA',
    latitude: '40.925984',
    longitude: '-75.938095',
    timeZoneId: 'America/New_York',
  },
  '18231': {
    zip: '18231',
    city: 'Kelayres',
    state: 'PA',
    latitude: '40.902566',
    longitude: '-76.00527',
    timeZoneId: 'America/New_York',
  },
  '18232': {
    zip: '18232',
    city: 'Lansford',
    state: 'PA',
    latitude: '40.834983',
    longitude: '-75.884076',
    timeZoneId: 'America/New_York',
  },
  '18234': {
    zip: '18234',
    city: 'Lattimer Mines',
    state: 'PA',
    latitude: '40.994353',
    longitude: '-75.961265',
    timeZoneId: 'America/New_York',
  },
  '18235': {
    zip: '18235',
    city: 'Lehighton',
    state: 'PA',
    latitude: '40.843037',
    longitude: '-75.694426',
    timeZoneId: 'America/New_York',
  },
  '18237': {
    zip: '18237',
    city: 'McAdoo',
    state: 'PA',
    latitude: '40.893467',
    longitude: '-75.999474',
    timeZoneId: 'America/New_York',
  },
  '18239': {
    zip: '18239',
    city: 'Milnesville',
    state: 'PA',
    latitude: '40.991136',
    longitude: '-75.984084',
    timeZoneId: 'America/New_York',
  },
  '18240': {
    zip: '18240',
    city: 'Nesquehoning',
    state: 'PA',
    latitude: '40.864814',
    longitude: '-75.815635',
    timeZoneId: 'America/New_York',
  },
  '18241': {
    zip: '18241',
    city: 'Nuremberg',
    state: 'PA',
    latitude: '40.938327',
    longitude: '-76.189558',
    timeZoneId: 'America/New_York',
  },
  '18242': {
    zip: '18242',
    city: 'Oneida',
    state: 'PA',
    latitude: '40.909404',
    longitude: '-76.133243',
    timeZoneId: 'America/New_York',
  },
  '18244': {
    zip: '18244',
    city: 'Parryville',
    state: 'PA',
    latitude: '40.822254',
    longitude: '-75.674108',
    timeZoneId: 'America/New_York',
  },
  '18245': {
    zip: '18245',
    city: 'Quakake',
    state: 'PA',
    latitude: '40.841272',
    longitude: '-75.984822',
    timeZoneId: 'America/New_York',
  },
  '18246': {
    zip: '18246',
    city: 'Rock Glen',
    state: 'PA',
    latitude: '40.953322',
    longitude: '-76.162773',
    timeZoneId: 'America/New_York',
  },
  '18247': {
    zip: '18247',
    city: 'Saint Johns',
    state: 'PA',
    latitude: '41.030726',
    longitude: '-76.00702',
    timeZoneId: 'America/New_York',
  },
  '18248': {
    zip: '18248',
    city: 'Sheppton',
    state: 'PA',
    latitude: '40.894965',
    longitude: '-76.115005',
    timeZoneId: 'America/New_York',
  },
  '18249': {
    zip: '18249',
    city: 'Sugarloaf',
    state: 'PA',
    latitude: '40.992038',
    longitude: '-76.07349',
    timeZoneId: 'America/New_York',
  },
  '18250': {
    zip: '18250',
    city: 'Summit Hill',
    state: 'PA',
    latitude: '40.828849',
    longitude: '-75.873626',
    timeZoneId: 'America/New_York',
  },
  '18251': {
    zip: '18251',
    city: 'Sybertsville',
    state: 'PA',
    latitude: '41.000088',
    longitude: '-76.077993',
    timeZoneId: 'America/New_York',
  },
  '18252': {
    zip: '18252',
    city: 'Tamaqua',
    state: 'PA',
    latitude: '40.798861',
    longitude: '-75.97196',
    timeZoneId: 'America/New_York',
  },
  '18254': {
    zip: '18254',
    city: 'Tresckow',
    state: 'PA',
    latitude: '40.913131',
    longitude: '-75.963752',
    timeZoneId: 'America/New_York',
  },
  '18255': {
    zip: '18255',
    city: 'Weatherly',
    state: 'PA',
    latitude: '40.944377',
    longitude: '-75.829049',
    timeZoneId: 'America/New_York',
  },
  '18256': {
    zip: '18256',
    city: 'Weston',
    state: 'PA',
    latitude: '40.941459',
    longitude: '-76.142805',
    timeZoneId: 'America/New_York',
  },
  '18301': {
    zip: '18301',
    city: 'East Stroudsburg',
    state: 'PA',
    latitude: '41.024022',
    longitude: '-75.185712',
    timeZoneId: 'America/New_York',
  },
  '18302': {
    zip: '18302',
    city: 'East Stroudsburg',
    state: 'PA',
    latitude: '41.024159',
    longitude: '-75.185836',
    timeZoneId: 'America/New_York',
  },
  '18320': {
    zip: '18320',
    city: 'Analomink',
    state: 'PA',
    latitude: '41.053321',
    longitude: '-75.223773',
    timeZoneId: 'America/New_York',
  },
  '18321': {
    zip: '18321',
    city: 'Bartonsville',
    state: 'PA',
    latitude: '41.009326',
    longitude: '-75.290126',
    timeZoneId: 'America/New_York',
  },
  '18322': {
    zip: '18322',
    city: 'Brodheadsville',
    state: 'PA',
    latitude: '40.954238',
    longitude: '-75.359905',
    timeZoneId: 'America/New_York',
  },
  '18323': {
    zip: '18323',
    city: 'Buck Hill Falls',
    state: 'PA',
    latitude: '41.187496',
    longitude: '-75.264475',
    timeZoneId: 'America/New_York',
  },
  '18324': {
    zip: '18324',
    city: 'Bushkill',
    state: 'PA',
    latitude: '41.166175',
    longitude: '-74.955517',
    timeZoneId: 'America/New_York',
  },
  '18325': {
    zip: '18325',
    city: 'Canadensis',
    state: 'PA',
    latitude: '41.245123',
    longitude: '-75.227545',
    timeZoneId: 'America/New_York',
  },
  '18326': {
    zip: '18326',
    city: 'Cresco',
    state: 'PA',
    latitude: '41.160326',
    longitude: '-75.252633',
    timeZoneId: 'America/New_York',
  },
  '18327': {
    zip: '18327',
    city: 'Delaware Water Gap',
    state: 'PA',
    latitude: '40.988659',
    longitude: '-75.147989',
    timeZoneId: 'America/New_York',
  },
  '18328': {
    zip: '18328',
    city: 'Dingmans Ferry',
    state: 'PA',
    latitude: '41.227695',
    longitude: '-74.945839',
    timeZoneId: 'America/New_York',
  },
  '18330': {
    zip: '18330',
    city: 'Effort',
    state: 'PA',
    latitude: '40.98202',
    longitude: '-75.453034',
    timeZoneId: 'America/New_York',
  },
  '18331': {
    zip: '18331',
    city: 'Gilbert',
    state: 'PA',
    latitude: '40.957059',
    longitude: '-75.504758',
    timeZoneId: 'America/New_York',
  },
  '18332': {
    zip: '18332',
    city: 'Henryville',
    state: 'PA',
    latitude: '41.081878',
    longitude: '-75.283385',
    timeZoneId: 'America/New_York',
  },
  '18333': {
    zip: '18333',
    city: 'Kresgeville',
    state: 'PA',
    latitude: '40.958388',
    longitude: '-75.501714',
    timeZoneId: 'America/New_York',
  },
  '18334': {
    zip: '18334',
    city: 'Long Pond',
    state: 'PA',
    latitude: '41.094457',
    longitude: '-75.430166',
    timeZoneId: 'America/New_York',
  },
  '18335': {
    zip: '18335',
    city: 'Marshalls Creek',
    state: 'PA',
    latitude: '41.080932',
    longitude: '-75.167868',
    timeZoneId: 'America/New_York',
  },
  '18336': {
    zip: '18336',
    city: 'Matamoras',
    state: 'PA',
    latitude: '41.366905',
    longitude: '-74.707303',
    timeZoneId: 'America/New_York',
  },
  '18337': {
    zip: '18337',
    city: 'Milford',
    state: 'PA',
    latitude: '41.325855',
    longitude: '-74.801827',
    timeZoneId: 'America/New_York',
  },
  '18340': {
    zip: '18340',
    city: 'Millrift',
    state: 'PA',
    latitude: '41.391718',
    longitude: '-74.790682',
    timeZoneId: 'America/New_York',
  },
  '18341': {
    zip: '18341',
    city: 'Minisink Hills',
    state: 'PA',
    latitude: '40.999999',
    longitude: '-75.136178',
    timeZoneId: 'America/New_York',
  },
  '18342': {
    zip: '18342',
    city: 'Mountainhome',
    state: 'PA',
    latitude: '41.177592',
    longitude: '-75.259145',
    timeZoneId: 'America/New_York',
  },
  '18343': {
    zip: '18343',
    city: 'Mount Bethel',
    state: 'PA',
    latitude: '40.892453',
    longitude: '-75.113563',
    timeZoneId: 'America/New_York',
  },
  '18344': {
    zip: '18344',
    city: 'Mount Pocono',
    state: 'PA',
    latitude: '41.120587',
    longitude: '-75.358352',
    timeZoneId: 'America/New_York',
  },
  '18346': {
    zip: '18346',
    city: 'Pocono Summit',
    state: 'PA',
    latitude: '41.108809',
    longitude: '-75.415877',
    timeZoneId: 'America/New_York',
  },
  '18347': {
    zip: '18347',
    city: 'Pocono Lake',
    state: 'PA',
    latitude: '41.153003',
    longitude: '-75.559822',
    timeZoneId: 'America/New_York',
  },
  '18348': {
    zip: '18348',
    city: 'Pocono Lake Preserve',
    state: 'PA',
    latitude: '41.106882',
    longitude: '-75.479231',
    timeZoneId: 'America/New_York',
  },
  '18349': {
    zip: '18349',
    city: 'Pocono Manor',
    state: 'PA',
    latitude: '41.097953',
    longitude: '-75.363955',
    timeZoneId: 'America/New_York',
  },
  '18350': {
    zip: '18350',
    city: 'Pocono Pines',
    state: 'PA',
    latitude: '41.117264',
    longitude: '-75.442241',
    timeZoneId: 'America/New_York',
  },
  '18351': {
    zip: '18351',
    city: 'Portland',
    state: 'PA',
    latitude: '40.927735',
    longitude: '-75.103214',
    timeZoneId: 'America/New_York',
  },
  '18352': {
    zip: '18352',
    city: 'Reeders',
    state: 'PA',
    latitude: '40.989696',
    longitude: '-75.275006',
    timeZoneId: 'America/New_York',
  },
  '18353': {
    zip: '18353',
    city: 'Saylorsburg',
    state: 'PA',
    latitude: '40.925028',
    longitude: '-75.381343',
    timeZoneId: 'America/New_York',
  },
  '18354': {
    zip: '18354',
    city: 'Sciota',
    state: 'PA',
    latitude: '40.919277',
    longitude: '-75.284452',
    timeZoneId: 'America/New_York',
  },
  '18355': {
    zip: '18355',
    city: 'Scotrun',
    state: 'PA',
    latitude: '41.075273',
    longitude: '-75.340247',
    timeZoneId: 'America/New_York',
  },
  '18356': {
    zip: '18356',
    city: 'Shawnee on Delaware',
    state: 'PA',
    latitude: '41.009711',
    longitude: '-75.111422',
    timeZoneId: 'America/New_York',
  },
  '18357': {
    zip: '18357',
    city: 'Skytop',
    state: 'PA',
    latitude: '41.225145',
    longitude: '-75.236122',
    timeZoneId: 'America/New_York',
  },
  '18360': {
    zip: '18360',
    city: 'Stroudsburg',
    state: 'PA',
    latitude: '40.99064',
    longitude: '-75.2263',
    timeZoneId: 'America/New_York',
  },
  '18370': {
    zip: '18370',
    city: 'Swiftwater',
    state: 'PA',
    latitude: '41.08929',
    longitude: '-75.361974',
    timeZoneId: 'America/New_York',
  },
  '18371': {
    zip: '18371',
    city: 'Tamiment',
    state: 'PA',
    latitude: '41.093333',
    longitude: '-75.002222',
    timeZoneId: 'America/New_York',
  },
  '18372': {
    zip: '18372',
    city: 'Tannersville',
    state: 'PA',
    latitude: '41.042009',
    longitude: '-75.319259',
    timeZoneId: 'America/New_York',
  },
  '18403': {
    zip: '18403',
    city: 'Archbald',
    state: 'PA',
    latitude: '41.496372',
    longitude: '-75.569205',
    timeZoneId: 'America/New_York',
  },
  '18405': {
    zip: '18405',
    city: 'Beach Lake',
    state: 'PA',
    latitude: '41.590216',
    longitude: '-75.115025',
    timeZoneId: 'America/New_York',
  },
  '18407': {
    zip: '18407',
    city: 'Carbondale',
    state: 'PA',
    latitude: '41.572651',
    longitude: '-75.499232',
    timeZoneId: 'America/New_York',
  },
  '18410': {
    zip: '18410',
    city: 'Chinchilla',
    state: 'PA',
    latitude: '41.477461',
    longitude: '-75.685159',
    timeZoneId: 'America/New_York',
  },
  '18411': {
    zip: '18411',
    city: 'Clarks Summit',
    state: 'PA',
    latitude: '41.484637',
    longitude: '-75.709934',
    timeZoneId: 'America/New_York',
  },
  '18413': {
    zip: '18413',
    city: 'Clifford',
    state: 'PA',
    latitude: '41.649391',
    longitude: '-75.598833',
    timeZoneId: 'America/New_York',
  },
  '18414': {
    zip: '18414',
    city: 'Dalton',
    state: 'PA',
    latitude: '41.532124',
    longitude: '-75.732122',
    timeZoneId: 'America/New_York',
  },
  '18415': {
    zip: '18415',
    city: 'Damascus',
    state: 'PA',
    latitude: '41.735665',
    longitude: '-75.135097',
    timeZoneId: 'America/New_York',
  },
  '18416': {
    zip: '18416',
    city: 'Elmhurst',
    state: 'PA',
    latitude: '41.373414',
    longitude: '-75.548628',
    timeZoneId: 'America/New_York',
  },
  '18417': {
    zip: '18417',
    city: 'Equinunk',
    state: 'PA',
    latitude: '41.796512',
    longitude: '-75.20884',
    timeZoneId: 'America/New_York',
  },
  '18419': {
    zip: '18419',
    city: 'Factoryville',
    state: 'PA',
    latitude: '41.561219',
    longitude: '-75.786848',
    timeZoneId: 'America/New_York',
  },
  '18420': {
    zip: '18420',
    city: 'Fleetville',
    state: 'PA',
    latitude: '41.598421',
    longitude: '-75.706863',
    timeZoneId: 'America/New_York',
  },
  '18421': {
    zip: '18421',
    city: 'Forest City',
    state: 'PA',
    latitude: '41.651632',
    longitude: '-75.469425',
    timeZoneId: 'America/New_York',
  },
  '18424': {
    zip: '18424',
    city: 'Gouldsboro',
    state: 'PA',
    latitude: '41.24813',
    longitude: '-75.569885',
    timeZoneId: 'America/New_York',
  },
  '18425': {
    zip: '18425',
    city: 'Greeley',
    state: 'PA',
    latitude: '41.448362',
    longitude: '-75.040583',
    timeZoneId: 'America/New_York',
  },
  '18426': {
    zip: '18426',
    city: 'Greentown',
    state: 'PA',
    latitude: '41.366597',
    longitude: '-75.248592',
    timeZoneId: 'America/New_York',
  },
  '18427': {
    zip: '18427',
    city: 'Hamlin',
    state: 'PA',
    latitude: '41.397513',
    longitude: '-75.35019',
    timeZoneId: 'America/New_York',
  },
  '18428': {
    zip: '18428',
    city: 'Hawley',
    state: 'PA',
    latitude: '41.47398',
    longitude: '-75.20508',
    timeZoneId: 'America/New_York',
  },
  '18430': {
    zip: '18430',
    city: 'Herrick Center',
    state: 'PA',
    latitude: '41.761885',
    longitude: '-75.507594',
    timeZoneId: 'America/New_York',
  },
  '18431': {
    zip: '18431',
    city: 'Honesdale',
    state: 'PA',
    latitude: '41.569284',
    longitude: '-75.257159',
    timeZoneId: 'America/New_York',
  },
  '18433': {
    zip: '18433',
    city: 'Jermyn',
    state: 'PA',
    latitude: '41.5334',
    longitude: '-75.542375',
    timeZoneId: 'America/New_York',
  },
  '18434': {
    zip: '18434',
    city: 'Jessup',
    state: 'PA',
    latitude: '41.473584',
    longitude: '-75.570479',
    timeZoneId: 'America/New_York',
  },
  '18435': {
    zip: '18435',
    city: 'Lackawaxen',
    state: 'PA',
    latitude: '41.511845',
    longitude: '-75.077607',
    timeZoneId: 'America/New_York',
  },
  '18436': {
    zip: '18436',
    city: 'Lake Ariel',
    state: 'PA',
    latitude: '41.40243',
    longitude: '-75.336574',
    timeZoneId: 'America/New_York',
  },
  '18437': {
    zip: '18437',
    city: 'Lake Como',
    state: 'PA',
    latitude: '41.863322',
    longitude: '-75.295137',
    timeZoneId: 'America/New_York',
  },
  '18438': {
    zip: '18438',
    city: 'Lakeville',
    state: 'PA',
    latitude: '41.475833',
    longitude: '-75.1825',
    timeZoneId: 'America/New_York',
  },
  '18439': {
    zip: '18439',
    city: 'Lakewood',
    state: 'PA',
    latitude: '41.814208',
    longitude: '-75.36959',
    timeZoneId: 'America/New_York',
  },
  '18440': {
    zip: '18440',
    city: 'La Plume',
    state: 'PA',
    latitude: '41.558059',
    longitude: '-75.753488',
    timeZoneId: 'America/New_York',
  },
  '18441': {
    zip: '18441',
    city: 'Lenoxville',
    state: 'PA',
    latitude: '41.6652',
    longitude: '-75.64368',
    timeZoneId: 'America/New_York',
  },
  '18443': {
    zip: '18443',
    city: 'Milanville',
    state: 'PA',
    latitude: '41.654818',
    longitude: '-75.083651',
    timeZoneId: 'America/New_York',
  },
  '18444': {
    zip: '18444',
    city: 'Moscow',
    state: 'PA',
    latitude: '41.350221',
    longitude: '-75.547811',
    timeZoneId: 'America/New_York',
  },
  '18445': {
    zip: '18445',
    city: 'Newfoundland',
    state: 'PA',
    latitude: '41.28987',
    longitude: '-75.399598',
    timeZoneId: 'America/New_York',
  },
  '18446': {
    zip: '18446',
    city: 'Nicholson',
    state: 'PA',
    latitude: '41.627472',
    longitude: '-75.785332',
    timeZoneId: 'America/New_York',
  },
  '18447': {
    zip: '18447',
    city: 'Olyphant',
    state: 'PA',
    latitude: '41.468875',
    longitude: '-75.598476',
    timeZoneId: 'America/New_York',
  },
  '18448': {
    zip: '18448',
    city: 'Olyphant',
    state: 'PA',
    latitude: '41.451993',
    longitude: '-75.580207',
    timeZoneId: 'America/New_York',
  },
  '18449': {
    zip: '18449',
    city: 'Orson',
    state: 'PA',
    latitude: '41.814594',
    longitude: '-75.449971',
    timeZoneId: 'America/New_York',
  },
  '18451': {
    zip: '18451',
    city: 'Paupack',
    state: 'PA',
    latitude: '41.383267',
    longitude: '-75.217471',
    timeZoneId: 'America/New_York',
  },
  '18452': {
    zip: '18452',
    city: 'Peckville',
    state: 'PA',
    latitude: '41.476387',
    longitude: '-75.587851',
    timeZoneId: 'America/New_York',
  },
  '18453': {
    zip: '18453',
    city: 'Pleasant Mount',
    state: 'PA',
    latitude: '41.732223',
    longitude: '-75.389178',
    timeZoneId: 'America/New_York',
  },
  '18454': {
    zip: '18454',
    city: 'Poyntelle',
    state: 'PA',
    latitude: '41.819727',
    longitude: '-75.420566',
    timeZoneId: 'America/New_York',
  },
  '18455': {
    zip: '18455',
    city: 'Preston Park',
    state: 'PA',
    latitude: '41.866964',
    longitude: '-75.381978',
    timeZoneId: 'America/New_York',
  },
  '18456': {
    zip: '18456',
    city: 'Prompton',
    state: 'PA',
    latitude: '41.574877',
    longitude: '-75.32443',
    timeZoneId: 'America/New_York',
  },
  '18457': {
    zip: '18457',
    city: 'Rowland',
    state: 'PA',
    latitude: '41.468132',
    longitude: '-75.043999',
    timeZoneId: 'America/New_York',
  },
  '18458': {
    zip: '18458',
    city: 'Shohola',
    state: 'PA',
    latitude: '41.417725',
    longitude: '-74.913678',
    timeZoneId: 'America/New_York',
  },
  '18459': {
    zip: '18459',
    city: 'South Canaan',
    state: 'PA',
    latitude: '41.503648',
    longitude: '-75.413679',
    timeZoneId: 'America/New_York',
  },
  '18460': {
    zip: '18460',
    city: 'South Sterling',
    state: 'PA',
    latitude: '41.274485',
    longitude: '-75.396065',
    timeZoneId: 'America/New_York',
  },
  '18461': {
    zip: '18461',
    city: 'Starlight',
    state: 'PA',
    latitude: '41.918969',
    longitude: '-75.320683',
    timeZoneId: 'America/New_York',
  },
  '18462': {
    zip: '18462',
    city: 'Starrucca',
    state: 'PA',
    latitude: '41.904059',
    longitude: '-75.440288',
    timeZoneId: 'America/New_York',
  },
  '18463': {
    zip: '18463',
    city: 'Sterling',
    state: 'PA',
    latitude: '41.35575',
    longitude: '-75.353736',
    timeZoneId: 'America/New_York',
  },
  '18464': {
    zip: '18464',
    city: 'Tafton',
    state: 'PA',
    latitude: '41.412769',
    longitude: '-75.169763',
    timeZoneId: 'America/New_York',
  },
  '18465': {
    zip: '18465',
    city: 'Thompson',
    state: 'PA',
    latitude: '41.8294',
    longitude: '-75.537198',
    timeZoneId: 'America/New_York',
  },
  '18466': {
    zip: '18466',
    city: 'Tobyhanna',
    state: 'PA',
    latitude: '41.183409',
    longitude: '-75.377541',
    timeZoneId: 'America/New_York',
  },
  '18469': {
    zip: '18469',
    city: 'Tyler Hill',
    state: 'PA',
    latitude: '41.667122',
    longitude: '-75.152624',
    timeZoneId: 'America/New_York',
  },
  '18470': {
    zip: '18470',
    city: 'Union Dale',
    state: 'PA',
    latitude: '41.71327',
    longitude: '-75.55048',
    timeZoneId: 'America/New_York',
  },
  '18471': {
    zip: '18471',
    city: 'Waverly',
    state: 'PA',
    latitude: '41.524177',
    longitude: '-75.702613',
    timeZoneId: 'America/New_York',
  },
  '18472': {
    zip: '18472',
    city: 'Waymart',
    state: 'PA',
    latitude: '41.583823',
    longitude: '-75.409378',
    timeZoneId: 'America/New_York',
  },
  '18473': {
    zip: '18473',
    city: 'White Mills',
    state: 'PA',
    latitude: '41.528258',
    longitude: '-75.202276',
    timeZoneId: 'America/New_York',
  },
  '18501': {
    zip: '18501',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.373105',
    longitude: '-75.683695',
    timeZoneId: 'America/New_York',
  },
  '18502': {
    zip: '18502',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.410462',
    longitude: '-75.669455',
    timeZoneId: 'America/New_York',
  },
  '18503': {
    zip: '18503',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.411047',
    longitude: '-75.665541',
    timeZoneId: 'America/New_York',
  },
  '18504': {
    zip: '18504',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.413309',
    longitude: '-75.690906',
    timeZoneId: 'America/New_York',
  },
  '18505': {
    zip: '18505',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.391055',
    longitude: '-75.66555',
    timeZoneId: 'America/New_York',
  },
  '18507': {
    zip: '18507',
    city: 'Moosic',
    state: 'PA',
    latitude: '41.37093',
    longitude: '-75.703801',
    timeZoneId: 'America/New_York',
  },
  '18508': {
    zip: '18508',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.437061',
    longitude: '-75.663443',
    timeZoneId: 'America/New_York',
  },
  '18509': {
    zip: '18509',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.423216',
    longitude: '-75.640635',
    timeZoneId: 'America/New_York',
  },
  '18510': {
    zip: '18510',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.413287',
    longitude: '-75.639206',
    timeZoneId: 'America/New_York',
  },
  '18512': {
    zip: '18512',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.420505',
    longitude: '-75.625001',
    timeZoneId: 'America/New_York',
  },
  '18515': {
    zip: '18515',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.449536',
    longitude: '-75.66412',
    timeZoneId: 'America/New_York',
  },
  '18517': {
    zip: '18517',
    city: 'Taylor',
    state: 'PA',
    latitude: '41.350889',
    longitude: '-75.727898',
    timeZoneId: 'America/New_York',
  },
  '18518': {
    zip: '18518',
    city: 'Old Forge',
    state: 'PA',
    latitude: '41.375757',
    longitude: '-75.741012',
    timeZoneId: 'America/New_York',
  },
  '18519': {
    zip: '18519',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.462308',
    longitude: '-75.625366',
    timeZoneId: 'America/New_York',
  },
  '18540': {
    zip: '18540',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.372284',
    longitude: '-75.6578',
    timeZoneId: 'America/New_York',
  },
  '18577': {
    zip: '18577',
    city: 'Scranton',
    state: 'PA',
    latitude: '41.42109',
    longitude: '-75.60282',
    timeZoneId: 'America/New_York',
  },
  '18601': {
    zip: '18601',
    city: 'Beach Haven',
    state: 'PA',
    latitude: '41.06766',
    longitude: '-76.176436',
    timeZoneId: 'America/New_York',
  },
  '18602': {
    zip: '18602',
    city: 'Bear Creek',
    state: 'PA',
    latitude: '41.2179',
    longitude: '-75.81144',
    timeZoneId: 'America/New_York',
  },
  '18603': {
    zip: '18603',
    city: 'Berwick',
    state: 'PA',
    latitude: '41.056782',
    longitude: '-76.24526',
    timeZoneId: 'America/New_York',
  },
  '18610': {
    zip: '18610',
    city: 'Blakeslee',
    state: 'PA',
    latitude: '41.100466',
    longitude: '-75.568798',
    timeZoneId: 'America/New_York',
  },
  '18611': {
    zip: '18611',
    city: 'Cambra',
    state: 'PA',
    latitude: '41.196751',
    longitude: '-76.304629',
    timeZoneId: 'America/New_York',
  },
  '18612': {
    zip: '18612',
    city: 'Dallas',
    state: 'PA',
    latitude: '41.332973',
    longitude: '-75.961823',
    timeZoneId: 'America/New_York',
  },
  '18614': {
    zip: '18614',
    city: 'Dushore',
    state: 'PA',
    latitude: '41.522494',
    longitude: '-76.401012',
    timeZoneId: 'America/New_York',
  },
  '18615': {
    zip: '18615',
    city: 'Falls',
    state: 'PA',
    latitude: '41.447237',
    longitude: '-75.85285',
    timeZoneId: 'America/New_York',
  },
  '18616': {
    zip: '18616',
    city: 'Forksville',
    state: 'PA',
    latitude: '41.515707',
    longitude: '-76.596254',
    timeZoneId: 'America/New_York',
  },
  '18617': {
    zip: '18617',
    city: 'Glen Lyon',
    state: 'PA',
    latitude: '41.171949',
    longitude: '-76.075487',
    timeZoneId: 'America/New_York',
  },
  '18618': {
    zip: '18618',
    city: 'Harveys Lake',
    state: 'PA',
    latitude: '41.34114',
    longitude: '-76.017214',
    timeZoneId: 'America/New_York',
  },
  '18619': {
    zip: '18619',
    city: 'Hillsgrove',
    state: 'PA',
    latitude: '41.433378',
    longitude: '-76.709144',
    timeZoneId: 'America/New_York',
  },
  '18621': {
    zip: '18621',
    city: 'Hunlock Creek',
    state: 'PA',
    latitude: '41.226559',
    longitude: '-76.014039',
    timeZoneId: 'America/New_York',
  },
  '18622': {
    zip: '18622',
    city: 'Huntington Mills',
    state: 'PA',
    latitude: '41.198934',
    longitude: '-76.198272',
    timeZoneId: 'America/New_York',
  },
  '18623': {
    zip: '18623',
    city: 'Laceyville',
    state: 'PA',
    latitude: '41.672186',
    longitude: '-76.135681',
    timeZoneId: 'America/New_York',
  },
  '18624': {
    zip: '18624',
    city: 'Lake Harmony',
    state: 'PA',
    latitude: '41.060501',
    longitude: '-75.605658',
    timeZoneId: 'America/New_York',
  },
  '18625': {
    zip: '18625',
    city: 'Lake Winola',
    state: 'PA',
    latitude: '41.514755',
    longitude: '-75.842759',
    timeZoneId: 'America/New_York',
  },
  '18626': {
    zip: '18626',
    city: 'Laporte',
    state: 'PA',
    latitude: '41.426484',
    longitude: '-76.495974',
    timeZoneId: 'America/New_York',
  },
  '18627': {
    zip: '18627',
    city: 'Lehman',
    state: 'PA',
    latitude: '41.316513',
    longitude: '-76.023413',
    timeZoneId: 'America/New_York',
  },
  '18628': {
    zip: '18628',
    city: 'Lopez',
    state: 'PA',
    latitude: '41.456957',
    longitude: '-76.295701',
    timeZoneId: 'America/New_York',
  },
  '18629': {
    zip: '18629',
    city: 'Mehoopany',
    state: 'PA',
    latitude: '41.490456',
    longitude: '-76.155214',
    timeZoneId: 'America/New_York',
  },
  '18630': {
    zip: '18630',
    city: 'Meshoppen',
    state: 'PA',
    latitude: '41.651464',
    longitude: '-76.023654',
    timeZoneId: 'America/New_York',
  },
  '18631': {
    zip: '18631',
    city: 'Mifflinville',
    state: 'PA',
    latitude: '41.029799',
    longitude: '-76.308789',
    timeZoneId: 'America/New_York',
  },
  '18632': {
    zip: '18632',
    city: 'Mildred',
    state: 'PA',
    latitude: '41.469607',
    longitude: '-76.379987',
    timeZoneId: 'America/New_York',
  },
  '18634': {
    zip: '18634',
    city: 'Nanticoke',
    state: 'PA',
    latitude: '41.196217',
    longitude: '-76.003548',
    timeZoneId: 'America/New_York',
  },
  '18635': {
    zip: '18635',
    city: 'Nescopeck',
    state: 'PA',
    latitude: '41.053252',
    longitude: '-76.21848',
    timeZoneId: 'America/New_York',
  },
  '18636': {
    zip: '18636',
    city: 'Noxen',
    state: 'PA',
    latitude: '41.418188',
    longitude: '-76.075214',
    timeZoneId: 'America/New_York',
  },
  '18640': {
    zip: '18640',
    city: 'Pittston',
    state: 'PA',
    latitude: '41.321046',
    longitude: '-75.792617',
    timeZoneId: 'America/New_York',
  },
  '18641': {
    zip: '18641',
    city: 'Pittston',
    state: 'PA',
    latitude: '41.329128',
    longitude: '-75.746729',
    timeZoneId: 'America/New_York',
  },
  '18642': {
    zip: '18642',
    city: 'Duryea',
    state: 'PA',
    latitude: '41.325833',
    longitude: '-75.789722',
    timeZoneId: 'America/New_York',
  },
  '18643': {
    zip: '18643',
    city: 'Pittston',
    state: 'PA',
    latitude: '41.328885',
    longitude: '-75.815364',
    timeZoneId: 'America/New_York',
  },
  '18644': {
    zip: '18644',
    city: 'Wyoming',
    state: 'PA',
    latitude: '41.3301',
    longitude: '-75.85368',
    timeZoneId: 'America/New_York',
  },
  '18651': {
    zip: '18651',
    city: 'Plymouth',
    state: 'PA',
    latitude: '41.24562',
    longitude: '-75.96174',
    timeZoneId: 'America/New_York',
  },
  '18653': {
    zip: '18653',
    city: 'Ransom',
    state: 'PA',
    latitude: '41.393995',
    longitude: '-75.824614',
    timeZoneId: 'America/New_York',
  },
  '18654': {
    zip: '18654',
    city: 'Shawanese',
    state: 'PA',
    latitude: '41.353444',
    longitude: '-76.051413',
    timeZoneId: 'America/New_York',
  },
  '18655': {
    zip: '18655',
    city: 'Shickshinny',
    state: 'PA',
    latitude: '41.17128',
    longitude: '-76.14',
    timeZoneId: 'America/New_York',
  },
  '18656': {
    zip: '18656',
    city: 'Sweet Valley',
    state: 'PA',
    latitude: '41.329275',
    longitude: '-76.161463',
    timeZoneId: 'America/New_York',
  },
  '18657': {
    zip: '18657',
    city: 'Tunkhannock',
    state: 'PA',
    latitude: '41.5374',
    longitude: '-75.97452',
    timeZoneId: 'America/New_York',
  },
  '18660': {
    zip: '18660',
    city: 'Wapwallopen',
    state: 'PA',
    latitude: '41.160721',
    longitude: '-76.010549',
    timeZoneId: 'America/New_York',
  },
  '18661': {
    zip: '18661',
    city: 'White Haven',
    state: 'PA',
    latitude: '41.062005',
    longitude: '-75.78408',
    timeZoneId: 'America/New_York',
  },
  '18690': {
    zip: '18690',
    city: 'Dallas',
    state: 'PA',
    latitude: '41.335765',
    longitude: '-75.970464',
    timeZoneId: 'America/New_York',
  },
  '18701': {
    zip: '18701',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.24247',
    longitude: '-75.884842',
    timeZoneId: 'America/New_York',
  },
  '18702': {
    zip: '18702',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.234843',
    longitude: '-75.877313',
    timeZoneId: 'America/New_York',
  },
  '18703': {
    zip: '18703',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.243136',
    longitude: '-75.885306',
    timeZoneId: 'America/New_York',
  },
  '18704': {
    zip: '18704',
    city: 'Kingston',
    state: 'PA',
    latitude: '41.26458',
    longitude: '-75.88818',
    timeZoneId: 'America/New_York',
  },
  '18705': {
    zip: '18705',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.272156',
    longitude: '-75.844934',
    timeZoneId: 'America/New_York',
  },
  '18706': {
    zip: '18706',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.207017',
    longitude: '-75.923776',
    timeZoneId: 'America/New_York',
  },
  '18707': {
    zip: '18707',
    city: 'Mountain Top',
    state: 'PA',
    latitude: '41.144606',
    longitude: '-75.925343',
    timeZoneId: 'America/New_York',
  },
  '18708': {
    zip: '18708',
    city: 'Shavertown',
    state: 'PA',
    latitude: '41.30028',
    longitude: '-75.96588',
    timeZoneId: 'America/New_York',
  },
  '18709': {
    zip: '18709',
    city: 'Luzerne',
    state: 'PA',
    latitude: '41.281505',
    longitude: '-75.894483',
    timeZoneId: 'America/New_York',
  },
  '18710': {
    zip: '18710',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.249324',
    longitude: '-75.879888',
    timeZoneId: 'America/New_York',
  },
  '18711': {
    zip: '18711',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.249408',
    longitude: '-75.884003',
    timeZoneId: 'America/New_York',
  },
  '18762': {
    zip: '18762',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.237393',
    longitude: '-75.897191',
    timeZoneId: 'America/New_York',
  },
  '18764': {
    zip: '18764',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.218277',
    longitude: '-75.794595',
    timeZoneId: 'America/New_York',
  },
  '18765': {
    zip: '18765',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.219464',
    longitude: '-75.79708',
    timeZoneId: 'America/New_York',
  },
  '18766': {
    zip: '18766',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.244606',
    longitude: '-75.883149',
    timeZoneId: 'America/New_York',
  },
  '18767': {
    zip: '18767',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.24995',
    longitude: '-75.875977',
    timeZoneId: 'America/New_York',
  },
  '18769': {
    zip: '18769',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.253872',
    longitude: '-75.871166',
    timeZoneId: 'America/New_York',
  },
  '18773': {
    zip: '18773',
    city: 'Wilkes Barre',
    state: 'PA',
    latitude: '41.245679',
    longitude: '-75.883795',
    timeZoneId: 'America/New_York',
  },
  '18801': {
    zip: '18801',
    city: 'Montrose',
    state: 'PA',
    latitude: '41.835677',
    longitude: '-75.875138',
    timeZoneId: 'America/New_York',
  },
  '18810': {
    zip: '18810',
    city: 'Athens',
    state: 'PA',
    latitude: '41.961913',
    longitude: '-76.521874',
    timeZoneId: 'America/New_York',
  },
  '18812': {
    zip: '18812',
    city: 'Brackney',
    state: 'PA',
    latitude: '41.955028',
    longitude: '-75.927922',
    timeZoneId: 'America/New_York',
  },
  '18813': {
    zip: '18813',
    city: 'Brooklyn',
    state: 'PA',
    latitude: '41.751582',
    longitude: '-75.808429',
    timeZoneId: 'America/New_York',
  },
  '18814': {
    zip: '18814',
    city: 'Burlington',
    state: 'PA',
    latitude: '41.776235',
    longitude: '-76.608405',
    timeZoneId: 'America/New_York',
  },
  '18815': {
    zip: '18815',
    city: 'Camptown',
    state: 'PA',
    latitude: '41.731124',
    longitude: '-76.232784',
    timeZoneId: 'America/New_York',
  },
  '18816': {
    zip: '18816',
    city: 'Dimock',
    state: 'PA',
    latitude: '41.744822',
    longitude: '-75.896269',
    timeZoneId: 'America/New_York',
  },
  '18817': {
    zip: '18817',
    city: 'East Smithfield',
    state: 'PA',
    latitude: '41.861211',
    longitude: '-76.614065',
    timeZoneId: 'America/New_York',
  },
  '18818': {
    zip: '18818',
    city: 'Friendsville',
    state: 'PA',
    latitude: '41.906615',
    longitude: '-76.046272',
    timeZoneId: 'America/New_York',
  },
  '18820': {
    zip: '18820',
    city: 'Gibson',
    state: 'PA',
    latitude: '41.802322',
    longitude: '-75.644157',
    timeZoneId: 'America/New_York',
  },
  '18821': {
    zip: '18821',
    city: 'Great Bend',
    state: 'PA',
    latitude: '41.973211',
    longitude: '-75.746207',
    timeZoneId: 'America/New_York',
  },
  '18822': {
    zip: '18822',
    city: 'Hallstead',
    state: 'PA',
    latitude: '41.961084',
    longitude: '-75.748092',
    timeZoneId: 'America/New_York',
  },
  '18823': {
    zip: '18823',
    city: 'Harford',
    state: 'PA',
    latitude: '41.778578',
    longitude: '-75.678338',
    timeZoneId: 'America/New_York',
  },
  '18824': {
    zip: '18824',
    city: 'Hop Bottom',
    state: 'PA',
    latitude: '41.692682',
    longitude: '-75.807152',
    timeZoneId: 'America/New_York',
  },
  '18825': {
    zip: '18825',
    city: 'Jackson',
    state: 'PA',
    latitude: '41.867048',
    longitude: '-75.609174',
    timeZoneId: 'America/New_York',
  },
  '18826': {
    zip: '18826',
    city: 'Kingsley',
    state: 'PA',
    latitude: '41.76986',
    longitude: '-75.783059',
    timeZoneId: 'America/New_York',
  },
  '18827': {
    zip: '18827',
    city: 'Lanesboro',
    state: 'PA',
    latitude: '41.956416',
    longitude: '-75.58703',
    timeZoneId: 'America/New_York',
  },
  '18828': {
    zip: '18828',
    city: 'Lawton',
    state: 'PA',
    latitude: '41.764619',
    longitude: '-76.094363',
    timeZoneId: 'America/New_York',
  },
  '18829': {
    zip: '18829',
    city: 'Le Raysville',
    state: 'PA',
    latitude: '41.845364',
    longitude: '-76.17908',
    timeZoneId: 'America/New_York',
  },
  '18830': {
    zip: '18830',
    city: 'Little Meadows',
    state: 'PA',
    latitude: '41.959552',
    longitude: '-76.107242',
    timeZoneId: 'America/New_York',
  },
  '18831': {
    zip: '18831',
    city: 'Milan',
    state: 'PA',
    latitude: '41.899052',
    longitude: '-76.53577',
    timeZoneId: 'America/New_York',
  },
  '18832': {
    zip: '18832',
    city: 'Monroeton',
    state: 'PA',
    latitude: '41.713247',
    longitude: '-76.471178',
    timeZoneId: 'America/New_York',
  },
  '18833': {
    zip: '18833',
    city: 'New Albany',
    state: 'PA',
    latitude: '41.601394',
    longitude: '-76.439697',
    timeZoneId: 'America/New_York',
  },
  '18834': {
    zip: '18834',
    city: 'New Milford',
    state: 'PA',
    latitude: '41.873819',
    longitude: '-75.726137',
    timeZoneId: 'America/New_York',
  },
  '18837': {
    zip: '18837',
    city: 'Rome',
    state: 'PA',
    latitude: '41.870052',
    longitude: '-76.297717',
    timeZoneId: 'America/New_York',
  },
  '18840': {
    zip: '18840',
    city: 'Sayre',
    state: 'PA',
    latitude: '41.983124',
    longitude: '-76.530623',
    timeZoneId: 'America/New_York',
  },
  '18842': {
    zip: '18842',
    city: 'South Gibson',
    state: 'PA',
    latitude: '41.753016',
    longitude: '-75.603639',
    timeZoneId: 'America/New_York',
  },
  '18843': {
    zip: '18843',
    city: 'South Montrose',
    state: 'PA',
    latitude: '41.79664',
    longitude: '-75.891646',
    timeZoneId: 'America/New_York',
  },
  '18844': {
    zip: '18844',
    city: 'Springville',
    state: 'PA',
    latitude: '41.710753',
    longitude: '-75.894888',
    timeZoneId: 'America/New_York',
  },
  '18845': {
    zip: '18845',
    city: 'Stevensville',
    state: 'PA',
    latitude: '41.770795',
    longitude: '-76.170281',
    timeZoneId: 'America/New_York',
  },
  '18846': {
    zip: '18846',
    city: 'Sugar Run',
    state: 'PA',
    latitude: '41.593223',
    longitude: '-76.268573',
    timeZoneId: 'America/New_York',
  },
  '18847': {
    zip: '18847',
    city: 'Susquehanna',
    state: 'PA',
    latitude: '41.944417',
    longitude: '-75.603491',
    timeZoneId: 'America/New_York',
  },
  '18848': {
    zip: '18848',
    city: 'Towanda',
    state: 'PA',
    latitude: '41.770672',
    longitude: '-76.44749',
    timeZoneId: 'America/New_York',
  },
  '18850': {
    zip: '18850',
    city: 'Ulster',
    state: 'PA',
    latitude: '41.842993',
    longitude: '-76.474076',
    timeZoneId: 'America/New_York',
  },
  '18851': {
    zip: '18851',
    city: 'Warren Center',
    state: 'PA',
    latitude: '41.946984',
    longitude: '-76.198497',
    timeZoneId: 'America/New_York',
  },
  '18853': {
    zip: '18853',
    city: 'Wyalusing',
    state: 'PA',
    latitude: '41.669695',
    longitude: '-76.262492',
    timeZoneId: 'America/New_York',
  },
  '18854': {
    zip: '18854',
    city: 'Wysox',
    state: 'PA',
    latitude: '41.781631',
    longitude: '-76.36005',
    timeZoneId: 'America/New_York',
  },
  '18901': {
    zip: '18901',
    city: 'Doylestown',
    state: 'PA',
    latitude: '40.332324',
    longitude: '-75.116759',
    timeZoneId: 'America/New_York',
  },
  '18902': {
    zip: '18902',
    city: 'Doylestown',
    state: 'PA',
    latitude: '40.332651',
    longitude: '-75.11654',
    timeZoneId: 'America/New_York',
  },
  '18910': {
    zip: '18910',
    city: 'Bedminster',
    state: 'PA',
    latitude: '40.4222',
    longitude: '-75.17982',
    timeZoneId: 'America/New_York',
  },
  '18911': {
    zip: '18911',
    city: 'Blooming Glen',
    state: 'PA',
    latitude: '40.37461',
    longitude: '-75.254906',
    timeZoneId: 'America/New_York',
  },
  '18912': {
    zip: '18912',
    city: 'Buckingham',
    state: 'PA',
    latitude: '40.293915',
    longitude: '-75.059598',
    timeZoneId: 'America/New_York',
  },
  '18913': {
    zip: '18913',
    city: 'Carversville',
    state: 'PA',
    latitude: '40.382046',
    longitude: '-75.045566',
    timeZoneId: 'America/New_York',
  },
  '18914': {
    zip: '18914',
    city: 'Chalfont',
    state: 'PA',
    latitude: '40.283914',
    longitude: '-75.196735',
    timeZoneId: 'America/New_York',
  },
  '18915': {
    zip: '18915',
    city: 'Colmar',
    state: 'PA',
    latitude: '40.26149',
    longitude: '-75.248416',
    timeZoneId: 'America/New_York',
  },
  '18916': {
    zip: '18916',
    city: 'Danboro',
    state: 'PA',
    latitude: '40.359991',
    longitude: '-75.139095',
    timeZoneId: 'America/New_York',
  },
  '18917': {
    zip: '18917',
    city: 'Dublin',
    state: 'PA',
    latitude: '40.373703',
    longitude: '-75.200962',
    timeZoneId: 'America/New_York',
  },
  '18918': {
    zip: '18918',
    city: 'Earlington',
    state: 'PA',
    latitude: '40.323908',
    longitude: '-75.377108',
    timeZoneId: 'America/New_York',
  },
  '18920': {
    zip: '18920',
    city: 'Erwinna',
    state: 'PA',
    latitude: '40.491353',
    longitude: '-75.079035',
    timeZoneId: 'America/New_York',
  },
  '18921': {
    zip: '18921',
    city: 'Ferndale',
    state: 'PA',
    latitude: '40.533461',
    longitude: '-75.180481',
    timeZoneId: 'America/New_York',
  },
  '18922': {
    zip: '18922',
    city: 'Forest Grove',
    state: 'PA',
    latitude: '40.298834',
    longitude: '-75.057172',
    timeZoneId: 'America/New_York',
  },
  '18923': {
    zip: '18923',
    city: 'Fountainville',
    state: 'PA',
    latitude: '40.350605',
    longitude: '-75.163282',
    timeZoneId: 'America/New_York',
  },
  '18925': {
    zip: '18925',
    city: 'Furlong',
    state: 'PA',
    latitude: '40.280453',
    longitude: '-75.066114',
    timeZoneId: 'America/New_York',
  },
  '18927': {
    zip: '18927',
    city: 'Hilltown',
    state: 'PA',
    latitude: '40.322584',
    longitude: '-75.245962',
    timeZoneId: 'America/New_York',
  },
  '18928': {
    zip: '18928',
    city: 'Holicong',
    state: 'PA',
    latitude: '40.337249',
    longitude: '-75.047898',
    timeZoneId: 'America/New_York',
  },
  '18929': {
    zip: '18929',
    city: 'Jamison',
    state: 'PA',
    latitude: '40.252079',
    longitude: '-75.085955',
    timeZoneId: 'America/New_York',
  },
  '18930': {
    zip: '18930',
    city: 'Kintnersville',
    state: 'PA',
    latitude: '40.508214',
    longitude: '-75.235473',
    timeZoneId: 'America/New_York',
  },
  '18931': {
    zip: '18931',
    city: 'Lahaska',
    state: 'PA',
    latitude: '40.357187',
    longitude: '-75.041298',
    timeZoneId: 'America/New_York',
  },
  '18932': {
    zip: '18932',
    city: 'Line Lexington',
    state: 'PA',
    latitude: '40.292841',
    longitude: '-75.259314',
    timeZoneId: 'America/New_York',
  },
  '18933': {
    zip: '18933',
    city: 'Lumberville',
    state: 'PA',
    latitude: '40.399813',
    longitude: '-75.0428',
    timeZoneId: 'America/New_York',
  },
  '18934': {
    zip: '18934',
    city: 'Mechanicsville',
    state: 'PA',
    latitude: '40.34663',
    longitude: '-75.072109',
    timeZoneId: 'America/New_York',
  },
  '18935': {
    zip: '18935',
    city: 'Milford Square',
    state: 'PA',
    latitude: '40.432188',
    longitude: '-75.396928',
    timeZoneId: 'America/New_York',
  },
  '18936': {
    zip: '18936',
    city: 'Montgomeryville',
    state: 'PA',
    latitude: '40.244471',
    longitude: '-75.243572',
    timeZoneId: 'America/New_York',
  },
  '18938': {
    zip: '18938',
    city: 'New Hope',
    state: 'PA',
    latitude: '40.357334',
    longitude: '-74.995503',
    timeZoneId: 'America/New_York',
  },
  '18940': {
    zip: '18940',
    city: 'Newtown',
    state: 'PA',
    latitude: '40.245694',
    longitude: '-74.942426',
    timeZoneId: 'America/New_York',
  },
  '18942': {
    zip: '18942',
    city: 'Ottsville',
    state: 'PA',
    latitude: '40.462392',
    longitude: '-75.160404',
    timeZoneId: 'America/New_York',
  },
  '18943': {
    zip: '18943',
    city: 'Penns Park',
    state: 'PA',
    latitude: '40.265356',
    longitude: '-74.997695',
    timeZoneId: 'America/New_York',
  },
  '18944': {
    zip: '18944',
    city: 'Perkasie',
    state: 'PA',
    latitude: '40.369436',
    longitude: '-75.254135',
    timeZoneId: 'America/New_York',
  },
  '18946': {
    zip: '18946',
    city: 'Pineville',
    state: 'PA',
    latitude: '40.29239',
    longitude: '-75.01391',
    timeZoneId: 'America/New_York',
  },
  '18947': {
    zip: '18947',
    city: 'Pipersville',
    state: 'PA',
    latitude: '40.416807',
    longitude: '-75.118504',
    timeZoneId: 'America/New_York',
  },
  '18949': {
    zip: '18949',
    city: 'Plumsteadville',
    state: 'PA',
    latitude: '40.378478',
    longitude: '-75.145192',
    timeZoneId: 'America/New_York',
  },
  '18950': {
    zip: '18950',
    city: 'Point Pleasant',
    state: 'PA',
    latitude: '40.41493',
    longitude: '-75.067576',
    timeZoneId: 'America/New_York',
  },
  '18951': {
    zip: '18951',
    city: 'Quakertown',
    state: 'PA',
    latitude: '40.442831',
    longitude: '-75.354501',
    timeZoneId: 'America/New_York',
  },
  '18953': {
    zip: '18953',
    city: 'Revere',
    state: 'PA',
    latitude: '40.514298',
    longitude: '-75.160151',
    timeZoneId: 'America/New_York',
  },
  '18954': {
    zip: '18954',
    city: 'Richboro',
    state: 'PA',
    latitude: '40.216524',
    longitude: '-75.002295',
    timeZoneId: 'America/New_York',
  },
  '18955': {
    zip: '18955',
    city: 'Richlandtown',
    state: 'PA',
    latitude: '40.47444',
    longitude: '-75.321164',
    timeZoneId: 'America/New_York',
  },
  '18956': {
    zip: '18956',
    city: 'Rushland',
    state: 'PA',
    latitude: '40.261618',
    longitude: '-75.027246',
    timeZoneId: 'America/New_York',
  },
  '18957': {
    zip: '18957',
    city: 'Salford',
    state: 'PA',
    latitude: '40.29607',
    longitude: '-75.453386',
    timeZoneId: 'America/New_York',
  },
  '18958': {
    zip: '18958',
    city: 'Salfordville',
    state: 'PA',
    latitude: '40.297622',
    longitude: '-75.441288',
    timeZoneId: 'America/New_York',
  },
  '18960': {
    zip: '18960',
    city: 'Sellersville',
    state: 'PA',
    latitude: '40.351221',
    longitude: '-75.297364',
    timeZoneId: 'America/New_York',
  },
  '18962': {
    zip: '18962',
    city: 'Silverdale',
    state: 'PA',
    latitude: '40.346383',
    longitude: '-75.268821',
    timeZoneId: 'America/New_York',
  },
  '18963': {
    zip: '18963',
    city: 'Solebury',
    state: 'PA',
    latitude: '40.379569',
    longitude: '-75.014126',
    timeZoneId: 'America/New_York',
  },
  '18964': {
    zip: '18964',
    city: 'Souderton',
    state: 'PA',
    latitude: '40.300323',
    longitude: '-75.32455',
    timeZoneId: 'America/New_York',
  },
  '18966': {
    zip: '18966',
    city: 'Southampton',
    state: 'PA',
    latitude: '40.1868',
    longitude: '-75.00966',
    timeZoneId: 'America/New_York',
  },
  '18968': {
    zip: '18968',
    city: 'Spinnerstown',
    state: 'PA',
    latitude: '40.443981',
    longitude: '-75.438278',
    timeZoneId: 'America/New_York',
  },
  '18969': {
    zip: '18969',
    city: 'Telford',
    state: 'PA',
    latitude: '40.322048',
    longitude: '-75.363209',
    timeZoneId: 'America/New_York',
  },
  '18970': {
    zip: '18970',
    city: 'Trumbauersville',
    state: 'PA',
    latitude: '40.410255',
    longitude: '-75.378287',
    timeZoneId: 'America/New_York',
  },
  '18971': {
    zip: '18971',
    city: 'Tylersport',
    state: 'PA',
    latitude: '40.403022',
    longitude: '-75.394221',
    timeZoneId: 'America/New_York',
  },
  '18972': {
    zip: '18972',
    city: 'Upper Black Eddy',
    state: 'PA',
    latitude: '40.538872',
    longitude: '-75.126125',
    timeZoneId: 'America/New_York',
  },
  '18974': {
    zip: '18974',
    city: 'Warminster',
    state: 'PA',
    latitude: '40.208672',
    longitude: '-75.079555',
    timeZoneId: 'America/New_York',
  },
  '18976': {
    zip: '18976',
    city: 'Warrington',
    state: 'PA',
    latitude: '40.241554',
    longitude: '-75.129493',
    timeZoneId: 'America/New_York',
  },
  '18977': {
    zip: '18977',
    city: 'Washington Crossing',
    state: 'PA',
    latitude: '40.2919',
    longitude: '-74.884277',
    timeZoneId: 'America/New_York',
  },
  '18979': {
    zip: '18979',
    city: 'Woxall',
    state: 'PA',
    latitude: '40.315318',
    longitude: '-75.458168',
    timeZoneId: 'America/New_York',
  },
  '18980': {
    zip: '18980',
    city: 'Wycombe',
    state: 'PA',
    latitude: '40.274',
    longitude: '-75.020597',
    timeZoneId: 'America/New_York',
  },
  '18981': {
    zip: '18981',
    city: 'Zionhill',
    state: 'PA',
    latitude: '40.486011',
    longitude: '-75.394037',
    timeZoneId: 'America/New_York',
  },
  '18991': {
    zip: '18991',
    city: 'Warminster',
    state: 'PA',
    latitude: '40.218695',
    longitude: '-75.07568',
    timeZoneId: 'America/New_York',
  },
  '19001': {
    zip: '19001',
    city: 'Abington',
    state: 'PA',
    latitude: '40.123936',
    longitude: '-75.131093',
    timeZoneId: 'America/New_York',
  },
  '19002': {
    zip: '19002',
    city: 'Ambler',
    state: 'PA',
    latitude: '40.19076',
    longitude: '-75.20658',
    timeZoneId: 'America/New_York',
  },
  '19003': {
    zip: '19003',
    city: 'Ardmore',
    state: 'PA',
    latitude: '39.993332',
    longitude: '-75.30525',
    timeZoneId: 'America/New_York',
  },
  '19004': {
    zip: '19004',
    city: 'Bala Cynwyd',
    state: 'PA',
    latitude: '40.013801',
    longitude: '-75.231706',
    timeZoneId: 'America/New_York',
  },
  '19006': {
    zip: '19006',
    city: 'Huntingdon Valley',
    state: 'PA',
    latitude: '40.129527',
    longitude: '-75.060892',
    timeZoneId: 'America/New_York',
  },
  '19007': {
    zip: '19007',
    city: 'Bristol',
    state: 'PA',
    latitude: '40.111586',
    longitude: '-74.861944',
    timeZoneId: 'America/New_York',
  },
  '19008': {
    zip: '19008',
    city: 'Broomall',
    state: 'PA',
    latitude: '39.975572',
    longitude: '-75.360537',
    timeZoneId: 'America/New_York',
  },
  '19009': {
    zip: '19009',
    city: 'Bryn Athyn',
    state: 'PA',
    latitude: '40.135969',
    longitude: '-75.061051',
    timeZoneId: 'America/New_York',
  },
  '19010': {
    zip: '19010',
    city: 'Bryn Mawr',
    state: 'PA',
    latitude: '40.02636',
    longitude: '-75.33438',
    timeZoneId: 'America/New_York',
  },
  '19012': {
    zip: '19012',
    city: 'Cheltenham',
    state: 'PA',
    latitude: '40.058426',
    longitude: '-75.105976',
    timeZoneId: 'America/New_York',
  },
  '19013': {
    zip: '19013',
    city: 'Chester',
    state: 'PA',
    latitude: '39.847709',
    longitude: '-75.376375',
    timeZoneId: 'America/New_York',
  },
  '19014': {
    zip: '19014',
    city: 'Aston',
    state: 'PA',
    latitude: '39.86237',
    longitude: '-75.434048',
    timeZoneId: 'America/New_York',
  },
  '19015': {
    zip: '19015',
    city: 'Brookhaven',
    state: 'PA',
    latitude: '39.868688',
    longitude: '-75.385721',
    timeZoneId: 'America/New_York',
  },
  '19016': {
    zip: '19016',
    city: 'Chester',
    state: 'PA',
    latitude: '39.84563',
    longitude: '-75.370818',
    timeZoneId: 'America/New_York',
  },
  '19017': {
    zip: '19017',
    city: 'Chester Heights',
    state: 'PA',
    latitude: '39.888886',
    longitude: '-75.471423',
    timeZoneId: 'America/New_York',
  },
  '19018': {
    zip: '19018',
    city: 'Clifton Heights',
    state: 'PA',
    latitude: '39.922624',
    longitude: '-75.302964',
    timeZoneId: 'America/New_York',
  },
  '19019': {
    zip: '19019',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.1176',
    longitude: '-75.01451',
    timeZoneId: 'America/New_York',
  },
  '19020': {
    zip: '19020',
    city: 'Bensalem',
    state: 'PA',
    latitude: '40.102797',
    longitude: '-74.929344',
    timeZoneId: 'America/New_York',
  },
  '19021': {
    zip: '19021',
    city: 'Croydon',
    state: 'PA',
    latitude: '40.094171',
    longitude: '-74.909281',
    timeZoneId: 'America/New_York',
  },
  '19022': {
    zip: '19022',
    city: 'Crum Lynne',
    state: 'PA',
    latitude: '39.862914',
    longitude: '-75.343388',
    timeZoneId: 'America/New_York',
  },
  '19023': {
    zip: '19023',
    city: 'Darby',
    state: 'PA',
    latitude: '39.917217',
    longitude: '-75.266969',
    timeZoneId: 'America/New_York',
  },
  '19025': {
    zip: '19025',
    city: 'Dresher',
    state: 'PA',
    latitude: '40.139401',
    longitude: '-75.223398',
    timeZoneId: 'America/New_York',
  },
  '19026': {
    zip: '19026',
    city: 'Drexel Hill',
    state: 'PA',
    latitude: '39.95502',
    longitude: '-75.32742',
    timeZoneId: 'America/New_York',
  },
  '19027': {
    zip: '19027',
    city: 'Elkins Park',
    state: 'PA',
    latitude: '40.017822',
    longitude: '-75.135978',
    timeZoneId: 'America/New_York',
  },
  '19028': {
    zip: '19028',
    city: 'Edgemont',
    state: 'PA',
    latitude: '39.980475',
    longitude: '-75.454566',
    timeZoneId: 'America/New_York',
  },
  '19029': {
    zip: '19029',
    city: 'Essington',
    state: 'PA',
    latitude: '39.86824',
    longitude: '-75.294939',
    timeZoneId: 'America/New_York',
  },
  '19030': {
    zip: '19030',
    city: 'Fairless Hills',
    state: 'PA',
    latitude: '40.17304',
    longitude: '-74.848169',
    timeZoneId: 'America/New_York',
  },
  '19031': {
    zip: '19031',
    city: 'Flourtown',
    state: 'PA',
    latitude: '40.105338',
    longitude: '-75.21214',
    timeZoneId: 'America/New_York',
  },
  '19032': {
    zip: '19032',
    city: 'Folcroft',
    state: 'PA',
    latitude: '39.894926',
    longitude: '-75.279274',
    timeZoneId: 'America/New_York',
  },
  '19033': {
    zip: '19033',
    city: 'Folsom',
    state: 'PA',
    latitude: '39.889987',
    longitude: '-75.326801',
    timeZoneId: 'America/New_York',
  },
  '19034': {
    zip: '19034',
    city: 'Fort Washington',
    state: 'PA',
    latitude: '40.135875',
    longitude: '-75.206603',
    timeZoneId: 'America/New_York',
  },
  '19035': {
    zip: '19035',
    city: 'Gladwyne',
    state: 'PA',
    latitude: '40.043915',
    longitude: '-75.283448',
    timeZoneId: 'America/New_York',
  },
  '19036': {
    zip: '19036',
    city: 'Glenolden',
    state: 'PA',
    latitude: '39.906381',
    longitude: '-75.293696',
    timeZoneId: 'America/New_York',
  },
  '19037': {
    zip: '19037',
    city: 'Glen Riddle Lima',
    state: 'PA',
    latitude: '39.901166',
    longitude: '-75.442291',
    timeZoneId: 'America/New_York',
  },
  '19038': {
    zip: '19038',
    city: 'Glenside',
    state: 'PA',
    latitude: '40.1079',
    longitude: '-75.15708',
    timeZoneId: 'America/New_York',
  },
  '19039': {
    zip: '19039',
    city: 'Gradyville',
    state: 'PA',
    latitude: '39.936739',
    longitude: '-75.46363',
    timeZoneId: 'America/New_York',
  },
  '19040': {
    zip: '19040',
    city: 'Hatboro',
    state: 'PA',
    latitude: '40.178137',
    longitude: '-75.108926',
    timeZoneId: 'America/New_York',
  },
  '19041': {
    zip: '19041',
    city: 'Haverford',
    state: 'PA',
    latitude: '40.027962',
    longitude: '-75.298984',
    timeZoneId: 'America/New_York',
  },
  '19043': {
    zip: '19043',
    city: 'Holmes',
    state: 'PA',
    latitude: '39.901622',
    longitude: '-75.311519',
    timeZoneId: 'America/New_York',
  },
  '19044': {
    zip: '19044',
    city: 'Horsham',
    state: 'PA',
    latitude: '40.182463',
    longitude: '-75.150115',
    timeZoneId: 'America/New_York',
  },
  '19046': {
    zip: '19046',
    city: 'Jenkintown',
    state: 'PA',
    latitude: '40.101066',
    longitude: '-75.11103',
    timeZoneId: 'America/New_York',
  },
  '19047': {
    zip: '19047',
    city: 'Langhorne',
    state: 'PA',
    latitude: '40.179301',
    longitude: '-74.905397',
    timeZoneId: 'America/New_York',
  },
  '19048': {
    zip: '19048',
    city: 'Fort Washington',
    state: 'PA',
    latitude: '40.176074',
    longitude: '-74.924818',
    timeZoneId: 'America/New_York',
  },
  '19049': {
    zip: '19049',
    city: 'Fort Washington',
    state: 'PA',
    latitude: '40.175266',
    longitude: '-74.919785',
    timeZoneId: 'America/New_York',
  },
  '19050': {
    zip: '19050',
    city: 'Lansdowne',
    state: 'PA',
    latitude: '39.93654',
    longitude: '-75.26358',
    timeZoneId: 'America/New_York',
  },
  '19052': {
    zip: '19052',
    city: 'Lenni',
    state: 'PA',
    latitude: '39.894353',
    longitude: '-75.444607',
    timeZoneId: 'America/New_York',
  },
  '19053': {
    zip: '19053',
    city: 'Feasterville Trevose',
    state: 'PA',
    latitude: '40.149318',
    longitude: '-74.981646',
    timeZoneId: 'America/New_York',
  },
  '19054': {
    zip: '19054',
    city: 'Levittown',
    state: 'PA',
    latitude: '40.16834',
    longitude: '-74.822181',
    timeZoneId: 'America/New_York',
  },
  '19055': {
    zip: '19055',
    city: 'Levittown',
    state: 'PA',
    latitude: '40.146863',
    longitude: '-74.839128',
    timeZoneId: 'America/New_York',
  },
  '19056': {
    zip: '19056',
    city: 'Levittown',
    state: 'PA',
    latitude: '40.150296',
    longitude: '-74.888025',
    timeZoneId: 'America/New_York',
  },
  '19057': {
    zip: '19057',
    city: 'Levittown',
    state: 'PA',
    latitude: '40.140756',
    longitude: '-74.860963',
    timeZoneId: 'America/New_York',
  },
  '19058': {
    zip: '19058',
    city: 'Levittown',
    state: 'PA',
    latitude: '40.152223',
    longitude: '-74.851228',
    timeZoneId: 'America/New_York',
  },
  '19060': {
    zip: '19060',
    city: 'Garnet Valley',
    state: 'PA',
    latitude: '39.846163',
    longitude: '-75.445489',
    timeZoneId: 'America/New_York',
  },
  '19061': {
    zip: '19061',
    city: 'Marcus Hook',
    state: 'PA',
    latitude: '39.835136',
    longitude: '-75.448632',
    timeZoneId: 'America/New_York',
  },
  '19063': {
    zip: '19063',
    city: 'Media',
    state: 'PA',
    latitude: '39.91944',
    longitude: '-75.42048',
    timeZoneId: 'America/New_York',
  },
  '19064': {
    zip: '19064',
    city: 'Springfield',
    state: 'PA',
    latitude: '39.95454',
    longitude: '-75.29796',
    timeZoneId: 'America/New_York',
  },
  '19065': {
    zip: '19065',
    city: 'Media',
    state: 'PA',
    latitude: '39.918847',
    longitude: '-75.390133',
    timeZoneId: 'America/New_York',
  },
  '19066': {
    zip: '19066',
    city: 'Merion Station',
    state: 'PA',
    latitude: '40.002386',
    longitude: '-75.253465',
    timeZoneId: 'America/New_York',
  },
  '19067': {
    zip: '19067',
    city: 'Morrisville',
    state: 'PA',
    latitude: '40.2024',
    longitude: '-74.83098',
    timeZoneId: 'America/New_York',
  },
  '19070': {
    zip: '19070',
    city: 'Morton',
    state: 'PA',
    latitude: '39.908428',
    longitude: '-75.323818',
    timeZoneId: 'America/New_York',
  },
  '19072': {
    zip: '19072',
    city: 'Narberth',
    state: 'PA',
    latitude: '40.018469',
    longitude: '-75.264095',
    timeZoneId: 'America/New_York',
  },
  '19073': {
    zip: '19073',
    city: 'Newtown Square',
    state: 'PA',
    latitude: '39.983187',
    longitude: '-75.412719',
    timeZoneId: 'America/New_York',
  },
  '19074': {
    zip: '19074',
    city: 'Norwood',
    state: 'PA',
    latitude: '39.888802',
    longitude: '-75.29802',
    timeZoneId: 'America/New_York',
  },
  '19075': {
    zip: '19075',
    city: 'Oreland',
    state: 'PA',
    latitude: '40.112781',
    longitude: '-75.185143',
    timeZoneId: 'America/New_York',
  },
  '19076': {
    zip: '19076',
    city: 'Prospect Park',
    state: 'PA',
    latitude: '39.885317',
    longitude: '-75.306333',
    timeZoneId: 'America/New_York',
  },
  '19078': {
    zip: '19078',
    city: 'Ridley Park',
    state: 'PA',
    latitude: '39.877221',
    longitude: '-75.322215',
    timeZoneId: 'America/New_York',
  },
  '19079': {
    zip: '19079',
    city: 'Sharon Hill',
    state: 'PA',
    latitude: '39.90298',
    longitude: '-75.26958',
    timeZoneId: 'America/New_York',
  },
  '19080': {
    zip: '19080',
    city: 'Wayne',
    state: 'PA',
    latitude: '40.041159',
    longitude: '-75.388173',
    timeZoneId: 'America/New_York',
  },
  '19081': {
    zip: '19081',
    city: 'Swarthmore',
    state: 'PA',
    latitude: '39.896544',
    longitude: '-75.343979',
    timeZoneId: 'America/New_York',
  },
  '19082': {
    zip: '19082',
    city: 'Upper Darby',
    state: 'PA',
    latitude: '39.956267',
    longitude: '-75.274357',
    timeZoneId: 'America/New_York',
  },
  '19083': {
    zip: '19083',
    city: 'Havertown',
    state: 'PA',
    latitude: '39.974773',
    longitude: '-75.308851',
    timeZoneId: 'America/New_York',
  },
  '19085': {
    zip: '19085',
    city: 'Villanova',
    state: 'PA',
    latitude: '40.0362',
    longitude: '-75.34932',
    timeZoneId: 'America/New_York',
  },
  '19086': {
    zip: '19086',
    city: 'Wallingford',
    state: 'PA',
    latitude: '39.889823',
    longitude: '-75.370994',
    timeZoneId: 'America/New_York',
  },
  '19087': {
    zip: '19087',
    city: 'Wayne',
    state: 'PA',
    latitude: '40.038508',
    longitude: '-75.383299',
    timeZoneId: 'America/New_York',
  },
  '19088': {
    zip: '19088',
    city: 'Wayne',
    state: 'PA',
    latitude: '40.048256',
    longitude: '-75.355995',
    timeZoneId: 'America/New_York',
  },
  '19089': {
    zip: '19089',
    city: 'Wayne',
    state: 'PA',
    latitude: '40.045461',
    longitude: '-75.387632',
    timeZoneId: 'America/New_York',
  },
  '19090': {
    zip: '19090',
    city: 'Willow Grove',
    state: 'PA',
    latitude: '40.145928',
    longitude: '-75.122767',
    timeZoneId: 'America/New_York',
  },
  '19091': {
    zip: '19091',
    city: 'Media',
    state: 'PA',
    latitude: '39.91905',
    longitude: '-75.387375',
    timeZoneId: 'America/New_York',
  },
  '19092': {
    zip: '19092',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.952579',
    longitude: '-75.181778',
    timeZoneId: 'America/New_York',
  },
  '19093': {
    zip: '19093',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.955914',
    longitude: '-75.182383',
    timeZoneId: 'America/New_York',
  },
  '19094': {
    zip: '19094',
    city: 'Woodlyn',
    state: 'PA',
    latitude: '39.873824',
    longitude: '-75.345641',
    timeZoneId: 'America/New_York',
  },
  '19095': {
    zip: '19095',
    city: 'Wyncote',
    state: 'PA',
    latitude: '40.086592',
    longitude: '-75.150296',
    timeZoneId: 'America/New_York',
  },
  '19096': {
    zip: '19096',
    city: 'Wynnewood',
    state: 'PA',
    latitude: '40.00251',
    longitude: '-75.273155',
    timeZoneId: 'America/New_York',
  },
  '19098': {
    zip: '19098',
    city: 'Holmes',
    state: 'PA',
    latitude: '39.904384',
    longitude: '-75.308537',
    timeZoneId: 'America/New_York',
  },
  '19099': {
    zip: '19099',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.953075',
    longitude: '-75.161034',
    timeZoneId: 'America/New_York',
  },
  '19101': {
    zip: '19101',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.003344',
    longitude: '-75.136687',
    timeZoneId: 'America/New_York',
  },
  '19102': {
    zip: '19102',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.952547',
    longitude: '-75.16395',
    timeZoneId: 'America/New_York',
  },
  '19103': {
    zip: '19103',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.953767',
    longitude: '-75.176335',
    timeZoneId: 'America/New_York',
  },
  '19104': {
    zip: '19104',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.963766',
    longitude: '-75.198203',
    timeZoneId: 'America/New_York',
  },
  '19105': {
    zip: '19105',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.952879',
    longitude: '-75.157935',
    timeZoneId: 'America/New_York',
  },
  '19106': {
    zip: '19106',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951659',
    longitude: '-75.144608',
    timeZoneId: 'America/New_York',
  },
  '19107': {
    zip: '19107',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.948927',
    longitude: '-75.158663',
    timeZoneId: 'America/New_York',
  },
  '19108': {
    zip: '19108',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.062989',
    longitude: '-75.200974',
    timeZoneId: 'America/New_York',
  },
  '19109': {
    zip: '19109',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.950326',
    longitude: '-75.156065',
    timeZoneId: 'America/New_York',
  },
  '19110': {
    zip: '19110',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.953564',
    longitude: '-75.166015',
    timeZoneId: 'America/New_York',
  },
  '19111': {
    zip: '19111',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.057937',
    longitude: '-75.081966',
    timeZoneId: 'America/New_York',
  },
  '19112': {
    zip: '19112',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.890037',
    longitude: '-75.176966',
    timeZoneId: 'America/New_York',
  },
  '19113': {
    zip: '19113',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.868191',
    longitude: '-75.284889',
    timeZoneId: 'America/New_York',
  },
  '19114': {
    zip: '19114',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.064913',
    longitude: '-75.003036',
    timeZoneId: 'America/New_York',
  },
  '19115': {
    zip: '19115',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.092863',
    longitude: '-75.041136',
    timeZoneId: 'America/New_York',
  },
  '19116': {
    zip: '19116',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.121574',
    longitude: '-75.018481',
    timeZoneId: 'America/New_York',
  },
  '19118': {
    zip: '19118',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.086847',
    longitude: '-75.197968',
    timeZoneId: 'America/New_York',
  },
  '19119': {
    zip: '19119',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.069561',
    longitude: '-75.210372',
    timeZoneId: 'America/New_York',
  },
  '19120': {
    zip: '19120',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.036043',
    longitude: '-75.119434',
    timeZoneId: 'America/New_York',
  },
  '19121': {
    zip: '19121',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.980775',
    longitude: '-75.17217',
    timeZoneId: 'America/New_York',
  },
  '19122': {
    zip: '19122',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.976781',
    longitude: '-75.144244',
    timeZoneId: 'America/New_York',
  },
  '19123': {
    zip: '19123',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.963063',
    longitude: '-75.149978',
    timeZoneId: 'America/New_York',
  },
  '19124': {
    zip: '19124',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.013115',
    longitude: '-75.094734',
    timeZoneId: 'America/New_York',
  },
  '19125': {
    zip: '19125',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.979114',
    longitude: '-75.123882',
    timeZoneId: 'America/New_York',
  },
  '19126': {
    zip: '19126',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.055969',
    longitude: '-75.135303',
    timeZoneId: 'America/New_York',
  },
  '19127': {
    zip: '19127',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.029648',
    longitude: '-75.223171',
    timeZoneId: 'America/New_York',
  },
  '19128': {
    zip: '19128',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.042066',
    longitude: '-75.225337',
    timeZoneId: 'America/New_York',
  },
  '19129': {
    zip: '19129',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.010366',
    longitude: '-75.183597',
    timeZoneId: 'America/New_York',
  },
  '19130': {
    zip: '19130',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.968491',
    longitude: '-75.173091',
    timeZoneId: 'America/New_York',
  },
  '19131': {
    zip: '19131',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.978878',
    longitude: '-75.220604',
    timeZoneId: 'America/New_York',
  },
  '19132': {
    zip: '19132',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.992702',
    longitude: '-75.166366',
    timeZoneId: 'America/New_York',
  },
  '19133': {
    zip: '19133',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.992137',
    longitude: '-75.143289',
    timeZoneId: 'America/New_York',
  },
  '19134': {
    zip: '19134',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.994605',
    longitude: '-75.113258',
    timeZoneId: 'America/New_York',
  },
  '19135': {
    zip: '19135',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.024203',
    longitude: '-75.049991',
    timeZoneId: 'America/New_York',
  },
  '19136': {
    zip: '19136',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.044445',
    longitude: '-75.024762',
    timeZoneId: 'America/New_York',
  },
  '19137': {
    zip: '19137',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.997468',
    longitude: '-75.099036',
    timeZoneId: 'America/New_York',
  },
  '19138': {
    zip: '19138',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.058584',
    longitude: '-75.160929',
    timeZoneId: 'America/New_York',
  },
  '19139': {
    zip: '19139',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.962447',
    longitude: '-75.230101',
    timeZoneId: 'America/New_York',
  },
  '19140': {
    zip: '19140',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.013413',
    longitude: '-75.144023',
    timeZoneId: 'America/New_York',
  },
  '19141': {
    zip: '19141',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.03465',
    longitude: '-75.144766',
    timeZoneId: 'America/New_York',
  },
  '19142': {
    zip: '19142',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.923516',
    longitude: '-75.233199',
    timeZoneId: 'America/New_York',
  },
  '19143': {
    zip: '19143',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.943711',
    longitude: '-75.229131',
    timeZoneId: 'America/New_York',
  },
  '19144': {
    zip: '19144',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.031925',
    longitude: '-75.173958',
    timeZoneId: 'America/New_York',
  },
  '19145': {
    zip: '19145',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.924229',
    longitude: '-75.186845',
    timeZoneId: 'America/New_York',
  },
  '19146': {
    zip: '19146',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.936445',
    longitude: '-75.183641',
    timeZoneId: 'America/New_York',
  },
  '19147': {
    zip: '19147',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.936737',
    longitude: '-75.154764',
    timeZoneId: 'America/New_York',
  },
  '19148': {
    zip: '19148',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.922429',
    longitude: '-75.158025',
    timeZoneId: 'America/New_York',
  },
  '19149': {
    zip: '19149',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.038521',
    longitude: '-75.067175',
    timeZoneId: 'America/New_York',
  },
  '19150': {
    zip: '19150',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.07091',
    longitude: '-75.17139',
    timeZoneId: 'America/New_York',
  },
  '19151': {
    zip: '19151',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.977579',
    longitude: '-75.254673',
    timeZoneId: 'America/New_York',
  },
  '19152': {
    zip: '19152',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.057073',
    longitude: '-75.047092',
    timeZoneId: 'America/New_York',
  },
  '19153': {
    zip: '19153',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.887687',
    longitude: '-75.233918',
    timeZoneId: 'America/New_York',
  },
  '19154': {
    zip: '19154',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.085444',
    longitude: '-74.983544',
    timeZoneId: 'America/New_York',
  },
  '19155': {
    zip: '19155',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.096358',
    longitude: '-74.978989',
    timeZoneId: 'America/New_York',
  },
  '19160': {
    zip: '19160',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.011141',
    longitude: '-75.147333',
    timeZoneId: 'America/New_York',
  },
  '19161': {
    zip: '19161',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.065457',
    longitude: '-75.080697',
    timeZoneId: 'America/New_York',
  },
  '19162': {
    zip: '19162',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.955702',
    longitude: '-75.181007',
    timeZoneId: 'America/New_York',
  },
  '19170': {
    zip: '19170',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.952013',
    longitude: '-75.183323',
    timeZoneId: 'America/New_York',
  },
  '19171': {
    zip: '19171',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.955628',
    longitude: '-75.15526',
    timeZoneId: 'America/New_York',
  },
  '19172': {
    zip: '19172',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.945336',
    longitude: '-75.151079',
    timeZoneId: 'America/New_York',
  },
  '19173': {
    zip: '19173',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.954726',
    longitude: '-75.169864',
    timeZoneId: 'America/New_York',
  },
  '19175': {
    zip: '19175',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.949991',
    longitude: '-75.154637',
    timeZoneId: 'America/New_York',
  },
  '19176': {
    zip: '19176',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.952222',
    longitude: '-75.164167',
    timeZoneId: 'America/New_York',
  },
  '19177': {
    zip: '19177',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951003',
    longitude: '-75.14975',
    timeZoneId: 'America/New_York',
  },
  '19178': {
    zip: '19178',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.953092',
    longitude: '-75.184961',
    timeZoneId: 'America/New_York',
  },
  '19179': {
    zip: '19179',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '40.032062',
    longitude: '-75.177546',
    timeZoneId: 'America/New_York',
  },
  '19181': {
    zip: '19181',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951446',
    longitude: '-75.14702',
    timeZoneId: 'America/New_York',
  },
  '19182': {
    zip: '19182',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951857',
    longitude: '-75.150106',
    timeZoneId: 'America/New_York',
  },
  '19183': {
    zip: '19183',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951604',
    longitude: '-75.168997',
    timeZoneId: 'America/New_York',
  },
  '19184': {
    zip: '19184',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.953202',
    longitude: '-75.183899',
    timeZoneId: 'America/New_York',
  },
  '19185': {
    zip: '19185',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.891542',
    longitude: '-75.169424',
    timeZoneId: 'America/New_York',
  },
  '19187': {
    zip: '19187',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.954771',
    longitude: '-75.167691',
    timeZoneId: 'America/New_York',
  },
  '19188': {
    zip: '19188',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.963336',
    longitude: '-75.159526',
    timeZoneId: 'America/New_York',
  },
  '19190': {
    zip: '19190',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.953099',
    longitude: '-75.166343',
    timeZoneId: 'America/New_York',
  },
  '19191': {
    zip: '19191',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.952279',
    longitude: '-75.165967',
    timeZoneId: 'America/New_York',
  },
  '19192': {
    zip: '19192',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.955297',
    longitude: '-75.184537',
    timeZoneId: 'America/New_York',
  },
  '19193': {
    zip: '19193',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.955683',
    longitude: '-75.184056',
    timeZoneId: 'America/New_York',
  },
  '19194': {
    zip: '19194',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.95494',
    longitude: '-75.183065',
    timeZoneId: 'America/New_York',
  },
  '19195': {
    zip: '19195',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.9525',
    longitude: '-75.1645',
    timeZoneId: 'America/New_York',
  },
  '19196': {
    zip: '19196',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.957442',
    longitude: '-75.184264',
    timeZoneId: 'America/New_York',
  },
  '19197': {
    zip: '19197',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.954696',
    longitude: '-75.178777',
    timeZoneId: 'America/New_York',
  },
  '19244': {
    zip: '19244',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951444',
    longitude: '-75.151511',
    timeZoneId: 'America/New_York',
  },
  '19255': {
    zip: '19255',
    city: 'Philadelphia',
    state: 'PA',
    latitude: '39.951255',
    longitude: '-75.163103',
    timeZoneId: 'America/New_York',
  },
  '19301': {
    zip: '19301',
    city: 'Paoli',
    state: 'PA',
    latitude: '40.039129',
    longitude: '-75.489847',
    timeZoneId: 'America/New_York',
  },
  '19310': {
    zip: '19310',
    city: 'Atglen',
    state: 'PA',
    latitude: '39.948925',
    longitude: '-75.972001',
    timeZoneId: 'America/New_York',
  },
  '19311': {
    zip: '19311',
    city: 'Avondale',
    state: 'PA',
    latitude: '39.822055',
    longitude: '-75.777223',
    timeZoneId: 'America/New_York',
  },
  '19312': {
    zip: '19312',
    city: 'Berwyn',
    state: 'PA',
    latitude: '40.034377',
    longitude: '-75.464176',
    timeZoneId: 'America/New_York',
  },
  '19316': {
    zip: '19316',
    city: 'Brandamore',
    state: 'PA',
    latitude: '40.048316',
    longitude: '-75.831566',
    timeZoneId: 'America/New_York',
  },
  '19317': {
    zip: '19317',
    city: 'Chadds Ford',
    state: 'PA',
    latitude: '39.853281',
    longitude: '-75.609998',
    timeZoneId: 'America/New_York',
  },
  '19318': {
    zip: '19318',
    city: 'Chatham',
    state: 'PA',
    latitude: '39.855966',
    longitude: '-75.822922',
    timeZoneId: 'America/New_York',
  },
  '19319': {
    zip: '19319',
    city: 'Cheyney',
    state: 'PA',
    latitude: '39.929221',
    longitude: '-75.516239',
    timeZoneId: 'America/New_York',
  },
  '19320': {
    zip: '19320',
    city: 'Coatesville',
    state: 'PA',
    latitude: '39.984475',
    longitude: '-75.810978',
    timeZoneId: 'America/New_York',
  },
  '19330': {
    zip: '19330',
    city: 'Cochranville',
    state: 'PA',
    latitude: '39.869928',
    longitude: '-75.900225',
    timeZoneId: 'America/New_York',
  },
  '19331': {
    zip: '19331',
    city: 'Concordville',
    state: 'PA',
    latitude: '39.880054',
    longitude: '-75.519758',
    timeZoneId: 'America/New_York',
  },
  '19333': {
    zip: '19333',
    city: 'Devon',
    state: 'PA',
    latitude: '40.044055',
    longitude: '-75.42494',
    timeZoneId: 'America/New_York',
  },
  '19335': {
    zip: '19335',
    city: 'Downingtown',
    state: 'PA',
    latitude: '40.0165',
    longitude: '-75.713718',
    timeZoneId: 'America/New_York',
  },
  '19339': {
    zip: '19339',
    city: 'Concordville',
    state: 'PA',
    latitude: '39.89912',
    longitude: '-75.504198',
    timeZoneId: 'America/New_York',
  },
  '19340': {
    zip: '19340',
    city: 'Concordville',
    state: 'PA',
    latitude: '39.886723',
    longitude: '-75.518894',
    timeZoneId: 'America/New_York',
  },
  '19341': {
    zip: '19341',
    city: 'Exton',
    state: 'PA',
    latitude: '40.040047',
    longitude: '-75.636825',
    timeZoneId: 'America/New_York',
  },
  '19342': {
    zip: '19342',
    city: 'Glen Mills',
    state: 'PA',
    latitude: '39.89614',
    longitude: '-75.501769',
    timeZoneId: 'America/New_York',
  },
  '19343': {
    zip: '19343',
    city: 'Glenmoore',
    state: 'PA',
    latitude: '40.094368',
    longitude: '-75.750753',
    timeZoneId: 'America/New_York',
  },
  '19344': {
    zip: '19344',
    city: 'Honey Brook',
    state: 'PA',
    latitude: '40.084833',
    longitude: '-75.898086',
    timeZoneId: 'America/New_York',
  },
  '19345': {
    zip: '19345',
    city: 'Immaculata',
    state: 'PA',
    latitude: '39.900291',
    longitude: '-75.671906',
    timeZoneId: 'America/New_York',
  },
  '19346': {
    zip: '19346',
    city: 'Kelton',
    state: 'PA',
    latitude: '39.794027',
    longitude: '-75.88851',
    timeZoneId: 'America/New_York',
  },
  '19347': {
    zip: '19347',
    city: 'Kemblesville',
    state: 'PA',
    latitude: '39.887483',
    longitude: '-75.647655',
    timeZoneId: 'America/New_York',
  },
  '19348': {
    zip: '19348',
    city: 'Kennett Square',
    state: 'PA',
    latitude: '39.86073',
    longitude: '-75.704389',
    timeZoneId: 'America/New_York',
  },
  '19350': {
    zip: '19350',
    city: 'Landenberg',
    state: 'PA',
    latitude: '39.758082',
    longitude: '-75.79046',
    timeZoneId: 'America/New_York',
  },
  '19351': {
    zip: '19351',
    city: 'Lewisville',
    state: 'PA',
    latitude: '39.722789',
    longitude: '-75.875297',
    timeZoneId: 'America/New_York',
  },
  '19352': {
    zip: '19352',
    city: 'Lincoln University',
    state: 'PA',
    latitude: '39.769645',
    longitude: '-75.89063',
    timeZoneId: 'America/New_York',
  },
  '19353': {
    zip: '19353',
    city: 'Lionville',
    state: 'PA',
    latitude: '40.0536',
    longitude: '-75.66018',
    timeZoneId: 'America/New_York',
  },
  '19354': {
    zip: '19354',
    city: 'Lyndell',
    state: 'PA',
    latitude: '40.05991',
    longitude: '-75.751833',
    timeZoneId: 'America/New_York',
  },
  '19355': {
    zip: '19355',
    city: 'Malvern',
    state: 'PA',
    latitude: '40.04136',
    longitude: '-75.53706',
    timeZoneId: 'America/New_York',
  },
  '19357': {
    zip: '19357',
    city: 'Mendenhall',
    state: 'PA',
    latitude: '39.853858',
    longitude: '-75.640348',
    timeZoneId: 'America/New_York',
  },
  '19358': {
    zip: '19358',
    city: 'Modena',
    state: 'PA',
    latitude: '39.96048',
    longitude: '-75.801461',
    timeZoneId: 'America/New_York',
  },
  '19360': {
    zip: '19360',
    city: 'New London',
    state: 'PA',
    latitude: '39.767028',
    longitude: '-75.889908',
    timeZoneId: 'America/New_York',
  },
  '19362': {
    zip: '19362',
    city: 'Nottingham',
    state: 'PA',
    latitude: '39.752457',
    longitude: '-76.067397',
    timeZoneId: 'America/New_York',
  },
  '19363': {
    zip: '19363',
    city: 'Oxford',
    state: 'PA',
    latitude: '39.787748',
    longitude: '-75.960554',
    timeZoneId: 'America/New_York',
  },
  '19365': {
    zip: '19365',
    city: 'Parkesburg',
    state: 'PA',
    latitude: '39.960339',
    longitude: '-75.92342',
    timeZoneId: 'America/New_York',
  },
  '19366': {
    zip: '19366',
    city: 'Pocopson',
    state: 'PA',
    latitude: '39.899041',
    longitude: '-75.625473',
    timeZoneId: 'America/New_York',
  },
  '19367': {
    zip: '19367',
    city: 'Pomeroy',
    state: 'PA',
    latitude: '39.96749',
    longitude: '-75.928117',
    timeZoneId: 'America/New_York',
  },
  '19369': {
    zip: '19369',
    city: 'Sadsburyville',
    state: 'PA',
    latitude: '39.980041',
    longitude: '-75.892351',
    timeZoneId: 'America/New_York',
  },
  '19371': {
    zip: '19371',
    city: 'Suplee',
    state: 'PA',
    latitude: '40.098036',
    longitude: '-75.879527',
    timeZoneId: 'America/New_York',
  },
  '19372': {
    zip: '19372',
    city: 'Thorndale',
    state: 'PA',
    latitude: '39.99276',
    longitude: '-75.75876',
    timeZoneId: 'America/New_York',
  },
  '19373': {
    zip: '19373',
    city: 'Thornton',
    state: 'PA',
    latitude: '39.90826',
    longitude: '-75.53489',
    timeZoneId: 'America/New_York',
  },
  '19374': {
    zip: '19374',
    city: 'Toughkenamon',
    state: 'PA',
    latitude: '39.835395',
    longitude: '-75.760821',
    timeZoneId: 'America/New_York',
  },
  '19375': {
    zip: '19375',
    city: 'Unionville',
    state: 'PA',
    latitude: '39.905006',
    longitude: '-75.730773',
    timeZoneId: 'America/New_York',
  },
  '19376': {
    zip: '19376',
    city: 'Wagontown',
    state: 'PA',
    latitude: '40.023441',
    longitude: '-75.826703',
    timeZoneId: 'America/New_York',
  },
  '19380': {
    zip: '19380',
    city: 'West Chester',
    state: 'PA',
    latitude: '39.982089',
    longitude: '-75.603385',
    timeZoneId: 'America/New_York',
  },
  '19381': {
    zip: '19381',
    city: 'West Chester',
    state: 'PA',
    latitude: '39.960448',
    longitude: '-75.601924',
    timeZoneId: 'America/New_York',
  },
  '19382': {
    zip: '19382',
    city: 'West Chester',
    state: 'PA',
    latitude: '39.942544',
    longitude: '-75.597966',
    timeZoneId: 'America/New_York',
  },
  '19383': {
    zip: '19383',
    city: 'West Chester',
    state: 'PA',
    latitude: '39.957668',
    longitude: '-75.604923',
    timeZoneId: 'America/New_York',
  },
  '19390': {
    zip: '19390',
    city: 'West Grove',
    state: 'PA',
    latitude: '39.821687',
    longitude: '-75.841624',
    timeZoneId: 'America/New_York',
  },
  '19395': {
    zip: '19395',
    city: 'Westtown',
    state: 'PA',
    latitude: '39.934998',
    longitude: '-75.545125',
    timeZoneId: 'America/New_York',
  },
  '19397': {
    zip: '19397',
    city: 'Southeastern',
    state: 'PA',
    latitude: '39.98224',
    longitude: '-75.508869',
    timeZoneId: 'America/New_York',
  },
  '19398': {
    zip: '19398',
    city: 'Southeastern',
    state: 'PA',
    latitude: '39.847666',
    longitude: '-75.699326',
    timeZoneId: 'America/New_York',
  },
  '19399': {
    zip: '19399',
    city: 'Southeastern',
    state: 'PA',
    latitude: '39.986656',
    longitude: '-75.510127',
    timeZoneId: 'America/New_York',
  },
  '19401': {
    zip: '19401',
    city: 'Norristown',
    state: 'PA',
    latitude: '40.13058',
    longitude: '-75.34752',
    timeZoneId: 'America/New_York',
  },
  '19403': {
    zip: '19403',
    city: 'Norristown',
    state: 'PA',
    latitude: '40.147771',
    longitude: '-75.375422',
    timeZoneId: 'America/New_York',
  },
  '19404': {
    zip: '19404',
    city: 'Norristown',
    state: 'PA',
    latitude: '40.14381',
    longitude: '-75.369566',
    timeZoneId: 'America/New_York',
  },
  '19405': {
    zip: '19405',
    city: 'Bridgeport',
    state: 'PA',
    latitude: '40.1034',
    longitude: '-75.3417',
    timeZoneId: 'America/New_York',
  },
  '19406': {
    zip: '19406',
    city: 'King of Prussia',
    state: 'PA',
    latitude: '40.11594',
    longitude: '-75.37374',
    timeZoneId: 'America/New_York',
  },
  '19407': {
    zip: '19407',
    city: 'Audubon',
    state: 'PA',
    latitude: '40.118426',
    longitude: '-75.446143',
    timeZoneId: 'America/New_York',
  },
  '19408': {
    zip: '19408',
    city: 'Eagleville',
    state: 'PA',
    latitude: '40.154128',
    longitude: '-75.414151',
    timeZoneId: 'America/New_York',
  },
  '19409': {
    zip: '19409',
    city: 'Fairview Village',
    state: 'PA',
    latitude: '40.121389',
    longitude: '-75.340278',
    timeZoneId: 'America/New_York',
  },
  '19415': {
    zip: '19415',
    city: 'Eagleville',
    state: 'PA',
    latitude: '40.159802',
    longitude: '-75.408718',
    timeZoneId: 'America/New_York',
  },
  '19421': {
    zip: '19421',
    city: 'Birchrunville',
    state: 'PA',
    latitude: '40.10644',
    longitude: '-75.644244',
    timeZoneId: 'America/New_York',
  },
  '19422': {
    zip: '19422',
    city: 'Blue Bell',
    state: 'PA',
    latitude: '40.15296',
    longitude: '-75.274659',
    timeZoneId: 'America/New_York',
  },
  '19423': {
    zip: '19423',
    city: 'Cedars',
    state: 'PA',
    latitude: '40.212',
    longitude: '-75.378308',
    timeZoneId: 'America/New_York',
  },
  '19424': {
    zip: '19424',
    city: 'Blue Bell',
    state: 'PA',
    latitude: '40.148617',
    longitude: '-75.266159',
    timeZoneId: 'America/New_York',
  },
  '19425': {
    zip: '19425',
    city: 'Chester Springs',
    state: 'PA',
    latitude: '40.089503',
    longitude: '-75.637922',
    timeZoneId: 'America/New_York',
  },
  '19426': {
    zip: '19426',
    city: 'Collegeville',
    state: 'PA',
    latitude: '40.18896',
    longitude: '-75.42636',
    timeZoneId: 'America/New_York',
  },
  '19428': {
    zip: '19428',
    city: 'Conshohocken',
    state: 'PA',
    latitude: '40.077938',
    longitude: '-75.303',
    timeZoneId: 'America/New_York',
  },
  '19429': {
    zip: '19429',
    city: 'Conshohocken',
    state: 'PA',
    latitude: '40.080514',
    longitude: '-75.30008',
    timeZoneId: 'America/New_York',
  },
  '19430': {
    zip: '19430',
    city: 'Creamery',
    state: 'PA',
    latitude: '40.212934',
    longitude: '-75.415802',
    timeZoneId: 'America/New_York',
  },
  '19432': {
    zip: '19432',
    city: 'Devault',
    state: 'PA',
    latitude: '40.080666',
    longitude: '-75.534691',
    timeZoneId: 'America/New_York',
  },
  '19435': {
    zip: '19435',
    city: 'Frederick',
    state: 'PA',
    latitude: '40.304515',
    longitude: '-75.538564',
    timeZoneId: 'America/New_York',
  },
  '19436': {
    zip: '19436',
    city: 'Gwynedd',
    state: 'PA',
    latitude: '40.20601',
    longitude: '-75.247779',
    timeZoneId: 'America/New_York',
  },
  '19437': {
    zip: '19437',
    city: 'Gwynedd Valley',
    state: 'PA',
    latitude: '40.182564',
    longitude: '-75.258744',
    timeZoneId: 'America/New_York',
  },
  '19438': {
    zip: '19438',
    city: 'Harleysville',
    state: 'PA',
    latitude: '40.267589',
    longitude: '-75.382781',
    timeZoneId: 'America/New_York',
  },
  '19440': {
    zip: '19440',
    city: 'Hatfield',
    state: 'PA',
    latitude: '40.274777',
    longitude: '-75.289827',
    timeZoneId: 'America/New_York',
  },
  '19441': {
    zip: '19441',
    city: 'Harleysville',
    state: 'PA',
    latitude: '40.278302',
    longitude: '-75.390438',
    timeZoneId: 'America/New_York',
  },
  '19442': {
    zip: '19442',
    city: 'Kimberton',
    state: 'PA',
    latitude: '40.134638',
    longitude: '-75.582908',
    timeZoneId: 'America/New_York',
  },
  '19443': {
    zip: '19443',
    city: 'Kulpsville',
    state: 'PA',
    latitude: '40.245647',
    longitude: '-75.341502',
    timeZoneId: 'America/New_York',
  },
  '19444': {
    zip: '19444',
    city: 'Lafayette Hill',
    state: 'PA',
    latitude: '40.086163',
    longitude: '-75.250559',
    timeZoneId: 'America/New_York',
  },
  '19446': {
    zip: '19446',
    city: 'Lansdale',
    state: 'PA',
    latitude: '40.235193',
    longitude: '-75.289081',
    timeZoneId: 'America/New_York',
  },
  '19450': {
    zip: '19450',
    city: 'Lederach',
    state: 'PA',
    latitude: '40.262252',
    longitude: '-75.418734',
    timeZoneId: 'America/New_York',
  },
  '19451': {
    zip: '19451',
    city: 'Mainland',
    state: 'PA',
    latitude: '40.265419',
    longitude: '-75.357024',
    timeZoneId: 'America/New_York',
  },
  '19453': {
    zip: '19453',
    city: 'Mont Clare',
    state: 'PA',
    latitude: '40.138403',
    longitude: '-75.50243',
    timeZoneId: 'America/New_York',
  },
  '19454': {
    zip: '19454',
    city: 'North Wales',
    state: 'PA',
    latitude: '40.215532',
    longitude: '-75.258606',
    timeZoneId: 'America/New_York',
  },
  '19455': {
    zip: '19455',
    city: 'North Wales',
    state: 'PA',
    latitude: '40.211065',
    longitude: '-75.2779',
    timeZoneId: 'America/New_York',
  },
  '19456': {
    zip: '19456',
    city: 'Oaks',
    state: 'PA',
    latitude: '40.135328',
    longitude: '-75.453612',
    timeZoneId: 'America/New_York',
  },
  '19457': {
    zip: '19457',
    city: 'Parker Ford',
    state: 'PA',
    latitude: '40.205934',
    longitude: '-75.592107',
    timeZoneId: 'America/New_York',
  },
  '19460': {
    zip: '19460',
    city: 'Phoenixville',
    state: 'PA',
    latitude: '40.111368',
    longitude: '-75.546198',
    timeZoneId: 'America/New_York',
  },
  '19462': {
    zip: '19462',
    city: 'Plymouth Meeting',
    state: 'PA',
    latitude: '40.108511',
    longitude: '-75.277184',
    timeZoneId: 'America/New_York',
  },
  '19464': {
    zip: '19464',
    city: 'Pottstown',
    state: 'PA',
    latitude: '40.24236',
    longitude: '-75.63666',
    timeZoneId: 'America/New_York',
  },
  '19465': {
    zip: '19465',
    city: 'Pottstown',
    state: 'PA',
    latitude: '40.217022',
    longitude: '-75.648374',
    timeZoneId: 'America/New_York',
  },
  '19468': {
    zip: '19468',
    city: 'Royersford',
    state: 'PA',
    latitude: '40.19682',
    longitude: '-75.53928',
    timeZoneId: 'America/New_York',
  },
  '19470': {
    zip: '19470',
    city: 'Saint Peters',
    state: 'PA',
    latitude: '40.173768',
    longitude: '-75.732305',
    timeZoneId: 'America/New_York',
  },
  '19472': {
    zip: '19472',
    city: 'Sassamansville',
    state: 'PA',
    latitude: '40.339201',
    longitude: '-75.57337',
    timeZoneId: 'America/New_York',
  },
  '19473': {
    zip: '19473',
    city: 'Schwenksville',
    state: 'PA',
    latitude: '40.23768',
    longitude: '-75.48144',
    timeZoneId: 'America/New_York',
  },
  '19474': {
    zip: '19474',
    city: 'Skippack',
    state: 'PA',
    latitude: '40.222746',
    longitude: '-75.398674',
    timeZoneId: 'America/New_York',
  },
  '19475': {
    zip: '19475',
    city: 'Spring City',
    state: 'PA',
    latitude: '40.17399',
    longitude: '-75.572077',
    timeZoneId: 'America/New_York',
  },
  '19477': {
    zip: '19477',
    city: 'Spring House',
    state: 'PA',
    latitude: '40.210833',
    longitude: '-75.278611',
    timeZoneId: 'America/New_York',
  },
  '19478': {
    zip: '19478',
    city: 'Spring Mount',
    state: 'PA',
    latitude: '40.274069',
    longitude: '-75.46075',
    timeZoneId: 'America/New_York',
  },
  '19480': {
    zip: '19480',
    city: 'Uwchland',
    state: 'PA',
    latitude: '40.081499',
    longitude: '-75.694821',
    timeZoneId: 'America/New_York',
  },
  '19481': {
    zip: '19481',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.09124',
    longitude: '-75.474279',
    timeZoneId: 'America/New_York',
  },
  '19482': {
    zip: '19482',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.094429',
    longitude: '-75.46924',
    timeZoneId: 'America/New_York',
  },
  '19484': {
    zip: '19484',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.111315',
    longitude: '-75.402927',
    timeZoneId: 'America/New_York',
  },
  '19486': {
    zip: '19486',
    city: 'West Point',
    state: 'PA',
    latitude: '40.209809',
    longitude: '-75.303409',
    timeZoneId: 'America/New_York',
  },
  '19490': {
    zip: '19490',
    city: 'Worcester',
    state: 'PA',
    latitude: '40.197251',
    longitude: '-75.344516',
    timeZoneId: 'America/New_York',
  },
  '19492': {
    zip: '19492',
    city: 'Zieglerville',
    state: 'PA',
    latitude: '40.283333',
    longitude: '-75.483333',
    timeZoneId: 'America/New_York',
  },
  '19493': {
    zip: '19493',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.053935',
    longitude: '-75.556943',
    timeZoneId: 'America/New_York',
  },
  '19494': {
    zip: '19494',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.054579',
    longitude: '-75.556882',
    timeZoneId: 'America/New_York',
  },
  '19495': {
    zip: '19495',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.054002',
    longitude: '-75.557455',
    timeZoneId: 'America/New_York',
  },
  '19496': {
    zip: '19496',
    city: 'Valley Forge',
    state: 'PA',
    latitude: '40.097292',
    longitude: '-75.468489',
    timeZoneId: 'America/New_York',
  },
  '19501': {
    zip: '19501',
    city: 'Adamstown',
    state: 'PA',
    latitude: '40.244709',
    longitude: '-76.056831',
    timeZoneId: 'America/New_York',
  },
  '19503': {
    zip: '19503',
    city: 'Bally',
    state: 'PA',
    latitude: '40.401509',
    longitude: '-75.587981',
    timeZoneId: 'America/New_York',
  },
  '19504': {
    zip: '19504',
    city: 'Barto',
    state: 'PA',
    latitude: '40.38846',
    longitude: '-75.57822',
    timeZoneId: 'America/New_York',
  },
  '19505': {
    zip: '19505',
    city: 'Bechtelsville',
    state: 'PA',
    latitude: '40.372171',
    longitude: '-75.62795',
    timeZoneId: 'America/New_York',
  },
  '19506': {
    zip: '19506',
    city: 'Bernville',
    state: 'PA',
    latitude: '40.4481',
    longitude: '-76.13304',
    timeZoneId: 'America/New_York',
  },
  '19507': {
    zip: '19507',
    city: 'Bethel',
    state: 'PA',
    latitude: '40.492547',
    longitude: '-76.295435',
    timeZoneId: 'America/New_York',
  },
  '19508': {
    zip: '19508',
    city: 'Birdsboro',
    state: 'PA',
    latitude: '40.284167',
    longitude: '-75.872222',
    timeZoneId: 'America/New_York',
  },
  '19510': {
    zip: '19510',
    city: 'Blandon',
    state: 'PA',
    latitude: '40.441303',
    longitude: '-75.879383',
    timeZoneId: 'America/New_York',
  },
  '19511': {
    zip: '19511',
    city: 'Bowers',
    state: 'PA',
    latitude: '40.51881',
    longitude: '-75.77435',
    timeZoneId: 'America/New_York',
  },
  '19512': {
    zip: '19512',
    city: 'Boyertown',
    state: 'PA',
    latitude: '40.333043',
    longitude: '-75.640609',
    timeZoneId: 'America/New_York',
  },
  '19516': {
    zip: '19516',
    city: 'Centerport',
    state: 'PA',
    latitude: '40.484168',
    longitude: '-76.008507',
    timeZoneId: 'America/New_York',
  },
  '19518': {
    zip: '19518',
    city: 'Douglassville',
    state: 'PA',
    latitude: '40.323056',
    longitude: '-75.755',
    timeZoneId: 'America/New_York',
  },
  '19519': {
    zip: '19519',
    city: 'Earlville',
    state: 'PA',
    latitude: '40.314999',
    longitude: '-75.738387',
    timeZoneId: 'America/New_York',
  },
  '19520': {
    zip: '19520',
    city: 'Elverson',
    state: 'PA',
    latitude: '40.158113',
    longitude: '-75.78678',
    timeZoneId: 'America/New_York',
  },
  '19522': {
    zip: '19522',
    city: 'Fleetwood',
    state: 'PA',
    latitude: '40.475833',
    longitude: '-75.895',
    timeZoneId: 'America/New_York',
  },
  '19523': {
    zip: '19523',
    city: 'Geigertown',
    state: 'PA',
    latitude: '40.203402',
    longitude: '-75.8382',
    timeZoneId: 'America/New_York',
  },
  '19525': {
    zip: '19525',
    city: 'Gilbertsville',
    state: 'PA',
    latitude: '40.31196',
    longitude: '-75.58938',
    timeZoneId: 'America/New_York',
  },
  '19526': {
    zip: '19526',
    city: 'Hamburg',
    state: 'PA',
    latitude: '40.55',
    longitude: '-76.016667',
    timeZoneId: 'America/New_York',
  },
  '19529': {
    zip: '19529',
    city: 'Kempton',
    state: 'PA',
    latitude: '40.635556',
    longitude: '-75.853611',
    timeZoneId: 'America/New_York',
  },
  '19530': {
    zip: '19530',
    city: 'Kutztown',
    state: 'PA',
    latitude: '40.53378',
    longitude: '-75.78954',
    timeZoneId: 'America/New_York',
  },
  '19533': {
    zip: '19533',
    city: 'Leesport',
    state: 'PA',
    latitude: '40.440626',
    longitude: '-75.974641',
    timeZoneId: 'America/New_York',
  },
  '19534': {
    zip: '19534',
    city: 'Lenhartsville',
    state: 'PA',
    latitude: '40.5717',
    longitude: '-75.84918',
    timeZoneId: 'America/New_York',
  },
  '19535': {
    zip: '19535',
    city: 'Limekiln',
    state: 'PA',
    latitude: '40.379094',
    longitude: '-75.763387',
    timeZoneId: 'America/New_York',
  },
  '19536': {
    zip: '19536',
    city: 'Lyon Station',
    state: 'PA',
    latitude: '40.480714',
    longitude: '-75.757241',
    timeZoneId: 'America/New_York',
  },
  '19538': {
    zip: '19538',
    city: 'Maxatawny',
    state: 'PA',
    latitude: '40.544055',
    longitude: '-75.691496',
    timeZoneId: 'America/New_York',
  },
  '19539': {
    zip: '19539',
    city: 'Mertztown',
    state: 'PA',
    latitude: '40.49034',
    longitude: '-75.69216',
    timeZoneId: 'America/New_York',
  },
  '19540': {
    zip: '19540',
    city: 'Mohnton',
    state: 'PA',
    latitude: '40.284584',
    longitude: '-75.988345',
    timeZoneId: 'America/New_York',
  },
  '19541': {
    zip: '19541',
    city: 'Mohrsville',
    state: 'PA',
    latitude: '40.477756',
    longitude: '-76.012001',
    timeZoneId: 'America/New_York',
  },
  '19543': {
    zip: '19543',
    city: 'Morgantown',
    state: 'PA',
    latitude: '40.167689',
    longitude: '-75.88701',
    timeZoneId: 'America/New_York',
  },
  '19544': {
    zip: '19544',
    city: 'Mount Aetna',
    state: 'PA',
    latitude: '40.418727',
    longitude: '-76.295812',
    timeZoneId: 'America/New_York',
  },
  '19545': {
    zip: '19545',
    city: 'New Berlinville',
    state: 'PA',
    latitude: '40.344188',
    longitude: '-75.633347',
    timeZoneId: 'America/New_York',
  },
  '19547': {
    zip: '19547',
    city: 'Oley',
    state: 'PA',
    latitude: '40.383022',
    longitude: '-75.78712',
    timeZoneId: 'America/New_York',
  },
  '19548': {
    zip: '19548',
    city: 'Pine Forge',
    state: 'PA',
    latitude: '40.285607',
    longitude: '-75.692533',
    timeZoneId: 'America/New_York',
  },
  '19549': {
    zip: '19549',
    city: 'Port Clinton',
    state: 'PA',
    latitude: '40.582691',
    longitude: '-76.029863',
    timeZoneId: 'America/New_York',
  },
  '19550': {
    zip: '19550',
    city: 'Rehrersburg',
    state: 'PA',
    latitude: '40.459219',
    longitude: '-76.243509',
    timeZoneId: 'America/New_York',
  },
  '19551': {
    zip: '19551',
    city: 'Robesonia',
    state: 'PA',
    latitude: '40.352879',
    longitude: '-76.135855',
    timeZoneId: 'America/New_York',
  },
  '19554': {
    zip: '19554',
    city: 'Shartlesville',
    state: 'PA',
    latitude: '40.51372',
    longitude: '-76.105043',
    timeZoneId: 'America/New_York',
  },
  '19555': {
    zip: '19555',
    city: 'Shoemakersville',
    state: 'PA',
    latitude: '40.496804',
    longitude: '-75.955057',
    timeZoneId: 'America/New_York',
  },
  '19559': {
    zip: '19559',
    city: 'Strausstown',
    state: 'PA',
    latitude: '40.492216',
    longitude: '-76.184763',
    timeZoneId: 'America/New_York',
  },
  '19560': {
    zip: '19560',
    city: 'Temple',
    state: 'PA',
    latitude: '40.39614',
    longitude: '-75.88962',
    timeZoneId: 'America/New_York',
  },
  '19562': {
    zip: '19562',
    city: 'Topton',
    state: 'PA',
    latitude: '40.50292',
    longitude: '-75.703015',
    timeZoneId: 'America/New_York',
  },
  '19564': {
    zip: '19564',
    city: 'Virginville',
    state: 'PA',
    latitude: '40.522052',
    longitude: '-75.870747',
    timeZoneId: 'America/New_York',
  },
  '19565': {
    zip: '19565',
    city: 'Wernersville',
    state: 'PA',
    latitude: '40.323518',
    longitude: '-76.086686',
    timeZoneId: 'America/New_York',
  },
  '19567': {
    zip: '19567',
    city: 'Womelsdorf',
    state: 'PA',
    latitude: '40.386594',
    longitude: '-76.216795',
    timeZoneId: 'America/New_York',
  },
  '19601': {
    zip: '19601',
    city: 'Reading',
    state: 'PA',
    latitude: '40.347781',
    longitude: '-75.9384',
    timeZoneId: 'America/New_York',
  },
  '19602': {
    zip: '19602',
    city: 'Reading',
    state: 'PA',
    latitude: '40.329373',
    longitude: '-75.921719',
    timeZoneId: 'America/New_York',
  },
  '19603': {
    zip: '19603',
    city: 'Reading',
    state: 'PA',
    latitude: '40.37399',
    longitude: '-75.927958',
    timeZoneId: 'America/New_York',
  },
  '19604': {
    zip: '19604',
    city: 'Reading',
    state: 'PA',
    latitude: '40.35485',
    longitude: '-75.907765',
    timeZoneId: 'America/New_York',
  },
  '19605': {
    zip: '19605',
    city: 'Reading',
    state: 'PA',
    latitude: '40.38604',
    longitude: '-75.932598',
    timeZoneId: 'America/New_York',
  },
  '19606': {
    zip: '19606',
    city: 'Reading',
    state: 'PA',
    latitude: '40.322494',
    longitude: '-75.86781',
    timeZoneId: 'America/New_York',
  },
  '19607': {
    zip: '19607',
    city: 'Reading',
    state: 'PA',
    latitude: '40.31112',
    longitude: '-75.92382',
    timeZoneId: 'America/New_York',
  },
  '19608': {
    zip: '19608',
    city: 'Reading',
    state: 'PA',
    latitude: '40.312265',
    longitude: '-76.023139',
    timeZoneId: 'America/New_York',
  },
  '19609': {
    zip: '19609',
    city: 'Reading',
    state: 'PA',
    latitude: '40.324875',
    longitude: '-75.998193',
    timeZoneId: 'America/New_York',
  },
  '19610': {
    zip: '19610',
    city: 'Reading',
    state: 'PA',
    latitude: '40.338971',
    longitude: '-75.981605',
    timeZoneId: 'America/New_York',
  },
  '19611': {
    zip: '19611',
    city: 'Reading',
    state: 'PA',
    latitude: '40.324211',
    longitude: '-75.944403',
    timeZoneId: 'America/New_York',
  },
  '19612': {
    zip: '19612',
    city: 'Reading',
    state: 'PA',
    latitude: '40.365134',
    longitude: '-75.909435',
    timeZoneId: 'America/New_York',
  },
  '19701': {
    zip: '19701',
    city: 'Bear',
    state: 'DE',
    latitude: '39.609577',
    longitude: '-75.690962',
    timeZoneId: 'America/New_York',
  },
  '19702': {
    zip: '19702',
    city: 'Newark',
    state: 'DE',
    latitude: '39.60461',
    longitude: '-75.74627',
    timeZoneId: 'America/New_York',
  },
  '19703': {
    zip: '19703',
    city: 'Claymont',
    state: 'DE',
    latitude: '39.804953',
    longitude: '-75.464429',
    timeZoneId: 'America/New_York',
  },
  '19706': {
    zip: '19706',
    city: 'Delaware City',
    state: 'DE',
    latitude: '39.571134',
    longitude: '-75.597542',
    timeZoneId: 'America/New_York',
  },
  '19707': {
    zip: '19707',
    city: 'Hockessin',
    state: 'DE',
    latitude: '39.77827',
    longitude: '-75.687486',
    timeZoneId: 'America/New_York',
  },
  '19708': {
    zip: '19708',
    city: 'Kirkwood',
    state: 'DE',
    latitude: '39.498425',
    longitude: '-75.684892',
    timeZoneId: 'America/New_York',
  },
  '19709': {
    zip: '19709',
    city: 'Middletown',
    state: 'DE',
    latitude: '39.488886',
    longitude: '-75.685331',
    timeZoneId: 'America/New_York',
  },
  '19710': {
    zip: '19710',
    city: 'Montchanin',
    state: 'DE',
    latitude: '39.794599',
    longitude: '-75.585633',
    timeZoneId: 'America/New_York',
  },
  '19711': {
    zip: '19711',
    city: 'Newark',
    state: 'DE',
    latitude: '39.70785',
    longitude: '-75.734964',
    timeZoneId: 'America/New_York',
  },
  '19712': {
    zip: '19712',
    city: 'Newark',
    state: 'DE',
    latitude: '39.682928',
    longitude: '-75.71918',
    timeZoneId: 'America/New_York',
  },
  '19713': {
    zip: '19713',
    city: 'Newark',
    state: 'DE',
    latitude: '39.670132',
    longitude: '-75.719978',
    timeZoneId: 'America/New_York',
  },
  '19714': {
    zip: '19714',
    city: 'Newark',
    state: 'DE',
    latitude: '39.714157',
    longitude: '-75.741454',
    timeZoneId: 'America/New_York',
  },
  '19715': {
    zip: '19715',
    city: 'Newark',
    state: 'DE',
    latitude: '39.714043',
    longitude: '-75.737757',
    timeZoneId: 'America/New_York',
  },
  '19716': {
    zip: '19716',
    city: 'Newark',
    state: 'DE',
    latitude: '39.714507',
    longitude: '-75.738715',
    timeZoneId: 'America/New_York',
  },
  '19717': {
    zip: '19717',
    city: 'Newark',
    state: 'DE',
    latitude: '39.713625',
    longitude: '-75.740186',
    timeZoneId: 'America/New_York',
  },
  '19718': {
    zip: '19718',
    city: 'Newark',
    state: 'DE',
    latitude: '39.6665',
    longitude: '-75.707524',
    timeZoneId: 'America/New_York',
  },
  '19720': {
    zip: '19720',
    city: 'New Castle',
    state: 'DE',
    latitude: '39.673263',
    longitude: '-75.595423',
    timeZoneId: 'America/New_York',
  },
  '19721': {
    zip: '19721',
    city: 'New Castle',
    state: 'DE',
    latitude: '39.663657',
    longitude: '-75.568203',
    timeZoneId: 'America/New_York',
  },
  '19725': {
    zip: '19725',
    city: 'Newark',
    state: 'DE',
    latitude: '39.685667',
    longitude: '-75.737283',
    timeZoneId: 'America/New_York',
  },
  '19726': {
    zip: '19726',
    city: 'New Castle',
    state: 'DE',
    latitude: '39.684577',
    longitude: '-75.735242',
    timeZoneId: 'America/New_York',
  },
  '19730': {
    zip: '19730',
    city: 'Odessa',
    state: 'DE',
    latitude: '39.45713',
    longitude: '-75.657005',
    timeZoneId: 'America/New_York',
  },
  '19731': {
    zip: '19731',
    city: 'Port Penn',
    state: 'DE',
    latitude: '39.515664',
    longitude: '-75.574443',
    timeZoneId: 'America/New_York',
  },
  '19732': {
    zip: '19732',
    city: 'Rockland',
    state: 'DE',
    latitude: '39.792047',
    longitude: '-75.572962',
    timeZoneId: 'America/New_York',
  },
  '19733': {
    zip: '19733',
    city: 'Saint Georges',
    state: 'DE',
    latitude: '39.554866',
    longitude: '-75.653688',
    timeZoneId: 'America/New_York',
  },
  '19734': {
    zip: '19734',
    city: 'Townsend',
    state: 'DE',
    latitude: '39.38256',
    longitude: '-75.66528',
    timeZoneId: 'America/New_York',
  },
  '19735': {
    zip: '19735',
    city: 'Winterthur',
    state: 'DE',
    latitude: '39.802932',
    longitude: '-75.612558',
    timeZoneId: 'America/New_York',
  },
  '19736': {
    zip: '19736',
    city: 'Yorklyn',
    state: 'DE',
    latitude: '39.802285',
    longitude: '-75.665154',
    timeZoneId: 'America/New_York',
  },
  '19801': {
    zip: '19801',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.736602',
    longitude: '-75.548998',
    timeZoneId: 'America/New_York',
  },
  '19802': {
    zip: '19802',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.75519',
    longitude: '-75.53087',
    timeZoneId: 'America/New_York',
  },
  '19803': {
    zip: '19803',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.794276',
    longitude: '-75.534321',
    timeZoneId: 'America/New_York',
  },
  '19804': {
    zip: '19804',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.72468',
    longitude: '-75.60606',
    timeZoneId: 'America/New_York',
  },
  '19805': {
    zip: '19805',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.73164',
    longitude: '-75.62424',
    timeZoneId: 'America/New_York',
  },
  '19806': {
    zip: '19806',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.759555',
    longitude: '-75.566524',
    timeZoneId: 'America/New_York',
  },
  '19807': {
    zip: '19807',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.79488',
    longitude: '-75.61674',
    timeZoneId: 'America/New_York',
  },
  '19808': {
    zip: '19808',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.731712',
    longitude: '-75.669224',
    timeZoneId: 'America/New_York',
  },
  '19809': {
    zip: '19809',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.771847',
    longitude: '-75.496485',
    timeZoneId: 'America/New_York',
  },
  '19810': {
    zip: '19810',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.82058',
    longitude: '-75.508102',
    timeZoneId: 'America/New_York',
  },
  '19850': {
    zip: '19850',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.728944',
    longitude: '-75.537337',
    timeZoneId: 'America/New_York',
  },
  '19880': {
    zip: '19880',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.730183',
    longitude: '-75.538618',
    timeZoneId: 'America/New_York',
  },
  '19884': {
    zip: '19884',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.743248',
    longitude: '-75.545385',
    timeZoneId: 'America/New_York',
  },
  '19885': {
    zip: '19885',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.743278',
    longitude: '-75.547128',
    timeZoneId: 'America/New_York',
  },
  '19886': {
    zip: '19886',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.74827',
    longitude: '-75.545647',
    timeZoneId: 'America/New_York',
  },
  '19890': {
    zip: '19890',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.738223',
    longitude: '-75.550288',
    timeZoneId: 'America/New_York',
  },
  '19891': {
    zip: '19891',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.682268',
    longitude: '-75.693124',
    timeZoneId: 'America/New_York',
  },
  '19892': {
    zip: '19892',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.683601',
    longitude: '-75.613075',
    timeZoneId: 'America/New_York',
  },
  '19893': {
    zip: '19893',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.747693',
    longitude: '-75.557912',
    timeZoneId: 'America/New_York',
  },
  '19894': {
    zip: '19894',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.74852',
    longitude: '-75.544089',
    timeZoneId: 'America/New_York',
  },
  '19895': {
    zip: '19895',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.74384',
    longitude: '-75.549474',
    timeZoneId: 'America/New_York',
  },
  '19896': {
    zip: '19896',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.758421',
    longitude: '-75.527711',
    timeZoneId: 'America/New_York',
  },
  '19897': {
    zip: '19897',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.798051',
    longitude: '-75.545651',
    timeZoneId: 'America/New_York',
  },
  '19898': {
    zip: '19898',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.742451',
    longitude: '-75.549413',
    timeZoneId: 'America/New_York',
  },
  '19899': {
    zip: '19899',
    city: 'Wilmington',
    state: 'DE',
    latitude: '39.735632',
    longitude: '-75.547657',
    timeZoneId: 'America/New_York',
  },
  '19901': {
    zip: '19901',
    city: 'Dover',
    state: 'DE',
    latitude: '39.149969',
    longitude: '-75.531543',
    timeZoneId: 'America/New_York',
  },
  '19902': {
    zip: '19902',
    city: 'Dover AFB',
    state: 'DE',
    latitude: '39.120867',
    longitude: '-75.478601',
    timeZoneId: 'America/New_York',
  },
  '19903': {
    zip: '19903',
    city: 'Dover',
    state: 'DE',
    latitude: '39.209462',
    longitude: '-75.570122',
    timeZoneId: 'America/New_York',
  },
  '19904': {
    zip: '19904',
    city: 'Dover',
    state: 'DE',
    latitude: '39.159797',
    longitude: '-75.52696',
    timeZoneId: 'America/New_York',
  },
  '19905': {
    zip: '19905',
    city: 'Dover',
    state: 'DE',
    latitude: '39.158991',
    longitude: '-75.520428',
    timeZoneId: 'America/New_York',
  },
  '19906': {
    zip: '19906',
    city: 'Dover',
    state: 'DE',
    latitude: '39.158893',
    longitude: '-75.526594',
    timeZoneId: 'America/New_York',
  },
  '19930': {
    zip: '19930',
    city: 'Bethany Beach',
    state: 'DE',
    latitude: '38.530364',
    longitude: '-75.062715',
    timeZoneId: 'America/New_York',
  },
  '19931': {
    zip: '19931',
    city: 'Bethel',
    state: 'DE',
    latitude: '38.56519',
    longitude: '-75.626034',
    timeZoneId: 'America/New_York',
  },
  '19933': {
    zip: '19933',
    city: 'Bridgeville',
    state: 'DE',
    latitude: '38.738901',
    longitude: '-75.593665',
    timeZoneId: 'America/New_York',
  },
  '19934': {
    zip: '19934',
    city: 'Camden Wyoming',
    state: 'DE',
    latitude: '39.09534',
    longitude: '-75.5703',
    timeZoneId: 'America/New_York',
  },
  '19936': {
    zip: '19936',
    city: 'Cheswold',
    state: 'DE',
    latitude: '39.215385',
    longitude: '-75.587941',
    timeZoneId: 'America/New_York',
  },
  '19938': {
    zip: '19938',
    city: 'Clayton',
    state: 'DE',
    latitude: '39.300955',
    longitude: '-75.708637',
    timeZoneId: 'America/New_York',
  },
  '19939': {
    zip: '19939',
    city: 'Dagsboro',
    state: 'DE',
    latitude: '38.554789',
    longitude: '-75.218796',
    timeZoneId: 'America/New_York',
  },
  '19940': {
    zip: '19940',
    city: 'Delmar',
    state: 'DE',
    latitude: '38.455711',
    longitude: '-75.574361',
    timeZoneId: 'America/New_York',
  },
  '19941': {
    zip: '19941',
    city: 'Ellendale',
    state: 'DE',
    latitude: '38.777869',
    longitude: '-75.366822',
    timeZoneId: 'America/New_York',
  },
  '19943': {
    zip: '19943',
    city: 'Felton',
    state: 'DE',
    latitude: '39.013699',
    longitude: '-75.572862',
    timeZoneId: 'America/New_York',
  },
  '19944': {
    zip: '19944',
    city: 'Fenwick Island',
    state: 'DE',
    latitude: '38.459312',
    longitude: '-75.052878',
    timeZoneId: 'America/New_York',
  },
  '19945': {
    zip: '19945',
    city: 'Frankford',
    state: 'DE',
    latitude: '38.524385',
    longitude: '-75.205393',
    timeZoneId: 'America/New_York',
  },
  '19946': {
    zip: '19946',
    city: 'Frederica',
    state: 'DE',
    latitude: '39.014861',
    longitude: '-75.479837',
    timeZoneId: 'America/New_York',
  },
  '19947': {
    zip: '19947',
    city: 'Georgetown',
    state: 'DE',
    latitude: '38.68802',
    longitude: '-75.382138',
    timeZoneId: 'America/New_York',
  },
  '19950': {
    zip: '19950',
    city: 'Greenwood',
    state: 'DE',
    latitude: '38.807972',
    longitude: '-75.590786',
    timeZoneId: 'America/New_York',
  },
  '19951': {
    zip: '19951',
    city: 'Harbeson',
    state: 'DE',
    latitude: '38.66118',
    longitude: '-75.176994',
    timeZoneId: 'America/New_York',
  },
  '19952': {
    zip: '19952',
    city: 'Harrington',
    state: 'DE',
    latitude: '38.93692',
    longitude: '-75.582667',
    timeZoneId: 'America/New_York',
  },
  '19953': {
    zip: '19953',
    city: 'Hartly',
    state: 'DE',
    latitude: '39.150871',
    longitude: '-75.693133',
    timeZoneId: 'America/New_York',
  },
  '19954': {
    zip: '19954',
    city: 'Houston',
    state: 'DE',
    latitude: '38.919187',
    longitude: '-75.504777',
    timeZoneId: 'America/New_York',
  },
  '19955': {
    zip: '19955',
    city: 'Kenton',
    state: 'DE',
    latitude: '39.228201',
    longitude: '-75.665752',
    timeZoneId: 'America/New_York',
  },
  '19956': {
    zip: '19956',
    city: 'Laurel',
    state: 'DE',
    latitude: '38.55245',
    longitude: '-75.569735',
    timeZoneId: 'America/New_York',
  },
  '19958': {
    zip: '19958',
    city: 'Lewes',
    state: 'DE',
    latitude: '38.7258',
    longitude: '-75.17418',
    timeZoneId: 'America/New_York',
  },
  '19960': {
    zip: '19960',
    city: 'Lincoln',
    state: 'DE',
    latitude: '38.857908',
    longitude: '-75.432843',
    timeZoneId: 'America/New_York',
  },
  '19961': {
    zip: '19961',
    city: 'Little Creek',
    state: 'DE',
    latitude: '39.164886',
    longitude: '-75.452048',
    timeZoneId: 'America/New_York',
  },
  '19962': {
    zip: '19962',
    city: 'Magnolia',
    state: 'DE',
    latitude: '39.071345',
    longitude: '-75.5265',
    timeZoneId: 'America/New_York',
  },
  '19963': {
    zip: '19963',
    city: 'Milford',
    state: 'DE',
    latitude: '38.903495',
    longitude: '-75.432364',
    timeZoneId: 'America/New_York',
  },
  '19964': {
    zip: '19964',
    city: 'Marydel',
    state: 'DE',
    latitude: '39.101607',
    longitude: '-75.727487',
    timeZoneId: 'America/New_York',
  },
  '19966': {
    zip: '19966',
    city: 'Millsboro',
    state: 'DE',
    latitude: '38.60496',
    longitude: '-75.22326',
    timeZoneId: 'America/New_York',
  },
  '19967': {
    zip: '19967',
    city: 'Millville',
    state: 'DE',
    latitude: '38.565806',
    longitude: '-75.113837',
    timeZoneId: 'America/New_York',
  },
  '19968': {
    zip: '19968',
    city: 'Milton',
    state: 'DE',
    latitude: '38.781391',
    longitude: '-75.282612',
    timeZoneId: 'America/New_York',
  },
  '19969': {
    zip: '19969',
    city: 'Nassau',
    state: 'DE',
    latitude: '38.754434',
    longitude: '-75.187853',
    timeZoneId: 'America/New_York',
  },
  '19970': {
    zip: '19970',
    city: 'Ocean View',
    state: 'DE',
    latitude: '38.555449',
    longitude: '-75.108954',
    timeZoneId: 'America/New_York',
  },
  '19971': {
    zip: '19971',
    city: 'Rehoboth Beach',
    state: 'DE',
    latitude: '38.72654',
    longitude: '-75.08125',
    timeZoneId: 'America/New_York',
  },
  '19973': {
    zip: '19973',
    city: 'Seaford',
    state: 'DE',
    latitude: '38.63874',
    longitude: '-75.61098',
    timeZoneId: 'America/New_York',
  },
  '19975': {
    zip: '19975',
    city: 'Selbyville',
    state: 'DE',
    latitude: '38.4663',
    longitude: '-75.14982',
    timeZoneId: 'America/New_York',
  },
  '19977': {
    zip: '19977',
    city: 'Smyrna',
    state: 'DE',
    latitude: '39.291184',
    longitude: '-75.606382',
    timeZoneId: 'America/New_York',
  },
  '19979': {
    zip: '19979',
    city: 'Viola',
    state: 'DE',
    latitude: '39.038951',
    longitude: '-75.569599',
    timeZoneId: 'America/New_York',
  },
  '19980': {
    zip: '19980',
    city: 'Woodside',
    state: 'DE',
    latitude: '39.069528',
    longitude: '-75.566951',
    timeZoneId: 'America/New_York',
  },
  '20001': {
    zip: '20001',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906639',
    longitude: '-77.016577',
    timeZoneId: 'America/New_York',
  },
  '20002': {
    zip: '20002',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905231',
    longitude: '-76.992743',
    timeZoneId: 'America/New_York',
  },
  '20003': {
    zip: '20003',
    city: 'Washington',
    state: 'DC',
    latitude: '38.880908',
    longitude: '-76.994494',
    timeZoneId: 'America/New_York',
  },
  '20004': {
    zip: '20004',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892163',
    longitude: '-77.027748',
    timeZoneId: 'America/New_York',
  },
  '20005': {
    zip: '20005',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905386',
    longitude: '-77.030988',
    timeZoneId: 'America/New_York',
  },
  '20006': {
    zip: '20006',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89617',
    longitude: '-77.042671',
    timeZoneId: 'America/New_York',
  },
  '20007': {
    zip: '20007',
    city: 'Washington',
    state: 'DC',
    latitude: '38.912682',
    longitude: '-77.071704',
    timeZoneId: 'America/New_York',
  },
  '20008': {
    zip: '20008',
    city: 'Washington',
    state: 'DC',
    latitude: '38.935762',
    longitude: '-77.059427',
    timeZoneId: 'America/New_York',
  },
  '20009': {
    zip: '20009',
    city: 'Washington',
    state: 'DC',
    latitude: '38.917847',
    longitude: '-77.035346',
    timeZoneId: 'America/New_York',
  },
  '20010': {
    zip: '20010',
    city: 'Washington',
    state: 'DC',
    latitude: '38.930413',
    longitude: '-77.028189',
    timeZoneId: 'America/New_York',
  },
  '20011': {
    zip: '20011',
    city: 'Washington',
    state: 'DC',
    latitude: '38.949806',
    longitude: '-77.015627',
    timeZoneId: 'America/New_York',
  },
  '20012': {
    zip: '20012',
    city: 'Washington',
    state: 'DC',
    latitude: '38.973853',
    longitude: '-77.030348',
    timeZoneId: 'America/New_York',
  },
  '20013': {
    zip: '20013',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894993',
    longitude: '-77.044909',
    timeZoneId: 'America/New_York',
  },
  '20015': {
    zip: '20015',
    city: 'Washington',
    state: 'DC',
    latitude: '38.963203',
    longitude: '-77.065176',
    timeZoneId: 'America/New_York',
  },
  '20016': {
    zip: '20016',
    city: 'Washington',
    state: 'DC',
    latitude: '38.940315',
    longitude: '-77.088596',
    timeZoneId: 'America/New_York',
  },
  '20017': {
    zip: '20017',
    city: 'Washington',
    state: 'DC',
    latitude: '38.938481',
    longitude: '-76.994394',
    timeZoneId: 'America/New_York',
  },
  '20018': {
    zip: '20018',
    city: 'Washington',
    state: 'DC',
    latitude: '38.927297',
    longitude: '-76.975967',
    timeZoneId: 'America/New_York',
  },
  '20019': {
    zip: '20019',
    city: 'Washington',
    state: 'DC',
    latitude: '38.890726',
    longitude: '-76.938125',
    timeZoneId: 'America/New_York',
  },
  '20020': {
    zip: '20020',
    city: 'Washington',
    state: 'DC',
    latitude: '38.86439',
    longitude: '-76.971958',
    timeZoneId: 'America/New_York',
  },
  '20022': {
    zip: '20022',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906942',
    longitude: '-76.99134',
    timeZoneId: 'America/New_York',
  },
  '20024': {
    zip: '20024',
    city: 'Washington',
    state: 'DC',
    latitude: '38.879013',
    longitude: '-77.020737',
    timeZoneId: 'America/New_York',
  },
  '20026': {
    zip: '20026',
    city: 'Washington',
    state: 'DC',
    latitude: '38.880334',
    longitude: '-77.019327',
    timeZoneId: 'America/New_York',
  },
  '20027': {
    zip: '20027',
    city: 'Washington',
    state: 'DC',
    latitude: '38.912207',
    longitude: '-77.074325',
    timeZoneId: 'America/New_York',
  },
  '20029': {
    zip: '20029',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892932',
    longitude: '-76.939967',
    timeZoneId: 'America/New_York',
  },
  '20030': {
    zip: '20030',
    city: 'Washington',
    state: 'DC',
    latitude: '38.862889',
    longitude: '-76.97473',
    timeZoneId: 'America/New_York',
  },
  '20032': {
    zip: '20032',
    city: 'Washington',
    state: 'DC',
    latitude: '38.833027',
    longitude: '-76.997902',
    timeZoneId: 'America/New_York',
  },
  '20033': {
    zip: '20033',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905164',
    longitude: '-77.041426',
    timeZoneId: 'America/New_York',
  },
  '20035': {
    zip: '20035',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905389',
    longitude: '-77.039447',
    timeZoneId: 'America/New_York',
  },
  '20036': {
    zip: '20036',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905726',
    longitude: '-77.038962',
    timeZoneId: 'America/New_York',
  },
  '20037': {
    zip: '20037',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904693',
    longitude: '-77.050778',
    timeZoneId: 'America/New_York',
  },
  '20038': {
    zip: '20038',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905008',
    longitude: '-77.031432',
    timeZoneId: 'America/New_York',
  },
  '20039': {
    zip: '20039',
    city: 'Washington',
    state: 'DC',
    latitude: '38.954422',
    longitude: '-77.02636',
    timeZoneId: 'America/New_York',
  },
  '20040': {
    zip: '20040',
    city: 'Washington',
    state: 'DC',
    latitude: '38.966723',
    longitude: '-77.031587',
    timeZoneId: 'America/New_York',
  },
  '20041': {
    zip: '20041',
    city: 'Washington',
    state: 'DC',
    latitude: '38.94',
    longitude: '-77.46',
    timeZoneId: 'America/New_York',
  },
  '20042': {
    zip: '20042',
    city: 'Washington',
    state: 'DC',
    latitude: '38.916732',
    longitude: '-76.994294',
    timeZoneId: 'America/New_York',
  },
  '20043': {
    zip: '20043',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905357',
    longitude: '-77.031771',
    timeZoneId: 'America/New_York',
  },
  '20044': {
    zip: '20044',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895999',
    longitude: '-77.025808',
    timeZoneId: 'America/New_York',
  },
  '20045': {
    zip: '20045',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895329',
    longitude: '-77.025372',
    timeZoneId: 'America/New_York',
  },
  '20047': {
    zip: '20047',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20049': {
    zip: '20049',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894428',
    longitude: '-77.020323',
    timeZoneId: 'America/New_York',
  },
  '20050': {
    zip: '20050',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906181',
    longitude: '-76.987222',
    timeZoneId: 'America/New_York',
  },
  '20052': {
    zip: '20052',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898075',
    longitude: '-77.043933',
    timeZoneId: 'America/New_York',
  },
  '20053': {
    zip: '20053',
    city: 'Washington',
    state: 'DC',
    latitude: '38.884726',
    longitude: '-77.012221',
    timeZoneId: 'America/New_York',
  },
  '20055': {
    zip: '20055',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901404',
    longitude: '-77.023348',
    timeZoneId: 'America/New_York',
  },
  '20056': {
    zip: '20056',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906628',
    longitude: '-76.986151',
    timeZoneId: 'America/New_York',
  },
  '20057': {
    zip: '20057',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905206',
    longitude: '-77.07547',
    timeZoneId: 'America/New_York',
  },
  '20058': {
    zip: '20058',
    city: 'Washington',
    state: 'DC',
    latitude: '38.92',
    longitude: '-76.99',
    timeZoneId: 'America/New_York',
  },
  '20059': {
    zip: '20059',
    city: 'Washington',
    state: 'DC',
    latitude: '38.923858',
    longitude: '-77.016121',
    timeZoneId: 'America/New_York',
  },
  '20060': {
    zip: '20060',
    city: 'Washington',
    state: 'DC',
    latitude: '38.918639',
    longitude: '-77.020382',
    timeZoneId: 'America/New_York',
  },
  '20061': {
    zip: '20061',
    city: 'Washington',
    state: 'DC',
    latitude: '38.91888',
    longitude: '-76.993855',
    timeZoneId: 'America/New_York',
  },
  '20062': {
    zip: '20062',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901909',
    longitude: '-77.03628',
    timeZoneId: 'America/New_York',
  },
  '20063': {
    zip: '20063',
    city: 'Washington',
    state: 'DC',
    latitude: '38.903209',
    longitude: '-77.044386',
    timeZoneId: 'America/New_York',
  },
  '20064': {
    zip: '20064',
    city: 'Washington',
    state: 'DC',
    latitude: '38.937522',
    longitude: '-76.996839',
    timeZoneId: 'America/New_York',
  },
  '20065': {
    zip: '20065',
    city: 'Washington',
    state: 'DC',
    latitude: '38.882711',
    longitude: '-77.027194',
    timeZoneId: 'America/New_York',
  },
  '20066': {
    zip: '20066',
    city: 'Washington',
    state: 'DC',
    latitude: '38.918215',
    longitude: '-76.995482',
    timeZoneId: 'America/New_York',
  },
  '20067': {
    zip: '20067',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898458',
    longitude: '-77.043288',
    timeZoneId: 'America/New_York',
  },
  '20068': {
    zip: '20068',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901054',
    longitude: '-77.046743',
    timeZoneId: 'America/New_York',
  },
  '20069': {
    zip: '20069',
    city: 'Washington',
    state: 'DC',
    latitude: '38.92',
    longitude: '-76.99',
    timeZoneId: 'America/New_York',
  },
  '20070': {
    zip: '20070',
    city: 'Washington',
    state: 'DC',
    latitude: '38.92',
    longitude: '-76.99',
    timeZoneId: 'America/New_York',
  },
  '20071': {
    zip: '20071',
    city: 'Washington',
    state: 'DC',
    latitude: '38.903241',
    longitude: '-77.036061',
    timeZoneId: 'America/New_York',
  },
  '20073': {
    zip: '20073',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896184',
    longitude: '-77.022512',
    timeZoneId: 'America/New_York',
  },
  '20074': {
    zip: '20074',
    city: 'Washington',
    state: 'DC',
    latitude: '38.914711',
    longitude: '-76.995922',
    timeZoneId: 'America/New_York',
  },
  '20075': {
    zip: '20075',
    city: 'Washington',
    state: 'DC',
    latitude: '38.916959',
    longitude: '-76.995806',
    timeZoneId: 'America/New_York',
  },
  '20076': {
    zip: '20076',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20077': {
    zip: '20077',
    city: 'Washington',
    state: 'DC',
    latitude: '38.919481',
    longitude: '-76.994663',
    timeZoneId: 'America/New_York',
  },
  '20078': {
    zip: '20078',
    city: 'Washington',
    state: 'DC',
    latitude: '38.916403',
    longitude: '-76.994507',
    timeZoneId: 'America/New_York',
  },
  '20080': {
    zip: '20080',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900032',
    longitude: '-77.026546',
    timeZoneId: 'America/New_York',
  },
  '20081': {
    zip: '20081',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899462',
    longitude: '-77.026641',
    timeZoneId: 'America/New_York',
  },
  '20082': {
    zip: '20082',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896423',
    longitude: '-77.008777',
    timeZoneId: 'America/New_York',
  },
  '20090': {
    zip: '20090',
    city: 'Washington',
    state: 'DC',
    latitude: '38.878902',
    longitude: '-77.015978',
    timeZoneId: 'America/New_York',
  },
  '20091': {
    zip: '20091',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904862',
    longitude: '-77.026188',
    timeZoneId: 'America/New_York',
  },
  '20101': {
    zip: '20101',
    city: 'Dulles',
    state: 'VA',
    latitude: '38.988346',
    longitude: '-77.451916',
    timeZoneId: 'America/New_York',
  },
  '20102': {
    zip: '20102',
    city: 'Dulles',
    state: 'VA',
    latitude: '38.956561',
    longitude: '-77.450321',
    timeZoneId: 'America/New_York',
  },
  '20103': {
    zip: '20103',
    city: 'Dulles',
    state: 'VA',
    latitude: '38.997377',
    longitude: '-77.451478',
    timeZoneId: 'America/New_York',
  },
  '20104': {
    zip: '20104',
    city: 'Dulles',
    state: 'VA',
    latitude: '39.326264',
    longitude: '-77.534628',
    timeZoneId: 'America/New_York',
  },
  '20105': {
    zip: '20105',
    city: 'Aldie',
    state: 'VA',
    latitude: '38.954967',
    longitude: '-77.598321',
    timeZoneId: 'America/New_York',
  },
  '20106': {
    zip: '20106',
    city: 'Amissville',
    state: 'VA',
    latitude: '38.671345',
    longitude: '-77.996631',
    timeZoneId: 'America/New_York',
  },
  '20108': {
    zip: '20108',
    city: 'Manassas',
    state: 'VA',
    latitude: '38.745944',
    longitude: '-77.48577',
    timeZoneId: 'America/New_York',
  },
  '20109': {
    zip: '20109',
    city: 'Manassas',
    state: 'VA',
    latitude: '38.799297',
    longitude: '-77.546852',
    timeZoneId: 'America/New_York',
  },
  '20110': {
    zip: '20110',
    city: 'Manassas',
    state: 'VA',
    latitude: '38.748646',
    longitude: '-77.477633',
    timeZoneId: 'America/New_York',
  },
  '20111': {
    zip: '20111',
    city: 'Manassas',
    state: 'VA',
    latitude: '38.7379',
    longitude: '-77.422197',
    timeZoneId: 'America/New_York',
  },
  '20112': {
    zip: '20112',
    city: 'Manassas',
    state: 'VA',
    latitude: '38.673619',
    longitude: '-77.42675',
    timeZoneId: 'America/New_York',
  },
  '20113': {
    zip: '20113',
    city: 'Manassas',
    state: 'VA',
    latitude: '38.786738',
    longitude: '-77.455746',
    timeZoneId: 'America/New_York',
  },
  '20115': {
    zip: '20115',
    city: 'Marshall',
    state: 'VA',
    latitude: '38.8111',
    longitude: '-77.91833',
    timeZoneId: 'America/New_York',
  },
  '20116': {
    zip: '20116',
    city: 'Marshall',
    state: 'VA',
    latitude: '38.868361',
    longitude: '-77.852454',
    timeZoneId: 'America/New_York',
  },
  '20117': {
    zip: '20117',
    city: 'Middleburg',
    state: 'VA',
    latitude: '39.006815',
    longitude: '-77.748359',
    timeZoneId: 'America/New_York',
  },
  '20118': {
    zip: '20118',
    city: 'Middleburg',
    state: 'VA',
    latitude: '38.967524',
    longitude: '-77.734278',
    timeZoneId: 'America/New_York',
  },
  '20119': {
    zip: '20119',
    city: 'Catlett',
    state: 'VA',
    latitude: '38.656521',
    longitude: '-77.641059',
    timeZoneId: 'America/New_York',
  },
  '20120': {
    zip: '20120',
    city: 'Centreville',
    state: 'VA',
    latitude: '38.850947',
    longitude: '-77.46113',
    timeZoneId: 'America/New_York',
  },
  '20121': {
    zip: '20121',
    city: 'Centreville',
    state: 'VA',
    latitude: '38.81819',
    longitude: '-77.468677',
    timeZoneId: 'America/New_York',
  },
  '20122': {
    zip: '20122',
    city: 'Centreville',
    state: 'VA',
    latitude: '38.855942',
    longitude: '-77.478615',
    timeZoneId: 'America/New_York',
  },
  '20124': {
    zip: '20124',
    city: 'Clifton',
    state: 'VA',
    latitude: '38.781025',
    longitude: '-77.385955',
    timeZoneId: 'America/New_York',
  },
  '20128': {
    zip: '20128',
    city: 'Orlean',
    state: 'VA',
    latitude: '38.749579',
    longitude: '-77.962459',
    timeZoneId: 'America/New_York',
  },
  '20129': {
    zip: '20129',
    city: 'Paeonian Springs',
    state: 'VA',
    latitude: '39.156021',
    longitude: '-77.597753',
    timeZoneId: 'America/New_York',
  },
  '20130': {
    zip: '20130',
    city: 'Paris',
    state: 'VA',
    latitude: '39.035313',
    longitude: '-77.943225',
    timeZoneId: 'America/New_York',
  },
  '20131': {
    zip: '20131',
    city: 'Philomont',
    state: 'VA',
    latitude: '39.058149',
    longitude: '-77.740608',
    timeZoneId: 'America/New_York',
  },
  '20132': {
    zip: '20132',
    city: 'Purcellville',
    state: 'VA',
    latitude: '39.163955',
    longitude: '-77.727182',
    timeZoneId: 'America/New_York',
  },
  '20134': {
    zip: '20134',
    city: 'Purcellville',
    state: 'VA',
    latitude: '39.142329',
    longitude: '-77.711386',
    timeZoneId: 'America/New_York',
  },
  '20135': {
    zip: '20135',
    city: 'Bluemont',
    state: 'VA',
    latitude: '39.091285',
    longitude: '-77.841619',
    timeZoneId: 'America/New_York',
  },
  '20136': {
    zip: '20136',
    city: 'Bristow',
    state: 'VA',
    latitude: '38.721168',
    longitude: '-77.535341',
    timeZoneId: 'America/New_York',
  },
  '20137': {
    zip: '20137',
    city: 'Broad Run',
    state: 'VA',
    latitude: '38.823769',
    longitude: '-77.715374',
    timeZoneId: 'America/New_York',
  },
  '20138': {
    zip: '20138',
    city: 'Calverton',
    state: 'VA',
    latitude: '38.631396',
    longitude: '-77.67255',
    timeZoneId: 'America/New_York',
  },
  '20139': {
    zip: '20139',
    city: 'Casanova',
    state: 'VA',
    latitude: '38.66039',
    longitude: '-77.715837',
    timeZoneId: 'America/New_York',
  },
  '20140': {
    zip: '20140',
    city: 'Rectortown',
    state: 'VA',
    latitude: '38.920368',
    longitude: '-77.862916',
    timeZoneId: 'America/New_York',
  },
  '20141': {
    zip: '20141',
    city: 'Round Hill',
    state: 'VA',
    latitude: '39.123098',
    longitude: '-77.785374',
    timeZoneId: 'America/New_York',
  },
  '20142': {
    zip: '20142',
    city: 'Round Hill',
    state: 'VA',
    latitude: '39.135038',
    longitude: '-77.771792',
    timeZoneId: 'America/New_York',
  },
  '20143': {
    zip: '20143',
    city: 'Catharpin',
    state: 'VA',
    latitude: '38.847611',
    longitude: '-77.565954',
    timeZoneId: 'America/New_York',
  },
  '20144': {
    zip: '20144',
    city: 'Delaplane',
    state: 'VA',
    latitude: '38.912253',
    longitude: '-77.917386',
    timeZoneId: 'America/New_York',
  },
  '20146': {
    zip: '20146',
    city: 'Ashburn',
    state: 'VA',
    latitude: '39.01905',
    longitude: '-77.460991',
    timeZoneId: 'America/New_York',
  },
  '20147': {
    zip: '20147',
    city: 'Ashburn',
    state: 'VA',
    latitude: '39.032894',
    longitude: '-77.486598',
    timeZoneId: 'America/New_York',
  },
  '20148': {
    zip: '20148',
    city: 'Ashburn',
    state: 'VA',
    latitude: '38.994011',
    longitude: '-77.531312',
    timeZoneId: 'America/New_York',
  },
  '20149': {
    zip: '20149',
    city: 'Ashburn',
    state: 'VA',
    latitude: '39.043616',
    longitude: '-77.481607',
    timeZoneId: 'America/New_York',
  },
  '20151': {
    zip: '20151',
    city: 'Chantilly',
    state: 'VA',
    latitude: '38.888306',
    longitude: '-77.453725',
    timeZoneId: 'America/New_York',
  },
  '20152': {
    zip: '20152',
    city: 'Chantilly',
    state: 'VA',
    latitude: '38.907611',
    longitude: '-77.518015',
    timeZoneId: 'America/New_York',
  },
  '20153': {
    zip: '20153',
    city: 'Chantilly',
    state: 'VA',
    latitude: '38.88759',
    longitude: '-77.441421',
    timeZoneId: 'America/New_York',
  },
  '20155': {
    zip: '20155',
    city: 'Gainesville',
    state: 'VA',
    latitude: '38.78348',
    longitude: '-77.655222',
    timeZoneId: 'America/New_York',
  },
  '20156': {
    zip: '20156',
    city: 'Gainesville',
    state: 'VA',
    latitude: '38.791954',
    longitude: '-77.62537',
    timeZoneId: 'America/New_York',
  },
  '20158': {
    zip: '20158',
    city: 'Hamilton',
    state: 'VA',
    latitude: '39.12931',
    longitude: '-77.659616',
    timeZoneId: 'America/New_York',
  },
  '20159': {
    zip: '20159',
    city: 'Hamilton',
    state: 'VA',
    latitude: '39.134248',
    longitude: '-77.664947',
    timeZoneId: 'America/New_York',
  },
  '20160': {
    zip: '20160',
    city: 'Lincoln',
    state: 'VA',
    latitude: '39.116202',
    longitude: '-77.693967',
    timeZoneId: 'America/New_York',
  },
  '20163': {
    zip: '20163',
    city: 'Sterling',
    state: 'VA',
    latitude: '38.98799',
    longitude: '-77.450131',
    timeZoneId: 'America/New_York',
  },
  '20164': {
    zip: '20164',
    city: 'Sterling',
    state: 'VA',
    latitude: '39.011683',
    longitude: '-77.393194',
    timeZoneId: 'America/New_York',
  },
  '20165': {
    zip: '20165',
    city: 'Sterling',
    state: 'VA',
    latitude: '39.053592',
    longitude: '-77.392876',
    timeZoneId: 'America/New_York',
  },
  '20166': {
    zip: '20166',
    city: 'Sterling',
    state: 'VA',
    latitude: '38.995686',
    longitude: '-77.435025',
    timeZoneId: 'America/New_York',
  },
  '20167': {
    zip: '20167',
    city: 'Sterling',
    state: 'VA',
    latitude: '39.017034',
    longitude: '-77.394089',
    timeZoneId: 'America/New_York',
  },
  '20168': {
    zip: '20168',
    city: 'Haymarket',
    state: 'VA',
    latitude: '38.810991',
    longitude: '-77.635632',
    timeZoneId: 'America/New_York',
  },
  '20169': {
    zip: '20169',
    city: 'Haymarket',
    state: 'VA',
    latitude: '38.872792',
    longitude: '-77.660457',
    timeZoneId: 'America/New_York',
  },
  '20170': {
    zip: '20170',
    city: 'Herndon',
    state: 'VA',
    latitude: '38.981236',
    longitude: '-77.387257',
    timeZoneId: 'America/New_York',
  },
  '20171': {
    zip: '20171',
    city: 'Herndon',
    state: 'VA',
    latitude: '38.925711',
    longitude: '-77.403864',
    timeZoneId: 'America/New_York',
  },
  '20172': {
    zip: '20172',
    city: 'Herndon',
    state: 'VA',
    latitude: '38.969316',
    longitude: '-77.383247',
    timeZoneId: 'America/New_York',
  },
  '20175': {
    zip: '20175',
    city: 'Leesburg',
    state: 'VA',
    latitude: '39.083838',
    longitude: '-77.605714',
    timeZoneId: 'America/New_York',
  },
  '20176': {
    zip: '20176',
    city: 'Leesburg',
    state: 'VA',
    latitude: '39.201977',
    longitude: '-77.535473',
    timeZoneId: 'America/New_York',
  },
  '20177': {
    zip: '20177',
    city: 'Leesburg',
    state: 'VA',
    latitude: '39.106936',
    longitude: '-77.565274',
    timeZoneId: 'America/New_York',
  },
  '20178': {
    zip: '20178',
    city: 'Leesburg',
    state: 'VA',
    latitude: '39.118779',
    longitude: '-77.566448',
    timeZoneId: 'America/New_York',
  },
  '20180': {
    zip: '20180',
    city: 'Lovettsville',
    state: 'VA',
    latitude: '39.275423',
    longitude: '-77.637821',
    timeZoneId: 'America/New_York',
  },
  '20181': {
    zip: '20181',
    city: 'Nokesville',
    state: 'VA',
    latitude: '38.666955',
    longitude: '-77.536389',
    timeZoneId: 'America/New_York',
  },
  '20182': {
    zip: '20182',
    city: 'Nokesville',
    state: 'VA',
    latitude: '38.700206',
    longitude: '-77.579071',
    timeZoneId: 'America/New_York',
  },
  '20184': {
    zip: '20184',
    city: 'Upperville',
    state: 'VA',
    latitude: '38.973017',
    longitude: '-77.856287',
    timeZoneId: 'America/New_York',
  },
  '20185': {
    zip: '20185',
    city: 'Upperville',
    state: 'VA',
    latitude: '38.994497',
    longitude: '-77.881808',
    timeZoneId: 'America/New_York',
  },
  '20186': {
    zip: '20186',
    city: 'Warrenton',
    state: 'VA',
    latitude: '38.694065',
    longitude: '-77.852222',
    timeZoneId: 'America/New_York',
  },
  '20187': {
    zip: '20187',
    city: 'Warrenton',
    state: 'VA',
    latitude: '38.722482',
    longitude: '-77.719815',
    timeZoneId: 'America/New_York',
  },
  '20188': {
    zip: '20188',
    city: 'Warrenton',
    state: 'VA',
    latitude: '38.705891',
    longitude: '-77.839374',
    timeZoneId: 'America/New_York',
  },
  '20189': {
    zip: '20189',
    city: 'Dulles',
    state: 'VA',
    latitude: '39.010055',
    longitude: '-77.441171',
    timeZoneId: 'America/New_York',
  },
  '20190': {
    zip: '20190',
    city: 'Reston',
    state: 'VA',
    latitude: '38.958168',
    longitude: '-77.341846',
    timeZoneId: 'America/New_York',
  },
  '20191': {
    zip: '20191',
    city: 'Reston',
    state: 'VA',
    latitude: '38.941053',
    longitude: '-77.346029',
    timeZoneId: 'America/New_York',
  },
  '20192': {
    zip: '20192',
    city: 'Herndon',
    state: 'VA',
    latitude: '38.946349',
    longitude: '-77.363143',
    timeZoneId: 'America/New_York',
  },
  '20194': {
    zip: '20194',
    city: 'Reston',
    state: 'VA',
    latitude: '38.980241',
    longitude: '-77.339946',
    timeZoneId: 'America/New_York',
  },
  '20195': {
    zip: '20195',
    city: 'Reston',
    state: 'VA',
    latitude: '38.948887',
    longitude: '-77.326781',
    timeZoneId: 'America/New_York',
  },
  '20196': {
    zip: '20196',
    city: 'Reston',
    state: 'VA',
    latitude: '38.926126',
    longitude: '-77.396695',
    timeZoneId: 'America/New_York',
  },
  '20197': {
    zip: '20197',
    city: 'Waterford',
    state: 'VA',
    latitude: '39.192941',
    longitude: '-77.633945',
    timeZoneId: 'America/New_York',
  },
  '20198': {
    zip: '20198',
    city: 'The Plains',
    state: 'VA',
    latitude: '38.863166',
    longitude: '-77.772695',
    timeZoneId: 'America/New_York',
  },
  '20201': {
    zip: '20201',
    city: 'Washington',
    state: 'DC',
    latitude: '38.887354',
    longitude: '-77.017361',
    timeZoneId: 'America/New_York',
  },
  '20202': {
    zip: '20202',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896694',
    longitude: '-77.02806',
    timeZoneId: 'America/New_York',
  },
  '20203': {
    zip: '20203',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904541',
    longitude: '-77.045041',
    timeZoneId: 'America/New_York',
  },
  '20204': {
    zip: '20204',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885381',
    longitude: '-77.01744',
    timeZoneId: 'America/New_York',
  },
  '20206': {
    zip: '20206',
    city: 'Washington',
    state: 'DC',
    latitude: '38.9176',
    longitude: '-76.955',
    timeZoneId: 'America/New_York',
  },
  '20207': {
    zip: '20207',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901718',
    longitude: '-76.983568',
    timeZoneId: 'America/New_York',
  },
  '20208': {
    zip: '20208',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897725',
    longitude: '-77.011119',
    timeZoneId: 'America/New_York',
  },
  '20210': {
    zip: '20210',
    city: 'Washington',
    state: 'DC',
    latitude: '38.889938',
    longitude: '-77.012346',
    timeZoneId: 'America/New_York',
  },
  '20211': {
    zip: '20211',
    city: 'Washington',
    state: 'DC',
    latitude: '38.902413',
    longitude: '-77.007921',
    timeZoneId: 'America/New_York',
  },
  '20212': {
    zip: '20212',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895371',
    longitude: '-77.007528',
    timeZoneId: 'America/New_York',
  },
  '20213': {
    zip: '20213',
    city: 'Washington',
    state: 'DC',
    latitude: '38.890866',
    longitude: '-77.013467',
    timeZoneId: 'America/New_York',
  },
  '20214': {
    zip: '20214',
    city: 'Washington',
    state: 'DC',
    latitude: '38.917561',
    longitude: '-76.993932',
    timeZoneId: 'America/New_York',
  },
  '20215': {
    zip: '20215',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894207',
    longitude: '-77.012508',
    timeZoneId: 'America/New_York',
  },
  '20216': {
    zip: '20216',
    city: 'Washington',
    state: 'DC',
    latitude: '38.891861',
    longitude: '-77.012777',
    timeZoneId: 'America/New_York',
  },
  '20217': {
    zip: '20217',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894934',
    longitude: '-77.011177',
    timeZoneId: 'America/New_York',
  },
  '20218': {
    zip: '20218',
    city: 'Washington',
    state: 'DC',
    latitude: '38.891183',
    longitude: '-76.942515',
    timeZoneId: 'America/New_York',
  },
  '20219': {
    zip: '20219',
    city: 'Washington',
    state: 'DC',
    latitude: '38.882413',
    longitude: '-77.016213',
    timeZoneId: 'America/New_York',
  },
  '20220': {
    zip: '20220',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899749',
    longitude: '-77.031261',
    timeZoneId: 'America/New_York',
  },
  '20221': {
    zip: '20221',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894103',
    longitude: '-77.028884',
    timeZoneId: 'America/New_York',
  },
  '20222': {
    zip: '20222',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898336',
    longitude: '-77.032305',
    timeZoneId: 'America/New_York',
  },
  '20223': {
    zip: '20223',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894341',
    longitude: '-77.036991',
    timeZoneId: 'America/New_York',
  },
  '20224': {
    zip: '20224',
    city: 'Washington',
    state: 'DC',
    latitude: '38.890942',
    longitude: '-77.027831',
    timeZoneId: 'America/New_York',
  },
  '20226': {
    zip: '20226',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897787',
    longitude: '-77.031979',
    timeZoneId: 'America/New_York',
  },
  '20227': {
    zip: '20227',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894569',
    longitude: '-77.033661',
    timeZoneId: 'America/New_York',
  },
  '20228': {
    zip: '20228',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88771',
    longitude: '-77.03142',
    timeZoneId: 'America/New_York',
  },
  '20229': {
    zip: '20229',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892772',
    longitude: '-77.032993',
    timeZoneId: 'America/New_York',
  },
  '20230': {
    zip: '20230',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899945',
    longitude: '-77.035749',
    timeZoneId: 'America/New_York',
  },
  '20232': {
    zip: '20232',
    city: 'Washington',
    state: 'DC',
    latitude: '38.919252',
    longitude: '-77.036045',
    timeZoneId: 'America/New_York',
  },
  '20233': {
    zip: '20233',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20235': {
    zip: '20235',
    city: 'Washington',
    state: 'DC',
    latitude: '38.911897',
    longitude: '-77.045863',
    timeZoneId: 'America/New_York',
  },
  '20237': {
    zip: '20237',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895381',
    longitude: '-77.036342',
    timeZoneId: 'America/New_York',
  },
  '20238': {
    zip: '20238',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904151',
    longitude: '-77.045347',
    timeZoneId: 'America/New_York',
  },
  '20239': {
    zip: '20239',
    city: 'Washington',
    state: 'DC',
    latitude: '38.879588',
    longitude: '-77.032458',
    timeZoneId: 'America/New_York',
  },
  '20240': {
    zip: '20240',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89468',
    longitude: '-77.041752',
    timeZoneId: 'America/New_York',
  },
  '20241': {
    zip: '20241',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894725',
    longitude: '-77.052712',
    timeZoneId: 'America/New_York',
  },
  '20242': {
    zip: '20242',
    city: 'Washington',
    state: 'DC',
    latitude: '38.960013',
    longitude: '-77.043563',
    timeZoneId: 'America/New_York',
  },
  '20244': {
    zip: '20244',
    city: 'Washington',
    state: 'DC',
    latitude: '38.914896',
    longitude: '-76.996272',
    timeZoneId: 'America/New_York',
  },
  '20245': {
    zip: '20245',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89248',
    longitude: '-77.045778',
    timeZoneId: 'America/New_York',
  },
  '20250': {
    zip: '20250',
    city: 'Washington',
    state: 'DC',
    latitude: '38.884663',
    longitude: '-77.030371',
    timeZoneId: 'America/New_York',
  },
  '20251': {
    zip: '20251',
    city: 'Washington',
    state: 'DC',
    latitude: '38.886154',
    longitude: '-77.021863',
    timeZoneId: 'America/New_York',
  },
  '20252': {
    zip: '20252',
    city: 'Washington',
    state: 'DC',
    latitude: '38.8774',
    longitude: '-77.0179',
    timeZoneId: 'America/New_York',
  },
  '20254': {
    zip: '20254',
    city: 'Washington',
    state: 'DC',
    latitude: '38.884967',
    longitude: '-77.020848',
    timeZoneId: 'America/New_York',
  },
  '20260': {
    zip: '20260',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88034',
    longitude: '-77.015833',
    timeZoneId: 'America/New_York',
  },
  '20261': {
    zip: '20261',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885689',
    longitude: '-77.019097',
    timeZoneId: 'America/New_York',
  },
  '20262': {
    zip: '20262',
    city: 'Washington',
    state: 'DC',
    latitude: '38.918995',
    longitude: '-76.99492',
    timeZoneId: 'America/New_York',
  },
  '20265': {
    zip: '20265',
    city: 'Washington',
    state: 'DC',
    latitude: '38.884326',
    longitude: '-77.0202',
    timeZoneId: 'America/New_York',
  },
  '20266': {
    zip: '20266',
    city: 'Washington',
    state: 'DC',
    latitude: '38.882442',
    longitude: '-77.020708',
    timeZoneId: 'America/New_York',
  },
  '20268': {
    zip: '20268',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901073',
    longitude: '-77.028825',
    timeZoneId: 'America/New_York',
  },
  '20270': {
    zip: '20270',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900588',
    longitude: '-77.022082',
    timeZoneId: 'America/New_York',
  },
  '20277': {
    zip: '20277',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88254',
    longitude: '-77.017002',
    timeZoneId: 'America/New_York',
  },
  '20289': {
    zip: '20289',
    city: 'Washington',
    state: 'DC',
    latitude: '38.883057',
    longitude: '-77.01885',
    timeZoneId: 'America/New_York',
  },
  '20299': {
    zip: '20299',
    city: 'Washington',
    state: 'DC',
    latitude: '38.918368',
    longitude: '-76.990783',
    timeZoneId: 'America/New_York',
  },
  '20301': {
    zip: '20301',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89109',
    longitude: '-77.020936',
    timeZoneId: 'America/New_York',
  },
  '20303': {
    zip: '20303',
    city: 'Washington',
    state: 'DC',
    latitude: '38.920577',
    longitude: '-76.9945',
    timeZoneId: 'America/New_York',
  },
  '20306': {
    zip: '20306',
    city: 'Washington',
    state: 'DC',
    latitude: '38.974169',
    longitude: '-77.033759',
    timeZoneId: 'America/New_York',
  },
  '20310': {
    zip: '20310',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20314': {
    zip: '20314',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895872',
    longitude: '-77.009157',
    timeZoneId: 'America/New_York',
  },
  '20317': {
    zip: '20317',
    city: 'Washington',
    state: 'DC',
    latitude: '38.942652',
    longitude: '-77.012337',
    timeZoneId: 'America/New_York',
  },
  '20318': {
    zip: '20318',
    city: 'Washington',
    state: 'DC',
    latitude: '38.864971',
    longitude: '-77.012219',
    timeZoneId: 'America/New_York',
  },
  '20319': {
    zip: '20319',
    city: 'Washington',
    state: 'DC',
    latitude: '38.87783',
    longitude: '-77.01802',
    timeZoneId: 'America/New_York',
  },
  '20330': {
    zip: '20330',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20340': {
    zip: '20340',
    city: 'Washington',
    state: 'DC',
    latitude: '38.849617',
    longitude: '-77.016636',
    timeZoneId: 'America/New_York',
  },
  '20350': {
    zip: '20350',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20355': {
    zip: '20355',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896255',
    longitude: '-77.03427',
    timeZoneId: 'America/New_York',
  },
  '20370': {
    zip: '20370',
    city: 'Washington',
    state: 'DC',
    latitude: '38.878761',
    longitude: '-76.994199',
    timeZoneId: 'America/New_York',
  },
  '20372': {
    zip: '20372',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900744',
    longitude: '-77.05016',
    timeZoneId: 'America/New_York',
  },
  '20373': {
    zip: '20373',
    city: 'Naval Anacost Annex',
    state: 'DC',
    latitude: '38.90598',
    longitude: '-76.98654',
    timeZoneId: 'America/New_York',
  },
  '20374': {
    zip: '20374',
    city: 'Washington Navy Yard',
    state: 'DC',
    latitude: '38.828906',
    longitude: '-77.015495',
    timeZoneId: 'America/New_York',
  },
  '20375': {
    zip: '20375',
    city: 'Washington',
    state: 'DC',
    latitude: '38.941368',
    longitude: '-77.089841',
    timeZoneId: 'America/New_York',
  },
  '20376': {
    zip: '20376',
    city: 'Washington Navy Yard',
    state: 'DC',
    latitude: '38.916809',
    longitude: '-76.993655',
    timeZoneId: 'America/New_York',
  },
  '20380': {
    zip: '20380',
    city: 'Washington',
    state: 'DC',
    latitude: '38.876916',
    longitude: '-76.99408',
    timeZoneId: 'America/New_York',
  },
  '20388': {
    zip: '20388',
    city: 'Washington Navy Yard',
    state: 'DC',
    latitude: '38.878851',
    longitude: '-76.991728',
    timeZoneId: 'America/New_York',
  },
  '20389': {
    zip: '20389',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20390': {
    zip: '20390',
    city: 'Washington',
    state: 'DC',
    latitude: '38.884615',
    longitude: '-76.99349',
    timeZoneId: 'America/New_York',
  },
  '20391': {
    zip: '20391',
    city: 'Washington Navy Yard',
    state: 'DC',
    latitude: '38.877045',
    longitude: '-76.994981',
    timeZoneId: 'America/New_York',
  },
  '20392': {
    zip: '20392',
    city: 'Washington',
    state: 'DC',
    latitude: '38.933497',
    longitude: '-77.060031',
    timeZoneId: 'America/New_York',
  },
  '20393': {
    zip: '20393',
    city: 'Washington',
    state: 'DC',
    latitude: '38.943574',
    longitude: '-77.082429',
    timeZoneId: 'America/New_York',
  },
  '20394': {
    zip: '20394',
    city: 'Washington',
    state: 'DC',
    latitude: '38.941666',
    longitude: '-77.087409',
    timeZoneId: 'America/New_York',
  },
  '20395': {
    zip: '20395',
    city: 'Washington',
    state: 'DC',
    latitude: '38.859014',
    longitude: '-76.975556',
    timeZoneId: 'America/New_York',
  },
  '20398': {
    zip: '20398',
    city: 'Washington Navy Yard',
    state: 'DC',
    latitude: '38.876127',
    longitude: '-76.994243',
    timeZoneId: 'America/New_York',
  },
  '20401': {
    zip: '20401',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897816',
    longitude: '-77.008997',
    timeZoneId: 'America/New_York',
  },
  '20402': {
    zip: '20402',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898247',
    longitude: '-77.010957',
    timeZoneId: 'America/New_York',
  },
  '20403': {
    zip: '20403',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88839',
    longitude: '-77.015989',
    timeZoneId: 'America/New_York',
  },
  '20404': {
    zip: '20404',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897713',
    longitude: '-77.008867',
    timeZoneId: 'America/New_York',
  },
  '20405': {
    zip: '20405',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901076',
    longitude: '-77.022171',
    timeZoneId: 'America/New_York',
  },
  '20406': {
    zip: '20406',
    city: 'Washington',
    state: 'DC',
    latitude: '38.914147',
    longitude: '-77.043449',
    timeZoneId: 'America/New_York',
  },
  '20407': {
    zip: '20407',
    city: 'Washington',
    state: 'DC',
    latitude: '38.884305',
    longitude: '-77.02313',
    timeZoneId: 'America/New_York',
  },
  '20408': {
    zip: '20408',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89373',
    longitude: '-77.021451',
    timeZoneId: 'America/New_York',
  },
  '20410': {
    zip: '20410',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885547',
    longitude: '-77.021382',
    timeZoneId: 'America/New_York',
  },
  '20411': {
    zip: '20411',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885195',
    longitude: '-77.023632',
    timeZoneId: 'America/New_York',
  },
  '20412': {
    zip: '20412',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892284',
    longitude: '-77.020518',
    timeZoneId: 'America/New_York',
  },
  '20413': {
    zip: '20413',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894978',
    longitude: '-77.021775',
    timeZoneId: 'America/New_York',
  },
  '20414': {
    zip: '20414',
    city: 'Washington',
    state: 'DC',
    latitude: '38.881612',
    longitude: '-77.024982',
    timeZoneId: 'America/New_York',
  },
  '20415': {
    zip: '20415',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896458',
    longitude: '-77.040977',
    timeZoneId: 'America/New_York',
  },
  '20416': {
    zip: '20416',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88262',
    longitude: '-77.017393',
    timeZoneId: 'America/New_York',
  },
  '20417': {
    zip: '20417',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885642',
    longitude: '-77.021833',
    timeZoneId: 'America/New_York',
  },
  '20418': {
    zip: '20418',
    city: 'Washington',
    state: 'DC',
    latitude: '38.889776',
    longitude: '-77.044451',
    timeZoneId: 'America/New_York',
  },
  '20419': {
    zip: '20419',
    city: 'Washington',
    state: 'DC',
    latitude: '38.902312',
    longitude: '-77.031757',
    timeZoneId: 'America/New_York',
  },
  '20420': {
    zip: '20420',
    city: 'Washington',
    state: 'DC',
    latitude: '38.902553',
    longitude: '-77.028688',
    timeZoneId: 'America/New_York',
  },
  '20421': {
    zip: '20421',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906309',
    longitude: '-77.031251',
    timeZoneId: 'America/New_York',
  },
  '20422': {
    zip: '20422',
    city: 'Washington',
    state: 'DC',
    latitude: '38.93185',
    longitude: '-77.031546',
    timeZoneId: 'America/New_York',
  },
  '20423': {
    zip: '20423',
    city: 'Washington',
    state: 'DC',
    latitude: '38.893212',
    longitude: '-77.027764',
    timeZoneId: 'America/New_York',
  },
  '20424': {
    zip: '20424',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895393',
    longitude: '-77.031186',
    timeZoneId: 'America/New_York',
  },
  '20425': {
    zip: '20425',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897093',
    longitude: '-77.024167',
    timeZoneId: 'America/New_York',
  },
  '20426': {
    zip: '20426',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901696',
    longitude: '-77.005988',
    timeZoneId: 'America/New_York',
  },
  '20427': {
    zip: '20427',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89904',
    longitude: '-77.037241',
    timeZoneId: 'America/New_York',
  },
  '20428': {
    zip: '20428',
    city: 'Washington',
    state: 'DC',
    latitude: '38.913441',
    longitude: '-77.043978',
    timeZoneId: 'America/New_York',
  },
  '20429': {
    zip: '20429',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89145',
    longitude: '-77.04079',
    timeZoneId: 'America/New_York',
  },
  '20431': {
    zip: '20431',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895708',
    longitude: '-77.044874',
    timeZoneId: 'America/New_York',
  },
  '20433': {
    zip: '20433',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906462',
    longitude: '-77.044928',
    timeZoneId: 'America/New_York',
  },
  '20434': {
    zip: '20434',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892939',
    longitude: '-77.038927',
    timeZoneId: 'America/New_York',
  },
  '20435': {
    zip: '20435',
    city: 'Washington',
    state: 'DC',
    latitude: '38.916398',
    longitude: '-77.07787',
    timeZoneId: 'America/New_York',
  },
  '20436': {
    zip: '20436',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897567',
    longitude: '-77.024098',
    timeZoneId: 'America/New_York',
  },
  '20437': {
    zip: '20437',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901872',
    longitude: '-77.04895',
    timeZoneId: 'America/New_York',
  },
  '20439': {
    zip: '20439',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904771',
    longitude: '-77.030231',
    timeZoneId: 'America/New_York',
  },
  '20440': {
    zip: '20440',
    city: 'Washington',
    state: 'DC',
    latitude: '38.914558',
    longitude: '-77.044465',
    timeZoneId: 'America/New_York',
  },
  '20441': {
    zip: '20441',
    city: 'Washington',
    state: 'DC',
    latitude: '38.92326',
    longitude: '-77.0348',
    timeZoneId: 'America/New_York',
  },
  '20442': {
    zip: '20442',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892812',
    longitude: '-77.014512',
    timeZoneId: 'America/New_York',
  },
  '20444': {
    zip: '20444',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896916',
    longitude: '-77.008805',
    timeZoneId: 'America/New_York',
  },
  '20447': {
    zip: '20447',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885057',
    longitude: '-77.027332',
    timeZoneId: 'America/New_York',
  },
  '20451': {
    zip: '20451',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894433',
    longitude: '-77.046676',
    timeZoneId: 'America/New_York',
  },
  '20453': {
    zip: '20453',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '20456': {
    zip: '20456',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898454',
    longitude: '-77.044445',
    timeZoneId: 'America/New_York',
  },
  '20460': {
    zip: '20460',
    city: 'Washington',
    state: 'DC',
    latitude: '38.878225',
    longitude: '-77.020008',
    timeZoneId: 'America/New_York',
  },
  '20463': {
    zip: '20463',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898581',
    longitude: '-77.024538',
    timeZoneId: 'America/New_York',
  },
  '20468': {
    zip: '20468',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894581',
    longitude: '-77.036348',
    timeZoneId: 'America/New_York',
  },
  '20469': {
    zip: '20469',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895209',
    longitude: '-77.041986',
    timeZoneId: 'America/New_York',
  },
  '20470': {
    zip: '20470',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892775',
    longitude: '-77.036853',
    timeZoneId: 'America/New_York',
  },
  '20472': {
    zip: '20472',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885928',
    longitude: '-77.019442',
    timeZoneId: 'America/New_York',
  },
  '20500': {
    zip: '20500',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898187',
    longitude: '-77.033118',
    timeZoneId: 'America/New_York',
  },
  '20501': {
    zip: '20501',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900876',
    longitude: '-77.029709',
    timeZoneId: 'America/New_York',
  },
  '20502': {
    zip: '20502',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896958',
    longitude: '-77.034624',
    timeZoneId: 'America/New_York',
  },
  '20503': {
    zip: '20503',
    city: 'Washington',
    state: 'DC',
    latitude: '38.902115',
    longitude: '-77.036165',
    timeZoneId: 'America/New_York',
  },
  '20504': {
    zip: '20504',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897725',
    longitude: '-77.042875',
    timeZoneId: 'America/New_York',
  },
  '20505': {
    zip: '20505',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896385',
    longitude: '-77.041564',
    timeZoneId: 'America/New_York',
  },
  '20506': {
    zip: '20506',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899668',
    longitude: '-77.039336',
    timeZoneId: 'America/New_York',
  },
  '20507': {
    zip: '20507',
    city: 'Washington',
    state: 'DC',
    latitude: '38.903711',
    longitude: '-77.042144',
    timeZoneId: 'America/New_York',
  },
  '20508': {
    zip: '20508',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899287',
    longitude: '-77.040342',
    timeZoneId: 'America/New_York',
  },
  '20509': {
    zip: '20509',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892273',
    longitude: '-77.037823',
    timeZoneId: 'America/New_York',
  },
  '20510': {
    zip: '20510',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88919',
    longitude: '-77.008664',
    timeZoneId: 'America/New_York',
  },
  '20511': {
    zip: '20511',
    city: 'Washington',
    state: 'DC',
    latitude: '38.8407',
    longitude: '-77.0167',
    timeZoneId: 'America/New_York',
  },
  '20515': {
    zip: '20515',
    city: 'Washington',
    state: 'DC',
    latitude: '38.891068',
    longitude: '-77.011322',
    timeZoneId: 'America/New_York',
  },
  '20520': {
    zip: '20520',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895588',
    longitude: '-77.047964',
    timeZoneId: 'America/New_York',
  },
  '20521': {
    zip: '20521',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899591',
    longitude: '-77.051114',
    timeZoneId: 'America/New_York',
  },
  '20522': {
    zip: '20522',
    city: 'Washington',
    state: 'DC',
    latitude: '38.893759',
    longitude: '-77.050641',
    timeZoneId: 'America/New_York',
  },
  '20523': {
    zip: '20523',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89671',
    longitude: '-77.04701',
    timeZoneId: 'America/New_York',
  },
  '20524': {
    zip: '20524',
    city: 'Washington',
    state: 'DC',
    latitude: '38.902472',
    longitude: '-77.031794',
    timeZoneId: 'America/New_York',
  },
  '20525': {
    zip: '20525',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900624',
    longitude: '-77.030204',
    timeZoneId: 'America/New_York',
  },
  '20526': {
    zip: '20526',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900668',
    longitude: '-77.045582',
    timeZoneId: 'America/New_York',
  },
  '20527': {
    zip: '20527',
    city: 'Washington',
    state: 'DC',
    latitude: '38.90191',
    longitude: '-77.025942',
    timeZoneId: 'America/New_York',
  },
  '20528': {
    zip: '20528',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894924',
    longitude: '-77.038234',
    timeZoneId: 'America/New_York',
  },
  '20529': {
    zip: '20529',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895138',
    longitude: '-77.036621',
    timeZoneId: 'America/New_York',
  },
  '20530': {
    zip: '20530',
    city: 'Washington',
    state: 'DC',
    latitude: '38.891194',
    longitude: '-77.022162',
    timeZoneId: 'America/New_York',
  },
  '20531': {
    zip: '20531',
    city: 'Washington',
    state: 'DC',
    latitude: '38.895714',
    longitude: '-77.019633',
    timeZoneId: 'America/New_York',
  },
  '20533': {
    zip: '20533',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904566',
    longitude: '-77.031907',
    timeZoneId: 'America/New_York',
  },
  '20534': {
    zip: '20534',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892271',
    longitude: '-77.011002',
    timeZoneId: 'America/New_York',
  },
  '20535': {
    zip: '20535',
    city: 'Washington',
    state: 'DC',
    latitude: '38.892434',
    longitude: '-77.022821',
    timeZoneId: 'America/New_York',
  },
  '20536': {
    zip: '20536',
    city: 'Washington',
    state: 'DC',
    latitude: '38.90101',
    longitude: '-77.015942',
    timeZoneId: 'America/New_York',
  },
  '20537': {
    zip: '20537',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897231',
    longitude: '-77.025361',
    timeZoneId: 'America/New_York',
  },
  '20538': {
    zip: '20538',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901597',
    longitude: '-77.017489',
    timeZoneId: 'America/New_York',
  },
  '20539': {
    zip: '20539',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898392',
    longitude: '-77.028592',
    timeZoneId: 'America/New_York',
  },
  '20540': {
    zip: '20540',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88927',
    longitude: '-77.004711',
    timeZoneId: 'America/New_York',
  },
  '20541': {
    zip: '20541',
    city: 'Washington',
    state: 'DC',
    latitude: '38.888643',
    longitude: '-77.007109',
    timeZoneId: 'America/New_York',
  },
  '20542': {
    zip: '20542',
    city: 'Washington',
    state: 'DC',
    latitude: '38.940478',
    longitude: '-77.029433',
    timeZoneId: 'America/New_York',
  },
  '20543': {
    zip: '20543',
    city: 'Washington',
    state: 'DC',
    latitude: '38.889169',
    longitude: '-77.008796',
    timeZoneId: 'America/New_York',
  },
  '20544': {
    zip: '20544',
    city: 'Washington',
    state: 'DC',
    latitude: '38.906224',
    longitude: '-77.03809',
    timeZoneId: 'America/New_York',
  },
  '20546': {
    zip: '20546',
    city: 'Washington',
    state: 'DC',
    latitude: '38.883971',
    longitude: '-76.989253',
    timeZoneId: 'America/New_York',
  },
  '20547': {
    zip: '20547',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885247',
    longitude: '-77.014601',
    timeZoneId: 'America/New_York',
  },
  '20548': {
    zip: '20548',
    city: 'Washington',
    state: 'DC',
    latitude: '38.900712',
    longitude: '-77.019624',
    timeZoneId: 'America/New_York',
  },
  '20549': {
    zip: '20549',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89182',
    longitude: '-77.019583',
    timeZoneId: 'America/New_York',
  },
  '20551': {
    zip: '20551',
    city: 'Washington',
    state: 'DC',
    latitude: '38.893288',
    longitude: '-77.043093',
    timeZoneId: 'America/New_York',
  },
  '20552': {
    zip: '20552',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899758',
    longitude: '-77.024429',
    timeZoneId: 'America/New_York',
  },
  '20553': {
    zip: '20553',
    city: 'Washington',
    state: 'DC',
    latitude: '38.887704',
    longitude: '-77.024043',
    timeZoneId: 'America/New_York',
  },
  '20554': {
    zip: '20554',
    city: 'Washington',
    state: 'DC',
    latitude: '38.917033',
    longitude: '-77.041158',
    timeZoneId: 'America/New_York',
  },
  '20555': {
    zip: '20555',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896447',
    longitude: '-77.041074',
    timeZoneId: 'America/New_York',
  },
  '20557': {
    zip: '20557',
    city: 'Washington',
    state: 'DC',
    latitude: '38.880368',
    longitude: '-76.990661',
    timeZoneId: 'America/New_York',
  },
  '20559': {
    zip: '20559',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88795',
    longitude: '-77.005785',
    timeZoneId: 'America/New_York',
  },
  '20560': {
    zip: '20560',
    city: 'Washington',
    state: 'DC',
    latitude: '38.885489',
    longitude: '-77.026296',
    timeZoneId: 'America/New_York',
  },
  '20565': {
    zip: '20565',
    city: 'Washington',
    state: 'DC',
    latitude: '38.904899',
    longitude: '-76.984842',
    timeZoneId: 'America/New_York',
  },
  '20566': {
    zip: '20566',
    city: 'Washington',
    state: 'DC',
    latitude: '38.903226',
    longitude: '-77.049281',
    timeZoneId: 'America/New_York',
  },
  '20570': {
    zip: '20570',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897985',
    longitude: '-77.04153',
    timeZoneId: 'America/New_York',
  },
  '20571': {
    zip: '20571',
    city: 'Washington',
    state: 'DC',
    latitude: '38.90045',
    longitude: '-77.032812',
    timeZoneId: 'America/New_York',
  },
  '20572': {
    zip: '20572',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905845',
    longitude: '-77.030192',
    timeZoneId: 'America/New_York',
  },
  '20573': {
    zip: '20573',
    city: 'Washington',
    state: 'DC',
    latitude: '38.89938',
    longitude: '-77.008021',
    timeZoneId: 'America/New_York',
  },
  '20575': {
    zip: '20575',
    city: 'Washington',
    state: 'DC',
    latitude: '38.899437',
    longitude: '-77.041181',
    timeZoneId: 'America/New_York',
  },
  '20576': {
    zip: '20576',
    city: 'Washington',
    state: 'DC',
    latitude: '38.898384',
    longitude: '-77.032462',
    timeZoneId: 'America/New_York',
  },
  '20577': {
    zip: '20577',
    city: 'Washington',
    state: 'DC',
    latitude: '38.90436',
    longitude: '-77.035413',
    timeZoneId: 'America/New_York',
  },
  '20578': {
    zip: '20578',
    city: 'Washington',
    state: 'DC',
    latitude: '38.897253',
    longitude: '-77.010546',
    timeZoneId: 'America/New_York',
  },
  '20579': {
    zip: '20579',
    city: 'Washington',
    state: 'DC',
    latitude: '38.901432',
    longitude: '-77.044897',
    timeZoneId: 'America/New_York',
  },
  '20580': {
    zip: '20580',
    city: 'Washington',
    state: 'DC',
    latitude: '38.894121',
    longitude: '-77.024126',
    timeZoneId: 'America/New_York',
  },
  '20581': {
    zip: '20581',
    city: 'Washington',
    state: 'DC',
    latitude: '38.891213',
    longitude: '-77.04491',
    timeZoneId: 'America/New_York',
  },
  '20585': {
    zip: '20585',
    city: 'Washington',
    state: 'DC',
    latitude: '38.886166',
    longitude: '-77.025669',
    timeZoneId: 'America/New_York',
  },
  '20586': {
    zip: '20586',
    city: 'Washington',
    state: 'DC',
    latitude: '38.905041',
    longitude: '-77.047044',
    timeZoneId: 'America/New_York',
  },
  '20588': {
    zip: '20588',
    city: 'DHS',
    state: 'MD',
    latitude: '38.916044',
    longitude: '-77.056499',
    timeZoneId: 'America/New_York',
  },
  '20590': {
    zip: '20590',
    city: 'Washington',
    state: 'DC',
    latitude: '38.878849',
    longitude: '-77.023618',
    timeZoneId: 'America/New_York',
  },
  '20591': {
    zip: '20591',
    city: 'Washington',
    state: 'DC',
    latitude: '38.891171',
    longitude: '-77.022513',
    timeZoneId: 'America/New_York',
  },
  '20593': {
    zip: '20593',
    city: 'Washington',
    state: 'DC',
    latitude: '38.867378',
    longitude: '-77.012246',
    timeZoneId: 'America/New_York',
  },
  '20594': {
    zip: '20594',
    city: 'Washington',
    state: 'DC',
    latitude: '38.88683',
    longitude: '-77.022138',
    timeZoneId: 'America/New_York',
  },
  '20597': {
    zip: '20597',
    city: 'Washington',
    state: 'DC',
    latitude: '38.896622',
    longitude: '-77.034001',
    timeZoneId: 'America/New_York',
  },
  '20598': {
    zip: '20598',
    city: 'DHS',
    state: 'VA',
    latitude: '38.916815',
    longitude: '-77.056474',
    timeZoneId: 'America/New_York',
  },
  '20599': {
    zip: '20599',
    city: 'Washington',
    state: 'DC',
    latitude: '38.893588',
    longitude: '-77.001294',
    timeZoneId: 'America/New_York',
  },
  '20601': {
    zip: '20601',
    city: 'Waldorf',
    state: 'MD',
    latitude: '38.627621',
    longitude: '-76.874973',
    timeZoneId: 'America/New_York',
  },
  '20602': {
    zip: '20602',
    city: 'Waldorf',
    state: 'MD',
    latitude: '38.603056',
    longitude: '-76.938889',
    timeZoneId: 'America/New_York',
  },
  '20603': {
    zip: '20603',
    city: 'Waldorf',
    state: 'MD',
    latitude: '38.628435',
    longitude: '-76.968736',
    timeZoneId: 'America/New_York',
  },
  '20604': {
    zip: '20604',
    city: 'Waldorf',
    state: 'MD',
    latitude: '38.617973',
    longitude: '-76.892084',
    timeZoneId: 'America/New_York',
  },
  '20606': {
    zip: '20606',
    city: 'Abell',
    state: 'MD',
    latitude: '38.250381',
    longitude: '-76.73881',
    timeZoneId: 'America/New_York',
  },
  '20607': {
    zip: '20607',
    city: 'Accokeek',
    state: 'MD',
    latitude: '38.671615',
    longitude: '-77.02291',
    timeZoneId: 'America/New_York',
  },
  '20608': {
    zip: '20608',
    city: 'Aquasco',
    state: 'MD',
    latitude: '38.582892',
    longitude: '-76.715193',
    timeZoneId: 'America/New_York',
  },
  '20609': {
    zip: '20609',
    city: 'Avenue',
    state: 'MD',
    latitude: '38.278304',
    longitude: '-76.723639',
    timeZoneId: 'America/New_York',
  },
  '20610': {
    zip: '20610',
    city: 'Barstow',
    state: 'MD',
    latitude: '38.533156',
    longitude: '-76.616803',
    timeZoneId: 'America/New_York',
  },
  '20611': {
    zip: '20611',
    city: 'Bel Alton',
    state: 'MD',
    latitude: '38.477162',
    longitude: '-76.981786',
    timeZoneId: 'America/New_York',
  },
  '20612': {
    zip: '20612',
    city: 'Benedict',
    state: 'MD',
    latitude: '38.508971',
    longitude: '-76.679356',
    timeZoneId: 'America/New_York',
  },
  '20613': {
    zip: '20613',
    city: 'Brandywine',
    state: 'MD',
    latitude: '38.678248',
    longitude: '-76.816106',
    timeZoneId: 'America/New_York',
  },
  '20615': {
    zip: '20615',
    city: 'Broomes Island',
    state: 'MD',
    latitude: '38.417827',
    longitude: '-76.545282',
    timeZoneId: 'America/New_York',
  },
  '20616': {
    zip: '20616',
    city: 'Bryans Road',
    state: 'MD',
    latitude: '38.648082',
    longitude: '-77.084535',
    timeZoneId: 'America/New_York',
  },
  '20617': {
    zip: '20617',
    city: 'Bryantown',
    state: 'MD',
    latitude: '38.542375',
    longitude: '-76.854109',
    timeZoneId: 'America/New_York',
  },
  '20618': {
    zip: '20618',
    city: 'Bushwood',
    state: 'MD',
    latitude: '38.278595',
    longitude: '-76.795324',
    timeZoneId: 'America/New_York',
  },
  '20619': {
    zip: '20619',
    city: 'California',
    state: 'MD',
    latitude: '38.30088',
    longitude: '-76.520266',
    timeZoneId: 'America/New_York',
  },
  '20620': {
    zip: '20620',
    city: 'Callaway',
    state: 'MD',
    latitude: '38.235472',
    longitude: '-76.52211',
    timeZoneId: 'America/New_York',
  },
  '20621': {
    zip: '20621',
    city: 'Chaptico',
    state: 'MD',
    latitude: '38.33484',
    longitude: '-76.8204',
    timeZoneId: 'America/New_York',
  },
  '20622': {
    zip: '20622',
    city: 'Charlotte Hall',
    state: 'MD',
    latitude: '38.497286',
    longitude: '-76.765851',
    timeZoneId: 'America/New_York',
  },
  '20623': {
    zip: '20623',
    city: 'Cheltenham',
    state: 'MD',
    latitude: '38.746143',
    longitude: '-76.830091',
    timeZoneId: 'America/New_York',
  },
  '20624': {
    zip: '20624',
    city: 'Clements',
    state: 'MD',
    latitude: '38.34198',
    longitude: '-76.733728',
    timeZoneId: 'America/New_York',
  },
  '20625': {
    zip: '20625',
    city: 'Cobb Island',
    state: 'MD',
    latitude: '38.260467',
    longitude: '-76.843591',
    timeZoneId: 'America/New_York',
  },
  '20626': {
    zip: '20626',
    city: 'Coltons Point',
    state: 'MD',
    latitude: '38.236396',
    longitude: '-76.76756',
    timeZoneId: 'America/New_York',
  },
  '20627': {
    zip: '20627',
    city: 'Compton',
    state: 'MD',
    latitude: '38.305008',
    longitude: '-76.632014',
    timeZoneId: 'America/New_York',
  },
  '20628': {
    zip: '20628',
    city: 'Dameron',
    state: 'MD',
    latitude: '38.140812',
    longitude: '-76.328067',
    timeZoneId: 'America/New_York',
  },
  '20629': {
    zip: '20629',
    city: 'Dowell',
    state: 'MD',
    latitude: '38.340604',
    longitude: '-76.453574',
    timeZoneId: 'America/New_York',
  },
  '20630': {
    zip: '20630',
    city: 'Drayden',
    state: 'MD',
    latitude: '38.175055',
    longitude: '-76.471555',
    timeZoneId: 'America/New_York',
  },
  '20632': {
    zip: '20632',
    city: 'Faulkner',
    state: 'MD',
    latitude: '38.440913',
    longitude: '-76.961253',
    timeZoneId: 'America/New_York',
  },
  '20634': {
    zip: '20634',
    city: 'Great Mills',
    state: 'MD',
    latitude: '38.257775',
    longitude: '-76.494134',
    timeZoneId: 'America/New_York',
  },
  '20635': {
    zip: '20635',
    city: 'Helen',
    state: 'MD',
    latitude: '38.331965',
    longitude: '-76.785037',
    timeZoneId: 'America/New_York',
  },
  '20636': {
    zip: '20636',
    city: 'Hollywood',
    state: 'MD',
    latitude: '38.357259',
    longitude: '-76.573388',
    timeZoneId: 'America/New_York',
  },
  '20637': {
    zip: '20637',
    city: 'Hughesville',
    state: 'MD',
    latitude: '38.522827',
    longitude: '-76.771844',
    timeZoneId: 'America/New_York',
  },
  '20639': {
    zip: '20639',
    city: 'Huntingtown',
    state: 'MD',
    latitude: '38.60623',
    longitude: '-76.599274',
    timeZoneId: 'America/New_York',
  },
  '20640': {
    zip: '20640',
    city: 'Indian Head',
    state: 'MD',
    latitude: '38.57208',
    longitude: '-77.15292',
    timeZoneId: 'America/New_York',
  },
  '20643': {
    zip: '20643',
    city: 'Ironsides',
    state: 'MD',
    latitude: '38.491426',
    longitude: '-77.158284',
    timeZoneId: 'America/New_York',
  },
  '20645': {
    zip: '20645',
    city: 'Issue',
    state: 'MD',
    latitude: '38.305493',
    longitude: '-76.920407',
    timeZoneId: 'America/New_York',
  },
  '20646': {
    zip: '20646',
    city: 'La Plata',
    state: 'MD',
    latitude: '38.524678',
    longitude: '-76.97363',
    timeZoneId: 'America/New_York',
  },
  '20650': {
    zip: '20650',
    city: 'Leonardtown',
    state: 'MD',
    latitude: '38.29734',
    longitude: '-76.64862',
    timeZoneId: 'America/New_York',
  },
  '20653': {
    zip: '20653',
    city: 'Lexington Park',
    state: 'MD',
    latitude: '38.273193',
    longitude: '-76.464098',
    timeZoneId: 'America/New_York',
  },
  '20656': {
    zip: '20656',
    city: 'Loveville',
    state: 'MD',
    latitude: '38.3513',
    longitude: '-76.680493',
    timeZoneId: 'America/New_York',
  },
  '20657': {
    zip: '20657',
    city: 'Lusby',
    state: 'MD',
    latitude: '38.37529',
    longitude: '-76.337326',
    timeZoneId: 'America/New_York',
  },
  '20658': {
    zip: '20658',
    city: 'Marbury',
    state: 'MD',
    latitude: '38.56236',
    longitude: '-77.1639',
    timeZoneId: 'America/New_York',
  },
  '20659': {
    zip: '20659',
    city: 'Mechanicsville',
    state: 'MD',
    latitude: '38.438361',
    longitude: '-76.723614',
    timeZoneId: 'America/New_York',
  },
  '20660': {
    zip: '20660',
    city: 'Morganza',
    state: 'MD',
    latitude: '38.376183',
    longitude: '-76.695132',
    timeZoneId: 'America/New_York',
  },
  '20661': {
    zip: '20661',
    city: 'Mount Victoria',
    state: 'MD',
    latitude: '38.359153',
    longitude: '-76.896668',
    timeZoneId: 'America/New_York',
  },
  '20662': {
    zip: '20662',
    city: 'Nanjemoy',
    state: 'MD',
    latitude: '38.412003',
    longitude: '-77.18868',
    timeZoneId: 'America/New_York',
  },
  '20664': {
    zip: '20664',
    city: 'Newburg',
    state: 'MD',
    latitude: '38.359304',
    longitude: '-76.945242',
    timeZoneId: 'America/New_York',
  },
  '20667': {
    zip: '20667',
    city: 'Park Hall',
    state: 'MD',
    latitude: '38.21742',
    longitude: '-76.450224',
    timeZoneId: 'America/New_York',
  },
  '20670': {
    zip: '20670',
    city: 'Patuxent River',
    state: 'MD',
    latitude: '38.281107',
    longitude: '-76.420424',
    timeZoneId: 'America/New_York',
  },
  '20674': {
    zip: '20674',
    city: 'Piney Point',
    state: 'MD',
    latitude: '38.1413',
    longitude: '-76.510191',
    timeZoneId: 'America/New_York',
  },
  '20675': {
    zip: '20675',
    city: 'Pomfret',
    state: 'MD',
    latitude: '38.580323',
    longitude: '-77.022464',
    timeZoneId: 'America/New_York',
  },
  '20676': {
    zip: '20676',
    city: 'Port Republic',
    state: 'MD',
    latitude: '38.492477',
    longitude: '-76.534133',
    timeZoneId: 'America/New_York',
  },
  '20677': {
    zip: '20677',
    city: 'Port Tobacco',
    state: 'MD',
    latitude: '38.506797',
    longitude: '-77.041996',
    timeZoneId: 'America/New_York',
  },
  '20678': {
    zip: '20678',
    city: 'Prince Frederick',
    state: 'MD',
    latitude: '38.53153',
    longitude: '-76.586745',
    timeZoneId: 'America/New_York',
  },
  '20680': {
    zip: '20680',
    city: 'Ridge',
    state: 'MD',
    latitude: '38.119822',
    longitude: '-76.371486',
    timeZoneId: 'America/New_York',
  },
  '20682': {
    zip: '20682',
    city: 'Rock Point',
    state: 'MD',
    latitude: '38.34621',
    longitude: '-76.920679',
    timeZoneId: 'America/New_York',
  },
  '20684': {
    zip: '20684',
    city: 'Saint Inigoes',
    state: 'MD',
    latitude: '38.152561',
    longitude: '-76.413819',
    timeZoneId: 'America/New_York',
  },
  '20685': {
    zip: '20685',
    city: 'Saint Leonard',
    state: 'MD',
    latitude: '38.438901',
    longitude: '-76.51907',
    timeZoneId: 'America/New_York',
  },
  '20686': {
    zip: '20686',
    city: 'Saint Marys City',
    state: 'MD',
    latitude: '38.186123',
    longitude: '-76.436956',
    timeZoneId: 'America/New_York',
  },
  '20687': {
    zip: '20687',
    city: 'Scotland',
    state: 'MD',
    latitude: '38.078266',
    longitude: '-76.346702',
    timeZoneId: 'America/New_York',
  },
  '20688': {
    zip: '20688',
    city: 'Solomons',
    state: 'MD',
    latitude: '38.338455',
    longitude: '-76.46343',
    timeZoneId: 'America/New_York',
  },
  '20689': {
    zip: '20689',
    city: 'Sunderland',
    state: 'MD',
    latitude: '38.657098',
    longitude: '-76.586489',
    timeZoneId: 'America/New_York',
  },
  '20690': {
    zip: '20690',
    city: 'Tall Timbers',
    state: 'MD',
    latitude: '38.166031',
    longitude: '-76.542912',
    timeZoneId: 'America/New_York',
  },
  '20692': {
    zip: '20692',
    city: 'Valley Lee',
    state: 'MD',
    latitude: '38.171738',
    longitude: '-76.50344',
    timeZoneId: 'America/New_York',
  },
  '20693': {
    zip: '20693',
    city: 'Welcome',
    state: 'MD',
    latitude: '38.478082',
    longitude: '-77.090606',
    timeZoneId: 'America/New_York',
  },
  '20695': {
    zip: '20695',
    city: 'White Plains',
    state: 'MD',
    latitude: '38.592595',
    longitude: '-76.957017',
    timeZoneId: 'America/New_York',
  },
  '20697': {
    zip: '20697',
    city: 'Southern MD Facility',
    state: 'MD',
    latitude: '39.03292',
    longitude: '-76.904253',
    timeZoneId: 'America/New_York',
  },
  '20701': {
    zip: '20701',
    city: 'Annapolis Junction',
    state: 'MD',
    latitude: '39.121614',
    longitude: '-76.79043',
    timeZoneId: 'America/New_York',
  },
  '20703': {
    zip: '20703',
    city: 'Lanham',
    state: 'MD',
    latitude: '38.966944',
    longitude: '-76.862222',
    timeZoneId: 'America/New_York',
  },
  '20704': {
    zip: '20704',
    city: 'Beltsville',
    state: 'MD',
    latitude: '39.039402',
    longitude: '-76.906964',
    timeZoneId: 'America/New_York',
  },
  '20705': {
    zip: '20705',
    city: 'Beltsville',
    state: 'MD',
    latitude: '39.044597',
    longitude: '-76.906156',
    timeZoneId: 'America/New_York',
  },
  '20706': {
    zip: '20706',
    city: 'Lanham',
    state: 'MD',
    latitude: '38.963377',
    longitude: '-76.854396',
    timeZoneId: 'America/New_York',
  },
  '20707': {
    zip: '20707',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.101616',
    longitude: '-76.884527',
    timeZoneId: 'America/New_York',
  },
  '20708': {
    zip: '20708',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.061389',
    longitude: '-76.851111',
    timeZoneId: 'America/New_York',
  },
  '20709': {
    zip: '20709',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.064161',
    longitude: '-76.837097',
    timeZoneId: 'America/New_York',
  },
  '20710': {
    zip: '20710',
    city: 'Bladensburg',
    state: 'MD',
    latitude: '38.942074',
    longitude: '-76.931715',
    timeZoneId: 'America/New_York',
  },
  '20711': {
    zip: '20711',
    city: 'Lothian',
    state: 'MD',
    latitude: '38.806456',
    longitude: '-76.650233',
    timeZoneId: 'America/New_York',
  },
  '20712': {
    zip: '20712',
    city: 'Mount Rainier',
    state: 'MD',
    latitude: '38.943335',
    longitude: '-76.965412',
    timeZoneId: 'America/New_York',
  },
  '20714': {
    zip: '20714',
    city: 'North Beach',
    state: 'MD',
    latitude: '38.711654',
    longitude: '-76.537211',
    timeZoneId: 'America/New_York',
  },
  '20715': {
    zip: '20715',
    city: 'Bowie',
    state: 'MD',
    latitude: '38.988865',
    longitude: '-76.738966',
    timeZoneId: 'America/New_York',
  },
  '20716': {
    zip: '20716',
    city: 'Bowie',
    state: 'MD',
    latitude: '38.9253',
    longitude: '-76.71546',
    timeZoneId: 'America/New_York',
  },
  '20717': {
    zip: '20717',
    city: 'Bowie',
    state: 'MD',
    latitude: '39.00896',
    longitude: '-76.781954',
    timeZoneId: 'America/New_York',
  },
  '20718': {
    zip: '20718',
    city: 'Bowie',
    state: 'MD',
    latitude: '38.985875',
    longitude: '-76.754152',
    timeZoneId: 'America/New_York',
  },
  '20719': {
    zip: '20719',
    city: 'Bowie',
    state: 'MD',
    latitude: '38.95985',
    longitude: '-76.73713',
    timeZoneId: 'America/New_York',
  },
  '20720': {
    zip: '20720',
    city: 'Bowie',
    state: 'MD',
    latitude: '38.965267',
    longitude: '-76.773094',
    timeZoneId: 'America/New_York',
  },
  '20721': {
    zip: '20721',
    city: 'Bowie',
    state: 'MD',
    latitude: '38.9307',
    longitude: '-76.7856',
    timeZoneId: 'America/New_York',
  },
  '20722': {
    zip: '20722',
    city: 'Brentwood',
    state: 'MD',
    latitude: '38.939104',
    longitude: '-76.953103',
    timeZoneId: 'America/New_York',
  },
  '20723': {
    zip: '20723',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.133677',
    longitude: '-76.861876',
    timeZoneId: 'America/New_York',
  },
  '20724': {
    zip: '20724',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.090999',
    longitude: '-76.818955',
    timeZoneId: 'America/New_York',
  },
  '20725': {
    zip: '20725',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.102525',
    longitude: '-76.844376',
    timeZoneId: 'America/New_York',
  },
  '20726': {
    zip: '20726',
    city: 'Laurel',
    state: 'MD',
    latitude: '39.089392',
    longitude: '-76.852395',
    timeZoneId: 'America/New_York',
  },
  '20731': {
    zip: '20731',
    city: 'Capitol Heights',
    state: 'MD',
    latitude: '38.887677',
    longitude: '-76.912147',
    timeZoneId: 'America/New_York',
  },
  '20732': {
    zip: '20732',
    city: 'Chesapeake Beach',
    state: 'MD',
    latitude: '38.658353',
    longitude: '-76.546878',
    timeZoneId: 'America/New_York',
  },
  '20733': {
    zip: '20733',
    city: 'Churchton',
    state: 'MD',
    latitude: '38.808321',
    longitude: '-76.531195',
    timeZoneId: 'America/New_York',
  },
  '20735': {
    zip: '20735',
    city: 'Clinton',
    state: 'MD',
    latitude: '38.756541',
    longitude: '-76.90699',
    timeZoneId: 'America/New_York',
  },
  '20736': {
    zip: '20736',
    city: 'Owings',
    state: 'MD',
    latitude: '38.698789',
    longitude: '-76.617409',
    timeZoneId: 'America/New_York',
  },
  '20737': {
    zip: '20737',
    city: 'Riverdale',
    state: 'MD',
    latitude: '38.959262',
    longitude: '-76.920047',
    timeZoneId: 'America/New_York',
  },
  '20738': {
    zip: '20738',
    city: 'Riverdale',
    state: 'MD',
    latitude: '38.963244',
    longitude: '-76.929195',
    timeZoneId: 'America/New_York',
  },
  '20740': {
    zip: '20740',
    city: 'College Park',
    state: 'MD',
    latitude: '38.998722',
    longitude: '-76.928721',
    timeZoneId: 'America/New_York',
  },
  '20741': {
    zip: '20741',
    city: 'College Park',
    state: 'MD',
    latitude: '38.998102',
    longitude: '-76.936482',
    timeZoneId: 'America/New_York',
  },
  '20742': {
    zip: '20742',
    city: 'College Park',
    state: 'MD',
    latitude: '38.992333',
    longitude: '-76.952986',
    timeZoneId: 'America/New_York',
  },
  '20743': {
    zip: '20743',
    city: 'Capitol Heights',
    state: 'MD',
    latitude: '38.885663',
    longitude: '-76.901034',
    timeZoneId: 'America/New_York',
  },
  '20744': {
    zip: '20744',
    city: 'Fort Washington',
    state: 'MD',
    latitude: '38.752955',
    longitude: '-76.981503',
    timeZoneId: 'America/New_York',
  },
  '20745': {
    zip: '20745',
    city: 'Oxon Hill',
    state: 'MD',
    latitude: '38.807841',
    longitude: '-76.989145',
    timeZoneId: 'America/New_York',
  },
  '20746': {
    zip: '20746',
    city: 'Suitland',
    state: 'MD',
    latitude: '38.837308',
    longitude: '-76.918744',
    timeZoneId: 'America/New_York',
  },
  '20747': {
    zip: '20747',
    city: 'District Heights',
    state: 'MD',
    latitude: '38.854982',
    longitude: '-76.887745',
    timeZoneId: 'America/New_York',
  },
  '20748': {
    zip: '20748',
    city: 'Temple Hills',
    state: 'MD',
    latitude: '38.816051',
    longitude: '-76.939606',
    timeZoneId: 'America/New_York',
  },
  '20749': {
    zip: '20749',
    city: 'Fort Washington',
    state: 'MD',
    latitude: '38.733525',
    longitude: '-77.003006',
    timeZoneId: 'America/New_York',
  },
  '20750': {
    zip: '20750',
    city: 'Oxon Hill',
    state: 'MD',
    latitude: '38.801416',
    longitude: '-76.991109',
    timeZoneId: 'America/New_York',
  },
  '20751': {
    zip: '20751',
    city: 'Deale',
    state: 'MD',
    latitude: '38.785008',
    longitude: '-76.552091',
    timeZoneId: 'America/New_York',
  },
  '20752': {
    zip: '20752',
    city: 'Suitland',
    state: 'MD',
    latitude: '38.849336',
    longitude: '-76.925085',
    timeZoneId: 'America/New_York',
  },
  '20753': {
    zip: '20753',
    city: 'District Heights',
    state: 'MD',
    latitude: '38.858256',
    longitude: '-76.887275',
    timeZoneId: 'America/New_York',
  },
  '20754': {
    zip: '20754',
    city: 'Dunkirk',
    state: 'MD',
    latitude: '38.730532',
    longitude: '-76.656015',
    timeZoneId: 'America/New_York',
  },
  '20755': {
    zip: '20755',
    city: 'Fort George G Meade',
    state: 'MD',
    latitude: '39.10968',
    longitude: '-76.736935',
    timeZoneId: 'America/New_York',
  },
  '20757': {
    zip: '20757',
    city: 'Temple Hills',
    state: 'MD',
    latitude: '38.806795',
    longitude: '-76.947183',
    timeZoneId: 'America/New_York',
  },
  '20758': {
    zip: '20758',
    city: 'Friendship',
    state: 'MD',
    latitude: '38.737232',
    longitude: '-76.5938',
    timeZoneId: 'America/New_York',
  },
  '20759': {
    zip: '20759',
    city: 'Fulton',
    state: 'MD',
    latitude: '39.152829',
    longitude: '-76.931318',
    timeZoneId: 'America/New_York',
  },
  '20762': {
    zip: '20762',
    city: 'Andrews Air Force Base',
    state: 'MD',
    latitude: '38.805042',
    longitude: '-76.880984',
    timeZoneId: 'America/New_York',
  },
  '20763': {
    zip: '20763',
    city: 'Savage',
    state: 'MD',
    latitude: '39.140828',
    longitude: '-76.8259',
    timeZoneId: 'America/New_York',
  },
  '20764': {
    zip: '20764',
    city: 'Shady Side',
    state: 'MD',
    latitude: '38.838222',
    longitude: '-76.512559',
    timeZoneId: 'America/New_York',
  },
  '20765': {
    zip: '20765',
    city: 'Galesville',
    state: 'MD',
    latitude: '38.844354',
    longitude: '-76.554174',
    timeZoneId: 'America/New_York',
  },
  '20768': {
    zip: '20768',
    city: 'Greenbelt',
    state: 'MD',
    latitude: '39.000621',
    longitude: '-76.877752',
    timeZoneId: 'America/New_York',
  },
  '20769': {
    zip: '20769',
    city: 'Glenn Dale',
    state: 'MD',
    latitude: '38.978884',
    longitude: '-76.805732',
    timeZoneId: 'America/New_York',
  },
  '20770': {
    zip: '20770',
    city: 'Greenbelt',
    state: 'MD',
    latitude: '38.996883',
    longitude: '-76.884085',
    timeZoneId: 'America/New_York',
  },
  '20771': {
    zip: '20771',
    city: 'Greenbelt',
    state: 'MD',
    latitude: '39.008275',
    longitude: '-76.865678',
    timeZoneId: 'America/New_York',
  },
  '20772': {
    zip: '20772',
    city: 'Upper Marlboro',
    state: 'MD',
    latitude: '38.799',
    longitude: '-76.78596',
    timeZoneId: 'America/New_York',
  },
  '20773': {
    zip: '20773',
    city: 'Upper Marlboro',
    state: 'MD',
    latitude: '38.814554',
    longitude: '-76.754248',
    timeZoneId: 'America/New_York',
  },
  '20774': {
    zip: '20774',
    city: 'Upper Marlboro',
    state: 'MD',
    latitude: '38.883657',
    longitude: '-76.766349',
    timeZoneId: 'America/New_York',
  },
  '20775': {
    zip: '20775',
    city: 'Upper Marlboro',
    state: 'MD',
    latitude: '38.782687',
    longitude: '-76.767816',
    timeZoneId: 'America/New_York',
  },
  '20776': {
    zip: '20776',
    city: 'Harwood',
    state: 'MD',
    latitude: '38.869837',
    longitude: '-76.624179',
    timeZoneId: 'America/New_York',
  },
  '20777': {
    zip: '20777',
    city: 'Highland',
    state: 'MD',
    latitude: '39.183211',
    longitude: '-76.968505',
    timeZoneId: 'America/New_York',
  },
  '20778': {
    zip: '20778',
    city: 'West River',
    state: 'MD',
    latitude: '38.834032',
    longitude: '-76.563786',
    timeZoneId: 'America/New_York',
  },
  '20779': {
    zip: '20779',
    city: 'Tracys Landing',
    state: 'MD',
    latitude: '38.770043',
    longitude: '-76.57786',
    timeZoneId: 'America/New_York',
  },
  '20781': {
    zip: '20781',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '38.94084',
    longitude: '-76.92828',
    timeZoneId: 'America/New_York',
  },
  '20782': {
    zip: '20782',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '38.964978',
    longitude: '-76.965246',
    timeZoneId: 'America/New_York',
  },
  '20783': {
    zip: '20783',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '38.99934',
    longitude: '-76.97502',
    timeZoneId: 'America/New_York',
  },
  '20784': {
    zip: '20784',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '38.95398',
    longitude: '-76.89678',
    timeZoneId: 'America/New_York',
  },
  '20785': {
    zip: '20785',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '38.91592',
    longitude: '-76.886146',
    timeZoneId: 'America/New_York',
  },
  '20787': {
    zip: '20787',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '39.001883',
    longitude: '-76.969387',
    timeZoneId: 'America/New_York',
  },
  '20788': {
    zip: '20788',
    city: 'Hyattsville',
    state: 'MD',
    latitude: '38.964901',
    longitude: '-76.966704',
    timeZoneId: 'America/New_York',
  },
  '20790': {
    zip: '20790',
    city: 'Capitol Heights',
    state: 'MD',
    latitude: '38.854641',
    longitude: '-76.931528',
    timeZoneId: 'America/New_York',
  },
  '20791': {
    zip: '20791',
    city: 'Capitol Heights',
    state: 'MD',
    latitude: '38.81765',
    longitude: '-76.943769',
    timeZoneId: 'America/New_York',
  },
  '20792': {
    zip: '20792',
    city: 'Upper Marlboro',
    state: 'MD',
    latitude: '38.815289',
    longitude: '-76.749621',
    timeZoneId: 'America/New_York',
  },
  '20794': {
    zip: '20794',
    city: 'Jessup',
    state: 'MD',
    latitude: '39.154739',
    longitude: '-76.811082',
    timeZoneId: 'America/New_York',
  },
  '20797': {
    zip: '20797',
    city: 'Southern MD Facility',
    state: 'MD',
    latitude: '39.095953',
    longitude: '-76.838956',
    timeZoneId: 'America/New_York',
  },
  '20799': {
    zip: '20799',
    city: 'Capitol Heights',
    state: 'MD',
    latitude: '38.881409',
    longitude: '-76.856119',
    timeZoneId: 'America/New_York',
  },
  '20810': {
    zip: '20810',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.979522',
    longitude: '-77.100398',
    timeZoneId: 'America/New_York',
  },
  '20811': {
    zip: '20811',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.982042',
    longitude: '-77.099807',
    timeZoneId: 'America/New_York',
  },
  '20812': {
    zip: '20812',
    city: 'Glen Echo',
    state: 'MD',
    latitude: '38.97208',
    longitude: '-77.129817',
    timeZoneId: 'America/New_York',
  },
  '20813': {
    zip: '20813',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.984105',
    longitude: '-77.080215',
    timeZoneId: 'America/New_York',
  },
  '20814': {
    zip: '20814',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.999228',
    longitude: '-77.104177',
    timeZoneId: 'America/New_York',
  },
  '20815': {
    zip: '20815',
    city: 'Chevy Chase',
    state: 'MD',
    latitude: '38.980206',
    longitude: '-77.07972',
    timeZoneId: 'America/New_York',
  },
  '20816': {
    zip: '20816',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.958169',
    longitude: '-77.115563',
    timeZoneId: 'America/New_York',
  },
  '20817': {
    zip: '20817',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.997996',
    longitude: '-77.146318',
    timeZoneId: 'America/New_York',
  },
  '20818': {
    zip: '20818',
    city: 'Cabin John',
    state: 'MD',
    latitude: '38.975593',
    longitude: '-77.159849',
    timeZoneId: 'America/New_York',
  },
  '20824': {
    zip: '20824',
    city: 'Bethesda',
    state: 'MD',
    latitude: '38.984119',
    longitude: '-77.092723',
    timeZoneId: 'America/New_York',
  },
  '20825': {
    zip: '20825',
    city: 'Chevy Chase',
    state: 'MD',
    latitude: '38.995496',
    longitude: '-77.074994',
    timeZoneId: 'America/New_York',
  },
  '20827': {
    zip: '20827',
    city: 'Bethesda',
    state: 'MD',
    latitude: '39.013715',
    longitude: '-77.158738',
    timeZoneId: 'America/New_York',
  },
  '20830': {
    zip: '20830',
    city: 'Olney',
    state: 'MD',
    latitude: '39.159267',
    longitude: '-77.075718',
    timeZoneId: 'America/New_York',
  },
  '20832': {
    zip: '20832',
    city: 'Olney',
    state: 'MD',
    latitude: '39.148558',
    longitude: '-77.074173',
    timeZoneId: 'America/New_York',
  },
  '20833': {
    zip: '20833',
    city: 'Brookeville',
    state: 'MD',
    latitude: '39.2064',
    longitude: '-77.05074',
    timeZoneId: 'America/New_York',
  },
  '20837': {
    zip: '20837',
    city: 'Poolesville',
    state: 'MD',
    latitude: '39.131746',
    longitude: '-77.404202',
    timeZoneId: 'America/New_York',
  },
  '20838': {
    zip: '20838',
    city: 'Barnesville',
    state: 'MD',
    latitude: '39.234875',
    longitude: '-77.348886',
    timeZoneId: 'America/New_York',
  },
  '20839': {
    zip: '20839',
    city: 'Beallsville',
    state: 'MD',
    latitude: '39.17605',
    longitude: '-77.406611',
    timeZoneId: 'America/New_York',
  },
  '20841': {
    zip: '20841',
    city: 'Boyds',
    state: 'MD',
    latitude: '39.196623',
    longitude: '-77.335092',
    timeZoneId: 'America/New_York',
  },
  '20842': {
    zip: '20842',
    city: 'Dickerson',
    state: 'MD',
    latitude: '39.20418',
    longitude: '-77.42172',
    timeZoneId: 'America/New_York',
  },
  '20847': {
    zip: '20847',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.08448',
    longitude: '-77.15186',
    timeZoneId: 'America/New_York',
  },
  '20848': {
    zip: '20848',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.073081',
    longitude: '-77.118289',
    timeZoneId: 'America/New_York',
  },
  '20849': {
    zip: '20849',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.082419',
    longitude: '-77.15282',
    timeZoneId: 'America/New_York',
  },
  '20850': {
    zip: '20850',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.08592',
    longitude: '-77.174389',
    timeZoneId: 'America/New_York',
  },
  '20851': {
    zip: '20851',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.077214',
    longitude: '-77.126217',
    timeZoneId: 'America/New_York',
  },
  '20852': {
    zip: '20852',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.046797',
    longitude: '-77.119654',
    timeZoneId: 'America/New_York',
  },
  '20853': {
    zip: '20853',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.096217',
    longitude: '-77.095489',
    timeZoneId: 'America/New_York',
  },
  '20854': {
    zip: '20854',
    city: 'Potomac',
    state: 'MD',
    latitude: '39.02874',
    longitude: '-77.24094',
    timeZoneId: 'America/New_York',
  },
  '20855': {
    zip: '20855',
    city: 'Derwood',
    state: 'MD',
    latitude: '39.14502',
    longitude: '-77.13756',
    timeZoneId: 'America/New_York',
  },
  '20857': {
    zip: '20857',
    city: 'Rockville',
    state: 'MD',
    latitude: '39.079354',
    longitude: '-77.156398',
    timeZoneId: 'America/New_York',
  },
  '20859': {
    zip: '20859',
    city: 'Potomac',
    state: 'MD',
    latitude: '39.022959',
    longitude: '-77.19925',
    timeZoneId: 'America/New_York',
  },
  '20860': {
    zip: '20860',
    city: 'Sandy Spring',
    state: 'MD',
    latitude: '39.149764',
    longitude: '-77.027379',
    timeZoneId: 'America/New_York',
  },
  '20861': {
    zip: '20861',
    city: 'Ashton',
    state: 'MD',
    latitude: '39.155101',
    longitude: '-76.994217',
    timeZoneId: 'America/New_York',
  },
  '20862': {
    zip: '20862',
    city: 'Brinklow',
    state: 'MD',
    latitude: '39.179672',
    longitude: '-77.020116',
    timeZoneId: 'America/New_York',
  },
  '20866': {
    zip: '20866',
    city: 'Burtonsville',
    state: 'MD',
    latitude: '39.098463',
    longitude: '-76.936128',
    timeZoneId: 'America/New_York',
  },
  '20868': {
    zip: '20868',
    city: 'Spencerville',
    state: 'MD',
    latitude: '39.119176',
    longitude: '-76.971825',
    timeZoneId: 'America/New_York',
  },
  '20871': {
    zip: '20871',
    city: 'Clarksburg',
    state: 'MD',
    latitude: '39.255811',
    longitude: '-77.279923',
    timeZoneId: 'America/New_York',
  },
  '20872': {
    zip: '20872',
    city: 'Damascus',
    state: 'MD',
    latitude: '39.281394',
    longitude: '-77.216305',
    timeZoneId: 'America/New_York',
  },
  '20874': {
    zip: '20874',
    city: 'Germantown',
    state: 'MD',
    latitude: '39.167973',
    longitude: '-77.268161',
    timeZoneId: 'America/New_York',
  },
  '20875': {
    zip: '20875',
    city: 'Germantown',
    state: 'MD',
    latitude: '39.176434',
    longitude: '-77.262513',
    timeZoneId: 'America/New_York',
  },
  '20876': {
    zip: '20876',
    city: 'Germantown',
    state: 'MD',
    latitude: '39.191056',
    longitude: '-77.234312',
    timeZoneId: 'America/New_York',
  },
  '20877': {
    zip: '20877',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.141703',
    longitude: '-77.193419',
    timeZoneId: 'America/New_York',
  },
  '20878': {
    zip: '20878',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.105531',
    longitude: '-77.24019',
    timeZoneId: 'America/New_York',
  },
  '20879': {
    zip: '20879',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.17754',
    longitude: '-77.194392',
    timeZoneId: 'America/New_York',
  },
  '20880': {
    zip: '20880',
    city: 'Washington Grove',
    state: 'MD',
    latitude: '39.140721',
    longitude: '-77.176247',
    timeZoneId: 'America/New_York',
  },
  '20882': {
    zip: '20882',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.215201',
    longitude: '-77.141552',
    timeZoneId: 'America/New_York',
  },
  '20883': {
    zip: '20883',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.141926',
    longitude: '-77.20251',
    timeZoneId: 'America/New_York',
  },
  '20884': {
    zip: '20884',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.142686',
    longitude: '-77.195078',
    timeZoneId: 'America/New_York',
  },
  '20885': {
    zip: '20885',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.133486',
    longitude: '-77.229056',
    timeZoneId: 'America/New_York',
  },
  '20886': {
    zip: '20886',
    city: 'Montgomery Village',
    state: 'MD',
    latitude: '39.17094',
    longitude: '-77.2095',
    timeZoneId: 'America/New_York',
  },
  '20889': {
    zip: '20889',
    city: 'Bethesda',
    state: 'MD',
    latitude: '39.009294',
    longitude: '-77.097732',
    timeZoneId: 'America/New_York',
  },
  '20891': {
    zip: '20891',
    city: 'Kensington',
    state: 'MD',
    latitude: '39.026282',
    longitude: '-77.075436',
    timeZoneId: 'America/New_York',
  },
  '20892': {
    zip: '20892',
    city: 'Bethesda',
    state: 'MD',
    latitude: '39.00826',
    longitude: '-77.10029',
    timeZoneId: 'America/New_York',
  },
  '20894': {
    zip: '20894',
    city: 'Bethesda',
    state: 'MD',
    latitude: '39.002607',
    longitude: '-77.093732',
    timeZoneId: 'America/New_York',
  },
  '20895': {
    zip: '20895',
    city: 'Kensington',
    state: 'MD',
    latitude: '39.027491',
    longitude: '-77.077263',
    timeZoneId: 'America/New_York',
  },
  '20896': {
    zip: '20896',
    city: 'Garrett Park',
    state: 'MD',
    latitude: '39.033201',
    longitude: '-77.092769',
    timeZoneId: 'America/New_York',
  },
  '20897': {
    zip: '20897',
    city: 'Suburb Maryland Fac',
    state: 'MD',
    latitude: '39.087874',
    longitude: '-77.054787',
    timeZoneId: 'America/New_York',
  },
  '20898': {
    zip: '20898',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.142887',
    longitude: '-77.202815',
    timeZoneId: 'America/New_York',
  },
  '20899': {
    zip: '20899',
    city: 'Gaithersburg',
    state: 'MD',
    latitude: '39.144286',
    longitude: '-77.215257',
    timeZoneId: 'America/New_York',
  },
  '20901': {
    zip: '20901',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.018164',
    longitude: '-77.008298',
    timeZoneId: 'America/New_York',
  },
  '20902': {
    zip: '20902',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.037324',
    longitude: '-77.049792',
    timeZoneId: 'America/New_York',
  },
  '20903': {
    zip: '20903',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.01278',
    longitude: '-76.9812',
    timeZoneId: 'America/New_York',
  },
  '20904': {
    zip: '20904',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.06504',
    longitude: '-76.9815',
    timeZoneId: 'America/New_York',
  },
  '20905': {
    zip: '20905',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.10187',
    longitude: '-76.991042',
    timeZoneId: 'America/New_York',
  },
  '20906': {
    zip: '20906',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.081407',
    longitude: '-77.056897',
    timeZoneId: 'America/New_York',
  },
  '20907': {
    zip: '20907',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '38.998857',
    longitude: '-77.032519',
    timeZoneId: 'America/New_York',
  },
  '20908': {
    zip: '20908',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.104732',
    longitude: '-77.076794',
    timeZoneId: 'America/New_York',
  },
  '20910': {
    zip: '20910',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '38.998735',
    longitude: '-77.036543',
    timeZoneId: 'America/New_York',
  },
  '20911': {
    zip: '20911',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '38.99457',
    longitude: '-77.032258',
    timeZoneId: 'America/New_York',
  },
  '20912': {
    zip: '20912',
    city: 'Takoma Park',
    state: 'MD',
    latitude: '38.985658',
    longitude: '-77.005547',
    timeZoneId: 'America/New_York',
  },
  '20913': {
    zip: '20913',
    city: 'Takoma Park',
    state: 'MD',
    latitude: '38.981883',
    longitude: '-77.003878',
    timeZoneId: 'America/New_York',
  },
  '20914': {
    zip: '20914',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.074133',
    longitude: '-77.00346',
    timeZoneId: 'America/New_York',
  },
  '20915': {
    zip: '20915',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.043731',
    longitude: '-77.046791',
    timeZoneId: 'America/New_York',
  },
  '20916': {
    zip: '20916',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.084115',
    longitude: '-77.079333',
    timeZoneId: 'America/New_York',
  },
  '20918': {
    zip: '20918',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '39.020818',
    longitude: '-77.016371',
    timeZoneId: 'America/New_York',
  },
  '20993': {
    zip: '20993',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '38.990556',
    longitude: '-77.026389',
    timeZoneId: 'America/New_York',
  },
  '20997': {
    zip: '20997',
    city: 'Silver Spring',
    state: 'MD',
    latitude: '38.994472',
    longitude: '-77.025419',
    timeZoneId: 'America/New_York',
  },
  '21001': {
    zip: '21001',
    city: 'Aberdeen',
    state: 'MD',
    latitude: '39.508989',
    longitude: '-76.18245',
    timeZoneId: 'America/New_York',
  },
  '21005': {
    zip: '21005',
    city: 'Aberdeen Proving Ground',
    state: 'MD',
    latitude: '39.455614',
    longitude: '-76.150285',
    timeZoneId: 'America/New_York',
  },
  '21009': {
    zip: '21009',
    city: 'Abingdon',
    state: 'MD',
    latitude: '39.473034',
    longitude: '-76.297814',
    timeZoneId: 'America/New_York',
  },
  '21010': {
    zip: '21010',
    city: 'Gunpowder',
    state: 'MD',
    latitude: '39.4047',
    longitude: '-76.28832',
    timeZoneId: 'America/New_York',
  },
  '21012': {
    zip: '21012',
    city: 'Arnold',
    state: 'MD',
    latitude: '39.047216',
    longitude: '-76.498751',
    timeZoneId: 'America/New_York',
  },
  '21013': {
    zip: '21013',
    city: 'Baldwin',
    state: 'MD',
    latitude: '39.499777',
    longitude: '-76.493431',
    timeZoneId: 'America/New_York',
  },
  '21014': {
    zip: '21014',
    city: 'Bel Air',
    state: 'MD',
    latitude: '39.536791',
    longitude: '-76.357326',
    timeZoneId: 'America/New_York',
  },
  '21015': {
    zip: '21015',
    city: 'Bel Air',
    state: 'MD',
    latitude: '39.535341',
    longitude: '-76.28926',
    timeZoneId: 'America/New_York',
  },
  '21017': {
    zip: '21017',
    city: 'Belcamp',
    state: 'MD',
    latitude: '39.472249',
    longitude: '-76.237972',
    timeZoneId: 'America/New_York',
  },
  '21018': {
    zip: '21018',
    city: 'Benson',
    state: 'MD',
    latitude: '39.504167',
    longitude: '-76.386472',
    timeZoneId: 'America/New_York',
  },
  '21020': {
    zip: '21020',
    city: 'Boring',
    state: 'MD',
    latitude: '39.523939',
    longitude: '-76.816901',
    timeZoneId: 'America/New_York',
  },
  '21022': {
    zip: '21022',
    city: 'Brooklandville',
    state: 'MD',
    latitude: '39.407526',
    longitude: '-76.667451',
    timeZoneId: 'America/New_York',
  },
  '21023': {
    zip: '21023',
    city: 'Butler',
    state: 'MD',
    latitude: '39.534656',
    longitude: '-76.730495',
    timeZoneId: 'America/New_York',
  },
  '21027': {
    zip: '21027',
    city: 'Chase',
    state: 'MD',
    latitude: '39.353926',
    longitude: '-76.372508',
    timeZoneId: 'America/New_York',
  },
  '21028': {
    zip: '21028',
    city: 'Churchville',
    state: 'MD',
    latitude: '39.563049',
    longitude: '-76.246709',
    timeZoneId: 'America/New_York',
  },
  '21029': {
    zip: '21029',
    city: 'Clarksville',
    state: 'MD',
    latitude: '39.201943',
    longitude: '-76.948075',
    timeZoneId: 'America/New_York',
  },
  '21030': {
    zip: '21030',
    city: 'Cockeysville',
    state: 'MD',
    latitude: '39.49152',
    longitude: '-76.66086',
    timeZoneId: 'America/New_York',
  },
  '21031': {
    zip: '21031',
    city: 'Hunt Valley',
    state: 'MD',
    latitude: '39.490349',
    longitude: '-76.659398',
    timeZoneId: 'America/New_York',
  },
  '21032': {
    zip: '21032',
    city: 'Crownsville',
    state: 'MD',
    latitude: '39.04501',
    longitude: '-76.596422',
    timeZoneId: 'America/New_York',
  },
  '21034': {
    zip: '21034',
    city: 'Darlington',
    state: 'MD',
    latitude: '39.646631',
    longitude: '-76.223142',
    timeZoneId: 'America/New_York',
  },
  '21035': {
    zip: '21035',
    city: 'Davidsonville',
    state: 'MD',
    latitude: '38.936803',
    longitude: '-76.630272',
    timeZoneId: 'America/New_York',
  },
  '21036': {
    zip: '21036',
    city: 'Dayton',
    state: 'MD',
    latitude: '39.240467',
    longitude: '-76.996499',
    timeZoneId: 'America/New_York',
  },
  '21037': {
    zip: '21037',
    city: 'Edgewater',
    state: 'MD',
    latitude: '38.921302',
    longitude: '-76.542804',
    timeZoneId: 'America/New_York',
  },
  '21040': {
    zip: '21040',
    city: 'Edgewood',
    state: 'MD',
    latitude: '39.429165',
    longitude: '-76.304399',
    timeZoneId: 'America/New_York',
  },
  '21041': {
    zip: '21041',
    city: 'Ellicott City',
    state: 'MD',
    latitude: '39.273952',
    longitude: '-76.836061',
    timeZoneId: 'America/New_York',
  },
  '21042': {
    zip: '21042',
    city: 'Ellicott City',
    state: 'MD',
    latitude: '39.277416',
    longitude: '-76.866712',
    timeZoneId: 'America/New_York',
  },
  '21043': {
    zip: '21043',
    city: 'Ellicott City',
    state: 'MD',
    latitude: '39.266016',
    longitude: '-76.845513',
    timeZoneId: 'America/New_York',
  },
  '21044': {
    zip: '21044',
    city: 'Columbia',
    state: 'MD',
    latitude: '39.212981',
    longitude: '-76.882253',
    timeZoneId: 'America/New_York',
  },
  '21045': {
    zip: '21045',
    city: 'Columbia',
    state: 'MD',
    latitude: '39.204277',
    longitude: '-76.83444',
    timeZoneId: 'America/New_York',
  },
  '21046': {
    zip: '21046',
    city: 'Columbia',
    state: 'MD',
    latitude: '39.171649',
    longitude: '-76.852986',
    timeZoneId: 'America/New_York',
  },
  '21047': {
    zip: '21047',
    city: 'Fallston',
    state: 'MD',
    latitude: '39.525849',
    longitude: '-76.43007',
    timeZoneId: 'America/New_York',
  },
  '21048': {
    zip: '21048',
    city: 'Finksburg',
    state: 'MD',
    latitude: '39.504233',
    longitude: '-76.904395',
    timeZoneId: 'America/New_York',
  },
  '21050': {
    zip: '21050',
    city: 'Forest Hill',
    state: 'MD',
    latitude: '39.574458',
    longitude: '-76.397623',
    timeZoneId: 'America/New_York',
  },
  '21051': {
    zip: '21051',
    city: 'Fork',
    state: 'MD',
    latitude: '39.473118',
    longitude: '-76.456353',
    timeZoneId: 'America/New_York',
  },
  '21052': {
    zip: '21052',
    city: 'Fort Howard',
    state: 'MD',
    latitude: '39.203436',
    longitude: '-76.446048',
    timeZoneId: 'America/New_York',
  },
  '21053': {
    zip: '21053',
    city: 'Freeland',
    state: 'MD',
    latitude: '39.696325',
    longitude: '-76.719225',
    timeZoneId: 'America/New_York',
  },
  '21054': {
    zip: '21054',
    city: 'Gambrills',
    state: 'MD',
    latitude: '39.036495',
    longitude: '-76.671718',
    timeZoneId: 'America/New_York',
  },
  '21056': {
    zip: '21056',
    city: 'Gibson Island',
    state: 'MD',
    latitude: '39.07737',
    longitude: '-76.431211',
    timeZoneId: 'America/New_York',
  },
  '21057': {
    zip: '21057',
    city: 'Glen Arm',
    state: 'MD',
    latitude: '39.448699',
    longitude: '-76.500508',
    timeZoneId: 'America/New_York',
  },
  '21060': {
    zip: '21060',
    city: 'Glen Burnie',
    state: 'MD',
    latitude: '39.164559',
    longitude: '-76.583398',
    timeZoneId: 'America/New_York',
  },
  '21061': {
    zip: '21061',
    city: 'Glen Burnie',
    state: 'MD',
    latitude: '39.164525',
    longitude: '-76.634664',
    timeZoneId: 'America/New_York',
  },
  '21062': {
    zip: '21062',
    city: 'Glen Burnie',
    state: 'MD',
    latitude: '39.163696',
    longitude: '-76.603579',
    timeZoneId: 'America/New_York',
  },
  '21065': {
    zip: '21065',
    city: 'Hunt Valley',
    state: 'MD',
    latitude: '39.478149',
    longitude: '-76.64514',
    timeZoneId: 'America/New_York',
  },
  '21071': {
    zip: '21071',
    city: 'Glyndon',
    state: 'MD',
    latitude: '39.485037',
    longitude: '-76.775877',
    timeZoneId: 'America/New_York',
  },
  '21074': {
    zip: '21074',
    city: 'Hampstead',
    state: 'MD',
    latitude: '39.610628',
    longitude: '-76.848152',
    timeZoneId: 'America/New_York',
  },
  '21075': {
    zip: '21075',
    city: 'Elkridge',
    state: 'MD',
    latitude: '39.201493',
    longitude: '-76.752641',
    timeZoneId: 'America/New_York',
  },
  '21076': {
    zip: '21076',
    city: 'Hanover',
    state: 'MD',
    latitude: '39.16494',
    longitude: '-76.71564',
    timeZoneId: 'America/New_York',
  },
  '21077': {
    zip: '21077',
    city: 'Harmans',
    state: 'MD',
    latitude: '39.156823',
    longitude: '-76.701819',
    timeZoneId: 'America/New_York',
  },
  '21078': {
    zip: '21078',
    city: 'Havre de Grace',
    state: 'MD',
    latitude: '39.583804',
    longitude: '-76.151327',
    timeZoneId: 'America/New_York',
  },
  '21082': {
    zip: '21082',
    city: 'Hydes',
    state: 'MD',
    latitude: '39.475606',
    longitude: '-76.475639',
    timeZoneId: 'America/New_York',
  },
  '21084': {
    zip: '21084',
    city: 'Jarrettsville',
    state: 'MD',
    latitude: '39.615377',
    longitude: '-76.471101',
    timeZoneId: 'America/New_York',
  },
  '21085': {
    zip: '21085',
    city: 'Joppa',
    state: 'MD',
    latitude: '39.431754',
    longitude: '-76.355815',
    timeZoneId: 'America/New_York',
  },
  '21087': {
    zip: '21087',
    city: 'Kingsville',
    state: 'MD',
    latitude: '39.448752',
    longitude: '-76.419654',
    timeZoneId: 'America/New_York',
  },
  '21088': {
    zip: '21088',
    city: 'Lineboro',
    state: 'MD',
    latitude: '39.71748',
    longitude: '-76.83',
    timeZoneId: 'America/New_York',
  },
  '21090': {
    zip: '21090',
    city: 'Linthicum Heights',
    state: 'MD',
    latitude: '39.20567',
    longitude: '-76.657567',
    timeZoneId: 'America/New_York',
  },
  '21092': {
    zip: '21092',
    city: 'Long Green',
    state: 'MD',
    latitude: '39.475296',
    longitude: '-76.528579',
    timeZoneId: 'America/New_York',
  },
  '21093': {
    zip: '21093',
    city: 'Lutherville Timonium',
    state: 'MD',
    latitude: '39.43494',
    longitude: '-76.65486',
    timeZoneId: 'America/New_York',
  },
  '21094': {
    zip: '21094',
    city: 'Lutherville Timonium',
    state: 'MD',
    latitude: '39.42132',
    longitude: '-76.623102',
    timeZoneId: 'America/New_York',
  },
  '21102': {
    zip: '21102',
    city: 'Manchester',
    state: 'MD',
    latitude: '39.64355',
    longitude: '-76.6964',
    timeZoneId: 'America/New_York',
  },
  '21104': {
    zip: '21104',
    city: 'Marriottsville',
    state: 'MD',
    latitude: '39.34368',
    longitude: '-76.90722',
    timeZoneId: 'America/New_York',
  },
  '21105': {
    zip: '21105',
    city: 'Maryland Line',
    state: 'MD',
    latitude: '39.711425',
    longitude: '-76.652659',
    timeZoneId: 'America/New_York',
  },
  '21106': {
    zip: '21106',
    city: 'Mayo',
    state: 'MD',
    latitude: '38.879899',
    longitude: '-76.507456',
    timeZoneId: 'America/New_York',
  },
  '21108': {
    zip: '21108',
    city: 'Millersville',
    state: 'MD',
    latitude: '39.094433',
    longitude: '-76.621588',
    timeZoneId: 'America/New_York',
  },
  '21111': {
    zip: '21111',
    city: 'Monkton',
    state: 'MD',
    latitude: '39.56994',
    longitude: '-76.59288',
    timeZoneId: 'America/New_York',
  },
  '21113': {
    zip: '21113',
    city: 'Odenton',
    state: 'MD',
    latitude: '39.0728',
    longitude: '-76.700643',
    timeZoneId: 'America/New_York',
  },
  '21114': {
    zip: '21114',
    city: 'Crofton',
    state: 'MD',
    latitude: '39.010382',
    longitude: '-76.681142',
    timeZoneId: 'America/New_York',
  },
  '21117': {
    zip: '21117',
    city: 'Owings Mills',
    state: 'MD',
    latitude: '39.428406',
    longitude: '-76.769758',
    timeZoneId: 'America/New_York',
  },
  '21120': {
    zip: '21120',
    city: 'Parkton',
    state: 'MD',
    latitude: '39.639703',
    longitude: '-76.68275',
    timeZoneId: 'America/New_York',
  },
  '21122': {
    zip: '21122',
    city: 'Pasadena',
    state: 'MD',
    latitude: '39.127599',
    longitude: '-76.512544',
    timeZoneId: 'America/New_York',
  },
  '21123': {
    zip: '21123',
    city: 'Pasadena',
    state: 'MD',
    latitude: '39.111974',
    longitude: '-76.553563',
    timeZoneId: 'America/New_York',
  },
  '21128': {
    zip: '21128',
    city: 'Perry Hall',
    state: 'MD',
    latitude: '39.401633',
    longitude: '-76.4461',
    timeZoneId: 'America/New_York',
  },
  '21130': {
    zip: '21130',
    city: 'Perryman',
    state: 'MD',
    latitude: '39.470616',
    longitude: '-76.200436',
    timeZoneId: 'America/New_York',
  },
  '21131': {
    zip: '21131',
    city: 'Phoenix',
    state: 'MD',
    latitude: '39.501074',
    longitude: '-76.573561',
    timeZoneId: 'America/New_York',
  },
  '21132': {
    zip: '21132',
    city: 'Pylesville',
    state: 'MD',
    latitude: '39.696302',
    longitude: '-76.432946',
    timeZoneId: 'America/New_York',
  },
  '21133': {
    zip: '21133',
    city: 'Randallstown',
    state: 'MD',
    latitude: '39.369846',
    longitude: '-76.802416',
    timeZoneId: 'America/New_York',
  },
  '21136': {
    zip: '21136',
    city: 'Reisterstown',
    state: 'MD',
    latitude: '39.465347',
    longitude: '-76.80977',
    timeZoneId: 'America/New_York',
  },
  '21139': {
    zip: '21139',
    city: 'Riderwood',
    state: 'MD',
    latitude: '39.407324',
    longitude: '-76.648486',
    timeZoneId: 'America/New_York',
  },
  '21140': {
    zip: '21140',
    city: 'Riva',
    state: 'MD',
    latitude: '38.951835',
    longitude: '-76.586544',
    timeZoneId: 'America/New_York',
  },
  '21144': {
    zip: '21144',
    city: 'Severn',
    state: 'MD',
    latitude: '39.13128',
    longitude: '-76.68988',
    timeZoneId: 'America/New_York',
  },
  '21146': {
    zip: '21146',
    city: 'Severna Park',
    state: 'MD',
    latitude: '39.081683',
    longitude: '-76.565355',
    timeZoneId: 'America/New_York',
  },
  '21150': {
    zip: '21150',
    city: 'Simpsonville',
    state: 'MD',
    latitude: '39.174404',
    longitude: '-76.867087',
    timeZoneId: 'America/New_York',
  },
  '21152': {
    zip: '21152',
    city: 'Sparks Glencoe',
    state: 'MD',
    latitude: '39.54324',
    longitude: '-76.68282',
    timeZoneId: 'America/New_York',
  },
  '21153': {
    zip: '21153',
    city: 'Stevenson',
    state: 'MD',
    latitude: '39.419263',
    longitude: '-76.712485',
    timeZoneId: 'America/New_York',
  },
  '21154': {
    zip: '21154',
    city: 'Street',
    state: 'MD',
    latitude: '39.65292',
    longitude: '-76.37682',
    timeZoneId: 'America/New_York',
  },
  '21155': {
    zip: '21155',
    city: 'Upperco',
    state: 'MD',
    latitude: '39.565851',
    longitude: '-76.800573',
    timeZoneId: 'America/New_York',
  },
  '21156': {
    zip: '21156',
    city: 'Upper Falls',
    state: 'MD',
    latitude: '39.436501',
    longitude: '-76.399072',
    timeZoneId: 'America/New_York',
  },
  '21157': {
    zip: '21157',
    city: 'Westminster',
    state: 'MD',
    latitude: '39.55512',
    longitude: '-76.99056',
    timeZoneId: 'America/New_York',
  },
  '21158': {
    zip: '21158',
    city: 'Westminster',
    state: 'MD',
    latitude: '39.64038',
    longitude: '-77.02968',
    timeZoneId: 'America/New_York',
  },
  '21160': {
    zip: '21160',
    city: 'Whiteford',
    state: 'MD',
    latitude: '39.706514',
    longitude: '-76.316709',
    timeZoneId: 'America/New_York',
  },
  '21161': {
    zip: '21161',
    city: 'White Hall',
    state: 'MD',
    latitude: '39.65144',
    longitude: '-76.57149',
    timeZoneId: 'America/New_York',
  },
  '21162': {
    zip: '21162',
    city: 'White Marsh',
    state: 'MD',
    latitude: '39.395111',
    longitude: '-76.411347',
    timeZoneId: 'America/New_York',
  },
  '21163': {
    zip: '21163',
    city: 'Woodstock',
    state: 'MD',
    latitude: '39.34116',
    longitude: '-76.85994',
    timeZoneId: 'America/New_York',
  },
  '21201': {
    zip: '21201',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.294917',
    longitude: '-76.624885',
    timeZoneId: 'America/New_York',
  },
  '21202': {
    zip: '21202',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.295835',
    longitude: '-76.609089',
    timeZoneId: 'America/New_York',
  },
  '21203': {
    zip: '21203',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.297206',
    longitude: '-76.607966',
    timeZoneId: 'America/New_York',
  },
  '21204': {
    zip: '21204',
    city: 'Towson',
    state: 'MD',
    latitude: '39.40409',
    longitude: '-76.608279',
    timeZoneId: 'America/New_York',
  },
  '21205': {
    zip: '21205',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.304502',
    longitude: '-76.567527',
    timeZoneId: 'America/New_York',
  },
  '21206': {
    zip: '21206',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.341591',
    longitude: '-76.530778',
    timeZoneId: 'America/New_York',
  },
  '21207': {
    zip: '21207',
    city: 'Gwynn Oak',
    state: 'MD',
    latitude: '39.32382',
    longitude: '-76.71426',
    timeZoneId: 'America/New_York',
  },
  '21208': {
    zip: '21208',
    city: 'Pikesville',
    state: 'MD',
    latitude: '39.37764',
    longitude: '-76.70598',
    timeZoneId: 'America/New_York',
  },
  '21209': {
    zip: '21209',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.3705',
    longitude: '-76.69446',
    timeZoneId: 'America/New_York',
  },
  '21210': {
    zip: '21210',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.35436',
    longitude: '-76.635912',
    timeZoneId: 'America/New_York',
  },
  '21211': {
    zip: '21211',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.330421',
    longitude: '-76.637183',
    timeZoneId: 'America/New_York',
  },
  '21212': {
    zip: '21212',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.3678',
    longitude: '-76.61856',
    timeZoneId: 'America/New_York',
  },
  '21213': {
    zip: '21213',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.31252',
    longitude: '-76.58345',
    timeZoneId: 'America/New_York',
  },
  '21214': {
    zip: '21214',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.35409',
    longitude: '-76.56084',
    timeZoneId: 'America/New_York',
  },
  '21215': {
    zip: '21215',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.346278',
    longitude: '-76.68128',
    timeZoneId: 'America/New_York',
  },
  '21216': {
    zip: '21216',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.308329',
    longitude: '-76.67718',
    timeZoneId: 'America/New_York',
  },
  '21217': {
    zip: '21217',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.307911',
    longitude: '-76.637572',
    timeZoneId: 'America/New_York',
  },
  '21218': {
    zip: '21218',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.325256',
    longitude: '-76.605131',
    timeZoneId: 'America/New_York',
  },
  '21219': {
    zip: '21219',
    city: 'Sparrows Point',
    state: 'MD',
    latitude: '39.250556',
    longitude: '-76.520833',
    timeZoneId: 'America/New_York',
  },
  '21220': {
    zip: '21220',
    city: 'Middle River',
    state: 'MD',
    latitude: '39.342626',
    longitude: '-76.412643',
    timeZoneId: 'America/New_York',
  },
  '21221': {
    zip: '21221',
    city: 'Essex',
    state: 'MD',
    latitude: '39.29484',
    longitude: '-76.43652',
    timeZoneId: 'America/New_York',
  },
  '21222': {
    zip: '21222',
    city: 'Dundalk',
    state: 'MD',
    latitude: '39.26302',
    longitude: '-76.499604',
    timeZoneId: 'America/New_York',
  },
  '21223': {
    zip: '21223',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.28026',
    longitude: '-76.65054',
    timeZoneId: 'America/New_York',
  },
  '21224': {
    zip: '21224',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.28068',
    longitude: '-76.53798',
    timeZoneId: 'America/New_York',
  },
  '21225': {
    zip: '21225',
    city: 'Brooklyn',
    state: 'MD',
    latitude: '39.2304',
    longitude: '-76.61736',
    timeZoneId: 'America/New_York',
  },
  '21226': {
    zip: '21226',
    city: 'Curtis Bay',
    state: 'MD',
    latitude: '39.200554',
    longitude: '-76.554316',
    timeZoneId: 'America/New_York',
  },
  '21227': {
    zip: '21227',
    city: 'Halethorpe',
    state: 'MD',
    latitude: '39.25254',
    longitude: '-76.69638',
    timeZoneId: 'America/New_York',
  },
  '21228': {
    zip: '21228',
    city: 'Catonsville',
    state: 'MD',
    latitude: '39.3279',
    longitude: '-76.74396',
    timeZoneId: 'America/New_York',
  },
  '21229': {
    zip: '21229',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.284902',
    longitude: '-76.689882',
    timeZoneId: 'America/New_York',
  },
  '21230': {
    zip: '21230',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.270522',
    longitude: '-76.626796',
    timeZoneId: 'America/New_York',
  },
  '21231': {
    zip: '21231',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.290195',
    longitude: '-76.593956',
    timeZoneId: 'America/New_York',
  },
  '21233': {
    zip: '21233',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.293438',
    longitude: '-76.605554',
    timeZoneId: 'America/New_York',
  },
  '21234': {
    zip: '21234',
    city: 'Parkville',
    state: 'MD',
    latitude: '39.391101',
    longitude: '-76.535369',
    timeZoneId: 'America/New_York',
  },
  '21235': {
    zip: '21235',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.309564',
    longitude: '-76.728397',
    timeZoneId: 'America/New_York',
  },
  '21236': {
    zip: '21236',
    city: 'Nottingham',
    state: 'MD',
    latitude: '39.389419',
    longitude: '-76.488137',
    timeZoneId: 'America/New_York',
  },
  '21237': {
    zip: '21237',
    city: 'Rosedale',
    state: 'MD',
    latitude: '39.34032',
    longitude: '-76.5051',
    timeZoneId: 'America/New_York',
  },
  '21239': {
    zip: '21239',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.358614',
    longitude: '-76.590884',
    timeZoneId: 'America/New_York',
  },
  '21240': {
    zip: '21240',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.175794',
    longitude: '-76.666332',
    timeZoneId: 'America/New_York',
  },
  '21241': {
    zip: '21241',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.303353',
    longitude: '-76.733353',
    timeZoneId: 'America/New_York',
  },
  '21244': {
    zip: '21244',
    city: 'Windsor Mill',
    state: 'MD',
    latitude: '39.3325',
    longitude: '-76.693056',
    timeZoneId: 'America/New_York',
  },
  '21250': {
    zip: '21250',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.252457',
    longitude: '-76.71121',
    timeZoneId: 'America/New_York',
  },
  '21251': {
    zip: '21251',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.341206',
    longitude: '-76.585933',
    timeZoneId: 'America/New_York',
  },
  '21252': {
    zip: '21252',
    city: 'Towson',
    state: 'MD',
    latitude: '39.394959',
    longitude: '-76.612936',
    timeZoneId: 'America/New_York',
  },
  '21263': {
    zip: '21263',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.29055',
    longitude: '-76.609596',
    timeZoneId: 'America/New_York',
  },
  '21264': {
    zip: '21264',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.29055',
    longitude: '-76.609596',
    timeZoneId: 'America/New_York',
  },
  '21270': {
    zip: '21270',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.359071',
    longitude: '-76.70477',
    timeZoneId: 'America/New_York',
  },
  '21273': {
    zip: '21273',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.287419',
    longitude: '-76.611265',
    timeZoneId: 'America/New_York',
  },
  '21275': {
    zip: '21275',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.287029',
    longitude: '-76.61413',
    timeZoneId: 'America/New_York',
  },
  '21278': {
    zip: '21278',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.316363',
    longitude: '-76.614724',
    timeZoneId: 'America/New_York',
  },
  '21279': {
    zip: '21279',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.296632',
    longitude: '-76.608209',
    timeZoneId: 'America/New_York',
  },
  '21281': {
    zip: '21281',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.290875',
    longitude: '-76.567741',
    timeZoneId: 'America/New_York',
  },
  '21282': {
    zip: '21282',
    city: 'Pikesville',
    state: 'MD',
    latitude: '39.3871',
    longitude: '-76.72283',
    timeZoneId: 'America/New_York',
  },
  '21284': {
    zip: '21284',
    city: 'Towson',
    state: 'MD',
    latitude: '39.399688',
    longitude: '-76.5992',
    timeZoneId: 'America/New_York',
  },
  '21285': {
    zip: '21285',
    city: 'Towson',
    state: 'MD',
    latitude: '39.407938',
    longitude: '-76.597911',
    timeZoneId: 'America/New_York',
  },
  '21286': {
    zip: '21286',
    city: 'Towson',
    state: 'MD',
    latitude: '39.406546',
    longitude: '-76.581702',
    timeZoneId: 'America/New_York',
  },
  '21287': {
    zip: '21287',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.304023',
    longitude: '-76.562557',
    timeZoneId: 'America/New_York',
  },
  '21289': {
    zip: '21289',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.312384',
    longitude: '-76.727934',
    timeZoneId: 'America/New_York',
  },
  '21290': {
    zip: '21290',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.29282',
    longitude: '-76.62379',
    timeZoneId: 'America/New_York',
  },
  '21297': {
    zip: '21297',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.295383',
    longitude: '-76.609172',
    timeZoneId: 'America/New_York',
  },
  '21298': {
    zip: '21298',
    city: 'Baltimore',
    state: 'MD',
    latitude: '39.29778',
    longitude: '-76.608993',
    timeZoneId: 'America/New_York',
  },
  '21401': {
    zip: '21401',
    city: 'Annapolis',
    state: 'MD',
    latitude: '39.002488',
    longitude: '-76.510357',
    timeZoneId: 'America/New_York',
  },
  '21402': {
    zip: '21402',
    city: 'Annapolis',
    state: 'MD',
    latitude: '38.987681',
    longitude: '-76.487016',
    timeZoneId: 'America/New_York',
  },
  '21403': {
    zip: '21403',
    city: 'Annapolis',
    state: 'MD',
    latitude: '38.949357',
    longitude: '-76.488902',
    timeZoneId: 'America/New_York',
  },
  '21404': {
    zip: '21404',
    city: 'Annapolis',
    state: 'MD',
    latitude: '38.977778',
    longitude: '-76.498291',
    timeZoneId: 'America/New_York',
  },
  '21405': {
    zip: '21405',
    city: 'Annapolis',
    state: 'MD',
    latitude: '38.978333',
    longitude: '-76.4925',
    timeZoneId: 'America/New_York',
  },
  '21409': {
    zip: '21409',
    city: 'Annapolis',
    state: 'MD',
    latitude: '39.0221',
    longitude: '-76.450111',
    timeZoneId: 'America/New_York',
  },
  '21411': {
    zip: '21411',
    city: 'Annapolis',
    state: 'MD',
    latitude: '38.971614',
    longitude: '-76.499864',
    timeZoneId: 'America/New_York',
  },
  '21412': {
    zip: '21412',
    city: 'Annapolis',
    state: 'MD',
    latitude: '39.122086',
    longitude: '-76.529331',
    timeZoneId: 'America/New_York',
  },
  '21501': {
    zip: '21501',
    city: 'Cumberland',
    state: 'MD',
    latitude: '39.649676',
    longitude: '-78.757756',
    timeZoneId: 'America/New_York',
  },
  '21502': {
    zip: '21502',
    city: 'Cumberland',
    state: 'MD',
    latitude: '39.596959',
    longitude: '-78.834511',
    timeZoneId: 'America/New_York',
  },
  '21503': {
    zip: '21503',
    city: 'Cumberland',
    state: 'MD',
    latitude: '39.647079',
    longitude: '-78.759684',
    timeZoneId: 'America/New_York',
  },
  '21504': {
    zip: '21504',
    city: 'Cumberland',
    state: 'MD',
    latitude: '39.660766',
    longitude: '-78.74317',
    timeZoneId: 'America/New_York',
  },
  '21505': {
    zip: '21505',
    city: 'Cumberland',
    state: 'MD',
    latitude: '39.658048',
    longitude: '-78.746255',
    timeZoneId: 'America/New_York',
  },
  '21520': {
    zip: '21520',
    city: 'Accident',
    state: 'MD',
    latitude: '39.643308',
    longitude: '-79.309802',
    timeZoneId: 'America/New_York',
  },
  '21521': {
    zip: '21521',
    city: 'Barton',
    state: 'MD',
    latitude: '39.551923',
    longitude: '-79.080111',
    timeZoneId: 'America/New_York',
  },
  '21522': {
    zip: '21522',
    city: 'Bittinger',
    state: 'MD',
    latitude: '39.588111',
    longitude: '-79.20277',
    timeZoneId: 'America/New_York',
  },
  '21523': {
    zip: '21523',
    city: 'Bloomington',
    state: 'MD',
    latitude: '39.504793',
    longitude: '-79.115289',
    timeZoneId: 'America/New_York',
  },
  '21524': {
    zip: '21524',
    city: 'Corriganville',
    state: 'MD',
    latitude: '39.692576',
    longitude: '-78.793467',
    timeZoneId: 'America/New_York',
  },
  '21528': {
    zip: '21528',
    city: 'Eckhart Mines',
    state: 'MD',
    latitude: '39.650221',
    longitude: '-78.905479',
    timeZoneId: 'America/New_York',
  },
  '21529': {
    zip: '21529',
    city: 'Ellerslie',
    state: 'MD',
    latitude: '39.718506',
    longitude: '-78.777773',
    timeZoneId: 'America/New_York',
  },
  '21530': {
    zip: '21530',
    city: 'Flintstone',
    state: 'MD',
    latitude: '39.689276',
    longitude: '-78.698799',
    timeZoneId: 'America/New_York',
  },
  '21531': {
    zip: '21531',
    city: 'Friendsville',
    state: 'MD',
    latitude: '39.660306',
    longitude: '-79.405427',
    timeZoneId: 'America/New_York',
  },
  '21532': {
    zip: '21532',
    city: 'Frostburg',
    state: 'MD',
    latitude: '39.655702',
    longitude: '-78.929019',
    timeZoneId: 'America/New_York',
  },
  '21536': {
    zip: '21536',
    city: 'Grantsville',
    state: 'MD',
    latitude: '39.67668',
    longitude: '-79.15392',
    timeZoneId: 'America/New_York',
  },
  '21538': {
    zip: '21538',
    city: 'Kitzmiller',
    state: 'MD',
    latitude: '39.379444',
    longitude: '-79.205',
    timeZoneId: 'America/New_York',
  },
  '21539': {
    zip: '21539',
    city: 'Lonaconing',
    state: 'MD',
    latitude: '39.59668',
    longitude: '-79.013567',
    timeZoneId: 'America/New_York',
  },
  '21540': {
    zip: '21540',
    city: 'Luke',
    state: 'MD',
    latitude: '39.479099',
    longitude: '-79.055699',
    timeZoneId: 'America/New_York',
  },
  '21541': {
    zip: '21541',
    city: 'Mc Henry',
    state: 'MD',
    latitude: '39.556671',
    longitude: '-79.35098',
    timeZoneId: 'America/New_York',
  },
  '21542': {
    zip: '21542',
    city: 'Midland',
    state: 'MD',
    latitude: '39.58897',
    longitude: '-78.950252',
    timeZoneId: 'America/New_York',
  },
  '21543': {
    zip: '21543',
    city: 'Midlothian',
    state: 'MD',
    latitude: '39.632075',
    longitude: '-78.946487',
    timeZoneId: 'America/New_York',
  },
  '21545': {
    zip: '21545',
    city: 'Mount Savage',
    state: 'MD',
    latitude: '39.697088',
    longitude: '-78.879519',
    timeZoneId: 'America/New_York',
  },
  '21550': {
    zip: '21550',
    city: 'Oakland',
    state: 'MD',
    latitude: '39.33792',
    longitude: '-79.40232',
    timeZoneId: 'America/New_York',
  },
  '21555': {
    zip: '21555',
    city: 'Oldtown',
    state: 'MD',
    latitude: '39.595816',
    longitude: '-78.560611',
    timeZoneId: 'America/New_York',
  },
  '21556': {
    zip: '21556',
    city: 'Pinto',
    state: 'MD',
    latitude: '39.569323',
    longitude: '-78.842825',
    timeZoneId: 'America/New_York',
  },
  '21557': {
    zip: '21557',
    city: 'Rawlings',
    state: 'MD',
    latitude: '39.516721',
    longitude: '-78.90614',
    timeZoneId: 'America/New_York',
  },
  '21560': {
    zip: '21560',
    city: 'Spring Gap',
    state: 'MD',
    latitude: '39.563513',
    longitude: '-78.716162',
    timeZoneId: 'America/New_York',
  },
  '21561': {
    zip: '21561',
    city: 'Swanton',
    state: 'MD',
    latitude: '39.496269',
    longitude: '-79.261292',
    timeZoneId: 'America/New_York',
  },
  '21562': {
    zip: '21562',
    city: 'Westernport',
    state: 'MD',
    latitude: '39.446389',
    longitude: '-78.9725',
    timeZoneId: 'America/New_York',
  },
  '21601': {
    zip: '21601',
    city: 'Easton',
    state: 'MD',
    latitude: '38.773948',
    longitude: '-76.06908',
    timeZoneId: 'America/New_York',
  },
  '21607': {
    zip: '21607',
    city: 'Barclay',
    state: 'MD',
    latitude: '39.120416',
    longitude: '-75.843492',
    timeZoneId: 'America/New_York',
  },
  '21609': {
    zip: '21609',
    city: 'Bethlehem',
    state: 'MD',
    latitude: '38.740325',
    longitude: '-75.952024',
    timeZoneId: 'America/New_York',
  },
  '21610': {
    zip: '21610',
    city: 'Betterton',
    state: 'MD',
    latitude: '39.37008',
    longitude: '-76.065963',
    timeZoneId: 'America/New_York',
  },
  '21612': {
    zip: '21612',
    city: 'Bozman',
    state: 'MD',
    latitude: '38.753587',
    longitude: '-76.29784',
    timeZoneId: 'America/New_York',
  },
  '21613': {
    zip: '21613',
    city: 'Cambridge',
    state: 'MD',
    latitude: '38.553713',
    longitude: '-76.094747',
    timeZoneId: 'America/New_York',
  },
  '21617': {
    zip: '21617',
    city: 'Centreville',
    state: 'MD',
    latitude: '39.043882',
    longitude: '-76.0659',
    timeZoneId: 'America/New_York',
  },
  '21619': {
    zip: '21619',
    city: 'Chester',
    state: 'MD',
    latitude: '38.968876',
    longitude: '-76.291949',
    timeZoneId: 'America/New_York',
  },
  '21620': {
    zip: '21620',
    city: 'Chestertown',
    state: 'MD',
    latitude: '39.204983',
    longitude: '-76.131512',
    timeZoneId: 'America/New_York',
  },
  '21622': {
    zip: '21622',
    city: 'Church Creek',
    state: 'MD',
    latitude: '38.446534',
    longitude: '-76.163544',
    timeZoneId: 'America/New_York',
  },
  '21623': {
    zip: '21623',
    city: 'Church Hill',
    state: 'MD',
    latitude: '39.107562',
    longitude: '-75.970112',
    timeZoneId: 'America/New_York',
  },
  '21624': {
    zip: '21624',
    city: 'Claiborne',
    state: 'MD',
    latitude: '38.785',
    longitude: '-76.224722',
    timeZoneId: 'America/New_York',
  },
  '21625': {
    zip: '21625',
    city: 'Cordova',
    state: 'MD',
    latitude: '38.864311',
    longitude: '-75.998898',
    timeZoneId: 'America/New_York',
  },
  '21626': {
    zip: '21626',
    city: 'Crapo',
    state: 'MD',
    latitude: '38.324307',
    longitude: '-76.111506',
    timeZoneId: 'America/New_York',
  },
  '21627': {
    zip: '21627',
    city: 'Crocheron',
    state: 'MD',
    latitude: '38.23777',
    longitude: '-76.050194',
    timeZoneId: 'America/New_York',
  },
  '21628': {
    zip: '21628',
    city: 'Crumpton',
    state: 'MD',
    latitude: '39.237149',
    longitude: '-75.920428',
    timeZoneId: 'America/New_York',
  },
  '21629': {
    zip: '21629',
    city: 'Denton',
    state: 'MD',
    latitude: '38.87675',
    longitude: '-75.831327',
    timeZoneId: 'America/New_York',
  },
  '21631': {
    zip: '21631',
    city: 'East New Market',
    state: 'MD',
    latitude: '38.596813',
    longitude: '-75.935069',
    timeZoneId: 'America/New_York',
  },
  '21632': {
    zip: '21632',
    city: 'Federalsburg',
    state: 'MD',
    latitude: '38.706568',
    longitude: '-75.775985',
    timeZoneId: 'America/New_York',
  },
  '21634': {
    zip: '21634',
    city: 'Fishing Creek',
    state: 'MD',
    latitude: '38.2995',
    longitude: '-76.2048',
    timeZoneId: 'America/New_York',
  },
  '21635': {
    zip: '21635',
    city: 'Galena',
    state: 'MD',
    latitude: '39.335931',
    longitude: '-75.867837',
    timeZoneId: 'America/New_York',
  },
  '21636': {
    zip: '21636',
    city: 'Goldsboro',
    state: 'MD',
    latitude: '39.03314',
    longitude: '-75.78788',
    timeZoneId: 'America/New_York',
  },
  '21638': {
    zip: '21638',
    city: 'Grasonville',
    state: 'MD',
    latitude: '38.94037',
    longitude: '-76.189363',
    timeZoneId: 'America/New_York',
  },
  '21639': {
    zip: '21639',
    city: 'Greensboro',
    state: 'MD',
    latitude: '38.975235',
    longitude: '-75.808602',
    timeZoneId: 'America/New_York',
  },
  '21640': {
    zip: '21640',
    city: 'Henderson',
    state: 'MD',
    latitude: '39.068537',
    longitude: '-75.814675',
    timeZoneId: 'America/New_York',
  },
  '21641': {
    zip: '21641',
    city: 'Hillsboro',
    state: 'MD',
    latitude: '38.91665',
    longitude: '-75.943316',
    timeZoneId: 'America/New_York',
  },
  '21643': {
    zip: '21643',
    city: 'Hurlock',
    state: 'MD',
    latitude: '38.640404',
    longitude: '-75.867217',
    timeZoneId: 'America/New_York',
  },
  '21644': {
    zip: '21644',
    city: 'Ingleside',
    state: 'MD',
    latitude: '39.128407',
    longitude: '-75.8814',
    timeZoneId: 'America/New_York',
  },
  '21645': {
    zip: '21645',
    city: 'Kennedyville',
    state: 'MD',
    latitude: '39.303304',
    longitude: '-75.961244',
    timeZoneId: 'America/New_York',
  },
  '21647': {
    zip: '21647',
    city: 'McDaniel',
    state: 'MD',
    latitude: '38.817195',
    longitude: '-76.290465',
    timeZoneId: 'America/New_York',
  },
  '21648': {
    zip: '21648',
    city: 'Madison',
    state: 'MD',
    latitude: '38.501857',
    longitude: '-76.229213',
    timeZoneId: 'America/New_York',
  },
  '21649': {
    zip: '21649',
    city: 'Marydel',
    state: 'MD',
    latitude: '39.109101',
    longitude: '-75.767492',
    timeZoneId: 'America/New_York',
  },
  '21650': {
    zip: '21650',
    city: 'Massey',
    state: 'MD',
    latitude: '39.310905',
    longitude: '-75.820082',
    timeZoneId: 'America/New_York',
  },
  '21651': {
    zip: '21651',
    city: 'Millington',
    state: 'MD',
    latitude: '39.257385',
    longitude: '-75.837093',
    timeZoneId: 'America/New_York',
  },
  '21652': {
    zip: '21652',
    city: 'Neavitt',
    state: 'MD',
    latitude: '38.736603',
    longitude: '-76.292982',
    timeZoneId: 'America/New_York',
  },
  '21653': {
    zip: '21653',
    city: 'Newcomb',
    state: 'MD',
    latitude: '38.787178',
    longitude: '-76.224932',
    timeZoneId: 'America/New_York',
  },
  '21654': {
    zip: '21654',
    city: 'Oxford',
    state: 'MD',
    latitude: '38.686228',
    longitude: '-76.169503',
    timeZoneId: 'America/New_York',
  },
  '21655': {
    zip: '21655',
    city: 'Preston',
    state: 'MD',
    latitude: '38.734621',
    longitude: '-75.904802',
    timeZoneId: 'America/New_York',
  },
  '21656': {
    zip: '21656',
    city: 'Price',
    state: 'MD',
    latitude: '39.0975',
    longitude: '-75.959167',
    timeZoneId: 'America/New_York',
  },
  '21657': {
    zip: '21657',
    city: 'Queen Anne',
    state: 'MD',
    latitude: '38.956946',
    longitude: '-75.993085',
    timeZoneId: 'America/New_York',
  },
  '21658': {
    zip: '21658',
    city: 'Queenstown',
    state: 'MD',
    latitude: '38.9644',
    longitude: '-76.123558',
    timeZoneId: 'America/New_York',
  },
  '21659': {
    zip: '21659',
    city: 'Rhodesdale',
    state: 'MD',
    latitude: '38.575555',
    longitude: '-75.815263',
    timeZoneId: 'America/New_York',
  },
  '21660': {
    zip: '21660',
    city: 'Ridgely',
    state: 'MD',
    latitude: '38.942711',
    longitude: '-75.890372',
    timeZoneId: 'America/New_York',
  },
  '21661': {
    zip: '21661',
    city: 'Rock Hall',
    state: 'MD',
    latitude: '39.137898',
    longitude: '-76.231798',
    timeZoneId: 'America/New_York',
  },
  '21662': {
    zip: '21662',
    city: 'Royal Oak',
    state: 'MD',
    latitude: '38.712959',
    longitude: '-76.197379',
    timeZoneId: 'America/New_York',
  },
  '21663': {
    zip: '21663',
    city: 'Saint Michaels',
    state: 'MD',
    latitude: '38.7881',
    longitude: '-76.225239',
    timeZoneId: 'America/New_York',
  },
  '21664': {
    zip: '21664',
    city: 'Secretary',
    state: 'MD',
    latitude: '38.609098',
    longitude: '-75.949023',
    timeZoneId: 'America/New_York',
  },
  '21665': {
    zip: '21665',
    city: 'Sherwood',
    state: 'MD',
    latitude: '38.741644',
    longitude: '-76.324831',
    timeZoneId: 'America/New_York',
  },
  '21666': {
    zip: '21666',
    city: 'Stevensville',
    state: 'MD',
    latitude: '38.932349',
    longitude: '-76.336467',
    timeZoneId: 'America/New_York',
  },
  '21667': {
    zip: '21667',
    city: 'Still Pond',
    state: 'MD',
    latitude: '39.328122',
    longitude: '-76.054372',
    timeZoneId: 'America/New_York',
  },
  '21668': {
    zip: '21668',
    city: 'Sudlersville',
    state: 'MD',
    latitude: '39.197496',
    longitude: '-75.855542',
    timeZoneId: 'America/New_York',
  },
  '21669': {
    zip: '21669',
    city: 'Taylors Island',
    state: 'MD',
    latitude: '38.472517',
    longitude: '-76.27618',
    timeZoneId: 'America/New_York',
  },
  '21670': {
    zip: '21670',
    city: 'Templeville',
    state: 'MD',
    latitude: '39.135447',
    longitude: '-75.767885',
    timeZoneId: 'America/New_York',
  },
  '21671': {
    zip: '21671',
    city: 'Tilghman',
    state: 'MD',
    latitude: '38.694278',
    longitude: '-76.335784',
    timeZoneId: 'America/New_York',
  },
  '21672': {
    zip: '21672',
    city: 'Toddville',
    state: 'MD',
    latitude: '38.27082',
    longitude: '-76.065987',
    timeZoneId: 'America/New_York',
  },
  '21673': {
    zip: '21673',
    city: 'Trappe',
    state: 'MD',
    latitude: '38.65552',
    longitude: '-76.057179',
    timeZoneId: 'America/New_York',
  },
  '21675': {
    zip: '21675',
    city: 'Wingate',
    state: 'MD',
    latitude: '38.291143',
    longitude: '-76.091675',
    timeZoneId: 'America/New_York',
  },
  '21676': {
    zip: '21676',
    city: 'Wittman',
    state: 'MD',
    latitude: '38.798765',
    longitude: '-76.283498',
    timeZoneId: 'America/New_York',
  },
  '21677': {
    zip: '21677',
    city: 'Woolford',
    state: 'MD',
    latitude: '38.51223',
    longitude: '-76.18723',
    timeZoneId: 'America/New_York',
  },
  '21678': {
    zip: '21678',
    city: 'Worton',
    state: 'MD',
    latitude: '39.299035',
    longitude: '-76.108948',
    timeZoneId: 'America/New_York',
  },
  '21679': {
    zip: '21679',
    city: 'Wye Mills',
    state: 'MD',
    latitude: '38.880954',
    longitude: '-75.830894',
    timeZoneId: 'America/New_York',
  },
  '21690': {
    zip: '21690',
    city: 'Chestertown',
    state: 'MD',
    latitude: '39.21889',
    longitude: '-76.071011',
    timeZoneId: 'America/New_York',
  },
  '21701': {
    zip: '21701',
    city: 'Frederick',
    state: 'MD',
    latitude: '39.41832',
    longitude: '-77.33616',
    timeZoneId: 'America/New_York',
  },
  '21702': {
    zip: '21702',
    city: 'Frederick',
    state: 'MD',
    latitude: '39.4944',
    longitude: '-77.45352',
    timeZoneId: 'America/New_York',
  },
  '21703': {
    zip: '21703',
    city: 'Frederick',
    state: 'MD',
    latitude: '39.366631',
    longitude: '-77.464397',
    timeZoneId: 'America/New_York',
  },
  '21704': {
    zip: '21704',
    city: 'Frederick',
    state: 'MD',
    latitude: '39.373967',
    longitude: '-77.390459',
    timeZoneId: 'America/New_York',
  },
  '21705': {
    zip: '21705',
    city: 'Frederick',
    state: 'MD',
    latitude: '39.412463',
    longitude: '-77.40738',
    timeZoneId: 'America/New_York',
  },
  '21709': {
    zip: '21709',
    city: 'Frederick',
    state: 'MD',
    latitude: '39.427762',
    longitude: '-77.415493',
    timeZoneId: 'America/New_York',
  },
  '21710': {
    zip: '21710',
    city: 'Adamstown',
    state: 'MD',
    latitude: '39.29154',
    longitude: '-77.45814',
    timeZoneId: 'America/New_York',
  },
  '21711': {
    zip: '21711',
    city: 'Big Pool',
    state: 'MD',
    latitude: '39.670565',
    longitude: '-78.004589',
    timeZoneId: 'America/New_York',
  },
  '21713': {
    zip: '21713',
    city: 'Boonsboro',
    state: 'MD',
    latitude: '39.524532',
    longitude: '-77.669246',
    timeZoneId: 'America/New_York',
  },
  '21714': {
    zip: '21714',
    city: 'Braddock Heights',
    state: 'MD',
    latitude: '39.415134',
    longitude: '-77.505881',
    timeZoneId: 'America/New_York',
  },
  '21715': {
    zip: '21715',
    city: 'Brownsville',
    state: 'MD',
    latitude: '39.383845',
    longitude: '-77.659515',
    timeZoneId: 'America/New_York',
  },
  '21716': {
    zip: '21716',
    city: 'Brunswick',
    state: 'MD',
    latitude: '39.314478',
    longitude: '-77.622428',
    timeZoneId: 'America/New_York',
  },
  '21717': {
    zip: '21717',
    city: 'Buckeystown',
    state: 'MD',
    latitude: '39.314542',
    longitude: '-77.441873',
    timeZoneId: 'America/New_York',
  },
  '21718': {
    zip: '21718',
    city: 'Burkittsville',
    state: 'MD',
    latitude: '39.391756',
    longitude: '-77.631033',
    timeZoneId: 'America/New_York',
  },
  '21719': {
    zip: '21719',
    city: 'Cascade',
    state: 'MD',
    latitude: '39.709736',
    longitude: '-77.493313',
    timeZoneId: 'America/New_York',
  },
  '21720': {
    zip: '21720',
    city: 'Cavetown',
    state: 'MD',
    latitude: '39.642371',
    longitude: '-77.582852',
    timeZoneId: 'America/New_York',
  },
  '21721': {
    zip: '21721',
    city: 'Chewsville',
    state: 'MD',
    latitude: '39.648217',
    longitude: '-77.627585',
    timeZoneId: 'America/New_York',
  },
  '21722': {
    zip: '21722',
    city: 'Clear Spring',
    state: 'MD',
    latitude: '39.66498',
    longitude: '-77.90916',
    timeZoneId: 'America/New_York',
  },
  '21723': {
    zip: '21723',
    city: 'Cooksville',
    state: 'MD',
    latitude: '39.320349',
    longitude: '-77.008735',
    timeZoneId: 'America/New_York',
  },
  '21727': {
    zip: '21727',
    city: 'Emmitsburg',
    state: 'MD',
    latitude: '39.690794',
    longitude: '-77.32583',
    timeZoneId: 'America/New_York',
  },
  '21733': {
    zip: '21733',
    city: 'Fairplay',
    state: 'MD',
    latitude: '39.548142',
    longitude: '-77.762386',
    timeZoneId: 'America/New_York',
  },
  '21734': {
    zip: '21734',
    city: 'Funkstown',
    state: 'MD',
    latitude: '39.606917',
    longitude: '-77.708414',
    timeZoneId: 'America/New_York',
  },
  '21737': {
    zip: '21737',
    city: 'Glenelg',
    state: 'MD',
    latitude: '39.259403',
    longitude: '-77.002827',
    timeZoneId: 'America/New_York',
  },
  '21738': {
    zip: '21738',
    city: 'Glenwood',
    state: 'MD',
    latitude: '39.278191',
    longitude: '-77.016131',
    timeZoneId: 'America/New_York',
  },
  '21740': {
    zip: '21740',
    city: 'Hagerstown',
    state: 'MD',
    latitude: '39.643547',
    longitude: '-77.718382',
    timeZoneId: 'America/New_York',
  },
  '21741': {
    zip: '21741',
    city: 'Hagerstown',
    state: 'MD',
    latitude: '39.64139',
    longitude: '-77.718786',
    timeZoneId: 'America/New_York',
  },
  '21742': {
    zip: '21742',
    city: 'Hagerstown',
    state: 'MD',
    latitude: '39.657087',
    longitude: '-77.709741',
    timeZoneId: 'America/New_York',
  },
  '21746': {
    zip: '21746',
    city: 'Hagerstown',
    state: 'MD',
    latitude: '39.564137',
    longitude: '-77.718312',
    timeZoneId: 'America/New_York',
  },
  '21747': {
    zip: '21747',
    city: 'Hagerstown',
    state: 'MD',
    latitude: '39.634471',
    longitude: '-77.745963',
    timeZoneId: 'America/New_York',
  },
  '21749': {
    zip: '21749',
    city: 'Hagerstown',
    state: 'MD',
    latitude: '39.637367',
    longitude: '-77.744539',
    timeZoneId: 'America/New_York',
  },
  '21750': {
    zip: '21750',
    city: 'Hancock',
    state: 'MD',
    latitude: '39.703852',
    longitude: '-78.188237',
    timeZoneId: 'America/New_York',
  },
  '21754': {
    zip: '21754',
    city: 'Ijamsville',
    state: 'MD',
    latitude: '39.333698',
    longitude: '-77.300785',
    timeZoneId: 'America/New_York',
  },
  '21755': {
    zip: '21755',
    city: 'Jefferson',
    state: 'MD',
    latitude: '39.368939',
    longitude: '-77.565107',
    timeZoneId: 'America/New_York',
  },
  '21756': {
    zip: '21756',
    city: 'Keedysville',
    state: 'MD',
    latitude: '39.463189',
    longitude: '-77.695659',
    timeZoneId: 'America/New_York',
  },
  '21757': {
    zip: '21757',
    city: 'Keymar',
    state: 'MD',
    latitude: '39.601199',
    longitude: '-77.254452',
    timeZoneId: 'America/New_York',
  },
  '21758': {
    zip: '21758',
    city: 'Knoxville',
    state: 'MD',
    latitude: '39.341495',
    longitude: '-77.653907',
    timeZoneId: 'America/New_York',
  },
  '21759': {
    zip: '21759',
    city: 'Ladiesburg',
    state: 'MD',
    latitude: '39.575011',
    longitude: '-77.263916',
    timeZoneId: 'America/New_York',
  },
  '21762': {
    zip: '21762',
    city: 'Libertytown',
    state: 'MD',
    latitude: '39.483338',
    longitude: '-77.24859',
    timeZoneId: 'America/New_York',
  },
  '21765': {
    zip: '21765',
    city: 'Lisbon',
    state: 'MD',
    latitude: '39.33483',
    longitude: '-77.06811',
    timeZoneId: 'America/New_York',
  },
  '21766': {
    zip: '21766',
    city: 'Little Orleans',
    state: 'MD',
    latitude: '39.660121',
    longitude: '-78.402968',
    timeZoneId: 'America/New_York',
  },
  '21767': {
    zip: '21767',
    city: 'Maugansville',
    state: 'MD',
    latitude: '39.696351',
    longitude: '-77.746502',
    timeZoneId: 'America/New_York',
  },
  '21769': {
    zip: '21769',
    city: 'Middletown',
    state: 'MD',
    latitude: '39.43901',
    longitude: '-77.55148',
    timeZoneId: 'America/New_York',
  },
  '21770': {
    zip: '21770',
    city: 'Monrovia',
    state: 'MD',
    latitude: '39.351327',
    longitude: '-77.253345',
    timeZoneId: 'America/New_York',
  },
  '21771': {
    zip: '21771',
    city: 'Mount Airy',
    state: 'MD',
    latitude: '39.388389',
    longitude: '-77.148195',
    timeZoneId: 'America/New_York',
  },
  '21773': {
    zip: '21773',
    city: 'Myersville',
    state: 'MD',
    latitude: '39.530125',
    longitude: '-77.553669',
    timeZoneId: 'America/New_York',
  },
  '21774': {
    zip: '21774',
    city: 'New Market',
    state: 'MD',
    latitude: '39.40632',
    longitude: '-77.29638',
    timeZoneId: 'America/New_York',
  },
  '21775': {
    zip: '21775',
    city: 'New Midway',
    state: 'MD',
    latitude: '39.556217',
    longitude: '-77.302212',
    timeZoneId: 'America/New_York',
  },
  '21776': {
    zip: '21776',
    city: 'New Windsor',
    state: 'MD',
    latitude: '39.517882',
    longitude: '-77.109276',
    timeZoneId: 'America/New_York',
  },
  '21777': {
    zip: '21777',
    city: 'Point of Rocks',
    state: 'MD',
    latitude: '39.279637',
    longitude: '-77.532881',
    timeZoneId: 'America/New_York',
  },
  '21778': {
    zip: '21778',
    city: 'Rocky Ridge',
    state: 'MD',
    latitude: '39.61418',
    longitude: '-77.340644',
    timeZoneId: 'America/New_York',
  },
  '21779': {
    zip: '21779',
    city: 'Rohrersville',
    state: 'MD',
    latitude: '39.43815',
    longitude: '-77.659315',
    timeZoneId: 'America/New_York',
  },
  '21780': {
    zip: '21780',
    city: 'Sabillasville',
    state: 'MD',
    latitude: '39.677831',
    longitude: '-77.464773',
    timeZoneId: 'America/New_York',
  },
  '21781': {
    zip: '21781',
    city: 'Saint James',
    state: 'MD',
    latitude: '39.562227',
    longitude: '-77.759294',
    timeZoneId: 'America/New_York',
  },
  '21782': {
    zip: '21782',
    city: 'Sharpsburg',
    state: 'MD',
    latitude: '39.448815',
    longitude: '-77.7549',
    timeZoneId: 'America/New_York',
  },
  '21783': {
    zip: '21783',
    city: 'Smithsburg',
    state: 'MD',
    latitude: '39.610146',
    longitude: '-77.52616',
    timeZoneId: 'America/New_York',
  },
  '21784': {
    zip: '21784',
    city: 'Sykesville',
    state: 'MD',
    latitude: '39.338199',
    longitude: '-76.9723',
    timeZoneId: 'America/New_York',
  },
  '21787': {
    zip: '21787',
    city: 'Taneytown',
    state: 'MD',
    latitude: '39.678105',
    longitude: '-77.157129',
    timeZoneId: 'America/New_York',
  },
  '21788': {
    zip: '21788',
    city: 'Thurmont',
    state: 'MD',
    latitude: '39.60054',
    longitude: '-77.42916',
    timeZoneId: 'America/New_York',
  },
  '21790': {
    zip: '21790',
    city: 'Tuscarora',
    state: 'MD',
    latitude: '39.266735',
    longitude: '-77.501058',
    timeZoneId: 'America/New_York',
  },
  '21791': {
    zip: '21791',
    city: 'Union Bridge',
    state: 'MD',
    latitude: '39.544988',
    longitude: '-77.194309',
    timeZoneId: 'America/New_York',
  },
  '21792': {
    zip: '21792',
    city: 'Unionville',
    state: 'MD',
    latitude: '39.474806',
    longitude: '-77.187221',
    timeZoneId: 'America/New_York',
  },
  '21793': {
    zip: '21793',
    city: 'Walkersville',
    state: 'MD',
    latitude: '39.484762',
    longitude: '-77.345888',
    timeZoneId: 'America/New_York',
  },
  '21794': {
    zip: '21794',
    city: 'West Friendship',
    state: 'MD',
    latitude: '39.295986',
    longitude: '-76.972906',
    timeZoneId: 'America/New_York',
  },
  '21795': {
    zip: '21795',
    city: 'Williamsport',
    state: 'MD',
    latitude: '39.607324',
    longitude: '-77.800793',
    timeZoneId: 'America/New_York',
  },
  '21797': {
    zip: '21797',
    city: 'Woodbine',
    state: 'MD',
    latitude: '39.310263',
    longitude: '-77.066828',
    timeZoneId: 'America/New_York',
  },
  '21798': {
    zip: '21798',
    city: 'Woodsboro',
    state: 'MD',
    latitude: '39.531691',
    longitude: '-77.304607',
    timeZoneId: 'America/New_York',
  },
  '21801': {
    zip: '21801',
    city: 'Salisbury',
    state: 'MD',
    latitude: '38.362467',
    longitude: '-75.594499',
    timeZoneId: 'America/New_York',
  },
  '21802': {
    zip: '21802',
    city: 'Salisbury',
    state: 'MD',
    latitude: '38.340961',
    longitude: '-75.536031',
    timeZoneId: 'America/New_York',
  },
  '21803': {
    zip: '21803',
    city: 'Salisbury',
    state: 'MD',
    latitude: '38.36305',
    longitude: '-75.656824',
    timeZoneId: 'America/New_York',
  },
  '21804': {
    zip: '21804',
    city: 'Salisbury',
    state: 'MD',
    latitude: '38.355713',
    longitude: '-75.537711',
    timeZoneId: 'America/New_York',
  },
  '21810': {
    zip: '21810',
    city: 'Allen',
    state: 'MD',
    latitude: '38.285863',
    longitude: '-75.689873',
    timeZoneId: 'America/New_York',
  },
  '21811': {
    zip: '21811',
    city: 'Berlin',
    state: 'MD',
    latitude: '38.31024',
    longitude: '-75.23448',
    timeZoneId: 'America/New_York',
  },
  '21813': {
    zip: '21813',
    city: 'Bishopville',
    state: 'MD',
    latitude: '38.41794',
    longitude: '-75.19146',
    timeZoneId: 'America/New_York',
  },
  '21814': {
    zip: '21814',
    city: 'Bivalve',
    state: 'MD',
    latitude: '38.294737',
    longitude: '-75.893814',
    timeZoneId: 'America/New_York',
  },
  '21817': {
    zip: '21817',
    city: 'Crisfield',
    state: 'MD',
    latitude: '37.989315',
    longitude: '-75.844496',
    timeZoneId: 'America/New_York',
  },
  '21821': {
    zip: '21821',
    city: 'Deal Island',
    state: 'MD',
    latitude: '38.167252',
    longitude: '-75.905948',
    timeZoneId: 'America/New_York',
  },
  '21822': {
    zip: '21822',
    city: 'Eden',
    state: 'MD',
    latitude: '38.282396',
    longitude: '-75.658085',
    timeZoneId: 'America/New_York',
  },
  '21824': {
    zip: '21824',
    city: 'Ewell',
    state: 'MD',
    latitude: '37.982465',
    longitude: '-76.042178',
    timeZoneId: 'America/New_York',
  },
  '21826': {
    zip: '21826',
    city: 'Fruitland',
    state: 'MD',
    latitude: '38.32299',
    longitude: '-75.623129',
    timeZoneId: 'America/New_York',
  },
  '21829': {
    zip: '21829',
    city: 'Girdletree',
    state: 'MD',
    latitude: '38.096777',
    longitude: '-75.397397',
    timeZoneId: 'America/New_York',
  },
  '21830': {
    zip: '21830',
    city: 'Hebron',
    state: 'MD',
    latitude: '38.412322',
    longitude: '-75.687536',
    timeZoneId: 'America/New_York',
  },
  '21835': {
    zip: '21835',
    city: 'Linkwood',
    state: 'MD',
    latitude: '38.535786',
    longitude: '-75.953036',
    timeZoneId: 'America/New_York',
  },
  '21836': {
    zip: '21836',
    city: 'Manokin',
    state: 'MD',
    latitude: '38.330727',
    longitude: '-75.80639',
    timeZoneId: 'America/New_York',
  },
  '21837': {
    zip: '21837',
    city: 'Mardela Springs',
    state: 'MD',
    latitude: '38.455644',
    longitude: '-75.755948',
    timeZoneId: 'America/New_York',
  },
  '21838': {
    zip: '21838',
    city: 'Marion Station',
    state: 'MD',
    latitude: '38.02506',
    longitude: '-75.72906',
    timeZoneId: 'America/New_York',
  },
  '21840': {
    zip: '21840',
    city: 'Nanticoke',
    state: 'MD',
    latitude: '38.267147',
    longitude: '-75.904241',
    timeZoneId: 'America/New_York',
  },
  '21841': {
    zip: '21841',
    city: 'Newark',
    state: 'MD',
    latitude: '38.241807',
    longitude: '-75.282442',
    timeZoneId: 'America/New_York',
  },
  '21842': {
    zip: '21842',
    city: 'Ocean City',
    state: 'MD',
    latitude: '38.38782',
    longitude: '-75.10728',
    timeZoneId: 'America/New_York',
  },
  '21843': {
    zip: '21843',
    city: 'Ocean City',
    state: 'MD',
    latitude: '38.338025',
    longitude: '-75.083974',
    timeZoneId: 'America/New_York',
  },
  '21849': {
    zip: '21849',
    city: 'Parsonsburg',
    state: 'MD',
    latitude: '38.379302',
    longitude: '-75.492423',
    timeZoneId: 'America/New_York',
  },
  '21850': {
    zip: '21850',
    city: 'Pittsville',
    state: 'MD',
    latitude: '38.393238',
    longitude: '-75.413071',
    timeZoneId: 'America/New_York',
  },
  '21851': {
    zip: '21851',
    city: 'Pocomoke City',
    state: 'MD',
    latitude: '38.066608',
    longitude: '-75.540891',
    timeZoneId: 'America/New_York',
  },
  '21852': {
    zip: '21852',
    city: 'Powellville',
    state: 'MD',
    latitude: '38.349503',
    longitude: '-75.405489',
    timeZoneId: 'America/New_York',
  },
  '21853': {
    zip: '21853',
    city: 'Princess Anne',
    state: 'MD',
    latitude: '38.19024',
    longitude: '-75.6948',
    timeZoneId: 'America/New_York',
  },
  '21856': {
    zip: '21856',
    city: 'Quantico',
    state: 'MD',
    latitude: '38.333919',
    longitude: '-75.806911',
    timeZoneId: 'America/New_York',
  },
  '21857': {
    zip: '21857',
    city: 'Rehobeth',
    state: 'MD',
    latitude: '38.046474',
    longitude: '-75.667256',
    timeZoneId: 'America/New_York',
  },
  '21861': {
    zip: '21861',
    city: 'Sharptown',
    state: 'MD',
    latitude: '38.540825',
    longitude: '-75.719907',
    timeZoneId: 'America/New_York',
  },
  '21862': {
    zip: '21862',
    city: 'Showell',
    state: 'MD',
    latitude: '38.396558',
    longitude: '-75.213903',
    timeZoneId: 'America/New_York',
  },
  '21863': {
    zip: '21863',
    city: 'Snow Hill',
    state: 'MD',
    latitude: '38.183588',
    longitude: '-75.391585',
    timeZoneId: 'America/New_York',
  },
  '21864': {
    zip: '21864',
    city: 'Stockton',
    state: 'MD',
    latitude: '38.043738',
    longitude: '-75.414833',
    timeZoneId: 'America/New_York',
  },
  '21865': {
    zip: '21865',
    city: 'Tyaskin',
    state: 'MD',
    latitude: '38.267142',
    longitude: '-75.793733',
    timeZoneId: 'America/New_York',
  },
  '21866': {
    zip: '21866',
    city: 'Tylerton',
    state: 'MD',
    latitude: '37.969447',
    longitude: '-76.00836',
    timeZoneId: 'America/New_York',
  },
  '21867': {
    zip: '21867',
    city: 'Upper Fairmount',
    state: 'MD',
    latitude: '38.102657',
    longitude: '-75.794628',
    timeZoneId: 'America/New_York',
  },
  '21869': {
    zip: '21869',
    city: 'Vienna',
    state: 'MD',
    latitude: '38.43276',
    longitude: '-75.8937',
    timeZoneId: 'America/New_York',
  },
  '21871': {
    zip: '21871',
    city: 'Westover',
    state: 'MD',
    latitude: '38.09754',
    longitude: '-75.76056',
    timeZoneId: 'America/New_York',
  },
  '21872': {
    zip: '21872',
    city: 'Whaleyville',
    state: 'MD',
    latitude: '38.399628',
    longitude: '-75.299816',
    timeZoneId: 'America/New_York',
  },
  '21874': {
    zip: '21874',
    city: 'Willards',
    state: 'MD',
    latitude: '38.402056',
    longitude: '-75.353987',
    timeZoneId: 'America/New_York',
  },
  '21875': {
    zip: '21875',
    city: 'Delmar',
    state: 'MD',
    latitude: '38.448681',
    longitude: '-75.580949',
    timeZoneId: 'America/New_York',
  },
  '21890': {
    zip: '21890',
    city: 'Westover',
    state: 'MD',
    latitude: '38.149316',
    longitude: '-75.690351',
    timeZoneId: 'America/New_York',
  },
  '21901': {
    zip: '21901',
    city: 'North East',
    state: 'MD',
    latitude: '39.590075',
    longitude: '-75.955936',
    timeZoneId: 'America/New_York',
  },
  '21902': {
    zip: '21902',
    city: 'Perry Point',
    state: 'MD',
    latitude: '39.552425',
    longitude: '-76.072526',
    timeZoneId: 'America/New_York',
  },
  '21903': {
    zip: '21903',
    city: 'Perryville',
    state: 'MD',
    latitude: '39.571241',
    longitude: '-76.046209',
    timeZoneId: 'America/New_York',
  },
  '21904': {
    zip: '21904',
    city: 'Port Deposit',
    state: 'MD',
    latitude: '39.63594',
    longitude: '-76.0794',
    timeZoneId: 'America/New_York',
  },
  '21911': {
    zip: '21911',
    city: 'Rising Sun',
    state: 'MD',
    latitude: '39.690212',
    longitude: '-76.050886',
    timeZoneId: 'America/New_York',
  },
  '21912': {
    zip: '21912',
    city: 'Warwick',
    state: 'MD',
    latitude: '39.430272',
    longitude: '-75.80396',
    timeZoneId: 'America/New_York',
  },
  '21913': {
    zip: '21913',
    city: 'Cecilton',
    state: 'MD',
    latitude: '39.416732',
    longitude: '-75.864757',
    timeZoneId: 'America/New_York',
  },
  '21914': {
    zip: '21914',
    city: 'Charlestown',
    state: 'MD',
    latitude: '39.574153',
    longitude: '-75.980992',
    timeZoneId: 'America/New_York',
  },
  '21915': {
    zip: '21915',
    city: 'Chesapeake City',
    state: 'MD',
    latitude: '39.508657',
    longitude: '-75.850582',
    timeZoneId: 'America/New_York',
  },
  '21916': {
    zip: '21916',
    city: 'Childs',
    state: 'MD',
    latitude: '39.649206',
    longitude: '-75.870953',
    timeZoneId: 'America/New_York',
  },
  '21917': {
    zip: '21917',
    city: 'Colora',
    state: 'MD',
    latitude: '39.655213',
    longitude: '-76.07302',
    timeZoneId: 'America/New_York',
  },
  '21918': {
    zip: '21918',
    city: 'Conowingo',
    state: 'MD',
    latitude: '39.676234',
    longitude: '-76.153303',
    timeZoneId: 'America/New_York',
  },
  '21919': {
    zip: '21919',
    city: 'Earleville',
    state: 'MD',
    latitude: '39.430899',
    longitude: '-75.945941',
    timeZoneId: 'America/New_York',
  },
  '21920': {
    zip: '21920',
    city: 'Elk Mills',
    state: 'MD',
    latitude: '39.656796',
    longitude: '-75.827397',
    timeZoneId: 'America/New_York',
  },
  '21921': {
    zip: '21921',
    city: 'Elkton',
    state: 'MD',
    latitude: '39.626375',
    longitude: '-75.849604',
    timeZoneId: 'America/New_York',
  },
  '21922': {
    zip: '21922',
    city: 'Elkton',
    state: 'MD',
    latitude: '39.60804',
    longitude: '-75.83567',
    timeZoneId: 'America/New_York',
  },
  '21930': {
    zip: '21930',
    city: 'Georgetown',
    state: 'MD',
    latitude: '39.368126',
    longitude: '-75.889063',
    timeZoneId: 'America/New_York',
  },
  '22003': {
    zip: '22003',
    city: 'Annandale',
    state: 'VA',
    latitude: '38.828264',
    longitude: '-77.212338',
    timeZoneId: 'America/New_York',
  },
  '22009': {
    zip: '22009',
    city: 'Burke',
    state: 'VA',
    latitude: '38.793333',
    longitude: '-77.271944',
    timeZoneId: 'America/New_York',
  },
  '22015': {
    zip: '22015',
    city: 'Burke',
    state: 'VA',
    latitude: '38.790084',
    longitude: '-77.280669',
    timeZoneId: 'America/New_York',
  },
  '22025': {
    zip: '22025',
    city: 'Dumfries',
    state: 'VA',
    latitude: '38.8746',
    longitude: '-77.9232',
    timeZoneId: 'America/New_York',
  },
  '22026': {
    zip: '22026',
    city: 'Dumfries',
    state: 'VA',
    latitude: '38.58312',
    longitude: '-77.33382',
    timeZoneId: 'America/New_York',
  },
  '22027': {
    zip: '22027',
    city: 'Dunn Loring',
    state: 'VA',
    latitude: '38.894516',
    longitude: '-77.223341',
    timeZoneId: 'America/New_York',
  },
  '22030': {
    zip: '22030',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.832588',
    longitude: '-77.338916',
    timeZoneId: 'America/New_York',
  },
  '22031': {
    zip: '22031',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.853996',
    longitude: '-77.259111',
    timeZoneId: 'America/New_York',
  },
  '22032': {
    zip: '22032',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.820862',
    longitude: '-77.290981',
    timeZoneId: 'America/New_York',
  },
  '22033': {
    zip: '22033',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.879674',
    longitude: '-77.388079',
    timeZoneId: 'America/New_York',
  },
  '22034': {
    zip: '22034',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.855963',
    longitude: '-77.260199',
    timeZoneId: 'America/New_York',
  },
  '22035': {
    zip: '22035',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.855217',
    longitude: '-77.361501',
    timeZoneId: 'America/New_York',
  },
  '22036': {
    zip: '22036',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.857984',
    longitude: '-77.258665',
    timeZoneId: 'America/New_York',
  },
  '22037': {
    zip: '22037',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.853441',
    longitude: '-77.300385',
    timeZoneId: 'America/New_York',
  },
  '22038': {
    zip: '22038',
    city: 'Fairfax',
    state: 'VA',
    latitude: '38.830825',
    longitude: '-77.362938',
    timeZoneId: 'America/New_York',
  },
  '22039': {
    zip: '22039',
    city: 'Fairfax Station',
    state: 'VA',
    latitude: '38.763405',
    longitude: '-77.314652',
    timeZoneId: 'America/New_York',
  },
  '22040': {
    zip: '22040',
    city: 'Falls Church',
    state: 'VA',
    latitude: '38.885693',
    longitude: '-77.171231',
    timeZoneId: 'America/New_York',
  },
  '22041': {
    zip: '22041',
    city: 'Falls Church',
    state: 'VA',
    latitude: '38.846929',
    longitude: '-77.142525',
    timeZoneId: 'America/New_York',
  },
  '22042': {
    zip: '22042',
    city: 'Falls Church',
    state: 'VA',
    latitude: '38.864751',
    longitude: '-77.194735',
    timeZoneId: 'America/New_York',
  },
  '22043': {
    zip: '22043',
    city: 'Falls Church',
    state: 'VA',
    latitude: '38.900669',
    longitude: '-77.193846',
    timeZoneId: 'America/New_York',
  },
  '22044': {
    zip: '22044',
    city: 'Falls Church',
    state: 'VA',
    latitude: '38.856209',
    longitude: '-77.15702',
    timeZoneId: 'America/New_York',
  },
  '22046': {
    zip: '22046',
    city: 'Falls Church',
    state: 'VA',
    latitude: '38.88456',
    longitude: '-77.182978',
    timeZoneId: 'America/New_York',
  },
  '22060': {
    zip: '22060',
    city: 'Fort Belvoir',
    state: 'VA',
    latitude: '38.712147',
    longitude: '-77.158616',
    timeZoneId: 'America/New_York',
  },
  '22066': {
    zip: '22066',
    city: 'Great Falls',
    state: 'VA',
    latitude: '39.002477',
    longitude: '-77.300045',
    timeZoneId: 'America/New_York',
  },
  '22067': {
    zip: '22067',
    city: 'Greenway',
    state: 'VA',
    latitude: '38.940622',
    longitude: '-77.165123',
    timeZoneId: 'America/New_York',
  },
  '22079': {
    zip: '22079',
    city: 'Lorton',
    state: 'VA',
    latitude: '38.6901',
    longitude: '-77.2065',
    timeZoneId: 'America/New_York',
  },
  '22081': {
    zip: '22081',
    city: 'Merrifield',
    state: 'VA',
    latitude: '38.873376',
    longitude: '-77.235978',
    timeZoneId: 'America/New_York',
  },
  '22082': {
    zip: '22082',
    city: 'Merrifield',
    state: 'VA',
    latitude: '38.872667',
    longitude: '-77.237303',
    timeZoneId: 'America/New_York',
  },
  '22095': {
    zip: '22095',
    city: 'Herndon',
    state: 'VA',
    latitude: '38.968942',
    longitude: '-77.388697',
    timeZoneId: 'America/New_York',
  },
  '22096': {
    zip: '22096',
    city: 'Reston',
    state: 'VA',
    latitude: '38.949859',
    longitude: '-77.344333',
    timeZoneId: 'America/New_York',
  },
  '22101': {
    zip: '22101',
    city: 'Mc Lean',
    state: 'VA',
    latitude: '38.929874',
    longitude: '-77.175966',
    timeZoneId: 'America/New_York',
  },
  '22102': {
    zip: '22102',
    city: 'Mc Lean',
    state: 'VA',
    latitude: '38.95582',
    longitude: '-77.234785',
    timeZoneId: 'America/New_York',
  },
  '22103': {
    zip: '22103',
    city: 'West McLean',
    state: 'VA',
    latitude: '38.947768',
    longitude: '-77.227954',
    timeZoneId: 'America/New_York',
  },
  '22106': {
    zip: '22106',
    city: 'Mc Lean',
    state: 'VA',
    latitude: '38.940842',
    longitude: '-77.176143',
    timeZoneId: 'America/New_York',
  },
  '22107': {
    zip: '22107',
    city: 'Mc Lean',
    state: 'VA',
    latitude: '38.933696',
    longitude: '-77.17924',
    timeZoneId: 'America/New_York',
  },
  '22108': {
    zip: '22108',
    city: 'Mc Lean',
    state: 'VA',
    latitude: '38.936723',
    longitude: '-77.177728',
    timeZoneId: 'America/New_York',
  },
  '22109': {
    zip: '22109',
    city: 'Mc Lean',
    state: 'VA',
    latitude: '38.93368',
    longitude: '-77.184391',
    timeZoneId: 'America/New_York',
  },
  '22116': {
    zip: '22116',
    city: 'Merrifield',
    state: 'VA',
    latitude: '38.861347',
    longitude: '-77.25745',
    timeZoneId: 'America/New_York',
  },
  '22118': {
    zip: '22118',
    city: 'Merrifield',
    state: 'VA',
    latitude: '38.839359',
    longitude: '-77.336627',
    timeZoneId: 'America/New_York',
  },
  '22119': {
    zip: '22119',
    city: 'Merrifield',
    state: 'VA',
    latitude: '38.876851',
    longitude: '-77.235738',
    timeZoneId: 'America/New_York',
  },
  '22121': {
    zip: '22121',
    city: 'Mount Vernon',
    state: 'VA',
    latitude: '38.716701',
    longitude: '-77.108231',
    timeZoneId: 'America/New_York',
  },
  '22122': {
    zip: '22122',
    city: 'Newington',
    state: 'VA',
    latitude: '38.739023',
    longitude: '-77.180316',
    timeZoneId: 'America/New_York',
  },
  '22124': {
    zip: '22124',
    city: 'Oakton',
    state: 'VA',
    latitude: '38.88917',
    longitude: '-77.322192',
    timeZoneId: 'America/New_York',
  },
  '22125': {
    zip: '22125',
    city: 'Occoquan',
    state: 'VA',
    latitude: '38.681319',
    longitude: '-77.26152',
    timeZoneId: 'America/New_York',
  },
  '22134': {
    zip: '22134',
    city: 'Quantico',
    state: 'VA',
    latitude: '38.520589',
    longitude: '-77.321006',
    timeZoneId: 'America/New_York',
  },
  '22135': {
    zip: '22135',
    city: 'Quantico',
    state: 'VA',
    latitude: '38.521184',
    longitude: '-77.288054',
    timeZoneId: 'America/New_York',
  },
  '22150': {
    zip: '22150',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.773578',
    longitude: '-77.18702',
    timeZoneId: 'America/New_York',
  },
  '22151': {
    zip: '22151',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.801146',
    longitude: '-77.207526',
    timeZoneId: 'America/New_York',
  },
  '22152': {
    zip: '22152',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.777214',
    longitude: '-77.235211',
    timeZoneId: 'America/New_York',
  },
  '22153': {
    zip: '22153',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.745146',
    longitude: '-77.23714',
    timeZoneId: 'America/New_York',
  },
  '22156': {
    zip: '22156',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.7765',
    longitude: '-77.186403',
    timeZoneId: 'America/New_York',
  },
  '22158': {
    zip: '22158',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.812261',
    longitude: '-77.256414',
    timeZoneId: 'America/New_York',
  },
  '22159': {
    zip: '22159',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.801702',
    longitude: '-77.18227',
    timeZoneId: 'America/New_York',
  },
  '22160': {
    zip: '22160',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.811286',
    longitude: '-77.221189',
    timeZoneId: 'America/New_York',
  },
  '22161': {
    zip: '22161',
    city: 'Springfield',
    state: 'VA',
    latitude: '38.787863',
    longitude: '-77.187222',
    timeZoneId: 'America/New_York',
  },
  '22172': {
    zip: '22172',
    city: 'Triangle',
    state: 'VA',
    latitude: '38.55127',
    longitude: '-77.324765',
    timeZoneId: 'America/New_York',
  },
  '22180': {
    zip: '22180',
    city: 'Vienna',
    state: 'VA',
    latitude: '38.896094',
    longitude: '-77.256034',
    timeZoneId: 'America/New_York',
  },
  '22181': {
    zip: '22181',
    city: 'Vienna',
    state: 'VA',
    latitude: '38.89854',
    longitude: '-77.291651',
    timeZoneId: 'America/New_York',
  },
  '22182': {
    zip: '22182',
    city: 'Vienna',
    state: 'VA',
    latitude: '38.928478',
    longitude: '-77.261486',
    timeZoneId: 'America/New_York',
  },
  '22183': {
    zip: '22183',
    city: 'Vienna',
    state: 'VA',
    latitude: '38.897446',
    longitude: '-77.254343',
    timeZoneId: 'America/New_York',
  },
  '22185': {
    zip: '22185',
    city: 'Vienna',
    state: 'VA',
    latitude: '38.899292',
    longitude: '-77.260327',
    timeZoneId: 'America/New_York',
  },
  '22191': {
    zip: '22191',
    city: 'Woodbridge',
    state: 'VA',
    latitude: '38.626232',
    longitude: '-77.274322',
    timeZoneId: 'America/New_York',
  },
  '22192': {
    zip: '22192',
    city: 'Woodbridge',
    state: 'VA',
    latitude: '38.68001',
    longitude: '-77.309022',
    timeZoneId: 'America/New_York',
  },
  '22193': {
    zip: '22193',
    city: 'Woodbridge',
    state: 'VA',
    latitude: '38.646162',
    longitude: '-77.349693',
    timeZoneId: 'America/New_York',
  },
  '22194': {
    zip: '22194',
    city: 'Woodbridge',
    state: 'VA',
    latitude: '38.634959',
    longitude: '-77.259815',
    timeZoneId: 'America/New_York',
  },
  '22195': {
    zip: '22195',
    city: 'Woodbridge',
    state: 'VA',
    latitude: '38.658441',
    longitude: '-77.24958',
    timeZoneId: 'America/New_York',
  },
  '22199': {
    zip: '22199',
    city: 'Lorton',
    state: 'VA',
    latitude: '38.701995',
    longitude: '-77.230356',
    timeZoneId: 'America/New_York',
  },
  '22201': {
    zip: '22201',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.887343',
    longitude: '-77.098842',
    timeZoneId: 'America/New_York',
  },
  '22202': {
    zip: '22202',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.861338',
    longitude: '-77.056739',
    timeZoneId: 'America/New_York',
  },
  '22203': {
    zip: '22203',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.875126',
    longitude: '-77.119607',
    timeZoneId: 'America/New_York',
  },
  '22204': {
    zip: '22204',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.859707',
    longitude: '-77.095607',
    timeZoneId: 'America/New_York',
  },
  '22205': {
    zip: '22205',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.884663',
    longitude: '-77.140211',
    timeZoneId: 'America/New_York',
  },
  '22206': {
    zip: '22206',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.841046',
    longitude: '-77.092448',
    timeZoneId: 'America/New_York',
  },
  '22207': {
    zip: '22207',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.903531',
    longitude: '-77.129764',
    timeZoneId: 'America/New_York',
  },
  '22209': {
    zip: '22209',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.894486',
    longitude: '-77.074049',
    timeZoneId: 'America/New_York',
  },
  '22210': {
    zip: '22210',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.887284',
    longitude: '-77.093944',
    timeZoneId: 'America/New_York',
  },
  '22211': {
    zip: '22211',
    city: 'Fort Myer',
    state: 'VA',
    latitude: '38.886949',
    longitude: '-77.078211',
    timeZoneId: 'America/New_York',
  },
  '22212': {
    zip: '22212',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.858432',
    longitude: '-77.089675',
    timeZoneId: 'America/New_York',
  },
  '22213': {
    zip: '22213',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.894653',
    longitude: '-77.163198',
    timeZoneId: 'America/New_York',
  },
  '22214': {
    zip: '22214',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.854651',
    longitude: '-77.070177',
    timeZoneId: 'America/New_York',
  },
  '22215': {
    zip: '22215',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.858463',
    longitude: '-77.048962',
    timeZoneId: 'America/New_York',
  },
  '22216': {
    zip: '22216',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.891625',
    longitude: '-77.085322',
    timeZoneId: 'America/New_York',
  },
  '22217': {
    zip: '22217',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.85566',
    longitude: '-77.093001',
    timeZoneId: 'America/New_York',
  },
  '22219': {
    zip: '22219',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.891234',
    longitude: '-77.073069',
    timeZoneId: 'America/New_York',
  },
  '22225': {
    zip: '22225',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.856611',
    longitude: '-77.059377',
    timeZoneId: 'America/New_York',
  },
  '22226': {
    zip: '22226',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.883884',
    longitude: '-77.094284',
    timeZoneId: 'America/New_York',
  },
  '22227': {
    zip: '22227',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.854079',
    longitude: '-77.054897',
    timeZoneId: 'America/New_York',
  },
  '22230': {
    zip: '22230',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.870056',
    longitude: '-77.11668',
    timeZoneId: 'America/New_York',
  },
  '22240': {
    zip: '22240',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.853022',
    longitude: '-77.054148',
    timeZoneId: 'America/New_York',
  },
  '22241': {
    zip: '22241',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.858702',
    longitude: '-77.056354',
    timeZoneId: 'America/New_York',
  },
  '22242': {
    zip: '22242',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.856291',
    longitude: '-77.050893',
    timeZoneId: 'America/New_York',
  },
  '22243': {
    zip: '22243',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.861034',
    longitude: '-77.052567',
    timeZoneId: 'America/New_York',
  },
  '22244': {
    zip: '22244',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.85512',
    longitude: '-77.050263',
    timeZoneId: 'America/New_York',
  },
  '22245': {
    zip: '22245',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.852066',
    longitude: '-77.051486',
    timeZoneId: 'America/New_York',
  },
  '22246': {
    zip: '22246',
    city: 'Arlington',
    state: 'VA',
    latitude: '38.857622',
    longitude: '-77.053083',
    timeZoneId: 'America/New_York',
  },
  '22301': {
    zip: '22301',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.81928',
    longitude: '-77.064236',
    timeZoneId: 'America/New_York',
  },
  '22302': {
    zip: '22302',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.82931',
    longitude: '-77.085205',
    timeZoneId: 'America/New_York',
  },
  '22303': {
    zip: '22303',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.794024',
    longitude: '-77.074314',
    timeZoneId: 'America/New_York',
  },
  '22304': {
    zip: '22304',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.817208',
    longitude: '-77.122599',
    timeZoneId: 'America/New_York',
  },
  '22305': {
    zip: '22305',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.83824',
    longitude: '-77.066036',
    timeZoneId: 'America/New_York',
  },
  '22306': {
    zip: '22306',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.760953',
    longitude: '-77.095861',
    timeZoneId: 'America/New_York',
  },
  '22307': {
    zip: '22307',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.772576',
    longitude: '-77.056869',
    timeZoneId: 'America/New_York',
  },
  '22308': {
    zip: '22308',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.727682',
    longitude: '-77.058124',
    timeZoneId: 'America/New_York',
  },
  '22309': {
    zip: '22309',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.727866',
    longitude: '-77.109317',
    timeZoneId: 'America/New_York',
  },
  '22310': {
    zip: '22310',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.78298',
    longitude: '-77.11998',
    timeZoneId: 'America/New_York',
  },
  '22311': {
    zip: '22311',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.835786',
    longitude: '-77.127498',
    timeZoneId: 'America/New_York',
  },
  '22312': {
    zip: '22312',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.821508',
    longitude: '-77.152254',
    timeZoneId: 'America/New_York',
  },
  '22313': {
    zip: '22313',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.772177',
    longitude: '-77.170494',
    timeZoneId: 'America/New_York',
  },
  '22314': {
    zip: '22314',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.799917',
    longitude: '-77.051663',
    timeZoneId: 'America/New_York',
  },
  '22315': {
    zip: '22315',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.78448',
    longitude: '-77.1252',
    timeZoneId: 'America/New_York',
  },
  '22320': {
    zip: '22320',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.805356',
    longitude: '-77.056422',
    timeZoneId: 'America/New_York',
  },
  '22331': {
    zip: '22331',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.80663',
    longitude: '-77.058064',
    timeZoneId: 'America/New_York',
  },
  '22332': {
    zip: '22332',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.804593',
    longitude: '-77.059273',
    timeZoneId: 'America/New_York',
  },
  '22333': {
    zip: '22333',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.815071',
    longitude: '-77.113643',
    timeZoneId: 'America/New_York',
  },
  '22334': {
    zip: '22334',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.803177',
    longitude: '-77.058685',
    timeZoneId: 'America/New_York',
  },
  '22350': {
    zip: '22350',
    city: 'Alexandria',
    state: 'VA',
    latitude: '38.853006',
    longitude: '-77.123393',
    timeZoneId: 'America/New_York',
  },
  '22401': {
    zip: '22401',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.296176',
    longitude: '-77.485335',
    timeZoneId: 'America/New_York',
  },
  '22402': {
    zip: '22402',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.256114',
    longitude: '-77.566794',
    timeZoneId: 'America/New_York',
  },
  '22403': {
    zip: '22403',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.350856',
    longitude: '-77.47736',
    timeZoneId: 'America/New_York',
  },
  '22404': {
    zip: '22404',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.297717',
    longitude: '-77.456462',
    timeZoneId: 'America/New_York',
  },
  '22405': {
    zip: '22405',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.320601',
    longitude: '-77.414651',
    timeZoneId: 'America/New_York',
  },
  '22406': {
    zip: '22406',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.380437',
    longitude: '-77.534307',
    timeZoneId: 'America/New_York',
  },
  '22407': {
    zip: '22407',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.282279',
    longitude: '-77.557232',
    timeZoneId: 'America/New_York',
  },
  '22408': {
    zip: '22408',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.207246',
    longitude: '-77.458369',
    timeZoneId: 'America/New_York',
  },
  '22412': {
    zip: '22412',
    city: 'Fredericksburg',
    state: 'VA',
    latitude: '38.299924',
    longitude: '-77.488208',
    timeZoneId: 'America/New_York',
  },
  '22427': {
    zip: '22427',
    city: 'Bowling Green',
    state: 'VA',
    latitude: '38.049038',
    longitude: '-77.350933',
    timeZoneId: 'America/New_York',
  },
  '22428': {
    zip: '22428',
    city: 'Bowling Green',
    state: 'VA',
    latitude: '38.04738',
    longitude: '-77.348116',
    timeZoneId: 'America/New_York',
  },
  '22430': {
    zip: '22430',
    city: 'Brooke',
    state: 'VA',
    latitude: '38.378786',
    longitude: '-77.362519',
    timeZoneId: 'America/New_York',
  },
  '22432': {
    zip: '22432',
    city: 'Burgess',
    state: 'VA',
    latitude: '37.86877',
    longitude: '-76.341637',
    timeZoneId: 'America/New_York',
  },
  '22433': {
    zip: '22433',
    city: 'Burr Hill',
    state: 'VA',
    latitude: '38.369137',
    longitude: '-77.885088',
    timeZoneId: 'America/New_York',
  },
  '22435': {
    zip: '22435',
    city: 'Callao',
    state: 'VA',
    latitude: '37.941111',
    longitude: '-76.535556',
    timeZoneId: 'America/New_York',
  },
  '22436': {
    zip: '22436',
    city: 'Caret',
    state: 'VA',
    latitude: '37.978173',
    longitude: '-76.944159',
    timeZoneId: 'America/New_York',
  },
  '22437': {
    zip: '22437',
    city: 'Center Cross',
    state: 'VA',
    latitude: '37.781797',
    longitude: '-76.758304',
    timeZoneId: 'America/New_York',
  },
  '22438': {
    zip: '22438',
    city: 'Champlain',
    state: 'VA',
    latitude: '38.029503',
    longitude: '-76.962492',
    timeZoneId: 'America/New_York',
  },
  '22442': {
    zip: '22442',
    city: 'Coles Point',
    state: 'VA',
    latitude: '38.14623',
    longitude: '-76.633816',
    timeZoneId: 'America/New_York',
  },
  '22443': {
    zip: '22443',
    city: 'Colonial Beach',
    state: 'VA',
    latitude: '38.248998',
    longitude: '-76.962438',
    timeZoneId: 'America/New_York',
  },
  '22446': {
    zip: '22446',
    city: 'Corbin',
    state: 'VA',
    latitude: '38.197773',
    longitude: '-77.390523',
    timeZoneId: 'America/New_York',
  },
  '22448': {
    zip: '22448',
    city: 'Dahlgren',
    state: 'VA',
    latitude: '38.335861',
    longitude: '-77.033198',
    timeZoneId: 'America/New_York',
  },
  '22451': {
    zip: '22451',
    city: 'Dogue',
    state: 'VA',
    latitude: '38.23522',
    longitude: '-77.214022',
    timeZoneId: 'America/New_York',
  },
  '22454': {
    zip: '22454',
    city: 'Dunnsville',
    state: 'VA',
    latitude: '37.8438',
    longitude: '-76.83108',
    timeZoneId: 'America/New_York',
  },
  '22456': {
    zip: '22456',
    city: 'Edwardsville',
    state: 'VA',
    latitude: '37.90854',
    longitude: '-76.367144',
    timeZoneId: 'America/New_York',
  },
  '22460': {
    zip: '22460',
    city: 'Farnham',
    state: 'VA',
    latitude: '37.864783',
    longitude: '-76.598767',
    timeZoneId: 'America/New_York',
  },
  '22463': {
    zip: '22463',
    city: 'Garrisonville',
    state: 'VA',
    latitude: '38.479142',
    longitude: '-77.496343',
    timeZoneId: 'America/New_York',
  },
  '22469': {
    zip: '22469',
    city: 'Hague',
    state: 'VA',
    latitude: '38.050417',
    longitude: '-76.670603',
    timeZoneId: 'America/New_York',
  },
  '22471': {
    zip: '22471',
    city: 'Hartwood',
    state: 'VA',
    latitude: '38.402421',
    longitude: '-77.580674',
    timeZoneId: 'America/New_York',
  },
  '22472': {
    zip: '22472',
    city: 'Haynesville',
    state: 'VA',
    latitude: '37.951692',
    longitude: '-76.663853',
    timeZoneId: 'America/New_York',
  },
  '22473': {
    zip: '22473',
    city: 'Heathsville',
    state: 'VA',
    latitude: '37.905166',
    longitude: '-76.434436',
    timeZoneId: 'America/New_York',
  },
  '22476': {
    zip: '22476',
    city: 'Hustle',
    state: 'VA',
    latitude: '38.022747',
    longitude: '-77.047665',
    timeZoneId: 'America/New_York',
  },
  '22480': {
    zip: '22480',
    city: 'Irvington',
    state: 'VA',
    latitude: '37.66534',
    longitude: '-76.414915',
    timeZoneId: 'America/New_York',
  },
  '22481': {
    zip: '22481',
    city: 'Jersey',
    state: 'VA',
    latitude: '38.21048',
    longitude: '-77.137268',
    timeZoneId: 'America/New_York',
  },
  '22482': {
    zip: '22482',
    city: 'Kilmarnock',
    state: 'VA',
    latitude: '37.708103',
    longitude: '-76.382222',
    timeZoneId: 'America/New_York',
  },
  '22485': {
    zip: '22485',
    city: 'King George',
    state: 'VA',
    latitude: '38.299944',
    longitude: '-77.147624',
    timeZoneId: 'America/New_York',
  },
  '22488': {
    zip: '22488',
    city: 'Kinsale',
    state: 'VA',
    latitude: '38.046655',
    longitude: '-76.588726',
    timeZoneId: 'America/New_York',
  },
  '22501': {
    zip: '22501',
    city: 'Ladysmith',
    state: 'VA',
    latitude: '38.01636',
    longitude: '-77.517427',
    timeZoneId: 'America/New_York',
  },
  '22503': {
    zip: '22503',
    city: 'Lancaster',
    state: 'VA',
    latitude: '37.763511',
    longitude: '-76.498306',
    timeZoneId: 'America/New_York',
  },
  '22504': {
    zip: '22504',
    city: 'Laneview',
    state: 'VA',
    latitude: '37.770833',
    longitude: '-76.688333',
    timeZoneId: 'America/New_York',
  },
  '22507': {
    zip: '22507',
    city: 'Lively',
    state: 'VA',
    latitude: '37.775853',
    longitude: '-76.514865',
    timeZoneId: 'America/New_York',
  },
  '22508': {
    zip: '22508',
    city: 'Locust Grove',
    state: 'VA',
    latitude: '38.333379',
    longitude: '-77.76253',
    timeZoneId: 'America/New_York',
  },
  '22509': {
    zip: '22509',
    city: 'Loretto',
    state: 'VA',
    latitude: '38.069719',
    longitude: '-76.975087',
    timeZoneId: 'America/New_York',
  },
  '22511': {
    zip: '22511',
    city: 'Lottsburg',
    state: 'VA',
    latitude: '37.97656',
    longitude: '-76.51567',
    timeZoneId: 'America/New_York',
  },
  '22513': {
    zip: '22513',
    city: 'Merry Point',
    state: 'VA',
    latitude: '37.734965',
    longitude: '-76.480408',
    timeZoneId: 'America/New_York',
  },
  '22514': {
    zip: '22514',
    city: 'Milford',
    state: 'VA',
    latitude: '38.00826',
    longitude: '-77.34588',
    timeZoneId: 'America/New_York',
  },
  '22517': {
    zip: '22517',
    city: 'Mollusk',
    state: 'VA',
    latitude: '37.73297',
    longitude: '-76.537003',
    timeZoneId: 'America/New_York',
  },
  '22520': {
    zip: '22520',
    city: 'Montross',
    state: 'VA',
    latitude: '38.148213',
    longitude: '-76.728369',
    timeZoneId: 'America/New_York',
  },
  '22523': {
    zip: '22523',
    city: 'Morattico',
    state: 'VA',
    latitude: '37.788658',
    longitude: '-76.630028',
    timeZoneId: 'America/New_York',
  },
  '22524': {
    zip: '22524',
    city: 'Mount Holly',
    state: 'VA',
    latitude: '38.135745',
    longitude: '-76.661658',
    timeZoneId: 'America/New_York',
  },
  '22526': {
    zip: '22526',
    city: 'Ninde',
    state: 'VA',
    latitude: '38.270141',
    longitude: '-77.054318',
    timeZoneId: 'America/New_York',
  },
  '22528': {
    zip: '22528',
    city: 'Nuttsville',
    state: 'VA',
    latitude: '37.790076',
    longitude: '-76.551822',
    timeZoneId: 'America/New_York',
  },
  '22529': {
    zip: '22529',
    city: 'Oldhams',
    state: 'VA',
    latitude: '37.999333',
    longitude: '-76.667766',
    timeZoneId: 'America/New_York',
  },
  '22530': {
    zip: '22530',
    city: 'Ophelia',
    state: 'VA',
    latitude: '37.912472',
    longitude: '-76.283591',
    timeZoneId: 'America/New_York',
  },
  '22534': {
    zip: '22534',
    city: 'Partlow',
    state: 'VA',
    latitude: '38.080719',
    longitude: '-77.678074',
    timeZoneId: 'America/New_York',
  },
  '22535': {
    zip: '22535',
    city: 'Port Royal',
    state: 'VA',
    latitude: '38.170649',
    longitude: '-77.19264',
    timeZoneId: 'America/New_York',
  },
  '22538': {
    zip: '22538',
    city: 'Rappahannock Academy',
    state: 'VA',
    latitude: '38.158313',
    longitude: '-77.30023',
    timeZoneId: 'America/New_York',
  },
  '22539': {
    zip: '22539',
    city: 'Reedville',
    state: 'VA',
    latitude: '37.866249',
    longitude: '-76.287053',
    timeZoneId: 'America/New_York',
  },
  '22542': {
    zip: '22542',
    city: 'Rhoadesville',
    state: 'VA',
    latitude: '38.284569',
    longitude: '-77.907317',
    timeZoneId: 'America/New_York',
  },
  '22544': {
    zip: '22544',
    city: 'Rollins Fork',
    state: 'VA',
    latitude: '38.185617',
    longitude: '-77.065844',
    timeZoneId: 'America/New_York',
  },
  '22545': {
    zip: '22545',
    city: 'Ruby',
    state: 'VA',
    latitude: '38.504345',
    longitude: '-77.517683',
    timeZoneId: 'America/New_York',
  },
  '22546': {
    zip: '22546',
    city: 'Ruther Glen',
    state: 'VA',
    latitude: '38.007807',
    longitude: '-77.545616',
    timeZoneId: 'America/New_York',
  },
  '22547': {
    zip: '22547',
    city: 'Sealston',
    state: 'VA',
    latitude: '38.250467',
    longitude: '-77.311067',
    timeZoneId: 'America/New_York',
  },
  '22548': {
    zip: '22548',
    city: 'Sharps',
    state: 'VA',
    latitude: '37.82573',
    longitude: '-76.697914',
    timeZoneId: 'America/New_York',
  },
  '22551': {
    zip: '22551',
    city: 'Spotsylvania',
    state: 'VA',
    latitude: '38.20352',
    longitude: '-77.598148',
    timeZoneId: 'America/New_York',
  },
  '22552': {
    zip: '22552',
    city: 'Sparta',
    state: 'VA',
    latitude: '37.991299',
    longitude: '-77.228895',
    timeZoneId: 'America/New_York',
  },
  '22553': {
    zip: '22553',
    city: 'Spotsylvania',
    state: 'VA',
    latitude: '38.20062',
    longitude: '-77.68542',
    timeZoneId: 'America/New_York',
  },
  '22554': {
    zip: '22554',
    city: 'Stafford',
    state: 'VA',
    latitude: '38.460218',
    longitude: '-77.437061',
    timeZoneId: 'America/New_York',
  },
  '22555': {
    zip: '22555',
    city: 'Stafford',
    state: 'VA',
    latitude: '38.420262',
    longitude: '-77.409552',
    timeZoneId: 'America/New_York',
  },
  '22556': {
    zip: '22556',
    city: 'Stafford',
    state: 'VA',
    latitude: '38.461964',
    longitude: '-77.435878',
    timeZoneId: 'America/New_York',
  },
  '22558': {
    zip: '22558',
    city: 'Stratford',
    state: 'VA',
    latitude: '38.146473',
    longitude: '-76.842295',
    timeZoneId: 'America/New_York',
  },
  '22560': {
    zip: '22560',
    city: 'Tappahannock',
    state: 'VA',
    latitude: '37.923806',
    longitude: '-76.864812',
    timeZoneId: 'America/New_York',
  },
  '22565': {
    zip: '22565',
    city: 'Thornburg',
    state: 'VA',
    latitude: '38.133364',
    longitude: '-77.519781',
    timeZoneId: 'America/New_York',
  },
  '22567': {
    zip: '22567',
    city: 'Unionville',
    state: 'VA',
    latitude: '38.228135',
    longitude: '-77.912975',
    timeZoneId: 'America/New_York',
  },
  '22570': {
    zip: '22570',
    city: 'Village',
    state: 'VA',
    latitude: '37.948402',
    longitude: '-76.604617',
    timeZoneId: 'America/New_York',
  },
  '22572': {
    zip: '22572',
    city: 'Warsaw',
    state: 'VA',
    latitude: '37.957415',
    longitude: '-76.762507',
    timeZoneId: 'America/New_York',
  },
  '22576': {
    zip: '22576',
    city: 'Weems',
    state: 'VA',
    latitude: '37.69217',
    longitude: '-76.421703',
    timeZoneId: 'America/New_York',
  },
  '22577': {
    zip: '22577',
    city: 'Sandy Point',
    state: 'VA',
    latitude: '38.065301',
    longitude: '-76.55916',
    timeZoneId: 'America/New_York',
  },
  '22578': {
    zip: '22578',
    city: 'White Stone',
    state: 'VA',
    latitude: '37.65',
    longitude: '-76.383333',
    timeZoneId: 'America/New_York',
  },
  '22579': {
    zip: '22579',
    city: 'Wicomico Church',
    state: 'VA',
    latitude: '37.788954',
    longitude: '-76.360133',
    timeZoneId: 'America/New_York',
  },
  '22580': {
    zip: '22580',
    city: 'Woodford',
    state: 'VA',
    latitude: '38.09899',
    longitude: '-77.534121',
    timeZoneId: 'America/New_York',
  },
  '22581': {
    zip: '22581',
    city: 'Zacata',
    state: 'VA',
    latitude: '38.119696',
    longitude: '-76.784998',
    timeZoneId: 'America/New_York',
  },
  '22601': {
    zip: '22601',
    city: 'Winchester',
    state: 'VA',
    latitude: '39.175637',
    longitude: '-78.164962',
    timeZoneId: 'America/New_York',
  },
  '22602': {
    zip: '22602',
    city: 'Winchester',
    state: 'VA',
    latitude: '39.165663',
    longitude: '-78.148614',
    timeZoneId: 'America/New_York',
  },
  '22603': {
    zip: '22603',
    city: 'Winchester',
    state: 'VA',
    latitude: '39.204657',
    longitude: '-78.162238',
    timeZoneId: 'America/New_York',
  },
  '22604': {
    zip: '22604',
    city: 'Winchester',
    state: 'VA',
    latitude: '39.187153',
    longitude: '-78.154627',
    timeZoneId: 'America/New_York',
  },
  '22610': {
    zip: '22610',
    city: 'Bentonville',
    state: 'VA',
    latitude: '38.81814',
    longitude: '-78.25812',
    timeZoneId: 'America/New_York',
  },
  '22611': {
    zip: '22611',
    city: 'Berryville',
    state: 'VA',
    latitude: '39.150938',
    longitude: '-77.976972',
    timeZoneId: 'America/New_York',
  },
  '22620': {
    zip: '22620',
    city: 'Boyce',
    state: 'VA',
    latitude: '39.07926',
    longitude: '-78.049642',
    timeZoneId: 'America/New_York',
  },
  '22622': {
    zip: '22622',
    city: 'Brucetown',
    state: 'VA',
    latitude: '39.256587',
    longitude: '-78.067063',
    timeZoneId: 'America/New_York',
  },
  '22623': {
    zip: '22623',
    city: 'Chester Gap',
    state: 'VA',
    latitude: '38.854561',
    longitude: '-78.133314',
    timeZoneId: 'America/New_York',
  },
  '22624': {
    zip: '22624',
    city: 'Clear Brook',
    state: 'VA',
    latitude: '39.247394',
    longitude: '-78.092824',
    timeZoneId: 'America/New_York',
  },
  '22625': {
    zip: '22625',
    city: 'Cross Junction',
    state: 'VA',
    latitude: '39.37044',
    longitude: '-78.30306',
    timeZoneId: 'America/New_York',
  },
  '22626': {
    zip: '22626',
    city: 'Fishers Hill',
    state: 'VA',
    latitude: '38.979185',
    longitude: '-78.405016',
    timeZoneId: 'America/New_York',
  },
  '22627': {
    zip: '22627',
    city: 'Flint Hill',
    state: 'VA',
    latitude: '38.730956',
    longitude: '-78.064789',
    timeZoneId: 'America/New_York',
  },
  '22630': {
    zip: '22630',
    city: 'Front Royal',
    state: 'VA',
    latitude: '38.946389',
    longitude: '-78.199722',
    timeZoneId: 'America/New_York',
  },
  '22637': {
    zip: '22637',
    city: 'Gore',
    state: 'VA',
    latitude: '39.274602',
    longitude: '-78.353036',
    timeZoneId: 'America/New_York',
  },
  '22639': {
    zip: '22639',
    city: 'Hume',
    state: 'VA',
    latitude: '38.818969',
    longitude: '-77.990868',
    timeZoneId: 'America/New_York',
  },
  '22640': {
    zip: '22640',
    city: 'Huntly',
    state: 'VA',
    latitude: '38.806691',
    longitude: '-78.140273',
    timeZoneId: 'America/New_York',
  },
  '22641': {
    zip: '22641',
    city: 'Strasburg',
    state: 'VA',
    latitude: '39.062322',
    longitude: '-78.362403',
    timeZoneId: 'America/New_York',
  },
  '22642': {
    zip: '22642',
    city: 'Linden',
    state: 'VA',
    latitude: '38.88906',
    longitude: '-78.075811',
    timeZoneId: 'America/New_York',
  },
  '22643': {
    zip: '22643',
    city: 'Markham',
    state: 'VA',
    latitude: '38.916784',
    longitude: '-78.004657',
    timeZoneId: 'America/New_York',
  },
  '22644': {
    zip: '22644',
    city: 'Maurertown',
    state: 'VA',
    latitude: '38.984928',
    longitude: '-78.511001',
    timeZoneId: 'America/New_York',
  },
  '22645': {
    zip: '22645',
    city: 'Middletown',
    state: 'VA',
    latitude: '39.025765',
    longitude: '-78.27823',
    timeZoneId: 'America/New_York',
  },
  '22646': {
    zip: '22646',
    city: 'Millwood',
    state: 'VA',
    latitude: '39.066551',
    longitude: '-78.03858',
    timeZoneId: 'America/New_York',
  },
  '22649': {
    zip: '22649',
    city: 'Middletown',
    state: 'VA',
    latitude: '39.005182',
    longitude: '-78.258327',
    timeZoneId: 'America/New_York',
  },
  '22650': {
    zip: '22650',
    city: 'Rileyville',
    state: 'VA',
    latitude: '38.765027',
    longitude: '-78.377011',
    timeZoneId: 'America/New_York',
  },
  '22652': {
    zip: '22652',
    city: 'Fort Valley',
    state: 'VA',
    latitude: '38.842058',
    longitude: '-78.419588',
    timeZoneId: 'America/New_York',
  },
  '22654': {
    zip: '22654',
    city: 'Star Tannery',
    state: 'VA',
    latitude: '39.081239',
    longitude: '-78.461184',
    timeZoneId: 'America/New_York',
  },
  '22655': {
    zip: '22655',
    city: 'Stephens City',
    state: 'VA',
    latitude: '39.086856',
    longitude: '-78.206821',
    timeZoneId: 'America/New_York',
  },
  '22656': {
    zip: '22656',
    city: 'Stephenson',
    state: 'VA',
    latitude: '39.207623',
    longitude: '-78.094979',
    timeZoneId: 'America/New_York',
  },
  '22657': {
    zip: '22657',
    city: 'Strasburg',
    state: 'VA',
    latitude: '38.987689',
    longitude: '-78.359876',
    timeZoneId: 'America/New_York',
  },
  '22660': {
    zip: '22660',
    city: 'Toms Brook',
    state: 'VA',
    latitude: '38.949385',
    longitude: '-78.43084',
    timeZoneId: 'America/New_York',
  },
  '22663': {
    zip: '22663',
    city: 'White Post',
    state: 'VA',
    latitude: '39.054892',
    longitude: '-78.097567',
    timeZoneId: 'America/New_York',
  },
  '22664': {
    zip: '22664',
    city: 'Woodstock',
    state: 'VA',
    latitude: '38.875497',
    longitude: '-78.51053',
    timeZoneId: 'America/New_York',
  },
  '22701': {
    zip: '22701',
    city: 'Culpeper',
    state: 'VA',
    latitude: '38.47164',
    longitude: '-78.013989',
    timeZoneId: 'America/New_York',
  },
  '22709': {
    zip: '22709',
    city: 'Aroda',
    state: 'VA',
    latitude: '38.322616',
    longitude: '-78.261232',
    timeZoneId: 'America/New_York',
  },
  '22711': {
    zip: '22711',
    city: 'Banco',
    state: 'VA',
    latitude: '38.448654',
    longitude: '-78.279768',
    timeZoneId: 'America/New_York',
  },
  '22712': {
    zip: '22712',
    city: 'Bealeton',
    state: 'VA',
    latitude: '38.57448',
    longitude: '-77.80068',
    timeZoneId: 'America/New_York',
  },
  '22713': {
    zip: '22713',
    city: 'Boston',
    state: 'VA',
    latitude: '38.537783',
    longitude: '-78.142325',
    timeZoneId: 'America/New_York',
  },
  '22714': {
    zip: '22714',
    city: 'Brandy Station',
    state: 'VA',
    latitude: '38.523104',
    longitude: '-77.898372',
    timeZoneId: 'America/New_York',
  },
  '22715': {
    zip: '22715',
    city: 'Brightwood',
    state: 'VA',
    latitude: '38.409191',
    longitude: '-78.168015',
    timeZoneId: 'America/New_York',
  },
  '22716': {
    zip: '22716',
    city: 'Castleton',
    state: 'VA',
    latitude: '38.607228',
    longitude: '-78.114936',
    timeZoneId: 'America/New_York',
  },
  '22718': {
    zip: '22718',
    city: 'Elkwood',
    state: 'VA',
    latitude: '38.460796',
    longitude: '-77.80698',
    timeZoneId: 'America/New_York',
  },
  '22719': {
    zip: '22719',
    city: 'Etlan',
    state: 'VA',
    latitude: '38.556608',
    longitude: '-78.307639',
    timeZoneId: 'America/New_York',
  },
  '22720': {
    zip: '22720',
    city: 'Goldvein',
    state: 'VA',
    latitude: '38.493237',
    longitude: '-77.666206',
    timeZoneId: 'America/New_York',
  },
  '22722': {
    zip: '22722',
    city: 'Haywood',
    state: 'VA',
    latitude: '38.469435',
    longitude: '-78.179818',
    timeZoneId: 'America/New_York',
  },
  '22723': {
    zip: '22723',
    city: 'Hood',
    state: 'VA',
    latitude: '38.357258',
    longitude: '-78.383955',
    timeZoneId: 'America/New_York',
  },
  '22724': {
    zip: '22724',
    city: 'Jeffersonton',
    state: 'VA',
    latitude: '38.629391',
    longitude: '-77.90607',
    timeZoneId: 'America/New_York',
  },
  '22725': {
    zip: '22725',
    city: 'Leon',
    state: 'VA',
    latitude: '38.453845',
    longitude: '-78.158848',
    timeZoneId: 'America/New_York',
  },
  '22726': {
    zip: '22726',
    city: 'Lignum',
    state: 'VA',
    latitude: '38.398281',
    longitude: '-77.816915',
    timeZoneId: 'America/New_York',
  },
  '22727': {
    zip: '22727',
    city: 'Madison',
    state: 'VA',
    latitude: '38.374012',
    longitude: '-78.321934',
    timeZoneId: 'America/New_York',
  },
  '22728': {
    zip: '22728',
    city: 'Midland',
    state: 'VA',
    latitude: '38.633221',
    longitude: '-77.721549',
    timeZoneId: 'America/New_York',
  },
  '22729': {
    zip: '22729',
    city: 'Mitchells',
    state: 'VA',
    latitude: '38.383019',
    longitude: '-78.01135',
    timeZoneId: 'America/New_York',
  },
  '22730': {
    zip: '22730',
    city: 'Oakpark',
    state: 'VA',
    latitude: '38.376926',
    longitude: '-78.124661',
    timeZoneId: 'America/New_York',
  },
  '22731': {
    zip: '22731',
    city: 'Pratts',
    state: 'VA',
    latitude: '38.356136',
    longitude: '-78.274541',
    timeZoneId: 'America/New_York',
  },
  '22732': {
    zip: '22732',
    city: 'Radiant',
    state: 'VA',
    latitude: '38.32125',
    longitude: '-78.176689',
    timeZoneId: 'America/New_York',
  },
  '22733': {
    zip: '22733',
    city: 'Rapidan',
    state: 'VA',
    latitude: '38.333795',
    longitude: '-78.039595',
    timeZoneId: 'America/New_York',
  },
  '22734': {
    zip: '22734',
    city: 'Remington',
    state: 'VA',
    latitude: '38.535968',
    longitude: '-77.807557',
    timeZoneId: 'America/New_York',
  },
  '22735': {
    zip: '22735',
    city: 'Reva',
    state: 'VA',
    latitude: '38.475407',
    longitude: '-78.16153',
    timeZoneId: 'America/New_York',
  },
  '22736': {
    zip: '22736',
    city: 'Richardsville',
    state: 'VA',
    latitude: '38.395739',
    longitude: '-77.691652',
    timeZoneId: 'America/New_York',
  },
  '22737': {
    zip: '22737',
    city: 'Rixeyville',
    state: 'VA',
    latitude: '38.598789',
    longitude: '-77.99765',
    timeZoneId: 'America/New_York',
  },
  '22738': {
    zip: '22738',
    city: 'Rochelle',
    state: 'VA',
    latitude: '38.265908',
    longitude: '-78.25132',
    timeZoneId: 'America/New_York',
  },
  '22739': {
    zip: '22739',
    city: 'Somerville',
    state: 'VA',
    latitude: '38.523991',
    longitude: '-77.610489',
    timeZoneId: 'America/New_York',
  },
  '22740': {
    zip: '22740',
    city: 'Sperryville',
    state: 'VA',
    latitude: '38.629508',
    longitude: '-78.252819',
    timeZoneId: 'America/New_York',
  },
  '22741': {
    zip: '22741',
    city: 'Stevensburg',
    state: 'VA',
    latitude: '38.442676',
    longitude: '-77.866475',
    timeZoneId: 'America/New_York',
  },
  '22742': {
    zip: '22742',
    city: 'Sumerduck',
    state: 'VA',
    latitude: '38.453208',
    longitude: '-77.707748',
    timeZoneId: 'America/New_York',
  },
  '22743': {
    zip: '22743',
    city: 'Syria',
    state: 'VA',
    latitude: '38.516368',
    longitude: '-78.353621',
    timeZoneId: 'America/New_York',
  },
  '22746': {
    zip: '22746',
    city: 'Viewtown',
    state: 'VA',
    latitude: '38.636708',
    longitude: '-78.013297',
    timeZoneId: 'America/New_York',
  },
  '22747': {
    zip: '22747',
    city: 'Washington',
    state: 'VA',
    latitude: '38.719068',
    longitude: '-78.166901',
    timeZoneId: 'America/New_York',
  },
  '22748': {
    zip: '22748',
    city: 'Wolftown',
    state: 'VA',
    latitude: '38.358118',
    longitude: '-78.348203',
    timeZoneId: 'America/New_York',
  },
  '22749': {
    zip: '22749',
    city: 'Woodville',
    state: 'VA',
    latitude: '38.646126',
    longitude: '-78.171784',
    timeZoneId: 'America/New_York',
  },
  '22801': {
    zip: '22801',
    city: 'Harrisonburg',
    state: 'VA',
    latitude: '38.442152',
    longitude: '-78.874027',
    timeZoneId: 'America/New_York',
  },
  '22802': {
    zip: '22802',
    city: 'Harrisonburg',
    state: 'VA',
    latitude: '38.508585',
    longitude: '-78.940514',
    timeZoneId: 'America/New_York',
  },
  '22803': {
    zip: '22803',
    city: 'Harrisonburg',
    state: 'VA',
    latitude: '38.449505',
    longitude: '-78.86953',
    timeZoneId: 'America/New_York',
  },
  '22807': {
    zip: '22807',
    city: 'Harrisonburg',
    state: 'VA',
    latitude: '38.434377',
    longitude: '-78.874712',
    timeZoneId: 'America/New_York',
  },
  '22810': {
    zip: '22810',
    city: 'Basye',
    state: 'VA',
    latitude: '38.795741',
    longitude: '-78.799328',
    timeZoneId: 'America/New_York',
  },
  '22811': {
    zip: '22811',
    city: 'Bergton',
    state: 'VA',
    latitude: '38.796293',
    longitude: '-78.969663',
    timeZoneId: 'America/New_York',
  },
  '22812': {
    zip: '22812',
    city: 'Bridgewater',
    state: 'VA',
    latitude: '38.38264',
    longitude: '-78.971771',
    timeZoneId: 'America/New_York',
  },
  '22815': {
    zip: '22815',
    city: 'Broadway',
    state: 'VA',
    latitude: '38.607291',
    longitude: '-78.800864',
    timeZoneId: 'America/New_York',
  },
  '22820': {
    zip: '22820',
    city: 'Criders',
    state: 'VA',
    latitude: '38.722457',
    longitude: '-79.017457',
    timeZoneId: 'America/New_York',
  },
  '22821': {
    zip: '22821',
    city: 'Dayton',
    state: 'VA',
    latitude: '38.417387',
    longitude: '-78.954792',
    timeZoneId: 'America/New_York',
  },
  '22824': {
    zip: '22824',
    city: 'Edinburg',
    state: 'VA',
    latitude: '38.814496',
    longitude: '-78.572016',
    timeZoneId: 'America/New_York',
  },
  '22827': {
    zip: '22827',
    city: 'Elkton',
    state: 'VA',
    latitude: '38.409979',
    longitude: '-78.620915',
    timeZoneId: 'America/New_York',
  },
  '22830': {
    zip: '22830',
    city: 'Fulks Run',
    state: 'VA',
    latitude: '38.634537',
    longitude: '-78.985851',
    timeZoneId: 'America/New_York',
  },
  '22831': {
    zip: '22831',
    city: 'Hinton',
    state: 'VA',
    latitude: '38.485855',
    longitude: '-79.006346',
    timeZoneId: 'America/New_York',
  },
  '22832': {
    zip: '22832',
    city: 'Keezletown',
    state: 'VA',
    latitude: '38.452004',
    longitude: '-78.762661',
    timeZoneId: 'America/New_York',
  },
  '22833': {
    zip: '22833',
    city: 'Lacey Spring',
    state: 'VA',
    latitude: '38.549055',
    longitude: '-78.73505',
    timeZoneId: 'America/New_York',
  },
  '22834': {
    zip: '22834',
    city: 'Linville',
    state: 'VA',
    latitude: '38.558265',
    longitude: '-78.898288',
    timeZoneId: 'America/New_York',
  },
  '22835': {
    zip: '22835',
    city: 'Luray',
    state: 'VA',
    latitude: '38.665612',
    longitude: '-78.456879',
    timeZoneId: 'America/New_York',
  },
  '22840': {
    zip: '22840',
    city: 'Mc Gaheysville',
    state: 'VA',
    latitude: '38.404045',
    longitude: '-78.719342',
    timeZoneId: 'America/New_York',
  },
  '22841': {
    zip: '22841',
    city: 'Mount Crawford',
    state: 'VA',
    latitude: '38.350903',
    longitude: '-78.955919',
    timeZoneId: 'America/New_York',
  },
  '22842': {
    zip: '22842',
    city: 'Mount Jackson',
    state: 'VA',
    latitude: '38.753381',
    longitude: '-78.639309',
    timeZoneId: 'America/New_York',
  },
  '22843': {
    zip: '22843',
    city: 'Mount Solon',
    state: 'VA',
    latitude: '38.371896',
    longitude: '-79.168406',
    timeZoneId: 'America/New_York',
  },
  '22844': {
    zip: '22844',
    city: 'New Market',
    state: 'VA',
    latitude: '38.648808',
    longitude: '-78.674885',
    timeZoneId: 'America/New_York',
  },
  '22845': {
    zip: '22845',
    city: 'Orkney Springs',
    state: 'VA',
    latitude: '38.788542',
    longitude: '-78.822829',
    timeZoneId: 'America/New_York',
  },
  '22846': {
    zip: '22846',
    city: 'Penn Laird',
    state: 'VA',
    latitude: '38.372896',
    longitude: '-78.80418',
    timeZoneId: 'America/New_York',
  },
  '22847': {
    zip: '22847',
    city: 'Quicksburg',
    state: 'VA',
    latitude: '38.725788',
    longitude: '-78.712644',
    timeZoneId: 'America/New_York',
  },
  '22848': {
    zip: '22848',
    city: 'Pleasant Valley',
    state: 'VA',
    latitude: '38.384318',
    longitude: '-78.900024',
    timeZoneId: 'America/New_York',
  },
  '22849': {
    zip: '22849',
    city: 'Shenandoah',
    state: 'VA',
    latitude: '38.485706',
    longitude: '-78.618795',
    timeZoneId: 'America/New_York',
  },
  '22850': {
    zip: '22850',
    city: 'Singers Glen',
    state: 'VA',
    latitude: '38.557018',
    longitude: '-78.895545',
    timeZoneId: 'America/New_York',
  },
  '22851': {
    zip: '22851',
    city: 'Stanley',
    state: 'VA',
    latitude: '38.547769',
    longitude: '-78.496558',
    timeZoneId: 'America/New_York',
  },
  '22853': {
    zip: '22853',
    city: 'Timberville',
    state: 'VA',
    latitude: '38.628588',
    longitude: '-78.776507',
    timeZoneId: 'America/New_York',
  },
  '22901': {
    zip: '22901',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.057163',
    longitude: '-78.49313',
    timeZoneId: 'America/New_York',
  },
  '22902': {
    zip: '22902',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.00028',
    longitude: '-78.46362',
    timeZoneId: 'America/New_York',
  },
  '22903': {
    zip: '22903',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.03766',
    longitude: '-78.519202',
    timeZoneId: 'America/New_York',
  },
  '22904': {
    zip: '22904',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.000947',
    longitude: '-78.471158',
    timeZoneId: 'America/New_York',
  },
  '22905': {
    zip: '22905',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.023188',
    longitude: '-78.489866',
    timeZoneId: 'America/New_York',
  },
  '22906': {
    zip: '22906',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.030177',
    longitude: '-78.47555',
    timeZoneId: 'America/New_York',
  },
  '22907': {
    zip: '22907',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '37.952452',
    longitude: '-78.492913',
    timeZoneId: 'America/New_York',
  },
  '22908': {
    zip: '22908',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.035452',
    longitude: '-78.514913',
    timeZoneId: 'America/New_York',
  },
  '22909': {
    zip: '22909',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.04321',
    longitude: '-78.474036',
    timeZoneId: 'America/New_York',
  },
  '22910': {
    zip: '22910',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.021668',
    longitude: '-78.598714',
    timeZoneId: 'America/New_York',
  },
  '22911': {
    zip: '22911',
    city: 'Charlottesville',
    state: 'VA',
    latitude: '38.071652',
    longitude: '-78.432215',
    timeZoneId: 'America/New_York',
  },
  '22920': {
    zip: '22920',
    city: 'Afton',
    state: 'VA',
    latitude: '37.952009',
    longitude: '-78.844068',
    timeZoneId: 'America/New_York',
  },
  '22922': {
    zip: '22922',
    city: 'Arrington',
    state: 'VA',
    latitude: '37.69218',
    longitude: '-78.9147',
    timeZoneId: 'America/New_York',
  },
  '22923': {
    zip: '22923',
    city: 'Barboursville',
    state: 'VA',
    latitude: '38.166765',
    longitude: '-78.365242',
    timeZoneId: 'America/New_York',
  },
  '22924': {
    zip: '22924',
    city: 'Batesville',
    state: 'VA',
    latitude: '37.995648',
    longitude: '-78.720211',
    timeZoneId: 'America/New_York',
  },
  '22931': {
    zip: '22931',
    city: 'Covesville',
    state: 'VA',
    latitude: '37.906385',
    longitude: '-78.722678',
    timeZoneId: 'America/New_York',
  },
  '22932': {
    zip: '22932',
    city: 'Crozet',
    state: 'VA',
    latitude: '38.066684',
    longitude: '-78.702915',
    timeZoneId: 'America/New_York',
  },
  '22935': {
    zip: '22935',
    city: 'Dyke',
    state: 'VA',
    latitude: '38.264055',
    longitude: '-78.574641',
    timeZoneId: 'America/New_York',
  },
  '22936': {
    zip: '22936',
    city: 'Earlysville',
    state: 'VA',
    latitude: '38.140751',
    longitude: '-78.485072',
    timeZoneId: 'America/New_York',
  },
  '22937': {
    zip: '22937',
    city: 'Esmont',
    state: 'VA',
    latitude: '37.802102',
    longitude: '-78.624774',
    timeZoneId: 'America/New_York',
  },
  '22938': {
    zip: '22938',
    city: 'Faber',
    state: 'VA',
    latitude: '37.857512',
    longitude: '-78.759743',
    timeZoneId: 'America/New_York',
  },
  '22939': {
    zip: '22939',
    city: 'Fishersville',
    state: 'VA',
    latitude: '38.087881',
    longitude: '-78.966521',
    timeZoneId: 'America/New_York',
  },
  '22940': {
    zip: '22940',
    city: 'Free Union',
    state: 'VA',
    latitude: '38.155282',
    longitude: '-78.577674',
    timeZoneId: 'America/New_York',
  },
  '22942': {
    zip: '22942',
    city: 'Gordonsville',
    state: 'VA',
    latitude: '38.134847',
    longitude: '-78.186346',
    timeZoneId: 'America/New_York',
  },
  '22943': {
    zip: '22943',
    city: 'Greenwood',
    state: 'VA',
    latitude: '38.044494',
    longitude: '-78.788119',
    timeZoneId: 'America/New_York',
  },
  '22945': {
    zip: '22945',
    city: 'Ivy',
    state: 'VA',
    latitude: '38.05725',
    longitude: '-78.597718',
    timeZoneId: 'America/New_York',
  },
  '22946': {
    zip: '22946',
    city: 'Keene',
    state: 'VA',
    latitude: '37.924605',
    longitude: '-78.559962',
    timeZoneId: 'America/New_York',
  },
  '22947': {
    zip: '22947',
    city: 'Keswick',
    state: 'VA',
    latitude: '37.99573',
    longitude: '-78.375095',
    timeZoneId: 'America/New_York',
  },
  '22948': {
    zip: '22948',
    city: 'Locust Dale',
    state: 'VA',
    latitude: '38.330939',
    longitude: '-78.112397',
    timeZoneId: 'America/New_York',
  },
  '22949': {
    zip: '22949',
    city: 'Lovingston',
    state: 'VA',
    latitude: '37.802521',
    longitude: '-78.876351',
    timeZoneId: 'America/New_York',
  },
  '22952': {
    zip: '22952',
    city: 'Lyndhurst',
    state: 'VA',
    latitude: '38.01138',
    longitude: '-78.92448',
    timeZoneId: 'America/New_York',
  },
  '22957': {
    zip: '22957',
    city: 'Montpelier Station',
    state: 'VA',
    latitude: '38.199667',
    longitude: '-78.116529',
    timeZoneId: 'America/New_York',
  },
  '22958': {
    zip: '22958',
    city: 'Nellysford',
    state: 'VA',
    latitude: '37.86318',
    longitude: '-78.87108',
    timeZoneId: 'America/New_York',
  },
  '22959': {
    zip: '22959',
    city: 'North Garden',
    state: 'VA',
    latitude: '37.954067',
    longitude: '-78.691947',
    timeZoneId: 'America/New_York',
  },
  '22960': {
    zip: '22960',
    city: 'Orange',
    state: 'VA',
    latitude: '38.24511',
    longitude: '-78.108521',
    timeZoneId: 'America/New_York',
  },
  '22963': {
    zip: '22963',
    city: 'Palmyra',
    state: 'VA',
    latitude: '37.915257',
    longitude: '-78.322769',
    timeZoneId: 'America/New_York',
  },
  '22964': {
    zip: '22964',
    city: 'Piney River',
    state: 'VA',
    latitude: '37.714274',
    longitude: '-79.020575',
    timeZoneId: 'America/New_York',
  },
  '22965': {
    zip: '22965',
    city: 'Quinque',
    state: 'VA',
    latitude: '38.279411',
    longitude: '-78.430453',
    timeZoneId: 'America/New_York',
  },
  '22967': {
    zip: '22967',
    city: 'Roseland',
    state: 'VA',
    latitude: '37.916232',
    longitude: '-78.951373',
    timeZoneId: 'America/New_York',
  },
  '22968': {
    zip: '22968',
    city: 'Ruckersville',
    state: 'VA',
    latitude: '38.218427',
    longitude: '-78.382009',
    timeZoneId: 'America/New_York',
  },
  '22969': {
    zip: '22969',
    city: 'Schuyler',
    state: 'VA',
    latitude: '37.772564',
    longitude: '-78.697807',
    timeZoneId: 'America/New_York',
  },
  '22971': {
    zip: '22971',
    city: 'Shipman',
    state: 'VA',
    latitude: '37.7589',
    longitude: '-78.81912',
    timeZoneId: 'America/New_York',
  },
  '22972': {
    zip: '22972',
    city: 'Somerset',
    state: 'VA',
    latitude: '38.20713',
    longitude: '-78.23724',
    timeZoneId: 'America/New_York',
  },
  '22973': {
    zip: '22973',
    city: 'Stanardsville',
    state: 'VA',
    latitude: '38.296962',
    longitude: '-78.439538',
    timeZoneId: 'America/New_York',
  },
  '22974': {
    zip: '22974',
    city: 'Troy',
    state: 'VA',
    latitude: '37.941957',
    longitude: '-78.235745',
    timeZoneId: 'America/New_York',
  },
  '22976': {
    zip: '22976',
    city: 'Tyro',
    state: 'VA',
    latitude: '37.921586',
    longitude: '-78.94501',
    timeZoneId: 'America/New_York',
  },
  '22980': {
    zip: '22980',
    city: 'Waynesboro',
    state: 'VA',
    latitude: '38.073472',
    longitude: '-78.90293',
    timeZoneId: 'America/New_York',
  },
  '22987': {
    zip: '22987',
    city: 'White Hall',
    state: 'VA',
    latitude: '38.11941',
    longitude: '-78.660697',
    timeZoneId: 'America/New_York',
  },
  '22989': {
    zip: '22989',
    city: 'Woodberry Forest',
    state: 'VA',
    latitude: '38.213226',
    longitude: '-78.052185',
    timeZoneId: 'America/New_York',
  },
  '23001': {
    zip: '23001',
    city: 'Achilles',
    state: 'VA',
    latitude: '37.281159',
    longitude: '-76.44096',
    timeZoneId: 'America/New_York',
  },
  '23002': {
    zip: '23002',
    city: 'Amelia Court House',
    state: 'VA',
    latitude: '37.39098',
    longitude: '-77.90472',
    timeZoneId: 'America/New_York',
  },
  '23003': {
    zip: '23003',
    city: 'Ark',
    state: 'VA',
    latitude: '37.438567',
    longitude: '-76.578223',
    timeZoneId: 'America/New_York',
  },
  '23004': {
    zip: '23004',
    city: 'Arvonia',
    state: 'VA',
    latitude: '37.667219',
    longitude: '-78.413499',
    timeZoneId: 'America/New_York',
  },
  '23005': {
    zip: '23005',
    city: 'Ashland',
    state: 'VA',
    latitude: '37.747553',
    longitude: '-77.471865',
    timeZoneId: 'America/New_York',
  },
  '23009': {
    zip: '23009',
    city: 'Aylett',
    state: 'VA',
    latitude: '37.752552',
    longitude: '-77.166999',
    timeZoneId: 'America/New_York',
  },
  '23011': {
    zip: '23011',
    city: 'Barhamsville',
    state: 'VA',
    latitude: '37.463636',
    longitude: '-76.818238',
    timeZoneId: 'America/New_York',
  },
  '23014': {
    zip: '23014',
    city: 'Beaumont',
    state: 'VA',
    latitude: '37.677729',
    longitude: '-77.89517',
    timeZoneId: 'America/New_York',
  },
  '23015': {
    zip: '23015',
    city: 'Beaverdam',
    state: 'VA',
    latitude: '38.008814',
    longitude: '-77.648058',
    timeZoneId: 'America/New_York',
  },
  '23018': {
    zip: '23018',
    city: 'Bena',
    state: 'VA',
    latitude: '37.270084',
    longitude: '-76.458519',
    timeZoneId: 'America/New_York',
  },
  '23021': {
    zip: '23021',
    city: 'Bohannon',
    state: 'VA',
    latitude: '37.393121',
    longitude: '-76.372982',
    timeZoneId: 'America/New_York',
  },
  '23022': {
    zip: '23022',
    city: 'Bremo Bluff',
    state: 'VA',
    latitude: '37.737887',
    longitude: '-78.266419',
    timeZoneId: 'America/New_York',
  },
  '23023': {
    zip: '23023',
    city: 'Bruington',
    state: 'VA',
    latitude: '37.776737',
    longitude: '-76.937881',
    timeZoneId: 'America/New_York',
  },
  '23024': {
    zip: '23024',
    city: 'Bumpass',
    state: 'VA',
    latitude: '38.036307',
    longitude: '-77.714721',
    timeZoneId: 'America/New_York',
  },
  '23025': {
    zip: '23025',
    city: 'Cardinal',
    state: 'VA',
    latitude: '37.420768',
    longitude: '-76.382292',
    timeZoneId: 'America/New_York',
  },
  '23027': {
    zip: '23027',
    city: 'Cartersville',
    state: 'VA',
    latitude: '37.65678',
    longitude: '-78.1089',
    timeZoneId: 'America/New_York',
  },
  '23030': {
    zip: '23030',
    city: 'Charles City',
    state: 'VA',
    latitude: '37.337419',
    longitude: '-77.050923',
    timeZoneId: 'America/New_York',
  },
  '23031': {
    zip: '23031',
    city: 'Christchurch',
    state: 'VA',
    latitude: '37.597737',
    longitude: '-76.450035',
    timeZoneId: 'America/New_York',
  },
  '23032': {
    zip: '23032',
    city: 'Church View',
    state: 'VA',
    latitude: '37.670809',
    longitude: '-76.67886',
    timeZoneId: 'America/New_York',
  },
  '23035': {
    zip: '23035',
    city: 'Cobbs Creek',
    state: 'VA',
    latitude: '37.50552',
    longitude: '-76.39722',
    timeZoneId: 'America/New_York',
  },
  '23038': {
    zip: '23038',
    city: 'Columbia',
    state: 'VA',
    latitude: '37.804145',
    longitude: '-78.08961',
    timeZoneId: 'America/New_York',
  },
  '23039': {
    zip: '23039',
    city: 'Crozier',
    state: 'VA',
    latitude: '37.65643',
    longitude: '-77.80595',
    timeZoneId: 'America/New_York',
  },
  '23040': {
    zip: '23040',
    city: 'Cumberland',
    state: 'VA',
    latitude: '37.509967',
    longitude: '-78.242868',
    timeZoneId: 'America/New_York',
  },
  '23043': {
    zip: '23043',
    city: 'Deltaville',
    state: 'VA',
    latitude: '37.554145',
    longitude: '-76.352965',
    timeZoneId: 'America/New_York',
  },
  '23045': {
    zip: '23045',
    city: 'Diggs',
    state: 'VA',
    latitude: '37.43611',
    longitude: '-76.281226',
    timeZoneId: 'America/New_York',
  },
  '23047': {
    zip: '23047',
    city: 'Doswell',
    state: 'VA',
    latitude: '37.850145',
    longitude: '-77.506082',
    timeZoneId: 'America/New_York',
  },
  '23050': {
    zip: '23050',
    city: 'Dutton',
    state: 'VA',
    latitude: '37.48811',
    longitude: '-76.429213',
    timeZoneId: 'America/New_York',
  },
  '23055': {
    zip: '23055',
    city: 'Fork Union',
    state: 'VA',
    latitude: '37.769687',
    longitude: '-78.215424',
    timeZoneId: 'America/New_York',
  },
  '23056': {
    zip: '23056',
    city: 'Foster',
    state: 'VA',
    latitude: '37.45141',
    longitude: '-76.385042',
    timeZoneId: 'America/New_York',
  },
  '23058': {
    zip: '23058',
    city: 'Glen Allen',
    state: 'VA',
    latitude: '37.662023',
    longitude: '-77.484349',
    timeZoneId: 'America/New_York',
  },
  '23059': {
    zip: '23059',
    city: 'Glen Allen',
    state: 'VA',
    latitude: '37.716392',
    longitude: '-77.578346',
    timeZoneId: 'America/New_York',
  },
  '23060': {
    zip: '23060',
    city: 'Glen Allen',
    state: 'VA',
    latitude: '37.65963',
    longitude: '-77.529243',
    timeZoneId: 'America/New_York',
  },
  '23061': {
    zip: '23061',
    city: 'Gloucester',
    state: 'VA',
    latitude: '37.435603',
    longitude: '-76.547405',
    timeZoneId: 'America/New_York',
  },
  '23062': {
    zip: '23062',
    city: 'Gloucester Point',
    state: 'VA',
    latitude: '37.256502',
    longitude: '-76.493791',
    timeZoneId: 'America/New_York',
  },
  '23063': {
    zip: '23063',
    city: 'Goochland',
    state: 'VA',
    latitude: '37.680935',
    longitude: '-77.88605',
    timeZoneId: 'America/New_York',
  },
  '23064': {
    zip: '23064',
    city: 'Grimstead',
    state: 'VA',
    latitude: '37.498933',
    longitude: '-76.304462',
    timeZoneId: 'America/New_York',
  },
  '23065': {
    zip: '23065',
    city: 'Gum Spring',
    state: 'VA',
    latitude: '37.802864',
    longitude: '-77.934501',
    timeZoneId: 'America/New_York',
  },
  '23066': {
    zip: '23066',
    city: 'Gwynn',
    state: 'VA',
    latitude: '37.50003',
    longitude: '-76.289392',
    timeZoneId: 'America/New_York',
  },
  '23067': {
    zip: '23067',
    city: 'Hadensville',
    state: 'VA',
    latitude: '37.81371',
    longitude: '-78.013015',
    timeZoneId: 'America/New_York',
  },
  '23068': {
    zip: '23068',
    city: 'Hallieford',
    state: 'VA',
    latitude: '37.494525',
    longitude: '-76.341224',
    timeZoneId: 'America/New_York',
  },
  '23069': {
    zip: '23069',
    city: 'Hanover',
    state: 'VA',
    latitude: '37.781421',
    longitude: '-77.309839',
    timeZoneId: 'America/New_York',
  },
  '23070': {
    zip: '23070',
    city: 'Hardyville',
    state: 'VA',
    latitude: '37.544869',
    longitude: '-76.423694',
    timeZoneId: 'America/New_York',
  },
  '23071': {
    zip: '23071',
    city: 'Hartfield',
    state: 'VA',
    latitude: '37.55974',
    longitude: '-76.480914',
    timeZoneId: 'America/New_York',
  },
  '23072': {
    zip: '23072',
    city: 'Hayes',
    state: 'VA',
    latitude: '37.283333',
    longitude: '-76.5',
    timeZoneId: 'America/New_York',
  },
  '23075': {
    zip: '23075',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.546429',
    longitude: '-77.325289',
    timeZoneId: 'America/New_York',
  },
  '23076': {
    zip: '23076',
    city: 'Hudgins',
    state: 'VA',
    latitude: '37.468121',
    longitude: '-76.32351',
    timeZoneId: 'America/New_York',
  },
  '23079': {
    zip: '23079',
    city: 'Jamaica',
    state: 'VA',
    latitude: '37.726585',
    longitude: '-76.688739',
    timeZoneId: 'America/New_York',
  },
  '23081': {
    zip: '23081',
    city: 'Jamestown',
    state: 'VA',
    latitude: '37.224814',
    longitude: '-76.781576',
    timeZoneId: 'America/New_York',
  },
  '23083': {
    zip: '23083',
    city: 'Jetersville',
    state: 'VA',
    latitude: '37.31791',
    longitude: '-78.118077',
    timeZoneId: 'America/New_York',
  },
  '23084': {
    zip: '23084',
    city: 'Kents Store',
    state: 'VA',
    latitude: '37.8621',
    longitude: '-78.043199',
    timeZoneId: 'America/New_York',
  },
  '23085': {
    zip: '23085',
    city: 'King and Queen Court House',
    state: 'VA',
    latitude: '37.7285',
    longitude: '-76.845215',
    timeZoneId: 'America/New_York',
  },
  '23086': {
    zip: '23086',
    city: 'King William',
    state: 'VA',
    latitude: '37.68046',
    longitude: '-77.068899',
    timeZoneId: 'America/New_York',
  },
  '23089': {
    zip: '23089',
    city: 'Lanexa',
    state: 'VA',
    latitude: '37.47312',
    longitude: '-76.905831',
    timeZoneId: 'America/New_York',
  },
  '23090': {
    zip: '23090',
    city: 'Lightfoot',
    state: 'VA',
    latitude: '37.323189',
    longitude: '-76.738989',
    timeZoneId: 'America/New_York',
  },
  '23091': {
    zip: '23091',
    city: 'Little Plymouth',
    state: 'VA',
    latitude: '37.667329',
    longitude: '-76.806604',
    timeZoneId: 'America/New_York',
  },
  '23092': {
    zip: '23092',
    city: 'Locust Hill',
    state: 'VA',
    latitude: '37.587858',
    longitude: '-76.496919',
    timeZoneId: 'America/New_York',
  },
  '23093': {
    zip: '23093',
    city: 'Louisa',
    state: 'VA',
    latitude: '38.092162',
    longitude: '-78.021999',
    timeZoneId: 'America/New_York',
  },
  '23102': {
    zip: '23102',
    city: 'Maidens',
    state: 'VA',
    latitude: '37.69842',
    longitude: '-77.84688',
    timeZoneId: 'America/New_York',
  },
  '23103': {
    zip: '23103',
    city: 'Manakin Sabot',
    state: 'VA',
    latitude: '37.653832',
    longitude: '-77.725631',
    timeZoneId: 'America/New_York',
  },
  '23105': {
    zip: '23105',
    city: 'Mannboro',
    state: 'VA',
    latitude: '37.255087',
    longitude: '-77.826024',
    timeZoneId: 'America/New_York',
  },
  '23106': {
    zip: '23106',
    city: 'Manquin',
    state: 'VA',
    latitude: '37.709531',
    longitude: '-77.192156',
    timeZoneId: 'America/New_York',
  },
  '23107': {
    zip: '23107',
    city: 'Maryus',
    state: 'VA',
    latitude: '37.278151',
    longitude: '-76.403531',
    timeZoneId: 'America/New_York',
  },
  '23108': {
    zip: '23108',
    city: 'Mascot',
    state: 'VA',
    latitude: '37.587586',
    longitude: '-76.723826',
    timeZoneId: 'America/New_York',
  },
  '23109': {
    zip: '23109',
    city: 'Mathews',
    state: 'VA',
    latitude: '37.442814',
    longitude: '-76.35637',
    timeZoneId: 'America/New_York',
  },
  '23110': {
    zip: '23110',
    city: 'Mattaponi',
    state: 'VA',
    latitude: '37.608056',
    longitude: '-76.778889',
    timeZoneId: 'America/New_York',
  },
  '23111': {
    zip: '23111',
    city: 'Mechanicsville',
    state: 'VA',
    latitude: '37.631363',
    longitude: '-77.356146',
    timeZoneId: 'America/New_York',
  },
  '23112': {
    zip: '23112',
    city: 'Midlothian',
    state: 'VA',
    latitude: '37.433683',
    longitude: '-77.64787',
    timeZoneId: 'America/New_York',
  },
  '23113': {
    zip: '23113',
    city: 'Midlothian',
    state: 'VA',
    latitude: '37.535176',
    longitude: '-77.670301',
    timeZoneId: 'America/New_York',
  },
  '23114': {
    zip: '23114',
    city: 'Midlothian',
    state: 'VA',
    latitude: '37.456175',
    longitude: '-77.64375',
    timeZoneId: 'America/New_York',
  },
  '23115': {
    zip: '23115',
    city: 'Millers Tavern',
    state: 'VA',
    latitude: '37.829088',
    longitude: '-76.946007',
    timeZoneId: 'America/New_York',
  },
  '23116': {
    zip: '23116',
    city: 'Mechanicsville',
    state: 'VA',
    latitude: '37.628332',
    longitude: '-77.353236',
    timeZoneId: 'America/New_York',
  },
  '23117': {
    zip: '23117',
    city: 'Mineral',
    state: 'VA',
    latitude: '37.837624',
    longitude: '-77.976633',
    timeZoneId: 'America/New_York',
  },
  '23119': {
    zip: '23119',
    city: 'Moon',
    state: 'VA',
    latitude: '37.448456',
    longitude: '-76.305783',
    timeZoneId: 'America/New_York',
  },
  '23120': {
    zip: '23120',
    city: 'Moseley',
    state: 'VA',
    latitude: '37.419091',
    longitude: '-77.768808',
    timeZoneId: 'America/New_York',
  },
  '23123': {
    zip: '23123',
    city: 'New Canton',
    state: 'VA',
    latitude: '37.646155',
    longitude: '-78.286693',
    timeZoneId: 'America/New_York',
  },
  '23124': {
    zip: '23124',
    city: 'New Kent',
    state: 'VA',
    latitude: '37.536551',
    longitude: '-77.027884',
    timeZoneId: 'America/New_York',
  },
  '23125': {
    zip: '23125',
    city: 'New Point',
    state: 'VA',
    latitude: '37.349596',
    longitude: '-76.279804',
    timeZoneId: 'America/New_York',
  },
  '23126': {
    zip: '23126',
    city: 'Newtown',
    state: 'VA',
    latitude: '37.877373',
    longitude: '-77.115657',
    timeZoneId: 'America/New_York',
  },
  '23127': {
    zip: '23127',
    city: 'Norge',
    state: 'VA',
    latitude: '37.364207',
    longitude: '-76.771807',
    timeZoneId: 'America/New_York',
  },
  '23128': {
    zip: '23128',
    city: 'North',
    state: 'VA',
    latitude: '37.46859',
    longitude: '-76.377198',
    timeZoneId: 'America/New_York',
  },
  '23129': {
    zip: '23129',
    city: 'Oilville',
    state: 'VA',
    latitude: '37.707016',
    longitude: '-77.779889',
    timeZoneId: 'America/New_York',
  },
  '23130': {
    zip: '23130',
    city: 'Onemo',
    state: 'VA',
    latitude: '37.401156',
    longitude: '-76.279761',
    timeZoneId: 'America/New_York',
  },
  '23131': {
    zip: '23131',
    city: 'Ordinary',
    state: 'VA',
    latitude: '37.314816',
    longitude: '-76.514907',
    timeZoneId: 'America/New_York',
  },
  '23138': {
    zip: '23138',
    city: 'Port Haywood',
    state: 'VA',
    latitude: '37.371944',
    longitude: '-76.279722',
    timeZoneId: 'America/New_York',
  },
  '23139': {
    zip: '23139',
    city: 'Powhatan',
    state: 'VA',
    latitude: '37.544219',
    longitude: '-77.877814',
    timeZoneId: 'America/New_York',
  },
  '23140': {
    zip: '23140',
    city: 'Providence Forge',
    state: 'VA',
    latitude: '37.443403',
    longitude: '-77.056714',
    timeZoneId: 'America/New_York',
  },
  '23141': {
    zip: '23141',
    city: 'Quinton',
    state: 'VA',
    latitude: '37.531619',
    longitude: '-77.162791',
    timeZoneId: 'America/New_York',
  },
  '23146': {
    zip: '23146',
    city: 'Rockville',
    state: 'VA',
    latitude: '37.700079',
    longitude: '-77.706409',
    timeZoneId: 'America/New_York',
  },
  '23147': {
    zip: '23147',
    city: 'Ruthville',
    state: 'VA',
    latitude: '37.367235',
    longitude: '-77.041322',
    timeZoneId: 'America/New_York',
  },
  '23148': {
    zip: '23148',
    city: 'Saint Stephens Church',
    state: 'VA',
    latitude: '37.914841',
    longitude: '-77.076387',
    timeZoneId: 'America/New_York',
  },
  '23149': {
    zip: '23149',
    city: 'Saluda',
    state: 'VA',
    latitude: '37.578281',
    longitude: '-76.606448',
    timeZoneId: 'America/New_York',
  },
  '23150': {
    zip: '23150',
    city: 'Sandston',
    state: 'VA',
    latitude: '37.507416',
    longitude: '-77.252885',
    timeZoneId: 'America/New_York',
  },
  '23153': {
    zip: '23153',
    city: 'Sandy Hook',
    state: 'VA',
    latitude: '37.779513',
    longitude: '-77.964754',
    timeZoneId: 'America/New_York',
  },
  '23154': {
    zip: '23154',
    city: 'Schley',
    state: 'VA',
    latitude: '37.389953',
    longitude: '-76.458186',
    timeZoneId: 'America/New_York',
  },
  '23155': {
    zip: '23155',
    city: 'Severn',
    state: 'VA',
    latitude: '37.294733',
    longitude: '-76.416395',
    timeZoneId: 'America/New_York',
  },
  '23156': {
    zip: '23156',
    city: 'Shacklefords',
    state: 'VA',
    latitude: '37.57668',
    longitude: '-76.72938',
    timeZoneId: 'America/New_York',
  },
  '23160': {
    zip: '23160',
    city: 'State Farm',
    state: 'VA',
    latitude: '37.646444',
    longitude: '-77.845488',
    timeZoneId: 'America/New_York',
  },
  '23161': {
    zip: '23161',
    city: 'Stevensville',
    state: 'VA',
    latitude: '37.706038',
    longitude: '-76.936507',
    timeZoneId: 'America/New_York',
  },
  '23162': {
    zip: '23162',
    city: 'Studley',
    state: 'VA',
    latitude: '37.675314',
    longitude: '-77.290214',
    timeZoneId: 'America/New_York',
  },
  '23163': {
    zip: '23163',
    city: 'Susan',
    state: 'VA',
    latitude: '37.349167',
    longitude: '-76.296944',
    timeZoneId: 'America/New_York',
  },
  '23168': {
    zip: '23168',
    city: 'Toano',
    state: 'VA',
    latitude: '37.394198',
    longitude: '-76.825905',
    timeZoneId: 'America/New_York',
  },
  '23169': {
    zip: '23169',
    city: 'Topping',
    state: 'VA',
    latitude: '37.599',
    longitude: '-76.45182',
    timeZoneId: 'America/New_York',
  },
  '23170': {
    zip: '23170',
    city: 'Trevilians',
    state: 'VA',
    latitude: '38.053742',
    longitude: '-78.072476',
    timeZoneId: 'America/New_York',
  },
  '23173': {
    zip: '23173',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.577785',
    longitude: '-77.534555',
    timeZoneId: 'America/New_York',
  },
  '23175': {
    zip: '23175',
    city: 'Urbanna',
    state: 'VA',
    latitude: '37.652942',
    longitude: '-76.612774',
    timeZoneId: 'America/New_York',
  },
  '23176': {
    zip: '23176',
    city: 'Wake',
    state: 'VA',
    latitude: '37.564504',
    longitude: '-76.427978',
    timeZoneId: 'America/New_York',
  },
  '23177': {
    zip: '23177',
    city: 'Walkerton',
    state: 'VA',
    latitude: '37.764484',
    longitude: '-77.011691',
    timeZoneId: 'America/New_York',
  },
  '23178': {
    zip: '23178',
    city: 'Ware Neck',
    state: 'VA',
    latitude: '37.401767',
    longitude: '-76.455747',
    timeZoneId: 'America/New_York',
  },
  '23180': {
    zip: '23180',
    city: 'Water View',
    state: 'VA',
    latitude: '37.700809',
    longitude: '-76.619083',
    timeZoneId: 'America/New_York',
  },
  '23181': {
    zip: '23181',
    city: 'West Point',
    state: 'VA',
    latitude: '37.556161',
    longitude: '-76.810679',
    timeZoneId: 'America/New_York',
  },
  '23183': {
    zip: '23183',
    city: 'White Marsh',
    state: 'VA',
    latitude: '37.34182',
    longitude: '-76.520077',
    timeZoneId: 'America/New_York',
  },
  '23184': {
    zip: '23184',
    city: 'Wicomico',
    state: 'VA',
    latitude: '37.292098',
    longitude: '-76.509951',
    timeZoneId: 'America/New_York',
  },
  '23185': {
    zip: '23185',
    city: 'Williamsburg',
    state: 'VA',
    latitude: '37.266334',
    longitude: '-76.709764',
    timeZoneId: 'America/New_York',
  },
  '23186': {
    zip: '23186',
    city: 'Williamsburg',
    state: 'VA',
    latitude: '37.272593',
    longitude: '-76.682518',
    timeZoneId: 'America/New_York',
  },
  '23187': {
    zip: '23187',
    city: 'Williamsburg',
    state: 'VA',
    latitude: '37.268238',
    longitude: '-76.710083',
    timeZoneId: 'America/New_York',
  },
  '23188': {
    zip: '23188',
    city: 'Williamsburg',
    state: 'VA',
    latitude: '37.326834',
    longitude: '-76.760558',
    timeZoneId: 'America/New_York',
  },
  '23190': {
    zip: '23190',
    city: 'Woods Cross Roads',
    state: 'VA',
    latitude: '37.483538',
    longitude: '-76.620692',
    timeZoneId: 'America/New_York',
  },
  '23192': {
    zip: '23192',
    city: 'Montpelier',
    state: 'VA',
    latitude: '37.811538',
    longitude: '-77.593495',
    timeZoneId: 'America/New_York',
  },
  '23218': {
    zip: '23218',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.538272',
    longitude: '-77.439111',
    timeZoneId: 'America/New_York',
  },
  '23219': {
    zip: '23219',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.541877',
    longitude: '-77.43628',
    timeZoneId: 'America/New_York',
  },
  '23220': {
    zip: '23220',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.553206',
    longitude: '-77.456383',
    timeZoneId: 'America/New_York',
  },
  '23221': {
    zip: '23221',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.558348',
    longitude: '-77.48865',
    timeZoneId: 'America/New_York',
  },
  '23222': {
    zip: '23222',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.581778',
    longitude: '-77.418908',
    timeZoneId: 'America/New_York',
  },
  '23223': {
    zip: '23223',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.558121',
    longitude: '-77.374277',
    timeZoneId: 'America/New_York',
  },
  '23224': {
    zip: '23224',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.49502',
    longitude: '-77.4771',
    timeZoneId: 'America/New_York',
  },
  '23225': {
    zip: '23225',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.51742',
    longitude: '-77.501037',
    timeZoneId: 'America/New_York',
  },
  '23226': {
    zip: '23226',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.579818',
    longitude: '-77.521626',
    timeZoneId: 'America/New_York',
  },
  '23227': {
    zip: '23227',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.61082',
    longitude: '-77.43996',
    timeZoneId: 'America/New_York',
  },
  '23228': {
    zip: '23228',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.624801',
    longitude: '-77.497483',
    timeZoneId: 'America/New_York',
  },
  '23229': {
    zip: '23229',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.602592',
    longitude: '-77.557517',
    timeZoneId: 'America/New_York',
  },
  '23230': {
    zip: '23230',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.592154',
    longitude: '-77.497969',
    timeZoneId: 'America/New_York',
  },
  '23231': {
    zip: '23231',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.45944',
    longitude: '-77.31444',
    timeZoneId: 'America/New_York',
  },
  '23232': {
    zip: '23232',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.560246',
    longitude: '-77.447422',
    timeZoneId: 'America/New_York',
  },
  '23233': {
    zip: '23233',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.62284',
    longitude: '-77.613493',
    timeZoneId: 'America/New_York',
  },
  '23234': {
    zip: '23234',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.452407',
    longitude: '-77.476225',
    timeZoneId: 'America/New_York',
  },
  '23235': {
    zip: '23235',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.50204',
    longitude: '-77.56992',
    timeZoneId: 'America/New_York',
  },
  '23236': {
    zip: '23236',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.478368',
    longitude: '-77.582532',
    timeZoneId: 'America/New_York',
  },
  '23237': {
    zip: '23237',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.400976',
    longitude: '-77.461897',
    timeZoneId: 'America/New_York',
  },
  '23238': {
    zip: '23238',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.602561',
    longitude: '-77.663297',
    timeZoneId: 'America/New_York',
  },
  '23241': {
    zip: '23241',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.585084',
    longitude: '-77.420677',
    timeZoneId: 'America/New_York',
  },
  '23242': {
    zip: '23242',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.619818',
    longitude: '-77.613981',
    timeZoneId: 'America/New_York',
  },
  '23249': {
    zip: '23249',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.494632',
    longitude: '-77.46764',
    timeZoneId: 'America/New_York',
  },
  '23250': {
    zip: '23250',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.50373',
    longitude: '-77.3375',
    timeZoneId: 'America/New_York',
  },
  '23255': {
    zip: '23255',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.588723',
    longitude: '-77.567833',
    timeZoneId: 'America/New_York',
  },
  '23260': {
    zip: '23260',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.549463',
    longitude: '-77.459359',
    timeZoneId: 'America/New_York',
  },
  '23261': {
    zip: '23261',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.548641',
    longitude: '-77.458413',
    timeZoneId: 'America/New_York',
  },
  '23269': {
    zip: '23269',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.557901',
    longitude: '-77.46764',
    timeZoneId: 'America/New_York',
  },
  '23273': {
    zip: '23273',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.587216',
    longitude: '-77.495005',
    timeZoneId: 'America/New_York',
  },
  '23274': {
    zip: '23274',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.542735',
    longitude: '-77.446569',
    timeZoneId: 'America/New_York',
  },
  '23276': {
    zip: '23276',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.559744',
    longitude: '-77.446742',
    timeZoneId: 'America/New_York',
  },
  '23278': {
    zip: '23278',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.558671',
    longitude: '-77.443034',
    timeZoneId: 'America/New_York',
  },
  '23279': {
    zip: '23279',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.583347',
    longitude: '-77.491807',
    timeZoneId: 'America/New_York',
  },
  '23282': {
    zip: '23282',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.569356',
    longitude: '-77.478092',
    timeZoneId: 'America/New_York',
  },
  '23284': {
    zip: '23284',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.553021',
    longitude: '-77.457951',
    timeZoneId: 'America/New_York',
  },
  '23285': {
    zip: '23285',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.538327',
    longitude: '-77.435822',
    timeZoneId: 'America/New_York',
  },
  '23286': {
    zip: '23286',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.557404',
    longitude: '-77.447536',
    timeZoneId: 'America/New_York',
  },
  '23288': {
    zip: '23288',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.601923',
    longitude: '-77.546947',
    timeZoneId: 'America/New_York',
  },
  '23289': {
    zip: '23289',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.542258',
    longitude: '-77.434021',
    timeZoneId: 'America/New_York',
  },
  '23290': {
    zip: '23290',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.550454',
    longitude: '-77.473709',
    timeZoneId: 'America/New_York',
  },
  '23291': {
    zip: '23291',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.544706',
    longitude: '-77.445183',
    timeZoneId: 'America/New_York',
  },
  '23292': {
    zip: '23292',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.54258',
    longitude: '-77.444722',
    timeZoneId: 'America/New_York',
  },
  '23293': {
    zip: '23293',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.441334',
    longitude: '-77.322755',
    timeZoneId: 'America/New_York',
  },
  '23294': {
    zip: '23294',
    city: 'Henrico',
    state: 'VA',
    latitude: '37.630728',
    longitude: '-77.544233',
    timeZoneId: 'America/New_York',
  },
  '23295': {
    zip: '23295',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.555283',
    longitude: '-77.461217',
    timeZoneId: 'America/New_York',
  },
  '23297': {
    zip: '23297',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.399712',
    longitude: '-77.457391',
    timeZoneId: 'America/New_York',
  },
  '23298': {
    zip: '23298',
    city: 'Richmond',
    state: 'VA',
    latitude: '37.540976',
    longitude: '-77.425624',
    timeZoneId: 'America/New_York',
  },
  '23301': {
    zip: '23301',
    city: 'Accomac',
    state: 'VA',
    latitude: '37.705746',
    longitude: '-75.66405',
    timeZoneId: 'America/New_York',
  },
  '23302': {
    zip: '23302',
    city: 'Assawoman',
    state: 'VA',
    latitude: '37.856541',
    longitude: '-75.515792',
    timeZoneId: 'America/New_York',
  },
  '23303': {
    zip: '23303',
    city: 'Atlantic',
    state: 'VA',
    latitude: '37.90173',
    longitude: '-75.509132',
    timeZoneId: 'America/New_York',
  },
  '23304': {
    zip: '23304',
    city: 'Battery Park',
    state: 'VA',
    latitude: '36.996165',
    longitude: '-76.577882',
    timeZoneId: 'America/New_York',
  },
  '23306': {
    zip: '23306',
    city: 'Belle Haven',
    state: 'VA',
    latitude: '37.582273',
    longitude: '-75.875233',
    timeZoneId: 'America/New_York',
  },
  '23307': {
    zip: '23307',
    city: 'Birdsnest',
    state: 'VA',
    latitude: '37.436981',
    longitude: '-75.894593',
    timeZoneId: 'America/New_York',
  },
  '23308': {
    zip: '23308',
    city: 'Bloxom',
    state: 'VA',
    latitude: '37.824339',
    longitude: '-75.612737',
    timeZoneId: 'America/New_York',
  },
  '23310': {
    zip: '23310',
    city: 'Cape Charles',
    state: 'VA',
    latitude: '37.269849',
    longitude: '-76.019571',
    timeZoneId: 'America/New_York',
  },
  '23313': {
    zip: '23313',
    city: 'Capeville',
    state: 'VA',
    latitude: '37.204036',
    longitude: '-75.975851',
    timeZoneId: 'America/New_York',
  },
  '23314': {
    zip: '23314',
    city: 'Carrollton',
    state: 'VA',
    latitude: '36.940479',
    longitude: '-76.526487',
    timeZoneId: 'America/New_York',
  },
  '23315': {
    zip: '23315',
    city: 'Carrsville',
    state: 'VA',
    latitude: '36.743516',
    longitude: '-76.837238',
    timeZoneId: 'America/New_York',
  },
  '23316': {
    zip: '23316',
    city: 'Cheriton',
    state: 'VA',
    latitude: '37.27659',
    longitude: '-75.961122',
    timeZoneId: 'America/New_York',
  },
  '23320': {
    zip: '23320',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.7434',
    longitude: '-76.22574',
    timeZoneId: 'America/New_York',
  },
  '23321': {
    zip: '23321',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.797907',
    longitude: '-76.425462',
    timeZoneId: 'America/New_York',
  },
  '23322': {
    zip: '23322',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.579718',
    longitude: '-76.307527',
    timeZoneId: 'America/New_York',
  },
  '23323': {
    zip: '23323',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.71436',
    longitude: '-76.29426',
    timeZoneId: 'America/New_York',
  },
  '23324': {
    zip: '23324',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.799974',
    longitude: '-76.267142',
    timeZoneId: 'America/New_York',
  },
  '23325': {
    zip: '23325',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.813481',
    longitude: '-76.238375',
    timeZoneId: 'America/New_York',
  },
  '23326': {
    zip: '23326',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.763676',
    longitude: '-76.252135',
    timeZoneId: 'America/New_York',
  },
  '23327': {
    zip: '23327',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.739387',
    longitude: '-76.230004',
    timeZoneId: 'America/New_York',
  },
  '23328': {
    zip: '23328',
    city: 'Chesapeake',
    state: 'VA',
    latitude: '36.73187',
    longitude: '-76.230359',
    timeZoneId: 'America/New_York',
  },
  '23336': {
    zip: '23336',
    city: 'Chincoteague Island',
    state: 'VA',
    latitude: '37.929948',
    longitude: '-75.376428',
    timeZoneId: 'America/New_York',
  },
  '23337': {
    zip: '23337',
    city: 'Wallops Island',
    state: 'VA',
    latitude: '37.862571',
    longitude: '-75.462907',
    timeZoneId: 'America/New_York',
  },
  '23341': {
    zip: '23341',
    city: 'Craddockville',
    state: 'VA',
    latitude: '37.576848',
    longitude: '-75.796523',
    timeZoneId: 'America/New_York',
  },
  '23345': {
    zip: '23345',
    city: 'Davis Wharf',
    state: 'VA',
    latitude: '37.569999',
    longitude: '-75.888446',
    timeZoneId: 'America/New_York',
  },
  '23347': {
    zip: '23347',
    city: 'Eastville',
    state: 'VA',
    latitude: '37.278564',
    longitude: '-75.960252',
    timeZoneId: 'America/New_York',
  },
  '23350': {
    zip: '23350',
    city: 'Exmore',
    state: 'VA',
    latitude: '37.535807',
    longitude: '-75.824342',
    timeZoneId: 'America/New_York',
  },
  '23354': {
    zip: '23354',
    city: 'Franktown',
    state: 'VA',
    latitude: '37.46982',
    longitude: '-75.91386',
    timeZoneId: 'America/New_York',
  },
  '23356': {
    zip: '23356',
    city: 'Greenbackville',
    state: 'VA',
    latitude: '38.001245',
    longitude: '-75.413882',
    timeZoneId: 'America/New_York',
  },
  '23357': {
    zip: '23357',
    city: 'Greenbush',
    state: 'VA',
    latitude: '37.760282',
    longitude: '-75.675288',
    timeZoneId: 'America/New_York',
  },
  '23358': {
    zip: '23358',
    city: 'Hacksneck',
    state: 'VA',
    latitude: '37.58116',
    longitude: '-75.796356',
    timeZoneId: 'America/New_York',
  },
  '23359': {
    zip: '23359',
    city: 'Hallwood',
    state: 'VA',
    latitude: '37.846582',
    longitude: '-75.549931',
    timeZoneId: 'America/New_York',
  },
  '23389': {
    zip: '23389',
    city: 'Harborton',
    state: 'VA',
    latitude: '37.637659',
    longitude: '-75.746119',
    timeZoneId: 'America/New_York',
  },
  '23395': {
    zip: '23395',
    city: 'Horntown',
    state: 'VA',
    latitude: '37.970467',
    longitude: '-75.475713',
    timeZoneId: 'America/New_York',
  },
  '23396': {
    zip: '23396',
    city: 'Oak Hall',
    state: 'VA',
    latitude: '37.925278',
    longitude: '-75.558889',
    timeZoneId: 'America/New_York',
  },
  '23397': {
    zip: '23397',
    city: 'Isle of Wight',
    state: 'VA',
    latitude: '36.853023',
    longitude: '-76.721406',
    timeZoneId: 'America/New_York',
  },
  '23398': {
    zip: '23398',
    city: 'Jamesville',
    state: 'VA',
    latitude: '37.510425',
    longitude: '-75.870874',
    timeZoneId: 'America/New_York',
  },
  '23399': {
    zip: '23399',
    city: 'Jenkins Bridge',
    state: 'VA',
    latitude: '37.917093',
    longitude: '-75.635031',
    timeZoneId: 'America/New_York',
  },
  '23401': {
    zip: '23401',
    city: 'Keller',
    state: 'VA',
    latitude: '37.640943',
    longitude: '-75.745171',
    timeZoneId: 'America/New_York',
  },
  '23404': {
    zip: '23404',
    city: 'Locustville',
    state: 'VA',
    latitude: '37.644101',
    longitude: '-75.669243',
    timeZoneId: 'America/New_York',
  },
  '23405': {
    zip: '23405',
    city: 'Machipongo',
    state: 'VA',
    latitude: '37.403672',
    longitude: '-75.910923',
    timeZoneId: 'America/New_York',
  },
  '23407': {
    zip: '23407',
    city: 'Mappsville',
    state: 'VA',
    latitude: '37.835028',
    longitude: '-75.576362',
    timeZoneId: 'America/New_York',
  },
  '23408': {
    zip: '23408',
    city: 'Marionville',
    state: 'VA',
    latitude: '37.438885',
    longitude: '-75.895385',
    timeZoneId: 'America/New_York',
  },
  '23409': {
    zip: '23409',
    city: 'Mears',
    state: 'VA',
    latitude: '37.878182',
    longitude: '-75.650443',
    timeZoneId: 'America/New_York',
  },
  '23410': {
    zip: '23410',
    city: 'Melfa',
    state: 'VA',
    latitude: '37.639778',
    longitude: '-75.748841',
    timeZoneId: 'America/New_York',
  },
  '23412': {
    zip: '23412',
    city: 'Modest Town',
    state: 'VA',
    latitude: '37.833363',
    longitude: '-75.568987',
    timeZoneId: 'America/New_York',
  },
  '23413': {
    zip: '23413',
    city: 'Nassawadox',
    state: 'VA',
    latitude: '37.47468',
    longitude: '-75.8586',
    timeZoneId: 'America/New_York',
  },
  '23414': {
    zip: '23414',
    city: 'Nelsonia',
    state: 'VA',
    latitude: '37.832292',
    longitude: '-75.570013',
    timeZoneId: 'America/New_York',
  },
  '23415': {
    zip: '23415',
    city: 'New Church',
    state: 'VA',
    latitude: '37.960394',
    longitude: '-75.510149',
    timeZoneId: 'America/New_York',
  },
  '23416': {
    zip: '23416',
    city: 'Oak Hall',
    state: 'VA',
    latitude: '37.952374',
    longitude: '-75.616004',
    timeZoneId: 'America/New_York',
  },
  '23417': {
    zip: '23417',
    city: 'Onancock',
    state: 'VA',
    latitude: '37.713408',
    longitude: '-75.753403',
    timeZoneId: 'America/New_York',
  },
  '23418': {
    zip: '23418',
    city: 'Onley',
    state: 'VA',
    latitude: '37.662299',
    longitude: '-75.687848',
    timeZoneId: 'America/New_York',
  },
  '23419': {
    zip: '23419',
    city: 'Oyster',
    state: 'VA',
    latitude: '37.284567',
    longitude: '-75.924132',
    timeZoneId: 'America/New_York',
  },
  '23420': {
    zip: '23420',
    city: 'Painter',
    state: 'VA',
    latitude: '37.575832',
    longitude: '-75.794935',
    timeZoneId: 'America/New_York',
  },
  '23421': {
    zip: '23421',
    city: 'Parksley',
    state: 'VA',
    latitude: '37.788179',
    longitude: '-75.655317',
    timeZoneId: 'America/New_York',
  },
  '23422': {
    zip: '23422',
    city: 'Pungoteague',
    state: 'VA',
    latitude: '37.579042',
    longitude: '-75.795534',
    timeZoneId: 'America/New_York',
  },
  '23423': {
    zip: '23423',
    city: 'Quinby',
    state: 'VA',
    latitude: '37.579482',
    longitude: '-75.793864',
    timeZoneId: 'America/New_York',
  },
  '23424': {
    zip: '23424',
    city: 'Rescue',
    state: 'VA',
    latitude: '36.954334',
    longitude: '-76.545889',
    timeZoneId: 'America/New_York',
  },
  '23426': {
    zip: '23426',
    city: 'Sanford',
    state: 'VA',
    latitude: '37.922079',
    longitude: '-75.683828',
    timeZoneId: 'America/New_York',
  },
  '23427': {
    zip: '23427',
    city: 'Saxis',
    state: 'VA',
    latitude: '37.921375',
    longitude: '-75.683849',
    timeZoneId: 'America/New_York',
  },
  '23429': {
    zip: '23429',
    city: 'Seaview',
    state: 'VA',
    latitude: '37.27619',
    longitude: '-75.957353',
    timeZoneId: 'America/New_York',
  },
  '23430': {
    zip: '23430',
    city: 'Smithfield',
    state: 'VA',
    latitude: '36.984938',
    longitude: '-76.619668',
    timeZoneId: 'America/New_York',
  },
  '23431': {
    zip: '23431',
    city: 'Smithfield',
    state: 'VA',
    latitude: '36.983696',
    longitude: '-76.616759',
    timeZoneId: 'America/New_York',
  },
  '23432': {
    zip: '23432',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.87012',
    longitude: '-76.55502',
    timeZoneId: 'America/New_York',
  },
  '23433': {
    zip: '23433',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.9063',
    longitude: '-76.49256',
    timeZoneId: 'America/New_York',
  },
  '23434': {
    zip: '23434',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.731942',
    longitude: '-76.60187',
    timeZoneId: 'America/New_York',
  },
  '23435': {
    zip: '23435',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.84966',
    longitude: '-76.47936',
    timeZoneId: 'America/New_York',
  },
  '23436': {
    zip: '23436',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.894714',
    longitude: '-76.513517',
    timeZoneId: 'America/New_York',
  },
  '23437': {
    zip: '23437',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.627139',
    longitude: '-76.791066',
    timeZoneId: 'America/New_York',
  },
  '23438': {
    zip: '23438',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.563256',
    longitude: '-76.717225',
    timeZoneId: 'America/New_York',
  },
  '23439': {
    zip: '23439',
    city: 'Suffolk',
    state: 'VA',
    latitude: '36.700891',
    longitude: '-76.637248',
    timeZoneId: 'America/New_York',
  },
  '23440': {
    zip: '23440',
    city: 'Tangier',
    state: 'VA',
    latitude: '37.830029',
    longitude: '-75.993029',
    timeZoneId: 'America/New_York',
  },
  '23441': {
    zip: '23441',
    city: 'Tasley',
    state: 'VA',
    latitude: '37.72007',
    longitude: '-75.776007',
    timeZoneId: 'America/New_York',
  },
  '23442': {
    zip: '23442',
    city: 'Temperanceville',
    state: 'VA',
    latitude: '37.895258',
    longitude: '-75.559531',
    timeZoneId: 'America/New_York',
  },
  '23443': {
    zip: '23443',
    city: 'Townsend',
    state: 'VA',
    latitude: '37.280198',
    longitude: '-75.958655',
    timeZoneId: 'America/New_York',
  },
  '23450': {
    zip: '23450',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.84403',
    longitude: '-76.093079',
    timeZoneId: 'America/New_York',
  },
  '23451': {
    zip: '23451',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.86373',
    longitude: '-76.012395',
    timeZoneId: 'America/New_York',
  },
  '23452': {
    zip: '23452',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.839129',
    longitude: '-76.090953',
    timeZoneId: 'America/New_York',
  },
  '23453': {
    zip: '23453',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.774472',
    longitude: '-76.075562',
    timeZoneId: 'America/New_York',
  },
  '23454': {
    zip: '23454',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.837731',
    longitude: '-76.025953',
    timeZoneId: 'America/New_York',
  },
  '23455': {
    zip: '23455',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.888297',
    longitude: '-76.144677',
    timeZoneId: 'America/New_York',
  },
  '23456': {
    zip: '23456',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.775899',
    longitude: '-76.075614',
    timeZoneId: 'America/New_York',
  },
  '23457': {
    zip: '23457',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.618897',
    longitude: '-76.058292',
    timeZoneId: 'America/New_York',
  },
  '23458': {
    zip: '23458',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.853343',
    longitude: '-75.975659',
    timeZoneId: 'America/New_York',
  },
  '23459': {
    zip: '23459',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.88164',
    longitude: '-76.00152',
    timeZoneId: 'America/New_York',
  },
  '23460': {
    zip: '23460',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.814484',
    longitude: '-76.025796',
    timeZoneId: 'America/New_York',
  },
  '23461': {
    zip: '23461',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.852778',
    longitude: '-75.978333',
    timeZoneId: 'America/New_York',
  },
  '23462': {
    zip: '23462',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.839595',
    longitude: '-76.151374',
    timeZoneId: 'America/New_York',
  },
  '23463': {
    zip: '23463',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.801576',
    longitude: '-76.176454',
    timeZoneId: 'America/New_York',
  },
  '23464': {
    zip: '23464',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.798333',
    longitude: '-76.177537',
    timeZoneId: 'America/New_York',
  },
  '23465': {
    zip: '23465',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.797995',
    longitude: '-76.176483',
    timeZoneId: 'America/New_York',
  },
  '23466': {
    zip: '23466',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.83836',
    longitude: '-76.153224',
    timeZoneId: 'America/New_York',
  },
  '23467': {
    zip: '23467',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.801711',
    longitude: '-76.174222',
    timeZoneId: 'America/New_York',
  },
  '23471': {
    zip: '23471',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.893954',
    longitude: '-76.147453',
    timeZoneId: 'America/New_York',
  },
  '23479': {
    zip: '23479',
    city: 'Virginia Beach',
    state: 'VA',
    latitude: '36.846156',
    longitude: '-76.092025',
    timeZoneId: 'America/New_York',
  },
  '23480': {
    zip: '23480',
    city: 'Wachapreague',
    state: 'VA',
    latitude: '37.602689',
    longitude: '-75.689312',
    timeZoneId: 'America/New_York',
  },
  '23482': {
    zip: '23482',
    city: 'Wardtown',
    state: 'VA',
    latitude: '37.507734',
    longitude: '-75.87035',
    timeZoneId: 'America/New_York',
  },
  '23483': {
    zip: '23483',
    city: 'Wattsville',
    state: 'VA',
    latitude: '37.961523',
    longitude: '-75.513407',
    timeZoneId: 'America/New_York',
  },
  '23486': {
    zip: '23486',
    city: 'Willis Wharf',
    state: 'VA',
    latitude: '37.509821',
    longitude: '-75.871865',
    timeZoneId: 'America/New_York',
  },
  '23487': {
    zip: '23487',
    city: 'Windsor',
    state: 'VA',
    latitude: '36.815167',
    longitude: '-76.744722',
    timeZoneId: 'America/New_York',
  },
  '23488': {
    zip: '23488',
    city: 'Withams',
    state: 'VA',
    latitude: '37.962106',
    longitude: '-75.511229',
    timeZoneId: 'America/New_York',
  },
  '23501': {
    zip: '23501',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.899082',
    longitude: '-76.206445',
    timeZoneId: 'America/New_York',
  },
  '23502': {
    zip: '23502',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.852645',
    longitude: '-76.21265',
    timeZoneId: 'America/New_York',
  },
  '23503': {
    zip: '23503',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.945923',
    longitude: '-76.253486',
    timeZoneId: 'America/New_York',
  },
  '23504': {
    zip: '23504',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.859599',
    longitude: '-76.26583',
    timeZoneId: 'America/New_York',
  },
  '23505': {
    zip: '23505',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.911339',
    longitude: '-76.284855',
    timeZoneId: 'America/New_York',
  },
  '23506': {
    zip: '23506',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.856945',
    longitude: '-76.211968',
    timeZoneId: 'America/New_York',
  },
  '23507': {
    zip: '23507',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.863555',
    longitude: '-76.299662',
    timeZoneId: 'America/New_York',
  },
  '23508': {
    zip: '23508',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.887613',
    longitude: '-76.29954',
    timeZoneId: 'America/New_York',
  },
  '23509': {
    zip: '23509',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.880723',
    longitude: '-76.263506',
    timeZoneId: 'America/New_York',
  },
  '23510': {
    zip: '23510',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.851128',
    longitude: '-76.291171',
    timeZoneId: 'America/New_York',
  },
  '23511': {
    zip: '23511',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.942636',
    longitude: '-76.307176',
    timeZoneId: 'America/New_York',
  },
  '23513': {
    zip: '23513',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.887779',
    longitude: '-76.23846',
    timeZoneId: 'America/New_York',
  },
  '23514': {
    zip: '23514',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.851923',
    longitude: '-76.292261',
    timeZoneId: 'America/New_York',
  },
  '23515': {
    zip: '23515',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.915605',
    longitude: '-76.304',
    timeZoneId: 'America/New_York',
  },
  '23517': {
    zip: '23517',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.866827',
    longitude: '-76.290455',
    timeZoneId: 'America/New_York',
  },
  '23518': {
    zip: '23518',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.916006',
    longitude: '-76.22142',
    timeZoneId: 'America/New_York',
  },
  '23519': {
    zip: '23519',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.923406',
    longitude: '-76.187822',
    timeZoneId: 'America/New_York',
  },
  '23523': {
    zip: '23523',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.831464',
    longitude: '-76.269871',
    timeZoneId: 'America/New_York',
  },
  '23529': {
    zip: '23529',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.884721',
    longitude: '-76.311064',
    timeZoneId: 'America/New_York',
  },
  '23541': {
    zip: '23541',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.857875',
    longitude: '-76.211427',
    timeZoneId: 'America/New_York',
  },
  '23551': {
    zip: '23551',
    city: 'Norfolk',
    state: 'VA',
    latitude: '36.848151',
    longitude: '-76.288565',
    timeZoneId: 'America/New_York',
  },
  '23601': {
    zip: '23601',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.057272',
    longitude: '-76.460838',
    timeZoneId: 'America/New_York',
  },
  '23602': {
    zip: '23602',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.124608',
    longitude: '-76.519972',
    timeZoneId: 'America/New_York',
  },
  '23603': {
    zip: '23603',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.190578',
    longitude: '-76.562567',
    timeZoneId: 'America/New_York',
  },
  '23604': {
    zip: '23604',
    city: 'Fort Eustis',
    state: 'VA',
    latitude: '36.978927',
    longitude: '-76.426918',
    timeZoneId: 'America/New_York',
  },
  '23605': {
    zip: '23605',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.019385',
    longitude: '-76.437883',
    timeZoneId: 'America/New_York',
  },
  '23606': {
    zip: '23606',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.070123',
    longitude: '-76.50031',
    timeZoneId: 'America/New_York',
  },
  '23607': {
    zip: '23607',
    city: 'Newport News',
    state: 'VA',
    latitude: '36.985518',
    longitude: '-76.420528',
    timeZoneId: 'America/New_York',
  },
  '23608': {
    zip: '23608',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.143493',
    longitude: '-76.539049',
    timeZoneId: 'America/New_York',
  },
  '23609': {
    zip: '23609',
    city: 'Newport News',
    state: 'VA',
    latitude: '36.976958',
    longitude: '-76.428558',
    timeZoneId: 'America/New_York',
  },
  '23612': {
    zip: '23612',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.068802',
    longitude: '-76.494931',
    timeZoneId: 'America/New_York',
  },
  '23628': {
    zip: '23628',
    city: 'Newport News',
    state: 'VA',
    latitude: '37.146056',
    longitude: '-76.578123',
    timeZoneId: 'America/New_York',
  },
  '23630': {
    zip: '23630',
    city: 'Hampton',
    state: 'VA',
    latitude: '36.999882',
    longitude: '-76.410732',
    timeZoneId: 'America/New_York',
  },
  '23651': {
    zip: '23651',
    city: 'Fort Monroe',
    state: 'VA',
    latitude: '37.007125',
    longitude: '-76.312335',
    timeZoneId: 'America/New_York',
  },
  '23661': {
    zip: '23661',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.005206',
    longitude: '-76.382577',
    timeZoneId: 'America/New_York',
  },
  '23662': {
    zip: '23662',
    city: 'Poquoson',
    state: 'VA',
    latitude: '37.1331',
    longitude: '-76.3659',
    timeZoneId: 'America/New_York',
  },
  '23663': {
    zip: '23663',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.035132',
    longitude: '-76.315821',
    timeZoneId: 'America/New_York',
  },
  '23664': {
    zip: '23664',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.056659',
    longitude: '-76.293478',
    timeZoneId: 'America/New_York',
  },
  '23665': {
    zip: '23665',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.080265',
    longitude: '-76.373645',
    timeZoneId: 'America/New_York',
  },
  '23666': {
    zip: '23666',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.049912',
    longitude: '-76.412922',
    timeZoneId: 'America/New_York',
  },
  '23667': {
    zip: '23667',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.00953',
    longitude: '-76.385641',
    timeZoneId: 'America/New_York',
  },
  '23668': {
    zip: '23668',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.023786',
    longitude: '-76.339698',
    timeZoneId: 'America/New_York',
  },
  '23669': {
    zip: '23669',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.041146',
    longitude: '-76.337539',
    timeZoneId: 'America/New_York',
  },
  '23670': {
    zip: '23670',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.056273',
    longitude: '-76.412388',
    timeZoneId: 'America/New_York',
  },
  '23681': {
    zip: '23681',
    city: 'Hampton',
    state: 'VA',
    latitude: '37.088709',
    longitude: '-76.366965',
    timeZoneId: 'America/New_York',
  },
  '23690': {
    zip: '23690',
    city: 'Yorktown',
    state: 'VA',
    latitude: '37.225941',
    longitude: '-76.523574',
    timeZoneId: 'America/New_York',
  },
  '23691': {
    zip: '23691',
    city: 'Yorktown',
    state: 'VA',
    latitude: '37.244023',
    longitude: '-76.551905',
    timeZoneId: 'America/New_York',
  },
  '23692': {
    zip: '23692',
    city: 'Yorktown',
    state: 'VA',
    latitude: '37.177139',
    longitude: '-76.459291',
    timeZoneId: 'America/New_York',
  },
  '23693': {
    zip: '23693',
    city: 'Yorktown',
    state: 'VA',
    latitude: '37.1223',
    longitude: '-76.4484',
    timeZoneId: 'America/New_York',
  },
  '23694': {
    zip: '23694',
    city: 'Lackey',
    state: 'VA',
    latitude: '37.22937',
    longitude: '-76.546609',
    timeZoneId: 'America/New_York',
  },
  '23696': {
    zip: '23696',
    city: 'Seaford',
    state: 'VA',
    latitude: '37.188821',
    longitude: '-76.431107',
    timeZoneId: 'America/New_York',
  },
  '23701': {
    zip: '23701',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.809491',
    longitude: '-76.367984',
    timeZoneId: 'America/New_York',
  },
  '23702': {
    zip: '23702',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.80557',
    longitude: '-76.32655',
    timeZoneId: 'America/New_York',
  },
  '23703': {
    zip: '23703',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.866685',
    longitude: '-76.387575',
    timeZoneId: 'America/New_York',
  },
  '23704': {
    zip: '23704',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.830829',
    longitude: '-76.312922',
    timeZoneId: 'America/New_York',
  },
  '23705': {
    zip: '23705',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.838463',
    longitude: '-76.34001',
    timeZoneId: 'America/New_York',
  },
  '23707': {
    zip: '23707',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.834117',
    longitude: '-76.34174',
    timeZoneId: 'America/New_York',
  },
  '23708': {
    zip: '23708',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.829941',
    longitude: '-76.312654',
    timeZoneId: 'America/New_York',
  },
  '23709': {
    zip: '23709',
    city: 'Portsmouth',
    state: 'VA',
    latitude: '36.812989',
    longitude: '-76.309856',
    timeZoneId: 'America/New_York',
  },
  '23801': {
    zip: '23801',
    city: 'Fort Lee',
    state: 'VA',
    latitude: '37.237019',
    longitude: '-77.334813',
    timeZoneId: 'America/New_York',
  },
  '23803': {
    zip: '23803',
    city: 'Petersburg',
    state: 'VA',
    latitude: '37.21386',
    longitude: '-77.45568',
    timeZoneId: 'America/New_York',
  },
  '23804': {
    zip: '23804',
    city: 'Petersburg',
    state: 'VA',
    latitude: '37.229058',
    longitude: '-77.403672',
    timeZoneId: 'America/New_York',
  },
  '23805': {
    zip: '23805',
    city: 'Petersburg',
    state: 'VA',
    latitude: '37.136328',
    longitude: '-77.3919',
    timeZoneId: 'America/New_York',
  },
  '23806': {
    zip: '23806',
    city: 'Virginia State University',
    state: 'VA',
    latitude: '37.226377',
    longitude: '-77.403086',
    timeZoneId: 'America/New_York',
  },
  '23821': {
    zip: '23821',
    city: 'Alberta',
    state: 'VA',
    latitude: '36.83644',
    longitude: '-77.853091',
    timeZoneId: 'America/New_York',
  },
  '23822': {
    zip: '23822',
    city: 'Ammon',
    state: 'VA',
    latitude: '37.161452',
    longitude: '-77.58517',
    timeZoneId: 'America/New_York',
  },
  '23824': {
    zip: '23824',
    city: 'Blackstone',
    state: 'VA',
    latitude: '37.081591',
    longitude: '-77.999211',
    timeZoneId: 'America/New_York',
  },
  '23827': {
    zip: '23827',
    city: 'Boykins',
    state: 'VA',
    latitude: '36.58271',
    longitude: '-77.196811',
    timeZoneId: 'America/New_York',
  },
  '23828': {
    zip: '23828',
    city: 'Branchville',
    state: 'VA',
    latitude: '36.585621',
    longitude: '-77.291884',
    timeZoneId: 'America/New_York',
  },
  '23829': {
    zip: '23829',
    city: 'Capron',
    state: 'VA',
    latitude: '36.732689',
    longitude: '-77.237769',
    timeZoneId: 'America/New_York',
  },
  '23830': {
    zip: '23830',
    city: 'Carson',
    state: 'VA',
    latitude: '37.020904',
    longitude: '-77.397114',
    timeZoneId: 'America/New_York',
  },
  '23831': {
    zip: '23831',
    city: 'Chester',
    state: 'VA',
    latitude: '37.338977',
    longitude: '-77.447582',
    timeZoneId: 'America/New_York',
  },
  '23832': {
    zip: '23832',
    city: 'Chesterfield',
    state: 'VA',
    latitude: '37.40994',
    longitude: '-77.61072',
    timeZoneId: 'America/New_York',
  },
  '23833': {
    zip: '23833',
    city: 'Church Road',
    state: 'VA',
    latitude: '37.217846',
    longitude: '-77.675533',
    timeZoneId: 'America/New_York',
  },
  '23834': {
    zip: '23834',
    city: 'Colonial Heights',
    state: 'VA',
    latitude: '37.287361',
    longitude: '-77.391074',
    timeZoneId: 'America/New_York',
  },
  '23836': {
    zip: '23836',
    city: 'Chester',
    state: 'VA',
    latitude: '37.349033',
    longitude: '-77.32394',
    timeZoneId: 'America/New_York',
  },
  '23837': {
    zip: '23837',
    city: 'Courtland',
    state: 'VA',
    latitude: '36.704339',
    longitude: '-77.059688',
    timeZoneId: 'America/New_York',
  },
  '23838': {
    zip: '23838',
    city: 'Chesterfield',
    state: 'VA',
    latitude: '37.326774',
    longitude: '-77.60735',
    timeZoneId: 'America/New_York',
  },
  '23839': {
    zip: '23839',
    city: 'Dendron',
    state: 'VA',
    latitude: '36.999427',
    longitude: '-76.963096',
    timeZoneId: 'America/New_York',
  },
  '23840': {
    zip: '23840',
    city: 'Dewitt',
    state: 'VA',
    latitude: '37.035224',
    longitude: '-77.65166',
    timeZoneId: 'America/New_York',
  },
  '23841': {
    zip: '23841',
    city: 'Dinwiddie',
    state: 'VA',
    latitude: '37.080906',
    longitude: '-77.549122',
    timeZoneId: 'America/New_York',
  },
  '23842': {
    zip: '23842',
    city: 'Disputanta',
    state: 'VA',
    latitude: '37.15295',
    longitude: '-77.237944',
    timeZoneId: 'America/New_York',
  },
  '23843': {
    zip: '23843',
    city: 'Dolphin',
    state: 'VA',
    latitude: '36.835688',
    longitude: '-77.794255',
    timeZoneId: 'America/New_York',
  },
  '23844': {
    zip: '23844',
    city: 'Drewryville',
    state: 'VA',
    latitude: '36.682602',
    longitude: '-77.360606',
    timeZoneId: 'America/New_York',
  },
  '23845': {
    zip: '23845',
    city: 'Ebony',
    state: 'VA',
    latitude: '36.541036',
    longitude: '-77.981982',
    timeZoneId: 'America/New_York',
  },
  '23846': {
    zip: '23846',
    city: 'Elberon',
    state: 'VA',
    latitude: '37.059423',
    longitude: '-76.822191',
    timeZoneId: 'America/New_York',
  },
  '23847': {
    zip: '23847',
    city: 'Emporia',
    state: 'VA',
    latitude: '36.695563',
    longitude: '-77.543942',
    timeZoneId: 'America/New_York',
  },
  '23850': {
    zip: '23850',
    city: 'Ford',
    state: 'VA',
    latitude: '37.153169',
    longitude: '-77.741192',
    timeZoneId: 'America/New_York',
  },
  '23851': {
    zip: '23851',
    city: 'Franklin',
    state: 'VA',
    latitude: '36.672869',
    longitude: '-76.940007',
    timeZoneId: 'America/New_York',
  },
  '23856': {
    zip: '23856',
    city: 'Freeman',
    state: 'VA',
    latitude: '36.710889',
    longitude: '-77.555557',
    timeZoneId: 'America/New_York',
  },
  '23857': {
    zip: '23857',
    city: 'Gasburg',
    state: 'VA',
    latitude: '36.542588',
    longitude: '-77.877073',
    timeZoneId: 'America/New_York',
  },
  '23860': {
    zip: '23860',
    city: 'Hopewell',
    state: 'VA',
    latitude: '37.286042',
    longitude: '-77.289149',
    timeZoneId: 'America/New_York',
  },
  '23866': {
    zip: '23866',
    city: 'Ivor',
    state: 'VA',
    latitude: '36.911869',
    longitude: '-76.878981',
    timeZoneId: 'America/New_York',
  },
  '23867': {
    zip: '23867',
    city: 'Jarratt',
    state: 'VA',
    latitude: '36.814192',
    longitude: '-77.473012',
    timeZoneId: 'America/New_York',
  },
  '23868': {
    zip: '23868',
    city: 'Lawrenceville',
    state: 'VA',
    latitude: '36.610278',
    longitude: '-77.766111',
    timeZoneId: 'America/New_York',
  },
  '23870': {
    zip: '23870',
    city: 'Jarratt',
    state: 'VA',
    latitude: '36.813701',
    longitude: '-77.469979',
    timeZoneId: 'America/New_York',
  },
  '23872': {
    zip: '23872',
    city: 'Mc Kenney',
    state: 'VA',
    latitude: '37.010182',
    longitude: '-77.725604',
    timeZoneId: 'America/New_York',
  },
  '23873': {
    zip: '23873',
    city: 'Meredithville',
    state: 'VA',
    latitude: '36.805341',
    longitude: '-77.957105',
    timeZoneId: 'America/New_York',
  },
  '23874': {
    zip: '23874',
    city: 'Newsoms',
    state: 'VA',
    latitude: '36.614061',
    longitude: '-77.096376',
    timeZoneId: 'America/New_York',
  },
  '23875': {
    zip: '23875',
    city: 'Prince George',
    state: 'VA',
    latitude: '37.216467',
    longitude: '-77.241927',
    timeZoneId: 'America/New_York',
  },
  '23876': {
    zip: '23876',
    city: 'Rawlings',
    state: 'VA',
    latitude: '36.953239',
    longitude: '-77.833694',
    timeZoneId: 'America/New_York',
  },
  '23878': {
    zip: '23878',
    city: 'Sedley',
    state: 'VA',
    latitude: '36.805778',
    longitude: '-77.024354',
    timeZoneId: 'America/New_York',
  },
  '23879': {
    zip: '23879',
    city: 'Skippers',
    state: 'VA',
    latitude: '36.588846',
    longitude: '-77.542319',
    timeZoneId: 'America/New_York',
  },
  '23881': {
    zip: '23881',
    city: 'Spring Grove',
    state: 'VA',
    latitude: '37.21403',
    longitude: '-77.07098',
    timeZoneId: 'America/New_York',
  },
  '23882': {
    zip: '23882',
    city: 'Stony Creek',
    state: 'VA',
    latitude: '36.938046',
    longitude: '-77.42753',
    timeZoneId: 'America/New_York',
  },
  '23883': {
    zip: '23883',
    city: 'Surry',
    state: 'VA',
    latitude: '37.127433',
    longitude: '-76.752688',
    timeZoneId: 'America/New_York',
  },
  '23884': {
    zip: '23884',
    city: 'Sussex',
    state: 'VA',
    latitude: '36.91627',
    longitude: '-77.279713',
    timeZoneId: 'America/New_York',
  },
  '23885': {
    zip: '23885',
    city: 'Sutherland',
    state: 'VA',
    latitude: '37.19609',
    longitude: '-77.553076',
    timeZoneId: 'America/New_York',
  },
  '23887': {
    zip: '23887',
    city: 'Valentines',
    state: 'VA',
    latitude: '36.560643',
    longitude: '-77.826284',
    timeZoneId: 'America/New_York',
  },
  '23888': {
    zip: '23888',
    city: 'Wakefield',
    state: 'VA',
    latitude: '36.973535',
    longitude: '-76.987737',
    timeZoneId: 'America/New_York',
  },
  '23889': {
    zip: '23889',
    city: 'Warfield',
    state: 'VA',
    latitude: '36.894657',
    longitude: '-77.745989',
    timeZoneId: 'America/New_York',
  },
  '23890': {
    zip: '23890',
    city: 'Waverly',
    state: 'VA',
    latitude: '37.035798',
    longitude: '-77.095681',
    timeZoneId: 'America/New_York',
  },
  '23891': {
    zip: '23891',
    city: 'Waverly',
    state: 'VA',
    latitude: '37.036209',
    longitude: '-77.096824',
    timeZoneId: 'America/New_York',
  },
  '23893': {
    zip: '23893',
    city: 'White Plains',
    state: 'VA',
    latitude: '36.633235',
    longitude: '-77.911891',
    timeZoneId: 'America/New_York',
  },
  '23894': {
    zip: '23894',
    city: 'Wilsons',
    state: 'VA',
    latitude: '37.130551',
    longitude: '-77.854198',
    timeZoneId: 'America/New_York',
  },
  '23897': {
    zip: '23897',
    city: 'Yale',
    state: 'VA',
    latitude: '36.832124',
    longitude: '-77.279258',
    timeZoneId: 'America/New_York',
  },
  '23898': {
    zip: '23898',
    city: 'Zuni',
    state: 'VA',
    latitude: '36.83128',
    longitude: '-76.840923',
    timeZoneId: 'America/New_York',
  },
  '23899': {
    zip: '23899',
    city: 'Claremont',
    state: 'VA',
    latitude: '37.22761',
    longitude: '-76.965605',
    timeZoneId: 'America/New_York',
  },
  '23901': {
    zip: '23901',
    city: 'Farmville',
    state: 'VA',
    latitude: '37.292839',
    longitude: '-78.396546',
    timeZoneId: 'America/New_York',
  },
  '23909': {
    zip: '23909',
    city: 'Farmville',
    state: 'VA',
    latitude: '37.295563',
    longitude: '-78.398027',
    timeZoneId: 'America/New_York',
  },
  '23915': {
    zip: '23915',
    city: 'Baskerville',
    state: 'VA',
    latitude: '36.634077',
    longitude: '-78.298095',
    timeZoneId: 'America/New_York',
  },
  '23917': {
    zip: '23917',
    city: 'Boydton',
    state: 'VA',
    latitude: '36.655688',
    longitude: '-78.378914',
    timeZoneId: 'America/New_York',
  },
  '23919': {
    zip: '23919',
    city: 'Bracey',
    state: 'VA',
    latitude: '36.581289',
    longitude: '-78.112051',
    timeZoneId: 'America/New_York',
  },
  '23920': {
    zip: '23920',
    city: 'Brodnax',
    state: 'VA',
    latitude: '36.736438',
    longitude: '-77.969862',
    timeZoneId: 'America/New_York',
  },
  '23921': {
    zip: '23921',
    city: 'Buckingham',
    state: 'VA',
    latitude: '37.598418',
    longitude: '-78.615702',
    timeZoneId: 'America/New_York',
  },
  '23922': {
    zip: '23922',
    city: 'Burkeville',
    state: 'VA',
    latitude: '37.18465',
    longitude: '-78.202504',
    timeZoneId: 'America/New_York',
  },
  '23923': {
    zip: '23923',
    city: 'Charlotte Court House',
    state: 'VA',
    latitude: '37.091464',
    longitude: '-78.658894',
    timeZoneId: 'America/New_York',
  },
  '23924': {
    zip: '23924',
    city: 'Chase City',
    state: 'VA',
    latitude: '36.796017',
    longitude: '-78.46184',
    timeZoneId: 'America/New_York',
  },
  '23927': {
    zip: '23927',
    city: 'Clarksville',
    state: 'VA',
    latitude: '36.603986',
    longitude: '-78.534312',
    timeZoneId: 'America/New_York',
  },
  '23930': {
    zip: '23930',
    city: 'Crewe',
    state: 'VA',
    latitude: '37.178317',
    longitude: '-78.120232',
    timeZoneId: 'America/New_York',
  },
  '23934': {
    zip: '23934',
    city: 'Cullen',
    state: 'VA',
    latitude: '37.161006',
    longitude: '-78.638349',
    timeZoneId: 'America/New_York',
  },
  '23936': {
    zip: '23936',
    city: 'Dillwyn',
    state: 'VA',
    latitude: '37.520399',
    longitude: '-78.47657',
    timeZoneId: 'America/New_York',
  },
  '23937': {
    zip: '23937',
    city: 'Drakes Branch',
    state: 'VA',
    latitude: '36.954547',
    longitude: '-78.543684',
    timeZoneId: 'America/New_York',
  },
  '23938': {
    zip: '23938',
    city: 'Dundas',
    state: 'VA',
    latitude: '36.906808',
    longitude: '-78.004803',
    timeZoneId: 'America/New_York',
  },
  '23939': {
    zip: '23939',
    city: 'Evergreen',
    state: 'VA',
    latitude: '37.320391',
    longitude: '-78.764202',
    timeZoneId: 'America/New_York',
  },
  '23941': {
    zip: '23941',
    city: 'Fort Mitchell',
    state: 'VA',
    latitude: '36.917169',
    longitude: '-78.488229',
    timeZoneId: 'America/New_York',
  },
  '23942': {
    zip: '23942',
    city: 'Green Bay',
    state: 'VA',
    latitude: '37.13233',
    longitude: '-78.307103',
    timeZoneId: 'America/New_York',
  },
  '23943': {
    zip: '23943',
    city: 'Hampden Sydney',
    state: 'VA',
    latitude: '37.240559',
    longitude: '-78.457075',
    timeZoneId: 'America/New_York',
  },
  '23944': {
    zip: '23944',
    city: 'Kenbridge',
    state: 'VA',
    latitude: '36.959977',
    longitude: '-78.12435',
    timeZoneId: 'America/New_York',
  },
  '23947': {
    zip: '23947',
    city: 'Keysville',
    state: 'VA',
    latitude: '36.989834',
    longitude: '-78.223003',
    timeZoneId: 'America/New_York',
  },
  '23950': {
    zip: '23950',
    city: 'La Crosse',
    state: 'VA',
    latitude: '36.752222',
    longitude: '-78.046389',
    timeZoneId: 'America/New_York',
  },
  '23952': {
    zip: '23952',
    city: 'Lunenburg',
    state: 'VA',
    latitude: '36.918227',
    longitude: '-78.294635',
    timeZoneId: 'America/New_York',
  },
  '23954': {
    zip: '23954',
    city: 'Meherrin',
    state: 'VA',
    latitude: '37.096043',
    longitude: '-78.382492',
    timeZoneId: 'America/New_York',
  },
  '23955': {
    zip: '23955',
    city: 'Nottoway',
    state: 'VA',
    latitude: '37.125076',
    longitude: '-78.075336',
    timeZoneId: 'America/New_York',
  },
  '23958': {
    zip: '23958',
    city: 'Pamplin',
    state: 'VA',
    latitude: '37.260126',
    longitude: '-78.645051',
    timeZoneId: 'America/New_York',
  },
  '23959': {
    zip: '23959',
    city: 'Phenix',
    state: 'VA',
    latitude: '37.100116',
    longitude: '-78.802734',
    timeZoneId: 'America/New_York',
  },
  '23960': {
    zip: '23960',
    city: 'Prospect',
    state: 'VA',
    latitude: '37.318317',
    longitude: '-78.553389',
    timeZoneId: 'America/New_York',
  },
  '23962': {
    zip: '23962',
    city: 'Randolph',
    state: 'VA',
    latitude: '36.970277',
    longitude: '-78.709836',
    timeZoneId: 'America/New_York',
  },
  '23963': {
    zip: '23963',
    city: 'Red House',
    state: 'VA',
    latitude: '37.20025',
    longitude: '-78.809699',
    timeZoneId: 'America/New_York',
  },
  '23964': {
    zip: '23964',
    city: 'Red Oak',
    state: 'VA',
    latitude: '36.763884',
    longitude: '-78.636781',
    timeZoneId: 'America/New_York',
  },
  '23966': {
    zip: '23966',
    city: 'Rice',
    state: 'VA',
    latitude: '37.28766',
    longitude: '-78.280962',
    timeZoneId: 'America/New_York',
  },
  '23967': {
    zip: '23967',
    city: 'Saxe',
    state: 'VA',
    latitude: '36.903402',
    longitude: '-78.619215',
    timeZoneId: 'America/New_York',
  },
  '23968': {
    zip: '23968',
    city: 'Skipwith',
    state: 'VA',
    latitude: '36.730287',
    longitude: '-78.528256',
    timeZoneId: 'America/New_York',
  },
  '23970': {
    zip: '23970',
    city: 'South Hill',
    state: 'VA',
    latitude: '36.727549',
    longitude: '-78.134748',
    timeZoneId: 'America/New_York',
  },
  '23974': {
    zip: '23974',
    city: 'Victoria',
    state: 'VA',
    latitude: '36.992629',
    longitude: '-78.227515',
    timeZoneId: 'America/New_York',
  },
  '23976': {
    zip: '23976',
    city: 'Wylliesburg',
    state: 'VA',
    latitude: '36.837427',
    longitude: '-78.610991',
    timeZoneId: 'America/New_York',
  },
  '24001': {
    zip: '24001',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.27076',
    longitude: '-79.939334',
    timeZoneId: 'America/New_York',
  },
  '24002': {
    zip: '24002',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.277907',
    longitude: '-79.956942',
    timeZoneId: 'America/New_York',
  },
  '24003': {
    zip: '24003',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.273466',
    longitude: '-79.942101',
    timeZoneId: 'America/New_York',
  },
  '24004': {
    zip: '24004',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.270193',
    longitude: '-79.944668',
    timeZoneId: 'America/New_York',
  },
  '24005': {
    zip: '24005',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.271194',
    longitude: '-79.940971',
    timeZoneId: 'America/New_York',
  },
  '24006': {
    zip: '24006',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.273068',
    longitude: '-79.943322',
    timeZoneId: 'America/New_York',
  },
  '24007': {
    zip: '24007',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.270595',
    longitude: '-79.941504',
    timeZoneId: 'America/New_York',
  },
  '24008': {
    zip: '24008',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.271345',
    longitude: '-79.944351',
    timeZoneId: 'America/New_York',
  },
  '24009': {
    zip: '24009',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.270658',
    longitude: '-79.938509',
    timeZoneId: 'America/New_York',
  },
  '24010': {
    zip: '24010',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.271505',
    longitude: '-79.942336',
    timeZoneId: 'America/New_York',
  },
  '24011': {
    zip: '24011',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.271198',
    longitude: '-79.942884',
    timeZoneId: 'America/New_York',
  },
  '24012': {
    zip: '24012',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.3065',
    longitude: '-79.94034',
    timeZoneId: 'America/New_York',
  },
  '24013': {
    zip: '24013',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.265503',
    longitude: '-79.928798',
    timeZoneId: 'America/New_York',
  },
  '24014': {
    zip: '24014',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.234614',
    longitude: '-79.934731',
    timeZoneId: 'America/New_York',
  },
  '24015': {
    zip: '24015',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.254944',
    longitude: '-79.980629',
    timeZoneId: 'America/New_York',
  },
  '24016': {
    zip: '24016',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.271578',
    longitude: '-79.950125',
    timeZoneId: 'America/New_York',
  },
  '24017': {
    zip: '24017',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.295707',
    longitude: '-79.989389',
    timeZoneId: 'America/New_York',
  },
  '24018': {
    zip: '24018',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.226453',
    longitude: '-80.026824',
    timeZoneId: 'America/New_York',
  },
  '24019': {
    zip: '24019',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.33716',
    longitude: '-79.95168',
    timeZoneId: 'America/New_York',
  },
  '24020': {
    zip: '24020',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.245254',
    longitude: '-80.030639',
    timeZoneId: 'America/New_York',
  },
  '24022': {
    zip: '24022',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.272082',
    longitude: '-79.939745',
    timeZoneId: 'America/New_York',
  },
  '24023': {
    zip: '24023',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.269966',
    longitude: '-79.951696',
    timeZoneId: 'America/New_York',
  },
  '24024': {
    zip: '24024',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.275792',
    longitude: '-79.934237',
    timeZoneId: 'America/New_York',
  },
  '24025': {
    zip: '24025',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.272916',
    longitude: '-79.952822',
    timeZoneId: 'America/New_York',
  },
  '24026': {
    zip: '24026',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.27224',
    longitude: '-79.951117',
    timeZoneId: 'America/New_York',
  },
  '24027': {
    zip: '24027',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.273624',
    longitude: '-79.955034',
    timeZoneId: 'America/New_York',
  },
  '24028': {
    zip: '24028',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.281781',
    longitude: '-79.942877',
    timeZoneId: 'America/New_York',
  },
  '24029': {
    zip: '24029',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.271593',
    longitude: '-79.952695',
    timeZoneId: 'America/New_York',
  },
  '24030': {
    zip: '24030',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.270962',
    longitude: '-79.954331',
    timeZoneId: 'America/New_York',
  },
  '24031': {
    zip: '24031',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.272186',
    longitude: '-79.956056',
    timeZoneId: 'America/New_York',
  },
  '24032': {
    zip: '24032',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.278278',
    longitude: '-79.935296',
    timeZoneId: 'America/New_York',
  },
  '24033': {
    zip: '24033',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.273418',
    longitude: '-79.955962',
    timeZoneId: 'America/New_York',
  },
  '24034': {
    zip: '24034',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.28012',
    longitude: '-79.938256',
    timeZoneId: 'America/New_York',
  },
  '24035': {
    zip: '24035',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.282403',
    longitude: '-79.928587',
    timeZoneId: 'America/New_York',
  },
  '24036': {
    zip: '24036',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.278909',
    longitude: '-79.932979',
    timeZoneId: 'America/New_York',
  },
  '24037': {
    zip: '24037',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.280233',
    longitude: '-79.935704',
    timeZoneId: 'America/New_York',
  },
  '24038': {
    zip: '24038',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.27169',
    longitude: '-79.956295',
    timeZoneId: 'America/New_York',
  },
  '24040': {
    zip: '24040',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.277095',
    longitude: '-79.934639',
    timeZoneId: 'America/New_York',
  },
  '24042': {
    zip: '24042',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.275867',
    longitude: '-79.941592',
    timeZoneId: 'America/New_York',
  },
  '24043': {
    zip: '24043',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.270171',
    longitude: '-79.922058',
    timeZoneId: 'America/New_York',
  },
  '24050': {
    zip: '24050',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.346218',
    longitude: '-79.957184',
    timeZoneId: 'America/New_York',
  },
  '24053': {
    zip: '24053',
    city: 'Ararat',
    state: 'VA',
    latitude: '36.59079',
    longitude: '-80.526996',
    timeZoneId: 'America/New_York',
  },
  '24054': {
    zip: '24054',
    city: 'Axton',
    state: 'VA',
    latitude: '36.659078',
    longitude: '-79.714557',
    timeZoneId: 'America/New_York',
  },
  '24055': {
    zip: '24055',
    city: 'Bassett',
    state: 'VA',
    latitude: '36.751969',
    longitude: '-79.987513',
    timeZoneId: 'America/New_York',
  },
  '24058': {
    zip: '24058',
    city: 'Belspring',
    state: 'VA',
    latitude: '37.136091',
    longitude: '-80.66155',
    timeZoneId: 'America/New_York',
  },
  '24059': {
    zip: '24059',
    city: 'Bent Mountain',
    state: 'VA',
    latitude: '37.153198',
    longitude: '-80.128472',
    timeZoneId: 'America/New_York',
  },
  '24060': {
    zip: '24060',
    city: 'Blacksburg',
    state: 'VA',
    latitude: '37.229033',
    longitude: '-80.414886',
    timeZoneId: 'America/New_York',
  },
  '24061': {
    zip: '24061',
    city: 'Blacksburg',
    state: 'VA',
    latitude: '37.255026',
    longitude: '-80.431691',
    timeZoneId: 'America/New_York',
  },
  '24062': {
    zip: '24062',
    city: 'Blacksburg',
    state: 'VA',
    latitude: '37.228444',
    longitude: '-80.415618',
    timeZoneId: 'America/New_York',
  },
  '24063': {
    zip: '24063',
    city: 'Blacksburg',
    state: 'VA',
    latitude: '37.228841',
    longitude: '-80.415762',
    timeZoneId: 'America/New_York',
  },
  '24064': {
    zip: '24064',
    city: 'Blue Ridge',
    state: 'VA',
    latitude: '37.377288',
    longitude: '-79.824583',
    timeZoneId: 'America/New_York',
  },
  '24065': {
    zip: '24065',
    city: 'Boones Mill',
    state: 'VA',
    latitude: '37.171728',
    longitude: '-79.962694',
    timeZoneId: 'America/New_York',
  },
  '24066': {
    zip: '24066',
    city: 'Buchanan',
    state: 'VA',
    latitude: '37.5318',
    longitude: '-79.68372',
    timeZoneId: 'America/New_York',
  },
  '24067': {
    zip: '24067',
    city: 'Callaway',
    state: 'VA',
    latitude: '37.04005',
    longitude: '-80.065629',
    timeZoneId: 'America/New_York',
  },
  '24068': {
    zip: '24068',
    city: 'Christiansburg',
    state: 'VA',
    latitude: '37.142462',
    longitude: '-80.400792',
    timeZoneId: 'America/New_York',
  },
  '24069': {
    zip: '24069',
    city: 'Cascade',
    state: 'VA',
    latitude: '36.58635',
    longitude: '-79.660137',
    timeZoneId: 'America/New_York',
  },
  '24070': {
    zip: '24070',
    city: 'Catawba',
    state: 'VA',
    latitude: '37.356587',
    longitude: '-80.180426',
    timeZoneId: 'America/New_York',
  },
  '24072': {
    zip: '24072',
    city: 'Check',
    state: 'VA',
    latitude: '37.036016',
    longitude: '-80.241336',
    timeZoneId: 'America/New_York',
  },
  '24073': {
    zip: '24073',
    city: 'Christiansburg',
    state: 'VA',
    latitude: '37.136439',
    longitude: '-80.404',
    timeZoneId: 'America/New_York',
  },
  '24076': {
    zip: '24076',
    city: 'Claudville',
    state: 'VA',
    latitude: '36.598118',
    longitude: '-80.430923',
    timeZoneId: 'America/New_York',
  },
  '24077': {
    zip: '24077',
    city: 'Cloverdale',
    state: 'VA',
    latitude: '37.366347',
    longitude: '-79.903795',
    timeZoneId: 'America/New_York',
  },
  '24078': {
    zip: '24078',
    city: 'Collinsville',
    state: 'VA',
    latitude: '36.722836',
    longitude: '-79.914738',
    timeZoneId: 'America/New_York',
  },
  '24079': {
    zip: '24079',
    city: 'Copper Hill',
    state: 'VA',
    latitude: '37.078372',
    longitude: '-80.150507',
    timeZoneId: 'America/New_York',
  },
  '24082': {
    zip: '24082',
    city: 'Critz',
    state: 'VA',
    latitude: '36.614641',
    longitude: '-80.121704',
    timeZoneId: 'America/New_York',
  },
  '24083': {
    zip: '24083',
    city: 'Daleville',
    state: 'VA',
    latitude: '37.415209',
    longitude: '-79.913412',
    timeZoneId: 'America/New_York',
  },
  '24084': {
    zip: '24084',
    city: 'Dublin',
    state: 'VA',
    latitude: '37.106884',
    longitude: '-80.684639',
    timeZoneId: 'America/New_York',
  },
  '24085': {
    zip: '24085',
    city: 'Eagle Rock',
    state: 'VA',
    latitude: '37.663244',
    longitude: '-79.835843',
    timeZoneId: 'America/New_York',
  },
  '24086': {
    zip: '24086',
    city: 'Eggleston',
    state: 'VA',
    latitude: '37.284944',
    longitude: '-80.619819',
    timeZoneId: 'America/New_York',
  },
  '24087': {
    zip: '24087',
    city: 'Elliston',
    state: 'VA',
    latitude: '37.1973',
    longitude: '-80.2212',
    timeZoneId: 'America/New_York',
  },
  '24088': {
    zip: '24088',
    city: 'Ferrum',
    state: 'VA',
    latitude: '36.928893',
    longitude: '-80.016884',
    timeZoneId: 'America/New_York',
  },
  '24089': {
    zip: '24089',
    city: 'Fieldale',
    state: 'VA',
    latitude: '36.70394',
    longitude: '-79.940681',
    timeZoneId: 'America/New_York',
  },
  '24090': {
    zip: '24090',
    city: 'Fincastle',
    state: 'VA',
    latitude: '37.494715',
    longitude: '-79.878228',
    timeZoneId: 'America/New_York',
  },
  '24091': {
    zip: '24091',
    city: 'Floyd',
    state: 'VA',
    latitude: '36.914072',
    longitude: '-80.316498',
    timeZoneId: 'America/New_York',
  },
  '24092': {
    zip: '24092',
    city: 'Glade Hill',
    state: 'VA',
    latitude: '37.003275',
    longitude: '-79.769882',
    timeZoneId: 'America/New_York',
  },
  '24093': {
    zip: '24093',
    city: 'Glen Lyn',
    state: 'VA',
    latitude: '37.373459',
    longitude: '-80.86777',
    timeZoneId: 'America/New_York',
  },
  '24095': {
    zip: '24095',
    city: 'Goodview',
    state: 'VA',
    latitude: '37.222194',
    longitude: '-79.758294',
    timeZoneId: 'America/New_York',
  },
  '24101': {
    zip: '24101',
    city: 'Hardy',
    state: 'VA',
    latitude: '37.170582',
    longitude: '-79.740775',
    timeZoneId: 'America/New_York',
  },
  '24102': {
    zip: '24102',
    city: 'Henry',
    state: 'VA',
    latitude: '36.839964',
    longitude: '-80.006088',
    timeZoneId: 'America/New_York',
  },
  '24104': {
    zip: '24104',
    city: 'Huddleston',
    state: 'VA',
    latitude: '37.064524',
    longitude: '-79.545301',
    timeZoneId: 'America/New_York',
  },
  '24105': {
    zip: '24105',
    city: 'Indian Valley',
    state: 'VA',
    latitude: '36.90324',
    longitude: '-80.578322',
    timeZoneId: 'America/New_York',
  },
  '24111': {
    zip: '24111',
    city: 'Mc Coy',
    state: 'VA',
    latitude: '37.25889',
    longitude: '-80.429778',
    timeZoneId: 'America/New_York',
  },
  '24112': {
    zip: '24112',
    city: 'Martinsville',
    state: 'VA',
    latitude: '36.685246',
    longitude: '-79.865321',
    timeZoneId: 'America/New_York',
  },
  '24113': {
    zip: '24113',
    city: 'Martinsville',
    state: 'VA',
    latitude: '36.682672',
    longitude: '-79.850377',
    timeZoneId: 'America/New_York',
  },
  '24114': {
    zip: '24114',
    city: 'Martinsville',
    state: 'VA',
    latitude: '36.683887',
    longitude: '-79.864118',
    timeZoneId: 'America/New_York',
  },
  '24115': {
    zip: '24115',
    city: 'Martinsville',
    state: 'VA',
    latitude: '36.687968',
    longitude: '-79.858657',
    timeZoneId: 'America/New_York',
  },
  '24120': {
    zip: '24120',
    city: 'Meadows of Dan',
    state: 'VA',
    latitude: '36.72421',
    longitude: '-80.401341',
    timeZoneId: 'America/New_York',
  },
  '24121': {
    zip: '24121',
    city: 'Moneta',
    state: 'VA',
    latitude: '37.100237',
    longitude: '-79.662578',
    timeZoneId: 'America/New_York',
  },
  '24122': {
    zip: '24122',
    city: 'Montvale',
    state: 'VA',
    latitude: '37.429529',
    longitude: '-79.707858',
    timeZoneId: 'America/New_York',
  },
  '24124': {
    zip: '24124',
    city: 'Narrows',
    state: 'VA',
    latitude: '37.33273',
    longitude: '-80.807301',
    timeZoneId: 'America/New_York',
  },
  '24126': {
    zip: '24126',
    city: 'Newbern',
    state: 'VA',
    latitude: '37.091186',
    longitude: '-80.655659',
    timeZoneId: 'America/New_York',
  },
  '24127': {
    zip: '24127',
    city: 'New Castle',
    state: 'VA',
    latitude: '37.504495',
    longitude: '-80.111684',
    timeZoneId: 'America/New_York',
  },
  '24128': {
    zip: '24128',
    city: 'Newport',
    state: 'VA',
    latitude: '37.316078',
    longitude: '-80.480491',
    timeZoneId: 'America/New_York',
  },
  '24129': {
    zip: '24129',
    city: 'New River',
    state: 'VA',
    latitude: '37.152671',
    longitude: '-80.619218',
    timeZoneId: 'America/New_York',
  },
  '24130': {
    zip: '24130',
    city: 'Oriskany',
    state: 'VA',
    latitude: '37.661877',
    longitude: '-79.834957',
    timeZoneId: 'America/New_York',
  },
  '24131': {
    zip: '24131',
    city: 'Paint Bank',
    state: 'VA',
    latitude: '37.567936',
    longitude: '-80.251529',
    timeZoneId: 'America/New_York',
  },
  '24132': {
    zip: '24132',
    city: 'Parrott',
    state: 'VA',
    latitude: '37.147992',
    longitude: '-80.615851',
    timeZoneId: 'America/New_York',
  },
  '24133': {
    zip: '24133',
    city: 'Patrick Springs',
    state: 'VA',
    latitude: '36.689216',
    longitude: '-80.149261',
    timeZoneId: 'America/New_York',
  },
  '24134': {
    zip: '24134',
    city: 'Pearisburg',
    state: 'VA',
    latitude: '37.323127',
    longitude: '-80.730504',
    timeZoneId: 'America/New_York',
  },
  '24136': {
    zip: '24136',
    city: 'Pembroke',
    state: 'VA',
    latitude: '37.381014',
    longitude: '-80.565458',
    timeZoneId: 'America/New_York',
  },
  '24137': {
    zip: '24137',
    city: 'Penhook',
    state: 'VA',
    latitude: '37.033302',
    longitude: '-79.59213',
    timeZoneId: 'America/New_York',
  },
  '24138': {
    zip: '24138',
    city: 'Pilot',
    state: 'VA',
    latitude: '37.058466',
    longitude: '-80.318946',
    timeZoneId: 'America/New_York',
  },
  '24139': {
    zip: '24139',
    city: 'Pittsville',
    state: 'VA',
    latitude: '37.010041',
    longitude: '-79.482448',
    timeZoneId: 'America/New_York',
  },
  '24141': {
    zip: '24141',
    city: 'Radford',
    state: 'VA',
    latitude: '37.1367',
    longitude: '-80.6082',
    timeZoneId: 'America/New_York',
  },
  '24142': {
    zip: '24142',
    city: 'Radford',
    state: 'VA',
    latitude: '37.150649',
    longitude: '-80.616629',
    timeZoneId: 'America/New_York',
  },
  '24143': {
    zip: '24143',
    city: 'Radford',
    state: 'VA',
    latitude: '37.125895',
    longitude: '-80.57524',
    timeZoneId: 'America/New_York',
  },
  '24146': {
    zip: '24146',
    city: 'Redwood',
    state: 'VA',
    latitude: '37.007922',
    longitude: '-79.770253',
    timeZoneId: 'America/New_York',
  },
  '24147': {
    zip: '24147',
    city: 'Rich Creek',
    state: 'VA',
    latitude: '37.380211',
    longitude: '-80.815976',
    timeZoneId: 'America/New_York',
  },
  '24148': {
    zip: '24148',
    city: 'Ridgeway',
    state: 'VA',
    latitude: '36.614394',
    longitude: '-79.864963',
    timeZoneId: 'America/New_York',
  },
  '24149': {
    zip: '24149',
    city: 'Riner',
    state: 'VA',
    latitude: '37.0263',
    longitude: '-80.43528',
    timeZoneId: 'America/New_York',
  },
  '24150': {
    zip: '24150',
    city: 'Ripplemead',
    state: 'VA',
    latitude: '37.37838',
    longitude: '-80.6562',
    timeZoneId: 'America/New_York',
  },
  '24151': {
    zip: '24151',
    city: 'Rocky Mount',
    state: 'VA',
    latitude: '37.000112',
    longitude: '-79.895756',
    timeZoneId: 'America/New_York',
  },
  '24153': {
    zip: '24153',
    city: 'Salem',
    state: 'VA',
    latitude: '37.288165',
    longitude: '-80.081369',
    timeZoneId: 'America/New_York',
  },
  '24155': {
    zip: '24155',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.29257',
    longitude: '-80.063621',
    timeZoneId: 'America/New_York',
  },
  '24157': {
    zip: '24157',
    city: 'Roanoke',
    state: 'VA',
    latitude: '37.292411',
    longitude: '-80.063958',
    timeZoneId: 'America/New_York',
  },
  '24161': {
    zip: '24161',
    city: 'Sandy Level',
    state: 'VA',
    latitude: '36.989339',
    longitude: '-79.546813',
    timeZoneId: 'America/New_York',
  },
  '24162': {
    zip: '24162',
    city: 'Shawsville',
    state: 'VA',
    latitude: '37.140086',
    longitude: '-80.32456',
    timeZoneId: 'America/New_York',
  },
  '24165': {
    zip: '24165',
    city: 'Spencer',
    state: 'VA',
    latitude: '36.579029',
    longitude: '-80.045917',
    timeZoneId: 'America/New_York',
  },
  '24167': {
    zip: '24167',
    city: 'Staffordsville',
    state: 'VA',
    latitude: '37.245104',
    longitude: '-80.766253',
    timeZoneId: 'America/New_York',
  },
  '24168': {
    zip: '24168',
    city: 'Stanleytown',
    state: 'VA',
    latitude: '36.73594',
    longitude: '-79.941103',
    timeZoneId: 'America/New_York',
  },
  '24171': {
    zip: '24171',
    city: 'Stuart',
    state: 'VA',
    latitude: '36.631672',
    longitude: '-80.247549',
    timeZoneId: 'America/New_York',
  },
  '24174': {
    zip: '24174',
    city: 'Thaxton',
    state: 'VA',
    latitude: '37.331597',
    longitude: '-79.688558',
    timeZoneId: 'America/New_York',
  },
  '24175': {
    zip: '24175',
    city: 'Troutville',
    state: 'VA',
    latitude: '37.397461',
    longitude: '-79.876477',
    timeZoneId: 'America/New_York',
  },
  '24176': {
    zip: '24176',
    city: 'Union Hall',
    state: 'VA',
    latitude: '37.036342',
    longitude: '-79.630508',
    timeZoneId: 'America/New_York',
  },
  '24177': {
    zip: '24177',
    city: 'Vesta',
    state: 'VA',
    latitude: '36.722656',
    longitude: '-80.400262',
    timeZoneId: 'America/New_York',
  },
  '24178': {
    zip: '24178',
    city: 'Villamont',
    state: 'VA',
    latitude: '37.425266',
    longitude: '-79.708545',
    timeZoneId: 'America/New_York',
  },
  '24179': {
    zip: '24179',
    city: 'Vinton',
    state: 'VA',
    latitude: '37.278679',
    longitude: '-79.88095',
    timeZoneId: 'America/New_York',
  },
  '24184': {
    zip: '24184',
    city: 'Wirtz',
    state: 'VA',
    latitude: '37.06296',
    longitude: '-79.71816',
    timeZoneId: 'America/New_York',
  },
  '24185': {
    zip: '24185',
    city: 'Woolwine',
    state: 'VA',
    latitude: '36.807816',
    longitude: '-80.278377',
    timeZoneId: 'America/New_York',
  },
  '24201': {
    zip: '24201',
    city: 'Bristol',
    state: 'VA',
    latitude: '36.61361',
    longitude: '-82.17095',
    timeZoneId: 'America/New_York',
  },
  '24202': {
    zip: '24202',
    city: 'Bristol',
    state: 'VA',
    latitude: '36.649682',
    longitude: '-82.201966',
    timeZoneId: 'America/New_York',
  },
  '24203': {
    zip: '24203',
    city: 'Bristol',
    state: 'VA',
    latitude: '36.597166',
    longitude: '-82.188623',
    timeZoneId: 'America/New_York',
  },
  '24205': {
    zip: '24205',
    city: 'Bristol',
    state: 'VA',
    latitude: '36.613682',
    longitude: '-82.170691',
    timeZoneId: 'America/New_York',
  },
  '24209': {
    zip: '24209',
    city: 'Bristol',
    state: 'VA',
    latitude: '36.612406',
    longitude: '-82.168621',
    timeZoneId: 'America/New_York',
  },
  '24210': {
    zip: '24210',
    city: 'Abingdon',
    state: 'VA',
    latitude: '36.707838',
    longitude: '-81.978013',
    timeZoneId: 'America/New_York',
  },
  '24211': {
    zip: '24211',
    city: 'Abingdon',
    state: 'VA',
    latitude: '36.710423',
    longitude: '-81.977417',
    timeZoneId: 'America/New_York',
  },
  '24212': {
    zip: '24212',
    city: 'Abingdon',
    state: 'VA',
    latitude: '36.706928',
    longitude: '-81.974386',
    timeZoneId: 'America/New_York',
  },
  '24215': {
    zip: '24215',
    city: 'Andover',
    state: 'VA',
    latitude: '36.993827',
    longitude: '-82.535934',
    timeZoneId: 'America/New_York',
  },
  '24216': {
    zip: '24216',
    city: 'Appalachia',
    state: 'VA',
    latitude: '36.906361',
    longitude: '-82.78262',
    timeZoneId: 'America/New_York',
  },
  '24217': {
    zip: '24217',
    city: 'Bee',
    state: 'VA',
    latitude: '37.083006',
    longitude: '-82.193556',
    timeZoneId: 'America/New_York',
  },
  '24218': {
    zip: '24218',
    city: 'Ben Hur',
    state: 'VA',
    latitude: '36.753377',
    longitude: '-83.036139',
    timeZoneId: 'America/New_York',
  },
  '24219': {
    zip: '24219',
    city: 'Big Stone Gap',
    state: 'VA',
    latitude: '36.869831',
    longitude: '-82.774127',
    timeZoneId: 'America/New_York',
  },
  '24220': {
    zip: '24220',
    city: 'Birchleaf',
    state: 'VA',
    latitude: '37.152395',
    longitude: '-82.237486',
    timeZoneId: 'America/New_York',
  },
  '24221': {
    zip: '24221',
    city: 'Blackwater',
    state: 'VA',
    latitude: '36.634666',
    longitude: '-83.009063',
    timeZoneId: 'America/New_York',
  },
  '24224': {
    zip: '24224',
    city: 'Castlewood',
    state: 'VA',
    latitude: '36.88008',
    longitude: '-82.2798',
    timeZoneId: 'America/New_York',
  },
  '24225': {
    zip: '24225',
    city: 'Cleveland',
    state: 'VA',
    latitude: '36.982036',
    longitude: '-82.136945',
    timeZoneId: 'America/New_York',
  },
  '24226': {
    zip: '24226',
    city: 'Clinchco',
    state: 'VA',
    latitude: '37.127536',
    longitude: '-82.336672',
    timeZoneId: 'America/New_York',
  },
  '24228': {
    zip: '24228',
    city: 'Clintwood',
    state: 'VA',
    latitude: '37.152022',
    longitude: '-82.458909',
    timeZoneId: 'America/New_York',
  },
  '24230': {
    zip: '24230',
    city: 'Coeburn',
    state: 'VA',
    latitude: '36.942598',
    longitude: '-82.468058',
    timeZoneId: 'America/New_York',
  },
  '24236': {
    zip: '24236',
    city: 'Damascus',
    state: 'VA',
    latitude: '36.636452',
    longitude: '-81.802169',
    timeZoneId: 'America/New_York',
  },
  '24237': {
    zip: '24237',
    city: 'Dante',
    state: 'VA',
    latitude: '37.040485',
    longitude: '-82.275198',
    timeZoneId: 'America/New_York',
  },
  '24239': {
    zip: '24239',
    city: 'Davenport',
    state: 'VA',
    latitude: '37.056587',
    longitude: '-82.123206',
    timeZoneId: 'America/New_York',
  },
  '24243': {
    zip: '24243',
    city: 'Dryden',
    state: 'VA',
    latitude: '36.795985',
    longitude: '-82.915693',
    timeZoneId: 'America/New_York',
  },
  '24244': {
    zip: '24244',
    city: 'Duffield',
    state: 'VA',
    latitude: '36.70746',
    longitude: '-82.8096',
    timeZoneId: 'America/New_York',
  },
  '24245': {
    zip: '24245',
    city: 'Dungannon',
    state: 'VA',
    latitude: '36.828482',
    longitude: '-82.534767',
    timeZoneId: 'America/New_York',
  },
  '24246': {
    zip: '24246',
    city: 'East Stone Gap',
    state: 'VA',
    latitude: '36.847723',
    longitude: '-82.759128',
    timeZoneId: 'America/New_York',
  },
  '24248': {
    zip: '24248',
    city: 'Ewing',
    state: 'VA',
    latitude: '36.625839',
    longitude: '-83.515719',
    timeZoneId: 'America/New_York',
  },
  '24250': {
    zip: '24250',
    city: 'Fort Blackmore',
    state: 'VA',
    latitude: '36.754746',
    longitude: '-82.625126',
    timeZoneId: 'America/New_York',
  },
  '24251': {
    zip: '24251',
    city: 'Gate City',
    state: 'VA',
    latitude: '36.633269',
    longitude: '-82.573483',
    timeZoneId: 'America/New_York',
  },
  '24256': {
    zip: '24256',
    city: 'Haysi',
    state: 'VA',
    latitude: '37.222888',
    longitude: '-82.286413',
    timeZoneId: 'America/New_York',
  },
  '24258': {
    zip: '24258',
    city: 'Hiltons',
    state: 'VA',
    latitude: '36.647452',
    longitude: '-82.409346',
    timeZoneId: 'America/New_York',
  },
  '24260': {
    zip: '24260',
    city: 'Honaker',
    state: 'VA',
    latitude: '37.029515',
    longitude: '-82.003511',
    timeZoneId: 'America/New_York',
  },
  '24263': {
    zip: '24263',
    city: 'Jonesville',
    state: 'VA',
    latitude: '36.687405',
    longitude: '-83.1102',
    timeZoneId: 'America/New_York',
  },
  '24265': {
    zip: '24265',
    city: 'Keokee',
    state: 'VA',
    latitude: '36.834134',
    longitude: '-82.964016',
    timeZoneId: 'America/New_York',
  },
  '24266': {
    zip: '24266',
    city: 'Lebanon',
    state: 'VA',
    latitude: '36.89046',
    longitude: '-82.08456',
    timeZoneId: 'America/New_York',
  },
  '24269': {
    zip: '24269',
    city: 'Mc Clure',
    state: 'VA',
    latitude: '37.080985',
    longitude: '-82.383101',
    timeZoneId: 'America/New_York',
  },
  '24270': {
    zip: '24270',
    city: 'Mendota',
    state: 'VA',
    latitude: '36.729564',
    longitude: '-82.251407',
    timeZoneId: 'America/New_York',
  },
  '24271': {
    zip: '24271',
    city: 'Nickelsville',
    state: 'VA',
    latitude: '36.752077',
    longitude: '-82.419781',
    timeZoneId: 'America/New_York',
  },
  '24272': {
    zip: '24272',
    city: 'Nora',
    state: 'VA',
    latitude: '37.027652',
    longitude: '-82.347712',
    timeZoneId: 'America/New_York',
  },
  '24273': {
    zip: '24273',
    city: 'Norton',
    state: 'VA',
    latitude: '36.936315',
    longitude: '-82.622842',
    timeZoneId: 'America/New_York',
  },
  '24277': {
    zip: '24277',
    city: 'Pennington Gap',
    state: 'VA',
    latitude: '36.755012',
    longitude: '-83.033078',
    timeZoneId: 'America/New_York',
  },
  '24279': {
    zip: '24279',
    city: 'Pound',
    state: 'VA',
    latitude: '36.939099',
    longitude: '-82.5955',
    timeZoneId: 'America/New_York',
  },
  '24280': {
    zip: '24280',
    city: 'Rosedale',
    state: 'VA',
    latitude: '36.965018',
    longitude: '-81.897825',
    timeZoneId: 'America/New_York',
  },
  '24281': {
    zip: '24281',
    city: 'Rose Hill',
    state: 'VA',
    latitude: '36.640839',
    longitude: '-83.33892',
    timeZoneId: 'America/New_York',
  },
  '24282': {
    zip: '24282',
    city: 'Saint Charles',
    state: 'VA',
    latitude: '36.835399',
    longitude: '-83.053923',
    timeZoneId: 'America/New_York',
  },
  '24283': {
    zip: '24283',
    city: 'Saint Paul',
    state: 'VA',
    latitude: '36.936209',
    longitude: '-82.355456',
    timeZoneId: 'America/New_York',
  },
  '24290': {
    zip: '24290',
    city: 'Weber City',
    state: 'VA',
    latitude: '36.619532',
    longitude: '-82.559995',
    timeZoneId: 'America/New_York',
  },
  '24292': {
    zip: '24292',
    city: 'Whitetop',
    state: 'VA',
    latitude: '36.616137',
    longitude: '-81.574642',
    timeZoneId: 'America/New_York',
  },
  '24293': {
    zip: '24293',
    city: 'Wise',
    state: 'VA',
    latitude: '36.975068',
    longitude: '-82.570844',
    timeZoneId: 'America/New_York',
  },
  '24301': {
    zip: '24301',
    city: 'Pulaski',
    state: 'VA',
    latitude: '37.050533',
    longitude: '-80.771948',
    timeZoneId: 'America/New_York',
  },
  '24311': {
    zip: '24311',
    city: 'Atkins',
    state: 'VA',
    latitude: '36.855788',
    longitude: '-81.38813',
    timeZoneId: 'America/New_York',
  },
  '24312': {
    zip: '24312',
    city: 'Austinville',
    state: 'VA',
    latitude: '36.819859',
    longitude: '-80.870189',
    timeZoneId: 'America/New_York',
  },
  '24313': {
    zip: '24313',
    city: 'Barren Springs',
    state: 'VA',
    latitude: '36.913901',
    longitude: '-80.807836',
    timeZoneId: 'America/New_York',
  },
  '24314': {
    zip: '24314',
    city: 'Bastian',
    state: 'VA',
    latitude: '37.16178',
    longitude: '-81.19848',
    timeZoneId: 'America/New_York',
  },
  '24315': {
    zip: '24315',
    city: 'Bland',
    state: 'VA',
    latitude: '37.142078',
    longitude: '-81.013829',
    timeZoneId: 'America/New_York',
  },
  '24316': {
    zip: '24316',
    city: 'Broadford',
    state: 'VA',
    latitude: '36.940706',
    longitude: '-81.649256',
    timeZoneId: 'America/New_York',
  },
  '24317': {
    zip: '24317',
    city: 'Cana',
    state: 'VA',
    latitude: '36.597106',
    longitude: '-80.672052',
    timeZoneId: 'America/New_York',
  },
  '24318': {
    zip: '24318',
    city: 'Ceres',
    state: 'VA',
    latitude: '37.010137',
    longitude: '-81.362054',
    timeZoneId: 'America/New_York',
  },
  '24319': {
    zip: '24319',
    city: 'Chilhowie',
    state: 'VA',
    latitude: '36.802209',
    longitude: '-81.682538',
    timeZoneId: 'America/New_York',
  },
  '24322': {
    zip: '24322',
    city: 'Cripple Creek',
    state: 'VA',
    latitude: '36.797818',
    longitude: '-81.105797',
    timeZoneId: 'America/New_York',
  },
  '24323': {
    zip: '24323',
    city: 'Crockett',
    state: 'VA',
    latitude: '36.87594',
    longitude: '-81.209706',
    timeZoneId: 'America/New_York',
  },
  '24324': {
    zip: '24324',
    city: 'Draper',
    state: 'VA',
    latitude: '36.967091',
    longitude: '-80.820914',
    timeZoneId: 'America/New_York',
  },
  '24325': {
    zip: '24325',
    city: 'Dugspur',
    state: 'VA',
    latitude: '36.821816',
    longitude: '-80.614233',
    timeZoneId: 'America/New_York',
  },
  '24326': {
    zip: '24326',
    city: 'Elk Creek',
    state: 'VA',
    latitude: '36.729391',
    longitude: '-81.194399',
    timeZoneId: 'America/New_York',
  },
  '24327': {
    zip: '24327',
    city: 'Emory',
    state: 'VA',
    latitude: '36.767761',
    longitude: '-81.854299',
    timeZoneId: 'America/New_York',
  },
  '24328': {
    zip: '24328',
    city: 'Fancy Gap',
    state: 'VA',
    latitude: '36.661564',
    longitude: '-80.69226',
    timeZoneId: 'America/New_York',
  },
  '24330': {
    zip: '24330',
    city: 'Fries',
    state: 'VA',
    latitude: '36.73123',
    longitude: '-81.029471',
    timeZoneId: 'America/New_York',
  },
  '24333': {
    zip: '24333',
    city: 'Galax',
    state: 'VA',
    latitude: '36.66059',
    longitude: '-80.923683',
    timeZoneId: 'America/New_York',
  },
  '24340': {
    zip: '24340',
    city: 'Glade Spring',
    state: 'VA',
    latitude: '36.787559',
    longitude: '-81.771869',
    timeZoneId: 'America/New_York',
  },
  '24343': {
    zip: '24343',
    city: 'Hillsville',
    state: 'VA',
    latitude: '36.764206',
    longitude: '-80.736826',
    timeZoneId: 'America/New_York',
  },
  '24347': {
    zip: '24347',
    city: 'Hiwassee',
    state: 'VA',
    latitude: '37.0161',
    longitude: '-80.60634',
    timeZoneId: 'America/New_York',
  },
  '24348': {
    zip: '24348',
    city: 'Independence',
    state: 'VA',
    latitude: '36.619535',
    longitude: '-81.144242',
    timeZoneId: 'America/New_York',
  },
  '24350': {
    zip: '24350',
    city: 'Ivanhoe',
    state: 'VA',
    latitude: '36.818045',
    longitude: '-81.007296',
    timeZoneId: 'America/New_York',
  },
  '24351': {
    zip: '24351',
    city: 'Lambsburg',
    state: 'VA',
    latitude: '36.579012',
    longitude: '-80.780164',
    timeZoneId: 'America/New_York',
  },
  '24352': {
    zip: '24352',
    city: 'Laurel Fork',
    state: 'VA',
    latitude: '36.709127',
    longitude: '-80.518355',
    timeZoneId: 'America/New_York',
  },
  '24354': {
    zip: '24354',
    city: 'Marion',
    state: 'VA',
    latitude: '36.834499',
    longitude: '-81.517149',
    timeZoneId: 'America/New_York',
  },
  '24360': {
    zip: '24360',
    city: 'Max Meadows',
    state: 'VA',
    latitude: '36.92448',
    longitude: '-80.9118',
    timeZoneId: 'America/New_York',
  },
  '24361': {
    zip: '24361',
    city: 'Meadowview',
    state: 'VA',
    latitude: '36.766293',
    longitude: '-81.85565',
    timeZoneId: 'America/New_York',
  },
  '24363': {
    zip: '24363',
    city: 'Mouth of Wilson',
    state: 'VA',
    latitude: '36.612187',
    longitude: '-81.384826',
    timeZoneId: 'America/New_York',
  },
  '24366': {
    zip: '24366',
    city: 'Rocky Gap',
    state: 'VA',
    latitude: '37.229852',
    longitude: '-81.19557',
    timeZoneId: 'America/New_York',
  },
  '24368': {
    zip: '24368',
    city: 'Rural Retreat',
    state: 'VA',
    latitude: '36.899249',
    longitude: '-81.271748',
    timeZoneId: 'America/New_York',
  },
  '24370': {
    zip: '24370',
    city: 'Saltville',
    state: 'VA',
    latitude: '36.876897',
    longitude: '-81.761345',
    timeZoneId: 'America/New_York',
  },
  '24374': {
    zip: '24374',
    city: 'Speedwell',
    state: 'VA',
    latitude: '36.786136',
    longitude: '-81.193948',
    timeZoneId: 'America/New_York',
  },
  '24375': {
    zip: '24375',
    city: 'Sugar Grove',
    state: 'VA',
    latitude: '36.771845',
    longitude: '-81.394418',
    timeZoneId: 'America/New_York',
  },
  '24377': {
    zip: '24377',
    city: 'Tannersville',
    state: 'VA',
    latitude: '36.974613',
    longitude: '-81.640976',
    timeZoneId: 'America/New_York',
  },
  '24378': {
    zip: '24378',
    city: 'Troutdale',
    state: 'VA',
    latitude: '36.692024',
    longitude: '-81.438104',
    timeZoneId: 'America/New_York',
  },
  '24380': {
    zip: '24380',
    city: 'Willis',
    state: 'VA',
    latitude: '36.866498',
    longitude: '-80.49302',
    timeZoneId: 'America/New_York',
  },
  '24381': {
    zip: '24381',
    city: 'Woodlawn',
    state: 'VA',
    latitude: '36.71385',
    longitude: '-80.806839',
    timeZoneId: 'America/New_York',
  },
  '24382': {
    zip: '24382',
    city: 'Wytheville',
    state: 'VA',
    latitude: '36.94526',
    longitude: '-81.089071',
    timeZoneId: 'America/New_York',
  },
  '24401': {
    zip: '24401',
    city: 'Staunton',
    state: 'VA',
    latitude: '38.153156',
    longitude: '-79.078684',
    timeZoneId: 'America/New_York',
  },
  '24402': {
    zip: '24402',
    city: 'Staunton',
    state: 'VA',
    latitude: '38.163217',
    longitude: '-79.066886',
    timeZoneId: 'America/New_York',
  },
  '24411': {
    zip: '24411',
    city: 'Augusta Springs',
    state: 'VA',
    latitude: '38.104025',
    longitude: '-79.318835',
    timeZoneId: 'America/New_York',
  },
  '24412': {
    zip: '24412',
    city: 'Bacova',
    state: 'VA',
    latitude: '38.054374',
    longitude: '-79.846369',
    timeZoneId: 'America/New_York',
  },
  '24413': {
    zip: '24413',
    city: 'Blue Grass',
    state: 'VA',
    latitude: '38.530184',
    longitude: '-79.60007',
    timeZoneId: 'America/New_York',
  },
  '24415': {
    zip: '24415',
    city: 'Brownsburg',
    state: 'VA',
    latitude: '37.93123',
    longitude: '-79.316935',
    timeZoneId: 'America/New_York',
  },
  '24416': {
    zip: '24416',
    city: 'Buena Vista',
    state: 'VA',
    latitude: '37.729973',
    longitude: '-79.350879',
    timeZoneId: 'America/New_York',
  },
  '24421': {
    zip: '24421',
    city: 'Churchville',
    state: 'VA',
    latitude: '38.219303',
    longitude: '-79.203526',
    timeZoneId: 'America/New_York',
  },
  '24422': {
    zip: '24422',
    city: 'Clifton Forge',
    state: 'VA',
    latitude: '37.817394',
    longitude: '-79.824624',
    timeZoneId: 'America/New_York',
  },
  '24426': {
    zip: '24426',
    city: 'Covington',
    state: 'VA',
    latitude: '37.75164',
    longitude: '-80.04576',
    timeZoneId: 'America/New_York',
  },
  '24430': {
    zip: '24430',
    city: 'Craigsville',
    state: 'VA',
    latitude: '38.107652',
    longitude: '-79.334798',
    timeZoneId: 'America/New_York',
  },
  '24431': {
    zip: '24431',
    city: 'Crimora',
    state: 'VA',
    latitude: '38.165674',
    longitude: '-78.833733',
    timeZoneId: 'America/New_York',
  },
  '24432': {
    zip: '24432',
    city: 'Deerfield',
    state: 'VA',
    latitude: '38.168708',
    longitude: '-79.40541',
    timeZoneId: 'America/New_York',
  },
  '24433': {
    zip: '24433',
    city: 'Doe Hill',
    state: 'VA',
    latitude: '38.395846',
    longitude: '-79.485644',
    timeZoneId: 'America/New_York',
  },
  '24435': {
    zip: '24435',
    city: 'Fairfield',
    state: 'VA',
    latitude: '37.878805',
    longitude: '-79.305142',
    timeZoneId: 'America/New_York',
  },
  '24437': {
    zip: '24437',
    city: 'Fort Defiance',
    state: 'VA',
    latitude: '38.217711',
    longitude: '-78.944668',
    timeZoneId: 'America/New_York',
  },
  '24438': {
    zip: '24438',
    city: 'Glen Wilton',
    state: 'VA',
    latitude: '37.753384',
    longitude: '-79.816617',
    timeZoneId: 'America/New_York',
  },
  '24439': {
    zip: '24439',
    city: 'Goshen',
    state: 'VA',
    latitude: '37.969846',
    longitude: '-79.483393',
    timeZoneId: 'America/New_York',
  },
  '24440': {
    zip: '24440',
    city: 'Greenville',
    state: 'VA',
    latitude: '37.978489',
    longitude: '-79.128091',
    timeZoneId: 'America/New_York',
  },
  '24441': {
    zip: '24441',
    city: 'Grottoes',
    state: 'VA',
    latitude: '38.268854',
    longitude: '-78.826751',
    timeZoneId: 'America/New_York',
  },
  '24442': {
    zip: '24442',
    city: 'Head Waters',
    state: 'VA',
    latitude: '38.300394',
    longitude: '-79.425747',
    timeZoneId: 'America/New_York',
  },
  '24445': {
    zip: '24445',
    city: 'Hot Springs',
    state: 'VA',
    latitude: '37.9719',
    longitude: '-79.87158',
    timeZoneId: 'America/New_York',
  },
  '24448': {
    zip: '24448',
    city: 'Iron Gate',
    state: 'VA',
    latitude: '37.795875',
    longitude: '-79.794086',
    timeZoneId: 'America/New_York',
  },
  '24450': {
    zip: '24450',
    city: 'Lexington',
    state: 'VA',
    latitude: '37.779731',
    longitude: '-79.444573',
    timeZoneId: 'America/New_York',
  },
  '24457': {
    zip: '24457',
    city: 'Low Moor',
    state: 'VA',
    latitude: '37.789907',
    longitude: '-79.885378',
    timeZoneId: 'America/New_York',
  },
  '24458': {
    zip: '24458',
    city: 'Mc Dowell',
    state: 'VA',
    latitude: '38.301798',
    longitude: '-79.52427',
    timeZoneId: 'America/New_York',
  },
  '24459': {
    zip: '24459',
    city: 'Middlebrook',
    state: 'VA',
    latitude: '38.025912',
    longitude: '-79.283055',
    timeZoneId: 'America/New_York',
  },
  '24460': {
    zip: '24460',
    city: 'Millboro',
    state: 'VA',
    latitude: '38.02062',
    longitude: '-79.6509',
    timeZoneId: 'America/New_York',
  },
  '24463': {
    zip: '24463',
    city: 'Mint Spring',
    state: 'VA',
    latitude: '38.063584',
    longitude: '-79.113794',
    timeZoneId: 'America/New_York',
  },
  '24464': {
    zip: '24464',
    city: 'Montebello',
    state: 'VA',
    latitude: '37.84428',
    longitude: '-79.070071',
    timeZoneId: 'America/New_York',
  },
  '24465': {
    zip: '24465',
    city: 'Monterey',
    state: 'VA',
    latitude: '38.379939',
    longitude: '-79.64016',
    timeZoneId: 'America/New_York',
  },
  '24467': {
    zip: '24467',
    city: 'Mount Sidney',
    state: 'VA',
    latitude: '38.2582',
    longitude: '-78.960354',
    timeZoneId: 'America/New_York',
  },
  '24468': {
    zip: '24468',
    city: 'Mustoe',
    state: 'VA',
    latitude: '38.286593',
    longitude: '-79.659825',
    timeZoneId: 'America/New_York',
  },
  '24469': {
    zip: '24469',
    city: 'New Hope',
    state: 'VA',
    latitude: '38.197386',
    longitude: '-78.908518',
    timeZoneId: 'America/New_York',
  },
  '24471': {
    zip: '24471',
    city: 'Port Republic',
    state: 'VA',
    latitude: '38.318464',
    longitude: '-78.812924',
    timeZoneId: 'America/New_York',
  },
  '24472': {
    zip: '24472',
    city: 'Raphine',
    state: 'VA',
    latitude: '37.937785',
    longitude: '-79.136378',
    timeZoneId: 'America/New_York',
  },
  '24473': {
    zip: '24473',
    city: 'Rockbridge Baths',
    state: 'VA',
    latitude: '37.899159',
    longitude: '-79.396696',
    timeZoneId: 'America/New_York',
  },
  '24474': {
    zip: '24474',
    city: 'Selma',
    state: 'VA',
    latitude: '37.804564',
    longitude: '-79.850309',
    timeZoneId: 'America/New_York',
  },
  '24476': {
    zip: '24476',
    city: 'Steeles Tavern',
    state: 'VA',
    latitude: '37.926887',
    longitude: '-79.200418',
    timeZoneId: 'America/New_York',
  },
  '24477': {
    zip: '24477',
    city: 'Stuarts Draft',
    state: 'VA',
    latitude: '38.021357',
    longitude: '-79.03023',
    timeZoneId: 'America/New_York',
  },
  '24479': {
    zip: '24479',
    city: 'Swoope',
    state: 'VA',
    latitude: '38.142486',
    longitude: '-79.201796',
    timeZoneId: 'America/New_York',
  },
  '24482': {
    zip: '24482',
    city: 'Verona',
    state: 'VA',
    latitude: '38.199926',
    longitude: '-79.005878',
    timeZoneId: 'America/New_York',
  },
  '24483': {
    zip: '24483',
    city: 'Vesuvius',
    state: 'VA',
    latitude: '37.807797',
    longitude: '-79.222553',
    timeZoneId: 'America/New_York',
  },
  '24484': {
    zip: '24484',
    city: 'Warm Springs',
    state: 'VA',
    latitude: '38.217222',
    longitude: '-79.676667',
    timeZoneId: 'America/New_York',
  },
  '24485': {
    zip: '24485',
    city: 'West Augusta',
    state: 'VA',
    latitude: '38.297636',
    longitude: '-79.31556',
    timeZoneId: 'America/New_York',
  },
  '24486': {
    zip: '24486',
    city: 'Weyers Cave',
    state: 'VA',
    latitude: '38.26514',
    longitude: '-78.899185',
    timeZoneId: 'America/New_York',
  },
  '24487': {
    zip: '24487',
    city: 'Williamsville',
    state: 'VA',
    latitude: '38.177778',
    longitude: '-79.647778',
    timeZoneId: 'America/New_York',
  },
  '24501': {
    zip: '24501',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.399278',
    longitude: '-79.174704',
    timeZoneId: 'America/New_York',
  },
  '24502': {
    zip: '24502',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.359789',
    longitude: '-79.218967',
    timeZoneId: 'America/New_York',
  },
  '24503': {
    zip: '24503',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.434734',
    longitude: '-79.200804',
    timeZoneId: 'America/New_York',
  },
  '24504': {
    zip: '24504',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.406392',
    longitude: '-79.128255',
    timeZoneId: 'America/New_York',
  },
  '24505': {
    zip: '24505',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.416254',
    longitude: '-79.141791',
    timeZoneId: 'America/New_York',
  },
  '24506': {
    zip: '24506',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.355911',
    longitude: '-79.166735',
    timeZoneId: 'America/New_York',
  },
  '24513': {
    zip: '24513',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.403695',
    longitude: '-79.189857',
    timeZoneId: 'America/New_York',
  },
  '24514': {
    zip: '24514',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.365835',
    longitude: '-79.215293',
    timeZoneId: 'America/New_York',
  },
  '24515': {
    zip: '24515',
    city: 'Lynchburg',
    state: 'VA',
    latitude: '37.34945',
    longitude: '-79.157075',
    timeZoneId: 'America/New_York',
  },
  '24517': {
    zip: '24517',
    city: 'Altavista',
    state: 'VA',
    latitude: '37.12313',
    longitude: '-79.289743',
    timeZoneId: 'America/New_York',
  },
  '24520': {
    zip: '24520',
    city: 'Alton',
    state: 'VA',
    latitude: '36.712113',
    longitude: '-78.848345',
    timeZoneId: 'America/New_York',
  },
  '24521': {
    zip: '24521',
    city: 'Amherst',
    state: 'VA',
    latitude: '37.573238',
    longitude: '-79.057954',
    timeZoneId: 'America/New_York',
  },
  '24522': {
    zip: '24522',
    city: 'Appomattox',
    state: 'VA',
    latitude: '37.344372',
    longitude: '-78.826984',
    timeZoneId: 'America/New_York',
  },
  '24523': {
    zip: '24523',
    city: 'Bedford',
    state: 'VA',
    latitude: '37.334622',
    longitude: '-79.522483',
    timeZoneId: 'America/New_York',
  },
  '24526': {
    zip: '24526',
    city: 'Big Island',
    state: 'VA',
    latitude: '37.5339',
    longitude: '-79.38522',
    timeZoneId: 'America/New_York',
  },
  '24527': {
    zip: '24527',
    city: 'Blairs',
    state: 'VA',
    latitude: '36.709193',
    longitude: '-79.377666',
    timeZoneId: 'America/New_York',
  },
  '24528': {
    zip: '24528',
    city: 'Brookneal',
    state: 'VA',
    latitude: '37.054719',
    longitude: '-78.94691',
    timeZoneId: 'America/New_York',
  },
  '24529': {
    zip: '24529',
    city: 'Buffalo Junction',
    state: 'VA',
    latitude: '36.598421',
    longitude: '-78.619575',
    timeZoneId: 'America/New_York',
  },
  '24530': {
    zip: '24530',
    city: 'Callands',
    state: 'VA',
    latitude: '36.780419',
    longitude: '-79.636872',
    timeZoneId: 'America/New_York',
  },
  '24531': {
    zip: '24531',
    city: 'Chatham',
    state: 'VA',
    latitude: '36.829539',
    longitude: '-79.39511',
    timeZoneId: 'America/New_York',
  },
  '24533': {
    zip: '24533',
    city: 'Clifford',
    state: 'VA',
    latitude: '37.63588',
    longitude: '-79.062428',
    timeZoneId: 'America/New_York',
  },
  '24534': {
    zip: '24534',
    city: 'Clover',
    state: 'VA',
    latitude: '36.879431',
    longitude: '-78.787323',
    timeZoneId: 'America/New_York',
  },
  '24535': {
    zip: '24535',
    city: 'Cluster Springs',
    state: 'VA',
    latitude: '36.585431',
    longitude: '-79.033084',
    timeZoneId: 'America/New_York',
  },
  '24536': {
    zip: '24536',
    city: 'Coleman Falls',
    state: 'VA',
    latitude: '37.473399',
    longitude: '-79.324182',
    timeZoneId: 'America/New_York',
  },
  '24538': {
    zip: '24538',
    city: 'Concord',
    state: 'VA',
    latitude: '37.344367',
    longitude: '-78.992295',
    timeZoneId: 'America/New_York',
  },
  '24539': {
    zip: '24539',
    city: 'Crystal Hill',
    state: 'VA',
    latitude: '36.870824',
    longitude: '-78.966925',
    timeZoneId: 'America/New_York',
  },
  '24540': {
    zip: '24540',
    city: 'Danville',
    state: 'VA',
    latitude: '36.614911',
    longitude: '-79.389142',
    timeZoneId: 'America/New_York',
  },
  '24541': {
    zip: '24541',
    city: 'Danville',
    state: 'VA',
    latitude: '36.574659',
    longitude: '-79.436458',
    timeZoneId: 'America/New_York',
  },
  '24543': {
    zip: '24543',
    city: 'Danville',
    state: 'VA',
    latitude: '36.584238',
    longitude: '-79.513765',
    timeZoneId: 'America/New_York',
  },
  '24549': {
    zip: '24549',
    city: 'Dry Fork',
    state: 'VA',
    latitude: '36.743265',
    longitude: '-79.46251',
    timeZoneId: 'America/New_York',
  },
  '24550': {
    zip: '24550',
    city: 'Evington',
    state: 'VA',
    latitude: '37.259932',
    longitude: '-79.236144',
    timeZoneId: 'America/New_York',
  },
  '24551': {
    zip: '24551',
    city: 'Forest',
    state: 'VA',
    latitude: '37.354725',
    longitude: '-79.283113',
    timeZoneId: 'America/New_York',
  },
  '24553': {
    zip: '24553',
    city: 'Gladstone',
    state: 'VA',
    latitude: '37.545247',
    longitude: '-78.839877',
    timeZoneId: 'America/New_York',
  },
  '24554': {
    zip: '24554',
    city: 'Gladys',
    state: 'VA',
    latitude: '37.113813',
    longitude: '-79.283359',
    timeZoneId: 'America/New_York',
  },
  '24555': {
    zip: '24555',
    city: 'Glasgow',
    state: 'VA',
    latitude: '37.63469',
    longitude: '-79.452214',
    timeZoneId: 'America/New_York',
  },
  '24556': {
    zip: '24556',
    city: 'Goode',
    state: 'VA',
    latitude: '37.366667',
    longitude: '-79.391704',
    timeZoneId: 'America/New_York',
  },
  '24557': {
    zip: '24557',
    city: 'Gretna',
    state: 'VA',
    latitude: '36.952581',
    longitude: '-79.364393',
    timeZoneId: 'America/New_York',
  },
  '24558': {
    zip: '24558',
    city: 'Halifax',
    state: 'VA',
    latitude: '36.764205',
    longitude: '-78.927758',
    timeZoneId: 'America/New_York',
  },
  '24562': {
    zip: '24562',
    city: 'Howardsville',
    state: 'VA',
    latitude: '37.767311',
    longitude: '-78.589328',
    timeZoneId: 'America/New_York',
  },
  '24563': {
    zip: '24563',
    city: 'Hurt',
    state: 'VA',
    latitude: '37.093845',
    longitude: '-79.295074',
    timeZoneId: 'America/New_York',
  },
  '24565': {
    zip: '24565',
    city: 'Java',
    state: 'VA',
    latitude: '36.86491',
    longitude: '-79.186583',
    timeZoneId: 'America/New_York',
  },
  '24566': {
    zip: '24566',
    city: 'Keeling',
    state: 'VA',
    latitude: '36.723243',
    longitude: '-79.26099',
    timeZoneId: 'America/New_York',
  },
  '24569': {
    zip: '24569',
    city: 'Long Island',
    state: 'VA',
    latitude: '37.068332',
    longitude: '-79.117191',
    timeZoneId: 'America/New_York',
  },
  '24570': {
    zip: '24570',
    city: 'Lowry',
    state: 'VA',
    latitude: '37.344787',
    longitude: '-79.451814',
    timeZoneId: 'America/New_York',
  },
  '24571': {
    zip: '24571',
    city: 'Lynch Station',
    state: 'VA',
    latitude: '37.14673',
    longitude: '-79.364812',
    timeZoneId: 'America/New_York',
  },
  '24572': {
    zip: '24572',
    city: 'Madison Heights',
    state: 'VA',
    latitude: '37.447984',
    longitude: '-79.112687',
    timeZoneId: 'America/New_York',
  },
  '24574': {
    zip: '24574',
    city: 'Monroe',
    state: 'VA',
    latitude: '37.519749',
    longitude: '-79.184428',
    timeZoneId: 'America/New_York',
  },
  '24576': {
    zip: '24576',
    city: 'Naruna',
    state: 'VA',
    latitude: '37.093469',
    longitude: '-78.90219',
    timeZoneId: 'America/New_York',
  },
  '24577': {
    zip: '24577',
    city: 'Nathalie',
    state: 'VA',
    latitude: '36.942263',
    longitude: '-78.970967',
    timeZoneId: 'America/New_York',
  },
  '24578': {
    zip: '24578',
    city: 'Natural Bridge',
    state: 'VA',
    latitude: '37.665785',
    longitude: '-79.551093',
    timeZoneId: 'America/New_York',
  },
  '24579': {
    zip: '24579',
    city: 'Natural Bridge Station',
    state: 'VA',
    latitude: '37.588035',
    longitude: '-79.502939',
    timeZoneId: 'America/New_York',
  },
  '24580': {
    zip: '24580',
    city: 'Nelson',
    state: 'VA',
    latitude: '36.555409',
    longitude: '-78.672411',
    timeZoneId: 'America/New_York',
  },
  '24581': {
    zip: '24581',
    city: 'Norwood',
    state: 'VA',
    latitude: '37.626118',
    longitude: '-78.757592',
    timeZoneId: 'America/New_York',
  },
  '24586': {
    zip: '24586',
    city: 'Ringgold',
    state: 'VA',
    latitude: '36.591963',
    longitude: '-79.308479',
    timeZoneId: 'America/New_York',
  },
  '24588': {
    zip: '24588',
    city: 'Rustburg',
    state: 'VA',
    latitude: '37.295271',
    longitude: '-79.148504',
    timeZoneId: 'America/New_York',
  },
  '24589': {
    zip: '24589',
    city: 'Scottsburg',
    state: 'VA',
    latitude: '36.771223',
    longitude: '-78.761974',
    timeZoneId: 'America/New_York',
  },
  '24590': {
    zip: '24590',
    city: 'Scottsville',
    state: 'VA',
    latitude: '37.810811',
    longitude: '-78.512844',
    timeZoneId: 'America/New_York',
  },
  '24592': {
    zip: '24592',
    city: 'South Boston',
    state: 'VA',
    latitude: '36.709303',
    longitude: '-78.90154',
    timeZoneId: 'America/New_York',
  },
  '24593': {
    zip: '24593',
    city: 'Spout Spring',
    state: 'VA',
    latitude: '37.368264',
    longitude: '-78.90834',
    timeZoneId: 'America/New_York',
  },
  '24594': {
    zip: '24594',
    city: 'Sutherlin',
    state: 'VA',
    latitude: '36.62756',
    longitude: '-79.189326',
    timeZoneId: 'America/New_York',
  },
  '24595': {
    zip: '24595',
    city: 'Sweet Briar',
    state: 'VA',
    latitude: '37.562727',
    longitude: '-79.082436',
    timeZoneId: 'America/New_York',
  },
  '24597': {
    zip: '24597',
    city: 'Vernon Hill',
    state: 'VA',
    latitude: '36.79231',
    longitude: '-79.12474',
    timeZoneId: 'America/New_York',
  },
  '24598': {
    zip: '24598',
    city: 'Virgilina',
    state: 'VA',
    latitude: '36.604233',
    longitude: '-78.76586',
    timeZoneId: 'America/New_York',
  },
  '24599': {
    zip: '24599',
    city: 'Wingina',
    state: 'VA',
    latitude: '37.62327',
    longitude: '-78.759548',
    timeZoneId: 'America/New_York',
  },
  '24601': {
    zip: '24601',
    city: 'Amonate',
    state: 'VA',
    latitude: '37.190202',
    longitude: '-81.640645',
    timeZoneId: 'America/New_York',
  },
  '24602': {
    zip: '24602',
    city: 'Bandy',
    state: 'VA',
    latitude: '37.175946',
    longitude: '-81.666701',
    timeZoneId: 'America/New_York',
  },
  '24603': {
    zip: '24603',
    city: 'Big Rock',
    state: 'VA',
    latitude: '37.352222',
    longitude: '-82.211944',
    timeZoneId: 'America/New_York',
  },
  '24604': {
    zip: '24604',
    city: 'Bishop',
    state: 'VA',
    latitude: '37.210094',
    longitude: '-81.555976',
    timeZoneId: 'America/New_York',
  },
  '24605': {
    zip: '24605',
    city: 'Bluefield',
    state: 'VA',
    latitude: '37.26336',
    longitude: '-81.28878',
    timeZoneId: 'America/New_York',
  },
  '24606': {
    zip: '24606',
    city: 'Boissevain',
    state: 'VA',
    latitude: '37.284352',
    longitude: '-81.382042',
    timeZoneId: 'America/New_York',
  },
  '24607': {
    zip: '24607',
    city: 'Breaks',
    state: 'VA',
    latitude: '37.301451',
    longitude: '-82.307866',
    timeZoneId: 'America/New_York',
  },
  '24608': {
    zip: '24608',
    city: 'Burkes Garden',
    state: 'VA',
    latitude: '37.116004',
    longitude: '-81.525343',
    timeZoneId: 'America/New_York',
  },
  '24609': {
    zip: '24609',
    city: 'Cedar Bluff',
    state: 'VA',
    latitude: '37.08384',
    longitude: '-81.76854',
    timeZoneId: 'America/New_York',
  },
  '24612': {
    zip: '24612',
    city: 'Doran',
    state: 'VA',
    latitude: '37.090762',
    longitude: '-81.791295',
    timeZoneId: 'America/New_York',
  },
  '24613': {
    zip: '24613',
    city: 'Falls Mills',
    state: 'VA',
    latitude: '37.265833',
    longitude: '-81.331458',
    timeZoneId: 'America/New_York',
  },
  '24614': {
    zip: '24614',
    city: 'Grundy',
    state: 'VA',
    latitude: '37.277524',
    longitude: '-82.094356',
    timeZoneId: 'America/New_York',
  },
  '24619': {
    zip: '24619',
    city: 'Horsepen',
    state: 'VA',
    latitude: '37.227452',
    longitude: '-81.517321',
    timeZoneId: 'America/New_York',
  },
  '24620': {
    zip: '24620',
    city: 'Hurley',
    state: 'VA',
    latitude: '37.417851',
    longitude: '-82.017149',
    timeZoneId: 'America/New_York',
  },
  '24622': {
    zip: '24622',
    city: 'Jewell Ridge',
    state: 'VA',
    latitude: '37.226926',
    longitude: '-81.810969',
    timeZoneId: 'America/New_York',
  },
  '24624': {
    zip: '24624',
    city: 'Keen Mountain',
    state: 'VA',
    latitude: '37.204379',
    longitude: '-81.987056',
    timeZoneId: 'America/New_York',
  },
  '24627': {
    zip: '24627',
    city: 'Mavisdale',
    state: 'VA',
    latitude: '37.182988',
    longitude: '-82.215192',
    timeZoneId: 'America/New_York',
  },
  '24628': {
    zip: '24628',
    city: 'Maxie',
    state: 'VA',
    latitude: '37.303473',
    longitude: '-82.173372',
    timeZoneId: 'America/New_York',
  },
  '24630': {
    zip: '24630',
    city: 'North Tazewell',
    state: 'VA',
    latitude: '37.1547',
    longitude: '-81.51132',
    timeZoneId: 'America/New_York',
  },
  '24631': {
    zip: '24631',
    city: 'Oakwood',
    state: 'VA',
    latitude: '37.264444',
    longitude: '-81.976111',
    timeZoneId: 'America/New_York',
  },
  '24634': {
    zip: '24634',
    city: 'Pilgrims Knob',
    state: 'VA',
    latitude: '37.297807',
    longitude: '-81.906793',
    timeZoneId: 'America/New_York',
  },
  '24635': {
    zip: '24635',
    city: 'Pocahontas',
    state: 'VA',
    latitude: '37.303883',
    longitude: '-81.335643',
    timeZoneId: 'America/New_York',
  },
  '24637': {
    zip: '24637',
    city: 'Pounding Mill',
    state: 'VA',
    latitude: '37.05798',
    longitude: '-81.73812',
    timeZoneId: 'America/New_York',
  },
  '24639': {
    zip: '24639',
    city: 'Raven',
    state: 'VA',
    latitude: '37.272658',
    longitude: '-82.105579',
    timeZoneId: 'America/New_York',
  },
  '24640': {
    zip: '24640',
    city: 'Red Ash',
    state: 'VA',
    latitude: '37.106469',
    longitude: '-81.862722',
    timeZoneId: 'America/New_York',
  },
  '24641': {
    zip: '24641',
    city: 'Richlands',
    state: 'VA',
    latitude: '37.093426',
    longitude: '-81.807039',
    timeZoneId: 'America/New_York',
  },
  '24646': {
    zip: '24646',
    city: 'Rowe',
    state: 'VA',
    latitude: '37.12663',
    longitude: '-82.03658',
    timeZoneId: 'America/New_York',
  },
  '24647': {
    zip: '24647',
    city: 'Shortt Gap',
    state: 'VA',
    latitude: '37.158485',
    longitude: '-81.873769',
    timeZoneId: 'America/New_York',
  },
  '24649': {
    zip: '24649',
    city: 'Swords Creek',
    state: 'VA',
    latitude: '37.081098',
    longitude: '-81.917929',
    timeZoneId: 'America/New_York',
  },
  '24651': {
    zip: '24651',
    city: 'Tazewell',
    state: 'VA',
    latitude: '37.11444',
    longitude: '-81.52248',
    timeZoneId: 'America/New_York',
  },
  '24656': {
    zip: '24656',
    city: 'Vansant',
    state: 'VA',
    latitude: '37.160378',
    longitude: '-82.127811',
    timeZoneId: 'America/New_York',
  },
  '24657': {
    zip: '24657',
    city: 'Whitewood',
    state: 'VA',
    latitude: '37.266217',
    longitude: '-81.886651',
    timeZoneId: 'America/New_York',
  },
  '24658': {
    zip: '24658',
    city: 'Wolford',
    state: 'VA',
    latitude: '37.352536',
    longitude: '-81.991717',
    timeZoneId: 'America/New_York',
  },
  '24701': {
    zip: '24701',
    city: 'Bluefield',
    state: 'WV',
    latitude: '37.281',
    longitude: '-81.19728',
    timeZoneId: 'America/New_York',
  },
  '24712': {
    zip: '24712',
    city: 'Athens',
    state: 'WV',
    latitude: '37.457773',
    longitude: '-80.997359',
    timeZoneId: 'America/New_York',
  },
  '24714': {
    zip: '24714',
    city: 'Beeson',
    state: 'WV',
    latitude: '37.496536',
    longitude: '-81.18521',
    timeZoneId: 'America/New_York',
  },
  '24715': {
    zip: '24715',
    city: 'Bramwell',
    state: 'WV',
    latitude: '37.337624',
    longitude: '-81.331466',
    timeZoneId: 'America/New_York',
  },
  '24716': {
    zip: '24716',
    city: 'Bud',
    state: 'WV',
    latitude: '37.5349',
    longitude: '-81.378125',
    timeZoneId: 'America/New_York',
  },
  '24719': {
    zip: '24719',
    city: 'Covel',
    state: 'WV',
    latitude: '37.492361',
    longitude: '-81.322125',
    timeZoneId: 'America/New_York',
  },
  '24724': {
    zip: '24724',
    city: 'Freeman',
    state: 'WV',
    latitude: '37.363056',
    longitude: '-81.334444',
    timeZoneId: 'America/New_York',
  },
  '24726': {
    zip: '24726',
    city: 'Herndon',
    state: 'WV',
    latitude: '37.517748',
    longitude: '-81.346153',
    timeZoneId: 'America/New_York',
  },
  '24729': {
    zip: '24729',
    city: 'Hiawatha',
    state: 'WV',
    latitude: '37.439979',
    longitude: '-81.244511',
    timeZoneId: 'America/New_York',
  },
  '24731': {
    zip: '24731',
    city: 'Kegley',
    state: 'WV',
    latitude: '37.413077',
    longitude: '-81.140557',
    timeZoneId: 'America/New_York',
  },
  '24732': {
    zip: '24732',
    city: 'Kellysville',
    state: 'WV',
    latitude: '37.341698',
    longitude: '-80.92781',
    timeZoneId: 'America/New_York',
  },
  '24733': {
    zip: '24733',
    city: 'Lashmeet',
    state: 'WV',
    latitude: '37.445081',
    longitude: '-81.217426',
    timeZoneId: 'America/New_York',
  },
  '24736': {
    zip: '24736',
    city: 'Matoaka',
    state: 'WV',
    latitude: '37.419663',
    longitude: '-81.244363',
    timeZoneId: 'America/New_York',
  },
  '24737': {
    zip: '24737',
    city: 'Montcalm',
    state: 'WV',
    latitude: '37.355619',
    longitude: '-81.254095',
    timeZoneId: 'America/New_York',
  },
  '24738': {
    zip: '24738',
    city: 'Nemours',
    state: 'WV',
    latitude: '37.257627',
    longitude: '-81.222882',
    timeZoneId: 'America/New_York',
  },
  '24739': {
    zip: '24739',
    city: 'Princeton',
    state: 'WV',
    latitude: '37.33004',
    longitude: '-80.967203',
    timeZoneId: 'America/New_York',
  },
  '24740': {
    zip: '24740',
    city: 'Princeton',
    state: 'WV',
    latitude: '37.41108',
    longitude: '-81.02724',
    timeZoneId: 'America/New_York',
  },
  '24747': {
    zip: '24747',
    city: 'Rock',
    state: 'WV',
    latitude: '37.389223',
    longitude: '-81.233084',
    timeZoneId: 'America/New_York',
  },
  '24751': {
    zip: '24751',
    city: 'Wolfe',
    state: 'WV',
    latitude: '37.307986',
    longitude: '-81.330659',
    timeZoneId: 'America/New_York',
  },
  '24801': {
    zip: '24801',
    city: 'Welch',
    state: 'WV',
    latitude: '37.430754',
    longitude: '-81.577391',
    timeZoneId: 'America/New_York',
  },
  '24808': {
    zip: '24808',
    city: 'Anawalt',
    state: 'WV',
    latitude: '37.333193',
    longitude: '-81.438739',
    timeZoneId: 'America/New_York',
  },
  '24811': {
    zip: '24811',
    city: 'Avondale',
    state: 'WV',
    latitude: '37.400556',
    longitude: '-81.784722',
    timeZoneId: 'America/New_York',
  },
  '24813': {
    zip: '24813',
    city: 'Bartley',
    state: 'WV',
    latitude: '37.340033',
    longitude: '-81.736141',
    timeZoneId: 'America/New_York',
  },
  '24815': {
    zip: '24815',
    city: 'Berwind',
    state: 'WV',
    latitude: '37.269547',
    longitude: '-81.66513',
    timeZoneId: 'America/New_York',
  },
  '24816': {
    zip: '24816',
    city: 'Big Sandy',
    state: 'WV',
    latitude: '37.465034',
    longitude: '-81.697432',
    timeZoneId: 'America/New_York',
  },
  '24817': {
    zip: '24817',
    city: 'Bradshaw',
    state: 'WV',
    latitude: '37.354998',
    longitude: '-81.798608',
    timeZoneId: 'America/New_York',
  },
  '24818': {
    zip: '24818',
    city: 'Brenton',
    state: 'WV',
    latitude: '37.582931',
    longitude: '-81.667453',
    timeZoneId: 'America/New_York',
  },
  '24822': {
    zip: '24822',
    city: 'Clear Fork',
    state: 'WV',
    latitude: '37.651797',
    longitude: '-81.691747',
    timeZoneId: 'America/New_York',
  },
  '24823': {
    zip: '24823',
    city: 'Coal Mountain',
    state: 'WV',
    latitude: '37.669843',
    longitude: '-81.729868',
    timeZoneId: 'America/New_York',
  },
  '24826': {
    zip: '24826',
    city: 'Cucumber',
    state: 'WV',
    latitude: '37.284573',
    longitude: '-81.621095',
    timeZoneId: 'America/New_York',
  },
  '24827': {
    zip: '24827',
    city: 'Cyclone',
    state: 'WV',
    latitude: '37.740116',
    longitude: '-81.664871',
    timeZoneId: 'America/New_York',
  },
  '24828': {
    zip: '24828',
    city: 'Davy',
    state: 'WV',
    latitude: '37.470561',
    longitude: '-81.65949',
    timeZoneId: 'America/New_York',
  },
  '24829': {
    zip: '24829',
    city: 'Eckman',
    state: 'WV',
    latitude: '37.442847',
    longitude: '-81.595444',
    timeZoneId: 'America/New_York',
  },
  '24830': {
    zip: '24830',
    city: 'Elbert',
    state: 'WV',
    latitude: '37.318056',
    longitude: '-81.544167',
    timeZoneId: 'America/New_York',
  },
  '24831': {
    zip: '24831',
    city: 'Elkhorn',
    state: 'WV',
    latitude: '37.352821',
    longitude: '-81.455533',
    timeZoneId: 'America/New_York',
  },
  '24834': {
    zip: '24834',
    city: 'Fanrock',
    state: 'WV',
    latitude: '37.566531',
    longitude: '-81.615628',
    timeZoneId: 'America/New_York',
  },
  '24836': {
    zip: '24836',
    city: 'Gary',
    state: 'WV',
    latitude: '37.359828',
    longitude: '-81.553478',
    timeZoneId: 'America/New_York',
  },
  '24839': {
    zip: '24839',
    city: 'Hanover',
    state: 'WV',
    latitude: '37.55507',
    longitude: '-81.821381',
    timeZoneId: 'America/New_York',
  },
  '24843': {
    zip: '24843',
    city: 'Hensley',
    state: 'WV',
    latitude: '37.478213',
    longitude: '-81.676884',
    timeZoneId: 'America/New_York',
  },
  '24844': {
    zip: '24844',
    city: 'Iaeger',
    state: 'WV',
    latitude: '37.4382',
    longitude: '-81.8109',
    timeZoneId: 'America/New_York',
  },
  '24845': {
    zip: '24845',
    city: 'Ikes Fork',
    state: 'WV',
    latitude: '37.540509',
    longitude: '-81.795247',
    timeZoneId: 'America/New_York',
  },
  '24846': {
    zip: '24846',
    city: 'Isaban',
    state: 'WV',
    latitude: '37.528355',
    longitude: '-81.88692',
    timeZoneId: 'America/New_York',
  },
  '24847': {
    zip: '24847',
    city: 'Itmann',
    state: 'WV',
    latitude: '37.574439',
    longitude: '-81.419907',
    timeZoneId: 'America/New_York',
  },
  '24848': {
    zip: '24848',
    city: 'Jenkinjones',
    state: 'WV',
    latitude: '37.29277',
    longitude: '-81.427872',
    timeZoneId: 'America/New_York',
  },
  '24849': {
    zip: '24849',
    city: 'Jesse',
    state: 'WV',
    latitude: '37.669558',
    longitude: '-81.558865',
    timeZoneId: 'America/New_York',
  },
  '24850': {
    zip: '24850',
    city: 'Jolo',
    state: 'WV',
    latitude: '37.332245',
    longitude: '-81.833824',
    timeZoneId: 'America/New_York',
  },
  '24851': {
    zip: '24851',
    city: 'Justice',
    state: 'WV',
    latitude: '37.586477',
    longitude: '-81.838907',
    timeZoneId: 'America/New_York',
  },
  '24853': {
    zip: '24853',
    city: 'Kimball',
    state: 'WV',
    latitude: '37.424159',
    longitude: '-81.5099',
    timeZoneId: 'America/New_York',
  },
  '24854': {
    zip: '24854',
    city: 'Kopperston',
    state: 'WV',
    latitude: '37.74513',
    longitude: '-81.570297',
    timeZoneId: 'America/New_York',
  },
  '24855': {
    zip: '24855',
    city: 'Kyle',
    state: 'WV',
    latitude: '37.407474',
    longitude: '-81.427908',
    timeZoneId: 'America/New_York',
  },
  '24857': {
    zip: '24857',
    city: 'Lynco',
    state: 'WV',
    latitude: '37.676111',
    longitude: '-81.663333',
    timeZoneId: 'America/New_York',
  },
  '24859': {
    zip: '24859',
    city: 'Marianna',
    state: 'WV',
    latitude: '37.617318',
    longitude: '-81.578242',
    timeZoneId: 'America/New_York',
  },
  '24860': {
    zip: '24860',
    city: 'Matheny',
    state: 'WV',
    latitude: '37.683186',
    longitude: '-81.587399',
    timeZoneId: 'America/New_York',
  },
  '24861': {
    zip: '24861',
    city: 'Maybeury',
    state: 'WV',
    latitude: '37.376327',
    longitude: '-81.357481',
    timeZoneId: 'America/New_York',
  },
  '24862': {
    zip: '24862',
    city: 'Mohawk',
    state: 'WV',
    latitude: '37.499123',
    longitude: '-81.901942',
    timeZoneId: 'America/New_York',
  },
  '24866': {
    zip: '24866',
    city: 'Newhall',
    state: 'WV',
    latitude: '37.261429',
    longitude: '-81.615283',
    timeZoneId: 'America/New_York',
  },
  '24867': {
    zip: '24867',
    city: 'New Richmond',
    state: 'WV',
    latitude: '37.546054',
    longitude: '-81.47285',
    timeZoneId: 'America/New_York',
  },
  '24868': {
    zip: '24868',
    city: 'Northfork',
    state: 'WV',
    latitude: '37.381371',
    longitude: '-81.410642',
    timeZoneId: 'America/New_York',
  },
  '24869': {
    zip: '24869',
    city: 'North Spring',
    state: 'WV',
    latitude: '37.523136',
    longitude: '-81.799159',
    timeZoneId: 'America/New_York',
  },
  '24870': {
    zip: '24870',
    city: 'Oceana',
    state: 'WV',
    latitude: '37.71102',
    longitude: '-81.60402',
    timeZoneId: 'America/New_York',
  },
  '24871': {
    zip: '24871',
    city: 'Pageton',
    state: 'WV',
    latitude: '37.349119',
    longitude: '-81.467423',
    timeZoneId: 'America/New_York',
  },
  '24872': {
    zip: '24872',
    city: 'Panther',
    state: 'WV',
    latitude: '37.481698',
    longitude: '-81.89857',
    timeZoneId: 'America/New_York',
  },
  '24873': {
    zip: '24873',
    city: 'Paynesville',
    state: 'WV',
    latitude: '37.361138',
    longitude: '-81.664254',
    timeZoneId: 'America/New_York',
  },
  '24874': {
    zip: '24874',
    city: 'Pineville',
    state: 'WV',
    latitude: '37.556987',
    longitude: '-81.529921',
    timeZoneId: 'America/New_York',
  },
  '24878': {
    zip: '24878',
    city: 'Premier',
    state: 'WV',
    latitude: '37.434771',
    longitude: '-81.633844',
    timeZoneId: 'America/New_York',
  },
  '24879': {
    zip: '24879',
    city: 'Raysal',
    state: 'WV',
    latitude: '37.349167',
    longitude: '-81.762778',
    timeZoneId: 'America/New_York',
  },
  '24880': {
    zip: '24880',
    city: 'Rock View',
    state: 'WV',
    latitude: '37.554073',
    longitude: '-81.524227',
    timeZoneId: 'America/New_York',
  },
  '24881': {
    zip: '24881',
    city: 'Roderfield',
    state: 'WV',
    latitude: '37.449608',
    longitude: '-81.70906',
    timeZoneId: 'America/New_York',
  },
  '24882': {
    zip: '24882',
    city: 'Simon',
    state: 'WV',
    latitude: '37.617947',
    longitude: '-81.767737',
    timeZoneId: 'America/New_York',
  },
  '24884': {
    zip: '24884',
    city: 'Squire',
    state: 'WV',
    latitude: '37.263607',
    longitude: '-81.571953',
    timeZoneId: 'America/New_York',
  },
  '24887': {
    zip: '24887',
    city: 'Switchback',
    state: 'WV',
    latitude: '37.36711',
    longitude: '-81.302224',
    timeZoneId: 'America/New_York',
  },
  '24888': {
    zip: '24888',
    city: 'Thorpe',
    state: 'WV',
    latitude: '37.441338',
    longitude: '-81.596776',
    timeZoneId: 'America/New_York',
  },
  '24892': {
    zip: '24892',
    city: 'War',
    state: 'WV',
    latitude: '37.304104',
    longitude: '-81.680662',
    timeZoneId: 'America/New_York',
  },
  '24894': {
    zip: '24894',
    city: 'Warriormine',
    state: 'WV',
    latitude: '37.291751',
    longitude: '-81.696432',
    timeZoneId: 'America/New_York',
  },
  '24895': {
    zip: '24895',
    city: 'Wilcoe',
    state: 'WV',
    latitude: '37.440921',
    longitude: '-81.595056',
    timeZoneId: 'America/New_York',
  },
  '24898': {
    zip: '24898',
    city: 'Wyoming',
    state: 'WV',
    latitude: '37.577932',
    longitude: '-81.585334',
    timeZoneId: 'America/New_York',
  },
  '24901': {
    zip: '24901',
    city: 'Lewisburg',
    state: 'WV',
    latitude: '37.80067',
    longitude: '-80.437878',
    timeZoneId: 'America/New_York',
  },
  '24902': {
    zip: '24902',
    city: 'Fairlea',
    state: 'WV',
    latitude: '37.777986',
    longitude: '-80.453921',
    timeZoneId: 'America/New_York',
  },
  '24910': {
    zip: '24910',
    city: 'Alderson',
    state: 'WV',
    latitude: '37.7322',
    longitude: '-80.66064',
    timeZoneId: 'America/New_York',
  },
  '24915': {
    zip: '24915',
    city: 'Arbovale',
    state: 'WV',
    latitude: '38.456002',
    longitude: '-79.753687',
    timeZoneId: 'America/New_York',
  },
  '24916': {
    zip: '24916',
    city: 'Asbury',
    state: 'WV',
    latitude: '37.834853',
    longitude: '-80.573425',
    timeZoneId: 'America/New_York',
  },
  '24918': {
    zip: '24918',
    city: 'Ballard',
    state: 'WV',
    latitude: '37.512562',
    longitude: '-80.758132',
    timeZoneId: 'America/New_York',
  },
  '24920': {
    zip: '24920',
    city: 'Bartow',
    state: 'WV',
    latitude: '38.581829',
    longitude: '-79.717497',
    timeZoneId: 'America/New_York',
  },
  '24924': {
    zip: '24924',
    city: 'Buckeye',
    state: 'WV',
    latitude: '38.190266',
    longitude: '-80.142658',
    timeZoneId: 'America/New_York',
  },
  '24925': {
    zip: '24925',
    city: 'Caldwell',
    state: 'WV',
    latitude: '37.754016',
    longitude: '-80.348267',
    timeZoneId: 'America/New_York',
  },
  '24927': {
    zip: '24927',
    city: 'Cass',
    state: 'WV',
    latitude: '38.364167',
    longitude: '-79.9625',
    timeZoneId: 'America/New_York',
  },
  '24931': {
    zip: '24931',
    city: 'Crawley',
    state: 'WV',
    latitude: '37.880631',
    longitude: '-80.587119',
    timeZoneId: 'America/New_York',
  },
  '24934': {
    zip: '24934',
    city: 'Dunmore',
    state: 'WV',
    latitude: '38.356916',
    longitude: '-79.828665',
    timeZoneId: 'America/New_York',
  },
  '24935': {
    zip: '24935',
    city: 'Forest Hill',
    state: 'WV',
    latitude: '37.554495',
    longitude: '-80.831424',
    timeZoneId: 'America/New_York',
  },
  '24938': {
    zip: '24938',
    city: 'Frankford',
    state: 'WV',
    latitude: '37.90977',
    longitude: '-80.357408',
    timeZoneId: 'America/New_York',
  },
  '24941': {
    zip: '24941',
    city: 'Gap Mills',
    state: 'WV',
    latitude: '37.521353',
    longitude: '-80.372282',
    timeZoneId: 'America/New_York',
  },
  '24943': {
    zip: '24943',
    city: 'Grassy Meadows',
    state: 'WV',
    latitude: '37.844161',
    longitude: '-80.743448',
    timeZoneId: 'America/New_York',
  },
  '24944': {
    zip: '24944',
    city: 'Green Bank',
    state: 'WV',
    latitude: '38.392363',
    longitude: '-79.78578',
    timeZoneId: 'America/New_York',
  },
  '24945': {
    zip: '24945',
    city: 'Greenville',
    state: 'WV',
    latitude: '37.532578',
    longitude: '-80.666547',
    timeZoneId: 'America/New_York',
  },
  '24946': {
    zip: '24946',
    city: 'Hillsboro',
    state: 'WV',
    latitude: '38.13282',
    longitude: '-80.24382',
    timeZoneId: 'America/New_York',
  },
  '24951': {
    zip: '24951',
    city: 'Lindside',
    state: 'WV',
    latitude: '37.676334',
    longitude: '-80.887504',
    timeZoneId: 'America/New_York',
  },
  '24954': {
    zip: '24954',
    city: 'Marlinton',
    state: 'WV',
    latitude: '38.21166',
    longitude: '-80.05566',
    timeZoneId: 'America/New_York',
  },
  '24957': {
    zip: '24957',
    city: 'Maxwelton',
    state: 'WV',
    latitude: '37.908011',
    longitude: '-80.425002',
    timeZoneId: 'America/New_York',
  },
  '24962': {
    zip: '24962',
    city: 'Pence Springs',
    state: 'WV',
    latitude: '37.670191',
    longitude: '-80.711159',
    timeZoneId: 'America/New_York',
  },
  '24963': {
    zip: '24963',
    city: 'Peterstown',
    state: 'WV',
    latitude: '37.398643',
    longitude: '-80.80014',
    timeZoneId: 'America/New_York',
  },
  '24966': {
    zip: '24966',
    city: 'Renick',
    state: 'WV',
    latitude: '38.065844',
    longitude: '-80.376621',
    timeZoneId: 'America/New_York',
  },
  '24970': {
    zip: '24970',
    city: 'Ronceverte',
    state: 'WV',
    latitude: '37.752172',
    longitude: '-80.467326',
    timeZoneId: 'America/New_York',
  },
  '24974': {
    zip: '24974',
    city: 'Secondcreek',
    state: 'WV',
    latitude: '37.659279',
    longitude: '-80.409383',
    timeZoneId: 'America/New_York',
  },
  '24976': {
    zip: '24976',
    city: 'Sinks Grove',
    state: 'WV',
    latitude: '37.668229',
    longitude: '-80.523874',
    timeZoneId: 'America/New_York',
  },
  '24977': {
    zip: '24977',
    city: 'Smoot',
    state: 'WV',
    latitude: '37.866405',
    longitude: '-80.665267',
    timeZoneId: 'America/New_York',
  },
  '24981': {
    zip: '24981',
    city: 'Talcott',
    state: 'WV',
    latitude: '37.648685',
    longitude: '-80.742207',
    timeZoneId: 'America/New_York',
  },
  '24983': {
    zip: '24983',
    city: 'Union',
    state: 'WV',
    latitude: '37.56209',
    longitude: '-80.514593',
    timeZoneId: 'America/New_York',
  },
  '24984': {
    zip: '24984',
    city: 'Waiteville',
    state: 'WV',
    latitude: '37.464502',
    longitude: '-80.460659',
    timeZoneId: 'America/New_York',
  },
  '24985': {
    zip: '24985',
    city: 'Wayside',
    state: 'WV',
    latitude: '37.634903',
    longitude: '-80.702448',
    timeZoneId: 'America/New_York',
  },
  '24986': {
    zip: '24986',
    city: 'White Sulphur Springs',
    state: 'WV',
    latitude: '37.797867',
    longitude: '-80.296195',
    timeZoneId: 'America/New_York',
  },
  '24991': {
    zip: '24991',
    city: 'Williamsburg',
    state: 'WV',
    latitude: '37.95153',
    longitude: '-80.512983',
    timeZoneId: 'America/New_York',
  },
  '24993': {
    zip: '24993',
    city: 'Wolfcreek',
    state: 'WV',
    latitude: '37.627564',
    longitude: '-80.605011',
    timeZoneId: 'America/New_York',
  },
  '25002': {
    zip: '25002',
    city: 'Alloy',
    state: 'WV',
    latitude: '38.1816',
    longitude: '-81.32879',
    timeZoneId: 'America/New_York',
  },
  '25003': {
    zip: '25003',
    city: 'Alum Creek',
    state: 'WV',
    latitude: '38.257455',
    longitude: '-81.79268',
    timeZoneId: 'America/New_York',
  },
  '25005': {
    zip: '25005',
    city: 'Amma',
    state: 'WV',
    latitude: '38.561614',
    longitude: '-81.264914',
    timeZoneId: 'America/New_York',
  },
  '25007': {
    zip: '25007',
    city: 'Arnett',
    state: 'WV',
    latitude: '37.840631',
    longitude: '-81.438192',
    timeZoneId: 'America/New_York',
  },
  '25008': {
    zip: '25008',
    city: 'Artie',
    state: 'WV',
    latitude: '37.915001',
    longitude: '-81.36805',
    timeZoneId: 'America/New_York',
  },
  '25009': {
    zip: '25009',
    city: 'Ashford',
    state: 'WV',
    latitude: '38.190518',
    longitude: '-81.71281',
    timeZoneId: 'America/New_York',
  },
  '25011': {
    zip: '25011',
    city: 'Bancroft',
    state: 'WV',
    latitude: '38.330954',
    longitude: '-81.604553',
    timeZoneId: 'America/New_York',
  },
  '25015': {
    zip: '25015',
    city: 'Belle',
    state: 'WV',
    latitude: '38.233939',
    longitude: '-81.528074',
    timeZoneId: 'America/New_York',
  },
  '25019': {
    zip: '25019',
    city: 'Bickmore',
    state: 'WV',
    latitude: '38.3575',
    longitude: '-81.103889',
    timeZoneId: 'America/New_York',
  },
  '25021': {
    zip: '25021',
    city: 'Bim',
    state: 'WV',
    latitude: '37.877938',
    longitude: '-81.709598',
    timeZoneId: 'America/New_York',
  },
  '25022': {
    zip: '25022',
    city: 'Blair',
    state: 'WV',
    latitude: '37.877303',
    longitude: '-81.827231',
    timeZoneId: 'America/New_York',
  },
  '25024': {
    zip: '25024',
    city: 'Bloomingrose',
    state: 'WV',
    latitude: '38.155799',
    longitude: '-81.625292',
    timeZoneId: 'America/New_York',
  },
  '25025': {
    zip: '25025',
    city: 'Blount',
    state: 'WV',
    latitude: '38.302878',
    longitude: '-81.384071',
    timeZoneId: 'America/New_York',
  },
  '25026': {
    zip: '25026',
    city: 'Blue Creek',
    state: 'WV',
    latitude: '38.439374',
    longitude: '-81.460289',
    timeZoneId: 'America/New_York',
  },
  '25028': {
    zip: '25028',
    city: 'Bob White',
    state: 'WV',
    latitude: '37.95874',
    longitude: '-81.727818',
    timeZoneId: 'America/New_York',
  },
  '25030': {
    zip: '25030',
    city: 'Bomont',
    state: 'WV',
    latitude: '38.4079',
    longitude: '-81.213881',
    timeZoneId: 'America/New_York',
  },
  '25031': {
    zip: '25031',
    city: 'Boomer',
    state: 'WV',
    latitude: '38.151292',
    longitude: '-81.286034',
    timeZoneId: 'America/New_York',
  },
  '25033': {
    zip: '25033',
    city: 'Buffalo',
    state: 'WV',
    latitude: '38.605804',
    longitude: '-81.913618',
    timeZoneId: 'America/New_York',
  },
  '25035': {
    zip: '25035',
    city: 'Cabin Creek',
    state: 'WV',
    latitude: '38.195408',
    longitude: '-81.48671',
    timeZoneId: 'America/New_York',
  },
  '25036': {
    zip: '25036',
    city: 'Cannelton',
    state: 'WV',
    latitude: '38.196778',
    longitude: '-81.296157',
    timeZoneId: 'America/New_York',
  },
  '25039': {
    zip: '25039',
    city: 'Cedar Grove',
    state: 'WV',
    latitude: '38.221119',
    longitude: '-81.431623',
    timeZoneId: 'America/New_York',
  },
  '25040': {
    zip: '25040',
    city: 'Charlton Heights',
    state: 'WV',
    latitude: '38.124629',
    longitude: '-81.235798',
    timeZoneId: 'America/New_York',
  },
  '25043': {
    zip: '25043',
    city: 'Clay',
    state: 'WV',
    latitude: '38.455543',
    longitude: '-81.045457',
    timeZoneId: 'America/New_York',
  },
  '25044': {
    zip: '25044',
    city: 'Clear Creek',
    state: 'WV',
    latitude: '37.896461',
    longitude: '-81.321466',
    timeZoneId: 'America/New_York',
  },
  '25045': {
    zip: '25045',
    city: 'Clendenin',
    state: 'WV',
    latitude: '38.46048',
    longitude: '-81.33552',
    timeZoneId: 'America/New_York',
  },
  '25047': {
    zip: '25047',
    city: 'Clothier',
    state: 'WV',
    latitude: '37.790206',
    longitude: '-81.99146',
    timeZoneId: 'America/New_York',
  },
  '25048': {
    zip: '25048',
    city: 'Colcord',
    state: 'WV',
    latitude: '37.936091',
    longitude: '-81.458341',
    timeZoneId: 'America/New_York',
  },
  '25049': {
    zip: '25049',
    city: 'Comfort',
    state: 'WV',
    latitude: '38.124386',
    longitude: '-81.572157',
    timeZoneId: 'America/New_York',
  },
  '25051': {
    zip: '25051',
    city: 'Costa',
    state: 'WV',
    latitude: '38.15182',
    longitude: '-81.713319',
    timeZoneId: 'America/New_York',
  },
  '25053': {
    zip: '25053',
    city: 'Danville',
    state: 'WV',
    latitude: '38.000074',
    longitude: '-81.865118',
    timeZoneId: 'America/New_York',
  },
  '25054': {
    zip: '25054',
    city: 'Dawes',
    state: 'WV',
    latitude: '38.144612',
    longitude: '-81.449858',
    timeZoneId: 'America/New_York',
  },
  '25057': {
    zip: '25057',
    city: 'Deep Water',
    state: 'WV',
    latitude: '38.121436',
    longitude: '-81.25932',
    timeZoneId: 'America/New_York',
  },
  '25059': {
    zip: '25059',
    city: 'Dixie',
    state: 'WV',
    latitude: '38.263961',
    longitude: '-81.183718',
    timeZoneId: 'America/New_York',
  },
  '25060': {
    zip: '25060',
    city: 'Dorothy',
    state: 'WV',
    latitude: '37.967841',
    longitude: '-81.495097',
    timeZoneId: 'America/New_York',
  },
  '25061': {
    zip: '25061',
    city: 'Drybranch',
    state: 'WV',
    latitude: '38.177371',
    longitude: '-81.469407',
    timeZoneId: 'America/New_York',
  },
  '25062': {
    zip: '25062',
    city: 'Dry Creek',
    state: 'WV',
    latitude: '37.872468',
    longitude: '-81.42884',
    timeZoneId: 'America/New_York',
  },
  '25063': {
    zip: '25063',
    city: 'Duck',
    state: 'WV',
    latitude: '38.567158',
    longitude: '-80.975873',
    timeZoneId: 'America/New_York',
  },
  '25064': {
    zip: '25064',
    city: 'Dunbar',
    state: 'WV',
    latitude: '38.365209',
    longitude: '-81.740204',
    timeZoneId: 'America/New_York',
  },
  '25067': {
    zip: '25067',
    city: 'East Bank',
    state: 'WV',
    latitude: '38.22063',
    longitude: '-81.441267',
    timeZoneId: 'America/New_York',
  },
  '25070': {
    zip: '25070',
    city: 'Eleanor',
    state: 'WV',
    latitude: '38.537903',
    longitude: '-81.928171',
    timeZoneId: 'America/New_York',
  },
  '25071': {
    zip: '25071',
    city: 'Elkview',
    state: 'WV',
    latitude: '38.4555',
    longitude: '-81.46914',
    timeZoneId: 'America/New_York',
  },
  '25075': {
    zip: '25075',
    city: 'Eskdale',
    state: 'WV',
    latitude: '38.128378',
    longitude: '-81.458564',
    timeZoneId: 'America/New_York',
  },
  '25076': {
    zip: '25076',
    city: 'Ethel',
    state: 'WV',
    latitude: '37.864393',
    longitude: '-81.913068',
    timeZoneId: 'America/New_York',
  },
  '25079': {
    zip: '25079',
    city: 'Falling Rock',
    state: 'WV',
    latitude: '38.475124',
    longitude: '-81.388946',
    timeZoneId: 'America/New_York',
  },
  '25081': {
    zip: '25081',
    city: 'Foster',
    state: 'WV',
    latitude: '38.092895',
    longitude: '-81.772967',
    timeZoneId: 'America/New_York',
  },
  '25082': {
    zip: '25082',
    city: 'Fraziers Bottom',
    state: 'WV',
    latitude: '38.568197',
    longitude: '-82.025774',
    timeZoneId: 'America/New_York',
  },
  '25083': {
    zip: '25083',
    city: 'Gallagher',
    state: 'WV',
    latitude: '38.137478',
    longitude: '-81.383508',
    timeZoneId: 'America/New_York',
  },
  '25085': {
    zip: '25085',
    city: 'Gauley Bridge',
    state: 'WV',
    latitude: '38.167246',
    longitude: '-81.190813',
    timeZoneId: 'America/New_York',
  },
  '25086': {
    zip: '25086',
    city: 'Glasgow',
    state: 'WV',
    latitude: '38.208152',
    longitude: '-81.419467',
    timeZoneId: 'America/New_York',
  },
  '25088': {
    zip: '25088',
    city: 'Glen',
    state: 'WV',
    latitude: '38.355355',
    longitude: '-81.230358',
    timeZoneId: 'America/New_York',
  },
  '25090': {
    zip: '25090',
    city: 'Glen Ferris',
    state: 'WV',
    latitude: '38.15285',
    longitude: '-81.213882',
    timeZoneId: 'America/New_York',
  },
  '25093': {
    zip: '25093',
    city: 'Gordon',
    state: 'WV',
    latitude: '37.993714',
    longitude: '-81.68132',
    timeZoneId: 'America/New_York',
  },
  '25102': {
    zip: '25102',
    city: 'Handley',
    state: 'WV',
    latitude: '38.183415',
    longitude: '-81.365717',
    timeZoneId: 'America/New_York',
  },
  '25103': {
    zip: '25103',
    city: 'Hansford',
    state: 'WV',
    latitude: '38.113553',
    longitude: '-81.377213',
    timeZoneId: 'America/New_York',
  },
  '25106': {
    zip: '25106',
    city: 'Henderson',
    state: 'WV',
    latitude: '38.8356',
    longitude: '-82.139177',
    timeZoneId: 'America/New_York',
  },
  '25107': {
    zip: '25107',
    city: 'Hernshaw',
    state: 'WV',
    latitude: '38.203334',
    longitude: '-81.612435',
    timeZoneId: 'America/New_York',
  },
  '25108': {
    zip: '25108',
    city: 'Hewett',
    state: 'WV',
    latitude: '37.960781',
    longitude: '-81.852506',
    timeZoneId: 'America/New_York',
  },
  '25109': {
    zip: '25109',
    city: 'Hometown',
    state: 'WV',
    latitude: '38.53317',
    longitude: '-81.862742',
    timeZoneId: 'America/New_York',
  },
  '25110': {
    zip: '25110',
    city: 'Hugheston',
    state: 'WV',
    latitude: '38.210093',
    longitude: '-81.374878',
    timeZoneId: 'America/New_York',
  },
  '25111': {
    zip: '25111',
    city: 'Indore',
    state: 'WV',
    latitude: '38.360834',
    longitude: '-81.16814',
    timeZoneId: 'America/New_York',
  },
  '25112': {
    zip: '25112',
    city: 'Institute',
    state: 'WV',
    latitude: '38.377795',
    longitude: '-81.762433',
    timeZoneId: 'America/New_York',
  },
  '25113': {
    zip: '25113',
    city: 'Ivydale',
    state: 'WV',
    latitude: '38.5296',
    longitude: '-81.03288',
    timeZoneId: 'America/New_York',
  },
  '25114': {
    zip: '25114',
    city: 'Jeffrey',
    state: 'WV',
    latitude: '37.9599',
    longitude: '-81.81198',
    timeZoneId: 'America/New_York',
  },
  '25115': {
    zip: '25115',
    city: 'Kanawha Falls',
    state: 'WV',
    latitude: '38.115592',
    longitude: '-81.158077',
    timeZoneId: 'America/New_York',
  },
  '25118': {
    zip: '25118',
    city: 'Kimberly',
    state: 'WV',
    latitude: '38.124236',
    longitude: '-81.324504',
    timeZoneId: 'America/New_York',
  },
  '25119': {
    zip: '25119',
    city: 'Kincaid',
    state: 'WV',
    latitude: '38.031523',
    longitude: '-81.263388',
    timeZoneId: 'America/New_York',
  },
  '25121': {
    zip: '25121',
    city: 'Lake',
    state: 'WV',
    latitude: '37.908488',
    longitude: '-81.920919',
    timeZoneId: 'America/New_York',
  },
  '25123': {
    zip: '25123',
    city: 'Leon',
    state: 'WV',
    latitude: '38.74652',
    longitude: '-81.95593',
    timeZoneId: 'America/New_York',
  },
  '25124': {
    zip: '25124',
    city: 'Liberty',
    state: 'WV',
    latitude: '38.629289',
    longitude: '-81.767821',
    timeZoneId: 'America/New_York',
  },
  '25125': {
    zip: '25125',
    city: 'Lizemores',
    state: 'WV',
    latitude: '38.337121',
    longitude: '-81.164391',
    timeZoneId: 'America/New_York',
  },
  '25126': {
    zip: '25126',
    city: 'London',
    state: 'WV',
    latitude: '38.188115',
    longitude: '-81.347686',
    timeZoneId: 'America/New_York',
  },
  '25130': {
    zip: '25130',
    city: 'Madison',
    state: 'WV',
    latitude: '38.0612',
    longitude: '-81.820332',
    timeZoneId: 'America/New_York',
  },
  '25132': {
    zip: '25132',
    city: 'Mammoth',
    state: 'WV',
    latitude: '38.255724',
    longitude: '-81.37428',
    timeZoneId: 'America/New_York',
  },
  '25133': {
    zip: '25133',
    city: 'Maysel',
    state: 'WV',
    latitude: '38.483821',
    longitude: '-81.127172',
    timeZoneId: 'America/New_York',
  },
  '25134': {
    zip: '25134',
    city: 'Miami',
    state: 'WV',
    latitude: '38.15042',
    longitude: '-81.448615',
    timeZoneId: 'America/New_York',
  },
  '25136': {
    zip: '25136',
    city: 'Montgomery',
    state: 'WV',
    latitude: '38.178508',
    longitude: '-81.321429',
    timeZoneId: 'America/New_York',
  },
  '25139': {
    zip: '25139',
    city: 'Mount Carbon',
    state: 'WV',
    latitude: '38.151124',
    longitude: '-81.326901',
    timeZoneId: 'America/New_York',
  },
  '25140': {
    zip: '25140',
    city: 'Naoma',
    state: 'WV',
    latitude: '37.897184',
    longitude: '-81.525084',
    timeZoneId: 'America/New_York',
  },
  '25141': {
    zip: '25141',
    city: 'Nebo',
    state: 'WV',
    latitude: '38.626014',
    longitude: '-81.026996',
    timeZoneId: 'America/New_York',
  },
  '25142': {
    zip: '25142',
    city: 'Nellis',
    state: 'WV',
    latitude: '38.15754',
    longitude: '-81.729976',
    timeZoneId: 'America/New_York',
  },
  '25143': {
    zip: '25143',
    city: 'Nitro',
    state: 'WV',
    latitude: '38.410942',
    longitude: '-81.828664',
    timeZoneId: 'America/New_York',
  },
  '25148': {
    zip: '25148',
    city: 'Orgas',
    state: 'WV',
    latitude: '38.061808',
    longitude: '-81.566835',
    timeZoneId: 'America/New_York',
  },
  '25149': {
    zip: '25149',
    city: 'Ottawa',
    state: 'WV',
    latitude: '37.959696',
    longitude: '-81.817975',
    timeZoneId: 'America/New_York',
  },
  '25152': {
    zip: '25152',
    city: 'Page',
    state: 'WV',
    latitude: '38.052053',
    longitude: '-81.270363',
    timeZoneId: 'America/New_York',
  },
  '25154': {
    zip: '25154',
    city: 'Peytona',
    state: 'WV',
    latitude: '38.121524',
    longitude: '-81.705556',
    timeZoneId: 'America/New_York',
  },
  '25156': {
    zip: '25156',
    city: 'Pinch',
    state: 'WV',
    latitude: '38.408696',
    longitude: '-81.481169',
    timeZoneId: 'America/New_York',
  },
  '25159': {
    zip: '25159',
    city: 'Poca',
    state: 'WV',
    latitude: '38.46864',
    longitude: '-81.7821',
    timeZoneId: 'America/New_York',
  },
  '25160': {
    zip: '25160',
    city: 'Pond Gap',
    state: 'WV',
    latitude: '38.284577',
    longitude: '-81.28585',
    timeZoneId: 'America/New_York',
  },
  '25161': {
    zip: '25161',
    city: 'Powellton',
    state: 'WV',
    latitude: '38.097121',
    longitude: '-81.323113',
    timeZoneId: 'America/New_York',
  },
  '25162': {
    zip: '25162',
    city: 'Pratt',
    state: 'WV',
    latitude: '38.211569',
    longitude: '-81.387264',
    timeZoneId: 'America/New_York',
  },
  '25164': {
    zip: '25164',
    city: 'Procious',
    state: 'WV',
    latitude: '38.470869',
    longitude: '-81.222751',
    timeZoneId: 'America/New_York',
  },
  '25165': {
    zip: '25165',
    city: 'Racine',
    state: 'WV',
    latitude: '38.159981',
    longitude: '-81.657018',
    timeZoneId: 'America/New_York',
  },
  '25168': {
    zip: '25168',
    city: 'Red House',
    state: 'WV',
    latitude: '38.558202',
    longitude: '-81.895822',
    timeZoneId: 'America/New_York',
  },
  '25169': {
    zip: '25169',
    city: 'Ridgeview',
    state: 'WV',
    latitude: '38.157778',
    longitude: '-81.787522',
    timeZoneId: 'America/New_York',
  },
  '25173': {
    zip: '25173',
    city: 'Robson',
    state: 'WV',
    latitude: '38.099577',
    longitude: '-81.24775',
    timeZoneId: 'America/New_York',
  },
  '25174': {
    zip: '25174',
    city: 'Rock Creek',
    state: 'WV',
    latitude: '37.838718',
    longitude: '-81.487447',
    timeZoneId: 'America/New_York',
  },
  '25177': {
    zip: '25177',
    city: 'Saint Albans',
    state: 'WV',
    latitude: '38.381638',
    longitude: '-81.823224',
    timeZoneId: 'America/New_York',
  },
  '25180': {
    zip: '25180',
    city: 'Saxon',
    state: 'WV',
    latitude: '37.782912',
    longitude: '-81.431537',
    timeZoneId: 'America/New_York',
  },
  '25181': {
    zip: '25181',
    city: 'Seth',
    state: 'WV',
    latitude: '38.08968',
    longitude: '-81.643862',
    timeZoneId: 'America/New_York',
  },
  '25183': {
    zip: '25183',
    city: 'Sharples',
    state: 'WV',
    latitude: '37.918049',
    longitude: '-81.827781',
    timeZoneId: 'America/New_York',
  },
  '25185': {
    zip: '25185',
    city: 'Mount Olive',
    state: 'WV',
    latitude: '38.240034',
    longitude: '-81.233861',
    timeZoneId: 'America/New_York',
  },
  '25186': {
    zip: '25186',
    city: 'Smithers',
    state: 'WV',
    latitude: '38.181843',
    longitude: '-81.308979',
    timeZoneId: 'America/New_York',
  },
  '25187': {
    zip: '25187',
    city: 'Southside',
    state: 'WV',
    latitude: '38.712584',
    longitude: '-82.011011',
    timeZoneId: 'America/New_York',
  },
  '25193': {
    zip: '25193',
    city: 'Sylvester',
    state: 'WV',
    latitude: '38.014457',
    longitude: '-81.534634',
    timeZoneId: 'America/New_York',
  },
  '25201': {
    zip: '25201',
    city: 'Tad',
    state: 'WV',
    latitude: '38.332052',
    longitude: '-81.495124',
    timeZoneId: 'America/New_York',
  },
  '25202': {
    zip: '25202',
    city: 'Tornado',
    state: 'WV',
    latitude: '38.340295',
    longitude: '-81.846193',
    timeZoneId: 'America/New_York',
  },
  '25203': {
    zip: '25203',
    city: 'Turtle Creek',
    state: 'WV',
    latitude: '38.044085',
    longitude: '-81.862302',
    timeZoneId: 'America/New_York',
  },
  '25204': {
    zip: '25204',
    city: 'Twilight',
    state: 'WV',
    latitude: '37.940556',
    longitude: '-81.64',
    timeZoneId: 'America/New_York',
  },
  '25205': {
    zip: '25205',
    city: 'Uneeda',
    state: 'WV',
    latitude: '38.035397',
    longitude: '-81.780288',
    timeZoneId: 'America/New_York',
  },
  '25206': {
    zip: '25206',
    city: 'Van',
    state: 'WV',
    latitude: '37.95599',
    longitude: '-81.693339',
    timeZoneId: 'America/New_York',
  },
  '25208': {
    zip: '25208',
    city: 'Wharton',
    state: 'WV',
    latitude: '37.903787',
    longitude: '-81.676067',
    timeZoneId: 'America/New_York',
  },
  '25209': {
    zip: '25209',
    city: 'Whitesville',
    state: 'WV',
    latitude: '37.970938',
    longitude: '-81.529874',
    timeZoneId: 'America/New_York',
  },
  '25211': {
    zip: '25211',
    city: 'Widen',
    state: 'WV',
    latitude: '38.464696',
    longitude: '-80.857277',
    timeZoneId: 'America/New_York',
  },
  '25213': {
    zip: '25213',
    city: 'Winfield',
    state: 'WV',
    latitude: '38.499702',
    longitude: '-81.8795',
    timeZoneId: 'America/New_York',
  },
  '25214': {
    zip: '25214',
    city: 'Winifrede',
    state: 'WV',
    latitude: '38.165043',
    longitude: '-81.560846',
    timeZoneId: 'America/New_York',
  },
  '25231': {
    zip: '25231',
    city: 'Advent',
    state: 'WV',
    latitude: '38.626641',
    longitude: '-81.566981',
    timeZoneId: 'America/New_York',
  },
  '25234': {
    zip: '25234',
    city: 'Arnoldsburg',
    state: 'WV',
    latitude: '38.83458',
    longitude: '-81.172662',
    timeZoneId: 'America/New_York',
  },
  '25235': {
    zip: '25235',
    city: 'Chloe',
    state: 'WV',
    latitude: '38.677705',
    longitude: '-81.093441',
    timeZoneId: 'America/New_York',
  },
  '25239': {
    zip: '25239',
    city: 'Cottageville',
    state: 'WV',
    latitude: '38.856119',
    longitude: '-81.815753',
    timeZoneId: 'America/New_York',
  },
  '25241': {
    zip: '25241',
    city: 'Evans',
    state: 'WV',
    latitude: '38.80648',
    longitude: '-81.797745',
    timeZoneId: 'America/New_York',
  },
  '25243': {
    zip: '25243',
    city: 'Gandeeville',
    state: 'WV',
    latitude: '38.70648',
    longitude: '-81.426',
    timeZoneId: 'America/New_York',
  },
  '25244': {
    zip: '25244',
    city: 'Gay',
    state: 'WV',
    latitude: '38.797281',
    longitude: '-81.573705',
    timeZoneId: 'America/New_York',
  },
  '25245': {
    zip: '25245',
    city: 'Given',
    state: 'WV',
    latitude: '38.680155',
    longitude: '-81.685128',
    timeZoneId: 'America/New_York',
  },
  '25247': {
    zip: '25247',
    city: 'Hartford',
    state: 'WV',
    latitude: '39.000627',
    longitude: '-81.97856',
    timeZoneId: 'America/New_York',
  },
  '25248': {
    zip: '25248',
    city: 'Kenna',
    state: 'WV',
    latitude: '38.655556',
    longitude: '-81.590556',
    timeZoneId: 'America/New_York',
  },
  '25251': {
    zip: '25251',
    city: 'Left Hand',
    state: 'WV',
    latitude: '38.611825',
    longitude: '-81.246538',
    timeZoneId: 'America/New_York',
  },
  '25252': {
    zip: '25252',
    city: 'Le Roy',
    state: 'WV',
    latitude: '38.957697',
    longitude: '-81.571231',
    timeZoneId: 'America/New_York',
  },
  '25253': {
    zip: '25253',
    city: 'Letart',
    state: 'WV',
    latitude: '38.98857',
    longitude: '-81.952381',
    timeZoneId: 'America/New_York',
  },
  '25259': {
    zip: '25259',
    city: 'Looneyville',
    state: 'WV',
    latitude: '38.66579',
    longitude: '-81.296596',
    timeZoneId: 'America/New_York',
  },
  '25260': {
    zip: '25260',
    city: 'Mason',
    state: 'WV',
    latitude: '39.017584',
    longitude: '-82.027784',
    timeZoneId: 'America/New_York',
  },
  '25261': {
    zip: '25261',
    city: 'Millstone',
    state: 'WV',
    latitude: '38.854513',
    longitude: '-81.077711',
    timeZoneId: 'America/New_York',
  },
  '25262': {
    zip: '25262',
    city: 'Millwood',
    state: 'WV',
    latitude: '38.883965',
    longitude: '-81.861594',
    timeZoneId: 'America/New_York',
  },
  '25264': {
    zip: '25264',
    city: 'Mount Alto',
    state: 'WV',
    latitude: '38.865477',
    longitude: '-81.88055',
    timeZoneId: 'America/New_York',
  },
  '25265': {
    zip: '25265',
    city: 'New Haven',
    state: 'WV',
    latitude: '38.985473',
    longitude: '-81.959404',
    timeZoneId: 'America/New_York',
  },
  '25266': {
    zip: '25266',
    city: 'Newton',
    state: 'WV',
    latitude: '38.617778',
    longitude: '-81.143333',
    timeZoneId: 'America/New_York',
  },
  '25267': {
    zip: '25267',
    city: 'Normantown',
    state: 'WV',
    latitude: '38.891624',
    longitude: '-80.957193',
    timeZoneId: 'America/New_York',
  },
  '25268': {
    zip: '25268',
    city: 'Orma',
    state: 'WV',
    latitude: '38.709444',
    longitude: '-81.098333',
    timeZoneId: 'America/New_York',
  },
  '25270': {
    zip: '25270',
    city: 'Reedy',
    state: 'WV',
    latitude: '38.844309',
    longitude: '-81.448115',
    timeZoneId: 'America/New_York',
  },
  '25271': {
    zip: '25271',
    city: 'Ripley',
    state: 'WV',
    latitude: '38.814793',
    longitude: '-81.707302',
    timeZoneId: 'America/New_York',
  },
  '25275': {
    zip: '25275',
    city: 'Sandyville',
    state: 'WV',
    latitude: '38.903607',
    longitude: '-81.639896',
    timeZoneId: 'America/New_York',
  },
  '25276': {
    zip: '25276',
    city: 'Spencer',
    state: 'WV',
    latitude: '38.79845',
    longitude: '-81.353076',
    timeZoneId: 'America/New_York',
  },
  '25285': {
    zip: '25285',
    city: 'Wallback',
    state: 'WV',
    latitude: '38.580248',
    longitude: '-81.087658',
    timeZoneId: 'America/New_York',
  },
  '25286': {
    zip: '25286',
    city: 'Walton',
    state: 'WV',
    latitude: '38.577042',
    longitude: '-81.419744',
    timeZoneId: 'America/New_York',
  },
  '25287': {
    zip: '25287',
    city: 'West Columbia',
    state: 'WV',
    latitude: '38.930914',
    longitude: '-82.072593',
    timeZoneId: 'America/New_York',
  },
  '25301': {
    zip: '25301',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.348843',
    longitude: '-81.631272',
    timeZoneId: 'America/New_York',
  },
  '25302': {
    zip: '25302',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.3862',
    longitude: '-81.61746',
    timeZoneId: 'America/New_York',
  },
  '25303': {
    zip: '25303',
    city: 'South Charleston',
    state: 'WV',
    latitude: '38.35266',
    longitude: '-81.6585',
    timeZoneId: 'America/New_York',
  },
  '25304': {
    zip: '25304',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.320517',
    longitude: '-81.591144',
    timeZoneId: 'America/New_York',
  },
  '25305': {
    zip: '25305',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.357935',
    longitude: '-81.57081',
    timeZoneId: 'America/New_York',
  },
  '25306': {
    zip: '25306',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.30502',
    longitude: '-81.53046',
    timeZoneId: 'America/New_York',
  },
  '25309': {
    zip: '25309',
    city: 'South Charleston',
    state: 'WV',
    latitude: '38.33916',
    longitude: '-81.75348',
    timeZoneId: 'America/New_York',
  },
  '25311': {
    zip: '25311',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.351903',
    longitude: '-81.592292',
    timeZoneId: 'America/New_York',
  },
  '25312': {
    zip: '25312',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.386908',
    longitude: '-81.665551',
    timeZoneId: 'America/New_York',
  },
  '25313': {
    zip: '25313',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.41038',
    longitude: '-81.75372',
    timeZoneId: 'America/New_York',
  },
  '25314': {
    zip: '25314',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.337622',
    longitude: '-81.659235',
    timeZoneId: 'America/New_York',
  },
  '25315': {
    zip: '25315',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.235874',
    longitude: '-81.555983',
    timeZoneId: 'America/New_York',
  },
  '25317': {
    zip: '25317',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.360057',
    longitude: '-81.570732',
    timeZoneId: 'America/New_York',
  },
  '25320': {
    zip: '25320',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.5269',
    longitude: '-81.63912',
    timeZoneId: 'America/New_York',
  },
  '25321': {
    zip: '25321',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.350847',
    longitude: '-81.630639',
    timeZoneId: 'America/New_York',
  },
  '25322': {
    zip: '25322',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.348921',
    longitude: '-81.630374',
    timeZoneId: 'America/New_York',
  },
  '25323': {
    zip: '25323',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.352773',
    longitude: '-81.631772',
    timeZoneId: 'America/New_York',
  },
  '25324': {
    zip: '25324',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.351904',
    longitude: '-81.630152',
    timeZoneId: 'America/New_York',
  },
  '25325': {
    zip: '25325',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.354475',
    longitude: '-81.627292',
    timeZoneId: 'America/New_York',
  },
  '25326': {
    zip: '25326',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.333129',
    longitude: '-81.598412',
    timeZoneId: 'America/New_York',
  },
  '25327': {
    zip: '25327',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.353108',
    longitude: '-81.632439',
    timeZoneId: 'America/New_York',
  },
  '25328': {
    zip: '25328',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.350365',
    longitude: '-81.629816',
    timeZoneId: 'America/New_York',
  },
  '25329': {
    zip: '25329',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.351228',
    longitude: '-81.629184',
    timeZoneId: 'America/New_York',
  },
  '25330': {
    zip: '25330',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.347736',
    longitude: '-81.630516',
    timeZoneId: 'America/New_York',
  },
  '25331': {
    zip: '25331',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.350011',
    longitude: '-81.629325',
    timeZoneId: 'America/New_York',
  },
  '25332': {
    zip: '25332',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.353789',
    longitude: '-81.628091',
    timeZoneId: 'America/New_York',
  },
  '25333': {
    zip: '25333',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.352941',
    longitude: '-81.633099',
    timeZoneId: 'America/New_York',
  },
  '25334': {
    zip: '25334',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.349486',
    longitude: '-81.627305',
    timeZoneId: 'America/New_York',
  },
  '25335': {
    zip: '25335',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.349999',
    longitude: '-81.629054',
    timeZoneId: 'America/New_York',
  },
  '25336': {
    zip: '25336',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.350534',
    longitude: '-81.628213',
    timeZoneId: 'America/New_York',
  },
  '25337': {
    zip: '25337',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.348918',
    longitude: '-81.624515',
    timeZoneId: 'America/New_York',
  },
  '25338': {
    zip: '25338',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.349917',
    longitude: '-81.630449',
    timeZoneId: 'America/New_York',
  },
  '25339': {
    zip: '25339',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.351127',
    longitude: '-81.629133',
    timeZoneId: 'America/New_York',
  },
  '25350': {
    zip: '25350',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.352825',
    longitude: '-81.629965',
    timeZoneId: 'America/New_York',
  },
  '25356': {
    zip: '25356',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.446811',
    longitude: '-81.754015',
    timeZoneId: 'America/New_York',
  },
  '25357': {
    zip: '25357',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.421675',
    longitude: '-81.689725',
    timeZoneId: 'America/New_York',
  },
  '25358': {
    zip: '25358',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.33395',
    longitude: '-81.745941',
    timeZoneId: 'America/New_York',
  },
  '25360': {
    zip: '25360',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.45324',
    longitude: '-81.669481',
    timeZoneId: 'America/New_York',
  },
  '25361': {
    zip: '25361',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.363963',
    longitude: '-81.552006',
    timeZoneId: 'America/New_York',
  },
  '25362': {
    zip: '25362',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.396951',
    longitude: '-81.586751',
    timeZoneId: 'America/New_York',
  },
  '25364': {
    zip: '25364',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.322642',
    longitude: '-81.602646',
    timeZoneId: 'America/New_York',
  },
  '25365': {
    zip: '25365',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.234272',
    longitude: '-81.573751',
    timeZoneId: 'America/New_York',
  },
  '25375': {
    zip: '25375',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.349281',
    longitude: '-81.631727',
    timeZoneId: 'America/New_York',
  },
  '25387': {
    zip: '25387',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.348986',
    longitude: '-81.631166',
    timeZoneId: 'America/New_York',
  },
  '25389': {
    zip: '25389',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.368284',
    longitude: '-81.673323',
    timeZoneId: 'America/New_York',
  },
  '25392': {
    zip: '25392',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.351984',
    longitude: '-81.628434',
    timeZoneId: 'America/New_York',
  },
  '25396': {
    zip: '25396',
    city: 'Charleston',
    state: 'WV',
    latitude: '38.351027',
    longitude: '-81.629411',
    timeZoneId: 'America/New_York',
  },
  '25401': {
    zip: '25401',
    city: 'Martinsburg',
    state: 'WV',
    latitude: '39.461266',
    longitude: '-77.965102',
    timeZoneId: 'America/New_York',
  },
  '25402': {
    zip: '25402',
    city: 'Martinsburg',
    state: 'WV',
    latitude: '39.461446',
    longitude: '-77.969824',
    timeZoneId: 'America/New_York',
  },
  '25403': {
    zip: '25403',
    city: 'Martinsburg',
    state: 'WV',
    latitude: '39.461591',
    longitude: '-77.969701',
    timeZoneId: 'America/New_York',
  },
  '25404': {
    zip: '25404',
    city: 'Martinsburg',
    state: 'WV',
    latitude: '39.461628',
    longitude: '-77.969503',
    timeZoneId: 'America/New_York',
  },
  '25405': {
    zip: '25405',
    city: 'Martinsburg',
    state: 'WV',
    latitude: '39.46194',
    longitude: '-77.969187',
    timeZoneId: 'America/New_York',
  },
  '25410': {
    zip: '25410',
    city: 'Bakerton',
    state: 'WV',
    latitude: '39.326988',
    longitude: '-77.780046',
    timeZoneId: 'America/New_York',
  },
  '25411': {
    zip: '25411',
    city: 'Berkeley Springs',
    state: 'WV',
    latitude: '39.54978',
    longitude: '-78.18816',
    timeZoneId: 'America/New_York',
  },
  '25413': {
    zip: '25413',
    city: 'Bunker Hill',
    state: 'WV',
    latitude: '39.304746',
    longitude: '-78.035353',
    timeZoneId: 'America/New_York',
  },
  '25414': {
    zip: '25414',
    city: 'Charles Town',
    state: 'WV',
    latitude: '39.262652',
    longitude: '-77.857813',
    timeZoneId: 'America/New_York',
  },
  '25419': {
    zip: '25419',
    city: 'Falling Waters',
    state: 'WV',
    latitude: '39.575745',
    longitude: '-77.891093',
    timeZoneId: 'America/New_York',
  },
  '25420': {
    zip: '25420',
    city: 'Gerrardstown',
    state: 'WV',
    latitude: '39.37814',
    longitude: '-78.135408',
    timeZoneId: 'America/New_York',
  },
  '25421': {
    zip: '25421',
    city: 'Glengary',
    state: 'WV',
    latitude: '39.38382',
    longitude: '-78.15582',
    timeZoneId: 'America/New_York',
  },
  '25422': {
    zip: '25422',
    city: 'Great Cacapon',
    state: 'WV',
    latitude: '39.61612',
    longitude: '-78.390186',
    timeZoneId: 'America/New_York',
  },
  '25423': {
    zip: '25423',
    city: 'Halltown',
    state: 'WV',
    latitude: '39.314488',
    longitude: '-77.798229',
    timeZoneId: 'America/New_York',
  },
  '25425': {
    zip: '25425',
    city: 'Harpers Ferry',
    state: 'WV',
    latitude: '39.338935',
    longitude: '-77.777029',
    timeZoneId: 'America/New_York',
  },
  '25427': {
    zip: '25427',
    city: 'Hedgesville',
    state: 'WV',
    latitude: '39.54948',
    longitude: '-78.07056',
    timeZoneId: 'America/New_York',
  },
  '25428': {
    zip: '25428',
    city: 'Inwood',
    state: 'WV',
    latitude: '39.358444',
    longitude: '-78.042496',
    timeZoneId: 'America/New_York',
  },
  '25430': {
    zip: '25430',
    city: 'Kearneysville',
    state: 'WV',
    latitude: '39.341984',
    longitude: '-77.943268',
    timeZoneId: 'America/New_York',
  },
  '25431': {
    zip: '25431',
    city: 'Levels',
    state: 'WV',
    latitude: '39.48',
    longitude: '-78.55',
    timeZoneId: 'America/New_York',
  },
  '25432': {
    zip: '25432',
    city: 'Millville',
    state: 'WV',
    latitude: '39.296459',
    longitude: '-77.785255',
    timeZoneId: 'America/New_York',
  },
  '25434': {
    zip: '25434',
    city: 'Paw Paw',
    state: 'WV',
    latitude: '39.468601',
    longitude: '-78.445876',
    timeZoneId: 'America/New_York',
  },
  '25437': {
    zip: '25437',
    city: 'Points',
    state: 'WV',
    latitude: '39.450587',
    longitude: '-78.564934',
    timeZoneId: 'America/New_York',
  },
  '25438': {
    zip: '25438',
    city: 'Ranson',
    state: 'WV',
    latitude: '39.301798',
    longitude: '-77.859006',
    timeZoneId: 'America/New_York',
  },
  '25440': {
    zip: '25440',
    city: 'Ridgeway',
    state: 'WV',
    latitude: '39.298009',
    longitude: '-78.071014',
    timeZoneId: 'America/New_York',
  },
  '25441': {
    zip: '25441',
    city: 'Rippon',
    state: 'WV',
    latitude: '39.228204',
    longitude: '-77.922225',
    timeZoneId: 'America/New_York',
  },
  '25442': {
    zip: '25442',
    city: 'Shenandoah Junction',
    state: 'WV',
    latitude: '39.359284',
    longitude: '-77.838178',
    timeZoneId: 'America/New_York',
  },
  '25443': {
    zip: '25443',
    city: 'Shepherdstown',
    state: 'WV',
    latitude: '39.427212',
    longitude: '-77.814316',
    timeZoneId: 'America/New_York',
  },
  '25444': {
    zip: '25444',
    city: 'Slanesville',
    state: 'WV',
    latitude: '39.397483',
    longitude: '-78.510178',
    timeZoneId: 'America/New_York',
  },
  '25446': {
    zip: '25446',
    city: 'Summit Point',
    state: 'WV',
    latitude: '39.24139',
    longitude: '-77.970484',
    timeZoneId: 'America/New_York',
  },
  '25501': {
    zip: '25501',
    city: 'Alkol',
    state: 'WV',
    latitude: '38.169706',
    longitude: '-81.995693',
    timeZoneId: 'America/New_York',
  },
  '25502': {
    zip: '25502',
    city: 'Apple Grove',
    state: 'WV',
    latitude: '38.663298',
    longitude: '-82.129837',
    timeZoneId: 'America/New_York',
  },
  '25503': {
    zip: '25503',
    city: 'Ashton',
    state: 'WV',
    latitude: '38.596993',
    longitude: '-82.108884',
    timeZoneId: 'America/New_York',
  },
  '25504': {
    zip: '25504',
    city: 'Barboursville',
    state: 'WV',
    latitude: '38.394409',
    longitude: '-82.284654',
    timeZoneId: 'America/New_York',
  },
  '25505': {
    zip: '25505',
    city: 'Big Creek',
    state: 'WV',
    latitude: '38.032514',
    longitude: '-82.018313',
    timeZoneId: 'America/New_York',
  },
  '25506': {
    zip: '25506',
    city: 'Branchland',
    state: 'WV',
    latitude: '38.232678',
    longitude: '-82.211046',
    timeZoneId: 'America/New_York',
  },
  '25507': {
    zip: '25507',
    city: 'Ceredo',
    state: 'WV',
    latitude: '38.390929',
    longitude: '-82.557214',
    timeZoneId: 'America/New_York',
  },
  '25508': {
    zip: '25508',
    city: 'Chapmanville',
    state: 'WV',
    latitude: '37.96728',
    longitude: '-82.01622',
    timeZoneId: 'America/New_York',
  },
  '25510': {
    zip: '25510',
    city: 'Culloden',
    state: 'WV',
    latitude: '38.420264',
    longitude: '-82.071294',
    timeZoneId: 'America/New_York',
  },
  '25511': {
    zip: '25511',
    city: 'Dunlow',
    state: 'WV',
    latitude: '38.036055',
    longitude: '-82.34278',
    timeZoneId: 'America/New_York',
  },
  '25512': {
    zip: '25512',
    city: 'East Lynn',
    state: 'WV',
    latitude: '38.194258',
    longitude: '-82.327188',
    timeZoneId: 'America/New_York',
  },
  '25514': {
    zip: '25514',
    city: 'Fort Gay',
    state: 'WV',
    latitude: '38.118046',
    longitude: '-82.596913',
    timeZoneId: 'America/New_York',
  },
  '25515': {
    zip: '25515',
    city: 'Gallipolis Ferry',
    state: 'WV',
    latitude: '38.699313',
    longitude: '-82.120471',
    timeZoneId: 'America/New_York',
  },
  '25517': {
    zip: '25517',
    city: 'Genoa',
    state: 'WV',
    latitude: '38.08452',
    longitude: '-82.47558',
    timeZoneId: 'America/New_York',
  },
  '25520': {
    zip: '25520',
    city: 'Glenwood',
    state: 'WV',
    latitude: '38.591594',
    longitude: '-82.194435',
    timeZoneId: 'America/New_York',
  },
  '25521': {
    zip: '25521',
    city: 'Griffithsville',
    state: 'WV',
    latitude: '38.24653',
    longitude: '-81.989476',
    timeZoneId: 'America/New_York',
  },
  '25523': {
    zip: '25523',
    city: 'Hamlin',
    state: 'WV',
    latitude: '38.276689',
    longitude: '-82.098761',
    timeZoneId: 'America/New_York',
  },
  '25524': {
    zip: '25524',
    city: 'Harts',
    state: 'WV',
    latitude: '38.03094',
    longitude: '-82.10538',
    timeZoneId: 'America/New_York',
  },
  '25526': {
    zip: '25526',
    city: 'Hurricane',
    state: 'WV',
    latitude: '38.435356',
    longitude: '-82.002155',
    timeZoneId: 'America/New_York',
  },
  '25529': {
    zip: '25529',
    city: 'Julian',
    state: 'WV',
    latitude: '38.149998',
    longitude: '-81.862075',
    timeZoneId: 'America/New_York',
  },
  '25530': {
    zip: '25530',
    city: 'Kenova',
    state: 'WV',
    latitude: '38.383516',
    longitude: '-82.570203',
    timeZoneId: 'America/New_York',
  },
  '25534': {
    zip: '25534',
    city: 'Kiahsville',
    state: 'WV',
    latitude: '38.095556',
    longitude: '-82.254444',
    timeZoneId: 'America/New_York',
  },
  '25535': {
    zip: '25535',
    city: 'Lavalette',
    state: 'WV',
    latitude: '38.304182',
    longitude: '-82.447328',
    timeZoneId: 'America/New_York',
  },
  '25537': {
    zip: '25537',
    city: 'Lesage',
    state: 'WV',
    latitude: '38.506423',
    longitude: '-82.290767',
    timeZoneId: 'America/New_York',
  },
  '25540': {
    zip: '25540',
    city: 'Midkiff',
    state: 'WV',
    latitude: '38.140519',
    longitude: '-82.139439',
    timeZoneId: 'America/New_York',
  },
  '25541': {
    zip: '25541',
    city: 'Milton',
    state: 'WV',
    latitude: '38.438748',
    longitude: '-82.142932',
    timeZoneId: 'America/New_York',
  },
  '25544': {
    zip: '25544',
    city: 'Myra',
    state: 'WV',
    latitude: '38.228793',
    longitude: '-82.136691',
    timeZoneId: 'America/New_York',
  },
  '25545': {
    zip: '25545',
    city: 'Ona',
    state: 'WV',
    latitude: '38.43449',
    longitude: '-82.225918',
    timeZoneId: 'America/New_York',
  },
  '25547': {
    zip: '25547',
    city: 'Pecks Mill',
    state: 'WV',
    latitude: '37.93146',
    longitude: '-81.999056',
    timeZoneId: 'America/New_York',
  },
  '25550': {
    zip: '25550',
    city: 'Point Pleasant',
    state: 'WV',
    latitude: '38.860965',
    longitude: '-82.131564',
    timeZoneId: 'America/New_York',
  },
  '25555': {
    zip: '25555',
    city: 'Prichard',
    state: 'WV',
    latitude: '38.247056',
    longitude: '-82.578821',
    timeZoneId: 'America/New_York',
  },
  '25557': {
    zip: '25557',
    city: 'Ranger',
    state: 'WV',
    latitude: '38.082567',
    longitude: '-82.160452',
    timeZoneId: 'America/New_York',
  },
  '25559': {
    zip: '25559',
    city: 'Salt Rock',
    state: 'WV',
    latitude: '38.336856',
    longitude: '-82.230402',
    timeZoneId: 'America/New_York',
  },
  '25560': {
    zip: '25560',
    city: 'Scott Depot',
    state: 'WV',
    latitude: '38.453925',
    longitude: '-81.90293',
    timeZoneId: 'America/New_York',
  },
  '25562': {
    zip: '25562',
    city: 'Shoals',
    state: 'WV',
    latitude: '38.326274',
    longitude: '-82.472411',
    timeZoneId: 'America/New_York',
  },
  '25564': {
    zip: '25564',
    city: 'Sod',
    state: 'WV',
    latitude: '38.265993',
    longitude: '-81.885135',
    timeZoneId: 'America/New_York',
  },
  '25565': {
    zip: '25565',
    city: 'Spurlockville',
    state: 'WV',
    latitude: '38.077916',
    longitude: '-81.994264',
    timeZoneId: 'America/New_York',
  },
  '25567': {
    zip: '25567',
    city: 'Sumerco',
    state: 'WV',
    latitude: '38.198154',
    longitude: '-81.892436',
    timeZoneId: 'America/New_York',
  },
  '25569': {
    zip: '25569',
    city: 'Teays',
    state: 'WV',
    latitude: '38.442544',
    longitude: '-81.952025',
    timeZoneId: 'America/New_York',
  },
  '25570': {
    zip: '25570',
    city: 'Wayne',
    state: 'WV',
    latitude: '38.221065',
    longitude: '-82.447234',
    timeZoneId: 'America/New_York',
  },
  '25571': {
    zip: '25571',
    city: 'West Hamlin',
    state: 'WV',
    latitude: '38.281486',
    longitude: '-82.167017',
    timeZoneId: 'America/New_York',
  },
  '25572': {
    zip: '25572',
    city: 'Woodville',
    state: 'WV',
    latitude: '38.160737',
    longitude: '-81.893383',
    timeZoneId: 'America/New_York',
  },
  '25573': {
    zip: '25573',
    city: 'Yawkey',
    state: 'WV',
    latitude: '38.238417',
    longitude: '-81.957848',
    timeZoneId: 'America/New_York',
  },
  '25601': {
    zip: '25601',
    city: 'Logan',
    state: 'WV',
    latitude: '37.868777',
    longitude: '-81.9885',
    timeZoneId: 'America/New_York',
  },
  '25606': {
    zip: '25606',
    city: 'Accoville',
    state: 'WV',
    latitude: '37.766019',
    longitude: '-81.835111',
    timeZoneId: 'America/New_York',
  },
  '25607': {
    zip: '25607',
    city: 'Amherstdale',
    state: 'WV',
    latitude: '37.7868',
    longitude: '-81.78954',
    timeZoneId: 'America/New_York',
  },
  '25608': {
    zip: '25608',
    city: 'Baisden',
    state: 'WV',
    latitude: '37.586285',
    longitude: '-81.948951',
    timeZoneId: 'America/New_York',
  },
  '25611': {
    zip: '25611',
    city: 'Bruno',
    state: 'WV',
    latitude: '37.692032',
    longitude: '-81.869387',
    timeZoneId: 'America/New_York',
  },
  '25612': {
    zip: '25612',
    city: 'Chauncey',
    state: 'WV',
    latitude: '37.763663',
    longitude: '-81.98912',
    timeZoneId: 'America/New_York',
  },
  '25614': {
    zip: '25614',
    city: 'Cora',
    state: 'WV',
    latitude: '37.85103',
    longitude: '-81.987834',
    timeZoneId: 'America/New_York',
  },
  '25617': {
    zip: '25617',
    city: 'Davin',
    state: 'WV',
    latitude: '37.716804',
    longitude: '-81.785101',
    timeZoneId: 'America/New_York',
  },
  '25621': {
    zip: '25621',
    city: 'Gilbert',
    state: 'WV',
    latitude: '37.578836',
    longitude: '-81.874407',
    timeZoneId: 'America/New_York',
  },
  '25624': {
    zip: '25624',
    city: 'Henlawson',
    state: 'WV',
    latitude: '37.846452',
    longitude: '-81.992864',
    timeZoneId: 'America/New_York',
  },
  '25625': {
    zip: '25625',
    city: 'Holden',
    state: 'WV',
    latitude: '37.82955',
    longitude: '-82.040681',
    timeZoneId: 'America/New_York',
  },
  '25628': {
    zip: '25628',
    city: 'Kistler',
    state: 'WV',
    latitude: '37.713214',
    longitude: '-81.788081',
    timeZoneId: 'America/New_York',
  },
  '25630': {
    zip: '25630',
    city: 'Lorado',
    state: 'WV',
    latitude: '37.798699',
    longitude: '-81.716346',
    timeZoneId: 'America/New_York',
  },
  '25632': {
    zip: '25632',
    city: 'Lyburn',
    state: 'WV',
    latitude: '37.773567',
    longitude: '-81.951589',
    timeZoneId: 'America/New_York',
  },
  '25634': {
    zip: '25634',
    city: 'Mallory',
    state: 'WV',
    latitude: '37.737232',
    longitude: '-81.818685',
    timeZoneId: 'America/New_York',
  },
  '25635': {
    zip: '25635',
    city: 'Man',
    state: 'WV',
    latitude: '37.744662',
    longitude: '-81.878847',
    timeZoneId: 'America/New_York',
  },
  '25637': {
    zip: '25637',
    city: 'Mount Gay',
    state: 'WV',
    latitude: '37.838894',
    longitude: '-82.008765',
    timeZoneId: 'America/New_York',
  },
  '25638': {
    zip: '25638',
    city: 'Omar',
    state: 'WV',
    latitude: '37.7388',
    longitude: '-82.00878',
    timeZoneId: 'America/New_York',
  },
  '25639': {
    zip: '25639',
    city: 'Peach Creek',
    state: 'WV',
    latitude: '37.846903',
    longitude: '-81.992224',
    timeZoneId: 'America/New_York',
  },
  '25644': {
    zip: '25644',
    city: 'Sarah Ann',
    state: 'WV',
    latitude: '37.700463',
    longitude: '-81.958636',
    timeZoneId: 'America/New_York',
  },
  '25646': {
    zip: '25646',
    city: 'Stollings',
    state: 'WV',
    latitude: '37.825556',
    longitude: '-81.966944',
    timeZoneId: 'America/New_York',
  },
  '25647': {
    zip: '25647',
    city: 'Switzer',
    state: 'WV',
    latitude: '37.785601',
    longitude: '-81.977736',
    timeZoneId: 'America/New_York',
  },
  '25649': {
    zip: '25649',
    city: 'Verdunville',
    state: 'WV',
    latitude: '37.898738',
    longitude: '-81.991915',
    timeZoneId: 'America/New_York',
  },
  '25650': {
    zip: '25650',
    city: 'Verner',
    state: 'WV',
    latitude: '37.66',
    longitude: '-81.85',
    timeZoneId: 'America/New_York',
  },
  '25651': {
    zip: '25651',
    city: 'Wharncliffe',
    state: 'WV',
    latitude: '37.550333',
    longitude: '-81.977593',
    timeZoneId: 'America/New_York',
  },
  '25652': {
    zip: '25652',
    city: 'Whitman',
    state: 'WV',
    latitude: '37.790768',
    longitude: '-82.05087',
    timeZoneId: 'America/New_York',
  },
  '25653': {
    zip: '25653',
    city: 'Wilkinson',
    state: 'WV',
    latitude: '37.836127',
    longitude: '-82.024798',
    timeZoneId: 'America/New_York',
  },
  '25654': {
    zip: '25654',
    city: 'Yolyn',
    state: 'WV',
    latitude: '37.87092',
    longitude: '-81.8733',
    timeZoneId: 'America/New_York',
  },
  '25661': {
    zip: '25661',
    city: 'Williamson',
    state: 'WV',
    latitude: '37.673227',
    longitude: '-82.277393',
    timeZoneId: 'America/New_York',
  },
  '25665': {
    zip: '25665',
    city: 'Borderland',
    state: 'WV',
    latitude: '37.713816',
    longitude: '-82.306041',
    timeZoneId: 'America/New_York',
  },
  '25666': {
    zip: '25666',
    city: 'Breeden',
    state: 'WV',
    latitude: '37.922409',
    longitude: '-82.269737',
    timeZoneId: 'America/New_York',
  },
  '25667': {
    zip: '25667',
    city: 'Chattaroy',
    state: 'WV',
    latitude: '37.705606',
    longitude: '-82.267781',
    timeZoneId: 'America/New_York',
  },
  '25669': {
    zip: '25669',
    city: 'Crum',
    state: 'WV',
    latitude: '37.938952',
    longitude: '-82.443431',
    timeZoneId: 'America/New_York',
  },
  '25670': {
    zip: '25670',
    city: 'Delbarton',
    state: 'WV',
    latitude: '37.726001',
    longitude: '-82.126879',
    timeZoneId: 'America/New_York',
  },
  '25671': {
    zip: '25671',
    city: 'Dingess',
    state: 'WV',
    latitude: '37.881209',
    longitude: '-82.19046',
    timeZoneId: 'America/New_York',
  },
  '25672': {
    zip: '25672',
    city: 'Edgarton',
    state: 'WV',
    latitude: '37.578967',
    longitude: '-82.106525',
    timeZoneId: 'America/New_York',
  },
  '25674': {
    zip: '25674',
    city: 'Kermit',
    state: 'WV',
    latitude: '37.838915',
    longitude: '-82.407867',
    timeZoneId: 'America/New_York',
  },
  '25676': {
    zip: '25676',
    city: 'Lenore',
    state: 'WV',
    latitude: '37.80461',
    longitude: '-82.272646',
    timeZoneId: 'America/New_York',
  },
  '25678': {
    zip: '25678',
    city: 'Matewan',
    state: 'WV',
    latitude: '37.649769',
    longitude: '-82.126194',
    timeZoneId: 'America/New_York',
  },
  '25685': {
    zip: '25685',
    city: 'Naugatuck',
    state: 'WV',
    latitude: '37.806746',
    longitude: '-82.271267',
    timeZoneId: 'America/New_York',
  },
  '25686': {
    zip: '25686',
    city: 'Newtown',
    state: 'WV',
    latitude: '37.649022',
    longitude: '-82.128388',
    timeZoneId: 'America/New_York',
  },
  '25688': {
    zip: '25688',
    city: 'North Matewan',
    state: 'WV',
    latitude: '37.625847',
    longitude: '-82.149628',
    timeZoneId: 'America/New_York',
  },
  '25690': {
    zip: '25690',
    city: 'Ragland',
    state: 'WV',
    latitude: '37.701906',
    longitude: '-82.125852',
    timeZoneId: 'America/New_York',
  },
  '25691': {
    zip: '25691',
    city: 'Rawl',
    state: 'WV',
    latitude: '37.647535',
    longitude: '-82.218312',
    timeZoneId: 'America/New_York',
  },
  '25692': {
    zip: '25692',
    city: 'Red Jacket',
    state: 'WV',
    latitude: '37.647797',
    longitude: '-82.133788',
    timeZoneId: 'America/New_York',
  },
  '25696': {
    zip: '25696',
    city: 'Varney',
    state: 'WV',
    latitude: '37.680935',
    longitude: '-82.063057',
    timeZoneId: 'America/New_York',
  },
  '25699': {
    zip: '25699',
    city: 'Wilsondale',
    state: 'WV',
    latitude: '37.957334',
    longitude: '-82.36716',
    timeZoneId: 'America/New_York',
  },
  '25701': {
    zip: '25701',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.401892',
    longitude: '-82.435626',
    timeZoneId: 'America/New_York',
  },
  '25702': {
    zip: '25702',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.427845',
    longitude: '-82.370681',
    timeZoneId: 'America/New_York',
  },
  '25703': {
    zip: '25703',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.424158',
    longitude: '-82.422731',
    timeZoneId: 'America/New_York',
  },
  '25704': {
    zip: '25704',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.374908',
    longitude: '-82.512584',
    timeZoneId: 'America/New_York',
  },
  '25705': {
    zip: '25705',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.408574',
    longitude: '-82.369744',
    timeZoneId: 'America/New_York',
  },
  '25706': {
    zip: '25706',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.397383',
    longitude: '-82.448716',
    timeZoneId: 'America/New_York',
  },
  '25707': {
    zip: '25707',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.395939',
    longitude: '-82.448947',
    timeZoneId: 'America/New_York',
  },
  '25708': {
    zip: '25708',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.421876',
    longitude: '-82.441837',
    timeZoneId: 'America/New_York',
  },
  '25709': {
    zip: '25709',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.423996',
    longitude: '-82.439606',
    timeZoneId: 'America/New_York',
  },
  '25710': {
    zip: '25710',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.421544',
    longitude: '-82.442258',
    timeZoneId: 'America/New_York',
  },
  '25711': {
    zip: '25711',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.399326',
    longitude: '-82.449496',
    timeZoneId: 'America/New_York',
  },
  '25712': {
    zip: '25712',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.397513',
    longitude: '-82.448552',
    timeZoneId: 'America/New_York',
  },
  '25713': {
    zip: '25713',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.395658',
    longitude: '-82.446172',
    timeZoneId: 'America/New_York',
  },
  '25714': {
    zip: '25714',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.398121',
    longitude: '-82.450042',
    timeZoneId: 'America/New_York',
  },
  '25715': {
    zip: '25715',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.395114',
    longitude: '-82.451782',
    timeZoneId: 'America/New_York',
  },
  '25716': {
    zip: '25716',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.398288',
    longitude: '-82.448343',
    timeZoneId: 'America/New_York',
  },
  '25717': {
    zip: '25717',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.425989',
    longitude: '-82.437268',
    timeZoneId: 'America/New_York',
  },
  '25718': {
    zip: '25718',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.426697',
    longitude: '-82.438558',
    timeZoneId: 'America/New_York',
  },
  '25719': {
    zip: '25719',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.423022',
    longitude: '-82.440755',
    timeZoneId: 'America/New_York',
  },
  '25720': {
    zip: '25720',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.398656',
    longitude: '-82.448258',
    timeZoneId: 'America/New_York',
  },
  '25721': {
    zip: '25721',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.422993',
    longitude: '-82.441633',
    timeZoneId: 'America/New_York',
  },
  '25722': {
    zip: '25722',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.425562',
    longitude: '-82.442578',
    timeZoneId: 'America/New_York',
  },
  '25723': {
    zip: '25723',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.423117',
    longitude: '-82.440485',
    timeZoneId: 'America/New_York',
  },
  '25724': {
    zip: '25724',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.426357',
    longitude: '-82.44099',
    timeZoneId: 'America/New_York',
  },
  '25725': {
    zip: '25725',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.398134',
    longitude: '-82.446804',
    timeZoneId: 'America/New_York',
  },
  '25726': {
    zip: '25726',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.418723',
    longitude: '-82.474617',
    timeZoneId: 'America/New_York',
  },
  '25727': {
    zip: '25727',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.414002',
    longitude: '-82.4752',
    timeZoneId: 'America/New_York',
  },
  '25728': {
    zip: '25728',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.398675',
    longitude: '-82.451881',
    timeZoneId: 'America/New_York',
  },
  '25729': {
    zip: '25729',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.399173',
    longitude: '-82.448689',
    timeZoneId: 'America/New_York',
  },
  '25755': {
    zip: '25755',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.416466',
    longitude: '-82.44721',
    timeZoneId: 'America/New_York',
  },
  '25770': {
    zip: '25770',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.338984',
    longitude: '-82.518431',
    timeZoneId: 'America/New_York',
  },
  '25771': {
    zip: '25771',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.341767',
    longitude: '-82.517475',
    timeZoneId: 'America/New_York',
  },
  '25772': {
    zip: '25772',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.416023',
    longitude: '-82.474435',
    timeZoneId: 'America/New_York',
  },
  '25773': {
    zip: '25773',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.419532',
    longitude: '-82.478315',
    timeZoneId: 'America/New_York',
  },
  '25774': {
    zip: '25774',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.419544',
    longitude: '-82.475337',
    timeZoneId: 'America/New_York',
  },
  '25775': {
    zip: '25775',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.413131',
    longitude: '-82.473204',
    timeZoneId: 'America/New_York',
  },
  '25776': {
    zip: '25776',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.419181',
    longitude: '-82.474658',
    timeZoneId: 'America/New_York',
  },
  '25777': {
    zip: '25777',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.416149',
    longitude: '-82.477547',
    timeZoneId: 'America/New_York',
  },
  '25778': {
    zip: '25778',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.41834',
    longitude: '-82.474262',
    timeZoneId: 'America/New_York',
  },
  '25779': {
    zip: '25779',
    city: 'Huntington',
    state: 'WV',
    latitude: '38.340605',
    longitude: '-82.518153',
    timeZoneId: 'America/New_York',
  },
  '25801': {
    zip: '25801',
    city: 'Beckley',
    state: 'WV',
    latitude: '37.786941',
    longitude: '-81.191873',
    timeZoneId: 'America/New_York',
  },
  '25802': {
    zip: '25802',
    city: 'Beckley',
    state: 'WV',
    latitude: '37.793326',
    longitude: '-81.219605',
    timeZoneId: 'America/New_York',
  },
  '25810': {
    zip: '25810',
    city: 'Allen Junction',
    state: 'WV',
    latitude: '37.591606',
    longitude: '-81.349909',
    timeZoneId: 'America/New_York',
  },
  '25811': {
    zip: '25811',
    city: 'Amigo',
    state: 'WV',
    latitude: '37.598884',
    longitude: '-81.339603',
    timeZoneId: 'America/New_York',
  },
  '25812': {
    zip: '25812',
    city: 'Ansted',
    state: 'WV',
    latitude: '38.136992',
    longitude: '-81.094222',
    timeZoneId: 'America/New_York',
  },
  '25813': {
    zip: '25813',
    city: 'Beaver',
    state: 'WV',
    latitude: '37.772983',
    longitude: '-81.102442',
    timeZoneId: 'America/New_York',
  },
  '25817': {
    zip: '25817',
    city: 'Bolt',
    state: 'WV',
    latitude: '37.780267',
    longitude: '-81.413406',
    timeZoneId: 'America/New_York',
  },
  '25818': {
    zip: '25818',
    city: 'Bradley',
    state: 'WV',
    latitude: '37.860258',
    longitude: '-81.198448',
    timeZoneId: 'America/New_York',
  },
  '25820': {
    zip: '25820',
    city: 'Camp Creek',
    state: 'WV',
    latitude: '37.493487',
    longitude: '-81.114447',
    timeZoneId: 'America/New_York',
  },
  '25823': {
    zip: '25823',
    city: 'Coal City',
    state: 'WV',
    latitude: '37.666111',
    longitude: '-81.184444',
    timeZoneId: 'America/New_York',
  },
  '25825': {
    zip: '25825',
    city: 'Cool Ridge',
    state: 'WV',
    latitude: '37.646196',
    longitude: '-81.121344',
    timeZoneId: 'America/New_York',
  },
  '25826': {
    zip: '25826',
    city: 'Corinne',
    state: 'WV',
    latitude: '37.572389',
    longitude: '-81.361365',
    timeZoneId: 'America/New_York',
  },
  '25827': {
    zip: '25827',
    city: 'Crab Orchard',
    state: 'WV',
    latitude: '37.735882',
    longitude: '-81.231306',
    timeZoneId: 'America/New_York',
  },
  '25831': {
    zip: '25831',
    city: 'Danese',
    state: 'WV',
    latitude: '37.883531',
    longitude: '-80.971211',
    timeZoneId: 'America/New_York',
  },
  '25832': {
    zip: '25832',
    city: 'Daniels',
    state: 'WV',
    latitude: '37.737399',
    longitude: '-81.134572',
    timeZoneId: 'America/New_York',
  },
  '25833': {
    zip: '25833',
    city: 'Dothan',
    state: 'WV',
    latitude: '37.989553',
    longitude: '-81.212484',
    timeZoneId: 'America/New_York',
  },
  '25836': {
    zip: '25836',
    city: 'Eccles',
    state: 'WV',
    latitude: '37.782512',
    longitude: '-81.265817',
    timeZoneId: 'America/New_York',
  },
  '25837': {
    zip: '25837',
    city: 'Edmond',
    state: 'WV',
    latitude: '38.053511',
    longitude: '-81.037441',
    timeZoneId: 'America/New_York',
  },
  '25839': {
    zip: '25839',
    city: 'Fairdale',
    state: 'WV',
    latitude: '37.788127',
    longitude: '-81.389816',
    timeZoneId: 'America/New_York',
  },
  '25840': {
    zip: '25840',
    city: 'Fayetteville',
    state: 'WV',
    latitude: '38.054872',
    longitude: '-81.10049',
    timeZoneId: 'America/New_York',
  },
  '25841': {
    zip: '25841',
    city: 'Flat Top',
    state: 'WV',
    latitude: '37.545399',
    longitude: '-81.119412',
    timeZoneId: 'America/New_York',
  },
  '25843': {
    zip: '25843',
    city: 'Ghent',
    state: 'WV',
    latitude: '37.61577',
    longitude: '-81.098481',
    timeZoneId: 'America/New_York',
  },
  '25844': {
    zip: '25844',
    city: 'Glen Daniel',
    state: 'WV',
    latitude: '37.744768',
    longitude: '-81.393226',
    timeZoneId: 'America/New_York',
  },
  '25845': {
    zip: '25845',
    city: 'Glen Fork',
    state: 'WV',
    latitude: '37.653271',
    longitude: '-81.528241',
    timeZoneId: 'America/New_York',
  },
  '25846': {
    zip: '25846',
    city: 'Glen Jean',
    state: 'WV',
    latitude: '37.898425',
    longitude: '-81.161008',
    timeZoneId: 'America/New_York',
  },
  '25848': {
    zip: '25848',
    city: 'Glen Rogers',
    state: 'WV',
    latitude: '37.732688',
    longitude: '-81.441177',
    timeZoneId: 'America/New_York',
  },
  '25849': {
    zip: '25849',
    city: 'Glen White',
    state: 'WV',
    latitude: '37.729884',
    longitude: '-81.277059',
    timeZoneId: 'America/New_York',
  },
  '25851': {
    zip: '25851',
    city: 'Harper',
    state: 'WV',
    latitude: '37.797567',
    longitude: '-81.261582',
    timeZoneId: 'America/New_York',
  },
  '25853': {
    zip: '25853',
    city: 'Helen',
    state: 'WV',
    latitude: '37.635682',
    longitude: '-81.312751',
    timeZoneId: 'America/New_York',
  },
  '25854': {
    zip: '25854',
    city: 'Hico',
    state: 'WV',
    latitude: '38.112753',
    longitude: '-80.947352',
    timeZoneId: 'America/New_York',
  },
  '25855': {
    zip: '25855',
    city: 'Hilltop',
    state: 'WV',
    latitude: '37.944541',
    longitude: '-81.149775',
    timeZoneId: 'America/New_York',
  },
  '25857': {
    zip: '25857',
    city: 'Josephine',
    state: 'WV',
    latitude: '37.624937',
    longitude: '-81.212444',
    timeZoneId: 'America/New_York',
  },
  '25860': {
    zip: '25860',
    city: 'Lanark',
    state: 'WV',
    latitude: '37.827028',
    longitude: '-81.14653',
    timeZoneId: 'America/New_York',
  },
  '25862': {
    zip: '25862',
    city: 'Lansing',
    state: 'WV',
    latitude: '38.110771',
    longitude: '-81.030463',
    timeZoneId: 'America/New_York',
  },
  '25864': {
    zip: '25864',
    city: 'Layland',
    state: 'WV',
    latitude: '37.871329',
    longitude: '-80.996894',
    timeZoneId: 'America/New_York',
  },
  '25865': {
    zip: '25865',
    city: 'Lester',
    state: 'WV',
    latitude: '37.717669',
    longitude: '-81.337501',
    timeZoneId: 'America/New_York',
  },
  '25866': {
    zip: '25866',
    city: 'Lochgelly',
    state: 'WV',
    latitude: '38.013867',
    longitude: '-81.148885',
    timeZoneId: 'America/New_York',
  },
  '25868': {
    zip: '25868',
    city: 'Lookout',
    state: 'WV',
    latitude: '38.052741',
    longitude: '-80.896905',
    timeZoneId: 'America/New_York',
  },
  '25870': {
    zip: '25870',
    city: 'Maben',
    state: 'WV',
    latitude: '37.659608',
    longitude: '-81.396162',
    timeZoneId: 'America/New_York',
  },
  '25871': {
    zip: '25871',
    city: 'Mabscott',
    state: 'WV',
    latitude: '37.769428',
    longitude: '-81.208346',
    timeZoneId: 'America/New_York',
  },
  '25873': {
    zip: '25873',
    city: 'Mac Arthur',
    state: 'WV',
    latitude: '37.759129',
    longitude: '-81.208224',
    timeZoneId: 'America/New_York',
  },
  '25875': {
    zip: '25875',
    city: 'Mc Graws',
    state: 'WV',
    latitude: '37.678374',
    longitude: '-81.465791',
    timeZoneId: 'America/New_York',
  },
  '25876': {
    zip: '25876',
    city: 'Saulsville',
    state: 'WV',
    latitude: '37.633053',
    longitude: '-81.446009',
    timeZoneId: 'America/New_York',
  },
  '25878': {
    zip: '25878',
    city: 'Midway',
    state: 'WV',
    latitude: '37.717778',
    longitude: '-81.219167',
    timeZoneId: 'America/New_York',
  },
  '25879': {
    zip: '25879',
    city: 'Minden',
    state: 'WV',
    latitude: '37.977026',
    longitude: '-81.119048',
    timeZoneId: 'America/New_York',
  },
  '25880': {
    zip: '25880',
    city: 'Mount Hope',
    state: 'WV',
    latitude: '37.893074',
    longitude: '-81.167686',
    timeZoneId: 'America/New_York',
  },
  '25882': {
    zip: '25882',
    city: 'Mullens',
    state: 'WV',
    latitude: '37.584243',
    longitude: '-81.387619',
    timeZoneId: 'America/New_York',
  },
  '25888': {
    zip: '25888',
    city: 'Mount Hope',
    state: 'WV',
    latitude: '37.893074',
    longitude: '-81.167686',
    timeZoneId: 'America/New_York',
  },
  '25901': {
    zip: '25901',
    city: 'Oak Hill',
    state: 'WV',
    latitude: '37.986637',
    longitude: '-81.142428',
    timeZoneId: 'America/New_York',
  },
  '25902': {
    zip: '25902',
    city: 'Odd',
    state: 'WV',
    latitude: '37.571017',
    longitude: '-81.231729',
    timeZoneId: 'America/New_York',
  },
  '25904': {
    zip: '25904',
    city: 'Pax',
    state: 'WV',
    latitude: '37.912793',
    longitude: '-81.262664',
    timeZoneId: 'America/New_York',
  },
  '25906': {
    zip: '25906',
    city: 'Piney View',
    state: 'WV',
    latitude: '37.831764',
    longitude: '-81.121938',
    timeZoneId: 'America/New_York',
  },
  '25907': {
    zip: '25907',
    city: 'Prince',
    state: 'WV',
    latitude: '37.858917',
    longitude: '-81.051519',
    timeZoneId: 'America/New_York',
  },
  '25908': {
    zip: '25908',
    city: 'Princewick',
    state: 'WV',
    latitude: '37.656976',
    longitude: '-81.239502',
    timeZoneId: 'America/New_York',
  },
  '25909': {
    zip: '25909',
    city: 'Prosperity',
    state: 'WV',
    latitude: '37.836699',
    longitude: '-81.208506',
    timeZoneId: 'America/New_York',
  },
  '25911': {
    zip: '25911',
    city: 'Raleigh',
    state: 'WV',
    latitude: '37.75529',
    longitude: '-81.170641',
    timeZoneId: 'America/New_York',
  },
  '25913': {
    zip: '25913',
    city: 'Ravencliff',
    state: 'WV',
    latitude: '37.701741',
    longitude: '-81.478915',
    timeZoneId: 'America/New_York',
  },
  '25915': {
    zip: '25915',
    city: 'Rhodell',
    state: 'WV',
    latitude: '37.62393',
    longitude: '-81.286203',
    timeZoneId: 'America/New_York',
  },
  '25916': {
    zip: '25916',
    city: 'Sabine',
    state: 'WV',
    latitude: '37.678133',
    longitude: '-81.503162',
    timeZoneId: 'America/New_York',
  },
  '25917': {
    zip: '25917',
    city: 'Scarbro',
    state: 'WV',
    latitude: '37.96951',
    longitude: '-81.158866',
    timeZoneId: 'America/New_York',
  },
  '25918': {
    zip: '25918',
    city: 'Shady Spring',
    state: 'WV',
    latitude: '37.7046',
    longitude: '-81.102',
    timeZoneId: 'America/New_York',
  },
  '25919': {
    zip: '25919',
    city: 'Skelton',
    state: 'WV',
    latitude: '37.796073',
    longitude: '-81.182158',
    timeZoneId: 'America/New_York',
  },
  '25920': {
    zip: '25920',
    city: 'Slab Fork',
    state: 'WV',
    latitude: '37.676207',
    longitude: '-81.274295',
    timeZoneId: 'America/New_York',
  },
  '25921': {
    zip: '25921',
    city: 'Sophia',
    state: 'WV',
    latitude: '37.706351',
    longitude: '-81.256171',
    timeZoneId: 'America/New_York',
  },
  '25922': {
    zip: '25922',
    city: 'Spanishburg',
    state: 'WV',
    latitude: '37.445126',
    longitude: '-81.115031',
    timeZoneId: 'America/New_York',
  },
  '25927': {
    zip: '25927',
    city: 'Stanaford',
    state: 'WV',
    latitude: '37.817344',
    longitude: '-81.161383',
    timeZoneId: 'America/New_York',
  },
  '25928': {
    zip: '25928',
    city: 'Stephenson',
    state: 'WV',
    latitude: '37.575758',
    longitude: '-81.328798',
    timeZoneId: 'America/New_York',
  },
  '25932': {
    zip: '25932',
    city: 'Surveyor',
    state: 'WV',
    latitude: '37.738613',
    longitude: '-81.344727',
    timeZoneId: 'America/New_York',
  },
  '25936': {
    zip: '25936',
    city: 'Thurmond',
    state: 'WV',
    latitude: '37.948413',
    longitude: '-81.050036',
    timeZoneId: 'America/New_York',
  },
  '25938': {
    zip: '25938',
    city: 'Victor',
    state: 'WV',
    latitude: '38.144624',
    longitude: '-81.034034',
    timeZoneId: 'America/New_York',
  },
  '25942': {
    zip: '25942',
    city: 'Winona',
    state: 'WV',
    latitude: '38.044933',
    longitude: '-80.99416',
    timeZoneId: 'America/New_York',
  },
  '25943': {
    zip: '25943',
    city: 'Wyco',
    state: 'WV',
    latitude: '37.598955',
    longitude: '-81.343661',
    timeZoneId: 'America/New_York',
  },
  '25951': {
    zip: '25951',
    city: 'Hinton',
    state: 'WV',
    latitude: '37.667392',
    longitude: '-80.883909',
    timeZoneId: 'America/New_York',
  },
  '25958': {
    zip: '25958',
    city: 'Charmco',
    state: 'WV',
    latitude: '37.989769',
    longitude: '-80.689842',
    timeZoneId: 'America/New_York',
  },
  '25962': {
    zip: '25962',
    city: 'Rainelle',
    state: 'WV',
    latitude: '37.968083',
    longitude: '-80.768018',
    timeZoneId: 'America/New_York',
  },
  '25966': {
    zip: '25966',
    city: 'Green Sulphur Springs',
    state: 'WV',
    latitude: '37.796066',
    longitude: '-80.830326',
    timeZoneId: 'America/New_York',
  },
  '25969': {
    zip: '25969',
    city: 'Jumping Branch',
    state: 'WV',
    latitude: '37.611675',
    longitude: '-81.019615',
    timeZoneId: 'America/New_York',
  },
  '25971': {
    zip: '25971',
    city: 'Lerona',
    state: 'WV',
    latitude: '37.497942',
    longitude: '-80.992166',
    timeZoneId: 'America/New_York',
  },
  '25972': {
    zip: '25972',
    city: 'Leslie',
    state: 'WV',
    latitude: '38.040659',
    longitude: '-80.746855',
    timeZoneId: 'America/New_York',
  },
  '25976': {
    zip: '25976',
    city: 'Meadow Bridge',
    state: 'WV',
    latitude: '37.866798',
    longitude: '-80.852381',
    timeZoneId: 'America/New_York',
  },
  '25977': {
    zip: '25977',
    city: 'Meadow Creek',
    state: 'WV',
    latitude: '37.806389',
    longitude: '-80.910024',
    timeZoneId: 'America/New_York',
  },
  '25978': {
    zip: '25978',
    city: 'Nimitz',
    state: 'WV',
    latitude: '37.626395',
    longitude: '-80.93746',
    timeZoneId: 'America/New_York',
  },
  '25979': {
    zip: '25979',
    city: 'Pipestem',
    state: 'WV',
    latitude: '37.516015',
    longitude: '-80.920145',
    timeZoneId: 'America/New_York',
  },
  '25981': {
    zip: '25981',
    city: 'Quinwood',
    state: 'WV',
    latitude: '38.055556',
    longitude: '-80.691389',
    timeZoneId: 'America/New_York',
  },
  '25984': {
    zip: '25984',
    city: 'Rupert',
    state: 'WV',
    latitude: '37.96158',
    longitude: '-80.6922',
    timeZoneId: 'America/New_York',
  },
  '25985': {
    zip: '25985',
    city: 'Sandstone',
    state: 'WV',
    latitude: '37.758808',
    longitude: '-80.866671',
    timeZoneId: 'America/New_York',
  },
  '25986': {
    zip: '25986',
    city: 'Spring Dale',
    state: 'WV',
    latitude: '37.870864',
    longitude: '-80.81249',
    timeZoneId: 'America/New_York',
  },
  '25989': {
    zip: '25989',
    city: 'White Oak',
    state: 'WV',
    latitude: '37.685504',
    longitude: '-81.031173',
    timeZoneId: 'America/New_York',
  },
  '26003': {
    zip: '26003',
    city: 'Wheeling',
    state: 'WV',
    latitude: '40.069414',
    longitude: '-80.687797',
    timeZoneId: 'America/New_York',
  },
  '26030': {
    zip: '26030',
    city: 'Beech Bottom',
    state: 'WV',
    latitude: '40.223857',
    longitude: '-80.654086',
    timeZoneId: 'America/New_York',
  },
  '26031': {
    zip: '26031',
    city: 'Benwood',
    state: 'WV',
    latitude: '40.00878',
    longitude: '-80.724416',
    timeZoneId: 'America/New_York',
  },
  '26032': {
    zip: '26032',
    city: 'Bethany',
    state: 'WV',
    latitude: '40.204912',
    longitude: '-80.557309',
    timeZoneId: 'America/New_York',
  },
  '26033': {
    zip: '26033',
    city: 'Cameron',
    state: 'WV',
    latitude: '39.827348',
    longitude: '-80.567821',
    timeZoneId: 'America/New_York',
  },
  '26034': {
    zip: '26034',
    city: 'Chester',
    state: 'WV',
    latitude: '40.614822',
    longitude: '-80.562027',
    timeZoneId: 'America/New_York',
  },
  '26035': {
    zip: '26035',
    city: 'Colliers',
    state: 'WV',
    latitude: '40.366239',
    longitude: '-80.568744',
    timeZoneId: 'America/New_York',
  },
  '26036': {
    zip: '26036',
    city: 'Dallas',
    state: 'WV',
    latitude: '39.976008',
    longitude: '-80.561714',
    timeZoneId: 'America/New_York',
  },
  '26037': {
    zip: '26037',
    city: 'Follansbee',
    state: 'WV',
    latitude: '40.327418',
    longitude: '-80.583587',
    timeZoneId: 'America/New_York',
  },
  '26038': {
    zip: '26038',
    city: 'Glen Dale',
    state: 'WV',
    latitude: '39.957196',
    longitude: '-80.744214',
    timeZoneId: 'America/New_York',
  },
  '26039': {
    zip: '26039',
    city: 'Glen Easton',
    state: 'WV',
    latitude: '39.831746',
    longitude: '-80.657573',
    timeZoneId: 'America/New_York',
  },
  '26040': {
    zip: '26040',
    city: 'McMechen',
    state: 'WV',
    latitude: '39.987108',
    longitude: '-80.731381',
    timeZoneId: 'America/New_York',
  },
  '26041': {
    zip: '26041',
    city: 'Moundsville',
    state: 'WV',
    latitude: '39.925293',
    longitude: '-80.736568',
    timeZoneId: 'America/New_York',
  },
  '26047': {
    zip: '26047',
    city: 'New Cumberland',
    state: 'WV',
    latitude: '40.49305',
    longitude: '-80.59757',
    timeZoneId: 'America/New_York',
  },
  '26050': {
    zip: '26050',
    city: 'Newell',
    state: 'WV',
    latitude: '40.612215',
    longitude: '-80.600971',
    timeZoneId: 'America/New_York',
  },
  '26055': {
    zip: '26055',
    city: 'Proctor',
    state: 'WV',
    latitude: '39.720438',
    longitude: '-80.752102',
    timeZoneId: 'America/New_York',
  },
  '26056': {
    zip: '26056',
    city: 'New Manchester',
    state: 'WV',
    latitude: '40.532923',
    longitude: '-80.580463',
    timeZoneId: 'America/New_York',
  },
  '26058': {
    zip: '26058',
    city: 'Short Creek',
    state: 'WV',
    latitude: '40.184992',
    longitude: '-80.675308',
    timeZoneId: 'America/New_York',
  },
  '26059': {
    zip: '26059',
    city: 'Triadelphia',
    state: 'WV',
    latitude: '40.090092',
    longitude: '-80.620633',
    timeZoneId: 'America/New_York',
  },
  '26060': {
    zip: '26060',
    city: 'Valley Grove',
    state: 'WV',
    latitude: '40.092999',
    longitude: '-80.573503',
    timeZoneId: 'America/New_York',
  },
  '26062': {
    zip: '26062',
    city: 'Weirton',
    state: 'WV',
    latitude: '40.415665',
    longitude: '-80.56341',
    timeZoneId: 'America/New_York',
  },
  '26070': {
    zip: '26070',
    city: 'Wellsburg',
    state: 'WV',
    latitude: '40.274036',
    longitude: '-80.588162',
    timeZoneId: 'America/New_York',
  },
  '26074': {
    zip: '26074',
    city: 'West Liberty',
    state: 'WV',
    latitude: '40.168725',
    longitude: '-80.595143',
    timeZoneId: 'America/New_York',
  },
  '26075': {
    zip: '26075',
    city: 'Windsor Heights',
    state: 'WV',
    latitude: '40.191404',
    longitude: '-80.665424',
    timeZoneId: 'America/New_York',
  },
  '26101': {
    zip: '26101',
    city: 'Parkersburg',
    state: 'WV',
    latitude: '39.263058',
    longitude: '-81.53612',
    timeZoneId: 'America/New_York',
  },
  '26102': {
    zip: '26102',
    city: 'Parkersburg',
    state: 'WV',
    latitude: '39.239752',
    longitude: '-81.516397',
    timeZoneId: 'America/New_York',
  },
  '26103': {
    zip: '26103',
    city: 'Parkersburg',
    state: 'WV',
    latitude: '39.24827',
    longitude: '-81.557201',
    timeZoneId: 'America/New_York',
  },
  '26104': {
    zip: '26104',
    city: 'Parkersburg',
    state: 'WV',
    latitude: '39.286068',
    longitude: '-81.526137',
    timeZoneId: 'America/New_York',
  },
  '26105': {
    zip: '26105',
    city: 'Vienna',
    state: 'WV',
    latitude: '39.325039',
    longitude: '-81.542603',
    timeZoneId: 'America/New_York',
  },
  '26106': {
    zip: '26106',
    city: 'Parkersburg',
    state: 'WV',
    latitude: '39.324681',
    longitude: '-81.500999',
    timeZoneId: 'America/New_York',
  },
  '26120': {
    zip: '26120',
    city: 'Mineral Wells',
    state: 'WV',
    latitude: '39.168661',
    longitude: '-81.490206',
    timeZoneId: 'America/New_York',
  },
  '26121': {
    zip: '26121',
    city: 'Mineral Wells',
    state: 'WV',
    latitude: '39.172142',
    longitude: '-81.494887',
    timeZoneId: 'America/New_York',
  },
  '26133': {
    zip: '26133',
    city: 'Belleville',
    state: 'WV',
    latitude: '39.125438',
    longitude: '-81.670967',
    timeZoneId: 'America/New_York',
  },
  '26134': {
    zip: '26134',
    city: 'Belmont',
    state: 'WV',
    latitude: '39.379129',
    longitude: '-81.264578',
    timeZoneId: 'America/New_York',
  },
  '26136': {
    zip: '26136',
    city: 'Big Bend',
    state: 'WV',
    latitude: '38.987064',
    longitude: '-81.139494',
    timeZoneId: 'America/New_York',
  },
  '26137': {
    zip: '26137',
    city: 'Big Springs',
    state: 'WV',
    latitude: '39.00282',
    longitude: '-81.07398',
    timeZoneId: 'America/New_York',
  },
  '26138': {
    zip: '26138',
    city: 'Brohard',
    state: 'WV',
    latitude: '39.028842',
    longitude: '-81.193995',
    timeZoneId: 'America/New_York',
  },
  '26141': {
    zip: '26141',
    city: 'Creston',
    state: 'WV',
    latitude: '38.961534',
    longitude: '-81.257948',
    timeZoneId: 'America/New_York',
  },
  '26142': {
    zip: '26142',
    city: 'Davisville',
    state: 'WV',
    latitude: '39.204487',
    longitude: '-81.491259',
    timeZoneId: 'America/New_York',
  },
  '26143': {
    zip: '26143',
    city: 'Elizabeth',
    state: 'WV',
    latitude: '39.061962',
    longitude: '-81.399985',
    timeZoneId: 'America/New_York',
  },
  '26146': {
    zip: '26146',
    city: 'Friendly',
    state: 'WV',
    latitude: '39.49255',
    longitude: '-81.013419',
    timeZoneId: 'America/New_York',
  },
  '26147': {
    zip: '26147',
    city: 'Grantsville',
    state: 'WV',
    latitude: '38.921135',
    longitude: '-81.093194',
    timeZoneId: 'America/New_York',
  },
  '26148': {
    zip: '26148',
    city: 'Macfarlan',
    state: 'WV',
    latitude: '39.080471',
    longitude: '-81.185556',
    timeZoneId: 'America/New_York',
  },
  '26149': {
    zip: '26149',
    city: 'Middlebourne',
    state: 'WV',
    latitude: '39.5007',
    longitude: '-80.89086',
    timeZoneId: 'America/New_York',
  },
  '26150': {
    zip: '26150',
    city: 'Mineral Wells',
    state: 'WV',
    latitude: '39.192369',
    longitude: '-81.520828',
    timeZoneId: 'America/New_York',
  },
  '26151': {
    zip: '26151',
    city: 'Mount Zion',
    state: 'WV',
    latitude: '38.892132',
    longitude: '-81.127507',
    timeZoneId: 'America/New_York',
  },
  '26152': {
    zip: '26152',
    city: 'Munday',
    state: 'WV',
    latitude: '39.007664',
    longitude: '-81.229239',
    timeZoneId: 'America/New_York',
  },
  '26155': {
    zip: '26155',
    city: 'New Martinsville',
    state: 'WV',
    latitude: '39.659451',
    longitude: '-80.861572',
    timeZoneId: 'America/New_York',
  },
  '26159': {
    zip: '26159',
    city: 'Paden City',
    state: 'WV',
    latitude: '39.605411',
    longitude: '-80.929983',
    timeZoneId: 'America/New_York',
  },
  '26160': {
    zip: '26160',
    city: 'Palestine',
    state: 'WV',
    latitude: '38.966426',
    longitude: '-81.425664',
    timeZoneId: 'America/New_York',
  },
  '26161': {
    zip: '26161',
    city: 'Petroleum',
    state: 'WV',
    latitude: '39.181051',
    longitude: '-81.254818',
    timeZoneId: 'America/New_York',
  },
  '26162': {
    zip: '26162',
    city: 'Porters Falls',
    state: 'WV',
    latitude: '39.579087',
    longitude: '-80.776218',
    timeZoneId: 'America/New_York',
  },
  '26164': {
    zip: '26164',
    city: 'Ravenswood',
    state: 'WV',
    latitude: '38.95272',
    longitude: '-81.75612',
    timeZoneId: 'America/New_York',
  },
  '26167': {
    zip: '26167',
    city: 'Reader',
    state: 'WV',
    latitude: '39.578498',
    longitude: '-80.74657',
    timeZoneId: 'America/New_York',
  },
  '26169': {
    zip: '26169',
    city: 'Rockport',
    state: 'WV',
    latitude: '39.075465',
    longitude: '-81.571097',
    timeZoneId: 'America/New_York',
  },
  '26170': {
    zip: '26170',
    city: 'Saint Marys',
    state: 'WV',
    latitude: '39.394325',
    longitude: '-81.200298',
    timeZoneId: 'America/New_York',
  },
  '26175': {
    zip: '26175',
    city: 'Sistersville',
    state: 'WV',
    latitude: '39.556931',
    longitude: '-80.998026',
    timeZoneId: 'America/New_York',
  },
  '26178': {
    zip: '26178',
    city: 'Smithville',
    state: 'WV',
    latitude: '39.06776',
    longitude: '-81.069659',
    timeZoneId: 'America/New_York',
  },
  '26180': {
    zip: '26180',
    city: 'Walker',
    state: 'WV',
    latitude: '39.181974',
    longitude: '-81.367925',
    timeZoneId: 'America/New_York',
  },
  '26181': {
    zip: '26181',
    city: 'Washington',
    state: 'WV',
    latitude: '39.24426',
    longitude: '-81.639',
    timeZoneId: 'America/New_York',
  },
  '26184': {
    zip: '26184',
    city: 'Waverly',
    state: 'WV',
    latitude: '39.323032',
    longitude: '-81.422838',
    timeZoneId: 'America/New_York',
  },
  '26187': {
    zip: '26187',
    city: 'Williamstown',
    state: 'WV',
    latitude: '39.390693',
    longitude: '-81.452216',
    timeZoneId: 'America/New_York',
  },
  '26201': {
    zip: '26201',
    city: 'Buckhannon',
    state: 'WV',
    latitude: '38.983537',
    longitude: '-80.225375',
    timeZoneId: 'America/New_York',
  },
  '26202': {
    zip: '26202',
    city: 'Fenwick',
    state: 'WV',
    latitude: '38.249209',
    longitude: '-80.643415',
    timeZoneId: 'America/New_York',
  },
  '26203': {
    zip: '26203',
    city: 'Erbacon',
    state: 'WV',
    latitude: '38.543012',
    longitude: '-80.566448',
    timeZoneId: 'America/New_York',
  },
  '26205': {
    zip: '26205',
    city: 'Craigsville',
    state: 'WV',
    latitude: '38.332208',
    longitude: '-80.639092',
    timeZoneId: 'America/New_York',
  },
  '26206': {
    zip: '26206',
    city: 'Cowen',
    state: 'WV',
    latitude: '38.44206',
    longitude: '-80.5302',
    timeZoneId: 'America/New_York',
  },
  '26208': {
    zip: '26208',
    city: 'Camden on Gauley',
    state: 'WV',
    latitude: '38.37522',
    longitude: '-80.57232',
    timeZoneId: 'America/New_York',
  },
  '26209': {
    zip: '26209',
    city: 'Snowshoe',
    state: 'WV',
    latitude: '38.387939',
    longitude: '-80.064576',
    timeZoneId: 'America/New_York',
  },
  '26210': {
    zip: '26210',
    city: 'Adrian',
    state: 'WV',
    latitude: '38.917176',
    longitude: '-80.304769',
    timeZoneId: 'America/New_York',
  },
  '26215': {
    zip: '26215',
    city: 'Cleveland',
    state: 'WV',
    latitude: '38.836483',
    longitude: '-80.34',
    timeZoneId: 'America/New_York',
  },
  '26217': {
    zip: '26217',
    city: 'Diana',
    state: 'WV',
    latitude: '38.581441',
    longitude: '-80.41916',
    timeZoneId: 'America/New_York',
  },
  '26218': {
    zip: '26218',
    city: 'French Creek',
    state: 'WV',
    latitude: '38.84622',
    longitude: '-80.26998',
    timeZoneId: 'America/New_York',
  },
  '26219': {
    zip: '26219',
    city: 'Frenchton',
    state: 'WV',
    latitude: '38.838055',
    longitude: '-80.259341',
    timeZoneId: 'America/New_York',
  },
  '26222': {
    zip: '26222',
    city: 'Hacker Valley',
    state: 'WV',
    latitude: '38.700556',
    longitude: '-80.464167',
    timeZoneId: 'America/New_York',
  },
  '26224': {
    zip: '26224',
    city: 'Helvetia',
    state: 'WV',
    latitude: '38.896029',
    longitude: '-79.848585',
    timeZoneId: 'America/New_York',
  },
  '26228': {
    zip: '26228',
    city: 'Kanawha Head',
    state: 'WV',
    latitude: '38.7632',
    longitude: '-80.372307',
    timeZoneId: 'America/New_York',
  },
  '26229': {
    zip: '26229',
    city: 'Lorentz',
    state: 'WV',
    latitude: '39.009338',
    longitude: '-80.30156',
    timeZoneId: 'America/New_York',
  },
  '26230': {
    zip: '26230',
    city: 'Pickens',
    state: 'WV',
    latitude: '38.659025',
    longitude: '-80.203847',
    timeZoneId: 'America/New_York',
  },
  '26234': {
    zip: '26234',
    city: 'Rock Cave',
    state: 'WV',
    latitude: '38.769558',
    longitude: '-80.304443',
    timeZoneId: 'America/New_York',
  },
  '26236': {
    zip: '26236',
    city: 'Selbyville',
    state: 'WV',
    latitude: '38.7147',
    longitude: '-80.311151',
    timeZoneId: 'America/New_York',
  },
  '26237': {
    zip: '26237',
    city: 'Tallmansville',
    state: 'WV',
    latitude: '38.832413',
    longitude: '-80.159421',
    timeZoneId: 'America/New_York',
  },
  '26238': {
    zip: '26238',
    city: 'Volga',
    state: 'WV',
    latitude: '39.067667',
    longitude: '-80.122357',
    timeZoneId: 'America/New_York',
  },
  '26241': {
    zip: '26241',
    city: 'Elkins',
    state: 'WV',
    latitude: '38.92326',
    longitude: '-79.850503',
    timeZoneId: 'America/New_York',
  },
  '26250': {
    zip: '26250',
    city: 'Belington',
    state: 'WV',
    latitude: '39.026524',
    longitude: '-79.940877',
    timeZoneId: 'America/New_York',
  },
  '26253': {
    zip: '26253',
    city: 'Beverly',
    state: 'WV',
    latitude: '38.848735',
    longitude: '-79.871311',
    timeZoneId: 'America/New_York',
  },
  '26254': {
    zip: '26254',
    city: 'Bowden',
    state: 'WV',
    latitude: '38.893333',
    longitude: '-79.618889',
    timeZoneId: 'America/New_York',
  },
  '26257': {
    zip: '26257',
    city: 'Coalton',
    state: 'WV',
    latitude: '38.905438',
    longitude: '-80.00102',
    timeZoneId: 'America/New_York',
  },
  '26259': {
    zip: '26259',
    city: 'Dailey',
    state: 'WV',
    latitude: '38.79978',
    longitude: '-79.894427',
    timeZoneId: 'America/New_York',
  },
  '26260': {
    zip: '26260',
    city: 'Davis',
    state: 'WV',
    latitude: '39.128653',
    longitude: '-79.468131',
    timeZoneId: 'America/New_York',
  },
  '26261': {
    zip: '26261',
    city: 'Richwood',
    state: 'WV',
    latitude: '38.225157',
    longitude: '-80.53947',
    timeZoneId: 'America/New_York',
  },
  '26263': {
    zip: '26263',
    city: 'Dryfork',
    state: 'WV',
    latitude: '38.946186',
    longitude: '-79.436366',
    timeZoneId: 'America/New_York',
  },
  '26264': {
    zip: '26264',
    city: 'Durbin',
    state: 'WV',
    latitude: '38.649083',
    longitude: '-79.776011',
    timeZoneId: 'America/New_York',
  },
  '26266': {
    zip: '26266',
    city: 'Upperglade',
    state: 'WV',
    latitude: '38.432206',
    longitude: '-80.500417',
    timeZoneId: 'America/New_York',
  },
  '26267': {
    zip: '26267',
    city: 'Ellamore',
    state: 'WV',
    latitude: '38.941989',
    longitude: '-80.088341',
    timeZoneId: 'America/New_York',
  },
  '26268': {
    zip: '26268',
    city: 'Glady',
    state: 'WV',
    latitude: '38.822152',
    longitude: '-79.706701',
    timeZoneId: 'America/New_York',
  },
  '26269': {
    zip: '26269',
    city: 'Hambleton',
    state: 'WV',
    latitude: '39.126718',
    longitude: '-79.599161',
    timeZoneId: 'America/New_York',
  },
  '26270': {
    zip: '26270',
    city: 'Harman',
    state: 'WV',
    latitude: '38.914157',
    longitude: '-79.531579',
    timeZoneId: 'America/New_York',
  },
  '26271': {
    zip: '26271',
    city: 'Hendricks',
    state: 'WV',
    latitude: '39.064458',
    longitude: '-79.612063',
    timeZoneId: 'America/New_York',
  },
  '26273': {
    zip: '26273',
    city: 'Huttonsville',
    state: 'WV',
    latitude: '38.634407',
    longitude: '-79.955699',
    timeZoneId: 'America/New_York',
  },
  '26275': {
    zip: '26275',
    city: 'Junior',
    state: 'WV',
    latitude: '39.034866',
    longitude: '-79.954565',
    timeZoneId: 'America/New_York',
  },
  '26276': {
    zip: '26276',
    city: 'Kerens',
    state: 'WV',
    latitude: '39.022674',
    longitude: '-79.75122',
    timeZoneId: 'America/New_York',
  },
  '26278': {
    zip: '26278',
    city: 'Mabie',
    state: 'WV',
    latitude: '38.81484',
    longitude: '-80.031844',
    timeZoneId: 'America/New_York',
  },
  '26280': {
    zip: '26280',
    city: 'Mill Creek',
    state: 'WV',
    latitude: '38.727962',
    longitude: '-80.026616',
    timeZoneId: 'America/New_York',
  },
  '26282': {
    zip: '26282',
    city: 'Monterville',
    state: 'WV',
    latitude: '38.512528',
    longitude: '-80.162591',
    timeZoneId: 'America/New_York',
  },
  '26283': {
    zip: '26283',
    city: 'Montrose',
    state: 'WV',
    latitude: '39.113355',
    longitude: '-79.784186',
    timeZoneId: 'America/New_York',
  },
  '26285': {
    zip: '26285',
    city: 'Norton',
    state: 'WV',
    latitude: '38.934311',
    longitude: '-79.967217',
    timeZoneId: 'America/New_York',
  },
  '26287': {
    zip: '26287',
    city: 'Parsons',
    state: 'WV',
    latitude: '39.093406',
    longitude: '-79.681282',
    timeZoneId: 'America/New_York',
  },
  '26288': {
    zip: '26288',
    city: 'Webster Springs',
    state: 'WV',
    latitude: '38.49726',
    longitude: '-80.36928',
    timeZoneId: 'America/New_York',
  },
  '26289': {
    zip: '26289',
    city: 'Red Creek',
    state: 'WV',
    latitude: '39.023266',
    longitude: '-79.564414',
    timeZoneId: 'America/New_York',
  },
  '26291': {
    zip: '26291',
    city: 'Slatyfork',
    state: 'WV',
    latitude: '38.388523',
    longitude: '-80.065331',
    timeZoneId: 'America/New_York',
  },
  '26292': {
    zip: '26292',
    city: 'Thomas',
    state: 'WV',
    latitude: '39.142004',
    longitude: '-79.498373',
    timeZoneId: 'America/New_York',
  },
  '26293': {
    zip: '26293',
    city: 'Valley Bend',
    state: 'WV',
    latitude: '38.795403',
    longitude: '-79.924628',
    timeZoneId: 'America/New_York',
  },
  '26294': {
    zip: '26294',
    city: 'Valley Head',
    state: 'WV',
    latitude: '38.498611',
    longitude: '-80.054444',
    timeZoneId: 'America/New_York',
  },
  '26296': {
    zip: '26296',
    city: 'Whitmer',
    state: 'WV',
    latitude: '38.864167',
    longitude: '-79.557222',
    timeZoneId: 'America/New_York',
  },
  '26298': {
    zip: '26298',
    city: 'Bergoo',
    state: 'WV',
    latitude: '38.474069',
    longitude: '-80.329775',
    timeZoneId: 'America/New_York',
  },
  '26301': {
    zip: '26301',
    city: 'Clarksburg',
    state: 'WV',
    latitude: '39.275559',
    longitude: '-80.338918',
    timeZoneId: 'America/New_York',
  },
  '26302': {
    zip: '26302',
    city: 'Clarksburg',
    state: 'WV',
    latitude: '39.266363',
    longitude: '-80.309665',
    timeZoneId: 'America/New_York',
  },
  '26306': {
    zip: '26306',
    city: 'Clarksburg',
    state: 'WV',
    latitude: '39.28189',
    longitude: '-80.332998',
    timeZoneId: 'America/New_York',
  },
  '26320': {
    zip: '26320',
    city: 'Alma',
    state: 'WV',
    latitude: '39.424509',
    longitude: '-80.82723',
    timeZoneId: 'America/New_York',
  },
  '26321': {
    zip: '26321',
    city: 'Alum Bridge',
    state: 'WV',
    latitude: '39.04692',
    longitude: '-80.69802',
    timeZoneId: 'America/New_York',
  },
  '26323': {
    zip: '26323',
    city: 'Anmoore',
    state: 'WV',
    latitude: '39.258261',
    longitude: '-80.28905',
    timeZoneId: 'America/New_York',
  },
  '26325': {
    zip: '26325',
    city: 'Auburn',
    state: 'WV',
    latitude: '39.08891',
    longitude: '-80.897635',
    timeZoneId: 'America/New_York',
  },
  '26327': {
    zip: '26327',
    city: 'Berea',
    state: 'WV',
    latitude: '39.129419',
    longitude: '-80.926406',
    timeZoneId: 'America/New_York',
  },
  '26330': {
    zip: '26330',
    city: 'Bridgeport',
    state: 'WV',
    latitude: '39.289276',
    longitude: '-80.254283',
    timeZoneId: 'America/New_York',
  },
  '26335': {
    zip: '26335',
    city: 'Burnsville',
    state: 'WV',
    latitude: '38.84862',
    longitude: '-80.65038',
    timeZoneId: 'America/New_York',
  },
  '26337': {
    zip: '26337',
    city: 'Cairo',
    state: 'WV',
    latitude: '39.235969',
    longitude: '-81.150146',
    timeZoneId: 'America/New_York',
  },
  '26338': {
    zip: '26338',
    city: 'Camden',
    state: 'WV',
    latitude: '39.097849',
    longitude: '-80.60913',
    timeZoneId: 'America/New_York',
  },
  '26339': {
    zip: '26339',
    city: 'Center Point',
    state: 'WV',
    latitude: '39.420348',
    longitude: '-80.617871',
    timeZoneId: 'America/New_York',
  },
  '26342': {
    zip: '26342',
    city: 'Coxs Mills',
    state: 'WV',
    latitude: '39.004809',
    longitude: '-80.868049',
    timeZoneId: 'America/New_York',
  },
  '26343': {
    zip: '26343',
    city: 'Crawford',
    state: 'WV',
    latitude: '38.843326',
    longitude: '-80.408499',
    timeZoneId: 'America/New_York',
  },
  '26346': {
    zip: '26346',
    city: 'Ellenboro',
    state: 'WV',
    latitude: '39.28872',
    longitude: '-81.05868',
    timeZoneId: 'America/New_York',
  },
  '26347': {
    zip: '26347',
    city: 'Flemington',
    state: 'WV',
    latitude: '39.269476',
    longitude: '-80.125327',
    timeZoneId: 'America/New_York',
  },
  '26348': {
    zip: '26348',
    city: 'Folsom',
    state: 'WV',
    latitude: '39.468173',
    longitude: '-80.526646',
    timeZoneId: 'America/New_York',
  },
  '26349': {
    zip: '26349',
    city: 'Galloway',
    state: 'WV',
    latitude: '39.223973',
    longitude: '-80.134333',
    timeZoneId: 'America/New_York',
  },
  '26351': {
    zip: '26351',
    city: 'Glenville',
    state: 'WV',
    latitude: '38.938379',
    longitude: '-80.83708',
    timeZoneId: 'America/New_York',
  },
  '26354': {
    zip: '26354',
    city: 'Grafton',
    state: 'WV',
    latitude: '39.343632',
    longitude: '-80.022519',
    timeZoneId: 'America/New_York',
  },
  '26361': {
    zip: '26361',
    city: 'Gypsy',
    state: 'WV',
    latitude: '39.370303',
    longitude: '-80.319346',
    timeZoneId: 'America/New_York',
  },
  '26362': {
    zip: '26362',
    city: 'Harrisville',
    state: 'WV',
    latitude: '39.211147',
    longitude: '-81.050312',
    timeZoneId: 'America/New_York',
  },
  '26366': {
    zip: '26366',
    city: 'Haywood',
    state: 'WV',
    latitude: '39.28163',
    longitude: '-80.342878',
    timeZoneId: 'America/New_York',
  },
  '26369': {
    zip: '26369',
    city: 'Hepzibah',
    state: 'WV',
    latitude: '39.331489',
    longitude: '-80.336714',
    timeZoneId: 'America/New_York',
  },
  '26372': {
    zip: '26372',
    city: 'Horner',
    state: 'WV',
    latitude: '38.957645',
    longitude: '-80.360194',
    timeZoneId: 'America/New_York',
  },
  '26374': {
    zip: '26374',
    city: 'Independence',
    state: 'WV',
    latitude: '39.442306',
    longitude: '-79.888485',
    timeZoneId: 'America/New_York',
  },
  '26376': {
    zip: '26376',
    city: 'Ireland',
    state: 'WV',
    latitude: '38.753333',
    longitude: '-80.438333',
    timeZoneId: 'America/New_York',
  },
  '26377': {
    zip: '26377',
    city: 'Jacksonburg',
    state: 'WV',
    latitude: '39.499919',
    longitude: '-80.641416',
    timeZoneId: 'America/New_York',
  },
  '26378': {
    zip: '26378',
    city: 'Jane Lew',
    state: 'WV',
    latitude: '39.117304',
    longitude: '-80.435643',
    timeZoneId: 'America/New_York',
  },
  '26384': {
    zip: '26384',
    city: 'Linn',
    state: 'WV',
    latitude: '38.960924',
    longitude: '-80.717488',
    timeZoneId: 'America/New_York',
  },
  '26385': {
    zip: '26385',
    city: 'Lost Creek',
    state: 'WV',
    latitude: '39.17016',
    longitude: '-80.38728',
    timeZoneId: 'America/New_York',
  },
  '26386': {
    zip: '26386',
    city: 'Lumberport',
    state: 'WV',
    latitude: '39.379361',
    longitude: '-80.353385',
    timeZoneId: 'America/New_York',
  },
  '26404': {
    zip: '26404',
    city: 'Meadowbrook',
    state: 'WV',
    latitude: '39.344669',
    longitude: '-80.314209',
    timeZoneId: 'America/New_York',
  },
  '26405': {
    zip: '26405',
    city: 'Moatsville',
    state: 'WV',
    latitude: '39.224954',
    longitude: '-79.904469',
    timeZoneId: 'America/New_York',
  },
  '26408': {
    zip: '26408',
    city: 'Mount Clare',
    state: 'WV',
    latitude: '39.199128',
    longitude: '-80.270155',
    timeZoneId: 'America/New_York',
  },
  '26410': {
    zip: '26410',
    city: 'Newburg',
    state: 'WV',
    latitude: '39.400981',
    longitude: '-79.824788',
    timeZoneId: 'America/New_York',
  },
  '26411': {
    zip: '26411',
    city: 'New Milton',
    state: 'WV',
    latitude: '39.176338',
    longitude: '-80.717586',
    timeZoneId: 'America/New_York',
  },
  '26412': {
    zip: '26412',
    city: 'Orlando',
    state: 'WV',
    latitude: '38.888712',
    longitude: '-80.568821',
    timeZoneId: 'America/New_York',
  },
  '26415': {
    zip: '26415',
    city: 'Pennsboro',
    state: 'WV',
    latitude: '39.285557',
    longitude: '-80.967156',
    timeZoneId: 'America/New_York',
  },
  '26416': {
    zip: '26416',
    city: 'Philippi',
    state: 'WV',
    latitude: '39.150767',
    longitude: '-80.040442',
    timeZoneId: 'America/New_York',
  },
  '26419': {
    zip: '26419',
    city: 'Pine Grove',
    state: 'WV',
    latitude: '39.5661',
    longitude: '-80.67678',
    timeZoneId: 'America/New_York',
  },
  '26421': {
    zip: '26421',
    city: 'Pullman',
    state: 'WV',
    latitude: '39.188873',
    longitude: '-80.914366',
    timeZoneId: 'America/New_York',
  },
  '26422': {
    zip: '26422',
    city: 'Reynoldsville',
    state: 'WV',
    latitude: '39.290355',
    longitude: '-80.434245',
    timeZoneId: 'America/New_York',
  },
  '26424': {
    zip: '26424',
    city: 'Rosemont',
    state: 'WV',
    latitude: '39.265886',
    longitude: '-80.160007',
    timeZoneId: 'America/New_York',
  },
  '26425': {
    zip: '26425',
    city: 'Rowlesburg',
    state: 'WV',
    latitude: '39.224494',
    longitude: '-79.662638',
    timeZoneId: 'America/New_York',
  },
  '26426': {
    zip: '26426',
    city: 'Salem',
    state: 'WV',
    latitude: '39.281554',
    longitude: '-80.559325',
    timeZoneId: 'America/New_York',
  },
  '26430': {
    zip: '26430',
    city: 'Sand Fork',
    state: 'WV',
    latitude: '38.892107',
    longitude: '-80.741255',
    timeZoneId: 'America/New_York',
  },
  '26431': {
    zip: '26431',
    city: 'Shinnston',
    state: 'WV',
    latitude: '39.388634',
    longitude: '-80.29951',
    timeZoneId: 'America/New_York',
  },
  '26434': {
    zip: '26434',
    city: 'Shirley',
    state: 'WV',
    latitude: '39.397058',
    longitude: '-80.766413',
    timeZoneId: 'America/New_York',
  },
  '26435': {
    zip: '26435',
    city: 'Simpson',
    state: 'WV',
    latitude: '39.283409',
    longitude: '-80.066534',
    timeZoneId: 'America/New_York',
  },
  '26436': {
    zip: '26436',
    city: 'Smithburg',
    state: 'WV',
    latitude: '39.241547',
    longitude: '-80.809776',
    timeZoneId: 'America/New_York',
  },
  '26437': {
    zip: '26437',
    city: 'Smithfield',
    state: 'WV',
    latitude: '39.504724',
    longitude: '-80.567131',
    timeZoneId: 'America/New_York',
  },
  '26438': {
    zip: '26438',
    city: 'Spelter',
    state: 'WV',
    latitude: '39.346008',
    longitude: '-80.317063',
    timeZoneId: 'America/New_York',
  },
  '26440': {
    zip: '26440',
    city: 'Thornton',
    state: 'WV',
    latitude: '39.330967',
    longitude: '-79.906322',
    timeZoneId: 'America/New_York',
  },
  '26443': {
    zip: '26443',
    city: 'Troy',
    state: 'WV',
    latitude: '39.085069',
    longitude: '-80.757695',
    timeZoneId: 'America/New_York',
  },
  '26444': {
    zip: '26444',
    city: 'Tunnelton',
    state: 'WV',
    latitude: '39.333665',
    longitude: '-79.761039',
    timeZoneId: 'America/New_York',
  },
  '26447': {
    zip: '26447',
    city: 'Walkersville',
    state: 'WV',
    latitude: '38.848407',
    longitude: '-80.503889',
    timeZoneId: 'America/New_York',
  },
  '26448': {
    zip: '26448',
    city: 'Wallace',
    state: 'WV',
    latitude: '39.409991',
    longitude: '-80.48395',
    timeZoneId: 'America/New_York',
  },
  '26451': {
    zip: '26451',
    city: 'West Milford',
    state: 'WV',
    latitude: '39.200286',
    longitude: '-80.401724',
    timeZoneId: 'America/New_York',
  },
  '26452': {
    zip: '26452',
    city: 'Weston',
    state: 'WV',
    latitude: '39.041383',
    longitude: '-80.464446',
    timeZoneId: 'America/New_York',
  },
  '26456': {
    zip: '26456',
    city: 'West Union',
    state: 'WV',
    latitude: '39.293955',
    longitude: '-80.773989',
    timeZoneId: 'America/New_York',
  },
  '26463': {
    zip: '26463',
    city: 'Wyatt',
    state: 'WV',
    latitude: '39.43282',
    longitude: '-80.353233',
    timeZoneId: 'America/New_York',
  },
  '26501': {
    zip: '26501',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.569424',
    longitude: '-79.992081',
    timeZoneId: 'America/New_York',
  },
  '26502': {
    zip: '26502',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.634444',
    longitude: '-79.97',
    timeZoneId: 'America/New_York',
  },
  '26504': {
    zip: '26504',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.630371',
    longitude: '-79.957848',
    timeZoneId: 'America/New_York',
  },
  '26505': {
    zip: '26505',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.636473',
    longitude: '-79.951111',
    timeZoneId: 'America/New_York',
  },
  '26506': {
    zip: '26506',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.618877',
    longitude: '-79.973319',
    timeZoneId: 'America/New_York',
  },
  '26507': {
    zip: '26507',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.614238',
    longitude: '-79.915841',
    timeZoneId: 'America/New_York',
  },
  '26508': {
    zip: '26508',
    city: 'Morgantown',
    state: 'WV',
    latitude: '39.589081',
    longitude: '-79.896108',
    timeZoneId: 'America/New_York',
  },
  '26519': {
    zip: '26519',
    city: 'Albright',
    state: 'WV',
    latitude: '39.53208',
    longitude: '-79.64304',
    timeZoneId: 'America/New_York',
  },
  '26520': {
    zip: '26520',
    city: 'Arthurdale',
    state: 'WV',
    latitude: '39.492323',
    longitude: '-79.813201',
    timeZoneId: 'America/New_York',
  },
  '26521': {
    zip: '26521',
    city: 'Blacksville',
    state: 'WV',
    latitude: '39.693698',
    longitude: '-80.23566',
    timeZoneId: 'America/New_York',
  },
  '26524': {
    zip: '26524',
    city: 'Bretz',
    state: 'WV',
    latitude: '39.541498',
    longitude: '-79.802342',
    timeZoneId: 'America/New_York',
  },
  '26525': {
    zip: '26525',
    city: 'Bruceton Mills',
    state: 'WV',
    latitude: '39.646175',
    longitude: '-79.624337',
    timeZoneId: 'America/New_York',
  },
  '26527': {
    zip: '26527',
    city: 'Cassville',
    state: 'WV',
    latitude: '39.667123',
    longitude: '-80.056369',
    timeZoneId: 'America/New_York',
  },
  '26531': {
    zip: '26531',
    city: 'Dellslow',
    state: 'WV',
    latitude: '39.606875',
    longitude: '-79.890656',
    timeZoneId: 'America/New_York',
  },
  '26534': {
    zip: '26534',
    city: 'Granville',
    state: 'WV',
    latitude: '39.639722',
    longitude: '-79.987121',
    timeZoneId: 'America/New_York',
  },
  '26537': {
    zip: '26537',
    city: 'Kingwood',
    state: 'WV',
    latitude: '39.471602',
    longitude: '-79.684322',
    timeZoneId: 'America/New_York',
  },
  '26541': {
    zip: '26541',
    city: 'Maidsville',
    state: 'WV',
    latitude: '39.705779',
    longitude: '-79.993492',
    timeZoneId: 'America/New_York',
  },
  '26542': {
    zip: '26542',
    city: 'Masontown',
    state: 'WV',
    latitude: '39.582126',
    longitude: '-79.79922',
    timeZoneId: 'America/New_York',
  },
  '26543': {
    zip: '26543',
    city: 'Osage',
    state: 'WV',
    latitude: '39.697322',
    longitude: '-80.066232',
    timeZoneId: 'America/New_York',
  },
  '26544': {
    zip: '26544',
    city: 'Pentress',
    state: 'WV',
    latitude: '39.710345',
    longitude: '-80.159708',
    timeZoneId: 'America/New_York',
  },
  '26546': {
    zip: '26546',
    city: 'Pursglove',
    state: 'WV',
    latitude: '39.699315',
    longitude: '-80.070871',
    timeZoneId: 'America/New_York',
  },
  '26547': {
    zip: '26547',
    city: 'Reedsville',
    state: 'WV',
    latitude: '39.514756',
    longitude: '-79.813748',
    timeZoneId: 'America/New_York',
  },
  '26554': {
    zip: '26554',
    city: 'Fairmont',
    state: 'WV',
    latitude: '39.476203',
    longitude: '-80.152059',
    timeZoneId: 'America/New_York',
  },
  '26555': {
    zip: '26555',
    city: 'Fairmont',
    state: 'WV',
    latitude: '39.470255',
    longitude: '-80.150258',
    timeZoneId: 'America/New_York',
  },
  '26559': {
    zip: '26559',
    city: 'Barrackville',
    state: 'WV',
    latitude: '39.50062',
    longitude: '-80.172206',
    timeZoneId: 'America/New_York',
  },
  '26560': {
    zip: '26560',
    city: 'Baxter',
    state: 'WV',
    latitude: '39.537151',
    longitude: '-80.145841',
    timeZoneId: 'America/New_York',
  },
  '26561': {
    zip: '26561',
    city: 'Big Run',
    state: 'WV',
    latitude: '39.591392',
    longitude: '-80.569806',
    timeZoneId: 'America/New_York',
  },
  '26562': {
    zip: '26562',
    city: 'Burton',
    state: 'WV',
    latitude: '39.66564',
    longitude: '-80.4195',
    timeZoneId: 'America/New_York',
  },
  '26563': {
    zip: '26563',
    city: 'Carolina',
    state: 'WV',
    latitude: '39.48154',
    longitude: '-80.273362',
    timeZoneId: 'America/New_York',
  },
  '26566': {
    zip: '26566',
    city: 'Colfax',
    state: 'WV',
    latitude: '39.435212',
    longitude: '-80.129545',
    timeZoneId: 'America/New_York',
  },
  '26568': {
    zip: '26568',
    city: 'Enterprise',
    state: 'WV',
    latitude: '39.42705',
    longitude: '-80.347848',
    timeZoneId: 'America/New_York',
  },
  '26570': {
    zip: '26570',
    city: 'Fairview',
    state: 'WV',
    latitude: '39.624974',
    longitude: '-80.259968',
    timeZoneId: 'America/New_York',
  },
  '26571': {
    zip: '26571',
    city: 'Farmington',
    state: 'WV',
    latitude: '39.515469',
    longitude: '-80.248772',
    timeZoneId: 'America/New_York',
  },
  '26572': {
    zip: '26572',
    city: 'Four States',
    state: 'WV',
    latitude: '39.48039',
    longitude: '-80.309372',
    timeZoneId: 'America/New_York',
  },
  '26574': {
    zip: '26574',
    city: 'Grant Town',
    state: 'WV',
    latitude: '39.555996',
    longitude: '-80.178507',
    timeZoneId: 'America/New_York',
  },
  '26575': {
    zip: '26575',
    city: 'Hundred',
    state: 'WV',
    latitude: '39.695645',
    longitude: '-80.469427',
    timeZoneId: 'America/New_York',
  },
  '26576': {
    zip: '26576',
    city: 'Idamay',
    state: 'WV',
    latitude: '39.497655',
    longitude: '-80.257794',
    timeZoneId: 'America/New_York',
  },
  '26578': {
    zip: '26578',
    city: 'Kingmont',
    state: 'WV',
    latitude: '39.448876',
    longitude: '-80.178997',
    timeZoneId: 'America/New_York',
  },
  '26581': {
    zip: '26581',
    city: 'Littleton',
    state: 'WV',
    latitude: '39.665921',
    longitude: '-80.575673',
    timeZoneId: 'America/New_York',
  },
  '26582': {
    zip: '26582',
    city: 'Mannington',
    state: 'WV',
    latitude: '39.52798',
    longitude: '-80.344068',
    timeZoneId: 'America/New_York',
  },
  '26585': {
    zip: '26585',
    city: 'Metz',
    state: 'WV',
    latitude: '39.612151',
    longitude: '-80.438407',
    timeZoneId: 'America/New_York',
  },
  '26586': {
    zip: '26586',
    city: 'Montana Mines',
    state: 'WV',
    latitude: '39.520447',
    longitude: '-80.114901',
    timeZoneId: 'America/New_York',
  },
  '26587': {
    zip: '26587',
    city: 'Rachel',
    state: 'WV',
    latitude: '39.522724',
    longitude: '-80.296322',
    timeZoneId: 'America/New_York',
  },
  '26588': {
    zip: '26588',
    city: 'Rivesville',
    state: 'WV',
    latitude: '39.532536',
    longitude: '-80.123247',
    timeZoneId: 'America/New_York',
  },
  '26590': {
    zip: '26590',
    city: 'Wana',
    state: 'WV',
    latitude: '39.705862',
    longitude: '-80.31465',
    timeZoneId: 'America/New_York',
  },
  '26591': {
    zip: '26591',
    city: 'Worthington',
    state: 'WV',
    latitude: '39.455841',
    longitude: '-80.298455',
    timeZoneId: 'America/New_York',
  },
  '26601': {
    zip: '26601',
    city: 'Sutton',
    state: 'WV',
    latitude: '38.67233',
    longitude: '-80.687532',
    timeZoneId: 'America/New_York',
  },
  '26610': {
    zip: '26610',
    city: 'Birch River',
    state: 'WV',
    latitude: '38.476703',
    longitude: '-80.760728',
    timeZoneId: 'America/New_York',
  },
  '26611': {
    zip: '26611',
    city: 'Cedarville',
    state: 'WV',
    latitude: '38.838066',
    longitude: '-80.849384',
    timeZoneId: 'America/New_York',
  },
  '26615': {
    zip: '26615',
    city: 'Copen',
    state: 'WV',
    latitude: '38.841723',
    longitude: '-80.723809',
    timeZoneId: 'America/New_York',
  },
  '26617': {
    zip: '26617',
    city: 'Dille',
    state: 'WV',
    latitude: '38.466488',
    longitude: '-80.871552',
    timeZoneId: 'America/New_York',
  },
  '26619': {
    zip: '26619',
    city: 'Exchange',
    state: 'WV',
    latitude: '38.798217',
    longitude: '-80.764455',
    timeZoneId: 'America/New_York',
  },
  '26621': {
    zip: '26621',
    city: 'Flatwoods',
    state: 'WV',
    latitude: '38.7282',
    longitude: '-80.63058',
    timeZoneId: 'America/New_York',
  },
  '26623': {
    zip: '26623',
    city: 'Frametown',
    state: 'WV',
    latitude: '38.630653',
    longitude: '-80.876931',
    timeZoneId: 'America/New_York',
  },
  '26624': {
    zip: '26624',
    city: 'Gassaway',
    state: 'WV',
    latitude: '38.68602',
    longitude: '-80.772214',
    timeZoneId: 'America/New_York',
  },
  '26627': {
    zip: '26627',
    city: 'Heaters',
    state: 'WV',
    latitude: '38.755689',
    longitude: '-80.651789',
    timeZoneId: 'America/New_York',
  },
  '26629': {
    zip: '26629',
    city: 'Little Birch',
    state: 'WV',
    latitude: '38.565848',
    longitude: '-80.70846',
    timeZoneId: 'America/New_York',
  },
  '26631': {
    zip: '26631',
    city: 'Napier',
    state: 'WV',
    latitude: '38.772829',
    longitude: '-80.582664',
    timeZoneId: 'America/New_York',
  },
  '26636': {
    zip: '26636',
    city: 'Rosedale',
    state: 'WV',
    latitude: '38.78225',
    longitude: '-80.899765',
    timeZoneId: 'America/New_York',
  },
  '26638': {
    zip: '26638',
    city: 'Shock',
    state: 'WV',
    latitude: '38.76767',
    longitude: '-80.992411',
    timeZoneId: 'America/New_York',
  },
  '26651': {
    zip: '26651',
    city: 'Summersville',
    state: 'WV',
    latitude: '38.285274',
    longitude: '-80.844612',
    timeZoneId: 'America/New_York',
  },
  '26656': {
    zip: '26656',
    city: 'Belva',
    state: 'WV',
    latitude: '38.269941',
    longitude: '-81.130988',
    timeZoneId: 'America/New_York',
  },
  '26660': {
    zip: '26660',
    city: 'Calvin',
    state: 'WV',
    latitude: '38.324547',
    longitude: '-80.707045',
    timeZoneId: 'America/New_York',
  },
  '26662': {
    zip: '26662',
    city: 'Canvas',
    state: 'WV',
    latitude: '38.261219',
    longitude: '-80.733726',
    timeZoneId: 'America/New_York',
  },
  '26667': {
    zip: '26667',
    city: 'Drennen',
    state: 'WV',
    latitude: '38.249394',
    longitude: '-81.016823',
    timeZoneId: 'America/New_York',
  },
  '26671': {
    zip: '26671',
    city: 'Gilboa',
    state: 'WV',
    latitude: '38.295354',
    longitude: '-80.963835',
    timeZoneId: 'America/New_York',
  },
  '26675': {
    zip: '26675',
    city: 'Keslers Cross Lanes',
    state: 'WV',
    latitude: '38.234705',
    longitude: '-80.936228',
    timeZoneId: 'America/New_York',
  },
  '26676': {
    zip: '26676',
    city: 'Leivasy',
    state: 'WV',
    latitude: '38.128092',
    longitude: '-80.738271',
    timeZoneId: 'America/New_York',
  },
  '26678': {
    zip: '26678',
    city: 'Mount Lookout',
    state: 'WV',
    latitude: '38.179342',
    longitude: '-80.906842',
    timeZoneId: 'America/New_York',
  },
  '26679': {
    zip: '26679',
    city: 'Mount Nebo',
    state: 'WV',
    latitude: '38.20518',
    longitude: '-80.82192',
    timeZoneId: 'America/New_York',
  },
  '26680': {
    zip: '26680',
    city: 'Nallen',
    state: 'WV',
    latitude: '38.10012',
    longitude: '-80.8869',
    timeZoneId: 'America/New_York',
  },
  '26681': {
    zip: '26681',
    city: 'Nettie',
    state: 'WV',
    latitude: '38.197435',
    longitude: '-80.682232',
    timeZoneId: 'America/New_York',
  },
  '26684': {
    zip: '26684',
    city: 'Pool',
    state: 'WV',
    latitude: '38.145767',
    longitude: '-80.884027',
    timeZoneId: 'America/New_York',
  },
  '26690': {
    zip: '26690',
    city: 'Swiss',
    state: 'WV',
    latitude: '38.277039',
    longitude: '-81.070532',
    timeZoneId: 'America/New_York',
  },
  '26691': {
    zip: '26691',
    city: 'Tioga',
    state: 'WV',
    latitude: '38.393433',
    longitude: '-80.67149',
    timeZoneId: 'America/New_York',
  },
  '26704': {
    zip: '26704',
    city: 'Augusta',
    state: 'WV',
    latitude: '39.297814',
    longitude: '-78.59396',
    timeZoneId: 'America/New_York',
  },
  '26705': {
    zip: '26705',
    city: 'Aurora',
    state: 'WV',
    latitude: '39.30222',
    longitude: '-79.57758',
    timeZoneId: 'America/New_York',
  },
  '26707': {
    zip: '26707',
    city: 'Bayard',
    state: 'WV',
    latitude: '39.254167',
    longitude: '-79.398056',
    timeZoneId: 'America/New_York',
  },
  '26710': {
    zip: '26710',
    city: 'Burlington',
    state: 'WV',
    latitude: '39.365881',
    longitude: '-78.91127',
    timeZoneId: 'America/New_York',
  },
  '26711': {
    zip: '26711',
    city: 'Capon Bridge',
    state: 'WV',
    latitude: '39.270713',
    longitude: '-78.496923',
    timeZoneId: 'America/New_York',
  },
  '26714': {
    zip: '26714',
    city: 'Delray',
    state: 'WV',
    latitude: '39.176445',
    longitude: '-78.639695',
    timeZoneId: 'America/New_York',
  },
  '26716': {
    zip: '26716',
    city: 'Eglon',
    state: 'WV',
    latitude: '39.291239',
    longitude: '-79.511965',
    timeZoneId: 'America/New_York',
  },
  '26717': {
    zip: '26717',
    city: 'Elk Garden',
    state: 'WV',
    latitude: '39.35726',
    longitude: '-79.16866',
    timeZoneId: 'America/New_York',
  },
  '26719': {
    zip: '26719',
    city: 'Fort Ashby',
    state: 'WV',
    latitude: '39.480227',
    longitude: '-78.773099',
    timeZoneId: 'America/New_York',
  },
  '26720': {
    zip: '26720',
    city: 'Gormania',
    state: 'WV',
    latitude: '39.246694',
    longitude: '-79.352749',
    timeZoneId: 'America/New_York',
  },
  '26722': {
    zip: '26722',
    city: 'Green Spring',
    state: 'WV',
    latitude: '39.494638',
    longitude: '-78.628233',
    timeZoneId: 'America/New_York',
  },
  '26726': {
    zip: '26726',
    city: 'Keyser',
    state: 'WV',
    latitude: '39.437003',
    longitude: '-78.981107',
    timeZoneId: 'America/New_York',
  },
  '26731': {
    zip: '26731',
    city: 'Lahmansville',
    state: 'WV',
    latitude: '39.163155',
    longitude: '-79.042152',
    timeZoneId: 'America/New_York',
  },
  '26739': {
    zip: '26739',
    city: 'Mount Storm',
    state: 'WV',
    latitude: '39.211179',
    longitude: '-79.25218',
    timeZoneId: 'America/New_York',
  },
  '26743': {
    zip: '26743',
    city: 'New Creek',
    state: 'WV',
    latitude: '39.319934',
    longitude: '-79.047286',
    timeZoneId: 'America/New_York',
  },
  '26750': {
    zip: '26750',
    city: 'Piedmont',
    state: 'WV',
    latitude: '39.508294',
    longitude: '-78.995394',
    timeZoneId: 'America/New_York',
  },
  '26753': {
    zip: '26753',
    city: 'Ridgeley',
    state: 'WV',
    latitude: '39.60096',
    longitude: '-78.78414',
    timeZoneId: 'America/New_York',
  },
  '26755': {
    zip: '26755',
    city: 'Rio',
    state: 'WV',
    latitude: '39.177526',
    longitude: '-78.691989',
    timeZoneId: 'America/New_York',
  },
  '26757': {
    zip: '26757',
    city: 'Romney',
    state: 'WV',
    latitude: '39.344134',
    longitude: '-78.758405',
    timeZoneId: 'America/New_York',
  },
  '26761': {
    zip: '26761',
    city: 'Shanks',
    state: 'WV',
    latitude: '39.266259',
    longitude: '-78.703294',
    timeZoneId: 'America/New_York',
  },
  '26763': {
    zip: '26763',
    city: 'Springfield',
    state: 'WV',
    latitude: '39.468141',
    longitude: '-78.69925',
    timeZoneId: 'America/New_York',
  },
  '26764': {
    zip: '26764',
    city: 'Terra Alta',
    state: 'WV',
    latitude: '39.444161',
    longitude: '-79.54462',
    timeZoneId: 'America/New_York',
  },
  '26767': {
    zip: '26767',
    city: 'Wiley Ford',
    state: 'WV',
    latitude: '39.61451',
    longitude: '-78.761574',
    timeZoneId: 'America/New_York',
  },
  '26801': {
    zip: '26801',
    city: 'Baker',
    state: 'WV',
    latitude: '39.066706',
    longitude: '-78.78785',
    timeZoneId: 'America/New_York',
  },
  '26802': {
    zip: '26802',
    city: 'Brandywine',
    state: 'WV',
    latitude: '38.640625',
    longitude: '-79.198583',
    timeZoneId: 'America/New_York',
  },
  '26804': {
    zip: '26804',
    city: 'Circleville',
    state: 'WV',
    latitude: '38.616985',
    longitude: '-79.552509',
    timeZoneId: 'America/New_York',
  },
  '26807': {
    zip: '26807',
    city: 'Franklin',
    state: 'WV',
    latitude: '38.620551',
    longitude: '-79.38411',
    timeZoneId: 'America/New_York',
  },
  '26808': {
    zip: '26808',
    city: 'High View',
    state: 'WV',
    latitude: '39.212405',
    longitude: '-78.435527',
    timeZoneId: 'America/New_York',
  },
  '26810': {
    zip: '26810',
    city: 'Lost City',
    state: 'WV',
    latitude: '38.907535',
    longitude: '-78.801085',
    timeZoneId: 'America/New_York',
  },
  '26812': {
    zip: '26812',
    city: 'Mathias',
    state: 'WV',
    latitude: '38.877412',
    longitude: '-78.892481',
    timeZoneId: 'America/New_York',
  },
  '26814': {
    zip: '26814',
    city: 'Riverton',
    state: 'WV',
    latitude: '38.699442',
    longitude: '-79.497969',
    timeZoneId: 'America/New_York',
  },
  '26815': {
    zip: '26815',
    city: 'Sugar Grove',
    state: 'WV',
    latitude: '38.498767',
    longitude: '-79.274451',
    timeZoneId: 'America/New_York',
  },
  '26817': {
    zip: '26817',
    city: 'Bloomery',
    state: 'WV',
    latitude: '39.375279',
    longitude: '-78.387899',
    timeZoneId: 'America/New_York',
  },
  '26818': {
    zip: '26818',
    city: 'Fisher',
    state: 'WV',
    latitude: '39.045865',
    longitude: '-78.993193',
    timeZoneId: 'America/New_York',
  },
  '26823': {
    zip: '26823',
    city: 'Capon Springs',
    state: 'WV',
    latitude: '39.137153',
    longitude: '-78.483814',
    timeZoneId: 'America/New_York',
  },
  '26833': {
    zip: '26833',
    city: 'Maysville',
    state: 'WV',
    latitude: '39.093016',
    longitude: '-79.202715',
    timeZoneId: 'America/New_York',
  },
  '26836': {
    zip: '26836',
    city: 'Moorefield',
    state: 'WV',
    latitude: '39.08748',
    longitude: '-78.99594',
    timeZoneId: 'America/New_York',
  },
  '26838': {
    zip: '26838',
    city: 'Milam',
    state: 'WV',
    latitude: '38.803923',
    longitude: '-79.064534',
    timeZoneId: 'America/New_York',
  },
  '26845': {
    zip: '26845',
    city: 'Old Fields',
    state: 'WV',
    latitude: '39.146898',
    longitude: '-78.951621',
    timeZoneId: 'America/New_York',
  },
  '26847': {
    zip: '26847',
    city: 'Petersburg',
    state: 'WV',
    latitude: '38.996532',
    longitude: '-79.123849',
    timeZoneId: 'America/New_York',
  },
  '26851': {
    zip: '26851',
    city: 'Wardensville',
    state: 'WV',
    latitude: '39.075211',
    longitude: '-78.596294',
    timeZoneId: 'America/New_York',
  },
  '26852': {
    zip: '26852',
    city: 'Purgitsville',
    state: 'WV',
    latitude: '39.264244',
    longitude: '-78.898607',
    timeZoneId: 'America/New_York',
  },
  '26855': {
    zip: '26855',
    city: 'Cabins',
    state: 'WV',
    latitude: '38.952589',
    longitude: '-79.272273',
    timeZoneId: 'America/New_York',
  },
  '26865': {
    zip: '26865',
    city: 'Yellow Spring',
    state: 'WV',
    latitude: '39.199167',
    longitude: '-78.438333',
    timeZoneId: 'America/New_York',
  },
  '26866': {
    zip: '26866',
    city: 'Upper Tract',
    state: 'WV',
    latitude: '38.796684',
    longitude: '-79.257888',
    timeZoneId: 'America/New_York',
  },
  '26884': {
    zip: '26884',
    city: 'Seneca Rocks',
    state: 'WV',
    latitude: '38.845264',
    longitude: '-79.383961',
    timeZoneId: 'America/New_York',
  },
  '26886': {
    zip: '26886',
    city: 'Onego',
    state: 'WV',
    latitude: '38.820372',
    longitude: '-79.476238',
    timeZoneId: 'America/New_York',
  },
  '27006': {
    zip: '27006',
    city: 'Advance',
    state: 'NC',
    latitude: '36.01038',
    longitude: '-80.44734',
    timeZoneId: 'America/New_York',
  },
  '27007': {
    zip: '27007',
    city: 'Ararat',
    state: 'NC',
    latitude: '36.383368',
    longitude: '-80.601678',
    timeZoneId: 'America/New_York',
  },
  '27009': {
    zip: '27009',
    city: 'Belews Creek',
    state: 'NC',
    latitude: '36.237061',
    longitude: '-80.082222',
    timeZoneId: 'America/New_York',
  },
  '27010': {
    zip: '27010',
    city: 'Bethania',
    state: 'NC',
    latitude: '36.165806',
    longitude: '-80.369502',
    timeZoneId: 'America/New_York',
  },
  '27011': {
    zip: '27011',
    city: 'Boonville',
    state: 'NC',
    latitude: '36.2403',
    longitude: '-80.63742',
    timeZoneId: 'America/New_York',
  },
  '27012': {
    zip: '27012',
    city: 'Clemmons',
    state: 'NC',
    latitude: '36.033742',
    longitude: '-80.396011',
    timeZoneId: 'America/New_York',
  },
  '27013': {
    zip: '27013',
    city: 'Cleveland',
    state: 'NC',
    latitude: '35.745026',
    longitude: '-80.676769',
    timeZoneId: 'America/New_York',
  },
  '27014': {
    zip: '27014',
    city: 'Cooleemee',
    state: 'NC',
    latitude: '35.811382',
    longitude: '-80.559115',
    timeZoneId: 'America/New_York',
  },
  '27016': {
    zip: '27016',
    city: 'Danbury',
    state: 'NC',
    latitude: '36.46646',
    longitude: '-80.251279',
    timeZoneId: 'America/New_York',
  },
  '27017': {
    zip: '27017',
    city: 'Dobson',
    state: 'NC',
    latitude: '36.36462',
    longitude: '-80.71284',
    timeZoneId: 'America/New_York',
  },
  '27018': {
    zip: '27018',
    city: 'East Bend',
    state: 'NC',
    latitude: '36.201254',
    longitude: '-80.530156',
    timeZoneId: 'America/New_York',
  },
  '27019': {
    zip: '27019',
    city: 'Germanton',
    state: 'NC',
    latitude: '36.245275',
    longitude: '-80.223445',
    timeZoneId: 'America/New_York',
  },
  '27020': {
    zip: '27020',
    city: 'Hamptonville',
    state: 'NC',
    latitude: '36.09564',
    longitude: '-80.7957',
    timeZoneId: 'America/New_York',
  },
  '27021': {
    zip: '27021',
    city: 'King',
    state: 'NC',
    latitude: '36.28728',
    longitude: '-80.356209',
    timeZoneId: 'America/New_York',
  },
  '27022': {
    zip: '27022',
    city: 'Lawsonville',
    state: 'NC',
    latitude: '36.513484',
    longitude: '-80.219058',
    timeZoneId: 'America/New_York',
  },
  '27023': {
    zip: '27023',
    city: 'Lewisville',
    state: 'NC',
    latitude: '36.097444',
    longitude: '-80.422826',
    timeZoneId: 'America/New_York',
  },
  '27024': {
    zip: '27024',
    city: 'Lowgap',
    state: 'NC',
    latitude: '36.500159',
    longitude: '-80.847233',
    timeZoneId: 'America/New_York',
  },
  '27025': {
    zip: '27025',
    city: 'Madison',
    state: 'NC',
    latitude: '36.385863',
    longitude: '-79.967595',
    timeZoneId: 'America/New_York',
  },
  '27027': {
    zip: '27027',
    city: 'Mayodan',
    state: 'NC',
    latitude: '36.411918',
    longitude: '-79.976025',
    timeZoneId: 'America/New_York',
  },
  '27028': {
    zip: '27028',
    city: 'Mocksville',
    state: 'NC',
    latitude: '35.90826',
    longitude: '-80.5563',
    timeZoneId: 'America/New_York',
  },
  '27030': {
    zip: '27030',
    city: 'Mount Airy',
    state: 'NC',
    latitude: '36.490645',
    longitude: '-80.628907',
    timeZoneId: 'America/New_York',
  },
  '27031': {
    zip: '27031',
    city: 'Mount Airy',
    state: 'NC',
    latitude: '36.445556',
    longitude: '-80.633611',
    timeZoneId: 'America/New_York',
  },
  '27040': {
    zip: '27040',
    city: 'Pfafftown',
    state: 'NC',
    latitude: '36.167058',
    longitude: '-80.37617',
    timeZoneId: 'America/New_York',
  },
  '27041': {
    zip: '27041',
    city: 'Pilot Mountain',
    state: 'NC',
    latitude: '36.450094',
    longitude: '-80.489865',
    timeZoneId: 'America/New_York',
  },
  '27042': {
    zip: '27042',
    city: 'Pine Hall',
    state: 'NC',
    latitude: '36.348959',
    longitude: '-80.05887',
    timeZoneId: 'America/New_York',
  },
  '27043': {
    zip: '27043',
    city: 'Pinnacle',
    state: 'NC',
    latitude: '36.255873',
    longitude: '-80.44278',
    timeZoneId: 'America/New_York',
  },
  '27045': {
    zip: '27045',
    city: 'Rural Hall',
    state: 'NC',
    latitude: '36.226761',
    longitude: '-80.291133',
    timeZoneId: 'America/New_York',
  },
  '27046': {
    zip: '27046',
    city: 'Sandy Ridge',
    state: 'NC',
    latitude: '36.492663',
    longitude: '-80.089256',
    timeZoneId: 'America/New_York',
  },
  '27047': {
    zip: '27047',
    city: 'Siloam',
    state: 'NC',
    latitude: '36.299108',
    longitude: '-80.57741',
    timeZoneId: 'America/New_York',
  },
  '27048': {
    zip: '27048',
    city: 'Stoneville',
    state: 'NC',
    latitude: '36.461117',
    longitude: '-79.907962',
    timeZoneId: 'America/New_York',
  },
  '27049': {
    zip: '27049',
    city: 'Toast',
    state: 'NC',
    latitude: '36.496244',
    longitude: '-80.632927',
    timeZoneId: 'America/New_York',
  },
  '27050': {
    zip: '27050',
    city: 'Tobaccoville',
    state: 'NC',
    latitude: '36.232639',
    longitude: '-80.396424',
    timeZoneId: 'America/New_York',
  },
  '27051': {
    zip: '27051',
    city: 'Walkertown',
    state: 'NC',
    latitude: '36.176386',
    longitude: '-80.164168',
    timeZoneId: 'America/New_York',
  },
  '27052': {
    zip: '27052',
    city: 'Walnut Cove',
    state: 'NC',
    latitude: '36.31338',
    longitude: '-80.15508',
    timeZoneId: 'America/New_York',
  },
  '27053': {
    zip: '27053',
    city: 'Westfield',
    state: 'NC',
    latitude: '36.434671',
    longitude: '-80.321082',
    timeZoneId: 'America/New_York',
  },
  '27054': {
    zip: '27054',
    city: 'Woodleaf',
    state: 'NC',
    latitude: '35.790019',
    longitude: '-80.612864',
    timeZoneId: 'America/New_York',
  },
  '27055': {
    zip: '27055',
    city: 'Yadkinville',
    state: 'NC',
    latitude: '36.13992',
    longitude: '-80.62362',
    timeZoneId: 'America/New_York',
  },
  '27094': {
    zip: '27094',
    city: 'Rural Hall',
    state: 'NC',
    latitude: '36.236783',
    longitude: '-80.29703',
    timeZoneId: 'America/New_York',
  },
  '27098': {
    zip: '27098',
    city: 'Rural Hall',
    state: 'NC',
    latitude: '36.229233',
    longitude: '-80.304498',
    timeZoneId: 'America/New_York',
  },
  '27099': {
    zip: '27099',
    city: 'Rural Hall',
    state: 'NC',
    latitude: '36.228578',
    longitude: '-80.303201',
    timeZoneId: 'America/New_York',
  },
  '27101': {
    zip: '27101',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.104297',
    longitude: '-80.214816',
    timeZoneId: 'America/New_York',
  },
  '27102': {
    zip: '27102',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.110895',
    longitude: '-80.202875',
    timeZoneId: 'America/New_York',
  },
  '27103': {
    zip: '27103',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.069214',
    longitude: '-80.31617',
    timeZoneId: 'America/New_York',
  },
  '27104': {
    zip: '27104',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.096281',
    longitude: '-80.322652',
    timeZoneId: 'America/New_York',
  },
  '27105': {
    zip: '27105',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.150762',
    longitude: '-80.234242',
    timeZoneId: 'America/New_York',
  },
  '27106': {
    zip: '27106',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.14964',
    longitude: '-80.3226',
    timeZoneId: 'America/New_York',
  },
  '27107': {
    zip: '27107',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.062543',
    longitude: '-80.183453',
    timeZoneId: 'America/New_York',
  },
  '27108': {
    zip: '27108',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.090834',
    longitude: '-80.247942',
    timeZoneId: 'America/New_York',
  },
  '27109': {
    zip: '27109',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.122579',
    longitude: '-80.285996',
    timeZoneId: 'America/New_York',
  },
  '27110': {
    zip: '27110',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.103342',
    longitude: '-80.227719',
    timeZoneId: 'America/New_York',
  },
  '27111': {
    zip: '27111',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.098893',
    longitude: '-80.243891',
    timeZoneId: 'America/New_York',
  },
  '27113': {
    zip: '27113',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.101917',
    longitude: '-80.247264',
    timeZoneId: 'America/New_York',
  },
  '27114': {
    zip: '27114',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.103243',
    longitude: '-80.243693',
    timeZoneId: 'America/New_York',
  },
  '27115': {
    zip: '27115',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.09941',
    longitude: '-80.249956',
    timeZoneId: 'America/New_York',
  },
  '27116': {
    zip: '27116',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.097867',
    longitude: '-80.244547',
    timeZoneId: 'America/New_York',
  },
  '27117': {
    zip: '27117',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.077833',
    longitude: '-80.23874',
    timeZoneId: 'America/New_York',
  },
  '27120': {
    zip: '27120',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.107889',
    longitude: '-80.24059',
    timeZoneId: 'America/New_York',
  },
  '27127': {
    zip: '27127',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.047311',
    longitude: '-80.265066',
    timeZoneId: 'America/New_York',
  },
  '27130': {
    zip: '27130',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.056965',
    longitude: '-80.320718',
    timeZoneId: 'America/New_York',
  },
  '27150': {
    zip: '27150',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.103682',
    longitude: '-80.20772',
    timeZoneId: 'America/New_York',
  },
  '27152': {
    zip: '27152',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.104411',
    longitude: '-80.206453',
    timeZoneId: 'America/New_York',
  },
  '27155': {
    zip: '27155',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.105135',
    longitude: '-80.201896',
    timeZoneId: 'America/New_York',
  },
  '27157': {
    zip: '27157',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.059402',
    longitude: '-80.321981',
    timeZoneId: 'America/New_York',
  },
  '27198': {
    zip: '27198',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.105109',
    longitude: '-80.225702',
    timeZoneId: 'America/New_York',
  },
  '27199': {
    zip: '27199',
    city: 'Winston Salem',
    state: 'NC',
    latitude: '36.099201',
    longitude: '-80.249323',
    timeZoneId: 'America/New_York',
  },
  '27201': {
    zip: '27201',
    city: 'Alamance',
    state: 'NC',
    latitude: '36.026457',
    longitude: '-79.485877',
    timeZoneId: 'America/New_York',
  },
  '27202': {
    zip: '27202',
    city: 'Altamahaw',
    state: 'NC',
    latitude: '36.184262',
    longitude: '-79.506728',
    timeZoneId: 'America/New_York',
  },
  '27203': {
    zip: '27203',
    city: 'Asheboro',
    state: 'NC',
    latitude: '35.65104',
    longitude: '-79.83162',
    timeZoneId: 'America/New_York',
  },
  '27204': {
    zip: '27204',
    city: 'Asheboro',
    state: 'NC',
    latitude: '35.668436',
    longitude: '-79.808474',
    timeZoneId: 'America/New_York',
  },
  '27205': {
    zip: '27205',
    city: 'Asheboro',
    state: 'NC',
    latitude: '35.712988',
    longitude: '-79.814274',
    timeZoneId: 'America/New_York',
  },
  '27207': {
    zip: '27207',
    city: 'Bear Creek',
    state: 'NC',
    latitude: '35.698455',
    longitude: '-79.508612',
    timeZoneId: 'America/New_York',
  },
  '27208': {
    zip: '27208',
    city: 'Bennett',
    state: 'NC',
    latitude: '35.574999',
    longitude: '-79.524083',
    timeZoneId: 'America/New_York',
  },
  '27209': {
    zip: '27209',
    city: 'Biscoe',
    state: 'NC',
    latitude: '35.35637',
    longitude: '-79.790361',
    timeZoneId: 'America/New_York',
  },
  '27212': {
    zip: '27212',
    city: 'Blanch',
    state: 'NC',
    latitude: '36.529874',
    longitude: '-79.302902',
    timeZoneId: 'America/New_York',
  },
  '27213': {
    zip: '27213',
    city: 'Bonlee',
    state: 'NC',
    latitude: '35.645657',
    longitude: '-79.413871',
    timeZoneId: 'America/New_York',
  },
  '27214': {
    zip: '27214',
    city: 'Browns Summit',
    state: 'NC',
    latitude: '36.2007',
    longitude: '-79.67772',
    timeZoneId: 'America/New_York',
  },
  '27215': {
    zip: '27215',
    city: 'Burlington',
    state: 'NC',
    latitude: '36.03342',
    longitude: '-79.49088',
    timeZoneId: 'America/New_York',
  },
  '27216': {
    zip: '27216',
    city: 'Burlington',
    state: 'NC',
    latitude: '36.08169',
    longitude: '-79.412697',
    timeZoneId: 'America/New_York',
  },
  '27217': {
    zip: '27217',
    city: 'Burlington',
    state: 'NC',
    latitude: '36.127359',
    longitude: '-79.415724',
    timeZoneId: 'America/New_York',
  },
  '27228': {
    zip: '27228',
    city: 'Bynum',
    state: 'NC',
    latitude: '35.772618',
    longitude: '-79.137819',
    timeZoneId: 'America/New_York',
  },
  '27229': {
    zip: '27229',
    city: 'Candor',
    state: 'NC',
    latitude: '35.226802',
    longitude: '-79.791427',
    timeZoneId: 'America/New_York',
  },
  '27230': {
    zip: '27230',
    city: 'Cedar Falls',
    state: 'NC',
    latitude: '35.752858',
    longitude: '-79.731626',
    timeZoneId: 'America/New_York',
  },
  '27231': {
    zip: '27231',
    city: 'Cedar Grove',
    state: 'NC',
    latitude: '36.192187',
    longitude: '-79.165321',
    timeZoneId: 'America/New_York',
  },
  '27233': {
    zip: '27233',
    city: 'Climax',
    state: 'NC',
    latitude: '35.93224',
    longitude: '-79.699061',
    timeZoneId: 'America/New_York',
  },
  '27235': {
    zip: '27235',
    city: 'Colfax',
    state: 'NC',
    latitude: '36.09911',
    longitude: '-80.009479',
    timeZoneId: 'America/New_York',
  },
  '27237': {
    zip: '27237',
    city: 'Cumnock',
    state: 'NC',
    latitude: '35.554722',
    longitude: '-79.238056',
    timeZoneId: 'America/New_York',
  },
  '27239': {
    zip: '27239',
    city: 'Denton',
    state: 'NC',
    latitude: '35.61756',
    longitude: '-80.11092',
    timeZoneId: 'America/New_York',
  },
  '27242': {
    zip: '27242',
    city: 'Eagle Springs',
    state: 'NC',
    latitude: '35.331358',
    longitude: '-79.629491',
    timeZoneId: 'America/New_York',
  },
  '27243': {
    zip: '27243',
    city: 'Efland',
    state: 'NC',
    latitude: '36.069418',
    longitude: '-79.20442',
    timeZoneId: 'America/New_York',
  },
  '27244': {
    zip: '27244',
    city: 'Elon',
    state: 'NC',
    latitude: '36.135017',
    longitude: '-79.505282',
    timeZoneId: 'America/New_York',
  },
  '27247': {
    zip: '27247',
    city: 'Ether',
    state: 'NC',
    latitude: '35.430675',
    longitude: '-79.787081',
    timeZoneId: 'America/New_York',
  },
  '27248': {
    zip: '27248',
    city: 'Franklinville',
    state: 'NC',
    latitude: '35.749129',
    longitude: '-79.701339',
    timeZoneId: 'America/New_York',
  },
  '27249': {
    zip: '27249',
    city: 'Gibsonville',
    state: 'NC',
    latitude: '36.127366',
    longitude: '-79.587855',
    timeZoneId: 'America/New_York',
  },
  '27252': {
    zip: '27252',
    city: 'Goldston',
    state: 'NC',
    latitude: '35.554892',
    longitude: '-79.346266',
    timeZoneId: 'America/New_York',
  },
  '27253': {
    zip: '27253',
    city: 'Graham',
    state: 'NC',
    latitude: '36.023611',
    longitude: '-79.366835',
    timeZoneId: 'America/New_York',
  },
  '27256': {
    zip: '27256',
    city: 'Gulf',
    state: 'NC',
    latitude: '35.562337',
    longitude: '-79.279304',
    timeZoneId: 'America/New_York',
  },
  '27258': {
    zip: '27258',
    city: 'Haw River',
    state: 'NC',
    latitude: '36.061861',
    longitude: '-79.339937',
    timeZoneId: 'America/New_York',
  },
  '27259': {
    zip: '27259',
    city: 'Highfalls',
    state: 'NC',
    latitude: '35.460991',
    longitude: '-79.57031',
    timeZoneId: 'America/New_York',
  },
  '27260': {
    zip: '27260',
    city: 'High Point',
    state: 'NC',
    latitude: '35.968678',
    longitude: '-80.000592',
    timeZoneId: 'America/New_York',
  },
  '27261': {
    zip: '27261',
    city: 'High Point',
    state: 'NC',
    latitude: '35.975341',
    longitude: '-79.999493',
    timeZoneId: 'America/New_York',
  },
  '27262': {
    zip: '27262',
    city: 'High Point',
    state: 'NC',
    latitude: '35.95602',
    longitude: '-80.04072',
    timeZoneId: 'America/New_York',
  },
  '27263': {
    zip: '27263',
    city: 'High Point',
    state: 'NC',
    latitude: '35.90802',
    longitude: '-79.98012',
    timeZoneId: 'America/New_York',
  },
  '27264': {
    zip: '27264',
    city: 'High Point',
    state: 'NC',
    latitude: '35.980506',
    longitude: '-79.995827',
    timeZoneId: 'America/New_York',
  },
  '27265': {
    zip: '27265',
    city: 'High Point',
    state: 'NC',
    latitude: '36.011602',
    longitude: '-80.001458',
    timeZoneId: 'America/New_York',
  },
  '27268': {
    zip: '27268',
    city: 'High Point',
    state: 'NC',
    latitude: '36.011602',
    longitude: '-80.001458',
    timeZoneId: 'America/New_York',
  },
  '27278': {
    zip: '27278',
    city: 'Hillsborough',
    state: 'NC',
    latitude: '36.07458',
    longitude: '-79.07766',
    timeZoneId: 'America/New_York',
  },
  '27281': {
    zip: '27281',
    city: 'Jackson Springs',
    state: 'NC',
    latitude: '35.2203',
    longitude: '-79.61082',
    timeZoneId: 'America/New_York',
  },
  '27282': {
    zip: '27282',
    city: 'Jamestown',
    state: 'NC',
    latitude: '35.999796',
    longitude: '-79.93165',
    timeZoneId: 'America/New_York',
  },
  '27283': {
    zip: '27283',
    city: 'Julian',
    state: 'NC',
    latitude: '35.954535',
    longitude: '-79.637459',
    timeZoneId: 'America/New_York',
  },
  '27284': {
    zip: '27284',
    city: 'Kernersville',
    state: 'NC',
    latitude: '36.11629',
    longitude: '-80.092145',
    timeZoneId: 'America/New_York',
  },
  '27285': {
    zip: '27285',
    city: 'Kernersville',
    state: 'NC',
    latitude: '36.11372',
    longitude: '-80.077061',
    timeZoneId: 'America/New_York',
  },
  '27288': {
    zip: '27288',
    city: 'Eden',
    state: 'NC',
    latitude: '36.48372',
    longitude: '-79.79136',
    timeZoneId: 'America/New_York',
  },
  '27289': {
    zip: '27289',
    city: 'Eden',
    state: 'NC',
    latitude: '36.50439',
    longitude: '-79.741267',
    timeZoneId: 'America/New_York',
  },
  '27291': {
    zip: '27291',
    city: 'Leasburg',
    state: 'NC',
    latitude: '36.416444',
    longitude: '-79.191618',
    timeZoneId: 'America/New_York',
  },
  '27292': {
    zip: '27292',
    city: 'Lexington',
    state: 'NC',
    latitude: '35.8257',
    longitude: '-80.27766',
    timeZoneId: 'America/New_York',
  },
  '27293': {
    zip: '27293',
    city: 'Lexington',
    state: 'NC',
    latitude: '35.859259',
    longitude: '-80.257858',
    timeZoneId: 'America/New_York',
  },
  '27294': {
    zip: '27294',
    city: 'Lexington',
    state: 'NC',
    latitude: '35.821157',
    longitude: '-80.250582',
    timeZoneId: 'America/New_York',
  },
  '27295': {
    zip: '27295',
    city: 'Lexington',
    state: 'NC',
    latitude: '35.809988',
    longitude: '-80.258118',
    timeZoneId: 'America/New_York',
  },
  '27298': {
    zip: '27298',
    city: 'Liberty',
    state: 'NC',
    latitude: '35.896446',
    longitude: '-79.506774',
    timeZoneId: 'America/New_York',
  },
  '27299': {
    zip: '27299',
    city: 'Linwood',
    state: 'NC',
    latitude: '35.789855',
    longitude: '-80.37954',
    timeZoneId: 'America/New_York',
  },
  '27301': {
    zip: '27301',
    city: 'Mc Leansville',
    state: 'NC',
    latitude: '36.113388',
    longitude: '-79.673916',
    timeZoneId: 'America/New_York',
  },
  '27302': {
    zip: '27302',
    city: 'Mebane',
    state: 'NC',
    latitude: '36.089915',
    longitude: '-79.285944',
    timeZoneId: 'America/New_York',
  },
  '27305': {
    zip: '27305',
    city: 'Milton',
    state: 'NC',
    latitude: '36.514063',
    longitude: '-79.220757',
    timeZoneId: 'America/New_York',
  },
  '27306': {
    zip: '27306',
    city: 'Mount Gilead',
    state: 'NC',
    latitude: '35.26944',
    longitude: '-79.974',
    timeZoneId: 'America/New_York',
  },
  '27310': {
    zip: '27310',
    city: 'Oak Ridge',
    state: 'NC',
    latitude: '36.165515',
    longitude: '-79.983154',
    timeZoneId: 'America/New_York',
  },
  '27311': {
    zip: '27311',
    city: 'Pelham',
    state: 'NC',
    latitude: '36.477171',
    longitude: '-79.47129',
    timeZoneId: 'America/New_York',
  },
  '27312': {
    zip: '27312',
    city: 'Pittsboro',
    state: 'NC',
    latitude: '35.765336',
    longitude: '-79.136415',
    timeZoneId: 'America/New_York',
  },
  '27313': {
    zip: '27313',
    city: 'Pleasant Garden',
    state: 'NC',
    latitude: '35.952898',
    longitude: '-79.757191',
    timeZoneId: 'America/New_York',
  },
  '27314': {
    zip: '27314',
    city: 'Prospect Hill',
    state: 'NC',
    latitude: '36.29346',
    longitude: '-79.197593',
    timeZoneId: 'America/New_York',
  },
  '27315': {
    zip: '27315',
    city: 'Providence',
    state: 'NC',
    latitude: '36.516305',
    longitude: '-79.428083',
    timeZoneId: 'America/New_York',
  },
  '27316': {
    zip: '27316',
    city: 'Ramseur',
    state: 'NC',
    latitude: '35.70312',
    longitude: '-79.6242',
    timeZoneId: 'America/New_York',
  },
  '27317': {
    zip: '27317',
    city: 'Randleman',
    state: 'NC',
    latitude: '35.84166',
    longitude: '-79.81602',
    timeZoneId: 'America/New_York',
  },
  '27320': {
    zip: '27320',
    city: 'Reidsville',
    state: 'NC',
    latitude: '36.3528',
    longitude: '-79.6182',
    timeZoneId: 'America/New_York',
  },
  '27323': {
    zip: '27323',
    city: 'Reidsville',
    state: 'NC',
    latitude: '36.315993',
    longitude: '-79.658545',
    timeZoneId: 'America/New_York',
  },
  '27325': {
    zip: '27325',
    city: 'Robbins',
    state: 'NC',
    latitude: '35.429683',
    longitude: '-79.581817',
    timeZoneId: 'America/New_York',
  },
  '27326': {
    zip: '27326',
    city: 'Ruffin',
    state: 'NC',
    latitude: '36.4491',
    longitude: '-79.58946',
    timeZoneId: 'America/New_York',
  },
  '27330': {
    zip: '27330',
    city: 'Sanford',
    state: 'NC',
    latitude: '35.459277',
    longitude: '-79.168611',
    timeZoneId: 'America/New_York',
  },
  '27331': {
    zip: '27331',
    city: 'Sanford',
    state: 'NC',
    latitude: '35.474935',
    longitude: '-79.182583',
    timeZoneId: 'America/New_York',
  },
  '27332': {
    zip: '27332',
    city: 'Sanford',
    state: 'NC',
    latitude: '35.462395',
    longitude: '-79.172874',
    timeZoneId: 'America/New_York',
  },
  '27340': {
    zip: '27340',
    city: 'Saxapahaw',
    state: 'NC',
    latitude: '35.950403',
    longitude: '-79.319706',
    timeZoneId: 'America/New_York',
  },
  '27341': {
    zip: '27341',
    city: 'Seagrove',
    state: 'NC',
    latitude: '35.398446',
    longitude: '-79.783082',
    timeZoneId: 'America/New_York',
  },
  '27342': {
    zip: '27342',
    city: 'Sedalia',
    state: 'NC',
    latitude: '36.077179',
    longitude: '-79.624155',
    timeZoneId: 'America/New_York',
  },
  '27343': {
    zip: '27343',
    city: 'Semora',
    state: 'NC',
    latitude: '36.513121',
    longitude: '-79.083162',
    timeZoneId: 'America/New_York',
  },
  '27344': {
    zip: '27344',
    city: 'Siler City',
    state: 'NC',
    latitude: '35.72346',
    longitude: '-79.42542',
    timeZoneId: 'America/New_York',
  },
  '27349': {
    zip: '27349',
    city: 'Snow Camp',
    state: 'NC',
    latitude: '35.915033',
    longitude: '-79.427355',
    timeZoneId: 'America/New_York',
  },
  '27350': {
    zip: '27350',
    city: 'Sophia',
    state: 'NC',
    latitude: '35.817055',
    longitude: '-79.892763',
    timeZoneId: 'America/New_York',
  },
  '27351': {
    zip: '27351',
    city: 'Southmont',
    state: 'NC',
    latitude: '35.670001',
    longitude: '-80.268009',
    timeZoneId: 'America/New_York',
  },
  '27355': {
    zip: '27355',
    city: 'Staley',
    state: 'NC',
    latitude: '35.805737',
    longitude: '-79.536595',
    timeZoneId: 'America/New_York',
  },
  '27356': {
    zip: '27356',
    city: 'Star',
    state: 'NC',
    latitude: '35.397694',
    longitude: '-79.785044',
    timeZoneId: 'America/New_York',
  },
  '27357': {
    zip: '27357',
    city: 'Stokesdale',
    state: 'NC',
    latitude: '36.239862',
    longitude: '-79.981948',
    timeZoneId: 'America/New_York',
  },
  '27358': {
    zip: '27358',
    city: 'Summerfield',
    state: 'NC',
    latitude: '36.202103',
    longitude: '-79.89612',
    timeZoneId: 'America/New_York',
  },
  '27359': {
    zip: '27359',
    city: 'Swepsonville',
    state: 'NC',
    latitude: '36.034143',
    longitude: '-79.368377',
    timeZoneId: 'America/New_York',
  },
  '27360': {
    zip: '27360',
    city: 'Thomasville',
    state: 'NC',
    latitude: '35.880803',
    longitude: '-80.083016',
    timeZoneId: 'America/New_York',
  },
  '27361': {
    zip: '27361',
    city: 'Thomasville',
    state: 'NC',
    latitude: '35.876008',
    longitude: '-80.099679',
    timeZoneId: 'America/New_York',
  },
  '27370': {
    zip: '27370',
    city: 'Trinity',
    state: 'NC',
    latitude: '35.868794',
    longitude: '-79.948733',
    timeZoneId: 'America/New_York',
  },
  '27371': {
    zip: '27371',
    city: 'Troy',
    state: 'NC',
    latitude: '35.36766',
    longitude: '-79.85814',
    timeZoneId: 'America/New_York',
  },
  '27373': {
    zip: '27373',
    city: 'Wallburg',
    state: 'NC',
    latitude: '36.012769',
    longitude: '-80.139199',
    timeZoneId: 'America/New_York',
  },
  '27374': {
    zip: '27374',
    city: 'Welcome',
    state: 'NC',
    latitude: '35.907696',
    longitude: '-80.254417',
    timeZoneId: 'America/New_York',
  },
  '27375': {
    zip: '27375',
    city: 'Wentworth',
    state: 'NC',
    latitude: '36.336122',
    longitude: '-79.653008',
    timeZoneId: 'America/New_York',
  },
  '27376': {
    zip: '27376',
    city: 'West End',
    state: 'NC',
    latitude: '35.24964',
    longitude: '-79.52916',
    timeZoneId: 'America/New_York',
  },
  '27377': {
    zip: '27377',
    city: 'Whitsett',
    state: 'NC',
    latitude: '36.035608',
    longitude: '-79.602646',
    timeZoneId: 'America/New_York',
  },
  '27379': {
    zip: '27379',
    city: 'Yanceyville',
    state: 'NC',
    latitude: '36.404814',
    longitude: '-79.335765',
    timeZoneId: 'America/New_York',
  },
  '27401': {
    zip: '27401',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.068126',
    longitude: '-79.770814',
    timeZoneId: 'America/New_York',
  },
  '27402': {
    zip: '27402',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.071757',
    longitude: '-79.791027',
    timeZoneId: 'America/New_York',
  },
  '27403': {
    zip: '27403',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.06576',
    longitude: '-79.822871',
    timeZoneId: 'America/New_York',
  },
  '27404': {
    zip: '27404',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.084834',
    longitude: '-79.83277',
    timeZoneId: 'America/New_York',
  },
  '27405': {
    zip: '27405',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.11058',
    longitude: '-79.7406',
    timeZoneId: 'America/New_York',
  },
  '27406': {
    zip: '27406',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.021374',
    longitude: '-79.767916',
    timeZoneId: 'America/New_York',
  },
  '27407': {
    zip: '27407',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.01266',
    longitude: '-79.86996',
    timeZoneId: 'America/New_York',
  },
  '27408': {
    zip: '27408',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.106359',
    longitude: '-79.817365',
    timeZoneId: 'America/New_York',
  },
  '27409': {
    zip: '27409',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.093667',
    longitude: '-79.927798',
    timeZoneId: 'America/New_York',
  },
  '27410': {
    zip: '27410',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.098395',
    longitude: '-79.885035',
    timeZoneId: 'America/New_York',
  },
  '27411': {
    zip: '27411',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.074246',
    longitude: '-79.77639',
    timeZoneId: 'America/New_York',
  },
  '27412': {
    zip: '27412',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.065665',
    longitude: '-79.806545',
    timeZoneId: 'America/New_York',
  },
  '27413': {
    zip: '27413',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.068728',
    longitude: '-79.810276',
    timeZoneId: 'America/New_York',
  },
  '27415': {
    zip: '27415',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.078926',
    longitude: '-79.645808',
    timeZoneId: 'America/New_York',
  },
  '27416': {
    zip: '27416',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.002309',
    longitude: '-79.764089',
    timeZoneId: 'America/New_York',
  },
  '27417': {
    zip: '27417',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.067592',
    longitude: '-79.952645',
    timeZoneId: 'America/New_York',
  },
  '27419': {
    zip: '27419',
    city: 'Greensboro',
    state: 'NC',
    latitude: '35.979131',
    longitude: '-79.708835',
    timeZoneId: 'America/New_York',
  },
  '27420': {
    zip: '27420',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.064672',
    longitude: '-79.843907',
    timeZoneId: 'America/New_York',
  },
  '27425': {
    zip: '27425',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.127293',
    longitude: '-79.942762',
    timeZoneId: 'America/New_York',
  },
  '27427': {
    zip: '27427',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.068902',
    longitude: '-79.766618',
    timeZoneId: 'America/New_York',
  },
  '27429': {
    zip: '27429',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.093454',
    longitude: '-79.812745',
    timeZoneId: 'America/New_York',
  },
  '27435': {
    zip: '27435',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.064515',
    longitude: '-79.826975',
    timeZoneId: 'America/New_York',
  },
  '27438': {
    zip: '27438',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.055746',
    longitude: '-79.804258',
    timeZoneId: 'America/New_York',
  },
  '27455': {
    zip: '27455',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.155085',
    longitude: '-79.814163',
    timeZoneId: 'America/New_York',
  },
  '27495': {
    zip: '27495',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.108616',
    longitude: '-79.737907',
    timeZoneId: 'America/New_York',
  },
  '27497': {
    zip: '27497',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.0442',
    longitude: '-79.8599',
    timeZoneId: 'America/New_York',
  },
  '27498': {
    zip: '27498',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.072005',
    longitude: '-79.767906',
    timeZoneId: 'America/New_York',
  },
  '27499': {
    zip: '27499',
    city: 'Greensboro',
    state: 'NC',
    latitude: '36.070576',
    longitude: '-79.766148',
    timeZoneId: 'America/New_York',
  },
  '27501': {
    zip: '27501',
    city: 'Angier',
    state: 'NC',
    latitude: '35.510553',
    longitude: '-78.742398',
    timeZoneId: 'America/New_York',
  },
  '27502': {
    zip: '27502',
    city: 'Apex',
    state: 'NC',
    latitude: '35.711653',
    longitude: '-78.81965',
    timeZoneId: 'America/New_York',
  },
  '27503': {
    zip: '27503',
    city: 'Bahama',
    state: 'NC',
    latitude: '36.157869',
    longitude: '-78.899222',
    timeZoneId: 'America/New_York',
  },
  '27504': {
    zip: '27504',
    city: 'Benson',
    state: 'NC',
    latitude: '35.39415',
    longitude: '-78.54782',
    timeZoneId: 'America/New_York',
  },
  '27505': {
    zip: '27505',
    city: 'Broadway',
    state: 'NC',
    latitude: '35.459283',
    longitude: '-79.053129',
    timeZoneId: 'America/New_York',
  },
  '27506': {
    zip: '27506',
    city: 'Buies Creek',
    state: 'NC',
    latitude: '35.406789',
    longitude: '-78.738203',
    timeZoneId: 'America/New_York',
  },
  '27507': {
    zip: '27507',
    city: 'Bullock',
    state: 'NC',
    latitude: '36.361513',
    longitude: '-78.529433',
    timeZoneId: 'America/New_York',
  },
  '27508': {
    zip: '27508',
    city: 'Bunn',
    state: 'NC',
    latitude: '35.96854',
    longitude: '-78.194646',
    timeZoneId: 'America/New_York',
  },
  '27509': {
    zip: '27509',
    city: 'Butner',
    state: 'NC',
    latitude: '36.138357',
    longitude: '-78.757582',
    timeZoneId: 'America/New_York',
  },
  '27510': {
    zip: '27510',
    city: 'Carrboro',
    state: 'NC',
    latitude: '35.915484',
    longitude: '-79.078537',
    timeZoneId: 'America/New_York',
  },
  '27511': {
    zip: '27511',
    city: 'Cary',
    state: 'NC',
    latitude: '35.758852',
    longitude: '-78.780766',
    timeZoneId: 'America/New_York',
  },
  '27512': {
    zip: '27512',
    city: 'Cary',
    state: 'NC',
    latitude: '35.76797',
    longitude: '-78.798984',
    timeZoneId: 'America/New_York',
  },
  '27513': {
    zip: '27513',
    city: 'Cary',
    state: 'NC',
    latitude: '35.80541',
    longitude: '-78.797679',
    timeZoneId: 'America/New_York',
  },
  '27514': {
    zip: '27514',
    city: 'Chapel Hill',
    state: 'NC',
    latitude: '35.934949',
    longitude: '-79.037474',
    timeZoneId: 'America/New_York',
  },
  '27515': {
    zip: '27515',
    city: 'Chapel Hill',
    state: 'NC',
    latitude: '35.910217',
    longitude: '-79.054608',
    timeZoneId: 'America/New_York',
  },
  '27516': {
    zip: '27516',
    city: 'Chapel Hill',
    state: 'NC',
    latitude: '35.917785',
    longitude: '-79.121025',
    timeZoneId: 'America/New_York',
  },
  '27517': {
    zip: '27517',
    city: 'Chapel Hill',
    state: 'NC',
    latitude: '35.938902',
    longitude: '-79.040688',
    timeZoneId: 'America/New_York',
  },
  '27518': {
    zip: '27518',
    city: 'Cary',
    state: 'NC',
    latitude: '35.756619',
    longitude: '-78.78153',
    timeZoneId: 'America/New_York',
  },
  '27519': {
    zip: '27519',
    city: 'Cary',
    state: 'NC',
    latitude: '35.785409',
    longitude: '-78.779645',
    timeZoneId: 'America/New_York',
  },
  '27520': {
    zip: '27520',
    city: 'Clayton',
    state: 'NC',
    latitude: '35.636282',
    longitude: '-78.444815',
    timeZoneId: 'America/New_York',
  },
  '27521': {
    zip: '27521',
    city: 'Coats',
    state: 'NC',
    latitude: '35.410283',
    longitude: '-78.672574',
    timeZoneId: 'America/New_York',
  },
  '27522': {
    zip: '27522',
    city: 'Creedmoor',
    state: 'NC',
    latitude: '36.120342',
    longitude: '-78.690685',
    timeZoneId: 'America/New_York',
  },
  '27523': {
    zip: '27523',
    city: 'Apex',
    state: 'NC',
    latitude: '35.713062',
    longitude: '-78.823781',
    timeZoneId: 'America/New_York',
  },
  '27524': {
    zip: '27524',
    city: 'Four Oaks',
    state: 'NC',
    latitude: '35.448202',
    longitude: '-78.425508',
    timeZoneId: 'America/New_York',
  },
  '27525': {
    zip: '27525',
    city: 'Franklinton',
    state: 'NC',
    latitude: '36.096252',
    longitude: '-78.452456',
    timeZoneId: 'America/New_York',
  },
  '27526': {
    zip: '27526',
    city: 'Fuquay Varina',
    state: 'NC',
    latitude: '35.59854',
    longitude: '-78.78432',
    timeZoneId: 'America/New_York',
  },
  '27527': {
    zip: '27527',
    city: 'Clayton',
    state: 'NC',
    latitude: '35.650556',
    longitude: '-78.456667',
    timeZoneId: 'America/New_York',
  },
  '27528': {
    zip: '27528',
    city: 'Clayton',
    state: 'NC',
    latitude: '35.636854',
    longitude: '-78.447613',
    timeZoneId: 'America/New_York',
  },
  '27529': {
    zip: '27529',
    city: 'Garner',
    state: 'NC',
    latitude: '35.684813',
    longitude: '-78.60826',
    timeZoneId: 'America/New_York',
  },
  '27530': {
    zip: '27530',
    city: 'Goldsboro',
    state: 'NC',
    latitude: '35.37996',
    longitude: '-78.09786',
    timeZoneId: 'America/New_York',
  },
  '27531': {
    zip: '27531',
    city: 'Goldsboro',
    state: 'NC',
    latitude: '35.38',
    longitude: '-77.99',
    timeZoneId: 'America/New_York',
  },
  '27532': {
    zip: '27532',
    city: 'Goldsboro',
    state: 'NC',
    latitude: '35.381183',
    longitude: '-77.953396',
    timeZoneId: 'America/New_York',
  },
  '27533': {
    zip: '27533',
    city: 'Goldsboro',
    state: 'NC',
    latitude: '35.383574',
    longitude: '-77.951066',
    timeZoneId: 'America/New_York',
  },
  '27534': {
    zip: '27534',
    city: 'Goldsboro',
    state: 'NC',
    latitude: '35.370546',
    longitude: '-77.930853',
    timeZoneId: 'America/New_York',
  },
  '27536': {
    zip: '27536',
    city: 'Henderson',
    state: 'NC',
    latitude: '36.32869',
    longitude: '-78.404636',
    timeZoneId: 'America/New_York',
  },
  '27537': {
    zip: '27537',
    city: 'Henderson',
    state: 'NC',
    latitude: '36.326401',
    longitude: '-78.405647',
    timeZoneId: 'America/New_York',
  },
  '27539': {
    zip: '27539',
    city: 'Apex',
    state: 'NC',
    latitude: '35.712518',
    longitude: '-78.823489',
    timeZoneId: 'America/New_York',
  },
  '27540': {
    zip: '27540',
    city: 'Holly Springs',
    state: 'NC',
    latitude: '35.623511',
    longitude: '-78.834978',
    timeZoneId: 'America/New_York',
  },
  '27541': {
    zip: '27541',
    city: 'Hurdle Mills',
    state: 'NC',
    latitude: '36.2026',
    longitude: '-79.074166',
    timeZoneId: 'America/New_York',
  },
  '27542': {
    zip: '27542',
    city: 'Kenly',
    state: 'NC',
    latitude: '35.590858',
    longitude: '-78.122283',
    timeZoneId: 'America/New_York',
  },
  '27543': {
    zip: '27543',
    city: 'Kipling',
    state: 'NC',
    latitude: '35.481055',
    longitude: '-78.821646',
    timeZoneId: 'America/New_York',
  },
  '27544': {
    zip: '27544',
    city: 'Kittrell',
    state: 'NC',
    latitude: '36.22316',
    longitude: '-78.440172',
    timeZoneId: 'America/New_York',
  },
  '27545': {
    zip: '27545',
    city: 'Knightdale',
    state: 'NC',
    latitude: '35.787687',
    longitude: '-78.483413',
    timeZoneId: 'America/New_York',
  },
  '27546': {
    zip: '27546',
    city: 'Lillington',
    state: 'NC',
    latitude: '35.397219',
    longitude: '-78.813484',
    timeZoneId: 'America/New_York',
  },
  '27549': {
    zip: '27549',
    city: 'Louisburg',
    state: 'NC',
    latitude: '36.089778',
    longitude: '-78.299777',
    timeZoneId: 'America/New_York',
  },
  '27551': {
    zip: '27551',
    city: 'Macon',
    state: 'NC',
    latitude: '36.434413',
    longitude: '-78.002225',
    timeZoneId: 'America/New_York',
  },
  '27552': {
    zip: '27552',
    city: 'Mamers',
    state: 'NC',
    latitude: '35.42006',
    longitude: '-78.934329',
    timeZoneId: 'America/New_York',
  },
  '27553': {
    zip: '27553',
    city: 'Manson',
    state: 'NC',
    latitude: '36.46878',
    longitude: '-78.29238',
    timeZoneId: 'America/New_York',
  },
  '27555': {
    zip: '27555',
    city: 'Micro',
    state: 'NC',
    latitude: '35.563044',
    longitude: '-78.205283',
    timeZoneId: 'America/New_York',
  },
  '27556': {
    zip: '27556',
    city: 'Middleburg',
    state: 'NC',
    latitude: '36.35508',
    longitude: '-78.399025',
    timeZoneId: 'America/New_York',
  },
  '27557': {
    zip: '27557',
    city: 'Middlesex',
    state: 'NC',
    latitude: '35.791243',
    longitude: '-78.189798',
    timeZoneId: 'America/New_York',
  },
  '27559': {
    zip: '27559',
    city: 'Moncure',
    state: 'NC',
    latitude: '35.671783',
    longitude: '-79.17059',
    timeZoneId: 'America/New_York',
  },
  '27560': {
    zip: '27560',
    city: 'Morrisville',
    state: 'NC',
    latitude: '35.825795',
    longitude: '-78.836037',
    timeZoneId: 'America/New_York',
  },
  '27562': {
    zip: '27562',
    city: 'New Hill',
    state: 'NC',
    latitude: '35.697919',
    longitude: '-78.934428',
    timeZoneId: 'America/New_York',
  },
  '27563': {
    zip: '27563',
    city: 'Norlina',
    state: 'NC',
    latitude: '36.445548',
    longitude: '-78.194509',
    timeZoneId: 'America/New_York',
  },
  '27565': {
    zip: '27565',
    city: 'Oxford',
    state: 'NC',
    latitude: '36.309269',
    longitude: '-78.591202',
    timeZoneId: 'America/New_York',
  },
  '27568': {
    zip: '27568',
    city: 'Pine Level',
    state: 'NC',
    latitude: '35.50587',
    longitude: '-78.243484',
    timeZoneId: 'America/New_York',
  },
  '27569': {
    zip: '27569',
    city: 'Princeton',
    state: 'NC',
    latitude: '35.464515',
    longitude: '-78.162907',
    timeZoneId: 'America/New_York',
  },
  '27570': {
    zip: '27570',
    city: 'Ridgeway',
    state: 'NC',
    latitude: '36.433158',
    longitude: '-78.236769',
    timeZoneId: 'America/New_York',
  },
  '27571': {
    zip: '27571',
    city: 'Rolesville',
    state: 'NC',
    latitude: '35.923393',
    longitude: '-78.456423',
    timeZoneId: 'America/New_York',
  },
  '27572': {
    zip: '27572',
    city: 'Rougemont',
    state: 'NC',
    latitude: '36.196439',
    longitude: '-79.007426',
    timeZoneId: 'America/New_York',
  },
  '27573': {
    zip: '27573',
    city: 'Roxboro',
    state: 'NC',
    latitude: '36.393107',
    longitude: '-78.985483',
    timeZoneId: 'America/New_York',
  },
  '27574': {
    zip: '27574',
    city: 'Roxboro',
    state: 'NC',
    latitude: '36.391284',
    longitude: '-78.989423',
    timeZoneId: 'America/New_York',
  },
  '27576': {
    zip: '27576',
    city: 'Selma',
    state: 'NC',
    latitude: '35.541368',
    longitude: '-78.284629',
    timeZoneId: 'America/New_York',
  },
  '27577': {
    zip: '27577',
    city: 'Smithfield',
    state: 'NC',
    latitude: '35.512172',
    longitude: '-78.347308',
    timeZoneId: 'America/New_York',
  },
  '27581': {
    zip: '27581',
    city: 'Stem',
    state: 'NC',
    latitude: '36.200116',
    longitude: '-78.724476',
    timeZoneId: 'America/New_York',
  },
  '27582': {
    zip: '27582',
    city: 'Stovall',
    state: 'NC',
    latitude: '36.448628',
    longitude: '-78.569712',
    timeZoneId: 'America/New_York',
  },
  '27583': {
    zip: '27583',
    city: 'Timberlake',
    state: 'NC',
    latitude: '36.23463',
    longitude: '-78.965713',
    timeZoneId: 'America/New_York',
  },
  '27584': {
    zip: '27584',
    city: 'Townsville',
    state: 'NC',
    latitude: '36.496145',
    longitude: '-78.423856',
    timeZoneId: 'America/New_York',
  },
  '27586': {
    zip: '27586',
    city: 'Vaughan',
    state: 'NC',
    latitude: '36.426794',
    longitude: '-78.004293',
    timeZoneId: 'America/New_York',
  },
  '27587': {
    zip: '27587',
    city: 'Wake Forest',
    state: 'NC',
    latitude: '35.963417',
    longitude: '-78.51658',
    timeZoneId: 'America/New_York',
  },
  '27588': {
    zip: '27588',
    city: 'Wake Forest',
    state: 'NC',
    latitude: '35.967355',
    longitude: '-78.517816',
    timeZoneId: 'America/New_York',
  },
  '27589': {
    zip: '27589',
    city: 'Warrenton',
    state: 'NC',
    latitude: '36.39716',
    longitude: '-78.151699',
    timeZoneId: 'America/New_York',
  },
  '27591': {
    zip: '27591',
    city: 'Wendell',
    state: 'NC',
    latitude: '35.792984',
    longitude: '-78.387755',
    timeZoneId: 'America/New_York',
  },
  '27592': {
    zip: '27592',
    city: 'Willow Spring',
    state: 'NC',
    latitude: '35.568335',
    longitude: '-78.70681',
    timeZoneId: 'America/New_York',
  },
  '27593': {
    zip: '27593',
    city: 'Wilsons Mills',
    state: 'NC',
    latitude: '35.604395',
    longitude: '-78.341248',
    timeZoneId: 'America/New_York',
  },
  '27594': {
    zip: '27594',
    city: 'Wise',
    state: 'NC',
    latitude: '36.485467',
    longitude: '-78.171513',
    timeZoneId: 'America/New_York',
  },
  '27596': {
    zip: '27596',
    city: 'Youngsville',
    state: 'NC',
    latitude: '36.025816',
    longitude: '-78.467462',
    timeZoneId: 'America/New_York',
  },
  '27597': {
    zip: '27597',
    city: 'Zebulon',
    state: 'NC',
    latitude: '35.845905',
    longitude: '-78.338439',
    timeZoneId: 'America/New_York',
  },
  '27599': {
    zip: '27599',
    city: 'Chapel Hill',
    state: 'NC',
    latitude: '35.928939',
    longitude: '-79.040693',
    timeZoneId: 'America/New_York',
  },
  '27601': {
    zip: '27601',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.772749',
    longitude: '-78.635142',
    timeZoneId: 'America/New_York',
  },
  '27602': {
    zip: '27602',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.747883',
    longitude: '-78.637225',
    timeZoneId: 'America/New_York',
  },
  '27603': {
    zip: '27603',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.714307',
    longitude: '-78.660593',
    timeZoneId: 'America/New_York',
  },
  '27604': {
    zip: '27604',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.86662',
    longitude: '-78.5319',
    timeZoneId: 'America/New_York',
  },
  '27605': {
    zip: '27605',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.790341',
    longitude: '-78.653956',
    timeZoneId: 'America/New_York',
  },
  '27606': {
    zip: '27606',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.754707',
    longitude: '-78.722559',
    timeZoneId: 'America/New_York',
  },
  '27607': {
    zip: '27607',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.804021',
    longitude: '-78.697656',
    timeZoneId: 'America/New_York',
  },
  '27608': {
    zip: '27608',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.81003',
    longitude: '-78.645013',
    timeZoneId: 'America/New_York',
  },
  '27609': {
    zip: '27609',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.84172',
    longitude: '-78.63252',
    timeZoneId: 'America/New_York',
  },
  '27610': {
    zip: '27610',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.761643',
    longitude: '-78.583787',
    timeZoneId: 'America/New_York',
  },
  '27611': {
    zip: '27611',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.720263',
    longitude: '-78.733806',
    timeZoneId: 'America/New_York',
  },
  '27612': {
    zip: '27612',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.856914',
    longitude: '-78.689606',
    timeZoneId: 'America/New_York',
  },
  '27613': {
    zip: '27613',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.902051',
    longitude: '-78.707118',
    timeZoneId: 'America/New_York',
  },
  '27614': {
    zip: '27614',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.938157',
    longitude: '-78.631847',
    timeZoneId: 'America/New_York',
  },
  '27615': {
    zip: '27615',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.889314',
    longitude: '-78.637839',
    timeZoneId: 'America/New_York',
  },
  '27616': {
    zip: '27616',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.863033',
    longitude: '-78.535615',
    timeZoneId: 'America/New_York',
  },
  '27617': {
    zip: '27617',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.877636',
    longitude: '-78.743481',
    timeZoneId: 'America/New_York',
  },
  '27619': {
    zip: '27619',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.854221',
    longitude: '-78.628804',
    timeZoneId: 'America/New_York',
  },
  '27620': {
    zip: '27620',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.765078',
    longitude: '-78.587276',
    timeZoneId: 'America/New_York',
  },
  '27621': {
    zip: '27621',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.774204',
    longitude: '-78.634732',
    timeZoneId: 'America/New_York',
  },
  '27622': {
    zip: '27622',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.850683',
    longitude: '-78.692667',
    timeZoneId: 'America/New_York',
  },
  '27623': {
    zip: '27623',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.859066',
    longitude: '-78.790232',
    timeZoneId: 'America/New_York',
  },
  '27624': {
    zip: '27624',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.845056',
    longitude: '-78.634253',
    timeZoneId: 'America/New_York',
  },
  '27625': {
    zip: '27625',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.821341',
    longitude: '-78.57084',
    timeZoneId: 'America/New_York',
  },
  '27626': {
    zip: '27626',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.777685',
    longitude: '-78.634524',
    timeZoneId: 'America/New_York',
  },
  '27627': {
    zip: '27627',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.772008',
    longitude: '-78.713778',
    timeZoneId: 'America/New_York',
  },
  '27628': {
    zip: '27628',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.804185',
    longitude: '-78.64556',
    timeZoneId: 'America/New_York',
  },
  '27629': {
    zip: '27629',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.819644',
    longitude: '-78.572747',
    timeZoneId: 'America/New_York',
  },
  '27634': {
    zip: '27634',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.779441',
    longitude: '-78.638863',
    timeZoneId: 'America/New_York',
  },
  '27635': {
    zip: '27635',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.817935',
    longitude: '-78.572329',
    timeZoneId: 'America/New_York',
  },
  '27636': {
    zip: '27636',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.792686',
    longitude: '-78.691052',
    timeZoneId: 'America/New_York',
  },
  '27640': {
    zip: '27640',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.786086',
    longitude: '-78.638689',
    timeZoneId: 'America/New_York',
  },
  '27650': {
    zip: '27650',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.787729',
    longitude: '-78.662621',
    timeZoneId: 'America/New_York',
  },
  '27656': {
    zip: '27656',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.848805',
    longitude: '-78.692264',
    timeZoneId: 'America/New_York',
  },
  '27658': {
    zip: '27658',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.850975',
    longitude: '-78.593286',
    timeZoneId: 'America/New_York',
  },
  '27661': {
    zip: '27661',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.8974',
    longitude: '-78.561539',
    timeZoneId: 'America/New_York',
  },
  '27668': {
    zip: '27668',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.842959',
    longitude: '-78.634483',
    timeZoneId: 'America/New_York',
  },
  '27675': {
    zip: '27675',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.904343',
    longitude: '-78.742412',
    timeZoneId: 'America/New_York',
  },
  '27676': {
    zip: '27676',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.772367',
    longitude: '-78.634536',
    timeZoneId: 'America/New_York',
  },
  '27690': {
    zip: '27690',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.834281',
    longitude: '-78.666486',
    timeZoneId: 'America/New_York',
  },
  '27695': {
    zip: '27695',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.803047',
    longitude: '-78.713095',
    timeZoneId: 'America/New_York',
  },
  '27697': {
    zip: '27697',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.778849',
    longitude: '-78.623747',
    timeZoneId: 'America/New_York',
  },
  '27698': {
    zip: '27698',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.772821',
    longitude: '-78.639812',
    timeZoneId: 'America/New_York',
  },
  '27699': {
    zip: '27699',
    city: 'Raleigh',
    state: 'NC',
    latitude: '35.670641',
    longitude: '-78.663729',
    timeZoneId: 'America/New_York',
  },
  '27701': {
    zip: '27701',
    city: 'Durham',
    state: 'NC',
    latitude: '35.9989',
    longitude: '-78.899063',
    timeZoneId: 'America/New_York',
  },
  '27702': {
    zip: '27702',
    city: 'Durham',
    state: 'NC',
    latitude: '36.052515',
    longitude: '-78.879485',
    timeZoneId: 'America/New_York',
  },
  '27703': {
    zip: '27703',
    city: 'Durham',
    state: 'NC',
    latitude: '35.973771',
    longitude: '-78.838515',
    timeZoneId: 'America/New_York',
  },
  '27704': {
    zip: '27704',
    city: 'Durham',
    state: 'NC',
    latitude: '36.034161',
    longitude: '-78.869276',
    timeZoneId: 'America/New_York',
  },
  '27705': {
    zip: '27705',
    city: 'Durham',
    state: 'NC',
    latitude: '36.017812',
    longitude: '-78.948419',
    timeZoneId: 'America/New_York',
  },
  '27706': {
    zip: '27706',
    city: 'Durham',
    state: 'NC',
    latitude: '35.99683',
    longitude: '-78.937993',
    timeZoneId: 'America/New_York',
  },
  '27707': {
    zip: '27707',
    city: 'Durham',
    state: 'NC',
    latitude: '35.964557',
    longitude: '-78.940527',
    timeZoneId: 'America/New_York',
  },
  '27708': {
    zip: '27708',
    city: 'Durham',
    state: 'NC',
    latitude: '35.997189',
    longitude: '-78.938612',
    timeZoneId: 'America/New_York',
  },
  '27709': {
    zip: '27709',
    city: 'Durham',
    state: 'NC',
    latitude: '35.9138',
    longitude: '-78.848911',
    timeZoneId: 'America/New_York',
  },
  '27710': {
    zip: '27710',
    city: 'Durham',
    state: 'NC',
    latitude: '36.001767',
    longitude: '-78.940724',
    timeZoneId: 'America/New_York',
  },
  '27711': {
    zip: '27711',
    city: 'Durham',
    state: 'NC',
    latitude: '35.916201',
    longitude: '-78.867468',
    timeZoneId: 'America/New_York',
  },
  '27712': {
    zip: '27712',
    city: 'Durham',
    state: 'NC',
    latitude: '36.086887',
    longitude: '-78.923696',
    timeZoneId: 'America/New_York',
  },
  '27713': {
    zip: '27713',
    city: 'Durham',
    state: 'NC',
    latitude: '35.916567',
    longitude: '-78.917612',
    timeZoneId: 'America/New_York',
  },
  '27715': {
    zip: '27715',
    city: 'Durham',
    state: 'NC',
    latitude: '36.013683',
    longitude: '-78.959137',
    timeZoneId: 'America/New_York',
  },
  '27717': {
    zip: '27717',
    city: 'Durham',
    state: 'NC',
    latitude: '35.956235',
    longitude: '-78.95163',
    timeZoneId: 'America/New_York',
  },
  '27722': {
    zip: '27722',
    city: 'Durham',
    state: 'NC',
    latitude: '35.996965',
    longitude: '-78.899565',
    timeZoneId: 'America/New_York',
  },
  '27801': {
    zip: '27801',
    city: 'Rocky Mount',
    state: 'NC',
    latitude: '35.9541',
    longitude: '-77.75184',
    timeZoneId: 'America/New_York',
  },
  '27802': {
    zip: '27802',
    city: 'Rocky Mount',
    state: 'NC',
    latitude: '35.863709',
    longitude: '-77.84019',
    timeZoneId: 'America/New_York',
  },
  '27803': {
    zip: '27803',
    city: 'Rocky Mount',
    state: 'NC',
    latitude: '35.930266',
    longitude: '-77.833897',
    timeZoneId: 'America/New_York',
  },
  '27804': {
    zip: '27804',
    city: 'Rocky Mount',
    state: 'NC',
    latitude: '35.974314',
    longitude: '-77.831514',
    timeZoneId: 'America/New_York',
  },
  '27805': {
    zip: '27805',
    city: 'Aulander',
    state: 'NC',
    latitude: '36.10166',
    longitude: '-77.04393',
    timeZoneId: 'America/New_York',
  },
  '27806': {
    zip: '27806',
    city: 'Aurora',
    state: 'NC',
    latitude: '35.301628',
    longitude: '-76.789234',
    timeZoneId: 'America/New_York',
  },
  '27807': {
    zip: '27807',
    city: 'Bailey',
    state: 'NC',
    latitude: '35.781949',
    longitude: '-78.11279',
    timeZoneId: 'America/New_York',
  },
  '27808': {
    zip: '27808',
    city: 'Bath',
    state: 'NC',
    latitude: '35.475087',
    longitude: '-76.812065',
    timeZoneId: 'America/New_York',
  },
  '27809': {
    zip: '27809',
    city: 'Battleboro',
    state: 'NC',
    latitude: '36.048686',
    longitude: '-77.749014',
    timeZoneId: 'America/New_York',
  },
  '27810': {
    zip: '27810',
    city: 'Belhaven',
    state: 'NC',
    latitude: '35.540971',
    longitude: '-76.618888',
    timeZoneId: 'America/New_York',
  },
  '27811': {
    zip: '27811',
    city: 'Bellarthur',
    state: 'NC',
    latitude: '35.583071',
    longitude: '-77.490491',
    timeZoneId: 'America/New_York',
  },
  '27812': {
    zip: '27812',
    city: 'Bethel',
    state: 'NC',
    latitude: '35.806779',
    longitude: '-77.378225',
    timeZoneId: 'America/New_York',
  },
  '27813': {
    zip: '27813',
    city: 'Black Creek',
    state: 'NC',
    latitude: '35.655977',
    longitude: '-77.939568',
    timeZoneId: 'America/New_York',
  },
  '27814': {
    zip: '27814',
    city: 'Blounts Creek',
    state: 'NC',
    latitude: '35.369891',
    longitude: '-76.922419',
    timeZoneId: 'America/New_York',
  },
  '27815': {
    zip: '27815',
    city: 'Rocky Mount',
    state: 'NC',
    latitude: '35.93523',
    longitude: '-77.775237',
    timeZoneId: 'America/New_York',
  },
  '27816': {
    zip: '27816',
    city: 'Castalia',
    state: 'NC',
    latitude: '36.100785',
    longitude: '-78.065444',
    timeZoneId: 'America/New_York',
  },
  '27817': {
    zip: '27817',
    city: 'Chocowinity',
    state: 'NC',
    latitude: '35.516589',
    longitude: '-77.104743',
    timeZoneId: 'America/New_York',
  },
  '27818': {
    zip: '27818',
    city: 'Como',
    state: 'NC',
    latitude: '36.505558',
    longitude: '-77.01177',
    timeZoneId: 'America/New_York',
  },
  '27819': {
    zip: '27819',
    city: 'Conetoe',
    state: 'NC',
    latitude: '35.818553',
    longitude: '-77.457634',
    timeZoneId: 'America/New_York',
  },
  '27820': {
    zip: '27820',
    city: 'Conway',
    state: 'NC',
    latitude: '36.441291',
    longitude: '-77.256965',
    timeZoneId: 'America/New_York',
  },
  '27821': {
    zip: '27821',
    city: 'Edward',
    state: 'NC',
    latitude: '35.329294',
    longitude: '-76.89263',
    timeZoneId: 'America/New_York',
  },
  '27822': {
    zip: '27822',
    city: 'Elm City',
    state: 'NC',
    latitude: '35.804683',
    longitude: '-77.8615',
    timeZoneId: 'America/New_York',
  },
  '27823': {
    zip: '27823',
    city: 'Enfield',
    state: 'NC',
    latitude: '36.175831',
    longitude: '-77.66663',
    timeZoneId: 'America/New_York',
  },
  '27824': {
    zip: '27824',
    city: 'Engelhard',
    state: 'NC',
    latitude: '35.53332',
    longitude: '-76.0377',
    timeZoneId: 'America/New_York',
  },
  '27825': {
    zip: '27825',
    city: 'Everetts',
    state: 'NC',
    latitude: '35.831579',
    longitude: '-77.172397',
    timeZoneId: 'America/New_York',
  },
  '27826': {
    zip: '27826',
    city: 'Fairfield',
    state: 'NC',
    latitude: '35.573676',
    longitude: '-76.242516',
    timeZoneId: 'America/New_York',
  },
  '27827': {
    zip: '27827',
    city: 'Falkland',
    state: 'NC',
    latitude: '35.698881',
    longitude: '-77.513436',
    timeZoneId: 'America/New_York',
  },
  '27828': {
    zip: '27828',
    city: 'Farmville',
    state: 'NC',
    latitude: '35.59328',
    longitude: '-77.588657',
    timeZoneId: 'America/New_York',
  },
  '27829': {
    zip: '27829',
    city: 'Fountain',
    state: 'NC',
    latitude: '35.675909',
    longitude: '-77.63764',
    timeZoneId: 'America/New_York',
  },
  '27830': {
    zip: '27830',
    city: 'Fremont',
    state: 'NC',
    latitude: '35.54286',
    longitude: '-77.96502',
    timeZoneId: 'America/New_York',
  },
  '27831': {
    zip: '27831',
    city: 'Garysburg',
    state: 'NC',
    latitude: '36.474201',
    longitude: '-77.596465',
    timeZoneId: 'America/New_York',
  },
  '27832': {
    zip: '27832',
    city: 'Gaston',
    state: 'NC',
    latitude: '36.488044',
    longitude: '-77.622501',
    timeZoneId: 'America/New_York',
  },
  '27833': {
    zip: '27833',
    city: 'Greenville',
    state: 'NC',
    latitude: '35.597011',
    longitude: '-77.375799',
    timeZoneId: 'America/New_York',
  },
  '27834': {
    zip: '27834',
    city: 'Greenville',
    state: 'NC',
    latitude: '35.62638',
    longitude: '-77.43858',
    timeZoneId: 'America/New_York',
  },
  '27835': {
    zip: '27835',
    city: 'Greenville',
    state: 'NC',
    latitude: '35.607',
    longitude: '-77.371771',
    timeZoneId: 'America/New_York',
  },
  '27836': {
    zip: '27836',
    city: 'Greenville',
    state: 'NC',
    latitude: '35.603283',
    longitude: '-77.359478',
    timeZoneId: 'America/New_York',
  },
  '27837': {
    zip: '27837',
    city: 'Grimesland',
    state: 'NC',
    latitude: '35.5767',
    longitude: '-77.23509',
    timeZoneId: 'America/New_York',
  },
  '27839': {
    zip: '27839',
    city: 'Halifax',
    state: 'NC',
    latitude: '36.326599',
    longitude: '-77.587407',
    timeZoneId: 'America/New_York',
  },
  '27840': {
    zip: '27840',
    city: 'Hamilton',
    state: 'NC',
    latitude: '35.943055',
    longitude: '-77.207183',
    timeZoneId: 'America/New_York',
  },
  '27841': {
    zip: '27841',
    city: 'Hassell',
    state: 'NC',
    latitude: '35.905492',
    longitude: '-77.27888',
    timeZoneId: 'America/New_York',
  },
  '27842': {
    zip: '27842',
    city: 'Henrico',
    state: 'NC',
    latitude: '36.524061',
    longitude: '-77.869673',
    timeZoneId: 'America/New_York',
  },
  '27843': {
    zip: '27843',
    city: 'Hobgood',
    state: 'NC',
    latitude: '35.983532',
    longitude: '-77.442885',
    timeZoneId: 'America/New_York',
  },
  '27844': {
    zip: '27844',
    city: 'Hollister',
    state: 'NC',
    latitude: '36.246667',
    longitude: '-77.956944',
    timeZoneId: 'America/New_York',
  },
  '27845': {
    zip: '27845',
    city: 'Jackson',
    state: 'NC',
    latitude: '36.39328',
    longitude: '-77.420095',
    timeZoneId: 'America/New_York',
  },
  '27846': {
    zip: '27846',
    city: 'Jamesville',
    state: 'NC',
    latitude: '35.810294',
    longitude: '-76.905822',
    timeZoneId: 'America/New_York',
  },
  '27847': {
    zip: '27847',
    city: 'Kelford',
    state: 'NC',
    latitude: '36.181279',
    longitude: '-77.218678',
    timeZoneId: 'America/New_York',
  },
  '27849': {
    zip: '27849',
    city: 'Lewiston Woodville',
    state: 'NC',
    latitude: '36.08826',
    longitude: '-77.22966',
    timeZoneId: 'America/New_York',
  },
  '27850': {
    zip: '27850',
    city: 'Littleton',
    state: 'NC',
    latitude: '36.517439',
    longitude: '-77.947612',
    timeZoneId: 'America/New_York',
  },
  '27851': {
    zip: '27851',
    city: 'Lucama',
    state: 'NC',
    latitude: '35.634649',
    longitude: '-78.012797',
    timeZoneId: 'America/New_York',
  },
  '27852': {
    zip: '27852',
    city: 'Macclesfield',
    state: 'NC',
    latitude: '35.7783',
    longitude: '-77.58762',
    timeZoneId: 'America/New_York',
  },
  '27853': {
    zip: '27853',
    city: 'Margarettsville',
    state: 'NC',
    latitude: '36.525209',
    longitude: '-77.292806',
    timeZoneId: 'America/New_York',
  },
  '27855': {
    zip: '27855',
    city: 'Murfreesboro',
    state: 'NC',
    latitude: '36.437143',
    longitude: '-77.097324',
    timeZoneId: 'America/New_York',
  },
  '27856': {
    zip: '27856',
    city: 'Nashville',
    state: 'NC',
    latitude: '35.965691',
    longitude: '-77.958946',
    timeZoneId: 'America/New_York',
  },
  '27857': {
    zip: '27857',
    city: 'Oak City',
    state: 'NC',
    latitude: '35.949714',
    longitude: '-77.257056',
    timeZoneId: 'America/New_York',
  },
  '27858': {
    zip: '27858',
    city: 'Greenville',
    state: 'NC',
    latitude: '35.589407',
    longitude: '-77.351275',
    timeZoneId: 'America/New_York',
  },
  '27860': {
    zip: '27860',
    city: 'Pantego',
    state: 'NC',
    latitude: '35.632234',
    longitude: '-76.714103',
    timeZoneId: 'America/New_York',
  },
  '27861': {
    zip: '27861',
    city: 'Parmele',
    state: 'NC',
    latitude: '35.820935',
    longitude: '-77.312517',
    timeZoneId: 'America/New_York',
  },
  '27862': {
    zip: '27862',
    city: 'Pendleton',
    state: 'NC',
    latitude: '36.493067',
    longitude: '-77.188974',
    timeZoneId: 'America/New_York',
  },
  '27863': {
    zip: '27863',
    city: 'Pikeville',
    state: 'NC',
    latitude: '35.492476',
    longitude: '-77.9833',
    timeZoneId: 'America/New_York',
  },
  '27864': {
    zip: '27864',
    city: 'Pinetops',
    state: 'NC',
    latitude: '35.790476',
    longitude: '-77.641917',
    timeZoneId: 'America/New_York',
  },
  '27865': {
    zip: '27865',
    city: 'Pinetown',
    state: 'NC',
    latitude: '35.6062',
    longitude: '-76.82886',
    timeZoneId: 'America/New_York',
  },
  '27866': {
    zip: '27866',
    city: 'Pleasant Hill',
    state: 'NC',
    latitude: '36.525713',
    longitude: '-77.514848',
    timeZoneId: 'America/New_York',
  },
  '27867': {
    zip: '27867',
    city: 'Potecasi',
    state: 'NC',
    latitude: '36.361074',
    longitude: '-77.238158',
    timeZoneId: 'America/New_York',
  },
  '27868': {
    zip: '27868',
    city: 'Red Oak',
    state: 'NC',
    latitude: '36.038485',
    longitude: '-77.90884',
    timeZoneId: 'America/New_York',
  },
  '27869': {
    zip: '27869',
    city: 'Rich Square',
    state: 'NC',
    latitude: '36.270766',
    longitude: '-77.286338',
    timeZoneId: 'America/New_York',
  },
  '27870': {
    zip: '27870',
    city: 'Roanoke Rapids',
    state: 'NC',
    latitude: '36.45143',
    longitude: '-77.655125',
    timeZoneId: 'America/New_York',
  },
  '27871': {
    zip: '27871',
    city: 'Robersonville',
    state: 'NC',
    latitude: '35.822151',
    longitude: '-77.25192',
    timeZoneId: 'America/New_York',
  },
  '27872': {
    zip: '27872',
    city: 'Roxobel',
    state: 'NC',
    latitude: '36.200834',
    longitude: '-77.243084',
    timeZoneId: 'America/New_York',
  },
  '27873': {
    zip: '27873',
    city: 'Saratoga',
    state: 'NC',
    latitude: '35.65508',
    longitude: '-77.770318',
    timeZoneId: 'America/New_York',
  },
  '27874': {
    zip: '27874',
    city: 'Scotland Neck',
    state: 'NC',
    latitude: '36.130304',
    longitude: '-77.420567',
    timeZoneId: 'America/New_York',
  },
  '27875': {
    zip: '27875',
    city: 'Scranton',
    state: 'NC',
    latitude: '35.581105',
    longitude: '-76.448894',
    timeZoneId: 'America/New_York',
  },
  '27876': {
    zip: '27876',
    city: 'Seaboard',
    state: 'NC',
    latitude: '36.487659',
    longitude: '-77.441965',
    timeZoneId: 'America/New_York',
  },
  '27877': {
    zip: '27877',
    city: 'Severn',
    state: 'NC',
    latitude: '36.514701',
    longitude: '-77.191721',
    timeZoneId: 'America/New_York',
  },
  '27878': {
    zip: '27878',
    city: 'Sharpsburg',
    state: 'NC',
    latitude: '35.866422',
    longitude: '-77.837357',
    timeZoneId: 'America/New_York',
  },
  '27879': {
    zip: '27879',
    city: 'Simpson',
    state: 'NC',
    latitude: '35.573583',
    longitude: '-77.27961',
    timeZoneId: 'America/New_York',
  },
  '27880': {
    zip: '27880',
    city: 'Sims',
    state: 'NC',
    latitude: '35.734926',
    longitude: '-78.100591',
    timeZoneId: 'America/New_York',
  },
  '27881': {
    zip: '27881',
    city: 'Speed',
    state: 'NC',
    latitude: '35.897439',
    longitude: '-77.542637',
    timeZoneId: 'America/New_York',
  },
  '27882': {
    zip: '27882',
    city: 'Spring Hope',
    state: 'NC',
    latitude: '35.938033',
    longitude: '-78.119372',
    timeZoneId: 'America/New_York',
  },
  '27883': {
    zip: '27883',
    city: 'Stantonsburg',
    state: 'NC',
    latitude: '35.603896',
    longitude: '-77.818704',
    timeZoneId: 'America/New_York',
  },
  '27884': {
    zip: '27884',
    city: 'Stokes',
    state: 'NC',
    latitude: '35.716698',
    longitude: '-77.269611',
    timeZoneId: 'America/New_York',
  },
  '27885': {
    zip: '27885',
    city: 'Swanquarter',
    state: 'NC',
    latitude: '35.40627',
    longitude: '-76.277705',
    timeZoneId: 'America/New_York',
  },
  '27886': {
    zip: '27886',
    city: 'Tarboro',
    state: 'NC',
    latitude: '35.89866',
    longitude: '-77.55822',
    timeZoneId: 'America/New_York',
  },
  '27887': {
    zip: '27887',
    city: 'Tillery',
    state: 'NC',
    latitude: '36.254271',
    longitude: '-77.487612',
    timeZoneId: 'America/New_York',
  },
  '27888': {
    zip: '27888',
    city: 'Walstonburg',
    state: 'NC',
    latitude: '35.581146',
    longitude: '-77.704479',
    timeZoneId: 'America/New_York',
  },
  '27889': {
    zip: '27889',
    city: 'Washington',
    state: 'NC',
    latitude: '35.549639',
    longitude: '-77.039376',
    timeZoneId: 'America/New_York',
  },
  '27890': {
    zip: '27890',
    city: 'Weldon',
    state: 'NC',
    latitude: '36.420265',
    longitude: '-77.609569',
    timeZoneId: 'America/New_York',
  },
  '27891': {
    zip: '27891',
    city: 'Whitakers',
    state: 'NC',
    latitude: '36.101004',
    longitude: '-77.723888',
    timeZoneId: 'America/New_York',
  },
  '27892': {
    zip: '27892',
    city: 'Williamston',
    state: 'NC',
    latitude: '35.73168',
    longitude: '-77.1141',
    timeZoneId: 'America/New_York',
  },
  '27893': {
    zip: '27893',
    city: 'Wilson',
    state: 'NC',
    latitude: '35.73252',
    longitude: '-77.920181',
    timeZoneId: 'America/New_York',
  },
  '27894': {
    zip: '27894',
    city: 'Wilson',
    state: 'NC',
    latitude: '35.732528',
    longitude: '-77.925919',
    timeZoneId: 'America/New_York',
  },
  '27895': {
    zip: '27895',
    city: 'Wilson',
    state: 'NC',
    latitude: '35.759935',
    longitude: '-77.943394',
    timeZoneId: 'America/New_York',
  },
  '27896': {
    zip: '27896',
    city: 'Wilson',
    state: 'NC',
    latitude: '35.75562',
    longitude: '-77.944711',
    timeZoneId: 'America/New_York',
  },
  '27897': {
    zip: '27897',
    city: 'Woodland',
    state: 'NC',
    latitude: '36.3249',
    longitude: '-77.19906',
    timeZoneId: 'America/New_York',
  },
  '27906': {
    zip: '27906',
    city: 'Elizabeth City',
    state: 'NC',
    latitude: '36.286618',
    longitude: '-76.216871',
    timeZoneId: 'America/New_York',
  },
  '27907': {
    zip: '27907',
    city: 'Elizabeth City',
    state: 'NC',
    latitude: '36.292677',
    longitude: '-76.221442',
    timeZoneId: 'America/New_York',
  },
  '27909': {
    zip: '27909',
    city: 'Elizabeth City',
    state: 'NC',
    latitude: '36.29501',
    longitude: '-76.233334',
    timeZoneId: 'America/New_York',
  },
  '27910': {
    zip: '27910',
    city: 'Ahoskie',
    state: 'NC',
    latitude: '36.288186',
    longitude: '-76.984827',
    timeZoneId: 'America/New_York',
  },
  '27915': {
    zip: '27915',
    city: 'Avon',
    state: 'NC',
    latitude: '35.349432',
    longitude: '-75.50184',
    timeZoneId: 'America/New_York',
  },
  '27916': {
    zip: '27916',
    city: 'Aydlett',
    state: 'NC',
    latitude: '36.30268',
    longitude: '-75.909091',
    timeZoneId: 'America/New_York',
  },
  '27917': {
    zip: '27917',
    city: 'Barco',
    state: 'NC',
    latitude: '36.359337',
    longitude: '-75.990261',
    timeZoneId: 'America/New_York',
  },
  '27919': {
    zip: '27919',
    city: 'Belvidere',
    state: 'NC',
    latitude: '36.31481',
    longitude: '-76.515448',
    timeZoneId: 'America/New_York',
  },
  '27920': {
    zip: '27920',
    city: 'Buxton',
    state: 'NC',
    latitude: '35.265883',
    longitude: '-75.52871',
    timeZoneId: 'America/New_York',
  },
  '27921': {
    zip: '27921',
    city: 'Camden',
    state: 'NC',
    latitude: '36.294916',
    longitude: '-76.149146',
    timeZoneId: 'America/New_York',
  },
  '27922': {
    zip: '27922',
    city: 'Cofield',
    state: 'NC',
    latitude: '36.316047',
    longitude: '-76.856572',
    timeZoneId: 'America/New_York',
  },
  '27923': {
    zip: '27923',
    city: 'Coinjock',
    state: 'NC',
    latitude: '36.341401',
    longitude: '-75.93715',
    timeZoneId: 'America/New_York',
  },
  '27924': {
    zip: '27924',
    city: 'Colerain',
    state: 'NC',
    latitude: '36.180756',
    longitude: '-76.848835',
    timeZoneId: 'America/New_York',
  },
  '27925': {
    zip: '27925',
    city: 'Columbia',
    state: 'NC',
    latitude: '35.921365',
    longitude: '-76.252726',
    timeZoneId: 'America/New_York',
  },
  '27926': {
    zip: '27926',
    city: 'Corapeake',
    state: 'NC',
    latitude: '36.506041',
    longitude: '-76.585151',
    timeZoneId: 'America/New_York',
  },
  '27927': {
    zip: '27927',
    city: 'Corolla',
    state: 'NC',
    latitude: '36.32508',
    longitude: '-75.80922',
    timeZoneId: 'America/New_York',
  },
  '27928': {
    zip: '27928',
    city: 'Creswell',
    state: 'NC',
    latitude: '35.81013',
    longitude: '-76.45638',
    timeZoneId: 'America/New_York',
  },
  '27929': {
    zip: '27929',
    city: 'Currituck',
    state: 'NC',
    latitude: '36.416317',
    longitude: '-76.013973',
    timeZoneId: 'America/New_York',
  },
  '27930': {
    zip: '27930',
    city: 'Hertford',
    state: 'NC',
    latitude: '36.153029',
    longitude: '-76.313021',
    timeZoneId: 'America/New_York',
  },
  '27932': {
    zip: '27932',
    city: 'Edenton',
    state: 'NC',
    latitude: '36.115773',
    longitude: '-76.642747',
    timeZoneId: 'America/New_York',
  },
  '27935': {
    zip: '27935',
    city: 'Eure',
    state: 'NC',
    latitude: '36.421909',
    longitude: '-76.857501',
    timeZoneId: 'America/New_York',
  },
  '27936': {
    zip: '27936',
    city: 'Frisco',
    state: 'NC',
    latitude: '35.238474',
    longitude: '-75.618039',
    timeZoneId: 'America/New_York',
  },
  '27937': {
    zip: '27937',
    city: 'Gates',
    state: 'NC',
    latitude: '36.505563',
    longitude: '-76.783424',
    timeZoneId: 'America/New_York',
  },
  '27938': {
    zip: '27938',
    city: 'Gatesville',
    state: 'NC',
    latitude: '36.391635',
    longitude: '-76.720068',
    timeZoneId: 'America/New_York',
  },
  '27939': {
    zip: '27939',
    city: 'Grandy',
    state: 'NC',
    latitude: '36.238287',
    longitude: '-75.899405',
    timeZoneId: 'America/New_York',
  },
  '27941': {
    zip: '27941',
    city: 'Harbinger',
    state: 'NC',
    latitude: '36.090072',
    longitude: '-75.815398',
    timeZoneId: 'America/New_York',
  },
  '27942': {
    zip: '27942',
    city: 'Harrellsville',
    state: 'NC',
    latitude: '36.286859',
    longitude: '-76.770668',
    timeZoneId: 'America/New_York',
  },
  '27943': {
    zip: '27943',
    city: 'Hatteras',
    state: 'NC',
    latitude: '35.230771',
    longitude: '-75.626592',
    timeZoneId: 'America/New_York',
  },
  '27944': {
    zip: '27944',
    city: 'Hertford',
    state: 'NC',
    latitude: '36.14964',
    longitude: '-76.34892',
    timeZoneId: 'America/New_York',
  },
  '27946': {
    zip: '27946',
    city: 'Hobbsville',
    state: 'NC',
    latitude: '36.353318',
    longitude: '-76.640451',
    timeZoneId: 'America/New_York',
  },
  '27947': {
    zip: '27947',
    city: 'Jarvisburg',
    state: 'NC',
    latitude: '36.175507',
    longitude: '-75.852327',
    timeZoneId: 'America/New_York',
  },
  '27948': {
    zip: '27948',
    city: 'Kill Devil Hills',
    state: 'NC',
    latitude: '36.022095',
    longitude: '-75.680394',
    timeZoneId: 'America/New_York',
  },
  '27949': {
    zip: '27949',
    city: 'Kitty Hawk',
    state: 'NC',
    latitude: '36.13344',
    longitude: '-75.7254',
    timeZoneId: 'America/New_York',
  },
  '27950': {
    zip: '27950',
    city: 'Knotts Island',
    state: 'NC',
    latitude: '36.537621',
    longitude: '-76.038101',
    timeZoneId: 'America/New_York',
  },
  '27953': {
    zip: '27953',
    city: 'Manns Harbor',
    state: 'NC',
    latitude: '35.86518',
    longitude: '-75.91902',
    timeZoneId: 'America/New_York',
  },
  '27954': {
    zip: '27954',
    city: 'Manteo',
    state: 'NC',
    latitude: '35.915917',
    longitude: '-75.677254',
    timeZoneId: 'America/New_York',
  },
  '27956': {
    zip: '27956',
    city: 'Maple',
    state: 'NC',
    latitude: '36.391755',
    longitude: '-76.003521',
    timeZoneId: 'America/New_York',
  },
  '27957': {
    zip: '27957',
    city: 'Merry Hill',
    state: 'NC',
    latitude: '36.082996',
    longitude: '-76.767953',
    timeZoneId: 'America/New_York',
  },
  '27958': {
    zip: '27958',
    city: 'Moyock',
    state: 'NC',
    latitude: '36.494355',
    longitude: '-76.140012',
    timeZoneId: 'America/New_York',
  },
  '27959': {
    zip: '27959',
    city: 'Nags Head',
    state: 'NC',
    latitude: '35.888575',
    longitude: '-75.603554',
    timeZoneId: 'America/New_York',
  },
  '27960': {
    zip: '27960',
    city: 'Ocracoke',
    state: 'NC',
    latitude: '35.11232',
    longitude: '-75.97622',
    timeZoneId: 'America/New_York',
  },
  '27962': {
    zip: '27962',
    city: 'Plymouth',
    state: 'NC',
    latitude: '35.859301',
    longitude: '-76.745346',
    timeZoneId: 'America/New_York',
  },
  '27964': {
    zip: '27964',
    city: 'Point Harbor',
    state: 'NC',
    latitude: '36.076731',
    longitude: '-75.799265',
    timeZoneId: 'America/New_York',
  },
  '27965': {
    zip: '27965',
    city: 'Poplar Branch',
    state: 'NC',
    latitude: '36.263218',
    longitude: '-75.883639',
    timeZoneId: 'America/New_York',
  },
  '27966': {
    zip: '27966',
    city: 'Powells Point',
    state: 'NC',
    latitude: '36.116292',
    longitude: '-75.83675',
    timeZoneId: 'America/New_York',
  },
  '27967': {
    zip: '27967',
    city: 'Powellsville',
    state: 'NC',
    latitude: '36.224611',
    longitude: '-76.930696',
    timeZoneId: 'America/New_York',
  },
  '27968': {
    zip: '27968',
    city: 'Rodanthe',
    state: 'NC',
    latitude: '35.58321',
    longitude: '-75.467676',
    timeZoneId: 'America/New_York',
  },
  '27969': {
    zip: '27969',
    city: 'Roduco',
    state: 'NC',
    latitude: '36.462769',
    longitude: '-76.814597',
    timeZoneId: 'America/New_York',
  },
  '27970': {
    zip: '27970',
    city: 'Roper',
    state: 'NC',
    latitude: '35.875739',
    longitude: '-76.616924',
    timeZoneId: 'America/New_York',
  },
  '27972': {
    zip: '27972',
    city: 'Salvo',
    state: 'NC',
    latitude: '35.548722',
    longitude: '-75.468151',
    timeZoneId: 'America/New_York',
  },
  '27973': {
    zip: '27973',
    city: 'Shawboro',
    state: 'NC',
    latitude: '36.376797',
    longitude: '-76.085192',
    timeZoneId: 'America/New_York',
  },
  '27974': {
    zip: '27974',
    city: 'Shiloh',
    state: 'NC',
    latitude: '36.243568',
    longitude: '-76.078238',
    timeZoneId: 'America/New_York',
  },
  '27976': {
    zip: '27976',
    city: 'South Mills',
    state: 'NC',
    latitude: '36.51177',
    longitude: '-76.348226',
    timeZoneId: 'America/New_York',
  },
  '27978': {
    zip: '27978',
    city: 'Stumpy Point',
    state: 'NC',
    latitude: '35.750419',
    longitude: '-75.808743',
    timeZoneId: 'America/New_York',
  },
  '27979': {
    zip: '27979',
    city: 'Sunbury',
    state: 'NC',
    latitude: '36.417311',
    longitude: '-76.565101',
    timeZoneId: 'America/New_York',
  },
  '27980': {
    zip: '27980',
    city: 'Tyner',
    state: 'NC',
    latitude: '36.254002',
    longitude: '-76.634013',
    timeZoneId: 'America/New_York',
  },
  '27981': {
    zip: '27981',
    city: 'Wanchese',
    state: 'NC',
    latitude: '35.83521',
    longitude: '-75.642417',
    timeZoneId: 'America/New_York',
  },
  '27982': {
    zip: '27982',
    city: 'Waves',
    state: 'NC',
    latitude: '35.553565',
    longitude: '-75.465694',
    timeZoneId: 'America/New_York',
  },
  '27983': {
    zip: '27983',
    city: 'Windsor',
    state: 'NC',
    latitude: '35.997565',
    longitude: '-76.94388',
    timeZoneId: 'America/New_York',
  },
  '27985': {
    zip: '27985',
    city: 'Winfall',
    state: 'NC',
    latitude: '36.209308',
    longitude: '-76.453104',
    timeZoneId: 'America/New_York',
  },
  '27986': {
    zip: '27986',
    city: 'Winton',
    state: 'NC',
    latitude: '36.385231',
    longitude: '-76.942476',
    timeZoneId: 'America/New_York',
  },
  '28001': {
    zip: '28001',
    city: 'Albemarle',
    state: 'NC',
    latitude: '35.34378',
    longitude: '-80.2263',
    timeZoneId: 'America/New_York',
  },
  '28002': {
    zip: '28002',
    city: 'Albemarle',
    state: 'NC',
    latitude: '35.354388',
    longitude: '-80.193633',
    timeZoneId: 'America/New_York',
  },
  '28006': {
    zip: '28006',
    city: 'Alexis',
    state: 'NC',
    latitude: '35.395071',
    longitude: '-81.113297',
    timeZoneId: 'America/New_York',
  },
  '28007': {
    zip: '28007',
    city: 'Ansonville',
    state: 'NC',
    latitude: '35.02111',
    longitude: '-80.081933',
    timeZoneId: 'America/New_York',
  },
  '28009': {
    zip: '28009',
    city: 'Badin',
    state: 'NC',
    latitude: '35.405852',
    longitude: '-80.114436',
    timeZoneId: 'America/New_York',
  },
  '28010': {
    zip: '28010',
    city: 'Barium Springs',
    state: 'NC',
    latitude: '35.720008',
    longitude: '-80.898107',
    timeZoneId: 'America/New_York',
  },
  '28012': {
    zip: '28012',
    city: 'Belmont',
    state: 'NC',
    latitude: '35.243718',
    longitude: '-81.03641',
    timeZoneId: 'America/New_York',
  },
  '28016': {
    zip: '28016',
    city: 'Bessemer City',
    state: 'NC',
    latitude: '35.285753',
    longitude: '-81.282523',
    timeZoneId: 'America/New_York',
  },
  '28017': {
    zip: '28017',
    city: 'Boiling Springs',
    state: 'NC',
    latitude: '35.252902',
    longitude: '-81.644801',
    timeZoneId: 'America/New_York',
  },
  '28018': {
    zip: '28018',
    city: 'Bostic',
    state: 'NC',
    latitude: '35.45712',
    longitude: '-81.80346',
    timeZoneId: 'America/New_York',
  },
  '28019': {
    zip: '28019',
    city: 'Caroleen',
    state: 'NC',
    latitude: '35.35363',
    longitude: '-81.87148',
    timeZoneId: 'America/New_York',
  },
  '28020': {
    zip: '28020',
    city: 'Casar',
    state: 'NC',
    latitude: '35.526116',
    longitude: '-81.597449',
    timeZoneId: 'America/New_York',
  },
  '28021': {
    zip: '28021',
    city: 'Cherryville',
    state: 'NC',
    latitude: '35.381411',
    longitude: '-81.375643',
    timeZoneId: 'America/New_York',
  },
  '28023': {
    zip: '28023',
    city: 'China Grove',
    state: 'NC',
    latitude: '35.569405',
    longitude: '-80.58962',
    timeZoneId: 'America/New_York',
  },
  '28024': {
    zip: '28024',
    city: 'Cliffside',
    state: 'NC',
    latitude: '35.237391',
    longitude: '-81.743376',
    timeZoneId: 'America/New_York',
  },
  '28025': {
    zip: '28025',
    city: 'Concord',
    state: 'NC',
    latitude: '35.400953',
    longitude: '-80.583807',
    timeZoneId: 'America/New_York',
  },
  '28026': {
    zip: '28026',
    city: 'Concord',
    state: 'NC',
    latitude: '35.376128',
    longitude: '-80.533682',
    timeZoneId: 'America/New_York',
  },
  '28027': {
    zip: '28027',
    city: 'Concord',
    state: 'NC',
    latitude: '35.411756',
    longitude: '-80.646757',
    timeZoneId: 'America/New_York',
  },
  '28031': {
    zip: '28031',
    city: 'Cornelius',
    state: 'NC',
    latitude: '35.478218',
    longitude: '-80.864412',
    timeZoneId: 'America/New_York',
  },
  '28032': {
    zip: '28032',
    city: 'Cramerton',
    state: 'NC',
    latitude: '35.239413',
    longitude: '-81.076081',
    timeZoneId: 'America/New_York',
  },
  '28033': {
    zip: '28033',
    city: 'Crouse',
    state: 'NC',
    latitude: '35.401571',
    longitude: '-81.346799',
    timeZoneId: 'America/New_York',
  },
  '28034': {
    zip: '28034',
    city: 'Dallas',
    state: 'NC',
    latitude: '35.327855',
    longitude: '-81.180475',
    timeZoneId: 'America/New_York',
  },
  '28035': {
    zip: '28035',
    city: 'Davidson',
    state: 'NC',
    latitude: '35.499205',
    longitude: '-80.846577',
    timeZoneId: 'America/New_York',
  },
  '28036': {
    zip: '28036',
    city: 'Davidson',
    state: 'NC',
    latitude: '35.47962',
    longitude: '-80.82306',
    timeZoneId: 'America/New_York',
  },
  '28037': {
    zip: '28037',
    city: 'Denver',
    state: 'NC',
    latitude: '35.510143',
    longitude: '-81.016888',
    timeZoneId: 'America/New_York',
  },
  '28038': {
    zip: '28038',
    city: 'Earl',
    state: 'NC',
    latitude: '35.199661',
    longitude: '-81.543345',
    timeZoneId: 'America/New_York',
  },
  '28039': {
    zip: '28039',
    city: 'East Spencer',
    state: 'NC',
    latitude: '35.678327',
    longitude: '-80.43316',
    timeZoneId: 'America/New_York',
  },
  '28040': {
    zip: '28040',
    city: 'Ellenboro',
    state: 'NC',
    latitude: '35.40048',
    longitude: '-81.75738',
    timeZoneId: 'America/New_York',
  },
  '28041': {
    zip: '28041',
    city: 'Faith',
    state: 'NC',
    latitude: '35.589483',
    longitude: '-80.466689',
    timeZoneId: 'America/New_York',
  },
  '28042': {
    zip: '28042',
    city: 'Fallston',
    state: 'NC',
    latitude: '35.429789',
    longitude: '-81.505251',
    timeZoneId: 'America/New_York',
  },
  '28043': {
    zip: '28043',
    city: 'Forest City',
    state: 'NC',
    latitude: '35.333268',
    longitude: '-81.86746',
    timeZoneId: 'America/New_York',
  },
  '28052': {
    zip: '28052',
    city: 'Gastonia',
    state: 'NC',
    latitude: '35.2275',
    longitude: '-81.24204',
    timeZoneId: 'America/New_York',
  },
  '28053': {
    zip: '28053',
    city: 'Gastonia',
    state: 'NC',
    latitude: '35.192766',
    longitude: '-81.235852',
    timeZoneId: 'America/New_York',
  },
  '28054': {
    zip: '28054',
    city: 'Gastonia',
    state: 'NC',
    latitude: '35.22972',
    longitude: '-81.14244',
    timeZoneId: 'America/New_York',
  },
  '28055': {
    zip: '28055',
    city: 'Gastonia',
    state: 'NC',
    latitude: '35.218503',
    longitude: '-81.2531',
    timeZoneId: 'America/New_York',
  },
  '28056': {
    zip: '28056',
    city: 'Gastonia',
    state: 'NC',
    latitude: '35.209997',
    longitude: '-81.146871',
    timeZoneId: 'America/New_York',
  },
  '28070': {
    zip: '28070',
    city: 'Huntersville',
    state: 'NC',
    latitude: '35.416797',
    longitude: '-80.842491',
    timeZoneId: 'America/New_York',
  },
  '28071': {
    zip: '28071',
    city: 'Gold Hill',
    state: 'NC',
    latitude: '35.476333',
    longitude: '-80.346732',
    timeZoneId: 'America/New_York',
  },
  '28072': {
    zip: '28072',
    city: 'Granite Quarry',
    state: 'NC',
    latitude: '35.60744',
    longitude: '-80.444746',
    timeZoneId: 'America/New_York',
  },
  '28073': {
    zip: '28073',
    city: 'Grover',
    state: 'NC',
    latitude: '35.202734',
    longitude: '-81.478614',
    timeZoneId: 'America/New_York',
  },
  '28074': {
    zip: '28074',
    city: 'Harris',
    state: 'NC',
    latitude: '35.243572',
    longitude: '-81.876734',
    timeZoneId: 'America/New_York',
  },
  '28075': {
    zip: '28075',
    city: 'Harrisburg',
    state: 'NC',
    latitude: '35.333819',
    longitude: '-80.663496',
    timeZoneId: 'America/New_York',
  },
  '28076': {
    zip: '28076',
    city: 'Henrietta',
    state: 'NC',
    latitude: '35.258864',
    longitude: '-81.794733',
    timeZoneId: 'America/New_York',
  },
  '28077': {
    zip: '28077',
    city: 'High Shoals',
    state: 'NC',
    latitude: '35.401297',
    longitude: '-81.204549',
    timeZoneId: 'America/New_York',
  },
  '28078': {
    zip: '28078',
    city: 'Huntersville',
    state: 'NC',
    latitude: '35.41404',
    longitude: '-80.85258',
    timeZoneId: 'America/New_York',
  },
  '28079': {
    zip: '28079',
    city: 'Indian Trail',
    state: 'NC',
    latitude: '35.098742',
    longitude: '-80.631203',
    timeZoneId: 'America/New_York',
  },
  '28080': {
    zip: '28080',
    city: 'Iron Station',
    state: 'NC',
    latitude: '35.457153',
    longitude: '-81.10951',
    timeZoneId: 'America/New_York',
  },
  '28081': {
    zip: '28081',
    city: 'Kannapolis',
    state: 'NC',
    latitude: '35.485398',
    longitude: '-80.626317',
    timeZoneId: 'America/New_York',
  },
  '28082': {
    zip: '28082',
    city: 'Kannapolis',
    state: 'NC',
    latitude: '35.479541',
    longitude: '-80.626617',
    timeZoneId: 'America/New_York',
  },
  '28083': {
    zip: '28083',
    city: 'Kannapolis',
    state: 'NC',
    latitude: '35.483897',
    longitude: '-80.599575',
    timeZoneId: 'America/New_York',
  },
  '28086': {
    zip: '28086',
    city: 'Kings Mountain',
    state: 'NC',
    latitude: '35.246918',
    longitude: '-81.361082',
    timeZoneId: 'America/New_York',
  },
  '28088': {
    zip: '28088',
    city: 'Landis',
    state: 'NC',
    latitude: '35.545742',
    longitude: '-80.614074',
    timeZoneId: 'America/New_York',
  },
  '28089': {
    zip: '28089',
    city: 'Lattimore',
    state: 'NC',
    latitude: '35.314701',
    longitude: '-81.654427',
    timeZoneId: 'America/New_York',
  },
  '28090': {
    zip: '28090',
    city: 'Lawndale',
    state: 'NC',
    latitude: '35.46546',
    longitude: '-81.53244',
    timeZoneId: 'America/New_York',
  },
  '28091': {
    zip: '28091',
    city: 'Lilesville',
    state: 'NC',
    latitude: '34.967036',
    longitude: '-79.989195',
    timeZoneId: 'America/New_York',
  },
  '28092': {
    zip: '28092',
    city: 'Lincolnton',
    state: 'NC',
    latitude: '35.48514',
    longitude: '-81.22386',
    timeZoneId: 'America/New_York',
  },
  '28093': {
    zip: '28093',
    city: 'Lincolnton',
    state: 'NC',
    latitude: '35.47361',
    longitude: '-81.25431',
    timeZoneId: 'America/New_York',
  },
  '28097': {
    zip: '28097',
    city: 'Locust',
    state: 'NC',
    latitude: '35.265418',
    longitude: '-80.426782',
    timeZoneId: 'America/New_York',
  },
  '28098': {
    zip: '28098',
    city: 'Lowell',
    state: 'NC',
    latitude: '35.270919',
    longitude: '-81.108026',
    timeZoneId: 'America/New_York',
  },
  '28101': {
    zip: '28101',
    city: 'Mc Adenville',
    state: 'NC',
    latitude: '35.258051',
    longitude: '-81.079408',
    timeZoneId: 'America/New_York',
  },
  '28102': {
    zip: '28102',
    city: 'Mc Farlan',
    state: 'NC',
    latitude: '34.815409',
    longitude: '-79.977442',
    timeZoneId: 'America/New_York',
  },
  '28103': {
    zip: '28103',
    city: 'Marshville',
    state: 'NC',
    latitude: '34.992963',
    longitude: '-80.366496',
    timeZoneId: 'America/New_York',
  },
  '28104': {
    zip: '28104',
    city: 'Matthews',
    state: 'NC',
    latitude: '35.116667',
    longitude: '-80.723889',
    timeZoneId: 'America/New_York',
  },
  '28105': {
    zip: '28105',
    city: 'Matthews',
    state: 'NC',
    latitude: '35.11434',
    longitude: '-80.69394',
    timeZoneId: 'America/New_York',
  },
  '28106': {
    zip: '28106',
    city: 'Matthews',
    state: 'NC',
    latitude: '35.123453',
    longitude: '-80.713602',
    timeZoneId: 'America/New_York',
  },
  '28107': {
    zip: '28107',
    city: 'Midland',
    state: 'NC',
    latitude: '35.246152',
    longitude: '-80.53302',
    timeZoneId: 'America/New_York',
  },
  '28108': {
    zip: '28108',
    city: 'Mineral Springs',
    state: 'NC',
    latitude: '34.930216',
    longitude: '-80.675564',
    timeZoneId: 'America/New_York',
  },
  '28109': {
    zip: '28109',
    city: 'Misenheimer',
    state: 'NC',
    latitude: '35.483541',
    longitude: '-80.322701',
    timeZoneId: 'America/New_York',
  },
  '28110': {
    zip: '28110',
    city: 'Monroe',
    state: 'NC',
    latitude: '35.010982',
    longitude: '-80.551224',
    timeZoneId: 'America/New_York',
  },
  '28111': {
    zip: '28111',
    city: 'Monroe',
    state: 'NC',
    latitude: '34.983675',
    longitude: '-80.549186',
    timeZoneId: 'America/New_York',
  },
  '28112': {
    zip: '28112',
    city: 'Monroe',
    state: 'NC',
    latitude: '34.940983',
    longitude: '-80.540648',
    timeZoneId: 'America/New_York',
  },
  '28114': {
    zip: '28114',
    city: 'Mooresboro',
    state: 'NC',
    latitude: '35.251745',
    longitude: '-81.717075',
    timeZoneId: 'America/New_York',
  },
  '28115': {
    zip: '28115',
    city: 'Mooresville',
    state: 'NC',
    latitude: '35.575801',
    longitude: '-80.819083',
    timeZoneId: 'America/New_York',
  },
  '28117': {
    zip: '28117',
    city: 'Mooresville',
    state: 'NC',
    latitude: '35.586807',
    longitude: '-80.810679',
    timeZoneId: 'America/New_York',
  },
  '28119': {
    zip: '28119',
    city: 'Morven',
    state: 'NC',
    latitude: '34.944771',
    longitude: '-80.142387',
    timeZoneId: 'America/New_York',
  },
  '28120': {
    zip: '28120',
    city: 'Mount Holly',
    state: 'NC',
    latitude: '35.307051',
    longitude: '-81.030096',
    timeZoneId: 'America/New_York',
  },
  '28123': {
    zip: '28123',
    city: 'Mount Mourne',
    state: 'NC',
    latitude: '35.543985',
    longitude: '-80.846555',
    timeZoneId: 'America/New_York',
  },
  '28124': {
    zip: '28124',
    city: 'Mount Pleasant',
    state: 'NC',
    latitude: '35.411081',
    longitude: '-80.41947',
    timeZoneId: 'America/New_York',
  },
  '28125': {
    zip: '28125',
    city: 'Mount Ulla',
    state: 'NC',
    latitude: '35.640595',
    longitude: '-80.716219',
    timeZoneId: 'America/New_York',
  },
  '28126': {
    zip: '28126',
    city: 'Newell',
    state: 'NC',
    latitude: '35.275939',
    longitude: '-80.736607',
    timeZoneId: 'America/New_York',
  },
  '28127': {
    zip: '28127',
    city: 'New London',
    state: 'NC',
    latitude: '35.40041',
    longitude: '-80.204216',
    timeZoneId: 'America/New_York',
  },
  '28128': {
    zip: '28128',
    city: 'Norwood',
    state: 'NC',
    latitude: '35.2374',
    longitude: '-80.16624',
    timeZoneId: 'America/New_York',
  },
  '28129': {
    zip: '28129',
    city: 'Oakboro',
    state: 'NC',
    latitude: '35.240371',
    longitude: '-80.331644',
    timeZoneId: 'America/New_York',
  },
  '28130': {
    zip: '28130',
    city: 'Paw Creek',
    state: 'NC',
    latitude: '35.290852',
    longitude: '-80.929304',
    timeZoneId: 'America/New_York',
  },
  '28133': {
    zip: '28133',
    city: 'Peachland',
    state: 'NC',
    latitude: '35.017385',
    longitude: '-80.263528',
    timeZoneId: 'America/New_York',
  },
  '28134': {
    zip: '28134',
    city: 'Pineville',
    state: 'NC',
    latitude: '35.080608',
    longitude: '-80.885732',
    timeZoneId: 'America/New_York',
  },
  '28135': {
    zip: '28135',
    city: 'Polkton',
    state: 'NC',
    latitude: '35.006202',
    longitude: '-80.207436',
    timeZoneId: 'America/New_York',
  },
  '28136': {
    zip: '28136',
    city: 'Polkville',
    state: 'NC',
    latitude: '35.414631',
    longitude: '-81.618702',
    timeZoneId: 'America/New_York',
  },
  '28137': {
    zip: '28137',
    city: 'Richfield',
    state: 'NC',
    latitude: '35.459658',
    longitude: '-80.26716',
    timeZoneId: 'America/New_York',
  },
  '28138': {
    zip: '28138',
    city: 'Rockwell',
    state: 'NC',
    latitude: '35.540209',
    longitude: '-80.430167',
    timeZoneId: 'America/New_York',
  },
  '28139': {
    zip: '28139',
    city: 'Rutherfordton',
    state: 'NC',
    latitude: '35.37822',
    longitude: '-81.97788',
    timeZoneId: 'America/New_York',
  },
  '28144': {
    zip: '28144',
    city: 'Salisbury',
    state: 'NC',
    latitude: '35.66452',
    longitude: '-80.474091',
    timeZoneId: 'America/New_York',
  },
  '28145': {
    zip: '28145',
    city: 'Salisbury',
    state: 'NC',
    latitude: '35.661257',
    longitude: '-80.447211',
    timeZoneId: 'America/New_York',
  },
  '28146': {
    zip: '28146',
    city: 'Salisbury',
    state: 'NC',
    latitude: '35.630497',
    longitude: '-80.432818',
    timeZoneId: 'America/New_York',
  },
  '28147': {
    zip: '28147',
    city: 'Salisbury',
    state: 'NC',
    latitude: '35.667513',
    longitude: '-80.533434',
    timeZoneId: 'America/New_York',
  },
  '28150': {
    zip: '28150',
    city: 'Shelby',
    state: 'NC',
    latitude: '35.304225',
    longitude: '-81.551791',
    timeZoneId: 'America/New_York',
  },
  '28151': {
    zip: '28151',
    city: 'Shelby',
    state: 'NC',
    latitude: '35.288342',
    longitude: '-81.543859',
    timeZoneId: 'America/New_York',
  },
  '28152': {
    zip: '28152',
    city: 'Shelby',
    state: 'NC',
    latitude: '35.2547',
    longitude: '-81.560764',
    timeZoneId: 'America/New_York',
  },
  '28159': {
    zip: '28159',
    city: 'Spencer',
    state: 'NC',
    latitude: '35.694549',
    longitude: '-80.433281',
    timeZoneId: 'America/New_York',
  },
  '28160': {
    zip: '28160',
    city: 'Spindale',
    state: 'NC',
    latitude: '35.35775',
    longitude: '-81.925321',
    timeZoneId: 'America/New_York',
  },
  '28163': {
    zip: '28163',
    city: 'Stanfield',
    state: 'NC',
    latitude: '35.251684',
    longitude: '-80.40316',
    timeZoneId: 'America/New_York',
  },
  '28164': {
    zip: '28164',
    city: 'Stanley',
    state: 'NC',
    latitude: '35.35284',
    longitude: '-81.10194',
    timeZoneId: 'America/New_York',
  },
  '28166': {
    zip: '28166',
    city: 'Troutman',
    state: 'NC',
    latitude: '35.699084',
    longitude: '-80.889947',
    timeZoneId: 'America/New_York',
  },
  '28167': {
    zip: '28167',
    city: 'Union Mills',
    state: 'NC',
    latitude: '35.490737',
    longitude: '-81.962942',
    timeZoneId: 'America/New_York',
  },
  '28168': {
    zip: '28168',
    city: 'Vale',
    state: 'NC',
    latitude: '35.494582',
    longitude: '-81.415179',
    timeZoneId: 'America/New_York',
  },
  '28169': {
    zip: '28169',
    city: 'Waco',
    state: 'NC',
    latitude: '35.361895',
    longitude: '-81.432001',
    timeZoneId: 'America/New_York',
  },
  '28170': {
    zip: '28170',
    city: 'Wadesboro',
    state: 'NC',
    latitude: '34.964789',
    longitude: '-80.071006',
    timeZoneId: 'America/New_York',
  },
  '28173': {
    zip: '28173',
    city: 'Waxhaw',
    state: 'NC',
    latitude: '34.92879',
    longitude: '-80.735968',
    timeZoneId: 'America/New_York',
  },
  '28174': {
    zip: '28174',
    city: 'Wingate',
    state: 'NC',
    latitude: '34.98655',
    longitude: '-80.448698',
    timeZoneId: 'America/New_York',
  },
  '28201': {
    zip: '28201',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.192962',
    longitude: '-80.826311',
    timeZoneId: 'America/New_York',
  },
  '28202': {
    zip: '28202',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.228512',
    longitude: '-80.844925',
    timeZoneId: 'America/New_York',
  },
  '28203': {
    zip: '28203',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.206514',
    longitude: '-80.857866',
    timeZoneId: 'America/New_York',
  },
  '28204': {
    zip: '28204',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.214583',
    longitude: '-80.828507',
    timeZoneId: 'America/New_York',
  },
  '28205': {
    zip: '28205',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.220088',
    longitude: '-80.790126',
    timeZoneId: 'America/New_York',
  },
  '28206': {
    zip: '28206',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.25084',
    longitude: '-80.822041',
    timeZoneId: 'America/New_York',
  },
  '28207': {
    zip: '28207',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.198282',
    longitude: '-80.830604',
    timeZoneId: 'America/New_York',
  },
  '28208': {
    zip: '28208',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.23537',
    longitude: '-80.906716',
    timeZoneId: 'America/New_York',
  },
  '28209': {
    zip: '28209',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.174505',
    longitude: '-80.853465',
    timeZoneId: 'America/New_York',
  },
  '28210': {
    zip: '28210',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.133058',
    longitude: '-80.859674',
    timeZoneId: 'America/New_York',
  },
  '28211': {
    zip: '28211',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.165273',
    longitude: '-80.788876',
    timeZoneId: 'America/New_York',
  },
  '28212': {
    zip: '28212',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.192219',
    longitude: '-80.715194',
    timeZoneId: 'America/New_York',
  },
  '28213': {
    zip: '28213',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.28978',
    longitude: '-80.780774',
    timeZoneId: 'America/New_York',
  },
  '28214': {
    zip: '28214',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.269641',
    longitude: '-80.960674',
    timeZoneId: 'America/New_York',
  },
  '28215': {
    zip: '28215',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.239821',
    longitude: '-80.725177',
    timeZoneId: 'America/New_York',
  },
  '28216': {
    zip: '28216',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.286212',
    longitude: '-80.879816',
    timeZoneId: 'America/New_York',
  },
  '28217': {
    zip: '28217',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.154268',
    longitude: '-80.928633',
    timeZoneId: 'America/New_York',
  },
  '28218': {
    zip: '28218',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.221989',
    longitude: '-80.788267',
    timeZoneId: 'America/New_York',
  },
  '28219': {
    zip: '28219',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.206905',
    longitude: '-80.941645',
    timeZoneId: 'America/New_York',
  },
  '28220': {
    zip: '28220',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.177804',
    longitude: '-80.851015',
    timeZoneId: 'America/New_York',
  },
  '28221': {
    zip: '28221',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.368572',
    longitude: '-80.76013',
    timeZoneId: 'America/New_York',
  },
  '28222': {
    zip: '28222',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.191264',
    longitude: '-80.804196',
    timeZoneId: 'America/New_York',
  },
  '28223': {
    zip: '28223',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.327415',
    longitude: '-80.759956',
    timeZoneId: 'America/New_York',
  },
  '28224': {
    zip: '28224',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.151064',
    longitude: '-80.873427',
    timeZoneId: 'America/New_York',
  },
  '28226': {
    zip: '28226',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.107873',
    longitude: '-80.807637',
    timeZoneId: 'America/New_York',
  },
  '28227': {
    zip: '28227',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.18496',
    longitude: '-80.65482',
    timeZoneId: 'America/New_York',
  },
  '28228': {
    zip: '28228',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.099604',
    longitude: '-80.771417',
    timeZoneId: 'America/New_York',
  },
  '28229': {
    zip: '28229',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.188824',
    longitude: '-80.743635',
    timeZoneId: 'America/New_York',
  },
  '28230': {
    zip: '28230',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.212613',
    longitude: '-80.828203',
    timeZoneId: 'America/New_York',
  },
  '28231': {
    zip: '28231',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.19619',
    longitude: '-80.824359',
    timeZoneId: 'America/New_York',
  },
  '28232': {
    zip: '28232',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.21628',
    longitude: '-80.826821',
    timeZoneId: 'America/New_York',
  },
  '28233': {
    zip: '28233',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.213038',
    longitude: '-80.827706',
    timeZoneId: 'America/New_York',
  },
  '28234': {
    zip: '28234',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.199142',
    longitude: '-80.856456',
    timeZoneId: 'America/New_York',
  },
  '28235': {
    zip: '28235',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.214514',
    longitude: '-80.825694',
    timeZoneId: 'America/New_York',
  },
  '28236': {
    zip: '28236',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.290104',
    longitude: '-81.013101',
    timeZoneId: 'America/New_York',
  },
  '28237': {
    zip: '28237',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.216255',
    longitude: '-80.828169',
    timeZoneId: 'America/New_York',
  },
  '28241': {
    zip: '28241',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.227662',
    longitude: '-80.843582',
    timeZoneId: 'America/New_York',
  },
  '28242': {
    zip: '28242',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.211849',
    longitude: '-80.861761',
    timeZoneId: 'America/New_York',
  },
  '28243': {
    zip: '28243',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.221435',
    longitude: '-80.837627',
    timeZoneId: 'America/New_York',
  },
  '28244': {
    zip: '28244',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.228942',
    longitude: '-80.84517',
    timeZoneId: 'America/New_York',
  },
  '28246': {
    zip: '28246',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.225415',
    longitude: '-80.845276',
    timeZoneId: 'America/New_York',
  },
  '28247': {
    zip: '28247',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.10593',
    longitude: '-80.822517',
    timeZoneId: 'America/New_York',
  },
  '28253': {
    zip: '28253',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.323653',
    longitude: '-80.782178',
    timeZoneId: 'America/New_York',
  },
  '28254': {
    zip: '28254',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.221426',
    longitude: '-80.928747',
    timeZoneId: 'America/New_York',
  },
  '28255': {
    zip: '28255',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.211192',
    longitude: '-80.865108',
    timeZoneId: 'America/New_York',
  },
  '28256': {
    zip: '28256',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.283183',
    longitude: '-80.730649',
    timeZoneId: 'America/New_York',
  },
  '28258': {
    zip: '28258',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.223001',
    longitude: '-80.930365',
    timeZoneId: 'America/New_York',
  },
  '28260': {
    zip: '28260',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.231298',
    longitude: '-80.844846',
    timeZoneId: 'America/New_York',
  },
  '28262': {
    zip: '28262',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.320146',
    longitude: '-80.741193',
    timeZoneId: 'America/New_York',
  },
  '28263': {
    zip: '28263',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.151538',
    longitude: '-80.873152',
    timeZoneId: 'America/New_York',
  },
  '28265': {
    zip: '28265',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.228515',
    longitude: '-80.842113',
    timeZoneId: 'America/New_York',
  },
  '28266': {
    zip: '28266',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.23132',
    longitude: '-80.844515',
    timeZoneId: 'America/New_York',
  },
  '28269': {
    zip: '28269',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.314436',
    longitude: '-80.805053',
    timeZoneId: 'America/New_York',
  },
  '28270': {
    zip: '28270',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.117679',
    longitude: '-80.760144',
    timeZoneId: 'America/New_York',
  },
  '28271': {
    zip: '28271',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.067814',
    longitude: '-80.806736',
    timeZoneId: 'America/New_York',
  },
  '28272': {
    zip: '28272',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.229711',
    longitude: '-80.844761',
    timeZoneId: 'America/New_York',
  },
  '28273': {
    zip: '28273',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.137982',
    longitude: '-80.938181',
    timeZoneId: 'America/New_York',
  },
  '28274': {
    zip: '28274',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.188792',
    longitude: '-80.837133',
    timeZoneId: 'America/New_York',
  },
  '28275': {
    zip: '28275',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.22687',
    longitude: '-80.842931',
    timeZoneId: 'America/New_York',
  },
  '28277': {
    zip: '28277',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.064565',
    longitude: '-80.806138',
    timeZoneId: 'America/New_York',
  },
  '28278': {
    zip: '28278',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.101881',
    longitude: '-81.024501',
    timeZoneId: 'America/New_York',
  },
  '28280': {
    zip: '28280',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.230706',
    longitude: '-80.846213',
    timeZoneId: 'America/New_York',
  },
  '28281': {
    zip: '28281',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.226708',
    longitude: '-80.841821',
    timeZoneId: 'America/New_York',
  },
  '28282': {
    zip: '28282',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.226719',
    longitude: '-80.844957',
    timeZoneId: 'America/New_York',
  },
  '28284': {
    zip: '28284',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.229893',
    longitude: '-80.840823',
    timeZoneId: 'America/New_York',
  },
  '28285': {
    zip: '28285',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.228864',
    longitude: '-80.846734',
    timeZoneId: 'America/New_York',
  },
  '28287': {
    zip: '28287',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.130178',
    longitude: '-80.853682',
    timeZoneId: 'America/New_York',
  },
  '28288': {
    zip: '28288',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.229166',
    longitude: '-80.841138',
    timeZoneId: 'America/New_York',
  },
  '28289': {
    zip: '28289',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.225894',
    longitude: '-80.841681',
    timeZoneId: 'America/New_York',
  },
  '28290': {
    zip: '28290',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.234982',
    longitude: '-80.913799',
    timeZoneId: 'America/New_York',
  },
  '28296': {
    zip: '28296',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.21302',
    longitude: '-80.827367',
    timeZoneId: 'America/New_York',
  },
  '28297': {
    zip: '28297',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.277971',
    longitude: '-80.855263',
    timeZoneId: 'America/New_York',
  },
  '28299': {
    zip: '28299',
    city: 'Charlotte',
    state: 'NC',
    latitude: '35.218471',
    longitude: '-80.811634',
    timeZoneId: 'America/New_York',
  },
  '28301': {
    zip: '28301',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.064922',
    longitude: '-78.864925',
    timeZoneId: 'America/New_York',
  },
  '28302': {
    zip: '28302',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.033259',
    longitude: '-78.92904',
    timeZoneId: 'America/New_York',
  },
  '28303': {
    zip: '28303',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.081833',
    longitude: '-78.962365',
    timeZoneId: 'America/New_York',
  },
  '28304': {
    zip: '28304',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.029744',
    longitude: '-78.970982',
    timeZoneId: 'America/New_York',
  },
  '28305': {
    zip: '28305',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.054049',
    longitude: '-78.903538',
    timeZoneId: 'America/New_York',
  },
  '28306': {
    zip: '28306',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.010174',
    longitude: '-78.930528',
    timeZoneId: 'America/New_York',
  },
  '28307': {
    zip: '28307',
    city: 'Fort Bragg',
    state: 'NC',
    latitude: '35.14074',
    longitude: '-78.99804',
    timeZoneId: 'America/New_York',
  },
  '28308': {
    zip: '28308',
    city: 'Pope Army Airfield',
    state: 'NC',
    latitude: '35.0525',
    longitude: '-78.878611',
    timeZoneId: 'America/New_York',
  },
  '28309': {
    zip: '28309',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.071877',
    longitude: '-78.89907',
    timeZoneId: 'America/New_York',
  },
  '28310': {
    zip: '28310',
    city: 'Fort Bragg',
    state: 'NC',
    latitude: '35.138766',
    longitude: '-79.000019',
    timeZoneId: 'America/New_York',
  },
  '28311': {
    zip: '28311',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.128576',
    longitude: '-78.897217',
    timeZoneId: 'America/New_York',
  },
  '28312': {
    zip: '28312',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.066291',
    longitude: '-78.866574',
    timeZoneId: 'America/New_York',
  },
  '28314': {
    zip: '28314',
    city: 'Fayetteville',
    state: 'NC',
    latitude: '35.05772',
    longitude: '-79.003491',
    timeZoneId: 'America/New_York',
  },
  '28315': {
    zip: '28315',
    city: 'Aberdeen',
    state: 'NC',
    latitude: '35.146104',
    longitude: '-79.424275',
    timeZoneId: 'America/New_York',
  },
  '28318': {
    zip: '28318',
    city: 'Autryville',
    state: 'NC',
    latitude: '34.989808',
    longitude: '-78.579363',
    timeZoneId: 'America/New_York',
  },
  '28319': {
    zip: '28319',
    city: 'Barnesville',
    state: 'NC',
    latitude: '34.384291',
    longitude: '-79.06613',
    timeZoneId: 'America/New_York',
  },
  '28320': {
    zip: '28320',
    city: 'Bladenboro',
    state: 'NC',
    latitude: '34.529407',
    longitude: '-78.793874',
    timeZoneId: 'America/New_York',
  },
  '28323': {
    zip: '28323',
    city: 'Bunnlevel',
    state: 'NC',
    latitude: '35.290805',
    longitude: '-78.831116',
    timeZoneId: 'America/New_York',
  },
  '28325': {
    zip: '28325',
    city: 'Calypso',
    state: 'NC',
    latitude: '34.999323',
    longitude: '-77.99233',
    timeZoneId: 'America/New_York',
  },
  '28326': {
    zip: '28326',
    city: 'Cameron',
    state: 'NC',
    latitude: '35.340855',
    longitude: '-79.223822',
    timeZoneId: 'America/New_York',
  },
  '28327': {
    zip: '28327',
    city: 'Carthage',
    state: 'NC',
    latitude: '35.34132',
    longitude: '-79.36248',
    timeZoneId: 'America/New_York',
  },
  '28328': {
    zip: '28328',
    city: 'Clinton',
    state: 'NC',
    latitude: '35.000598',
    longitude: '-78.339145',
    timeZoneId: 'America/New_York',
  },
  '28329': {
    zip: '28329',
    city: 'Clinton',
    state: 'NC',
    latitude: '34.99831',
    longitude: '-78.330057',
    timeZoneId: 'America/New_York',
  },
  '28330': {
    zip: '28330',
    city: 'Cordova',
    state: 'NC',
    latitude: '34.91839',
    longitude: '-79.825742',
    timeZoneId: 'America/New_York',
  },
  '28331': {
    zip: '28331',
    city: 'Cumberland',
    state: 'NC',
    latitude: '34.998959',
    longitude: '-78.973679',
    timeZoneId: 'America/New_York',
  },
  '28332': {
    zip: '28332',
    city: 'Dublin',
    state: 'NC',
    latitude: '34.656992',
    longitude: '-78.728566',
    timeZoneId: 'America/New_York',
  },
  '28333': {
    zip: '28333',
    city: 'Dudley',
    state: 'NC',
    latitude: '35.29569',
    longitude: '-78.021263',
    timeZoneId: 'America/New_York',
  },
  '28334': {
    zip: '28334',
    city: 'Dunn',
    state: 'NC',
    latitude: '35.310491',
    longitude: '-78.619199',
    timeZoneId: 'America/New_York',
  },
  '28335': {
    zip: '28335',
    city: 'Dunn',
    state: 'NC',
    latitude: '35.3097',
    longitude: '-78.608202',
    timeZoneId: 'America/New_York',
  },
  '28337': {
    zip: '28337',
    city: 'Elizabethtown',
    state: 'NC',
    latitude: '34.6218',
    longitude: '-78.5721',
    timeZoneId: 'America/New_York',
  },
  '28338': {
    zip: '28338',
    city: 'Ellerbe',
    state: 'NC',
    latitude: '35.065733',
    longitude: '-79.756549',
    timeZoneId: 'America/New_York',
  },
  '28339': {
    zip: '28339',
    city: 'Erwin',
    state: 'NC',
    latitude: '35.327232',
    longitude: '-78.673753',
    timeZoneId: 'America/New_York',
  },
  '28340': {
    zip: '28340',
    city: 'Fairmont',
    state: 'NC',
    latitude: '34.48494',
    longitude: '-79.08588',
    timeZoneId: 'America/New_York',
  },
  '28341': {
    zip: '28341',
    city: 'Faison',
    state: 'NC',
    latitude: '35.10706',
    longitude: '-78.103985',
    timeZoneId: 'America/New_York',
  },
  '28342': {
    zip: '28342',
    city: 'Falcon',
    state: 'NC',
    latitude: '35.187956',
    longitude: '-78.651081',
    timeZoneId: 'America/New_York',
  },
  '28343': {
    zip: '28343',
    city: 'Gibson',
    state: 'NC',
    latitude: '34.788592',
    longitude: '-79.554281',
    timeZoneId: 'America/New_York',
  },
  '28344': {
    zip: '28344',
    city: 'Godwin',
    state: 'NC',
    latitude: '35.196241',
    longitude: '-78.659945',
    timeZoneId: 'America/New_York',
  },
  '28345': {
    zip: '28345',
    city: 'Hamlet',
    state: 'NC',
    latitude: '34.889274',
    longitude: '-79.707478',
    timeZoneId: 'America/New_York',
  },
  '28347': {
    zip: '28347',
    city: 'Hoffman',
    state: 'NC',
    latitude: '35.043068',
    longitude: '-79.558659',
    timeZoneId: 'America/New_York',
  },
  '28348': {
    zip: '28348',
    city: 'Hope Mills',
    state: 'NC',
    latitude: '34.967775',
    longitude: '-78.945409',
    timeZoneId: 'America/New_York',
  },
  '28349': {
    zip: '28349',
    city: 'Kenansville',
    state: 'NC',
    latitude: '34.962078',
    longitude: '-77.960596',
    timeZoneId: 'America/New_York',
  },
  '28350': {
    zip: '28350',
    city: 'Lakeview',
    state: 'NC',
    latitude: '35.243168',
    longitude: '-79.310761',
    timeZoneId: 'America/New_York',
  },
  '28351': {
    zip: '28351',
    city: 'Laurel Hill',
    state: 'NC',
    latitude: '34.869052',
    longitude: '-79.546721',
    timeZoneId: 'America/New_York',
  },
  '28352': {
    zip: '28352',
    city: 'Laurinburg',
    state: 'NC',
    latitude: '34.767212',
    longitude: '-79.468392',
    timeZoneId: 'America/New_York',
  },
  '28353': {
    zip: '28353',
    city: 'Laurinburg',
    state: 'NC',
    latitude: '34.777024',
    longitude: '-79.465083',
    timeZoneId: 'America/New_York',
  },
  '28355': {
    zip: '28355',
    city: 'Lemon Springs',
    state: 'NC',
    latitude: '35.394801',
    longitude: '-79.188356',
    timeZoneId: 'America/New_York',
  },
  '28356': {
    zip: '28356',
    city: 'Linden',
    state: 'NC',
    latitude: '35.231971',
    longitude: '-78.797717',
    timeZoneId: 'America/New_York',
  },
  '28357': {
    zip: '28357',
    city: 'Lumber Bridge',
    state: 'NC',
    latitude: '34.89192',
    longitude: '-79.07819',
    timeZoneId: 'America/New_York',
  },
  '28358': {
    zip: '28358',
    city: 'Lumberton',
    state: 'NC',
    latitude: '34.630251',
    longitude: '-79.006164',
    timeZoneId: 'America/New_York',
  },
  '28359': {
    zip: '28359',
    city: 'Lumberton',
    state: 'NC',
    latitude: '34.622961',
    longitude: '-79.011063',
    timeZoneId: 'America/New_York',
  },
  '28360': {
    zip: '28360',
    city: 'Lumberton',
    state: 'NC',
    latitude: '34.618316',
    longitude: '-79.008979',
    timeZoneId: 'America/New_York',
  },
  '28362': {
    zip: '28362',
    city: 'Marietta',
    state: 'NC',
    latitude: '34.367126',
    longitude: '-79.11914',
    timeZoneId: 'America/New_York',
  },
  '28363': {
    zip: '28363',
    city: 'Marston',
    state: 'NC',
    latitude: '34.991473',
    longitude: '-79.618711',
    timeZoneId: 'America/New_York',
  },
  '28364': {
    zip: '28364',
    city: 'Maxton',
    state: 'NC',
    latitude: '34.738081',
    longitude: '-79.341399',
    timeZoneId: 'America/New_York',
  },
  '28365': {
    zip: '28365',
    city: 'Mount Olive',
    state: 'NC',
    latitude: '35.197486',
    longitude: '-78.066179',
    timeZoneId: 'America/New_York',
  },
  '28366': {
    zip: '28366',
    city: 'Newton Grove',
    state: 'NC',
    latitude: '35.017786',
    longitude: '-78.501727',
    timeZoneId: 'America/New_York',
  },
  '28367': {
    zip: '28367',
    city: 'Norman',
    state: 'NC',
    latitude: '35.169689',
    longitude: '-79.722074',
    timeZoneId: 'America/New_York',
  },
  '28368': {
    zip: '28368',
    city: 'Olivia',
    state: 'NC',
    latitude: '35.358676',
    longitude: '-79.108813',
    timeZoneId: 'America/New_York',
  },
  '28369': {
    zip: '28369',
    city: 'Orrum',
    state: 'NC',
    latitude: '34.427354',
    longitude: '-79.021071',
    timeZoneId: 'America/New_York',
  },
  '28370': {
    zip: '28370',
    city: 'Pinehurst',
    state: 'NC',
    latitude: '35.182077',
    longitude: '-79.460429',
    timeZoneId: 'America/New_York',
  },
  '28371': {
    zip: '28371',
    city: 'Parkton',
    state: 'NC',
    latitude: '34.900876',
    longitude: '-79.005897',
    timeZoneId: 'America/New_York',
  },
  '28372': {
    zip: '28372',
    city: 'Pembroke',
    state: 'NC',
    latitude: '34.682421',
    longitude: '-79.194731',
    timeZoneId: 'America/New_York',
  },
  '28373': {
    zip: '28373',
    city: 'Pinebluff',
    state: 'NC',
    latitude: '35.103517',
    longitude: '-79.474031',
    timeZoneId: 'America/New_York',
  },
  '28374': {
    zip: '28374',
    city: 'Pinehurst',
    state: 'NC',
    latitude: '35.186997',
    longitude: '-79.467297',
    timeZoneId: 'America/New_York',
  },
  '28375': {
    zip: '28375',
    city: 'Proctorville',
    state: 'NC',
    latitude: '34.473871',
    longitude: '-79.038584',
    timeZoneId: 'America/New_York',
  },
  '28376': {
    zip: '28376',
    city: 'Raeford',
    state: 'NC',
    latitude: '34.986107',
    longitude: '-79.206141',
    timeZoneId: 'America/New_York',
  },
  '28377': {
    zip: '28377',
    city: 'Red Springs',
    state: 'NC',
    latitude: '34.810391',
    longitude: '-79.184698',
    timeZoneId: 'America/New_York',
  },
  '28378': {
    zip: '28378',
    city: 'Rex',
    state: 'NC',
    latitude: '34.85345',
    longitude: '-79.050019',
    timeZoneId: 'America/New_York',
  },
  '28379': {
    zip: '28379',
    city: 'Rockingham',
    state: 'NC',
    latitude: '34.936426',
    longitude: '-79.763623',
    timeZoneId: 'America/New_York',
  },
  '28380': {
    zip: '28380',
    city: 'Rockingham',
    state: 'NC',
    latitude: '34.943054',
    longitude: '-79.760844',
    timeZoneId: 'America/New_York',
  },
  '28382': {
    zip: '28382',
    city: 'Roseboro',
    state: 'NC',
    latitude: '34.950848',
    longitude: '-78.516195',
    timeZoneId: 'America/New_York',
  },
  '28383': {
    zip: '28383',
    city: 'Rowland',
    state: 'NC',
    latitude: '34.536371',
    longitude: '-79.290511',
    timeZoneId: 'America/New_York',
  },
  '28384': {
    zip: '28384',
    city: 'Saint Pauls',
    state: 'NC',
    latitude: '34.802763',
    longitude: '-78.975198',
    timeZoneId: 'America/New_York',
  },
  '28385': {
    zip: '28385',
    city: 'Salemburg',
    state: 'NC',
    latitude: '35.016623',
    longitude: '-78.504209',
    timeZoneId: 'America/New_York',
  },
  '28386': {
    zip: '28386',
    city: 'Shannon',
    state: 'NC',
    latitude: '34.904009',
    longitude: '-79.166196',
    timeZoneId: 'America/New_York',
  },
  '28387': {
    zip: '28387',
    city: 'Southern Pines',
    state: 'NC',
    latitude: '35.174091',
    longitude: '-79.390844',
    timeZoneId: 'America/New_York',
  },
  '28388': {
    zip: '28388',
    city: 'Southern Pines',
    state: 'NC',
    latitude: '35.18181',
    longitude: '-79.401905',
    timeZoneId: 'America/New_York',
  },
  '28390': {
    zip: '28390',
    city: 'Spring Lake',
    state: 'NC',
    latitude: '35.175116',
    longitude: '-78.976743',
    timeZoneId: 'America/New_York',
  },
  '28391': {
    zip: '28391',
    city: 'Stedman',
    state: 'NC',
    latitude: '35.021874',
    longitude: '-78.704641',
    timeZoneId: 'America/New_York',
  },
  '28392': {
    zip: '28392',
    city: 'Tar Heel',
    state: 'NC',
    latitude: '34.733176',
    longitude: '-78.793919',
    timeZoneId: 'America/New_York',
  },
  '28393': {
    zip: '28393',
    city: 'Turkey',
    state: 'NC',
    latitude: '34.955656',
    longitude: '-78.206696',
    timeZoneId: 'America/New_York',
  },
  '28394': {
    zip: '28394',
    city: 'Vass',
    state: 'NC',
    latitude: '35.242723',
    longitude: '-79.227747',
    timeZoneId: 'America/New_York',
  },
  '28395': {
    zip: '28395',
    city: 'Wade',
    state: 'NC',
    latitude: '35.160288',
    longitude: '-78.735146',
    timeZoneId: 'America/New_York',
  },
  '28396': {
    zip: '28396',
    city: 'Wagram',
    state: 'NC',
    latitude: '34.909224',
    longitude: '-79.383666',
    timeZoneId: 'America/New_York',
  },
  '28398': {
    zip: '28398',
    city: 'Warsaw',
    state: 'NC',
    latitude: '34.98054',
    longitude: '-78.09456',
    timeZoneId: 'America/New_York',
  },
  '28399': {
    zip: '28399',
    city: 'White Oak',
    state: 'NC',
    latitude: '34.78767',
    longitude: '-78.722092',
    timeZoneId: 'America/New_York',
  },
  '28401': {
    zip: '28401',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.229055',
    longitude: '-77.935953',
    timeZoneId: 'America/New_York',
  },
  '28402': {
    zip: '28402',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.238808',
    longitude: '-77.948973',
    timeZoneId: 'America/New_York',
  },
  '28403': {
    zip: '28403',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.204361',
    longitude: '-77.883608',
    timeZoneId: 'America/New_York',
  },
  '28404': {
    zip: '28404',
    city: 'Wilmington',
    state: 'NC',
    latitude: '33.927021',
    longitude: '-78.069326',
    timeZoneId: 'America/New_York',
  },
  '28405': {
    zip: '28405',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.28172',
    longitude: '-77.82474',
    timeZoneId: 'America/New_York',
  },
  '28406': {
    zip: '28406',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.178185',
    longitude: '-77.885847',
    timeZoneId: 'America/New_York',
  },
  '28407': {
    zip: '28407',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.156508',
    longitude: '-77.894445',
    timeZoneId: 'America/New_York',
  },
  '28408': {
    zip: '28408',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.226075',
    longitude: '-77.946552',
    timeZoneId: 'America/New_York',
  },
  '28409': {
    zip: '28409',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.164792',
    longitude: '-77.873138',
    timeZoneId: 'America/New_York',
  },
  '28410': {
    zip: '28410',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.125636',
    longitude: '-77.953828',
    timeZoneId: 'America/New_York',
  },
  '28411': {
    zip: '28411',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.227651',
    longitude: '-77.945987',
    timeZoneId: 'America/New_York',
  },
  '28412': {
    zip: '28412',
    city: 'Wilmington',
    state: 'NC',
    latitude: '34.142287',
    longitude: '-77.915225',
    timeZoneId: 'America/New_York',
  },
  '28420': {
    zip: '28420',
    city: 'Ash',
    state: 'NC',
    latitude: '34.05604',
    longitude: '-78.517548',
    timeZoneId: 'America/New_York',
  },
  '28421': {
    zip: '28421',
    city: 'Atkinson',
    state: 'NC',
    latitude: '34.527759',
    longitude: '-78.173939',
    timeZoneId: 'America/New_York',
  },
  '28422': {
    zip: '28422',
    city: 'Bolivia',
    state: 'NC',
    latitude: '34.0341',
    longitude: '-78.16716',
    timeZoneId: 'America/New_York',
  },
  '28423': {
    zip: '28423',
    city: 'Bolton',
    state: 'NC',
    latitude: '34.178325',
    longitude: '-78.398021',
    timeZoneId: 'America/New_York',
  },
  '28424': {
    zip: '28424',
    city: 'Brunswick',
    state: 'NC',
    latitude: '34.28927',
    longitude: '-78.705899',
    timeZoneId: 'America/New_York',
  },
  '28425': {
    zip: '28425',
    city: 'Burgaw',
    state: 'NC',
    latitude: '34.550127',
    longitude: '-77.935342',
    timeZoneId: 'America/New_York',
  },
  '28428': {
    zip: '28428',
    city: 'Carolina Beach',
    state: 'NC',
    latitude: '34.037053',
    longitude: '-77.901405',
    timeZoneId: 'America/New_York',
  },
  '28429': {
    zip: '28429',
    city: 'Castle Hayne',
    state: 'NC',
    latitude: '34.325657',
    longitude: '-77.908843',
    timeZoneId: 'America/New_York',
  },
  '28430': {
    zip: '28430',
    city: 'Cerro Gordo',
    state: 'NC',
    latitude: '34.28704',
    longitude: '-78.927792',
    timeZoneId: 'America/New_York',
  },
  '28431': {
    zip: '28431',
    city: 'Chadbourn',
    state: 'NC',
    latitude: '34.323716',
    longitude: '-78.823898',
    timeZoneId: 'America/New_York',
  },
  '28432': {
    zip: '28432',
    city: 'Clarendon',
    state: 'NC',
    latitude: '34.175534',
    longitude: '-78.794338',
    timeZoneId: 'America/New_York',
  },
  '28433': {
    zip: '28433',
    city: 'Clarkton',
    state: 'NC',
    latitude: '34.518659',
    longitude: '-78.64365',
    timeZoneId: 'America/New_York',
  },
  '28434': {
    zip: '28434',
    city: 'Council',
    state: 'NC',
    latitude: '34.448316',
    longitude: '-78.437794',
    timeZoneId: 'America/New_York',
  },
  '28435': {
    zip: '28435',
    city: 'Currie',
    state: 'NC',
    latitude: '34.410005',
    longitude: '-78.101347',
    timeZoneId: 'America/New_York',
  },
  '28436': {
    zip: '28436',
    city: 'Delco',
    state: 'NC',
    latitude: '34.341124',
    longitude: '-78.187982',
    timeZoneId: 'America/New_York',
  },
  '28438': {
    zip: '28438',
    city: 'Evergreen',
    state: 'NC',
    latitude: '34.42021',
    longitude: '-78.81783',
    timeZoneId: 'America/New_York',
  },
  '28439': {
    zip: '28439',
    city: 'Fair Bluff',
    state: 'NC',
    latitude: '34.308288',
    longitude: '-79.00305',
    timeZoneId: 'America/New_York',
  },
  '28441': {
    zip: '28441',
    city: 'Garland',
    state: 'NC',
    latitude: '34.82228',
    longitude: '-78.329017',
    timeZoneId: 'America/New_York',
  },
  '28442': {
    zip: '28442',
    city: 'Hallsboro',
    state: 'NC',
    latitude: '34.293827',
    longitude: '-78.599687',
    timeZoneId: 'America/New_York',
  },
  '28443': {
    zip: '28443',
    city: 'Hampstead',
    state: 'NC',
    latitude: '34.394896',
    longitude: '-77.651107',
    timeZoneId: 'America/New_York',
  },
  '28444': {
    zip: '28444',
    city: 'Harrells',
    state: 'NC',
    latitude: '34.725227',
    longitude: '-78.191649',
    timeZoneId: 'America/New_York',
  },
  '28445': {
    zip: '28445',
    city: 'Holly Ridge',
    state: 'NC',
    latitude: '34.47996',
    longitude: '-77.50236',
    timeZoneId: 'America/New_York',
  },
  '28447': {
    zip: '28447',
    city: 'Ivanhoe',
    state: 'NC',
    latitude: '34.693305',
    longitude: '-78.162602',
    timeZoneId: 'America/New_York',
  },
  '28448': {
    zip: '28448',
    city: 'Kelly',
    state: 'NC',
    latitude: '34.478193',
    longitude: '-78.314764',
    timeZoneId: 'America/New_York',
  },
  '28449': {
    zip: '28449',
    city: 'Kure Beach',
    state: 'NC',
    latitude: '33.997171',
    longitude: '-77.909349',
    timeZoneId: 'America/New_York',
  },
  '28450': {
    zip: '28450',
    city: 'Lake Waccamaw',
    state: 'NC',
    latitude: '34.315897',
    longitude: '-78.505018',
    timeZoneId: 'America/New_York',
  },
  '28451': {
    zip: '28451',
    city: 'Leland',
    state: 'NC',
    latitude: '34.21376',
    longitude: '-78.038355',
    timeZoneId: 'America/New_York',
  },
  '28452': {
    zip: '28452',
    city: 'Longwood',
    state: 'NC',
    latitude: '33.95556',
    longitude: '-78.550208',
    timeZoneId: 'America/New_York',
  },
  '28453': {
    zip: '28453',
    city: 'Magnolia',
    state: 'NC',
    latitude: '34.895031',
    longitude: '-78.043144',
    timeZoneId: 'America/New_York',
  },
  '28454': {
    zip: '28454',
    city: 'Maple Hill',
    state: 'NC',
    latitude: '34.600327',
    longitude: '-77.767276',
    timeZoneId: 'America/New_York',
  },
  '28455': {
    zip: '28455',
    city: 'Nakina',
    state: 'NC',
    latitude: '34.112441',
    longitude: '-78.653824',
    timeZoneId: 'America/New_York',
  },
  '28456': {
    zip: '28456',
    city: 'Riegelwood',
    state: 'NC',
    latitude: '34.3449',
    longitude: '-78.25782',
    timeZoneId: 'America/New_York',
  },
  '28457': {
    zip: '28457',
    city: 'Rocky Point',
    state: 'NC',
    latitude: '34.410616',
    longitude: '-77.91232',
    timeZoneId: 'America/New_York',
  },
  '28458': {
    zip: '28458',
    city: 'Rose Hill',
    state: 'NC',
    latitude: '34.825637',
    longitude: '-78.025004',
    timeZoneId: 'America/New_York',
  },
  '28459': {
    zip: '28459',
    city: 'Shallotte',
    state: 'NC',
    latitude: '33.907639',
    longitude: '-78.482064',
    timeZoneId: 'America/New_York',
  },
  '28460': {
    zip: '28460',
    city: 'Sneads Ferry',
    state: 'NC',
    latitude: '34.531918',
    longitude: '-77.387722',
    timeZoneId: 'America/New_York',
  },
  '28461': {
    zip: '28461',
    city: 'Southport',
    state: 'NC',
    latitude: '33.98328',
    longitude: '-78.04848',
    timeZoneId: 'America/New_York',
  },
  '28462': {
    zip: '28462',
    city: 'Supply',
    state: 'NC',
    latitude: '33.97272',
    longitude: '-78.28986',
    timeZoneId: 'America/New_York',
  },
  '28463': {
    zip: '28463',
    city: 'Tabor City',
    state: 'NC',
    latitude: '34.147085',
    longitude: '-78.875878',
    timeZoneId: 'America/New_York',
  },
  '28464': {
    zip: '28464',
    city: 'Teachey',
    state: 'NC',
    latitude: '34.771922',
    longitude: '-78.014009',
    timeZoneId: 'America/New_York',
  },
  '28465': {
    zip: '28465',
    city: 'Oak Island',
    state: 'NC',
    latitude: '33.915678',
    longitude: '-78.132541',
    timeZoneId: 'America/New_York',
  },
  '28466': {
    zip: '28466',
    city: 'Wallace',
    state: 'NC',
    latitude: '34.736924',
    longitude: '-77.993819',
    timeZoneId: 'America/New_York',
  },
  '28467': {
    zip: '28467',
    city: 'Calabash',
    state: 'NC',
    latitude: '33.898397',
    longitude: '-78.578267',
    timeZoneId: 'America/New_York',
  },
  '28468': {
    zip: '28468',
    city: 'Sunset Beach',
    state: 'NC',
    latitude: '33.884891',
    longitude: '-78.50872',
    timeZoneId: 'America/New_York',
  },
  '28469': {
    zip: '28469',
    city: 'Ocean Isle Beach',
    state: 'NC',
    latitude: '33.923901',
    longitude: '-78.459091',
    timeZoneId: 'America/New_York',
  },
  '28470': {
    zip: '28470',
    city: 'Shallotte',
    state: 'NC',
    latitude: '33.959446',
    longitude: '-78.395981',
    timeZoneId: 'America/New_York',
  },
  '28472': {
    zip: '28472',
    city: 'Whiteville',
    state: 'NC',
    latitude: '34.334306',
    longitude: '-78.702996',
    timeZoneId: 'America/New_York',
  },
  '28478': {
    zip: '28478',
    city: 'Willard',
    state: 'NC',
    latitude: '34.665443',
    longitude: '-78.066376',
    timeZoneId: 'America/New_York',
  },
  '28479': {
    zip: '28479',
    city: 'Winnabow',
    state: 'NC',
    latitude: '34.205993',
    longitude: '-78.105518',
    timeZoneId: 'America/New_York',
  },
  '28480': {
    zip: '28480',
    city: 'Wrightsville Beach',
    state: 'NC',
    latitude: '34.20724',
    longitude: '-77.79633',
    timeZoneId: 'America/New_York',
  },
  '28501': {
    zip: '28501',
    city: 'Kinston',
    state: 'NC',
    latitude: '35.273461',
    longitude: '-77.597639',
    timeZoneId: 'America/New_York',
  },
  '28502': {
    zip: '28502',
    city: 'Kinston',
    state: 'NC',
    latitude: '35.261679',
    longitude: '-77.577928',
    timeZoneId: 'America/New_York',
  },
  '28503': {
    zip: '28503',
    city: 'Kinston',
    state: 'NC',
    latitude: '35.280064',
    longitude: '-77.582905',
    timeZoneId: 'America/New_York',
  },
  '28504': {
    zip: '28504',
    city: 'Kinston',
    state: 'NC',
    latitude: '35.275222',
    longitude: '-77.589004',
    timeZoneId: 'America/New_York',
  },
  '28508': {
    zip: '28508',
    city: 'Albertson',
    state: 'NC',
    latitude: '35.113254',
    longitude: '-77.852057',
    timeZoneId: 'America/New_York',
  },
  '28509': {
    zip: '28509',
    city: 'Alliance',
    state: 'NC',
    latitude: '35.146647',
    longitude: '-76.799183',
    timeZoneId: 'America/New_York',
  },
  '28510': {
    zip: '28510',
    city: 'Arapahoe',
    state: 'NC',
    latitude: '34.979257',
    longitude: '-76.825851',
    timeZoneId: 'America/New_York',
  },
  '28511': {
    zip: '28511',
    city: 'Atlantic',
    state: 'NC',
    latitude: '34.893362',
    longitude: '-76.336977',
    timeZoneId: 'America/New_York',
  },
  '28512': {
    zip: '28512',
    city: 'Atlantic Beach',
    state: 'NC',
    latitude: '34.699394',
    longitude: '-76.781687',
    timeZoneId: 'America/New_York',
  },
  '28513': {
    zip: '28513',
    city: 'Ayden',
    state: 'NC',
    latitude: '35.4703',
    longitude: '-77.423119',
    timeZoneId: 'America/New_York',
  },
  '28515': {
    zip: '28515',
    city: 'Bayboro',
    state: 'NC',
    latitude: '35.132651',
    longitude: '-76.760131',
    timeZoneId: 'America/New_York',
  },
  '28516': {
    zip: '28516',
    city: 'Beaufort',
    state: 'NC',
    latitude: '34.731559',
    longitude: '-76.655049',
    timeZoneId: 'America/New_York',
  },
  '28518': {
    zip: '28518',
    city: 'Beulaville',
    state: 'NC',
    latitude: '34.920149',
    longitude: '-77.821894',
    timeZoneId: 'America/New_York',
  },
  '28519': {
    zip: '28519',
    city: 'Bridgeton',
    state: 'NC',
    latitude: '35.122069',
    longitude: '-77.020064',
    timeZoneId: 'America/New_York',
  },
  '28520': {
    zip: '28520',
    city: 'Cedar Island',
    state: 'NC',
    latitude: '34.94196',
    longitude: '-76.33561',
    timeZoneId: 'America/New_York',
  },
  '28521': {
    zip: '28521',
    city: 'Chinquapin',
    state: 'NC',
    latitude: '34.82784',
    longitude: '-77.744935',
    timeZoneId: 'America/New_York',
  },
  '28522': {
    zip: '28522',
    city: 'Comfort',
    state: 'NC',
    latitude: '35.003386',
    longitude: '-77.522269',
    timeZoneId: 'America/New_York',
  },
  '28523': {
    zip: '28523',
    city: 'Cove City',
    state: 'NC',
    latitude: '35.209185',
    longitude: '-77.290206',
    timeZoneId: 'America/New_York',
  },
  '28524': {
    zip: '28524',
    city: 'Davis',
    state: 'NC',
    latitude: '34.795464',
    longitude: '-76.456798',
    timeZoneId: 'America/New_York',
  },
  '28525': {
    zip: '28525',
    city: 'Deep Run',
    state: 'NC',
    latitude: '35.127332',
    longitude: '-77.727139',
    timeZoneId: 'America/New_York',
  },
  '28526': {
    zip: '28526',
    city: 'Dover',
    state: 'NC',
    latitude: '35.26746',
    longitude: '-77.37402',
    timeZoneId: 'America/New_York',
  },
  '28527': {
    zip: '28527',
    city: 'Ernul',
    state: 'NC',
    latitude: '35.243238',
    longitude: '-77.026924',
    timeZoneId: 'America/New_York',
  },
  '28528': {
    zip: '28528',
    city: 'Gloucester',
    state: 'NC',
    latitude: '34.684622',
    longitude: '-76.526421',
    timeZoneId: 'America/New_York',
  },
  '28529': {
    zip: '28529',
    city: 'Grantsboro',
    state: 'NC',
    latitude: '35.14027',
    longitude: '-76.81998',
    timeZoneId: 'America/New_York',
  },
  '28530': {
    zip: '28530',
    city: 'Grifton',
    state: 'NC',
    latitude: '35.379849',
    longitude: '-77.429291',
    timeZoneId: 'America/New_York',
  },
  '28531': {
    zip: '28531',
    city: 'Harkers Island',
    state: 'NC',
    latitude: '34.726336',
    longitude: '-76.57094',
    timeZoneId: 'America/New_York',
  },
  '28532': {
    zip: '28532',
    city: 'Havelock',
    state: 'NC',
    latitude: '34.893026',
    longitude: '-76.90137',
    timeZoneId: 'America/New_York',
  },
  '28533': {
    zip: '28533',
    city: 'Cherry Point',
    state: 'NC',
    latitude: '34.878889',
    longitude: '-76.901667',
    timeZoneId: 'America/New_York',
  },
  '28537': {
    zip: '28537',
    city: 'Hobucken',
    state: 'NC',
    latitude: '35.270599',
    longitude: '-76.539775',
    timeZoneId: 'America/New_York',
  },
  '28538': {
    zip: '28538',
    city: 'Hookerton',
    state: 'NC',
    latitude: '35.427634',
    longitude: '-77.54805',
    timeZoneId: 'America/New_York',
  },
  '28539': {
    zip: '28539',
    city: 'Hubert',
    state: 'NC',
    latitude: '34.696204',
    longitude: '-77.208383',
    timeZoneId: 'America/New_York',
  },
  '28540': {
    zip: '28540',
    city: 'Jacksonville',
    state: 'NC',
    latitude: '34.764003',
    longitude: '-77.434331',
    timeZoneId: 'America/New_York',
  },
  '28541': {
    zip: '28541',
    city: 'Jacksonville',
    state: 'NC',
    latitude: '34.764959',
    longitude: '-77.403904',
    timeZoneId: 'America/New_York',
  },
  '28542': {
    zip: '28542',
    city: 'Camp Lejeune',
    state: 'NC',
    latitude: '34.697778',
    longitude: '-77.352038',
    timeZoneId: 'America/New_York',
  },
  '28543': {
    zip: '28543',
    city: 'Tarawa Terrace',
    state: 'NC',
    latitude: '34.734194',
    longitude: '-77.377379',
    timeZoneId: 'America/New_York',
  },
  '28544': {
    zip: '28544',
    city: 'Midway Park',
    state: 'NC',
    latitude: '34.73262',
    longitude: '-77.32764',
    timeZoneId: 'America/New_York',
  },
  '28545': {
    zip: '28545',
    city: 'McCutcheon Field',
    state: 'NC',
    latitude: '34.683745',
    longitude: '-77.34191',
    timeZoneId: 'America/New_York',
  },
  '28546': {
    zip: '28546',
    city: 'Jacksonville',
    state: 'NC',
    latitude: '34.774523',
    longitude: '-77.381406',
    timeZoneId: 'America/New_York',
  },
  '28547': {
    zip: '28547',
    city: 'Camp Lejeune',
    state: 'NC',
    latitude: '34.704321',
    longitude: '-77.349467',
    timeZoneId: 'America/New_York',
  },
  '28551': {
    zip: '28551',
    city: 'La Grange',
    state: 'NC',
    latitude: '35.307956',
    longitude: '-77.789886',
    timeZoneId: 'America/New_York',
  },
  '28552': {
    zip: '28552',
    city: 'Lowland',
    state: 'NC',
    latitude: '35.306995',
    longitude: '-76.569858',
    timeZoneId: 'America/New_York',
  },
  '28553': {
    zip: '28553',
    city: 'Marshallberg',
    state: 'NC',
    latitude: '34.729019',
    longitude: '-76.510873',
    timeZoneId: 'America/New_York',
  },
  '28554': {
    zip: '28554',
    city: 'Maury',
    state: 'NC',
    latitude: '35.482968',
    longitude: '-77.586178',
    timeZoneId: 'America/New_York',
  },
  '28555': {
    zip: '28555',
    city: 'Maysville',
    state: 'NC',
    latitude: '34.842466',
    longitude: '-77.24709',
    timeZoneId: 'America/New_York',
  },
  '28556': {
    zip: '28556',
    city: 'Merritt',
    state: 'NC',
    latitude: '35.110791',
    longitude: '-76.694764',
    timeZoneId: 'America/New_York',
  },
  '28557': {
    zip: '28557',
    city: 'Morehead City',
    state: 'NC',
    latitude: '34.725535',
    longitude: '-76.758443',
    timeZoneId: 'America/New_York',
  },
  '28560': {
    zip: '28560',
    city: 'New Bern',
    state: 'NC',
    latitude: '35.097221',
    longitude: '-77.029014',
    timeZoneId: 'America/New_York',
  },
  '28561': {
    zip: '28561',
    city: 'New Bern',
    state: 'NC',
    latitude: '35.101125',
    longitude: '-77.077035',
    timeZoneId: 'America/New_York',
  },
  '28562': {
    zip: '28562',
    city: 'New Bern',
    state: 'NC',
    latitude: '35.07174',
    longitude: '-77.09958',
    timeZoneId: 'America/New_York',
  },
  '28563': {
    zip: '28563',
    city: 'New Bern',
    state: 'NC',
    latitude: '35.13821',
    longitude: '-77.00635',
    timeZoneId: 'America/New_York',
  },
  '28564': {
    zip: '28564',
    city: 'New Bern',
    state: 'NC',
    latitude: '35.074831',
    longitude: '-77.022188',
    timeZoneId: 'America/New_York',
  },
  '28570': {
    zip: '28570',
    city: 'Newport',
    state: 'NC',
    latitude: '34.770637',
    longitude: '-76.87077',
    timeZoneId: 'America/New_York',
  },
  '28571': {
    zip: '28571',
    city: 'Oriental',
    state: 'NC',
    latitude: '35.031095',
    longitude: '-76.695847',
    timeZoneId: 'America/New_York',
  },
  '28572': {
    zip: '28572',
    city: 'Pink Hill',
    state: 'NC',
    latitude: '35.052316',
    longitude: '-77.74526',
    timeZoneId: 'America/New_York',
  },
  '28573': {
    zip: '28573',
    city: 'Pollocksville',
    state: 'NC',
    latitude: '35.003901',
    longitude: '-77.230433',
    timeZoneId: 'America/New_York',
  },
  '28574': {
    zip: '28574',
    city: 'Richlands',
    state: 'NC',
    latitude: '34.83171',
    longitude: '-77.576529',
    timeZoneId: 'America/New_York',
  },
  '28575': {
    zip: '28575',
    city: 'Salter Path',
    state: 'NC',
    latitude: '34.685333',
    longitude: '-76.902239',
    timeZoneId: 'America/New_York',
  },
  '28577': {
    zip: '28577',
    city: 'Sealevel',
    state: 'NC',
    latitude: '34.893197',
    longitude: '-76.392189',
    timeZoneId: 'America/New_York',
  },
  '28578': {
    zip: '28578',
    city: 'Seven Springs',
    state: 'NC',
    latitude: '35.208045',
    longitude: '-77.918494',
    timeZoneId: 'America/New_York',
  },
  '28579': {
    zip: '28579',
    city: 'Smyrna',
    state: 'NC',
    latitude: '34.800896',
    longitude: '-76.512781',
    timeZoneId: 'America/New_York',
  },
  '28580': {
    zip: '28580',
    city: 'Snow Hill',
    state: 'NC',
    latitude: '35.447755',
    longitude: '-77.678735',
    timeZoneId: 'America/New_York',
  },
  '28581': {
    zip: '28581',
    city: 'Stacy',
    state: 'NC',
    latitude: '34.857921',
    longitude: '-76.42332',
    timeZoneId: 'America/New_York',
  },
  '28582': {
    zip: '28582',
    city: 'Stella',
    state: 'NC',
    latitude: '34.751786',
    longitude: '-77.152727',
    timeZoneId: 'America/New_York',
  },
  '28583': {
    zip: '28583',
    city: 'Stonewall',
    state: 'NC',
    latitude: '35.13746',
    longitude: '-76.738621',
    timeZoneId: 'America/New_York',
  },
  '28584': {
    zip: '28584',
    city: 'Swansboro',
    state: 'NC',
    latitude: '34.701094',
    longitude: '-77.065759',
    timeZoneId: 'America/New_York',
  },
  '28585': {
    zip: '28585',
    city: 'Trenton',
    state: 'NC',
    latitude: '35.066822',
    longitude: '-77.410809',
    timeZoneId: 'America/New_York',
  },
  '28586': {
    zip: '28586',
    city: 'Vanceboro',
    state: 'NC',
    latitude: '35.299473',
    longitude: '-77.145872',
    timeZoneId: 'America/New_York',
  },
  '28587': {
    zip: '28587',
    city: 'Vandemere',
    state: 'NC',
    latitude: '35.182811',
    longitude: '-76.661444',
    timeZoneId: 'America/New_York',
  },
  '28589': {
    zip: '28589',
    city: 'Williston',
    state: 'NC',
    latitude: '34.78942',
    longitude: '-76.505253',
    timeZoneId: 'America/New_York',
  },
  '28590': {
    zip: '28590',
    city: 'Winterville',
    state: 'NC',
    latitude: '35.535325',
    longitude: '-77.395248',
    timeZoneId: 'America/New_York',
  },
  '28594': {
    zip: '28594',
    city: 'Emerald Isle',
    state: 'NC',
    latitude: '34.66362',
    longitude: '-77.00508',
    timeZoneId: 'America/New_York',
  },
  '28601': {
    zip: '28601',
    city: 'Hickory',
    state: 'NC',
    latitude: '35.75358',
    longitude: '-81.3249',
    timeZoneId: 'America/New_York',
  },
  '28602': {
    zip: '28602',
    city: 'Hickory',
    state: 'NC',
    latitude: '35.6616',
    longitude: '-81.37032',
    timeZoneId: 'America/New_York',
  },
  '28603': {
    zip: '28603',
    city: 'Hickory',
    state: 'NC',
    latitude: '35.757664',
    longitude: '-81.327333',
    timeZoneId: 'America/New_York',
  },
  '28604': {
    zip: '28604',
    city: 'Banner Elk',
    state: 'NC',
    latitude: '36.1722',
    longitude: '-81.82518',
    timeZoneId: 'America/New_York',
  },
  '28605': {
    zip: '28605',
    city: 'Blowing Rock',
    state: 'NC',
    latitude: '36.116573',
    longitude: '-81.678226',
    timeZoneId: 'America/New_York',
  },
  '28606': {
    zip: '28606',
    city: 'Boomer',
    state: 'NC',
    latitude: '36.226493',
    longitude: '-81.099183',
    timeZoneId: 'America/New_York',
  },
  '28607': {
    zip: '28607',
    city: 'Boone',
    state: 'NC',
    latitude: '36.21864',
    longitude: '-81.6546',
    timeZoneId: 'America/New_York',
  },
  '28608': {
    zip: '28608',
    city: 'Boone',
    state: 'NC',
    latitude: '36.211626',
    longitude: '-81.668282',
    timeZoneId: 'America/New_York',
  },
  '28609': {
    zip: '28609',
    city: 'Catawba',
    state: 'NC',
    latitude: '35.667368',
    longitude: '-80.999551',
    timeZoneId: 'America/New_York',
  },
  '28610': {
    zip: '28610',
    city: 'Claremont',
    state: 'NC',
    latitude: '35.707009',
    longitude: '-81.148762',
    timeZoneId: 'America/New_York',
  },
  '28611': {
    zip: '28611',
    city: 'Collettsville',
    state: 'NC',
    latitude: '35.992485',
    longitude: '-81.713513',
    timeZoneId: 'America/New_York',
  },
  '28612': {
    zip: '28612',
    city: 'Connelly Springs',
    state: 'NC',
    latitude: '35.819784',
    longitude: '-81.615772',
    timeZoneId: 'America/New_York',
  },
  '28613': {
    zip: '28613',
    city: 'Conover',
    state: 'NC',
    latitude: '35.71182',
    longitude: '-81.209282',
    timeZoneId: 'America/New_York',
  },
  '28615': {
    zip: '28615',
    city: 'Creston',
    state: 'NC',
    latitude: '36.44718',
    longitude: '-81.65928',
    timeZoneId: 'America/New_York',
  },
  '28616': {
    zip: '28616',
    city: 'Crossnore',
    state: 'NC',
    latitude: '36.090448',
    longitude: '-81.854065',
    timeZoneId: 'America/New_York',
  },
  '28617': {
    zip: '28617',
    city: 'Crumpler',
    state: 'NC',
    latitude: '36.46086',
    longitude: '-81.41364',
    timeZoneId: 'America/New_York',
  },
  '28618': {
    zip: '28618',
    city: 'Deep Gap',
    state: 'NC',
    latitude: '36.192222',
    longitude: '-81.529594',
    timeZoneId: 'America/New_York',
  },
  '28619': {
    zip: '28619',
    city: 'Drexel',
    state: 'NC',
    latitude: '35.753757',
    longitude: '-81.606879',
    timeZoneId: 'America/New_York',
  },
  '28621': {
    zip: '28621',
    city: 'Elkin',
    state: 'NC',
    latitude: '36.31572',
    longitude: '-80.81616',
    timeZoneId: 'America/New_York',
  },
  '28622': {
    zip: '28622',
    city: 'Elk Park',
    state: 'NC',
    latitude: '36.175674',
    longitude: '-81.95517',
    timeZoneId: 'America/New_York',
  },
  '28623': {
    zip: '28623',
    city: 'Ennice',
    state: 'NC',
    latitude: '36.52932',
    longitude: '-80.96922',
    timeZoneId: 'America/New_York',
  },
  '28624': {
    zip: '28624',
    city: 'Ferguson',
    state: 'NC',
    latitude: '36.15666',
    longitude: '-81.39384',
    timeZoneId: 'America/New_York',
  },
  '28625': {
    zip: '28625',
    city: 'Statesville',
    state: 'NC',
    latitude: '35.780234',
    longitude: '-80.886089',
    timeZoneId: 'America/New_York',
  },
  '28626': {
    zip: '28626',
    city: 'Fleetwood',
    state: 'NC',
    latitude: '36.404248',
    longitude: '-81.493632',
    timeZoneId: 'America/New_York',
  },
  '28627': {
    zip: '28627',
    city: 'Glade Valley',
    state: 'NC',
    latitude: '36.440899',
    longitude: '-81.0175',
    timeZoneId: 'America/New_York',
  },
  '28628': {
    zip: '28628',
    city: 'Glen Alpine',
    state: 'NC',
    latitude: '35.721926',
    longitude: '-81.782025',
    timeZoneId: 'America/New_York',
  },
  '28629': {
    zip: '28629',
    city: 'Glendale Springs',
    state: 'NC',
    latitude: '36.347119',
    longitude: '-81.382714',
    timeZoneId: 'America/New_York',
  },
  '28630': {
    zip: '28630',
    city: 'Granite Falls',
    state: 'NC',
    latitude: '35.8161',
    longitude: '-81.45714',
    timeZoneId: 'America/New_York',
  },
  '28631': {
    zip: '28631',
    city: 'Grassy Creek',
    state: 'NC',
    latitude: '36.547139',
    longitude: '-81.434483',
    timeZoneId: 'America/New_York',
  },
  '28633': {
    zip: '28633',
    city: 'Lenoir',
    state: 'NC',
    latitude: '35.902482',
    longitude: '-81.535317',
    timeZoneId: 'America/New_York',
  },
  '28634': {
    zip: '28634',
    city: 'Harmony',
    state: 'NC',
    latitude: '35.956738',
    longitude: '-80.772702',
    timeZoneId: 'America/New_York',
  },
  '28635': {
    zip: '28635',
    city: 'Hays',
    state: 'NC',
    latitude: '36.356569',
    longitude: '-81.12708',
    timeZoneId: 'America/New_York',
  },
  '28636': {
    zip: '28636',
    city: 'Hiddenite',
    state: 'NC',
    latitude: '35.997778',
    longitude: '-81.105556',
    timeZoneId: 'America/New_York',
  },
  '28637': {
    zip: '28637',
    city: 'Hildebran',
    state: 'NC',
    latitude: '35.718235',
    longitude: '-81.426789',
    timeZoneId: 'America/New_York',
  },
  '28638': {
    zip: '28638',
    city: 'Hudson',
    state: 'NC',
    latitude: '35.845122',
    longitude: '-81.491631',
    timeZoneId: 'America/New_York',
  },
  '28640': {
    zip: '28640',
    city: 'Jefferson',
    state: 'NC',
    latitude: '36.40914',
    longitude: '-81.41046',
    timeZoneId: 'America/New_York',
  },
  '28641': {
    zip: '28641',
    city: 'Jonas Ridge',
    state: 'NC',
    latitude: '35.884283',
    longitude: '-81.739742',
    timeZoneId: 'America/New_York',
  },
  '28642': {
    zip: '28642',
    city: 'Jonesville',
    state: 'NC',
    latitude: '36.23694',
    longitude: '-80.78232',
    timeZoneId: 'America/New_York',
  },
  '28643': {
    zip: '28643',
    city: 'Lansing',
    state: 'NC',
    latitude: '36.49',
    longitude: '-81.51',
    timeZoneId: 'America/New_York',
  },
  '28644': {
    zip: '28644',
    city: 'Laurel Springs',
    state: 'NC',
    latitude: '36.448151',
    longitude: '-81.252551',
    timeZoneId: 'America/New_York',
  },
  '28645': {
    zip: '28645',
    city: 'Lenoir',
    state: 'NC',
    latitude: '35.949',
    longitude: '-81.5298',
    timeZoneId: 'America/New_York',
  },
  '28646': {
    zip: '28646',
    city: 'Linville',
    state: 'NC',
    latitude: '36.067758',
    longitude: '-81.877449',
    timeZoneId: 'America/New_York',
  },
  '28647': {
    zip: '28647',
    city: 'Linville Falls',
    state: 'NC',
    latitude: '35.956741',
    longitude: '-81.94418',
    timeZoneId: 'America/New_York',
  },
  '28649': {
    zip: '28649',
    city: 'Mc Grady',
    state: 'NC',
    latitude: '36.321231',
    longitude: '-81.188583',
    timeZoneId: 'America/New_York',
  },
  '28650': {
    zip: '28650',
    city: 'Maiden',
    state: 'NC',
    latitude: '35.574796',
    longitude: '-81.20773',
    timeZoneId: 'America/New_York',
  },
  '28651': {
    zip: '28651',
    city: 'Millers Creek',
    state: 'NC',
    latitude: '36.270637',
    longitude: '-81.284124',
    timeZoneId: 'America/New_York',
  },
  '28652': {
    zip: '28652',
    city: 'Minneapolis',
    state: 'NC',
    latitude: '36.099899',
    longitude: '-81.987145',
    timeZoneId: 'America/New_York',
  },
  '28653': {
    zip: '28653',
    city: 'Montezuma',
    state: 'NC',
    latitude: '36.065927',
    longitude: '-81.904155',
    timeZoneId: 'America/New_York',
  },
  '28654': {
    zip: '28654',
    city: 'Moravian Falls',
    state: 'NC',
    latitude: '36.099261',
    longitude: '-81.17466',
    timeZoneId: 'America/New_York',
  },
  '28655': {
    zip: '28655',
    city: 'Morganton',
    state: 'NC',
    latitude: '35.74404',
    longitude: '-81.71316',
    timeZoneId: 'America/New_York',
  },
  '28656': {
    zip: '28656',
    city: 'North Wilkesboro',
    state: 'NC',
    latitude: '36.20329',
    longitude: '-81.076593',
    timeZoneId: 'America/New_York',
  },
  '28657': {
    zip: '28657',
    city: 'Newland',
    state: 'NC',
    latitude: '36.03276',
    longitude: '-81.95436',
    timeZoneId: 'America/New_York',
  },
  '28658': {
    zip: '28658',
    city: 'Newton',
    state: 'NC',
    latitude: '35.658361',
    longitude: '-81.233412',
    timeZoneId: 'America/New_York',
  },
  '28659': {
    zip: '28659',
    city: 'North Wilkesboro',
    state: 'NC',
    latitude: '36.234234',
    longitude: '-81.113377',
    timeZoneId: 'America/New_York',
  },
  '28660': {
    zip: '28660',
    city: 'Olin',
    state: 'NC',
    latitude: '35.954635',
    longitude: '-80.852343',
    timeZoneId: 'America/New_York',
  },
  '28661': {
    zip: '28661',
    city: 'Patterson',
    state: 'NC',
    latitude: '35.998515',
    longitude: '-81.563991',
    timeZoneId: 'America/New_York',
  },
  '28662': {
    zip: '28662',
    city: 'Pineola',
    state: 'NC',
    latitude: '36.030658',
    longitude: '-81.891045',
    timeZoneId: 'America/New_York',
  },
  '28663': {
    zip: '28663',
    city: 'Piney Creek',
    state: 'NC',
    latitude: '36.563107',
    longitude: '-81.281762',
    timeZoneId: 'America/New_York',
  },
  '28664': {
    zip: '28664',
    city: 'Plumtree',
    state: 'NC',
    latitude: '36.027479',
    longitude: '-82.004487',
    timeZoneId: 'America/New_York',
  },
  '28665': {
    zip: '28665',
    city: 'Purlear',
    state: 'NC',
    latitude: '36.221121',
    longitude: '-81.387906',
    timeZoneId: 'America/New_York',
  },
  '28666': {
    zip: '28666',
    city: 'Icard',
    state: 'NC',
    latitude: '35.647803',
    longitude: '-81.263244',
    timeZoneId: 'America/New_York',
  },
  '28667': {
    zip: '28667',
    city: 'Rhodhiss',
    state: 'NC',
    latitude: '35.776179',
    longitude: '-81.426688',
    timeZoneId: 'America/New_York',
  },
  '28668': {
    zip: '28668',
    city: 'Roaring Gap',
    state: 'NC',
    latitude: '36.404449',
    longitude: '-81.005156',
    timeZoneId: 'America/New_York',
  },
  '28669': {
    zip: '28669',
    city: 'Roaring River',
    state: 'NC',
    latitude: '36.190998',
    longitude: '-80.999456',
    timeZoneId: 'America/New_York',
  },
  '28670': {
    zip: '28670',
    city: 'Ronda',
    state: 'NC',
    latitude: '36.23634',
    longitude: '-80.92662',
    timeZoneId: 'America/New_York',
  },
  '28671': {
    zip: '28671',
    city: 'Rutherford College',
    state: 'NC',
    latitude: '35.752185',
    longitude: '-81.523048',
    timeZoneId: 'America/New_York',
  },
  '28672': {
    zip: '28672',
    city: 'Scottville',
    state: 'NC',
    latitude: '36.473151',
    longitude: '-81.380417',
    timeZoneId: 'America/New_York',
  },
  '28673': {
    zip: '28673',
    city: 'Sherrills Ford',
    state: 'NC',
    latitude: '35.5895',
    longitude: '-81.002891',
    timeZoneId: 'America/New_York',
  },
  '28675': {
    zip: '28675',
    city: 'Sparta',
    state: 'NC',
    latitude: '36.506191',
    longitude: '-81.117283',
    timeZoneId: 'America/New_York',
  },
  '28676': {
    zip: '28676',
    city: 'State Road',
    state: 'NC',
    latitude: '36.396418',
    longitude: '-80.89226',
    timeZoneId: 'America/New_York',
  },
  '28677': {
    zip: '28677',
    city: 'Statesville',
    state: 'NC',
    latitude: '35.84526',
    longitude: '-80.8992',
    timeZoneId: 'America/New_York',
  },
  '28678': {
    zip: '28678',
    city: 'Stony Point',
    state: 'NC',
    latitude: '35.851332',
    longitude: '-81.043467',
    timeZoneId: 'America/New_York',
  },
  '28679': {
    zip: '28679',
    city: 'Sugar Grove',
    state: 'NC',
    latitude: '36.27348',
    longitude: '-81.87642',
    timeZoneId: 'America/New_York',
  },
  '28680': {
    zip: '28680',
    city: 'Morganton',
    state: 'NC',
    latitude: '35.743777',
    longitude: '-81.69662',
    timeZoneId: 'America/New_York',
  },
  '28681': {
    zip: '28681',
    city: 'Taylorsville',
    state: 'NC',
    latitude: '35.87244',
    longitude: '-81.21336',
    timeZoneId: 'America/New_York',
  },
  '28682': {
    zip: '28682',
    city: 'Terrell',
    state: 'NC',
    latitude: '35.583276',
    longitude: '-80.964993',
    timeZoneId: 'America/New_York',
  },
  '28683': {
    zip: '28683',
    city: 'Thurmond',
    state: 'NC',
    latitude: '36.362647',
    longitude: '-80.93926',
    timeZoneId: 'America/New_York',
  },
  '28684': {
    zip: '28684',
    city: 'Todd',
    state: 'NC',
    latitude: '36.3114',
    longitude: '-81.579',
    timeZoneId: 'America/New_York',
  },
  '28685': {
    zip: '28685',
    city: 'Traphill',
    state: 'NC',
    latitude: '36.346465',
    longitude: '-81.027968',
    timeZoneId: 'America/New_York',
  },
  '28687': {
    zip: '28687',
    city: 'Statesville',
    state: 'NC',
    latitude: '35.776465',
    longitude: '-80.910513',
    timeZoneId: 'America/New_York',
  },
  '28688': {
    zip: '28688',
    city: 'Turnersburg',
    state: 'NC',
    latitude: '35.83074',
    longitude: '-80.898404',
    timeZoneId: 'America/New_York',
  },
  '28689': {
    zip: '28689',
    city: 'Union Grove',
    state: 'NC',
    latitude: '36.037202',
    longitude: '-80.90854',
    timeZoneId: 'America/New_York',
  },
  '28690': {
    zip: '28690',
    city: 'Valdese',
    state: 'NC',
    latitude: '35.743328',
    longitude: '-81.563588',
    timeZoneId: 'America/New_York',
  },
  '28691': {
    zip: '28691',
    city: 'Valle Crucis',
    state: 'NC',
    latitude: '36.207601',
    longitude: '-81.885029',
    timeZoneId: 'America/New_York',
  },
  '28692': {
    zip: '28692',
    city: 'Vilas',
    state: 'NC',
    latitude: '36.273369',
    longitude: '-81.786913',
    timeZoneId: 'America/New_York',
  },
  '28693': {
    zip: '28693',
    city: 'Warrensville',
    state: 'NC',
    latitude: '36.461801',
    longitude: '-81.554415',
    timeZoneId: 'America/New_York',
  },
  '28694': {
    zip: '28694',
    city: 'West Jefferson',
    state: 'NC',
    latitude: '36.35214',
    longitude: '-81.47238',
    timeZoneId: 'America/New_York',
  },
  '28697': {
    zip: '28697',
    city: 'Wilkesboro',
    state: 'NC',
    latitude: '36.138201',
    longitude: '-81.1575',
    timeZoneId: 'America/New_York',
  },
  '28698': {
    zip: '28698',
    city: 'Zionville',
    state: 'NC',
    latitude: '36.331209',
    longitude: '-81.73921',
    timeZoneId: 'America/New_York',
  },
  '28699': {
    zip: '28699',
    city: 'Scotts',
    state: 'NC',
    latitude: '35.844263',
    longitude: '-81.007155',
    timeZoneId: 'America/New_York',
  },
  '28701': {
    zip: '28701',
    city: 'Alexander',
    state: 'NC',
    latitude: '35.705795',
    longitude: '-82.640881',
    timeZoneId: 'America/New_York',
  },
  '28702': {
    zip: '28702',
    city: 'Almond',
    state: 'NC',
    latitude: '35.388116',
    longitude: '-83.634747',
    timeZoneId: 'America/New_York',
  },
  '28704': {
    zip: '28704',
    city: 'Arden',
    state: 'NC',
    latitude: '35.473046',
    longitude: '-82.519301',
    timeZoneId: 'America/New_York',
  },
  '28705': {
    zip: '28705',
    city: 'Bakersville',
    state: 'NC',
    latitude: '35.977949',
    longitude: '-82.121955',
    timeZoneId: 'America/New_York',
  },
  '28707': {
    zip: '28707',
    city: 'Balsam',
    state: 'NC',
    latitude: '35.44749',
    longitude: '-83.08221',
    timeZoneId: 'America/New_York',
  },
  '28708': {
    zip: '28708',
    city: 'Balsam Grove',
    state: 'NC',
    latitude: '35.224044',
    longitude: '-82.885192',
    timeZoneId: 'America/New_York',
  },
  '28709': {
    zip: '28709',
    city: 'Barnardsville',
    state: 'NC',
    latitude: '35.761504',
    longitude: '-82.433278',
    timeZoneId: 'America/New_York',
  },
  '28710': {
    zip: '28710',
    city: 'Bat Cave',
    state: 'NC',
    latitude: '35.430771',
    longitude: '-82.544357',
    timeZoneId: 'America/New_York',
  },
  '28711': {
    zip: '28711',
    city: 'Black Mountain',
    state: 'NC',
    latitude: '35.60761',
    longitude: '-82.312275',
    timeZoneId: 'America/New_York',
  },
  '28712': {
    zip: '28712',
    city: 'Brevard',
    state: 'NC',
    latitude: '35.200259',
    longitude: '-82.738354',
    timeZoneId: 'America/New_York',
  },
  '28713': {
    zip: '28713',
    city: 'Bryson City',
    state: 'NC',
    latitude: '35.401379',
    longitude: '-83.467681',
    timeZoneId: 'America/New_York',
  },
  '28714': {
    zip: '28714',
    city: 'Burnsville',
    state: 'NC',
    latitude: '35.885779',
    longitude: '-82.259797',
    timeZoneId: 'America/New_York',
  },
  '28715': {
    zip: '28715',
    city: 'Candler',
    state: 'NC',
    latitude: '35.536698',
    longitude: '-82.680987',
    timeZoneId: 'America/New_York',
  },
  '28716': {
    zip: '28716',
    city: 'Canton',
    state: 'NC',
    latitude: '35.53003',
    longitude: '-82.839417',
    timeZoneId: 'America/New_York',
  },
  '28717': {
    zip: '28717',
    city: 'Cashiers',
    state: 'NC',
    latitude: '35.080295',
    longitude: '-83.081376',
    timeZoneId: 'America/New_York',
  },
  '28718': {
    zip: '28718',
    city: 'Cedar Mountain',
    state: 'NC',
    latitude: '35.137445',
    longitude: '-82.682532',
    timeZoneId: 'America/New_York',
  },
  '28719': {
    zip: '28719',
    city: 'Cherokee',
    state: 'NC',
    latitude: '35.474495',
    longitude: '-83.341234',
    timeZoneId: 'America/New_York',
  },
  '28720': {
    zip: '28720',
    city: 'Chimney Rock',
    state: 'NC',
    latitude: '35.437008',
    longitude: '-82.24414',
    timeZoneId: 'America/New_York',
  },
  '28721': {
    zip: '28721',
    city: 'Clyde',
    state: 'NC',
    latitude: '35.538721',
    longitude: '-82.915332',
    timeZoneId: 'America/New_York',
  },
  '28722': {
    zip: '28722',
    city: 'Columbus',
    state: 'NC',
    latitude: '35.24227',
    longitude: '-82.190564',
    timeZoneId: 'America/New_York',
  },
  '28723': {
    zip: '28723',
    city: 'Cullowhee',
    state: 'NC',
    latitude: '35.284272',
    longitude: '-83.179192',
    timeZoneId: 'America/New_York',
  },
  '28724': {
    zip: '28724',
    city: 'Dana',
    state: 'NC',
    latitude: '35.329055',
    longitude: '-82.374461',
    timeZoneId: 'America/New_York',
  },
  '28725': {
    zip: '28725',
    city: 'Dillsboro',
    state: 'NC',
    latitude: '35.369907',
    longitude: '-83.251691',
    timeZoneId: 'America/New_York',
  },
  '28726': {
    zip: '28726',
    city: 'East Flat Rock',
    state: 'NC',
    latitude: '35.282463',
    longitude: '-82.41798',
    timeZoneId: 'America/New_York',
  },
  '28727': {
    zip: '28727',
    city: 'Edneyville',
    state: 'NC',
    latitude: '35.396345',
    longitude: '-82.343722',
    timeZoneId: 'America/New_York',
  },
  '28728': {
    zip: '28728',
    city: 'Enka',
    state: 'NC',
    latitude: '35.541407',
    longitude: '-82.64364',
    timeZoneId: 'America/New_York',
  },
  '28729': {
    zip: '28729',
    city: 'Etowah',
    state: 'NC',
    latitude: '35.318754',
    longitude: '-82.604976',
    timeZoneId: 'America/New_York',
  },
  '28730': {
    zip: '28730',
    city: 'Fairview',
    state: 'NC',
    latitude: '35.524195',
    longitude: '-82.410772',
    timeZoneId: 'America/New_York',
  },
  '28731': {
    zip: '28731',
    city: 'Flat Rock',
    state: 'NC',
    latitude: '35.272427',
    longitude: '-82.419576',
    timeZoneId: 'America/New_York',
  },
  '28732': {
    zip: '28732',
    city: 'Fletcher',
    state: 'NC',
    latitude: '35.421548',
    longitude: '-82.499968',
    timeZoneId: 'America/New_York',
  },
  '28733': {
    zip: '28733',
    city: 'Fontana Dam',
    state: 'NC',
    latitude: '35.430442',
    longitude: '-83.828133',
    timeZoneId: 'America/New_York',
  },
  '28734': {
    zip: '28734',
    city: 'Franklin',
    state: 'NC',
    latitude: '35.20146',
    longitude: '-83.38146',
    timeZoneId: 'America/New_York',
  },
  '28735': {
    zip: '28735',
    city: 'Gerton',
    state: 'NC',
    latitude: '35.474017',
    longitude: '-82.307894',
    timeZoneId: 'America/New_York',
  },
  '28736': {
    zip: '28736',
    city: 'Glenville',
    state: 'NC',
    latitude: '35.19368',
    longitude: '-83.085357',
    timeZoneId: 'America/New_York',
  },
  '28737': {
    zip: '28737',
    city: 'Glenwood',
    state: 'NC',
    latitude: '35.683889',
    longitude: '-82.009444',
    timeZoneId: 'America/New_York',
  },
  '28738': {
    zip: '28738',
    city: 'Hazelwood',
    state: 'NC',
    latitude: '35.476787',
    longitude: '-83.002928',
    timeZoneId: 'America/New_York',
  },
  '28739': {
    zip: '28739',
    city: 'Hendersonville',
    state: 'NC',
    latitude: '35.318666',
    longitude: '-82.488402',
    timeZoneId: 'America/New_York',
  },
  '28740': {
    zip: '28740',
    city: 'Green Mountain',
    state: 'NC',
    latitude: '35.9943',
    longitude: '-82.2744',
    timeZoneId: 'America/New_York',
  },
  '28741': {
    zip: '28741',
    city: 'Highlands',
    state: 'NC',
    latitude: '35.055868',
    longitude: '-83.205699',
    timeZoneId: 'America/New_York',
  },
  '28742': {
    zip: '28742',
    city: 'Horse Shoe',
    state: 'NC',
    latitude: '35.36115',
    longitude: '-82.574013',
    timeZoneId: 'America/New_York',
  },
  '28743': {
    zip: '28743',
    city: 'Hot Springs',
    state: 'NC',
    latitude: '35.8458',
    longitude: '-82.87062',
    timeZoneId: 'America/New_York',
  },
  '28744': {
    zip: '28744',
    city: 'Franklin',
    state: 'NC',
    latitude: '35.180743',
    longitude: '-83.381664',
    timeZoneId: 'America/New_York',
  },
  '28745': {
    zip: '28745',
    city: 'Lake Junaluska',
    state: 'NC',
    latitude: '35.524801',
    longitude: '-82.972902',
    timeZoneId: 'America/New_York',
  },
  '28746': {
    zip: '28746',
    city: 'Lake Lure',
    state: 'NC',
    latitude: '35.452129',
    longitude: '-82.190425',
    timeZoneId: 'America/New_York',
  },
  '28747': {
    zip: '28747',
    city: 'Lake Toxaway',
    state: 'NC',
    latitude: '35.14604',
    longitude: '-82.933874',
    timeZoneId: 'America/New_York',
  },
  '28748': {
    zip: '28748',
    city: 'Leicester',
    state: 'NC',
    latitude: '35.628226',
    longitude: '-82.709526',
    timeZoneId: 'America/New_York',
  },
  '28749': {
    zip: '28749',
    city: 'Little Switzerland',
    state: 'NC',
    latitude: '35.847794',
    longitude: '-82.097662',
    timeZoneId: 'America/New_York',
  },
  '28750': {
    zip: '28750',
    city: 'Lynn',
    state: 'NC',
    latitude: '35.226869',
    longitude: '-82.222796',
    timeZoneId: 'America/New_York',
  },
  '28751': {
    zip: '28751',
    city: 'Maggie Valley',
    state: 'NC',
    latitude: '35.521141',
    longitude: '-83.063129',
    timeZoneId: 'America/New_York',
  },
  '28752': {
    zip: '28752',
    city: 'Marion',
    state: 'NC',
    latitude: '35.674582',
    longitude: '-82.017112',
    timeZoneId: 'America/New_York',
  },
  '28753': {
    zip: '28753',
    city: 'Marshall',
    state: 'NC',
    latitude: '35.837619',
    longitude: '-82.710606',
    timeZoneId: 'America/New_York',
  },
  '28754': {
    zip: '28754',
    city: 'Mars Hill',
    state: 'NC',
    latitude: '35.876794',
    longitude: '-82.540355',
    timeZoneId: 'America/New_York',
  },
  '28755': {
    zip: '28755',
    city: 'Micaville',
    state: 'NC',
    latitude: '35.906336',
    longitude: '-82.215416',
    timeZoneId: 'America/New_York',
  },
  '28756': {
    zip: '28756',
    city: 'Mill Spring',
    state: 'NC',
    latitude: '35.339715',
    longitude: '-82.169664',
    timeZoneId: 'America/New_York',
  },
  '28757': {
    zip: '28757',
    city: 'Montreat',
    state: 'NC',
    latitude: '35.643925',
    longitude: '-82.305516',
    timeZoneId: 'America/New_York',
  },
  '28758': {
    zip: '28758',
    city: 'Mountain Home',
    state: 'NC',
    latitude: '35.378626',
    longitude: '-82.477763',
    timeZoneId: 'America/New_York',
  },
  '28759': {
    zip: '28759',
    city: 'Mills River',
    state: 'NC',
    latitude: '35.301536',
    longitude: '-82.581486',
    timeZoneId: 'America/New_York',
  },
  '28760': {
    zip: '28760',
    city: 'Naples',
    state: 'NC',
    latitude: '35.392606',
    longitude: '-82.502999',
    timeZoneId: 'America/New_York',
  },
  '28761': {
    zip: '28761',
    city: 'Nebo',
    state: 'NC',
    latitude: '35.665956',
    longitude: '-81.912008',
    timeZoneId: 'America/New_York',
  },
  '28762': {
    zip: '28762',
    city: 'Old Fort',
    state: 'NC',
    latitude: '35.630002',
    longitude: '-82.157958',
    timeZoneId: 'America/New_York',
  },
  '28763': {
    zip: '28763',
    city: 'Otto',
    state: 'NC',
    latitude: '35.046523',
    longitude: '-83.396898',
    timeZoneId: 'America/New_York',
  },
  '28765': {
    zip: '28765',
    city: 'Penland',
    state: 'NC',
    latitude: '35.92802',
    longitude: '-82.11699',
    timeZoneId: 'America/New_York',
  },
  '28766': {
    zip: '28766',
    city: 'Penrose',
    state: 'NC',
    latitude: '35.282903',
    longitude: '-82.658439',
    timeZoneId: 'America/New_York',
  },
  '28768': {
    zip: '28768',
    city: 'Pisgah Forest',
    state: 'NC',
    latitude: '35.25693',
    longitude: '-82.668289',
    timeZoneId: 'America/New_York',
  },
  '28770': {
    zip: '28770',
    city: 'Ridgecrest',
    state: 'NC',
    latitude: '35.615404',
    longitude: '-82.318031',
    timeZoneId: 'America/New_York',
  },
  '28771': {
    zip: '28771',
    city: 'Robbinsville',
    state: 'NC',
    latitude: '35.346072',
    longitude: '-83.874306',
    timeZoneId: 'America/New_York',
  },
  '28772': {
    zip: '28772',
    city: 'Rosman',
    state: 'NC',
    latitude: '35.142895',
    longitude: '-82.83623',
    timeZoneId: 'America/New_York',
  },
  '28773': {
    zip: '28773',
    city: 'Saluda',
    state: 'NC',
    latitude: '35.24654',
    longitude: '-82.309619',
    timeZoneId: 'America/New_York',
  },
  '28774': {
    zip: '28774',
    city: 'Sapphire',
    state: 'NC',
    latitude: '35.104149',
    longitude: '-82.978026',
    timeZoneId: 'America/New_York',
  },
  '28775': {
    zip: '28775',
    city: 'Scaly Mountain',
    state: 'NC',
    latitude: '35.014232',
    longitude: '-83.33364',
    timeZoneId: 'America/New_York',
  },
  '28776': {
    zip: '28776',
    city: 'Skyland',
    state: 'NC',
    latitude: '35.482015',
    longitude: '-82.523906',
    timeZoneId: 'America/New_York',
  },
  '28777': {
    zip: '28777',
    city: 'Spruce Pine',
    state: 'NC',
    latitude: '35.914346',
    longitude: '-82.07249',
    timeZoneId: 'America/New_York',
  },
  '28778': {
    zip: '28778',
    city: 'Swannanoa',
    state: 'NC',
    latitude: '35.603045',
    longitude: '-82.409169',
    timeZoneId: 'America/New_York',
  },
  '28779': {
    zip: '28779',
    city: 'Sylva',
    state: 'NC',
    latitude: '35.377705',
    longitude: '-83.21216',
    timeZoneId: 'America/New_York',
  },
  '28781': {
    zip: '28781',
    city: 'Topton',
    state: 'NC',
    latitude: '35.256543',
    longitude: '-83.696128',
    timeZoneId: 'America/New_York',
  },
  '28782': {
    zip: '28782',
    city: 'Tryon',
    state: 'NC',
    latitude: '35.217184',
    longitude: '-82.226181',
    timeZoneId: 'America/New_York',
  },
  '28783': {
    zip: '28783',
    city: 'Tuckasegee',
    state: 'NC',
    latitude: '35.249037',
    longitude: '-83.118647',
    timeZoneId: 'America/New_York',
  },
  '28784': {
    zip: '28784',
    city: 'Tuxedo',
    state: 'NC',
    latitude: '35.232294',
    longitude: '-82.431185',
    timeZoneId: 'America/New_York',
  },
  '28785': {
    zip: '28785',
    city: 'Waynesville',
    state: 'NC',
    latitude: '35.574687',
    longitude: '-83.010377',
    timeZoneId: 'America/New_York',
  },
  '28786': {
    zip: '28786',
    city: 'Waynesville',
    state: 'NC',
    latitude: '35.500644',
    longitude: '-82.99509',
    timeZoneId: 'America/New_York',
  },
  '28787': {
    zip: '28787',
    city: 'Weaverville',
    state: 'NC',
    latitude: '35.718689',
    longitude: '-82.5471',
    timeZoneId: 'America/New_York',
  },
  '28788': {
    zip: '28788',
    city: 'Webster',
    state: 'NC',
    latitude: '35.351992',
    longitude: '-83.213222',
    timeZoneId: 'America/New_York',
  },
  '28789': {
    zip: '28789',
    city: 'Whittier',
    state: 'NC',
    latitude: '35.457462',
    longitude: '-83.287016',
    timeZoneId: 'America/New_York',
  },
  '28790': {
    zip: '28790',
    city: 'Zirconia',
    state: 'NC',
    latitude: '35.233139',
    longitude: '-82.415861',
    timeZoneId: 'America/New_York',
  },
  '28791': {
    zip: '28791',
    city: 'Hendersonville',
    state: 'NC',
    latitude: '35.350441',
    longitude: '-82.494453',
    timeZoneId: 'America/New_York',
  },
  '28792': {
    zip: '28792',
    city: 'Hendersonville',
    state: 'NC',
    latitude: '35.337349',
    longitude: '-82.449108',
    timeZoneId: 'America/New_York',
  },
  '28793': {
    zip: '28793',
    city: 'Hendersonville',
    state: 'NC',
    latitude: '35.327979',
    longitude: '-82.478182',
    timeZoneId: 'America/New_York',
  },
  '28801': {
    zip: '28801',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.594571',
    longitude: '-82.555747',
    timeZoneId: 'America/New_York',
  },
  '28802': {
    zip: '28802',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.498675',
    longitude: '-82.538396',
    timeZoneId: 'America/New_York',
  },
  '28803': {
    zip: '28803',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.548613',
    longitude: '-82.522312',
    timeZoneId: 'America/New_York',
  },
  '28804': {
    zip: '28804',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.63743',
    longitude: '-82.558381',
    timeZoneId: 'America/New_York',
  },
  '28805': {
    zip: '28805',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.594556',
    longitude: '-82.501708',
    timeZoneId: 'America/New_York',
  },
  '28806': {
    zip: '28806',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.583509',
    longitude: '-82.60779',
    timeZoneId: 'America/New_York',
  },
  '28810': {
    zip: '28810',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.567335',
    longitude: '-82.614735',
    timeZoneId: 'America/New_York',
  },
  '28813': {
    zip: '28813',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.55447',
    longitude: '-82.51072',
    timeZoneId: 'America/New_York',
  },
  '28814': {
    zip: '28814',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.62368',
    longitude: '-82.55448',
    timeZoneId: 'America/New_York',
  },
  '28815': {
    zip: '28815',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.575619',
    longitude: '-82.545629',
    timeZoneId: 'America/New_York',
  },
  '28816': {
    zip: '28816',
    city: 'Asheville',
    state: 'NC',
    latitude: '35.581775',
    longitude: '-82.60141',
    timeZoneId: 'America/New_York',
  },
  '28901': {
    zip: '28901',
    city: 'Andrews',
    state: 'NC',
    latitude: '35.189476',
    longitude: '-83.804291',
    timeZoneId: 'America/New_York',
  },
  '28902': {
    zip: '28902',
    city: 'Brasstown',
    state: 'NC',
    latitude: '35.043243',
    longitude: '-83.937634',
    timeZoneId: 'America/New_York',
  },
  '28903': {
    zip: '28903',
    city: 'Culberson',
    state: 'NC',
    latitude: '34.989954',
    longitude: '-84.167688',
    timeZoneId: 'America/New_York',
  },
  '28904': {
    zip: '28904',
    city: 'Hayesville',
    state: 'NC',
    latitude: '35.025287',
    longitude: '-83.810433',
    timeZoneId: 'America/New_York',
  },
  '28905': {
    zip: '28905',
    city: 'Marble',
    state: 'NC',
    latitude: '35.130869',
    longitude: '-83.947014',
    timeZoneId: 'America/New_York',
  },
  '28906': {
    zip: '28906',
    city: 'Murphy',
    state: 'NC',
    latitude: '35.0673',
    longitude: '-84.12618',
    timeZoneId: 'America/New_York',
  },
  '28909': {
    zip: '28909',
    city: 'Warne',
    state: 'NC',
    latitude: '35.000176',
    longitude: '-83.911806',
    timeZoneId: 'America/New_York',
  },
  '29001': {
    zip: '29001',
    city: 'Alcolu',
    state: 'SC',
    latitude: '33.860717',
    longitude: '-80.211061',
    timeZoneId: 'America/New_York',
  },
  '29002': {
    zip: '29002',
    city: 'Ballentine',
    state: 'SC',
    latitude: '34.160607',
    longitude: '-81.262',
    timeZoneId: 'America/New_York',
  },
  '29003': {
    zip: '29003',
    city: 'Bamberg',
    state: 'SC',
    latitude: '33.2871',
    longitude: '-81.02292',
    timeZoneId: 'America/New_York',
  },
  '29006': {
    zip: '29006',
    city: 'Batesburg',
    state: 'SC',
    latitude: '33.88691',
    longitude: '-81.534141',
    timeZoneId: 'America/New_York',
  },
  '29009': {
    zip: '29009',
    city: 'Bethune',
    state: 'SC',
    latitude: '34.433803',
    longitude: '-80.36895',
    timeZoneId: 'America/New_York',
  },
  '29010': {
    zip: '29010',
    city: 'Bishopville',
    state: 'SC',
    latitude: '34.21908',
    longitude: '-80.27082',
    timeZoneId: 'America/New_York',
  },
  '29014': {
    zip: '29014',
    city: 'Blackstock',
    state: 'SC',
    latitude: '34.573234',
    longitude: '-80.987145',
    timeZoneId: 'America/New_York',
  },
  '29015': {
    zip: '29015',
    city: 'Blair',
    state: 'SC',
    latitude: '34.321055',
    longitude: '-81.130372',
    timeZoneId: 'America/New_York',
  },
  '29016': {
    zip: '29016',
    city: 'Blythewood',
    state: 'SC',
    latitude: '34.19224',
    longitude: '-80.975064',
    timeZoneId: 'America/New_York',
  },
  '29018': {
    zip: '29018',
    city: 'Bowman',
    state: 'SC',
    latitude: '33.34911',
    longitude: '-80.687537',
    timeZoneId: 'America/New_York',
  },
  '29020': {
    zip: '29020',
    city: 'Camden',
    state: 'SC',
    latitude: '34.31304',
    longitude: '-80.60556',
    timeZoneId: 'America/New_York',
  },
  '29021': {
    zip: '29021',
    city: 'Camden',
    state: 'SC',
    latitude: '34.313874',
    longitude: '-80.605934',
    timeZoneId: 'America/New_York',
  },
  '29030': {
    zip: '29030',
    city: 'Cameron',
    state: 'SC',
    latitude: '33.55',
    longitude: '-80.71',
    timeZoneId: 'America/New_York',
  },
  '29031': {
    zip: '29031',
    city: 'Carlisle',
    state: 'SC',
    latitude: '34.683284',
    longitude: '-81.636507',
    timeZoneId: 'America/New_York',
  },
  '29032': {
    zip: '29032',
    city: 'Cassatt',
    state: 'SC',
    latitude: '34.337084',
    longitude: '-80.489212',
    timeZoneId: 'America/New_York',
  },
  '29033': {
    zip: '29033',
    city: 'Cayce',
    state: 'SC',
    latitude: '33.965536',
    longitude: '-81.069156',
    timeZoneId: 'America/New_York',
  },
  '29036': {
    zip: '29036',
    city: 'Chapin',
    state: 'SC',
    latitude: '34.14108',
    longitude: '-81.3441',
    timeZoneId: 'America/New_York',
  },
  '29037': {
    zip: '29037',
    city: 'Chappells',
    state: 'SC',
    latitude: '34.235316',
    longitude: '-81.841403',
    timeZoneId: 'America/New_York',
  },
  '29038': {
    zip: '29038',
    city: 'Cope',
    state: 'SC',
    latitude: '33.370991',
    longitude: '-80.963626',
    timeZoneId: 'America/New_York',
  },
  '29039': {
    zip: '29039',
    city: 'Cordova',
    state: 'SC',
    latitude: '33.456975',
    longitude: '-80.894539',
    timeZoneId: 'America/New_York',
  },
  '29040': {
    zip: '29040',
    city: 'Dalzell',
    state: 'SC',
    latitude: '34.002009',
    longitude: '-80.482959',
    timeZoneId: 'America/New_York',
  },
  '29041': {
    zip: '29041',
    city: 'Davis Station',
    state: 'SC',
    latitude: '33.601734',
    longitude: '-80.264746',
    timeZoneId: 'America/New_York',
  },
  '29042': {
    zip: '29042',
    city: 'Denmark',
    state: 'SC',
    latitude: '33.318209',
    longitude: '-81.139139',
    timeZoneId: 'America/New_York',
  },
  '29044': {
    zip: '29044',
    city: 'Eastover',
    state: 'SC',
    latitude: '33.91836',
    longitude: '-80.73216',
    timeZoneId: 'America/New_York',
  },
  '29045': {
    zip: '29045',
    city: 'Elgin',
    state: 'SC',
    latitude: '34.180379',
    longitude: '-80.797725',
    timeZoneId: 'America/New_York',
  },
  '29046': {
    zip: '29046',
    city: 'Elliott',
    state: 'SC',
    latitude: '34.115037',
    longitude: '-80.183743',
    timeZoneId: 'America/New_York',
  },
  '29047': {
    zip: '29047',
    city: 'Elloree',
    state: 'SC',
    latitude: '33.531329',
    longitude: '-80.571823',
    timeZoneId: 'America/New_York',
  },
  '29048': {
    zip: '29048',
    city: 'Eutawville',
    state: 'SC',
    latitude: '33.40896',
    longitude: '-80.32842',
    timeZoneId: 'America/New_York',
  },
  '29051': {
    zip: '29051',
    city: 'Gable',
    state: 'SC',
    latitude: '33.841591',
    longitude: '-80.087052',
    timeZoneId: 'America/New_York',
  },
  '29052': {
    zip: '29052',
    city: 'Gadsden',
    state: 'SC',
    latitude: '33.849444',
    longitude: '-80.76368',
    timeZoneId: 'America/New_York',
  },
  '29053': {
    zip: '29053',
    city: 'Gaston',
    state: 'SC',
    latitude: '33.827254',
    longitude: '-81.122127',
    timeZoneId: 'America/New_York',
  },
  '29054': {
    zip: '29054',
    city: 'Gilbert',
    state: 'SC',
    latitude: '33.948355',
    longitude: '-81.383815',
    timeZoneId: 'America/New_York',
  },
  '29055': {
    zip: '29055',
    city: 'Great Falls',
    state: 'SC',
    latitude: '34.570485',
    longitude: '-80.902059',
    timeZoneId: 'America/New_York',
  },
  '29056': {
    zip: '29056',
    city: 'Greeleyville',
    state: 'SC',
    latitude: '33.789296',
    longitude: '-79.832893',
    timeZoneId: 'America/New_York',
  },
  '29058': {
    zip: '29058',
    city: 'Heath Springs',
    state: 'SC',
    latitude: '34.5447',
    longitude: '-80.75178',
    timeZoneId: 'America/New_York',
  },
  '29059': {
    zip: '29059',
    city: 'Holly Hill',
    state: 'SC',
    latitude: '33.322019',
    longitude: '-80.412299',
    timeZoneId: 'America/New_York',
  },
  '29061': {
    zip: '29061',
    city: 'Hopkins',
    state: 'SC',
    latitude: '33.932888',
    longitude: '-80.844488',
    timeZoneId: 'America/New_York',
  },
  '29062': {
    zip: '29062',
    city: 'Horatio',
    state: 'SC',
    latitude: '34.113377',
    longitude: '-80.549366',
    timeZoneId: 'America/New_York',
  },
  '29063': {
    zip: '29063',
    city: 'Irmo',
    state: 'SC',
    latitude: '34.122153',
    longitude: '-81.195991',
    timeZoneId: 'America/New_York',
  },
  '29065': {
    zip: '29065',
    city: 'Jenkinsville',
    state: 'SC',
    latitude: '34.244829',
    longitude: '-81.264483',
    timeZoneId: 'America/New_York',
  },
  '29067': {
    zip: '29067',
    city: 'Kershaw',
    state: 'SC',
    latitude: '34.498309',
    longitude: '-80.520734',
    timeZoneId: 'America/New_York',
  },
  '29069': {
    zip: '29069',
    city: 'Lamar',
    state: 'SC',
    latitude: '34.181109',
    longitude: '-80.057782',
    timeZoneId: 'America/New_York',
  },
  '29070': {
    zip: '29070',
    city: 'Leesville',
    state: 'SC',
    latitude: '33.908554',
    longitude: '-81.449991',
    timeZoneId: 'America/New_York',
  },
  '29071': {
    zip: '29071',
    city: 'Lexington',
    state: 'SC',
    latitude: '33.980985',
    longitude: '-81.235011',
    timeZoneId: 'America/New_York',
  },
  '29072': {
    zip: '29072',
    city: 'Lexington',
    state: 'SC',
    latitude: '33.97986',
    longitude: '-81.26052',
    timeZoneId: 'America/New_York',
  },
  '29073': {
    zip: '29073',
    city: 'Lexington',
    state: 'SC',
    latitude: '33.927355',
    longitude: '-81.216327',
    timeZoneId: 'America/New_York',
  },
  '29074': {
    zip: '29074',
    city: 'Liberty Hill',
    state: 'SC',
    latitude: '34.453638',
    longitude: '-80.790518',
    timeZoneId: 'America/New_York',
  },
  '29075': {
    zip: '29075',
    city: 'Little Mountain',
    state: 'SC',
    latitude: '34.204268',
    longitude: '-81.289172',
    timeZoneId: 'America/New_York',
  },
  '29078': {
    zip: '29078',
    city: 'Lugoff',
    state: 'SC',
    latitude: '34.220008',
    longitude: '-80.71366',
    timeZoneId: 'America/New_York',
  },
  '29079': {
    zip: '29079',
    city: 'Lydia',
    state: 'SC',
    latitude: '34.285597',
    longitude: '-80.034022',
    timeZoneId: 'America/New_York',
  },
  '29080': {
    zip: '29080',
    city: 'Lynchburg',
    state: 'SC',
    latitude: '34.038853',
    longitude: '-80.110297',
    timeZoneId: 'America/New_York',
  },
  '29081': {
    zip: '29081',
    city: 'Ehrhardt',
    state: 'SC',
    latitude: '33.107954',
    longitude: '-81.022941',
    timeZoneId: 'America/New_York',
  },
  '29082': {
    zip: '29082',
    city: 'Lodge',
    state: 'SC',
    latitude: '33.06881',
    longitude: '-80.95292',
    timeZoneId: 'America/New_York',
  },
  '29101': {
    zip: '29101',
    city: 'Mc Bee',
    state: 'SC',
    latitude: '34.4892',
    longitude: '-80.2482',
    timeZoneId: 'America/New_York',
  },
  '29102': {
    zip: '29102',
    city: 'Manning',
    state: 'SC',
    latitude: '33.63792',
    longitude: '-80.25738',
    timeZoneId: 'America/New_York',
  },
  '29104': {
    zip: '29104',
    city: 'Mayesville',
    state: 'SC',
    latitude: '33.983524',
    longitude: '-80.208008',
    timeZoneId: 'America/New_York',
  },
  '29105': {
    zip: '29105',
    city: 'Monetta',
    state: 'SC',
    latitude: '33.759336',
    longitude: '-81.55839',
    timeZoneId: 'America/New_York',
  },
  '29107': {
    zip: '29107',
    city: 'Neeses',
    state: 'SC',
    latitude: '33.489723',
    longitude: '-81.034501',
    timeZoneId: 'America/New_York',
  },
  '29108': {
    zip: '29108',
    city: 'Newberry',
    state: 'SC',
    latitude: '34.277092',
    longitude: '-81.618636',
    timeZoneId: 'America/New_York',
  },
  '29111': {
    zip: '29111',
    city: 'New Zion',
    state: 'SC',
    latitude: '33.780339',
    longitude: '-80.018183',
    timeZoneId: 'America/New_York',
  },
  '29112': {
    zip: '29112',
    city: 'North',
    state: 'SC',
    latitude: '33.689919',
    longitude: '-81.124565',
    timeZoneId: 'America/New_York',
  },
  '29113': {
    zip: '29113',
    city: 'Norway',
    state: 'SC',
    latitude: '33.44637',
    longitude: '-81.116514',
    timeZoneId: 'America/New_York',
  },
  '29114': {
    zip: '29114',
    city: 'Olanta',
    state: 'SC',
    latitude: '33.927712',
    longitude: '-79.905262',
    timeZoneId: 'America/New_York',
  },
  '29115': {
    zip: '29115',
    city: 'Orangeburg',
    state: 'SC',
    latitude: '33.581944',
    longitude: '-80.818056',
    timeZoneId: 'America/New_York',
  },
  '29116': {
    zip: '29116',
    city: 'Orangeburg',
    state: 'SC',
    latitude: '33.48145',
    longitude: '-80.810907',
    timeZoneId: 'America/New_York',
  },
  '29117': {
    zip: '29117',
    city: 'Orangeburg',
    state: 'SC',
    latitude: '33.495205',
    longitude: '-80.844399',
    timeZoneId: 'America/New_York',
  },
  '29118': {
    zip: '29118',
    city: 'Orangeburg',
    state: 'SC',
    latitude: '33.532316',
    longitude: '-80.873936',
    timeZoneId: 'America/New_York',
  },
  '29122': {
    zip: '29122',
    city: 'Peak',
    state: 'SC',
    latitude: '34.171834',
    longitude: '-81.414509',
    timeZoneId: 'America/New_York',
  },
  '29123': {
    zip: '29123',
    city: 'Pelion',
    state: 'SC',
    latitude: '33.776254',
    longitude: '-81.255322',
    timeZoneId: 'America/New_York',
  },
  '29125': {
    zip: '29125',
    city: 'Pinewood',
    state: 'SC',
    latitude: '33.74226',
    longitude: '-80.48172',
    timeZoneId: 'America/New_York',
  },
  '29126': {
    zip: '29126',
    city: 'Pomaria',
    state: 'SC',
    latitude: '34.323669',
    longitude: '-81.444178',
    timeZoneId: 'America/New_York',
  },
  '29127': {
    zip: '29127',
    city: 'Prosperity',
    state: 'SC',
    latitude: '34.182431',
    longitude: '-81.52094',
    timeZoneId: 'America/New_York',
  },
  '29128': {
    zip: '29128',
    city: 'Rembert',
    state: 'SC',
    latitude: '34.09208',
    longitude: '-80.5048',
    timeZoneId: 'America/New_York',
  },
  '29129': {
    zip: '29129',
    city: 'Ridge Spring',
    state: 'SC',
    latitude: '33.737089',
    longitude: '-81.65768',
    timeZoneId: 'America/New_York',
  },
  '29130': {
    zip: '29130',
    city: 'Ridgeway',
    state: 'SC',
    latitude: '34.326001',
    longitude: '-80.760553',
    timeZoneId: 'America/New_York',
  },
  '29132': {
    zip: '29132',
    city: 'Rion',
    state: 'SC',
    latitude: '34.303731',
    longitude: '-81.124142',
    timeZoneId: 'America/New_York',
  },
  '29133': {
    zip: '29133',
    city: 'Rowesville',
    state: 'SC',
    latitude: '33.368863',
    longitude: '-80.834172',
    timeZoneId: 'America/New_York',
  },
  '29135': {
    zip: '29135',
    city: 'Saint Matthews',
    state: 'SC',
    latitude: '33.66187',
    longitude: '-80.781778',
    timeZoneId: 'America/New_York',
  },
  '29137': {
    zip: '29137',
    city: 'Salley',
    state: 'SC',
    latitude: '33.637039',
    longitude: '-81.295434',
    timeZoneId: 'America/New_York',
  },
  '29138': {
    zip: '29138',
    city: 'Saluda',
    state: 'SC',
    latitude: '34.007368',
    longitude: '-81.769758',
    timeZoneId: 'America/New_York',
  },
  '29142': {
    zip: '29142',
    city: 'Santee',
    state: 'SC',
    latitude: '33.488318',
    longitude: '-80.45644',
    timeZoneId: 'America/New_York',
  },
  '29143': {
    zip: '29143',
    city: 'Sardinia',
    state: 'SC',
    latitude: '33.836621',
    longitude: '-80.080723',
    timeZoneId: 'America/New_York',
  },
  '29145': {
    zip: '29145',
    city: 'Silverstreet',
    state: 'SC',
    latitude: '34.212506',
    longitude: '-81.743693',
    timeZoneId: 'America/New_York',
  },
  '29146': {
    zip: '29146',
    city: 'Springfield',
    state: 'SC',
    latitude: '33.497823',
    longitude: '-81.280338',
    timeZoneId: 'America/New_York',
  },
  '29147': {
    zip: '29147',
    city: 'State Park',
    state: 'SC',
    latitude: '34.113735',
    longitude: '-80.954384',
    timeZoneId: 'America/New_York',
  },
  '29148': {
    zip: '29148',
    city: 'Summerton',
    state: 'SC',
    latitude: '33.589744',
    longitude: '-80.341999',
    timeZoneId: 'America/New_York',
  },
  '29150': {
    zip: '29150',
    city: 'Sumter',
    state: 'SC',
    latitude: '33.87708',
    longitude: '-80.2575',
    timeZoneId: 'America/New_York',
  },
  '29151': {
    zip: '29151',
    city: 'Sumter',
    state: 'SC',
    latitude: '33.90864',
    longitude: '-80.362593',
    timeZoneId: 'America/New_York',
  },
  '29152': {
    zip: '29152',
    city: 'Shaw AFB',
    state: 'SC',
    latitude: '33.983141',
    longitude: '-80.485057',
    timeZoneId: 'America/New_York',
  },
  '29153': {
    zip: '29153',
    city: 'Sumter',
    state: 'SC',
    latitude: '33.941345',
    longitude: '-80.324377',
    timeZoneId: 'America/New_York',
  },
  '29154': {
    zip: '29154',
    city: 'Sumter',
    state: 'SC',
    latitude: '33.896794',
    longitude: '-80.418858',
    timeZoneId: 'America/New_York',
  },
  '29160': {
    zip: '29160',
    city: 'Swansea',
    state: 'SC',
    latitude: '33.734049',
    longitude: '-81.10143',
    timeZoneId: 'America/New_York',
  },
  '29161': {
    zip: '29161',
    city: 'Timmonsville',
    state: 'SC',
    latitude: '34.11966',
    longitude: '-79.97382',
    timeZoneId: 'America/New_York',
  },
  '29162': {
    zip: '29162',
    city: 'Turbeville',
    state: 'SC',
    latitude: '33.881147',
    longitude: '-80.006901',
    timeZoneId: 'America/New_York',
  },
  '29163': {
    zip: '29163',
    city: 'Vance',
    state: 'SC',
    latitude: '33.407034',
    longitude: '-80.46895',
    timeZoneId: 'America/New_York',
  },
  '29164': {
    zip: '29164',
    city: 'Wagener',
    state: 'SC',
    latitude: '33.654721',
    longitude: '-81.43339',
    timeZoneId: 'America/New_York',
  },
  '29166': {
    zip: '29166',
    city: 'Ward',
    state: 'SC',
    latitude: '33.884865',
    longitude: '-81.740141',
    timeZoneId: 'America/New_York',
  },
  '29168': {
    zip: '29168',
    city: 'Wedgefield',
    state: 'SC',
    latitude: '33.901928',
    longitude: '-80.497455',
    timeZoneId: 'America/New_York',
  },
  '29169': {
    zip: '29169',
    city: 'West Columbia',
    state: 'SC',
    latitude: '33.98898',
    longitude: '-81.10008',
    timeZoneId: 'America/New_York',
  },
  '29170': {
    zip: '29170',
    city: 'West Columbia',
    state: 'SC',
    latitude: '33.950402',
    longitude: '-81.141165',
    timeZoneId: 'America/New_York',
  },
  '29171': {
    zip: '29171',
    city: 'West Columbia',
    state: 'SC',
    latitude: '33.970062',
    longitude: '-81.078582',
    timeZoneId: 'America/New_York',
  },
  '29172': {
    zip: '29172',
    city: 'West Columbia',
    state: 'SC',
    latitude: '33.898509',
    longitude: '-81.092152',
    timeZoneId: 'America/New_York',
  },
  '29175': {
    zip: '29175',
    city: 'Westville',
    state: 'SC',
    latitude: '34.447043',
    longitude: '-80.601334',
    timeZoneId: 'America/New_York',
  },
  '29177': {
    zip: '29177',
    city: 'White Rock',
    state: 'SC',
    latitude: '34.157696',
    longitude: '-81.282809',
    timeZoneId: 'America/New_York',
  },
  '29178': {
    zip: '29178',
    city: 'Whitmire',
    state: 'SC',
    latitude: '34.501145',
    longitude: '-81.616631',
    timeZoneId: 'America/New_York',
  },
  '29180': {
    zip: '29180',
    city: 'Winnsboro',
    state: 'SC',
    latitude: '34.372149',
    longitude: '-81.086971',
    timeZoneId: 'America/New_York',
  },
  '29201': {
    zip: '29201',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.998223',
    longitude: '-81.032546',
    timeZoneId: 'America/New_York',
  },
  '29202': {
    zip: '29202',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.972106',
    longitude: '-80.975437',
    timeZoneId: 'America/New_York',
  },
  '29203': {
    zip: '29203',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.09818',
    longitude: '-81.03618',
    timeZoneId: 'America/New_York',
  },
  '29204': {
    zip: '29204',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.028919',
    longitude: '-80.99859',
    timeZoneId: 'America/New_York',
  },
  '29205': {
    zip: '29205',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.992894',
    longitude: '-80.99424',
    timeZoneId: 'America/New_York',
  },
  '29206': {
    zip: '29206',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.02954',
    longitude: '-80.95188',
    timeZoneId: 'America/New_York',
  },
  '29207': {
    zip: '29207',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.061667',
    longitude: '-80.966102',
    timeZoneId: 'America/New_York',
  },
  '29208': {
    zip: '29208',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.975039',
    longitude: '-81.007842',
    timeZoneId: 'America/New_York',
  },
  '29209': {
    zip: '29209',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.966947',
    longitude: '-80.934317',
    timeZoneId: 'America/New_York',
  },
  '29210': {
    zip: '29210',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.040812',
    longitude: '-81.095436',
    timeZoneId: 'America/New_York',
  },
  '29211': {
    zip: '29211',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.024196',
    longitude: '-81.293589',
    timeZoneId: 'America/New_York',
  },
  '29212': {
    zip: '29212',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.08114',
    longitude: '-81.19494',
    timeZoneId: 'America/New_York',
  },
  '29214': {
    zip: '29214',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.000793',
    longitude: '-81.031935',
    timeZoneId: 'America/New_York',
  },
  '29215': {
    zip: '29215',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.026843',
    longitude: '-81.001189',
    timeZoneId: 'America/New_York',
  },
  '29216': {
    zip: '29216',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.997822',
    longitude: '-81.036511',
    timeZoneId: 'America/New_York',
  },
  '29217': {
    zip: '29217',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.010724',
    longitude: '-81.036122',
    timeZoneId: 'America/New_York',
  },
  '29218': {
    zip: '29218',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.007301',
    longitude: '-81.036586',
    timeZoneId: 'America/New_York',
  },
  '29219': {
    zip: '29219',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.067593',
    longitude: '-80.920837',
    timeZoneId: 'America/New_York',
  },
  '29220': {
    zip: '29220',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.00432',
    longitude: '-81.032526',
    timeZoneId: 'America/New_York',
  },
  '29221': {
    zip: '29221',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.044558',
    longitude: '-81.109663',
    timeZoneId: 'America/New_York',
  },
  '29222': {
    zip: '29222',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.984355',
    longitude: '-81.029966',
    timeZoneId: 'America/New_York',
  },
  '29223': {
    zip: '29223',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.08846',
    longitude: '-80.92287',
    timeZoneId: 'America/New_York',
  },
  '29224': {
    zip: '29224',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.057985',
    longitude: '-80.841611',
    timeZoneId: 'America/New_York',
  },
  '29225': {
    zip: '29225',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.998957',
    longitude: '-81.03086',
    timeZoneId: 'America/New_York',
  },
  '29226': {
    zip: '29226',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.031953',
    longitude: '-81.104915',
    timeZoneId: 'America/New_York',
  },
  '29227': {
    zip: '29227',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.033609',
    longitude: '-81.10874',
    timeZoneId: 'America/New_York',
  },
  '29228': {
    zip: '29228',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.920816',
    longitude: '-81.090297',
    timeZoneId: 'America/New_York',
  },
  '29229': {
    zip: '29229',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.127676',
    longitude: '-80.896127',
    timeZoneId: 'America/New_York',
  },
  '29230': {
    zip: '29230',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.138187',
    longitude: '-81.065959',
    timeZoneId: 'America/New_York',
  },
  '29240': {
    zip: '29240',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.027608',
    longitude: '-81.009201',
    timeZoneId: 'America/New_York',
  },
  '29250': {
    zip: '29250',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.002817',
    longitude: '-81.005802',
    timeZoneId: 'America/New_York',
  },
  '29260': {
    zip: '29260',
    city: 'Columbia',
    state: 'SC',
    latitude: '34.026058',
    longitude: '-80.967646',
    timeZoneId: 'America/New_York',
  },
  '29290': {
    zip: '29290',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.918265',
    longitude: '-80.873716',
    timeZoneId: 'America/New_York',
  },
  '29292': {
    zip: '29292',
    city: 'Columbia',
    state: 'SC',
    latitude: '33.909357',
    longitude: '-81.076717',
    timeZoneId: 'America/New_York',
  },
  '29301': {
    zip: '29301',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.933791',
    longitude: '-81.965052',
    timeZoneId: 'America/New_York',
  },
  '29302': {
    zip: '29302',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.957122',
    longitude: '-81.863011',
    timeZoneId: 'America/New_York',
  },
  '29303': {
    zip: '29303',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '35.01804',
    longitude: '-81.97614',
    timeZoneId: 'America/New_York',
  },
  '29304': {
    zip: '29304',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.958086',
    longitude: '-81.905765',
    timeZoneId: 'America/New_York',
  },
  '29305': {
    zip: '29305',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.989259',
    longitude: '-81.954612',
    timeZoneId: 'America/New_York',
  },
  '29306': {
    zip: '29306',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.928003',
    longitude: '-81.908736',
    timeZoneId: 'America/New_York',
  },
  '29307': {
    zip: '29307',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.998752',
    longitude: '-81.74645',
    timeZoneId: 'America/New_York',
  },
  '29316': {
    zip: '29316',
    city: 'Boiling Springs',
    state: 'SC',
    latitude: '35.056379',
    longitude: '-81.964009',
    timeZoneId: 'America/New_York',
  },
  '29319': {
    zip: '29319',
    city: 'Spartanburg',
    state: 'SC',
    latitude: '34.947178',
    longitude: '-81.926781',
    timeZoneId: 'America/New_York',
  },
  '29320': {
    zip: '29320',
    city: 'Arcadia',
    state: 'SC',
    latitude: '34.959588',
    longitude: '-81.993413',
    timeZoneId: 'America/New_York',
  },
  '29321': {
    zip: '29321',
    city: 'Buffalo',
    state: 'SC',
    latitude: '34.725233',
    longitude: '-81.683994',
    timeZoneId: 'America/New_York',
  },
  '29322': {
    zip: '29322',
    city: 'Campobello',
    state: 'SC',
    latitude: '35.118004',
    longitude: '-82.126556',
    timeZoneId: 'America/New_York',
  },
  '29323': {
    zip: '29323',
    city: 'Chesnee',
    state: 'SC',
    latitude: '35.120234',
    longitude: '-81.909469',
    timeZoneId: 'America/New_York',
  },
  '29324': {
    zip: '29324',
    city: 'Clifton',
    state: 'SC',
    latitude: '34.983913',
    longitude: '-81.824034',
    timeZoneId: 'America/New_York',
  },
  '29325': {
    zip: '29325',
    city: 'Clinton',
    state: 'SC',
    latitude: '34.470685',
    longitude: '-81.887131',
    timeZoneId: 'America/New_York',
  },
  '29329': {
    zip: '29329',
    city: 'Converse',
    state: 'SC',
    latitude: '34.999533',
    longitude: '-81.844017',
    timeZoneId: 'America/New_York',
  },
  '29330': {
    zip: '29330',
    city: 'Cowpens',
    state: 'SC',
    latitude: '35.030555',
    longitude: '-81.817377',
    timeZoneId: 'America/New_York',
  },
  '29331': {
    zip: '29331',
    city: 'Cross Anchor',
    state: 'SC',
    latitude: '34.654981',
    longitude: '-81.877783',
    timeZoneId: 'America/New_York',
  },
  '29332': {
    zip: '29332',
    city: 'Cross Hill',
    state: 'SC',
    latitude: '34.283143',
    longitude: '-81.982876',
    timeZoneId: 'America/New_York',
  },
  '29333': {
    zip: '29333',
    city: 'Drayton',
    state: 'SC',
    latitude: '35.004529',
    longitude: '-81.874135',
    timeZoneId: 'America/New_York',
  },
  '29334': {
    zip: '29334',
    city: 'Duncan',
    state: 'SC',
    latitude: '34.914282',
    longitude: '-82.134672',
    timeZoneId: 'America/New_York',
  },
  '29335': {
    zip: '29335',
    city: 'Enoree',
    state: 'SC',
    latitude: '34.669468',
    longitude: '-81.904979',
    timeZoneId: 'America/New_York',
  },
  '29336': {
    zip: '29336',
    city: 'Fairforest',
    state: 'SC',
    latitude: '34.950367',
    longitude: '-82.014946',
    timeZoneId: 'America/New_York',
  },
  '29338': {
    zip: '29338',
    city: 'Fingerville',
    state: 'SC',
    latitude: '34.934397',
    longitude: '-81.981856',
    timeZoneId: 'America/New_York',
  },
  '29340': {
    zip: '29340',
    city: 'Gaffney',
    state: 'SC',
    latitude: '35.062322',
    longitude: '-81.645437',
    timeZoneId: 'America/New_York',
  },
  '29341': {
    zip: '29341',
    city: 'Gaffney',
    state: 'SC',
    latitude: '35.09456',
    longitude: '-81.677416',
    timeZoneId: 'America/New_York',
  },
  '29342': {
    zip: '29342',
    city: 'Gaffney',
    state: 'SC',
    latitude: '34.985626',
    longitude: '-81.659125',
    timeZoneId: 'America/New_York',
  },
  '29346': {
    zip: '29346',
    city: 'Glendale',
    state: 'SC',
    latitude: '34.93988',
    longitude: '-81.837005',
    timeZoneId: 'America/New_York',
  },
  '29348': {
    zip: '29348',
    city: 'Gramling',
    state: 'SC',
    latitude: '35.082221',
    longitude: '-82.126458',
    timeZoneId: 'America/New_York',
  },
  '29349': {
    zip: '29349',
    city: 'Inman',
    state: 'SC',
    latitude: '35.061669',
    longitude: '-82.059864',
    timeZoneId: 'America/New_York',
  },
  '29351': {
    zip: '29351',
    city: 'Joanna',
    state: 'SC',
    latitude: '34.409867',
    longitude: '-81.817504',
    timeZoneId: 'America/New_York',
  },
  '29353': {
    zip: '29353',
    city: 'Jonesville',
    state: 'SC',
    latitude: '34.81512',
    longitude: '-81.69408',
    timeZoneId: 'America/New_York',
  },
  '29355': {
    zip: '29355',
    city: 'Kinards',
    state: 'SC',
    latitude: '34.353151',
    longitude: '-81.766819',
    timeZoneId: 'America/New_York',
  },
  '29356': {
    zip: '29356',
    city: 'Landrum',
    state: 'SC',
    latitude: '35.173475',
    longitude: '-82.182252',
    timeZoneId: 'America/New_York',
  },
  '29360': {
    zip: '29360',
    city: 'Laurens',
    state: 'SC',
    latitude: '34.499471',
    longitude: '-82.025271',
    timeZoneId: 'America/New_York',
  },
  '29364': {
    zip: '29364',
    city: 'Lockhart',
    state: 'SC',
    latitude: '34.785521',
    longitude: '-81.464419',
    timeZoneId: 'America/New_York',
  },
  '29365': {
    zip: '29365',
    city: 'Lyman',
    state: 'SC',
    latitude: '34.972496',
    longitude: '-82.151829',
    timeZoneId: 'America/New_York',
  },
  '29368': {
    zip: '29368',
    city: 'Mayo',
    state: 'SC',
    latitude: '35.072629',
    longitude: '-81.873086',
    timeZoneId: 'America/New_York',
  },
  '29369': {
    zip: '29369',
    city: 'Moore',
    state: 'SC',
    latitude: '34.874823',
    longitude: '-82.02079',
    timeZoneId: 'America/New_York',
  },
  '29370': {
    zip: '29370',
    city: 'Mountville',
    state: 'SC',
    latitude: '34.341308',
    longitude: '-81.957117',
    timeZoneId: 'America/New_York',
  },
  '29372': {
    zip: '29372',
    city: 'Pacolet',
    state: 'SC',
    latitude: '34.89871',
    longitude: '-81.758466',
    timeZoneId: 'America/New_York',
  },
  '29373': {
    zip: '29373',
    city: 'Pacolet Mills',
    state: 'SC',
    latitude: '34.914539',
    longitude: '-81.745893',
    timeZoneId: 'America/New_York',
  },
  '29374': {
    zip: '29374',
    city: 'Pauline',
    state: 'SC',
    latitude: '34.77366',
    longitude: '-81.85704',
    timeZoneId: 'America/New_York',
  },
  '29375': {
    zip: '29375',
    city: 'Reidville',
    state: 'SC',
    latitude: '34.857948',
    longitude: '-82.114151',
    timeZoneId: 'America/New_York',
  },
  '29376': {
    zip: '29376',
    city: 'Roebuck',
    state: 'SC',
    latitude: '34.860123',
    longitude: '-81.950767',
    timeZoneId: 'America/New_York',
  },
  '29377': {
    zip: '29377',
    city: 'Startex',
    state: 'SC',
    latitude: '34.931343',
    longitude: '-82.098636',
    timeZoneId: 'America/New_York',
  },
  '29378': {
    zip: '29378',
    city: 'Una',
    state: 'SC',
    latitude: '34.984132',
    longitude: '-81.987347',
    timeZoneId: 'America/New_York',
  },
  '29379': {
    zip: '29379',
    city: 'Union',
    state: 'SC',
    latitude: '34.714757',
    longitude: '-81.621133',
    timeZoneId: 'America/New_York',
  },
  '29384': {
    zip: '29384',
    city: 'Waterloo',
    state: 'SC',
    latitude: '34.335048',
    longitude: '-82.08485',
    timeZoneId: 'America/New_York',
  },
  '29385': {
    zip: '29385',
    city: 'Wellford',
    state: 'SC',
    latitude: '34.953723',
    longitude: '-82.095835',
    timeZoneId: 'America/New_York',
  },
  '29386': {
    zip: '29386',
    city: 'White Stone',
    state: 'SC',
    latitude: '34.883365',
    longitude: '-81.806934',
    timeZoneId: 'America/New_York',
  },
  '29388': {
    zip: '29388',
    city: 'Woodruff',
    state: 'SC',
    latitude: '34.759551',
    longitude: '-82.045514',
    timeZoneId: 'America/New_York',
  },
  '29395': {
    zip: '29395',
    city: 'Jonesville',
    state: 'SC',
    latitude: '34.855365',
    longitude: '-81.738114',
    timeZoneId: 'America/New_York',
  },
  '29401': {
    zip: '29401',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.779122',
    longitude: '-79.939806',
    timeZoneId: 'America/New_York',
  },
  '29402': {
    zip: '29402',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.774463',
    longitude: '-79.930662',
    timeZoneId: 'America/New_York',
  },
  '29403': {
    zip: '29403',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.798426',
    longitude: '-79.948096',
    timeZoneId: 'America/New_York',
  },
  '29404': {
    zip: '29404',
    city: 'Charleston AFB',
    state: 'SC',
    latitude: '32.893419',
    longitude: '-80.069777',
    timeZoneId: 'America/New_York',
  },
  '29405': {
    zip: '29405',
    city: 'North Charleston',
    state: 'SC',
    latitude: '32.85943',
    longitude: '-79.988157',
    timeZoneId: 'America/New_York',
  },
  '29406': {
    zip: '29406',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.92896',
    longitude: '-80.02842',
    timeZoneId: 'America/New_York',
  },
  '29407': {
    zip: '29407',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.800052',
    longitude: '-80.010928',
    timeZoneId: 'America/New_York',
  },
  '29409': {
    zip: '29409',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.794242',
    longitude: '-79.96077',
    timeZoneId: 'America/New_York',
  },
  '29410': {
    zip: '29410',
    city: 'Hanahan',
    state: 'SC',
    latitude: '33.194932',
    longitude: '-80.029749',
    timeZoneId: 'America/New_York',
  },
  '29412': {
    zip: '29412',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.71926',
    longitude: '-79.95108',
    timeZoneId: 'America/New_York',
  },
  '29413': {
    zip: '29413',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.793627',
    longitude: '-79.929515',
    timeZoneId: 'America/New_York',
  },
  '29414': {
    zip: '29414',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.821883',
    longitude: '-80.055197',
    timeZoneId: 'America/New_York',
  },
  '29415': {
    zip: '29415',
    city: 'North Charleston',
    state: 'SC',
    latitude: '33.027502',
    longitude: '-80.046168',
    timeZoneId: 'America/New_York',
  },
  '29416': {
    zip: '29416',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.87767',
    longitude: '-80.054864',
    timeZoneId: 'America/New_York',
  },
  '29417': {
    zip: '29417',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.786797',
    longitude: '-80.032618',
    timeZoneId: 'America/New_York',
  },
  '29418': {
    zip: '29418',
    city: 'North Charleston',
    state: 'SC',
    latitude: '32.89296',
    longitude: '-80.0595',
    timeZoneId: 'America/New_York',
  },
  '29419': {
    zip: '29419',
    city: 'North Charleston',
    state: 'SC',
    latitude: '32.936047',
    longitude: '-80.100088',
    timeZoneId: 'America/New_York',
  },
  '29420': {
    zip: '29420',
    city: 'North Charleston',
    state: 'SC',
    latitude: '32.923689',
    longitude: '-80.099458',
    timeZoneId: 'America/New_York',
  },
  '29422': {
    zip: '29422',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.681241',
    longitude: '-79.953645',
    timeZoneId: 'America/New_York',
  },
  '29423': {
    zip: '29423',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.845603',
    longitude: '-79.977751',
    timeZoneId: 'America/New_York',
  },
  '29424': {
    zip: '29424',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.783715',
    longitude: '-79.939185',
    timeZoneId: 'America/New_York',
  },
  '29425': {
    zip: '29425',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.785741',
    longitude: '-79.947783',
    timeZoneId: 'America/New_York',
  },
  '29426': {
    zip: '29426',
    city: 'Adams Run',
    state: 'SC',
    latitude: '32.78976',
    longitude: '-80.35296',
    timeZoneId: 'America/New_York',
  },
  '29429': {
    zip: '29429',
    city: 'Awendaw',
    state: 'SC',
    latitude: '32.950528',
    longitude: '-79.680316',
    timeZoneId: 'America/New_York',
  },
  '29431': {
    zip: '29431',
    city: 'Bonneau',
    state: 'SC',
    latitude: '33.318469',
    longitude: '-79.988526',
    timeZoneId: 'America/New_York',
  },
  '29432': {
    zip: '29432',
    city: 'Branchville',
    state: 'SC',
    latitude: '33.253796',
    longitude: '-80.816801',
    timeZoneId: 'America/New_York',
  },
  '29433': {
    zip: '29433',
    city: 'Canadys',
    state: 'SC',
    latitude: '33.054685',
    longitude: '-80.620733',
    timeZoneId: 'America/New_York',
  },
  '29434': {
    zip: '29434',
    city: 'Cordesville',
    state: 'SC',
    latitude: '33.131273',
    longitude: '-79.901616',
    timeZoneId: 'America/New_York',
  },
  '29435': {
    zip: '29435',
    city: 'Cottageville',
    state: 'SC',
    latitude: '32.938647',
    longitude: '-80.479809',
    timeZoneId: 'America/New_York',
  },
  '29436': {
    zip: '29436',
    city: 'Cross',
    state: 'SC',
    latitude: '33.300589',
    longitude: '-80.201392',
    timeZoneId: 'America/New_York',
  },
  '29437': {
    zip: '29437',
    city: 'Dorchester',
    state: 'SC',
    latitude: '33.143072',
    longitude: '-80.378909',
    timeZoneId: 'America/New_York',
  },
  '29438': {
    zip: '29438',
    city: 'Edisto Island',
    state: 'SC',
    latitude: '32.569486',
    longitude: '-80.310046',
    timeZoneId: 'America/New_York',
  },
  '29439': {
    zip: '29439',
    city: 'Folly Beach',
    state: 'SC',
    latitude: '32.661442',
    longitude: '-79.937395',
    timeZoneId: 'America/New_York',
  },
  '29440': {
    zip: '29440',
    city: 'Georgetown',
    state: 'SC',
    latitude: '33.52698',
    longitude: '-79.29846',
    timeZoneId: 'America/New_York',
  },
  '29442': {
    zip: '29442',
    city: 'Georgetown',
    state: 'SC',
    latitude: '33.383786',
    longitude: '-79.286956',
    timeZoneId: 'America/New_York',
  },
  '29445': {
    zip: '29445',
    city: 'Goose Creek',
    state: 'SC',
    latitude: '33.02808',
    longitude: '-80.04732',
    timeZoneId: 'America/New_York',
  },
  '29446': {
    zip: '29446',
    city: 'Green Pond',
    state: 'SC',
    latitude: '32.655449',
    longitude: '-80.51524',
    timeZoneId: 'America/New_York',
  },
  '29447': {
    zip: '29447',
    city: 'Grover',
    state: 'SC',
    latitude: '33.107714',
    longitude: '-80.592916',
    timeZoneId: 'America/New_York',
  },
  '29448': {
    zip: '29448',
    city: 'Harleyville',
    state: 'SC',
    latitude: '33.185687',
    longitude: '-80.577757',
    timeZoneId: 'America/New_York',
  },
  '29449': {
    zip: '29449',
    city: 'Hollywood',
    state: 'SC',
    latitude: '32.71104',
    longitude: '-80.27682',
    timeZoneId: 'America/New_York',
  },
  '29450': {
    zip: '29450',
    city: 'Huger',
    state: 'SC',
    latitude: '33.02091',
    longitude: '-79.790845',
    timeZoneId: 'America/New_York',
  },
  '29451': {
    zip: '29451',
    city: 'Isle of Palms',
    state: 'SC',
    latitude: '32.795877',
    longitude: '-79.769665',
    timeZoneId: 'America/New_York',
  },
  '29452': {
    zip: '29452',
    city: 'Jacksonboro',
    state: 'SC',
    latitude: '32.767539',
    longitude: '-80.456879',
    timeZoneId: 'America/New_York',
  },
  '29453': {
    zip: '29453',
    city: 'Jamestown',
    state: 'SC',
    latitude: '33.197783',
    longitude: '-79.691205',
    timeZoneId: 'America/New_York',
  },
  '29455': {
    zip: '29455',
    city: 'Johns Island',
    state: 'SC',
    latitude: '32.691291',
    longitude: '-80.091155',
    timeZoneId: 'America/New_York',
  },
  '29456': {
    zip: '29456',
    city: 'Ladson',
    state: 'SC',
    latitude: '33.016638',
    longitude: '-80.105646',
    timeZoneId: 'America/New_York',
  },
  '29457': {
    zip: '29457',
    city: 'Johns Island',
    state: 'SC',
    latitude: '32.74506',
    longitude: '-80.032709',
    timeZoneId: 'America/New_York',
  },
  '29458': {
    zip: '29458',
    city: 'Mc Clellanville',
    state: 'SC',
    latitude: '33.103751',
    longitude: '-79.470346',
    timeZoneId: 'America/New_York',
  },
  '29461': {
    zip: '29461',
    city: 'Moncks Corner',
    state: 'SC',
    latitude: '33.149449',
    longitude: '-80.028022',
    timeZoneId: 'America/New_York',
  },
  '29464': {
    zip: '29464',
    city: 'Mount Pleasant',
    state: 'SC',
    latitude: '32.82026',
    longitude: '-79.846061',
    timeZoneId: 'America/New_York',
  },
  '29465': {
    zip: '29465',
    city: 'Mount Pleasant',
    state: 'SC',
    latitude: '32.79433',
    longitude: '-79.86081',
    timeZoneId: 'America/New_York',
  },
  '29466': {
    zip: '29466',
    city: 'Mount Pleasant',
    state: 'SC',
    latitude: '32.792155',
    longitude: '-79.863357',
    timeZoneId: 'America/New_York',
  },
  '29468': {
    zip: '29468',
    city: 'Pineville',
    state: 'SC',
    latitude: '33.415669',
    longitude: '-80.085088',
    timeZoneId: 'America/New_York',
  },
  '29469': {
    zip: '29469',
    city: 'Pinopolis',
    state: 'SC',
    latitude: '33.224163',
    longitude: '-80.041291',
    timeZoneId: 'America/New_York',
  },
  '29470': {
    zip: '29470',
    city: 'Ravenel',
    state: 'SC',
    latitude: '32.791062',
    longitude: '-80.221919',
    timeZoneId: 'America/New_York',
  },
  '29471': {
    zip: '29471',
    city: 'Reevesville',
    state: 'SC',
    latitude: '33.179731',
    longitude: '-80.685009',
    timeZoneId: 'America/New_York',
  },
  '29472': {
    zip: '29472',
    city: 'Ridgeville',
    state: 'SC',
    latitude: '33.092825',
    longitude: '-80.316503',
    timeZoneId: 'America/New_York',
  },
  '29474': {
    zip: '29474',
    city: 'Round O',
    state: 'SC',
    latitude: '32.942016',
    longitude: '-80.577727',
    timeZoneId: 'America/New_York',
  },
  '29475': {
    zip: '29475',
    city: 'Ruffin',
    state: 'SC',
    latitude: '33.010716',
    longitude: '-80.823021',
    timeZoneId: 'America/New_York',
  },
  '29476': {
    zip: '29476',
    city: 'Russellville',
    state: 'SC',
    latitude: '33.397957',
    longitude: '-79.974782',
    timeZoneId: 'America/New_York',
  },
  '29477': {
    zip: '29477',
    city: 'Saint George',
    state: 'SC',
    latitude: '33.185354',
    longitude: '-80.581025',
    timeZoneId: 'America/New_York',
  },
  '29479': {
    zip: '29479',
    city: 'Saint Stephen',
    state: 'SC',
    latitude: '33.348889',
    longitude: '-79.816389',
    timeZoneId: 'America/New_York',
  },
  '29481': {
    zip: '29481',
    city: 'Smoaks',
    state: 'SC',
    latitude: '33.102798',
    longitude: '-80.811011',
    timeZoneId: 'America/New_York',
  },
  '29482': {
    zip: '29482',
    city: 'Sullivans Island',
    state: 'SC',
    latitude: '32.761726',
    longitude: '-79.837391',
    timeZoneId: 'America/New_York',
  },
  '29483': {
    zip: '29483',
    city: 'Summerville',
    state: 'SC',
    latitude: '33.017824',
    longitude: '-80.21135',
    timeZoneId: 'America/New_York',
  },
  '29484': {
    zip: '29484',
    city: 'Summerville',
    state: 'SC',
    latitude: '33.016437',
    longitude: '-80.203762',
    timeZoneId: 'America/New_York',
  },
  '29485': {
    zip: '29485',
    city: 'Summerville',
    state: 'SC',
    latitude: '32.975069',
    longitude: '-80.180159',
    timeZoneId: 'America/New_York',
  },
  '29486': {
    zip: '29486',
    city: 'Summerville',
    state: 'SC',
    latitude: '32.975069',
    longitude: '-80.180159',
    timeZoneId: 'America/New_York',
  },
  '29487': {
    zip: '29487',
    city: 'Wadmalaw Island',
    state: 'SC',
    latitude: '32.653461',
    longitude: '-80.187416',
    timeZoneId: 'America/New_York',
  },
  '29488': {
    zip: '29488',
    city: 'Walterboro',
    state: 'SC',
    latitude: '32.89122',
    longitude: '-80.6946',
    timeZoneId: 'America/New_York',
  },
  '29492': {
    zip: '29492',
    city: 'Charleston',
    state: 'SC',
    latitude: '32.955719',
    longitude: '-79.887276',
    timeZoneId: 'America/New_York',
  },
  '29493': {
    zip: '29493',
    city: 'Williams',
    state: 'SC',
    latitude: '33.032888',
    longitude: '-80.841541',
    timeZoneId: 'America/New_York',
  },
  '29501': {
    zip: '29501',
    city: 'Florence',
    state: 'SC',
    latitude: '34.182662',
    longitude: '-79.782749',
    timeZoneId: 'America/New_York',
  },
  '29502': {
    zip: '29502',
    city: 'Florence',
    state: 'SC',
    latitude: '34.195168',
    longitude: '-79.792218',
    timeZoneId: 'America/New_York',
  },
  '29503': {
    zip: '29503',
    city: 'Florence',
    state: 'SC',
    latitude: '34.192315',
    longitude: '-79.803716',
    timeZoneId: 'America/New_York',
  },
  '29504': {
    zip: '29504',
    city: 'Florence',
    state: 'SC',
    latitude: '34.184235',
    longitude: '-79.782543',
    timeZoneId: 'America/New_York',
  },
  '29505': {
    zip: '29505',
    city: 'Florence',
    state: 'SC',
    latitude: '34.128606',
    longitude: '-79.747284',
    timeZoneId: 'America/New_York',
  },
  '29506': {
    zip: '29506',
    city: 'Florence',
    state: 'SC',
    latitude: '34.2099',
    longitude: '-79.73412',
    timeZoneId: 'America/New_York',
  },
  '29510': {
    zip: '29510',
    city: 'Andrews',
    state: 'SC',
    latitude: '33.448476',
    longitude: '-79.555913',
    timeZoneId: 'America/New_York',
  },
  '29511': {
    zip: '29511',
    city: 'Aynor',
    state: 'SC',
    latitude: '33.990448',
    longitude: '-79.191606',
    timeZoneId: 'America/New_York',
  },
  '29512': {
    zip: '29512',
    city: 'Bennettsville',
    state: 'SC',
    latitude: '34.613793',
    longitude: '-79.686703',
    timeZoneId: 'America/New_York',
  },
  '29516': {
    zip: '29516',
    city: 'Blenheim',
    state: 'SC',
    latitude: '34.452562',
    longitude: '-79.653803',
    timeZoneId: 'America/New_York',
  },
  '29518': {
    zip: '29518',
    city: 'Cades',
    state: 'SC',
    latitude: '33.80225',
    longitude: '-79.802509',
    timeZoneId: 'America/New_York',
  },
  '29519': {
    zip: '29519',
    city: 'Centenary',
    state: 'SC',
    latitude: '34.029377',
    longitude: '-79.356406',
    timeZoneId: 'America/New_York',
  },
  '29520': {
    zip: '29520',
    city: 'Cheraw',
    state: 'SC',
    latitude: '34.691188',
    longitude: '-79.902254',
    timeZoneId: 'America/New_York',
  },
  '29525': {
    zip: '29525',
    city: 'Clio',
    state: 'SC',
    latitude: '34.577486',
    longitude: '-79.542351',
    timeZoneId: 'America/New_York',
  },
  '29526': {
    zip: '29526',
    city: 'Conway',
    state: 'SC',
    latitude: '33.832026',
    longitude: '-79.043531',
    timeZoneId: 'America/New_York',
  },
  '29527': {
    zip: '29527',
    city: 'Conway',
    state: 'SC',
    latitude: '33.74562',
    longitude: '-79.0749',
    timeZoneId: 'America/New_York',
  },
  '29528': {
    zip: '29528',
    city: 'Conway',
    state: 'SC',
    latitude: '33.840173',
    longitude: '-79.060795',
    timeZoneId: 'America/New_York',
  },
  '29530': {
    zip: '29530',
    city: 'Coward',
    state: 'SC',
    latitude: '34.003183',
    longitude: '-79.74392',
    timeZoneId: 'America/New_York',
  },
  '29532': {
    zip: '29532',
    city: 'Darlington',
    state: 'SC',
    latitude: '34.300363',
    longitude: '-79.87017',
    timeZoneId: 'America/New_York',
  },
  '29536': {
    zip: '29536',
    city: 'Dillon',
    state: 'SC',
    latitude: '34.414774',
    longitude: '-79.364457',
    timeZoneId: 'America/New_York',
  },
  '29540': {
    zip: '29540',
    city: 'Darlington',
    state: 'SC',
    latitude: '34.300877',
    longitude: '-79.875675',
    timeZoneId: 'America/New_York',
  },
  '29541': {
    zip: '29541',
    city: 'Effingham',
    state: 'SC',
    latitude: '34.117509',
    longitude: '-79.771376',
    timeZoneId: 'America/New_York',
  },
  '29543': {
    zip: '29543',
    city: 'Fork',
    state: 'SC',
    latitude: '34.288639',
    longitude: '-79.24171',
    timeZoneId: 'America/New_York',
  },
  '29544': {
    zip: '29544',
    city: 'Galivants Ferry',
    state: 'SC',
    latitude: '34.12728',
    longitude: '-79.10838',
    timeZoneId: 'America/New_York',
  },
  '29545': {
    zip: '29545',
    city: 'Green Sea',
    state: 'SC',
    latitude: '34.213666',
    longitude: '-79.060992',
    timeZoneId: 'America/New_York',
  },
  '29546': {
    zip: '29546',
    city: 'Gresham',
    state: 'SC',
    latitude: '33.93',
    longitude: '-79.41',
    timeZoneId: 'America/New_York',
  },
  '29547': {
    zip: '29547',
    city: 'Hamer',
    state: 'SC',
    latitude: '34.508632',
    longitude: '-79.405977',
    timeZoneId: 'America/New_York',
  },
  '29550': {
    zip: '29550',
    city: 'Hartsville',
    state: 'SC',
    latitude: '34.368444',
    longitude: '-80.081896',
    timeZoneId: 'America/New_York',
  },
  '29551': {
    zip: '29551',
    city: 'Hartsville',
    state: 'SC',
    latitude: '34.368964',
    longitude: '-80.086322',
    timeZoneId: 'America/New_York',
  },
  '29554': {
    zip: '29554',
    city: 'Hemingway',
    state: 'SC',
    latitude: '33.73902',
    longitude: '-79.44792',
    timeZoneId: 'America/New_York',
  },
  '29555': {
    zip: '29555',
    city: 'Johnsonville',
    state: 'SC',
    latitude: '33.817535',
    longitude: '-79.451852',
    timeZoneId: 'America/New_York',
  },
  '29556': {
    zip: '29556',
    city: 'Kingstree',
    state: 'SC',
    latitude: '33.67835',
    longitude: '-79.824331',
    timeZoneId: 'America/New_York',
  },
  '29560': {
    zip: '29560',
    city: 'Lake City',
    state: 'SC',
    latitude: '33.8695',
    longitude: '-79.760872',
    timeZoneId: 'America/New_York',
  },
  '29563': {
    zip: '29563',
    city: 'Lake View',
    state: 'SC',
    latitude: '34.346041',
    longitude: '-79.166819',
    timeZoneId: 'America/New_York',
  },
  '29564': {
    zip: '29564',
    city: 'Lane',
    state: 'SC',
    latitude: '33.462666',
    longitude: '-79.833819',
    timeZoneId: 'America/New_York',
  },
  '29565': {
    zip: '29565',
    city: 'Latta',
    state: 'SC',
    latitude: '34.335502',
    longitude: '-79.432246',
    timeZoneId: 'America/New_York',
  },
  '29566': {
    zip: '29566',
    city: 'Little River',
    state: 'SC',
    latitude: '33.885716',
    longitude: '-78.637355',
    timeZoneId: 'America/New_York',
  },
  '29567': {
    zip: '29567',
    city: 'Little Rock',
    state: 'SC',
    latitude: '34.405781',
    longitude: '-79.303152',
    timeZoneId: 'America/New_York',
  },
  '29568': {
    zip: '29568',
    city: 'Longs',
    state: 'SC',
    latitude: '33.887253',
    longitude: '-78.749124',
    timeZoneId: 'America/New_York',
  },
  '29569': {
    zip: '29569',
    city: 'Loris',
    state: 'SC',
    latitude: '34.020799',
    longitude: '-78.909328',
    timeZoneId: 'America/New_York',
  },
  '29570': {
    zip: '29570',
    city: 'Mc Coll',
    state: 'SC',
    latitude: '34.662498',
    longitude: '-79.544259',
    timeZoneId: 'America/New_York',
  },
  '29571': {
    zip: '29571',
    city: 'Marion',
    state: 'SC',
    latitude: '34.183414',
    longitude: '-79.396314',
    timeZoneId: 'America/New_York',
  },
  '29572': {
    zip: '29572',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.762507',
    longitude: '-78.798962',
    timeZoneId: 'America/New_York',
  },
  '29574': {
    zip: '29574',
    city: 'Mullins',
    state: 'SC',
    latitude: '34.203923',
    longitude: '-79.25682',
    timeZoneId: 'America/New_York',
  },
  '29575': {
    zip: '29575',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.626586',
    longitude: '-78.983446',
    timeZoneId: 'America/New_York',
  },
  '29576': {
    zip: '29576',
    city: 'Murrells Inlet',
    state: 'SC',
    latitude: '33.58677',
    longitude: '-79.009455',
    timeZoneId: 'America/New_York',
  },
  '29577': {
    zip: '29577',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.6606',
    longitude: '-78.92394',
    timeZoneId: 'America/New_York',
  },
  '29578': {
    zip: '29578',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.726237',
    longitude: '-78.844532',
    timeZoneId: 'America/New_York',
  },
  '29579': {
    zip: '29579',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.655004',
    longitude: '-78.945276',
    timeZoneId: 'America/New_York',
  },
  '29580': {
    zip: '29580',
    city: 'Nesmith',
    state: 'SC',
    latitude: '33.598052',
    longitude: '-79.566749',
    timeZoneId: 'America/New_York',
  },
  '29581': {
    zip: '29581',
    city: 'Nichols',
    state: 'SC',
    latitude: '34.227737',
    longitude: '-79.147719',
    timeZoneId: 'America/New_York',
  },
  '29582': {
    zip: '29582',
    city: 'North Myrtle Beach',
    state: 'SC',
    latitude: '33.82742',
    longitude: '-78.682495',
    timeZoneId: 'America/New_York',
  },
  '29583': {
    zip: '29583',
    city: 'Pamplico',
    state: 'SC',
    latitude: '33.993369',
    longitude: '-79.568322',
    timeZoneId: 'America/New_York',
  },
  '29584': {
    zip: '29584',
    city: 'Patrick',
    state: 'SC',
    latitude: '34.562239',
    longitude: '-80.076179',
    timeZoneId: 'America/New_York',
  },
  '29585': {
    zip: '29585',
    city: 'Pawleys Island',
    state: 'SC',
    latitude: '33.453325',
    longitude: '-79.135137',
    timeZoneId: 'America/New_York',
  },
  '29587': {
    zip: '29587',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.617875',
    longitude: '-78.987929',
    timeZoneId: 'America/New_York',
  },
  '29588': {
    zip: '29588',
    city: 'Myrtle Beach',
    state: 'SC',
    latitude: '33.676817',
    longitude: '-79.014238',
    timeZoneId: 'America/New_York',
  },
  '29589': {
    zip: '29589',
    city: 'Rains',
    state: 'SC',
    latitude: '34.18772',
    longitude: '-79.256942',
    timeZoneId: 'America/New_York',
  },
  '29590': {
    zip: '29590',
    city: 'Salters',
    state: 'SC',
    latitude: '33.562975',
    longitude: '-79.827528',
    timeZoneId: 'America/New_York',
  },
  '29591': {
    zip: '29591',
    city: 'Scranton',
    state: 'SC',
    latitude: '33.916161',
    longitude: '-79.742464',
    timeZoneId: 'America/New_York',
  },
  '29592': {
    zip: '29592',
    city: 'Sellers',
    state: 'SC',
    latitude: '34.252631',
    longitude: '-79.457951',
    timeZoneId: 'America/New_York',
  },
  '29593': {
    zip: '29593',
    city: 'Society Hill',
    state: 'SC',
    latitude: '34.444613',
    longitude: '-79.83773',
    timeZoneId: 'America/New_York',
  },
  '29594': {
    zip: '29594',
    city: 'Tatum',
    state: 'SC',
    latitude: '34.64521',
    longitude: '-79.585766',
    timeZoneId: 'America/New_York',
  },
  '29596': {
    zip: '29596',
    city: 'Wallace',
    state: 'SC',
    latitude: '34.728935',
    longitude: '-79.86291',
    timeZoneId: 'America/New_York',
  },
  '29597': {
    zip: '29597',
    city: 'North Myrtle Beach',
    state: 'SC',
    latitude: '33.812707',
    longitude: '-78.700848',
    timeZoneId: 'America/New_York',
  },
  '29598': {
    zip: '29598',
    city: 'North Myrtle Beach',
    state: 'SC',
    latitude: '33.80553',
    longitude: '-78.718152',
    timeZoneId: 'America/New_York',
  },
  '29601': {
    zip: '29601',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.85051',
    longitude: '-82.403553',
    timeZoneId: 'America/New_York',
  },
  '29602': {
    zip: '29602',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.855034',
    longitude: '-82.408153',
    timeZoneId: 'America/New_York',
  },
  '29603': {
    zip: '29603',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.850619',
    longitude: '-82.396693',
    timeZoneId: 'America/New_York',
  },
  '29604': {
    zip: '29604',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.830301',
    longitude: '-82.394264',
    timeZoneId: 'America/New_York',
  },
  '29605': {
    zip: '29605',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.799312',
    longitude: '-82.391757',
    timeZoneId: 'America/New_York',
  },
  '29606': {
    zip: '29606',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.858052',
    longitude: '-82.321696',
    timeZoneId: 'America/New_York',
  },
  '29607': {
    zip: '29607',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.829648',
    longitude: '-82.340068',
    timeZoneId: 'America/New_York',
  },
  '29608': {
    zip: '29608',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.901963',
    longitude: '-82.409599',
    timeZoneId: 'America/New_York',
  },
  '29609': {
    zip: '29609',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.897521',
    longitude: '-82.398093',
    timeZoneId: 'America/New_York',
  },
  '29610': {
    zip: '29610',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.931778',
    longitude: '-82.471944',
    timeZoneId: 'America/New_York',
  },
  '29611': {
    zip: '29611',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.854896',
    longitude: '-82.448532',
    timeZoneId: 'America/New_York',
  },
  '29612': {
    zip: '29612',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.847835',
    longitude: '-82.403959',
    timeZoneId: 'America/New_York',
  },
  '29613': {
    zip: '29613',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.949211',
    longitude: '-82.43869',
    timeZoneId: 'America/New_York',
  },
  '29614': {
    zip: '29614',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.91032',
    longitude: '-82.409036',
    timeZoneId: 'America/New_York',
  },
  '29615': {
    zip: '29615',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.868789',
    longitude: '-82.312139',
    timeZoneId: 'America/New_York',
  },
  '29616': {
    zip: '29616',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.854817',
    longitude: '-82.393105',
    timeZoneId: 'America/New_York',
  },
  '29617': {
    zip: '29617',
    city: 'Greenville',
    state: 'SC',
    latitude: '34.921125',
    longitude: '-82.476529',
    timeZoneId: 'America/New_York',
  },
  '29620': {
    zip: '29620',
    city: 'Abbeville',
    state: 'SC',
    latitude: '34.175299',
    longitude: '-82.379012',
    timeZoneId: 'America/New_York',
  },
  '29621': {
    zip: '29621',
    city: 'Anderson',
    state: 'SC',
    latitude: '34.523656',
    longitude: '-82.644593',
    timeZoneId: 'America/New_York',
  },
  '29622': {
    zip: '29622',
    city: 'Anderson',
    state: 'SC',
    latitude: '34.499718',
    longitude: '-82.660714',
    timeZoneId: 'America/New_York',
  },
  '29623': {
    zip: '29623',
    city: 'Anderson',
    state: 'SC',
    latitude: '34.519837',
    longitude: '-82.717055',
    timeZoneId: 'America/New_York',
  },
  '29624': {
    zip: '29624',
    city: 'Anderson',
    state: 'SC',
    latitude: '34.478216',
    longitude: '-82.68165',
    timeZoneId: 'America/New_York',
  },
  '29625': {
    zip: '29625',
    city: 'Anderson',
    state: 'SC',
    latitude: '34.536322',
    longitude: '-82.732656',
    timeZoneId: 'America/New_York',
  },
  '29626': {
    zip: '29626',
    city: 'Anderson',
    state: 'SC',
    latitude: '34.444494',
    longitude: '-82.754302',
    timeZoneId: 'America/New_York',
  },
  '29627': {
    zip: '29627',
    city: 'Belton',
    state: 'SC',
    latitude: '34.517182',
    longitude: '-82.501649',
    timeZoneId: 'America/New_York',
  },
  '29628': {
    zip: '29628',
    city: 'Calhoun Falls',
    state: 'SC',
    latitude: '34.092078',
    longitude: '-82.591621',
    timeZoneId: 'America/New_York',
  },
  '29630': {
    zip: '29630',
    city: 'Central',
    state: 'SC',
    latitude: '34.743252',
    longitude: '-82.799162',
    timeZoneId: 'America/New_York',
  },
  '29631': {
    zip: '29631',
    city: 'Clemson',
    state: 'SC',
    latitude: '34.685349',
    longitude: '-82.816921',
    timeZoneId: 'America/New_York',
  },
  '29632': {
    zip: '29632',
    city: 'Clemson',
    state: 'SC',
    latitude: '34.685014',
    longitude: '-82.811204',
    timeZoneId: 'America/New_York',
  },
  '29633': {
    zip: '29633',
    city: 'Clemson',
    state: 'SC',
    latitude: '34.69888',
    longitude: '-82.893146',
    timeZoneId: 'America/New_York',
  },
  '29634': {
    zip: '29634',
    city: 'Clemson',
    state: 'SC',
    latitude: '34.673146',
    longitude: '-82.823962',
    timeZoneId: 'America/New_York',
  },
  '29635': {
    zip: '29635',
    city: 'Cleveland',
    state: 'SC',
    latitude: '35.050003',
    longitude: '-82.64205',
    timeZoneId: 'America/New_York',
  },
  '29636': {
    zip: '29636',
    city: 'Conestee',
    state: 'SC',
    latitude: '34.769607',
    longitude: '-82.352293',
    timeZoneId: 'America/New_York',
  },
  '29638': {
    zip: '29638',
    city: 'Donalds',
    state: 'SC',
    latitude: '34.37435',
    longitude: '-82.272156',
    timeZoneId: 'America/New_York',
  },
  '29639': {
    zip: '29639',
    city: 'Due West',
    state: 'SC',
    latitude: '34.334985',
    longitude: '-82.382073',
    timeZoneId: 'America/New_York',
  },
  '29640': {
    zip: '29640',
    city: 'Easley',
    state: 'SC',
    latitude: '34.830936',
    longitude: '-82.589822',
    timeZoneId: 'America/New_York',
  },
  '29641': {
    zip: '29641',
    city: 'Easley',
    state: 'SC',
    latitude: '34.843681',
    longitude: '-82.595072',
    timeZoneId: 'America/New_York',
  },
  '29642': {
    zip: '29642',
    city: 'Easley',
    state: 'SC',
    latitude: '34.805816',
    longitude: '-82.562178',
    timeZoneId: 'America/New_York',
  },
  '29643': {
    zip: '29643',
    city: 'Fair Play',
    state: 'SC',
    latitude: '34.524647',
    longitude: '-83.042855',
    timeZoneId: 'America/New_York',
  },
  '29644': {
    zip: '29644',
    city: 'Fountain Inn',
    state: 'SC',
    latitude: '34.682989',
    longitude: '-82.217533',
    timeZoneId: 'America/New_York',
  },
  '29645': {
    zip: '29645',
    city: 'Gray Court',
    state: 'SC',
    latitude: '34.59576',
    longitude: '-82.14384',
    timeZoneId: 'America/New_York',
  },
  '29646': {
    zip: '29646',
    city: 'Greenwood',
    state: 'SC',
    latitude: '34.184847',
    longitude: '-82.152009',
    timeZoneId: 'America/New_York',
  },
  '29647': {
    zip: '29647',
    city: 'Greenwood',
    state: 'SC',
    latitude: '34.189754',
    longitude: '-82.15384',
    timeZoneId: 'America/New_York',
  },
  '29648': {
    zip: '29648',
    city: 'Greenwood',
    state: 'SC',
    latitude: '34.189151',
    longitude: '-82.156129',
    timeZoneId: 'America/New_York',
  },
  '29649': {
    zip: '29649',
    city: 'Greenwood',
    state: 'SC',
    latitude: '34.219994',
    longitude: '-82.160345',
    timeZoneId: 'America/New_York',
  },
  '29650': {
    zip: '29650',
    city: 'Greer',
    state: 'SC',
    latitude: '34.902548',
    longitude: '-82.265574',
    timeZoneId: 'America/New_York',
  },
  '29651': {
    zip: '29651',
    city: 'Greer',
    state: 'SC',
    latitude: '34.968571',
    longitude: '-82.255432',
    timeZoneId: 'America/New_York',
  },
  '29652': {
    zip: '29652',
    city: 'Greer',
    state: 'SC',
    latitude: '34.93786',
    longitude: '-82.202937',
    timeZoneId: 'America/New_York',
  },
  '29653': {
    zip: '29653',
    city: 'Hodges',
    state: 'SC',
    latitude: '34.287837',
    longitude: '-82.216353',
    timeZoneId: 'America/New_York',
  },
  '29654': {
    zip: '29654',
    city: 'Honea Path',
    state: 'SC',
    latitude: '34.446826',
    longitude: '-82.404793',
    timeZoneId: 'America/New_York',
  },
  '29655': {
    zip: '29655',
    city: 'Iva',
    state: 'SC',
    latitude: '34.3121',
    longitude: '-82.66968',
    timeZoneId: 'America/New_York',
  },
  '29656': {
    zip: '29656',
    city: 'La France',
    state: 'SC',
    latitude: '34.612072',
    longitude: '-82.761338',
    timeZoneId: 'America/New_York',
  },
  '29657': {
    zip: '29657',
    city: 'Liberty',
    state: 'SC',
    latitude: '34.788984',
    longitude: '-82.697845',
    timeZoneId: 'America/New_York',
  },
  '29658': {
    zip: '29658',
    city: 'Long Creek',
    state: 'SC',
    latitude: '34.762398',
    longitude: '-83.233773',
    timeZoneId: 'America/New_York',
  },
  '29659': {
    zip: '29659',
    city: 'Lowndesville',
    state: 'SC',
    latitude: '34.257916',
    longitude: '-82.583408',
    timeZoneId: 'America/New_York',
  },
  '29661': {
    zip: '29661',
    city: 'Marietta',
    state: 'SC',
    latitude: '35.056803',
    longitude: '-82.511622',
    timeZoneId: 'America/New_York',
  },
  '29662': {
    zip: '29662',
    city: 'Mauldin',
    state: 'SC',
    latitude: '34.778801',
    longitude: '-82.302512',
    timeZoneId: 'America/New_York',
  },
  '29664': {
    zip: '29664',
    city: 'Mountain Rest',
    state: 'SC',
    latitude: '34.805775',
    longitude: '-83.258248',
    timeZoneId: 'America/New_York',
  },
  '29665': {
    zip: '29665',
    city: 'Newry',
    state: 'SC',
    latitude: '34.717905',
    longitude: '-82.902959',
    timeZoneId: 'America/New_York',
  },
  '29666': {
    zip: '29666',
    city: 'Ninety Six',
    state: 'SC',
    latitude: '34.173267',
    longitude: '-82.019642',
    timeZoneId: 'America/New_York',
  },
  '29667': {
    zip: '29667',
    city: 'Norris',
    state: 'SC',
    latitude: '34.759485',
    longitude: '-82.770568',
    timeZoneId: 'America/New_York',
  },
  '29669': {
    zip: '29669',
    city: 'Pelzer',
    state: 'SC',
    latitude: '34.651323',
    longitude: '-82.494694',
    timeZoneId: 'America/New_York',
  },
  '29670': {
    zip: '29670',
    city: 'Pendleton',
    state: 'SC',
    latitude: '34.645861',
    longitude: '-82.76726',
    timeZoneId: 'America/New_York',
  },
  '29671': {
    zip: '29671',
    city: 'Pickens',
    state: 'SC',
    latitude: '34.889416',
    longitude: '-82.72739',
    timeZoneId: 'America/New_York',
  },
  '29672': {
    zip: '29672',
    city: 'Seneca',
    state: 'SC',
    latitude: '34.685658',
    longitude: '-82.956989',
    timeZoneId: 'America/New_York',
  },
  '29673': {
    zip: '29673',
    city: 'Piedmont',
    state: 'SC',
    latitude: '34.67513',
    longitude: '-82.534256',
    timeZoneId: 'America/New_York',
  },
  '29675': {
    zip: '29675',
    city: 'Richland',
    state: 'SC',
    latitude: '34.704787',
    longitude: '-83.020014',
    timeZoneId: 'America/New_York',
  },
  '29676': {
    zip: '29676',
    city: 'Salem',
    state: 'SC',
    latitude: '34.838477',
    longitude: '-82.930726',
    timeZoneId: 'America/New_York',
  },
  '29677': {
    zip: '29677',
    city: 'Sandy Springs',
    state: 'SC',
    latitude: '34.595195',
    longitude: '-82.737557',
    timeZoneId: 'America/New_York',
  },
  '29678': {
    zip: '29678',
    city: 'Seneca',
    state: 'SC',
    latitude: '34.693402',
    longitude: '-82.941284',
    timeZoneId: 'America/New_York',
  },
  '29679': {
    zip: '29679',
    city: 'Seneca',
    state: 'SC',
    latitude: '34.685966',
    longitude: '-82.944821',
    timeZoneId: 'America/New_York',
  },
  '29680': {
    zip: '29680',
    city: 'Simpsonville',
    state: 'SC',
    latitude: '34.720389',
    longitude: '-82.270653',
    timeZoneId: 'America/New_York',
  },
  '29681': {
    zip: '29681',
    city: 'Simpsonville',
    state: 'SC',
    latitude: '34.777584',
    longitude: '-82.128196',
    timeZoneId: 'America/New_York',
  },
  '29682': {
    zip: '29682',
    city: 'Six Mile',
    state: 'SC',
    latitude: '34.829053',
    longitude: '-82.831145',
    timeZoneId: 'America/New_York',
  },
  '29683': {
    zip: '29683',
    city: 'Slater',
    state: 'SC',
    latitude: '35.032403',
    longitude: '-82.494379',
    timeZoneId: 'America/New_York',
  },
  '29684': {
    zip: '29684',
    city: 'Starr',
    state: 'SC',
    latitude: '34.397696',
    longitude: '-82.667729',
    timeZoneId: 'America/New_York',
  },
  '29685': {
    zip: '29685',
    city: 'Sunset',
    state: 'SC',
    latitude: '34.920954',
    longitude: '-82.821687',
    timeZoneId: 'America/New_York',
  },
  '29686': {
    zip: '29686',
    city: 'Tamassee',
    state: 'SC',
    latitude: '34.902168',
    longitude: '-82.978665',
    timeZoneId: 'America/New_York',
  },
  '29687': {
    zip: '29687',
    city: 'Taylors',
    state: 'SC',
    latitude: '34.933851',
    longitude: '-82.317958',
    timeZoneId: 'America/New_York',
  },
  '29688': {
    zip: '29688',
    city: 'Tigerville',
    state: 'SC',
    latitude: '35.062449',
    longitude: '-82.352257',
    timeZoneId: 'America/New_York',
  },
  '29689': {
    zip: '29689',
    city: 'Townville',
    state: 'SC',
    latitude: '34.534882',
    longitude: '-82.869969',
    timeZoneId: 'America/New_York',
  },
  '29690': {
    zip: '29690',
    city: 'Travelers Rest',
    state: 'SC',
    latitude: '35.00406',
    longitude: '-82.435522',
    timeZoneId: 'America/New_York',
  },
  '29691': {
    zip: '29691',
    city: 'Walhalla',
    state: 'SC',
    latitude: '34.766678',
    longitude: '-83.062038',
    timeZoneId: 'America/New_York',
  },
  '29692': {
    zip: '29692',
    city: 'Ware Shoals',
    state: 'SC',
    latitude: '34.391023',
    longitude: '-82.244579',
    timeZoneId: 'America/New_York',
  },
  '29693': {
    zip: '29693',
    city: 'Westminster',
    state: 'SC',
    latitude: '34.642443',
    longitude: '-83.088445',
    timeZoneId: 'America/New_York',
  },
  '29695': {
    zip: '29695',
    city: 'Hodges',
    state: 'SC',
    latitude: '34.287453',
    longitude: '-82.245208',
    timeZoneId: 'America/New_York',
  },
  '29696': {
    zip: '29696',
    city: 'West Union',
    state: 'SC',
    latitude: '34.772475',
    longitude: '-83.047952',
    timeZoneId: 'America/New_York',
  },
  '29697': {
    zip: '29697',
    city: 'Williamston',
    state: 'SC',
    latitude: '34.618937',
    longitude: '-82.496462',
    timeZoneId: 'America/New_York',
  },
  '29702': {
    zip: '29702',
    city: 'Blacksburg',
    state: 'SC',
    latitude: '35.11567',
    longitude: '-81.513969',
    timeZoneId: 'America/New_York',
  },
  '29703': {
    zip: '29703',
    city: 'Bowling Green',
    state: 'SC',
    latitude: '35.1455',
    longitude: '-81.223077',
    timeZoneId: 'America/New_York',
  },
  '29704': {
    zip: '29704',
    city: 'Catawba',
    state: 'SC',
    latitude: '34.845853',
    longitude: '-80.933937',
    timeZoneId: 'America/New_York',
  },
  '29706': {
    zip: '29706',
    city: 'Chester',
    state: 'SC',
    latitude: '34.711411',
    longitude: '-81.207675',
    timeZoneId: 'America/New_York',
  },
  '29707': {
    zip: '29707',
    city: 'Fort Mill',
    state: 'SC',
    latitude: '35.006139',
    longitude: '-80.947219',
    timeZoneId: 'America/New_York',
  },
  '29708': {
    zip: '29708',
    city: 'Fort Mill',
    state: 'SC',
    latitude: '35.006322',
    longitude: '-80.942526',
    timeZoneId: 'America/New_York',
  },
  '29709': {
    zip: '29709',
    city: 'Chesterfield',
    state: 'SC',
    latitude: '34.736824',
    longitude: '-80.095126',
    timeZoneId: 'America/New_York',
  },
  '29710': {
    zip: '29710',
    city: 'Clover',
    state: 'SC',
    latitude: '35.105301',
    longitude: '-81.184746',
    timeZoneId: 'America/New_York',
  },
  '29712': {
    zip: '29712',
    city: 'Edgemoor',
    state: 'SC',
    latitude: '34.829331',
    longitude: '-81.012395',
    timeZoneId: 'America/New_York',
  },
  '29714': {
    zip: '29714',
    city: 'Fort Lawn',
    state: 'SC',
    latitude: '34.701218',
    longitude: '-80.913514',
    timeZoneId: 'America/New_York',
  },
  '29715': {
    zip: '29715',
    city: 'Fort Mill',
    state: 'SC',
    latitude: '35.02632',
    longitude: '-80.9472',
    timeZoneId: 'America/New_York',
  },
  '29716': {
    zip: '29716',
    city: 'Fort Mill',
    state: 'SC',
    latitude: '35.006945',
    longitude: '-80.942339',
    timeZoneId: 'America/New_York',
  },
  '29717': {
    zip: '29717',
    city: 'Hickory Grove',
    state: 'SC',
    latitude: '34.970701',
    longitude: '-81.426068',
    timeZoneId: 'America/New_York',
  },
  '29718': {
    zip: '29718',
    city: 'Jefferson',
    state: 'SC',
    latitude: '34.584331',
    longitude: '-80.419337',
    timeZoneId: 'America/New_York',
  },
  '29720': {
    zip: '29720',
    city: 'Lancaster',
    state: 'SC',
    latitude: '34.709391',
    longitude: '-80.775133',
    timeZoneId: 'America/New_York',
  },
  '29721': {
    zip: '29721',
    city: 'Lancaster',
    state: 'SC',
    latitude: '34.747105',
    longitude: '-80.718422',
    timeZoneId: 'America/New_York',
  },
  '29722': {
    zip: '29722',
    city: 'Lancaster',
    state: 'SC',
    latitude: '34.749579',
    longitude: '-80.720816',
    timeZoneId: 'America/New_York',
  },
  '29724': {
    zip: '29724',
    city: 'Lando',
    state: 'SC',
    latitude: '34.778471',
    longitude: '-81.012826',
    timeZoneId: 'America/New_York',
  },
  '29726': {
    zip: '29726',
    city: 'Mc Connells',
    state: 'SC',
    latitude: '34.872034',
    longitude: '-81.223083',
    timeZoneId: 'America/New_York',
  },
  '29727': {
    zip: '29727',
    city: 'Mount Croghan',
    state: 'SC',
    latitude: '34.75005',
    longitude: '-80.250547',
    timeZoneId: 'America/New_York',
  },
  '29728': {
    zip: '29728',
    city: 'Pageland',
    state: 'SC',
    latitude: '34.769328',
    longitude: '-80.392158',
    timeZoneId: 'America/New_York',
  },
  '29729': {
    zip: '29729',
    city: 'Richburg',
    state: 'SC',
    latitude: '34.775868',
    longitude: '-81.026327',
    timeZoneId: 'America/New_York',
  },
  '29730': {
    zip: '29730',
    city: 'Rock Hill',
    state: 'SC',
    latitude: '34.929113',
    longitude: '-81.022958',
    timeZoneId: 'America/New_York',
  },
  '29731': {
    zip: '29731',
    city: 'Rock Hill',
    state: 'SC',
    latitude: '34.96369',
    longitude: '-81.051035',
    timeZoneId: 'America/New_York',
  },
  '29732': {
    zip: '29732',
    city: 'Rock Hill',
    state: 'SC',
    latitude: '34.970453',
    longitude: '-81.060985',
    timeZoneId: 'America/New_York',
  },
  '29733': {
    zip: '29733',
    city: 'Rock Hill',
    state: 'SC',
    latitude: '34.890601',
    longitude: '-81.024567',
    timeZoneId: 'America/New_York',
  },
  '29734': {
    zip: '29734',
    city: 'Rock Hill',
    state: 'SC',
    latitude: '34.886025',
    longitude: '-81.022063',
    timeZoneId: 'America/New_York',
  },
  '29741': {
    zip: '29741',
    city: 'Ruby',
    state: 'SC',
    latitude: '34.703963',
    longitude: '-80.206821',
    timeZoneId: 'America/New_York',
  },
  '29742': {
    zip: '29742',
    city: 'Sharon',
    state: 'SC',
    latitude: '34.91321',
    longitude: '-81.34476',
    timeZoneId: 'America/New_York',
  },
  '29743': {
    zip: '29743',
    city: 'Smyrna',
    state: 'SC',
    latitude: '35.031662',
    longitude: '-81.412116',
    timeZoneId: 'America/New_York',
  },
  '29744': {
    zip: '29744',
    city: 'Van Wyck',
    state: 'SC',
    latitude: '34.858501',
    longitude: '-80.846028',
    timeZoneId: 'America/New_York',
  },
  '29745': {
    zip: '29745',
    city: 'York',
    state: 'SC',
    latitude: '35.004572',
    longitude: '-81.209303',
    timeZoneId: 'America/New_York',
  },
  '29801': {
    zip: '29801',
    city: 'Aiken',
    state: 'SC',
    latitude: '33.564827',
    longitude: '-81.709984',
    timeZoneId: 'America/New_York',
  },
  '29802': {
    zip: '29802',
    city: 'Aiken',
    state: 'SC',
    latitude: '33.562561',
    longitude: '-81.720328',
    timeZoneId: 'America/New_York',
  },
  '29803': {
    zip: '29803',
    city: 'Aiken',
    state: 'SC',
    latitude: '33.504406',
    longitude: '-81.724533',
    timeZoneId: 'America/New_York',
  },
  '29804': {
    zip: '29804',
    city: 'Aiken',
    state: 'SC',
    latitude: '33.532298',
    longitude: '-81.570882',
    timeZoneId: 'America/New_York',
  },
  '29805': {
    zip: '29805',
    city: 'Aiken',
    state: 'SC',
    latitude: '33.698508',
    longitude: '-81.634909',
    timeZoneId: 'America/New_York',
  },
  '29808': {
    zip: '29808',
    city: 'Aiken',
    state: 'SC',
    latitude: '33.248093',
    longitude: '-81.649368',
    timeZoneId: 'America/New_York',
  },
  '29809': {
    zip: '29809',
    city: 'New Ellenton',
    state: 'SC',
    latitude: '33.414659',
    longitude: '-81.688024',
    timeZoneId: 'America/New_York',
  },
  '29810': {
    zip: '29810',
    city: 'Allendale',
    state: 'SC',
    latitude: '33.006169',
    longitude: '-81.306627',
    timeZoneId: 'America/New_York',
  },
  '29812': {
    zip: '29812',
    city: 'Barnwell',
    state: 'SC',
    latitude: '33.243078',
    longitude: '-81.359153',
    timeZoneId: 'America/New_York',
  },
  '29813': {
    zip: '29813',
    city: 'Hilda',
    state: 'SC',
    latitude: '33.241913',
    longitude: '-81.361784',
    timeZoneId: 'America/New_York',
  },
  '29816': {
    zip: '29816',
    city: 'Bath',
    state: 'SC',
    latitude: '33.500799',
    longitude: '-81.867579',
    timeZoneId: 'America/New_York',
  },
  '29817': {
    zip: '29817',
    city: 'Blackville',
    state: 'SC',
    latitude: '33.356585',
    longitude: '-81.27563',
    timeZoneId: 'America/New_York',
  },
  '29819': {
    zip: '29819',
    city: 'Bradley',
    state: 'SC',
    latitude: '34.109752',
    longitude: '-82.233224',
    timeZoneId: 'America/New_York',
  },
  '29821': {
    zip: '29821',
    city: 'Clarks Hill',
    state: 'SC',
    latitude: '33.643723',
    longitude: '-82.146272',
    timeZoneId: 'America/New_York',
  },
  '29822': {
    zip: '29822',
    city: 'Clearwater',
    state: 'SC',
    latitude: '33.497709',
    longitude: '-81.893574',
    timeZoneId: 'America/New_York',
  },
  '29824': {
    zip: '29824',
    city: 'Edgefield',
    state: 'SC',
    latitude: '33.78696',
    longitude: '-81.929623',
    timeZoneId: 'America/New_York',
  },
  '29826': {
    zip: '29826',
    city: 'Elko',
    state: 'SC',
    latitude: '33.379504',
    longitude: '-81.377307',
    timeZoneId: 'America/New_York',
  },
  '29827': {
    zip: '29827',
    city: 'Fairfax',
    state: 'SC',
    latitude: '32.92338',
    longitude: '-81.28578',
    timeZoneId: 'America/New_York',
  },
  '29828': {
    zip: '29828',
    city: 'Gloverville',
    state: 'SC',
    latitude: '33.520944',
    longitude: '-81.826713',
    timeZoneId: 'America/New_York',
  },
  '29829': {
    zip: '29829',
    city: 'Graniteville',
    state: 'SC',
    latitude: '33.560576',
    longitude: '-81.828149',
    timeZoneId: 'America/New_York',
  },
  '29831': {
    zip: '29831',
    city: 'Jackson',
    state: 'SC',
    latitude: '33.343729',
    longitude: '-81.80796',
    timeZoneId: 'America/New_York',
  },
  '29832': {
    zip: '29832',
    city: 'Johnston',
    state: 'SC',
    latitude: '33.833823',
    longitude: '-81.807516',
    timeZoneId: 'America/New_York',
  },
  '29834': {
    zip: '29834',
    city: 'Langley',
    state: 'SC',
    latitude: '33.513651',
    longitude: '-81.847009',
    timeZoneId: 'America/New_York',
  },
  '29835': {
    zip: '29835',
    city: 'Mc Cormick',
    state: 'SC',
    latitude: '33.911159',
    longitude: '-82.290467',
    timeZoneId: 'America/New_York',
  },
  '29836': {
    zip: '29836',
    city: 'Martin',
    state: 'SC',
    latitude: '33.096723',
    longitude: '-81.525746',
    timeZoneId: 'America/New_York',
  },
  '29838': {
    zip: '29838',
    city: 'Modoc',
    state: 'SC',
    latitude: '33.708528',
    longitude: '-82.217647',
    timeZoneId: 'America/New_York',
  },
  '29839': {
    zip: '29839',
    city: 'Montmorenci',
    state: 'SC',
    latitude: '33.50628',
    longitude: '-81.600469',
    timeZoneId: 'America/New_York',
  },
  '29840': {
    zip: '29840',
    city: 'Mount Carmel',
    state: 'SC',
    latitude: '33.988998',
    longitude: '-82.446249',
    timeZoneId: 'America/New_York',
  },
  '29841': {
    zip: '29841',
    city: 'North Augusta',
    state: 'SC',
    latitude: '33.512008',
    longitude: '-81.932644',
    timeZoneId: 'America/New_York',
  },
  '29842': {
    zip: '29842',
    city: 'Beech Island',
    state: 'SC',
    latitude: '33.408592',
    longitude: '-81.881677',
    timeZoneId: 'America/New_York',
  },
  '29843': {
    zip: '29843',
    city: 'Olar',
    state: 'SC',
    latitude: '33.174153',
    longitude: '-81.153464',
    timeZoneId: 'America/New_York',
  },
  '29844': {
    zip: '29844',
    city: 'Parksville',
    state: 'SC',
    latitude: '33.786637',
    longitude: '-82.217188',
    timeZoneId: 'America/New_York',
  },
  '29845': {
    zip: '29845',
    city: 'Plum Branch',
    state: 'SC',
    latitude: '33.816772',
    longitude: '-82.235638',
    timeZoneId: 'America/New_York',
  },
  '29846': {
    zip: '29846',
    city: 'Sycamore',
    state: 'SC',
    latitude: '33.037812',
    longitude: '-81.224619',
    timeZoneId: 'America/New_York',
  },
  '29847': {
    zip: '29847',
    city: 'Trenton',
    state: 'SC',
    latitude: '33.70116',
    longitude: '-81.81978',
    timeZoneId: 'America/New_York',
  },
  '29848': {
    zip: '29848',
    city: 'Troy',
    state: 'SC',
    latitude: '33.965222',
    longitude: '-82.287072',
    timeZoneId: 'America/New_York',
  },
  '29849': {
    zip: '29849',
    city: 'Ulmer',
    state: 'SC',
    latitude: '33.055326',
    longitude: '-81.215569',
    timeZoneId: 'America/New_York',
  },
  '29850': {
    zip: '29850',
    city: 'Vaucluse',
    state: 'SC',
    latitude: '33.608533',
    longitude: '-81.805438',
    timeZoneId: 'America/New_York',
  },
  '29851': {
    zip: '29851',
    city: 'Warrenville',
    state: 'SC',
    latitude: '33.518108',
    longitude: '-81.820849',
    timeZoneId: 'America/New_York',
  },
  '29853': {
    zip: '29853',
    city: 'Williston',
    state: 'SC',
    latitude: '33.397983',
    longitude: '-81.424281',
    timeZoneId: 'America/New_York',
  },
  '29856': {
    zip: '29856',
    city: 'Windsor',
    state: 'SC',
    latitude: '33.47626',
    longitude: '-81.527087',
    timeZoneId: 'America/New_York',
  },
  '29860': {
    zip: '29860',
    city: 'North Augusta',
    state: 'SC',
    latitude: '33.501601',
    longitude: '-81.962785',
    timeZoneId: 'America/New_York',
  },
  '29861': {
    zip: '29861',
    city: 'North Augusta',
    state: 'SC',
    latitude: '33.517536',
    longitude: '-81.958594',
    timeZoneId: 'America/New_York',
  },
  '29899': {
    zip: '29899',
    city: 'Mc Cormick',
    state: 'SC',
    latitude: '33.908678',
    longitude: '-82.290232',
    timeZoneId: 'America/New_York',
  },
  '29901': {
    zip: '29901',
    city: 'Beaufort',
    state: 'SC',
    latitude: '32.417543',
    longitude: '-80.657499',
    timeZoneId: 'America/New_York',
  },
  '29902': {
    zip: '29902',
    city: 'Beaufort',
    state: 'SC',
    latitude: '32.44746',
    longitude: '-80.70864',
    timeZoneId: 'America/New_York',
  },
  '29903': {
    zip: '29903',
    city: 'Beaufort',
    state: 'SC',
    latitude: '32.424633',
    longitude: '-80.725146',
    timeZoneId: 'America/New_York',
  },
  '29904': {
    zip: '29904',
    city: 'Beaufort',
    state: 'SC',
    latitude: '32.457864',
    longitude: '-80.8014',
    timeZoneId: 'America/New_York',
  },
  '29905': {
    zip: '29905',
    city: 'Parris Island',
    state: 'SC',
    latitude: '32.34388',
    longitude: '-80.665757',
    timeZoneId: 'America/New_York',
  },
  '29906': {
    zip: '29906',
    city: 'Beaufort',
    state: 'SC',
    latitude: '32.432733',
    longitude: '-80.673074',
    timeZoneId: 'America/New_York',
  },
  '29907': {
    zip: '29907',
    city: 'Beaufort',
    state: 'SC',
    latitude: '32.427269',
    longitude: '-80.7022',
    timeZoneId: 'America/New_York',
  },
  '29909': {
    zip: '29909',
    city: 'Okatie',
    state: 'SC',
    latitude: '32.262421',
    longitude: '-80.867479',
    timeZoneId: 'America/New_York',
  },
  '29910': {
    zip: '29910',
    city: 'Bluffton',
    state: 'SC',
    latitude: '32.259699',
    longitude: '-80.872324',
    timeZoneId: 'America/New_York',
  },
  '29911': {
    zip: '29911',
    city: 'Brunson',
    state: 'SC',
    latitude: '32.925432',
    longitude: '-81.18777',
    timeZoneId: 'America/New_York',
  },
  '29912': {
    zip: '29912',
    city: 'Coosawhatchie',
    state: 'SC',
    latitude: '32.488115',
    longitude: '-80.987419',
    timeZoneId: 'America/New_York',
  },
  '29913': {
    zip: '29913',
    city: 'Crocketville',
    state: 'SC',
    latitude: '32.913225',
    longitude: '-81.07413',
    timeZoneId: 'America/New_York',
  },
  '29914': {
    zip: '29914',
    city: 'Dale',
    state: 'SC',
    latitude: '32.554485',
    longitude: '-80.692502',
    timeZoneId: 'America/New_York',
  },
  '29915': {
    zip: '29915',
    city: 'Daufuskie Island',
    state: 'SC',
    latitude: '32.216111',
    longitude: '-80.752778',
    timeZoneId: 'America/New_York',
  },
  '29916': {
    zip: '29916',
    city: 'Early Branch',
    state: 'SC',
    latitude: '32.63532',
    longitude: '-81.03492',
    timeZoneId: 'America/New_York',
  },
  '29918': {
    zip: '29918',
    city: 'Estill',
    state: 'SC',
    latitude: '32.74452',
    longitude: '-81.24012',
    timeZoneId: 'America/New_York',
  },
  '29920': {
    zip: '29920',
    city: 'Saint Helena Island',
    state: 'SC',
    latitude: '32.36994',
    longitude: '-80.5503',
    timeZoneId: 'America/New_York',
  },
  '29921': {
    zip: '29921',
    city: 'Furman',
    state: 'SC',
    latitude: '32.760287',
    longitude: '-81.241951',
    timeZoneId: 'America/New_York',
  },
  '29922': {
    zip: '29922',
    city: 'Garnett',
    state: 'SC',
    latitude: '32.60199',
    longitude: '-81.24181',
    timeZoneId: 'America/New_York',
  },
  '29923': {
    zip: '29923',
    city: 'Gifford',
    state: 'SC',
    latitude: '32.86129',
    longitude: '-81.239714',
    timeZoneId: 'America/New_York',
  },
  '29924': {
    zip: '29924',
    city: 'Hampton',
    state: 'SC',
    latitude: '32.867113',
    longitude: '-81.11664',
    timeZoneId: 'America/New_York',
  },
  '29925': {
    zip: '29925',
    city: 'Hilton Head Island',
    state: 'SC',
    latitude: '32.164766',
    longitude: '-80.754408',
    timeZoneId: 'America/New_York',
  },
  '29926': {
    zip: '29926',
    city: 'Hilton Head Island',
    state: 'SC',
    latitude: '32.234951',
    longitude: '-80.735624',
    timeZoneId: 'America/New_York',
  },
  '29927': {
    zip: '29927',
    city: 'Hardeeville',
    state: 'SC',
    latitude: '32.2813',
    longitude: '-81.071015',
    timeZoneId: 'America/New_York',
  },
  '29928': {
    zip: '29928',
    city: 'Hilton Head Island',
    state: 'SC',
    latitude: '32.161512',
    longitude: '-80.752518',
    timeZoneId: 'America/New_York',
  },
  '29929': {
    zip: '29929',
    city: 'Islandton',
    state: 'SC',
    latitude: '32.953565',
    longitude: '-80.883542',
    timeZoneId: 'America/New_York',
  },
  '29931': {
    zip: '29931',
    city: 'Lobeco',
    state: 'SC',
    latitude: '32.553916',
    longitude: '-80.745388',
    timeZoneId: 'America/New_York',
  },
  '29932': {
    zip: '29932',
    city: 'Luray',
    state: 'SC',
    latitude: '32.864636',
    longitude: '-81.228486',
    timeZoneId: 'America/New_York',
  },
  '29933': {
    zip: '29933',
    city: 'Miley',
    state: 'SC',
    latitude: '32.948572',
    longitude: '-81.031062',
    timeZoneId: 'America/New_York',
  },
  '29934': {
    zip: '29934',
    city: 'Pineland',
    state: 'SC',
    latitude: '32.581515',
    longitude: '-81.191837',
    timeZoneId: 'America/New_York',
  },
  '29935': {
    zip: '29935',
    city: 'Port Royal',
    state: 'SC',
    latitude: '32.385662',
    longitude: '-80.69289',
    timeZoneId: 'America/New_York',
  },
  '29936': {
    zip: '29936',
    city: 'Ridgeland',
    state: 'SC',
    latitude: '32.481598',
    longitude: '-80.984342',
    timeZoneId: 'America/New_York',
  },
  '29938': {
    zip: '29938',
    city: 'Hilton Head Island',
    state: 'SC',
    latitude: '32.16153',
    longitude: '-80.752119',
    timeZoneId: 'America/New_York',
  },
  '29939': {
    zip: '29939',
    city: 'Scotia',
    state: 'SC',
    latitude: '32.681996',
    longitude: '-81.245041',
    timeZoneId: 'America/New_York',
  },
  '29940': {
    zip: '29940',
    city: 'Seabrook',
    state: 'SC',
    latitude: '32.52245',
    longitude: '-80.745942',
    timeZoneId: 'America/New_York',
  },
  '29941': {
    zip: '29941',
    city: 'Sheldon',
    state: 'SC',
    latitude: '32.602379',
    longitude: '-80.790344',
    timeZoneId: 'America/New_York',
  },
  '29943': {
    zip: '29943',
    city: 'Tillman',
    state: 'SC',
    latitude: '32.481456',
    longitude: '-81.113941',
    timeZoneId: 'America/New_York',
  },
  '29944': {
    zip: '29944',
    city: 'Varnville',
    state: 'SC',
    latitude: '32.857618',
    longitude: '-81.07728',
    timeZoneId: 'America/New_York',
  },
  '29945': {
    zip: '29945',
    city: 'Yemassee',
    state: 'SC',
    latitude: '32.65422',
    longitude: '-80.82672',
    timeZoneId: 'America/New_York',
  },
  '30002': {
    zip: '30002',
    city: 'Avondale Estates',
    state: 'GA',
    latitude: '33.772774',
    longitude: '-84.264732',
    timeZoneId: 'America/New_York',
  },
  '30003': {
    zip: '30003',
    city: 'Norcross',
    state: 'GA',
    latitude: '33.68081',
    longitude: '-84.00732',
    timeZoneId: 'America/New_York',
  },
  '30004': {
    zip: '30004',
    city: 'Alpharetta',
    state: 'GA',
    latitude: '34.122744',
    longitude: '-84.296757',
    timeZoneId: 'America/New_York',
  },
  '30005': {
    zip: '30005',
    city: 'Alpharetta',
    state: 'GA',
    latitude: '34.08061',
    longitude: '-84.200785',
    timeZoneId: 'America/New_York',
  },
  '30006': {
    zip: '30006',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.953731',
    longitude: '-84.541162',
    timeZoneId: 'America/New_York',
  },
  '30007': {
    zip: '30007',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.963312',
    longitude: '-84.551056',
    timeZoneId: 'America/New_York',
  },
  '30008': {
    zip: '30008',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.90374',
    longitude: '-84.582593',
    timeZoneId: 'America/New_York',
  },
  '30009': {
    zip: '30009',
    city: 'Alpharetta',
    state: 'GA',
    latitude: '34.096195',
    longitude: '-84.261245',
    timeZoneId: 'America/New_York',
  },
  '30010': {
    zip: '30010',
    city: 'Norcross',
    state: 'GA',
    latitude: '33.966056',
    longitude: '-84.218437',
    timeZoneId: 'America/New_York',
  },
  '30011': {
    zip: '30011',
    city: 'Auburn',
    state: 'GA',
    latitude: '34.02374',
    longitude: '-83.826404',
    timeZoneId: 'America/New_York',
  },
  '30012': {
    zip: '30012',
    city: 'Conyers',
    state: 'GA',
    latitude: '33.706453',
    longitude: '-84.005117',
    timeZoneId: 'America/New_York',
  },
  '30013': {
    zip: '30013',
    city: 'Conyers',
    state: 'GA',
    latitude: '33.640211',
    longitude: '-83.962891',
    timeZoneId: 'America/New_York',
  },
  '30014': {
    zip: '30014',
    city: 'Covington',
    state: 'GA',
    latitude: '33.587144',
    longitude: '-83.832307',
    timeZoneId: 'America/New_York',
  },
  '30015': {
    zip: '30015',
    city: 'Covington',
    state: 'GA',
    latitude: '33.600466',
    longitude: '-83.853776',
    timeZoneId: 'America/New_York',
  },
  '30016': {
    zip: '30016',
    city: 'Covington',
    state: 'GA',
    latitude: '33.530099',
    longitude: '-83.944327',
    timeZoneId: 'America/New_York',
  },
  '30017': {
    zip: '30017',
    city: 'Grayson',
    state: 'GA',
    latitude: '33.891156',
    longitude: '-83.963999',
    timeZoneId: 'America/New_York',
  },
  '30018': {
    zip: '30018',
    city: 'Jersey',
    state: 'GA',
    latitude: '33.715558',
    longitude: '-83.801524',
    timeZoneId: 'America/New_York',
  },
  '30019': {
    zip: '30019',
    city: 'Dacula',
    state: 'GA',
    latitude: '33.975719',
    longitude: '-83.87393',
    timeZoneId: 'America/New_York',
  },
  '30021': {
    zip: '30021',
    city: 'Clarkston',
    state: 'GA',
    latitude: '33.805596',
    longitude: '-84.236301',
    timeZoneId: 'America/New_York',
  },
  '30022': {
    zip: '30022',
    city: 'Alpharetta',
    state: 'GA',
    latitude: '34.029562',
    longitude: '-84.240658',
    timeZoneId: 'America/New_York',
  },
  '30023': {
    zip: '30023',
    city: 'Alpharetta',
    state: 'GA',
    latitude: '34.067631',
    longitude: '-84.271155',
    timeZoneId: 'America/New_York',
  },
  '30024': {
    zip: '30024',
    city: 'Suwanee',
    state: 'GA',
    latitude: '34.062747',
    longitude: '-84.083283',
    timeZoneId: 'America/New_York',
  },
  '30025': {
    zip: '30025',
    city: 'Social Circle',
    state: 'GA',
    latitude: '33.654242',
    longitude: '-83.686948',
    timeZoneId: 'America/New_York',
  },
  '30026': {
    zip: '30026',
    city: 'North Metro',
    state: 'GA',
    latitude: '33.976579',
    longitude: '-84.150049',
    timeZoneId: 'America/New_York',
  },
  '30028': {
    zip: '30028',
    city: 'Cumming',
    state: 'GA',
    latitude: '34.205991',
    longitude: '-84.136243',
    timeZoneId: 'America/New_York',
  },
  '30029': {
    zip: '30029',
    city: 'North Metro',
    state: 'GA',
    latitude: '33.977944',
    longitude: '-84.147687',
    timeZoneId: 'America/New_York',
  },
  '30030': {
    zip: '30030',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.773883',
    longitude: '-84.295376',
    timeZoneId: 'America/New_York',
  },
  '30031': {
    zip: '30031',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.767202',
    longitude: '-84.285482',
    timeZoneId: 'America/New_York',
  },
  '30032': {
    zip: '30032',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.735464',
    longitude: '-84.266983',
    timeZoneId: 'America/New_York',
  },
  '30033': {
    zip: '30033',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.817078',
    longitude: '-84.281903',
    timeZoneId: 'America/New_York',
  },
  '30034': {
    zip: '30034',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.689216',
    longitude: '-84.247271',
    timeZoneId: 'America/New_York',
  },
  '30035': {
    zip: '30035',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.726646',
    longitude: '-84.207866',
    timeZoneId: 'America/New_York',
  },
  '30036': {
    zip: '30036',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.774347',
    longitude: '-84.291903',
    timeZoneId: 'America/New_York',
  },
  '30037': {
    zip: '30037',
    city: 'Decatur',
    state: 'GA',
    latitude: '33.691557',
    longitude: '-84.253279',
    timeZoneId: 'America/New_York',
  },
  '30038': {
    zip: '30038',
    city: 'Lithonia',
    state: 'GA',
    latitude: '33.681893',
    longitude: '-84.161895',
    timeZoneId: 'America/New_York',
  },
  '30039': {
    zip: '30039',
    city: 'Snellville',
    state: 'GA',
    latitude: '33.8162',
    longitude: '-84.01802',
    timeZoneId: 'America/New_York',
  },
  '30040': {
    zip: '30040',
    city: 'Cumming',
    state: 'GA',
    latitude: '34.249703',
    longitude: '-84.175246',
    timeZoneId: 'America/New_York',
  },
  '30041': {
    zip: '30041',
    city: 'Cumming',
    state: 'GA',
    latitude: '34.203077',
    longitude: '-84.074322',
    timeZoneId: 'America/New_York',
  },
  '30042': {
    zip: '30042',
    city: 'Lawrenceville',
    state: 'GA',
    latitude: '33.923544',
    longitude: '-84.07302',
    timeZoneId: 'America/New_York',
  },
  '30043': {
    zip: '30043',
    city: 'Lawrenceville',
    state: 'GA',
    latitude: '34.009393',
    longitude: '-84.001553',
    timeZoneId: 'America/New_York',
  },
  '30044': {
    zip: '30044',
    city: 'Lawrenceville',
    state: 'GA',
    latitude: '33.921718',
    longitude: '-84.07267',
    timeZoneId: 'America/New_York',
  },
  '30045': {
    zip: '30045',
    city: 'Lawrenceville',
    state: 'GA',
    latitude: '33.937159',
    longitude: '-83.9611',
    timeZoneId: 'America/New_York',
  },
  '30046': {
    zip: '30046',
    city: 'Lawrenceville',
    state: 'GA',
    latitude: '33.94069',
    longitude: '-83.960938',
    timeZoneId: 'America/New_York',
  },
  '30047': {
    zip: '30047',
    city: 'Lilburn',
    state: 'GA',
    latitude: '33.874851',
    longitude: '-84.113781',
    timeZoneId: 'America/New_York',
  },
  '30048': {
    zip: '30048',
    city: 'Lilburn',
    state: 'GA',
    latitude: '33.90025',
    longitude: '-84.125496',
    timeZoneId: 'America/New_York',
  },
  '30049': {
    zip: '30049',
    city: 'Lawrenceville',
    state: 'GA',
    latitude: '33.95024',
    longitude: '-83.98441',
    timeZoneId: 'America/New_York',
  },
  '30052': {
    zip: '30052',
    city: 'Loganville',
    state: 'GA',
    latitude: '33.810339',
    longitude: '-83.891909',
    timeZoneId: 'America/New_York',
  },
  '30054': {
    zip: '30054',
    city: 'Oxford',
    state: 'GA',
    latitude: '33.677484',
    longitude: '-83.872134',
    timeZoneId: 'America/New_York',
  },
  '30055': {
    zip: '30055',
    city: 'Mansfield',
    state: 'GA',
    latitude: '33.493766',
    longitude: '-83.750336',
    timeZoneId: 'America/New_York',
  },
  '30056': {
    zip: '30056',
    city: 'Newborn',
    state: 'GA',
    latitude: '33.4845',
    longitude: '-83.665156',
    timeZoneId: 'America/New_York',
  },
  '30058': {
    zip: '30058',
    city: 'Lithonia',
    state: 'GA',
    latitude: '33.727929',
    longitude: '-84.123557',
    timeZoneId: 'America/New_York',
  },
  '30060': {
    zip: '30060',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.936824',
    longitude: '-84.529346',
    timeZoneId: 'America/New_York',
  },
  '30061': {
    zip: '30061',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.920083',
    longitude: '-84.544072',
    timeZoneId: 'America/New_York',
  },
  '30062': {
    zip: '30062',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.999118',
    longitude: '-84.467523',
    timeZoneId: 'America/New_York',
  },
  '30063': {
    zip: '30063',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.928513',
    longitude: '-84.522094',
    timeZoneId: 'America/New_York',
  },
  '30064': {
    zip: '30064',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.939798',
    longitude: '-84.614184',
    timeZoneId: 'America/New_York',
  },
  '30065': {
    zip: '30065',
    city: 'Marietta',
    state: 'GA',
    latitude: '34.047214',
    longitude: '-84.442482',
    timeZoneId: 'America/New_York',
  },
  '30066': {
    zip: '30066',
    city: 'Marietta',
    state: 'GA',
    latitude: '34.036122',
    longitude: '-84.505267',
    timeZoneId: 'America/New_York',
  },
  '30067': {
    zip: '30067',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.930563',
    longitude: '-84.462218',
    timeZoneId: 'America/New_York',
  },
  '30068': {
    zip: '30068',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.965429',
    longitude: '-84.43882',
    timeZoneId: 'America/New_York',
  },
  '30069': {
    zip: '30069',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.916908',
    longitude: '-84.517971',
    timeZoneId: 'America/New_York',
  },
  '30070': {
    zip: '30070',
    city: 'Porterdale',
    state: 'GA',
    latitude: '33.575459',
    longitude: '-83.897275',
    timeZoneId: 'America/New_York',
  },
  '30071': {
    zip: '30071',
    city: 'Norcross',
    state: 'GA',
    latitude: '33.939475',
    longitude: '-84.197677',
    timeZoneId: 'America/New_York',
  },
  '30072': {
    zip: '30072',
    city: 'Pine Lake',
    state: 'GA',
    latitude: '33.79286',
    longitude: '-84.207364',
    timeZoneId: 'America/New_York',
  },
  '30074': {
    zip: '30074',
    city: 'Redan',
    state: 'GA',
    latitude: '33.744614',
    longitude: '-84.135993',
    timeZoneId: 'America/New_York',
  },
  '30075': {
    zip: '30075',
    city: 'Roswell',
    state: 'GA',
    latitude: '34.046659',
    longitude: '-84.376691',
    timeZoneId: 'America/New_York',
  },
  '30076': {
    zip: '30076',
    city: 'Roswell',
    state: 'GA',
    latitude: '34.026099',
    longitude: '-84.311677',
    timeZoneId: 'America/New_York',
  },
  '30077': {
    zip: '30077',
    city: 'Roswell',
    state: 'GA',
    latitude: '34.040334',
    longitude: '-84.354585',
    timeZoneId: 'America/New_York',
  },
  '30078': {
    zip: '30078',
    city: 'Snellville',
    state: 'GA',
    latitude: '33.854024',
    longitude: '-84.010874',
    timeZoneId: 'America/New_York',
  },
  '30079': {
    zip: '30079',
    city: 'Scottdale',
    state: 'GA',
    latitude: '33.792377',
    longitude: '-84.260882',
    timeZoneId: 'America/New_York',
  },
  '30080': {
    zip: '30080',
    city: 'Smyrna',
    state: 'GA',
    latitude: '33.872023',
    longitude: '-84.507595',
    timeZoneId: 'America/New_York',
  },
  '30081': {
    zip: '30081',
    city: 'Smyrna',
    state: 'GA',
    latitude: '33.870025',
    longitude: '-84.518265',
    timeZoneId: 'America/New_York',
  },
  '30082': {
    zip: '30082',
    city: 'Smyrna',
    state: 'GA',
    latitude: '33.862636',
    longitude: '-84.539199',
    timeZoneId: 'America/New_York',
  },
  '30083': {
    zip: '30083',
    city: 'Stone Mountain',
    state: 'GA',
    latitude: '33.789125',
    longitude: '-84.199181',
    timeZoneId: 'America/New_York',
  },
  '30084': {
    zip: '30084',
    city: 'Tucker',
    state: 'GA',
    latitude: '33.848103',
    longitude: '-84.226117',
    timeZoneId: 'America/New_York',
  },
  '30085': {
    zip: '30085',
    city: 'Tucker',
    state: 'GA',
    latitude: '33.857014',
    longitude: '-84.222994',
    timeZoneId: 'America/New_York',
  },
  '30086': {
    zip: '30086',
    city: 'Stone Mountain',
    state: 'GA',
    latitude: '33.803359',
    longitude: '-84.103425',
    timeZoneId: 'America/New_York',
  },
  '30087': {
    zip: '30087',
    city: 'Stone Mountain',
    state: 'GA',
    latitude: '33.801564',
    longitude: '-84.138154',
    timeZoneId: 'America/New_York',
  },
  '30088': {
    zip: '30088',
    city: 'Stone Mountain',
    state: 'GA',
    latitude: '33.762657',
    longitude: '-84.176061',
    timeZoneId: 'America/New_York',
  },
  '30090': {
    zip: '30090',
    city: 'Marietta',
    state: 'GA',
    latitude: '33.968982',
    longitude: '-84.548663',
    timeZoneId: 'America/New_York',
  },
  '30091': {
    zip: '30091',
    city: 'Norcross',
    state: 'GA',
    latitude: '33.946415',
    longitude: '-84.175009',
    timeZoneId: 'America/New_York',
  },
  '30092': {
    zip: '30092',
    city: 'Peachtree Corners',
    state: 'GA',
    latitude: '33.973773',
    longitude: '-84.238127',
    timeZoneId: 'America/New_York',
  },
  '30093': {
    zip: '30093',
    city: 'Norcross',
    state: 'GA',
    latitude: '33.905579',
    longitude: '-84.179966',
    timeZoneId: 'America/New_York',
  },
  '30094': {
    zip: '30094',
    city: 'Conyers',
    state: 'GA',
    latitude: '33.601564',
    longitude: '-84.060376',
    timeZoneId: 'America/New_York',
  },
  '30095': {
    zip: '30095',
    city: 'Duluth',
    state: 'GA',
    latitude: '33.977522',
    longitude: '-84.148704',
    timeZoneId: 'America/New_York',
  },
  '30096': {
    zip: '30096',
    city: 'Duluth',
    state: 'GA',
    latitude: '33.985868',
    longitude: '-84.163754',
    timeZoneId: 'America/New_York',
  },
  '30097': {
    zip: '30097',
    city: 'Duluth',
    state: 'GA',
    latitude: '34.025741',
    longitude: '-84.141816',
    timeZoneId: 'America/New_York',
  },
  '30098': {
    zip: '30098',
    city: 'Duluth',
    state: 'GA',
    latitude: '34.02556',
    longitude: '-84.145413',
    timeZoneId: 'America/New_York',
  },
  '30099': {
    zip: '30099',
    city: 'Duluth',
    state: 'GA',
    latitude: '33.980117',
    longitude: '-84.149338',
    timeZoneId: 'America/New_York',
  },
  '30101': {
    zip: '30101',
    city: 'Acworth',
    state: 'GA',
    latitude: '34.035593',
    longitude: '-84.708375',
    timeZoneId: 'America/New_York',
  },
  '30102': {
    zip: '30102',
    city: 'Acworth',
    state: 'GA',
    latitude: '34.097324',
    longitude: '-84.614194',
    timeZoneId: 'America/New_York',
  },
  '30103': {
    zip: '30103',
    city: 'Adairsville',
    state: 'GA',
    latitude: '34.34964',
    longitude: '-84.914492',
    timeZoneId: 'America/New_York',
  },
  '30104': {
    zip: '30104',
    city: 'Aragon',
    state: 'GA',
    latitude: '34.061779',
    longitude: '-85.078993',
    timeZoneId: 'America/New_York',
  },
  '30105': {
    zip: '30105',
    city: 'Armuchee',
    state: 'GA',
    latitude: '34.426568',
    longitude: '-85.168719',
    timeZoneId: 'America/New_York',
  },
  '30106': {
    zip: '30106',
    city: 'Austell',
    state: 'GA',
    latitude: '33.835384',
    longitude: '-84.630102',
    timeZoneId: 'America/New_York',
  },
  '30107': {
    zip: '30107',
    city: 'Ball Ground',
    state: 'GA',
    latitude: '34.333388',
    longitude: '-84.346223',
    timeZoneId: 'America/New_York',
  },
  '30108': {
    zip: '30108',
    city: 'Bowdon',
    state: 'GA',
    latitude: '33.537435',
    longitude: '-85.256617',
    timeZoneId: 'America/New_York',
  },
  '30109': {
    zip: '30109',
    city: 'Bowdon Junction',
    state: 'GA',
    latitude: '33.567436',
    longitude: '-85.081472',
    timeZoneId: 'America/New_York',
  },
  '30110': {
    zip: '30110',
    city: 'Bremen',
    state: 'GA',
    latitude: '33.739322',
    longitude: '-85.135317',
    timeZoneId: 'America/New_York',
  },
  '30111': {
    zip: '30111',
    city: 'Clarkdale',
    state: 'GA',
    latitude: '33.804574',
    longitude: '-84.606511',
    timeZoneId: 'America/New_York',
  },
  '30112': {
    zip: '30112',
    city: 'Carrollton',
    state: 'GA',
    latitude: '33.580424',
    longitude: '-85.087792',
    timeZoneId: 'America/New_York',
  },
  '30113': {
    zip: '30113',
    city: 'Buchanan',
    state: 'GA',
    latitude: '33.833164',
    longitude: '-85.193198',
    timeZoneId: 'America/New_York',
  },
  '30114': {
    zip: '30114',
    city: 'Canton',
    state: 'GA',
    latitude: '34.247481',
    longitude: '-84.524085',
    timeZoneId: 'America/New_York',
  },
  '30115': {
    zip: '30115',
    city: 'Canton',
    state: 'GA',
    latitude: '34.207461',
    longitude: '-84.40182',
    timeZoneId: 'America/New_York',
  },
  '30116': {
    zip: '30116',
    city: 'Carrollton',
    state: 'GA',
    latitude: '33.493045',
    longitude: '-85.031651',
    timeZoneId: 'America/New_York',
  },
  '30117': {
    zip: '30117',
    city: 'Carrollton',
    state: 'GA',
    latitude: '33.589927',
    longitude: '-85.120833',
    timeZoneId: 'America/New_York',
  },
  '30118': {
    zip: '30118',
    city: 'Carrollton',
    state: 'GA',
    latitude: '33.571798',
    longitude: '-85.10691',
    timeZoneId: 'America/New_York',
  },
  '30119': {
    zip: '30119',
    city: 'Carrollton',
    state: 'GA',
    latitude: '33.589126',
    longitude: '-85.13233',
    timeZoneId: 'America/New_York',
  },
  '30120': {
    zip: '30120',
    city: 'Cartersville',
    state: 'GA',
    latitude: '34.177555',
    longitude: '-84.854308',
    timeZoneId: 'America/New_York',
  },
  '30121': {
    zip: '30121',
    city: 'Cartersville',
    state: 'GA',
    latitude: '34.228469',
    longitude: '-84.789525',
    timeZoneId: 'America/New_York',
  },
  '30122': {
    zip: '30122',
    city: 'Lithia Springs',
    state: 'GA',
    latitude: '33.773697',
    longitude: '-84.637899',
    timeZoneId: 'America/New_York',
  },
  '30123': {
    zip: '30123',
    city: 'Cassville',
    state: 'GA',
    latitude: '34.248055',
    longitude: '-84.855986',
    timeZoneId: 'America/New_York',
  },
  '30124': {
    zip: '30124',
    city: 'Cave Spring',
    state: 'GA',
    latitude: '34.125103',
    longitude: '-85.349734',
    timeZoneId: 'America/New_York',
  },
  '30125': {
    zip: '30125',
    city: 'Cedartown',
    state: 'GA',
    latitude: '34.010998',
    longitude: '-85.256877',
    timeZoneId: 'America/New_York',
  },
  '30126': {
    zip: '30126',
    city: 'Mableton',
    state: 'GA',
    latitude: '33.813468',
    longitude: '-84.548054',
    timeZoneId: 'America/New_York',
  },
  '30127': {
    zip: '30127',
    city: 'Powder Springs',
    state: 'GA',
    latitude: '33.883327',
    longitude: '-84.688671',
    timeZoneId: 'America/New_York',
  },
  '30129': {
    zip: '30129',
    city: 'Coosa',
    state: 'GA',
    latitude: '34.258619',
    longitude: '-85.338529',
    timeZoneId: 'America/New_York',
  },
  '30132': {
    zip: '30132',
    city: 'Dallas',
    state: 'GA',
    latitude: '33.994458',
    longitude: '-84.859977',
    timeZoneId: 'America/New_York',
  },
  '30133': {
    zip: '30133',
    city: 'Douglasville',
    state: 'GA',
    latitude: '33.735023',
    longitude: '-84.702085',
    timeZoneId: 'America/New_York',
  },
  '30134': {
    zip: '30134',
    city: 'Douglasville',
    state: 'GA',
    latitude: '33.759987',
    longitude: '-84.740136',
    timeZoneId: 'America/New_York',
  },
  '30135': {
    zip: '30135',
    city: 'Douglasville',
    state: 'GA',
    latitude: '33.696171',
    longitude: '-84.742497',
    timeZoneId: 'America/New_York',
  },
  '30137': {
    zip: '30137',
    city: 'Emerson',
    state: 'GA',
    latitude: '34.115139',
    longitude: '-84.758982',
    timeZoneId: 'America/New_York',
  },
  '30138': {
    zip: '30138',
    city: 'Esom Hill',
    state: 'GA',
    latitude: '34.001797',
    longitude: '-85.268188',
    timeZoneId: 'America/New_York',
  },
  '30139': {
    zip: '30139',
    city: 'Fairmount',
    state: 'GA',
    latitude: '34.460296',
    longitude: '-84.740922',
    timeZoneId: 'America/New_York',
  },
  '30140': {
    zip: '30140',
    city: 'Felton',
    state: 'GA',
    latitude: '33.884836',
    longitude: '-85.219859',
    timeZoneId: 'America/New_York',
  },
  '30141': {
    zip: '30141',
    city: 'Hiram',
    state: 'GA',
    latitude: '33.869888',
    longitude: '-84.768038',
    timeZoneId: 'America/New_York',
  },
  '30142': {
    zip: '30142',
    city: 'Holly Springs',
    state: 'GA',
    latitude: '34.175472',
    longitude: '-84.498039',
    timeZoneId: 'America/New_York',
  },
  '30143': {
    zip: '30143',
    city: 'Jasper',
    state: 'GA',
    latitude: '34.4589',
    longitude: '-84.50406',
    timeZoneId: 'America/New_York',
  },
  '30144': {
    zip: '30144',
    city: 'Kennesaw',
    state: 'GA',
    latitude: '34.034049',
    longitude: '-84.584675',
    timeZoneId: 'America/New_York',
  },
  '30145': {
    zip: '30145',
    city: 'Kingston',
    state: 'GA',
    latitude: '34.236623',
    longitude: '-84.96654',
    timeZoneId: 'America/New_York',
  },
  '30146': {
    zip: '30146',
    city: 'Lebanon',
    state: 'GA',
    latitude: '34.225248',
    longitude: '-84.458297',
    timeZoneId: 'America/New_York',
  },
  '30147': {
    zip: '30147',
    city: 'Lindale',
    state: 'GA',
    latitude: '34.171707',
    longitude: '-85.189331',
    timeZoneId: 'America/New_York',
  },
  '30148': {
    zip: '30148',
    city: 'Marble Hill',
    state: 'GA',
    latitude: '34.455391',
    longitude: '-84.270318',
    timeZoneId: 'America/New_York',
  },
  '30149': {
    zip: '30149',
    city: 'Mount Berry',
    state: 'GA',
    latitude: '34.275',
    longitude: '-85.18332',
    timeZoneId: 'America/New_York',
  },
  '30150': {
    zip: '30150',
    city: 'Mount Zion',
    state: 'GA',
    latitude: '33.625905',
    longitude: '-85.185263',
    timeZoneId: 'America/New_York',
  },
  '30151': {
    zip: '30151',
    city: 'Nelson',
    state: 'GA',
    latitude: '34.3824',
    longitude: '-84.3693',
    timeZoneId: 'America/New_York',
  },
  '30152': {
    zip: '30152',
    city: 'Kennesaw',
    state: 'GA',
    latitude: '33.99056',
    longitude: '-84.68088',
    timeZoneId: 'America/New_York',
  },
  '30153': {
    zip: '30153',
    city: 'Rockmart',
    state: 'GA',
    latitude: '33.9875',
    longitude: '-85.0551',
    timeZoneId: 'America/New_York',
  },
  '30154': {
    zip: '30154',
    city: 'Douglasville',
    state: 'GA',
    latitude: '33.744279',
    longitude: '-84.746956',
    timeZoneId: 'America/New_York',
  },
  '30156': {
    zip: '30156',
    city: 'Kennesaw',
    state: 'GA',
    latitude: '34.02481',
    longitude: '-84.612261',
    timeZoneId: 'America/New_York',
  },
  '30157': {
    zip: '30157',
    city: 'Dallas',
    state: 'GA',
    latitude: '33.873764',
    longitude: '-84.86505',
    timeZoneId: 'America/New_York',
  },
  '30160': {
    zip: '30160',
    city: 'Kennesaw',
    state: 'GA',
    latitude: '34.025101',
    longitude: '-84.615154',
    timeZoneId: 'America/New_York',
  },
  '30161': {
    zip: '30161',
    city: 'Rome',
    state: 'GA',
    latitude: '34.24549',
    longitude: '-85.109805',
    timeZoneId: 'America/New_York',
  },
  '30162': {
    zip: '30162',
    city: 'Rome',
    state: 'GA',
    latitude: '34.246306',
    longitude: '-85.127709',
    timeZoneId: 'America/New_York',
  },
  '30164': {
    zip: '30164',
    city: 'Rome',
    state: 'GA',
    latitude: '34.276431',
    longitude: '-85.231078',
    timeZoneId: 'America/New_York',
  },
  '30165': {
    zip: '30165',
    city: 'Rome',
    state: 'GA',
    latitude: '34.282882',
    longitude: '-85.237598',
    timeZoneId: 'America/New_York',
  },
  '30168': {
    zip: '30168',
    city: 'Austell',
    state: 'GA',
    latitude: '33.779077',
    longitude: '-84.570132',
    timeZoneId: 'America/New_York',
  },
  '30169': {
    zip: '30169',
    city: 'Canton',
    state: 'GA',
    latitude: '34.247238',
    longitude: '-84.521591',
    timeZoneId: 'America/New_York',
  },
  '30170': {
    zip: '30170',
    city: 'Roopville',
    state: 'GA',
    latitude: '33.418873',
    longitude: '-85.174117',
    timeZoneId: 'America/New_York',
  },
  '30171': {
    zip: '30171',
    city: 'Rydal',
    state: 'GA',
    latitude: '34.346521',
    longitude: '-84.737632',
    timeZoneId: 'America/New_York',
  },
  '30172': {
    zip: '30172',
    city: 'Shannon',
    state: 'GA',
    latitude: '34.343073',
    longitude: '-85.083756',
    timeZoneId: 'America/New_York',
  },
  '30173': {
    zip: '30173',
    city: 'Silver Creek',
    state: 'GA',
    latitude: '34.1499',
    longitude: '-85.139033',
    timeZoneId: 'America/New_York',
  },
  '30175': {
    zip: '30175',
    city: 'Talking Rock',
    state: 'GA',
    latitude: '34.545086',
    longitude: '-84.527119',
    timeZoneId: 'America/New_York',
  },
  '30176': {
    zip: '30176',
    city: 'Tallapoosa',
    state: 'GA',
    latitude: '33.753316',
    longitude: '-85.291849',
    timeZoneId: 'America/New_York',
  },
  '30177': {
    zip: '30177',
    city: 'Tate',
    state: 'GA',
    latitude: '34.462624',
    longitude: '-84.317684',
    timeZoneId: 'America/New_York',
  },
  '30178': {
    zip: '30178',
    city: 'Taylorsville',
    state: 'GA',
    latitude: '34.118231',
    longitude: '-84.974477',
    timeZoneId: 'America/New_York',
  },
  '30179': {
    zip: '30179',
    city: 'Temple',
    state: 'GA',
    latitude: '33.808273',
    longitude: '-85.018501',
    timeZoneId: 'America/New_York',
  },
  '30180': {
    zip: '30180',
    city: 'Villa Rica',
    state: 'GA',
    latitude: '33.708023',
    longitude: '-84.932487',
    timeZoneId: 'America/New_York',
  },
  '30182': {
    zip: '30182',
    city: 'Waco',
    state: 'GA',
    latitude: '33.642788',
    longitude: '-85.234357',
    timeZoneId: 'America/New_York',
  },
  '30183': {
    zip: '30183',
    city: 'Waleska',
    state: 'GA',
    latitude: '34.33514',
    longitude: '-84.590564',
    timeZoneId: 'America/New_York',
  },
  '30184': {
    zip: '30184',
    city: 'White',
    state: 'GA',
    latitude: '34.268651',
    longitude: '-84.714438',
    timeZoneId: 'America/New_York',
  },
  '30185': {
    zip: '30185',
    city: 'Whitesburg',
    state: 'GA',
    latitude: '33.516993',
    longitude: '-84.919009',
    timeZoneId: 'America/New_York',
  },
  '30187': {
    zip: '30187',
    city: 'Winston',
    state: 'GA',
    latitude: '33.670933',
    longitude: '-84.838664',
    timeZoneId: 'America/New_York',
  },
  '30188': {
    zip: '30188',
    city: 'Woodstock',
    state: 'GA',
    latitude: '34.115005',
    longitude: '-84.455112',
    timeZoneId: 'America/New_York',
  },
  '30189': {
    zip: '30189',
    city: 'Woodstock',
    state: 'GA',
    latitude: '34.126928',
    longitude: '-84.563188',
    timeZoneId: 'America/New_York',
  },
  '30204': {
    zip: '30204',
    city: 'Barnesville',
    state: 'GA',
    latitude: '33.055571',
    longitude: '-84.156933',
    timeZoneId: 'America/New_York',
  },
  '30205': {
    zip: '30205',
    city: 'Brooks',
    state: 'GA',
    latitude: '33.268083',
    longitude: '-84.464021',
    timeZoneId: 'America/New_York',
  },
  '30206': {
    zip: '30206',
    city: 'Concord',
    state: 'GA',
    latitude: '33.096075',
    longitude: '-84.46479',
    timeZoneId: 'America/New_York',
  },
  '30212': {
    zip: '30212',
    city: 'Experiment',
    state: 'GA',
    latitude: '33.267698',
    longitude: '-84.282751',
    timeZoneId: 'America/New_York',
  },
  '30213': {
    zip: '30213',
    city: 'Fairburn',
    state: 'GA',
    latitude: '33.577678',
    longitude: '-84.594233',
    timeZoneId: 'America/New_York',
  },
  '30214': {
    zip: '30214',
    city: 'Fayetteville',
    state: 'GA',
    latitude: '33.440281',
    longitude: '-84.462654',
    timeZoneId: 'America/New_York',
  },
  '30215': {
    zip: '30215',
    city: 'Fayetteville',
    state: 'GA',
    latitude: '33.383599',
    longitude: '-84.445716',
    timeZoneId: 'America/New_York',
  },
  '30216': {
    zip: '30216',
    city: 'Flovilla',
    state: 'GA',
    latitude: '33.24423',
    longitude: '-83.89609',
    timeZoneId: 'America/New_York',
  },
  '30217': {
    zip: '30217',
    city: 'Franklin',
    state: 'GA',
    latitude: '33.308377',
    longitude: '-85.109975',
    timeZoneId: 'America/New_York',
  },
  '30218': {
    zip: '30218',
    city: 'Gay',
    state: 'GA',
    latitude: '33.104107',
    longitude: '-84.631461',
    timeZoneId: 'America/New_York',
  },
  '30220': {
    zip: '30220',
    city: 'Grantville',
    state: 'GA',
    latitude: '33.226075',
    longitude: '-84.819456',
    timeZoneId: 'America/New_York',
  },
  '30222': {
    zip: '30222',
    city: 'Greenville',
    state: 'GA',
    latitude: '33.025339',
    longitude: '-84.720335',
    timeZoneId: 'America/New_York',
  },
  '30223': {
    zip: '30223',
    city: 'Griffin',
    state: 'GA',
    latitude: '33.292686',
    longitude: '-84.261559',
    timeZoneId: 'America/New_York',
  },
  '30224': {
    zip: '30224',
    city: 'Griffin',
    state: 'GA',
    latitude: '33.207433',
    longitude: '-84.236153',
    timeZoneId: 'America/New_York',
  },
  '30228': {
    zip: '30228',
    city: 'Hampton',
    state: 'GA',
    latitude: '33.404127',
    longitude: '-84.282',
    timeZoneId: 'America/New_York',
  },
  '30229': {
    zip: '30229',
    city: 'Haralson',
    state: 'GA',
    latitude: '33.230541',
    longitude: '-84.569939',
    timeZoneId: 'America/New_York',
  },
  '30230': {
    zip: '30230',
    city: 'Hogansville',
    state: 'GA',
    latitude: '33.166257',
    longitude: '-84.943787',
    timeZoneId: 'America/New_York',
  },
  '30233': {
    zip: '30233',
    city: 'Jackson',
    state: 'GA',
    latitude: '33.294108',
    longitude: '-83.965048',
    timeZoneId: 'America/New_York',
  },
  '30234': {
    zip: '30234',
    city: 'Jenkinsburg',
    state: 'GA',
    latitude: '33.320972',
    longitude: '-84.033019',
    timeZoneId: 'America/New_York',
  },
  '30236': {
    zip: '30236',
    city: 'Jonesboro',
    state: 'GA',
    latitude: '33.511823',
    longitude: '-84.316803',
    timeZoneId: 'America/New_York',
  },
  '30237': {
    zip: '30237',
    city: 'Jonesboro',
    state: 'GA',
    latitude: '33.522896',
    longitude: '-84.353002',
    timeZoneId: 'America/New_York',
  },
  '30238': {
    zip: '30238',
    city: 'Jonesboro',
    state: 'GA',
    latitude: '33.493166',
    longitude: '-84.384485',
    timeZoneId: 'America/New_York',
  },
  '30240': {
    zip: '30240',
    city: 'Lagrange',
    state: 'GA',
    latitude: '33.030882',
    longitude: '-85.03697',
    timeZoneId: 'America/New_York',
  },
  '30241': {
    zip: '30241',
    city: 'Lagrange',
    state: 'GA',
    latitude: '33.099497',
    longitude: '-85.024744',
    timeZoneId: 'America/New_York',
  },
  '30248': {
    zip: '30248',
    city: 'Locust Grove',
    state: 'GA',
    latitude: '33.359583',
    longitude: '-84.10537',
    timeZoneId: 'America/New_York',
  },
  '30250': {
    zip: '30250',
    city: 'Lovejoy',
    state: 'GA',
    latitude: '33.441429',
    longitude: '-84.317728',
    timeZoneId: 'America/New_York',
  },
  '30251': {
    zip: '30251',
    city: 'Luthersville',
    state: 'GA',
    latitude: '33.209532',
    longitude: '-84.74484',
    timeZoneId: 'America/New_York',
  },
  '30252': {
    zip: '30252',
    city: 'McDonough',
    state: 'GA',
    latitude: '33.469647',
    longitude: '-84.049084',
    timeZoneId: 'America/New_York',
  },
  '30253': {
    zip: '30253',
    city: 'McDonough',
    state: 'GA',
    latitude: '33.443757',
    longitude: '-84.181882',
    timeZoneId: 'America/New_York',
  },
  '30256': {
    zip: '30256',
    city: 'Meansville',
    state: 'GA',
    latitude: '32.981277',
    longitude: '-84.328511',
    timeZoneId: 'America/New_York',
  },
  '30257': {
    zip: '30257',
    city: 'Milner',
    state: 'GA',
    latitude: '33.128913',
    longitude: '-84.198621',
    timeZoneId: 'America/New_York',
  },
  '30258': {
    zip: '30258',
    city: 'Molena',
    state: 'GA',
    latitude: '32.972429',
    longitude: '-84.454609',
    timeZoneId: 'America/New_York',
  },
  '30259': {
    zip: '30259',
    city: 'Moreland',
    state: 'GA',
    latitude: '33.275376',
    longitude: '-84.749624',
    timeZoneId: 'America/New_York',
  },
  '30260': {
    zip: '30260',
    city: 'Morrow',
    state: 'GA',
    latitude: '33.583733',
    longitude: '-84.328154',
    timeZoneId: 'America/New_York',
  },
  '30261': {
    zip: '30261',
    city: 'Lagrange',
    state: 'GA',
    latitude: '33.061746',
    longitude: '-84.890953',
    timeZoneId: 'America/New_York',
  },
  '30263': {
    zip: '30263',
    city: 'Newnan',
    state: 'GA',
    latitude: '33.393182',
    longitude: '-84.823447',
    timeZoneId: 'America/New_York',
  },
  '30264': {
    zip: '30264',
    city: 'Newnan',
    state: 'GA',
    latitude: '33.371858',
    longitude: '-84.811498',
    timeZoneId: 'America/New_York',
  },
  '30265': {
    zip: '30265',
    city: 'Newnan',
    state: 'GA',
    latitude: '33.405124',
    longitude: '-84.710418',
    timeZoneId: 'America/New_York',
  },
  '30266': {
    zip: '30266',
    city: 'Orchard Hill',
    state: 'GA',
    latitude: '33.174195',
    longitude: '-84.215161',
    timeZoneId: 'America/New_York',
  },
  '30268': {
    zip: '30268',
    city: 'Palmetto',
    state: 'GA',
    latitude: '33.53345',
    longitude: '-84.690866',
    timeZoneId: 'America/New_York',
  },
  '30269': {
    zip: '30269',
    city: 'Peachtree City',
    state: 'GA',
    latitude: '33.393706',
    longitude: '-84.564697',
    timeZoneId: 'America/New_York',
  },
  '30270': {
    zip: '30270',
    city: 'Peachtree City',
    state: 'GA',
    latitude: '33.396971',
    longitude: '-84.592052',
    timeZoneId: 'America/New_York',
  },
  '30271': {
    zip: '30271',
    city: 'Newnan',
    state: 'GA',
    latitude: '33.377625',
    longitude: '-84.787631',
    timeZoneId: 'America/New_York',
  },
  '30272': {
    zip: '30272',
    city: 'Red Oak',
    state: 'GA',
    latitude: '33.610004',
    longitude: '-84.512219',
    timeZoneId: 'America/New_York',
  },
  '30273': {
    zip: '30273',
    city: 'Rex',
    state: 'GA',
    latitude: '33.577477',
    longitude: '-84.276017',
    timeZoneId: 'America/New_York',
  },
  '30274': {
    zip: '30274',
    city: 'Riverdale',
    state: 'GA',
    latitude: '33.554773',
    longitude: '-84.399793',
    timeZoneId: 'America/New_York',
  },
  '30275': {
    zip: '30275',
    city: 'Sargent',
    state: 'GA',
    latitude: '33.429119',
    longitude: '-84.855226',
    timeZoneId: 'America/New_York',
  },
  '30276': {
    zip: '30276',
    city: 'Senoia',
    state: 'GA',
    latitude: '33.286584',
    longitude: '-84.590087',
    timeZoneId: 'America/New_York',
  },
  '30277': {
    zip: '30277',
    city: 'Sharpsburg',
    state: 'GA',
    latitude: '33.38341',
    longitude: '-84.635224',
    timeZoneId: 'America/New_York',
  },
  '30281': {
    zip: '30281',
    city: 'Stockbridge',
    state: 'GA',
    latitude: '33.553217',
    longitude: '-84.213596',
    timeZoneId: 'America/New_York',
  },
  '30284': {
    zip: '30284',
    city: 'Sunny Side',
    state: 'GA',
    latitude: '33.339156',
    longitude: '-84.291395',
    timeZoneId: 'America/New_York',
  },
  '30285': {
    zip: '30285',
    city: 'The Rock',
    state: 'GA',
    latitude: '32.969762',
    longitude: '-84.247908',
    timeZoneId: 'America/New_York',
  },
  '30286': {
    zip: '30286',
    city: 'Thomaston',
    state: 'GA',
    latitude: '32.905189',
    longitude: '-84.334376',
    timeZoneId: 'America/New_York',
  },
  '30287': {
    zip: '30287',
    city: 'Morrow',
    state: 'GA',
    latitude: '33.549788',
    longitude: '-84.403101',
    timeZoneId: 'America/New_York',
  },
  '30288': {
    zip: '30288',
    city: 'Conley',
    state: 'GA',
    latitude: '33.65549',
    longitude: '-84.331675',
    timeZoneId: 'America/New_York',
  },
  '30289': {
    zip: '30289',
    city: 'Turin',
    state: 'GA',
    latitude: '33.325102',
    longitude: '-84.63505',
    timeZoneId: 'America/New_York',
  },
  '30290': {
    zip: '30290',
    city: 'Tyrone',
    state: 'GA',
    latitude: '33.474391',
    longitude: '-84.58888',
    timeZoneId: 'America/New_York',
  },
  '30291': {
    zip: '30291',
    city: 'Union City',
    state: 'GA',
    latitude: '33.583587',
    longitude: '-84.547325',
    timeZoneId: 'America/New_York',
  },
  '30292': {
    zip: '30292',
    city: 'Williamson',
    state: 'GA',
    latitude: '33.206861',
    longitude: '-84.359144',
    timeZoneId: 'America/New_York',
  },
  '30293': {
    zip: '30293',
    city: 'Woodbury',
    state: 'GA',
    latitude: '32.974674',
    longitude: '-84.588014',
    timeZoneId: 'America/New_York',
  },
  '30294': {
    zip: '30294',
    city: 'Ellenwood',
    state: 'GA',
    latitude: '33.640692',
    longitude: '-84.256263',
    timeZoneId: 'America/New_York',
  },
  '30295': {
    zip: '30295',
    city: 'Zebulon',
    state: 'GA',
    latitude: '33.098626',
    longitude: '-84.30073',
    timeZoneId: 'America/New_York',
  },
  '30296': {
    zip: '30296',
    city: 'Riverdale',
    state: 'GA',
    latitude: '33.568229',
    longitude: '-84.432301',
    timeZoneId: 'America/New_York',
  },
  '30297': {
    zip: '30297',
    city: 'Forest Park',
    state: 'GA',
    latitude: '33.615198',
    longitude: '-84.367485',
    timeZoneId: 'America/New_York',
  },
  '30298': {
    zip: '30298',
    city: 'Forest Park',
    state: 'GA',
    latitude: '33.620191',
    longitude: '-84.355795',
    timeZoneId: 'America/New_York',
  },
  '30301': {
    zip: '30301',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.855904',
    longitude: '-84.395303',
    timeZoneId: 'America/New_York',
  },
  '30302': {
    zip: '30302',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.755454',
    longitude: '-84.387605',
    timeZoneId: 'America/New_York',
  },
  '30303': {
    zip: '30303',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.750591',
    longitude: '-84.387699',
    timeZoneId: 'America/New_York',
  },
  '30304': {
    zip: '30304',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.651549',
    longitude: '-84.394247',
    timeZoneId: 'America/New_York',
  },
  '30305': {
    zip: '30305',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.831382',
    longitude: '-84.385066',
    timeZoneId: 'America/New_York',
  },
  '30306': {
    zip: '30306',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.786536',
    longitude: '-84.349237',
    timeZoneId: 'America/New_York',
  },
  '30307': {
    zip: '30307',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.769788',
    longitude: '-84.328046',
    timeZoneId: 'America/New_York',
  },
  '30308': {
    zip: '30308',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.769731',
    longitude: '-84.377217',
    timeZoneId: 'America/New_York',
  },
  '30309': {
    zip: '30309',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.788039',
    longitude: '-84.385689',
    timeZoneId: 'America/New_York',
  },
  '30310': {
    zip: '30310',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.725127',
    longitude: '-84.422754',
    timeZoneId: 'America/New_York',
  },
  '30311': {
    zip: '30311',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.722171',
    longitude: '-84.473898',
    timeZoneId: 'America/New_York',
  },
  '30312': {
    zip: '30312',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.743314',
    longitude: '-84.378489',
    timeZoneId: 'America/New_York',
  },
  '30313': {
    zip: '30313',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.764232',
    longitude: '-84.395287',
    timeZoneId: 'America/New_York',
  },
  '30314': {
    zip: '30314',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.755847',
    longitude: '-84.427174',
    timeZoneId: 'America/New_York',
  },
  '30315': {
    zip: '30315',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.708343',
    longitude: '-84.38409',
    timeZoneId: 'America/New_York',
  },
  '30316': {
    zip: '30316',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.718321',
    longitude: '-84.32619',
    timeZoneId: 'America/New_York',
  },
  '30317': {
    zip: '30317',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.749993',
    longitude: '-84.314022',
    timeZoneId: 'America/New_York',
  },
  '30318': {
    zip: '30318',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.78768',
    longitude: '-84.443612',
    timeZoneId: 'America/New_York',
  },
  '30319': {
    zip: '30319',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.87546',
    longitude: '-84.333398',
    timeZoneId: 'America/New_York',
  },
  '30320': {
    zip: '30320',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.635644',
    longitude: '-84.433286',
    timeZoneId: 'America/New_York',
  },
  '30321': {
    zip: '30321',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.756316',
    longitude: '-84.389551',
    timeZoneId: 'America/New_York',
  },
  '30322': {
    zip: '30322',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.791247',
    longitude: '-84.3249',
    timeZoneId: 'America/New_York',
  },
  '30324': {
    zip: '30324',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.82034',
    longitude: '-84.362232',
    timeZoneId: 'America/New_York',
  },
  '30325': {
    zip: '30325',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.791989',
    longitude: '-84.447285',
    timeZoneId: 'America/New_York',
  },
  '30326': {
    zip: '30326',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.852137',
    longitude: '-84.360463',
    timeZoneId: 'America/New_York',
  },
  '30327': {
    zip: '30327',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.865889',
    longitude: '-84.425245',
    timeZoneId: 'America/New_York',
  },
  '30328': {
    zip: '30328',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.933854',
    longitude: '-84.386944',
    timeZoneId: 'America/New_York',
  },
  '30329': {
    zip: '30329',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.82516',
    longitude: '-84.3197',
    timeZoneId: 'America/New_York',
  },
  '30331': {
    zip: '30331',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.712646',
    longitude: '-84.532291',
    timeZoneId: 'America/New_York',
  },
  '30332': {
    zip: '30332',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.777293',
    longitude: '-84.396568',
    timeZoneId: 'America/New_York',
  },
  '30333': {
    zip: '30333',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.795743',
    longitude: '-84.323393',
    timeZoneId: 'America/New_York',
  },
  '30334': {
    zip: '30334',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.727528',
    longitude: '-84.412803',
    timeZoneId: 'America/New_York',
  },
  '30336': {
    zip: '30336',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.749608',
    longitude: '-84.551548',
    timeZoneId: 'America/New_York',
  },
  '30337': {
    zip: '30337',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.644256',
    longitude: '-84.467351',
    timeZoneId: 'America/New_York',
  },
  '30338': {
    zip: '30338',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.938634',
    longitude: '-84.316813',
    timeZoneId: 'America/New_York',
  },
  '30339': {
    zip: '30339',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.862244',
    longitude: '-84.470385',
    timeZoneId: 'America/New_York',
  },
  '30340': {
    zip: '30340',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.891239',
    longitude: '-84.250847',
    timeZoneId: 'America/New_York',
  },
  '30341': {
    zip: '30341',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.897852',
    longitude: '-84.306585',
    timeZoneId: 'America/New_York',
  },
  '30342': {
    zip: '30342',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.87789',
    longitude: '-84.370698',
    timeZoneId: 'America/New_York',
  },
  '30343': {
    zip: '30343',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.753835',
    longitude: '-84.392167',
    timeZoneId: 'America/New_York',
  },
  '30344': {
    zip: '30344',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.66894',
    longitude: '-84.45828',
    timeZoneId: 'America/New_York',
  },
  '30345': {
    zip: '30345',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.850513',
    longitude: '-84.28642',
    timeZoneId: 'America/New_York',
  },
  '30346': {
    zip: '30346',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.927811',
    longitude: '-84.344192',
    timeZoneId: 'America/New_York',
  },
  '30348': {
    zip: '30348',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.835327',
    longitude: '-84.392667',
    timeZoneId: 'America/New_York',
  },
  '30349': {
    zip: '30349',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.614175',
    longitude: '-84.497109',
    timeZoneId: 'America/New_York',
  },
  '30350': {
    zip: '30350',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.975825',
    longitude: '-84.325274',
    timeZoneId: 'America/New_York',
  },
  '30353': {
    zip: '30353',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.753297',
    longitude: '-84.391406',
    timeZoneId: 'America/New_York',
  },
  '30354': {
    zip: '30354',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.65832',
    longitude: '-84.3846',
    timeZoneId: 'America/New_York',
  },
  '30355': {
    zip: '30355',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.835447',
    longitude: '-84.366399',
    timeZoneId: 'America/New_York',
  },
  '30356': {
    zip: '30356',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.947356',
    longitude: '-84.330786',
    timeZoneId: 'America/New_York',
  },
  '30357': {
    zip: '30357',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.799057',
    longitude: '-84.387174',
    timeZoneId: 'America/New_York',
  },
  '30358': {
    zip: '30358',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.934524',
    longitude: '-84.388655',
    timeZoneId: 'America/New_York',
  },
  '30359': {
    zip: '30359',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.822811',
    longitude: '-84.323174',
    timeZoneId: 'America/New_York',
  },
  '30360': {
    zip: '30360',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.92809',
    longitude: '-84.27853',
    timeZoneId: 'America/New_York',
  },
  '30361': {
    zip: '30361',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.789903',
    longitude: '-84.378744',
    timeZoneId: 'America/New_York',
  },
  '30362': {
    zip: '30362',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.900867',
    longitude: '-84.276425',
    timeZoneId: 'America/New_York',
  },
  '30363': {
    zip: '30363',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.84122',
    longitude: '-84.38563',
    timeZoneId: 'America/New_York',
  },
  '30364': {
    zip: '30364',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.675999',
    longitude: '-84.461995',
    timeZoneId: 'America/New_York',
  },
  '30366': {
    zip: '30366',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.887527',
    longitude: '-84.287641',
    timeZoneId: 'America/New_York',
  },
  '30368': {
    zip: '30368',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.649172',
    longitude: '-84.392574',
    timeZoneId: 'America/New_York',
  },
  '30369': {
    zip: '30369',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.803584',
    longitude: '-84.471339',
    timeZoneId: 'America/New_York',
  },
  '30370': {
    zip: '30370',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.75225',
    longitude: '-84.38899',
    timeZoneId: 'America/New_York',
  },
  '30371': {
    zip: '30371',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.762163',
    longitude: '-84.424695',
    timeZoneId: 'America/New_York',
  },
  '30374': {
    zip: '30374',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.576833',
    longitude: '-84.39474',
    timeZoneId: 'America/New_York',
  },
  '30375': {
    zip: '30375',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.775206',
    longitude: '-84.389428',
    timeZoneId: 'America/New_York',
  },
  '30377': {
    zip: '30377',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.793048',
    longitude: '-84.450115',
    timeZoneId: 'America/New_York',
  },
  '30378': {
    zip: '30378',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.757624',
    longitude: '-84.550681',
    timeZoneId: 'America/New_York',
  },
  '30380': {
    zip: '30380',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.646308',
    longitude: '-84.389514',
    timeZoneId: 'America/New_York',
  },
  '30384': {
    zip: '30384',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.897456',
    longitude: '-84.451548',
    timeZoneId: 'America/New_York',
  },
  '30385': {
    zip: '30385',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.674395',
    longitude: '-84.441613',
    timeZoneId: 'America/New_York',
  },
  '30388': {
    zip: '30388',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.647853',
    longitude: '-84.388398',
    timeZoneId: 'America/New_York',
  },
  '30392': {
    zip: '30392',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.755406',
    longitude: '-84.392641',
    timeZoneId: 'America/New_York',
  },
  '30394': {
    zip: '30394',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.7528',
    longitude: '-84.392638',
    timeZoneId: 'America/New_York',
  },
  '30396': {
    zip: '30396',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.647672',
    longitude: '-84.393744',
    timeZoneId: 'America/New_York',
  },
  '30398': {
    zip: '30398',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.650336',
    longitude: '-84.389036',
    timeZoneId: 'America/New_York',
  },
  '30401': {
    zip: '30401',
    city: 'Swainsboro',
    state: 'GA',
    latitude: '32.597493',
    longitude: '-82.336281',
    timeZoneId: 'America/New_York',
  },
  '30410': {
    zip: '30410',
    city: 'Ailey',
    state: 'GA',
    latitude: '32.2104',
    longitude: '-82.48332',
    timeZoneId: 'America/New_York',
  },
  '30411': {
    zip: '30411',
    city: 'Alamo',
    state: 'GA',
    latitude: '32.153584',
    longitude: '-82.779628',
    timeZoneId: 'America/New_York',
  },
  '30412': {
    zip: '30412',
    city: 'Alston',
    state: 'GA',
    latitude: '32.080636',
    longitude: '-82.479694',
    timeZoneId: 'America/New_York',
  },
  '30413': {
    zip: '30413',
    city: 'Bartow',
    state: 'GA',
    latitude: '32.879456',
    longitude: '-82.49107',
    timeZoneId: 'America/New_York',
  },
  '30414': {
    zip: '30414',
    city: 'Bellville',
    state: 'GA',
    latitude: '32.15096',
    longitude: '-81.973742',
    timeZoneId: 'America/New_York',
  },
  '30415': {
    zip: '30415',
    city: 'Brooklet',
    state: 'GA',
    latitude: '32.371613',
    longitude: '-81.670188',
    timeZoneId: 'America/New_York',
  },
  '30417': {
    zip: '30417',
    city: 'Claxton',
    state: 'GA',
    latitude: '32.161491',
    longitude: '-81.909323',
    timeZoneId: 'America/New_York',
  },
  '30420': {
    zip: '30420',
    city: 'Cobbtown',
    state: 'GA',
    latitude: '32.256436',
    longitude: '-82.124286',
    timeZoneId: 'America/New_York',
  },
  '30421': {
    zip: '30421',
    city: 'Collins',
    state: 'GA',
    latitude: '32.175248',
    longitude: '-82.110804',
    timeZoneId: 'America/New_York',
  },
  '30423': {
    zip: '30423',
    city: 'Daisy',
    state: 'GA',
    latitude: '32.150267',
    longitude: '-81.839238',
    timeZoneId: 'America/New_York',
  },
  '30424': {
    zip: '30424',
    city: 'Dover',
    state: 'GA',
    latitude: '32.576781',
    longitude: '-81.715789',
    timeZoneId: 'America/New_York',
  },
  '30425': {
    zip: '30425',
    city: 'Garfield',
    state: 'GA',
    latitude: '32.599435',
    longitude: '-82.070896',
    timeZoneId: 'America/New_York',
  },
  '30426': {
    zip: '30426',
    city: 'Girard',
    state: 'GA',
    latitude: '33.042559',
    longitude: '-81.995343',
    timeZoneId: 'America/New_York',
  },
  '30427': {
    zip: '30427',
    city: 'Glennville',
    state: 'GA',
    latitude: '31.933927',
    longitude: '-81.925339',
    timeZoneId: 'America/New_York',
  },
  '30428': {
    zip: '30428',
    city: 'Glenwood',
    state: 'GA',
    latitude: '32.111404',
    longitude: '-82.665685',
    timeZoneId: 'America/New_York',
  },
  '30429': {
    zip: '30429',
    city: 'Hagan',
    state: 'GA',
    latitude: '32.152588',
    longitude: '-81.935744',
    timeZoneId: 'America/New_York',
  },
  '30434': {
    zip: '30434',
    city: 'Louisville',
    state: 'GA',
    latitude: '33.000236',
    longitude: '-82.407759',
    timeZoneId: 'America/New_York',
  },
  '30436': {
    zip: '30436',
    city: 'Lyons',
    state: 'GA',
    latitude: '32.201196',
    longitude: '-82.323239',
    timeZoneId: 'America/New_York',
  },
  '30438': {
    zip: '30438',
    city: 'Manassas',
    state: 'GA',
    latitude: '32.088685',
    longitude: '-82.052572',
    timeZoneId: 'America/New_York',
  },
  '30439': {
    zip: '30439',
    city: 'Metter',
    state: 'GA',
    latitude: '32.402027',
    longitude: '-82.061386',
    timeZoneId: 'America/New_York',
  },
  '30441': {
    zip: '30441',
    city: 'Midville',
    state: 'GA',
    latitude: '32.895717',
    longitude: '-82.197463',
    timeZoneId: 'America/New_York',
  },
  '30442': {
    zip: '30442',
    city: 'Millen',
    state: 'GA',
    latitude: '32.80608',
    longitude: '-81.941063',
    timeZoneId: 'America/New_York',
  },
  '30445': {
    zip: '30445',
    city: 'Mount Vernon',
    state: 'GA',
    latitude: '32.179324',
    longitude: '-82.595973',
    timeZoneId: 'America/New_York',
  },
  '30446': {
    zip: '30446',
    city: 'Newington',
    state: 'GA',
    latitude: '32.679721',
    longitude: '-81.578871',
    timeZoneId: 'America/New_York',
  },
  '30447': {
    zip: '30447',
    city: 'Norristown',
    state: 'GA',
    latitude: '32.503946',
    longitude: '-82.494456',
    timeZoneId: 'America/New_York',
  },
  '30448': {
    zip: '30448',
    city: 'Nunez',
    state: 'GA',
    latitude: '32.48947',
    longitude: '-82.349001',
    timeZoneId: 'America/New_York',
  },
  '30449': {
    zip: '30449',
    city: 'Oliver',
    state: 'GA',
    latitude: '32.521207',
    longitude: '-81.531318',
    timeZoneId: 'America/New_York',
  },
  '30450': {
    zip: '30450',
    city: 'Portal',
    state: 'GA',
    latitude: '32.569614',
    longitude: '-81.903024',
    timeZoneId: 'America/New_York',
  },
  '30451': {
    zip: '30451',
    city: 'Pulaski',
    state: 'GA',
    latitude: '32.391427',
    longitude: '-81.959069',
    timeZoneId: 'America/New_York',
  },
  '30452': {
    zip: '30452',
    city: 'Register',
    state: 'GA',
    latitude: '32.319837',
    longitude: '-81.87705',
    timeZoneId: 'America/New_York',
  },
  '30453': {
    zip: '30453',
    city: 'Reidsville',
    state: 'GA',
    latitude: '32.081383',
    longitude: '-82.118083',
    timeZoneId: 'America/New_York',
  },
  '30454': {
    zip: '30454',
    city: 'Rockledge',
    state: 'GA',
    latitude: '32.365859',
    longitude: '-82.744912',
    timeZoneId: 'America/New_York',
  },
  '30455': {
    zip: '30455',
    city: 'Rocky Ford',
    state: 'GA',
    latitude: '32.658804',
    longitude: '-81.744488',
    timeZoneId: 'America/New_York',
  },
  '30456': {
    zip: '30456',
    city: 'Sardis',
    state: 'GA',
    latitude: '32.976002',
    longitude: '-81.759411',
    timeZoneId: 'America/New_York',
  },
  '30457': {
    zip: '30457',
    city: 'Soperton',
    state: 'GA',
    latitude: '32.375542',
    longitude: '-82.593391',
    timeZoneId: 'America/New_York',
  },
  '30458': {
    zip: '30458',
    city: 'Statesboro',
    state: 'GA',
    latitude: '32.44509',
    longitude: '-81.780358',
    timeZoneId: 'America/New_York',
  },
  '30459': {
    zip: '30459',
    city: 'Statesboro',
    state: 'GA',
    latitude: '32.447552',
    longitude: '-81.781293',
    timeZoneId: 'America/New_York',
  },
  '30460': {
    zip: '30460',
    city: 'Statesboro',
    state: 'GA',
    latitude: '32.444047',
    longitude: '-81.745328',
    timeZoneId: 'America/New_York',
  },
  '30461': {
    zip: '30461',
    city: 'Statesboro',
    state: 'GA',
    latitude: '32.515293',
    longitude: '-81.708465',
    timeZoneId: 'America/New_York',
  },
  '30464': {
    zip: '30464',
    city: 'Stillmore',
    state: 'GA',
    latitude: '32.455746',
    longitude: '-82.223616',
    timeZoneId: 'America/New_York',
  },
  '30467': {
    zip: '30467',
    city: 'Sylvania',
    state: 'GA',
    latitude: '32.74847',
    longitude: '-81.638522',
    timeZoneId: 'America/New_York',
  },
  '30470': {
    zip: '30470',
    city: 'Tarrytown',
    state: 'GA',
    latitude: '32.290664',
    longitude: '-82.525613',
    timeZoneId: 'America/New_York',
  },
  '30471': {
    zip: '30471',
    city: 'Twin City',
    state: 'GA',
    latitude: '32.652254',
    longitude: '-82.155942',
    timeZoneId: 'America/New_York',
  },
  '30473': {
    zip: '30473',
    city: 'Uvalda',
    state: 'GA',
    latitude: '32.037807',
    longitude: '-82.514539',
    timeZoneId: 'America/New_York',
  },
  '30474': {
    zip: '30474',
    city: 'Vidalia',
    state: 'GA',
    latitude: '32.210424',
    longitude: '-82.411532',
    timeZoneId: 'America/New_York',
  },
  '30475': {
    zip: '30475',
    city: 'Vidalia',
    state: 'GA',
    latitude: '32.211075',
    longitude: '-82.403886',
    timeZoneId: 'America/New_York',
  },
  '30477': {
    zip: '30477',
    city: 'Wadley',
    state: 'GA',
    latitude: '32.862048',
    longitude: '-82.407315',
    timeZoneId: 'America/New_York',
  },
  '30499': {
    zip: '30499',
    city: 'Reidsville',
    state: 'GA',
    latitude: '32.083302',
    longitude: '-82.119056',
    timeZoneId: 'America/New_York',
  },
  '30501': {
    zip: '30501',
    city: 'Gainesville',
    state: 'GA',
    latitude: '34.286057',
    longitude: '-83.820309',
    timeZoneId: 'America/New_York',
  },
  '30502': {
    zip: '30502',
    city: 'Chestnut Mountain',
    state: 'GA',
    latitude: '34.176858',
    longitude: '-83.90784',
    timeZoneId: 'America/New_York',
  },
  '30503': {
    zip: '30503',
    city: 'Gainesville',
    state: 'GA',
    latitude: '34.371277',
    longitude: '-83.822547',
    timeZoneId: 'America/New_York',
  },
  '30504': {
    zip: '30504',
    city: 'Gainesville',
    state: 'GA',
    latitude: '34.262306',
    longitude: '-83.890081',
    timeZoneId: 'America/New_York',
  },
  '30506': {
    zip: '30506',
    city: 'Gainesville',
    state: 'GA',
    latitude: '34.371032',
    longitude: '-83.865332',
    timeZoneId: 'America/New_York',
  },
  '30507': {
    zip: '30507',
    city: 'Gainesville',
    state: 'GA',
    latitude: '34.254602',
    longitude: '-83.775548',
    timeZoneId: 'America/New_York',
  },
  '30510': {
    zip: '30510',
    city: 'Alto',
    state: 'GA',
    latitude: '34.430265',
    longitude: '-83.636673',
    timeZoneId: 'America/New_York',
  },
  '30511': {
    zip: '30511',
    city: 'Baldwin',
    state: 'GA',
    latitude: '34.522844',
    longitude: '-83.532714',
    timeZoneId: 'America/New_York',
  },
  '30512': {
    zip: '30512',
    city: 'Blairsville',
    state: 'GA',
    latitude: '34.877672',
    longitude: '-84.022139',
    timeZoneId: 'America/New_York',
  },
  '30513': {
    zip: '30513',
    city: 'Blue Ridge',
    state: 'GA',
    latitude: '34.843508',
    longitude: '-84.336171',
    timeZoneId: 'America/New_York',
  },
  '30514': {
    zip: '30514',
    city: 'Blairsville',
    state: 'GA',
    latitude: '34.876134',
    longitude: '-83.952809',
    timeZoneId: 'America/New_York',
  },
  '30515': {
    zip: '30515',
    city: 'Buford',
    state: 'GA',
    latitude: '34.130974',
    longitude: '-84.026588',
    timeZoneId: 'America/New_York',
  },
  '30516': {
    zip: '30516',
    city: 'Bowersville',
    state: 'GA',
    latitude: '34.398615',
    longitude: '-83.048142',
    timeZoneId: 'America/New_York',
  },
  '30517': {
    zip: '30517',
    city: 'Braselton',
    state: 'GA',
    latitude: '34.132726',
    longitude: '-83.758475',
    timeZoneId: 'America/New_York',
  },
  '30518': {
    zip: '30518',
    city: 'Buford',
    state: 'GA',
    latitude: '34.124264',
    longitude: '-84.031055',
    timeZoneId: 'America/New_York',
  },
  '30519': {
    zip: '30519',
    city: 'Buford',
    state: 'GA',
    latitude: '34.103295',
    longitude: '-83.973195',
    timeZoneId: 'America/New_York',
  },
  '30520': {
    zip: '30520',
    city: 'Canon',
    state: 'GA',
    latitude: '34.285002',
    longitude: '-83.105264',
    timeZoneId: 'America/New_York',
  },
  '30521': {
    zip: '30521',
    city: 'Carnesville',
    state: 'GA',
    latitude: '34.383201',
    longitude: '-83.286493',
    timeZoneId: 'America/New_York',
  },
  '30522': {
    zip: '30522',
    city: 'Cherry Log',
    state: 'GA',
    latitude: '34.612755',
    longitude: '-84.531737',
    timeZoneId: 'America/New_York',
  },
  '30523': {
    zip: '30523',
    city: 'Clarkesville',
    state: 'GA',
    latitude: '34.613734',
    longitude: '-83.520947',
    timeZoneId: 'America/New_York',
  },
  '30525': {
    zip: '30525',
    city: 'Clayton',
    state: 'GA',
    latitude: '34.863738',
    longitude: '-83.398704',
    timeZoneId: 'America/New_York',
  },
  '30527': {
    zip: '30527',
    city: 'Clermont',
    state: 'GA',
    latitude: '34.475011',
    longitude: '-83.789346',
    timeZoneId: 'America/New_York',
  },
  '30528': {
    zip: '30528',
    city: 'Cleveland',
    state: 'GA',
    latitude: '34.626813',
    longitude: '-83.804069',
    timeZoneId: 'America/New_York',
  },
  '30529': {
    zip: '30529',
    city: 'Commerce',
    state: 'GA',
    latitude: '34.187974',
    longitude: '-83.514373',
    timeZoneId: 'America/New_York',
  },
  '30530': {
    zip: '30530',
    city: 'Commerce',
    state: 'GA',
    latitude: '34.207252',
    longitude: '-83.412131',
    timeZoneId: 'America/New_York',
  },
  '30531': {
    zip: '30531',
    city: 'Cornelia',
    state: 'GA',
    latitude: '34.514324',
    longitude: '-83.5248',
    timeZoneId: 'America/New_York',
  },
  '30533': {
    zip: '30533',
    city: 'Dahlonega',
    state: 'GA',
    latitude: '34.529731',
    longitude: '-83.984892',
    timeZoneId: 'America/New_York',
  },
  '30534': {
    zip: '30534',
    city: 'Dawsonville',
    state: 'GA',
    latitude: '34.388619',
    longitude: '-84.096464',
    timeZoneId: 'America/New_York',
  },
  '30535': {
    zip: '30535',
    city: 'Demorest',
    state: 'GA',
    latitude: '34.589561',
    longitude: '-83.578838',
    timeZoneId: 'America/New_York',
  },
  '30536': {
    zip: '30536',
    city: 'Ellijay',
    state: 'GA',
    latitude: '34.694722',
    longitude: '-84.482222',
    timeZoneId: 'America/New_York',
  },
  '30537': {
    zip: '30537',
    city: 'Dillard',
    state: 'GA',
    latitude: '34.98108',
    longitude: '-83.38158',
    timeZoneId: 'America/New_York',
  },
  '30538': {
    zip: '30538',
    city: 'Eastanollee',
    state: 'GA',
    latitude: '34.498181',
    longitude: '-83.256207',
    timeZoneId: 'America/New_York',
  },
  '30539': {
    zip: '30539',
    city: 'East Ellijay',
    state: 'GA',
    latitude: '34.68635',
    longitude: '-84.474574',
    timeZoneId: 'America/New_York',
  },
  '30540': {
    zip: '30540',
    city: 'Ellijay',
    state: 'GA',
    latitude: '34.685591',
    longitude: '-84.485193',
    timeZoneId: 'America/New_York',
  },
  '30541': {
    zip: '30541',
    city: 'Epworth',
    state: 'GA',
    latitude: '34.904691',
    longitude: '-84.495403',
    timeZoneId: 'America/New_York',
  },
  '30542': {
    zip: '30542',
    city: 'Flowery Branch',
    state: 'GA',
    latitude: '34.188876',
    longitude: '-83.905713',
    timeZoneId: 'America/New_York',
  },
  '30543': {
    zip: '30543',
    city: 'Gillsville',
    state: 'GA',
    latitude: '34.292108',
    longitude: '-83.674281',
    timeZoneId: 'America/New_York',
  },
  '30545': {
    zip: '30545',
    city: 'Helen',
    state: 'GA',
    latitude: '34.7023',
    longitude: '-83.726338',
    timeZoneId: 'America/New_York',
  },
  '30546': {
    zip: '30546',
    city: 'Hiawassee',
    state: 'GA',
    latitude: '34.910001',
    longitude: '-83.712789',
    timeZoneId: 'America/New_York',
  },
  '30547': {
    zip: '30547',
    city: 'Homer',
    state: 'GA',
    latitude: '34.358958',
    longitude: '-83.486868',
    timeZoneId: 'America/New_York',
  },
  '30548': {
    zip: '30548',
    city: 'Hoschton',
    state: 'GA',
    latitude: '34.068356',
    longitude: '-83.87087',
    timeZoneId: 'America/New_York',
  },
  '30549': {
    zip: '30549',
    city: 'Jefferson',
    state: 'GA',
    latitude: '34.112889',
    longitude: '-83.58',
    timeZoneId: 'America/New_York',
  },
  '30552': {
    zip: '30552',
    city: 'Lakemont',
    state: 'GA',
    latitude: '34.752997',
    longitude: '-83.395937',
    timeZoneId: 'America/New_York',
  },
  '30553': {
    zip: '30553',
    city: 'Lavonia',
    state: 'GA',
    latitude: '34.436422',
    longitude: '-83.105732',
    timeZoneId: 'America/New_York',
  },
  '30554': {
    zip: '30554',
    city: 'Lula',
    state: 'GA',
    latitude: '34.393395',
    longitude: '-83.684802',
    timeZoneId: 'America/New_York',
  },
  '30555': {
    zip: '30555',
    city: 'Mc Caysville',
    state: 'GA',
    latitude: '34.978674',
    longitude: '-84.372632',
    timeZoneId: 'America/New_York',
  },
  '30557': {
    zip: '30557',
    city: 'Martin',
    state: 'GA',
    latitude: '34.530869',
    longitude: '-83.144053',
    timeZoneId: 'America/New_York',
  },
  '30558': {
    zip: '30558',
    city: 'Maysville',
    state: 'GA',
    latitude: '34.256794',
    longitude: '-83.557304',
    timeZoneId: 'America/New_York',
  },
  '30559': {
    zip: '30559',
    city: 'Mineral Bluff',
    state: 'GA',
    latitude: '34.940401',
    longitude: '-84.267308',
    timeZoneId: 'America/New_York',
  },
  '30560': {
    zip: '30560',
    city: 'Morganton',
    state: 'GA',
    latitude: '34.871631',
    longitude: '-84.243732',
    timeZoneId: 'America/New_York',
  },
  '30562': {
    zip: '30562',
    city: 'Mountain City',
    state: 'GA',
    latitude: '34.916566',
    longitude: '-83.384658',
    timeZoneId: 'America/New_York',
  },
  '30563': {
    zip: '30563',
    city: 'Mount Airy',
    state: 'GA',
    latitude: '34.556772',
    longitude: '-83.466688',
    timeZoneId: 'America/New_York',
  },
  '30564': {
    zip: '30564',
    city: 'Murrayville',
    state: 'GA',
    latitude: '34.428732',
    longitude: '-83.91074',
    timeZoneId: 'America/New_York',
  },
  '30565': {
    zip: '30565',
    city: 'Nicholson',
    state: 'GA',
    latitude: '34.074688',
    longitude: '-83.417461',
    timeZoneId: 'America/New_York',
  },
  '30566': {
    zip: '30566',
    city: 'Oakwood',
    state: 'GA',
    latitude: '34.236484',
    longitude: '-83.89479',
    timeZoneId: 'America/New_York',
  },
  '30567': {
    zip: '30567',
    city: 'Pendergrass',
    state: 'GA',
    latitude: '34.207969',
    longitude: '-83.746626',
    timeZoneId: 'America/New_York',
  },
  '30568': {
    zip: '30568',
    city: 'Rabun Gap',
    state: 'GA',
    latitude: '34.957065',
    longitude: '-83.394861',
    timeZoneId: 'America/New_York',
  },
  '30571': {
    zip: '30571',
    city: 'Sautee Nacoochee',
    state: 'GA',
    latitude: '34.743332',
    longitude: '-83.716475',
    timeZoneId: 'America/New_York',
  },
  '30572': {
    zip: '30572',
    city: 'Suches',
    state: 'GA',
    latitude: '34.730746',
    longitude: '-84.069126',
    timeZoneId: 'America/New_York',
  },
  '30573': {
    zip: '30573',
    city: 'Tallulah Falls',
    state: 'GA',
    latitude: '34.726029',
    longitude: '-83.382319',
    timeZoneId: 'America/New_York',
  },
  '30575': {
    zip: '30575',
    city: 'Talmo',
    state: 'GA',
    latitude: '34.220844',
    longitude: '-83.731019',
    timeZoneId: 'America/New_York',
  },
  '30576': {
    zip: '30576',
    city: 'Tiger',
    state: 'GA',
    latitude: '34.801334',
    longitude: '-83.450139',
    timeZoneId: 'America/New_York',
  },
  '30577': {
    zip: '30577',
    city: 'Toccoa',
    state: 'GA',
    latitude: '34.573035',
    longitude: '-83.318464',
    timeZoneId: 'America/New_York',
  },
  '30580': {
    zip: '30580',
    city: 'Turnerville',
    state: 'GA',
    latitude: '34.68976',
    longitude: '-83.526227',
    timeZoneId: 'America/New_York',
  },
  '30581': {
    zip: '30581',
    city: 'Wiley',
    state: 'GA',
    latitude: '34.800137',
    longitude: '-83.44787',
    timeZoneId: 'America/New_York',
  },
  '30582': {
    zip: '30582',
    city: 'Young Harris',
    state: 'GA',
    latitude: '34.910667',
    longitude: '-83.850085',
    timeZoneId: 'America/New_York',
  },
  '30597': {
    zip: '30597',
    city: 'Dahlonega',
    state: 'GA',
    latitude: '34.528036',
    longitude: '-83.987193',
    timeZoneId: 'America/New_York',
  },
  '30598': {
    zip: '30598',
    city: 'Toccoa Falls',
    state: 'GA',
    latitude: '34.591475',
    longitude: '-83.35649',
    timeZoneId: 'America/New_York',
  },
  '30599': {
    zip: '30599',
    city: 'Commerce',
    state: 'GA',
    latitude: '34.2052',
    longitude: '-83.46061',
    timeZoneId: 'America/New_York',
  },
  '30601': {
    zip: '30601',
    city: 'Athens',
    state: 'GA',
    latitude: '33.974834',
    longitude: '-83.363891',
    timeZoneId: 'America/New_York',
  },
  '30602': {
    zip: '30602',
    city: 'Athens',
    state: 'GA',
    latitude: '33.903503',
    longitude: '-83.318464',
    timeZoneId: 'America/New_York',
  },
  '30603': {
    zip: '30603',
    city: 'Athens',
    state: 'GA',
    latitude: '33.959292',
    longitude: '-83.376888',
    timeZoneId: 'America/New_York',
  },
  '30604': {
    zip: '30604',
    city: 'Athens',
    state: 'GA',
    latitude: '34.006838',
    longitude: '-83.493235',
    timeZoneId: 'America/New_York',
  },
  '30605': {
    zip: '30605',
    city: 'Athens',
    state: 'GA',
    latitude: '33.926072',
    longitude: '-83.340748',
    timeZoneId: 'America/New_York',
  },
  '30606': {
    zip: '30606',
    city: 'Athens',
    state: 'GA',
    latitude: '33.948996',
    longitude: '-83.423229',
    timeZoneId: 'America/New_York',
  },
  '30607': {
    zip: '30607',
    city: 'Athens',
    state: 'GA',
    latitude: '33.992365',
    longitude: '-83.416707',
    timeZoneId: 'America/New_York',
  },
  '30608': {
    zip: '30608',
    city: 'Athens',
    state: 'GA',
    latitude: '33.959618',
    longitude: '-83.376988',
    timeZoneId: 'America/New_York',
  },
  '30609': {
    zip: '30609',
    city: 'Athens',
    state: 'GA',
    latitude: '33.93678',
    longitude: '-83.43157',
    timeZoneId: 'America/New_York',
  },
  '30612': {
    zip: '30612',
    city: 'Athens',
    state: 'GA',
    latitude: '33.955626',
    longitude: '-83.393098',
    timeZoneId: 'America/New_York',
  },
  '30619': {
    zip: '30619',
    city: 'Arnoldsville',
    state: 'GA',
    latitude: '33.849559',
    longitude: '-83.284161',
    timeZoneId: 'America/New_York',
  },
  '30620': {
    zip: '30620',
    city: 'Bethlehem',
    state: 'GA',
    latitude: '33.924569',
    longitude: '-83.747066',
    timeZoneId: 'America/New_York',
  },
  '30621': {
    zip: '30621',
    city: 'Bishop',
    state: 'GA',
    latitude: '33.833825',
    longitude: '-83.471768',
    timeZoneId: 'America/New_York',
  },
  '30622': {
    zip: '30622',
    city: 'Bogart',
    state: 'GA',
    latitude: '33.921521',
    longitude: '-83.527179',
    timeZoneId: 'America/New_York',
  },
  '30623': {
    zip: '30623',
    city: 'Bostwick',
    state: 'GA',
    latitude: '33.735548',
    longitude: '-83.522298',
    timeZoneId: 'America/New_York',
  },
  '30624': {
    zip: '30624',
    city: 'Bowman',
    state: 'GA',
    latitude: '34.094349',
    longitude: '-82.818421',
    timeZoneId: 'America/New_York',
  },
  '30625': {
    zip: '30625',
    city: 'Buckhead',
    state: 'GA',
    latitude: '33.528483',
    longitude: '-83.340128',
    timeZoneId: 'America/New_York',
  },
  '30627': {
    zip: '30627',
    city: 'Carlton',
    state: 'GA',
    latitude: '33.965792',
    longitude: '-82.939135',
    timeZoneId: 'America/New_York',
  },
  '30628': {
    zip: '30628',
    city: 'Colbert',
    state: 'GA',
    latitude: '34.040327',
    longitude: '-83.213298',
    timeZoneId: 'America/New_York',
  },
  '30629': {
    zip: '30629',
    city: 'Comer',
    state: 'GA',
    latitude: '34.066604',
    longitude: '-83.123296',
    timeZoneId: 'America/New_York',
  },
  '30630': {
    zip: '30630',
    city: 'Crawford',
    state: 'GA',
    latitude: '33.904531',
    longitude: '-83.191795',
    timeZoneId: 'America/New_York',
  },
  '30631': {
    zip: '30631',
    city: 'Crawfordville',
    state: 'GA',
    latitude: '33.55058',
    longitude: '-82.895523',
    timeZoneId: 'America/New_York',
  },
  '30633': {
    zip: '30633',
    city: 'Danielsville',
    state: 'GA',
    latitude: '34.160936',
    longitude: '-83.242089',
    timeZoneId: 'America/New_York',
  },
  '30634': {
    zip: '30634',
    city: 'Dewy Rose',
    state: 'GA',
    latitude: '34.181291',
    longitude: '-82.947378',
    timeZoneId: 'America/New_York',
  },
  '30635': {
    zip: '30635',
    city: 'Elberton',
    state: 'GA',
    latitude: '34.109753',
    longitude: '-82.86146',
    timeZoneId: 'America/New_York',
  },
  '30638': {
    zip: '30638',
    city: 'Farmington',
    state: 'GA',
    latitude: '33.770038',
    longitude: '-83.422408',
    timeZoneId: 'America/New_York',
  },
  '30639': {
    zip: '30639',
    city: 'Franklin Springs',
    state: 'GA',
    latitude: '34.274158',
    longitude: '-83.172001',
    timeZoneId: 'America/New_York',
  },
  '30641': {
    zip: '30641',
    city: 'Good Hope',
    state: 'GA',
    latitude: '33.777502',
    longitude: '-83.566916',
    timeZoneId: 'America/New_York',
  },
  '30642': {
    zip: '30642',
    city: 'Greensboro',
    state: 'GA',
    latitude: '33.556831',
    longitude: '-83.171996',
    timeZoneId: 'America/New_York',
  },
  '30643': {
    zip: '30643',
    city: 'Hartwell',
    state: 'GA',
    latitude: '34.358844',
    longitude: '-82.92227',
    timeZoneId: 'America/New_York',
  },
  '30645': {
    zip: '30645',
    city: 'High Shoals',
    state: 'GA',
    latitude: '33.820756',
    longitude: '-83.502472',
    timeZoneId: 'America/New_York',
  },
  '30646': {
    zip: '30646',
    city: 'Hull',
    state: 'GA',
    latitude: '34.020092',
    longitude: '-83.314243',
    timeZoneId: 'America/New_York',
  },
  '30647': {
    zip: '30647',
    city: 'Ila',
    state: 'GA',
    latitude: '34.172693',
    longitude: '-83.29241',
    timeZoneId: 'America/New_York',
  },
  '30648': {
    zip: '30648',
    city: 'Lexington',
    state: 'GA',
    latitude: '33.87218',
    longitude: '-83.078293',
    timeZoneId: 'America/New_York',
  },
  '30650': {
    zip: '30650',
    city: 'Madison',
    state: 'GA',
    latitude: '33.590212',
    longitude: '-83.477409',
    timeZoneId: 'America/New_York',
  },
  '30655': {
    zip: '30655',
    city: 'Monroe',
    state: 'GA',
    latitude: '33.790908',
    longitude: '-83.711688',
    timeZoneId: 'America/New_York',
  },
  '30656': {
    zip: '30656',
    city: 'Monroe',
    state: 'GA',
    latitude: '33.847484',
    longitude: '-83.717624',
    timeZoneId: 'America/New_York',
  },
  '30660': {
    zip: '30660',
    city: 'Rayle',
    state: 'GA',
    latitude: '33.724162',
    longitude: '-82.76201',
    timeZoneId: 'America/New_York',
  },
  '30662': {
    zip: '30662',
    city: 'Royston',
    state: 'GA',
    latitude: '34.287277',
    longitude: '-83.11636',
    timeZoneId: 'America/New_York',
  },
  '30663': {
    zip: '30663',
    city: 'Rutledge',
    state: 'GA',
    latitude: '33.615881',
    longitude: '-83.607221',
    timeZoneId: 'America/New_York',
  },
  '30664': {
    zip: '30664',
    city: 'Sharon',
    state: 'GA',
    latitude: '33.563799',
    longitude: '-82.879775',
    timeZoneId: 'America/New_York',
  },
  '30665': {
    zip: '30665',
    city: 'Siloam',
    state: 'GA',
    latitude: '33.5315',
    longitude: '-83.082441',
    timeZoneId: 'America/New_York',
  },
  '30666': {
    zip: '30666',
    city: 'Statham',
    state: 'GA',
    latitude: '33.967529',
    longitude: '-83.596167',
    timeZoneId: 'America/New_York',
  },
  '30667': {
    zip: '30667',
    city: 'Stephens',
    state: 'GA',
    latitude: '33.772658',
    longitude: '-83.106905',
    timeZoneId: 'America/New_York',
  },
  '30668': {
    zip: '30668',
    city: 'Tignall',
    state: 'GA',
    latitude: '33.78213',
    longitude: '-82.816561',
    timeZoneId: 'America/New_York',
  },
  '30669': {
    zip: '30669',
    city: 'Union Point',
    state: 'GA',
    latitude: '33.64458',
    longitude: '-83.085879',
    timeZoneId: 'America/New_York',
  },
  '30671': {
    zip: '30671',
    city: 'Maxeys',
    state: 'GA',
    latitude: '33.772874',
    longitude: '-83.107171',
    timeZoneId: 'America/New_York',
  },
  '30673': {
    zip: '30673',
    city: 'Washington',
    state: 'GA',
    latitude: '33.735939',
    longitude: '-82.742819',
    timeZoneId: 'America/New_York',
  },
  '30677': {
    zip: '30677',
    city: 'Watkinsville',
    state: 'GA',
    latitude: '33.848354',
    longitude: '-83.414224',
    timeZoneId: 'America/New_York',
  },
  '30678': {
    zip: '30678',
    city: 'White Plains',
    state: 'GA',
    latitude: '33.455729',
    longitude: '-83.082172',
    timeZoneId: 'America/New_York',
  },
  '30680': {
    zip: '30680',
    city: 'Winder',
    state: 'GA',
    latitude: '34.001056',
    longitude: '-83.724148',
    timeZoneId: 'America/New_York',
  },
  '30683': {
    zip: '30683',
    city: 'Winterville',
    state: 'GA',
    latitude: '33.960174',
    longitude: '-83.287969',
    timeZoneId: 'America/New_York',
  },
  '30701': {
    zip: '30701',
    city: 'Calhoun',
    state: 'GA',
    latitude: '34.495946',
    longitude: '-84.931056',
    timeZoneId: 'America/New_York',
  },
  '30703': {
    zip: '30703',
    city: 'Calhoun',
    state: 'GA',
    latitude: '34.501348',
    longitude: '-84.953816',
    timeZoneId: 'America/New_York',
  },
  '30705': {
    zip: '30705',
    city: 'Chatsworth',
    state: 'GA',
    latitude: '34.752332',
    longitude: '-84.7916',
    timeZoneId: 'America/New_York',
  },
  '30707': {
    zip: '30707',
    city: 'Chickamauga',
    state: 'GA',
    latitude: '34.845003',
    longitude: '-85.330721',
    timeZoneId: 'America/New_York',
  },
  '30708': {
    zip: '30708',
    city: 'Cisco',
    state: 'GA',
    latitude: '34.880162',
    longitude: '-84.666973',
    timeZoneId: 'America/New_York',
  },
  '30710': {
    zip: '30710',
    city: 'Cohutta',
    state: 'GA',
    latitude: '34.942281',
    longitude: '-84.939608',
    timeZoneId: 'America/New_York',
  },
  '30711': {
    zip: '30711',
    city: 'Crandall',
    state: 'GA',
    latitude: '34.940692',
    longitude: '-84.759587',
    timeZoneId: 'America/New_York',
  },
  '30719': {
    zip: '30719',
    city: 'Dalton',
    state: 'GA',
    latitude: '34.770019',
    longitude: '-84.970725',
    timeZoneId: 'America/New_York',
  },
  '30720': {
    zip: '30720',
    city: 'Dalton',
    state: 'GA',
    latitude: '34.759673',
    longitude: '-84.975973',
    timeZoneId: 'America/New_York',
  },
  '30721': {
    zip: '30721',
    city: 'Dalton',
    state: 'GA',
    latitude: '34.784599',
    longitude: '-84.932948',
    timeZoneId: 'America/New_York',
  },
  '30722': {
    zip: '30722',
    city: 'Dalton',
    state: 'GA',
    latitude: '34.761009',
    longitude: '-84.949881',
    timeZoneId: 'America/New_York',
  },
  '30724': {
    zip: '30724',
    city: 'Eton',
    state: 'GA',
    latitude: '34.822161',
    longitude: '-84.761831',
    timeZoneId: 'America/New_York',
  },
  '30725': {
    zip: '30725',
    city: 'Flintstone',
    state: 'GA',
    latitude: '34.937899',
    longitude: '-85.331723',
    timeZoneId: 'America/New_York',
  },
  '30726': {
    zip: '30726',
    city: 'Graysville',
    state: 'GA',
    latitude: '34.97419',
    longitude: '-85.142346',
    timeZoneId: 'America/New_York',
  },
  '30728': {
    zip: '30728',
    city: 'La Fayette',
    state: 'GA',
    latitude: '34.708605',
    longitude: '-85.286695',
    timeZoneId: 'America/New_York',
  },
  '30730': {
    zip: '30730',
    city: 'Lyerly',
    state: 'GA',
    latitude: '34.402441',
    longitude: '-85.397197',
    timeZoneId: 'America/New_York',
  },
  '30731': {
    zip: '30731',
    city: 'Menlo',
    state: 'GA',
    latitude: '34.62264',
    longitude: '-85.49772',
    timeZoneId: 'America/New_York',
  },
  '30732': {
    zip: '30732',
    city: 'Oakman',
    state: 'GA',
    latitude: '34.564396',
    longitude: '-84.704346',
    timeZoneId: 'America/New_York',
  },
  '30733': {
    zip: '30733',
    city: 'Plainville',
    state: 'GA',
    latitude: '34.412119',
    longitude: '-85.039417',
    timeZoneId: 'America/New_York',
  },
  '30734': {
    zip: '30734',
    city: 'Ranger',
    state: 'GA',
    latitude: '34.532321',
    longitude: '-84.73469',
    timeZoneId: 'America/New_York',
  },
  '30735': {
    zip: '30735',
    city: 'Resaca',
    state: 'GA',
    latitude: '34.593674',
    longitude: '-84.928381',
    timeZoneId: 'America/New_York',
  },
  '30736': {
    zip: '30736',
    city: 'Ringgold',
    state: 'GA',
    latitude: '34.934373',
    longitude: '-85.151128',
    timeZoneId: 'America/New_York',
  },
  '30738': {
    zip: '30738',
    city: 'Rising Fawn',
    state: 'GA',
    latitude: '34.782056',
    longitude: '-85.444826',
    timeZoneId: 'America/New_York',
  },
  '30739': {
    zip: '30739',
    city: 'Rock Spring',
    state: 'GA',
    latitude: '34.900531',
    longitude: '-85.283287',
    timeZoneId: 'America/New_York',
  },
  '30740': {
    zip: '30740',
    city: 'Rocky Face',
    state: 'GA',
    latitude: '34.771128',
    longitude: '-85.056235',
    timeZoneId: 'America/New_York',
  },
  '30741': {
    zip: '30741',
    city: 'Rossville',
    state: 'GA',
    latitude: '34.958205',
    longitude: '-85.295156',
    timeZoneId: 'America/New_York',
  },
  '30742': {
    zip: '30742',
    city: 'Fort Oglethorpe',
    state: 'GA',
    latitude: '34.954118',
    longitude: '-85.250532',
    timeZoneId: 'America/New_York',
  },
  '30746': {
    zip: '30746',
    city: 'Sugar Valley',
    state: 'GA',
    latitude: '34.572613',
    longitude: '-85.025048',
    timeZoneId: 'America/New_York',
  },
  '30747': {
    zip: '30747',
    city: 'Summerville',
    state: 'GA',
    latitude: '34.486025',
    longitude: '-85.345875',
    timeZoneId: 'America/New_York',
  },
  '30750': {
    zip: '30750',
    city: 'Lookout Mountain',
    state: 'GA',
    latitude: '34.975006',
    longitude: '-85.355133',
    timeZoneId: 'America/New_York',
  },
  '30751': {
    zip: '30751',
    city: 'Tennga',
    state: 'GA',
    latitude: '34.986081',
    longitude: '-84.736575',
    timeZoneId: 'America/New_York',
  },
  '30752': {
    zip: '30752',
    city: 'Trenton',
    state: 'GA',
    latitude: '34.870608',
    longitude: '-85.508537',
    timeZoneId: 'America/New_York',
  },
  '30753': {
    zip: '30753',
    city: 'Trion',
    state: 'GA',
    latitude: '34.543448',
    longitude: '-85.309856',
    timeZoneId: 'America/New_York',
  },
  '30755': {
    zip: '30755',
    city: 'Tunnel Hill',
    state: 'GA',
    latitude: '34.843757',
    longitude: '-85.04345',
    timeZoneId: 'America/New_York',
  },
  '30756': {
    zip: '30756',
    city: 'Varnell',
    state: 'GA',
    latitude: '34.895021',
    longitude: '-84.977128',
    timeZoneId: 'America/New_York',
  },
  '30757': {
    zip: '30757',
    city: 'Wildwood',
    state: 'GA',
    latitude: '34.977192',
    longitude: '-85.436272',
    timeZoneId: 'America/New_York',
  },
  '30802': {
    zip: '30802',
    city: 'Appling',
    state: 'GA',
    latitude: '33.5398',
    longitude: '-82.315022',
    timeZoneId: 'America/New_York',
  },
  '30803': {
    zip: '30803',
    city: 'Avera',
    state: 'GA',
    latitude: '33.193064',
    longitude: '-82.528161',
    timeZoneId: 'America/New_York',
  },
  '30805': {
    zip: '30805',
    city: 'Blythe',
    state: 'GA',
    latitude: '33.344593',
    longitude: '-82.127389',
    timeZoneId: 'America/New_York',
  },
  '30806': {
    zip: '30806',
    city: 'Boneville',
    state: 'GA',
    latitude: '33.526632',
    longitude: '-82.513399',
    timeZoneId: 'America/New_York',
  },
  '30807': {
    zip: '30807',
    city: 'Camak',
    state: 'GA',
    latitude: '33.458265',
    longitude: '-82.646376',
    timeZoneId: 'America/New_York',
  },
  '30808': {
    zip: '30808',
    city: 'Dearing',
    state: 'GA',
    latitude: '33.463274',
    longitude: '-82.495096',
    timeZoneId: 'America/New_York',
  },
  '30809': {
    zip: '30809',
    city: 'Evans',
    state: 'GA',
    latitude: '33.541277',
    longitude: '-82.136764',
    timeZoneId: 'America/New_York',
  },
  '30810': {
    zip: '30810',
    city: 'Gibson',
    state: 'GA',
    latitude: '33.235312',
    longitude: '-82.597815',
    timeZoneId: 'America/New_York',
  },
  '30811': {
    zip: '30811',
    city: 'Gough',
    state: 'GA',
    latitude: '33.09341',
    longitude: '-82.225707',
    timeZoneId: 'America/New_York',
  },
  '30812': {
    zip: '30812',
    city: 'Gracewood',
    state: 'GA',
    latitude: '33.373889',
    longitude: '-82.026497',
    timeZoneId: 'America/New_York',
  },
  '30813': {
    zip: '30813',
    city: 'Grovetown',
    state: 'GA',
    latitude: '33.463492',
    longitude: '-82.208269',
    timeZoneId: 'America/New_York',
  },
  '30814': {
    zip: '30814',
    city: 'Harlem',
    state: 'GA',
    latitude: '33.414274',
    longitude: '-82.313268',
    timeZoneId: 'America/New_York',
  },
  '30815': {
    zip: '30815',
    city: 'Hephzibah',
    state: 'GA',
    latitude: '33.344357',
    longitude: '-82.069749',
    timeZoneId: 'America/New_York',
  },
  '30816': {
    zip: '30816',
    city: 'Keysville',
    state: 'GA',
    latitude: '33.170681',
    longitude: '-82.179086',
    timeZoneId: 'America/New_York',
  },
  '30817': {
    zip: '30817',
    city: 'Lincolnton',
    state: 'GA',
    latitude: '33.824462',
    longitude: '-82.493112',
    timeZoneId: 'America/New_York',
  },
  '30818': {
    zip: '30818',
    city: 'Matthews',
    state: 'GA',
    latitude: '33.215285',
    longitude: '-82.287101',
    timeZoneId: 'America/New_York',
  },
  '30819': {
    zip: '30819',
    city: 'Mesena',
    state: 'GA',
    latitude: '33.460559',
    longitude: '-82.591621',
    timeZoneId: 'America/New_York',
  },
  '30820': {
    zip: '30820',
    city: 'Mitchell',
    state: 'GA',
    latitude: '33.187247',
    longitude: '-82.669971',
    timeZoneId: 'America/New_York',
  },
  '30821': {
    zip: '30821',
    city: 'Norwood',
    state: 'GA',
    latitude: '33.462193',
    longitude: '-82.706161',
    timeZoneId: 'America/New_York',
  },
  '30822': {
    zip: '30822',
    city: 'Perkins',
    state: 'GA',
    latitude: '32.809497',
    longitude: '-81.940678',
    timeZoneId: 'America/New_York',
  },
  '30823': {
    zip: '30823',
    city: 'Stapleton',
    state: 'GA',
    latitude: '33.21104',
    longitude: '-82.458438',
    timeZoneId: 'America/New_York',
  },
  '30824': {
    zip: '30824',
    city: 'Thomson',
    state: 'GA',
    latitude: '33.468374',
    longitude: '-82.498017',
    timeZoneId: 'America/New_York',
  },
  '30828': {
    zip: '30828',
    city: 'Warrenton',
    state: 'GA',
    latitude: '33.408859',
    longitude: '-82.665215',
    timeZoneId: 'America/New_York',
  },
  '30830': {
    zip: '30830',
    city: 'Waynesboro',
    state: 'GA',
    latitude: '33.092086',
    longitude: '-82.019367',
    timeZoneId: 'America/New_York',
  },
  '30833': {
    zip: '30833',
    city: 'Wrens',
    state: 'GA',
    latitude: '33.206767',
    longitude: '-82.389017',
    timeZoneId: 'America/New_York',
  },
  '30901': {
    zip: '30901',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.460973',
    longitude: '-81.974866',
    timeZoneId: 'America/New_York',
  },
  '30903': {
    zip: '30903',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.472729',
    longitude: '-81.967746',
    timeZoneId: 'America/New_York',
  },
  '30904': {
    zip: '30904',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.470117',
    longitude: '-82.01317',
    timeZoneId: 'America/New_York',
  },
  '30905': {
    zip: '30905',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.403925',
    longitude: '-82.120051',
    timeZoneId: 'America/New_York',
  },
  '30906': {
    zip: '30906',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.400357',
    longitude: '-82.031397',
    timeZoneId: 'America/New_York',
  },
  '30907': {
    zip: '30907',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.525',
    longitude: '-82.10142',
    timeZoneId: 'America/New_York',
  },
  '30909': {
    zip: '30909',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.478228',
    longitude: '-82.067015',
    timeZoneId: 'America/New_York',
  },
  '30912': {
    zip: '30912',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.473519',
    longitude: '-81.98825',
    timeZoneId: 'America/New_York',
  },
  '30914': {
    zip: '30914',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.467027',
    longitude: '-82.01743',
    timeZoneId: 'America/New_York',
  },
  '30916': {
    zip: '30916',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.419732',
    longitude: '-82.019129',
    timeZoneId: 'America/New_York',
  },
  '30917': {
    zip: '30917',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.51674',
    longitude: '-82.057765',
    timeZoneId: 'America/New_York',
  },
  '30919': {
    zip: '30919',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.470809',
    longitude: '-82.067346',
    timeZoneId: 'America/New_York',
  },
  '30999': {
    zip: '30999',
    city: 'Augusta',
    state: 'GA',
    latitude: '33.456177',
    longitude: '-81.991838',
    timeZoneId: 'America/New_York',
  },
  '31001': {
    zip: '31001',
    city: 'Abbeville',
    state: 'GA',
    latitude: '31.991336',
    longitude: '-83.31305',
    timeZoneId: 'America/New_York',
  },
  '31002': {
    zip: '31002',
    city: 'Adrian',
    state: 'GA',
    latitude: '32.528899',
    longitude: '-82.592627',
    timeZoneId: 'America/New_York',
  },
  '31003': {
    zip: '31003',
    city: 'Allentown',
    state: 'GA',
    latitude: '32.597329',
    longitude: '-83.223086',
    timeZoneId: 'America/New_York',
  },
  '31004': {
    zip: '31004',
    city: 'Bolingbroke',
    state: 'GA',
    latitude: '33.085895',
    longitude: '-83.921131',
    timeZoneId: 'America/New_York',
  },
  '31005': {
    zip: '31005',
    city: 'Bonaire',
    state: 'GA',
    latitude: '32.557405',
    longitude: '-83.617919',
    timeZoneId: 'America/New_York',
  },
  '31006': {
    zip: '31006',
    city: 'Butler',
    state: 'GA',
    latitude: '32.55639',
    longitude: '-84.242868',
    timeZoneId: 'America/New_York',
  },
  '31007': {
    zip: '31007',
    city: 'Byromville',
    state: 'GA',
    latitude: '32.171788',
    longitude: '-83.926088',
    timeZoneId: 'America/New_York',
  },
  '31008': {
    zip: '31008',
    city: 'Byron',
    state: 'GA',
    latitude: '32.646227',
    longitude: '-83.754589',
    timeZoneId: 'America/New_York',
  },
  '31009': {
    zip: '31009',
    city: 'Cadwell',
    state: 'GA',
    latitude: '32.337992',
    longitude: '-83.042533',
    timeZoneId: 'America/New_York',
  },
  '31010': {
    zip: '31010',
    city: 'Cordele',
    state: 'GA',
    latitude: '31.953919',
    longitude: '-83.782947',
    timeZoneId: 'America/New_York',
  },
  '31011': {
    zip: '31011',
    city: 'Chauncey',
    state: 'GA',
    latitude: '32.20796',
    longitude: '-83.128783',
    timeZoneId: 'America/New_York',
  },
  '31012': {
    zip: '31012',
    city: 'Chester',
    state: 'GA',
    latitude: '32.324564',
    longitude: '-83.165882',
    timeZoneId: 'America/New_York',
  },
  '31013': {
    zip: '31013',
    city: 'Clinchfield',
    state: 'GA',
    latitude: '32.337684',
    longitude: '-83.731445',
    timeZoneId: 'America/New_York',
  },
  '31014': {
    zip: '31014',
    city: 'Cochran',
    state: 'GA',
    latitude: '32.385663',
    longitude: '-83.348675',
    timeZoneId: 'America/New_York',
  },
  '31015': {
    zip: '31015',
    city: 'Cordele',
    state: 'GA',
    latitude: '31.946591',
    longitude: '-83.765287',
    timeZoneId: 'America/New_York',
  },
  '31016': {
    zip: '31016',
    city: 'Culloden',
    state: 'GA',
    latitude: '32.776113',
    longitude: '-84.183821',
    timeZoneId: 'America/New_York',
  },
  '31017': {
    zip: '31017',
    city: 'Danville',
    state: 'GA',
    latitude: '32.604257',
    longitude: '-83.248013',
    timeZoneId: 'America/New_York',
  },
  '31018': {
    zip: '31018',
    city: 'Davisboro',
    state: 'GA',
    latitude: '32.983468',
    longitude: '-82.599047',
    timeZoneId: 'America/New_York',
  },
  '31019': {
    zip: '31019',
    city: 'Dexter',
    state: 'GA',
    latitude: '32.438652',
    longitude: '-83.060758',
    timeZoneId: 'America/New_York',
  },
  '31020': {
    zip: '31020',
    city: 'Dry Branch',
    state: 'GA',
    latitude: '32.815139',
    longitude: '-83.548394',
    timeZoneId: 'America/New_York',
  },
  '31021': {
    zip: '31021',
    city: 'Dublin',
    state: 'GA',
    latitude: '32.542986',
    longitude: '-82.909684',
    timeZoneId: 'America/New_York',
  },
  '31022': {
    zip: '31022',
    city: 'Dudley',
    state: 'GA',
    latitude: '32.509216',
    longitude: '-83.08885',
    timeZoneId: 'America/New_York',
  },
  '31023': {
    zip: '31023',
    city: 'Eastman',
    state: 'GA',
    latitude: '32.196771',
    longitude: '-83.180079',
    timeZoneId: 'America/New_York',
  },
  '31024': {
    zip: '31024',
    city: 'Eatonton',
    state: 'GA',
    latitude: '33.326891',
    longitude: '-83.345511',
    timeZoneId: 'America/New_York',
  },
  '31025': {
    zip: '31025',
    city: 'Elko',
    state: 'GA',
    latitude: '32.343533',
    longitude: '-83.742165',
    timeZoneId: 'America/New_York',
  },
  '31026': {
    zip: '31026',
    city: 'Eatonton',
    state: 'GA',
    latitude: '33.303533',
    longitude: '-83.377912',
    timeZoneId: 'America/New_York',
  },
  '31027': {
    zip: '31027',
    city: 'East Dublin',
    state: 'GA',
    latitude: '32.548593',
    longitude: '-82.870112',
    timeZoneId: 'America/New_York',
  },
  '31028': {
    zip: '31028',
    city: 'Centerville',
    state: 'GA',
    latitude: '32.629036',
    longitude: '-83.677182',
    timeZoneId: 'America/New_York',
  },
  '31029': {
    zip: '31029',
    city: 'Forsyth',
    state: 'GA',
    latitude: '33.030828',
    longitude: '-83.936093',
    timeZoneId: 'America/New_York',
  },
  '31030': {
    zip: '31030',
    city: 'Fort Valley',
    state: 'GA',
    latitude: '32.550073',
    longitude: '-83.882935',
    timeZoneId: 'America/New_York',
  },
  '31031': {
    zip: '31031',
    city: 'Gordon',
    state: 'GA',
    latitude: '32.901291',
    longitude: '-83.317179',
    timeZoneId: 'America/New_York',
  },
  '31032': {
    zip: '31032',
    city: 'Gray',
    state: 'GA',
    latitude: '33.004161',
    longitude: '-83.545026',
    timeZoneId: 'America/New_York',
  },
  '31033': {
    zip: '31033',
    city: 'Haddock',
    state: 'GA',
    latitude: '33.008756',
    longitude: '-83.368096',
    timeZoneId: 'America/New_York',
  },
  '31034': {
    zip: '31034',
    city: 'Hardwick',
    state: 'GA',
    latitude: '33.053908',
    longitude: '-83.230381',
    timeZoneId: 'America/New_York',
  },
  '31035': {
    zip: '31035',
    city: 'Harrison',
    state: 'GA',
    latitude: '32.877871',
    longitude: '-82.744513',
    timeZoneId: 'America/New_York',
  },
  '31036': {
    zip: '31036',
    city: 'Hawkinsville',
    state: 'GA',
    latitude: '32.281988',
    longitude: '-83.47361',
    timeZoneId: 'America/New_York',
  },
  '31037': {
    zip: '31037',
    city: 'Mc Rae Helena',
    state: 'GA',
    latitude: '32.060166',
    longitude: '-82.93656',
    timeZoneId: 'America/New_York',
  },
  '31038': {
    zip: '31038',
    city: 'Hillsboro',
    state: 'GA',
    latitude: '33.174782',
    longitude: '-83.709904',
    timeZoneId: 'America/New_York',
  },
  '31039': {
    zip: '31039',
    city: 'Howard',
    state: 'GA',
    latitude: '32.595605',
    longitude: '-84.249396',
    timeZoneId: 'America/New_York',
  },
  '31040': {
    zip: '31040',
    city: 'Dublin',
    state: 'GA',
    latitude: '32.547937',
    longitude: '-82.901637',
    timeZoneId: 'America/New_York',
  },
  '31041': {
    zip: '31041',
    city: 'Ideal',
    state: 'GA',
    latitude: '32.377455',
    longitude: '-84.204243',
    timeZoneId: 'America/New_York',
  },
  '31042': {
    zip: '31042',
    city: 'Irwinton',
    state: 'GA',
    latitude: '32.809157',
    longitude: '-83.17322',
    timeZoneId: 'America/New_York',
  },
  '31044': {
    zip: '31044',
    city: 'Jeffersonville',
    state: 'GA',
    latitude: '32.682004',
    longitude: '-83.331608',
    timeZoneId: 'America/New_York',
  },
  '31045': {
    zip: '31045',
    city: 'Jewell',
    state: 'GA',
    latitude: '33.336256',
    longitude: '-82.734618',
    timeZoneId: 'America/New_York',
  },
  '31046': {
    zip: '31046',
    city: 'Juliette',
    state: 'GA',
    latitude: '32.99542',
    longitude: '-83.775669',
    timeZoneId: 'America/New_York',
  },
  '31047': {
    zip: '31047',
    city: 'Kathleen',
    state: 'GA',
    latitude: '32.501331',
    longitude: '-83.630956',
    timeZoneId: 'America/New_York',
  },
  '31049': {
    zip: '31049',
    city: 'Kite',
    state: 'GA',
    latitude: '32.718538',
    longitude: '-82.530638',
    timeZoneId: 'America/New_York',
  },
  '31050': {
    zip: '31050',
    city: 'Knoxville',
    state: 'GA',
    latitude: '32.63303',
    longitude: '-83.961232',
    timeZoneId: 'America/New_York',
  },
  '31051': {
    zip: '31051',
    city: 'Lilly',
    state: 'GA',
    latitude: '32.148599',
    longitude: '-83.877382',
    timeZoneId: 'America/New_York',
  },
  '31052': {
    zip: '31052',
    city: 'Lizella',
    state: 'GA',
    latitude: '32.786526',
    longitude: '-83.793445',
    timeZoneId: 'America/New_York',
  },
  '31054': {
    zip: '31054',
    city: 'Mc Intyre',
    state: 'GA',
    latitude: '32.848657',
    longitude: '-83.203467',
    timeZoneId: 'America/New_York',
  },
  '31055': {
    zip: '31055',
    city: 'Mc Rae Helena',
    state: 'GA',
    latitude: '32.063143',
    longitude: '-82.901568',
    timeZoneId: 'America/New_York',
  },
  '31057': {
    zip: '31057',
    city: 'Marshallville',
    state: 'GA',
    latitude: '32.454415',
    longitude: '-83.942245',
    timeZoneId: 'America/New_York',
  },
  '31058': {
    zip: '31058',
    city: 'Mauk',
    state: 'GA',
    latitude: '32.510143',
    longitude: '-84.375453',
    timeZoneId: 'America/New_York',
  },
  '31059': {
    zip: '31059',
    city: 'Milledgeville',
    state: 'GA',
    latitude: '33.086793',
    longitude: '-83.244856',
    timeZoneId: 'America/New_York',
  },
  '31060': {
    zip: '31060',
    city: 'Milan',
    state: 'GA',
    latitude: '31.930995',
    longitude: '-83.066296',
    timeZoneId: 'America/New_York',
  },
  '31061': {
    zip: '31061',
    city: 'Milledgeville',
    state: 'GA',
    latitude: '33.085732',
    longitude: '-83.245046',
    timeZoneId: 'America/New_York',
  },
  '31062': {
    zip: '31062',
    city: 'Milledgeville',
    state: 'GA',
    latitude: '33.086401',
    longitude: '-83.23986',
    timeZoneId: 'America/New_York',
  },
  '31063': {
    zip: '31063',
    city: 'Montezuma',
    state: 'GA',
    latitude: '32.29909',
    longitude: '-84.021341',
    timeZoneId: 'America/New_York',
  },
  '31064': {
    zip: '31064',
    city: 'Monticello',
    state: 'GA',
    latitude: '33.332348',
    longitude: '-83.758157',
    timeZoneId: 'America/New_York',
  },
  '31065': {
    zip: '31065',
    city: 'Montrose',
    state: 'GA',
    latitude: '32.561904',
    longitude: '-83.160896',
    timeZoneId: 'America/New_York',
  },
  '31066': {
    zip: '31066',
    city: 'Musella',
    state: 'GA',
    latitude: '32.841603',
    longitude: '-83.886065',
    timeZoneId: 'America/New_York',
  },
  '31067': {
    zip: '31067',
    city: 'Oconee',
    state: 'GA',
    latitude: '32.864936',
    longitude: '-82.874224',
    timeZoneId: 'America/New_York',
  },
  '31068': {
    zip: '31068',
    city: 'Oglethorpe',
    state: 'GA',
    latitude: '32.293577',
    longitude: '-84.068988',
    timeZoneId: 'America/New_York',
  },
  '31069': {
    zip: '31069',
    city: 'Perry',
    state: 'GA',
    latitude: '32.458921',
    longitude: '-83.730638',
    timeZoneId: 'America/New_York',
  },
  '31070': {
    zip: '31070',
    city: 'Pinehurst',
    state: 'GA',
    latitude: '32.193022',
    longitude: '-83.760561',
    timeZoneId: 'America/New_York',
  },
  '31071': {
    zip: '31071',
    city: 'Pineview',
    state: 'GA',
    latitude: '32.076427',
    longitude: '-83.500229',
    timeZoneId: 'America/New_York',
  },
  '31072': {
    zip: '31072',
    city: 'Pitts',
    state: 'GA',
    latitude: '32.007175',
    longitude: '-83.616832',
    timeZoneId: 'America/New_York',
  },
  '31075': {
    zip: '31075',
    city: 'Rentz',
    state: 'GA',
    latitude: '32.334705',
    longitude: '-82.88595',
    timeZoneId: 'America/New_York',
  },
  '31076': {
    zip: '31076',
    city: 'Reynolds',
    state: 'GA',
    latitude: '32.561496',
    longitude: '-84.092692',
    timeZoneId: 'America/New_York',
  },
  '31077': {
    zip: '31077',
    city: 'Rhine',
    state: 'GA',
    latitude: '32.01125',
    longitude: '-83.198326',
    timeZoneId: 'America/New_York',
  },
  '31078': {
    zip: '31078',
    city: 'Roberta',
    state: 'GA',
    latitude: '32.723299',
    longitude: '-84.016707',
    timeZoneId: 'America/New_York',
  },
  '31079': {
    zip: '31079',
    city: 'Rochelle',
    state: 'GA',
    latitude: '31.949715',
    longitude: '-83.454065',
    timeZoneId: 'America/New_York',
  },
  '31081': {
    zip: '31081',
    city: 'Rupert',
    state: 'GA',
    latitude: '32.424044',
    longitude: '-84.289842',
    timeZoneId: 'America/New_York',
  },
  '31082': {
    zip: '31082',
    city: 'Sandersville',
    state: 'GA',
    latitude: '32.980476',
    longitude: '-82.810981',
    timeZoneId: 'America/New_York',
  },
  '31083': {
    zip: '31083',
    city: 'Scotland',
    state: 'GA',
    latitude: '31.982026',
    longitude: '-82.870144',
    timeZoneId: 'America/New_York',
  },
  '31084': {
    zip: '31084',
    city: 'Seville',
    state: 'GA',
    latitude: '31.931082',
    longitude: '-83.554622',
    timeZoneId: 'America/New_York',
  },
  '31085': {
    zip: '31085',
    city: 'Shady Dale',
    state: 'GA',
    latitude: '33.398491',
    longitude: '-83.659553',
    timeZoneId: 'America/New_York',
  },
  '31086': {
    zip: '31086',
    city: 'Smarr',
    state: 'GA',
    latitude: '32.876605',
    longitude: '-84.058444',
    timeZoneId: 'America/New_York',
  },
  '31087': {
    zip: '31087',
    city: 'Sparta',
    state: 'GA',
    latitude: '33.277104',
    longitude: '-82.971578',
    timeZoneId: 'America/New_York',
  },
  '31088': {
    zip: '31088',
    city: 'Warner Robins',
    state: 'GA',
    latitude: '32.595758',
    longitude: '-83.638378',
    timeZoneId: 'America/New_York',
  },
  '31089': {
    zip: '31089',
    city: 'Tennille',
    state: 'GA',
    latitude: '32.940199',
    longitude: '-82.811114',
    timeZoneId: 'America/New_York',
  },
  '31090': {
    zip: '31090',
    city: 'Toomsboro',
    state: 'GA',
    latitude: '32.843356',
    longitude: '-83.098794',
    timeZoneId: 'America/New_York',
  },
  '31091': {
    zip: '31091',
    city: 'Unadilla',
    state: 'GA',
    latitude: '32.259597',
    longitude: '-83.746979',
    timeZoneId: 'America/New_York',
  },
  '31092': {
    zip: '31092',
    city: 'Vienna',
    state: 'GA',
    latitude: '32.093587',
    longitude: '-83.793623',
    timeZoneId: 'America/New_York',
  },
  '31093': {
    zip: '31093',
    city: 'Warner Robins',
    state: 'GA',
    latitude: '32.636119',
    longitude: '-83.641763',
    timeZoneId: 'America/New_York',
  },
  '31094': {
    zip: '31094',
    city: 'Warthen',
    state: 'GA',
    latitude: '33.010838',
    longitude: '-82.823711',
    timeZoneId: 'America/New_York',
  },
  '31095': {
    zip: '31095',
    city: 'Warner Robins',
    state: 'GA',
    latitude: '32.619635',
    longitude: '-83.599433',
    timeZoneId: 'America/New_York',
  },
  '31096': {
    zip: '31096',
    city: 'Wrightsville',
    state: 'GA',
    latitude: '32.723545',
    longitude: '-82.723352',
    timeZoneId: 'America/New_York',
  },
  '31097': {
    zip: '31097',
    city: 'Yatesville',
    state: 'GA',
    latitude: '32.925363',
    longitude: '-84.163891',
    timeZoneId: 'America/New_York',
  },
  '31098': {
    zip: '31098',
    city: 'Warner Robins',
    state: 'GA',
    latitude: '32.611973',
    longitude: '-83.596726',
    timeZoneId: 'America/New_York',
  },
  '31099': {
    zip: '31099',
    city: 'Warner Robins',
    state: 'GA',
    latitude: '32.643141',
    longitude: '-83.634469',
    timeZoneId: 'America/New_York',
  },
  '31106': {
    zip: '31106',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.787616',
    longitude: '-84.355516',
    timeZoneId: 'America/New_York',
  },
  '31107': {
    zip: '31107',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.763577',
    longitude: '-84.351567',
    timeZoneId: 'America/New_York',
  },
  '31119': {
    zip: '31119',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.857043',
    longitude: '-84.344412',
    timeZoneId: 'America/New_York',
  },
  '31126': {
    zip: '31126',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.847924',
    longitude: '-84.359054',
    timeZoneId: 'America/New_York',
  },
  '31131': {
    zip: '31131',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.705697',
    longitude: '-84.5472',
    timeZoneId: 'America/New_York',
  },
  '31136': {
    zip: '31136',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.720331',
    longitude: '-84.525484',
    timeZoneId: 'America/New_York',
  },
  '31139': {
    zip: '31139',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.748107',
    longitude: '-84.384924',
    timeZoneId: 'America/New_York',
  },
  '31141': {
    zip: '31141',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.761509',
    longitude: '-84.419799',
    timeZoneId: 'America/New_York',
  },
  '31144': {
    zip: '31144',
    city: 'Kennesaw',
    state: 'GA',
    latitude: '34.02365',
    longitude: '-84.61554',
    timeZoneId: 'America/New_York',
  },
  '31145': {
    zip: '31145',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.853136',
    longitude: '-84.279625',
    timeZoneId: 'America/New_York',
  },
  '31146': {
    zip: '31146',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.925442',
    longitude: '-84.341206',
    timeZoneId: 'America/New_York',
  },
  '31150': {
    zip: '31150',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.982371',
    longitude: '-84.333684',
    timeZoneId: 'America/New_York',
  },
  '31156': {
    zip: '31156',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.959963',
    longitude: '-84.364913',
    timeZoneId: 'America/New_York',
  },
  '31169': {
    zip: '31169',
    city: 'Peachtree City',
    state: 'GA',
    latitude: '33.396731',
    longitude: '-84.595632',
    timeZoneId: 'America/New_York',
  },
  '31192': {
    zip: '31192',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.623867',
    longitude: '-84.534721',
    timeZoneId: 'America/New_York',
  },
  '31193': {
    zip: '31193',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.7612',
    longitude: '-84.385347',
    timeZoneId: 'America/New_York',
  },
  '31195': {
    zip: '31195',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.80607',
    longitude: '-84.477521',
    timeZoneId: 'America/New_York',
  },
  '31196': {
    zip: '31196',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.807894',
    longitude: '-84.474929',
    timeZoneId: 'America/New_York',
  },
  '31201': {
    zip: '31201',
    city: 'Macon',
    state: 'GA',
    latitude: '32.833817',
    longitude: '-83.633712',
    timeZoneId: 'America/New_York',
  },
  '31202': {
    zip: '31202',
    city: 'Macon',
    state: 'GA',
    latitude: '32.834051',
    longitude: '-83.632207',
    timeZoneId: 'America/New_York',
  },
  '31203': {
    zip: '31203',
    city: 'Macon',
    state: 'GA',
    latitude: '32.867332',
    longitude: '-83.693101',
    timeZoneId: 'America/New_York',
  },
  '31204': {
    zip: '31204',
    city: 'Macon',
    state: 'GA',
    latitude: '32.842317',
    longitude: '-83.677163',
    timeZoneId: 'America/New_York',
  },
  '31205': {
    zip: '31205',
    city: 'Macon',
    state: 'GA',
    latitude: '32.750172',
    longitude: '-83.661143',
    timeZoneId: 'America/New_York',
  },
  '31206': {
    zip: '31206',
    city: 'Macon',
    state: 'GA',
    latitude: '32.7921',
    longitude: '-83.6667',
    timeZoneId: 'America/New_York',
  },
  '31207': {
    zip: '31207',
    city: 'Macon',
    state: 'GA',
    latitude: '32.832198',
    longitude: '-83.656205',
    timeZoneId: 'America/New_York',
  },
  '31208': {
    zip: '31208',
    city: 'Macon',
    state: 'GA',
    latitude: '32.831183',
    longitude: '-83.63993',
    timeZoneId: 'America/New_York',
  },
  '31209': {
    zip: '31209',
    city: 'Macon',
    state: 'GA',
    latitude: '32.879094',
    longitude: '-83.664957',
    timeZoneId: 'America/New_York',
  },
  '31210': {
    zip: '31210',
    city: 'Macon',
    state: 'GA',
    latitude: '32.876518',
    longitude: '-83.739134',
    timeZoneId: 'America/New_York',
  },
  '31211': {
    zip: '31211',
    city: 'Macon',
    state: 'GA',
    latitude: '32.867714',
    longitude: '-83.605178',
    timeZoneId: 'America/New_York',
  },
  '31213': {
    zip: '31213',
    city: 'Macon',
    state: 'GA',
    latitude: '32.821378',
    longitude: '-83.647007',
    timeZoneId: 'America/New_York',
  },
  '31216': {
    zip: '31216',
    city: 'Macon',
    state: 'GA',
    latitude: '32.730819',
    longitude: '-83.683586',
    timeZoneId: 'America/New_York',
  },
  '31217': {
    zip: '31217',
    city: 'Macon',
    state: 'GA',
    latitude: '32.840243',
    longitude: '-83.538862',
    timeZoneId: 'America/New_York',
  },
  '31220': {
    zip: '31220',
    city: 'Macon',
    state: 'GA',
    latitude: '32.880527',
    longitude: '-83.836998',
    timeZoneId: 'America/New_York',
  },
  '31221': {
    zip: '31221',
    city: 'Macon',
    state: 'GA',
    latitude: '32.880489',
    longitude: '-83.818616',
    timeZoneId: 'America/New_York',
  },
  '31294': {
    zip: '31294',
    city: 'Macon',
    state: 'GA',
    latitude: '32.837487',
    longitude: '-83.632352',
    timeZoneId: 'America/New_York',
  },
  '31295': {
    zip: '31295',
    city: 'Macon',
    state: 'GA',
    latitude: '32.810582',
    longitude: '-83.571041',
    timeZoneId: 'America/New_York',
  },
  '31296': {
    zip: '31296',
    city: 'Macon',
    state: 'GA',
    latitude: '32.808701',
    longitude: '-83.569496',
    timeZoneId: 'America/New_York',
  },
  '31297': {
    zip: '31297',
    city: 'Macon',
    state: 'GA',
    latitude: '32.808857',
    longitude: '-83.690683',
    timeZoneId: 'America/New_York',
  },
  '31301': {
    zip: '31301',
    city: 'Allenhurst',
    state: 'GA',
    latitude: '31.771082',
    longitude: '-81.598279',
    timeZoneId: 'America/New_York',
  },
  '31302': {
    zip: '31302',
    city: 'Bloomingdale',
    state: 'GA',
    latitude: '32.121679',
    longitude: '-81.299728',
    timeZoneId: 'America/New_York',
  },
  '31303': {
    zip: '31303',
    city: 'Clyo',
    state: 'GA',
    latitude: '32.516854',
    longitude: '-81.318805',
    timeZoneId: 'America/New_York',
  },
  '31304': {
    zip: '31304',
    city: 'Crescent',
    state: 'GA',
    latitude: '31.499626',
    longitude: '-81.373103',
    timeZoneId: 'America/New_York',
  },
  '31305': {
    zip: '31305',
    city: 'Darien',
    state: 'GA',
    latitude: '31.377338',
    longitude: '-81.42438',
    timeZoneId: 'America/New_York',
  },
  '31307': {
    zip: '31307',
    city: 'Eden',
    state: 'GA',
    latitude: '32.172291',
    longitude: '-81.39017',
    timeZoneId: 'America/New_York',
  },
  '31308': {
    zip: '31308',
    city: 'Ellabell',
    state: 'GA',
    latitude: '32.175889',
    longitude: '-81.48207',
    timeZoneId: 'America/New_York',
  },
  '31309': {
    zip: '31309',
    city: 'Fleming',
    state: 'GA',
    latitude: '31.88267',
    longitude: '-81.413585',
    timeZoneId: 'America/New_York',
  },
  '31310': {
    zip: '31310',
    city: 'Hinesville',
    state: 'GA',
    latitude: '31.833907',
    longitude: '-81.600102',
    timeZoneId: 'America/New_York',
  },
  '31312': {
    zip: '31312',
    city: 'Guyton',
    state: 'GA',
    latitude: '32.319023',
    longitude: '-81.394389',
    timeZoneId: 'America/New_York',
  },
  '31313': {
    zip: '31313',
    city: 'Hinesville',
    state: 'GA',
    latitude: '31.843745',
    longitude: '-81.603148',
    timeZoneId: 'America/New_York',
  },
  '31314': {
    zip: '31314',
    city: 'Fort Stewart',
    state: 'GA',
    latitude: '31.866667',
    longitude: '-81.583333',
    timeZoneId: 'America/New_York',
  },
  '31315': {
    zip: '31315',
    city: 'Fort Stewart',
    state: 'GA',
    latitude: '31.887737',
    longitude: '-81.600923',
    timeZoneId: 'America/New_York',
  },
  '31316': {
    zip: '31316',
    city: 'Ludowici',
    state: 'GA',
    latitude: '31.730688',
    longitude: '-81.735664',
    timeZoneId: 'America/New_York',
  },
  '31318': {
    zip: '31318',
    city: 'Meldrim',
    state: 'GA',
    latitude: '32.142635',
    longitude: '-81.376965',
    timeZoneId: 'America/New_York',
  },
  '31319': {
    zip: '31319',
    city: 'Meridian',
    state: 'GA',
    latitude: '31.421746',
    longitude: '-81.423676',
    timeZoneId: 'America/New_York',
  },
  '31320': {
    zip: '31320',
    city: 'Midway',
    state: 'GA',
    latitude: '31.822149',
    longitude: '-81.392949',
    timeZoneId: 'America/New_York',
  },
  '31321': {
    zip: '31321',
    city: 'Pembroke',
    state: 'GA',
    latitude: '32.134586',
    longitude: '-81.628588',
    timeZoneId: 'America/New_York',
  },
  '31322': {
    zip: '31322',
    city: 'Pooler',
    state: 'GA',
    latitude: '32.117778',
    longitude: '-81.252115',
    timeZoneId: 'America/New_York',
  },
  '31323': {
    zip: '31323',
    city: 'Riceboro',
    state: 'GA',
    latitude: '31.726286',
    longitude: '-81.469983',
    timeZoneId: 'America/New_York',
  },
  '31324': {
    zip: '31324',
    city: 'Richmond Hill',
    state: 'GA',
    latitude: '31.875724',
    longitude: '-81.284127',
    timeZoneId: 'America/New_York',
  },
  '31326': {
    zip: '31326',
    city: 'Rincon',
    state: 'GA',
    latitude: '32.283779',
    longitude: '-81.221548',
    timeZoneId: 'America/New_York',
  },
  '31327': {
    zip: '31327',
    city: 'Sapelo Island',
    state: 'GA',
    latitude: '31.441253',
    longitude: '-81.26423',
    timeZoneId: 'America/New_York',
  },
  '31328': {
    zip: '31328',
    city: 'Tybee Island',
    state: 'GA',
    latitude: '32.005769',
    longitude: '-80.851185',
    timeZoneId: 'America/New_York',
  },
  '31329': {
    zip: '31329',
    city: 'Springfield',
    state: 'GA',
    latitude: '32.369473',
    longitude: '-81.309239',
    timeZoneId: 'America/New_York',
  },
  '31331': {
    zip: '31331',
    city: 'Townsend',
    state: 'GA',
    latitude: '31.380744',
    longitude: '-81.423097',
    timeZoneId: 'America/New_York',
  },
  '31333': {
    zip: '31333',
    city: 'Walthourville',
    state: 'GA',
    latitude: '31.774554',
    longitude: '-81.633207',
    timeZoneId: 'America/New_York',
  },
  '31401': {
    zip: '31401',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.07092',
    longitude: '-81.105661',
    timeZoneId: 'America/New_York',
  },
  '31402': {
    zip: '31402',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.095395',
    longitude: '-81.334504',
    timeZoneId: 'America/New_York',
  },
  '31403': {
    zip: '31403',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.043785',
    longitude: '-81.111925',
    timeZoneId: 'America/New_York',
  },
  '31404': {
    zip: '31404',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.044042',
    longitude: '-81.068991',
    timeZoneId: 'America/New_York',
  },
  '31405': {
    zip: '31405',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.043073',
    longitude: '-81.132144',
    timeZoneId: 'America/New_York',
  },
  '31406': {
    zip: '31406',
    city: 'Savannah',
    state: 'GA',
    latitude: '31.99324',
    longitude: '-81.095484',
    timeZoneId: 'America/New_York',
  },
  '31407': {
    zip: '31407',
    city: 'Port Wentworth',
    state: 'GA',
    latitude: '32.16666',
    longitude: '-81.17274',
    timeZoneId: 'America/New_York',
  },
  '31408': {
    zip: '31408',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.172908',
    longitude: '-81.206224',
    timeZoneId: 'America/New_York',
  },
  '31409': {
    zip: '31409',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.02251',
    longitude: '-81.12857',
    timeZoneId: 'America/New_York',
  },
  '31410': {
    zip: '31410',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.013342',
    longitude: '-80.953521',
    timeZoneId: 'America/New_York',
  },
  '31411': {
    zip: '31411',
    city: 'Savannah',
    state: 'GA',
    latitude: '31.942043',
    longitude: '-81.033866',
    timeZoneId: 'America/New_York',
  },
  '31412': {
    zip: '31412',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.076536',
    longitude: '-81.091718',
    timeZoneId: 'America/New_York',
  },
  '31414': {
    zip: '31414',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.049448',
    longitude: '-81.050393',
    timeZoneId: 'America/New_York',
  },
  '31415': {
    zip: '31415',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.075555',
    longitude: '-81.129128',
    timeZoneId: 'America/New_York',
  },
  '31416': {
    zip: '31416',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.004398',
    longitude: '-81.09504',
    timeZoneId: 'America/New_York',
  },
  '31418': {
    zip: '31418',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.150637',
    longitude: '-81.194558',
    timeZoneId: 'America/New_York',
  },
  '31419': {
    zip: '31419',
    city: 'Savannah',
    state: 'GA',
    latitude: '31.979874',
    longitude: '-81.180758',
    timeZoneId: 'America/New_York',
  },
  '31420': {
    zip: '31420',
    city: 'Savannah',
    state: 'GA',
    latitude: '31.979375',
    longitude: '-81.145823',
    timeZoneId: 'America/New_York',
  },
  '31421': {
    zip: '31421',
    city: 'Savannah',
    state: 'GA',
    latitude: '32.082183',
    longitude: '-81.123407',
    timeZoneId: 'America/New_York',
  },
  '31501': {
    zip: '31501',
    city: 'Waycross',
    state: 'GA',
    latitude: '31.22354',
    longitude: '-82.355739',
    timeZoneId: 'America/New_York',
  },
  '31502': {
    zip: '31502',
    city: 'Waycross',
    state: 'GA',
    latitude: '31.210635',
    longitude: '-82.359537',
    timeZoneId: 'America/New_York',
  },
  '31503': {
    zip: '31503',
    city: 'Waycross',
    state: 'GA',
    latitude: '31.218893',
    longitude: '-82.373503',
    timeZoneId: 'America/New_York',
  },
  '31510': {
    zip: '31510',
    city: 'Alma',
    state: 'GA',
    latitude: '31.539831',
    longitude: '-82.464187',
    timeZoneId: 'America/New_York',
  },
  '31512': {
    zip: '31512',
    city: 'Ambrose',
    state: 'GA',
    latitude: '31.511182',
    longitude: '-83.016275',
    timeZoneId: 'America/New_York',
  },
  '31513': {
    zip: '31513',
    city: 'Baxley',
    state: 'GA',
    latitude: '31.772585',
    longitude: '-82.351151',
    timeZoneId: 'America/New_York',
  },
  '31515': {
    zip: '31515',
    city: 'Baxley',
    state: 'GA',
    latitude: '31.776021',
    longitude: '-82.350157',
    timeZoneId: 'America/New_York',
  },
  '31516': {
    zip: '31516',
    city: 'Blackshear',
    state: 'GA',
    latitude: '31.299595',
    longitude: '-82.25518',
    timeZoneId: 'America/New_York',
  },
  '31518': {
    zip: '31518',
    city: 'Bristol',
    state: 'GA',
    latitude: '31.41833',
    longitude: '-82.265423',
    timeZoneId: 'America/New_York',
  },
  '31519': {
    zip: '31519',
    city: 'Broxton',
    state: 'GA',
    latitude: '31.627081',
    longitude: '-82.891582',
    timeZoneId: 'America/New_York',
  },
  '31520': {
    zip: '31520',
    city: 'Brunswick',
    state: 'GA',
    latitude: '31.178679',
    longitude: '-81.489022',
    timeZoneId: 'America/New_York',
  },
  '31521': {
    zip: '31521',
    city: 'Brunswick',
    state: 'GA',
    latitude: '31.158959',
    longitude: '-81.485895',
    timeZoneId: 'America/New_York',
  },
  '31522': {
    zip: '31522',
    city: 'Saint Simons Island',
    state: 'GA',
    latitude: '31.165091',
    longitude: '-81.3847',
    timeZoneId: 'America/New_York',
  },
  '31523': {
    zip: '31523',
    city: 'Brunswick',
    state: 'GA',
    latitude: '31.174003',
    longitude: '-81.501377',
    timeZoneId: 'America/New_York',
  },
  '31524': {
    zip: '31524',
    city: 'Brunswick',
    state: 'GA',
    latitude: '31.14401',
    longitude: '-81.469037',
    timeZoneId: 'America/New_York',
  },
  '31525': {
    zip: '31525',
    city: 'Brunswick',
    state: 'GA',
    latitude: '31.233616',
    longitude: '-81.501727',
    timeZoneId: 'America/New_York',
  },
  '31527': {
    zip: '31527',
    city: 'Jekyll Island',
    state: 'GA',
    latitude: '31.071521',
    longitude: '-81.407896',
    timeZoneId: 'America/New_York',
  },
  '31532': {
    zip: '31532',
    city: 'Denton',
    state: 'GA',
    latitude: '31.783139',
    longitude: '-82.658447',
    timeZoneId: 'America/New_York',
  },
  '31533': {
    zip: '31533',
    city: 'Douglas',
    state: 'GA',
    latitude: '31.508915',
    longitude: '-82.849891',
    timeZoneId: 'America/New_York',
  },
  '31534': {
    zip: '31534',
    city: 'Douglas',
    state: 'GA',
    latitude: '31.50604',
    longitude: '-82.849856',
    timeZoneId: 'America/New_York',
  },
  '31535': {
    zip: '31535',
    city: 'Douglas',
    state: 'GA',
    latitude: '31.507807',
    longitude: '-82.849284',
    timeZoneId: 'America/New_York',
  },
  '31537': {
    zip: '31537',
    city: 'Folkston',
    state: 'GA',
    latitude: '30.840664',
    longitude: '-82.010728',
    timeZoneId: 'America/New_York',
  },
  '31539': {
    zip: '31539',
    city: 'Hazlehurst',
    state: 'GA',
    latitude: '31.866984',
    longitude: '-82.600623',
    timeZoneId: 'America/New_York',
  },
  '31542': {
    zip: '31542',
    city: 'Hoboken',
    state: 'GA',
    latitude: '31.185514',
    longitude: '-82.117732',
    timeZoneId: 'America/New_York',
  },
  '31543': {
    zip: '31543',
    city: 'Hortense',
    state: 'GA',
    latitude: '31.310697',
    longitude: '-81.967185',
    timeZoneId: 'America/New_York',
  },
  '31544': {
    zip: '31544',
    city: 'Jacksonville',
    state: 'GA',
    latitude: '31.836189',
    longitude: '-82.953021',
    timeZoneId: 'America/New_York',
  },
  '31545': {
    zip: '31545',
    city: 'Jesup',
    state: 'GA',
    latitude: '31.612517',
    longitude: '-81.88556',
    timeZoneId: 'America/New_York',
  },
  '31546': {
    zip: '31546',
    city: 'Jesup',
    state: 'GA',
    latitude: '31.469865',
    longitude: '-81.781564',
    timeZoneId: 'America/New_York',
  },
  '31547': {
    zip: '31547',
    city: 'Kings Bay',
    state: 'GA',
    latitude: '30.834844',
    longitude: '-81.773454',
    timeZoneId: 'America/New_York',
  },
  '31548': {
    zip: '31548',
    city: 'Kingsland',
    state: 'GA',
    latitude: '30.790654',
    longitude: '-81.711294',
    timeZoneId: 'America/New_York',
  },
  '31549': {
    zip: '31549',
    city: 'Lumber City',
    state: 'GA',
    latitude: '31.911153',
    longitude: '-82.774223',
    timeZoneId: 'America/New_York',
  },
  '31550': {
    zip: '31550',
    city: 'Manor',
    state: 'GA',
    latitude: '31.103827',
    longitude: '-82.57333',
    timeZoneId: 'America/New_York',
  },
  '31551': {
    zip: '31551',
    city: 'Mershon',
    state: 'GA',
    latitude: '31.481294',
    longitude: '-82.219597',
    timeZoneId: 'America/New_York',
  },
  '31552': {
    zip: '31552',
    city: 'Millwood',
    state: 'GA',
    latitude: '31.270467',
    longitude: '-82.638049',
    timeZoneId: 'America/New_York',
  },
  '31553': {
    zip: '31553',
    city: 'Nahunta',
    state: 'GA',
    latitude: '31.216716',
    longitude: '-81.956732',
    timeZoneId: 'America/New_York',
  },
  '31554': {
    zip: '31554',
    city: 'Nicholls',
    state: 'GA',
    latitude: '31.421121',
    longitude: '-82.59505',
    timeZoneId: 'America/New_York',
  },
  '31555': {
    zip: '31555',
    city: 'Odum',
    state: 'GA',
    latitude: '31.665775',
    longitude: '-82.014991',
    timeZoneId: 'America/New_York',
  },
  '31556': {
    zip: '31556',
    city: 'Offerman',
    state: 'GA',
    latitude: '31.406251',
    longitude: '-82.112871',
    timeZoneId: 'America/New_York',
  },
  '31557': {
    zip: '31557',
    city: 'Patterson',
    state: 'GA',
    latitude: '31.376101',
    longitude: '-82.10537',
    timeZoneId: 'America/New_York',
  },
  '31558': {
    zip: '31558',
    city: 'Saint Marys',
    state: 'GA',
    latitude: '30.754348',
    longitude: '-81.561603',
    timeZoneId: 'America/New_York',
  },
  '31560': {
    zip: '31560',
    city: 'Screven',
    state: 'GA',
    latitude: '31.486142',
    longitude: '-82.018463',
    timeZoneId: 'America/New_York',
  },
  '31561': {
    zip: '31561',
    city: 'Sea Island',
    state: 'GA',
    latitude: '31.205555',
    longitude: '-81.329738',
    timeZoneId: 'America/New_York',
  },
  '31562': {
    zip: '31562',
    city: 'Saint George',
    state: 'GA',
    latitude: '30.525772',
    longitude: '-82.037217',
    timeZoneId: 'America/New_York',
  },
  '31563': {
    zip: '31563',
    city: 'Surrency',
    state: 'GA',
    latitude: '31.664666',
    longitude: '-82.188613',
    timeZoneId: 'America/New_York',
  },
  '31564': {
    zip: '31564',
    city: 'Waresboro',
    state: 'GA',
    latitude: '31.245104',
    longitude: '-82.47529',
    timeZoneId: 'America/New_York',
  },
  '31565': {
    zip: '31565',
    city: 'Waverly',
    state: 'GA',
    latitude: '31.0473',
    longitude: '-81.5775',
    timeZoneId: 'America/New_York',
  },
  '31566': {
    zip: '31566',
    city: 'Waynesville',
    state: 'GA',
    latitude: '31.249057',
    longitude: '-81.820471',
    timeZoneId: 'America/New_York',
  },
  '31567': {
    zip: '31567',
    city: 'West Green',
    state: 'GA',
    latitude: '31.608272',
    longitude: '-82.739715',
    timeZoneId: 'America/New_York',
  },
  '31568': {
    zip: '31568',
    city: 'White Oak',
    state: 'GA',
    latitude: '31.106278',
    longitude: '-81.72575',
    timeZoneId: 'America/New_York',
  },
  '31569': {
    zip: '31569',
    city: 'Woodbine',
    state: 'GA',
    latitude: '30.900325',
    longitude: '-81.667732',
    timeZoneId: 'America/New_York',
  },
  '31598': {
    zip: '31598',
    city: 'Jesup',
    state: 'GA',
    latitude: '31.506075',
    longitude: '-81.82296',
    timeZoneId: 'America/New_York',
  },
  '31599': {
    zip: '31599',
    city: 'Jesup',
    state: 'GA',
    latitude: '31.478461',
    longitude: '-81.802265',
    timeZoneId: 'America/New_York',
  },
  '31601': {
    zip: '31601',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.825593',
    longitude: '-83.284447',
    timeZoneId: 'America/New_York',
  },
  '31602': {
    zip: '31602',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.869486',
    longitude: '-83.287532',
    timeZoneId: 'America/New_York',
  },
  '31603': {
    zip: '31603',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.872061',
    longitude: '-83.285374',
    timeZoneId: 'America/New_York',
  },
  '31604': {
    zip: '31604',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.881108',
    longitude: '-83.28047',
    timeZoneId: 'America/New_York',
  },
  '31605': {
    zip: '31605',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.934844',
    longitude: '-83.221011',
    timeZoneId: 'America/New_York',
  },
  '31606': {
    zip: '31606',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.81289',
    longitude: '-83.166477',
    timeZoneId: 'America/New_York',
  },
  '31620': {
    zip: '31620',
    city: 'Adel',
    state: 'GA',
    latitude: '31.136179',
    longitude: '-83.426493',
    timeZoneId: 'America/New_York',
  },
  '31622': {
    zip: '31622',
    city: 'Alapaha',
    state: 'GA',
    latitude: '31.394482',
    longitude: '-83.198172',
    timeZoneId: 'America/New_York',
  },
  '31623': {
    zip: '31623',
    city: 'Argyle',
    state: 'GA',
    latitude: '31.065563',
    longitude: '-82.759949',
    timeZoneId: 'America/New_York',
  },
  '31624': {
    zip: '31624',
    city: 'Axson',
    state: 'GA',
    latitude: '31.289599',
    longitude: '-82.720752',
    timeZoneId: 'America/New_York',
  },
  '31625': {
    zip: '31625',
    city: 'Barney',
    state: 'GA',
    latitude: '31.002216',
    longitude: '-83.51924',
    timeZoneId: 'America/New_York',
  },
  '31626': {
    zip: '31626',
    city: 'Boston',
    state: 'GA',
    latitude: '30.791561',
    longitude: '-83.786193',
    timeZoneId: 'America/New_York',
  },
  '31627': {
    zip: '31627',
    city: 'Cecil',
    state: 'GA',
    latitude: '31.109433',
    longitude: '-83.419618',
    timeZoneId: 'America/New_York',
  },
  '31629': {
    zip: '31629',
    city: 'Dixie',
    state: 'GA',
    latitude: '30.745881',
    longitude: '-83.679723',
    timeZoneId: 'America/New_York',
  },
  '31630': {
    zip: '31630',
    city: 'Du Pont',
    state: 'GA',
    latitude: '30.947953',
    longitude: '-82.880111',
    timeZoneId: 'America/New_York',
  },
  '31631': {
    zip: '31631',
    city: 'Fargo',
    state: 'GA',
    latitude: '30.787206',
    longitude: '-82.610697',
    timeZoneId: 'America/New_York',
  },
  '31632': {
    zip: '31632',
    city: 'Hahira',
    state: 'GA',
    latitude: '30.98094',
    longitude: '-83.370202',
    timeZoneId: 'America/New_York',
  },
  '31634': {
    zip: '31634',
    city: 'Homerville',
    state: 'GA',
    latitude: '31.037475',
    longitude: '-82.752207',
    timeZoneId: 'America/New_York',
  },
  '31635': {
    zip: '31635',
    city: 'Lakeland',
    state: 'GA',
    latitude: '31.039688',
    longitude: '-83.071992',
    timeZoneId: 'America/New_York',
  },
  '31636': {
    zip: '31636',
    city: 'Lake Park',
    state: 'GA',
    latitude: '30.6871',
    longitude: '-83.20203',
    timeZoneId: 'America/New_York',
  },
  '31637': {
    zip: '31637',
    city: 'Lenox',
    state: 'GA',
    latitude: '31.268413',
    longitude: '-83.464912',
    timeZoneId: 'America/New_York',
  },
  '31638': {
    zip: '31638',
    city: 'Morven',
    state: 'GA',
    latitude: '30.903326',
    longitude: '-83.496047',
    timeZoneId: 'America/New_York',
  },
  '31639': {
    zip: '31639',
    city: 'Nashville',
    state: 'GA',
    latitude: '31.202158',
    longitude: '-83.237799',
    timeZoneId: 'America/New_York',
  },
  '31641': {
    zip: '31641',
    city: 'Naylor',
    state: 'GA',
    latitude: '31.044144',
    longitude: '-83.075482',
    timeZoneId: 'America/New_York',
  },
  '31642': {
    zip: '31642',
    city: 'Pearson',
    state: 'GA',
    latitude: '31.300424',
    longitude: '-82.854854',
    timeZoneId: 'America/New_York',
  },
  '31643': {
    zip: '31643',
    city: 'Quitman',
    state: 'GA',
    latitude: '30.785137',
    longitude: '-83.55762',
    timeZoneId: 'America/New_York',
  },
  '31645': {
    zip: '31645',
    city: 'Ray City',
    state: 'GA',
    latitude: '31.073974',
    longitude: '-83.195973',
    timeZoneId: 'America/New_York',
  },
  '31647': {
    zip: '31647',
    city: 'Sparks',
    state: 'GA',
    latitude: '31.179638',
    longitude: '-83.434243',
    timeZoneId: 'America/New_York',
  },
  '31648': {
    zip: '31648',
    city: 'Statenville',
    state: 'GA',
    latitude: '30.707394',
    longitude: '-82.849717',
    timeZoneId: 'America/New_York',
  },
  '31649': {
    zip: '31649',
    city: 'Stockton',
    state: 'GA',
    latitude: '31.029294',
    longitude: '-83.008366',
    timeZoneId: 'America/New_York',
  },
  '31650': {
    zip: '31650',
    city: 'Willacoochee',
    state: 'GA',
    latitude: '31.337658',
    longitude: '-83.051055',
    timeZoneId: 'America/New_York',
  },
  '31698': {
    zip: '31698',
    city: 'Valdosta',
    state: 'GA',
    latitude: '30.865739',
    longitude: '-83.288419',
    timeZoneId: 'America/New_York',
  },
  '31699': {
    zip: '31699',
    city: 'Moody AFB',
    state: 'GA',
    latitude: '30.971019',
    longitude: '-83.202986',
    timeZoneId: 'America/New_York',
  },
  '31701': {
    zip: '31701',
    city: 'Albany',
    state: 'GA',
    latitude: '31.572819',
    longitude: '-84.163287',
    timeZoneId: 'America/New_York',
  },
  '31702': {
    zip: '31702',
    city: 'Albany',
    state: 'GA',
    latitude: '31.598251',
    longitude: '-84.199958',
    timeZoneId: 'America/New_York',
  },
  '31703': {
    zip: '31703',
    city: 'Albany',
    state: 'GA',
    latitude: '31.577073',
    longitude: '-84.153068',
    timeZoneId: 'America/New_York',
  },
  '31704': {
    zip: '31704',
    city: 'Albany',
    state: 'GA',
    latitude: '31.553595',
    longitude: '-84.051925',
    timeZoneId: 'America/New_York',
  },
  '31705': {
    zip: '31705',
    city: 'Albany',
    state: 'GA',
    latitude: '31.558731',
    longitude: '-84.090313',
    timeZoneId: 'America/New_York',
  },
  '31706': {
    zip: '31706',
    city: 'Albany',
    state: 'GA',
    latitude: '31.579078',
    longitude: '-84.156243',
    timeZoneId: 'America/New_York',
  },
  '31707': {
    zip: '31707',
    city: 'Albany',
    state: 'GA',
    latitude: '31.580916',
    longitude: '-84.22168',
    timeZoneId: 'America/New_York',
  },
  '31708': {
    zip: '31708',
    city: 'Albany',
    state: 'GA',
    latitude: '31.593388',
    longitude: '-84.141048',
    timeZoneId: 'America/New_York',
  },
  '31709': {
    zip: '31709',
    city: 'Americus',
    state: 'GA',
    latitude: '32.0725',
    longitude: '-84.225141',
    timeZoneId: 'America/New_York',
  },
  '31711': {
    zip: '31711',
    city: 'Andersonville',
    state: 'GA',
    latitude: '32.195044',
    longitude: '-84.145508',
    timeZoneId: 'America/New_York',
  },
  '31712': {
    zip: '31712',
    city: 'Arabi',
    state: 'GA',
    latitude: '31.839748',
    longitude: '-83.725125',
    timeZoneId: 'America/New_York',
  },
  '31714': {
    zip: '31714',
    city: 'Ashburn',
    state: 'GA',
    latitude: '31.701024',
    longitude: '-83.654748',
    timeZoneId: 'America/New_York',
  },
  '31716': {
    zip: '31716',
    city: 'Baconton',
    state: 'GA',
    latitude: '31.374218',
    longitude: '-84.161186',
    timeZoneId: 'America/New_York',
  },
  '31719': {
    zip: '31719',
    city: 'Americus',
    state: 'GA',
    latitude: '32.075442',
    longitude: '-84.223306',
    timeZoneId: 'America/New_York',
  },
  '31720': {
    zip: '31720',
    city: 'Barwick',
    state: 'GA',
    latitude: '30.899369',
    longitude: '-83.740513',
    timeZoneId: 'America/New_York',
  },
  '31721': {
    zip: '31721',
    city: 'Albany',
    state: 'GA',
    latitude: '31.577688',
    longitude: '-84.220227',
    timeZoneId: 'America/New_York',
  },
  '31722': {
    zip: '31722',
    city: 'Berlin',
    state: 'GA',
    latitude: '31.126702',
    longitude: '-83.70301',
    timeZoneId: 'America/New_York',
  },
  '31727': {
    zip: '31727',
    city: 'Brookfield',
    state: 'GA',
    latitude: '31.44049',
    longitude: '-83.50279',
    timeZoneId: 'America/New_York',
  },
  '31730': {
    zip: '31730',
    city: 'Camilla',
    state: 'GA',
    latitude: '31.231372',
    longitude: '-84.207215',
    timeZoneId: 'America/New_York',
  },
  '31733': {
    zip: '31733',
    city: 'Chula',
    state: 'GA',
    latitude: '31.546387',
    longitude: '-83.555566',
    timeZoneId: 'America/New_York',
  },
  '31735': {
    zip: '31735',
    city: 'Cobb',
    state: 'GA',
    latitude: '31.964001',
    longitude: '-83.968585',
    timeZoneId: 'America/New_York',
  },
  '31738': {
    zip: '31738',
    city: 'Coolidge',
    state: 'GA',
    latitude: '31.033206',
    longitude: '-83.856301',
    timeZoneId: 'America/New_York',
  },
  '31739': {
    zip: '31739',
    city: 'Cotton',
    state: 'GA',
    latitude: '31.12337',
    longitude: '-84.152676',
    timeZoneId: 'America/New_York',
  },
  '31743': {
    zip: '31743',
    city: 'De Soto',
    state: 'GA',
    latitude: '31.974383',
    longitude: '-84.028237',
    timeZoneId: 'America/New_York',
  },
  '31744': {
    zip: '31744',
    city: 'Doerun',
    state: 'GA',
    latitude: '31.314801',
    longitude: '-83.920494',
    timeZoneId: 'America/New_York',
  },
  '31747': {
    zip: '31747',
    city: 'Ellenton',
    state: 'GA',
    latitude: '31.186638',
    longitude: '-83.746826',
    timeZoneId: 'America/New_York',
  },
  '31749': {
    zip: '31749',
    city: 'Enigma',
    state: 'GA',
    latitude: '31.365365',
    longitude: '-83.346313',
    timeZoneId: 'America/New_York',
  },
  '31750': {
    zip: '31750',
    city: 'Fitzgerald',
    state: 'GA',
    latitude: '31.71499',
    longitude: '-83.253233',
    timeZoneId: 'America/New_York',
  },
  '31753': {
    zip: '31753',
    city: 'Funston',
    state: 'GA',
    latitude: '31.213388',
    longitude: '-83.968407',
    timeZoneId: 'America/New_York',
  },
  '31756': {
    zip: '31756',
    city: 'Hartsfield',
    state: 'GA',
    latitude: '31.215276',
    longitude: '-83.970568',
    timeZoneId: 'America/New_York',
  },
  '31757': {
    zip: '31757',
    city: 'Thomasville',
    state: 'GA',
    latitude: '30.865689',
    longitude: '-83.906478',
    timeZoneId: 'America/New_York',
  },
  '31758': {
    zip: '31758',
    city: 'Thomasville',
    state: 'GA',
    latitude: '30.837608',
    longitude: '-83.978538',
    timeZoneId: 'America/New_York',
  },
  '31760': {
    zip: '31760',
    city: 'Irwinville',
    state: 'GA',
    latitude: '31.656637',
    longitude: '-83.410124',
    timeZoneId: 'America/New_York',
  },
  '31763': {
    zip: '31763',
    city: 'Leesburg',
    state: 'GA',
    latitude: '31.67669',
    longitude: '-84.145973',
    timeZoneId: 'America/New_York',
  },
  '31764': {
    zip: '31764',
    city: 'Leslie',
    state: 'GA',
    latitude: '31.95431',
    longitude: '-84.083715',
    timeZoneId: 'America/New_York',
  },
  '31765': {
    zip: '31765',
    city: 'Meigs',
    state: 'GA',
    latitude: '31.06068',
    longitude: '-84.0795',
    timeZoneId: 'America/New_York',
  },
  '31768': {
    zip: '31768',
    city: 'Moultrie',
    state: 'GA',
    latitude: '31.171229',
    longitude: '-83.782941',
    timeZoneId: 'America/New_York',
  },
  '31769': {
    zip: '31769',
    city: 'Mystic',
    state: 'GA',
    latitude: '31.563068',
    longitude: '-83.270421',
    timeZoneId: 'America/New_York',
  },
  '31771': {
    zip: '31771',
    city: 'Norman Park',
    state: 'GA',
    latitude: '31.239723',
    longitude: '-83.715199',
    timeZoneId: 'America/New_York',
  },
  '31772': {
    zip: '31772',
    city: 'Oakfield',
    state: 'GA',
    latitude: '31.787275',
    longitude: '-83.962687',
    timeZoneId: 'America/New_York',
  },
  '31773': {
    zip: '31773',
    city: 'Ochlocknee',
    state: 'GA',
    latitude: '30.852812',
    longitude: '-84.205045',
    timeZoneId: 'America/New_York',
  },
  '31774': {
    zip: '31774',
    city: 'Ocilla',
    state: 'GA',
    latitude: '31.595052',
    longitude: '-83.252522',
    timeZoneId: 'America/New_York',
  },
  '31775': {
    zip: '31775',
    city: 'Omega',
    state: 'GA',
    latitude: '31.380728',
    longitude: '-83.582679',
    timeZoneId: 'America/New_York',
  },
  '31776': {
    zip: '31776',
    city: 'Moultrie',
    state: 'GA',
    latitude: '31.155653',
    longitude: '-83.793903',
    timeZoneId: 'America/New_York',
  },
  '31778': {
    zip: '31778',
    city: 'Pavo',
    state: 'GA',
    latitude: '30.956101',
    longitude: '-83.738121',
    timeZoneId: 'America/New_York',
  },
  '31779': {
    zip: '31779',
    city: 'Pelham',
    state: 'GA',
    latitude: '31.125607',
    longitude: '-84.15463',
    timeZoneId: 'America/New_York',
  },
  '31780': {
    zip: '31780',
    city: 'Plains',
    state: 'GA',
    latitude: '32.029302',
    longitude: '-84.39314',
    timeZoneId: 'America/New_York',
  },
  '31781': {
    zip: '31781',
    city: 'Poulan',
    state: 'GA',
    latitude: '31.514345',
    longitude: '-83.7908',
    timeZoneId: 'America/New_York',
  },
  '31782': {
    zip: '31782',
    city: 'Putney',
    state: 'GA',
    latitude: '31.465321',
    longitude: '-83.983681',
    timeZoneId: 'America/New_York',
  },
  '31783': {
    zip: '31783',
    city: 'Rebecca',
    state: 'GA',
    latitude: '31.784845',
    longitude: '-83.532927',
    timeZoneId: 'America/New_York',
  },
  '31784': {
    zip: '31784',
    city: 'Sale City',
    state: 'GA',
    latitude: '31.254991',
    longitude: '-84.042695',
    timeZoneId: 'America/New_York',
  },
  '31787': {
    zip: '31787',
    city: 'Smithville',
    state: 'GA',
    latitude: '31.904581',
    longitude: '-84.249811',
    timeZoneId: 'America/New_York',
  },
  '31788': {
    zip: '31788',
    city: 'Moultrie',
    state: 'GA',
    latitude: '31.171588',
    longitude: '-83.788569',
    timeZoneId: 'America/New_York',
  },
  '31789': {
    zip: '31789',
    city: 'Sumner',
    state: 'GA',
    latitude: '31.542195',
    longitude: '-83.718349',
    timeZoneId: 'America/New_York',
  },
  '31790': {
    zip: '31790',
    city: 'Sycamore',
    state: 'GA',
    latitude: '31.655399',
    longitude: '-83.59203',
    timeZoneId: 'America/New_York',
  },
  '31791': {
    zip: '31791',
    city: 'Sylvester',
    state: 'GA',
    latitude: '31.531174',
    longitude: '-83.842342',
    timeZoneId: 'America/New_York',
  },
  '31792': {
    zip: '31792',
    city: 'Thomasville',
    state: 'GA',
    latitude: '30.836835',
    longitude: '-83.972588',
    timeZoneId: 'America/New_York',
  },
  '31793': {
    zip: '31793',
    city: 'Tifton',
    state: 'GA',
    latitude: '31.465874',
    longitude: '-83.505876',
    timeZoneId: 'America/New_York',
  },
  '31794': {
    zip: '31794',
    city: 'Tifton',
    state: 'GA',
    latitude: '31.460118',
    longitude: '-83.509086',
    timeZoneId: 'America/New_York',
  },
  '31795': {
    zip: '31795',
    city: 'Ty Ty',
    state: 'GA',
    latitude: '31.469259',
    longitude: '-83.540717',
    timeZoneId: 'America/New_York',
  },
  '31796': {
    zip: '31796',
    city: 'Warwick',
    state: 'GA',
    latitude: '31.829982',
    longitude: '-83.919589',
    timeZoneId: 'America/New_York',
  },
  '31798': {
    zip: '31798',
    city: 'Wray',
    state: 'GA',
    latitude: '31.595382',
    longitude: '-83.109035',
    timeZoneId: 'America/New_York',
  },
  '31799': {
    zip: '31799',
    city: 'Thomasville',
    state: 'GA',
    latitude: '30.837613',
    longitude: '-83.978012',
    timeZoneId: 'America/New_York',
  },
  '31801': {
    zip: '31801',
    city: 'Box Springs',
    state: 'GA',
    latitude: '32.57082',
    longitude: '-84.62172',
    timeZoneId: 'America/New_York',
  },
  '31803': {
    zip: '31803',
    city: 'Buena Vista',
    state: 'GA',
    latitude: '32.312777',
    longitude: '-84.51137',
    timeZoneId: 'America/New_York',
  },
  '31804': {
    zip: '31804',
    city: 'Cataula',
    state: 'GA',
    latitude: '32.640908',
    longitude: '-84.90201',
    timeZoneId: 'America/New_York',
  },
  '31805': {
    zip: '31805',
    city: 'Cusseta',
    state: 'GA',
    latitude: '32.286885',
    longitude: '-84.781146',
    timeZoneId: 'America/New_York',
  },
  '31806': {
    zip: '31806',
    city: 'Ellaville',
    state: 'GA',
    latitude: '32.238191',
    longitude: '-84.308815',
    timeZoneId: 'America/New_York',
  },
  '31807': {
    zip: '31807',
    city: 'Ellerslie',
    state: 'GA',
    latitude: '32.633465',
    longitude: '-84.791841',
    timeZoneId: 'America/New_York',
  },
  '31808': {
    zip: '31808',
    city: 'Fortson',
    state: 'GA',
    latitude: '32.595608',
    longitude: '-84.970919',
    timeZoneId: 'America/New_York',
  },
  '31810': {
    zip: '31810',
    city: 'Geneva',
    state: 'GA',
    latitude: '32.586218',
    longitude: '-84.619383',
    timeZoneId: 'America/New_York',
  },
  '31811': {
    zip: '31811',
    city: 'Hamilton',
    state: 'GA',
    latitude: '32.726249',
    longitude: '-84.994153',
    timeZoneId: 'America/New_York',
  },
  '31812': {
    zip: '31812',
    city: 'Junction City',
    state: 'GA',
    latitude: '32.602308',
    longitude: '-84.457842',
    timeZoneId: 'America/New_York',
  },
  '31814': {
    zip: '31814',
    city: 'Louvale',
    state: 'GA',
    latitude: '32.145716',
    longitude: '-84.901377',
    timeZoneId: 'America/New_York',
  },
  '31815': {
    zip: '31815',
    city: 'Lumpkin',
    state: 'GA',
    latitude: '32.052799',
    longitude: '-84.794747',
    timeZoneId: 'America/New_York',
  },
  '31816': {
    zip: '31816',
    city: 'Manchester',
    state: 'GA',
    latitude: '32.856189',
    longitude: '-84.621217',
    timeZoneId: 'America/New_York',
  },
  '31820': {
    zip: '31820',
    city: 'Midland',
    state: 'GA',
    latitude: '32.560011',
    longitude: '-84.843488',
    timeZoneId: 'America/New_York',
  },
  '31821': {
    zip: '31821',
    city: 'Omaha',
    state: 'GA',
    latitude: '32.145985',
    longitude: '-84.904327',
    timeZoneId: 'America/New_York',
  },
  '31822': {
    zip: '31822',
    city: 'Pine Mountain',
    state: 'GA',
    latitude: '32.898928',
    longitude: '-84.925175',
    timeZoneId: 'America/New_York',
  },
  '31823': {
    zip: '31823',
    city: 'Pine Mountain Valley',
    state: 'GA',
    latitude: '32.784644',
    longitude: '-84.823456',
    timeZoneId: 'America/New_York',
  },
  '31824': {
    zip: '31824',
    city: 'Preston',
    state: 'GA',
    latitude: '32.08119',
    longitude: '-84.544935',
    timeZoneId: 'America/New_York',
  },
  '31825': {
    zip: '31825',
    city: 'Richland',
    state: 'GA',
    latitude: '32.090514',
    longitude: '-84.66391',
    timeZoneId: 'America/New_York',
  },
  '31826': {
    zip: '31826',
    city: 'Shiloh',
    state: 'GA',
    latitude: '32.798553',
    longitude: '-84.744824',
    timeZoneId: 'America/New_York',
  },
  '31827': {
    zip: '31827',
    city: 'Talbotton',
    state: 'GA',
    latitude: '32.701566',
    longitude: '-84.487321',
    timeZoneId: 'America/New_York',
  },
  '31829': {
    zip: '31829',
    city: 'Upatoi',
    state: 'GA',
    latitude: '32.56374',
    longitude: '-84.73932',
    timeZoneId: 'America/New_York',
  },
  '31830': {
    zip: '31830',
    city: 'Warm Springs',
    state: 'GA',
    latitude: '32.887296',
    longitude: '-84.688156',
    timeZoneId: 'America/New_York',
  },
  '31831': {
    zip: '31831',
    city: 'Waverly Hall',
    state: 'GA',
    latitude: '32.692717',
    longitude: '-84.743192',
    timeZoneId: 'America/New_York',
  },
  '31832': {
    zip: '31832',
    city: 'Weston',
    state: 'GA',
    latitude: '31.958888',
    longitude: '-84.567148',
    timeZoneId: 'America/New_York',
  },
  '31833': {
    zip: '31833',
    city: 'West Point',
    state: 'GA',
    latitude: '32.887649',
    longitude: '-85.138122',
    timeZoneId: 'America/New_York',
  },
  '31836': {
    zip: '31836',
    city: 'Woodland',
    state: 'GA',
    latitude: '32.793372',
    longitude: '-84.57193',
    timeZoneId: 'America/New_York',
  },
  '31901': {
    zip: '31901',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.474185',
    longitude: '-84.977932',
    timeZoneId: 'America/New_York',
  },
  '31902': {
    zip: '31902',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.500152',
    longitude: '-84.973827',
    timeZoneId: 'America/New_York',
  },
  '31903': {
    zip: '31903',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.425356',
    longitude: '-84.945749',
    timeZoneId: 'America/New_York',
  },
  '31904': {
    zip: '31904',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.520024',
    longitude: '-84.981542',
    timeZoneId: 'America/New_York',
  },
  '31905': {
    zip: '31905',
    city: 'Fort Benning',
    state: 'GA',
    latitude: '32.43012',
    longitude: '-84.91818',
    timeZoneId: 'America/New_York',
  },
  '31906': {
    zip: '31906',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.470086',
    longitude: '-84.953402',
    timeZoneId: 'America/New_York',
  },
  '31907': {
    zip: '31907',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.477304',
    longitude: '-84.903608',
    timeZoneId: 'America/New_York',
  },
  '31908': {
    zip: '31908',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.531133',
    longitude: '-84.938045',
    timeZoneId: 'America/New_York',
  },
  '31909': {
    zip: '31909',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.534042',
    longitude: '-84.92471',
    timeZoneId: 'America/New_York',
  },
  '31914': {
    zip: '31914',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.5562',
    longitude: '-85.005558',
    timeZoneId: 'America/New_York',
  },
  '31917': {
    zip: '31917',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.484084',
    longitude: '-84.828966',
    timeZoneId: 'America/New_York',
  },
  '31993': {
    zip: '31993',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.481702',
    longitude: '-84.976028',
    timeZoneId: 'America/New_York',
  },
  '31995': {
    zip: '31995',
    city: 'Fort Benning',
    state: 'GA',
    latitude: '32.489923',
    longitude: '-84.969539',
    timeZoneId: 'America/New_York',
  },
  '31997': {
    zip: '31997',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.485272',
    longitude: '-84.830872',
    timeZoneId: 'America/New_York',
  },
  '31998': {
    zip: '31998',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.468275',
    longitude: '-84.949281',
    timeZoneId: 'America/New_York',
  },
  '31999': {
    zip: '31999',
    city: 'Columbus',
    state: 'GA',
    latitude: '32.457882',
    longitude: '-84.988344',
    timeZoneId: 'America/New_York',
  },
  '32003': {
    zip: '32003',
    city: 'Fleming Island',
    state: 'FL',
    latitude: '30.106435',
    longitude: '-81.711499',
    timeZoneId: 'America/New_York',
  },
  '32004': {
    zip: '32004',
    city: 'Ponte Vedra Beach',
    state: 'FL',
    latitude: '30.241583',
    longitude: '-81.383434',
    timeZoneId: 'America/New_York',
  },
  '32006': {
    zip: '32006',
    city: 'Fleming Island',
    state: 'FL',
    latitude: '30.157247',
    longitude: '-81.704123',
    timeZoneId: 'America/New_York',
  },
  '32007': {
    zip: '32007',
    city: 'Bostwick',
    state: 'FL',
    latitude: '29.774403',
    longitude: '-81.640224',
    timeZoneId: 'America/New_York',
  },
  '32008': {
    zip: '32008',
    city: 'Branford',
    state: 'FL',
    latitude: '30.048849',
    longitude: '-82.955713',
    timeZoneId: 'America/New_York',
  },
  '32009': {
    zip: '32009',
    city: 'Bryceville',
    state: 'FL',
    latitude: '30.410361',
    longitude: '-81.950312',
    timeZoneId: 'America/New_York',
  },
  '32011': {
    zip: '32011',
    city: 'Callahan',
    state: 'FL',
    latitude: '30.558939',
    longitude: '-81.835699',
    timeZoneId: 'America/New_York',
  },
  '32013': {
    zip: '32013',
    city: 'Day',
    state: 'FL',
    latitude: '30.186927',
    longitude: '-83.292081',
    timeZoneId: 'America/New_York',
  },
  '32024': {
    zip: '32024',
    city: 'Lake City',
    state: 'FL',
    latitude: '30.190547',
    longitude: '-82.64124',
    timeZoneId: 'America/New_York',
  },
  '32025': {
    zip: '32025',
    city: 'Lake City',
    state: 'FL',
    latitude: '30.189356',
    longitude: '-82.636764',
    timeZoneId: 'America/New_York',
  },
  '32026': {
    zip: '32026',
    city: 'Raiford',
    state: 'FL',
    latitude: '30.06247',
    longitude: '-82.236973',
    timeZoneId: 'America/New_York',
  },
  '32030': {
    zip: '32030',
    city: 'Doctors Inlet',
    state: 'FL',
    latitude: '30.105007',
    longitude: '-81.780508',
    timeZoneId: 'America/New_York',
  },
  '32033': {
    zip: '32033',
    city: 'Elkton',
    state: 'FL',
    latitude: '29.780205',
    longitude: '-81.438268',
    timeZoneId: 'America/New_York',
  },
  '32034': {
    zip: '32034',
    city: 'Fernandina Beach',
    state: 'FL',
    latitude: '30.636954',
    longitude: '-81.46134',
    timeZoneId: 'America/New_York',
  },
  '32035': {
    zip: '32035',
    city: 'Fernandina Beach',
    state: 'FL',
    latitude: '30.670498',
    longitude: '-81.462032',
    timeZoneId: 'America/New_York',
  },
  '32038': {
    zip: '32038',
    city: 'Fort White',
    state: 'FL',
    latitude: '29.92321',
    longitude: '-82.71408',
    timeZoneId: 'America/New_York',
  },
  '32040': {
    zip: '32040',
    city: 'Glen Saint Mary',
    state: 'FL',
    latitude: '30.288545',
    longitude: '-82.158561',
    timeZoneId: 'America/New_York',
  },
  '32041': {
    zip: '32041',
    city: 'Yulee',
    state: 'FL',
    latitude: '30.63597',
    longitude: '-81.573116',
    timeZoneId: 'America/New_York',
  },
  '32042': {
    zip: '32042',
    city: 'Graham',
    state: 'FL',
    latitude: '29.966711',
    longitude: '-82.122266',
    timeZoneId: 'America/New_York',
  },
  '32043': {
    zip: '32043',
    city: 'Green Cove Springs',
    state: 'FL',
    latitude: '29.958919',
    longitude: '-81.736288',
    timeZoneId: 'America/New_York',
  },
  '32044': {
    zip: '32044',
    city: 'Hampton',
    state: 'FL',
    latitude: '29.845168',
    longitude: '-82.144682',
    timeZoneId: 'America/New_York',
  },
  '32046': {
    zip: '32046',
    city: 'Hilliard',
    state: 'FL',
    latitude: '30.726368',
    longitude: '-81.88785',
    timeZoneId: 'America/New_York',
  },
  '32050': {
    zip: '32050',
    city: 'Middleburg',
    state: 'FL',
    latitude: '30.072061',
    longitude: '-81.861423',
    timeZoneId: 'America/New_York',
  },
  '32052': {
    zip: '32052',
    city: 'Jasper',
    state: 'FL',
    latitude: '30.517247',
    longitude: '-82.950317',
    timeZoneId: 'America/New_York',
  },
  '32053': {
    zip: '32053',
    city: 'Jennings',
    state: 'FL',
    latitude: '30.562359',
    longitude: '-83.247328',
    timeZoneId: 'America/New_York',
  },
  '32054': {
    zip: '32054',
    city: 'Lake Butler',
    state: 'FL',
    latitude: '30.022895',
    longitude: '-82.341708',
    timeZoneId: 'America/New_York',
  },
  '32055': {
    zip: '32055',
    city: 'Lake City',
    state: 'FL',
    latitude: '30.184011',
    longitude: '-82.634774',
    timeZoneId: 'America/New_York',
  },
  '32056': {
    zip: '32056',
    city: 'Lake City',
    state: 'FL',
    latitude: '30.19068',
    longitude: '-82.639799',
    timeZoneId: 'America/New_York',
  },
  '32058': {
    zip: '32058',
    city: 'Lawtey',
    state: 'FL',
    latitude: '30.08419',
    longitude: '-82.106221',
    timeZoneId: 'America/New_York',
  },
  '32059': {
    zip: '32059',
    city: 'Lee',
    state: 'FL',
    latitude: '30.365439',
    longitude: '-83.263804',
    timeZoneId: 'America/New_York',
  },
  '32060': {
    zip: '32060',
    city: 'Live Oak',
    state: 'FL',
    latitude: '30.24498',
    longitude: '-83.21285',
    timeZoneId: 'America/New_York',
  },
  '32061': {
    zip: '32061',
    city: 'Lulu',
    state: 'FL',
    latitude: '30.069316',
    longitude: '-82.550528',
    timeZoneId: 'America/New_York',
  },
  '32062': {
    zip: '32062',
    city: 'Mc Alpin',
    state: 'FL',
    latitude: '30.297771',
    longitude: '-82.987534',
    timeZoneId: 'America/New_York',
  },
  '32063': {
    zip: '32063',
    city: 'Macclenny',
    state: 'FL',
    latitude: '30.284132',
    longitude: '-82.127726',
    timeZoneId: 'America/New_York',
  },
  '32064': {
    zip: '32064',
    city: 'Live Oak',
    state: 'FL',
    latitude: '30.29548',
    longitude: '-82.987712',
    timeZoneId: 'America/New_York',
  },
  '32065': {
    zip: '32065',
    city: 'Orange Park',
    state: 'FL',
    latitude: '30.134405',
    longitude: '-81.774717',
    timeZoneId: 'America/New_York',
  },
  '32066': {
    zip: '32066',
    city: 'Mayo',
    state: 'FL',
    latitude: '30.055814',
    longitude: '-83.175483',
    timeZoneId: 'America/New_York',
  },
  '32067': {
    zip: '32067',
    city: 'Orange Park',
    state: 'FL',
    latitude: '30.032559',
    longitude: '-81.791988',
    timeZoneId: 'America/New_York',
  },
  '32068': {
    zip: '32068',
    city: 'Middleburg',
    state: 'FL',
    latitude: '30.092929',
    longitude: '-81.867697',
    timeZoneId: 'America/New_York',
  },
  '32071': {
    zip: '32071',
    city: 'O Brien',
    state: 'FL',
    latitude: '30.04812',
    longitude: '-82.97658',
    timeZoneId: 'America/New_York',
  },
  '32072': {
    zip: '32072',
    city: 'Olustee',
    state: 'FL',
    latitude: '30.202771',
    longitude: '-82.430793',
    timeZoneId: 'America/New_York',
  },
  '32073': {
    zip: '32073',
    city: 'Orange Park',
    state: 'FL',
    latitude: '30.174663',
    longitude: '-81.758375',
    timeZoneId: 'America/New_York',
  },
  '32079': {
    zip: '32079',
    city: 'Penney Farms',
    state: 'FL',
    latitude: '29.984314',
    longitude: '-81.808272',
    timeZoneId: 'America/New_York',
  },
  '32080': {
    zip: '32080',
    city: 'Saint Augustine',
    state: 'FL',
    latitude: '29.832017',
    longitude: '-81.272761',
    timeZoneId: 'America/New_York',
  },
  '32081': {
    zip: '32081',
    city: 'Ponte Vedra',
    state: 'FL',
    latitude: '30.173581',
    longitude: '-81.395627',
    timeZoneId: 'America/New_York',
  },
  '32082': {
    zip: '32082',
    city: 'Ponte Vedra Beach',
    state: 'FL',
    latitude: '30.173444',
    longitude: '-81.395785',
    timeZoneId: 'America/New_York',
  },
  '32083': {
    zip: '32083',
    city: 'Raiford',
    state: 'FL',
    latitude: '30.091771',
    longitude: '-82.195882',
    timeZoneId: 'America/New_York',
  },
  '32084': {
    zip: '32084',
    city: 'Saint Augustine',
    state: 'FL',
    latitude: '29.88516',
    longitude: '-81.31398',
    timeZoneId: 'America/New_York',
  },
  '32085': {
    zip: '32085',
    city: 'Saint Augustine',
    state: 'FL',
    latitude: '29.886854',
    longitude: '-81.343718',
    timeZoneId: 'America/New_York',
  },
  '32086': {
    zip: '32086',
    city: 'Saint Augustine',
    state: 'FL',
    latitude: '29.759403',
    longitude: '-81.30397',
    timeZoneId: 'America/New_York',
  },
  '32087': {
    zip: '32087',
    city: 'Sanderson',
    state: 'FL',
    latitude: '30.20415',
    longitude: '-82.427506',
    timeZoneId: 'America/New_York',
  },
  '32091': {
    zip: '32091',
    city: 'Starke',
    state: 'FL',
    latitude: '29.949586',
    longitude: '-82.112253',
    timeZoneId: 'America/New_York',
  },
  '32092': {
    zip: '32092',
    city: 'Saint Augustine',
    state: 'FL',
    latitude: '29.956369',
    longitude: '-81.524977',
    timeZoneId: 'America/New_York',
  },
  '32094': {
    zip: '32094',
    city: 'Wellborn',
    state: 'FL',
    latitude: '30.17826',
    longitude: '-82.858788',
    timeZoneId: 'America/New_York',
  },
  '32095': {
    zip: '32095',
    city: 'Saint Augustine',
    state: 'FL',
    latitude: '29.916697',
    longitude: '-81.339048',
    timeZoneId: 'America/New_York',
  },
  '32096': {
    zip: '32096',
    city: 'White Springs',
    state: 'FL',
    latitude: '30.336677',
    longitude: '-82.758442',
    timeZoneId: 'America/New_York',
  },
  '32097': {
    zip: '32097',
    city: 'Yulee',
    state: 'FL',
    latitude: '30.661181',
    longitude: '-81.63836',
    timeZoneId: 'America/New_York',
  },
  '32099': {
    zip: '32099',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.33493',
    longitude: '-81.770917',
    timeZoneId: 'America/New_York',
  },
  '32102': {
    zip: '32102',
    city: 'Astor',
    state: 'FL',
    latitude: '29.154918',
    longitude: '-81.548357',
    timeZoneId: 'America/New_York',
  },
  '32105': {
    zip: '32105',
    city: 'Barberville',
    state: 'FL',
    latitude: '29.188945',
    longitude: '-81.418844',
    timeZoneId: 'America/New_York',
  },
  '32110': {
    zip: '32110',
    city: 'Bunnell',
    state: 'FL',
    latitude: '29.466087',
    longitude: '-81.247622',
    timeZoneId: 'America/New_York',
  },
  '32111': {
    zip: '32111',
    city: 'Candler',
    state: 'FL',
    latitude: '29.164842',
    longitude: '-82.217478',
    timeZoneId: 'America/New_York',
  },
  '32112': {
    zip: '32112',
    city: 'Crescent City',
    state: 'FL',
    latitude: '29.430988',
    longitude: '-81.513718',
    timeZoneId: 'America/New_York',
  },
  '32113': {
    zip: '32113',
    city: 'Citra',
    state: 'FL',
    latitude: '29.386926',
    longitude: '-82.118936',
    timeZoneId: 'America/New_York',
  },
  '32114': {
    zip: '32114',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.198356',
    longitude: '-81.042354',
    timeZoneId: 'America/New_York',
  },
  '32115': {
    zip: '32115',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.213213',
    longitude: '-81.02377',
    timeZoneId: 'America/New_York',
  },
  '32116': {
    zip: '32116',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.212508',
    longitude: '-81.008741',
    timeZoneId: 'America/New_York',
  },
  '32117': {
    zip: '32117',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.23644',
    longitude: '-81.05778',
    timeZoneId: 'America/New_York',
  },
  '32118': {
    zip: '32118',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.207209',
    longitude: '-80.999801',
    timeZoneId: 'America/New_York',
  },
  '32119': {
    zip: '32119',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.139694',
    longitude: '-81.012938',
    timeZoneId: 'America/New_York',
  },
  '32120': {
    zip: '32120',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.195928',
    longitude: '-81.049011',
    timeZoneId: 'America/New_York',
  },
  '32121': {
    zip: '32121',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.193767',
    longitude: '-81.053922',
    timeZoneId: 'America/New_York',
  },
  '32122': {
    zip: '32122',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.145349',
    longitude: '-81.029374',
    timeZoneId: 'America/New_York',
  },
  '32123': {
    zip: '32123',
    city: 'Port Orange',
    state: 'FL',
    latitude: '29.149529',
    longitude: '-81.03118',
    timeZoneId: 'America/New_York',
  },
  '32124': {
    zip: '32124',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.092732',
    longitude: '-81.042176',
    timeZoneId: 'America/New_York',
  },
  '32125': {
    zip: '32125',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.234976',
    longitude: '-81.055403',
    timeZoneId: 'America/New_York',
  },
  '32126': {
    zip: '32126',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.225945',
    longitude: '-81.006483',
    timeZoneId: 'America/New_York',
  },
  '32127': {
    zip: '32127',
    city: 'Port Orange',
    state: 'FL',
    latitude: '29.110341',
    longitude: '-80.980002',
    timeZoneId: 'America/New_York',
  },
  '32128': {
    zip: '32128',
    city: 'Port Orange',
    state: 'FL',
    latitude: '29.13051',
    longitude: '-81.118481',
    timeZoneId: 'America/New_York',
  },
  '32129': {
    zip: '32129',
    city: 'Port Orange',
    state: 'FL',
    latitude: '29.148785',
    longitude: '-81.025137',
    timeZoneId: 'America/New_York',
  },
  '32130': {
    zip: '32130',
    city: 'De Leon Springs',
    state: 'FL',
    latitude: '29.126886',
    longitude: '-81.329806',
    timeZoneId: 'America/New_York',
  },
  '32131': {
    zip: '32131',
    city: 'East Palatka',
    state: 'FL',
    latitude: '29.664677',
    longitude: '-81.563252',
    timeZoneId: 'America/New_York',
  },
  '32132': {
    zip: '32132',
    city: 'Edgewater',
    state: 'FL',
    latitude: '28.975063',
    longitude: '-80.912136',
    timeZoneId: 'America/New_York',
  },
  '32133': {
    zip: '32133',
    city: 'Eastlake Weir',
    state: 'FL',
    latitude: '29.022775',
    longitude: '-81.907945',
    timeZoneId: 'America/New_York',
  },
  '32134': {
    zip: '32134',
    city: 'Fort Mc Coy',
    state: 'FL',
    latitude: '29.365867',
    longitude: '-81.885231',
    timeZoneId: 'America/New_York',
  },
  '32135': {
    zip: '32135',
    city: 'Palm Coast',
    state: 'FL',
    latitude: '29.545761',
    longitude: '-81.225951',
    timeZoneId: 'America/New_York',
  },
  '32136': {
    zip: '32136',
    city: 'Flagler Beach',
    state: 'FL',
    latitude: '29.449219',
    longitude: '-81.119771',
    timeZoneId: 'America/New_York',
  },
  '32137': {
    zip: '32137',
    city: 'Palm Coast',
    state: 'FL',
    latitude: '29.54147',
    longitude: '-81.226479',
    timeZoneId: 'America/New_York',
  },
  '32138': {
    zip: '32138',
    city: 'Grandin',
    state: 'FL',
    latitude: '29.724316',
    longitude: '-81.919683',
    timeZoneId: 'America/New_York',
  },
  '32139': {
    zip: '32139',
    city: 'Georgetown',
    state: 'FL',
    latitude: '29.417831',
    longitude: '-81.584045',
    timeZoneId: 'America/New_York',
  },
  '32140': {
    zip: '32140',
    city: 'Florahome',
    state: 'FL',
    latitude: '29.760177',
    longitude: '-81.882535',
    timeZoneId: 'America/New_York',
  },
  '32141': {
    zip: '32141',
    city: 'Edgewater',
    state: 'FL',
    latitude: '28.940925',
    longitude: '-80.894574',
    timeZoneId: 'America/New_York',
  },
  '32142': {
    zip: '32142',
    city: 'Palm Coast',
    state: 'FL',
    latitude: '29.480136',
    longitude: '-81.216354',
    timeZoneId: 'America/New_York',
  },
  '32143': {
    zip: '32143',
    city: 'Palm Coast',
    state: 'FL',
    latitude: '29.481152',
    longitude: '-81.133651',
    timeZoneId: 'America/New_York',
  },
  '32145': {
    zip: '32145',
    city: 'Hastings',
    state: 'FL',
    latitude: '29.677524',
    longitude: '-81.418611',
    timeZoneId: 'America/New_York',
  },
  '32147': {
    zip: '32147',
    city: 'Hollister',
    state: 'FL',
    latitude: '29.623748',
    longitude: '-81.813963',
    timeZoneId: 'America/New_York',
  },
  '32148': {
    zip: '32148',
    city: 'Interlachen',
    state: 'FL',
    latitude: '29.641935',
    longitude: '-81.900984',
    timeZoneId: 'America/New_York',
  },
  '32149': {
    zip: '32149',
    city: 'Interlachen',
    state: 'FL',
    latitude: '29.619345',
    longitude: '-81.897928',
    timeZoneId: 'America/New_York',
  },
  '32157': {
    zip: '32157',
    city: 'Lake Como',
    state: 'FL',
    latitude: '29.480074',
    longitude: '-81.574063',
    timeZoneId: 'America/New_York',
  },
  '32158': {
    zip: '32158',
    city: 'Lady Lake',
    state: 'FL',
    latitude: '28.92727',
    longitude: '-81.926955',
    timeZoneId: 'America/New_York',
  },
  '32159': {
    zip: '32159',
    city: 'Lady Lake',
    state: 'FL',
    latitude: '28.929131',
    longitude: '-81.925528',
    timeZoneId: 'America/New_York',
  },
  '32160': {
    zip: '32160',
    city: 'Lake Geneva',
    state: 'FL',
    latitude: '29.773028',
    longitude: '-82.010181',
    timeZoneId: 'America/New_York',
  },
  '32162': {
    zip: '32162',
    city: 'The Villages',
    state: 'FL',
    latitude: '28.91547',
    longitude: '-81.922679',
    timeZoneId: 'America/New_York',
  },
  '32163': {
    zip: '32163',
    city: 'The Villages',
    state: 'FL',
    latitude: '28.933053',
    longitude: '-81.948296',
    timeZoneId: 'America/New_York',
  },
  '32164': {
    zip: '32164',
    city: 'Palm Coast',
    state: 'FL',
    latitude: '29.532413',
    longitude: '-81.230479',
    timeZoneId: 'America/New_York',
  },
  '32168': {
    zip: '32168',
    city: 'New Smyrna Beach',
    state: 'FL',
    latitude: '29.002944',
    longitude: '-81.017002',
    timeZoneId: 'America/New_York',
  },
  '32169': {
    zip: '32169',
    city: 'New Smyrna Beach',
    state: 'FL',
    latitude: '28.999132',
    longitude: '-80.877818',
    timeZoneId: 'America/New_York',
  },
  '32170': {
    zip: '32170',
    city: 'New Smyrna Beach',
    state: 'FL',
    latitude: '29.034832',
    longitude: '-80.918544',
    timeZoneId: 'America/New_York',
  },
  '32173': {
    zip: '32173',
    city: 'Ormond Beach',
    state: 'FL',
    latitude: '29.290568',
    longitude: '-81.094572',
    timeZoneId: 'America/New_York',
  },
  '32174': {
    zip: '32174',
    city: 'Ormond Beach',
    state: 'FL',
    latitude: '29.289955',
    longitude: '-81.123815',
    timeZoneId: 'America/New_York',
  },
  '32175': {
    zip: '32175',
    city: 'Ormond Beach',
    state: 'FL',
    latitude: '29.286677',
    longitude: '-81.055778',
    timeZoneId: 'America/New_York',
  },
  '32176': {
    zip: '32176',
    city: 'Ormond Beach',
    state: 'FL',
    latitude: '29.325915',
    longitude: '-81.05781',
    timeZoneId: 'America/New_York',
  },
  '32177': {
    zip: '32177',
    city: 'Palatka',
    state: 'FL',
    latitude: '29.644139',
    longitude: '-81.659574',
    timeZoneId: 'America/New_York',
  },
  '32178': {
    zip: '32178',
    city: 'Palatka',
    state: 'FL',
    latitude: '29.646317',
    longitude: '-81.639501',
    timeZoneId: 'America/New_York',
  },
  '32179': {
    zip: '32179',
    city: 'Ocklawaha',
    state: 'FL',
    latitude: '29.05224',
    longitude: '-81.92016',
    timeZoneId: 'America/New_York',
  },
  '32180': {
    zip: '32180',
    city: 'Pierson',
    state: 'FL',
    latitude: '29.22263',
    longitude: '-81.466671',
    timeZoneId: 'America/New_York',
  },
  '32181': {
    zip: '32181',
    city: 'Pomona Park',
    state: 'FL',
    latitude: '29.472883',
    longitude: '-81.615343',
    timeZoneId: 'America/New_York',
  },
  '32182': {
    zip: '32182',
    city: 'Orange Springs',
    state: 'FL',
    latitude: '29.480155',
    longitude: '-81.940776',
    timeZoneId: 'America/New_York',
  },
  '32183': {
    zip: '32183',
    city: 'Ocklawaha',
    state: 'FL',
    latitude: '29.043234',
    longitude: '-81.929375',
    timeZoneId: 'America/New_York',
  },
  '32185': {
    zip: '32185',
    city: 'Putnam Hall',
    state: 'FL',
    latitude: '29.730954',
    longitude: '-81.957365',
    timeZoneId: 'America/New_York',
  },
  '32187': {
    zip: '32187',
    city: 'San Mateo',
    state: 'FL',
    latitude: '29.569734',
    longitude: '-81.562275',
    timeZoneId: 'America/New_York',
  },
  '32189': {
    zip: '32189',
    city: 'Satsuma',
    state: 'FL',
    latitude: '29.564126',
    longitude: '-81.633232',
    timeZoneId: 'America/New_York',
  },
  '32190': {
    zip: '32190',
    city: 'Seville',
    state: 'FL',
    latitude: '29.344542',
    longitude: '-81.510069',
    timeZoneId: 'America/New_York',
  },
  '32192': {
    zip: '32192',
    city: 'Sparr',
    state: 'FL',
    latitude: '29.341432',
    longitude: '-82.091915',
    timeZoneId: 'America/New_York',
  },
  '32193': {
    zip: '32193',
    city: 'Welaka',
    state: 'FL',
    latitude: '29.480929',
    longitude: '-81.672079',
    timeZoneId: 'America/New_York',
  },
  '32195': {
    zip: '32195',
    city: 'Weirsdale',
    state: 'FL',
    latitude: '28.98182',
    longitude: '-81.917908',
    timeZoneId: 'America/New_York',
  },
  '32198': {
    zip: '32198',
    city: 'Daytona Beach',
    state: 'FL',
    latitude: '29.195511',
    longitude: '-81.052236',
    timeZoneId: 'America/New_York',
  },
  '32201': {
    zip: '32201',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.275611',
    longitude: '-81.764254',
    timeZoneId: 'America/New_York',
  },
  '32202': {
    zip: '32202',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.328103',
    longitude: '-81.654825',
    timeZoneId: 'America/New_York',
  },
  '32203': {
    zip: '32203',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.337271',
    longitude: '-81.672748',
    timeZoneId: 'America/New_York',
  },
  '32204': {
    zip: '32204',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.326112',
    longitude: '-81.680064',
    timeZoneId: 'America/New_York',
  },
  '32205': {
    zip: '32205',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.30316',
    longitude: '-81.726849',
    timeZoneId: 'America/New_York',
  },
  '32206': {
    zip: '32206',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.351865',
    longitude: '-81.643107',
    timeZoneId: 'America/New_York',
  },
  '32207': {
    zip: '32207',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.292512',
    longitude: '-81.629247',
    timeZoneId: 'America/New_York',
  },
  '32208': {
    zip: '32208',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.390755',
    longitude: '-81.684166',
    timeZoneId: 'America/New_York',
  },
  '32209': {
    zip: '32209',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.355851',
    longitude: '-81.694137',
    timeZoneId: 'America/New_York',
  },
  '32210': {
    zip: '32210',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.260733',
    longitude: '-81.746664',
    timeZoneId: 'America/New_York',
  },
  '32211': {
    zip: '32211',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.329252',
    longitude: '-81.579613',
    timeZoneId: 'America/New_York',
  },
  '32212': {
    zip: '32212',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.209823',
    longitude: '-81.688591',
    timeZoneId: 'America/New_York',
  },
  '32214': {
    zip: '32214',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.216694',
    longitude: '-81.684003',
    timeZoneId: 'America/New_York',
  },
  '32216': {
    zip: '32216',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.279875',
    longitude: '-81.583037',
    timeZoneId: 'America/New_York',
  },
  '32217': {
    zip: '32217',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.237412',
    longitude: '-81.617619',
    timeZoneId: 'America/New_York',
  },
  '32218': {
    zip: '32218',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.461492',
    longitude: '-81.625786',
    timeZoneId: 'America/New_York',
  },
  '32219': {
    zip: '32219',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.410491',
    longitude: '-81.807977',
    timeZoneId: 'America/New_York',
  },
  '32220': {
    zip: '32220',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.328507',
    longitude: '-81.815147',
    timeZoneId: 'America/New_York',
  },
  '32221': {
    zip: '32221',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.291357',
    longitude: '-81.811864',
    timeZoneId: 'America/New_York',
  },
  '32222': {
    zip: '32222',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.222818',
    longitude: '-81.815888',
    timeZoneId: 'America/New_York',
  },
  '32223': {
    zip: '32223',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.158207',
    longitude: '-81.624997',
    timeZoneId: 'America/New_York',
  },
  '32224': {
    zip: '32224',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.275858',
    longitude: '-81.461221',
    timeZoneId: 'America/New_York',
  },
  '32225': {
    zip: '32225',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.348896',
    longitude: '-81.50107',
    timeZoneId: 'America/New_York',
  },
  '32226': {
    zip: '32226',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.466832',
    longitude: '-81.547848',
    timeZoneId: 'America/New_York',
  },
  '32227': {
    zip: '32227',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.38191',
    longitude: '-81.41041',
    timeZoneId: 'America/New_York',
  },
  '32228': {
    zip: '32228',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.388853',
    longitude: '-81.433191',
    timeZoneId: 'America/New_York',
  },
  '32229': {
    zip: '32229',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.408371',
    longitude: '-81.579521',
    timeZoneId: 'America/New_York',
  },
  '32231': {
    zip: '32231',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.289991',
    longitude: '-81.633454',
    timeZoneId: 'America/New_York',
  },
  '32232': {
    zip: '32232',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.292563',
    longitude: '-81.635312',
    timeZoneId: 'America/New_York',
  },
  '32233': {
    zip: '32233',
    city: 'Atlantic Beach',
    state: 'FL',
    latitude: '30.35562',
    longitude: '-81.51846',
    timeZoneId: 'America/New_York',
  },
  '32234': {
    zip: '32234',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.219367',
    longitude: '-81.997401',
    timeZoneId: 'America/New_York',
  },
  '32235': {
    zip: '32235',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.294054',
    longitude: '-81.634778',
    timeZoneId: 'America/New_York',
  },
  '32236': {
    zip: '32236',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.306054',
    longitude: '-81.729965',
    timeZoneId: 'America/New_York',
  },
  '32237': {
    zip: '32237',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.24339',
    longitude: '-81.619117',
    timeZoneId: 'America/New_York',
  },
  '32238': {
    zip: '32238',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.221453',
    longitude: '-81.763006',
    timeZoneId: 'America/New_York',
  },
  '32239': {
    zip: '32239',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.330986',
    longitude: '-81.577906',
    timeZoneId: 'America/New_York',
  },
  '32240': {
    zip: '32240',
    city: 'Jacksonville Beach',
    state: 'FL',
    latitude: '30.27824',
    longitude: '-81.41168',
    timeZoneId: 'America/New_York',
  },
  '32241': {
    zip: '32241',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.168289',
    longitude: '-81.629109',
    timeZoneId: 'America/New_York',
  },
  '32244': {
    zip: '32244',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.223538',
    longitude: '-81.757243',
    timeZoneId: 'America/New_York',
  },
  '32245': {
    zip: '32245',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.247865',
    longitude: '-81.549311',
    timeZoneId: 'America/New_York',
  },
  '32246': {
    zip: '32246',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.305263',
    longitude: '-81.511694',
    timeZoneId: 'America/New_York',
  },
  '32247': {
    zip: '32247',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.297084',
    longitude: '-81.635006',
    timeZoneId: 'America/New_York',
  },
  '32250': {
    zip: '32250',
    city: 'Jacksonville Beach',
    state: 'FL',
    latitude: '30.28122',
    longitude: '-81.41682',
    timeZoneId: 'America/New_York',
  },
  '32254': {
    zip: '32254',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.338709',
    longitude: '-81.727618',
    timeZoneId: 'America/New_York',
  },
  '32255': {
    zip: '32255',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.280202',
    longitude: '-81.546387',
    timeZoneId: 'America/New_York',
  },
  '32256': {
    zip: '32256',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.191339',
    longitude: '-81.49456',
    timeZoneId: 'America/New_York',
  },
  '32257': {
    zip: '32257',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.193293',
    longitude: '-81.601505',
    timeZoneId: 'America/New_York',
  },
  '32258': {
    zip: '32258',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.139567',
    longitude: '-81.544736',
    timeZoneId: 'America/New_York',
  },
  '32259': {
    zip: '32259',
    city: 'Saint Johns',
    state: 'FL',
    latitude: '30.091233',
    longitude: '-81.625682',
    timeZoneId: 'America/New_York',
  },
  '32260': {
    zip: '32260',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.077501',
    longitude: '-81.580838',
    timeZoneId: 'America/New_York',
  },
  '32266': {
    zip: '32266',
    city: 'Neptune Beach',
    state: 'FL',
    latitude: '30.315188',
    longitude: '-81.404431',
    timeZoneId: 'America/New_York',
  },
  '32277': {
    zip: '32277',
    city: 'Jacksonville',
    state: 'FL',
    latitude: '30.362941',
    longitude: '-81.581889',
    timeZoneId: 'America/New_York',
  },
  '32301': {
    zip: '32301',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.4293',
    longitude: '-84.2571',
    timeZoneId: 'America/New_York',
  },
  '32302': {
    zip: '32302',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.443756',
    longitude: '-84.283569',
    timeZoneId: 'America/New_York',
  },
  '32303': {
    zip: '32303',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.483064',
    longitude: '-84.32434',
    timeZoneId: 'America/New_York',
  },
  '32304': {
    zip: '32304',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.424511',
    longitude: '-84.324199',
    timeZoneId: 'America/New_York',
  },
  '32305': {
    zip: '32305',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.199158',
    longitude: '-84.267525',
    timeZoneId: 'America/New_York',
  },
  '32306': {
    zip: '32306',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.442453',
    longitude: '-84.299083',
    timeZoneId: 'America/New_York',
  },
  '32307': {
    zip: '32307',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.431483',
    longitude: '-84.288359',
    timeZoneId: 'America/New_York',
  },
  '32308': {
    zip: '32308',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.503754',
    longitude: '-84.212359',
    timeZoneId: 'America/New_York',
  },
  '32309': {
    zip: '32309',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.597156',
    longitude: '-84.041559',
    timeZoneId: 'America/New_York',
  },
  '32310': {
    zip: '32310',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.398031',
    longitude: '-84.319879',
    timeZoneId: 'America/New_York',
  },
  '32311': {
    zip: '32311',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.425681',
    longitude: '-84.177159',
    timeZoneId: 'America/New_York',
  },
  '32312': {
    zip: '32312',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.528764',
    longitude: '-84.261941',
    timeZoneId: 'America/New_York',
  },
  '32313': {
    zip: '32313',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.43',
    longitude: '-84.28',
    timeZoneId: 'America/New_York',
  },
  '32314': {
    zip: '32314',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.440348',
    longitude: '-84.334698',
    timeZoneId: 'America/New_York',
  },
  '32315': {
    zip: '32315',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.46169',
    longitude: '-84.287643',
    timeZoneId: 'America/New_York',
  },
  '32316': {
    zip: '32316',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.44556',
    longitude: '-84.327868',
    timeZoneId: 'America/New_York',
  },
  '32317': {
    zip: '32317',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.5672',
    longitude: '-84.111895',
    timeZoneId: 'America/New_York',
  },
  '32318': {
    zip: '32318',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.440983',
    longitude: '-84.281514',
    timeZoneId: 'America/New_York',
  },
  '32320': {
    zip: '32320',
    city: 'Apalachicola',
    state: 'FL',
    latitude: '29.724173',
    longitude: '-84.999118',
    timeZoneId: 'America/New_York',
  },
  '32321': {
    zip: '32321',
    city: 'Bristol',
    state: 'FL',
    latitude: '30.421751',
    longitude: '-84.979623',
    timeZoneId: 'America/New_York',
  },
  '32322': {
    zip: '32322',
    city: 'Carrabelle',
    state: 'FL',
    latitude: '29.940707',
    longitude: '-84.632787',
    timeZoneId: 'America/New_York',
  },
  '32323': {
    zip: '32323',
    city: 'Lanark Village',
    state: 'FL',
    latitude: '29.880772',
    longitude: '-84.599766',
    timeZoneId: 'America/New_York',
  },
  '32324': {
    zip: '32324',
    city: 'Chattahoochee',
    state: 'FL',
    latitude: '30.692351',
    longitude: '-84.842278',
    timeZoneId: 'America/New_York',
  },
  '32326': {
    zip: '32326',
    city: 'Crawfordville',
    state: 'FL',
    latitude: '30.172668',
    longitude: '-84.375634',
    timeZoneId: 'America/New_York',
  },
  '32327': {
    zip: '32327',
    city: 'Crawfordville',
    state: 'FL',
    latitude: '30.194005',
    longitude: '-84.263529',
    timeZoneId: 'America/New_York',
  },
  '32328': {
    zip: '32328',
    city: 'Eastpoint',
    state: 'FL',
    latitude: '29.7468',
    longitude: '-84.92322',
    timeZoneId: 'America/New_York',
  },
  '32329': {
    zip: '32329',
    city: 'Apalachicola',
    state: 'FL',
    latitude: '29.726522',
    longitude: '-84.997012',
    timeZoneId: 'America/New_York',
  },
  '32330': {
    zip: '32330',
    city: 'Greensboro',
    state: 'FL',
    latitude: '30.571884',
    longitude: '-84.743962',
    timeZoneId: 'America/New_York',
  },
  '32331': {
    zip: '32331',
    city: 'Greenville',
    state: 'FL',
    latitude: '30.468234',
    longitude: '-83.631594',
    timeZoneId: 'America/New_York',
  },
  '32332': {
    zip: '32332',
    city: 'Gretna',
    state: 'FL',
    latitude: '30.615685',
    longitude: '-84.664824',
    timeZoneId: 'America/New_York',
  },
  '32333': {
    zip: '32333',
    city: 'Havana',
    state: 'FL',
    latitude: '30.620089',
    longitude: '-84.419787',
    timeZoneId: 'America/New_York',
  },
  '32334': {
    zip: '32334',
    city: 'Hosford',
    state: 'FL',
    latitude: '30.209904',
    longitude: '-84.805533',
    timeZoneId: 'America/New_York',
  },
  '32335': {
    zip: '32335',
    city: 'Sumatra',
    state: 'FL',
    latitude: '30.023197',
    longitude: '-84.979408',
    timeZoneId: 'America/New_York',
  },
  '32336': {
    zip: '32336',
    city: 'Lamont',
    state: 'FL',
    latitude: '30.301068',
    longitude: '-83.940182',
    timeZoneId: 'America/New_York',
  },
  '32337': {
    zip: '32337',
    city: 'Lloyd',
    state: 'FL',
    latitude: '30.476702',
    longitude: '-84.02172',
    timeZoneId: 'America/New_York',
  },
  '32340': {
    zip: '32340',
    city: 'Madison',
    state: 'FL',
    latitude: '30.467665',
    longitude: '-83.415379',
    timeZoneId: 'America/New_York',
  },
  '32341': {
    zip: '32341',
    city: 'Madison',
    state: 'FL',
    latitude: '30.46973',
    longitude: '-83.417479',
    timeZoneId: 'America/New_York',
  },
  '32343': {
    zip: '32343',
    city: 'Midway',
    state: 'FL',
    latitude: '30.487891',
    longitude: '-84.462951',
    timeZoneId: 'America/New_York',
  },
  '32344': {
    zip: '32344',
    city: 'Monticello',
    state: 'FL',
    latitude: '30.545766',
    longitude: '-83.86513',
    timeZoneId: 'America/New_York',
  },
  '32345': {
    zip: '32345',
    city: 'Monticello',
    state: 'FL',
    latitude: '30.544019',
    longitude: '-83.872485',
    timeZoneId: 'America/New_York',
  },
  '32346': {
    zip: '32346',
    city: 'Panacea',
    state: 'FL',
    latitude: '30.023822',
    longitude: '-84.391553',
    timeZoneId: 'America/New_York',
  },
  '32347': {
    zip: '32347',
    city: 'Perry',
    state: 'FL',
    latitude: '30.111591',
    longitude: '-83.582335',
    timeZoneId: 'America/New_York',
  },
  '32348': {
    zip: '32348',
    city: 'Perry',
    state: 'FL',
    latitude: '30.110251',
    longitude: '-83.579791',
    timeZoneId: 'America/New_York',
  },
  '32350': {
    zip: '32350',
    city: 'Pinetta',
    state: 'FL',
    latitude: '30.605854',
    longitude: '-83.338359',
    timeZoneId: 'America/New_York',
  },
  '32351': {
    zip: '32351',
    city: 'Quincy',
    state: 'FL',
    latitude: '30.581537',
    longitude: '-84.581395',
    timeZoneId: 'America/New_York',
  },
  '32352': {
    zip: '32352',
    city: 'Quincy',
    state: 'FL',
    latitude: '30.686033',
    longitude: '-84.826115',
    timeZoneId: 'America/New_York',
  },
  '32353': {
    zip: '32353',
    city: 'Quincy',
    state: 'FL',
    latitude: '30.578986',
    longitude: '-84.592801',
    timeZoneId: 'America/New_York',
  },
  '32355': {
    zip: '32355',
    city: 'Saint Marks',
    state: 'FL',
    latitude: '30.162617',
    longitude: '-84.207166',
    timeZoneId: 'America/New_York',
  },
  '32356': {
    zip: '32356',
    city: 'Salem',
    state: 'FL',
    latitude: '29.790746',
    longitude: '-83.383125',
    timeZoneId: 'America/New_York',
  },
  '32357': {
    zip: '32357',
    city: 'Shady Grove',
    state: 'FL',
    latitude: '30.285383',
    longitude: '-83.629313',
    timeZoneId: 'America/New_York',
  },
  '32358': {
    zip: '32358',
    city: 'Sopchoppy',
    state: 'FL',
    latitude: '30.122839',
    longitude: '-84.522762',
    timeZoneId: 'America/New_York',
  },
  '32359': {
    zip: '32359',
    city: 'Steinhatchee',
    state: 'FL',
    latitude: '29.676861',
    longitude: '-83.378046',
    timeZoneId: 'America/New_York',
  },
  '32360': {
    zip: '32360',
    city: 'Telogia',
    state: 'FL',
    latitude: '30.349304',
    longitude: '-84.818526',
    timeZoneId: 'America/New_York',
  },
  '32361': {
    zip: '32361',
    city: 'Wacissa',
    state: 'FL',
    latitude: '30.357349',
    longitude: '-83.985919',
    timeZoneId: 'America/New_York',
  },
  '32362': {
    zip: '32362',
    city: 'Woodville',
    state: 'FL',
    latitude: '30.314652',
    longitude: '-84.264359',
    timeZoneId: 'America/New_York',
  },
  '32395': {
    zip: '32395',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.430504',
    longitude: '-84.258788',
    timeZoneId: 'America/New_York',
  },
  '32399': {
    zip: '32399',
    city: 'Tallahassee',
    state: 'FL',
    latitude: '30.429675',
    longitude: '-84.258538',
    timeZoneId: 'America/New_York',
  },
  '32401': {
    zip: '32401',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.160005',
    longitude: '-85.649607',
    timeZoneId: 'America/Chicago',
  },
  '32402': {
    zip: '32402',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.190549',
    longitude: '-85.67348',
    timeZoneId: 'America/Chicago',
  },
  '32403': {
    zip: '32403',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.090395',
    longitude: '-85.629069',
    timeZoneId: 'America/Chicago',
  },
  '32404': {
    zip: '32404',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.180448',
    longitude: '-85.559816',
    timeZoneId: 'America/Chicago',
  },
  '32405': {
    zip: '32405',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.203385',
    longitude: '-85.660566',
    timeZoneId: 'America/Chicago',
  },
  '32406': {
    zip: '32406',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.152705',
    longitude: '-85.737314',
    timeZoneId: 'America/Chicago',
  },
  '32407': {
    zip: '32407',
    city: 'Panama City Beach',
    state: 'FL',
    latitude: '30.20298',
    longitude: '-85.79796',
    timeZoneId: 'America/Chicago',
  },
  '32408': {
    zip: '32408',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.158417',
    longitude: '-85.763888',
    timeZoneId: 'America/Chicago',
  },
  '32409': {
    zip: '32409',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.34896',
    longitude: '-85.6764',
    timeZoneId: 'America/Chicago',
  },
  '32410': {
    zip: '32410',
    city: 'Mexico Beach',
    state: 'FL',
    latitude: '29.94399',
    longitude: '-85.409019',
    timeZoneId: 'America/Chicago',
  },
  '32411': {
    zip: '32411',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.14916',
    longitude: '-85.729755',
    timeZoneId: 'America/Chicago',
  },
  '32412': {
    zip: '32412',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.156293',
    longitude: '-85.648646',
    timeZoneId: 'America/Chicago',
  },
  '32413': {
    zip: '32413',
    city: 'Panama City Beach',
    state: 'FL',
    latitude: '30.243545',
    longitude: '-85.907737',
    timeZoneId: 'America/Chicago',
  },
  '32417': {
    zip: '32417',
    city: 'Panama City',
    state: 'FL',
    latitude: '30.1843',
    longitude: '-85.811156',
    timeZoneId: 'America/Chicago',
  },
  '32420': {
    zip: '32420',
    city: 'Alford',
    state: 'FL',
    latitude: '30.64361',
    longitude: '-85.377228',
    timeZoneId: 'America/Chicago',
  },
  '32421': {
    zip: '32421',
    city: 'Altha',
    state: 'FL',
    latitude: '30.520144',
    longitude: '-85.213643',
    timeZoneId: 'America/Chicago',
  },
  '32422': {
    zip: '32422',
    city: 'Argyle',
    state: 'FL',
    latitude: '30.749556',
    longitude: '-86.07287',
    timeZoneId: 'America/Chicago',
  },
  '32423': {
    zip: '32423',
    city: 'Bascom',
    state: 'FL',
    latitude: '30.957522',
    longitude: '-85.079542',
    timeZoneId: 'America/Chicago',
  },
  '32424': {
    zip: '32424',
    city: 'Blountstown',
    state: 'FL',
    latitude: '30.440452',
    longitude: '-85.046484',
    timeZoneId: 'America/Chicago',
  },
  '32425': {
    zip: '32425',
    city: 'Bonifay',
    state: 'FL',
    latitude: '30.795111',
    longitude: '-85.677801',
    timeZoneId: 'America/Chicago',
  },
  '32426': {
    zip: '32426',
    city: 'Campbellton',
    state: 'FL',
    latitude: '30.942431',
    longitude: '-85.357503',
    timeZoneId: 'America/Chicago',
  },
  '32427': {
    zip: '32427',
    city: 'Caryville',
    state: 'FL',
    latitude: '30.816993',
    longitude: '-85.804293',
    timeZoneId: 'America/Chicago',
  },
  '32428': {
    zip: '32428',
    city: 'Chipley',
    state: 'FL',
    latitude: '30.67194',
    longitude: '-85.571469',
    timeZoneId: 'America/Chicago',
  },
  '32430': {
    zip: '32430',
    city: 'Clarksville',
    state: 'FL',
    latitude: '30.363435',
    longitude: '-85.182016',
    timeZoneId: 'America/Chicago',
  },
  '32431': {
    zip: '32431',
    city: 'Cottondale',
    state: 'FL',
    latitude: '30.798181',
    longitude: '-85.373699',
    timeZoneId: 'America/Chicago',
  },
  '32432': {
    zip: '32432',
    city: 'Cypress',
    state: 'FL',
    latitude: '30.710912',
    longitude: '-85.074337',
    timeZoneId: 'America/Chicago',
  },
  '32433': {
    zip: '32433',
    city: 'Defuniak Springs',
    state: 'FL',
    latitude: '30.72315',
    longitude: '-86.120366',
    timeZoneId: 'America/Chicago',
  },
  '32434': {
    zip: '32434',
    city: 'Mossy Head',
    state: 'FL',
    latitude: '30.742338',
    longitude: '-86.314609',
    timeZoneId: 'America/Chicago',
  },
  '32435': {
    zip: '32435',
    city: 'Defuniak Springs',
    state: 'FL',
    latitude: '30.719521',
    longitude: '-86.121763',
    timeZoneId: 'America/Chicago',
  },
  '32437': {
    zip: '32437',
    city: 'Ebro',
    state: 'FL',
    latitude: '30.433336',
    longitude: '-85.911773',
    timeZoneId: 'America/Chicago',
  },
  '32438': {
    zip: '32438',
    city: 'Fountain',
    state: 'FL',
    latitude: '30.482956',
    longitude: '-85.422367',
    timeZoneId: 'America/Chicago',
  },
  '32439': {
    zip: '32439',
    city: 'Freeport',
    state: 'FL',
    latitude: '30.457485',
    longitude: '-86.215323',
    timeZoneId: 'America/Chicago',
  },
  '32440': {
    zip: '32440',
    city: 'Graceville',
    state: 'FL',
    latitude: '30.957923',
    longitude: '-85.510353',
    timeZoneId: 'America/Chicago',
  },
  '32442': {
    zip: '32442',
    city: 'Grand Ridge',
    state: 'FL',
    latitude: '30.70281',
    longitude: '-85.02477',
    timeZoneId: 'America/Chicago',
  },
  '32443': {
    zip: '32443',
    city: 'Greenwood',
    state: 'FL',
    latitude: '30.867175',
    longitude: '-85.171707',
    timeZoneId: 'America/Chicago',
  },
  '32444': {
    zip: '32444',
    city: 'Lynn Haven',
    state: 'FL',
    latitude: '30.235941',
    longitude: '-85.647485',
    timeZoneId: 'America/Chicago',
  },
  '32445': {
    zip: '32445',
    city: 'Malone',
    state: 'FL',
    latitude: '30.971243',
    longitude: '-85.174676',
    timeZoneId: 'America/Chicago',
  },
  '32446': {
    zip: '32446',
    city: 'Marianna',
    state: 'FL',
    latitude: '30.776351',
    longitude: '-85.229988',
    timeZoneId: 'America/Chicago',
  },
  '32447': {
    zip: '32447',
    city: 'Marianna',
    state: 'FL',
    latitude: '30.852964',
    longitude: '-85.227838',
    timeZoneId: 'America/Chicago',
  },
  '32448': {
    zip: '32448',
    city: 'Marianna',
    state: 'FL',
    latitude: '30.661698',
    longitude: '-85.18484',
    timeZoneId: 'America/Chicago',
  },
  '32449': {
    zip: '32449',
    city: 'Wewahitchka',
    state: 'FL',
    latitude: '30.270733',
    longitude: '-85.198121',
    timeZoneId: 'America/Chicago',
  },
  '32452': {
    zip: '32452',
    city: 'Noma',
    state: 'FL',
    latitude: '30.980595',
    longitude: '-85.615081',
    timeZoneId: 'America/Chicago',
  },
  '32455': {
    zip: '32455',
    city: 'Ponce de Leon',
    state: 'FL',
    latitude: '30.771038',
    longitude: '-85.953835',
    timeZoneId: 'America/Chicago',
  },
  '32456': {
    zip: '32456',
    city: 'Port Saint Joe',
    state: 'FL',
    latitude: '29.819214',
    longitude: '-85.299275',
    timeZoneId: 'America/Chicago',
  },
  '32457': {
    zip: '32457',
    city: 'Port Saint Joe',
    state: 'FL',
    latitude: '29.811847',
    longitude: '-85.303541',
    timeZoneId: 'America/Chicago',
  },
  '32459': {
    zip: '32459',
    city: 'Santa Rosa Beach',
    state: 'FL',
    latitude: '30.359723',
    longitude: '-86.237832',
    timeZoneId: 'America/Chicago',
  },
  '32460': {
    zip: '32460',
    city: 'Sneads',
    state: 'FL',
    latitude: '30.71428',
    longitude: '-84.930687',
    timeZoneId: 'America/Chicago',
  },
  '32461': {
    zip: '32461',
    city: 'Inlet Beach',
    state: 'FL',
    latitude: '30.251077',
    longitude: '-85.950567',
    timeZoneId: 'America/Chicago',
  },
  '32462': {
    zip: '32462',
    city: 'Vernon',
    state: 'FL',
    latitude: '30.504954',
    longitude: '-85.867382',
    timeZoneId: 'America/Chicago',
  },
  '32463': {
    zip: '32463',
    city: 'Wausau',
    state: 'FL',
    latitude: '30.629641',
    longitude: '-85.587623',
    timeZoneId: 'America/Chicago',
  },
  '32464': {
    zip: '32464',
    city: 'Westville',
    state: 'FL',
    latitude: '30.770964',
    longitude: '-85.853378',
    timeZoneId: 'America/Chicago',
  },
  '32465': {
    zip: '32465',
    city: 'Wewahitchka',
    state: 'FL',
    latitude: '30.056707',
    longitude: '-85.159531',
    timeZoneId: 'America/Chicago',
  },
  '32466': {
    zip: '32466',
    city: 'Youngstown',
    state: 'FL',
    latitude: '30.336245',
    longitude: '-85.525494',
    timeZoneId: 'America/Chicago',
  },
  '32501': {
    zip: '32501',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.419339',
    longitude: '-87.21907',
    timeZoneId: 'America/Chicago',
  },
  '32502': {
    zip: '32502',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.423197',
    longitude: '-87.226657',
    timeZoneId: 'America/Chicago',
  },
  '32503': {
    zip: '32503',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.452842',
    longitude: '-87.211049',
    timeZoneId: 'America/Chicago',
  },
  '32504': {
    zip: '32504',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.490718',
    longitude: '-87.183731',
    timeZoneId: 'America/Chicago',
  },
  '32505': {
    zip: '32505',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.44218',
    longitude: '-87.25585',
    timeZoneId: 'America/Chicago',
  },
  '32506': {
    zip: '32506',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.433325',
    longitude: '-87.318561',
    timeZoneId: 'America/Chicago',
  },
  '32507': {
    zip: '32507',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.367319',
    longitude: '-87.330899',
    timeZoneId: 'America/Chicago',
  },
  '32508': {
    zip: '32508',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.353315',
    longitude: '-87.276065',
    timeZoneId: 'America/Chicago',
  },
  '32509': {
    zip: '32509',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.420085',
    longitude: '-87.222152',
    timeZoneId: 'America/Chicago',
  },
  '32511': {
    zip: '32511',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.396956',
    longitude: '-87.352515',
    timeZoneId: 'America/Chicago',
  },
  '32512': {
    zip: '32512',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.502687',
    longitude: '-87.173684',
    timeZoneId: 'America/Chicago',
  },
  '32513': {
    zip: '32513',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.460225',
    longitude: '-87.215056',
    timeZoneId: 'America/Chicago',
  },
  '32514': {
    zip: '32514',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.524613',
    longitude: '-87.236038',
    timeZoneId: 'America/Chicago',
  },
  '32516': {
    zip: '32516',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.424453',
    longitude: '-87.294897',
    timeZoneId: 'America/Chicago',
  },
  '32520': {
    zip: '32520',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.455593',
    longitude: '-87.258153',
    timeZoneId: 'America/Chicago',
  },
  '32521': {
    zip: '32521',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.375769',
    longitude: '-87.276144',
    timeZoneId: 'America/Chicago',
  },
  '32522': {
    zip: '32522',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.422794',
    longitude: '-87.223235',
    timeZoneId: 'America/Chicago',
  },
  '32523': {
    zip: '32523',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.516644',
    longitude: '-87.218851',
    timeZoneId: 'America/Chicago',
  },
  '32524': {
    zip: '32524',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.485413',
    longitude: '-87.190253',
    timeZoneId: 'America/Chicago',
  },
  '32526': {
    zip: '32526',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.478102',
    longitude: '-87.324059',
    timeZoneId: 'America/Chicago',
  },
  '32530': {
    zip: '32530',
    city: 'Bagdad',
    state: 'FL',
    latitude: '30.618153',
    longitude: '-87.025228',
    timeZoneId: 'America/Chicago',
  },
  '32531': {
    zip: '32531',
    city: 'Baker',
    state: 'FL',
    latitude: '30.788131',
    longitude: '-86.606115',
    timeZoneId: 'America/Chicago',
  },
  '32533': {
    zip: '32533',
    city: 'Cantonment',
    state: 'FL',
    latitude: '30.630694',
    longitude: '-87.334171',
    timeZoneId: 'America/Chicago',
  },
  '32534': {
    zip: '32534',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.525975',
    longitude: '-87.279037',
    timeZoneId: 'America/Chicago',
  },
  '32535': {
    zip: '32535',
    city: 'Century',
    state: 'FL',
    latitude: '30.969246',
    longitude: '-87.3123',
    timeZoneId: 'America/Chicago',
  },
  '32536': {
    zip: '32536',
    city: 'Crestview',
    state: 'FL',
    latitude: '30.763215',
    longitude: '-86.563528',
    timeZoneId: 'America/Chicago',
  },
  '32537': {
    zip: '32537',
    city: 'Milligan',
    state: 'FL',
    latitude: '30.760921',
    longitude: '-86.605919',
    timeZoneId: 'America/Chicago',
  },
  '32538': {
    zip: '32538',
    city: 'Paxton',
    state: 'FL',
    latitude: '30.97737',
    longitude: '-86.312405',
    timeZoneId: 'America/Chicago',
  },
  '32539': {
    zip: '32539',
    city: 'Crestview',
    state: 'FL',
    latitude: '30.763784',
    longitude: '-86.564408',
    timeZoneId: 'America/Chicago',
  },
  '32540': {
    zip: '32540',
    city: 'Destin',
    state: 'FL',
    latitude: '30.392838',
    longitude: '-86.481823',
    timeZoneId: 'America/Chicago',
  },
  '32541': {
    zip: '32541',
    city: 'Destin',
    state: 'FL',
    latitude: '30.397703',
    longitude: '-86.479754',
    timeZoneId: 'America/Chicago',
  },
  '32542': {
    zip: '32542',
    city: 'Eglin AFB',
    state: 'FL',
    latitude: '30.464353',
    longitude: '-86.534988',
    timeZoneId: 'America/Chicago',
  },
  '32544': {
    zip: '32544',
    city: 'Hurlburt Field',
    state: 'FL',
    latitude: '30.421136',
    longitude: '-86.695646',
    timeZoneId: 'America/Chicago',
  },
  '32547': {
    zip: '32547',
    city: 'Fort Walton Beach',
    state: 'FL',
    latitude: '30.446373',
    longitude: '-86.626191',
    timeZoneId: 'America/Chicago',
  },
  '32548': {
    zip: '32548',
    city: 'Fort Walton Beach',
    state: 'FL',
    latitude: '30.411115',
    longitude: '-86.61443',
    timeZoneId: 'America/Chicago',
  },
  '32549': {
    zip: '32549',
    city: 'Fort Walton Beach',
    state: 'FL',
    latitude: '30.410303',
    longitude: '-86.614571',
    timeZoneId: 'America/Chicago',
  },
  '32550': {
    zip: '32550',
    city: 'Miramar Beach',
    state: 'FL',
    latitude: '30.393333',
    longitude: '-86.495833',
    timeZoneId: 'America/Chicago',
  },
  '32559': {
    zip: '32559',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.465303',
    longitude: '-87.338781',
    timeZoneId: 'America/Chicago',
  },
  '32560': {
    zip: '32560',
    city: 'Gonzalez',
    state: 'FL',
    latitude: '30.582196',
    longitude: '-87.291619',
    timeZoneId: 'America/Chicago',
  },
  '32561': {
    zip: '32561',
    city: 'Gulf Breeze',
    state: 'FL',
    latitude: '30.383106',
    longitude: '-87.090524',
    timeZoneId: 'America/Chicago',
  },
  '32562': {
    zip: '32562',
    city: 'Gulf Breeze',
    state: 'FL',
    latitude: '30.371535',
    longitude: '-87.173931',
    timeZoneId: 'America/Chicago',
  },
  '32563': {
    zip: '32563',
    city: 'Gulf Breeze',
    state: 'FL',
    latitude: '30.40581',
    longitude: '-86.98719',
    timeZoneId: 'America/Chicago',
  },
  '32564': {
    zip: '32564',
    city: 'Holt',
    state: 'FL',
    latitude: '30.706505',
    longitude: '-86.848432',
    timeZoneId: 'America/Chicago',
  },
  '32565': {
    zip: '32565',
    city: 'Jay',
    state: 'FL',
    latitude: '30.916266',
    longitude: '-87.147853',
    timeZoneId: 'America/Chicago',
  },
  '32566': {
    zip: '32566',
    city: 'Navarre',
    state: 'FL',
    latitude: '30.50088',
    longitude: '-86.89662',
    timeZoneId: 'America/Chicago',
  },
  '32567': {
    zip: '32567',
    city: 'Laurel Hill',
    state: 'FL',
    latitude: '30.933464',
    longitude: '-86.418718',
    timeZoneId: 'America/Chicago',
  },
  '32568': {
    zip: '32568',
    city: 'Mc David',
    state: 'FL',
    latitude: '30.86934',
    longitude: '-87.45384',
    timeZoneId: 'America/Chicago',
  },
  '32569': {
    zip: '32569',
    city: 'Mary Esther',
    state: 'FL',
    latitude: '30.412242',
    longitude: '-86.717211',
    timeZoneId: 'America/Chicago',
  },
  '32570': {
    zip: '32570',
    city: 'Milton',
    state: 'FL',
    latitude: '30.633995',
    longitude: '-87.046252',
    timeZoneId: 'America/Chicago',
  },
  '32571': {
    zip: '32571',
    city: 'Milton',
    state: 'FL',
    latitude: '30.6696',
    longitude: '-87.15492',
    timeZoneId: 'America/Chicago',
  },
  '32572': {
    zip: '32572',
    city: 'Milton',
    state: 'FL',
    latitude: '30.627804',
    longitude: '-87.048607',
    timeZoneId: 'America/Chicago',
  },
  '32577': {
    zip: '32577',
    city: 'Molino',
    state: 'FL',
    latitude: '30.722097',
    longitude: '-87.324952',
    timeZoneId: 'America/Chicago',
  },
  '32578': {
    zip: '32578',
    city: 'Niceville',
    state: 'FL',
    latitude: '30.505216',
    longitude: '-86.454252',
    timeZoneId: 'America/Chicago',
  },
  '32579': {
    zip: '32579',
    city: 'Shalimar',
    state: 'FL',
    latitude: '30.448064',
    longitude: '-86.572977',
    timeZoneId: 'America/Chicago',
  },
  '32580': {
    zip: '32580',
    city: 'Valparaiso',
    state: 'FL',
    latitude: '30.507402',
    longitude: '-86.493872',
    timeZoneId: 'America/Chicago',
  },
  '32583': {
    zip: '32583',
    city: 'Milton',
    state: 'FL',
    latitude: '30.58677',
    longitude: '-87.025229',
    timeZoneId: 'America/Chicago',
  },
  '32588': {
    zip: '32588',
    city: 'Niceville',
    state: 'FL',
    latitude: '30.524498',
    longitude: '-86.474906',
    timeZoneId: 'America/Chicago',
  },
  '32591': {
    zip: '32591',
    city: 'Pensacola',
    state: 'FL',
    latitude: '30.417805',
    longitude: '-87.22537',
    timeZoneId: 'America/Chicago',
  },
  '32601': {
    zip: '32601',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.648924',
    longitude: '-82.304742',
    timeZoneId: 'America/New_York',
  },
  '32602': {
    zip: '32602',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.663326',
    longitude: '-82.450099',
    timeZoneId: 'America/New_York',
  },
  '32603': {
    zip: '32603',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.655262',
    longitude: '-82.346991',
    timeZoneId: 'America/New_York',
  },
  '32604': {
    zip: '32604',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.649826',
    longitude: '-82.345893',
    timeZoneId: 'America/New_York',
  },
  '32605': {
    zip: '32605',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.680283',
    longitude: '-82.372095',
    timeZoneId: 'America/New_York',
  },
  '32606': {
    zip: '32606',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.696881',
    longitude: '-82.411723',
    timeZoneId: 'America/New_York',
  },
  '32607': {
    zip: '32607',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.643647',
    longitude: '-82.414335',
    timeZoneId: 'America/New_York',
  },
  '32608': {
    zip: '32608',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.602269',
    longitude: '-82.399138',
    timeZoneId: 'America/New_York',
  },
  '32609': {
    zip: '32609',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.695004',
    longitude: '-82.299594',
    timeZoneId: 'America/New_York',
  },
  '32610': {
    zip: '32610',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.642276',
    longitude: '-82.344949',
    timeZoneId: 'America/New_York',
  },
  '32611': {
    zip: '32611',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.643443',
    longitude: '-82.349637',
    timeZoneId: 'America/New_York',
  },
  '32612': {
    zip: '32612',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.652878',
    longitude: '-82.351153',
    timeZoneId: 'America/New_York',
  },
  '32614': {
    zip: '32614',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.610707',
    longitude: '-82.375187',
    timeZoneId: 'America/New_York',
  },
  '32615': {
    zip: '32615',
    city: 'Alachua',
    state: 'FL',
    latitude: '29.784372',
    longitude: '-82.477314',
    timeZoneId: 'America/New_York',
  },
  '32616': {
    zip: '32616',
    city: 'Alachua',
    state: 'FL',
    latitude: '29.780604',
    longitude: '-82.480289',
    timeZoneId: 'America/New_York',
  },
  '32617': {
    zip: '32617',
    city: 'Anthony',
    state: 'FL',
    latitude: '29.293459',
    longitude: '-82.108432',
    timeZoneId: 'America/New_York',
  },
  '32618': {
    zip: '32618',
    city: 'Archer',
    state: 'FL',
    latitude: '29.544826',
    longitude: '-82.514549',
    timeZoneId: 'America/New_York',
  },
  '32619': {
    zip: '32619',
    city: 'Bell',
    state: 'FL',
    latitude: '29.616466',
    longitude: '-82.816722',
    timeZoneId: 'America/New_York',
  },
  '32621': {
    zip: '32621',
    city: 'Bronson',
    state: 'FL',
    latitude: '29.453446',
    longitude: '-82.635481',
    timeZoneId: 'America/New_York',
  },
  '32622': {
    zip: '32622',
    city: 'Brooker',
    state: 'FL',
    latitude: '29.926221',
    longitude: '-82.284544',
    timeZoneId: 'America/New_York',
  },
  '32625': {
    zip: '32625',
    city: 'Cedar Key',
    state: 'FL',
    latitude: '29.170127',
    longitude: '-83.014197',
    timeZoneId: 'America/New_York',
  },
  '32626': {
    zip: '32626',
    city: 'Chiefland',
    state: 'FL',
    latitude: '29.484117',
    longitude: '-82.864496',
    timeZoneId: 'America/New_York',
  },
  '32627': {
    zip: '32627',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.646353',
    longitude: '-82.328199',
    timeZoneId: 'America/New_York',
  },
  '32628': {
    zip: '32628',
    city: 'Cross City',
    state: 'FL',
    latitude: '29.634988',
    longitude: '-83.111485',
    timeZoneId: 'America/New_York',
  },
  '32631': {
    zip: '32631',
    city: 'Earleton',
    state: 'FL',
    latitude: '29.75928',
    longitude: '-82.12221',
    timeZoneId: 'America/New_York',
  },
  '32633': {
    zip: '32633',
    city: 'Evinston',
    state: 'FL',
    latitude: '29.483793',
    longitude: '-82.222855',
    timeZoneId: 'America/New_York',
  },
  '32634': {
    zip: '32634',
    city: 'Fairfield',
    state: 'FL',
    latitude: '29.371891',
    longitude: '-82.278007',
    timeZoneId: 'America/New_York',
  },
  '32635': {
    zip: '32635',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.650401',
    longitude: '-82.325247',
    timeZoneId: 'America/New_York',
  },
  '32639': {
    zip: '32639',
    city: 'Gulf Hammock',
    state: 'FL',
    latitude: '29.250081',
    longitude: '-82.72237',
    timeZoneId: 'America/New_York',
  },
  '32640': {
    zip: '32640',
    city: 'Hawthorne',
    state: 'FL',
    latitude: '29.591884',
    longitude: '-82.112532',
    timeZoneId: 'America/New_York',
  },
  '32641': {
    zip: '32641',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.653504',
    longitude: '-82.32719',
    timeZoneId: 'America/New_York',
  },
  '32643': {
    zip: '32643',
    city: 'High Springs',
    state: 'FL',
    latitude: '29.827571',
    longitude: '-82.600164',
    timeZoneId: 'America/New_York',
  },
  '32644': {
    zip: '32644',
    city: 'Chiefland',
    state: 'FL',
    latitude: '29.478382',
    longitude: '-82.858338',
    timeZoneId: 'America/New_York',
  },
  '32648': {
    zip: '32648',
    city: 'Horseshoe Beach',
    state: 'FL',
    latitude: '29.554006',
    longitude: '-83.254234',
    timeZoneId: 'America/New_York',
  },
  '32653': {
    zip: '32653',
    city: 'Gainesville',
    state: 'FL',
    latitude: '29.66249',
    longitude: '-82.325135',
    timeZoneId: 'America/New_York',
  },
  '32654': {
    zip: '32654',
    city: 'Island Grove',
    state: 'FL',
    latitude: '29.590092',
    longitude: '-82.127367',
    timeZoneId: 'America/New_York',
  },
  '32655': {
    zip: '32655',
    city: 'High Springs',
    state: 'FL',
    latitude: '29.822532',
    longitude: '-82.590809',
    timeZoneId: 'America/New_York',
  },
  '32656': {
    zip: '32656',
    city: 'Keystone Heights',
    state: 'FL',
    latitude: '29.798149',
    longitude: '-81.989223',
    timeZoneId: 'America/New_York',
  },
  '32658': {
    zip: '32658',
    city: 'La Crosse',
    state: 'FL',
    latitude: '29.844295',
    longitude: '-82.405529',
    timeZoneId: 'America/New_York',
  },
  '32662': {
    zip: '32662',
    city: 'Lochloosa',
    state: 'FL',
    latitude: '29.51144',
    longitude: '-82.099001',
    timeZoneId: 'America/New_York',
  },
  '32663': {
    zip: '32663',
    city: 'Lowell',
    state: 'FL',
    latitude: '29.332676',
    longitude: '-82.198554',
    timeZoneId: 'America/New_York',
  },
  '32664': {
    zip: '32664',
    city: 'Mc Intosh',
    state: 'FL',
    latitude: '29.448039',
    longitude: '-82.229425',
    timeZoneId: 'America/New_York',
  },
  '32666': {
    zip: '32666',
    city: 'Melrose',
    state: 'FL',
    latitude: '29.746789',
    longitude: '-82.010428',
    timeZoneId: 'America/New_York',
  },
  '32667': {
    zip: '32667',
    city: 'Micanopy',
    state: 'FL',
    latitude: '29.441958',
    longitude: '-82.298007',
    timeZoneId: 'America/New_York',
  },
  '32668': {
    zip: '32668',
    city: 'Morriston',
    state: 'FL',
    latitude: '29.273046',
    longitude: '-82.364684',
    timeZoneId: 'America/New_York',
  },
  '32669': {
    zip: '32669',
    city: 'Newberry',
    state: 'FL',
    latitude: '29.647073',
    longitude: '-82.599723',
    timeZoneId: 'America/New_York',
  },
  '32680': {
    zip: '32680',
    city: 'Old Town',
    state: 'FL',
    latitude: '29.626506',
    longitude: '-83.10563',
    timeZoneId: 'America/New_York',
  },
  '32681': {
    zip: '32681',
    city: 'Orange Lake',
    state: 'FL',
    latitude: '29.418125',
    longitude: '-82.215695',
    timeZoneId: 'America/New_York',
  },
  '32683': {
    zip: '32683',
    city: 'Otter Creek',
    state: 'FL',
    latitude: '29.325365',
    longitude: '-82.772815',
    timeZoneId: 'America/New_York',
  },
  '32686': {
    zip: '32686',
    city: 'Reddick',
    state: 'FL',
    latitude: '29.35878',
    longitude: '-82.25285',
    timeZoneId: 'America/New_York',
  },
  '32692': {
    zip: '32692',
    city: 'Suwannee',
    state: 'FL',
    latitude: '29.327937',
    longitude: '-83.145284',
    timeZoneId: 'America/New_York',
  },
  '32693': {
    zip: '32693',
    city: 'Trenton',
    state: 'FL',
    latitude: '29.613522',
    longitude: '-82.814668',
    timeZoneId: 'America/New_York',
  },
  '32694': {
    zip: '32694',
    city: 'Waldo',
    state: 'FL',
    latitude: '29.78265',
    longitude: '-82.173373',
    timeZoneId: 'America/New_York',
  },
  '32696': {
    zip: '32696',
    city: 'Williston',
    state: 'FL',
    latitude: '29.388078',
    longitude: '-82.453512',
    timeZoneId: 'America/New_York',
  },
  '32697': {
    zip: '32697',
    city: 'Worthington Springs',
    state: 'FL',
    latitude: '29.931333',
    longitude: '-82.424654',
    timeZoneId: 'America/New_York',
  },
  '32701': {
    zip: '32701',
    city: 'Altamonte Springs',
    state: 'FL',
    latitude: '28.662995',
    longitude: '-81.372939',
    timeZoneId: 'America/New_York',
  },
  '32702': {
    zip: '32702',
    city: 'Altoona',
    state: 'FL',
    latitude: '29.014274',
    longitude: '-81.624817',
    timeZoneId: 'America/New_York',
  },
  '32703': {
    zip: '32703',
    city: 'Apopka',
    state: 'FL',
    latitude: '28.662448',
    longitude: '-81.501094',
    timeZoneId: 'America/New_York',
  },
  '32704': {
    zip: '32704',
    city: 'Apopka',
    state: 'FL',
    latitude: '28.611586',
    longitude: '-81.498583',
    timeZoneId: 'America/New_York',
  },
  '32706': {
    zip: '32706',
    city: 'Cassadaga',
    state: 'FL',
    latitude: '28.967199',
    longitude: '-81.236308',
    timeZoneId: 'America/New_York',
  },
  '32707': {
    zip: '32707',
    city: 'Casselberry',
    state: 'FL',
    latitude: '28.660803',
    longitude: '-81.309834',
    timeZoneId: 'America/New_York',
  },
  '32708': {
    zip: '32708',
    city: 'Winter Springs',
    state: 'FL',
    latitude: '28.68102',
    longitude: '-81.2808',
    timeZoneId: 'America/New_York',
  },
  '32709': {
    zip: '32709',
    city: 'Christmas',
    state: 'FL',
    latitude: '28.516066',
    longitude: '-80.984322',
    timeZoneId: 'America/New_York',
  },
  '32710': {
    zip: '32710',
    city: 'Clarcona',
    state: 'FL',
    latitude: '28.610937',
    longitude: '-81.496326',
    timeZoneId: 'America/New_York',
  },
  '32712': {
    zip: '32712',
    city: 'Apopka',
    state: 'FL',
    latitude: '28.736116',
    longitude: '-81.538717',
    timeZoneId: 'America/New_York',
  },
  '32713': {
    zip: '32713',
    city: 'Debary',
    state: 'FL',
    latitude: '28.882535',
    longitude: '-81.306446',
    timeZoneId: 'America/New_York',
  },
  '32714': {
    zip: '32714',
    city: 'Altamonte Springs',
    state: 'FL',
    latitude: '28.66652',
    longitude: '-81.413231',
    timeZoneId: 'America/New_York',
  },
  '32715': {
    zip: '32715',
    city: 'Altamonte Springs',
    state: 'FL',
    latitude: '28.666741',
    longitude: '-81.359609',
    timeZoneId: 'America/New_York',
  },
  '32716': {
    zip: '32716',
    city: 'Altamonte Springs',
    state: 'FL',
    latitude: '28.663807',
    longitude: '-81.403206',
    timeZoneId: 'America/New_York',
  },
  '32718': {
    zip: '32718',
    city: 'Casselberry',
    state: 'FL',
    latitude: '28.663263',
    longitude: '-81.324774',
    timeZoneId: 'America/New_York',
  },
  '32719': {
    zip: '32719',
    city: 'Winter Springs',
    state: 'FL',
    latitude: '28.683684',
    longitude: '-81.276404',
    timeZoneId: 'America/New_York',
  },
  '32720': {
    zip: '32720',
    city: 'Deland',
    state: 'FL',
    latitude: '29.028004',
    longitude: '-81.325835',
    timeZoneId: 'America/New_York',
  },
  '32721': {
    zip: '32721',
    city: 'Deland',
    state: 'FL',
    latitude: '29.027194',
    longitude: '-81.295606',
    timeZoneId: 'America/New_York',
  },
  '32722': {
    zip: '32722',
    city: 'Glenwood',
    state: 'FL',
    latitude: '29.079239',
    longitude: '-81.352956',
    timeZoneId: 'America/New_York',
  },
  '32723': {
    zip: '32723',
    city: 'Deland',
    state: 'FL',
    latitude: '29.009964',
    longitude: '-81.308768',
    timeZoneId: 'America/New_York',
  },
  '32724': {
    zip: '32724',
    city: 'Deland',
    state: 'FL',
    latitude: '29.04151',
    longitude: '-81.285829',
    timeZoneId: 'America/New_York',
  },
  '32725': {
    zip: '32725',
    city: 'Deltona',
    state: 'FL',
    latitude: '28.898339',
    longitude: '-81.241296',
    timeZoneId: 'America/New_York',
  },
  '32726': {
    zip: '32726',
    city: 'Eustis',
    state: 'FL',
    latitude: '28.861188',
    longitude: '-81.618986',
    timeZoneId: 'America/New_York',
  },
  '32727': {
    zip: '32727',
    city: 'Eustis',
    state: 'FL',
    latitude: '28.831384',
    longitude: '-81.656911',
    timeZoneId: 'America/New_York',
  },
  '32728': {
    zip: '32728',
    city: 'Deltona',
    state: 'FL',
    latitude: '28.87149',
    longitude: '-81.23827',
    timeZoneId: 'America/New_York',
  },
  '32730': {
    zip: '32730',
    city: 'Casselberry',
    state: 'FL',
    latitude: '28.651184',
    longitude: '-81.345343',
    timeZoneId: 'America/New_York',
  },
  '32732': {
    zip: '32732',
    city: 'Geneva',
    state: 'FL',
    latitude: '28.745894',
    longitude: '-81.119322',
    timeZoneId: 'America/New_York',
  },
  '32733': {
    zip: '32733',
    city: 'Goldenrod',
    state: 'FL',
    latitude: '28.623648',
    longitude: '-81.264139',
    timeZoneId: 'America/New_York',
  },
  '32735': {
    zip: '32735',
    city: 'Grand Island',
    state: 'FL',
    latitude: '28.88805',
    longitude: '-81.735747',
    timeZoneId: 'America/New_York',
  },
  '32736': {
    zip: '32736',
    city: 'Eustis',
    state: 'FL',
    latitude: '28.8899',
    longitude: '-81.51475',
    timeZoneId: 'America/New_York',
  },
  '32738': {
    zip: '32738',
    city: 'Deltona',
    state: 'FL',
    latitude: '28.910545',
    longitude: '-81.192559',
    timeZoneId: 'America/New_York',
  },
  '32739': {
    zip: '32739',
    city: 'Deltona',
    state: 'FL',
    latitude: '28.91003',
    longitude: '-81.207277',
    timeZoneId: 'America/New_York',
  },
  '32744': {
    zip: '32744',
    city: 'Lake Helen',
    state: 'FL',
    latitude: '28.984058',
    longitude: '-81.234902',
    timeZoneId: 'America/New_York',
  },
  '32745': {
    zip: '32745',
    city: 'Mid Florida',
    state: 'FL',
    latitude: '28.78673',
    longitude: '-81.34713',
    timeZoneId: 'America/New_York',
  },
  '32746': {
    zip: '32746',
    city: 'Lake Mary',
    state: 'FL',
    latitude: '28.75992',
    longitude: '-81.34584',
    timeZoneId: 'America/New_York',
  },
  '32747': {
    zip: '32747',
    city: 'Lake Monroe',
    state: 'FL',
    latitude: '28.82221',
    longitude: '-81.319736',
    timeZoneId: 'America/New_York',
  },
  '32750': {
    zip: '32750',
    city: 'Longwood',
    state: 'FL',
    latitude: '28.70426',
    longitude: '-81.362395',
    timeZoneId: 'America/New_York',
  },
  '32751': {
    zip: '32751',
    city: 'Maitland',
    state: 'FL',
    latitude: '28.625621',
    longitude: '-81.355905',
    timeZoneId: 'America/New_York',
  },
  '32752': {
    zip: '32752',
    city: 'Longwood',
    state: 'FL',
    latitude: '28.712149',
    longitude: '-81.391509',
    timeZoneId: 'America/New_York',
  },
  '32753': {
    zip: '32753',
    city: 'Debary',
    state: 'FL',
    latitude: '28.880831',
    longitude: '-81.30633',
    timeZoneId: 'America/New_York',
  },
  '32754': {
    zip: '32754',
    city: 'Mims',
    state: 'FL',
    latitude: '28.691588',
    longitude: '-80.864509',
    timeZoneId: 'America/New_York',
  },
  '32756': {
    zip: '32756',
    city: 'Mount Dora',
    state: 'FL',
    latitude: '28.804712',
    longitude: '-81.644149',
    timeZoneId: 'America/New_York',
  },
  '32757': {
    zip: '32757',
    city: 'Mount Dora',
    state: 'FL',
    latitude: '28.77412',
    longitude: '-81.628286',
    timeZoneId: 'America/New_York',
  },
  '32759': {
    zip: '32759',
    city: 'Oak Hill',
    state: 'FL',
    latitude: '28.863237',
    longitude: '-80.861361',
    timeZoneId: 'America/New_York',
  },
  '32762': {
    zip: '32762',
    city: 'Oviedo',
    state: 'FL',
    latitude: '28.658287',
    longitude: '-81.181302',
    timeZoneId: 'America/New_York',
  },
  '32763': {
    zip: '32763',
    city: 'Orange City',
    state: 'FL',
    latitude: '28.943912',
    longitude: '-81.301782',
    timeZoneId: 'America/New_York',
  },
  '32764': {
    zip: '32764',
    city: 'Osteen',
    state: 'FL',
    latitude: '28.848035',
    longitude: '-81.14074',
    timeZoneId: 'America/New_York',
  },
  '32765': {
    zip: '32765',
    city: 'Oviedo',
    state: 'FL',
    latitude: '28.648105',
    longitude: '-81.210071',
    timeZoneId: 'America/New_York',
  },
  '32766': {
    zip: '32766',
    city: 'Oviedo',
    state: 'FL',
    latitude: '28.647707',
    longitude: '-81.150362',
    timeZoneId: 'America/New_York',
  },
  '32767': {
    zip: '32767',
    city: 'Paisley',
    state: 'FL',
    latitude: '28.977072',
    longitude: '-81.50883',
    timeZoneId: 'America/New_York',
  },
  '32768': {
    zip: '32768',
    city: 'Plymouth',
    state: 'FL',
    latitude: '28.697511',
    longitude: '-81.555408',
    timeZoneId: 'America/New_York',
  },
  '32771': {
    zip: '32771',
    city: 'Sanford',
    state: 'FL',
    latitude: '28.79419',
    longitude: '-81.297139',
    timeZoneId: 'America/New_York',
  },
  '32772': {
    zip: '32772',
    city: 'Sanford',
    state: 'FL',
    latitude: '28.767538',
    longitude: '-81.246852',
    timeZoneId: 'America/New_York',
  },
  '32773': {
    zip: '32773',
    city: 'Sanford',
    state: 'FL',
    latitude: '28.765778',
    longitude: '-81.276553',
    timeZoneId: 'America/New_York',
  },
  '32774': {
    zip: '32774',
    city: 'Orange City',
    state: 'FL',
    latitude: '28.951285',
    longitude: '-81.299272',
    timeZoneId: 'America/New_York',
  },
  '32775': {
    zip: '32775',
    city: 'Scottsmoor',
    state: 'FL',
    latitude: '28.76913',
    longitude: '-80.873824',
    timeZoneId: 'America/New_York',
  },
  '32776': {
    zip: '32776',
    city: 'Sorrento',
    state: 'FL',
    latitude: '28.804779',
    longitude: '-81.535369',
    timeZoneId: 'America/New_York',
  },
  '32777': {
    zip: '32777',
    city: 'Tangerine',
    state: 'FL',
    latitude: '28.760814',
    longitude: '-81.62564',
    timeZoneId: 'America/New_York',
  },
  '32778': {
    zip: '32778',
    city: 'Tavares',
    state: 'FL',
    latitude: '28.801667',
    longitude: '-81.736253',
    timeZoneId: 'America/New_York',
  },
  '32779': {
    zip: '32779',
    city: 'Longwood',
    state: 'FL',
    latitude: '28.705154',
    longitude: '-81.420524',
    timeZoneId: 'America/New_York',
  },
  '32780': {
    zip: '32780',
    city: 'Titusville',
    state: 'FL',
    latitude: '28.572256',
    longitude: '-80.820946',
    timeZoneId: 'America/New_York',
  },
  '32781': {
    zip: '32781',
    city: 'Titusville',
    state: 'FL',
    latitude: '28.535305',
    longitude: '-80.812429',
    timeZoneId: 'America/New_York',
  },
  '32783': {
    zip: '32783',
    city: 'Titusville',
    state: 'FL',
    latitude: '28.543118',
    longitude: '-80.855534',
    timeZoneId: 'America/New_York',
  },
  '32784': {
    zip: '32784',
    city: 'Umatilla',
    state: 'FL',
    latitude: '28.92794',
    longitude: '-81.664305',
    timeZoneId: 'America/New_York',
  },
  '32789': {
    zip: '32789',
    city: 'Winter Park',
    state: 'FL',
    latitude: '28.599717',
    longitude: '-81.357134',
    timeZoneId: 'America/New_York',
  },
  '32790': {
    zip: '32790',
    city: 'Winter Park',
    state: 'FL',
    latitude: '28.589404',
    longitude: '-81.330445',
    timeZoneId: 'America/New_York',
  },
  '32791': {
    zip: '32791',
    city: 'Longwood',
    state: 'FL',
    latitude: '28.703312',
    longitude: '-81.419952',
    timeZoneId: 'America/New_York',
  },
  '32792': {
    zip: '32792',
    city: 'Winter Park',
    state: 'FL',
    latitude: '28.595103',
    longitude: '-81.304723',
    timeZoneId: 'America/New_York',
  },
  '32793': {
    zip: '32793',
    city: 'Winter Park',
    state: 'FL',
    latitude: '28.662002',
    longitude: '-81.48316',
    timeZoneId: 'America/New_York',
  },
  '32794': {
    zip: '32794',
    city: 'Maitland',
    state: 'FL',
    latitude: '28.627618',
    longitude: '-81.365615',
    timeZoneId: 'America/New_York',
  },
  '32795': {
    zip: '32795',
    city: 'Lake Mary',
    state: 'FL',
    latitude: '28.758848',
    longitude: '-81.331901',
    timeZoneId: 'America/New_York',
  },
  '32796': {
    zip: '32796',
    city: 'Titusville',
    state: 'FL',
    latitude: '28.621623',
    longitude: '-80.840117',
    timeZoneId: 'America/New_York',
  },
  '32798': {
    zip: '32798',
    city: 'Zellwood',
    state: 'FL',
    latitude: '28.72008',
    longitude: '-81.573895',
    timeZoneId: 'America/New_York',
  },
  '32799': {
    zip: '32799',
    city: 'Mid Florida',
    state: 'FL',
    latitude: '28.630233',
    longitude: '-81.354944',
    timeZoneId: 'America/New_York',
  },
  '32801': {
    zip: '32801',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.538375',
    longitude: '-81.374693',
    timeZoneId: 'America/New_York',
  },
  '32802': {
    zip: '32802',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.543808',
    longitude: '-81.377151',
    timeZoneId: 'America/New_York',
  },
  '32803': {
    zip: '32803',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.558279',
    longitude: '-81.357567',
    timeZoneId: 'America/New_York',
  },
  '32804': {
    zip: '32804',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.57378',
    longitude: '-81.39719',
    timeZoneId: 'America/New_York',
  },
  '32805': {
    zip: '32805',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.531427',
    longitude: '-81.40751',
    timeZoneId: 'America/New_York',
  },
  '32806': {
    zip: '32806',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.510504',
    longitude: '-81.356954',
    timeZoneId: 'America/New_York',
  },
  '32807': {
    zip: '32807',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.559275',
    longitude: '-81.302867',
    timeZoneId: 'America/New_York',
  },
  '32808': {
    zip: '32808',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.57263',
    longitude: '-81.442973',
    timeZoneId: 'America/New_York',
  },
  '32809': {
    zip: '32809',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.471095',
    longitude: '-81.389356',
    timeZoneId: 'America/New_York',
  },
  '32810': {
    zip: '32810',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.61821',
    longitude: '-81.44206',
    timeZoneId: 'America/New_York',
  },
  '32811': {
    zip: '32811',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.522969',
    longitude: '-81.44305',
    timeZoneId: 'America/New_York',
  },
  '32812': {
    zip: '32812',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.488781',
    longitude: '-81.324101',
    timeZoneId: 'America/New_York',
  },
  '32814': {
    zip: '32814',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.555519',
    longitude: '-81.347458',
    timeZoneId: 'America/New_York',
  },
  '32815': {
    zip: '32815',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.59229',
    longitude: '-80.656063',
    timeZoneId: 'America/New_York',
  },
  '32816': {
    zip: '32816',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.606119',
    longitude: '-81.204801',
    timeZoneId: 'America/New_York',
  },
  '32817': {
    zip: '32817',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.582385',
    longitude: '-81.251475',
    timeZoneId: 'America/New_York',
  },
  '32818': {
    zip: '32818',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.57755',
    longitude: '-81.496134',
    timeZoneId: 'America/New_York',
  },
  '32819': {
    zip: '32819',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.460252',
    longitude: '-81.475492',
    timeZoneId: 'America/New_York',
  },
  '32820': {
    zip: '32820',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.588392',
    longitude: '-81.123204',
    timeZoneId: 'America/New_York',
  },
  '32821': {
    zip: '32821',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.379055',
    longitude: '-81.467846',
    timeZoneId: 'America/New_York',
  },
  '32822': {
    zip: '32822',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.505058',
    longitude: '-81.290138',
    timeZoneId: 'America/New_York',
  },
  '32824': {
    zip: '32824',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.403466',
    longitude: '-81.360078',
    timeZoneId: 'America/New_York',
  },
  '32825': {
    zip: '32825',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.522353',
    longitude: '-81.227289',
    timeZoneId: 'America/New_York',
  },
  '32826': {
    zip: '32826',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.580464',
    longitude: '-81.186488',
    timeZoneId: 'America/New_York',
  },
  '32827': {
    zip: '32827',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.427444',
    longitude: '-81.316938',
    timeZoneId: 'America/New_York',
  },
  '32828': {
    zip: '32828',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.545909',
    longitude: '-81.181177',
    timeZoneId: 'America/New_York',
  },
  '32829': {
    zip: '32829',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.463802',
    longitude: '-81.220717',
    timeZoneId: 'America/New_York',
  },
  '32830': {
    zip: '32830',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.370015',
    longitude: '-81.556799',
    timeZoneId: 'America/New_York',
  },
  '32831': {
    zip: '32831',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.473036',
    longitude: '-81.136938',
    timeZoneId: 'America/New_York',
  },
  '32832': {
    zip: '32832',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.40396',
    longitude: '-81.170142',
    timeZoneId: 'America/New_York',
  },
  '32833': {
    zip: '32833',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.48635',
    longitude: '-81.08473',
    timeZoneId: 'America/New_York',
  },
  '32834': {
    zip: '32834',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.563579',
    longitude: '-81.206956',
    timeZoneId: 'America/New_York',
  },
  '32835': {
    zip: '32835',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.5166',
    longitude: '-81.481682',
    timeZoneId: 'America/New_York',
  },
  '32836': {
    zip: '32836',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.427503',
    longitude: '-81.507039',
    timeZoneId: 'America/New_York',
  },
  '32837': {
    zip: '32837',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.392466',
    longitude: '-81.41782',
    timeZoneId: 'America/New_York',
  },
  '32839': {
    zip: '32839',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.487458',
    longitude: '-81.403507',
    timeZoneId: 'America/New_York',
  },
  '32853': {
    zip: '32853',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.557246',
    longitude: '-81.349131',
    timeZoneId: 'America/New_York',
  },
  '32854': {
    zip: '32854',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.568324',
    longitude: '-81.38626',
    timeZoneId: 'America/New_York',
  },
  '32855': {
    zip: '32855',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.509349',
    longitude: '-81.390108',
    timeZoneId: 'America/New_York',
  },
  '32856': {
    zip: '32856',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.513146',
    longitude: '-81.371713',
    timeZoneId: 'America/New_York',
  },
  '32857': {
    zip: '32857',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.56054',
    longitude: '-81.296712',
    timeZoneId: 'America/New_York',
  },
  '32858': {
    zip: '32858',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.554201',
    longitude: '-81.449787',
    timeZoneId: 'America/New_York',
  },
  '32859': {
    zip: '32859',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.462121',
    longitude: '-81.385294',
    timeZoneId: 'America/New_York',
  },
  '32860': {
    zip: '32860',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.624797',
    longitude: '-81.429589',
    timeZoneId: 'America/New_York',
  },
  '32861': {
    zip: '32861',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.516161',
    longitude: '-81.44405',
    timeZoneId: 'America/New_York',
  },
  '32862': {
    zip: '32862',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.538739',
    longitude: '-81.377138',
    timeZoneId: 'America/New_York',
  },
  '32867': {
    zip: '32867',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.567313',
    longitude: '-81.255496',
    timeZoneId: 'America/New_York',
  },
  '32868': {
    zip: '32868',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.590178',
    longitude: '-81.49363',
    timeZoneId: 'America/New_York',
  },
  '32869': {
    zip: '32869',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.591236',
    longitude: '-81.492691',
    timeZoneId: 'America/New_York',
  },
  '32872': {
    zip: '32872',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.539939',
    longitude: '-81.372604',
    timeZoneId: 'America/New_York',
  },
  '32877': {
    zip: '32877',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.544639',
    longitude: '-81.376096',
    timeZoneId: 'America/New_York',
  },
  '32878': {
    zip: '32878',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.526291',
    longitude: '-81.170878',
    timeZoneId: 'America/New_York',
  },
  '32885': {
    zip: '32885',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.539323',
    longitude: '-81.379368',
    timeZoneId: 'America/New_York',
  },
  '32886': {
    zip: '32886',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.541241',
    longitude: '-81.380821',
    timeZoneId: 'America/New_York',
  },
  '32887': {
    zip: '32887',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.384748',
    longitude: '-81.467936',
    timeZoneId: 'America/New_York',
  },
  '32891': {
    zip: '32891',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.556599',
    longitude: '-81.349587',
    timeZoneId: 'America/New_York',
  },
  '32896': {
    zip: '32896',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.537421',
    longitude: '-81.381153',
    timeZoneId: 'America/New_York',
  },
  '32897': {
    zip: '32897',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.538957',
    longitude: '-81.379064',
    timeZoneId: 'America/New_York',
  },
  '32899': {
    zip: '32899',
    city: 'Orlando',
    state: 'FL',
    latitude: '28.611682',
    longitude: '-80.677691',
    timeZoneId: 'America/New_York',
  },
  '32901': {
    zip: '32901',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.074083',
    longitude: '-80.630823',
    timeZoneId: 'America/New_York',
  },
  '32902': {
    zip: '32902',
    city: 'Melbourne',
    state: 'FL',
    latitude: '27.99438',
    longitude: '-80.646933',
    timeZoneId: 'America/New_York',
  },
  '32903': {
    zip: '32903',
    city: 'Indialantic',
    state: 'FL',
    latitude: '28.116342',
    longitude: '-80.582287',
    timeZoneId: 'America/New_York',
  },
  '32904': {
    zip: '32904',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.074548',
    longitude: '-80.694237',
    timeZoneId: 'America/New_York',
  },
  '32905': {
    zip: '32905',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '28.028121',
    longitude: '-80.601039',
    timeZoneId: 'America/New_York',
  },
  '32906': {
    zip: '32906',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '28.012469',
    longitude: '-80.627582',
    timeZoneId: 'America/New_York',
  },
  '32907': {
    zip: '32907',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '28.015502',
    longitude: '-80.669053',
    timeZoneId: 'America/New_York',
  },
  '32908': {
    zip: '32908',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '27.980227',
    longitude: '-80.688532',
    timeZoneId: 'America/New_York',
  },
  '32909': {
    zip: '32909',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '27.969161',
    longitude: '-80.647489',
    timeZoneId: 'America/New_York',
  },
  '32910': {
    zip: '32910',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '27.997533',
    longitude: '-80.691463',
    timeZoneId: 'America/New_York',
  },
  '32911': {
    zip: '32911',
    city: 'Palm Bay',
    state: 'FL',
    latitude: '28.018087',
    longitude: '-80.682599',
    timeZoneId: 'America/New_York',
  },
  '32912': {
    zip: '32912',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.078258',
    longitude: '-80.603624',
    timeZoneId: 'America/New_York',
  },
  '32919': {
    zip: '32919',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.077407',
    longitude: '-80.631614',
    timeZoneId: 'America/New_York',
  },
  '32920': {
    zip: '32920',
    city: 'Cape Canaveral',
    state: 'FL',
    latitude: '28.391416',
    longitude: '-80.605396',
    timeZoneId: 'America/New_York',
  },
  '32922': {
    zip: '32922',
    city: 'Cocoa',
    state: 'FL',
    latitude: '28.363967',
    longitude: '-80.747039',
    timeZoneId: 'America/New_York',
  },
  '32923': {
    zip: '32923',
    city: 'Cocoa',
    state: 'FL',
    latitude: '28.26075',
    longitude: '-80.705796',
    timeZoneId: 'America/New_York',
  },
  '32924': {
    zip: '32924',
    city: 'Cocoa',
    state: 'FL',
    latitude: '28.262067',
    longitude: '-80.705413',
    timeZoneId: 'America/New_York',
  },
  '32925': {
    zip: '32925',
    city: 'Patrick AFB',
    state: 'FL',
    latitude: '28.229987',
    longitude: '-80.623528',
    timeZoneId: 'America/New_York',
  },
  '32926': {
    zip: '32926',
    city: 'Cocoa',
    state: 'FL',
    latitude: '28.387668',
    longitude: '-80.791132',
    timeZoneId: 'America/New_York',
  },
  '32927': {
    zip: '32927',
    city: 'Cocoa',
    state: 'FL',
    latitude: '28.470183',
    longitude: '-80.797492',
    timeZoneId: 'America/New_York',
  },
  '32931': {
    zip: '32931',
    city: 'Cocoa Beach',
    state: 'FL',
    latitude: '28.329058',
    longitude: '-80.611545',
    timeZoneId: 'America/New_York',
  },
  '32932': {
    zip: '32932',
    city: 'Cocoa Beach',
    state: 'FL',
    latitude: '28.31812',
    longitude: '-80.612743',
    timeZoneId: 'America/New_York',
  },
  '32934': {
    zip: '32934',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.142973',
    longitude: '-80.696349',
    timeZoneId: 'America/New_York',
  },
  '32935': {
    zip: '32935',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.138545',
    longitude: '-80.657969',
    timeZoneId: 'America/New_York',
  },
  '32936': {
    zip: '32936',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.137568',
    longitude: '-80.650157',
    timeZoneId: 'America/New_York',
  },
  '32937': {
    zip: '32937',
    city: 'Satellite Beach',
    state: 'FL',
    latitude: '28.172827',
    longitude: '-80.598673',
    timeZoneId: 'America/New_York',
  },
  '32940': {
    zip: '32940',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.22581',
    longitude: '-80.688308',
    timeZoneId: 'America/New_York',
  },
  '32941': {
    zip: '32941',
    city: 'Melbourne',
    state: 'FL',
    latitude: '28.077442',
    longitude: '-80.628489',
    timeZoneId: 'America/New_York',
  },
  '32948': {
    zip: '32948',
    city: 'Fellsmere',
    state: 'FL',
    latitude: '27.769713',
    longitude: '-80.601532',
    timeZoneId: 'America/New_York',
  },
  '32949': {
    zip: '32949',
    city: 'Grant',
    state: 'FL',
    latitude: '27.92554',
    longitude: '-80.53179',
    timeZoneId: 'America/New_York',
  },
  '32950': {
    zip: '32950',
    city: 'Malabar',
    state: 'FL',
    latitude: '27.985689',
    longitude: '-80.589258',
    timeZoneId: 'America/New_York',
  },
  '32951': {
    zip: '32951',
    city: 'Melbourne Beach',
    state: 'FL',
    latitude: '28.024675',
    longitude: '-80.539219',
    timeZoneId: 'America/New_York',
  },
  '32952': {
    zip: '32952',
    city: 'Merritt Island',
    state: 'FL',
    latitude: '28.342491',
    longitude: '-80.675378',
    timeZoneId: 'America/New_York',
  },
  '32953': {
    zip: '32953',
    city: 'Merritt Island',
    state: 'FL',
    latitude: '28.400081',
    longitude: '-80.705159',
    timeZoneId: 'America/New_York',
  },
  '32954': {
    zip: '32954',
    city: 'Merritt Island',
    state: 'FL',
    latitude: '28.307863',
    longitude: '-80.663103',
    timeZoneId: 'America/New_York',
  },
  '32955': {
    zip: '32955',
    city: 'Rockledge',
    state: 'FL',
    latitude: '28.313343',
    longitude: '-80.723283',
    timeZoneId: 'America/New_York',
  },
  '32956': {
    zip: '32956',
    city: 'Rockledge',
    state: 'FL',
    latitude: '28.316875',
    longitude: '-80.727762',
    timeZoneId: 'America/New_York',
  },
  '32957': {
    zip: '32957',
    city: 'Roseland',
    state: 'FL',
    latitude: '27.831962',
    longitude: '-80.492354',
    timeZoneId: 'America/New_York',
  },
  '32958': {
    zip: '32958',
    city: 'Sebastian',
    state: 'FL',
    latitude: '27.783115',
    longitude: '-80.483191',
    timeZoneId: 'America/New_York',
  },
  '32959': {
    zip: '32959',
    city: 'Sharpes',
    state: 'FL',
    latitude: '28.460307',
    longitude: '-80.827428',
    timeZoneId: 'America/New_York',
  },
  '32960': {
    zip: '32960',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.65128',
    longitude: '-80.417512',
    timeZoneId: 'America/New_York',
  },
  '32961': {
    zip: '32961',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.630902',
    longitude: '-80.379791',
    timeZoneId: 'America/New_York',
  },
  '32962': {
    zip: '32962',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.589213',
    longitude: '-80.410201',
    timeZoneId: 'America/New_York',
  },
  '32963': {
    zip: '32963',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.647905',
    longitude: '-80.360668',
    timeZoneId: 'America/New_York',
  },
  '32964': {
    zip: '32964',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.637858',
    longitude: '-80.399918',
    timeZoneId: 'America/New_York',
  },
  '32965': {
    zip: '32965',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.645533',
    longitude: '-80.394153',
    timeZoneId: 'America/New_York',
  },
  '32966': {
    zip: '32966',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.637794',
    longitude: '-80.475396',
    timeZoneId: 'America/New_York',
  },
  '32967': {
    zip: '32967',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.702306',
    longitude: '-80.456836',
    timeZoneId: 'America/New_York',
  },
  '32968': {
    zip: '32968',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.596949',
    longitude: '-80.452283',
    timeZoneId: 'America/New_York',
  },
  '32969': {
    zip: '32969',
    city: 'Vero Beach',
    state: 'FL',
    latitude: '27.638947',
    longitude: '-80.397672',
    timeZoneId: 'America/New_York',
  },
  '32970': {
    zip: '32970',
    city: 'Wabasso',
    state: 'FL',
    latitude: '27.751915',
    longitude: '-80.447757',
    timeZoneId: 'America/New_York',
  },
  '32971': {
    zip: '32971',
    city: 'Winter Beach',
    state: 'FL',
    latitude: '27.714624',
    longitude: '-80.422408',
    timeZoneId: 'America/New_York',
  },
  '32976': {
    zip: '32976',
    city: 'Sebastian',
    state: 'FL',
    latitude: '27.880728',
    longitude: '-80.51623',
    timeZoneId: 'America/New_York',
  },
  '32978': {
    zip: '32978',
    city: 'Sebastian',
    state: 'FL',
    latitude: '27.792642',
    longitude: '-80.486816',
    timeZoneId: 'America/New_York',
  },
  '33001': {
    zip: '33001',
    city: 'Long Key',
    state: 'FL',
    latitude: '24.816667',
    longitude: '-80.816667',
    timeZoneId: 'America/New_York',
  },
  '33002': {
    zip: '33002',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.901949',
    longitude: '-80.305033',
    timeZoneId: 'America/New_York',
  },
  '33004': {
    zip: '33004',
    city: 'Dania',
    state: 'FL',
    latitude: '26.051174',
    longitude: '-80.147034',
    timeZoneId: 'America/New_York',
  },
  '33008': {
    zip: '33008',
    city: 'Hallandale',
    state: 'FL',
    latitude: '25.986952',
    longitude: '-80.140539',
    timeZoneId: 'America/New_York',
  },
  '33009': {
    zip: '33009',
    city: 'Hallandale',
    state: 'FL',
    latitude: '25.986228',
    longitude: '-80.149188',
    timeZoneId: 'America/New_York',
  },
  '33010': {
    zip: '33010',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.82898',
    longitude: '-80.28564',
    timeZoneId: 'America/New_York',
  },
  '33011': {
    zip: '33011',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.822221',
    longitude: '-80.284112',
    timeZoneId: 'America/New_York',
  },
  '33012': {
    zip: '33012',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.865517',
    longitude: '-80.297427',
    timeZoneId: 'America/New_York',
  },
  '33013': {
    zip: '33013',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.862177',
    longitude: '-80.270734',
    timeZoneId: 'America/New_York',
  },
  '33014': {
    zip: '33014',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.901437',
    longitude: '-80.306472',
    timeZoneId: 'America/New_York',
  },
  '33015': {
    zip: '33015',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.94077',
    longitude: '-80.315677',
    timeZoneId: 'America/New_York',
  },
  '33016': {
    zip: '33016',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.890138',
    longitude: '-80.341466',
    timeZoneId: 'America/New_York',
  },
  '33017': {
    zip: '33017',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.943386',
    longitude: '-80.318215',
    timeZoneId: 'America/New_York',
  },
  '33018': {
    zip: '33018',
    city: 'Hialeah',
    state: 'FL',
    latitude: '25.94232',
    longitude: '-80.3904',
    timeZoneId: 'America/New_York',
  },
  '33019': {
    zip: '33019',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.021851',
    longitude: '-80.123476',
    timeZoneId: 'America/New_York',
  },
  '33020': {
    zip: '33020',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.016843',
    longitude: '-80.15108',
    timeZoneId: 'America/New_York',
  },
  '33021': {
    zip: '33021',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.026253',
    longitude: '-80.189124',
    timeZoneId: 'America/New_York',
  },
  '33022': {
    zip: '33022',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.009694',
    longitude: '-80.144836',
    timeZoneId: 'America/New_York',
  },
  '33023': {
    zip: '33023',
    city: 'Hollywood',
    state: 'FL',
    latitude: '25.986968',
    longitude: '-80.20656',
    timeZoneId: 'America/New_York',
  },
  '33024': {
    zip: '33024',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.01867',
    longitude: '-80.258199',
    timeZoneId: 'America/New_York',
  },
  '33025': {
    zip: '33025',
    city: 'Hollywood',
    state: 'FL',
    latitude: '25.992876',
    longitude: '-80.274221',
    timeZoneId: 'America/New_York',
  },
  '33026': {
    zip: '33026',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.028424',
    longitude: '-80.296813',
    timeZoneId: 'America/New_York',
  },
  '33027': {
    zip: '33027',
    city: 'Hollywood',
    state: 'FL',
    latitude: '25.978093',
    longitude: '-80.330196',
    timeZoneId: 'America/New_York',
  },
  '33028': {
    zip: '33028',
    city: 'Pembroke Pines',
    state: 'FL',
    latitude: '26.0289',
    longitude: '-80.3447',
    timeZoneId: 'America/New_York',
  },
  '33029': {
    zip: '33029',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.006742',
    longitude: '-80.428893',
    timeZoneId: 'America/New_York',
  },
  '33030': {
    zip: '33030',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.481403',
    longitude: '-80.505418',
    timeZoneId: 'America/New_York',
  },
  '33031': {
    zip: '33031',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.526883',
    longitude: '-80.482539',
    timeZoneId: 'America/New_York',
  },
  '33032': {
    zip: '33032',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.526149',
    longitude: '-80.406867',
    timeZoneId: 'America/New_York',
  },
  '33033': {
    zip: '33033',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.494244',
    longitude: '-80.432291',
    timeZoneId: 'America/New_York',
  },
  '33034': {
    zip: '33034',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.450256',
    longitude: '-80.489973',
    timeZoneId: 'America/New_York',
  },
  '33035': {
    zip: '33035',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.379693',
    longitude: '-80.406632',
    timeZoneId: 'America/New_York',
  },
  '33036': {
    zip: '33036',
    city: 'Islamorada',
    state: 'FL',
    latitude: '24.921276',
    longitude: '-80.630142',
    timeZoneId: 'America/New_York',
  },
  '33037': {
    zip: '33037',
    city: 'Key Largo',
    state: 'FL',
    latitude: '25.130791',
    longitude: '-80.410224',
    timeZoneId: 'America/New_York',
  },
  '33039': {
    zip: '33039',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.507761',
    longitude: '-80.409021',
    timeZoneId: 'America/New_York',
  },
  '33040': {
    zip: '33040',
    city: 'Key West',
    state: 'FL',
    latitude: '24.562514',
    longitude: '-81.761061',
    timeZoneId: 'America/New_York',
  },
  '33041': {
    zip: '33041',
    city: 'Key West',
    state: 'FL',
    latitude: '24.558003',
    longitude: '-81.808398',
    timeZoneId: 'America/New_York',
  },
  '33042': {
    zip: '33042',
    city: 'Summerland Key',
    state: 'FL',
    latitude: '24.657645',
    longitude: '-81.454218',
    timeZoneId: 'America/New_York',
  },
  '33043': {
    zip: '33043',
    city: 'Big Pine Key',
    state: 'FL',
    latitude: '24.678501',
    longitude: '-81.368387',
    timeZoneId: 'America/New_York',
  },
  '33045': {
    zip: '33045',
    city: 'Key West',
    state: 'FL',
    latitude: '24.557701',
    longitude: '-81.805496',
    timeZoneId: 'America/New_York',
  },
  '33050': {
    zip: '33050',
    city: 'Marathon',
    state: 'FL',
    latitude: '24.723614',
    longitude: '-81.046878',
    timeZoneId: 'America/New_York',
  },
  '33051': {
    zip: '33051',
    city: 'Key Colony Beach',
    state: 'FL',
    latitude: '24.721329',
    longitude: '-81.021245',
    timeZoneId: 'America/New_York',
  },
  '33052': {
    zip: '33052',
    city: 'Marathon Shores',
    state: 'FL',
    latitude: '24.724766',
    longitude: '-81.062436',
    timeZoneId: 'America/New_York',
  },
  '33054': {
    zip: '33054',
    city: 'Opa Locka',
    state: 'FL',
    latitude: '25.90529',
    longitude: '-80.25716',
    timeZoneId: 'America/New_York',
  },
  '33055': {
    zip: '33055',
    city: 'Opa Locka',
    state: 'FL',
    latitude: '25.943016',
    longitude: '-80.276516',
    timeZoneId: 'America/New_York',
  },
  '33056': {
    zip: '33056',
    city: 'Miami Gardens',
    state: 'FL',
    latitude: '25.946322',
    longitude: '-80.247066',
    timeZoneId: 'America/New_York',
  },
  '33060': {
    zip: '33060',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.227867',
    longitude: '-80.120189',
    timeZoneId: 'America/New_York',
  },
  '33061': {
    zip: '33061',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.236516',
    longitude: '-80.120613',
    timeZoneId: 'America/New_York',
  },
  '33062': {
    zip: '33062',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.234821',
    longitude: '-80.093841',
    timeZoneId: 'America/New_York',
  },
  '33063': {
    zip: '33063',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.248973',
    longitude: '-80.206871',
    timeZoneId: 'America/New_York',
  },
  '33064': {
    zip: '33064',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.2779',
    longitude: '-80.14782',
    timeZoneId: 'America/New_York',
  },
  '33065': {
    zip: '33065',
    city: 'Coral Springs',
    state: 'FL',
    latitude: '26.268785',
    longitude: '-80.255243',
    timeZoneId: 'America/New_York',
  },
  '33066': {
    zip: '33066',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.247211',
    longitude: '-80.174433',
    timeZoneId: 'America/New_York',
  },
  '33067': {
    zip: '33067',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.309681',
    longitude: '-80.220464',
    timeZoneId: 'America/New_York',
  },
  '33068': {
    zip: '33068',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.216234',
    longitude: '-80.216437',
    timeZoneId: 'America/New_York',
  },
  '33069': {
    zip: '33069',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.233635',
    longitude: '-80.156758',
    timeZoneId: 'America/New_York',
  },
  '33070': {
    zip: '33070',
    city: 'Tavernier',
    state: 'FL',
    latitude: '25.000353',
    longitude: '-80.537936',
    timeZoneId: 'America/New_York',
  },
  '33071': {
    zip: '33071',
    city: 'Coral Springs',
    state: 'FL',
    latitude: '26.243112',
    longitude: '-80.261973',
    timeZoneId: 'America/New_York',
  },
  '33072': {
    zip: '33072',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.233068',
    longitude: '-80.09421',
    timeZoneId: 'America/New_York',
  },
  '33073': {
    zip: '33073',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.305181',
    longitude: '-80.187797',
    timeZoneId: 'America/New_York',
  },
  '33074': {
    zip: '33074',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.238998',
    longitude: '-80.124146',
    timeZoneId: 'America/New_York',
  },
  '33075': {
    zip: '33075',
    city: 'Coral Springs',
    state: 'FL',
    latitude: '26.244645',
    longitude: '-80.26276',
    timeZoneId: 'America/New_York',
  },
  '33076': {
    zip: '33076',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.26688',
    longitude: '-80.28456',
    timeZoneId: 'America/New_York',
  },
  '33077': {
    zip: '33077',
    city: 'Pompano Beach',
    state: 'FL',
    latitude: '26.246438',
    longitude: '-80.263929',
    timeZoneId: 'America/New_York',
  },
  '33081': {
    zip: '33081',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.017272',
    longitude: '-80.19999',
    timeZoneId: 'America/New_York',
  },
  '33082': {
    zip: '33082',
    city: 'Pembroke Pines',
    state: 'FL',
    latitude: '26.013469',
    longitude: '-80.203516',
    timeZoneId: 'America/New_York',
  },
  '33083': {
    zip: '33083',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.148688',
    longitude: '-80.224835',
    timeZoneId: 'America/New_York',
  },
  '33084': {
    zip: '33084',
    city: 'Hollywood',
    state: 'FL',
    latitude: '26.025865',
    longitude: '-80.243456',
    timeZoneId: 'America/New_York',
  },
  '33090': {
    zip: '33090',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.47777',
    longitude: '-80.542042',
    timeZoneId: 'America/New_York',
  },
  '33092': {
    zip: '33092',
    city: 'Homestead',
    state: 'FL',
    latitude: '25.546985',
    longitude: '-80.424765',
    timeZoneId: 'America/New_York',
  },
  '33093': {
    zip: '33093',
    city: 'Margate',
    state: 'FL',
    latitude: '26.252723',
    longitude: '-80.209522',
    timeZoneId: 'America/New_York',
  },
  '33097': {
    zip: '33097',
    city: 'Coconut Creek',
    state: 'FL',
    latitude: '26.235803',
    longitude: '-80.133635',
    timeZoneId: 'America/New_York',
  },
  '33101': {
    zip: '33101',
    city: 'Miami',
    state: 'FL',
    latitude: '25.779127',
    longitude: '-80.19772',
    timeZoneId: 'America/New_York',
  },
  '33102': {
    zip: '33102',
    city: 'Miami',
    state: 'FL',
    latitude: '25.796138',
    longitude: '-80.301056',
    timeZoneId: 'America/New_York',
  },
  '33106': {
    zip: '33106',
    city: 'Miami',
    state: 'FL',
    latitude: '25.796832',
    longitude: '-80.301438',
    timeZoneId: 'America/New_York',
  },
  '33109': {
    zip: '33109',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.796586',
    longitude: '-80.28695',
    timeZoneId: 'America/New_York',
  },
  '33111': {
    zip: '33111',
    city: 'Miami',
    state: 'FL',
    latitude: '25.766472',
    longitude: '-80.19477',
    timeZoneId: 'America/New_York',
  },
  '33112': {
    zip: '33112',
    city: 'Miami',
    state: 'FL',
    latitude: '25.79665',
    longitude: '-80.341619',
    timeZoneId: 'America/New_York',
  },
  '33114': {
    zip: '33114',
    city: 'Coral Gables',
    state: 'FL',
    latitude: '25.752299',
    longitude: '-80.270652',
    timeZoneId: 'America/New_York',
  },
  '33116': {
    zip: '33116',
    city: 'Miami',
    state: 'FL',
    latitude: '25.671227',
    longitude: '-80.372256',
    timeZoneId: 'America/New_York',
  },
  '33119': {
    zip: '33119',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.786672',
    longitude: '-80.133513',
    timeZoneId: 'America/New_York',
  },
  '33122': {
    zip: '33122',
    city: 'Miami',
    state: 'FL',
    latitude: '25.799007',
    longitude: '-80.300688',
    timeZoneId: 'America/New_York',
  },
  '33124': {
    zip: '33124',
    city: 'Miami',
    state: 'FL',
    latitude: '25.726412',
    longitude: '-80.238',
    timeZoneId: 'America/New_York',
  },
  '33125': {
    zip: '33125',
    city: 'Miami',
    state: 'FL',
    latitude: '25.781311',
    longitude: '-80.236441',
    timeZoneId: 'America/New_York',
  },
  '33126': {
    zip: '33126',
    city: 'Miami',
    state: 'FL',
    latitude: '25.774119',
    longitude: '-80.290919',
    timeZoneId: 'America/New_York',
  },
  '33127': {
    zip: '33127',
    city: 'Miami',
    state: 'FL',
    latitude: '25.816413',
    longitude: '-80.206972',
    timeZoneId: 'America/New_York',
  },
  '33128': {
    zip: '33128',
    city: 'Miami',
    state: 'FL',
    latitude: '25.773953',
    longitude: '-80.207889',
    timeZoneId: 'America/New_York',
  },
  '33129': {
    zip: '33129',
    city: 'Miami',
    state: 'FL',
    latitude: '25.755934',
    longitude: '-80.204659',
    timeZoneId: 'America/New_York',
  },
  '33130': {
    zip: '33130',
    city: 'Miami',
    state: 'FL',
    latitude: '25.770398',
    longitude: '-80.203205',
    timeZoneId: 'America/New_York',
  },
  '33131': {
    zip: '33131',
    city: 'Miami',
    state: 'FL',
    latitude: '25.766457',
    longitude: '-80.191319',
    timeZoneId: 'America/New_York',
  },
  '33132': {
    zip: '33132',
    city: 'Miami',
    state: 'FL',
    latitude: '25.784288',
    longitude: '-80.19345',
    timeZoneId: 'America/New_York',
  },
  '33133': {
    zip: '33133',
    city: 'Miami',
    state: 'FL',
    latitude: '25.733767',
    longitude: '-80.241942',
    timeZoneId: 'America/New_York',
  },
  '33134': {
    zip: '33134',
    city: 'Miami',
    state: 'FL',
    latitude: '25.753678',
    longitude: '-80.272023',
    timeZoneId: 'America/New_York',
  },
  '33135': {
    zip: '33135',
    city: 'Miami',
    state: 'FL',
    latitude: '25.766858',
    longitude: '-80.238741',
    timeZoneId: 'America/New_York',
  },
  '33136': {
    zip: '33136',
    city: 'Miami',
    state: 'FL',
    latitude: '25.784628',
    longitude: '-80.206454',
    timeZoneId: 'America/New_York',
  },
  '33137': {
    zip: '33137',
    city: 'Miami',
    state: 'FL',
    latitude: '25.814453',
    longitude: '-80.188296',
    timeZoneId: 'America/New_York',
  },
  '33138': {
    zip: '33138',
    city: 'Miami',
    state: 'FL',
    latitude: '25.84935',
    longitude: '-80.181844',
    timeZoneId: 'America/New_York',
  },
  '33139': {
    zip: '33139',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.784853',
    longitude: '-80.144731',
    timeZoneId: 'America/New_York',
  },
  '33140': {
    zip: '33140',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.815316',
    longitude: '-80.13071',
    timeZoneId: 'America/New_York',
  },
  '33141': {
    zip: '33141',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.849841',
    longitude: '-80.166593',
    timeZoneId: 'America/New_York',
  },
  '33142': {
    zip: '33142',
    city: 'Miami',
    state: 'FL',
    latitude: '25.811543',
    longitude: '-80.237681',
    timeZoneId: 'America/New_York',
  },
  '33143': {
    zip: '33143',
    city: 'Miami',
    state: 'FL',
    latitude: '25.702034',
    longitude: '-80.295591',
    timeZoneId: 'America/New_York',
  },
  '33144': {
    zip: '33144',
    city: 'Miami',
    state: 'FL',
    latitude: '25.763251',
    longitude: '-80.313357',
    timeZoneId: 'America/New_York',
  },
  '33145': {
    zip: '33145',
    city: 'Miami',
    state: 'FL',
    latitude: '25.753248',
    longitude: '-80.236857',
    timeZoneId: 'America/New_York',
  },
  '33146': {
    zip: '33146',
    city: 'Miami',
    state: 'FL',
    latitude: '25.719595',
    longitude: '-80.272159',
    timeZoneId: 'America/New_York',
  },
  '33147': {
    zip: '33147',
    city: 'Miami',
    state: 'FL',
    latitude: '25.853411',
    longitude: '-80.237002',
    timeZoneId: 'America/New_York',
  },
  '33149': {
    zip: '33149',
    city: 'Key Biscayne',
    state: 'FL',
    latitude: '25.694059',
    longitude: '-80.166798',
    timeZoneId: 'America/New_York',
  },
  '33150': {
    zip: '33150',
    city: 'Miami',
    state: 'FL',
    latitude: '25.849298',
    longitude: '-80.205791',
    timeZoneId: 'America/New_York',
  },
  '33151': {
    zip: '33151',
    city: 'Miami',
    state: 'FL',
    latitude: '25.83187',
    longitude: '-80.209513',
    timeZoneId: 'America/New_York',
  },
  '33152': {
    zip: '33152',
    city: 'Miami',
    state: 'FL',
    latitude: '25.797522',
    longitude: '-80.313487',
    timeZoneId: 'America/New_York',
  },
  '33153': {
    zip: '33153',
    city: 'Miami',
    state: 'FL',
    latitude: '25.863568',
    longitude: '-80.192551',
    timeZoneId: 'America/New_York',
  },
  '33154': {
    zip: '33154',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.885829',
    longitude: '-80.127959',
    timeZoneId: 'America/New_York',
  },
  '33155': {
    zip: '33155',
    city: 'Miami',
    state: 'FL',
    latitude: '25.737315',
    longitude: '-80.309137',
    timeZoneId: 'America/New_York',
  },
  '33156': {
    zip: '33156',
    city: 'Miami',
    state: 'FL',
    latitude: '25.665235',
    longitude: '-80.301146',
    timeZoneId: 'America/New_York',
  },
  '33157': {
    zip: '33157',
    city: 'Miami',
    state: 'FL',
    latitude: '25.606686',
    longitude: '-80.344992',
    timeZoneId: 'America/New_York',
  },
  '33158': {
    zip: '33158',
    city: 'Miami',
    state: 'FL',
    latitude: '25.63775',
    longitude: '-80.31709',
    timeZoneId: 'America/New_York',
  },
  '33160': {
    zip: '33160',
    city: 'North Miami Beach',
    state: 'FL',
    latitude: '25.940364',
    longitude: '-80.139706',
    timeZoneId: 'America/New_York',
  },
  '33161': {
    zip: '33161',
    city: 'Miami',
    state: 'FL',
    latitude: '25.890934',
    longitude: '-80.178037',
    timeZoneId: 'America/New_York',
  },
  '33162': {
    zip: '33162',
    city: 'Miami',
    state: 'FL',
    latitude: '25.92477',
    longitude: '-80.1782',
    timeZoneId: 'America/New_York',
  },
  '33163': {
    zip: '33163',
    city: 'Miami',
    state: 'FL',
    latitude: '25.964602',
    longitude: '-80.142184',
    timeZoneId: 'America/New_York',
  },
  '33164': {
    zip: '33164',
    city: 'Miami',
    state: 'FL',
    latitude: '25.929334',
    longitude: '-80.176758',
    timeZoneId: 'America/New_York',
  },
  '33165': {
    zip: '33165',
    city: 'Miami',
    state: 'FL',
    latitude: '25.729513',
    longitude: '-80.360218',
    timeZoneId: 'America/New_York',
  },
  '33166': {
    zip: '33166',
    city: 'Miami',
    state: 'FL',
    latitude: '25.821954',
    longitude: '-80.300074',
    timeZoneId: 'America/New_York',
  },
  '33167': {
    zip: '33167',
    city: 'Miami',
    state: 'FL',
    latitude: '25.885455',
    longitude: '-80.228537',
    timeZoneId: 'America/New_York',
  },
  '33168': {
    zip: '33168',
    city: 'Miami',
    state: 'FL',
    latitude: '25.875268',
    longitude: '-80.201839',
    timeZoneId: 'America/New_York',
  },
  '33169': {
    zip: '33169',
    city: 'Miami',
    state: 'FL',
    latitude: '25.93549',
    longitude: '-80.21401',
    timeZoneId: 'America/New_York',
  },
  '33170': {
    zip: '33170',
    city: 'Miami',
    state: 'FL',
    latitude: '25.556966',
    longitude: '-80.464922',
    timeZoneId: 'America/New_York',
  },
  '33172': {
    zip: '33172',
    city: 'Miami',
    state: 'FL',
    latitude: '25.796061',
    longitude: '-80.360119',
    timeZoneId: 'America/New_York',
  },
  '33173': {
    zip: '33173',
    city: 'Miami',
    state: 'FL',
    latitude: '25.701363',
    longitude: '-80.360874',
    timeZoneId: 'America/New_York',
  },
  '33174': {
    zip: '33174',
    city: 'Miami',
    state: 'FL',
    latitude: '25.762543',
    longitude: '-80.353593',
    timeZoneId: 'America/New_York',
  },
  '33175': {
    zip: '33175',
    city: 'Miami',
    state: 'FL',
    latitude: '25.729688',
    longitude: '-80.410127',
    timeZoneId: 'America/New_York',
  },
  '33176': {
    zip: '33176',
    city: 'Miami',
    state: 'FL',
    latitude: '25.656256',
    longitude: '-80.360854',
    timeZoneId: 'America/New_York',
  },
  '33177': {
    zip: '33177',
    city: 'Miami',
    state: 'FL',
    latitude: '25.592736',
    longitude: '-80.405844',
    timeZoneId: 'America/New_York',
  },
  '33178': {
    zip: '33178',
    city: 'Miami',
    state: 'FL',
    latitude: '25.85677',
    longitude: '-80.41599',
    timeZoneId: 'America/New_York',
  },
  '33179': {
    zip: '33179',
    city: 'Miami',
    state: 'FL',
    latitude: '25.95453',
    longitude: '-80.183568',
    timeZoneId: 'America/New_York',
  },
  '33180': {
    zip: '33180',
    city: 'Miami',
    state: 'FL',
    latitude: '25.961901',
    longitude: '-80.140608',
    timeZoneId: 'America/New_York',
  },
  '33181': {
    zip: '33181',
    city: 'Miami',
    state: 'FL',
    latitude: '25.902791',
    longitude: '-80.15129',
    timeZoneId: 'America/New_York',
  },
  '33182': {
    zip: '33182',
    city: 'Miami',
    state: 'FL',
    latitude: '25.77825',
    longitude: '-80.414948',
    timeZoneId: 'America/New_York',
  },
  '33183': {
    zip: '33183',
    city: 'Miami',
    state: 'FL',
    latitude: '25.700327',
    longitude: '-80.408398',
    timeZoneId: 'America/New_York',
  },
  '33184': {
    zip: '33184',
    city: 'Miami',
    state: 'FL',
    latitude: '25.756934',
    longitude: '-80.404841',
    timeZoneId: 'America/New_York',
  },
  '33185': {
    zip: '33185',
    city: 'Miami',
    state: 'FL',
    latitude: '25.716424',
    longitude: '-80.437255',
    timeZoneId: 'America/New_York',
  },
  '33186': {
    zip: '33186',
    city: 'Miami',
    state: 'FL',
    latitude: '25.661132',
    longitude: '-80.407574',
    timeZoneId: 'America/New_York',
  },
  '33187': {
    zip: '33187',
    city: 'Miami',
    state: 'FL',
    latitude: '25.5856',
    longitude: '-80.50466',
    timeZoneId: 'America/New_York',
  },
  '33188': {
    zip: '33188',
    city: 'Miami',
    state: 'FL',
    latitude: '25.814728',
    longitude: '-80.240372',
    timeZoneId: 'America/New_York',
  },
  '33189': {
    zip: '33189',
    city: 'Miami',
    state: 'FL',
    latitude: '25.568606',
    longitude: '-80.34289',
    timeZoneId: 'America/New_York',
  },
  '33190': {
    zip: '33190',
    city: 'Miami',
    state: 'FL',
    latitude: '25.556771',
    longitude: '-80.336864',
    timeZoneId: 'America/New_York',
  },
  '33191': {
    zip: '33191',
    city: 'Miami',
    state: 'FL',
    latitude: '25.5607',
    longitude: '-80.3494',
    timeZoneId: 'America/New_York',
  },
  '33192': {
    zip: '33192',
    city: 'Miami',
    state: 'FL',
    latitude: '26.70835',
    longitude: '-80.108888',
    timeZoneId: 'America/New_York',
  },
  '33193': {
    zip: '33193',
    city: 'Miami',
    state: 'FL',
    latitude: '25.693575',
    longitude: '-80.445767',
    timeZoneId: 'America/New_York',
  },
  '33194': {
    zip: '33194',
    city: 'Miami',
    state: 'FL',
    latitude: '25.758515',
    longitude: '-80.391867',
    timeZoneId: 'America/New_York',
  },
  '33195': {
    zip: '33195',
    city: 'Miami',
    state: 'FL',
    latitude: '25.7664',
    longitude: '-80.1913',
    timeZoneId: 'America/New_York',
  },
  '33196': {
    zip: '33196',
    city: 'Miami',
    state: 'FL',
    latitude: '25.651234',
    longitude: '-80.48136',
    timeZoneId: 'America/New_York',
  },
  '33197': {
    zip: '33197',
    city: 'Miami',
    state: 'FL',
    latitude: '25.595047',
    longitude: '-80.363125',
    timeZoneId: 'America/New_York',
  },
  '33198': {
    zip: '33198',
    city: 'Miami',
    state: 'FL',
    latitude: '26.70835',
    longitude: '-80.108888',
    timeZoneId: 'America/New_York',
  },
  '33199': {
    zip: '33199',
    city: 'Miami',
    state: 'FL',
    latitude: '25.759145',
    longitude: '-80.372492',
    timeZoneId: 'America/New_York',
  },
  '33206': {
    zip: '33206',
    city: 'Miami',
    state: 'FL',
    latitude: '25.79518',
    longitude: '-80.32538',
    timeZoneId: 'America/New_York',
  },
  '33222': {
    zip: '33222',
    city: 'Miami',
    state: 'FL',
    latitude: '25.732873',
    longitude: '-80.241591',
    timeZoneId: 'America/New_York',
  },
  '33231': {
    zip: '33231',
    city: 'Miami',
    state: 'FL',
    latitude: '25.77393',
    longitude: '-80.194434',
    timeZoneId: 'America/New_York',
  },
  '33233': {
    zip: '33233',
    city: 'Miami',
    state: 'FL',
    latitude: '25.732156',
    longitude: '-80.241534',
    timeZoneId: 'America/New_York',
  },
  '33234': {
    zip: '33234',
    city: 'Miami',
    state: 'FL',
    latitude: '25.750799',
    longitude: '-80.274412',
    timeZoneId: 'America/New_York',
  },
  '33238': {
    zip: '33238',
    city: 'Miami',
    state: 'FL',
    latitude: '25.854728',
    longitude: '-80.182494',
    timeZoneId: 'America/New_York',
  },
  '33239': {
    zip: '33239',
    city: 'Miami Beach',
    state: 'FL',
    latitude: '25.790887',
    longitude: '-80.141386',
    timeZoneId: 'America/New_York',
  },
  '33242': {
    zip: '33242',
    city: 'Miami',
    state: 'FL',
    latitude: '25.811761',
    longitude: '-80.234329',
    timeZoneId: 'America/New_York',
  },
  '33243': {
    zip: '33243',
    city: 'Miami',
    state: 'FL',
    latitude: '25.701397',
    longitude: '-80.29902',
    timeZoneId: 'America/New_York',
  },
  '33245': {
    zip: '33245',
    city: 'Miami',
    state: 'FL',
    latitude: '25.747122',
    longitude: '-80.288174',
    timeZoneId: 'America/New_York',
  },
  '33247': {
    zip: '33247',
    city: 'Miami',
    state: 'FL',
    latitude: '25.852307',
    longitude: '-80.239515',
    timeZoneId: 'America/New_York',
  },
  '33255': {
    zip: '33255',
    city: 'Miami',
    state: 'FL',
    latitude: '25.77693',
    longitude: '-80.190934',
    timeZoneId: 'America/New_York',
  },
  '33256': {
    zip: '33256',
    city: 'Miami',
    state: 'FL',
    latitude: '25.661865',
    longitude: '-80.326386',
    timeZoneId: 'America/New_York',
  },
  '33257': {
    zip: '33257',
    city: 'Miami',
    state: 'FL',
    latitude: '25.613248',
    longitude: '-80.347422',
    timeZoneId: 'America/New_York',
  },
  '33261': {
    zip: '33261',
    city: 'Miami',
    state: 'FL',
    latitude: '25.891214',
    longitude: '-80.181179',
    timeZoneId: 'America/New_York',
  },
  '33265': {
    zip: '33265',
    city: 'Miami',
    state: 'FL',
    latitude: '25.737256',
    longitude: '-80.411116',
    timeZoneId: 'America/New_York',
  },
  '33266': {
    zip: '33266',
    city: 'Miami',
    state: 'FL',
    latitude: '25.827127',
    longitude: '-80.313971',
    timeZoneId: 'America/New_York',
  },
  '33269': {
    zip: '33269',
    city: 'Miami',
    state: 'FL',
    latitude: '25.94476',
    longitude: '-80.213994',
    timeZoneId: 'America/New_York',
  },
  '33280': {
    zip: '33280',
    city: 'Miami',
    state: 'FL',
    latitude: '25.932022',
    longitude: '-80.136421',
    timeZoneId: 'America/New_York',
  },
  '33283': {
    zip: '33283',
    city: 'Miami',
    state: 'FL',
    latitude: '25.69928',
    longitude: '-80.381577',
    timeZoneId: 'America/New_York',
  },
  '33296': {
    zip: '33296',
    city: 'Miami',
    state: 'FL',
    latitude: '25.656259',
    longitude: '-80.357036',
    timeZoneId: 'America/New_York',
  },
  '33299': {
    zip: '33299',
    city: 'Miami',
    state: 'FL',
    latitude: '25.775355',
    longitude: '-80.208792',
    timeZoneId: 'America/New_York',
  },
  '33301': {
    zip: '33301',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.12025',
    longitude: '-80.129547',
    timeZoneId: 'America/New_York',
  },
  '33302': {
    zip: '33302',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.036168',
    longitude: '-80.196791',
    timeZoneId: 'America/New_York',
  },
  '33303': {
    zip: '33303',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.198883',
    longitude: '-80.095109',
    timeZoneId: 'America/New_York',
  },
  '33304': {
    zip: '33304',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.14078',
    longitude: '-80.124434',
    timeZoneId: 'America/New_York',
  },
  '33305': {
    zip: '33305',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.15615',
    longitude: '-80.122239',
    timeZoneId: 'America/New_York',
  },
  '33306': {
    zip: '33306',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.163196',
    longitude: '-80.110875',
    timeZoneId: 'America/New_York',
  },
  '33307': {
    zip: '33307',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.181193',
    longitude: '-80.277948',
    timeZoneId: 'America/New_York',
  },
  '33308': {
    zip: '33308',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.189201',
    longitude: '-80.109298',
    timeZoneId: 'America/New_York',
  },
  '33309': {
    zip: '33309',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.182764',
    longitude: '-80.175692',
    timeZoneId: 'America/New_York',
  },
  '33310': {
    zip: '33310',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.171944',
    longitude: '-80.132222',
    timeZoneId: 'America/New_York',
  },
  '33311': {
    zip: '33311',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.140547',
    longitude: '-80.173789',
    timeZoneId: 'America/New_York',
  },
  '33312': {
    zip: '33312',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.088164',
    longitude: '-80.184514',
    timeZoneId: 'America/New_York',
  },
  '33313': {
    zip: '33313',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.148778',
    longitude: '-80.227624',
    timeZoneId: 'America/New_York',
  },
  '33314': {
    zip: '33314',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.070011',
    longitude: '-80.219723',
    timeZoneId: 'America/New_York',
  },
  '33315': {
    zip: '33315',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.097086',
    longitude: '-80.15436',
    timeZoneId: 'America/New_York',
  },
  '33316': {
    zip: '33316',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.103966',
    longitude: '-80.124513',
    timeZoneId: 'America/New_York',
  },
  '33317': {
    zip: '33317',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.093836',
    longitude: '-80.239494',
    timeZoneId: 'America/New_York',
  },
  '33318': {
    zip: '33318',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.118323',
    longitude: '-80.251273',
    timeZoneId: 'America/New_York',
  },
  '33319': {
    zip: '33319',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.181839',
    longitude: '-80.227592',
    timeZoneId: 'America/New_York',
  },
  '33320': {
    zip: '33320',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.14395',
    longitude: '-80.141327',
    timeZoneId: 'America/New_York',
  },
  '33321': {
    zip: '33321',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.205804',
    longitude: '-80.266294',
    timeZoneId: 'America/New_York',
  },
  '33322': {
    zip: '33322',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.150168',
    longitude: '-80.273686',
    timeZoneId: 'America/New_York',
  },
  '33323': {
    zip: '33323',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.162098',
    longitude: '-80.306679',
    timeZoneId: 'America/New_York',
  },
  '33324': {
    zip: '33324',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.110295',
    longitude: '-80.270027',
    timeZoneId: 'America/New_York',
  },
  '33325': {
    zip: '33325',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.110796',
    longitude: '-80.317912',
    timeZoneId: 'America/New_York',
  },
  '33326': {
    zip: '33326',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.111456',
    longitude: '-80.372148',
    timeZoneId: 'America/New_York',
  },
  '33327': {
    zip: '33327',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.118498',
    longitude: '-80.436434',
    timeZoneId: 'America/New_York',
  },
  '33328': {
    zip: '33328',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.06658',
    longitude: '-80.2728',
    timeZoneId: 'America/New_York',
  },
  '33329': {
    zip: '33329',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.11117',
    longitude: '-80.274605',
    timeZoneId: 'America/New_York',
  },
  '33330': {
    zip: '33330',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.059626',
    longitude: '-80.322613',
    timeZoneId: 'America/New_York',
  },
  '33331': {
    zip: '33331',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.057117',
    longitude: '-80.368319',
    timeZoneId: 'America/New_York',
  },
  '33332': {
    zip: '33332',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.063246',
    longitude: '-80.420398',
    timeZoneId: 'America/New_York',
  },
  '33334': {
    zip: '33334',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.181067',
    longitude: '-80.138938',
    timeZoneId: 'America/New_York',
  },
  '33335': {
    zip: '33335',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.181775',
    longitude: '-80.138566',
    timeZoneId: 'America/New_York',
  },
  '33336': {
    zip: '33336',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.14255',
    longitude: '-80.139499',
    timeZoneId: 'America/New_York',
  },
  '33337': {
    zip: '33337',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.110891',
    longitude: '-80.274099',
    timeZoneId: 'America/New_York',
  },
  '33338': {
    zip: '33338',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.140429',
    longitude: '-80.12529',
    timeZoneId: 'America/New_York',
  },
  '33339': {
    zip: '33339',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.163838',
    longitude: '-80.11387',
    timeZoneId: 'America/New_York',
  },
  '33340': {
    zip: '33340',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.143611',
    longitude: '-80.173524',
    timeZoneId: 'America/New_York',
  },
  '33345': {
    zip: '33345',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.178308',
    longitude: '-80.278398',
    timeZoneId: 'America/New_York',
  },
  '33346': {
    zip: '33346',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.123836',
    longitude: '-80.142332',
    timeZoneId: 'America/New_York',
  },
  '33348': {
    zip: '33348',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.187318',
    longitude: '-80.108851',
    timeZoneId: 'America/New_York',
  },
  '33349': {
    zip: '33349',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.181282',
    longitude: '-80.22618',
    timeZoneId: 'America/New_York',
  },
  '33351': {
    zip: '33351',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.177671',
    longitude: '-80.275118',
    timeZoneId: 'America/New_York',
  },
  '33355': {
    zip: '33355',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.11279',
    longitude: '-80.303694',
    timeZoneId: 'America/New_York',
  },
  '33359': {
    zip: '33359',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.17937',
    longitude: '-80.226998',
    timeZoneId: 'America/New_York',
  },
  '33388': {
    zip: '33388',
    city: 'Plantation',
    state: 'FL',
    latitude: '26.117073',
    longitude: '-80.254184',
    timeZoneId: 'America/New_York',
  },
  '33394': {
    zip: '33394',
    city: 'Fort Lauderdale',
    state: 'FL',
    latitude: '26.121148',
    longitude: '-80.139899',
    timeZoneId: 'America/New_York',
  },
  '33401': {
    zip: '33401',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.705811',
    longitude: '-80.064783',
    timeZoneId: 'America/New_York',
  },
  '33402': {
    zip: '33402',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.717231',
    longitude: '-80.051556',
    timeZoneId: 'America/New_York',
  },
  '33403': {
    zip: '33403',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.78494',
    longitude: '-80.071224',
    timeZoneId: 'America/New_York',
  },
  '33404': {
    zip: '33404',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.779152',
    longitude: '-80.073595',
    timeZoneId: 'America/New_York',
  },
  '33405': {
    zip: '33405',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.671543',
    longitude: '-80.059678',
    timeZoneId: 'America/New_York',
  },
  '33406': {
    zip: '33406',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.656737',
    longitude: '-80.090777',
    timeZoneId: 'America/New_York',
  },
  '33407': {
    zip: '33407',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.760988',
    longitude: '-80.085606',
    timeZoneId: 'America/New_York',
  },
  '33408': {
    zip: '33408',
    city: 'North Palm Beach',
    state: 'FL',
    latitude: '26.837511',
    longitude: '-80.060444',
    timeZoneId: 'America/New_York',
  },
  '33409': {
    zip: '33409',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.708576',
    longitude: '-80.096654',
    timeZoneId: 'America/New_York',
  },
  '33410': {
    zip: '33410',
    city: 'Palm Beach Gardens',
    state: 'FL',
    latitude: '26.843134',
    longitude: '-80.087669',
    timeZoneId: 'America/New_York',
  },
  '33411': {
    zip: '33411',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.715568',
    longitude: '-80.217416',
    timeZoneId: 'America/New_York',
  },
  '33412': {
    zip: '33412',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.788572',
    longitude: '-80.243519',
    timeZoneId: 'America/New_York',
  },
  '33413': {
    zip: '33413',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.678715',
    longitude: '-80.141603',
    timeZoneId: 'America/New_York',
  },
  '33414': {
    zip: '33414',
    city: 'Wellington',
    state: 'FL',
    latitude: '26.661979',
    longitude: '-80.259339',
    timeZoneId: 'America/New_York',
  },
  '33415': {
    zip: '33415',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.663672',
    longitude: '-80.126382',
    timeZoneId: 'America/New_York',
  },
  '33416': {
    zip: '33416',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.66419',
    longitude: '-80.090666',
    timeZoneId: 'America/New_York',
  },
  '33417': {
    zip: '33417',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.714953',
    longitude: '-80.124964',
    timeZoneId: 'America/New_York',
  },
  '33418': {
    zip: '33418',
    city: 'Palm Beach Gardens',
    state: 'FL',
    latitude: '26.855526',
    longitude: '-80.14433',
    timeZoneId: 'America/New_York',
  },
  '33419': {
    zip: '33419',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.781477',
    longitude: '-80.092135',
    timeZoneId: 'America/New_York',
  },
  '33420': {
    zip: '33420',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.855333',
    longitude: '-80.083047',
    timeZoneId: 'America/New_York',
  },
  '33421': {
    zip: '33421',
    city: 'Royal Palm Beach',
    state: 'FL',
    latitude: '26.6802',
    longitude: '-80.204984',
    timeZoneId: 'America/New_York',
  },
  '33422': {
    zip: '33422',
    city: 'West Palm Beach',
    state: 'FL',
    latitude: '26.750328',
    longitude: '-80.128524',
    timeZoneId: 'America/New_York',
  },
  '33424': {
    zip: '33424',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.51918',
    longitude: '-80.108145',
    timeZoneId: 'America/New_York',
  },
  '33425': {
    zip: '33425',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.516509',
    longitude: '-80.127865',
    timeZoneId: 'America/New_York',
  },
  '33426': {
    zip: '33426',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.518184',
    longitude: '-80.081941',
    timeZoneId: 'America/New_York',
  },
  '33427': {
    zip: '33427',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.346052',
    longitude: '-80.082015',
    timeZoneId: 'America/New_York',
  },
  '33428': {
    zip: '33428',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.35563',
    longitude: '-80.195232',
    timeZoneId: 'America/New_York',
  },
  '33429': {
    zip: '33429',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.349389',
    longitude: '-80.082008',
    timeZoneId: 'America/New_York',
  },
  '33430': {
    zip: '33430',
    city: 'Belle Glade',
    state: 'FL',
    latitude: '26.680463',
    longitude: '-80.666853',
    timeZoneId: 'America/New_York',
  },
  '33431': {
    zip: '33431',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.384367',
    longitude: '-80.099535',
    timeZoneId: 'America/New_York',
  },
  '33432': {
    zip: '33432',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.349491',
    longitude: '-80.092164',
    timeZoneId: 'America/New_York',
  },
  '33433': {
    zip: '33433',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.348386',
    longitude: '-80.15787',
    timeZoneId: 'America/New_York',
  },
  '33434': {
    zip: '33434',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.383356',
    longitude: '-80.16321',
    timeZoneId: 'America/New_York',
  },
  '33435': {
    zip: '33435',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.524047',
    longitude: '-80.068212',
    timeZoneId: 'America/New_York',
  },
  '33436': {
    zip: '33436',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.524205',
    longitude: '-80.107257',
    timeZoneId: 'America/New_York',
  },
  '33437': {
    zip: '33437',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.524817',
    longitude: '-80.17679',
    timeZoneId: 'America/New_York',
  },
  '33438': {
    zip: '33438',
    city: 'Canal Point',
    state: 'FL',
    latitude: '26.860317',
    longitude: '-80.632842',
    timeZoneId: 'America/New_York',
  },
  '33440': {
    zip: '33440',
    city: 'Clewiston',
    state: 'FL',
    latitude: '26.737871',
    longitude: '-80.941459',
    timeZoneId: 'America/New_York',
  },
  '33441': {
    zip: '33441',
    city: 'Deerfield Beach',
    state: 'FL',
    latitude: '26.312859',
    longitude: '-80.104367',
    timeZoneId: 'America/New_York',
  },
  '33442': {
    zip: '33442',
    city: 'Deerfield Beach',
    state: 'FL',
    latitude: '26.308884',
    longitude: '-80.140867',
    timeZoneId: 'America/New_York',
  },
  '33443': {
    zip: '33443',
    city: 'Deerfield Beach',
    state: 'FL',
    latitude: '26.324699',
    longitude: '-80.120509',
    timeZoneId: 'America/New_York',
  },
  '33444': {
    zip: '33444',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.461443',
    longitude: '-80.077804',
    timeZoneId: 'America/New_York',
  },
  '33445': {
    zip: '33445',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.461329',
    longitude: '-80.102797',
    timeZoneId: 'America/New_York',
  },
  '33446': {
    zip: '33446',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.451567',
    longitude: '-80.173388',
    timeZoneId: 'America/New_York',
  },
  '33448': {
    zip: '33448',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.461111',
    longitude: '-80.073056',
    timeZoneId: 'America/New_York',
  },
  '33449': {
    zip: '33449',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.6072',
    longitude: '-80.233543',
    timeZoneId: 'America/New_York',
  },
  '33454': {
    zip: '33454',
    city: 'Greenacres',
    state: 'FL',
    latitude: '26.594768',
    longitude: '-80.200705',
    timeZoneId: 'America/New_York',
  },
  '33455': {
    zip: '33455',
    city: 'Hobe Sound',
    state: 'FL',
    latitude: '27.075482',
    longitude: '-80.143309',
    timeZoneId: 'America/New_York',
  },
  '33458': {
    zip: '33458',
    city: 'Jupiter',
    state: 'FL',
    latitude: '26.935471',
    longitude: '-80.116253',
    timeZoneId: 'America/New_York',
  },
  '33459': {
    zip: '33459',
    city: 'Lake Harbor',
    state: 'FL',
    latitude: '26.693456',
    longitude: '-80.817722',
    timeZoneId: 'America/New_York',
  },
  '33460': {
    zip: '33460',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.613877',
    longitude: '-80.054114',
    timeZoneId: 'America/New_York',
  },
  '33461': {
    zip: '33461',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.612961',
    longitude: '-80.08925',
    timeZoneId: 'America/New_York',
  },
  '33462': {
    zip: '33462',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.58113',
    longitude: '-80.07902',
    timeZoneId: 'America/New_York',
  },
  '33463': {
    zip: '33463',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.59398',
    longitude: '-80.12772',
    timeZoneId: 'America/New_York',
  },
  '33464': {
    zip: '33464',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.58413',
    longitude: '-80.049062',
    timeZoneId: 'America/New_York',
  },
  '33465': {
    zip: '33465',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.57632',
    longitude: '-80.087218',
    timeZoneId: 'America/New_York',
  },
  '33466': {
    zip: '33466',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.61742',
    longitude: '-80.060095',
    timeZoneId: 'America/New_York',
  },
  '33467': {
    zip: '33467',
    city: 'Lake Worth',
    state: 'FL',
    latitude: '26.606929',
    longitude: '-80.1777',
    timeZoneId: 'America/New_York',
  },
  '33468': {
    zip: '33468',
    city: 'Jupiter',
    state: 'FL',
    latitude: '26.937644',
    longitude: '-80.111302',
    timeZoneId: 'America/New_York',
  },
  '33469': {
    zip: '33469',
    city: 'Jupiter',
    state: 'FL',
    latitude: '26.960459',
    longitude: '-80.098465',
    timeZoneId: 'America/New_York',
  },
  '33470': {
    zip: '33470',
    city: 'Loxahatchee',
    state: 'FL',
    latitude: '26.722172',
    longitude: '-80.306749',
    timeZoneId: 'America/New_York',
  },
  '33471': {
    zip: '33471',
    city: 'Moore Haven',
    state: 'FL',
    latitude: '26.83404',
    longitude: '-81.10405',
    timeZoneId: 'America/New_York',
  },
  '33472': {
    zip: '33472',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.553316',
    longitude: '-80.221672',
    timeZoneId: 'America/New_York',
  },
  '33473': {
    zip: '33473',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.517012',
    longitude: '-80.179715',
    timeZoneId: 'America/New_York',
  },
  '33474': {
    zip: '33474',
    city: 'Boynton Beach',
    state: 'FL',
    latitude: '26.526563',
    longitude: '-80.082117',
    timeZoneId: 'America/New_York',
  },
  '33475': {
    zip: '33475',
    city: 'Hobe Sound',
    state: 'FL',
    latitude: '27.040732',
    longitude: '-80.120463',
    timeZoneId: 'America/New_York',
  },
  '33476': {
    zip: '33476',
    city: 'Pahokee',
    state: 'FL',
    latitude: '26.819344',
    longitude: '-80.662225',
    timeZoneId: 'America/New_York',
  },
  '33477': {
    zip: '33477',
    city: 'Jupiter',
    state: 'FL',
    latitude: '26.921272',
    longitude: '-80.075932',
    timeZoneId: 'America/New_York',
  },
  '33478': {
    zip: '33478',
    city: 'Jupiter',
    state: 'FL',
    latitude: '26.921814',
    longitude: '-80.215886',
    timeZoneId: 'America/New_York',
  },
  '33480': {
    zip: '33480',
    city: 'Palm Beach',
    state: 'FL',
    latitude: '26.717031',
    longitude: '-80.047448',
    timeZoneId: 'America/New_York',
  },
  '33481': {
    zip: '33481',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.379928',
    longitude: '-80.101289',
    timeZoneId: 'America/New_York',
  },
  '33482': {
    zip: '33482',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.453951',
    longitude: '-80.087116',
    timeZoneId: 'America/New_York',
  },
  '33483': {
    zip: '33483',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.463927',
    longitude: '-80.062263',
    timeZoneId: 'America/New_York',
  },
  '33484': {
    zip: '33484',
    city: 'Delray Beach',
    state: 'FL',
    latitude: '26.457619',
    longitude: '-80.13516',
    timeZoneId: 'America/New_York',
  },
  '33486': {
    zip: '33486',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.346508',
    longitude: '-80.113393',
    timeZoneId: 'America/New_York',
  },
  '33487': {
    zip: '33487',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.3997',
    longitude: '-80.10168',
    timeZoneId: 'America/New_York',
  },
  '33488': {
    zip: '33488',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.355907',
    longitude: '-80.171604',
    timeZoneId: 'America/New_York',
  },
  '33493': {
    zip: '33493',
    city: 'South Bay',
    state: 'FL',
    latitude: '26.672694',
    longitude: '-80.716833',
    timeZoneId: 'America/New_York',
  },
  '33496': {
    zip: '33496',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.40507',
    longitude: '-80.168274',
    timeZoneId: 'America/New_York',
  },
  '33497': {
    zip: '33497',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.349695',
    longitude: '-80.23142',
    timeZoneId: 'America/New_York',
  },
  '33498': {
    zip: '33498',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.393289',
    longitude: '-80.215643',
    timeZoneId: 'America/New_York',
  },
  '33499': {
    zip: '33499',
    city: 'Boca Raton',
    state: 'FL',
    latitude: '26.409609',
    longitude: '-80.106315',
    timeZoneId: 'America/New_York',
  },
  '33503': {
    zip: '33503',
    city: 'Balm',
    state: 'FL',
    latitude: '27.779577',
    longitude: '-82.312495',
    timeZoneId: 'America/New_York',
  },
  '33508': {
    zip: '33508',
    city: 'Brandon',
    state: 'FL',
    latitude: '27.927446',
    longitude: '-82.293411',
    timeZoneId: 'America/New_York',
  },
  '33509': {
    zip: '33509',
    city: 'Brandon',
    state: 'FL',
    latitude: '27.894857',
    longitude: '-82.33095',
    timeZoneId: 'America/New_York',
  },
  '33510': {
    zip: '33510',
    city: 'Brandon',
    state: 'FL',
    latitude: '27.95241',
    longitude: '-82.295627',
    timeZoneId: 'America/New_York',
  },
  '33511': {
    zip: '33511',
    city: 'Brandon',
    state: 'FL',
    latitude: '27.912173',
    longitude: '-82.296703',
    timeZoneId: 'America/New_York',
  },
  '33513': {
    zip: '33513',
    city: 'Bushnell',
    state: 'FL',
    latitude: '28.661872',
    longitude: '-82.114026',
    timeZoneId: 'America/New_York',
  },
  '33514': {
    zip: '33514',
    city: 'Center Hill',
    state: 'FL',
    latitude: '28.69961',
    longitude: '-82.004424',
    timeZoneId: 'America/New_York',
  },
  '33521': {
    zip: '33521',
    city: 'Coleman',
    state: 'FL',
    latitude: '28.800169',
    longitude: '-82.070244',
    timeZoneId: 'America/New_York',
  },
  '33523': {
    zip: '33523',
    city: 'Dade City',
    state: 'FL',
    latitude: '28.4001',
    longitude: '-82.22862',
    timeZoneId: 'America/New_York',
  },
  '33524': {
    zip: '33524',
    city: 'Crystal Springs',
    state: 'FL',
    latitude: '28.18259',
    longitude: '-82.15872',
    timeZoneId: 'America/New_York',
  },
  '33525': {
    zip: '33525',
    city: 'Dade City',
    state: 'FL',
    latitude: '28.36443',
    longitude: '-82.209827',
    timeZoneId: 'America/New_York',
  },
  '33526': {
    zip: '33526',
    city: 'Dade City',
    state: 'FL',
    latitude: '28.40695',
    longitude: '-82.190328',
    timeZoneId: 'America/New_York',
  },
  '33527': {
    zip: '33527',
    city: 'Dover',
    state: 'FL',
    latitude: '27.982324',
    longitude: '-82.223137',
    timeZoneId: 'America/New_York',
  },
  '33530': {
    zip: '33530',
    city: 'Durant',
    state: 'FL',
    latitude: '27.896971',
    longitude: '-82.163527',
    timeZoneId: 'America/New_York',
  },
  '33534': {
    zip: '33534',
    city: 'Gibsonton',
    state: 'FL',
    latitude: '27.829591',
    longitude: '-82.368604',
    timeZoneId: 'America/New_York',
  },
  '33537': {
    zip: '33537',
    city: 'Lacoochee',
    state: 'FL',
    latitude: '28.466643',
    longitude: '-82.17676',
    timeZoneId: 'America/New_York',
  },
  '33538': {
    zip: '33538',
    city: 'Lake Panasoffkee',
    state: 'FL',
    latitude: '28.885081',
    longitude: '-82.19282',
    timeZoneId: 'America/New_York',
  },
  '33539': {
    zip: '33539',
    city: 'Zephyrhills',
    state: 'FL',
    latitude: '28.249851',
    longitude: '-82.300231',
    timeZoneId: 'America/New_York',
  },
  '33540': {
    zip: '33540',
    city: 'Zephyrhills',
    state: 'FL',
    latitude: '28.230447',
    longitude: '-82.169298',
    timeZoneId: 'America/New_York',
  },
  '33541': {
    zip: '33541',
    city: 'Zephyrhills',
    state: 'FL',
    latitude: '28.237746',
    longitude: '-82.210716',
    timeZoneId: 'America/New_York',
  },
  '33542': {
    zip: '33542',
    city: 'Zephyrhills',
    state: 'FL',
    latitude: '28.231487',
    longitude: '-82.166188',
    timeZoneId: 'America/New_York',
  },
  '33543': {
    zip: '33543',
    city: 'Wesley Chapel',
    state: 'FL',
    latitude: '28.2051',
    longitude: '-82.30002',
    timeZoneId: 'America/New_York',
  },
  '33544': {
    zip: '33544',
    city: 'Wesley Chapel',
    state: 'FL',
    latitude: '28.269457',
    longitude: '-82.319078',
    timeZoneId: 'America/New_York',
  },
  '33545': {
    zip: '33545',
    city: 'Wesley Chapel',
    state: 'FL',
    latitude: '28.22962',
    longitude: '-82.282896',
    timeZoneId: 'America/New_York',
  },
  '33547': {
    zip: '33547',
    city: 'Lithia',
    state: 'FL',
    latitude: '27.810925',
    longitude: '-82.1489',
    timeZoneId: 'America/New_York',
  },
  '33548': {
    zip: '33548',
    city: 'Lutz',
    state: 'FL',
    latitude: '28.143554',
    longitude: '-82.48035',
    timeZoneId: 'America/New_York',
  },
  '33549': {
    zip: '33549',
    city: 'Lutz',
    state: 'FL',
    latitude: '28.123545',
    longitude: '-82.449632',
    timeZoneId: 'America/New_York',
  },
  '33550': {
    zip: '33550',
    city: 'Mango',
    state: 'FL',
    latitude: '27.977903',
    longitude: '-82.303118',
    timeZoneId: 'America/New_York',
  },
  '33556': {
    zip: '33556',
    city: 'Odessa',
    state: 'FL',
    latitude: '28.13854',
    longitude: '-82.607174',
    timeZoneId: 'America/New_York',
  },
  '33558': {
    zip: '33558',
    city: 'Lutz',
    state: 'FL',
    latitude: '28.157052',
    longitude: '-82.520763',
    timeZoneId: 'America/New_York',
  },
  '33559': {
    zip: '33559',
    city: 'Lutz',
    state: 'FL',
    latitude: '28.165438',
    longitude: '-82.407004',
    timeZoneId: 'America/New_York',
  },
  '33563': {
    zip: '33563',
    city: 'Plant City',
    state: 'FL',
    latitude: '28.014481',
    longitude: '-82.136482',
    timeZoneId: 'America/New_York',
  },
  '33564': {
    zip: '33564',
    city: 'Plant City',
    state: 'FL',
    latitude: '28.019232',
    longitude: '-82.097947',
    timeZoneId: 'America/New_York',
  },
  '33565': {
    zip: '33565',
    city: 'Plant City',
    state: 'FL',
    latitude: '28.086888',
    longitude: '-82.1548',
    timeZoneId: 'America/New_York',
  },
  '33566': {
    zip: '33566',
    city: 'Plant City',
    state: 'FL',
    latitude: '28.003601',
    longitude: '-82.097562',
    timeZoneId: 'America/New_York',
  },
  '33567': {
    zip: '33567',
    city: 'Plant City',
    state: 'FL',
    latitude: '27.93582',
    longitude: '-82.126812',
    timeZoneId: 'America/New_York',
  },
  '33568': {
    zip: '33568',
    city: 'Riverview',
    state: 'FL',
    latitude: '27.868719',
    longitude: '-82.319589',
    timeZoneId: 'America/New_York',
  },
  '33569': {
    zip: '33569',
    city: 'Riverview',
    state: 'FL',
    latitude: '27.850104',
    longitude: '-82.313905',
    timeZoneId: 'America/New_York',
  },
  '33570': {
    zip: '33570',
    city: 'Ruskin',
    state: 'FL',
    latitude: '27.70938',
    longitude: '-82.44714',
    timeZoneId: 'America/New_York',
  },
  '33571': {
    zip: '33571',
    city: 'Sun City Center',
    state: 'FL',
    latitude: '27.720556',
    longitude: '-82.433333',
    timeZoneId: 'America/New_York',
  },
  '33572': {
    zip: '33572',
    city: 'Apollo Beach',
    state: 'FL',
    latitude: '27.771363',
    longitude: '-82.391466',
    timeZoneId: 'America/New_York',
  },
  '33573': {
    zip: '33573',
    city: 'Sun City Center',
    state: 'FL',
    latitude: '27.718176',
    longitude: '-82.364372',
    timeZoneId: 'America/New_York',
  },
  '33574': {
    zip: '33574',
    city: 'Saint Leo',
    state: 'FL',
    latitude: '28.331832',
    longitude: '-82.274046',
    timeZoneId: 'America/New_York',
  },
  '33575': {
    zip: '33575',
    city: 'Ruskin',
    state: 'FL',
    latitude: '27.714551',
    longitude: '-82.419142',
    timeZoneId: 'America/New_York',
  },
  '33576': {
    zip: '33576',
    city: 'San Antonio',
    state: 'FL',
    latitude: '28.324246',
    longitude: '-82.319355',
    timeZoneId: 'America/New_York',
  },
  '33578': {
    zip: '33578',
    city: 'Riverview',
    state: 'FL',
    latitude: '27.880697',
    longitude: '-82.369066',
    timeZoneId: 'America/New_York',
  },
  '33579': {
    zip: '33579',
    city: 'Riverview',
    state: 'FL',
    latitude: '27.813553',
    longitude: '-82.319353',
    timeZoneId: 'America/New_York',
  },
  '33583': {
    zip: '33583',
    city: 'Seffner',
    state: 'FL',
    latitude: '27.99987',
    longitude: '-82.277661',
    timeZoneId: 'America/New_York',
  },
  '33584': {
    zip: '33584',
    city: 'Seffner',
    state: 'FL',
    latitude: '27.990282',
    longitude: '-82.285514',
    timeZoneId: 'America/New_York',
  },
  '33585': {
    zip: '33585',
    city: 'Sumterville',
    state: 'FL',
    latitude: '28.697187',
    longitude: '-82.002798',
    timeZoneId: 'America/New_York',
  },
  '33586': {
    zip: '33586',
    city: 'Sun City',
    state: 'FL',
    latitude: '27.675657',
    longitude: '-82.479102',
    timeZoneId: 'America/New_York',
  },
  '33587': {
    zip: '33587',
    city: 'Sydney',
    state: 'FL',
    latitude: '27.978498',
    longitude: '-82.200446',
    timeZoneId: 'America/New_York',
  },
  '33592': {
    zip: '33592',
    city: 'Thonotosassa',
    state: 'FL',
    latitude: '28.06097',
    longitude: '-82.30421',
    timeZoneId: 'America/New_York',
  },
  '33593': {
    zip: '33593',
    city: 'Trilby',
    state: 'FL',
    latitude: '28.45861',
    longitude: '-82.19591',
    timeZoneId: 'America/New_York',
  },
  '33594': {
    zip: '33594',
    city: 'Valrico',
    state: 'FL',
    latitude: '27.916416',
    longitude: '-82.249089',
    timeZoneId: 'America/New_York',
  },
  '33595': {
    zip: '33595',
    city: 'Valrico',
    state: 'FL',
    latitude: '27.947074',
    longitude: '-82.258942',
    timeZoneId: 'America/New_York',
  },
  '33596': {
    zip: '33596',
    city: 'Valrico',
    state: 'FL',
    latitude: '27.871499',
    longitude: '-82.251412',
    timeZoneId: 'America/New_York',
  },
  '33597': {
    zip: '33597',
    city: 'Webster',
    state: 'FL',
    latitude: '28.582156',
    longitude: '-82.172936',
    timeZoneId: 'America/New_York',
  },
  '33598': {
    zip: '33598',
    city: 'Wimauma',
    state: 'FL',
    latitude: '27.699334',
    longitude: '-82.321612',
    timeZoneId: 'America/New_York',
  },
  '33601': {
    zip: '33601',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.981145',
    longitude: '-82.342998',
    timeZoneId: 'America/New_York',
  },
  '33602': {
    zip: '33602',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.955402',
    longitude: '-82.459825',
    timeZoneId: 'America/New_York',
  },
  '33603': {
    zip: '33603',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.985173',
    longitude: '-82.471207',
    timeZoneId: 'America/New_York',
  },
  '33604': {
    zip: '33604',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.015177',
    longitude: '-82.457644',
    timeZoneId: 'America/New_York',
  },
  '33605': {
    zip: '33605',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.962333',
    longitude: '-82.428868',
    timeZoneId: 'America/New_York',
  },
  '33606': {
    zip: '33606',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.936563',
    longitude: '-82.465286',
    timeZoneId: 'America/New_York',
  },
  '33607': {
    zip: '33607',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.965627',
    longitude: '-82.510542',
    timeZoneId: 'America/New_York',
  },
  '33608': {
    zip: '33608',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.841617',
    longitude: '-82.513107',
    timeZoneId: 'America/New_York',
  },
  '33609': {
    zip: '33609',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.941629',
    longitude: '-82.508523',
    timeZoneId: 'America/New_York',
  },
  '33610': {
    zip: '33610',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.992647',
    longitude: '-82.383708',
    timeZoneId: 'America/New_York',
  },
  '33611': {
    zip: '33611',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.888949',
    longitude: '-82.507598',
    timeZoneId: 'America/New_York',
  },
  '33612': {
    zip: '33612',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.05673',
    longitude: '-82.451615',
    timeZoneId: 'America/New_York',
  },
  '33613': {
    zip: '33613',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.075939',
    longitude: '-82.45056',
    timeZoneId: 'America/New_York',
  },
  '33614': {
    zip: '33614',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.007355',
    longitude: '-82.51571',
    timeZoneId: 'America/New_York',
  },
  '33615': {
    zip: '33615',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.009516',
    longitude: '-82.585627',
    timeZoneId: 'America/New_York',
  },
  '33616': {
    zip: '33616',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.871387',
    longitude: '-82.519405',
    timeZoneId: 'America/New_York',
  },
  '33617': {
    zip: '33617',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.036676',
    longitude: '-82.39623',
    timeZoneId: 'America/New_York',
  },
  '33618': {
    zip: '33618',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.077694',
    longitude: '-82.499088',
    timeZoneId: 'America/New_York',
  },
  '33619': {
    zip: '33619',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.933359',
    longitude: '-82.37821',
    timeZoneId: 'America/New_York',
  },
  '33620': {
    zip: '33620',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.060736',
    longitude: '-82.410807',
    timeZoneId: 'America/New_York',
  },
  '33621': {
    zip: '33621',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.838697',
    longitude: '-82.496433',
    timeZoneId: 'America/New_York',
  },
  '33622': {
    zip: '33622',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.95452',
    longitude: '-82.457101',
    timeZoneId: 'America/New_York',
  },
  '33623': {
    zip: '33623',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.954131',
    longitude: '-82.458719',
    timeZoneId: 'America/New_York',
  },
  '33624': {
    zip: '33624',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.076141',
    longitude: '-82.523628',
    timeZoneId: 'America/New_York',
  },
  '33625': {
    zip: '33625',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.071279',
    longitude: '-82.555434',
    timeZoneId: 'America/New_York',
  },
  '33626': {
    zip: '33626',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.056858',
    longitude: '-82.605871',
    timeZoneId: 'America/New_York',
  },
  '33629': {
    zip: '33629',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.921224',
    longitude: '-82.508478',
    timeZoneId: 'America/New_York',
  },
  '33630': {
    zip: '33630',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.968369',
    longitude: '-82.519828',
    timeZoneId: 'America/New_York',
  },
  '33631': {
    zip: '33631',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.962692',
    longitude: '-82.533494',
    timeZoneId: 'America/New_York',
  },
  '33633': {
    zip: '33633',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.977884',
    longitude: '-82.487078',
    timeZoneId: 'America/New_York',
  },
  '33634': {
    zip: '33634',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.008709',
    longitude: '-82.553744',
    timeZoneId: 'America/New_York',
  },
  '33635': {
    zip: '33635',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.02959',
    longitude: '-82.606177',
    timeZoneId: 'America/New_York',
  },
  '33637': {
    zip: '33637',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.032275',
    longitude: '-82.365111',
    timeZoneId: 'America/New_York',
  },
  '33646': {
    zip: '33646',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.947016',
    longitude: '-82.459229',
    timeZoneId: 'America/New_York',
  },
  '33647': {
    zip: '33647',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.114509',
    longitude: '-82.363889',
    timeZoneId: 'America/New_York',
  },
  '33650': {
    zip: '33650',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.968812',
    longitude: '-82.518425',
    timeZoneId: 'America/New_York',
  },
  '33655': {
    zip: '33655',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.948815',
    longitude: '-82.455581',
    timeZoneId: 'America/New_York',
  },
  '33660': {
    zip: '33660',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.931147',
    longitude: '-82.375898',
    timeZoneId: 'America/New_York',
  },
  '33661': {
    zip: '33661',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.971525',
    longitude: '-82.348109',
    timeZoneId: 'America/New_York',
  },
  '33662': {
    zip: '33662',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.971741',
    longitude: '-82.348435',
    timeZoneId: 'America/New_York',
  },
  '33663': {
    zip: '33663',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.961787',
    longitude: '-82.527993',
    timeZoneId: 'America/New_York',
  },
  '33664': {
    zip: '33664',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.96776',
    longitude: '-82.51825',
    timeZoneId: 'America/New_York',
  },
  '33672': {
    zip: '33672',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.955873',
    longitude: '-82.454991',
    timeZoneId: 'America/New_York',
  },
  '33673': {
    zip: '33673',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.997099',
    longitude: '-82.459984',
    timeZoneId: 'America/New_York',
  },
  '33674': {
    zip: '33674',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.018749',
    longitude: '-82.457886',
    timeZoneId: 'America/New_York',
  },
  '33675': {
    zip: '33675',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.119021',
    longitude: '-82.471502',
    timeZoneId: 'America/New_York',
  },
  '33677': {
    zip: '33677',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.966235',
    longitude: '-82.520188',
    timeZoneId: 'America/New_York',
  },
  '33679': {
    zip: '33679',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.952793',
    longitude: '-82.508216',
    timeZoneId: 'America/New_York',
  },
  '33680': {
    zip: '33680',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.00275',
    longitude: '-82.400927',
    timeZoneId: 'America/New_York',
  },
  '33681': {
    zip: '33681',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.893868',
    longitude: '-82.525675',
    timeZoneId: 'America/New_York',
  },
  '33682': {
    zip: '33682',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.055906',
    longitude: '-82.45906',
    timeZoneId: 'America/New_York',
  },
  '33684': {
    zip: '33684',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.995506',
    longitude: '-82.49671',
    timeZoneId: 'America/New_York',
  },
  '33685': {
    zip: '33685',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.000085',
    longitude: '-82.583494',
    timeZoneId: 'America/New_York',
  },
  '33686': {
    zip: '33686',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.868507',
    longitude: '-82.526473',
    timeZoneId: 'America/New_York',
  },
  '33687': {
    zip: '33687',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.035966',
    longitude: '-82.390978',
    timeZoneId: 'America/New_York',
  },
  '33688': {
    zip: '33688',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.061605',
    longitude: '-82.505549',
    timeZoneId: 'America/New_York',
  },
  '33689': {
    zip: '33689',
    city: 'Tampa',
    state: 'FL',
    latitude: '27.950388',
    longitude: '-82.459663',
    timeZoneId: 'America/New_York',
  },
  '33694': {
    zip: '33694',
    city: 'Tampa',
    state: 'FL',
    latitude: '28.06931',
    longitude: '-82.497203',
    timeZoneId: 'America/New_York',
  },
  '33701': {
    zip: '33701',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.767645',
    longitude: '-82.638123',
    timeZoneId: 'America/New_York',
  },
  '33702': {
    zip: '33702',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.844334',
    longitude: '-82.643675',
    timeZoneId: 'America/New_York',
  },
  '33703': {
    zip: '33703',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.819613',
    longitude: '-82.622849',
    timeZoneId: 'America/New_York',
  },
  '33704': {
    zip: '33704',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.794804',
    longitude: '-82.635433',
    timeZoneId: 'America/New_York',
  },
  '33705': {
    zip: '33705',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.742211',
    longitude: '-82.643117',
    timeZoneId: 'America/New_York',
  },
  '33706': {
    zip: '33706',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.74134',
    longitude: '-82.748341',
    timeZoneId: 'America/New_York',
  },
  '33707': {
    zip: '33707',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.758748',
    longitude: '-82.721353',
    timeZoneId: 'America/New_York',
  },
  '33708': {
    zip: '33708',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.814056',
    longitude: '-82.796577',
    timeZoneId: 'America/New_York',
  },
  '33709': {
    zip: '33709',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.817643',
    longitude: '-82.732339',
    timeZoneId: 'America/New_York',
  },
  '33710': {
    zip: '33710',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.788979',
    longitude: '-82.729043',
    timeZoneId: 'America/New_York',
  },
  '33711': {
    zip: '33711',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.751284',
    longitude: '-82.687336',
    timeZoneId: 'America/New_York',
  },
  '33712': {
    zip: '33712',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.730222',
    longitude: '-82.667425',
    timeZoneId: 'America/New_York',
  },
  '33713': {
    zip: '33713',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.788397',
    longitude: '-82.674456',
    timeZoneId: 'America/New_York',
  },
  '33714': {
    zip: '33714',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.819947',
    longitude: '-82.679529',
    timeZoneId: 'America/New_York',
  },
  '33715': {
    zip: '33715',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.68712',
    longitude: '-82.71258',
    timeZoneId: 'America/New_York',
  },
  '33716': {
    zip: '33716',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.878343',
    longitude: '-82.649244',
    timeZoneId: 'America/New_York',
  },
  '33729': {
    zip: '33729',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.878378',
    longitude: '-82.669082',
    timeZoneId: 'America/New_York',
  },
  '33730': {
    zip: '33730',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.822536',
    longitude: '-82.827888',
    timeZoneId: 'America/New_York',
  },
  '33731': {
    zip: '33731',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.708024',
    longitude: '-82.676949',
    timeZoneId: 'America/New_York',
  },
  '33732': {
    zip: '33732',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.756372',
    longitude: '-82.643512',
    timeZoneId: 'America/New_York',
  },
  '33733': {
    zip: '33733',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.770605',
    longitude: '-82.677731',
    timeZoneId: 'America/New_York',
  },
  '33734': {
    zip: '33734',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.805743',
    longitude: '-82.647994',
    timeZoneId: 'America/New_York',
  },
  '33736': {
    zip: '33736',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.742754',
    longitude: '-82.748427',
    timeZoneId: 'America/New_York',
  },
  '33738': {
    zip: '33738',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.801363',
    longitude: '-82.801164',
    timeZoneId: 'America/New_York',
  },
  '33740': {
    zip: '33740',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.770012',
    longitude: '-82.766347',
    timeZoneId: 'America/New_York',
  },
  '33741': {
    zip: '33741',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.688063',
    longitude: '-82.733847',
    timeZoneId: 'America/New_York',
  },
  '33742': {
    zip: '33742',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.844686',
    longitude: '-82.643445',
    timeZoneId: 'America/New_York',
  },
  '33743': {
    zip: '33743',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.782429',
    longitude: '-82.727623',
    timeZoneId: 'America/New_York',
  },
  '33744': {
    zip: '33744',
    city: 'Bay Pines',
    state: 'FL',
    latitude: '27.817002',
    longitude: '-82.774691',
    timeZoneId: 'America/New_York',
  },
  '33747': {
    zip: '33747',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.772495',
    longitude: '-82.679889',
    timeZoneId: 'America/New_York',
  },
  '33755': {
    zip: '33755',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.977549',
    longitude: '-82.782998',
    timeZoneId: 'America/New_York',
  },
  '33756': {
    zip: '33756',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.94594',
    longitude: '-82.788362',
    timeZoneId: 'America/New_York',
  },
  '33757': {
    zip: '33757',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.980018',
    longitude: '-82.780895',
    timeZoneId: 'America/New_York',
  },
  '33758': {
    zip: '33758',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.974259',
    longitude: '-82.749702',
    timeZoneId: 'America/New_York',
  },
  '33759': {
    zip: '33759',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.979517',
    longitude: '-82.718245',
    timeZoneId: 'America/New_York',
  },
  '33760': {
    zip: '33760',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.905781',
    longitude: '-82.718355',
    timeZoneId: 'America/New_York',
  },
  '33761': {
    zip: '33761',
    city: 'Clearwater',
    state: 'FL',
    latitude: '28.029446',
    longitude: '-82.724016',
    timeZoneId: 'America/New_York',
  },
  '33762': {
    zip: '33762',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.895316',
    longitude: '-82.677117',
    timeZoneId: 'America/New_York',
  },
  '33763': {
    zip: '33763',
    city: 'Clearwater',
    state: 'FL',
    latitude: '28.001976',
    longitude: '-82.746528',
    timeZoneId: 'America/New_York',
  },
  '33764': {
    zip: '33764',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.938898',
    longitude: '-82.744839',
    timeZoneId: 'America/New_York',
  },
  '33765': {
    zip: '33765',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.974032',
    longitude: '-82.745337',
    timeZoneId: 'America/New_York',
  },
  '33766': {
    zip: '33766',
    city: 'Clearwater',
    state: 'FL',
    latitude: '28.030055',
    longitude: '-82.71991',
    timeZoneId: 'America/New_York',
  },
  '33767': {
    zip: '33767',
    city: 'Clearwater Beach',
    state: 'FL',
    latitude: '27.982798',
    longitude: '-82.817763',
    timeZoneId: 'America/New_York',
  },
  '33769': {
    zip: '33769',
    city: 'Clearwater',
    state: 'FL',
    latitude: '27.968846',
    longitude: '-82.803211',
    timeZoneId: 'America/New_York',
  },
  '33770': {
    zip: '33770',
    city: 'Largo',
    state: 'FL',
    latitude: '27.920852',
    longitude: '-82.799669',
    timeZoneId: 'America/New_York',
  },
  '33771': {
    zip: '33771',
    city: 'Largo',
    state: 'FL',
    latitude: '27.901331',
    longitude: '-82.756093',
    timeZoneId: 'America/New_York',
  },
  '33772': {
    zip: '33772',
    city: 'Seminole',
    state: 'FL',
    latitude: '27.837047',
    longitude: '-82.799175',
    timeZoneId: 'America/New_York',
  },
  '33773': {
    zip: '33773',
    city: 'Largo',
    state: 'FL',
    latitude: '27.886791',
    longitude: '-82.755307',
    timeZoneId: 'America/New_York',
  },
  '33774': {
    zip: '33774',
    city: 'Largo',
    state: 'FL',
    latitude: '27.878143',
    longitude: '-82.827197',
    timeZoneId: 'America/New_York',
  },
  '33775': {
    zip: '33775',
    city: 'Seminole',
    state: 'FL',
    latitude: '27.84531',
    longitude: '-82.794565',
    timeZoneId: 'America/New_York',
  },
  '33776': {
    zip: '33776',
    city: 'Seminole',
    state: 'FL',
    latitude: '27.850818',
    longitude: '-82.829083',
    timeZoneId: 'America/New_York',
  },
  '33777': {
    zip: '33777',
    city: 'Seminole',
    state: 'FL',
    latitude: '27.853863',
    longitude: '-82.762036',
    timeZoneId: 'America/New_York',
  },
  '33778': {
    zip: '33778',
    city: 'Largo',
    state: 'FL',
    latitude: '27.885994',
    longitude: '-82.798166',
    timeZoneId: 'America/New_York',
  },
  '33779': {
    zip: '33779',
    city: 'Largo',
    state: 'FL',
    latitude: '27.910349',
    longitude: '-82.760117',
    timeZoneId: 'America/New_York',
  },
  '33780': {
    zip: '33780',
    city: 'Pinellas Park',
    state: 'FL',
    latitude: '27.84',
    longitude: '-82.720795',
    timeZoneId: 'America/New_York',
  },
  '33781': {
    zip: '33781',
    city: 'Pinellas Park',
    state: 'FL',
    latitude: '27.838073',
    longitude: '-82.715875',
    timeZoneId: 'America/New_York',
  },
  '33782': {
    zip: '33782',
    city: 'Pinellas Park',
    state: 'FL',
    latitude: '27.855702',
    longitude: '-82.706232',
    timeZoneId: 'America/New_York',
  },
  '33784': {
    zip: '33784',
    city: 'Saint Petersburg',
    state: 'FL',
    latitude: '27.815529',
    longitude: '-82.6797',
    timeZoneId: 'America/New_York',
  },
  '33785': {
    zip: '33785',
    city: 'Indian Rocks Beach',
    state: 'FL',
    latitude: '27.894398',
    longitude: '-82.844386',
    timeZoneId: 'America/New_York',
  },
  '33786': {
    zip: '33786',
    city: 'Belleair Beach',
    state: 'FL',
    latitude: '27.922839',
    longitude: '-82.834505',
    timeZoneId: 'America/New_York',
  },
  '33801': {
    zip: '33801',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.037963',
    longitude: '-81.943908',
    timeZoneId: 'America/New_York',
  },
  '33802': {
    zip: '33802',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.064649',
    longitude: '-81.943511',
    timeZoneId: 'America/New_York',
  },
  '33803': {
    zip: '33803',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.01467',
    longitude: '-81.934529',
    timeZoneId: 'America/New_York',
  },
  '33804': {
    zip: '33804',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.079105',
    longitude: '-81.954512',
    timeZoneId: 'America/New_York',
  },
  '33805': {
    zip: '33805',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.085598',
    longitude: '-81.97553',
    timeZoneId: 'America/New_York',
  },
  '33806': {
    zip: '33806',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.02891',
    longitude: '-81.955237',
    timeZoneId: 'America/New_York',
  },
  '33807': {
    zip: '33807',
    city: 'Lakeland',
    state: 'FL',
    latitude: '27.953142',
    longitude: '-81.991994',
    timeZoneId: 'America/New_York',
  },
  '33809': {
    zip: '33809',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.121806',
    longitude: '-81.983669',
    timeZoneId: 'America/New_York',
  },
  '33810': {
    zip: '33810',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.11224',
    longitude: '-82.015226',
    timeZoneId: 'America/New_York',
  },
  '33811': {
    zip: '33811',
    city: 'Lakeland',
    state: 'FL',
    latitude: '27.962536',
    longitude: '-82.008592',
    timeZoneId: 'America/New_York',
  },
  '33812': {
    zip: '33812',
    city: 'Lakeland',
    state: 'FL',
    latitude: '27.962476',
    longitude: '-82.008918',
    timeZoneId: 'America/New_York',
  },
  '33813': {
    zip: '33813',
    city: 'Lakeland',
    state: 'FL',
    latitude: '27.966593',
    longitude: '-81.934465',
    timeZoneId: 'America/New_York',
  },
  '33815': {
    zip: '33815',
    city: 'Lakeland',
    state: 'FL',
    latitude: '28.038562',
    longitude: '-81.997042',
    timeZoneId: 'America/New_York',
  },
  '33820': {
    zip: '33820',
    city: 'Alturas',
    state: 'FL',
    latitude: '27.862327',
    longitude: '-81.710585',
    timeZoneId: 'America/New_York',
  },
  '33823': {
    zip: '33823',
    city: 'Auburndale',
    state: 'FL',
    latitude: '28.072406',
    longitude: '-81.80446',
    timeZoneId: 'America/New_York',
  },
  '33825': {
    zip: '33825',
    city: 'Avon Park',
    state: 'FL',
    latitude: '27.604536',
    longitude: '-81.509633',
    timeZoneId: 'America/New_York',
  },
  '33826': {
    zip: '33826',
    city: 'Avon Park',
    state: 'FL',
    latitude: '27.593634',
    longitude: '-81.502978',
    timeZoneId: 'America/New_York',
  },
  '33827': {
    zip: '33827',
    city: 'Babson Park',
    state: 'FL',
    latitude: '27.830226',
    longitude: '-81.534299',
    timeZoneId: 'America/New_York',
  },
  '33830': {
    zip: '33830',
    city: 'Bartow',
    state: 'FL',
    latitude: '27.899601',
    longitude: '-81.819661',
    timeZoneId: 'America/New_York',
  },
  '33831': {
    zip: '33831',
    city: 'Bartow',
    state: 'FL',
    latitude: '27.895368',
    longitude: '-81.842203',
    timeZoneId: 'America/New_York',
  },
  '33834': {
    zip: '33834',
    city: 'Bowling Green',
    state: 'FL',
    latitude: '27.627339',
    longitude: '-81.834273',
    timeZoneId: 'America/New_York',
  },
  '33835': {
    zip: '33835',
    city: 'Bradley',
    state: 'FL',
    latitude: '27.780904',
    longitude: '-81.917625',
    timeZoneId: 'America/New_York',
  },
  '33836': {
    zip: '33836',
    city: 'Davenport',
    state: 'FL',
    latitude: '28.160466',
    longitude: '-81.60595',
    timeZoneId: 'America/New_York',
  },
  '33837': {
    zip: '33837',
    city: 'Davenport',
    state: 'FL',
    latitude: '28.199839',
    longitude: '-81.620177',
    timeZoneId: 'America/New_York',
  },
  '33838': {
    zip: '33838',
    city: 'Dundee',
    state: 'FL',
    latitude: '28.018703',
    longitude: '-81.618485',
    timeZoneId: 'America/New_York',
  },
  '33839': {
    zip: '33839',
    city: 'Eagle Lake',
    state: 'FL',
    latitude: '27.979013',
    longitude: '-81.758014',
    timeZoneId: 'America/New_York',
  },
  '33840': {
    zip: '33840',
    city: 'Eaton Park',
    state: 'FL',
    latitude: '28.00884',
    longitude: '-81.904087',
    timeZoneId: 'America/New_York',
  },
  '33841': {
    zip: '33841',
    city: 'Fort Meade',
    state: 'FL',
    latitude: '27.748263',
    longitude: '-81.776231',
    timeZoneId: 'America/New_York',
  },
  '33843': {
    zip: '33843',
    city: 'Frostproof',
    state: 'FL',
    latitude: '27.741623',
    longitude: '-81.547806',
    timeZoneId: 'America/New_York',
  },
  '33844': {
    zip: '33844',
    city: 'Haines City',
    state: 'FL',
    latitude: '28.099287',
    longitude: '-81.615558',
    timeZoneId: 'America/New_York',
  },
  '33845': {
    zip: '33845',
    city: 'Haines City',
    state: 'FL',
    latitude: '28.110209',
    longitude: '-81.629416',
    timeZoneId: 'America/New_York',
  },
  '33846': {
    zip: '33846',
    city: 'Highland City',
    state: 'FL',
    latitude: '27.965064',
    longitude: '-81.878891',
    timeZoneId: 'America/New_York',
  },
  '33847': {
    zip: '33847',
    city: 'Homeland',
    state: 'FL',
    latitude: '27.818652',
    longitude: '-81.827765',
    timeZoneId: 'America/New_York',
  },
  '33848': {
    zip: '33848',
    city: 'Intercession City',
    state: 'FL',
    latitude: '28.263539',
    longitude: '-81.506217',
    timeZoneId: 'America/New_York',
  },
  '33849': {
    zip: '33849',
    city: 'Kathleen',
    state: 'FL',
    latitude: '28.159532',
    longitude: '-82.037045',
    timeZoneId: 'America/New_York',
  },
  '33850': {
    zip: '33850',
    city: 'Lake Alfred',
    state: 'FL',
    latitude: '28.088186',
    longitude: '-81.727355',
    timeZoneId: 'America/New_York',
  },
  '33851': {
    zip: '33851',
    city: 'Lake Hamilton',
    state: 'FL',
    latitude: '28.044293',
    longitude: '-81.623281',
    timeZoneId: 'America/New_York',
  },
  '33852': {
    zip: '33852',
    city: 'Lake Placid',
    state: 'FL',
    latitude: '27.28862',
    longitude: '-81.36857',
    timeZoneId: 'America/New_York',
  },
  '33853': {
    zip: '33853',
    city: 'Lake Wales',
    state: 'FL',
    latitude: '27.903462',
    longitude: '-81.534572',
    timeZoneId: 'America/New_York',
  },
  '33854': {
    zip: '33854',
    city: 'Lakeshore',
    state: 'FL',
    latitude: '27.857939',
    longitude: '-81.412475',
    timeZoneId: 'America/New_York',
  },
  '33855': {
    zip: '33855',
    city: 'Indian Lake Estates',
    state: 'FL',
    latitude: '27.873608',
    longitude: '-81.477219',
    timeZoneId: 'America/New_York',
  },
  '33856': {
    zip: '33856',
    city: 'Nalcrest',
    state: 'FL',
    latitude: '27.870875',
    longitude: '-81.474398',
    timeZoneId: 'America/New_York',
  },
  '33857': {
    zip: '33857',
    city: 'Lorida',
    state: 'FL',
    latitude: '27.427648',
    longitude: '-81.226924',
    timeZoneId: 'America/New_York',
  },
  '33858': {
    zip: '33858',
    city: 'Loughman',
    state: 'FL',
    latitude: '28.242334',
    longitude: '-81.566897',
    timeZoneId: 'America/New_York',
  },
  '33859': {
    zip: '33859',
    city: 'Lake Wales',
    state: 'FL',
    latitude: '27.79459',
    longitude: '-81.400121',
    timeZoneId: 'America/New_York',
  },
  '33860': {
    zip: '33860',
    city: 'Mulberry',
    state: 'FL',
    latitude: '27.898459',
    longitude: '-81.99684',
    timeZoneId: 'America/New_York',
  },
  '33862': {
    zip: '33862',
    city: 'Lake Placid',
    state: 'FL',
    latitude: '27.293438',
    longitude: '-81.361851',
    timeZoneId: 'America/New_York',
  },
  '33863': {
    zip: '33863',
    city: 'Nichols',
    state: 'FL',
    latitude: '27.866121',
    longitude: '-81.805848',
    timeZoneId: 'America/New_York',
  },
  '33865': {
    zip: '33865',
    city: 'Ona',
    state: 'FL',
    latitude: '27.461174',
    longitude: '-81.961885',
    timeZoneId: 'America/New_York',
  },
  '33867': {
    zip: '33867',
    city: 'River Ranch',
    state: 'FL',
    latitude: '27.884462',
    longitude: '-81.47959',
    timeZoneId: 'America/New_York',
  },
  '33868': {
    zip: '33868',
    city: 'Polk City',
    state: 'FL',
    latitude: '28.195584',
    longitude: '-81.831716',
    timeZoneId: 'America/New_York',
  },
  '33870': {
    zip: '33870',
    city: 'Sebring',
    state: 'FL',
    latitude: '27.486041',
    longitude: '-81.416909',
    timeZoneId: 'America/New_York',
  },
  '33871': {
    zip: '33871',
    city: 'Sebring',
    state: 'FL',
    latitude: '27.503328',
    longitude: '-81.453853',
    timeZoneId: 'America/New_York',
  },
  '33872': {
    zip: '33872',
    city: 'Sebring',
    state: 'FL',
    latitude: '27.46334',
    longitude: '-81.482785',
    timeZoneId: 'America/New_York',
  },
  '33873': {
    zip: '33873',
    city: 'Wauchula',
    state: 'FL',
    latitude: '27.543598',
    longitude: '-81.82069',
    timeZoneId: 'America/New_York',
  },
  '33875': {
    zip: '33875',
    city: 'Sebring',
    state: 'FL',
    latitude: '27.42953',
    longitude: '-81.46862',
    timeZoneId: 'America/New_York',
  },
  '33876': {
    zip: '33876',
    city: 'Sebring',
    state: 'FL',
    latitude: '27.441087',
    longitude: '-81.35887',
    timeZoneId: 'America/New_York',
  },
  '33877': {
    zip: '33877',
    city: 'Waverly',
    state: 'FL',
    latitude: '27.980169',
    longitude: '-81.614641',
    timeZoneId: 'America/New_York',
  },
  '33880': {
    zip: '33880',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '27.996505',
    longitude: '-81.752285',
    timeZoneId: 'America/New_York',
  },
  '33881': {
    zip: '33881',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '28.044545',
    longitude: '-81.728425',
    timeZoneId: 'America/New_York',
  },
  '33882': {
    zip: '33882',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '28.003388',
    longitude: '-81.671983',
    timeZoneId: 'America/New_York',
  },
  '33883': {
    zip: '33883',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '28.023165',
    longitude: '-81.726968',
    timeZoneId: 'America/New_York',
  },
  '33884': {
    zip: '33884',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '27.999605',
    longitude: '-81.679242',
    timeZoneId: 'America/New_York',
  },
  '33885': {
    zip: '33885',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '28.053367',
    longitude: '-81.712064',
    timeZoneId: 'America/New_York',
  },
  '33888': {
    zip: '33888',
    city: 'Winter Haven',
    state: 'FL',
    latitude: '28.044236',
    longitude: '-81.722199',
    timeZoneId: 'America/New_York',
  },
  '33890': {
    zip: '33890',
    city: 'Zolfo Springs',
    state: 'FL',
    latitude: '27.492646',
    longitude: '-81.789165',
    timeZoneId: 'America/New_York',
  },
  '33896': {
    zip: '33896',
    city: 'Davenport',
    state: 'FL',
    latitude: '28.198079',
    longitude: '-81.621088',
    timeZoneId: 'America/New_York',
  },
  '33897': {
    zip: '33897',
    city: 'Davenport',
    state: 'FL',
    latitude: '28.199309',
    longitude: '-81.61863',
    timeZoneId: 'America/New_York',
  },
  '33898': {
    zip: '33898',
    city: 'Lake Wales',
    state: 'FL',
    latitude: '27.900343',
    longitude: '-81.535123',
    timeZoneId: 'America/New_York',
  },
  '33901': {
    zip: '33901',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.628428',
    longitude: '-81.86758',
    timeZoneId: 'America/New_York',
  },
  '33902': {
    zip: '33902',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.604733',
    longitude: '-81.863805',
    timeZoneId: 'America/New_York',
  },
  '33903': {
    zip: '33903',
    city: 'North Fort Myers',
    state: 'FL',
    latitude: '26.683534',
    longitude: '-81.905577',
    timeZoneId: 'America/New_York',
  },
  '33904': {
    zip: '33904',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.59961',
    longitude: '-81.950324',
    timeZoneId: 'America/New_York',
  },
  '33905': {
    zip: '33905',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.67091',
    longitude: '-81.80214',
    timeZoneId: 'America/New_York',
  },
  '33906': {
    zip: '33906',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.59268',
    longitude: '-81.857155',
    timeZoneId: 'America/New_York',
  },
  '33907': {
    zip: '33907',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.564829',
    longitude: '-81.88708',
    timeZoneId: 'America/New_York',
  },
  '33908': {
    zip: '33908',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.506918',
    longitude: '-81.92854',
    timeZoneId: 'America/New_York',
  },
  '33909': {
    zip: '33909',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.672523',
    longitude: '-81.972797',
    timeZoneId: 'America/New_York',
  },
  '33910': {
    zip: '33910',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.551448',
    longitude: '-82.020111',
    timeZoneId: 'America/New_York',
  },
  '33911': {
    zip: '33911',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.628255',
    longitude: '-81.993931',
    timeZoneId: 'America/New_York',
  },
  '33912': {
    zip: '33912',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.509195',
    longitude: '-81.832204',
    timeZoneId: 'America/New_York',
  },
  '33913': {
    zip: '33913',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.49719',
    longitude: '-81.730739',
    timeZoneId: 'America/New_York',
  },
  '33914': {
    zip: '33914',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.580208',
    longitude: '-81.998543',
    timeZoneId: 'America/New_York',
  },
  '33915': {
    zip: '33915',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.669179',
    longitude: '-82.00387',
    timeZoneId: 'America/New_York',
  },
  '33916': {
    zip: '33916',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.630792',
    longitude: '-81.83411',
    timeZoneId: 'America/New_York',
  },
  '33917': {
    zip: '33917',
    city: 'North Fort Myers',
    state: 'FL',
    latitude: '26.704114',
    longitude: '-81.853949',
    timeZoneId: 'America/New_York',
  },
  '33918': {
    zip: '33918',
    city: 'North Fort Myers',
    state: 'FL',
    latitude: '26.676957',
    longitude: '-81.892701',
    timeZoneId: 'America/New_York',
  },
  '33919': {
    zip: '33919',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.561274',
    longitude: '-81.905205',
    timeZoneId: 'America/New_York',
  },
  '33920': {
    zip: '33920',
    city: 'Alva',
    state: 'FL',
    latitude: '26.711165',
    longitude: '-81.630975',
    timeZoneId: 'America/New_York',
  },
  '33921': {
    zip: '33921',
    city: 'Boca Grande',
    state: 'FL',
    latitude: '26.758763',
    longitude: '-82.265044',
    timeZoneId: 'America/New_York',
  },
  '33922': {
    zip: '33922',
    city: 'Bokeelia',
    state: 'FL',
    latitude: '26.659618',
    longitude: '-82.132968',
    timeZoneId: 'America/New_York',
  },
  '33924': {
    zip: '33924',
    city: 'Captiva',
    state: 'FL',
    latitude: '26.519264',
    longitude: '-82.188154',
    timeZoneId: 'America/New_York',
  },
  '33927': {
    zip: '33927',
    city: 'El Jobean',
    state: 'FL',
    latitude: '26.976643',
    longitude: '-82.202329',
    timeZoneId: 'America/New_York',
  },
  '33928': {
    zip: '33928',
    city: 'Estero',
    state: 'FL',
    latitude: '26.399815',
    longitude: '-81.860317',
    timeZoneId: 'America/New_York',
  },
  '33929': {
    zip: '33929',
    city: 'Estero',
    state: 'FL',
    latitude: '26.436857',
    longitude: '-81.809864',
    timeZoneId: 'America/New_York',
  },
  '33930': {
    zip: '33930',
    city: 'Felda',
    state: 'FL',
    latitude: '26.710106',
    longitude: '-81.529356',
    timeZoneId: 'America/New_York',
  },
  '33931': {
    zip: '33931',
    city: 'Fort Myers Beach',
    state: 'FL',
    latitude: '26.447226',
    longitude: '-81.931788',
    timeZoneId: 'America/New_York',
  },
  '33932': {
    zip: '33932',
    city: 'Fort Myers Beach',
    state: 'FL',
    latitude: '26.449073',
    longitude: '-81.932449',
    timeZoneId: 'America/New_York',
  },
  '33935': {
    zip: '33935',
    city: 'Labelle',
    state: 'FL',
    latitude: '26.6343',
    longitude: '-81.3414',
    timeZoneId: 'America/New_York',
  },
  '33936': {
    zip: '33936',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.615621',
    longitude: '-81.616389',
    timeZoneId: 'America/New_York',
  },
  '33938': {
    zip: '33938',
    city: 'Murdock',
    state: 'FL',
    latitude: '27.010988',
    longitude: '-82.162362',
    timeZoneId: 'America/New_York',
  },
  '33944': {
    zip: '33944',
    city: 'Palmdale',
    state: 'FL',
    latitude: '26.943751',
    longitude: '-81.315508',
    timeZoneId: 'America/New_York',
  },
  '33945': {
    zip: '33945',
    city: 'Pineland',
    state: 'FL',
    latitude: '26.660469',
    longitude: '-82.144604',
    timeZoneId: 'America/New_York',
  },
  '33946': {
    zip: '33946',
    city: 'Placida',
    state: 'FL',
    latitude: '26.851772',
    longitude: '-82.287635',
    timeZoneId: 'America/New_York',
  },
  '33947': {
    zip: '33947',
    city: 'Rotonda West',
    state: 'FL',
    latitude: '26.890856',
    longitude: '-82.289031',
    timeZoneId: 'America/New_York',
  },
  '33948': {
    zip: '33948',
    city: 'Port Charlotte',
    state: 'FL',
    latitude: '26.983322',
    longitude: '-82.144991',
    timeZoneId: 'America/New_York',
  },
  '33949': {
    zip: '33949',
    city: 'Port Charlotte',
    state: 'FL',
    latitude: '26.97582',
    longitude: '-82.0908',
    timeZoneId: 'America/New_York',
  },
  '33950': {
    zip: '33950',
    city: 'Punta Gorda',
    state: 'FL',
    latitude: '26.912326',
    longitude: '-82.046888',
    timeZoneId: 'America/New_York',
  },
  '33951': {
    zip: '33951',
    city: 'Punta Gorda',
    state: 'FL',
    latitude: '26.935314',
    longitude: '-81.986584',
    timeZoneId: 'America/New_York',
  },
  '33952': {
    zip: '33952',
    city: 'Port Charlotte',
    state: 'FL',
    latitude: '26.991101',
    longitude: '-82.099054',
    timeZoneId: 'America/New_York',
  },
  '33953': {
    zip: '33953',
    city: 'Port Charlotte',
    state: 'FL',
    latitude: '27.000048',
    longitude: '-82.209983',
    timeZoneId: 'America/New_York',
  },
  '33954': {
    zip: '33954',
    city: 'Port Charlotte',
    state: 'FL',
    latitude: '27.023295',
    longitude: '-82.107696',
    timeZoneId: 'America/New_York',
  },
  '33955': {
    zip: '33955',
    city: 'Punta Gorda',
    state: 'FL',
    latitude: '26.83994',
    longitude: '-81.997611',
    timeZoneId: 'America/New_York',
  },
  '33956': {
    zip: '33956',
    city: 'Saint James City',
    state: 'FL',
    latitude: '26.527436',
    longitude: '-82.090909',
    timeZoneId: 'America/New_York',
  },
  '33957': {
    zip: '33957',
    city: 'Sanibel',
    state: 'FL',
    latitude: '26.446199',
    longitude: '-82.086096',
    timeZoneId: 'America/New_York',
  },
  '33960': {
    zip: '33960',
    city: 'Venus',
    state: 'FL',
    latitude: '27.094549',
    longitude: '-81.397416',
    timeZoneId: 'America/New_York',
  },
  '33965': {
    zip: '33965',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.637271',
    longitude: '-81.872508',
    timeZoneId: 'America/New_York',
  },
  '33966': {
    zip: '33966',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.637141',
    longitude: '-81.871604',
    timeZoneId: 'America/New_York',
  },
  '33967': {
    zip: '33967',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.637179',
    longitude: '-81.87252',
    timeZoneId: 'America/New_York',
  },
  '33970': {
    zip: '33970',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.606435',
    longitude: '-81.665841',
    timeZoneId: 'America/New_York',
  },
  '33971': {
    zip: '33971',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.597681',
    longitude: '-81.699773',
    timeZoneId: 'America/New_York',
  },
  '33972': {
    zip: '33972',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.602691',
    longitude: '-81.632837',
    timeZoneId: 'America/New_York',
  },
  '33973': {
    zip: '33973',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.610303',
    longitude: '-81.691774',
    timeZoneId: 'America/New_York',
  },
  '33974': {
    zip: '33974',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.554897',
    longitude: '-81.626174',
    timeZoneId: 'America/New_York',
  },
  '33975': {
    zip: '33975',
    city: 'Labelle',
    state: 'FL',
    latitude: '26.758822',
    longitude: '-81.44101',
    timeZoneId: 'America/New_York',
  },
  '33976': {
    zip: '33976',
    city: 'Lehigh Acres',
    state: 'FL',
    latitude: '26.612085',
    longitude: '-81.663866',
    timeZoneId: 'America/New_York',
  },
  '33980': {
    zip: '33980',
    city: 'Punta Gorda',
    state: 'FL',
    latitude: '26.978628',
    longitude: '-82.059701',
    timeZoneId: 'America/New_York',
  },
  '33981': {
    zip: '33981',
    city: 'Port Charlotte',
    state: 'FL',
    latitude: '26.936512',
    longitude: '-82.232968',
    timeZoneId: 'America/New_York',
  },
  '33982': {
    zip: '33982',
    city: 'Punta Gorda',
    state: 'FL',
    latitude: '26.971236',
    longitude: '-81.962313',
    timeZoneId: 'America/New_York',
  },
  '33983': {
    zip: '33983',
    city: 'Punta Gorda',
    state: 'FL',
    latitude: '27.003613',
    longitude: '-82.013836',
    timeZoneId: 'America/New_York',
  },
  '33990': {
    zip: '33990',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.629659',
    longitude: '-81.951755',
    timeZoneId: 'America/New_York',
  },
  '33991': {
    zip: '33991',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.629052',
    longitude: '-82.002027',
    timeZoneId: 'America/New_York',
  },
  '33993': {
    zip: '33993',
    city: 'Cape Coral',
    state: 'FL',
    latitude: '26.713725',
    longitude: '-82.026694',
    timeZoneId: 'America/New_York',
  },
  '33994': {
    zip: '33994',
    city: 'Fort Myers',
    state: 'FL',
    latitude: '26.630413',
    longitude: '-81.852216',
    timeZoneId: 'America/New_York',
  },
  '34101': {
    zip: '34101',
    city: 'Naples',
    state: 'FL',
    latitude: '26.138693',
    longitude: '-81.797096',
    timeZoneId: 'America/New_York',
  },
  '34102': {
    zip: '34102',
    city: 'Naples',
    state: 'FL',
    latitude: '26.143103',
    longitude: '-81.799067',
    timeZoneId: 'America/New_York',
  },
  '34103': {
    zip: '34103',
    city: 'Naples',
    state: 'FL',
    latitude: '26.187903',
    longitude: '-81.804396',
    timeZoneId: 'America/New_York',
  },
  '34104': {
    zip: '34104',
    city: 'Naples',
    state: 'FL',
    latitude: '26.160499',
    longitude: '-81.735857',
    timeZoneId: 'America/New_York',
  },
  '34105': {
    zip: '34105',
    city: 'Naples',
    state: 'FL',
    latitude: '26.185362',
    longitude: '-81.764078',
    timeZoneId: 'America/New_York',
  },
  '34106': {
    zip: '34106',
    city: 'Naples',
    state: 'FL',
    latitude: '26.139274',
    longitude: '-81.797939',
    timeZoneId: 'America/New_York',
  },
  '34107': {
    zip: '34107',
    city: 'Naples',
    state: 'FL',
    latitude: '26.269597',
    longitude: '-81.791672',
    timeZoneId: 'America/New_York',
  },
  '34108': {
    zip: '34108',
    city: 'Naples',
    state: 'FL',
    latitude: '26.236315',
    longitude: '-81.805998',
    timeZoneId: 'America/New_York',
  },
  '34109': {
    zip: '34109',
    city: 'Naples',
    state: 'FL',
    latitude: '26.236364',
    longitude: '-81.76478',
    timeZoneId: 'America/New_York',
  },
  '34110': {
    zip: '34110',
    city: 'Naples',
    state: 'FL',
    latitude: '26.305562',
    longitude: '-81.79088',
    timeZoneId: 'America/New_York',
  },
  '34112': {
    zip: '34112',
    city: 'Naples',
    state: 'FL',
    latitude: '26.118299',
    longitude: '-81.734198',
    timeZoneId: 'America/New_York',
  },
  '34113': {
    zip: '34113',
    city: 'Naples',
    state: 'FL',
    latitude: '26.059541',
    longitude: '-81.74543',
    timeZoneId: 'America/New_York',
  },
  '34114': {
    zip: '34114',
    city: 'Naples',
    state: 'FL',
    latitude: '25.989979',
    longitude: '-81.533404',
    timeZoneId: 'America/New_York',
  },
  '34116': {
    zip: '34116',
    city: 'Naples',
    state: 'FL',
    latitude: '26.182736',
    longitude: '-81.710784',
    timeZoneId: 'America/New_York',
  },
  '34117': {
    zip: '34117',
    city: 'Naples',
    state: 'FL',
    latitude: '26.143413',
    longitude: '-81.523339',
    timeZoneId: 'America/New_York',
  },
  '34119': {
    zip: '34119',
    city: 'Naples',
    state: 'FL',
    latitude: '26.256936',
    longitude: '-81.713878',
    timeZoneId: 'America/New_York',
  },
  '34120': {
    zip: '34120',
    city: 'Naples',
    state: 'FL',
    latitude: '26.312625',
    longitude: '-81.596577',
    timeZoneId: 'America/New_York',
  },
  '34133': {
    zip: '34133',
    city: 'Bonita Springs',
    state: 'FL',
    latitude: '26.382005',
    longitude: '-81.710252',
    timeZoneId: 'America/New_York',
  },
  '34134': {
    zip: '34134',
    city: 'Bonita Springs',
    state: 'FL',
    latitude: '26.362812',
    longitude: '-81.82298',
    timeZoneId: 'America/New_York',
  },
  '34135': {
    zip: '34135',
    city: 'Bonita Springs',
    state: 'FL',
    latitude: '26.387185',
    longitude: '-81.735987',
    timeZoneId: 'America/New_York',
  },
  '34136': {
    zip: '34136',
    city: 'Bonita Springs',
    state: 'FL',
    latitude: '26.387994',
    longitude: '-81.709518',
    timeZoneId: 'America/New_York',
  },
  '34137': {
    zip: '34137',
    city: 'Copeland',
    state: 'FL',
    latitude: '25.946921',
    longitude: '-81.358399',
    timeZoneId: 'America/New_York',
  },
  '34138': {
    zip: '34138',
    city: 'Chokoloskee',
    state: 'FL',
    latitude: '25.814179',
    longitude: '-81.357783',
    timeZoneId: 'America/New_York',
  },
  '34139': {
    zip: '34139',
    city: 'Everglades City',
    state: 'FL',
    latitude: '25.86087',
    longitude: '-81.381711',
    timeZoneId: 'America/New_York',
  },
  '34140': {
    zip: '34140',
    city: 'Goodland',
    state: 'FL',
    latitude: '25.924592',
    longitude: '-81.649561',
    timeZoneId: 'America/New_York',
  },
  '34141': {
    zip: '34141',
    city: 'Ochopee',
    state: 'FL',
    latitude: '25.906809',
    longitude: '-81.303364',
    timeZoneId: 'America/New_York',
  },
  '34142': {
    zip: '34142',
    city: 'Immokalee',
    state: 'FL',
    latitude: '26.327612',
    longitude: '-81.313729',
    timeZoneId: 'America/New_York',
  },
  '34143': {
    zip: '34143',
    city: 'Immokalee',
    state: 'FL',
    latitude: '26.429476',
    longitude: '-81.433479',
    timeZoneId: 'America/New_York',
  },
  '34145': {
    zip: '34145',
    city: 'Marco Island',
    state: 'FL',
    latitude: '25.940928',
    longitude: '-81.719213',
    timeZoneId: 'America/New_York',
  },
  '34146': {
    zip: '34146',
    city: 'Marco Island',
    state: 'FL',
    latitude: '25.943277',
    longitude: '-81.715711',
    timeZoneId: 'America/New_York',
  },
  '34201': {
    zip: '34201',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.390254',
    longitude: '-82.481246',
    timeZoneId: 'America/New_York',
  },
  '34202': {
    zip: '34202',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.475504',
    longitude: '-82.392128',
    timeZoneId: 'America/New_York',
  },
  '34203': {
    zip: '34203',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.44317',
    longitude: '-82.531219',
    timeZoneId: 'America/New_York',
  },
  '34204': {
    zip: '34204',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.442625',
    longitude: '-82.507552',
    timeZoneId: 'America/New_York',
  },
  '34205': {
    zip: '34205',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.484977',
    longitude: '-82.585541',
    timeZoneId: 'America/New_York',
  },
  '34206': {
    zip: '34206',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.491765',
    longitude: '-82.572897',
    timeZoneId: 'America/New_York',
  },
  '34207': {
    zip: '34207',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.43728',
    longitude: '-82.576198',
    timeZoneId: 'America/New_York',
  },
  '34208': {
    zip: '34208',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.484276',
    longitude: '-82.535488',
    timeZoneId: 'America/New_York',
  },
  '34209': {
    zip: '34209',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.508354',
    longitude: '-82.646654',
    timeZoneId: 'America/New_York',
  },
  '34210': {
    zip: '34210',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.454714',
    longitude: '-82.641506',
    timeZoneId: 'America/New_York',
  },
  '34211': {
    zip: '34211',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.469714',
    longitude: '-82.411482',
    timeZoneId: 'America/New_York',
  },
  '34212': {
    zip: '34212',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.471865',
    longitude: '-82.412165',
    timeZoneId: 'America/New_York',
  },
  '34215': {
    zip: '34215',
    city: 'Cortez',
    state: 'FL',
    latitude: '27.470889',
    longitude: '-82.686173',
    timeZoneId: 'America/New_York',
  },
  '34216': {
    zip: '34216',
    city: 'Anna Maria',
    state: 'FL',
    latitude: '27.531908',
    longitude: '-82.733863',
    timeZoneId: 'America/New_York',
  },
  '34217': {
    zip: '34217',
    city: 'Bradenton Beach',
    state: 'FL',
    latitude: '27.508565',
    longitude: '-82.716234',
    timeZoneId: 'America/New_York',
  },
  '34218': {
    zip: '34218',
    city: 'Holmes Beach',
    state: 'FL',
    latitude: '27.50154',
    longitude: '-82.70982',
    timeZoneId: 'America/New_York',
  },
  '34219': {
    zip: '34219',
    city: 'Parrish',
    state: 'FL',
    latitude: '27.562795',
    longitude: '-82.433618',
    timeZoneId: 'America/New_York',
  },
  '34220': {
    zip: '34220',
    city: 'Palmetto',
    state: 'FL',
    latitude: '27.522214',
    longitude: '-82.578741',
    timeZoneId: 'America/New_York',
  },
  '34221': {
    zip: '34221',
    city: 'Palmetto',
    state: 'FL',
    latitude: '27.538108',
    longitude: '-82.55286',
    timeZoneId: 'America/New_York',
  },
  '34222': {
    zip: '34222',
    city: 'Ellenton',
    state: 'FL',
    latitude: '27.5334',
    longitude: '-82.500332',
    timeZoneId: 'America/New_York',
  },
  '34223': {
    zip: '34223',
    city: 'Englewood',
    state: 'FL',
    latitude: '27.013007',
    longitude: '-82.380021',
    timeZoneId: 'America/New_York',
  },
  '34224': {
    zip: '34224',
    city: 'Englewood',
    state: 'FL',
    latitude: '26.92539',
    longitude: '-82.302641',
    timeZoneId: 'America/New_York',
  },
  '34228': {
    zip: '34228',
    city: 'Longboat Key',
    state: 'FL',
    latitude: '27.426057',
    longitude: '-82.672355',
    timeZoneId: 'America/New_York',
  },
  '34229': {
    zip: '34229',
    city: 'Osprey',
    state: 'FL',
    latitude: '27.183926',
    longitude: '-82.488306',
    timeZoneId: 'America/New_York',
  },
  '34230': {
    zip: '34230',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.353613',
    longitude: '-82.486627',
    timeZoneId: 'America/New_York',
  },
  '34231': {
    zip: '34231',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.271652',
    longitude: '-82.516843',
    timeZoneId: 'America/New_York',
  },
  '34232': {
    zip: '34232',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.321096',
    longitude: '-82.476578',
    timeZoneId: 'America/New_York',
  },
  '34233': {
    zip: '34233',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.281597',
    longitude: '-82.473386',
    timeZoneId: 'America/New_York',
  },
  '34234': {
    zip: '34234',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.369954',
    longitude: '-82.528624',
    timeZoneId: 'America/New_York',
  },
  '34235': {
    zip: '34235',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.362992',
    longitude: '-82.480759',
    timeZoneId: 'America/New_York',
  },
  '34236': {
    zip: '34236',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.332306',
    longitude: '-82.551206',
    timeZoneId: 'America/New_York',
  },
  '34237': {
    zip: '34237',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.339503',
    longitude: '-82.514099',
    timeZoneId: 'America/New_York',
  },
  '34238': {
    zip: '34238',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.24743',
    longitude: '-82.489728',
    timeZoneId: 'America/New_York',
  },
  '34239': {
    zip: '34239',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.310402',
    longitude: '-82.517837',
    timeZoneId: 'America/New_York',
  },
  '34240': {
    zip: '34240',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.319476',
    longitude: '-82.386179',
    timeZoneId: 'America/New_York',
  },
  '34241': {
    zip: '34241',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.283889',
    longitude: '-82.409597',
    timeZoneId: 'America/New_York',
  },
  '34242': {
    zip: '34242',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.270991',
    longitude: '-82.547525',
    timeZoneId: 'America/New_York',
  },
  '34243': {
    zip: '34243',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.408675',
    longitude: '-82.523335',
    timeZoneId: 'America/New_York',
  },
  '34249': {
    zip: '34249',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.378467',
    longitude: '-82.576906',
    timeZoneId: 'America/New_York',
  },
  '34250': {
    zip: '34250',
    city: 'Terra Ceia',
    state: 'FL',
    latitude: '27.575069',
    longitude: '-82.576017',
    timeZoneId: 'America/New_York',
  },
  '34251': {
    zip: '34251',
    city: 'Myakka City',
    state: 'FL',
    latitude: '27.338184',
    longitude: '-82.195127',
    timeZoneId: 'America/New_York',
  },
  '34260': {
    zip: '34260',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.421461',
    longitude: '-82.539875',
    timeZoneId: 'America/New_York',
  },
  '34264': {
    zip: '34264',
    city: 'Oneco',
    state: 'FL',
    latitude: '27.445348',
    longitude: '-82.536877',
    timeZoneId: 'America/New_York',
  },
  '34265': {
    zip: '34265',
    city: 'Arcadia',
    state: 'FL',
    latitude: '27.187943',
    longitude: '-81.808861',
    timeZoneId: 'America/New_York',
  },
  '34266': {
    zip: '34266',
    city: 'Arcadia',
    state: 'FL',
    latitude: '27.19915',
    longitude: '-81.847982',
    timeZoneId: 'America/New_York',
  },
  '34267': {
    zip: '34267',
    city: 'Fort Ogden',
    state: 'FL',
    latitude: '27.087121',
    longitude: '-81.954404',
    timeZoneId: 'America/New_York',
  },
  '34268': {
    zip: '34268',
    city: 'Nocatee',
    state: 'FL',
    latitude: '27.162193',
    longitude: '-81.882333',
    timeZoneId: 'America/New_York',
  },
  '34269': {
    zip: '34269',
    city: 'Arcadia',
    state: 'FL',
    latitude: '27.166492',
    longitude: '-81.804395',
    timeZoneId: 'America/New_York',
  },
  '34270': {
    zip: '34270',
    city: 'Tallevast',
    state: 'FL',
    latitude: '27.402668',
    longitude: '-82.541088',
    timeZoneId: 'America/New_York',
  },
  '34272': {
    zip: '34272',
    city: 'Laurel',
    state: 'FL',
    latitude: '27.135805',
    longitude: '-82.447292',
    timeZoneId: 'America/New_York',
  },
  '34274': {
    zip: '34274',
    city: 'Nokomis',
    state: 'FL',
    latitude: '27.135066',
    longitude: '-82.454548',
    timeZoneId: 'America/New_York',
  },
  '34275': {
    zip: '34275',
    city: 'Nokomis',
    state: 'FL',
    latitude: '27.136587',
    longitude: '-82.450143',
    timeZoneId: 'America/New_York',
  },
  '34276': {
    zip: '34276',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.336762',
    longitude: '-82.546895',
    timeZoneId: 'America/New_York',
  },
  '34277': {
    zip: '34277',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.336078',
    longitude: '-82.32478',
    timeZoneId: 'America/New_York',
  },
  '34278': {
    zip: '34278',
    city: 'Sarasota',
    state: 'FL',
    latitude: '27.332107',
    longitude: '-82.530323',
    timeZoneId: 'America/New_York',
  },
  '34280': {
    zip: '34280',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.49884',
    longitude: '-82.636138',
    timeZoneId: 'America/New_York',
  },
  '34281': {
    zip: '34281',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.489556',
    longitude: '-82.579608',
    timeZoneId: 'America/New_York',
  },
  '34282': {
    zip: '34282',
    city: 'Bradenton',
    state: 'FL',
    latitude: '27.442104',
    longitude: '-82.582522',
    timeZoneId: 'America/New_York',
  },
  '34284': {
    zip: '34284',
    city: 'Venice',
    state: 'FL',
    latitude: '27.047938',
    longitude: '-82.407211',
    timeZoneId: 'America/New_York',
  },
  '34285': {
    zip: '34285',
    city: 'Venice',
    state: 'FL',
    latitude: '27.072561',
    longitude: '-82.435523',
    timeZoneId: 'America/New_York',
  },
  '34286': {
    zip: '34286',
    city: 'North Port',
    state: 'FL',
    latitude: '27.070796',
    longitude: '-82.145952',
    timeZoneId: 'America/New_York',
  },
  '34287': {
    zip: '34287',
    city: 'North Port',
    state: 'FL',
    latitude: '27.0783',
    longitude: '-82.18116',
    timeZoneId: 'America/New_York',
  },
  '34288': {
    zip: '34288',
    city: 'North Port',
    state: 'FL',
    latitude: '27.048529',
    longitude: '-82.194897',
    timeZoneId: 'America/New_York',
  },
  '34289': {
    zip: '34289',
    city: 'North Port',
    state: 'FL',
    latitude: '27.052555',
    longitude: '-82.197321',
    timeZoneId: 'America/New_York',
  },
  '34290': {
    zip: '34290',
    city: 'North Port',
    state: 'FL',
    latitude: '27.043504',
    longitude: '-82.236065',
    timeZoneId: 'America/New_York',
  },
  '34291': {
    zip: '34291',
    city: 'North Port',
    state: 'FL',
    latitude: '27.091905',
    longitude: '-82.232783',
    timeZoneId: 'America/New_York',
  },
  '34292': {
    zip: '34292',
    city: 'Venice',
    state: 'FL',
    latitude: '27.104244',
    longitude: '-82.409144',
    timeZoneId: 'America/New_York',
  },
  '34293': {
    zip: '34293',
    city: 'Venice',
    state: 'FL',
    latitude: '27.051449',
    longitude: '-82.393701',
    timeZoneId: 'America/New_York',
  },
  '34295': {
    zip: '34295',
    city: 'Englewood',
    state: 'FL',
    latitude: '26.930149',
    longitude: '-82.283757',
    timeZoneId: 'America/New_York',
  },
  '34420': {
    zip: '34420',
    city: 'Belleview',
    state: 'FL',
    latitude: '29.044347',
    longitude: '-82.036592',
    timeZoneId: 'America/New_York',
  },
  '34421': {
    zip: '34421',
    city: 'Belleview',
    state: 'FL',
    latitude: '29.049241',
    longitude: '-82.036417',
    timeZoneId: 'America/New_York',
  },
  '34423': {
    zip: '34423',
    city: 'Crystal River',
    state: 'FL',
    latitude: '28.862789',
    longitude: '-82.572331',
    timeZoneId: 'America/New_York',
  },
  '34428': {
    zip: '34428',
    city: 'Crystal River',
    state: 'FL',
    latitude: '28.961417',
    longitude: '-82.625622',
    timeZoneId: 'America/New_York',
  },
  '34429': {
    zip: '34429',
    city: 'Crystal River',
    state: 'FL',
    latitude: '28.870559',
    longitude: '-82.590328',
    timeZoneId: 'America/New_York',
  },
  '34430': {
    zip: '34430',
    city: 'Dunnellon',
    state: 'FL',
    latitude: '29.053058',
    longitude: '-82.378464',
    timeZoneId: 'America/New_York',
  },
  '34431': {
    zip: '34431',
    city: 'Dunnellon',
    state: 'FL',
    latitude: '29.141649',
    longitude: '-82.515504',
    timeZoneId: 'America/New_York',
  },
  '34432': {
    zip: '34432',
    city: 'Dunnellon',
    state: 'FL',
    latitude: '29.057502',
    longitude: '-82.386536',
    timeZoneId: 'America/New_York',
  },
  '34433': {
    zip: '34433',
    city: 'Dunnellon',
    state: 'FL',
    latitude: '29.004097',
    longitude: '-82.522871',
    timeZoneId: 'America/New_York',
  },
  '34434': {
    zip: '34434',
    city: 'Dunnellon',
    state: 'FL',
    latitude: '28.998415',
    longitude: '-82.431555',
    timeZoneId: 'America/New_York',
  },
  '34436': {
    zip: '34436',
    city: 'Floral City',
    state: 'FL',
    latitude: '28.721477',
    longitude: '-82.298318',
    timeZoneId: 'America/New_York',
  },
  '34441': {
    zip: '34441',
    city: 'Hernando',
    state: 'FL',
    latitude: '28.898632',
    longitude: '-82.373435',
    timeZoneId: 'America/New_York',
  },
  '34442': {
    zip: '34442',
    city: 'Hernando',
    state: 'FL',
    latitude: '28.93077',
    longitude: '-82.37343',
    timeZoneId: 'America/New_York',
  },
  '34445': {
    zip: '34445',
    city: 'Holder',
    state: 'FL',
    latitude: '28.914824',
    longitude: '-82.459029',
    timeZoneId: 'America/New_York',
  },
  '34446': {
    zip: '34446',
    city: 'Homosassa',
    state: 'FL',
    latitude: '28.747053',
    longitude: '-82.526574',
    timeZoneId: 'America/New_York',
  },
  '34447': {
    zip: '34447',
    city: 'Homosassa Springs',
    state: 'FL',
    latitude: '28.796554',
    longitude: '-82.550226',
    timeZoneId: 'America/New_York',
  },
  '34448': {
    zip: '34448',
    city: 'Homosassa',
    state: 'FL',
    latitude: '28.772147',
    longitude: '-82.591385',
    timeZoneId: 'America/New_York',
  },
  '34449': {
    zip: '34449',
    city: 'Inglis',
    state: 'FL',
    latitude: '29.200281',
    longitude: '-82.708237',
    timeZoneId: 'America/New_York',
  },
  '34450': {
    zip: '34450',
    city: 'Inverness',
    state: 'FL',
    latitude: '28.824261',
    longitude: '-82.257899',
    timeZoneId: 'America/New_York',
  },
  '34451': {
    zip: '34451',
    city: 'Inverness',
    state: 'FL',
    latitude: '28.838477',
    longitude: '-82.331566',
    timeZoneId: 'America/New_York',
  },
  '34452': {
    zip: '34452',
    city: 'Inverness',
    state: 'FL',
    latitude: '28.777209',
    longitude: '-82.39099',
    timeZoneId: 'America/New_York',
  },
  '34453': {
    zip: '34453',
    city: 'Inverness',
    state: 'FL',
    latitude: '28.874263',
    longitude: '-82.350653',
    timeZoneId: 'America/New_York',
  },
  '34460': {
    zip: '34460',
    city: 'Lecanto',
    state: 'FL',
    latitude: '28.93117',
    longitude: '-82.491367',
    timeZoneId: 'America/New_York',
  },
  '34461': {
    zip: '34461',
    city: 'Lecanto',
    state: 'FL',
    latitude: '28.815855',
    longitude: '-82.481143',
    timeZoneId: 'America/New_York',
  },
  '34464': {
    zip: '34464',
    city: 'Beverly Hills',
    state: 'FL',
    latitude: '28.912992',
    longitude: '-82.463184',
    timeZoneId: 'America/New_York',
  },
  '34465': {
    zip: '34465',
    city: 'Beverly Hills',
    state: 'FL',
    latitude: '28.93616',
    longitude: '-82.450412',
    timeZoneId: 'America/New_York',
  },
  '34470': {
    zip: '34470',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.203104',
    longitude: '-82.091918',
    timeZoneId: 'America/New_York',
  },
  '34471': {
    zip: '34471',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.17026',
    longitude: '-82.091441',
    timeZoneId: 'America/New_York',
  },
  '34472': {
    zip: '34472',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.091917',
    longitude: '-81.98932',
    timeZoneId: 'America/New_York',
  },
  '34473': {
    zip: '34473',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.026127',
    longitude: '-82.185636',
    timeZoneId: 'America/New_York',
  },
  '34474': {
    zip: '34474',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.156224',
    longitude: '-82.196036',
    timeZoneId: 'America/New_York',
  },
  '34475': {
    zip: '34475',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.226341',
    longitude: '-82.159076',
    timeZoneId: 'America/New_York',
  },
  '34476': {
    zip: '34476',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.085582',
    longitude: '-82.193215',
    timeZoneId: 'America/New_York',
  },
  '34477': {
    zip: '34477',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.187714',
    longitude: '-82.129017',
    timeZoneId: 'America/New_York',
  },
  '34478': {
    zip: '34478',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.185467',
    longitude: '-82.141799',
    timeZoneId: 'America/New_York',
  },
  '34479': {
    zip: '34479',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.237548',
    longitude: '-82.096893',
    timeZoneId: 'America/New_York',
  },
  '34480': {
    zip: '34480',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.105081',
    longitude: '-82.098267',
    timeZoneId: 'America/New_York',
  },
  '34481': {
    zip: '34481',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.134462',
    longitude: '-82.331561',
    timeZoneId: 'America/New_York',
  },
  '34482': {
    zip: '34482',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.229052',
    longitude: '-82.306676',
    timeZoneId: 'America/New_York',
  },
  '34483': {
    zip: '34483',
    city: 'Ocala',
    state: 'FL',
    latitude: '29.186238',
    longitude: '-82.138652',
    timeZoneId: 'America/New_York',
  },
  '34484': {
    zip: '34484',
    city: 'Oxford',
    state: 'FL',
    latitude: '28.92312',
    longitude: '-82.043905',
    timeZoneId: 'America/New_York',
  },
  '34487': {
    zip: '34487',
    city: 'Homosassa',
    state: 'FL',
    latitude: '28.780574',
    longitude: '-82.615556',
    timeZoneId: 'America/New_York',
  },
  '34488': {
    zip: '34488',
    city: 'Silver Springs',
    state: 'FL',
    latitude: '29.225427',
    longitude: '-81.949001',
    timeZoneId: 'America/New_York',
  },
  '34489': {
    zip: '34489',
    city: 'Silver Springs',
    state: 'FL',
    latitude: '29.226877',
    longitude: '-81.94807',
    timeZoneId: 'America/New_York',
  },
  '34491': {
    zip: '34491',
    city: 'Summerfield',
    state: 'FL',
    latitude: '28.991165',
    longitude: '-82.046372',
    timeZoneId: 'America/New_York',
  },
  '34492': {
    zip: '34492',
    city: 'Summerfield',
    state: 'FL',
    latitude: '28.992642',
    longitude: '-82.049538',
    timeZoneId: 'America/New_York',
  },
  '34498': {
    zip: '34498',
    city: 'Yankeetown',
    state: 'FL',
    latitude: '29.0209',
    longitude: '-82.73136',
    timeZoneId: 'America/New_York',
  },
  '34601': {
    zip: '34601',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.565743',
    longitude: '-82.376785',
    timeZoneId: 'America/New_York',
  },
  '34602': {
    zip: '34602',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.475302',
    longitude: '-82.284057',
    timeZoneId: 'America/New_York',
  },
  '34603': {
    zip: '34603',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.55221',
    longitude: '-82.391059',
    timeZoneId: 'America/New_York',
  },
  '34604': {
    zip: '34604',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.555',
    longitude: '-82.388056',
    timeZoneId: 'America/New_York',
  },
  '34605': {
    zip: '34605',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.496964',
    longitude: '-82.452561',
    timeZoneId: 'America/New_York',
  },
  '34606': {
    zip: '34606',
    city: 'Spring Hill',
    state: 'FL',
    latitude: '28.466311',
    longitude: '-82.596769',
    timeZoneId: 'America/New_York',
  },
  '34607': {
    zip: '34607',
    city: 'Spring Hill',
    state: 'FL',
    latitude: '28.505114',
    longitude: '-82.636115',
    timeZoneId: 'America/New_York',
  },
  '34608': {
    zip: '34608',
    city: 'Spring Hill',
    state: 'FL',
    latitude: '28.480569',
    longitude: '-82.554386',
    timeZoneId: 'America/New_York',
  },
  '34609': {
    zip: '34609',
    city: 'Spring Hill',
    state: 'FL',
    latitude: '28.479491',
    longitude: '-82.499987',
    timeZoneId: 'America/New_York',
  },
  '34610': {
    zip: '34610',
    city: 'Spring Hill',
    state: 'FL',
    latitude: '28.397951',
    longitude: '-82.537892',
    timeZoneId: 'America/New_York',
  },
  '34611': {
    zip: '34611',
    city: 'Spring Hill',
    state: 'FL',
    latitude: '28.482064',
    longitude: '-82.561085',
    timeZoneId: 'America/New_York',
  },
  '34613': {
    zip: '34613',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.556846',
    longitude: '-82.518576',
    timeZoneId: 'America/New_York',
  },
  '34614': {
    zip: '34614',
    city: 'Brooksville',
    state: 'FL',
    latitude: '28.623474',
    longitude: '-82.533927',
    timeZoneId: 'America/New_York',
  },
  '34636': {
    zip: '34636',
    city: 'Istachatta',
    state: 'FL',
    latitude: '28.658911',
    longitude: '-82.273018',
    timeZoneId: 'America/New_York',
  },
  '34637': {
    zip: '34637',
    city: 'Land O Lakes',
    state: 'FL',
    latitude: '28.261247',
    longitude: '-82.464882',
    timeZoneId: 'America/New_York',
  },
  '34638': {
    zip: '34638',
    city: 'Land O Lakes',
    state: 'FL',
    latitude: '28.262356',
    longitude: '-82.51831',
    timeZoneId: 'America/New_York',
  },
  '34639': {
    zip: '34639',
    city: 'Land O Lakes',
    state: 'FL',
    latitude: '28.247163',
    longitude: '-82.49833',
    timeZoneId: 'America/New_York',
  },
  '34652': {
    zip: '34652',
    city: 'New Port Richey',
    state: 'FL',
    latitude: '28.238545',
    longitude: '-82.7307',
    timeZoneId: 'America/New_York',
  },
  '34653': {
    zip: '34653',
    city: 'New Port Richey',
    state: 'FL',
    latitude: '28.248579',
    longitude: '-82.701991',
    timeZoneId: 'America/New_York',
  },
  '34654': {
    zip: '34654',
    city: 'New Port Richey',
    state: 'FL',
    latitude: '28.300248',
    longitude: '-82.634053',
    timeZoneId: 'America/New_York',
  },
  '34655': {
    zip: '34655',
    city: 'New Port Richey',
    state: 'FL',
    latitude: '28.210274',
    longitude: '-82.682809',
    timeZoneId: 'America/New_York',
  },
  '34656': {
    zip: '34656',
    city: 'New Port Richey',
    state: 'FL',
    latitude: '28.243613',
    longitude: '-82.709068',
    timeZoneId: 'America/New_York',
  },
  '34660': {
    zip: '34660',
    city: 'Ozona',
    state: 'FL',
    latitude: '28.066568',
    longitude: '-82.777726',
    timeZoneId: 'America/New_York',
  },
  '34661': {
    zip: '34661',
    city: 'Nobleton',
    state: 'FL',
    latitude: '28.645537',
    longitude: '-82.261836',
    timeZoneId: 'America/New_York',
  },
  '34667': {
    zip: '34667',
    city: 'Hudson',
    state: 'FL',
    latitude: '28.393847',
    longitude: '-82.651438',
    timeZoneId: 'America/New_York',
  },
  '34668': {
    zip: '34668',
    city: 'Port Richey',
    state: 'FL',
    latitude: '28.303686',
    longitude: '-82.70063',
    timeZoneId: 'America/New_York',
  },
  '34669': {
    zip: '34669',
    city: 'Hudson',
    state: 'FL',
    latitude: '28.35975',
    longitude: '-82.610876',
    timeZoneId: 'America/New_York',
  },
  '34673': {
    zip: '34673',
    city: 'Port Richey',
    state: 'FL',
    latitude: '28.275161',
    longitude: '-82.720167',
    timeZoneId: 'America/New_York',
  },
  '34674': {
    zip: '34674',
    city: 'Hudson',
    state: 'FL',
    latitude: '28.357719',
    longitude: '-82.698689',
    timeZoneId: 'America/New_York',
  },
  '34677': {
    zip: '34677',
    city: 'Oldsmar',
    state: 'FL',
    latitude: '28.045649',
    longitude: '-82.687104',
    timeZoneId: 'America/New_York',
  },
  '34679': {
    zip: '34679',
    city: 'Aripeka',
    state: 'FL',
    latitude: '28.427697',
    longitude: '-82.663889',
    timeZoneId: 'America/New_York',
  },
  '34680': {
    zip: '34680',
    city: 'Elfers',
    state: 'FL',
    latitude: '28.210297',
    longitude: '-82.711649',
    timeZoneId: 'America/New_York',
  },
  '34681': {
    zip: '34681',
    city: 'Crystal Beach',
    state: 'FL',
    latitude: '28.087767',
    longitude: '-82.779052',
    timeZoneId: 'America/New_York',
  },
  '34682': {
    zip: '34682',
    city: 'Palm Harbor',
    state: 'FL',
    latitude: '28.077046',
    longitude: '-82.761422',
    timeZoneId: 'America/New_York',
  },
  '34683': {
    zip: '34683',
    city: 'Palm Harbor',
    state: 'FL',
    latitude: '28.077455',
    longitude: '-82.761109',
    timeZoneId: 'America/New_York',
  },
  '34684': {
    zip: '34684',
    city: 'Palm Harbor',
    state: 'FL',
    latitude: '28.077842',
    longitude: '-82.725926',
    timeZoneId: 'America/New_York',
  },
  '34685': {
    zip: '34685',
    city: 'Palm Harbor',
    state: 'FL',
    latitude: '28.104449',
    longitude: '-82.680302',
    timeZoneId: 'America/New_York',
  },
  '34688': {
    zip: '34688',
    city: 'Tarpon Springs',
    state: 'FL',
    latitude: '28.143822',
    longitude: '-82.68011',
    timeZoneId: 'America/New_York',
  },
  '34689': {
    zip: '34689',
    city: 'Tarpon Springs',
    state: 'FL',
    latitude: '28.143816',
    longitude: '-82.747935',
    timeZoneId: 'America/New_York',
  },
  '34690': {
    zip: '34690',
    city: 'Holiday',
    state: 'FL',
    latitude: '28.191092',
    longitude: '-82.728081',
    timeZoneId: 'America/New_York',
  },
  '34691': {
    zip: '34691',
    city: 'Holiday',
    state: 'FL',
    latitude: '28.192041',
    longitude: '-82.754164',
    timeZoneId: 'America/New_York',
  },
  '34692': {
    zip: '34692',
    city: 'Holiday',
    state: 'FL',
    latitude: '28.191135',
    longitude: '-82.728072',
    timeZoneId: 'America/New_York',
  },
  '34695': {
    zip: '34695',
    city: 'Safety Harbor',
    state: 'FL',
    latitude: '28.005845',
    longitude: '-82.695282',
    timeZoneId: 'America/New_York',
  },
  '34697': {
    zip: '34697',
    city: 'Dunedin',
    state: 'FL',
    latitude: '28.026546',
    longitude: '-82.764257',
    timeZoneId: 'America/New_York',
  },
  '34698': {
    zip: '34698',
    city: 'Dunedin',
    state: 'FL',
    latitude: '28.023417',
    longitude: '-82.767613',
    timeZoneId: 'America/New_York',
  },
  '34705': {
    zip: '34705',
    city: 'Astatula',
    state: 'FL',
    latitude: '28.705093',
    longitude: '-81.714841',
    timeZoneId: 'America/New_York',
  },
  '34711': {
    zip: '34711',
    city: 'Clermont',
    state: 'FL',
    latitude: '28.535867',
    longitude: '-81.763831',
    timeZoneId: 'America/New_York',
  },
  '34712': {
    zip: '34712',
    city: 'Clermont',
    state: 'FL',
    latitude: '28.553199',
    longitude: '-81.757548',
    timeZoneId: 'America/New_York',
  },
  '34713': {
    zip: '34713',
    city: 'Clermont',
    state: 'FL',
    latitude: '28.547308',
    longitude: '-81.770426',
    timeZoneId: 'America/New_York',
  },
  '34714': {
    zip: '34714',
    city: 'Clermont',
    state: 'FL',
    latitude: '28.440119',
    longitude: '-81.845959',
    timeZoneId: 'America/New_York',
  },
  '34715': {
    zip: '34715',
    city: 'Clermont',
    state: 'FL',
    latitude: '28.592359',
    longitude: '-81.799392',
    timeZoneId: 'America/New_York',
  },
  '34729': {
    zip: '34729',
    city: 'Ferndale',
    state: 'FL',
    latitude: '28.620539',
    longitude: '-81.7115',
    timeZoneId: 'America/New_York',
  },
  '34731': {
    zip: '34731',
    city: 'Fruitland Park',
    state: 'FL',
    latitude: '28.864889',
    longitude: '-81.898909',
    timeZoneId: 'America/New_York',
  },
  '34734': {
    zip: '34734',
    city: 'Gotha',
    state: 'FL',
    latitude: '28.536246',
    longitude: '-81.52522',
    timeZoneId: 'America/New_York',
  },
  '34736': {
    zip: '34736',
    city: 'Groveland',
    state: 'FL',
    latitude: '28.570758',
    longitude: '-81.862493',
    timeZoneId: 'America/New_York',
  },
  '34737': {
    zip: '34737',
    city: 'Howey in the Hills',
    state: 'FL',
    latitude: '28.704236',
    longitude: '-81.784964',
    timeZoneId: 'America/New_York',
  },
  '34739': {
    zip: '34739',
    city: 'Kenansville',
    state: 'FL',
    latitude: '27.858419',
    longitude: '-80.976211',
    timeZoneId: 'America/New_York',
  },
  '34740': {
    zip: '34740',
    city: 'Killarney',
    state: 'FL',
    latitude: '28.545029',
    longitude: '-81.648835',
    timeZoneId: 'America/New_York',
  },
  '34741': {
    zip: '34741',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.304764',
    longitude: '-81.423148',
    timeZoneId: 'America/New_York',
  },
  '34742': {
    zip: '34742',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.296304',
    longitude: '-81.352352',
    timeZoneId: 'America/New_York',
  },
  '34743': {
    zip: '34743',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.328203',
    longitude: '-81.354643',
    timeZoneId: 'America/New_York',
  },
  '34744': {
    zip: '34744',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.277758',
    longitude: '-81.350625',
    timeZoneId: 'America/New_York',
  },
  '34745': {
    zip: '34745',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.302729',
    longitude: '-81.409886',
    timeZoneId: 'America/New_York',
  },
  '34746': {
    zip: '34746',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.258683',
    longitude: '-81.459499',
    timeZoneId: 'America/New_York',
  },
  '34747': {
    zip: '34747',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.297785',
    longitude: '-81.613279',
    timeZoneId: 'America/New_York',
  },
  '34748': {
    zip: '34748',
    city: 'Leesburg',
    state: 'FL',
    latitude: '28.804183',
    longitude: '-81.885627',
    timeZoneId: 'America/New_York',
  },
  '34749': {
    zip: '34749',
    city: 'Leesburg',
    state: 'FL',
    latitude: '28.807861',
    longitude: '-81.875946',
    timeZoneId: 'America/New_York',
  },
  '34753': {
    zip: '34753',
    city: 'Mascotte',
    state: 'FL',
    latitude: '28.579955',
    longitude: '-81.892469',
    timeZoneId: 'America/New_York',
  },
  '34755': {
    zip: '34755',
    city: 'Minneola',
    state: 'FL',
    latitude: '28.577556',
    longitude: '-81.749268',
    timeZoneId: 'America/New_York',
  },
  '34756': {
    zip: '34756',
    city: 'Montverde',
    state: 'FL',
    latitude: '28.596545',
    longitude: '-81.680818',
    timeZoneId: 'America/New_York',
  },
  '34758': {
    zip: '34758',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.203472',
    longitude: '-81.500447',
    timeZoneId: 'America/New_York',
  },
  '34759': {
    zip: '34759',
    city: 'Kissimmee',
    state: 'FL',
    latitude: '28.142963',
    longitude: '-81.453023',
    timeZoneId: 'America/New_York',
  },
  '34760': {
    zip: '34760',
    city: 'Oakland',
    state: 'FL',
    latitude: '28.557035',
    longitude: '-81.63621',
    timeZoneId: 'America/New_York',
  },
  '34761': {
    zip: '34761',
    city: 'Ocoee',
    state: 'FL',
    latitude: '28.580595',
    longitude: '-81.539393',
    timeZoneId: 'America/New_York',
  },
  '34762': {
    zip: '34762',
    city: 'Okahumpka',
    state: 'FL',
    latitude: '28.753723',
    longitude: '-81.885779',
    timeZoneId: 'America/New_York',
  },
  '34769': {
    zip: '34769',
    city: 'Saint Cloud',
    state: 'FL',
    latitude: '28.248959',
    longitude: '-81.295928',
    timeZoneId: 'America/New_York',
  },
  '34770': {
    zip: '34770',
    city: 'Saint Cloud',
    state: 'FL',
    latitude: '28.164897',
    longitude: '-81.285225',
    timeZoneId: 'America/New_York',
  },
  '34771': {
    zip: '34771',
    city: 'Saint Cloud',
    state: 'FL',
    latitude: '28.287024',
    longitude: '-81.213434',
    timeZoneId: 'America/New_York',
  },
  '34772': {
    zip: '34772',
    city: 'Saint Cloud',
    state: 'FL',
    latitude: '28.125336',
    longitude: '-81.242167',
    timeZoneId: 'America/New_York',
  },
  '34773': {
    zip: '34773',
    city: 'Saint Cloud',
    state: 'FL',
    latitude: '28.060933',
    longitude: '-81.05253',
    timeZoneId: 'America/New_York',
  },
  '34777': {
    zip: '34777',
    city: 'Winter Garden',
    state: 'FL',
    latitude: '28.486449',
    longitude: '-81.60663',
    timeZoneId: 'America/New_York',
  },
  '34778': {
    zip: '34778',
    city: 'Winter Garden',
    state: 'FL',
    latitude: '28.497294',
    longitude: '-81.615976',
    timeZoneId: 'America/New_York',
  },
  '34785': {
    zip: '34785',
    city: 'Wildwood',
    state: 'FL',
    latitude: '28.860356',
    longitude: '-82.040896',
    timeZoneId: 'America/New_York',
  },
  '34786': {
    zip: '34786',
    city: 'Windermere',
    state: 'FL',
    latitude: '28.501774',
    longitude: '-81.530876',
    timeZoneId: 'America/New_York',
  },
  '34787': {
    zip: '34787',
    city: 'Winter Garden',
    state: 'FL',
    latitude: '28.532903',
    longitude: '-81.590667',
    timeZoneId: 'America/New_York',
  },
  '34788': {
    zip: '34788',
    city: 'Leesburg',
    state: 'FL',
    latitude: '28.853853',
    longitude: '-81.778191',
    timeZoneId: 'America/New_York',
  },
  '34789': {
    zip: '34789',
    city: 'Leesburg',
    state: 'FL',
    latitude: '28.809564',
    longitude: '-81.882467',
    timeZoneId: 'America/New_York',
  },
  '34797': {
    zip: '34797',
    city: 'Yalaha',
    state: 'FL',
    latitude: '28.742227',
    longitude: '-81.820758',
    timeZoneId: 'America/New_York',
  },
  '34945': {
    zip: '34945',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.42497',
    longitude: '-80.450133',
    timeZoneId: 'America/New_York',
  },
  '34946': {
    zip: '34946',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.482596',
    longitude: '-80.353492',
    timeZoneId: 'America/New_York',
  },
  '34947': {
    zip: '34947',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.450262',
    longitude: '-80.369969',
    timeZoneId: 'America/New_York',
  },
  '34948': {
    zip: '34948',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.447204',
    longitude: '-80.344071',
    timeZoneId: 'America/New_York',
  },
  '34949': {
    zip: '34949',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.475372',
    longitude: '-80.302774',
    timeZoneId: 'America/New_York',
  },
  '34950': {
    zip: '34950',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.442882',
    longitude: '-80.343377',
    timeZoneId: 'America/New_York',
  },
  '34951': {
    zip: '34951',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.532914',
    longitude: '-80.396835',
    timeZoneId: 'America/New_York',
  },
  '34952': {
    zip: '34952',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.29814',
    longitude: '-80.29674',
    timeZoneId: 'America/New_York',
  },
  '34953': {
    zip: '34953',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.256604',
    longitude: '-80.384626',
    timeZoneId: 'America/New_York',
  },
  '34954': {
    zip: '34954',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.451083',
    longitude: '-80.338885',
    timeZoneId: 'America/New_York',
  },
  '34956': {
    zip: '34956',
    city: 'Indiantown',
    state: 'FL',
    latitude: '27.031983',
    longitude: '-80.468131',
    timeZoneId: 'America/New_York',
  },
  '34957': {
    zip: '34957',
    city: 'Jensen Beach',
    state: 'FL',
    latitude: '27.23455',
    longitude: '-80.232374',
    timeZoneId: 'America/New_York',
  },
  '34958': {
    zip: '34958',
    city: 'Jensen Beach',
    state: 'FL',
    latitude: '27.19407',
    longitude: '-80.193203',
    timeZoneId: 'America/New_York',
  },
  '34972': {
    zip: '34972',
    city: 'Okeechobee',
    state: 'FL',
    latitude: '27.325224',
    longitude: '-80.849484',
    timeZoneId: 'America/New_York',
  },
  '34973': {
    zip: '34973',
    city: 'Okeechobee',
    state: 'FL',
    latitude: '27.244715',
    longitude: '-80.831008',
    timeZoneId: 'America/New_York',
  },
  '34974': {
    zip: '34974',
    city: 'Okeechobee',
    state: 'FL',
    latitude: '27.22862',
    longitude: '-80.807426',
    timeZoneId: 'America/New_York',
  },
  '34979': {
    zip: '34979',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.445034',
    longitude: '-80.339808',
    timeZoneId: 'America/New_York',
  },
  '34981': {
    zip: '34981',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.403812',
    longitude: '-80.361046',
    timeZoneId: 'America/New_York',
  },
  '34982': {
    zip: '34982',
    city: 'Fort Pierce',
    state: 'FL',
    latitude: '27.39139',
    longitude: '-80.332087',
    timeZoneId: 'America/New_York',
  },
  '34983': {
    zip: '34983',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.308284',
    longitude: '-80.348414',
    timeZoneId: 'America/New_York',
  },
  '34984': {
    zip: '34984',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.275367',
    longitude: '-80.347242',
    timeZoneId: 'America/New_York',
  },
  '34985': {
    zip: '34985',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.29509',
    longitude: '-80.349568',
    timeZoneId: 'America/New_York',
  },
  '34986': {
    zip: '34986',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.321528',
    longitude: '-80.418405',
    timeZoneId: 'America/New_York',
  },
  '34987': {
    zip: '34987',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.308771',
    longitude: '-80.453062',
    timeZoneId: 'America/New_York',
  },
  '34988': {
    zip: '34988',
    city: 'Port Saint Lucie',
    state: 'FL',
    latitude: '27.341002',
    longitude: '-80.519774',
    timeZoneId: 'America/New_York',
  },
  '34990': {
    zip: '34990',
    city: 'Palm City',
    state: 'FL',
    latitude: '27.166551',
    longitude: '-80.29247',
    timeZoneId: 'America/New_York',
  },
  '34991': {
    zip: '34991',
    city: 'Palm City',
    state: 'FL',
    latitude: '27.161314',
    longitude: '-80.268451',
    timeZoneId: 'America/New_York',
  },
  '34992': {
    zip: '34992',
    city: 'Port Salerno',
    state: 'FL',
    latitude: '27.142814',
    longitude: '-80.209168',
    timeZoneId: 'America/New_York',
  },
  '34994': {
    zip: '34994',
    city: 'Stuart',
    state: 'FL',
    latitude: '27.197528',
    longitude: '-80.253111',
    timeZoneId: 'America/New_York',
  },
  '34995': {
    zip: '34995',
    city: 'Stuart',
    state: 'FL',
    latitude: '27.159963',
    longitude: '-80.235098',
    timeZoneId: 'America/New_York',
  },
  '34996': {
    zip: '34996',
    city: 'Stuart',
    state: 'FL',
    latitude: '27.194129',
    longitude: '-80.206393',
    timeZoneId: 'America/New_York',
  },
  '34997': {
    zip: '34997',
    city: 'Stuart',
    state: 'FL',
    latitude: '27.138891',
    longitude: '-80.214592',
    timeZoneId: 'America/New_York',
  },
  '35004': {
    zip: '35004',
    city: 'Moody',
    state: 'AL',
    latitude: '33.59212',
    longitude: '-86.497541',
    timeZoneId: 'America/Chicago',
  },
  '35005': {
    zip: '35005',
    city: 'Adamsville',
    state: 'AL',
    latitude: '33.592136',
    longitude: '-86.961171',
    timeZoneId: 'America/Chicago',
  },
  '35006': {
    zip: '35006',
    city: 'Adger',
    state: 'AL',
    latitude: '33.44394',
    longitude: '-87.20292',
    timeZoneId: 'America/Chicago',
  },
  '35007': {
    zip: '35007',
    city: 'Alabaster',
    state: 'AL',
    latitude: '33.241465',
    longitude: '-86.821286',
    timeZoneId: 'America/Chicago',
  },
  '35010': {
    zip: '35010',
    city: 'Alexander City',
    state: 'AL',
    latitude: '32.91336',
    longitude: '-85.93836',
    timeZoneId: 'America/Chicago',
  },
  '35011': {
    zip: '35011',
    city: 'Alexander City',
    state: 'AL',
    latitude: '32.927018',
    longitude: '-85.937364',
    timeZoneId: 'America/Chicago',
  },
  '35013': {
    zip: '35013',
    city: 'Allgood',
    state: 'AL',
    latitude: '33.966342',
    longitude: '-86.38357',
    timeZoneId: 'America/Chicago',
  },
  '35014': {
    zip: '35014',
    city: 'Alpine',
    state: 'AL',
    latitude: '33.331742',
    longitude: '-86.186598',
    timeZoneId: 'America/Chicago',
  },
  '35015': {
    zip: '35015',
    city: 'Alton',
    state: 'AL',
    latitude: '33.579758',
    longitude: '-86.639044',
    timeZoneId: 'America/Chicago',
  },
  '35016': {
    zip: '35016',
    city: 'Arab',
    state: 'AL',
    latitude: '34.321343',
    longitude: '-86.497114',
    timeZoneId: 'America/Chicago',
  },
  '35019': {
    zip: '35019',
    city: 'Baileyton',
    state: 'AL',
    latitude: '34.26757',
    longitude: '-86.845224',
    timeZoneId: 'America/Chicago',
  },
  '35020': {
    zip: '35020',
    city: 'Bessemer',
    state: 'AL',
    latitude: '33.40098',
    longitude: '-86.94156',
    timeZoneId: 'America/Chicago',
  },
  '35021': {
    zip: '35021',
    city: 'Bessemer',
    state: 'AL',
    latitude: '33.434295',
    longitude: '-86.906053',
    timeZoneId: 'America/Chicago',
  },
  '35022': {
    zip: '35022',
    city: 'Bessemer',
    state: 'AL',
    latitude: '33.342582',
    longitude: '-86.962361',
    timeZoneId: 'America/Chicago',
  },
  '35023': {
    zip: '35023',
    city: 'Bessemer',
    state: 'AL',
    latitude: '33.4119',
    longitude: '-87.04614',
    timeZoneId: 'America/Chicago',
  },
  '35031': {
    zip: '35031',
    city: 'Blountsville',
    state: 'AL',
    latitude: '34.08006',
    longitude: '-86.594331',
    timeZoneId: 'America/Chicago',
  },
  '35032': {
    zip: '35032',
    city: 'Bon Air',
    state: 'AL',
    latitude: '33.259993',
    longitude: '-86.340295',
    timeZoneId: 'America/Chicago',
  },
  '35033': {
    zip: '35033',
    city: 'Bremen',
    state: 'AL',
    latitude: '33.953478',
    longitude: '-87.004753',
    timeZoneId: 'America/Chicago',
  },
  '35034': {
    zip: '35034',
    city: 'Brent',
    state: 'AL',
    latitude: '32.933665',
    longitude: '-87.171821',
    timeZoneId: 'America/Chicago',
  },
  '35035': {
    zip: '35035',
    city: 'Brierfield',
    state: 'AL',
    latitude: '33.069588',
    longitude: '-86.928859',
    timeZoneId: 'America/Chicago',
  },
  '35036': {
    zip: '35036',
    city: 'Brookside',
    state: 'AL',
    latitude: '33.633971',
    longitude: '-86.91792',
    timeZoneId: 'America/Chicago',
  },
  '35038': {
    zip: '35038',
    city: 'Burnwell',
    state: 'AL',
    latitude: '33.906385',
    longitude: '-87.316333',
    timeZoneId: 'America/Chicago',
  },
  '35040': {
    zip: '35040',
    city: 'Calera',
    state: 'AL',
    latitude: '33.151899',
    longitude: '-86.719204',
    timeZoneId: 'America/Chicago',
  },
  '35042': {
    zip: '35042',
    city: 'Centreville',
    state: 'AL',
    latitude: '32.953666',
    longitude: '-87.138868',
    timeZoneId: 'America/Chicago',
  },
  '35043': {
    zip: '35043',
    city: 'Chelsea',
    state: 'AL',
    latitude: '33.319654',
    longitude: '-86.646309',
    timeZoneId: 'America/Chicago',
  },
  '35044': {
    zip: '35044',
    city: 'Childersburg',
    state: 'AL',
    latitude: '33.276643',
    longitude: '-86.352253',
    timeZoneId: 'America/Chicago',
  },
  '35045': {
    zip: '35045',
    city: 'Clanton',
    state: 'AL',
    latitude: '32.839154',
    longitude: '-86.630913',
    timeZoneId: 'America/Chicago',
  },
  '35046': {
    zip: '35046',
    city: 'Clanton',
    state: 'AL',
    latitude: '32.839427',
    longitude: '-86.623504',
    timeZoneId: 'America/Chicago',
  },
  '35048': {
    zip: '35048',
    city: 'Clay',
    state: 'AL',
    latitude: '33.735659',
    longitude: '-86.570205',
    timeZoneId: 'America/Chicago',
  },
  '35049': {
    zip: '35049',
    city: 'Cleveland',
    state: 'AL',
    latitude: '33.969191',
    longitude: '-86.59837',
    timeZoneId: 'America/Chicago',
  },
  '35051': {
    zip: '35051',
    city: 'Columbiana',
    state: 'AL',
    latitude: '33.182346',
    longitude: '-86.604992',
    timeZoneId: 'America/Chicago',
  },
  '35052': {
    zip: '35052',
    city: 'Cook Springs',
    state: 'AL',
    latitude: '33.591075',
    longitude: '-86.391244',
    timeZoneId: 'America/Chicago',
  },
  '35053': {
    zip: '35053',
    city: 'Crane Hill',
    state: 'AL',
    latitude: '34.071568',
    longitude: '-87.0597',
    timeZoneId: 'America/Chicago',
  },
  '35054': {
    zip: '35054',
    city: 'Cropwell',
    state: 'AL',
    latitude: '33.506959',
    longitude: '-86.294543',
    timeZoneId: 'America/Chicago',
  },
  '35055': {
    zip: '35055',
    city: 'Cullman',
    state: 'AL',
    latitude: '34.174036',
    longitude: '-86.843648',
    timeZoneId: 'America/Chicago',
  },
  '35056': {
    zip: '35056',
    city: 'Cullman',
    state: 'AL',
    latitude: '34.180143',
    longitude: '-86.851112',
    timeZoneId: 'America/Chicago',
  },
  '35057': {
    zip: '35057',
    city: 'Cullman',
    state: 'AL',
    latitude: '34.213486',
    longitude: '-86.865502',
    timeZoneId: 'America/Chicago',
  },
  '35058': {
    zip: '35058',
    city: 'Cullman',
    state: 'AL',
    latitude: '34.174458',
    longitude: '-86.843089',
    timeZoneId: 'America/Chicago',
  },
  '35060': {
    zip: '35060',
    city: 'Docena',
    state: 'AL',
    latitude: '33.559893',
    longitude: '-86.929588',
    timeZoneId: 'America/Chicago',
  },
  '35061': {
    zip: '35061',
    city: 'Dolomite',
    state: 'AL',
    latitude: '33.463579',
    longitude: '-86.958874',
    timeZoneId: 'America/Chicago',
  },
  '35062': {
    zip: '35062',
    city: 'Dora',
    state: 'AL',
    latitude: '33.730666',
    longitude: '-87.005126',
    timeZoneId: 'America/Chicago',
  },
  '35063': {
    zip: '35063',
    city: 'Empire',
    state: 'AL',
    latitude: '33.802419',
    longitude: '-86.941597',
    timeZoneId: 'America/Chicago',
  },
  '35064': {
    zip: '35064',
    city: 'Fairfield',
    state: 'AL',
    latitude: '33.478682',
    longitude: '-86.921329',
    timeZoneId: 'America/Chicago',
  },
  '35068': {
    zip: '35068',
    city: 'Fultondale',
    state: 'AL',
    latitude: '33.612',
    longitude: '-86.82654',
    timeZoneId: 'America/Chicago',
  },
  '35070': {
    zip: '35070',
    city: 'Garden City',
    state: 'AL',
    latitude: '34.015138',
    longitude: '-86.748672',
    timeZoneId: 'America/Chicago',
  },
  '35071': {
    zip: '35071',
    city: 'Gardendale',
    state: 'AL',
    latitude: '33.669561',
    longitude: '-86.827977',
    timeZoneId: 'America/Chicago',
  },
  '35072': {
    zip: '35072',
    city: 'Goodwater',
    state: 'AL',
    latitude: '33.063015',
    longitude: '-86.041103',
    timeZoneId: 'America/Chicago',
  },
  '35073': {
    zip: '35073',
    city: 'Graysville',
    state: 'AL',
    latitude: '33.621087',
    longitude: '-86.958247',
    timeZoneId: 'America/Chicago',
  },
  '35074': {
    zip: '35074',
    city: 'Green Pond',
    state: 'AL',
    latitude: '33.223262',
    longitude: '-87.124392',
    timeZoneId: 'America/Chicago',
  },
  '35077': {
    zip: '35077',
    city: 'Hanceville',
    state: 'AL',
    latitude: '34.064328',
    longitude: '-86.758497',
    timeZoneId: 'America/Chicago',
  },
  '35078': {
    zip: '35078',
    city: 'Harpersville',
    state: 'AL',
    latitude: '33.337983',
    longitude: '-86.455431',
    timeZoneId: 'America/Chicago',
  },
  '35079': {
    zip: '35079',
    city: 'Hayden',
    state: 'AL',
    latitude: '33.884716',
    longitude: '-86.806982',
    timeZoneId: 'America/Chicago',
  },
  '35080': {
    zip: '35080',
    city: 'Helena',
    state: 'AL',
    latitude: '33.305287',
    longitude: '-86.818296',
    timeZoneId: 'America/Chicago',
  },
  '35082': {
    zip: '35082',
    city: 'Hollins',
    state: 'AL',
    latitude: '33.115279',
    longitude: '-86.10832',
    timeZoneId: 'America/Chicago',
  },
  '35083': {
    zip: '35083',
    city: 'Holly Pond',
    state: 'AL',
    latitude: '34.181612',
    longitude: '-86.618121',
    timeZoneId: 'America/Chicago',
  },
  '35085': {
    zip: '35085',
    city: 'Jemison',
    state: 'AL',
    latitude: '32.990905',
    longitude: '-86.678329',
    timeZoneId: 'America/Chicago',
  },
  '35087': {
    zip: '35087',
    city: 'Joppa',
    state: 'AL',
    latitude: '34.274397',
    longitude: '-86.550323',
    timeZoneId: 'America/Chicago',
  },
  '35089': {
    zip: '35089',
    city: 'Kellyton',
    state: 'AL',
    latitude: '32.968451',
    longitude: '-85.961235',
    timeZoneId: 'America/Chicago',
  },
  '35091': {
    zip: '35091',
    city: 'Kimberly',
    state: 'AL',
    latitude: '33.767665',
    longitude: '-86.807616',
    timeZoneId: 'America/Chicago',
  },
  '35094': {
    zip: '35094',
    city: 'Leeds',
    state: 'AL',
    latitude: '33.540397',
    longitude: '-86.55789',
    timeZoneId: 'America/Chicago',
  },
  '35096': {
    zip: '35096',
    city: 'Lincoln',
    state: 'AL',
    latitude: '33.593989',
    longitude: '-86.132344',
    timeZoneId: 'America/Chicago',
  },
  '35097': {
    zip: '35097',
    city: 'Locust Fork',
    state: 'AL',
    latitude: '33.903495',
    longitude: '-86.620468',
    timeZoneId: 'America/Chicago',
  },
  '35098': {
    zip: '35098',
    city: 'Logan',
    state: 'AL',
    latitude: '34.184538',
    longitude: '-87.043005',
    timeZoneId: 'America/Chicago',
  },
  '35111': {
    zip: '35111',
    city: 'Mc Calla',
    state: 'AL',
    latitude: '33.268815',
    longitude: '-87.144228',
    timeZoneId: 'America/Chicago',
  },
  '35112': {
    zip: '35112',
    city: 'Margaret',
    state: 'AL',
    latitude: '33.688062',
    longitude: '-86.475825',
    timeZoneId: 'America/Chicago',
  },
  '35114': {
    zip: '35114',
    city: 'Alabaster',
    state: 'AL',
    latitude: '33.239695',
    longitude: '-86.861764',
    timeZoneId: 'America/Chicago',
  },
  '35115': {
    zip: '35115',
    city: 'Montevallo',
    state: 'AL',
    latitude: '33.13572',
    longitude: '-86.84892',
    timeZoneId: 'America/Chicago',
  },
  '35116': {
    zip: '35116',
    city: 'Morris',
    state: 'AL',
    latitude: '33.74512',
    longitude: '-86.77134',
    timeZoneId: 'America/Chicago',
  },
  '35117': {
    zip: '35117',
    city: 'Mount Olive',
    state: 'AL',
    latitude: '33.668747',
    longitude: '-86.868208',
    timeZoneId: 'America/Chicago',
  },
  '35118': {
    zip: '35118',
    city: 'Mulga',
    state: 'AL',
    latitude: '33.53808',
    longitude: '-87.03924',
    timeZoneId: 'America/Chicago',
  },
  '35119': {
    zip: '35119',
    city: 'New Castle',
    state: 'AL',
    latitude: '33.648426',
    longitude: '-86.773022',
    timeZoneId: 'America/Chicago',
  },
  '35120': {
    zip: '35120',
    city: 'Odenville',
    state: 'AL',
    latitude: '33.668856',
    longitude: '-86.429644',
    timeZoneId: 'America/Chicago',
  },
  '35121': {
    zip: '35121',
    city: 'Oneonta',
    state: 'AL',
    latitude: '33.948154',
    longitude: '-86.470543',
    timeZoneId: 'America/Chicago',
  },
  '35123': {
    zip: '35123',
    city: 'Palmerdale',
    state: 'AL',
    latitude: '33.747733',
    longitude: '-86.64506',
    timeZoneId: 'America/Chicago',
  },
  '35124': {
    zip: '35124',
    city: 'Pelham',
    state: 'AL',
    latitude: '33.30186',
    longitude: '-86.7918',
    timeZoneId: 'America/Chicago',
  },
  '35125': {
    zip: '35125',
    city: 'Pell City',
    state: 'AL',
    latitude: '33.573051',
    longitude: '-86.276572',
    timeZoneId: 'America/Chicago',
  },
  '35126': {
    zip: '35126',
    city: 'Pinson',
    state: 'AL',
    latitude: '33.7263',
    longitude: '-86.64396',
    timeZoneId: 'America/Chicago',
  },
  '35127': {
    zip: '35127',
    city: 'Pleasant Grove',
    state: 'AL',
    latitude: '33.489447',
    longitude: '-86.973348',
    timeZoneId: 'America/Chicago',
  },
  '35128': {
    zip: '35128',
    city: 'Pell City',
    state: 'AL',
    latitude: '33.586316',
    longitude: '-86.285828',
    timeZoneId: 'America/Chicago',
  },
  '35130': {
    zip: '35130',
    city: 'Quinton',
    state: 'AL',
    latitude: '33.570865',
    longitude: '-87.165507',
    timeZoneId: 'America/Chicago',
  },
  '35131': {
    zip: '35131',
    city: 'Ragland',
    state: 'AL',
    latitude: '33.758996',
    longitude: '-86.205444',
    timeZoneId: 'America/Chicago',
  },
  '35133': {
    zip: '35133',
    city: 'Remlap',
    state: 'AL',
    latitude: '33.821151',
    longitude: '-86.623895',
    timeZoneId: 'America/Chicago',
  },
  '35135': {
    zip: '35135',
    city: 'Riverside',
    state: 'AL',
    latitude: '33.60154',
    longitude: '-86.196001',
    timeZoneId: 'America/Chicago',
  },
  '35136': {
    zip: '35136',
    city: 'Rockford',
    state: 'AL',
    latitude: '32.863614',
    longitude: '-86.271333',
    timeZoneId: 'America/Chicago',
  },
  '35137': {
    zip: '35137',
    city: 'Saginaw',
    state: 'AL',
    latitude: '33.215942',
    longitude: '-86.791875',
    timeZoneId: 'America/Chicago',
  },
  '35139': {
    zip: '35139',
    city: 'Sayre',
    state: 'AL',
    latitude: '33.714591',
    longitude: '-86.97521',
    timeZoneId: 'America/Chicago',
  },
  '35142': {
    zip: '35142',
    city: 'Shannon',
    state: 'AL',
    latitude: '33.403514',
    longitude: '-86.874303',
    timeZoneId: 'America/Chicago',
  },
  '35143': {
    zip: '35143',
    city: 'Shelby',
    state: 'AL',
    latitude: '33.099667',
    longitude: '-86.50197',
    timeZoneId: 'America/Chicago',
  },
  '35144': {
    zip: '35144',
    city: 'Siluria',
    state: 'AL',
    latitude: '33.2',
    longitude: '-86.783333',
    timeZoneId: 'America/Chicago',
  },
  '35146': {
    zip: '35146',
    city: 'Springville',
    state: 'AL',
    latitude: '33.7773',
    longitude: '-86.45165',
    timeZoneId: 'America/Chicago',
  },
  '35147': {
    zip: '35147',
    city: 'Sterrett',
    state: 'AL',
    latitude: '33.425188',
    longitude: '-86.50669',
    timeZoneId: 'America/Chicago',
  },
  '35148': {
    zip: '35148',
    city: 'Sumiton',
    state: 'AL',
    latitude: '33.753152',
    longitude: '-87.05073',
    timeZoneId: 'America/Chicago',
  },
  '35149': {
    zip: '35149',
    city: 'Sycamore',
    state: 'AL',
    latitude: '33.250962',
    longitude: '-86.203027',
    timeZoneId: 'America/Chicago',
  },
  '35150': {
    zip: '35150',
    city: 'Sylacauga',
    state: 'AL',
    latitude: '33.175189',
    longitude: '-86.255713',
    timeZoneId: 'America/Chicago',
  },
  '35151': {
    zip: '35151',
    city: 'Sylacauga',
    state: 'AL',
    latitude: '33.176529',
    longitude: '-86.262153',
    timeZoneId: 'America/Chicago',
  },
  '35160': {
    zip: '35160',
    city: 'Talladega',
    state: 'AL',
    latitude: '33.439294',
    longitude: '-86.102326',
    timeZoneId: 'America/Chicago',
  },
  '35161': {
    zip: '35161',
    city: 'Talladega',
    state: 'AL',
    latitude: '33.437647',
    longitude: '-86.103133',
    timeZoneId: 'America/Chicago',
  },
  '35171': {
    zip: '35171',
    city: 'Thorsby',
    state: 'AL',
    latitude: '32.923746',
    longitude: '-86.770973',
    timeZoneId: 'America/Chicago',
  },
  '35172': {
    zip: '35172',
    city: 'Trafford',
    state: 'AL',
    latitude: '33.813893',
    longitude: '-86.747615',
    timeZoneId: 'America/Chicago',
  },
  '35173': {
    zip: '35173',
    city: 'Trussville',
    state: 'AL',
    latitude: '33.64248',
    longitude: '-86.58402',
    timeZoneId: 'America/Chicago',
  },
  '35175': {
    zip: '35175',
    city: 'Union Grove',
    state: 'AL',
    latitude: '34.420133',
    longitude: '-86.465895',
    timeZoneId: 'America/Chicago',
  },
  '35176': {
    zip: '35176',
    city: 'Vandiver',
    state: 'AL',
    latitude: '33.483959',
    longitude: '-86.499823',
    timeZoneId: 'America/Chicago',
  },
  '35178': {
    zip: '35178',
    city: 'Vincent',
    state: 'AL',
    latitude: '33.398446',
    longitude: '-86.393123',
    timeZoneId: 'America/Chicago',
  },
  '35179': {
    zip: '35179',
    city: 'Vinemont',
    state: 'AL',
    latitude: '34.264921',
    longitude: '-87.009412',
    timeZoneId: 'America/Chicago',
  },
  '35180': {
    zip: '35180',
    city: 'Warrior',
    state: 'AL',
    latitude: '33.801525',
    longitude: '-86.836468',
    timeZoneId: 'America/Chicago',
  },
  '35181': {
    zip: '35181',
    city: 'Watson',
    state: 'AL',
    latitude: '33.624644',
    longitude: '-86.89053',
    timeZoneId: 'America/Chicago',
  },
  '35182': {
    zip: '35182',
    city: 'Wattsville',
    state: 'AL',
    latitude: '33.660556',
    longitude: '-86.263333',
    timeZoneId: 'America/Chicago',
  },
  '35183': {
    zip: '35183',
    city: 'Weogufka',
    state: 'AL',
    latitude: '32.967826',
    longitude: '-86.369735',
    timeZoneId: 'America/Chicago',
  },
  '35184': {
    zip: '35184',
    city: 'West Blocton',
    state: 'AL',
    latitude: '33.205643',
    longitude: '-87.071388',
    timeZoneId: 'America/Chicago',
  },
  '35185': {
    zip: '35185',
    city: 'Westover',
    state: 'AL',
    latitude: '33.344597',
    longitude: '-86.541432',
    timeZoneId: 'America/Chicago',
  },
  '35186': {
    zip: '35186',
    city: 'Wilsonville',
    state: 'AL',
    latitude: '33.239586',
    longitude: '-86.517708',
    timeZoneId: 'America/Chicago',
  },
  '35187': {
    zip: '35187',
    city: 'Wilton',
    state: 'AL',
    latitude: '33.073633',
    longitude: '-86.875231',
    timeZoneId: 'America/Chicago',
  },
  '35188': {
    zip: '35188',
    city: 'Woodstock',
    state: 'AL',
    latitude: '33.168372',
    longitude: '-87.170225',
    timeZoneId: 'America/Chicago',
  },
  '35201': {
    zip: '35201',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.518556',
    longitude: '-86.808416',
    timeZoneId: 'America/Chicago',
  },
  '35202': {
    zip: '35202',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.516349',
    longitude: '-86.80581',
    timeZoneId: 'America/Chicago',
  },
  '35203': {
    zip: '35203',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.515462',
    longitude: '-86.821045',
    timeZoneId: 'America/Chicago',
  },
  '35204': {
    zip: '35204',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.520297',
    longitude: '-86.838364',
    timeZoneId: 'America/Chicago',
  },
  '35205': {
    zip: '35205',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.496708',
    longitude: '-86.804796',
    timeZoneId: 'America/Chicago',
  },
  '35206': {
    zip: '35206',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.567997',
    longitude: '-86.720742',
    timeZoneId: 'America/Chicago',
  },
  '35207': {
    zip: '35207',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.560963',
    longitude: '-86.818624',
    timeZoneId: 'America/Chicago',
  },
  '35208': {
    zip: '35208',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.493638',
    longitude: '-86.883796',
    timeZoneId: 'America/Chicago',
  },
  '35209': {
    zip: '35209',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.4602',
    longitude: '-86.8092',
    timeZoneId: 'America/Chicago',
  },
  '35210': {
    zip: '35210',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.54072',
    longitude: '-86.68518',
    timeZoneId: 'America/Chicago',
  },
  '35211': {
    zip: '35211',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.473968',
    longitude: '-86.862639',
    timeZoneId: 'America/Chicago',
  },
  '35212': {
    zip: '35212',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.543843',
    longitude: '-86.747944',
    timeZoneId: 'America/Chicago',
  },
  '35213': {
    zip: '35213',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.504565',
    longitude: '-86.74715',
    timeZoneId: 'America/Chicago',
  },
  '35214': {
    zip: '35214',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.57336',
    longitude: '-86.89326',
    timeZoneId: 'America/Chicago',
  },
  '35215': {
    zip: '35215',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.639053',
    longitude: '-86.691875',
    timeZoneId: 'America/Chicago',
  },
  '35216': {
    zip: '35216',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.421551',
    longitude: '-86.794582',
    timeZoneId: 'America/Chicago',
  },
  '35217': {
    zip: '35217',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.6087',
    longitude: '-86.75262',
    timeZoneId: 'America/Chicago',
  },
  '35218': {
    zip: '35218',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.504425',
    longitude: '-86.894499',
    timeZoneId: 'America/Chicago',
  },
  '35219': {
    zip: '35219',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.473661',
    longitude: '-86.825878',
    timeZoneId: 'America/Chicago',
  },
  '35220': {
    zip: '35220',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.441667',
    longitude: '-86.905801',
    timeZoneId: 'America/Chicago',
  },
  '35221': {
    zip: '35221',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.454994',
    longitude: '-86.895854',
    timeZoneId: 'America/Chicago',
  },
  '35222': {
    zip: '35222',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.525173',
    longitude: '-86.771265',
    timeZoneId: 'America/Chicago',
  },
  '35223': {
    zip: '35223',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.489469',
    longitude: '-86.741202',
    timeZoneId: 'America/Chicago',
  },
  '35224': {
    zip: '35224',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.521022',
    longitude: '-86.93471',
    timeZoneId: 'America/Chicago',
  },
  '35226': {
    zip: '35226',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.405053',
    longitude: '-86.82832',
    timeZoneId: 'America/Chicago',
  },
  '35228': {
    zip: '35228',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.50028',
    longitude: '-86.91762',
    timeZoneId: 'America/Chicago',
  },
  '35229': {
    zip: '35229',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.464351',
    longitude: '-86.795405',
    timeZoneId: 'America/Chicago',
  },
  '35231': {
    zip: '35231',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.565231',
    longitude: '-86.892763',
    timeZoneId: 'America/Chicago',
  },
  '35232': {
    zip: '35232',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.535892',
    longitude: '-86.758358',
    timeZoneId: 'America/Chicago',
  },
  '35233': {
    zip: '35233',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.509765',
    longitude: '-86.802918',
    timeZoneId: 'America/Chicago',
  },
  '35234': {
    zip: '35234',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.53596',
    longitude: '-86.802701',
    timeZoneId: 'America/Chicago',
  },
  '35235': {
    zip: '35235',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.605039',
    longitude: '-86.656101',
    timeZoneId: 'America/Chicago',
  },
  '35236': {
    zip: '35236',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.356611',
    longitude: '-86.817395',
    timeZoneId: 'America/Chicago',
  },
  '35237': {
    zip: '35237',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.519891',
    longitude: '-86.807747',
    timeZoneId: 'America/Chicago',
  },
  '35238': {
    zip: '35238',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.518088',
    longitude: '-86.802297',
    timeZoneId: 'America/Chicago',
  },
  '35242': {
    zip: '35242',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.403565',
    longitude: '-86.711683',
    timeZoneId: 'America/Chicago',
  },
  '35243': {
    zip: '35243',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.42942',
    longitude: '-86.7252',
    timeZoneId: 'America/Chicago',
  },
  '35244': {
    zip: '35244',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.36171',
    longitude: '-86.788783',
    timeZoneId: 'America/Chicago',
  },
  '35246': {
    zip: '35246',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.519522',
    longitude: '-86.80188',
    timeZoneId: 'America/Chicago',
  },
  '35249': {
    zip: '35249',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.519034',
    longitude: '-86.803591',
    timeZoneId: 'America/Chicago',
  },
  '35253': {
    zip: '35253',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.486215',
    longitude: '-86.740394',
    timeZoneId: 'America/Chicago',
  },
  '35254': {
    zip: '35254',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.513945',
    longitude: '-86.853654',
    timeZoneId: 'America/Chicago',
  },
  '35255': {
    zip: '35255',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.493514',
    longitude: '-86.810296',
    timeZoneId: 'America/Chicago',
  },
  '35259': {
    zip: '35259',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.520556',
    longitude: '-86.8025',
    timeZoneId: 'America/Chicago',
  },
  '35260': {
    zip: '35260',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.399765',
    longitude: '-86.838955',
    timeZoneId: 'America/Chicago',
  },
  '35261': {
    zip: '35261',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.573309',
    longitude: '-86.715321',
    timeZoneId: 'America/Chicago',
  },
  '35266': {
    zip: '35266',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.446847',
    longitude: '-86.790162',
    timeZoneId: 'America/Chicago',
  },
  '35270': {
    zip: '35270',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.510761',
    longitude: '-86.795905',
    timeZoneId: 'America/Chicago',
  },
  '35282': {
    zip: '35282',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.49562',
    longitude: '-86.794338',
    timeZoneId: 'America/Chicago',
  },
  '35283': {
    zip: '35283',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.517488',
    longitude: '-86.809476',
    timeZoneId: 'America/Chicago',
  },
  '35285': {
    zip: '35285',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.541978',
    longitude: '-86.803608',
    timeZoneId: 'America/Chicago',
  },
  '35287': {
    zip: '35287',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.513903',
    longitude: '-86.807366',
    timeZoneId: 'America/Chicago',
  },
  '35288': {
    zip: '35288',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.463112',
    longitude: '-86.769414',
    timeZoneId: 'America/Chicago',
  },
  '35290': {
    zip: '35290',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.550093',
    longitude: '-86.74132',
    timeZoneId: 'America/Chicago',
  },
  '35291': {
    zip: '35291',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.513087',
    longitude: '-86.806429',
    timeZoneId: 'America/Chicago',
  },
  '35292': {
    zip: '35292',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.5125',
    longitude: '-86.805024',
    timeZoneId: 'America/Chicago',
  },
  '35293': {
    zip: '35293',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.463859',
    longitude: '-86.823031',
    timeZoneId: 'America/Chicago',
  },
  '35294': {
    zip: '35294',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.510109',
    longitude: '-86.807875',
    timeZoneId: 'America/Chicago',
  },
  '35295': {
    zip: '35295',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.511347',
    longitude: '-86.804838',
    timeZoneId: 'America/Chicago',
  },
  '35296': {
    zip: '35296',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.414914',
    longitude: '-86.809475',
    timeZoneId: 'America/Chicago',
  },
  '35297': {
    zip: '35297',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.493207',
    longitude: '-86.78175',
    timeZoneId: 'America/Chicago',
  },
  '35298': {
    zip: '35298',
    city: 'Birmingham',
    state: 'AL',
    latitude: '33.499493',
    longitude: '-86.800795',
    timeZoneId: 'America/Chicago',
  },
  '35401': {
    zip: '35401',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.191809',
    longitude: '-87.564581',
    timeZoneId: 'America/Chicago',
  },
  '35402': {
    zip: '35402',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.148495',
    longitude: '-87.609658',
    timeZoneId: 'America/Chicago',
  },
  '35403': {
    zip: '35403',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.204105',
    longitude: '-87.523651',
    timeZoneId: 'America/Chicago',
  },
  '35404': {
    zip: '35404',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.212241',
    longitude: '-87.492537',
    timeZoneId: 'America/Chicago',
  },
  '35405': {
    zip: '35405',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.157819',
    longitude: '-87.51716',
    timeZoneId: 'America/Chicago',
  },
  '35406': {
    zip: '35406',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.251917',
    longitude: '-87.530735',
    timeZoneId: 'America/Chicago',
  },
  '35407': {
    zip: '35407',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.166477',
    longitude: '-87.522249',
    timeZoneId: 'America/Chicago',
  },
  '35440': {
    zip: '35440',
    city: 'Abernant',
    state: 'AL',
    latitude: '33.292555',
    longitude: '-87.197891',
    timeZoneId: 'America/Chicago',
  },
  '35441': {
    zip: '35441',
    city: 'Akron',
    state: 'AL',
    latitude: '32.87448',
    longitude: '-87.69678',
    timeZoneId: 'America/Chicago',
  },
  '35442': {
    zip: '35442',
    city: 'Aliceville',
    state: 'AL',
    latitude: '33.1242',
    longitude: '-88.15926',
    timeZoneId: 'America/Chicago',
  },
  '35443': {
    zip: '35443',
    city: 'Boligee',
    state: 'AL',
    latitude: '32.763781',
    longitude: '-88.025971',
    timeZoneId: 'America/Chicago',
  },
  '35444': {
    zip: '35444',
    city: 'Brookwood',
    state: 'AL',
    latitude: '33.338834',
    longitude: '-87.122617',
    timeZoneId: 'America/Chicago',
  },
  '35446': {
    zip: '35446',
    city: 'Buhl',
    state: 'AL',
    latitude: '33.2721',
    longitude: '-87.73416',
    timeZoneId: 'America/Chicago',
  },
  '35447': {
    zip: '35447',
    city: 'Carrollton',
    state: 'AL',
    latitude: '33.22437',
    longitude: '-88.07393',
    timeZoneId: 'America/Chicago',
  },
  '35448': {
    zip: '35448',
    city: 'Clinton',
    state: 'AL',
    latitude: '32.915713',
    longitude: '-87.991932',
    timeZoneId: 'America/Chicago',
  },
  '35449': {
    zip: '35449',
    city: 'Coaling',
    state: 'AL',
    latitude: '33.157283',
    longitude: '-87.338672',
    timeZoneId: 'America/Chicago',
  },
  '35452': {
    zip: '35452',
    city: 'Coker',
    state: 'AL',
    latitude: '33.286474',
    longitude: '-87.649998',
    timeZoneId: 'America/Chicago',
  },
  '35453': {
    zip: '35453',
    city: 'Cottondale',
    state: 'AL',
    latitude: '33.200553',
    longitude: '-87.401063',
    timeZoneId: 'America/Chicago',
  },
  '35456': {
    zip: '35456',
    city: 'Duncanville',
    state: 'AL',
    latitude: '33.094162',
    longitude: '-87.445692',
    timeZoneId: 'America/Chicago',
  },
  '35457': {
    zip: '35457',
    city: 'Echola',
    state: 'AL',
    latitude: '33.322469',
    longitude: '-87.809615',
    timeZoneId: 'America/Chicago',
  },
  '35458': {
    zip: '35458',
    city: 'Elrod',
    state: 'AL',
    latitude: '33.366419',
    longitude: '-87.800829',
    timeZoneId: 'America/Chicago',
  },
  '35459': {
    zip: '35459',
    city: 'Emelle',
    state: 'AL',
    latitude: '32.76172',
    longitude: '-88.309687',
    timeZoneId: 'America/Chicago',
  },
  '35460': {
    zip: '35460',
    city: 'Epes',
    state: 'AL',
    latitude: '32.711667',
    longitude: '-88.238889',
    timeZoneId: 'America/Chicago',
  },
  '35461': {
    zip: '35461',
    city: 'Ethelsville',
    state: 'AL',
    latitude: '33.380586',
    longitude: '-88.218499',
    timeZoneId: 'America/Chicago',
  },
  '35462': {
    zip: '35462',
    city: 'Eutaw',
    state: 'AL',
    latitude: '32.837265',
    longitude: '-87.890002',
    timeZoneId: 'America/Chicago',
  },
  '35463': {
    zip: '35463',
    city: 'Fosters',
    state: 'AL',
    latitude: '33.124953',
    longitude: '-87.726199',
    timeZoneId: 'America/Chicago',
  },
  '35464': {
    zip: '35464',
    city: 'Gainesville',
    state: 'AL',
    latitude: '32.819013',
    longitude: '-88.154657',
    timeZoneId: 'America/Chicago',
  },
  '35466': {
    zip: '35466',
    city: 'Gordo',
    state: 'AL',
    latitude: '33.397012',
    longitude: '-87.891649',
    timeZoneId: 'America/Chicago',
  },
  '35468': {
    zip: '35468',
    city: 'Kellerman',
    state: 'AL',
    latitude: '33.338804',
    longitude: '-87.302401',
    timeZoneId: 'America/Chicago',
  },
  '35469': {
    zip: '35469',
    city: 'Knoxville',
    state: 'AL',
    latitude: '32.973197',
    longitude: '-87.805293',
    timeZoneId: 'America/Chicago',
  },
  '35470': {
    zip: '35470',
    city: 'Livingston',
    state: 'AL',
    latitude: '32.60256',
    longitude: '-88.16418',
    timeZoneId: 'America/Chicago',
  },
  '35471': {
    zip: '35471',
    city: 'Mc Shan',
    state: 'AL',
    latitude: '33.381037',
    longitude: '-88.14272',
    timeZoneId: 'America/Chicago',
  },
  '35473': {
    zip: '35473',
    city: 'Northport',
    state: 'AL',
    latitude: '33.228904',
    longitude: '-87.577424',
    timeZoneId: 'America/Chicago',
  },
  '35474': {
    zip: '35474',
    city: 'Moundville',
    state: 'AL',
    latitude: '33.007906',
    longitude: '-87.60075',
    timeZoneId: 'America/Chicago',
  },
  '35475': {
    zip: '35475',
    city: 'Northport',
    state: 'AL',
    latitude: '33.24539',
    longitude: '-87.594372',
    timeZoneId: 'America/Chicago',
  },
  '35476': {
    zip: '35476',
    city: 'Northport',
    state: 'AL',
    latitude: '33.255087',
    longitude: '-87.586136',
    timeZoneId: 'America/Chicago',
  },
  '35477': {
    zip: '35477',
    city: 'Panola',
    state: 'AL',
    latitude: '32.952883',
    longitude: '-88.270684',
    timeZoneId: 'America/Chicago',
  },
  '35478': {
    zip: '35478',
    city: 'Peterson',
    state: 'AL',
    latitude: '33.234302',
    longitude: '-87.423475',
    timeZoneId: 'America/Chicago',
  },
  '35480': {
    zip: '35480',
    city: 'Ralph',
    state: 'AL',
    latitude: '33.054468',
    longitude: '-87.770735',
    timeZoneId: 'America/Chicago',
  },
  '35481': {
    zip: '35481',
    city: 'Reform',
    state: 'AL',
    latitude: '33.385953',
    longitude: '-88.01406',
    timeZoneId: 'America/Chicago',
  },
  '35482': {
    zip: '35482',
    city: 'Samantha',
    state: 'AL',
    latitude: '33.456558',
    longitude: '-87.670475',
    timeZoneId: 'America/Chicago',
  },
  '35486': {
    zip: '35486',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.206738',
    longitude: '-87.539186',
    timeZoneId: 'America/Chicago',
  },
  '35487': {
    zip: '35487',
    city: 'Tuscaloosa',
    state: 'AL',
    latitude: '33.215665',
    longitude: '-87.53919',
    timeZoneId: 'America/Chicago',
  },
  '35490': {
    zip: '35490',
    city: 'Vance',
    state: 'AL',
    latitude: '33.290778',
    longitude: '-87.15766',
    timeZoneId: 'America/Chicago',
  },
  '35491': {
    zip: '35491',
    city: 'West Greene',
    state: 'AL',
    latitude: '32.923389',
    longitude: '-88.088523',
    timeZoneId: 'America/Chicago',
  },
  '35501': {
    zip: '35501',
    city: 'Jasper',
    state: 'AL',
    latitude: '33.842149',
    longitude: '-87.274833',
    timeZoneId: 'America/Chicago',
  },
  '35502': {
    zip: '35502',
    city: 'Jasper',
    state: 'AL',
    latitude: '33.828491',
    longitude: '-87.277348',
    timeZoneId: 'America/Chicago',
  },
  '35503': {
    zip: '35503',
    city: 'Jasper',
    state: 'AL',
    latitude: '33.930626',
    longitude: '-87.314072',
    timeZoneId: 'America/Chicago',
  },
  '35504': {
    zip: '35504',
    city: 'Jasper',
    state: 'AL',
    latitude: '33.890976',
    longitude: '-87.194044',
    timeZoneId: 'America/Chicago',
  },
  '35540': {
    zip: '35540',
    city: 'Addison',
    state: 'AL',
    latitude: '34.213763',
    longitude: '-87.289271',
    timeZoneId: 'America/Chicago',
  },
  '35541': {
    zip: '35541',
    city: 'Arley',
    state: 'AL',
    latitude: '34.052335',
    longitude: '-87.155167',
    timeZoneId: 'America/Chicago',
  },
  '35542': {
    zip: '35542',
    city: 'Bankston',
    state: 'AL',
    latitude: '33.705822',
    longitude: '-87.68894',
    timeZoneId: 'America/Chicago',
  },
  '35543': {
    zip: '35543',
    city: 'Bear Creek',
    state: 'AL',
    latitude: '34.195102',
    longitude: '-87.714476',
    timeZoneId: 'America/Chicago',
  },
  '35544': {
    zip: '35544',
    city: 'Beaverton',
    state: 'AL',
    latitude: '33.965374',
    longitude: '-88.016495',
    timeZoneId: 'America/Chicago',
  },
  '35545': {
    zip: '35545',
    city: 'Belk',
    state: 'AL',
    latitude: '33.646243',
    longitude: '-87.931317',
    timeZoneId: 'America/Chicago',
  },
  '35546': {
    zip: '35546',
    city: 'Berry',
    state: 'AL',
    latitude: '33.50049',
    longitude: '-87.687768',
    timeZoneId: 'America/Chicago',
  },
  '35548': {
    zip: '35548',
    city: 'Brilliant',
    state: 'AL',
    latitude: '34.053064',
    longitude: '-87.730084',
    timeZoneId: 'America/Chicago',
  },
  '35549': {
    zip: '35549',
    city: 'Carbon Hill',
    state: 'AL',
    latitude: '33.909911',
    longitude: '-87.543633',
    timeZoneId: 'America/Chicago',
  },
  '35550': {
    zip: '35550',
    city: 'Cordova',
    state: 'AL',
    latitude: '33.77046',
    longitude: '-87.17778',
    timeZoneId: 'America/Chicago',
  },
  '35551': {
    zip: '35551',
    city: 'Delmar',
    state: 'AL',
    latitude: '34.173518',
    longitude: '-87.606111',
    timeZoneId: 'America/Chicago',
  },
  '35552': {
    zip: '35552',
    city: 'Detroit',
    state: 'AL',
    latitude: '33.99353',
    longitude: '-88.158358',
    timeZoneId: 'America/Chicago',
  },
  '35553': {
    zip: '35553',
    city: 'Double Springs',
    state: 'AL',
    latitude: '34.13287',
    longitude: '-87.387518',
    timeZoneId: 'America/Chicago',
  },
  '35554': {
    zip: '35554',
    city: 'Eldridge',
    state: 'AL',
    latitude: '33.921941',
    longitude: '-87.622032',
    timeZoneId: 'America/Chicago',
  },
  '35555': {
    zip: '35555',
    city: 'Fayette',
    state: 'AL',
    latitude: '33.69404',
    longitude: '-87.831107',
    timeZoneId: 'America/Chicago',
  },
  '35559': {
    zip: '35559',
    city: 'Glen Allen',
    state: 'AL',
    latitude: '33.882529',
    longitude: '-87.744808',
    timeZoneId: 'America/Chicago',
  },
  '35560': {
    zip: '35560',
    city: 'Goodsprings',
    state: 'AL',
    latitude: '33.666004',
    longitude: '-87.231064',
    timeZoneId: 'America/Chicago',
  },
  '35563': {
    zip: '35563',
    city: 'Guin',
    state: 'AL',
    latitude: '33.966394',
    longitude: '-87.895475',
    timeZoneId: 'America/Chicago',
  },
  '35564': {
    zip: '35564',
    city: 'Hackleburg',
    state: 'AL',
    latitude: '34.2593',
    longitude: '-87.866969',
    timeZoneId: 'America/Chicago',
  },
  '35565': {
    zip: '35565',
    city: 'Haleyville',
    state: 'AL',
    latitude: '34.233891',
    longitude: '-87.618593',
    timeZoneId: 'America/Chicago',
  },
  '35570': {
    zip: '35570',
    city: 'Hamilton',
    state: 'AL',
    latitude: '34.167932',
    longitude: '-87.97915',
    timeZoneId: 'America/Chicago',
  },
  '35571': {
    zip: '35571',
    city: 'Hodges',
    state: 'AL',
    latitude: '34.344894',
    longitude: '-87.968525',
    timeZoneId: 'America/Chicago',
  },
  '35572': {
    zip: '35572',
    city: 'Houston',
    state: 'AL',
    latitude: '34.280278',
    longitude: '-87.319444',
    timeZoneId: 'America/Chicago',
  },
  '35573': {
    zip: '35573',
    city: 'Kansas',
    state: 'AL',
    latitude: '33.901154',
    longitude: '-87.55216',
    timeZoneId: 'America/Chicago',
  },
  '35574': {
    zip: '35574',
    city: 'Kennedy',
    state: 'AL',
    latitude: '33.633424',
    longitude: '-87.93298',
    timeZoneId: 'America/Chicago',
  },
  '35575': {
    zip: '35575',
    city: 'Lynn',
    state: 'AL',
    latitude: '34.054728',
    longitude: '-87.529566',
    timeZoneId: 'America/Chicago',
  },
  '35576': {
    zip: '35576',
    city: 'Millport',
    state: 'AL',
    latitude: '33.55728',
    longitude: '-88.19052',
    timeZoneId: 'America/Chicago',
  },
  '35577': {
    zip: '35577',
    city: 'Natural Bridge',
    state: 'AL',
    latitude: '34.088637',
    longitude: '-87.604239',
    timeZoneId: 'America/Chicago',
  },
  '35578': {
    zip: '35578',
    city: 'Nauvoo',
    state: 'AL',
    latitude: '33.93372',
    longitude: '-87.42654',
    timeZoneId: 'America/Chicago',
  },
  '35579': {
    zip: '35579',
    city: 'Oakman',
    state: 'AL',
    latitude: '33.667762',
    longitude: '-87.368234',
    timeZoneId: 'America/Chicago',
  },
  '35580': {
    zip: '35580',
    city: 'Parrish',
    state: 'AL',
    latitude: '33.708726',
    longitude: '-87.256908',
    timeZoneId: 'America/Chicago',
  },
  '35581': {
    zip: '35581',
    city: 'Phil Campbell',
    state: 'AL',
    latitude: '34.35365',
    longitude: '-87.762918',
    timeZoneId: 'America/Chicago',
  },
  '35582': {
    zip: '35582',
    city: 'Red Bay',
    state: 'AL',
    latitude: '34.441006',
    longitude: '-88.144045',
    timeZoneId: 'America/Chicago',
  },
  '35584': {
    zip: '35584',
    city: 'Sipsey',
    state: 'AL',
    latitude: '33.822855',
    longitude: '-87.080882',
    timeZoneId: 'America/Chicago',
  },
  '35585': {
    zip: '35585',
    city: 'Spruce Pine',
    state: 'AL',
    latitude: '34.377968',
    longitude: '-87.692749',
    timeZoneId: 'America/Chicago',
  },
  '35586': {
    zip: '35586',
    city: 'Sulligent',
    state: 'AL',
    latitude: '33.893001',
    longitude: '-88.132364',
    timeZoneId: 'America/Chicago',
  },
  '35587': {
    zip: '35587',
    city: 'Townley',
    state: 'AL',
    latitude: '33.84025',
    longitude: '-87.450538',
    timeZoneId: 'America/Chicago',
  },
  '35592': {
    zip: '35592',
    city: 'Vernon',
    state: 'AL',
    latitude: '33.753979',
    longitude: '-88.118153',
    timeZoneId: 'America/Chicago',
  },
  '35593': {
    zip: '35593',
    city: 'Vina',
    state: 'AL',
    latitude: '34.366192',
    longitude: '-88.08117',
    timeZoneId: 'America/Chicago',
  },
  '35594': {
    zip: '35594',
    city: 'Winfield',
    state: 'AL',
    latitude: '33.923348',
    longitude: '-87.814573',
    timeZoneId: 'America/Chicago',
  },
  '35601': {
    zip: '35601',
    city: 'Decatur',
    state: 'AL',
    latitude: '34.59188',
    longitude: '-86.984191',
    timeZoneId: 'America/Chicago',
  },
  '35602': {
    zip: '35602',
    city: 'Decatur',
    state: 'AL',
    latitude: '34.544077',
    longitude: '-86.963369',
    timeZoneId: 'America/Chicago',
  },
  '35603': {
    zip: '35603',
    city: 'Decatur',
    state: 'AL',
    latitude: '34.546718',
    longitude: '-86.981852',
    timeZoneId: 'America/Chicago',
  },
  '35609': {
    zip: '35609',
    city: 'Decatur',
    state: 'AL',
    latitude: '34.612494',
    longitude: '-87.022119',
    timeZoneId: 'America/Chicago',
  },
  '35610': {
    zip: '35610',
    city: 'Anderson',
    state: 'AL',
    latitude: '34.951348',
    longitude: '-87.27585',
    timeZoneId: 'America/Chicago',
  },
  '35611': {
    zip: '35611',
    city: 'Athens',
    state: 'AL',
    latitude: '34.798525',
    longitude: '-86.966415',
    timeZoneId: 'America/Chicago',
  },
  '35612': {
    zip: '35612',
    city: 'Athens',
    state: 'AL',
    latitude: '34.795322',
    longitude: '-87.008608',
    timeZoneId: 'America/Chicago',
  },
  '35613': {
    zip: '35613',
    city: 'Athens',
    state: 'AL',
    latitude: '34.823963',
    longitude: '-86.882552',
    timeZoneId: 'America/Chicago',
  },
  '35614': {
    zip: '35614',
    city: 'Athens',
    state: 'AL',
    latitude: '34.782615',
    longitude: '-87.07662',
    timeZoneId: 'America/Chicago',
  },
  '35615': {
    zip: '35615',
    city: 'Belle Mina',
    state: 'AL',
    latitude: '34.711141',
    longitude: '-86.74346',
    timeZoneId: 'America/Chicago',
  },
  '35616': {
    zip: '35616',
    city: 'Cherokee',
    state: 'AL',
    latitude: '34.718885',
    longitude: '-88.000065',
    timeZoneId: 'America/Chicago',
  },
  '35617': {
    zip: '35617',
    city: 'Cloverdale',
    state: 'AL',
    latitude: '34.936875',
    longitude: '-87.771703',
    timeZoneId: 'America/Chicago',
  },
  '35618': {
    zip: '35618',
    city: 'Courtland',
    state: 'AL',
    latitude: '34.652816',
    longitude: '-87.299297',
    timeZoneId: 'America/Chicago',
  },
  '35619': {
    zip: '35619',
    city: 'Danville',
    state: 'AL',
    latitude: '34.419222',
    longitude: '-87.159667',
    timeZoneId: 'America/Chicago',
  },
  '35620': {
    zip: '35620',
    city: 'Elkmont',
    state: 'AL',
    latitude: '34.908359',
    longitude: '-87.009957',
    timeZoneId: 'America/Chicago',
  },
  '35621': {
    zip: '35621',
    city: 'Eva',
    state: 'AL',
    latitude: '34.315232',
    longitude: '-86.738421',
    timeZoneId: 'America/Chicago',
  },
  '35622': {
    zip: '35622',
    city: 'Falkville',
    state: 'AL',
    latitude: '34.381767',
    longitude: '-86.903816',
    timeZoneId: 'America/Chicago',
  },
  '35630': {
    zip: '35630',
    city: 'Florence',
    state: 'AL',
    latitude: '34.819157',
    longitude: '-87.658805',
    timeZoneId: 'America/Chicago',
  },
  '35631': {
    zip: '35631',
    city: 'Florence',
    state: 'AL',
    latitude: '34.810558',
    longitude: '-87.653253',
    timeZoneId: 'America/Chicago',
  },
  '35632': {
    zip: '35632',
    city: 'Florence',
    state: 'AL',
    latitude: '34.84171',
    longitude: '-87.606651',
    timeZoneId: 'America/Chicago',
  },
  '35633': {
    zip: '35633',
    city: 'Florence',
    state: 'AL',
    latitude: '34.856704',
    longitude: '-87.686979',
    timeZoneId: 'America/Chicago',
  },
  '35634': {
    zip: '35634',
    city: 'Florence',
    state: 'AL',
    latitude: '34.799674',
    longitude: '-87.675698',
    timeZoneId: 'America/Chicago',
  },
  '35640': {
    zip: '35640',
    city: 'Hartselle',
    state: 'AL',
    latitude: '34.446769',
    longitude: '-86.939131',
    timeZoneId: 'America/Chicago',
  },
  '35643': {
    zip: '35643',
    city: 'Hillsboro',
    state: 'AL',
    latitude: '34.616663',
    longitude: '-87.197904',
    timeZoneId: 'America/Chicago',
  },
  '35645': {
    zip: '35645',
    city: 'Killen',
    state: 'AL',
    latitude: '34.914766',
    longitude: '-87.502564',
    timeZoneId: 'America/Chicago',
  },
  '35646': {
    zip: '35646',
    city: 'Leighton',
    state: 'AL',
    latitude: '34.698609',
    longitude: '-87.522872',
    timeZoneId: 'America/Chicago',
  },
  '35647': {
    zip: '35647',
    city: 'Lester',
    state: 'AL',
    latitude: '34.925555',
    longitude: '-87.136408',
    timeZoneId: 'America/Chicago',
  },
  '35648': {
    zip: '35648',
    city: 'Lexington',
    state: 'AL',
    latitude: '34.95561',
    longitude: '-87.393336',
    timeZoneId: 'America/Chicago',
  },
  '35649': {
    zip: '35649',
    city: 'Mooresville',
    state: 'AL',
    latitude: '34.710313',
    longitude: '-86.7394',
    timeZoneId: 'America/Chicago',
  },
  '35650': {
    zip: '35650',
    city: 'Moulton',
    state: 'AL',
    latitude: '34.491418',
    longitude: '-87.290515',
    timeZoneId: 'America/Chicago',
  },
  '35651': {
    zip: '35651',
    city: 'Mount Hope',
    state: 'AL',
    latitude: '34.459229',
    longitude: '-87.480038',
    timeZoneId: 'America/Chicago',
  },
  '35652': {
    zip: '35652',
    city: 'Rogersville',
    state: 'AL',
    latitude: '34.828765',
    longitude: '-87.292792',
    timeZoneId: 'America/Chicago',
  },
  '35653': {
    zip: '35653',
    city: 'Russellville',
    state: 'AL',
    latitude: '34.508322',
    longitude: '-87.740943',
    timeZoneId: 'America/Chicago',
  },
  '35654': {
    zip: '35654',
    city: 'Russellville',
    state: 'AL',
    latitude: '34.507855',
    longitude: '-87.727793',
    timeZoneId: 'America/Chicago',
  },
  '35660': {
    zip: '35660',
    city: 'Sheffield',
    state: 'AL',
    latitude: '34.754898',
    longitude: '-87.681822',
    timeZoneId: 'America/Chicago',
  },
  '35661': {
    zip: '35661',
    city: 'Muscle Shoals',
    state: 'AL',
    latitude: '34.751695',
    longitude: '-87.644366',
    timeZoneId: 'America/Chicago',
  },
  '35662': {
    zip: '35662',
    city: 'Muscle Shoals',
    state: 'AL',
    latitude: '34.74468',
    longitude: '-87.6675',
    timeZoneId: 'America/Chicago',
  },
  '35670': {
    zip: '35670',
    city: 'Somerville',
    state: 'AL',
    latitude: '34.536418',
    longitude: '-86.780675',
    timeZoneId: 'America/Chicago',
  },
  '35671': {
    zip: '35671',
    city: 'Tanner',
    state: 'AL',
    latitude: '34.720922',
    longitude: '-86.974811',
    timeZoneId: 'America/Chicago',
  },
  '35672': {
    zip: '35672',
    city: 'Town Creek',
    state: 'AL',
    latitude: '34.613453',
    longitude: '-87.417111',
    timeZoneId: 'America/Chicago',
  },
  '35673': {
    zip: '35673',
    city: 'Trinity',
    state: 'AL',
    latitude: '34.554774',
    longitude: '-87.15824',
    timeZoneId: 'America/Chicago',
  },
  '35674': {
    zip: '35674',
    city: 'Tuscumbia',
    state: 'AL',
    latitude: '34.725279',
    longitude: '-87.697761',
    timeZoneId: 'America/Chicago',
  },
  '35677': {
    zip: '35677',
    city: 'Waterloo',
    state: 'AL',
    latitude: '34.949906',
    longitude: '-88.005186',
    timeZoneId: 'America/Chicago',
  },
  '35699': {
    zip: '35699',
    city: 'Decatur',
    state: 'AL',
    latitude: '34.607691',
    longitude: '-87.0213',
    timeZoneId: 'America/Chicago',
  },
  '35739': {
    zip: '35739',
    city: 'Ardmore',
    state: 'AL',
    latitude: '34.960139',
    longitude: '-86.755214',
    timeZoneId: 'America/Chicago',
  },
  '35740': {
    zip: '35740',
    city: 'Bridgeport',
    state: 'AL',
    latitude: '34.945931',
    longitude: '-85.720479',
    timeZoneId: 'America/Chicago',
  },
  '35741': {
    zip: '35741',
    city: 'Brownsboro',
    state: 'AL',
    latitude: '34.717749',
    longitude: '-86.482115',
    timeZoneId: 'America/Chicago',
  },
  '35742': {
    zip: '35742',
    city: 'Capshaw',
    state: 'AL',
    latitude: '34.804854',
    longitude: '-86.781949',
    timeZoneId: 'America/Chicago',
  },
  '35744': {
    zip: '35744',
    city: 'Dutton',
    state: 'AL',
    latitude: '34.607613',
    longitude: '-85.90399',
    timeZoneId: 'America/Chicago',
  },
  '35745': {
    zip: '35745',
    city: 'Estillfork',
    state: 'AL',
    latitude: '34.913039',
    longitude: '-86.194623',
    timeZoneId: 'America/Chicago',
  },
  '35746': {
    zip: '35746',
    city: 'Fackler',
    state: 'AL',
    latitude: '34.828261',
    longitude: '-85.987826',
    timeZoneId: 'America/Chicago',
  },
  '35747': {
    zip: '35747',
    city: 'Grant',
    state: 'AL',
    latitude: '34.505027',
    longitude: '-86.269107',
    timeZoneId: 'America/Chicago',
  },
  '35748': {
    zip: '35748',
    city: 'Gurley',
    state: 'AL',
    latitude: '34.718445',
    longitude: '-86.393989',
    timeZoneId: 'America/Chicago',
  },
  '35749': {
    zip: '35749',
    city: 'Harvest',
    state: 'AL',
    latitude: '34.834779',
    longitude: '-86.7395',
    timeZoneId: 'America/Chicago',
  },
  '35750': {
    zip: '35750',
    city: 'Hazel Green',
    state: 'AL',
    latitude: '34.950712',
    longitude: '-86.587332',
    timeZoneId: 'America/Chicago',
  },
  '35751': {
    zip: '35751',
    city: 'Hollytree',
    state: 'AL',
    latitude: '34.814839',
    longitude: '-86.27444',
    timeZoneId: 'America/Chicago',
  },
  '35752': {
    zip: '35752',
    city: 'Hollywood',
    state: 'AL',
    latitude: '34.73722',
    longitude: '-85.961102',
    timeZoneId: 'America/Chicago',
  },
  '35754': {
    zip: '35754',
    city: 'Laceys Spring',
    state: 'AL',
    latitude: '34.523132',
    longitude: '-86.599091',
    timeZoneId: 'America/Chicago',
  },
  '35755': {
    zip: '35755',
    city: 'Langston',
    state: 'AL',
    latitude: '34.455447',
    longitude: '-86.142996',
    timeZoneId: 'America/Chicago',
  },
  '35756': {
    zip: '35756',
    city: 'Madison',
    state: 'AL',
    latitude: '34.581973',
    longitude: '-86.822646',
    timeZoneId: 'America/Chicago',
  },
  '35757': {
    zip: '35757',
    city: 'Madison',
    state: 'AL',
    latitude: '34.770466',
    longitude: '-86.749611',
    timeZoneId: 'America/Chicago',
  },
  '35758': {
    zip: '35758',
    city: 'Madison',
    state: 'AL',
    latitude: '34.68468',
    longitude: '-86.79204',
    timeZoneId: 'America/Chicago',
  },
  '35759': {
    zip: '35759',
    city: 'Meridianville',
    state: 'AL',
    latitude: '34.866627',
    longitude: '-86.569504',
    timeZoneId: 'America/Chicago',
  },
  '35760': {
    zip: '35760',
    city: 'New Hope',
    state: 'AL',
    latitude: '34.551235',
    longitude: '-86.39604',
    timeZoneId: 'America/Chicago',
  },
  '35761': {
    zip: '35761',
    city: 'New Market',
    state: 'AL',
    latitude: '34.901198',
    longitude: '-86.446794',
    timeZoneId: 'America/Chicago',
  },
  '35762': {
    zip: '35762',
    city: 'Normal',
    state: 'AL',
    latitude: '34.792912',
    longitude: '-86.569',
    timeZoneId: 'America/Chicago',
  },
  '35763': {
    zip: '35763',
    city: 'Owens Cross Roads',
    state: 'AL',
    latitude: '34.610433',
    longitude: '-86.463316',
    timeZoneId: 'America/Chicago',
  },
  '35764': {
    zip: '35764',
    city: 'Paint Rock',
    state: 'AL',
    latitude: '34.673521',
    longitude: '-86.317046',
    timeZoneId: 'America/Chicago',
  },
  '35765': {
    zip: '35765',
    city: 'Pisgah',
    state: 'AL',
    latitude: '34.699257',
    longitude: '-85.813193',
    timeZoneId: 'America/Chicago',
  },
  '35766': {
    zip: '35766',
    city: 'Princeton',
    state: 'AL',
    latitude: '34.839156',
    longitude: '-86.228857',
    timeZoneId: 'America/Chicago',
  },
  '35767': {
    zip: '35767',
    city: 'Ryland',
    state: 'AL',
    latitude: '34.770398',
    longitude: '-86.481716',
    timeZoneId: 'America/Chicago',
  },
  '35768': {
    zip: '35768',
    city: 'Scottsboro',
    state: 'AL',
    latitude: '34.91655',
    longitude: '-86.08791',
    timeZoneId: 'America/Chicago',
  },
  '35769': {
    zip: '35769',
    city: 'Scottsboro',
    state: 'AL',
    latitude: '34.650985',
    longitude: '-86.045946',
    timeZoneId: 'America/Chicago',
  },
  '35771': {
    zip: '35771',
    city: 'Section',
    state: 'AL',
    latitude: '34.580858',
    longitude: '-85.989749',
    timeZoneId: 'America/Chicago',
  },
  '35772': {
    zip: '35772',
    city: 'Stevenson',
    state: 'AL',
    latitude: '34.868205',
    longitude: '-85.833355',
    timeZoneId: 'America/Chicago',
  },
  '35773': {
    zip: '35773',
    city: 'Toney',
    state: 'AL',
    latitude: '34.901692',
    longitude: '-86.691794',
    timeZoneId: 'America/Chicago',
  },
  '35774': {
    zip: '35774',
    city: 'Trenton',
    state: 'AL',
    latitude: '34.749319',
    longitude: '-86.274711',
    timeZoneId: 'America/Chicago',
  },
  '35775': {
    zip: '35775',
    city: 'Valhermoso Springs',
    state: 'AL',
    latitude: '34.55049',
    longitude: '-86.69497',
    timeZoneId: 'America/Chicago',
  },
  '35776': {
    zip: '35776',
    city: 'Woodville',
    state: 'AL',
    latitude: '34.575641',
    longitude: '-86.243534',
    timeZoneId: 'America/Chicago',
  },
  '35801': {
    zip: '35801',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.725222',
    longitude: '-86.566317',
    timeZoneId: 'America/Chicago',
  },
  '35802': {
    zip: '35802',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.668541',
    longitude: '-86.560249',
    timeZoneId: 'America/Chicago',
  },
  '35803': {
    zip: '35803',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.616542',
    longitude: '-86.549318',
    timeZoneId: 'America/Chicago',
  },
  '35804': {
    zip: '35804',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.729985',
    longitude: '-86.585901',
    timeZoneId: 'America/Chicago',
  },
  '35805': {
    zip: '35805',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.712977',
    longitude: '-86.612847',
    timeZoneId: 'America/Chicago',
  },
  '35806': {
    zip: '35806',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.7573',
    longitude: '-86.678606',
    timeZoneId: 'America/Chicago',
  },
  '35807': {
    zip: '35807',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.714111',
    longitude: '-86.653531',
    timeZoneId: 'America/Chicago',
  },
  '35808': {
    zip: '35808',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.69841',
    longitude: '-86.646477',
    timeZoneId: 'America/Chicago',
  },
  '35809': {
    zip: '35809',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.730278',
    longitude: '-86.586111',
    timeZoneId: 'America/Chicago',
  },
  '35810': {
    zip: '35810',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.781112',
    longitude: '-86.605454',
    timeZoneId: 'America/Chicago',
  },
  '35811': {
    zip: '35811',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.780058',
    longitude: '-86.52391',
    timeZoneId: 'America/Chicago',
  },
  '35812': {
    zip: '35812',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.632494',
    longitude: '-86.652732',
    timeZoneId: 'America/Chicago',
  },
  '35813': {
    zip: '35813',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.645609',
    longitude: '-86.750533',
    timeZoneId: 'America/Chicago',
  },
  '35814': {
    zip: '35814',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.708405',
    longitude: '-86.61731',
    timeZoneId: 'America/Chicago',
  },
  '35815': {
    zip: '35815',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.640498',
    longitude: '-86.564791',
    timeZoneId: 'America/Chicago',
  },
  '35816': {
    zip: '35816',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.737489',
    longitude: '-86.624911',
    timeZoneId: 'America/Chicago',
  },
  '35824': {
    zip: '35824',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.621292',
    longitude: '-86.733809',
    timeZoneId: 'America/Chicago',
  },
  '35893': {
    zip: '35893',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.73244',
    longitude: '-86.584594',
    timeZoneId: 'America/Chicago',
  },
  '35894': {
    zip: '35894',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.646701',
    longitude: '-86.754498',
    timeZoneId: 'America/Chicago',
  },
  '35895': {
    zip: '35895',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.731405',
    longitude: '-86.589774',
    timeZoneId: 'America/Chicago',
  },
  '35896': {
    zip: '35896',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.663817',
    longitude: '-86.751466',
    timeZoneId: 'America/Chicago',
  },
  '35897': {
    zip: '35897',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.716742',
    longitude: '-86.631183',
    timeZoneId: 'America/Chicago',
  },
  '35898': {
    zip: '35898',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.628936',
    longitude: '-86.651129',
    timeZoneId: 'America/Chicago',
  },
  '35899': {
    zip: '35899',
    city: 'Huntsville',
    state: 'AL',
    latitude: '34.740543',
    longitude: '-86.629283',
    timeZoneId: 'America/Chicago',
  },
  '35901': {
    zip: '35901',
    city: 'Gadsden',
    state: 'AL',
    latitude: '34.00824',
    longitude: '-85.94424',
    timeZoneId: 'America/Chicago',
  },
  '35902': {
    zip: '35902',
    city: 'Gadsden',
    state: 'AL',
    latitude: '34.000276',
    longitude: '-85.956435',
    timeZoneId: 'America/Chicago',
  },
  '35903': {
    zip: '35903',
    city: 'Gadsden',
    state: 'AL',
    latitude: '33.9933',
    longitude: '-85.9059',
    timeZoneId: 'America/Chicago',
  },
  '35904': {
    zip: '35904',
    city: 'Gadsden',
    state: 'AL',
    latitude: '34.023713',
    longitude: '-86.054211',
    timeZoneId: 'America/Chicago',
  },
  '35905': {
    zip: '35905',
    city: 'Gadsden',
    state: 'AL',
    latitude: '33.954808',
    longitude: '-85.903321',
    timeZoneId: 'America/Chicago',
  },
  '35906': {
    zip: '35906',
    city: 'Rainbow City',
    state: 'AL',
    latitude: '33.942',
    longitude: '-86.10372',
    timeZoneId: 'America/Chicago',
  },
  '35907': {
    zip: '35907',
    city: 'Gadsden',
    state: 'AL',
    latitude: '34.014167',
    longitude: '-86.006667',
    timeZoneId: 'America/Chicago',
  },
  '35950': {
    zip: '35950',
    city: 'Albertville',
    state: 'AL',
    latitude: '34.263566',
    longitude: '-86.207048',
    timeZoneId: 'America/Chicago',
  },
  '35951': {
    zip: '35951',
    city: 'Albertville',
    state: 'AL',
    latitude: '34.267171',
    longitude: '-86.210937',
    timeZoneId: 'America/Chicago',
  },
  '35952': {
    zip: '35952',
    city: 'Altoona',
    state: 'AL',
    latitude: '34.026',
    longitude: '-86.29926',
    timeZoneId: 'America/Chicago',
  },
  '35953': {
    zip: '35953',
    city: 'Ashville',
    state: 'AL',
    latitude: '33.837955',
    longitude: '-86.250092',
    timeZoneId: 'America/Chicago',
  },
  '35954': {
    zip: '35954',
    city: 'Attalla',
    state: 'AL',
    latitude: '34.08306',
    longitude: '-86.06028',
    timeZoneId: 'America/Chicago',
  },
  '35956': {
    zip: '35956',
    city: 'Boaz',
    state: 'AL',
    latitude: '34.203788',
    longitude: '-86.154159',
    timeZoneId: 'America/Chicago',
  },
  '35957': {
    zip: '35957',
    city: 'Boaz',
    state: 'AL',
    latitude: '34.1481',
    longitude: '-86.14806',
    timeZoneId: 'America/Chicago',
  },
  '35958': {
    zip: '35958',
    city: 'Bryant',
    state: 'AL',
    latitude: '34.95009',
    longitude: '-85.643668',
    timeZoneId: 'America/Chicago',
  },
  '35959': {
    zip: '35959',
    city: 'Cedar Bluff',
    state: 'AL',
    latitude: '34.216906',
    longitude: '-85.600321',
    timeZoneId: 'America/Chicago',
  },
  '35960': {
    zip: '35960',
    city: 'Centre',
    state: 'AL',
    latitude: '34.15083',
    longitude: '-85.674502',
    timeZoneId: 'America/Chicago',
  },
  '35961': {
    zip: '35961',
    city: 'Collinsville',
    state: 'AL',
    latitude: '34.307986',
    longitude: '-85.834569',
    timeZoneId: 'America/Chicago',
  },
  '35962': {
    zip: '35962',
    city: 'Crossville',
    state: 'AL',
    latitude: '34.283369',
    longitude: '-85.998076',
    timeZoneId: 'America/Chicago',
  },
  '35963': {
    zip: '35963',
    city: 'Dawson',
    state: 'AL',
    latitude: '34.355993',
    longitude: '-85.926785',
    timeZoneId: 'America/Chicago',
  },
  '35964': {
    zip: '35964',
    city: 'Douglas',
    state: 'AL',
    latitude: '34.17087',
    longitude: '-86.3229',
    timeZoneId: 'America/Chicago',
  },
  '35966': {
    zip: '35966',
    city: 'Flat Rock',
    state: 'AL',
    latitude: '34.807624',
    longitude: '-85.72897',
    timeZoneId: 'America/Chicago',
  },
  '35967': {
    zip: '35967',
    city: 'Fort Payne',
    state: 'AL',
    latitude: '34.445502',
    longitude: '-85.711553',
    timeZoneId: 'America/Chicago',
  },
  '35968': {
    zip: '35968',
    city: 'Fort Payne',
    state: 'AL',
    latitude: '34.441634',
    longitude: '-85.719381',
    timeZoneId: 'America/Chicago',
  },
  '35971': {
    zip: '35971',
    city: 'Fyffe',
    state: 'AL',
    latitude: '34.506569',
    longitude: '-85.9043',
    timeZoneId: 'America/Chicago',
  },
  '35972': {
    zip: '35972',
    city: 'Gallant',
    state: 'AL',
    latitude: '33.994384',
    longitude: '-86.227809',
    timeZoneId: 'America/Chicago',
  },
  '35973': {
    zip: '35973',
    city: 'Gaylesville',
    state: 'AL',
    latitude: '34.36761',
    longitude: '-85.557609',
    timeZoneId: 'America/Chicago',
  },
  '35974': {
    zip: '35974',
    city: 'Geraldine',
    state: 'AL',
    latitude: '34.35742',
    longitude: '-86.003111',
    timeZoneId: 'America/Chicago',
  },
  '35975': {
    zip: '35975',
    city: 'Groveoak',
    state: 'AL',
    latitude: '34.435006',
    longitude: '-86.044669',
    timeZoneId: 'America/Chicago',
  },
  '35976': {
    zip: '35976',
    city: 'Guntersville',
    state: 'AL',
    latitude: '34.335961',
    longitude: '-86.307211',
    timeZoneId: 'America/Chicago',
  },
  '35978': {
    zip: '35978',
    city: 'Henagar',
    state: 'AL',
    latitude: '34.626709',
    longitude: '-85.752319',
    timeZoneId: 'America/Chicago',
  },
  '35979': {
    zip: '35979',
    city: 'Higdon',
    state: 'AL',
    latitude: '34.882242',
    longitude: '-85.639687',
    timeZoneId: 'America/Chicago',
  },
  '35980': {
    zip: '35980',
    city: 'Horton',
    state: 'AL',
    latitude: '34.185171',
    longitude: '-86.3233',
    timeZoneId: 'America/Chicago',
  },
  '35981': {
    zip: '35981',
    city: 'Ider',
    state: 'AL',
    latitude: '34.728552',
    longitude: '-85.636151',
    timeZoneId: 'America/Chicago',
  },
  '35983': {
    zip: '35983',
    city: 'Leesburg',
    state: 'AL',
    latitude: '34.160544',
    longitude: '-85.779405',
    timeZoneId: 'America/Chicago',
  },
  '35984': {
    zip: '35984',
    city: 'Mentone',
    state: 'AL',
    latitude: '34.591751',
    longitude: '-85.57661',
    timeZoneId: 'America/Chicago',
  },
  '35986': {
    zip: '35986',
    city: 'Rainsville',
    state: 'AL',
    latitude: '34.497002',
    longitude: '-85.84376',
    timeZoneId: 'America/Chicago',
  },
  '35987': {
    zip: '35987',
    city: 'Steele',
    state: 'AL',
    latitude: '33.928976',
    longitude: '-86.174926',
    timeZoneId: 'America/Chicago',
  },
  '35988': {
    zip: '35988',
    city: 'Sylvania',
    state: 'AL',
    latitude: '34.560198',
    longitude: '-85.787476',
    timeZoneId: 'America/Chicago',
  },
  '35989': {
    zip: '35989',
    city: 'Valley Head',
    state: 'AL',
    latitude: '34.504625',
    longitude: '-85.681555',
    timeZoneId: 'America/Chicago',
  },
  '35990': {
    zip: '35990',
    city: 'Walnut Grove',
    state: 'AL',
    latitude: '34.063349',
    longitude: '-86.288307',
    timeZoneId: 'America/Chicago',
  },
  '36003': {
    zip: '36003',
    city: 'Autaugaville',
    state: 'AL',
    latitude: '32.45868',
    longitude: '-86.71458',
    timeZoneId: 'America/Chicago',
  },
  '36005': {
    zip: '36005',
    city: 'Banks',
    state: 'AL',
    latitude: '31.842135',
    longitude: '-85.789986',
    timeZoneId: 'America/Chicago',
  },
  '36006': {
    zip: '36006',
    city: 'Billingsley',
    state: 'AL',
    latitude: '32.616643',
    longitude: '-86.708049',
    timeZoneId: 'America/Chicago',
  },
  '36008': {
    zip: '36008',
    city: 'Booth',
    state: 'AL',
    latitude: '32.497413',
    longitude: '-86.571774',
    timeZoneId: 'America/Chicago',
  },
  '36009': {
    zip: '36009',
    city: 'Brantley',
    state: 'AL',
    latitude: '31.585788',
    longitude: '-86.247921',
    timeZoneId: 'America/Chicago',
  },
  '36010': {
    zip: '36010',
    city: 'Brundidge',
    state: 'AL',
    latitude: '31.721929',
    longitude: '-85.819591',
    timeZoneId: 'America/Chicago',
  },
  '36013': {
    zip: '36013',
    city: 'Cecil',
    state: 'AL',
    latitude: '32.29734',
    longitude: '-86.01846',
    timeZoneId: 'America/Chicago',
  },
  '36015': {
    zip: '36015',
    city: 'Chapman',
    state: 'AL',
    latitude: '31.663525',
    longitude: '-86.700217',
    timeZoneId: 'America/Chicago',
  },
  '36016': {
    zip: '36016',
    city: 'Clayton',
    state: 'AL',
    latitude: '31.88351',
    longitude: '-85.451006',
    timeZoneId: 'America/Chicago',
  },
  '36017': {
    zip: '36017',
    city: 'Clio',
    state: 'AL',
    latitude: '31.711536',
    longitude: '-85.661259',
    timeZoneId: 'America/Chicago',
  },
  '36020': {
    zip: '36020',
    city: 'Coosada',
    state: 'AL',
    latitude: '32.496449',
    longitude: '-86.343169',
    timeZoneId: 'America/Chicago',
  },
  '36022': {
    zip: '36022',
    city: 'Deatsville',
    state: 'AL',
    latitude: '32.583847',
    longitude: '-86.444717',
    timeZoneId: 'America/Chicago',
  },
  '36023': {
    zip: '36023',
    city: 'East Tallassee',
    state: 'AL',
    latitude: '32.60958',
    longitude: '-85.810369',
    timeZoneId: 'America/Chicago',
  },
  '36024': {
    zip: '36024',
    city: 'Eclectic',
    state: 'AL',
    latitude: '32.68158',
    longitude: '-85.99788',
    timeZoneId: 'America/Chicago',
  },
  '36025': {
    zip: '36025',
    city: 'Elmore',
    state: 'AL',
    latitude: '32.516019',
    longitude: '-86.351147',
    timeZoneId: 'America/Chicago',
  },
  '36026': {
    zip: '36026',
    city: 'Equality',
    state: 'AL',
    latitude: '32.8032',
    longitude: '-86.0799',
    timeZoneId: 'America/Chicago',
  },
  '36027': {
    zip: '36027',
    city: 'Eufaula',
    state: 'AL',
    latitude: '31.884947',
    longitude: '-85.153904',
    timeZoneId: 'America/Chicago',
  },
  '36028': {
    zip: '36028',
    city: 'Dozier',
    state: 'AL',
    latitude: '31.523436',
    longitude: '-86.363431',
    timeZoneId: 'America/Chicago',
  },
  '36029': {
    zip: '36029',
    city: 'Fitzpatrick',
    state: 'AL',
    latitude: '32.179055',
    longitude: '-85.901826',
    timeZoneId: 'America/Chicago',
  },
  '36030': {
    zip: '36030',
    city: 'Forest Home',
    state: 'AL',
    latitude: '31.875416',
    longitude: '-86.796942',
    timeZoneId: 'America/Chicago',
  },
  '36031': {
    zip: '36031',
    city: 'Fort Davis',
    state: 'AL',
    latitude: '32.286461',
    longitude: '-85.728157',
    timeZoneId: 'America/Chicago',
  },
  '36032': {
    zip: '36032',
    city: 'Fort Deposit',
    state: 'AL',
    latitude: '31.982388',
    longitude: '-86.578741',
    timeZoneId: 'America/Chicago',
  },
  '36033': {
    zip: '36033',
    city: 'Georgiana',
    state: 'AL',
    latitude: '31.6409',
    longitude: '-86.734286',
    timeZoneId: 'America/Chicago',
  },
  '36034': {
    zip: '36034',
    city: 'Glenwood',
    state: 'AL',
    latitude: '31.666194',
    longitude: '-86.16687',
    timeZoneId: 'America/Chicago',
  },
  '36035': {
    zip: '36035',
    city: 'Goshen',
    state: 'AL',
    latitude: '31.854466',
    longitude: '-86.108579',
    timeZoneId: 'America/Chicago',
  },
  '36036': {
    zip: '36036',
    city: 'Grady',
    state: 'AL',
    latitude: '32.002701',
    longitude: '-86.115957',
    timeZoneId: 'America/Chicago',
  },
  '36037': {
    zip: '36037',
    city: 'Greenville',
    state: 'AL',
    latitude: '31.819462',
    longitude: '-86.607363',
    timeZoneId: 'America/Chicago',
  },
  '36038': {
    zip: '36038',
    city: 'Gantt',
    state: 'AL',
    latitude: '31.422368',
    longitude: '-86.416458',
    timeZoneId: 'America/Chicago',
  },
  '36039': {
    zip: '36039',
    city: 'Hardaway',
    state: 'AL',
    latitude: '32.307753',
    longitude: '-85.807747',
    timeZoneId: 'America/Chicago',
  },
  '36040': {
    zip: '36040',
    city: 'Hayneville',
    state: 'AL',
    latitude: '32.20548',
    longitude: '-86.66022',
    timeZoneId: 'America/Chicago',
  },
  '36041': {
    zip: '36041',
    city: 'Highland Home',
    state: 'AL',
    latitude: '31.922283',
    longitude: '-86.300926',
    timeZoneId: 'America/Chicago',
  },
  '36042': {
    zip: '36042',
    city: 'Honoraville',
    state: 'AL',
    latitude: '31.877638',
    longitude: '-86.39874',
    timeZoneId: 'America/Chicago',
  },
  '36043': {
    zip: '36043',
    city: 'Hope Hull',
    state: 'AL',
    latitude: '32.230319',
    longitude: '-86.367123',
    timeZoneId: 'America/Chicago',
  },
  '36045': {
    zip: '36045',
    city: 'Kent',
    state: 'AL',
    latitude: '32.618799',
    longitude: '-85.949542',
    timeZoneId: 'America/Chicago',
  },
  '36046': {
    zip: '36046',
    city: 'Lapine',
    state: 'AL',
    latitude: '31.985756',
    longitude: '-86.281073',
    timeZoneId: 'America/Chicago',
  },
  '36047': {
    zip: '36047',
    city: 'Letohatchee',
    state: 'AL',
    latitude: '32.078214',
    longitude: '-86.555167',
    timeZoneId: 'America/Chicago',
  },
  '36048': {
    zip: '36048',
    city: 'Louisville',
    state: 'AL',
    latitude: '31.780682',
    longitude: '-85.554586',
    timeZoneId: 'America/Chicago',
  },
  '36049': {
    zip: '36049',
    city: 'Luverne',
    state: 'AL',
    latitude: '31.713452',
    longitude: '-86.263994',
    timeZoneId: 'America/Chicago',
  },
  '36051': {
    zip: '36051',
    city: 'Marbury',
    state: 'AL',
    latitude: '32.668183',
    longitude: '-86.517425',
    timeZoneId: 'America/Chicago',
  },
  '36052': {
    zip: '36052',
    city: 'Mathews',
    state: 'AL',
    latitude: '32.128288',
    longitude: '-86.041262',
    timeZoneId: 'America/Chicago',
  },
  '36053': {
    zip: '36053',
    city: 'Midway',
    state: 'AL',
    latitude: '32.107979',
    longitude: '-85.52691',
    timeZoneId: 'America/Chicago',
  },
  '36054': {
    zip: '36054',
    city: 'Millbrook',
    state: 'AL',
    latitude: '32.489491',
    longitude: '-86.36808',
    timeZoneId: 'America/Chicago',
  },
  '36057': {
    zip: '36057',
    city: 'Mount Meigs',
    state: 'AL',
    latitude: '32.360503',
    longitude: '-86.065967',
    timeZoneId: 'America/Chicago',
  },
  '36061': {
    zip: '36061',
    city: 'Perote',
    state: 'AL',
    latitude: '31.991905',
    longitude: '-85.751161',
    timeZoneId: 'America/Chicago',
  },
  '36062': {
    zip: '36062',
    city: 'Petrey',
    state: 'AL',
    latitude: '31.848193',
    longitude: '-86.207276',
    timeZoneId: 'America/Chicago',
  },
  '36064': {
    zip: '36064',
    city: 'Pike Road',
    state: 'AL',
    latitude: '32.322691',
    longitude: '-86.094111',
    timeZoneId: 'America/Chicago',
  },
  '36065': {
    zip: '36065',
    city: 'Pine Level',
    state: 'AL',
    latitude: '32.070031',
    longitude: '-86.057006',
    timeZoneId: 'America/Chicago',
  },
  '36066': {
    zip: '36066',
    city: 'Prattville',
    state: 'AL',
    latitude: '32.469884',
    longitude: '-86.436853',
    timeZoneId: 'America/Chicago',
  },
  '36067': {
    zip: '36067',
    city: 'Prattville',
    state: 'AL',
    latitude: '32.487639',
    longitude: '-86.508013',
    timeZoneId: 'America/Chicago',
  },
  '36068': {
    zip: '36068',
    city: 'Prattville',
    state: 'AL',
    latitude: '32.461221',
    longitude: '-86.444633',
    timeZoneId: 'America/Chicago',
  },
  '36069': {
    zip: '36069',
    city: 'Ramer',
    state: 'AL',
    latitude: '32.0949',
    longitude: '-86.28222',
    timeZoneId: 'America/Chicago',
  },
  '36071': {
    zip: '36071',
    city: 'Rutledge',
    state: 'AL',
    latitude: '31.768034',
    longitude: '-86.364675',
    timeZoneId: 'America/Chicago',
  },
  '36072': {
    zip: '36072',
    city: 'Eufaula',
    state: 'AL',
    latitude: '31.889093',
    longitude: '-85.143727',
    timeZoneId: 'America/Chicago',
  },
  '36075': {
    zip: '36075',
    city: 'Shorter',
    state: 'AL',
    latitude: '32.406725',
    longitude: '-85.954678',
    timeZoneId: 'America/Chicago',
  },
  '36078': {
    zip: '36078',
    city: 'Tallassee',
    state: 'AL',
    latitude: '32.540535',
    longitude: '-85.904526',
    timeZoneId: 'America/Chicago',
  },
  '36079': {
    zip: '36079',
    city: 'Troy',
    state: 'AL',
    latitude: '31.712313',
    longitude: '-85.974013',
    timeZoneId: 'America/Chicago',
  },
  '36080': {
    zip: '36080',
    city: 'Titus',
    state: 'AL',
    latitude: '32.651394',
    longitude: '-86.264772',
    timeZoneId: 'America/Chicago',
  },
  '36081': {
    zip: '36081',
    city: 'Troy',
    state: 'AL',
    latitude: '31.799767',
    longitude: '-85.963152',
    timeZoneId: 'America/Chicago',
  },
  '36082': {
    zip: '36082',
    city: 'Troy',
    state: 'AL',
    latitude: '31.797868',
    longitude: '-85.959935',
    timeZoneId: 'America/Chicago',
  },
  '36083': {
    zip: '36083',
    city: 'Tuskegee',
    state: 'AL',
    latitude: '32.427623',
    longitude: '-85.690804',
    timeZoneId: 'America/Chicago',
  },
  '36087': {
    zip: '36087',
    city: 'Tuskegee Institute',
    state: 'AL',
    latitude: '32.412992',
    longitude: '-85.681539',
    timeZoneId: 'America/Chicago',
  },
  '36088': {
    zip: '36088',
    city: 'Tuskegee Institute',
    state: 'AL',
    latitude: '32.413395',
    longitude: '-85.721531',
    timeZoneId: 'America/Chicago',
  },
  '36089': {
    zip: '36089',
    city: 'Union Springs',
    state: 'AL',
    latitude: '32.140539',
    longitude: '-85.714718',
    timeZoneId: 'America/Chicago',
  },
  '36091': {
    zip: '36091',
    city: 'Verbena',
    state: 'AL',
    latitude: '32.692852',
    longitude: '-86.553384',
    timeZoneId: 'America/Chicago',
  },
  '36092': {
    zip: '36092',
    city: 'Wetumpka',
    state: 'AL',
    latitude: '32.53204',
    longitude: '-86.193776',
    timeZoneId: 'America/Chicago',
  },
  '36093': {
    zip: '36093',
    city: 'Wetumpka',
    state: 'AL',
    latitude: '32.545362',
    longitude: '-86.203327',
    timeZoneId: 'America/Chicago',
  },
  '36101': {
    zip: '36101',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.398863',
    longitude: '-86.325648',
    timeZoneId: 'America/Chicago',
  },
  '36102': {
    zip: '36102',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.395238',
    longitude: '-86.326456',
    timeZoneId: 'America/Chicago',
  },
  '36103': {
    zip: '36103',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.397938',
    longitude: '-86.326829',
    timeZoneId: 'America/Chicago',
  },
  '36104': {
    zip: '36104',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.409628',
    longitude: '-86.330198',
    timeZoneId: 'America/Chicago',
  },
  '36105': {
    zip: '36105',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.323522',
    longitude: '-86.313946',
    timeZoneId: 'America/Chicago',
  },
  '36106': {
    zip: '36106',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.351832',
    longitude: '-86.264275',
    timeZoneId: 'America/Chicago',
  },
  '36107': {
    zip: '36107',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.376358',
    longitude: '-86.278031',
    timeZoneId: 'America/Chicago',
  },
  '36108': {
    zip: '36108',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.35067',
    longitude: '-86.361835',
    timeZoneId: 'America/Chicago',
  },
  '36109': {
    zip: '36109',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.380663',
    longitude: '-86.246181',
    timeZoneId: 'America/Chicago',
  },
  '36110': {
    zip: '36110',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.418887',
    longitude: '-86.273229',
    timeZoneId: 'America/Chicago',
  },
  '36111': {
    zip: '36111',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.337152',
    longitude: '-86.267594',
    timeZoneId: 'America/Chicago',
  },
  '36112': {
    zip: '36112',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.36658',
    longitude: '-86.29998',
    timeZoneId: 'America/Chicago',
  },
  '36113': {
    zip: '36113',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.3829',
    longitude: '-86.3043',
    timeZoneId: 'America/Chicago',
  },
  '36114': {
    zip: '36114',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.405544',
    longitude: '-86.24805',
    timeZoneId: 'America/Chicago',
  },
  '36115': {
    zip: '36115',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.405177',
    longitude: '-86.250295',
    timeZoneId: 'America/Chicago',
  },
  '36116': {
    zip: '36116',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.3119',
    longitude: '-86.237923',
    timeZoneId: 'America/Chicago',
  },
  '36117': {
    zip: '36117',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.38369',
    longitude: '-86.13246',
    timeZoneId: 'America/Chicago',
  },
  '36118': {
    zip: '36118',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.406982',
    longitude: '-86.247828',
    timeZoneId: 'America/Chicago',
  },
  '36119': {
    zip: '36119',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.398077',
    longitude: '-86.328482',
    timeZoneId: 'America/Chicago',
  },
  '36120': {
    zip: '36120',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.312846',
    longitude: '-86.234558',
    timeZoneId: 'America/Chicago',
  },
  '36121': {
    zip: '36121',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.386094',
    longitude: '-86.146989',
    timeZoneId: 'America/Chicago',
  },
  '36123': {
    zip: '36123',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.364931',
    longitude: '-86.298471',
    timeZoneId: 'America/Chicago',
  },
  '36124': {
    zip: '36124',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.393633',
    longitude: '-86.175738',
    timeZoneId: 'America/Chicago',
  },
  '36125': {
    zip: '36125',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.313036',
    longitude: '-86.318132',
    timeZoneId: 'America/Chicago',
  },
  '36130': {
    zip: '36130',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.37316',
    longitude: '-86.311027',
    timeZoneId: 'America/Chicago',
  },
  '36131': {
    zip: '36131',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.379353',
    longitude: '-86.298504',
    timeZoneId: 'America/Chicago',
  },
  '36132': {
    zip: '36132',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.3801',
    longitude: '-86.181883',
    timeZoneId: 'America/Chicago',
  },
  '36135': {
    zip: '36135',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.387934',
    longitude: '-86.151025',
    timeZoneId: 'America/Chicago',
  },
  '36140': {
    zip: '36140',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.382875',
    longitude: '-86.146711',
    timeZoneId: 'America/Chicago',
  },
  '36141': {
    zip: '36141',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.386911',
    longitude: '-86.148084',
    timeZoneId: 'America/Chicago',
  },
  '36142': {
    zip: '36142',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.381048',
    longitude: '-86.310445',
    timeZoneId: 'America/Chicago',
  },
  '36177': {
    zip: '36177',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.378975',
    longitude: '-86.303722',
    timeZoneId: 'America/Chicago',
  },
  '36191': {
    zip: '36191',
    city: 'Montgomery',
    state: 'AL',
    latitude: '32.326539',
    longitude: '-86.276619',
    timeZoneId: 'America/Chicago',
  },
  '36201': {
    zip: '36201',
    city: 'Anniston',
    state: 'AL',
    latitude: '33.65665',
    longitude: '-85.827251',
    timeZoneId: 'America/Chicago',
  },
  '36202': {
    zip: '36202',
    city: 'Anniston',
    state: 'AL',
    latitude: '33.718679',
    longitude: '-85.875597',
    timeZoneId: 'America/Chicago',
  },
  '36203': {
    zip: '36203',
    city: 'Oxford',
    state: 'AL',
    latitude: '33.602111',
    longitude: '-85.835924',
    timeZoneId: 'America/Chicago',
  },
  '36204': {
    zip: '36204',
    city: 'Anniston',
    state: 'AL',
    latitude: '33.684768',
    longitude: '-85.822124',
    timeZoneId: 'America/Chicago',
  },
  '36205': {
    zip: '36205',
    city: 'Anniston',
    state: 'AL',
    latitude: '33.659722',
    longitude: '-85.831667',
    timeZoneId: 'America/Chicago',
  },
  '36206': {
    zip: '36206',
    city: 'Anniston',
    state: 'AL',
    latitude: '33.720033',
    longitude: '-85.832356',
    timeZoneId: 'America/Chicago',
  },
  '36207': {
    zip: '36207',
    city: 'Anniston',
    state: 'AL',
    latitude: '33.680609',
    longitude: '-85.710854',
    timeZoneId: 'America/Chicago',
  },
  '36250': {
    zip: '36250',
    city: 'Alexandria',
    state: 'AL',
    latitude: '33.767433',
    longitude: '-85.893282',
    timeZoneId: 'America/Chicago',
  },
  '36251': {
    zip: '36251',
    city: 'Ashland',
    state: 'AL',
    latitude: '33.274905',
    longitude: '-85.832315',
    timeZoneId: 'America/Chicago',
  },
  '36253': {
    zip: '36253',
    city: 'Bynum',
    state: 'AL',
    latitude: '33.602494',
    longitude: '-85.962626',
    timeZoneId: 'America/Chicago',
  },
  '36254': {
    zip: '36254',
    city: 'Choccolocco',
    state: 'AL',
    latitude: '33.65793',
    longitude: '-85.699195',
    timeZoneId: 'America/Chicago',
  },
  '36255': {
    zip: '36255',
    city: 'Cragford',
    state: 'AL',
    latitude: '33.217353',
    longitude: '-85.689925',
    timeZoneId: 'America/Chicago',
  },
  '36256': {
    zip: '36256',
    city: 'Daviston',
    state: 'AL',
    latitude: '33.042132',
    longitude: '-85.738562',
    timeZoneId: 'America/Chicago',
  },
  '36257': {
    zip: '36257',
    city: 'De Armanville',
    state: 'AL',
    latitude: '33.628517',
    longitude: '-85.74325',
    timeZoneId: 'America/Chicago',
  },
  '36258': {
    zip: '36258',
    city: 'Delta',
    state: 'AL',
    latitude: '33.454247',
    longitude: '-85.684409',
    timeZoneId: 'America/Chicago',
  },
  '36260': {
    zip: '36260',
    city: 'Eastaboga',
    state: 'AL',
    latitude: '33.603741',
    longitude: '-85.976733',
    timeZoneId: 'America/Chicago',
  },
  '36261': {
    zip: '36261',
    city: 'Edwardsville',
    state: 'AL',
    latitude: '33.707399',
    longitude: '-85.509363',
    timeZoneId: 'America/Chicago',
  },
  '36262': {
    zip: '36262',
    city: 'Fruithurst',
    state: 'AL',
    latitude: '33.729919',
    longitude: '-85.43037',
    timeZoneId: 'America/Chicago',
  },
  '36263': {
    zip: '36263',
    city: 'Graham',
    state: 'AL',
    latitude: '33.462293',
    longitude: '-85.338064',
    timeZoneId: 'America/Chicago',
  },
  '36264': {
    zip: '36264',
    city: 'Heflin',
    state: 'AL',
    latitude: '33.647368',
    longitude: '-85.584872',
    timeZoneId: 'America/Chicago',
  },
  '36265': {
    zip: '36265',
    city: 'Jacksonville',
    state: 'AL',
    latitude: '33.831372',
    longitude: '-85.779123',
    timeZoneId: 'America/Chicago',
  },
  '36266': {
    zip: '36266',
    city: 'Lineville',
    state: 'AL',
    latitude: '33.33702',
    longitude: '-85.72908',
    timeZoneId: 'America/Chicago',
  },
  '36267': {
    zip: '36267',
    city: 'Millerville',
    state: 'AL',
    latitude: '33.159088',
    longitude: '-85.961771',
    timeZoneId: 'America/Chicago',
  },
  '36268': {
    zip: '36268',
    city: 'Munford',
    state: 'AL',
    latitude: '33.528225',
    longitude: '-85.977131',
    timeZoneId: 'America/Chicago',
  },
  '36269': {
    zip: '36269',
    city: 'Muscadine',
    state: 'AL',
    latitude: '33.744532',
    longitude: '-85.379277',
    timeZoneId: 'America/Chicago',
  },
  '36271': {
    zip: '36271',
    city: 'Ohatchee',
    state: 'AL',
    latitude: '33.791846',
    longitude: '-86.011796',
    timeZoneId: 'America/Chicago',
  },
  '36272': {
    zip: '36272',
    city: 'Piedmont',
    state: 'AL',
    latitude: '33.916629',
    longitude: '-85.632561',
    timeZoneId: 'America/Chicago',
  },
  '36273': {
    zip: '36273',
    city: 'Ranburne',
    state: 'AL',
    latitude: '33.580874',
    longitude: '-85.395353',
    timeZoneId: 'America/Chicago',
  },
  '36274': {
    zip: '36274',
    city: 'Roanoke',
    state: 'AL',
    latitude: '33.152242',
    longitude: '-85.372945',
    timeZoneId: 'America/Chicago',
  },
  '36275': {
    zip: '36275',
    city: 'Spring Garden',
    state: 'AL',
    latitude: '33.975661',
    longitude: '-85.55592',
    timeZoneId: 'America/Chicago',
  },
  '36276': {
    zip: '36276',
    city: 'Wadley',
    state: 'AL',
    latitude: '33.1521',
    longitude: '-85.5618',
    timeZoneId: 'America/Chicago',
  },
  '36277': {
    zip: '36277',
    city: 'Weaver',
    state: 'AL',
    latitude: '33.753391',
    longitude: '-85.819377',
    timeZoneId: 'America/Chicago',
  },
  '36278': {
    zip: '36278',
    city: 'Wedowee',
    state: 'AL',
    latitude: '33.325381',
    longitude: '-85.506798',
    timeZoneId: 'America/Chicago',
  },
  '36279': {
    zip: '36279',
    city: 'Wellington',
    state: 'AL',
    latitude: '33.866411',
    longitude: '-85.887489',
    timeZoneId: 'America/Chicago',
  },
  '36280': {
    zip: '36280',
    city: 'Woodland',
    state: 'AL',
    latitude: '33.357551',
    longitude: '-85.348233',
    timeZoneId: 'America/Chicago',
  },
  '36301': {
    zip: '36301',
    city: 'Dothan',
    state: 'AL',
    latitude: '31.19982',
    longitude: '-85.41768',
    timeZoneId: 'America/Chicago',
  },
  '36302': {
    zip: '36302',
    city: 'Dothan',
    state: 'AL',
    latitude: '31.227185',
    longitude: '-85.395884',
    timeZoneId: 'America/Chicago',
  },
  '36303': {
    zip: '36303',
    city: 'Dothan',
    state: 'AL',
    latitude: '31.25424',
    longitude: '-85.3989',
    timeZoneId: 'America/Chicago',
  },
  '36304': {
    zip: '36304',
    city: 'Dothan',
    state: 'AL',
    latitude: '31.233884',
    longitude: '-85.403767',
    timeZoneId: 'America/Chicago',
  },
  '36305': {
    zip: '36305',
    city: 'Dothan',
    state: 'AL',
    latitude: '31.22247',
    longitude: '-85.390698',
    timeZoneId: 'America/Chicago',
  },
  '36310': {
    zip: '36310',
    city: 'Abbeville',
    state: 'AL',
    latitude: '31.55496',
    longitude: '-85.26546',
    timeZoneId: 'America/Chicago',
  },
  '36311': {
    zip: '36311',
    city: 'Ariton',
    state: 'AL',
    latitude: '31.600782',
    longitude: '-85.721325',
    timeZoneId: 'America/Chicago',
  },
  '36312': {
    zip: '36312',
    city: 'Ashford',
    state: 'AL',
    latitude: '31.188743',
    longitude: '-85.240935',
    timeZoneId: 'America/Chicago',
  },
  '36313': {
    zip: '36313',
    city: 'Bellwood',
    state: 'AL',
    latitude: '31.228197',
    longitude: '-85.759117',
    timeZoneId: 'America/Chicago',
  },
  '36314': {
    zip: '36314',
    city: 'Black',
    state: 'AL',
    latitude: '31.008169',
    longitude: '-85.746883',
    timeZoneId: 'America/Chicago',
  },
  '36316': {
    zip: '36316',
    city: 'Chancellor',
    state: 'AL',
    latitude: '31.178128',
    longitude: '-85.919169',
    timeZoneId: 'America/Chicago',
  },
  '36317': {
    zip: '36317',
    city: 'Clopton',
    state: 'AL',
    latitude: '31.598085',
    longitude: '-85.467166',
    timeZoneId: 'America/Chicago',
  },
  '36318': {
    zip: '36318',
    city: 'Coffee Springs',
    state: 'AL',
    latitude: '31.169648',
    longitude: '-85.910785',
    timeZoneId: 'America/Chicago',
  },
  '36319': {
    zip: '36319',
    city: 'Columbia',
    state: 'AL',
    latitude: '31.31538',
    longitude: '-85.12362',
    timeZoneId: 'America/Chicago',
  },
  '36320': {
    zip: '36320',
    city: 'Cottonwood',
    state: 'AL',
    latitude: '31.0758',
    longitude: '-85.30224',
    timeZoneId: 'America/Chicago',
  },
  '36321': {
    zip: '36321',
    city: 'Cowarts',
    state: 'AL',
    latitude: '31.199896',
    longitude: '-85.29932',
    timeZoneId: 'America/Chicago',
  },
  '36322': {
    zip: '36322',
    city: 'Daleville',
    state: 'AL',
    latitude: '31.28466',
    longitude: '-85.73034',
    timeZoneId: 'America/Chicago',
  },
  '36323': {
    zip: '36323',
    city: 'Elba',
    state: 'AL',
    latitude: '31.41692',
    longitude: '-86.075109',
    timeZoneId: 'America/Chicago',
  },
  '36330': {
    zip: '36330',
    city: 'Enterprise',
    state: 'AL',
    latitude: '31.32558',
    longitude: '-85.844682',
    timeZoneId: 'America/Chicago',
  },
  '36331': {
    zip: '36331',
    city: 'Enterprise',
    state: 'AL',
    latitude: '31.326065',
    longitude: '-85.854309',
    timeZoneId: 'America/Chicago',
  },
  '36340': {
    zip: '36340',
    city: 'Geneva',
    state: 'AL',
    latitude: '31.038911',
    longitude: '-85.879322',
    timeZoneId: 'America/Chicago',
  },
  '36343': {
    zip: '36343',
    city: 'Gordon',
    state: 'AL',
    latitude: '31.152278',
    longitude: '-85.075175',
    timeZoneId: 'America/Chicago',
  },
  '36344': {
    zip: '36344',
    city: 'Hartford',
    state: 'AL',
    latitude: '31.101347',
    longitude: '-85.700671',
    timeZoneId: 'America/Chicago',
  },
  '36345': {
    zip: '36345',
    city: 'Headland',
    state: 'AL',
    latitude: '31.354177',
    longitude: '-85.342013',
    timeZoneId: 'America/Chicago',
  },
  '36346': {
    zip: '36346',
    city: 'Jack',
    state: 'AL',
    latitude: '31.596944',
    longitude: '-85.913611',
    timeZoneId: 'America/Chicago',
  },
  '36349': {
    zip: '36349',
    city: 'Malvern',
    state: 'AL',
    latitude: '31.103492',
    longitude: '-85.602975',
    timeZoneId: 'America/Chicago',
  },
  '36350': {
    zip: '36350',
    city: 'Midland City',
    state: 'AL',
    latitude: '31.278807',
    longitude: '-85.501242',
    timeZoneId: 'America/Chicago',
  },
  '36351': {
    zip: '36351',
    city: 'New Brockton',
    state: 'AL',
    latitude: '31.382154',
    longitude: '-85.931082',
    timeZoneId: 'America/Chicago',
  },
  '36352': {
    zip: '36352',
    city: 'Newton',
    state: 'AL',
    latitude: '31.33138',
    longitude: '-85.601124',
    timeZoneId: 'America/Chicago',
  },
  '36353': {
    zip: '36353',
    city: 'Newville',
    state: 'AL',
    latitude: '31.43142',
    longitude: '-85.33116',
    timeZoneId: 'America/Chicago',
  },
  '36360': {
    zip: '36360',
    city: 'Ozark',
    state: 'AL',
    latitude: '31.443324',
    longitude: '-85.646911',
    timeZoneId: 'America/Chicago',
  },
  '36361': {
    zip: '36361',
    city: 'Ozark',
    state: 'AL',
    latitude: '31.454462',
    longitude: '-85.647963',
    timeZoneId: 'America/Chicago',
  },
  '36362': {
    zip: '36362',
    city: 'Fort Rucker',
    state: 'AL',
    latitude: '31.348835',
    longitude: '-85.719366',
    timeZoneId: 'America/Chicago',
  },
  '36370': {
    zip: '36370',
    city: 'Pansey',
    state: 'AL',
    latitude: '31.054891',
    longitude: '-85.30282',
    timeZoneId: 'America/Chicago',
  },
  '36371': {
    zip: '36371',
    city: 'Pinckard',
    state: 'AL',
    latitude: '31.398565',
    longitude: '-85.50329',
    timeZoneId: 'America/Chicago',
  },
  '36373': {
    zip: '36373',
    city: 'Shorterville',
    state: 'AL',
    latitude: '31.615301',
    longitude: '-85.146248',
    timeZoneId: 'America/Chicago',
  },
  '36374': {
    zip: '36374',
    city: 'Skipperville',
    state: 'AL',
    latitude: '31.558713',
    longitude: '-85.543523',
    timeZoneId: 'America/Chicago',
  },
  '36375': {
    zip: '36375',
    city: 'Slocomb',
    state: 'AL',
    latitude: '31.110702',
    longitude: '-85.590295',
    timeZoneId: 'America/Chicago',
  },
  '36376': {
    zip: '36376',
    city: 'Webb',
    state: 'AL',
    latitude: '31.200351',
    longitude: '-85.295977',
    timeZoneId: 'America/Chicago',
  },
  '36401': {
    zip: '36401',
    city: 'Evergreen',
    state: 'AL',
    latitude: '31.430309',
    longitude: '-86.956166',
    timeZoneId: 'America/Chicago',
  },
  '36420': {
    zip: '36420',
    city: 'Andalusia',
    state: 'AL',
    latitude: '31.23708',
    longitude: '-86.5758',
    timeZoneId: 'America/Chicago',
  },
  '36421': {
    zip: '36421',
    city: 'Andalusia',
    state: 'AL',
    latitude: '31.307991',
    longitude: '-86.476872',
    timeZoneId: 'America/Chicago',
  },
  '36425': {
    zip: '36425',
    city: 'Beatrice',
    state: 'AL',
    latitude: '31.746433',
    longitude: '-87.105274',
    timeZoneId: 'America/Chicago',
  },
  '36426': {
    zip: '36426',
    city: 'Brewton',
    state: 'AL',
    latitude: '31.209444',
    longitude: '-87.218333',
    timeZoneId: 'America/Chicago',
  },
  '36427': {
    zip: '36427',
    city: 'Brewton',
    state: 'AL',
    latitude: '31.107929',
    longitude: '-87.071149',
    timeZoneId: 'America/Chicago',
  },
  '36429': {
    zip: '36429',
    city: 'Brooklyn',
    state: 'AL',
    latitude: '31.49498',
    longitude: '-86.912259',
    timeZoneId: 'America/Chicago',
  },
  '36432': {
    zip: '36432',
    city: 'Castleberry',
    state: 'AL',
    latitude: '31.303173',
    longitude: '-87.025313',
    timeZoneId: 'America/Chicago',
  },
  '36435': {
    zip: '36435',
    city: 'Coy',
    state: 'AL',
    latitude: '31.891108',
    longitude: '-87.331404',
    timeZoneId: 'America/Chicago',
  },
  '36436': {
    zip: '36436',
    city: 'Dickinson',
    state: 'AL',
    latitude: '31.730014',
    longitude: '-87.651049',
    timeZoneId: 'America/Chicago',
  },
  '36439': {
    zip: '36439',
    city: 'Excel',
    state: 'AL',
    latitude: '31.393663',
    longitude: '-87.420921',
    timeZoneId: 'America/Chicago',
  },
  '36441': {
    zip: '36441',
    city: 'Flomaton',
    state: 'AL',
    latitude: '31.04064',
    longitude: '-87.24354',
    timeZoneId: 'America/Chicago',
  },
  '36442': {
    zip: '36442',
    city: 'Florala',
    state: 'AL',
    latitude: '31.002261',
    longitude: '-86.325796',
    timeZoneId: 'America/Chicago',
  },
  '36444': {
    zip: '36444',
    city: 'Franklin',
    state: 'AL',
    latitude: '31.72464',
    longitude: '-87.455369',
    timeZoneId: 'America/Chicago',
  },
  '36445': {
    zip: '36445',
    city: 'Frisco City',
    state: 'AL',
    latitude: '31.416123',
    longitude: '-87.417902',
    timeZoneId: 'America/Chicago',
  },
  '36446': {
    zip: '36446',
    city: 'Fulton',
    state: 'AL',
    latitude: '31.78666',
    longitude: '-87.647785',
    timeZoneId: 'America/Chicago',
  },
  '36449': {
    zip: '36449',
    city: 'Uriah',
    state: 'AL',
    latitude: '31.33662',
    longitude: '-87.42582',
    timeZoneId: 'America/Chicago',
  },
  '36451': {
    zip: '36451',
    city: 'Grove Hill',
    state: 'AL',
    latitude: '31.705769',
    longitude: '-87.780178',
    timeZoneId: 'America/Chicago',
  },
  '36453': {
    zip: '36453',
    city: 'Kinston',
    state: 'AL',
    latitude: '31.227293',
    longitude: '-86.153052',
    timeZoneId: 'America/Chicago',
  },
  '36454': {
    zip: '36454',
    city: 'Lenox',
    state: 'AL',
    latitude: '31.324078',
    longitude: '-87.197115',
    timeZoneId: 'America/Chicago',
  },
  '36455': {
    zip: '36455',
    city: 'Lockhart',
    state: 'AL',
    latitude: '31.050639',
    longitude: '-86.347063',
    timeZoneId: 'America/Chicago',
  },
  '36456': {
    zip: '36456',
    city: 'Mc Kenzie',
    state: 'AL',
    latitude: '31.54572',
    longitude: '-86.74392',
    timeZoneId: 'America/Chicago',
  },
  '36457': {
    zip: '36457',
    city: 'Megargel',
    state: 'AL',
    latitude: '31.394375',
    longitude: '-87.419431',
    timeZoneId: 'America/Chicago',
  },
  '36458': {
    zip: '36458',
    city: 'Mexia',
    state: 'AL',
    latitude: '31.394385',
    longitude: '-87.416332',
    timeZoneId: 'America/Chicago',
  },
  '36460': {
    zip: '36460',
    city: 'Monroeville',
    state: 'AL',
    latitude: '31.514812',
    longitude: '-87.327625',
    timeZoneId: 'America/Chicago',
  },
  '36461': {
    zip: '36461',
    city: 'Monroeville',
    state: 'AL',
    latitude: '31.52018',
    longitude: '-87.320717',
    timeZoneId: 'America/Chicago',
  },
  '36467': {
    zip: '36467',
    city: 'Opp',
    state: 'AL',
    latitude: '31.2855',
    longitude: '-86.26476',
    timeZoneId: 'America/Chicago',
  },
  '36470': {
    zip: '36470',
    city: 'Perdue Hill',
    state: 'AL',
    latitude: '31.525703',
    longitude: '-87.324291',
    timeZoneId: 'America/Chicago',
  },
  '36471': {
    zip: '36471',
    city: 'Peterman',
    state: 'AL',
    latitude: '31.607468',
    longitude: '-87.26385',
    timeZoneId: 'America/Chicago',
  },
  '36473': {
    zip: '36473',
    city: 'Range',
    state: 'AL',
    latitude: '31.308807',
    longitude: '-87.306658',
    timeZoneId: 'America/Chicago',
  },
  '36474': {
    zip: '36474',
    city: 'Red Level',
    state: 'AL',
    latitude: '31.456303',
    longitude: '-86.602931',
    timeZoneId: 'America/Chicago',
  },
  '36475': {
    zip: '36475',
    city: 'Repton',
    state: 'AL',
    latitude: '31.43148',
    longitude: '-87.1893',
    timeZoneId: 'America/Chicago',
  },
  '36476': {
    zip: '36476',
    city: 'River Falls',
    state: 'AL',
    latitude: '31.30161',
    longitude: '-86.622849',
    timeZoneId: 'America/Chicago',
  },
  '36477': {
    zip: '36477',
    city: 'Samson',
    state: 'AL',
    latitude: '31.109288',
    longitude: '-86.048186',
    timeZoneId: 'America/Chicago',
  },
  '36480': {
    zip: '36480',
    city: 'Uriah',
    state: 'AL',
    latitude: '31.32658',
    longitude: '-87.605287',
    timeZoneId: 'America/Chicago',
  },
  '36481': {
    zip: '36481',
    city: 'Vredenburgh',
    state: 'AL',
    latitude: '31.823614',
    longitude: '-87.310611',
    timeZoneId: 'America/Chicago',
  },
  '36482': {
    zip: '36482',
    city: 'Whatley',
    state: 'AL',
    latitude: '31.651737',
    longitude: '-87.627073',
    timeZoneId: 'America/Chicago',
  },
  '36483': {
    zip: '36483',
    city: 'Wing',
    state: 'AL',
    latitude: '31.123075',
    longitude: '-86.584525',
    timeZoneId: 'America/Chicago',
  },
  '36502': {
    zip: '36502',
    city: 'Atmore',
    state: 'AL',
    latitude: '31.021667',
    longitude: '-87.484959',
    timeZoneId: 'America/Chicago',
  },
  '36503': {
    zip: '36503',
    city: 'Atmore',
    state: 'AL',
    latitude: '31.022934',
    longitude: '-87.493385',
    timeZoneId: 'America/Chicago',
  },
  '36504': {
    zip: '36504',
    city: 'Atmore',
    state: 'AL',
    latitude: '31.019446',
    longitude: '-87.479547',
    timeZoneId: 'America/Chicago',
  },
  '36505': {
    zip: '36505',
    city: 'Axis',
    state: 'AL',
    latitude: '30.903562',
    longitude: '-88.04794',
    timeZoneId: 'America/Chicago',
  },
  '36507': {
    zip: '36507',
    city: 'Bay Minette',
    state: 'AL',
    latitude: '30.874562',
    longitude: '-87.775538',
    timeZoneId: 'America/Chicago',
  },
  '36509': {
    zip: '36509',
    city: 'Bayou La Batre',
    state: 'AL',
    latitude: '30.403859',
    longitude: '-88.246081',
    timeZoneId: 'America/Chicago',
  },
  '36511': {
    zip: '36511',
    city: 'Bon Secour',
    state: 'AL',
    latitude: '30.314881',
    longitude: '-87.725251',
    timeZoneId: 'America/Chicago',
  },
  '36512': {
    zip: '36512',
    city: 'Bucks',
    state: 'AL',
    latitude: '31.089663',
    longitude: '-88.045174',
    timeZoneId: 'America/Chicago',
  },
  '36513': {
    zip: '36513',
    city: 'Calvert',
    state: 'AL',
    latitude: '30.80223',
    longitude: '-88.234419',
    timeZoneId: 'America/Chicago',
  },
  '36518': {
    zip: '36518',
    city: 'Chatom',
    state: 'AL',
    latitude: '31.465242',
    longitude: '-88.237087',
    timeZoneId: 'America/Chicago',
  },
  '36521': {
    zip: '36521',
    city: 'Chunchula',
    state: 'AL',
    latitude: '30.97422',
    longitude: '-88.12686',
    timeZoneId: 'America/Chicago',
  },
  '36522': {
    zip: '36522',
    city: 'Citronelle',
    state: 'AL',
    latitude: '31.072363',
    longitude: '-88.264023',
    timeZoneId: 'America/Chicago',
  },
  '36523': {
    zip: '36523',
    city: 'Coden',
    state: 'AL',
    latitude: '30.387102',
    longitude: '-88.167787',
    timeZoneId: 'America/Chicago',
  },
  '36524': {
    zip: '36524',
    city: 'Coffeeville',
    state: 'AL',
    latitude: '31.791771',
    longitude: '-88.066849',
    timeZoneId: 'America/Chicago',
  },
  '36525': {
    zip: '36525',
    city: 'Creola',
    state: 'AL',
    latitude: '30.889269',
    longitude: '-88.026134',
    timeZoneId: 'America/Chicago',
  },
  '36526': {
    zip: '36526',
    city: 'Daphne',
    state: 'AL',
    latitude: '30.622635',
    longitude: '-87.891959',
    timeZoneId: 'America/Chicago',
  },
  '36527': {
    zip: '36527',
    city: 'Daphne',
    state: 'AL',
    latitude: '30.68237',
    longitude: '-87.868223',
    timeZoneId: 'America/Chicago',
  },
  '36528': {
    zip: '36528',
    city: 'Dauphin Island',
    state: 'AL',
    latitude: '30.250952',
    longitude: '-88.12861',
    timeZoneId: 'America/Chicago',
  },
  '36529': {
    zip: '36529',
    city: 'Deer Park',
    state: 'AL',
    latitude: '31.321111',
    longitude: '-88.188611',
    timeZoneId: 'America/Chicago',
  },
  '36530': {
    zip: '36530',
    city: 'Elberta',
    state: 'AL',
    latitude: '30.40578',
    longitude: '-87.58638',
    timeZoneId: 'America/Chicago',
  },
  '36532': {
    zip: '36532',
    city: 'Fairhope',
    state: 'AL',
    latitude: '30.50025',
    longitude: '-87.868968',
    timeZoneId: 'America/Chicago',
  },
  '36533': {
    zip: '36533',
    city: 'Fairhope',
    state: 'AL',
    latitude: '30.491518',
    longitude: '-87.855545',
    timeZoneId: 'America/Chicago',
  },
  '36535': {
    zip: '36535',
    city: 'Foley',
    state: 'AL',
    latitude: '30.398083',
    longitude: '-87.704195',
    timeZoneId: 'America/Chicago',
  },
  '36536': {
    zip: '36536',
    city: 'Foley',
    state: 'AL',
    latitude: '30.403695',
    longitude: '-87.658883',
    timeZoneId: 'America/Chicago',
  },
  '36538': {
    zip: '36538',
    city: 'Frankville',
    state: 'AL',
    latitude: '31.655932',
    longitude: '-88.133041',
    timeZoneId: 'America/Chicago',
  },
  '36539': {
    zip: '36539',
    city: 'Fruitdale',
    state: 'AL',
    latitude: '31.326277',
    longitude: '-88.315502',
    timeZoneId: 'America/Chicago',
  },
  '36540': {
    zip: '36540',
    city: 'Gainestown',
    state: 'AL',
    latitude: '31.424852',
    longitude: '-87.673723',
    timeZoneId: 'America/Chicago',
  },
  '36541': {
    zip: '36541',
    city: 'Grand Bay',
    state: 'AL',
    latitude: '30.490561',
    longitude: '-88.330633',
    timeZoneId: 'America/Chicago',
  },
  '36542': {
    zip: '36542',
    city: 'Gulf Shores',
    state: 'AL',
    latitude: '30.274095',
    longitude: '-87.715267',
    timeZoneId: 'America/Chicago',
  },
  '36543': {
    zip: '36543',
    city: 'Huxford',
    state: 'AL',
    latitude: '31.21703',
    longitude: '-87.461165',
    timeZoneId: 'America/Chicago',
  },
  '36544': {
    zip: '36544',
    city: 'Irvington',
    state: 'AL',
    latitude: '30.482569',
    longitude: '-88.230815',
    timeZoneId: 'America/Chicago',
  },
  '36545': {
    zip: '36545',
    city: 'Jackson',
    state: 'AL',
    latitude: '31.527175',
    longitude: '-87.891357',
    timeZoneId: 'America/Chicago',
  },
  '36547': {
    zip: '36547',
    city: 'Gulf Shores',
    state: 'AL',
    latitude: '30.24614',
    longitude: '-87.699911',
    timeZoneId: 'America/Chicago',
  },
  '36548': {
    zip: '36548',
    city: 'Leroy',
    state: 'AL',
    latitude: '31.516479',
    longitude: '-87.896552',
    timeZoneId: 'America/Chicago',
  },
  '36549': {
    zip: '36549',
    city: 'Lillian',
    state: 'AL',
    latitude: '30.383906',
    longitude: '-87.461113',
    timeZoneId: 'America/Chicago',
  },
  '36550': {
    zip: '36550',
    city: 'Little River',
    state: 'AL',
    latitude: '31.216909',
    longitude: '-87.793024',
    timeZoneId: 'America/Chicago',
  },
  '36551': {
    zip: '36551',
    city: 'Loxley',
    state: 'AL',
    latitude: '30.615589',
    longitude: '-87.751521',
    timeZoneId: 'America/Chicago',
  },
  '36553': {
    zip: '36553',
    city: 'Mc Intosh',
    state: 'AL',
    latitude: '31.232711',
    longitude: '-88.065729',
    timeZoneId: 'America/Chicago',
  },
  '36555': {
    zip: '36555',
    city: 'Magnolia Springs',
    state: 'AL',
    latitude: '30.399119',
    longitude: '-87.787327',
    timeZoneId: 'America/Chicago',
  },
  '36556': {
    zip: '36556',
    city: 'Malcolm',
    state: 'AL',
    latitude: '31.232074',
    longitude: '-88.06339',
    timeZoneId: 'America/Chicago',
  },
  '36558': {
    zip: '36558',
    city: 'Millry',
    state: 'AL',
    latitude: '31.627372',
    longitude: '-88.352891',
    timeZoneId: 'America/Chicago',
  },
  '36559': {
    zip: '36559',
    city: 'Montrose',
    state: 'AL',
    latitude: '30.565959',
    longitude: '-87.901959',
    timeZoneId: 'America/Chicago',
  },
  '36560': {
    zip: '36560',
    city: 'Mount Vernon',
    state: 'AL',
    latitude: '31.06938',
    longitude: '-88.05312',
    timeZoneId: 'America/Chicago',
  },
  '36561': {
    zip: '36561',
    city: 'Orange Beach',
    state: 'AL',
    latitude: '30.296786',
    longitude: '-87.548627',
    timeZoneId: 'America/Chicago',
  },
  '36562': {
    zip: '36562',
    city: 'Perdido',
    state: 'AL',
    latitude: '31.009821',
    longitude: '-87.65899',
    timeZoneId: 'America/Chicago',
  },
  '36564': {
    zip: '36564',
    city: 'Point Clear',
    state: 'AL',
    latitude: '30.477081',
    longitude: '-87.920025',
    timeZoneId: 'America/Chicago',
  },
  '36567': {
    zip: '36567',
    city: 'Robertsdale',
    state: 'AL',
    latitude: '30.5694',
    longitude: '-87.63072',
    timeZoneId: 'America/Chicago',
  },
  '36568': {
    zip: '36568',
    city: 'Saint Elmo',
    state: 'AL',
    latitude: '30.496356',
    longitude: '-88.28462',
    timeZoneId: 'America/Chicago',
  },
  '36569': {
    zip: '36569',
    city: 'Saint Stephens',
    state: 'AL',
    latitude: '31.542499',
    longitude: '-88.050964',
    timeZoneId: 'America/Chicago',
  },
  '36571': {
    zip: '36571',
    city: 'Saraland',
    state: 'AL',
    latitude: '30.822782',
    longitude: '-88.095227',
    timeZoneId: 'America/Chicago',
  },
  '36572': {
    zip: '36572',
    city: 'Satsuma',
    state: 'AL',
    latitude: '30.854788',
    longitude: '-88.056897',
    timeZoneId: 'America/Chicago',
  },
  '36574': {
    zip: '36574',
    city: 'Seminole',
    state: 'AL',
    latitude: '30.5097',
    longitude: '-87.48588',
    timeZoneId: 'America/Chicago',
  },
  '36575': {
    zip: '36575',
    city: 'Semmes',
    state: 'AL',
    latitude: '30.757651',
    longitude: '-88.266909',
    timeZoneId: 'America/Chicago',
  },
  '36576': {
    zip: '36576',
    city: 'Silverhill',
    state: 'AL',
    latitude: '30.533306',
    longitude: '-87.761816',
    timeZoneId: 'America/Chicago',
  },
  '36577': {
    zip: '36577',
    city: 'Spanish Fort',
    state: 'AL',
    latitude: '30.67444',
    longitude: '-87.884145',
    timeZoneId: 'America/Chicago',
  },
  '36578': {
    zip: '36578',
    city: 'Stapleton',
    state: 'AL',
    latitude: '30.743444',
    longitude: '-87.78815',
    timeZoneId: 'America/Chicago',
  },
  '36579': {
    zip: '36579',
    city: 'Stockton',
    state: 'AL',
    latitude: '31.085651',
    longitude: '-87.824349',
    timeZoneId: 'America/Chicago',
  },
  '36580': {
    zip: '36580',
    city: 'Summerdale',
    state: 'AL',
    latitude: '30.47885',
    longitude: '-87.720472',
    timeZoneId: 'America/Chicago',
  },
  '36581': {
    zip: '36581',
    city: 'Sunflower',
    state: 'AL',
    latitude: '31.393774',
    longitude: '-88.06227',
    timeZoneId: 'America/Chicago',
  },
  '36582': {
    zip: '36582',
    city: 'Theodore',
    state: 'AL',
    latitude: '30.532137',
    longitude: '-88.179287',
    timeZoneId: 'America/Chicago',
  },
  '36583': {
    zip: '36583',
    city: 'Tibbie',
    state: 'AL',
    latitude: '31.381734',
    longitude: '-88.265038',
    timeZoneId: 'America/Chicago',
  },
  '36584': {
    zip: '36584',
    city: 'Vinegar Bend',
    state: 'AL',
    latitude: '31.255248',
    longitude: '-88.393313',
    timeZoneId: 'America/Chicago',
  },
  '36585': {
    zip: '36585',
    city: 'Wagarville',
    state: 'AL',
    latitude: '31.392178',
    longitude: '-88.062461',
    timeZoneId: 'America/Chicago',
  },
  '36587': {
    zip: '36587',
    city: 'Wilmer',
    state: 'AL',
    latitude: '30.815411',
    longitude: '-88.330567',
    timeZoneId: 'America/Chicago',
  },
  '36590': {
    zip: '36590',
    city: 'Theodore',
    state: 'AL',
    latitude: '30.533459',
    longitude: '-88.184687',
    timeZoneId: 'America/Chicago',
  },
  '36601': {
    zip: '36601',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.69745',
    longitude: '-88.049878',
    timeZoneId: 'America/Chicago',
  },
  '36602': {
    zip: '36602',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.692404',
    longitude: '-88.046843',
    timeZoneId: 'America/Chicago',
  },
  '36603': {
    zip: '36603',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.689828',
    longitude: '-88.055657',
    timeZoneId: 'America/Chicago',
  },
  '36604': {
    zip: '36604',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.683282',
    longitude: '-88.069544',
    timeZoneId: 'America/Chicago',
  },
  '36605': {
    zip: '36605',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.627381',
    longitude: '-88.085652',
    timeZoneId: 'America/Chicago',
  },
  '36606': {
    zip: '36606',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.671284',
    longitude: '-88.102989',
    timeZoneId: 'America/Chicago',
  },
  '36607': {
    zip: '36607',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.698011',
    longitude: '-88.10196',
    timeZoneId: 'America/Chicago',
  },
  '36608': {
    zip: '36608',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.692961',
    longitude: '-88.194383',
    timeZoneId: 'America/Chicago',
  },
  '36609': {
    zip: '36609',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.646418',
    longitude: '-88.171305',
    timeZoneId: 'America/Chicago',
  },
  '36610': {
    zip: '36610',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.739731',
    longitude: '-88.080296',
    timeZoneId: 'America/Chicago',
  },
  '36611': {
    zip: '36611',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.7278',
    longitude: '-88.13898',
    timeZoneId: 'America/Chicago',
  },
  '36612': {
    zip: '36612',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.75129',
    longitude: '-88.110308',
    timeZoneId: 'America/Chicago',
  },
  '36613': {
    zip: '36613',
    city: 'Eight Mile',
    state: 'AL',
    latitude: '30.83448',
    longitude: '-88.19424',
    timeZoneId: 'America/Chicago',
  },
  '36615': {
    zip: '36615',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.628884',
    longitude: '-88.072632',
    timeZoneId: 'America/Chicago',
  },
  '36616': {
    zip: '36616',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.667396',
    longitude: '-88.127812',
    timeZoneId: 'America/Chicago',
  },
  '36617': {
    zip: '36617',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.715635',
    longitude: '-88.087882',
    timeZoneId: 'America/Chicago',
  },
  '36618': {
    zip: '36618',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.731393',
    longitude: '-88.170447',
    timeZoneId: 'America/Chicago',
  },
  '36619': {
    zip: '36619',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.593684',
    longitude: '-88.184484',
    timeZoneId: 'America/Chicago',
  },
  '36628': {
    zip: '36628',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.693855',
    longitude: '-88.045183',
    timeZoneId: 'America/Chicago',
  },
  '36633': {
    zip: '36633',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.670807',
    longitude: '-88.106466',
    timeZoneId: 'America/Chicago',
  },
  '36640': {
    zip: '36640',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.681274',
    longitude: '-88.067832',
    timeZoneId: 'America/Chicago',
  },
  '36641': {
    zip: '36641',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.682699',
    longitude: '-88.068789',
    timeZoneId: 'America/Chicago',
  },
  '36644': {
    zip: '36644',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.694297',
    longitude: '-88.041917',
    timeZoneId: 'America/Chicago',
  },
  '36652': {
    zip: '36652',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.6959',
    longitude: '-88.043034',
    timeZoneId: 'America/Chicago',
  },
  '36660': {
    zip: '36660',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.674301',
    longitude: '-88.087731',
    timeZoneId: 'America/Chicago',
  },
  '36663': {
    zip: '36663',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.762053',
    longitude: '-88.128625',
    timeZoneId: 'America/Chicago',
  },
  '36670': {
    zip: '36670',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.700055',
    longitude: '-88.108118',
    timeZoneId: 'America/Chicago',
  },
  '36671': {
    zip: '36671',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.775281',
    longitude: '-88.081056',
    timeZoneId: 'America/Chicago',
  },
  '36675': {
    zip: '36675',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.695546',
    longitude: '-88.046939',
    timeZoneId: 'America/Chicago',
  },
  '36685': {
    zip: '36685',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.690171',
    longitude: '-88.297518',
    timeZoneId: 'America/Chicago',
  },
  '36688': {
    zip: '36688',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.688966',
    longitude: '-88.189832',
    timeZoneId: 'America/Chicago',
  },
  '36689': {
    zip: '36689',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.688637',
    longitude: '-88.169832',
    timeZoneId: 'America/Chicago',
  },
  '36691': {
    zip: '36691',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.691197',
    longitude: '-88.042054',
    timeZoneId: 'America/Chicago',
  },
  '36693': {
    zip: '36693',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.631691',
    longitude: '-88.156439',
    timeZoneId: 'America/Chicago',
  },
  '36695': {
    zip: '36695',
    city: 'Mobile',
    state: 'AL',
    latitude: '30.647608',
    longitude: '-88.237461',
    timeZoneId: 'America/Chicago',
  },
  '36701': {
    zip: '36701',
    city: 'Selma',
    state: 'AL',
    latitude: '32.413447',
    longitude: '-87.027314',
    timeZoneId: 'America/Chicago',
  },
  '36702': {
    zip: '36702',
    city: 'Selma',
    state: 'AL',
    latitude: '32.406235',
    longitude: '-87.022431',
    timeZoneId: 'America/Chicago',
  },
  '36703': {
    zip: '36703',
    city: 'Selma',
    state: 'AL',
    latitude: '32.420765',
    longitude: '-87.007289',
    timeZoneId: 'America/Chicago',
  },
  '36720': {
    zip: '36720',
    city: 'Alberta',
    state: 'AL',
    latitude: '32.114094',
    longitude: '-87.326683',
    timeZoneId: 'America/Chicago',
  },
  '36722': {
    zip: '36722',
    city: 'Arlington',
    state: 'AL',
    latitude: '32.04471',
    longitude: '-87.58582',
    timeZoneId: 'America/Chicago',
  },
  '36723': {
    zip: '36723',
    city: 'Boykin',
    state: 'AL',
    latitude: '32.079748',
    longitude: '-87.281135',
    timeZoneId: 'America/Chicago',
  },
  '36726': {
    zip: '36726',
    city: 'Camden',
    state: 'AL',
    latitude: '31.996605',
    longitude: '-87.296969',
    timeZoneId: 'America/Chicago',
  },
  '36727': {
    zip: '36727',
    city: 'Campbell',
    state: 'AL',
    latitude: '31.966651',
    longitude: '-88.007603',
    timeZoneId: 'America/Chicago',
  },
  '36728': {
    zip: '36728',
    city: 'Catherine',
    state: 'AL',
    latitude: '32.201926',
    longitude: '-87.481239',
    timeZoneId: 'America/Chicago',
  },
  '36732': {
    zip: '36732',
    city: 'Demopolis',
    state: 'AL',
    latitude: '32.508129',
    longitude: '-87.836342',
    timeZoneId: 'America/Chicago',
  },
  '36736': {
    zip: '36736',
    city: 'Dixons Mills',
    state: 'AL',
    latitude: '32.054938',
    longitude: '-87.730751',
    timeZoneId: 'America/Chicago',
  },
  '36738': {
    zip: '36738',
    city: 'Faunsdale',
    state: 'AL',
    latitude: '32.405885',
    longitude: '-87.61244',
    timeZoneId: 'America/Chicago',
  },
  '36740': {
    zip: '36740',
    city: 'Forkland',
    state: 'AL',
    latitude: '32.654245',
    longitude: '-87.872492',
    timeZoneId: 'America/Chicago',
  },
  '36741': {
    zip: '36741',
    city: 'Furman',
    state: 'AL',
    latitude: '32.002943',
    longitude: '-86.962196',
    timeZoneId: 'America/Chicago',
  },
  '36742': {
    zip: '36742',
    city: 'Gallion',
    state: 'AL',
    latitude: '32.541822',
    longitude: '-87.706622',
    timeZoneId: 'America/Chicago',
  },
  '36744': {
    zip: '36744',
    city: 'Greensboro',
    state: 'AL',
    latitude: '32.702746',
    longitude: '-87.601343',
    timeZoneId: 'America/Chicago',
  },
  '36745': {
    zip: '36745',
    city: 'Jefferson',
    state: 'AL',
    latitude: '32.387543',
    longitude: '-87.902054',
    timeZoneId: 'America/Chicago',
  },
  '36748': {
    zip: '36748',
    city: 'Linden',
    state: 'AL',
    latitude: '32.306',
    longitude: '-87.793801',
    timeZoneId: 'America/Chicago',
  },
  '36749': {
    zip: '36749',
    city: 'Jones',
    state: 'AL',
    latitude: '32.476828',
    longitude: '-86.848059',
    timeZoneId: 'America/Chicago',
  },
  '36750': {
    zip: '36750',
    city: 'Maplesville',
    state: 'AL',
    latitude: '32.790938',
    longitude: '-86.872036',
    timeZoneId: 'America/Chicago',
  },
  '36751': {
    zip: '36751',
    city: 'Lower Peach Tree',
    state: 'AL',
    latitude: '31.848577',
    longitude: '-87.56957',
    timeZoneId: 'America/Chicago',
  },
  '36752': {
    zip: '36752',
    city: 'Lowndesboro',
    state: 'AL',
    latitude: '32.31012',
    longitude: '-86.6523',
    timeZoneId: 'America/Chicago',
  },
  '36753': {
    zip: '36753',
    city: 'Mc Williams',
    state: 'AL',
    latitude: '31.830584',
    longitude: '-87.095985',
    timeZoneId: 'America/Chicago',
  },
  '36754': {
    zip: '36754',
    city: 'Magnolia',
    state: 'AL',
    latitude: '32.14029',
    longitude: '-87.716683',
    timeZoneId: 'America/Chicago',
  },
  '36756': {
    zip: '36756',
    city: 'Marion',
    state: 'AL',
    latitude: '32.67417',
    longitude: '-87.22309',
    timeZoneId: 'America/Chicago',
  },
  '36758': {
    zip: '36758',
    city: 'Plantersville',
    state: 'AL',
    latitude: '32.597755',
    longitude: '-86.944319',
    timeZoneId: 'America/Chicago',
  },
  '36759': {
    zip: '36759',
    city: 'Marion Junction',
    state: 'AL',
    latitude: '32.44086',
    longitude: '-87.34908',
    timeZoneId: 'America/Chicago',
  },
  '36761': {
    zip: '36761',
    city: 'Minter',
    state: 'AL',
    latitude: '32.086912',
    longitude: '-87.016816',
    timeZoneId: 'America/Chicago',
  },
  '36763': {
    zip: '36763',
    city: 'Myrtlewood',
    state: 'AL',
    latitude: '32.248322',
    longitude: '-87.949345',
    timeZoneId: 'America/Chicago',
  },
  '36764': {
    zip: '36764',
    city: 'Nanafalia',
    state: 'AL',
    latitude: '32.111618',
    longitude: '-87.986397',
    timeZoneId: 'America/Chicago',
  },
  '36765': {
    zip: '36765',
    city: 'Newbern',
    state: 'AL',
    latitude: '32.546',
    longitude: '-87.586759',
    timeZoneId: 'America/Chicago',
  },
  '36766': {
    zip: '36766',
    city: 'Oak Hill',
    state: 'AL',
    latitude: '31.921413',
    longitude: '-87.086669',
    timeZoneId: 'America/Chicago',
  },
  '36767': {
    zip: '36767',
    city: 'Orrville',
    state: 'AL',
    latitude: '32.319938',
    longitude: '-87.10016',
    timeZoneId: 'America/Chicago',
  },
  '36768': {
    zip: '36768',
    city: 'Pine Apple',
    state: 'AL',
    latitude: '31.936892',
    longitude: '-87.00888',
    timeZoneId: 'America/Chicago',
  },
  '36769': {
    zip: '36769',
    city: 'Pine Hill',
    state: 'AL',
    latitude: '31.949033',
    longitude: '-87.557784',
    timeZoneId: 'America/Chicago',
  },
  '36773': {
    zip: '36773',
    city: 'Safford',
    state: 'AL',
    latitude: '32.283499',
    longitude: '-87.417631',
    timeZoneId: 'America/Chicago',
  },
  '36775': {
    zip: '36775',
    city: 'Sardis',
    state: 'AL',
    latitude: '32.267832',
    longitude: '-86.98624',
    timeZoneId: 'America/Chicago',
  },
  '36776': {
    zip: '36776',
    city: 'Sawyerville',
    state: 'AL',
    latitude: '32.705802',
    longitude: '-87.599073',
    timeZoneId: 'America/Chicago',
  },
  '36782': {
    zip: '36782',
    city: 'Sweet Water',
    state: 'AL',
    latitude: '32.082428',
    longitude: '-87.944688',
    timeZoneId: 'America/Chicago',
  },
  '36783': {
    zip: '36783',
    city: 'Thomaston',
    state: 'AL',
    latitude: '32.268145',
    longitude: '-87.627712',
    timeZoneId: 'America/Chicago',
  },
  '36784': {
    zip: '36784',
    city: 'Thomasville',
    state: 'AL',
    latitude: '31.892574',
    longitude: '-87.74813',
    timeZoneId: 'America/Chicago',
  },
  '36785': {
    zip: '36785',
    city: 'Tyler',
    state: 'AL',
    latitude: '32.269183',
    longitude: '-86.852148',
    timeZoneId: 'America/Chicago',
  },
  '36786': {
    zip: '36786',
    city: 'Uniontown',
    state: 'AL',
    latitude: '32.459293',
    longitude: '-87.464556',
    timeZoneId: 'America/Chicago',
  },
  '36790': {
    zip: '36790',
    city: 'Stanton',
    state: 'AL',
    latitude: '32.707163',
    longitude: '-86.917886',
    timeZoneId: 'America/Chicago',
  },
  '36792': {
    zip: '36792',
    city: 'Randolph',
    state: 'AL',
    latitude: '32.890298',
    longitude: '-86.904543',
    timeZoneId: 'America/Chicago',
  },
  '36793': {
    zip: '36793',
    city: 'Lawley',
    state: 'AL',
    latitude: '32.865756',
    longitude: '-86.963491',
    timeZoneId: 'America/Chicago',
  },
  '36801': {
    zip: '36801',
    city: 'Opelika',
    state: 'AL',
    latitude: '32.640621',
    longitude: '-85.382583',
    timeZoneId: 'America/Chicago',
  },
  '36802': {
    zip: '36802',
    city: 'Opelika',
    state: 'AL',
    latitude: '32.594907',
    longitude: '-85.314202',
    timeZoneId: 'America/Chicago',
  },
  '36803': {
    zip: '36803',
    city: 'Opelika',
    state: 'AL',
    latitude: '32.645558',
    longitude: '-85.374434',
    timeZoneId: 'America/Chicago',
  },
  '36804': {
    zip: '36804',
    city: 'Opelika',
    state: 'AL',
    latitude: '32.657875',
    longitude: '-85.377323',
    timeZoneId: 'America/Chicago',
  },
  '36830': {
    zip: '36830',
    city: 'Auburn',
    state: 'AL',
    latitude: '32.599446',
    longitude: '-85.473449',
    timeZoneId: 'America/Chicago',
  },
  '36831': {
    zip: '36831',
    city: 'Auburn',
    state: 'AL',
    latitude: '32.606785',
    longitude: '-85.480344',
    timeZoneId: 'America/Chicago',
  },
  '36832': {
    zip: '36832',
    city: 'Auburn',
    state: 'AL',
    latitude: '32.59325',
    longitude: '-85.479363',
    timeZoneId: 'America/Chicago',
  },
  '36849': {
    zip: '36849',
    city: 'Auburn University',
    state: 'AL',
    latitude: '32.602139',
    longitude: '-85.508496',
    timeZoneId: 'America/Chicago',
  },
  '36850': {
    zip: '36850',
    city: 'Camp Hill',
    state: 'AL',
    latitude: '32.896297',
    longitude: '-85.575854',
    timeZoneId: 'America/Chicago',
  },
  '36851': {
    zip: '36851',
    city: 'Cottonton',
    state: 'AL',
    latitude: '32.145972',
    longitude: '-85.063402',
    timeZoneId: 'America/Chicago',
  },
  '36852': {
    zip: '36852',
    city: 'Cusseta',
    state: 'AL',
    latitude: '32.759234',
    longitude: '-85.241701',
    timeZoneId: 'America/Chicago',
  },
  '36853': {
    zip: '36853',
    city: 'Dadeville',
    state: 'AL',
    latitude: '32.826979',
    longitude: '-85.765591',
    timeZoneId: 'America/Chicago',
  },
  '36854': {
    zip: '36854',
    city: 'Valley',
    state: 'AL',
    latitude: '32.810093',
    longitude: '-85.181624',
    timeZoneId: 'America/Chicago',
  },
  '36855': {
    zip: '36855',
    city: 'Five Points',
    state: 'AL',
    latitude: '33.049589',
    longitude: '-85.270722',
    timeZoneId: 'America/Chicago',
  },
  '36856': {
    zip: '36856',
    city: 'Fort Mitchell',
    state: 'AL',
    latitude: '32.339501',
    longitude: '-85.020268',
    timeZoneId: 'America/Chicago',
  },
  '36858': {
    zip: '36858',
    city: 'Hatchechubbee',
    state: 'AL',
    latitude: '32.223068',
    longitude: '-85.239841',
    timeZoneId: 'America/Chicago',
  },
  '36859': {
    zip: '36859',
    city: 'Holy Trinity',
    state: 'AL',
    latitude: '32.170732',
    longitude: '-85.128717',
    timeZoneId: 'America/Chicago',
  },
  '36860': {
    zip: '36860',
    city: 'Hurtsboro',
    state: 'AL',
    latitude: '32.236282',
    longitude: '-85.417164',
    timeZoneId: 'America/Chicago',
  },
  '36861': {
    zip: '36861',
    city: 'Jacksons Gap',
    state: 'AL',
    latitude: '32.88206',
    longitude: '-85.825889',
    timeZoneId: 'America/Chicago',
  },
  '36862': {
    zip: '36862',
    city: 'Lafayette',
    state: 'AL',
    latitude: '32.897003',
    longitude: '-85.435404',
    timeZoneId: 'America/Chicago',
  },
  '36863': {
    zip: '36863',
    city: 'Lanett',
    state: 'AL',
    latitude: '32.868458',
    longitude: '-85.239722',
    timeZoneId: 'America/Chicago',
  },
  '36865': {
    zip: '36865',
    city: 'Loachapoka',
    state: 'AL',
    latitude: '32.616958',
    longitude: '-85.600678',
    timeZoneId: 'America/Chicago',
  },
  '36866': {
    zip: '36866',
    city: 'Notasulga',
    state: 'AL',
    latitude: '32.557951',
    longitude: '-85.662068',
    timeZoneId: 'America/Chicago',
  },
  '36867': {
    zip: '36867',
    city: 'Phenix City',
    state: 'AL',
    latitude: '32.473217',
    longitude: '-85.01999',
    timeZoneId: 'America/Chicago',
  },
  '36868': {
    zip: '36868',
    city: 'Phenix City',
    state: 'AL',
    latitude: '32.450321',
    longitude: '-85.008861',
    timeZoneId: 'America/Chicago',
  },
  '36869': {
    zip: '36869',
    city: 'Phenix City',
    state: 'AL',
    latitude: '32.443587',
    longitude: '-85.024696',
    timeZoneId: 'America/Chicago',
  },
  '36870': {
    zip: '36870',
    city: 'Phenix City',
    state: 'AL',
    latitude: '32.467449',
    longitude: '-85.011553',
    timeZoneId: 'America/Chicago',
  },
  '36871': {
    zip: '36871',
    city: 'Pittsview',
    state: 'AL',
    latitude: '32.188403',
    longitude: '-85.223329',
    timeZoneId: 'America/Chicago',
  },
  '36872': {
    zip: '36872',
    city: 'Valley',
    state: 'AL',
    latitude: '32.81741',
    longitude: '-85.17337',
    timeZoneId: 'America/Chicago',
  },
  '36874': {
    zip: '36874',
    city: 'Salem',
    state: 'AL',
    latitude: '32.537244',
    longitude: '-85.220329',
    timeZoneId: 'America/Chicago',
  },
  '36875': {
    zip: '36875',
    city: 'Seale',
    state: 'AL',
    latitude: '32.336199',
    longitude: '-85.019591',
    timeZoneId: 'America/Chicago',
  },
  '36877': {
    zip: '36877',
    city: 'Smiths Station',
    state: 'AL',
    latitude: '32.537478',
    longitude: '-85.099853',
    timeZoneId: 'America/Chicago',
  },
  '36879': {
    zip: '36879',
    city: 'Waverly',
    state: 'AL',
    latitude: '32.785158',
    longitude: '-85.532396',
    timeZoneId: 'America/Chicago',
  },
  '36901': {
    zip: '36901',
    city: 'Bellamy',
    state: 'AL',
    latitude: '32.449118',
    longitude: '-88.131892',
    timeZoneId: 'America/Chicago',
  },
  '36904': {
    zip: '36904',
    city: 'Butler',
    state: 'AL',
    latitude: '32.091455',
    longitude: '-88.222286',
    timeZoneId: 'America/Chicago',
  },
  '36907': {
    zip: '36907',
    city: 'Cuba',
    state: 'AL',
    latitude: '32.391122',
    longitude: '-88.359194',
    timeZoneId: 'America/Chicago',
  },
  '36908': {
    zip: '36908',
    city: 'Gilbertown',
    state: 'AL',
    latitude: '31.862112',
    longitude: '-88.342985',
    timeZoneId: 'America/Chicago',
  },
  '36910': {
    zip: '36910',
    city: 'Jachin',
    state: 'AL',
    latitude: '32.24099',
    longitude: '-88.223446',
    timeZoneId: 'America/Chicago',
  },
  '36912': {
    zip: '36912',
    city: 'Lisman',
    state: 'AL',
    latitude: '32.22288',
    longitude: '-88.32618',
    timeZoneId: 'America/Chicago',
  },
  '36913': {
    zip: '36913',
    city: 'Melvin',
    state: 'AL',
    latitude: '31.928964',
    longitude: '-88.457923',
    timeZoneId: 'America/Chicago',
  },
  '36915': {
    zip: '36915',
    city: 'Needham',
    state: 'AL',
    latitude: '32.014975',
    longitude: '-88.336031',
    timeZoneId: 'America/Chicago',
  },
  '36916': {
    zip: '36916',
    city: 'Pennington',
    state: 'AL',
    latitude: '32.246209',
    longitude: '-88.08081',
    timeZoneId: 'America/Chicago',
  },
  '36919': {
    zip: '36919',
    city: 'Silas',
    state: 'AL',
    latitude: '31.762783',
    longitude: '-88.300013',
    timeZoneId: 'America/Chicago',
  },
  '36921': {
    zip: '36921',
    city: 'Toxey',
    state: 'AL',
    latitude: '31.965833',
    longitude: '-88.360431',
    timeZoneId: 'America/Chicago',
  },
  '36922': {
    zip: '36922',
    city: 'Ward',
    state: 'AL',
    latitude: '32.361718',
    longitude: '-88.27793',
    timeZoneId: 'America/Chicago',
  },
  '36925': {
    zip: '36925',
    city: 'York',
    state: 'AL',
    latitude: '32.46636',
    longitude: '-88.26948',
    timeZoneId: 'America/Chicago',
  },
  '37010': {
    zip: '37010',
    city: 'Adams',
    state: 'TN',
    latitude: '36.565492',
    longitude: '-87.095773',
    timeZoneId: 'America/Chicago',
  },
  '37011': {
    zip: '37011',
    city: 'Antioch',
    state: 'TN',
    latitude: '36.172524',
    longitude: '-86.880844',
    timeZoneId: 'America/Chicago',
  },
  '37012': {
    zip: '37012',
    city: 'Alexandria',
    state: 'TN',
    latitude: '36.076957',
    longitude: '-86.03625',
    timeZoneId: 'America/Chicago',
  },
  '37013': {
    zip: '37013',
    city: 'Antioch',
    state: 'TN',
    latitude: '36.037491',
    longitude: '-86.638596',
    timeZoneId: 'America/Chicago',
  },
  '37014': {
    zip: '37014',
    city: 'Arrington',
    state: 'TN',
    latitude: '35.86458',
    longitude: '-86.66484',
    timeZoneId: 'America/Chicago',
  },
  '37015': {
    zip: '37015',
    city: 'Ashland City',
    state: 'TN',
    latitude: '36.284785',
    longitude: '-87.071634',
    timeZoneId: 'America/Chicago',
  },
  '37016': {
    zip: '37016',
    city: 'Auburntown',
    state: 'TN',
    latitude: '35.988733',
    longitude: '-86.114362',
    timeZoneId: 'America/Chicago',
  },
  '37018': {
    zip: '37018',
    city: 'Beechgrove',
    state: 'TN',
    latitude: '35.622179',
    longitude: '-86.172797',
    timeZoneId: 'America/Chicago',
  },
  '37019': {
    zip: '37019',
    city: 'Belfast',
    state: 'TN',
    latitude: '35.517599',
    longitude: '-86.808808',
    timeZoneId: 'America/Chicago',
  },
  '37020': {
    zip: '37020',
    city: 'Bell Buckle',
    state: 'TN',
    latitude: '35.604064',
    longitude: '-86.410407',
    timeZoneId: 'America/Chicago',
  },
  '37022': {
    zip: '37022',
    city: 'Bethpage',
    state: 'TN',
    latitude: '36.514694',
    longitude: '-86.32453',
    timeZoneId: 'America/Chicago',
  },
  '37023': {
    zip: '37023',
    city: 'Big Rock',
    state: 'TN',
    latitude: '36.598437',
    longitude: '-87.699494',
    timeZoneId: 'America/Chicago',
  },
  '37024': {
    zip: '37024',
    city: 'Brentwood',
    state: 'TN',
    latitude: '35.997763',
    longitude: '-86.785825',
    timeZoneId: 'America/Chicago',
  },
  '37025': {
    zip: '37025',
    city: 'Bon Aqua',
    state: 'TN',
    latitude: '35.930256',
    longitude: '-87.246161',
    timeZoneId: 'America/Chicago',
  },
  '37026': {
    zip: '37026',
    city: 'Bradyville',
    state: 'TN',
    latitude: '35.698888',
    longitude: '-86.09954',
    timeZoneId: 'America/Chicago',
  },
  '37027': {
    zip: '37027',
    city: 'Brentwood',
    state: 'TN',
    latitude: '36.00245',
    longitude: '-86.791159',
    timeZoneId: 'America/Chicago',
  },
  '37028': {
    zip: '37028',
    city: 'Bumpus Mills',
    state: 'TN',
    latitude: '36.660239',
    longitude: '-87.828611',
    timeZoneId: 'America/Chicago',
  },
  '37029': {
    zip: '37029',
    city: 'Burns',
    state: 'TN',
    latitude: '36.040722',
    longitude: '-87.25725',
    timeZoneId: 'America/Chicago',
  },
  '37030': {
    zip: '37030',
    city: 'Carthage',
    state: 'TN',
    latitude: '36.26238',
    longitude: '-85.9944',
    timeZoneId: 'America/Chicago',
  },
  '37031': {
    zip: '37031',
    city: 'Castalian Springs',
    state: 'TN',
    latitude: '36.3824',
    longitude: '-86.318015',
    timeZoneId: 'America/Chicago',
  },
  '37032': {
    zip: '37032',
    city: 'Cedar Hill',
    state: 'TN',
    latitude: '36.512059',
    longitude: '-87.020325',
    timeZoneId: 'America/Chicago',
  },
  '37033': {
    zip: '37033',
    city: 'Centerville',
    state: 'TN',
    latitude: '35.66847',
    longitude: '-87.69903',
    timeZoneId: 'America/Chicago',
  },
  '37034': {
    zip: '37034',
    city: 'Chapel Hill',
    state: 'TN',
    latitude: '35.621639',
    longitude: '-86.617769',
    timeZoneId: 'America/Chicago',
  },
  '37035': {
    zip: '37035',
    city: 'Chapmansboro',
    state: 'TN',
    latitude: '36.368347',
    longitude: '-87.137681',
    timeZoneId: 'America/Chicago',
  },
  '37036': {
    zip: '37036',
    city: 'Charlotte',
    state: 'TN',
    latitude: '36.236488',
    longitude: '-87.261716',
    timeZoneId: 'America/Chicago',
  },
  '37037': {
    zip: '37037',
    city: 'Christiana',
    state: 'TN',
    latitude: '35.712477',
    longitude: '-86.364445',
    timeZoneId: 'America/Chicago',
  },
  '37040': {
    zip: '37040',
    city: 'Clarksville',
    state: 'TN',
    latitude: '36.532026',
    longitude: '-87.350258',
    timeZoneId: 'America/Chicago',
  },
  '37041': {
    zip: '37041',
    city: 'Clarksville',
    state: 'TN',
    latitude: '36.561204',
    longitude: '-87.348735',
    timeZoneId: 'America/Chicago',
  },
  '37042': {
    zip: '37042',
    city: 'Clarksville',
    state: 'TN',
    latitude: '36.56214',
    longitude: '-87.40572',
    timeZoneId: 'America/Chicago',
  },
  '37043': {
    zip: '37043',
    city: 'Clarksville',
    state: 'TN',
    latitude: '36.49278',
    longitude: '-87.23346',
    timeZoneId: 'America/Chicago',
  },
  '37044': {
    zip: '37044',
    city: 'Clarksville',
    state: 'TN',
    latitude: '36.529382',
    longitude: '-87.337634',
    timeZoneId: 'America/Chicago',
  },
  '37046': {
    zip: '37046',
    city: 'College Grove',
    state: 'TN',
    latitude: '35.772714',
    longitude: '-86.714662',
    timeZoneId: 'America/Chicago',
  },
  '37047': {
    zip: '37047',
    city: 'Cornersville',
    state: 'TN',
    latitude: '35.360804',
    longitude: '-86.840796',
    timeZoneId: 'America/Chicago',
  },
  '37048': {
    zip: '37048',
    city: 'Cottontown',
    state: 'TN',
    latitude: '36.491451',
    longitude: '-86.594531',
    timeZoneId: 'America/Chicago',
  },
  '37049': {
    zip: '37049',
    city: 'Cross Plains',
    state: 'TN',
    latitude: '36.556184',
    longitude: '-86.68191',
    timeZoneId: 'America/Chicago',
  },
  '37050': {
    zip: '37050',
    city: 'Cumberland City',
    state: 'TN',
    latitude: '36.375503',
    longitude: '-87.563413',
    timeZoneId: 'America/Chicago',
  },
  '37051': {
    zip: '37051',
    city: 'Cumberland Furnace',
    state: 'TN',
    latitude: '36.298358',
    longitude: '-87.412642',
    timeZoneId: 'America/Chicago',
  },
  '37052': {
    zip: '37052',
    city: 'Cunningham',
    state: 'TN',
    latitude: '36.381179',
    longitude: '-87.408352',
    timeZoneId: 'America/Chicago',
  },
  '37055': {
    zip: '37055',
    city: 'Dickson',
    state: 'TN',
    latitude: '36.083188',
    longitude: '-87.428235',
    timeZoneId: 'America/Chicago',
  },
  '37056': {
    zip: '37056',
    city: 'Dickson',
    state: 'TN',
    latitude: '36.076801',
    longitude: '-87.377175',
    timeZoneId: 'America/Chicago',
  },
  '37057': {
    zip: '37057',
    city: 'Dixon Springs',
    state: 'TN',
    latitude: '36.402435',
    longitude: '-86.039251',
    timeZoneId: 'America/Chicago',
  },
  '37058': {
    zip: '37058',
    city: 'Dover',
    state: 'TN',
    latitude: '36.482869',
    longitude: '-87.833627',
    timeZoneId: 'America/Chicago',
  },
  '37059': {
    zip: '37059',
    city: 'Dowelltown',
    state: 'TN',
    latitude: '36.01361',
    longitude: '-85.946404',
    timeZoneId: 'America/Chicago',
  },
  '37060': {
    zip: '37060',
    city: 'Eagleville',
    state: 'TN',
    latitude: '35.748245',
    longitude: '-86.640746',
    timeZoneId: 'America/Chicago',
  },
  '37061': {
    zip: '37061',
    city: 'Erin',
    state: 'TN',
    latitude: '36.289871',
    longitude: '-87.7483',
    timeZoneId: 'America/Chicago',
  },
  '37062': {
    zip: '37062',
    city: 'Fairview',
    state: 'TN',
    latitude: '35.970047',
    longitude: '-87.127121',
    timeZoneId: 'America/Chicago',
  },
  '37063': {
    zip: '37063',
    city: 'Fosterville',
    state: 'TN',
    latitude: '35.745445',
    longitude: '-86.388115',
    timeZoneId: 'America/Chicago',
  },
  '37064': {
    zip: '37064',
    city: 'Franklin',
    state: 'TN',
    latitude: '35.923225',
    longitude: '-86.887821',
    timeZoneId: 'America/Chicago',
  },
  '37065': {
    zip: '37065',
    city: 'Franklin',
    state: 'TN',
    latitude: '35.923142',
    longitude: '-86.8698',
    timeZoneId: 'America/Chicago',
  },
  '37066': {
    zip: '37066',
    city: 'Gallatin',
    state: 'TN',
    latitude: '36.389876',
    longitude: '-86.454091',
    timeZoneId: 'America/Chicago',
  },
  '37067': {
    zip: '37067',
    city: 'Franklin',
    state: 'TN',
    latitude: '35.928406',
    longitude: '-86.805538',
    timeZoneId: 'America/Chicago',
  },
  '37068': {
    zip: '37068',
    city: 'Franklin',
    state: 'TN',
    latitude: '35.927805',
    longitude: '-86.843648',
    timeZoneId: 'America/Chicago',
  },
  '37069': {
    zip: '37069',
    city: 'Franklin',
    state: 'TN',
    latitude: '35.990255',
    longitude: '-86.895944',
    timeZoneId: 'America/Chicago',
  },
  '37070': {
    zip: '37070',
    city: 'Goodlettsville',
    state: 'TN',
    latitude: '36.32371',
    longitude: '-86.715822',
    timeZoneId: 'America/Chicago',
  },
  '37071': {
    zip: '37071',
    city: 'Gladeville',
    state: 'TN',
    latitude: '36.193796',
    longitude: '-86.279517',
    timeZoneId: 'America/Chicago',
  },
  '37072': {
    zip: '37072',
    city: 'Goodlettsville',
    state: 'TN',
    latitude: '36.325144',
    longitude: '-86.737474',
    timeZoneId: 'America/Chicago',
  },
  '37073': {
    zip: '37073',
    city: 'Greenbrier',
    state: 'TN',
    latitude: '36.426973',
    longitude: '-86.801739',
    timeZoneId: 'America/Chicago',
  },
  '37074': {
    zip: '37074',
    city: 'Hartsville',
    state: 'TN',
    latitude: '36.387273',
    longitude: '-86.159417',
    timeZoneId: 'America/Chicago',
  },
  '37075': {
    zip: '37075',
    city: 'Hendersonville',
    state: 'TN',
    latitude: '36.316241',
    longitude: '-86.607771',
    timeZoneId: 'America/Chicago',
  },
  '37076': {
    zip: '37076',
    city: 'Hermitage',
    state: 'TN',
    latitude: '36.186946',
    longitude: '-86.600463',
    timeZoneId: 'America/Chicago',
  },
  '37077': {
    zip: '37077',
    city: 'Hendersonville',
    state: 'TN',
    latitude: '36.304003',
    longitude: '-86.619156',
    timeZoneId: 'America/Chicago',
  },
  '37078': {
    zip: '37078',
    city: 'Hurricane Mills',
    state: 'TN',
    latitude: '35.949864',
    longitude: '-87.761509',
    timeZoneId: 'America/Chicago',
  },
  '37079': {
    zip: '37079',
    city: 'Indian Mound',
    state: 'TN',
    latitude: '36.466021',
    longitude: '-87.574984',
    timeZoneId: 'America/Chicago',
  },
  '37080': {
    zip: '37080',
    city: 'Joelton',
    state: 'TN',
    latitude: '36.329107',
    longitude: '-86.905779',
    timeZoneId: 'America/Chicago',
  },
  '37082': {
    zip: '37082',
    city: 'Kingston Springs',
    state: 'TN',
    latitude: '36.085361',
    longitude: '-87.121788',
    timeZoneId: 'America/Chicago',
  },
  '37083': {
    zip: '37083',
    city: 'Lafayette',
    state: 'TN',
    latitude: '36.524054',
    longitude: '-86.024892',
    timeZoneId: 'America/Chicago',
  },
  '37085': {
    zip: '37085',
    city: 'Lascassas',
    state: 'TN',
    latitude: '35.938527',
    longitude: '-86.272023',
    timeZoneId: 'America/Chicago',
  },
  '37086': {
    zip: '37086',
    city: 'La Vergne',
    state: 'TN',
    latitude: '36.013661',
    longitude: '-86.565021',
    timeZoneId: 'America/Chicago',
  },
  '37087': {
    zip: '37087',
    city: 'Lebanon',
    state: 'TN',
    latitude: '36.211615',
    longitude: '-86.306808',
    timeZoneId: 'America/Chicago',
  },
  '37088': {
    zip: '37088',
    city: 'Lebanon',
    state: 'TN',
    latitude: '36.208115',
    longitude: '-86.289256',
    timeZoneId: 'America/Chicago',
  },
  '37089': {
    zip: '37089',
    city: 'La Vergne',
    state: 'TN',
    latitude: '35.998042',
    longitude: '-86.574564',
    timeZoneId: 'America/Chicago',
  },
  '37090': {
    zip: '37090',
    city: 'Lebanon',
    state: 'TN',
    latitude: '36.152343',
    longitude: '-86.315832',
    timeZoneId: 'America/Chicago',
  },
  '37091': {
    zip: '37091',
    city: 'Lewisburg',
    state: 'TN',
    latitude: '35.450839',
    longitude: '-86.795069',
    timeZoneId: 'America/Chicago',
  },
  '37095': {
    zip: '37095',
    city: 'Liberty',
    state: 'TN',
    latitude: '36.00612',
    longitude: '-85.970822',
    timeZoneId: 'America/Chicago',
  },
  '37096': {
    zip: '37096',
    city: 'Linden',
    state: 'TN',
    latitude: '35.59836',
    longitude: '-87.852',
    timeZoneId: 'America/Chicago',
  },
  '37097': {
    zip: '37097',
    city: 'Lobelville',
    state: 'TN',
    latitude: '35.778644',
    longitude: '-87.786538',
    timeZoneId: 'America/Chicago',
  },
  '37098': {
    zip: '37098',
    city: 'Lyles',
    state: 'TN',
    latitude: '35.88803',
    longitude: '-87.337285',
    timeZoneId: 'America/Chicago',
  },
  '37101': {
    zip: '37101',
    city: 'Mc Ewen',
    state: 'TN',
    latitude: '36.138332',
    longitude: '-87.657349',
    timeZoneId: 'America/Chicago',
  },
  '37110': {
    zip: '37110',
    city: 'McMinnville',
    state: 'TN',
    latitude: '35.683587',
    longitude: '-85.776477',
    timeZoneId: 'America/Chicago',
  },
  '37111': {
    zip: '37111',
    city: 'McMinnville',
    state: 'TN',
    latitude: '35.687754',
    longitude: '-85.775514',
    timeZoneId: 'America/Chicago',
  },
  '37115': {
    zip: '37115',
    city: 'Madison',
    state: 'TN',
    latitude: '36.264356',
    longitude: '-86.711888',
    timeZoneId: 'America/Chicago',
  },
  '37116': {
    zip: '37116',
    city: 'Madison',
    state: 'TN',
    latitude: '36.193514',
    longitude: '-86.743409',
    timeZoneId: 'America/Chicago',
  },
  '37118': {
    zip: '37118',
    city: 'Milton',
    state: 'TN',
    latitude: '35.916709',
    longitude: '-86.179424',
    timeZoneId: 'America/Chicago',
  },
  '37119': {
    zip: '37119',
    city: 'Mitchellville',
    state: 'TN',
    latitude: '36.632593',
    longitude: '-86.537098',
    timeZoneId: 'America/Chicago',
  },
  '37121': {
    zip: '37121',
    city: 'Mount Juliet',
    state: 'TN',
    latitude: '36.20506',
    longitude: '-86.520664',
    timeZoneId: 'America/Chicago',
  },
  '37122': {
    zip: '37122',
    city: 'Mount Juliet',
    state: 'TN',
    latitude: '36.215209',
    longitude: '-86.517501',
    timeZoneId: 'America/Chicago',
  },
  '37127': {
    zip: '37127',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.775581',
    longitude: '-86.328633',
    timeZoneId: 'America/Chicago',
  },
  '37128': {
    zip: '37128',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.815518',
    longitude: '-86.45125',
    timeZoneId: 'America/Chicago',
  },
  '37129': {
    zip: '37129',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.871995',
    longitude: '-86.435491',
    timeZoneId: 'America/Chicago',
  },
  '37130': {
    zip: '37130',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.870529',
    longitude: '-86.341561',
    timeZoneId: 'America/Chicago',
  },
  '37131': {
    zip: '37131',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.853243',
    longitude: '-86.396024',
    timeZoneId: 'America/Chicago',
  },
  '37132': {
    zip: '37132',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.850303',
    longitude: '-86.361672',
    timeZoneId: 'America/Chicago',
  },
  '37133': {
    zip: '37133',
    city: 'Murfreesboro',
    state: 'TN',
    latitude: '35.840269',
    longitude: '-86.394053',
    timeZoneId: 'America/Chicago',
  },
  '37134': {
    zip: '37134',
    city: 'New Johnsonville',
    state: 'TN',
    latitude: '36.010324',
    longitude: '-87.967812',
    timeZoneId: 'America/Chicago',
  },
  '37135': {
    zip: '37135',
    city: 'Nolensville',
    state: 'TN',
    latitude: '35.94413',
    longitude: '-86.672903',
    timeZoneId: 'America/Chicago',
  },
  '37136': {
    zip: '37136',
    city: 'Norene',
    state: 'TN',
    latitude: '36.195537',
    longitude: '-86.278064',
    timeZoneId: 'America/Chicago',
  },
  '37137': {
    zip: '37137',
    city: 'Nunnelly',
    state: 'TN',
    latitude: '35.8335',
    longitude: '-87.445662',
    timeZoneId: 'America/Chicago',
  },
  '37138': {
    zip: '37138',
    city: 'Old Hickory',
    state: 'TN',
    latitude: '36.24516',
    longitude: '-86.632377',
    timeZoneId: 'America/Chicago',
  },
  '37140': {
    zip: '37140',
    city: 'Only',
    state: 'TN',
    latitude: '35.870014',
    longitude: '-87.653403',
    timeZoneId: 'America/Chicago',
  },
  '37141': {
    zip: '37141',
    city: 'Orlinda',
    state: 'TN',
    latitude: '36.613179',
    longitude: '-86.686512',
    timeZoneId: 'America/Chicago',
  },
  '37142': {
    zip: '37142',
    city: 'Palmyra',
    state: 'TN',
    latitude: '36.408773',
    longitude: '-87.504544',
    timeZoneId: 'America/Chicago',
  },
  '37143': {
    zip: '37143',
    city: 'Pegram',
    state: 'TN',
    latitude: '36.124381',
    longitude: '-87.026548',
    timeZoneId: 'America/Chicago',
  },
  '37144': {
    zip: '37144',
    city: 'Petersburg',
    state: 'TN',
    latitude: '35.377427',
    longitude: '-86.586934',
    timeZoneId: 'America/Chicago',
  },
  '37145': {
    zip: '37145',
    city: 'Pleasant Shade',
    state: 'TN',
    latitude: '36.353662',
    longitude: '-85.902405',
    timeZoneId: 'America/Chicago',
  },
  '37146': {
    zip: '37146',
    city: 'Pleasant View',
    state: 'TN',
    latitude: '36.384642',
    longitude: '-87.035393',
    timeZoneId: 'America/Chicago',
  },
  '37148': {
    zip: '37148',
    city: 'Portland',
    state: 'TN',
    latitude: '36.568357',
    longitude: '-86.49064',
    timeZoneId: 'America/Chicago',
  },
  '37149': {
    zip: '37149',
    city: 'Readyville',
    state: 'TN',
    latitude: '35.824986',
    longitude: '-86.217289',
    timeZoneId: 'America/Chicago',
  },
  '37150': {
    zip: '37150',
    city: 'Red Boiling Springs',
    state: 'TN',
    latitude: '36.529731',
    longitude: '-85.845293',
    timeZoneId: 'America/Chicago',
  },
  '37151': {
    zip: '37151',
    city: 'Riddleton',
    state: 'TN',
    latitude: '36.350576',
    longitude: '-86.030064',
    timeZoneId: 'America/Chicago',
  },
  '37152': {
    zip: '37152',
    city: 'Ridgetop',
    state: 'TN',
    latitude: '36.401821',
    longitude: '-86.771287',
    timeZoneId: 'America/Chicago',
  },
  '37153': {
    zip: '37153',
    city: 'Rockvale',
    state: 'TN',
    latitude: '35.757032',
    longitude: '-86.554116',
    timeZoneId: 'America/Chicago',
  },
  '37160': {
    zip: '37160',
    city: 'Shelbyville',
    state: 'TN',
    latitude: '35.476039',
    longitude: '-86.46721',
    timeZoneId: 'America/Chicago',
  },
  '37161': {
    zip: '37161',
    city: 'Shelbyville',
    state: 'TN',
    latitude: '35.484443',
    longitude: '-86.46117',
    timeZoneId: 'America/Chicago',
  },
  '37162': {
    zip: '37162',
    city: 'Shelbyville',
    state: 'TN',
    latitude: '35.491536',
    longitude: '-86.445995',
    timeZoneId: 'America/Chicago',
  },
  '37165': {
    zip: '37165',
    city: 'Slayden',
    state: 'TN',
    latitude: '36.225192',
    longitude: '-87.461496',
    timeZoneId: 'America/Chicago',
  },
  '37166': {
    zip: '37166',
    city: 'Smithville',
    state: 'TN',
    latitude: '35.958669',
    longitude: '-85.824729',
    timeZoneId: 'America/Chicago',
  },
  '37167': {
    zip: '37167',
    city: 'Smyrna',
    state: 'TN',
    latitude: '35.967035',
    longitude: '-86.513959',
    timeZoneId: 'America/Chicago',
  },
  '37171': {
    zip: '37171',
    city: 'Southside',
    state: 'TN',
    latitude: '36.362704',
    longitude: '-87.30434',
    timeZoneId: 'America/Chicago',
  },
  '37172': {
    zip: '37172',
    city: 'Springfield',
    state: 'TN',
    latitude: '36.524986',
    longitude: '-86.867133',
    timeZoneId: 'America/Chicago',
  },
  '37174': {
    zip: '37174',
    city: 'Spring Hill',
    state: 'TN',
    latitude: '35.755896',
    longitude: '-86.884965',
    timeZoneId: 'America/Chicago',
  },
  '37175': {
    zip: '37175',
    city: 'Stewart',
    state: 'TN',
    latitude: '36.323063',
    longitude: '-87.895927',
    timeZoneId: 'America/Chicago',
  },
  '37178': {
    zip: '37178',
    city: 'Tennessee Ridge',
    state: 'TN',
    latitude: '36.313167',
    longitude: '-87.764653',
    timeZoneId: 'America/Chicago',
  },
  '37179': {
    zip: '37179',
    city: 'Thompsons Station',
    state: 'TN',
    latitude: '35.799949',
    longitude: '-86.904828',
    timeZoneId: 'America/Chicago',
  },
  '37180': {
    zip: '37180',
    city: 'Unionville',
    state: 'TN',
    latitude: '35.624856',
    longitude: '-86.570973',
    timeZoneId: 'America/Chicago',
  },
  '37181': {
    zip: '37181',
    city: 'Vanleer',
    state: 'TN',
    latitude: '36.223832',
    longitude: '-87.46061',
    timeZoneId: 'America/Chicago',
  },
  '37183': {
    zip: '37183',
    city: 'Wartrace',
    state: 'TN',
    latitude: '35.502048',
    longitude: '-86.340949',
    timeZoneId: 'America/Chicago',
  },
  '37184': {
    zip: '37184',
    city: 'Watertown',
    state: 'TN',
    latitude: '36.108708',
    longitude: '-86.136719',
    timeZoneId: 'America/Chicago',
  },
  '37185': {
    zip: '37185',
    city: 'Waverly',
    state: 'TN',
    latitude: '36.087613',
    longitude: '-87.790907',
    timeZoneId: 'America/Chicago',
  },
  '37186': {
    zip: '37186',
    city: 'Westmoreland',
    state: 'TN',
    latitude: '36.574864',
    longitude: '-86.223555',
    timeZoneId: 'America/Chicago',
  },
  '37187': {
    zip: '37187',
    city: 'White Bluff',
    state: 'TN',
    latitude: '36.117237',
    longitude: '-87.228213',
    timeZoneId: 'America/Chicago',
  },
  '37188': {
    zip: '37188',
    city: 'White House',
    state: 'TN',
    latitude: '36.484055',
    longitude: '-86.687263',
    timeZoneId: 'America/Chicago',
  },
  '37189': {
    zip: '37189',
    city: 'Whites Creek',
    state: 'TN',
    latitude: '36.272962',
    longitude: '-86.831533',
    timeZoneId: 'America/Chicago',
  },
  '37190': {
    zip: '37190',
    city: 'Woodbury',
    state: 'TN',
    latitude: '35.812584',
    longitude: '-86.069115',
    timeZoneId: 'America/Chicago',
  },
  '37191': {
    zip: '37191',
    city: 'Woodlawn',
    state: 'TN',
    latitude: '36.501196',
    longitude: '-87.539057',
    timeZoneId: 'America/Chicago',
  },
  '37201': {
    zip: '37201',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.165634',
    longitude: '-86.779786',
    timeZoneId: 'America/Chicago',
  },
  '37202': {
    zip: '37202',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.149245',
    longitude: '-86.792828',
    timeZoneId: 'America/Chicago',
  },
  '37203': {
    zip: '37203',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.155873',
    longitude: '-86.78928',
    timeZoneId: 'America/Chicago',
  },
  '37204': {
    zip: '37204',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.108754',
    longitude: '-86.780579',
    timeZoneId: 'America/Chicago',
  },
  '37205': {
    zip: '37205',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.11103',
    longitude: '-86.864958',
    timeZoneId: 'America/Chicago',
  },
  '37206': {
    zip: '37206',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.180628',
    longitude: '-86.735521',
    timeZoneId: 'America/Chicago',
  },
  '37207': {
    zip: '37207',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.222188',
    longitude: '-86.773002',
    timeZoneId: 'America/Chicago',
  },
  '37208': {
    zip: '37208',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.177224',
    longitude: '-86.806822',
    timeZoneId: 'America/Chicago',
  },
  '37209': {
    zip: '37209',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.150761',
    longitude: '-86.8641',
    timeZoneId: 'America/Chicago',
  },
  '37210': {
    zip: '37210',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.138854',
    longitude: '-86.736367',
    timeZoneId: 'America/Chicago',
  },
  '37211': {
    zip: '37211',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.075248',
    longitude: '-86.7207',
    timeZoneId: 'America/Chicago',
  },
  '37212': {
    zip: '37212',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.133012',
    longitude: '-86.802764',
    timeZoneId: 'America/Chicago',
  },
  '37213': {
    zip: '37213',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.165353',
    longitude: '-86.765654',
    timeZoneId: 'America/Chicago',
  },
  '37214': {
    zip: '37214',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.1719',
    longitude: '-86.7207',
    timeZoneId: 'America/Chicago',
  },
  '37215': {
    zip: '37215',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.093978',
    longitude: '-86.824688',
    timeZoneId: 'America/Chicago',
  },
  '37216': {
    zip: '37216',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.214733',
    longitude: '-86.72486',
    timeZoneId: 'America/Chicago',
  },
  '37217': {
    zip: '37217',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.103283',
    longitude: '-86.655886',
    timeZoneId: 'America/Chicago',
  },
  '37218': {
    zip: '37218',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.219335',
    longitude: '-86.856532',
    timeZoneId: 'America/Chicago',
  },
  '37219': {
    zip: '37219',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.167252',
    longitude: '-86.786435',
    timeZoneId: 'America/Chicago',
  },
  '37220': {
    zip: '37220',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.067492',
    longitude: '-86.781198',
    timeZoneId: 'America/Chicago',
  },
  '37221': {
    zip: '37221',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.05502',
    longitude: '-86.9616',
    timeZoneId: 'America/Chicago',
  },
  '37222': {
    zip: '37222',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.068877',
    longitude: '-86.726246',
    timeZoneId: 'America/Chicago',
  },
  '37224': {
    zip: '37224',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.151112',
    longitude: '-86.795584',
    timeZoneId: 'America/Chicago',
  },
  '37227': {
    zip: '37227',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.132543',
    longitude: '-86.669444',
    timeZoneId: 'America/Chicago',
  },
  '37228': {
    zip: '37228',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.194411',
    longitude: '-86.80017',
    timeZoneId: 'America/Chicago',
  },
  '37229': {
    zip: '37229',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.162527',
    longitude: '-86.663695',
    timeZoneId: 'America/Chicago',
  },
  '37230': {
    zip: '37230',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.148709',
    longitude: '-86.785305',
    timeZoneId: 'America/Chicago',
  },
  '37232': {
    zip: '37232',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.142857',
    longitude: '-86.807356',
    timeZoneId: 'America/Chicago',
  },
  '37234': {
    zip: '37234',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.150333',
    longitude: '-86.790926',
    timeZoneId: 'America/Chicago',
  },
  '37235': {
    zip: '37235',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.148097',
    longitude: '-86.805447',
    timeZoneId: 'America/Chicago',
  },
  '37236': {
    zip: '37236',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.150275',
    longitude: '-86.802472',
    timeZoneId: 'America/Chicago',
  },
  '37238': {
    zip: '37238',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.162112',
    longitude: '-86.772739',
    timeZoneId: 'America/Chicago',
  },
  '37240': {
    zip: '37240',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.143381',
    longitude: '-86.803365',
    timeZoneId: 'America/Chicago',
  },
  '37241': {
    zip: '37241',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.108116',
    longitude: '-86.67472',
    timeZoneId: 'America/Chicago',
  },
  '37242': {
    zip: '37242',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.163122',
    longitude: '-86.783343',
    timeZoneId: 'America/Chicago',
  },
  '37243': {
    zip: '37243',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.162894',
    longitude: '-86.785734',
    timeZoneId: 'America/Chicago',
  },
  '37244': {
    zip: '37244',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.166215',
    longitude: '-86.785877',
    timeZoneId: 'America/Chicago',
  },
  '37246': {
    zip: '37246',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.16628',
    longitude: '-86.783735',
    timeZoneId: 'America/Chicago',
  },
  '37250': {
    zip: '37250',
    city: 'Nashville',
    state: 'TN',
    latitude: '36.161267',
    longitude: '-86.783675',
    timeZoneId: 'America/Chicago',
  },
  '37301': {
    zip: '37301',
    city: 'Altamont',
    state: 'TN',
    latitude: '35.418786',
    longitude: '-85.797724',
    timeZoneId: 'America/Chicago',
  },
  '37302': {
    zip: '37302',
    city: 'Apison',
    state: 'TN',
    latitude: '35.013216',
    longitude: '-85.015951',
    timeZoneId: 'America/New_York',
  },
  '37303': {
    zip: '37303',
    city: 'Athens',
    state: 'TN',
    latitude: '35.443853',
    longitude: '-84.599989',
    timeZoneId: 'America/New_York',
  },
  '37304': {
    zip: '37304',
    city: 'Bakewell',
    state: 'TN',
    latitude: '35.382222',
    longitude: '-85.108889',
    timeZoneId: 'America/New_York',
  },
  '37305': {
    zip: '37305',
    city: 'Beersheba Springs',
    state: 'TN',
    latitude: '35.485212',
    longitude: '-85.652867',
    timeZoneId: 'America/Chicago',
  },
  '37306': {
    zip: '37306',
    city: 'Belvidere',
    state: 'TN',
    latitude: '35.201042',
    longitude: '-85.901615',
    timeZoneId: 'America/Chicago',
  },
  '37307': {
    zip: '37307',
    city: 'Benton',
    state: 'TN',
    latitude: '35.171455',
    longitude: '-84.649915',
    timeZoneId: 'America/New_York',
  },
  '37308': {
    zip: '37308',
    city: 'Birchwood',
    state: 'TN',
    latitude: '35.304557',
    longitude: '-85.011238',
    timeZoneId: 'America/New_York',
  },
  '37309': {
    zip: '37309',
    city: 'Calhoun',
    state: 'TN',
    latitude: '35.382097',
    longitude: '-84.766636',
    timeZoneId: 'America/New_York',
  },
  '37310': {
    zip: '37310',
    city: 'Charleston',
    state: 'TN',
    latitude: '35.258306',
    longitude: '-84.868393',
    timeZoneId: 'America/New_York',
  },
  '37311': {
    zip: '37311',
    city: 'Cleveland',
    state: 'TN',
    latitude: '35.15733',
    longitude: '-84.87039',
    timeZoneId: 'America/New_York',
  },
  '37312': {
    zip: '37312',
    city: 'Cleveland',
    state: 'TN',
    latitude: '35.19953',
    longitude: '-84.863073',
    timeZoneId: 'America/New_York',
  },
  '37313': {
    zip: '37313',
    city: 'Coalmont',
    state: 'TN',
    latitude: '35.403427',
    longitude: '-85.646182',
    timeZoneId: 'America/Chicago',
  },
  '37314': {
    zip: '37314',
    city: 'Coker Creek',
    state: 'TN',
    latitude: '35.459502',
    longitude: '-84.343618',
    timeZoneId: 'America/New_York',
  },
  '37315': {
    zip: '37315',
    city: 'Collegedale',
    state: 'TN',
    latitude: '35.04784',
    longitude: '-85.04759',
    timeZoneId: 'America/New_York',
  },
  '37316': {
    zip: '37316',
    city: 'Conasauga',
    state: 'TN',
    latitude: '35.004536',
    longitude: '-84.733735',
    timeZoneId: 'America/New_York',
  },
  '37317': {
    zip: '37317',
    city: 'Copperhill',
    state: 'TN',
    latitude: '34.98712',
    longitude: '-84.372874',
    timeZoneId: 'America/New_York',
  },
  '37318': {
    zip: '37318',
    city: 'Cowan',
    state: 'TN',
    latitude: '35.163183',
    longitude: '-86.015612',
    timeZoneId: 'America/Chicago',
  },
  '37320': {
    zip: '37320',
    city: 'Cleveland',
    state: 'TN',
    latitude: '35.15767',
    longitude: '-84.877175',
    timeZoneId: 'America/New_York',
  },
  '37321': {
    zip: '37321',
    city: 'Dayton',
    state: 'TN',
    latitude: '35.49181',
    longitude: '-85.015179',
    timeZoneId: 'America/New_York',
  },
  '37322': {
    zip: '37322',
    city: 'Decatur',
    state: 'TN',
    latitude: '35.452828',
    longitude: '-84.914566',
    timeZoneId: 'America/New_York',
  },
  '37323': {
    zip: '37323',
    city: 'Cleveland',
    state: 'TN',
    latitude: '35.125348',
    longitude: '-84.866606',
    timeZoneId: 'America/New_York',
  },
  '37324': {
    zip: '37324',
    city: 'Decherd',
    state: 'TN',
    latitude: '35.207332',
    longitude: '-86.082483',
    timeZoneId: 'America/Chicago',
  },
  '37325': {
    zip: '37325',
    city: 'Delano',
    state: 'TN',
    latitude: '35.251232',
    longitude: '-84.623648',
    timeZoneId: 'America/New_York',
  },
  '37326': {
    zip: '37326',
    city: 'Ducktown',
    state: 'TN',
    latitude: '35.037585',
    longitude: '-84.380831',
    timeZoneId: 'America/New_York',
  },
  '37327': {
    zip: '37327',
    city: 'Dunlap',
    state: 'TN',
    latitude: '35.371135',
    longitude: '-85.392644',
    timeZoneId: 'America/Chicago',
  },
  '37328': {
    zip: '37328',
    city: 'Elora',
    state: 'TN',
    latitude: '35.032842',
    longitude: '-86.422944',
    timeZoneId: 'America/Chicago',
  },
  '37329': {
    zip: '37329',
    city: 'Englewood',
    state: 'TN',
    latitude: '35.330416',
    longitude: '-84.521646',
    timeZoneId: 'America/New_York',
  },
  '37330': {
    zip: '37330',
    city: 'Estill Springs',
    state: 'TN',
    latitude: '35.27228',
    longitude: '-86.12869',
    timeZoneId: 'America/Chicago',
  },
  '37331': {
    zip: '37331',
    city: 'Etowah',
    state: 'TN',
    latitude: '35.327364',
    longitude: '-84.528516',
    timeZoneId: 'America/New_York',
  },
  '37332': {
    zip: '37332',
    city: 'Evensville',
    state: 'TN',
    latitude: '35.615048',
    longitude: '-84.996588',
    timeZoneId: 'America/New_York',
  },
  '37333': {
    zip: '37333',
    city: 'Farner',
    state: 'TN',
    latitude: '35.135821',
    longitude: '-84.325911',
    timeZoneId: 'America/New_York',
  },
  '37334': {
    zip: '37334',
    city: 'Fayetteville',
    state: 'TN',
    latitude: '35.151295',
    longitude: '-86.574002',
    timeZoneId: 'America/Chicago',
  },
  '37335': {
    zip: '37335',
    city: 'Flintville',
    state: 'TN',
    latitude: '35.018124',
    longitude: '-86.44012',
    timeZoneId: 'America/Chicago',
  },
  '37336': {
    zip: '37336',
    city: 'Georgetown',
    state: 'TN',
    latitude: '35.271974',
    longitude: '-84.986898',
    timeZoneId: 'America/New_York',
  },
  '37337': {
    zip: '37337',
    city: 'Grandview',
    state: 'TN',
    latitude: '35.767208',
    longitude: '-84.854798',
    timeZoneId: 'America/New_York',
  },
  '37338': {
    zip: '37338',
    city: 'Graysville',
    state: 'TN',
    latitude: '35.450237',
    longitude: '-85.08461',
    timeZoneId: 'America/New_York',
  },
  '37339': {
    zip: '37339',
    city: 'Gruetli Laager',
    state: 'TN',
    latitude: '35.3725',
    longitude: '-85.598889',
    timeZoneId: 'America/Chicago',
  },
  '37340': {
    zip: '37340',
    city: 'Guild',
    state: 'TN',
    latitude: '35.017989',
    longitude: '-85.509634',
    timeZoneId: 'America/Chicago',
  },
  '37341': {
    zip: '37341',
    city: 'Harrison',
    state: 'TN',
    latitude: '35.174574',
    longitude: '-85.093484',
    timeZoneId: 'America/New_York',
  },
  '37342': {
    zip: '37342',
    city: 'Hillsboro',
    state: 'TN',
    latitude: '35.387925',
    longitude: '-85.997192',
    timeZoneId: 'America/Chicago',
  },
  '37343': {
    zip: '37343',
    city: 'Hixson',
    state: 'TN',
    latitude: '35.163602',
    longitude: '-85.211915',
    timeZoneId: 'America/New_York',
  },
  '37345': {
    zip: '37345',
    city: 'Huntland',
    state: 'TN',
    latitude: '35.052997',
    longitude: '-86.271617',
    timeZoneId: 'America/Chicago',
  },
  '37347': {
    zip: '37347',
    city: 'Jasper',
    state: 'TN',
    latitude: '35.06928',
    longitude: '-85.6023',
    timeZoneId: 'America/Chicago',
  },
  '37348': {
    zip: '37348',
    city: 'Kelso',
    state: 'TN',
    latitude: '35.109489',
    longitude: '-86.464752',
    timeZoneId: 'America/Chicago',
  },
  '37349': {
    zip: '37349',
    city: 'Manchester',
    state: 'TN',
    latitude: '35.4676',
    longitude: '-86.083325',
    timeZoneId: 'America/Chicago',
  },
  '37350': {
    zip: '37350',
    city: 'Lookout Mountain',
    state: 'TN',
    latitude: '34.999753',
    longitude: '-85.353348',
    timeZoneId: 'America/New_York',
  },
  '37351': {
    zip: '37351',
    city: 'Lupton City',
    state: 'TN',
    latitude: '35.103193',
    longitude: '-85.262378',
    timeZoneId: 'America/New_York',
  },
  '37352': {
    zip: '37352',
    city: 'Lynchburg',
    state: 'TN',
    latitude: '35.261755',
    longitude: '-86.382944',
    timeZoneId: 'America/Chicago',
  },
  '37353': {
    zip: '37353',
    city: 'Mc Donald',
    state: 'TN',
    latitude: '35.076691',
    longitude: '-85.010002',
    timeZoneId: 'America/New_York',
  },
  '37354': {
    zip: '37354',
    city: 'Madisonville',
    state: 'TN',
    latitude: '35.51916',
    longitude: '-84.363478',
    timeZoneId: 'America/New_York',
  },
  '37355': {
    zip: '37355',
    city: 'Manchester',
    state: 'TN',
    latitude: '35.474428',
    longitude: '-86.106025',
    timeZoneId: 'America/Chicago',
  },
  '37356': {
    zip: '37356',
    city: 'Monteagle',
    state: 'TN',
    latitude: '35.219746',
    longitude: '-85.850668',
    timeZoneId: 'America/Chicago',
  },
  '37357': {
    zip: '37357',
    city: 'Morrison',
    state: 'TN',
    latitude: '35.587843',
    longitude: '-85.935375',
    timeZoneId: 'America/Chicago',
  },
  '37359': {
    zip: '37359',
    city: 'Mulberry',
    state: 'TN',
    latitude: '35.182176',
    longitude: '-86.41708',
    timeZoneId: 'America/Chicago',
  },
  '37360': {
    zip: '37360',
    city: 'Normandy',
    state: 'TN',
    latitude: '35.414121',
    longitude: '-86.237865',
    timeZoneId: 'America/Chicago',
  },
  '37361': {
    zip: '37361',
    city: 'Ocoee',
    state: 'TN',
    latitude: '35.107372',
    longitude: '-84.705948',
    timeZoneId: 'America/New_York',
  },
  '37362': {
    zip: '37362',
    city: 'Old Fort',
    state: 'TN',
    latitude: '35.044633',
    longitude: '-84.640632',
    timeZoneId: 'America/New_York',
  },
  '37363': {
    zip: '37363',
    city: 'Ooltewah',
    state: 'TN',
    latitude: '35.087036',
    longitude: '-85.060075',
    timeZoneId: 'America/New_York',
  },
  '37364': {
    zip: '37364',
    city: 'Cleveland',
    state: 'TN',
    latitude: '35.157291',
    longitude: '-84.835529',
    timeZoneId: 'America/New_York',
  },
  '37365': {
    zip: '37365',
    city: 'Palmer',
    state: 'TN',
    latitude: '35.378544',
    longitude: '-85.569238',
    timeZoneId: 'America/Chicago',
  },
  '37366': {
    zip: '37366',
    city: 'Pelham',
    state: 'TN',
    latitude: '35.320199',
    longitude: '-85.789482',
    timeZoneId: 'America/Chicago',
  },
  '37367': {
    zip: '37367',
    city: 'Pikeville',
    state: 'TN',
    latitude: '35.613924',
    longitude: '-85.193725',
    timeZoneId: 'America/Chicago',
  },
  '37369': {
    zip: '37369',
    city: 'Reliance',
    state: 'TN',
    latitude: '35.181454',
    longitude: '-84.505928',
    timeZoneId: 'America/New_York',
  },
  '37370': {
    zip: '37370',
    city: 'Riceville',
    state: 'TN',
    latitude: '35.324129',
    longitude: '-84.658559',
    timeZoneId: 'America/New_York',
  },
  '37371': {
    zip: '37371',
    city: 'Athens',
    state: 'TN',
    latitude: '35.443149',
    longitude: '-84.594792',
    timeZoneId: 'America/New_York',
  },
  '37373': {
    zip: '37373',
    city: 'Sale Creek',
    state: 'TN',
    latitude: '35.38608',
    longitude: '-85.095951',
    timeZoneId: 'America/New_York',
  },
  '37374': {
    zip: '37374',
    city: 'Sequatchie',
    state: 'TN',
    latitude: '35.191382',
    longitude: '-85.659281',
    timeZoneId: 'America/Chicago',
  },
  '37375': {
    zip: '37375',
    city: 'Sewanee',
    state: 'TN',
    latitude: '35.204551',
    longitude: '-85.919546',
    timeZoneId: 'America/Chicago',
  },
  '37376': {
    zip: '37376',
    city: 'Sherwood',
    state: 'TN',
    latitude: '35.05696',
    longitude: '-85.962402',
    timeZoneId: 'America/Chicago',
  },
  '37377': {
    zip: '37377',
    city: 'Signal Mountain',
    state: 'TN',
    latitude: '35.147329',
    longitude: '-85.330097',
    timeZoneId: 'America/New_York',
  },
  '37378': {
    zip: '37378',
    city: 'Smartt',
    state: 'TN',
    latitude: '35.647368',
    longitude: '-85.833968',
    timeZoneId: 'America/Chicago',
  },
  '37379': {
    zip: '37379',
    city: 'Soddy Daisy',
    state: 'TN',
    latitude: '35.265858',
    longitude: '-85.160818',
    timeZoneId: 'America/New_York',
  },
  '37380': {
    zip: '37380',
    city: 'South Pittsburg',
    state: 'TN',
    latitude: '35.009705',
    longitude: '-85.711704',
    timeZoneId: 'America/Chicago',
  },
  '37381': {
    zip: '37381',
    city: 'Spring City',
    state: 'TN',
    latitude: '35.66902',
    longitude: '-84.851318',
    timeZoneId: 'America/New_York',
  },
  '37382': {
    zip: '37382',
    city: 'Summitville',
    state: 'TN',
    latitude: '35.55777',
    longitude: '-85.993171',
    timeZoneId: 'America/Chicago',
  },
  '37383': {
    zip: '37383',
    city: 'Sewanee',
    state: 'TN',
    latitude: '35.201422',
    longitude: '-85.923515',
    timeZoneId: 'America/Chicago',
  },
  '37384': {
    zip: '37384',
    city: 'Soddy Daisy',
    state: 'TN',
    latitude: '35.275049',
    longitude: '-85.167714',
    timeZoneId: 'America/New_York',
  },
  '37385': {
    zip: '37385',
    city: 'Tellico Plains',
    state: 'TN',
    latitude: '35.338706',
    longitude: '-84.234031',
    timeZoneId: 'America/New_York',
  },
  '37387': {
    zip: '37387',
    city: 'Tracy City',
    state: 'TN',
    latitude: '35.277419',
    longitude: '-85.733605',
    timeZoneId: 'America/Chicago',
  },
  '37388': {
    zip: '37388',
    city: 'Tullahoma',
    state: 'TN',
    latitude: '35.371202',
    longitude: '-86.213117',
    timeZoneId: 'America/Chicago',
  },
  '37389': {
    zip: '37389',
    city: 'Arnold AFB',
    state: 'TN',
    latitude: '35.320179',
    longitude: '-86.067451',
    timeZoneId: 'America/Chicago',
  },
  '37391': {
    zip: '37391',
    city: 'Turtletown',
    state: 'TN',
    latitude: '35.143958',
    longitude: '-84.370385',
    timeZoneId: 'America/New_York',
  },
  '37394': {
    zip: '37394',
    city: 'Viola',
    state: 'TN',
    latitude: '35.539624',
    longitude: '-85.85789',
    timeZoneId: 'America/Chicago',
  },
  '37396': {
    zip: '37396',
    city: 'Whiteside',
    state: 'TN',
    latitude: '35.093549',
    longitude: '-85.395042',
    timeZoneId: 'America/Chicago',
  },
  '37397': {
    zip: '37397',
    city: 'Whitwell',
    state: 'TN',
    latitude: '35.198597',
    longitude: '-85.514246',
    timeZoneId: 'America/Chicago',
  },
  '37398': {
    zip: '37398',
    city: 'Winchester',
    state: 'TN',
    latitude: '35.188876',
    longitude: '-86.111111',
    timeZoneId: 'America/Chicago',
  },
  '37401': {
    zip: '37401',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.03852',
    longitude: '-85.308854',
    timeZoneId: 'America/New_York',
  },
  '37402': {
    zip: '37402',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.045624',
    longitude: '-85.310635',
    timeZoneId: 'America/New_York',
  },
  '37403': {
    zip: '37403',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.045024',
    longitude: '-85.296037',
    timeZoneId: 'America/New_York',
  },
  '37404': {
    zip: '37404',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.027912',
    longitude: '-85.272196',
    timeZoneId: 'America/New_York',
  },
  '37405': {
    zip: '37405',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.074768',
    longitude: '-85.309383',
    timeZoneId: 'America/New_York',
  },
  '37406': {
    zip: '37406',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.060724',
    longitude: '-85.245113',
    timeZoneId: 'America/New_York',
  },
  '37407': {
    zip: '37407',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.000945',
    longitude: '-85.285097',
    timeZoneId: 'America/New_York',
  },
  '37408': {
    zip: '37408',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.03167',
    longitude: '-85.306753',
    timeZoneId: 'America/New_York',
  },
  '37409': {
    zip: '37409',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.001427',
    longitude: '-85.332196',
    timeZoneId: 'America/New_York',
  },
  '37410': {
    zip: '37410',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '34.999255',
    longitude: '-85.317893',
    timeZoneId: 'America/New_York',
  },
  '37411': {
    zip: '37411',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.025075',
    longitude: '-85.237257',
    timeZoneId: 'America/New_York',
  },
  '37412': {
    zip: '37412',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '34.997779',
    longitude: '-85.233796',
    timeZoneId: 'America/New_York',
  },
  '37414': {
    zip: '37414',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.026201',
    longitude: '-85.230406',
    timeZoneId: 'America/New_York',
  },
  '37415': {
    zip: '37415',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.127',
    longitude: '-85.27404',
    timeZoneId: 'America/New_York',
  },
  '37416': {
    zip: '37416',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.095902',
    longitude: '-85.1566',
    timeZoneId: 'America/New_York',
  },
  '37419': {
    zip: '37419',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.034095',
    longitude: '-85.367139',
    timeZoneId: 'America/New_York',
  },
  '37421': {
    zip: '37421',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.02529',
    longitude: '-85.144734',
    timeZoneId: 'America/New_York',
  },
  '37422': {
    zip: '37422',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.141047',
    longitude: '-85.348656',
    timeZoneId: 'America/New_York',
  },
  '37424': {
    zip: '37424',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.030239',
    longitude: '-85.148472',
    timeZoneId: 'America/New_York',
  },
  '37450': {
    zip: '37450',
    city: 'Chattanooga',
    state: 'TN',
    latitude: '35.047863',
    longitude: '-85.305894',
    timeZoneId: 'America/New_York',
  },
  '37501': {
    zip: '37501',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.068065',
    longitude: '-89.957585',
    timeZoneId: 'America/Chicago',
  },
  '37544': {
    zip: '37544',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.07032',
    longitude: '-89.95656',
    timeZoneId: 'America/Chicago',
  },
  '37601': {
    zip: '37601',
    city: 'Johnson City',
    state: 'TN',
    latitude: '36.331192',
    longitude: '-82.359362',
    timeZoneId: 'America/New_York',
  },
  '37602': {
    zip: '37602',
    city: 'Johnson City',
    state: 'TN',
    latitude: '36.305672',
    longitude: '-82.383269',
    timeZoneId: 'America/New_York',
  },
  '37604': {
    zip: '37604',
    city: 'Johnson City',
    state: 'TN',
    latitude: '36.309556',
    longitude: '-82.381176',
    timeZoneId: 'America/New_York',
  },
  '37605': {
    zip: '37605',
    city: 'Johnson City',
    state: 'TN',
    latitude: '36.347307',
    longitude: '-82.332037',
    timeZoneId: 'America/New_York',
  },
  '37614': {
    zip: '37614',
    city: 'Johnson City',
    state: 'TN',
    latitude: '36.330892',
    longitude: '-82.365584',
    timeZoneId: 'America/New_York',
  },
  '37615': {
    zip: '37615',
    city: 'Johnson City',
    state: 'TN',
    latitude: '36.37392',
    longitude: '-82.44504',
    timeZoneId: 'America/New_York',
  },
  '37616': {
    zip: '37616',
    city: 'Afton',
    state: 'TN',
    latitude: '36.188867',
    longitude: '-82.702767',
    timeZoneId: 'America/New_York',
  },
  '37617': {
    zip: '37617',
    city: 'Blountville',
    state: 'TN',
    latitude: '36.533107',
    longitude: '-82.359186',
    timeZoneId: 'America/New_York',
  },
  '37618': {
    zip: '37618',
    city: 'Bluff City',
    state: 'TN',
    latitude: '36.469676',
    longitude: '-82.23901',
    timeZoneId: 'America/New_York',
  },
  '37620': {
    zip: '37620',
    city: 'Bristol',
    state: 'TN',
    latitude: '36.569357',
    longitude: '-82.183865',
    timeZoneId: 'America/New_York',
  },
  '37621': {
    zip: '37621',
    city: 'Bristol',
    state: 'TN',
    latitude: '36.580525',
    longitude: '-82.20482',
    timeZoneId: 'America/New_York',
  },
  '37625': {
    zip: '37625',
    city: 'Bristol',
    state: 'TN',
    latitude: '36.596104',
    longitude: '-82.186322',
    timeZoneId: 'America/New_York',
  },
  '37640': {
    zip: '37640',
    city: 'Butler',
    state: 'TN',
    latitude: '36.342296',
    longitude: '-82.220268',
    timeZoneId: 'America/New_York',
  },
  '37641': {
    zip: '37641',
    city: 'Chuckey',
    state: 'TN',
    latitude: '36.151291',
    longitude: '-82.610467',
    timeZoneId: 'America/New_York',
  },
  '37642': {
    zip: '37642',
    city: 'Church Hill',
    state: 'TN',
    latitude: '36.528984',
    longitude: '-82.698396',
    timeZoneId: 'America/New_York',
  },
  '37643': {
    zip: '37643',
    city: 'Elizabethton',
    state: 'TN',
    latitude: '36.342259',
    longitude: '-82.225665',
    timeZoneId: 'America/New_York',
  },
  '37644': {
    zip: '37644',
    city: 'Elizabethton',
    state: 'TN',
    latitude: '36.347396',
    longitude: '-82.213748',
    timeZoneId: 'America/New_York',
  },
  '37645': {
    zip: '37645',
    city: 'Mount Carmel',
    state: 'TN',
    latitude: '36.522222',
    longitude: '-82.713611',
    timeZoneId: 'America/New_York',
  },
  '37650': {
    zip: '37650',
    city: 'Erwin',
    state: 'TN',
    latitude: '36.140916',
    longitude: '-82.409298',
    timeZoneId: 'America/New_York',
  },
  '37656': {
    zip: '37656',
    city: 'Fall Branch',
    state: 'TN',
    latitude: '36.429658',
    longitude: '-82.594132',
    timeZoneId: 'America/New_York',
  },
  '37657': {
    zip: '37657',
    city: 'Flag Pond',
    state: 'TN',
    latitude: '36.024636',
    longitude: '-82.552636',
    timeZoneId: 'America/New_York',
  },
  '37658': {
    zip: '37658',
    city: 'Hampton',
    state: 'TN',
    latitude: '36.241626',
    longitude: '-82.19521',
    timeZoneId: 'America/New_York',
  },
  '37659': {
    zip: '37659',
    city: 'Jonesborough',
    state: 'TN',
    latitude: '36.272018',
    longitude: '-82.487968',
    timeZoneId: 'America/New_York',
  },
  '37660': {
    zip: '37660',
    city: 'Kingsport',
    state: 'TN',
    latitude: '36.548955',
    longitude: '-82.546424',
    timeZoneId: 'America/New_York',
  },
  '37662': {
    zip: '37662',
    city: 'Kingsport',
    state: 'TN',
    latitude: '36.546449',
    longitude: '-82.563833',
    timeZoneId: 'America/New_York',
  },
  '37663': {
    zip: '37663',
    city: 'Kingsport',
    state: 'TN',
    latitude: '36.460654',
    longitude: '-82.484579',
    timeZoneId: 'America/New_York',
  },
  '37664': {
    zip: '37664',
    city: 'Kingsport',
    state: 'TN',
    latitude: '36.524127',
    longitude: '-82.494146',
    timeZoneId: 'America/New_York',
  },
  '37665': {
    zip: '37665',
    city: 'Kingsport',
    state: 'TN',
    latitude: '36.582057',
    longitude: '-82.564868',
    timeZoneId: 'America/New_York',
  },
  '37669': {
    zip: '37669',
    city: 'Kingsport',
    state: 'TN',
    latitude: '36.529122',
    longitude: '-82.556123',
    timeZoneId: 'America/New_York',
  },
  '37680': {
    zip: '37680',
    city: 'Laurel Bloomery',
    state: 'TN',
    latitude: '36.574547',
    longitude: '-81.739325',
    timeZoneId: 'America/New_York',
  },
  '37681': {
    zip: '37681',
    city: 'Limestone',
    state: 'TN',
    latitude: '36.212327',
    longitude: '-82.585503',
    timeZoneId: 'America/New_York',
  },
  '37682': {
    zip: '37682',
    city: 'Milligan College',
    state: 'TN',
    latitude: '36.302307',
    longitude: '-82.296008',
    timeZoneId: 'America/New_York',
  },
  '37683': {
    zip: '37683',
    city: 'Mountain City',
    state: 'TN',
    latitude: '36.473298',
    longitude: '-81.802628',
    timeZoneId: 'America/New_York',
  },
  '37684': {
    zip: '37684',
    city: 'Mountain Home',
    state: 'TN',
    latitude: '36.306702',
    longitude: '-82.377444',
    timeZoneId: 'America/New_York',
  },
  '37686': {
    zip: '37686',
    city: 'Piney Flats',
    state: 'TN',
    latitude: '36.4468',
    longitude: '-82.334349',
    timeZoneId: 'America/New_York',
  },
  '37687': {
    zip: '37687',
    city: 'Roan Mountain',
    state: 'TN',
    latitude: '36.171244',
    longitude: '-82.096567',
    timeZoneId: 'America/New_York',
  },
  '37688': {
    zip: '37688',
    city: 'Shady Valley',
    state: 'TN',
    latitude: '36.545239',
    longitude: '-81.884418',
    timeZoneId: 'America/New_York',
  },
  '37690': {
    zip: '37690',
    city: 'Telford',
    state: 'TN',
    latitude: '36.232998',
    longitude: '-82.54839',
    timeZoneId: 'America/New_York',
  },
  '37691': {
    zip: '37691',
    city: 'Trade',
    state: 'TN',
    latitude: '36.370684',
    longitude: '-81.754167',
    timeZoneId: 'America/New_York',
  },
  '37692': {
    zip: '37692',
    city: 'Unicoi',
    state: 'TN',
    latitude: '36.187403',
    longitude: '-82.342355',
    timeZoneId: 'America/New_York',
  },
  '37694': {
    zip: '37694',
    city: 'Watauga',
    state: 'TN',
    latitude: '36.414288',
    longitude: '-82.270814',
    timeZoneId: 'America/New_York',
  },
  '37699': {
    zip: '37699',
    city: 'Piney Flats',
    state: 'TN',
    latitude: '36.418354',
    longitude: '-82.303895',
    timeZoneId: 'America/New_York',
  },
  '37701': {
    zip: '37701',
    city: 'Alcoa',
    state: 'TN',
    latitude: '35.784224',
    longitude: '-83.980839',
    timeZoneId: 'America/New_York',
  },
  '37705': {
    zip: '37705',
    city: 'Andersonville',
    state: 'TN',
    latitude: '36.212392',
    longitude: '-84.018115',
    timeZoneId: 'America/New_York',
  },
  '37707': {
    zip: '37707',
    city: 'Arthur',
    state: 'TN',
    latitude: '36.425379',
    longitude: '-83.558044',
    timeZoneId: 'America/New_York',
  },
  '37708': {
    zip: '37708',
    city: 'Bean Station',
    state: 'TN',
    latitude: '36.338879',
    longitude: '-83.325091',
    timeZoneId: 'America/New_York',
  },
  '37709': {
    zip: '37709',
    city: 'Blaine',
    state: 'TN',
    latitude: '36.124912',
    longitude: '-83.715281',
    timeZoneId: 'America/New_York',
  },
  '37710': {
    zip: '37710',
    city: 'Briceville',
    state: 'TN',
    latitude: '36.1722',
    longitude: '-84.23148',
    timeZoneId: 'America/New_York',
  },
  '37711': {
    zip: '37711',
    city: 'Bulls Gap',
    state: 'TN',
    latitude: '36.269293',
    longitude: '-83.102067',
    timeZoneId: 'America/New_York',
  },
  '37713': {
    zip: '37713',
    city: 'Bybee',
    state: 'TN',
    latitude: '36.079345',
    longitude: '-83.15754',
    timeZoneId: 'America/New_York',
  },
  '37714': {
    zip: '37714',
    city: 'Caryville',
    state: 'TN',
    latitude: '36.276059',
    longitude: '-84.192974',
    timeZoneId: 'America/New_York',
  },
  '37715': {
    zip: '37715',
    city: 'Clairfield',
    state: 'TN',
    latitude: '36.541619',
    longitude: '-83.874799',
    timeZoneId: 'America/New_York',
  },
  '37716': {
    zip: '37716',
    city: 'Clinton',
    state: 'TN',
    latitude: '36.100413',
    longitude: '-84.140862',
    timeZoneId: 'America/New_York',
  },
  '37717': {
    zip: '37717',
    city: 'Clinton',
    state: 'TN',
    latitude: '36.091034',
    longitude: '-84.13209',
    timeZoneId: 'America/New_York',
  },
  '37719': {
    zip: '37719',
    city: 'Coalfield',
    state: 'TN',
    latitude: '36.052468',
    longitude: '-84.343174',
    timeZoneId: 'America/New_York',
  },
  '37721': {
    zip: '37721',
    city: 'Corryton',
    state: 'TN',
    latitude: '36.124806',
    longitude: '-83.814477',
    timeZoneId: 'America/New_York',
  },
  '37722': {
    zip: '37722',
    city: 'Cosby',
    state: 'TN',
    latitude: '35.807702',
    longitude: '-83.214203',
    timeZoneId: 'America/New_York',
  },
  '37723': {
    zip: '37723',
    city: 'Crab Orchard',
    state: 'TN',
    latitude: '35.895389',
    longitude: '-84.848577',
    timeZoneId: 'America/Chicago',
  },
  '37724': {
    zip: '37724',
    city: 'Cumberland Gap',
    state: 'TN',
    latitude: '36.5423',
    longitude: '-83.689657',
    timeZoneId: 'America/New_York',
  },
  '37725': {
    zip: '37725',
    city: 'Dandridge',
    state: 'TN',
    latitude: '36.020059',
    longitude: '-83.419087',
    timeZoneId: 'America/New_York',
  },
  '37726': {
    zip: '37726',
    city: 'Deer Lodge',
    state: 'TN',
    latitude: '36.220858',
    longitude: '-84.836036',
    timeZoneId: 'America/New_York',
  },
  '37727': {
    zip: '37727',
    city: 'Del Rio',
    state: 'TN',
    latitude: '35.885054',
    longitude: '-83.001681',
    timeZoneId: 'America/New_York',
  },
  '37729': {
    zip: '37729',
    city: 'Duff',
    state: 'TN',
    latitude: '36.426894',
    longitude: '-83.99319',
    timeZoneId: 'America/New_York',
  },
  '37730': {
    zip: '37730',
    city: 'Eagan',
    state: 'TN',
    latitude: '36.551016',
    longitude: '-83.980097',
    timeZoneId: 'America/New_York',
  },
  '37731': {
    zip: '37731',
    city: 'Eidson',
    state: 'TN',
    latitude: '36.497864',
    longitude: '-83.079182',
    timeZoneId: 'America/New_York',
  },
  '37732': {
    zip: '37732',
    city: 'Elgin',
    state: 'TN',
    latitude: '36.46022',
    longitude: '-84.581296',
    timeZoneId: 'America/New_York',
  },
  '37733': {
    zip: '37733',
    city: 'Rugby',
    state: 'TN',
    latitude: '36.36031',
    longitude: '-84.698278',
    timeZoneId: 'America/New_York',
  },
  '37737': {
    zip: '37737',
    city: 'Friendsville',
    state: 'TN',
    latitude: '35.7699',
    longitude: '-84.158597',
    timeZoneId: 'America/New_York',
  },
  '37738': {
    zip: '37738',
    city: 'Gatlinburg',
    state: 'TN',
    latitude: '35.71349',
    longitude: '-83.515498',
    timeZoneId: 'America/New_York',
  },
  '37742': {
    zip: '37742',
    city: 'Greenback',
    state: 'TN',
    latitude: '35.658505',
    longitude: '-84.172683',
    timeZoneId: 'America/New_York',
  },
  '37743': {
    zip: '37743',
    city: 'Greeneville',
    state: 'TN',
    latitude: '36.162752',
    longitude: '-82.818898',
    timeZoneId: 'America/New_York',
  },
  '37744': {
    zip: '37744',
    city: 'Greeneville',
    state: 'TN',
    latitude: '36.163345',
    longitude: '-82.812426',
    timeZoneId: 'America/New_York',
  },
  '37745': {
    zip: '37745',
    city: 'Greeneville',
    state: 'TN',
    latitude: '36.186641',
    longitude: '-82.815175',
    timeZoneId: 'America/New_York',
  },
  '37748': {
    zip: '37748',
    city: 'Harriman',
    state: 'TN',
    latitude: '35.94222',
    longitude: '-84.50046',
    timeZoneId: 'America/New_York',
  },
  '37752': {
    zip: '37752',
    city: 'Harrogate',
    state: 'TN',
    latitude: '36.59462',
    longitude: '-83.66241',
    timeZoneId: 'America/New_York',
  },
  '37753': {
    zip: '37753',
    city: 'Hartford',
    state: 'TN',
    latitude: '35.820547',
    longitude: '-83.101045',
    timeZoneId: 'America/New_York',
  },
  '37754': {
    zip: '37754',
    city: 'Heiskell',
    state: 'TN',
    latitude: '36.087523',
    longitude: '-84.042629',
    timeZoneId: 'America/New_York',
  },
  '37755': {
    zip: '37755',
    city: 'Helenwood',
    state: 'TN',
    latitude: '36.43479',
    longitude: '-84.565182',
    timeZoneId: 'America/New_York',
  },
  '37756': {
    zip: '37756',
    city: 'Huntsville',
    state: 'TN',
    latitude: '36.39588',
    longitude: '-84.45924',
    timeZoneId: 'America/New_York',
  },
  '37757': {
    zip: '37757',
    city: 'Jacksboro',
    state: 'TN',
    latitude: '36.331839',
    longitude: '-84.179659',
    timeZoneId: 'America/New_York',
  },
  '37760': {
    zip: '37760',
    city: 'Jefferson City',
    state: 'TN',
    latitude: '36.119161',
    longitude: '-83.493766',
    timeZoneId: 'America/New_York',
  },
  '37762': {
    zip: '37762',
    city: 'Jellico',
    state: 'TN',
    latitude: '36.578238',
    longitude: '-84.127269',
    timeZoneId: 'America/New_York',
  },
  '37763': {
    zip: '37763',
    city: 'Kingston',
    state: 'TN',
    latitude: '35.876568',
    longitude: '-84.507327',
    timeZoneId: 'America/New_York',
  },
  '37764': {
    zip: '37764',
    city: 'Kodak',
    state: 'TN',
    latitude: '35.979026',
    longitude: '-83.672577',
    timeZoneId: 'America/New_York',
  },
  '37765': {
    zip: '37765',
    city: 'Kyles Ford',
    state: 'TN',
    latitude: '36.564311',
    longitude: '-83.048671',
    timeZoneId: 'America/New_York',
  },
  '37766': {
    zip: '37766',
    city: 'La Follette',
    state: 'TN',
    latitude: '36.33828',
    longitude: '-84.0696',
    timeZoneId: 'America/New_York',
  },
  '37769': {
    zip: '37769',
    city: 'Rocky Top',
    state: 'TN',
    latitude: '36.215045',
    longitude: '-84.153561',
    timeZoneId: 'America/New_York',
  },
  '37770': {
    zip: '37770',
    city: 'Lancing',
    state: 'TN',
    latitude: '36.15153',
    longitude: '-84.688941',
    timeZoneId: 'America/New_York',
  },
  '37771': {
    zip: '37771',
    city: 'Lenoir City',
    state: 'TN',
    latitude: '35.792702',
    longitude: '-84.266138',
    timeZoneId: 'America/New_York',
  },
  '37772': {
    zip: '37772',
    city: 'Lenoir City',
    state: 'TN',
    latitude: '35.787215',
    longitude: '-84.220049',
    timeZoneId: 'America/New_York',
  },
  '37773': {
    zip: '37773',
    city: 'Lone Mountain',
    state: 'TN',
    latitude: '36.392041',
    longitude: '-83.584627',
    timeZoneId: 'America/New_York',
  },
  '37774': {
    zip: '37774',
    city: 'Loudon',
    state: 'TN',
    latitude: '35.707092',
    longitude: '-84.297811',
    timeZoneId: 'America/New_York',
  },
  '37777': {
    zip: '37777',
    city: 'Louisville',
    state: 'TN',
    latitude: '35.831102',
    longitude: '-84.048154',
    timeZoneId: 'America/New_York',
  },
  '37778': {
    zip: '37778',
    city: 'Lowland',
    state: 'TN',
    latitude: '36.162053',
    longitude: '-83.249062',
    timeZoneId: 'America/New_York',
  },
  '37779': {
    zip: '37779',
    city: 'Luttrell',
    state: 'TN',
    latitude: '36.157029',
    longitude: '-83.751395',
    timeZoneId: 'America/New_York',
  },
  '37801': {
    zip: '37801',
    city: 'Maryville',
    state: 'TN',
    latitude: '35.749086',
    longitude: '-83.96954',
    timeZoneId: 'America/New_York',
  },
  '37802': {
    zip: '37802',
    city: 'Maryville',
    state: 'TN',
    latitude: '35.756806',
    longitude: '-83.970335',
    timeZoneId: 'America/New_York',
  },
  '37803': {
    zip: '37803',
    city: 'Maryville',
    state: 'TN',
    latitude: '35.764529',
    longitude: '-83.964122',
    timeZoneId: 'America/New_York',
  },
  '37804': {
    zip: '37804',
    city: 'Maryville',
    state: 'TN',
    latitude: '35.775122',
    longitude: '-83.939035',
    timeZoneId: 'America/New_York',
  },
  '37806': {
    zip: '37806',
    city: 'Mascot',
    state: 'TN',
    latitude: '36.080485',
    longitude: '-83.735652',
    timeZoneId: 'America/New_York',
  },
  '37807': {
    zip: '37807',
    city: 'Maynardville',
    state: 'TN',
    latitude: '36.166587',
    longitude: '-83.911863',
    timeZoneId: 'America/New_York',
  },
  '37809': {
    zip: '37809',
    city: 'Midway',
    state: 'TN',
    latitude: '36.149148',
    longitude: '-83.030331',
    timeZoneId: 'America/New_York',
  },
  '37810': {
    zip: '37810',
    city: 'Mohawk',
    state: 'TN',
    latitude: '36.181796',
    longitude: '-83.093461',
    timeZoneId: 'America/New_York',
  },
  '37811': {
    zip: '37811',
    city: 'Mooresburg',
    state: 'TN',
    latitude: '36.369657',
    longitude: '-83.192901',
    timeZoneId: 'America/New_York',
  },
  '37813': {
    zip: '37813',
    city: 'Morristown',
    state: 'TN',
    latitude: '36.190558',
    longitude: '-83.271169',
    timeZoneId: 'America/New_York',
  },
  '37814': {
    zip: '37814',
    city: 'Morristown',
    state: 'TN',
    latitude: '36.221466',
    longitude: '-83.310936',
    timeZoneId: 'America/New_York',
  },
  '37815': {
    zip: '37815',
    city: 'Morristown',
    state: 'TN',
    latitude: '36.213648',
    longitude: '-83.293749',
    timeZoneId: 'America/New_York',
  },
  '37816': {
    zip: '37816',
    city: 'Morristown',
    state: 'TN',
    latitude: '36.192124',
    longitude: '-83.372532',
    timeZoneId: 'America/New_York',
  },
  '37818': {
    zip: '37818',
    city: 'Mosheim',
    state: 'TN',
    latitude: '36.169896',
    longitude: '-82.973555',
    timeZoneId: 'America/New_York',
  },
  '37819': {
    zip: '37819',
    city: 'Newcomb',
    state: 'TN',
    latitude: '36.544131',
    longitude: '-84.186377',
    timeZoneId: 'America/New_York',
  },
  '37820': {
    zip: '37820',
    city: 'New Market',
    state: 'TN',
    latitude: '36.081197',
    longitude: '-83.567459',
    timeZoneId: 'America/New_York',
  },
  '37821': {
    zip: '37821',
    city: 'Newport',
    state: 'TN',
    latitude: '35.965629',
    longitude: '-83.188913',
    timeZoneId: 'America/New_York',
  },
  '37822': {
    zip: '37822',
    city: 'Newport',
    state: 'TN',
    latitude: '35.957118',
    longitude: '-83.190977',
    timeZoneId: 'America/New_York',
  },
  '37824': {
    zip: '37824',
    city: 'New Tazewell',
    state: 'TN',
    latitude: '36.434143',
    longitude: '-83.60662',
    timeZoneId: 'America/New_York',
  },
  '37825': {
    zip: '37825',
    city: 'New Tazewell',
    state: 'TN',
    latitude: '36.439083',
    longitude: '-83.601957',
    timeZoneId: 'America/New_York',
  },
  '37826': {
    zip: '37826',
    city: 'Niota',
    state: 'TN',
    latitude: '35.509859',
    longitude: '-84.545622',
    timeZoneId: 'America/New_York',
  },
  '37828': {
    zip: '37828',
    city: 'Norris',
    state: 'TN',
    latitude: '36.194145',
    longitude: '-84.069274',
    timeZoneId: 'America/New_York',
  },
  '37829': {
    zip: '37829',
    city: 'Oakdale',
    state: 'TN',
    latitude: '36.013101',
    longitude: '-84.636364',
    timeZoneId: 'America/New_York',
  },
  '37830': {
    zip: '37830',
    city: 'Oak Ridge',
    state: 'TN',
    latitude: '36.020634',
    longitude: '-84.253983',
    timeZoneId: 'America/New_York',
  },
  '37831': {
    zip: '37831',
    city: 'Oak Ridge',
    state: 'TN',
    latitude: '35.963275',
    longitude: '-84.298102',
    timeZoneId: 'America/New_York',
  },
  '37840': {
    zip: '37840',
    city: 'Oliver Springs',
    state: 'TN',
    latitude: '36.032719',
    longitude: '-84.324441',
    timeZoneId: 'America/New_York',
  },
  '37841': {
    zip: '37841',
    city: 'Oneida',
    state: 'TN',
    latitude: '36.506945',
    longitude: '-84.514883',
    timeZoneId: 'America/New_York',
  },
  '37843': {
    zip: '37843',
    city: 'Parrottsville',
    state: 'TN',
    latitude: '35.996437',
    longitude: '-83.053565',
    timeZoneId: 'America/New_York',
  },
  '37845': {
    zip: '37845',
    city: 'Petros',
    state: 'TN',
    latitude: '36.055638',
    longitude: '-84.435067',
    timeZoneId: 'America/New_York',
  },
  '37846': {
    zip: '37846',
    city: 'Philadelphia',
    state: 'TN',
    latitude: '35.678552',
    longitude: '-84.406508',
    timeZoneId: 'America/New_York',
  },
  '37847': {
    zip: '37847',
    city: 'Pioneer',
    state: 'TN',
    latitude: '36.459083',
    longitude: '-84.30266',
    timeZoneId: 'America/New_York',
  },
  '37848': {
    zip: '37848',
    city: 'Powder Springs',
    state: 'TN',
    latitude: '36.252231',
    longitude: '-83.668136',
    timeZoneId: 'America/New_York',
  },
  '37849': {
    zip: '37849',
    city: 'Powell',
    state: 'TN',
    latitude: '36.054476',
    longitude: '-84.029301',
    timeZoneId: 'America/New_York',
  },
  '37851': {
    zip: '37851',
    city: 'Pruden',
    state: 'TN',
    latitude: '36.541011',
    longitude: '-83.876133',
    timeZoneId: 'America/New_York',
  },
  '37852': {
    zip: '37852',
    city: 'Robbins',
    state: 'TN',
    latitude: '36.326828',
    longitude: '-84.571266',
    timeZoneId: 'America/New_York',
  },
  '37853': {
    zip: '37853',
    city: 'Rockford',
    state: 'TN',
    latitude: '35.828507',
    longitude: '-83.944804',
    timeZoneId: 'America/New_York',
  },
  '37854': {
    zip: '37854',
    city: 'Rockwood',
    state: 'TN',
    latitude: '35.862451',
    longitude: '-84.679796',
    timeZoneId: 'America/New_York',
  },
  '37857': {
    zip: '37857',
    city: 'Rogersville',
    state: 'TN',
    latitude: '36.405041',
    longitude: '-83.009455',
    timeZoneId: 'America/New_York',
  },
  '37860': {
    zip: '37860',
    city: 'Russellville',
    state: 'TN',
    latitude: '36.246308',
    longitude: '-83.195495',
    timeZoneId: 'America/New_York',
  },
  '37861': {
    zip: '37861',
    city: 'Rutledge',
    state: 'TN',
    latitude: '36.27777',
    longitude: '-83.517316',
    timeZoneId: 'America/New_York',
  },
  '37862': {
    zip: '37862',
    city: 'Sevierville',
    state: 'TN',
    latitude: '35.871495',
    longitude: '-83.53315',
    timeZoneId: 'America/New_York',
  },
  '37863': {
    zip: '37863',
    city: 'Pigeon Forge',
    state: 'TN',
    latitude: '35.793902',
    longitude: '-83.562814',
    timeZoneId: 'America/New_York',
  },
  '37864': {
    zip: '37864',
    city: 'Sevierville',
    state: 'TN',
    latitude: '35.856862',
    longitude: '-83.577319',
    timeZoneId: 'America/New_York',
  },
  '37865': {
    zip: '37865',
    city: 'Seymour',
    state: 'TN',
    latitude: '35.863635',
    longitude: '-83.739635',
    timeZoneId: 'America/New_York',
  },
  '37866': {
    zip: '37866',
    city: 'Sharps Chapel',
    state: 'TN',
    latitude: '36.356798',
    longitude: '-83.863314',
    timeZoneId: 'America/New_York',
  },
  '37867': {
    zip: '37867',
    city: 'Shawanee',
    state: 'TN',
    latitude: '36.579916',
    longitude: '-83.639506',
    timeZoneId: 'America/New_York',
  },
  '37868': {
    zip: '37868',
    city: 'Pigeon Forge',
    state: 'TN',
    latitude: '35.775608',
    longitude: '-83.537836',
    timeZoneId: 'America/New_York',
  },
  '37869': {
    zip: '37869',
    city: 'Sneedville',
    state: 'TN',
    latitude: '36.535976',
    longitude: '-83.220036',
    timeZoneId: 'America/New_York',
  },
  '37870': {
    zip: '37870',
    city: 'Speedwell',
    state: 'TN',
    latitude: '36.477773',
    longitude: '-83.812782',
    timeZoneId: 'America/New_York',
  },
  '37871': {
    zip: '37871',
    city: 'Strawberry Plains',
    state: 'TN',
    latitude: '36.018011',
    longitude: '-83.70671',
    timeZoneId: 'America/New_York',
  },
  '37872': {
    zip: '37872',
    city: 'Sunbright',
    state: 'TN',
    latitude: '36.27066',
    longitude: '-84.6912',
    timeZoneId: 'America/New_York',
  },
  '37873': {
    zip: '37873',
    city: 'Surgoinsville',
    state: 'TN',
    latitude: '36.46497',
    longitude: '-82.824611',
    timeZoneId: 'America/New_York',
  },
  '37874': {
    zip: '37874',
    city: 'Sweetwater',
    state: 'TN',
    latitude: '35.600803',
    longitude: '-84.460574',
    timeZoneId: 'America/New_York',
  },
  '37876': {
    zip: '37876',
    city: 'Sevierville',
    state: 'TN',
    latitude: '35.839522',
    longitude: '-83.480286',
    timeZoneId: 'America/New_York',
  },
  '37877': {
    zip: '37877',
    city: 'Talbott',
    state: 'TN',
    latitude: '36.174831',
    longitude: '-83.418381',
    timeZoneId: 'America/New_York',
  },
  '37878': {
    zip: '37878',
    city: 'Tallassee',
    state: 'TN',
    latitude: '35.568988',
    longitude: '-83.981124',
    timeZoneId: 'America/New_York',
  },
  '37879': {
    zip: '37879',
    city: 'Tazewell',
    state: 'TN',
    latitude: '36.445486',
    longitude: '-83.58383',
    timeZoneId: 'America/New_York',
  },
  '37880': {
    zip: '37880',
    city: 'Ten Mile',
    state: 'TN',
    latitude: '35.683153',
    longitude: '-84.652784',
    timeZoneId: 'America/New_York',
  },
  '37881': {
    zip: '37881',
    city: 'Thorn Hill',
    state: 'TN',
    latitude: '36.395156',
    longitude: '-83.365973',
    timeZoneId: 'America/New_York',
  },
  '37882': {
    zip: '37882',
    city: 'Townsend',
    state: 'TN',
    latitude: '35.627245',
    longitude: '-83.783892',
    timeZoneId: 'America/New_York',
  },
  '37885': {
    zip: '37885',
    city: 'Vonore',
    state: 'TN',
    latitude: '35.481202',
    longitude: '-84.091965',
    timeZoneId: 'America/New_York',
  },
  '37886': {
    zip: '37886',
    city: 'Walland',
    state: 'TN',
    latitude: '35.776507',
    longitude: '-83.821306',
    timeZoneId: 'America/New_York',
  },
  '37887': {
    zip: '37887',
    city: 'Wartburg',
    state: 'TN',
    latitude: '36.047026',
    longitude: '-84.613764',
    timeZoneId: 'America/New_York',
  },
  '37888': {
    zip: '37888',
    city: 'Washburn',
    state: 'TN',
    latitude: '36.312757',
    longitude: '-83.599222',
    timeZoneId: 'America/New_York',
  },
  '37890': {
    zip: '37890',
    city: 'White Pine',
    state: 'TN',
    latitude: '36.06164',
    longitude: '-83.26011',
    timeZoneId: 'America/New_York',
  },
  '37891': {
    zip: '37891',
    city: 'Whitesburg',
    state: 'TN',
    latitude: '36.258729',
    longitude: '-83.137811',
    timeZoneId: 'America/New_York',
  },
  '37892': {
    zip: '37892',
    city: 'Winfield',
    state: 'TN',
    latitude: '36.52186',
    longitude: '-84.501975',
    timeZoneId: 'America/New_York',
  },
  '37901': {
    zip: '37901',
    city: 'Knoxville',
    state: 'TN',
    latitude: '36.029281',
    longitude: '-83.886332',
    timeZoneId: 'America/New_York',
  },
  '37902': {
    zip: '37902',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.962214',
    longitude: '-83.922984',
    timeZoneId: 'America/New_York',
  },
  '37909': {
    zip: '37909',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.94973',
    longitude: '-84.018625',
    timeZoneId: 'America/New_York',
  },
  '37912': {
    zip: '37912',
    city: 'Knoxville',
    state: 'TN',
    latitude: '36.005751',
    longitude: '-83.981348',
    timeZoneId: 'America/New_York',
  },
  '37914': {
    zip: '37914',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.992378',
    longitude: '-83.825982',
    timeZoneId: 'America/New_York',
  },
  '37915': {
    zip: '37915',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.972359',
    longitude: '-83.89956',
    timeZoneId: 'America/New_York',
  },
  '37916': {
    zip: '37916',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.954943',
    longitude: '-83.93035',
    timeZoneId: 'America/New_York',
  },
  '37917': {
    zip: '37917',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.995236',
    longitude: '-83.917073',
    timeZoneId: 'America/New_York',
  },
  '37918': {
    zip: '37918',
    city: 'Knoxville',
    state: 'TN',
    latitude: '36.049059',
    longitude: '-83.918501',
    timeZoneId: 'America/New_York',
  },
  '37919': {
    zip: '37919',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.926579',
    longitude: '-84.006405',
    timeZoneId: 'America/New_York',
  },
  '37920': {
    zip: '37920',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.923862',
    longitude: '-83.877198',
    timeZoneId: 'America/New_York',
  },
  '37921': {
    zip: '37921',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.97295',
    longitude: '-83.986531',
    timeZoneId: 'America/New_York',
  },
  '37922': {
    zip: '37922',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.85642',
    longitude: '-84.12834',
    timeZoneId: 'America/New_York',
  },
  '37923': {
    zip: '37923',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.924565',
    longitude: '-84.100399',
    timeZoneId: 'America/New_York',
  },
  '37924': {
    zip: '37924',
    city: 'Knoxville',
    state: 'TN',
    latitude: '36.030778',
    longitude: '-83.813882',
    timeZoneId: 'America/New_York',
  },
  '37927': {
    zip: '37927',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.999238',
    longitude: '-83.91077',
    timeZoneId: 'America/New_York',
  },
  '37928': {
    zip: '37928',
    city: 'Knoxville',
    state: 'TN',
    latitude: '36.06009',
    longitude: '-83.911345',
    timeZoneId: 'America/New_York',
  },
  '37929': {
    zip: '37929',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.962625',
    longitude: '-83.921292',
    timeZoneId: 'America/New_York',
  },
  '37930': {
    zip: '37930',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.93017',
    longitude: '-84.077513',
    timeZoneId: 'America/New_York',
  },
  '37931': {
    zip: '37931',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.97327',
    longitude: '-84.11443',
    timeZoneId: 'America/New_York',
  },
  '37932': {
    zip: '37932',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.920159',
    longitude: '-84.174433',
    timeZoneId: 'America/New_York',
  },
  '37933': {
    zip: '37933',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.86607',
    longitude: '-84.124099',
    timeZoneId: 'America/New_York',
  },
  '37934': {
    zip: '37934',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.8761',
    longitude: '-84.1703',
    timeZoneId: 'America/New_York',
  },
  '37938': {
    zip: '37938',
    city: 'Knoxville',
    state: 'TN',
    latitude: '36.114021',
    longitude: '-83.934826',
    timeZoneId: 'America/New_York',
  },
  '37939': {
    zip: '37939',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.915861',
    longitude: '-84.004282',
    timeZoneId: 'America/New_York',
  },
  '37940': {
    zip: '37940',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.899618',
    longitude: '-83.855185',
    timeZoneId: 'America/New_York',
  },
  '37950': {
    zip: '37950',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.944694',
    longitude: '-84.022662',
    timeZoneId: 'America/New_York',
  },
  '37995': {
    zip: '37995',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.963474',
    longitude: '-83.91558',
    timeZoneId: 'America/New_York',
  },
  '37996': {
    zip: '37996',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.956034',
    longitude: '-83.937037',
    timeZoneId: 'America/New_York',
  },
  '37997': {
    zip: '37997',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.965412',
    longitude: '-83.909511',
    timeZoneId: 'America/New_York',
  },
  '37998': {
    zip: '37998',
    city: 'Knoxville',
    state: 'TN',
    latitude: '35.937584',
    longitude: '-83.75048',
    timeZoneId: 'America/New_York',
  },
  '38001': {
    zip: '38001',
    city: 'Alamo',
    state: 'TN',
    latitude: '35.78557',
    longitude: '-89.114394',
    timeZoneId: 'America/Chicago',
  },
  '38002': {
    zip: '38002',
    city: 'Arlington',
    state: 'TN',
    latitude: '35.29206',
    longitude: '-89.7417',
    timeZoneId: 'America/Chicago',
  },
  '38004': {
    zip: '38004',
    city: 'Atoka',
    state: 'TN',
    latitude: '35.43307',
    longitude: '-89.796386',
    timeZoneId: 'America/Chicago',
  },
  '38006': {
    zip: '38006',
    city: 'Bells',
    state: 'TN',
    latitude: '35.730519',
    longitude: '-89.089584',
    timeZoneId: 'America/Chicago',
  },
  '38007': {
    zip: '38007',
    city: 'Bogota',
    state: 'TN',
    latitude: '36.216148',
    longitude: '-89.477327',
    timeZoneId: 'America/Chicago',
  },
  '38008': {
    zip: '38008',
    city: 'Bolivar',
    state: 'TN',
    latitude: '35.265716',
    longitude: '-89.003717',
    timeZoneId: 'America/Chicago',
  },
  '38010': {
    zip: '38010',
    city: 'Braden',
    state: 'TN',
    latitude: '35.201706',
    longitude: '-89.395141',
    timeZoneId: 'America/Chicago',
  },
  '38011': {
    zip: '38011',
    city: 'Brighton',
    state: 'TN',
    latitude: '35.482116',
    longitude: '-89.722391',
    timeZoneId: 'America/Chicago',
  },
  '38012': {
    zip: '38012',
    city: 'Brownsville',
    state: 'TN',
    latitude: '35.597482',
    longitude: '-89.271036',
    timeZoneId: 'America/Chicago',
  },
  '38014': {
    zip: '38014',
    city: 'Brunswick',
    state: 'TN',
    latitude: '35.266062',
    longitude: '-89.772731',
    timeZoneId: 'America/Chicago',
  },
  '38015': {
    zip: '38015',
    city: 'Burlison',
    state: 'TN',
    latitude: '35.560536',
    longitude: '-89.838101',
    timeZoneId: 'America/Chicago',
  },
  '38016': {
    zip: '38016',
    city: 'Cordova',
    state: 'TN',
    latitude: '35.179164',
    longitude: '-89.762665',
    timeZoneId: 'America/Chicago',
  },
  '38017': {
    zip: '38017',
    city: 'Collierville',
    state: 'TN',
    latitude: '35.054102',
    longitude: '-89.679063',
    timeZoneId: 'America/Chicago',
  },
  '38018': {
    zip: '38018',
    city: 'Cordova',
    state: 'TN',
    latitude: '35.13832',
    longitude: '-89.758273',
    timeZoneId: 'America/Chicago',
  },
  '38019': {
    zip: '38019',
    city: 'Covington',
    state: 'TN',
    latitude: '35.557255',
    longitude: '-89.657223',
    timeZoneId: 'America/Chicago',
  },
  '38021': {
    zip: '38021',
    city: 'Crockett Mills',
    state: 'TN',
    latitude: '35.911875',
    longitude: '-89.190532',
    timeZoneId: 'America/Chicago',
  },
  '38023': {
    zip: '38023',
    city: 'Drummonds',
    state: 'TN',
    latitude: '35.468977',
    longitude: '-89.955354',
    timeZoneId: 'America/Chicago',
  },
  '38024': {
    zip: '38024',
    city: 'Dyersburg',
    state: 'TN',
    latitude: '36.039737',
    longitude: '-89.384816',
    timeZoneId: 'America/Chicago',
  },
  '38025': {
    zip: '38025',
    city: 'Dyersburg',
    state: 'TN',
    latitude: '36.036078',
    longitude: '-89.383313',
    timeZoneId: 'America/Chicago',
  },
  '38027': {
    zip: '38027',
    city: 'Collierville',
    state: 'TN',
    latitude: '35.056954',
    longitude: '-89.676442',
    timeZoneId: 'America/Chicago',
  },
  '38028': {
    zip: '38028',
    city: 'Eads',
    state: 'TN',
    latitude: '35.156132',
    longitude: '-89.682958',
    timeZoneId: 'America/Chicago',
  },
  '38029': {
    zip: '38029',
    city: 'Ellendale',
    state: 'TN',
    latitude: '35.202963',
    longitude: '-89.820479',
    timeZoneId: 'America/Chicago',
  },
  '38030': {
    zip: '38030',
    city: 'Finley',
    state: 'TN',
    latitude: '35.990003',
    longitude: '-89.579499',
    timeZoneId: 'America/Chicago',
  },
  '38034': {
    zip: '38034',
    city: 'Friendship',
    state: 'TN',
    latitude: '35.909116',
    longitude: '-89.195058',
    timeZoneId: 'America/Chicago',
  },
  '38036': {
    zip: '38036',
    city: 'Gallaway',
    state: 'TN',
    latitude: '35.324079',
    longitude: '-89.619045',
    timeZoneId: 'America/Chicago',
  },
  '38037': {
    zip: '38037',
    city: 'Gates',
    state: 'TN',
    latitude: '35.830317',
    longitude: '-89.405482',
    timeZoneId: 'America/Chicago',
  },
  '38039': {
    zip: '38039',
    city: 'Grand Junction',
    state: 'TN',
    latitude: '35.272747',
    longitude: '-89.154588',
    timeZoneId: 'America/Chicago',
  },
  '38040': {
    zip: '38040',
    city: 'Halls',
    state: 'TN',
    latitude: '35.875019',
    longitude: '-89.394151',
    timeZoneId: 'America/Chicago',
  },
  '38041': {
    zip: '38041',
    city: 'Henning',
    state: 'TN',
    latitude: '35.673919',
    longitude: '-89.570485',
    timeZoneId: 'America/Chicago',
  },
  '38042': {
    zip: '38042',
    city: 'Hickory Valley',
    state: 'TN',
    latitude: '35.322522',
    longitude: '-89.1522',
    timeZoneId: 'America/Chicago',
  },
  '38044': {
    zip: '38044',
    city: 'Hornsby',
    state: 'TN',
    latitude: '35.223706',
    longitude: '-88.825077',
    timeZoneId: 'America/Chicago',
  },
  '38045': {
    zip: '38045',
    city: 'Laconia',
    state: 'TN',
    latitude: '35.292164',
    longitude: '-89.368803',
    timeZoneId: 'America/Chicago',
  },
  '38046': {
    zip: '38046',
    city: 'La Grange',
    state: 'TN',
    latitude: '35.065576',
    longitude: '-89.329117',
    timeZoneId: 'America/Chicago',
  },
  '38047': {
    zip: '38047',
    city: 'Lenox',
    state: 'TN',
    latitude: '36.064558',
    longitude: '-89.42136',
    timeZoneId: 'America/Chicago',
  },
  '38048': {
    zip: '38048',
    city: 'Macon',
    state: 'TN',
    latitude: '35.151834',
    longitude: '-89.489494',
    timeZoneId: 'America/Chicago',
  },
  '38049': {
    zip: '38049',
    city: 'Mason',
    state: 'TN',
    latitude: '35.394464',
    longitude: '-89.537243',
    timeZoneId: 'America/Chicago',
  },
  '38050': {
    zip: '38050',
    city: 'Maury City',
    state: 'TN',
    latitude: '35.815031',
    longitude: '-89.222697',
    timeZoneId: 'America/Chicago',
  },
  '38052': {
    zip: '38052',
    city: 'Middleton',
    state: 'TN',
    latitude: '35.062209',
    longitude: '-88.895359',
    timeZoneId: 'America/Chicago',
  },
  '38053': {
    zip: '38053',
    city: 'Millington',
    state: 'TN',
    latitude: '35.33166',
    longitude: '-89.89458',
    timeZoneId: 'America/Chicago',
  },
  '38054': {
    zip: '38054',
    city: 'Millington',
    state: 'TN',
    latitude: '35.339231',
    longitude: '-89.877562',
    timeZoneId: 'America/Chicago',
  },
  '38055': {
    zip: '38055',
    city: 'Millington',
    state: 'TN',
    latitude: '35.34056',
    longitude: '-89.894343',
    timeZoneId: 'America/Chicago',
  },
  '38057': {
    zip: '38057',
    city: 'Moscow',
    state: 'TN',
    latitude: '35.059236',
    longitude: '-89.379123',
    timeZoneId: 'America/Chicago',
  },
  '38058': {
    zip: '38058',
    city: 'Munford',
    state: 'TN',
    latitude: '35.448981',
    longitude: '-89.807405',
    timeZoneId: 'America/Chicago',
  },
  '38059': {
    zip: '38059',
    city: 'Newbern',
    state: 'TN',
    latitude: '36.11424',
    longitude: '-89.267273',
    timeZoneId: 'America/Chicago',
  },
  '38060': {
    zip: '38060',
    city: 'Oakland',
    state: 'TN',
    latitude: '35.21295',
    longitude: '-89.542271',
    timeZoneId: 'America/Chicago',
  },
  '38061': {
    zip: '38061',
    city: 'Pocahontas',
    state: 'TN',
    latitude: '35.028862',
    longitude: '-88.816731',
    timeZoneId: 'America/Chicago',
  },
  '38063': {
    zip: '38063',
    city: 'Ripley',
    state: 'TN',
    latitude: '35.73461',
    longitude: '-89.53708',
    timeZoneId: 'America/Chicago',
  },
  '38066': {
    zip: '38066',
    city: 'Rossville',
    state: 'TN',
    latitude: '35.072957',
    longitude: '-89.53623',
    timeZoneId: 'America/Chicago',
  },
  '38067': {
    zip: '38067',
    city: 'Saulsbury',
    state: 'TN',
    latitude: '35.051743',
    longitude: '-88.948776',
    timeZoneId: 'America/Chicago',
  },
  '38068': {
    zip: '38068',
    city: 'Somerville',
    state: 'TN',
    latitude: '35.241585',
    longitude: '-89.359208',
    timeZoneId: 'America/Chicago',
  },
  '38069': {
    zip: '38069',
    city: 'Stanton',
    state: 'TN',
    latitude: '35.441511',
    longitude: '-89.33686',
    timeZoneId: 'America/Chicago',
  },
  '38070': {
    zip: '38070',
    city: 'Tigrett',
    state: 'TN',
    latitude: '36.095127',
    longitude: '-89.237293',
    timeZoneId: 'America/Chicago',
  },
  '38071': {
    zip: '38071',
    city: 'Tipton',
    state: 'TN',
    latitude: '35.423532',
    longitude: '-89.807982',
    timeZoneId: 'America/Chicago',
  },
  '38075': {
    zip: '38075',
    city: 'Whiteville',
    state: 'TN',
    latitude: '35.324796',
    longitude: '-89.14958',
    timeZoneId: 'America/Chicago',
  },
  '38076': {
    zip: '38076',
    city: 'Williston',
    state: 'TN',
    latitude: '35.167612',
    longitude: '-89.377588',
    timeZoneId: 'America/Chicago',
  },
  '38077': {
    zip: '38077',
    city: 'Wynnburg',
    state: 'TN',
    latitude: '36.261189',
    longitude: '-89.523178',
    timeZoneId: 'America/Chicago',
  },
  '38079': {
    zip: '38079',
    city: 'Tiptonville',
    state: 'TN',
    latitude: '36.377752',
    longitude: '-89.478666',
    timeZoneId: 'America/Chicago',
  },
  '38080': {
    zip: '38080',
    city: 'Ridgely',
    state: 'TN',
    latitude: '36.263847',
    longitude: '-89.484004',
    timeZoneId: 'America/Chicago',
  },
  '38083': {
    zip: '38083',
    city: 'Millington',
    state: 'TN',
    latitude: '35.332201',
    longitude: '-89.887885',
    timeZoneId: 'America/Chicago',
  },
  '38088': {
    zip: '38088',
    city: 'Cordova',
    state: 'TN',
    latitude: '35.151852',
    longitude: '-89.790781',
    timeZoneId: 'America/Chicago',
  },
  '38101': {
    zip: '38101',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.131068',
    longitude: '-90.054586',
    timeZoneId: 'America/Chicago',
  },
  '38103': {
    zip: '38103',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.145357',
    longitude: '-90.049688',
    timeZoneId: 'America/Chicago',
  },
  '38104': {
    zip: '38104',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.133557',
    longitude: '-89.998776',
    timeZoneId: 'America/Chicago',
  },
  '38105': {
    zip: '38105',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.150666',
    longitude: '-90.031667',
    timeZoneId: 'America/Chicago',
  },
  '38106': {
    zip: '38106',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.107211',
    longitude: '-90.042832',
    timeZoneId: 'America/Chicago',
  },
  '38107': {
    zip: '38107',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.168302',
    longitude: '-90.0283',
    timeZoneId: 'America/Chicago',
  },
  '38108': {
    zip: '38108',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.180649',
    longitude: '-89.954309',
    timeZoneId: 'America/Chicago',
  },
  '38109': {
    zip: '38109',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.046864',
    longitude: '-90.068205',
    timeZoneId: 'America/Chicago',
  },
  '38111': {
    zip: '38111',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.112624',
    longitude: '-89.946832',
    timeZoneId: 'America/Chicago',
  },
  '38112': {
    zip: '38112',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.145279',
    longitude: '-89.979378',
    timeZoneId: 'America/Chicago',
  },
  '38113': {
    zip: '38113',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.209681',
    longitude: '-89.996175',
    timeZoneId: 'America/Chicago',
  },
  '38114': {
    zip: '38114',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.102122',
    longitude: '-89.980902',
    timeZoneId: 'America/Chicago',
  },
  '38115': {
    zip: '38115',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.045523',
    longitude: '-89.849029',
    timeZoneId: 'America/Chicago',
  },
  '38116': {
    zip: '38116',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.036558',
    longitude: '-90.00901',
    timeZoneId: 'America/Chicago',
  },
  '38117': {
    zip: '38117',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.11597',
    longitude: '-89.901902',
    timeZoneId: 'America/Chicago',
  },
  '38118': {
    zip: '38118',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.048583',
    longitude: '-89.925961',
    timeZoneId: 'America/Chicago',
  },
  '38119': {
    zip: '38119',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.085796',
    longitude: '-89.848658',
    timeZoneId: 'America/Chicago',
  },
  '38120': {
    zip: '38120',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.122753',
    longitude: '-89.861988',
    timeZoneId: 'America/Chicago',
  },
  '38122': {
    zip: '38122',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.158002',
    longitude: '-89.923644',
    timeZoneId: 'America/Chicago',
  },
  '38124': {
    zip: '38124',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.111049',
    longitude: '-89.907808',
    timeZoneId: 'America/Chicago',
  },
  '38125': {
    zip: '38125',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.029029',
    longitude: '-89.810269',
    timeZoneId: 'America/Chicago',
  },
  '38126': {
    zip: '38126',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.12402',
    longitude: '-90.041534',
    timeZoneId: 'America/Chicago',
  },
  '38127': {
    zip: '38127',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.240147',
    longitude: '-90.020802',
    timeZoneId: 'America/Chicago',
  },
  '38128': {
    zip: '38128',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.219361',
    longitude: '-89.925899',
    timeZoneId: 'America/Chicago',
  },
  '38130': {
    zip: '38130',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.032451',
    longitude: '-89.938116',
    timeZoneId: 'America/Chicago',
  },
  '38131': {
    zip: '38131',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.063425',
    longitude: '-89.993345',
    timeZoneId: 'America/Chicago',
  },
  '38132': {
    zip: '38132',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.062992',
    longitude: '-89.976596',
    timeZoneId: 'America/Chicago',
  },
  '38133': {
    zip: '38133',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.206829',
    longitude: '-89.791238',
    timeZoneId: 'America/Chicago',
  },
  '38134': {
    zip: '38134',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.19186',
    longitude: '-89.92536',
    timeZoneId: 'America/Chicago',
  },
  '38135': {
    zip: '38135',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.229541',
    longitude: '-89.853081',
    timeZoneId: 'America/Chicago',
  },
  '38136': {
    zip: '38136',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.099619',
    longitude: '-90.036265',
    timeZoneId: 'America/Chicago',
  },
  '38137': {
    zip: '38137',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.110214',
    longitude: '-89.891356',
    timeZoneId: 'America/Chicago',
  },
  '38138': {
    zip: '38138',
    city: 'Germantown',
    state: 'TN',
    latitude: '35.082896',
    longitude: '-89.791615',
    timeZoneId: 'America/Chicago',
  },
  '38139': {
    zip: '38139',
    city: 'Germantown',
    state: 'TN',
    latitude: '35.08928',
    longitude: '-89.773074',
    timeZoneId: 'America/Chicago',
  },
  '38141': {
    zip: '38141',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.02636',
    longitude: '-89.847033',
    timeZoneId: 'America/Chicago',
  },
  '38145': {
    zip: '38145',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.142373',
    longitude: '-90.050604',
    timeZoneId: 'America/Chicago',
  },
  '38148': {
    zip: '38148',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.146943',
    longitude: '-90.046493',
    timeZoneId: 'America/Chicago',
  },
  '38150': {
    zip: '38150',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.147314',
    longitude: '-90.048201',
    timeZoneId: 'America/Chicago',
  },
  '38151': {
    zip: '38151',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.149052',
    longitude: '-89.977749',
    timeZoneId: 'America/Chicago',
  },
  '38152': {
    zip: '38152',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.120123',
    longitude: '-89.939705',
    timeZoneId: 'America/Chicago',
  },
  '38157': {
    zip: '38157',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.111106',
    longitude: '-89.896047',
    timeZoneId: 'America/Chicago',
  },
  '38159': {
    zip: '38159',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.148592',
    longitude: '-90.054728',
    timeZoneId: 'America/Chicago',
  },
  '38161': {
    zip: '38161',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.136604',
    longitude: '-89.860771',
    timeZoneId: 'America/Chicago',
  },
  '38163': {
    zip: '38163',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.141451',
    longitude: '-90.035289',
    timeZoneId: 'America/Chicago',
  },
  '38166': {
    zip: '38166',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.130758',
    longitude: '-90.004671',
    timeZoneId: 'America/Chicago',
  },
  '38167': {
    zip: '38167',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.255312',
    longitude: '-90.030337',
    timeZoneId: 'America/Chicago',
  },
  '38168': {
    zip: '38168',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.229844',
    longitude: '-89.924595',
    timeZoneId: 'America/Chicago',
  },
  '38173': {
    zip: '38173',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.143248',
    longitude: '-90.054456',
    timeZoneId: 'America/Chicago',
  },
  '38174': {
    zip: '38174',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.132269',
    longitude: '-90.002258',
    timeZoneId: 'America/Chicago',
  },
  '38175': {
    zip: '38175',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.046145',
    longitude: '-89.865973',
    timeZoneId: 'America/Chicago',
  },
  '38177': {
    zip: '38177',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.11471',
    longitude: '-89.904468',
    timeZoneId: 'America/Chicago',
  },
  '38181': {
    zip: '38181',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.052297',
    longitude: '-89.942237',
    timeZoneId: 'America/Chicago',
  },
  '38182': {
    zip: '38182',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.145303',
    longitude: '-89.970637',
    timeZoneId: 'America/Chicago',
  },
  '38183': {
    zip: '38183',
    city: 'Germantown',
    state: 'TN',
    latitude: '35.097353',
    longitude: '-89.805551',
    timeZoneId: 'America/Chicago',
  },
  '38184': {
    zip: '38184',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.185496',
    longitude: '-89.864856',
    timeZoneId: 'America/Chicago',
  },
  '38186': {
    zip: '38186',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.035348',
    longitude: '-90.015959',
    timeZoneId: 'America/Chicago',
  },
  '38187': {
    zip: '38187',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.07607',
    longitude: '-89.843603',
    timeZoneId: 'America/Chicago',
  },
  '38188': {
    zip: '38188',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.096999',
    longitude: '-89.85768',
    timeZoneId: 'America/Chicago',
  },
  '38190': {
    zip: '38190',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.056208',
    longitude: '-90.060145',
    timeZoneId: 'America/Chicago',
  },
  '38193': {
    zip: '38193',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.055189',
    longitude: '-89.86291',
    timeZoneId: 'America/Chicago',
  },
  '38194': {
    zip: '38194',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.064129',
    longitude: '-89.999493',
    timeZoneId: 'America/Chicago',
  },
  '38197': {
    zip: '38197',
    city: 'Memphis',
    state: 'TN',
    latitude: '35.09904',
    longitude: '-89.848207',
    timeZoneId: 'America/Chicago',
  },
  '38201': {
    zip: '38201',
    city: 'Mc Kenzie',
    state: 'TN',
    latitude: '36.134856',
    longitude: '-88.517265',
    timeZoneId: 'America/Chicago',
  },
  '38220': {
    zip: '38220',
    city: 'Atwood',
    state: 'TN',
    latitude: '36.011358',
    longitude: '-88.715032',
    timeZoneId: 'America/Chicago',
  },
  '38221': {
    zip: '38221',
    city: 'Big Sandy',
    state: 'TN',
    latitude: '36.23919',
    longitude: '-88.060257',
    timeZoneId: 'America/Chicago',
  },
  '38222': {
    zip: '38222',
    city: 'Buchanan',
    state: 'TN',
    latitude: '36.346048',
    longitude: '-88.380509',
    timeZoneId: 'America/Chicago',
  },
  '38223': {
    zip: '38223',
    city: 'Como',
    state: 'TN',
    latitude: '36.357791',
    longitude: '-88.463805',
    timeZoneId: 'America/Chicago',
  },
  '38224': {
    zip: '38224',
    city: 'Cottage Grove',
    state: 'TN',
    latitude: '36.379988',
    longitude: '-88.480605',
    timeZoneId: 'America/Chicago',
  },
  '38225': {
    zip: '38225',
    city: 'Dresden',
    state: 'TN',
    latitude: '36.29046',
    longitude: '-88.704397',
    timeZoneId: 'America/Chicago',
  },
  '38226': {
    zip: '38226',
    city: 'Dukedom',
    state: 'TN',
    latitude: '36.472923',
    longitude: '-88.6673',
    timeZoneId: 'America/Chicago',
  },
  '38229': {
    zip: '38229',
    city: 'Gleason',
    state: 'TN',
    latitude: '36.216363',
    longitude: '-88.616472',
    timeZoneId: 'America/Chicago',
  },
  '38230': {
    zip: '38230',
    city: 'Greenfield',
    state: 'TN',
    latitude: '36.151574',
    longitude: '-88.799392',
    timeZoneId: 'America/Chicago',
  },
  '38231': {
    zip: '38231',
    city: 'Henry',
    state: 'TN',
    latitude: '36.206881',
    longitude: '-88.464225',
    timeZoneId: 'America/Chicago',
  },
  '38232': {
    zip: '38232',
    city: 'Hornbeak',
    state: 'TN',
    latitude: '36.331585',
    longitude: '-89.311065',
    timeZoneId: 'America/Chicago',
  },
  '38233': {
    zip: '38233',
    city: 'Kenton',
    state: 'TN',
    latitude: '36.177966',
    longitude: '-89.025271',
    timeZoneId: 'America/Chicago',
  },
  '38235': {
    zip: '38235',
    city: 'Mc Lemoresville',
    state: 'TN',
    latitude: '35.985579',
    longitude: '-88.581792',
    timeZoneId: 'America/Chicago',
  },
  '38236': {
    zip: '38236',
    city: 'Mansfield',
    state: 'TN',
    latitude: '36.183352',
    longitude: '-88.285932',
    timeZoneId: 'America/Chicago',
  },
  '38237': {
    zip: '38237',
    city: 'Martin',
    state: 'TN',
    latitude: '36.338425',
    longitude: '-88.853587',
    timeZoneId: 'America/Chicago',
  },
  '38238': {
    zip: '38238',
    city: 'Martin',
    state: 'TN',
    latitude: '36.344329',
    longitude: '-88.858264',
    timeZoneId: 'America/Chicago',
  },
  '38240': {
    zip: '38240',
    city: 'Obion',
    state: 'TN',
    latitude: '36.261145',
    longitude: '-89.190894',
    timeZoneId: 'America/Chicago',
  },
  '38241': {
    zip: '38241',
    city: 'Palmersville',
    state: 'TN',
    latitude: '36.284486',
    longitude: '-88.699663',
    timeZoneId: 'America/Chicago',
  },
  '38242': {
    zip: '38242',
    city: 'Paris',
    state: 'TN',
    latitude: '36.302656',
    longitude: '-88.314279',
    timeZoneId: 'America/Chicago',
  },
  '38251': {
    zip: '38251',
    city: 'Puryear',
    state: 'TN',
    latitude: '36.446292',
    longitude: '-88.332515',
    timeZoneId: 'America/Chicago',
  },
  '38253': {
    zip: '38253',
    city: 'Rives',
    state: 'TN',
    latitude: '36.323149',
    longitude: '-89.039676',
    timeZoneId: 'America/Chicago',
  },
  '38254': {
    zip: '38254',
    city: 'Samburg',
    state: 'TN',
    latitude: '36.357242',
    longitude: '-89.298053',
    timeZoneId: 'America/Chicago',
  },
  '38255': {
    zip: '38255',
    city: 'Sharon',
    state: 'TN',
    latitude: '36.235538',
    longitude: '-88.824945',
    timeZoneId: 'America/Chicago',
  },
  '38256': {
    zip: '38256',
    city: 'Springville',
    state: 'TN',
    latitude: '36.381413',
    longitude: '-88.105873',
    timeZoneId: 'America/Chicago',
  },
  '38257': {
    zip: '38257',
    city: 'South Fulton',
    state: 'TN',
    latitude: '36.496436',
    longitude: '-88.876214',
    timeZoneId: 'America/Chicago',
  },
  '38258': {
    zip: '38258',
    city: 'Trezevant',
    state: 'TN',
    latitude: '36.031357',
    longitude: '-88.6029',
    timeZoneId: 'America/Chicago',
  },
  '38259': {
    zip: '38259',
    city: 'Trimble',
    state: 'TN',
    latitude: '36.201934',
    longitude: '-89.190422',
    timeZoneId: 'America/Chicago',
  },
  '38260': {
    zip: '38260',
    city: 'Troy',
    state: 'TN',
    latitude: '36.335456',
    longitude: '-89.165183',
    timeZoneId: 'America/Chicago',
  },
  '38261': {
    zip: '38261',
    city: 'Union City',
    state: 'TN',
    latitude: '36.424975',
    longitude: '-89.056137',
    timeZoneId: 'America/Chicago',
  },
  '38271': {
    zip: '38271',
    city: 'Woodland Mills',
    state: 'TN',
    latitude: '36.430309',
    longitude: '-89.123003',
    timeZoneId: 'America/Chicago',
  },
  '38281': {
    zip: '38281',
    city: 'Union City',
    state: 'TN',
    latitude: '36.425852',
    longitude: '-89.049841',
    timeZoneId: 'America/Chicago',
  },
  '38301': {
    zip: '38301',
    city: 'Jackson',
    state: 'TN',
    latitude: '35.610381',
    longitude: '-88.819872',
    timeZoneId: 'America/Chicago',
  },
  '38302': {
    zip: '38302',
    city: 'Jackson',
    state: 'TN',
    latitude: '35.59022',
    longitude: '-88.860766',
    timeZoneId: 'America/Chicago',
  },
  '38303': {
    zip: '38303',
    city: 'Jackson',
    state: 'TN',
    latitude: '35.639272',
    longitude: '-88.823732',
    timeZoneId: 'America/Chicago',
  },
  '38305': {
    zip: '38305',
    city: 'Jackson',
    state: 'TN',
    latitude: '35.681229',
    longitude: '-88.821998',
    timeZoneId: 'America/Chicago',
  },
  '38308': {
    zip: '38308',
    city: 'Jackson',
    state: 'TN',
    latitude: '35.59363',
    longitude: '-88.820531',
    timeZoneId: 'America/Chicago',
  },
  '38310': {
    zip: '38310',
    city: 'Adamsville',
    state: 'TN',
    latitude: '35.231782',
    longitude: '-88.387691',
    timeZoneId: 'America/Chicago',
  },
  '38311': {
    zip: '38311',
    city: 'Bath Springs',
    state: 'TN',
    latitude: '35.441131',
    longitude: '-88.103977',
    timeZoneId: 'America/Chicago',
  },
  '38313': {
    zip: '38313',
    city: 'Beech Bluff',
    state: 'TN',
    latitude: '35.60398',
    longitude: '-88.645457',
    timeZoneId: 'America/Chicago',
  },
  '38314': {
    zip: '38314',
    city: 'Jackson',
    state: 'TN',
    latitude: '35.57268',
    longitude: '-88.8213',
    timeZoneId: 'America/Chicago',
  },
  '38315': {
    zip: '38315',
    city: 'Bethel Springs',
    state: 'TN',
    latitude: '35.225473',
    longitude: '-88.661743',
    timeZoneId: 'America/Chicago',
  },
  '38316': {
    zip: '38316',
    city: 'Bradford',
    state: 'TN',
    latitude: '36.065895',
    longitude: '-88.807989',
    timeZoneId: 'America/Chicago',
  },
  '38317': {
    zip: '38317',
    city: 'Bruceton',
    state: 'TN',
    latitude: '36.038756',
    longitude: '-88.246285',
    timeZoneId: 'America/Chicago',
  },
  '38318': {
    zip: '38318',
    city: 'Buena Vista',
    state: 'TN',
    latitude: '35.937514',
    longitude: '-88.270198',
    timeZoneId: 'America/Chicago',
  },
  '38320': {
    zip: '38320',
    city: 'Camden',
    state: 'TN',
    latitude: '36.05848',
    longitude: '-88.102425',
    timeZoneId: 'America/Chicago',
  },
  '38321': {
    zip: '38321',
    city: 'Cedar Grove',
    state: 'TN',
    latitude: '35.861718',
    longitude: '-88.520863',
    timeZoneId: 'America/Chicago',
  },
  '38324': {
    zip: '38324',
    city: 'Clarksburg',
    state: 'TN',
    latitude: '35.862842',
    longitude: '-88.379326',
    timeZoneId: 'America/Chicago',
  },
  '38326': {
    zip: '38326',
    city: 'Counce',
    state: 'TN',
    latitude: '35.168761',
    longitude: '-88.213907',
    timeZoneId: 'America/Chicago',
  },
  '38327': {
    zip: '38327',
    city: 'Crump',
    state: 'TN',
    latitude: '35.221868',
    longitude: '-88.323878',
    timeZoneId: 'America/Chicago',
  },
  '38328': {
    zip: '38328',
    city: 'Darden',
    state: 'TN',
    latitude: '35.673536',
    longitude: '-88.215747',
    timeZoneId: 'America/Chicago',
  },
  '38329': {
    zip: '38329',
    city: 'Decaturville',
    state: 'TN',
    latitude: '35.543475',
    longitude: '-88.107852',
    timeZoneId: 'America/Chicago',
  },
  '38330': {
    zip: '38330',
    city: 'Dyer',
    state: 'TN',
    latitude: '36.068208',
    longitude: '-89.016996',
    timeZoneId: 'America/Chicago',
  },
  '38331': {
    zip: '38331',
    city: 'Eaton',
    state: 'TN',
    latitude: '35.972969',
    longitude: '-89.133432',
    timeZoneId: 'America/Chicago',
  },
  '38332': {
    zip: '38332',
    city: 'Enville',
    state: 'TN',
    latitude: '35.472931',
    longitude: '-88.417941',
    timeZoneId: 'America/Chicago',
  },
  '38333': {
    zip: '38333',
    city: 'Eva',
    state: 'TN',
    latitude: '36.114028',
    longitude: '-87.995028',
    timeZoneId: 'America/Chicago',
  },
  '38334': {
    zip: '38334',
    city: 'Finger',
    state: 'TN',
    latitude: '35.318925',
    longitude: '-88.596816',
    timeZoneId: 'America/Chicago',
  },
  '38336': {
    zip: '38336',
    city: 'Fruitvale',
    state: 'TN',
    latitude: '35.718807',
    longitude: '-89.064976',
    timeZoneId: 'America/Chicago',
  },
  '38337': {
    zip: '38337',
    city: 'Gadsden',
    state: 'TN',
    latitude: '35.772006',
    longitude: '-88.988408',
    timeZoneId: 'America/Chicago',
  },
  '38338': {
    zip: '38338',
    city: 'Gibson',
    state: 'TN',
    latitude: '35.870429',
    longitude: '-88.84793',
    timeZoneId: 'America/Chicago',
  },
  '38339': {
    zip: '38339',
    city: 'Guys',
    state: 'TN',
    latitude: '35.016979',
    longitude: '-88.528146',
    timeZoneId: 'America/Chicago',
  },
  '38340': {
    zip: '38340',
    city: 'Henderson',
    state: 'TN',
    latitude: '35.424648',
    longitude: '-88.653725',
    timeZoneId: 'America/Chicago',
  },
  '38341': {
    zip: '38341',
    city: 'Holladay',
    state: 'TN',
    latitude: '35.897318',
    longitude: '-88.074904',
    timeZoneId: 'America/Chicago',
  },
  '38342': {
    zip: '38342',
    city: 'Hollow Rock',
    state: 'TN',
    latitude: '36.02787',
    longitude: '-88.277775',
    timeZoneId: 'America/Chicago',
  },
  '38343': {
    zip: '38343',
    city: 'Humboldt',
    state: 'TN',
    latitude: '35.83759',
    longitude: '-88.911952',
    timeZoneId: 'America/Chicago',
  },
  '38344': {
    zip: '38344',
    city: 'Huntingdon',
    state: 'TN',
    latitude: '35.99706',
    longitude: '-88.4229',
    timeZoneId: 'America/Chicago',
  },
  '38345': {
    zip: '38345',
    city: 'Huron',
    state: 'TN',
    latitude: '35.58678',
    longitude: '-88.508713',
    timeZoneId: 'America/Chicago',
  },
  '38346': {
    zip: '38346',
    city: 'Idlewild',
    state: 'TN',
    latitude: '36.028634',
    longitude: '-88.801305',
    timeZoneId: 'America/Chicago',
  },
  '38347': {
    zip: '38347',
    city: 'Jacks Creek',
    state: 'TN',
    latitude: '35.487538',
    longitude: '-88.499439',
    timeZoneId: 'America/Chicago',
  },
  '38348': {
    zip: '38348',
    city: 'Lavinia',
    state: 'TN',
    latitude: '35.864377',
    longitude: '-88.649514',
    timeZoneId: 'America/Chicago',
  },
  '38351': {
    zip: '38351',
    city: 'Lexington',
    state: 'TN',
    latitude: '35.658539',
    longitude: '-88.400806',
    timeZoneId: 'America/Chicago',
  },
  '38352': {
    zip: '38352',
    city: 'Luray',
    state: 'TN',
    latitude: '35.685659',
    longitude: '-88.877568',
    timeZoneId: 'America/Chicago',
  },
  '38355': {
    zip: '38355',
    city: 'Medina',
    state: 'TN',
    latitude: '35.761901',
    longitude: '-88.78309',
    timeZoneId: 'America/Chicago',
  },
  '38356': {
    zip: '38356',
    city: 'Medon',
    state: 'TN',
    latitude: '35.468906',
    longitude: '-88.895631',
    timeZoneId: 'America/Chicago',
  },
  '38357': {
    zip: '38357',
    city: 'Michie',
    state: 'TN',
    latitude: '35.069645',
    longitude: '-88.444749',
    timeZoneId: 'America/Chicago',
  },
  '38358': {
    zip: '38358',
    city: 'Milan',
    state: 'TN',
    latitude: '35.923931',
    longitude: '-88.765013',
    timeZoneId: 'America/Chicago',
  },
  '38359': {
    zip: '38359',
    city: 'Milledgeville',
    state: 'TN',
    latitude: '35.342148',
    longitude: '-88.406142',
    timeZoneId: 'America/Chicago',
  },
  '38361': {
    zip: '38361',
    city: 'Morris Chapel',
    state: 'TN',
    latitude: '35.309868',
    longitude: '-88.287025',
    timeZoneId: 'America/Chicago',
  },
  '38362': {
    zip: '38362',
    city: 'Oakfield',
    state: 'TN',
    latitude: '35.721568',
    longitude: '-88.77478',
    timeZoneId: 'America/Chicago',
  },
  '38363': {
    zip: '38363',
    city: 'Parsons',
    state: 'TN',
    latitude: '35.644216',
    longitude: '-88.126023',
    timeZoneId: 'America/Chicago',
  },
  '38365': {
    zip: '38365',
    city: 'Pickwick Dam',
    state: 'TN',
    latitude: '35.036164',
    longitude: '-88.288421',
    timeZoneId: 'America/Chicago',
  },
  '38366': {
    zip: '38366',
    city: 'Pinson',
    state: 'TN',
    latitude: '35.487021',
    longitude: '-88.741874',
    timeZoneId: 'America/Chicago',
  },
  '38367': {
    zip: '38367',
    city: 'Ramer',
    state: 'TN',
    latitude: '35.052884',
    longitude: '-88.638071',
    timeZoneId: 'America/Chicago',
  },
  '38368': {
    zip: '38368',
    city: 'Reagan',
    state: 'TN',
    latitude: '35.512768',
    longitude: '-88.355669',
    timeZoneId: 'America/Chicago',
  },
  '38369': {
    zip: '38369',
    city: 'Rutherford',
    state: 'TN',
    latitude: '36.125967',
    longitude: '-88.975445',
    timeZoneId: 'America/Chicago',
  },
  '38370': {
    zip: '38370',
    city: 'Saltillo',
    state: 'TN',
    latitude: '35.382497',
    longitude: '-88.209373',
    timeZoneId: 'America/Chicago',
  },
  '38371': {
    zip: '38371',
    city: 'Sardis',
    state: 'TN',
    latitude: '35.437411',
    longitude: '-88.307156',
    timeZoneId: 'America/Chicago',
  },
  '38372': {
    zip: '38372',
    city: 'Savannah',
    state: 'TN',
    latitude: '35.226194',
    longitude: '-88.233434',
    timeZoneId: 'America/Chicago',
  },
  '38374': {
    zip: '38374',
    city: 'Scotts Hill',
    state: 'TN',
    latitude: '35.504287',
    longitude: '-88.236798',
    timeZoneId: 'America/Chicago',
  },
  '38375': {
    zip: '38375',
    city: 'Selmer',
    state: 'TN',
    latitude: '35.1537',
    longitude: '-88.611',
    timeZoneId: 'America/Chicago',
  },
  '38376': {
    zip: '38376',
    city: 'Shiloh',
    state: 'TN',
    latitude: '35.129598',
    longitude: '-88.342137',
    timeZoneId: 'America/Chicago',
  },
  '38377': {
    zip: '38377',
    city: 'Silerton',
    state: 'TN',
    latitude: '35.362195',
    longitude: '-88.829203',
    timeZoneId: 'America/Chicago',
  },
  '38378': {
    zip: '38378',
    city: 'Spring Creek',
    state: 'TN',
    latitude: '35.769058',
    longitude: '-88.665436',
    timeZoneId: 'America/Chicago',
  },
  '38379': {
    zip: '38379',
    city: 'Stantonville',
    state: 'TN',
    latitude: '35.184702',
    longitude: '-88.443973',
    timeZoneId: 'America/Chicago',
  },
  '38380': {
    zip: '38380',
    city: 'Sugar Tree',
    state: 'TN',
    latitude: '35.781661',
    longitude: '-87.994033',
    timeZoneId: 'America/Chicago',
  },
  '38381': {
    zip: '38381',
    city: 'Toone',
    state: 'TN',
    latitude: '35.363456',
    longitude: '-88.937564',
    timeZoneId: 'America/Chicago',
  },
  '38382': {
    zip: '38382',
    city: 'Trenton',
    state: 'TN',
    latitude: '35.973792',
    longitude: '-88.968578',
    timeZoneId: 'America/Chicago',
  },
  '38387': {
    zip: '38387',
    city: 'Westport',
    state: 'TN',
    latitude: '35.889982',
    longitude: '-88.297478',
    timeZoneId: 'America/Chicago',
  },
  '38388': {
    zip: '38388',
    city: 'Wildersville',
    state: 'TN',
    latitude: '35.721022',
    longitude: '-88.390326',
    timeZoneId: 'America/Chicago',
  },
  '38389': {
    zip: '38389',
    city: 'Yorkville',
    state: 'TN',
    latitude: '36.077305',
    longitude: '-89.060592',
    timeZoneId: 'America/Chicago',
  },
  '38390': {
    zip: '38390',
    city: 'Yuma',
    state: 'TN',
    latitude: '35.865184',
    longitude: '-88.379591',
    timeZoneId: 'America/Chicago',
  },
  '38391': {
    zip: '38391',
    city: 'Denmark',
    state: 'TN',
    latitude: '35.542234',
    longitude: '-88.992348',
    timeZoneId: 'America/Chicago',
  },
  '38392': {
    zip: '38392',
    city: 'Mercer',
    state: 'TN',
    latitude: '35.494613',
    longitude: '-89.039633',
    timeZoneId: 'America/Chicago',
  },
  '38393': {
    zip: '38393',
    city: 'Chewalla',
    state: 'TN',
    latitude: '35.054641',
    longitude: '-88.635842',
    timeZoneId: 'America/Chicago',
  },
  '38401': {
    zip: '38401',
    city: 'Columbia',
    state: 'TN',
    latitude: '35.609931',
    longitude: '-87.048941',
    timeZoneId: 'America/Chicago',
  },
  '38402': {
    zip: '38402',
    city: 'Columbia',
    state: 'TN',
    latitude: '35.615211',
    longitude: '-87.031789',
    timeZoneId: 'America/Chicago',
  },
  '38425': {
    zip: '38425',
    city: 'Clifton',
    state: 'TN',
    latitude: '35.388057',
    longitude: '-87.990069',
    timeZoneId: 'America/Chicago',
  },
  '38449': {
    zip: '38449',
    city: 'Ardmore',
    state: 'TN',
    latitude: '35.080509',
    longitude: '-86.889332',
    timeZoneId: 'America/Chicago',
  },
  '38450': {
    zip: '38450',
    city: 'Collinwood',
    state: 'TN',
    latitude: '35.150728',
    longitude: '-87.703441',
    timeZoneId: 'America/Chicago',
  },
  '38451': {
    zip: '38451',
    city: 'Culleoka',
    state: 'TN',
    latitude: '35.462279',
    longitude: '-86.991892',
    timeZoneId: 'America/Chicago',
  },
  '38452': {
    zip: '38452',
    city: 'Cypress Inn',
    state: 'TN',
    latitude: '35.057191',
    longitude: '-87.795538',
    timeZoneId: 'America/Chicago',
  },
  '38453': {
    zip: '38453',
    city: 'Dellrose',
    state: 'TN',
    latitude: '35.154174',
    longitude: '-87.09031',
    timeZoneId: 'America/Chicago',
  },
  '38454': {
    zip: '38454',
    city: 'Duck River',
    state: 'TN',
    latitude: '35.696303',
    longitude: '-87.340851',
    timeZoneId: 'America/Chicago',
  },
  '38455': {
    zip: '38455',
    city: 'Elkton',
    state: 'TN',
    latitude: '35.083297',
    longitude: '-86.888381',
    timeZoneId: 'America/Chicago',
  },
  '38456': {
    zip: '38456',
    city: 'Ethridge',
    state: 'TN',
    latitude: '35.323472',
    longitude: '-87.305069',
    timeZoneId: 'America/Chicago',
  },
  '38457': {
    zip: '38457',
    city: 'Five Points',
    state: 'TN',
    latitude: '35.027318',
    longitude: '-87.284586',
    timeZoneId: 'America/Chicago',
  },
  '38459': {
    zip: '38459',
    city: 'Frankewing',
    state: 'TN',
    latitude: '35.172609',
    longitude: '-86.775948',
    timeZoneId: 'America/Chicago',
  },
  '38460': {
    zip: '38460',
    city: 'Goodspring',
    state: 'TN',
    latitude: '35.126635',
    longitude: '-87.143747',
    timeZoneId: 'America/Chicago',
  },
  '38461': {
    zip: '38461',
    city: 'Hampshire',
    state: 'TN',
    latitude: '35.586677',
    longitude: '-87.357491',
    timeZoneId: 'America/Chicago',
  },
  '38462': {
    zip: '38462',
    city: 'Hohenwald',
    state: 'TN',
    latitude: '35.553087',
    longitude: '-87.546132',
    timeZoneId: 'America/Chicago',
  },
  '38463': {
    zip: '38463',
    city: 'Iron City',
    state: 'TN',
    latitude: '35.018253',
    longitude: '-87.582288',
    timeZoneId: 'America/Chicago',
  },
  '38464': {
    zip: '38464',
    city: 'Lawrenceburg',
    state: 'TN',
    latitude: '35.246888',
    longitude: '-87.327981',
    timeZoneId: 'America/Chicago',
  },
  '38468': {
    zip: '38468',
    city: 'Leoma',
    state: 'TN',
    latitude: '35.22973',
    longitude: '-87.339983',
    timeZoneId: 'America/Chicago',
  },
  '38469': {
    zip: '38469',
    city: 'Loretto',
    state: 'TN',
    latitude: '35.079612',
    longitude: '-87.436064',
    timeZoneId: 'America/Chicago',
  },
  '38471': {
    zip: '38471',
    city: 'Lutts',
    state: 'TN',
    latitude: '35.120142',
    longitude: '-87.905568',
    timeZoneId: 'America/Chicago',
  },
  '38472': {
    zip: '38472',
    city: 'Lynnville',
    state: 'TN',
    latitude: '35.387148',
    longitude: '-86.996972',
    timeZoneId: 'America/Chicago',
  },
  '38473': {
    zip: '38473',
    city: 'Minor Hill',
    state: 'TN',
    latitude: '35.048358',
    longitude: '-87.154937',
    timeZoneId: 'America/Chicago',
  },
  '38474': {
    zip: '38474',
    city: 'Mount Pleasant',
    state: 'TN',
    latitude: '35.53687',
    longitude: '-87.207199',
    timeZoneId: 'America/Chicago',
  },
  '38475': {
    zip: '38475',
    city: 'Olivehill',
    state: 'TN',
    latitude: '35.271532',
    longitude: '-88.040542',
    timeZoneId: 'America/Chicago',
  },
  '38476': {
    zip: '38476',
    city: 'Primm Springs',
    state: 'TN',
    latitude: '35.837001',
    longitude: '-87.239841',
    timeZoneId: 'America/Chicago',
  },
  '38477': {
    zip: '38477',
    city: 'Prospect',
    state: 'TN',
    latitude: '35.051581',
    longitude: '-87.009633',
    timeZoneId: 'America/Chicago',
  },
  '38478': {
    zip: '38478',
    city: 'Pulaski',
    state: 'TN',
    latitude: '35.190505',
    longitude: '-87.02677',
    timeZoneId: 'America/Chicago',
  },
  '38481': {
    zip: '38481',
    city: 'Saint Joseph',
    state: 'TN',
    latitude: '35.039736',
    longitude: '-87.505697',
    timeZoneId: 'America/Chicago',
  },
  '38482': {
    zip: '38482',
    city: 'Santa Fe',
    state: 'TN',
    latitude: '35.756828',
    longitude: '-87.16711',
    timeZoneId: 'America/Chicago',
  },
  '38483': {
    zip: '38483',
    city: 'Summertown',
    state: 'TN',
    latitude: '35.43301',
    longitude: '-87.336384',
    timeZoneId: 'America/Chicago',
  },
  '38485': {
    zip: '38485',
    city: 'Waynesboro',
    state: 'TN',
    latitude: '35.317202',
    longitude: '-87.759589',
    timeZoneId: 'America/Chicago',
  },
  '38486': {
    zip: '38486',
    city: 'Westpoint',
    state: 'TN',
    latitude: '35.154544',
    longitude: '-87.533952',
    timeZoneId: 'America/Chicago',
  },
  '38487': {
    zip: '38487',
    city: 'Williamsport',
    state: 'TN',
    latitude: '35.646374',
    longitude: '-87.23431',
    timeZoneId: 'America/Chicago',
  },
  '38488': {
    zip: '38488',
    city: 'Taft',
    state: 'TN',
    latitude: '35.051918',
    longitude: '-86.68127',
    timeZoneId: 'America/Chicago',
  },
  '38501': {
    zip: '38501',
    city: 'Cookeville',
    state: 'TN',
    latitude: '36.19962',
    longitude: '-85.52274',
    timeZoneId: 'America/Chicago',
  },
  '38502': {
    zip: '38502',
    city: 'Cookeville',
    state: 'TN',
    latitude: '36.161052',
    longitude: '-85.499438',
    timeZoneId: 'America/Chicago',
  },
  '38503': {
    zip: '38503',
    city: 'Cookeville',
    state: 'TN',
    latitude: '36.156672',
    longitude: '-85.506357',
    timeZoneId: 'America/Chicago',
  },
  '38504': {
    zip: '38504',
    city: 'Allardt',
    state: 'TN',
    latitude: '36.368347',
    longitude: '-84.811613',
    timeZoneId: 'America/Chicago',
  },
  '38505': {
    zip: '38505',
    city: 'Cookeville',
    state: 'TN',
    latitude: '36.182099',
    longitude: '-85.461511',
    timeZoneId: 'America/Chicago',
  },
  '38506': {
    zip: '38506',
    city: 'Cookeville',
    state: 'TN',
    latitude: '36.146202',
    longitude: '-85.52744',
    timeZoneId: 'America/Chicago',
  },
  '38541': {
    zip: '38541',
    city: 'Allons',
    state: 'TN',
    latitude: '36.49125',
    longitude: '-85.317823',
    timeZoneId: 'America/Chicago',
  },
  '38542': {
    zip: '38542',
    city: 'Allred',
    state: 'TN',
    latitude: '36.366955',
    longitude: '-85.19445',
    timeZoneId: 'America/Chicago',
  },
  '38543': {
    zip: '38543',
    city: 'Alpine',
    state: 'TN',
    latitude: '36.366464',
    longitude: '-85.148902',
    timeZoneId: 'America/Chicago',
  },
  '38544': {
    zip: '38544',
    city: 'Baxter',
    state: 'TN',
    latitude: '36.156092',
    longitude: '-85.640158',
    timeZoneId: 'America/Chicago',
  },
  '38545': {
    zip: '38545',
    city: 'Bloomington Springs',
    state: 'TN',
    latitude: '36.343846',
    longitude: '-85.651469',
    timeZoneId: 'America/Chicago',
  },
  '38547': {
    zip: '38547',
    city: 'Brush Creek',
    state: 'TN',
    latitude: '36.125275',
    longitude: '-86.0207',
    timeZoneId: 'America/Chicago',
  },
  '38548': {
    zip: '38548',
    city: 'Buffalo Valley',
    state: 'TN',
    latitude: '36.182066',
    longitude: '-85.756226',
    timeZoneId: 'America/Chicago',
  },
  '38549': {
    zip: '38549',
    city: 'Byrdstown',
    state: 'TN',
    latitude: '36.573617',
    longitude: '-85.140037',
    timeZoneId: 'America/Chicago',
  },
  '38550': {
    zip: '38550',
    city: 'Campaign',
    state: 'TN',
    latitude: '35.713',
    longitude: '-85.603449',
    timeZoneId: 'America/Chicago',
  },
  '38551': {
    zip: '38551',
    city: 'Celina',
    state: 'TN',
    latitude: '36.619354',
    longitude: '-85.431418',
    timeZoneId: 'America/Chicago',
  },
  '38552': {
    zip: '38552',
    city: 'Chestnut Mound',
    state: 'TN',
    latitude: '36.187343',
    longitude: '-85.842629',
    timeZoneId: 'America/Chicago',
  },
  '38553': {
    zip: '38553',
    city: 'Clarkrange',
    state: 'TN',
    latitude: '36.217167',
    longitude: '-84.966493',
    timeZoneId: 'America/Chicago',
  },
  '38554': {
    zip: '38554',
    city: 'Crawford',
    state: 'TN',
    latitude: '36.248188',
    longitude: '-85.188903',
    timeZoneId: 'America/Chicago',
  },
  '38555': {
    zip: '38555',
    city: 'Crossville',
    state: 'TN',
    latitude: '35.95122',
    longitude: '-85.02978',
    timeZoneId: 'America/Chicago',
  },
  '38556': {
    zip: '38556',
    city: 'Jamestown',
    state: 'TN',
    latitude: '36.411279',
    longitude: '-84.937606',
    timeZoneId: 'America/Chicago',
  },
  '38557': {
    zip: '38557',
    city: 'Crossville',
    state: 'TN',
    latitude: '35.978972',
    longitude: '-84.970658',
    timeZoneId: 'America/Chicago',
  },
  '38558': {
    zip: '38558',
    city: 'Crossville',
    state: 'TN',
    latitude: '35.951652',
    longitude: '-85.022011',
    timeZoneId: 'America/Chicago',
  },
  '38559': {
    zip: '38559',
    city: 'Doyle',
    state: 'TN',
    latitude: '35.866079',
    longitude: '-85.496926',
    timeZoneId: 'America/Chicago',
  },
  '38560': {
    zip: '38560',
    city: 'Elmwood',
    state: 'TN',
    latitude: '36.241881',
    longitude: '-85.883125',
    timeZoneId: 'America/Chicago',
  },
  '38562': {
    zip: '38562',
    city: 'Gainesboro',
    state: 'TN',
    latitude: '36.357388',
    longitude: '-85.653151',
    timeZoneId: 'America/Chicago',
  },
  '38563': {
    zip: '38563',
    city: 'Gordonsville',
    state: 'TN',
    latitude: '36.172586',
    longitude: '-85.931293',
    timeZoneId: 'America/Chicago',
  },
  '38564': {
    zip: '38564',
    city: 'Granville',
    state: 'TN',
    latitude: '36.280031',
    longitude: '-85.746245',
    timeZoneId: 'America/Chicago',
  },
  '38565': {
    zip: '38565',
    city: 'Grimsley',
    state: 'TN',
    latitude: '36.255506',
    longitude: '-85.030665',
    timeZoneId: 'America/Chicago',
  },
  '38567': {
    zip: '38567',
    city: 'Hickman',
    state: 'TN',
    latitude: '36.106232',
    longitude: '-85.908114',
    timeZoneId: 'America/Chicago',
  },
  '38568': {
    zip: '38568',
    city: 'Hilham',
    state: 'TN',
    latitude: '36.403331',
    longitude: '-85.439307',
    timeZoneId: 'America/Chicago',
  },
  '38569': {
    zip: '38569',
    city: 'Lancaster',
    state: 'TN',
    latitude: '36.09831',
    longitude: '-85.847416',
    timeZoneId: 'America/Chicago',
  },
  '38570': {
    zip: '38570',
    city: 'Livingston',
    state: 'TN',
    latitude: '36.388366',
    longitude: '-85.321465',
    timeZoneId: 'America/Chicago',
  },
  '38571': {
    zip: '38571',
    city: 'Crossville',
    state: 'TN',
    latitude: '35.957067',
    longitude: '-84.971996',
    timeZoneId: 'America/Chicago',
  },
  '38572': {
    zip: '38572',
    city: 'Crossville',
    state: 'TN',
    latitude: '35.958592',
    longitude: '-84.971393',
    timeZoneId: 'America/Chicago',
  },
  '38573': {
    zip: '38573',
    city: 'Monroe',
    state: 'TN',
    latitude: '36.453959',
    longitude: '-85.201425',
    timeZoneId: 'America/Chicago',
  },
  '38574': {
    zip: '38574',
    city: 'Monterey',
    state: 'TN',
    latitude: '36.13853',
    longitude: '-85.26596',
    timeZoneId: 'America/Chicago',
  },
  '38575': {
    zip: '38575',
    city: 'Moss',
    state: 'TN',
    latitude: '36.599606',
    longitude: '-85.688062',
    timeZoneId: 'America/Chicago',
  },
  '38577': {
    zip: '38577',
    city: 'Pall Mall',
    state: 'TN',
    latitude: '36.570466',
    longitude: '-84.950492',
    timeZoneId: 'America/Chicago',
  },
  '38578': {
    zip: '38578',
    city: 'Pleasant Hill',
    state: 'TN',
    latitude: '35.981599',
    longitude: '-85.202413',
    timeZoneId: 'America/Chicago',
  },
  '38579': {
    zip: '38579',
    city: 'Quebeck',
    state: 'TN',
    latitude: '35.834444',
    longitude: '-85.548236',
    timeZoneId: 'America/Chicago',
  },
  '38580': {
    zip: '38580',
    city: 'Rickman',
    state: 'TN',
    latitude: '36.301898',
    longitude: '-85.388794',
    timeZoneId: 'America/Chicago',
  },
  '38581': {
    zip: '38581',
    city: 'Rock Island',
    state: 'TN',
    latitude: '35.73084',
    longitude: '-85.65018',
    timeZoneId: 'America/Chicago',
  },
  '38582': {
    zip: '38582',
    city: 'Silver Point',
    state: 'TN',
    latitude: '36.10207',
    longitude: '-85.749169',
    timeZoneId: 'America/Chicago',
  },
  '38583': {
    zip: '38583',
    city: 'Sparta',
    state: 'TN',
    latitude: '35.931616',
    longitude: '-85.469699',
    timeZoneId: 'America/Chicago',
  },
  '38585': {
    zip: '38585',
    city: 'Spencer',
    state: 'TN',
    latitude: '35.703319',
    longitude: '-85.414206',
    timeZoneId: 'America/Chicago',
  },
  '38587': {
    zip: '38587',
    city: 'Walling',
    state: 'TN',
    latitude: '35.930222',
    longitude: '-85.474463',
    timeZoneId: 'America/Chicago',
  },
  '38588': {
    zip: '38588',
    city: 'Whitleyville',
    state: 'TN',
    latitude: '36.48357',
    longitude: '-85.707587',
    timeZoneId: 'America/Chicago',
  },
  '38589': {
    zip: '38589',
    city: 'Wilder',
    state: 'TN',
    latitude: '36.26719',
    longitude: '-85.088335',
    timeZoneId: 'America/Chicago',
  },
  '38601': {
    zip: '38601',
    city: 'Abbeville',
    state: 'MS',
    latitude: '34.47212',
    longitude: '-89.600253',
    timeZoneId: 'America/Chicago',
  },
  '38602': {
    zip: '38602',
    city: 'Arkabutla',
    state: 'MS',
    latitude: '34.697827',
    longitude: '-90.008046',
    timeZoneId: 'America/Chicago',
  },
  '38603': {
    zip: '38603',
    city: 'Ashland',
    state: 'MS',
    latitude: '34.831184',
    longitude: '-89.170922',
    timeZoneId: 'America/Chicago',
  },
  '38606': {
    zip: '38606',
    city: 'Batesville',
    state: 'MS',
    latitude: '34.316846',
    longitude: '-89.949955',
    timeZoneId: 'America/Chicago',
  },
  '38609': {
    zip: '38609',
    city: 'Marks',
    state: 'MS',
    latitude: '34.232702',
    longitude: '-90.292653',
    timeZoneId: 'America/Chicago',
  },
  '38610': {
    zip: '38610',
    city: 'Blue Mountain',
    state: 'MS',
    latitude: '34.670141',
    longitude: '-89.019896',
    timeZoneId: 'America/Chicago',
  },
  '38611': {
    zip: '38611',
    city: 'Byhalia',
    state: 'MS',
    latitude: '34.841937',
    longitude: '-89.756017',
    timeZoneId: 'America/Chicago',
  },
  '38614': {
    zip: '38614',
    city: 'Clarksdale',
    state: 'MS',
    latitude: '34.175236',
    longitude: '-90.640469',
    timeZoneId: 'America/Chicago',
  },
  '38617': {
    zip: '38617',
    city: 'Coahoma',
    state: 'MS',
    latitude: '34.393358',
    longitude: '-90.49031',
    timeZoneId: 'America/Chicago',
  },
  '38618': {
    zip: '38618',
    city: 'Coldwater',
    state: 'MS',
    latitude: '34.689243',
    longitude: '-89.97918',
    timeZoneId: 'America/Chicago',
  },
  '38619': {
    zip: '38619',
    city: 'Como',
    state: 'MS',
    latitude: '34.51032',
    longitude: '-89.9019',
    timeZoneId: 'America/Chicago',
  },
  '38620': {
    zip: '38620',
    city: 'Courtland',
    state: 'MS',
    latitude: '34.172202',
    longitude: '-90.137417',
    timeZoneId: 'America/Chicago',
  },
  '38621': {
    zip: '38621',
    city: 'Crenshaw',
    state: 'MS',
    latitude: '34.504355',
    longitude: '-90.192058',
    timeZoneId: 'America/Chicago',
  },
  '38622': {
    zip: '38622',
    city: 'Crowder',
    state: 'MS',
    latitude: '34.172593',
    longitude: '-90.135281',
    timeZoneId: 'America/Chicago',
  },
  '38623': {
    zip: '38623',
    city: 'Darling',
    state: 'MS',
    latitude: '34.436854',
    longitude: '-90.175508',
    timeZoneId: 'America/Chicago',
  },
  '38625': {
    zip: '38625',
    city: 'Dumas',
    state: 'MS',
    latitude: '34.640768',
    longitude: '-88.802425',
    timeZoneId: 'America/Chicago',
  },
  '38626': {
    zip: '38626',
    city: 'Dundee',
    state: 'MS',
    latitude: '34.53438',
    longitude: '-90.396',
    timeZoneId: 'America/Chicago',
  },
  '38627': {
    zip: '38627',
    city: 'Etta',
    state: 'MS',
    latitude: '34.434041',
    longitude: '-89.185271',
    timeZoneId: 'America/Chicago',
  },
  '38628': {
    zip: '38628',
    city: 'Sledge',
    state: 'MS',
    latitude: '34.395268',
    longitude: '-90.254922',
    timeZoneId: 'America/Chicago',
  },
  '38629': {
    zip: '38629',
    city: 'Falkner',
    state: 'MS',
    latitude: '34.840977',
    longitude: '-88.961435',
    timeZoneId: 'America/Chicago',
  },
  '38630': {
    zip: '38630',
    city: 'Farrell',
    state: 'MS',
    latitude: '34.201017',
    longitude: '-90.570892',
    timeZoneId: 'America/Chicago',
  },
  '38631': {
    zip: '38631',
    city: 'Friars Point',
    state: 'MS',
    latitude: '34.359895',
    longitude: '-90.635937',
    timeZoneId: 'America/Chicago',
  },
  '38632': {
    zip: '38632',
    city: 'Hernando',
    state: 'MS',
    latitude: '34.808376',
    longitude: '-90.028477',
    timeZoneId: 'America/Chicago',
  },
  '38633': {
    zip: '38633',
    city: 'Hickory Flat',
    state: 'MS',
    latitude: '34.638021',
    longitude: '-89.177255',
    timeZoneId: 'America/Chicago',
  },
  '38634': {
    zip: '38634',
    city: 'Holly Springs',
    state: 'MS',
    latitude: '34.772071',
    longitude: '-89.440463',
    timeZoneId: 'America/Chicago',
  },
  '38635': {
    zip: '38635',
    city: 'Holly Springs',
    state: 'MS',
    latitude: '34.772162',
    longitude: '-89.450965',
    timeZoneId: 'America/Chicago',
  },
  '38637': {
    zip: '38637',
    city: 'Horn Lake',
    state: 'MS',
    latitude: '34.950244',
    longitude: '-90.037659',
    timeZoneId: 'America/Chicago',
  },
  '38638': {
    zip: '38638',
    city: 'Independence',
    state: 'MS',
    latitude: '34.633091',
    longitude: '-89.823776',
    timeZoneId: 'America/Chicago',
  },
  '38639': {
    zip: '38639',
    city: 'Jonestown',
    state: 'MS',
    latitude: '34.324642',
    longitude: '-90.453025',
    timeZoneId: 'America/Chicago',
  },
  '38641': {
    zip: '38641',
    city: 'Lake Cormorant',
    state: 'MS',
    latitude: '34.92534',
    longitude: '-90.15984',
    timeZoneId: 'America/Chicago',
  },
  '38642': {
    zip: '38642',
    city: 'Lamar',
    state: 'MS',
    latitude: '34.98846',
    longitude: '-89.37474',
    timeZoneId: 'America/Chicago',
  },
  '38643': {
    zip: '38643',
    city: 'Lambert',
    state: 'MS',
    latitude: '34.202923',
    longitude: '-90.281184',
    timeZoneId: 'America/Chicago',
  },
  '38644': {
    zip: '38644',
    city: 'Lula',
    state: 'MS',
    latitude: '34.455872',
    longitude: '-90.475665',
    timeZoneId: 'America/Chicago',
  },
  '38645': {
    zip: '38645',
    city: 'Lyon',
    state: 'MS',
    latitude: '34.236273',
    longitude: '-90.519701',
    timeZoneId: 'America/Chicago',
  },
  '38646': {
    zip: '38646',
    city: 'Marks',
    state: 'MS',
    latitude: '34.25015',
    longitude: '-90.274085',
    timeZoneId: 'America/Chicago',
  },
  '38647': {
    zip: '38647',
    city: 'Michigan City',
    state: 'MS',
    latitude: '34.932778',
    longitude: '-89.211111',
    timeZoneId: 'America/Chicago',
  },
  '38649': {
    zip: '38649',
    city: 'Mount Pleasant',
    state: 'MS',
    latitude: '34.737265',
    longitude: '-89.500427',
    timeZoneId: 'America/Chicago',
  },
  '38650': {
    zip: '38650',
    city: 'Myrtle',
    state: 'MS',
    latitude: '34.543592',
    longitude: '-89.128594',
    timeZoneId: 'America/Chicago',
  },
  '38651': {
    zip: '38651',
    city: 'Nesbit',
    state: 'MS',
    latitude: '34.88754',
    longitude: '-89.9931',
    timeZoneId: 'America/Chicago',
  },
  '38652': {
    zip: '38652',
    city: 'New Albany',
    state: 'MS',
    latitude: '34.491548',
    longitude: '-89.012762',
    timeZoneId: 'America/Chicago',
  },
  '38654': {
    zip: '38654',
    city: 'Olive Branch',
    state: 'MS',
    latitude: '34.950007',
    longitude: '-89.862634',
    timeZoneId: 'America/Chicago',
  },
  '38655': {
    zip: '38655',
    city: 'Oxford',
    state: 'MS',
    latitude: '34.35036',
    longitude: '-89.51898',
    timeZoneId: 'America/Chicago',
  },
  '38658': {
    zip: '38658',
    city: 'Pope',
    state: 'MS',
    latitude: '34.218345',
    longitude: '-89.946584',
    timeZoneId: 'America/Chicago',
  },
  '38659': {
    zip: '38659',
    city: 'Potts Camp',
    state: 'MS',
    latitude: '34.60182',
    longitude: '-89.32092',
    timeZoneId: 'America/Chicago',
  },
  '38661': {
    zip: '38661',
    city: 'Red Banks',
    state: 'MS',
    latitude: '34.95591',
    longitude: '-89.51604',
    timeZoneId: 'America/Chicago',
  },
  '38663': {
    zip: '38663',
    city: 'Ripley',
    state: 'MS',
    latitude: '34.73952',
    longitude: '-88.9731',
    timeZoneId: 'America/Chicago',
  },
  '38664': {
    zip: '38664',
    city: 'Robinsonville',
    state: 'MS',
    latitude: '34.817778',
    longitude: '-90.370833',
    timeZoneId: 'America/Chicago',
  },
  '38665': {
    zip: '38665',
    city: 'Sarah',
    state: 'MS',
    latitude: '34.601919',
    longitude: '-90.126392',
    timeZoneId: 'America/Chicago',
  },
  '38666': {
    zip: '38666',
    city: 'Sardis',
    state: 'MS',
    latitude: '34.437126',
    longitude: '-89.917698',
    timeZoneId: 'America/Chicago',
  },
  '38668': {
    zip: '38668',
    city: 'Senatobia',
    state: 'MS',
    latitude: '34.65318',
    longitude: '-89.86986',
    timeZoneId: 'America/Chicago',
  },
  '38669': {
    zip: '38669',
    city: 'Sherard',
    state: 'MS',
    latitude: '34.189654',
    longitude: '-90.62667',
    timeZoneId: 'America/Chicago',
  },
  '38670': {
    zip: '38670',
    city: 'Sledge',
    state: 'MS',
    latitude: '34.429607',
    longitude: '-90.218388',
    timeZoneId: 'America/Chicago',
  },
  '38671': {
    zip: '38671',
    city: 'Southaven',
    state: 'MS',
    latitude: '34.974697',
    longitude: '-89.993398',
    timeZoneId: 'America/Chicago',
  },
  '38672': {
    zip: '38672',
    city: 'Southaven',
    state: 'MS',
    latitude: '34.991',
    longitude: '-90.0231',
    timeZoneId: 'America/Chicago',
  },
  '38673': {
    zip: '38673',
    city: 'Taylor',
    state: 'MS',
    latitude: '34.275541',
    longitude: '-89.637463',
    timeZoneId: 'America/Chicago',
  },
  '38674': {
    zip: '38674',
    city: 'Tiplersville',
    state: 'MS',
    latitude: '34.903866',
    longitude: '-88.918583',
    timeZoneId: 'America/Chicago',
  },
  '38675': {
    zip: '38675',
    city: 'Oxford',
    state: 'MS',
    latitude: '34.364813',
    longitude: '-89.526651',
    timeZoneId: 'America/Chicago',
  },
  '38676': {
    zip: '38676',
    city: 'Tunica',
    state: 'MS',
    latitude: '34.6944',
    longitude: '-90.36942',
    timeZoneId: 'America/Chicago',
  },
  '38677': {
    zip: '38677',
    city: 'University',
    state: 'MS',
    latitude: '34.36739',
    longitude: '-89.528956',
    timeZoneId: 'America/Chicago',
  },
  '38679': {
    zip: '38679',
    city: 'Victoria',
    state: 'MS',
    latitude: '34.885729',
    longitude: '-89.51038',
    timeZoneId: 'America/Chicago',
  },
  '38680': {
    zip: '38680',
    city: 'Walls',
    state: 'MS',
    latitude: '34.961644',
    longitude: '-90.119278',
    timeZoneId: 'America/Chicago',
  },
  '38683': {
    zip: '38683',
    city: 'Walnut',
    state: 'MS',
    latitude: '34.947755',
    longitude: '-88.899821',
    timeZoneId: 'America/Chicago',
  },
  '38685': {
    zip: '38685',
    city: 'Waterford',
    state: 'MS',
    latitude: '34.6407',
    longitude: '-89.46978',
    timeZoneId: 'America/Chicago',
  },
  '38686': {
    zip: '38686',
    city: 'Walls',
    state: 'MS',
    latitude: '34.956644',
    longitude: '-90.152037',
    timeZoneId: 'America/Chicago',
  },
  '38701': {
    zip: '38701',
    city: 'Greenville',
    state: 'MS',
    latitude: '33.28878',
    longitude: '-90.99072',
    timeZoneId: 'America/Chicago',
  },
  '38702': {
    zip: '38702',
    city: 'Greenville',
    state: 'MS',
    latitude: '33.408211',
    longitude: '-91.061088',
    timeZoneId: 'America/Chicago',
  },
  '38703': {
    zip: '38703',
    city: 'Greenville',
    state: 'MS',
    latitude: '33.4215',
    longitude: '-91.0194',
    timeZoneId: 'America/Chicago',
  },
  '38704': {
    zip: '38704',
    city: 'Greenville',
    state: 'MS',
    latitude: '33.404969',
    longitude: '-91.038585',
    timeZoneId: 'America/Chicago',
  },
  '38720': {
    zip: '38720',
    city: 'Alligator',
    state: 'MS',
    latitude: '34.128534',
    longitude: '-90.771281',
    timeZoneId: 'America/Chicago',
  },
  '38721': {
    zip: '38721',
    city: 'Anguilla',
    state: 'MS',
    latitude: '32.974616',
    longitude: '-90.831462',
    timeZoneId: 'America/Chicago',
  },
  '38722': {
    zip: '38722',
    city: 'Arcola',
    state: 'MS',
    latitude: '33.267536',
    longitude: '-90.881204',
    timeZoneId: 'America/Chicago',
  },
  '38723': {
    zip: '38723',
    city: 'Avon',
    state: 'MS',
    latitude: '33.263218',
    longitude: '-91.025221',
    timeZoneId: 'America/Chicago',
  },
  '38725': {
    zip: '38725',
    city: 'Benoit',
    state: 'MS',
    latitude: '33.65286',
    longitude: '-91.0317',
    timeZoneId: 'America/Chicago',
  },
  '38726': {
    zip: '38726',
    city: 'Beulah',
    state: 'MS',
    latitude: '33.786816',
    longitude: '-90.981417',
    timeZoneId: 'America/Chicago',
  },
  '38730': {
    zip: '38730',
    city: 'Boyle',
    state: 'MS',
    latitude: '33.705356',
    longitude: '-90.727661',
    timeZoneId: 'America/Chicago',
  },
  '38731': {
    zip: '38731',
    city: 'Chatham',
    state: 'MS',
    latitude: '33.087531',
    longitude: '-91.08441',
    timeZoneId: 'America/Chicago',
  },
  '38732': {
    zip: '38732',
    city: 'Cleveland',
    state: 'MS',
    latitude: '33.74082',
    longitude: '-90.724995',
    timeZoneId: 'America/Chicago',
  },
  '38733': {
    zip: '38733',
    city: 'Cleveland',
    state: 'MS',
    latitude: '33.749492',
    longitude: '-90.831182',
    timeZoneId: 'America/Chicago',
  },
  '38736': {
    zip: '38736',
    city: 'Doddsville',
    state: 'MS',
    latitude: '33.659722',
    longitude: '-90.616389',
    timeZoneId: 'America/Chicago',
  },
  '38737': {
    zip: '38737',
    city: 'Drew',
    state: 'MS',
    latitude: '33.8364',
    longitude: '-90.5328',
    timeZoneId: 'America/Chicago',
  },
  '38738': {
    zip: '38738',
    city: 'Parchman',
    state: 'MS',
    latitude: '33.917778',
    longitude: '-90.496389',
    timeZoneId: 'America/Chicago',
  },
  '38739': {
    zip: '38739',
    city: 'Dublin',
    state: 'MS',
    latitude: '34.188126',
    longitude: '-90.625399',
    timeZoneId: 'America/Chicago',
  },
  '38740': {
    zip: '38740',
    city: 'Duncan',
    state: 'MS',
    latitude: '33.777156',
    longitude: '-90.722884',
    timeZoneId: 'America/Chicago',
  },
  '38744': {
    zip: '38744',
    city: 'Glen Allan',
    state: 'MS',
    latitude: '33.0355',
    longitude: '-91.016167',
    timeZoneId: 'America/Chicago',
  },
  '38745': {
    zip: '38745',
    city: 'Grace',
    state: 'MS',
    latitude: '32.823937',
    longitude: '-91.044092',
    timeZoneId: 'America/Chicago',
  },
  '38746': {
    zip: '38746',
    city: 'Gunnison',
    state: 'MS',
    latitude: '33.977222',
    longitude: '-90.912222',
    timeZoneId: 'America/Chicago',
  },
  '38748': {
    zip: '38748',
    city: 'Hollandale',
    state: 'MS',
    latitude: '33.175353',
    longitude: '-90.85637',
    timeZoneId: 'America/Chicago',
  },
  '38749': {
    zip: '38749',
    city: 'Indianola',
    state: 'MS',
    latitude: '33.450833',
    longitude: '-90.655',
    timeZoneId: 'America/Chicago',
  },
  '38751': {
    zip: '38751',
    city: 'Indianola',
    state: 'MS',
    latitude: '33.452442',
    longitude: '-90.645728',
    timeZoneId: 'America/Chicago',
  },
  '38753': {
    zip: '38753',
    city: 'Inverness',
    state: 'MS',
    latitude: '33.35272',
    longitude: '-90.592066',
    timeZoneId: 'America/Chicago',
  },
  '38754': {
    zip: '38754',
    city: 'Isola',
    state: 'MS',
    latitude: '33.260452',
    longitude: '-90.592415',
    timeZoneId: 'America/Chicago',
  },
  '38756': {
    zip: '38756',
    city: 'Leland',
    state: 'MS',
    latitude: '33.40616',
    longitude: '-90.897726',
    timeZoneId: 'America/Chicago',
  },
  '38759': {
    zip: '38759',
    city: 'Merigold',
    state: 'MS',
    latitude: '33.834487',
    longitude: '-90.729355',
    timeZoneId: 'America/Chicago',
  },
  '38760': {
    zip: '38760',
    city: 'Metcalfe',
    state: 'MS',
    latitude: '33.44478',
    longitude: '-91.001974',
    timeZoneId: 'America/Chicago',
  },
  '38761': {
    zip: '38761',
    city: 'Moorhead',
    state: 'MS',
    latitude: '33.449634',
    longitude: '-90.504159',
    timeZoneId: 'America/Chicago',
  },
  '38762': {
    zip: '38762',
    city: 'Mound Bayou',
    state: 'MS',
    latitude: '33.884479',
    longitude: '-90.72827',
    timeZoneId: 'America/Chicago',
  },
  '38764': {
    zip: '38764',
    city: 'Pace',
    state: 'MS',
    latitude: '33.791689',
    longitude: '-90.857201',
    timeZoneId: 'America/Chicago',
  },
  '38765': {
    zip: '38765',
    city: 'Panther Burn',
    state: 'MS',
    latitude: '33.024904',
    longitude: '-90.787172',
    timeZoneId: 'America/Chicago',
  },
  '38767': {
    zip: '38767',
    city: 'Rena Lara',
    state: 'MS',
    latitude: '34.147347',
    longitude: '-90.780987',
    timeZoneId: 'America/Chicago',
  },
  '38768': {
    zip: '38768',
    city: 'Rome',
    state: 'MS',
    latitude: '33.987693',
    longitude: '-90.345294',
    timeZoneId: 'America/Chicago',
  },
  '38769': {
    zip: '38769',
    city: 'Rosedale',
    state: 'MS',
    latitude: '33.84642',
    longitude: '-91.00188',
    timeZoneId: 'America/Chicago',
  },
  '38771': {
    zip: '38771',
    city: 'Ruleville',
    state: 'MS',
    latitude: '33.721184',
    longitude: '-90.544563',
    timeZoneId: 'America/Chicago',
  },
  '38772': {
    zip: '38772',
    city: 'Scott',
    state: 'MS',
    latitude: '33.598674',
    longitude: '-91.080131',
    timeZoneId: 'America/Chicago',
  },
  '38773': {
    zip: '38773',
    city: 'Shaw',
    state: 'MS',
    latitude: '33.5958',
    longitude: '-90.82278',
    timeZoneId: 'America/Chicago',
  },
  '38774': {
    zip: '38774',
    city: 'Shelby',
    state: 'MS',
    latitude: '33.94868',
    longitude: '-90.765417',
    timeZoneId: 'America/Chicago',
  },
  '38776': {
    zip: '38776',
    city: 'Stoneville',
    state: 'MS',
    latitude: '33.40757',
    longitude: '-90.911217',
    timeZoneId: 'America/Chicago',
  },
  '38778': {
    zip: '38778',
    city: 'Sunflower',
    state: 'MS',
    latitude: '33.550255',
    longitude: '-90.529915',
    timeZoneId: 'America/Chicago',
  },
  '38780': {
    zip: '38780',
    city: 'Wayside',
    state: 'MS',
    latitude: '33.266791',
    longitude: '-91.023527',
    timeZoneId: 'America/Chicago',
  },
  '38781': {
    zip: '38781',
    city: 'Winstonville',
    state: 'MS',
    latitude: '33.897283',
    longitude: '-90.718493',
    timeZoneId: 'America/Chicago',
  },
  '38782': {
    zip: '38782',
    city: 'Winterville',
    state: 'MS',
    latitude: '33.504147',
    longitude: '-91.057094',
    timeZoneId: 'America/Chicago',
  },
  '38801': {
    zip: '38801',
    city: 'Tupelo',
    state: 'MS',
    latitude: '34.233855',
    longitude: '-88.738853',
    timeZoneId: 'America/Chicago',
  },
  '38802': {
    zip: '38802',
    city: 'Tupelo',
    state: 'MS',
    latitude: '34.272739',
    longitude: '-88.700478',
    timeZoneId: 'America/Chicago',
  },
  '38803': {
    zip: '38803',
    city: 'Tupelo',
    state: 'MS',
    latitude: '34.271341',
    longitude: '-88.703114',
    timeZoneId: 'America/Chicago',
  },
  '38804': {
    zip: '38804',
    city: 'Tupelo',
    state: 'MS',
    latitude: '34.286982',
    longitude: '-88.683036',
    timeZoneId: 'America/Chicago',
  },
  '38820': {
    zip: '38820',
    city: 'Algoma',
    state: 'MS',
    latitude: '34.178945',
    longitude: '-88.965751',
    timeZoneId: 'America/Chicago',
  },
  '38821': {
    zip: '38821',
    city: 'Amory',
    state: 'MS',
    latitude: '33.99966',
    longitude: '-88.45194',
    timeZoneId: 'America/Chicago',
  },
  '38824': {
    zip: '38824',
    city: 'Baldwyn',
    state: 'MS',
    latitude: '34.5201',
    longitude: '-88.67256',
    timeZoneId: 'America/Chicago',
  },
  '38825': {
    zip: '38825',
    city: 'Becker',
    state: 'MS',
    latitude: '33.987917',
    longitude: '-88.470181',
    timeZoneId: 'America/Chicago',
  },
  '38826': {
    zip: '38826',
    city: 'Belden',
    state: 'MS',
    latitude: '34.29106',
    longitude: '-88.780847',
    timeZoneId: 'America/Chicago',
  },
  '38827': {
    zip: '38827',
    city: 'Belmont',
    state: 'MS',
    latitude: '34.512414',
    longitude: '-88.210005',
    timeZoneId: 'America/Chicago',
  },
  '38828': {
    zip: '38828',
    city: 'Blue Springs',
    state: 'MS',
    latitude: '34.42668',
    longitude: '-88.8687',
    timeZoneId: 'America/Chicago',
  },
  '38829': {
    zip: '38829',
    city: 'Booneville',
    state: 'MS',
    latitude: '34.654538',
    longitude: '-88.559614',
    timeZoneId: 'America/Chicago',
  },
  '38833': {
    zip: '38833',
    city: 'Burnsville',
    state: 'MS',
    latitude: '34.89012',
    longitude: '-88.309221',
    timeZoneId: 'America/Chicago',
  },
  '38834': {
    zip: '38834',
    city: 'Corinth',
    state: 'MS',
    latitude: '34.936175',
    longitude: '-88.512642',
    timeZoneId: 'America/Chicago',
  },
  '38835': {
    zip: '38835',
    city: 'Corinth',
    state: 'MS',
    latitude: '34.932281',
    longitude: '-88.518647',
    timeZoneId: 'America/Chicago',
  },
  '38838': {
    zip: '38838',
    city: 'Dennis',
    state: 'MS',
    latitude: '34.5618',
    longitude: '-88.20408',
    timeZoneId: 'America/Chicago',
  },
  '38839': {
    zip: '38839',
    city: 'Derma',
    state: 'MS',
    latitude: '33.863628',
    longitude: '-89.321103',
    timeZoneId: 'America/Chicago',
  },
  '38841': {
    zip: '38841',
    city: 'Ecru',
    state: 'MS',
    latitude: '34.34736',
    longitude: '-88.989',
    timeZoneId: 'America/Chicago',
  },
  '38843': {
    zip: '38843',
    city: 'Fulton',
    state: 'MS',
    latitude: '34.269865',
    longitude: '-88.403996',
    timeZoneId: 'America/Chicago',
  },
  '38844': {
    zip: '38844',
    city: 'Gattman',
    state: 'MS',
    latitude: '33.883707',
    longitude: '-88.234948',
    timeZoneId: 'America/Chicago',
  },
  '38846': {
    zip: '38846',
    city: 'Glen',
    state: 'MS',
    latitude: '34.847072',
    longitude: '-88.419386',
    timeZoneId: 'America/Chicago',
  },
  '38847': {
    zip: '38847',
    city: 'Golden',
    state: 'MS',
    latitude: '34.4682',
    longitude: '-88.18122',
    timeZoneId: 'America/Chicago',
  },
  '38848': {
    zip: '38848',
    city: 'Greenwood Springs',
    state: 'MS',
    latitude: '33.850186',
    longitude: '-88.364251',
    timeZoneId: 'America/Chicago',
  },
  '38849': {
    zip: '38849',
    city: 'Guntown',
    state: 'MS',
    latitude: '34.44137',
    longitude: '-88.663295',
    timeZoneId: 'America/Chicago',
  },
  '38850': {
    zip: '38850',
    city: 'Houlka',
    state: 'MS',
    latitude: '33.916603',
    longitude: '-89.002366',
    timeZoneId: 'America/Chicago',
  },
  '38851': {
    zip: '38851',
    city: 'Houston',
    state: 'MS',
    latitude: '33.89784',
    longitude: '-88.99098',
    timeZoneId: 'America/Chicago',
  },
  '38852': {
    zip: '38852',
    city: 'Iuka',
    state: 'MS',
    latitude: '34.82718',
    longitude: '-88.2165',
    timeZoneId: 'America/Chicago',
  },
  '38855': {
    zip: '38855',
    city: 'Mantachie',
    state: 'MS',
    latitude: '34.372551',
    longitude: '-88.474011',
    timeZoneId: 'America/Chicago',
  },
  '38856': {
    zip: '38856',
    city: 'Marietta',
    state: 'MS',
    latitude: '34.496021',
    longitude: '-88.422433',
    timeZoneId: 'America/Chicago',
  },
  '38857': {
    zip: '38857',
    city: 'Mooreville',
    state: 'MS',
    latitude: '34.280237',
    longitude: '-88.592439',
    timeZoneId: 'America/Chicago',
  },
  '38858': {
    zip: '38858',
    city: 'Nettleton',
    state: 'MS',
    latitude: '34.076707',
    longitude: '-88.619807',
    timeZoneId: 'America/Chicago',
  },
  '38859': {
    zip: '38859',
    city: 'New Site',
    state: 'MS',
    latitude: '34.557966',
    longitude: '-88.415093',
    timeZoneId: 'America/Chicago',
  },
  '38860': {
    zip: '38860',
    city: 'Okolona',
    state: 'MS',
    latitude: '34.005968',
    longitude: '-88.748637',
    timeZoneId: 'America/Chicago',
  },
  '38862': {
    zip: '38862',
    city: 'Plantersville',
    state: 'MS',
    latitude: '34.197376',
    longitude: '-88.663986',
    timeZoneId: 'America/Chicago',
  },
  '38863': {
    zip: '38863',
    city: 'Pontotoc',
    state: 'MS',
    latitude: '34.22568',
    longitude: '-88.97454',
    timeZoneId: 'America/Chicago',
  },
  '38864': {
    zip: '38864',
    city: 'Randolph',
    state: 'MS',
    latitude: '34.1712',
    longitude: '-89.18832',
    timeZoneId: 'America/Chicago',
  },
  '38865': {
    zip: '38865',
    city: 'Rienzi',
    state: 'MS',
    latitude: '34.71184',
    longitude: '-88.659681',
    timeZoneId: 'America/Chicago',
  },
  '38866': {
    zip: '38866',
    city: 'Saltillo',
    state: 'MS',
    latitude: '34.375655',
    longitude: '-88.686467',
    timeZoneId: 'America/Chicago',
  },
  '38868': {
    zip: '38868',
    city: 'Shannon',
    state: 'MS',
    latitude: '34.120111',
    longitude: '-88.714124',
    timeZoneId: 'America/Chicago',
  },
  '38869': {
    zip: '38869',
    city: 'Sherman',
    state: 'MS',
    latitude: '34.337307',
    longitude: '-88.954321',
    timeZoneId: 'America/Chicago',
  },
  '38870': {
    zip: '38870',
    city: 'Smithville',
    state: 'MS',
    latitude: '34.065634',
    longitude: '-88.382455',
    timeZoneId: 'America/Chicago',
  },
  '38871': {
    zip: '38871',
    city: 'Thaxton',
    state: 'MS',
    latitude: '34.310044',
    longitude: '-89.158801',
    timeZoneId: 'America/Chicago',
  },
  '38873': {
    zip: '38873',
    city: 'Tishomingo',
    state: 'MS',
    latitude: '34.644991',
    longitude: '-88.238561',
    timeZoneId: 'America/Chicago',
  },
  '38874': {
    zip: '38874',
    city: 'Toccopola',
    state: 'MS',
    latitude: '34.304862',
    longitude: '-89.158498',
    timeZoneId: 'America/Chicago',
  },
  '38875': {
    zip: '38875',
    city: 'Trebloc',
    state: 'MS',
    latitude: '33.910763',
    longitude: '-88.930078',
    timeZoneId: 'America/Chicago',
  },
  '38876': {
    zip: '38876',
    city: 'Tremont',
    state: 'MS',
    latitude: '34.215232',
    longitude: '-88.208583',
    timeZoneId: 'America/Chicago',
  },
  '38877': {
    zip: '38877',
    city: 'Van Vleet',
    state: 'MS',
    latitude: '33.910569',
    longitude: '-88.931385',
    timeZoneId: 'America/Chicago',
  },
  '38878': {
    zip: '38878',
    city: 'Vardaman',
    state: 'MS',
    latitude: '33.937451',
    longitude: '-89.211711',
    timeZoneId: 'America/Chicago',
  },
  '38879': {
    zip: '38879',
    city: 'Verona',
    state: 'MS',
    latitude: '34.185371',
    longitude: '-88.721819',
    timeZoneId: 'America/Chicago',
  },
  '38880': {
    zip: '38880',
    city: 'Wheeler',
    state: 'MS',
    latitude: '34.535135',
    longitude: '-88.636857',
    timeZoneId: 'America/Chicago',
  },
  '38901': {
    zip: '38901',
    city: 'Grenada',
    state: 'MS',
    latitude: '33.783216',
    longitude: '-89.806975',
    timeZoneId: 'America/Chicago',
  },
  '38902': {
    zip: '38902',
    city: 'Grenada',
    state: 'MS',
    latitude: '33.778833',
    longitude: '-89.812279',
    timeZoneId: 'America/Chicago',
  },
  '38913': {
    zip: '38913',
    city: 'Banner',
    state: 'MS',
    latitude: '34.104818',
    longitude: '-89.422186',
    timeZoneId: 'America/Chicago',
  },
  '38914': {
    zip: '38914',
    city: 'Big Creek',
    state: 'MS',
    latitude: '33.867342',
    longitude: '-89.457753',
    timeZoneId: 'America/Chicago',
  },
  '38915': {
    zip: '38915',
    city: 'Bruce',
    state: 'MS',
    latitude: '34.00488',
    longitude: '-89.36046',
    timeZoneId: 'America/Chicago',
  },
  '38916': {
    zip: '38916',
    city: 'Calhoun City',
    state: 'MS',
    latitude: '33.883888',
    longitude: '-89.326351',
    timeZoneId: 'America/Chicago',
  },
  '38917': {
    zip: '38917',
    city: 'Carrollton',
    state: 'MS',
    latitude: '33.6325',
    longitude: '-89.895',
    timeZoneId: 'America/Chicago',
  },
  '38920': {
    zip: '38920',
    city: 'Cascilla',
    state: 'MS',
    latitude: '33.89685',
    longitude: '-90.036929',
    timeZoneId: 'America/Chicago',
  },
  '38921': {
    zip: '38921',
    city: 'Charleston',
    state: 'MS',
    latitude: '33.98094',
    longitude: '-90.10752',
    timeZoneId: 'America/Chicago',
  },
  '38922': {
    zip: '38922',
    city: 'Coffeeville',
    state: 'MS',
    latitude: '33.976963',
    longitude: '-89.676645',
    timeZoneId: 'America/Chicago',
  },
  '38923': {
    zip: '38923',
    city: 'Coila',
    state: 'MS',
    latitude: '33.371212',
    longitude: '-89.976844',
    timeZoneId: 'America/Chicago',
  },
  '38924': {
    zip: '38924',
    city: 'Cruger',
    state: 'MS',
    latitude: '33.293145',
    longitude: '-90.230924',
    timeZoneId: 'America/Chicago',
  },
  '38925': {
    zip: '38925',
    city: 'Duck Hill',
    state: 'MS',
    latitude: '33.633238',
    longitude: '-89.715693',
    timeZoneId: 'America/Chicago',
  },
  '38926': {
    zip: '38926',
    city: 'Elliott',
    state: 'MS',
    latitude: '33.780085',
    longitude: '-89.827085',
    timeZoneId: 'America/Chicago',
  },
  '38927': {
    zip: '38927',
    city: 'Enid',
    state: 'MS',
    latitude: '34.123299',
    longitude: '-90.037402',
    timeZoneId: 'America/Chicago',
  },
  '38928': {
    zip: '38928',
    city: 'Glendora',
    state: 'MS',
    latitude: '33.946772',
    longitude: '-90.174703',
    timeZoneId: 'America/Chicago',
  },
  '38929': {
    zip: '38929',
    city: 'Gore Springs',
    state: 'MS',
    latitude: '33.786191',
    longitude: '-89.80629',
    timeZoneId: 'America/Chicago',
  },
  '38930': {
    zip: '38930',
    city: 'Greenwood',
    state: 'MS',
    latitude: '33.50418',
    longitude: '-90.16188',
    timeZoneId: 'America/Chicago',
  },
  '38935': {
    zip: '38935',
    city: 'Greenwood',
    state: 'MS',
    latitude: '33.522263',
    longitude: '-90.178983',
    timeZoneId: 'America/Chicago',
  },
  '38940': {
    zip: '38940',
    city: 'Holcomb',
    state: 'MS',
    latitude: '33.691186',
    longitude: '-89.93908',
    timeZoneId: 'America/Chicago',
  },
  '38941': {
    zip: '38941',
    city: 'Itta Bena',
    state: 'MS',
    latitude: '33.497698',
    longitude: '-90.325338',
    timeZoneId: 'America/Chicago',
  },
  '38943': {
    zip: '38943',
    city: 'Mc Carley',
    state: 'MS',
    latitude: '33.590842',
    longitude: '-89.850324',
    timeZoneId: 'America/Chicago',
  },
  '38944': {
    zip: '38944',
    city: 'Minter City',
    state: 'MS',
    latitude: '33.696389',
    longitude: '-90.295',
    timeZoneId: 'America/Chicago',
  },
  '38945': {
    zip: '38945',
    city: 'Greenwood',
    state: 'MS',
    latitude: '33.520275',
    longitude: '-90.180575',
    timeZoneId: 'America/Chicago',
  },
  '38946': {
    zip: '38946',
    city: 'Morgan City',
    state: 'MS',
    latitude: '33.37818',
    longitude: '-90.349864',
    timeZoneId: 'America/Chicago',
  },
  '38947': {
    zip: '38947',
    city: 'North Carrollton',
    state: 'MS',
    latitude: '33.51948',
    longitude: '-89.919278',
    timeZoneId: 'America/Chicago',
  },
  '38948': {
    zip: '38948',
    city: 'Oakland',
    state: 'MS',
    latitude: '34.121437',
    longitude: '-89.791303',
    timeZoneId: 'America/Chicago',
  },
  '38949': {
    zip: '38949',
    city: 'Paris',
    state: 'MS',
    latitude: '34.176213',
    longitude: '-89.373249',
    timeZoneId: 'America/Chicago',
  },
  '38950': {
    zip: '38950',
    city: 'Philipp',
    state: 'MS',
    latitude: '33.745493',
    longitude: '-90.213618',
    timeZoneId: 'America/Chicago',
  },
  '38951': {
    zip: '38951',
    city: 'Pittsboro',
    state: 'MS',
    latitude: '33.944219',
    longitude: '-89.313681',
    timeZoneId: 'America/Chicago',
  },
  '38952': {
    zip: '38952',
    city: 'Schlater',
    state: 'MS',
    latitude: '33.61089',
    longitude: '-90.375689',
    timeZoneId: 'America/Chicago',
  },
  '38953': {
    zip: '38953',
    city: 'Scobey',
    state: 'MS',
    latitude: '33.885851',
    longitude: '-89.9181',
    timeZoneId: 'America/Chicago',
  },
  '38954': {
    zip: '38954',
    city: 'Sidon',
    state: 'MS',
    latitude: '33.461906',
    longitude: '-90.211639',
    timeZoneId: 'America/Chicago',
  },
  '38955': {
    zip: '38955',
    city: 'Slate Spring',
    state: 'MS',
    latitude: '33.740556',
    longitude: '-89.371111',
    timeZoneId: 'America/Chicago',
  },
  '38957': {
    zip: '38957',
    city: 'Sumner',
    state: 'MS',
    latitude: '33.986878',
    longitude: '-90.34532',
    timeZoneId: 'America/Chicago',
  },
  '38958': {
    zip: '38958',
    city: 'Swan Lake',
    state: 'MS',
    latitude: '33.949231',
    longitude: '-90.174679',
    timeZoneId: 'America/Chicago',
  },
  '38959': {
    zip: '38959',
    city: 'Swiftown',
    state: 'MS',
    latitude: '33.407616',
    longitude: '-90.374719',
    timeZoneId: 'America/Chicago',
  },
  '38960': {
    zip: '38960',
    city: 'Tie Plant',
    state: 'MS',
    latitude: '33.738816',
    longitude: '-89.789409',
    timeZoneId: 'America/Chicago',
  },
  '38961': {
    zip: '38961',
    city: 'Tillatoba',
    state: 'MS',
    latitude: '33.990153',
    longitude: '-89.87868',
    timeZoneId: 'America/Chicago',
  },
  '38962': {
    zip: '38962',
    city: 'Tippo',
    state: 'MS',
    latitude: '33.892564',
    longitude: '-90.035943',
    timeZoneId: 'America/Chicago',
  },
  '38963': {
    zip: '38963',
    city: 'Tutwiler',
    state: 'MS',
    latitude: '34.014758',
    longitude: '-90.431889',
    timeZoneId: 'America/Chicago',
  },
  '38964': {
    zip: '38964',
    city: 'Vance',
    state: 'MS',
    latitude: '34.016683',
    longitude: '-90.432367',
    timeZoneId: 'America/Chicago',
  },
  '38965': {
    zip: '38965',
    city: 'Water Valley',
    state: 'MS',
    latitude: '34.153955',
    longitude: '-89.632766',
    timeZoneId: 'America/Chicago',
  },
  '38966': {
    zip: '38966',
    city: 'Webb',
    state: 'MS',
    latitude: '33.948357',
    longitude: '-90.348145',
    timeZoneId: 'America/Chicago',
  },
  '38967': {
    zip: '38967',
    city: 'Winona',
    state: 'MS',
    latitude: '33.483835',
    longitude: '-89.732137',
    timeZoneId: 'America/Chicago',
  },
  '39038': {
    zip: '39038',
    city: 'Belzoni',
    state: 'MS',
    latitude: '33.181313',
    longitude: '-90.485915',
    timeZoneId: 'America/Chicago',
  },
  '39039': {
    zip: '39039',
    city: 'Benton',
    state: 'MS',
    latitude: '32.871673',
    longitude: '-90.468877',
    timeZoneId: 'America/Chicago',
  },
  '39040': {
    zip: '39040',
    city: 'Bentonia',
    state: 'MS',
    latitude: '32.643323',
    longitude: '-90.368586',
    timeZoneId: 'America/Chicago',
  },
  '39041': {
    zip: '39041',
    city: 'Bolton',
    state: 'MS',
    latitude: '32.369187',
    longitude: '-90.454684',
    timeZoneId: 'America/Chicago',
  },
  '39042': {
    zip: '39042',
    city: 'Brandon',
    state: 'MS',
    latitude: '32.289412',
    longitude: '-89.967651',
    timeZoneId: 'America/Chicago',
  },
  '39043': {
    zip: '39043',
    city: 'Brandon',
    state: 'MS',
    latitude: '32.282534',
    longitude: '-90.005169',
    timeZoneId: 'America/Chicago',
  },
  '39044': {
    zip: '39044',
    city: 'Braxton',
    state: 'MS',
    latitude: '32.025924',
    longitude: '-89.969873',
    timeZoneId: 'America/Chicago',
  },
  '39045': {
    zip: '39045',
    city: 'Camden',
    state: 'MS',
    latitude: '32.732477',
    longitude: '-89.81648',
    timeZoneId: 'America/Chicago',
  },
  '39046': {
    zip: '39046',
    city: 'Canton',
    state: 'MS',
    latitude: '32.63928',
    longitude: '-90.0075',
    timeZoneId: 'America/Chicago',
  },
  '39047': {
    zip: '39047',
    city: 'Brandon',
    state: 'MS',
    latitude: '32.273533',
    longitude: '-89.985922',
    timeZoneId: 'America/Chicago',
  },
  '39051': {
    zip: '39051',
    city: 'Carthage',
    state: 'MS',
    latitude: '32.75844',
    longitude: '-89.58474',
    timeZoneId: 'America/Chicago',
  },
  '39054': {
    zip: '39054',
    city: 'Cary',
    state: 'MS',
    latitude: '32.802891',
    longitude: '-90.928947',
    timeZoneId: 'America/Chicago',
  },
  '39056': {
    zip: '39056',
    city: 'Clinton',
    state: 'MS',
    latitude: '32.337754',
    longitude: '-90.32538',
    timeZoneId: 'America/Chicago',
  },
  '39057': {
    zip: '39057',
    city: 'Conehatta',
    state: 'MS',
    latitude: '32.475021',
    longitude: '-89.3181',
    timeZoneId: 'America/Chicago',
  },
  '39058': {
    zip: '39058',
    city: 'Clinton',
    state: 'MS',
    latitude: '32.34283',
    longitude: '-90.324199',
    timeZoneId: 'America/Chicago',
  },
  '39059': {
    zip: '39059',
    city: 'Crystal Springs',
    state: 'MS',
    latitude: '31.983819',
    longitude: '-90.358823',
    timeZoneId: 'America/Chicago',
  },
  '39060': {
    zip: '39060',
    city: 'Clinton',
    state: 'MS',
    latitude: '32.362834',
    longitude: '-90.265435',
    timeZoneId: 'America/Chicago',
  },
  '39061': {
    zip: '39061',
    city: 'Delta City',
    state: 'MS',
    latitude: '33.025276',
    longitude: '-90.786213',
    timeZoneId: 'America/Chicago',
  },
  '39062': {
    zip: '39062',
    city: 'D Lo',
    state: 'MS',
    latitude: '31.984304',
    longitude: '-89.898049',
    timeZoneId: 'America/Chicago',
  },
  '39063': {
    zip: '39063',
    city: 'Durant',
    state: 'MS',
    latitude: '33.073783',
    longitude: '-89.857157',
    timeZoneId: 'America/Chicago',
  },
  '39066': {
    zip: '39066',
    city: 'Edwards',
    state: 'MS',
    latitude: '32.281639',
    longitude: '-90.586512',
    timeZoneId: 'America/Chicago',
  },
  '39067': {
    zip: '39067',
    city: 'Ethel',
    state: 'MS',
    latitude: '33.081483',
    longitude: '-89.54286',
    timeZoneId: 'America/Chicago',
  },
  '39069': {
    zip: '39069',
    city: 'Fayette',
    state: 'MS',
    latitude: '31.708395',
    longitude: '-91.061363',
    timeZoneId: 'America/Chicago',
  },
  '39071': {
    zip: '39071',
    city: 'Flora',
    state: 'MS',
    latitude: '32.543945',
    longitude: '-90.295297',
    timeZoneId: 'America/Chicago',
  },
  '39073': {
    zip: '39073',
    city: 'Florence',
    state: 'MS',
    latitude: '32.148436',
    longitude: '-90.134308',
    timeZoneId: 'America/Chicago',
  },
  '39074': {
    zip: '39074',
    city: 'Forest',
    state: 'MS',
    latitude: '32.363909',
    longitude: '-89.47308',
    timeZoneId: 'America/Chicago',
  },
  '39077': {
    zip: '39077',
    city: 'Gallman',
    state: 'MS',
    latitude: '31.933678',
    longitude: '-90.386888',
    timeZoneId: 'America/Chicago',
  },
  '39078': {
    zip: '39078',
    city: 'Georgetown',
    state: 'MS',
    latitude: '31.838165',
    longitude: '-90.219233',
    timeZoneId: 'America/Chicago',
  },
  '39079': {
    zip: '39079',
    city: 'Goodman',
    state: 'MS',
    latitude: '32.96883',
    longitude: '-89.910822',
    timeZoneId: 'America/Chicago',
  },
  '39080': {
    zip: '39080',
    city: 'Harperville',
    state: 'MS',
    latitude: '32.496002',
    longitude: '-89.489832',
    timeZoneId: 'America/Chicago',
  },
  '39081': {
    zip: '39081',
    city: 'Fayette',
    state: 'MS',
    latitude: '31.718062',
    longitude: '-91.038486',
    timeZoneId: 'America/Chicago',
  },
  '39082': {
    zip: '39082',
    city: 'Harrisville',
    state: 'MS',
    latitude: '31.957765',
    longitude: '-90.093264',
    timeZoneId: 'America/Chicago',
  },
  '39083': {
    zip: '39083',
    city: 'Hazlehurst',
    state: 'MS',
    latitude: '31.865846',
    longitude: '-90.38783',
    timeZoneId: 'America/Chicago',
  },
  '39086': {
    zip: '39086',
    city: 'Hermanville',
    state: 'MS',
    latitude: '32.01108',
    longitude: '-90.8178',
    timeZoneId: 'America/Chicago',
  },
  '39087': {
    zip: '39087',
    city: 'Hillsboro',
    state: 'MS',
    latitude: '32.456505',
    longitude: '-89.511242',
    timeZoneId: 'America/Chicago',
  },
  '39088': {
    zip: '39088',
    city: 'Holly Bluff',
    state: 'MS',
    latitude: '32.832419',
    longitude: '-90.698675',
    timeZoneId: 'America/Chicago',
  },
  '39090': {
    zip: '39090',
    city: 'Kosciusko',
    state: 'MS',
    latitude: '33.056108',
    longitude: '-89.589283',
    timeZoneId: 'America/Chicago',
  },
  '39092': {
    zip: '39092',
    city: 'Lake',
    state: 'MS',
    latitude: '32.340473',
    longitude: '-89.356039',
    timeZoneId: 'America/Chicago',
  },
  '39094': {
    zip: '39094',
    city: 'Lena',
    state: 'MS',
    latitude: '32.638026',
    longitude: '-89.56815',
    timeZoneId: 'America/Chicago',
  },
  '39095': {
    zip: '39095',
    city: 'Lexington',
    state: 'MS',
    latitude: '33.111605',
    longitude: '-90.05111',
    timeZoneId: 'America/Chicago',
  },
  '39096': {
    zip: '39096',
    city: 'Lorman',
    state: 'MS',
    latitude: '31.817159',
    longitude: '-91.197038',
    timeZoneId: 'America/Chicago',
  },
  '39097': {
    zip: '39097',
    city: 'Louise',
    state: 'MS',
    latitude: '32.977406',
    longitude: '-90.591952',
    timeZoneId: 'America/Chicago',
  },
  '39098': {
    zip: '39098',
    city: 'Ludlow',
    state: 'MS',
    latitude: '32.57298',
    longitude: '-89.709348',
    timeZoneId: 'America/Chicago',
  },
  '39107': {
    zip: '39107',
    city: 'Mc Adams',
    state: 'MS',
    latitude: '33.019495',
    longitude: '-89.687084',
    timeZoneId: 'America/Chicago',
  },
  '39108': {
    zip: '39108',
    city: 'Mc Cool',
    state: 'MS',
    latitude: '33.168445',
    longitude: '-89.328314',
    timeZoneId: 'America/Chicago',
  },
  '39109': {
    zip: '39109',
    city: 'Madden',
    state: 'MS',
    latitude: '32.707565',
    longitude: '-89.376192',
    timeZoneId: 'America/Chicago',
  },
  '39110': {
    zip: '39110',
    city: 'Madison',
    state: 'MS',
    latitude: '32.467942',
    longitude: '-90.121854',
    timeZoneId: 'America/Chicago',
  },
  '39111': {
    zip: '39111',
    city: 'Magee',
    state: 'MS',
    latitude: '31.872919',
    longitude: '-89.734963',
    timeZoneId: 'America/Chicago',
  },
  '39113': {
    zip: '39113',
    city: 'Mayersville',
    state: 'MS',
    latitude: '32.851702',
    longitude: '-91.03583',
    timeZoneId: 'America/Chicago',
  },
  '39114': {
    zip: '39114',
    city: 'Mendenhall',
    state: 'MS',
    latitude: '31.962304',
    longitude: '-89.873649',
    timeZoneId: 'America/Chicago',
  },
  '39115': {
    zip: '39115',
    city: 'Midnight',
    state: 'MS',
    latitude: '33.050106',
    longitude: '-90.572655',
    timeZoneId: 'America/Chicago',
  },
  '39116': {
    zip: '39116',
    city: 'Mize',
    state: 'MS',
    latitude: '31.849432',
    longitude: '-89.564337',
    timeZoneId: 'America/Chicago',
  },
  '39117': {
    zip: '39117',
    city: 'Morton',
    state: 'MS',
    latitude: '32.352309',
    longitude: '-89.654064',
    timeZoneId: 'America/Chicago',
  },
  '39119': {
    zip: '39119',
    city: 'Mount Olive',
    state: 'MS',
    latitude: '31.720647',
    longitude: '-89.680754',
    timeZoneId: 'America/Chicago',
  },
  '39120': {
    zip: '39120',
    city: 'Natchez',
    state: 'MS',
    latitude: '31.53648',
    longitude: '-91.3266',
    timeZoneId: 'America/Chicago',
  },
  '39121': {
    zip: '39121',
    city: 'Natchez',
    state: 'MS',
    latitude: '31.508317',
    longitude: '-91.36173',
    timeZoneId: 'America/Chicago',
  },
  '39122': {
    zip: '39122',
    city: 'Natchez',
    state: 'MS',
    latitude: '31.562045',
    longitude: '-91.401246',
    timeZoneId: 'America/Chicago',
  },
  '39130': {
    zip: '39130',
    city: 'Madison',
    state: 'MS',
    latitude: '32.462957',
    longitude: '-90.114896',
    timeZoneId: 'America/Chicago',
  },
  '39140': {
    zip: '39140',
    city: 'Newhebron',
    state: 'MS',
    latitude: '31.73028',
    longitude: '-90.01752',
    timeZoneId: 'America/Chicago',
  },
  '39144': {
    zip: '39144',
    city: 'Pattison',
    state: 'MS',
    latitude: '31.851924',
    longitude: '-90.804549',
    timeZoneId: 'America/Chicago',
  },
  '39145': {
    zip: '39145',
    city: 'Pelahatchie',
    state: 'MS',
    latitude: '32.327227',
    longitude: '-89.790741',
    timeZoneId: 'America/Chicago',
  },
  '39146': {
    zip: '39146',
    city: 'Pickens',
    state: 'MS',
    latitude: '32.889754',
    longitude: '-89.971322',
    timeZoneId: 'America/Chicago',
  },
  '39148': {
    zip: '39148',
    city: 'Piney Woods',
    state: 'MS',
    latitude: '32.0654',
    longitude: '-89.99442',
    timeZoneId: 'America/Chicago',
  },
  '39149': {
    zip: '39149',
    city: 'Pinola',
    state: 'MS',
    latitude: '31.824327',
    longitude: '-90.022923',
    timeZoneId: 'America/Chicago',
  },
  '39150': {
    zip: '39150',
    city: 'Port Gibson',
    state: 'MS',
    latitude: '31.957545',
    longitude: '-90.981874',
    timeZoneId: 'America/Chicago',
  },
  '39151': {
    zip: '39151',
    city: 'Puckett',
    state: 'MS',
    latitude: '32.101045',
    longitude: '-89.787095',
    timeZoneId: 'America/Chicago',
  },
  '39152': {
    zip: '39152',
    city: 'Pulaski',
    state: 'MS',
    latitude: '32.273915',
    longitude: '-89.635116',
    timeZoneId: 'America/Chicago',
  },
  '39153': {
    zip: '39153',
    city: 'Raleigh',
    state: 'MS',
    latitude: '32.06442',
    longitude: '-89.5071',
    timeZoneId: 'America/Chicago',
  },
  '39154': {
    zip: '39154',
    city: 'Raymond',
    state: 'MS',
    latitude: '32.22396',
    longitude: '-90.44304',
    timeZoneId: 'America/Chicago',
  },
  '39156': {
    zip: '39156',
    city: 'Redwood',
    state: 'MS',
    latitude: '32.422033',
    longitude: '-90.816785',
    timeZoneId: 'America/Chicago',
  },
  '39157': {
    zip: '39157',
    city: 'Ridgeland',
    state: 'MS',
    latitude: '32.419743',
    longitude: '-90.125726',
    timeZoneId: 'America/Chicago',
  },
  '39158': {
    zip: '39158',
    city: 'Ridgeland',
    state: 'MS',
    latitude: '32.418992',
    longitude: '-90.132277',
    timeZoneId: 'America/Chicago',
  },
  '39159': {
    zip: '39159',
    city: 'Rolling Fork',
    state: 'MS',
    latitude: '32.89767',
    longitude: '-90.882023',
    timeZoneId: 'America/Chicago',
  },
  '39160': {
    zip: '39160',
    city: 'Sallis',
    state: 'MS',
    latitude: '32.978934',
    longitude: '-89.78079',
    timeZoneId: 'America/Chicago',
  },
  '39161': {
    zip: '39161',
    city: 'Sandhill',
    state: 'MS',
    latitude: '32.469765',
    longitude: '-89.876398',
    timeZoneId: 'America/Chicago',
  },
  '39162': {
    zip: '39162',
    city: 'Satartia',
    state: 'MS',
    latitude: '32.616651',
    longitude: '-90.625798',
    timeZoneId: 'America/Chicago',
  },
  '39163': {
    zip: '39163',
    city: 'Sharon',
    state: 'MS',
    latitude: '32.658868',
    longitude: '-89.937209',
    timeZoneId: 'America/Chicago',
  },
  '39165': {
    zip: '39165',
    city: 'Sibley',
    state: 'MS',
    latitude: '31.385954',
    longitude: '-91.406023',
    timeZoneId: 'America/Chicago',
  },
  '39166': {
    zip: '39166',
    city: 'Silver City',
    state: 'MS',
    latitude: '33.094647',
    longitude: '-90.490461',
    timeZoneId: 'America/Chicago',
  },
  '39167': {
    zip: '39167',
    city: 'Star',
    state: 'MS',
    latitude: '32.094353',
    longitude: '-90.085165',
    timeZoneId: 'America/Chicago',
  },
  '39168': {
    zip: '39168',
    city: 'Taylorsville',
    state: 'MS',
    latitude: '31.825343',
    longitude: '-89.429553',
    timeZoneId: 'America/Chicago',
  },
  '39169': {
    zip: '39169',
    city: 'Tchula',
    state: 'MS',
    latitude: '33.179923',
    longitude: '-90.223094',
    timeZoneId: 'America/Chicago',
  },
  '39170': {
    zip: '39170',
    city: 'Terry',
    state: 'MS',
    latitude: '32.140911',
    longitude: '-90.34794',
    timeZoneId: 'America/Chicago',
  },
  '39171': {
    zip: '39171',
    city: 'Thomastown',
    state: 'MS',
    latitude: '32.863506',
    longitude: '-89.670941',
    timeZoneId: 'America/Chicago',
  },
  '39173': {
    zip: '39173',
    city: 'Tinsley',
    state: 'MS',
    latitude: '32.731312',
    longitude: '-90.458132',
    timeZoneId: 'America/Chicago',
  },
  '39174': {
    zip: '39174',
    city: 'Tougaloo',
    state: 'MS',
    latitude: '32.397137',
    longitude: '-90.153459',
    timeZoneId: 'America/Chicago',
  },
  '39175': {
    zip: '39175',
    city: 'Utica',
    state: 'MS',
    latitude: '32.120866',
    longitude: '-90.599332',
    timeZoneId: 'America/Chicago',
  },
  '39176': {
    zip: '39176',
    city: 'Vaiden',
    state: 'MS',
    latitude: '33.334334',
    longitude: '-89.7418',
    timeZoneId: 'America/Chicago',
  },
  '39177': {
    zip: '39177',
    city: 'Valley Park',
    state: 'MS',
    latitude: '32.585852',
    longitude: '-90.846431',
    timeZoneId: 'America/Chicago',
  },
  '39179': {
    zip: '39179',
    city: 'Vaughan',
    state: 'MS',
    latitude: '32.811569',
    longitude: '-90.093514',
    timeZoneId: 'America/Chicago',
  },
  '39180': {
    zip: '39180',
    city: 'Vicksburg',
    state: 'MS',
    latitude: '32.323121',
    longitude: '-90.860809',
    timeZoneId: 'America/Chicago',
  },
  '39181': {
    zip: '39181',
    city: 'Vicksburg',
    state: 'MS',
    latitude: '32.343713',
    longitude: '-90.85746',
    timeZoneId: 'America/Chicago',
  },
  '39182': {
    zip: '39182',
    city: 'Vicksburg',
    state: 'MS',
    latitude: '32.350854',
    longitude: '-90.87854',
    timeZoneId: 'America/Chicago',
  },
  '39183': {
    zip: '39183',
    city: 'Vicksburg',
    state: 'MS',
    latitude: '32.390993',
    longitude: '-90.829096',
    timeZoneId: 'America/Chicago',
  },
  '39189': {
    zip: '39189',
    city: 'Walnut Grove',
    state: 'MS',
    latitude: '32.596584',
    longitude: '-89.456321',
    timeZoneId: 'America/Chicago',
  },
  '39190': {
    zip: '39190',
    city: 'Washington',
    state: 'MS',
    latitude: '31.576072',
    longitude: '-91.29918',
    timeZoneId: 'America/Chicago',
  },
  '39191': {
    zip: '39191',
    city: 'Wesson',
    state: 'MS',
    latitude: '31.701603',
    longitude: '-90.395297',
    timeZoneId: 'America/Chicago',
  },
  '39192': {
    zip: '39192',
    city: 'West',
    state: 'MS',
    latitude: '33.204672',
    longitude: '-89.782274',
    timeZoneId: 'America/Chicago',
  },
  '39193': {
    zip: '39193',
    city: 'Whitfield',
    state: 'MS',
    latitude: '32.236068',
    longitude: '-90.070628',
    timeZoneId: 'America/Chicago',
  },
  '39194': {
    zip: '39194',
    city: 'Yazoo City',
    state: 'MS',
    latitude: '32.857919',
    longitude: '-90.405161',
    timeZoneId: 'America/Chicago',
  },
  '39201': {
    zip: '39201',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.292867',
    longitude: '-90.184187',
    timeZoneId: 'America/Chicago',
  },
  '39202': {
    zip: '39202',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.310057',
    longitude: '-90.176007',
    timeZoneId: 'America/Chicago',
  },
  '39203': {
    zip: '39203',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.309387',
    longitude: '-90.196952',
    timeZoneId: 'America/Chicago',
  },
  '39204': {
    zip: '39204',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.285241',
    longitude: '-90.230923',
    timeZoneId: 'America/Chicago',
  },
  '39205': {
    zip: '39205',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.304324',
    longitude: '-90.182529',
    timeZoneId: 'America/Chicago',
  },
  '39206': {
    zip: '39206',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.364777',
    longitude: '-90.17174',
    timeZoneId: 'America/Chicago',
  },
  '39207': {
    zip: '39207',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.306196',
    longitude: '-90.198595',
    timeZoneId: 'America/Chicago',
  },
  '39208': {
    zip: '39208',
    city: 'Pearl',
    state: 'MS',
    latitude: '32.27172',
    longitude: '-90.10494',
    timeZoneId: 'America/Chicago',
  },
  '39209': {
    zip: '39209',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.324026',
    longitude: '-90.252348',
    timeZoneId: 'America/Chicago',
  },
  '39210': {
    zip: '39210',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.276055',
    longitude: '-90.218261',
    timeZoneId: 'America/Chicago',
  },
  '39211': {
    zip: '39211',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.370898',
    longitude: '-90.12806',
    timeZoneId: 'America/Chicago',
  },
  '39212': {
    zip: '39212',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.243548',
    longitude: '-90.263734',
    timeZoneId: 'America/Chicago',
  },
  '39213': {
    zip: '39213',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.346826',
    longitude: '-90.214369',
    timeZoneId: 'America/Chicago',
  },
  '39215': {
    zip: '39215',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.297171',
    longitude: '-90.183699',
    timeZoneId: 'America/Chicago',
  },
  '39216': {
    zip: '39216',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.335115',
    longitude: '-90.166439',
    timeZoneId: 'America/Chicago',
  },
  '39217': {
    zip: '39217',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.288941',
    longitude: '-90.186095',
    timeZoneId: 'America/Chicago',
  },
  '39218': {
    zip: '39218',
    city: 'Richland',
    state: 'MS',
    latitude: '32.2377',
    longitude: '-90.1548',
    timeZoneId: 'America/Chicago',
  },
  '39225': {
    zip: '39225',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.291456',
    longitude: '-90.18351',
    timeZoneId: 'America/Chicago',
  },
  '39232': {
    zip: '39232',
    city: 'Flowood',
    state: 'MS',
    latitude: '32.289828',
    longitude: '-90.181404',
    timeZoneId: 'America/Chicago',
  },
  '39236': {
    zip: '39236',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.364207',
    longitude: '-90.14518',
    timeZoneId: 'America/Chicago',
  },
  '39250': {
    zip: '39250',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.291319',
    longitude: '-90.185931',
    timeZoneId: 'America/Chicago',
  },
  '39269': {
    zip: '39269',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.299021',
    longitude: '-90.185387',
    timeZoneId: 'America/Chicago',
  },
  '39271': {
    zip: '39271',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.289835',
    longitude: '-90.18084',
    timeZoneId: 'America/Chicago',
  },
  '39272': {
    zip: '39272',
    city: 'Byram',
    state: 'MS',
    latitude: '32.216828',
    longitude: '-90.269123',
    timeZoneId: 'America/Chicago',
  },
  '39282': {
    zip: '39282',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.254854',
    longitude: '-90.248918',
    timeZoneId: 'America/Chicago',
  },
  '39283': {
    zip: '39283',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.367889',
    longitude: '-90.231119',
    timeZoneId: 'America/Chicago',
  },
  '39284': {
    zip: '39284',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.275332',
    longitude: '-90.218859',
    timeZoneId: 'America/Chicago',
  },
  '39286': {
    zip: '39286',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.350653',
    longitude: '-90.176499',
    timeZoneId: 'America/Chicago',
  },
  '39288': {
    zip: '39288',
    city: 'Pearl',
    state: 'MS',
    latitude: '32.30002',
    longitude: '-90.145549',
    timeZoneId: 'America/Chicago',
  },
  '39289': {
    zip: '39289',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.385455',
    longitude: '-90.276532',
    timeZoneId: 'America/Chicago',
  },
  '39296': {
    zip: '39296',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.345145',
    longitude: '-90.165957',
    timeZoneId: 'America/Chicago',
  },
  '39298': {
    zip: '39298',
    city: 'Jackson',
    state: 'MS',
    latitude: '32.321984',
    longitude: '-90.206032',
    timeZoneId: 'America/Chicago',
  },
  '39301': {
    zip: '39301',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.372903',
    longitude: '-88.696221',
    timeZoneId: 'America/Chicago',
  },
  '39302': {
    zip: '39302',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.365411',
    longitude: '-88.700251',
    timeZoneId: 'America/Chicago',
  },
  '39303': {
    zip: '39303',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.424433',
    longitude: '-88.704863',
    timeZoneId: 'America/Chicago',
  },
  '39304': {
    zip: '39304',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.364295',
    longitude: '-88.68699',
    timeZoneId: 'America/Chicago',
  },
  '39305': {
    zip: '39305',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.402996',
    longitude: '-88.706033',
    timeZoneId: 'America/Chicago',
  },
  '39307': {
    zip: '39307',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.330793',
    longitude: '-88.827844',
    timeZoneId: 'America/Chicago',
  },
  '39309': {
    zip: '39309',
    city: 'Meridian',
    state: 'MS',
    latitude: '32.537503',
    longitude: '-88.567935',
    timeZoneId: 'America/Chicago',
  },
  '39320': {
    zip: '39320',
    city: 'Bailey',
    state: 'MS',
    latitude: '32.487494',
    longitude: '-88.69981',
    timeZoneId: 'America/Chicago',
  },
  '39322': {
    zip: '39322',
    city: 'Buckatunna',
    state: 'MS',
    latitude: '31.490348',
    longitude: '-88.561931',
    timeZoneId: 'America/Chicago',
  },
  '39323': {
    zip: '39323',
    city: 'Chunky',
    state: 'MS',
    latitude: '32.256145',
    longitude: '-88.948437',
    timeZoneId: 'America/Chicago',
  },
  '39324': {
    zip: '39324',
    city: 'Clara',
    state: 'MS',
    latitude: '31.580476',
    longitude: '-88.694206',
    timeZoneId: 'America/Chicago',
  },
  '39325': {
    zip: '39325',
    city: 'Collinsville',
    state: 'MS',
    latitude: '32.524029',
    longitude: '-88.815609',
    timeZoneId: 'America/Chicago',
  },
  '39326': {
    zip: '39326',
    city: 'Daleville',
    state: 'MS',
    latitude: '32.547875',
    longitude: '-88.672319',
    timeZoneId: 'America/Chicago',
  },
  '39327': {
    zip: '39327',
    city: 'Decatur',
    state: 'MS',
    latitude: '32.440159',
    longitude: '-89.109307',
    timeZoneId: 'America/Chicago',
  },
  '39328': {
    zip: '39328',
    city: 'De Kalb',
    state: 'MS',
    latitude: '32.774588',
    longitude: '-88.662108',
    timeZoneId: 'America/Chicago',
  },
  '39330': {
    zip: '39330',
    city: 'Enterprise',
    state: 'MS',
    latitude: '32.082172',
    longitude: '-88.745621',
    timeZoneId: 'America/Chicago',
  },
  '39332': {
    zip: '39332',
    city: 'Hickory',
    state: 'MS',
    latitude: '32.317605',
    longitude: '-89.02095',
    timeZoneId: 'America/Chicago',
  },
  '39335': {
    zip: '39335',
    city: 'Lauderdale',
    state: 'MS',
    latitude: '32.52966',
    longitude: '-88.47852',
    timeZoneId: 'America/Chicago',
  },
  '39336': {
    zip: '39336',
    city: 'Lawrence',
    state: 'MS',
    latitude: '32.295244',
    longitude: '-89.267534',
    timeZoneId: 'America/Chicago',
  },
  '39337': {
    zip: '39337',
    city: 'Little Rock',
    state: 'MS',
    latitude: '32.538238',
    longitude: '-89.18462',
    timeZoneId: 'America/Chicago',
  },
  '39338': {
    zip: '39338',
    city: 'Louin',
    state: 'MS',
    latitude: '32.12238',
    longitude: '-89.2296',
    timeZoneId: 'America/Chicago',
  },
  '39339': {
    zip: '39339',
    city: 'Louisville',
    state: 'MS',
    latitude: '33.125133',
    longitude: '-89.055051',
    timeZoneId: 'America/Chicago',
  },
  '39341': {
    zip: '39341',
    city: 'Macon',
    state: 'MS',
    latitude: '33.113434',
    longitude: '-88.556999',
    timeZoneId: 'America/Chicago',
  },
  '39342': {
    zip: '39342',
    city: 'Marion',
    state: 'MS',
    latitude: '32.4189',
    longitude: '-88.64727',
    timeZoneId: 'America/Chicago',
  },
  '39345': {
    zip: '39345',
    city: 'Newton',
    state: 'MS',
    latitude: '32.317802',
    longitude: '-89.164446',
    timeZoneId: 'America/Chicago',
  },
  '39346': {
    zip: '39346',
    city: 'Noxapater',
    state: 'MS',
    latitude: '32.975786',
    longitude: '-89.155652',
    timeZoneId: 'America/Chicago',
  },
  '39347': {
    zip: '39347',
    city: 'Pachuta',
    state: 'MS',
    latitude: '32.004817',
    longitude: '-88.865821',
    timeZoneId: 'America/Chicago',
  },
  '39348': {
    zip: '39348',
    city: 'Paulding',
    state: 'MS',
    latitude: '32.009581',
    longitude: '-89.079164',
    timeZoneId: 'America/Chicago',
  },
  '39350': {
    zip: '39350',
    city: 'Philadelphia',
    state: 'MS',
    latitude: '32.773985',
    longitude: '-89.114722',
    timeZoneId: 'America/Chicago',
  },
  '39352': {
    zip: '39352',
    city: 'Porterville',
    state: 'MS',
    latitude: '32.652809',
    longitude: '-88.489078',
    timeZoneId: 'America/Chicago',
  },
  '39354': {
    zip: '39354',
    city: 'Preston',
    state: 'MS',
    latitude: '32.862463',
    longitude: '-88.833533',
    timeZoneId: 'America/Chicago',
  },
  '39355': {
    zip: '39355',
    city: 'Quitman',
    state: 'MS',
    latitude: '32.045394',
    longitude: '-88.72368',
    timeZoneId: 'America/Chicago',
  },
  '39356': {
    zip: '39356',
    city: 'Rose Hill',
    state: 'MS',
    latitude: '32.135619',
    longitude: '-89.012567',
    timeZoneId: 'America/Chicago',
  },
  '39358': {
    zip: '39358',
    city: 'Scooba',
    state: 'MS',
    latitude: '32.840314',
    longitude: '-88.517252',
    timeZoneId: 'America/Chicago',
  },
  '39359': {
    zip: '39359',
    city: 'Sebastopol',
    state: 'MS',
    latitude: '32.57067',
    longitude: '-89.332385',
    timeZoneId: 'America/Chicago',
  },
  '39360': {
    zip: '39360',
    city: 'Shubuta',
    state: 'MS',
    latitude: '31.878',
    longitude: '-88.70808',
    timeZoneId: 'America/Chicago',
  },
  '39361': {
    zip: '39361',
    city: 'Shuqualak',
    state: 'MS',
    latitude: '32.967999',
    longitude: '-88.545385',
    timeZoneId: 'America/Chicago',
  },
  '39362': {
    zip: '39362',
    city: 'State Line',
    state: 'MS',
    latitude: '31.436443',
    longitude: '-88.46827',
    timeZoneId: 'America/Chicago',
  },
  '39363': {
    zip: '39363',
    city: 'Stonewall',
    state: 'MS',
    latitude: '32.133765',
    longitude: '-88.798622',
    timeZoneId: 'America/Chicago',
  },
  '39364': {
    zip: '39364',
    city: 'Toomsuba',
    state: 'MS',
    latitude: '32.43162',
    longitude: '-88.4661',
    timeZoneId: 'America/Chicago',
  },
  '39365': {
    zip: '39365',
    city: 'Union',
    state: 'MS',
    latitude: '32.570827',
    longitude: '-89.121425',
    timeZoneId: 'America/Chicago',
  },
  '39366': {
    zip: '39366',
    city: 'Vossburg',
    state: 'MS',
    latitude: '31.981816',
    longitude: '-88.971858',
    timeZoneId: 'America/Chicago',
  },
  '39367': {
    zip: '39367',
    city: 'Waynesboro',
    state: 'MS',
    latitude: '31.677923',
    longitude: '-88.64205',
    timeZoneId: 'America/Chicago',
  },
  '39401': {
    zip: '39401',
    city: 'Hattiesburg',
    state: 'MS',
    latitude: '31.25064',
    longitude: '-89.27646',
    timeZoneId: 'America/Chicago',
  },
  '39402': {
    zip: '39402',
    city: 'Hattiesburg',
    state: 'MS',
    latitude: '31.313286',
    longitude: '-89.341967',
    timeZoneId: 'America/Chicago',
  },
  '39403': {
    zip: '39403',
    city: 'Hattiesburg',
    state: 'MS',
    latitude: '31.322369',
    longitude: '-89.278966',
    timeZoneId: 'America/Chicago',
  },
  '39404': {
    zip: '39404',
    city: 'Hattiesburg',
    state: 'MS',
    latitude: '31.296391',
    longitude: '-89.429287',
    timeZoneId: 'America/Chicago',
  },
  '39406': {
    zip: '39406',
    city: 'Hattiesburg',
    state: 'MS',
    latitude: '31.330181',
    longitude: '-89.338932',
    timeZoneId: 'America/Chicago',
  },
  '39407': {
    zip: '39407',
    city: 'Hattiesburg',
    state: 'MS',
    latitude: '31.313533',
    longitude: '-89.305394',
    timeZoneId: 'America/Chicago',
  },
  '39421': {
    zip: '39421',
    city: 'Bassfield',
    state: 'MS',
    latitude: '31.495255',
    longitude: '-89.685891',
    timeZoneId: 'America/Chicago',
  },
  '39422': {
    zip: '39422',
    city: 'Bay Springs',
    state: 'MS',
    latitude: '31.945464',
    longitude: '-89.199718',
    timeZoneId: 'America/Chicago',
  },
  '39423': {
    zip: '39423',
    city: 'Beaumont',
    state: 'MS',
    latitude: '31.1295',
    longitude: '-88.90464',
    timeZoneId: 'America/Chicago',
  },
  '39425': {
    zip: '39425',
    city: 'Brooklyn',
    state: 'MS',
    latitude: '31.03116',
    longitude: '-89.172',
    timeZoneId: 'America/Chicago',
  },
  '39426': {
    zip: '39426',
    city: 'Carriere',
    state: 'MS',
    latitude: '30.592349',
    longitude: '-89.639175',
    timeZoneId: 'America/Chicago',
  },
  '39427': {
    zip: '39427',
    city: 'Carson',
    state: 'MS',
    latitude: '31.56113',
    longitude: '-89.781077',
    timeZoneId: 'America/Chicago',
  },
  '39428': {
    zip: '39428',
    city: 'Collins',
    state: 'MS',
    latitude: '31.65228',
    longitude: '-89.55732',
    timeZoneId: 'America/Chicago',
  },
  '39429': {
    zip: '39429',
    city: 'Columbia',
    state: 'MS',
    latitude: '31.256049',
    longitude: '-89.826376',
    timeZoneId: 'America/Chicago',
  },
  '39436': {
    zip: '39436',
    city: 'Eastabuchie',
    state: 'MS',
    latitude: '31.472935',
    longitude: '-89.326297',
    timeZoneId: 'America/Chicago',
  },
  '39437': {
    zip: '39437',
    city: 'Ellisville',
    state: 'MS',
    latitude: '31.59786',
    longitude: '-89.2086',
    timeZoneId: 'America/Chicago',
  },
  '39439': {
    zip: '39439',
    city: 'Heidelberg',
    state: 'MS',
    latitude: '31.897396',
    longitude: '-89.002726',
    timeZoneId: 'America/Chicago',
  },
  '39440': {
    zip: '39440',
    city: 'Laurel',
    state: 'MS',
    latitude: '31.71048',
    longitude: '-89.10234',
    timeZoneId: 'America/Chicago',
  },
  '39441': {
    zip: '39441',
    city: 'Laurel',
    state: 'MS',
    latitude: '31.704944',
    longitude: '-89.12324',
    timeZoneId: 'America/Chicago',
  },
  '39442': {
    zip: '39442',
    city: 'Laurel',
    state: 'MS',
    latitude: '31.693155',
    longitude: '-89.143217',
    timeZoneId: 'America/Chicago',
  },
  '39443': {
    zip: '39443',
    city: 'Laurel',
    state: 'MS',
    latitude: '31.69349',
    longitude: '-89.130537',
    timeZoneId: 'America/Chicago',
  },
  '39451': {
    zip: '39451',
    city: 'Leakesville',
    state: 'MS',
    latitude: '31.152091',
    longitude: '-88.563411',
    timeZoneId: 'America/Chicago',
  },
  '39452': {
    zip: '39452',
    city: 'Lucedale',
    state: 'MS',
    latitude: '30.71326',
    longitude: '-88.524635',
    timeZoneId: 'America/Chicago',
  },
  '39455': {
    zip: '39455',
    city: 'Lumberton',
    state: 'MS',
    latitude: '31.05996',
    longitude: '-89.50218',
    timeZoneId: 'America/Chicago',
  },
  '39456': {
    zip: '39456',
    city: 'Mc Lain',
    state: 'MS',
    latitude: '31.07388',
    longitude: '-88.81608',
    timeZoneId: 'America/Chicago',
  },
  '39457': {
    zip: '39457',
    city: 'Mc Neill',
    state: 'MS',
    latitude: '30.668484',
    longitude: '-89.638961',
    timeZoneId: 'America/Chicago',
  },
  '39459': {
    zip: '39459',
    city: 'Moselle',
    state: 'MS',
    latitude: '31.48274',
    longitude: '-89.327507',
    timeZoneId: 'America/Chicago',
  },
  '39460': {
    zip: '39460',
    city: 'Moss',
    state: 'MS',
    latitude: '31.81504',
    longitude: '-89.17748',
    timeZoneId: 'America/Chicago',
  },
  '39461': {
    zip: '39461',
    city: 'Neely',
    state: 'MS',
    latitude: '31.186218',
    longitude: '-88.736886',
    timeZoneId: 'America/Chicago',
  },
  '39462': {
    zip: '39462',
    city: 'New Augusta',
    state: 'MS',
    latitude: '31.291608',
    longitude: '-89.067446',
    timeZoneId: 'America/Chicago',
  },
  '39463': {
    zip: '39463',
    city: 'Nicholson',
    state: 'MS',
    latitude: '30.557662',
    longitude: '-89.644314',
    timeZoneId: 'America/Chicago',
  },
  '39464': {
    zip: '39464',
    city: 'Ovett',
    state: 'MS',
    latitude: '31.403056',
    longitude: '-89.093336',
    timeZoneId: 'America/Chicago',
  },
  '39465': {
    zip: '39465',
    city: 'Petal',
    state: 'MS',
    latitude: '31.34004',
    longitude: '-89.1891',
    timeZoneId: 'America/Chicago',
  },
  '39466': {
    zip: '39466',
    city: 'Picayune',
    state: 'MS',
    latitude: '30.58368',
    longitude: '-89.67594',
    timeZoneId: 'America/Chicago',
  },
  '39470': {
    zip: '39470',
    city: 'Poplarville',
    state: 'MS',
    latitude: '30.87972',
    longitude: '-89.58846',
    timeZoneId: 'America/Chicago',
  },
  '39474': {
    zip: '39474',
    city: 'Prentiss',
    state: 'MS',
    latitude: '31.595381',
    longitude: '-89.875941',
    timeZoneId: 'America/Chicago',
  },
  '39475': {
    zip: '39475',
    city: 'Purvis',
    state: 'MS',
    latitude: '31.065616',
    longitude: '-89.28984',
    timeZoneId: 'America/Chicago',
  },
  '39476': {
    zip: '39476',
    city: 'Richton',
    state: 'MS',
    latitude: '31.335',
    longitude: '-88.94616',
    timeZoneId: 'America/Chicago',
  },
  '39477': {
    zip: '39477',
    city: 'Sandersville',
    state: 'MS',
    latitude: '31.787509',
    longitude: '-89.038271',
    timeZoneId: 'America/Chicago',
  },
  '39478': {
    zip: '39478',
    city: 'Sandy Hook',
    state: 'MS',
    latitude: '31.047598',
    longitude: '-89.810405',
    timeZoneId: 'America/Chicago',
  },
  '39479': {
    zip: '39479',
    city: 'Seminary',
    state: 'MS',
    latitude: '31.53282',
    longitude: '-89.48658',
    timeZoneId: 'America/Chicago',
  },
  '39480': {
    zip: '39480',
    city: 'Soso',
    state: 'MS',
    latitude: '31.763949',
    longitude: '-89.320828',
    timeZoneId: 'America/Chicago',
  },
  '39481': {
    zip: '39481',
    city: 'Stringer',
    state: 'MS',
    latitude: '31.846712',
    longitude: '-89.258554',
    timeZoneId: 'America/Chicago',
  },
  '39482': {
    zip: '39482',
    city: 'Sumrall',
    state: 'MS',
    latitude: '31.36296',
    longitude: '-89.58918',
    timeZoneId: 'America/Chicago',
  },
  '39483': {
    zip: '39483',
    city: 'Foxworth',
    state: 'MS',
    latitude: '31.25496',
    longitude: '-89.93382',
    timeZoneId: 'America/Chicago',
  },
  '39501': {
    zip: '39501',
    city: 'Gulfport',
    state: 'MS',
    latitude: '30.385481',
    longitude: '-89.086881',
    timeZoneId: 'America/Chicago',
  },
  '39502': {
    zip: '39502',
    city: 'Gulfport',
    state: 'MS',
    latitude: '30.389224',
    longitude: '-89.099424',
    timeZoneId: 'America/Chicago',
  },
  '39503': {
    zip: '39503',
    city: 'Gulfport',
    state: 'MS',
    latitude: '30.4731',
    longitude: '-89.15382',
    timeZoneId: 'America/Chicago',
  },
  '39505': {
    zip: '39505',
    city: 'Gulfport',
    state: 'MS',
    latitude: '30.442559',
    longitude: '-89.067759',
    timeZoneId: 'America/Chicago',
  },
  '39506': {
    zip: '39506',
    city: 'Gulfport',
    state: 'MS',
    latitude: '30.389458',
    longitude: '-89.057201',
    timeZoneId: 'America/Chicago',
  },
  '39507': {
    zip: '39507',
    city: 'Gulfport',
    state: 'MS',
    latitude: '30.394254',
    longitude: '-89.037041',
    timeZoneId: 'America/Chicago',
  },
  '39520': {
    zip: '39520',
    city: 'Bay Saint Louis',
    state: 'MS',
    latitude: '30.31621',
    longitude: '-89.483285',
    timeZoneId: 'America/Chicago',
  },
  '39521': {
    zip: '39521',
    city: 'Bay Saint Louis',
    state: 'MS',
    latitude: '30.329135',
    longitude: '-89.370668',
    timeZoneId: 'America/Chicago',
  },
  '39522': {
    zip: '39522',
    city: 'Stennis Space Center',
    state: 'MS',
    latitude: '30.335026',
    longitude: '-89.509144',
    timeZoneId: 'America/Chicago',
  },
  '39525': {
    zip: '39525',
    city: 'Diamondhead',
    state: 'MS',
    latitude: '30.38456',
    longitude: '-89.375564',
    timeZoneId: 'America/Chicago',
  },
  '39529': {
    zip: '39529',
    city: 'Stennis Space Center',
    state: 'MS',
    latitude: '30.337636',
    longitude: '-89.50458',
    timeZoneId: 'America/Chicago',
  },
  '39530': {
    zip: '39530',
    city: 'Biloxi',
    state: 'MS',
    latitude: '30.400015',
    longitude: '-88.897393',
    timeZoneId: 'America/Chicago',
  },
  '39531': {
    zip: '39531',
    city: 'Biloxi',
    state: 'MS',
    latitude: '30.400773',
    longitude: '-88.962563',
    timeZoneId: 'America/Chicago',
  },
  '39532': {
    zip: '39532',
    city: 'Biloxi',
    state: 'MS',
    latitude: '30.461218',
    longitude: '-88.949246',
    timeZoneId: 'America/Chicago',
  },
  '39533': {
    zip: '39533',
    city: 'Biloxi',
    state: 'MS',
    latitude: '30.396332',
    longitude: '-88.884915',
    timeZoneId: 'America/Chicago',
  },
  '39534': {
    zip: '39534',
    city: 'Biloxi',
    state: 'MS',
    latitude: '30.413857',
    longitude: '-88.910394',
    timeZoneId: 'America/Chicago',
  },
  '39535': {
    zip: '39535',
    city: 'Biloxi',
    state: 'MS',
    latitude: '30.415255',
    longitude: '-88.930352',
    timeZoneId: 'America/Chicago',
  },
  '39540': {
    zip: '39540',
    city: 'Diberville',
    state: 'MS',
    latitude: '30.459729',
    longitude: '-88.948864',
    timeZoneId: 'America/Chicago',
  },
  '39552': {
    zip: '39552',
    city: 'Escatawpa',
    state: 'MS',
    latitude: '30.450481',
    longitude: '-88.540961',
    timeZoneId: 'America/Chicago',
  },
  '39553': {
    zip: '39553',
    city: 'Gautier',
    state: 'MS',
    latitude: '30.400064',
    longitude: '-88.643006',
    timeZoneId: 'America/Chicago',
  },
  '39555': {
    zip: '39555',
    city: 'Hurley',
    state: 'MS',
    latitude: '30.674763',
    longitude: '-88.485871',
    timeZoneId: 'America/Chicago',
  },
  '39556': {
    zip: '39556',
    city: 'Kiln',
    state: 'MS',
    latitude: '30.413756',
    longitude: '-89.447969',
    timeZoneId: 'America/Chicago',
  },
  '39558': {
    zip: '39558',
    city: 'Lakeshore',
    state: 'MS',
    latitude: '30.24187',
    longitude: '-89.434728',
    timeZoneId: 'America/Chicago',
  },
  '39560': {
    zip: '39560',
    city: 'Long Beach',
    state: 'MS',
    latitude: '30.362985',
    longitude: '-89.166163',
    timeZoneId: 'America/Chicago',
  },
  '39561': {
    zip: '39561',
    city: 'Mc Henry',
    state: 'MS',
    latitude: '30.688163',
    longitude: '-89.180838',
    timeZoneId: 'America/Chicago',
  },
  '39562': {
    zip: '39562',
    city: 'Moss Point',
    state: 'MS',
    latitude: '30.425733',
    longitude: '-88.529215',
    timeZoneId: 'America/Chicago',
  },
  '39563': {
    zip: '39563',
    city: 'Moss Point',
    state: 'MS',
    latitude: '30.407564',
    longitude: '-88.530167',
    timeZoneId: 'America/Chicago',
  },
  '39564': {
    zip: '39564',
    city: 'Ocean Springs',
    state: 'MS',
    latitude: '30.62467',
    longitude: '-88.84789',
    timeZoneId: 'America/Chicago',
  },
  '39565': {
    zip: '39565',
    city: 'Vancleave',
    state: 'MS',
    latitude: '30.568555',
    longitude: '-88.740289',
    timeZoneId: 'America/Chicago',
  },
  '39566': {
    zip: '39566',
    city: 'Ocean Springs',
    state: 'MS',
    latitude: '30.414895',
    longitude: '-88.823565',
    timeZoneId: 'America/Chicago',
  },
  '39567': {
    zip: '39567',
    city: 'Pascagoula',
    state: 'MS',
    latitude: '30.44766',
    longitude: '-88.52088',
    timeZoneId: 'America/Chicago',
  },
  '39568': {
    zip: '39568',
    city: 'Pascagoula',
    state: 'MS',
    latitude: '30.369518',
    longitude: '-88.523942',
    timeZoneId: 'America/Chicago',
  },
  '39569': {
    zip: '39569',
    city: 'Pascagoula',
    state: 'MS',
    latitude: '30.369087',
    longitude: '-88.558091',
    timeZoneId: 'America/Chicago',
  },
  '39571': {
    zip: '39571',
    city: 'Pass Christian',
    state: 'MS',
    latitude: '30.367611',
    longitude: '-89.262586',
    timeZoneId: 'America/Chicago',
  },
  '39572': {
    zip: '39572',
    city: 'Pearlington',
    state: 'MS',
    latitude: '30.242297',
    longitude: '-89.605365',
    timeZoneId: 'America/Chicago',
  },
  '39573': {
    zip: '39573',
    city: 'Perkinston',
    state: 'MS',
    latitude: '30.764527',
    longitude: '-89.081583',
    timeZoneId: 'America/Chicago',
  },
  '39574': {
    zip: '39574',
    city: 'Saucier',
    state: 'MS',
    latitude: '30.60072',
    longitude: '-89.11224',
    timeZoneId: 'America/Chicago',
  },
  '39576': {
    zip: '39576',
    city: 'Waveland',
    state: 'MS',
    latitude: '30.28953',
    longitude: '-89.379444',
    timeZoneId: 'America/Chicago',
  },
  '39577': {
    zip: '39577',
    city: 'Wiggins',
    state: 'MS',
    latitude: '30.855908',
    longitude: '-89.135956',
    timeZoneId: 'America/Chicago',
  },
  '39581': {
    zip: '39581',
    city: 'Pascagoula',
    state: 'MS',
    latitude: '30.491609',
    longitude: '-88.505551',
    timeZoneId: 'America/Chicago',
  },
  '39595': {
    zip: '39595',
    city: 'Pascagoula',
    state: 'MS',
    latitude: '30.368782',
    longitude: '-88.549063',
    timeZoneId: 'America/Chicago',
  },
  '39601': {
    zip: '39601',
    city: 'Brookhaven',
    state: 'MS',
    latitude: '31.58418',
    longitude: '-90.40662',
    timeZoneId: 'America/Chicago',
  },
  '39602': {
    zip: '39602',
    city: 'Brookhaven',
    state: 'MS',
    latitude: '31.593072',
    longitude: '-90.469962',
    timeZoneId: 'America/Chicago',
  },
  '39603': {
    zip: '39603',
    city: 'Brookhaven',
    state: 'MS',
    latitude: '31.577695',
    longitude: '-90.477599',
    timeZoneId: 'America/Chicago',
  },
  '39629': {
    zip: '39629',
    city: 'Bogue Chitto',
    state: 'MS',
    latitude: '31.48512',
    longitude: '-90.45294',
    timeZoneId: 'America/Chicago',
  },
  '39630': {
    zip: '39630',
    city: 'Bude',
    state: 'MS',
    latitude: '31.463101',
    longitude: '-90.850212',
    timeZoneId: 'America/Chicago',
  },
  '39631': {
    zip: '39631',
    city: 'Centreville',
    state: 'MS',
    latitude: '31.088728',
    longitude: '-91.067021',
    timeZoneId: 'America/Chicago',
  },
  '39632': {
    zip: '39632',
    city: 'Chatawa',
    state: 'MS',
    latitude: '31.169789',
    longitude: '-90.473852',
    timeZoneId: 'America/Chicago',
  },
  '39633': {
    zip: '39633',
    city: 'Crosby',
    state: 'MS',
    latitude: '31.312918',
    longitude: '-90.982432',
    timeZoneId: 'America/Chicago',
  },
  '39635': {
    zip: '39635',
    city: 'Fernwood',
    state: 'MS',
    latitude: '31.183043',
    longitude: '-90.447396',
    timeZoneId: 'America/Chicago',
  },
  '39638': {
    zip: '39638',
    city: 'Gloster',
    state: 'MS',
    latitude: '31.24753',
    longitude: '-91.03045',
    timeZoneId: 'America/Chicago',
  },
  '39641': {
    zip: '39641',
    city: 'Jayess',
    state: 'MS',
    latitude: '31.410049',
    longitude: '-90.187496',
    timeZoneId: 'America/Chicago',
  },
  '39643': {
    zip: '39643',
    city: 'Kokomo',
    state: 'MS',
    latitude: '31.173021',
    longitude: '-89.986969',
    timeZoneId: 'America/Chicago',
  },
  '39645': {
    zip: '39645',
    city: 'Liberty',
    state: 'MS',
    latitude: '31.158025',
    longitude: '-90.804511',
    timeZoneId: 'America/Chicago',
  },
  '39647': {
    zip: '39647',
    city: 'Mc Call Creek',
    state: 'MS',
    latitude: '31.51092',
    longitude: '-90.77292',
    timeZoneId: 'America/Chicago',
  },
  '39648': {
    zip: '39648',
    city: 'McComb',
    state: 'MS',
    latitude: '31.16238',
    longitude: '-90.38442',
    timeZoneId: 'America/Chicago',
  },
  '39649': {
    zip: '39649',
    city: 'McComb',
    state: 'MS',
    latitude: '31.243738',
    longitude: '-90.455916',
    timeZoneId: 'America/Chicago',
  },
  '39652': {
    zip: '39652',
    city: 'Magnolia',
    state: 'MS',
    latitude: '31.14464',
    longitude: '-90.458735',
    timeZoneId: 'America/Chicago',
  },
  '39653': {
    zip: '39653',
    city: 'Meadville',
    state: 'MS',
    latitude: '31.4556',
    longitude: '-90.87966',
    timeZoneId: 'America/Chicago',
  },
  '39654': {
    zip: '39654',
    city: 'Monticello',
    state: 'MS',
    latitude: '31.506',
    longitude: '-90.11808',
    timeZoneId: 'America/Chicago',
  },
  '39656': {
    zip: '39656',
    city: 'Oak Vale',
    state: 'MS',
    latitude: '31.439273',
    longitude: '-90.000185',
    timeZoneId: 'America/Chicago',
  },
  '39657': {
    zip: '39657',
    city: 'Osyka',
    state: 'MS',
    latitude: '31.02024',
    longitude: '-90.46392',
    timeZoneId: 'America/Chicago',
  },
  '39661': {
    zip: '39661',
    city: 'Roxie',
    state: 'MS',
    latitude: '31.42896',
    longitude: '-91.11636',
    timeZoneId: 'America/Chicago',
  },
  '39662': {
    zip: '39662',
    city: 'Ruth',
    state: 'MS',
    latitude: '31.381546',
    longitude: '-90.292446',
    timeZoneId: 'America/Chicago',
  },
  '39663': {
    zip: '39663',
    city: 'Silver Creek',
    state: 'MS',
    latitude: '31.5771',
    longitude: '-90.0228',
    timeZoneId: 'America/Chicago',
  },
  '39664': {
    zip: '39664',
    city: 'Smithdale',
    state: 'MS',
    latitude: '31.3563',
    longitude: '-90.63606',
    timeZoneId: 'America/Chicago',
  },
  '39665': {
    zip: '39665',
    city: 'Sontag',
    state: 'MS',
    latitude: '31.614167',
    longitude: '-90.238056',
    timeZoneId: 'America/Chicago',
  },
  '39666': {
    zip: '39666',
    city: 'Summit',
    state: 'MS',
    latitude: '31.30902',
    longitude: '-90.4218',
    timeZoneId: 'America/Chicago',
  },
  '39667': {
    zip: '39667',
    city: 'Tylertown',
    state: 'MS',
    latitude: '31.16304',
    longitude: '-90.13362',
    timeZoneId: 'America/Chicago',
  },
  '39668': {
    zip: '39668',
    city: 'Union Church',
    state: 'MS',
    latitude: '31.709377',
    longitude: '-90.826145',
    timeZoneId: 'America/Chicago',
  },
  '39669': {
    zip: '39669',
    city: 'Woodville',
    state: 'MS',
    latitude: '31.17576',
    longitude: '-91.29774',
    timeZoneId: 'America/Chicago',
  },
  '39701': {
    zip: '39701',
    city: 'Columbus',
    state: 'MS',
    latitude: '33.53766',
    longitude: '-88.46238',
    timeZoneId: 'America/Chicago',
  },
  '39702': {
    zip: '39702',
    city: 'Columbus',
    state: 'MS',
    latitude: '33.438653',
    longitude: '-88.346866',
    timeZoneId: 'America/Chicago',
  },
  '39703': {
    zip: '39703',
    city: 'Columbus',
    state: 'MS',
    latitude: '33.516152',
    longitude: '-88.483499',
    timeZoneId: 'America/Chicago',
  },
  '39704': {
    zip: '39704',
    city: 'Columbus',
    state: 'MS',
    latitude: '33.517443',
    longitude: '-88.479933',
    timeZoneId: 'America/Chicago',
  },
  '39705': {
    zip: '39705',
    city: 'Columbus',
    state: 'MS',
    latitude: '33.505932',
    longitude: '-88.40362',
    timeZoneId: 'America/Chicago',
  },
  '39710': {
    zip: '39710',
    city: 'Columbus',
    state: 'MS',
    latitude: '33.636366',
    longitude: '-88.455067',
    timeZoneId: 'America/Chicago',
  },
  '39730': {
    zip: '39730',
    city: 'Aberdeen',
    state: 'MS',
    latitude: '33.84',
    longitude: '-88.53012',
    timeZoneId: 'America/Chicago',
  },
  '39735': {
    zip: '39735',
    city: 'Ackerman',
    state: 'MS',
    latitude: '33.33606',
    longitude: '-89.18694',
    timeZoneId: 'America/Chicago',
  },
  '39736': {
    zip: '39736',
    city: 'Artesia',
    state: 'MS',
    latitude: '33.409816',
    longitude: '-88.642641',
    timeZoneId: 'America/Chicago',
  },
  '39737': {
    zip: '39737',
    city: 'Bellefontaine',
    state: 'MS',
    latitude: '33.609442',
    longitude: '-89.366939',
    timeZoneId: 'America/Chicago',
  },
  '39739': {
    zip: '39739',
    city: 'Brooksville',
    state: 'MS',
    latitude: '33.234567',
    longitude: '-88.580798',
    timeZoneId: 'America/Chicago',
  },
  '39740': {
    zip: '39740',
    city: 'Caledonia',
    state: 'MS',
    latitude: '33.688435',
    longitude: '-88.329881',
    timeZoneId: 'America/Chicago',
  },
  '39741': {
    zip: '39741',
    city: 'Cedarbluff',
    state: 'MS',
    latitude: '33.613034',
    longitude: '-88.845449',
    timeZoneId: 'America/Chicago',
  },
  '39743': {
    zip: '39743',
    city: 'Crawford',
    state: 'MS',
    latitude: '33.341575',
    longitude: '-88.51405',
    timeZoneId: 'America/Chicago',
  },
  '39744': {
    zip: '39744',
    city: 'Eupora',
    state: 'MS',
    latitude: '33.55584',
    longitude: '-89.29434',
    timeZoneId: 'America/Chicago',
  },
  '39745': {
    zip: '39745',
    city: 'French Camp',
    state: 'MS',
    latitude: '33.290348',
    longitude: '-89.40196',
    timeZoneId: 'America/Chicago',
  },
  '39746': {
    zip: '39746',
    city: 'Hamilton',
    state: 'MS',
    latitude: '33.799577',
    longitude: '-88.429985',
    timeZoneId: 'America/Chicago',
  },
  '39747': {
    zip: '39747',
    city: 'Kilmichael',
    state: 'MS',
    latitude: '33.444845',
    longitude: '-89.567673',
    timeZoneId: 'America/Chicago',
  },
  '39750': {
    zip: '39750',
    city: 'Maben',
    state: 'MS',
    latitude: '33.50964',
    longitude: '-89.06688',
    timeZoneId: 'America/Chicago',
  },
  '39751': {
    zip: '39751',
    city: 'Mantee',
    state: 'MS',
    latitude: '33.733333',
    longitude: '-89.066667',
    timeZoneId: 'America/Chicago',
  },
  '39752': {
    zip: '39752',
    city: 'Mathiston',
    state: 'MS',
    latitude: '33.539394',
    longitude: '-89.135634',
    timeZoneId: 'America/Chicago',
  },
  '39753': {
    zip: '39753',
    city: 'Mayhew',
    state: 'MS',
    latitude: '33.44813',
    longitude: '-88.481457',
    timeZoneId: 'America/Chicago',
  },
  '39754': {
    zip: '39754',
    city: 'Montpelier',
    state: 'MS',
    latitude: '33.715811',
    longitude: '-88.94825',
    timeZoneId: 'America/Chicago',
  },
  '39755': {
    zip: '39755',
    city: 'Pheba',
    state: 'MS',
    latitude: '33.591536',
    longitude: '-88.953093',
    timeZoneId: 'America/Chicago',
  },
  '39756': {
    zip: '39756',
    city: 'Prairie',
    state: 'MS',
    latitude: '33.807566',
    longitude: '-88.636356',
    timeZoneId: 'America/Chicago',
  },
  '39759': {
    zip: '39759',
    city: 'Starkville',
    state: 'MS',
    latitude: '33.43452',
    longitude: '-88.83534',
    timeZoneId: 'America/Chicago',
  },
  '39760': {
    zip: '39760',
    city: 'Starkville',
    state: 'MS',
    latitude: '33.449945',
    longitude: '-88.820003',
    timeZoneId: 'America/Chicago',
  },
  '39762': {
    zip: '39762',
    city: 'Mississippi State',
    state: 'MS',
    latitude: '33.457993',
    longitude: '-88.786062',
    timeZoneId: 'America/Chicago',
  },
  '39766': {
    zip: '39766',
    city: 'Steens',
    state: 'MS',
    latitude: '33.577565',
    longitude: '-88.354769',
    timeZoneId: 'America/Chicago',
  },
  '39767': {
    zip: '39767',
    city: 'Stewart',
    state: 'MS',
    latitude: '33.532113',
    longitude: '-89.496533',
    timeZoneId: 'America/Chicago',
  },
  '39769': {
    zip: '39769',
    city: 'Sturgis',
    state: 'MS',
    latitude: '33.35772',
    longitude: '-89.05002',
    timeZoneId: 'America/Chicago',
  },
  '39771': {
    zip: '39771',
    city: 'Walthall',
    state: 'MS',
    latitude: '33.613941',
    longitude: '-89.366226',
    timeZoneId: 'America/Chicago',
  },
  '39772': {
    zip: '39772',
    city: 'Weir',
    state: 'MS',
    latitude: '33.2661',
    longitude: '-89.28252',
    timeZoneId: 'America/Chicago',
  },
  '39773': {
    zip: '39773',
    city: 'West Point',
    state: 'MS',
    latitude: '33.62958',
    longitude: '-88.66428',
    timeZoneId: 'America/Chicago',
  },
  '39776': {
    zip: '39776',
    city: 'Woodland',
    state: 'MS',
    latitude: '33.794434',
    longitude: '-89.053443',
    timeZoneId: 'America/Chicago',
  },
  '39813': {
    zip: '39813',
    city: 'Arlington',
    state: 'GA',
    latitude: '31.443443',
    longitude: '-84.723823',
    timeZoneId: 'America/New_York',
  },
  '39815': {
    zip: '39815',
    city: 'Attapulgus',
    state: 'GA',
    latitude: '30.818988',
    longitude: '-84.519116',
    timeZoneId: 'America/New_York',
  },
  '39817': {
    zip: '39817',
    city: 'Bainbridge',
    state: 'GA',
    latitude: '30.905055',
    longitude: '-84.568611',
    timeZoneId: 'America/New_York',
  },
  '39818': {
    zip: '39818',
    city: 'Bainbridge',
    state: 'GA',
    latitude: '30.90889',
    longitude: '-84.578618',
    timeZoneId: 'America/New_York',
  },
  '39819': {
    zip: '39819',
    city: 'Bainbridge',
    state: 'GA',
    latitude: '30.901653',
    longitude: '-84.567519',
    timeZoneId: 'America/New_York',
  },
  '39823': {
    zip: '39823',
    city: 'Blakely',
    state: 'GA',
    latitude: '31.376097',
    longitude: '-84.934063',
    timeZoneId: 'America/New_York',
  },
  '39824': {
    zip: '39824',
    city: 'Bluffton',
    state: 'GA',
    latitude: '31.552394',
    longitude: '-84.899796',
    timeZoneId: 'America/New_York',
  },
  '39825': {
    zip: '39825',
    city: 'Brinson',
    state: 'GA',
    latitude: '30.942154',
    longitude: '-84.676803',
    timeZoneId: 'America/New_York',
  },
  '39826': {
    zip: '39826',
    city: 'Bronwood',
    state: 'GA',
    latitude: '31.82852',
    longitude: '-84.359918',
    timeZoneId: 'America/New_York',
  },
  '39827': {
    zip: '39827',
    city: 'Cairo',
    state: 'GA',
    latitude: '30.876043',
    longitude: '-84.206528',
    timeZoneId: 'America/New_York',
  },
  '39828': {
    zip: '39828',
    city: 'Cairo',
    state: 'GA',
    latitude: '30.874451',
    longitude: '-84.20936',
    timeZoneId: 'America/New_York',
  },
  '39829': {
    zip: '39829',
    city: 'Calvary',
    state: 'GA',
    latitude: '30.749193',
    longitude: '-84.310922',
    timeZoneId: 'America/New_York',
  },
  '39832': {
    zip: '39832',
    city: 'Cedar Springs',
    state: 'GA',
    latitude: '31.184745',
    longitude: '-85.004825',
    timeZoneId: 'America/New_York',
  },
  '39834': {
    zip: '39834',
    city: 'Climax',
    state: 'GA',
    latitude: '30.876579',
    longitude: '-84.441863',
    timeZoneId: 'America/New_York',
  },
  '39836': {
    zip: '39836',
    city: 'Coleman',
    state: 'GA',
    latitude: '31.654867',
    longitude: '-84.876604',
    timeZoneId: 'America/New_York',
  },
  '39837': {
    zip: '39837',
    city: 'Colquitt',
    state: 'GA',
    latitude: '31.174262',
    longitude: '-84.730167',
    timeZoneId: 'America/New_York',
  },
  '39840': {
    zip: '39840',
    city: 'Cuthbert',
    state: 'GA',
    latitude: '31.772884',
    longitude: '-84.791311',
    timeZoneId: 'America/New_York',
  },
  '39841': {
    zip: '39841',
    city: 'Damascus',
    state: 'GA',
    latitude: '31.347599',
    longitude: '-84.731813',
    timeZoneId: 'America/New_York',
  },
  '39842': {
    zip: '39842',
    city: 'Dawson',
    state: 'GA',
    latitude: '31.768132',
    longitude: '-84.431214',
    timeZoneId: 'America/New_York',
  },
  '39845': {
    zip: '39845',
    city: 'Donalsonville',
    state: 'GA',
    latitude: '31.041858',
    longitude: '-84.881232',
    timeZoneId: 'America/New_York',
  },
  '39846': {
    zip: '39846',
    city: 'Edison',
    state: 'GA',
    latitude: '31.561815',
    longitude: '-84.738841',
    timeZoneId: 'America/New_York',
  },
  '39851': {
    zip: '39851',
    city: 'Fort Gaines',
    state: 'GA',
    latitude: '31.605392',
    longitude: '-85.046905',
    timeZoneId: 'America/New_York',
  },
  '39852': {
    zip: '39852',
    city: 'Fowlstown',
    state: 'GA',
    latitude: '30.877745',
    longitude: '-84.584575',
    timeZoneId: 'America/New_York',
  },
  '39854': {
    zip: '39854',
    city: 'Georgetown',
    state: 'GA',
    latitude: '31.874042',
    longitude: '-85.104636',
    timeZoneId: 'America/New_York',
  },
  '39859': {
    zip: '39859',
    city: 'Iron City',
    state: 'GA',
    latitude: '30.984497',
    longitude: '-84.786533',
    timeZoneId: 'America/New_York',
  },
  '39861': {
    zip: '39861',
    city: 'Jakin',
    state: 'GA',
    latitude: '31.184086',
    longitude: '-85.006655',
    timeZoneId: 'America/New_York',
  },
  '39862': {
    zip: '39862',
    city: 'Leary',
    state: 'GA',
    latitude: '31.524345',
    longitude: '-84.506321',
    timeZoneId: 'America/New_York',
  },
  '39866': {
    zip: '39866',
    city: 'Morgan',
    state: 'GA',
    latitude: '31.531835',
    longitude: '-84.623875',
    timeZoneId: 'America/New_York',
  },
  '39867': {
    zip: '39867',
    city: 'Morris',
    state: 'GA',
    latitude: '31.818931',
    longitude: '-85.035602',
    timeZoneId: 'America/New_York',
  },
  '39870': {
    zip: '39870',
    city: 'Newton',
    state: 'GA',
    latitude: '31.321697',
    longitude: '-84.458675',
    timeZoneId: 'America/New_York',
  },
  '39877': {
    zip: '39877',
    city: 'Parrott',
    state: 'GA',
    latitude: '31.894364',
    longitude: '-84.507084',
    timeZoneId: 'America/New_York',
  },
  '39885': {
    zip: '39885',
    city: 'Sasser',
    state: 'GA',
    latitude: '31.713415',
    longitude: '-84.349784',
    timeZoneId: 'America/New_York',
  },
  '39886': {
    zip: '39886',
    city: 'Shellman',
    state: 'GA',
    latitude: '31.761276',
    longitude: '-84.606731',
    timeZoneId: 'America/New_York',
  },
  '39897': {
    zip: '39897',
    city: 'Whigham',
    state: 'GA',
    latitude: '30.936474',
    longitude: '-84.31452',
    timeZoneId: 'America/New_York',
  },
  '39901': {
    zip: '39901',
    city: 'Atlanta',
    state: 'GA',
    latitude: '33.890737',
    longitude: '-84.28763',
    timeZoneId: 'America/New_York',
  },
  '40003': {
    zip: '40003',
    city: 'Bagdad',
    state: 'KY',
    latitude: '38.283992',
    longitude: '-84.974789',
    timeZoneId: 'America/New_York',
  },
  '40004': {
    zip: '40004',
    city: 'Bardstown',
    state: 'KY',
    latitude: '37.814398',
    longitude: '-85.466471',
    timeZoneId: 'America/New_York',
  },
  '40006': {
    zip: '40006',
    city: 'Bedford',
    state: 'KY',
    latitude: '38.582109',
    longitude: '-85.31424',
    timeZoneId: 'America/New_York',
  },
  '40007': {
    zip: '40007',
    city: 'Bethlehem',
    state: 'KY',
    latitude: '38.401801',
    longitude: '-85.062016',
    timeZoneId: 'America/New_York',
  },
  '40008': {
    zip: '40008',
    city: 'Bloomfield',
    state: 'KY',
    latitude: '37.906036',
    longitude: '-85.288243',
    timeZoneId: 'America/New_York',
  },
  '40009': {
    zip: '40009',
    city: 'Bradfordsville',
    state: 'KY',
    latitude: '37.421874',
    longitude: '-85.166763',
    timeZoneId: 'America/New_York',
  },
  '40010': {
    zip: '40010',
    city: 'Buckner',
    state: 'KY',
    latitude: '38.383365',
    longitude: '-85.450819',
    timeZoneId: 'America/New_York',
  },
  '40011': {
    zip: '40011',
    city: 'Campbellsburg',
    state: 'KY',
    latitude: '38.484347',
    longitude: '-85.116998',
    timeZoneId: 'America/New_York',
  },
  '40012': {
    zip: '40012',
    city: 'Chaplin',
    state: 'KY',
    latitude: '37.899244',
    longitude: '-85.208252',
    timeZoneId: 'America/New_York',
  },
  '40013': {
    zip: '40013',
    city: 'Coxs Creek',
    state: 'KY',
    latitude: '37.917352',
    longitude: '-85.501392',
    timeZoneId: 'America/New_York',
  },
  '40014': {
    zip: '40014',
    city: 'Crestwood',
    state: 'KY',
    latitude: '38.33799',
    longitude: '-85.443424',
    timeZoneId: 'America/New_York',
  },
  '40018': {
    zip: '40018',
    city: 'Eastwood',
    state: 'KY',
    latitude: '38.273714',
    longitude: '-85.413748',
    timeZoneId: 'America/New_York',
  },
  '40019': {
    zip: '40019',
    city: 'Eminence',
    state: 'KY',
    latitude: '38.380212',
    longitude: '-85.182994',
    timeZoneId: 'America/New_York',
  },
  '40020': {
    zip: '40020',
    city: 'Fairfield',
    state: 'KY',
    latitude: '37.930175',
    longitude: '-85.378486',
    timeZoneId: 'America/New_York',
  },
  '40022': {
    zip: '40022',
    city: 'Finchville',
    state: 'KY',
    latitude: '38.155235',
    longitude: '-85.300049',
    timeZoneId: 'America/New_York',
  },
  '40023': {
    zip: '40023',
    city: 'Fisherville',
    state: 'KY',
    latitude: '38.188191',
    longitude: '-85.441224',
    timeZoneId: 'America/New_York',
  },
  '40025': {
    zip: '40025',
    city: 'Glenview',
    state: 'KY',
    latitude: '38.306833',
    longitude: '-85.645273',
    timeZoneId: 'America/New_York',
  },
  '40026': {
    zip: '40026',
    city: 'Goshen',
    state: 'KY',
    latitude: '38.415028',
    longitude: '-85.56299',
    timeZoneId: 'America/New_York',
  },
  '40027': {
    zip: '40027',
    city: 'Harrods Creek',
    state: 'KY',
    latitude: '38.316932',
    longitude: '-85.631873',
    timeZoneId: 'America/New_York',
  },
  '40031': {
    zip: '40031',
    city: 'La Grange',
    state: 'KY',
    latitude: '38.406748',
    longitude: '-85.388667',
    timeZoneId: 'America/New_York',
  },
  '40032': {
    zip: '40032',
    city: 'La Grange',
    state: 'KY',
    latitude: '38.42276',
    longitude: '-85.393054',
    timeZoneId: 'America/New_York',
  },
  '40033': {
    zip: '40033',
    city: 'Lebanon',
    state: 'KY',
    latitude: '37.568206',
    longitude: '-85.25068',
    timeZoneId: 'America/New_York',
  },
  '40036': {
    zip: '40036',
    city: 'Lockport',
    state: 'KY',
    latitude: '38.417408',
    longitude: '-84.961804',
    timeZoneId: 'America/New_York',
  },
  '40037': {
    zip: '40037',
    city: 'Loretto',
    state: 'KY',
    latitude: '37.690697',
    longitude: '-85.454674',
    timeZoneId: 'America/New_York',
  },
  '40040': {
    zip: '40040',
    city: 'Mackville',
    state: 'KY',
    latitude: '37.720885',
    longitude: '-85.087388',
    timeZoneId: 'America/New_York',
  },
  '40041': {
    zip: '40041',
    city: 'Masonic Home',
    state: 'KY',
    latitude: '38.252308',
    longitude: '-85.665637',
    timeZoneId: 'America/New_York',
  },
  '40045': {
    zip: '40045',
    city: 'Milton',
    state: 'KY',
    latitude: '38.69028',
    longitude: '-85.37118',
    timeZoneId: 'America/New_York',
  },
  '40046': {
    zip: '40046',
    city: 'Mount Eden',
    state: 'KY',
    latitude: '38.021586',
    longitude: '-85.135305',
    timeZoneId: 'America/New_York',
  },
  '40047': {
    zip: '40047',
    city: 'Mount Washington',
    state: 'KY',
    latitude: '38.04417',
    longitude: '-85.555572',
    timeZoneId: 'America/New_York',
  },
  '40048': {
    zip: '40048',
    city: 'Nazareth',
    state: 'KY',
    latitude: '37.847071',
    longitude: '-85.473376',
    timeZoneId: 'America/New_York',
  },
  '40049': {
    zip: '40049',
    city: 'Nerinx',
    state: 'KY',
    latitude: '37.661331',
    longitude: '-85.397992',
    timeZoneId: 'America/New_York',
  },
  '40050': {
    zip: '40050',
    city: 'New Castle',
    state: 'KY',
    latitude: '38.436132',
    longitude: '-85.171917',
    timeZoneId: 'America/New_York',
  },
  '40051': {
    zip: '40051',
    city: 'New Haven',
    state: 'KY',
    latitude: '37.671684',
    longitude: '-85.546062',
    timeZoneId: 'America/New_York',
  },
  '40052': {
    zip: '40052',
    city: 'New Hope',
    state: 'KY',
    latitude: '37.611523',
    longitude: '-85.515059',
    timeZoneId: 'America/New_York',
  },
  '40055': {
    zip: '40055',
    city: 'Pendleton',
    state: 'KY',
    latitude: '38.49504',
    longitude: '-85.339571',
    timeZoneId: 'America/New_York',
  },
  '40056': {
    zip: '40056',
    city: 'Pewee Valley',
    state: 'KY',
    latitude: '38.304452',
    longitude: '-85.4835',
    timeZoneId: 'America/New_York',
  },
  '40057': {
    zip: '40057',
    city: 'Pleasureville',
    state: 'KY',
    latitude: '38.369196',
    longitude: '-85.063919',
    timeZoneId: 'America/New_York',
  },
  '40058': {
    zip: '40058',
    city: 'Port Royal',
    state: 'KY',
    latitude: '38.557783',
    longitude: '-85.078665',
    timeZoneId: 'America/New_York',
  },
  '40059': {
    zip: '40059',
    city: 'Prospect',
    state: 'KY',
    latitude: '38.338312',
    longitude: '-85.613141',
    timeZoneId: 'America/New_York',
  },
  '40060': {
    zip: '40060',
    city: 'Raywick',
    state: 'KY',
    latitude: '37.529118',
    longitude: '-85.439647',
    timeZoneId: 'America/New_York',
  },
  '40061': {
    zip: '40061',
    city: 'Saint Catharine',
    state: 'KY',
    latitude: '37.695114',
    longitude: '-85.264452',
    timeZoneId: 'America/New_York',
  },
  '40062': {
    zip: '40062',
    city: 'Saint Francis',
    state: 'KY',
    latitude: '37.626449',
    longitude: '-85.465732',
    timeZoneId: 'America/New_York',
  },
  '40063': {
    zip: '40063',
    city: 'Saint Mary',
    state: 'KY',
    latitude: '37.579147',
    longitude: '-85.342897',
    timeZoneId: 'America/New_York',
  },
  '40065': {
    zip: '40065',
    city: 'Shelbyville',
    state: 'KY',
    latitude: '38.209503',
    longitude: '-85.229057',
    timeZoneId: 'America/New_York',
  },
  '40066': {
    zip: '40066',
    city: 'Shelbyville',
    state: 'KY',
    latitude: '38.211321',
    longitude: '-85.225568',
    timeZoneId: 'America/New_York',
  },
  '40067': {
    zip: '40067',
    city: 'Simpsonville',
    state: 'KY',
    latitude: '38.235127',
    longitude: '-85.352938',
    timeZoneId: 'America/New_York',
  },
  '40068': {
    zip: '40068',
    city: 'Smithfield',
    state: 'KY',
    latitude: '38.357433',
    longitude: '-85.320685',
    timeZoneId: 'America/New_York',
  },
  '40069': {
    zip: '40069',
    city: 'Springfield',
    state: 'KY',
    latitude: '37.71336',
    longitude: '-85.2018',
    timeZoneId: 'America/New_York',
  },
  '40070': {
    zip: '40070',
    city: 'Sulphur',
    state: 'KY',
    latitude: '38.488992',
    longitude: '-85.232317',
    timeZoneId: 'America/New_York',
  },
  '40071': {
    zip: '40071',
    city: 'Taylorsville',
    state: 'KY',
    latitude: '38.050122',
    longitude: '-85.371767',
    timeZoneId: 'America/New_York',
  },
  '40075': {
    zip: '40075',
    city: 'Turners Station',
    state: 'KY',
    latitude: '38.556216',
    longitude: '-85.126679',
    timeZoneId: 'America/New_York',
  },
  '40076': {
    zip: '40076',
    city: 'Waddy',
    state: 'KY',
    latitude: '38.149878',
    longitude: '-85.06148',
    timeZoneId: 'America/New_York',
  },
  '40077': {
    zip: '40077',
    city: 'Westport',
    state: 'KY',
    latitude: '38.485186',
    longitude: '-85.451519',
    timeZoneId: 'America/New_York',
  },
  '40078': {
    zip: '40078',
    city: 'Willisburg',
    state: 'KY',
    latitude: '37.895371',
    longitude: '-85.106075',
    timeZoneId: 'America/New_York',
  },
  '40104': {
    zip: '40104',
    city: 'Battletown',
    state: 'KY',
    latitude: '38.067',
    longitude: '-86.323794',
    timeZoneId: 'America/New_York',
  },
  '40107': {
    zip: '40107',
    city: 'Boston',
    state: 'KY',
    latitude: '37.753515',
    longitude: '-85.637791',
    timeZoneId: 'America/New_York',
  },
  '40108': {
    zip: '40108',
    city: 'Brandenburg',
    state: 'KY',
    latitude: '37.982152',
    longitude: '-86.130383',
    timeZoneId: 'America/New_York',
  },
  '40109': {
    zip: '40109',
    city: 'Brooks',
    state: 'KY',
    latitude: '38.056143',
    longitude: '-85.7334',
    timeZoneId: 'America/New_York',
  },
  '40110': {
    zip: '40110',
    city: 'Clermont',
    state: 'KY',
    latitude: '37.930194',
    longitude: '-85.655556',
    timeZoneId: 'America/New_York',
  },
  '40111': {
    zip: '40111',
    city: 'Cloverport',
    state: 'KY',
    latitude: '37.832364',
    longitude: '-86.631',
    timeZoneId: 'America/Chicago',
  },
  '40115': {
    zip: '40115',
    city: 'Custer',
    state: 'KY',
    latitude: '37.753035',
    longitude: '-86.249373',
    timeZoneId: 'America/Chicago',
  },
  '40117': {
    zip: '40117',
    city: 'Ekron',
    state: 'KY',
    latitude: '37.906255',
    longitude: '-86.150031',
    timeZoneId: 'America/New_York',
  },
  '40118': {
    zip: '40118',
    city: 'Fairdale',
    state: 'KY',
    latitude: '38.110096',
    longitude: '-85.755717',
    timeZoneId: 'America/New_York',
  },
  '40119': {
    zip: '40119',
    city: 'Falls of Rough',
    state: 'KY',
    latitude: '37.58796',
    longitude: '-86.4936',
    timeZoneId: 'America/Chicago',
  },
  '40121': {
    zip: '40121',
    city: 'Fort Knox',
    state: 'KY',
    latitude: '37.926575',
    longitude: '-85.912788',
    timeZoneId: 'America/New_York',
  },
  '40122': {
    zip: '40122',
    city: 'Fort Knox',
    state: 'KY',
    latitude: '37.8811',
    longitude: '-85.962689',
    timeZoneId: 'America/New_York',
  },
  '40129': {
    zip: '40129',
    city: 'Hillview',
    state: 'KY',
    latitude: '38.073955',
    longitude: '-85.665026',
    timeZoneId: 'America/New_York',
  },
  '40140': {
    zip: '40140',
    city: 'Garfield',
    state: 'KY',
    latitude: '37.814276',
    longitude: '-86.321436',
    timeZoneId: 'America/Chicago',
  },
  '40142': {
    zip: '40142',
    city: 'Guston',
    state: 'KY',
    latitude: '37.895924',
    longitude: '-86.163022',
    timeZoneId: 'America/New_York',
  },
  '40143': {
    zip: '40143',
    city: 'Hardinsburg',
    state: 'KY',
    latitude: '37.77918',
    longitude: '-86.45898',
    timeZoneId: 'America/Chicago',
  },
  '40144': {
    zip: '40144',
    city: 'Harned',
    state: 'KY',
    latitude: '37.75062',
    longitude: '-86.37108',
    timeZoneId: 'America/Chicago',
  },
  '40145': {
    zip: '40145',
    city: 'Hudson',
    state: 'KY',
    latitude: '37.606684',
    longitude: '-86.318269',
    timeZoneId: 'America/Chicago',
  },
  '40146': {
    zip: '40146',
    city: 'Irvington',
    state: 'KY',
    latitude: '37.8741',
    longitude: '-86.29554',
    timeZoneId: 'America/Chicago',
  },
  '40150': {
    zip: '40150',
    city: 'Lebanon Junction',
    state: 'KY',
    latitude: '37.853195',
    longitude: '-85.729211',
    timeZoneId: 'America/New_York',
  },
  '40152': {
    zip: '40152',
    city: 'Mc Daniels',
    state: 'KY',
    latitude: '37.626288',
    longitude: '-86.447899',
    timeZoneId: 'America/Chicago',
  },
  '40153': {
    zip: '40153',
    city: 'Mc Quady',
    state: 'KY',
    latitude: '37.707933',
    longitude: '-86.521171',
    timeZoneId: 'America/Chicago',
  },
  '40155': {
    zip: '40155',
    city: 'Muldraugh',
    state: 'KY',
    latitude: '37.934965',
    longitude: '-85.992791',
    timeZoneId: 'America/New_York',
  },
  '40157': {
    zip: '40157',
    city: 'Payneville',
    state: 'KY',
    latitude: '38.0255',
    longitude: '-86.400703',
    timeZoneId: 'America/New_York',
  },
  '40159': {
    zip: '40159',
    city: 'Radcliff',
    state: 'KY',
    latitude: '37.825258',
    longitude: '-85.941249',
    timeZoneId: 'America/New_York',
  },
  '40160': {
    zip: '40160',
    city: 'Radcliff',
    state: 'KY',
    latitude: '37.826645',
    longitude: '-85.94293',
    timeZoneId: 'America/New_York',
  },
  '40161': {
    zip: '40161',
    city: 'Rhodelia',
    state: 'KY',
    latitude: '37.96457',
    longitude: '-86.315361',
    timeZoneId: 'America/New_York',
  },
  '40162': {
    zip: '40162',
    city: 'Rineyville',
    state: 'KY',
    latitude: '37.751318',
    longitude: '-86.005252',
    timeZoneId: 'America/New_York',
  },
  '40165': {
    zip: '40165',
    city: 'Shepherdsville',
    state: 'KY',
    latitude: '38.010762',
    longitude: '-85.697038',
    timeZoneId: 'America/New_York',
  },
  '40166': {
    zip: '40166',
    city: 'Shepherdsville',
    state: 'KY',
    latitude: '38.010762',
    longitude: '-85.697038',
    timeZoneId: 'America/New_York',
  },
  '40170': {
    zip: '40170',
    city: 'Stephensport',
    state: 'KY',
    latitude: '37.897704',
    longitude: '-86.534884',
    timeZoneId: 'America/Chicago',
  },
  '40171': {
    zip: '40171',
    city: 'Union Star',
    state: 'KY',
    latitude: '37.975286',
    longitude: '-86.463714',
    timeZoneId: 'America/Chicago',
  },
  '40175': {
    zip: '40175',
    city: 'Vine Grove',
    state: 'KY',
    latitude: '37.814263',
    longitude: '-85.99001',
    timeZoneId: 'America/New_York',
  },
  '40176': {
    zip: '40176',
    city: 'Webster',
    state: 'KY',
    latitude: '37.929066',
    longitude: '-86.394425',
    timeZoneId: 'America/Chicago',
  },
  '40177': {
    zip: '40177',
    city: 'West Point',
    state: 'KY',
    latitude: '37.993837',
    longitude: '-85.950334',
    timeZoneId: 'America/New_York',
  },
  '40178': {
    zip: '40178',
    city: 'Westview',
    state: 'KY',
    latitude: '37.629802',
    longitude: '-86.394256',
    timeZoneId: 'America/Chicago',
  },
  '40201': {
    zip: '40201',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.247047',
    longitude: '-85.776597',
    timeZoneId: 'America/New_York',
  },
  '40202': {
    zip: '40202',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.256394',
    longitude: '-85.750706',
    timeZoneId: 'America/New_York',
  },
  '40203': {
    zip: '40203',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.247961',
    longitude: '-85.764632',
    timeZoneId: 'America/New_York',
  },
  '40204': {
    zip: '40204',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.235927',
    longitude: '-85.724905',
    timeZoneId: 'America/New_York',
  },
  '40205': {
    zip: '40205',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.224111',
    longitude: '-85.683771',
    timeZoneId: 'America/New_York',
  },
  '40206': {
    zip: '40206',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.256596',
    longitude: '-85.701248',
    timeZoneId: 'America/New_York',
  },
  '40207': {
    zip: '40207',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.26278',
    longitude: '-85.66098',
    timeZoneId: 'America/New_York',
  },
  '40208': {
    zip: '40208',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.218692',
    longitude: '-85.766333',
    timeZoneId: 'America/New_York',
  },
  '40209': {
    zip: '40209',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.189812',
    longitude: '-85.754157',
    timeZoneId: 'America/New_York',
  },
  '40210': {
    zip: '40210',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.232274',
    longitude: '-85.787541',
    timeZoneId: 'America/New_York',
  },
  '40211': {
    zip: '40211',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.238179',
    longitude: '-85.809781',
    timeZoneId: 'America/New_York',
  },
  '40212': {
    zip: '40212',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.26198',
    longitude: '-85.800266',
    timeZoneId: 'America/New_York',
  },
  '40213': {
    zip: '40213',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.184398',
    longitude: '-85.716074',
    timeZoneId: 'America/New_York',
  },
  '40214': {
    zip: '40214',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.158525',
    longitude: '-85.786573',
    timeZoneId: 'America/New_York',
  },
  '40215': {
    zip: '40215',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.192575',
    longitude: '-85.78428',
    timeZoneId: 'America/New_York',
  },
  '40216': {
    zip: '40216',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.189129',
    longitude: '-85.835133',
    timeZoneId: 'America/New_York',
  },
  '40217': {
    zip: '40217',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.217903',
    longitude: '-85.741113',
    timeZoneId: 'America/New_York',
  },
  '40218': {
    zip: '40218',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.192865',
    longitude: '-85.653204',
    timeZoneId: 'America/New_York',
  },
  '40219': {
    zip: '40219',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.14326',
    longitude: '-85.69578',
    timeZoneId: 'America/New_York',
  },
  '40220': {
    zip: '40220',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.213254',
    longitude: '-85.621574',
    timeZoneId: 'America/New_York',
  },
  '40221': {
    zip: '40221',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.186043',
    longitude: '-85.751873',
    timeZoneId: 'America/New_York',
  },
  '40222': {
    zip: '40222',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.258163',
    longitude: '-85.606834',
    timeZoneId: 'America/New_York',
  },
  '40223': {
    zip: '40223',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.25594',
    longitude: '-85.51404',
    timeZoneId: 'America/New_York',
  },
  '40224': {
    zip: '40224',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.226983',
    longitude: '-85.574524',
    timeZoneId: 'America/New_York',
  },
  '40225': {
    zip: '40225',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.188651',
    longitude: '-85.651479',
    timeZoneId: 'America/New_York',
  },
  '40228': {
    zip: '40228',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.13947',
    longitude: '-85.630403',
    timeZoneId: 'America/New_York',
  },
  '40229': {
    zip: '40229',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.102864',
    longitude: '-85.665671',
    timeZoneId: 'America/New_York',
  },
  '40231': {
    zip: '40231',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.179183',
    longitude: '-85.719878',
    timeZoneId: 'America/New_York',
  },
  '40232': {
    zip: '40232',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.175012',
    longitude: '-85.720253',
    timeZoneId: 'America/New_York',
  },
  '40233': {
    zip: '40233',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.272002',
    longitude: '-85.529061',
    timeZoneId: 'America/New_York',
  },
  '40241': {
    zip: '40241',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.302202',
    longitude: '-85.581132',
    timeZoneId: 'America/New_York',
  },
  '40242': {
    zip: '40242',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.256667',
    longitude: '-85.601667',
    timeZoneId: 'America/New_York',
  },
  '40243': {
    zip: '40243',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.239734',
    longitude: '-85.539227',
    timeZoneId: 'America/New_York',
  },
  '40245': {
    zip: '40245',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.264541',
    longitude: '-85.495953',
    timeZoneId: 'America/New_York',
  },
  '40250': {
    zip: '40250',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.218955',
    longitude: '-85.620945',
    timeZoneId: 'America/New_York',
  },
  '40251': {
    zip: '40251',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.250649',
    longitude: '-85.799994',
    timeZoneId: 'America/New_York',
  },
  '40252': {
    zip: '40252',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.261193',
    longitude: '-85.604564',
    timeZoneId: 'America/New_York',
  },
  '40253': {
    zip: '40253',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.242117',
    longitude: '-85.529319',
    timeZoneId: 'America/New_York',
  },
  '40255': {
    zip: '40255',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.227823',
    longitude: '-85.686306',
    timeZoneId: 'America/New_York',
  },
  '40256': {
    zip: '40256',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.195411',
    longitude: '-85.808291',
    timeZoneId: 'America/New_York',
  },
  '40257': {
    zip: '40257',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.25486',
    longitude: '-85.766403',
    timeZoneId: 'America/New_York',
  },
  '40258': {
    zip: '40258',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.14231',
    longitude: '-85.861886',
    timeZoneId: 'America/New_York',
  },
  '40259': {
    zip: '40259',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.25448',
    longitude: '-85.759321',
    timeZoneId: 'America/New_York',
  },
  '40261': {
    zip: '40261',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.192741',
    longitude: '-85.650714',
    timeZoneId: 'America/New_York',
  },
  '40266': {
    zip: '40266',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.226101',
    longitude: '-85.742616',
    timeZoneId: 'America/New_York',
  },
  '40268': {
    zip: '40268',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.146878',
    longitude: '-85.87068',
    timeZoneId: 'America/New_York',
  },
  '40269': {
    zip: '40269',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.164595',
    longitude: '-85.5155',
    timeZoneId: 'America/New_York',
  },
  '40270': {
    zip: '40270',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.253553',
    longitude: '-85.757909',
    timeZoneId: 'America/New_York',
  },
  '40272': {
    zip: '40272',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.087506',
    longitude: '-85.84448',
    timeZoneId: 'America/New_York',
  },
  '40280': {
    zip: '40280',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.247902',
    longitude: '-85.68411',
    timeZoneId: 'America/New_York',
  },
  '40281': {
    zip: '40281',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.145981',
    longitude: '-85.867083',
    timeZoneId: 'America/New_York',
  },
  '40282': {
    zip: '40282',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.25486',
    longitude: '-85.766403',
    timeZoneId: 'America/New_York',
  },
  '40283': {
    zip: '40283',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.25486',
    longitude: '-85.766403',
    timeZoneId: 'America/New_York',
  },
  '40285': {
    zip: '40285',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.179153',
    longitude: '-85.721438',
    timeZoneId: 'America/New_York',
  },
  '40287': {
    zip: '40287',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.226435',
    longitude: '-85.686923',
    timeZoneId: 'America/New_York',
  },
  '40289': {
    zip: '40289',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.258911',
    longitude: '-85.756935',
    timeZoneId: 'America/New_York',
  },
  '40290': {
    zip: '40290',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.201276',
    longitude: '-85.69813',
    timeZoneId: 'America/New_York',
  },
  '40291': {
    zip: '40291',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.12514',
    longitude: '-85.58928',
    timeZoneId: 'America/New_York',
  },
  '40292': {
    zip: '40292',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.231826',
    longitude: '-85.669849',
    timeZoneId: 'America/New_York',
  },
  '40293': {
    zip: '40293',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.258971',
    longitude: '-85.758075',
    timeZoneId: 'America/New_York',
  },
  '40294': {
    zip: '40294',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.258043',
    longitude: '-85.759137',
    timeZoneId: 'America/New_York',
  },
  '40295': {
    zip: '40295',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.249134',
    longitude: '-85.757506',
    timeZoneId: 'America/New_York',
  },
  '40296': {
    zip: '40296',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.257669',
    longitude: '-85.757548',
    timeZoneId: 'America/New_York',
  },
  '40297': {
    zip: '40297',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.254499',
    longitude: '-85.7616',
    timeZoneId: 'America/New_York',
  },
  '40298': {
    zip: '40298',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.256066',
    longitude: '-85.753145',
    timeZoneId: 'America/New_York',
  },
  '40299': {
    zip: '40299',
    city: 'Louisville',
    state: 'KY',
    latitude: '38.163',
    longitude: '-85.5213',
    timeZoneId: 'America/New_York',
  },
  '40310': {
    zip: '40310',
    city: 'Burgin',
    state: 'KY',
    latitude: '37.755091',
    longitude: '-84.770528',
    timeZoneId: 'America/New_York',
  },
  '40311': {
    zip: '40311',
    city: 'Carlisle',
    state: 'KY',
    latitude: '38.338601',
    longitude: '-84.025878',
    timeZoneId: 'America/New_York',
  },
  '40312': {
    zip: '40312',
    city: 'Clay City',
    state: 'KY',
    latitude: '37.83966',
    longitude: '-83.93652',
    timeZoneId: 'America/New_York',
  },
  '40313': {
    zip: '40313',
    city: 'Clearfield',
    state: 'KY',
    latitude: '38.139029',
    longitude: '-83.539583',
    timeZoneId: 'America/New_York',
  },
  '40316': {
    zip: '40316',
    city: 'Denniston',
    state: 'KY',
    latitude: '37.921607',
    longitude: '-83.511154',
    timeZoneId: 'America/New_York',
  },
  '40317': {
    zip: '40317',
    city: 'Elliottville',
    state: 'KY',
    latitude: '38.182729',
    longitude: '-83.27658',
    timeZoneId: 'America/New_York',
  },
  '40319': {
    zip: '40319',
    city: 'Farmers',
    state: 'KY',
    latitude: '38.145208',
    longitude: '-83.545594',
    timeZoneId: 'America/New_York',
  },
  '40322': {
    zip: '40322',
    city: 'Frenchburg',
    state: 'KY',
    latitude: '37.95724',
    longitude: '-83.618623',
    timeZoneId: 'America/New_York',
  },
  '40324': {
    zip: '40324',
    city: 'Georgetown',
    state: 'KY',
    latitude: '38.220225',
    longitude: '-84.565091',
    timeZoneId: 'America/New_York',
  },
  '40328': {
    zip: '40328',
    city: 'Gravel Switch',
    state: 'KY',
    latitude: '37.568998',
    longitude: '-85.08567',
    timeZoneId: 'America/New_York',
  },
  '40330': {
    zip: '40330',
    city: 'Harrodsburg',
    state: 'KY',
    latitude: '37.7892',
    longitude: '-84.86178',
    timeZoneId: 'America/New_York',
  },
  '40334': {
    zip: '40334',
    city: 'Hope',
    state: 'KY',
    latitude: '37.917111',
    longitude: '-83.856444',
    timeZoneId: 'America/New_York',
  },
  '40336': {
    zip: '40336',
    city: 'Irvine',
    state: 'KY',
    latitude: '37.60268',
    longitude: '-83.88723',
    timeZoneId: 'America/New_York',
  },
  '40337': {
    zip: '40337',
    city: 'Jeffersonville',
    state: 'KY',
    latitude: '37.951348',
    longitude: '-83.863945',
    timeZoneId: 'America/New_York',
  },
  '40339': {
    zip: '40339',
    city: 'Keene',
    state: 'KY',
    latitude: '37.938356',
    longitude: '-84.65639',
    timeZoneId: 'America/New_York',
  },
  '40340': {
    zip: '40340',
    city: 'Nicholasville',
    state: 'KY',
    latitude: '37.878264',
    longitude: '-84.578028',
    timeZoneId: 'America/New_York',
  },
  '40342': {
    zip: '40342',
    city: 'Lawrenceburg',
    state: 'KY',
    latitude: '38.011899',
    longitude: '-84.949818',
    timeZoneId: 'America/New_York',
  },
  '40346': {
    zip: '40346',
    city: 'Means',
    state: 'KY',
    latitude: '37.9139',
    longitude: '-83.714363',
    timeZoneId: 'America/New_York',
  },
  '40347': {
    zip: '40347',
    city: 'Midway',
    state: 'KY',
    latitude: '38.143797',
    longitude: '-84.695175',
    timeZoneId: 'America/New_York',
  },
  '40348': {
    zip: '40348',
    city: 'Millersburg',
    state: 'KY',
    latitude: '38.301505',
    longitude: '-84.142003',
    timeZoneId: 'America/New_York',
  },
  '40350': {
    zip: '40350',
    city: 'Moorefield',
    state: 'KY',
    latitude: '38.302911',
    longitude: '-83.869535',
    timeZoneId: 'America/New_York',
  },
  '40351': {
    zip: '40351',
    city: 'Morehead',
    state: 'KY',
    latitude: '38.194098',
    longitude: '-83.439956',
    timeZoneId: 'America/New_York',
  },
  '40353': {
    zip: '40353',
    city: 'Mount Sterling',
    state: 'KY',
    latitude: '38.057445',
    longitude: '-83.934406',
    timeZoneId: 'America/New_York',
  },
  '40355': {
    zip: '40355',
    city: 'New Liberty',
    state: 'KY',
    latitude: '38.620652',
    longitude: '-84.890171',
    timeZoneId: 'America/New_York',
  },
  '40356': {
    zip: '40356',
    city: 'Nicholasville',
    state: 'KY',
    latitude: '37.883596',
    longitude: '-84.566606',
    timeZoneId: 'America/New_York',
  },
  '40357': {
    zip: '40357',
    city: 'North Middletown',
    state: 'KY',
    latitude: '38.144609',
    longitude: '-84.108627',
    timeZoneId: 'America/New_York',
  },
  '40358': {
    zip: '40358',
    city: 'Olympia',
    state: 'KY',
    latitude: '38.049641',
    longitude: '-83.759266',
    timeZoneId: 'America/New_York',
  },
  '40359': {
    zip: '40359',
    city: 'Owenton',
    state: 'KY',
    latitude: '38.4786',
    longitude: '-84.81408',
    timeZoneId: 'America/New_York',
  },
  '40360': {
    zip: '40360',
    city: 'Owingsville',
    state: 'KY',
    latitude: '38.143221',
    longitude: '-83.76749',
    timeZoneId: 'America/New_York',
  },
  '40361': {
    zip: '40361',
    city: 'Paris',
    state: 'KY',
    latitude: '38.205376',
    longitude: '-84.241639',
    timeZoneId: 'America/New_York',
  },
  '40362': {
    zip: '40362',
    city: 'Paris',
    state: 'KY',
    latitude: '38.205378',
    longitude: '-84.263431',
    timeZoneId: 'America/New_York',
  },
  '40363': {
    zip: '40363',
    city: 'Perry Park',
    state: 'KY',
    latitude: '38.547694',
    longitude: '-84.998631',
    timeZoneId: 'America/New_York',
  },
  '40366': {
    zip: '40366',
    city: 'Preston',
    state: 'KY',
    latitude: '38.082578',
    longitude: '-83.757136',
    timeZoneId: 'America/New_York',
  },
  '40370': {
    zip: '40370',
    city: 'Sadieville',
    state: 'KY',
    latitude: '38.387033',
    longitude: '-84.533895',
    timeZoneId: 'America/New_York',
  },
  '40371': {
    zip: '40371',
    city: 'Salt Lick',
    state: 'KY',
    latitude: '38.084878',
    longitude: '-83.623075',
    timeZoneId: 'America/New_York',
  },
  '40372': {
    zip: '40372',
    city: 'Salvisa',
    state: 'KY',
    latitude: '37.909927',
    longitude: '-84.876344',
    timeZoneId: 'America/New_York',
  },
  '40374': {
    zip: '40374',
    city: 'Sharpsburg',
    state: 'KY',
    latitude: '38.245',
    longitude: '-83.868056',
    timeZoneId: 'America/New_York',
  },
  '40376': {
    zip: '40376',
    city: 'Slade',
    state: 'KY',
    latitude: '37.774455',
    longitude: '-83.685771',
    timeZoneId: 'America/New_York',
  },
  '40379': {
    zip: '40379',
    city: 'Stamping Ground',
    state: 'KY',
    latitude: '38.296886',
    longitude: '-84.675833',
    timeZoneId: 'America/New_York',
  },
  '40380': {
    zip: '40380',
    city: 'Stanton',
    state: 'KY',
    latitude: '37.85159',
    longitude: '-83.854413',
    timeZoneId: 'America/New_York',
  },
  '40383': {
    zip: '40383',
    city: 'Versailles',
    state: 'KY',
    latitude: '38.049052',
    longitude: '-84.716968',
    timeZoneId: 'America/New_York',
  },
  '40384': {
    zip: '40384',
    city: 'Versailles',
    state: 'KY',
    latitude: '38.05198',
    longitude: '-84.72598',
    timeZoneId: 'America/New_York',
  },
  '40385': {
    zip: '40385',
    city: 'Waco',
    state: 'KY',
    latitude: '37.747999',
    longitude: '-84.128552',
    timeZoneId: 'America/New_York',
  },
  '40387': {
    zip: '40387',
    city: 'Wellington',
    state: 'KY',
    latitude: '37.981269',
    longitude: '-83.472057',
    timeZoneId: 'America/New_York',
  },
  '40390': {
    zip: '40390',
    city: 'Wilmore',
    state: 'KY',
    latitude: '37.862373',
    longitude: '-84.648746',
    timeZoneId: 'America/New_York',
  },
  '40391': {
    zip: '40391',
    city: 'Winchester',
    state: 'KY',
    latitude: '37.980177',
    longitude: '-84.168803',
    timeZoneId: 'America/New_York',
  },
  '40392': {
    zip: '40392',
    city: 'Winchester',
    state: 'KY',
    latitude: '37.988447',
    longitude: '-84.178486',
    timeZoneId: 'America/New_York',
  },
  '40402': {
    zip: '40402',
    city: 'Annville',
    state: 'KY',
    latitude: '37.303317',
    longitude: '-84.007453',
    timeZoneId: 'America/New_York',
  },
  '40403': {
    zip: '40403',
    city: 'Berea',
    state: 'KY',
    latitude: '37.58292',
    longitude: '-84.274023',
    timeZoneId: 'America/New_York',
  },
  '40404': {
    zip: '40404',
    city: 'Berea',
    state: 'KY',
    latitude: '37.568461',
    longitude: '-84.292823',
    timeZoneId: 'America/New_York',
  },
  '40405': {
    zip: '40405',
    city: 'Bighill',
    state: 'KY',
    latitude: '37.557751',
    longitude: '-84.208093',
    timeZoneId: 'America/New_York',
  },
  '40409': {
    zip: '40409',
    city: 'Brodhead',
    state: 'KY',
    latitude: '37.367616',
    longitude: '-84.437246',
    timeZoneId: 'America/New_York',
  },
  '40410': {
    zip: '40410',
    city: 'Bryantsville',
    state: 'KY',
    latitude: '37.713868',
    longitude: '-84.646972',
    timeZoneId: 'America/New_York',
  },
  '40419': {
    zip: '40419',
    city: 'Crab Orchard',
    state: 'KY',
    latitude: '37.440601',
    longitude: '-84.519795',
    timeZoneId: 'America/New_York',
  },
  '40422': {
    zip: '40422',
    city: 'Danville',
    state: 'KY',
    latitude: '37.64022',
    longitude: '-84.782363',
    timeZoneId: 'America/New_York',
  },
  '40423': {
    zip: '40423',
    city: 'Danville',
    state: 'KY',
    latitude: '37.643159',
    longitude: '-84.771501',
    timeZoneId: 'America/New_York',
  },
  '40434': {
    zip: '40434',
    city: 'Gray Hawk',
    state: 'KY',
    latitude: '37.403958',
    longitude: '-83.898404',
    timeZoneId: 'America/New_York',
  },
  '40437': {
    zip: '40437',
    city: 'Hustonville',
    state: 'KY',
    latitude: '37.435708',
    longitude: '-84.784183',
    timeZoneId: 'America/New_York',
  },
  '40440': {
    zip: '40440',
    city: 'Junction City',
    state: 'KY',
    latitude: '37.579154',
    longitude: '-84.819559',
    timeZoneId: 'America/New_York',
  },
  '40442': {
    zip: '40442',
    city: 'Kings Mountain',
    state: 'KY',
    latitude: '37.366098',
    longitude: '-84.701152',
    timeZoneId: 'America/New_York',
  },
  '40444': {
    zip: '40444',
    city: 'Lancaster',
    state: 'KY',
    latitude: '37.66364',
    longitude: '-84.593177',
    timeZoneId: 'America/New_York',
  },
  '40445': {
    zip: '40445',
    city: 'Livingston',
    state: 'KY',
    latitude: '37.304447',
    longitude: '-84.233162',
    timeZoneId: 'America/New_York',
  },
  '40447': {
    zip: '40447',
    city: 'Mc Kee',
    state: 'KY',
    latitude: '37.450161',
    longitude: '-84.012456',
    timeZoneId: 'America/New_York',
  },
  '40448': {
    zip: '40448',
    city: 'Mc Kinney',
    state: 'KY',
    latitude: '37.45401',
    longitude: '-84.760289',
    timeZoneId: 'America/New_York',
  },
  '40452': {
    zip: '40452',
    city: 'Mitchellsburg',
    state: 'KY',
    latitude: '37.598414',
    longitude: '-84.964295',
    timeZoneId: 'America/New_York',
  },
  '40456': {
    zip: '40456',
    city: 'Mount Vernon',
    state: 'KY',
    latitude: '37.358415',
    longitude: '-84.346235',
    timeZoneId: 'America/New_York',
  },
  '40460': {
    zip: '40460',
    city: 'Orlando',
    state: 'KY',
    latitude: '37.38778',
    longitude: '-84.220202',
    timeZoneId: 'America/New_York',
  },
  '40461': {
    zip: '40461',
    city: 'Paint Lick',
    state: 'KY',
    latitude: '37.582697',
    longitude: '-84.432837',
    timeZoneId: 'America/New_York',
  },
  '40464': {
    zip: '40464',
    city: 'Parksville',
    state: 'KY',
    latitude: '37.588144',
    longitude: '-84.921499',
    timeZoneId: 'America/New_York',
  },
  '40468': {
    zip: '40468',
    city: 'Perryville',
    state: 'KY',
    latitude: '37.64835',
    longitude: '-84.968959',
    timeZoneId: 'America/New_York',
  },
  '40472': {
    zip: '40472',
    city: 'Ravenna',
    state: 'KY',
    latitude: '37.68522',
    longitude: '-83.9427',
    timeZoneId: 'America/New_York',
  },
  '40473': {
    zip: '40473',
    city: 'Renfro Valley',
    state: 'KY',
    latitude: '37.388535',
    longitude: '-84.330442',
    timeZoneId: 'America/New_York',
  },
  '40475': {
    zip: '40475',
    city: 'Richmond',
    state: 'KY',
    latitude: '37.76341',
    longitude: '-84.300058',
    timeZoneId: 'America/New_York',
  },
  '40476': {
    zip: '40476',
    city: 'Richmond',
    state: 'KY',
    latitude: '37.748985',
    longitude: '-84.293512',
    timeZoneId: 'America/New_York',
  },
  '40481': {
    zip: '40481',
    city: 'Sandgap',
    state: 'KY',
    latitude: '37.485179',
    longitude: '-84.044483',
    timeZoneId: 'America/New_York',
  },
  '40484': {
    zip: '40484',
    city: 'Stanford',
    state: 'KY',
    latitude: '37.515657',
    longitude: '-84.679979',
    timeZoneId: 'America/New_York',
  },
  '40486': {
    zip: '40486',
    city: 'Tyner',
    state: 'KY',
    latitude: '37.378148',
    longitude: '-83.874828',
    timeZoneId: 'America/New_York',
  },
  '40488': {
    zip: '40488',
    city: 'Waneta',
    state: 'KY',
    latitude: '37.474812',
    longitude: '-84.038292',
    timeZoneId: 'America/New_York',
  },
  '40489': {
    zip: '40489',
    city: 'Waynesburg',
    state: 'KY',
    latitude: '37.363655',
    longitude: '-84.632448',
    timeZoneId: 'America/New_York',
  },
  '40492': {
    zip: '40492',
    city: 'Wildie',
    state: 'KY',
    latitude: '37.420572',
    longitude: '-84.299702',
    timeZoneId: 'America/New_York',
  },
  '40502': {
    zip: '40502',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.002247',
    longitude: '-84.480071',
    timeZoneId: 'America/New_York',
  },
  '40503': {
    zip: '40503',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.000395',
    longitude: '-84.530955',
    timeZoneId: 'America/New_York',
  },
  '40504': {
    zip: '40504',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.039789',
    longitude: '-84.537499',
    timeZoneId: 'America/New_York',
  },
  '40505': {
    zip: '40505',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.063208',
    longitude: '-84.449524',
    timeZoneId: 'America/New_York',
  },
  '40506': {
    zip: '40506',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.030576',
    longitude: '-84.508062',
    timeZoneId: 'America/New_York',
  },
  '40507': {
    zip: '40507',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.048262',
    longitude: '-84.492068',
    timeZoneId: 'America/New_York',
  },
  '40508': {
    zip: '40508',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.055734',
    longitude: '-84.502272',
    timeZoneId: 'America/New_York',
  },
  '40509': {
    zip: '40509',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.003602',
    longitude: '-84.419005',
    timeZoneId: 'America/New_York',
  },
  '40510': {
    zip: '40510',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.060788',
    longitude: '-84.603634',
    timeZoneId: 'America/New_York',
  },
  '40511': {
    zip: '40511',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.09751',
    longitude: '-84.497054',
    timeZoneId: 'America/New_York',
  },
  '40512': {
    zip: '40512',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.137113',
    longitude: '-84.467519',
    timeZoneId: 'America/New_York',
  },
  '40513': {
    zip: '40513',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.015394',
    longitude: '-84.593769',
    timeZoneId: 'America/New_York',
  },
  '40514': {
    zip: '40514',
    city: 'Lexington',
    state: 'KY',
    latitude: '37.981959',
    longitude: '-84.570615',
    timeZoneId: 'America/New_York',
  },
  '40515': {
    zip: '40515',
    city: 'Lexington',
    state: 'KY',
    latitude: '37.963113',
    longitude: '-84.467883',
    timeZoneId: 'America/New_York',
  },
  '40516': {
    zip: '40516',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.058255',
    longitude: '-84.379824',
    timeZoneId: 'America/New_York',
  },
  '40517': {
    zip: '40517',
    city: 'Lexington',
    state: 'KY',
    latitude: '37.979479',
    longitude: '-84.48485',
    timeZoneId: 'America/New_York',
  },
  '40522': {
    zip: '40522',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.022218',
    longitude: '-84.489709',
    timeZoneId: 'America/New_York',
  },
  '40523': {
    zip: '40523',
    city: 'Lexington',
    state: 'KY',
    latitude: '37.984878',
    longitude: '-84.513996',
    timeZoneId: 'America/New_York',
  },
  '40524': {
    zip: '40524',
    city: 'Lexington',
    state: 'KY',
    latitude: '37.990125',
    longitude: '-84.533081',
    timeZoneId: 'America/New_York',
  },
  '40526': {
    zip: '40526',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.040959',
    longitude: '-84.505885',
    timeZoneId: 'America/New_York',
  },
  '40533': {
    zip: '40533',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.0388',
    longitude: '-84.542503',
    timeZoneId: 'America/New_York',
  },
  '40536': {
    zip: '40536',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.030056',
    longitude: '-84.507951',
    timeZoneId: 'America/New_York',
  },
  '40544': {
    zip: '40544',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.042496',
    longitude: '-84.541577',
    timeZoneId: 'America/New_York',
  },
  '40546': {
    zip: '40546',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.038753',
    longitude: '-84.508993',
    timeZoneId: 'America/New_York',
  },
  '40550': {
    zip: '40550',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.141026',
    longitude: '-84.46693',
    timeZoneId: 'America/New_York',
  },
  '40555': {
    zip: '40555',
    city: 'Lexington',
    state: 'KY',
    latitude: '37.984827',
    longitude: '-84.427055',
    timeZoneId: 'America/New_York',
  },
  '40574': {
    zip: '40574',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.137144',
    longitude: '-84.473423',
    timeZoneId: 'America/New_York',
  },
  '40575': {
    zip: '40575',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.136382',
    longitude: '-84.470769',
    timeZoneId: 'America/New_York',
  },
  '40576': {
    zip: '40576',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.136625',
    longitude: '-84.470075',
    timeZoneId: 'America/New_York',
  },
  '40577': {
    zip: '40577',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.071498',
    longitude: '-84.502984',
    timeZoneId: 'America/New_York',
  },
  '40578': {
    zip: '40578',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.078233',
    longitude: '-84.503065',
    timeZoneId: 'America/New_York',
  },
  '40579': {
    zip: '40579',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.136298',
    longitude: '-84.472269',
    timeZoneId: 'America/New_York',
  },
  '40580': {
    zip: '40580',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.136215',
    longitude: '-84.470716',
    timeZoneId: 'America/New_York',
  },
  '40581': {
    zip: '40581',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.139148',
    longitude: '-84.475535',
    timeZoneId: 'America/New_York',
  },
  '40582': {
    zip: '40582',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.139575',
    longitude: '-84.473523',
    timeZoneId: 'America/New_York',
  },
  '40583': {
    zip: '40583',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.136537',
    longitude: '-84.473757',
    timeZoneId: 'America/New_York',
  },
  '40588': {
    zip: '40588',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.046141',
    longitude: '-84.498648',
    timeZoneId: 'America/New_York',
  },
  '40591': {
    zip: '40591',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.048061',
    longitude: '-84.493671',
    timeZoneId: 'America/New_York',
  },
  '40598': {
    zip: '40598',
    city: 'Lexington',
    state: 'KY',
    latitude: '38.050332',
    longitude: '-84.49784',
    timeZoneId: 'America/New_York',
  },
  '40601': {
    zip: '40601',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.199801',
    longitude: '-84.947505',
    timeZoneId: 'America/New_York',
  },
  '40602': {
    zip: '40602',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.213933',
    longitude: '-84.816581',
    timeZoneId: 'America/New_York',
  },
  '40603': {
    zip: '40603',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.202865',
    longitude: '-84.829522',
    timeZoneId: 'America/New_York',
  },
  '40604': {
    zip: '40604',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.221884',
    longitude: '-84.916161',
    timeZoneId: 'America/New_York',
  },
  '40618': {
    zip: '40618',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.179131',
    longitude: '-84.898561',
    timeZoneId: 'America/New_York',
  },
  '40619': {
    zip: '40619',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.17791',
    longitude: '-84.899138',
    timeZoneId: 'America/New_York',
  },
  '40620': {
    zip: '40620',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.177757',
    longitude: '-84.899142',
    timeZoneId: 'America/New_York',
  },
  '40621': {
    zip: '40621',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.200847',
    longitude: '-84.872727',
    timeZoneId: 'America/New_York',
  },
  '40622': {
    zip: '40622',
    city: 'Frankfort',
    state: 'KY',
    latitude: '38.197856',
    longitude: '-84.87161',
    timeZoneId: 'America/New_York',
  },
  '40701': {
    zip: '40701',
    city: 'Corbin',
    state: 'KY',
    latitude: '36.937489',
    longitude: '-84.114901',
    timeZoneId: 'America/New_York',
  },
  '40702': {
    zip: '40702',
    city: 'Corbin',
    state: 'KY',
    latitude: '36.950715',
    longitude: '-84.086781',
    timeZoneId: 'America/New_York',
  },
  '40724': {
    zip: '40724',
    city: 'Bush',
    state: 'KY',
    latitude: '37.077557',
    longitude: '-84.082857',
    timeZoneId: 'America/New_York',
  },
  '40729': {
    zip: '40729',
    city: 'East Bernstadt',
    state: 'KY',
    latitude: '37.204331',
    longitude: '-84.118241',
    timeZoneId: 'America/New_York',
  },
  '40730': {
    zip: '40730',
    city: 'Emlyn',
    state: 'KY',
    latitude: '36.70642',
    longitude: '-84.146051',
    timeZoneId: 'America/New_York',
  },
  '40734': {
    zip: '40734',
    city: 'Gray',
    state: 'KY',
    latitude: '36.952243',
    longitude: '-83.963632',
    timeZoneId: 'America/New_York',
  },
  '40737': {
    zip: '40737',
    city: 'Keavy',
    state: 'KY',
    latitude: '37.017673',
    longitude: '-84.1593',
    timeZoneId: 'America/New_York',
  },
  '40740': {
    zip: '40740',
    city: 'Lily',
    state: 'KY',
    latitude: '37.018122',
    longitude: '-84.065681',
    timeZoneId: 'America/New_York',
  },
  '40741': {
    zip: '40741',
    city: 'London',
    state: 'KY',
    latitude: '37.11022',
    longitude: '-84.078189',
    timeZoneId: 'America/New_York',
  },
  '40742': {
    zip: '40742',
    city: 'London',
    state: 'KY',
    latitude: '37.089649',
    longitude: '-84.146587',
    timeZoneId: 'America/New_York',
  },
  '40743': {
    zip: '40743',
    city: 'London',
    state: 'KY',
    latitude: '37.129167',
    longitude: '-84.082336',
    timeZoneId: 'America/New_York',
  },
  '40744': {
    zip: '40744',
    city: 'London',
    state: 'KY',
    latitude: '37.068625',
    longitude: '-84.020634',
    timeZoneId: 'America/New_York',
  },
  '40745': {
    zip: '40745',
    city: 'London',
    state: 'KY',
    latitude: '37.088793',
    longitude: '-84.143582',
    timeZoneId: 'America/New_York',
  },
  '40750': {
    zip: '40750',
    city: 'London',
    state: 'KY',
    latitude: '37.129091',
    longitude: '-84.157082',
    timeZoneId: 'America/New_York',
  },
  '40755': {
    zip: '40755',
    city: 'Pittsburg',
    state: 'KY',
    latitude: '37.159579',
    longitude: '-84.1041',
    timeZoneId: 'America/New_York',
  },
  '40759': {
    zip: '40759',
    city: 'Rockholds',
    state: 'KY',
    latitude: '36.82738',
    longitude: '-84.09159',
    timeZoneId: 'America/New_York',
  },
  '40763': {
    zip: '40763',
    city: 'Siler',
    state: 'KY',
    latitude: '36.724748',
    longitude: '-83.969105',
    timeZoneId: 'America/New_York',
  },
  '40769': {
    zip: '40769',
    city: 'Williamsburg',
    state: 'KY',
    latitude: '36.744037',
    longitude: '-84.165125',
    timeZoneId: 'America/New_York',
  },
  '40771': {
    zip: '40771',
    city: 'Woodbine',
    state: 'KY',
    latitude: '36.92097',
    longitude: '-84.080605',
    timeZoneId: 'America/New_York',
  },
  '40801': {
    zip: '40801',
    city: 'Ages Brookside',
    state: 'KY',
    latitude: '36.859167',
    longitude: '-83.249444',
    timeZoneId: 'America/New_York',
  },
  '40803': {
    zip: '40803',
    city: 'Asher',
    state: 'KY',
    latitude: '36.989628',
    longitude: '-83.392726',
    timeZoneId: 'America/New_York',
  },
  '40806': {
    zip: '40806',
    city: 'Baxter',
    state: 'KY',
    latitude: '36.87756',
    longitude: '-83.31672',
    timeZoneId: 'America/New_York',
  },
  '40807': {
    zip: '40807',
    city: 'Benham',
    state: 'KY',
    latitude: '36.967225',
    longitude: '-82.956333',
    timeZoneId: 'America/New_York',
  },
  '40808': {
    zip: '40808',
    city: 'Big Laurel',
    state: 'KY',
    latitude: '36.997932',
    longitude: '-83.164976',
    timeZoneId: 'America/New_York',
  },
  '40810': {
    zip: '40810',
    city: 'Bledsoe',
    state: 'KY',
    latitude: '36.87298',
    longitude: '-83.43296',
    timeZoneId: 'America/New_York',
  },
  '40813': {
    zip: '40813',
    city: 'Calvin',
    state: 'KY',
    latitude: '36.693772',
    longitude: '-83.765321',
    timeZoneId: 'America/New_York',
  },
  '40815': {
    zip: '40815',
    city: 'Cawood',
    state: 'KY',
    latitude: '36.751998',
    longitude: '-83.26836',
    timeZoneId: 'America/New_York',
  },
  '40816': {
    zip: '40816',
    city: 'Chappell',
    state: 'KY',
    latitude: '36.990603',
    longitude: '-83.389842',
    timeZoneId: 'America/New_York',
  },
  '40818': {
    zip: '40818',
    city: 'Coalgood',
    state: 'KY',
    latitude: '36.807791',
    longitude: '-83.237338',
    timeZoneId: 'America/New_York',
  },
  '40819': {
    zip: '40819',
    city: 'Coldiron',
    state: 'KY',
    latitude: '36.87462',
    longitude: '-83.44032',
    timeZoneId: 'America/New_York',
  },
  '40820': {
    zip: '40820',
    city: 'Cranks',
    state: 'KY',
    latitude: '36.757243',
    longitude: '-83.199118',
    timeZoneId: 'America/New_York',
  },
  '40823': {
    zip: '40823',
    city: 'Cumberland',
    state: 'KY',
    latitude: '36.975189',
    longitude: '-82.986975',
    timeZoneId: 'America/New_York',
  },
  '40824': {
    zip: '40824',
    city: 'Dayhoit',
    state: 'KY',
    latitude: '36.867282',
    longitude: '-83.387384',
    timeZoneId: 'America/New_York',
  },
  '40826': {
    zip: '40826',
    city: 'Eolia',
    state: 'KY',
    latitude: '37.065784',
    longitude: '-82.760052',
    timeZoneId: 'America/New_York',
  },
  '40827': {
    zip: '40827',
    city: 'Essie',
    state: 'KY',
    latitude: '36.987935',
    longitude: '-83.387657',
    timeZoneId: 'America/New_York',
  },
  '40828': {
    zip: '40828',
    city: 'Evarts',
    state: 'KY',
    latitude: '36.847695',
    longitude: '-83.163724',
    timeZoneId: 'America/New_York',
  },
  '40829': {
    zip: '40829',
    city: 'Grays Knob',
    state: 'KY',
    latitude: '36.841312',
    longitude: '-83.318518',
    timeZoneId: 'America/New_York',
  },
  '40830': {
    zip: '40830',
    city: 'Gulston',
    state: 'KY',
    latitude: '36.757527',
    longitude: '-83.334349',
    timeZoneId: 'America/New_York',
  },
  '40831': {
    zip: '40831',
    city: 'Harlan',
    state: 'KY',
    latitude: '36.846189',
    longitude: '-83.314477',
    timeZoneId: 'America/New_York',
  },
  '40840': {
    zip: '40840',
    city: 'Helton',
    state: 'KY',
    latitude: '36.952639',
    longitude: '-83.391566',
    timeZoneId: 'America/New_York',
  },
  '40843': {
    zip: '40843',
    city: 'Holmes Mill',
    state: 'KY',
    latitude: '36.884524',
    longitude: '-82.967825',
    timeZoneId: 'America/New_York',
  },
  '40844': {
    zip: '40844',
    city: 'Hoskinston',
    state: 'KY',
    latitude: '37.077258',
    longitude: '-83.390639',
    timeZoneId: 'America/New_York',
  },
  '40845': {
    zip: '40845',
    city: 'Hulen',
    state: 'KY',
    latitude: '36.748142',
    longitude: '-83.531184',
    timeZoneId: 'America/New_York',
  },
  '40847': {
    zip: '40847',
    city: 'Kenvir',
    state: 'KY',
    latitude: '36.773063',
    longitude: '-83.157662',
    timeZoneId: 'America/New_York',
  },
  '40849': {
    zip: '40849',
    city: 'Lejunior',
    state: 'KY',
    latitude: '36.918266',
    longitude: '-83.181917',
    timeZoneId: 'America/New_York',
  },
  '40854': {
    zip: '40854',
    city: 'Loyall',
    state: 'KY',
    latitude: '36.846185',
    longitude: '-83.355819',
    timeZoneId: 'America/New_York',
  },
  '40855': {
    zip: '40855',
    city: 'Lynch',
    state: 'KY',
    latitude: '36.963272',
    longitude: '-82.91036',
    timeZoneId: 'America/New_York',
  },
  '40856': {
    zip: '40856',
    city: 'Miracle',
    state: 'KY',
    latitude: '36.701111',
    longitude: '-83.521111',
    timeZoneId: 'America/New_York',
  },
  '40858': {
    zip: '40858',
    city: 'Mozelle',
    state: 'KY',
    latitude: '36.98795',
    longitude: '-83.390993',
    timeZoneId: 'America/New_York',
  },
  '40862': {
    zip: '40862',
    city: 'Partridge',
    state: 'KY',
    latitude: '37.011481',
    longitude: '-82.875359',
    timeZoneId: 'America/New_York',
  },
  '40863': {
    zip: '40863',
    city: 'Pathfork',
    state: 'KY',
    latitude: '36.745787',
    longitude: '-83.455764',
    timeZoneId: 'America/New_York',
  },
  '40865': {
    zip: '40865',
    city: 'Putney',
    state: 'KY',
    latitude: '36.938143',
    longitude: '-83.265739',
    timeZoneId: 'America/New_York',
  },
  '40868': {
    zip: '40868',
    city: 'Stinnett',
    state: 'KY',
    latitude: '37.088942',
    longitude: '-83.366316',
    timeZoneId: 'America/New_York',
  },
  '40870': {
    zip: '40870',
    city: 'Totz',
    state: 'KY',
    latitude: '36.96915',
    longitude: '-83.204775',
    timeZoneId: 'America/New_York',
  },
  '40873': {
    zip: '40873',
    city: 'Wallins Creek',
    state: 'KY',
    latitude: '36.81786',
    longitude: '-83.40576',
    timeZoneId: 'America/New_York',
  },
  '40874': {
    zip: '40874',
    city: 'Warbranch',
    state: 'KY',
    latitude: '36.990654',
    longitude: '-83.391027',
    timeZoneId: 'America/New_York',
  },
  '40902': {
    zip: '40902',
    city: 'Arjay',
    state: 'KY',
    latitude: '36.842039',
    longitude: '-83.640103',
    timeZoneId: 'America/New_York',
  },
  '40903': {
    zip: '40903',
    city: 'Artemus',
    state: 'KY',
    latitude: '36.843261',
    longitude: '-83.828011',
    timeZoneId: 'America/New_York',
  },
  '40906': {
    zip: '40906',
    city: 'Barbourville',
    state: 'KY',
    latitude: '36.86578',
    longitude: '-83.892076',
    timeZoneId: 'America/New_York',
  },
  '40913': {
    zip: '40913',
    city: 'Beverly',
    state: 'KY',
    latitude: '36.940872',
    longitude: '-83.564677',
    timeZoneId: 'America/New_York',
  },
  '40914': {
    zip: '40914',
    city: 'Big Creek',
    state: 'KY',
    latitude: '37.151896',
    longitude: '-83.620829',
    timeZoneId: 'America/New_York',
  },
  '40915': {
    zip: '40915',
    city: 'Bimble',
    state: 'KY',
    latitude: '36.890188',
    longitude: '-83.823329',
    timeZoneId: 'America/New_York',
  },
  '40921': {
    zip: '40921',
    city: 'Bryants Store',
    state: 'KY',
    latitude: '36.766617',
    longitude: '-83.918708',
    timeZoneId: 'America/New_York',
  },
  '40923': {
    zip: '40923',
    city: 'Cannon',
    state: 'KY',
    latitude: '36.964621',
    longitude: '-83.806033',
    timeZoneId: 'America/New_York',
  },
  '40927': {
    zip: '40927',
    city: 'Closplint',
    state: 'KY',
    latitude: '36.900366',
    longitude: '-83.085472',
    timeZoneId: 'America/New_York',
  },
  '40930': {
    zip: '40930',
    city: 'Dewitt',
    state: 'KY',
    latitude: '36.870963',
    longitude: '-83.729048',
    timeZoneId: 'America/New_York',
  },
  '40932': {
    zip: '40932',
    city: 'Fall Rock',
    state: 'KY',
    latitude: '37.219911',
    longitude: '-83.78907',
    timeZoneId: 'America/New_York',
  },
  '40935': {
    zip: '40935',
    city: 'Flat Lick',
    state: 'KY',
    latitude: '36.834317',
    longitude: '-83.759584',
    timeZoneId: 'America/New_York',
  },
  '40939': {
    zip: '40939',
    city: 'Fourmile',
    state: 'KY',
    latitude: '36.742655',
    longitude: '-83.693032',
    timeZoneId: 'America/New_York',
  },
  '40940': {
    zip: '40940',
    city: 'Frakes',
    state: 'KY',
    latitude: '36.629745',
    longitude: '-83.888424',
    timeZoneId: 'America/New_York',
  },
  '40941': {
    zip: '40941',
    city: 'Garrard',
    state: 'KY',
    latitude: '37.12285',
    longitude: '-83.748834',
    timeZoneId: 'America/New_York',
  },
  '40943': {
    zip: '40943',
    city: 'Girdler',
    state: 'KY',
    latitude: '36.972409',
    longitude: '-83.855205',
    timeZoneId: 'America/New_York',
  },
  '40944': {
    zip: '40944',
    city: 'Goose Rock',
    state: 'KY',
    latitude: '37.090083',
    longitude: '-83.693057',
    timeZoneId: 'America/New_York',
  },
  '40946': {
    zip: '40946',
    city: 'Green Road',
    state: 'KY',
    latitude: '37.01005',
    longitude: '-83.896526',
    timeZoneId: 'America/New_York',
  },
  '40949': {
    zip: '40949',
    city: 'Heidrick',
    state: 'KY',
    latitude: '36.896379',
    longitude: '-83.868344',
    timeZoneId: 'America/New_York',
  },
  '40951': {
    zip: '40951',
    city: 'Hima',
    state: 'KY',
    latitude: '37.118439',
    longitude: '-83.774727',
    timeZoneId: 'America/New_York',
  },
  '40953': {
    zip: '40953',
    city: 'Hinkle',
    state: 'KY',
    latitude: '36.876856',
    longitude: '-83.777945',
    timeZoneId: 'America/New_York',
  },
  '40955': {
    zip: '40955',
    city: 'Ingram',
    state: 'KY',
    latitude: '36.728551',
    longitude: '-83.800519',
    timeZoneId: 'America/New_York',
  },
  '40958': {
    zip: '40958',
    city: 'Kettle Island',
    state: 'KY',
    latitude: '36.818992',
    longitude: '-83.585177',
    timeZoneId: 'America/New_York',
  },
  '40962': {
    zip: '40962',
    city: 'Manchester',
    state: 'KY',
    latitude: '37.152002',
    longitude: '-83.764393',
    timeZoneId: 'America/New_York',
  },
  '40964': {
    zip: '40964',
    city: 'Mary Alice',
    state: 'KY',
    latitude: '36.699988',
    longitude: '-83.419993',
    timeZoneId: 'America/New_York',
  },
  '40965': {
    zip: '40965',
    city: 'Middlesboro',
    state: 'KY',
    latitude: '36.609522',
    longitude: '-83.721655',
    timeZoneId: 'America/New_York',
  },
  '40972': {
    zip: '40972',
    city: 'Oneida',
    state: 'KY',
    latitude: '37.27342',
    longitude: '-83.653289',
    timeZoneId: 'America/New_York',
  },
  '40977': {
    zip: '40977',
    city: 'Pineville',
    state: 'KY',
    latitude: '36.751701',
    longitude: '-83.699321',
    timeZoneId: 'America/New_York',
  },
  '40979': {
    zip: '40979',
    city: 'Roark',
    state: 'KY',
    latitude: '37.028733',
    longitude: '-83.510828',
    timeZoneId: 'America/New_York',
  },
  '40981': {
    zip: '40981',
    city: 'Saul',
    state: 'KY',
    latitude: '37.395772',
    longitude: '-83.271741',
    timeZoneId: 'America/New_York',
  },
  '40982': {
    zip: '40982',
    city: 'Scalf',
    state: 'KY',
    latitude: '36.940616',
    longitude: '-83.718678',
    timeZoneId: 'America/New_York',
  },
  '40983': {
    zip: '40983',
    city: 'Sextons Creek',
    state: 'KY',
    latitude: '37.294413',
    longitude: '-83.795195',
    timeZoneId: 'America/New_York',
  },
  '40988': {
    zip: '40988',
    city: 'Stoney Fork',
    state: 'KY',
    latitude: '36.884347',
    longitude: '-83.530783',
    timeZoneId: 'America/New_York',
  },
  '40995': {
    zip: '40995',
    city: 'Trosper',
    state: 'KY',
    latitude: '36.782335',
    longitude: '-83.835499',
    timeZoneId: 'America/New_York',
  },
  '40997': {
    zip: '40997',
    city: 'Walker',
    state: 'KY',
    latitude: '36.890985',
    longitude: '-83.66809',
    timeZoneId: 'America/New_York',
  },
  '41001': {
    zip: '41001',
    city: 'Alexandria',
    state: 'KY',
    latitude: '38.91576',
    longitude: '-84.3957',
    timeZoneId: 'America/New_York',
  },
  '41002': {
    zip: '41002',
    city: 'Augusta',
    state: 'KY',
    latitude: '38.770754',
    longitude: '-84.002867',
    timeZoneId: 'America/New_York',
  },
  '41003': {
    zip: '41003',
    city: 'Berry',
    state: 'KY',
    latitude: '38.358271',
    longitude: '-84.280361',
    timeZoneId: 'America/New_York',
  },
  '41004': {
    zip: '41004',
    city: 'Brooksville',
    state: 'KY',
    latitude: '38.651705',
    longitude: '-84.095395',
    timeZoneId: 'America/New_York',
  },
  '41005': {
    zip: '41005',
    city: 'Burlington',
    state: 'KY',
    latitude: '39.024812',
    longitude: '-84.747372',
    timeZoneId: 'America/New_York',
  },
  '41006': {
    zip: '41006',
    city: 'Butler',
    state: 'KY',
    latitude: '38.791002',
    longitude: '-84.32156',
    timeZoneId: 'America/New_York',
  },
  '41007': {
    zip: '41007',
    city: 'California',
    state: 'KY',
    latitude: '38.894989',
    longitude: '-84.294749',
    timeZoneId: 'America/New_York',
  },
  '41008': {
    zip: '41008',
    city: 'Carrollton',
    state: 'KY',
    latitude: '38.677051',
    longitude: '-85.169553',
    timeZoneId: 'America/New_York',
  },
  '41010': {
    zip: '41010',
    city: 'Corinth',
    state: 'KY',
    latitude: '38.539046',
    longitude: '-84.590182',
    timeZoneId: 'America/New_York',
  },
  '41011': {
    zip: '41011',
    city: 'Covington',
    state: 'KY',
    latitude: '39.068663',
    longitude: '-84.529476',
    timeZoneId: 'America/New_York',
  },
  '41012': {
    zip: '41012',
    city: 'Covington',
    state: 'KY',
    latitude: '39.068847',
    longitude: '-84.53293',
    timeZoneId: 'America/New_York',
  },
  '41014': {
    zip: '41014',
    city: 'Covington',
    state: 'KY',
    latitude: '39.063763',
    longitude: '-84.506679',
    timeZoneId: 'America/New_York',
  },
  '41015': {
    zip: '41015',
    city: 'Latonia',
    state: 'KY',
    latitude: '38.95092',
    longitude: '-84.48438',
    timeZoneId: 'America/New_York',
  },
  '41016': {
    zip: '41016',
    city: 'Covington',
    state: 'KY',
    latitude: '39.084954',
    longitude: '-84.549188',
    timeZoneId: 'America/New_York',
  },
  '41017': {
    zip: '41017',
    city: 'Ft Mitchell',
    state: 'KY',
    latitude: '39.034253',
    longitude: '-84.574476',
    timeZoneId: 'America/New_York',
  },
  '41018': {
    zip: '41018',
    city: 'Erlanger',
    state: 'KY',
    latitude: '39.009027',
    longitude: '-84.598071',
    timeZoneId: 'America/New_York',
  },
  '41019': {
    zip: '41019',
    city: 'Covington',
    state: 'KY',
    latitude: '39.069636',
    longitude: '-84.529815',
    timeZoneId: 'America/New_York',
  },
  '41021': {
    zip: '41021',
    city: 'Hebron',
    state: 'KY',
    latitude: '39.066147',
    longitude: '-84.703189',
    timeZoneId: 'America/New_York',
  },
  '41022': {
    zip: '41022',
    city: 'Florence',
    state: 'KY',
    latitude: '38.989761',
    longitude: '-84.642819',
    timeZoneId: 'America/New_York',
  },
  '41025': {
    zip: '41025',
    city: 'Erlanger',
    state: 'KY',
    latitude: '39.066147',
    longitude: '-84.703189',
    timeZoneId: 'America/New_York',
  },
  '41030': {
    zip: '41030',
    city: 'Crittenden',
    state: 'KY',
    latitude: '38.769713',
    longitude: '-84.573036',
    timeZoneId: 'America/New_York',
  },
  '41031': {
    zip: '41031',
    city: 'Cynthiana',
    state: 'KY',
    latitude: '38.391151',
    longitude: '-84.294101',
    timeZoneId: 'America/New_York',
  },
  '41033': {
    zip: '41033',
    city: 'De Mossville',
    state: 'KY',
    latitude: '38.749457',
    longitude: '-84.473187',
    timeZoneId: 'America/New_York',
  },
  '41034': {
    zip: '41034',
    city: 'Dover',
    state: 'KY',
    latitude: '38.636219',
    longitude: '-83.789157',
    timeZoneId: 'America/New_York',
  },
  '41035': {
    zip: '41035',
    city: 'Dry Ridge',
    state: 'KY',
    latitude: '38.707527',
    longitude: '-84.655201',
    timeZoneId: 'America/New_York',
  },
  '41037': {
    zip: '41037',
    city: 'Elizaville',
    state: 'KY',
    latitude: '38.419974',
    longitude: '-83.824136',
    timeZoneId: 'America/New_York',
  },
  '41039': {
    zip: '41039',
    city: 'Ewing',
    state: 'KY',
    latitude: '38.43306',
    longitude: '-83.85174',
    timeZoneId: 'America/New_York',
  },
  '41040': {
    zip: '41040',
    city: 'Falmouth',
    state: 'KY',
    latitude: '38.67873',
    longitude: '-84.333508',
    timeZoneId: 'America/New_York',
  },
  '41041': {
    zip: '41041',
    city: 'Flemingsburg',
    state: 'KY',
    latitude: '38.41854',
    longitude: '-83.68812',
    timeZoneId: 'America/New_York',
  },
  '41042': {
    zip: '41042',
    city: 'Florence',
    state: 'KY',
    latitude: '38.986125',
    longitude: '-84.648281',
    timeZoneId: 'America/New_York',
  },
  '41043': {
    zip: '41043',
    city: 'Foster',
    state: 'KY',
    latitude: '38.749906',
    longitude: '-84.151904',
    timeZoneId: 'America/New_York',
  },
  '41044': {
    zip: '41044',
    city: 'Germantown',
    state: 'KY',
    latitude: '38.624615',
    longitude: '-83.978515',
    timeZoneId: 'America/New_York',
  },
  '41045': {
    zip: '41045',
    city: 'Ghent',
    state: 'KY',
    latitude: '38.738171',
    longitude: '-85.060813',
    timeZoneId: 'America/New_York',
  },
  '41046': {
    zip: '41046',
    city: 'Glencoe',
    state: 'KY',
    latitude: '38.713339',
    longitude: '-84.799484',
    timeZoneId: 'America/New_York',
  },
  '41048': {
    zip: '41048',
    city: 'Hebron',
    state: 'KY',
    latitude: '39.07302',
    longitude: '-84.71316',
    timeZoneId: 'America/New_York',
  },
  '41049': {
    zip: '41049',
    city: 'Hillsboro',
    state: 'KY',
    latitude: '38.285556',
    longitude: '-83.678852',
    timeZoneId: 'America/New_York',
  },
  '41051': {
    zip: '41051',
    city: 'Independence',
    state: 'KY',
    latitude: '38.941955',
    longitude: '-84.552352',
    timeZoneId: 'America/New_York',
  },
  '41052': {
    zip: '41052',
    city: 'Jonesville',
    state: 'KY',
    latitude: '38.66169',
    longitude: '-84.772325',
    timeZoneId: 'America/New_York',
  },
  '41053': {
    zip: '41053',
    city: 'Kenton',
    state: 'KY',
    latitude: '38.867056',
    longitude: '-84.458582',
    timeZoneId: 'America/New_York',
  },
  '41054': {
    zip: '41054',
    city: 'Mason',
    state: 'KY',
    latitude: '38.578368',
    longitude: '-84.584337',
    timeZoneId: 'America/New_York',
  },
  '41055': {
    zip: '41055',
    city: 'Mayslick',
    state: 'KY',
    latitude: '38.530049',
    longitude: '-83.881279',
    timeZoneId: 'America/New_York',
  },
  '41056': {
    zip: '41056',
    city: 'Maysville',
    state: 'KY',
    latitude: '38.59602',
    longitude: '-83.78112',
    timeZoneId: 'America/New_York',
  },
  '41059': {
    zip: '41059',
    city: 'Melbourne',
    state: 'KY',
    latitude: '39.0183',
    longitude: '-84.36048',
    timeZoneId: 'America/New_York',
  },
  '41061': {
    zip: '41061',
    city: 'Milford',
    state: 'KY',
    latitude: '38.582646',
    longitude: '-84.15486',
    timeZoneId: 'America/New_York',
  },
  '41062': {
    zip: '41062',
    city: 'Minerva',
    state: 'KY',
    latitude: '38.703777',
    longitude: '-83.917172',
    timeZoneId: 'America/New_York',
  },
  '41063': {
    zip: '41063',
    city: 'Morning View',
    state: 'KY',
    latitude: '38.84226',
    longitude: '-84.52266',
    timeZoneId: 'America/New_York',
  },
  '41064': {
    zip: '41064',
    city: 'Mount Olivet',
    state: 'KY',
    latitude: '38.532132',
    longitude: '-84.039003',
    timeZoneId: 'America/New_York',
  },
  '41071': {
    zip: '41071',
    city: 'Newport',
    state: 'KY',
    latitude: '39.082844',
    longitude: '-84.482628',
    timeZoneId: 'America/New_York',
  },
  '41072': {
    zip: '41072',
    city: 'Newport',
    state: 'KY',
    latitude: '39.079577',
    longitude: '-84.480339',
    timeZoneId: 'America/New_York',
  },
  '41073': {
    zip: '41073',
    city: 'Bellevue',
    state: 'KY',
    latitude: '39.101923',
    longitude: '-84.48201',
    timeZoneId: 'America/New_York',
  },
  '41074': {
    zip: '41074',
    city: 'Dayton',
    state: 'KY',
    latitude: '39.11038',
    longitude: '-84.468431',
    timeZoneId: 'America/New_York',
  },
  '41075': {
    zip: '41075',
    city: 'Fort Thomas',
    state: 'KY',
    latitude: '39.091389',
    longitude: '-84.495833',
    timeZoneId: 'America/New_York',
  },
  '41076': {
    zip: '41076',
    city: 'Newport',
    state: 'KY',
    latitude: '39.02022',
    longitude: '-84.44016',
    timeZoneId: 'America/New_York',
  },
  '41080': {
    zip: '41080',
    city: 'Petersburg',
    state: 'KY',
    latitude: '39.042442',
    longitude: '-84.84138',
    timeZoneId: 'America/New_York',
  },
  '41081': {
    zip: '41081',
    city: 'Plummers Landing',
    state: 'KY',
    latitude: '38.304959',
    longitude: '-83.569924',
    timeZoneId: 'America/New_York',
  },
  '41083': {
    zip: '41083',
    city: 'Sanders',
    state: 'KY',
    latitude: '38.664881',
    longitude: '-84.996614',
    timeZoneId: 'America/New_York',
  },
  '41085': {
    zip: '41085',
    city: 'Silver Grove',
    state: 'KY',
    latitude: '39.032105',
    longitude: '-84.387375',
    timeZoneId: 'America/New_York',
  },
  '41086': {
    zip: '41086',
    city: 'Sparta',
    state: 'KY',
    latitude: '38.722967',
    longitude: '-84.887273',
    timeZoneId: 'America/New_York',
  },
  '41091': {
    zip: '41091',
    city: 'Union',
    state: 'KY',
    latitude: '38.9277',
    longitude: '-84.71754',
    timeZoneId: 'America/New_York',
  },
  '41092': {
    zip: '41092',
    city: 'Verona',
    state: 'KY',
    latitude: '38.825633',
    longitude: '-84.678381',
    timeZoneId: 'America/New_York',
  },
  '41093': {
    zip: '41093',
    city: 'Wallingford',
    state: 'KY',
    latitude: '38.341084',
    longitude: '-83.555814',
    timeZoneId: 'America/New_York',
  },
  '41094': {
    zip: '41094',
    city: 'Walton',
    state: 'KY',
    latitude: '38.884714',
    longitude: '-84.634472',
    timeZoneId: 'America/New_York',
  },
  '41095': {
    zip: '41095',
    city: 'Warsaw',
    state: 'KY',
    latitude: '38.78501',
    longitude: '-84.902852',
    timeZoneId: 'America/New_York',
  },
  '41096': {
    zip: '41096',
    city: 'Washington',
    state: 'KY',
    latitude: '38.615854',
    longitude: '-83.806165',
    timeZoneId: 'America/New_York',
  },
  '41097': {
    zip: '41097',
    city: 'Williamstown',
    state: 'KY',
    latitude: '38.608858',
    longitude: '-84.580873',
    timeZoneId: 'America/New_York',
  },
  '41098': {
    zip: '41098',
    city: 'Worthville',
    state: 'KY',
    latitude: '38.635167',
    longitude: '-85.059062',
    timeZoneId: 'America/New_York',
  },
  '41099': {
    zip: '41099',
    city: 'Newport',
    state: 'KY',
    latitude: '39.032312',
    longitude: '-84.463361',
    timeZoneId: 'America/New_York',
  },
  '41101': {
    zip: '41101',
    city: 'Ashland',
    state: 'KY',
    latitude: '38.459835',
    longitude: '-82.661314',
    timeZoneId: 'America/New_York',
  },
  '41102': {
    zip: '41102',
    city: 'Ashland',
    state: 'KY',
    latitude: '38.441408',
    longitude: '-82.688442',
    timeZoneId: 'America/New_York',
  },
  '41105': {
    zip: '41105',
    city: 'Ashland',
    state: 'KY',
    latitude: '38.430686',
    longitude: '-82.66744',
    timeZoneId: 'America/New_York',
  },
  '41114': {
    zip: '41114',
    city: 'Ashland',
    state: 'KY',
    latitude: '38.461423',
    longitude: '-82.647651',
    timeZoneId: 'America/New_York',
  },
  '41121': {
    zip: '41121',
    city: 'Argillite',
    state: 'KY',
    latitude: '38.511728',
    longitude: '-82.873744',
    timeZoneId: 'America/New_York',
  },
  '41124': {
    zip: '41124',
    city: 'Blaine',
    state: 'KY',
    latitude: '38.035564',
    longitude: '-82.858564',
    timeZoneId: 'America/New_York',
  },
  '41128': {
    zip: '41128',
    city: 'Carter',
    state: 'KY',
    latitude: '38.427399',
    longitude: '-83.119767',
    timeZoneId: 'America/New_York',
  },
  '41129': {
    zip: '41129',
    city: 'Catlettsburg',
    state: 'KY',
    latitude: '38.373377',
    longitude: '-82.628036',
    timeZoneId: 'America/New_York',
  },
  '41132': {
    zip: '41132',
    city: 'Denton',
    state: 'KY',
    latitude: '38.287351',
    longitude: '-82.822444',
    timeZoneId: 'America/New_York',
  },
  '41135': {
    zip: '41135',
    city: 'Emerson',
    state: 'KY',
    latitude: '38.380556',
    longitude: '-83.260136',
    timeZoneId: 'America/New_York',
  },
  '41139': {
    zip: '41139',
    city: 'Flatwoods',
    state: 'KY',
    latitude: '38.520483',
    longitude: '-82.721857',
    timeZoneId: 'America/New_York',
  },
  '41141': {
    zip: '41141',
    city: 'Garrison',
    state: 'KY',
    latitude: '38.551771',
    longitude: '-83.193288',
    timeZoneId: 'America/New_York',
  },
  '41142': {
    zip: '41142',
    city: 'Grahn',
    state: 'KY',
    latitude: '38.283826',
    longitude: '-83.075594',
    timeZoneId: 'America/New_York',
  },
  '41143': {
    zip: '41143',
    city: 'Grayson',
    state: 'KY',
    latitude: '38.329825',
    longitude: '-82.936538',
    timeZoneId: 'America/New_York',
  },
  '41144': {
    zip: '41144',
    city: 'Greenup',
    state: 'KY',
    latitude: '38.572275',
    longitude: '-82.812737',
    timeZoneId: 'America/New_York',
  },
  '41146': {
    zip: '41146',
    city: 'Hitchins',
    state: 'KY',
    latitude: '38.236656',
    longitude: '-82.919076',
    timeZoneId: 'America/New_York',
  },
  '41149': {
    zip: '41149',
    city: 'Isonville',
    state: 'KY',
    latitude: '38.06247',
    longitude: '-83.038908',
    timeZoneId: 'America/New_York',
  },
  '41159': {
    zip: '41159',
    city: 'Martha',
    state: 'KY',
    latitude: '38.011172',
    longitude: '-82.957727',
    timeZoneId: 'America/New_York',
  },
  '41160': {
    zip: '41160',
    city: 'Mazie',
    state: 'KY',
    latitude: '38.033886',
    longitude: '-82.85851',
    timeZoneId: 'America/New_York',
  },
  '41164': {
    zip: '41164',
    city: 'Olive Hill',
    state: 'KY',
    latitude: '38.305124',
    longitude: '-83.169523',
    timeZoneId: 'America/New_York',
  },
  '41166': {
    zip: '41166',
    city: 'Quincy',
    state: 'KY',
    latitude: '38.631473',
    longitude: '-83.095046',
    timeZoneId: 'America/New_York',
  },
  '41168': {
    zip: '41168',
    city: 'Rush',
    state: 'KY',
    latitude: '38.30832',
    longitude: '-82.7463',
    timeZoneId: 'America/New_York',
  },
  '41169': {
    zip: '41169',
    city: 'Russell',
    state: 'KY',
    latitude: '38.52006',
    longitude: '-82.72416',
    timeZoneId: 'America/New_York',
  },
  '41171': {
    zip: '41171',
    city: 'Sandy Hook',
    state: 'KY',
    latitude: '38.067411',
    longitude: '-83.220393',
    timeZoneId: 'America/New_York',
  },
  '41173': {
    zip: '41173',
    city: 'Soldier',
    state: 'KY',
    latitude: '38.260114',
    longitude: '-83.299489',
    timeZoneId: 'America/New_York',
  },
  '41174': {
    zip: '41174',
    city: 'South Portsmouth',
    state: 'KY',
    latitude: '38.706344',
    longitude: '-83.011058',
    timeZoneId: 'America/New_York',
  },
  '41175': {
    zip: '41175',
    city: 'South Shore',
    state: 'KY',
    latitude: '38.720129',
    longitude: '-82.953687',
    timeZoneId: 'America/New_York',
  },
  '41179': {
    zip: '41179',
    city: 'Vanceburg',
    state: 'KY',
    latitude: '38.594885',
    longitude: '-83.318316',
    timeZoneId: 'America/New_York',
  },
  '41180': {
    zip: '41180',
    city: 'Webbville',
    state: 'KY',
    latitude: '38.152071',
    longitude: '-82.793657',
    timeZoneId: 'America/New_York',
  },
  '41181': {
    zip: '41181',
    city: 'Willard',
    state: 'KY',
    latitude: '38.213094',
    longitude: '-82.899909',
    timeZoneId: 'America/New_York',
  },
  '41183': {
    zip: '41183',
    city: 'Worthington',
    state: 'KY',
    latitude: '38.550451',
    longitude: '-82.732082',
    timeZoneId: 'America/New_York',
  },
  '41189': {
    zip: '41189',
    city: 'Tollesboro',
    state: 'KY',
    latitude: '38.586965',
    longitude: '-83.548106',
    timeZoneId: 'America/New_York',
  },
  '41201': {
    zip: '41201',
    city: 'Adams',
    state: 'KY',
    latitude: '38.00119',
    longitude: '-82.716339',
    timeZoneId: 'America/New_York',
  },
  '41203': {
    zip: '41203',
    city: 'Beauty',
    state: 'KY',
    latitude: '37.758275',
    longitude: '-82.623531',
    timeZoneId: 'America/New_York',
  },
  '41204': {
    zip: '41204',
    city: 'Boons Camp',
    state: 'KY',
    latitude: '37.816767',
    longitude: '-82.674355',
    timeZoneId: 'America/New_York',
  },
  '41214': {
    zip: '41214',
    city: 'Debord',
    state: 'KY',
    latitude: '37.798056',
    longitude: '-82.581944',
    timeZoneId: 'America/New_York',
  },
  '41216': {
    zip: '41216',
    city: 'East Point',
    state: 'KY',
    latitude: '37.709857',
    longitude: '-82.83499',
    timeZoneId: 'America/New_York',
  },
  '41219': {
    zip: '41219',
    city: 'Flatgap',
    state: 'KY',
    latitude: '37.9474',
    longitude: '-82.887743',
    timeZoneId: 'America/New_York',
  },
  '41222': {
    zip: '41222',
    city: 'Hagerhill',
    state: 'KY',
    latitude: '37.79535',
    longitude: '-82.819772',
    timeZoneId: 'America/New_York',
  },
  '41224': {
    zip: '41224',
    city: 'Inez',
    state: 'KY',
    latitude: '37.86456',
    longitude: '-82.514588',
    timeZoneId: 'America/New_York',
  },
  '41226': {
    zip: '41226',
    city: 'Keaton',
    state: 'KY',
    latitude: '37.971849',
    longitude: '-82.950663',
    timeZoneId: 'America/New_York',
  },
  '41230': {
    zip: '41230',
    city: 'Louisa',
    state: 'KY',
    latitude: '38.10954',
    longitude: '-82.62684',
    timeZoneId: 'America/New_York',
  },
  '41231': {
    zip: '41231',
    city: 'Lovely',
    state: 'KY',
    latitude: '37.781435',
    longitude: '-82.3625',
    timeZoneId: 'America/New_York',
  },
  '41232': {
    zip: '41232',
    city: 'Lowmansville',
    state: 'KY',
    latitude: '38.000588',
    longitude: '-82.715329',
    timeZoneId: 'America/New_York',
  },
  '41234': {
    zip: '41234',
    city: 'Meally',
    state: 'KY',
    latitude: '37.79952',
    longitude: '-82.732821',
    timeZoneId: 'America/New_York',
  },
  '41238': {
    zip: '41238',
    city: 'Oil Springs',
    state: 'KY',
    latitude: '37.842864',
    longitude: '-82.940341',
    timeZoneId: 'America/New_York',
  },
  '41240': {
    zip: '41240',
    city: 'Paintsville',
    state: 'KY',
    latitude: '37.809028',
    longitude: '-82.802236',
    timeZoneId: 'America/New_York',
  },
  '41250': {
    zip: '41250',
    city: 'Pilgrim',
    state: 'KY',
    latitude: '37.7075',
    longitude: '-82.432778',
    timeZoneId: 'America/New_York',
  },
  '41254': {
    zip: '41254',
    city: 'River',
    state: 'KY',
    latitude: '37.874684',
    longitude: '-82.660393',
    timeZoneId: 'America/New_York',
  },
  '41255': {
    zip: '41255',
    city: 'Sitka',
    state: 'KY',
    latitude: '37.886311',
    longitude: '-82.846592',
    timeZoneId: 'America/New_York',
  },
  '41256': {
    zip: '41256',
    city: 'Staffordsville',
    state: 'KY',
    latitude: '37.836517',
    longitude: '-82.861342',
    timeZoneId: 'America/New_York',
  },
  '41257': {
    zip: '41257',
    city: 'Stambaugh',
    state: 'KY',
    latitude: '37.841603',
    longitude: '-82.762181',
    timeZoneId: 'America/New_York',
  },
  '41260': {
    zip: '41260',
    city: 'Thelma',
    state: 'KY',
    latitude: '37.803373',
    longitude: '-82.771002',
    timeZoneId: 'America/New_York',
  },
  '41262': {
    zip: '41262',
    city: 'Tomahawk',
    state: 'KY',
    latitude: '37.82712',
    longitude: '-82.62282',
    timeZoneId: 'America/New_York',
  },
  '41263': {
    zip: '41263',
    city: 'Tutor Key',
    state: 'KY',
    latitude: '37.857529',
    longitude: '-82.737476',
    timeZoneId: 'America/New_York',
  },
  '41264': {
    zip: '41264',
    city: 'Ulysses',
    state: 'KY',
    latitude: '38.000051',
    longitude: '-82.7147',
    timeZoneId: 'America/New_York',
  },
  '41265': {
    zip: '41265',
    city: 'Van Lear',
    state: 'KY',
    latitude: '37.698773',
    longitude: '-82.687184',
    timeZoneId: 'America/New_York',
  },
  '41267': {
    zip: '41267',
    city: 'Warfield',
    state: 'KY',
    latitude: '37.854',
    longitude: '-82.43502',
    timeZoneId: 'America/New_York',
  },
  '41268': {
    zip: '41268',
    city: 'West Van Lear',
    state: 'KY',
    latitude: '37.81224',
    longitude: '-82.805599',
    timeZoneId: 'America/New_York',
  },
  '41271': {
    zip: '41271',
    city: 'Williamsport',
    state: 'KY',
    latitude: '37.815261',
    longitude: '-82.713247',
    timeZoneId: 'America/New_York',
  },
  '41274': {
    zip: '41274',
    city: 'Wittensville',
    state: 'KY',
    latitude: '37.874106',
    longitude: '-82.813925',
    timeZoneId: 'America/New_York',
  },
  '41301': {
    zip: '41301',
    city: 'Campton',
    state: 'KY',
    latitude: '37.742837',
    longitude: '-83.449664',
    timeZoneId: 'America/New_York',
  },
  '41310': {
    zip: '41310',
    city: 'Bays',
    state: 'KY',
    latitude: '37.622355',
    longitude: '-83.214006',
    timeZoneId: 'America/New_York',
  },
  '41311': {
    zip: '41311',
    city: 'Beattyville',
    state: 'KY',
    latitude: '37.578443',
    longitude: '-83.708123',
    timeZoneId: 'America/New_York',
  },
  '41314': {
    zip: '41314',
    city: 'Booneville',
    state: 'KY',
    latitude: '37.480497',
    longitude: '-83.664385',
    timeZoneId: 'America/New_York',
  },
  '41317': {
    zip: '41317',
    city: 'Clayhole',
    state: 'KY',
    latitude: '37.465096',
    longitude: '-83.23186',
    timeZoneId: 'America/New_York',
  },
  '41332': {
    zip: '41332',
    city: 'Hazel Green',
    state: 'KY',
    latitude: '37.796026',
    longitude: '-83.456314',
    timeZoneId: 'America/New_York',
  },
  '41339': {
    zip: '41339',
    city: 'Jackson',
    state: 'KY',
    latitude: '37.537944',
    longitude: '-83.388665',
    timeZoneId: 'America/New_York',
  },
  '41347': {
    zip: '41347',
    city: 'Lone',
    state: 'KY',
    latitude: '37.532864',
    longitude: '-83.601771',
    timeZoneId: 'America/New_York',
  },
  '41348': {
    zip: '41348',
    city: 'Lost Creek',
    state: 'KY',
    latitude: '37.48',
    longitude: '-83.32',
    timeZoneId: 'America/New_York',
  },
  '41351': {
    zip: '41351',
    city: 'Mistletoe',
    state: 'KY',
    latitude: '37.318317',
    longitude: '-83.581646',
    timeZoneId: 'America/New_York',
  },
  '41352': {
    zip: '41352',
    city: 'Mize',
    state: 'KY',
    latitude: '37.85919',
    longitude: '-83.372286',
    timeZoneId: 'America/New_York',
  },
  '41360': {
    zip: '41360',
    city: 'Pine Ridge',
    state: 'KY',
    latitude: '37.769383',
    longitude: '-83.626315',
    timeZoneId: 'America/New_York',
  },
  '41364': {
    zip: '41364',
    city: 'Ricetown',
    state: 'KY',
    latitude: '37.399975',
    longitude: '-83.60798',
    timeZoneId: 'America/New_York',
  },
  '41365': {
    zip: '41365',
    city: 'Rogers',
    state: 'KY',
    latitude: '37.708373',
    longitude: '-83.629588',
    timeZoneId: 'America/New_York',
  },
  '41366': {
    zip: '41366',
    city: 'Rousseau',
    state: 'KY',
    latitude: '37.601971',
    longitude: '-83.209842',
    timeZoneId: 'America/New_York',
  },
  '41367': {
    zip: '41367',
    city: 'Rowdy',
    state: 'KY',
    latitude: '37.413974',
    longitude: '-83.205249',
    timeZoneId: 'America/New_York',
  },
  '41368': {
    zip: '41368',
    city: 'Saint Helens',
    state: 'KY',
    latitude: '37.579332',
    longitude: '-83.644057',
    timeZoneId: 'America/New_York',
  },
  '41385': {
    zip: '41385',
    city: 'Vancleve',
    state: 'KY',
    latitude: '37.628288',
    longitude: '-83.413815',
    timeZoneId: 'America/New_York',
  },
  '41386': {
    zip: '41386',
    city: 'Vincent',
    state: 'KY',
    latitude: '37.450115',
    longitude: '-83.803868',
    timeZoneId: 'America/New_York',
  },
  '41390': {
    zip: '41390',
    city: 'Whick',
    state: 'KY',
    latitude: '37.408235',
    longitude: '-83.372612',
    timeZoneId: 'America/New_York',
  },
  '41397': {
    zip: '41397',
    city: 'Zoe',
    state: 'KY',
    latitude: '37.656853',
    longitude: '-83.670861',
    timeZoneId: 'America/New_York',
  },
  '41408': {
    zip: '41408',
    city: 'Cannel City',
    state: 'KY',
    latitude: '37.790978',
    longitude: '-83.274399',
    timeZoneId: 'America/New_York',
  },
  '41413': {
    zip: '41413',
    city: 'Crockett',
    state: 'KY',
    latitude: '37.987468',
    longitude: '-83.091171',
    timeZoneId: 'America/New_York',
  },
  '41421': {
    zip: '41421',
    city: 'Elkfork',
    state: 'KY',
    latitude: '37.933831',
    longitude: '-83.076852',
    timeZoneId: 'America/New_York',
  },
  '41425': {
    zip: '41425',
    city: 'Ezel',
    state: 'KY',
    latitude: '37.913003',
    longitude: '-83.392966',
    timeZoneId: 'America/New_York',
  },
  '41426': {
    zip: '41426',
    city: 'Falcon',
    state: 'KY',
    latitude: '37.788716',
    longitude: '-83.000043',
    timeZoneId: 'America/New_York',
  },
  '41451': {
    zip: '41451',
    city: 'Malone',
    state: 'KY',
    latitude: '37.873834',
    longitude: '-83.258714',
    timeZoneId: 'America/New_York',
  },
  '41464': {
    zip: '41464',
    city: 'Royalton',
    state: 'KY',
    latitude: '37.6625',
    longitude: '-82.963889',
    timeZoneId: 'America/New_York',
  },
  '41465': {
    zip: '41465',
    city: 'Salyersville',
    state: 'KY',
    latitude: '37.755467',
    longitude: '-83.046508',
    timeZoneId: 'America/New_York',
  },
  '41472': {
    zip: '41472',
    city: 'West Liberty',
    state: 'KY',
    latitude: '37.970737',
    longitude: '-83.27869',
    timeZoneId: 'America/New_York',
  },
  '41477': {
    zip: '41477',
    city: 'Wrigley',
    state: 'KY',
    latitude: '37.98626',
    longitude: '-83.302388',
    timeZoneId: 'America/New_York',
  },
  '41501': {
    zip: '41501',
    city: 'Pikeville',
    state: 'KY',
    latitude: '37.476931',
    longitude: '-82.513726',
    timeZoneId: 'America/New_York',
  },
  '41502': {
    zip: '41502',
    city: 'Pikeville',
    state: 'KY',
    latitude: '37.479655',
    longitude: '-82.518774',
    timeZoneId: 'America/New_York',
  },
  '41503': {
    zip: '41503',
    city: 'South Williamson',
    state: 'KY',
    latitude: '37.670901',
    longitude: '-82.287453',
    timeZoneId: 'America/New_York',
  },
  '41512': {
    zip: '41512',
    city: 'Ashcamp',
    state: 'KY',
    latitude: '37.256847',
    longitude: '-82.473464',
    timeZoneId: 'America/New_York',
  },
  '41513': {
    zip: '41513',
    city: 'Belcher',
    state: 'KY',
    latitude: '37.300665',
    longitude: '-82.348713',
    timeZoneId: 'America/New_York',
  },
  '41514': {
    zip: '41514',
    city: 'Belfry',
    state: 'KY',
    latitude: '37.639594',
    longitude: '-82.258022',
    timeZoneId: 'America/New_York',
  },
  '41517': {
    zip: '41517',
    city: 'Burdine',
    state: 'KY',
    latitude: '37.188924',
    longitude: '-82.606072',
    timeZoneId: 'America/New_York',
  },
  '41519': {
    zip: '41519',
    city: 'Canada',
    state: 'KY',
    latitude: '37.598218',
    longitude: '-82.323703',
    timeZoneId: 'America/New_York',
  },
  '41520': {
    zip: '41520',
    city: 'Dorton',
    state: 'KY',
    latitude: '37.276554',
    longitude: '-82.581764',
    timeZoneId: 'America/New_York',
  },
  '41522': {
    zip: '41522',
    city: 'Elkhorn City',
    state: 'KY',
    latitude: '37.29954',
    longitude: '-82.35516',
    timeZoneId: 'America/New_York',
  },
  '41524': {
    zip: '41524',
    city: 'Fedscreek',
    state: 'KY',
    latitude: '37.36878',
    longitude: '-82.2621',
    timeZoneId: 'America/New_York',
  },
  '41526': {
    zip: '41526',
    city: 'Fords Branch',
    state: 'KY',
    latitude: '37.535969',
    longitude: '-82.510334',
    timeZoneId: 'America/New_York',
  },
  '41527': {
    zip: '41527',
    city: 'Forest Hills',
    state: 'KY',
    latitude: '37.625841',
    longitude: '-82.303733',
    timeZoneId: 'America/New_York',
  },
  '41528': {
    zip: '41528',
    city: 'Freeburn',
    state: 'KY',
    latitude: '37.543483',
    longitude: '-82.13564',
    timeZoneId: 'America/New_York',
  },
  '41531': {
    zip: '41531',
    city: 'Hardy',
    state: 'KY',
    latitude: '37.611104',
    longitude: '-82.250507',
    timeZoneId: 'America/New_York',
  },
  '41534': {
    zip: '41534',
    city: 'Hellier',
    state: 'KY',
    latitude: '37.286063',
    longitude: '-82.47327',
    timeZoneId: 'America/New_York',
  },
  '41535': {
    zip: '41535',
    city: 'Huddy',
    state: 'KY',
    latitude: '37.579269',
    longitude: '-82.253429',
    timeZoneId: 'America/New_York',
  },
  '41537': {
    zip: '41537',
    city: 'Jenkins',
    state: 'KY',
    latitude: '37.17618',
    longitude: '-82.64178',
    timeZoneId: 'America/New_York',
  },
  '41538': {
    zip: '41538',
    city: 'Jonancy',
    state: 'KY',
    latitude: '37.317544',
    longitude: '-82.581457',
    timeZoneId: 'America/New_York',
  },
  '41539': {
    zip: '41539',
    city: 'Kimper',
    state: 'KY',
    latitude: '37.498827',
    longitude: '-82.312244',
    timeZoneId: 'America/New_York',
  },
  '41540': {
    zip: '41540',
    city: 'Lick Creek',
    state: 'KY',
    latitude: '37.369441',
    longitude: '-82.297026',
    timeZoneId: 'America/New_York',
  },
  '41542': {
    zip: '41542',
    city: 'Lookout',
    state: 'KY',
    latitude: '37.315919',
    longitude: '-82.470787',
    timeZoneId: 'America/New_York',
  },
  '41543': {
    zip: '41543',
    city: 'Mc Andrews',
    state: 'KY',
    latitude: '37.537673',
    longitude: '-82.262326',
    timeZoneId: 'America/New_York',
  },
  '41544': {
    zip: '41544',
    city: 'Mc Carr',
    state: 'KY',
    latitude: '37.594087',
    longitude: '-82.167909',
    timeZoneId: 'America/New_York',
  },
  '41547': {
    zip: '41547',
    city: 'Majestic',
    state: 'KY',
    latitude: '37.542213',
    longitude: '-82.094738',
    timeZoneId: 'America/New_York',
  },
  '41548': {
    zip: '41548',
    city: 'Mouthcard',
    state: 'KY',
    latitude: '37.374131',
    longitude: '-82.287581',
    timeZoneId: 'America/New_York',
  },
  '41549': {
    zip: '41549',
    city: 'Myra',
    state: 'KY',
    latitude: '37.293501',
    longitude: '-82.590206',
    timeZoneId: 'America/New_York',
  },
  '41553': {
    zip: '41553',
    city: 'Phelps',
    state: 'KY',
    latitude: '37.492791',
    longitude: '-82.167535',
    timeZoneId: 'America/New_York',
  },
  '41554': {
    zip: '41554',
    city: 'Phyllis',
    state: 'KY',
    latitude: '37.466033',
    longitude: '-82.342894',
    timeZoneId: 'America/New_York',
  },
  '41555': {
    zip: '41555',
    city: 'Pinsonfork',
    state: 'KY',
    latitude: '37.556989',
    longitude: '-82.192577',
    timeZoneId: 'America/New_York',
  },
  '41557': {
    zip: '41557',
    city: 'Raccoon',
    state: 'KY',
    latitude: '37.498447',
    longitude: '-82.428405',
    timeZoneId: 'America/New_York',
  },
  '41558': {
    zip: '41558',
    city: 'Ransom',
    state: 'KY',
    latitude: '37.59368',
    longitude: '-82.170026',
    timeZoneId: 'America/New_York',
  },
  '41559': {
    zip: '41559',
    city: 'Regina',
    state: 'KY',
    latitude: '37.359629',
    longitude: '-82.372947',
    timeZoneId: 'America/New_York',
  },
  '41560': {
    zip: '41560',
    city: 'Robinson Creek',
    state: 'KY',
    latitude: '37.328847',
    longitude: '-82.570316',
    timeZoneId: 'America/New_York',
  },
  '41561': {
    zip: '41561',
    city: 'Rockhouse',
    state: 'KY',
    latitude: '37.326911',
    longitude: '-82.453377',
    timeZoneId: 'America/New_York',
  },
  '41562': {
    zip: '41562',
    city: 'Shelbiana',
    state: 'KY',
    latitude: '37.39326',
    longitude: '-82.4712',
    timeZoneId: 'America/New_York',
  },
  '41563': {
    zip: '41563',
    city: 'Shelby Gap',
    state: 'KY',
    latitude: '37.235378',
    longitude: '-82.568966',
    timeZoneId: 'America/New_York',
  },
  '41564': {
    zip: '41564',
    city: 'Sidney',
    state: 'KY',
    latitude: '37.615627',
    longitude: '-82.377097',
    timeZoneId: 'America/New_York',
  },
  '41566': {
    zip: '41566',
    city: 'Steele',
    state: 'KY',
    latitude: '37.400823',
    longitude: '-82.203687',
    timeZoneId: 'America/New_York',
  },
  '41567': {
    zip: '41567',
    city: 'Stone',
    state: 'KY',
    latitude: '37.563307',
    longitude: '-82.289864',
    timeZoneId: 'America/New_York',
  },
  '41568': {
    zip: '41568',
    city: 'Stopover',
    state: 'KY',
    latitude: '37.51938',
    longitude: '-82.0545',
    timeZoneId: 'America/New_York',
  },
  '41571': {
    zip: '41571',
    city: 'Varney',
    state: 'KY',
    latitude: '37.609843',
    longitude: '-82.418289',
    timeZoneId: 'America/New_York',
  },
  '41572': {
    zip: '41572',
    city: 'Virgie',
    state: 'KY',
    latitude: '37.34112',
    longitude: '-82.6506',
    timeZoneId: 'America/New_York',
  },
  '41601': {
    zip: '41601',
    city: 'Allen',
    state: 'KY',
    latitude: '37.657284',
    longitude: '-82.701808',
    timeZoneId: 'America/New_York',
  },
  '41602': {
    zip: '41602',
    city: 'Auxier',
    state: 'KY',
    latitude: '37.736769',
    longitude: '-82.762448',
    timeZoneId: 'America/New_York',
  },
  '41603': {
    zip: '41603',
    city: 'Banner',
    state: 'KY',
    latitude: '37.579884',
    longitude: '-82.700311',
    timeZoneId: 'America/New_York',
  },
  '41604': {
    zip: '41604',
    city: 'Beaver',
    state: 'KY',
    latitude: '37.369321',
    longitude: '-82.67059',
    timeZoneId: 'America/New_York',
  },
  '41605': {
    zip: '41605',
    city: 'Betsy Layne',
    state: 'KY',
    latitude: '37.542142',
    longitude: '-82.692771',
    timeZoneId: 'America/New_York',
  },
  '41606': {
    zip: '41606',
    city: 'Bevinsville',
    state: 'KY',
    latitude: '37.335525',
    longitude: '-82.715205',
    timeZoneId: 'America/New_York',
  },
  '41607': {
    zip: '41607',
    city: 'Blue River',
    state: 'KY',
    latitude: '37.640057',
    longitude: '-82.832048',
    timeZoneId: 'America/New_York',
  },
  '41612': {
    zip: '41612',
    city: 'Bypro',
    state: 'KY',
    latitude: '37.305683',
    longitude: '-82.701844',
    timeZoneId: 'America/New_York',
  },
  '41615': {
    zip: '41615',
    city: 'Dana',
    state: 'KY',
    latitude: '37.554142',
    longitude: '-82.676693',
    timeZoneId: 'America/New_York',
  },
  '41616': {
    zip: '41616',
    city: 'David',
    state: 'KY',
    latitude: '37.611701',
    longitude: '-82.89402',
    timeZoneId: 'America/New_York',
  },
  '41619': {
    zip: '41619',
    city: 'Drift',
    state: 'KY',
    latitude: '37.481429',
    longitude: '-82.74908',
    timeZoneId: 'America/New_York',
  },
  '41621': {
    zip: '41621',
    city: 'Dwale',
    state: 'KY',
    latitude: '37.62352',
    longitude: '-82.725022',
    timeZoneId: 'America/New_York',
  },
  '41622': {
    zip: '41622',
    city: 'Eastern',
    state: 'KY',
    latitude: '37.525559',
    longitude: '-82.82376',
    timeZoneId: 'America/New_York',
  },
  '41630': {
    zip: '41630',
    city: 'Garrett',
    state: 'KY',
    latitude: '37.483546',
    longitude: '-82.856443',
    timeZoneId: 'America/New_York',
  },
  '41631': {
    zip: '41631',
    city: 'Grethel',
    state: 'KY',
    latitude: '37.45543',
    longitude: '-82.735217',
    timeZoneId: 'America/New_York',
  },
  '41632': {
    zip: '41632',
    city: 'Gunlock',
    state: 'KY',
    latitude: '37.550571',
    longitude: '-82.94742',
    timeZoneId: 'America/New_York',
  },
  '41635': {
    zip: '41635',
    city: 'Harold',
    state: 'KY',
    latitude: '37.4928',
    longitude: '-82.617099',
    timeZoneId: 'America/New_York',
  },
  '41636': {
    zip: '41636',
    city: 'Hi Hat',
    state: 'KY',
    latitude: '37.390254',
    longitude: '-82.72905',
    timeZoneId: 'America/New_York',
  },
  '41640': {
    zip: '41640',
    city: 'Hueysville',
    state: 'KY',
    latitude: '37.508219',
    longitude: '-82.83288',
    timeZoneId: 'America/New_York',
  },
  '41642': {
    zip: '41642',
    city: 'Ivel',
    state: 'KY',
    latitude: '37.599644',
    longitude: '-82.66182',
    timeZoneId: 'America/New_York',
  },
  '41643': {
    zip: '41643',
    city: 'Lackey',
    state: 'KY',
    latitude: '37.461984',
    longitude: '-82.796111',
    timeZoneId: 'America/New_York',
  },
  '41645': {
    zip: '41645',
    city: 'Langley',
    state: 'KY',
    latitude: '37.548556',
    longitude: '-82.775409',
    timeZoneId: 'America/New_York',
  },
  '41647': {
    zip: '41647',
    city: 'Mc Dowell',
    state: 'KY',
    latitude: '37.427384',
    longitude: '-82.743993',
    timeZoneId: 'America/New_York',
  },
  '41649': {
    zip: '41649',
    city: 'Martin',
    state: 'KY',
    latitude: '37.545706',
    longitude: '-82.758667',
    timeZoneId: 'America/New_York',
  },
  '41650': {
    zip: '41650',
    city: 'Melvin',
    state: 'KY',
    latitude: '37.348109',
    longitude: '-82.692963',
    timeZoneId: 'America/New_York',
  },
  '41651': {
    zip: '41651',
    city: 'Minnie',
    state: 'KY',
    latitude: '37.479601',
    longitude: '-82.755894',
    timeZoneId: 'America/New_York',
  },
  '41653': {
    zip: '41653',
    city: 'Prestonsburg',
    state: 'KY',
    latitude: '37.665242',
    longitude: '-82.767938',
    timeZoneId: 'America/New_York',
  },
  '41655': {
    zip: '41655',
    city: 'Printer',
    state: 'KY',
    latitude: '37.505207',
    longitude: '-82.707164',
    timeZoneId: 'America/New_York',
  },
  '41659': {
    zip: '41659',
    city: 'Stanville',
    state: 'KY',
    latitude: '37.582237',
    longitude: '-82.673955',
    timeZoneId: 'America/New_York',
  },
  '41660': {
    zip: '41660',
    city: 'Teaberry',
    state: 'KY',
    latitude: '37.422387',
    longitude: '-82.638021',
    timeZoneId: 'America/New_York',
  },
  '41663': {
    zip: '41663',
    city: 'Tram',
    state: 'KY',
    latitude: '37.575173',
    longitude: '-82.646677',
    timeZoneId: 'America/New_York',
  },
  '41666': {
    zip: '41666',
    city: 'Wayland',
    state: 'KY',
    latitude: '37.456944',
    longitude: '-82.818889',
    timeZoneId: 'America/New_York',
  },
  '41667': {
    zip: '41667',
    city: 'Weeksbury',
    state: 'KY',
    latitude: '37.327206',
    longitude: '-82.68752',
    timeZoneId: 'America/New_York',
  },
  '41669': {
    zip: '41669',
    city: 'Wheelwright',
    state: 'KY',
    latitude: '37.334317',
    longitude: '-82.720582',
    timeZoneId: 'America/New_York',
  },
  '41701': {
    zip: '41701',
    city: 'Hazard',
    state: 'KY',
    latitude: '37.253627',
    longitude: '-83.184866',
    timeZoneId: 'America/New_York',
  },
  '41702': {
    zip: '41702',
    city: 'Hazard',
    state: 'KY',
    latitude: '37.249396',
    longitude: '-83.19137',
    timeZoneId: 'America/New_York',
  },
  '41712': {
    zip: '41712',
    city: 'Ary',
    state: 'KY',
    latitude: '37.352774',
    longitude: '-83.137318',
    timeZoneId: 'America/New_York',
  },
  '41713': {
    zip: '41713',
    city: 'Avawam',
    state: 'KY',
    latitude: '37.222725',
    longitude: '-83.280452',
    timeZoneId: 'America/New_York',
  },
  '41714': {
    zip: '41714',
    city: 'Bear Branch',
    state: 'KY',
    latitude: '37.209943',
    longitude: '-83.490621',
    timeZoneId: 'America/New_York',
  },
  '41719': {
    zip: '41719',
    city: 'Bonnyman',
    state: 'KY',
    latitude: '37.309817',
    longitude: '-83.218287',
    timeZoneId: 'America/New_York',
  },
  '41721': {
    zip: '41721',
    city: 'Buckhorn',
    state: 'KY',
    latitude: '37.304153',
    longitude: '-83.492093',
    timeZoneId: 'America/New_York',
  },
  '41722': {
    zip: '41722',
    city: 'Bulan',
    state: 'KY',
    latitude: '37.3',
    longitude: '-83.166667',
    timeZoneId: 'America/New_York',
  },
  '41723': {
    zip: '41723',
    city: 'Busy',
    state: 'KY',
    latitude: '37.269914',
    longitude: '-83.271246',
    timeZoneId: 'America/New_York',
  },
  '41725': {
    zip: '41725',
    city: 'Carrie',
    state: 'KY',
    latitude: '37.308153',
    longitude: '-83.027485',
    timeZoneId: 'America/New_York',
  },
  '41727': {
    zip: '41727',
    city: 'Chavies',
    state: 'KY',
    latitude: '37.361634',
    longitude: '-83.32107',
    timeZoneId: 'America/New_York',
  },
  '41729': {
    zip: '41729',
    city: 'Combs',
    state: 'KY',
    latitude: '37.29569',
    longitude: '-83.200168',
    timeZoneId: 'America/New_York',
  },
  '41731': {
    zip: '41731',
    city: 'Cornettsville',
    state: 'KY',
    latitude: '37.124133',
    longitude: '-83.036516',
    timeZoneId: 'America/New_York',
  },
  '41735': {
    zip: '41735',
    city: 'Delphia',
    state: 'KY',
    latitude: '37.022953',
    longitude: '-83.093658',
    timeZoneId: 'America/New_York',
  },
  '41736': {
    zip: '41736',
    city: 'Dice',
    state: 'KY',
    latitude: '37.377071',
    longitude: '-83.208391',
    timeZoneId: 'America/New_York',
  },
  '41739': {
    zip: '41739',
    city: 'Dwarf',
    state: 'KY',
    latitude: '37.337744',
    longitude: '-83.130217',
    timeZoneId: 'America/New_York',
  },
  '41740': {
    zip: '41740',
    city: 'Emmalena',
    state: 'KY',
    latitude: '37.324933',
    longitude: '-83.06826',
    timeZoneId: 'America/New_York',
  },
  '41743': {
    zip: '41743',
    city: 'Fisty',
    state: 'KY',
    latitude: '37.354985',
    longitude: '-83.104501',
    timeZoneId: 'America/New_York',
  },
  '41745': {
    zip: '41745',
    city: 'Gays Creek',
    state: 'KY',
    latitude: '37.350359',
    longitude: '-83.448518',
    timeZoneId: 'America/New_York',
  },
  '41746': {
    zip: '41746',
    city: 'Happy',
    state: 'KY',
    latitude: '37.202205',
    longitude: '-83.096358',
    timeZoneId: 'America/New_York',
  },
  '41749': {
    zip: '41749',
    city: 'Hyden',
    state: 'KY',
    latitude: '37.164468',
    longitude: '-83.437621',
    timeZoneId: 'America/New_York',
  },
  '41751': {
    zip: '41751',
    city: 'Jeff',
    state: 'KY',
    latitude: '37.206923',
    longitude: '-83.187841',
    timeZoneId: 'America/New_York',
  },
  '41754': {
    zip: '41754',
    city: 'Krypton',
    state: 'KY',
    latitude: '37.313333',
    longitude: '-83.312222',
    timeZoneId: 'America/New_York',
  },
  '41759': {
    zip: '41759',
    city: 'Sassafras',
    state: 'KY',
    latitude: '37.239124',
    longitude: '-83.035289',
    timeZoneId: 'America/New_York',
  },
  '41760': {
    zip: '41760',
    city: 'Scuddy',
    state: 'KY',
    latitude: '37.213406',
    longitude: '-83.091384',
    timeZoneId: 'America/New_York',
  },
  '41762': {
    zip: '41762',
    city: 'Sizerock',
    state: 'KY',
    latitude: '37.164104',
    longitude: '-83.438462',
    timeZoneId: 'America/New_York',
  },
  '41763': {
    zip: '41763',
    city: 'Slemp',
    state: 'KY',
    latitude: '37.069934',
    longitude: '-83.118057',
    timeZoneId: 'America/New_York',
  },
  '41764': {
    zip: '41764',
    city: 'Smilax',
    state: 'KY',
    latitude: '37.167709',
    longitude: '-83.259154',
    timeZoneId: 'America/New_York',
  },
  '41766': {
    zip: '41766',
    city: 'Thousandsticks',
    state: 'KY',
    latitude: '37.184094',
    longitude: '-83.430195',
    timeZoneId: 'America/New_York',
  },
  '41772': {
    zip: '41772',
    city: 'Vest',
    state: 'KY',
    latitude: '37.431548',
    longitude: '-83.071184',
    timeZoneId: 'America/New_York',
  },
  '41773': {
    zip: '41773',
    city: 'Vicco',
    state: 'KY',
    latitude: '37.157727',
    longitude: '-83.09571',
    timeZoneId: 'America/New_York',
  },
  '41774': {
    zip: '41774',
    city: 'Viper',
    state: 'KY',
    latitude: '37.138863',
    longitude: '-83.165412',
    timeZoneId: 'America/New_York',
  },
  '41775': {
    zip: '41775',
    city: 'Wendover',
    state: 'KY',
    latitude: '37.164398',
    longitude: '-83.378767',
    timeZoneId: 'America/New_York',
  },
  '41776': {
    zip: '41776',
    city: 'Wooton',
    state: 'KY',
    latitude: '37.201089',
    longitude: '-83.298307',
    timeZoneId: 'America/New_York',
  },
  '41777': {
    zip: '41777',
    city: 'Yeaddiss',
    state: 'KY',
    latitude: '37.046606',
    longitude: '-83.220511',
    timeZoneId: 'America/New_York',
  },
  '41778': {
    zip: '41778',
    city: 'Yerkes',
    state: 'KY',
    latitude: '37.278519',
    longitude: '-83.300052',
    timeZoneId: 'America/New_York',
  },
  '41804': {
    zip: '41804',
    city: 'Blackey',
    state: 'KY',
    latitude: '37.175',
    longitude: '-83',
    timeZoneId: 'America/New_York',
  },
  '41810': {
    zip: '41810',
    city: 'Cromona',
    state: 'KY',
    latitude: '37.181445',
    longitude: '-82.686695',
    timeZoneId: 'America/New_York',
  },
  '41812': {
    zip: '41812',
    city: 'Deane',
    state: 'KY',
    latitude: '37.235392',
    longitude: '-82.797841',
    timeZoneId: 'America/New_York',
  },
  '41815': {
    zip: '41815',
    city: 'Ermine',
    state: 'KY',
    latitude: '37.173071',
    longitude: '-82.825294',
    timeZoneId: 'America/New_York',
  },
  '41817': {
    zip: '41817',
    city: 'Garner',
    state: 'KY',
    latitude: '37.376981',
    longitude: '-82.888568',
    timeZoneId: 'America/New_York',
  },
  '41819': {
    zip: '41819',
    city: 'Gordon',
    state: 'KY',
    latitude: '36.992572',
    longitude: '-83.069894',
    timeZoneId: 'America/New_York',
  },
  '41821': {
    zip: '41821',
    city: 'Hallie',
    state: 'KY',
    latitude: '37.075833',
    longitude: '-82.981667',
    timeZoneId: 'America/New_York',
  },
  '41822': {
    zip: '41822',
    city: 'Hindman',
    state: 'KY',
    latitude: '37.322511',
    longitude: '-82.949258',
    timeZoneId: 'America/New_York',
  },
  '41824': {
    zip: '41824',
    city: 'Isom',
    state: 'KY',
    latitude: '37.193878',
    longitude: '-82.907404',
    timeZoneId: 'America/New_York',
  },
  '41825': {
    zip: '41825',
    city: 'Jackhorn',
    state: 'KY',
    latitude: '37.166083',
    longitude: '-82.690959',
    timeZoneId: 'America/New_York',
  },
  '41826': {
    zip: '41826',
    city: 'Jeremiah',
    state: 'KY',
    latitude: '37.163934',
    longitude: '-82.933262',
    timeZoneId: 'America/New_York',
  },
  '41828': {
    zip: '41828',
    city: 'Kite',
    state: 'KY',
    latitude: '37.301667',
    longitude: '-82.789444',
    timeZoneId: 'America/New_York',
  },
  '41831': {
    zip: '41831',
    city: 'Leburn',
    state: 'KY',
    latitude: '37.402222',
    longitude: '-82.942222',
    timeZoneId: 'America/New_York',
  },
  '41832': {
    zip: '41832',
    city: 'Letcher',
    state: 'KY',
    latitude: '37.160712',
    longitude: '-82.994745',
    timeZoneId: 'America/New_York',
  },
  '41833': {
    zip: '41833',
    city: 'Linefork',
    state: 'KY',
    latitude: '37.038564',
    longitude: '-82.945432',
    timeZoneId: 'America/New_York',
  },
  '41834': {
    zip: '41834',
    city: 'Littcarr',
    state: 'KY',
    latitude: '37.259621',
    longitude: '-82.967852',
    timeZoneId: 'America/New_York',
  },
  '41835': {
    zip: '41835',
    city: 'Mc Roberts',
    state: 'KY',
    latitude: '37.208737',
    longitude: '-82.66964',
    timeZoneId: 'America/New_York',
  },
  '41836': {
    zip: '41836',
    city: 'Mallie',
    state: 'KY',
    latitude: '37.301259',
    longitude: '-82.915491',
    timeZoneId: 'America/New_York',
  },
  '41837': {
    zip: '41837',
    city: 'Mayking',
    state: 'KY',
    latitude: '37.106826',
    longitude: '-82.767907',
    timeZoneId: 'America/New_York',
  },
  '41838': {
    zip: '41838',
    city: 'Millstone',
    state: 'KY',
    latitude: '37.146815',
    longitude: '-82.739237',
    timeZoneId: 'America/New_York',
  },
  '41839': {
    zip: '41839',
    city: 'Mousie',
    state: 'KY',
    latitude: '37.439323',
    longitude: '-82.911153',
    timeZoneId: 'America/New_York',
  },
  '41840': {
    zip: '41840',
    city: 'Neon',
    state: 'KY',
    latitude: '37.212627',
    longitude: '-82.707115',
    timeZoneId: 'America/New_York',
  },
  '41843': {
    zip: '41843',
    city: 'Pine Top',
    state: 'KY',
    latitude: '37.305673',
    longitude: '-82.82666',
    timeZoneId: 'America/New_York',
  },
  '41844': {
    zip: '41844',
    city: 'Pippa Passes',
    state: 'KY',
    latitude: '37.369951',
    longitude: '-82.827928',
    timeZoneId: 'America/New_York',
  },
  '41845': {
    zip: '41845',
    city: 'Premium',
    state: 'KY',
    latitude: '37.147265',
    longitude: '-82.892244',
    timeZoneId: 'America/New_York',
  },
  '41847': {
    zip: '41847',
    city: 'Redfox',
    state: 'KY',
    latitude: '37.231111',
    longitude: '-82.968056',
    timeZoneId: 'America/New_York',
  },
  '41848': {
    zip: '41848',
    city: 'Roxana',
    state: 'KY',
    latitude: '37.091763',
    longitude: '-83.029667',
    timeZoneId: 'America/New_York',
  },
  '41849': {
    zip: '41849',
    city: 'Seco',
    state: 'KY',
    latitude: '37.171925',
    longitude: '-82.732417',
    timeZoneId: 'America/New_York',
  },
  '41855': {
    zip: '41855',
    city: 'Thornton',
    state: 'KY',
    latitude: '37.134175',
    longitude: '-82.744792',
    timeZoneId: 'America/New_York',
  },
  '41858': {
    zip: '41858',
    city: 'Whitesburg',
    state: 'KY',
    latitude: '37.122402',
    longitude: '-82.826576',
    timeZoneId: 'America/New_York',
  },
  '41859': {
    zip: '41859',
    city: 'Dema',
    state: 'KY',
    latitude: '37.431745',
    longitude: '-82.851394',
    timeZoneId: 'America/New_York',
  },
  '41861': {
    zip: '41861',
    city: 'Raven',
    state: 'KY',
    latitude: '37.394717',
    longitude: '-82.840377',
    timeZoneId: 'America/New_York',
  },
  '41862': {
    zip: '41862',
    city: 'Topmost',
    state: 'KY',
    latitude: '37.324722',
    longitude: '-82.768056',
    timeZoneId: 'America/New_York',
  },
  '42001': {
    zip: '42001',
    city: 'Paducah',
    state: 'KY',
    latitude: '37.03758',
    longitude: '-88.71018',
    timeZoneId: 'America/Chicago',
  },
  '42002': {
    zip: '42002',
    city: 'Paducah',
    state: 'KY',
    latitude: '37.068551',
    longitude: '-88.624938',
    timeZoneId: 'America/Chicago',
  },
  '42003': {
    zip: '42003',
    city: 'Paducah',
    state: 'KY',
    latitude: '37.031534',
    longitude: '-88.587966',
    timeZoneId: 'America/Chicago',
  },
  '42020': {
    zip: '42020',
    city: 'Almo',
    state: 'KY',
    latitude: '36.668399',
    longitude: '-88.368865',
    timeZoneId: 'America/Chicago',
  },
  '42021': {
    zip: '42021',
    city: 'Arlington',
    state: 'KY',
    latitude: '36.791078',
    longitude: '-89.005986',
    timeZoneId: 'America/Chicago',
  },
  '42022': {
    zip: '42022',
    city: 'Bandana',
    state: 'KY',
    latitude: '37.147896',
    longitude: '-88.943305',
    timeZoneId: 'America/Chicago',
  },
  '42023': {
    zip: '42023',
    city: 'Bardwell',
    state: 'KY',
    latitude: '36.874889',
    longitude: '-89.00882',
    timeZoneId: 'America/Chicago',
  },
  '42024': {
    zip: '42024',
    city: 'Barlow',
    state: 'KY',
    latitude: '37.058897',
    longitude: '-89.075654',
    timeZoneId: 'America/Chicago',
  },
  '42025': {
    zip: '42025',
    city: 'Benton',
    state: 'KY',
    latitude: '36.87798',
    longitude: '-88.3332',
    timeZoneId: 'America/Chicago',
  },
  '42027': {
    zip: '42027',
    city: 'Boaz',
    state: 'KY',
    latitude: '36.954599',
    longitude: '-88.641856',
    timeZoneId: 'America/Chicago',
  },
  '42028': {
    zip: '42028',
    city: 'Burna',
    state: 'KY',
    latitude: '37.028417',
    longitude: '-88.2844',
    timeZoneId: 'America/Chicago',
  },
  '42029': {
    zip: '42029',
    city: 'Calvert City',
    state: 'KY',
    latitude: '37.019627',
    longitude: '-88.347552',
    timeZoneId: 'America/Chicago',
  },
  '42031': {
    zip: '42031',
    city: 'Clinton',
    state: 'KY',
    latitude: '36.71358',
    longitude: '-89.00682',
    timeZoneId: 'America/Chicago',
  },
  '42032': {
    zip: '42032',
    city: 'Columbus',
    state: 'KY',
    latitude: '36.717637',
    longitude: '-89.103911',
    timeZoneId: 'America/Chicago',
  },
  '42033': {
    zip: '42033',
    city: 'Crayne',
    state: 'KY',
    latitude: '37.271858',
    longitude: '-88.079526',
    timeZoneId: 'America/Chicago',
  },
  '42035': {
    zip: '42035',
    city: 'Cunningham',
    state: 'KY',
    latitude: '36.894031',
    longitude: '-88.874629',
    timeZoneId: 'America/Chicago',
  },
  '42036': {
    zip: '42036',
    city: 'Dexter',
    state: 'KY',
    latitude: '36.698534',
    longitude: '-88.166659',
    timeZoneId: 'America/Chicago',
  },
  '42037': {
    zip: '42037',
    city: 'Dycusburg',
    state: 'KY',
    latitude: '37.158069',
    longitude: '-88.186909',
    timeZoneId: 'America/Chicago',
  },
  '42038': {
    zip: '42038',
    city: 'Eddyville',
    state: 'KY',
    latitude: '37.0452',
    longitude: '-88.05012',
    timeZoneId: 'America/Chicago',
  },
  '42039': {
    zip: '42039',
    city: 'Fancy Farm',
    state: 'KY',
    latitude: '36.758589',
    longitude: '-88.795489',
    timeZoneId: 'America/Chicago',
  },
  '42040': {
    zip: '42040',
    city: 'Farmington',
    state: 'KY',
    latitude: '36.67845',
    longitude: '-88.535796',
    timeZoneId: 'America/Chicago',
  },
  '42041': {
    zip: '42041',
    city: 'Fulton',
    state: 'KY',
    latitude: '36.5319',
    longitude: '-88.94748',
    timeZoneId: 'America/Chicago',
  },
  '42044': {
    zip: '42044',
    city: 'Gilbertsville',
    state: 'KY',
    latitude: '36.927731',
    longitude: '-88.222158',
    timeZoneId: 'America/Chicago',
  },
  '42045': {
    zip: '42045',
    city: 'Grand Rivers',
    state: 'KY',
    latitude: '37.07598',
    longitude: '-88.25994',
    timeZoneId: 'America/Chicago',
  },
  '42047': {
    zip: '42047',
    city: 'Hampton',
    state: 'KY',
    latitude: '37.332744',
    longitude: '-88.400437',
    timeZoneId: 'America/Chicago',
  },
  '42048': {
    zip: '42048',
    city: 'Hardin',
    state: 'KY',
    latitude: '36.78108',
    longitude: '-88.25274',
    timeZoneId: 'America/Chicago',
  },
  '42049': {
    zip: '42049',
    city: 'Hazel',
    state: 'KY',
    latitude: '36.53987',
    longitude: '-88.342749',
    timeZoneId: 'America/Chicago',
  },
  '42050': {
    zip: '42050',
    city: 'Hickman',
    state: 'KY',
    latitude: '36.566908',
    longitude: '-89.186943',
    timeZoneId: 'America/Chicago',
  },
  '42051': {
    zip: '42051',
    city: 'Hickory',
    state: 'KY',
    latitude: '36.8535',
    longitude: '-88.69518',
    timeZoneId: 'America/Chicago',
  },
  '42053': {
    zip: '42053',
    city: 'Kevil',
    state: 'KY',
    latitude: '37.100611',
    longitude: '-88.846047',
    timeZoneId: 'America/Chicago',
  },
  '42054': {
    zip: '42054',
    city: 'Kirksey',
    state: 'KY',
    latitude: '36.682144',
    longitude: '-88.428036',
    timeZoneId: 'America/Chicago',
  },
  '42055': {
    zip: '42055',
    city: 'Kuttawa',
    state: 'KY',
    latitude: '37.058468',
    longitude: '-88.128269',
    timeZoneId: 'America/Chicago',
  },
  '42056': {
    zip: '42056',
    city: 'La Center',
    state: 'KY',
    latitude: '37.1012',
    longitude: '-88.969174',
    timeZoneId: 'America/Chicago',
  },
  '42058': {
    zip: '42058',
    city: 'Ledbetter',
    state: 'KY',
    latitude: '37.047851',
    longitude: '-88.492729',
    timeZoneId: 'America/Chicago',
  },
  '42060': {
    zip: '42060',
    city: 'Lovelaceville',
    state: 'KY',
    latitude: '36.970722',
    longitude: '-88.831281',
    timeZoneId: 'America/Chicago',
  },
  '42061': {
    zip: '42061',
    city: 'Lowes',
    state: 'KY',
    latitude: '36.890127',
    longitude: '-88.774431',
    timeZoneId: 'America/Chicago',
  },
  '42063': {
    zip: '42063',
    city: 'Lynnville',
    state: 'KY',
    latitude: '36.559278',
    longitude: '-88.568383',
    timeZoneId: 'America/Chicago',
  },
  '42064': {
    zip: '42064',
    city: 'Marion',
    state: 'KY',
    latitude: '37.29438',
    longitude: '-88.08846',
    timeZoneId: 'America/Chicago',
  },
  '42066': {
    zip: '42066',
    city: 'Mayfield',
    state: 'KY',
    latitude: '36.736513',
    longitude: '-88.638158',
    timeZoneId: 'America/Chicago',
  },
  '42069': {
    zip: '42069',
    city: 'Melber',
    state: 'KY',
    latitude: '36.948877',
    longitude: '-88.753453',
    timeZoneId: 'America/Chicago',
  },
  '42070': {
    zip: '42070',
    city: 'Milburn',
    state: 'KY',
    latitude: '36.797936',
    longitude: '-88.897657',
    timeZoneId: 'America/Chicago',
  },
  '42071': {
    zip: '42071',
    city: 'Murray',
    state: 'KY',
    latitude: '36.6183',
    longitude: '-88.25106',
    timeZoneId: 'America/Chicago',
  },
  '42076': {
    zip: '42076',
    city: 'New Concord',
    state: 'KY',
    latitude: '36.598904',
    longitude: '-88.067577',
    timeZoneId: 'America/Chicago',
  },
  '42078': {
    zip: '42078',
    city: 'Salem',
    state: 'KY',
    latitude: '37.242613',
    longitude: '-88.281093',
    timeZoneId: 'America/Chicago',
  },
  '42079': {
    zip: '42079',
    city: 'Sedalia',
    state: 'KY',
    latitude: '36.56655',
    longitude: '-88.586948',
    timeZoneId: 'America/Chicago',
  },
  '42081': {
    zip: '42081',
    city: 'Smithland',
    state: 'KY',
    latitude: '37.3975',
    longitude: '-88.374722',
    timeZoneId: 'America/Chicago',
  },
  '42082': {
    zip: '42082',
    city: 'Symsonia',
    state: 'KY',
    latitude: '36.961114',
    longitude: '-88.511278',
    timeZoneId: 'America/Chicago',
  },
  '42083': {
    zip: '42083',
    city: 'Tiline',
    state: 'KY',
    latitude: '37.163084',
    longitude: '-88.248816',
    timeZoneId: 'America/Chicago',
  },
  '42085': {
    zip: '42085',
    city: 'Water Valley',
    state: 'KY',
    latitude: '36.573343',
    longitude: '-88.767218',
    timeZoneId: 'America/Chicago',
  },
  '42086': {
    zip: '42086',
    city: 'West Paducah',
    state: 'KY',
    latitude: '37.087107',
    longitude: '-88.767014',
    timeZoneId: 'America/Chicago',
  },
  '42087': {
    zip: '42087',
    city: 'Wickliffe',
    state: 'KY',
    latitude: '36.969136',
    longitude: '-88.980962',
    timeZoneId: 'America/Chicago',
  },
  '42088': {
    zip: '42088',
    city: 'Wingo',
    state: 'KY',
    latitude: '36.618387',
    longitude: '-88.735634',
    timeZoneId: 'America/Chicago',
  },
  '42101': {
    zip: '42101',
    city: 'Bowling Green',
    state: 'KY',
    latitude: '37.018172',
    longitude: '-86.458733',
    timeZoneId: 'America/Chicago',
  },
  '42102': {
    zip: '42102',
    city: 'Bowling Green',
    state: 'KY',
    latitude: '36.952692',
    longitude: '-86.401915',
    timeZoneId: 'America/Chicago',
  },
  '42103': {
    zip: '42103',
    city: 'Bowling Green',
    state: 'KY',
    latitude: '36.963037',
    longitude: '-86.370495',
    timeZoneId: 'America/Chicago',
  },
  '42104': {
    zip: '42104',
    city: 'Bowling Green',
    state: 'KY',
    latitude: '36.932012',
    longitude: '-86.45006',
    timeZoneId: 'America/Chicago',
  },
  '42120': {
    zip: '42120',
    city: 'Adolphus',
    state: 'KY',
    latitude: '36.668405',
    longitude: '-86.276025',
    timeZoneId: 'America/Chicago',
  },
  '42122': {
    zip: '42122',
    city: 'Alvaton',
    state: 'KY',
    latitude: '36.866581',
    longitude: '-86.361492',
    timeZoneId: 'America/Chicago',
  },
  '42123': {
    zip: '42123',
    city: 'Austin',
    state: 'KY',
    latitude: '36.822874',
    longitude: '-85.994572',
    timeZoneId: 'America/Chicago',
  },
  '42124': {
    zip: '42124',
    city: 'Beaumont',
    state: 'KY',
    latitude: '36.862105',
    longitude: '-85.644818',
    timeZoneId: 'America/Chicago',
  },
  '42127': {
    zip: '42127',
    city: 'Cave City',
    state: 'KY',
    latitude: '37.121088',
    longitude: '-85.916475',
    timeZoneId: 'America/Chicago',
  },
  '42128': {
    zip: '42128',
    city: 'Bowling Green',
    state: 'KY',
    latitude: '36.834323',
    longitude: '-86.406391',
    timeZoneId: 'America/Chicago',
  },
  '42129': {
    zip: '42129',
    city: 'Edmonton',
    state: 'KY',
    latitude: '36.988715',
    longitude: '-85.611504',
    timeZoneId: 'America/Chicago',
  },
  '42130': {
    zip: '42130',
    city: 'Eighty Eight',
    state: 'KY',
    latitude: '36.924418',
    longitude: '-85.789972',
    timeZoneId: 'America/Chicago',
  },
  '42131': {
    zip: '42131',
    city: 'Etoile',
    state: 'KY',
    latitude: '36.8129',
    longitude: '-85.911479',
    timeZoneId: 'America/Chicago',
  },
  '42133': {
    zip: '42133',
    city: 'Fountain Run',
    state: 'KY',
    latitude: '36.703779',
    longitude: '-85.937084',
    timeZoneId: 'America/Chicago',
  },
  '42134': {
    zip: '42134',
    city: 'Franklin',
    state: 'KY',
    latitude: '36.728547',
    longitude: '-86.572927',
    timeZoneId: 'America/Chicago',
  },
  '42135': {
    zip: '42135',
    city: 'Franklin',
    state: 'KY',
    latitude: '36.716493',
    longitude: '-86.577334',
    timeZoneId: 'America/Chicago',
  },
  '42140': {
    zip: '42140',
    city: 'Gamaliel',
    state: 'KY',
    latitude: '36.660772',
    longitude: '-85.813348',
    timeZoneId: 'America/Chicago',
  },
  '42141': {
    zip: '42141',
    city: 'Glasgow',
    state: 'KY',
    latitude: '36.974371',
    longitude: '-85.907023',
    timeZoneId: 'America/Chicago',
  },
  '42142': {
    zip: '42142',
    city: 'Glasgow',
    state: 'KY',
    latitude: '36.997611',
    longitude: '-85.906299',
    timeZoneId: 'America/Chicago',
  },
  '42151': {
    zip: '42151',
    city: 'Hestand',
    state: 'KY',
    latitude: '36.66302',
    longitude: '-85.599734',
    timeZoneId: 'America/Chicago',
  },
  '42152': {
    zip: '42152',
    city: 'Hiseville',
    state: 'KY',
    latitude: '37.098707',
    longitude: '-85.811844',
    timeZoneId: 'America/Chicago',
  },
  '42153': {
    zip: '42153',
    city: 'Holland',
    state: 'KY',
    latitude: '36.66952',
    longitude: '-86.047299',
    timeZoneId: 'America/Chicago',
  },
  '42154': {
    zip: '42154',
    city: 'Knob Lick',
    state: 'KY',
    latitude: '37.054794',
    longitude: '-85.720252',
    timeZoneId: 'America/Chicago',
  },
  '42156': {
    zip: '42156',
    city: 'Lucas',
    state: 'KY',
    latitude: '36.846642',
    longitude: '-86.047312',
    timeZoneId: 'America/Chicago',
  },
  '42157': {
    zip: '42157',
    city: 'Mount Hermon',
    state: 'KY',
    latitude: '36.832888',
    longitude: '-85.845417',
    timeZoneId: 'America/Chicago',
  },
  '42159': {
    zip: '42159',
    city: 'Oakland',
    state: 'KY',
    latitude: '36.998289',
    longitude: '-86.256896',
    timeZoneId: 'America/Chicago',
  },
  '42160': {
    zip: '42160',
    city: 'Park City',
    state: 'KY',
    latitude: '37.064959',
    longitude: '-86.05419',
    timeZoneId: 'America/Chicago',
  },
  '42163': {
    zip: '42163',
    city: 'Rocky Hill',
    state: 'KY',
    latitude: '37.068506',
    longitude: '-86.098123',
    timeZoneId: 'America/Chicago',
  },
  '42164': {
    zip: '42164',
    city: 'Scottsville',
    state: 'KY',
    latitude: '36.78216',
    longitude: '-86.19012',
    timeZoneId: 'America/Chicago',
  },
  '42166': {
    zip: '42166',
    city: 'Summer Shade',
    state: 'KY',
    latitude: '36.89124',
    longitude: '-85.71546',
    timeZoneId: 'America/Chicago',
  },
  '42167': {
    zip: '42167',
    city: 'Tompkinsville',
    state: 'KY',
    latitude: '36.718537',
    longitude: '-85.700436',
    timeZoneId: 'America/Chicago',
  },
  '42170': {
    zip: '42170',
    city: 'Woodburn',
    state: 'KY',
    latitude: '36.840465',
    longitude: '-86.611447',
    timeZoneId: 'America/Chicago',
  },
  '42171': {
    zip: '42171',
    city: 'Smiths Grove',
    state: 'KY',
    latitude: '37.022755',
    longitude: '-86.184158',
    timeZoneId: 'America/Chicago',
  },
  '42201': {
    zip: '42201',
    city: 'Aberdeen',
    state: 'KY',
    latitude: '37.253618',
    longitude: '-86.684733',
    timeZoneId: 'America/Chicago',
  },
  '42202': {
    zip: '42202',
    city: 'Adairville',
    state: 'KY',
    latitude: '36.684982',
    longitude: '-86.851839',
    timeZoneId: 'America/Chicago',
  },
  '42204': {
    zip: '42204',
    city: 'Allensville',
    state: 'KY',
    latitude: '36.70476',
    longitude: '-87.04986',
    timeZoneId: 'America/Chicago',
  },
  '42206': {
    zip: '42206',
    city: 'Auburn',
    state: 'KY',
    latitude: '36.885141',
    longitude: '-86.72318',
    timeZoneId: 'America/Chicago',
  },
  '42207': {
    zip: '42207',
    city: 'Bee Spring',
    state: 'KY',
    latitude: '37.301324',
    longitude: '-86.275612',
    timeZoneId: 'America/Chicago',
  },
  '42210': {
    zip: '42210',
    city: 'Brownsville',
    state: 'KY',
    latitude: '37.198402',
    longitude: '-86.277888',
    timeZoneId: 'America/Chicago',
  },
  '42211': {
    zip: '42211',
    city: 'Cadiz',
    state: 'KY',
    latitude: '36.79806',
    longitude: '-87.83484',
    timeZoneId: 'America/Chicago',
  },
  '42214': {
    zip: '42214',
    city: 'Center',
    state: 'KY',
    latitude: '37.130336',
    longitude: '-85.689134',
    timeZoneId: 'America/Chicago',
  },
  '42215': {
    zip: '42215',
    city: 'Cerulean',
    state: 'KY',
    latitude: '36.994321',
    longitude: '-87.64822',
    timeZoneId: 'America/Chicago',
  },
  '42216': {
    zip: '42216',
    city: 'Clifty',
    state: 'KY',
    latitude: '36.987194',
    longitude: '-87.142357',
    timeZoneId: 'America/Chicago',
  },
  '42217': {
    zip: '42217',
    city: 'Crofton',
    state: 'KY',
    latitude: '37.039869',
    longitude: '-87.491783',
    timeZoneId: 'America/Chicago',
  },
  '42219': {
    zip: '42219',
    city: 'Dunbar',
    state: 'KY',
    latitude: '37.164221',
    longitude: '-86.749492',
    timeZoneId: 'America/Chicago',
  },
  '42220': {
    zip: '42220',
    city: 'Elkton',
    state: 'KY',
    latitude: '36.874485',
    longitude: '-87.17614',
    timeZoneId: 'America/Chicago',
  },
  '42221': {
    zip: '42221',
    city: 'Fairview',
    state: 'KY',
    latitude: '36.894485',
    longitude: '-87.485341',
    timeZoneId: 'America/Chicago',
  },
  '42223': {
    zip: '42223',
    city: 'Fort Campbell',
    state: 'KY',
    latitude: '36.654747',
    longitude: '-87.462668',
    timeZoneId: 'America/Chicago',
  },
  '42232': {
    zip: '42232',
    city: 'Gracey',
    state: 'KY',
    latitude: '36.868364',
    longitude: '-87.651698',
    timeZoneId: 'America/Chicago',
  },
  '42234': {
    zip: '42234',
    city: 'Guthrie',
    state: 'KY',
    latitude: '36.695643',
    longitude: '-87.17693',
    timeZoneId: 'America/Chicago',
  },
  '42236': {
    zip: '42236',
    city: 'Herndon',
    state: 'KY',
    latitude: '36.710577',
    longitude: '-87.603781',
    timeZoneId: 'America/Chicago',
  },
  '42240': {
    zip: '42240',
    city: 'Hopkinsville',
    state: 'KY',
    latitude: '36.868139',
    longitude: '-87.479532',
    timeZoneId: 'America/Chicago',
  },
  '42241': {
    zip: '42241',
    city: 'Hopkinsville',
    state: 'KY',
    latitude: '36.867091',
    longitude: '-87.489755',
    timeZoneId: 'America/Chicago',
  },
  '42252': {
    zip: '42252',
    city: 'Jetson',
    state: 'KY',
    latitude: '37.236542',
    longitude: '-86.517041',
    timeZoneId: 'America/Chicago',
  },
  '42254': {
    zip: '42254',
    city: 'La Fayette',
    state: 'KY',
    latitude: '36.658712',
    longitude: '-87.647685',
    timeZoneId: 'America/Chicago',
  },
  '42256': {
    zip: '42256',
    city: 'Lewisburg',
    state: 'KY',
    latitude: '37.008924',
    longitude: '-86.979116',
    timeZoneId: 'America/Chicago',
  },
  '42259': {
    zip: '42259',
    city: 'Mammoth Cave',
    state: 'KY',
    latitude: '37.284708',
    longitude: '-86.189466',
    timeZoneId: 'America/Chicago',
  },
  '42261': {
    zip: '42261',
    city: 'Morgantown',
    state: 'KY',
    latitude: '37.19634',
    longitude: '-86.68932',
    timeZoneId: 'America/Chicago',
  },
  '42262': {
    zip: '42262',
    city: 'Oak Grove',
    state: 'KY',
    latitude: '36.677845',
    longitude: '-87.447679',
    timeZoneId: 'America/Chicago',
  },
  '42265': {
    zip: '42265',
    city: 'Olmstead',
    state: 'KY',
    latitude: '36.723367',
    longitude: '-86.991472',
    timeZoneId: 'America/Chicago',
  },
  '42266': {
    zip: '42266',
    city: 'Pembroke',
    state: 'KY',
    latitude: '36.779266',
    longitude: '-87.364735',
    timeZoneId: 'America/Chicago',
  },
  '42273': {
    zip: '42273',
    city: 'Rochester',
    state: 'KY',
    latitude: '37.219089',
    longitude: '-86.868909',
    timeZoneId: 'America/Chicago',
  },
  '42274': {
    zip: '42274',
    city: 'Rockfield',
    state: 'KY',
    latitude: '36.951025',
    longitude: '-86.601859',
    timeZoneId: 'America/Chicago',
  },
  '42275': {
    zip: '42275',
    city: 'Roundhill',
    state: 'KY',
    latitude: '37.199181',
    longitude: '-86.454521',
    timeZoneId: 'America/Chicago',
  },
  '42276': {
    zip: '42276',
    city: 'Russellville',
    state: 'KY',
    latitude: '36.83094',
    longitude: '-86.91702',
    timeZoneId: 'America/Chicago',
  },
  '42280': {
    zip: '42280',
    city: 'Sharon Grove',
    state: 'KY',
    latitude: '36.941844',
    longitude: '-87.107304',
    timeZoneId: 'America/Chicago',
  },
  '42285': {
    zip: '42285',
    city: 'Sweeden',
    state: 'KY',
    latitude: '37.262476',
    longitude: '-86.279063',
    timeZoneId: 'America/Chicago',
  },
  '42286': {
    zip: '42286',
    city: 'Trenton',
    state: 'KY',
    latitude: '36.728871',
    longitude: '-87.255257',
    timeZoneId: 'America/Chicago',
  },
  '42288': {
    zip: '42288',
    city: 'Woodbury',
    state: 'KY',
    latitude: '37.181719',
    longitude: '-86.636201',
    timeZoneId: 'America/Chicago',
  },
  '42301': {
    zip: '42301',
    city: 'Owensboro',
    state: 'KY',
    latitude: '37.747812',
    longitude: '-87.178813',
    timeZoneId: 'America/Chicago',
  },
  '42302': {
    zip: '42302',
    city: 'Owensboro',
    state: 'KY',
    latitude: '37.765092',
    longitude: '-87.043646',
    timeZoneId: 'America/Chicago',
  },
  '42303': {
    zip: '42303',
    city: 'Owensboro',
    state: 'KY',
    latitude: '37.757138',
    longitude: '-87.092555',
    timeZoneId: 'America/Chicago',
  },
  '42304': {
    zip: '42304',
    city: 'Owensboro',
    state: 'KY',
    latitude: '37.777706',
    longitude: '-87.168648',
    timeZoneId: 'America/Chicago',
  },
  '42320': {
    zip: '42320',
    city: 'Beaver Dam',
    state: 'KY',
    latitude: '37.33206',
    longitude: '-86.87814',
    timeZoneId: 'America/Chicago',
  },
  '42321': {
    zip: '42321',
    city: 'Beech Creek',
    state: 'KY',
    latitude: '37.182281',
    longitude: '-87.120566',
    timeZoneId: 'America/Chicago',
  },
  '42322': {
    zip: '42322',
    city: 'Beech Grove',
    state: 'KY',
    latitude: '37.614047',
    longitude: '-87.398347',
    timeZoneId: 'America/Chicago',
  },
  '42323': {
    zip: '42323',
    city: 'Beechmont',
    state: 'KY',
    latitude: '37.177666',
    longitude: '-87.050396',
    timeZoneId: 'America/Chicago',
  },
  '42324': {
    zip: '42324',
    city: 'Belton',
    state: 'KY',
    latitude: '37.145871',
    longitude: '-86.966575',
    timeZoneId: 'America/Chicago',
  },
  '42325': {
    zip: '42325',
    city: 'Bremen',
    state: 'KY',
    latitude: '37.343773',
    longitude: '-87.254214',
    timeZoneId: 'America/Chicago',
  },
  '42326': {
    zip: '42326',
    city: 'Browder',
    state: 'KY',
    latitude: '37.271937',
    longitude: '-87.014955',
    timeZoneId: 'America/Chicago',
  },
  '42327': {
    zip: '42327',
    city: 'Calhoun',
    state: 'KY',
    latitude: '37.564331',
    longitude: '-87.260812',
    timeZoneId: 'America/Chicago',
  },
  '42328': {
    zip: '42328',
    city: 'Centertown',
    state: 'KY',
    latitude: '37.419834',
    longitude: '-86.994628',
    timeZoneId: 'America/Chicago',
  },
  '42330': {
    zip: '42330',
    city: 'Central City',
    state: 'KY',
    latitude: '37.294778',
    longitude: '-87.126648',
    timeZoneId: 'America/Chicago',
  },
  '42332': {
    zip: '42332',
    city: 'Cleaton',
    state: 'KY',
    latitude: '37.252816',
    longitude: '-87.092264',
    timeZoneId: 'America/Chicago',
  },
  '42333': {
    zip: '42333',
    city: 'Cromwell',
    state: 'KY',
    latitude: '37.33605',
    longitude: '-86.77033',
    timeZoneId: 'America/Chicago',
  },
  '42334': {
    zip: '42334',
    city: 'Curdsville',
    state: 'KY',
    latitude: '37.736732',
    longitude: '-87.329656',
    timeZoneId: 'America/Chicago',
  },
  '42337': {
    zip: '42337',
    city: 'Drakesboro',
    state: 'KY',
    latitude: '37.215647',
    longitude: '-87.049887',
    timeZoneId: 'America/Chicago',
  },
  '42338': {
    zip: '42338',
    city: 'Dundee',
    state: 'KY',
    latitude: '37.549238',
    longitude: '-86.76816',
    timeZoneId: 'America/Chicago',
  },
  '42339': {
    zip: '42339',
    city: 'Dunmor',
    state: 'KY',
    latitude: '37.080671',
    longitude: '-86.978957',
    timeZoneId: 'America/Chicago',
  },
  '42343': {
    zip: '42343',
    city: 'Fordsville',
    state: 'KY',
    latitude: '37.62816',
    longitude: '-86.7087',
    timeZoneId: 'America/Chicago',
  },
  '42344': {
    zip: '42344',
    city: 'Graham',
    state: 'KY',
    latitude: '37.239171',
    longitude: '-87.300028',
    timeZoneId: 'America/Chicago',
  },
  '42345': {
    zip: '42345',
    city: 'Greenville',
    state: 'KY',
    latitude: '37.20701',
    longitude: '-87.181121',
    timeZoneId: 'America/Chicago',
  },
  '42347': {
    zip: '42347',
    city: 'Hartford',
    state: 'KY',
    latitude: '37.49202',
    longitude: '-86.92608',
    timeZoneId: 'America/Chicago',
  },
  '42348': {
    zip: '42348',
    city: 'Hawesville',
    state: 'KY',
    latitude: '37.794496',
    longitude: '-86.850476',
    timeZoneId: 'America/Chicago',
  },
  '42349': {
    zip: '42349',
    city: 'Horse Branch',
    state: 'KY',
    latitude: '37.421761',
    longitude: '-86.606244',
    timeZoneId: 'America/Chicago',
  },
  '42350': {
    zip: '42350',
    city: 'Island',
    state: 'KY',
    latitude: '37.447021',
    longitude: '-87.168751',
    timeZoneId: 'America/Chicago',
  },
  '42351': {
    zip: '42351',
    city: 'Lewisport',
    state: 'KY',
    latitude: '37.859038',
    longitude: '-86.928438',
    timeZoneId: 'America/Chicago',
  },
  '42352': {
    zip: '42352',
    city: 'Livermore',
    state: 'KY',
    latitude: '37.496597',
    longitude: '-87.125805',
    timeZoneId: 'America/Chicago',
  },
  '42354': {
    zip: '42354',
    city: 'Mc Henry',
    state: 'KY',
    latitude: '37.382786',
    longitude: '-86.925555',
    timeZoneId: 'America/Chicago',
  },
  '42355': {
    zip: '42355',
    city: 'Maceo',
    state: 'KY',
    latitude: '37.861',
    longitude: '-86.984565',
    timeZoneId: 'America/Chicago',
  },
  '42356': {
    zip: '42356',
    city: 'Maple Mount',
    state: 'KY',
    latitude: '37.689402',
    longitude: '-87.323525',
    timeZoneId: 'America/Chicago',
  },
  '42361': {
    zip: '42361',
    city: 'Olaton',
    state: 'KY',
    latitude: '37.505252',
    longitude: '-86.717486',
    timeZoneId: 'America/Chicago',
  },
  '42364': {
    zip: '42364',
    city: 'Hawesville',
    state: 'KY',
    latitude: '37.752441',
    longitude: '-86.814795',
    timeZoneId: 'America/Chicago',
  },
  '42366': {
    zip: '42366',
    city: 'Philpot',
    state: 'KY',
    latitude: '37.738372',
    longitude: '-86.943626',
    timeZoneId: 'America/Chicago',
  },
  '42367': {
    zip: '42367',
    city: 'Powderly',
    state: 'KY',
    latitude: '37.20616',
    longitude: '-87.173607',
    timeZoneId: 'America/Chicago',
  },
  '42368': {
    zip: '42368',
    city: 'Reynolds Station',
    state: 'KY',
    latitude: '37.698386',
    longitude: '-86.772446',
    timeZoneId: 'America/Chicago',
  },
  '42369': {
    zip: '42369',
    city: 'Rockport',
    state: 'KY',
    latitude: '37.28767',
    longitude: '-86.933978',
    timeZoneId: 'America/Chicago',
  },
  '42370': {
    zip: '42370',
    city: 'Rosine',
    state: 'KY',
    latitude: '37.667156',
    longitude: '-86.828682',
    timeZoneId: 'America/Chicago',
  },
  '42371': {
    zip: '42371',
    city: 'Rumsey',
    state: 'KY',
    latitude: '37.506347',
    longitude: '-87.28527',
    timeZoneId: 'America/Chicago',
  },
  '42372': {
    zip: '42372',
    city: 'Sacramento',
    state: 'KY',
    latitude: '37.422299',
    longitude: '-87.278561',
    timeZoneId: 'America/Chicago',
  },
  '42374': {
    zip: '42374',
    city: 'South Carrollton',
    state: 'KY',
    latitude: '37.335929',
    longitude: '-87.142259',
    timeZoneId: 'America/Chicago',
  },
  '42376': {
    zip: '42376',
    city: 'Utica',
    state: 'KY',
    latitude: '37.624761',
    longitude: '-87.07838',
    timeZoneId: 'America/Chicago',
  },
  '42377': {
    zip: '42377',
    city: 'West Louisville',
    state: 'KY',
    latitude: '37.698211',
    longitude: '-87.284712',
    timeZoneId: 'America/Chicago',
  },
  '42378': {
    zip: '42378',
    city: 'Whitesville',
    state: 'KY',
    latitude: '37.683228',
    longitude: '-86.87302',
    timeZoneId: 'America/Chicago',
  },
  '42402': {
    zip: '42402',
    city: 'Baskett',
    state: 'KY',
    latitude: '37.868596',
    longitude: '-87.461396',
    timeZoneId: 'America/Chicago',
  },
  '42404': {
    zip: '42404',
    city: 'Clay',
    state: 'KY',
    latitude: '37.490563',
    longitude: '-87.793465',
    timeZoneId: 'America/Chicago',
  },
  '42406': {
    zip: '42406',
    city: 'Corydon',
    state: 'KY',
    latitude: '37.727979',
    longitude: '-87.694821',
    timeZoneId: 'America/Chicago',
  },
  '42408': {
    zip: '42408',
    city: 'Dawson Springs',
    state: 'KY',
    latitude: '37.18986',
    longitude: '-87.68724',
    timeZoneId: 'America/Chicago',
  },
  '42409': {
    zip: '42409',
    city: 'Dixon',
    state: 'KY',
    latitude: '37.525276',
    longitude: '-87.680465',
    timeZoneId: 'America/Chicago',
  },
  '42410': {
    zip: '42410',
    city: 'Earlington',
    state: 'KY',
    latitude: '37.270969',
    longitude: '-87.51168',
    timeZoneId: 'America/Chicago',
  },
  '42411': {
    zip: '42411',
    city: 'Fredonia',
    state: 'KY',
    latitude: '37.218831',
    longitude: '-87.986254',
    timeZoneId: 'America/Chicago',
  },
  '42413': {
    zip: '42413',
    city: 'Hanson',
    state: 'KY',
    latitude: '37.437638',
    longitude: '-87.479402',
    timeZoneId: 'America/Chicago',
  },
  '42419': {
    zip: '42419',
    city: 'Henderson',
    state: 'KY',
    latitude: '37.837363',
    longitude: '-87.59155',
    timeZoneId: 'America/Chicago',
  },
  '42420': {
    zip: '42420',
    city: 'Henderson',
    state: 'KY',
    latitude: '37.82244',
    longitude: '-87.55526',
    timeZoneId: 'America/Chicago',
  },
  '42431': {
    zip: '42431',
    city: 'Madisonville',
    state: 'KY',
    latitude: '37.336987',
    longitude: '-87.497302',
    timeZoneId: 'America/Chicago',
  },
  '42436': {
    zip: '42436',
    city: 'Manitou',
    state: 'KY',
    latitude: '37.417951',
    longitude: '-87.567065',
    timeZoneId: 'America/Chicago',
  },
  '42437': {
    zip: '42437',
    city: 'Morganfield',
    state: 'KY',
    latitude: '37.64592',
    longitude: '-87.9027',
    timeZoneId: 'America/Chicago',
  },
  '42440': {
    zip: '42440',
    city: 'Mortons Gap',
    state: 'KY',
    latitude: '37.238024',
    longitude: '-87.469456',
    timeZoneId: 'America/Chicago',
  },
  '42441': {
    zip: '42441',
    city: 'Nebo',
    state: 'KY',
    latitude: '37.352328',
    longitude: '-87.658687',
    timeZoneId: 'America/Chicago',
  },
  '42442': {
    zip: '42442',
    city: 'Nortonville',
    state: 'KY',
    latitude: '37.17941',
    longitude: '-87.471198',
    timeZoneId: 'America/Chicago',
  },
  '42444': {
    zip: '42444',
    city: 'Poole',
    state: 'KY',
    latitude: '37.639435',
    longitude: '-87.643948',
    timeZoneId: 'America/Chicago',
  },
  '42445': {
    zip: '42445',
    city: 'Princeton',
    state: 'KY',
    latitude: '37.10854',
    longitude: '-87.880111',
    timeZoneId: 'America/Chicago',
  },
  '42450': {
    zip: '42450',
    city: 'Providence',
    state: 'KY',
    latitude: '37.400598',
    longitude: '-87.755019',
    timeZoneId: 'America/Chicago',
  },
  '42451': {
    zip: '42451',
    city: 'Reed',
    state: 'KY',
    latitude: '37.859401',
    longitude: '-87.38099',
    timeZoneId: 'America/Chicago',
  },
  '42452': {
    zip: '42452',
    city: 'Robards',
    state: 'KY',
    latitude: '37.688158',
    longitude: '-87.550435',
    timeZoneId: 'America/Chicago',
  },
  '42453': {
    zip: '42453',
    city: 'Saint Charles',
    state: 'KY',
    latitude: '37.163997',
    longitude: '-87.584022',
    timeZoneId: 'America/Chicago',
  },
  '42455': {
    zip: '42455',
    city: 'Sebree',
    state: 'KY',
    latitude: '37.607747',
    longitude: '-87.532668',
    timeZoneId: 'America/Chicago',
  },
  '42456': {
    zip: '42456',
    city: 'Slaughters',
    state: 'KY',
    latitude: '37.506005',
    longitude: '-87.444474',
    timeZoneId: 'America/Chicago',
  },
  '42457': {
    zip: '42457',
    city: 'Smith Mills',
    state: 'KY',
    latitude: '37.807995',
    longitude: '-87.771357',
    timeZoneId: 'America/Chicago',
  },
  '42458': {
    zip: '42458',
    city: 'Spottsville',
    state: 'KY',
    latitude: '37.853559',
    longitude: '-87.428941',
    timeZoneId: 'America/Chicago',
  },
  '42459': {
    zip: '42459',
    city: 'Sturgis',
    state: 'KY',
    latitude: '37.550004',
    longitude: '-87.985593',
    timeZoneId: 'America/Chicago',
  },
  '42460': {
    zip: '42460',
    city: 'Sullivan',
    state: 'KY',
    latitude: '37.498725',
    longitude: '-87.945255',
    timeZoneId: 'America/Chicago',
  },
  '42461': {
    zip: '42461',
    city: 'Uniontown',
    state: 'KY',
    latitude: '37.776711',
    longitude: '-87.811674',
    timeZoneId: 'America/Chicago',
  },
  '42462': {
    zip: '42462',
    city: 'Waverly',
    state: 'KY',
    latitude: '37.800053',
    longitude: '-87.770721',
    timeZoneId: 'America/Chicago',
  },
  '42463': {
    zip: '42463',
    city: 'Wheatcroft',
    state: 'KY',
    latitude: '37.489055',
    longitude: '-87.858856',
    timeZoneId: 'America/Chicago',
  },
  '42464': {
    zip: '42464',
    city: 'White Plains',
    state: 'KY',
    latitude: '37.188655',
    longitude: '-87.391351',
    timeZoneId: 'America/Chicago',
  },
  '42501': {
    zip: '42501',
    city: 'Somerset',
    state: 'KY',
    latitude: '37.092398',
    longitude: '-84.571846',
    timeZoneId: 'America/New_York',
  },
  '42502': {
    zip: '42502',
    city: 'Somerset',
    state: 'KY',
    latitude: '37.095864',
    longitude: '-84.603694',
    timeZoneId: 'America/New_York',
  },
  '42503': {
    zip: '42503',
    city: 'Somerset',
    state: 'KY',
    latitude: '37.084666',
    longitude: '-84.611545',
    timeZoneId: 'America/New_York',
  },
  '42516': {
    zip: '42516',
    city: 'Bethelridge',
    state: 'KY',
    latitude: '37.146786',
    longitude: '-84.717801',
    timeZoneId: 'America/New_York',
  },
  '42518': {
    zip: '42518',
    city: 'Bronston',
    state: 'KY',
    latitude: '36.967062',
    longitude: '-84.627631',
    timeZoneId: 'America/New_York',
  },
  '42519': {
    zip: '42519',
    city: 'Burnside',
    state: 'KY',
    latitude: '36.970557',
    longitude: '-84.572994',
    timeZoneId: 'America/New_York',
  },
  '42528': {
    zip: '42528',
    city: 'Dunnville',
    state: 'KY',
    latitude: '37.171173',
    longitude: '-85.079962',
    timeZoneId: 'America/Chicago',
  },
  '42533': {
    zip: '42533',
    city: 'Ferguson',
    state: 'KY',
    latitude: '37.0718',
    longitude: '-84.5976',
    timeZoneId: 'America/New_York',
  },
  '42539': {
    zip: '42539',
    city: 'Liberty',
    state: 'KY',
    latitude: '37.3167',
    longitude: '-84.98118',
    timeZoneId: 'America/New_York',
  },
  '42541': {
    zip: '42541',
    city: 'Middleburg',
    state: 'KY',
    latitude: '37.372342',
    longitude: '-84.837752',
    timeZoneId: 'America/New_York',
  },
  '42544': {
    zip: '42544',
    city: 'Nancy',
    state: 'KY',
    latitude: '37.05274',
    longitude: '-84.75885',
    timeZoneId: 'America/New_York',
  },
  '42553': {
    zip: '42553',
    city: 'Science Hill',
    state: 'KY',
    latitude: '37.173',
    longitude: '-84.68592',
    timeZoneId: 'America/New_York',
  },
  '42558': {
    zip: '42558',
    city: 'Tateville',
    state: 'KY',
    latitude: '36.948689',
    longitude: '-84.57667',
    timeZoneId: 'America/New_York',
  },
  '42564': {
    zip: '42564',
    city: 'West Somerset',
    state: 'KY',
    latitude: '37.088391',
    longitude: '-84.600854',
    timeZoneId: 'America/New_York',
  },
  '42565': {
    zip: '42565',
    city: 'Windsor',
    state: 'KY',
    latitude: '37.179714',
    longitude: '-84.915007',
    timeZoneId: 'America/New_York',
  },
  '42566': {
    zip: '42566',
    city: 'Yosemite',
    state: 'KY',
    latitude: '37.325225',
    longitude: '-84.928753',
    timeZoneId: 'America/New_York',
  },
  '42567': {
    zip: '42567',
    city: 'Eubank',
    state: 'KY',
    latitude: '37.258848',
    longitude: '-84.598938',
    timeZoneId: 'America/New_York',
  },
  '42602': {
    zip: '42602',
    city: 'Albany',
    state: 'KY',
    latitude: '36.691259',
    longitude: '-85.133802',
    timeZoneId: 'America/Chicago',
  },
  '42603': {
    zip: '42603',
    city: 'Alpha',
    state: 'KY',
    latitude: '36.783402',
    longitude: '-85.083842',
    timeZoneId: 'America/Chicago',
  },
  '42629': {
    zip: '42629',
    city: 'Jamestown',
    state: 'KY',
    latitude: '36.976422',
    longitude: '-85.093378',
    timeZoneId: 'America/Chicago',
  },
  '42631': {
    zip: '42631',
    city: 'Marshes Siding',
    state: 'KY',
    latitude: '36.742909',
    longitude: '-84.481254',
    timeZoneId: 'America/New_York',
  },
  '42633': {
    zip: '42633',
    city: 'Monticello',
    state: 'KY',
    latitude: '36.83413',
    longitude: '-84.845607',
    timeZoneId: 'America/New_York',
  },
  '42634': {
    zip: '42634',
    city: 'Parkers Lake',
    state: 'KY',
    latitude: '36.845915',
    longitude: '-84.451334',
    timeZoneId: 'America/New_York',
  },
  '42635': {
    zip: '42635',
    city: 'Pine Knot',
    state: 'KY',
    latitude: '36.650404',
    longitude: '-84.396639',
    timeZoneId: 'America/New_York',
  },
  '42638': {
    zip: '42638',
    city: 'Revelo',
    state: 'KY',
    latitude: '36.671672',
    longitude: '-84.467842',
    timeZoneId: 'America/New_York',
  },
  '42642': {
    zip: '42642',
    city: 'Russell Springs',
    state: 'KY',
    latitude: '37.05544',
    longitude: '-85.091503',
    timeZoneId: 'America/Chicago',
  },
  '42647': {
    zip: '42647',
    city: 'Stearns',
    state: 'KY',
    latitude: '36.681333',
    longitude: '-84.619572',
    timeZoneId: 'America/New_York',
  },
  '42649': {
    zip: '42649',
    city: 'Strunk',
    state: 'KY',
    latitude: '36.606503',
    longitude: '-84.430804',
    timeZoneId: 'America/New_York',
  },
  '42653': {
    zip: '42653',
    city: 'Whitley City',
    state: 'KY',
    latitude: '36.720391',
    longitude: '-84.465547',
    timeZoneId: 'America/New_York',
  },
  '42701': {
    zip: '42701',
    city: 'Elizabethtown',
    state: 'KY',
    latitude: '37.707586',
    longitude: '-85.861867',
    timeZoneId: 'America/New_York',
  },
  '42702': {
    zip: '42702',
    city: 'Elizabethtown',
    state: 'KY',
    latitude: '37.69406',
    longitude: '-85.860654',
    timeZoneId: 'America/New_York',
  },
  '42712': {
    zip: '42712',
    city: 'Big Clifty',
    state: 'KY',
    latitude: '37.511098',
    longitude: '-86.146073',
    timeZoneId: 'America/Chicago',
  },
  '42713': {
    zip: '42713',
    city: 'Bonnieville',
    state: 'KY',
    latitude: '37.375384',
    longitude: '-85.889297',
    timeZoneId: 'America/Chicago',
  },
  '42715': {
    zip: '42715',
    city: 'Breeding',
    state: 'KY',
    latitude: '36.959621',
    longitude: '-85.388851',
    timeZoneId: 'America/Chicago',
  },
  '42716': {
    zip: '42716',
    city: 'Buffalo',
    state: 'KY',
    latitude: '37.496324',
    longitude: '-85.675114',
    timeZoneId: 'America/New_York',
  },
  '42717': {
    zip: '42717',
    city: 'Burkesville',
    state: 'KY',
    latitude: '36.80586',
    longitude: '-85.40934',
    timeZoneId: 'America/Chicago',
  },
  '42718': {
    zip: '42718',
    city: 'Campbellsville',
    state: 'KY',
    latitude: '37.461291',
    longitude: '-85.462912',
    timeZoneId: 'America/New_York',
  },
  '42719': {
    zip: '42719',
    city: 'Campbellsville',
    state: 'KY',
    latitude: '37.341936',
    longitude: '-85.340234',
    timeZoneId: 'America/New_York',
  },
  '42720': {
    zip: '42720',
    city: 'Cane Valley',
    state: 'KY',
    latitude: '37.170284',
    longitude: '-85.326171',
    timeZoneId: 'America/Chicago',
  },
  '42721': {
    zip: '42721',
    city: 'Caneyville',
    state: 'KY',
    latitude: '37.426977',
    longitude: '-86.4867',
    timeZoneId: 'America/Chicago',
  },
  '42722': {
    zip: '42722',
    city: 'Canmer',
    state: 'KY',
    latitude: '37.271473',
    longitude: '-85.717382',
    timeZoneId: 'America/Chicago',
  },
  '42724': {
    zip: '42724',
    city: 'Cecilia',
    state: 'KY',
    latitude: '37.671855',
    longitude: '-86.025831',
    timeZoneId: 'America/New_York',
  },
  '42726': {
    zip: '42726',
    city: 'Clarkson',
    state: 'KY',
    latitude: '37.413206',
    longitude: '-86.157634',
    timeZoneId: 'America/Chicago',
  },
  '42728': {
    zip: '42728',
    city: 'Columbia',
    state: 'KY',
    latitude: '37.106526',
    longitude: '-85.278233',
    timeZoneId: 'America/Chicago',
  },
  '42729': {
    zip: '42729',
    city: 'Cub Run',
    state: 'KY',
    latitude: '37.295386',
    longitude: '-86.063461',
    timeZoneId: 'America/Chicago',
  },
  '42732': {
    zip: '42732',
    city: 'Eastview',
    state: 'KY',
    latitude: '37.606912',
    longitude: '-86.114062',
    timeZoneId: 'America/New_York',
  },
  '42733': {
    zip: '42733',
    city: 'Elk Horn',
    state: 'KY',
    latitude: '37.332813',
    longitude: '-85.197664',
    timeZoneId: 'America/New_York',
  },
  '42740': {
    zip: '42740',
    city: 'Glendale',
    state: 'KY',
    latitude: '37.591766',
    longitude: '-85.93973',
    timeZoneId: 'America/New_York',
  },
  '42741': {
    zip: '42741',
    city: 'Glens Fork',
    state: 'KY',
    latitude: '37.000018',
    longitude: '-85.247221',
    timeZoneId: 'America/Chicago',
  },
  '42742': {
    zip: '42742',
    city: 'Gradyville',
    state: 'KY',
    latitude: '37.04649',
    longitude: '-85.437092',
    timeZoneId: 'America/Chicago',
  },
  '42743': {
    zip: '42743',
    city: 'Greensburg',
    state: 'KY',
    latitude: '37.242155',
    longitude: '-85.5095',
    timeZoneId: 'America/Chicago',
  },
  '42746': {
    zip: '42746',
    city: 'Hardyville',
    state: 'KY',
    latitude: '37.222229',
    longitude: '-85.746864',
    timeZoneId: 'America/Chicago',
  },
  '42748': {
    zip: '42748',
    city: 'Hodgenville',
    state: 'KY',
    latitude: '37.57686',
    longitude: '-85.70892',
    timeZoneId: 'America/New_York',
  },
  '42749': {
    zip: '42749',
    city: 'Horse Cave',
    state: 'KY',
    latitude: '37.185069',
    longitude: '-85.878778',
    timeZoneId: 'America/Chicago',
  },
  '42753': {
    zip: '42753',
    city: 'Knifley',
    state: 'KY',
    latitude: '37.233254',
    longitude: '-85.169953',
    timeZoneId: 'America/Chicago',
  },
  '42754': {
    zip: '42754',
    city: 'Leitchfield',
    state: 'KY',
    latitude: '37.476274',
    longitude: '-86.296962',
    timeZoneId: 'America/Chicago',
  },
  '42755': {
    zip: '42755',
    city: 'Leitchfield',
    state: 'KY',
    latitude: '37.47843',
    longitude: '-86.291409',
    timeZoneId: 'America/Chicago',
  },
  '42757': {
    zip: '42757',
    city: 'Magnolia',
    state: 'KY',
    latitude: '37.376284',
    longitude: '-85.731624',
    timeZoneId: 'America/Chicago',
  },
  '42758': {
    zip: '42758',
    city: 'Mannsville',
    state: 'KY',
    latitude: '37.337992',
    longitude: '-85.343706',
    timeZoneId: 'America/New_York',
  },
  '42759': {
    zip: '42759',
    city: 'Marrowbone',
    state: 'KY',
    latitude: '36.832079',
    longitude: '-85.514937',
    timeZoneId: 'America/Chicago',
  },
  '42762': {
    zip: '42762',
    city: 'Millwood',
    state: 'KY',
    latitude: '37.442701',
    longitude: '-86.409807',
    timeZoneId: 'America/Chicago',
  },
  '42764': {
    zip: '42764',
    city: 'Mount Sherman',
    state: 'KY',
    latitude: '37.417506',
    longitude: '-85.632078',
    timeZoneId: 'America/Chicago',
  },
  '42765': {
    zip: '42765',
    city: 'Munfordville',
    state: 'KY',
    latitude: '37.293705',
    longitude: '-85.916947',
    timeZoneId: 'America/Chicago',
  },
  '42776': {
    zip: '42776',
    city: 'Sonora',
    state: 'KY',
    latitude: '37.52738',
    longitude: '-85.932922',
    timeZoneId: 'America/New_York',
  },
  '42782': {
    zip: '42782',
    city: 'Summersville',
    state: 'KY',
    latitude: '37.336525',
    longitude: '-85.699899',
    timeZoneId: 'America/Chicago',
  },
  '42784': {
    zip: '42784',
    city: 'Upton',
    state: 'KY',
    latitude: '37.469104',
    longitude: '-85.925903',
    timeZoneId: 'America/New_York',
  },
  '42788': {
    zip: '42788',
    city: 'White Mills',
    state: 'KY',
    latitude: '37.544181',
    longitude: '-86.030281',
    timeZoneId: 'America/New_York',
  },
  '43001': {
    zip: '43001',
    city: 'Alexandria',
    state: 'OH',
    latitude: '40.092467',
    longitude: '-82.608341',
    timeZoneId: 'America/New_York',
  },
  '43002': {
    zip: '43002',
    city: 'Amlin',
    state: 'OH',
    latitude: '40.078159',
    longitude: '-83.169222',
    timeZoneId: 'America/New_York',
  },
  '43003': {
    zip: '43003',
    city: 'Ashley',
    state: 'OH',
    latitude: '40.418525',
    longitude: '-82.974431',
    timeZoneId: 'America/New_York',
  },
  '43004': {
    zip: '43004',
    city: 'Blacklick',
    state: 'OH',
    latitude: '40.011212',
    longitude: '-82.80946',
    timeZoneId: 'America/New_York',
  },
  '43005': {
    zip: '43005',
    city: 'Bladensburg',
    state: 'OH',
    latitude: '40.290487',
    longitude: '-82.291148',
    timeZoneId: 'America/New_York',
  },
  '43006': {
    zip: '43006',
    city: 'Brinkhaven',
    state: 'OH',
    latitude: '40.471026',
    longitude: '-82.188196',
    timeZoneId: 'America/New_York',
  },
  '43007': {
    zip: '43007',
    city: 'Broadway',
    state: 'OH',
    latitude: '40.337547',
    longitude: '-83.411104',
    timeZoneId: 'America/New_York',
  },
  '43008': {
    zip: '43008',
    city: 'Buckeye Lake',
    state: 'OH',
    latitude: '39.937333',
    longitude: '-82.476328',
    timeZoneId: 'America/New_York',
  },
  '43009': {
    zip: '43009',
    city: 'Cable',
    state: 'OH',
    latitude: '40.173885',
    longitude: '-83.644285',
    timeZoneId: 'America/New_York',
  },
  '43010': {
    zip: '43010',
    city: 'Catawba',
    state: 'OH',
    latitude: '40.001052',
    longitude: '-83.622964',
    timeZoneId: 'America/New_York',
  },
  '43011': {
    zip: '43011',
    city: 'Centerburg',
    state: 'OH',
    latitude: '40.307025',
    longitude: '-82.669574',
    timeZoneId: 'America/New_York',
  },
  '43013': {
    zip: '43013',
    city: 'Croton',
    state: 'OH',
    latitude: '40.234813',
    longitude: '-82.691307',
    timeZoneId: 'America/New_York',
  },
  '43014': {
    zip: '43014',
    city: 'Danville',
    state: 'OH',
    latitude: '40.463831',
    longitude: '-82.26231',
    timeZoneId: 'America/New_York',
  },
  '43015': {
    zip: '43015',
    city: 'Delaware',
    state: 'OH',
    latitude: '40.28538',
    longitude: '-83.0595',
    timeZoneId: 'America/New_York',
  },
  '43016': {
    zip: '43016',
    city: 'Dublin',
    state: 'OH',
    latitude: '40.12777',
    longitude: '-83.09629',
    timeZoneId: 'America/New_York',
  },
  '43017': {
    zip: '43017',
    city: 'Dublin',
    state: 'OH',
    latitude: '40.100412',
    longitude: '-83.115807',
    timeZoneId: 'America/New_York',
  },
  '43018': {
    zip: '43018',
    city: 'Etna',
    state: 'OH',
    latitude: '39.960293',
    longitude: '-82.682353',
    timeZoneId: 'America/New_York',
  },
  '43019': {
    zip: '43019',
    city: 'Fredericktown',
    state: 'OH',
    latitude: '40.491704',
    longitude: '-82.53339',
    timeZoneId: 'America/New_York',
  },
  '43021': {
    zip: '43021',
    city: 'Galena',
    state: 'OH',
    latitude: '40.195977',
    longitude: '-82.899957',
    timeZoneId: 'America/New_York',
  },
  '43022': {
    zip: '43022',
    city: 'Gambier',
    state: 'OH',
    latitude: '40.346971',
    longitude: '-82.347352',
    timeZoneId: 'America/New_York',
  },
  '43023': {
    zip: '43023',
    city: 'Granville',
    state: 'OH',
    latitude: '40.069498',
    longitude: '-82.526879',
    timeZoneId: 'America/New_York',
  },
  '43025': {
    zip: '43025',
    city: 'Hebron',
    state: 'OH',
    latitude: '39.97458',
    longitude: '-82.50594',
    timeZoneId: 'America/New_York',
  },
  '43026': {
    zip: '43026',
    city: 'Hilliard',
    state: 'OH',
    latitude: '40.032483',
    longitude: '-83.135808',
    timeZoneId: 'America/New_York',
  },
  '43027': {
    zip: '43027',
    city: 'Homer',
    state: 'OH',
    latitude: '40.244808',
    longitude: '-82.516572',
    timeZoneId: 'America/New_York',
  },
  '43028': {
    zip: '43028',
    city: 'Howard',
    state: 'OH',
    latitude: '40.39104',
    longitude: '-82.30386',
    timeZoneId: 'America/New_York',
  },
  '43029': {
    zip: '43029',
    city: 'Irwin',
    state: 'OH',
    latitude: '40.089788',
    longitude: '-83.455458',
    timeZoneId: 'America/New_York',
  },
  '43030': {
    zip: '43030',
    city: 'Jacksontown',
    state: 'OH',
    latitude: '39.960557',
    longitude: '-82.413494',
    timeZoneId: 'America/New_York',
  },
  '43031': {
    zip: '43031',
    city: 'Johnstown',
    state: 'OH',
    latitude: '40.160799',
    longitude: '-82.655946',
    timeZoneId: 'America/New_York',
  },
  '43032': {
    zip: '43032',
    city: 'Kilbourne',
    state: 'OH',
    latitude: '40.3286',
    longitude: '-82.959025',
    timeZoneId: 'America/New_York',
  },
  '43033': {
    zip: '43033',
    city: 'Kirkersville',
    state: 'OH',
    latitude: '39.960707',
    longitude: '-82.602939',
    timeZoneId: 'America/New_York',
  },
  '43035': {
    zip: '43035',
    city: 'Lewis Center',
    state: 'OH',
    latitude: '40.179771',
    longitude: '-83.009937',
    timeZoneId: 'America/New_York',
  },
  '43036': {
    zip: '43036',
    city: 'Magnetic Springs',
    state: 'OH',
    latitude: '40.352146',
    longitude: '-83.262257',
    timeZoneId: 'America/New_York',
  },
  '43037': {
    zip: '43037',
    city: 'Martinsburg',
    state: 'OH',
    latitude: '40.272989',
    longitude: '-82.333037',
    timeZoneId: 'America/New_York',
  },
  '43040': {
    zip: '43040',
    city: 'Marysville',
    state: 'OH',
    latitude: '40.256632',
    longitude: '-83.375483',
    timeZoneId: 'America/New_York',
  },
  '43041': {
    zip: '43041',
    city: 'Marysville',
    state: 'OH',
    latitude: '40.238271',
    longitude: '-83.368023',
    timeZoneId: 'America/New_York',
  },
  '43044': {
    zip: '43044',
    city: 'Mechanicsburg',
    state: 'OH',
    latitude: '40.064517',
    longitude: '-83.580112',
    timeZoneId: 'America/New_York',
  },
  '43045': {
    zip: '43045',
    city: 'Milford Center',
    state: 'OH',
    latitude: '40.174261',
    longitude: '-83.443176',
    timeZoneId: 'America/New_York',
  },
  '43046': {
    zip: '43046',
    city: 'Millersport',
    state: 'OH',
    latitude: '39.89895',
    longitude: '-82.526218',
    timeZoneId: 'America/New_York',
  },
  '43047': {
    zip: '43047',
    city: 'Mingo',
    state: 'OH',
    latitude: '40.205526',
    longitude: '-83.644226',
    timeZoneId: 'America/New_York',
  },
  '43048': {
    zip: '43048',
    city: 'Mount Liberty',
    state: 'OH',
    latitude: '40.350178',
    longitude: '-82.630042',
    timeZoneId: 'America/New_York',
  },
  '43050': {
    zip: '43050',
    city: 'Mount Vernon',
    state: 'OH',
    latitude: '40.382215',
    longitude: '-82.483862',
    timeZoneId: 'America/New_York',
  },
  '43054': {
    zip: '43054',
    city: 'New Albany',
    state: 'OH',
    latitude: '40.086466',
    longitude: '-82.808804',
    timeZoneId: 'America/New_York',
  },
  '43055': {
    zip: '43055',
    city: 'Newark',
    state: 'OH',
    latitude: '40.116667',
    longitude: '-82.433333',
    timeZoneId: 'America/New_York',
  },
  '43056': {
    zip: '43056',
    city: 'Heath',
    state: 'OH',
    latitude: '40.00488',
    longitude: '-82.34646',
    timeZoneId: 'America/New_York',
  },
  '43058': {
    zip: '43058',
    city: 'Newark',
    state: 'OH',
    latitude: '40.059575',
    longitude: '-82.403784',
    timeZoneId: 'America/New_York',
  },
  '43060': {
    zip: '43060',
    city: 'North Lewisburg',
    state: 'OH',
    latitude: '40.21909',
    longitude: '-83.569313',
    timeZoneId: 'America/New_York',
  },
  '43061': {
    zip: '43061',
    city: 'Ostrander',
    state: 'OH',
    latitude: '40.285125',
    longitude: '-83.202729',
    timeZoneId: 'America/New_York',
  },
  '43062': {
    zip: '43062',
    city: 'Pataskala',
    state: 'OH',
    latitude: '40.005979',
    longitude: '-82.676213',
    timeZoneId: 'America/New_York',
  },
  '43064': {
    zip: '43064',
    city: 'Plain City',
    state: 'OH',
    latitude: '40.09854',
    longitude: '-83.298',
    timeZoneId: 'America/New_York',
  },
  '43065': {
    zip: '43065',
    city: 'Powell',
    state: 'OH',
    latitude: '40.172501',
    longitude: '-83.093346',
    timeZoneId: 'America/New_York',
  },
  '43066': {
    zip: '43066',
    city: 'Radnor',
    state: 'OH',
    latitude: '40.396842',
    longitude: '-83.162111',
    timeZoneId: 'America/New_York',
  },
  '43067': {
    zip: '43067',
    city: 'Raymond',
    state: 'OH',
    latitude: '40.24722',
    longitude: '-83.36658',
    timeZoneId: 'America/New_York',
  },
  '43068': {
    zip: '43068',
    city: 'Reynoldsburg',
    state: 'OH',
    latitude: '39.962571',
    longitude: '-82.783851',
    timeZoneId: 'America/New_York',
  },
  '43069': {
    zip: '43069',
    city: 'Reynoldsburg',
    state: 'OH',
    latitude: '39.96345',
    longitude: '-82.782984',
    timeZoneId: 'America/New_York',
  },
  '43070': {
    zip: '43070',
    city: 'Rosewood',
    state: 'OH',
    latitude: '40.217305',
    longitude: '-83.959489',
    timeZoneId: 'America/New_York',
  },
  '43071': {
    zip: '43071',
    city: 'Saint Louisville',
    state: 'OH',
    latitude: '40.177388',
    longitude: '-82.375739',
    timeZoneId: 'America/New_York',
  },
  '43072': {
    zip: '43072',
    city: 'Saint Paris',
    state: 'OH',
    latitude: '40.125518',
    longitude: '-83.957665',
    timeZoneId: 'America/New_York',
  },
  '43073': {
    zip: '43073',
    city: 'Summit Station',
    state: 'OH',
    latitude: '40.001966',
    longitude: '-82.751027',
    timeZoneId: 'America/New_York',
  },
  '43074': {
    zip: '43074',
    city: 'Sunbury',
    state: 'OH',
    latitude: '40.269436',
    longitude: '-82.843951',
    timeZoneId: 'America/New_York',
  },
  '43076': {
    zip: '43076',
    city: 'Thornville',
    state: 'OH',
    latitude: '39.876048',
    longitude: '-82.363065',
    timeZoneId: 'America/New_York',
  },
  '43077': {
    zip: '43077',
    city: 'Unionville Center',
    state: 'OH',
    latitude: '40.138683',
    longitude: '-83.338596',
    timeZoneId: 'America/New_York',
  },
  '43078': {
    zip: '43078',
    city: 'Urbana',
    state: 'OH',
    latitude: '40.11864',
    longitude: '-83.76762',
    timeZoneId: 'America/New_York',
  },
  '43080': {
    zip: '43080',
    city: 'Utica',
    state: 'OH',
    latitude: '40.230307',
    longitude: '-82.454626',
    timeZoneId: 'America/New_York',
  },
  '43081': {
    zip: '43081',
    city: 'Westerville',
    state: 'OH',
    latitude: '40.110098',
    longitude: '-82.907426',
    timeZoneId: 'America/New_York',
  },
  '43082': {
    zip: '43082',
    city: 'Westerville',
    state: 'OH',
    latitude: '40.121049',
    longitude: '-82.929557',
    timeZoneId: 'America/New_York',
  },
  '43083': {
    zip: '43083',
    city: 'Westville',
    state: 'OH',
    latitude: '40.108381',
    longitude: '-83.836233',
    timeZoneId: 'America/New_York',
  },
  '43084': {
    zip: '43084',
    city: 'Woodstock',
    state: 'OH',
    latitude: '40.16198',
    longitude: '-83.543234',
    timeZoneId: 'America/New_York',
  },
  '43085': {
    zip: '43085',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.098773',
    longitude: '-83.017045',
    timeZoneId: 'America/New_York',
  },
  '43086': {
    zip: '43086',
    city: 'Westerville',
    state: 'OH',
    latitude: '40.117449',
    longitude: '-82.916664',
    timeZoneId: 'America/New_York',
  },
  '43093': {
    zip: '43093',
    city: 'Newark',
    state: 'OH',
    latitude: '40.058168',
    longitude: '-82.45232',
    timeZoneId: 'America/New_York',
  },
  '43101': {
    zip: '43101',
    city: 'Adelphi',
    state: 'OH',
    latitude: '39.464919',
    longitude: '-82.748279',
    timeZoneId: 'America/New_York',
  },
  '43102': {
    zip: '43102',
    city: 'Amanda',
    state: 'OH',
    latitude: '39.640743',
    longitude: '-82.757046',
    timeZoneId: 'America/New_York',
  },
  '43103': {
    zip: '43103',
    city: 'Ashville',
    state: 'OH',
    latitude: '39.72678',
    longitude: '-82.95294',
    timeZoneId: 'America/New_York',
  },
  '43105': {
    zip: '43105',
    city: 'Baltimore',
    state: 'OH',
    latitude: '39.858878',
    longitude: '-82.620111',
    timeZoneId: 'America/New_York',
  },
  '43106': {
    zip: '43106',
    city: 'Bloomingburg',
    state: 'OH',
    latitude: '39.632887',
    longitude: '-83.401809',
    timeZoneId: 'America/New_York',
  },
  '43107': {
    zip: '43107',
    city: 'Bremen',
    state: 'OH',
    latitude: '39.696084',
    longitude: '-82.422138',
    timeZoneId: 'America/New_York',
  },
  '43109': {
    zip: '43109',
    city: 'Brice',
    state: 'OH',
    latitude: '39.915024',
    longitude: '-82.832813',
    timeZoneId: 'America/New_York',
  },
  '43110': {
    zip: '43110',
    city: 'Canal Winchester',
    state: 'OH',
    latitude: '39.82757',
    longitude: '-82.794186',
    timeZoneId: 'America/New_York',
  },
  '43111': {
    zip: '43111',
    city: 'Carbon Hill',
    state: 'OH',
    latitude: '39.501058',
    longitude: '-82.236496',
    timeZoneId: 'America/New_York',
  },
  '43112': {
    zip: '43112',
    city: 'Carroll',
    state: 'OH',
    latitude: '39.80237',
    longitude: '-82.710428',
    timeZoneId: 'America/New_York',
  },
  '43113': {
    zip: '43113',
    city: 'Circleville',
    state: 'OH',
    latitude: '39.594775',
    longitude: '-82.936458',
    timeZoneId: 'America/New_York',
  },
  '43115': {
    zip: '43115',
    city: 'Clarksburg',
    state: 'OH',
    latitude: '39.490249',
    longitude: '-83.159647',
    timeZoneId: 'America/New_York',
  },
  '43116': {
    zip: '43116',
    city: 'Commercial Point',
    state: 'OH',
    latitude: '39.770313',
    longitude: '-83.05834',
    timeZoneId: 'America/New_York',
  },
  '43117': {
    zip: '43117',
    city: 'Derby',
    state: 'OH',
    latitude: '39.764621',
    longitude: '-83.208368',
    timeZoneId: 'America/New_York',
  },
  '43119': {
    zip: '43119',
    city: 'Galloway',
    state: 'OH',
    latitude: '39.93672',
    longitude: '-83.19444',
    timeZoneId: 'America/New_York',
  },
  '43123': {
    zip: '43123',
    city: 'Grove City',
    state: 'OH',
    latitude: '39.881816',
    longitude: '-83.091047',
    timeZoneId: 'America/New_York',
  },
  '43125': {
    zip: '43125',
    city: 'Groveport',
    state: 'OH',
    latitude: '39.844452',
    longitude: '-82.879107',
    timeZoneId: 'America/New_York',
  },
  '43126': {
    zip: '43126',
    city: 'Harrisburg',
    state: 'OH',
    latitude: '39.809767',
    longitude: '-83.168194',
    timeZoneId: 'America/New_York',
  },
  '43127': {
    zip: '43127',
    city: 'Haydenville',
    state: 'OH',
    latitude: '39.474543',
    longitude: '-82.317784',
    timeZoneId: 'America/New_York',
  },
  '43128': {
    zip: '43128',
    city: 'Jeffersonville',
    state: 'OH',
    latitude: '39.65666',
    longitude: '-83.573926',
    timeZoneId: 'America/New_York',
  },
  '43130': {
    zip: '43130',
    city: 'Lancaster',
    state: 'OH',
    latitude: '39.70728',
    longitude: '-82.60788',
    timeZoneId: 'America/New_York',
  },
  '43135': {
    zip: '43135',
    city: 'Laurelville',
    state: 'OH',
    latitude: '39.476647',
    longitude: '-82.682155',
    timeZoneId: 'America/New_York',
  },
  '43136': {
    zip: '43136',
    city: 'Lithopolis',
    state: 'OH',
    latitude: '39.804527',
    longitude: '-82.808331',
    timeZoneId: 'America/New_York',
  },
  '43137': {
    zip: '43137',
    city: 'Lockbourne',
    state: 'OH',
    latitude: '39.832831',
    longitude: '-82.978833',
    timeZoneId: 'America/New_York',
  },
  '43138': {
    zip: '43138',
    city: 'Logan',
    state: 'OH',
    latitude: '39.523862',
    longitude: '-82.415172',
    timeZoneId: 'America/New_York',
  },
  '43140': {
    zip: '43140',
    city: 'London',
    state: 'OH',
    latitude: '39.89106',
    longitude: '-83.40534',
    timeZoneId: 'America/New_York',
  },
  '43142': {
    zip: '43142',
    city: 'Milledgeville',
    state: 'OH',
    latitude: '39.595174',
    longitude: '-83.587871',
    timeZoneId: 'America/New_York',
  },
  '43143': {
    zip: '43143',
    city: 'Mount Sterling',
    state: 'OH',
    latitude: '39.741372',
    longitude: '-83.306744',
    timeZoneId: 'America/New_York',
  },
  '43144': {
    zip: '43144',
    city: 'Murray City',
    state: 'OH',
    latitude: '39.513016',
    longitude: '-82.165595',
    timeZoneId: 'America/New_York',
  },
  '43145': {
    zip: '43145',
    city: 'New Holland',
    state: 'OH',
    latitude: '39.558554',
    longitude: '-83.232584',
    timeZoneId: 'America/New_York',
  },
  '43146': {
    zip: '43146',
    city: 'Orient',
    state: 'OH',
    latitude: '39.771545',
    longitude: '-83.148051',
    timeZoneId: 'America/New_York',
  },
  '43147': {
    zip: '43147',
    city: 'Pickerington',
    state: 'OH',
    latitude: '39.903244',
    longitude: '-82.750689',
    timeZoneId: 'America/New_York',
  },
  '43148': {
    zip: '43148',
    city: 'Pleasantville',
    state: 'OH',
    latitude: '39.814868',
    longitude: '-82.502382',
    timeZoneId: 'America/New_York',
  },
  '43149': {
    zip: '43149',
    city: 'Rockbridge',
    state: 'OH',
    latitude: '39.52086',
    longitude: '-82.58568',
    timeZoneId: 'America/New_York',
  },
  '43150': {
    zip: '43150',
    city: 'Rushville',
    state: 'OH',
    latitude: '39.777131',
    longitude: '-82.425291',
    timeZoneId: 'America/New_York',
  },
  '43151': {
    zip: '43151',
    city: 'Sedalia',
    state: 'OH',
    latitude: '39.72996',
    longitude: '-83.47169',
    timeZoneId: 'America/New_York',
  },
  '43152': {
    zip: '43152',
    city: 'South Bloomingville',
    state: 'OH',
    latitude: '39.40794',
    longitude: '-82.61154',
    timeZoneId: 'America/New_York',
  },
  '43153': {
    zip: '43153',
    city: 'South Solon',
    state: 'OH',
    latitude: '39.741198',
    longitude: '-83.564833',
    timeZoneId: 'America/New_York',
  },
  '43154': {
    zip: '43154',
    city: 'Stoutsville',
    state: 'OH',
    latitude: '39.600098',
    longitude: '-82.817282',
    timeZoneId: 'America/New_York',
  },
  '43155': {
    zip: '43155',
    city: 'Sugar Grove',
    state: 'OH',
    latitude: '39.630339',
    longitude: '-82.535734',
    timeZoneId: 'America/New_York',
  },
  '43156': {
    zip: '43156',
    city: 'Tarlton',
    state: 'OH',
    latitude: '39.555824',
    longitude: '-82.775783',
    timeZoneId: 'America/New_York',
  },
  '43157': {
    zip: '43157',
    city: 'Thurston',
    state: 'OH',
    latitude: '39.840416',
    longitude: '-82.54484',
    timeZoneId: 'America/New_York',
  },
  '43158': {
    zip: '43158',
    city: 'Union Furnace',
    state: 'OH',
    latitude: '39.451134',
    longitude: '-82.362582',
    timeZoneId: 'America/New_York',
  },
  '43160': {
    zip: '43160',
    city: 'Washington Court House',
    state: 'OH',
    latitude: '39.536064',
    longitude: '-83.442724',
    timeZoneId: 'America/New_York',
  },
  '43162': {
    zip: '43162',
    city: 'West Jefferson',
    state: 'OH',
    latitude: '39.943824',
    longitude: '-83.293373',
    timeZoneId: 'America/New_York',
  },
  '43164': {
    zip: '43164',
    city: 'Williamsport',
    state: 'OH',
    latitude: '39.600994',
    longitude: '-83.122085',
    timeZoneId: 'America/New_York',
  },
  '43194': {
    zip: '43194',
    city: 'Lockbourne',
    state: 'OH',
    latitude: '39.808925',
    longitude: '-82.977053',
    timeZoneId: 'America/New_York',
  },
  '43195': {
    zip: '43195',
    city: 'Groveport',
    state: 'OH',
    latitude: '39.854672',
    longitude: '-82.88364',
    timeZoneId: 'America/New_York',
  },
  '43199': {
    zip: '43199',
    city: 'Groveport',
    state: 'OH',
    latitude: '39.859691',
    longitude: '-82.894413',
    timeZoneId: 'America/New_York',
  },
  '43201': {
    zip: '43201',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.99135',
    longitude: '-83.001104',
    timeZoneId: 'America/New_York',
  },
  '43202': {
    zip: '43202',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.020415',
    longitude: '-83.01189',
    timeZoneId: 'America/New_York',
  },
  '43203': {
    zip: '43203',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.971464',
    longitude: '-82.969937',
    timeZoneId: 'America/New_York',
  },
  '43204': {
    zip: '43204',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.955851',
    longitude: '-83.082221',
    timeZoneId: 'America/New_York',
  },
  '43205': {
    zip: '43205',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.959225',
    longitude: '-82.966539',
    timeZoneId: 'America/New_York',
  },
  '43206': {
    zip: '43206',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.940669',
    longitude: '-82.978592',
    timeZoneId: 'America/New_York',
  },
  '43207': {
    zip: '43207',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.89394',
    longitude: '-82.95408',
    timeZoneId: 'America/New_York',
  },
  '43209': {
    zip: '43209',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.959129',
    longitude: '-82.92899',
    timeZoneId: 'America/New_York',
  },
  '43210': {
    zip: '43210',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.007855',
    longitude: '-83.021452',
    timeZoneId: 'America/New_York',
  },
  '43211': {
    zip: '43211',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.013425',
    longitude: '-82.971974',
    timeZoneId: 'America/New_York',
  },
  '43212': {
    zip: '43212',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.98726',
    longitude: '-83.043294',
    timeZoneId: 'America/New_York',
  },
  '43213': {
    zip: '43213',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.965354',
    longitude: '-82.875172',
    timeZoneId: 'America/New_York',
  },
  '43214': {
    zip: '43214',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.053773',
    longitude: '-83.025973',
    timeZoneId: 'America/New_York',
  },
  '43215': {
    zip: '43215',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.965846',
    longitude: '-83.003648',
    timeZoneId: 'America/New_York',
  },
  '43216': {
    zip: '43216',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.974192',
    longitude: '-82.898226',
    timeZoneId: 'America/New_York',
  },
  '43217': {
    zip: '43217',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.825664',
    longitude: '-82.947527',
    timeZoneId: 'America/New_York',
  },
  '43218': {
    zip: '43218',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.966616',
    longitude: '-83.012341',
    timeZoneId: 'America/New_York',
  },
  '43219': {
    zip: '43219',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.005201',
    longitude: '-82.931203',
    timeZoneId: 'America/New_York',
  },
  '43220': {
    zip: '43220',
    city: 'Columbus',
    state: 'OH',
    latitude: '40',
    longitude: '-83.066667',
    timeZoneId: 'America/New_York',
  },
  '43221': {
    zip: '43221',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.014525',
    longitude: '-83.068936',
    timeZoneId: 'America/New_York',
  },
  '43222': {
    zip: '43222',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.956475',
    longitude: '-83.029361',
    timeZoneId: 'America/New_York',
  },
  '43223': {
    zip: '43223',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.935259',
    longitude: '-83.043278',
    timeZoneId: 'America/New_York',
  },
  '43224': {
    zip: '43224',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.040081',
    longitude: '-82.966132',
    timeZoneId: 'America/New_York',
  },
  '43226': {
    zip: '43226',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.978571',
    longitude: '-83.163374',
    timeZoneId: 'America/New_York',
  },
  '43227': {
    zip: '43227',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.937858',
    longitude: '-82.887113',
    timeZoneId: 'America/New_York',
  },
  '43228': {
    zip: '43228',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.951399',
    longitude: '-83.128',
    timeZoneId: 'America/New_York',
  },
  '43229': {
    zip: '43229',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.083167',
    longitude: '-82.975693',
    timeZoneId: 'America/New_York',
  },
  '43230': {
    zip: '43230',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.044616',
    longitude: '-82.856435',
    timeZoneId: 'America/New_York',
  },
  '43231': {
    zip: '43231',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.080299',
    longitude: '-82.9378',
    timeZoneId: 'America/New_York',
  },
  '43232': {
    zip: '43232',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.917023',
    longitude: '-82.875392',
    timeZoneId: 'America/New_York',
  },
  '43234': {
    zip: '43234',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.10298',
    longitude: '-83.055995',
    timeZoneId: 'America/New_York',
  },
  '43235': {
    zip: '43235',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.120326',
    longitude: '-83.033953',
    timeZoneId: 'America/New_York',
  },
  '43236': {
    zip: '43236',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.000056',
    longitude: '-82.887958',
    timeZoneId: 'America/New_York',
  },
  '43240': {
    zip: '43240',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.140503',
    longitude: '-82.986473',
    timeZoneId: 'America/New_York',
  },
  '43251': {
    zip: '43251',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.959725',
    longitude: '-83.020801',
    timeZoneId: 'America/New_York',
  },
  '43260': {
    zip: '43260',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.96287',
    longitude: '-82.998416',
    timeZoneId: 'America/New_York',
  },
  '43266': {
    zip: '43266',
    city: 'Columbus',
    state: 'OH',
    latitude: '40.056417',
    longitude: '-82.968199',
    timeZoneId: 'America/New_York',
  },
  '43268': {
    zip: '43268',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.964398',
    longitude: '-82.994319',
    timeZoneId: 'America/New_York',
  },
  '43270': {
    zip: '43270',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.96497',
    longitude: '-83.000319',
    timeZoneId: 'America/New_York',
  },
  '43271': {
    zip: '43271',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.970935',
    longitude: '-83.026273',
    timeZoneId: 'America/New_York',
  },
  '43272': {
    zip: '43272',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.960349',
    longitude: '-83.00566',
    timeZoneId: 'America/New_York',
  },
  '43279': {
    zip: '43279',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.966103',
    longitude: '-83.025448',
    timeZoneId: 'America/New_York',
  },
  '43287': {
    zip: '43287',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.961425',
    longitude: '-82.999386',
    timeZoneId: 'America/New_York',
  },
  '43291': {
    zip: '43291',
    city: 'Columbus',
    state: 'OH',
    latitude: '39.976602',
    longitude: '-82.856827',
    timeZoneId: 'America/New_York',
  },
  '43301': {
    zip: '43301',
    city: 'Marion',
    state: 'OH',
    latitude: '40.565693',
    longitude: '-83.124872',
    timeZoneId: 'America/New_York',
  },
  '43302': {
    zip: '43302',
    city: 'Marion',
    state: 'OH',
    latitude: '40.588875',
    longitude: '-83.12552',
    timeZoneId: 'America/New_York',
  },
  '43310': {
    zip: '43310',
    city: 'Belle Center',
    state: 'OH',
    latitude: '40.506954',
    longitude: '-83.78957',
    timeZoneId: 'America/New_York',
  },
  '43311': {
    zip: '43311',
    city: 'Bellefontaine',
    state: 'OH',
    latitude: '40.359628',
    longitude: '-83.757376',
    timeZoneId: 'America/New_York',
  },
  '43314': {
    zip: '43314',
    city: 'Caledonia',
    state: 'OH',
    latitude: '40.64664',
    longitude: '-82.96116',
    timeZoneId: 'America/New_York',
  },
  '43315': {
    zip: '43315',
    city: 'Cardington',
    state: 'OH',
    latitude: '40.48213',
    longitude: '-82.860172',
    timeZoneId: 'America/New_York',
  },
  '43316': {
    zip: '43316',
    city: 'Carey',
    state: 'OH',
    latitude: '40.943398',
    longitude: '-83.381939',
    timeZoneId: 'America/New_York',
  },
  '43317': {
    zip: '43317',
    city: 'Chesterville',
    state: 'OH',
    latitude: '40.479777',
    longitude: '-82.684801',
    timeZoneId: 'America/New_York',
  },
  '43318': {
    zip: '43318',
    city: 'De Graff',
    state: 'OH',
    latitude: '40.329651',
    longitude: '-83.914601',
    timeZoneId: 'America/New_York',
  },
  '43319': {
    zip: '43319',
    city: 'East Liberty',
    state: 'OH',
    latitude: '40.306292',
    longitude: '-83.580853',
    timeZoneId: 'America/New_York',
  },
  '43320': {
    zip: '43320',
    city: 'Edison',
    state: 'OH',
    latitude: '40.577397',
    longitude: '-82.883382',
    timeZoneId: 'America/New_York',
  },
  '43321': {
    zip: '43321',
    city: 'Fulton',
    state: 'OH',
    latitude: '40.463265',
    longitude: '-82.829866',
    timeZoneId: 'America/New_York',
  },
  '43322': {
    zip: '43322',
    city: 'Green Camp',
    state: 'OH',
    latitude: '40.53549',
    longitude: '-83.214701',
    timeZoneId: 'America/New_York',
  },
  '43323': {
    zip: '43323',
    city: 'Harpster',
    state: 'OH',
    latitude: '40.743966',
    longitude: '-83.235166',
    timeZoneId: 'America/New_York',
  },
  '43324': {
    zip: '43324',
    city: 'Huntsville',
    state: 'OH',
    latitude: '40.447389',
    longitude: '-83.809071',
    timeZoneId: 'America/New_York',
  },
  '43325': {
    zip: '43325',
    city: 'Iberia',
    state: 'OH',
    latitude: '40.666089',
    longitude: '-82.841658',
    timeZoneId: 'America/New_York',
  },
  '43326': {
    zip: '43326',
    city: 'Kenton',
    state: 'OH',
    latitude: '40.6437',
    longitude: '-83.607011',
    timeZoneId: 'America/New_York',
  },
  '43330': {
    zip: '43330',
    city: 'Kirby',
    state: 'OH',
    latitude: '40.810973',
    longitude: '-83.420189',
    timeZoneId: 'America/New_York',
  },
  '43331': {
    zip: '43331',
    city: 'Lakeview',
    state: 'OH',
    latitude: '40.49848',
    longitude: '-83.907723',
    timeZoneId: 'America/New_York',
  },
  '43332': {
    zip: '43332',
    city: 'La Rue',
    state: 'OH',
    latitude: '40.574908',
    longitude: '-83.364808',
    timeZoneId: 'America/New_York',
  },
  '43333': {
    zip: '43333',
    city: 'Lewistown',
    state: 'OH',
    latitude: '40.439457',
    longitude: '-83.916891',
    timeZoneId: 'America/New_York',
  },
  '43334': {
    zip: '43334',
    city: 'Marengo',
    state: 'OH',
    latitude: '40.3857',
    longitude: '-82.82976',
    timeZoneId: 'America/New_York',
  },
  '43335': {
    zip: '43335',
    city: 'Martel',
    state: 'OH',
    latitude: '40.663648',
    longitude: '-82.910449',
    timeZoneId: 'America/New_York',
  },
  '43336': {
    zip: '43336',
    city: 'Middleburg',
    state: 'OH',
    latitude: '40.293906',
    longitude: '-83.584083',
    timeZoneId: 'America/New_York',
  },
  '43337': {
    zip: '43337',
    city: 'Morral',
    state: 'OH',
    latitude: '40.676941',
    longitude: '-83.253323',
    timeZoneId: 'America/New_York',
  },
  '43338': {
    zip: '43338',
    city: 'Mount Gilead',
    state: 'OH',
    latitude: '40.560186',
    longitude: '-82.7817',
    timeZoneId: 'America/New_York',
  },
  '43340': {
    zip: '43340',
    city: 'Mount Victory',
    state: 'OH',
    latitude: '40.552721',
    longitude: '-83.495057',
    timeZoneId: 'America/New_York',
  },
  '43341': {
    zip: '43341',
    city: 'New Bloomington',
    state: 'OH',
    latitude: '40.591276',
    longitude: '-83.317071',
    timeZoneId: 'America/New_York',
  },
  '43342': {
    zip: '43342',
    city: 'Prospect',
    state: 'OH',
    latitude: '40.47405',
    longitude: '-83.175188',
    timeZoneId: 'America/New_York',
  },
  '43343': {
    zip: '43343',
    city: 'Quincy',
    state: 'OH',
    latitude: '40.318945',
    longitude: '-83.974251',
    timeZoneId: 'America/New_York',
  },
  '43344': {
    zip: '43344',
    city: 'Richwood',
    state: 'OH',
    latitude: '40.453611',
    longitude: '-83.455556',
    timeZoneId: 'America/New_York',
  },
  '43345': {
    zip: '43345',
    city: 'Ridgeway',
    state: 'OH',
    latitude: '40.52877',
    longitude: '-83.584574',
    timeZoneId: 'America/New_York',
  },
  '43346': {
    zip: '43346',
    city: 'Roundhead',
    state: 'OH',
    latitude: '40.566781',
    longitude: '-83.842051',
    timeZoneId: 'America/New_York',
  },
  '43347': {
    zip: '43347',
    city: 'Rushsylvania',
    state: 'OH',
    latitude: '40.470088',
    longitude: '-83.6662',
    timeZoneId: 'America/New_York',
  },
  '43348': {
    zip: '43348',
    city: 'Russells Point',
    state: 'OH',
    latitude: '40.468795',
    longitude: '-83.879904',
    timeZoneId: 'America/New_York',
  },
  '43349': {
    zip: '43349',
    city: 'Shauck',
    state: 'OH',
    latitude: '40.618676',
    longitude: '-82.665978',
    timeZoneId: 'America/New_York',
  },
  '43350': {
    zip: '43350',
    city: 'Sparta',
    state: 'OH',
    latitude: '40.354745',
    longitude: '-82.711177',
    timeZoneId: 'America/New_York',
  },
  '43351': {
    zip: '43351',
    city: 'Upper Sandusky',
    state: 'OH',
    latitude: '40.81548',
    longitude: '-83.31012',
    timeZoneId: 'America/New_York',
  },
  '43356': {
    zip: '43356',
    city: 'Waldo',
    state: 'OH',
    latitude: '40.464334',
    longitude: '-83.062303',
    timeZoneId: 'America/New_York',
  },
  '43357': {
    zip: '43357',
    city: 'West Liberty',
    state: 'OH',
    latitude: '40.265708',
    longitude: '-83.737998',
    timeZoneId: 'America/New_York',
  },
  '43358': {
    zip: '43358',
    city: 'West Mansfield',
    state: 'OH',
    latitude: '40.41642',
    longitude: '-83.486218',
    timeZoneId: 'America/New_York',
  },
  '43359': {
    zip: '43359',
    city: 'Wharton',
    state: 'OH',
    latitude: '40.870834',
    longitude: '-83.449911',
    timeZoneId: 'America/New_York',
  },
  '43360': {
    zip: '43360',
    city: 'Zanesfield',
    state: 'OH',
    latitude: '40.318273',
    longitude: '-83.651698',
    timeZoneId: 'America/New_York',
  },
  '43402': {
    zip: '43402',
    city: 'Bowling Green',
    state: 'OH',
    latitude: '41.387758',
    longitude: '-83.653281',
    timeZoneId: 'America/New_York',
  },
  '43403': {
    zip: '43403',
    city: 'Bowling Green',
    state: 'OH',
    latitude: '41.376437',
    longitude: '-83.650803',
    timeZoneId: 'America/New_York',
  },
  '43406': {
    zip: '43406',
    city: 'Bradner',
    state: 'OH',
    latitude: '41.330225',
    longitude: '-83.442064',
    timeZoneId: 'America/New_York',
  },
  '43407': {
    zip: '43407',
    city: 'Burgoon',
    state: 'OH',
    latitude: '41.277987',
    longitude: '-83.245497',
    timeZoneId: 'America/New_York',
  },
  '43408': {
    zip: '43408',
    city: 'Clay Center',
    state: 'OH',
    latitude: '41.557648',
    longitude: '-83.247498',
    timeZoneId: 'America/New_York',
  },
  '43410': {
    zip: '43410',
    city: 'Clyde',
    state: 'OH',
    latitude: '41.315461',
    longitude: '-82.963285',
    timeZoneId: 'America/New_York',
  },
  '43412': {
    zip: '43412',
    city: 'Curtice',
    state: 'OH',
    latitude: '41.651607',
    longitude: '-83.28354',
    timeZoneId: 'America/New_York',
  },
  '43413': {
    zip: '43413',
    city: 'Cygnet',
    state: 'OH',
    latitude: '41.238209',
    longitude: '-83.652763',
    timeZoneId: 'America/New_York',
  },
  '43414': {
    zip: '43414',
    city: 'Dunbridge',
    state: 'OH',
    latitude: '41.457303',
    longitude: '-83.611686',
    timeZoneId: 'America/New_York',
  },
  '43416': {
    zip: '43416',
    city: 'Elmore',
    state: 'OH',
    latitude: '41.480107',
    longitude: '-83.282707',
    timeZoneId: 'America/New_York',
  },
  '43420': {
    zip: '43420',
    city: 'Fremont',
    state: 'OH',
    latitude: '41.345436',
    longitude: '-83.117931',
    timeZoneId: 'America/New_York',
  },
  '43430': {
    zip: '43430',
    city: 'Genoa',
    state: 'OH',
    latitude: '41.528738',
    longitude: '-83.366522',
    timeZoneId: 'America/New_York',
  },
  '43431': {
    zip: '43431',
    city: 'Gibsonburg',
    state: 'OH',
    latitude: '41.379115',
    longitude: '-83.329977',
    timeZoneId: 'America/New_York',
  },
  '43432': {
    zip: '43432',
    city: 'Graytown',
    state: 'OH',
    latitude: '41.557515',
    longitude: '-83.24262',
    timeZoneId: 'America/New_York',
  },
  '43433': {
    zip: '43433',
    city: 'Gypsum',
    state: 'OH',
    latitude: '41.502503',
    longitude: '-82.871469',
    timeZoneId: 'America/New_York',
  },
  '43434': {
    zip: '43434',
    city: 'Harbor View',
    state: 'OH',
    latitude: '41.67771',
    longitude: '-83.519921',
    timeZoneId: 'America/New_York',
  },
  '43435': {
    zip: '43435',
    city: 'Helena',
    state: 'OH',
    latitude: '41.311944',
    longitude: '-83.281389',
    timeZoneId: 'America/New_York',
  },
  '43436': {
    zip: '43436',
    city: 'Isle Saint George',
    state: 'OH',
    latitude: '41.715585',
    longitude: '-82.819135',
    timeZoneId: 'America/New_York',
  },
  '43437': {
    zip: '43437',
    city: 'Jerry City',
    state: 'OH',
    latitude: '41.252052',
    longitude: '-83.603644',
    timeZoneId: 'America/New_York',
  },
  '43438': {
    zip: '43438',
    city: 'Kelleys Island',
    state: 'OH',
    latitude: '41.603835',
    longitude: '-82.686144',
    timeZoneId: 'America/New_York',
  },
  '43439': {
    zip: '43439',
    city: 'Lacarne',
    state: 'OH',
    latitude: '41.517228',
    longitude: '-83.040882',
    timeZoneId: 'America/New_York',
  },
  '43440': {
    zip: '43440',
    city: 'Lakeside Marblehead',
    state: 'OH',
    latitude: '41.531235',
    longitude: '-82.759429',
    timeZoneId: 'America/New_York',
  },
  '43441': {
    zip: '43441',
    city: 'Lemoyne',
    state: 'OH',
    latitude: '41.498038',
    longitude: '-83.471932',
    timeZoneId: 'America/New_York',
  },
  '43442': {
    zip: '43442',
    city: 'Lindsey',
    state: 'OH',
    latitude: '41.422606',
    longitude: '-83.220463',
    timeZoneId: 'America/New_York',
  },
  '43443': {
    zip: '43443',
    city: 'Luckey',
    state: 'OH',
    latitude: '41.460907',
    longitude: '-83.476317',
    timeZoneId: 'America/New_York',
  },
  '43445': {
    zip: '43445',
    city: 'Martin',
    state: 'OH',
    latitude: '41.59194',
    longitude: '-83.3115',
    timeZoneId: 'America/New_York',
  },
  '43446': {
    zip: '43446',
    city: 'Middle Bass',
    state: 'OH',
    latitude: '41.648155',
    longitude: '-82.817376',
    timeZoneId: 'America/New_York',
  },
  '43447': {
    zip: '43447',
    city: 'Millbury',
    state: 'OH',
    latitude: '41.560938',
    longitude: '-83.452654',
    timeZoneId: 'America/New_York',
  },
  '43449': {
    zip: '43449',
    city: 'Oak Harbor',
    state: 'OH',
    latitude: '41.533747',
    longitude: '-83.138207',
    timeZoneId: 'America/New_York',
  },
  '43450': {
    zip: '43450',
    city: 'Pemberville',
    state: 'OH',
    latitude: '41.410784',
    longitude: '-83.47873',
    timeZoneId: 'America/New_York',
  },
  '43451': {
    zip: '43451',
    city: 'Portage',
    state: 'OH',
    latitude: '41.318391',
    longitude: '-83.617545',
    timeZoneId: 'America/New_York',
  },
  '43452': {
    zip: '43452',
    city: 'Port Clinton',
    state: 'OH',
    latitude: '41.5368',
    longitude: '-82.908',
    timeZoneId: 'America/New_York',
  },
  '43456': {
    zip: '43456',
    city: 'Put in Bay',
    state: 'OH',
    latitude: '41.648779',
    longitude: '-82.821414',
    timeZoneId: 'America/New_York',
  },
  '43457': {
    zip: '43457',
    city: 'Risingsun',
    state: 'OH',
    latitude: '41.27208',
    longitude: '-83.43582',
    timeZoneId: 'America/New_York',
  },
  '43458': {
    zip: '43458',
    city: 'Rocky Ridge',
    state: 'OH',
    latitude: '41.531155',
    longitude: '-83.216285',
    timeZoneId: 'America/New_York',
  },
  '43460': {
    zip: '43460',
    city: 'Rossford',
    state: 'OH',
    latitude: '41.603999',
    longitude: '-83.568757',
    timeZoneId: 'America/New_York',
  },
  '43462': {
    zip: '43462',
    city: 'Rudolph',
    state: 'OH',
    latitude: '41.284376',
    longitude: '-83.698854',
    timeZoneId: 'America/New_York',
  },
  '43463': {
    zip: '43463',
    city: 'Stony Ridge',
    state: 'OH',
    latitude: '41.50977',
    longitude: '-83.508757',
    timeZoneId: 'America/New_York',
  },
  '43464': {
    zip: '43464',
    city: 'Vickery',
    state: 'OH',
    latitude: '41.388217',
    longitude: '-82.942973',
    timeZoneId: 'America/New_York',
  },
  '43465': {
    zip: '43465',
    city: 'Walbridge',
    state: 'OH',
    latitude: '41.57139',
    longitude: '-83.496483',
    timeZoneId: 'America/New_York',
  },
  '43466': {
    zip: '43466',
    city: 'Wayne',
    state: 'OH',
    latitude: '41.291967',
    longitude: '-83.50958',
    timeZoneId: 'America/New_York',
  },
  '43467': {
    zip: '43467',
    city: 'West Millgrove',
    state: 'OH',
    latitude: '41.240434',
    longitude: '-83.490982',
    timeZoneId: 'America/New_York',
  },
  '43468': {
    zip: '43468',
    city: 'Williston',
    state: 'OH',
    latitude: '41.603678',
    longitude: '-83.331413',
    timeZoneId: 'America/New_York',
  },
  '43469': {
    zip: '43469',
    city: 'Woodville',
    state: 'OH',
    latitude: '41.448066',
    longitude: '-83.371641',
    timeZoneId: 'America/New_York',
  },
  '43501': {
    zip: '43501',
    city: 'Alvordton',
    state: 'OH',
    latitude: '41.644753',
    longitude: '-84.448647',
    timeZoneId: 'America/New_York',
  },
  '43502': {
    zip: '43502',
    city: 'Archbold',
    state: 'OH',
    latitude: '41.536104',
    longitude: '-84.296476',
    timeZoneId: 'America/New_York',
  },
  '43504': {
    zip: '43504',
    city: 'Berkey',
    state: 'OH',
    latitude: '41.699935',
    longitude: '-83.833263',
    timeZoneId: 'America/New_York',
  },
  '43505': {
    zip: '43505',
    city: 'Blakeslee',
    state: 'OH',
    latitude: '41.522576',
    longitude: '-84.749395',
    timeZoneId: 'America/New_York',
  },
  '43506': {
    zip: '43506',
    city: 'Bryan',
    state: 'OH',
    latitude: '41.47543',
    longitude: '-84.567219',
    timeZoneId: 'America/New_York',
  },
  '43510': {
    zip: '43510',
    city: 'Colton',
    state: 'OH',
    latitude: '41.447834',
    longitude: '-83.972902',
    timeZoneId: 'America/New_York',
  },
  '43511': {
    zip: '43511',
    city: 'Custar',
    state: 'OH',
    latitude: '41.261582',
    longitude: '-83.820803',
    timeZoneId: 'America/New_York',
  },
  '43512': {
    zip: '43512',
    city: 'Defiance',
    state: 'OH',
    latitude: '41.297567',
    longitude: '-84.357805',
    timeZoneId: 'America/New_York',
  },
  '43515': {
    zip: '43515',
    city: 'Delta',
    state: 'OH',
    latitude: '41.577589',
    longitude: '-84.00726',
    timeZoneId: 'America/New_York',
  },
  '43516': {
    zip: '43516',
    city: 'Deshler',
    state: 'OH',
    latitude: '41.206706',
    longitude: '-83.901298',
    timeZoneId: 'America/New_York',
  },
  '43517': {
    zip: '43517',
    city: 'Edgerton',
    state: 'OH',
    latitude: '41.45968',
    longitude: '-84.741556',
    timeZoneId: 'America/New_York',
  },
  '43518': {
    zip: '43518',
    city: 'Edon',
    state: 'OH',
    latitude: '41.573093',
    longitude: '-84.769884',
    timeZoneId: 'America/New_York',
  },
  '43519': {
    zip: '43519',
    city: 'Evansport',
    state: 'OH',
    latitude: '41.428226',
    longitude: '-84.395702',
    timeZoneId: 'America/New_York',
  },
  '43520': {
    zip: '43520',
    city: 'Farmer',
    state: 'OH',
    latitude: '41.384405',
    longitude: '-84.628572',
    timeZoneId: 'America/New_York',
  },
  '43521': {
    zip: '43521',
    city: 'Fayette',
    state: 'OH',
    latitude: '41.657148',
    longitude: '-84.307721',
    timeZoneId: 'America/New_York',
  },
  '43522': {
    zip: '43522',
    city: 'Grand Rapids',
    state: 'OH',
    latitude: '41.402142',
    longitude: '-83.849564',
    timeZoneId: 'America/New_York',
  },
  '43523': {
    zip: '43523',
    city: 'Grelton',
    state: 'OH',
    latitude: '41.342644',
    longitude: '-84.000355',
    timeZoneId: 'America/New_York',
  },
  '43524': {
    zip: '43524',
    city: 'Hamler',
    state: 'OH',
    latitude: '41.229092',
    longitude: '-84.033359',
    timeZoneId: 'America/New_York',
  },
  '43525': {
    zip: '43525',
    city: 'Haskins',
    state: 'OH',
    latitude: '41.466054',
    longitude: '-83.705182',
    timeZoneId: 'America/New_York',
  },
  '43526': {
    zip: '43526',
    city: 'Hicksville',
    state: 'OH',
    latitude: '41.312912',
    longitude: '-84.734343',
    timeZoneId: 'America/New_York',
  },
  '43527': {
    zip: '43527',
    city: 'Holgate',
    state: 'OH',
    latitude: '41.249834',
    longitude: '-84.132736',
    timeZoneId: 'America/New_York',
  },
  '43528': {
    zip: '43528',
    city: 'Holland',
    state: 'OH',
    latitude: '41.61965',
    longitude: '-83.726811',
    timeZoneId: 'America/New_York',
  },
  '43529': {
    zip: '43529',
    city: 'Hoytville',
    state: 'OH',
    latitude: '41.190741',
    longitude: '-83.785403',
    timeZoneId: 'America/New_York',
  },
  '43530': {
    zip: '43530',
    city: 'Jewell',
    state: 'OH',
    latitude: '41.327153',
    longitude: '-84.284934',
    timeZoneId: 'America/New_York',
  },
  '43531': {
    zip: '43531',
    city: 'Kunkle',
    state: 'OH',
    latitude: '41.635529',
    longitude: '-84.493224',
    timeZoneId: 'America/New_York',
  },
  '43532': {
    zip: '43532',
    city: 'Liberty Center',
    state: 'OH',
    latitude: '41.421995',
    longitude: '-83.974523',
    timeZoneId: 'America/New_York',
  },
  '43533': {
    zip: '43533',
    city: 'Lyons',
    state: 'OH',
    latitude: '41.694752',
    longitude: '-84.075981',
    timeZoneId: 'America/New_York',
  },
  '43534': {
    zip: '43534',
    city: 'Mc Clure',
    state: 'OH',
    latitude: '41.373',
    longitude: '-83.939975',
    timeZoneId: 'America/New_York',
  },
  '43535': {
    zip: '43535',
    city: 'Malinta',
    state: 'OH',
    latitude: '41.320879',
    longitude: '-84.040485',
    timeZoneId: 'America/New_York',
  },
  '43536': {
    zip: '43536',
    city: 'Mark Center',
    state: 'OH',
    latitude: '41.30875',
    longitude: '-84.636797',
    timeZoneId: 'America/New_York',
  },
  '43537': {
    zip: '43537',
    city: 'Maumee',
    state: 'OH',
    latitude: '41.576929',
    longitude: '-83.664917',
    timeZoneId: 'America/New_York',
  },
  '43540': {
    zip: '43540',
    city: 'Metamora',
    state: 'OH',
    latitude: '41.702703',
    longitude: '-83.933406',
    timeZoneId: 'America/New_York',
  },
  '43541': {
    zip: '43541',
    city: 'Milton Center',
    state: 'OH',
    latitude: '41.302432',
    longitude: '-83.830175',
    timeZoneId: 'America/New_York',
  },
  '43542': {
    zip: '43542',
    city: 'Monclova',
    state: 'OH',
    latitude: '41.571329',
    longitude: '-83.764702',
    timeZoneId: 'America/New_York',
  },
  '43543': {
    zip: '43543',
    city: 'Montpelier',
    state: 'OH',
    latitude: '41.59007',
    longitude: '-84.611596',
    timeZoneId: 'America/New_York',
  },
  '43545': {
    zip: '43545',
    city: 'Napoleon',
    state: 'OH',
    latitude: '41.386399',
    longitude: '-84.133117',
    timeZoneId: 'America/New_York',
  },
  '43547': {
    zip: '43547',
    city: 'Neapolis',
    state: 'OH',
    latitude: '41.488639',
    longitude: '-83.872248',
    timeZoneId: 'America/New_York',
  },
  '43548': {
    zip: '43548',
    city: 'New Bavaria',
    state: 'OH',
    latitude: '41.203392',
    longitude: '-84.166759',
    timeZoneId: 'America/New_York',
  },
  '43549': {
    zip: '43549',
    city: 'Ney',
    state: 'OH',
    latitude: '41.374875',
    longitude: '-84.524779',
    timeZoneId: 'America/New_York',
  },
  '43550': {
    zip: '43550',
    city: 'Okolona',
    state: 'OH',
    latitude: '41.392207',
    longitude: '-84.123017',
    timeZoneId: 'America/New_York',
  },
  '43551': {
    zip: '43551',
    city: 'Perrysburg',
    state: 'OH',
    latitude: '41.542639',
    longitude: '-83.597702',
    timeZoneId: 'America/New_York',
  },
  '43552': {
    zip: '43552',
    city: 'Perrysburg',
    state: 'OH',
    latitude: '41.553911',
    longitude: '-83.626056',
    timeZoneId: 'America/New_York',
  },
  '43553': {
    zip: '43553',
    city: 'Pettisville',
    state: 'OH',
    latitude: '41.529269',
    longitude: '-84.224413',
    timeZoneId: 'America/New_York',
  },
  '43554': {
    zip: '43554',
    city: 'Pioneer',
    state: 'OH',
    latitude: '41.667305',
    longitude: '-84.55207',
    timeZoneId: 'America/New_York',
  },
  '43555': {
    zip: '43555',
    city: 'Ridgeville Corners',
    state: 'OH',
    latitude: '41.438435',
    longitude: '-84.257991',
    timeZoneId: 'America/New_York',
  },
  '43556': {
    zip: '43556',
    city: 'Sherwood',
    state: 'OH',
    latitude: '41.29957',
    longitude: '-84.562441',
    timeZoneId: 'America/New_York',
  },
  '43557': {
    zip: '43557',
    city: 'Stryker',
    state: 'OH',
    latitude: '41.502278',
    longitude: '-84.412734',
    timeZoneId: 'America/New_York',
  },
  '43558': {
    zip: '43558',
    city: 'Swanton',
    state: 'OH',
    latitude: '41.580263',
    longitude: '-83.912406',
    timeZoneId: 'America/New_York',
  },
  '43560': {
    zip: '43560',
    city: 'Sylvania',
    state: 'OH',
    latitude: '41.709644',
    longitude: '-83.708999',
    timeZoneId: 'America/New_York',
  },
  '43565': {
    zip: '43565',
    city: 'Tontogany',
    state: 'OH',
    latitude: '41.417956',
    longitude: '-83.740816',
    timeZoneId: 'America/New_York',
  },
  '43566': {
    zip: '43566',
    city: 'Waterville',
    state: 'OH',
    latitude: '41.501296',
    longitude: '-83.738531',
    timeZoneId: 'America/New_York',
  },
  '43567': {
    zip: '43567',
    city: 'Wauseon',
    state: 'OH',
    latitude: '41.557717',
    longitude: '-84.152338',
    timeZoneId: 'America/New_York',
  },
  '43569': {
    zip: '43569',
    city: 'Weston',
    state: 'OH',
    latitude: '41.351195',
    longitude: '-83.790896',
    timeZoneId: 'America/New_York',
  },
  '43570': {
    zip: '43570',
    city: 'West Unity',
    state: 'OH',
    latitude: '41.584133',
    longitude: '-84.438762',
    timeZoneId: 'America/New_York',
  },
  '43571': {
    zip: '43571',
    city: 'Whitehouse',
    state: 'OH',
    latitude: '41.521573',
    longitude: '-83.813173',
    timeZoneId: 'America/New_York',
  },
  '43601': {
    zip: '43601',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.647294',
    longitude: '-83.547631',
    timeZoneId: 'America/New_York',
  },
  '43603': {
    zip: '43603',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.65807',
    longitude: '-83.527385',
    timeZoneId: 'America/New_York',
  },
  '43604': {
    zip: '43604',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.653385',
    longitude: '-83.530339',
    timeZoneId: 'America/New_York',
  },
  '43605': {
    zip: '43605',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.65146',
    longitude: '-83.50866',
    timeZoneId: 'America/New_York',
  },
  '43606': {
    zip: '43606',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.672103',
    longitude: '-83.602649',
    timeZoneId: 'America/New_York',
  },
  '43607': {
    zip: '43607',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.650995',
    longitude: '-83.603793',
    timeZoneId: 'America/New_York',
  },
  '43608': {
    zip: '43608',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.676751',
    longitude: '-83.535409',
    timeZoneId: 'America/New_York',
  },
  '43609': {
    zip: '43609',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.630611',
    longitude: '-83.576936',
    timeZoneId: 'America/New_York',
  },
  '43610': {
    zip: '43610',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.675482',
    longitude: '-83.557388',
    timeZoneId: 'America/New_York',
  },
  '43611': {
    zip: '43611',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.706803',
    longitude: '-83.489427',
    timeZoneId: 'America/New_York',
  },
  '43612': {
    zip: '43612',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.702832',
    longitude: '-83.539208',
    timeZoneId: 'America/New_York',
  },
  '43613': {
    zip: '43613',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.704307',
    longitude: '-83.604452',
    timeZoneId: 'America/New_York',
  },
  '43614': {
    zip: '43614',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.60343',
    longitude: '-83.627311',
    timeZoneId: 'America/New_York',
  },
  '43615': {
    zip: '43615',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.651393',
    longitude: '-83.674791',
    timeZoneId: 'America/New_York',
  },
  '43616': {
    zip: '43616',
    city: 'Oregon',
    state: 'OH',
    latitude: '41.648315',
    longitude: '-83.466213',
    timeZoneId: 'America/New_York',
  },
  '43617': {
    zip: '43617',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.66411',
    longitude: '-83.717316',
    timeZoneId: 'America/New_York',
  },
  '43619': {
    zip: '43619',
    city: 'Northwood',
    state: 'OH',
    latitude: '41.601962',
    longitude: '-83.480669',
    timeZoneId: 'America/New_York',
  },
  '43620': {
    zip: '43620',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.664084',
    longitude: '-83.550387',
    timeZoneId: 'America/New_York',
  },
  '43623': {
    zip: '43623',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.707048',
    longitude: '-83.649692',
    timeZoneId: 'America/New_York',
  },
  '43635': {
    zip: '43635',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.652535',
    longitude: '-83.671321',
    timeZoneId: 'America/New_York',
  },
  '43652': {
    zip: '43652',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.650967',
    longitude: '-83.536485',
    timeZoneId: 'America/New_York',
  },
  '43654': {
    zip: '43654',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.605962',
    longitude: '-83.537003',
    timeZoneId: 'America/New_York',
  },
  '43656': {
    zip: '43656',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.704746',
    longitude: '-83.6508',
    timeZoneId: 'America/New_York',
  },
  '43657': {
    zip: '43657',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.676827',
    longitude: '-83.561778',
    timeZoneId: 'America/New_York',
  },
  '43659': {
    zip: '43659',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.649245',
    longitude: '-83.536795',
    timeZoneId: 'America/New_York',
  },
  '43660': {
    zip: '43660',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.638952',
    longitude: '-83.544684',
    timeZoneId: 'America/New_York',
  },
  '43661': {
    zip: '43661',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.652881',
    longitude: '-83.604687',
    timeZoneId: 'America/New_York',
  },
  '43666': {
    zip: '43666',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.654455',
    longitude: '-83.530392',
    timeZoneId: 'America/New_York',
  },
  '43667': {
    zip: '43667',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.650895',
    longitude: '-83.535228',
    timeZoneId: 'America/New_York',
  },
  '43681': {
    zip: '43681',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.642211',
    longitude: '-83.542156',
    timeZoneId: 'America/New_York',
  },
  '43682': {
    zip: '43682',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.642592',
    longitude: '-83.544001',
    timeZoneId: 'America/New_York',
  },
  '43697': {
    zip: '43697',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.644777',
    longitude: '-83.543436',
    timeZoneId: 'America/New_York',
  },
  '43699': {
    zip: '43699',
    city: 'Toledo',
    state: 'OH',
    latitude: '41.631065',
    longitude: '-83.618751',
    timeZoneId: 'America/New_York',
  },
  '43701': {
    zip: '43701',
    city: 'Zanesville',
    state: 'OH',
    latitude: '39.95424',
    longitude: '-81.98496',
    timeZoneId: 'America/New_York',
  },
  '43702': {
    zip: '43702',
    city: 'Zanesville',
    state: 'OH',
    latitude: '39.940823',
    longitude: '-82.006789',
    timeZoneId: 'America/New_York',
  },
  '43711': {
    zip: '43711',
    city: 'Ava',
    state: 'OH',
    latitude: '39.832707',
    longitude: '-81.574545',
    timeZoneId: 'America/New_York',
  },
  '43713': {
    zip: '43713',
    city: 'Barnesville',
    state: 'OH',
    latitude: '39.97614',
    longitude: '-81.165',
    timeZoneId: 'America/New_York',
  },
  '43716': {
    zip: '43716',
    city: 'Beallsville',
    state: 'OH',
    latitude: '39.86388',
    longitude: '-81.00276',
    timeZoneId: 'America/New_York',
  },
  '43717': {
    zip: '43717',
    city: 'Belle Valley',
    state: 'OH',
    latitude: '39.802138',
    longitude: '-81.551969',
    timeZoneId: 'America/New_York',
  },
  '43718': {
    zip: '43718',
    city: 'Belmont',
    state: 'OH',
    latitude: '40.015424',
    longitude: '-81.01562',
    timeZoneId: 'America/New_York',
  },
  '43719': {
    zip: '43719',
    city: 'Bethesda',
    state: 'OH',
    latitude: '39.98586',
    longitude: '-81.07572',
    timeZoneId: 'America/New_York',
  },
  '43720': {
    zip: '43720',
    city: 'Blue Rock',
    state: 'OH',
    latitude: '39.797077',
    longitude: '-81.879055',
    timeZoneId: 'America/New_York',
  },
  '43721': {
    zip: '43721',
    city: 'Brownsville',
    state: 'OH',
    latitude: '39.939964',
    longitude: '-82.252329',
    timeZoneId: 'America/New_York',
  },
  '43722': {
    zip: '43722',
    city: 'Buffalo',
    state: 'OH',
    latitude: '39.914026',
    longitude: '-81.520023',
    timeZoneId: 'America/New_York',
  },
  '43723': {
    zip: '43723',
    city: 'Byesville',
    state: 'OH',
    latitude: '39.960932',
    longitude: '-81.544227',
    timeZoneId: 'America/New_York',
  },
  '43724': {
    zip: '43724',
    city: 'Caldwell',
    state: 'OH',
    latitude: '39.76968',
    longitude: '-81.48006',
    timeZoneId: 'America/New_York',
  },
  '43725': {
    zip: '43725',
    city: 'Cambridge',
    state: 'OH',
    latitude: '40.027358',
    longitude: '-81.593383',
    timeZoneId: 'America/New_York',
  },
  '43727': {
    zip: '43727',
    city: 'Chandlersville',
    state: 'OH',
    latitude: '39.877305',
    longitude: '-81.810514',
    timeZoneId: 'America/New_York',
  },
  '43728': {
    zip: '43728',
    city: 'Chesterhill',
    state: 'OH',
    latitude: '39.653299',
    longitude: '-81.810316',
    timeZoneId: 'America/New_York',
  },
  '43730': {
    zip: '43730',
    city: 'Corning',
    state: 'OH',
    latitude: '39.630644',
    longitude: '-82.090365',
    timeZoneId: 'America/New_York',
  },
  '43731': {
    zip: '43731',
    city: 'Crooksville',
    state: 'OH',
    latitude: '39.75552',
    longitude: '-82.04694',
    timeZoneId: 'America/New_York',
  },
  '43732': {
    zip: '43732',
    city: 'Cumberland',
    state: 'OH',
    latitude: '39.883082',
    longitude: '-81.641698',
    timeZoneId: 'America/New_York',
  },
  '43733': {
    zip: '43733',
    city: 'Derwent',
    state: 'OH',
    latitude: '39.930089',
    longitude: '-81.499768',
    timeZoneId: 'America/New_York',
  },
  '43734': {
    zip: '43734',
    city: 'Duncan Falls',
    state: 'OH',
    latitude: '39.880812',
    longitude: '-81.911534',
    timeZoneId: 'America/New_York',
  },
  '43735': {
    zip: '43735',
    city: 'East Fultonham',
    state: 'OH',
    latitude: '39.853633',
    longitude: '-82.122518',
    timeZoneId: 'America/New_York',
  },
  '43736': {
    zip: '43736',
    city: 'Fairview',
    state: 'OH',
    latitude: '39.994015',
    longitude: '-81.285558',
    timeZoneId: 'America/New_York',
  },
  '43738': {
    zip: '43738',
    city: 'Fultonham',
    state: 'OH',
    latitude: '39.857321',
    longitude: '-82.13854',
    timeZoneId: 'America/New_York',
  },
  '43739': {
    zip: '43739',
    city: 'Glenford',
    state: 'OH',
    latitude: '39.939027',
    longitude: '-82.274937',
    timeZoneId: 'America/New_York',
  },
  '43740': {
    zip: '43740',
    city: 'Gratiot',
    state: 'OH',
    latitude: '39.949506',
    longitude: '-82.210768',
    timeZoneId: 'America/New_York',
  },
  '43746': {
    zip: '43746',
    city: 'Hopewell',
    state: 'OH',
    latitude: '39.963',
    longitude: '-82.170248',
    timeZoneId: 'America/New_York',
  },
  '43747': {
    zip: '43747',
    city: 'Jerusalem',
    state: 'OH',
    latitude: '39.837154',
    longitude: '-81.116078',
    timeZoneId: 'America/New_York',
  },
  '43748': {
    zip: '43748',
    city: 'Junction City',
    state: 'OH',
    latitude: '39.714915',
    longitude: '-82.310414',
    timeZoneId: 'America/New_York',
  },
  '43749': {
    zip: '43749',
    city: 'Kimbolton',
    state: 'OH',
    latitude: '40.181667',
    longitude: '-81.5925',
    timeZoneId: 'America/New_York',
  },
  '43750': {
    zip: '43750',
    city: 'Kipling',
    state: 'OH',
    latitude: '39.994618',
    longitude: '-81.506178',
    timeZoneId: 'America/New_York',
  },
  '43752': {
    zip: '43752',
    city: 'Laings',
    state: 'OH',
    latitude: '39.71601',
    longitude: '-81.013102',
    timeZoneId: 'America/New_York',
  },
  '43754': {
    zip: '43754',
    city: 'Lewisville',
    state: 'OH',
    latitude: '39.762764',
    longitude: '-81.237672',
    timeZoneId: 'America/New_York',
  },
  '43755': {
    zip: '43755',
    city: 'Lore City',
    state: 'OH',
    latitude: '40.052056',
    longitude: '-81.438911',
    timeZoneId: 'America/New_York',
  },
  '43756': {
    zip: '43756',
    city: 'McConnelsville',
    state: 'OH',
    latitude: '39.69',
    longitude: '-81.83598',
    timeZoneId: 'America/New_York',
  },
  '43757': {
    zip: '43757',
    city: 'Malaga',
    state: 'OH',
    latitude: '39.856227',
    longitude: '-81.146767',
    timeZoneId: 'America/New_York',
  },
  '43758': {
    zip: '43758',
    city: 'Malta',
    state: 'OH',
    latitude: '39.640908',
    longitude: '-81.894711',
    timeZoneId: 'America/New_York',
  },
  '43759': {
    zip: '43759',
    city: 'Morristown',
    state: 'OH',
    latitude: '40.060403',
    longitude: '-81.071245',
    timeZoneId: 'America/New_York',
  },
  '43760': {
    zip: '43760',
    city: 'Mount Perry',
    state: 'OH',
    latitude: '39.899113',
    longitude: '-82.172415',
    timeZoneId: 'America/New_York',
  },
  '43761': {
    zip: '43761',
    city: 'Moxahala',
    state: 'OH',
    latitude: '39.66127',
    longitude: '-82.141325',
    timeZoneId: 'America/New_York',
  },
  '43762': {
    zip: '43762',
    city: 'New Concord',
    state: 'OH',
    latitude: '40.019934',
    longitude: '-81.751659',
    timeZoneId: 'America/New_York',
  },
  '43764': {
    zip: '43764',
    city: 'New Lexington',
    state: 'OH',
    latitude: '39.71802',
    longitude: '-82.1859',
    timeZoneId: 'America/New_York',
  },
  '43766': {
    zip: '43766',
    city: 'New Straitsville',
    state: 'OH',
    latitude: '39.614349',
    longitude: '-82.280139',
    timeZoneId: 'America/New_York',
  },
  '43767': {
    zip: '43767',
    city: 'Norwich',
    state: 'OH',
    latitude: '39.996606',
    longitude: '-81.802153',
    timeZoneId: 'America/New_York',
  },
  '43768': {
    zip: '43768',
    city: 'Old Washington',
    state: 'OH',
    latitude: '40.032494',
    longitude: '-81.411187',
    timeZoneId: 'America/New_York',
  },
  '43771': {
    zip: '43771',
    city: 'Philo',
    state: 'OH',
    latitude: '39.84828',
    longitude: '-81.936488',
    timeZoneId: 'America/New_York',
  },
  '43772': {
    zip: '43772',
    city: 'Pleasant City',
    state: 'OH',
    latitude: '39.906705',
    longitude: '-81.552435',
    timeZoneId: 'America/New_York',
  },
  '43773': {
    zip: '43773',
    city: 'Quaker City',
    state: 'OH',
    latitude: '40.036335',
    longitude: '-81.297511',
    timeZoneId: 'America/New_York',
  },
  '43777': {
    zip: '43777',
    city: 'Roseville',
    state: 'OH',
    latitude: '39.826232',
    longitude: '-82.065033',
    timeZoneId: 'America/New_York',
  },
  '43778': {
    zip: '43778',
    city: 'Salesville',
    state: 'OH',
    latitude: '40.00536',
    longitude: '-81.38556',
    timeZoneId: 'America/New_York',
  },
  '43779': {
    zip: '43779',
    city: 'Sarahsville',
    state: 'OH',
    latitude: '39.814019',
    longitude: '-81.441843',
    timeZoneId: 'America/New_York',
  },
  '43780': {
    zip: '43780',
    city: 'Senecaville',
    state: 'OH',
    latitude: '39.937952',
    longitude: '-81.463412',
    timeZoneId: 'America/New_York',
  },
  '43782': {
    zip: '43782',
    city: 'Shawnee',
    state: 'OH',
    latitude: '39.613693',
    longitude: '-82.214228',
    timeZoneId: 'America/New_York',
  },
  '43783': {
    zip: '43783',
    city: 'Somerset',
    state: 'OH',
    latitude: '39.804837',
    longitude: '-82.290922',
    timeZoneId: 'America/New_York',
  },
  '43786': {
    zip: '43786',
    city: 'Stafford',
    state: 'OH',
    latitude: '39.715067',
    longitude: '-81.276377',
    timeZoneId: 'America/New_York',
  },
  '43787': {
    zip: '43787',
    city: 'Stockport',
    state: 'OH',
    latitude: '39.557874',
    longitude: '-81.770693',
    timeZoneId: 'America/New_York',
  },
  '43788': {
    zip: '43788',
    city: 'Summerfield',
    state: 'OH',
    latitude: '39.792959',
    longitude: '-81.345816',
    timeZoneId: 'America/New_York',
  },
  '43791': {
    zip: '43791',
    city: 'White Cottage',
    state: 'OH',
    latitude: '39.876236',
    longitude: '-82.103404',
    timeZoneId: 'America/New_York',
  },
  '43793': {
    zip: '43793',
    city: 'Woodsfield',
    state: 'OH',
    latitude: '39.755116',
    longitude: '-81.112949',
    timeZoneId: 'America/New_York',
  },
  '43802': {
    zip: '43802',
    city: 'Adamsville',
    state: 'OH',
    latitude: '40.083252',
    longitude: '-81.859062',
    timeZoneId: 'America/New_York',
  },
  '43803': {
    zip: '43803',
    city: 'Bakersville',
    state: 'OH',
    latitude: '40.355315',
    longitude: '-81.643974',
    timeZoneId: 'America/New_York',
  },
  '43804': {
    zip: '43804',
    city: 'Baltic',
    state: 'OH',
    latitude: '40.463509',
    longitude: '-81.734242',
    timeZoneId: 'America/New_York',
  },
  '43805': {
    zip: '43805',
    city: 'Blissfield',
    state: 'OH',
    latitude: '40.400882',
    longitude: '-81.964837',
    timeZoneId: 'America/New_York',
  },
  '43811': {
    zip: '43811',
    city: 'Conesville',
    state: 'OH',
    latitude: '40.192343',
    longitude: '-81.910664',
    timeZoneId: 'America/New_York',
  },
  '43812': {
    zip: '43812',
    city: 'Coshocton',
    state: 'OH',
    latitude: '40.266552',
    longitude: '-81.852642',
    timeZoneId: 'America/New_York',
  },
  '43821': {
    zip: '43821',
    city: 'Dresden',
    state: 'OH',
    latitude: '40.12224',
    longitude: '-81.98616',
    timeZoneId: 'America/New_York',
  },
  '43822': {
    zip: '43822',
    city: 'Frazeysburg',
    state: 'OH',
    latitude: '40.12057',
    longitude: '-82.127628',
    timeZoneId: 'America/New_York',
  },
  '43824': {
    zip: '43824',
    city: 'Fresno',
    state: 'OH',
    latitude: '40.34722',
    longitude: '-81.738511',
    timeZoneId: 'America/New_York',
  },
  '43828': {
    zip: '43828',
    city: 'Keene',
    state: 'OH',
    latitude: '40.341692',
    longitude: '-81.8657',
    timeZoneId: 'America/New_York',
  },
  '43830': {
    zip: '43830',
    city: 'Nashport',
    state: 'OH',
    latitude: '40.05522',
    longitude: '-82.10994',
    timeZoneId: 'America/New_York',
  },
  '43832': {
    zip: '43832',
    city: 'Newcomerstown',
    state: 'OH',
    latitude: '40.266',
    longitude: '-81.60018',
    timeZoneId: 'America/New_York',
  },
  '43836': {
    zip: '43836',
    city: 'Plainfield',
    state: 'OH',
    latitude: '40.208882',
    longitude: '-81.719943',
    timeZoneId: 'America/New_York',
  },
  '43837': {
    zip: '43837',
    city: 'Port Washington',
    state: 'OH',
    latitude: '40.320682',
    longitude: '-81.50214',
    timeZoneId: 'America/New_York',
  },
  '43840': {
    zip: '43840',
    city: 'Stone Creek',
    state: 'OH',
    latitude: '40.407646',
    longitude: '-81.595511',
    timeZoneId: 'America/New_York',
  },
  '43842': {
    zip: '43842',
    city: 'Trinway',
    state: 'OH',
    latitude: '40.139867',
    longitude: '-82.00953',
    timeZoneId: 'America/New_York',
  },
  '43843': {
    zip: '43843',
    city: 'Walhonding',
    state: 'OH',
    latitude: '40.311486',
    longitude: '-82.219602',
    timeZoneId: 'America/New_York',
  },
  '43844': {
    zip: '43844',
    city: 'Warsaw',
    state: 'OH',
    latitude: '40.311603',
    longitude: '-82.047764',
    timeZoneId: 'America/New_York',
  },
  '43845': {
    zip: '43845',
    city: 'West Lafayette',
    state: 'OH',
    latitude: '40.269968',
    longitude: '-81.735623',
    timeZoneId: 'America/New_York',
  },
  '43901': {
    zip: '43901',
    city: 'Adena',
    state: 'OH',
    latitude: '40.219347',
    longitude: '-80.864391',
    timeZoneId: 'America/New_York',
  },
  '43902': {
    zip: '43902',
    city: 'Alledonia',
    state: 'OH',
    latitude: '39.880712',
    longitude: '-80.993107',
    timeZoneId: 'America/New_York',
  },
  '43903': {
    zip: '43903',
    city: 'Amsterdam',
    state: 'OH',
    latitude: '40.477439',
    longitude: '-80.963005',
    timeZoneId: 'America/New_York',
  },
  '43905': {
    zip: '43905',
    city: 'Barton',
    state: 'OH',
    latitude: '40.107657',
    longitude: '-80.840421',
    timeZoneId: 'America/New_York',
  },
  '43906': {
    zip: '43906',
    city: 'Bellaire',
    state: 'OH',
    latitude: '40.017876',
    longitude: '-80.757949',
    timeZoneId: 'America/New_York',
  },
  '43907': {
    zip: '43907',
    city: 'Cadiz',
    state: 'OH',
    latitude: '40.25886',
    longitude: '-81.03132',
    timeZoneId: 'America/New_York',
  },
  '43908': {
    zip: '43908',
    city: 'Bergholz',
    state: 'OH',
    latitude: '40.547038',
    longitude: '-80.929703',
    timeZoneId: 'America/New_York',
  },
  '43909': {
    zip: '43909',
    city: 'Blaine',
    state: 'OH',
    latitude: '40.070954',
    longitude: '-80.816073',
    timeZoneId: 'America/New_York',
  },
  '43910': {
    zip: '43910',
    city: 'Bloomingdale',
    state: 'OH',
    latitude: '40.340971',
    longitude: '-80.806712',
    timeZoneId: 'America/New_York',
  },
  '43912': {
    zip: '43912',
    city: 'Bridgeport',
    state: 'OH',
    latitude: '40.074874',
    longitude: '-80.764849',
    timeZoneId: 'America/New_York',
  },
  '43913': {
    zip: '43913',
    city: 'Brilliant',
    state: 'OH',
    latitude: '40.263036',
    longitude: '-80.629993',
    timeZoneId: 'America/New_York',
  },
  '43914': {
    zip: '43914',
    city: 'Cameron',
    state: 'OH',
    latitude: '39.769151',
    longitude: '-80.946797',
    timeZoneId: 'America/New_York',
  },
  '43915': {
    zip: '43915',
    city: 'Clarington',
    state: 'OH',
    latitude: '39.747461',
    longitude: '-80.893816',
    timeZoneId: 'America/New_York',
  },
  '43916': {
    zip: '43916',
    city: 'Colerain',
    state: 'OH',
    latitude: '40.07001',
    longitude: '-80.74028',
    timeZoneId: 'America/New_York',
  },
  '43917': {
    zip: '43917',
    city: 'Dillonvale',
    state: 'OH',
    latitude: '40.147121',
    longitude: '-80.831081',
    timeZoneId: 'America/New_York',
  },
  '43920': {
    zip: '43920',
    city: 'East Liverpool',
    state: 'OH',
    latitude: '40.67112',
    longitude: '-80.58306',
    timeZoneId: 'America/New_York',
  },
  '43925': {
    zip: '43925',
    city: 'East Springfield',
    state: 'OH',
    latitude: '40.451367',
    longitude: '-80.858749',
    timeZoneId: 'America/New_York',
  },
  '43926': {
    zip: '43926',
    city: 'Empire',
    state: 'OH',
    latitude: '40.51193',
    longitude: '-80.623747',
    timeZoneId: 'America/New_York',
  },
  '43927': {
    zip: '43927',
    city: 'Fairpoint',
    state: 'OH',
    latitude: '40.123339',
    longitude: '-80.933498',
    timeZoneId: 'America/New_York',
  },
  '43928': {
    zip: '43928',
    city: 'Glencoe',
    state: 'OH',
    latitude: '40.006406',
    longitude: '-80.873509',
    timeZoneId: 'America/New_York',
  },
  '43930': {
    zip: '43930',
    city: 'Hammondsville',
    state: 'OH',
    latitude: '40.612105',
    longitude: '-80.783966',
    timeZoneId: 'America/New_York',
  },
  '43931': {
    zip: '43931',
    city: 'Hannibal',
    state: 'OH',
    latitude: '39.664786',
    longitude: '-80.877461',
    timeZoneId: 'America/New_York',
  },
  '43932': {
    zip: '43932',
    city: 'Irondale',
    state: 'OH',
    latitude: '40.590849',
    longitude: '-80.708824',
    timeZoneId: 'America/New_York',
  },
  '43933': {
    zip: '43933',
    city: 'Jacobsburg',
    state: 'OH',
    latitude: '39.94541',
    longitude: '-80.902208',
    timeZoneId: 'America/New_York',
  },
  '43934': {
    zip: '43934',
    city: 'Lansing',
    state: 'OH',
    latitude: '40.073876',
    longitude: '-80.788268',
    timeZoneId: 'America/New_York',
  },
  '43935': {
    zip: '43935',
    city: 'Martins Ferry',
    state: 'OH',
    latitude: '40.100135',
    longitude: '-80.730164',
    timeZoneId: 'America/New_York',
  },
  '43937': {
    zip: '43937',
    city: 'Maynard',
    state: 'OH',
    latitude: '40.130903',
    longitude: '-80.879039',
    timeZoneId: 'America/New_York',
  },
  '43938': {
    zip: '43938',
    city: 'Mingo Junction',
    state: 'OH',
    latitude: '40.3155',
    longitude: '-80.6262',
    timeZoneId: 'America/New_York',
  },
  '43939': {
    zip: '43939',
    city: 'Mount Pleasant',
    state: 'OH',
    latitude: '40.176383',
    longitude: '-80.800676',
    timeZoneId: 'America/New_York',
  },
  '43940': {
    zip: '43940',
    city: 'Neffs',
    state: 'OH',
    latitude: '40.026323',
    longitude: '-80.818501',
    timeZoneId: 'America/New_York',
  },
  '43941': {
    zip: '43941',
    city: 'Piney Fork',
    state: 'OH',
    latitude: '40.252583',
    longitude: '-80.813445',
    timeZoneId: 'America/New_York',
  },
  '43942': {
    zip: '43942',
    city: 'Powhatan Point',
    state: 'OH',
    latitude: '39.862119',
    longitude: '-80.816459',
    timeZoneId: 'America/New_York',
  },
  '43943': {
    zip: '43943',
    city: 'Rayland',
    state: 'OH',
    latitude: '40.179877',
    longitude: '-80.70794',
    timeZoneId: 'America/New_York',
  },
  '43944': {
    zip: '43944',
    city: 'Richmond',
    state: 'OH',
    latitude: '40.431203',
    longitude: '-80.756208',
    timeZoneId: 'America/New_York',
  },
  '43945': {
    zip: '43945',
    city: 'Salineville',
    state: 'OH',
    latitude: '40.640246',
    longitude: '-80.828749',
    timeZoneId: 'America/New_York',
  },
  '43946': {
    zip: '43946',
    city: 'Sardis',
    state: 'OH',
    latitude: '39.639294',
    longitude: '-80.949108',
    timeZoneId: 'America/New_York',
  },
  '43947': {
    zip: '43947',
    city: 'Shadyside',
    state: 'OH',
    latitude: '39.968088',
    longitude: '-80.754883',
    timeZoneId: 'America/New_York',
  },
  '43948': {
    zip: '43948',
    city: 'Smithfield',
    state: 'OH',
    latitude: '40.274706',
    longitude: '-80.782',
    timeZoneId: 'America/New_York',
  },
  '43950': {
    zip: '43950',
    city: 'Saint Clairsville',
    state: 'OH',
    latitude: '40.082971',
    longitude: '-80.912626',
    timeZoneId: 'America/New_York',
  },
  '43951': {
    zip: '43951',
    city: 'Lafferty',
    state: 'OH',
    latitude: '40.111831',
    longitude: '-81.018183',
    timeZoneId: 'America/New_York',
  },
  '43952': {
    zip: '43952',
    city: 'Steubenville',
    state: 'OH',
    latitude: '40.36914',
    longitude: '-80.7126',
    timeZoneId: 'America/New_York',
  },
  '43953': {
    zip: '43953',
    city: 'Steubenville',
    state: 'OH',
    latitude: '40.376875',
    longitude: '-80.700762',
    timeZoneId: 'America/New_York',
  },
  '43961': {
    zip: '43961',
    city: 'Stratton',
    state: 'OH',
    latitude: '40.518617',
    longitude: '-80.627696',
    timeZoneId: 'America/New_York',
  },
  '43962': {
    zip: '43962',
    city: 'Summitville',
    state: 'OH',
    latitude: '40.677674',
    longitude: '-80.886924',
    timeZoneId: 'America/New_York',
  },
  '43963': {
    zip: '43963',
    city: 'Tiltonsville',
    state: 'OH',
    latitude: '40.174164',
    longitude: '-80.69737',
    timeZoneId: 'America/New_York',
  },
  '43964': {
    zip: '43964',
    city: 'Toronto',
    state: 'OH',
    latitude: '40.463811',
    longitude: '-80.608335',
    timeZoneId: 'America/New_York',
  },
  '43967': {
    zip: '43967',
    city: 'Warnock',
    state: 'OH',
    latitude: '40.021742',
    longitude: '-80.940084',
    timeZoneId: 'America/New_York',
  },
  '43968': {
    zip: '43968',
    city: 'Wellsville',
    state: 'OH',
    latitude: '40.62525',
    longitude: '-80.66859',
    timeZoneId: 'America/New_York',
  },
  '43970': {
    zip: '43970',
    city: 'Wolf Run',
    state: 'OH',
    latitude: '40.465643',
    longitude: '-80.885986',
    timeZoneId: 'America/New_York',
  },
  '43971': {
    zip: '43971',
    city: 'Yorkville',
    state: 'OH',
    latitude: '40.16416',
    longitude: '-80.703781',
    timeZoneId: 'America/New_York',
  },
  '43972': {
    zip: '43972',
    city: 'Bannock',
    state: 'OH',
    latitude: '40.100473',
    longitude: '-80.975753',
    timeZoneId: 'America/New_York',
  },
  '43973': {
    zip: '43973',
    city: 'Freeport',
    state: 'OH',
    latitude: '40.156554',
    longitude: '-81.323349',
    timeZoneId: 'America/New_York',
  },
  '43974': {
    zip: '43974',
    city: 'Harrisville',
    state: 'OH',
    latitude: '40.179904',
    longitude: '-80.884655',
    timeZoneId: 'America/New_York',
  },
  '43976': {
    zip: '43976',
    city: 'Hopedale',
    state: 'OH',
    latitude: '40.366816',
    longitude: '-80.90719',
    timeZoneId: 'America/New_York',
  },
  '43977': {
    zip: '43977',
    city: 'Flushing',
    state: 'OH',
    latitude: '40.128055',
    longitude: '-81.070756',
    timeZoneId: 'America/New_York',
  },
  '43981': {
    zip: '43981',
    city: 'New Athens',
    state: 'OH',
    latitude: '40.181824',
    longitude: '-80.993551',
    timeZoneId: 'America/New_York',
  },
  '43983': {
    zip: '43983',
    city: 'Piedmont',
    state: 'OH',
    latitude: '40.141278',
    longitude: '-81.19164',
    timeZoneId: 'America/New_York',
  },
  '43984': {
    zip: '43984',
    city: 'New Rumley',
    state: 'OH',
    latitude: '40.399263',
    longitude: '-81.033928',
    timeZoneId: 'America/New_York',
  },
  '43985': {
    zip: '43985',
    city: 'Holloway',
    state: 'OH',
    latitude: '40.161005',
    longitude: '-81.127401',
    timeZoneId: 'America/New_York',
  },
  '43986': {
    zip: '43986',
    city: 'Jewett',
    state: 'OH',
    latitude: '40.371767',
    longitude: '-80.989071',
    timeZoneId: 'America/New_York',
  },
  '43988': {
    zip: '43988',
    city: 'Scio',
    state: 'OH',
    latitude: '40.377948',
    longitude: '-81.121554',
    timeZoneId: 'America/New_York',
  },
  '44001': {
    zip: '44001',
    city: 'Amherst',
    state: 'OH',
    latitude: '41.369097',
    longitude: '-82.253517',
    timeZoneId: 'America/New_York',
  },
  '44003': {
    zip: '44003',
    city: 'Andover',
    state: 'OH',
    latitude: '41.627584',
    longitude: '-80.585183',
    timeZoneId: 'America/New_York',
  },
  '44004': {
    zip: '44004',
    city: 'Ashtabula',
    state: 'OH',
    latitude: '41.85054',
    longitude: '-80.7774',
    timeZoneId: 'America/New_York',
  },
  '44005': {
    zip: '44005',
    city: 'Ashtabula',
    state: 'OH',
    latitude: '41.876001',
    longitude: '-80.800053',
    timeZoneId: 'America/New_York',
  },
  '44010': {
    zip: '44010',
    city: 'Austinburg',
    state: 'OH',
    latitude: '41.760517',
    longitude: '-80.850566',
    timeZoneId: 'America/New_York',
  },
  '44011': {
    zip: '44011',
    city: 'Avon',
    state: 'OH',
    latitude: '41.448666',
    longitude: '-82.021439',
    timeZoneId: 'America/New_York',
  },
  '44012': {
    zip: '44012',
    city: 'Avon Lake',
    state: 'OH',
    latitude: '41.494448',
    longitude: '-82.020479',
    timeZoneId: 'America/New_York',
  },
  '44017': {
    zip: '44017',
    city: 'Berea',
    state: 'OH',
    latitude: '41.370214',
    longitude: '-81.855911',
    timeZoneId: 'America/New_York',
  },
  '44021': {
    zip: '44021',
    city: 'Burton',
    state: 'OH',
    latitude: '41.452676',
    longitude: '-81.155013',
    timeZoneId: 'America/New_York',
  },
  '44022': {
    zip: '44022',
    city: 'Chagrin Falls',
    state: 'OH',
    latitude: '41.438462',
    longitude: '-81.418674',
    timeZoneId: 'America/New_York',
  },
  '44023': {
    zip: '44023',
    city: 'Chagrin Falls',
    state: 'OH',
    latitude: '41.390948',
    longitude: '-81.336689',
    timeZoneId: 'America/New_York',
  },
  '44024': {
    zip: '44024',
    city: 'Chardon',
    state: 'OH',
    latitude: '41.569436',
    longitude: '-81.203372',
    timeZoneId: 'America/New_York',
  },
  '44026': {
    zip: '44026',
    city: 'Chesterland',
    state: 'OH',
    latitude: '41.536632',
    longitude: '-81.339554',
    timeZoneId: 'America/New_York',
  },
  '44028': {
    zip: '44028',
    city: 'Columbia Station',
    state: 'OH',
    latitude: '41.312086',
    longitude: '-81.935671',
    timeZoneId: 'America/New_York',
  },
  '44030': {
    zip: '44030',
    city: 'Conneaut',
    state: 'OH',
    latitude: '41.931936',
    longitude: '-80.581103',
    timeZoneId: 'America/New_York',
  },
  '44032': {
    zip: '44032',
    city: 'Dorset',
    state: 'OH',
    latitude: '41.664055',
    longitude: '-80.674035',
    timeZoneId: 'America/New_York',
  },
  '44033': {
    zip: '44033',
    city: 'East Claridon',
    state: 'OH',
    latitude: '41.54698',
    longitude: '-81.101726',
    timeZoneId: 'America/New_York',
  },
  '44035': {
    zip: '44035',
    city: 'Elyria',
    state: 'OH',
    latitude: '41.370813',
    longitude: '-82.114096',
    timeZoneId: 'America/New_York',
  },
  '44036': {
    zip: '44036',
    city: 'Elyria',
    state: 'OH',
    latitude: '41.367756',
    longitude: '-82.098511',
    timeZoneId: 'America/New_York',
  },
  '44039': {
    zip: '44039',
    city: 'North Ridgeville',
    state: 'OH',
    latitude: '41.391979',
    longitude: '-82.019396',
    timeZoneId: 'America/New_York',
  },
  '44040': {
    zip: '44040',
    city: 'Gates Mills',
    state: 'OH',
    latitude: '41.527765',
    longitude: '-81.412458',
    timeZoneId: 'America/New_York',
  },
  '44041': {
    zip: '44041',
    city: 'Geneva',
    state: 'OH',
    latitude: '41.786888',
    longitude: '-80.957349',
    timeZoneId: 'America/New_York',
  },
  '44044': {
    zip: '44044',
    city: 'Grafton',
    state: 'OH',
    latitude: '41.27082',
    longitude: '-82.0458',
    timeZoneId: 'America/New_York',
  },
  '44045': {
    zip: '44045',
    city: 'Grand River',
    state: 'OH',
    latitude: '41.738297',
    longitude: '-81.279247',
    timeZoneId: 'America/New_York',
  },
  '44046': {
    zip: '44046',
    city: 'Huntsburg',
    state: 'OH',
    latitude: '41.536534',
    longitude: '-81.062161',
    timeZoneId: 'America/New_York',
  },
  '44047': {
    zip: '44047',
    city: 'Jefferson',
    state: 'OH',
    latitude: '41.650287',
    longitude: '-80.769918',
    timeZoneId: 'America/New_York',
  },
  '44048': {
    zip: '44048',
    city: 'Kingsville',
    state: 'OH',
    latitude: '41.86129',
    longitude: '-80.65469',
    timeZoneId: 'America/New_York',
  },
  '44049': {
    zip: '44049',
    city: 'Kipton',
    state: 'OH',
    latitude: '41.269183',
    longitude: '-82.305608',
    timeZoneId: 'America/New_York',
  },
  '44050': {
    zip: '44050',
    city: 'Lagrange',
    state: 'OH',
    latitude: '41.245712',
    longitude: '-82.130046',
    timeZoneId: 'America/New_York',
  },
  '44052': {
    zip: '44052',
    city: 'Lorain',
    state: 'OH',
    latitude: '41.455994',
    longitude: '-82.168713',
    timeZoneId: 'America/New_York',
  },
  '44053': {
    zip: '44053',
    city: 'Lorain',
    state: 'OH',
    latitude: '41.428474',
    longitude: '-82.204417',
    timeZoneId: 'America/New_York',
  },
  '44054': {
    zip: '44054',
    city: 'Sheffield Lake',
    state: 'OH',
    latitude: '41.47701',
    longitude: '-82.098416',
    timeZoneId: 'America/New_York',
  },
  '44055': {
    zip: '44055',
    city: 'Lorain',
    state: 'OH',
    latitude: '41.43438',
    longitude: '-82.13742',
    timeZoneId: 'America/New_York',
  },
  '44056': {
    zip: '44056',
    city: 'Macedonia',
    state: 'OH',
    latitude: '41.31372',
    longitude: '-81.49614',
    timeZoneId: 'America/New_York',
  },
  '44057': {
    zip: '44057',
    city: 'Madison',
    state: 'OH',
    latitude: '41.77097',
    longitude: '-81.058712',
    timeZoneId: 'America/New_York',
  },
  '44060': {
    zip: '44060',
    city: 'Mentor',
    state: 'OH',
    latitude: '41.72262',
    longitude: '-81.35652',
    timeZoneId: 'America/New_York',
  },
  '44061': {
    zip: '44061',
    city: 'Mentor',
    state: 'OH',
    latitude: '41.688236',
    longitude: '-81.332823',
    timeZoneId: 'America/New_York',
  },
  '44062': {
    zip: '44062',
    city: 'Middlefield',
    state: 'OH',
    latitude: '41.446501',
    longitude: '-81.015275',
    timeZoneId: 'America/New_York',
  },
  '44064': {
    zip: '44064',
    city: 'Montville',
    state: 'OH',
    latitude: '41.597486',
    longitude: '-81.050426',
    timeZoneId: 'America/New_York',
  },
  '44065': {
    zip: '44065',
    city: 'Newbury',
    state: 'OH',
    latitude: '41.464982',
    longitude: '-81.23849',
    timeZoneId: 'America/New_York',
  },
  '44067': {
    zip: '44067',
    city: 'Northfield',
    state: 'OH',
    latitude: '41.316627',
    longitude: '-81.548365',
    timeZoneId: 'America/New_York',
  },
  '44068': {
    zip: '44068',
    city: 'North Kingsville',
    state: 'OH',
    latitude: '41.905833',
    longitude: '-80.690556',
    timeZoneId: 'America/New_York',
  },
  '44070': {
    zip: '44070',
    city: 'North Olmsted',
    state: 'OH',
    latitude: '41.421789',
    longitude: '-81.918009',
    timeZoneId: 'America/New_York',
  },
  '44072': {
    zip: '44072',
    city: 'Novelty',
    state: 'OH',
    latitude: '41.4723',
    longitude: '-81.33522',
    timeZoneId: 'America/New_York',
  },
  '44073': {
    zip: '44073',
    city: 'Novelty',
    state: 'OH',
    latitude: '41.466851',
    longitude: '-81.339894',
    timeZoneId: 'America/New_York',
  },
  '44074': {
    zip: '44074',
    city: 'Oberlin',
    state: 'OH',
    latitude: '41.286311',
    longitude: '-82.234157',
    timeZoneId: 'America/New_York',
  },
  '44076': {
    zip: '44076',
    city: 'Orwell',
    state: 'OH',
    latitude: '41.532299',
    longitude: '-80.830144',
    timeZoneId: 'America/New_York',
  },
  '44077': {
    zip: '44077',
    city: 'Painesville',
    state: 'OH',
    latitude: '41.75',
    longitude: '-81.283333',
    timeZoneId: 'America/New_York',
  },
  '44080': {
    zip: '44080',
    city: 'Parkman',
    state: 'OH',
    latitude: '41.370196',
    longitude: '-81.065945',
    timeZoneId: 'America/New_York',
  },
  '44081': {
    zip: '44081',
    city: 'Perry',
    state: 'OH',
    latitude: '41.765861',
    longitude: '-81.146611',
    timeZoneId: 'America/New_York',
  },
  '44082': {
    zip: '44082',
    city: 'Pierpont',
    state: 'OH',
    latitude: '41.762768',
    longitude: '-80.563672',
    timeZoneId: 'America/New_York',
  },
  '44084': {
    zip: '44084',
    city: 'Rock Creek',
    state: 'OH',
    latitude: '41.666663',
    longitude: '-80.872296',
    timeZoneId: 'America/New_York',
  },
  '44085': {
    zip: '44085',
    city: 'Rome',
    state: 'OH',
    latitude: '41.615188',
    longitude: '-80.856141',
    timeZoneId: 'America/New_York',
  },
  '44086': {
    zip: '44086',
    city: 'Thompson',
    state: 'OH',
    latitude: '41.677794',
    longitude: '-81.052903',
    timeZoneId: 'America/New_York',
  },
  '44087': {
    zip: '44087',
    city: 'Twinsburg',
    state: 'OH',
    latitude: '41.322813',
    longitude: '-81.453825',
    timeZoneId: 'America/New_York',
  },
  '44088': {
    zip: '44088',
    city: 'Unionville',
    state: 'OH',
    latitude: '41.779353',
    longitude: '-81.007653',
    timeZoneId: 'America/New_York',
  },
  '44089': {
    zip: '44089',
    city: 'Vermilion',
    state: 'OH',
    latitude: '41.406313',
    longitude: '-82.382678',
    timeZoneId: 'America/New_York',
  },
  '44090': {
    zip: '44090',
    city: 'Wellington',
    state: 'OH',
    latitude: '41.15538',
    longitude: '-82.20684',
    timeZoneId: 'America/New_York',
  },
  '44092': {
    zip: '44092',
    city: 'Wickliffe',
    state: 'OH',
    latitude: '41.603368',
    longitude: '-81.464859',
    timeZoneId: 'America/New_York',
  },
  '44093': {
    zip: '44093',
    city: 'Williamsfield',
    state: 'OH',
    latitude: '41.528936',
    longitude: '-80.609192',
    timeZoneId: 'America/New_York',
  },
  '44094': {
    zip: '44094',
    city: 'Willoughby',
    state: 'OH',
    latitude: '41.637224',
    longitude: '-81.418352',
    timeZoneId: 'America/New_York',
  },
  '44095': {
    zip: '44095',
    city: 'Eastlake',
    state: 'OH',
    latitude: '41.65481',
    longitude: '-81.440913',
    timeZoneId: 'America/New_York',
  },
  '44096': {
    zip: '44096',
    city: 'Willoughby',
    state: 'OH',
    latitude: '41.646617',
    longitude: '-81.408728',
    timeZoneId: 'America/New_York',
  },
  '44097': {
    zip: '44097',
    city: 'Eastlake',
    state: 'OH',
    latitude: '41.651449',
    longitude: '-81.44878',
    timeZoneId: 'America/New_York',
  },
  '44099': {
    zip: '44099',
    city: 'Windsor',
    state: 'OH',
    latitude: '41.561142',
    longitude: '-80.96699',
    timeZoneId: 'America/New_York',
  },
  '44101': {
    zip: '44101',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.494043',
    longitude: '-81.669403',
    timeZoneId: 'America/New_York',
  },
  '44102': {
    zip: '44102',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.470294',
    longitude: '-81.737685',
    timeZoneId: 'America/New_York',
  },
  '44103': {
    zip: '44103',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.511131',
    longitude: '-81.637887',
    timeZoneId: 'America/New_York',
  },
  '44104': {
    zip: '44104',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.484079',
    longitude: '-81.629568',
    timeZoneId: 'America/New_York',
  },
  '44105': {
    zip: '44105',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.452479',
    longitude: '-81.625585',
    timeZoneId: 'America/New_York',
  },
  '44106': {
    zip: '44106',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.505132',
    longitude: '-81.607134',
    timeZoneId: 'America/New_York',
  },
  '44107': {
    zip: '44107',
    city: 'Lakewood',
    state: 'OH',
    latitude: '41.482343',
    longitude: '-81.794563',
    timeZoneId: 'America/New_York',
  },
  '44108': {
    zip: '44108',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.539143',
    longitude: '-81.611674',
    timeZoneId: 'America/New_York',
  },
  '44109': {
    zip: '44109',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.450419',
    longitude: '-81.7019',
    timeZoneId: 'America/New_York',
  },
  '44110': {
    zip: '44110',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.565195',
    longitude: '-81.5671',
    timeZoneId: 'America/New_York',
  },
  '44111': {
    zip: '44111',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.459888',
    longitude: '-81.784804',
    timeZoneId: 'America/New_York',
  },
  '44112': {
    zip: '44112',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.534374',
    longitude: '-81.574679',
    timeZoneId: 'America/New_York',
  },
  '44113': {
    zip: '44113',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.48285',
    longitude: '-81.694321',
    timeZoneId: 'America/New_York',
  },
  '44114': {
    zip: '44114',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.508496',
    longitude: '-81.675394',
    timeZoneId: 'America/New_York',
  },
  '44115': {
    zip: '44115',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.496936',
    longitude: '-81.6742',
    timeZoneId: 'America/New_York',
  },
  '44116': {
    zip: '44116',
    city: 'Rocky River',
    state: 'OH',
    latitude: '41.467062',
    longitude: '-81.860125',
    timeZoneId: 'America/New_York',
  },
  '44117': {
    zip: '44117',
    city: 'Euclid',
    state: 'OH',
    latitude: '41.56963',
    longitude: '-81.525168',
    timeZoneId: 'America/New_York',
  },
  '44118': {
    zip: '44118',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.498766',
    longitude: '-81.554594',
    timeZoneId: 'America/New_York',
  },
  '44119': {
    zip: '44119',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.586699',
    longitude: '-81.547659',
    timeZoneId: 'America/New_York',
  },
  '44120': {
    zip: '44120',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.472129',
    longitude: '-81.578028',
    timeZoneId: 'America/New_York',
  },
  '44121': {
    zip: '44121',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.52',
    longitude: '-81.556389',
    timeZoneId: 'America/New_York',
  },
  '44122': {
    zip: '44122',
    city: 'Beachwood',
    state: 'OH',
    latitude: '41.468177',
    longitude: '-81.514037',
    timeZoneId: 'America/New_York',
  },
  '44123': {
    zip: '44123',
    city: 'Euclid',
    state: 'OH',
    latitude: '41.593056',
    longitude: '-81.526944',
    timeZoneId: 'America/New_York',
  },
  '44124': {
    zip: '44124',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.511136',
    longitude: '-81.463234',
    timeZoneId: 'America/New_York',
  },
  '44125': {
    zip: '44125',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.435278',
    longitude: '-81.6575',
    timeZoneId: 'America/New_York',
  },
  '44126': {
    zip: '44126',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.446483',
    longitude: '-81.856757',
    timeZoneId: 'America/New_York',
  },
  '44127': {
    zip: '44127',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.47203',
    longitude: '-81.650481',
    timeZoneId: 'America/New_York',
  },
  '44128': {
    zip: '44128',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.441144',
    longitude: '-81.545858',
    timeZoneId: 'America/New_York',
  },
  '44129': {
    zip: '44129',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.38644',
    longitude: '-81.7377',
    timeZoneId: 'America/New_York',
  },
  '44130': {
    zip: '44130',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.380101',
    longitude: '-81.785719',
    timeZoneId: 'America/New_York',
  },
  '44131': {
    zip: '44131',
    city: 'Independence',
    state: 'OH',
    latitude: '41.384643',
    longitude: '-81.657442',
    timeZoneId: 'America/New_York',
  },
  '44132': {
    zip: '44132',
    city: 'Euclid',
    state: 'OH',
    latitude: '41.606668',
    longitude: '-81.50085',
    timeZoneId: 'America/New_York',
  },
  '44133': {
    zip: '44133',
    city: 'North Royalton',
    state: 'OH',
    latitude: '41.315718',
    longitude: '-81.741271',
    timeZoneId: 'America/New_York',
  },
  '44134': {
    zip: '44134',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.387185',
    longitude: '-81.702198',
    timeZoneId: 'America/New_York',
  },
  '44135': {
    zip: '44135',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.434425',
    longitude: '-81.80182',
    timeZoneId: 'America/New_York',
  },
  '44136': {
    zip: '44136',
    city: 'Strongsville',
    state: 'OH',
    latitude: '41.31288',
    longitude: '-81.83472',
    timeZoneId: 'America/New_York',
  },
  '44137': {
    zip: '44137',
    city: 'Maple Heights',
    state: 'OH',
    latitude: '41.409495',
    longitude: '-81.558216',
    timeZoneId: 'America/New_York',
  },
  '44138': {
    zip: '44138',
    city: 'Olmsted Falls',
    state: 'OH',
    latitude: '41.378304',
    longitude: '-81.918914',
    timeZoneId: 'America/New_York',
  },
  '44139': {
    zip: '44139',
    city: 'Solon',
    state: 'OH',
    latitude: '41.392321',
    longitude: '-81.444888',
    timeZoneId: 'America/New_York',
  },
  '44140': {
    zip: '44140',
    city: 'Bay Village',
    state: 'OH',
    latitude: '41.48982',
    longitude: '-81.92148',
    timeZoneId: 'America/New_York',
  },
  '44141': {
    zip: '44141',
    city: 'Brecksville',
    state: 'OH',
    latitude: '41.30832',
    longitude: '-81.62076',
    timeZoneId: 'America/New_York',
  },
  '44142': {
    zip: '44142',
    city: 'Brookpark',
    state: 'OH',
    latitude: '41.398708',
    longitude: '-81.832392',
    timeZoneId: 'America/New_York',
  },
  '44143': {
    zip: '44143',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.555719',
    longitude: '-81.479949',
    timeZoneId: 'America/New_York',
  },
  '44144': {
    zip: '44144',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.437456',
    longitude: '-81.744047',
    timeZoneId: 'America/New_York',
  },
  '44145': {
    zip: '44145',
    city: 'Westlake',
    state: 'OH',
    latitude: '41.450401',
    longitude: '-81.925054',
    timeZoneId: 'America/New_York',
  },
  '44146': {
    zip: '44146',
    city: 'Bedford',
    state: 'OH',
    latitude: '41.38669',
    longitude: '-81.527124',
    timeZoneId: 'America/New_York',
  },
  '44147': {
    zip: '44147',
    city: 'Broadview Heights',
    state: 'OH',
    latitude: '41.320684',
    longitude: '-81.676508',
    timeZoneId: 'America/New_York',
  },
  '44149': {
    zip: '44149',
    city: 'Strongsville',
    state: 'OH',
    latitude: '41.308372',
    longitude: '-81.856213',
    timeZoneId: 'America/New_York',
  },
  '44181': {
    zip: '44181',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.46138',
    longitude: '-81.78691',
    timeZoneId: 'America/New_York',
  },
  '44188': {
    zip: '44188',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.400504',
    longitude: '-81.826781',
    timeZoneId: 'America/New_York',
  },
  '44190': {
    zip: '44190',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.503501',
    longitude: '-81.62116',
    timeZoneId: 'America/New_York',
  },
  '44191': {
    zip: '44191',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.48468',
    longitude: '-81.702744',
    timeZoneId: 'America/New_York',
  },
  '44192': {
    zip: '44192',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.50172',
    longitude: '-81.686437',
    timeZoneId: 'America/New_York',
  },
  '44193': {
    zip: '44193',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.502884',
    longitude: '-81.695421',
    timeZoneId: 'America/New_York',
  },
  '44194': {
    zip: '44194',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.516028',
    longitude: '-81.672288',
    timeZoneId: 'America/New_York',
  },
  '44195': {
    zip: '44195',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.502183',
    longitude: '-81.619647',
    timeZoneId: 'America/New_York',
  },
  '44197': {
    zip: '44197',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.480998',
    longitude: '-81.681696',
    timeZoneId: 'America/New_York',
  },
  '44198': {
    zip: '44198',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.493668',
    longitude: '-81.676246',
    timeZoneId: 'America/New_York',
  },
  '44199': {
    zip: '44199',
    city: 'Cleveland',
    state: 'OH',
    latitude: '41.505351',
    longitude: '-81.693427',
    timeZoneId: 'America/New_York',
  },
  '44201': {
    zip: '44201',
    city: 'Atwater',
    state: 'OH',
    latitude: '41.023195',
    longitude: '-81.197179',
    timeZoneId: 'America/New_York',
  },
  '44202': {
    zip: '44202',
    city: 'Aurora',
    state: 'OH',
    latitude: '41.314774',
    longitude: '-81.345613',
    timeZoneId: 'America/New_York',
  },
  '44203': {
    zip: '44203',
    city: 'Barberton',
    state: 'OH',
    latitude: '41.014711',
    longitude: '-81.6145',
    timeZoneId: 'America/New_York',
  },
  '44210': {
    zip: '44210',
    city: 'Bath',
    state: 'OH',
    latitude: '41.188919',
    longitude: '-81.629751',
    timeZoneId: 'America/New_York',
  },
  '44211': {
    zip: '44211',
    city: 'Brady Lake',
    state: 'OH',
    latitude: '41.170383',
    longitude: '-81.316819',
    timeZoneId: 'America/New_York',
  },
  '44212': {
    zip: '44212',
    city: 'Brunswick',
    state: 'OH',
    latitude: '41.248756',
    longitude: '-81.828533',
    timeZoneId: 'America/New_York',
  },
  '44214': {
    zip: '44214',
    city: 'Burbank',
    state: 'OH',
    latitude: '40.946706',
    longitude: '-81.998141',
    timeZoneId: 'America/New_York',
  },
  '44215': {
    zip: '44215',
    city: 'Chippewa Lake',
    state: 'OH',
    latitude: '41.068001',
    longitude: '-81.904211',
    timeZoneId: 'America/New_York',
  },
  '44216': {
    zip: '44216',
    city: 'Clinton',
    state: 'OH',
    latitude: '40.938924',
    longitude: '-81.580189',
    timeZoneId: 'America/New_York',
  },
  '44217': {
    zip: '44217',
    city: 'Creston',
    state: 'OH',
    latitude: '40.961849',
    longitude: '-81.906308',
    timeZoneId: 'America/New_York',
  },
  '44221': {
    zip: '44221',
    city: 'Cuyahoga Falls',
    state: 'OH',
    latitude: '41.142424',
    longitude: '-81.472891',
    timeZoneId: 'America/New_York',
  },
  '44222': {
    zip: '44222',
    city: 'Cuyahoga Falls',
    state: 'OH',
    latitude: '41.135336',
    longitude: '-81.483106',
    timeZoneId: 'America/New_York',
  },
  '44223': {
    zip: '44223',
    city: 'Cuyahoga Falls',
    state: 'OH',
    latitude: '41.145747',
    longitude: '-81.518239',
    timeZoneId: 'America/New_York',
  },
  '44224': {
    zip: '44224',
    city: 'Stow',
    state: 'OH',
    latitude: '41.1735',
    longitude: '-81.4497',
    timeZoneId: 'America/New_York',
  },
  '44230': {
    zip: '44230',
    city: 'Doylestown',
    state: 'OH',
    latitude: '40.96572',
    longitude: '-81.687944',
    timeZoneId: 'America/New_York',
  },
  '44231': {
    zip: '44231',
    city: 'Garrettsville',
    state: 'OH',
    latitude: '41.29932',
    longitude: '-81.07302',
    timeZoneId: 'America/New_York',
  },
  '44232': {
    zip: '44232',
    city: 'Green',
    state: 'OH',
    latitude: '40.9458',
    longitude: '-81.4833',
    timeZoneId: 'America/New_York',
  },
  '44233': {
    zip: '44233',
    city: 'Hinckley',
    state: 'OH',
    latitude: '41.239897',
    longitude: '-81.729713',
    timeZoneId: 'America/New_York',
  },
  '44234': {
    zip: '44234',
    city: 'Hiram',
    state: 'OH',
    latitude: '41.313003',
    longitude: '-81.156535',
    timeZoneId: 'America/New_York',
  },
  '44235': {
    zip: '44235',
    city: 'Homerville',
    state: 'OH',
    latitude: '41.037145',
    longitude: '-82.120441',
    timeZoneId: 'America/New_York',
  },
  '44236': {
    zip: '44236',
    city: 'Hudson',
    state: 'OH',
    latitude: '41.246532',
    longitude: '-81.439256',
    timeZoneId: 'America/New_York',
  },
  '44237': {
    zip: '44237',
    city: 'Hudson',
    state: 'OH',
    latitude: '41.254965',
    longitude: '-81.481388',
    timeZoneId: 'America/New_York',
  },
  '44240': {
    zip: '44240',
    city: 'Kent',
    state: 'OH',
    latitude: '41.152005',
    longitude: '-81.325997',
    timeZoneId: 'America/New_York',
  },
  '44241': {
    zip: '44241',
    city: 'Streetsboro',
    state: 'OH',
    latitude: '41.248684',
    longitude: '-81.345809',
    timeZoneId: 'America/New_York',
  },
  '44242': {
    zip: '44242',
    city: 'Kent',
    state: 'OH',
    latitude: '41.146742',
    longitude: '-81.362684',
    timeZoneId: 'America/New_York',
  },
  '44243': {
    zip: '44243',
    city: 'Kent',
    state: 'OH',
    latitude: '41.136498',
    longitude: '-81.341458',
    timeZoneId: 'America/New_York',
  },
  '44250': {
    zip: '44250',
    city: 'Lakemore',
    state: 'OH',
    latitude: '41.020998',
    longitude: '-81.434099',
    timeZoneId: 'America/New_York',
  },
  '44251': {
    zip: '44251',
    city: 'Westfield Center',
    state: 'OH',
    latitude: '41.027952',
    longitude: '-81.938348',
    timeZoneId: 'America/New_York',
  },
  '44253': {
    zip: '44253',
    city: 'Litchfield',
    state: 'OH',
    latitude: '41.167635',
    longitude: '-82.032477',
    timeZoneId: 'America/New_York',
  },
  '44254': {
    zip: '44254',
    city: 'Lodi',
    state: 'OH',
    latitude: '41.041984',
    longitude: '-82.015606',
    timeZoneId: 'America/New_York',
  },
  '44255': {
    zip: '44255',
    city: 'Mantua',
    state: 'OH',
    latitude: '41.30088',
    longitude: '-81.22986',
    timeZoneId: 'America/New_York',
  },
  '44256': {
    zip: '44256',
    city: 'Medina',
    state: 'OH',
    latitude: '41.134844',
    longitude: '-81.860472',
    timeZoneId: 'America/New_York',
  },
  '44258': {
    zip: '44258',
    city: 'Medina',
    state: 'OH',
    latitude: '41.101605',
    longitude: '-82.12428',
    timeZoneId: 'America/New_York',
  },
  '44260': {
    zip: '44260',
    city: 'Mogadore',
    state: 'OH',
    latitude: '41.036803',
    longitude: '-81.348875',
    timeZoneId: 'America/New_York',
  },
  '44262': {
    zip: '44262',
    city: 'Munroe Falls',
    state: 'OH',
    latitude: '41.142981',
    longitude: '-81.439172',
    timeZoneId: 'America/New_York',
  },
  '44264': {
    zip: '44264',
    city: 'Peninsula',
    state: 'OH',
    latitude: '41.23242',
    longitude: '-81.553757',
    timeZoneId: 'America/New_York',
  },
  '44265': {
    zip: '44265',
    city: 'Randolph',
    state: 'OH',
    latitude: '41.019515',
    longitude: '-81.302392',
    timeZoneId: 'America/New_York',
  },
  '44266': {
    zip: '44266',
    city: 'Ravenna',
    state: 'OH',
    latitude: '41.159227',
    longitude: '-81.233714',
    timeZoneId: 'America/New_York',
  },
  '44270': {
    zip: '44270',
    city: 'Rittman',
    state: 'OH',
    latitude: '40.9525',
    longitude: '-81.735556',
    timeZoneId: 'America/New_York',
  },
  '44272': {
    zip: '44272',
    city: 'Rootstown',
    state: 'OH',
    latitude: '41.09713',
    longitude: '-81.216588',
    timeZoneId: 'America/New_York',
  },
  '44273': {
    zip: '44273',
    city: 'Seville',
    state: 'OH',
    latitude: '41.027572',
    longitude: '-81.883128',
    timeZoneId: 'America/New_York',
  },
  '44274': {
    zip: '44274',
    city: 'Sharon Center',
    state: 'OH',
    latitude: '41.096432',
    longitude: '-81.738688',
    timeZoneId: 'America/New_York',
  },
  '44275': {
    zip: '44275',
    city: 'Spencer',
    state: 'OH',
    latitude: '41.101074',
    longitude: '-82.102809',
    timeZoneId: 'America/New_York',
  },
  '44276': {
    zip: '44276',
    city: 'Sterling',
    state: 'OH',
    latitude: '40.940184',
    longitude: '-81.834331',
    timeZoneId: 'America/New_York',
  },
  '44278': {
    zip: '44278',
    city: 'Tallmadge',
    state: 'OH',
    latitude: '41.095652',
    longitude: '-81.429879',
    timeZoneId: 'America/New_York',
  },
  '44280': {
    zip: '44280',
    city: 'Valley City',
    state: 'OH',
    latitude: '41.238919',
    longitude: '-81.917158',
    timeZoneId: 'America/New_York',
  },
  '44281': {
    zip: '44281',
    city: 'Wadsworth',
    state: 'OH',
    latitude: '41.029624',
    longitude: '-81.733414',
    timeZoneId: 'America/New_York',
  },
  '44282': {
    zip: '44282',
    city: 'Wadsworth',
    state: 'OH',
    latitude: '41.027604',
    longitude: '-81.727651',
    timeZoneId: 'America/New_York',
  },
  '44285': {
    zip: '44285',
    city: 'Wayland',
    state: 'OH',
    latitude: '41.160687',
    longitude: '-81.067897',
    timeZoneId: 'America/New_York',
  },
  '44286': {
    zip: '44286',
    city: 'Richfield',
    state: 'OH',
    latitude: '41.231451',
    longitude: '-81.641199',
    timeZoneId: 'America/New_York',
  },
  '44287': {
    zip: '44287',
    city: 'West Salem',
    state: 'OH',
    latitude: '40.925833',
    longitude: '-82.053056',
    timeZoneId: 'America/New_York',
  },
  '44288': {
    zip: '44288',
    city: 'Windham',
    state: 'OH',
    latitude: '41.242226',
    longitude: '-81.056024',
    timeZoneId: 'America/New_York',
  },
  '44301': {
    zip: '44301',
    city: 'Akron',
    state: 'OH',
    latitude: '41.043261',
    longitude: '-81.521198',
    timeZoneId: 'America/New_York',
  },
  '44302': {
    zip: '44302',
    city: 'Akron',
    state: 'OH',
    latitude: '41.092067',
    longitude: '-81.539665',
    timeZoneId: 'America/New_York',
  },
  '44303': {
    zip: '44303',
    city: 'Akron',
    state: 'OH',
    latitude: '41.102498',
    longitude: '-81.53499',
    timeZoneId: 'America/New_York',
  },
  '44304': {
    zip: '44304',
    city: 'Akron',
    state: 'OH',
    latitude: '41.082751',
    longitude: '-81.50955',
    timeZoneId: 'America/New_York',
  },
  '44305': {
    zip: '44305',
    city: 'Akron',
    state: 'OH',
    latitude: '41.07376',
    longitude: '-81.465332',
    timeZoneId: 'America/New_York',
  },
  '44306': {
    zip: '44306',
    city: 'Akron',
    state: 'OH',
    latitude: '41.047753',
    longitude: '-81.488189',
    timeZoneId: 'America/New_York',
  },
  '44307': {
    zip: '44307',
    city: 'Akron',
    state: 'OH',
    latitude: '41.067235',
    longitude: '-81.547576',
    timeZoneId: 'America/New_York',
  },
  '44308': {
    zip: '44308',
    city: 'Akron',
    state: 'OH',
    latitude: '41.081525',
    longitude: '-81.518551',
    timeZoneId: 'America/New_York',
  },
  '44309': {
    zip: '44309',
    city: 'Akron',
    state: 'OH',
    latitude: '41.099536',
    longitude: '-81.514473',
    timeZoneId: 'America/New_York',
  },
  '44310': {
    zip: '44310',
    city: 'Akron',
    state: 'OH',
    latitude: '41.106498',
    longitude: '-81.497681',
    timeZoneId: 'America/New_York',
  },
  '44311': {
    zip: '44311',
    city: 'Akron',
    state: 'OH',
    latitude: '41.060965',
    longitude: '-81.521953',
    timeZoneId: 'America/New_York',
  },
  '44312': {
    zip: '44312',
    city: 'Akron',
    state: 'OH',
    latitude: '41.029977',
    longitude: '-81.439236',
    timeZoneId: 'America/New_York',
  },
  '44313': {
    zip: '44313',
    city: 'Akron',
    state: 'OH',
    latitude: '41.134604',
    longitude: '-81.59571',
    timeZoneId: 'America/New_York',
  },
  '44314': {
    zip: '44314',
    city: 'Akron',
    state: 'OH',
    latitude: '41.042156',
    longitude: '-81.557464',
    timeZoneId: 'America/New_York',
  },
  '44315': {
    zip: '44315',
    city: 'Akron',
    state: 'OH',
    latitude: '41.037729',
    longitude: '-81.483085',
    timeZoneId: 'America/New_York',
  },
  '44316': {
    zip: '44316',
    city: 'Akron',
    state: 'OH',
    latitude: '41.079334',
    longitude: '-81.472767',
    timeZoneId: 'America/New_York',
  },
  '44317': {
    zip: '44317',
    city: 'Akron',
    state: 'OH',
    latitude: '41.045074',
    longitude: '-81.526198',
    timeZoneId: 'America/New_York',
  },
  '44319': {
    zip: '44319',
    city: 'Akron',
    state: 'OH',
    latitude: '40.987174',
    longitude: '-81.531004',
    timeZoneId: 'America/New_York',
  },
  '44320': {
    zip: '44320',
    city: 'Akron',
    state: 'OH',
    latitude: '41.081335',
    longitude: '-81.573558',
    timeZoneId: 'America/New_York',
  },
  '44321': {
    zip: '44321',
    city: 'Akron',
    state: 'OH',
    latitude: '41.104382',
    longitude: '-81.650553',
    timeZoneId: 'America/New_York',
  },
  '44325': {
    zip: '44325',
    city: 'Akron',
    state: 'OH',
    latitude: '41.076672',
    longitude: '-81.510338',
    timeZoneId: 'America/New_York',
  },
  '44326': {
    zip: '44326',
    city: 'Akron',
    state: 'OH',
    latitude: '41.083101',
    longitude: '-81.517487',
    timeZoneId: 'America/New_York',
  },
  '44328': {
    zip: '44328',
    city: 'Akron',
    state: 'OH',
    latitude: '41.087158',
    longitude: '-81.506914',
    timeZoneId: 'America/New_York',
  },
  '44333': {
    zip: '44333',
    city: 'Akron',
    state: 'OH',
    latitude: '41.15604',
    longitude: '-81.63774',
    timeZoneId: 'America/New_York',
  },
  '44334': {
    zip: '44334',
    city: 'Fairlawn',
    state: 'OH',
    latitude: '41.079338',
    longitude: '-81.516616',
    timeZoneId: 'America/New_York',
  },
  '44372': {
    zip: '44372',
    city: 'Akron',
    state: 'OH',
    latitude: '41.150868',
    longitude: '-81.608894',
    timeZoneId: 'America/New_York',
  },
  '44396': {
    zip: '44396',
    city: 'Akron',
    state: 'OH',
    latitude: '41.065643',
    longitude: '-81.520817',
    timeZoneId: 'America/New_York',
  },
  '44398': {
    zip: '44398',
    city: 'Akron',
    state: 'OH',
    latitude: '41.081679',
    longitude: '-81.507276',
    timeZoneId: 'America/New_York',
  },
  '44401': {
    zip: '44401',
    city: 'Berlin Center',
    state: 'OH',
    latitude: '41.03572',
    longitude: '-80.951296',
    timeZoneId: 'America/New_York',
  },
  '44402': {
    zip: '44402',
    city: 'Bristolville',
    state: 'OH',
    latitude: '41.38039',
    longitude: '-80.859104',
    timeZoneId: 'America/New_York',
  },
  '44403': {
    zip: '44403',
    city: 'Brookfield',
    state: 'OH',
    latitude: '41.236682',
    longitude: '-80.577887',
    timeZoneId: 'America/New_York',
  },
  '44404': {
    zip: '44404',
    city: 'Burghill',
    state: 'OH',
    latitude: '41.335819',
    longitude: '-80.548258',
    timeZoneId: 'America/New_York',
  },
  '44405': {
    zip: '44405',
    city: 'Campbell',
    state: 'OH',
    latitude: '41.075132',
    longitude: '-80.589868',
    timeZoneId: 'America/New_York',
  },
  '44406': {
    zip: '44406',
    city: 'Canfield',
    state: 'OH',
    latitude: '41.029535',
    longitude: '-80.757209',
    timeZoneId: 'America/New_York',
  },
  '44408': {
    zip: '44408',
    city: 'Columbiana',
    state: 'OH',
    latitude: '40.873824',
    longitude: '-80.681892',
    timeZoneId: 'America/New_York',
  },
  '44410': {
    zip: '44410',
    city: 'Cortland',
    state: 'OH',
    latitude: '41.331036',
    longitude: '-80.729796',
    timeZoneId: 'America/New_York',
  },
  '44411': {
    zip: '44411',
    city: 'Deerfield',
    state: 'OH',
    latitude: '41.021628',
    longitude: '-81.042585',
    timeZoneId: 'America/New_York',
  },
  '44412': {
    zip: '44412',
    city: 'Diamond',
    state: 'OH',
    latitude: '41.095854',
    longitude: '-81.042337',
    timeZoneId: 'America/New_York',
  },
  '44413': {
    zip: '44413',
    city: 'East Palestine',
    state: 'OH',
    latitude: '40.838445',
    longitude: '-80.548361',
    timeZoneId: 'America/New_York',
  },
  '44415': {
    zip: '44415',
    city: 'Elkton',
    state: 'OH',
    latitude: '40.770882',
    longitude: '-80.691391',
    timeZoneId: 'America/New_York',
  },
  '44416': {
    zip: '44416',
    city: 'Ellsworth',
    state: 'OH',
    latitude: '41.025292',
    longitude: '-80.858693',
    timeZoneId: 'America/New_York',
  },
  '44417': {
    zip: '44417',
    city: 'Farmdale',
    state: 'OH',
    latitude: '41.42579',
    longitude: '-80.661237',
    timeZoneId: 'America/New_York',
  },
  '44418': {
    zip: '44418',
    city: 'Fowler',
    state: 'OH',
    latitude: '41.302457',
    longitude: '-80.606765',
    timeZoneId: 'America/New_York',
  },
  '44420': {
    zip: '44420',
    city: 'Girard',
    state: 'OH',
    latitude: '41.157789',
    longitude: '-80.68793',
    timeZoneId: 'America/New_York',
  },
  '44422': {
    zip: '44422',
    city: 'Greenford',
    state: 'OH',
    latitude: '40.945019',
    longitude: '-80.792061',
    timeZoneId: 'America/New_York',
  },
  '44423': {
    zip: '44423',
    city: 'Hanoverton',
    state: 'OH',
    latitude: '40.765396',
    longitude: '-80.906383',
    timeZoneId: 'America/New_York',
  },
  '44424': {
    zip: '44424',
    city: 'Hartford',
    state: 'OH',
    latitude: '41.317268',
    longitude: '-80.569501',
    timeZoneId: 'America/New_York',
  },
  '44425': {
    zip: '44425',
    city: 'Hubbard',
    state: 'OH',
    latitude: '41.163813',
    longitude: '-80.581187',
    timeZoneId: 'America/New_York',
  },
  '44427': {
    zip: '44427',
    city: 'Kensington',
    state: 'OH',
    latitude: '40.732793',
    longitude: '-80.953074',
    timeZoneId: 'America/New_York',
  },
  '44428': {
    zip: '44428',
    city: 'Kinsman',
    state: 'OH',
    latitude: '41.437474',
    longitude: '-80.580228',
    timeZoneId: 'America/New_York',
  },
  '44429': {
    zip: '44429',
    city: 'Lake Milton',
    state: 'OH',
    latitude: '41.101255',
    longitude: '-80.981944',
    timeZoneId: 'America/New_York',
  },
  '44430': {
    zip: '44430',
    city: 'Leavittsburg',
    state: 'OH',
    latitude: '41.239301',
    longitude: '-80.887941',
    timeZoneId: 'America/New_York',
  },
  '44431': {
    zip: '44431',
    city: 'Leetonia',
    state: 'OH',
    latitude: '40.860443',
    longitude: '-80.75483',
    timeZoneId: 'America/New_York',
  },
  '44432': {
    zip: '44432',
    city: 'Lisbon',
    state: 'OH',
    latitude: '40.761443',
    longitude: '-80.762546',
    timeZoneId: 'America/New_York',
  },
  '44436': {
    zip: '44436',
    city: 'Lowellville',
    state: 'OH',
    latitude: '41.051961',
    longitude: '-80.544699',
    timeZoneId: 'America/New_York',
  },
  '44437': {
    zip: '44437',
    city: 'Mc Donald',
    state: 'OH',
    latitude: '41.158569',
    longitude: '-80.732639',
    timeZoneId: 'America/New_York',
  },
  '44438': {
    zip: '44438',
    city: 'Masury',
    state: 'OH',
    latitude: '41.225733',
    longitude: '-80.533137',
    timeZoneId: 'America/New_York',
  },
  '44439': {
    zip: '44439',
    city: 'Mesopotamia',
    state: 'OH',
    latitude: '41.464411',
    longitude: '-80.950412',
    timeZoneId: 'America/New_York',
  },
  '44440': {
    zip: '44440',
    city: 'Mineral Ridge',
    state: 'OH',
    latitude: '41.1431',
    longitude: '-80.774484',
    timeZoneId: 'America/New_York',
  },
  '44441': {
    zip: '44441',
    city: 'Negley',
    state: 'OH',
    latitude: '40.771152',
    longitude: '-80.555526',
    timeZoneId: 'America/New_York',
  },
  '44442': {
    zip: '44442',
    city: 'New Middletown',
    state: 'OH',
    latitude: '40.965625',
    longitude: '-80.556678',
    timeZoneId: 'America/New_York',
  },
  '44443': {
    zip: '44443',
    city: 'New Springfield',
    state: 'OH',
    latitude: '40.928502',
    longitude: '-80.596676',
    timeZoneId: 'America/New_York',
  },
  '44444': {
    zip: '44444',
    city: 'Newton Falls',
    state: 'OH',
    latitude: '41.185982',
    longitude: '-80.96761',
    timeZoneId: 'America/New_York',
  },
  '44445': {
    zip: '44445',
    city: 'New Waterford',
    state: 'OH',
    latitude: '40.848035',
    longitude: '-80.61983',
    timeZoneId: 'America/New_York',
  },
  '44446': {
    zip: '44446',
    city: 'Niles',
    state: 'OH',
    latitude: '41.184692',
    longitude: '-80.748967',
    timeZoneId: 'America/New_York',
  },
  '44449': {
    zip: '44449',
    city: 'North Benton',
    state: 'OH',
    latitude: '40.997024',
    longitude: '-81.026963',
    timeZoneId: 'America/New_York',
  },
  '44450': {
    zip: '44450',
    city: 'North Bloomfield',
    state: 'OH',
    latitude: '41.46078',
    longitude: '-80.83044',
    timeZoneId: 'America/New_York',
  },
  '44451': {
    zip: '44451',
    city: 'North Jackson',
    state: 'OH',
    latitude: '41.078419',
    longitude: '-80.867992',
    timeZoneId: 'America/New_York',
  },
  '44452': {
    zip: '44452',
    city: 'North Lima',
    state: 'OH',
    latitude: '40.952505',
    longitude: '-80.658498',
    timeZoneId: 'America/New_York',
  },
  '44453': {
    zip: '44453',
    city: 'Orangeville',
    state: 'OH',
    latitude: '41.336016',
    longitude: '-80.526283',
    timeZoneId: 'America/New_York',
  },
  '44454': {
    zip: '44454',
    city: 'Petersburg',
    state: 'OH',
    latitude: '40.919478',
    longitude: '-80.537955',
    timeZoneId: 'America/New_York',
  },
  '44455': {
    zip: '44455',
    city: 'Rogers',
    state: 'OH',
    latitude: '40.779315',
    longitude: '-80.606133',
    timeZoneId: 'America/New_York',
  },
  '44460': {
    zip: '44460',
    city: 'Salem',
    state: 'OH',
    latitude: '40.886216',
    longitude: '-80.871563',
    timeZoneId: 'America/New_York',
  },
  '44470': {
    zip: '44470',
    city: 'Southington',
    state: 'OH',
    latitude: '41.29965',
    longitude: '-80.950333',
    timeZoneId: 'America/New_York',
  },
  '44471': {
    zip: '44471',
    city: 'Struthers',
    state: 'OH',
    latitude: '41.051161',
    longitude: '-80.592557',
    timeZoneId: 'America/New_York',
  },
  '44473': {
    zip: '44473',
    city: 'Vienna',
    state: 'OH',
    latitude: '41.237908',
    longitude: '-80.662007',
    timeZoneId: 'America/New_York',
  },
  '44481': {
    zip: '44481',
    city: 'Warren',
    state: 'OH',
    latitude: '41.24142',
    longitude: '-80.85708',
    timeZoneId: 'America/New_York',
  },
  '44482': {
    zip: '44482',
    city: 'Warren',
    state: 'OH',
    latitude: '41.194253',
    longitude: '-80.827465',
    timeZoneId: 'America/New_York',
  },
  '44483': {
    zip: '44483',
    city: 'Warren',
    state: 'OH',
    latitude: '41.27814',
    longitude: '-80.8173',
    timeZoneId: 'America/New_York',
  },
  '44484': {
    zip: '44484',
    city: 'Warren',
    state: 'OH',
    latitude: '41.231245',
    longitude: '-80.762228',
    timeZoneId: 'America/New_York',
  },
  '44485': {
    zip: '44485',
    city: 'Warren',
    state: 'OH',
    latitude: '41.235976',
    longitude: '-80.843944',
    timeZoneId: 'America/New_York',
  },
  '44486': {
    zip: '44486',
    city: 'Warren',
    state: 'OH',
    latitude: '41.300597',
    longitude: '-80.844183',
    timeZoneId: 'America/New_York',
  },
  '44490': {
    zip: '44490',
    city: 'Washingtonville',
    state: 'OH',
    latitude: '40.899998',
    longitude: '-80.761464',
    timeZoneId: 'America/New_York',
  },
  '44491': {
    zip: '44491',
    city: 'West Farmington',
    state: 'OH',
    latitude: '41.368536',
    longitude: '-80.963139',
    timeZoneId: 'America/New_York',
  },
  '44492': {
    zip: '44492',
    city: 'West Point',
    state: 'OH',
    latitude: '40.707961',
    longitude: '-80.702205',
    timeZoneId: 'America/New_York',
  },
  '44493': {
    zip: '44493',
    city: 'Winona',
    state: 'OH',
    latitude: '40.823075',
    longitude: '-80.89826',
    timeZoneId: 'America/New_York',
  },
  '44501': {
    zip: '44501',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.097395',
    longitude: '-80.661703',
    timeZoneId: 'America/New_York',
  },
  '44502': {
    zip: '44502',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.084949',
    longitude: '-80.6391',
    timeZoneId: 'America/New_York',
  },
  '44503': {
    zip: '44503',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.097435',
    longitude: '-80.652249',
    timeZoneId: 'America/New_York',
  },
  '44504': {
    zip: '44504',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.12282',
    longitude: '-80.656988',
    timeZoneId: 'America/New_York',
  },
  '44505': {
    zip: '44505',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.112602',
    longitude: '-80.609229',
    timeZoneId: 'America/New_York',
  },
  '44506': {
    zip: '44506',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.098383',
    longitude: '-80.625881',
    timeZoneId: 'America/New_York',
  },
  '44507': {
    zip: '44507',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.073822',
    longitude: '-80.656567',
    timeZoneId: 'America/New_York',
  },
  '44509': {
    zip: '44509',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.105989',
    longitude: '-80.695488',
    timeZoneId: 'America/New_York',
  },
  '44510': {
    zip: '44510',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.121745',
    longitude: '-80.667955',
    timeZoneId: 'America/New_York',
  },
  '44511': {
    zip: '44511',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.071321',
    longitude: '-80.698827',
    timeZoneId: 'America/New_York',
  },
  '44512': {
    zip: '44512',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.04168',
    longitude: '-80.6856',
    timeZoneId: 'America/New_York',
  },
  '44513': {
    zip: '44513',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.024672',
    longitude: '-80.67596',
    timeZoneId: 'America/New_York',
  },
  '44514': {
    zip: '44514',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.00874',
    longitude: '-80.62416',
    timeZoneId: 'America/New_York',
  },
  '44515': {
    zip: '44515',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.099874',
    longitude: '-80.751233',
    timeZoneId: 'America/New_York',
  },
  '44555': {
    zip: '44555',
    city: 'Youngstown',
    state: 'OH',
    latitude: '41.101415',
    longitude: '-80.643558',
    timeZoneId: 'America/New_York',
  },
  '44601': {
    zip: '44601',
    city: 'Alliance',
    state: 'OH',
    latitude: '40.918936',
    longitude: '-81.135368',
    timeZoneId: 'America/New_York',
  },
  '44606': {
    zip: '44606',
    city: 'Apple Creek',
    state: 'OH',
    latitude: '40.737325',
    longitude: '-81.805321',
    timeZoneId: 'America/New_York',
  },
  '44607': {
    zip: '44607',
    city: 'Augusta',
    state: 'OH',
    latitude: '40.68424',
    longitude: '-81.017079',
    timeZoneId: 'America/New_York',
  },
  '44608': {
    zip: '44608',
    city: 'Beach City',
    state: 'OH',
    latitude: '40.656862',
    longitude: '-81.585627',
    timeZoneId: 'America/New_York',
  },
  '44609': {
    zip: '44609',
    city: 'Beloit',
    state: 'OH',
    latitude: '40.931159',
    longitude: '-80.98899',
    timeZoneId: 'America/New_York',
  },
  '44610': {
    zip: '44610',
    city: 'Berlin',
    state: 'OH',
    latitude: '40.558598',
    longitude: '-81.809134',
    timeZoneId: 'America/New_York',
  },
  '44611': {
    zip: '44611',
    city: 'Big Prairie',
    state: 'OH',
    latitude: '40.635128',
    longitude: '-82.09411',
    timeZoneId: 'America/New_York',
  },
  '44612': {
    zip: '44612',
    city: 'Bolivar',
    state: 'OH',
    latitude: '40.643022',
    longitude: '-81.44274',
    timeZoneId: 'America/New_York',
  },
  '44613': {
    zip: '44613',
    city: 'Brewster',
    state: 'OH',
    latitude: '40.713728',
    longitude: '-81.598902',
    timeZoneId: 'America/New_York',
  },
  '44614': {
    zip: '44614',
    city: 'Canal Fulton',
    state: 'OH',
    latitude: '40.88058',
    longitude: '-81.58968',
    timeZoneId: 'America/New_York',
  },
  '44615': {
    zip: '44615',
    city: 'Carrollton',
    state: 'OH',
    latitude: '40.559162',
    longitude: '-81.083154',
    timeZoneId: 'America/New_York',
  },
  '44617': {
    zip: '44617',
    city: 'Charm',
    state: 'OH',
    latitude: '40.506035',
    longitude: '-81.784473',
    timeZoneId: 'America/New_York',
  },
  '44618': {
    zip: '44618',
    city: 'Dalton',
    state: 'OH',
    latitude: '40.785331',
    longitude: '-81.699322',
    timeZoneId: 'America/New_York',
  },
  '44619': {
    zip: '44619',
    city: 'Damascus',
    state: 'OH',
    latitude: '40.897775',
    longitude: '-80.95763',
    timeZoneId: 'America/New_York',
  },
  '44620': {
    zip: '44620',
    city: 'Dellroy',
    state: 'OH',
    latitude: '40.574692',
    longitude: '-81.210512',
    timeZoneId: 'America/New_York',
  },
  '44621': {
    zip: '44621',
    city: 'Dennison',
    state: 'OH',
    latitude: '40.395563',
    longitude: '-81.329056',
    timeZoneId: 'America/New_York',
  },
  '44622': {
    zip: '44622',
    city: 'Dover',
    state: 'OH',
    latitude: '40.55112',
    longitude: '-81.4458',
    timeZoneId: 'America/New_York',
  },
  '44624': {
    zip: '44624',
    city: 'Dundee',
    state: 'OH',
    latitude: '40.6563',
    longitude: '-81.69108',
    timeZoneId: 'America/New_York',
  },
  '44625': {
    zip: '44625',
    city: 'East Rochester',
    state: 'OH',
    latitude: '40.771834',
    longitude: '-80.996741',
    timeZoneId: 'America/New_York',
  },
  '44626': {
    zip: '44626',
    city: 'East Sparta',
    state: 'OH',
    latitude: '40.694655',
    longitude: '-81.377559',
    timeZoneId: 'America/New_York',
  },
  '44627': {
    zip: '44627',
    city: 'Fredericksburg',
    state: 'OH',
    latitude: '40.689496',
    longitude: '-81.854261',
    timeZoneId: 'America/New_York',
  },
  '44628': {
    zip: '44628',
    city: 'Glenmont',
    state: 'OH',
    latitude: '40.536455',
    longitude: '-82.219421',
    timeZoneId: 'America/New_York',
  },
  '44629': {
    zip: '44629',
    city: 'Gnadenhutten',
    state: 'OH',
    latitude: '40.357109',
    longitude: '-81.42914',
    timeZoneId: 'America/New_York',
  },
  '44630': {
    zip: '44630',
    city: 'Greentown',
    state: 'OH',
    latitude: '40.928064',
    longitude: '-81.405022',
    timeZoneId: 'America/New_York',
  },
  '44632': {
    zip: '44632',
    city: 'Hartville',
    state: 'OH',
    latitude: '40.959926',
    longitude: '-81.326086',
    timeZoneId: 'America/New_York',
  },
  '44633': {
    zip: '44633',
    city: 'Holmesville',
    state: 'OH',
    latitude: '40.61969',
    longitude: '-81.920167',
    timeZoneId: 'America/New_York',
  },
  '44634': {
    zip: '44634',
    city: 'Homeworth',
    state: 'OH',
    latitude: '40.830436',
    longitude: '-81.045474',
    timeZoneId: 'America/New_York',
  },
  '44636': {
    zip: '44636',
    city: 'Kidron',
    state: 'OH',
    latitude: '40.742337',
    longitude: '-81.74703',
    timeZoneId: 'America/New_York',
  },
  '44637': {
    zip: '44637',
    city: 'Killbuck',
    state: 'OH',
    latitude: '40.502447',
    longitude: '-81.988483',
    timeZoneId: 'America/New_York',
  },
  '44638': {
    zip: '44638',
    city: 'Lakeville',
    state: 'OH',
    latitude: '40.623008',
    longitude: '-82.136093',
    timeZoneId: 'America/New_York',
  },
  '44639': {
    zip: '44639',
    city: 'Leesville',
    state: 'OH',
    latitude: '40.444048',
    longitude: '-81.219711',
    timeZoneId: 'America/New_York',
  },
  '44640': {
    zip: '44640',
    city: 'Limaville',
    state: 'OH',
    latitude: '40.982869',
    longitude: '-81.146572',
    timeZoneId: 'America/New_York',
  },
  '44641': {
    zip: '44641',
    city: 'Louisville',
    state: 'OH',
    latitude: '40.845335',
    longitude: '-81.258482',
    timeZoneId: 'America/New_York',
  },
  '44643': {
    zip: '44643',
    city: 'Magnolia',
    state: 'OH',
    latitude: '40.667895',
    longitude: '-81.306958',
    timeZoneId: 'America/New_York',
  },
  '44644': {
    zip: '44644',
    city: 'Malvern',
    state: 'OH',
    latitude: '40.685014',
    longitude: '-81.195285',
    timeZoneId: 'America/New_York',
  },
  '44645': {
    zip: '44645',
    city: 'Marshallville',
    state: 'OH',
    latitude: '40.906247',
    longitude: '-81.72751',
    timeZoneId: 'America/New_York',
  },
  '44646': {
    zip: '44646',
    city: 'Massillon',
    state: 'OH',
    latitude: '40.802818',
    longitude: '-81.520846',
    timeZoneId: 'America/New_York',
  },
  '44647': {
    zip: '44647',
    city: 'Massillon',
    state: 'OH',
    latitude: '40.797699',
    longitude: '-81.552259',
    timeZoneId: 'America/New_York',
  },
  '44648': {
    zip: '44648',
    city: 'Massillon',
    state: 'OH',
    latitude: '40.75416',
    longitude: '-81.513999',
    timeZoneId: 'America/New_York',
  },
  '44650': {
    zip: '44650',
    city: 'Maximo',
    state: 'OH',
    latitude: '40.876028',
    longitude: '-81.174132',
    timeZoneId: 'America/New_York',
  },
  '44651': {
    zip: '44651',
    city: 'Mechanicstown',
    state: 'OH',
    latitude: '40.632802',
    longitude: '-80.959765',
    timeZoneId: 'America/New_York',
  },
  '44652': {
    zip: '44652',
    city: 'Middlebranch',
    state: 'OH',
    latitude: '40.900119',
    longitude: '-81.33008',
    timeZoneId: 'America/New_York',
  },
  '44653': {
    zip: '44653',
    city: 'Midvale',
    state: 'OH',
    latitude: '40.437941',
    longitude: '-81.370867',
    timeZoneId: 'America/New_York',
  },
  '44654': {
    zip: '44654',
    city: 'Millersburg',
    state: 'OH',
    latitude: '40.545778',
    longitude: '-81.873263',
    timeZoneId: 'America/New_York',
  },
  '44656': {
    zip: '44656',
    city: 'Mineral City',
    state: 'OH',
    latitude: '40.60932',
    longitude: '-81.3582',
    timeZoneId: 'America/New_York',
  },
  '44657': {
    zip: '44657',
    city: 'Minerva',
    state: 'OH',
    latitude: '40.73898',
    longitude: '-81.0996',
    timeZoneId: 'America/New_York',
  },
  '44659': {
    zip: '44659',
    city: 'Mount Eaton',
    state: 'OH',
    latitude: '40.693635',
    longitude: '-81.703189',
    timeZoneId: 'America/New_York',
  },
  '44660': {
    zip: '44660',
    city: 'Mount Hope',
    state: 'OH',
    latitude: '40.6221',
    longitude: '-81.787658',
    timeZoneId: 'America/New_York',
  },
  '44661': {
    zip: '44661',
    city: 'Nashville',
    state: 'OH',
    latitude: '40.592914',
    longitude: '-82.099696',
    timeZoneId: 'America/New_York',
  },
  '44662': {
    zip: '44662',
    city: 'Navarre',
    state: 'OH',
    latitude: '40.717968',
    longitude: '-81.530814',
    timeZoneId: 'America/New_York',
  },
  '44663': {
    zip: '44663',
    city: 'New Philadelphia',
    state: 'OH',
    latitude: '40.48604',
    longitude: '-81.442532',
    timeZoneId: 'America/New_York',
  },
  '44665': {
    zip: '44665',
    city: 'North Georgetown',
    state: 'OH',
    latitude: '40.839273',
    longitude: '-80.98603',
    timeZoneId: 'America/New_York',
  },
  '44666': {
    zip: '44666',
    city: 'North Lawrence',
    state: 'OH',
    latitude: '40.832808',
    longitude: '-81.615396',
    timeZoneId: 'America/New_York',
  },
  '44667': {
    zip: '44667',
    city: 'Orrville',
    state: 'OH',
    latitude: '40.833108',
    longitude: '-81.768456',
    timeZoneId: 'America/New_York',
  },
  '44669': {
    zip: '44669',
    city: 'Paris',
    state: 'OH',
    latitude: '40.788186',
    longitude: '-81.15978',
    timeZoneId: 'America/New_York',
  },
  '44670': {
    zip: '44670',
    city: 'Robertsville',
    state: 'OH',
    latitude: '40.761561',
    longitude: '-81.186167',
    timeZoneId: 'America/New_York',
  },
  '44671': {
    zip: '44671',
    city: 'Sandyville',
    state: 'OH',
    latitude: '40.63952',
    longitude: '-81.370179',
    timeZoneId: 'America/New_York',
  },
  '44672': {
    zip: '44672',
    city: 'Sebring',
    state: 'OH',
    latitude: '40.919938',
    longitude: '-81.026671',
    timeZoneId: 'America/New_York',
  },
  '44675': {
    zip: '44675',
    city: 'Sherrodsville',
    state: 'OH',
    latitude: '40.52015',
    longitude: '-81.233307',
    timeZoneId: 'America/New_York',
  },
  '44676': {
    zip: '44676',
    city: 'Shreve',
    state: 'OH',
    latitude: '40.697236',
    longitude: '-82.034128',
    timeZoneId: 'America/New_York',
  },
  '44677': {
    zip: '44677',
    city: 'Smithville',
    state: 'OH',
    latitude: '40.870847',
    longitude: '-81.857857',
    timeZoneId: 'America/New_York',
  },
  '44678': {
    zip: '44678',
    city: 'Somerdale',
    state: 'OH',
    latitude: '40.565943',
    longitude: '-81.362676',
    timeZoneId: 'America/New_York',
  },
  '44679': {
    zip: '44679',
    city: 'Stillwater',
    state: 'OH',
    latitude: '40.32091',
    longitude: '-81.309501',
    timeZoneId: 'America/New_York',
  },
  '44680': {
    zip: '44680',
    city: 'Strasburg',
    state: 'OH',
    latitude: '40.600632',
    longitude: '-81.53326',
    timeZoneId: 'America/New_York',
  },
  '44681': {
    zip: '44681',
    city: 'Sugarcreek',
    state: 'OH',
    latitude: '40.51284',
    longitude: '-81.6783',
    timeZoneId: 'America/New_York',
  },
  '44682': {
    zip: '44682',
    city: 'Tuscarawas',
    state: 'OH',
    latitude: '40.400835',
    longitude: '-81.405712',
    timeZoneId: 'America/New_York',
  },
  '44683': {
    zip: '44683',
    city: 'Uhrichsville',
    state: 'OH',
    latitude: '40.392715',
    longitude: '-81.347949',
    timeZoneId: 'America/New_York',
  },
  '44685': {
    zip: '44685',
    city: 'Uniontown',
    state: 'OH',
    latitude: '40.961109',
    longitude: '-81.388948',
    timeZoneId: 'America/New_York',
  },
  '44687': {
    zip: '44687',
    city: 'Walnut Creek',
    state: 'OH',
    latitude: '40.542033',
    longitude: '-81.719256',
    timeZoneId: 'America/New_York',
  },
  '44688': {
    zip: '44688',
    city: 'Waynesburg',
    state: 'OH',
    latitude: '40.685305',
    longitude: '-81.267135',
    timeZoneId: 'America/New_York',
  },
  '44689': {
    zip: '44689',
    city: 'Wilmot',
    state: 'OH',
    latitude: '40.657592',
    longitude: '-81.635586',
    timeZoneId: 'America/New_York',
  },
  '44690': {
    zip: '44690',
    city: 'Winesburg',
    state: 'OH',
    latitude: '40.617698',
    longitude: '-81.706191',
    timeZoneId: 'America/New_York',
  },
  '44691': {
    zip: '44691',
    city: 'Wooster',
    state: 'OH',
    latitude: '40.806978',
    longitude: '-81.956817',
    timeZoneId: 'America/New_York',
  },
  '44693': {
    zip: '44693',
    city: 'Deersville',
    state: 'OH',
    latitude: '40.308377',
    longitude: '-81.185804',
    timeZoneId: 'America/New_York',
  },
  '44695': {
    zip: '44695',
    city: 'Bowerston',
    state: 'OH',
    latitude: '40.415653',
    longitude: '-81.173846',
    timeZoneId: 'America/New_York',
  },
  '44697': {
    zip: '44697',
    city: 'Zoar',
    state: 'OH',
    latitude: '40.614579',
    longitude: '-81.423245',
    timeZoneId: 'America/New_York',
  },
  '44699': {
    zip: '44699',
    city: 'Tippecanoe',
    state: 'OH',
    latitude: '40.281057',
    longitude: '-81.268298',
    timeZoneId: 'America/New_York',
  },
  '44701': {
    zip: '44701',
    city: 'Canton',
    state: 'OH',
    latitude: '40.729703',
    longitude: '-81.450833',
    timeZoneId: 'America/New_York',
  },
  '44702': {
    zip: '44702',
    city: 'Canton',
    state: 'OH',
    latitude: '40.798315',
    longitude: '-81.377198',
    timeZoneId: 'America/New_York',
  },
  '44703': {
    zip: '44703',
    city: 'Canton',
    state: 'OH',
    latitude: '40.807231',
    longitude: '-81.369181',
    timeZoneId: 'America/New_York',
  },
  '44704': {
    zip: '44704',
    city: 'Canton',
    state: 'OH',
    latitude: '40.800224',
    longitude: '-81.353797',
    timeZoneId: 'America/New_York',
  },
  '44705': {
    zip: '44705',
    city: 'Canton',
    state: 'OH',
    latitude: '40.822094',
    longitude: '-81.338111',
    timeZoneId: 'America/New_York',
  },
  '44706': {
    zip: '44706',
    city: 'Canton',
    state: 'OH',
    latitude: '40.771402',
    longitude: '-81.413102',
    timeZoneId: 'America/New_York',
  },
  '44707': {
    zip: '44707',
    city: 'Canton',
    state: 'OH',
    latitude: '40.780264',
    longitude: '-81.361212',
    timeZoneId: 'America/New_York',
  },
  '44708': {
    zip: '44708',
    city: 'Canton',
    state: 'OH',
    latitude: '40.812034',
    longitude: '-81.424133',
    timeZoneId: 'America/New_York',
  },
  '44709': {
    zip: '44709',
    city: 'Canton',
    state: 'OH',
    latitude: '40.839184',
    longitude: '-81.389308',
    timeZoneId: 'America/New_York',
  },
  '44710': {
    zip: '44710',
    city: 'Canton',
    state: 'OH',
    latitude: '40.793793',
    longitude: '-81.421951',
    timeZoneId: 'America/New_York',
  },
  '44711': {
    zip: '44711',
    city: 'Canton',
    state: 'OH',
    latitude: '40.830222',
    longitude: '-81.386453',
    timeZoneId: 'America/New_York',
  },
  '44714': {
    zip: '44714',
    city: 'Canton',
    state: 'OH',
    latitude: '40.829869',
    longitude: '-81.364581',
    timeZoneId: 'America/New_York',
  },
  '44718': {
    zip: '44718',
    city: 'Canton',
    state: 'OH',
    latitude: '40.851462',
    longitude: '-81.443104',
    timeZoneId: 'America/New_York',
  },
  '44720': {
    zip: '44720',
    city: 'North Canton',
    state: 'OH',
    latitude: '40.89732',
    longitude: '-81.43344',
    timeZoneId: 'America/New_York',
  },
  '44721': {
    zip: '44721',
    city: 'Canton',
    state: 'OH',
    latitude: '40.882563',
    longitude: '-81.346759',
    timeZoneId: 'America/New_York',
  },
  '44730': {
    zip: '44730',
    city: 'East Canton',
    state: 'OH',
    latitude: '40.76556',
    longitude: '-81.26652',
    timeZoneId: 'America/New_York',
  },
  '44735': {
    zip: '44735',
    city: 'Canton',
    state: 'OH',
    latitude: '40.84795',
    longitude: '-81.441359',
    timeZoneId: 'America/New_York',
  },
  '44750': {
    zip: '44750',
    city: 'Canton',
    state: 'OH',
    latitude: '40.791005',
    longitude: '-81.423621',
    timeZoneId: 'America/New_York',
  },
  '44767': {
    zip: '44767',
    city: 'Canton',
    state: 'OH',
    latitude: '40.894428',
    longitude: '-81.422107',
    timeZoneId: 'America/New_York',
  },
  '44799': {
    zip: '44799',
    city: 'Canton',
    state: 'OH',
    latitude: '40.811856',
    longitude: '-81.373368',
    timeZoneId: 'America/New_York',
  },
  '44802': {
    zip: '44802',
    city: 'Alvada',
    state: 'OH',
    latitude: '41.041557',
    longitude: '-83.446098',
    timeZoneId: 'America/New_York',
  },
  '44804': {
    zip: '44804',
    city: 'Arcadia',
    state: 'OH',
    latitude: '41.108804',
    longitude: '-83.523527',
    timeZoneId: 'America/New_York',
  },
  '44805': {
    zip: '44805',
    city: 'Ashland',
    state: 'OH',
    latitude: '40.85322',
    longitude: '-82.31502',
    timeZoneId: 'America/New_York',
  },
  '44807': {
    zip: '44807',
    city: 'Attica',
    state: 'OH',
    latitude: '41.072571',
    longitude: '-82.891165',
    timeZoneId: 'America/New_York',
  },
  '44809': {
    zip: '44809',
    city: 'Bascom',
    state: 'OH',
    latitude: '41.133459',
    longitude: '-83.284306',
    timeZoneId: 'America/New_York',
  },
  '44811': {
    zip: '44811',
    city: 'Bellevue',
    state: 'OH',
    latitude: '41.254327',
    longitude: '-82.816931',
    timeZoneId: 'America/New_York',
  },
  '44813': {
    zip: '44813',
    city: 'Bellville',
    state: 'OH',
    latitude: '40.610016',
    longitude: '-82.51793',
    timeZoneId: 'America/New_York',
  },
  '44814': {
    zip: '44814',
    city: 'Berlin Heights',
    state: 'OH',
    latitude: '41.327386',
    longitude: '-82.477219',
    timeZoneId: 'America/New_York',
  },
  '44815': {
    zip: '44815',
    city: 'Bettsville',
    state: 'OH',
    latitude: '41.242804',
    longitude: '-83.232241',
    timeZoneId: 'America/New_York',
  },
  '44816': {
    zip: '44816',
    city: 'Birmingham',
    state: 'OH',
    latitude: '41.329962',
    longitude: '-82.361126',
    timeZoneId: 'America/New_York',
  },
  '44817': {
    zip: '44817',
    city: 'Bloomdale',
    state: 'OH',
    latitude: '41.193937',
    longitude: '-83.549843',
    timeZoneId: 'America/New_York',
  },
  '44818': {
    zip: '44818',
    city: 'Bloomville',
    state: 'OH',
    latitude: '41.01378',
    longitude: '-82.99092',
    timeZoneId: 'America/New_York',
  },
  '44820': {
    zip: '44820',
    city: 'Bucyrus',
    state: 'OH',
    latitude: '40.816708',
    longitude: '-82.969402',
    timeZoneId: 'America/New_York',
  },
  '44822': {
    zip: '44822',
    city: 'Butler',
    state: 'OH',
    latitude: '40.583729',
    longitude: '-82.415811',
    timeZoneId: 'America/New_York',
  },
  '44824': {
    zip: '44824',
    city: 'Castalia',
    state: 'OH',
    latitude: '41.38157',
    longitude: '-82.799917',
    timeZoneId: 'America/New_York',
  },
  '44825': {
    zip: '44825',
    city: 'Chatfield',
    state: 'OH',
    latitude: '40.950456',
    longitude: '-82.94207',
    timeZoneId: 'America/New_York',
  },
  '44826': {
    zip: '44826',
    city: 'Collins',
    state: 'OH',
    latitude: '41.236226',
    longitude: '-82.484275',
    timeZoneId: 'America/New_York',
  },
  '44827': {
    zip: '44827',
    city: 'Crestline',
    state: 'OH',
    latitude: '40.799552',
    longitude: '-82.760593',
    timeZoneId: 'America/New_York',
  },
  '44828': {
    zip: '44828',
    city: 'Flat Rock',
    state: 'OH',
    latitude: '41.236043',
    longitude: '-82.861254',
    timeZoneId: 'America/New_York',
  },
  '44830': {
    zip: '44830',
    city: 'Fostoria',
    state: 'OH',
    latitude: '41.185277',
    longitude: '-83.406022',
    timeZoneId: 'America/New_York',
  },
  '44833': {
    zip: '44833',
    city: 'Galion',
    state: 'OH',
    latitude: '40.735262',
    longitude: '-82.797835',
    timeZoneId: 'America/New_York',
  },
  '44836': {
    zip: '44836',
    city: 'Green Springs',
    state: 'OH',
    latitude: '41.232713',
    longitude: '-83.073155',
    timeZoneId: 'America/New_York',
  },
  '44837': {
    zip: '44837',
    city: 'Greenwich',
    state: 'OH',
    latitude: '41.032283',
    longitude: '-82.508909',
    timeZoneId: 'America/New_York',
  },
  '44838': {
    zip: '44838',
    city: 'Hayesville',
    state: 'OH',
    latitude: '40.77262',
    longitude: '-82.259036',
    timeZoneId: 'America/New_York',
  },
  '44839': {
    zip: '44839',
    city: 'Huron',
    state: 'OH',
    latitude: '41.388818',
    longitude: '-82.561774',
    timeZoneId: 'America/New_York',
  },
  '44840': {
    zip: '44840',
    city: 'Jeromesville',
    state: 'OH',
    latitude: '40.810987',
    longitude: '-82.189201',
    timeZoneId: 'America/New_York',
  },
  '44841': {
    zip: '44841',
    city: 'Kansas',
    state: 'OH',
    latitude: '41.246255',
    longitude: '-83.299949',
    timeZoneId: 'America/New_York',
  },
  '44842': {
    zip: '44842',
    city: 'Loudonville',
    state: 'OH',
    latitude: '40.644167',
    longitude: '-82.233247',
    timeZoneId: 'America/New_York',
  },
  '44843': {
    zip: '44843',
    city: 'Lucas',
    state: 'OH',
    latitude: '40.691',
    longitude: '-82.404305',
    timeZoneId: 'America/New_York',
  },
  '44844': {
    zip: '44844',
    city: 'Mc Cutchenville',
    state: 'OH',
    latitude: '40.981166',
    longitude: '-83.270538',
    timeZoneId: 'America/New_York',
  },
  '44845': {
    zip: '44845',
    city: 'Melmore',
    state: 'OH',
    latitude: '41.031153',
    longitude: '-83.133461',
    timeZoneId: 'America/New_York',
  },
  '44846': {
    zip: '44846',
    city: 'Milan',
    state: 'OH',
    latitude: '41.308029',
    longitude: '-82.601408',
    timeZoneId: 'America/New_York',
  },
  '44847': {
    zip: '44847',
    city: 'Monroeville',
    state: 'OH',
    latitude: '41.214505',
    longitude: '-82.714432',
    timeZoneId: 'America/New_York',
  },
  '44848': {
    zip: '44848',
    city: 'Nankin',
    state: 'OH',
    latitude: '40.920824',
    longitude: '-82.28186',
    timeZoneId: 'America/New_York',
  },
  '44849': {
    zip: '44849',
    city: 'Nevada',
    state: 'OH',
    latitude: '40.8264',
    longitude: '-83.1309',
    timeZoneId: 'America/New_York',
  },
  '44850': {
    zip: '44850',
    city: 'New Haven',
    state: 'OH',
    latitude: '41.034144',
    longitude: '-82.680678',
    timeZoneId: 'America/New_York',
  },
  '44851': {
    zip: '44851',
    city: 'New London',
    state: 'OH',
    latitude: '41.101292',
    longitude: '-82.421414',
    timeZoneId: 'America/New_York',
  },
  '44853': {
    zip: '44853',
    city: 'New Riegel',
    state: 'OH',
    latitude: '41.052873',
    longitude: '-83.311555',
    timeZoneId: 'America/New_York',
  },
  '44854': {
    zip: '44854',
    city: 'New Washington',
    state: 'OH',
    latitude: '40.951507',
    longitude: '-82.851713',
    timeZoneId: 'America/New_York',
  },
  '44855': {
    zip: '44855',
    city: 'North Fairfield',
    state: 'OH',
    latitude: '41.10383',
    longitude: '-82.597828',
    timeZoneId: 'America/New_York',
  },
  '44856': {
    zip: '44856',
    city: 'North Robinson',
    state: 'OH',
    latitude: '40.792386',
    longitude: '-82.860196',
    timeZoneId: 'America/New_York',
  },
  '44857': {
    zip: '44857',
    city: 'Norwalk',
    state: 'OH',
    latitude: '41.238868',
    longitude: '-82.602595',
    timeZoneId: 'America/New_York',
  },
  '44859': {
    zip: '44859',
    city: 'Nova',
    state: 'OH',
    latitude: '41.001752',
    longitude: '-82.345248',
    timeZoneId: 'America/New_York',
  },
  '44860': {
    zip: '44860',
    city: 'Oceola',
    state: 'OH',
    latitude: '40.851887',
    longitude: '-83.096404',
    timeZoneId: 'America/New_York',
  },
  '44861': {
    zip: '44861',
    city: 'Old Fort',
    state: 'OH',
    latitude: '41.24208',
    longitude: '-83.153164',
    timeZoneId: 'America/New_York',
  },
  '44862': {
    zip: '44862',
    city: 'Ontario',
    state: 'OH',
    latitude: '40.757861',
    longitude: '-82.64614',
    timeZoneId: 'America/New_York',
  },
  '44864': {
    zip: '44864',
    city: 'Perrysville',
    state: 'OH',
    latitude: '40.65212',
    longitude: '-82.314078',
    timeZoneId: 'America/New_York',
  },
  '44865': {
    zip: '44865',
    city: 'Plymouth',
    state: 'OH',
    latitude: '40.99578',
    longitude: '-82.67028',
    timeZoneId: 'America/New_York',
  },
  '44866': {
    zip: '44866',
    city: 'Polk',
    state: 'OH',
    latitude: '40.92156',
    longitude: '-82.20228',
    timeZoneId: 'America/New_York',
  },
  '44867': {
    zip: '44867',
    city: 'Republic',
    state: 'OH',
    latitude: '41.141469',
    longitude: '-82.99649',
    timeZoneId: 'America/New_York',
  },
  '44870': {
    zip: '44870',
    city: 'Sandusky',
    state: 'OH',
    latitude: '41.40498',
    longitude: '-82.73142',
    timeZoneId: 'America/New_York',
  },
  '44871': {
    zip: '44871',
    city: 'Sandusky',
    state: 'OH',
    latitude: '41.449774',
    longitude: '-82.705869',
    timeZoneId: 'America/New_York',
  },
  '44874': {
    zip: '44874',
    city: 'Savannah',
    state: 'OH',
    latitude: '40.966349',
    longitude: '-82.366717',
    timeZoneId: 'America/New_York',
  },
  '44875': {
    zip: '44875',
    city: 'Shelby',
    state: 'OH',
    latitude: '40.8888',
    longitude: '-82.66254',
    timeZoneId: 'America/New_York',
  },
  '44878': {
    zip: '44878',
    city: 'Shiloh',
    state: 'OH',
    latitude: '40.93512',
    longitude: '-82.50798',
    timeZoneId: 'America/New_York',
  },
  '44880': {
    zip: '44880',
    city: 'Sullivan',
    state: 'OH',
    latitude: '41.031976',
    longitude: '-82.214181',
    timeZoneId: 'America/New_York',
  },
  '44881': {
    zip: '44881',
    city: 'Sulphur Springs',
    state: 'OH',
    latitude: '40.869583',
    longitude: '-82.881591',
    timeZoneId: 'America/New_York',
  },
  '44882': {
    zip: '44882',
    city: 'Sycamore',
    state: 'OH',
    latitude: '40.94382',
    longitude: '-83.13888',
    timeZoneId: 'America/New_York',
  },
  '44883': {
    zip: '44883',
    city: 'Tiffin',
    state: 'OH',
    latitude: '41.121369',
    longitude: '-83.17586',
    timeZoneId: 'America/New_York',
  },
  '44887': {
    zip: '44887',
    city: 'Tiro',
    state: 'OH',
    latitude: '40.904316',
    longitude: '-82.792556',
    timeZoneId: 'America/New_York',
  },
  '44888': {
    zip: '44888',
    city: 'Willard',
    state: 'OH',
    latitude: '41.05676',
    longitude: '-82.721898',
    timeZoneId: 'America/New_York',
  },
  '44889': {
    zip: '44889',
    city: 'Wakeman',
    state: 'OH',
    latitude: '41.224184',
    longitude: '-82.403611',
    timeZoneId: 'America/New_York',
  },
  '44890': {
    zip: '44890',
    city: 'Willard',
    state: 'OH',
    latitude: '41.074326',
    longitude: '-82.724338',
    timeZoneId: 'America/New_York',
  },
  '44901': {
    zip: '44901',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.685074',
    longitude: '-82.586108',
    timeZoneId: 'America/New_York',
  },
  '44902': {
    zip: '44902',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.761375',
    longitude: '-82.513115',
    timeZoneId: 'America/New_York',
  },
  '44903': {
    zip: '44903',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.76032',
    longitude: '-82.520048',
    timeZoneId: 'America/New_York',
  },
  '44904': {
    zip: '44904',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.67772',
    longitude: '-82.61484',
    timeZoneId: 'America/New_York',
  },
  '44905': {
    zip: '44905',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.775298',
    longitude: '-82.476807',
    timeZoneId: 'America/New_York',
  },
  '44906': {
    zip: '44906',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.764961',
    longitude: '-82.565848',
    timeZoneId: 'America/New_York',
  },
  '44907': {
    zip: '44907',
    city: 'Mansfield',
    state: 'OH',
    latitude: '40.735427',
    longitude: '-82.521533',
    timeZoneId: 'America/New_York',
  },
  '45001': {
    zip: '45001',
    city: 'Addyston',
    state: 'OH',
    latitude: '39.137789',
    longitude: '-84.709626',
    timeZoneId: 'America/New_York',
  },
  '45002': {
    zip: '45002',
    city: 'Cleves',
    state: 'OH',
    latitude: '39.181017',
    longitude: '-84.720886',
    timeZoneId: 'America/New_York',
  },
  '45003': {
    zip: '45003',
    city: 'College Corner',
    state: 'OH',
    latitude: '39.586561',
    longitude: '-84.786665',
    timeZoneId: 'America/New_York',
  },
  '45004': {
    zip: '45004',
    city: 'Collinsville',
    state: 'OH',
    latitude: '39.51275',
    longitude: '-84.612152',
    timeZoneId: 'America/New_York',
  },
  '45005': {
    zip: '45005',
    city: 'Franklin',
    state: 'OH',
    latitude: '39.52896',
    longitude: '-84.29742',
    timeZoneId: 'America/New_York',
  },
  '45011': {
    zip: '45011',
    city: 'Hamilton',
    state: 'OH',
    latitude: '39.406984',
    longitude: '-84.509883',
    timeZoneId: 'America/New_York',
  },
  '45012': {
    zip: '45012',
    city: 'Hamilton',
    state: 'OH',
    latitude: '39.474907',
    longitude: '-84.681954',
    timeZoneId: 'America/New_York',
  },
  '45013': {
    zip: '45013',
    city: 'Hamilton',
    state: 'OH',
    latitude: '39.408216',
    longitude: '-84.617598',
    timeZoneId: 'America/New_York',
  },
  '45014': {
    zip: '45014',
    city: 'Fairfield',
    state: 'OH',
    latitude: '39.33444',
    longitude: '-84.56226',
    timeZoneId: 'America/New_York',
  },
  '45015': {
    zip: '45015',
    city: 'Hamilton',
    state: 'OH',
    latitude: '39.367572',
    longitude: '-84.550446',
    timeZoneId: 'America/New_York',
  },
  '45018': {
    zip: '45018',
    city: 'Fairfield',
    state: 'OH',
    latitude: '39.430238',
    longitude: '-84.492982',
    timeZoneId: 'America/New_York',
  },
  '45030': {
    zip: '45030',
    city: 'Harrison',
    state: 'OH',
    latitude: '39.25116',
    longitude: '-84.73962',
    timeZoneId: 'America/New_York',
  },
  '45032': {
    zip: '45032',
    city: 'Harveysburg',
    state: 'OH',
    latitude: '39.504357',
    longitude: '-84.009988',
    timeZoneId: 'America/New_York',
  },
  '45033': {
    zip: '45033',
    city: 'Hooven',
    state: 'OH',
    latitude: '39.176513',
    longitude: '-84.759571',
    timeZoneId: 'America/New_York',
  },
  '45034': {
    zip: '45034',
    city: 'Kings Mills',
    state: 'OH',
    latitude: '39.355219',
    longitude: '-84.244219',
    timeZoneId: 'America/New_York',
  },
  '45036': {
    zip: '45036',
    city: 'Lebanon',
    state: 'OH',
    latitude: '39.446127',
    longitude: '-84.210158',
    timeZoneId: 'America/New_York',
  },
  '45039': {
    zip: '45039',
    city: 'Maineville',
    state: 'OH',
    latitude: '39.320497',
    longitude: '-84.253761',
    timeZoneId: 'America/New_York',
  },
  '45040': {
    zip: '45040',
    city: 'Mason',
    state: 'OH',
    latitude: '39.345374',
    longitude: '-84.313966',
    timeZoneId: 'America/New_York',
  },
  '45041': {
    zip: '45041',
    city: 'Miamitown',
    state: 'OH',
    latitude: '39.212981',
    longitude: '-84.703406',
    timeZoneId: 'America/New_York',
  },
  '45042': {
    zip: '45042',
    city: 'Middletown',
    state: 'OH',
    latitude: '39.54516',
    longitude: '-84.43926',
    timeZoneId: 'America/New_York',
  },
  '45044': {
    zip: '45044',
    city: 'Middletown',
    state: 'OH',
    latitude: '39.484738',
    longitude: '-84.383452',
    timeZoneId: 'America/New_York',
  },
  '45050': {
    zip: '45050',
    city: 'Monroe',
    state: 'OH',
    latitude: '39.446195',
    longitude: '-84.369751',
    timeZoneId: 'America/New_York',
  },
  '45051': {
    zip: '45051',
    city: 'Mount Saint Joseph',
    state: 'OH',
    latitude: '39.095333',
    longitude: '-84.63978',
    timeZoneId: 'America/New_York',
  },
  '45052': {
    zip: '45052',
    city: 'North Bend',
    state: 'OH',
    latitude: '39.14172',
    longitude: '-84.7566',
    timeZoneId: 'America/New_York',
  },
  '45053': {
    zip: '45053',
    city: 'Okeana',
    state: 'OH',
    latitude: '39.346343',
    longitude: '-84.783327',
    timeZoneId: 'America/New_York',
  },
  '45054': {
    zip: '45054',
    city: 'Oregonia',
    state: 'OH',
    latitude: '39.441107',
    longitude: '-84.067374',
    timeZoneId: 'America/New_York',
  },
  '45055': {
    zip: '45055',
    city: 'Overpeck',
    state: 'OH',
    latitude: '39.450623',
    longitude: '-84.514312',
    timeZoneId: 'America/New_York',
  },
  '45056': {
    zip: '45056',
    city: 'Oxford',
    state: 'OH',
    latitude: '39.494309',
    longitude: '-84.74407',
    timeZoneId: 'America/New_York',
  },
  '45061': {
    zip: '45061',
    city: 'Ross',
    state: 'OH',
    latitude: '39.311626',
    longitude: '-84.64507',
    timeZoneId: 'America/New_York',
  },
  '45062': {
    zip: '45062',
    city: 'Seven Mile',
    state: 'OH',
    latitude: '39.483343',
    longitude: '-84.55319',
    timeZoneId: 'America/New_York',
  },
  '45063': {
    zip: '45063',
    city: 'Shandon',
    state: 'OH',
    latitude: '39.324928',
    longitude: '-84.713192',
    timeZoneId: 'America/New_York',
  },
  '45064': {
    zip: '45064',
    city: 'Somerville',
    state: 'OH',
    latitude: '39.552619',
    longitude: '-84.633421',
    timeZoneId: 'America/New_York',
  },
  '45065': {
    zip: '45065',
    city: 'South Lebanon',
    state: 'OH',
    latitude: '39.37235',
    longitude: '-84.213694',
    timeZoneId: 'America/New_York',
  },
  '45066': {
    zip: '45066',
    city: 'Springboro',
    state: 'OH',
    latitude: '39.558963',
    longitude: '-84.223222',
    timeZoneId: 'America/New_York',
  },
  '45067': {
    zip: '45067',
    city: 'Trenton',
    state: 'OH',
    latitude: '39.468243',
    longitude: '-84.457116',
    timeZoneId: 'America/New_York',
  },
  '45068': {
    zip: '45068',
    city: 'Waynesville',
    state: 'OH',
    latitude: '39.52002',
    longitude: '-84.07392',
    timeZoneId: 'America/New_York',
  },
  '45069': {
    zip: '45069',
    city: 'West Chester',
    state: 'OH',
    latitude: '39.34146',
    longitude: '-84.398674',
    timeZoneId: 'America/New_York',
  },
  '45070': {
    zip: '45070',
    city: 'West Elkton',
    state: 'OH',
    latitude: '39.588718',
    longitude: '-84.556106',
    timeZoneId: 'America/New_York',
  },
  '45071': {
    zip: '45071',
    city: 'West Chester',
    state: 'OH',
    latitude: '39.333798',
    longitude: '-84.405623',
    timeZoneId: 'America/New_York',
  },
  '45101': {
    zip: '45101',
    city: 'Aberdeen',
    state: 'OH',
    latitude: '38.679427',
    longitude: '-83.750546',
    timeZoneId: 'America/New_York',
  },
  '45102': {
    zip: '45102',
    city: 'Amelia',
    state: 'OH',
    latitude: '39.0222',
    longitude: '-84.2256',
    timeZoneId: 'America/New_York',
  },
  '45103': {
    zip: '45103',
    city: 'Batavia',
    state: 'OH',
    latitude: '39.09726',
    longitude: '-84.14706',
    timeZoneId: 'America/New_York',
  },
  '45105': {
    zip: '45105',
    city: 'Bentonville',
    state: 'OH',
    latitude: '38.752554',
    longitude: '-83.609424',
    timeZoneId: 'America/New_York',
  },
  '45106': {
    zip: '45106',
    city: 'Bethel',
    state: 'OH',
    latitude: '38.948415',
    longitude: '-84.096965',
    timeZoneId: 'America/New_York',
  },
  '45107': {
    zip: '45107',
    city: 'Blanchester',
    state: 'OH',
    latitude: '39.306863',
    longitude: '-83.9652',
    timeZoneId: 'America/New_York',
  },
  '45111': {
    zip: '45111',
    city: 'Camp Dennison',
    state: 'OH',
    latitude: '39.19718',
    longitude: '-84.291954',
    timeZoneId: 'America/New_York',
  },
  '45112': {
    zip: '45112',
    city: 'Chilo',
    state: 'OH',
    latitude: '38.793094',
    longitude: '-84.139751',
    timeZoneId: 'America/New_York',
  },
  '45113': {
    zip: '45113',
    city: 'Clarksville',
    state: 'OH',
    latitude: '39.402232',
    longitude: '-83.965563',
    timeZoneId: 'America/New_York',
  },
  '45114': {
    zip: '45114',
    city: 'Cuba',
    state: 'OH',
    latitude: '39.361894',
    longitude: '-83.862435',
    timeZoneId: 'America/New_York',
  },
  '45115': {
    zip: '45115',
    city: 'Decatur',
    state: 'OH',
    latitude: '38.81218',
    longitude: '-83.697339',
    timeZoneId: 'America/New_York',
  },
  '45118': {
    zip: '45118',
    city: 'Fayetteville',
    state: 'OH',
    latitude: '39.183132',
    longitude: '-83.951736',
    timeZoneId: 'America/New_York',
  },
  '45119': {
    zip: '45119',
    city: 'Feesburg',
    state: 'OH',
    latitude: '38.877676',
    longitude: '-83.99702',
    timeZoneId: 'America/New_York',
  },
  '45120': {
    zip: '45120',
    city: 'Felicity',
    state: 'OH',
    latitude: '38.823814',
    longitude: '-84.104901',
    timeZoneId: 'America/New_York',
  },
  '45121': {
    zip: '45121',
    city: 'Georgetown',
    state: 'OH',
    latitude: '38.876731',
    longitude: '-83.895335',
    timeZoneId: 'America/New_York',
  },
  '45122': {
    zip: '45122',
    city: 'Goshen',
    state: 'OH',
    latitude: '39.22296',
    longitude: '-84.12024',
    timeZoneId: 'America/New_York',
  },
  '45123': {
    zip: '45123',
    city: 'Greenfield',
    state: 'OH',
    latitude: '39.333281',
    longitude: '-83.413658',
    timeZoneId: 'America/New_York',
  },
  '45130': {
    zip: '45130',
    city: 'Hamersville',
    state: 'OH',
    latitude: '38.910678',
    longitude: '-83.984564',
    timeZoneId: 'America/New_York',
  },
  '45131': {
    zip: '45131',
    city: 'Higginsport',
    state: 'OH',
    latitude: '38.790408',
    longitude: '-83.968405',
    timeZoneId: 'America/New_York',
  },
  '45132': {
    zip: '45132',
    city: 'Highland',
    state: 'OH',
    latitude: '39.346947',
    longitude: '-83.602314',
    timeZoneId: 'America/New_York',
  },
  '45133': {
    zip: '45133',
    city: 'Hillsboro',
    state: 'OH',
    latitude: '39.16542',
    longitude: '-83.57118',
    timeZoneId: 'America/New_York',
  },
  '45135': {
    zip: '45135',
    city: 'Leesburg',
    state: 'OH',
    latitude: '39.334108',
    longitude: '-83.547824',
    timeZoneId: 'America/New_York',
  },
  '45140': {
    zip: '45140',
    city: 'Loveland',
    state: 'OH',
    latitude: '39.267042',
    longitude: '-84.292122',
    timeZoneId: 'America/New_York',
  },
  '45142': {
    zip: '45142',
    city: 'Lynchburg',
    state: 'OH',
    latitude: '39.204471',
    longitude: '-83.787626',
    timeZoneId: 'America/New_York',
  },
  '45144': {
    zip: '45144',
    city: 'Manchester',
    state: 'OH',
    latitude: '38.702',
    longitude: '-83.612593',
    timeZoneId: 'America/New_York',
  },
  '45146': {
    zip: '45146',
    city: 'Martinsville',
    state: 'OH',
    latitude: '39.316777',
    longitude: '-83.795637',
    timeZoneId: 'America/New_York',
  },
  '45147': {
    zip: '45147',
    city: 'Miamiville',
    state: 'OH',
    latitude: '39.21237',
    longitude: '-84.299993',
    timeZoneId: 'America/New_York',
  },
  '45148': {
    zip: '45148',
    city: 'Midland',
    state: 'OH',
    latitude: '39.295707',
    longitude: '-83.886912',
    timeZoneId: 'America/New_York',
  },
  '45150': {
    zip: '45150',
    city: 'Milford',
    state: 'OH',
    latitude: '39.178746',
    longitude: '-84.246032',
    timeZoneId: 'America/New_York',
  },
  '45152': {
    zip: '45152',
    city: 'Morrow',
    state: 'OH',
    latitude: '39.350658',
    longitude: '-84.092161',
    timeZoneId: 'America/New_York',
  },
  '45153': {
    zip: '45153',
    city: 'Moscow',
    state: 'OH',
    latitude: '38.85264',
    longitude: '-84.17946',
    timeZoneId: 'America/New_York',
  },
  '45154': {
    zip: '45154',
    city: 'Mount Orab',
    state: 'OH',
    latitude: '39.046508',
    longitude: '-83.92847',
    timeZoneId: 'America/New_York',
  },
  '45155': {
    zip: '45155',
    city: 'Mowrystown',
    state: 'OH',
    latitude: '39.039634',
    longitude: '-83.749616',
    timeZoneId: 'America/New_York',
  },
  '45156': {
    zip: '45156',
    city: 'Neville',
    state: 'OH',
    latitude: '38.809335',
    longitude: '-84.207927',
    timeZoneId: 'America/New_York',
  },
  '45157': {
    zip: '45157',
    city: 'New Richmond',
    state: 'OH',
    latitude: '38.95518',
    longitude: '-84.2196',
    timeZoneId: 'America/New_York',
  },
  '45158': {
    zip: '45158',
    city: 'Newtonsville',
    state: 'OH',
    latitude: '39.181677',
    longitude: '-84.087426',
    timeZoneId: 'America/New_York',
  },
  '45159': {
    zip: '45159',
    city: 'New Vienna',
    state: 'OH',
    latitude: '39.342854',
    longitude: '-83.695157',
    timeZoneId: 'America/New_York',
  },
  '45160': {
    zip: '45160',
    city: 'Owensville',
    state: 'OH',
    latitude: '39.124142',
    longitude: '-84.139513',
    timeZoneId: 'America/New_York',
  },
  '45162': {
    zip: '45162',
    city: 'Pleasant Plain',
    state: 'OH',
    latitude: '39.29664',
    longitude: '-84.08652',
    timeZoneId: 'America/New_York',
  },
  '45164': {
    zip: '45164',
    city: 'Port William',
    state: 'OH',
    latitude: '39.551661',
    longitude: '-83.786658',
    timeZoneId: 'America/New_York',
  },
  '45166': {
    zip: '45166',
    city: 'Reesville',
    state: 'OH',
    latitude: '39.48132',
    longitude: '-83.677691',
    timeZoneId: 'America/New_York',
  },
  '45167': {
    zip: '45167',
    city: 'Ripley',
    state: 'OH',
    latitude: '38.762237',
    longitude: '-83.812847',
    timeZoneId: 'America/New_York',
  },
  '45168': {
    zip: '45168',
    city: 'Russellville',
    state: 'OH',
    latitude: '38.860557',
    longitude: '-83.770773',
    timeZoneId: 'America/New_York',
  },
  '45169': {
    zip: '45169',
    city: 'Sabina',
    state: 'OH',
    latitude: '39.491952',
    longitude: '-83.654601',
    timeZoneId: 'America/New_York',
  },
  '45171': {
    zip: '45171',
    city: 'Sardinia',
    state: 'OH',
    latitude: '38.969796',
    longitude: '-83.793965',
    timeZoneId: 'America/New_York',
  },
  '45172': {
    zip: '45172',
    city: 'Sinking Spring',
    state: 'OH',
    latitude: '39.055787',
    longitude: '-83.352014',
    timeZoneId: 'America/New_York',
  },
  '45174': {
    zip: '45174',
    city: 'Terrace Park',
    state: 'OH',
    latitude: '39.159318',
    longitude: '-84.311489',
    timeZoneId: 'America/New_York',
  },
  '45176': {
    zip: '45176',
    city: 'Williamsburg',
    state: 'OH',
    latitude: '39.07206',
    longitude: '-84.043331',
    timeZoneId: 'America/New_York',
  },
  '45177': {
    zip: '45177',
    city: 'Wilmington',
    state: 'OH',
    latitude: '39.453237',
    longitude: '-83.843068',
    timeZoneId: 'America/New_York',
  },
  '45201': {
    zip: '45201',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.108786',
    longitude: '-84.501246',
    timeZoneId: 'America/New_York',
  },
  '45202': {
    zip: '45202',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.105479',
    longitude: '-84.502052',
    timeZoneId: 'America/New_York',
  },
  '45203': {
    zip: '45203',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.101841',
    longitude: '-84.529638',
    timeZoneId: 'America/New_York',
  },
  '45204': {
    zip: '45204',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.099986',
    longitude: '-84.5703',
    timeZoneId: 'America/New_York',
  },
  '45205': {
    zip: '45205',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.109367',
    longitude: '-84.574394',
    timeZoneId: 'America/New_York',
  },
  '45206': {
    zip: '45206',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.126559',
    longitude: '-84.483385',
    timeZoneId: 'America/New_York',
  },
  '45207': {
    zip: '45207',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.146978',
    longitude: '-84.470797',
    timeZoneId: 'America/New_York',
  },
  '45208': {
    zip: '45208',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.132484',
    longitude: '-84.435285',
    timeZoneId: 'America/New_York',
  },
  '45209': {
    zip: '45209',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.152971',
    longitude: '-84.427731',
    timeZoneId: 'America/New_York',
  },
  '45211': {
    zip: '45211',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.15846',
    longitude: '-84.5946',
    timeZoneId: 'America/New_York',
  },
  '45212': {
    zip: '45212',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.175321',
    longitude: '-84.440014',
    timeZoneId: 'America/New_York',
  },
  '45213': {
    zip: '45213',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.179149',
    longitude: '-84.417894',
    timeZoneId: 'America/New_York',
  },
  '45214': {
    zip: '45214',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.120751',
    longitude: '-84.543703',
    timeZoneId: 'America/New_York',
  },
  '45215': {
    zip: '45215',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.19122',
    longitude: '-84.46152',
    timeZoneId: 'America/New_York',
  },
  '45216': {
    zip: '45216',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.197614',
    longitude: '-84.478537',
    timeZoneId: 'America/New_York',
  },
  '45217': {
    zip: '45217',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.165637',
    longitude: '-84.495829',
    timeZoneId: 'America/New_York',
  },
  '45218': {
    zip: '45218',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.269057',
    longitude: '-84.521832',
    timeZoneId: 'America/New_York',
  },
  '45219': {
    zip: '45219',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.1261',
    longitude: '-84.517318',
    timeZoneId: 'America/New_York',
  },
  '45220': {
    zip: '45220',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.146959',
    longitude: '-84.51875',
    timeZoneId: 'America/New_York',
  },
  '45221': {
    zip: '45221',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.13032',
    longitude: '-84.516932',
    timeZoneId: 'America/New_York',
  },
  '45222': {
    zip: '45222',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.193649',
    longitude: '-84.44642',
    timeZoneId: 'America/New_York',
  },
  '45223': {
    zip: '45223',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.162801',
    longitude: '-84.548827',
    timeZoneId: 'America/New_York',
  },
  '45224': {
    zip: '45224',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.20125',
    longitude: '-84.531566',
    timeZoneId: 'America/New_York',
  },
  '45225': {
    zip: '45225',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.14182',
    longitude: '-84.552967',
    timeZoneId: 'America/New_York',
  },
  '45226': {
    zip: '45226',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.118178',
    longitude: '-84.426641',
    timeZoneId: 'America/New_York',
  },
  '45227': {
    zip: '45227',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.156',
    longitude: '-84.3714',
    timeZoneId: 'America/New_York',
  },
  '45229': {
    zip: '45229',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.149736',
    longitude: '-84.487866',
    timeZoneId: 'America/New_York',
  },
  '45230': {
    zip: '45230',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.078715',
    longitude: '-84.377216',
    timeZoneId: 'America/New_York',
  },
  '45231': {
    zip: '45231',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.244265',
    longitude: '-84.540628',
    timeZoneId: 'America/New_York',
  },
  '45232': {
    zip: '45232',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.176213',
    longitude: '-84.513602',
    timeZoneId: 'America/New_York',
  },
  '45233': {
    zip: '45233',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.119196',
    longitude: '-84.669399',
    timeZoneId: 'America/New_York',
  },
  '45234': {
    zip: '45234',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.273417',
    longitude: '-84.399795',
    timeZoneId: 'America/New_York',
  },
  '45235': {
    zip: '45235',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.274405',
    longitude: '-84.39891',
    timeZoneId: 'America/New_York',
  },
  '45236': {
    zip: '45236',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.204746',
    longitude: '-84.400718',
    timeZoneId: 'America/New_York',
  },
  '45237': {
    zip: '45237',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.190923',
    longitude: '-84.455964',
    timeZoneId: 'America/New_York',
  },
  '45238': {
    zip: '45238',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.110911',
    longitude: '-84.613884',
    timeZoneId: 'America/New_York',
  },
  '45239': {
    zip: '45239',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.221695',
    longitude: '-84.580861',
    timeZoneId: 'America/New_York',
  },
  '45240': {
    zip: '45240',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.285863',
    longitude: '-84.524542',
    timeZoneId: 'America/New_York',
  },
  '45241': {
    zip: '45241',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.267662',
    longitude: '-84.405863',
    timeZoneId: 'America/New_York',
  },
  '45242': {
    zip: '45242',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.245232',
    longitude: '-84.353209',
    timeZoneId: 'America/New_York',
  },
  '45243': {
    zip: '45243',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.185697',
    longitude: '-84.352352',
    timeZoneId: 'America/New_York',
  },
  '45244': {
    zip: '45244',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.11832',
    longitude: '-84.33768',
    timeZoneId: 'America/New_York',
  },
  '45245': {
    zip: '45245',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.069523',
    longitude: '-84.277672',
    timeZoneId: 'America/New_York',
  },
  '45246': {
    zip: '45246',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.279734',
    longitude: '-84.471102',
    timeZoneId: 'America/New_York',
  },
  '45247': {
    zip: '45247',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.228599',
    longitude: '-84.625748',
    timeZoneId: 'America/New_York',
  },
  '45248': {
    zip: '45248',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.159624',
    longitude: '-84.661853',
    timeZoneId: 'America/New_York',
  },
  '45249': {
    zip: '45249',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.29058',
    longitude: '-84.33469',
    timeZoneId: 'America/New_York',
  },
  '45250': {
    zip: '45250',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.118985',
    longitude: '-84.547916',
    timeZoneId: 'America/New_York',
  },
  '45251': {
    zip: '45251',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.25523',
    longitude: '-84.589734',
    timeZoneId: 'America/New_York',
  },
  '45252': {
    zip: '45252',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.272712',
    longitude: '-84.627394',
    timeZoneId: 'America/New_York',
  },
  '45253': {
    zip: '45253',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.269123',
    longitude: '-84.599293',
    timeZoneId: 'America/New_York',
  },
  '45254': {
    zip: '45254',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.105465',
    longitude: '-84.501838',
    timeZoneId: 'America/New_York',
  },
  '45255': {
    zip: '45255',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.051766',
    longitude: '-84.347377',
    timeZoneId: 'America/New_York',
  },
  '45258': {
    zip: '45258',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.140679',
    longitude: '-84.62751',
    timeZoneId: 'America/New_York',
  },
  '45262': {
    zip: '45262',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.122089',
    longitude: '-84.54413',
    timeZoneId: 'America/New_York',
  },
  '45263': {
    zip: '45263',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.10798',
    longitude: '-84.534184',
    timeZoneId: 'America/New_York',
  },
  '45264': {
    zip: '45264',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.10437',
    longitude: '-84.53787',
    timeZoneId: 'America/New_York',
  },
  '45267': {
    zip: '45267',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.127931',
    longitude: '-84.514462',
    timeZoneId: 'America/New_York',
  },
  '45268': {
    zip: '45268',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.148449',
    longitude: '-84.519895',
    timeZoneId: 'America/New_York',
  },
  '45269': {
    zip: '45269',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.106237',
    longitude: '-84.534866',
    timeZoneId: 'America/New_York',
  },
  '45270': {
    zip: '45270',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.100915',
    longitude: '-84.535879',
    timeZoneId: 'America/New_York',
  },
  '45271': {
    zip: '45271',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.136175',
    longitude: '-84.536864',
    timeZoneId: 'America/New_York',
  },
  '45273': {
    zip: '45273',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.101063',
    longitude: '-84.514066',
    timeZoneId: 'America/New_York',
  },
  '45274': {
    zip: '45274',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.138138',
    longitude: '-84.536318',
    timeZoneId: 'America/New_York',
  },
  '45275': {
    zip: '45275',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.138322',
    longitude: '-84.504469',
    timeZoneId: 'America/New_York',
  },
  '45277': {
    zip: '45277',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.02839',
    longitude: '-84.519831',
    timeZoneId: 'America/New_York',
  },
  '45280': {
    zip: '45280',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.161596',
    longitude: '-84.46049',
    timeZoneId: 'America/New_York',
  },
  '45296': {
    zip: '45296',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.103702',
    longitude: '-84.532826',
    timeZoneId: 'America/New_York',
  },
  '45298': {
    zip: '45298',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.087401',
    longitude: '-84.513464',
    timeZoneId: 'America/New_York',
  },
  '45299': {
    zip: '45299',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.10383',
    longitude: '-84.507547',
    timeZoneId: 'America/New_York',
  },
  '45301': {
    zip: '45301',
    city: 'Alpha',
    state: 'OH',
    latitude: '39.708108',
    longitude: '-84.020217',
    timeZoneId: 'America/New_York',
  },
  '45302': {
    zip: '45302',
    city: 'Anna',
    state: 'OH',
    latitude: '40.399636',
    longitude: '-84.202593',
    timeZoneId: 'America/New_York',
  },
  '45303': {
    zip: '45303',
    city: 'Ansonia',
    state: 'OH',
    latitude: '40.216786',
    longitude: '-84.641269',
    timeZoneId: 'America/New_York',
  },
  '45304': {
    zip: '45304',
    city: 'Arcanum',
    state: 'OH',
    latitude: '39.989474',
    longitude: '-84.534278',
    timeZoneId: 'America/New_York',
  },
  '45305': {
    zip: '45305',
    city: 'Bellbrook',
    state: 'OH',
    latitude: '39.636147',
    longitude: '-84.082389',
    timeZoneId: 'America/New_York',
  },
  '45306': {
    zip: '45306',
    city: 'Botkins',
    state: 'OH',
    latitude: '40.463867',
    longitude: '-84.182685',
    timeZoneId: 'America/New_York',
  },
  '45307': {
    zip: '45307',
    city: 'Bowersville',
    state: 'OH',
    latitude: '39.580195',
    longitude: '-83.724466',
    timeZoneId: 'America/New_York',
  },
  '45308': {
    zip: '45308',
    city: 'Bradford',
    state: 'OH',
    latitude: '40.130219',
    longitude: '-84.46929',
    timeZoneId: 'America/New_York',
  },
  '45309': {
    zip: '45309',
    city: 'Brookville',
    state: 'OH',
    latitude: '39.837478',
    longitude: '-84.420495',
    timeZoneId: 'America/New_York',
  },
  '45310': {
    zip: '45310',
    city: 'Burkettsville',
    state: 'OH',
    latitude: '40.352981',
    longitude: '-84.643596',
    timeZoneId: 'America/New_York',
  },
  '45311': {
    zip: '45311',
    city: 'Camden',
    state: 'OH',
    latitude: '39.633333',
    longitude: '-84.65',
    timeZoneId: 'America/New_York',
  },
  '45312': {
    zip: '45312',
    city: 'Casstown',
    state: 'OH',
    latitude: '40.059044',
    longitude: '-84.089349',
    timeZoneId: 'America/New_York',
  },
  '45314': {
    zip: '45314',
    city: 'Cedarville',
    state: 'OH',
    latitude: '39.747769',
    longitude: '-83.788032',
    timeZoneId: 'America/New_York',
  },
  '45315': {
    zip: '45315',
    city: 'Clayton',
    state: 'OH',
    latitude: '39.852017',
    longitude: '-84.33278',
    timeZoneId: 'America/New_York',
  },
  '45316': {
    zip: '45316',
    city: 'Clifton',
    state: 'OH',
    latitude: '39.799124',
    longitude: '-83.826339',
    timeZoneId: 'America/New_York',
  },
  '45317': {
    zip: '45317',
    city: 'Conover',
    state: 'OH',
    latitude: '40.188641',
    longitude: '-83.992318',
    timeZoneId: 'America/New_York',
  },
  '45318': {
    zip: '45318',
    city: 'Covington',
    state: 'OH',
    latitude: '40.127549',
    longitude: '-84.348416',
    timeZoneId: 'America/New_York',
  },
  '45319': {
    zip: '45319',
    city: 'Donnelsville',
    state: 'OH',
    latitude: '39.916803',
    longitude: '-83.94924',
    timeZoneId: 'America/New_York',
  },
  '45320': {
    zip: '45320',
    city: 'Eaton',
    state: 'OH',
    latitude: '39.733814',
    longitude: '-84.671102',
    timeZoneId: 'America/New_York',
  },
  '45321': {
    zip: '45321',
    city: 'Eldorado',
    state: 'OH',
    latitude: '39.892254',
    longitude: '-84.673553',
    timeZoneId: 'America/New_York',
  },
  '45322': {
    zip: '45322',
    city: 'Englewood',
    state: 'OH',
    latitude: '39.88404',
    longitude: '-84.3468',
    timeZoneId: 'America/New_York',
  },
  '45323': {
    zip: '45323',
    city: 'Enon',
    state: 'OH',
    latitude: '39.866384',
    longitude: '-83.934445',
    timeZoneId: 'America/New_York',
  },
  '45324': {
    zip: '45324',
    city: 'Fairborn',
    state: 'OH',
    latitude: '39.807279',
    longitude: '-84.019499',
    timeZoneId: 'America/New_York',
  },
  '45325': {
    zip: '45325',
    city: 'Farmersville',
    state: 'OH',
    latitude: '39.69834',
    longitude: '-84.4179',
    timeZoneId: 'America/New_York',
  },
  '45326': {
    zip: '45326',
    city: 'Fletcher',
    state: 'OH',
    latitude: '40.1399',
    longitude: '-84.102649',
    timeZoneId: 'America/New_York',
  },
  '45327': {
    zip: '45327',
    city: 'Germantown',
    state: 'OH',
    latitude: '39.631562',
    longitude: '-84.383463',
    timeZoneId: 'America/New_York',
  },
  '45328': {
    zip: '45328',
    city: 'Gettysburg',
    state: 'OH',
    latitude: '40.115328',
    longitude: '-84.494432',
    timeZoneId: 'America/New_York',
  },
  '45330': {
    zip: '45330',
    city: 'Gratis',
    state: 'OH',
    latitude: '39.648938',
    longitude: '-84.52708',
    timeZoneId: 'America/New_York',
  },
  '45331': {
    zip: '45331',
    city: 'Greenville',
    state: 'OH',
    latitude: '40.092949',
    longitude: '-84.632927',
    timeZoneId: 'America/New_York',
  },
  '45332': {
    zip: '45332',
    city: 'Hollansburg',
    state: 'OH',
    latitude: '39.998065',
    longitude: '-84.788708',
    timeZoneId: 'America/New_York',
  },
  '45333': {
    zip: '45333',
    city: 'Houston',
    state: 'OH',
    latitude: '40.25832',
    longitude: '-84.346537',
    timeZoneId: 'America/New_York',
  },
  '45334': {
    zip: '45334',
    city: 'Jackson Center',
    state: 'OH',
    latitude: '40.446262',
    longitude: '-84.043697',
    timeZoneId: 'America/New_York',
  },
  '45335': {
    zip: '45335',
    city: 'Jamestown',
    state: 'OH',
    latitude: '39.639044',
    longitude: '-83.749398',
    timeZoneId: 'America/New_York',
  },
  '45336': {
    zip: '45336',
    city: 'Kettlersville',
    state: 'OH',
    latitude: '40.441376',
    longitude: '-84.261648',
    timeZoneId: 'America/New_York',
  },
  '45337': {
    zip: '45337',
    city: 'Laura',
    state: 'OH',
    latitude: '39.996855',
    longitude: '-84.410254',
    timeZoneId: 'America/New_York',
  },
  '45338': {
    zip: '45338',
    city: 'Lewisburg',
    state: 'OH',
    latitude: '39.845072',
    longitude: '-84.542576',
    timeZoneId: 'America/New_York',
  },
  '45339': {
    zip: '45339',
    city: 'Ludlow Falls',
    state: 'OH',
    latitude: '40.002325',
    longitude: '-84.346362',
    timeZoneId: 'America/New_York',
  },
  '45340': {
    zip: '45340',
    city: 'Maplewood',
    state: 'OH',
    latitude: '40.373328',
    longitude: '-84.045548',
    timeZoneId: 'America/New_York',
  },
  '45341': {
    zip: '45341',
    city: 'Medway',
    state: 'OH',
    latitude: '39.88047',
    longitude: '-84.027072',
    timeZoneId: 'America/New_York',
  },
  '45342': {
    zip: '45342',
    city: 'Miamisburg',
    state: 'OH',
    latitude: '39.629485',
    longitude: '-84.263441',
    timeZoneId: 'America/New_York',
  },
  '45343': {
    zip: '45343',
    city: 'Miamisburg',
    state: 'OH',
    latitude: '39.636393',
    longitude: '-84.267253',
    timeZoneId: 'America/New_York',
  },
  '45344': {
    zip: '45344',
    city: 'New Carlisle',
    state: 'OH',
    latitude: '39.939675',
    longitude: '-84.014989',
    timeZoneId: 'America/New_York',
  },
  '45345': {
    zip: '45345',
    city: 'New Lebanon',
    state: 'OH',
    latitude: '39.736169',
    longitude: '-84.390776',
    timeZoneId: 'America/New_York',
  },
  '45346': {
    zip: '45346',
    city: 'New Madison',
    state: 'OH',
    latitude: '39.982307',
    longitude: '-84.713975',
    timeZoneId: 'America/New_York',
  },
  '45347': {
    zip: '45347',
    city: 'New Paris',
    state: 'OH',
    latitude: '39.870254',
    longitude: '-84.772952',
    timeZoneId: 'America/New_York',
  },
  '45348': {
    zip: '45348',
    city: 'New Weston',
    state: 'OH',
    latitude: '40.32168',
    longitude: '-84.62712',
    timeZoneId: 'America/New_York',
  },
  '45349': {
    zip: '45349',
    city: 'North Hampton',
    state: 'OH',
    latitude: '39.989041',
    longitude: '-83.939918',
    timeZoneId: 'America/New_York',
  },
  '45350': {
    zip: '45350',
    city: 'North Star',
    state: 'OH',
    latitude: '40.326656',
    longitude: '-84.567991',
    timeZoneId: 'America/New_York',
  },
  '45351': {
    zip: '45351',
    city: 'Osgood',
    state: 'OH',
    latitude: '40.340439',
    longitude: '-84.495845',
    timeZoneId: 'America/New_York',
  },
  '45352': {
    zip: '45352',
    city: 'Palestine',
    state: 'OH',
    latitude: '40.049672',
    longitude: '-84.744526',
    timeZoneId: 'America/New_York',
  },
  '45353': {
    zip: '45353',
    city: 'Pemberton',
    state: 'OH',
    latitude: '40.291694',
    longitude: '-84.032709',
    timeZoneId: 'America/New_York',
  },
  '45354': {
    zip: '45354',
    city: 'Phillipsburg',
    state: 'OH',
    latitude: '39.900931',
    longitude: '-84.400434',
    timeZoneId: 'America/New_York',
  },
  '45356': {
    zip: '45356',
    city: 'Piqua',
    state: 'OH',
    latitude: '40.148347',
    longitude: '-84.241182',
    timeZoneId: 'America/New_York',
  },
  '45358': {
    zip: '45358',
    city: 'Pitsburg',
    state: 'OH',
    latitude: '39.986707',
    longitude: '-84.487723',
    timeZoneId: 'America/New_York',
  },
  '45359': {
    zip: '45359',
    city: 'Pleasant Hill',
    state: 'OH',
    latitude: '40.054057',
    longitude: '-84.35377',
    timeZoneId: 'America/New_York',
  },
  '45360': {
    zip: '45360',
    city: 'Port Jefferson',
    state: 'OH',
    latitude: '40.332192',
    longitude: '-84.090568',
    timeZoneId: 'America/New_York',
  },
  '45361': {
    zip: '45361',
    city: 'Potsdam',
    state: 'OH',
    latitude: '39.961989',
    longitude: '-84.414577',
    timeZoneId: 'America/New_York',
  },
  '45362': {
    zip: '45362',
    city: 'Rossburg',
    state: 'OH',
    latitude: '40.287418',
    longitude: '-84.630866',
    timeZoneId: 'America/New_York',
  },
  '45363': {
    zip: '45363',
    city: 'Russia',
    state: 'OH',
    latitude: '40.240468',
    longitude: '-84.403292',
    timeZoneId: 'America/New_York',
  },
  '45365': {
    zip: '45365',
    city: 'Sidney',
    state: 'OH',
    latitude: '40.286524',
    longitude: '-84.155059',
    timeZoneId: 'America/New_York',
  },
  '45367': {
    zip: '45367',
    city: 'Sidney',
    state: 'OH',
    latitude: '40.289153',
    longitude: '-84.163256',
    timeZoneId: 'America/New_York',
  },
  '45368': {
    zip: '45368',
    city: 'South Charleston',
    state: 'OH',
    latitude: '39.847694',
    longitude: '-83.658868',
    timeZoneId: 'America/New_York',
  },
  '45369': {
    zip: '45369',
    city: 'South Vienna',
    state: 'OH',
    latitude: '39.956268',
    longitude: '-83.612904',
    timeZoneId: 'America/New_York',
  },
  '45370': {
    zip: '45370',
    city: 'Spring Valley',
    state: 'OH',
    latitude: '39.599468',
    longitude: '-84.138005',
    timeZoneId: 'America/New_York',
  },
  '45371': {
    zip: '45371',
    city: 'Tipp City',
    state: 'OH',
    latitude: '39.955039',
    longitude: '-84.178566',
    timeZoneId: 'America/New_York',
  },
  '45372': {
    zip: '45372',
    city: 'Tremont City',
    state: 'OH',
    latitude: '40.01274',
    longitude: '-83.8362',
    timeZoneId: 'America/New_York',
  },
  '45373': {
    zip: '45373',
    city: 'Troy',
    state: 'OH',
    latitude: '40.03502',
    longitude: '-84.20612',
    timeZoneId: 'America/New_York',
  },
  '45374': {
    zip: '45374',
    city: 'Troy',
    state: 'OH',
    latitude: '40.044093',
    longitude: '-84.217683',
    timeZoneId: 'America/New_York',
  },
  '45377': {
    zip: '45377',
    city: 'Vandalia',
    state: 'OH',
    latitude: '39.889016',
    longitude: '-84.205982',
    timeZoneId: 'America/New_York',
  },
  '45378': {
    zip: '45378',
    city: 'Verona',
    state: 'OH',
    latitude: '39.900394',
    longitude: '-84.489382',
    timeZoneId: 'America/New_York',
  },
  '45380': {
    zip: '45380',
    city: 'Versailles',
    state: 'OH',
    latitude: '40.238785',
    longitude: '-84.511676',
    timeZoneId: 'America/New_York',
  },
  '45381': {
    zip: '45381',
    city: 'West Alexandria',
    state: 'OH',
    latitude: '39.729736',
    longitude: '-84.535455',
    timeZoneId: 'America/New_York',
  },
  '45382': {
    zip: '45382',
    city: 'West Manchester',
    state: 'OH',
    latitude: '39.898244',
    longitude: '-84.621194',
    timeZoneId: 'America/New_York',
  },
  '45383': {
    zip: '45383',
    city: 'West Milton',
    state: 'OH',
    latitude: '39.955496',
    longitude: '-84.328876',
    timeZoneId: 'America/New_York',
  },
  '45384': {
    zip: '45384',
    city: 'Wilberforce',
    state: 'OH',
    latitude: '39.709101',
    longitude: '-83.883356',
    timeZoneId: 'America/New_York',
  },
  '45385': {
    zip: '45385',
    city: 'Xenia',
    state: 'OH',
    latitude: '39.691445',
    longitude: '-83.953106',
    timeZoneId: 'America/New_York',
  },
  '45387': {
    zip: '45387',
    city: 'Yellow Springs',
    state: 'OH',
    latitude: '39.798844',
    longitude: '-83.897655',
    timeZoneId: 'America/New_York',
  },
  '45388': {
    zip: '45388',
    city: 'Yorkshire',
    state: 'OH',
    latitude: '40.325904',
    longitude: '-84.482044',
    timeZoneId: 'America/New_York',
  },
  '45389': {
    zip: '45389',
    city: 'Christiansburg',
    state: 'OH',
    latitude: '40.054168',
    longitude: '-84.024219',
    timeZoneId: 'America/New_York',
  },
  '45390': {
    zip: '45390',
    city: 'Union City',
    state: 'OH',
    latitude: '40.0185',
    longitude: '-84.56424',
    timeZoneId: 'America/New_York',
  },
  '45401': {
    zip: '45401',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.77463',
    longitude: '-84.198398',
    timeZoneId: 'America/New_York',
  },
  '45402': {
    zip: '45402',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.760218',
    longitude: '-84.18684',
    timeZoneId: 'America/New_York',
  },
  '45403': {
    zip: '45403',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.763897',
    longitude: '-84.154772',
    timeZoneId: 'America/New_York',
  },
  '45404': {
    zip: '45404',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.785814',
    longitude: '-84.160985',
    timeZoneId: 'America/New_York',
  },
  '45405': {
    zip: '45405',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.787602',
    longitude: '-84.210619',
    timeZoneId: 'America/New_York',
  },
  '45406': {
    zip: '45406',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.79421',
    longitude: '-84.27011',
    timeZoneId: 'America/New_York',
  },
  '45409': {
    zip: '45409',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.724144',
    longitude: '-84.183354',
    timeZoneId: 'America/New_York',
  },
  '45410': {
    zip: '45410',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.746481',
    longitude: '-84.160751',
    timeZoneId: 'America/New_York',
  },
  '45412': {
    zip: '45412',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.760813',
    longitude: '-84.193399',
    timeZoneId: 'America/New_York',
  },
  '45413': {
    zip: '45413',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.824011',
    longitude: '-84.189799',
    timeZoneId: 'America/New_York',
  },
  '45414': {
    zip: '45414',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.82779',
    longitude: '-84.203696',
    timeZoneId: 'America/New_York',
  },
  '45415': {
    zip: '45415',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.82445',
    longitude: '-84.27613',
    timeZoneId: 'America/New_York',
  },
  '45416': {
    zip: '45416',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.800645',
    longitude: '-84.257639',
    timeZoneId: 'America/New_York',
  },
  '45417': {
    zip: '45417',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.75581',
    longitude: '-84.240573',
    timeZoneId: 'America/New_York',
  },
  '45419': {
    zip: '45419',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.714561',
    longitude: '-84.165115',
    timeZoneId: 'America/New_York',
  },
  '45420': {
    zip: '45420',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.704905',
    longitude: '-84.126463',
    timeZoneId: 'America/New_York',
  },
  '45422': {
    zip: '45422',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.760595',
    longitude: '-84.186625',
    timeZoneId: 'America/New_York',
  },
  '45423': {
    zip: '45423',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.757346',
    longitude: '-84.187384',
    timeZoneId: 'America/New_York',
  },
  '45424': {
    zip: '45424',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.84564',
    longitude: '-84.11172',
    timeZoneId: 'America/New_York',
  },
  '45426': {
    zip: '45426',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.804271',
    longitude: '-84.300328',
    timeZoneId: 'America/New_York',
  },
  '45428': {
    zip: '45428',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.751559',
    longitude: '-84.250474',
    timeZoneId: 'America/New_York',
  },
  '45429': {
    zip: '45429',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.686892',
    longitude: '-84.159357',
    timeZoneId: 'America/New_York',
  },
  '45430': {
    zip: '45430',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.706775',
    longitude: '-84.084303',
    timeZoneId: 'America/New_York',
  },
  '45431': {
    zip: '45431',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.765802',
    longitude: '-84.12094',
    timeZoneId: 'America/New_York',
  },
  '45432': {
    zip: '45432',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.741263',
    longitude: '-84.068879',
    timeZoneId: 'America/New_York',
  },
  '45433': {
    zip: '45433',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.791449',
    longitude: '-84.068804',
    timeZoneId: 'America/New_York',
  },
  '45434': {
    zip: '45434',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.712421',
    longitude: '-84.041488',
    timeZoneId: 'America/New_York',
  },
  '45435': {
    zip: '45435',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.766782',
    longitude: '-84.085913',
    timeZoneId: 'America/New_York',
  },
  '45437': {
    zip: '45437',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.767084',
    longitude: '-84.125122',
    timeZoneId: 'America/New_York',
  },
  '45439': {
    zip: '45439',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.699058',
    longitude: '-84.219886',
    timeZoneId: 'America/New_York',
  },
  '45440': {
    zip: '45440',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.670431',
    longitude: '-84.122239',
    timeZoneId: 'America/New_York',
  },
  '45441': {
    zip: '45441',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.644959',
    longitude: '-84.167261',
    timeZoneId: 'America/New_York',
  },
  '45448': {
    zip: '45448',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.647561',
    longitude: '-84.169843',
    timeZoneId: 'America/New_York',
  },
  '45449': {
    zip: '45449',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.668355',
    longitude: '-84.246864',
    timeZoneId: 'America/New_York',
  },
  '45458': {
    zip: '45458',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.612491',
    longitude: '-84.161498',
    timeZoneId: 'America/New_York',
  },
  '45459': {
    zip: '45459',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.638203',
    longitude: '-84.16746',
    timeZoneId: 'America/New_York',
  },
  '45469': {
    zip: '45469',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.721166',
    longitude: '-84.190652',
    timeZoneId: 'America/New_York',
  },
  '45470': {
    zip: '45470',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.629744',
    longitude: '-84.272496',
    timeZoneId: 'America/New_York',
  },
  '45475': {
    zip: '45475',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.643805',
    longitude: '-84.166326',
    timeZoneId: 'America/New_York',
  },
  '45479': {
    zip: '45479',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.723956',
    longitude: '-84.192405',
    timeZoneId: 'America/New_York',
  },
  '45481': {
    zip: '45481',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.75761',
    longitude: '-84.178798',
    timeZoneId: 'America/New_York',
  },
  '45482': {
    zip: '45482',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.721533',
    longitude: '-84.101071',
    timeZoneId: 'America/New_York',
  },
  '45490': {
    zip: '45490',
    city: 'Dayton',
    state: 'OH',
    latitude: '39.893349',
    longitude: '-84.219043',
    timeZoneId: 'America/New_York',
  },
  '45501': {
    zip: '45501',
    city: 'Springfield',
    state: 'OH',
    latitude: '39.925818',
    longitude: '-83.854004',
    timeZoneId: 'America/New_York',
  },
  '45502': {
    zip: '45502',
    city: 'Springfield',
    state: 'OH',
    latitude: '39.925374',
    longitude: '-83.824327',
    timeZoneId: 'America/New_York',
  },
  '45503': {
    zip: '45503',
    city: 'Springfield',
    state: 'OH',
    latitude: '39.952811',
    longitude: '-83.780216',
    timeZoneId: 'America/New_York',
  },
  '45504': {
    zip: '45504',
    city: 'Springfield',
    state: 'OH',
    latitude: '39.940286',
    longitude: '-83.843773',
    timeZoneId: 'America/New_York',
  },
  '45505': {
    zip: '45505',
    city: 'Springfield',
    state: 'OH',
    latitude: '39.908372',
    longitude: '-83.780134',
    timeZoneId: 'America/New_York',
  },
  '45506': {
    zip: '45506',
    city: 'Springfield',
    state: 'OH',
    latitude: '39.911242',
    longitude: '-83.839281',
    timeZoneId: 'America/New_York',
  },
  '45601': {
    zip: '45601',
    city: 'Chillicothe',
    state: 'OH',
    latitude: '39.328095',
    longitude: '-82.982598',
    timeZoneId: 'America/New_York',
  },
  '45612': {
    zip: '45612',
    city: 'Bainbridge',
    state: 'OH',
    latitude: '39.24037',
    longitude: '-83.27019',
    timeZoneId: 'America/New_York',
  },
  '45613': {
    zip: '45613',
    city: 'Beaver',
    state: 'OH',
    latitude: '39.028231',
    longitude: '-82.860447',
    timeZoneId: 'America/New_York',
  },
  '45614': {
    zip: '45614',
    city: 'Bidwell',
    state: 'OH',
    latitude: '38.928707',
    longitude: '-82.254397',
    timeZoneId: 'America/New_York',
  },
  '45616': {
    zip: '45616',
    city: 'Blue Creek',
    state: 'OH',
    latitude: '38.793184',
    longitude: '-83.340218',
    timeZoneId: 'America/New_York',
  },
  '45617': {
    zip: '45617',
    city: 'Bourneville',
    state: 'OH',
    latitude: '39.282733',
    longitude: '-83.161784',
    timeZoneId: 'America/New_York',
  },
  '45618': {
    zip: '45618',
    city: 'Cherry Fork',
    state: 'OH',
    latitude: '38.853058',
    longitude: '-83.585381',
    timeZoneId: 'America/New_York',
  },
  '45619': {
    zip: '45619',
    city: 'Chesapeake',
    state: 'OH',
    latitude: '38.430677',
    longitude: '-82.454715',
    timeZoneId: 'America/New_York',
  },
  '45620': {
    zip: '45620',
    city: 'Cheshire',
    state: 'OH',
    latitude: '38.945159',
    longitude: '-82.112882',
    timeZoneId: 'America/New_York',
  },
  '45621': {
    zip: '45621',
    city: 'Coalton',
    state: 'OH',
    latitude: '39.118612',
    longitude: '-82.531374',
    timeZoneId: 'America/New_York',
  },
  '45622': {
    zip: '45622',
    city: 'Creola',
    state: 'OH',
    latitude: '39.383811',
    longitude: '-82.529439',
    timeZoneId: 'America/New_York',
  },
  '45623': {
    zip: '45623',
    city: 'Crown City',
    state: 'OH',
    latitude: '38.616537',
    longitude: '-82.261858',
    timeZoneId: 'America/New_York',
  },
  '45624': {
    zip: '45624',
    city: 'Cynthiana',
    state: 'OH',
    latitude: '39.158935',
    longitude: '-83.355739',
    timeZoneId: 'America/New_York',
  },
  '45628': {
    zip: '45628',
    city: 'Frankfort',
    state: 'OH',
    latitude: '39.387932',
    longitude: '-83.194166',
    timeZoneId: 'America/New_York',
  },
  '45629': {
    zip: '45629',
    city: 'Franklin Furnace',
    state: 'OH',
    latitude: '38.657779',
    longitude: '-82.807843',
    timeZoneId: 'America/New_York',
  },
  '45630': {
    zip: '45630',
    city: 'Friendship',
    state: 'OH',
    latitude: '38.698862',
    longitude: '-83.09203',
    timeZoneId: 'America/New_York',
  },
  '45631': {
    zip: '45631',
    city: 'Gallipolis',
    state: 'OH',
    latitude: '38.821555',
    longitude: '-82.196836',
    timeZoneId: 'America/New_York',
  },
  '45633': {
    zip: '45633',
    city: 'Hallsville',
    state: 'OH',
    latitude: '39.455574',
    longitude: '-82.788111',
    timeZoneId: 'America/New_York',
  },
  '45634': {
    zip: '45634',
    city: 'Hamden',
    state: 'OH',
    latitude: '39.16087',
    longitude: '-82.524305',
    timeZoneId: 'America/New_York',
  },
  '45636': {
    zip: '45636',
    city: 'Haverhill',
    state: 'OH',
    latitude: '38.584164',
    longitude: '-82.830812',
    timeZoneId: 'America/New_York',
  },
  '45638': {
    zip: '45638',
    city: 'Ironton',
    state: 'OH',
    latitude: '38.524095',
    longitude: '-82.664084',
    timeZoneId: 'America/New_York',
  },
  '45640': {
    zip: '45640',
    city: 'Jackson',
    state: 'OH',
    latitude: '39.032746',
    longitude: '-82.649218',
    timeZoneId: 'America/New_York',
  },
  '45642': {
    zip: '45642',
    city: 'Jasper',
    state: 'OH',
    latitude: '39.047459',
    longitude: '-83.049854',
    timeZoneId: 'America/New_York',
  },
  '45643': {
    zip: '45643',
    city: 'Kerr',
    state: 'OH',
    latitude: '38.871119',
    longitude: '-82.26283',
    timeZoneId: 'America/New_York',
  },
  '45644': {
    zip: '45644',
    city: 'Kingston',
    state: 'OH',
    latitude: '39.448325',
    longitude: '-82.875803',
    timeZoneId: 'America/New_York',
  },
  '45645': {
    zip: '45645',
    city: 'Kitts Hill',
    state: 'OH',
    latitude: '38.576404',
    longitude: '-82.529368',
    timeZoneId: 'America/New_York',
  },
  '45646': {
    zip: '45646',
    city: 'Latham',
    state: 'OH',
    latitude: '39.090705',
    longitude: '-83.292192',
    timeZoneId: 'America/New_York',
  },
  '45647': {
    zip: '45647',
    city: 'Londonderry',
    state: 'OH',
    latitude: '39.278034',
    longitude: '-82.798955',
    timeZoneId: 'America/New_York',
  },
  '45648': {
    zip: '45648',
    city: 'Lucasville',
    state: 'OH',
    latitude: '38.91474',
    longitude: '-83.0328',
    timeZoneId: 'America/New_York',
  },
  '45650': {
    zip: '45650',
    city: 'Lynx',
    state: 'OH',
    latitude: '38.744486',
    longitude: '-83.422398',
    timeZoneId: 'America/New_York',
  },
  '45651': {
    zip: '45651',
    city: 'Mc Arthur',
    state: 'OH',
    latitude: '39.249907',
    longitude: '-82.478264',
    timeZoneId: 'America/New_York',
  },
  '45652': {
    zip: '45652',
    city: 'Mc Dermott',
    state: 'OH',
    latitude: '38.8428',
    longitude: '-83.0814',
    timeZoneId: 'America/New_York',
  },
  '45653': {
    zip: '45653',
    city: 'Minford',
    state: 'OH',
    latitude: '38.88885',
    longitude: '-82.831125',
    timeZoneId: 'America/New_York',
  },
  '45654': {
    zip: '45654',
    city: 'New Plymouth',
    state: 'OH',
    latitude: '39.40993',
    longitude: '-82.358584',
    timeZoneId: 'America/New_York',
  },
  '45656': {
    zip: '45656',
    city: 'Oak Hill',
    state: 'OH',
    latitude: '38.88678',
    longitude: '-82.59222',
    timeZoneId: 'America/New_York',
  },
  '45657': {
    zip: '45657',
    city: 'Otway',
    state: 'OH',
    latitude: '38.820494',
    longitude: '-83.28686',
    timeZoneId: 'America/New_York',
  },
  '45658': {
    zip: '45658',
    city: 'Patriot',
    state: 'OH',
    latitude: '38.775857',
    longitude: '-82.438825',
    timeZoneId: 'America/New_York',
  },
  '45659': {
    zip: '45659',
    city: 'Pedro',
    state: 'OH',
    latitude: '38.65608',
    longitude: '-82.63962',
    timeZoneId: 'America/New_York',
  },
  '45660': {
    zip: '45660',
    city: 'Peebles',
    state: 'OH',
    latitude: '38.957037',
    longitude: '-83.402685',
    timeZoneId: 'America/New_York',
  },
  '45661': {
    zip: '45661',
    city: 'Piketon',
    state: 'OH',
    latitude: '39.03786',
    longitude: '-83.1012',
    timeZoneId: 'America/New_York',
  },
  '45662': {
    zip: '45662',
    city: 'Portsmouth',
    state: 'OH',
    latitude: '38.78352',
    longitude: '-82.9413',
    timeZoneId: 'America/New_York',
  },
  '45663': {
    zip: '45663',
    city: 'West Portsmouth',
    state: 'OH',
    latitude: '38.74926',
    longitude: '-82.98786',
    timeZoneId: 'America/New_York',
  },
  '45669': {
    zip: '45669',
    city: 'Proctorville',
    state: 'OH',
    latitude: '38.43522',
    longitude: '-82.381444',
    timeZoneId: 'America/New_York',
  },
  '45671': {
    zip: '45671',
    city: 'Rarden',
    state: 'OH',
    latitude: '38.980022',
    longitude: '-83.276192',
    timeZoneId: 'America/New_York',
  },
  '45672': {
    zip: '45672',
    city: 'Ray',
    state: 'OH',
    latitude: '39.175482',
    longitude: '-82.709789',
    timeZoneId: 'America/New_York',
  },
  '45673': {
    zip: '45673',
    city: 'Richmond Dale',
    state: 'OH',
    latitude: '39.203081',
    longitude: '-82.816583',
    timeZoneId: 'America/New_York',
  },
  '45674': {
    zip: '45674',
    city: 'Rio Grande',
    state: 'OH',
    latitude: '38.882583',
    longitude: '-82.380832',
    timeZoneId: 'America/New_York',
  },
  '45675': {
    zip: '45675',
    city: 'Rock Camp',
    state: 'OH',
    latitude: '38.521864',
    longitude: '-82.56326',
    timeZoneId: 'America/New_York',
  },
  '45677': {
    zip: '45677',
    city: 'Scioto Furnace',
    state: 'OH',
    latitude: '38.797453',
    longitude: '-82.762942',
    timeZoneId: 'America/New_York',
  },
  '45678': {
    zip: '45678',
    city: 'Scottown',
    state: 'OH',
    latitude: '38.612469',
    longitude: '-82.397919',
    timeZoneId: 'America/New_York',
  },
  '45679': {
    zip: '45679',
    city: 'Seaman',
    state: 'OH',
    latitude: '38.963935',
    longitude: '-83.563138',
    timeZoneId: 'America/New_York',
  },
  '45680': {
    zip: '45680',
    city: 'South Point',
    state: 'OH',
    latitude: '38.42004',
    longitude: '-82.5729',
    timeZoneId: 'America/New_York',
  },
  '45681': {
    zip: '45681',
    city: 'South Salem',
    state: 'OH',
    latitude: '39.308066',
    longitude: '-83.258473',
    timeZoneId: 'America/New_York',
  },
  '45682': {
    zip: '45682',
    city: 'South Webster',
    state: 'OH',
    latitude: '38.863742',
    longitude: '-82.750533',
    timeZoneId: 'America/New_York',
  },
  '45683': {
    zip: '45683',
    city: 'Stockdale',
    state: 'OH',
    latitude: '38.952953',
    longitude: '-82.862523',
    timeZoneId: 'America/New_York',
  },
  '45684': {
    zip: '45684',
    city: 'Stout',
    state: 'OH',
    latitude: '38.706026',
    longitude: '-83.358873',
    timeZoneId: 'America/New_York',
  },
  '45685': {
    zip: '45685',
    city: 'Thurman',
    state: 'OH',
    latitude: '38.956699',
    longitude: '-82.474011',
    timeZoneId: 'America/New_York',
  },
  '45686': {
    zip: '45686',
    city: 'Vinton',
    state: 'OH',
    latitude: '39.0039',
    longitude: '-82.36914',
    timeZoneId: 'America/New_York',
  },
  '45687': {
    zip: '45687',
    city: 'Wakefield',
    state: 'OH',
    latitude: '38.96828',
    longitude: '-83.021553',
    timeZoneId: 'America/New_York',
  },
  '45688': {
    zip: '45688',
    city: 'Waterloo',
    state: 'OH',
    latitude: '38.686389',
    longitude: '-82.457135',
    timeZoneId: 'America/New_York',
  },
  '45690': {
    zip: '45690',
    city: 'Waverly',
    state: 'OH',
    latitude: '39.124116',
    longitude: '-83.008351',
    timeZoneId: 'America/New_York',
  },
  '45692': {
    zip: '45692',
    city: 'Wellston',
    state: 'OH',
    latitude: '39.12244',
    longitude: '-82.546002',
    timeZoneId: 'America/New_York',
  },
  '45693': {
    zip: '45693',
    city: 'West Union',
    state: 'OH',
    latitude: '38.797727',
    longitude: '-83.527461',
    timeZoneId: 'America/New_York',
  },
  '45694': {
    zip: '45694',
    city: 'Wheelersburg',
    state: 'OH',
    latitude: '38.736369',
    longitude: '-82.839582',
    timeZoneId: 'America/New_York',
  },
  '45695': {
    zip: '45695',
    city: 'Wilkesville',
    state: 'OH',
    latitude: '39.140833',
    longitude: '-82.376944',
    timeZoneId: 'America/New_York',
  },
  '45696': {
    zip: '45696',
    city: 'Willow Wood',
    state: 'OH',
    latitude: '38.596101',
    longitude: '-82.450776',
    timeZoneId: 'America/New_York',
  },
  '45697': {
    zip: '45697',
    city: 'Winchester',
    state: 'OH',
    latitude: '38.907484',
    longitude: '-83.636488',
    timeZoneId: 'America/New_York',
  },
  '45698': {
    zip: '45698',
    city: 'Zaleski',
    state: 'OH',
    latitude: '39.282937',
    longitude: '-82.39324',
    timeZoneId: 'America/New_York',
  },
  '45699': {
    zip: '45699',
    city: 'Lucasville',
    state: 'OH',
    latitude: '38.881627',
    longitude: '-82.992138',
    timeZoneId: 'America/New_York',
  },
  '45701': {
    zip: '45701',
    city: 'Athens',
    state: 'OH',
    latitude: '39.31371',
    longitude: '-82.096417',
    timeZoneId: 'America/New_York',
  },
  '45710': {
    zip: '45710',
    city: 'Albany',
    state: 'OH',
    latitude: '39.21084',
    longitude: '-82.19268',
    timeZoneId: 'America/New_York',
  },
  '45711': {
    zip: '45711',
    city: 'Amesville',
    state: 'OH',
    latitude: '39.415295',
    longitude: '-81.940441',
    timeZoneId: 'America/New_York',
  },
  '45712': {
    zip: '45712',
    city: 'Barlow',
    state: 'OH',
    latitude: '39.394944',
    longitude: '-81.66776',
    timeZoneId: 'America/New_York',
  },
  '45713': {
    zip: '45713',
    city: 'Bartlett',
    state: 'OH',
    latitude: '39.421391',
    longitude: '-81.815945',
    timeZoneId: 'America/New_York',
  },
  '45714': {
    zip: '45714',
    city: 'Belpre',
    state: 'OH',
    latitude: '39.2886',
    longitude: '-81.59568',
    timeZoneId: 'America/New_York',
  },
  '45715': {
    zip: '45715',
    city: 'Beverly',
    state: 'OH',
    latitude: '39.57024',
    longitude: '-81.63552',
    timeZoneId: 'America/New_York',
  },
  '45716': {
    zip: '45716',
    city: 'Buchtel',
    state: 'OH',
    latitude: '39.46233',
    longitude: '-82.185638',
    timeZoneId: 'America/New_York',
  },
  '45717': {
    zip: '45717',
    city: 'Carbondale',
    state: 'OH',
    latitude: '39.37932',
    longitude: '-82.270986',
    timeZoneId: 'America/New_York',
  },
  '45719': {
    zip: '45719',
    city: 'Chauncey',
    state: 'OH',
    latitude: '39.401522',
    longitude: '-82.129448',
    timeZoneId: 'America/New_York',
  },
  '45720': {
    zip: '45720',
    city: 'Chester',
    state: 'OH',
    latitude: '39.089442',
    longitude: '-81.923112',
    timeZoneId: 'America/New_York',
  },
  '45721': {
    zip: '45721',
    city: 'Coal Run',
    state: 'OH',
    latitude: '39.567042',
    longitude: '-81.582359',
    timeZoneId: 'America/New_York',
  },
  '45723': {
    zip: '45723',
    city: 'Coolville',
    state: 'OH',
    latitude: '39.226792',
    longitude: '-81.801929',
    timeZoneId: 'America/New_York',
  },
  '45724': {
    zip: '45724',
    city: 'Cutler',
    state: 'OH',
    latitude: '39.404374',
    longitude: '-81.770012',
    timeZoneId: 'America/New_York',
  },
  '45727': {
    zip: '45727',
    city: 'Dexter City',
    state: 'OH',
    latitude: '39.634712',
    longitude: '-81.506267',
    timeZoneId: 'America/New_York',
  },
  '45729': {
    zip: '45729',
    city: 'Fleming',
    state: 'OH',
    latitude: '39.402882',
    longitude: '-81.652843',
    timeZoneId: 'America/New_York',
  },
  '45732': {
    zip: '45732',
    city: 'Glouster',
    state: 'OH',
    latitude: '39.498216',
    longitude: '-82.085766',
    timeZoneId: 'America/New_York',
  },
  '45734': {
    zip: '45734',
    city: 'Graysville',
    state: 'OH',
    latitude: '39.656927',
    longitude: '-81.187313',
    timeZoneId: 'America/New_York',
  },
  '45735': {
    zip: '45735',
    city: 'Guysville',
    state: 'OH',
    latitude: '39.259585',
    longitude: '-81.927828',
    timeZoneId: 'America/New_York',
  },
  '45739': {
    zip: '45739',
    city: 'Hockingport',
    state: 'OH',
    latitude: '39.187565',
    longitude: '-81.750644',
    timeZoneId: 'America/New_York',
  },
  '45740': {
    zip: '45740',
    city: 'Jacksonville',
    state: 'OH',
    latitude: '39.477806',
    longitude: '-82.07907',
    timeZoneId: 'America/New_York',
  },
  '45741': {
    zip: '45741',
    city: 'Langsville',
    state: 'OH',
    latitude: '39.06282',
    longitude: '-82.24986',
    timeZoneId: 'America/New_York',
  },
  '45742': {
    zip: '45742',
    city: 'Little Hocking',
    state: 'OH',
    latitude: '39.283437',
    longitude: '-81.692064',
    timeZoneId: 'America/New_York',
  },
  '45743': {
    zip: '45743',
    city: 'Long Bottom',
    state: 'OH',
    latitude: '39.078125',
    longitude: '-81.856643',
    timeZoneId: 'America/New_York',
  },
  '45744': {
    zip: '45744',
    city: 'Lowell',
    state: 'OH',
    latitude: '39.528014',
    longitude: '-81.504494',
    timeZoneId: 'America/New_York',
  },
  '45745': {
    zip: '45745',
    city: 'Lower Salem',
    state: 'OH',
    latitude: '39.6078',
    longitude: '-81.3504',
    timeZoneId: 'America/New_York',
  },
  '45746': {
    zip: '45746',
    city: 'Macksburg',
    state: 'OH',
    latitude: '39.60828',
    longitude: '-81.441502',
    timeZoneId: 'America/New_York',
  },
  '45750': {
    zip: '45750',
    city: 'Marietta',
    state: 'OH',
    latitude: '39.473611',
    longitude: '-81.479167',
    timeZoneId: 'America/New_York',
  },
  '45760': {
    zip: '45760',
    city: 'Middleport',
    state: 'OH',
    latitude: '39.006705',
    longitude: '-82.08009',
    timeZoneId: 'America/New_York',
  },
  '45761': {
    zip: '45761',
    city: 'Millfield',
    state: 'OH',
    latitude: '39.42704',
    longitude: '-82.080598',
    timeZoneId: 'America/New_York',
  },
  '45764': {
    zip: '45764',
    city: 'Nelsonville',
    state: 'OH',
    latitude: '39.445704',
    longitude: '-82.226243',
    timeZoneId: 'America/New_York',
  },
  '45766': {
    zip: '45766',
    city: 'New Marshfield',
    state: 'OH',
    latitude: '39.333878',
    longitude: '-82.244068',
    timeZoneId: 'America/New_York',
  },
  '45767': {
    zip: '45767',
    city: 'New Matamoras',
    state: 'OH',
    latitude: '39.603478',
    longitude: '-81.082854',
    timeZoneId: 'America/New_York',
  },
  '45768': {
    zip: '45768',
    city: 'Newport',
    state: 'OH',
    latitude: '39.403143',
    longitude: '-81.26873',
    timeZoneId: 'America/New_York',
  },
  '45769': {
    zip: '45769',
    city: 'Pomeroy',
    state: 'OH',
    latitude: '39.08808',
    longitude: '-82.03176',
    timeZoneId: 'America/New_York',
  },
  '45770': {
    zip: '45770',
    city: 'Portland',
    state: 'OH',
    latitude: '38.990357',
    longitude: '-81.800654',
    timeZoneId: 'America/New_York',
  },
  '45771': {
    zip: '45771',
    city: 'Racine',
    state: 'OH',
    latitude: '38.9673',
    longitude: '-81.9153',
    timeZoneId: 'America/New_York',
  },
  '45772': {
    zip: '45772',
    city: 'Reedsville',
    state: 'OH',
    latitude: '39.133099',
    longitude: '-81.817021',
    timeZoneId: 'America/New_York',
  },
  '45773': {
    zip: '45773',
    city: 'Reno',
    state: 'OH',
    latitude: '39.396667',
    longitude: '-81.375',
    timeZoneId: 'America/New_York',
  },
  '45775': {
    zip: '45775',
    city: 'Rutland',
    state: 'OH',
    latitude: '39.078654',
    longitude: '-82.154842',
    timeZoneId: 'America/New_York',
  },
  '45776': {
    zip: '45776',
    city: 'Shade',
    state: 'OH',
    latitude: '39.167515',
    longitude: '-82.025534',
    timeZoneId: 'America/New_York',
  },
  '45777': {
    zip: '45777',
    city: 'Sharpsburg',
    state: 'OH',
    latitude: '39.445418',
    longitude: '-81.921198',
    timeZoneId: 'America/New_York',
  },
  '45778': {
    zip: '45778',
    city: 'Stewart',
    state: 'OH',
    latitude: '39.33619',
    longitude: '-81.904922',
    timeZoneId: 'America/New_York',
  },
  '45779': {
    zip: '45779',
    city: 'Syracuse',
    state: 'OH',
    latitude: '38.997565',
    longitude: '-81.973984',
    timeZoneId: 'America/New_York',
  },
  '45780': {
    zip: '45780',
    city: 'The Plains',
    state: 'OH',
    latitude: '39.368277',
    longitude: '-82.132234',
    timeZoneId: 'America/New_York',
  },
  '45782': {
    zip: '45782',
    city: 'Trimble',
    state: 'OH',
    latitude: '39.482076',
    longitude: '-82.08329',
    timeZoneId: 'America/New_York',
  },
  '45783': {
    zip: '45783',
    city: 'Tuppers Plains',
    state: 'OH',
    latitude: '39.16643',
    longitude: '-81.835292',
    timeZoneId: 'America/New_York',
  },
  '45784': {
    zip: '45784',
    city: 'Vincent',
    state: 'OH',
    latitude: '39.333552',
    longitude: '-81.675363',
    timeZoneId: 'America/New_York',
  },
  '45786': {
    zip: '45786',
    city: 'Waterford',
    state: 'OH',
    latitude: '39.51453',
    longitude: '-81.666299',
    timeZoneId: 'America/New_York',
  },
  '45787': {
    zip: '45787',
    city: 'Watertown',
    state: 'OH',
    latitude: '39.465803',
    longitude: '-81.634639',
    timeZoneId: 'America/New_York',
  },
  '45788': {
    zip: '45788',
    city: 'Whipple',
    state: 'OH',
    latitude: '39.471944',
    longitude: '-81.411389',
    timeZoneId: 'America/New_York',
  },
  '45789': {
    zip: '45789',
    city: 'Wingett Run',
    state: 'OH',
    latitude: '39.583134',
    longitude: '-81.24066',
    timeZoneId: 'America/New_York',
  },
  '45801': {
    zip: '45801',
    city: 'Lima',
    state: 'OH',
    latitude: '40.759246',
    longitude: '-84.070041',
    timeZoneId: 'America/New_York',
  },
  '45802': {
    zip: '45802',
    city: 'Lima',
    state: 'OH',
    latitude: '40.741048',
    longitude: '-84.174464',
    timeZoneId: 'America/New_York',
  },
  '45804': {
    zip: '45804',
    city: 'Lima',
    state: 'OH',
    latitude: '40.721201',
    longitude: '-84.076062',
    timeZoneId: 'America/New_York',
  },
  '45805': {
    zip: '45805',
    city: 'Lima',
    state: 'OH',
    latitude: '40.738328',
    longitude: '-84.157022',
    timeZoneId: 'America/New_York',
  },
  '45806': {
    zip: '45806',
    city: 'Lima',
    state: 'OH',
    latitude: '40.686359',
    longitude: '-84.133844',
    timeZoneId: 'America/New_York',
  },
  '45807': {
    zip: '45807',
    city: 'Lima',
    state: 'OH',
    latitude: '40.79592',
    longitude: '-84.11934',
    timeZoneId: 'America/New_York',
  },
  '45808': {
    zip: '45808',
    city: 'Beaverdam',
    state: 'OH',
    latitude: '40.830818',
    longitude: '-83.969324',
    timeZoneId: 'America/New_York',
  },
  '45809': {
    zip: '45809',
    city: 'Gomer',
    state: 'OH',
    latitude: '40.845893',
    longitude: '-84.183369',
    timeZoneId: 'America/New_York',
  },
  '45810': {
    zip: '45810',
    city: 'Ada',
    state: 'OH',
    latitude: '40.748152',
    longitude: '-83.793709',
    timeZoneId: 'America/New_York',
  },
  '45812': {
    zip: '45812',
    city: 'Alger',
    state: 'OH',
    latitude: '40.6973',
    longitude: '-83.822534',
    timeZoneId: 'America/New_York',
  },
  '45813': {
    zip: '45813',
    city: 'Antwerp',
    state: 'OH',
    latitude: '41.179794',
    longitude: '-84.740486',
    timeZoneId: 'America/New_York',
  },
  '45814': {
    zip: '45814',
    city: 'Arlington',
    state: 'OH',
    latitude: '40.901358',
    longitude: '-83.630699',
    timeZoneId: 'America/New_York',
  },
  '45815': {
    zip: '45815',
    city: 'Belmore',
    state: 'OH',
    latitude: '41.15328',
    longitude: '-83.93862',
    timeZoneId: 'America/New_York',
  },
  '45816': {
    zip: '45816',
    city: 'Benton Ridge',
    state: 'OH',
    latitude: '41.004596',
    longitude: '-83.796175',
    timeZoneId: 'America/New_York',
  },
  '45817': {
    zip: '45817',
    city: 'Bluffton',
    state: 'OH',
    latitude: '40.882285',
    longitude: '-83.911911',
    timeZoneId: 'America/New_York',
  },
  '45819': {
    zip: '45819',
    city: 'Buckland',
    state: 'OH',
    latitude: '40.62401',
    longitude: '-84.262255',
    timeZoneId: 'America/New_York',
  },
  '45820': {
    zip: '45820',
    city: 'Cairo',
    state: 'OH',
    latitude: '40.83092',
    longitude: '-84.084843',
    timeZoneId: 'America/New_York',
  },
  '45821': {
    zip: '45821',
    city: 'Cecil',
    state: 'OH',
    latitude: '41.219928',
    longitude: '-84.603438',
    timeZoneId: 'America/New_York',
  },
  '45822': {
    zip: '45822',
    city: 'Celina',
    state: 'OH',
    latitude: '40.549433',
    longitude: '-84.578',
    timeZoneId: 'America/New_York',
  },
  '45826': {
    zip: '45826',
    city: 'Chickasaw',
    state: 'OH',
    latitude: '40.434324',
    longitude: '-84.493555',
    timeZoneId: 'America/New_York',
  },
  '45827': {
    zip: '45827',
    city: 'Cloverdale',
    state: 'OH',
    latitude: '41.011628',
    longitude: '-84.286099',
    timeZoneId: 'America/New_York',
  },
  '45828': {
    zip: '45828',
    city: 'Coldwater',
    state: 'OH',
    latitude: '40.483515',
    longitude: '-84.66008',
    timeZoneId: 'America/New_York',
  },
  '45830': {
    zip: '45830',
    city: 'Columbus Grove',
    state: 'OH',
    latitude: '40.872082',
    longitude: '-84.028336',
    timeZoneId: 'America/New_York',
  },
  '45831': {
    zip: '45831',
    city: 'Continental',
    state: 'OH',
    latitude: '41.102664',
    longitude: '-84.253759',
    timeZoneId: 'America/New_York',
  },
  '45832': {
    zip: '45832',
    city: 'Convoy',
    state: 'OH',
    latitude: '40.918978',
    longitude: '-84.703398',
    timeZoneId: 'America/New_York',
  },
  '45833': {
    zip: '45833',
    city: 'Delphos',
    state: 'OH',
    latitude: '40.825122',
    longitude: '-84.323947',
    timeZoneId: 'America/New_York',
  },
  '45835': {
    zip: '45835',
    city: 'Dola',
    state: 'OH',
    latitude: '40.769445',
    longitude: '-83.684937',
    timeZoneId: 'America/New_York',
  },
  '45836': {
    zip: '45836',
    city: 'Dunkirk',
    state: 'OH',
    latitude: '40.784838',
    longitude: '-83.637661',
    timeZoneId: 'America/New_York',
  },
  '45837': {
    zip: '45837',
    city: 'Dupont',
    state: 'OH',
    latitude: '41.056211',
    longitude: '-84.300289',
    timeZoneId: 'America/New_York',
  },
  '45838': {
    zip: '45838',
    city: 'Elgin',
    state: 'OH',
    latitude: '40.739828',
    longitude: '-84.475071',
    timeZoneId: 'America/New_York',
  },
  '45839': {
    zip: '45839',
    city: 'Findlay',
    state: 'OH',
    latitude: '41.044361',
    longitude: '-83.648036',
    timeZoneId: 'America/New_York',
  },
  '45840': {
    zip: '45840',
    city: 'Findlay',
    state: 'OH',
    latitude: '41.043063',
    longitude: '-83.643019',
    timeZoneId: 'America/New_York',
  },
  '45841': {
    zip: '45841',
    city: 'Jenera',
    state: 'OH',
    latitude: '40.88622',
    longitude: '-83.72286',
    timeZoneId: 'America/New_York',
  },
  '45843': {
    zip: '45843',
    city: 'Forest',
    state: 'OH',
    latitude: '40.777616',
    longitude: '-83.537432',
    timeZoneId: 'America/New_York',
  },
  '45844': {
    zip: '45844',
    city: 'Fort Jennings',
    state: 'OH',
    latitude: '40.915999',
    longitude: '-84.29049',
    timeZoneId: 'America/New_York',
  },
  '45845': {
    zip: '45845',
    city: 'Fort Loramie',
    state: 'OH',
    latitude: '40.327233',
    longitude: '-84.374535',
    timeZoneId: 'America/New_York',
  },
  '45846': {
    zip: '45846',
    city: 'Fort Recovery',
    state: 'OH',
    latitude: '40.418548',
    longitude: '-84.748829',
    timeZoneId: 'America/New_York',
  },
  '45848': {
    zip: '45848',
    city: 'Glandorf',
    state: 'OH',
    latitude: '41.02369',
    longitude: '-84.070127',
    timeZoneId: 'America/New_York',
  },
  '45849': {
    zip: '45849',
    city: 'Grover Hill',
    state: 'OH',
    latitude: '41.016949',
    longitude: '-84.486111',
    timeZoneId: 'America/New_York',
  },
  '45850': {
    zip: '45850',
    city: 'Harrod',
    state: 'OH',
    latitude: '40.722062',
    longitude: '-83.934773',
    timeZoneId: 'America/New_York',
  },
  '45851': {
    zip: '45851',
    city: 'Haviland',
    state: 'OH',
    latitude: '41.019254',
    longitude: '-84.58042',
    timeZoneId: 'America/New_York',
  },
  '45853': {
    zip: '45853',
    city: 'Kalida',
    state: 'OH',
    latitude: '40.982658',
    longitude: '-84.200776',
    timeZoneId: 'America/New_York',
  },
  '45854': {
    zip: '45854',
    city: 'Lafayette',
    state: 'OH',
    latitude: '40.758842',
    longitude: '-83.94565',
    timeZoneId: 'America/New_York',
  },
  '45855': {
    zip: '45855',
    city: 'Latty',
    state: 'OH',
    latitude: '41.088271',
    longitude: '-84.585511',
    timeZoneId: 'America/New_York',
  },
  '45856': {
    zip: '45856',
    city: 'Leipsic',
    state: 'OH',
    latitude: '41.097024',
    longitude: '-83.996732',
    timeZoneId: 'America/New_York',
  },
  '45858': {
    zip: '45858',
    city: 'Mc Comb',
    state: 'OH',
    latitude: '41.105322',
    longitude: '-83.806293',
    timeZoneId: 'America/New_York',
  },
  '45859': {
    zip: '45859',
    city: 'Mc Guffey',
    state: 'OH',
    latitude: '40.693751',
    longitude: '-83.783763',
    timeZoneId: 'America/New_York',
  },
  '45860': {
    zip: '45860',
    city: 'Maria Stein',
    state: 'OH',
    latitude: '40.395982',
    longitude: '-84.516707',
    timeZoneId: 'America/New_York',
  },
  '45861': {
    zip: '45861',
    city: 'Melrose',
    state: 'OH',
    latitude: '41.091358',
    longitude: '-84.42037',
    timeZoneId: 'America/New_York',
  },
  '45862': {
    zip: '45862',
    city: 'Mendon',
    state: 'OH',
    latitude: '40.671884',
    longitude: '-84.523424',
    timeZoneId: 'America/New_York',
  },
  '45863': {
    zip: '45863',
    city: 'Middle Point',
    state: 'OH',
    latitude: '40.85486',
    longitude: '-84.447417',
    timeZoneId: 'America/New_York',
  },
  '45864': {
    zip: '45864',
    city: 'Miller City',
    state: 'OH',
    latitude: '41.105717',
    longitude: '-84.130775',
    timeZoneId: 'America/New_York',
  },
  '45865': {
    zip: '45865',
    city: 'Minster',
    state: 'OH',
    latitude: '40.4002',
    longitude: '-84.383223',
    timeZoneId: 'America/New_York',
  },
  '45866': {
    zip: '45866',
    city: 'Montezuma',
    state: 'OH',
    latitude: '40.490703',
    longitude: '-84.54742',
    timeZoneId: 'America/New_York',
  },
  '45867': {
    zip: '45867',
    city: 'Mount Blanchard',
    state: 'OH',
    latitude: '40.905474',
    longitude: '-83.535045',
    timeZoneId: 'America/New_York',
  },
  '45868': {
    zip: '45868',
    city: 'Mount Cory',
    state: 'OH',
    latitude: '40.968598',
    longitude: '-83.837304',
    timeZoneId: 'America/New_York',
  },
  '45869': {
    zip: '45869',
    city: 'New Bremen',
    state: 'OH',
    latitude: '40.444556',
    longitude: '-84.381645',
    timeZoneId: 'America/New_York',
  },
  '45870': {
    zip: '45870',
    city: 'New Hampshire',
    state: 'OH',
    latitude: '40.558346',
    longitude: '-83.954092',
    timeZoneId: 'America/New_York',
  },
  '45871': {
    zip: '45871',
    city: 'New Knoxville',
    state: 'OH',
    latitude: '40.495497',
    longitude: '-84.302052',
    timeZoneId: 'America/New_York',
  },
  '45872': {
    zip: '45872',
    city: 'North Baltimore',
    state: 'OH',
    latitude: '41.189235',
    longitude: '-83.669819',
    timeZoneId: 'America/New_York',
  },
  '45873': {
    zip: '45873',
    city: 'Oakwood',
    state: 'OH',
    latitude: '41.096528',
    longitude: '-84.403657',
    timeZoneId: 'America/New_York',
  },
  '45874': {
    zip: '45874',
    city: 'Ohio City',
    state: 'OH',
    latitude: '40.774564',
    longitude: '-84.633321',
    timeZoneId: 'America/New_York',
  },
  '45875': {
    zip: '45875',
    city: 'Ottawa',
    state: 'OH',
    latitude: '41.029812',
    longitude: '-84.054975',
    timeZoneId: 'America/New_York',
  },
  '45876': {
    zip: '45876',
    city: 'Ottoville',
    state: 'OH',
    latitude: '40.931483',
    longitude: '-84.336412',
    timeZoneId: 'America/New_York',
  },
  '45877': {
    zip: '45877',
    city: 'Pandora',
    state: 'OH',
    latitude: '40.948816',
    longitude: '-83.953667',
    timeZoneId: 'America/New_York',
  },
  '45879': {
    zip: '45879',
    city: 'Paulding',
    state: 'OH',
    latitude: '41.137337',
    longitude: '-84.586462',
    timeZoneId: 'America/New_York',
  },
  '45880': {
    zip: '45880',
    city: 'Payne',
    state: 'OH',
    latitude: '41.076402',
    longitude: '-84.730848',
    timeZoneId: 'America/New_York',
  },
  '45881': {
    zip: '45881',
    city: 'Rawson',
    state: 'OH',
    latitude: '40.955966',
    longitude: '-83.776777',
    timeZoneId: 'America/New_York',
  },
  '45882': {
    zip: '45882',
    city: 'Rockford',
    state: 'OH',
    latitude: '40.672372',
    longitude: '-84.655712',
    timeZoneId: 'America/New_York',
  },
  '45883': {
    zip: '45883',
    city: 'Saint Henry',
    state: 'OH',
    latitude: '40.409173',
    longitude: '-84.633077',
    timeZoneId: 'America/New_York',
  },
  '45884': {
    zip: '45884',
    city: 'Saint Johns',
    state: 'OH',
    latitude: '40.553727',
    longitude: '-84.081758',
    timeZoneId: 'America/New_York',
  },
  '45885': {
    zip: '45885',
    city: 'Saint Marys',
    state: 'OH',
    latitude: '40.546649',
    longitude: '-84.395162',
    timeZoneId: 'America/New_York',
  },
  '45886': {
    zip: '45886',
    city: 'Scott',
    state: 'OH',
    latitude: '40.985439',
    longitude: '-84.580964',
    timeZoneId: 'America/New_York',
  },
  '45887': {
    zip: '45887',
    city: 'Spencerville',
    state: 'OH',
    latitude: '40.720642',
    longitude: '-84.34499',
    timeZoneId: 'America/New_York',
  },
  '45888': {
    zip: '45888',
    city: 'Uniopolis',
    state: 'OH',
    latitude: '40.601867',
    longitude: '-84.084745',
    timeZoneId: 'America/New_York',
  },
  '45889': {
    zip: '45889',
    city: 'Van Buren',
    state: 'OH',
    latitude: '41.138307',
    longitude: '-83.636176',
    timeZoneId: 'America/New_York',
  },
  '45890': {
    zip: '45890',
    city: 'Vanlue',
    state: 'OH',
    latitude: '40.967308',
    longitude: '-83.489889',
    timeZoneId: 'America/New_York',
  },
  '45891': {
    zip: '45891',
    city: 'Van Wert',
    state: 'OH',
    latitude: '40.861959',
    longitude: '-84.587966',
    timeZoneId: 'America/New_York',
  },
  '45893': {
    zip: '45893',
    city: 'Vaughnsville',
    state: 'OH',
    latitude: '40.884509',
    longitude: '-84.147928',
    timeZoneId: 'America/New_York',
  },
  '45894': {
    zip: '45894',
    city: 'Venedocia',
    state: 'OH',
    latitude: '40.750319',
    longitude: '-84.517876',
    timeZoneId: 'America/New_York',
  },
  '45895': {
    zip: '45895',
    city: 'Wapakoneta',
    state: 'OH',
    latitude: '40.579383',
    longitude: '-84.175552',
    timeZoneId: 'America/New_York',
  },
  '45896': {
    zip: '45896',
    city: 'Waynesfield',
    state: 'OH',
    latitude: '40.605675',
    longitude: '-83.968537',
    timeZoneId: 'America/New_York',
  },
  '45897': {
    zip: '45897',
    city: 'Williamstown',
    state: 'OH',
    latitude: '40.833565',
    longitude: '-83.648163',
    timeZoneId: 'America/New_York',
  },
  '45898': {
    zip: '45898',
    city: 'Willshire',
    state: 'OH',
    latitude: '40.696324',
    longitude: '-84.781616',
    timeZoneId: 'America/New_York',
  },
  '45899': {
    zip: '45899',
    city: 'Wren',
    state: 'OH',
    latitude: '40.801856',
    longitude: '-84.772143',
    timeZoneId: 'America/New_York',
  },
  '45999': {
    zip: '45999',
    city: 'Cincinnati',
    state: 'OH',
    latitude: '39.085561',
    longitude: '-84.513589',
    timeZoneId: 'America/New_York',
  },
  '46001': {
    zip: '46001',
    city: 'Alexandria',
    state: 'IN',
    latitude: '40.251384',
    longitude: '-85.660463',
    timeZoneId: 'America/New_York',
  },
  '46011': {
    zip: '46011',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.131179',
    longitude: '-85.761842',
    timeZoneId: 'America/New_York',
  },
  '46012': {
    zip: '46012',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.141971',
    longitude: '-85.628094',
    timeZoneId: 'America/New_York',
  },
  '46013': {
    zip: '46013',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.053006',
    longitude: '-85.676882',
    timeZoneId: 'America/New_York',
  },
  '46014': {
    zip: '46014',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.08703',
    longitude: '-85.67633',
    timeZoneId: 'America/New_York',
  },
  '46015': {
    zip: '46015',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.108596',
    longitude: '-85.67518',
    timeZoneId: 'America/New_York',
  },
  '46016': {
    zip: '46016',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.097201',
    longitude: '-85.681347',
    timeZoneId: 'America/New_York',
  },
  '46017': {
    zip: '46017',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.08426',
    longitude: '-85.6047',
    timeZoneId: 'America/New_York',
  },
  '46018': {
    zip: '46018',
    city: 'Anderson',
    state: 'IN',
    latitude: '40.093342',
    longitude: '-85.685401',
    timeZoneId: 'America/New_York',
  },
  '46030': {
    zip: '46030',
    city: 'Arcadia',
    state: 'IN',
    latitude: '40.175147',
    longitude: '-86.017681',
    timeZoneId: 'America/New_York',
  },
  '46031': {
    zip: '46031',
    city: 'Atlanta',
    state: 'IN',
    latitude: '40.16214',
    longitude: '-85.9356',
    timeZoneId: 'America/New_York',
  },
  '46032': {
    zip: '46032',
    city: 'Carmel',
    state: 'IN',
    latitude: '39.967649',
    longitude: '-86.160227',
    timeZoneId: 'America/New_York',
  },
  '46033': {
    zip: '46033',
    city: 'Carmel',
    state: 'IN',
    latitude: '39.975924',
    longitude: '-86.08032',
    timeZoneId: 'America/New_York',
  },
  '46034': {
    zip: '46034',
    city: 'Cicero',
    state: 'IN',
    latitude: '40.129985',
    longitude: '-86.018106',
    timeZoneId: 'America/New_York',
  },
  '46035': {
    zip: '46035',
    city: 'Colfax',
    state: 'IN',
    latitude: '40.193307',
    longitude: '-86.665691',
    timeZoneId: 'America/New_York',
  },
  '46036': {
    zip: '46036',
    city: 'Elwood',
    state: 'IN',
    latitude: '40.272959',
    longitude: '-85.843758',
    timeZoneId: 'America/New_York',
  },
  '46037': {
    zip: '46037',
    city: 'Fishers',
    state: 'IN',
    latitude: '39.9479',
    longitude: '-85.975',
    timeZoneId: 'America/New_York',
  },
  '46038': {
    zip: '46038',
    city: 'Fishers',
    state: 'IN',
    latitude: '39.96',
    longitude: '-85.98366',
    timeZoneId: 'America/New_York',
  },
  '46039': {
    zip: '46039',
    city: 'Forest',
    state: 'IN',
    latitude: '40.377511',
    longitude: '-86.313689',
    timeZoneId: 'America/New_York',
  },
  '46040': {
    zip: '46040',
    city: 'Fortville',
    state: 'IN',
    latitude: '39.927526',
    longitude: '-85.828673',
    timeZoneId: 'America/New_York',
  },
  '46041': {
    zip: '46041',
    city: 'Frankfort',
    state: 'IN',
    latitude: '40.26462',
    longitude: '-86.52846',
    timeZoneId: 'America/New_York',
  },
  '46044': {
    zip: '46044',
    city: 'Frankton',
    state: 'IN',
    latitude: '40.223059',
    longitude: '-85.771497',
    timeZoneId: 'America/New_York',
  },
  '46045': {
    zip: '46045',
    city: 'Goldsmith',
    state: 'IN',
    latitude: '40.29197',
    longitude: '-86.147711',
    timeZoneId: 'America/New_York',
  },
  '46047': {
    zip: '46047',
    city: 'Hobbs',
    state: 'IN',
    latitude: '40.286838',
    longitude: '-85.947714',
    timeZoneId: 'America/New_York',
  },
  '46048': {
    zip: '46048',
    city: 'Ingalls',
    state: 'IN',
    latitude: '39.954093',
    longitude: '-85.795621',
    timeZoneId: 'America/New_York',
  },
  '46049': {
    zip: '46049',
    city: 'Kempton',
    state: 'IN',
    latitude: '40.290062',
    longitude: '-86.230566',
    timeZoneId: 'America/New_York',
  },
  '46050': {
    zip: '46050',
    city: 'Kirklin',
    state: 'IN',
    latitude: '40.194024',
    longitude: '-86.358834',
    timeZoneId: 'America/New_York',
  },
  '46051': {
    zip: '46051',
    city: 'Lapel',
    state: 'IN',
    latitude: '40.061719',
    longitude: '-85.834745',
    timeZoneId: 'America/New_York',
  },
  '46052': {
    zip: '46052',
    city: 'Lebanon',
    state: 'IN',
    latitude: '40.051603',
    longitude: '-86.470531',
    timeZoneId: 'America/New_York',
  },
  '46055': {
    zip: '46055',
    city: 'McCordsville',
    state: 'IN',
    latitude: '39.891661',
    longitude: '-85.901929',
    timeZoneId: 'America/New_York',
  },
  '46056': {
    zip: '46056',
    city: 'Markleville',
    state: 'IN',
    latitude: '39.982476',
    longitude: '-85.614191',
    timeZoneId: 'America/New_York',
  },
  '46057': {
    zip: '46057',
    city: 'Michigantown',
    state: 'IN',
    latitude: '40.327309',
    longitude: '-86.424171',
    timeZoneId: 'America/New_York',
  },
  '46058': {
    zip: '46058',
    city: 'Mulberry',
    state: 'IN',
    latitude: '40.371179',
    longitude: '-86.626904',
    timeZoneId: 'America/New_York',
  },
  '46060': {
    zip: '46060',
    city: 'Noblesville',
    state: 'IN',
    latitude: '40.040132',
    longitude: '-86.00591',
    timeZoneId: 'America/New_York',
  },
  '46061': {
    zip: '46061',
    city: 'Noblesville',
    state: 'IN',
    latitude: '40.057606',
    longitude: '-86.031722',
    timeZoneId: 'America/New_York',
  },
  '46062': {
    zip: '46062',
    city: 'Noblesville',
    state: 'IN',
    latitude: '40.03853',
    longitude: '-86.005091',
    timeZoneId: 'America/New_York',
  },
  '46063': {
    zip: '46063',
    city: 'Orestes',
    state: 'IN',
    latitude: '40.269855',
    longitude: '-85.730519',
    timeZoneId: 'America/New_York',
  },
  '46064': {
    zip: '46064',
    city: 'Pendleton',
    state: 'IN',
    latitude: '39.99252',
    longitude: '-85.77294',
    timeZoneId: 'America/New_York',
  },
  '46065': {
    zip: '46065',
    city: 'Rossville',
    state: 'IN',
    latitude: '40.417917',
    longitude: '-86.595717',
    timeZoneId: 'America/New_York',
  },
  '46067': {
    zip: '46067',
    city: 'Sedalia',
    state: 'IN',
    latitude: '40.415976',
    longitude: '-86.515395',
    timeZoneId: 'America/New_York',
  },
  '46068': {
    zip: '46068',
    city: 'Sharpsville',
    state: 'IN',
    latitude: '40.380698',
    longitude: '-86.088622',
    timeZoneId: 'America/New_York',
  },
  '46069': {
    zip: '46069',
    city: 'Sheridan',
    state: 'IN',
    latitude: '40.137561',
    longitude: '-86.21159',
    timeZoneId: 'America/New_York',
  },
  '46070': {
    zip: '46070',
    city: 'Summitville',
    state: 'IN',
    latitude: '40.340908',
    longitude: '-85.662129',
    timeZoneId: 'America/New_York',
  },
  '46071': {
    zip: '46071',
    city: 'Thorntown',
    state: 'IN',
    latitude: '40.125801',
    longitude: '-86.611995',
    timeZoneId: 'America/New_York',
  },
  '46072': {
    zip: '46072',
    city: 'Tipton',
    state: 'IN',
    latitude: '40.287496',
    longitude: '-86.046318',
    timeZoneId: 'America/New_York',
  },
  '46074': {
    zip: '46074',
    city: 'Westfield',
    state: 'IN',
    latitude: '40.037435',
    longitude: '-86.153185',
    timeZoneId: 'America/New_York',
  },
  '46075': {
    zip: '46075',
    city: 'Whitestown',
    state: 'IN',
    latitude: '39.982382',
    longitude: '-86.35094',
    timeZoneId: 'America/New_York',
  },
  '46076': {
    zip: '46076',
    city: 'Windfall',
    state: 'IN',
    latitude: '40.363274',
    longitude: '-85.95734',
    timeZoneId: 'America/New_York',
  },
  '46077': {
    zip: '46077',
    city: 'Zionsville',
    state: 'IN',
    latitude: '39.956095',
    longitude: '-86.273792',
    timeZoneId: 'America/New_York',
  },
  '46082': {
    zip: '46082',
    city: 'Carmel',
    state: 'IN',
    latitude: '39.9798',
    longitude: '-86.119237',
    timeZoneId: 'America/New_York',
  },
  '46085': {
    zip: '46085',
    city: 'Fishers',
    state: 'IN',
    latitude: '39.979634',
    longitude: '-86.119609',
    timeZoneId: 'America/New_York',
  },
  '46102': {
    zip: '46102',
    city: 'Advance',
    state: 'IN',
    latitude: '39.996834',
    longitude: '-86.620497',
    timeZoneId: 'America/New_York',
  },
  '46103': {
    zip: '46103',
    city: 'Amo',
    state: 'IN',
    latitude: '39.697239',
    longitude: '-86.582314',
    timeZoneId: 'America/New_York',
  },
  '46104': {
    zip: '46104',
    city: 'Arlington',
    state: 'IN',
    latitude: '39.656163',
    longitude: '-85.574678',
    timeZoneId: 'America/New_York',
  },
  '46105': {
    zip: '46105',
    city: 'Bainbridge',
    state: 'IN',
    latitude: '39.762383',
    longitude: '-86.814179',
    timeZoneId: 'America/New_York',
  },
  '46106': {
    zip: '46106',
    city: 'Bargersville',
    state: 'IN',
    latitude: '39.507357',
    longitude: '-86.199658',
    timeZoneId: 'America/New_York',
  },
  '46107': {
    zip: '46107',
    city: 'Beech Grove',
    state: 'IN',
    latitude: '39.715417',
    longitude: '-86.095646',
    timeZoneId: 'America/New_York',
  },
  '46110': {
    zip: '46110',
    city: 'Boggstown',
    state: 'IN',
    latitude: '39.5648',
    longitude: '-85.921392',
    timeZoneId: 'America/New_York',
  },
  '46111': {
    zip: '46111',
    city: 'Brooklyn',
    state: 'IN',
    latitude: '39.540408',
    longitude: '-86.366352',
    timeZoneId: 'America/New_York',
  },
  '46112': {
    zip: '46112',
    city: 'Brownsburg',
    state: 'IN',
    latitude: '39.859746',
    longitude: '-86.383573',
    timeZoneId: 'America/New_York',
  },
  '46113': {
    zip: '46113',
    city: 'Camby',
    state: 'IN',
    latitude: '39.640017',
    longitude: '-86.308573',
    timeZoneId: 'America/New_York',
  },
  '46115': {
    zip: '46115',
    city: 'Carthage',
    state: 'IN',
    latitude: '39.732645',
    longitude: '-85.570001',
    timeZoneId: 'America/New_York',
  },
  '46117': {
    zip: '46117',
    city: 'Charlottesville',
    state: 'IN',
    latitude: '39.817766',
    longitude: '-85.617132',
    timeZoneId: 'America/New_York',
  },
  '46118': {
    zip: '46118',
    city: 'Clayton',
    state: 'IN',
    latitude: '39.655558',
    longitude: '-86.521037',
    timeZoneId: 'America/New_York',
  },
  '46120': {
    zip: '46120',
    city: 'Cloverdale',
    state: 'IN',
    latitude: '39.51282',
    longitude: '-86.81922',
    timeZoneId: 'America/New_York',
  },
  '46121': {
    zip: '46121',
    city: 'Coatesville',
    state: 'IN',
    latitude: '39.67309',
    longitude: '-86.664333',
    timeZoneId: 'America/New_York',
  },
  '46122': {
    zip: '46122',
    city: 'Danville',
    state: 'IN',
    latitude: '39.771018',
    longitude: '-86.540474',
    timeZoneId: 'America/New_York',
  },
  '46123': {
    zip: '46123',
    city: 'Avon',
    state: 'IN',
    latitude: '39.760556',
    longitude: '-86.526389',
    timeZoneId: 'America/New_York',
  },
  '46124': {
    zip: '46124',
    city: 'Edinburgh',
    state: 'IN',
    latitude: '39.353574',
    longitude: '-85.964085',
    timeZoneId: 'America/New_York',
  },
  '46125': {
    zip: '46125',
    city: 'Eminence',
    state: 'IN',
    latitude: '39.522149',
    longitude: '-86.637917',
    timeZoneId: 'America/New_York',
  },
  '46126': {
    zip: '46126',
    city: 'Fairland',
    state: 'IN',
    latitude: '39.609769',
    longitude: '-85.887525',
    timeZoneId: 'America/New_York',
  },
  '46127': {
    zip: '46127',
    city: 'Falmouth',
    state: 'IN',
    latitude: '39.743856',
    longitude: '-85.354057',
    timeZoneId: 'America/New_York',
  },
  '46128': {
    zip: '46128',
    city: 'Fillmore',
    state: 'IN',
    latitude: '39.651369',
    longitude: '-86.745964',
    timeZoneId: 'America/New_York',
  },
  '46129': {
    zip: '46129',
    city: 'Finly',
    state: 'IN',
    latitude: '39.705783',
    longitude: '-85.823391',
    timeZoneId: 'America/New_York',
  },
  '46130': {
    zip: '46130',
    city: 'Fountaintown',
    state: 'IN',
    latitude: '39.694471',
    longitude: '-85.782306',
    timeZoneId: 'America/New_York',
  },
  '46131': {
    zip: '46131',
    city: 'Franklin',
    state: 'IN',
    latitude: '39.488252',
    longitude: '-86.054873',
    timeZoneId: 'America/New_York',
  },
  '46133': {
    zip: '46133',
    city: 'Glenwood',
    state: 'IN',
    latitude: '39.611007',
    longitude: '-85.273628',
    timeZoneId: 'America/New_York',
  },
  '46135': {
    zip: '46135',
    city: 'Greencastle',
    state: 'IN',
    latitude: '39.64758',
    longitude: '-86.86722',
    timeZoneId: 'America/New_York',
  },
  '46140': {
    zip: '46140',
    city: 'Greenfield',
    state: 'IN',
    latitude: '39.794852',
    longitude: '-85.791522',
    timeZoneId: 'America/New_York',
  },
  '46142': {
    zip: '46142',
    city: 'Greenwood',
    state: 'IN',
    latitude: '39.621324',
    longitude: '-86.14211',
    timeZoneId: 'America/New_York',
  },
  '46143': {
    zip: '46143',
    city: 'Greenwood',
    state: 'IN',
    latitude: '39.601573',
    longitude: '-86.133793',
    timeZoneId: 'America/New_York',
  },
  '46144': {
    zip: '46144',
    city: 'Gwynneville',
    state: 'IN',
    latitude: '39.661231',
    longitude: '-85.648483',
    timeZoneId: 'America/New_York',
  },
  '46146': {
    zip: '46146',
    city: 'Homer',
    state: 'IN',
    latitude: '39.577781',
    longitude: '-85.579338',
    timeZoneId: 'America/New_York',
  },
  '46147': {
    zip: '46147',
    city: 'Jamestown',
    state: 'IN',
    latitude: '39.974266',
    longitude: '-86.635771',
    timeZoneId: 'America/New_York',
  },
  '46148': {
    zip: '46148',
    city: 'Knightstown',
    state: 'IN',
    latitude: '39.794447',
    longitude: '-85.525839',
    timeZoneId: 'America/New_York',
  },
  '46149': {
    zip: '46149',
    city: 'Lizton',
    state: 'IN',
    latitude: '39.886653',
    longitude: '-86.546411',
    timeZoneId: 'America/New_York',
  },
  '46150': {
    zip: '46150',
    city: 'Manilla',
    state: 'IN',
    latitude: '39.57895',
    longitude: '-85.635677',
    timeZoneId: 'America/New_York',
  },
  '46151': {
    zip: '46151',
    city: 'Martinsville',
    state: 'IN',
    latitude: '39.4107',
    longitude: '-86.46318',
    timeZoneId: 'America/New_York',
  },
  '46154': {
    zip: '46154',
    city: 'Maxwell',
    state: 'IN',
    latitude: '39.858357',
    longitude: '-85.767408',
    timeZoneId: 'America/New_York',
  },
  '46155': {
    zip: '46155',
    city: 'Mays',
    state: 'IN',
    latitude: '39.742938',
    longitude: '-85.429654',
    timeZoneId: 'America/New_York',
  },
  '46156': {
    zip: '46156',
    city: 'Milroy',
    state: 'IN',
    latitude: '39.502223',
    longitude: '-85.467205',
    timeZoneId: 'America/New_York',
  },
  '46157': {
    zip: '46157',
    city: 'Monrovia',
    state: 'IN',
    latitude: '39.57097',
    longitude: '-86.5216',
    timeZoneId: 'America/New_York',
  },
  '46158': {
    zip: '46158',
    city: 'Mooresville',
    state: 'IN',
    latitude: '39.592032',
    longitude: '-86.375059',
    timeZoneId: 'America/New_York',
  },
  '46160': {
    zip: '46160',
    city: 'Morgantown',
    state: 'IN',
    latitude: '39.380093',
    longitude: '-86.264593',
    timeZoneId: 'America/New_York',
  },
  '46161': {
    zip: '46161',
    city: 'Morristown',
    state: 'IN',
    latitude: '39.674407',
    longitude: '-85.699943',
    timeZoneId: 'America/New_York',
  },
  '46162': {
    zip: '46162',
    city: 'Needham',
    state: 'IN',
    latitude: '39.555482',
    longitude: '-85.957258',
    timeZoneId: 'America/New_York',
  },
  '46163': {
    zip: '46163',
    city: 'New Palestine',
    state: 'IN',
    latitude: '39.734334',
    longitude: '-85.908374',
    timeZoneId: 'America/New_York',
  },
  '46164': {
    zip: '46164',
    city: 'Nineveh',
    state: 'IN',
    latitude: '39.341154',
    longitude: '-86.103529',
    timeZoneId: 'America/New_York',
  },
  '46165': {
    zip: '46165',
    city: 'North Salem',
    state: 'IN',
    latitude: '39.855141',
    longitude: '-86.646545',
    timeZoneId: 'America/New_York',
  },
  '46166': {
    zip: '46166',
    city: 'Paragon',
    state: 'IN',
    latitude: '39.399504',
    longitude: '-86.586345',
    timeZoneId: 'America/New_York',
  },
  '46167': {
    zip: '46167',
    city: 'Pittsboro',
    state: 'IN',
    latitude: '39.878885',
    longitude: '-86.469256',
    timeZoneId: 'America/New_York',
  },
  '46168': {
    zip: '46168',
    city: 'Plainfield',
    state: 'IN',
    latitude: '39.709427',
    longitude: '-86.395053',
    timeZoneId: 'America/New_York',
  },
  '46170': {
    zip: '46170',
    city: 'Putnamville',
    state: 'IN',
    latitude: '39.57359',
    longitude: '-86.862163',
    timeZoneId: 'America/New_York',
  },
  '46171': {
    zip: '46171',
    city: 'Reelsville',
    state: 'IN',
    latitude: '39.540941',
    longitude: '-86.959946',
    timeZoneId: 'America/New_York',
  },
  '46172': {
    zip: '46172',
    city: 'Roachdale',
    state: 'IN',
    latitude: '39.847885',
    longitude: '-86.799339',
    timeZoneId: 'America/New_York',
  },
  '46173': {
    zip: '46173',
    city: 'Rushville',
    state: 'IN',
    latitude: '39.615609',
    longitude: '-85.446541',
    timeZoneId: 'America/New_York',
  },
  '46175': {
    zip: '46175',
    city: 'Russellville',
    state: 'IN',
    latitude: '39.823183',
    longitude: '-86.955674',
    timeZoneId: 'America/New_York',
  },
  '46176': {
    zip: '46176',
    city: 'Shelbyville',
    state: 'IN',
    latitude: '39.51857',
    longitude: '-85.778917',
    timeZoneId: 'America/New_York',
  },
  '46180': {
    zip: '46180',
    city: 'Stilesville',
    state: 'IN',
    latitude: '39.62443',
    longitude: '-86.605019',
    timeZoneId: 'America/New_York',
  },
  '46181': {
    zip: '46181',
    city: 'Trafalgar',
    state: 'IN',
    latitude: '39.365002',
    longitude: '-86.183378',
    timeZoneId: 'America/New_York',
  },
  '46182': {
    zip: '46182',
    city: 'Waldron',
    state: 'IN',
    latitude: '39.45244',
    longitude: '-85.66593',
    timeZoneId: 'America/New_York',
  },
  '46183': {
    zip: '46183',
    city: 'West Newton',
    state: 'IN',
    latitude: '39.654145',
    longitude: '-86.276621',
    timeZoneId: 'America/New_York',
  },
  '46184': {
    zip: '46184',
    city: 'Whiteland',
    state: 'IN',
    latitude: '39.555508',
    longitude: '-86.091336',
    timeZoneId: 'America/New_York',
  },
  '46186': {
    zip: '46186',
    city: 'Wilkinson',
    state: 'IN',
    latitude: '39.885591',
    longitude: '-85.608655',
    timeZoneId: 'America/New_York',
  },
  '46201': {
    zip: '46201',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.774629',
    longitude: '-86.109637',
    timeZoneId: 'America/New_York',
  },
  '46202': {
    zip: '46202',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.789789',
    longitude: '-86.159462',
    timeZoneId: 'America/New_York',
  },
  '46203': {
    zip: '46203',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.743419',
    longitude: '-86.104228',
    timeZoneId: 'America/New_York',
  },
  '46204': {
    zip: '46204',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.774822',
    longitude: '-86.158971',
    timeZoneId: 'America/New_York',
  },
  '46205': {
    zip: '46205',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.827486',
    longitude: '-86.133117',
    timeZoneId: 'America/New_York',
  },
  '46206': {
    zip: '46206',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.742836',
    longitude: '-86.159777',
    timeZoneId: 'America/New_York',
  },
  '46207': {
    zip: '46207',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.742239',
    longitude: '-86.164298',
    timeZoneId: 'America/New_York',
  },
  '46208': {
    zip: '46208',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.820704',
    longitude: '-86.169262',
    timeZoneId: 'America/New_York',
  },
  '46209': {
    zip: '46209',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.744999',
    longitude: '-86.158607',
    timeZoneId: 'America/New_York',
  },
  '46213': {
    zip: '46213',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.779785',
    longitude: '-86.13275',
    timeZoneId: 'America/New_York',
  },
  '46214': {
    zip: '46214',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.788672',
    longitude: '-86.29565',
    timeZoneId: 'America/New_York',
  },
  '46216': {
    zip: '46216',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.861466',
    longitude: '-86.014172',
    timeZoneId: 'America/New_York',
  },
  '46217': {
    zip: '46217',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.667568',
    longitude: '-86.198814',
    timeZoneId: 'America/New_York',
  },
  '46218': {
    zip: '46218',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.809206',
    longitude: '-86.103073',
    timeZoneId: 'America/New_York',
  },
  '46219': {
    zip: '46219',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.780423',
    longitude: '-86.044659',
    timeZoneId: 'America/New_York',
  },
  '46220': {
    zip: '46220',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.867992',
    longitude: '-86.108018',
    timeZoneId: 'America/New_York',
  },
  '46221': {
    zip: '46221',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.699569',
    longitude: '-86.233683',
    timeZoneId: 'America/New_York',
  },
  '46222': {
    zip: '46222',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.791182',
    longitude: '-86.212094',
    timeZoneId: 'America/New_York',
  },
  '46224': {
    zip: '46224',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.79296',
    longitude: '-86.25708',
    timeZoneId: 'America/New_York',
  },
  '46225': {
    zip: '46225',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.747805',
    longitude: '-86.160941',
    timeZoneId: 'America/New_York',
  },
  '46226': {
    zip: '46226',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.836981',
    longitude: '-86.046783',
    timeZoneId: 'America/New_York',
  },
  '46227': {
    zip: '46227',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.674837',
    longitude: '-86.127705',
    timeZoneId: 'America/New_York',
  },
  '46228': {
    zip: '46228',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.848173',
    longitude: '-86.193351',
    timeZoneId: 'America/New_York',
  },
  '46229': {
    zip: '46229',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.77874',
    longitude: '-85.97664',
    timeZoneId: 'America/New_York',
  },
  '46230': {
    zip: '46230',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.866751',
    longitude: '-86.109147',
    timeZoneId: 'America/New_York',
  },
  '46231': {
    zip: '46231',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.722652',
    longitude: '-86.30895',
    timeZoneId: 'America/New_York',
  },
  '46234': {
    zip: '46234',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.80771',
    longitude: '-86.33759',
    timeZoneId: 'America/New_York',
  },
  '46235': {
    zip: '46235',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.768333',
    longitude: '-86.158056',
    timeZoneId: 'America/New_York',
  },
  '46236': {
    zip: '46236',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.86321',
    longitude: '-85.96736',
    timeZoneId: 'America/New_York',
  },
  '46237': {
    zip: '46237',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.65622',
    longitude: '-86.1003',
    timeZoneId: 'America/New_York',
  },
  '46239': {
    zip: '46239',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.717557',
    longitude: '-86.000421',
    timeZoneId: 'America/New_York',
  },
  '46240': {
    zip: '46240',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.905944',
    longitude: '-86.128201',
    timeZoneId: 'America/New_York',
  },
  '46241': {
    zip: '46241',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.725953',
    longitude: '-86.25473',
    timeZoneId: 'America/New_York',
  },
  '46242': {
    zip: '46242',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.70131',
    longitude: '-86.257374',
    timeZoneId: 'America/New_York',
  },
  '46244': {
    zip: '46244',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.774382',
    longitude: '-86.158793',
    timeZoneId: 'America/New_York',
  },
  '46247': {
    zip: '46247',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.675539',
    longitude: '-86.12938',
    timeZoneId: 'America/New_York',
  },
  '46249': {
    zip: '46249',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.861999',
    longitude: '-86.002044',
    timeZoneId: 'America/New_York',
  },
  '46250': {
    zip: '46250',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.903249',
    longitude: '-86.068818',
    timeZoneId: 'America/New_York',
  },
  '46251': {
    zip: '46251',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.724125',
    longitude: '-86.269787',
    timeZoneId: 'America/New_York',
  },
  '46253': {
    zip: '46253',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.826866',
    longitude: '-86.270775',
    timeZoneId: 'America/New_York',
  },
  '46254': {
    zip: '46254',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.840838',
    longitude: '-86.265101',
    timeZoneId: 'America/New_York',
  },
  '46255': {
    zip: '46255',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.766744',
    longitude: '-86.163967',
    timeZoneId: 'America/New_York',
  },
  '46256': {
    zip: '46256',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.901691',
    longitude: '-86.015662',
    timeZoneId: 'America/New_York',
  },
  '46259': {
    zip: '46259',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.656811',
    longitude: '-85.996975',
    timeZoneId: 'America/New_York',
  },
  '46260': {
    zip: '46260',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.892649',
    longitude: '-86.180844',
    timeZoneId: 'America/New_York',
  },
  '46262': {
    zip: '46262',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.893169',
    longitude: '-86.181003',
    timeZoneId: 'America/New_York',
  },
  '46268': {
    zip: '46268',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.897141',
    longitude: '-86.228019',
    timeZoneId: 'America/New_York',
  },
  '46277': {
    zip: '46277',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.772026',
    longitude: '-86.155024',
    timeZoneId: 'America/New_York',
  },
  '46278': {
    zip: '46278',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.883333',
    longitude: '-86.238611',
    timeZoneId: 'America/New_York',
  },
  '46280': {
    zip: '46280',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.937574',
    longitude: '-86.138329',
    timeZoneId: 'America/New_York',
  },
  '46282': {
    zip: '46282',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.769381',
    longitude: '-86.160168',
    timeZoneId: 'America/New_York',
  },
  '46283': {
    zip: '46283',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.77728',
    longitude: '-86.111789',
    timeZoneId: 'America/New_York',
  },
  '46285': {
    zip: '46285',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.743009',
    longitude: '-86.162811',
    timeZoneId: 'America/New_York',
  },
  '46288': {
    zip: '46288',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.769578',
    longitude: '-86.160082',
    timeZoneId: 'America/New_York',
  },
  '46290': {
    zip: '46290',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.936276',
    longitude: '-86.211203',
    timeZoneId: 'America/New_York',
  },
  '46298': {
    zip: '46298',
    city: 'Indianapolis',
    state: 'IN',
    latitude: '39.89393',
    longitude: '-86.229942',
    timeZoneId: 'America/New_York',
  },
  '46301': {
    zip: '46301',
    city: 'Beverly Shores',
    state: 'IN',
    latitude: '41.672687',
    longitude: '-86.973604',
    timeZoneId: 'America/Chicago',
  },
  '46302': {
    zip: '46302',
    city: 'Boone Grove',
    state: 'IN',
    latitude: '41.353384',
    longitude: '-87.125263',
    timeZoneId: 'America/Chicago',
  },
  '46303': {
    zip: '46303',
    city: 'Cedar Lake',
    state: 'IN',
    latitude: '41.377765',
    longitude: '-87.444811',
    timeZoneId: 'America/Chicago',
  },
  '46304': {
    zip: '46304',
    city: 'Chesterton',
    state: 'IN',
    latitude: '41.617037',
    longitude: '-87.028793',
    timeZoneId: 'America/Chicago',
  },
  '46307': {
    zip: '46307',
    city: 'Crown Point',
    state: 'IN',
    latitude: '41.419705',
    longitude: '-87.354291',
    timeZoneId: 'America/Chicago',
  },
  '46308': {
    zip: '46308',
    city: 'Crown Point',
    state: 'IN',
    latitude: '41.417149',
    longitude: '-87.363926',
    timeZoneId: 'America/Chicago',
  },
  '46310': {
    zip: '46310',
    city: 'Demotte',
    state: 'IN',
    latitude: '41.184919',
    longitude: '-87.209975',
    timeZoneId: 'America/Chicago',
  },
  '46311': {
    zip: '46311',
    city: 'Dyer',
    state: 'IN',
    latitude: '41.48732',
    longitude: '-87.508227',
    timeZoneId: 'America/Chicago',
  },
  '46312': {
    zip: '46312',
    city: 'East Chicago',
    state: 'IN',
    latitude: '41.636175',
    longitude: '-87.456737',
    timeZoneId: 'America/Chicago',
  },
  '46319': {
    zip: '46319',
    city: 'Griffith',
    state: 'IN',
    latitude: '41.53214',
    longitude: '-87.421913',
    timeZoneId: 'America/Chicago',
  },
  '46320': {
    zip: '46320',
    city: 'Hammond',
    state: 'IN',
    latitude: '41.613152',
    longitude: '-87.504662',
    timeZoneId: 'America/Chicago',
  },
  '46321': {
    zip: '46321',
    city: 'Munster',
    state: 'IN',
    latitude: '41.55553',
    longitude: '-87.497888',
    timeZoneId: 'America/Chicago',
  },
  '46322': {
    zip: '46322',
    city: 'Highland',
    state: 'IN',
    latitude: '41.551945',
    longitude: '-87.457161',
    timeZoneId: 'America/Chicago',
  },
  '46323': {
    zip: '46323',
    city: 'Hammond',
    state: 'IN',
    latitude: '41.584786',
    longitude: '-87.451351',
    timeZoneId: 'America/Chicago',
  },
  '46324': {
    zip: '46324',
    city: 'Hammond',
    state: 'IN',
    latitude: '41.585919',
    longitude: '-87.502396',
    timeZoneId: 'America/Chicago',
  },
  '46325': {
    zip: '46325',
    city: 'Hammond',
    state: 'IN',
    latitude: '41.614095',
    longitude: '-87.514713',
    timeZoneId: 'America/Chicago',
  },
  '46327': {
    zip: '46327',
    city: 'Hammond',
    state: 'IN',
    latitude: '41.632444',
    longitude: '-87.508675',
    timeZoneId: 'America/Chicago',
  },
  '46340': {
    zip: '46340',
    city: 'Hanna',
    state: 'IN',
    latitude: '41.41204',
    longitude: '-86.777964',
    timeZoneId: 'America/Chicago',
  },
  '46341': {
    zip: '46341',
    city: 'Hebron',
    state: 'IN',
    latitude: '41.34042',
    longitude: '-87.198181',
    timeZoneId: 'America/Chicago',
  },
  '46342': {
    zip: '46342',
    city: 'Hobart',
    state: 'IN',
    latitude: '41.51934',
    longitude: '-87.24996',
    timeZoneId: 'America/Chicago',
  },
  '46345': {
    zip: '46345',
    city: 'Kingsbury',
    state: 'IN',
    latitude: '41.528451',
    longitude: '-86.697613',
    timeZoneId: 'America/Chicago',
  },
  '46346': {
    zip: '46346',
    city: 'Kingsford Heights',
    state: 'IN',
    latitude: '41.479434',
    longitude: '-86.69476',
    timeZoneId: 'America/Chicago',
  },
  '46347': {
    zip: '46347',
    city: 'Kouts',
    state: 'IN',
    latitude: '41.314916',
    longitude: '-87.034521',
    timeZoneId: 'America/Chicago',
  },
  '46348': {
    zip: '46348',
    city: 'La Crosse',
    state: 'IN',
    latitude: '41.31342',
    longitude: '-86.887041',
    timeZoneId: 'America/Chicago',
  },
  '46349': {
    zip: '46349',
    city: 'Lake Village',
    state: 'IN',
    latitude: '41.127882',
    longitude: '-87.461946',
    timeZoneId: 'America/Chicago',
  },
  '46350': {
    zip: '46350',
    city: 'La Porte',
    state: 'IN',
    latitude: '41.614878',
    longitude: '-86.734117',
    timeZoneId: 'America/Chicago',
  },
  '46352': {
    zip: '46352',
    city: 'La Porte',
    state: 'IN',
    latitude: '41.62198',
    longitude: '-86.695386',
    timeZoneId: 'America/Chicago',
  },
  '46355': {
    zip: '46355',
    city: 'Leroy',
    state: 'IN',
    latitude: '41.363967',
    longitude: '-87.267208',
    timeZoneId: 'America/Chicago',
  },
  '46356': {
    zip: '46356',
    city: 'Lowell',
    state: 'IN',
    latitude: '41.284559',
    longitude: '-87.416071',
    timeZoneId: 'America/Chicago',
  },
  '46360': {
    zip: '46360',
    city: 'Michigan City',
    state: 'IN',
    latitude: '41.709441',
    longitude: '-86.864786',
    timeZoneId: 'America/Chicago',
  },
  '46361': {
    zip: '46361',
    city: 'Michigan City',
    state: 'IN',
    latitude: '41.719635',
    longitude: '-86.902914',
    timeZoneId: 'America/Chicago',
  },
  '46365': {
    zip: '46365',
    city: 'Mill Creek',
    state: 'IN',
    latitude: '41.597919',
    longitude: '-86.549243',
    timeZoneId: 'America/Chicago',
  },
  '46366': {
    zip: '46366',
    city: 'North Judson',
    state: 'IN',
    latitude: '41.220866',
    longitude: '-86.773298',
    timeZoneId: 'America/Chicago',
  },
  '46368': {
    zip: '46368',
    city: 'Portage',
    state: 'IN',
    latitude: '41.58462',
    longitude: '-87.17472',
    timeZoneId: 'America/Chicago',
  },
  '46371': {
    zip: '46371',
    city: 'Rolling Prairie',
    state: 'IN',
    latitude: '41.686889',
    longitude: '-86.609518',
    timeZoneId: 'America/Chicago',
  },
  '46372': {
    zip: '46372',
    city: 'Roselawn',
    state: 'IN',
    latitude: '41.142111',
    longitude: '-87.310237',
    timeZoneId: 'America/Chicago',
  },
  '46373': {
    zip: '46373',
    city: 'Saint John',
    state: 'IN',
    latitude: '41.451569',
    longitude: '-87.476665',
    timeZoneId: 'America/Chicago',
  },
  '46374': {
    zip: '46374',
    city: 'San Pierre',
    state: 'IN',
    latitude: '41.197538',
    longitude: '-86.894413',
    timeZoneId: 'America/Chicago',
  },
  '46375': {
    zip: '46375',
    city: 'Schererville',
    state: 'IN',
    latitude: '41.490429',
    longitude: '-87.456888',
    timeZoneId: 'America/Chicago',
  },
  '46376': {
    zip: '46376',
    city: 'Schneider',
    state: 'IN',
    latitude: '41.18338',
    longitude: '-87.462356',
    timeZoneId: 'America/Chicago',
  },
  '46377': {
    zip: '46377',
    city: 'Shelby',
    state: 'IN',
    latitude: '41.192768',
    longitude: '-87.345317',
    timeZoneId: 'America/Chicago',
  },
  '46379': {
    zip: '46379',
    city: 'Sumava Resorts',
    state: 'IN',
    latitude: '41.16614',
    longitude: '-87.438854',
    timeZoneId: 'America/Chicago',
  },
  '46380': {
    zip: '46380',
    city: 'Tefft',
    state: 'IN',
    latitude: '41.199044',
    longitude: '-86.972303',
    timeZoneId: 'America/Chicago',
  },
  '46381': {
    zip: '46381',
    city: 'Thayer',
    state: 'IN',
    latitude: '41.171767',
    longitude: '-87.335809',
    timeZoneId: 'America/Chicago',
  },
  '46382': {
    zip: '46382',
    city: 'Union Mills',
    state: 'IN',
    latitude: '41.47602',
    longitude: '-86.77908',
    timeZoneId: 'America/Chicago',
  },
  '46383': {
    zip: '46383',
    city: 'Valparaiso',
    state: 'IN',
    latitude: '41.489214',
    longitude: '-87.074602',
    timeZoneId: 'America/Chicago',
  },
  '46384': {
    zip: '46384',
    city: 'Valparaiso',
    state: 'IN',
    latitude: '41.493624',
    longitude: '-87.054754',
    timeZoneId: 'America/Chicago',
  },
  '46385': {
    zip: '46385',
    city: 'Valparaiso',
    state: 'IN',
    latitude: '41.473056',
    longitude: '-87.061111',
    timeZoneId: 'America/Chicago',
  },
  '46390': {
    zip: '46390',
    city: 'Wanatah',
    state: 'IN',
    latitude: '41.435469',
    longitude: '-86.892131',
    timeZoneId: 'America/Chicago',
  },
  '46391': {
    zip: '46391',
    city: 'Westville',
    state: 'IN',
    latitude: '41.551257',
    longitude: '-86.899358',
    timeZoneId: 'America/Chicago',
  },
  '46392': {
    zip: '46392',
    city: 'Wheatfield',
    state: 'IN',
    latitude: '41.192267',
    longitude: '-87.052631',
    timeZoneId: 'America/Chicago',
  },
  '46393': {
    zip: '46393',
    city: 'Wheeler',
    state: 'IN',
    latitude: '41.512642',
    longitude: '-87.177875',
    timeZoneId: 'America/Chicago',
  },
  '46394': {
    zip: '46394',
    city: 'Whiting',
    state: 'IN',
    latitude: '41.679405',
    longitude: '-87.496209',
    timeZoneId: 'America/Chicago',
  },
  '46401': {
    zip: '46401',
    city: 'Gary',
    state: 'IN',
    latitude: '41.460909',
    longitude: '-87.285371',
    timeZoneId: 'America/Chicago',
  },
  '46402': {
    zip: '46402',
    city: 'Gary',
    state: 'IN',
    latitude: '41.601011',
    longitude: '-87.336284',
    timeZoneId: 'America/Chicago',
  },
  '46403': {
    zip: '46403',
    city: 'Gary',
    state: 'IN',
    latitude: '41.590239',
    longitude: '-87.276348',
    timeZoneId: 'America/Chicago',
  },
  '46404': {
    zip: '46404',
    city: 'Gary',
    state: 'IN',
    latitude: '41.585279',
    longitude: '-87.374644',
    timeZoneId: 'America/Chicago',
  },
  '46405': {
    zip: '46405',
    city: 'Lake Station',
    state: 'IN',
    latitude: '41.56853',
    longitude: '-87.270213',
    timeZoneId: 'America/Chicago',
  },
  '46406': {
    zip: '46406',
    city: 'Gary',
    state: 'IN',
    latitude: '41.587301',
    longitude: '-87.411577',
    timeZoneId: 'America/Chicago',
  },
  '46407': {
    zip: '46407',
    city: 'Gary',
    state: 'IN',
    latitude: '41.583077',
    longitude: '-87.335347',
    timeZoneId: 'America/Chicago',
  },
  '46408': {
    zip: '46408',
    city: 'Gary',
    state: 'IN',
    latitude: '41.539202',
    longitude: '-87.365937',
    timeZoneId: 'America/Chicago',
  },
  '46409': {
    zip: '46409',
    city: 'Gary',
    state: 'IN',
    latitude: '41.545122',
    longitude: '-87.323773',
    timeZoneId: 'America/Chicago',
  },
  '46410': {
    zip: '46410',
    city: 'Merrillville',
    state: 'IN',
    latitude: '41.47794',
    longitude: '-87.30582',
    timeZoneId: 'America/Chicago',
  },
  '46411': {
    zip: '46411',
    city: 'Merrillville',
    state: 'IN',
    latitude: '41.47137',
    longitude: '-87.376351',
    timeZoneId: 'America/Chicago',
  },
  '46501': {
    zip: '46501',
    city: 'Argos',
    state: 'IN',
    latitude: '41.236625',
    longitude: '-86.248195',
    timeZoneId: 'America/New_York',
  },
  '46502': {
    zip: '46502',
    city: 'Atwood',
    state: 'IN',
    latitude: '41.263913',
    longitude: '-85.974073',
    timeZoneId: 'America/New_York',
  },
  '46504': {
    zip: '46504',
    city: 'Bourbon',
    state: 'IN',
    latitude: '41.317525',
    longitude: '-86.119695',
    timeZoneId: 'America/New_York',
  },
  '46506': {
    zip: '46506',
    city: 'Bremen',
    state: 'IN',
    latitude: '41.435747',
    longitude: '-86.172511',
    timeZoneId: 'America/New_York',
  },
  '46507': {
    zip: '46507',
    city: 'Bristol',
    state: 'IN',
    latitude: '41.721113',
    longitude: '-85.827423',
    timeZoneId: 'America/New_York',
  },
  '46508': {
    zip: '46508',
    city: 'Burket',
    state: 'IN',
    latitude: '41.153498',
    longitude: '-85.96731',
    timeZoneId: 'America/New_York',
  },
  '46510': {
    zip: '46510',
    city: 'Claypool',
    state: 'IN',
    latitude: '41.123175',
    longitude: '-85.898042',
    timeZoneId: 'America/New_York',
  },
  '46511': {
    zip: '46511',
    city: 'Culver',
    state: 'IN',
    latitude: '41.226198',
    longitude: '-86.41522',
    timeZoneId: 'America/New_York',
  },
  '46513': {
    zip: '46513',
    city: 'Donaldson',
    state: 'IN',
    latitude: '41.361678',
    longitude: '-86.445676',
    timeZoneId: 'America/New_York',
  },
  '46514': {
    zip: '46514',
    city: 'Elkhart',
    state: 'IN',
    latitude: '41.711357',
    longitude: '-85.970759',
    timeZoneId: 'America/New_York',
  },
  '46515': {
    zip: '46515',
    city: 'Elkhart',
    state: 'IN',
    latitude: '41.684211',
    longitude: '-85.969529',
    timeZoneId: 'America/New_York',
  },
  '46516': {
    zip: '46516',
    city: 'Elkhart',
    state: 'IN',
    latitude: '41.677793',
    longitude: '-85.960517',
    timeZoneId: 'America/New_York',
  },
  '46517': {
    zip: '46517',
    city: 'Elkhart',
    state: 'IN',
    latitude: '41.62236',
    longitude: '-85.98378',
    timeZoneId: 'America/New_York',
  },
  '46524': {
    zip: '46524',
    city: 'Etna Green',
    state: 'IN',
    latitude: '41.296073',
    longitude: '-86.089031',
    timeZoneId: 'America/New_York',
  },
  '46526': {
    zip: '46526',
    city: 'Goshen',
    state: 'IN',
    latitude: '41.586985',
    longitude: '-85.835835',
    timeZoneId: 'America/New_York',
  },
  '46527': {
    zip: '46527',
    city: 'Goshen',
    state: 'IN',
    latitude: '41.58871',
    longitude: '-85.843436',
    timeZoneId: 'America/New_York',
  },
  '46528': {
    zip: '46528',
    city: 'Goshen',
    state: 'IN',
    latitude: '41.599107',
    longitude: '-85.787614',
    timeZoneId: 'America/New_York',
  },
  '46530': {
    zip: '46530',
    city: 'Granger',
    state: 'IN',
    latitude: '41.742276',
    longitude: '-86.161696',
    timeZoneId: 'America/New_York',
  },
  '46531': {
    zip: '46531',
    city: 'Grovertown',
    state: 'IN',
    latitude: '41.3255',
    longitude: '-86.520662',
    timeZoneId: 'America/Chicago',
  },
  '46532': {
    zip: '46532',
    city: 'Hamlet',
    state: 'IN',
    latitude: '41.382638',
    longitude: '-86.585826',
    timeZoneId: 'America/Chicago',
  },
  '46534': {
    zip: '46534',
    city: 'Knox',
    state: 'IN',
    latitude: '41.299044',
    longitude: '-86.61672',
    timeZoneId: 'America/Chicago',
  },
  '46536': {
    zip: '46536',
    city: 'Lakeville',
    state: 'IN',
    latitude: '41.525454',
    longitude: '-86.275744',
    timeZoneId: 'America/New_York',
  },
  '46537': {
    zip: '46537',
    city: 'Lapaz',
    state: 'IN',
    latitude: '41.457406',
    longitude: '-86.305414',
    timeZoneId: 'America/New_York',
  },
  '46538': {
    zip: '46538',
    city: 'Leesburg',
    state: 'IN',
    latitude: '41.333442',
    longitude: '-85.842384',
    timeZoneId: 'America/New_York',
  },
  '46539': {
    zip: '46539',
    city: 'Mentone',
    state: 'IN',
    latitude: '41.171247',
    longitude: '-86.037685',
    timeZoneId: 'America/New_York',
  },
  '46540': {
    zip: '46540',
    city: 'Middlebury',
    state: 'IN',
    latitude: '41.676613',
    longitude: '-85.704764',
    timeZoneId: 'America/New_York',
  },
  '46542': {
    zip: '46542',
    city: 'Milford',
    state: 'IN',
    latitude: '41.409782',
    longitude: '-85.848241',
    timeZoneId: 'America/New_York',
  },
  '46543': {
    zip: '46543',
    city: 'Millersburg',
    state: 'IN',
    latitude: '41.520705',
    longitude: '-85.699646',
    timeZoneId: 'America/New_York',
  },
  '46544': {
    zip: '46544',
    city: 'Mishawaka',
    state: 'IN',
    latitude: '41.65248',
    longitude: '-86.158744',
    timeZoneId: 'America/New_York',
  },
  '46545': {
    zip: '46545',
    city: 'Mishawaka',
    state: 'IN',
    latitude: '41.680563',
    longitude: '-86.160151',
    timeZoneId: 'America/New_York',
  },
  '46546': {
    zip: '46546',
    city: 'Mishawaka',
    state: 'IN',
    latitude: '41.662647',
    longitude: '-86.157621',
    timeZoneId: 'America/New_York',
  },
  '46550': {
    zip: '46550',
    city: 'Nappanee',
    state: 'IN',
    latitude: '41.447798',
    longitude: '-85.999846',
    timeZoneId: 'America/New_York',
  },
  '46552': {
    zip: '46552',
    city: 'New Carlisle',
    state: 'IN',
    latitude: '41.708477',
    longitude: '-86.48143',
    timeZoneId: 'America/New_York',
  },
  '46553': {
    zip: '46553',
    city: 'New Paris',
    state: 'IN',
    latitude: '41.501908',
    longitude: '-85.827126',
    timeZoneId: 'America/New_York',
  },
  '46554': {
    zip: '46554',
    city: 'North Liberty',
    state: 'IN',
    latitude: '41.55255',
    longitude: '-86.407096',
    timeZoneId: 'America/New_York',
  },
  '46555': {
    zip: '46555',
    city: 'North Webster',
    state: 'IN',
    latitude: '41.325309',
    longitude: '-85.68982',
    timeZoneId: 'America/New_York',
  },
  '46556': {
    zip: '46556',
    city: 'Notre Dame',
    state: 'IN',
    latitude: '41.693901',
    longitude: '-86.239284',
    timeZoneId: 'America/New_York',
  },
  '46561': {
    zip: '46561',
    city: 'Osceola',
    state: 'IN',
    latitude: '41.670601',
    longitude: '-86.077419',
    timeZoneId: 'America/New_York',
  },
  '46562': {
    zip: '46562',
    city: 'Pierceton',
    state: 'IN',
    latitude: '41.200623',
    longitude: '-85.708234',
    timeZoneId: 'America/New_York',
  },
  '46563': {
    zip: '46563',
    city: 'Plymouth',
    state: 'IN',
    latitude: '41.348915',
    longitude: '-86.328202',
    timeZoneId: 'America/New_York',
  },
  '46565': {
    zip: '46565',
    city: 'Shipshewana',
    state: 'IN',
    latitude: '41.675713',
    longitude: '-85.576964',
    timeZoneId: 'America/New_York',
  },
  '46567': {
    zip: '46567',
    city: 'Syracuse',
    state: 'IN',
    latitude: '41.423554',
    longitude: '-85.74079',
    timeZoneId: 'America/New_York',
  },
  '46570': {
    zip: '46570',
    city: 'Tippecanoe',
    state: 'IN',
    latitude: '41.212477',
    longitude: '-86.124053',
    timeZoneId: 'America/New_York',
  },
  '46571': {
    zip: '46571',
    city: 'Topeka',
    state: 'IN',
    latitude: '41.540647',
    longitude: '-85.53954',
    timeZoneId: 'America/New_York',
  },
  '46572': {
    zip: '46572',
    city: 'Tyner',
    state: 'IN',
    latitude: '41.421775',
    longitude: '-86.400405',
    timeZoneId: 'America/New_York',
  },
  '46573': {
    zip: '46573',
    city: 'Wakarusa',
    state: 'IN',
    latitude: '41.537809',
    longitude: '-86.082369',
    timeZoneId: 'America/New_York',
  },
  '46574': {
    zip: '46574',
    city: 'Walkerton',
    state: 'IN',
    latitude: '41.468357',
    longitude: '-86.465282',
    timeZoneId: 'America/New_York',
  },
  '46580': {
    zip: '46580',
    city: 'Warsaw',
    state: 'IN',
    latitude: '41.238354',
    longitude: '-85.851876',
    timeZoneId: 'America/New_York',
  },
  '46581': {
    zip: '46581',
    city: 'Warsaw',
    state: 'IN',
    latitude: '41.237114',
    longitude: '-85.85823',
    timeZoneId: 'America/New_York',
  },
  '46582': {
    zip: '46582',
    city: 'Warsaw',
    state: 'IN',
    latitude: '41.237728',
    longitude: '-85.854267',
    timeZoneId: 'America/New_York',
  },
  '46590': {
    zip: '46590',
    city: 'Winona Lake',
    state: 'IN',
    latitude: '41.219507',
    longitude: '-85.818519',
    timeZoneId: 'America/New_York',
  },
  '46595': {
    zip: '46595',
    city: 'Wyatt',
    state: 'IN',
    latitude: '41.522298',
    longitude: '-86.169288',
    timeZoneId: 'America/New_York',
  },
  '46601': {
    zip: '46601',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.672606',
    longitude: '-86.251722',
    timeZoneId: 'America/New_York',
  },
  '46613': {
    zip: '46613',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.651754',
    longitude: '-86.25101',
    timeZoneId: 'America/New_York',
  },
  '46614': {
    zip: '46614',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.625248',
    longitude: '-86.247979',
    timeZoneId: 'America/New_York',
  },
  '46615': {
    zip: '46615',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.672646',
    longitude: '-86.213631',
    timeZoneId: 'America/New_York',
  },
  '46616': {
    zip: '46616',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.689863',
    longitude: '-86.264884',
    timeZoneId: 'America/New_York',
  },
  '46617': {
    zip: '46617',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.683768',
    longitude: '-86.23232',
    timeZoneId: 'America/New_York',
  },
  '46619': {
    zip: '46619',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.665548',
    longitude: '-86.322304',
    timeZoneId: 'America/New_York',
  },
  '46624': {
    zip: '46624',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.656358',
    longitude: '-86.288349',
    timeZoneId: 'America/New_York',
  },
  '46626': {
    zip: '46626',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.657277',
    longitude: '-86.295067',
    timeZoneId: 'America/New_York',
  },
  '46628': {
    zip: '46628',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.698701',
    longitude: '-86.309464',
    timeZoneId: 'America/New_York',
  },
  '46634': {
    zip: '46634',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.692367',
    longitude: '-86.238939',
    timeZoneId: 'America/New_York',
  },
  '46635': {
    zip: '46635',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.71525',
    longitude: '-86.210153',
    timeZoneId: 'America/New_York',
  },
  '46637': {
    zip: '46637',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.726797',
    longitude: '-86.24277',
    timeZoneId: 'America/New_York',
  },
  '46660': {
    zip: '46660',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.695627',
    longitude: '-86.21444',
    timeZoneId: 'America/New_York',
  },
  '46680': {
    zip: '46680',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.632227',
    longitude: '-86.250041',
    timeZoneId: 'America/New_York',
  },
  '46699': {
    zip: '46699',
    city: 'South Bend',
    state: 'IN',
    latitude: '41.671706',
    longitude: '-86.252194',
    timeZoneId: 'America/New_York',
  },
  '46701': {
    zip: '46701',
    city: 'Albion',
    state: 'IN',
    latitude: '41.395396',
    longitude: '-85.422456',
    timeZoneId: 'America/New_York',
  },
  '46702': {
    zip: '46702',
    city: 'Andrews',
    state: 'IN',
    latitude: '40.851385',
    longitude: '-85.602954',
    timeZoneId: 'America/New_York',
  },
  '46703': {
    zip: '46703',
    city: 'Angola',
    state: 'IN',
    latitude: '41.66718',
    longitude: '-84.98964',
    timeZoneId: 'America/New_York',
  },
  '46704': {
    zip: '46704',
    city: 'Arcola',
    state: 'IN',
    latitude: '41.104668',
    longitude: '-85.291919',
    timeZoneId: 'America/New_York',
  },
  '46705': {
    zip: '46705',
    city: 'Ashley',
    state: 'IN',
    latitude: '41.514265',
    longitude: '-85.072002',
    timeZoneId: 'America/New_York',
  },
  '46706': {
    zip: '46706',
    city: 'Auburn',
    state: 'IN',
    latitude: '41.370451',
    longitude: '-85.045718',
    timeZoneId: 'America/New_York',
  },
  '46710': {
    zip: '46710',
    city: 'Avilla',
    state: 'IN',
    latitude: '41.367073',
    longitude: '-85.237128',
    timeZoneId: 'America/New_York',
  },
  '46711': {
    zip: '46711',
    city: 'Berne',
    state: 'IN',
    latitude: '40.645',
    longitude: '-85.033056',
    timeZoneId: 'America/New_York',
  },
  '46713': {
    zip: '46713',
    city: 'Bippus',
    state: 'IN',
    latitude: '40.943414',
    longitude: '-85.619752',
    timeZoneId: 'America/New_York',
  },
  '46714': {
    zip: '46714',
    city: 'Bluffton',
    state: 'IN',
    latitude: '40.71876',
    longitude: '-85.19484',
    timeZoneId: 'America/New_York',
  },
  '46721': {
    zip: '46721',
    city: 'Butler',
    state: 'IN',
    latitude: '41.413936',
    longitude: '-84.885428',
    timeZoneId: 'America/New_York',
  },
  '46723': {
    zip: '46723',
    city: 'Churubusco',
    state: 'IN',
    latitude: '41.222797',
    longitude: '-85.273777',
    timeZoneId: 'America/New_York',
  },
  '46725': {
    zip: '46725',
    city: 'Columbia City',
    state: 'IN',
    latitude: '41.14962',
    longitude: '-85.46502',
    timeZoneId: 'America/New_York',
  },
  '46730': {
    zip: '46730',
    city: 'Corunna',
    state: 'IN',
    latitude: '41.438215',
    longitude: '-85.154373',
    timeZoneId: 'America/New_York',
  },
  '46731': {
    zip: '46731',
    city: 'Craigville',
    state: 'IN',
    latitude: '40.778549',
    longitude: '-85.098739',
    timeZoneId: 'America/New_York',
  },
  '46732': {
    zip: '46732',
    city: 'Cromwell',
    state: 'IN',
    latitude: '41.401608',
    longitude: '-85.666412',
    timeZoneId: 'America/New_York',
  },
  '46733': {
    zip: '46733',
    city: 'Decatur',
    state: 'IN',
    latitude: '40.829878',
    longitude: '-84.928591',
    timeZoneId: 'America/New_York',
  },
  '46737': {
    zip: '46737',
    city: 'Fremont',
    state: 'IN',
    latitude: '41.7219',
    longitude: '-84.92022',
    timeZoneId: 'America/New_York',
  },
  '46738': {
    zip: '46738',
    city: 'Garrett',
    state: 'IN',
    latitude: '41.348005',
    longitude: '-85.13363',
    timeZoneId: 'America/New_York',
  },
  '46740': {
    zip: '46740',
    city: 'Geneva',
    state: 'IN',
    latitude: '40.595413',
    longitude: '-84.959127',
    timeZoneId: 'America/New_York',
  },
  '46741': {
    zip: '46741',
    city: 'Grabill',
    state: 'IN',
    latitude: '41.210406',
    longitude: '-84.975439',
    timeZoneId: 'America/New_York',
  },
  '46742': {
    zip: '46742',
    city: 'Hamilton',
    state: 'IN',
    latitude: '41.53872',
    longitude: '-84.89892',
    timeZoneId: 'America/New_York',
  },
  '46743': {
    zip: '46743',
    city: 'Harlan',
    state: 'IN',
    latitude: '41.219283',
    longitude: '-84.848097',
    timeZoneId: 'America/New_York',
  },
  '46745': {
    zip: '46745',
    city: 'Hoagland',
    state: 'IN',
    latitude: '40.949213',
    longitude: '-85.004562',
    timeZoneId: 'America/New_York',
  },
  '46746': {
    zip: '46746',
    city: 'Howe',
    state: 'IN',
    latitude: '41.71686',
    longitude: '-85.41384',
    timeZoneId: 'America/New_York',
  },
  '46747': {
    zip: '46747',
    city: 'Hudson',
    state: 'IN',
    latitude: '41.531111',
    longitude: '-85.170278',
    timeZoneId: 'America/New_York',
  },
  '46748': {
    zip: '46748',
    city: 'Huntertown',
    state: 'IN',
    latitude: '41.242357',
    longitude: '-85.156411',
    timeZoneId: 'America/New_York',
  },
  '46750': {
    zip: '46750',
    city: 'Huntington',
    state: 'IN',
    latitude: '40.883166',
    longitude: '-85.495183',
    timeZoneId: 'America/New_York',
  },
  '46755': {
    zip: '46755',
    city: 'Kendallville',
    state: 'IN',
    latitude: '41.448737',
    longitude: '-85.263287',
    timeZoneId: 'America/New_York',
  },
  '46759': {
    zip: '46759',
    city: 'Keystone',
    state: 'IN',
    latitude: '40.610058',
    longitude: '-85.190031',
    timeZoneId: 'America/New_York',
  },
  '46760': {
    zip: '46760',
    city: 'Kimmell',
    state: 'IN',
    latitude: '41.34881',
    longitude: '-85.609076',
    timeZoneId: 'America/New_York',
  },
  '46761': {
    zip: '46761',
    city: 'Lagrange',
    state: 'IN',
    latitude: '41.64462',
    longitude: '-85.4046',
    timeZoneId: 'America/New_York',
  },
  '46763': {
    zip: '46763',
    city: 'Laotto',
    state: 'IN',
    latitude: '41.291971',
    longitude: '-85.20244',
    timeZoneId: 'America/New_York',
  },
  '46764': {
    zip: '46764',
    city: 'Larwill',
    state: 'IN',
    latitude: '41.191399',
    longitude: '-85.616852',
    timeZoneId: 'America/New_York',
  },
  '46765': {
    zip: '46765',
    city: 'Leo',
    state: 'IN',
    latitude: '41.228281',
    longitude: '-85.030944',
    timeZoneId: 'America/New_York',
  },
  '46766': {
    zip: '46766',
    city: 'Liberty Center',
    state: 'IN',
    latitude: '40.707359',
    longitude: '-85.290083',
    timeZoneId: 'America/New_York',
  },
  '46767': {
    zip: '46767',
    city: 'Ligonier',
    state: 'IN',
    latitude: '41.466943',
    longitude: '-85.588349',
    timeZoneId: 'America/New_York',
  },
  '46769': {
    zip: '46769',
    city: 'Linn Grove',
    state: 'IN',
    latitude: '40.644448',
    longitude: '-85.031233',
    timeZoneId: 'America/New_York',
  },
  '46770': {
    zip: '46770',
    city: 'Markle',
    state: 'IN',
    latitude: '40.831539',
    longitude: '-85.326409',
    timeZoneId: 'America/New_York',
  },
  '46771': {
    zip: '46771',
    city: 'Mongo',
    state: 'IN',
    latitude: '41.685012',
    longitude: '-85.278102',
    timeZoneId: 'America/New_York',
  },
  '46772': {
    zip: '46772',
    city: 'Monroe',
    state: 'IN',
    latitude: '40.740508',
    longitude: '-84.944366',
    timeZoneId: 'America/New_York',
  },
  '46773': {
    zip: '46773',
    city: 'Monroeville',
    state: 'IN',
    latitude: '40.98251',
    longitude: '-84.877333',
    timeZoneId: 'America/New_York',
  },
  '46774': {
    zip: '46774',
    city: 'New Haven',
    state: 'IN',
    latitude: '41.076957',
    longitude: '-84.996923',
    timeZoneId: 'America/New_York',
  },
  '46776': {
    zip: '46776',
    city: 'Orland',
    state: 'IN',
    latitude: '41.73679',
    longitude: '-85.171469',
    timeZoneId: 'America/New_York',
  },
  '46777': {
    zip: '46777',
    city: 'Ossian',
    state: 'IN',
    latitude: '40.87092',
    longitude: '-85.15674',
    timeZoneId: 'America/New_York',
  },
  '46778': {
    zip: '46778',
    city: 'Petroleum',
    state: 'IN',
    latitude: '40.612239',
    longitude: '-85.148995',
    timeZoneId: 'America/New_York',
  },
  '46779': {
    zip: '46779',
    city: 'Pleasant Lake',
    state: 'IN',
    latitude: '41.59368',
    longitude: '-85.01298',
    timeZoneId: 'America/New_York',
  },
  '46780': {
    zip: '46780',
    city: 'Pleasant Mills',
    state: 'IN',
    latitude: '40.774016',
    longitude: '-84.841633',
    timeZoneId: 'America/New_York',
  },
  '46781': {
    zip: '46781',
    city: 'Poneto',
    state: 'IN',
    latitude: '40.656523',
    longitude: '-85.242718',
    timeZoneId: 'America/New_York',
  },
  '46782': {
    zip: '46782',
    city: 'Preble',
    state: 'IN',
    latitude: '40.833715',
    longitude: '-85.014182',
    timeZoneId: 'America/New_York',
  },
  '46783': {
    zip: '46783',
    city: 'Roanoke',
    state: 'IN',
    latitude: '40.962488',
    longitude: '-85.294525',
    timeZoneId: 'America/New_York',
  },
  '46784': {
    zip: '46784',
    city: 'Rome City',
    state: 'IN',
    latitude: '41.494938',
    longitude: '-85.366105',
    timeZoneId: 'America/New_York',
  },
  '46785': {
    zip: '46785',
    city: 'Saint Joe',
    state: 'IN',
    latitude: '41.320866',
    longitude: '-84.8929',
    timeZoneId: 'America/New_York',
  },
  '46786': {
    zip: '46786',
    city: 'South Milford',
    state: 'IN',
    latitude: '41.528859',
    longitude: '-85.274974',
    timeZoneId: 'America/New_York',
  },
  '46787': {
    zip: '46787',
    city: 'South Whitley',
    state: 'IN',
    latitude: '41.08577',
    longitude: '-85.618016',
    timeZoneId: 'America/New_York',
  },
  '46788': {
    zip: '46788',
    city: 'Spencerville',
    state: 'IN',
    latitude: '41.252712',
    longitude: '-84.942553',
    timeZoneId: 'America/New_York',
  },
  '46789': {
    zip: '46789',
    city: 'Stroh',
    state: 'IN',
    latitude: '41.580247',
    longitude: '-85.199688',
    timeZoneId: 'America/New_York',
  },
  '46791': {
    zip: '46791',
    city: 'Uniondale',
    state: 'IN',
    latitude: '40.833492',
    longitude: '-85.234445',
    timeZoneId: 'America/New_York',
  },
  '46792': {
    zip: '46792',
    city: 'Warren',
    state: 'IN',
    latitude: '40.685126',
    longitude: '-85.422972',
    timeZoneId: 'America/New_York',
  },
  '46793': {
    zip: '46793',
    city: 'Waterloo',
    state: 'IN',
    latitude: '41.426955',
    longitude: '-85.022802',
    timeZoneId: 'America/New_York',
  },
  '46794': {
    zip: '46794',
    city: 'Wawaka',
    state: 'IN',
    latitude: '41.491296',
    longitude: '-85.420706',
    timeZoneId: 'America/New_York',
  },
  '46795': {
    zip: '46795',
    city: 'Wolcottville',
    state: 'IN',
    latitude: '41.55204',
    longitude: '-85.2849',
    timeZoneId: 'America/New_York',
  },
  '46796': {
    zip: '46796',
    city: 'Wolflake',
    state: 'IN',
    latitude: '41.335013',
    longitude: '-85.494767',
    timeZoneId: 'America/New_York',
  },
  '46797': {
    zip: '46797',
    city: 'Woodburn',
    state: 'IN',
    latitude: '41.130094',
    longitude: '-84.866509',
    timeZoneId: 'America/New_York',
  },
  '46798': {
    zip: '46798',
    city: 'Yoder',
    state: 'IN',
    latitude: '40.940475',
    longitude: '-85.21854',
    timeZoneId: 'America/New_York',
  },
  '46799': {
    zip: '46799',
    city: 'Zanesville',
    state: 'IN',
    latitude: '40.917963',
    longitude: '-85.277888',
    timeZoneId: 'America/New_York',
  },
  '46801': {
    zip: '46801',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.067891',
    longitude: '-85.167675',
    timeZoneId: 'America/New_York',
  },
  '46802': {
    zip: '46802',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.072951',
    longitude: '-85.153427',
    timeZoneId: 'America/New_York',
  },
  '46803': {
    zip: '46803',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.070631',
    longitude: '-85.101842',
    timeZoneId: 'America/New_York',
  },
  '46804': {
    zip: '46804',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.055452',
    longitude: '-85.243032',
    timeZoneId: 'America/New_York',
  },
  '46805': {
    zip: '46805',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.099088',
    longitude: '-85.115003',
    timeZoneId: 'America/New_York',
  },
  '46806': {
    zip: '46806',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.046401',
    longitude: '-85.105518',
    timeZoneId: 'America/New_York',
  },
  '46807': {
    zip: '46807',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.048761',
    longitude: '-85.145583',
    timeZoneId: 'America/New_York',
  },
  '46808': {
    zip: '46808',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.10106',
    longitude: '-85.170092',
    timeZoneId: 'America/New_York',
  },
  '46809': {
    zip: '46809',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.026644',
    longitude: '-85.189643',
    timeZoneId: 'America/New_York',
  },
  '46814': {
    zip: '46814',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.128755',
    longitude: '-85.128368',
    timeZoneId: 'America/New_York',
  },
  '46815': {
    zip: '46815',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.113302',
    longitude: '-85.061006',
    timeZoneId: 'America/New_York',
  },
  '46816': {
    zip: '46816',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.01781',
    longitude: '-85.076819',
    timeZoneId: 'America/New_York',
  },
  '46818': {
    zip: '46818',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.147538',
    longitude: '-85.224765',
    timeZoneId: 'America/New_York',
  },
  '46819': {
    zip: '46819',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '40.996887',
    longitude: '-85.13263',
    timeZoneId: 'America/New_York',
  },
  '46825': {
    zip: '46825',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.160774',
    longitude: '-85.115233',
    timeZoneId: 'America/New_York',
  },
  '46835': {
    zip: '46835',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.138225',
    longitude: '-85.061298',
    timeZoneId: 'America/New_York',
  },
  '46845': {
    zip: '46845',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.20133',
    longitude: '-85.112447',
    timeZoneId: 'America/New_York',
  },
  '46850': {
    zip: '46850',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.066462',
    longitude: '-85.168804',
    timeZoneId: 'America/New_York',
  },
  '46851': {
    zip: '46851',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.067721',
    longitude: '-85.165914',
    timeZoneId: 'America/New_York',
  },
  '46852': {
    zip: '46852',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.069242',
    longitude: '-85.168962',
    timeZoneId: 'America/New_York',
  },
  '46853': {
    zip: '46853',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.067397',
    longitude: '-85.165583',
    timeZoneId: 'America/New_York',
  },
  '46854': {
    zip: '46854',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.148996',
    longitude: '-85.073748',
    timeZoneId: 'America/New_York',
  },
  '46855': {
    zip: '46855',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.068529',
    longitude: '-85.167398',
    timeZoneId: 'America/New_York',
  },
  '46856': {
    zip: '46856',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.066024',
    longitude: '-85.165168',
    timeZoneId: 'America/New_York',
  },
  '46857': {
    zip: '46857',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.072906',
    longitude: '-85.16652',
    timeZoneId: 'America/New_York',
  },
  '46858': {
    zip: '46858',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.067996',
    longitude: '-85.166367',
    timeZoneId: 'America/New_York',
  },
  '46859': {
    zip: '46859',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.070893',
    longitude: '-85.164844',
    timeZoneId: 'America/New_York',
  },
  '46860': {
    zip: '46860',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.071141',
    longitude: '-85.162544',
    timeZoneId: 'America/New_York',
  },
  '46861': {
    zip: '46861',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.069251',
    longitude: '-85.163346',
    timeZoneId: 'America/New_York',
  },
  '46862': {
    zip: '46862',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.06897',
    longitude: '-85.167855',
    timeZoneId: 'America/New_York',
  },
  '46863': {
    zip: '46863',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.069243',
    longitude: '-85.167029',
    timeZoneId: 'America/New_York',
  },
  '46864': {
    zip: '46864',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.067693',
    longitude: '-85.166366',
    timeZoneId: 'America/New_York',
  },
  '46865': {
    zip: '46865',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.065607',
    longitude: '-85.166533',
    timeZoneId: 'America/New_York',
  },
  '46866': {
    zip: '46866',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.070946',
    longitude: '-85.163819',
    timeZoneId: 'America/New_York',
  },
  '46867': {
    zip: '46867',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.069068',
    longitude: '-85.165857',
    timeZoneId: 'America/New_York',
  },
  '46868': {
    zip: '46868',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.069873',
    longitude: '-85.166649',
    timeZoneId: 'America/New_York',
  },
  '46869': {
    zip: '46869',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.068482',
    longitude: '-85.169351',
    timeZoneId: 'America/New_York',
  },
  '46885': {
    zip: '46885',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.135863',
    longitude: '-85.033678',
    timeZoneId: 'America/New_York',
  },
  '46895': {
    zip: '46895',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.104804',
    longitude: '-85.116922',
    timeZoneId: 'America/New_York',
  },
  '46896': {
    zip: '46896',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.00772',
    longitude: '-85.069323',
    timeZoneId: 'America/New_York',
  },
  '46897': {
    zip: '46897',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.069224',
    longitude: '-85.161465',
    timeZoneId: 'America/New_York',
  },
  '46898': {
    zip: '46898',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '41.197539',
    longitude: '-85.3053',
    timeZoneId: 'America/New_York',
  },
  '46899': {
    zip: '46899',
    city: 'Fort Wayne',
    state: 'IN',
    latitude: '40.998617',
    longitude: '-85.234375',
    timeZoneId: 'America/New_York',
  },
  '46901': {
    zip: '46901',
    city: 'Kokomo',
    state: 'IN',
    latitude: '40.490485',
    longitude: '-86.147278',
    timeZoneId: 'America/New_York',
  },
  '46902': {
    zip: '46902',
    city: 'Kokomo',
    state: 'IN',
    latitude: '40.455097',
    longitude: '-86.134088',
    timeZoneId: 'America/New_York',
  },
  '46903': {
    zip: '46903',
    city: 'Kokomo',
    state: 'IN',
    latitude: '40.475045',
    longitude: '-86.13183',
    timeZoneId: 'America/New_York',
  },
  '46904': {
    zip: '46904',
    city: 'Kokomo',
    state: 'IN',
    latitude: '40.659213',
    longitude: '-86.239543',
    timeZoneId: 'America/New_York',
  },
  '46910': {
    zip: '46910',
    city: 'Akron',
    state: 'IN',
    latitude: '41.039604',
    longitude: '-86.030494',
    timeZoneId: 'America/New_York',
  },
  '46911': {
    zip: '46911',
    city: 'Amboy',
    state: 'IN',
    latitude: '40.615509',
    longitude: '-85.942896',
    timeZoneId: 'America/New_York',
  },
  '46912': {
    zip: '46912',
    city: 'Athens',
    state: 'IN',
    latitude: '41.055062',
    longitude: '-86.125399',
    timeZoneId: 'America/New_York',
  },
  '46913': {
    zip: '46913',
    city: 'Bringhurst',
    state: 'IN',
    latitude: '40.513551',
    longitude: '-86.527197',
    timeZoneId: 'America/New_York',
  },
  '46914': {
    zip: '46914',
    city: 'Bunker Hill',
    state: 'IN',
    latitude: '40.658549',
    longitude: '-86.099518',
    timeZoneId: 'America/New_York',
  },
  '46915': {
    zip: '46915',
    city: 'Burlington',
    state: 'IN',
    latitude: '40.481653',
    longitude: '-86.394991',
    timeZoneId: 'America/New_York',
  },
  '46916': {
    zip: '46916',
    city: 'Burrows',
    state: 'IN',
    latitude: '40.674828',
    longitude: '-86.506016',
    timeZoneId: 'America/New_York',
  },
  '46917': {
    zip: '46917',
    city: 'Camden',
    state: 'IN',
    latitude: '40.59828',
    longitude: '-86.50068',
    timeZoneId: 'America/New_York',
  },
  '46919': {
    zip: '46919',
    city: 'Converse',
    state: 'IN',
    latitude: '40.578802',
    longitude: '-85.869187',
    timeZoneId: 'America/New_York',
  },
  '46920': {
    zip: '46920',
    city: 'Cutler',
    state: 'IN',
    latitude: '40.472512',
    longitude: '-86.476346',
    timeZoneId: 'America/New_York',
  },
  '46921': {
    zip: '46921',
    city: 'Deedsville',
    state: 'IN',
    latitude: '40.910115',
    longitude: '-86.101803',
    timeZoneId: 'America/New_York',
  },
  '46922': {
    zip: '46922',
    city: 'Delong',
    state: 'IN',
    latitude: '41.138558',
    longitude: '-86.416382',
    timeZoneId: 'America/New_York',
  },
  '46923': {
    zip: '46923',
    city: 'Delphi',
    state: 'IN',
    latitude: '40.589623',
    longitude: '-86.669671',
    timeZoneId: 'America/New_York',
  },
  '46926': {
    zip: '46926',
    city: 'Denver',
    state: 'IN',
    latitude: '40.891935',
    longitude: '-86.050254',
    timeZoneId: 'America/New_York',
  },
  '46928': {
    zip: '46928',
    city: 'Fairmount',
    state: 'IN',
    latitude: '40.416231',
    longitude: '-85.644682',
    timeZoneId: 'America/New_York',
  },
  '46929': {
    zip: '46929',
    city: 'Flora',
    state: 'IN',
    latitude: '40.545171',
    longitude: '-86.523488',
    timeZoneId: 'America/New_York',
  },
  '46930': {
    zip: '46930',
    city: 'Fowlerton',
    state: 'IN',
    latitude: '40.409777',
    longitude: '-85.571366',
    timeZoneId: 'America/New_York',
  },
  '46931': {
    zip: '46931',
    city: 'Fulton',
    state: 'IN',
    latitude: '40.948398',
    longitude: '-86.265871',
    timeZoneId: 'America/New_York',
  },
  '46932': {
    zip: '46932',
    city: 'Galveston',
    state: 'IN',
    latitude: '40.576797',
    longitude: '-86.195393',
    timeZoneId: 'America/New_York',
  },
  '46933': {
    zip: '46933',
    city: 'Gas City',
    state: 'IN',
    latitude: '40.487084',
    longitude: '-85.603074',
    timeZoneId: 'America/New_York',
  },
  '46935': {
    zip: '46935',
    city: 'Grass Creek',
    state: 'IN',
    latitude: '41.018611',
    longitude: '-86.413333',
    timeZoneId: 'America/New_York',
  },
  '46936': {
    zip: '46936',
    city: 'Greentown',
    state: 'IN',
    latitude: '40.476073',
    longitude: '-85.970909',
    timeZoneId: 'America/New_York',
  },
  '46937': {
    zip: '46937',
    city: 'Hemlock',
    state: 'IN',
    latitude: '40.419095',
    longitude: '-86.040352',
    timeZoneId: 'America/New_York',
  },
  '46938': {
    zip: '46938',
    city: 'Jonesboro',
    state: 'IN',
    latitude: '40.4759',
    longitude: '-85.623772',
    timeZoneId: 'America/New_York',
  },
  '46939': {
    zip: '46939',
    city: 'Kewanna',
    state: 'IN',
    latitude: '40.990318',
    longitude: '-86.398743',
    timeZoneId: 'America/New_York',
  },
  '46940': {
    zip: '46940',
    city: 'La Fontaine',
    state: 'IN',
    latitude: '40.674047',
    longitude: '-85.721014',
    timeZoneId: 'America/New_York',
  },
  '46941': {
    zip: '46941',
    city: 'Lagro',
    state: 'IN',
    latitude: '40.838011',
    longitude: '-85.728898',
    timeZoneId: 'America/New_York',
  },
  '46942': {
    zip: '46942',
    city: 'Lake Cicott',
    state: 'IN',
    latitude: '40.764992',
    longitude: '-86.53928',
    timeZoneId: 'America/New_York',
  },
  '46943': {
    zip: '46943',
    city: 'Laketon',
    state: 'IN',
    latitude: '40.973045',
    longitude: '-85.837383',
    timeZoneId: 'America/New_York',
  },
  '46945': {
    zip: '46945',
    city: 'Leiters Ford',
    state: 'IN',
    latitude: '41.121701',
    longitude: '-86.384808',
    timeZoneId: 'America/New_York',
  },
  '46946': {
    zip: '46946',
    city: 'Liberty Mills',
    state: 'IN',
    latitude: '41.030681',
    longitude: '-85.73758',
    timeZoneId: 'America/New_York',
  },
  '46947': {
    zip: '46947',
    city: 'Logansport',
    state: 'IN',
    latitude: '40.789744',
    longitude: '-86.408796',
    timeZoneId: 'America/New_York',
  },
  '46950': {
    zip: '46950',
    city: 'Lucerne',
    state: 'IN',
    latitude: '40.86747',
    longitude: '-86.413408',
    timeZoneId: 'America/New_York',
  },
  '46951': {
    zip: '46951',
    city: 'Macy',
    state: 'IN',
    latitude: '40.96878',
    longitude: '-86.1264',
    timeZoneId: 'America/New_York',
  },
  '46952': {
    zip: '46952',
    city: 'Marion',
    state: 'IN',
    latitude: '40.573842',
    longitude: '-85.666492',
    timeZoneId: 'America/New_York',
  },
  '46953': {
    zip: '46953',
    city: 'Marion',
    state: 'IN',
    latitude: '40.532775',
    longitude: '-85.658755',
    timeZoneId: 'America/New_York',
  },
  '46957': {
    zip: '46957',
    city: 'Matthews',
    state: 'IN',
    latitude: '40.388702',
    longitude: '-85.495675',
    timeZoneId: 'America/New_York',
  },
  '46958': {
    zip: '46958',
    city: 'Mexico',
    state: 'IN',
    latitude: '40.824343',
    longitude: '-86.115164',
    timeZoneId: 'America/New_York',
  },
  '46959': {
    zip: '46959',
    city: 'Miami',
    state: 'IN',
    latitude: '40.613903',
    longitude: '-86.108163',
    timeZoneId: 'America/New_York',
  },
  '46960': {
    zip: '46960',
    city: 'Monterey',
    state: 'IN',
    latitude: '41.127883',
    longitude: '-86.524856',
    timeZoneId: 'America/New_York',
  },
  '46961': {
    zip: '46961',
    city: 'New Waverly',
    state: 'IN',
    latitude: '40.764026',
    longitude: '-86.194231',
    timeZoneId: 'America/New_York',
  },
  '46962': {
    zip: '46962',
    city: 'North Manchester',
    state: 'IN',
    latitude: '41.003793',
    longitude: '-85.773443',
    timeZoneId: 'America/New_York',
  },
  '46965': {
    zip: '46965',
    city: 'Oakford',
    state: 'IN',
    latitude: '40.416518',
    longitude: '-86.102449',
    timeZoneId: 'America/New_York',
  },
  '46967': {
    zip: '46967',
    city: 'Onward',
    state: 'IN',
    latitude: '40.69494',
    longitude: '-86.196524',
    timeZoneId: 'America/New_York',
  },
  '46968': {
    zip: '46968',
    city: 'Ora',
    state: 'IN',
    latitude: '41.174795',
    longitude: '-86.556486',
    timeZoneId: 'America/Chicago',
  },
  '46970': {
    zip: '46970',
    city: 'Peru',
    state: 'IN',
    latitude: '40.753609',
    longitude: '-86.073812',
    timeZoneId: 'America/New_York',
  },
  '46971': {
    zip: '46971',
    city: 'Grissom ARB',
    state: 'IN',
    latitude: '40.673495',
    longitude: '-86.158752',
    timeZoneId: 'America/New_York',
  },
  '46974': {
    zip: '46974',
    city: 'Roann',
    state: 'IN',
    latitude: '40.909342',
    longitude: '-85.923516',
    timeZoneId: 'America/New_York',
  },
  '46975': {
    zip: '46975',
    city: 'Rochester',
    state: 'IN',
    latitude: '41.061041',
    longitude: '-86.208192',
    timeZoneId: 'America/New_York',
  },
  '46977': {
    zip: '46977',
    city: 'Rockfield',
    state: 'IN',
    latitude: '40.641473',
    longitude: '-86.573302',
    timeZoneId: 'America/New_York',
  },
  '46978': {
    zip: '46978',
    city: 'Royal Center',
    state: 'IN',
    latitude: '40.857298',
    longitude: '-86.491253',
    timeZoneId: 'America/New_York',
  },
  '46979': {
    zip: '46979',
    city: 'Russiaville',
    state: 'IN',
    latitude: '40.424335',
    longitude: '-86.26471',
    timeZoneId: 'America/New_York',
  },
  '46980': {
    zip: '46980',
    city: 'Servia',
    state: 'IN',
    latitude: '40.95508',
    longitude: '-85.739339',
    timeZoneId: 'America/New_York',
  },
  '46982': {
    zip: '46982',
    city: 'Silver Lake',
    state: 'IN',
    latitude: '41.073022',
    longitude: '-85.888777',
    timeZoneId: 'America/New_York',
  },
  '46984': {
    zip: '46984',
    city: 'Somerset',
    state: 'IN',
    latitude: '40.668252',
    longitude: '-85.828011',
    timeZoneId: 'America/New_York',
  },
  '46985': {
    zip: '46985',
    city: 'Star City',
    state: 'IN',
    latitude: '40.957243',
    longitude: '-86.526445',
    timeZoneId: 'America/New_York',
  },
  '46986': {
    zip: '46986',
    city: 'Swayzee',
    state: 'IN',
    latitude: '40.508776',
    longitude: '-85.82007',
    timeZoneId: 'America/New_York',
  },
  '46987': {
    zip: '46987',
    city: 'Sweetser',
    state: 'IN',
    latitude: '40.569508',
    longitude: '-85.766779',
    timeZoneId: 'America/New_York',
  },
  '46988': {
    zip: '46988',
    city: 'Twelve Mile',
    state: 'IN',
    latitude: '40.858248',
    longitude: '-86.204585',
    timeZoneId: 'America/New_York',
  },
  '46989': {
    zip: '46989',
    city: 'Upland',
    state: 'IN',
    latitude: '40.469833',
    longitude: '-85.504988',
    timeZoneId: 'America/New_York',
  },
  '46990': {
    zip: '46990',
    city: 'Urbana',
    state: 'IN',
    latitude: '40.901903',
    longitude: '-85.7198',
    timeZoneId: 'America/New_York',
  },
  '46991': {
    zip: '46991',
    city: 'Van Buren',
    state: 'IN',
    latitude: '40.62636',
    longitude: '-85.49544',
    timeZoneId: 'America/New_York',
  },
  '46992': {
    zip: '46992',
    city: 'Wabash',
    state: 'IN',
    latitude: '40.803388',
    longitude: '-85.82682',
    timeZoneId: 'America/New_York',
  },
  '46994': {
    zip: '46994',
    city: 'Walton',
    state: 'IN',
    latitude: '40.660177',
    longitude: '-86.24273',
    timeZoneId: 'America/New_York',
  },
  '46995': {
    zip: '46995',
    city: 'West Middleton',
    state: 'IN',
    latitude: '40.439485',
    longitude: '-86.217646',
    timeZoneId: 'America/New_York',
  },
  '46996': {
    zip: '46996',
    city: 'Winamac',
    state: 'IN',
    latitude: '41.054144',
    longitude: '-86.603068',
    timeZoneId: 'America/New_York',
  },
  '46998': {
    zip: '46998',
    city: 'Young America',
    state: 'IN',
    latitude: '40.568911',
    longitude: '-86.352814',
    timeZoneId: 'America/New_York',
  },
  '47001': {
    zip: '47001',
    city: 'Aurora',
    state: 'IN',
    latitude: '39.09486',
    longitude: '-84.9819',
    timeZoneId: 'America/New_York',
  },
  '47003': {
    zip: '47003',
    city: 'West College Corner',
    state: 'IN',
    latitude: '39.504861',
    longitude: '-84.86062',
    timeZoneId: 'America/New_York',
  },
  '47006': {
    zip: '47006',
    city: 'Batesville',
    state: 'IN',
    latitude: '39.328406',
    longitude: '-85.199126',
    timeZoneId: 'America/New_York',
  },
  '47010': {
    zip: '47010',
    city: 'Bath',
    state: 'IN',
    latitude: '39.489557',
    longitude: '-84.837882',
    timeZoneId: 'America/New_York',
  },
  '47011': {
    zip: '47011',
    city: 'Bennington',
    state: 'IN',
    latitude: '38.872783',
    longitude: '-85.080787',
    timeZoneId: 'America/New_York',
  },
  '47012': {
    zip: '47012',
    city: 'Brookville',
    state: 'IN',
    latitude: '39.41604',
    longitude: '-84.98118',
    timeZoneId: 'America/New_York',
  },
  '47016': {
    zip: '47016',
    city: 'Cedar Grove',
    state: 'IN',
    latitude: '39.368843',
    longitude: '-84.909376',
    timeZoneId: 'America/New_York',
  },
  '47017': {
    zip: '47017',
    city: 'Cross Plains',
    state: 'IN',
    latitude: '38.946441',
    longitude: '-85.221148',
    timeZoneId: 'America/New_York',
  },
  '47018': {
    zip: '47018',
    city: 'Dillsboro',
    state: 'IN',
    latitude: '38.96742',
    longitude: '-85.05186',
    timeZoneId: 'America/New_York',
  },
  '47019': {
    zip: '47019',
    city: 'East Enterprise',
    state: 'IN',
    latitude: '38.872221',
    longitude: '-84.98891',
    timeZoneId: 'America/New_York',
  },
  '47020': {
    zip: '47020',
    city: 'Florence',
    state: 'IN',
    latitude: '38.820823',
    longitude: '-84.93501',
    timeZoneId: 'America/New_York',
  },
  '47021': {
    zip: '47021',
    city: 'Friendship',
    state: 'IN',
    latitude: '39.119473',
    longitude: '-85.207959',
    timeZoneId: 'America/New_York',
  },
  '47022': {
    zip: '47022',
    city: 'Guilford',
    state: 'IN',
    latitude: '39.206304',
    longitude: '-84.952322',
    timeZoneId: 'America/New_York',
  },
  '47023': {
    zip: '47023',
    city: 'Holton',
    state: 'IN',
    latitude: '39.021348',
    longitude: '-85.387979',
    timeZoneId: 'America/New_York',
  },
  '47024': {
    zip: '47024',
    city: 'Laurel',
    state: 'IN',
    latitude: '39.477157',
    longitude: '-85.195131',
    timeZoneId: 'America/New_York',
  },
  '47025': {
    zip: '47025',
    city: 'Lawrenceburg',
    state: 'IN',
    latitude: '39.15246',
    longitude: '-84.88728',
    timeZoneId: 'America/New_York',
  },
  '47030': {
    zip: '47030',
    city: 'Metamora',
    state: 'IN',
    latitude: '39.425219',
    longitude: '-85.136486',
    timeZoneId: 'America/New_York',
  },
  '47031': {
    zip: '47031',
    city: 'Milan',
    state: 'IN',
    latitude: '39.162406',
    longitude: '-85.140847',
    timeZoneId: 'America/New_York',
  },
  '47032': {
    zip: '47032',
    city: 'Moores Hill',
    state: 'IN',
    latitude: '39.077097',
    longitude: '-85.051934',
    timeZoneId: 'America/New_York',
  },
  '47033': {
    zip: '47033',
    city: 'Morris',
    state: 'IN',
    latitude: '39.287744',
    longitude: '-85.196501',
    timeZoneId: 'America/New_York',
  },
  '47034': {
    zip: '47034',
    city: 'Napoleon',
    state: 'IN',
    latitude: '39.204178',
    longitude: '-85.329689',
    timeZoneId: 'America/New_York',
  },
  '47035': {
    zip: '47035',
    city: 'New Trenton',
    state: 'IN',
    latitude: '39.312024',
    longitude: '-84.900887',
    timeZoneId: 'America/New_York',
  },
  '47036': {
    zip: '47036',
    city: 'Oldenburg',
    state: 'IN',
    latitude: '39.379006',
    longitude: '-85.240938',
    timeZoneId: 'America/New_York',
  },
  '47037': {
    zip: '47037',
    city: 'Osgood',
    state: 'IN',
    latitude: '39.126913',
    longitude: '-85.29363',
    timeZoneId: 'America/New_York',
  },
  '47038': {
    zip: '47038',
    city: 'Patriot',
    state: 'IN',
    latitude: '38.835094',
    longitude: '-84.844731',
    timeZoneId: 'America/New_York',
  },
  '47039': {
    zip: '47039',
    city: 'Pierceville',
    state: 'IN',
    latitude: '39.112806',
    longitude: '-85.221521',
    timeZoneId: 'America/New_York',
  },
  '47040': {
    zip: '47040',
    city: 'Rising Sun',
    state: 'IN',
    latitude: '38.949548',
    longitude: '-84.857658',
    timeZoneId: 'America/New_York',
  },
  '47041': {
    zip: '47041',
    city: 'Sunman',
    state: 'IN',
    latitude: '39.25422',
    longitude: '-85.06746',
    timeZoneId: 'America/New_York',
  },
  '47042': {
    zip: '47042',
    city: 'Versailles',
    state: 'IN',
    latitude: '39.068723',
    longitude: '-85.255691',
    timeZoneId: 'America/New_York',
  },
  '47043': {
    zip: '47043',
    city: 'Vevay',
    state: 'IN',
    latitude: '38.750187',
    longitude: '-85.066805',
    timeZoneId: 'America/New_York',
  },
  '47060': {
    zip: '47060',
    city: 'West Harrison',
    state: 'IN',
    latitude: '39.288941',
    longitude: '-84.865533',
    timeZoneId: 'America/New_York',
  },
  '47102': {
    zip: '47102',
    city: 'Austin',
    state: 'IN',
    latitude: '38.743476',
    longitude: '-85.80459',
    timeZoneId: 'America/New_York',
  },
  '47104': {
    zip: '47104',
    city: 'Bethlehem',
    state: 'IN',
    latitude: '38.539827',
    longitude: '-85.422262',
    timeZoneId: 'America/New_York',
  },
  '47106': {
    zip: '47106',
    city: 'Borden',
    state: 'IN',
    latitude: '38.454142',
    longitude: '-85.870973',
    timeZoneId: 'America/New_York',
  },
  '47107': {
    zip: '47107',
    city: 'Bradford',
    state: 'IN',
    latitude: '38.36703',
    longitude: '-86.063767',
    timeZoneId: 'America/New_York',
  },
  '47108': {
    zip: '47108',
    city: 'Campbellsburg',
    state: 'IN',
    latitude: '38.65',
    longitude: '-86.26',
    timeZoneId: 'America/New_York',
  },
  '47110': {
    zip: '47110',
    city: 'Central',
    state: 'IN',
    latitude: '38.091924',
    longitude: '-86.161324',
    timeZoneId: 'America/New_York',
  },
  '47111': {
    zip: '47111',
    city: 'Charlestown',
    state: 'IN',
    latitude: '38.457512',
    longitude: '-85.653758',
    timeZoneId: 'America/New_York',
  },
  '47112': {
    zip: '47112',
    city: 'Corydon',
    state: 'IN',
    latitude: '38.207724',
    longitude: '-86.127597',
    timeZoneId: 'America/New_York',
  },
  '47114': {
    zip: '47114',
    city: 'Crandall',
    state: 'IN',
    latitude: '38.28932',
    longitude: '-86.073487',
    timeZoneId: 'America/New_York',
  },
  '47115': {
    zip: '47115',
    city: 'Depauw',
    state: 'IN',
    latitude: '38.336211',
    longitude: '-86.27019',
    timeZoneId: 'America/New_York',
  },
  '47116': {
    zip: '47116',
    city: 'Eckerty',
    state: 'IN',
    latitude: '38.319039',
    longitude: '-86.618917',
    timeZoneId: 'America/New_York',
  },
  '47117': {
    zip: '47117',
    city: 'Elizabeth',
    state: 'IN',
    latitude: '38.115905',
    longitude: '-86.055376',
    timeZoneId: 'America/New_York',
  },
  '47118': {
    zip: '47118',
    city: 'English',
    state: 'IN',
    latitude: '38.333768',
    longitude: '-86.461871',
    timeZoneId: 'America/New_York',
  },
  '47119': {
    zip: '47119',
    city: 'Floyds Knobs',
    state: 'IN',
    latitude: '38.3562',
    longitude: '-85.90476',
    timeZoneId: 'America/New_York',
  },
  '47120': {
    zip: '47120',
    city: 'Fredericksburg',
    state: 'IN',
    latitude: '38.43313',
    longitude: '-86.18997',
    timeZoneId: 'America/New_York',
  },
  '47122': {
    zip: '47122',
    city: 'Georgetown',
    state: 'IN',
    latitude: '38.305393',
    longitude: '-85.952952',
    timeZoneId: 'America/New_York',
  },
  '47123': {
    zip: '47123',
    city: 'Grantsburg',
    state: 'IN',
    latitude: '38.285702',
    longitude: '-86.472108',
    timeZoneId: 'America/New_York',
  },
  '47124': {
    zip: '47124',
    city: 'Greenville',
    state: 'IN',
    latitude: '38.374078',
    longitude: '-85.992416',
    timeZoneId: 'America/New_York',
  },
  '47125': {
    zip: '47125',
    city: 'Hardinsburg',
    state: 'IN',
    latitude: '38.466102',
    longitude: '-86.323493',
    timeZoneId: 'America/New_York',
  },
  '47126': {
    zip: '47126',
    city: 'Henryville',
    state: 'IN',
    latitude: '38.539614',
    longitude: '-85.773039',
    timeZoneId: 'America/New_York',
  },
  '47129': {
    zip: '47129',
    city: 'Clarksville',
    state: 'IN',
    latitude: '38.316146',
    longitude: '-85.769135',
    timeZoneId: 'America/New_York',
  },
  '47130': {
    zip: '47130',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.300223',
    longitude: '-85.733457',
    timeZoneId: 'America/New_York',
  },
  '47131': {
    zip: '47131',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.338659',
    longitude: '-85.705189',
    timeZoneId: 'America/New_York',
  },
  '47132': {
    zip: '47132',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.287784',
    longitude: '-85.731015',
    timeZoneId: 'America/New_York',
  },
  '47133': {
    zip: '47133',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.287708',
    longitude: '-85.73618',
    timeZoneId: 'America/New_York',
  },
  '47134': {
    zip: '47134',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.285868',
    longitude: '-85.732024',
    timeZoneId: 'America/New_York',
  },
  '47135': {
    zip: '47135',
    city: 'Laconia',
    state: 'IN',
    latitude: '38.03976',
    longitude: '-86.074463',
    timeZoneId: 'America/New_York',
  },
  '47136': {
    zip: '47136',
    city: 'Lanesville',
    state: 'IN',
    latitude: '38.242776',
    longitude: '-85.930962',
    timeZoneId: 'America/New_York',
  },
  '47137': {
    zip: '47137',
    city: 'Leavenworth',
    state: 'IN',
    latitude: '38.209645',
    longitude: '-86.365045',
    timeZoneId: 'America/New_York',
  },
  '47138': {
    zip: '47138',
    city: 'Lexington',
    state: 'IN',
    latitude: '38.712755',
    longitude: '-85.655231',
    timeZoneId: 'America/New_York',
  },
  '47140': {
    zip: '47140',
    city: 'Marengo',
    state: 'IN',
    latitude: '38.383335',
    longitude: '-86.38451',
    timeZoneId: 'America/New_York',
  },
  '47141': {
    zip: '47141',
    city: 'Marysville',
    state: 'IN',
    latitude: '38.550638',
    longitude: '-85.597956',
    timeZoneId: 'America/New_York',
  },
  '47142': {
    zip: '47142',
    city: 'Mauckport',
    state: 'IN',
    latitude: '38.092395',
    longitude: '-86.191402',
    timeZoneId: 'America/New_York',
  },
  '47143': {
    zip: '47143',
    city: 'Memphis',
    state: 'IN',
    latitude: '38.471919',
    longitude: '-85.766289',
    timeZoneId: 'America/New_York',
  },
  '47144': {
    zip: '47144',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.33273',
    longitude: '-85.7084',
    timeZoneId: 'America/New_York',
  },
  '47145': {
    zip: '47145',
    city: 'Milltown',
    state: 'IN',
    latitude: '38.343123',
    longitude: '-86.265723',
    timeZoneId: 'America/New_York',
  },
  '47146': {
    zip: '47146',
    city: 'Mount Saint Francis',
    state: 'IN',
    latitude: '38.318232',
    longitude: '-85.911798',
    timeZoneId: 'America/New_York',
  },
  '47147': {
    zip: '47147',
    city: 'Nabb',
    state: 'IN',
    latitude: '38.581248',
    longitude: '-85.535514',
    timeZoneId: 'America/New_York',
  },
  '47150': {
    zip: '47150',
    city: 'New Albany',
    state: 'IN',
    latitude: '38.307855',
    longitude: '-85.826843',
    timeZoneId: 'America/New_York',
  },
  '47151': {
    zip: '47151',
    city: 'New Albany',
    state: 'IN',
    latitude: '38.287601',
    longitude: '-85.825632',
    timeZoneId: 'America/New_York',
  },
  '47160': {
    zip: '47160',
    city: 'New Middletown',
    state: 'IN',
    latitude: '38.138492',
    longitude: '-86.054477',
    timeZoneId: 'America/New_York',
  },
  '47161': {
    zip: '47161',
    city: 'New Salisbury',
    state: 'IN',
    latitude: '38.302477',
    longitude: '-86.097081',
    timeZoneId: 'America/New_York',
  },
  '47162': {
    zip: '47162',
    city: 'New Washington',
    state: 'IN',
    latitude: '38.545267',
    longitude: '-85.478896',
    timeZoneId: 'America/New_York',
  },
  '47163': {
    zip: '47163',
    city: 'Otisco',
    state: 'IN',
    latitude: '38.543218',
    longitude: '-85.662713',
    timeZoneId: 'America/New_York',
  },
  '47164': {
    zip: '47164',
    city: 'Palmyra',
    state: 'IN',
    latitude: '38.406478',
    longitude: '-86.089859',
    timeZoneId: 'America/New_York',
  },
  '47165': {
    zip: '47165',
    city: 'Pekin',
    state: 'IN',
    latitude: '38.397297',
    longitude: '-85.994776',
    timeZoneId: 'America/New_York',
  },
  '47166': {
    zip: '47166',
    city: 'Ramsey',
    state: 'IN',
    latitude: '38.33852',
    longitude: '-86.136754',
    timeZoneId: 'America/New_York',
  },
  '47167': {
    zip: '47167',
    city: 'Salem',
    state: 'IN',
    latitude: '38.605089',
    longitude: '-86.098301',
    timeZoneId: 'America/New_York',
  },
  '47170': {
    zip: '47170',
    city: 'Scottsburg',
    state: 'IN',
    latitude: '38.68501',
    longitude: '-85.777663',
    timeZoneId: 'America/New_York',
  },
  '47172': {
    zip: '47172',
    city: 'Sellersburg',
    state: 'IN',
    latitude: '38.392719',
    longitude: '-85.760504',
    timeZoneId: 'America/New_York',
  },
  '47174': {
    zip: '47174',
    city: 'Sulphur',
    state: 'IN',
    latitude: '38.22289',
    longitude: '-86.49147',
    timeZoneId: 'America/New_York',
  },
  '47175': {
    zip: '47175',
    city: 'Taswell',
    state: 'IN',
    latitude: '38.347872',
    longitude: '-86.538762',
    timeZoneId: 'America/New_York',
  },
  '47177': {
    zip: '47177',
    city: 'Underwood',
    state: 'IN',
    latitude: '38.51516',
    longitude: '-85.760406',
    timeZoneId: 'America/New_York',
  },
  '47190': {
    zip: '47190',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.2792',
    longitude: '-85.7227',
    timeZoneId: 'America/New_York',
  },
  '47199': {
    zip: '47199',
    city: 'Jeffersonville',
    state: 'IN',
    latitude: '38.285992',
    longitude: '-85.732879',
    timeZoneId: 'America/New_York',
  },
  '47201': {
    zip: '47201',
    city: 'Columbus',
    state: 'IN',
    latitude: '39.199917',
    longitude: '-85.934788',
    timeZoneId: 'America/New_York',
  },
  '47202': {
    zip: '47202',
    city: 'Columbus',
    state: 'IN',
    latitude: '39.207308',
    longitude: '-85.918098',
    timeZoneId: 'America/New_York',
  },
  '47203': {
    zip: '47203',
    city: 'Columbus',
    state: 'IN',
    latitude: '39.233901',
    longitude: '-85.876002',
    timeZoneId: 'America/New_York',
  },
  '47220': {
    zip: '47220',
    city: 'Brownstown',
    state: 'IN',
    latitude: '38.881161',
    longitude: '-86.045869',
    timeZoneId: 'America/New_York',
  },
  '47223': {
    zip: '47223',
    city: 'Butlerville',
    state: 'IN',
    latitude: '39.120056',
    longitude: '-85.492249',
    timeZoneId: 'America/New_York',
  },
  '47224': {
    zip: '47224',
    city: 'Canaan',
    state: 'IN',
    latitude: '38.87635',
    longitude: '-85.265875',
    timeZoneId: 'America/New_York',
  },
  '47225': {
    zip: '47225',
    city: 'Clarksburg',
    state: 'IN',
    latitude: '39.432445',
    longitude: '-85.35535',
    timeZoneId: 'America/New_York',
  },
  '47226': {
    zip: '47226',
    city: 'Clifford',
    state: 'IN',
    latitude: '39.285117',
    longitude: '-85.868116',
    timeZoneId: 'America/New_York',
  },
  '47227': {
    zip: '47227',
    city: 'Commiskey',
    state: 'IN',
    latitude: '38.856452',
    longitude: '-85.638507',
    timeZoneId: 'America/New_York',
  },
  '47228': {
    zip: '47228',
    city: 'Cortland',
    state: 'IN',
    latitude: '38.985937',
    longitude: '-86.003945',
    timeZoneId: 'America/New_York',
  },
  '47229': {
    zip: '47229',
    city: 'Crothersville',
    state: 'IN',
    latitude: '38.792996',
    longitude: '-85.841879',
    timeZoneId: 'America/New_York',
  },
  '47230': {
    zip: '47230',
    city: 'Deputy',
    state: 'IN',
    latitude: '38.781889',
    longitude: '-85.625205',
    timeZoneId: 'America/New_York',
  },
  '47231': {
    zip: '47231',
    city: 'Dupont',
    state: 'IN',
    latitude: '38.891468',
    longitude: '-85.50173',
    timeZoneId: 'America/New_York',
  },
  '47232': {
    zip: '47232',
    city: 'Elizabethtown',
    state: 'IN',
    latitude: '39.1122',
    longitude: '-85.80948',
    timeZoneId: 'America/New_York',
  },
  '47234': {
    zip: '47234',
    city: 'Flat Rock',
    state: 'IN',
    latitude: '39.396093',
    longitude: '-85.687549',
    timeZoneId: 'America/New_York',
  },
  '47235': {
    zip: '47235',
    city: 'Freetown',
    state: 'IN',
    latitude: '39.010573',
    longitude: '-86.122781',
    timeZoneId: 'America/New_York',
  },
  '47236': {
    zip: '47236',
    city: 'Grammer',
    state: 'IN',
    latitude: '39.135',
    longitude: '-85.813333',
    timeZoneId: 'America/New_York',
  },
  '47240': {
    zip: '47240',
    city: 'Greensburg',
    state: 'IN',
    latitude: '39.33696',
    longitude: '-85.45392',
    timeZoneId: 'America/New_York',
  },
  '47243': {
    zip: '47243',
    city: 'Hanover',
    state: 'IN',
    latitude: '38.714687',
    longitude: '-85.472195',
    timeZoneId: 'America/New_York',
  },
  '47244': {
    zip: '47244',
    city: 'Hartsville',
    state: 'IN',
    latitude: '39.268162',
    longitude: '-85.697809',
    timeZoneId: 'America/New_York',
  },
  '47245': {
    zip: '47245',
    city: 'Hayden',
    state: 'IN',
    latitude: '38.983934',
    longitude: '-85.741159',
    timeZoneId: 'America/New_York',
  },
  '47246': {
    zip: '47246',
    city: 'Hope',
    state: 'IN',
    latitude: '39.292612',
    longitude: '-85.759781',
    timeZoneId: 'America/New_York',
  },
  '47247': {
    zip: '47247',
    city: 'Jonesville',
    state: 'IN',
    latitude: '39.05983',
    longitude: '-85.892065',
    timeZoneId: 'America/New_York',
  },
  '47249': {
    zip: '47249',
    city: 'Kurtz',
    state: 'IN',
    latitude: '38.956371',
    longitude: '-86.204228',
    timeZoneId: 'America/New_York',
  },
  '47250': {
    zip: '47250',
    city: 'Madison',
    state: 'IN',
    latitude: '38.755353',
    longitude: '-85.397514',
    timeZoneId: 'America/New_York',
  },
  '47260': {
    zip: '47260',
    city: 'Medora',
    state: 'IN',
    latitude: '38.820298',
    longitude: '-86.211748',
    timeZoneId: 'America/New_York',
  },
  '47263': {
    zip: '47263',
    city: 'New Point',
    state: 'IN',
    latitude: '39.310196',
    longitude: '-85.328612',
    timeZoneId: 'America/New_York',
  },
  '47264': {
    zip: '47264',
    city: 'Norman',
    state: 'IN',
    latitude: '38.953683',
    longitude: '-86.22303',
    timeZoneId: 'America/New_York',
  },
  '47265': {
    zip: '47265',
    city: 'North Vernon',
    state: 'IN',
    latitude: '39.018614',
    longitude: '-85.652437',
    timeZoneId: 'America/New_York',
  },
  '47270': {
    zip: '47270',
    city: 'Paris Crossing',
    state: 'IN',
    latitude: '38.855889',
    longitude: '-85.743535',
    timeZoneId: 'America/New_York',
  },
  '47272': {
    zip: '47272',
    city: 'Saint Paul',
    state: 'IN',
    latitude: '39.430872',
    longitude: '-85.633781',
    timeZoneId: 'America/New_York',
  },
  '47273': {
    zip: '47273',
    city: 'Scipio',
    state: 'IN',
    latitude: '39.080034',
    longitude: '-85.724927',
    timeZoneId: 'America/New_York',
  },
  '47274': {
    zip: '47274',
    city: 'Seymour',
    state: 'IN',
    latitude: '38.959237',
    longitude: '-85.892632',
    timeZoneId: 'America/New_York',
  },
  '47280': {
    zip: '47280',
    city: 'Taylorsville',
    state: 'IN',
    latitude: '39.298329',
    longitude: '-85.947906',
    timeZoneId: 'America/New_York',
  },
  '47281': {
    zip: '47281',
    city: 'Vallonia',
    state: 'IN',
    latitude: '38.800788',
    longitude: '-86.059127',
    timeZoneId: 'America/New_York',
  },
  '47282': {
    zip: '47282',
    city: 'Vernon',
    state: 'IN',
    latitude: '38.98451',
    longitude: '-85.60942',
    timeZoneId: 'America/New_York',
  },
  '47283': {
    zip: '47283',
    city: 'Westport',
    state: 'IN',
    latitude: '39.175635',
    longitude: '-85.575619',
    timeZoneId: 'America/New_York',
  },
  '47302': {
    zip: '47302',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.171455',
    longitude: '-85.379033',
    timeZoneId: 'America/New_York',
  },
  '47303': {
    zip: '47303',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.218641',
    longitude: '-85.37752',
    timeZoneId: 'America/New_York',
  },
  '47304': {
    zip: '47304',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.22682',
    longitude: '-85.44594',
    timeZoneId: 'America/New_York',
  },
  '47305': {
    zip: '47305',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.193779',
    longitude: '-85.384433',
    timeZoneId: 'America/New_York',
  },
  '47306': {
    zip: '47306',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.20089',
    longitude: '-85.407098',
    timeZoneId: 'America/New_York',
  },
  '47307': {
    zip: '47307',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.177694',
    longitude: '-85.395063',
    timeZoneId: 'America/New_York',
  },
  '47308': {
    zip: '47308',
    city: 'Muncie',
    state: 'IN',
    latitude: '40.13032',
    longitude: '-85.379246',
    timeZoneId: 'America/New_York',
  },
  '47320': {
    zip: '47320',
    city: 'Albany',
    state: 'IN',
    latitude: '40.295554',
    longitude: '-85.241905',
    timeZoneId: 'America/New_York',
  },
  '47322': {
    zip: '47322',
    city: 'Bentonville',
    state: 'IN',
    latitude: '39.748489',
    longitude: '-85.241805',
    timeZoneId: 'America/New_York',
  },
  '47324': {
    zip: '47324',
    city: 'Boston',
    state: 'IN',
    latitude: '39.740984',
    longitude: '-84.852765',
    timeZoneId: 'America/New_York',
  },
  '47325': {
    zip: '47325',
    city: 'Brownsville',
    state: 'IN',
    latitude: '39.717506',
    longitude: '-84.963353',
    timeZoneId: 'America/New_York',
  },
  '47326': {
    zip: '47326',
    city: 'Bryant',
    state: 'IN',
    latitude: '40.536527',
    longitude: '-84.963031',
    timeZoneId: 'America/New_York',
  },
  '47327': {
    zip: '47327',
    city: 'Cambridge City',
    state: 'IN',
    latitude: '39.822189',
    longitude: '-85.164134',
    timeZoneId: 'America/New_York',
  },
  '47330': {
    zip: '47330',
    city: 'Centerville',
    state: 'IN',
    latitude: '39.797725',
    longitude: '-85.011958',
    timeZoneId: 'America/New_York',
  },
  '47331': {
    zip: '47331',
    city: 'Connersville',
    state: 'IN',
    latitude: '39.64482',
    longitude: '-85.15752',
    timeZoneId: 'America/New_York',
  },
  '47334': {
    zip: '47334',
    city: 'Daleville',
    state: 'IN',
    latitude: '40.122017',
    longitude: '-85.548158',
    timeZoneId: 'America/New_York',
  },
  '47335': {
    zip: '47335',
    city: 'Dublin',
    state: 'IN',
    latitude: '39.812592',
    longitude: '-85.20867',
    timeZoneId: 'America/New_York',
  },
  '47336': {
    zip: '47336',
    city: 'Dunkirk',
    state: 'IN',
    latitude: '40.374187',
    longitude: '-85.208851',
    timeZoneId: 'America/New_York',
  },
  '47337': {
    zip: '47337',
    city: 'Dunreith',
    state: 'IN',
    latitude: '39.805006',
    longitude: '-85.4381',
    timeZoneId: 'America/New_York',
  },
  '47338': {
    zip: '47338',
    city: 'Eaton',
    state: 'IN',
    latitude: '40.342989',
    longitude: '-85.35375',
    timeZoneId: 'America/New_York',
  },
  '47339': {
    zip: '47339',
    city: 'Economy',
    state: 'IN',
    latitude: '39.965896',
    longitude: '-85.092842',
    timeZoneId: 'America/New_York',
  },
  '47340': {
    zip: '47340',
    city: 'Farmland',
    state: 'IN',
    latitude: '40.181057',
    longitude: '-85.113281',
    timeZoneId: 'America/New_York',
  },
  '47341': {
    zip: '47341',
    city: 'Fountain City',
    state: 'IN',
    latitude: '39.967634',
    longitude: '-84.910903',
    timeZoneId: 'America/New_York',
  },
  '47342': {
    zip: '47342',
    city: 'Gaston',
    state: 'IN',
    latitude: '40.310795',
    longitude: '-85.502413',
    timeZoneId: 'America/New_York',
  },
  '47344': {
    zip: '47344',
    city: 'Greensboro',
    state: 'IN',
    latitude: '39.876621',
    longitude: '-85.461392',
    timeZoneId: 'America/New_York',
  },
  '47345': {
    zip: '47345',
    city: 'Greens Fork',
    state: 'IN',
    latitude: '39.896372',
    longitude: '-85.052265',
    timeZoneId: 'America/New_York',
  },
  '47346': {
    zip: '47346',
    city: 'Hagerstown',
    state: 'IN',
    latitude: '39.927181',
    longitude: '-85.153805',
    timeZoneId: 'America/New_York',
  },
  '47348': {
    zip: '47348',
    city: 'Hartford City',
    state: 'IN',
    latitude: '40.4664',
    longitude: '-85.36512',
    timeZoneId: 'America/New_York',
  },
  '47351': {
    zip: '47351',
    city: 'Kennard',
    state: 'IN',
    latitude: '39.901651',
    longitude: '-85.518515',
    timeZoneId: 'America/New_York',
  },
  '47352': {
    zip: '47352',
    city: 'Lewisville',
    state: 'IN',
    latitude: '39.804052',
    longitude: '-85.355093',
    timeZoneId: 'America/New_York',
  },
  '47353': {
    zip: '47353',
    city: 'Liberty',
    state: 'IN',
    latitude: '39.631241',
    longitude: '-84.928586',
    timeZoneId: 'America/New_York',
  },
  '47354': {
    zip: '47354',
    city: 'Losantville',
    state: 'IN',
    latitude: '40.03974',
    longitude: '-85.20192',
    timeZoneId: 'America/New_York',
  },
  '47355': {
    zip: '47355',
    city: 'Lynn',
    state: 'IN',
    latitude: '40.04957',
    longitude: '-84.940519',
    timeZoneId: 'America/New_York',
  },
  '47356': {
    zip: '47356',
    city: 'Middletown',
    state: 'IN',
    latitude: '40.056706',
    longitude: '-85.543297',
    timeZoneId: 'America/New_York',
  },
  '47357': {
    zip: '47357',
    city: 'Milton',
    state: 'IN',
    latitude: '39.767292',
    longitude: '-85.139144',
    timeZoneId: 'America/New_York',
  },
  '47358': {
    zip: '47358',
    city: 'Modoc',
    state: 'IN',
    latitude: '39.994052',
    longitude: '-85.080636',
    timeZoneId: 'America/New_York',
  },
  '47359': {
    zip: '47359',
    city: 'Montpelier',
    state: 'IN',
    latitude: '40.553124',
    longitude: '-85.277033',
    timeZoneId: 'America/New_York',
  },
  '47360': {
    zip: '47360',
    city: 'Mooreland',
    state: 'IN',
    latitude: '39.996465',
    longitude: '-85.246526',
    timeZoneId: 'America/New_York',
  },
  '47361': {
    zip: '47361',
    city: 'Mount Summit',
    state: 'IN',
    latitude: '40.005668',
    longitude: '-85.38673',
    timeZoneId: 'America/New_York',
  },
  '47362': {
    zip: '47362',
    city: 'New Castle',
    state: 'IN',
    latitude: '39.91956',
    longitude: '-85.34028',
    timeZoneId: 'America/New_York',
  },
  '47366': {
    zip: '47366',
    city: 'New Lisbon',
    state: 'IN',
    latitude: '39.861283',
    longitude: '-85.263377',
    timeZoneId: 'America/New_York',
  },
  '47367': {
    zip: '47367',
    city: 'Oakville',
    state: 'IN',
    latitude: '40.079318',
    longitude: '-85.389412',
    timeZoneId: 'America/New_York',
  },
  '47368': {
    zip: '47368',
    city: 'Parker City',
    state: 'IN',
    latitude: '40.192356',
    longitude: '-85.191566',
    timeZoneId: 'America/New_York',
  },
  '47369': {
    zip: '47369',
    city: 'Pennville',
    state: 'IN',
    latitude: '40.492708',
    longitude: '-85.14919',
    timeZoneId: 'America/New_York',
  },
  '47370': {
    zip: '47370',
    city: 'Pershing',
    state: 'IN',
    latitude: '39.815011',
    longitude: '-85.136637',
    timeZoneId: 'America/New_York',
  },
  '47371': {
    zip: '47371',
    city: 'Portland',
    state: 'IN',
    latitude: '40.42938',
    longitude: '-84.99858',
    timeZoneId: 'America/New_York',
  },
  '47373': {
    zip: '47373',
    city: 'Redkey',
    state: 'IN',
    latitude: '40.33926',
    longitude: '-85.15512',
    timeZoneId: 'America/New_York',
  },
  '47374': {
    zip: '47374',
    city: 'Richmond',
    state: 'IN',
    latitude: '39.83393',
    longitude: '-84.891208',
    timeZoneId: 'America/New_York',
  },
  '47375': {
    zip: '47375',
    city: 'Richmond',
    state: 'IN',
    latitude: '39.830703',
    longitude: '-84.894185',
    timeZoneId: 'America/New_York',
  },
  '47380': {
    zip: '47380',
    city: 'Ridgeville',
    state: 'IN',
    latitude: '40.29238',
    longitude: '-85.028052',
    timeZoneId: 'America/New_York',
  },
  '47381': {
    zip: '47381',
    city: 'Salamonia',
    state: 'IN',
    latitude: '40.350997',
    longitude: '-84.851952',
    timeZoneId: 'America/New_York',
  },
  '47382': {
    zip: '47382',
    city: 'Saratoga',
    state: 'IN',
    latitude: '40.236088',
    longitude: '-84.922295',
    timeZoneId: 'America/New_York',
  },
  '47383': {
    zip: '47383',
    city: 'Selma',
    state: 'IN',
    latitude: '40.185979',
    longitude: '-85.275224',
    timeZoneId: 'America/New_York',
  },
  '47384': {
    zip: '47384',
    city: 'Shirley',
    state: 'IN',
    latitude: '39.888672',
    longitude: '-85.580079',
    timeZoneId: 'America/New_York',
  },
  '47385': {
    zip: '47385',
    city: 'Spiceland',
    state: 'IN',
    latitude: '39.837003',
    longitude: '-85.438958',
    timeZoneId: 'America/New_York',
  },
  '47386': {
    zip: '47386',
    city: 'Springport',
    state: 'IN',
    latitude: '40.046633',
    longitude: '-85.394207',
    timeZoneId: 'America/New_York',
  },
  '47387': {
    zip: '47387',
    city: 'Straughn',
    state: 'IN',
    latitude: '39.829411',
    longitude: '-85.270894',
    timeZoneId: 'America/New_York',
  },
  '47388': {
    zip: '47388',
    city: 'Sulphur Springs',
    state: 'IN',
    latitude: '40.016569',
    longitude: '-85.44317',
    timeZoneId: 'America/New_York',
  },
  '47390': {
    zip: '47390',
    city: 'Union City',
    state: 'IN',
    latitude: '40.202822',
    longitude: '-84.817856',
    timeZoneId: 'America/New_York',
  },
  '47392': {
    zip: '47392',
    city: 'Webster',
    state: 'IN',
    latitude: '39.905961',
    longitude: '-84.929026',
    timeZoneId: 'America/New_York',
  },
  '47393': {
    zip: '47393',
    city: 'Williamsburg',
    state: 'IN',
    latitude: '39.951307',
    longitude: '-84.994159',
    timeZoneId: 'America/New_York',
  },
  '47394': {
    zip: '47394',
    city: 'Winchester',
    state: 'IN',
    latitude: '40.175183',
    longitude: '-84.976721',
    timeZoneId: 'America/New_York',
  },
  '47396': {
    zip: '47396',
    city: 'Yorktown',
    state: 'IN',
    latitude: '40.172376',
    longitude: '-85.487338',
    timeZoneId: 'America/New_York',
  },
  '47401': {
    zip: '47401',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.095259',
    longitude: '-86.457677',
    timeZoneId: 'America/New_York',
  },
  '47402': {
    zip: '47402',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.095551',
    longitude: '-86.472738',
    timeZoneId: 'America/New_York',
  },
  '47403': {
    zip: '47403',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.113644',
    longitude: '-86.586952',
    timeZoneId: 'America/New_York',
  },
  '47404': {
    zip: '47404',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.193029',
    longitude: '-86.575949',
    timeZoneId: 'America/New_York',
  },
  '47405': {
    zip: '47405',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.172055',
    longitude: '-86.523157',
    timeZoneId: 'America/New_York',
  },
  '47406': {
    zip: '47406',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.180192',
    longitude: '-86.512479',
    timeZoneId: 'America/New_York',
  },
  '47407': {
    zip: '47407',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.170043',
    longitude: '-86.495005',
    timeZoneId: 'America/New_York',
  },
  '47408': {
    zip: '47408',
    city: 'Bloomington',
    state: 'IN',
    latitude: '39.217645',
    longitude: '-86.458495',
    timeZoneId: 'America/New_York',
  },
  '47420': {
    zip: '47420',
    city: 'Avoca',
    state: 'IN',
    latitude: '38.951522',
    longitude: '-86.626391',
    timeZoneId: 'America/New_York',
  },
  '47421': {
    zip: '47421',
    city: 'Bedford',
    state: 'IN',
    latitude: '38.863336',
    longitude: '-86.490428',
    timeZoneId: 'America/New_York',
  },
  '47424': {
    zip: '47424',
    city: 'Bloomfield',
    state: 'IN',
    latitude: '39.02472',
    longitude: '-86.91306',
    timeZoneId: 'America/New_York',
  },
  '47426': {
    zip: '47426',
    city: 'Clear Creek',
    state: 'IN',
    latitude: '39.094733',
    longitude: '-86.462329',
    timeZoneId: 'America/New_York',
  },
  '47427': {
    zip: '47427',
    city: 'Coal City',
    state: 'IN',
    latitude: '39.249277',
    longitude: '-86.989828',
    timeZoneId: 'America/New_York',
  },
  '47429': {
    zip: '47429',
    city: 'Ellettsville',
    state: 'IN',
    latitude: '39.247016',
    longitude: '-86.620515',
    timeZoneId: 'America/New_York',
  },
  '47431': {
    zip: '47431',
    city: 'Freedom',
    state: 'IN',
    latitude: '39.211607',
    longitude: '-86.851623',
    timeZoneId: 'America/New_York',
  },
  '47432': {
    zip: '47432',
    city: 'French Lick',
    state: 'IN',
    latitude: '38.545144',
    longitude: '-86.619616',
    timeZoneId: 'America/New_York',
  },
  '47433': {
    zip: '47433',
    city: 'Gosport',
    state: 'IN',
    latitude: '39.302962',
    longitude: '-86.623199',
    timeZoneId: 'America/New_York',
  },
  '47434': {
    zip: '47434',
    city: 'Harrodsburg',
    state: 'IN',
    latitude: '39.015056',
    longitude: '-86.542106',
    timeZoneId: 'America/New_York',
  },
  '47435': {
    zip: '47435',
    city: 'Helmsburg',
    state: 'IN',
    latitude: '39.310662',
    longitude: '-86.313707',
    timeZoneId: 'America/New_York',
  },
  '47436': {
    zip: '47436',
    city: 'Heltonville',
    state: 'IN',
    latitude: '39.003996',
    longitude: '-86.436004',
    timeZoneId: 'America/New_York',
  },
  '47437': {
    zip: '47437',
    city: 'Huron',
    state: 'IN',
    latitude: '38.772039',
    longitude: '-86.6285',
    timeZoneId: 'America/New_York',
  },
  '47438': {
    zip: '47438',
    city: 'Jasonville',
    state: 'IN',
    latitude: '39.163489',
    longitude: '-87.199212',
    timeZoneId: 'America/New_York',
  },
  '47441': {
    zip: '47441',
    city: 'Linton',
    state: 'IN',
    latitude: '39.0732',
    longitude: '-87.17046',
    timeZoneId: 'America/New_York',
  },
  '47443': {
    zip: '47443',
    city: 'Lyons',
    state: 'IN',
    latitude: '38.989429',
    longitude: '-87.083684',
    timeZoneId: 'America/New_York',
  },
  '47445': {
    zip: '47445',
    city: 'Midland',
    state: 'IN',
    latitude: '39.059161',
    longitude: '-87.18714',
    timeZoneId: 'America/New_York',
  },
  '47446': {
    zip: '47446',
    city: 'Mitchell',
    state: 'IN',
    latitude: '38.735506',
    longitude: '-86.473305',
    timeZoneId: 'America/New_York',
  },
  '47448': {
    zip: '47448',
    city: 'Nashville',
    state: 'IN',
    latitude: '39.1926',
    longitude: '-86.2206',
    timeZoneId: 'America/New_York',
  },
  '47449': {
    zip: '47449',
    city: 'Newberry',
    state: 'IN',
    latitude: '38.926065',
    longitude: '-87.009605',
    timeZoneId: 'America/New_York',
  },
  '47451': {
    zip: '47451',
    city: 'Oolitic',
    state: 'IN',
    latitude: '38.896406',
    longitude: '-86.528488',
    timeZoneId: 'America/New_York',
  },
  '47452': {
    zip: '47452',
    city: 'Orleans',
    state: 'IN',
    latitude: '38.662839',
    longitude: '-86.450428',
    timeZoneId: 'America/New_York',
  },
  '47453': {
    zip: '47453',
    city: 'Owensburg',
    state: 'IN',
    latitude: '38.946534',
    longitude: '-86.755017',
    timeZoneId: 'America/New_York',
  },
  '47454': {
    zip: '47454',
    city: 'Paoli',
    state: 'IN',
    latitude: '38.555193',
    longitude: '-86.468358',
    timeZoneId: 'America/New_York',
  },
  '47455': {
    zip: '47455',
    city: 'Patricksburg',
    state: 'IN',
    latitude: '39.247894',
    longitude: '-86.988727',
    timeZoneId: 'America/New_York',
  },
  '47456': {
    zip: '47456',
    city: 'Quincy',
    state: 'IN',
    latitude: '39.435138',
    longitude: '-86.786652',
    timeZoneId: 'America/New_York',
  },
  '47457': {
    zip: '47457',
    city: 'Scotland',
    state: 'IN',
    latitude: '39.032752',
    longitude: '-86.854951',
    timeZoneId: 'America/New_York',
  },
  '47458': {
    zip: '47458',
    city: 'Smithville',
    state: 'IN',
    latitude: '39.066787',
    longitude: '-86.505402',
    timeZoneId: 'America/New_York',
  },
  '47459': {
    zip: '47459',
    city: 'Solsberry',
    state: 'IN',
    latitude: '39.123646',
    longitude: '-86.753712',
    timeZoneId: 'America/New_York',
  },
  '47460': {
    zip: '47460',
    city: 'Spencer',
    state: 'IN',
    latitude: '39.28962',
    longitude: '-86.74866',
    timeZoneId: 'America/New_York',
  },
  '47462': {
    zip: '47462',
    city: 'Springville',
    state: 'IN',
    latitude: '39.003349',
    longitude: '-86.589842',
    timeZoneId: 'America/New_York',
  },
  '47463': {
    zip: '47463',
    city: 'Stanford',
    state: 'IN',
    latitude: '39.095244',
    longitude: '-86.664081',
    timeZoneId: 'America/New_York',
  },
  '47464': {
    zip: '47464',
    city: 'Stinesville',
    state: 'IN',
    latitude: '39.299521',
    longitude: '-86.647398',
    timeZoneId: 'America/New_York',
  },
  '47465': {
    zip: '47465',
    city: 'Switz City',
    state: 'IN',
    latitude: '39.038049',
    longitude: '-87.047701',
    timeZoneId: 'America/New_York',
  },
  '47467': {
    zip: '47467',
    city: 'Tunnelton',
    state: 'IN',
    latitude: '38.872044',
    longitude: '-86.456458',
    timeZoneId: 'America/New_York',
  },
  '47468': {
    zip: '47468',
    city: 'Unionville',
    state: 'IN',
    latitude: '39.261476',
    longitude: '-86.412024',
    timeZoneId: 'America/New_York',
  },
  '47469': {
    zip: '47469',
    city: 'West Baden Springs',
    state: 'IN',
    latitude: '38.57838',
    longitude: '-86.61',
    timeZoneId: 'America/New_York',
  },
  '47470': {
    zip: '47470',
    city: 'Williams',
    state: 'IN',
    latitude: '38.770024',
    longitude: '-86.627519',
    timeZoneId: 'America/New_York',
  },
  '47471': {
    zip: '47471',
    city: 'Worthington',
    state: 'IN',
    latitude: '39.118499',
    longitude: '-86.979363',
    timeZoneId: 'America/New_York',
  },
  '47501': {
    zip: '47501',
    city: 'Washington',
    state: 'IN',
    latitude: '38.65801',
    longitude: '-87.171087',
    timeZoneId: 'America/New_York',
  },
  '47512': {
    zip: '47512',
    city: 'Bicknell',
    state: 'IN',
    latitude: '38.772909',
    longitude: '-87.307853',
    timeZoneId: 'America/New_York',
  },
  '47513': {
    zip: '47513',
    city: 'Birdseye',
    state: 'IN',
    latitude: '38.28332',
    longitude: '-86.714517',
    timeZoneId: 'America/New_York',
  },
  '47514': {
    zip: '47514',
    city: 'Branchville',
    state: 'IN',
    latitude: '38.1711',
    longitude: '-86.56938',
    timeZoneId: 'America/Chicago',
  },
  '47515': {
    zip: '47515',
    city: 'Bristow',
    state: 'IN',
    latitude: '38.16764',
    longitude: '-86.715843',
    timeZoneId: 'America/Chicago',
  },
  '47516': {
    zip: '47516',
    city: 'Bruceville',
    state: 'IN',
    latitude: '38.758593',
    longitude: '-87.435699',
    timeZoneId: 'America/New_York',
  },
  '47519': {
    zip: '47519',
    city: 'Cannelburg',
    state: 'IN',
    latitude: '38.697215',
    longitude: '-86.969122',
    timeZoneId: 'America/New_York',
  },
  '47520': {
    zip: '47520',
    city: 'Cannelton',
    state: 'IN',
    latitude: '38.09651',
    longitude: '-86.46327',
    timeZoneId: 'America/Chicago',
  },
  '47521': {
    zip: '47521',
    city: 'Celestine',
    state: 'IN',
    latitude: '38.392127',
    longitude: '-86.734494',
    timeZoneId: 'America/New_York',
  },
  '47522': {
    zip: '47522',
    city: 'Crane',
    state: 'IN',
    latitude: '38.896688',
    longitude: '-86.900331',
    timeZoneId: 'America/New_York',
  },
  '47523': {
    zip: '47523',
    city: 'Dale',
    state: 'IN',
    latitude: '38.17326',
    longitude: '-86.99946',
    timeZoneId: 'America/Chicago',
  },
  '47524': {
    zip: '47524',
    city: 'Decker',
    state: 'IN',
    latitude: '38.486075',
    longitude: '-87.622339',
    timeZoneId: 'America/New_York',
  },
  '47525': {
    zip: '47525',
    city: 'Derby',
    state: 'IN',
    latitude: '38.029575',
    longitude: '-86.566656',
    timeZoneId: 'America/Chicago',
  },
  '47527': {
    zip: '47527',
    city: 'Dubois',
    state: 'IN',
    latitude: '38.472743',
    longitude: '-86.782697',
    timeZoneId: 'America/New_York',
  },
  '47528': {
    zip: '47528',
    city: 'Edwardsport',
    state: 'IN',
    latitude: '38.813066',
    longitude: '-87.243531',
    timeZoneId: 'America/New_York',
  },
  '47529': {
    zip: '47529',
    city: 'Elnora',
    state: 'IN',
    latitude: '38.875867',
    longitude: '-87.084533',
    timeZoneId: 'America/New_York',
  },
  '47531': {
    zip: '47531',
    city: 'Evanston',
    state: 'IN',
    latitude: '38.023906',
    longitude: '-86.827683',
    timeZoneId: 'America/Chicago',
  },
  '47532': {
    zip: '47532',
    city: 'Ferdinand',
    state: 'IN',
    latitude: '38.226646',
    longitude: '-86.862058',
    timeZoneId: 'America/New_York',
  },
  '47535': {
    zip: '47535',
    city: 'Freelandville',
    state: 'IN',
    latitude: '38.849864',
    longitude: '-87.415417',
    timeZoneId: 'America/New_York',
  },
  '47536': {
    zip: '47536',
    city: 'Fulda',
    state: 'IN',
    latitude: '38.137904',
    longitude: '-86.851121',
    timeZoneId: 'America/Chicago',
  },
  '47537': {
    zip: '47537',
    city: 'Gentryville',
    state: 'IN',
    latitude: '38.21807',
    longitude: '-86.862317',
    timeZoneId: 'America/New_York',
  },
  '47541': {
    zip: '47541',
    city: 'Holland',
    state: 'IN',
    latitude: '38.246997',
    longitude: '-87.040762',
    timeZoneId: 'America/New_York',
  },
  '47542': {
    zip: '47542',
    city: 'Huntingburg',
    state: 'IN',
    latitude: '38.297169',
    longitude: '-86.95669',
    timeZoneId: 'America/New_York',
  },
  '47545': {
    zip: '47545',
    city: 'Ireland',
    state: 'IN',
    latitude: '38.291263',
    longitude: '-86.960857',
    timeZoneId: 'America/New_York',
  },
  '47546': {
    zip: '47546',
    city: 'Jasper',
    state: 'IN',
    latitude: '38.39544',
    longitude: '-86.932333',
    timeZoneId: 'America/New_York',
  },
  '47547': {
    zip: '47547',
    city: 'Jasper',
    state: 'IN',
    latitude: '38.420527',
    longitude: '-86.963204',
    timeZoneId: 'America/New_York',
  },
  '47549': {
    zip: '47549',
    city: 'Jasper',
    state: 'IN',
    latitude: '38.392247',
    longitude: '-86.955378',
    timeZoneId: 'America/New_York',
  },
  '47550': {
    zip: '47550',
    city: 'Lamar',
    state: 'IN',
    latitude: '38.05242',
    longitude: '-86.96652',
    timeZoneId: 'America/Chicago',
  },
  '47551': {
    zip: '47551',
    city: 'Leopold',
    state: 'IN',
    latitude: '38.100381',
    longitude: '-86.605274',
    timeZoneId: 'America/Chicago',
  },
  '47552': {
    zip: '47552',
    city: 'Lincoln City',
    state: 'IN',
    latitude: '38.12941',
    longitude: '-86.988319',
    timeZoneId: 'America/Chicago',
  },
  '47553': {
    zip: '47553',
    city: 'Loogootee',
    state: 'IN',
    latitude: '38.8225',
    longitude: '-86.886944',
    timeZoneId: 'America/New_York',
  },
  '47556': {
    zip: '47556',
    city: 'Mariah Hill',
    state: 'IN',
    latitude: '38.13606',
    longitude: '-86.849362',
    timeZoneId: 'America/Chicago',
  },
  '47557': {
    zip: '47557',
    city: 'Monroe City',
    state: 'IN',
    latitude: '38.580666',
    longitude: '-87.353511',
    timeZoneId: 'America/New_York',
  },
  '47558': {
    zip: '47558',
    city: 'Montgomery',
    state: 'IN',
    latitude: '38.623171',
    longitude: '-87.044863',
    timeZoneId: 'America/New_York',
  },
  '47561': {
    zip: '47561',
    city: 'Oaktown',
    state: 'IN',
    latitude: '38.851331',
    longitude: '-87.415278',
    timeZoneId: 'America/New_York',
  },
  '47562': {
    zip: '47562',
    city: 'Odon',
    state: 'IN',
    latitude: '38.842918',
    longitude: '-86.990039',
    timeZoneId: 'America/New_York',
  },
  '47564': {
    zip: '47564',
    city: 'Otwell',
    state: 'IN',
    latitude: '38.478071',
    longitude: '-87.099267',
    timeZoneId: 'America/New_York',
  },
  '47567': {
    zip: '47567',
    city: 'Petersburg',
    state: 'IN',
    latitude: '38.4654',
    longitude: '-87.3207',
    timeZoneId: 'America/New_York',
  },
  '47568': {
    zip: '47568',
    city: 'Plainville',
    state: 'IN',
    latitude: '38.777115',
    longitude: '-87.180839',
    timeZoneId: 'America/New_York',
  },
  '47573': {
    zip: '47573',
    city: 'Ragsdale',
    state: 'IN',
    latitude: '38.772302',
    longitude: '-87.327969',
    timeZoneId: 'America/New_York',
  },
  '47574': {
    zip: '47574',
    city: 'Rome',
    state: 'IN',
    latitude: '37.947643',
    longitude: '-86.588212',
    timeZoneId: 'America/Chicago',
  },
  '47575': {
    zip: '47575',
    city: 'Saint Anthony',
    state: 'IN',
    latitude: '38.333863',
    longitude: '-86.821988',
    timeZoneId: 'America/New_York',
  },
  '47576': {
    zip: '47576',
    city: 'Saint Croix',
    state: 'IN',
    latitude: '38.227647',
    longitude: '-86.609796',
    timeZoneId: 'America/Chicago',
  },
  '47577': {
    zip: '47577',
    city: 'Saint Meinrad',
    state: 'IN',
    latitude: '38.186231',
    longitude: '-86.847745',
    timeZoneId: 'America/Chicago',
  },
  '47578': {
    zip: '47578',
    city: 'Sandborn',
    state: 'IN',
    latitude: '38.89795',
    longitude: '-87.185026',
    timeZoneId: 'America/New_York',
  },
  '47579': {
    zip: '47579',
    city: 'Santa Claus',
    state: 'IN',
    latitude: '38.104943',
    longitude: '-86.928717',
    timeZoneId: 'America/Chicago',
  },
  '47580': {
    zip: '47580',
    city: 'Schnellville',
    state: 'IN',
    latitude: '38.342295',
    longitude: '-86.755119',
    timeZoneId: 'America/New_York',
  },
  '47581': {
    zip: '47581',
    city: 'Shoals',
    state: 'IN',
    latitude: '38.75',
    longitude: '-86.766667',
    timeZoneId: 'America/New_York',
  },
  '47584': {
    zip: '47584',
    city: 'Spurgeon',
    state: 'IN',
    latitude: '38.348983',
    longitude: '-87.222007',
    timeZoneId: 'America/New_York',
  },
  '47585': {
    zip: '47585',
    city: 'Stendal',
    state: 'IN',
    latitude: '38.277754',
    longitude: '-87.128319',
    timeZoneId: 'America/New_York',
  },
  '47586': {
    zip: '47586',
    city: 'Tell City',
    state: 'IN',
    latitude: '37.953864',
    longitude: '-86.762258',
    timeZoneId: 'America/Chicago',
  },
  '47588': {
    zip: '47588',
    city: 'Troy',
    state: 'IN',
    latitude: '37.996498',
    longitude: '-86.801594',
    timeZoneId: 'America/Chicago',
  },
  '47590': {
    zip: '47590',
    city: 'Velpen',
    state: 'IN',
    latitude: '38.376792',
    longitude: '-87.100893',
    timeZoneId: 'America/New_York',
  },
  '47591': {
    zip: '47591',
    city: 'Vincennes',
    state: 'IN',
    latitude: '38.680278',
    longitude: '-87.423056',
    timeZoneId: 'America/New_York',
  },
  '47596': {
    zip: '47596',
    city: 'Westphalia',
    state: 'IN',
    latitude: '38.871663',
    longitude: '-87.203975',
    timeZoneId: 'America/New_York',
  },
  '47597': {
    zip: '47597',
    city: 'Wheatland',
    state: 'IN',
    latitude: '38.669385',
    longitude: '-87.298788',
    timeZoneId: 'America/New_York',
  },
  '47598': {
    zip: '47598',
    city: 'Winslow',
    state: 'IN',
    latitude: '38.3625',
    longitude: '-87.21756',
    timeZoneId: 'America/New_York',
  },
  '47601': {
    zip: '47601',
    city: 'Boonville',
    state: 'IN',
    latitude: '38.05728',
    longitude: '-87.26244',
    timeZoneId: 'America/Chicago',
  },
  '47610': {
    zip: '47610',
    city: 'Chandler',
    state: 'IN',
    latitude: '38.036425',
    longitude: '-87.376602',
    timeZoneId: 'America/Chicago',
  },
  '47611': {
    zip: '47611',
    city: 'Chrisney',
    state: 'IN',
    latitude: '38.011983',
    longitude: '-87.032495',
    timeZoneId: 'America/Chicago',
  },
  '47612': {
    zip: '47612',
    city: 'Cynthiana',
    state: 'IN',
    latitude: '38.171264',
    longitude: '-87.692616',
    timeZoneId: 'America/Chicago',
  },
  '47613': {
    zip: '47613',
    city: 'Elberfeld',
    state: 'IN',
    latitude: '38.160224',
    longitude: '-87.449707',
    timeZoneId: 'America/Chicago',
  },
  '47615': {
    zip: '47615',
    city: 'Grandview',
    state: 'IN',
    latitude: '37.9749',
    longitude: '-86.94702',
    timeZoneId: 'America/Chicago',
  },
  '47616': {
    zip: '47616',
    city: 'Griffin',
    state: 'IN',
    latitude: '38.20929',
    longitude: '-87.927619',
    timeZoneId: 'America/Chicago',
  },
  '47617': {
    zip: '47617',
    city: 'Hatfield',
    state: 'IN',
    latitude: '37.910925',
    longitude: '-87.203235',
    timeZoneId: 'America/Chicago',
  },
  '47618': {
    zip: '47618',
    city: 'Inglefield',
    state: 'IN',
    latitude: '38.110743',
    longitude: '-87.560239',
    timeZoneId: 'America/Chicago',
  },
  '47619': {
    zip: '47619',
    city: 'Lynnville',
    state: 'IN',
    latitude: '38.1917',
    longitude: '-87.292118',
    timeZoneId: 'America/Chicago',
  },
  '47620': {
    zip: '47620',
    city: 'Mount Vernon',
    state: 'IN',
    latitude: '37.950895',
    longitude: '-87.86209',
    timeZoneId: 'America/Chicago',
  },
  '47629': {
    zip: '47629',
    city: 'Newburgh',
    state: 'IN',
    latitude: '37.956025',
    longitude: '-87.401574',
    timeZoneId: 'America/Chicago',
  },
  '47630': {
    zip: '47630',
    city: 'Newburgh',
    state: 'IN',
    latitude: '37.96434',
    longitude: '-87.35412',
    timeZoneId: 'America/Chicago',
  },
  '47631': {
    zip: '47631',
    city: 'New Harmony',
    state: 'IN',
    latitude: '38.116261',
    longitude: '-87.922878',
    timeZoneId: 'America/Chicago',
  },
  '47633': {
    zip: '47633',
    city: 'Poseyville',
    state: 'IN',
    latitude: '38.155388',
    longitude: '-87.775202',
    timeZoneId: 'America/Chicago',
  },
  '47634': {
    zip: '47634',
    city: 'Richland',
    state: 'IN',
    latitude: '37.912579',
    longitude: '-87.201577',
    timeZoneId: 'America/Chicago',
  },
  '47635': {
    zip: '47635',
    city: 'Rockport',
    state: 'IN',
    latitude: '37.89552',
    longitude: '-87.06078',
    timeZoneId: 'America/Chicago',
  },
  '47637': {
    zip: '47637',
    city: 'Tennyson',
    state: 'IN',
    latitude: '38.12874',
    longitude: '-87.148763',
    timeZoneId: 'America/Chicago',
  },
  '47638': {
    zip: '47638',
    city: 'Wadesville',
    state: 'IN',
    latitude: '38.075839',
    longitude: '-87.761568',
    timeZoneId: 'America/Chicago',
  },
  '47639': {
    zip: '47639',
    city: 'Haubstadt',
    state: 'IN',
    latitude: '38.1846',
    longitude: '-87.62592',
    timeZoneId: 'America/Chicago',
  },
  '47640': {
    zip: '47640',
    city: 'Hazleton',
    state: 'IN',
    latitude: '38.489588',
    longitude: '-87.542063',
    timeZoneId: 'America/Chicago',
  },
  '47647': {
    zip: '47647',
    city: 'Buckskin',
    state: 'IN',
    latitude: '38.229577',
    longitude: '-87.445172',
    timeZoneId: 'America/Chicago',
  },
  '47648': {
    zip: '47648',
    city: 'Fort Branch',
    state: 'IN',
    latitude: '38.245368',
    longitude: '-87.578368',
    timeZoneId: 'America/Chicago',
  },
  '47649': {
    zip: '47649',
    city: 'Francisco',
    state: 'IN',
    latitude: '38.334511',
    longitude: '-87.445445',
    timeZoneId: 'America/Chicago',
  },
  '47654': {
    zip: '47654',
    city: 'Mackey',
    state: 'IN',
    latitude: '38.251669',
    longitude: '-87.390716',
    timeZoneId: 'America/Chicago',
  },
  '47660': {
    zip: '47660',
    city: 'Oakland City',
    state: 'IN',
    latitude: '38.336755',
    longitude: '-87.352284',
    timeZoneId: 'America/Chicago',
  },
  '47665': {
    zip: '47665',
    city: 'Owensville',
    state: 'IN',
    latitude: '38.274334',
    longitude: '-87.691786',
    timeZoneId: 'America/Chicago',
  },
  '47666': {
    zip: '47666',
    city: 'Patoka',
    state: 'IN',
    latitude: '38.407821',
    longitude: '-87.58598',
    timeZoneId: 'America/Chicago',
  },
  '47670': {
    zip: '47670',
    city: 'Princeton',
    state: 'IN',
    latitude: '38.353193',
    longitude: '-87.567259',
    timeZoneId: 'America/Chicago',
  },
  '47683': {
    zip: '47683',
    city: 'Somerville',
    state: 'IN',
    latitude: '38.280726',
    longitude: '-87.379386',
    timeZoneId: 'America/Chicago',
  },
  '47701': {
    zip: '47701',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.977344',
    longitude: '-87.572423',
    timeZoneId: 'America/Chicago',
  },
  '47702': {
    zip: '47702',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.954299',
    longitude: '-87.558781',
    timeZoneId: 'America/Chicago',
  },
  '47703': {
    zip: '47703',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.954521',
    longitude: '-87.55955',
    timeZoneId: 'America/Chicago',
  },
  '47704': {
    zip: '47704',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.95697',
    longitude: '-87.55987',
    timeZoneId: 'America/Chicago',
  },
  '47705': {
    zip: '47705',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.955129',
    longitude: '-87.55821',
    timeZoneId: 'America/Chicago',
  },
  '47706': {
    zip: '47706',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.95693',
    longitude: '-87.558672',
    timeZoneId: 'America/Chicago',
  },
  '47708': {
    zip: '47708',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.971882',
    longitude: '-87.57506',
    timeZoneId: 'America/Chicago',
  },
  '47710': {
    zip: '47710',
    city: 'Evansville',
    state: 'IN',
    latitude: '38.011789',
    longitude: '-87.579506',
    timeZoneId: 'America/Chicago',
  },
  '47711': {
    zip: '47711',
    city: 'Evansville',
    state: 'IN',
    latitude: '38.029596',
    longitude: '-87.53239',
    timeZoneId: 'America/Chicago',
  },
  '47712': {
    zip: '47712',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.991451',
    longitude: '-87.637797',
    timeZoneId: 'America/Chicago',
  },
  '47713': {
    zip: '47713',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.964898',
    longitude: '-87.556864',
    timeZoneId: 'America/Chicago',
  },
  '47714': {
    zip: '47714',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.957902',
    longitude: '-87.524265',
    timeZoneId: 'America/Chicago',
  },
  '47715': {
    zip: '47715',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.969963',
    longitude: '-87.484049',
    timeZoneId: 'America/Chicago',
  },
  '47716': {
    zip: '47716',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.961823',
    longitude: '-87.492329',
    timeZoneId: 'America/Chicago',
  },
  '47719': {
    zip: '47719',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.986573',
    longitude: '-87.595529',
    timeZoneId: 'America/Chicago',
  },
  '47720': {
    zip: '47720',
    city: 'Evansville',
    state: 'IN',
    latitude: '38.032487',
    longitude: '-87.631363',
    timeZoneId: 'America/Chicago',
  },
  '47721': {
    zip: '47721',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.915026',
    longitude: '-87.648785',
    timeZoneId: 'America/Chicago',
  },
  '47722': {
    zip: '47722',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.974242',
    longitude: '-87.535628',
    timeZoneId: 'America/Chicago',
  },
  '47724': {
    zip: '47724',
    city: 'Evansville',
    state: 'IN',
    latitude: '38.000309',
    longitude: '-87.571626',
    timeZoneId: 'America/Chicago',
  },
  '47725': {
    zip: '47725',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.974596',
    longitude: '-87.555538',
    timeZoneId: 'America/Chicago',
  },
  '47728': {
    zip: '47728',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.946514',
    longitude: '-87.527325',
    timeZoneId: 'America/Chicago',
  },
  '47730': {
    zip: '47730',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.973685',
    longitude: '-87.574227',
    timeZoneId: 'America/Chicago',
  },
  '47731': {
    zip: '47731',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.974343',
    longitude: '-87.574709',
    timeZoneId: 'America/Chicago',
  },
  '47732': {
    zip: '47732',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.973198',
    longitude: '-87.572339',
    timeZoneId: 'America/Chicago',
  },
  '47733': {
    zip: '47733',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.974042',
    longitude: '-87.573396',
    timeZoneId: 'America/Chicago',
  },
  '47734': {
    zip: '47734',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.972826',
    longitude: '-87.571062',
    timeZoneId: 'America/Chicago',
  },
  '47735': {
    zip: '47735',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.974147',
    longitude: '-87.571111',
    timeZoneId: 'America/Chicago',
  },
  '47736': {
    zip: '47736',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.95332',
    longitude: '-87.561824',
    timeZoneId: 'America/Chicago',
  },
  '47737': {
    zip: '47737',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.973127',
    longitude: '-87.573374',
    timeZoneId: 'America/Chicago',
  },
  '47740': {
    zip: '47740',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.976389',
    longitude: '-87.585504',
    timeZoneId: 'America/Chicago',
  },
  '47747': {
    zip: '47747',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.982273',
    longitude: '-87.567901',
    timeZoneId: 'America/Chicago',
  },
  '47750': {
    zip: '47750',
    city: 'Evansville',
    state: 'IN',
    latitude: '37.961584',
    longitude: '-87.508593',
    timeZoneId: 'America/Chicago',
  },
  '47801': {
    zip: '47801',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.467121',
    longitude: '-87.410139',
    timeZoneId: 'America/New_York',
  },
  '47802': {
    zip: '47802',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.42715',
    longitude: '-87.391878',
    timeZoneId: 'America/New_York',
  },
  '47803': {
    zip: '47803',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.465549',
    longitude: '-87.354311',
    timeZoneId: 'America/New_York',
  },
  '47804': {
    zip: '47804',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.49595',
    longitude: '-87.391373',
    timeZoneId: 'America/New_York',
  },
  '47805': {
    zip: '47805',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.529334',
    longitude: '-87.367163',
    timeZoneId: 'America/New_York',
  },
  '47807': {
    zip: '47807',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.473138',
    longitude: '-87.396567',
    timeZoneId: 'America/New_York',
  },
  '47808': {
    zip: '47808',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.512735',
    longitude: '-87.351994',
    timeZoneId: 'America/New_York',
  },
  '47809': {
    zip: '47809',
    city: 'Terre Haute',
    state: 'IN',
    latitude: '39.470391',
    longitude: '-87.409294',
    timeZoneId: 'America/New_York',
  },
  '47831': {
    zip: '47831',
    city: 'Blanford',
    state: 'IN',
    latitude: '39.649794',
    longitude: '-87.459919',
    timeZoneId: 'America/New_York',
  },
  '47832': {
    zip: '47832',
    city: 'Bloomingdale',
    state: 'IN',
    latitude: '39.844258',
    longitude: '-87.267425',
    timeZoneId: 'America/New_York',
  },
  '47833': {
    zip: '47833',
    city: 'Bowling Green',
    state: 'IN',
    latitude: '39.47514',
    longitude: '-87.107054',
    timeZoneId: 'America/New_York',
  },
  '47834': {
    zip: '47834',
    city: 'Brazil',
    state: 'IN',
    latitude: '39.52512',
    longitude: '-87.1692',
    timeZoneId: 'America/New_York',
  },
  '47836': {
    zip: '47836',
    city: 'Bridgeton',
    state: 'IN',
    latitude: '39.649687',
    longitude: '-87.183167',
    timeZoneId: 'America/New_York',
  },
  '47837': {
    zip: '47837',
    city: 'Carbon',
    state: 'IN',
    latitude: '39.576608',
    longitude: '-87.056532',
    timeZoneId: 'America/New_York',
  },
  '47838': {
    zip: '47838',
    city: 'Carlisle',
    state: 'IN',
    latitude: '39.117887',
    longitude: '-87.465105',
    timeZoneId: 'America/New_York',
  },
  '47840': {
    zip: '47840',
    city: 'Centerpoint',
    state: 'IN',
    latitude: '39.376944',
    longitude: '-87.100716',
    timeZoneId: 'America/New_York',
  },
  '47841': {
    zip: '47841',
    city: 'Clay City',
    state: 'IN',
    latitude: '39.273442',
    longitude: '-87.115129',
    timeZoneId: 'America/New_York',
  },
  '47842': {
    zip: '47842',
    city: 'Clinton',
    state: 'IN',
    latitude: '39.64944',
    longitude: '-87.42372',
    timeZoneId: 'America/New_York',
  },
  '47845': {
    zip: '47845',
    city: 'Coalmont',
    state: 'IN',
    latitude: '39.198875',
    longitude: '-87.186742',
    timeZoneId: 'America/New_York',
  },
  '47846': {
    zip: '47846',
    city: 'Cory',
    state: 'IN',
    latitude: '39.381462',
    longitude: '-87.201497',
    timeZoneId: 'America/New_York',
  },
  '47847': {
    zip: '47847',
    city: 'Dana',
    state: 'IN',
    latitude: '39.807913',
    longitude: '-87.490923',
    timeZoneId: 'America/New_York',
  },
  '47848': {
    zip: '47848',
    city: 'Dugger',
    state: 'IN',
    latitude: '39.064996',
    longitude: '-87.253689',
    timeZoneId: 'America/New_York',
  },
  '47849': {
    zip: '47849',
    city: 'Fairbanks',
    state: 'IN',
    latitude: '39.214283',
    longitude: '-87.524549',
    timeZoneId: 'America/New_York',
  },
  '47850': {
    zip: '47850',
    city: 'Farmersburg',
    state: 'IN',
    latitude: '39.251872',
    longitude: '-87.37823',
    timeZoneId: 'America/New_York',
  },
  '47851': {
    zip: '47851',
    city: 'Fontanet',
    state: 'IN',
    latitude: '39.543778',
    longitude: '-87.319509',
    timeZoneId: 'America/New_York',
  },
  '47852': {
    zip: '47852',
    city: 'Graysville',
    state: 'IN',
    latitude: '39.106009',
    longitude: '-87.433786',
    timeZoneId: 'America/New_York',
  },
  '47853': {
    zip: '47853',
    city: 'Harmony',
    state: 'IN',
    latitude: '39.576142',
    longitude: '-87.056265',
    timeZoneId: 'America/New_York',
  },
  '47854': {
    zip: '47854',
    city: 'Hillsdale',
    state: 'IN',
    latitude: '39.763883',
    longitude: '-87.41016',
    timeZoneId: 'America/New_York',
  },
  '47855': {
    zip: '47855',
    city: 'Hymera',
    state: 'IN',
    latitude: '39.182538',
    longitude: '-87.299357',
    timeZoneId: 'America/New_York',
  },
  '47857': {
    zip: '47857',
    city: 'Knightsville',
    state: 'IN',
    latitude: '39.575049',
    longitude: '-87.056992',
    timeZoneId: 'America/New_York',
  },
  '47858': {
    zip: '47858',
    city: 'Lewis',
    state: 'IN',
    latitude: '39.284987',
    longitude: '-87.269499',
    timeZoneId: 'America/New_York',
  },
  '47859': {
    zip: '47859',
    city: 'Marshall',
    state: 'IN',
    latitude: '39.910754',
    longitude: '-87.172148',
    timeZoneId: 'America/New_York',
  },
  '47860': {
    zip: '47860',
    city: 'Mecca',
    state: 'IN',
    latitude: '39.771195',
    longitude: '-87.168232',
    timeZoneId: 'America/New_York',
  },
  '47861': {
    zip: '47861',
    city: 'Merom',
    state: 'IN',
    latitude: '39.014376',
    longitude: '-87.525993',
    timeZoneId: 'America/New_York',
  },
  '47862': {
    zip: '47862',
    city: 'Montezuma',
    state: 'IN',
    latitude: '39.789289',
    longitude: '-87.373004',
    timeZoneId: 'America/New_York',
  },
  '47863': {
    zip: '47863',
    city: 'New Goshen',
    state: 'IN',
    latitude: '39.512211',
    longitude: '-87.476732',
    timeZoneId: 'America/New_York',
  },
  '47865': {
    zip: '47865',
    city: 'Paxton',
    state: 'IN',
    latitude: '38.961776',
    longitude: '-87.355067',
    timeZoneId: 'America/New_York',
  },
  '47866': {
    zip: '47866',
    city: 'Pimento',
    state: 'IN',
    latitude: '39.285986',
    longitude: '-87.379085',
    timeZoneId: 'America/New_York',
  },
  '47868': {
    zip: '47868',
    city: 'Poland',
    state: 'IN',
    latitude: '39.450742',
    longitude: '-86.974712',
    timeZoneId: 'America/New_York',
  },
  '47869': {
    zip: '47869',
    city: 'Prairie Creek',
    state: 'IN',
    latitude: '39.358399',
    longitude: '-87.40709',
    timeZoneId: 'America/New_York',
  },
  '47870': {
    zip: '47870',
    city: 'Prairieton',
    state: 'IN',
    latitude: '39.359042',
    longitude: '-87.407122',
    timeZoneId: 'America/New_York',
  },
  '47871': {
    zip: '47871',
    city: 'Riley',
    state: 'IN',
    latitude: '39.387755',
    longitude: '-87.301153',
    timeZoneId: 'America/New_York',
  },
  '47872': {
    zip: '47872',
    city: 'Rockville',
    state: 'IN',
    latitude: '39.77334',
    longitude: '-87.27006',
    timeZoneId: 'America/New_York',
  },
  '47874': {
    zip: '47874',
    city: 'Rosedale',
    state: 'IN',
    latitude: '39.60306',
    longitude: '-87.25734',
    timeZoneId: 'America/New_York',
  },
  '47875': {
    zip: '47875',
    city: 'Saint Bernice',
    state: 'IN',
    latitude: '39.755202',
    longitude: '-87.487061',
    timeZoneId: 'America/New_York',
  },
  '47876': {
    zip: '47876',
    city: 'Saint Mary of the Woods',
    state: 'IN',
    latitude: '39.511739',
    longitude: '-87.474237',
    timeZoneId: 'America/New_York',
  },
  '47878': {
    zip: '47878',
    city: 'Seelyville',
    state: 'IN',
    latitude: '39.495156',
    longitude: '-87.263683',
    timeZoneId: 'America/New_York',
  },
  '47879': {
    zip: '47879',
    city: 'Shelburn',
    state: 'IN',
    latitude: '39.181484',
    longitude: '-87.394063',
    timeZoneId: 'America/New_York',
  },
  '47880': {
    zip: '47880',
    city: 'Shepardsville',
    state: 'IN',
    latitude: '39.510391',
    longitude: '-87.477562',
    timeZoneId: 'America/New_York',
  },
  '47881': {
    zip: '47881',
    city: 'Staunton',
    state: 'IN',
    latitude: '39.51614',
    longitude: '-87.124206',
    timeZoneId: 'America/New_York',
  },
  '47882': {
    zip: '47882',
    city: 'Sullivan',
    state: 'IN',
    latitude: '39.098637',
    longitude: '-87.402599',
    timeZoneId: 'America/New_York',
  },
  '47884': {
    zip: '47884',
    city: 'Universal',
    state: 'IN',
    latitude: '39.650931',
    longitude: '-87.462343',
    timeZoneId: 'America/New_York',
  },
  '47885': {
    zip: '47885',
    city: 'West Terre Haute',
    state: 'IN',
    latitude: '39.464223',
    longitude: '-87.445806',
    timeZoneId: 'America/New_York',
  },
  '47901': {
    zip: '47901',
    city: 'Lafayette',
    state: 'IN',
    latitude: '40.415409',
    longitude: '-86.892088',
    timeZoneId: 'America/New_York',
  },
  '47902': {
    zip: '47902',
    city: 'Lafayette',
    state: 'IN',
    latitude: '40.416378',
    longitude: '-86.851288',
    timeZoneId: 'America/New_York',
  },
  '47903': {
    zip: '47903',
    city: 'Lafayette',
    state: 'IN',
    latitude: '40.417333',
    longitude: '-86.855216',
    timeZoneId: 'America/New_York',
  },
  '47904': {
    zip: '47904',
    city: 'Lafayette',
    state: 'IN',
    latitude: '40.428786',
    longitude: '-86.875104',
    timeZoneId: 'America/New_York',
  },
  '47905': {
    zip: '47905',
    city: 'Lafayette',
    state: 'IN',
    latitude: '40.322083',
    longitude: '-86.910463',
    timeZoneId: 'America/New_York',
  },
  '47906': {
    zip: '47906',
    city: 'West Lafayette',
    state: 'IN',
    latitude: '40.495856',
    longitude: '-86.96718',
    timeZoneId: 'America/New_York',
  },
  '47907': {
    zip: '47907',
    city: 'West Lafayette',
    state: 'IN',
    latitude: '40.368719',
    longitude: '-86.877988',
    timeZoneId: 'America/New_York',
  },
  '47909': {
    zip: '47909',
    city: 'Lafayette',
    state: 'IN',
    latitude: '40.323451',
    longitude: '-86.883198',
    timeZoneId: 'America/New_York',
  },
  '47916': {
    zip: '47916',
    city: 'Alamo',
    state: 'IN',
    latitude: '39.972803',
    longitude: '-87.029735',
    timeZoneId: 'America/New_York',
  },
  '47917': {
    zip: '47917',
    city: 'Ambia',
    state: 'IN',
    latitude: '40.465621',
    longitude: '-87.464804',
    timeZoneId: 'America/New_York',
  },
  '47918': {
    zip: '47918',
    city: 'Attica',
    state: 'IN',
    latitude: '40.291256',
    longitude: '-87.249487',
    timeZoneId: 'America/New_York',
  },
  '47920': {
    zip: '47920',
    city: 'Battle Ground',
    state: 'IN',
    latitude: '40.524602',
    longitude: '-86.827766',
    timeZoneId: 'America/New_York',
  },
  '47921': {
    zip: '47921',
    city: 'Boswell',
    state: 'IN',
    latitude: '40.518401',
    longitude: '-87.357588',
    timeZoneId: 'America/New_York',
  },
  '47922': {
    zip: '47922',
    city: 'Brook',
    state: 'IN',
    latitude: '40.867564',
    longitude: '-87.3637',
    timeZoneId: 'America/Chicago',
  },
  '47923': {
    zip: '47923',
    city: 'Brookston',
    state: 'IN',
    latitude: '40.601295',
    longitude: '-86.864871',
    timeZoneId: 'America/New_York',
  },
  '47924': {
    zip: '47924',
    city: 'Buck Creek',
    state: 'IN',
    latitude: '40.485527',
    longitude: '-86.759583',
    timeZoneId: 'America/New_York',
  },
  '47925': {
    zip: '47925',
    city: 'Buffalo',
    state: 'IN',
    latitude: '40.882225',
    longitude: '-86.744955',
    timeZoneId: 'America/New_York',
  },
  '47926': {
    zip: '47926',
    city: 'Burnettsville',
    state: 'IN',
    latitude: '40.706849',
    longitude: '-86.575464',
    timeZoneId: 'America/New_York',
  },
  '47928': {
    zip: '47928',
    city: 'Cayuga',
    state: 'IN',
    latitude: '39.951874',
    longitude: '-87.464218',
    timeZoneId: 'America/New_York',
  },
  '47929': {
    zip: '47929',
    city: 'Chalmers',
    state: 'IN',
    latitude: '40.662213',
    longitude: '-86.86246',
    timeZoneId: 'America/New_York',
  },
  '47930': {
    zip: '47930',
    city: 'Clarks Hill',
    state: 'IN',
    latitude: '40.244067',
    longitude: '-86.729838',
    timeZoneId: 'America/New_York',
  },
  '47932': {
    zip: '47932',
    city: 'Covington',
    state: 'IN',
    latitude: '40.138435',
    longitude: '-87.391625',
    timeZoneId: 'America/New_York',
  },
  '47933': {
    zip: '47933',
    city: 'Crawfordsville',
    state: 'IN',
    latitude: '40.034074',
    longitude: '-86.907942',
    timeZoneId: 'America/New_York',
  },
  '47940': {
    zip: '47940',
    city: 'Darlington',
    state: 'IN',
    latitude: '40.137961',
    longitude: '-86.756432',
    timeZoneId: 'America/New_York',
  },
  '47941': {
    zip: '47941',
    city: 'Dayton',
    state: 'IN',
    latitude: '40.373449',
    longitude: '-86.766894',
    timeZoneId: 'America/New_York',
  },
  '47942': {
    zip: '47942',
    city: 'Earl Park',
    state: 'IN',
    latitude: '40.682835',
    longitude: '-87.411617',
    timeZoneId: 'America/New_York',
  },
  '47943': {
    zip: '47943',
    city: 'Fair Oaks',
    state: 'IN',
    latitude: '41.070599',
    longitude: '-87.210287',
    timeZoneId: 'America/Chicago',
  },
  '47944': {
    zip: '47944',
    city: 'Fowler',
    state: 'IN',
    latitude: '40.61638',
    longitude: '-87.319872',
    timeZoneId: 'America/New_York',
  },
  '47946': {
    zip: '47946',
    city: 'Francesville',
    state: 'IN',
    latitude: '40.984945',
    longitude: '-86.882427',
    timeZoneId: 'America/New_York',
  },
  '47948': {
    zip: '47948',
    city: 'Goodland',
    state: 'IN',
    latitude: '40.766143',
    longitude: '-87.293377',
    timeZoneId: 'America/Chicago',
  },
  '47949': {
    zip: '47949',
    city: 'Hillsboro',
    state: 'IN',
    latitude: '40.111682',
    longitude: '-87.155988',
    timeZoneId: 'America/New_York',
  },
  '47950': {
    zip: '47950',
    city: 'Idaville',
    state: 'IN',
    latitude: '40.80035',
    longitude: '-86.654431',
    timeZoneId: 'America/New_York',
  },
  '47951': {
    zip: '47951',
    city: 'Kentland',
    state: 'IN',
    latitude: '40.777097',
    longitude: '-87.446288',
    timeZoneId: 'America/Chicago',
  },
  '47952': {
    zip: '47952',
    city: 'Kingman',
    state: 'IN',
    latitude: '40.0173',
    longitude: '-87.28512',
    timeZoneId: 'America/New_York',
  },
  '47954': {
    zip: '47954',
    city: 'Ladoga',
    state: 'IN',
    latitude: '39.91883',
    longitude: '-86.797098',
    timeZoneId: 'America/New_York',
  },
  '47955': {
    zip: '47955',
    city: 'Linden',
    state: 'IN',
    latitude: '40.190561',
    longitude: '-86.900602',
    timeZoneId: 'America/New_York',
  },
  '47957': {
    zip: '47957',
    city: 'Medaryville',
    state: 'IN',
    latitude: '41.079726',
    longitude: '-86.887555',
    timeZoneId: 'America/New_York',
  },
  '47958': {
    zip: '47958',
    city: 'Mellott',
    state: 'IN',
    latitude: '40.161228',
    longitude: '-87.149075',
    timeZoneId: 'America/New_York',
  },
  '47959': {
    zip: '47959',
    city: 'Monon',
    state: 'IN',
    latitude: '40.863882',
    longitude: '-86.878604',
    timeZoneId: 'America/New_York',
  },
  '47960': {
    zip: '47960',
    city: 'Monticello',
    state: 'IN',
    latitude: '40.749635',
    longitude: '-86.762622',
    timeZoneId: 'America/New_York',
  },
  '47962': {
    zip: '47962',
    city: 'Montmorenci',
    state: 'IN',
    latitude: '40.474179',
    longitude: '-87.03001',
    timeZoneId: 'America/New_York',
  },
  '47963': {
    zip: '47963',
    city: 'Morocco',
    state: 'IN',
    latitude: '40.944059',
    longitude: '-87.450207',
    timeZoneId: 'America/Chicago',
  },
  '47964': {
    zip: '47964',
    city: 'Mount Ayr',
    state: 'IN',
    latitude: '40.953169',
    longitude: '-87.295031',
    timeZoneId: 'America/Chicago',
  },
  '47965': {
    zip: '47965',
    city: 'New Market',
    state: 'IN',
    latitude: '39.954916',
    longitude: '-86.92072',
    timeZoneId: 'America/New_York',
  },
  '47966': {
    zip: '47966',
    city: 'Newport',
    state: 'IN',
    latitude: '39.881774',
    longitude: '-87.405059',
    timeZoneId: 'America/New_York',
  },
  '47967': {
    zip: '47967',
    city: 'New Richmond',
    state: 'IN',
    latitude: '40.196107',
    longitude: '-86.980031',
    timeZoneId: 'America/New_York',
  },
  '47968': {
    zip: '47968',
    city: 'New Ross',
    state: 'IN',
    latitude: '39.997991',
    longitude: '-86.751969',
    timeZoneId: 'America/New_York',
  },
  '47969': {
    zip: '47969',
    city: 'Newtown',
    state: 'IN',
    latitude: '40.205844',
    longitude: '-87.148287',
    timeZoneId: 'America/New_York',
  },
  '47970': {
    zip: '47970',
    city: 'Otterbein',
    state: 'IN',
    latitude: '40.487792',
    longitude: '-87.096309',
    timeZoneId: 'America/New_York',
  },
  '47971': {
    zip: '47971',
    city: 'Oxford',
    state: 'IN',
    latitude: '40.524522',
    longitude: '-87.249544',
    timeZoneId: 'America/New_York',
  },
  '47974': {
    zip: '47974',
    city: 'Perrysville',
    state: 'IN',
    latitude: '40.077863',
    longitude: '-87.477227',
    timeZoneId: 'America/New_York',
  },
  '47975': {
    zip: '47975',
    city: 'Pine Village',
    state: 'IN',
    latitude: '40.433584',
    longitude: '-87.235324',
    timeZoneId: 'America/New_York',
  },
  '47977': {
    zip: '47977',
    city: 'Remington',
    state: 'IN',
    latitude: '40.76398',
    longitude: '-87.150709',
    timeZoneId: 'America/Chicago',
  },
  '47978': {
    zip: '47978',
    city: 'Rensselaer',
    state: 'IN',
    latitude: '40.939522',
    longitude: '-87.153854',
    timeZoneId: 'America/Chicago',
  },
  '47980': {
    zip: '47980',
    city: 'Reynolds',
    state: 'IN',
    latitude: '40.750017',
    longitude: '-86.872641',
    timeZoneId: 'America/New_York',
  },
  '47981': {
    zip: '47981',
    city: 'Romney',
    state: 'IN',
    latitude: '40.257477',
    longitude: '-86.899324',
    timeZoneId: 'America/New_York',
  },
  '47982': {
    zip: '47982',
    city: 'State Line',
    state: 'IN',
    latitude: '40.196368',
    longitude: '-87.529325',
    timeZoneId: 'America/New_York',
  },
  '47983': {
    zip: '47983',
    city: 'Stockwell',
    state: 'IN',
    latitude: '40.283659',
    longitude: '-86.77063',
    timeZoneId: 'America/New_York',
  },
  '47986': {
    zip: '47986',
    city: 'Templeton',
    state: 'IN',
    latitude: '40.516336',
    longitude: '-87.20762',
    timeZoneId: 'America/New_York',
  },
  '47987': {
    zip: '47987',
    city: 'Veedersburg',
    state: 'IN',
    latitude: '40.114114',
    longitude: '-87.265455',
    timeZoneId: 'America/New_York',
  },
  '47988': {
    zip: '47988',
    city: 'Wallace',
    state: 'IN',
    latitude: '39.988972',
    longitude: '-87.146779',
    timeZoneId: 'America/New_York',
  },
  '47989': {
    zip: '47989',
    city: 'Waveland',
    state: 'IN',
    latitude: '39.878181',
    longitude: '-87.041617',
    timeZoneId: 'America/New_York',
  },
  '47990': {
    zip: '47990',
    city: 'Waynetown',
    state: 'IN',
    latitude: '40.089875',
    longitude: '-87.062026',
    timeZoneId: 'America/New_York',
  },
  '47991': {
    zip: '47991',
    city: 'West Lebanon',
    state: 'IN',
    latitude: '40.270611',
    longitude: '-87.385722',
    timeZoneId: 'America/New_York',
  },
  '47992': {
    zip: '47992',
    city: 'Westpoint',
    state: 'IN',
    latitude: '40.317115',
    longitude: '-87.066701',
    timeZoneId: 'America/New_York',
  },
  '47993': {
    zip: '47993',
    city: 'Williamsport',
    state: 'IN',
    latitude: '40.3002',
    longitude: '-87.31752',
    timeZoneId: 'America/New_York',
  },
  '47994': {
    zip: '47994',
    city: 'Wingate',
    state: 'IN',
    latitude: '40.17347',
    longitude: '-87.070915',
    timeZoneId: 'America/New_York',
  },
  '47995': {
    zip: '47995',
    city: 'Wolcott',
    state: 'IN',
    latitude: '40.759453',
    longitude: '-87.040583',
    timeZoneId: 'America/New_York',
  },
  '47996': {
    zip: '47996',
    city: 'West Lafayette',
    state: 'IN',
    latitude: '40.443842',
    longitude: '-86.911409',
    timeZoneId: 'America/New_York',
  },
  '47997': {
    zip: '47997',
    city: 'Yeoman',
    state: 'IN',
    latitude: '40.664464',
    longitude: '-86.720368',
    timeZoneId: 'America/New_York',
  },
  '48001': {
    zip: '48001',
    city: 'Algonac',
    state: 'MI',
    latitude: '42.63846',
    longitude: '-82.58274',
    timeZoneId: 'America/New_York',
  },
  '48002': {
    zip: '48002',
    city: 'Allenton',
    state: 'MI',
    latitude: '42.908333',
    longitude: '-82.883056',
    timeZoneId: 'America/New_York',
  },
  '48003': {
    zip: '48003',
    city: 'Almont',
    state: 'MI',
    latitude: '42.923761',
    longitude: '-83.043136',
    timeZoneId: 'America/New_York',
  },
  '48004': {
    zip: '48004',
    city: 'Anchorville',
    state: 'MI',
    latitude: '42.713705',
    longitude: '-82.682196',
    timeZoneId: 'America/New_York',
  },
  '48005': {
    zip: '48005',
    city: 'Armada',
    state: 'MI',
    latitude: '42.847253',
    longitude: '-82.919999',
    timeZoneId: 'America/New_York',
  },
  '48006': {
    zip: '48006',
    city: 'Avoca',
    state: 'MI',
    latitude: '43.064902',
    longitude: '-82.68872',
    timeZoneId: 'America/New_York',
  },
  '48007': {
    zip: '48007',
    city: 'Troy',
    state: 'MI',
    latitude: '42.581',
    longitude: '-83.145735',
    timeZoneId: 'America/New_York',
  },
  '48009': {
    zip: '48009',
    city: 'Birmingham',
    state: 'MI',
    latitude: '42.544619',
    longitude: '-83.213883',
    timeZoneId: 'America/New_York',
  },
  '48012': {
    zip: '48012',
    city: 'Birmingham',
    state: 'MI',
    latitude: '42.544872',
    longitude: '-83.208462',
    timeZoneId: 'America/New_York',
  },
  '48014': {
    zip: '48014',
    city: 'Capac',
    state: 'MI',
    latitude: '43.023996',
    longitude: '-82.92432',
    timeZoneId: 'America/New_York',
  },
  '48015': {
    zip: '48015',
    city: 'Center Line',
    state: 'MI',
    latitude: '42.480245',
    longitude: '-83.025107',
    timeZoneId: 'America/New_York',
  },
  '48017': {
    zip: '48017',
    city: 'Clawson',
    state: 'MI',
    latitude: '42.535396',
    longitude: '-83.151815',
    timeZoneId: 'America/New_York',
  },
  '48021': {
    zip: '48021',
    city: 'Eastpointe',
    state: 'MI',
    latitude: '42.463894',
    longitude: '-82.946167',
    timeZoneId: 'America/New_York',
  },
  '48022': {
    zip: '48022',
    city: 'Emmett',
    state: 'MI',
    latitude: '43.023041',
    longitude: '-82.814127',
    timeZoneId: 'America/New_York',
  },
  '48023': {
    zip: '48023',
    city: 'Fair Haven',
    state: 'MI',
    latitude: '42.688876',
    longitude: '-82.669348',
    timeZoneId: 'America/New_York',
  },
  '48025': {
    zip: '48025',
    city: 'Franklin',
    state: 'MI',
    latitude: '42.523195',
    longitude: '-83.265758',
    timeZoneId: 'America/New_York',
  },
  '48026': {
    zip: '48026',
    city: 'Fraser',
    state: 'MI',
    latitude: '42.534205',
    longitude: '-82.950983',
    timeZoneId: 'America/New_York',
  },
  '48027': {
    zip: '48027',
    city: 'Goodells',
    state: 'MI',
    latitude: '42.981737',
    longitude: '-82.660493',
    timeZoneId: 'America/New_York',
  },
  '48028': {
    zip: '48028',
    city: 'Harsens Island',
    state: 'MI',
    latitude: '42.577729',
    longitude: '-82.602065',
    timeZoneId: 'America/New_York',
  },
  '48030': {
    zip: '48030',
    city: 'Hazel Park',
    state: 'MI',
    latitude: '42.460506',
    longitude: '-83.096906',
    timeZoneId: 'America/New_York',
  },
  '48032': {
    zip: '48032',
    city: 'Jeddo',
    state: 'MI',
    latitude: '43.173246',
    longitude: '-82.593346',
    timeZoneId: 'America/New_York',
  },
  '48033': {
    zip: '48033',
    city: 'Southfield',
    state: 'MI',
    latitude: '42.472345',
    longitude: '-83.293971',
    timeZoneId: 'America/New_York',
  },
  '48034': {
    zip: '48034',
    city: 'Southfield',
    state: 'MI',
    latitude: '42.472818',
    longitude: '-83.293772',
    timeZoneId: 'America/New_York',
  },
  '48035': {
    zip: '48035',
    city: 'Clinton Township',
    state: 'MI',
    latitude: '42.552959',
    longitude: '-82.911875',
    timeZoneId: 'America/New_York',
  },
  '48036': {
    zip: '48036',
    city: 'Clinton Township',
    state: 'MI',
    latitude: '42.593259',
    longitude: '-82.911912',
    timeZoneId: 'America/New_York',
  },
  '48037': {
    zip: '48037',
    city: 'Southfield',
    state: 'MI',
    latitude: '42.48596',
    longitude: '-83.265438',
    timeZoneId: 'America/New_York',
  },
  '48038': {
    zip: '48038',
    city: 'Clinton Township',
    state: 'MI',
    latitude: '42.599508',
    longitude: '-82.953544',
    timeZoneId: 'America/New_York',
  },
  '48039': {
    zip: '48039',
    city: 'Marine City',
    state: 'MI',
    latitude: '42.719047',
    longitude: '-82.521691',
    timeZoneId: 'America/New_York',
  },
  '48040': {
    zip: '48040',
    city: 'Marysville',
    state: 'MI',
    latitude: '42.914675',
    longitude: '-82.480996',
    timeZoneId: 'America/New_York',
  },
  '48041': {
    zip: '48041',
    city: 'Memphis',
    state: 'MI',
    latitude: '42.924364',
    longitude: '-82.78134',
    timeZoneId: 'America/New_York',
  },
  '48042': {
    zip: '48042',
    city: 'Macomb',
    state: 'MI',
    latitude: '42.68414',
    longitude: '-82.918673',
    timeZoneId: 'America/New_York',
  },
  '48043': {
    zip: '48043',
    city: 'Mount Clemens',
    state: 'MI',
    latitude: '42.59708',
    longitude: '-82.881654',
    timeZoneId: 'America/New_York',
  },
  '48044': {
    zip: '48044',
    city: 'Macomb',
    state: 'MI',
    latitude: '42.651262',
    longitude: '-82.925811',
    timeZoneId: 'America/New_York',
  },
  '48045': {
    zip: '48045',
    city: 'Harrison Township',
    state: 'MI',
    latitude: '42.60941',
    longitude: '-82.835672',
    timeZoneId: 'America/New_York',
  },
  '48046': {
    zip: '48046',
    city: 'Mount Clemens',
    state: 'MI',
    latitude: '42.59213',
    longitude: '-82.883759',
    timeZoneId: 'America/New_York',
  },
  '48047': {
    zip: '48047',
    city: 'New Baltimore',
    state: 'MI',
    latitude: '42.674168',
    longitude: '-82.780566',
    timeZoneId: 'America/New_York',
  },
  '48048': {
    zip: '48048',
    city: 'New Haven',
    state: 'MI',
    latitude: '42.73806',
    longitude: '-82.80102',
    timeZoneId: 'America/New_York',
  },
  '48049': {
    zip: '48049',
    city: 'North Street',
    state: 'MI',
    latitude: '43.03632',
    longitude: '-82.57458',
    timeZoneId: 'America/New_York',
  },
  '48050': {
    zip: '48050',
    city: 'New Haven',
    state: 'MI',
    latitude: '42.78444',
    longitude: '-82.91688',
    timeZoneId: 'America/New_York',
  },
  '48051': {
    zip: '48051',
    city: 'New Baltimore',
    state: 'MI',
    latitude: '42.688473',
    longitude: '-82.809772',
    timeZoneId: 'America/New_York',
  },
  '48054': {
    zip: '48054',
    city: 'East China',
    state: 'MI',
    latitude: '42.767172',
    longitude: '-82.540934',
    timeZoneId: 'America/New_York',
  },
  '48059': {
    zip: '48059',
    city: 'Fort Gratiot',
    state: 'MI',
    latitude: '43.075603',
    longitude: '-82.480044',
    timeZoneId: 'America/New_York',
  },
  '48060': {
    zip: '48060',
    city: 'Port Huron',
    state: 'MI',
    latitude: '42.9861',
    longitude: '-82.46748',
    timeZoneId: 'America/New_York',
  },
  '48061': {
    zip: '48061',
    city: 'Port Huron',
    state: 'MI',
    latitude: '42.994263',
    longitude: '-82.43191',
    timeZoneId: 'America/New_York',
  },
  '48062': {
    zip: '48062',
    city: 'Richmond',
    state: 'MI',
    latitude: '42.843791',
    longitude: '-82.798308',
    timeZoneId: 'America/New_York',
  },
  '48063': {
    zip: '48063',
    city: 'Columbus',
    state: 'MI',
    latitude: '42.84791',
    longitude: '-82.676031',
    timeZoneId: 'America/New_York',
  },
  '48064': {
    zip: '48064',
    city: 'Casco',
    state: 'MI',
    latitude: '42.76764',
    longitude: '-82.67238',
    timeZoneId: 'America/New_York',
  },
  '48065': {
    zip: '48065',
    city: 'Romeo',
    state: 'MI',
    latitude: '42.840846',
    longitude: '-83.032465',
    timeZoneId: 'America/New_York',
  },
  '48066': {
    zip: '48066',
    city: 'Roseville',
    state: 'MI',
    latitude: '42.503558',
    longitude: '-82.936991',
    timeZoneId: 'America/New_York',
  },
  '48067': {
    zip: '48067',
    city: 'Royal Oak',
    state: 'MI',
    latitude: '42.491862',
    longitude: '-83.140721',
    timeZoneId: 'America/New_York',
  },
  '48068': {
    zip: '48068',
    city: 'Royal Oak',
    state: 'MI',
    latitude: '42.48523',
    longitude: '-83.146542',
    timeZoneId: 'America/New_York',
  },
  '48069': {
    zip: '48069',
    city: 'Pleasant Ridge',
    state: 'MI',
    latitude: '42.47159',
    longitude: '-83.141547',
    timeZoneId: 'America/New_York',
  },
  '48070': {
    zip: '48070',
    city: 'Huntington Woods',
    state: 'MI',
    latitude: '42.480041',
    longitude: '-83.166602',
    timeZoneId: 'America/New_York',
  },
  '48071': {
    zip: '48071',
    city: 'Madison Heights',
    state: 'MI',
    latitude: '42.504258',
    longitude: '-83.104966',
    timeZoneId: 'America/New_York',
  },
  '48072': {
    zip: '48072',
    city: 'Berkley',
    state: 'MI',
    latitude: '42.502236',
    longitude: '-83.185811',
    timeZoneId: 'America/New_York',
  },
  '48073': {
    zip: '48073',
    city: 'Royal Oak',
    state: 'MI',
    latitude: '42.518881',
    longitude: '-83.163476',
    timeZoneId: 'America/New_York',
  },
  '48074': {
    zip: '48074',
    city: 'Smiths Creek',
    state: 'MI',
    latitude: '42.944217',
    longitude: '-82.559913',
    timeZoneId: 'America/New_York',
  },
  '48075': {
    zip: '48075',
    city: 'Southfield',
    state: 'MI',
    latitude: '42.46407',
    longitude: '-83.225289',
    timeZoneId: 'America/New_York',
  },
  '48076': {
    zip: '48076',
    city: 'Southfield',
    state: 'MI',
    latitude: '42.497322',
    longitude: '-83.233132',
    timeZoneId: 'America/New_York',
  },
  '48079': {
    zip: '48079',
    city: 'Saint Clair',
    state: 'MI',
    latitude: '42.85385',
    longitude: '-82.547092',
    timeZoneId: 'America/New_York',
  },
  '48080': {
    zip: '48080',
    city: 'Saint Clair Shores',
    state: 'MI',
    latitude: '42.465933',
    longitude: '-82.903852',
    timeZoneId: 'America/New_York',
  },
  '48081': {
    zip: '48081',
    city: 'Saint Clair Shores',
    state: 'MI',
    latitude: '42.498015',
    longitude: '-82.899035',
    timeZoneId: 'America/New_York',
  },
  '48082': {
    zip: '48082',
    city: 'Saint Clair Shores',
    state: 'MI',
    latitude: '42.529366',
    longitude: '-82.887803',
    timeZoneId: 'America/New_York',
  },
  '48083': {
    zip: '48083',
    city: 'Troy',
    state: 'MI',
    latitude: '42.559552',
    longitude: '-83.123208',
    timeZoneId: 'America/New_York',
  },
  '48084': {
    zip: '48084',
    city: 'Troy',
    state: 'MI',
    latitude: '42.556928',
    longitude: '-83.17284',
    timeZoneId: 'America/New_York',
  },
  '48085': {
    zip: '48085',
    city: 'Troy',
    state: 'MI',
    latitude: '42.595219',
    longitude: '-83.120115',
    timeZoneId: 'America/New_York',
  },
  '48086': {
    zip: '48086',
    city: 'Southfield',
    state: 'MI',
    latitude: '42.47301',
    longitude: '-83.260797',
    timeZoneId: 'America/New_York',
  },
  '48088': {
    zip: '48088',
    city: 'Warren',
    state: 'MI',
    latitude: '42.51581',
    longitude: '-82.981163',
    timeZoneId: 'America/New_York',
  },
  '48089': {
    zip: '48089',
    city: 'Warren',
    state: 'MI',
    latitude: '42.465547',
    longitude: '-82.993978',
    timeZoneId: 'America/New_York',
  },
  '48090': {
    zip: '48090',
    city: 'Warren',
    state: 'MI',
    latitude: '42.455368',
    longitude: '-82.885257',
    timeZoneId: 'America/New_York',
  },
  '48091': {
    zip: '48091',
    city: 'Warren',
    state: 'MI',
    latitude: '42.466705',
    longitude: '-83.055818',
    timeZoneId: 'America/New_York',
  },
  '48092': {
    zip: '48092',
    city: 'Warren',
    state: 'MI',
    latitude: '42.512974',
    longitude: '-83.059791',
    timeZoneId: 'America/New_York',
  },
  '48093': {
    zip: '48093',
    city: 'Warren',
    state: 'MI',
    latitude: '42.516647',
    longitude: '-83.007709',
    timeZoneId: 'America/New_York',
  },
  '48094': {
    zip: '48094',
    city: 'Washington',
    state: 'MI',
    latitude: '42.73446',
    longitude: '-82.99812',
    timeZoneId: 'America/New_York',
  },
  '48095': {
    zip: '48095',
    city: 'Washington',
    state: 'MI',
    latitude: '42.783484',
    longitude: '-83.041714',
    timeZoneId: 'America/New_York',
  },
  '48096': {
    zip: '48096',
    city: 'Ray',
    state: 'MI',
    latitude: '42.76152',
    longitude: '-82.92708',
    timeZoneId: 'America/New_York',
  },
  '48097': {
    zip: '48097',
    city: 'Yale',
    state: 'MI',
    latitude: '43.116201',
    longitude: '-82.794016',
    timeZoneId: 'America/New_York',
  },
  '48098': {
    zip: '48098',
    city: 'Troy',
    state: 'MI',
    latitude: '42.603558',
    longitude: '-83.176258',
    timeZoneId: 'America/New_York',
  },
  '48099': {
    zip: '48099',
    city: 'Troy',
    state: 'MI',
    latitude: '42.561732',
    longitude: '-83.14647',
    timeZoneId: 'America/New_York',
  },
  '48101': {
    zip: '48101',
    city: 'Allen Park',
    state: 'MI',
    latitude: '42.253333',
    longitude: '-83.208255',
    timeZoneId: 'America/New_York',
  },
  '48103': {
    zip: '48103',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.27078',
    longitude: '-83.80866',
    timeZoneId: 'America/New_York',
  },
  '48104': {
    zip: '48104',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.261244',
    longitude: '-83.716523',
    timeZoneId: 'America/New_York',
  },
  '48105': {
    zip: '48105',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.306761',
    longitude: '-83.700406',
    timeZoneId: 'America/New_York',
  },
  '48106': {
    zip: '48106',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.227952',
    longitude: '-83.738851',
    timeZoneId: 'America/New_York',
  },
  '48107': {
    zip: '48107',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.256407',
    longitude: '-83.68054',
    timeZoneId: 'America/New_York',
  },
  '48108': {
    zip: '48108',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.240869',
    longitude: '-83.719353',
    timeZoneId: 'America/New_York',
  },
  '48109': {
    zip: '48109',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.289849',
    longitude: '-83.715145',
    timeZoneId: 'America/New_York',
  },
  '48110': {
    zip: '48110',
    city: 'Azalia',
    state: 'MI',
    latitude: '42.015471',
    longitude: '-83.668836',
    timeZoneId: 'America/New_York',
  },
  '48111': {
    zip: '48111',
    city: 'Belleville',
    state: 'MI',
    latitude: '42.174217',
    longitude: '-83.489647',
    timeZoneId: 'America/New_York',
  },
  '48112': {
    zip: '48112',
    city: 'Belleville',
    state: 'MI',
    latitude: '42.204508',
    longitude: '-83.482683',
    timeZoneId: 'America/New_York',
  },
  '48113': {
    zip: '48113',
    city: 'Ann Arbor',
    state: 'MI',
    latitude: '42.274234',
    longitude: '-83.730952',
    timeZoneId: 'America/New_York',
  },
  '48114': {
    zip: '48114',
    city: 'Brighton',
    state: 'MI',
    latitude: '42.529992',
    longitude: '-83.782845',
    timeZoneId: 'America/New_York',
  },
  '48115': {
    zip: '48115',
    city: 'Bridgewater',
    state: 'MI',
    latitude: '42.160506',
    longitude: '-83.921332',
    timeZoneId: 'America/New_York',
  },
  '48116': {
    zip: '48116',
    city: 'Brighton',
    state: 'MI',
    latitude: '42.532103',
    longitude: '-83.773187',
    timeZoneId: 'America/New_York',
  },
  '48117': {
    zip: '48117',
    city: 'Carleton',
    state: 'MI',
    latitude: '42.052359',
    longitude: '-83.410724',
    timeZoneId: 'America/New_York',
  },
  '48118': {
    zip: '48118',
    city: 'Chelsea',
    state: 'MI',
    latitude: '42.317447',
    longitude: '-84.030349',
    timeZoneId: 'America/New_York',
  },
  '48120': {
    zip: '48120',
    city: 'Dearborn',
    state: 'MI',
    latitude: '42.306408',
    longitude: '-83.173266',
    timeZoneId: 'America/New_York',
  },
  '48121': {
    zip: '48121',
    city: 'Dearborn',
    state: 'MI',
    latitude: '42.31272',
    longitude: '-83.192258',
    timeZoneId: 'America/New_York',
  },
  '48122': {
    zip: '48122',
    city: 'Melvindale',
    state: 'MI',
    latitude: '42.277639',
    longitude: '-83.181617',
    timeZoneId: 'America/New_York',
  },
  '48123': {
    zip: '48123',
    city: 'Dearborn',
    state: 'MI',
    latitude: '42.31272',
    longitude: '-83.214361',
    timeZoneId: 'America/New_York',
  },
  '48124': {
    zip: '48124',
    city: 'Dearborn',
    state: 'MI',
    latitude: '42.294678',
    longitude: '-83.250573',
    timeZoneId: 'America/New_York',
  },
  '48125': {
    zip: '48125',
    city: 'Dearborn Heights',
    state: 'MI',
    latitude: '42.275895',
    longitude: '-83.263587',
    timeZoneId: 'America/New_York',
  },
  '48126': {
    zip: '48126',
    city: 'Dearborn',
    state: 'MI',
    latitude: '42.335197',
    longitude: '-83.193476',
    timeZoneId: 'America/New_York',
  },
  '48127': {
    zip: '48127',
    city: 'Dearborn Heights',
    state: 'MI',
    latitude: '42.33465',
    longitude: '-83.284212',
    timeZoneId: 'America/New_York',
  },
  '48128': {
    zip: '48128',
    city: 'Dearborn',
    state: 'MI',
    latitude: '42.320079',
    longitude: '-83.262487',
    timeZoneId: 'America/New_York',
  },
  '48130': {
    zip: '48130',
    city: 'Dexter',
    state: 'MI',
    latitude: '42.353665',
    longitude: '-83.897388',
    timeZoneId: 'America/New_York',
  },
  '48131': {
    zip: '48131',
    city: 'Dundee',
    state: 'MI',
    latitude: '41.96576',
    longitude: '-83.668534',
    timeZoneId: 'America/New_York',
  },
  '48133': {
    zip: '48133',
    city: 'Erie',
    state: 'MI',
    latitude: '41.782511',
    longitude: '-83.490703',
    timeZoneId: 'America/New_York',
  },
  '48134': {
    zip: '48134',
    city: 'Flat Rock',
    state: 'MI',
    latitude: '42.107811',
    longitude: '-83.281359',
    timeZoneId: 'America/New_York',
  },
  '48135': {
    zip: '48135',
    city: 'Garden City',
    state: 'MI',
    latitude: '42.324302',
    longitude: '-83.338607',
    timeZoneId: 'America/New_York',
  },
  '48136': {
    zip: '48136',
    city: 'Garden City',
    state: 'MI',
    latitude: '42.325324',
    longitude: '-83.343608',
    timeZoneId: 'America/New_York',
  },
  '48137': {
    zip: '48137',
    city: 'Gregory',
    state: 'MI',
    latitude: '42.46842',
    longitude: '-84.06588',
    timeZoneId: 'America/New_York',
  },
  '48138': {
    zip: '48138',
    city: 'Grosse Ile',
    state: 'MI',
    latitude: '42.134339',
    longitude: '-83.153677',
    timeZoneId: 'America/New_York',
  },
  '48139': {
    zip: '48139',
    city: 'Hamburg',
    state: 'MI',
    latitude: '42.454041',
    longitude: '-83.811311',
    timeZoneId: 'America/New_York',
  },
  '48140': {
    zip: '48140',
    city: 'Ida',
    state: 'MI',
    latitude: '41.875757',
    longitude: '-83.58033',
    timeZoneId: 'America/New_York',
  },
  '48141': {
    zip: '48141',
    city: 'Inkster',
    state: 'MI',
    latitude: '42.292417',
    longitude: '-83.31705',
    timeZoneId: 'America/New_York',
  },
  '48143': {
    zip: '48143',
    city: 'Lakeland',
    state: 'MI',
    latitude: '42.456748',
    longitude: '-83.897356',
    timeZoneId: 'America/New_York',
  },
  '48144': {
    zip: '48144',
    city: 'Lambertville',
    state: 'MI',
    latitude: '41.753467',
    longitude: '-83.627319',
    timeZoneId: 'America/New_York',
  },
  '48145': {
    zip: '48145',
    city: 'La Salle',
    state: 'MI',
    latitude: '41.847942',
    longitude: '-83.45527',
    timeZoneId: 'America/New_York',
  },
  '48146': {
    zip: '48146',
    city: 'Lincoln Park',
    state: 'MI',
    latitude: '42.245551',
    longitude: '-83.180156',
    timeZoneId: 'America/New_York',
  },
  '48150': {
    zip: '48150',
    city: 'Livonia',
    state: 'MI',
    latitude: '42.366557',
    longitude: '-83.370612',
    timeZoneId: 'America/New_York',
  },
  '48151': {
    zip: '48151',
    city: 'Livonia',
    state: 'MI',
    latitude: '42.396016',
    longitude: '-83.371538',
    timeZoneId: 'America/New_York',
  },
  '48152': {
    zip: '48152',
    city: 'Livonia',
    state: 'MI',
    latitude: '42.423546',
    longitude: '-83.372611',
    timeZoneId: 'America/New_York',
  },
  '48153': {
    zip: '48153',
    city: 'Livonia',
    state: 'MI',
    latitude: '42.3648',
    longitude: '-83.38765',
    timeZoneId: 'America/New_York',
  },
  '48154': {
    zip: '48154',
    city: 'Livonia',
    state: 'MI',
    latitude: '42.397134',
    longitude: '-83.377371',
    timeZoneId: 'America/New_York',
  },
  '48157': {
    zip: '48157',
    city: 'Luna Pier',
    state: 'MI',
    latitude: '41.816305',
    longitude: '-83.440293',
    timeZoneId: 'America/New_York',
  },
  '48158': {
    zip: '48158',
    city: 'Manchester',
    state: 'MI',
    latitude: '42.16254',
    longitude: '-84.02004',
    timeZoneId: 'America/New_York',
  },
  '48159': {
    zip: '48159',
    city: 'Maybee',
    state: 'MI',
    latitude: '42.021186',
    longitude: '-83.543036',
    timeZoneId: 'America/New_York',
  },
  '48160': {
    zip: '48160',
    city: 'Milan',
    state: 'MI',
    latitude: '42.110465',
    longitude: '-83.684175',
    timeZoneId: 'America/New_York',
  },
  '48161': {
    zip: '48161',
    city: 'Monroe',
    state: 'MI',
    latitude: '41.909061',
    longitude: '-83.470098',
    timeZoneId: 'America/New_York',
  },
  '48162': {
    zip: '48162',
    city: 'Monroe',
    state: 'MI',
    latitude: '41.94',
    longitude: '-83.313333',
    timeZoneId: 'America/New_York',
  },
  '48164': {
    zip: '48164',
    city: 'New Boston',
    state: 'MI',
    latitude: '42.129384',
    longitude: '-83.390387',
    timeZoneId: 'America/New_York',
  },
  '48165': {
    zip: '48165',
    city: 'New Hudson',
    state: 'MI',
    latitude: '42.506178',
    longitude: '-83.626234',
    timeZoneId: 'America/New_York',
  },
  '48166': {
    zip: '48166',
    city: 'Newport',
    state: 'MI',
    latitude: '41.977424',
    longitude: '-83.284218',
    timeZoneId: 'America/New_York',
  },
  '48167': {
    zip: '48167',
    city: 'Northville',
    state: 'MI',
    latitude: '42.422231',
    longitude: '-83.470096',
    timeZoneId: 'America/New_York',
  },
  '48168': {
    zip: '48168',
    city: 'Northville',
    state: 'MI',
    latitude: '42.4107',
    longitude: '-83.4993',
    timeZoneId: 'America/New_York',
  },
  '48169': {
    zip: '48169',
    city: 'Pinckney',
    state: 'MI',
    latitude: '42.45908',
    longitude: '-83.934013',
    timeZoneId: 'America/New_York',
  },
  '48170': {
    zip: '48170',
    city: 'Plymouth',
    state: 'MI',
    latitude: '42.369118',
    longitude: '-83.520276',
    timeZoneId: 'America/New_York',
  },
  '48173': {
    zip: '48173',
    city: 'Rockwood',
    state: 'MI',
    latitude: '42.073111',
    longitude: '-83.223109',
    timeZoneId: 'America/New_York',
  },
  '48174': {
    zip: '48174',
    city: 'Romulus',
    state: 'MI',
    latitude: '42.215215',
    longitude: '-83.344726',
    timeZoneId: 'America/New_York',
  },
  '48175': {
    zip: '48175',
    city: 'Salem',
    state: 'MI',
    latitude: '42.408287',
    longitude: '-83.577493',
    timeZoneId: 'America/New_York',
  },
  '48176': {
    zip: '48176',
    city: 'Saline',
    state: 'MI',
    latitude: '42.165249',
    longitude: '-83.786854',
    timeZoneId: 'America/New_York',
  },
  '48177': {
    zip: '48177',
    city: 'Samaria',
    state: 'MI',
    latitude: '41.811359',
    longitude: '-83.576606',
    timeZoneId: 'America/New_York',
  },
  '48178': {
    zip: '48178',
    city: 'South Lyon',
    state: 'MI',
    latitude: '42.447611',
    longitude: '-83.662388',
    timeZoneId: 'America/New_York',
  },
  '48179': {
    zip: '48179',
    city: 'South Rockwood',
    state: 'MI',
    latitude: '42.051676',
    longitude: '-83.258881',
    timeZoneId: 'America/New_York',
  },
  '48180': {
    zip: '48180',
    city: 'Taylor',
    state: 'MI',
    latitude: '42.234324',
    longitude: '-83.269312',
    timeZoneId: 'America/New_York',
  },
  '48182': {
    zip: '48182',
    city: 'Temperance',
    state: 'MI',
    latitude: '41.788937',
    longitude: '-83.571374',
    timeZoneId: 'America/New_York',
  },
  '48183': {
    zip: '48183',
    city: 'Trenton',
    state: 'MI',
    latitude: '42.134388',
    longitude: '-83.215861',
    timeZoneId: 'America/New_York',
  },
  '48184': {
    zip: '48184',
    city: 'Wayne',
    state: 'MI',
    latitude: '42.275455',
    longitude: '-83.382717',
    timeZoneId: 'America/New_York',
  },
  '48185': {
    zip: '48185',
    city: 'Westland',
    state: 'MI',
    latitude: '42.341489',
    longitude: '-83.397113',
    timeZoneId: 'America/New_York',
  },
  '48186': {
    zip: '48186',
    city: 'Westland',
    state: 'MI',
    latitude: '42.299722',
    longitude: '-83.379852',
    timeZoneId: 'America/New_York',
  },
  '48187': {
    zip: '48187',
    city: 'Canton',
    state: 'MI',
    latitude: '42.331838',
    longitude: '-83.477568',
    timeZoneId: 'America/New_York',
  },
  '48188': {
    zip: '48188',
    city: 'Canton',
    state: 'MI',
    latitude: '42.288869',
    longitude: '-83.477501',
    timeZoneId: 'America/New_York',
  },
  '48189': {
    zip: '48189',
    city: 'Whitmore Lake',
    state: 'MI',
    latitude: '42.399875',
    longitude: '-83.77163',
    timeZoneId: 'America/New_York',
  },
  '48190': {
    zip: '48190',
    city: 'Whittaker',
    state: 'MI',
    latitude: '42.126163',
    longitude: '-83.593877',
    timeZoneId: 'America/New_York',
  },
  '48191': {
    zip: '48191',
    city: 'Willis',
    state: 'MI',
    latitude: '42.130909',
    longitude: '-83.570539',
    timeZoneId: 'America/New_York',
  },
  '48192': {
    zip: '48192',
    city: 'Wyandotte',
    state: 'MI',
    latitude: '42.197463',
    longitude: '-83.185203',
    timeZoneId: 'America/New_York',
  },
  '48193': {
    zip: '48193',
    city: 'Riverview',
    state: 'MI',
    latitude: '42.1769',
    longitude: '-83.1932',
    timeZoneId: 'America/New_York',
  },
  '48195': {
    zip: '48195',
    city: 'Southgate',
    state: 'MI',
    latitude: '42.205329',
    longitude: '-83.20698',
    timeZoneId: 'America/New_York',
  },
  '48197': {
    zip: '48197',
    city: 'Ypsilanti',
    state: 'MI',
    latitude: '42.233132',
    longitude: '-83.623767',
    timeZoneId: 'America/New_York',
  },
  '48198': {
    zip: '48198',
    city: 'Ypsilanti',
    state: 'MI',
    latitude: '42.248536',
    longitude: '-83.586753',
    timeZoneId: 'America/New_York',
  },
  '48201': {
    zip: '48201',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.346393',
    longitude: '-83.06102',
    timeZoneId: 'America/New_York',
  },
  '48202': {
    zip: '48202',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.371987',
    longitude: '-83.076406',
    timeZoneId: 'America/New_York',
  },
  '48203': {
    zip: '48203',
    city: 'Highland Park',
    state: 'MI',
    latitude: '42.424001',
    longitude: '-83.101934',
    timeZoneId: 'America/New_York',
  },
  '48204': {
    zip: '48204',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.367514',
    longitude: '-83.138488',
    timeZoneId: 'America/New_York',
  },
  '48205': {
    zip: '48205',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.432798',
    longitude: '-82.981462',
    timeZoneId: 'America/New_York',
  },
  '48206': {
    zip: '48206',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.37229',
    longitude: '-83.099898',
    timeZoneId: 'America/New_York',
  },
  '48207': {
    zip: '48207',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.350631',
    longitude: '-83.023593',
    timeZoneId: 'America/New_York',
  },
  '48208': {
    zip: '48208',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.350968',
    longitude: '-83.089896',
    timeZoneId: 'America/New_York',
  },
  '48209': {
    zip: '48209',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.307946',
    longitude: '-83.109633',
    timeZoneId: 'America/New_York',
  },
  '48210': {
    zip: '48210',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.336104',
    longitude: '-83.126056',
    timeZoneId: 'America/New_York',
  },
  '48211': {
    zip: '48211',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.38013',
    longitude: '-83.05175',
    timeZoneId: 'America/New_York',
  },
  '48212': {
    zip: '48212',
    city: 'Hamtramck',
    state: 'MI',
    latitude: '42.408353',
    longitude: '-83.058329',
    timeZoneId: 'America/New_York',
  },
  '48213': {
    zip: '48213',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.393275',
    longitude: '-82.995296',
    timeZoneId: 'America/New_York',
  },
  '48214': {
    zip: '48214',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.366513',
    longitude: '-82.993799',
    timeZoneId: 'America/New_York',
  },
  '48215': {
    zip: '48215',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.38772',
    longitude: '-82.9506',
    timeZoneId: 'America/New_York',
  },
  '48216': {
    zip: '48216',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.329264',
    longitude: '-83.080548',
    timeZoneId: 'America/New_York',
  },
  '48217': {
    zip: '48217',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.276563',
    longitude: '-83.152089',
    timeZoneId: 'America/New_York',
  },
  '48218': {
    zip: '48218',
    city: 'River Rouge',
    state: 'MI',
    latitude: '42.331389',
    longitude: '-83.045833',
    timeZoneId: 'America/New_York',
  },
  '48219': {
    zip: '48219',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.423328',
    longitude: '-83.250921',
    timeZoneId: 'America/New_York',
  },
  '48220': {
    zip: '48220',
    city: 'Ferndale',
    state: 'MI',
    latitude: '42.458803',
    longitude: '-83.138618',
    timeZoneId: 'America/New_York',
  },
  '48221': {
    zip: '48221',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.429028',
    longitude: '-83.150684',
    timeZoneId: 'America/New_York',
  },
  '48222': {
    zip: '48222',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.273318',
    longitude: '-83.132151',
    timeZoneId: 'America/New_York',
  },
  '48223': {
    zip: '48223',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.388698',
    longitude: '-83.250589',
    timeZoneId: 'America/New_York',
  },
  '48224': {
    zip: '48224',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.411143',
    longitude: '-82.943461',
    timeZoneId: 'America/New_York',
  },
  '48225': {
    zip: '48225',
    city: 'Harper Woods',
    state: 'MI',
    latitude: '42.437669',
    longitude: '-82.940907',
    timeZoneId: 'America/New_York',
  },
  '48226': {
    zip: '48226',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.332339',
    longitude: '-83.051131',
    timeZoneId: 'America/New_York',
  },
  '48227': {
    zip: '48227',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.389657',
    longitude: '-83.193613',
    timeZoneId: 'America/New_York',
  },
  '48228': {
    zip: '48228',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.353376',
    longitude: '-83.212913',
    timeZoneId: 'America/New_York',
  },
  '48229': {
    zip: '48229',
    city: 'Ecorse',
    state: 'MI',
    latitude: '42.252273',
    longitude: '-83.144669',
    timeZoneId: 'America/New_York',
  },
  '48230': {
    zip: '48230',
    city: 'Grosse Pointe',
    state: 'MI',
    latitude: '42.394584',
    longitude: '-82.918482',
    timeZoneId: 'America/New_York',
  },
  '48231': {
    zip: '48231',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.331232',
    longitude: '-83.048801',
    timeZoneId: 'America/New_York',
  },
  '48232': {
    zip: '48232',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.328867',
    longitude: '-83.051104',
    timeZoneId: 'America/New_York',
  },
  '48233': {
    zip: '48233',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.322115',
    longitude: '-83.062643',
    timeZoneId: 'America/New_York',
  },
  '48234': {
    zip: '48234',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.430213',
    longitude: '-83.042907',
    timeZoneId: 'America/New_York',
  },
  '48235': {
    zip: '48235',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.425493',
    longitude: '-83.19335',
    timeZoneId: 'America/New_York',
  },
  '48236': {
    zip: '48236',
    city: 'Grosse Pointe',
    state: 'MI',
    latitude: '42.424935',
    longitude: '-82.900732',
    timeZoneId: 'America/New_York',
  },
  '48237': {
    zip: '48237',
    city: 'Oak Park',
    state: 'MI',
    latitude: '42.46554',
    longitude: '-83.19738',
    timeZoneId: 'America/New_York',
  },
  '48238': {
    zip: '48238',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.39504',
    longitude: '-83.141885',
    timeZoneId: 'America/New_York',
  },
  '48239': {
    zip: '48239',
    city: 'Redford',
    state: 'MI',
    latitude: '42.376745',
    longitude: '-83.285091',
    timeZoneId: 'America/New_York',
  },
  '48240': {
    zip: '48240',
    city: 'Redford',
    state: 'MI',
    latitude: '42.423668',
    longitude: '-83.299778',
    timeZoneId: 'America/New_York',
  },
  '48242': {
    zip: '48242',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.212327',
    longitude: '-83.347739',
    timeZoneId: 'America/New_York',
  },
  '48243': {
    zip: '48243',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.329927',
    longitude: '-83.033898',
    timeZoneId: 'America/New_York',
  },
  '48244': {
    zip: '48244',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.329735',
    longitude: '-83.081635',
    timeZoneId: 'America/New_York',
  },
  '48255': {
    zip: '48255',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.331409',
    longitude: '-83.051738',
    timeZoneId: 'America/New_York',
  },
  '48260': {
    zip: '48260',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.33199',
    longitude: '-83.057185',
    timeZoneId: 'America/New_York',
  },
  '48264': {
    zip: '48264',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.329958',
    longitude: '-83.045066',
    timeZoneId: 'America/New_York',
  },
  '48265': {
    zip: '48265',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.441129',
    longitude: '-82.930987',
    timeZoneId: 'America/New_York',
  },
  '48266': {
    zip: '48266',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.34445',
    longitude: '-83.00918',
    timeZoneId: 'America/New_York',
  },
  '48267': {
    zip: '48267',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.331484',
    longitude: '-83.05132',
    timeZoneId: 'America/New_York',
  },
  '48268': {
    zip: '48268',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.331348',
    longitude: '-83.048397',
    timeZoneId: 'America/New_York',
  },
  '48269': {
    zip: '48269',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.328906',
    longitude: '-83.045858',
    timeZoneId: 'America/New_York',
  },
  '48272': {
    zip: '48272',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.336958',
    longitude: '-83.054444',
    timeZoneId: 'America/New_York',
  },
  '48275': {
    zip: '48275',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.332231',
    longitude: '-83.05028',
    timeZoneId: 'America/New_York',
  },
  '48277': {
    zip: '48277',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.325731',
    longitude: '-83.056878',
    timeZoneId: 'America/New_York',
  },
  '48278': {
    zip: '48278',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.32639',
    longitude: '-83.060061',
    timeZoneId: 'America/New_York',
  },
  '48279': {
    zip: '48279',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.327887',
    longitude: '-83.042036',
    timeZoneId: 'America/New_York',
  },
  '48288': {
    zip: '48288',
    city: 'Detroit',
    state: 'MI',
    latitude: '42.418809',
    longitude: '-83.104982',
    timeZoneId: 'America/New_York',
  },
  '48301': {
    zip: '48301',
    city: 'Bloomfield Hills',
    state: 'MI',
    latitude: '42.541806',
    longitude: '-83.281957',
    timeZoneId: 'America/New_York',
  },
  '48302': {
    zip: '48302',
    city: 'Bloomfield Hills',
    state: 'MI',
    latitude: '42.573777',
    longitude: '-83.293724',
    timeZoneId: 'America/New_York',
  },
  '48303': {
    zip: '48303',
    city: 'Bloomfield Hills',
    state: 'MI',
    latitude: '42.582303',
    longitude: '-83.245248',
    timeZoneId: 'America/New_York',
  },
  '48304': {
    zip: '48304',
    city: 'Bloomfield Hills',
    state: 'MI',
    latitude: '42.58956',
    longitude: '-83.26536',
    timeZoneId: 'America/New_York',
  },
  '48306': {
    zip: '48306',
    city: 'Rochester',
    state: 'MI',
    latitude: '42.724767',
    longitude: '-83.149761',
    timeZoneId: 'America/New_York',
  },
  '48307': {
    zip: '48307',
    city: 'Rochester',
    state: 'MI',
    latitude: '42.657341',
    longitude: '-83.123033',
    timeZoneId: 'America/New_York',
  },
  '48308': {
    zip: '48308',
    city: 'Rochester',
    state: 'MI',
    latitude: '42.678404',
    longitude: '-83.134101',
    timeZoneId: 'America/New_York',
  },
  '48309': {
    zip: '48309',
    city: 'Rochester',
    state: 'MI',
    latitude: '42.65796',
    longitude: '-83.18652',
    timeZoneId: 'America/New_York',
  },
  '48310': {
    zip: '48310',
    city: 'Sterling Heights',
    state: 'MI',
    latitude: '42.566407',
    longitude: '-83.06801',
    timeZoneId: 'America/New_York',
  },
  '48311': {
    zip: '48311',
    city: 'Sterling Heights',
    state: 'MI',
    latitude: '42.578334',
    longitude: '-83.028442',
    timeZoneId: 'America/New_York',
  },
  '48312': {
    zip: '48312',
    city: 'Sterling Heights',
    state: 'MI',
    latitude: '42.556244',
    longitude: '-83.006843',
    timeZoneId: 'America/New_York',
  },
  '48313': {
    zip: '48313',
    city: 'Sterling Heights',
    state: 'MI',
    latitude: '42.598804',
    longitude: '-82.997434',
    timeZoneId: 'America/New_York',
  },
  '48314': {
    zip: '48314',
    city: 'Sterling Heights',
    state: 'MI',
    latitude: '42.603554',
    longitude: '-83.056445',
    timeZoneId: 'America/New_York',
  },
  '48315': {
    zip: '48315',
    city: 'Utica',
    state: 'MI',
    latitude: '42.66556',
    longitude: '-82.995885',
    timeZoneId: 'America/New_York',
  },
  '48316': {
    zip: '48316',
    city: 'Utica',
    state: 'MI',
    latitude: '42.680668',
    longitude: '-83.052212',
    timeZoneId: 'America/New_York',
  },
  '48317': {
    zip: '48317',
    city: 'Utica',
    state: 'MI',
    latitude: '42.64705',
    longitude: '-83.049668',
    timeZoneId: 'America/New_York',
  },
  '48318': {
    zip: '48318',
    city: 'Utica',
    state: 'MI',
    latitude: '42.624463',
    longitude: '-83.033255',
    timeZoneId: 'America/New_York',
  },
  '48320': {
    zip: '48320',
    city: 'Keego Harbor',
    state: 'MI',
    latitude: '42.609351',
    longitude: '-83.345619',
    timeZoneId: 'America/New_York',
  },
  '48321': {
    zip: '48321',
    city: 'Auburn Hills',
    state: 'MI',
    latitude: '42.674336',
    longitude: '-83.242398',
    timeZoneId: 'America/New_York',
  },
  '48322': {
    zip: '48322',
    city: 'West Bloomfield',
    state: 'MI',
    latitude: '42.545288',
    longitude: '-83.382354',
    timeZoneId: 'America/New_York',
  },
  '48323': {
    zip: '48323',
    city: 'West Bloomfield',
    state: 'MI',
    latitude: '42.57318',
    longitude: '-83.3802',
    timeZoneId: 'America/New_York',
  },
  '48324': {
    zip: '48324',
    city: 'West Bloomfield',
    state: 'MI',
    latitude: '42.59544',
    longitude: '-83.38302',
    timeZoneId: 'America/New_York',
  },
  '48325': {
    zip: '48325',
    city: 'West Bloomfield',
    state: 'MI',
    latitude: '42.595072',
    longitude: '-83.389939',
    timeZoneId: 'America/New_York',
  },
  '48326': {
    zip: '48326',
    city: 'Auburn Hills',
    state: 'MI',
    latitude: '42.66462',
    longitude: '-83.27388',
    timeZoneId: 'America/New_York',
  },
  '48327': {
    zip: '48327',
    city: 'Waterford',
    state: 'MI',
    latitude: '42.647953',
    longitude: '-83.407284',
    timeZoneId: 'America/New_York',
  },
  '48328': {
    zip: '48328',
    city: 'Waterford',
    state: 'MI',
    latitude: '42.644305',
    longitude: '-83.356394',
    timeZoneId: 'America/New_York',
  },
  '48329': {
    zip: '48329',
    city: 'Waterford',
    state: 'MI',
    latitude: '42.688849',
    longitude: '-83.399301',
    timeZoneId: 'America/New_York',
  },
  '48330': {
    zip: '48330',
    city: 'Drayton Plains',
    state: 'MI',
    latitude: '42.683616',
    longitude: '-83.377192',
    timeZoneId: 'America/New_York',
  },
  '48331': {
    zip: '48331',
    city: 'Farmington',
    state: 'MI',
    latitude: '42.50508',
    longitude: '-83.40858',
    timeZoneId: 'America/New_York',
  },
  '48332': {
    zip: '48332',
    city: 'Farmington',
    state: 'MI',
    latitude: '42.465819',
    longitude: '-83.378683',
    timeZoneId: 'America/New_York',
  },
  '48333': {
    zip: '48333',
    city: 'Farmington',
    state: 'MI',
    latitude: '42.462218',
    longitude: '-83.377754',
    timeZoneId: 'America/New_York',
  },
  '48334': {
    zip: '48334',
    city: 'Farmington',
    state: 'MI',
    latitude: '42.505423',
    longitude: '-83.351825',
    timeZoneId: 'America/New_York',
  },
  '48335': {
    zip: '48335',
    city: 'Farmington',
    state: 'MI',
    latitude: '42.466205',
    longitude: '-83.401727',
    timeZoneId: 'America/New_York',
  },
  '48336': {
    zip: '48336',
    city: 'Farmington',
    state: 'MI',
    latitude: '42.458873',
    longitude: '-83.346843',
    timeZoneId: 'America/New_York',
  },
  '48340': {
    zip: '48340',
    city: 'Pontiac',
    state: 'MI',
    latitude: '42.672494',
    longitude: '-83.288704',
    timeZoneId: 'America/New_York',
  },
  '48341': {
    zip: '48341',
    city: 'Pontiac',
    state: 'MI',
    latitude: '42.626279',
    longitude: '-83.297758',
    timeZoneId: 'America/New_York',
  },
  '48342': {
    zip: '48342',
    city: 'Pontiac',
    state: 'MI',
    latitude: '42.646052',
    longitude: '-83.273628',
    timeZoneId: 'America/New_York',
  },
  '48343': {
    zip: '48343',
    city: 'Pontiac',
    state: 'MI',
    latitude: '42.640408',
    longitude: '-83.290741',
    timeZoneId: 'America/New_York',
  },
  '48346': {
    zip: '48346',
    city: 'Clarkston',
    state: 'MI',
    latitude: '42.722381',
    longitude: '-83.418152',
    timeZoneId: 'America/New_York',
  },
  '48347': {
    zip: '48347',
    city: 'Clarkston',
    state: 'MI',
    latitude: '42.735616',
    longitude: '-83.419991',
    timeZoneId: 'America/New_York',
  },
  '48348': {
    zip: '48348',
    city: 'Clarkston',
    state: 'MI',
    latitude: '42.767149',
    longitude: '-83.400047',
    timeZoneId: 'America/New_York',
  },
  '48350': {
    zip: '48350',
    city: 'Davisburg',
    state: 'MI',
    latitude: '42.742927',
    longitude: '-83.530899',
    timeZoneId: 'America/New_York',
  },
  '48353': {
    zip: '48353',
    city: 'Hartland',
    state: 'MI',
    latitude: '42.624245',
    longitude: '-83.707663',
    timeZoneId: 'America/New_York',
  },
  '48356': {
    zip: '48356',
    city: 'Highland',
    state: 'MI',
    latitude: '42.650009',
    longitude: '-83.582556',
    timeZoneId: 'America/New_York',
  },
  '48357': {
    zip: '48357',
    city: 'Highland',
    state: 'MI',
    latitude: '42.656319',
    longitude: '-83.63633',
    timeZoneId: 'America/New_York',
  },
  '48359': {
    zip: '48359',
    city: 'Lake Orion',
    state: 'MI',
    latitude: '42.722304',
    longitude: '-83.277927',
    timeZoneId: 'America/New_York',
  },
  '48360': {
    zip: '48360',
    city: 'Lake Orion',
    state: 'MI',
    latitude: '42.76632',
    longitude: '-83.27802',
    timeZoneId: 'America/New_York',
  },
  '48361': {
    zip: '48361',
    city: 'Lake Orion',
    state: 'MI',
    latitude: '42.784438',
    longitude: '-83.238082',
    timeZoneId: 'America/New_York',
  },
  '48362': {
    zip: '48362',
    city: 'Lake Orion',
    state: 'MI',
    latitude: '42.782837',
    longitude: '-83.269237',
    timeZoneId: 'America/New_York',
  },
  '48363': {
    zip: '48363',
    city: 'Oakland',
    state: 'MI',
    latitude: '42.764292',
    longitude: '-83.166518',
    timeZoneId: 'America/New_York',
  },
  '48366': {
    zip: '48366',
    city: 'Lakeville',
    state: 'MI',
    latitude: '42.821685',
    longitude: '-83.152182',
    timeZoneId: 'America/New_York',
  },
  '48367': {
    zip: '48367',
    city: 'Leonard',
    state: 'MI',
    latitude: '42.844709',
    longitude: '-83.140674',
    timeZoneId: 'America/New_York',
  },
  '48370': {
    zip: '48370',
    city: 'Oxford',
    state: 'MI',
    latitude: '42.843341',
    longitude: '-83.198007',
    timeZoneId: 'America/New_York',
  },
  '48371': {
    zip: '48371',
    city: 'Oxford',
    state: 'MI',
    latitude: '42.837217',
    longitude: '-83.289693',
    timeZoneId: 'America/New_York',
  },
  '48374': {
    zip: '48374',
    city: 'Novi',
    state: 'MI',
    latitude: '42.470836',
    longitude: '-83.523535',
    timeZoneId: 'America/New_York',
  },
  '48375': {
    zip: '48375',
    city: 'Novi',
    state: 'MI',
    latitude: '42.463733',
    longitude: '-83.460384',
    timeZoneId: 'America/New_York',
  },
  '48376': {
    zip: '48376',
    city: 'Novi',
    state: 'MI',
    latitude: '42.488585',
    longitude: '-83.476005',
    timeZoneId: 'America/New_York',
  },
  '48377': {
    zip: '48377',
    city: 'Novi',
    state: 'MI',
    latitude: '42.506757',
    longitude: '-83.475536',
    timeZoneId: 'America/New_York',
  },
  '48380': {
    zip: '48380',
    city: 'Milford',
    state: 'MI',
    latitude: '42.585954',
    longitude: '-83.65074',
    timeZoneId: 'America/New_York',
  },
  '48381': {
    zip: '48381',
    city: 'Milford',
    state: 'MI',
    latitude: '42.574828',
    longitude: '-83.594968',
    timeZoneId: 'America/New_York',
  },
  '48382': {
    zip: '48382',
    city: 'Commerce Township',
    state: 'MI',
    latitude: '42.58356',
    longitude: '-83.50032',
    timeZoneId: 'America/New_York',
  },
  '48383': {
    zip: '48383',
    city: 'White Lake',
    state: 'MI',
    latitude: '42.658765',
    longitude: '-83.542659',
    timeZoneId: 'America/New_York',
  },
  '48386': {
    zip: '48386',
    city: 'White Lake',
    state: 'MI',
    latitude: '42.642161',
    longitude: '-83.471924',
    timeZoneId: 'America/New_York',
  },
  '48387': {
    zip: '48387',
    city: 'Union Lake',
    state: 'MI',
    latitude: '42.612315',
    longitude: '-83.447838',
    timeZoneId: 'America/New_York',
  },
  '48390': {
    zip: '48390',
    city: 'Walled Lake',
    state: 'MI',
    latitude: '42.551225',
    longitude: '-83.477353',
    timeZoneId: 'America/New_York',
  },
  '48393': {
    zip: '48393',
    city: 'Wixom',
    state: 'MI',
    latitude: '42.518077',
    longitude: '-83.542455',
    timeZoneId: 'America/New_York',
  },
  '48397': {
    zip: '48397',
    city: 'Warren',
    state: 'MI',
    latitude: '42.503868',
    longitude: '-83.045668',
    timeZoneId: 'America/New_York',
  },
  '48401': {
    zip: '48401',
    city: 'Applegate',
    state: 'MI',
    latitude: '43.356418',
    longitude: '-82.647034',
    timeZoneId: 'America/New_York',
  },
  '48410': {
    zip: '48410',
    city: 'Argyle',
    state: 'MI',
    latitude: '43.564218',
    longitude: '-82.928368',
    timeZoneId: 'America/New_York',
  },
  '48411': {
    zip: '48411',
    city: 'Atlas',
    state: 'MI',
    latitude: '42.930338',
    longitude: '-83.544652',
    timeZoneId: 'America/New_York',
  },
  '48412': {
    zip: '48412',
    city: 'Attica',
    state: 'MI',
    latitude: '43.07544',
    longitude: '-83.17164',
    timeZoneId: 'America/New_York',
  },
  '48413': {
    zip: '48413',
    city: 'Bad Axe',
    state: 'MI',
    latitude: '43.804425',
    longitude: '-82.996869',
    timeZoneId: 'America/New_York',
  },
  '48414': {
    zip: '48414',
    city: 'Bancroft',
    state: 'MI',
    latitude: '42.866302',
    longitude: '-84.074561',
    timeZoneId: 'America/New_York',
  },
  '48415': {
    zip: '48415',
    city: 'Birch Run',
    state: 'MI',
    latitude: '43.266718',
    longitude: '-83.792556',
    timeZoneId: 'America/New_York',
  },
  '48416': {
    zip: '48416',
    city: 'Brown City',
    state: 'MI',
    latitude: '43.225819',
    longitude: '-82.945905',
    timeZoneId: 'America/New_York',
  },
  '48417': {
    zip: '48417',
    city: 'Burt',
    state: 'MI',
    latitude: '43.256877',
    longitude: '-83.93452',
    timeZoneId: 'America/New_York',
  },
  '48418': {
    zip: '48418',
    city: 'Byron',
    state: 'MI',
    latitude: '42.811174',
    longitude: '-83.97939',
    timeZoneId: 'America/New_York',
  },
  '48419': {
    zip: '48419',
    city: 'Carsonville',
    state: 'MI',
    latitude: '43.434677',
    longitude: '-82.631487',
    timeZoneId: 'America/New_York',
  },
  '48420': {
    zip: '48420',
    city: 'Clio',
    state: 'MI',
    latitude: '43.174189',
    longitude: '-83.719772',
    timeZoneId: 'America/New_York',
  },
  '48421': {
    zip: '48421',
    city: 'Columbiaville',
    state: 'MI',
    latitude: '43.150828',
    longitude: '-83.382321',
    timeZoneId: 'America/New_York',
  },
  '48422': {
    zip: '48422',
    city: 'Croswell',
    state: 'MI',
    latitude: '43.268333',
    longitude: '-82.632042',
    timeZoneId: 'America/New_York',
  },
  '48423': {
    zip: '48423',
    city: 'Davison',
    state: 'MI',
    latitude: '43.029997',
    longitude: '-83.523986',
    timeZoneId: 'America/New_York',
  },
  '48426': {
    zip: '48426',
    city: 'Decker',
    state: 'MI',
    latitude: '43.504956',
    longitude: '-83.065201',
    timeZoneId: 'America/New_York',
  },
  '48427': {
    zip: '48427',
    city: 'Deckerville',
    state: 'MI',
    latitude: '43.530187',
    longitude: '-82.706551',
    timeZoneId: 'America/New_York',
  },
  '48428': {
    zip: '48428',
    city: 'Dryden',
    state: 'MI',
    latitude: '42.936752',
    longitude: '-83.143404',
    timeZoneId: 'America/New_York',
  },
  '48429': {
    zip: '48429',
    city: 'Durand',
    state: 'MI',
    latitude: '42.909481',
    longitude: '-83.991837',
    timeZoneId: 'America/New_York',
  },
  '48430': {
    zip: '48430',
    city: 'Fenton',
    state: 'MI',
    latitude: '42.814469',
    longitude: '-83.715874',
    timeZoneId: 'America/New_York',
  },
  '48432': {
    zip: '48432',
    city: 'Filion',
    state: 'MI',
    latitude: '43.904518',
    longitude: '-82.998654',
    timeZoneId: 'America/New_York',
  },
  '48433': {
    zip: '48433',
    city: 'Flushing',
    state: 'MI',
    latitude: '43.068946',
    longitude: '-83.83719',
    timeZoneId: 'America/New_York',
  },
  '48434': {
    zip: '48434',
    city: 'Forestville',
    state: 'MI',
    latitude: '43.657845',
    longitude: '-82.609493',
    timeZoneId: 'America/New_York',
  },
  '48435': {
    zip: '48435',
    city: 'Fostoria',
    state: 'MI',
    latitude: '43.260793',
    longitude: '-83.382924',
    timeZoneId: 'America/New_York',
  },
  '48436': {
    zip: '48436',
    city: 'Gaines',
    state: 'MI',
    latitude: '42.866091',
    longitude: '-83.87545',
    timeZoneId: 'America/New_York',
  },
  '48437': {
    zip: '48437',
    city: 'Genesee',
    state: 'MI',
    latitude: '43.104282',
    longitude: '-83.638707',
    timeZoneId: 'America/New_York',
  },
  '48438': {
    zip: '48438',
    city: 'Goodrich',
    state: 'MI',
    latitude: '42.910423',
    longitude: '-83.507861',
    timeZoneId: 'America/New_York',
  },
  '48439': {
    zip: '48439',
    city: 'Grand Blanc',
    state: 'MI',
    latitude: '42.926659',
    longitude: '-83.638011',
    timeZoneId: 'America/New_York',
  },
  '48440': {
    zip: '48440',
    city: 'Hadley',
    state: 'MI',
    latitude: '42.954184',
    longitude: '-83.40069',
    timeZoneId: 'America/New_York',
  },
  '48441': {
    zip: '48441',
    city: 'Harbor Beach',
    state: 'MI',
    latitude: '43.809224',
    longitude: '-82.686669',
    timeZoneId: 'America/New_York',
  },
  '48442': {
    zip: '48442',
    city: 'Holly',
    state: 'MI',
    latitude: '42.792562',
    longitude: '-83.609652',
    timeZoneId: 'America/New_York',
  },
  '48444': {
    zip: '48444',
    city: 'Imlay City',
    state: 'MI',
    latitude: '43.055349',
    longitude: '-83.070632',
    timeZoneId: 'America/New_York',
  },
  '48445': {
    zip: '48445',
    city: 'Kinde',
    state: 'MI',
    latitude: '43.94916',
    longitude: '-83.004445',
    timeZoneId: 'America/New_York',
  },
  '48446': {
    zip: '48446',
    city: 'Lapeer',
    state: 'MI',
    latitude: '43.056009',
    longitude: '-83.327865',
    timeZoneId: 'America/New_York',
  },
  '48449': {
    zip: '48449',
    city: 'Lennon',
    state: 'MI',
    latitude: '42.996449',
    longitude: '-83.94961',
    timeZoneId: 'America/New_York',
  },
  '48450': {
    zip: '48450',
    city: 'Lexington',
    state: 'MI',
    latitude: '43.247918',
    longitude: '-82.529194',
    timeZoneId: 'America/New_York',
  },
  '48451': {
    zip: '48451',
    city: 'Linden',
    state: 'MI',
    latitude: '42.813625',
    longitude: '-83.801965',
    timeZoneId: 'America/New_York',
  },
  '48453': {
    zip: '48453',
    city: 'Marlette',
    state: 'MI',
    latitude: '43.339316',
    longitude: '-83.037832',
    timeZoneId: 'America/New_York',
  },
  '48454': {
    zip: '48454',
    city: 'Melvin',
    state: 'MI',
    latitude: '43.199609',
    longitude: '-82.822046',
    timeZoneId: 'America/New_York',
  },
  '48455': {
    zip: '48455',
    city: 'Metamora',
    state: 'MI',
    latitude: '42.941257',
    longitude: '-83.308589',
    timeZoneId: 'America/New_York',
  },
  '48456': {
    zip: '48456',
    city: 'Minden City',
    state: 'MI',
    latitude: '43.666281',
    longitude: '-82.738842',
    timeZoneId: 'America/New_York',
  },
  '48457': {
    zip: '48457',
    city: 'Montrose',
    state: 'MI',
    latitude: '43.178102',
    longitude: '-83.883104',
    timeZoneId: 'America/New_York',
  },
  '48458': {
    zip: '48458',
    city: 'Mount Morris',
    state: 'MI',
    latitude: '43.113921',
    longitude: '-83.689128',
    timeZoneId: 'America/New_York',
  },
  '48460': {
    zip: '48460',
    city: 'New Lothrop',
    state: 'MI',
    latitude: '43.108607',
    longitude: '-83.996083',
    timeZoneId: 'America/New_York',
  },
  '48461': {
    zip: '48461',
    city: 'North Branch',
    state: 'MI',
    latitude: '43.211436',
    longitude: '-83.212904',
    timeZoneId: 'America/New_York',
  },
  '48462': {
    zip: '48462',
    city: 'Ortonville',
    state: 'MI',
    latitude: '42.838413',
    longitude: '-83.428185',
    timeZoneId: 'America/New_York',
  },
  '48463': {
    zip: '48463',
    city: 'Otisville',
    state: 'MI',
    latitude: '43.154524',
    longitude: '-83.519011',
    timeZoneId: 'America/New_York',
  },
  '48464': {
    zip: '48464',
    city: 'Otter Lake',
    state: 'MI',
    latitude: '43.214142',
    longitude: '-83.425096',
    timeZoneId: 'America/New_York',
  },
  '48465': {
    zip: '48465',
    city: 'Palms',
    state: 'MI',
    latitude: '43.629223',
    longitude: '-82.631547',
    timeZoneId: 'America/New_York',
  },
  '48466': {
    zip: '48466',
    city: 'Peck',
    state: 'MI',
    latitude: '43.274958',
    longitude: '-82.811823',
    timeZoneId: 'America/New_York',
  },
  '48467': {
    zip: '48467',
    city: 'Port Austin',
    state: 'MI',
    latitude: '44.00874',
    longitude: '-82.98078',
    timeZoneId: 'America/New_York',
  },
  '48468': {
    zip: '48468',
    city: 'Port Hope',
    state: 'MI',
    latitude: '43.948198',
    longitude: '-82.781696',
    timeZoneId: 'America/New_York',
  },
  '48469': {
    zip: '48469',
    city: 'Port Sanilac',
    state: 'MI',
    latitude: '43.476841',
    longitude: '-82.573667',
    timeZoneId: 'America/New_York',
  },
  '48470': {
    zip: '48470',
    city: 'Ruth',
    state: 'MI',
    latitude: '43.732849',
    longitude: '-82.749407',
    timeZoneId: 'America/New_York',
  },
  '48471': {
    zip: '48471',
    city: 'Sandusky',
    state: 'MI',
    latitude: '43.420077',
    longitude: '-82.851553',
    timeZoneId: 'America/New_York',
  },
  '48472': {
    zip: '48472',
    city: 'Snover',
    state: 'MI',
    latitude: '43.521197',
    longitude: '-82.956304',
    timeZoneId: 'America/New_York',
  },
  '48473': {
    zip: '48473',
    city: 'Swartz Creek',
    state: 'MI',
    latitude: '42.94212',
    longitude: '-83.82804',
    timeZoneId: 'America/New_York',
  },
  '48475': {
    zip: '48475',
    city: 'Ubly',
    state: 'MI',
    latitude: '43.714517',
    longitude: '-82.944482',
    timeZoneId: 'America/New_York',
  },
  '48476': {
    zip: '48476',
    city: 'Vernon',
    state: 'MI',
    latitude: '42.939227',
    longitude: '-84.027904',
    timeZoneId: 'America/New_York',
  },
  '48480': {
    zip: '48480',
    city: 'Grand Blanc',
    state: 'MI',
    latitude: '42.925523',
    longitude: '-83.632082',
    timeZoneId: 'America/New_York',
  },
  '48501': {
    zip: '48501',
    city: 'Flint',
    state: 'MI',
    latitude: '42.966167',
    longitude: '-83.777843',
    timeZoneId: 'America/New_York',
  },
  '48502': {
    zip: '48502',
    city: 'Flint',
    state: 'MI',
    latitude: '43.014202',
    longitude: '-83.68892',
    timeZoneId: 'America/New_York',
  },
  '48503': {
    zip: '48503',
    city: 'Flint',
    state: 'MI',
    latitude: '43.012315',
    longitude: '-83.69206',
    timeZoneId: 'America/New_York',
  },
  '48504': {
    zip: '48504',
    city: 'Flint',
    state: 'MI',
    latitude: '43.035862',
    longitude: '-83.74276',
    timeZoneId: 'America/New_York',
  },
  '48505': {
    zip: '48505',
    city: 'Flint',
    state: 'MI',
    latitude: '43.062702',
    longitude: '-83.692479',
    timeZoneId: 'America/New_York',
  },
  '48506': {
    zip: '48506',
    city: 'Flint',
    state: 'MI',
    latitude: '43.051354',
    longitude: '-83.637617',
    timeZoneId: 'America/New_York',
  },
  '48507': {
    zip: '48507',
    city: 'Flint',
    state: 'MI',
    latitude: '42.970942',
    longitude: '-83.703232',
    timeZoneId: 'America/New_York',
  },
  '48509': {
    zip: '48509',
    city: 'Burton',
    state: 'MI',
    latitude: '43.024922',
    longitude: '-83.604329',
    timeZoneId: 'America/New_York',
  },
  '48519': {
    zip: '48519',
    city: 'Burton',
    state: 'MI',
    latitude: '42.989167',
    longitude: '-83.615887',
    timeZoneId: 'America/New_York',
  },
  '48529': {
    zip: '48529',
    city: 'Burton',
    state: 'MI',
    latitude: '42.974171',
    longitude: '-83.664604',
    timeZoneId: 'America/New_York',
  },
  '48531': {
    zip: '48531',
    city: 'Flint',
    state: 'MI',
    latitude: '43.055082',
    longitude: '-83.750365',
    timeZoneId: 'America/New_York',
  },
  '48532': {
    zip: '48532',
    city: 'Flint',
    state: 'MI',
    latitude: '43.007712',
    longitude: '-83.774965',
    timeZoneId: 'America/New_York',
  },
  '48550': {
    zip: '48550',
    city: 'Flint',
    state: 'MI',
    latitude: '43.065739',
    longitude: '-83.688105',
    timeZoneId: 'America/New_York',
  },
  '48551': {
    zip: '48551',
    city: 'Flint',
    state: 'MI',
    latitude: '42.987259',
    longitude: '-83.711956',
    timeZoneId: 'America/New_York',
  },
  '48552': {
    zip: '48552',
    city: 'Flint',
    state: 'MI',
    latitude: '42.990914',
    longitude: '-83.711409',
    timeZoneId: 'America/New_York',
  },
  '48553': {
    zip: '48553',
    city: 'Flint',
    state: 'MI',
    latitude: '42.974333',
    longitude: '-83.716168',
    timeZoneId: 'America/New_York',
  },
  '48554': {
    zip: '48554',
    city: 'Flint',
    state: 'MI',
    latitude: '42.936862',
    longitude: '-83.823819',
    timeZoneId: 'America/New_York',
  },
  '48555': {
    zip: '48555',
    city: 'Flint',
    state: 'MI',
    latitude: '43.059116',
    longitude: '-83.744242',
    timeZoneId: 'America/New_York',
  },
  '48556': {
    zip: '48556',
    city: 'Flint',
    state: 'MI',
    latitude: '43.064861',
    longitude: '-83.626394',
    timeZoneId: 'America/New_York',
  },
  '48557': {
    zip: '48557',
    city: 'Flint',
    state: 'MI',
    latitude: '42.987118',
    longitude: '-83.679301',
    timeZoneId: 'America/New_York',
  },
  '48601': {
    zip: '48601',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.405416',
    longitude: '-83.900719',
    timeZoneId: 'America/New_York',
  },
  '48602': {
    zip: '48602',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.420918',
    longitude: '-83.974434',
    timeZoneId: 'America/New_York',
  },
  '48603': {
    zip: '48603',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.433525',
    longitude: '-84.027056',
    timeZoneId: 'America/New_York',
  },
  '48604': {
    zip: '48604',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.47323',
    longitude: '-83.947983',
    timeZoneId: 'America/New_York',
  },
  '48605': {
    zip: '48605',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.459077',
    longitude: '-84.052167',
    timeZoneId: 'America/New_York',
  },
  '48606': {
    zip: '48606',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.431558',
    longitude: '-83.93624',
    timeZoneId: 'America/New_York',
  },
  '48607': {
    zip: '48607',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.431818',
    longitude: '-83.932056',
    timeZoneId: 'America/New_York',
  },
  '48608': {
    zip: '48608',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.439672',
    longitude: '-84.029446',
    timeZoneId: 'America/New_York',
  },
  '48609': {
    zip: '48609',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.410854',
    longitude: '-84.09041',
    timeZoneId: 'America/New_York',
  },
  '48610': {
    zip: '48610',
    city: 'Alger',
    state: 'MI',
    latitude: '44.124868',
    longitude: '-84.088232',
    timeZoneId: 'America/New_York',
  },
  '48611': {
    zip: '48611',
    city: 'Auburn',
    state: 'MI',
    latitude: '43.619125',
    longitude: '-84.089278',
    timeZoneId: 'America/New_York',
  },
  '48612': {
    zip: '48612',
    city: 'Beaverton',
    state: 'MI',
    latitude: '43.875973',
    longitude: '-84.419697',
    timeZoneId: 'America/New_York',
  },
  '48613': {
    zip: '48613',
    city: 'Bentley',
    state: 'MI',
    latitude: '43.935665',
    longitude: '-84.112013',
    timeZoneId: 'America/New_York',
  },
  '48614': {
    zip: '48614',
    city: 'Brant',
    state: 'MI',
    latitude: '43.246726',
    longitude: '-84.294606',
    timeZoneId: 'America/New_York',
  },
  '48615': {
    zip: '48615',
    city: 'Breckenridge',
    state: 'MI',
    latitude: '43.39174',
    longitude: '-84.482871',
    timeZoneId: 'America/New_York',
  },
  '48616': {
    zip: '48616',
    city: 'Chesaning',
    state: 'MI',
    latitude: '43.191198',
    longitude: '-84.118351',
    timeZoneId: 'America/New_York',
  },
  '48617': {
    zip: '48617',
    city: 'Clare',
    state: 'MI',
    latitude: '43.862429',
    longitude: '-84.734662',
    timeZoneId: 'America/New_York',
  },
  '48618': {
    zip: '48618',
    city: 'Coleman',
    state: 'MI',
    latitude: '43.744516',
    longitude: '-84.546925',
    timeZoneId: 'America/New_York',
  },
  '48619': {
    zip: '48619',
    city: 'Comins',
    state: 'MI',
    latitude: '44.803567',
    longitude: '-84.037996',
    timeZoneId: 'America/New_York',
  },
  '48620': {
    zip: '48620',
    city: 'Edenville',
    state: 'MI',
    latitude: '43.799885',
    longitude: '-84.388017',
    timeZoneId: 'America/New_York',
  },
  '48621': {
    zip: '48621',
    city: 'Fairview',
    state: 'MI',
    latitude: '44.723788',
    longitude: '-84.037663',
    timeZoneId: 'America/New_York',
  },
  '48622': {
    zip: '48622',
    city: 'Farwell',
    state: 'MI',
    latitude: '43.863136',
    longitude: '-84.868062',
    timeZoneId: 'America/New_York',
  },
  '48623': {
    zip: '48623',
    city: 'Freeland',
    state: 'MI',
    latitude: '43.50176',
    longitude: '-84.128179',
    timeZoneId: 'America/New_York',
  },
  '48624': {
    zip: '48624',
    city: 'Gladwin',
    state: 'MI',
    latitude: '44.036102',
    longitude: '-84.457382',
    timeZoneId: 'America/New_York',
  },
  '48625': {
    zip: '48625',
    city: 'Harrison',
    state: 'MI',
    latitude: '44.041448',
    longitude: '-84.80707',
    timeZoneId: 'America/New_York',
  },
  '48626': {
    zip: '48626',
    city: 'Hemlock',
    state: 'MI',
    latitude: '43.410234',
    longitude: '-84.223586',
    timeZoneId: 'America/New_York',
  },
  '48627': {
    zip: '48627',
    city: 'Higgins Lake',
    state: 'MI',
    latitude: '44.442024',
    longitude: '-84.743635',
    timeZoneId: 'America/New_York',
  },
  '48628': {
    zip: '48628',
    city: 'Hope',
    state: 'MI',
    latitude: '43.780241',
    longitude: '-84.331596',
    timeZoneId: 'America/New_York',
  },
  '48629': {
    zip: '48629',
    city: 'Houghton Lake',
    state: 'MI',
    latitude: '44.325299',
    longitude: '-84.736993',
    timeZoneId: 'America/New_York',
  },
  '48630': {
    zip: '48630',
    city: 'Houghton Lake Heights',
    state: 'MI',
    latitude: '44.325418',
    longitude: '-84.774328',
    timeZoneId: 'America/New_York',
  },
  '48631': {
    zip: '48631',
    city: 'Kawkawlin',
    state: 'MI',
    latitude: '43.676581',
    longitude: '-83.989626',
    timeZoneId: 'America/New_York',
  },
  '48632': {
    zip: '48632',
    city: 'Lake',
    state: 'MI',
    latitude: '43.895163',
    longitude: '-85.005528',
    timeZoneId: 'America/New_York',
  },
  '48633': {
    zip: '48633',
    city: 'Lake George',
    state: 'MI',
    latitude: '43.980222',
    longitude: '-84.906123',
    timeZoneId: 'America/New_York',
  },
  '48634': {
    zip: '48634',
    city: 'Linwood',
    state: 'MI',
    latitude: '43.749601',
    longitude: '-84.040156',
    timeZoneId: 'America/New_York',
  },
  '48635': {
    zip: '48635',
    city: 'Lupton',
    state: 'MI',
    latitude: '44.402931',
    longitude: '-83.988841',
    timeZoneId: 'America/New_York',
  },
  '48636': {
    zip: '48636',
    city: 'Luzerne',
    state: 'MI',
    latitude: '44.637482',
    longitude: '-84.252637',
    timeZoneId: 'America/New_York',
  },
  '48637': {
    zip: '48637',
    city: 'Merrill',
    state: 'MI',
    latitude: '43.397782',
    longitude: '-84.322285',
    timeZoneId: 'America/New_York',
  },
  '48638': {
    zip: '48638',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.4221',
    longitude: '-84.0138',
    timeZoneId: 'America/New_York',
  },
  '48640': {
    zip: '48640',
    city: 'Midland',
    state: 'MI',
    latitude: '43.630483',
    longitude: '-84.274746',
    timeZoneId: 'America/New_York',
  },
  '48641': {
    zip: '48641',
    city: 'Midland',
    state: 'MI',
    latitude: '43.57818',
    longitude: '-84.358213',
    timeZoneId: 'America/New_York',
  },
  '48642': {
    zip: '48642',
    city: 'Midland',
    state: 'MI',
    latitude: '43.634232',
    longitude: '-84.203334',
    timeZoneId: 'America/New_York',
  },
  '48647': {
    zip: '48647',
    city: 'Mio',
    state: 'MI',
    latitude: '44.659301',
    longitude: '-84.115729',
    timeZoneId: 'America/New_York',
  },
  '48649': {
    zip: '48649',
    city: 'Oakley',
    state: 'MI',
    latitude: '43.151296',
    longitude: '-84.201887',
    timeZoneId: 'America/New_York',
  },
  '48650': {
    zip: '48650',
    city: 'Pinconning',
    state: 'MI',
    latitude: '43.84768',
    longitude: '-83.990744',
    timeZoneId: 'America/New_York',
  },
  '48651': {
    zip: '48651',
    city: 'Prudenville',
    state: 'MI',
    latitude: '44.298054',
    longitude: '-84.66491',
    timeZoneId: 'America/New_York',
  },
  '48652': {
    zip: '48652',
    city: 'Rhodes',
    state: 'MI',
    latitude: '43.868179',
    longitude: '-84.213418',
    timeZoneId: 'America/New_York',
  },
  '48653': {
    zip: '48653',
    city: 'Roscommon',
    state: 'MI',
    latitude: '44.466131',
    longitude: '-84.678344',
    timeZoneId: 'America/New_York',
  },
  '48654': {
    zip: '48654',
    city: 'Rose City',
    state: 'MI',
    latitude: '44.433039',
    longitude: '-84.122243',
    timeZoneId: 'America/New_York',
  },
  '48655': {
    zip: '48655',
    city: 'Saint Charles',
    state: 'MI',
    latitude: '43.295333',
    longitude: '-84.16365',
    timeZoneId: 'America/New_York',
  },
  '48656': {
    zip: '48656',
    city: 'Saint Helen',
    state: 'MI',
    latitude: '44.364342',
    longitude: '-84.430308',
    timeZoneId: 'America/New_York',
  },
  '48657': {
    zip: '48657',
    city: 'Sanford',
    state: 'MI',
    latitude: '43.703198',
    longitude: '-84.393657',
    timeZoneId: 'America/New_York',
  },
  '48658': {
    zip: '48658',
    city: 'Standish',
    state: 'MI',
    latitude: '43.977133',
    longitude: '-83.934633',
    timeZoneId: 'America/New_York',
  },
  '48659': {
    zip: '48659',
    city: 'Sterling',
    state: 'MI',
    latitude: '44.064327',
    longitude: '-84.025337',
    timeZoneId: 'America/New_York',
  },
  '48661': {
    zip: '48661',
    city: 'West Branch',
    state: 'MI',
    latitude: '44.289024',
    longitude: '-84.21901',
    timeZoneId: 'America/New_York',
  },
  '48662': {
    zip: '48662',
    city: 'Wheeler',
    state: 'MI',
    latitude: '43.388718',
    longitude: '-84.416657',
    timeZoneId: 'America/New_York',
  },
  '48663': {
    zip: '48663',
    city: 'Saginaw',
    state: 'MI',
    latitude: '43.419367',
    longitude: '-83.947813',
    timeZoneId: 'America/New_York',
  },
  '48667': {
    zip: '48667',
    city: 'Midland',
    state: 'MI',
    latitude: '43.610465',
    longitude: '-84.237362',
    timeZoneId: 'America/New_York',
  },
  '48670': {
    zip: '48670',
    city: 'Midland',
    state: 'MI',
    latitude: '43.636969',
    longitude: '-84.25606',
    timeZoneId: 'America/New_York',
  },
  '48674': {
    zip: '48674',
    city: 'Midland',
    state: 'MI',
    latitude: '43.707462',
    longitude: '-84.228673',
    timeZoneId: 'America/New_York',
  },
  '48686': {
    zip: '48686',
    city: 'Midland',
    state: 'MI',
    latitude: '43.601347',
    longitude: '-84.206759',
    timeZoneId: 'America/New_York',
  },
  '48701': {
    zip: '48701',
    city: 'Akron',
    state: 'MI',
    latitude: '43.58853',
    longitude: '-83.549241',
    timeZoneId: 'America/New_York',
  },
  '48703': {
    zip: '48703',
    city: 'Au Gres',
    state: 'MI',
    latitude: '44.060348',
    longitude: '-83.644117',
    timeZoneId: 'America/New_York',
  },
  '48705': {
    zip: '48705',
    city: 'Barton City',
    state: 'MI',
    latitude: '44.678735',
    longitude: '-83.632265',
    timeZoneId: 'America/New_York',
  },
  '48706': {
    zip: '48706',
    city: 'Bay City',
    state: 'MI',
    latitude: '43.608032',
    longitude: '-83.919043',
    timeZoneId: 'America/New_York',
  },
  '48707': {
    zip: '48707',
    city: 'Bay City',
    state: 'MI',
    latitude: '43.529416',
    longitude: '-83.841192',
    timeZoneId: 'America/New_York',
  },
  '48708': {
    zip: '48708',
    city: 'Bay City',
    state: 'MI',
    latitude: '43.581561',
    longitude: '-83.868517',
    timeZoneId: 'America/New_York',
  },
  '48710': {
    zip: '48710',
    city: 'University Center',
    state: 'MI',
    latitude: '43.561826',
    longitude: '-83.987515',
    timeZoneId: 'America/New_York',
  },
  '48720': {
    zip: '48720',
    city: 'Bay Port',
    state: 'MI',
    latitude: '43.827437',
    longitude: '-83.354983',
    timeZoneId: 'America/New_York',
  },
  '48721': {
    zip: '48721',
    city: 'Black River',
    state: 'MI',
    latitude: '44.796699',
    longitude: '-83.324416',
    timeZoneId: 'America/New_York',
  },
  '48722': {
    zip: '48722',
    city: 'Bridgeport',
    state: 'MI',
    latitude: '43.350825',
    longitude: '-83.851455',
    timeZoneId: 'America/New_York',
  },
  '48723': {
    zip: '48723',
    city: 'Caro',
    state: 'MI',
    latitude: '43.48612',
    longitude: '-83.389537',
    timeZoneId: 'America/New_York',
  },
  '48724': {
    zip: '48724',
    city: 'Carrollton',
    state: 'MI',
    latitude: '43.461778',
    longitude: '-83.92602',
    timeZoneId: 'America/New_York',
  },
  '48725': {
    zip: '48725',
    city: 'Caseville',
    state: 'MI',
    latitude: '43.947964',
    longitude: '-83.23418',
    timeZoneId: 'America/New_York',
  },
  '48726': {
    zip: '48726',
    city: 'Cass City',
    state: 'MI',
    latitude: '43.592764',
    longitude: '-83.199936',
    timeZoneId: 'America/New_York',
  },
  '48727': {
    zip: '48727',
    city: 'Clifford',
    state: 'MI',
    latitude: '43.306457',
    longitude: '-83.176628',
    timeZoneId: 'America/New_York',
  },
  '48728': {
    zip: '48728',
    city: 'Curran',
    state: 'MI',
    latitude: '44.728327',
    longitude: '-83.809576',
    timeZoneId: 'America/New_York',
  },
  '48729': {
    zip: '48729',
    city: 'Deford',
    state: 'MI',
    latitude: '43.491897',
    longitude: '-83.183767',
    timeZoneId: 'America/New_York',
  },
  '48730': {
    zip: '48730',
    city: 'East Tawas',
    state: 'MI',
    latitude: '44.308832',
    longitude: '-83.471081',
    timeZoneId: 'America/New_York',
  },
  '48731': {
    zip: '48731',
    city: 'Elkton',
    state: 'MI',
    latitude: '43.833028',
    longitude: '-83.158864',
    timeZoneId: 'America/New_York',
  },
  '48732': {
    zip: '48732',
    city: 'Essexville',
    state: 'MI',
    latitude: '43.605497',
    longitude: '-83.822737',
    timeZoneId: 'America/New_York',
  },
  '48733': {
    zip: '48733',
    city: 'Fairgrove',
    state: 'MI',
    latitude: '43.533081',
    longitude: '-83.593351',
    timeZoneId: 'America/New_York',
  },
  '48734': {
    zip: '48734',
    city: 'Frankenmuth',
    state: 'MI',
    latitude: '43.340608',
    longitude: '-83.747615',
    timeZoneId: 'America/New_York',
  },
  '48735': {
    zip: '48735',
    city: 'Gagetown',
    state: 'MI',
    latitude: '43.649713',
    longitude: '-83.273095',
    timeZoneId: 'America/New_York',
  },
  '48737': {
    zip: '48737',
    city: 'Glennie',
    state: 'MI',
    latitude: '44.549664',
    longitude: '-83.705696',
    timeZoneId: 'America/New_York',
  },
  '48738': {
    zip: '48738',
    city: 'Greenbush',
    state: 'MI',
    latitude: '44.565381',
    longitude: '-83.332281',
    timeZoneId: 'America/New_York',
  },
  '48739': {
    zip: '48739',
    city: 'Hale',
    state: 'MI',
    latitude: '44.398678',
    longitude: '-83.813915',
    timeZoneId: 'America/New_York',
  },
  '48740': {
    zip: '48740',
    city: 'Harrisville',
    state: 'MI',
    latitude: '44.658699',
    longitude: '-83.358493',
    timeZoneId: 'America/New_York',
  },
  '48741': {
    zip: '48741',
    city: 'Kingston',
    state: 'MI',
    latitude: '43.405212',
    longitude: '-83.175423',
    timeZoneId: 'America/New_York',
  },
  '48742': {
    zip: '48742',
    city: 'Lincoln',
    state: 'MI',
    latitude: '44.729167',
    longitude: '-83.439572',
    timeZoneId: 'America/New_York',
  },
  '48743': {
    zip: '48743',
    city: 'Long Lake',
    state: 'MI',
    latitude: '44.429715',
    longitude: '-83.87728',
    timeZoneId: 'America/New_York',
  },
  '48744': {
    zip: '48744',
    city: 'Mayville',
    state: 'MI',
    latitude: '43.354988',
    longitude: '-83.361552',
    timeZoneId: 'America/New_York',
  },
  '48745': {
    zip: '48745',
    city: 'Mikado',
    state: 'MI',
    latitude: '44.577581',
    longitude: '-83.456148',
    timeZoneId: 'America/New_York',
  },
  '48746': {
    zip: '48746',
    city: 'Millington',
    state: 'MI',
    latitude: '43.278288',
    longitude: '-83.538168',
    timeZoneId: 'America/New_York',
  },
  '48747': {
    zip: '48747',
    city: 'Munger',
    state: 'MI',
    latitude: '43.524928',
    longitude: '-83.756592',
    timeZoneId: 'America/New_York',
  },
  '48748': {
    zip: '48748',
    city: 'National City',
    state: 'MI',
    latitude: '44.300286',
    longitude: '-83.674914',
    timeZoneId: 'America/New_York',
  },
  '48749': {
    zip: '48749',
    city: 'Omer',
    state: 'MI',
    latitude: '44.056004',
    longitude: '-83.895744',
    timeZoneId: 'America/New_York',
  },
  '48750': {
    zip: '48750',
    city: 'Oscoda',
    state: 'MI',
    latitude: '44.448307',
    longitude: '-83.380374',
    timeZoneId: 'America/New_York',
  },
  '48754': {
    zip: '48754',
    city: 'Owendale',
    state: 'MI',
    latitude: '43.733485',
    longitude: '-83.244446',
    timeZoneId: 'America/New_York',
  },
  '48755': {
    zip: '48755',
    city: 'Pigeon',
    state: 'MI',
    latitude: '43.854179',
    longitude: '-83.289645',
    timeZoneId: 'America/New_York',
  },
  '48756': {
    zip: '48756',
    city: 'Prescott',
    state: 'MI',
    latitude: '44.183333',
    longitude: '-84.035278',
    timeZoneId: 'America/New_York',
  },
  '48757': {
    zip: '48757',
    city: 'Reese',
    state: 'MI',
    latitude: '43.463098',
    longitude: '-83.671606',
    timeZoneId: 'America/New_York',
  },
  '48758': {
    zip: '48758',
    city: 'Richville',
    state: 'MI',
    latitude: '43.406608',
    longitude: '-83.673253',
    timeZoneId: 'America/New_York',
  },
  '48759': {
    zip: '48759',
    city: 'Sebewaing',
    state: 'MI',
    latitude: '43.73218',
    longitude: '-83.41296',
    timeZoneId: 'America/New_York',
  },
  '48760': {
    zip: '48760',
    city: 'Silverwood',
    state: 'MI',
    latitude: '43.321577',
    longitude: '-83.247216',
    timeZoneId: 'America/New_York',
  },
  '48761': {
    zip: '48761',
    city: 'South Branch',
    state: 'MI',
    latitude: '44.475118',
    longitude: '-83.916018',
    timeZoneId: 'America/New_York',
  },
  '48762': {
    zip: '48762',
    city: 'Spruce',
    state: 'MI',
    latitude: '44.793554',
    longitude: '-83.519384',
    timeZoneId: 'America/New_York',
  },
  '48763': {
    zip: '48763',
    city: 'Tawas City',
    state: 'MI',
    latitude: '44.265786',
    longitude: '-83.563369',
    timeZoneId: 'America/New_York',
  },
  '48764': {
    zip: '48764',
    city: 'Tawas City',
    state: 'MI',
    latitude: '44.272502',
    longitude: '-83.531206',
    timeZoneId: 'America/New_York',
  },
  '48765': {
    zip: '48765',
    city: 'Turner',
    state: 'MI',
    latitude: '44.130949',
    longitude: '-83.720491',
    timeZoneId: 'America/New_York',
  },
  '48766': {
    zip: '48766',
    city: 'Twining',
    state: 'MI',
    latitude: '44.119328',
    longitude: '-83.853012',
    timeZoneId: 'America/New_York',
  },
  '48767': {
    zip: '48767',
    city: 'Unionville',
    state: 'MI',
    latitude: '43.644452',
    longitude: '-83.470258',
    timeZoneId: 'America/New_York',
  },
  '48768': {
    zip: '48768',
    city: 'Vassar',
    state: 'MI',
    latitude: '43.368767',
    longitude: '-83.574866',
    timeZoneId: 'America/New_York',
  },
  '48770': {
    zip: '48770',
    city: 'Whittemore',
    state: 'MI',
    latitude: '44.234603',
    longitude: '-83.811373',
    timeZoneId: 'America/New_York',
  },
  '48787': {
    zip: '48787',
    city: 'Frankenmuth',
    state: 'MI',
    latitude: '43.331555',
    longitude: '-83.741778',
    timeZoneId: 'America/New_York',
  },
  '48801': {
    zip: '48801',
    city: 'Alma',
    state: 'MI',
    latitude: '43.375805',
    longitude: '-84.664584',
    timeZoneId: 'America/New_York',
  },
  '48804': {
    zip: '48804',
    city: 'Mount Pleasant',
    state: 'MI',
    latitude: '43.606089',
    longitude: '-84.688548',
    timeZoneId: 'America/New_York',
  },
  '48805': {
    zip: '48805',
    city: 'Okemos',
    state: 'MI',
    latitude: '42.707511',
    longitude: '-84.415014',
    timeZoneId: 'America/New_York',
  },
  '48806': {
    zip: '48806',
    city: 'Ashley',
    state: 'MI',
    latitude: '43.174376',
    longitude: '-84.504172',
    timeZoneId: 'America/New_York',
  },
  '48807': {
    zip: '48807',
    city: 'Bannister',
    state: 'MI',
    latitude: '43.140978',
    longitude: '-84.424584',
    timeZoneId: 'America/New_York',
  },
  '48808': {
    zip: '48808',
    city: 'Bath',
    state: 'MI',
    latitude: '42.81818',
    longitude: '-84.46061',
    timeZoneId: 'America/New_York',
  },
  '48809': {
    zip: '48809',
    city: 'Belding',
    state: 'MI',
    latitude: '43.077173',
    longitude: '-85.228388',
    timeZoneId: 'America/New_York',
  },
  '48811': {
    zip: '48811',
    city: 'Carson City',
    state: 'MI',
    latitude: '43.181337',
    longitude: '-84.868066',
    timeZoneId: 'America/New_York',
  },
  '48812': {
    zip: '48812',
    city: 'Cedar Lake',
    state: 'MI',
    latitude: '43.404686',
    longitude: '-84.976435',
    timeZoneId: 'America/New_York',
  },
  '48813': {
    zip: '48813',
    city: 'Charlotte',
    state: 'MI',
    latitude: '42.57239',
    longitude: '-84.838031',
    timeZoneId: 'America/New_York',
  },
  '48815': {
    zip: '48815',
    city: 'Clarksville',
    state: 'MI',
    latitude: '42.844524',
    longitude: '-85.247239',
    timeZoneId: 'America/New_York',
  },
  '48816': {
    zip: '48816',
    city: 'Cohoctah',
    state: 'MI',
    latitude: '42.758825',
    longitude: '-83.948518',
    timeZoneId: 'America/New_York',
  },
  '48817': {
    zip: '48817',
    city: 'Corunna',
    state: 'MI',
    latitude: '43.005955',
    longitude: '-84.065189',
    timeZoneId: 'America/New_York',
  },
  '48818': {
    zip: '48818',
    city: 'Crystal',
    state: 'MI',
    latitude: '43.271074',
    longitude: '-84.909865',
    timeZoneId: 'America/New_York',
  },
  '48819': {
    zip: '48819',
    city: 'Dansville',
    state: 'MI',
    latitude: '42.547264',
    longitude: '-84.279402',
    timeZoneId: 'America/New_York',
  },
  '48820': {
    zip: '48820',
    city: 'Dewitt',
    state: 'MI',
    latitude: '42.846571',
    longitude: '-84.590334',
    timeZoneId: 'America/New_York',
  },
  '48821': {
    zip: '48821',
    city: 'Dimondale',
    state: 'MI',
    latitude: '42.648912',
    longitude: '-84.645594',
    timeZoneId: 'America/New_York',
  },
  '48822': {
    zip: '48822',
    city: 'Eagle',
    state: 'MI',
    latitude: '42.976002',
    longitude: '-84.535243',
    timeZoneId: 'America/New_York',
  },
  '48823': {
    zip: '48823',
    city: 'East Lansing',
    state: 'MI',
    latitude: '42.745357',
    longitude: '-84.470605',
    timeZoneId: 'America/New_York',
  },
  '48824': {
    zip: '48824',
    city: 'East Lansing',
    state: 'MI',
    latitude: '42.653979',
    longitude: '-84.492032',
    timeZoneId: 'America/New_York',
  },
  '48825': {
    zip: '48825',
    city: 'East Lansing',
    state: 'MI',
    latitude: '42.723616',
    longitude: '-84.467477',
    timeZoneId: 'America/New_York',
  },
  '48826': {
    zip: '48826',
    city: 'East Lansing',
    state: 'MI',
    latitude: '42.744186',
    longitude: '-84.4841',
    timeZoneId: 'America/New_York',
  },
  '48827': {
    zip: '48827',
    city: 'Eaton Rapids',
    state: 'MI',
    latitude: '42.508608',
    longitude: '-84.66871',
    timeZoneId: 'America/New_York',
  },
  '48829': {
    zip: '48829',
    city: 'Edmore',
    state: 'MI',
    latitude: '43.398784',
    longitude: '-85.028071',
    timeZoneId: 'America/New_York',
  },
  '48830': {
    zip: '48830',
    city: 'Elm Hall',
    state: 'MI',
    latitude: '43.362598',
    longitude: '-84.834064',
    timeZoneId: 'America/New_York',
  },
  '48831': {
    zip: '48831',
    city: 'Elsie',
    state: 'MI',
    latitude: '43.089874',
    longitude: '-84.427686',
    timeZoneId: 'America/New_York',
  },
  '48832': {
    zip: '48832',
    city: 'Elwell',
    state: 'MI',
    latitude: '43.407798',
    longitude: '-84.771317',
    timeZoneId: 'America/New_York',
  },
  '48833': {
    zip: '48833',
    city: 'Eureka',
    state: 'MI',
    latitude: '43.103318',
    longitude: '-84.507212',
    timeZoneId: 'America/New_York',
  },
  '48834': {
    zip: '48834',
    city: 'Fenwick',
    state: 'MI',
    latitude: '43.146154',
    longitude: '-85.048477',
    timeZoneId: 'America/New_York',
  },
  '48835': {
    zip: '48835',
    city: 'Fowler',
    state: 'MI',
    latitude: '43.010137',
    longitude: '-84.749958',
    timeZoneId: 'America/New_York',
  },
  '48836': {
    zip: '48836',
    city: 'Fowlerville',
    state: 'MI',
    latitude: '42.663825',
    longitude: '-84.068992',
    timeZoneId: 'America/New_York',
  },
  '48837': {
    zip: '48837',
    city: 'Grand Ledge',
    state: 'MI',
    latitude: '42.741897',
    longitude: '-84.743758',
    timeZoneId: 'America/New_York',
  },
  '48838': {
    zip: '48838',
    city: 'Greenville',
    state: 'MI',
    latitude: '43.190086',
    longitude: '-85.247315',
    timeZoneId: 'America/New_York',
  },
  '48840': {
    zip: '48840',
    city: 'Haslett',
    state: 'MI',
    latitude: '42.751765',
    longitude: '-84.395613',
    timeZoneId: 'America/New_York',
  },
  '48841': {
    zip: '48841',
    city: 'Henderson',
    state: 'MI',
    latitude: '43.100242',
    longitude: '-84.227445',
    timeZoneId: 'America/New_York',
  },
  '48842': {
    zip: '48842',
    city: 'Holt',
    state: 'MI',
    latitude: '42.640455',
    longitude: '-84.528731',
    timeZoneId: 'America/New_York',
  },
  '48843': {
    zip: '48843',
    city: 'Howell',
    state: 'MI',
    latitude: '42.620869',
    longitude: '-83.919104',
    timeZoneId: 'America/New_York',
  },
  '48844': {
    zip: '48844',
    city: 'Howell',
    state: 'MI',
    latitude: '42.606977',
    longitude: '-83.931981',
    timeZoneId: 'America/New_York',
  },
  '48845': {
    zip: '48845',
    city: 'Hubbardston',
    state: 'MI',
    latitude: '43.092877',
    longitude: '-84.868158',
    timeZoneId: 'America/New_York',
  },
  '48846': {
    zip: '48846',
    city: 'Ionia',
    state: 'MI',
    latitude: '42.985392',
    longitude: '-85.064142',
    timeZoneId: 'America/New_York',
  },
  '48847': {
    zip: '48847',
    city: 'Ithaca',
    state: 'MI',
    latitude: '43.271092',
    longitude: '-84.592337',
    timeZoneId: 'America/New_York',
  },
  '48848': {
    zip: '48848',
    city: 'Laingsburg',
    state: 'MI',
    latitude: '42.884521',
    longitude: '-84.328333',
    timeZoneId: 'America/New_York',
  },
  '48849': {
    zip: '48849',
    city: 'Lake Odessa',
    state: 'MI',
    latitude: '42.804386',
    longitude: '-85.139514',
    timeZoneId: 'America/New_York',
  },
  '48850': {
    zip: '48850',
    city: 'Lakeview',
    state: 'MI',
    latitude: '43.426823',
    longitude: '-85.249843',
    timeZoneId: 'America/New_York',
  },
  '48851': {
    zip: '48851',
    city: 'Lyons',
    state: 'MI',
    latitude: '42.956822',
    longitude: '-84.943116',
    timeZoneId: 'America/New_York',
  },
  '48852': {
    zip: '48852',
    city: 'McBrides',
    state: 'MI',
    latitude: '43.350739',
    longitude: '-85.05068',
    timeZoneId: 'America/New_York',
  },
  '48853': {
    zip: '48853',
    city: 'Maple Rapids',
    state: 'MI',
    latitude: '43.102216',
    longitude: '-84.688937',
    timeZoneId: 'America/New_York',
  },
  '48854': {
    zip: '48854',
    city: 'Mason',
    state: 'MI',
    latitude: '42.584076',
    longitude: '-84.437704',
    timeZoneId: 'America/New_York',
  },
  '48855': {
    zip: '48855',
    city: 'Howell',
    state: 'MI',
    latitude: '42.620163',
    longitude: '-83.917238',
    timeZoneId: 'America/New_York',
  },
  '48856': {
    zip: '48856',
    city: 'Middleton',
    state: 'MI',
    latitude: '43.198172',
    longitude: '-84.737927',
    timeZoneId: 'America/New_York',
  },
  '48857': {
    zip: '48857',
    city: 'Morrice',
    state: 'MI',
    latitude: '42.84413',
    longitude: '-84.160156',
    timeZoneId: 'America/New_York',
  },
  '48858': {
    zip: '48858',
    city: 'Mount Pleasant',
    state: 'MI',
    latitude: '43.601402',
    longitude: '-84.787107',
    timeZoneId: 'America/New_York',
  },
  '48859': {
    zip: '48859',
    city: 'Mount Pleasant',
    state: 'MI',
    latitude: '43.597602',
    longitude: '-84.778009',
    timeZoneId: 'America/New_York',
  },
  '48860': {
    zip: '48860',
    city: 'Muir',
    state: 'MI',
    latitude: '43.031986',
    longitude: '-84.922141',
    timeZoneId: 'America/New_York',
  },
  '48861': {
    zip: '48861',
    city: 'Mulliken',
    state: 'MI',
    latitude: '42.723713',
    longitude: '-84.924432',
    timeZoneId: 'America/New_York',
  },
  '48862': {
    zip: '48862',
    city: 'North Star',
    state: 'MI',
    latitude: '43.246419',
    longitude: '-84.546396',
    timeZoneId: 'America/New_York',
  },
  '48864': {
    zip: '48864',
    city: 'Okemos',
    state: 'MI',
    latitude: '42.707769',
    longitude: '-84.42075',
    timeZoneId: 'America/New_York',
  },
  '48865': {
    zip: '48865',
    city: 'Orleans',
    state: 'MI',
    latitude: '43.084881',
    longitude: '-85.11676',
    timeZoneId: 'America/New_York',
  },
  '48866': {
    zip: '48866',
    city: 'Ovid',
    state: 'MI',
    latitude: '43.005897',
    longitude: '-84.399452',
    timeZoneId: 'America/New_York',
  },
  '48867': {
    zip: '48867',
    city: 'Owosso',
    state: 'MI',
    latitude: '43.004634',
    longitude: '-84.184189',
    timeZoneId: 'America/New_York',
  },
  '48870': {
    zip: '48870',
    city: 'Palo',
    state: 'MI',
    latitude: '43.107474',
    longitude: '-84.987566',
    timeZoneId: 'America/New_York',
  },
  '48871': {
    zip: '48871',
    city: 'Perrinton',
    state: 'MI',
    latitude: '43.157508',
    longitude: '-84.680945',
    timeZoneId: 'America/New_York',
  },
  '48872': {
    zip: '48872',
    city: 'Perry',
    state: 'MI',
    latitude: '42.816189',
    longitude: '-84.226366',
    timeZoneId: 'America/New_York',
  },
  '48873': {
    zip: '48873',
    city: 'Pewamo',
    state: 'MI',
    latitude: '42.979924',
    longitude: '-84.855911',
    timeZoneId: 'America/New_York',
  },
  '48874': {
    zip: '48874',
    city: 'Pompeii',
    state: 'MI',
    latitude: '43.183115',
    longitude: '-84.603069',
    timeZoneId: 'America/New_York',
  },
  '48875': {
    zip: '48875',
    city: 'Portland',
    state: 'MI',
    latitude: '42.860218',
    longitude: '-84.931002',
    timeZoneId: 'America/New_York',
  },
  '48876': {
    zip: '48876',
    city: 'Potterville',
    state: 'MI',
    latitude: '42.639064',
    longitude: '-84.737396',
    timeZoneId: 'America/New_York',
  },
  '48877': {
    zip: '48877',
    city: 'Riverdale',
    state: 'MI',
    latitude: '43.401993',
    longitude: '-84.838372',
    timeZoneId: 'America/New_York',
  },
  '48878': {
    zip: '48878',
    city: 'Rosebush',
    state: 'MI',
    latitude: '43.71307',
    longitude: '-84.771596',
    timeZoneId: 'America/New_York',
  },
  '48879': {
    zip: '48879',
    city: 'Saint Johns',
    state: 'MI',
    latitude: '42.999309',
    longitude: '-84.57543',
    timeZoneId: 'America/New_York',
  },
  '48880': {
    zip: '48880',
    city: 'Saint Louis',
    state: 'MI',
    latitude: '43.409427',
    longitude: '-84.599079',
    timeZoneId: 'America/New_York',
  },
  '48881': {
    zip: '48881',
    city: 'Saranac',
    state: 'MI',
    latitude: '42.927301',
    longitude: '-85.208744',
    timeZoneId: 'America/New_York',
  },
  '48882': {
    zip: '48882',
    city: 'Shaftsburg',
    state: 'MI',
    latitude: '42.809922',
    longitude: '-84.295427',
    timeZoneId: 'America/New_York',
  },
  '48883': {
    zip: '48883',
    city: 'Shepherd',
    state: 'MI',
    latitude: '43.52386',
    longitude: '-84.731401',
    timeZoneId: 'America/New_York',
  },
  '48884': {
    zip: '48884',
    city: 'Sheridan',
    state: 'MI',
    latitude: '43.211632',
    longitude: '-85.052531',
    timeZoneId: 'America/New_York',
  },
  '48885': {
    zip: '48885',
    city: 'Sidney',
    state: 'MI',
    latitude: '43.251891',
    longitude: '-85.143329',
    timeZoneId: 'America/New_York',
  },
  '48886': {
    zip: '48886',
    city: 'Six Lakes',
    state: 'MI',
    latitude: '43.416761',
    longitude: '-85.161881',
    timeZoneId: 'America/New_York',
  },
  '48887': {
    zip: '48887',
    city: 'Smyrna',
    state: 'MI',
    latitude: '43.06242',
    longitude: '-85.262729',
    timeZoneId: 'America/New_York',
  },
  '48888': {
    zip: '48888',
    city: 'Stanton',
    state: 'MI',
    latitude: '43.302535',
    longitude: '-85.110003',
    timeZoneId: 'America/New_York',
  },
  '48889': {
    zip: '48889',
    city: 'Sumner',
    state: 'MI',
    latitude: '43.292719',
    longitude: '-84.799875',
    timeZoneId: 'America/New_York',
  },
  '48890': {
    zip: '48890',
    city: 'Sunfield',
    state: 'MI',
    latitude: '42.749009',
    longitude: '-84.990678',
    timeZoneId: 'America/New_York',
  },
  '48891': {
    zip: '48891',
    city: 'Vestaburg',
    state: 'MI',
    latitude: '43.39579',
    longitude: '-84.918019',
    timeZoneId: 'America/New_York',
  },
  '48892': {
    zip: '48892',
    city: 'Webberville',
    state: 'MI',
    latitude: '42.648435',
    longitude: '-84.173694',
    timeZoneId: 'America/New_York',
  },
  '48893': {
    zip: '48893',
    city: 'Weidman',
    state: 'MI',
    latitude: '43.671739',
    longitude: '-84.988416',
    timeZoneId: 'America/New_York',
  },
  '48894': {
    zip: '48894',
    city: 'Westphalia',
    state: 'MI',
    latitude: '42.92863',
    longitude: '-84.795666',
    timeZoneId: 'America/New_York',
  },
  '48895': {
    zip: '48895',
    city: 'Williamston',
    state: 'MI',
    latitude: '42.689475',
    longitude: '-84.286763',
    timeZoneId: 'America/New_York',
  },
  '48896': {
    zip: '48896',
    city: 'Winn',
    state: 'MI',
    latitude: '43.52726',
    longitude: '-84.901708',
    timeZoneId: 'America/New_York',
  },
  '48897': {
    zip: '48897',
    city: 'Woodland',
    state: 'MI',
    latitude: '42.707619',
    longitude: '-85.129563',
    timeZoneId: 'America/New_York',
  },
  '48901': {
    zip: '48901',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.732308',
    longitude: '-84.553263',
    timeZoneId: 'America/New_York',
  },
  '48906': {
    zip: '48906',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.76821',
    longitude: '-84.579742',
    timeZoneId: 'America/New_York',
  },
  '48908': {
    zip: '48908',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.731046',
    longitude: '-84.645869',
    timeZoneId: 'America/New_York',
  },
  '48909': {
    zip: '48909',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.733354',
    longitude: '-84.54958',
    timeZoneId: 'America/New_York',
  },
  '48910': {
    zip: '48910',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.704153',
    longitude: '-84.546782',
    timeZoneId: 'America/New_York',
  },
  '48911': {
    zip: '48911',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.677285',
    longitude: '-84.567592',
    timeZoneId: 'America/New_York',
  },
  '48912': {
    zip: '48912',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.735638',
    longitude: '-84.523037',
    timeZoneId: 'America/New_York',
  },
  '48913': {
    zip: '48913',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.677493',
    longitude: '-84.665847',
    timeZoneId: 'America/New_York',
  },
  '48915': {
    zip: '48915',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.740498',
    longitude: '-84.572112',
    timeZoneId: 'America/New_York',
  },
  '48916': {
    zip: '48916',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.671084',
    longitude: '-84.573494',
    timeZoneId: 'America/New_York',
  },
  '48917': {
    zip: '48917',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.731731',
    longitude: '-84.637521',
    timeZoneId: 'America/New_York',
  },
  '48918': {
    zip: '48918',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.674828',
    longitude: '-84.678438',
    timeZoneId: 'America/New_York',
  },
  '48919': {
    zip: '48919',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.730681',
    longitude: '-84.552898',
    timeZoneId: 'America/New_York',
  },
  '48922': {
    zip: '48922',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.735066',
    longitude: '-84.558119',
    timeZoneId: 'America/New_York',
  },
  '48924': {
    zip: '48924',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.6997',
    longitude: '-84.527094',
    timeZoneId: 'America/New_York',
  },
  '48929': {
    zip: '48929',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.734857',
    longitude: '-84.556883',
    timeZoneId: 'America/New_York',
  },
  '48930': {
    zip: '48930',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.734929',
    longitude: '-84.55937',
    timeZoneId: 'America/New_York',
  },
  '48933': {
    zip: '48933',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.732863',
    longitude: '-84.5527',
    timeZoneId: 'America/New_York',
  },
  '48937': {
    zip: '48937',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.783292',
    longitude: '-84.571296',
    timeZoneId: 'America/New_York',
  },
  '48951': {
    zip: '48951',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.73318',
    longitude: '-84.555446',
    timeZoneId: 'America/New_York',
  },
  '48956': {
    zip: '48956',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.734814',
    longitude: '-84.556691',
    timeZoneId: 'America/New_York',
  },
  '48980': {
    zip: '48980',
    city: 'Lansing',
    state: 'MI',
    latitude: '42.671821',
    longitude: '-84.675469',
    timeZoneId: 'America/New_York',
  },
  '49001': {
    zip: '49001',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.26388',
    longitude: '-85.464',
    timeZoneId: 'America/New_York',
  },
  '49002': {
    zip: '49002',
    city: 'Portage',
    state: 'MI',
    latitude: '42.204383',
    longitude: '-85.598689',
    timeZoneId: 'America/New_York',
  },
  '49003': {
    zip: '49003',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.312389',
    longitude: '-85.682295',
    timeZoneId: 'America/New_York',
  },
  '49004': {
    zip: '49004',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.3471',
    longitude: '-85.54746',
    timeZoneId: 'America/New_York',
  },
  '49005': {
    zip: '49005',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.291162',
    longitude: '-85.582601',
    timeZoneId: 'America/New_York',
  },
  '49006': {
    zip: '49006',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.293748',
    longitude: '-85.62414',
    timeZoneId: 'America/New_York',
  },
  '49007': {
    zip: '49007',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.298764',
    longitude: '-85.610796',
    timeZoneId: 'America/New_York',
  },
  '49008': {
    zip: '49008',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.261774',
    longitude: '-85.607934',
    timeZoneId: 'America/New_York',
  },
  '49009': {
    zip: '49009',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.273649',
    longitude: '-85.688557',
    timeZoneId: 'America/New_York',
  },
  '49010': {
    zip: '49010',
    city: 'Allegan',
    state: 'MI',
    latitude: '42.531851',
    longitude: '-85.8672',
    timeZoneId: 'America/New_York',
  },
  '49011': {
    zip: '49011',
    city: 'Athens',
    state: 'MI',
    latitude: '42.098631',
    longitude: '-85.219805',
    timeZoneId: 'America/New_York',
  },
  '49012': {
    zip: '49012',
    city: 'Augusta',
    state: 'MI',
    latitude: '42.356033',
    longitude: '-85.347067',
    timeZoneId: 'America/New_York',
  },
  '49013': {
    zip: '49013',
    city: 'Bangor',
    state: 'MI',
    latitude: '42.304881',
    longitude: '-86.116634',
    timeZoneId: 'America/New_York',
  },
  '49014': {
    zip: '49014',
    city: 'Battle Creek',
    state: 'MI',
    latitude: '42.312625',
    longitude: '-85.106599',
    timeZoneId: 'America/New_York',
  },
  '49015': {
    zip: '49015',
    city: 'Battle Creek',
    state: 'MI',
    latitude: '42.30102',
    longitude: '-85.2291',
    timeZoneId: 'America/New_York',
  },
  '49016': {
    zip: '49016',
    city: 'Battle Creek',
    state: 'MI',
    latitude: '42.30587',
    longitude: '-85.172099',
    timeZoneId: 'America/New_York',
  },
  '49017': {
    zip: '49017',
    city: 'Battle Creek',
    state: 'MI',
    latitude: '42.325907',
    longitude: '-85.18106',
    timeZoneId: 'America/New_York',
  },
  '49018': {
    zip: '49018',
    city: 'Battle Creek',
    state: 'MI',
    latitude: '42.39748',
    longitude: '-85.219713',
    timeZoneId: 'America/New_York',
  },
  '49019': {
    zip: '49019',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.272881',
    longitude: '-85.587416',
    timeZoneId: 'America/New_York',
  },
  '49020': {
    zip: '49020',
    city: 'Bedford',
    state: 'MI',
    latitude: '42.395449',
    longitude: '-85.233075',
    timeZoneId: 'America/New_York',
  },
  '49021': {
    zip: '49021',
    city: 'Bellevue',
    state: 'MI',
    latitude: '42.465598',
    longitude: '-85.00986',
    timeZoneId: 'America/New_York',
  },
  '49022': {
    zip: '49022',
    city: 'Benton Harbor',
    state: 'MI',
    latitude: '42.110957',
    longitude: '-86.403985',
    timeZoneId: 'America/New_York',
  },
  '49023': {
    zip: '49023',
    city: 'Benton Harbor',
    state: 'MI',
    latitude: '42.107543',
    longitude: '-86.466051',
    timeZoneId: 'America/New_York',
  },
  '49024': {
    zip: '49024',
    city: 'Portage',
    state: 'MI',
    latitude: '42.201972',
    longitude: '-85.592387',
    timeZoneId: 'America/New_York',
  },
  '49026': {
    zip: '49026',
    city: 'Bloomingdale',
    state: 'MI',
    latitude: '42.37348',
    longitude: '-85.971257',
    timeZoneId: 'America/New_York',
  },
  '49027': {
    zip: '49027',
    city: 'Breedsville',
    state: 'MI',
    latitude: '42.352513',
    longitude: '-86.063283',
    timeZoneId: 'America/New_York',
  },
  '49028': {
    zip: '49028',
    city: 'Bronson',
    state: 'MI',
    latitude: '41.848158',
    longitude: '-85.192123',
    timeZoneId: 'America/New_York',
  },
  '49029': {
    zip: '49029',
    city: 'Burlington',
    state: 'MI',
    latitude: '42.135098',
    longitude: '-85.088963',
    timeZoneId: 'America/New_York',
  },
  '49030': {
    zip: '49030',
    city: 'Burr Oak',
    state: 'MI',
    latitude: '41.858964',
    longitude: '-85.33523',
    timeZoneId: 'America/New_York',
  },
  '49031': {
    zip: '49031',
    city: 'Cassopolis',
    state: 'MI',
    latitude: '41.903522',
    longitude: '-85.989471',
    timeZoneId: 'America/New_York',
  },
  '49032': {
    zip: '49032',
    city: 'Centreville',
    state: 'MI',
    latitude: '41.919412',
    longitude: '-85.500581',
    timeZoneId: 'America/New_York',
  },
  '49033': {
    zip: '49033',
    city: 'Ceresco',
    state: 'MI',
    latitude: '42.225723',
    longitude: '-85.096442',
    timeZoneId: 'America/New_York',
  },
  '49034': {
    zip: '49034',
    city: 'Climax',
    state: 'MI',
    latitude: '42.232378',
    longitude: '-85.345777',
    timeZoneId: 'America/New_York',
  },
  '49035': {
    zip: '49035',
    city: 'Cloverdale',
    state: 'MI',
    latitude: '42.552666',
    longitude: '-85.385214',
    timeZoneId: 'America/New_York',
  },
  '49036': {
    zip: '49036',
    city: 'Coldwater',
    state: 'MI',
    latitude: '41.90815',
    longitude: '-85.014826',
    timeZoneId: 'America/New_York',
  },
  '49037': {
    zip: '49037',
    city: 'Battle Creek',
    state: 'MI',
    latitude: '42.237105',
    longitude: '-85.203233',
    timeZoneId: 'America/New_York',
  },
  '49038': {
    zip: '49038',
    city: 'Coloma',
    state: 'MI',
    latitude: '42.203099',
    longitude: '-86.326828',
    timeZoneId: 'America/New_York',
  },
  '49039': {
    zip: '49039',
    city: 'Hagar Shores',
    state: 'MI',
    latitude: '42.2',
    longitude: '-86.333333',
    timeZoneId: 'America/New_York',
  },
  '49040': {
    zip: '49040',
    city: 'Colon',
    state: 'MI',
    latitude: '41.957101',
    longitude: '-85.331154',
    timeZoneId: 'America/New_York',
  },
  '49041': {
    zip: '49041',
    city: 'Comstock',
    state: 'MI',
    latitude: '42.284886',
    longitude: '-85.511298',
    timeZoneId: 'America/New_York',
  },
  '49042': {
    zip: '49042',
    city: 'Constantine',
    state: 'MI',
    latitude: '41.852452',
    longitude: '-85.66267',
    timeZoneId: 'America/New_York',
  },
  '49043': {
    zip: '49043',
    city: 'Covert',
    state: 'MI',
    latitude: '42.294025',
    longitude: '-86.272654',
    timeZoneId: 'America/New_York',
  },
  '49045': {
    zip: '49045',
    city: 'Decatur',
    state: 'MI',
    latitude: '42.119771',
    longitude: '-85.998583',
    timeZoneId: 'America/New_York',
  },
  '49046': {
    zip: '49046',
    city: 'Delton',
    state: 'MI',
    latitude: '42.516236',
    longitude: '-85.400453',
    timeZoneId: 'America/New_York',
  },
  '49047': {
    zip: '49047',
    city: 'Dowagiac',
    state: 'MI',
    latitude: '41.997525',
    longitude: '-86.111356',
    timeZoneId: 'America/New_York',
  },
  '49048': {
    zip: '49048',
    city: 'Kalamazoo',
    state: 'MI',
    latitude: '42.293679',
    longitude: '-85.586333',
    timeZoneId: 'America/New_York',
  },
  '49050': {
    zip: '49050',
    city: 'Dowling',
    state: 'MI',
    latitude: '42.497608',
    longitude: '-85.241437',
    timeZoneId: 'America/New_York',
  },
  '49051': {
    zip: '49051',
    city: 'East Leroy',
    state: 'MI',
    latitude: '42.191219',
    longitude: '-85.236464',
    timeZoneId: 'America/New_York',
  },
  '49052': {
    zip: '49052',
    city: 'Fulton',
    state: 'MI',
    latitude: '42.107053',
    longitude: '-85.339615',
    timeZoneId: 'America/New_York',
  },
  '49053': {
    zip: '49053',
    city: 'Galesburg',
    state: 'MI',
    latitude: '42.289831',
    longitude: '-85.420315',
    timeZoneId: 'America/New_York',
  },
  '49055': {
    zip: '49055',
    city: 'Gobles',
    state: 'MI',
    latitude: '42.363768',
    longitude: '-85.871346',
    timeZoneId: 'America/New_York',
  },
  '49056': {
    zip: '49056',
    city: 'Grand Junction',
    state: 'MI',
    latitude: '42.375171',
    longitude: '-86.059363',
    timeZoneId: 'America/New_York',
  },
  '49057': {
    zip: '49057',
    city: 'Hartford',
    state: 'MI',
    latitude: '42.19405',
    longitude: '-86.170158',
    timeZoneId: 'America/New_York',
  },
  '49058': {
    zip: '49058',
    city: 'Hastings',
    state: 'MI',
    latitude: '42.639887',
    longitude: '-85.294848',
    timeZoneId: 'America/New_York',
  },
  '49060': {
    zip: '49060',
    city: 'Hickory Corners',
    state: 'MI',
    latitude: '42.43616',
    longitude: '-85.400652',
    timeZoneId: 'America/New_York',
  },
  '49061': {
    zip: '49061',
    city: 'Jones',
    state: 'MI',
    latitude: '41.881397',
    longitude: '-85.825725',
    timeZoneId: 'America/New_York',
  },
  '49062': {
    zip: '49062',
    city: 'Kendall',
    state: 'MI',
    latitude: '42.36089',
    longitude: '-85.815472',
    timeZoneId: 'America/New_York',
  },
  '49063': {
    zip: '49063',
    city: 'Lacota',
    state: 'MI',
    latitude: '42.413544',
    longitude: '-86.131234',
    timeZoneId: 'America/New_York',
  },
  '49064': {
    zip: '49064',
    city: 'Lawrence',
    state: 'MI',
    latitude: '42.216384',
    longitude: '-86.051631',
    timeZoneId: 'America/New_York',
  },
  '49065': {
    zip: '49065',
    city: 'Lawton',
    state: 'MI',
    latitude: '42.14897',
    longitude: '-85.838671',
    timeZoneId: 'America/New_York',
  },
  '49066': {
    zip: '49066',
    city: 'Leonidas',
    state: 'MI',
    latitude: '42.033923',
    longitude: '-85.355288',
    timeZoneId: 'America/New_York',
  },
  '49067': {
    zip: '49067',
    city: 'Marcellus',
    state: 'MI',
    latitude: '42.01596',
    longitude: '-85.835576',
    timeZoneId: 'America/New_York',
  },
  '49068': {
    zip: '49068',
    city: 'Marshall',
    state: 'MI',
    latitude: '42.268198',
    longitude: '-84.942961',
    timeZoneId: 'America/New_York',
  },
  '49070': {
    zip: '49070',
    city: 'Martin',
    state: 'MI',
    latitude: '42.541296',
    longitude: '-85.634433',
    timeZoneId: 'America/New_York',
  },
  '49071': {
    zip: '49071',
    city: 'Mattawan',
    state: 'MI',
    latitude: '42.234495',
    longitude: '-85.791411',
    timeZoneId: 'America/New_York',
  },
  '49072': {
    zip: '49072',
    city: 'Mendon',
    state: 'MI',
    latitude: '42.006628',
    longitude: '-85.460228',
    timeZoneId: 'America/New_York',
  },
  '49073': {
    zip: '49073',
    city: 'Nashville',
    state: 'MI',
    latitude: '42.585782',
    longitude: '-85.12256',
    timeZoneId: 'America/New_York',
  },
  '49074': {
    zip: '49074',
    city: 'Nazareth',
    state: 'MI',
    latitude: '42.288107',
    longitude: '-85.598653',
    timeZoneId: 'America/New_York',
  },
  '49075': {
    zip: '49075',
    city: 'Nottawa',
    state: 'MI',
    latitude: '41.919222',
    longitude: '-85.447537',
    timeZoneId: 'America/New_York',
  },
  '49076': {
    zip: '49076',
    city: 'Olivet',
    state: 'MI',
    latitude: '42.455103',
    longitude: '-84.907989',
    timeZoneId: 'America/New_York',
  },
  '49077': {
    zip: '49077',
    city: 'Oshtemo',
    state: 'MI',
    latitude: '42.255524',
    longitude: '-85.676998',
    timeZoneId: 'America/New_York',
  },
  '49078': {
    zip: '49078',
    city: 'Otsego',
    state: 'MI',
    latitude: '42.468819',
    longitude: '-85.713723',
    timeZoneId: 'America/New_York',
  },
  '49079': {
    zip: '49079',
    city: 'Paw Paw',
    state: 'MI',
    latitude: '42.231265',
    longitude: '-85.902785',
    timeZoneId: 'America/New_York',
  },
  '49080': {
    zip: '49080',
    city: 'Plainwell',
    state: 'MI',
    latitude: '42.456287',
    longitude: '-85.628925',
    timeZoneId: 'America/New_York',
  },
  '49081': {
    zip: '49081',
    city: 'Portage',
    state: 'MI',
    latitude: '42.205616',
    longitude: '-85.566785',
    timeZoneId: 'America/New_York',
  },
  '49082': {
    zip: '49082',
    city: 'Quincy',
    state: 'MI',
    latitude: '41.945571',
    longitude: '-84.88596',
    timeZoneId: 'America/New_York',
  },
  '49083': {
    zip: '49083',
    city: 'Richland',
    state: 'MI',
    latitude: '42.376617',
    longitude: '-85.447312',
    timeZoneId: 'America/New_York',
  },
  '49084': {
    zip: '49084',
    city: 'Riverside',
    state: 'MI',
    latitude: '42.182986',
    longitude: '-86.380844',
    timeZoneId: 'America/New_York',
  },
  '49085': {
    zip: '49085',
    city: 'Saint Joseph',
    state: 'MI',
    latitude: '42.068065',
    longitude: '-86.475496',
    timeZoneId: 'America/New_York',
  },
  '49087': {
    zip: '49087',
    city: 'Schoolcraft',
    state: 'MI',
    latitude: '42.11965',
    longitude: '-85.67354',
    timeZoneId: 'America/New_York',
  },
  '49088': {
    zip: '49088',
    city: 'Scotts',
    state: 'MI',
    latitude: '42.186704',
    longitude: '-85.421776',
    timeZoneId: 'America/New_York',
  },
  '49089': {
    zip: '49089',
    city: 'Sherwood',
    state: 'MI',
    latitude: '42.009295',
    longitude: '-85.22871',
    timeZoneId: 'America/New_York',
  },
  '49090': {
    zip: '49090',
    city: 'South Haven',
    state: 'MI',
    latitude: '42.386974',
    longitude: '-86.258209',
    timeZoneId: 'America/New_York',
  },
  '49091': {
    zip: '49091',
    city: 'Sturgis',
    state: 'MI',
    latitude: '41.814747',
    longitude: '-85.43139',
    timeZoneId: 'America/New_York',
  },
  '49092': {
    zip: '49092',
    city: 'Tekonsha',
    state: 'MI',
    latitude: '42.102483',
    longitude: '-84.980824',
    timeZoneId: 'America/New_York',
  },
  '49093': {
    zip: '49093',
    city: 'Three Rivers',
    state: 'MI',
    latitude: '41.960439',
    longitude: '-85.636997',
    timeZoneId: 'America/New_York',
  },
  '49094': {
    zip: '49094',
    city: 'Union City',
    state: 'MI',
    latitude: '42.040123',
    longitude: '-85.114805',
    timeZoneId: 'America/New_York',
  },
  '49095': {
    zip: '49095',
    city: 'Vandalia',
    state: 'MI',
    latitude: '41.903319',
    longitude: '-85.885876',
    timeZoneId: 'America/New_York',
  },
  '49096': {
    zip: '49096',
    city: 'Vermontville',
    state: 'MI',
    latitude: '42.634787',
    longitude: '-85.014625',
    timeZoneId: 'America/New_York',
  },
  '49097': {
    zip: '49097',
    city: 'Vicksburg',
    state: 'MI',
    latitude: '42.120191',
    longitude: '-85.50628',
    timeZoneId: 'America/New_York',
  },
  '49098': {
    zip: '49098',
    city: 'Watervliet',
    state: 'MI',
    latitude: '42.187078',
    longitude: '-86.256124',
    timeZoneId: 'America/New_York',
  },
  '49099': {
    zip: '49099',
    city: 'White Pigeon',
    state: 'MI',
    latitude: '41.793351',
    longitude: '-85.661168',
    timeZoneId: 'America/New_York',
  },
  '49101': {
    zip: '49101',
    city: 'Baroda',
    state: 'MI',
    latitude: '41.947675',
    longitude: '-86.484704',
    timeZoneId: 'America/New_York',
  },
  '49102': {
    zip: '49102',
    city: 'Berrien Center',
    state: 'MI',
    latitude: '41.954134',
    longitude: '-86.276203',
    timeZoneId: 'America/New_York',
  },
  '49103': {
    zip: '49103',
    city: 'Berrien Springs',
    state: 'MI',
    latitude: '41.940154',
    longitude: '-86.349801',
    timeZoneId: 'America/New_York',
  },
  '49104': {
    zip: '49104',
    city: 'Berrien Springs',
    state: 'MI',
    latitude: '41.945755',
    longitude: '-86.340672',
    timeZoneId: 'America/New_York',
  },
  '49106': {
    zip: '49106',
    city: 'Bridgman',
    state: 'MI',
    latitude: '41.942155',
    longitude: '-86.551885',
    timeZoneId: 'America/New_York',
  },
  '49107': {
    zip: '49107',
    city: 'Buchanan',
    state: 'MI',
    latitude: '41.83176',
    longitude: '-86.40288',
    timeZoneId: 'America/New_York',
  },
  '49111': {
    zip: '49111',
    city: 'Eau Claire',
    state: 'MI',
    latitude: '42.016888',
    longitude: '-86.301114',
    timeZoneId: 'America/New_York',
  },
  '49112': {
    zip: '49112',
    city: 'Edwardsburg',
    state: 'MI',
    latitude: '41.797374',
    longitude: '-86.040698',
    timeZoneId: 'America/New_York',
  },
  '49113': {
    zip: '49113',
    city: 'Galien',
    state: 'MI',
    latitude: '41.799111',
    longitude: '-86.515538',
    timeZoneId: 'America/New_York',
  },
  '49115': {
    zip: '49115',
    city: 'Harbert',
    state: 'MI',
    latitude: '41.875075',
    longitude: '-86.6309',
    timeZoneId: 'America/New_York',
  },
  '49116': {
    zip: '49116',
    city: 'Lakeside',
    state: 'MI',
    latitude: '41.848626',
    longitude: '-86.670206',
    timeZoneId: 'America/New_York',
  },
  '49117': {
    zip: '49117',
    city: 'New Buffalo',
    state: 'MI',
    latitude: '41.786972',
    longitude: '-86.754132',
    timeZoneId: 'America/New_York',
  },
  '49119': {
    zip: '49119',
    city: 'New Troy',
    state: 'MI',
    latitude: '41.878062',
    longitude: '-86.550298',
    timeZoneId: 'America/New_York',
  },
  '49120': {
    zip: '49120',
    city: 'Niles',
    state: 'MI',
    latitude: '41.821932',
    longitude: '-86.258675',
    timeZoneId: 'America/New_York',
  },
  '49125': {
    zip: '49125',
    city: 'Sawyer',
    state: 'MI',
    latitude: '41.88865',
    longitude: '-86.594119',
    timeZoneId: 'America/New_York',
  },
  '49126': {
    zip: '49126',
    city: 'Sodus',
    state: 'MI',
    latitude: '42.03682',
    longitude: '-86.369682',
    timeZoneId: 'America/New_York',
  },
  '49127': {
    zip: '49127',
    city: 'Stevensville',
    state: 'MI',
    latitude: '42.016823',
    longitude: '-86.516037',
    timeZoneId: 'America/New_York',
  },
  '49128': {
    zip: '49128',
    city: 'Three Oaks',
    state: 'MI',
    latitude: '41.822485',
    longitude: '-86.61655',
    timeZoneId: 'America/New_York',
  },
  '49129': {
    zip: '49129',
    city: 'Union Pier',
    state: 'MI',
    latitude: '41.825741',
    longitude: '-86.688623',
    timeZoneId: 'America/New_York',
  },
  '49130': {
    zip: '49130',
    city: 'Union',
    state: 'MI',
    latitude: '41.785266',
    longitude: '-85.85256',
    timeZoneId: 'America/New_York',
  },
  '49201': {
    zip: '49201',
    city: 'Jackson',
    state: 'MI',
    latitude: '42.248579',
    longitude: '-84.384984',
    timeZoneId: 'America/New_York',
  },
  '49202': {
    zip: '49202',
    city: 'Jackson',
    state: 'MI',
    latitude: '42.264371',
    longitude: '-84.410995',
    timeZoneId: 'America/New_York',
  },
  '49203': {
    zip: '49203',
    city: 'Jackson',
    state: 'MI',
    latitude: '42.225032',
    longitude: '-84.406836',
    timeZoneId: 'America/New_York',
  },
  '49204': {
    zip: '49204',
    city: 'Jackson',
    state: 'MI',
    latitude: '42.330066',
    longitude: '-84.515206',
    timeZoneId: 'America/New_York',
  },
  '49220': {
    zip: '49220',
    city: 'Addison',
    state: 'MI',
    latitude: '42.003218',
    longitude: '-84.319565',
    timeZoneId: 'America/New_York',
  },
  '49221': {
    zip: '49221',
    city: 'Adrian',
    state: 'MI',
    latitude: '41.904241',
    longitude: '-84.050711',
    timeZoneId: 'America/New_York',
  },
  '49224': {
    zip: '49224',
    city: 'Albion',
    state: 'MI',
    latitude: '42.261733',
    longitude: '-84.761805',
    timeZoneId: 'America/New_York',
  },
  '49227': {
    zip: '49227',
    city: 'Allen',
    state: 'MI',
    latitude: '41.949885',
    longitude: '-84.768253',
    timeZoneId: 'America/New_York',
  },
  '49228': {
    zip: '49228',
    city: 'Blissfield',
    state: 'MI',
    latitude: '41.819406',
    longitude: '-83.875842',
    timeZoneId: 'America/New_York',
  },
  '49229': {
    zip: '49229',
    city: 'Britton',
    state: 'MI',
    latitude: '41.984165',
    longitude: '-83.839522',
    timeZoneId: 'America/New_York',
  },
  '49230': {
    zip: '49230',
    city: 'Brooklyn',
    state: 'MI',
    latitude: '42.103506',
    longitude: '-84.245512',
    timeZoneId: 'America/New_York',
  },
  '49232': {
    zip: '49232',
    city: 'Camden',
    state: 'MI',
    latitude: '41.73973',
    longitude: '-84.652695',
    timeZoneId: 'America/New_York',
  },
  '49233': {
    zip: '49233',
    city: 'Cement City',
    state: 'MI',
    latitude: '42.051484',
    longitude: '-84.379811',
    timeZoneId: 'America/New_York',
  },
  '49234': {
    zip: '49234',
    city: 'Clarklake',
    state: 'MI',
    latitude: '42.119931',
    longitude: '-84.348549',
    timeZoneId: 'America/New_York',
  },
  '49235': {
    zip: '49235',
    city: 'Clayton',
    state: 'MI',
    latitude: '41.866607',
    longitude: '-84.21133',
    timeZoneId: 'America/New_York',
  },
  '49236': {
    zip: '49236',
    city: 'Clinton',
    state: 'MI',
    latitude: '42.066581',
    longitude: '-83.957581',
    timeZoneId: 'America/New_York',
  },
  '49237': {
    zip: '49237',
    city: 'Concord',
    state: 'MI',
    latitude: '42.171609',
    longitude: '-84.646329',
    timeZoneId: 'America/New_York',
  },
  '49238': {
    zip: '49238',
    city: 'Deerfield',
    state: 'MI',
    latitude: '41.896662',
    longitude: '-83.797467',
    timeZoneId: 'America/New_York',
  },
  '49239': {
    zip: '49239',
    city: 'Frontier',
    state: 'MI',
    latitude: '41.793886',
    longitude: '-84.623116',
    timeZoneId: 'America/New_York',
  },
  '49240': {
    zip: '49240',
    city: 'Grass Lake',
    state: 'MI',
    latitude: '42.283342',
    longitude: '-84.200658',
    timeZoneId: 'America/New_York',
  },
  '49241': {
    zip: '49241',
    city: 'Hanover',
    state: 'MI',
    latitude: '42.105922',
    longitude: '-84.604392',
    timeZoneId: 'America/New_York',
  },
  '49242': {
    zip: '49242',
    city: 'Hillsdale',
    state: 'MI',
    latitude: '41.901249',
    longitude: '-84.620166',
    timeZoneId: 'America/New_York',
  },
  '49245': {
    zip: '49245',
    city: 'Homer',
    state: 'MI',
    latitude: '42.150968',
    longitude: '-84.811795',
    timeZoneId: 'America/New_York',
  },
  '49246': {
    zip: '49246',
    city: 'Horton',
    state: 'MI',
    latitude: '42.1183',
    longitude: '-84.50182',
    timeZoneId: 'America/New_York',
  },
  '49247': {
    zip: '49247',
    city: 'Hudson',
    state: 'MI',
    latitude: '41.853146',
    longitude: '-84.330032',
    timeZoneId: 'America/New_York',
  },
  '49248': {
    zip: '49248',
    city: 'Jasper',
    state: 'MI',
    latitude: '41.767239',
    longitude: '-84.012049',
    timeZoneId: 'America/New_York',
  },
  '49249': {
    zip: '49249',
    city: 'Jerome',
    state: 'MI',
    latitude: '42.043416',
    longitude: '-84.445244',
    timeZoneId: 'America/New_York',
  },
  '49250': {
    zip: '49250',
    city: 'Jonesville',
    state: 'MI',
    latitude: '42.009584',
    longitude: '-84.630051',
    timeZoneId: 'America/New_York',
  },
  '49251': {
    zip: '49251',
    city: 'Leslie',
    state: 'MI',
    latitude: '42.466322',
    longitude: '-84.417115',
    timeZoneId: 'America/New_York',
  },
  '49252': {
    zip: '49252',
    city: 'Litchfield',
    state: 'MI',
    latitude: '42.031597',
    longitude: '-84.766909',
    timeZoneId: 'America/New_York',
  },
  '49253': {
    zip: '49253',
    city: 'Manitou Beach',
    state: 'MI',
    latitude: '41.974048',
    longitude: '-84.276829',
    timeZoneId: 'America/New_York',
  },
  '49254': {
    zip: '49254',
    city: 'Michigan Center',
    state: 'MI',
    latitude: '42.22772',
    longitude: '-84.324707',
    timeZoneId: 'America/New_York',
  },
  '49255': {
    zip: '49255',
    city: 'Montgomery',
    state: 'MI',
    latitude: '41.782742',
    longitude: '-84.883132',
    timeZoneId: 'America/New_York',
  },
  '49256': {
    zip: '49256',
    city: 'Morenci',
    state: 'MI',
    latitude: '41.743671',
    longitude: '-84.217238',
    timeZoneId: 'America/New_York',
  },
  '49257': {
    zip: '49257',
    city: 'Moscow',
    state: 'MI',
    latitude: '42.051081',
    longitude: '-84.508969',
    timeZoneId: 'America/New_York',
  },
  '49258': {
    zip: '49258',
    city: 'Mosherville',
    state: 'MI',
    latitude: '42.058745',
    longitude: '-84.656404',
    timeZoneId: 'America/New_York',
  },
  '49259': {
    zip: '49259',
    city: 'Munith',
    state: 'MI',
    latitude: '42.381338',
    longitude: '-84.26033',
    timeZoneId: 'America/New_York',
  },
  '49261': {
    zip: '49261',
    city: 'Napoleon',
    state: 'MI',
    latitude: '42.246',
    longitude: '-84.404617',
    timeZoneId: 'America/New_York',
  },
  '49262': {
    zip: '49262',
    city: 'North Adams',
    state: 'MI',
    latitude: '41.962474',
    longitude: '-84.490077',
    timeZoneId: 'America/New_York',
  },
  '49263': {
    zip: '49263',
    city: 'Norvell',
    state: 'MI',
    latitude: '42.159227',
    longitude: '-84.184591',
    timeZoneId: 'America/New_York',
  },
  '49264': {
    zip: '49264',
    city: 'Onondaga',
    state: 'MI',
    latitude: '42.453693',
    longitude: '-84.553294',
    timeZoneId: 'America/New_York',
  },
  '49265': {
    zip: '49265',
    city: 'Onsted',
    state: 'MI',
    latitude: '42.01423',
    longitude: '-84.172982',
    timeZoneId: 'America/New_York',
  },
  '49266': {
    zip: '49266',
    city: 'Osseo',
    state: 'MI',
    latitude: '41.851341',
    longitude: '-84.548348',
    timeZoneId: 'America/New_York',
  },
  '49267': {
    zip: '49267',
    city: 'Ottawa Lake',
    state: 'MI',
    latitude: '41.758173',
    longitude: '-83.692281',
    timeZoneId: 'America/New_York',
  },
  '49268': {
    zip: '49268',
    city: 'Palmyra',
    state: 'MI',
    latitude: '41.871803',
    longitude: '-83.932716',
    timeZoneId: 'America/New_York',
  },
  '49269': {
    zip: '49269',
    city: 'Parma',
    state: 'MI',
    latitude: '42.279435',
    longitude: '-84.58467',
    timeZoneId: 'America/New_York',
  },
  '49270': {
    zip: '49270',
    city: 'Petersburg',
    state: 'MI',
    latitude: '41.87297',
    longitude: '-83.680358',
    timeZoneId: 'America/New_York',
  },
  '49271': {
    zip: '49271',
    city: 'Pittsford',
    state: 'MI',
    latitude: '41.835985',
    longitude: '-84.461354',
    timeZoneId: 'America/New_York',
  },
  '49272': {
    zip: '49272',
    city: 'Pleasant Lake',
    state: 'MI',
    latitude: '42.396206',
    longitude: '-84.344252',
    timeZoneId: 'America/New_York',
  },
  '49274': {
    zip: '49274',
    city: 'Reading',
    state: 'MI',
    latitude: '41.845656',
    longitude: '-84.760742',
    timeZoneId: 'America/New_York',
  },
  '49276': {
    zip: '49276',
    city: 'Riga',
    state: 'MI',
    latitude: '41.792208',
    longitude: '-83.808952',
    timeZoneId: 'America/New_York',
  },
  '49277': {
    zip: '49277',
    city: 'Rives Junction',
    state: 'MI',
    latitude: '42.388466',
    longitude: '-84.466414',
    timeZoneId: 'America/New_York',
  },
  '49279': {
    zip: '49279',
    city: 'Sand Creek',
    state: 'MI',
    latitude: '41.781146',
    longitude: '-84.102103',
    timeZoneId: 'America/New_York',
  },
  '49281': {
    zip: '49281',
    city: 'Somerset',
    state: 'MI',
    latitude: '42.051931',
    longitude: '-84.382907',
    timeZoneId: 'America/New_York',
  },
  '49282': {
    zip: '49282',
    city: 'Somerset Center',
    state: 'MI',
    latitude: '42.052162',
    longitude: '-84.418064',
    timeZoneId: 'America/New_York',
  },
  '49283': {
    zip: '49283',
    city: 'Spring Arbor',
    state: 'MI',
    latitude: '42.209557',
    longitude: '-84.547787',
    timeZoneId: 'America/New_York',
  },
  '49284': {
    zip: '49284',
    city: 'Springport',
    state: 'MI',
    latitude: '42.379037',
    longitude: '-84.666199',
    timeZoneId: 'America/New_York',
  },
  '49285': {
    zip: '49285',
    city: 'Stockbridge',
    state: 'MI',
    latitude: '42.466619',
    longitude: '-84.21319',
    timeZoneId: 'America/New_York',
  },
  '49286': {
    zip: '49286',
    city: 'Tecumseh',
    state: 'MI',
    latitude: '42.010493',
    longitude: '-83.943686',
    timeZoneId: 'America/New_York',
  },
  '49287': {
    zip: '49287',
    city: 'Tipton',
    state: 'MI',
    latitude: '42.029334',
    longitude: '-84.086644',
    timeZoneId: 'America/New_York',
  },
  '49288': {
    zip: '49288',
    city: 'Waldron',
    state: 'MI',
    latitude: '41.731548',
    longitude: '-84.43856',
    timeZoneId: 'America/New_York',
  },
  '49289': {
    zip: '49289',
    city: 'Weston',
    state: 'MI',
    latitude: '41.772761',
    longitude: '-84.098116',
    timeZoneId: 'America/New_York',
  },
  '49301': {
    zip: '49301',
    city: 'Ada',
    state: 'MI',
    latitude: '42.955202',
    longitude: '-85.471385',
    timeZoneId: 'America/New_York',
  },
  '49302': {
    zip: '49302',
    city: 'Alto',
    state: 'MI',
    latitude: '42.82975',
    longitude: '-85.422188',
    timeZoneId: 'America/New_York',
  },
  '49303': {
    zip: '49303',
    city: 'Bailey',
    state: 'MI',
    latitude: '43.276495',
    longitude: '-85.841572',
    timeZoneId: 'America/New_York',
  },
  '49304': {
    zip: '49304',
    city: 'Baldwin',
    state: 'MI',
    latitude: '43.897103',
    longitude: '-85.853664',
    timeZoneId: 'America/New_York',
  },
  '49305': {
    zip: '49305',
    city: 'Barryton',
    state: 'MI',
    latitude: '43.749421',
    longitude: '-85.169379',
    timeZoneId: 'America/New_York',
  },
  '49306': {
    zip: '49306',
    city: 'Belmont',
    state: 'MI',
    latitude: '43.077653',
    longitude: '-85.587725',
    timeZoneId: 'America/New_York',
  },
  '49307': {
    zip: '49307',
    city: 'Big Rapids',
    state: 'MI',
    latitude: '43.697737',
    longitude: '-85.468184',
    timeZoneId: 'America/New_York',
  },
  '49309': {
    zip: '49309',
    city: 'Bitely',
    state: 'MI',
    latitude: '43.740936',
    longitude: '-85.870792',
    timeZoneId: 'America/New_York',
  },
  '49310': {
    zip: '49310',
    city: 'Blanchard',
    state: 'MI',
    latitude: '43.519182',
    longitude: '-85.010651',
    timeZoneId: 'America/New_York',
  },
  '49311': {
    zip: '49311',
    city: 'Bradley',
    state: 'MI',
    latitude: '42.638591',
    longitude: '-85.646128',
    timeZoneId: 'America/New_York',
  },
  '49312': {
    zip: '49312',
    city: 'Brohman',
    state: 'MI',
    latitude: '43.689648',
    longitude: '-85.794821',
    timeZoneId: 'America/New_York',
  },
  '49314': {
    zip: '49314',
    city: 'Burnips',
    state: 'MI',
    latitude: '42.731059',
    longitude: '-85.851086',
    timeZoneId: 'America/New_York',
  },
  '49315': {
    zip: '49315',
    city: 'Byron Center',
    state: 'MI',
    latitude: '42.805357',
    longitude: '-85.703821',
    timeZoneId: 'America/New_York',
  },
  '49316': {
    zip: '49316',
    city: 'Caledonia',
    state: 'MI',
    latitude: '42.78702',
    longitude: '-85.54632',
    timeZoneId: 'America/New_York',
  },
  '49317': {
    zip: '49317',
    city: 'Cannonsburg',
    state: 'MI',
    latitude: '43.130587',
    longitude: '-85.482425',
    timeZoneId: 'America/New_York',
  },
  '49318': {
    zip: '49318',
    city: 'Casnovia',
    state: 'MI',
    latitude: '43.234973',
    longitude: '-85.82242',
    timeZoneId: 'America/New_York',
  },
  '49319': {
    zip: '49319',
    city: 'Cedar Springs',
    state: 'MI',
    latitude: '43.224214',
    longitude: '-85.536818',
    timeZoneId: 'America/New_York',
  },
  '49320': {
    zip: '49320',
    city: 'Chippewa Lake',
    state: 'MI',
    latitude: '43.762265',
    longitude: '-85.28766',
    timeZoneId: 'America/New_York',
  },
  '49321': {
    zip: '49321',
    city: 'Comstock Park',
    state: 'MI',
    latitude: '43.058199',
    longitude: '-85.664935',
    timeZoneId: 'America/New_York',
  },
  '49322': {
    zip: '49322',
    city: 'Coral',
    state: 'MI',
    latitude: '43.357892',
    longitude: '-85.362896',
    timeZoneId: 'America/New_York',
  },
  '49323': {
    zip: '49323',
    city: 'Dorr',
    state: 'MI',
    latitude: '42.721063',
    longitude: '-85.779886',
    timeZoneId: 'America/New_York',
  },
  '49325': {
    zip: '49325',
    city: 'Freeport',
    state: 'MI',
    latitude: '42.748202',
    longitude: '-85.308202',
    timeZoneId: 'America/New_York',
  },
  '49326': {
    zip: '49326',
    city: 'Gowen',
    state: 'MI',
    latitude: '43.23502',
    longitude: '-85.340378',
    timeZoneId: 'America/New_York',
  },
  '49327': {
    zip: '49327',
    city: 'Grant',
    state: 'MI',
    latitude: '43.341807',
    longitude: '-85.830102',
    timeZoneId: 'America/New_York',
  },
  '49328': {
    zip: '49328',
    city: 'Hopkins',
    state: 'MI',
    latitude: '42.63024',
    longitude: '-85.750474',
    timeZoneId: 'America/New_York',
  },
  '49329': {
    zip: '49329',
    city: 'Howard City',
    state: 'MI',
    latitude: '43.412482',
    longitude: '-85.459684',
    timeZoneId: 'America/New_York',
  },
  '49330': {
    zip: '49330',
    city: 'Kent City',
    state: 'MI',
    latitude: '43.238562',
    longitude: '-85.72511',
    timeZoneId: 'America/New_York',
  },
  '49331': {
    zip: '49331',
    city: 'Lowell',
    state: 'MI',
    latitude: '42.948478',
    longitude: '-85.359091',
    timeZoneId: 'America/New_York',
  },
  '49332': {
    zip: '49332',
    city: 'Mecosta',
    state: 'MI',
    latitude: '43.638325',
    longitude: '-85.24831',
    timeZoneId: 'America/New_York',
  },
  '49333': {
    zip: '49333',
    city: 'Middleville',
    state: 'MI',
    latitude: '42.68448',
    longitude: '-85.45206',
    timeZoneId: 'America/New_York',
  },
  '49335': {
    zip: '49335',
    city: 'Moline',
    state: 'MI',
    latitude: '42.737508',
    longitude: '-85.662495',
    timeZoneId: 'America/New_York',
  },
  '49336': {
    zip: '49336',
    city: 'Morley',
    state: 'MI',
    latitude: '43.506477',
    longitude: '-85.4259',
    timeZoneId: 'America/New_York',
  },
  '49337': {
    zip: '49337',
    city: 'Newaygo',
    state: 'MI',
    latitude: '43.43772',
    longitude: '-85.74042',
    timeZoneId: 'America/New_York',
  },
  '49338': {
    zip: '49338',
    city: 'Paris',
    state: 'MI',
    latitude: '43.782718',
    longitude: '-85.511821',
    timeZoneId: 'America/New_York',
  },
  '49339': {
    zip: '49339',
    city: 'Pierson',
    state: 'MI',
    latitude: '43.331082',
    longitude: '-85.481474',
    timeZoneId: 'America/New_York',
  },
  '49340': {
    zip: '49340',
    city: 'Remus',
    state: 'MI',
    latitude: '43.617953',
    longitude: '-85.13887',
    timeZoneId: 'America/New_York',
  },
  '49341': {
    zip: '49341',
    city: 'Rockford',
    state: 'MI',
    latitude: '43.114707',
    longitude: '-85.511792',
    timeZoneId: 'America/New_York',
  },
  '49342': {
    zip: '49342',
    city: 'Rodney',
    state: 'MI',
    latitude: '43.698234',
    longitude: '-85.311467',
    timeZoneId: 'America/New_York',
  },
  '49343': {
    zip: '49343',
    city: 'Sand Lake',
    state: 'MI',
    latitude: '43.279939',
    longitude: '-85.477323',
    timeZoneId: 'America/New_York',
  },
  '49344': {
    zip: '49344',
    city: 'Shelbyville',
    state: 'MI',
    latitude: '42.601379',
    longitude: '-85.613081',
    timeZoneId: 'America/New_York',
  },
  '49345': {
    zip: '49345',
    city: 'Sparta',
    state: 'MI',
    latitude: '43.161725',
    longitude: '-85.694381',
    timeZoneId: 'America/New_York',
  },
  '49346': {
    zip: '49346',
    city: 'Stanwood',
    state: 'MI',
    latitude: '43.590116',
    longitude: '-85.361863',
    timeZoneId: 'America/New_York',
  },
  '49347': {
    zip: '49347',
    city: 'Trufant',
    state: 'MI',
    latitude: '43.31808',
    longitude: '-85.344102',
    timeZoneId: 'America/New_York',
  },
  '49348': {
    zip: '49348',
    city: 'Wayland',
    state: 'MI',
    latitude: '42.680118',
    longitude: '-85.634853',
    timeZoneId: 'America/New_York',
  },
  '49349': {
    zip: '49349',
    city: 'White Cloud',
    state: 'MI',
    latitude: '43.561678',
    longitude: '-85.752158',
    timeZoneId: 'America/New_York',
  },
  '49351': {
    zip: '49351',
    city: 'Rockford',
    state: 'MI',
    latitude: '43.125261',
    longitude: '-85.559025',
    timeZoneId: 'America/New_York',
  },
  '49355': {
    zip: '49355',
    city: 'Ada',
    state: 'MI',
    latitude: '42.953901',
    longitude: '-85.478655',
    timeZoneId: 'America/New_York',
  },
  '49356': {
    zip: '49356',
    city: 'Ada',
    state: 'MI',
    latitude: '42.956876',
    longitude: '-85.4814',
    timeZoneId: 'America/New_York',
  },
  '49357': {
    zip: '49357',
    city: 'Ada',
    state: 'MI',
    latitude: '42.950385',
    longitude: '-85.487478',
    timeZoneId: 'America/New_York',
  },
  '49401': {
    zip: '49401',
    city: 'Allendale',
    state: 'MI',
    latitude: '42.974447',
    longitude: '-85.930121',
    timeZoneId: 'America/New_York',
  },
  '49402': {
    zip: '49402',
    city: 'Branch',
    state: 'MI',
    latitude: '43.920128',
    longitude: '-86.067294',
    timeZoneId: 'America/New_York',
  },
  '49403': {
    zip: '49403',
    city: 'Conklin',
    state: 'MI',
    latitude: '43.136209',
    longitude: '-85.856936',
    timeZoneId: 'America/New_York',
  },
  '49404': {
    zip: '49404',
    city: 'Coopersville',
    state: 'MI',
    latitude: '43.066832',
    longitude: '-85.934647',
    timeZoneId: 'America/New_York',
  },
  '49405': {
    zip: '49405',
    city: 'Custer',
    state: 'MI',
    latitude: '43.927259',
    longitude: '-86.201867',
    timeZoneId: 'America/New_York',
  },
  '49406': {
    zip: '49406',
    city: 'Douglas',
    state: 'MI',
    latitude: '42.639583',
    longitude: '-86.213961',
    timeZoneId: 'America/New_York',
  },
  '49408': {
    zip: '49408',
    city: 'Fennville',
    state: 'MI',
    latitude: '42.56718',
    longitude: '-86.12616',
    timeZoneId: 'America/New_York',
  },
  '49409': {
    zip: '49409',
    city: 'Ferrysburg',
    state: 'MI',
    latitude: '43.082979',
    longitude: '-86.213705',
    timeZoneId: 'America/New_York',
  },
  '49410': {
    zip: '49410',
    city: 'Fountain',
    state: 'MI',
    latitude: '44.016476',
    longitude: '-86.135206',
    timeZoneId: 'America/New_York',
  },
  '49411': {
    zip: '49411',
    city: 'Free Soil',
    state: 'MI',
    latitude: '44.110189',
    longitude: '-86.238527',
    timeZoneId: 'America/New_York',
  },
  '49412': {
    zip: '49412',
    city: 'Fremont',
    state: 'MI',
    latitude: '43.46655',
    longitude: '-85.948006',
    timeZoneId: 'America/New_York',
  },
  '49413': {
    zip: '49413',
    city: 'Fremont',
    state: 'MI',
    latitude: '43.462833',
    longitude: '-85.952274',
    timeZoneId: 'America/New_York',
  },
  '49415': {
    zip: '49415',
    city: 'Fruitport',
    state: 'MI',
    latitude: '43.144002',
    longitude: '-86.1348',
    timeZoneId: 'America/New_York',
  },
  '49416': {
    zip: '49416',
    city: 'Glenn',
    state: 'MI',
    latitude: '42.521987',
    longitude: '-86.232601',
    timeZoneId: 'America/New_York',
  },
  '49417': {
    zip: '49417',
    city: 'Grand Haven',
    state: 'MI',
    latitude: '43.035526',
    longitude: '-86.183808',
    timeZoneId: 'America/New_York',
  },
  '49418': {
    zip: '49418',
    city: 'Grandville',
    state: 'MI',
    latitude: '42.896187',
    longitude: '-85.756061',
    timeZoneId: 'America/New_York',
  },
  '49419': {
    zip: '49419',
    city: 'Hamilton',
    state: 'MI',
    latitude: '42.686263',
    longitude: '-85.984542',
    timeZoneId: 'America/New_York',
  },
  '49420': {
    zip: '49420',
    city: 'Hart',
    state: 'MI',
    latitude: '43.703466',
    longitude: '-86.359567',
    timeZoneId: 'America/New_York',
  },
  '49421': {
    zip: '49421',
    city: 'Hesperia',
    state: 'MI',
    latitude: '43.612946',
    longitude: '-86.063543',
    timeZoneId: 'America/New_York',
  },
  '49422': {
    zip: '49422',
    city: 'Holland',
    state: 'MI',
    latitude: '42.786809',
    longitude: '-86.109131',
    timeZoneId: 'America/New_York',
  },
  '49423': {
    zip: '49423',
    city: 'Holland',
    state: 'MI',
    latitude: '42.779463',
    longitude: '-86.110617',
    timeZoneId: 'America/New_York',
  },
  '49424': {
    zip: '49424',
    city: 'Holland',
    state: 'MI',
    latitude: '42.821709',
    longitude: '-86.146908',
    timeZoneId: 'America/New_York',
  },
  '49425': {
    zip: '49425',
    city: 'Holton',
    state: 'MI',
    latitude: '43.42518',
    longitude: '-86.105175',
    timeZoneId: 'America/New_York',
  },
  '49426': {
    zip: '49426',
    city: 'Hudsonville',
    state: 'MI',
    latitude: '42.875583',
    longitude: '-85.877048',
    timeZoneId: 'America/New_York',
  },
  '49427': {
    zip: '49427',
    city: 'Jamestown',
    state: 'MI',
    latitude: '42.827106',
    longitude: '-85.846942',
    timeZoneId: 'America/New_York',
  },
  '49428': {
    zip: '49428',
    city: 'Jenison',
    state: 'MI',
    latitude: '42.907457',
    longitude: '-85.830308',
    timeZoneId: 'America/New_York',
  },
  '49429': {
    zip: '49429',
    city: 'Jenison',
    state: 'MI',
    latitude: '42.89011',
    longitude: '-85.831096',
    timeZoneId: 'America/New_York',
  },
  '49430': {
    zip: '49430',
    city: 'Lamont',
    state: 'MI',
    latitude: '43.006717',
    longitude: '-85.89515',
    timeZoneId: 'America/New_York',
  },
  '49431': {
    zip: '49431',
    city: 'Ludington',
    state: 'MI',
    latitude: '43.967114',
    longitude: '-86.419549',
    timeZoneId: 'America/New_York',
  },
  '49434': {
    zip: '49434',
    city: 'Macatawa',
    state: 'MI',
    latitude: '42.766497',
    longitude: '-86.207979',
    timeZoneId: 'America/New_York',
  },
  '49435': {
    zip: '49435',
    city: 'Marne',
    state: 'MI',
    latitude: '43.023977',
    longitude: '-85.837519',
    timeZoneId: 'America/New_York',
  },
  '49436': {
    zip: '49436',
    city: 'Mears',
    state: 'MI',
    latitude: '43.681967',
    longitude: '-86.464667',
    timeZoneId: 'America/New_York',
  },
  '49437': {
    zip: '49437',
    city: 'Montague',
    state: 'MI',
    latitude: '43.421496',
    longitude: '-86.360385',
    timeZoneId: 'America/New_York',
  },
  '49440': {
    zip: '49440',
    city: 'Muskegon',
    state: 'MI',
    latitude: '43.232503',
    longitude: '-86.251323',
    timeZoneId: 'America/New_York',
  },
  '49441': {
    zip: '49441',
    city: 'Muskegon',
    state: 'MI',
    latitude: '43.168889',
    longitude: '-86.263889',
    timeZoneId: 'America/New_York',
  },
  '49442': {
    zip: '49442',
    city: 'Muskegon',
    state: 'MI',
    latitude: '43.233938',
    longitude: '-86.169837',
    timeZoneId: 'America/New_York',
  },
  '49443': {
    zip: '49443',
    city: 'Muskegon',
    state: 'MI',
    latitude: '43.211349',
    longitude: '-86.228675',
    timeZoneId: 'America/New_York',
  },
  '49444': {
    zip: '49444',
    city: 'Muskegon',
    state: 'MI',
    latitude: '43.16844',
    longitude: '-86.18454',
    timeZoneId: 'America/New_York',
  },
  '49445': {
    zip: '49445',
    city: 'Muskegon',
    state: 'MI',
    latitude: '43.29318',
    longitude: '-86.28288',
    timeZoneId: 'America/New_York',
  },
  '49446': {
    zip: '49446',
    city: 'New Era',
    state: 'MI',
    latitude: '43.561615',
    longitude: '-86.357296',
    timeZoneId: 'America/New_York',
  },
  '49448': {
    zip: '49448',
    city: 'Nunica',
    state: 'MI',
    latitude: '43.080133',
    longitude: '-86.083807',
    timeZoneId: 'America/New_York',
  },
  '49449': {
    zip: '49449',
    city: 'Pentwater',
    state: 'MI',
    latitude: '43.79682',
    longitude: '-86.37132',
    timeZoneId: 'America/New_York',
  },
  '49450': {
    zip: '49450',
    city: 'Pullman',
    state: 'MI',
    latitude: '42.480878',
    longitude: '-86.077307',
    timeZoneId: 'America/New_York',
  },
  '49451': {
    zip: '49451',
    city: 'Ravenna',
    state: 'MI',
    latitude: '43.205403',
    longitude: '-85.958895',
    timeZoneId: 'America/New_York',
  },
  '49452': {
    zip: '49452',
    city: 'Rothbury',
    state: 'MI',
    latitude: '43.513627',
    longitude: '-86.305428',
    timeZoneId: 'America/New_York',
  },
  '49453': {
    zip: '49453',
    city: 'Saugatuck',
    state: 'MI',
    latitude: '42.663097',
    longitude: '-86.185702',
    timeZoneId: 'America/New_York',
  },
  '49454': {
    zip: '49454',
    city: 'Scottville',
    state: 'MI',
    latitude: '43.941651',
    longitude: '-86.286813',
    timeZoneId: 'America/New_York',
  },
  '49455': {
    zip: '49455',
    city: 'Shelby',
    state: 'MI',
    latitude: '43.600338',
    longitude: '-86.361909',
    timeZoneId: 'America/New_York',
  },
  '49456': {
    zip: '49456',
    city: 'Spring Lake',
    state: 'MI',
    latitude: '43.086511',
    longitude: '-86.189307',
    timeZoneId: 'America/New_York',
  },
  '49457': {
    zip: '49457',
    city: 'Twin Lake',
    state: 'MI',
    latitude: '43.359781',
    longitude: '-86.176186',
    timeZoneId: 'America/New_York',
  },
  '49458': {
    zip: '49458',
    city: 'Walhalla',
    state: 'MI',
    latitude: '43.94389',
    longitude: '-86.09922',
    timeZoneId: 'America/New_York',
  },
  '49459': {
    zip: '49459',
    city: 'Walkerville',
    state: 'MI',
    latitude: '43.741411',
    longitude: '-86.008534',
    timeZoneId: 'America/New_York',
  },
  '49460': {
    zip: '49460',
    city: 'West Olive',
    state: 'MI',
    latitude: '42.92802',
    longitude: '-86.10864',
    timeZoneId: 'America/New_York',
  },
  '49461': {
    zip: '49461',
    city: 'Whitehall',
    state: 'MI',
    latitude: '43.384995',
    longitude: '-86.340748',
    timeZoneId: 'America/New_York',
  },
  '49463': {
    zip: '49463',
    city: 'Sylvan Beach',
    state: 'MI',
    latitude: '43.370564',
    longitude: '-86.423252',
    timeZoneId: 'America/New_York',
  },
  '49464': {
    zip: '49464',
    city: 'Zeeland',
    state: 'MI',
    latitude: '42.86688',
    longitude: '-85.9686',
    timeZoneId: 'America/New_York',
  },
  '49468': {
    zip: '49468',
    city: 'Grandville',
    state: 'MI',
    latitude: '42.905879',
    longitude: '-85.75528',
    timeZoneId: 'America/New_York',
  },
  '49501': {
    zip: '49501',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.96492',
    longitude: '-85.658539',
    timeZoneId: 'America/New_York',
  },
  '49502': {
    zip: '49502',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.961458',
    longitude: '-85.661874',
    timeZoneId: 'America/New_York',
  },
  '49503': {
    zip: '49503',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.963193',
    longitude: '-85.659686',
    timeZoneId: 'America/New_York',
  },
  '49504': {
    zip: '49504',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.986465',
    longitude: '-85.721042',
    timeZoneId: 'America/New_York',
  },
  '49505': {
    zip: '49505',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '43.010412',
    longitude: '-85.625219',
    timeZoneId: 'America/New_York',
  },
  '49506': {
    zip: '49506',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.94165',
    longitude: '-85.594449',
    timeZoneId: 'America/New_York',
  },
  '49507': {
    zip: '49507',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.934602',
    longitude: '-85.652654',
    timeZoneId: 'America/New_York',
  },
  '49508': {
    zip: '49508',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.873625',
    longitude: '-85.625143',
    timeZoneId: 'America/New_York',
  },
  '49509': {
    zip: '49509',
    city: 'Wyoming',
    state: 'MI',
    latitude: '42.88386',
    longitude: '-85.70616',
    timeZoneId: 'America/New_York',
  },
  '49510': {
    zip: '49510',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '43.022059',
    longitude: '-85.612581',
    timeZoneId: 'America/New_York',
  },
  '49512': {
    zip: '49512',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.88146',
    longitude: '-85.5354',
    timeZoneId: 'America/New_York',
  },
  '49514': {
    zip: '49514',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.965573',
    longitude: '-85.655928',
    timeZoneId: 'America/New_York',
  },
  '49515': {
    zip: '49515',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.962887',
    longitude: '-85.654539',
    timeZoneId: 'America/New_York',
  },
  '49516': {
    zip: '49516',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.919062',
    longitude: '-85.527056',
    timeZoneId: 'America/New_York',
  },
  '49518': {
    zip: '49518',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.875823',
    longitude: '-85.625787',
    timeZoneId: 'America/New_York',
  },
  '49519': {
    zip: '49519',
    city: 'Wyoming',
    state: 'MI',
    latitude: '42.901059',
    longitude: '-85.70473',
    timeZoneId: 'America/New_York',
  },
  '49523': {
    zip: '49523',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.96524',
    longitude: '-85.672993',
    timeZoneId: 'America/New_York',
  },
  '49525': {
    zip: '49525',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '43.01283',
    longitude: '-85.601883',
    timeZoneId: 'America/New_York',
  },
  '49528': {
    zip: '49528',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.8811',
    longitude: '-85.6239',
    timeZoneId: 'America/New_York',
  },
  '49530': {
    zip: '49530',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.866782',
    longitude: '-85.544254',
    timeZoneId: 'America/New_York',
  },
  '49534': {
    zip: '49534',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.9769',
    longitude: '-85.7689',
    timeZoneId: 'America/New_York',
  },
  '49544': {
    zip: '49544',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '43.001565',
    longitude: '-85.743164',
    timeZoneId: 'America/New_York',
  },
  '49546': {
    zip: '49546',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.928581',
    longitude: '-85.53204',
    timeZoneId: 'America/New_York',
  },
  '49548': {
    zip: '49548',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.86657',
    longitude: '-85.660484',
    timeZoneId: 'America/New_York',
  },
  '49555': {
    zip: '49555',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.969643',
    longitude: '-85.665859',
    timeZoneId: 'America/New_York',
  },
  '49560': {
    zip: '49560',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.876705',
    longitude: '-85.627473',
    timeZoneId: 'America/New_York',
  },
  '49588': {
    zip: '49588',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.963544',
    longitude: '-85.656222',
    timeZoneId: 'America/New_York',
  },
  '49599': {
    zip: '49599',
    city: 'Grand Rapids',
    state: 'MI',
    latitude: '42.963384',
    longitude: '-85.652746',
    timeZoneId: 'America/New_York',
  },
  '49601': {
    zip: '49601',
    city: 'Cadillac',
    state: 'MI',
    latitude: '44.25667',
    longitude: '-85.44522',
    timeZoneId: 'America/New_York',
  },
  '49610': {
    zip: '49610',
    city: 'Acme',
    state: 'MI',
    latitude: '44.793024',
    longitude: '-85.494568',
    timeZoneId: 'America/New_York',
  },
  '49611': {
    zip: '49611',
    city: 'Alba',
    state: 'MI',
    latitude: '44.958047',
    longitude: '-84.899828',
    timeZoneId: 'America/New_York',
  },
  '49612': {
    zip: '49612',
    city: 'Alden',
    state: 'MI',
    latitude: '44.893479',
    longitude: '-85.265659',
    timeZoneId: 'America/New_York',
  },
  '49613': {
    zip: '49613',
    city: 'Arcadia',
    state: 'MI',
    latitude: '44.480669',
    longitude: '-86.21765',
    timeZoneId: 'America/New_York',
  },
  '49614': {
    zip: '49614',
    city: 'Bear Lake',
    state: 'MI',
    latitude: '44.423101',
    longitude: '-86.126289',
    timeZoneId: 'America/New_York',
  },
  '49615': {
    zip: '49615',
    city: 'Bellaire',
    state: 'MI',
    latitude: '44.966845',
    longitude: '-85.21313',
    timeZoneId: 'America/New_York',
  },
  '49616': {
    zip: '49616',
    city: 'Benzonia',
    state: 'MI',
    latitude: '44.588594',
    longitude: '-86.097228',
    timeZoneId: 'America/New_York',
  },
  '49617': {
    zip: '49617',
    city: 'Beulah',
    state: 'MI',
    latitude: '44.644459',
    longitude: '-86.055187',
    timeZoneId: 'America/New_York',
  },
  '49618': {
    zip: '49618',
    city: 'Boon',
    state: 'MI',
    latitude: '44.301037',
    longitude: '-85.592047',
    timeZoneId: 'America/New_York',
  },
  '49619': {
    zip: '49619',
    city: 'Brethren',
    state: 'MI',
    latitude: '44.307001',
    longitude: '-86.024596',
    timeZoneId: 'America/New_York',
  },
  '49620': {
    zip: '49620',
    city: 'Buckley',
    state: 'MI',
    latitude: '44.548612',
    longitude: '-85.716975',
    timeZoneId: 'America/New_York',
  },
  '49621': {
    zip: '49621',
    city: 'Cedar',
    state: 'MI',
    latitude: '44.862433',
    longitude: '-85.783402',
    timeZoneId: 'America/New_York',
  },
  '49622': {
    zip: '49622',
    city: 'Central Lake',
    state: 'MI',
    latitude: '45.075561',
    longitude: '-85.260237',
    timeZoneId: 'America/New_York',
  },
  '49623': {
    zip: '49623',
    city: 'Chase',
    state: 'MI',
    latitude: '43.895693',
    longitude: '-85.63629',
    timeZoneId: 'America/New_York',
  },
  '49625': {
    zip: '49625',
    city: 'Copemish',
    state: 'MI',
    latitude: '44.44773',
    longitude: '-85.886363',
    timeZoneId: 'America/New_York',
  },
  '49626': {
    zip: '49626',
    city: 'Eastlake',
    state: 'MI',
    latitude: '44.244523',
    longitude: '-86.294677',
    timeZoneId: 'America/New_York',
  },
  '49627': {
    zip: '49627',
    city: 'Eastport',
    state: 'MI',
    latitude: '45.088153',
    longitude: '-85.310648',
    timeZoneId: 'America/New_York',
  },
  '49628': {
    zip: '49628',
    city: 'Elberta',
    state: 'MI',
    latitude: '44.617654',
    longitude: '-86.225748',
    timeZoneId: 'America/New_York',
  },
  '49629': {
    zip: '49629',
    city: 'Elk Rapids',
    state: 'MI',
    latitude: '44.904646',
    longitude: '-85.399511',
    timeZoneId: 'America/New_York',
  },
  '49630': {
    zip: '49630',
    city: 'Empire',
    state: 'MI',
    latitude: '44.837486',
    longitude: '-86.032086',
    timeZoneId: 'America/New_York',
  },
  '49631': {
    zip: '49631',
    city: 'Evart',
    state: 'MI',
    latitude: '43.893701',
    longitude: '-85.267454',
    timeZoneId: 'America/New_York',
  },
  '49632': {
    zip: '49632',
    city: 'Falmouth',
    state: 'MI',
    latitude: '44.256075',
    longitude: '-85.024453',
    timeZoneId: 'America/New_York',
  },
  '49633': {
    zip: '49633',
    city: 'Fife Lake',
    state: 'MI',
    latitude: '44.564228',
    longitude: '-85.1909',
    timeZoneId: 'America/New_York',
  },
  '49634': {
    zip: '49634',
    city: 'Filer City',
    state: 'MI',
    latitude: '44.214543',
    longitude: '-86.288914',
    timeZoneId: 'America/New_York',
  },
  '49635': {
    zip: '49635',
    city: 'Frankfort',
    state: 'MI',
    latitude: '44.631996',
    longitude: '-86.21469',
    timeZoneId: 'America/New_York',
  },
  '49636': {
    zip: '49636',
    city: 'Glen Arbor',
    state: 'MI',
    latitude: '44.890959',
    longitude: '-85.963971',
    timeZoneId: 'America/New_York',
  },
  '49637': {
    zip: '49637',
    city: 'Grawn',
    state: 'MI',
    latitude: '44.633541',
    longitude: '-85.706844',
    timeZoneId: 'America/New_York',
  },
  '49638': {
    zip: '49638',
    city: 'Harrietta',
    state: 'MI',
    latitude: '44.291982',
    longitude: '-85.727444',
    timeZoneId: 'America/New_York',
  },
  '49639': {
    zip: '49639',
    city: 'Hersey',
    state: 'MI',
    latitude: '43.852028',
    longitude: '-85.409839',
    timeZoneId: 'America/New_York',
  },
  '49640': {
    zip: '49640',
    city: 'Honor',
    state: 'MI',
    latitude: '44.703782',
    longitude: '-86.060239',
    timeZoneId: 'America/New_York',
  },
  '49642': {
    zip: '49642',
    city: 'Idlewild',
    state: 'MI',
    latitude: '43.889548',
    longitude: '-85.782464',
    timeZoneId: 'America/New_York',
  },
  '49643': {
    zip: '49643',
    city: 'Interlochen',
    state: 'MI',
    latitude: '44.65116',
    longitude: '-85.83864',
    timeZoneId: 'America/New_York',
  },
  '49644': {
    zip: '49644',
    city: 'Irons',
    state: 'MI',
    latitude: '44.165877',
    longitude: '-85.958375',
    timeZoneId: 'America/New_York',
  },
  '49645': {
    zip: '49645',
    city: 'Kaleva',
    state: 'MI',
    latitude: '44.365346',
    longitude: '-86.038719',
    timeZoneId: 'America/New_York',
  },
  '49646': {
    zip: '49646',
    city: 'Kalkaska',
    state: 'MI',
    latitude: '44.737703',
    longitude: '-85.081328',
    timeZoneId: 'America/New_York',
  },
  '49648': {
    zip: '49648',
    city: 'Kewadin',
    state: 'MI',
    latitude: '44.926163',
    longitude: '-85.35449',
    timeZoneId: 'America/New_York',
  },
  '49649': {
    zip: '49649',
    city: 'Kingsley',
    state: 'MI',
    latitude: '44.576008',
    longitude: '-85.544315',
    timeZoneId: 'America/New_York',
  },
  '49650': {
    zip: '49650',
    city: 'Lake Ann',
    state: 'MI',
    latitude: '44.747191',
    longitude: '-85.877104',
    timeZoneId: 'America/New_York',
  },
  '49651': {
    zip: '49651',
    city: 'Lake City',
    state: 'MI',
    latitude: '44.38656',
    longitude: '-85.1454',
    timeZoneId: 'America/New_York',
  },
  '49653': {
    zip: '49653',
    city: 'Lake Leelanau',
    state: 'MI',
    latitude: '44.98983',
    longitude: '-85.735701',
    timeZoneId: 'America/New_York',
  },
  '49654': {
    zip: '49654',
    city: 'Leland',
    state: 'MI',
    latitude: '45.025065',
    longitude: '-85.752371',
    timeZoneId: 'America/New_York',
  },
  '49655': {
    zip: '49655',
    city: 'Leroy',
    state: 'MI',
    latitude: '44.04948',
    longitude: '-85.4439',
    timeZoneId: 'America/New_York',
  },
  '49656': {
    zip: '49656',
    city: 'Luther',
    state: 'MI',
    latitude: '44.056564',
    longitude: '-85.687546',
    timeZoneId: 'America/New_York',
  },
  '49657': {
    zip: '49657',
    city: 'Mc Bain',
    state: 'MI',
    latitude: '44.2194',
    longitude: '-85.193294',
    timeZoneId: 'America/New_York',
  },
  '49659': {
    zip: '49659',
    city: 'Mancelona',
    state: 'MI',
    latitude: '44.913539',
    longitude: '-85.039556',
    timeZoneId: 'America/New_York',
  },
  '49660': {
    zip: '49660',
    city: 'Manistee',
    state: 'MI',
    latitude: '44.17644',
    longitude: '-86.20956',
    timeZoneId: 'America/New_York',
  },
  '49663': {
    zip: '49663',
    city: 'Manton',
    state: 'MI',
    latitude: '44.420163',
    longitude: '-85.432435',
    timeZoneId: 'America/New_York',
  },
  '49664': {
    zip: '49664',
    city: 'Maple City',
    state: 'MI',
    latitude: '44.878854',
    longitude: '-85.889433',
    timeZoneId: 'America/New_York',
  },
  '49665': {
    zip: '49665',
    city: 'Marion',
    state: 'MI',
    latitude: '44.094231',
    longitude: '-85.178134',
    timeZoneId: 'America/New_York',
  },
  '49666': {
    zip: '49666',
    city: 'Mayfield',
    state: 'MI',
    latitude: '44.597988',
    longitude: '-85.534358',
    timeZoneId: 'America/New_York',
  },
  '49667': {
    zip: '49667',
    city: 'Merritt',
    state: 'MI',
    latitude: '44.343975',
    longitude: '-84.930617',
    timeZoneId: 'America/New_York',
  },
  '49668': {
    zip: '49668',
    city: 'Mesick',
    state: 'MI',
    latitude: '44.408397',
    longitude: '-85.709353',
    timeZoneId: 'America/New_York',
  },
  '49670': {
    zip: '49670',
    city: 'Northport',
    state: 'MI',
    latitude: '45.132456',
    longitude: '-85.611687',
    timeZoneId: 'America/New_York',
  },
  '49673': {
    zip: '49673',
    city: 'Old Mission',
    state: 'MI',
    latitude: '44.954609',
    longitude: '-85.488628',
    timeZoneId: 'America/New_York',
  },
  '49674': {
    zip: '49674',
    city: 'Omena',
    state: 'MI',
    latitude: '45.063163',
    longitude: '-85.621512',
    timeZoneId: 'America/New_York',
  },
  '49675': {
    zip: '49675',
    city: 'Onekama',
    state: 'MI',
    latitude: '44.368783',
    longitude: '-86.218269',
    timeZoneId: 'America/New_York',
  },
  '49676': {
    zip: '49676',
    city: 'Rapid City',
    state: 'MI',
    latitude: '44.821793',
    longitude: '-85.263782',
    timeZoneId: 'America/New_York',
  },
  '49677': {
    zip: '49677',
    city: 'Reed City',
    state: 'MI',
    latitude: '43.899809',
    longitude: '-85.511425',
    timeZoneId: 'America/New_York',
  },
  '49679': {
    zip: '49679',
    city: 'Sears',
    state: 'MI',
    latitude: '43.863072',
    longitude: '-85.158511',
    timeZoneId: 'America/New_York',
  },
  '49680': {
    zip: '49680',
    city: 'South Boardman',
    state: 'MI',
    latitude: '44.633052',
    longitude: '-85.24349',
    timeZoneId: 'America/New_York',
  },
  '49682': {
    zip: '49682',
    city: 'Suttons Bay',
    state: 'MI',
    latitude: '44.967179',
    longitude: '-85.639857',
    timeZoneId: 'America/New_York',
  },
  '49683': {
    zip: '49683',
    city: 'Thompsonville',
    state: 'MI',
    latitude: '44.499017',
    longitude: '-85.997212',
    timeZoneId: 'America/New_York',
  },
  '49684': {
    zip: '49684',
    city: 'Traverse City',
    state: 'MI',
    latitude: '44.773499',
    longitude: '-85.703641',
    timeZoneId: 'America/New_York',
  },
  '49685': {
    zip: '49685',
    city: 'Traverse City',
    state: 'MI',
    latitude: '44.674614',
    longitude: '-85.654239',
    timeZoneId: 'America/New_York',
  },
  '49686': {
    zip: '49686',
    city: 'Traverse City',
    state: 'MI',
    latitude: '44.754969',
    longitude: '-85.596072',
    timeZoneId: 'America/New_York',
  },
  '49688': {
    zip: '49688',
    city: 'Tustin',
    state: 'MI',
    latitude: '44.11368',
    longitude: '-85.430879',
    timeZoneId: 'America/New_York',
  },
  '49689': {
    zip: '49689',
    city: 'Wellston',
    state: 'MI',
    latitude: '44.212918',
    longitude: '-85.937123',
    timeZoneId: 'America/New_York',
  },
  '49690': {
    zip: '49690',
    city: 'Williamsburg',
    state: 'MI',
    latitude: '44.789033',
    longitude: '-85.429799',
    timeZoneId: 'America/New_York',
  },
  '49696': {
    zip: '49696',
    city: 'Traverse City',
    state: 'MI',
    latitude: '44.757555',
    longitude: '-85.601648',
    timeZoneId: 'America/New_York',
  },
  '49701': {
    zip: '49701',
    city: 'Mackinaw City',
    state: 'MI',
    latitude: '45.777537',
    longitude: '-84.754096',
    timeZoneId: 'America/New_York',
  },
  '49705': {
    zip: '49705',
    city: 'Afton',
    state: 'MI',
    latitude: '45.363111',
    longitude: '-84.474201',
    timeZoneId: 'America/New_York',
  },
  '49706': {
    zip: '49706',
    city: 'Alanson',
    state: 'MI',
    latitude: '45.445809',
    longitude: '-84.801513',
    timeZoneId: 'America/New_York',
  },
  '49707': {
    zip: '49707',
    city: 'Alpena',
    state: 'MI',
    latitude: '45.075555',
    longitude: '-83.478059',
    timeZoneId: 'America/New_York',
  },
  '49709': {
    zip: '49709',
    city: 'Atlanta',
    state: 'MI',
    latitude: '44.991675',
    longitude: '-84.144137',
    timeZoneId: 'America/New_York',
  },
  '49710': {
    zip: '49710',
    city: 'Barbeau',
    state: 'MI',
    latitude: '46.486615',
    longitude: '-84.376114',
    timeZoneId: 'America/New_York',
  },
  '49711': {
    zip: '49711',
    city: 'Bay Shore',
    state: 'MI',
    latitude: '45.358333',
    longitude: '-85.097222',
    timeZoneId: 'America/New_York',
  },
  '49712': {
    zip: '49712',
    city: 'Boyne City',
    state: 'MI',
    latitude: '45.221294',
    longitude: '-85.026342',
    timeZoneId: 'America/New_York',
  },
  '49713': {
    zip: '49713',
    city: 'Boyne Falls',
    state: 'MI',
    latitude: '45.192764',
    longitude: '-84.859816',
    timeZoneId: 'America/New_York',
  },
  '49715': {
    zip: '49715',
    city: 'Brimley',
    state: 'MI',
    latitude: '46.397049',
    longitude: '-84.594749',
    timeZoneId: 'America/New_York',
  },
  '49716': {
    zip: '49716',
    city: 'Brutus',
    state: 'MI',
    latitude: '45.504726',
    longitude: '-84.772608',
    timeZoneId: 'America/New_York',
  },
  '49717': {
    zip: '49717',
    city: 'Burt Lake',
    state: 'MI',
    latitude: '45.435582',
    longitude: '-84.696901',
    timeZoneId: 'America/New_York',
  },
  '49718': {
    zip: '49718',
    city: 'Carp Lake',
    state: 'MI',
    latitude: '45.699913',
    longitude: '-84.7924',
    timeZoneId: 'America/New_York',
  },
  '49719': {
    zip: '49719',
    city: 'Cedarville',
    state: 'MI',
    latitude: '45.924883',
    longitude: '-84.398988',
    timeZoneId: 'America/New_York',
  },
  '49720': {
    zip: '49720',
    city: 'Charlevoix',
    state: 'MI',
    latitude: '45.27936',
    longitude: '-85.27047',
    timeZoneId: 'America/New_York',
  },
  '49721': {
    zip: '49721',
    city: 'Cheboygan',
    state: 'MI',
    latitude: '45.593079',
    longitude: '-84.471635',
    timeZoneId: 'America/New_York',
  },
  '49722': {
    zip: '49722',
    city: 'Conway',
    state: 'MI',
    latitude: '45.419771',
    longitude: '-84.865068',
    timeZoneId: 'America/New_York',
  },
  '49723': {
    zip: '49723',
    city: 'Cross Village',
    state: 'MI',
    latitude: '45.629984',
    longitude: '-85.023411',
    timeZoneId: 'America/New_York',
  },
  '49724': {
    zip: '49724',
    city: 'Dafter',
    state: 'MI',
    latitude: '46.344348',
    longitude: '-84.415923',
    timeZoneId: 'America/New_York',
  },
  '49725': {
    zip: '49725',
    city: 'De Tour Village',
    state: 'MI',
    latitude: '45.984999',
    longitude: '-83.90384',
    timeZoneId: 'America/New_York',
  },
  '49726': {
    zip: '49726',
    city: 'Drummond Island',
    state: 'MI',
    latitude: '45.9982',
    longitude: '-83.71592',
    timeZoneId: 'America/New_York',
  },
  '49727': {
    zip: '49727',
    city: 'East Jordan',
    state: 'MI',
    latitude: '45.17569',
    longitude: '-85.132484',
    timeZoneId: 'America/New_York',
  },
  '49728': {
    zip: '49728',
    city: 'Eckerman',
    state: 'MI',
    latitude: '46.332529',
    longitude: '-85.176969',
    timeZoneId: 'America/New_York',
  },
  '49729': {
    zip: '49729',
    city: 'Ellsworth',
    state: 'MI',
    latitude: '45.14527',
    longitude: '-85.211919',
    timeZoneId: 'America/New_York',
  },
  '49730': {
    zip: '49730',
    city: 'Elmira',
    state: 'MI',
    latitude: '44.950099',
    longitude: '-84.883435',
    timeZoneId: 'America/New_York',
  },
  '49733': {
    zip: '49733',
    city: 'Frederic',
    state: 'MI',
    latitude: '44.795919',
    longitude: '-84.723377',
    timeZoneId: 'America/New_York',
  },
  '49734': {
    zip: '49734',
    city: 'Gaylord',
    state: 'MI',
    latitude: '45.026069',
    longitude: '-84.67301',
    timeZoneId: 'America/New_York',
  },
  '49735': {
    zip: '49735',
    city: 'Gaylord',
    state: 'MI',
    latitude: '44.987952',
    longitude: '-84.675724',
    timeZoneId: 'America/New_York',
  },
  '49736': {
    zip: '49736',
    city: 'Goetzville',
    state: 'MI',
    latitude: '46.095',
    longitude: '-84.06702',
    timeZoneId: 'America/New_York',
  },
  '49737': {
    zip: '49737',
    city: 'Good Hart',
    state: 'MI',
    latitude: '45.562761',
    longitude: '-85.097009',
    timeZoneId: 'America/New_York',
  },
  '49738': {
    zip: '49738',
    city: 'Grayling',
    state: 'MI',
    latitude: '44.660428',
    longitude: '-84.727615',
    timeZoneId: 'America/New_York',
  },
  '49739': {
    zip: '49739',
    city: 'Grayling',
    state: 'MI',
    latitude: '44.621842',
    longitude: '-84.784785',
    timeZoneId: 'America/New_York',
  },
  '49740': {
    zip: '49740',
    city: 'Harbor Springs',
    state: 'MI',
    latitude: '45.53147',
    longitude: '-85.004593',
    timeZoneId: 'America/New_York',
  },
  '49743': {
    zip: '49743',
    city: 'Hawks',
    state: 'MI',
    latitude: '45.294167',
    longitude: '-83.873513',
    timeZoneId: 'America/New_York',
  },
  '49744': {
    zip: '49744',
    city: 'Herron',
    state: 'MI',
    latitude: '44.992291',
    longitude: '-83.664049',
    timeZoneId: 'America/New_York',
  },
  '49745': {
    zip: '49745',
    city: 'Hessel',
    state: 'MI',
    latitude: '46.025812',
    longitude: '-84.327501',
    timeZoneId: 'America/New_York',
  },
  '49746': {
    zip: '49746',
    city: 'Hillman',
    state: 'MI',
    latitude: '45.066069',
    longitude: '-83.843782',
    timeZoneId: 'America/New_York',
  },
  '49747': {
    zip: '49747',
    city: 'Hubbard Lake',
    state: 'MI',
    latitude: '44.892056',
    longitude: '-83.611057',
    timeZoneId: 'America/New_York',
  },
  '49748': {
    zip: '49748',
    city: 'Hulbert',
    state: 'MI',
    latitude: '46.355616',
    longitude: '-85.156135',
    timeZoneId: 'America/New_York',
  },
  '49749': {
    zip: '49749',
    city: 'Indian River',
    state: 'MI',
    latitude: '45.425299',
    longitude: '-84.588537',
    timeZoneId: 'America/New_York',
  },
  '49751': {
    zip: '49751',
    city: 'Johannesburg',
    state: 'MI',
    latitude: '44.976836',
    longitude: '-84.449237',
    timeZoneId: 'America/New_York',
  },
  '49752': {
    zip: '49752',
    city: 'Kinross',
    state: 'MI',
    latitude: '46.364888',
    longitude: '-84.844438',
    timeZoneId: 'America/New_York',
  },
  '49753': {
    zip: '49753',
    city: 'Lachine',
    state: 'MI',
    latitude: '45.057363',
    longitude: '-83.747507',
    timeZoneId: 'America/New_York',
  },
  '49755': {
    zip: '49755',
    city: 'Levering',
    state: 'MI',
    latitude: '45.644468',
    longitude: '-84.84045',
    timeZoneId: 'America/New_York',
  },
  '49756': {
    zip: '49756',
    city: 'Lewiston',
    state: 'MI',
    latitude: '44.807644',
    longitude: '-84.273894',
    timeZoneId: 'America/New_York',
  },
  '49757': {
    zip: '49757',
    city: 'Mackinac Island',
    state: 'MI',
    latitude: '45.859701',
    longitude: '-84.615503',
    timeZoneId: 'America/New_York',
  },
  '49759': {
    zip: '49759',
    city: 'Millersburg',
    state: 'MI',
    latitude: '45.436961',
    longitude: '-84.100677',
    timeZoneId: 'America/New_York',
  },
  '49760': {
    zip: '49760',
    city: 'Moran',
    state: 'MI',
    latitude: '46.043353',
    longitude: '-84.973791',
    timeZoneId: 'America/New_York',
  },
  '49761': {
    zip: '49761',
    city: 'Mullett Lake',
    state: 'MI',
    latitude: '45.560825',
    longitude: '-84.523849',
    timeZoneId: 'America/New_York',
  },
  '49762': {
    zip: '49762',
    city: 'Naubinway',
    state: 'MI',
    latitude: '46.147284',
    longitude: '-85.40321',
    timeZoneId: 'America/New_York',
  },
  '49764': {
    zip: '49764',
    city: 'Oden',
    state: 'MI',
    latitude: '45.423794',
    longitude: '-84.82364',
    timeZoneId: 'America/New_York',
  },
  '49765': {
    zip: '49765',
    city: 'Onaway',
    state: 'MI',
    latitude: '45.372543',
    longitude: '-84.208577',
    timeZoneId: 'America/New_York',
  },
  '49766': {
    zip: '49766',
    city: 'Ossineke',
    state: 'MI',
    latitude: '44.921005',
    longitude: '-83.461892',
    timeZoneId: 'America/New_York',
  },
  '49768': {
    zip: '49768',
    city: 'Paradise',
    state: 'MI',
    latitude: '46.592184',
    longitude: '-85.129207',
    timeZoneId: 'America/New_York',
  },
  '49769': {
    zip: '49769',
    city: 'Pellston',
    state: 'MI',
    latitude: '45.568594',
    longitude: '-84.87465',
    timeZoneId: 'America/New_York',
  },
  '49770': {
    zip: '49770',
    city: 'Petoskey',
    state: 'MI',
    latitude: '45.356561',
    longitude: '-84.930304',
    timeZoneId: 'America/New_York',
  },
  '49774': {
    zip: '49774',
    city: 'Pickford',
    state: 'MI',
    latitude: '46.168679',
    longitude: '-84.181686',
    timeZoneId: 'America/New_York',
  },
  '49775': {
    zip: '49775',
    city: 'Pointe Aux Pins',
    state: 'MI',
    latitude: '45.764363',
    longitude: '-84.467304',
    timeZoneId: 'America/New_York',
  },
  '49776': {
    zip: '49776',
    city: 'Posen',
    state: 'MI',
    latitude: '45.259026',
    longitude: '-83.687804',
    timeZoneId: 'America/New_York',
  },
  '49777': {
    zip: '49777',
    city: 'Presque Isle',
    state: 'MI',
    latitude: '45.323506',
    longitude: '-83.488814',
    timeZoneId: 'America/New_York',
  },
  '49779': {
    zip: '49779',
    city: 'Rogers City',
    state: 'MI',
    latitude: '45.416207',
    longitude: '-83.85762',
    timeZoneId: 'America/New_York',
  },
  '49780': {
    zip: '49780',
    city: 'Rudyard',
    state: 'MI',
    latitude: '46.18518',
    longitude: '-84.48372',
    timeZoneId: 'America/New_York',
  },
  '49781': {
    zip: '49781',
    city: 'Saint Ignace',
    state: 'MI',
    latitude: '45.879306',
    longitude: '-84.731798',
    timeZoneId: 'America/New_York',
  },
  '49782': {
    zip: '49782',
    city: 'Beaver Island',
    state: 'MI',
    latitude: '45.666667',
    longitude: '-85.55',
    timeZoneId: 'America/New_York',
  },
  '49783': {
    zip: '49783',
    city: 'Sault Sainte Marie',
    state: 'MI',
    latitude: '46.478524',
    longitude: '-84.355204',
    timeZoneId: 'America/New_York',
  },
  '49784': {
    zip: '49784',
    city: 'Kincheloe',
    state: 'MI',
    latitude: '46.258915',
    longitude: '-84.381415',
    timeZoneId: 'America/New_York',
  },
  '49785': {
    zip: '49785',
    city: 'Kincheloe',
    state: 'MI',
    latitude: '46.26458',
    longitude: '-84.44927',
    timeZoneId: 'America/New_York',
  },
  '49786': {
    zip: '49786',
    city: 'Kincheloe',
    state: 'MI',
    latitude: '46.266667',
    longitude: '-84.483333',
    timeZoneId: 'America/New_York',
  },
  '49788': {
    zip: '49788',
    city: 'Kincheloe',
    state: 'MI',
    latitude: '46.495278',
    longitude: '-84.345278',
    timeZoneId: 'America/New_York',
  },
  '49791': {
    zip: '49791',
    city: 'Topinabee',
    state: 'MI',
    latitude: '45.485724',
    longitude: '-84.596255',
    timeZoneId: 'America/New_York',
  },
  '49792': {
    zip: '49792',
    city: 'Tower',
    state: 'MI',
    latitude: '45.36698',
    longitude: '-84.306157',
    timeZoneId: 'America/New_York',
  },
  '49793': {
    zip: '49793',
    city: 'Trout Lake',
    state: 'MI',
    latitude: '46.193435',
    longitude: '-85.015947',
    timeZoneId: 'America/New_York',
  },
  '49795': {
    zip: '49795',
    city: 'Vanderbilt',
    state: 'MI',
    latitude: '45.144939',
    longitude: '-84.626232',
    timeZoneId: 'America/New_York',
  },
  '49796': {
    zip: '49796',
    city: 'Walloon Lake',
    state: 'MI',
    latitude: '45.264304',
    longitude: '-84.933235',
    timeZoneId: 'America/New_York',
  },
  '49797': {
    zip: '49797',
    city: 'Waters',
    state: 'MI',
    latitude: '44.86827',
    longitude: '-84.67004',
    timeZoneId: 'America/New_York',
  },
  '49799': {
    zip: '49799',
    city: 'Wolverine',
    state: 'MI',
    latitude: '45.267783',
    longitude: '-84.588712',
    timeZoneId: 'America/New_York',
  },
  '49801': {
    zip: '49801',
    city: 'Iron Mountain',
    state: 'MI',
    latitude: '45.81787',
    longitude: '-88.066576',
    timeZoneId: 'America/Chicago',
  },
  '49802': {
    zip: '49802',
    city: 'Kingsford',
    state: 'MI',
    latitude: '45.818797',
    longitude: '-88.067829',
    timeZoneId: 'America/Chicago',
  },
  '49805': {
    zip: '49805',
    city: 'Allouez',
    state: 'MI',
    latitude: '47.284989',
    longitude: '-88.408322',
    timeZoneId: 'America/New_York',
  },
  '49806': {
    zip: '49806',
    city: 'Au Train',
    state: 'MI',
    latitude: '46.430403',
    longitude: '-86.838416',
    timeZoneId: 'America/New_York',
  },
  '49807': {
    zip: '49807',
    city: 'Bark River',
    state: 'MI',
    latitude: '45.6984',
    longitude: '-87.23598',
    timeZoneId: 'America/New_York',
  },
  '49808': {
    zip: '49808',
    city: 'Big Bay',
    state: 'MI',
    latitude: '46.815765',
    longitude: '-87.728475',
    timeZoneId: 'America/New_York',
  },
  '49812': {
    zip: '49812',
    city: 'Carney',
    state: 'MI',
    latitude: '45.581636',
    longitude: '-87.559163',
    timeZoneId: 'America/Chicago',
  },
  '49814': {
    zip: '49814',
    city: 'Champion',
    state: 'MI',
    latitude: '46.50925',
    longitude: '-87.838101',
    timeZoneId: 'America/New_York',
  },
  '49815': {
    zip: '49815',
    city: 'Channing',
    state: 'MI',
    latitude: '46.157367',
    longitude: '-88.073571',
    timeZoneId: 'America/Chicago',
  },
  '49816': {
    zip: '49816',
    city: 'Chatham',
    state: 'MI',
    latitude: '46.28849',
    longitude: '-86.760174',
    timeZoneId: 'America/New_York',
  },
  '49817': {
    zip: '49817',
    city: 'Cooks',
    state: 'MI',
    latitude: '45.910863',
    longitude: '-86.524784',
    timeZoneId: 'America/New_York',
  },
  '49818': {
    zip: '49818',
    city: 'Cornell',
    state: 'MI',
    latitude: '45.909059',
    longitude: '-87.2007',
    timeZoneId: 'America/New_York',
  },
  '49819': {
    zip: '49819',
    city: 'Arnold',
    state: 'MI',
    latitude: '46.049895',
    longitude: '-87.492966',
    timeZoneId: 'America/New_York',
  },
  '49820': {
    zip: '49820',
    city: 'Curtis',
    state: 'MI',
    latitude: '46.198098',
    longitude: '-85.820506',
    timeZoneId: 'America/New_York',
  },
  '49821': {
    zip: '49821',
    city: 'Daggett',
    state: 'MI',
    latitude: '45.452521',
    longitude: '-87.594739',
    timeZoneId: 'America/Chicago',
  },
  '49822': {
    zip: '49822',
    city: 'Deerton',
    state: 'MI',
    latitude: '46.449225',
    longitude: '-87.006293',
    timeZoneId: 'America/New_York',
  },
  '49825': {
    zip: '49825',
    city: 'Eben Junction',
    state: 'MI',
    latitude: '46.35342',
    longitude: '-87.033521',
    timeZoneId: 'America/New_York',
  },
  '49826': {
    zip: '49826',
    city: 'Rumely',
    state: 'MI',
    latitude: '46.34256',
    longitude: '-86.920968',
    timeZoneId: 'America/New_York',
  },
  '49827': {
    zip: '49827',
    city: 'Engadine',
    state: 'MI',
    latitude: '46.208679',
    longitude: '-85.595465',
    timeZoneId: 'America/New_York',
  },
  '49829': {
    zip: '49829',
    city: 'Escanaba',
    state: 'MI',
    latitude: '45.749678',
    longitude: '-87.096833',
    timeZoneId: 'America/New_York',
  },
  '49831': {
    zip: '49831',
    city: 'Felch',
    state: 'MI',
    latitude: '45.998379',
    longitude: '-87.826892',
    timeZoneId: 'America/Chicago',
  },
  '49833': {
    zip: '49833',
    city: 'Little Lake',
    state: 'MI',
    latitude: '46.286567',
    longitude: '-87.333115',
    timeZoneId: 'America/New_York',
  },
  '49834': {
    zip: '49834',
    city: 'Foster City',
    state: 'MI',
    latitude: '46.083693',
    longitude: '-87.778192',
    timeZoneId: 'America/Chicago',
  },
  '49835': {
    zip: '49835',
    city: 'Garden',
    state: 'MI',
    latitude: '45.736897',
    longitude: '-86.591583',
    timeZoneId: 'America/New_York',
  },
  '49836': {
    zip: '49836',
    city: 'Germfask',
    state: 'MI',
    latitude: '46.243692',
    longitude: '-85.928722',
    timeZoneId: 'America/New_York',
  },
  '49837': {
    zip: '49837',
    city: 'Gladstone',
    state: 'MI',
    latitude: '45.88014',
    longitude: '-87.04758',
    timeZoneId: 'America/New_York',
  },
  '49838': {
    zip: '49838',
    city: 'Gould City',
    state: 'MI',
    latitude: '46.069365',
    longitude: '-85.732722',
    timeZoneId: 'America/New_York',
  },
  '49839': {
    zip: '49839',
    city: 'Grand Marais',
    state: 'MI',
    latitude: '46.588717',
    longitude: '-86.046862',
    timeZoneId: 'America/New_York',
  },
  '49840': {
    zip: '49840',
    city: 'Gulliver',
    state: 'MI',
    latitude: '46.11364',
    longitude: '-86.008147',
    timeZoneId: 'America/New_York',
  },
  '49841': {
    zip: '49841',
    city: 'Gwinn',
    state: 'MI',
    latitude: '46.32384',
    longitude: '-87.48036',
    timeZoneId: 'America/New_York',
  },
  '49845': {
    zip: '49845',
    city: 'Harris',
    state: 'MI',
    latitude: '45.702908',
    longitude: '-87.347276',
    timeZoneId: 'America/Chicago',
  },
  '49847': {
    zip: '49847',
    city: 'Hermansville',
    state: 'MI',
    latitude: '45.75206',
    longitude: '-87.64229',
    timeZoneId: 'America/Chicago',
  },
  '49848': {
    zip: '49848',
    city: 'Ingalls',
    state: 'MI',
    latitude: '45.379343',
    longitude: '-87.613529',
    timeZoneId: 'America/Chicago',
  },
  '49849': {
    zip: '49849',
    city: 'Ishpeming',
    state: 'MI',
    latitude: '46.4952',
    longitude: '-87.72558',
    timeZoneId: 'America/New_York',
  },
  '49852': {
    zip: '49852',
    city: 'Loretto',
    state: 'MI',
    latitude: '45.781469',
    longitude: '-87.816698',
    timeZoneId: 'America/Chicago',
  },
  '49853': {
    zip: '49853',
    city: 'Mc Millan',
    state: 'MI',
    latitude: '46.371164',
    longitude: '-85.738692',
    timeZoneId: 'America/New_York',
  },
  '49854': {
    zip: '49854',
    city: 'Manistique',
    state: 'MI',
    latitude: '46.00974',
    longitude: '-86.2788',
    timeZoneId: 'America/New_York',
  },
  '49855': {
    zip: '49855',
    city: 'Marquette',
    state: 'MI',
    latitude: '46.55',
    longitude: '-87.4',
    timeZoneId: 'America/New_York',
  },
  '49858': {
    zip: '49858',
    city: 'Menominee',
    state: 'MI',
    latitude: '45.123103',
    longitude: '-87.615268',
    timeZoneId: 'America/Chicago',
  },
  '49861': {
    zip: '49861',
    city: 'Michigamme',
    state: 'MI',
    latitude: '46.531489',
    longitude: '-88.086335',
    timeZoneId: 'America/New_York',
  },
  '49862': {
    zip: '49862',
    city: 'Munising',
    state: 'MI',
    latitude: '46.41642',
    longitude: '-86.62398',
    timeZoneId: 'America/New_York',
  },
  '49863': {
    zip: '49863',
    city: 'Nadeau',
    state: 'MI',
    latitude: '45.607239',
    longitude: '-87.549156',
    timeZoneId: 'America/Chicago',
  },
  '49864': {
    zip: '49864',
    city: 'Nahma',
    state: 'MI',
    latitude: '45.841551',
    longitude: '-86.664264',
    timeZoneId: 'America/New_York',
  },
  '49865': {
    zip: '49865',
    city: 'National Mine',
    state: 'MI',
    latitude: '46.457683',
    longitude: '-87.681834',
    timeZoneId: 'America/New_York',
  },
  '49866': {
    zip: '49866',
    city: 'Negaunee',
    state: 'MI',
    latitude: '46.513209',
    longitude: '-87.590554',
    timeZoneId: 'America/New_York',
  },
  '49868': {
    zip: '49868',
    city: 'Newberry',
    state: 'MI',
    latitude: '46.344668',
    longitude: '-85.508112',
    timeZoneId: 'America/New_York',
  },
  '49870': {
    zip: '49870',
    city: 'Norway',
    state: 'MI',
    latitude: '45.785403',
    longitude: '-87.901924',
    timeZoneId: 'America/Chicago',
  },
  '49871': {
    zip: '49871',
    city: 'Palmer',
    state: 'MI',
    latitude: '46.440099',
    longitude: '-87.590135',
    timeZoneId: 'America/New_York',
  },
  '49872': {
    zip: '49872',
    city: 'Perkins',
    state: 'MI',
    latitude: '45.997357',
    longitude: '-87.092569',
    timeZoneId: 'America/New_York',
  },
  '49873': {
    zip: '49873',
    city: 'Perronville',
    state: 'MI',
    latitude: '45.873016',
    longitude: '-87.498701',
    timeZoneId: 'America/Chicago',
  },
  '49874': {
    zip: '49874',
    city: 'Powers',
    state: 'MI',
    latitude: '45.690289',
    longitude: '-87.499966',
    timeZoneId: 'America/Chicago',
  },
  '49876': {
    zip: '49876',
    city: 'Quinnesec',
    state: 'MI',
    latitude: '45.803607',
    longitude: '-87.991164',
    timeZoneId: 'America/Chicago',
  },
  '49877': {
    zip: '49877',
    city: 'Ralph',
    state: 'MI',
    latitude: '46.108498',
    longitude: '-87.784388',
    timeZoneId: 'America/Chicago',
  },
  '49878': {
    zip: '49878',
    city: 'Rapid River',
    state: 'MI',
    latitude: '45.897736',
    longitude: '-86.899651',
    timeZoneId: 'America/New_York',
  },
  '49879': {
    zip: '49879',
    city: 'Republic',
    state: 'MI',
    latitude: '46.278878',
    longitude: '-88.137022',
    timeZoneId: 'America/Chicago',
  },
  '49880': {
    zip: '49880',
    city: 'Rock',
    state: 'MI',
    latitude: '46.038161',
    longitude: '-87.175792',
    timeZoneId: 'America/New_York',
  },
  '49881': {
    zip: '49881',
    city: 'Sagola',
    state: 'MI',
    latitude: '46.068868',
    longitude: '-88.064656',
    timeZoneId: 'America/Chicago',
  },
  '49883': {
    zip: '49883',
    city: 'Seney',
    state: 'MI',
    latitude: '46.420521',
    longitude: '-86.051082',
    timeZoneId: 'America/New_York',
  },
  '49884': {
    zip: '49884',
    city: 'Shingleton',
    state: 'MI',
    latitude: '46.355034',
    longitude: '-86.452403',
    timeZoneId: 'America/New_York',
  },
  '49885': {
    zip: '49885',
    city: 'Skandia',
    state: 'MI',
    latitude: '46.34976',
    longitude: '-87.251184',
    timeZoneId: 'America/New_York',
  },
  '49886': {
    zip: '49886',
    city: 'Spalding',
    state: 'MI',
    latitude: '45.697002',
    longitude: '-87.504571',
    timeZoneId: 'America/Chicago',
  },
  '49887': {
    zip: '49887',
    city: 'Stephenson',
    state: 'MI',
    latitude: '45.414673',
    longitude: '-87.615414',
    timeZoneId: 'America/Chicago',
  },
  '49891': {
    zip: '49891',
    city: 'Trenary',
    state: 'MI',
    latitude: '46.202796',
    longitude: '-86.866256',
    timeZoneId: 'America/New_York',
  },
  '49892': {
    zip: '49892',
    city: 'Vulcan',
    state: 'MI',
    latitude: '45.783077',
    longitude: '-87.869923',
    timeZoneId: 'America/Chicago',
  },
  '49893': {
    zip: '49893',
    city: 'Wallace',
    state: 'MI',
    latitude: '45.334797',
    longitude: '-87.609864',
    timeZoneId: 'America/Chicago',
  },
  '49894': {
    zip: '49894',
    city: 'Wells',
    state: 'MI',
    latitude: '45.778408',
    longitude: '-87.074986',
    timeZoneId: 'America/New_York',
  },
  '49895': {
    zip: '49895',
    city: 'Wetmore',
    state: 'MI',
    latitude: '46.374729',
    longitude: '-86.618286',
    timeZoneId: 'America/New_York',
  },
  '49896': {
    zip: '49896',
    city: 'Wilson',
    state: 'MI',
    latitude: '45.657788',
    longitude: '-87.39758',
    timeZoneId: 'America/Chicago',
  },
  '49901': {
    zip: '49901',
    city: 'Ahmeek',
    state: 'MI',
    latitude: '47.29541',
    longitude: '-88.395923',
    timeZoneId: 'America/New_York',
  },
  '49902': {
    zip: '49902',
    city: 'Alpha',
    state: 'MI',
    latitude: '46.01707',
    longitude: '-88.30499',
    timeZoneId: 'America/Chicago',
  },
  '49903': {
    zip: '49903',
    city: 'Amasa',
    state: 'MI',
    latitude: '46.237996',
    longitude: '-88.44115',
    timeZoneId: 'America/Chicago',
  },
  '49905': {
    zip: '49905',
    city: 'Atlantic Mine',
    state: 'MI',
    latitude: '47.066379',
    longitude: '-88.652963',
    timeZoneId: 'America/New_York',
  },
  '49908': {
    zip: '49908',
    city: 'Baraga',
    state: 'MI',
    latitude: '46.78428',
    longitude: '-88.49364',
    timeZoneId: 'America/New_York',
  },
  '49910': {
    zip: '49910',
    city: 'Bergland',
    state: 'MI',
    latitude: '46.591148',
    longitude: '-89.566364',
    timeZoneId: 'America/New_York',
  },
  '49911': {
    zip: '49911',
    city: 'Bessemer',
    state: 'MI',
    latitude: '46.476713',
    longitude: '-90.052014',
    timeZoneId: 'America/Chicago',
  },
  '49912': {
    zip: '49912',
    city: 'Bruce Crossing',
    state: 'MI',
    latitude: '46.538683',
    longitude: '-89.202693',
    timeZoneId: 'America/New_York',
  },
  '49913': {
    zip: '49913',
    city: 'Calumet',
    state: 'MI',
    latitude: '47.241904',
    longitude: '-88.450576',
    timeZoneId: 'America/New_York',
  },
  '49915': {
    zip: '49915',
    city: 'Caspian',
    state: 'MI',
    latitude: '46.066795',
    longitude: '-88.627714',
    timeZoneId: 'America/Chicago',
  },
  '49916': {
    zip: '49916',
    city: 'Chassell',
    state: 'MI',
    latitude: '47.026576',
    longitude: '-88.527737',
    timeZoneId: 'America/New_York',
  },
  '49917': {
    zip: '49917',
    city: 'Copper City',
    state: 'MI',
    latitude: '47.284178',
    longitude: '-88.385855',
    timeZoneId: 'America/New_York',
  },
  '49918': {
    zip: '49918',
    city: 'Copper Harbor',
    state: 'MI',
    latitude: '47.470095',
    longitude: '-87.887734',
    timeZoneId: 'America/New_York',
  },
  '49919': {
    zip: '49919',
    city: 'Covington',
    state: 'MI',
    latitude: '46.548312',
    longitude: '-88.513722',
    timeZoneId: 'America/New_York',
  },
  '49920': {
    zip: '49920',
    city: 'Crystal Falls',
    state: 'MI',
    latitude: '46.104755',
    longitude: '-88.349027',
    timeZoneId: 'America/Chicago',
  },
  '49921': {
    zip: '49921',
    city: 'Dodgeville',
    state: 'MI',
    latitude: '47.090392',
    longitude: '-88.580984',
    timeZoneId: 'America/New_York',
  },
  '49922': {
    zip: '49922',
    city: 'Dollar Bay',
    state: 'MI',
    latitude: '47.12119',
    longitude: '-88.503527',
    timeZoneId: 'America/New_York',
  },
  '49925': {
    zip: '49925',
    city: 'Ewen',
    state: 'MI',
    latitude: '46.539412',
    longitude: '-89.381658',
    timeZoneId: 'America/New_York',
  },
  '49927': {
    zip: '49927',
    city: 'Gaastra',
    state: 'MI',
    latitude: '46.051469',
    longitude: '-88.58901',
    timeZoneId: 'America/Chicago',
  },
  '49929': {
    zip: '49929',
    city: 'Greenland',
    state: 'MI',
    latitude: '46.782554',
    longitude: '-89.095113',
    timeZoneId: 'America/New_York',
  },
  '49930': {
    zip: '49930',
    city: 'Hancock',
    state: 'MI',
    latitude: '47.1447',
    longitude: '-88.52676',
    timeZoneId: 'America/New_York',
  },
  '49931': {
    zip: '49931',
    city: 'Houghton',
    state: 'MI',
    latitude: '47.115232',
    longitude: '-88.563407',
    timeZoneId: 'America/New_York',
  },
  '49934': {
    zip: '49934',
    city: 'Hubbell',
    state: 'MI',
    latitude: '47.175317',
    longitude: '-88.430414',
    timeZoneId: 'America/New_York',
  },
  '49935': {
    zip: '49935',
    city: 'Iron River',
    state: 'MI',
    latitude: '46.10684',
    longitude: '-88.657064',
    timeZoneId: 'America/Chicago',
  },
  '49938': {
    zip: '49938',
    city: 'Ironwood',
    state: 'MI',
    latitude: '46.456602',
    longitude: '-90.156472',
    timeZoneId: 'America/Chicago',
  },
  '49942': {
    zip: '49942',
    city: 'Kearsarge',
    state: 'MI',
    latitude: '47.269521',
    longitude: '-88.416753',
    timeZoneId: 'America/New_York',
  },
  '49945': {
    zip: '49945',
    city: 'Lake Linden',
    state: 'MI',
    latitude: '47.17482',
    longitude: '-88.41078',
    timeZoneId: 'America/New_York',
  },
  '49946': {
    zip: '49946',
    city: 'Lanse',
    state: 'MI',
    latitude: '46.75974',
    longitude: '-88.40934',
    timeZoneId: 'America/New_York',
  },
  '49947': {
    zip: '49947',
    city: 'Marenisco',
    state: 'MI',
    latitude: '46.3758',
    longitude: '-89.59398',
    timeZoneId: 'America/Chicago',
  },
  '49948': {
    zip: '49948',
    city: 'Mass City',
    state: 'MI',
    latitude: '46.725602',
    longitude: '-89.032124',
    timeZoneId: 'America/New_York',
  },
  '49950': {
    zip: '49950',
    city: 'Mohawk',
    state: 'MI',
    latitude: '47.316535',
    longitude: '-88.344835',
    timeZoneId: 'America/New_York',
  },
  '49952': {
    zip: '49952',
    city: 'Nisula',
    state: 'MI',
    latitude: '46.633823',
    longitude: '-88.880318',
    timeZoneId: 'America/New_York',
  },
  '49953': {
    zip: '49953',
    city: 'Ontonagon',
    state: 'MI',
    latitude: '46.855606',
    longitude: '-89.315817',
    timeZoneId: 'America/New_York',
  },
  '49955': {
    zip: '49955',
    city: 'Painesdale',
    state: 'MI',
    latitude: '47.043157',
    longitude: '-88.671488',
    timeZoneId: 'America/New_York',
  },
  '49958': {
    zip: '49958',
    city: 'Pelkie',
    state: 'MI',
    latitude: '46.729644',
    longitude: '-88.643973',
    timeZoneId: 'America/New_York',
  },
  '49959': {
    zip: '49959',
    city: 'Ramsay',
    state: 'MI',
    latitude: '46.478413',
    longitude: '-89.991743',
    timeZoneId: 'America/Chicago',
  },
  '49960': {
    zip: '49960',
    city: 'Rockland',
    state: 'MI',
    latitude: '46.742283',
    longitude: '-89.183486',
    timeZoneId: 'America/New_York',
  },
  '49961': {
    zip: '49961',
    city: 'Sidnaw',
    state: 'MI',
    latitude: '46.505314',
    longitude: '-88.73136',
    timeZoneId: 'America/New_York',
  },
  '49962': {
    zip: '49962',
    city: 'Skanee',
    state: 'MI',
    latitude: '46.871363',
    longitude: '-88.163796',
    timeZoneId: 'America/New_York',
  },
  '49963': {
    zip: '49963',
    city: 'South Range',
    state: 'MI',
    latitude: '47.067439',
    longitude: '-88.642845',
    timeZoneId: 'America/New_York',
  },
  '49964': {
    zip: '49964',
    city: 'Stambaugh',
    state: 'MI',
    latitude: '46.081481',
    longitude: '-88.629835',
    timeZoneId: 'America/Chicago',
  },
  '49965': {
    zip: '49965',
    city: 'Toivola',
    state: 'MI',
    latitude: '46.939867',
    longitude: '-88.853406',
    timeZoneId: 'America/New_York',
  },
  '49967': {
    zip: '49967',
    city: 'Trout Creek',
    state: 'MI',
    latitude: '46.476821',
    longitude: '-89.007955',
    timeZoneId: 'America/New_York',
  },
  '49968': {
    zip: '49968',
    city: 'Wakefield',
    state: 'MI',
    latitude: '46.478287',
    longitude: '-89.936616',
    timeZoneId: 'America/Chicago',
  },
  '49969': {
    zip: '49969',
    city: 'Watersmeet',
    state: 'MI',
    latitude: '46.268905',
    longitude: '-89.174316',
    timeZoneId: 'America/Chicago',
  },
  '49970': {
    zip: '49970',
    city: 'Watton',
    state: 'MI',
    latitude: '46.502349',
    longitude: '-88.620275',
    timeZoneId: 'America/New_York',
  },
  '49971': {
    zip: '49971',
    city: 'White Pine',
    state: 'MI',
    latitude: '46.757263',
    longitude: '-89.581095',
    timeZoneId: 'America/New_York',
  },
  '50001': {
    zip: '50001',
    city: 'Ackworth',
    state: 'IA',
    latitude: '41.3814',
    longitude: '-93.39876',
    timeZoneId: 'America/Chicago',
  },
  '50002': {
    zip: '50002',
    city: 'Adair',
    state: 'IA',
    latitude: '41.499324',
    longitude: '-94.647499',
    timeZoneId: 'America/Chicago',
  },
  '50003': {
    zip: '50003',
    city: 'Adel',
    state: 'IA',
    latitude: '41.614486',
    longitude: '-94.020176',
    timeZoneId: 'America/Chicago',
  },
  '50005': {
    zip: '50005',
    city: 'Albion',
    state: 'IA',
    latitude: '42.121073',
    longitude: '-93.01318',
    timeZoneId: 'America/Chicago',
  },
  '50006': {
    zip: '50006',
    city: 'Alden',
    state: 'IA',
    latitude: '42.518788',
    longitude: '-93.37217',
    timeZoneId: 'America/Chicago',
  },
  '50007': {
    zip: '50007',
    city: 'Alleman',
    state: 'IA',
    latitude: '41.81783',
    longitude: '-93.610934',
    timeZoneId: 'America/Chicago',
  },
  '50008': {
    zip: '50008',
    city: 'Allerton',
    state: 'IA',
    latitude: '40.674358',
    longitude: '-93.382116',
    timeZoneId: 'America/Chicago',
  },
  '50009': {
    zip: '50009',
    city: 'Altoona',
    state: 'IA',
    latitude: '41.649151',
    longitude: '-93.469244',
    timeZoneId: 'America/Chicago',
  },
  '50010': {
    zip: '50010',
    city: 'Ames',
    state: 'IA',
    latitude: '42.029583',
    longitude: '-93.640161',
    timeZoneId: 'America/Chicago',
  },
  '50011': {
    zip: '50011',
    city: 'Ames',
    state: 'IA',
    latitude: '42.022225',
    longitude: '-93.639475',
    timeZoneId: 'America/Chicago',
  },
  '50012': {
    zip: '50012',
    city: 'Ames',
    state: 'IA',
    latitude: '42.023294',
    longitude: '-93.647478',
    timeZoneId: 'America/Chicago',
  },
  '50013': {
    zip: '50013',
    city: 'Ames',
    state: 'IA',
    latitude: '42.019665',
    longitude: '-93.645568',
    timeZoneId: 'America/Chicago',
  },
  '50014': {
    zip: '50014',
    city: 'Ames',
    state: 'IA',
    latitude: '42.023745',
    longitude: '-93.662894',
    timeZoneId: 'America/Chicago',
  },
  '50020': {
    zip: '50020',
    city: 'Anita',
    state: 'IA',
    latitude: '41.4441',
    longitude: '-94.765161',
    timeZoneId: 'America/Chicago',
  },
  '50021': {
    zip: '50021',
    city: 'Ankeny',
    state: 'IA',
    latitude: '41.724091',
    longitude: '-93.60968',
    timeZoneId: 'America/Chicago',
  },
  '50022': {
    zip: '50022',
    city: 'Atlantic',
    state: 'IA',
    latitude: '41.400014',
    longitude: '-95.009466',
    timeZoneId: 'America/Chicago',
  },
  '50023': {
    zip: '50023',
    city: 'Ankeny',
    state: 'IA',
    latitude: '41.7196',
    longitude: '-93.6227',
    timeZoneId: 'America/Chicago',
  },
  '50025': {
    zip: '50025',
    city: 'Audubon',
    state: 'IA',
    latitude: '41.7261',
    longitude: '-94.91856',
    timeZoneId: 'America/Chicago',
  },
  '50026': {
    zip: '50026',
    city: 'Bagley',
    state: 'IA',
    latitude: '41.819718',
    longitude: '-94.456171',
    timeZoneId: 'America/Chicago',
  },
  '50027': {
    zip: '50027',
    city: 'Barnes City',
    state: 'IA',
    latitude: '41.507587',
    longitude: '-92.473535',
    timeZoneId: 'America/Chicago',
  },
  '50028': {
    zip: '50028',
    city: 'Baxter',
    state: 'IA',
    latitude: '41.820316',
    longitude: '-93.148265',
    timeZoneId: 'America/Chicago',
  },
  '50029': {
    zip: '50029',
    city: 'Bayard',
    state: 'IA',
    latitude: '41.853153',
    longitude: '-94.558798',
    timeZoneId: 'America/Chicago',
  },
  '50031': {
    zip: '50031',
    city: 'Beaver',
    state: 'IA',
    latitude: '42.036895',
    longitude: '-94.105895',
    timeZoneId: 'America/Chicago',
  },
  '50032': {
    zip: '50032',
    city: 'Berwick',
    state: 'IA',
    latitude: '41.666398',
    longitude: '-93.544619',
    timeZoneId: 'America/Chicago',
  },
  '50033': {
    zip: '50033',
    city: 'Bevington',
    state: 'IA',
    latitude: '41.359363',
    longitude: '-93.792209',
    timeZoneId: 'America/Chicago',
  },
  '50034': {
    zip: '50034',
    city: 'Blairsburg',
    state: 'IA',
    latitude: '42.480643',
    longitude: '-93.64305',
    timeZoneId: 'America/Chicago',
  },
  '50035': {
    zip: '50035',
    city: 'Bondurant',
    state: 'IA',
    latitude: '41.724657',
    longitude: '-93.447145',
    timeZoneId: 'America/Chicago',
  },
  '50036': {
    zip: '50036',
    city: 'Boone',
    state: 'IA',
    latitude: '42.061308',
    longitude: '-93.887441',
    timeZoneId: 'America/Chicago',
  },
  '50037': {
    zip: '50037',
    city: 'Boone',
    state: 'IA',
    latitude: '42.056176',
    longitude: '-93.880757',
    timeZoneId: 'America/Chicago',
  },
  '50038': {
    zip: '50038',
    city: 'Booneville',
    state: 'IA',
    latitude: '41.539788',
    longitude: '-93.885271',
    timeZoneId: 'America/Chicago',
  },
  '50039': {
    zip: '50039',
    city: 'Bouton',
    state: 'IA',
    latitude: '41.852588',
    longitude: '-94.006979',
    timeZoneId: 'America/Chicago',
  },
  '50040': {
    zip: '50040',
    city: 'Boxholm',
    state: 'IA',
    latitude: '42.166492',
    longitude: '-94.106704',
    timeZoneId: 'America/Chicago',
  },
  '50041': {
    zip: '50041',
    city: 'Bradford',
    state: 'IA',
    latitude: '42.601229',
    longitude: '-93.204334',
    timeZoneId: 'America/Chicago',
  },
  '50042': {
    zip: '50042',
    city: 'Brayton',
    state: 'IA',
    latitude: '41.553383',
    longitude: '-94.983495',
    timeZoneId: 'America/Chicago',
  },
  '50043': {
    zip: '50043',
    city: 'Buckeye',
    state: 'IA',
    latitude: '42.520278',
    longitude: '-93.375833',
    timeZoneId: 'America/Chicago',
  },
  '50044': {
    zip: '50044',
    city: 'Bussey',
    state: 'IA',
    latitude: '41.204439',
    longitude: '-92.884174',
    timeZoneId: 'America/Chicago',
  },
  '50046': {
    zip: '50046',
    city: 'Cambridge',
    state: 'IA',
    latitude: '41.901318',
    longitude: '-93.53275',
    timeZoneId: 'America/Chicago',
  },
  '50047': {
    zip: '50047',
    city: 'Carlisle',
    state: 'IA',
    latitude: '41.477985',
    longitude: '-93.476846',
    timeZoneId: 'America/Chicago',
  },
  '50048': {
    zip: '50048',
    city: 'Casey',
    state: 'IA',
    latitude: '41.488238',
    longitude: '-94.43065',
    timeZoneId: 'America/Chicago',
  },
  '50049': {
    zip: '50049',
    city: 'Chariton',
    state: 'IA',
    latitude: '41.018686',
    longitude: '-93.311392',
    timeZoneId: 'America/Chicago',
  },
  '50050': {
    zip: '50050',
    city: 'Churdan',
    state: 'IA',
    latitude: '42.130801',
    longitude: '-94.461946',
    timeZoneId: 'America/Chicago',
  },
  '50051': {
    zip: '50051',
    city: 'Clemons',
    state: 'IA',
    latitude: '42.131461',
    longitude: '-93.145031',
    timeZoneId: 'America/Chicago',
  },
  '50052': {
    zip: '50052',
    city: 'Clio',
    state: 'IA',
    latitude: '40.68008',
    longitude: '-93.498964',
    timeZoneId: 'America/Chicago',
  },
  '50054': {
    zip: '50054',
    city: 'Colfax',
    state: 'IA',
    latitude: '41.6814',
    longitude: '-93.2679',
    timeZoneId: 'America/Chicago',
  },
  '50055': {
    zip: '50055',
    city: 'Collins',
    state: 'IA',
    latitude: '41.903253',
    longitude: '-93.306942',
    timeZoneId: 'America/Chicago',
  },
  '50056': {
    zip: '50056',
    city: 'Colo',
    state: 'IA',
    latitude: '42.017715',
    longitude: '-93.315659',
    timeZoneId: 'America/Chicago',
  },
  '50057': {
    zip: '50057',
    city: 'Columbia',
    state: 'IA',
    latitude: '41.182401',
    longitude: '-93.157392',
    timeZoneId: 'America/Chicago',
  },
  '50058': {
    zip: '50058',
    city: 'Coon Rapids',
    state: 'IA',
    latitude: '41.877941',
    longitude: '-94.680284',
    timeZoneId: 'America/Chicago',
  },
  '50059': {
    zip: '50059',
    city: 'Cooper',
    state: 'IA',
    latitude: '41.899565',
    longitude: '-94.339514',
    timeZoneId: 'America/Chicago',
  },
  '50060': {
    zip: '50060',
    city: 'Corydon',
    state: 'IA',
    latitude: '40.758022',
    longitude: '-93.318743',
    timeZoneId: 'America/Chicago',
  },
  '50061': {
    zip: '50061',
    city: 'Cumming',
    state: 'IA',
    latitude: '41.489268',
    longitude: '-93.75386',
    timeZoneId: 'America/Chicago',
  },
  '50062': {
    zip: '50062',
    city: 'Melcher Dallas',
    state: 'IA',
    latitude: '41.232661',
    longitude: '-93.237493',
    timeZoneId: 'America/Chicago',
  },
  '50063': {
    zip: '50063',
    city: 'Dallas Center',
    state: 'IA',
    latitude: '41.690242',
    longitude: '-93.967705',
    timeZoneId: 'America/Chicago',
  },
  '50064': {
    zip: '50064',
    city: 'Dana',
    state: 'IA',
    latitude: '42.106874',
    longitude: '-94.227054',
    timeZoneId: 'America/Chicago',
  },
  '50065': {
    zip: '50065',
    city: 'Davis City',
    state: 'IA',
    latitude: '40.639458',
    longitude: '-93.812081',
    timeZoneId: 'America/Chicago',
  },
  '50066': {
    zip: '50066',
    city: 'Dawson',
    state: 'IA',
    latitude: '41.820013',
    longitude: '-94.21998',
    timeZoneId: 'America/Chicago',
  },
  '50067': {
    zip: '50067',
    city: 'Decatur',
    state: 'IA',
    latitude: '40.768448',
    longitude: '-93.842967',
    timeZoneId: 'America/Chicago',
  },
  '50068': {
    zip: '50068',
    city: 'Derby',
    state: 'IA',
    latitude: '40.943215',
    longitude: '-93.498112',
    timeZoneId: 'America/Chicago',
  },
  '50069': {
    zip: '50069',
    city: 'De Soto',
    state: 'IA',
    latitude: '41.529836',
    longitude: '-94.062822',
    timeZoneId: 'America/Chicago',
  },
  '50070': {
    zip: '50070',
    city: 'Dexter',
    state: 'IA',
    latitude: '41.518177',
    longitude: '-94.226804',
    timeZoneId: 'America/Chicago',
  },
  '50071': {
    zip: '50071',
    city: 'Dows',
    state: 'IA',
    latitude: '42.654039',
    longitude: '-93.502101',
    timeZoneId: 'America/Chicago',
  },
  '50072': {
    zip: '50072',
    city: 'Earlham',
    state: 'IA',
    latitude: '41.491725',
    longitude: '-94.122623',
    timeZoneId: 'America/Chicago',
  },
  '50073': {
    zip: '50073',
    city: 'Elkhart',
    state: 'IA',
    latitude: '41.789463',
    longitude: '-93.515162',
    timeZoneId: 'America/Chicago',
  },
  '50074': {
    zip: '50074',
    city: 'Ellston',
    state: 'IA',
    latitude: '40.852201',
    longitude: '-94.074806',
    timeZoneId: 'America/Chicago',
  },
  '50075': {
    zip: '50075',
    city: 'Ellsworth',
    state: 'IA',
    latitude: '42.31337',
    longitude: '-93.580699',
    timeZoneId: 'America/Chicago',
  },
  '50076': {
    zip: '50076',
    city: 'Exira',
    state: 'IA',
    latitude: '41.590777',
    longitude: '-94.874949',
    timeZoneId: 'America/Chicago',
  },
  '50078': {
    zip: '50078',
    city: 'Ferguson',
    state: 'IA',
    latitude: '41.940879',
    longitude: '-92.867284',
    timeZoneId: 'America/Chicago',
  },
  '50099': {
    zip: '50099',
    city: 'Boone',
    state: 'IA',
    latitude: '42.056411',
    longitude: '-93.879522',
    timeZoneId: 'America/Chicago',
  },
  '50101': {
    zip: '50101',
    city: 'Galt',
    state: 'IA',
    latitude: '42.688739',
    longitude: '-93.596143',
    timeZoneId: 'America/Chicago',
  },
  '50102': {
    zip: '50102',
    city: 'Garden City',
    state: 'IA',
    latitude: '42.253563',
    longitude: '-93.403906',
    timeZoneId: 'America/Chicago',
  },
  '50103': {
    zip: '50103',
    city: 'Garden Grove',
    state: 'IA',
    latitude: '40.717931',
    longitude: '-93.614761',
    timeZoneId: 'America/Chicago',
  },
  '50104': {
    zip: '50104',
    city: 'Gibson',
    state: 'IA',
    latitude: '41.465855',
    longitude: '-92.352805',
    timeZoneId: 'America/Chicago',
  },
  '50105': {
    zip: '50105',
    city: 'Gilbert',
    state: 'IA',
    latitude: '42.104178',
    longitude: '-93.649016',
    timeZoneId: 'America/Chicago',
  },
  '50106': {
    zip: '50106',
    city: 'Gilman',
    state: 'IA',
    latitude: '41.900273',
    longitude: '-92.82475',
    timeZoneId: 'America/Chicago',
  },
  '50107': {
    zip: '50107',
    city: 'Grand Junction',
    state: 'IA',
    latitude: '42.032301',
    longitude: '-94.23204',
    timeZoneId: 'America/Chicago',
  },
  '50108': {
    zip: '50108',
    city: 'Grand River',
    state: 'IA',
    latitude: '40.809211',
    longitude: '-93.958622',
    timeZoneId: 'America/Chicago',
  },
  '50109': {
    zip: '50109',
    city: 'Granger',
    state: 'IA',
    latitude: '41.761899',
    longitude: '-93.774509',
    timeZoneId: 'America/Chicago',
  },
  '50110': {
    zip: '50110',
    city: 'Gray',
    state: 'IA',
    latitude: '41.837497',
    longitude: '-94.976472',
    timeZoneId: 'America/Chicago',
  },
  '50111': {
    zip: '50111',
    city: 'Grimes',
    state: 'IA',
    latitude: '41.685291',
    longitude: '-93.782087',
    timeZoneId: 'America/Chicago',
  },
  '50112': {
    zip: '50112',
    city: 'Grinnell',
    state: 'IA',
    latitude: '41.76732',
    longitude: '-92.76504',
    timeZoneId: 'America/Chicago',
  },
  '50115': {
    zip: '50115',
    city: 'Guthrie Center',
    state: 'IA',
    latitude: '41.677381',
    longitude: '-94.500012',
    timeZoneId: 'America/Chicago',
  },
  '50116': {
    zip: '50116',
    city: 'Hamilton',
    state: 'IA',
    latitude: '41.176154',
    longitude: '-92.929975',
    timeZoneId: 'America/Chicago',
  },
  '50117': {
    zip: '50117',
    city: 'Hamlin',
    state: 'IA',
    latitude: '41.646818',
    longitude: '-94.918972',
    timeZoneId: 'America/Chicago',
  },
  '50118': {
    zip: '50118',
    city: 'Hartford',
    state: 'IA',
    latitude: '41.461679',
    longitude: '-93.389691',
    timeZoneId: 'America/Chicago',
  },
  '50119': {
    zip: '50119',
    city: 'Harvey',
    state: 'IA',
    latitude: '41.317591',
    longitude: '-92.930643',
    timeZoneId: 'America/Chicago',
  },
  '50120': {
    zip: '50120',
    city: 'Haverhill',
    state: 'IA',
    latitude: '41.930905',
    longitude: '-92.979024',
    timeZoneId: 'America/Chicago',
  },
  '50122': {
    zip: '50122',
    city: 'Hubbard',
    state: 'IA',
    latitude: '42.305273',
    longitude: '-93.297256',
    timeZoneId: 'America/Chicago',
  },
  '50123': {
    zip: '50123',
    city: 'Humeston',
    state: 'IA',
    latitude: '40.84692',
    longitude: '-93.50352',
    timeZoneId: 'America/Chicago',
  },
  '50124': {
    zip: '50124',
    city: 'Huxley',
    state: 'IA',
    latitude: '41.897615',
    longitude: '-93.604954',
    timeZoneId: 'America/Chicago',
  },
  '50125': {
    zip: '50125',
    city: 'Indianola',
    state: 'IA',
    latitude: '41.31504',
    longitude: '-93.57204',
    timeZoneId: 'America/Chicago',
  },
  '50126': {
    zip: '50126',
    city: 'Iowa Falls',
    state: 'IA',
    latitude: '42.512225',
    longitude: '-93.291365',
    timeZoneId: 'America/Chicago',
  },
  '50127': {
    zip: '50127',
    city: 'Ira',
    state: 'IA',
    latitude: '41.734534',
    longitude: '-93.170294',
    timeZoneId: 'America/Chicago',
  },
  '50128': {
    zip: '50128',
    city: 'Jamaica',
    state: 'IA',
    latitude: '41.833335',
    longitude: '-94.338909',
    timeZoneId: 'America/Chicago',
  },
  '50129': {
    zip: '50129',
    city: 'Jefferson',
    state: 'IA',
    latitude: '42.012434',
    longitude: '-94.378248',
    timeZoneId: 'America/Chicago',
  },
  '50130': {
    zip: '50130',
    city: 'Jewell',
    state: 'IA',
    latitude: '42.304755',
    longitude: '-93.640015',
    timeZoneId: 'America/Chicago',
  },
  '50131': {
    zip: '50131',
    city: 'Johnston',
    state: 'IA',
    latitude: '41.682602',
    longitude: '-93.709577',
    timeZoneId: 'America/Chicago',
  },
  '50132': {
    zip: '50132',
    city: 'Kamrar',
    state: 'IA',
    latitude: '42.390238',
    longitude: '-93.730273',
    timeZoneId: 'America/Chicago',
  },
  '50133': {
    zip: '50133',
    city: 'Kellerton',
    state: 'IA',
    latitude: '40.64898',
    longitude: '-94.070796',
    timeZoneId: 'America/Chicago',
  },
  '50134': {
    zip: '50134',
    city: 'Kelley',
    state: 'IA',
    latitude: '41.940362',
    longitude: '-93.660609',
    timeZoneId: 'America/Chicago',
  },
  '50135': {
    zip: '50135',
    city: 'Kellogg',
    state: 'IA',
    latitude: '41.731036',
    longitude: '-92.920132',
    timeZoneId: 'America/Chicago',
  },
  '50136': {
    zip: '50136',
    city: 'Keswick',
    state: 'IA',
    latitude: '41.469943',
    longitude: '-92.244021',
    timeZoneId: 'America/Chicago',
  },
  '50137': {
    zip: '50137',
    city: 'Killduff',
    state: 'IA',
    latitude: '41.721789',
    longitude: '-93.033905',
    timeZoneId: 'America/Chicago',
  },
  '50138': {
    zip: '50138',
    city: 'Knoxville',
    state: 'IA',
    latitude: '41.320318',
    longitude: '-93.103486',
    timeZoneId: 'America/Chicago',
  },
  '50139': {
    zip: '50139',
    city: 'Lacona',
    state: 'IA',
    latitude: '41.196919',
    longitude: '-93.394123',
    timeZoneId: 'America/Chicago',
  },
  '50140': {
    zip: '50140',
    city: 'Lamoni',
    state: 'IA',
    latitude: '40.622185',
    longitude: '-93.931971',
    timeZoneId: 'America/Chicago',
  },
  '50141': {
    zip: '50141',
    city: 'Laurel',
    state: 'IA',
    latitude: '41.888202',
    longitude: '-92.942663',
    timeZoneId: 'America/Chicago',
  },
  '50142': {
    zip: '50142',
    city: 'Le Grand',
    state: 'IA',
    latitude: '42.005152',
    longitude: '-92.775704',
    timeZoneId: 'America/Chicago',
  },
  '50143': {
    zip: '50143',
    city: 'Leighton',
    state: 'IA',
    latitude: '41.376124',
    longitude: '-92.816298',
    timeZoneId: 'America/Chicago',
  },
  '50144': {
    zip: '50144',
    city: 'Leon',
    state: 'IA',
    latitude: '40.740854',
    longitude: '-93.745139',
    timeZoneId: 'America/Chicago',
  },
  '50145': {
    zip: '50145',
    city: 'Liberty Center',
    state: 'IA',
    latitude: '41.206742',
    longitude: '-93.498722',
    timeZoneId: 'America/Chicago',
  },
  '50146': {
    zip: '50146',
    city: 'Linden',
    state: 'IA',
    latitude: '41.641849',
    longitude: '-94.271445',
    timeZoneId: 'America/Chicago',
  },
  '50147': {
    zip: '50147',
    city: 'Lineville',
    state: 'IA',
    latitude: '40.579725',
    longitude: '-93.523649',
    timeZoneId: 'America/Chicago',
  },
  '50148': {
    zip: '50148',
    city: 'Liscomb',
    state: 'IA',
    latitude: '42.184344',
    longitude: '-93.000026',
    timeZoneId: 'America/Chicago',
  },
  '50149': {
    zip: '50149',
    city: 'Lorimor',
    state: 'IA',
    latitude: '41.111128',
    longitude: '-94.073065',
    timeZoneId: 'America/Chicago',
  },
  '50150': {
    zip: '50150',
    city: 'Lovilia',
    state: 'IA',
    latitude: '41.132809',
    longitude: '-92.906584',
    timeZoneId: 'America/Chicago',
  },
  '50151': {
    zip: '50151',
    city: 'Lucas',
    state: 'IA',
    latitude: '41.177296',
    longitude: '-93.538427',
    timeZoneId: 'America/Chicago',
  },
  '50152': {
    zip: '50152',
    city: 'Luther',
    state: 'IA',
    latitude: '41.994938',
    longitude: '-93.862586',
    timeZoneId: 'America/Chicago',
  },
  '50153': {
    zip: '50153',
    city: 'Lynnville',
    state: 'IA',
    latitude: '41.57809',
    longitude: '-92.783547',
    timeZoneId: 'America/Chicago',
  },
  '50154': {
    zip: '50154',
    city: 'Mc Callsburg',
    state: 'IA',
    latitude: '42.165609',
    longitude: '-93.389645',
    timeZoneId: 'America/Chicago',
  },
  '50155': {
    zip: '50155',
    city: 'Macksburg',
    state: 'IA',
    latitude: '41.213003',
    longitude: '-94.184371',
    timeZoneId: 'America/Chicago',
  },
  '50156': {
    zip: '50156',
    city: 'Madrid',
    state: 'IA',
    latitude: '41.875552',
    longitude: '-93.819402',
    timeZoneId: 'America/Chicago',
  },
  '50157': {
    zip: '50157',
    city: 'Malcom',
    state: 'IA',
    latitude: '41.612217',
    longitude: '-92.531212',
    timeZoneId: 'America/Chicago',
  },
  '50158': {
    zip: '50158',
    city: 'Marshalltown',
    state: 'IA',
    latitude: '42.05592',
    longitude: '-92.9406',
    timeZoneId: 'America/Chicago',
  },
  '50160': {
    zip: '50160',
    city: 'Martensdale',
    state: 'IA',
    latitude: '41.375259',
    longitude: '-93.74104',
    timeZoneId: 'America/Chicago',
  },
  '50161': {
    zip: '50161',
    city: 'Maxwell',
    state: 'IA',
    latitude: '41.88114',
    longitude: '-93.41172',
    timeZoneId: 'America/Chicago',
  },
  '50162': {
    zip: '50162',
    city: 'Melbourne',
    state: 'IA',
    latitude: '41.929203',
    longitude: '-93.067787',
    timeZoneId: 'America/Chicago',
  },
  '50163': {
    zip: '50163',
    city: 'Melcher Dallas',
    state: 'IA',
    latitude: '41.225249',
    longitude: '-93.238708',
    timeZoneId: 'America/Chicago',
  },
  '50164': {
    zip: '50164',
    city: 'Menlo',
    state: 'IA',
    latitude: '41.558007',
    longitude: '-94.416659',
    timeZoneId: 'America/Chicago',
  },
  '50165': {
    zip: '50165',
    city: 'Millerton',
    state: 'IA',
    latitude: '40.856051',
    longitude: '-93.271104',
    timeZoneId: 'America/Chicago',
  },
  '50166': {
    zip: '50166',
    city: 'Milo',
    state: 'IA',
    latitude: '41.281038',
    longitude: '-93.424925',
    timeZoneId: 'America/Chicago',
  },
  '50167': {
    zip: '50167',
    city: 'Minburn',
    state: 'IA',
    latitude: '41.757699',
    longitude: '-94.024564',
    timeZoneId: 'America/Chicago',
  },
  '50168': {
    zip: '50168',
    city: 'Mingo',
    state: 'IA',
    latitude: '41.800128',
    longitude: '-93.285926',
    timeZoneId: 'America/Chicago',
  },
  '50169': {
    zip: '50169',
    city: 'Mitchellville',
    state: 'IA',
    latitude: '41.665549',
    longitude: '-93.36755',
    timeZoneId: 'America/Chicago',
  },
  '50170': {
    zip: '50170',
    city: 'Monroe',
    state: 'IA',
    latitude: '41.523814',
    longitude: '-93.103546',
    timeZoneId: 'America/Chicago',
  },
  '50171': {
    zip: '50171',
    city: 'Montezuma',
    state: 'IA',
    latitude: '41.584863',
    longitude: '-92.525294',
    timeZoneId: 'America/Chicago',
  },
  '50173': {
    zip: '50173',
    city: 'Montour',
    state: 'IA',
    latitude: '41.94726',
    longitude: '-92.707811',
    timeZoneId: 'America/Chicago',
  },
  '50174': {
    zip: '50174',
    city: 'Murray',
    state: 'IA',
    latitude: '41.040931',
    longitude: '-93.949175',
    timeZoneId: 'America/Chicago',
  },
  '50201': {
    zip: '50201',
    city: 'Nevada',
    state: 'IA',
    latitude: '42.018291',
    longitude: '-93.450971',
    timeZoneId: 'America/Chicago',
  },
  '50206': {
    zip: '50206',
    city: 'New Providence',
    state: 'IA',
    latitude: '42.198187',
    longitude: '-93.188548',
    timeZoneId: 'America/Chicago',
  },
  '50207': {
    zip: '50207',
    city: 'New Sharon',
    state: 'IA',
    latitude: '41.467705',
    longitude: '-92.649644',
    timeZoneId: 'America/Chicago',
  },
  '50208': {
    zip: '50208',
    city: 'Newton',
    state: 'IA',
    latitude: '41.697096',
    longitude: '-93.046897',
    timeZoneId: 'America/Chicago',
  },
  '50210': {
    zip: '50210',
    city: 'New Virginia',
    state: 'IA',
    latitude: '41.20524',
    longitude: '-93.67062',
    timeZoneId: 'America/Chicago',
  },
  '50211': {
    zip: '50211',
    city: 'Norwalk',
    state: 'IA',
    latitude: '41.464165',
    longitude: '-93.687138',
    timeZoneId: 'America/Chicago',
  },
  '50212': {
    zip: '50212',
    city: 'Ogden',
    state: 'IA',
    latitude: '42.040772',
    longitude: '-94.027489',
    timeZoneId: 'America/Chicago',
  },
  '50213': {
    zip: '50213',
    city: 'Osceola',
    state: 'IA',
    latitude: '41.036668',
    longitude: '-93.763856',
    timeZoneId: 'America/Chicago',
  },
  '50214': {
    zip: '50214',
    city: 'Otley',
    state: 'IA',
    latitude: '41.459212',
    longitude: '-93.038568',
    timeZoneId: 'America/Chicago',
  },
  '50216': {
    zip: '50216',
    city: 'Panora',
    state: 'IA',
    latitude: '41.692194',
    longitude: '-94.362644',
    timeZoneId: 'America/Chicago',
  },
  '50217': {
    zip: '50217',
    city: 'Paton',
    state: 'IA',
    latitude: '42.162387',
    longitude: '-94.255165',
    timeZoneId: 'America/Chicago',
  },
  '50218': {
    zip: '50218',
    city: 'Patterson',
    state: 'IA',
    latitude: '41.374206',
    longitude: '-93.874199',
    timeZoneId: 'America/Chicago',
  },
  '50219': {
    zip: '50219',
    city: 'Pella',
    state: 'IA',
    latitude: '41.408313',
    longitude: '-92.914645',
    timeZoneId: 'America/Chicago',
  },
  '50220': {
    zip: '50220',
    city: 'Perry',
    state: 'IA',
    latitude: '41.840572',
    longitude: '-94.098673',
    timeZoneId: 'America/Chicago',
  },
  '50222': {
    zip: '50222',
    city: 'Peru',
    state: 'IA',
    latitude: '41.204218',
    longitude: '-93.942498',
    timeZoneId: 'America/Chicago',
  },
  '50223': {
    zip: '50223',
    city: 'Pilot Mound',
    state: 'IA',
    latitude: '42.167465',
    longitude: '-94.010592',
    timeZoneId: 'America/Chicago',
  },
  '50225': {
    zip: '50225',
    city: 'Pleasantville',
    state: 'IA',
    latitude: '41.388347',
    longitude: '-93.271889',
    timeZoneId: 'America/Chicago',
  },
  '50226': {
    zip: '50226',
    city: 'Polk City',
    state: 'IA',
    latitude: '41.783383',
    longitude: '-93.705476',
    timeZoneId: 'America/Chicago',
  },
  '50227': {
    zip: '50227',
    city: 'Popejoy',
    state: 'IA',
    latitude: '42.599402',
    longitude: '-93.437458',
    timeZoneId: 'America/Chicago',
  },
  '50228': {
    zip: '50228',
    city: 'Prairie City',
    state: 'IA',
    latitude: '41.598594',
    longitude: '-93.236114',
    timeZoneId: 'America/Chicago',
  },
  '50229': {
    zip: '50229',
    city: 'Prole',
    state: 'IA',
    latitude: '41.361538',
    longitude: '-93.718608',
    timeZoneId: 'America/Chicago',
  },
  '50230': {
    zip: '50230',
    city: 'Radcliffe',
    state: 'IA',
    latitude: '42.318477',
    longitude: '-93.430967',
    timeZoneId: 'America/Chicago',
  },
  '50231': {
    zip: '50231',
    city: 'Randall',
    state: 'IA',
    latitude: '42.254007',
    longitude: '-93.638925',
    timeZoneId: 'America/Chicago',
  },
  '50232': {
    zip: '50232',
    city: 'Reasnor',
    state: 'IA',
    latitude: '41.587056',
    longitude: '-93.019302',
    timeZoneId: 'America/Chicago',
  },
  '50233': {
    zip: '50233',
    city: 'Redfield',
    state: 'IA',
    latitude: '41.551425',
    longitude: '-94.182514',
    timeZoneId: 'America/Chicago',
  },
  '50234': {
    zip: '50234',
    city: 'Rhodes',
    state: 'IA',
    latitude: '41.908955',
    longitude: '-93.182342',
    timeZoneId: 'America/Chicago',
  },
  '50235': {
    zip: '50235',
    city: 'Rippey',
    state: 'IA',
    latitude: '41.932292',
    longitude: '-94.202558',
    timeZoneId: 'America/Chicago',
  },
  '50236': {
    zip: '50236',
    city: 'Roland',
    state: 'IA',
    latitude: '42.165862',
    longitude: '-93.499209',
    timeZoneId: 'America/Chicago',
  },
  '50237': {
    zip: '50237',
    city: 'Runnells',
    state: 'IA',
    latitude: '41.545966',
    longitude: '-93.400201',
    timeZoneId: 'America/Chicago',
  },
  '50238': {
    zip: '50238',
    city: 'Russell',
    state: 'IA',
    latitude: '40.983991',
    longitude: '-93.199488',
    timeZoneId: 'America/Chicago',
  },
  '50239': {
    zip: '50239',
    city: 'Saint Anthony',
    state: 'IA',
    latitude: '42.130933',
    longitude: '-93.198735',
    timeZoneId: 'America/Chicago',
  },
  '50240': {
    zip: '50240',
    city: 'Saint Charles',
    state: 'IA',
    latitude: '41.300602',
    longitude: '-93.744062',
    timeZoneId: 'America/Chicago',
  },
  '50241': {
    zip: '50241',
    city: 'Saint Marys',
    state: 'IA',
    latitude: '41.306977',
    longitude: '-93.733755',
    timeZoneId: 'America/Chicago',
  },
  '50242': {
    zip: '50242',
    city: 'Searsboro',
    state: 'IA',
    latitude: '41.555425',
    longitude: '-92.697855',
    timeZoneId: 'America/Chicago',
  },
  '50243': {
    zip: '50243',
    city: 'Sheldahl',
    state: 'IA',
    latitude: '41.864748',
    longitude: '-93.691118',
    timeZoneId: 'America/Chicago',
  },
  '50244': {
    zip: '50244',
    city: 'Slater',
    state: 'IA',
    latitude: '41.88145',
    longitude: '-93.679502',
    timeZoneId: 'America/Chicago',
  },
  '50246': {
    zip: '50246',
    city: 'Stanhope',
    state: 'IA',
    latitude: '42.285678',
    longitude: '-93.791679',
    timeZoneId: 'America/Chicago',
  },
  '50247': {
    zip: '50247',
    city: 'State Center',
    state: 'IA',
    latitude: '42.015094',
    longitude: '-93.161812',
    timeZoneId: 'America/Chicago',
  },
  '50248': {
    zip: '50248',
    city: 'Story City',
    state: 'IA',
    latitude: '42.180091',
    longitude: '-93.591611',
    timeZoneId: 'America/Chicago',
  },
  '50249': {
    zip: '50249',
    city: 'Stratford',
    state: 'IA',
    latitude: '42.272379',
    longitude: '-93.927468',
    timeZoneId: 'America/Chicago',
  },
  '50250': {
    zip: '50250',
    city: 'Stuart',
    state: 'IA',
    latitude: '41.50201',
    longitude: '-94.316481',
    timeZoneId: 'America/Chicago',
  },
  '50251': {
    zip: '50251',
    city: 'Sully',
    state: 'IA',
    latitude: '41.575962',
    longitude: '-92.842502',
    timeZoneId: 'America/Chicago',
  },
  '50252': {
    zip: '50252',
    city: 'Swan',
    state: 'IA',
    latitude: '41.443405',
    longitude: '-93.351241',
    timeZoneId: 'America/Chicago',
  },
  '50254': {
    zip: '50254',
    city: 'Thayer',
    state: 'IA',
    latitude: '40.985232',
    longitude: '-94.072081',
    timeZoneId: 'America/Chicago',
  },
  '50255': {
    zip: '50255',
    city: 'Thornburg',
    state: 'IA',
    latitude: '41.467746',
    longitude: '-92.354327',
    timeZoneId: 'America/Chicago',
  },
  '50256': {
    zip: '50256',
    city: 'Tracy',
    state: 'IA',
    latitude: '41.283057',
    longitude: '-92.871871',
    timeZoneId: 'America/Chicago',
  },
  '50257': {
    zip: '50257',
    city: 'Truro',
    state: 'IA',
    latitude: '41.209459',
    longitude: '-93.846687',
    timeZoneId: 'America/Chicago',
  },
  '50258': {
    zip: '50258',
    city: 'Union',
    state: 'IA',
    latitude: '42.172317',
    longitude: '-93.084639',
    timeZoneId: 'America/Chicago',
  },
  '50259': {
    zip: '50259',
    city: 'Gifford',
    state: 'IA',
    latitude: '42.253336',
    longitude: '-93.059924',
    timeZoneId: 'America/Chicago',
  },
  '50261': {
    zip: '50261',
    city: 'Van Meter',
    state: 'IA',
    latitude: '41.526185',
    longitude: '-93.95566',
    timeZoneId: 'America/Chicago',
  },
  '50262': {
    zip: '50262',
    city: 'Van Wert',
    state: 'IA',
    latitude: '40.854624',
    longitude: '-93.846622',
    timeZoneId: 'America/Chicago',
  },
  '50263': {
    zip: '50263',
    city: 'Waukee',
    state: 'IA',
    latitude: '41.602701',
    longitude: '-93.869105',
    timeZoneId: 'America/Chicago',
  },
  '50264': {
    zip: '50264',
    city: 'Weldon',
    state: 'IA',
    latitude: '40.857694',
    longitude: '-93.728115',
    timeZoneId: 'America/Chicago',
  },
  '50265': {
    zip: '50265',
    city: 'West Des Moines',
    state: 'IA',
    latitude: '41.583232',
    longitude: '-93.756888',
    timeZoneId: 'America/Chicago',
  },
  '50266': {
    zip: '50266',
    city: 'West Des Moines',
    state: 'IA',
    latitude: '41.5866',
    longitude: '-93.765743',
    timeZoneId: 'America/Chicago',
  },
  '50268': {
    zip: '50268',
    city: 'What Cheer',
    state: 'IA',
    latitude: '41.377924',
    longitude: '-92.353635',
    timeZoneId: 'America/Chicago',
  },
  '50269': {
    zip: '50269',
    city: 'Whitten',
    state: 'IA',
    latitude: '42.251604',
    longitude: '-93.061585',
    timeZoneId: 'America/Chicago',
  },
  '50271': {
    zip: '50271',
    city: 'Williams',
    state: 'IA',
    latitude: '42.488487',
    longitude: '-93.542349',
    timeZoneId: 'America/Chicago',
  },
  '50272': {
    zip: '50272',
    city: 'Williamson',
    state: 'IA',
    latitude: '41.114545',
    longitude: '-93.268297',
    timeZoneId: 'America/Chicago',
  },
  '50273': {
    zip: '50273',
    city: 'Winterset',
    state: 'IA',
    latitude: '41.332721',
    longitude: '-94.013335',
    timeZoneId: 'America/Chicago',
  },
  '50274': {
    zip: '50274',
    city: 'Wiota',
    state: 'IA',
    latitude: '41.40282',
    longitude: '-94.889868',
    timeZoneId: 'America/Chicago',
  },
  '50275': {
    zip: '50275',
    city: 'Woodburn',
    state: 'IA',
    latitude: '40.983826',
    longitude: '-93.616934',
    timeZoneId: 'America/Chicago',
  },
  '50276': {
    zip: '50276',
    city: 'Woodward',
    state: 'IA',
    latitude: '41.851937',
    longitude: '-93.924289',
    timeZoneId: 'America/Chicago',
  },
  '50277': {
    zip: '50277',
    city: 'Yale',
    state: 'IA',
    latitude: '41.773978',
    longitude: '-94.359197',
    timeZoneId: 'America/Chicago',
  },
  '50278': {
    zip: '50278',
    city: 'Zearing',
    state: 'IA',
    latitude: '42.1613',
    longitude: '-93.30758',
    timeZoneId: 'America/Chicago',
  },
  '50301': {
    zip: '50301',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.603794',
    longitude: '-93.631657',
    timeZoneId: 'America/Chicago',
  },
  '50302': {
    zip: '50302',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.605801',
    longitude: '-93.634877',
    timeZoneId: 'America/Chicago',
  },
  '50303': {
    zip: '50303',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.60319',
    longitude: '-93.631372',
    timeZoneId: 'America/Chicago',
  },
  '50304': {
    zip: '50304',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.606979',
    longitude: '-93.632601',
    timeZoneId: 'America/Chicago',
  },
  '50305': {
    zip: '50305',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.602146',
    longitude: '-93.631088',
    timeZoneId: 'America/Chicago',
  },
  '50306': {
    zip: '50306',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.605028',
    longitude: '-93.631799',
    timeZoneId: 'America/Chicago',
  },
  '50307': {
    zip: '50307',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.594051',
    longitude: '-93.602387',
    timeZoneId: 'America/Chicago',
  },
  '50308': {
    zip: '50308',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.583922',
    longitude: '-93.621551',
    timeZoneId: 'America/Chicago',
  },
  '50309': {
    zip: '50309',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.588394',
    longitude: '-93.619191',
    timeZoneId: 'America/Chicago',
  },
  '50310': {
    zip: '50310',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.626925',
    longitude: '-93.672635',
    timeZoneId: 'America/Chicago',
  },
  '50311': {
    zip: '50311',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.603992',
    longitude: '-93.684345',
    timeZoneId: 'America/Chicago',
  },
  '50312': {
    zip: '50312',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.586911',
    longitude: '-93.675689',
    timeZoneId: 'America/Chicago',
  },
  '50313': {
    zip: '50313',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.641599',
    longitude: '-93.617602',
    timeZoneId: 'America/Chicago',
  },
  '50314': {
    zip: '50314',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.604631',
    longitude: '-93.634646',
    timeZoneId: 'America/Chicago',
  },
  '50315': {
    zip: '50315',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.544895',
    longitude: '-93.615845',
    timeZoneId: 'America/Chicago',
  },
  '50316': {
    zip: '50316',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.607822',
    longitude: '-93.597425',
    timeZoneId: 'America/Chicago',
  },
  '50317': {
    zip: '50317',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.60277',
    longitude: '-93.543515',
    timeZoneId: 'America/Chicago',
  },
  '50318': {
    zip: '50318',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.586246',
    longitude: '-93.622986',
    timeZoneId: 'America/Chicago',
  },
  '50319': {
    zip: '50319',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.589947',
    longitude: '-93.598609',
    timeZoneId: 'America/Chicago',
  },
  '50320': {
    zip: '50320',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.54139',
    longitude: '-93.582',
    timeZoneId: 'America/Chicago',
  },
  '50321': {
    zip: '50321',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.543913',
    longitude: '-93.676872',
    timeZoneId: 'America/Chicago',
  },
  '50322': {
    zip: '50322',
    city: 'Urbandale',
    state: 'IA',
    latitude: '41.629345',
    longitude: '-93.732012',
    timeZoneId: 'America/Chicago',
  },
  '50323': {
    zip: '50323',
    city: 'Urbandale',
    state: 'IA',
    latitude: '41.626717',
    longitude: '-93.80126',
    timeZoneId: 'America/Chicago',
  },
  '50324': {
    zip: '50324',
    city: 'Windsor Heights',
    state: 'IA',
    latitude: '41.543762',
    longitude: '-93.586549',
    timeZoneId: 'America/Chicago',
  },
  '50325': {
    zip: '50325',
    city: 'Clive',
    state: 'IA',
    latitude: '41.604998',
    longitude: '-93.747731',
    timeZoneId: 'America/Chicago',
  },
  '50327': {
    zip: '50327',
    city: 'Pleasant Hill',
    state: 'IA',
    latitude: '41.578216',
    longitude: '-93.491427',
    timeZoneId: 'America/Chicago',
  },
  '50328': {
    zip: '50328',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582091',
    longitude: '-93.637951',
    timeZoneId: 'America/Chicago',
  },
  '50329': {
    zip: '50329',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.585933',
    longitude: '-93.635208',
    timeZoneId: 'America/Chicago',
  },
  '50330': {
    zip: '50330',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.587059',
    longitude: '-93.637291',
    timeZoneId: 'America/Chicago',
  },
  '50331': {
    zip: '50331',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.587161',
    longitude: '-93.634602',
    timeZoneId: 'America/Chicago',
  },
  '50332': {
    zip: '50332',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.589141',
    longitude: '-93.637284',
    timeZoneId: 'America/Chicago',
  },
  '50333': {
    zip: '50333',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.678246',
    longitude: '-93.610113',
    timeZoneId: 'America/Chicago',
  },
  '50334': {
    zip: '50334',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.627111',
    longitude: '-93.710893',
    timeZoneId: 'America/Chicago',
  },
  '50335': {
    zip: '50335',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.595639',
    longitude: '-93.62105',
    timeZoneId: 'America/Chicago',
  },
  '50336': {
    zip: '50336',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.568532',
    longitude: '-93.714503',
    timeZoneId: 'America/Chicago',
  },
  '50339': {
    zip: '50339',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.597161',
    longitude: '-93.746439',
    timeZoneId: 'America/Chicago',
  },
  '50340': {
    zip: '50340',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.587244',
    longitude: '-93.617538',
    timeZoneId: 'America/Chicago',
  },
  '50359': {
    zip: '50359',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.595835',
    longitude: '-93.764981',
    timeZoneId: 'America/Chicago',
  },
  '50360': {
    zip: '50360',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.594905',
    longitude: '-93.764562',
    timeZoneId: 'America/Chicago',
  },
  '50361': {
    zip: '50361',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.597268',
    longitude: '-93.764352',
    timeZoneId: 'America/Chicago',
  },
  '50362': {
    zip: '50362',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.596486',
    longitude: '-93.765012',
    timeZoneId: 'America/Chicago',
  },
  '50363': {
    zip: '50363',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.595159',
    longitude: '-93.764976',
    timeZoneId: 'America/Chicago',
  },
  '50364': {
    zip: '50364',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.596699',
    longitude: '-93.764908',
    timeZoneId: 'America/Chicago',
  },
  '50367': {
    zip: '50367',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.595632',
    longitude: '-93.766327',
    timeZoneId: 'America/Chicago',
  },
  '50368': {
    zip: '50368',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.598509',
    longitude: '-93.766771',
    timeZoneId: 'America/Chicago',
  },
  '50369': {
    zip: '50369',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.59594',
    longitude: '-93.746203',
    timeZoneId: 'America/Chicago',
  },
  '50380': {
    zip: '50380',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582496',
    longitude: '-93.639304',
    timeZoneId: 'America/Chicago',
  },
  '50381': {
    zip: '50381',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.58564',
    longitude: '-93.640154',
    timeZoneId: 'America/Chicago',
  },
  '50391': {
    zip: '50391',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.585461',
    longitude: '-93.622417',
    timeZoneId: 'America/Chicago',
  },
  '50392': {
    zip: '50392',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.59037',
    longitude: '-93.629659',
    timeZoneId: 'America/Chicago',
  },
  '50393': {
    zip: '50393',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582211',
    longitude: '-93.622208',
    timeZoneId: 'America/Chicago',
  },
  '50394': {
    zip: '50394',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.628965',
    longitude: '-93.67441',
    timeZoneId: 'America/Chicago',
  },
  '50395': {
    zip: '50395',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.600964',
    longitude: '-93.630768',
    timeZoneId: 'America/Chicago',
  },
  '50396': {
    zip: '50396',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.587997',
    longitude: '-93.609801',
    timeZoneId: 'America/Chicago',
  },
  '50398': {
    zip: '50398',
    city: 'Urbandale',
    state: 'IA',
    latitude: '41.577582',
    longitude: '-93.708707',
    timeZoneId: 'America/Chicago',
  },
  '50401': {
    zip: '50401',
    city: 'Mason City',
    state: 'IA',
    latitude: '43.149424',
    longitude: '-93.198045',
    timeZoneId: 'America/Chicago',
  },
  '50402': {
    zip: '50402',
    city: 'Mason City',
    state: 'IA',
    latitude: '43.151666',
    longitude: '-93.204478',
    timeZoneId: 'America/Chicago',
  },
  '50420': {
    zip: '50420',
    city: 'Alexander',
    state: 'IA',
    latitude: '42.819827',
    longitude: '-93.439648',
    timeZoneId: 'America/Chicago',
  },
  '50421': {
    zip: '50421',
    city: 'Belmond',
    state: 'IA',
    latitude: '42.846375',
    longitude: '-93.614291',
    timeZoneId: 'America/Chicago',
  },
  '50423': {
    zip: '50423',
    city: 'Britt',
    state: 'IA',
    latitude: '43.095207',
    longitude: '-93.799817',
    timeZoneId: 'America/Chicago',
  },
  '50424': {
    zip: '50424',
    city: 'Buffalo Center',
    state: 'IA',
    latitude: '43.388034',
    longitude: '-93.950119',
    timeZoneId: 'America/Chicago',
  },
  '50426': {
    zip: '50426',
    city: 'Carpenter',
    state: 'IA',
    latitude: '43.431365',
    longitude: '-92.913573',
    timeZoneId: 'America/Chicago',
  },
  '50427': {
    zip: '50427',
    city: 'Chapin',
    state: 'IA',
    latitude: '42.839158',
    longitude: '-93.222003',
    timeZoneId: 'America/Chicago',
  },
  '50428': {
    zip: '50428',
    city: 'Clear Lake',
    state: 'IA',
    latitude: '43.138257',
    longitude: '-93.384461',
    timeZoneId: 'America/Chicago',
  },
  '50430': {
    zip: '50430',
    city: 'Corwith',
    state: 'IA',
    latitude: '42.99307',
    longitude: '-93.953142',
    timeZoneId: 'America/Chicago',
  },
  '50431': {
    zip: '50431',
    city: 'Coulter',
    state: 'IA',
    latitude: '42.735954',
    longitude: '-93.368072',
    timeZoneId: 'America/Chicago',
  },
  '50432': {
    zip: '50432',
    city: 'Crystal Lake',
    state: 'IA',
    latitude: '43.225498',
    longitude: '-93.791023',
    timeZoneId: 'America/Chicago',
  },
  '50433': {
    zip: '50433',
    city: 'Dougherty',
    state: 'IA',
    latitude: '42.922058',
    longitude: '-93.041859',
    timeZoneId: 'America/Chicago',
  },
  '50434': {
    zip: '50434',
    city: 'Fertile',
    state: 'IA',
    latitude: '43.2634',
    longitude: '-93.421488',
    timeZoneId: 'America/Chicago',
  },
  '50435': {
    zip: '50435',
    city: 'Floyd',
    state: 'IA',
    latitude: '43.127067',
    longitude: '-92.736859',
    timeZoneId: 'America/Chicago',
  },
  '50436': {
    zip: '50436',
    city: 'Forest City',
    state: 'IA',
    latitude: '43.26454',
    longitude: '-93.644865',
    timeZoneId: 'America/Chicago',
  },
  '50438': {
    zip: '50438',
    city: 'Garner',
    state: 'IA',
    latitude: '43.11432',
    longitude: '-93.55374',
    timeZoneId: 'America/Chicago',
  },
  '50439': {
    zip: '50439',
    city: 'Goodell',
    state: 'IA',
    latitude: '42.922102',
    longitude: '-93.617078',
    timeZoneId: 'America/Chicago',
  },
  '50440': {
    zip: '50440',
    city: 'Grafton',
    state: 'IA',
    latitude: '43.328788',
    longitude: '-93.072452',
    timeZoneId: 'America/Chicago',
  },
  '50441': {
    zip: '50441',
    city: 'Hampton',
    state: 'IA',
    latitude: '42.745447',
    longitude: '-93.208062',
    timeZoneId: 'America/Chicago',
  },
  '50444': {
    zip: '50444',
    city: 'Hanlontown',
    state: 'IA',
    latitude: '43.279755',
    longitude: '-93.37759',
    timeZoneId: 'America/Chicago',
  },
  '50446': {
    zip: '50446',
    city: 'Joice',
    state: 'IA',
    latitude: '43.361512',
    longitude: '-93.454058',
    timeZoneId: 'America/Chicago',
  },
  '50447': {
    zip: '50447',
    city: 'Kanawha',
    state: 'IA',
    latitude: '42.935696',
    longitude: '-93.790461',
    timeZoneId: 'America/Chicago',
  },
  '50448': {
    zip: '50448',
    city: 'Kensett',
    state: 'IA',
    latitude: '43.356377',
    longitude: '-93.227126',
    timeZoneId: 'America/Chicago',
  },
  '50449': {
    zip: '50449',
    city: 'Klemme',
    state: 'IA',
    latitude: '43.04582',
    longitude: '-93.475891',
    timeZoneId: 'America/Chicago',
  },
  '50450': {
    zip: '50450',
    city: 'Lake Mills',
    state: 'IA',
    latitude: '43.421066',
    longitude: '-93.532888',
    timeZoneId: 'America/Chicago',
  },
  '50451': {
    zip: '50451',
    city: 'Lakota',
    state: 'IA',
    latitude: '43.410394',
    longitude: '-94.053463',
    timeZoneId: 'America/Chicago',
  },
  '50452': {
    zip: '50452',
    city: 'Latimer',
    state: 'IA',
    latitude: '42.775174',
    longitude: '-93.321219',
    timeZoneId: 'America/Chicago',
  },
  '50453': {
    zip: '50453',
    city: 'Leland',
    state: 'IA',
    latitude: '43.33826',
    longitude: '-93.633413',
    timeZoneId: 'America/Chicago',
  },
  '50454': {
    zip: '50454',
    city: 'Little Cedar',
    state: 'IA',
    latitude: '43.390835',
    longitude: '-92.732723',
    timeZoneId: 'America/Chicago',
  },
  '50455': {
    zip: '50455',
    city: 'Mc Intire',
    state: 'IA',
    latitude: '43.465027',
    longitude: '-92.612882',
    timeZoneId: 'America/Chicago',
  },
  '50456': {
    zip: '50456',
    city: 'Manly',
    state: 'IA',
    latitude: '43.288858',
    longitude: '-93.201173',
    timeZoneId: 'America/Chicago',
  },
  '50457': {
    zip: '50457',
    city: 'Meservey',
    state: 'IA',
    latitude: '42.931012',
    longitude: '-93.466683',
    timeZoneId: 'America/Chicago',
  },
  '50458': {
    zip: '50458',
    city: 'Nora Springs',
    state: 'IA',
    latitude: '43.1291',
    longitude: '-92.996025',
    timeZoneId: 'America/Chicago',
  },
  '50459': {
    zip: '50459',
    city: 'Northwood',
    state: 'IA',
    latitude: '43.444547',
    longitude: '-93.217706',
    timeZoneId: 'America/Chicago',
  },
  '50460': {
    zip: '50460',
    city: 'Orchard',
    state: 'IA',
    latitude: '43.23686',
    longitude: '-92.68418',
    timeZoneId: 'America/Chicago',
  },
  '50461': {
    zip: '50461',
    city: 'Osage',
    state: 'IA',
    latitude: '43.286734',
    longitude: '-92.810092',
    timeZoneId: 'America/Chicago',
  },
  '50464': {
    zip: '50464',
    city: 'Plymouth',
    state: 'IA',
    latitude: '43.247429',
    longitude: '-93.124562',
    timeZoneId: 'America/Chicago',
  },
  '50465': {
    zip: '50465',
    city: 'Rake',
    state: 'IA',
    latitude: '43.467276',
    longitude: '-93.910376',
    timeZoneId: 'America/Chicago',
  },
  '50466': {
    zip: '50466',
    city: 'Riceville',
    state: 'IA',
    latitude: '43.36529',
    longitude: '-92.551275',
    timeZoneId: 'America/Chicago',
  },
  '50467': {
    zip: '50467',
    city: 'Rock Falls',
    state: 'IA',
    latitude: '43.207888',
    longitude: '-93.085486',
    timeZoneId: 'America/Chicago',
  },
  '50468': {
    zip: '50468',
    city: 'Rockford',
    state: 'IA',
    latitude: '43.055849',
    longitude: '-92.944925',
    timeZoneId: 'America/Chicago',
  },
  '50469': {
    zip: '50469',
    city: 'Rockwell',
    state: 'IA',
    latitude: '43.01232',
    longitude: '-93.31452',
    timeZoneId: 'America/Chicago',
  },
  '50470': {
    zip: '50470',
    city: 'Rowan',
    state: 'IA',
    latitude: '42.740083',
    longitude: '-93.545238',
    timeZoneId: 'America/Chicago',
  },
  '50471': {
    zip: '50471',
    city: 'Rudd',
    state: 'IA',
    latitude: '43.127601',
    longitude: '-92.905238',
    timeZoneId: 'America/Chicago',
  },
  '50472': {
    zip: '50472',
    city: 'Saint Ansgar',
    state: 'IA',
    latitude: '43.377784',
    longitude: '-92.915665',
    timeZoneId: 'America/Chicago',
  },
  '50473': {
    zip: '50473',
    city: 'Scarville',
    state: 'IA',
    latitude: '43.46657',
    longitude: '-93.65235',
    timeZoneId: 'America/Chicago',
  },
  '50475': {
    zip: '50475',
    city: 'Sheffield',
    state: 'IA',
    latitude: '42.891379',
    longitude: '-93.218136',
    timeZoneId: 'America/Chicago',
  },
  '50476': {
    zip: '50476',
    city: 'Stacyville',
    state: 'IA',
    latitude: '43.441918',
    longitude: '-92.782398',
    timeZoneId: 'America/Chicago',
  },
  '50477': {
    zip: '50477',
    city: 'Swaledale',
    state: 'IA',
    latitude: '42.951782',
    longitude: '-93.313856',
    timeZoneId: 'America/Chicago',
  },
  '50478': {
    zip: '50478',
    city: 'Thompson',
    state: 'IA',
    latitude: '43.36853',
    longitude: '-93.771293',
    timeZoneId: 'America/Chicago',
  },
  '50479': {
    zip: '50479',
    city: 'Thornton',
    state: 'IA',
    latitude: '42.943522',
    longitude: '-93.380824',
    timeZoneId: 'America/Chicago',
  },
  '50480': {
    zip: '50480',
    city: 'Titonka',
    state: 'IA',
    latitude: '43.238426',
    longitude: '-94.039265',
    timeZoneId: 'America/Chicago',
  },
  '50481': {
    zip: '50481',
    city: 'Toeterville',
    state: 'IA',
    latitude: '43.378333',
    longitude: '-92.918611',
    timeZoneId: 'America/Chicago',
  },
  '50482': {
    zip: '50482',
    city: 'Ventura',
    state: 'IA',
    latitude: '43.12704',
    longitude: '-93.474427',
    timeZoneId: 'America/Chicago',
  },
  '50483': {
    zip: '50483',
    city: 'Wesley',
    state: 'IA',
    latitude: '43.08409',
    longitude: '-93.993811',
    timeZoneId: 'America/Chicago',
  },
  '50484': {
    zip: '50484',
    city: 'Woden',
    state: 'IA',
    latitude: '43.229647',
    longitude: '-93.907928',
    timeZoneId: 'America/Chicago',
  },
  '50501': {
    zip: '50501',
    city: 'Fort Dodge',
    state: 'IA',
    latitude: '42.506592',
    longitude: '-94.17699',
    timeZoneId: 'America/Chicago',
  },
  '50510': {
    zip: '50510',
    city: 'Albert City',
    state: 'IA',
    latitude: '42.784863',
    longitude: '-94.962813',
    timeZoneId: 'America/Chicago',
  },
  '50511': {
    zip: '50511',
    city: 'Algona',
    state: 'IA',
    latitude: '43.072355',
    longitude: '-94.229492',
    timeZoneId: 'America/Chicago',
  },
  '50514': {
    zip: '50514',
    city: 'Armstrong',
    state: 'IA',
    latitude: '43.39546',
    longitude: '-94.486346',
    timeZoneId: 'America/Chicago',
  },
  '50515': {
    zip: '50515',
    city: 'Ayrshire',
    state: 'IA',
    latitude: '43.037511',
    longitude: '-94.835824',
    timeZoneId: 'America/Chicago',
  },
  '50516': {
    zip: '50516',
    city: 'Badger',
    state: 'IA',
    latitude: '42.602811',
    longitude: '-94.150516',
    timeZoneId: 'America/Chicago',
  },
  '50517': {
    zip: '50517',
    city: 'Bancroft',
    state: 'IA',
    latitude: '43.292754',
    longitude: '-94.2164',
    timeZoneId: 'America/Chicago',
  },
  '50518': {
    zip: '50518',
    city: 'Barnum',
    state: 'IA',
    latitude: '42.509584',
    longitude: '-94.36524',
    timeZoneId: 'America/Chicago',
  },
  '50519': {
    zip: '50519',
    city: 'Bode',
    state: 'IA',
    latitude: '42.865196',
    longitude: '-94.283898',
    timeZoneId: 'America/Chicago',
  },
  '50520': {
    zip: '50520',
    city: 'Bradgate',
    state: 'IA',
    latitude: '42.793234',
    longitude: '-94.385221',
    timeZoneId: 'America/Chicago',
  },
  '50521': {
    zip: '50521',
    city: 'Burnside',
    state: 'IA',
    latitude: '42.336366',
    longitude: '-94.111285',
    timeZoneId: 'America/Chicago',
  },
  '50522': {
    zip: '50522',
    city: 'Burt',
    state: 'IA',
    latitude: '43.197762',
    longitude: '-94.222103',
    timeZoneId: 'America/Chicago',
  },
  '50523': {
    zip: '50523',
    city: 'Callender',
    state: 'IA',
    latitude: '42.360744',
    longitude: '-94.292047',
    timeZoneId: 'America/Chicago',
  },
  '50524': {
    zip: '50524',
    city: 'Clare',
    state: 'IA',
    latitude: '42.588872',
    longitude: '-94.344666',
    timeZoneId: 'America/Chicago',
  },
  '50525': {
    zip: '50525',
    city: 'Clarion',
    state: 'IA',
    latitude: '42.729586',
    longitude: '-93.733773',
    timeZoneId: 'America/Chicago',
  },
  '50526': {
    zip: '50526',
    city: 'Clarion',
    state: 'IA',
    latitude: '42.732885',
    longitude: '-93.732537',
    timeZoneId: 'America/Chicago',
  },
  '50527': {
    zip: '50527',
    city: 'Curlew',
    state: 'IA',
    latitude: '42.959933',
    longitude: '-94.82468',
    timeZoneId: 'America/Chicago',
  },
  '50528': {
    zip: '50528',
    city: 'Cylinder',
    state: 'IA',
    latitude: '43.090244',
    longitude: '-94.550554',
    timeZoneId: 'America/Chicago',
  },
  '50529': {
    zip: '50529',
    city: 'Dakota City',
    state: 'IA',
    latitude: '42.718671',
    longitude: '-94.197179',
    timeZoneId: 'America/Chicago',
  },
  '50530': {
    zip: '50530',
    city: 'Dayton',
    state: 'IA',
    latitude: '42.268384',
    longitude: '-94.062724',
    timeZoneId: 'America/Chicago',
  },
  '50531': {
    zip: '50531',
    city: 'Dolliver',
    state: 'IA',
    latitude: '43.462245',
    longitude: '-94.620349',
    timeZoneId: 'America/Chicago',
  },
  '50532': {
    zip: '50532',
    city: 'Duncombe',
    state: 'IA',
    latitude: '42.470067',
    longitude: '-93.993314',
    timeZoneId: 'America/Chicago',
  },
  '50533': {
    zip: '50533',
    city: 'Eagle Grove',
    state: 'IA',
    latitude: '42.66325',
    longitude: '-93.902294',
    timeZoneId: 'America/Chicago',
  },
  '50535': {
    zip: '50535',
    city: 'Early',
    state: 'IA',
    latitude: '42.454667',
    longitude: '-95.092473',
    timeZoneId: 'America/Chicago',
  },
  '50536': {
    zip: '50536',
    city: 'Emmetsburg',
    state: 'IA',
    latitude: '43.10847',
    longitude: '-94.678781',
    timeZoneId: 'America/Chicago',
  },
  '50538': {
    zip: '50538',
    city: 'Farnhamville',
    state: 'IA',
    latitude: '42.280038',
    longitude: '-94.405502',
    timeZoneId: 'America/Chicago',
  },
  '50539': {
    zip: '50539',
    city: 'Fenton',
    state: 'IA',
    latitude: '43.216976',
    longitude: '-94.411098',
    timeZoneId: 'America/Chicago',
  },
  '50540': {
    zip: '50540',
    city: 'Fonda',
    state: 'IA',
    latitude: '42.586237',
    longitude: '-94.844066',
    timeZoneId: 'America/Chicago',
  },
  '50541': {
    zip: '50541',
    city: 'Gilmore City',
    state: 'IA',
    latitude: '42.71766',
    longitude: '-94.425',
    timeZoneId: 'America/Chicago',
  },
  '50542': {
    zip: '50542',
    city: 'Goldfield',
    state: 'IA',
    latitude: '42.733991',
    longitude: '-93.921071',
    timeZoneId: 'America/Chicago',
  },
  '50543': {
    zip: '50543',
    city: 'Gowrie',
    state: 'IA',
    latitude: '42.278285',
    longitude: '-94.288979',
    timeZoneId: 'America/Chicago',
  },
  '50544': {
    zip: '50544',
    city: 'Harcourt',
    state: 'IA',
    latitude: '42.263592',
    longitude: '-94.178232',
    timeZoneId: 'America/Chicago',
  },
  '50545': {
    zip: '50545',
    city: 'Hardy',
    state: 'IA',
    latitude: '42.807516',
    longitude: '-94.053923',
    timeZoneId: 'America/Chicago',
  },
  '50546': {
    zip: '50546',
    city: 'Havelock',
    state: 'IA',
    latitude: '42.842849',
    longitude: '-94.734536',
    timeZoneId: 'America/Chicago',
  },
  '50548': {
    zip: '50548',
    city: 'Humboldt',
    state: 'IA',
    latitude: '42.722941',
    longitude: '-94.220119',
    timeZoneId: 'America/Chicago',
  },
  '50551': {
    zip: '50551',
    city: 'Jolley',
    state: 'IA',
    latitude: '42.5126',
    longitude: '-94.745828',
    timeZoneId: 'America/Chicago',
  },
  '50552': {
    zip: '50552',
    city: 'Knierim',
    state: 'IA',
    latitude: '42.457369',
    longitude: '-94.45912',
    timeZoneId: 'America/Chicago',
  },
  '50554': {
    zip: '50554',
    city: 'Laurens',
    state: 'IA',
    latitude: '42.849816',
    longitude: '-94.849302',
    timeZoneId: 'America/Chicago',
  },
  '50556': {
    zip: '50556',
    city: 'Ledyard',
    state: 'IA',
    latitude: '43.440882',
    longitude: '-94.148411',
    timeZoneId: 'America/Chicago',
  },
  '50557': {
    zip: '50557',
    city: 'Lehigh',
    state: 'IA',
    latitude: '42.470182',
    longitude: '-93.987054',
    timeZoneId: 'America/Chicago',
  },
  '50558': {
    zip: '50558',
    city: 'Livermore',
    state: 'IA',
    latitude: '42.866229',
    longitude: '-94.183669',
    timeZoneId: 'America/Chicago',
  },
  '50559': {
    zip: '50559',
    city: 'Lone Rock',
    state: 'IA',
    latitude: '43.223405',
    longitude: '-94.320812',
    timeZoneId: 'America/Chicago',
  },
  '50560': {
    zip: '50560',
    city: 'Lu Verne',
    state: 'IA',
    latitude: '42.912847',
    longitude: '-94.084571',
    timeZoneId: 'America/Chicago',
  },
  '50561': {
    zip: '50561',
    city: 'Lytton',
    state: 'IA',
    latitude: '42.426042',
    longitude: '-94.862217',
    timeZoneId: 'America/Chicago',
  },
  '50562': {
    zip: '50562',
    city: 'Mallard',
    state: 'IA',
    latitude: '42.9441',
    longitude: '-94.67928',
    timeZoneId: 'America/Chicago',
  },
  '50563': {
    zip: '50563',
    city: 'Manson',
    state: 'IA',
    latitude: '42.53008',
    longitude: '-94.537593',
    timeZoneId: 'America/Chicago',
  },
  '50565': {
    zip: '50565',
    city: 'Marathon',
    state: 'IA',
    latitude: '42.859938',
    longitude: '-94.983964',
    timeZoneId: 'America/Chicago',
  },
  '50566': {
    zip: '50566',
    city: 'Moorland',
    state: 'IA',
    latitude: '42.445392',
    longitude: '-94.290298',
    timeZoneId: 'America/Chicago',
  },
  '50567': {
    zip: '50567',
    city: 'Nemaha',
    state: 'IA',
    latitude: '42.516543',
    longitude: '-95.092223',
    timeZoneId: 'America/Chicago',
  },
  '50568': {
    zip: '50568',
    city: 'Newell',
    state: 'IA',
    latitude: '42.605095',
    longitude: '-95.005059',
    timeZoneId: 'America/Chicago',
  },
  '50569': {
    zip: '50569',
    city: 'Otho',
    state: 'IA',
    latitude: '42.430428',
    longitude: '-94.162014',
    timeZoneId: 'America/Chicago',
  },
  '50570': {
    zip: '50570',
    city: 'Ottosen',
    state: 'IA',
    latitude: '42.895295',
    longitude: '-94.379185',
    timeZoneId: 'America/Chicago',
  },
  '50571': {
    zip: '50571',
    city: 'Palmer',
    state: 'IA',
    latitude: '42.633721',
    longitude: '-94.597766',
    timeZoneId: 'America/Chicago',
  },
  '50573': {
    zip: '50573',
    city: 'Plover',
    state: 'IA',
    latitude: '42.866039',
    longitude: '-94.62007',
    timeZoneId: 'America/Chicago',
  },
  '50574': {
    zip: '50574',
    city: 'Pocahontas',
    state: 'IA',
    latitude: '42.733325',
    longitude: '-94.671319',
    timeZoneId: 'America/Chicago',
  },
  '50575': {
    zip: '50575',
    city: 'Pomeroy',
    state: 'IA',
    latitude: '42.51747',
    longitude: '-94.76337',
    timeZoneId: 'America/Chicago',
  },
  '50576': {
    zip: '50576',
    city: 'Rembrandt',
    state: 'IA',
    latitude: '42.826983',
    longitude: '-95.165096',
    timeZoneId: 'America/Chicago',
  },
  '50577': {
    zip: '50577',
    city: 'Renwick',
    state: 'IA',
    latitude: '42.827927',
    longitude: '-93.980188',
    timeZoneId: 'America/Chicago',
  },
  '50578': {
    zip: '50578',
    city: 'Ringsted',
    state: 'IA',
    latitude: '43.236142',
    longitude: '-94.319113',
    timeZoneId: 'America/Chicago',
  },
  '50579': {
    zip: '50579',
    city: 'Rockwell City',
    state: 'IA',
    latitude: '42.394028',
    longitude: '-94.630743',
    timeZoneId: 'America/Chicago',
  },
  '50581': {
    zip: '50581',
    city: 'Rolfe',
    state: 'IA',
    latitude: '42.812984',
    longitude: '-94.529614',
    timeZoneId: 'America/Chicago',
  },
  '50582': {
    zip: '50582',
    city: 'Rutland',
    state: 'IA',
    latitude: '42.75765',
    longitude: '-94.291712',
    timeZoneId: 'America/Chicago',
  },
  '50583': {
    zip: '50583',
    city: 'Sac City',
    state: 'IA',
    latitude: '42.419313',
    longitude: '-94.993699',
    timeZoneId: 'America/Chicago',
  },
  '50585': {
    zip: '50585',
    city: 'Sioux Rapids',
    state: 'IA',
    latitude: '42.9054',
    longitude: '-95.1453',
    timeZoneId: 'America/Chicago',
  },
  '50586': {
    zip: '50586',
    city: 'Somers',
    state: 'IA',
    latitude: '42.377536',
    longitude: '-94.429628',
    timeZoneId: 'America/Chicago',
  },
  '50588': {
    zip: '50588',
    city: 'Storm Lake',
    state: 'IA',
    latitude: '42.641049',
    longitude: '-95.20254',
    timeZoneId: 'America/Chicago',
  },
  '50590': {
    zip: '50590',
    city: 'Swea City',
    state: 'IA',
    latitude: '43.382381',
    longitude: '-94.309212',
    timeZoneId: 'America/Chicago',
  },
  '50591': {
    zip: '50591',
    city: 'Thor',
    state: 'IA',
    latitude: '42.686415',
    longitude: '-94.047874',
    timeZoneId: 'America/Chicago',
  },
  '50592': {
    zip: '50592',
    city: 'Truesdale',
    state: 'IA',
    latitude: '42.730416',
    longitude: '-95.181613',
    timeZoneId: 'America/Chicago',
  },
  '50593': {
    zip: '50593',
    city: 'Varina',
    state: 'IA',
    latitude: '42.630778',
    longitude: '-94.816716',
    timeZoneId: 'America/Chicago',
  },
  '50594': {
    zip: '50594',
    city: 'Vincent',
    state: 'IA',
    latitude: '42.589884',
    longitude: '-94.01884',
    timeZoneId: 'America/Chicago',
  },
  '50595': {
    zip: '50595',
    city: 'Webster City',
    state: 'IA',
    latitude: '42.46086',
    longitude: '-93.82968',
    timeZoneId: 'America/Chicago',
  },
  '50597': {
    zip: '50597',
    city: 'West Bend',
    state: 'IA',
    latitude: '42.962944',
    longitude: '-94.445907',
    timeZoneId: 'America/Chicago',
  },
  '50598': {
    zip: '50598',
    city: 'Whittemore',
    state: 'IA',
    latitude: '43.062263',
    longitude: '-94.424146',
    timeZoneId: 'America/Chicago',
  },
  '50599': {
    zip: '50599',
    city: 'Woolstock',
    state: 'IA',
    latitude: '42.601398',
    longitude: '-93.795443',
    timeZoneId: 'America/Chicago',
  },
  '50601': {
    zip: '50601',
    city: 'Ackley',
    state: 'IA',
    latitude: '42.552024',
    longitude: '-93.052562',
    timeZoneId: 'America/Chicago',
  },
  '50602': {
    zip: '50602',
    city: 'Allison',
    state: 'IA',
    latitude: '42.75829',
    longitude: '-92.799014',
    timeZoneId: 'America/Chicago',
  },
  '50603': {
    zip: '50603',
    city: 'Alta Vista',
    state: 'IA',
    latitude: '43.148786',
    longitude: '-92.435782',
    timeZoneId: 'America/Chicago',
  },
  '50604': {
    zip: '50604',
    city: 'Aplington',
    state: 'IA',
    latitude: '42.58163',
    longitude: '-92.880135',
    timeZoneId: 'America/Chicago',
  },
  '50605': {
    zip: '50605',
    city: 'Aredale',
    state: 'IA',
    latitude: '42.836963',
    longitude: '-93.003512',
    timeZoneId: 'America/Chicago',
  },
  '50606': {
    zip: '50606',
    city: 'Arlington',
    state: 'IA',
    latitude: '42.768576',
    longitude: '-91.693918',
    timeZoneId: 'America/Chicago',
  },
  '50607': {
    zip: '50607',
    city: 'Aurora',
    state: 'IA',
    latitude: '42.623665',
    longitude: '-91.733386',
    timeZoneId: 'America/Chicago',
  },
  '50608': {
    zip: '50608',
    city: 'Austinville',
    state: 'IA',
    latitude: '42.599205',
    longitude: '-92.965967',
    timeZoneId: 'America/Chicago',
  },
  '50609': {
    zip: '50609',
    city: 'Beaman',
    state: 'IA',
    latitude: '42.222729',
    longitude: '-92.82011',
    timeZoneId: 'America/Chicago',
  },
  '50611': {
    zip: '50611',
    city: 'Bristow',
    state: 'IA',
    latitude: '42.774055',
    longitude: '-92.90564',
    timeZoneId: 'America/Chicago',
  },
  '50612': {
    zip: '50612',
    city: 'Buckingham',
    state: 'IA',
    latitude: '42.310784',
    longitude: '-92.376708',
    timeZoneId: 'America/Chicago',
  },
  '50613': {
    zip: '50613',
    city: 'Cedar Falls',
    state: 'IA',
    latitude: '42.529347',
    longitude: '-92.451306',
    timeZoneId: 'America/Chicago',
  },
  '50614': {
    zip: '50614',
    city: 'Cedar Falls',
    state: 'IA',
    latitude: '42.509279',
    longitude: '-92.460752',
    timeZoneId: 'America/Chicago',
  },
  '50616': {
    zip: '50616',
    city: 'Charles City',
    state: 'IA',
    latitude: '43.063262',
    longitude: '-92.671092',
    timeZoneId: 'America/Chicago',
  },
  '50619': {
    zip: '50619',
    city: 'Clarksville',
    state: 'IA',
    latitude: '42.778981',
    longitude: '-92.665398',
    timeZoneId: 'America/Chicago',
  },
  '50620': {
    zip: '50620',
    city: 'Colwell',
    state: 'IA',
    latitude: '43.068536',
    longitude: '-92.711538',
    timeZoneId: 'America/Chicago',
  },
  '50621': {
    zip: '50621',
    city: 'Conrad',
    state: 'IA',
    latitude: '42.228083',
    longitude: '-92.874394',
    timeZoneId: 'America/Chicago',
  },
  '50622': {
    zip: '50622',
    city: 'Denver',
    state: 'IA',
    latitude: '42.669107',
    longitude: '-92.33416',
    timeZoneId: 'America/Chicago',
  },
  '50623': {
    zip: '50623',
    city: 'Dewar',
    state: 'IA',
    latitude: '42.527591',
    longitude: '-92.212957',
    timeZoneId: 'America/Chicago',
  },
  '50624': {
    zip: '50624',
    city: 'Dike',
    state: 'IA',
    latitude: '42.405761',
    longitude: '-92.684255',
    timeZoneId: 'America/Chicago',
  },
  '50625': {
    zip: '50625',
    city: 'Dumont',
    state: 'IA',
    latitude: '42.761396',
    longitude: '-92.980568',
    timeZoneId: 'America/Chicago',
  },
  '50626': {
    zip: '50626',
    city: 'Dunkerton',
    state: 'IA',
    latitude: '42.577243',
    longitude: '-92.168002',
    timeZoneId: 'America/Chicago',
  },
  '50627': {
    zip: '50627',
    city: 'Eldora',
    state: 'IA',
    latitude: '42.366323',
    longitude: '-93.095322',
    timeZoneId: 'America/Chicago',
  },
  '50628': {
    zip: '50628',
    city: 'Elma',
    state: 'IA',
    latitude: '43.245347',
    longitude: '-92.439097',
    timeZoneId: 'America/Chicago',
  },
  '50629': {
    zip: '50629',
    city: 'Fairbank',
    state: 'IA',
    latitude: '42.639336',
    longitude: '-92.0486',
    timeZoneId: 'America/Chicago',
  },
  '50630': {
    zip: '50630',
    city: 'Fredericksburg',
    state: 'IA',
    latitude: '42.968397',
    longitude: '-92.197281',
    timeZoneId: 'America/Chicago',
  },
  '50631': {
    zip: '50631',
    city: 'Frederika',
    state: 'IA',
    latitude: '42.801815',
    longitude: '-92.268512',
    timeZoneId: 'America/Chicago',
  },
  '50632': {
    zip: '50632',
    city: 'Garwin',
    state: 'IA',
    latitude: '42.095421',
    longitude: '-92.674244',
    timeZoneId: 'America/Chicago',
  },
  '50633': {
    zip: '50633',
    city: 'Geneva',
    state: 'IA',
    latitude: '42.676333',
    longitude: '-93.131752',
    timeZoneId: 'America/Chicago',
  },
  '50634': {
    zip: '50634',
    city: 'Gilbertville',
    state: 'IA',
    latitude: '42.4159',
    longitude: '-92.215683',
    timeZoneId: 'America/Chicago',
  },
  '50635': {
    zip: '50635',
    city: 'Gladbrook',
    state: 'IA',
    latitude: '42.18397',
    longitude: '-92.711709',
    timeZoneId: 'America/Chicago',
  },
  '50636': {
    zip: '50636',
    city: 'Greene',
    state: 'IA',
    latitude: '42.897631',
    longitude: '-92.805586',
    timeZoneId: 'America/Chicago',
  },
  '50638': {
    zip: '50638',
    city: 'Grundy Center',
    state: 'IA',
    latitude: '42.360271',
    longitude: '-92.773756',
    timeZoneId: 'America/Chicago',
  },
  '50641': {
    zip: '50641',
    city: 'Hazleton',
    state: 'IA',
    latitude: '42.617154',
    longitude: '-91.903815',
    timeZoneId: 'America/Chicago',
  },
  '50642': {
    zip: '50642',
    city: 'Holland',
    state: 'IA',
    latitude: '42.400663',
    longitude: '-92.800257',
    timeZoneId: 'America/Chicago',
  },
  '50643': {
    zip: '50643',
    city: 'Hudson',
    state: 'IA',
    latitude: '42.383057',
    longitude: '-92.461301',
    timeZoneId: 'America/Chicago',
  },
  '50644': {
    zip: '50644',
    city: 'Independence',
    state: 'IA',
    latitude: '42.468967',
    longitude: '-91.895783',
    timeZoneId: 'America/Chicago',
  },
  '50645': {
    zip: '50645',
    city: 'Ionia',
    state: 'IA',
    latitude: '43.04706',
    longitude: '-92.4852',
    timeZoneId: 'America/Chicago',
  },
  '50647': {
    zip: '50647',
    city: 'Janesville',
    state: 'IA',
    latitude: '42.632632',
    longitude: '-92.495481',
    timeZoneId: 'America/Chicago',
  },
  '50648': {
    zip: '50648',
    city: 'Jesup',
    state: 'IA',
    latitude: '42.4587',
    longitude: '-92.06316',
    timeZoneId: 'America/Chicago',
  },
  '50649': {
    zip: '50649',
    city: 'Kesley',
    state: 'IA',
    latitude: '42.687274',
    longitude: '-92.849624',
    timeZoneId: 'America/Chicago',
  },
  '50650': {
    zip: '50650',
    city: 'Lamont',
    state: 'IA',
    latitude: '42.600344',
    longitude: '-91.643904',
    timeZoneId: 'America/Chicago',
  },
  '50651': {
    zip: '50651',
    city: 'La Porte City',
    state: 'IA',
    latitude: '42.333783',
    longitude: '-92.194367',
    timeZoneId: 'America/Chicago',
  },
  '50652': {
    zip: '50652',
    city: 'Lincoln',
    state: 'IA',
    latitude: '42.265433',
    longitude: '-92.689775',
    timeZoneId: 'America/Chicago',
  },
  '50653': {
    zip: '50653',
    city: 'Marble Rock',
    state: 'IA',
    latitude: '42.96618',
    longitude: '-92.88096',
    timeZoneId: 'America/Chicago',
  },
  '50654': {
    zip: '50654',
    city: 'Masonville',
    state: 'IA',
    latitude: '42.510957',
    longitude: '-91.548967',
    timeZoneId: 'America/Chicago',
  },
  '50655': {
    zip: '50655',
    city: 'Maynard',
    state: 'IA',
    latitude: '42.774721',
    longitude: '-91.881839',
    timeZoneId: 'America/Chicago',
  },
  '50657': {
    zip: '50657',
    city: 'Morrison',
    state: 'IA',
    latitude: '42.370549',
    longitude: '-92.791105',
    timeZoneId: 'America/Chicago',
  },
  '50658': {
    zip: '50658',
    city: 'Nashua',
    state: 'IA',
    latitude: '42.949099',
    longitude: '-92.539914',
    timeZoneId: 'America/Chicago',
  },
  '50659': {
    zip: '50659',
    city: 'New Hampton',
    state: 'IA',
    latitude: '43.062151',
    longitude: '-92.314419',
    timeZoneId: 'America/Chicago',
  },
  '50660': {
    zip: '50660',
    city: 'New Hartford',
    state: 'IA',
    latitude: '42.585',
    longitude: '-92.649072',
    timeZoneId: 'America/Chicago',
  },
  '50661': {
    zip: '50661',
    city: 'North Washington',
    state: 'IA',
    latitude: '43.057551',
    longitude: '-92.312724',
    timeZoneId: 'America/Chicago',
  },
  '50662': {
    zip: '50662',
    city: 'Oelwein',
    state: 'IA',
    latitude: '42.678446',
    longitude: '-91.911924',
    timeZoneId: 'America/Chicago',
  },
  '50664': {
    zip: '50664',
    city: 'Oran',
    state: 'IA',
    latitude: '42.71059',
    longitude: '-91.877058',
    timeZoneId: 'America/Chicago',
  },
  '50665': {
    zip: '50665',
    city: 'Parkersburg',
    state: 'IA',
    latitude: '42.579549',
    longitude: '-92.788151',
    timeZoneId: 'America/Chicago',
  },
  '50666': {
    zip: '50666',
    city: 'Plainfield',
    state: 'IA',
    latitude: '42.845914',
    longitude: '-92.53546',
    timeZoneId: 'America/Chicago',
  },
  '50667': {
    zip: '50667',
    city: 'Raymond',
    state: 'IA',
    latitude: '42.469819',
    longitude: '-92.222425',
    timeZoneId: 'America/Chicago',
  },
  '50668': {
    zip: '50668',
    city: 'Readlyn',
    state: 'IA',
    latitude: '42.703803',
    longitude: '-92.225832',
    timeZoneId: 'America/Chicago',
  },
  '50669': {
    zip: '50669',
    city: 'Reinbeck',
    state: 'IA',
    latitude: '42.31698',
    longitude: '-92.571',
    timeZoneId: 'America/Chicago',
  },
  '50670': {
    zip: '50670',
    city: 'Shell Rock',
    state: 'IA',
    latitude: '42.710147',
    longitude: '-92.581298',
    timeZoneId: 'America/Chicago',
  },
  '50671': {
    zip: '50671',
    city: 'Stanley',
    state: 'IA',
    latitude: '42.641692',
    longitude: '-91.809785',
    timeZoneId: 'America/Chicago',
  },
  '50672': {
    zip: '50672',
    city: 'Steamboat Rock',
    state: 'IA',
    latitude: '42.407668',
    longitude: '-93.067862',
    timeZoneId: 'America/Chicago',
  },
  '50673': {
    zip: '50673',
    city: 'Stout',
    state: 'IA',
    latitude: '42.580592',
    longitude: '-92.758845',
    timeZoneId: 'America/Chicago',
  },
  '50674': {
    zip: '50674',
    city: 'Sumner',
    state: 'IA',
    latitude: '42.84999',
    longitude: '-92.100951',
    timeZoneId: 'America/Chicago',
  },
  '50675': {
    zip: '50675',
    city: 'Traer',
    state: 'IA',
    latitude: '42.19124',
    longitude: '-92.477228',
    timeZoneId: 'America/Chicago',
  },
  '50676': {
    zip: '50676',
    city: 'Tripoli',
    state: 'IA',
    latitude: '42.806451',
    longitude: '-92.259397',
    timeZoneId: 'America/Chicago',
  },
  '50677': {
    zip: '50677',
    city: 'Waverly',
    state: 'IA',
    latitude: '42.71622',
    longitude: '-92.42562',
    timeZoneId: 'America/Chicago',
  },
  '50680': {
    zip: '50680',
    city: 'Wellsburg',
    state: 'IA',
    latitude: '42.433838',
    longitude: '-92.927069',
    timeZoneId: 'America/Chicago',
  },
  '50681': {
    zip: '50681',
    city: 'Westgate',
    state: 'IA',
    latitude: '42.81924',
    longitude: '-92.024227',
    timeZoneId: 'America/Chicago',
  },
  '50682': {
    zip: '50682',
    city: 'Winthrop',
    state: 'IA',
    latitude: '42.474575',
    longitude: '-91.735596',
    timeZoneId: 'America/Chicago',
  },
  '50701': {
    zip: '50701',
    city: 'Waterloo',
    state: 'IA',
    latitude: '42.478356',
    longitude: '-92.367856',
    timeZoneId: 'America/Chicago',
  },
  '50702': {
    zip: '50702',
    city: 'Waterloo',
    state: 'IA',
    latitude: '42.470074',
    longitude: '-92.327921',
    timeZoneId: 'America/Chicago',
  },
  '50703': {
    zip: '50703',
    city: 'Waterloo',
    state: 'IA',
    latitude: '42.515044',
    longitude: '-92.320054',
    timeZoneId: 'America/Chicago',
  },
  '50704': {
    zip: '50704',
    city: 'Waterloo',
    state: 'IA',
    latitude: '42.527686',
    longitude: '-92.271674',
    timeZoneId: 'America/Chicago',
  },
  '50707': {
    zip: '50707',
    city: 'Evansdale',
    state: 'IA',
    latitude: '42.48288',
    longitude: '-92.27706',
    timeZoneId: 'America/Chicago',
  },
  '50801': {
    zip: '50801',
    city: 'Creston',
    state: 'IA',
    latitude: '41.05746',
    longitude: '-94.4034',
    timeZoneId: 'America/Chicago',
  },
  '50830': {
    zip: '50830',
    city: 'Afton',
    state: 'IA',
    latitude: '41.025666',
    longitude: '-94.197746',
    timeZoneId: 'America/Chicago',
  },
  '50831': {
    zip: '50831',
    city: 'Arispe',
    state: 'IA',
    latitude: '40.985255',
    longitude: '-94.071276',
    timeZoneId: 'America/Chicago',
  },
  '50833': {
    zip: '50833',
    city: 'Bedford',
    state: 'IA',
    latitude: '40.7058',
    longitude: '-94.7589',
    timeZoneId: 'America/Chicago',
  },
  '50835': {
    zip: '50835',
    city: 'Benton',
    state: 'IA',
    latitude: '40.728444',
    longitude: '-94.39683',
    timeZoneId: 'America/Chicago',
  },
  '50836': {
    zip: '50836',
    city: 'Blockton',
    state: 'IA',
    latitude: '40.648229',
    longitude: '-94.530783',
    timeZoneId: 'America/Chicago',
  },
  '50837': {
    zip: '50837',
    city: 'Bridgewater',
    state: 'IA',
    latitude: '41.179961',
    longitude: '-94.641678',
    timeZoneId: 'America/Chicago',
  },
  '50839': {
    zip: '50839',
    city: 'Carbon',
    state: 'IA',
    latitude: '40.94032',
    longitude: '-94.870974',
    timeZoneId: 'America/Chicago',
  },
  '50840': {
    zip: '50840',
    city: 'Clearfield',
    state: 'IA',
    latitude: '40.800557',
    longitude: '-94.478617',
    timeZoneId: 'America/Chicago',
  },
  '50841': {
    zip: '50841',
    city: 'Corning',
    state: 'IA',
    latitude: '41.0049',
    longitude: '-94.74288',
    timeZoneId: 'America/Chicago',
  },
  '50842': {
    zip: '50842',
    city: 'Cromwell',
    state: 'IA',
    latitude: '41.078174',
    longitude: '-94.391882',
    timeZoneId: 'America/Chicago',
  },
  '50843': {
    zip: '50843',
    city: 'Cumberland',
    state: 'IA',
    latitude: '41.24551',
    longitude: '-94.870675',
    timeZoneId: 'America/Chicago',
  },
  '50845': {
    zip: '50845',
    city: 'Diagonal',
    state: 'IA',
    latitude: '40.835955',
    longitude: '-94.358078',
    timeZoneId: 'America/Chicago',
  },
  '50846': {
    zip: '50846',
    city: 'Fontanelle',
    state: 'IA',
    latitude: '41.29349',
    longitude: '-94.562779',
    timeZoneId: 'America/Chicago',
  },
  '50847': {
    zip: '50847',
    city: 'Grant',
    state: 'IA',
    latitude: '41.112132',
    longitude: '-94.984793',
    timeZoneId: 'America/Chicago',
  },
  '50848': {
    zip: '50848',
    city: 'Gravity',
    state: 'IA',
    latitude: '40.754997',
    longitude: '-94.742542',
    timeZoneId: 'America/Chicago',
  },
  '50849': {
    zip: '50849',
    city: 'Greenfield',
    state: 'IA',
    latitude: '41.308687',
    longitude: '-94.46168',
    timeZoneId: 'America/Chicago',
  },
  '50851': {
    zip: '50851',
    city: 'Lenox',
    state: 'IA',
    latitude: '40.88208',
    longitude: '-94.560958',
    timeZoneId: 'America/Chicago',
  },
  '50853': {
    zip: '50853',
    city: 'Massena',
    state: 'IA',
    latitude: '41.253389',
    longitude: '-94.768718',
    timeZoneId: 'America/Chicago',
  },
  '50854': {
    zip: '50854',
    city: 'Mount Ayr',
    state: 'IA',
    latitude: '40.714841',
    longitude: '-94.236952',
    timeZoneId: 'America/Chicago',
  },
  '50857': {
    zip: '50857',
    city: 'Nodaway',
    state: 'IA',
    latitude: '40.943672',
    longitude: '-94.874173',
    timeZoneId: 'America/Chicago',
  },
  '50858': {
    zip: '50858',
    city: 'Orient',
    state: 'IA',
    latitude: '41.204707',
    longitude: '-94.422418',
    timeZoneId: 'America/Chicago',
  },
  '50859': {
    zip: '50859',
    city: 'Prescott',
    state: 'IA',
    latitude: '41.02669',
    longitude: '-94.526282',
    timeZoneId: 'America/Chicago',
  },
  '50860': {
    zip: '50860',
    city: 'Redding',
    state: 'IA',
    latitude: '40.605151',
    longitude: '-94.356644',
    timeZoneId: 'America/Chicago',
  },
  '50861': {
    zip: '50861',
    city: 'Shannon City',
    state: 'IA',
    latitude: '40.9395',
    longitude: '-94.239358',
    timeZoneId: 'America/Chicago',
  },
  '50862': {
    zip: '50862',
    city: 'Sharpsburg',
    state: 'IA',
    latitude: '40.801122',
    longitude: '-94.636076',
    timeZoneId: 'America/Chicago',
  },
  '50863': {
    zip: '50863',
    city: 'Tingley',
    state: 'IA',
    latitude: '40.851137',
    longitude: '-94.190589',
    timeZoneId: 'America/Chicago',
  },
  '50864': {
    zip: '50864',
    city: 'Villisca',
    state: 'IA',
    latitude: '40.926401',
    longitude: '-94.974732',
    timeZoneId: 'America/Chicago',
  },
  '50936': {
    zip: '50936',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.58204',
    longitude: '-93.638893',
    timeZoneId: 'America/Chicago',
  },
  '50940': {
    zip: '50940',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.585359',
    longitude: '-93.629011',
    timeZoneId: 'America/Chicago',
  },
  '50947': {
    zip: '50947',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582042',
    longitude: '-93.629331',
    timeZoneId: 'America/Chicago',
  },
  '50950': {
    zip: '50950',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.580273',
    longitude: '-93.62842',
    timeZoneId: 'America/Chicago',
  },
  '50980': {
    zip: '50980',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.581737',
    longitude: '-93.639001',
    timeZoneId: 'America/Chicago',
  },
  '50981': {
    zip: '50981',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582831',
    longitude: '-93.639369',
    timeZoneId: 'America/Chicago',
  },
  '50982': {
    zip: '50982',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582732',
    longitude: '-93.639236',
    timeZoneId: 'America/Chicago',
  },
  '50983': {
    zip: '50983',
    city: 'Des Moines',
    state: 'IA',
    latitude: '41.582136',
    longitude: '-93.638733',
    timeZoneId: 'America/Chicago',
  },
  '51001': {
    zip: '51001',
    city: 'Akron',
    state: 'IA',
    latitude: '42.83352',
    longitude: '-96.52956',
    timeZoneId: 'America/Chicago',
  },
  '51002': {
    zip: '51002',
    city: 'Alta',
    state: 'IA',
    latitude: '42.672649',
    longitude: '-95.304541',
    timeZoneId: 'America/Chicago',
  },
  '51003': {
    zip: '51003',
    city: 'Alton',
    state: 'IA',
    latitude: '42.987828',
    longitude: '-96.011384',
    timeZoneId: 'America/Chicago',
  },
  '51004': {
    zip: '51004',
    city: 'Anthon',
    state: 'IA',
    latitude: '42.38719',
    longitude: '-95.88329',
    timeZoneId: 'America/Chicago',
  },
  '51005': {
    zip: '51005',
    city: 'Aurelia',
    state: 'IA',
    latitude: '42.715939',
    longitude: '-95.435448',
    timeZoneId: 'America/Chicago',
  },
  '51006': {
    zip: '51006',
    city: 'Battle Creek',
    state: 'IA',
    latitude: '42.325465',
    longitude: '-95.590506',
    timeZoneId: 'America/Chicago',
  },
  '51007': {
    zip: '51007',
    city: 'Bronson',
    state: 'IA',
    latitude: '42.414816',
    longitude: '-96.213252',
    timeZoneId: 'America/Chicago',
  },
  '51008': {
    zip: '51008',
    city: 'Brunsville',
    state: 'IA',
    latitude: '42.809493',
    longitude: '-96.268603',
    timeZoneId: 'America/Chicago',
  },
  '51009': {
    zip: '51009',
    city: 'Calumet',
    state: 'IA',
    latitude: '42.945207',
    longitude: '-95.550225',
    timeZoneId: 'America/Chicago',
  },
  '51010': {
    zip: '51010',
    city: 'Castana',
    state: 'IA',
    latitude: '42.071125',
    longitude: '-95.911358',
    timeZoneId: 'America/Chicago',
  },
  '51011': {
    zip: '51011',
    city: 'Chatsworth',
    state: 'IA',
    latitude: '43.014778',
    longitude: '-96.431632',
    timeZoneId: 'America/Chicago',
  },
  '51012': {
    zip: '51012',
    city: 'Cherokee',
    state: 'IA',
    latitude: '42.749686',
    longitude: '-95.554902',
    timeZoneId: 'America/Chicago',
  },
  '51014': {
    zip: '51014',
    city: 'Cleghorn',
    state: 'IA',
    latitude: '42.80872',
    longitude: '-95.709164',
    timeZoneId: 'America/Chicago',
  },
  '51015': {
    zip: '51015',
    city: 'Climbing Hill',
    state: 'IA',
    latitude: '42.347865',
    longitude: '-96.093439',
    timeZoneId: 'America/Chicago',
  },
  '51016': {
    zip: '51016',
    city: 'Correctionville',
    state: 'IA',
    latitude: '42.478073',
    longitude: '-95.782566',
    timeZoneId: 'America/Chicago',
  },
  '51018': {
    zip: '51018',
    city: 'Cushing',
    state: 'IA',
    latitude: '42.464908',
    longitude: '-95.678858',
    timeZoneId: 'America/Chicago',
  },
  '51019': {
    zip: '51019',
    city: 'Danbury',
    state: 'IA',
    latitude: '42.261533',
    longitude: '-95.784346',
    timeZoneId: 'America/Chicago',
  },
  '51020': {
    zip: '51020',
    city: 'Galva',
    state: 'IA',
    latitude: '42.505637',
    longitude: '-95.414574',
    timeZoneId: 'America/Chicago',
  },
  '51022': {
    zip: '51022',
    city: 'Granville',
    state: 'IA',
    latitude: '42.983744',
    longitude: '-95.903558',
    timeZoneId: 'America/Chicago',
  },
  '51023': {
    zip: '51023',
    city: 'Hawarden',
    state: 'IA',
    latitude: '43.00066',
    longitude: '-96.480582',
    timeZoneId: 'America/Chicago',
  },
  '51024': {
    zip: '51024',
    city: 'Hinton',
    state: 'IA',
    latitude: '42.621194',
    longitude: '-96.295955',
    timeZoneId: 'America/Chicago',
  },
  '51025': {
    zip: '51025',
    city: 'Holstein',
    state: 'IA',
    latitude: '42.485024',
    longitude: '-95.545838',
    timeZoneId: 'America/Chicago',
  },
  '51026': {
    zip: '51026',
    city: 'Hornick',
    state: 'IA',
    latitude: '42.34908',
    longitude: '-96.06528',
    timeZoneId: 'America/Chicago',
  },
  '51027': {
    zip: '51027',
    city: 'Ireton',
    state: 'IA',
    latitude: '42.975452',
    longitude: '-96.317851',
    timeZoneId: 'America/Chicago',
  },
  '51028': {
    zip: '51028',
    city: 'Kingsley',
    state: 'IA',
    latitude: '42.631894',
    longitude: '-96.061191',
    timeZoneId: 'America/Chicago',
  },
  '51029': {
    zip: '51029',
    city: 'Larrabee',
    state: 'IA',
    latitude: '42.862632',
    longitude: '-95.544096',
    timeZoneId: 'America/Chicago',
  },
  '51030': {
    zip: '51030',
    city: 'Lawton',
    state: 'IA',
    latitude: '42.479685',
    longitude: '-96.185734',
    timeZoneId: 'America/Chicago',
  },
  '51031': {
    zip: '51031',
    city: 'Le Mars',
    state: 'IA',
    latitude: '42.78659',
    longitude: '-96.172617',
    timeZoneId: 'America/Chicago',
  },
  '51033': {
    zip: '51033',
    city: 'Linn Grove',
    state: 'IA',
    latitude: '42.886847',
    longitude: '-95.246989',
    timeZoneId: 'America/Chicago',
  },
  '51034': {
    zip: '51034',
    city: 'Mapleton',
    state: 'IA',
    latitude: '42.160798',
    longitude: '-95.806022',
    timeZoneId: 'America/Chicago',
  },
  '51035': {
    zip: '51035',
    city: 'Marcus',
    state: 'IA',
    latitude: '42.819666',
    longitude: '-95.803987',
    timeZoneId: 'America/Chicago',
  },
  '51036': {
    zip: '51036',
    city: 'Maurice',
    state: 'IA',
    latitude: '42.964551',
    longitude: '-96.180988',
    timeZoneId: 'America/Chicago',
  },
  '51037': {
    zip: '51037',
    city: 'Meriden',
    state: 'IA',
    latitude: '42.794769',
    longitude: '-95.632212',
    timeZoneId: 'America/Chicago',
  },
  '51038': {
    zip: '51038',
    city: 'Merrill',
    state: 'IA',
    latitude: '42.724946',
    longitude: '-96.248252',
    timeZoneId: 'America/Chicago',
  },
  '51039': {
    zip: '51039',
    city: 'Moville',
    state: 'IA',
    latitude: '42.489096',
    longitude: '-96.070229',
    timeZoneId: 'America/Chicago',
  },
  '51040': {
    zip: '51040',
    city: 'Onawa',
    state: 'IA',
    latitude: '42.028935',
    longitude: '-96.098207',
    timeZoneId: 'America/Chicago',
  },
  '51041': {
    zip: '51041',
    city: 'Orange City',
    state: 'IA',
    latitude: '43.001764',
    longitude: '-96.058049',
    timeZoneId: 'America/Chicago',
  },
  '51044': {
    zip: '51044',
    city: 'Oto',
    state: 'IA',
    latitude: '42.281336',
    longitude: '-95.893624',
    timeZoneId: 'America/Chicago',
  },
  '51045': {
    zip: '51045',
    city: 'Oyens',
    state: 'IA',
    latitude: '42.822848',
    longitude: '-96.057827',
    timeZoneId: 'America/Chicago',
  },
  '51046': {
    zip: '51046',
    city: 'Paullina',
    state: 'IA',
    latitude: '42.97458',
    longitude: '-95.7063',
    timeZoneId: 'America/Chicago',
  },
  '51047': {
    zip: '51047',
    city: 'Peterson',
    state: 'IA',
    latitude: '42.919009',
    longitude: '-95.339599',
    timeZoneId: 'America/Chicago',
  },
  '51048': {
    zip: '51048',
    city: 'Pierson',
    state: 'IA',
    latitude: '42.543373',
    longitude: '-95.86785',
    timeZoneId: 'America/Chicago',
  },
  '51049': {
    zip: '51049',
    city: 'Quimby',
    state: 'IA',
    latitude: '42.627623',
    longitude: '-95.641152',
    timeZoneId: 'America/Chicago',
  },
  '51050': {
    zip: '51050',
    city: 'Remsen',
    state: 'IA',
    latitude: '42.813861',
    longitude: '-95.97132',
    timeZoneId: 'America/Chicago',
  },
  '51051': {
    zip: '51051',
    city: 'Rodney',
    state: 'IA',
    latitude: '42.20448',
    longitude: '-95.951601',
    timeZoneId: 'America/Chicago',
  },
  '51052': {
    zip: '51052',
    city: 'Salix',
    state: 'IA',
    latitude: '42.304178',
    longitude: '-96.275313',
    timeZoneId: 'America/Chicago',
  },
  '51053': {
    zip: '51053',
    city: 'Schaller',
    state: 'IA',
    latitude: '42.497581',
    longitude: '-95.294879',
    timeZoneId: 'America/Chicago',
  },
  '51054': {
    zip: '51054',
    city: 'Sergeant Bluff',
    state: 'IA',
    latitude: '42.407067',
    longitude: '-96.357402',
    timeZoneId: 'America/Chicago',
  },
  '51055': {
    zip: '51055',
    city: 'Sloan',
    state: 'IA',
    latitude: '42.236796',
    longitude: '-96.226638',
    timeZoneId: 'America/Chicago',
  },
  '51056': {
    zip: '51056',
    city: 'Smithland',
    state: 'IA',
    latitude: '42.225253',
    longitude: '-95.934391',
    timeZoneId: 'America/Chicago',
  },
  '51058': {
    zip: '51058',
    city: 'Sutherland',
    state: 'IA',
    latitude: '42.970337',
    longitude: '-95.498504',
    timeZoneId: 'America/Chicago',
  },
  '51060': {
    zip: '51060',
    city: 'Ute',
    state: 'IA',
    latitude: '42.050911',
    longitude: '-95.707888',
    timeZoneId: 'America/Chicago',
  },
  '51061': {
    zip: '51061',
    city: 'Washta',
    state: 'IA',
    latitude: '42.577838',
    longitude: '-95.726145',
    timeZoneId: 'America/Chicago',
  },
  '51062': {
    zip: '51062',
    city: 'Westfield',
    state: 'IA',
    latitude: '42.710245',
    longitude: '-96.486217',
    timeZoneId: 'America/Chicago',
  },
  '51063': {
    zip: '51063',
    city: 'Whiting',
    state: 'IA',
    latitude: '42.126223',
    longitude: '-96.15239',
    timeZoneId: 'America/Chicago',
  },
  '51101': {
    zip: '51101',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.494368',
    longitude: '-96.395068',
    timeZoneId: 'America/Chicago',
  },
  '51102': {
    zip: '51102',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.417726',
    longitude: '-96.339615',
    timeZoneId: 'America/Chicago',
  },
  '51103': {
    zip: '51103',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.507089',
    longitude: '-96.433472',
    timeZoneId: 'America/Chicago',
  },
  '51104': {
    zip: '51104',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.522094',
    longitude: '-96.384395',
    timeZoneId: 'America/Chicago',
  },
  '51105': {
    zip: '51105',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.503888',
    longitude: '-96.372206',
    timeZoneId: 'America/Chicago',
  },
  '51106': {
    zip: '51106',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.468321',
    longitude: '-96.358319',
    timeZoneId: 'America/Chicago',
  },
  '51108': {
    zip: '51108',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.55092',
    longitude: '-96.39024',
    timeZoneId: 'America/Chicago',
  },
  '51109': {
    zip: '51109',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.515558',
    longitude: '-96.478357',
    timeZoneId: 'America/Chicago',
  },
  '51111': {
    zip: '51111',
    city: 'Sioux City',
    state: 'IA',
    latitude: '42.425511',
    longitude: '-96.375133',
    timeZoneId: 'America/Chicago',
  },
  '51201': {
    zip: '51201',
    city: 'Sheldon',
    state: 'IA',
    latitude: '43.184792',
    longitude: '-95.847789',
    timeZoneId: 'America/Chicago',
  },
  '51230': {
    zip: '51230',
    city: 'Alvord',
    state: 'IA',
    latitude: '43.303422',
    longitude: '-96.284316',
    timeZoneId: 'America/Chicago',
  },
  '51231': {
    zip: '51231',
    city: 'Archer',
    state: 'IA',
    latitude: '43.115395',
    longitude: '-95.745834',
    timeZoneId: 'America/Chicago',
  },
  '51232': {
    zip: '51232',
    city: 'Ashton',
    state: 'IA',
    latitude: '43.31214',
    longitude: '-95.78466',
    timeZoneId: 'America/Chicago',
  },
  '51234': {
    zip: '51234',
    city: 'Boyden',
    state: 'IA',
    latitude: '43.194052',
    longitude: '-96.00416',
    timeZoneId: 'America/Chicago',
  },
  '51235': {
    zip: '51235',
    city: 'Doon',
    state: 'IA',
    latitude: '43.276098',
    longitude: '-96.229124',
    timeZoneId: 'America/Chicago',
  },
  '51237': {
    zip: '51237',
    city: 'George',
    state: 'IA',
    latitude: '43.346352',
    longitude: '-96.00477',
    timeZoneId: 'America/Chicago',
  },
  '51238': {
    zip: '51238',
    city: 'Hospers',
    state: 'IA',
    latitude: '43.069984',
    longitude: '-95.904488',
    timeZoneId: 'America/Chicago',
  },
  '51239': {
    zip: '51239',
    city: 'Hull',
    state: 'IA',
    latitude: '43.192568',
    longitude: '-96.130531',
    timeZoneId: 'America/Chicago',
  },
  '51240': {
    zip: '51240',
    city: 'Inwood',
    state: 'IA',
    latitude: '43.30541',
    longitude: '-96.429125',
    timeZoneId: 'America/Chicago',
  },
  '51241': {
    zip: '51241',
    city: 'Larchwood',
    state: 'IA',
    latitude: '43.45349',
    longitude: '-96.435021',
    timeZoneId: 'America/Chicago',
  },
  '51242': {
    zip: '51242',
    city: 'Lester',
    state: 'IA',
    latitude: '43.437959',
    longitude: '-96.331648',
    timeZoneId: 'America/Chicago',
  },
  '51243': {
    zip: '51243',
    city: 'Little Rock',
    state: 'IA',
    latitude: '43.467838',
    longitude: '-95.917546',
    timeZoneId: 'America/Chicago',
  },
  '51244': {
    zip: '51244',
    city: 'Matlock',
    state: 'IA',
    latitude: '43.215655',
    longitude: '-95.978975',
    timeZoneId: 'America/Chicago',
  },
  '51245': {
    zip: '51245',
    city: 'Primghar',
    state: 'IA',
    latitude: '43.08336',
    longitude: '-95.6412',
    timeZoneId: 'America/Chicago',
  },
  '51246': {
    zip: '51246',
    city: 'Rock Rapids',
    state: 'IA',
    latitude: '43.42206',
    longitude: '-96.24804',
    timeZoneId: 'America/Chicago',
  },
  '51247': {
    zip: '51247',
    city: 'Rock Valley',
    state: 'IA',
    latitude: '43.205229',
    longitude: '-96.291066',
    timeZoneId: 'America/Chicago',
  },
  '51248': {
    zip: '51248',
    city: 'Sanborn',
    state: 'IA',
    latitude: '43.181515',
    longitude: '-95.654995',
    timeZoneId: 'America/Chicago',
  },
  '51249': {
    zip: '51249',
    city: 'Sibley',
    state: 'IA',
    latitude: '43.40391',
    longitude: '-95.746853',
    timeZoneId: 'America/Chicago',
  },
  '51250': {
    zip: '51250',
    city: 'Sioux Center',
    state: 'IA',
    latitude: '43.079162',
    longitude: '-96.175074',
    timeZoneId: 'America/Chicago',
  },
  '51301': {
    zip: '51301',
    city: 'Spencer',
    state: 'IA',
    latitude: '43.144591',
    longitude: '-95.144154',
    timeZoneId: 'America/Chicago',
  },
  '51331': {
    zip: '51331',
    city: 'Arnolds Park',
    state: 'IA',
    latitude: '43.364605',
    longitude: '-95.133522',
    timeZoneId: 'America/Chicago',
  },
  '51333': {
    zip: '51333',
    city: 'Dickens',
    state: 'IA',
    latitude: '43.170368',
    longitude: '-94.970954',
    timeZoneId: 'America/Chicago',
  },
  '51334': {
    zip: '51334',
    city: 'Estherville',
    state: 'IA',
    latitude: '43.40754',
    longitude: '-94.82412',
    timeZoneId: 'America/Chicago',
  },
  '51338': {
    zip: '51338',
    city: 'Everly',
    state: 'IA',
    latitude: '43.158726',
    longitude: '-95.327253',
    timeZoneId: 'America/Chicago',
  },
  '51340': {
    zip: '51340',
    city: 'Fostoria',
    state: 'IA',
    latitude: '43.212453',
    longitude: '-95.210212',
    timeZoneId: 'America/Chicago',
  },
  '51341': {
    zip: '51341',
    city: 'Gillett Grove',
    state: 'IA',
    latitude: '43.037826',
    longitude: '-95.032004',
    timeZoneId: 'America/Chicago',
  },
  '51342': {
    zip: '51342',
    city: 'Graettinger',
    state: 'IA',
    latitude: '43.237283',
    longitude: '-94.753901',
    timeZoneId: 'America/Chicago',
  },
  '51343': {
    zip: '51343',
    city: 'Greenville',
    state: 'IA',
    latitude: '43.041318',
    longitude: '-95.032585',
    timeZoneId: 'America/Chicago',
  },
  '51345': {
    zip: '51345',
    city: 'Harris',
    state: 'IA',
    latitude: '43.444748',
    longitude: '-95.434345',
    timeZoneId: 'America/Chicago',
  },
  '51346': {
    zip: '51346',
    city: 'Hartley',
    state: 'IA',
    latitude: '43.183529',
    longitude: '-95.478433',
    timeZoneId: 'America/Chicago',
  },
  '51347': {
    zip: '51347',
    city: 'Lake Park',
    state: 'IA',
    latitude: '43.448381',
    longitude: '-95.331102',
    timeZoneId: 'America/Chicago',
  },
  '51350': {
    zip: '51350',
    city: 'Melvin',
    state: 'IA',
    latitude: '43.278016',
    longitude: '-95.565094',
    timeZoneId: 'America/Chicago',
  },
  '51351': {
    zip: '51351',
    city: 'Milford',
    state: 'IA',
    latitude: '43.32348',
    longitude: '-95.16966',
    timeZoneId: 'America/Chicago',
  },
  '51354': {
    zip: '51354',
    city: 'Ocheyedan',
    state: 'IA',
    latitude: '43.415978',
    longitude: '-95.533305',
    timeZoneId: 'America/Chicago',
  },
  '51355': {
    zip: '51355',
    city: 'Okoboji',
    state: 'IA',
    latitude: '43.379236',
    longitude: '-95.163787',
    timeZoneId: 'America/Chicago',
  },
  '51357': {
    zip: '51357',
    city: 'Royal',
    state: 'IA',
    latitude: '43.03884',
    longitude: '-95.23698',
    timeZoneId: 'America/Chicago',
  },
  '51358': {
    zip: '51358',
    city: 'Ruthven',
    state: 'IA',
    latitude: '43.131401',
    longitude: '-94.899857',
    timeZoneId: 'America/Chicago',
  },
  '51360': {
    zip: '51360',
    city: 'Spirit Lake',
    state: 'IA',
    latitude: '43.41942',
    longitude: '-95.13288',
    timeZoneId: 'America/Chicago',
  },
  '51363': {
    zip: '51363',
    city: 'Superior',
    state: 'IA',
    latitude: '43.462491',
    longitude: '-94.970139',
    timeZoneId: 'America/Chicago',
  },
  '51364': {
    zip: '51364',
    city: 'Terril',
    state: 'IA',
    latitude: '43.299433',
    longitude: '-94.96997',
    timeZoneId: 'America/Chicago',
  },
  '51365': {
    zip: '51365',
    city: 'Wallingford',
    state: 'IA',
    latitude: '43.296934',
    longitude: '-94.854337',
    timeZoneId: 'America/Chicago',
  },
  '51366': {
    zip: '51366',
    city: 'Webb',
    state: 'IA',
    latitude: '43.063795',
    longitude: '-95.283539',
    timeZoneId: 'America/Chicago',
  },
  '51401': {
    zip: '51401',
    city: 'Carroll',
    state: 'IA',
    latitude: '42.04722',
    longitude: '-94.86516',
    timeZoneId: 'America/Chicago',
  },
  '51430': {
    zip: '51430',
    city: 'Arcadia',
    state: 'IA',
    latitude: '42.090226',
    longitude: '-95.044867',
    timeZoneId: 'America/Chicago',
  },
  '51431': {
    zip: '51431',
    city: 'Arthur',
    state: 'IA',
    latitude: '42.336733',
    longitude: '-95.34498',
    timeZoneId: 'America/Chicago',
  },
  '51432': {
    zip: '51432',
    city: 'Aspinwall',
    state: 'IA',
    latitude: '41.911651',
    longitude: '-95.135035',
    timeZoneId: 'America/Chicago',
  },
  '51433': {
    zip: '51433',
    city: 'Auburn',
    state: 'IA',
    latitude: '42.2712',
    longitude: '-94.89174',
    timeZoneId: 'America/Chicago',
  },
  '51436': {
    zip: '51436',
    city: 'Breda',
    state: 'IA',
    latitude: '42.185428',
    longitude: '-94.974505',
    timeZoneId: 'America/Chicago',
  },
  '51439': {
    zip: '51439',
    city: 'Charter Oak',
    state: 'IA',
    latitude: '42.067724',
    longitude: '-95.591441',
    timeZoneId: 'America/Chicago',
  },
  '51440': {
    zip: '51440',
    city: 'Dedham',
    state: 'IA',
    latitude: '41.908376',
    longitude: '-94.820201',
    timeZoneId: 'America/Chicago',
  },
  '51441': {
    zip: '51441',
    city: 'Deloit',
    state: 'IA',
    latitude: '42.094805',
    longitude: '-95.343672',
    timeZoneId: 'America/Chicago',
  },
  '51442': {
    zip: '51442',
    city: 'Denison',
    state: 'IA',
    latitude: '42.021358',
    longitude: '-95.346531',
    timeZoneId: 'America/Chicago',
  },
  '51443': {
    zip: '51443',
    city: 'Glidden',
    state: 'IA',
    latitude: '42.060616',
    longitude: '-94.729301',
    timeZoneId: 'America/Chicago',
  },
  '51444': {
    zip: '51444',
    city: 'Halbur',
    state: 'IA',
    latitude: '42.00827',
    longitude: '-94.973752',
    timeZoneId: 'America/Chicago',
  },
  '51445': {
    zip: '51445',
    city: 'Ida Grove',
    state: 'IA',
    latitude: '42.341791',
    longitude: '-95.465297',
    timeZoneId: 'America/Chicago',
  },
  '51446': {
    zip: '51446',
    city: 'Irwin',
    state: 'IA',
    latitude: '41.79112',
    longitude: '-95.210119',
    timeZoneId: 'America/Chicago',
  },
  '51447': {
    zip: '51447',
    city: 'Kirkman',
    state: 'IA',
    latitude: '41.729851',
    longitude: '-95.264767',
    timeZoneId: 'America/Chicago',
  },
  '51448': {
    zip: '51448',
    city: 'Kiron',
    state: 'IA',
    latitude: '42.18804',
    longitude: '-95.31786',
    timeZoneId: 'America/Chicago',
  },
  '51449': {
    zip: '51449',
    city: 'Lake City',
    state: 'IA',
    latitude: '42.271354',
    longitude: '-94.735241',
    timeZoneId: 'America/Chicago',
  },
  '51450': {
    zip: '51450',
    city: 'Lake View',
    state: 'IA',
    latitude: '42.30467',
    longitude: '-95.045383',
    timeZoneId: 'America/Chicago',
  },
  '51451': {
    zip: '51451',
    city: 'Lanesboro',
    state: 'IA',
    latitude: '42.184141',
    longitude: '-94.694018',
    timeZoneId: 'America/Chicago',
  },
  '51452': {
    zip: '51452',
    city: 'Lidderdale',
    state: 'IA',
    latitude: '42.125824',
    longitude: '-94.783663',
    timeZoneId: 'America/Chicago',
  },
  '51453': {
    zip: '51453',
    city: 'Lohrville',
    state: 'IA',
    latitude: '42.272104',
    longitude: '-94.540172',
    timeZoneId: 'America/Chicago',
  },
  '51454': {
    zip: '51454',
    city: 'Manilla',
    state: 'IA',
    latitude: '41.903541',
    longitude: '-95.249649',
    timeZoneId: 'America/Chicago',
  },
  '51455': {
    zip: '51455',
    city: 'Manning',
    state: 'IA',
    latitude: '41.90735',
    longitude: '-95.064387',
    timeZoneId: 'America/Chicago',
  },
  '51458': {
    zip: '51458',
    city: 'Odebolt',
    state: 'IA',
    latitude: '42.310153',
    longitude: '-95.249138',
    timeZoneId: 'America/Chicago',
  },
  '51459': {
    zip: '51459',
    city: 'Ralston',
    state: 'IA',
    latitude: '42.078806',
    longitude: '-94.567738',
    timeZoneId: 'America/Chicago',
  },
  '51460': {
    zip: '51460',
    city: 'Ricketts',
    state: 'IA',
    latitude: '42.130171',
    longitude: '-95.573865',
    timeZoneId: 'America/Chicago',
  },
  '51461': {
    zip: '51461',
    city: 'Schleswig',
    state: 'IA',
    latitude: '42.163264',
    longitude: '-95.435436',
    timeZoneId: 'America/Chicago',
  },
  '51462': {
    zip: '51462',
    city: 'Scranton',
    state: 'IA',
    latitude: '42.023571',
    longitude: '-94.542782',
    timeZoneId: 'America/Chicago',
  },
  '51463': {
    zip: '51463',
    city: 'Templeton',
    state: 'IA',
    latitude: '41.908678',
    longitude: '-94.917539',
    timeZoneId: 'America/Chicago',
  },
  '51465': {
    zip: '51465',
    city: 'Vail',
    state: 'IA',
    latitude: '42.064094',
    longitude: '-95.203183',
    timeZoneId: 'America/Chicago',
  },
  '51466': {
    zip: '51466',
    city: 'Wall Lake',
    state: 'IA',
    latitude: '42.271327',
    longitude: '-95.090519',
    timeZoneId: 'America/Chicago',
  },
  '51467': {
    zip: '51467',
    city: 'Westside',
    state: 'IA',
    latitude: '42.074661',
    longitude: '-95.101101',
    timeZoneId: 'America/Chicago',
  },
  '51501': {
    zip: '51501',
    city: 'Council Bluffs',
    state: 'IA',
    latitude: '41.254887',
    longitude: '-95.852753',
    timeZoneId: 'America/Chicago',
  },
  '51502': {
    zip: '51502',
    city: 'Council Bluffs',
    state: 'IA',
    latitude: '41.261393',
    longitude: '-95.849876',
    timeZoneId: 'America/Chicago',
  },
  '51503': {
    zip: '51503',
    city: 'Council Bluffs',
    state: 'IA',
    latitude: '41.26336',
    longitude: '-95.819074',
    timeZoneId: 'America/Chicago',
  },
  '51510': {
    zip: '51510',
    city: 'Carter Lake',
    state: 'IA',
    latitude: '41.291727',
    longitude: '-95.910128',
    timeZoneId: 'America/Chicago',
  },
  '51520': {
    zip: '51520',
    city: 'Arion',
    state: 'IA',
    latitude: '41.949169',
    longitude: '-95.463302',
    timeZoneId: 'America/Chicago',
  },
  '51521': {
    zip: '51521',
    city: 'Avoca',
    state: 'IA',
    latitude: '41.477491',
    longitude: '-95.339627',
    timeZoneId: 'America/Chicago',
  },
  '51523': {
    zip: '51523',
    city: 'Blencoe',
    state: 'IA',
    latitude: '41.932467',
    longitude: '-96.082692',
    timeZoneId: 'America/Chicago',
  },
  '51525': {
    zip: '51525',
    city: 'Carson',
    state: 'IA',
    latitude: '41.238041',
    longitude: '-95.417441',
    timeZoneId: 'America/Chicago',
  },
  '51526': {
    zip: '51526',
    city: 'Crescent',
    state: 'IA',
    latitude: '41.364771',
    longitude: '-95.850441',
    timeZoneId: 'America/Chicago',
  },
  '51527': {
    zip: '51527',
    city: 'Defiance',
    state: 'IA',
    latitude: '41.827307',
    longitude: '-95.341633',
    timeZoneId: 'America/Chicago',
  },
  '51528': {
    zip: '51528',
    city: 'Dow City',
    state: 'IA',
    latitude: '41.925696',
    longitude: '-95.476441',
    timeZoneId: 'America/Chicago',
  },
  '51529': {
    zip: '51529',
    city: 'Dunlap',
    state: 'IA',
    latitude: '41.852301',
    longitude: '-95.607663',
    timeZoneId: 'America/Chicago',
  },
  '51530': {
    zip: '51530',
    city: 'Earling',
    state: 'IA',
    latitude: '41.772993',
    longitude: '-95.417996',
    timeZoneId: 'America/Chicago',
  },
  '51531': {
    zip: '51531',
    city: 'Elk Horn',
    state: 'IA',
    latitude: '41.595124',
    longitude: '-95.06043',
    timeZoneId: 'America/Chicago',
  },
  '51532': {
    zip: '51532',
    city: 'Elliott',
    state: 'IA',
    latitude: '41.152713',
    longitude: '-95.162777',
    timeZoneId: 'America/Chicago',
  },
  '51533': {
    zip: '51533',
    city: 'Emerson',
    state: 'IA',
    latitude: '41.018892',
    longitude: '-95.403931',
    timeZoneId: 'America/Chicago',
  },
  '51534': {
    zip: '51534',
    city: 'Glenwood',
    state: 'IA',
    latitude: '41.047534',
    longitude: '-95.742473',
    timeZoneId: 'America/Chicago',
  },
  '51535': {
    zip: '51535',
    city: 'Griswold',
    state: 'IA',
    latitude: '41.236282',
    longitude: '-95.139293',
    timeZoneId: 'America/Chicago',
  },
  '51536': {
    zip: '51536',
    city: 'Hancock',
    state: 'IA',
    latitude: '41.389497',
    longitude: '-95.357103',
    timeZoneId: 'America/Chicago',
  },
  '51537': {
    zip: '51537',
    city: 'Harlan',
    state: 'IA',
    latitude: '41.651801',
    longitude: '-95.327571',
    timeZoneId: 'America/Chicago',
  },
  '51540': {
    zip: '51540',
    city: 'Hastings',
    state: 'IA',
    latitude: '41.019331',
    longitude: '-95.50091',
    timeZoneId: 'America/Chicago',
  },
  '51541': {
    zip: '51541',
    city: 'Henderson',
    state: 'IA',
    latitude: '41.141456',
    longitude: '-95.431242',
    timeZoneId: 'America/Chicago',
  },
  '51542': {
    zip: '51542',
    city: 'Honey Creek',
    state: 'IA',
    latitude: '41.47699',
    longitude: '-95.920407',
    timeZoneId: 'America/Chicago',
  },
  '51543': {
    zip: '51543',
    city: 'Kimballton',
    state: 'IA',
    latitude: '41.644191',
    longitude: '-95.032404',
    timeZoneId: 'America/Chicago',
  },
  '51544': {
    zip: '51544',
    city: 'Lewis',
    state: 'IA',
    latitude: '41.304611',
    longitude: '-95.086673',
    timeZoneId: 'America/Chicago',
  },
  '51545': {
    zip: '51545',
    city: 'Little Sioux',
    state: 'IA',
    latitude: '41.807064',
    longitude: '-96.024597',
    timeZoneId: 'America/Chicago',
  },
  '51546': {
    zip: '51546',
    city: 'Logan',
    state: 'IA',
    latitude: '41.648696',
    longitude: '-95.789131',
    timeZoneId: 'America/Chicago',
  },
  '51548': {
    zip: '51548',
    city: 'Mc Clelland',
    state: 'IA',
    latitude: '41.303365',
    longitude: '-95.672529',
    timeZoneId: 'America/Chicago',
  },
  '51549': {
    zip: '51549',
    city: 'Macedonia',
    state: 'IA',
    latitude: '41.189072',
    longitude: '-95.425145',
    timeZoneId: 'America/Chicago',
  },
  '51550': {
    zip: '51550',
    city: 'Magnolia',
    state: 'IA',
    latitude: '41.643056',
    longitude: '-95.788611',
    timeZoneId: 'America/Chicago',
  },
  '51551': {
    zip: '51551',
    city: 'Malvern',
    state: 'IA',
    latitude: '41.003006',
    longitude: '-95.585413',
    timeZoneId: 'America/Chicago',
  },
  '51552': {
    zip: '51552',
    city: 'Marne',
    state: 'IA',
    latitude: '41.450556',
    longitude: '-95.107889',
    timeZoneId: 'America/Chicago',
  },
  '51553': {
    zip: '51553',
    city: 'Minden',
    state: 'IA',
    latitude: '41.467372',
    longitude: '-95.54417',
    timeZoneId: 'America/Chicago',
  },
  '51554': {
    zip: '51554',
    city: 'Mineola',
    state: 'IA',
    latitude: '41.142317',
    longitude: '-95.692775',
    timeZoneId: 'America/Chicago',
  },
  '51555': {
    zip: '51555',
    city: 'Missouri Valley',
    state: 'IA',
    latitude: '41.562303',
    longitude: '-95.890364',
    timeZoneId: 'America/Chicago',
  },
  '51556': {
    zip: '51556',
    city: 'Modale',
    state: 'IA',
    latitude: '41.618555',
    longitude: '-96.00907',
    timeZoneId: 'America/Chicago',
  },
  '51557': {
    zip: '51557',
    city: 'Mondamin',
    state: 'IA',
    latitude: '41.710092',
    longitude: '-96.024526',
    timeZoneId: 'America/Chicago',
  },
  '51558': {
    zip: '51558',
    city: 'Moorhead',
    state: 'IA',
    latitude: '41.928152',
    longitude: '-95.852604',
    timeZoneId: 'America/Chicago',
  },
  '51559': {
    zip: '51559',
    city: 'Neola',
    state: 'IA',
    latitude: '41.455145',
    longitude: '-95.617157',
    timeZoneId: 'America/Chicago',
  },
  '51560': {
    zip: '51560',
    city: 'Oakland',
    state: 'IA',
    latitude: '41.306383',
    longitude: '-95.392741',
    timeZoneId: 'America/Chicago',
  },
  '51561': {
    zip: '51561',
    city: 'Pacific Junction',
    state: 'IA',
    latitude: '41.020858',
    longitude: '-95.79767',
    timeZoneId: 'America/Chicago',
  },
  '51562': {
    zip: '51562',
    city: 'Panama',
    state: 'IA',
    latitude: '41.727122',
    longitude: '-95.474308',
    timeZoneId: 'America/Chicago',
  },
  '51563': {
    zip: '51563',
    city: 'Persia',
    state: 'IA',
    latitude: '41.578558',
    longitude: '-95.571262',
    timeZoneId: 'America/Chicago',
  },
  '51564': {
    zip: '51564',
    city: 'Pisgah',
    state: 'IA',
    latitude: '41.833133',
    longitude: '-95.922603',
    timeZoneId: 'America/Chicago',
  },
  '51565': {
    zip: '51565',
    city: 'Portsmouth',
    state: 'IA',
    latitude: '41.646859',
    longitude: '-95.500172',
    timeZoneId: 'America/Chicago',
  },
  '51566': {
    zip: '51566',
    city: 'Red Oak',
    state: 'IA',
    latitude: '41.009513',
    longitude: '-95.223391',
    timeZoneId: 'America/Chicago',
  },
  '51570': {
    zip: '51570',
    city: 'Shelby',
    state: 'IA',
    latitude: '41.519002',
    longitude: '-95.452367',
    timeZoneId: 'America/Chicago',
  },
  '51571': {
    zip: '51571',
    city: 'Silver City',
    state: 'IA',
    latitude: '41.113806',
    longitude: '-95.633702',
    timeZoneId: 'America/Chicago',
  },
  '51572': {
    zip: '51572',
    city: 'Soldier',
    state: 'IA',
    latitude: '41.984662',
    longitude: '-95.78217',
    timeZoneId: 'America/Chicago',
  },
  '51573': {
    zip: '51573',
    city: 'Stanton',
    state: 'IA',
    latitude: '40.980645',
    longitude: '-95.10105',
    timeZoneId: 'America/Chicago',
  },
  '51575': {
    zip: '51575',
    city: 'Treynor',
    state: 'IA',
    latitude: '41.230035',
    longitude: '-95.60664',
    timeZoneId: 'America/Chicago',
  },
  '51576': {
    zip: '51576',
    city: 'Underwood',
    state: 'IA',
    latitude: '41.317278',
    longitude: '-95.708118',
    timeZoneId: 'America/Chicago',
  },
  '51577': {
    zip: '51577',
    city: 'Walnut',
    state: 'IA',
    latitude: '41.478376',
    longitude: '-95.219305',
    timeZoneId: 'America/Chicago',
  },
  '51578': {
    zip: '51578',
    city: 'Westphalia',
    state: 'IA',
    latitude: '41.718477',
    longitude: '-95.395176',
    timeZoneId: 'America/Chicago',
  },
  '51579': {
    zip: '51579',
    city: 'Woodbine',
    state: 'IA',
    latitude: '41.746152',
    longitude: '-95.697814',
    timeZoneId: 'America/Chicago',
  },
  '51591': {
    zip: '51591',
    city: 'Red Oak',
    state: 'IA',
    latitude: '40.978361',
    longitude: '-95.257753',
    timeZoneId: 'America/Chicago',
  },
  '51593': {
    zip: '51593',
    city: 'Harlan',
    state: 'IA',
    latitude: '41.65809',
    longitude: '-95.31861',
    timeZoneId: 'America/Chicago',
  },
  '51601': {
    zip: '51601',
    city: 'Shenandoah',
    state: 'IA',
    latitude: '40.758944',
    longitude: '-95.370427',
    timeZoneId: 'America/Chicago',
  },
  '51603': {
    zip: '51603',
    city: 'Shenandoah',
    state: 'IA',
    latitude: '40.766701',
    longitude: '-95.371715',
    timeZoneId: 'America/Chicago',
  },
  '51630': {
    zip: '51630',
    city: 'Blanchard',
    state: 'IA',
    latitude: '40.600218',
    longitude: '-95.208207',
    timeZoneId: 'America/Chicago',
  },
  '51631': {
    zip: '51631',
    city: 'Braddyville',
    state: 'IA',
    latitude: '40.610998',
    longitude: '-94.969146',
    timeZoneId: 'America/Chicago',
  },
  '51632': {
    zip: '51632',
    city: 'Clarinda',
    state: 'IA',
    latitude: '40.736141',
    longitude: '-95.038982',
    timeZoneId: 'America/Chicago',
  },
  '51636': {
    zip: '51636',
    city: 'Coin',
    state: 'IA',
    latitude: '40.658476',
    longitude: '-95.234115',
    timeZoneId: 'America/Chicago',
  },
  '51637': {
    zip: '51637',
    city: 'College Springs',
    state: 'IA',
    latitude: '40.620338',
    longitude: '-95.1098',
    timeZoneId: 'America/Chicago',
  },
  '51638': {
    zip: '51638',
    city: 'Essex',
    state: 'IA',
    latitude: '40.83536',
    longitude: '-95.301959',
    timeZoneId: 'America/Chicago',
  },
  '51639': {
    zip: '51639',
    city: 'Farragut',
    state: 'IA',
    latitude: '40.721288',
    longitude: '-95.480358',
    timeZoneId: 'America/Chicago',
  },
  '51640': {
    zip: '51640',
    city: 'Hamburg',
    state: 'IA',
    latitude: '40.636691',
    longitude: '-95.653933',
    timeZoneId: 'America/Chicago',
  },
  '51645': {
    zip: '51645',
    city: 'Imogene',
    state: 'IA',
    latitude: '40.879226',
    longitude: '-95.426289',
    timeZoneId: 'America/Chicago',
  },
  '51646': {
    zip: '51646',
    city: 'New Market',
    state: 'IA',
    latitude: '40.733318',
    longitude: '-94.901302',
    timeZoneId: 'America/Chicago',
  },
  '51647': {
    zip: '51647',
    city: 'Northboro',
    state: 'IA',
    latitude: '40.606685',
    longitude: '-95.291836',
    timeZoneId: 'America/Chicago',
  },
  '51648': {
    zip: '51648',
    city: 'Percival',
    state: 'IA',
    latitude: '40.751649',
    longitude: '-95.809619',
    timeZoneId: 'America/Chicago',
  },
  '51649': {
    zip: '51649',
    city: 'Randolph',
    state: 'IA',
    latitude: '40.870781',
    longitude: '-95.567028',
    timeZoneId: 'America/Chicago',
  },
  '51650': {
    zip: '51650',
    city: 'Riverton',
    state: 'IA',
    latitude: '40.687799',
    longitude: '-95.569399',
    timeZoneId: 'America/Chicago',
  },
  '51651': {
    zip: '51651',
    city: 'Shambaugh',
    state: 'IA',
    latitude: '40.680842',
    longitude: '-95.063935',
    timeZoneId: 'America/Chicago',
  },
  '51652': {
    zip: '51652',
    city: 'Sidney',
    state: 'IA',
    latitude: '40.74844',
    longitude: '-95.648459',
    timeZoneId: 'America/Chicago',
  },
  '51653': {
    zip: '51653',
    city: 'Tabor',
    state: 'IA',
    latitude: '40.896392',
    longitude: '-95.67139',
    timeZoneId: 'America/Chicago',
  },
  '51654': {
    zip: '51654',
    city: 'Thurman',
    state: 'IA',
    latitude: '40.821039',
    longitude: '-95.75057',
    timeZoneId: 'America/Chicago',
  },
  '51656': {
    zip: '51656',
    city: 'Yorktown',
    state: 'IA',
    latitude: '40.732025',
    longitude: '-95.154746',
    timeZoneId: 'America/Chicago',
  },
  '52001': {
    zip: '52001',
    city: 'Dubuque',
    state: 'IA',
    latitude: '42.55752',
    longitude: '-90.69078',
    timeZoneId: 'America/Chicago',
  },
  '52002': {
    zip: '52002',
    city: 'Dubuque',
    state: 'IA',
    latitude: '42.511769',
    longitude: '-90.749087',
    timeZoneId: 'America/Chicago',
  },
  '52003': {
    zip: '52003',
    city: 'Dubuque',
    state: 'IA',
    latitude: '42.466529',
    longitude: '-90.686748',
    timeZoneId: 'America/Chicago',
  },
  '52004': {
    zip: '52004',
    city: 'Dubuque',
    state: 'IA',
    latitude: '42.500101',
    longitude: '-90.667751',
    timeZoneId: 'America/Chicago',
  },
  '52030': {
    zip: '52030',
    city: 'Andrew',
    state: 'IA',
    latitude: '42.165035',
    longitude: '-90.606013',
    timeZoneId: 'America/Chicago',
  },
  '52031': {
    zip: '52031',
    city: 'Bellevue',
    state: 'IA',
    latitude: '42.259183',
    longitude: '-90.429039',
    timeZoneId: 'America/Chicago',
  },
  '52032': {
    zip: '52032',
    city: 'Bernard',
    state: 'IA',
    latitude: '42.334273',
    longitude: '-90.835393',
    timeZoneId: 'America/Chicago',
  },
  '52033': {
    zip: '52033',
    city: 'Cascade',
    state: 'IA',
    latitude: '42.310784',
    longitude: '-91.005487',
    timeZoneId: 'America/Chicago',
  },
  '52035': {
    zip: '52035',
    city: 'Colesburg',
    state: 'IA',
    latitude: '42.634992',
    longitude: '-91.200496',
    timeZoneId: 'America/Chicago',
  },
  '52036': {
    zip: '52036',
    city: 'Delaware',
    state: 'IA',
    latitude: '42.483688',
    longitude: '-91.267338',
    timeZoneId: 'America/Chicago',
  },
  '52037': {
    zip: '52037',
    city: 'Delmar',
    state: 'IA',
    latitude: '42.001059',
    longitude: '-90.609493',
    timeZoneId: 'America/Chicago',
  },
  '52038': {
    zip: '52038',
    city: 'Dundee',
    state: 'IA',
    latitude: '42.578159',
    longitude: '-91.549181',
    timeZoneId: 'America/Chicago',
  },
  '52039': {
    zip: '52039',
    city: 'Durango',
    state: 'IA',
    latitude: '42.54047',
    longitude: '-90.839326',
    timeZoneId: 'America/Chicago',
  },
  '52040': {
    zip: '52040',
    city: 'Dyersville',
    state: 'IA',
    latitude: '42.49254',
    longitude: '-91.0803',
    timeZoneId: 'America/Chicago',
  },
  '52041': {
    zip: '52041',
    city: 'Earlville',
    state: 'IA',
    latitude: '42.480661',
    longitude: '-91.271389',
    timeZoneId: 'America/Chicago',
  },
  '52042': {
    zip: '52042',
    city: 'Edgewood',
    state: 'IA',
    latitude: '42.649161',
    longitude: '-91.399965',
    timeZoneId: 'America/Chicago',
  },
  '52043': {
    zip: '52043',
    city: 'Elkader',
    state: 'IA',
    latitude: '42.85618',
    longitude: '-91.404581',
    timeZoneId: 'America/Chicago',
  },
  '52044': {
    zip: '52044',
    city: 'Elkport',
    state: 'IA',
    latitude: '42.738977',
    longitude: '-91.278923',
    timeZoneId: 'America/Chicago',
  },
  '52045': {
    zip: '52045',
    city: 'Epworth',
    state: 'IA',
    latitude: '42.44082',
    longitude: '-90.92868',
    timeZoneId: 'America/Chicago',
  },
  '52046': {
    zip: '52046',
    city: 'Farley',
    state: 'IA',
    latitude: '42.453542',
    longitude: '-91.009472',
    timeZoneId: 'America/Chicago',
  },
  '52047': {
    zip: '52047',
    city: 'Farmersburg',
    state: 'IA',
    latitude: '42.947298',
    longitude: '-91.311574',
    timeZoneId: 'America/Chicago',
  },
  '52048': {
    zip: '52048',
    city: 'Garber',
    state: 'IA',
    latitude: '42.748109',
    longitude: '-91.248549',
    timeZoneId: 'America/Chicago',
  },
  '52049': {
    zip: '52049',
    city: 'Garnavillo',
    state: 'IA',
    latitude: '42.8727',
    longitude: '-91.22916',
    timeZoneId: 'America/Chicago',
  },
  '52050': {
    zip: '52050',
    city: 'Greeley',
    state: 'IA',
    latitude: '42.602932',
    longitude: '-91.308377',
    timeZoneId: 'America/Chicago',
  },
  '52052': {
    zip: '52052',
    city: 'Guttenberg',
    state: 'IA',
    latitude: '42.711',
    longitude: '-91.07868',
    timeZoneId: 'America/Chicago',
  },
  '52053': {
    zip: '52053',
    city: 'Holy Cross',
    state: 'IA',
    latitude: '42.591397',
    longitude: '-90.963771',
    timeZoneId: 'America/Chicago',
  },
  '52054': {
    zip: '52054',
    city: 'La Motte',
    state: 'IA',
    latitude: '42.379281',
    longitude: '-90.670192',
    timeZoneId: 'America/Chicago',
  },
  '52056': {
    zip: '52056',
    city: 'Luxemburg',
    state: 'IA',
    latitude: '42.611503',
    longitude: '-91.083512',
    timeZoneId: 'America/Chicago',
  },
  '52057': {
    zip: '52057',
    city: 'Manchester',
    state: 'IA',
    latitude: '42.48584',
    longitude: '-91.458289',
    timeZoneId: 'America/Chicago',
  },
  '52060': {
    zip: '52060',
    city: 'Maquoketa',
    state: 'IA',
    latitude: '42.071208',
    longitude: '-90.660765',
    timeZoneId: 'America/Chicago',
  },
  '52064': {
    zip: '52064',
    city: 'Miles',
    state: 'IA',
    latitude: '42.08184',
    longitude: '-90.32652',
    timeZoneId: 'America/Chicago',
  },
  '52065': {
    zip: '52065',
    city: 'New Vienna',
    state: 'IA',
    latitude: '42.560057',
    longitude: '-91.088019',
    timeZoneId: 'America/Chicago',
  },
  '52066': {
    zip: '52066',
    city: 'North Buena Vista',
    state: 'IA',
    latitude: '42.672057',
    longitude: '-90.967071',
    timeZoneId: 'America/Chicago',
  },
  '52068': {
    zip: '52068',
    city: 'Peosta',
    state: 'IA',
    latitude: '42.43158',
    longitude: '-90.79938',
    timeZoneId: 'America/Chicago',
  },
  '52069': {
    zip: '52069',
    city: 'Preston',
    state: 'IA',
    latitude: '42.050769',
    longitude: '-90.394243',
    timeZoneId: 'America/Chicago',
  },
  '52070': {
    zip: '52070',
    city: 'Sabula',
    state: 'IA',
    latitude: '42.070681',
    longitude: '-90.174281',
    timeZoneId: 'America/Chicago',
  },
  '52071': {
    zip: '52071',
    city: 'Saint Donatus',
    state: 'IA',
    latitude: '42.336876',
    longitude: '-90.494815',
    timeZoneId: 'America/Chicago',
  },
  '52072': {
    zip: '52072',
    city: 'Saint Olaf',
    state: 'IA',
    latitude: '42.95111',
    longitude: '-91.426746',
    timeZoneId: 'America/Chicago',
  },
  '52073': {
    zip: '52073',
    city: 'Sherrill',
    state: 'IA',
    latitude: '42.613258',
    longitude: '-90.811465',
    timeZoneId: 'America/Chicago',
  },
  '52074': {
    zip: '52074',
    city: 'Spragueville',
    state: 'IA',
    latitude: '42.075043',
    longitude: '-90.490232',
    timeZoneId: 'America/Chicago',
  },
  '52075': {
    zip: '52075',
    city: 'Springbrook',
    state: 'IA',
    latitude: '42.166719',
    longitude: '-90.49155',
    timeZoneId: 'America/Chicago',
  },
  '52076': {
    zip: '52076',
    city: 'Strawberry Point',
    state: 'IA',
    latitude: '42.704281',
    longitude: '-91.512431',
    timeZoneId: 'America/Chicago',
  },
  '52077': {
    zip: '52077',
    city: 'Volga',
    state: 'IA',
    latitude: '42.804486',
    longitude: '-91.541051',
    timeZoneId: 'America/Chicago',
  },
  '52078': {
    zip: '52078',
    city: 'Worthington',
    state: 'IA',
    latitude: '42.393176',
    longitude: '-91.102562',
    timeZoneId: 'America/Chicago',
  },
  '52079': {
    zip: '52079',
    city: 'Zwingle',
    state: 'IA',
    latitude: '42.30924',
    longitude: '-90.72234',
    timeZoneId: 'America/Chicago',
  },
  '52099': {
    zip: '52099',
    city: 'Dubuque',
    state: 'IA',
    latitude: '42.495882',
    longitude: '-90.667512',
    timeZoneId: 'America/Chicago',
  },
  '52101': {
    zip: '52101',
    city: 'Decorah',
    state: 'IA',
    latitude: '43.392',
    longitude: '-91.88274',
    timeZoneId: 'America/Chicago',
  },
  '52132': {
    zip: '52132',
    city: 'Calmar',
    state: 'IA',
    latitude: '43.184136',
    longitude: '-91.872048',
    timeZoneId: 'America/Chicago',
  },
  '52133': {
    zip: '52133',
    city: 'Castalia',
    state: 'IA',
    latitude: '43.166865',
    longitude: '-91.662442',
    timeZoneId: 'America/Chicago',
  },
  '52134': {
    zip: '52134',
    city: 'Chester',
    state: 'IA',
    latitude: '43.465662',
    longitude: '-92.433456',
    timeZoneId: 'America/Chicago',
  },
  '52135': {
    zip: '52135',
    city: 'Clermont',
    state: 'IA',
    latitude: '43.004514',
    longitude: '-91.66061',
    timeZoneId: 'America/Chicago',
  },
  '52136': {
    zip: '52136',
    city: 'Cresco',
    state: 'IA',
    latitude: '43.376062',
    longitude: '-92.114632',
    timeZoneId: 'America/Chicago',
  },
  '52140': {
    zip: '52140',
    city: 'Dorchester',
    state: 'IA',
    latitude: '43.43532',
    longitude: '-91.513065',
    timeZoneId: 'America/Chicago',
  },
  '52141': {
    zip: '52141',
    city: 'Elgin',
    state: 'IA',
    latitude: '42.958838',
    longitude: '-91.636493',
    timeZoneId: 'America/Chicago',
  },
  '52142': {
    zip: '52142',
    city: 'Fayette',
    state: 'IA',
    latitude: '42.857326',
    longitude: '-91.809593',
    timeZoneId: 'America/Chicago',
  },
  '52144': {
    zip: '52144',
    city: 'Fort Atkinson',
    state: 'IA',
    latitude: '43.143605',
    longitude: '-91.93199',
    timeZoneId: 'America/Chicago',
  },
  '52146': {
    zip: '52146',
    city: 'Harpers Ferry',
    state: 'IA',
    latitude: '43.201376',
    longitude: '-91.147423',
    timeZoneId: 'America/Chicago',
  },
  '52147': {
    zip: '52147',
    city: 'Hawkeye',
    state: 'IA',
    latitude: '42.940399',
    longitude: '-91.948173',
    timeZoneId: 'America/Chicago',
  },
  '52149': {
    zip: '52149',
    city: 'Highlandville',
    state: 'IA',
    latitude: '43.305362',
    longitude: '-91.789881',
    timeZoneId: 'America/Chicago',
  },
  '52151': {
    zip: '52151',
    city: 'Lansing',
    state: 'IA',
    latitude: '43.359099',
    longitude: '-91.22189',
    timeZoneId: 'America/Chicago',
  },
  '52154': {
    zip: '52154',
    city: 'Lawler',
    state: 'IA',
    latitude: '43.101911',
    longitude: '-92.138892',
    timeZoneId: 'America/Chicago',
  },
  '52155': {
    zip: '52155',
    city: 'Lime Springs',
    state: 'IA',
    latitude: '43.45308',
    longitude: '-92.282246',
    timeZoneId: 'America/Chicago',
  },
  '52156': {
    zip: '52156',
    city: 'Luana',
    state: 'IA',
    latitude: '43.056418',
    longitude: '-91.392347',
    timeZoneId: 'America/Chicago',
  },
  '52157': {
    zip: '52157',
    city: 'Mc Gregor',
    state: 'IA',
    latitude: '43.02147',
    longitude: '-91.179382',
    timeZoneId: 'America/Chicago',
  },
  '52158': {
    zip: '52158',
    city: 'Marquette',
    state: 'IA',
    latitude: '43.061141',
    longitude: '-91.217446',
    timeZoneId: 'America/Chicago',
  },
  '52159': {
    zip: '52159',
    city: 'Monona',
    state: 'IA',
    latitude: '43.04928',
    longitude: '-91.388773',
    timeZoneId: 'America/Chicago',
  },
  '52160': {
    zip: '52160',
    city: 'New Albin',
    state: 'IA',
    latitude: '43.495201',
    longitude: '-91.284754',
    timeZoneId: 'America/Chicago',
  },
  '52161': {
    zip: '52161',
    city: 'Ossian',
    state: 'IA',
    latitude: '43.172563',
    longitude: '-91.818637',
    timeZoneId: 'America/Chicago',
  },
  '52162': {
    zip: '52162',
    city: 'Postville',
    state: 'IA',
    latitude: '43.0455',
    longitude: '-91.566',
    timeZoneId: 'America/Chicago',
  },
  '52163': {
    zip: '52163',
    city: 'Protivin',
    state: 'IA',
    latitude: '43.361128',
    longitude: '-92.167992',
    timeZoneId: 'America/Chicago',
  },
  '52164': {
    zip: '52164',
    city: 'Randalia',
    state: 'IA',
    latitude: '42.862408',
    longitude: '-91.886149',
    timeZoneId: 'America/Chicago',
  },
  '52165': {
    zip: '52165',
    city: 'Ridgeway',
    state: 'IA',
    latitude: '43.293751',
    longitude: '-91.988675',
    timeZoneId: 'America/Chicago',
  },
  '52166': {
    zip: '52166',
    city: 'Saint Lucas',
    state: 'IA',
    latitude: '43.065027',
    longitude: '-91.932103',
    timeZoneId: 'America/Chicago',
  },
  '52168': {
    zip: '52168',
    city: 'Spillville',
    state: 'IA',
    latitude: '43.201837',
    longitude: '-91.948183',
    timeZoneId: 'America/Chicago',
  },
  '52169': {
    zip: '52169',
    city: 'Wadena',
    state: 'IA',
    latitude: '42.83933',
    longitude: '-91.652851',
    timeZoneId: 'America/Chicago',
  },
  '52170': {
    zip: '52170',
    city: 'Waterville',
    state: 'IA',
    latitude: '43.271561',
    longitude: '-91.258309',
    timeZoneId: 'America/Chicago',
  },
  '52171': {
    zip: '52171',
    city: 'Waucoma',
    state: 'IA',
    latitude: '43.058499',
    longitude: '-91.990554',
    timeZoneId: 'America/Chicago',
  },
  '52172': {
    zip: '52172',
    city: 'Waukon',
    state: 'IA',
    latitude: '43.271393',
    longitude: '-91.477245',
    timeZoneId: 'America/Chicago',
  },
  '52175': {
    zip: '52175',
    city: 'West Union',
    state: 'IA',
    latitude: '42.96966',
    longitude: '-91.80084',
    timeZoneId: 'America/Chicago',
  },
  '52201': {
    zip: '52201',
    city: 'Ainsworth',
    state: 'IA',
    latitude: '41.291753',
    longitude: '-91.5521',
    timeZoneId: 'America/Chicago',
  },
  '52202': {
    zip: '52202',
    city: 'Alburnett',
    state: 'IA',
    latitude: '42.155886',
    longitude: '-91.632063',
    timeZoneId: 'America/Chicago',
  },
  '52203': {
    zip: '52203',
    city: 'Amana',
    state: 'IA',
    latitude: '41.804159',
    longitude: '-91.867902',
    timeZoneId: 'America/Chicago',
  },
  '52204': {
    zip: '52204',
    city: 'Amana',
    state: 'IA',
    latitude: '41.798523',
    longitude: '-91.870646',
    timeZoneId: 'America/Chicago',
  },
  '52205': {
    zip: '52205',
    city: 'Anamosa',
    state: 'IA',
    latitude: '42.11352',
    longitude: '-91.29522',
    timeZoneId: 'America/Chicago',
  },
  '52206': {
    zip: '52206',
    city: 'Atkins',
    state: 'IA',
    latitude: '42.00596',
    longitude: '-91.871945',
    timeZoneId: 'America/Chicago',
  },
  '52207': {
    zip: '52207',
    city: 'Baldwin',
    state: 'IA',
    latitude: '42.072359',
    longitude: '-90.820412',
    timeZoneId: 'America/Chicago',
  },
  '52208': {
    zip: '52208',
    city: 'Belle Plaine',
    state: 'IA',
    latitude: '41.898779',
    longitude: '-92.274878',
    timeZoneId: 'America/Chicago',
  },
  '52209': {
    zip: '52209',
    city: 'Blairstown',
    state: 'IA',
    latitude: '41.90705',
    longitude: '-92.081962',
    timeZoneId: 'America/Chicago',
  },
  '52210': {
    zip: '52210',
    city: 'Brandon',
    state: 'IA',
    latitude: '42.314232',
    longitude: '-92.005487',
    timeZoneId: 'America/Chicago',
  },
  '52211': {
    zip: '52211',
    city: 'Brooklyn',
    state: 'IA',
    latitude: '41.729084',
    longitude: '-92.44554',
    timeZoneId: 'America/Chicago',
  },
  '52212': {
    zip: '52212',
    city: 'Center Junction',
    state: 'IA',
    latitude: '42.078396',
    longitude: '-91.073294',
    timeZoneId: 'America/Chicago',
  },
  '52213': {
    zip: '52213',
    city: 'Center Point',
    state: 'IA',
    latitude: '42.192358',
    longitude: '-91.757115',
    timeZoneId: 'America/Chicago',
  },
  '52214': {
    zip: '52214',
    city: 'Central City',
    state: 'IA',
    latitude: '42.18114',
    longitude: '-91.49136',
    timeZoneId: 'America/Chicago',
  },
  '52215': {
    zip: '52215',
    city: 'Chelsea',
    state: 'IA',
    latitude: '41.91857',
    longitude: '-92.393781',
    timeZoneId: 'America/Chicago',
  },
  '52216': {
    zip: '52216',
    city: 'Clarence',
    state: 'IA',
    latitude: '41.881233',
    longitude: '-91.061085',
    timeZoneId: 'America/Chicago',
  },
  '52217': {
    zip: '52217',
    city: 'Clutier',
    state: 'IA',
    latitude: '42.079104',
    longitude: '-92.402443',
    timeZoneId: 'America/Chicago',
  },
  '52218': {
    zip: '52218',
    city: 'Coggon',
    state: 'IA',
    latitude: '42.278588',
    longitude: '-91.540055',
    timeZoneId: 'America/Chicago',
  },
  '52219': {
    zip: '52219',
    city: 'Prairieburg',
    state: 'IA',
    latitude: '42.237355',
    longitude: '-91.427686',
    timeZoneId: 'America/Chicago',
  },
  '52220': {
    zip: '52220',
    city: 'Conroy',
    state: 'IA',
    latitude: '41.743848',
    longitude: '-91.96625',
    timeZoneId: 'America/Chicago',
  },
  '52221': {
    zip: '52221',
    city: 'Guernsey',
    state: 'IA',
    latitude: '41.64869',
    longitude: '-92.341879',
    timeZoneId: 'America/Chicago',
  },
  '52222': {
    zip: '52222',
    city: 'Deep River',
    state: 'IA',
    latitude: '41.59734',
    longitude: '-92.319254',
    timeZoneId: 'America/Chicago',
  },
  '52223': {
    zip: '52223',
    city: 'Delhi',
    state: 'IA',
    latitude: '42.429786',
    longitude: '-91.330245',
    timeZoneId: 'America/Chicago',
  },
  '52224': {
    zip: '52224',
    city: 'Dysart',
    state: 'IA',
    latitude: '42.168794',
    longitude: '-92.307308',
    timeZoneId: 'America/Chicago',
  },
  '52225': {
    zip: '52225',
    city: 'Elberon',
    state: 'IA',
    latitude: '42.01165',
    longitude: '-92.350272',
    timeZoneId: 'America/Chicago',
  },
  '52227': {
    zip: '52227',
    city: 'Ely',
    state: 'IA',
    latitude: '41.89619',
    longitude: '-91.567417',
    timeZoneId: 'America/Chicago',
  },
  '52228': {
    zip: '52228',
    city: 'Fairfax',
    state: 'IA',
    latitude: '41.911092',
    longitude: '-91.782512',
    timeZoneId: 'America/Chicago',
  },
  '52229': {
    zip: '52229',
    city: 'Garrison',
    state: 'IA',
    latitude: '42.142701',
    longitude: '-92.140224',
    timeZoneId: 'America/Chicago',
  },
  '52231': {
    zip: '52231',
    city: 'Harper',
    state: 'IA',
    latitude: '41.377556',
    longitude: '-92.02548',
    timeZoneId: 'America/Chicago',
  },
  '52232': {
    zip: '52232',
    city: 'Hartwick',
    state: 'IA',
    latitude: '41.819886',
    longitude: '-92.356934',
    timeZoneId: 'America/Chicago',
  },
  '52233': {
    zip: '52233',
    city: 'Hiawatha',
    state: 'IA',
    latitude: '42.040137',
    longitude: '-91.676199',
    timeZoneId: 'America/Chicago',
  },
  '52235': {
    zip: '52235',
    city: 'Hills',
    state: 'IA',
    latitude: '41.552974',
    longitude: '-91.532344',
    timeZoneId: 'America/Chicago',
  },
  '52236': {
    zip: '52236',
    city: 'Homestead',
    state: 'IA',
    latitude: '41.725449',
    longitude: '-91.886548',
    timeZoneId: 'America/Chicago',
  },
  '52237': {
    zip: '52237',
    city: 'Hopkinton',
    state: 'IA',
    latitude: '42.320147',
    longitude: '-91.12732',
    timeZoneId: 'America/Chicago',
  },
  '52240': {
    zip: '52240',
    city: 'Iowa City',
    state: 'IA',
    latitude: '41.657959',
    longitude: '-91.52806',
    timeZoneId: 'America/Chicago',
  },
  '52241': {
    zip: '52241',
    city: 'Coralville',
    state: 'IA',
    latitude: '41.680235',
    longitude: '-91.577652',
    timeZoneId: 'America/Chicago',
  },
  '52242': {
    zip: '52242',
    city: 'Iowa City',
    state: 'IA',
    latitude: '41.664405',
    longitude: '-91.542152',
    timeZoneId: 'America/Chicago',
  },
  '52243': {
    zip: '52243',
    city: 'Iowa City',
    state: 'IA',
    latitude: '41.656599',
    longitude: '-91.535548',
    timeZoneId: 'America/Chicago',
  },
  '52244': {
    zip: '52244',
    city: 'Iowa City',
    state: 'IA',
    latitude: '41.685037',
    longitude: '-91.517079',
    timeZoneId: 'America/Chicago',
  },
  '52245': {
    zip: '52245',
    city: 'Iowa City',
    state: 'IA',
    latitude: '41.66635',
    longitude: '-91.511043',
    timeZoneId: 'America/Chicago',
  },
  '52246': {
    zip: '52246',
    city: 'Iowa City',
    state: 'IA',
    latitude: '41.654177',
    longitude: '-91.560065',
    timeZoneId: 'America/Chicago',
  },
  '52247': {
    zip: '52247',
    city: 'Kalona',
    state: 'IA',
    latitude: '41.4873',
    longitude: '-91.707929',
    timeZoneId: 'America/Chicago',
  },
  '52248': {
    zip: '52248',
    city: 'Keota',
    state: 'IA',
    latitude: '41.363498',
    longitude: '-91.954172',
    timeZoneId: 'America/Chicago',
  },
  '52249': {
    zip: '52249',
    city: 'Keystone',
    state: 'IA',
    latitude: '42.002389',
    longitude: '-92.202003',
    timeZoneId: 'America/Chicago',
  },
  '52251': {
    zip: '52251',
    city: 'Ladora',
    state: 'IA',
    latitude: '41.76047',
    longitude: '-92.197012',
    timeZoneId: 'America/Chicago',
  },
  '52252': {
    zip: '52252',
    city: 'Langworthy',
    state: 'IA',
    latitude: '42.194237',
    longitude: '-91.225157',
    timeZoneId: 'America/Chicago',
  },
  '52253': {
    zip: '52253',
    city: 'Lisbon',
    state: 'IA',
    latitude: '41.916168',
    longitude: '-91.383473',
    timeZoneId: 'America/Chicago',
  },
  '52254': {
    zip: '52254',
    city: 'Lost Nation',
    state: 'IA',
    latitude: '41.966532',
    longitude: '-90.817621',
    timeZoneId: 'America/Chicago',
  },
  '52255': {
    zip: '52255',
    city: 'Lowden',
    state: 'IA',
    latitude: '41.860439',
    longitude: '-90.928486',
    timeZoneId: 'America/Chicago',
  },
  '52257': {
    zip: '52257',
    city: 'Luzerne',
    state: 'IA',
    latitude: '41.903335',
    longitude: '-92.178335',
    timeZoneId: 'America/Chicago',
  },
  '52301': {
    zip: '52301',
    city: 'Marengo',
    state: 'IA',
    latitude: '41.797617',
    longitude: '-92.081501',
    timeZoneId: 'America/Chicago',
  },
  '52302': {
    zip: '52302',
    city: 'Marion',
    state: 'IA',
    latitude: '42.043431',
    longitude: '-91.593062',
    timeZoneId: 'America/Chicago',
  },
  '52305': {
    zip: '52305',
    city: 'Martelle',
    state: 'IA',
    latitude: '42.011938',
    longitude: '-91.387767',
    timeZoneId: 'America/Chicago',
  },
  '52306': {
    zip: '52306',
    city: 'Mechanicsville',
    state: 'IA',
    latitude: '41.902291',
    longitude: '-91.25499',
    timeZoneId: 'America/Chicago',
  },
  '52307': {
    zip: '52307',
    city: 'Middle Amana',
    state: 'IA',
    latitude: '41.800087',
    longitude: '-91.895779',
    timeZoneId: 'America/Chicago',
  },
  '52308': {
    zip: '52308',
    city: 'Millersburg',
    state: 'IA',
    latitude: '41.573839',
    longitude: '-92.159956',
    timeZoneId: 'America/Chicago',
  },
  '52309': {
    zip: '52309',
    city: 'Monmouth',
    state: 'IA',
    latitude: '42.082257',
    longitude: '-90.870603',
    timeZoneId: 'America/Chicago',
  },
  '52310': {
    zip: '52310',
    city: 'Monticello',
    state: 'IA',
    latitude: '42.239992',
    longitude: '-91.189303',
    timeZoneId: 'America/Chicago',
  },
  '52312': {
    zip: '52312',
    city: 'Morley',
    state: 'IA',
    latitude: '42.003964',
    longitude: '-91.244526',
    timeZoneId: 'America/Chicago',
  },
  '52313': {
    zip: '52313',
    city: 'Mount Auburn',
    state: 'IA',
    latitude: '42.256626',
    longitude: '-92.171137',
    timeZoneId: 'America/Chicago',
  },
  '52314': {
    zip: '52314',
    city: 'Mount Vernon',
    state: 'IA',
    latitude: '41.93675',
    longitude: '-91.438291',
    timeZoneId: 'America/Chicago',
  },
  '52315': {
    zip: '52315',
    city: 'Newhall',
    state: 'IA',
    latitude: '41.993889',
    longitude: '-91.967551',
    timeZoneId: 'America/Chicago',
  },
  '52316': {
    zip: '52316',
    city: 'North English',
    state: 'IA',
    latitude: '41.518029',
    longitude: '-92.080384',
    timeZoneId: 'America/Chicago',
  },
  '52317': {
    zip: '52317',
    city: 'North Liberty',
    state: 'IA',
    latitude: '41.743269',
    longitude: '-91.60053',
    timeZoneId: 'America/Chicago',
  },
  '52318': {
    zip: '52318',
    city: 'Norway',
    state: 'IA',
    latitude: '41.904347',
    longitude: '-91.918391',
    timeZoneId: 'America/Chicago',
  },
  '52320': {
    zip: '52320',
    city: 'Olin',
    state: 'IA',
    latitude: '42.004912',
    longitude: '-91.137564',
    timeZoneId: 'America/Chicago',
  },
  '52321': {
    zip: '52321',
    city: 'Onslow',
    state: 'IA',
    latitude: '42.127198',
    longitude: '-90.958707',
    timeZoneId: 'America/Chicago',
  },
  '52322': {
    zip: '52322',
    city: 'Oxford',
    state: 'IA',
    latitude: '41.722093',
    longitude: '-91.785552',
    timeZoneId: 'America/Chicago',
  },
  '52323': {
    zip: '52323',
    city: 'Oxford Junction',
    state: 'IA',
    latitude: '41.989991',
    longitude: '-90.978487',
    timeZoneId: 'America/Chicago',
  },
  '52324': {
    zip: '52324',
    city: 'Palo',
    state: 'IA',
    latitude: '42.05214',
    longitude: '-91.79247',
    timeZoneId: 'America/Chicago',
  },
  '52325': {
    zip: '52325',
    city: 'Parnell',
    state: 'IA',
    latitude: '41.554231',
    longitude: '-92.004947',
    timeZoneId: 'America/Chicago',
  },
  '52326': {
    zip: '52326',
    city: 'Quasqueton',
    state: 'IA',
    latitude: '42.395085',
    longitude: '-91.756488',
    timeZoneId: 'America/Chicago',
  },
  '52327': {
    zip: '52327',
    city: 'Riverside',
    state: 'IA',
    latitude: '41.483163',
    longitude: '-91.582362',
    timeZoneId: 'America/Chicago',
  },
  '52328': {
    zip: '52328',
    city: 'Robins',
    state: 'IA',
    latitude: '42.074811',
    longitude: '-91.665381',
    timeZoneId: 'America/Chicago',
  },
  '52329': {
    zip: '52329',
    city: 'Rowley',
    state: 'IA',
    latitude: '42.368583',
    longitude: '-91.844393',
    timeZoneId: 'America/Chicago',
  },
  '52330': {
    zip: '52330',
    city: 'Ryan',
    state: 'IA',
    latitude: '42.350073',
    longitude: '-91.482006',
    timeZoneId: 'America/Chicago',
  },
  '52332': {
    zip: '52332',
    city: 'Shellsburg',
    state: 'IA',
    latitude: '42.09568',
    longitude: '-91.870031',
    timeZoneId: 'America/Chicago',
  },
  '52333': {
    zip: '52333',
    city: 'Solon',
    state: 'IA',
    latitude: '41.803913',
    longitude: '-91.51581',
    timeZoneId: 'America/Chicago',
  },
  '52334': {
    zip: '52334',
    city: 'South Amana',
    state: 'IA',
    latitude: '41.75798',
    longitude: '-91.927486',
    timeZoneId: 'America/Chicago',
  },
  '52335': {
    zip: '52335',
    city: 'South English',
    state: 'IA',
    latitude: '41.469607',
    longitude: '-92.116288',
    timeZoneId: 'America/Chicago',
  },
  '52336': {
    zip: '52336',
    city: 'Springville',
    state: 'IA',
    latitude: '42.09468',
    longitude: '-91.46262',
    timeZoneId: 'America/Chicago',
  },
  '52337': {
    zip: '52337',
    city: 'Stanwood',
    state: 'IA',
    latitude: '41.890714',
    longitude: '-91.147902',
    timeZoneId: 'America/Chicago',
  },
  '52338': {
    zip: '52338',
    city: 'Swisher',
    state: 'IA',
    latitude: '41.84873',
    longitude: '-91.697035',
    timeZoneId: 'America/Chicago',
  },
  '52339': {
    zip: '52339',
    city: 'Tama',
    state: 'IA',
    latitude: '41.966336',
    longitude: '-92.57322',
    timeZoneId: 'America/Chicago',
  },
  '52340': {
    zip: '52340',
    city: 'Tiffin',
    state: 'IA',
    latitude: '41.7099',
    longitude: '-91.66615',
    timeZoneId: 'America/Chicago',
  },
  '52341': {
    zip: '52341',
    city: 'Toddville',
    state: 'IA',
    latitude: '42.108842',
    longitude: '-91.736923',
    timeZoneId: 'America/Chicago',
  },
  '52342': {
    zip: '52342',
    city: 'Toledo',
    state: 'IA',
    latitude: '41.990728',
    longitude: '-92.575735',
    timeZoneId: 'America/Chicago',
  },
  '52344': {
    zip: '52344',
    city: 'Troy Mills',
    state: 'IA',
    latitude: '42.29204',
    longitude: '-91.685564',
    timeZoneId: 'America/Chicago',
  },
  '52345': {
    zip: '52345',
    city: 'Urbana',
    state: 'IA',
    latitude: '42.223644',
    longitude: '-91.875135',
    timeZoneId: 'America/Chicago',
  },
  '52346': {
    zip: '52346',
    city: 'Van Horne',
    state: 'IA',
    latitude: '42.023359',
    longitude: '-92.079688',
    timeZoneId: 'America/Chicago',
  },
  '52347': {
    zip: '52347',
    city: 'Victor',
    state: 'IA',
    latitude: '41.729129',
    longitude: '-92.294632',
    timeZoneId: 'America/Chicago',
  },
  '52348': {
    zip: '52348',
    city: 'Vining',
    state: 'IA',
    latitude: '41.974587',
    longitude: '-92.364979',
    timeZoneId: 'America/Chicago',
  },
  '52349': {
    zip: '52349',
    city: 'Vinton',
    state: 'IA',
    latitude: '42.166008',
    longitude: '-92.019386',
    timeZoneId: 'America/Chicago',
  },
  '52351': {
    zip: '52351',
    city: 'Walford',
    state: 'IA',
    latitude: '41.905945',
    longitude: '-91.890838',
    timeZoneId: 'America/Chicago',
  },
  '52352': {
    zip: '52352',
    city: 'Walker',
    state: 'IA',
    latitude: '42.269223',
    longitude: '-91.760343',
    timeZoneId: 'America/Chicago',
  },
  '52353': {
    zip: '52353',
    city: 'Washington',
    state: 'IA',
    latitude: '41.299109',
    longitude: '-91.688587',
    timeZoneId: 'America/Chicago',
  },
  '52354': {
    zip: '52354',
    city: 'Watkins',
    state: 'IA',
    latitude: '41.904259',
    longitude: '-92.006214',
    timeZoneId: 'America/Chicago',
  },
  '52355': {
    zip: '52355',
    city: 'Webster',
    state: 'IA',
    latitude: '41.43615',
    longitude: '-92.189243',
    timeZoneId: 'America/Chicago',
  },
  '52356': {
    zip: '52356',
    city: 'Wellman',
    state: 'IA',
    latitude: '41.470532',
    longitude: '-91.832359',
    timeZoneId: 'America/Chicago',
  },
  '52358': {
    zip: '52358',
    city: 'West Branch',
    state: 'IA',
    latitude: '41.675413',
    longitude: '-91.346693',
    timeZoneId: 'America/Chicago',
  },
  '52359': {
    zip: '52359',
    city: 'West Chester',
    state: 'IA',
    latitude: '41.377483',
    longitude: '-91.881338',
    timeZoneId: 'America/Chicago',
  },
  '52361': {
    zip: '52361',
    city: 'Williamsburg',
    state: 'IA',
    latitude: '41.661523',
    longitude: '-92.01523',
    timeZoneId: 'America/Chicago',
  },
  '52362': {
    zip: '52362',
    city: 'Wyoming',
    state: 'IA',
    latitude: '42.062',
    longitude: '-91.004313',
    timeZoneId: 'America/Chicago',
  },
  '52401': {
    zip: '52401',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.974802',
    longitude: '-91.660929',
    timeZoneId: 'America/Chicago',
  },
  '52402': {
    zip: '52402',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '42.020752',
    longitude: '-91.669918',
    timeZoneId: 'America/Chicago',
  },
  '52403': {
    zip: '52403',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.982434',
    longitude: '-91.607252',
    timeZoneId: 'America/Chicago',
  },
  '52404': {
    zip: '52404',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.947254',
    longitude: '-91.690122',
    timeZoneId: 'America/Chicago',
  },
  '52405': {
    zip: '52405',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.97937',
    longitude: '-91.718932',
    timeZoneId: 'America/Chicago',
  },
  '52406': {
    zip: '52406',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.919897',
    longitude: '-91.677991',
    timeZoneId: 'America/Chicago',
  },
  '52407': {
    zip: '52407',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.97584',
    longitude: '-91.655362',
    timeZoneId: 'America/Chicago',
  },
  '52408': {
    zip: '52408',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.987896',
    longitude: '-91.713106',
    timeZoneId: 'America/Chicago',
  },
  '52409': {
    zip: '52409',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.989566',
    longitude: '-91.714334',
    timeZoneId: 'America/Chicago',
  },
  '52410': {
    zip: '52410',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '42.021256',
    longitude: '-91.658506',
    timeZoneId: 'America/Chicago',
  },
  '52411': {
    zip: '52411',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '42.040798',
    longitude: '-91.723138',
    timeZoneId: 'America/Chicago',
  },
  '52497': {
    zip: '52497',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '42.005872',
    longitude: '-91.641725',
    timeZoneId: 'America/Chicago',
  },
  '52498': {
    zip: '52498',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '42.021791',
    longitude: '-91.661092',
    timeZoneId: 'America/Chicago',
  },
  '52499': {
    zip: '52499',
    city: 'Cedar Rapids',
    state: 'IA',
    latitude: '41.974943',
    longitude: '-91.655103',
    timeZoneId: 'America/Chicago',
  },
  '52501': {
    zip: '52501',
    city: 'Ottumwa',
    state: 'IA',
    latitude: '41.018213',
    longitude: '-92.416533',
    timeZoneId: 'America/Chicago',
  },
  '52530': {
    zip: '52530',
    city: 'Agency',
    state: 'IA',
    latitude: '40.994699',
    longitude: '-92.305353',
    timeZoneId: 'America/Chicago',
  },
  '52531': {
    zip: '52531',
    city: 'Albia',
    state: 'IA',
    latitude: '41.08692',
    longitude: '-92.7288',
    timeZoneId: 'America/Chicago',
  },
  '52533': {
    zip: '52533',
    city: 'Batavia',
    state: 'IA',
    latitude: '40.997808',
    longitude: '-92.159295',
    timeZoneId: 'America/Chicago',
  },
  '52534': {
    zip: '52534',
    city: 'Beacon',
    state: 'IA',
    latitude: '41.277376',
    longitude: '-92.683877',
    timeZoneId: 'America/Chicago',
  },
  '52535': {
    zip: '52535',
    city: 'Birmingham',
    state: 'IA',
    latitude: '40.854186',
    longitude: '-91.948242',
    timeZoneId: 'America/Chicago',
  },
  '52536': {
    zip: '52536',
    city: 'Blakesburg',
    state: 'IA',
    latitude: '40.960626',
    longitude: '-92.635668',
    timeZoneId: 'America/Chicago',
  },
  '52537': {
    zip: '52537',
    city: 'Bloomfield',
    state: 'IA',
    latitude: '40.73268',
    longitude: '-92.4141',
    timeZoneId: 'America/Chicago',
  },
  '52540': {
    zip: '52540',
    city: 'Brighton',
    state: 'IA',
    latitude: '41.173994',
    longitude: '-91.823207',
    timeZoneId: 'America/Chicago',
  },
  '52542': {
    zip: '52542',
    city: 'Cantril',
    state: 'IA',
    latitude: '40.642819',
    longitude: '-92.070389',
    timeZoneId: 'America/Chicago',
  },
  '52543': {
    zip: '52543',
    city: 'Cedar',
    state: 'IA',
    latitude: '41.204581',
    longitude: '-92.50057',
    timeZoneId: 'America/Chicago',
  },
  '52544': {
    zip: '52544',
    city: 'Centerville',
    state: 'IA',
    latitude: '40.727537',
    longitude: '-92.865109',
    timeZoneId: 'America/Chicago',
  },
  '52548': {
    zip: '52548',
    city: 'Chillicothe',
    state: 'IA',
    latitude: '41.069956',
    longitude: '-92.541431',
    timeZoneId: 'America/Chicago',
  },
  '52549': {
    zip: '52549',
    city: 'Cincinnati',
    state: 'IA',
    latitude: '40.63427',
    longitude: '-92.923794',
    timeZoneId: 'America/Chicago',
  },
  '52550': {
    zip: '52550',
    city: 'Delta',
    state: 'IA',
    latitude: '41.301976',
    longitude: '-92.351351',
    timeZoneId: 'America/Chicago',
  },
  '52551': {
    zip: '52551',
    city: 'Douds',
    state: 'IA',
    latitude: '40.80334',
    longitude: '-92.046595',
    timeZoneId: 'America/Chicago',
  },
  '52552': {
    zip: '52552',
    city: 'Drakesville',
    state: 'IA',
    latitude: '40.777019',
    longitude: '-92.455663',
    timeZoneId: 'America/Chicago',
  },
  '52553': {
    zip: '52553',
    city: 'Eddyville',
    state: 'IA',
    latitude: '41.155807',
    longitude: '-92.63511',
    timeZoneId: 'America/Chicago',
  },
  '52554': {
    zip: '52554',
    city: 'Eldon',
    state: 'IA',
    latitude: '40.91702',
    longitude: '-92.221138',
    timeZoneId: 'America/Chicago',
  },
  '52555': {
    zip: '52555',
    city: 'Exline',
    state: 'IA',
    latitude: '40.646795',
    longitude: '-92.844715',
    timeZoneId: 'America/Chicago',
  },
  '52556': {
    zip: '52556',
    city: 'Fairfield',
    state: 'IA',
    latitude: '41.00517',
    longitude: '-91.968732',
    timeZoneId: 'America/Chicago',
  },
  '52557': {
    zip: '52557',
    city: 'Fairfield',
    state: 'IA',
    latitude: '41.005913',
    longitude: '-91.9642',
    timeZoneId: 'America/Chicago',
  },
  '52560': {
    zip: '52560',
    city: 'Floris',
    state: 'IA',
    latitude: '40.85501',
    longitude: '-92.294921',
    timeZoneId: 'America/Chicago',
  },
  '52561': {
    zip: '52561',
    city: 'Fremont',
    state: 'IA',
    latitude: '41.212479',
    longitude: '-92.435459',
    timeZoneId: 'America/Chicago',
  },
  '52562': {
    zip: '52562',
    city: 'Hayesville',
    state: 'IA',
    latitude: '41.332615',
    longitude: '-92.181871',
    timeZoneId: 'America/Chicago',
  },
  '52563': {
    zip: '52563',
    city: 'Hedrick',
    state: 'IA',
    latitude: '41.18232',
    longitude: '-92.28468',
    timeZoneId: 'America/Chicago',
  },
  '52565': {
    zip: '52565',
    city: 'Keosauqua',
    state: 'IA',
    latitude: '40.734954',
    longitude: '-91.960982',
    timeZoneId: 'America/Chicago',
  },
  '52566': {
    zip: '52566',
    city: 'Kirkville',
    state: 'IA',
    latitude: '41.119729',
    longitude: '-92.464299',
    timeZoneId: 'America/Chicago',
  },
  '52567': {
    zip: '52567',
    city: 'Libertyville',
    state: 'IA',
    latitude: '40.956315',
    longitude: '-92.049045',
    timeZoneId: 'America/Chicago',
  },
  '52568': {
    zip: '52568',
    city: 'Martinsburg',
    state: 'IA',
    latitude: '41.203919',
    longitude: '-92.160184',
    timeZoneId: 'America/Chicago',
  },
  '52569': {
    zip: '52569',
    city: 'Melrose',
    state: 'IA',
    latitude: '40.975161',
    longitude: '-93.052547',
    timeZoneId: 'America/Chicago',
  },
  '52570': {
    zip: '52570',
    city: 'Milton',
    state: 'IA',
    latitude: '40.676337',
    longitude: '-92.138273',
    timeZoneId: 'America/Chicago',
  },
  '52571': {
    zip: '52571',
    city: 'Moravia',
    state: 'IA',
    latitude: '40.88562',
    longitude: '-92.86062',
    timeZoneId: 'America/Chicago',
  },
  '52572': {
    zip: '52572',
    city: 'Moulton',
    state: 'IA',
    latitude: '40.68945',
    longitude: '-92.684164',
    timeZoneId: 'America/Chicago',
  },
  '52573': {
    zip: '52573',
    city: 'Mount Sterling',
    state: 'IA',
    latitude: '40.617778',
    longitude: '-91.931944',
    timeZoneId: 'America/Chicago',
  },
  '52574': {
    zip: '52574',
    city: 'Mystic',
    state: 'IA',
    latitude: '40.7964',
    longitude: '-92.92872',
    timeZoneId: 'America/Chicago',
  },
  '52576': {
    zip: '52576',
    city: 'Ollie',
    state: 'IA',
    latitude: '41.203732',
    longitude: '-92.161382',
    timeZoneId: 'America/Chicago',
  },
  '52577': {
    zip: '52577',
    city: 'Oskaloosa',
    state: 'IA',
    latitude: '41.2866',
    longitude: '-92.60028',
    timeZoneId: 'America/Chicago',
  },
  '52580': {
    zip: '52580',
    city: 'Packwood',
    state: 'IA',
    latitude: '41.118406',
    longitude: '-92.061888',
    timeZoneId: 'America/Chicago',
  },
  '52581': {
    zip: '52581',
    city: 'Plano',
    state: 'IA',
    latitude: '40.783615',
    longitude: '-93.036389',
    timeZoneId: 'America/Chicago',
  },
  '52583': {
    zip: '52583',
    city: 'Promise City',
    state: 'IA',
    latitude: '40.765798',
    longitude: '-93.155233',
    timeZoneId: 'America/Chicago',
  },
  '52584': {
    zip: '52584',
    city: 'Pulaski',
    state: 'IA',
    latitude: '40.695621',
    longitude: '-92.235799',
    timeZoneId: 'America/Chicago',
  },
  '52585': {
    zip: '52585',
    city: 'Richland',
    state: 'IA',
    latitude: '41.182995',
    longitude: '-91.99149',
    timeZoneId: 'America/Chicago',
  },
  '52586': {
    zip: '52586',
    city: 'Rose Hill',
    state: 'IA',
    latitude: '41.180138',
    longitude: '-92.313516',
    timeZoneId: 'America/Chicago',
  },
  '52588': {
    zip: '52588',
    city: 'Selma',
    state: 'IA',
    latitude: '40.857546',
    longitude: '-92.123347',
    timeZoneId: 'America/Chicago',
  },
  '52590': {
    zip: '52590',
    city: 'Seymour',
    state: 'IA',
    latitude: '40.681531',
    longitude: '-93.12061',
    timeZoneId: 'America/Chicago',
  },
  '52591': {
    zip: '52591',
    city: 'Sigourney',
    state: 'IA',
    latitude: '41.332082',
    longitude: '-92.206101',
    timeZoneId: 'America/Chicago',
  },
  '52593': {
    zip: '52593',
    city: 'Udell',
    state: 'IA',
    latitude: '40.82048',
    longitude: '-92.697529',
    timeZoneId: 'America/Chicago',
  },
  '52594': {
    zip: '52594',
    city: 'Unionville',
    state: 'IA',
    latitude: '40.853185',
    longitude: '-92.696609',
    timeZoneId: 'America/Chicago',
  },
  '52595': {
    zip: '52595',
    city: 'University Park',
    state: 'IA',
    latitude: '41.28563',
    longitude: '-92.620526',
    timeZoneId: 'America/Chicago',
  },
  '52601': {
    zip: '52601',
    city: 'Burlington',
    state: 'IA',
    latitude: '40.80606',
    longitude: '-91.120685',
    timeZoneId: 'America/Chicago',
  },
  '52619': {
    zip: '52619',
    city: 'Argyle',
    state: 'IA',
    latitude: '40.56468',
    longitude: '-91.57986',
    timeZoneId: 'America/Chicago',
  },
  '52620': {
    zip: '52620',
    city: 'Bonaparte',
    state: 'IA',
    latitude: '40.698766',
    longitude: '-91.801994',
    timeZoneId: 'America/Chicago',
  },
  '52621': {
    zip: '52621',
    city: 'Crawfordsville',
    state: 'IA',
    latitude: '41.212644',
    longitude: '-91.536955',
    timeZoneId: 'America/Chicago',
  },
  '52623': {
    zip: '52623',
    city: 'Danville',
    state: 'IA',
    latitude: '40.865581',
    longitude: '-91.312756',
    timeZoneId: 'America/Chicago',
  },
  '52624': {
    zip: '52624',
    city: 'Denmark',
    state: 'IA',
    latitude: '40.74275',
    longitude: '-91.340151',
    timeZoneId: 'America/Chicago',
  },
  '52625': {
    zip: '52625',
    city: 'Donnellson',
    state: 'IA',
    latitude: '40.65318',
    longitude: '-91.5507',
    timeZoneId: 'America/Chicago',
  },
  '52626': {
    zip: '52626',
    city: 'Farmington',
    state: 'IA',
    latitude: '40.649487',
    longitude: '-91.745867',
    timeZoneId: 'America/Chicago',
  },
  '52627': {
    zip: '52627',
    city: 'Fort Madison',
    state: 'IA',
    latitude: '40.632072',
    longitude: '-91.335554',
    timeZoneId: 'America/Chicago',
  },
  '52630': {
    zip: '52630',
    city: 'Hillsboro',
    state: 'IA',
    latitude: '40.827784',
    longitude: '-91.708242',
    timeZoneId: 'America/Chicago',
  },
  '52631': {
    zip: '52631',
    city: 'Houghton',
    state: 'IA',
    latitude: '40.770855',
    longitude: '-91.661151',
    timeZoneId: 'America/Chicago',
  },
  '52632': {
    zip: '52632',
    city: 'Keokuk',
    state: 'IA',
    latitude: '40.472778',
    longitude: '-91.448611',
    timeZoneId: 'America/Chicago',
  },
  '52635': {
    zip: '52635',
    city: 'Lockridge',
    state: 'IA',
    latitude: '40.99772',
    longitude: '-91.752281',
    timeZoneId: 'America/Chicago',
  },
  '52637': {
    zip: '52637',
    city: 'Mediapolis',
    state: 'IA',
    latitude: '41.0076',
    longitude: '-91.09794',
    timeZoneId: 'America/Chicago',
  },
  '52638': {
    zip: '52638',
    city: 'Middletown',
    state: 'IA',
    latitude: '40.824699',
    longitude: '-91.262591',
    timeZoneId: 'America/Chicago',
  },
  '52639': {
    zip: '52639',
    city: 'Montrose',
    state: 'IA',
    latitude: '40.528312',
    longitude: '-91.412979',
    timeZoneId: 'America/Chicago',
  },
  '52640': {
    zip: '52640',
    city: 'Morning Sun',
    state: 'IA',
    latitude: '41.093975',
    longitude: '-91.252796',
    timeZoneId: 'America/Chicago',
  },
  '52641': {
    zip: '52641',
    city: 'Mount Pleasant',
    state: 'IA',
    latitude: '40.968419',
    longitude: '-91.548901',
    timeZoneId: 'America/Chicago',
  },
  '52642': {
    zip: '52642',
    city: 'Rome',
    state: 'IA',
    latitude: '40.981518',
    longitude: '-91.680877',
    timeZoneId: 'America/Chicago',
  },
  '52644': {
    zip: '52644',
    city: 'Mount Union',
    state: 'IA',
    latitude: '41.032332',
    longitude: '-91.426886',
    timeZoneId: 'America/Chicago',
  },
  '52645': {
    zip: '52645',
    city: 'New London',
    state: 'IA',
    latitude: '40.923837',
    longitude: '-91.401797',
    timeZoneId: 'America/Chicago',
  },
  '52646': {
    zip: '52646',
    city: 'Oakville',
    state: 'IA',
    latitude: '41.09725',
    longitude: '-91.04331',
    timeZoneId: 'America/Chicago',
  },
  '52647': {
    zip: '52647',
    city: 'Olds',
    state: 'IA',
    latitude: '41.119622',
    longitude: '-91.542675',
    timeZoneId: 'America/Chicago',
  },
  '52648': {
    zip: '52648',
    city: 'Pilot Grove',
    state: 'IA',
    latitude: '40.770301',
    longitude: '-91.547576',
    timeZoneId: 'America/Chicago',
  },
  '52649': {
    zip: '52649',
    city: 'Salem',
    state: 'IA',
    latitude: '40.855483',
    longitude: '-91.622611',
    timeZoneId: 'America/Chicago',
  },
  '52650': {
    zip: '52650',
    city: 'Sperry',
    state: 'IA',
    latitude: '40.943056',
    longitude: '-91.180556',
    timeZoneId: 'America/Chicago',
  },
  '52651': {
    zip: '52651',
    city: 'Stockport',
    state: 'IA',
    latitude: '40.859744',
    longitude: '-91.834252',
    timeZoneId: 'America/Chicago',
  },
  '52652': {
    zip: '52652',
    city: 'Swedesburg',
    state: 'IA',
    latitude: '41.119162',
    longitude: '-91.540226',
    timeZoneId: 'America/Chicago',
  },
  '52653': {
    zip: '52653',
    city: 'Wapello',
    state: 'IA',
    latitude: '41.179547',
    longitude: '-91.188068',
    timeZoneId: 'America/Chicago',
  },
  '52654': {
    zip: '52654',
    city: 'Wayland',
    state: 'IA',
    latitude: '41.145353',
    longitude: '-91.664079',
    timeZoneId: 'America/Chicago',
  },
  '52655': {
    zip: '52655',
    city: 'West Burlington',
    state: 'IA',
    latitude: '40.826742',
    longitude: '-91.165656',
    timeZoneId: 'America/Chicago',
  },
  '52656': {
    zip: '52656',
    city: 'West Point',
    state: 'IA',
    latitude: '40.716675',
    longitude: '-91.454585',
    timeZoneId: 'America/Chicago',
  },
  '52657': {
    zip: '52657',
    city: 'Saint Paul',
    state: 'IA',
    latitude: '40.768772',
    longitude: '-91.541663',
    timeZoneId: 'America/Chicago',
  },
  '52658': {
    zip: '52658',
    city: 'Wever',
    state: 'IA',
    latitude: '40.683871',
    longitude: '-91.199609',
    timeZoneId: 'America/Chicago',
  },
  '52659': {
    zip: '52659',
    city: 'Winfield',
    state: 'IA',
    latitude: '41.126832',
    longitude: '-91.436627',
    timeZoneId: 'America/Chicago',
  },
  '52660': {
    zip: '52660',
    city: 'Yarmouth',
    state: 'IA',
    latitude: '41.032221',
    longitude: '-91.31468',
    timeZoneId: 'America/Chicago',
  },
  '52701': {
    zip: '52701',
    city: 'Andover',
    state: 'IA',
    latitude: '41.987315',
    longitude: '-90.239923',
    timeZoneId: 'America/Chicago',
  },
  '52720': {
    zip: '52720',
    city: 'Atalissa',
    state: 'IA',
    latitude: '41.569059',
    longitude: '-91.162052',
    timeZoneId: 'America/Chicago',
  },
  '52721': {
    zip: '52721',
    city: 'Bennett',
    state: 'IA',
    latitude: '41.743424',
    longitude: '-90.971603',
    timeZoneId: 'America/Chicago',
  },
  '52722': {
    zip: '52722',
    city: 'Bettendorf',
    state: 'IA',
    latitude: '41.56722',
    longitude: '-90.462',
    timeZoneId: 'America/Chicago',
  },
  '52726': {
    zip: '52726',
    city: 'Blue Grass',
    state: 'IA',
    latitude: '41.498229',
    longitude: '-90.757156',
    timeZoneId: 'America/Chicago',
  },
  '52727': {
    zip: '52727',
    city: 'Bryant',
    state: 'IA',
    latitude: '41.973508',
    longitude: '-90.342726',
    timeZoneId: 'America/Chicago',
  },
  '52728': {
    zip: '52728',
    city: 'Buffalo',
    state: 'IA',
    latitude: '41.454459',
    longitude: '-90.726424',
    timeZoneId: 'America/Chicago',
  },
  '52729': {
    zip: '52729',
    city: 'Calamus',
    state: 'IA',
    latitude: '41.828564',
    longitude: '-90.756519',
    timeZoneId: 'America/Chicago',
  },
  '52730': {
    zip: '52730',
    city: 'Camanche',
    state: 'IA',
    latitude: '41.785818',
    longitude: '-90.26171',
    timeZoneId: 'America/Chicago',
  },
  '52731': {
    zip: '52731',
    city: 'Charlotte',
    state: 'IA',
    latitude: '41.96304',
    longitude: '-90.47113',
    timeZoneId: 'America/Chicago',
  },
  '52732': {
    zip: '52732',
    city: 'Clinton',
    state: 'IA',
    latitude: '41.849037',
    longitude: '-90.208697',
    timeZoneId: 'America/Chicago',
  },
  '52733': {
    zip: '52733',
    city: 'Clinton',
    state: 'IA',
    latitude: '41.847697',
    longitude: '-90.187345',
    timeZoneId: 'America/Chicago',
  },
  '52734': {
    zip: '52734',
    city: 'Clinton',
    state: 'IA',
    latitude: '41.848613',
    longitude: '-90.208528',
    timeZoneId: 'America/Chicago',
  },
  '52736': {
    zip: '52736',
    city: 'Clinton',
    state: 'IA',
    latitude: '41.842528',
    longitude: '-90.231685',
    timeZoneId: 'America/Chicago',
  },
  '52737': {
    zip: '52737',
    city: 'Columbus City',
    state: 'IA',
    latitude: '41.264947',
    longitude: '-91.370101',
    timeZoneId: 'America/Chicago',
  },
  '52738': {
    zip: '52738',
    city: 'Columbus Junction',
    state: 'IA',
    latitude: '41.28258',
    longitude: '-91.38222',
    timeZoneId: 'America/Chicago',
  },
  '52739': {
    zip: '52739',
    city: 'Conesville',
    state: 'IA',
    latitude: '41.383565',
    longitude: '-91.350458',
    timeZoneId: 'America/Chicago',
  },
  '52742': {
    zip: '52742',
    city: 'De Witt',
    state: 'IA',
    latitude: '41.82354',
    longitude: '-90.4602',
    timeZoneId: 'America/Chicago',
  },
  '52745': {
    zip: '52745',
    city: 'Dixon',
    state: 'IA',
    latitude: '41.770278',
    longitude: '-90.826111',
    timeZoneId: 'America/Chicago',
  },
  '52746': {
    zip: '52746',
    city: 'Donahue',
    state: 'IA',
    latitude: '41.711318',
    longitude: '-90.678943',
    timeZoneId: 'America/Chicago',
  },
  '52747': {
    zip: '52747',
    city: 'Durant',
    state: 'IA',
    latitude: '41.600384',
    longitude: '-90.909392',
    timeZoneId: 'America/Chicago',
  },
  '52748': {
    zip: '52748',
    city: 'Eldridge',
    state: 'IA',
    latitude: '41.663915',
    longitude: '-90.556726',
    timeZoneId: 'America/Chicago',
  },
  '52749': {
    zip: '52749',
    city: 'Fruitland',
    state: 'IA',
    latitude: '41.356809',
    longitude: '-91.12398',
    timeZoneId: 'America/Chicago',
  },
  '52750': {
    zip: '52750',
    city: 'Goose Lake',
    state: 'IA',
    latitude: '41.993759',
    longitude: '-90.411063',
    timeZoneId: 'America/Chicago',
  },
  '52751': {
    zip: '52751',
    city: 'Grand Mound',
    state: 'IA',
    latitude: '41.844423',
    longitude: '-90.677731',
    timeZoneId: 'America/Chicago',
  },
  '52752': {
    zip: '52752',
    city: 'Grandview',
    state: 'IA',
    latitude: '41.279733',
    longitude: '-91.188208',
    timeZoneId: 'America/Chicago',
  },
  '52753': {
    zip: '52753',
    city: 'Le Claire',
    state: 'IA',
    latitude: '41.606862',
    longitude: '-90.370939',
    timeZoneId: 'America/Chicago',
  },
  '52754': {
    zip: '52754',
    city: 'Letts',
    state: 'IA',
    latitude: '41.328289',
    longitude: '-91.235037',
    timeZoneId: 'America/Chicago',
  },
  '52755': {
    zip: '52755',
    city: 'Lone Tree',
    state: 'IA',
    latitude: '41.483617',
    longitude: '-91.428653',
    timeZoneId: 'America/Chicago',
  },
  '52756': {
    zip: '52756',
    city: 'Long Grove',
    state: 'IA',
    latitude: '41.721476',
    longitude: '-90.549616',
    timeZoneId: 'America/Chicago',
  },
  '52757': {
    zip: '52757',
    city: 'Low Moor',
    state: 'IA',
    latitude: '41.838174',
    longitude: '-90.47893',
    timeZoneId: 'America/Chicago',
  },
  '52758': {
    zip: '52758',
    city: 'Mc Causland',
    state: 'IA',
    latitude: '41.745733',
    longitude: '-90.447474',
    timeZoneId: 'America/Chicago',
  },
  '52759': {
    zip: '52759',
    city: 'Montpelier',
    state: 'IA',
    latitude: '41.461303',
    longitude: '-90.808311',
    timeZoneId: 'America/Chicago',
  },
  '52760': {
    zip: '52760',
    city: 'Moscow',
    state: 'IA',
    latitude: '41.580616',
    longitude: '-91.050304',
    timeZoneId: 'America/Chicago',
  },
  '52761': {
    zip: '52761',
    city: 'Muscatine',
    state: 'IA',
    latitude: '41.435833',
    longitude: '-90.904444',
    timeZoneId: 'America/Chicago',
  },
  '52765': {
    zip: '52765',
    city: 'New Liberty',
    state: 'IA',
    latitude: '41.727867',
    longitude: '-90.862476',
    timeZoneId: 'America/Chicago',
  },
  '52766': {
    zip: '52766',
    city: 'Nichols',
    state: 'IA',
    latitude: '41.479309',
    longitude: '-91.305402',
    timeZoneId: 'America/Chicago',
  },
  '52767': {
    zip: '52767',
    city: 'Pleasant Valley',
    state: 'IA',
    latitude: '41.569175',
    longitude: '-90.423855',
    timeZoneId: 'America/Chicago',
  },
  '52768': {
    zip: '52768',
    city: 'Princeton',
    state: 'IA',
    latitude: '41.685749',
    longitude: '-90.382646',
    timeZoneId: 'America/Chicago',
  },
  '52769': {
    zip: '52769',
    city: 'Stockton',
    state: 'IA',
    latitude: '41.654523',
    longitude: '-90.835711',
    timeZoneId: 'America/Chicago',
  },
  '52771': {
    zip: '52771',
    city: 'Teeds Grove',
    state: 'IA',
    latitude: '42.011516',
    longitude: '-90.244984',
    timeZoneId: 'America/Chicago',
  },
  '52772': {
    zip: '52772',
    city: 'Tipton',
    state: 'IA',
    latitude: '41.78826',
    longitude: '-91.13124',
    timeZoneId: 'America/Chicago',
  },
  '52773': {
    zip: '52773',
    city: 'Walcott',
    state: 'IA',
    latitude: '41.63646',
    longitude: '-90.75894',
    timeZoneId: 'America/Chicago',
  },
  '52774': {
    zip: '52774',
    city: 'Welton',
    state: 'IA',
    latitude: '41.905202',
    longitude: '-90.609367',
    timeZoneId: 'America/Chicago',
  },
  '52776': {
    zip: '52776',
    city: 'West Liberty',
    state: 'IA',
    latitude: '41.575495',
    longitude: '-91.261347',
    timeZoneId: 'America/Chicago',
  },
  '52777': {
    zip: '52777',
    city: 'Wheatland',
    state: 'IA',
    latitude: '41.832932',
    longitude: '-90.840574',
    timeZoneId: 'America/Chicago',
  },
  '52778': {
    zip: '52778',
    city: 'Wilton',
    state: 'IA',
    latitude: '41.590341',
    longitude: '-91.01928',
    timeZoneId: 'America/Chicago',
  },
  '52801': {
    zip: '52801',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.522295',
    longitude: '-90.577561',
    timeZoneId: 'America/Chicago',
  },
  '52802': {
    zip: '52802',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.512014',
    longitude: '-90.62454',
    timeZoneId: 'America/Chicago',
  },
  '52803': {
    zip: '52803',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.539303',
    longitude: '-90.55421',
    timeZoneId: 'America/Chicago',
  },
  '52804': {
    zip: '52804',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.545202',
    longitude: '-90.619714',
    timeZoneId: 'America/Chicago',
  },
  '52805': {
    zip: '52805',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.572625',
    longitude: '-90.602314',
    timeZoneId: 'America/Chicago',
  },
  '52806': {
    zip: '52806',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.571562',
    longitude: '-90.60316',
    timeZoneId: 'America/Chicago',
  },
  '52807': {
    zip: '52807',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.563174',
    longitude: '-90.538577',
    timeZoneId: 'America/Chicago',
  },
  '52808': {
    zip: '52808',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.503182',
    longitude: '-90.633992',
    timeZoneId: 'America/Chicago',
  },
  '52809': {
    zip: '52809',
    city: 'Davenport',
    state: 'IA',
    latitude: '41.560101',
    longitude: '-90.632535',
    timeZoneId: 'America/Chicago',
  },
  '53001': {
    zip: '53001',
    city: 'Adell',
    state: 'WI',
    latitude: '43.622721',
    longitude: '-87.956471',
    timeZoneId: 'America/Chicago',
  },
  '53002': {
    zip: '53002',
    city: 'Allenton',
    state: 'WI',
    latitude: '43.458494',
    longitude: '-88.359486',
    timeZoneId: 'America/Chicago',
  },
  '53003': {
    zip: '53003',
    city: 'Ashippun',
    state: 'WI',
    latitude: '43.213134',
    longitude: '-88.51695',
    timeZoneId: 'America/Chicago',
  },
  '53004': {
    zip: '53004',
    city: 'Belgium',
    state: 'WI',
    latitude: '43.498221',
    longitude: '-87.860708',
    timeZoneId: 'America/Chicago',
  },
  '53005': {
    zip: '53005',
    city: 'Brookfield',
    state: 'WI',
    latitude: '43.064855',
    longitude: '-88.104493',
    timeZoneId: 'America/Chicago',
  },
  '53006': {
    zip: '53006',
    city: 'Brownsville',
    state: 'WI',
    latitude: '43.6152',
    longitude: '-88.51698',
    timeZoneId: 'America/Chicago',
  },
  '53007': {
    zip: '53007',
    city: 'Butler',
    state: 'WI',
    latitude: '43.106178',
    longitude: '-88.070608',
    timeZoneId: 'America/Chicago',
  },
  '53008': {
    zip: '53008',
    city: 'Brookfield',
    state: 'WI',
    latitude: '43.06199',
    longitude: '-88.109637',
    timeZoneId: 'America/Chicago',
  },
  '53010': {
    zip: '53010',
    city: 'Campbellsport',
    state: 'WI',
    latitude: '43.610388',
    longitude: '-88.277922',
    timeZoneId: 'America/Chicago',
  },
  '53011': {
    zip: '53011',
    city: 'Cascade',
    state: 'WI',
    latitude: '43.659276',
    longitude: '-88.033207',
    timeZoneId: 'America/Chicago',
  },
  '53012': {
    zip: '53012',
    city: 'Cedarburg',
    state: 'WI',
    latitude: '43.312977',
    longitude: '-88.043817',
    timeZoneId: 'America/Chicago',
  },
  '53013': {
    zip: '53013',
    city: 'Cedar Grove',
    state: 'WI',
    latitude: '43.571376',
    longitude: '-87.844404',
    timeZoneId: 'America/Chicago',
  },
  '53014': {
    zip: '53014',
    city: 'Chilton',
    state: 'WI',
    latitude: '44.030916',
    longitude: '-88.19326',
    timeZoneId: 'America/Chicago',
  },
  '53015': {
    zip: '53015',
    city: 'Cleveland',
    state: 'WI',
    latitude: '43.918804',
    longitude: '-87.767648',
    timeZoneId: 'America/Chicago',
  },
  '53016': {
    zip: '53016',
    city: 'Clyman',
    state: 'WI',
    latitude: '43.313962',
    longitude: '-88.722855',
    timeZoneId: 'America/Chicago',
  },
  '53017': {
    zip: '53017',
    city: 'Colgate',
    state: 'WI',
    latitude: '43.204933',
    longitude: '-88.2432',
    timeZoneId: 'America/Chicago',
  },
  '53018': {
    zip: '53018',
    city: 'Delafield',
    state: 'WI',
    latitude: '43.063198',
    longitude: '-88.40258',
    timeZoneId: 'America/Chicago',
  },
  '53019': {
    zip: '53019',
    city: 'Eden',
    state: 'WI',
    latitude: '43.695123',
    longitude: '-88.338784',
    timeZoneId: 'America/Chicago',
  },
  '53020': {
    zip: '53020',
    city: 'Elkhart Lake',
    state: 'WI',
    latitude: '43.829547',
    longitude: '-88.01815',
    timeZoneId: 'America/Chicago',
  },
  '53021': {
    zip: '53021',
    city: 'Fredonia',
    state: 'WI',
    latitude: '43.4985',
    longitude: '-88.00572',
    timeZoneId: 'America/Chicago',
  },
  '53022': {
    zip: '53022',
    city: 'Germantown',
    state: 'WI',
    latitude: '43.224428',
    longitude: '-88.145134',
    timeZoneId: 'America/Chicago',
  },
  '53023': {
    zip: '53023',
    city: 'Glenbeulah',
    state: 'WI',
    latitude: '43.792158',
    longitude: '-88.078669',
    timeZoneId: 'America/Chicago',
  },
  '53024': {
    zip: '53024',
    city: 'Grafton',
    state: 'WI',
    latitude: '43.32656',
    longitude: '-87.930502',
    timeZoneId: 'America/Chicago',
  },
  '53026': {
    zip: '53026',
    city: 'Greenbush',
    state: 'WI',
    latitude: '43.778693',
    longitude: '-88.080218',
    timeZoneId: 'America/Chicago',
  },
  '53027': {
    zip: '53027',
    city: 'Hartford',
    state: 'WI',
    latitude: '43.300873',
    longitude: '-88.364442',
    timeZoneId: 'America/Chicago',
  },
  '53029': {
    zip: '53029',
    city: 'Hartland',
    state: 'WI',
    latitude: '43.106382',
    longitude: '-88.351136',
    timeZoneId: 'America/Chicago',
  },
  '53031': {
    zip: '53031',
    city: 'Hingham',
    state: 'WI',
    latitude: '43.639932',
    longitude: '-87.919809',
    timeZoneId: 'America/Chicago',
  },
  '53032': {
    zip: '53032',
    city: 'Horicon',
    state: 'WI',
    latitude: '43.450498',
    longitude: '-88.630976',
    timeZoneId: 'America/Chicago',
  },
  '53033': {
    zip: '53033',
    city: 'Hubertus',
    state: 'WI',
    latitude: '43.233131',
    longitude: '-88.248081',
    timeZoneId: 'America/Chicago',
  },
  '53034': {
    zip: '53034',
    city: 'Hustisford',
    state: 'WI',
    latitude: '43.343769',
    longitude: '-88.602485',
    timeZoneId: 'America/Chicago',
  },
  '53035': {
    zip: '53035',
    city: 'Iron Ridge',
    state: 'WI',
    latitude: '43.394704',
    longitude: '-88.534239',
    timeZoneId: 'America/Chicago',
  },
  '53036': {
    zip: '53036',
    city: 'Ixonia',
    state: 'WI',
    latitude: '43.135872',
    longitude: '-88.583574',
    timeZoneId: 'America/Chicago',
  },
  '53037': {
    zip: '53037',
    city: 'Jackson',
    state: 'WI',
    latitude: '43.319801',
    longitude: '-88.162627',
    timeZoneId: 'America/Chicago',
  },
  '53038': {
    zip: '53038',
    city: 'Johnson Creek',
    state: 'WI',
    latitude: '43.072467',
    longitude: '-88.776005',
    timeZoneId: 'America/Chicago',
  },
  '53039': {
    zip: '53039',
    city: 'Juneau',
    state: 'WI',
    latitude: '43.395816',
    longitude: '-88.684251',
    timeZoneId: 'America/Chicago',
  },
  '53040': {
    zip: '53040',
    city: 'Kewaskum',
    state: 'WI',
    latitude: '43.510597',
    longitude: '-88.204941',
    timeZoneId: 'America/Chicago',
  },
  '53042': {
    zip: '53042',
    city: 'Kiel',
    state: 'WI',
    latitude: '43.925523',
    longitude: '-87.986674',
    timeZoneId: 'America/Chicago',
  },
  '53044': {
    zip: '53044',
    city: 'Kohler',
    state: 'WI',
    latitude: '43.740624',
    longitude: '-87.785575',
    timeZoneId: 'America/Chicago',
  },
  '53045': {
    zip: '53045',
    city: 'Brookfield',
    state: 'WI',
    latitude: '43.061327',
    longitude: '-88.155393',
    timeZoneId: 'America/Chicago',
  },
  '53046': {
    zip: '53046',
    city: 'Lannon',
    state: 'WI',
    latitude: '43.152352',
    longitude: '-88.167487',
    timeZoneId: 'America/Chicago',
  },
  '53047': {
    zip: '53047',
    city: 'Lebanon',
    state: 'WI',
    latitude: '43.223347',
    longitude: '-88.748563',
    timeZoneId: 'America/Chicago',
  },
  '53048': {
    zip: '53048',
    city: 'Lomira',
    state: 'WI',
    latitude: '43.590376',
    longitude: '-88.447118',
    timeZoneId: 'America/Chicago',
  },
  '53049': {
    zip: '53049',
    city: 'Malone',
    state: 'WI',
    latitude: '43.84475',
    longitude: '-88.345131',
    timeZoneId: 'America/Chicago',
  },
  '53050': {
    zip: '53050',
    city: 'Mayville',
    state: 'WI',
    latitude: '43.499689',
    longitude: '-88.546329',
    timeZoneId: 'America/Chicago',
  },
  '53051': {
    zip: '53051',
    city: 'Menomonee Falls',
    state: 'WI',
    latitude: '43.145602',
    longitude: '-88.115779',
    timeZoneId: 'America/Chicago',
  },
  '53052': {
    zip: '53052',
    city: 'Menomonee Falls',
    state: 'WI',
    latitude: '43.178003',
    longitude: '-88.119438',
    timeZoneId: 'America/Chicago',
  },
  '53056': {
    zip: '53056',
    city: 'Merton',
    state: 'WI',
    latitude: '43.147758',
    longitude: '-88.307088',
    timeZoneId: 'America/Chicago',
  },
  '53057': {
    zip: '53057',
    city: 'Mount Calvary',
    state: 'WI',
    latitude: '43.822404',
    longitude: '-88.246314',
    timeZoneId: 'America/Chicago',
  },
  '53058': {
    zip: '53058',
    city: 'Nashotah',
    state: 'WI',
    latitude: '43.112049',
    longitude: '-88.405116',
    timeZoneId: 'America/Chicago',
  },
  '53059': {
    zip: '53059',
    city: 'Neosho',
    state: 'WI',
    latitude: '43.311909',
    longitude: '-88.525006',
    timeZoneId: 'America/Chicago',
  },
  '53060': {
    zip: '53060',
    city: 'Newburg',
    state: 'WI',
    latitude: '43.430905',
    longitude: '-88.045708',
    timeZoneId: 'America/Chicago',
  },
  '53061': {
    zip: '53061',
    city: 'New Holstein',
    state: 'WI',
    latitude: '43.947588',
    longitude: '-88.089299',
    timeZoneId: 'America/Chicago',
  },
  '53062': {
    zip: '53062',
    city: 'New Holstein',
    state: 'WI',
    latitude: '43.947498',
    longitude: '-88.101978',
    timeZoneId: 'America/Chicago',
  },
  '53063': {
    zip: '53063',
    city: 'Newton',
    state: 'WI',
    latitude: '43.969789',
    longitude: '-87.794434',
    timeZoneId: 'America/Chicago',
  },
  '53064': {
    zip: '53064',
    city: 'North Lake',
    state: 'WI',
    latitude: '43.160497',
    longitude: '-88.371653',
    timeZoneId: 'America/Chicago',
  },
  '53065': {
    zip: '53065',
    city: 'Oakfield',
    state: 'WI',
    latitude: '43.684255',
    longitude: '-88.546863',
    timeZoneId: 'America/Chicago',
  },
  '53066': {
    zip: '53066',
    city: 'Oconomowoc',
    state: 'WI',
    latitude: '43.105369',
    longitude: '-88.479152',
    timeZoneId: 'America/Chicago',
  },
  '53069': {
    zip: '53069',
    city: 'Okauchee',
    state: 'WI',
    latitude: '43.113593',
    longitude: '-88.443741',
    timeZoneId: 'America/Chicago',
  },
  '53070': {
    zip: '53070',
    city: 'Oostburg',
    state: 'WI',
    latitude: '43.627466',
    longitude: '-87.792764',
    timeZoneId: 'America/Chicago',
  },
  '53072': {
    zip: '53072',
    city: 'Pewaukee',
    state: 'WI',
    latitude: '43.078937',
    longitude: '-88.256368',
    timeZoneId: 'America/Chicago',
  },
  '53073': {
    zip: '53073',
    city: 'Plymouth',
    state: 'WI',
    latitude: '43.750559',
    longitude: '-87.979314',
    timeZoneId: 'America/Chicago',
  },
  '53074': {
    zip: '53074',
    city: 'Port Washington',
    state: 'WI',
    latitude: '43.421132',
    longitude: '-87.876731',
    timeZoneId: 'America/Chicago',
  },
  '53075': {
    zip: '53075',
    city: 'Random Lake',
    state: 'WI',
    latitude: '43.553924',
    longitude: '-87.951651',
    timeZoneId: 'America/Chicago',
  },
  '53076': {
    zip: '53076',
    city: 'Richfield',
    state: 'WI',
    latitude: '43.268248',
    longitude: '-88.218877',
    timeZoneId: 'America/Chicago',
  },
  '53078': {
    zip: '53078',
    city: 'Rubicon',
    state: 'WI',
    latitude: '43.326045',
    longitude: '-88.424348',
    timeZoneId: 'America/Chicago',
  },
  '53079': {
    zip: '53079',
    city: 'Saint Cloud',
    state: 'WI',
    latitude: '43.795101',
    longitude: '-88.175712',
    timeZoneId: 'America/Chicago',
  },
  '53080': {
    zip: '53080',
    city: 'Saukville',
    state: 'WI',
    latitude: '43.387123',
    longitude: '-87.967557',
    timeZoneId: 'America/Chicago',
  },
  '53081': {
    zip: '53081',
    city: 'Sheboygan',
    state: 'WI',
    latitude: '43.741822',
    longitude: '-87.728355',
    timeZoneId: 'America/Chicago',
  },
  '53082': {
    zip: '53082',
    city: 'Sheboygan',
    state: 'WI',
    latitude: '43.765755',
    longitude: '-87.76871',
    timeZoneId: 'America/Chicago',
  },
  '53083': {
    zip: '53083',
    city: 'Sheboygan',
    state: 'WI',
    latitude: '43.80234',
    longitude: '-87.7698',
    timeZoneId: 'America/Chicago',
  },
  '53085': {
    zip: '53085',
    city: 'Sheboygan Falls',
    state: 'WI',
    latitude: '43.731424',
    longitude: '-87.816063',
    timeZoneId: 'America/Chicago',
  },
  '53086': {
    zip: '53086',
    city: 'Slinger',
    state: 'WI',
    latitude: '43.330414',
    longitude: '-88.278981',
    timeZoneId: 'America/Chicago',
  },
  '53088': {
    zip: '53088',
    city: 'Stockbridge',
    state: 'WI',
    latitude: '44.075694',
    longitude: '-88.303086',
    timeZoneId: 'America/Chicago',
  },
  '53089': {
    zip: '53089',
    city: 'Sussex',
    state: 'WI',
    latitude: '43.153034',
    longitude: '-88.234855',
    timeZoneId: 'America/Chicago',
  },
  '53090': {
    zip: '53090',
    city: 'West Bend',
    state: 'WI',
    latitude: '43.449492',
    longitude: '-88.171297',
    timeZoneId: 'America/Chicago',
  },
  '53091': {
    zip: '53091',
    city: 'Theresa',
    state: 'WI',
    latitude: '43.516681',
    longitude: '-88.453725',
    timeZoneId: 'America/Chicago',
  },
  '53092': {
    zip: '53092',
    city: 'Mequon',
    state: 'WI',
    latitude: '43.22148',
    longitude: '-87.95052',
    timeZoneId: 'America/Chicago',
  },
  '53093': {
    zip: '53093',
    city: 'Waldo',
    state: 'WI',
    latitude: '43.676495',
    longitude: '-87.941985',
    timeZoneId: 'America/Chicago',
  },
  '53094': {
    zip: '53094',
    city: 'Watertown',
    state: 'WI',
    latitude: '43.187721',
    longitude: '-88.723178',
    timeZoneId: 'America/Chicago',
  },
  '53095': {
    zip: '53095',
    city: 'West Bend',
    state: 'WI',
    latitude: '43.390226',
    longitude: '-88.167388',
    timeZoneId: 'America/Chicago',
  },
  '53097': {
    zip: '53097',
    city: 'Mequon',
    state: 'WI',
    latitude: '43.23648',
    longitude: '-87.9846',
    timeZoneId: 'America/Chicago',
  },
  '53098': {
    zip: '53098',
    city: 'Watertown',
    state: 'WI',
    latitude: '43.205791',
    longitude: '-88.721802',
    timeZoneId: 'America/Chicago',
  },
  '53099': {
    zip: '53099',
    city: 'Woodland',
    state: 'WI',
    latitude: '43.366744',
    longitude: '-88.517082',
    timeZoneId: 'America/Chicago',
  },
  '53101': {
    zip: '53101',
    city: 'Bassett',
    state: 'WI',
    latitude: '42.538597',
    longitude: '-88.227841',
    timeZoneId: 'America/Chicago',
  },
  '53102': {
    zip: '53102',
    city: 'Benet Lake',
    state: 'WI',
    latitude: '42.50437',
    longitude: '-88.07726',
    timeZoneId: 'America/Chicago',
  },
  '53103': {
    zip: '53103',
    city: 'Big Bend',
    state: 'WI',
    latitude: '42.878515',
    longitude: '-88.217994',
    timeZoneId: 'America/Chicago',
  },
  '53104': {
    zip: '53104',
    city: 'Bristol',
    state: 'WI',
    latitude: '42.541955',
    longitude: '-88.045185',
    timeZoneId: 'America/Chicago',
  },
  '53105': {
    zip: '53105',
    city: 'Burlington',
    state: 'WI',
    latitude: '42.677953',
    longitude: '-88.26588',
    timeZoneId: 'America/Chicago',
  },
  '53108': {
    zip: '53108',
    city: 'Caledonia',
    state: 'WI',
    latitude: '42.827165',
    longitude: '-87.93608',
    timeZoneId: 'America/Chicago',
  },
  '53109': {
    zip: '53109',
    city: 'Camp Lake',
    state: 'WI',
    latitude: '42.540942',
    longitude: '-88.140019',
    timeZoneId: 'America/Chicago',
  },
  '53110': {
    zip: '53110',
    city: 'Cudahy',
    state: 'WI',
    latitude: '42.947125',
    longitude: '-87.861954',
    timeZoneId: 'America/Chicago',
  },
  '53114': {
    zip: '53114',
    city: 'Darien',
    state: 'WI',
    latitude: '42.601566',
    longitude: '-88.704996',
    timeZoneId: 'America/Chicago',
  },
  '53115': {
    zip: '53115',
    city: 'Delavan',
    state: 'WI',
    latitude: '42.613597',
    longitude: '-88.628941',
    timeZoneId: 'America/Chicago',
  },
  '53118': {
    zip: '53118',
    city: 'Dousman',
    state: 'WI',
    latitude: '42.976521',
    longitude: '-88.480955',
    timeZoneId: 'America/Chicago',
  },
  '53119': {
    zip: '53119',
    city: 'Eagle',
    state: 'WI',
    latitude: '42.87712',
    longitude: '-88.468233',
    timeZoneId: 'America/Chicago',
  },
  '53120': {
    zip: '53120',
    city: 'East Troy',
    state: 'WI',
    latitude: '42.803402',
    longitude: '-88.415506',
    timeZoneId: 'America/Chicago',
  },
  '53121': {
    zip: '53121',
    city: 'Elkhorn',
    state: 'WI',
    latitude: '42.682649',
    longitude: '-88.539027',
    timeZoneId: 'America/Chicago',
  },
  '53122': {
    zip: '53122',
    city: 'Elm Grove',
    state: 'WI',
    latitude: '43.048854',
    longitude: '-88.086552',
    timeZoneId: 'America/Chicago',
  },
  '53125': {
    zip: '53125',
    city: 'Fontana',
    state: 'WI',
    latitude: '42.54314',
    longitude: '-88.56693',
    timeZoneId: 'America/Chicago',
  },
  '53126': {
    zip: '53126',
    city: 'Franksville',
    state: 'WI',
    latitude: '42.779997',
    longitude: '-87.960272',
    timeZoneId: 'America/Chicago',
  },
  '53127': {
    zip: '53127',
    city: 'Genesee Depot',
    state: 'WI',
    latitude: '42.971014',
    longitude: '-88.371705',
    timeZoneId: 'America/Chicago',
  },
  '53128': {
    zip: '53128',
    city: 'Genoa City',
    state: 'WI',
    latitude: '42.536232',
    longitude: '-88.294642',
    timeZoneId: 'America/Chicago',
  },
  '53129': {
    zip: '53129',
    city: 'Greendale',
    state: 'WI',
    latitude: '42.938162',
    longitude: '-87.995124',
    timeZoneId: 'America/Chicago',
  },
  '53130': {
    zip: '53130',
    city: 'Hales Corners',
    state: 'WI',
    latitude: '42.939685',
    longitude: '-88.050138',
    timeZoneId: 'America/Chicago',
  },
  '53132': {
    zip: '53132',
    city: 'Franklin',
    state: 'WI',
    latitude: '42.88728',
    longitude: '-88.00956',
    timeZoneId: 'America/Chicago',
  },
  '53137': {
    zip: '53137',
    city: 'Helenville',
    state: 'WI',
    latitude: '43.057252',
    longitude: '-88.69454',
    timeZoneId: 'America/Chicago',
  },
  '53138': {
    zip: '53138',
    city: 'Honey Creek',
    state: 'WI',
    latitude: '42.745569',
    longitude: '-88.305445',
    timeZoneId: 'America/Chicago',
  },
  '53139': {
    zip: '53139',
    city: 'Kansasville',
    state: 'WI',
    latitude: '42.700056',
    longitude: '-88.127065',
    timeZoneId: 'America/Chicago',
  },
  '53140': {
    zip: '53140',
    city: 'Kenosha',
    state: 'WI',
    latitude: '42.577888',
    longitude: '-87.830792',
    timeZoneId: 'America/Chicago',
  },
  '53141': {
    zip: '53141',
    city: 'Kenosha',
    state: 'WI',
    latitude: '42.583868',
    longitude: '-87.822442',
    timeZoneId: 'America/Chicago',
  },
  '53142': {
    zip: '53142',
    city: 'Kenosha',
    state: 'WI',
    latitude: '42.573269',
    longitude: '-87.880354',
    timeZoneId: 'America/Chicago',
  },
  '53143': {
    zip: '53143',
    city: 'Kenosha',
    state: 'WI',
    latitude: '42.544106',
    longitude: '-87.837568',
    timeZoneId: 'America/Chicago',
  },
  '53144': {
    zip: '53144',
    city: 'Kenosha',
    state: 'WI',
    latitude: '42.64996',
    longitude: '-87.869149',
    timeZoneId: 'America/Chicago',
  },
  '53146': {
    zip: '53146',
    city: 'New Berlin',
    state: 'WI',
    latitude: '42.971531',
    longitude: '-88.157905',
    timeZoneId: 'America/Chicago',
  },
  '53147': {
    zip: '53147',
    city: 'Lake Geneva',
    state: 'WI',
    latitude: '42.590378',
    longitude: '-88.43964',
    timeZoneId: 'America/Chicago',
  },
  '53148': {
    zip: '53148',
    city: 'Lyons',
    state: 'WI',
    latitude: '42.649186',
    longitude: '-88.355887',
    timeZoneId: 'America/Chicago',
  },
  '53149': {
    zip: '53149',
    city: 'Mukwonago',
    state: 'WI',
    latitude: '42.867284',
    longitude: '-88.337814',
    timeZoneId: 'America/Chicago',
  },
  '53150': {
    zip: '53150',
    city: 'Muskego',
    state: 'WI',
    latitude: '42.891978',
    longitude: '-88.124961',
    timeZoneId: 'America/Chicago',
  },
  '53151': {
    zip: '53151',
    city: 'New Berlin',
    state: 'WI',
    latitude: '42.976655',
    longitude: '-88.098995',
    timeZoneId: 'America/Chicago',
  },
  '53152': {
    zip: '53152',
    city: 'New Munster',
    state: 'WI',
    latitude: '42.577244',
    longitude: '-88.226018',
    timeZoneId: 'America/Chicago',
  },
  '53153': {
    zip: '53153',
    city: 'North Prairie',
    state: 'WI',
    latitude: '42.945745',
    longitude: '-88.424589',
    timeZoneId: 'America/Chicago',
  },
  '53154': {
    zip: '53154',
    city: 'Oak Creek',
    state: 'WI',
    latitude: '42.88606',
    longitude: '-87.903062',
    timeZoneId: 'America/Chicago',
  },
  '53156': {
    zip: '53156',
    city: 'Palmyra',
    state: 'WI',
    latitude: '42.875121',
    longitude: '-88.58522',
    timeZoneId: 'America/Chicago',
  },
  '53157': {
    zip: '53157',
    city: 'Pell Lake',
    state: 'WI',
    latitude: '42.541376',
    longitude: '-88.354927',
    timeZoneId: 'America/Chicago',
  },
  '53158': {
    zip: '53158',
    city: 'Pleasant Prairie',
    state: 'WI',
    latitude: '42.551177',
    longitude: '-87.931552',
    timeZoneId: 'America/Chicago',
  },
  '53159': {
    zip: '53159',
    city: 'Powers Lake',
    state: 'WI',
    latitude: '42.553163',
    longitude: '-88.293993',
    timeZoneId: 'America/Chicago',
  },
  '53167': {
    zip: '53167',
    city: 'Rochester',
    state: 'WI',
    latitude: '42.742634',
    longitude: '-88.225342',
    timeZoneId: 'America/Chicago',
  },
  '53168': {
    zip: '53168',
    city: 'Salem',
    state: 'WI',
    latitude: '42.57864',
    longitude: '-88.13664',
    timeZoneId: 'America/Chicago',
  },
  '53170': {
    zip: '53170',
    city: 'Silver Lake',
    state: 'WI',
    latitude: '42.55051',
    longitude: '-88.168709',
    timeZoneId: 'America/Chicago',
  },
  '53171': {
    zip: '53171',
    city: 'Somers',
    state: 'WI',
    latitude: '42.636099',
    longitude: '-87.90979',
    timeZoneId: 'America/Chicago',
  },
  '53172': {
    zip: '53172',
    city: 'South Milwaukee',
    state: 'WI',
    latitude: '42.909971',
    longitude: '-87.866551',
    timeZoneId: 'America/Chicago',
  },
  '53176': {
    zip: '53176',
    city: 'Springfield',
    state: 'WI',
    latitude: '42.64327',
    longitude: '-88.41224',
    timeZoneId: 'America/Chicago',
  },
  '53177': {
    zip: '53177',
    city: 'Sturtevant',
    state: 'WI',
    latitude: '42.697861',
    longitude: '-87.91746',
    timeZoneId: 'America/Chicago',
  },
  '53178': {
    zip: '53178',
    city: 'Sullivan',
    state: 'WI',
    latitude: '43.010733',
    longitude: '-88.599899',
    timeZoneId: 'America/Chicago',
  },
  '53179': {
    zip: '53179',
    city: 'Trevor',
    state: 'WI',
    latitude: '42.517117',
    longitude: '-88.134016',
    timeZoneId: 'America/Chicago',
  },
  '53181': {
    zip: '53181',
    city: 'Twin Lakes',
    state: 'WI',
    latitude: '42.519397',
    longitude: '-88.254983',
    timeZoneId: 'America/Chicago',
  },
  '53182': {
    zip: '53182',
    city: 'Union Grove',
    state: 'WI',
    latitude: '42.703188',
    longitude: '-88.038888',
    timeZoneId: 'America/Chicago',
  },
  '53183': {
    zip: '53183',
    city: 'Wales',
    state: 'WI',
    latitude: '43.004463',
    longitude: '-88.378948',
    timeZoneId: 'America/Chicago',
  },
  '53184': {
    zip: '53184',
    city: 'Walworth',
    state: 'WI',
    latitude: '42.532266',
    longitude: '-88.599572',
    timeZoneId: 'America/Chicago',
  },
  '53185': {
    zip: '53185',
    city: 'Waterford',
    state: 'WI',
    latitude: '42.78714',
    longitude: '-88.20648',
    timeZoneId: 'America/Chicago',
  },
  '53186': {
    zip: '53186',
    city: 'Waukesha',
    state: 'WI',
    latitude: '43.013231',
    longitude: '-88.214586',
    timeZoneId: 'America/Chicago',
  },
  '53187': {
    zip: '53187',
    city: 'Waukesha',
    state: 'WI',
    latitude: '43.00888',
    longitude: '-88.221398',
    timeZoneId: 'America/Chicago',
  },
  '53188': {
    zip: '53188',
    city: 'Waukesha',
    state: 'WI',
    latitude: '43.016203',
    longitude: '-88.267858',
    timeZoneId: 'America/Chicago',
  },
  '53189': {
    zip: '53189',
    city: 'Waukesha',
    state: 'WI',
    latitude: '43.013819',
    longitude: '-88.228571',
    timeZoneId: 'America/Chicago',
  },
  '53190': {
    zip: '53190',
    city: 'Whitewater',
    state: 'WI',
    latitude: '42.820613',
    longitude: '-88.729455',
    timeZoneId: 'America/Chicago',
  },
  '53191': {
    zip: '53191',
    city: 'Williams Bay',
    state: 'WI',
    latitude: '42.57913',
    longitude: '-88.536213',
    timeZoneId: 'America/Chicago',
  },
  '53192': {
    zip: '53192',
    city: 'Wilmot',
    state: 'WI',
    latitude: '42.513487',
    longitude: '-88.209516',
    timeZoneId: 'America/Chicago',
  },
  '53194': {
    zip: '53194',
    city: 'Woodworth',
    state: 'WI',
    latitude: '42.562811',
    longitude: '-88.072225',
    timeZoneId: 'America/Chicago',
  },
  '53195': {
    zip: '53195',
    city: 'Zenda',
    state: 'WI',
    latitude: '42.512623',
    longitude: '-88.481385',
    timeZoneId: 'America/Chicago',
  },
  '53201': {
    zip: '53201',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.036178',
    longitude: '-87.916956',
    timeZoneId: 'America/Chicago',
  },
  '53202': {
    zip: '53202',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.048572',
    longitude: '-87.89628',
    timeZoneId: 'America/Chicago',
  },
  '53203': {
    zip: '53203',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.03535',
    longitude: '-87.915098',
    timeZoneId: 'America/Chicago',
  },
  '53204': {
    zip: '53204',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.017316',
    longitude: '-87.924759',
    timeZoneId: 'America/Chicago',
  },
  '53205': {
    zip: '53205',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.053463',
    longitude: '-87.933809',
    timeZoneId: 'America/Chicago',
  },
  '53206': {
    zip: '53206',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.074967',
    longitude: '-87.932138',
    timeZoneId: 'America/Chicago',
  },
  '53207': {
    zip: '53207',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.980668',
    longitude: '-87.892825',
    timeZoneId: 'America/Chicago',
  },
  '53208': {
    zip: '53208',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.048166',
    longitude: '-87.964638',
    timeZoneId: 'America/Chicago',
  },
  '53209': {
    zip: '53209',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.12592',
    longitude: '-87.946764',
    timeZoneId: 'America/Chicago',
  },
  '53210': {
    zip: '53210',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.068996',
    longitude: '-87.978957',
    timeZoneId: 'America/Chicago',
  },
  '53211': {
    zip: '53211',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.082534',
    longitude: '-87.884406',
    timeZoneId: 'America/Chicago',
  },
  '53212': {
    zip: '53212',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.070633',
    longitude: '-87.906171',
    timeZoneId: 'America/Chicago',
  },
  '53213': {
    zip: '53213',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.049511',
    longitude: '-88.005322',
    timeZoneId: 'America/Chicago',
  },
  '53214': {
    zip: '53214',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.020816',
    longitude: '-88.017337',
    timeZoneId: 'America/Chicago',
  },
  '53215': {
    zip: '53215',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.997687',
    longitude: '-87.942662',
    timeZoneId: 'America/Chicago',
  },
  '53216': {
    zip: '53216',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.089739',
    longitude: '-87.975948',
    timeZoneId: 'America/Chicago',
  },
  '53217': {
    zip: '53217',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.150112',
    longitude: '-87.911181',
    timeZoneId: 'America/Chicago',
  },
  '53218': {
    zip: '53218',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.113642',
    longitude: '-87.991022',
    timeZoneId: 'America/Chicago',
  },
  '53219': {
    zip: '53219',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.994394',
    longitude: '-87.991356',
    timeZoneId: 'America/Chicago',
  },
  '53220': {
    zip: '53220',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.961389',
    longitude: '-88.0125',
    timeZoneId: 'America/Chicago',
  },
  '53221': {
    zip: '53221',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.947637',
    longitude: '-87.944575',
    timeZoneId: 'America/Chicago',
  },
  '53222': {
    zip: '53222',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.084153',
    longitude: '-88.036961',
    timeZoneId: 'America/Chicago',
  },
  '53223': {
    zip: '53223',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.158245',
    longitude: '-87.989707',
    timeZoneId: 'America/Chicago',
  },
  '53224': {
    zip: '53224',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.156957',
    longitude: '-88.044018',
    timeZoneId: 'America/Chicago',
  },
  '53225': {
    zip: '53225',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.113789',
    longitude: '-88.04076',
    timeZoneId: 'America/Chicago',
  },
  '53226': {
    zip: '53226',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.047262',
    longitude: '-88.044347',
    timeZoneId: 'America/Chicago',
  },
  '53227': {
    zip: '53227',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.994705',
    longitude: '-88.042267',
    timeZoneId: 'America/Chicago',
  },
  '53228': {
    zip: '53228',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '42.966203',
    longitude: '-88.043196',
    timeZoneId: 'America/Chicago',
  },
  '53233': {
    zip: '53233',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.036985',
    longitude: '-87.932408',
    timeZoneId: 'America/Chicago',
  },
  '53234': {
    zip: '53234',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.001743',
    longitude: '-87.970753',
    timeZoneId: 'America/Chicago',
  },
  '53235': {
    zip: '53235',
    city: 'Saint Francis',
    state: 'WI',
    latitude: '42.969046',
    longitude: '-87.871317',
    timeZoneId: 'America/Chicago',
  },
  '53237': {
    zip: '53237',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.041809',
    longitude: '-87.906837',
    timeZoneId: 'America/Chicago',
  },
  '53259': {
    zip: '53259',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.03629',
    longitude: '-87.912848',
    timeZoneId: 'America/Chicago',
  },
  '53263': {
    zip: '53263',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.084119',
    longitude: '-88.035945',
    timeZoneId: 'America/Chicago',
  },
  '53274': {
    zip: '53274',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.040271',
    longitude: '-87.907473',
    timeZoneId: 'America/Chicago',
  },
  '53278': {
    zip: '53278',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.03945',
    longitude: '-87.900295',
    timeZoneId: 'America/Chicago',
  },
  '53288': {
    zip: '53288',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.043453',
    longitude: '-87.912413',
    timeZoneId: 'America/Chicago',
  },
  '53290': {
    zip: '53290',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.036612',
    longitude: '-87.912064',
    timeZoneId: 'America/Chicago',
  },
  '53293': {
    zip: '53293',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.042193',
    longitude: '-87.92019',
    timeZoneId: 'America/Chicago',
  },
  '53295': {
    zip: '53295',
    city: 'Milwaukee',
    state: 'WI',
    latitude: '43.016381',
    longitude: '-87.979711',
    timeZoneId: 'America/Chicago',
  },
  '53401': {
    zip: '53401',
    city: 'Racine',
    state: 'WI',
    latitude: '42.691955',
    longitude: '-87.814781',
    timeZoneId: 'America/Chicago',
  },
  '53402': {
    zip: '53402',
    city: 'Racine',
    state: 'WI',
    latitude: '42.7978',
    longitude: '-87.832227',
    timeZoneId: 'America/Chicago',
  },
  '53403': {
    zip: '53403',
    city: 'Racine',
    state: 'WI',
    latitude: '42.704176',
    longitude: '-87.80658',
    timeZoneId: 'America/Chicago',
  },
  '53404': {
    zip: '53404',
    city: 'Racine',
    state: 'WI',
    latitude: '42.740654',
    longitude: '-87.809611',
    timeZoneId: 'America/Chicago',
  },
  '53405': {
    zip: '53405',
    city: 'Racine',
    state: 'WI',
    latitude: '42.716356',
    longitude: '-87.823291',
    timeZoneId: 'America/Chicago',
  },
  '53406': {
    zip: '53406',
    city: 'Racine',
    state: 'WI',
    latitude: '42.72572',
    longitude: '-87.856436',
    timeZoneId: 'America/Chicago',
  },
  '53407': {
    zip: '53407',
    city: 'Racine',
    state: 'WI',
    latitude: '42.726079',
    longitude: '-87.783375',
    timeZoneId: 'America/Chicago',
  },
  '53408': {
    zip: '53408',
    city: 'Racine',
    state: 'WI',
    latitude: '42.725298',
    longitude: '-87.808915',
    timeZoneId: 'America/Chicago',
  },
  '53501': {
    zip: '53501',
    city: 'Afton',
    state: 'WI',
    latitude: '42.602616',
    longitude: '-89.068462',
    timeZoneId: 'America/Chicago',
  },
  '53502': {
    zip: '53502',
    city: 'Albany',
    state: 'WI',
    latitude: '42.730426',
    longitude: '-89.443626',
    timeZoneId: 'America/Chicago',
  },
  '53503': {
    zip: '53503',
    city: 'Arena',
    state: 'WI',
    latitude: '43.141048',
    longitude: '-89.946926',
    timeZoneId: 'America/Chicago',
  },
  '53504': {
    zip: '53504',
    city: 'Argyle',
    state: 'WI',
    latitude: '42.704144',
    longitude: '-89.901971',
    timeZoneId: 'America/Chicago',
  },
  '53505': {
    zip: '53505',
    city: 'Avalon',
    state: 'WI',
    latitude: '42.687981',
    longitude: '-88.810568',
    timeZoneId: 'America/Chicago',
  },
  '53506': {
    zip: '53506',
    city: 'Avoca',
    state: 'WI',
    latitude: '43.190183',
    longitude: '-90.317873',
    timeZoneId: 'America/Chicago',
  },
  '53507': {
    zip: '53507',
    city: 'Barneveld',
    state: 'WI',
    latitude: '43.008512',
    longitude: '-89.897707',
    timeZoneId: 'America/Chicago',
  },
  '53508': {
    zip: '53508',
    city: 'Belleville',
    state: 'WI',
    latitude: '42.881803',
    longitude: '-89.560741',
    timeZoneId: 'America/Chicago',
  },
  '53510': {
    zip: '53510',
    city: 'Belmont',
    state: 'WI',
    latitude: '42.729527',
    longitude: '-90.330139',
    timeZoneId: 'America/Chicago',
  },
  '53511': {
    zip: '53511',
    city: 'Beloit',
    state: 'WI',
    latitude: '42.5589',
    longitude: '-89.08734',
    timeZoneId: 'America/Chicago',
  },
  '53512': {
    zip: '53512',
    city: 'Beloit',
    state: 'WI',
    latitude: '42.507644',
    longitude: '-89.032201',
    timeZoneId: 'America/Chicago',
  },
  '53515': {
    zip: '53515',
    city: 'Black Earth',
    state: 'WI',
    latitude: '43.127067',
    longitude: '-89.742349',
    timeZoneId: 'America/Chicago',
  },
  '53516': {
    zip: '53516',
    city: 'Blanchardville',
    state: 'WI',
    latitude: '42.78401',
    longitude: '-89.902654',
    timeZoneId: 'America/Chicago',
  },
  '53517': {
    zip: '53517',
    city: 'Blue Mounds',
    state: 'WI',
    latitude: '43.005538',
    longitude: '-89.821569',
    timeZoneId: 'America/Chicago',
  },
  '53518': {
    zip: '53518',
    city: 'Blue River',
    state: 'WI',
    latitude: '43.185758',
    longitude: '-90.552858',
    timeZoneId: 'America/Chicago',
  },
  '53520': {
    zip: '53520',
    city: 'Brodhead',
    state: 'WI',
    latitude: '42.621933',
    longitude: '-89.373581',
    timeZoneId: 'America/Chicago',
  },
  '53521': {
    zip: '53521',
    city: 'Brooklyn',
    state: 'WI',
    latitude: '42.86849',
    longitude: '-89.389756',
    timeZoneId: 'America/Chicago',
  },
  '53522': {
    zip: '53522',
    city: 'Browntown',
    state: 'WI',
    latitude: '42.576493',
    longitude: '-89.795722',
    timeZoneId: 'America/Chicago',
  },
  '53523': {
    zip: '53523',
    city: 'Cambridge',
    state: 'WI',
    latitude: '42.978962',
    longitude: '-89.047633',
    timeZoneId: 'America/Chicago',
  },
  '53525': {
    zip: '53525',
    city: 'Clinton',
    state: 'WI',
    latitude: '42.560436',
    longitude: '-88.867865',
    timeZoneId: 'America/Chicago',
  },
  '53526': {
    zip: '53526',
    city: 'Cobb',
    state: 'WI',
    latitude: '42.963001',
    longitude: '-90.333272',
    timeZoneId: 'America/Chicago',
  },
  '53527': {
    zip: '53527',
    city: 'Cottage Grove',
    state: 'WI',
    latitude: '43.070701',
    longitude: '-89.198254',
    timeZoneId: 'America/Chicago',
  },
  '53528': {
    zip: '53528',
    city: 'Cross Plains',
    state: 'WI',
    latitude: '43.12081',
    longitude: '-89.638208',
    timeZoneId: 'America/Chicago',
  },
  '53529': {
    zip: '53529',
    city: 'Dane',
    state: 'WI',
    latitude: '43.235421',
    longitude: '-89.525588',
    timeZoneId: 'America/Chicago',
  },
  '53530': {
    zip: '53530',
    city: 'Darlington',
    state: 'WI',
    latitude: '42.690879',
    longitude: '-90.105208',
    timeZoneId: 'America/Chicago',
  },
  '53531': {
    zip: '53531',
    city: 'Deerfield',
    state: 'WI',
    latitude: '43.05239',
    longitude: '-89.097446',
    timeZoneId: 'America/Chicago',
  },
  '53532': {
    zip: '53532',
    city: 'Deforest',
    state: 'WI',
    latitude: '43.236225',
    longitude: '-89.333063',
    timeZoneId: 'America/Chicago',
  },
  '53533': {
    zip: '53533',
    city: 'Dodgeville',
    state: 'WI',
    latitude: '42.963649',
    longitude: '-90.134169',
    timeZoneId: 'America/Chicago',
  },
  '53534': {
    zip: '53534',
    city: 'Edgerton',
    state: 'WI',
    latitude: '42.822805',
    longitude: '-89.063685',
    timeZoneId: 'America/Chicago',
  },
  '53535': {
    zip: '53535',
    city: 'Edmund',
    state: 'WI',
    latitude: '42.969874',
    longitude: '-90.262851',
    timeZoneId: 'America/Chicago',
  },
  '53536': {
    zip: '53536',
    city: 'Evansville',
    state: 'WI',
    latitude: '42.770303',
    longitude: '-89.271419',
    timeZoneId: 'America/Chicago',
  },
  '53537': {
    zip: '53537',
    city: 'Footville',
    state: 'WI',
    latitude: '42.670296',
    longitude: '-89.213228',
    timeZoneId: 'America/Chicago',
  },
  '53538': {
    zip: '53538',
    city: 'Fort Atkinson',
    state: 'WI',
    latitude: '42.924206',
    longitude: '-88.840777',
    timeZoneId: 'America/Chicago',
  },
  '53540': {
    zip: '53540',
    city: 'Gotham',
    state: 'WI',
    latitude: '43.222224',
    longitude: '-90.292434',
    timeZoneId: 'America/Chicago',
  },
  '53541': {
    zip: '53541',
    city: 'Gratiot',
    state: 'WI',
    latitude: '42.585999',
    longitude: '-90.019817',
    timeZoneId: 'America/Chicago',
  },
  '53542': {
    zip: '53542',
    city: 'Hanover',
    state: 'WI',
    latitude: '42.63262',
    longitude: '-89.165667',
    timeZoneId: 'America/Chicago',
  },
  '53543': {
    zip: '53543',
    city: 'Highland',
    state: 'WI',
    latitude: '43.042113',
    longitude: '-90.363424',
    timeZoneId: 'America/Chicago',
  },
  '53544': {
    zip: '53544',
    city: 'Hollandale',
    state: 'WI',
    latitude: '42.905916',
    longitude: '-89.832037',
    timeZoneId: 'America/Chicago',
  },
  '53545': {
    zip: '53545',
    city: 'Janesville',
    state: 'WI',
    latitude: '42.689826',
    longitude: '-89.050644',
    timeZoneId: 'America/Chicago',
  },
  '53546': {
    zip: '53546',
    city: 'Janesville',
    state: 'WI',
    latitude: '42.670071',
    longitude: '-88.994997',
    timeZoneId: 'America/Chicago',
  },
  '53547': {
    zip: '53547',
    city: 'Janesville',
    state: 'WI',
    latitude: '42.705031',
    longitude: '-89.006424',
    timeZoneId: 'America/Chicago',
  },
  '53548': {
    zip: '53548',
    city: 'Janesville',
    state: 'WI',
    latitude: '42.67823',
    longitude: '-89.016451',
    timeZoneId: 'America/Chicago',
  },
  '53549': {
    zip: '53549',
    city: 'Jefferson',
    state: 'WI',
    latitude: '43.005551',
    longitude: '-88.804618',
    timeZoneId: 'America/Chicago',
  },
  '53550': {
    zip: '53550',
    city: 'Juda',
    state: 'WI',
    latitude: '42.502945',
    longitude: '-89.510157',
    timeZoneId: 'America/Chicago',
  },
  '53551': {
    zip: '53551',
    city: 'Lake Mills',
    state: 'WI',
    latitude: '43.079375',
    longitude: '-88.910122',
    timeZoneId: 'America/Chicago',
  },
  '53553': {
    zip: '53553',
    city: 'Linden',
    state: 'WI',
    latitude: '42.918177',
    longitude: '-90.27686',
    timeZoneId: 'America/Chicago',
  },
  '53554': {
    zip: '53554',
    city: 'Livingston',
    state: 'WI',
    latitude: '42.898732',
    longitude: '-90.456658',
    timeZoneId: 'America/Chicago',
  },
  '53555': {
    zip: '53555',
    city: 'Lodi',
    state: 'WI',
    latitude: '43.328456',
    longitude: '-89.563763',
    timeZoneId: 'America/Chicago',
  },
  '53556': {
    zip: '53556',
    city: 'Lone Rock',
    state: 'WI',
    latitude: '43.182834',
    longitude: '-90.198719',
    timeZoneId: 'America/Chicago',
  },
  '53557': {
    zip: '53557',
    city: 'Lowell',
    state: 'WI',
    latitude: '43.339451',
    longitude: '-88.816525',
    timeZoneId: 'America/Chicago',
  },
  '53558': {
    zip: '53558',
    city: 'Mc Farland',
    state: 'WI',
    latitude: '43.007217',
    longitude: '-89.296558',
    timeZoneId: 'America/Chicago',
  },
  '53559': {
    zip: '53559',
    city: 'Marshall',
    state: 'WI',
    latitude: '43.175384',
    longitude: '-89.075712',
    timeZoneId: 'America/Chicago',
  },
  '53560': {
    zip: '53560',
    city: 'Mazomanie',
    state: 'WI',
    latitude: '43.188047',
    longitude: '-89.758253',
    timeZoneId: 'America/Chicago',
  },
  '53561': {
    zip: '53561',
    city: 'Merrimac',
    state: 'WI',
    latitude: '43.374059',
    longitude: '-89.674731',
    timeZoneId: 'America/Chicago',
  },
  '53562': {
    zip: '53562',
    city: 'Middleton',
    state: 'WI',
    latitude: '43.11528',
    longitude: '-89.52492',
    timeZoneId: 'America/Chicago',
  },
  '53563': {
    zip: '53563',
    city: 'Milton',
    state: 'WI',
    latitude: '42.777048',
    longitude: '-88.953704',
    timeZoneId: 'America/Chicago',
  },
  '53565': {
    zip: '53565',
    city: 'Mineral Point',
    state: 'WI',
    latitude: '42.862371',
    longitude: '-90.182008',
    timeZoneId: 'America/Chicago',
  },
  '53566': {
    zip: '53566',
    city: 'Monroe',
    state: 'WI',
    latitude: '42.596735',
    longitude: '-89.640073',
    timeZoneId: 'America/Chicago',
  },
  '53569': {
    zip: '53569',
    city: 'Montfort',
    state: 'WI',
    latitude: '42.991221',
    longitude: '-90.475978',
    timeZoneId: 'America/Chicago',
  },
  '53570': {
    zip: '53570',
    city: 'Monticello',
    state: 'WI',
    latitude: '42.747446',
    longitude: '-89.592433',
    timeZoneId: 'America/Chicago',
  },
  '53571': {
    zip: '53571',
    city: 'Morrisonville',
    state: 'WI',
    latitude: '43.277632',
    longitude: '-89.357662',
    timeZoneId: 'America/Chicago',
  },
  '53572': {
    zip: '53572',
    city: 'Mount Horeb',
    state: 'WI',
    latitude: '42.981166',
    longitude: '-89.729689',
    timeZoneId: 'America/Chicago',
  },
  '53573': {
    zip: '53573',
    city: 'Muscoda',
    state: 'WI',
    latitude: '43.16406',
    longitude: '-90.468805',
    timeZoneId: 'America/Chicago',
  },
  '53574': {
    zip: '53574',
    city: 'New Glarus',
    state: 'WI',
    latitude: '42.812386',
    longitude: '-89.639376',
    timeZoneId: 'America/Chicago',
  },
  '53575': {
    zip: '53575',
    city: 'Oregon',
    state: 'WI',
    latitude: '42.931421',
    longitude: '-89.389758',
    timeZoneId: 'America/Chicago',
  },
  '53576': {
    zip: '53576',
    city: 'Orfordville',
    state: 'WI',
    latitude: '42.623722',
    longitude: '-89.244316',
    timeZoneId: 'America/Chicago',
  },
  '53577': {
    zip: '53577',
    city: 'Plain',
    state: 'WI',
    latitude: '43.278134',
    longitude: '-90.043068',
    timeZoneId: 'America/Chicago',
  },
  '53578': {
    zip: '53578',
    city: 'Prairie du Sac',
    state: 'WI',
    latitude: '43.289654',
    longitude: '-89.736826',
    timeZoneId: 'America/Chicago',
  },
  '53579': {
    zip: '53579',
    city: 'Reeseville',
    state: 'WI',
    latitude: '43.303699',
    longitude: '-88.844663',
    timeZoneId: 'America/Chicago',
  },
  '53580': {
    zip: '53580',
    city: 'Rewey',
    state: 'WI',
    latitude: '42.842708',
    longitude: '-90.393117',
    timeZoneId: 'America/Chicago',
  },
  '53581': {
    zip: '53581',
    city: 'Richland Center',
    state: 'WI',
    latitude: '43.35114',
    longitude: '-90.38994',
    timeZoneId: 'America/Chicago',
  },
  '53582': {
    zip: '53582',
    city: 'Ridgeway',
    state: 'WI',
    latitude: '42.998132',
    longitude: '-89.996343',
    timeZoneId: 'America/Chicago',
  },
  '53583': {
    zip: '53583',
    city: 'Sauk City',
    state: 'WI',
    latitude: '43.273674',
    longitude: '-89.737229',
    timeZoneId: 'America/Chicago',
  },
  '53584': {
    zip: '53584',
    city: 'Sextonville',
    state: 'WI',
    latitude: '43.281665',
    longitude: '-90.292672',
    timeZoneId: 'America/Chicago',
  },
  '53585': {
    zip: '53585',
    city: 'Sharon',
    state: 'WI',
    latitude: '42.506463',
    longitude: '-88.727832',
    timeZoneId: 'America/Chicago',
  },
  '53586': {
    zip: '53586',
    city: 'Shullsburg',
    state: 'WI',
    latitude: '42.577863',
    longitude: '-90.225429',
    timeZoneId: 'America/Chicago',
  },
  '53587': {
    zip: '53587',
    city: 'South Wayne',
    state: 'WI',
    latitude: '42.588101',
    longitude: '-89.902503',
    timeZoneId: 'America/Chicago',
  },
  '53588': {
    zip: '53588',
    city: 'Spring Green',
    state: 'WI',
    latitude: '43.185995',
    longitude: '-90.078999',
    timeZoneId: 'America/Chicago',
  },
  '53589': {
    zip: '53589',
    city: 'Stoughton',
    state: 'WI',
    latitude: '42.930347',
    longitude: '-89.214312',
    timeZoneId: 'America/Chicago',
  },
  '53590': {
    zip: '53590',
    city: 'Sun Prairie',
    state: 'WI',
    latitude: '43.189901',
    longitude: '-89.219791',
    timeZoneId: 'America/Chicago',
  },
  '53593': {
    zip: '53593',
    city: 'Verona',
    state: 'WI',
    latitude: '42.9926',
    longitude: '-89.57011',
    timeZoneId: 'America/Chicago',
  },
  '53594': {
    zip: '53594',
    city: 'Waterloo',
    state: 'WI',
    latitude: '43.177186',
    longitude: '-88.982394',
    timeZoneId: 'America/Chicago',
  },
  '53595': {
    zip: '53595',
    city: 'Dodgeville',
    state: 'WI',
    latitude: '42.96748',
    longitude: '-90.132036',
    timeZoneId: 'America/Chicago',
  },
  '53596': {
    zip: '53596',
    city: 'Sun Prairie',
    state: 'WI',
    latitude: '43.195587',
    longitude: '-89.264013',
    timeZoneId: 'America/Chicago',
  },
  '53597': {
    zip: '53597',
    city: 'Waunakee',
    state: 'WI',
    latitude: '43.181072',
    longitude: '-89.450204',
    timeZoneId: 'America/Chicago',
  },
  '53598': {
    zip: '53598',
    city: 'Windsor',
    state: 'WI',
    latitude: '43.211584',
    longitude: '-89.345542',
    timeZoneId: 'America/Chicago',
  },
  '53599': {
    zip: '53599',
    city: 'Woodford',
    state: 'WI',
    latitude: '42.649996',
    longitude: '-89.862141',
    timeZoneId: 'America/Chicago',
  },
  '53701': {
    zip: '53701',
    city: 'Madison',
    state: 'WI',
    latitude: '43.072838',
    longitude: '-89.379278',
    timeZoneId: 'America/Chicago',
  },
  '53702': {
    zip: '53702',
    city: 'Madison',
    state: 'WI',
    latitude: '43.074862',
    longitude: '-89.380795',
    timeZoneId: 'America/Chicago',
  },
  '53703': {
    zip: '53703',
    city: 'Madison',
    state: 'WI',
    latitude: '43.079239',
    longitude: '-89.37722',
    timeZoneId: 'America/Chicago',
  },
  '53704': {
    zip: '53704',
    city: 'Madison',
    state: 'WI',
    latitude: '43.117217',
    longitude: '-89.335082',
    timeZoneId: 'America/Chicago',
  },
  '53705': {
    zip: '53705',
    city: 'Madison',
    state: 'WI',
    latitude: '43.075083',
    longitude: '-89.459898',
    timeZoneId: 'America/Chicago',
  },
  '53706': {
    zip: '53706',
    city: 'Madison',
    state: 'WI',
    latitude: '43.072821',
    longitude: '-89.410573',
    timeZoneId: 'America/Chicago',
  },
  '53707': {
    zip: '53707',
    city: 'Madison',
    state: 'WI',
    latitude: '43.098563',
    longitude: '-89.311125',
    timeZoneId: 'America/Chicago',
  },
  '53708': {
    zip: '53708',
    city: 'Madison',
    state: 'WI',
    latitude: '43.100954',
    longitude: '-89.312327',
    timeZoneId: 'America/Chicago',
  },
  '53711': {
    zip: '53711',
    city: 'Madison',
    state: 'WI',
    latitude: '43.030573',
    longitude: '-89.444075',
    timeZoneId: 'America/Chicago',
  },
  '53713': {
    zip: '53713',
    city: 'Madison',
    state: 'WI',
    latitude: '43.032823',
    longitude: '-89.394847',
    timeZoneId: 'America/Chicago',
  },
  '53714': {
    zip: '53714',
    city: 'Madison',
    state: 'WI',
    latitude: '43.099135',
    longitude: '-89.3127',
    timeZoneId: 'America/Chicago',
  },
  '53715': {
    zip: '53715',
    city: 'Madison',
    state: 'WI',
    latitude: '43.063624',
    longitude: '-89.394312',
    timeZoneId: 'America/Chicago',
  },
  '53716': {
    zip: '53716',
    city: 'Madison',
    state: 'WI',
    latitude: '43.065869',
    longitude: '-89.312254',
    timeZoneId: 'America/Chicago',
  },
  '53717': {
    zip: '53717',
    city: 'Madison',
    state: 'WI',
    latitude: '43.070985',
    longitude: '-89.51216',
    timeZoneId: 'America/Chicago',
  },
  '53718': {
    zip: '53718',
    city: 'Madison',
    state: 'WI',
    latitude: '43.147068',
    longitude: '-89.406946',
    timeZoneId: 'America/Chicago',
  },
  '53719': {
    zip: '53719',
    city: 'Madison',
    state: 'WI',
    latitude: '43.032837',
    longitude: '-89.498129',
    timeZoneId: 'America/Chicago',
  },
  '53725': {
    zip: '53725',
    city: 'Madison',
    state: 'WI',
    latitude: '43.050369',
    longitude: '-89.397499',
    timeZoneId: 'America/Chicago',
  },
  '53726': {
    zip: '53726',
    city: 'Madison',
    state: 'WI',
    latitude: '43.073535',
    longitude: '-89.395574',
    timeZoneId: 'America/Chicago',
  },
  '53744': {
    zip: '53744',
    city: 'Madison',
    state: 'WI',
    latitude: '43.015628',
    longitude: '-89.414521',
    timeZoneId: 'America/Chicago',
  },
  '53774': {
    zip: '53774',
    city: 'Madison',
    state: 'WI',
    latitude: '43.072549',
    longitude: '-89.402181',
    timeZoneId: 'America/Chicago',
  },
  '53777': {
    zip: '53777',
    city: 'Madison',
    state: 'WI',
    latitude: '43.107649',
    longitude: '-89.339418',
    timeZoneId: 'America/Chicago',
  },
  '53782': {
    zip: '53782',
    city: 'Madison',
    state: 'WI',
    latitude: '43.063213',
    longitude: '-89.315194',
    timeZoneId: 'America/Chicago',
  },
  '53783': {
    zip: '53783',
    city: 'Madison',
    state: 'WI',
    latitude: '43.159291',
    longitude: '-89.283709',
    timeZoneId: 'America/Chicago',
  },
  '53784': {
    zip: '53784',
    city: 'Madison',
    state: 'WI',
    latitude: '43.040958',
    longitude: '-89.394588',
    timeZoneId: 'America/Chicago',
  },
  '53785': {
    zip: '53785',
    city: 'Madison',
    state: 'WI',
    latitude: '43.075404',
    longitude: '-89.383079',
    timeZoneId: 'America/Chicago',
  },
  '53786': {
    zip: '53786',
    city: 'Madison',
    state: 'WI',
    latitude: '43.077126',
    longitude: '-89.380448',
    timeZoneId: 'America/Chicago',
  },
  '53788': {
    zip: '53788',
    city: 'Madison',
    state: 'WI',
    latitude: '43.075177',
    longitude: '-89.3784',
    timeZoneId: 'America/Chicago',
  },
  '53790': {
    zip: '53790',
    city: 'Madison',
    state: 'WI',
    latitude: '43.073534',
    longitude: '-89.382948',
    timeZoneId: 'America/Chicago',
  },
  '53791': {
    zip: '53791',
    city: 'Madison',
    state: 'WI',
    latitude: '43.077245',
    longitude: '-89.454359',
    timeZoneId: 'America/Chicago',
  },
  '53792': {
    zip: '53792',
    city: 'Madison',
    state: 'WI',
    latitude: '43.076741',
    longitude: '-89.433069',
    timeZoneId: 'America/Chicago',
  },
  '53793': {
    zip: '53793',
    city: 'Madison',
    state: 'WI',
    latitude: '43.098357',
    longitude: '-89.325603',
    timeZoneId: 'America/Chicago',
  },
  '53794': {
    zip: '53794',
    city: 'Madison',
    state: 'WI',
    latitude: '43.098373',
    longitude: '-89.324921',
    timeZoneId: 'America/Chicago',
  },
  '53801': {
    zip: '53801',
    city: 'Bagley',
    state: 'WI',
    latitude: '42.923562',
    longitude: '-91.056053',
    timeZoneId: 'America/Chicago',
  },
  '53802': {
    zip: '53802',
    city: 'Beetown',
    state: 'WI',
    latitude: '42.761754',
    longitude: '-90.891946',
    timeZoneId: 'America/Chicago',
  },
  '53803': {
    zip: '53803',
    city: 'Benton',
    state: 'WI',
    latitude: '42.559118',
    longitude: '-90.367335',
    timeZoneId: 'America/Chicago',
  },
  '53804': {
    zip: '53804',
    city: 'Bloomington',
    state: 'WI',
    latitude: '42.874775',
    longitude: '-90.912124',
    timeZoneId: 'America/Chicago',
  },
  '53805': {
    zip: '53805',
    city: 'Boscobel',
    state: 'WI',
    latitude: '43.117895',
    longitude: '-90.678798',
    timeZoneId: 'America/Chicago',
  },
  '53806': {
    zip: '53806',
    city: 'Cassville',
    state: 'WI',
    latitude: '42.738574',
    longitude: '-90.94294',
    timeZoneId: 'America/Chicago',
  },
  '53807': {
    zip: '53807',
    city: 'Cuba City',
    state: 'WI',
    latitude: '42.598203',
    longitude: '-90.509698',
    timeZoneId: 'America/Chicago',
  },
  '53808': {
    zip: '53808',
    city: 'Dickeyville',
    state: 'WI',
    latitude: '42.625866',
    longitude: '-90.593685',
    timeZoneId: 'America/Chicago',
  },
  '53809': {
    zip: '53809',
    city: 'Fennimore',
    state: 'WI',
    latitude: '42.990107',
    longitude: '-90.64726',
    timeZoneId: 'America/Chicago',
  },
  '53810': {
    zip: '53810',
    city: 'Glen Haven',
    state: 'WI',
    latitude: '42.821307',
    longitude: '-90.991171',
    timeZoneId: 'America/Chicago',
  },
  '53811': {
    zip: '53811',
    city: 'Hazel Green',
    state: 'WI',
    latitude: '42.535981',
    longitude: '-90.509343',
    timeZoneId: 'America/Chicago',
  },
  '53812': {
    zip: '53812',
    city: 'Kieler',
    state: 'WI',
    latitude: '42.580498',
    longitude: '-90.597992',
    timeZoneId: 'America/Chicago',
  },
  '53813': {
    zip: '53813',
    city: 'Lancaster',
    state: 'WI',
    latitude: '42.844351',
    longitude: '-90.710641',
    timeZoneId: 'America/Chicago',
  },
  '53816': {
    zip: '53816',
    city: 'Mount Hope',
    state: 'WI',
    latitude: '42.971007',
    longitude: '-90.844711',
    timeZoneId: 'America/Chicago',
  },
  '53817': {
    zip: '53817',
    city: 'Patch Grove',
    state: 'WI',
    latitude: '42.942923',
    longitude: '-90.97433',
    timeZoneId: 'America/Chicago',
  },
  '53818': {
    zip: '53818',
    city: 'Platteville',
    state: 'WI',
    latitude: '42.747092',
    longitude: '-90.496206',
    timeZoneId: 'America/Chicago',
  },
  '53820': {
    zip: '53820',
    city: 'Potosi',
    state: 'WI',
    latitude: '42.699392',
    longitude: '-90.705017',
    timeZoneId: 'America/Chicago',
  },
  '53821': {
    zip: '53821',
    city: 'Prairie du Chien',
    state: 'WI',
    latitude: '43.04725',
    longitude: '-91.135493',
    timeZoneId: 'America/Chicago',
  },
  '53824': {
    zip: '53824',
    city: 'Sinsinawa',
    state: 'WI',
    latitude: '42.523779',
    longitude: '-90.537853',
    timeZoneId: 'America/Chicago',
  },
  '53825': {
    zip: '53825',
    city: 'Stitzer',
    state: 'WI',
    latitude: '42.91356',
    longitude: '-90.588319',
    timeZoneId: 'America/Chicago',
  },
  '53826': {
    zip: '53826',
    city: 'Wauzeka',
    state: 'WI',
    latitude: '43.11139',
    longitude: '-90.851909',
    timeZoneId: 'America/Chicago',
  },
  '53827': {
    zip: '53827',
    city: 'Woodman',
    state: 'WI',
    latitude: '43.048813',
    longitude: '-90.825159',
    timeZoneId: 'America/Chicago',
  },
  '53901': {
    zip: '53901',
    city: 'Portage',
    state: 'WI',
    latitude: '43.544357',
    longitude: '-89.473269',
    timeZoneId: 'America/Chicago',
  },
  '53910': {
    zip: '53910',
    city: 'Adams',
    state: 'WI',
    latitude: '43.911421',
    longitude: '-89.801303',
    timeZoneId: 'America/Chicago',
  },
  '53911': {
    zip: '53911',
    city: 'Arlington',
    state: 'WI',
    latitude: '43.282786',
    longitude: '-89.389648',
    timeZoneId: 'America/Chicago',
  },
  '53913': {
    zip: '53913',
    city: 'Baraboo',
    state: 'WI',
    latitude: '43.52592',
    longitude: '-89.77404',
    timeZoneId: 'America/Chicago',
  },
  '53916': {
    zip: '53916',
    city: 'Beaver Dam',
    state: 'WI',
    latitude: '43.462045',
    longitude: '-88.839358',
    timeZoneId: 'America/Chicago',
  },
  '53919': {
    zip: '53919',
    city: 'Brandon',
    state: 'WI',
    latitude: '43.733428',
    longitude: '-88.78302',
    timeZoneId: 'America/Chicago',
  },
  '53920': {
    zip: '53920',
    city: 'Briggsville',
    state: 'WI',
    latitude: '43.6621',
    longitude: '-89.616601',
    timeZoneId: 'America/Chicago',
  },
  '53922': {
    zip: '53922',
    city: 'Burnett',
    state: 'WI',
    latitude: '43.524713',
    longitude: '-88.701534',
    timeZoneId: 'America/Chicago',
  },
  '53923': {
    zip: '53923',
    city: 'Cambria',
    state: 'WI',
    latitude: '43.546952',
    longitude: '-89.105675',
    timeZoneId: 'America/Chicago',
  },
  '53924': {
    zip: '53924',
    city: 'Cazenovia',
    state: 'WI',
    latitude: '43.503207',
    longitude: '-90.272564',
    timeZoneId: 'America/Chicago',
  },
  '53925': {
    zip: '53925',
    city: 'Columbus',
    state: 'WI',
    latitude: '43.33727',
    longitude: '-89.028562',
    timeZoneId: 'America/Chicago',
  },
  '53926': {
    zip: '53926',
    city: 'Dalton',
    state: 'WI',
    latitude: '43.65505',
    longitude: '-89.206388',
    timeZoneId: 'America/Chicago',
  },
  '53928': {
    zip: '53928',
    city: 'Doylestown',
    state: 'WI',
    latitude: '43.429315',
    longitude: '-89.14958',
    timeZoneId: 'America/Chicago',
  },
  '53929': {
    zip: '53929',
    city: 'Elroy',
    state: 'WI',
    latitude: '43.746595',
    longitude: '-90.267539',
    timeZoneId: 'America/Chicago',
  },
  '53930': {
    zip: '53930',
    city: 'Endeavor',
    state: 'WI',
    latitude: '43.715623',
    longitude: '-89.468193',
    timeZoneId: 'America/Chicago',
  },
  '53931': {
    zip: '53931',
    city: 'Fairwater',
    state: 'WI',
    latitude: '43.74489',
    longitude: '-88.86514',
    timeZoneId: 'America/Chicago',
  },
  '53932': {
    zip: '53932',
    city: 'Fall River',
    state: 'WI',
    latitude: '43.384756',
    longitude: '-89.045623',
    timeZoneId: 'America/Chicago',
  },
  '53933': {
    zip: '53933',
    city: 'Fox Lake',
    state: 'WI',
    latitude: '43.565132',
    longitude: '-88.91353',
    timeZoneId: 'America/Chicago',
  },
  '53934': {
    zip: '53934',
    city: 'Friendship',
    state: 'WI',
    latitude: '43.97679',
    longitude: '-89.838601',
    timeZoneId: 'America/Chicago',
  },
  '53935': {
    zip: '53935',
    city: 'Friesland',
    state: 'WI',
    latitude: '43.589562',
    longitude: '-89.067739',
    timeZoneId: 'America/Chicago',
  },
  '53936': {
    zip: '53936',
    city: 'Grand Marsh',
    state: 'WI',
    latitude: '43.855188',
    longitude: '-89.732392',
    timeZoneId: 'America/Chicago',
  },
  '53937': {
    zip: '53937',
    city: 'Hillpoint',
    state: 'WI',
    latitude: '43.386342',
    longitude: '-90.168476',
    timeZoneId: 'America/Chicago',
  },
  '53939': {
    zip: '53939',
    city: 'Kingston',
    state: 'WI',
    latitude: '43.694164',
    longitude: '-89.126328',
    timeZoneId: 'America/Chicago',
  },
  '53940': {
    zip: '53940',
    city: 'Lake Delton',
    state: 'WI',
    latitude: '43.594441',
    longitude: '-89.79713',
    timeZoneId: 'America/Chicago',
  },
  '53941': {
    zip: '53941',
    city: 'La Valle',
    state: 'WI',
    latitude: '43.573421',
    longitude: '-90.140745',
    timeZoneId: 'America/Chicago',
  },
  '53942': {
    zip: '53942',
    city: 'Lime Ridge',
    state: 'WI',
    latitude: '43.468808',
    longitude: '-90.161213',
    timeZoneId: 'America/Chicago',
  },
  '53943': {
    zip: '53943',
    city: 'Loganville',
    state: 'WI',
    latitude: '43.438436',
    longitude: '-90.03912',
    timeZoneId: 'America/Chicago',
  },
  '53944': {
    zip: '53944',
    city: 'Lyndon Station',
    state: 'WI',
    latitude: '43.706413',
    longitude: '-89.892011',
    timeZoneId: 'America/Chicago',
  },
  '53946': {
    zip: '53946',
    city: 'Markesan',
    state: 'WI',
    latitude: '43.705413',
    longitude: '-88.990142',
    timeZoneId: 'America/Chicago',
  },
  '53947': {
    zip: '53947',
    city: 'Marquette',
    state: 'WI',
    latitude: '43.75076',
    longitude: '-89.141097',
    timeZoneId: 'America/Chicago',
  },
  '53948': {
    zip: '53948',
    city: 'Mauston',
    state: 'WI',
    latitude: '43.795494',
    longitude: '-90.080098',
    timeZoneId: 'America/Chicago',
  },
  '53949': {
    zip: '53949',
    city: 'Montello',
    state: 'WI',
    latitude: '43.793929',
    longitude: '-89.327626',
    timeZoneId: 'America/Chicago',
  },
  '53950': {
    zip: '53950',
    city: 'New Lisbon',
    state: 'WI',
    latitude: '43.886478',
    longitude: '-90.15379',
    timeZoneId: 'America/Chicago',
  },
  '53951': {
    zip: '53951',
    city: 'North Freedom',
    state: 'WI',
    latitude: '43.429199',
    longitude: '-89.850103',
    timeZoneId: 'America/Chicago',
  },
  '53952': {
    zip: '53952',
    city: 'Oxford',
    state: 'WI',
    latitude: '43.802455',
    longitude: '-89.641674',
    timeZoneId: 'America/Chicago',
  },
  '53953': {
    zip: '53953',
    city: 'Packwaukee',
    state: 'WI',
    latitude: '43.767992',
    longitude: '-89.469212',
    timeZoneId: 'America/Chicago',
  },
  '53954': {
    zip: '53954',
    city: 'Pardeeville',
    state: 'WI',
    latitude: '43.531481',
    longitude: '-89.316283',
    timeZoneId: 'America/Chicago',
  },
  '53955': {
    zip: '53955',
    city: 'Poynette',
    state: 'WI',
    latitude: '43.393535',
    longitude: '-89.413257',
    timeZoneId: 'America/Chicago',
  },
  '53956': {
    zip: '53956',
    city: 'Randolph',
    state: 'WI',
    latitude: '43.540343',
    longitude: '-88.999032',
    timeZoneId: 'America/Chicago',
  },
  '53957': {
    zip: '53957',
    city: 'Randolph',
    state: 'WI',
    latitude: '43.541272',
    longitude: '-89.005699',
    timeZoneId: 'America/Chicago',
  },
  '53958': {
    zip: '53958',
    city: 'Reedsburg',
    state: 'WI',
    latitude: '43.53216',
    longitude: '-90.001703',
    timeZoneId: 'America/Chicago',
  },
  '53959': {
    zip: '53959',
    city: 'Reedsburg',
    state: 'WI',
    latitude: '43.53632',
    longitude: '-90.000631',
    timeZoneId: 'America/Chicago',
  },
  '53960': {
    zip: '53960',
    city: 'Rio',
    state: 'WI',
    latitude: '43.444828',
    longitude: '-89.251186',
    timeZoneId: 'America/Chicago',
  },
  '53961': {
    zip: '53961',
    city: 'Rock Springs',
    state: 'WI',
    latitude: '43.479574',
    longitude: '-89.912377',
    timeZoneId: 'America/Chicago',
  },
  '53962': {
    zip: '53962',
    city: 'Union Center',
    state: 'WI',
    latitude: '43.682465',
    longitude: '-90.267028',
    timeZoneId: 'America/Chicago',
  },
  '53963': {
    zip: '53963',
    city: 'Waupun',
    state: 'WI',
    latitude: '43.629805',
    longitude: '-88.734679',
    timeZoneId: 'America/Chicago',
  },
  '53964': {
    zip: '53964',
    city: 'Westfield',
    state: 'WI',
    latitude: '43.920698',
    longitude: '-89.619735',
    timeZoneId: 'America/Chicago',
  },
  '53965': {
    zip: '53965',
    city: 'Wisconsin Dells',
    state: 'WI',
    latitude: '43.727182',
    longitude: '-89.759168',
    timeZoneId: 'America/Chicago',
  },
  '53968': {
    zip: '53968',
    city: 'Wonewoc',
    state: 'WI',
    latitude: '43.654868',
    longitude: '-90.220514',
    timeZoneId: 'America/Chicago',
  },
  '53969': {
    zip: '53969',
    city: 'Wyocena',
    state: 'WI',
    latitude: '43.495865',
    longitude: '-89.307819',
    timeZoneId: 'America/Chicago',
  },
  '54001': {
    zip: '54001',
    city: 'Amery',
    state: 'WI',
    latitude: '45.306512',
    longitude: '-92.356007',
    timeZoneId: 'America/Chicago',
  },
  '54002': {
    zip: '54002',
    city: 'Baldwin',
    state: 'WI',
    latitude: '44.97462',
    longitude: '-92.35032',
    timeZoneId: 'America/Chicago',
  },
  '54003': {
    zip: '54003',
    city: 'Beldenville',
    state: 'WI',
    latitude: '44.787574',
    longitude: '-92.441735',
    timeZoneId: 'America/Chicago',
  },
  '54004': {
    zip: '54004',
    city: 'Clayton',
    state: 'WI',
    latitude: '45.330762',
    longitude: '-92.170455',
    timeZoneId: 'America/Chicago',
  },
  '54005': {
    zip: '54005',
    city: 'Clear Lake',
    state: 'WI',
    latitude: '45.249537',
    longitude: '-92.272632',
    timeZoneId: 'America/Chicago',
  },
  '54006': {
    zip: '54006',
    city: 'Cushing',
    state: 'WI',
    latitude: '45.57312',
    longitude: '-92.64888',
    timeZoneId: 'America/Chicago',
  },
  '54007': {
    zip: '54007',
    city: 'Deer Park',
    state: 'WI',
    latitude: '45.169996',
    longitude: '-92.361717',
    timeZoneId: 'America/Chicago',
  },
  '54009': {
    zip: '54009',
    city: 'Dresser',
    state: 'WI',
    latitude: '45.362975',
    longitude: '-92.621521',
    timeZoneId: 'America/Chicago',
  },
  '54011': {
    zip: '54011',
    city: 'Ellsworth',
    state: 'WI',
    latitude: '44.735213',
    longitude: '-92.480939',
    timeZoneId: 'America/Chicago',
  },
  '54013': {
    zip: '54013',
    city: 'Glenwood City',
    state: 'WI',
    latitude: '45.064774',
    longitude: '-92.196624',
    timeZoneId: 'America/Chicago',
  },
  '54014': {
    zip: '54014',
    city: 'Hager City',
    state: 'WI',
    latitude: '44.602849',
    longitude: '-92.538909',
    timeZoneId: 'America/Chicago',
  },
  '54015': {
    zip: '54015',
    city: 'Hammond',
    state: 'WI',
    latitude: '44.964112',
    longitude: '-92.465156',
    timeZoneId: 'America/Chicago',
  },
  '54016': {
    zip: '54016',
    city: 'Hudson',
    state: 'WI',
    latitude: '44.98188',
    longitude: '-92.69082',
    timeZoneId: 'America/Chicago',
  },
  '54017': {
    zip: '54017',
    city: 'New Richmond',
    state: 'WI',
    latitude: '45.13092',
    longitude: '-92.52156',
    timeZoneId: 'America/Chicago',
  },
  '54020': {
    zip: '54020',
    city: 'Osceola',
    state: 'WI',
    latitude: '45.320122',
    longitude: '-92.699523',
    timeZoneId: 'America/Chicago',
  },
  '54021': {
    zip: '54021',
    city: 'Prescott',
    state: 'WI',
    latitude: '44.749536',
    longitude: '-92.794364',
    timeZoneId: 'America/Chicago',
  },
  '54022': {
    zip: '54022',
    city: 'River Falls',
    state: 'WI',
    latitude: '44.88078',
    longitude: '-92.63226',
    timeZoneId: 'America/Chicago',
  },
  '54023': {
    zip: '54023',
    city: 'Roberts',
    state: 'WI',
    latitude: '44.9898',
    longitude: '-92.56092',
    timeZoneId: 'America/Chicago',
  },
  '54024': {
    zip: '54024',
    city: 'Saint Croix Falls',
    state: 'WI',
    latitude: '45.412894',
    longitude: '-92.633615',
    timeZoneId: 'America/Chicago',
  },
  '54025': {
    zip: '54025',
    city: 'Somerset',
    state: 'WI',
    latitude: '45.128445',
    longitude: '-92.679907',
    timeZoneId: 'America/Chicago',
  },
  '54026': {
    zip: '54026',
    city: 'Star Prairie',
    state: 'WI',
    latitude: '45.19782',
    longitude: '-92.53013',
    timeZoneId: 'America/Chicago',
  },
  '54027': {
    zip: '54027',
    city: 'Wilson',
    state: 'WI',
    latitude: '44.956333',
    longitude: '-92.173468',
    timeZoneId: 'America/Chicago',
  },
  '54028': {
    zip: '54028',
    city: 'Woodville',
    state: 'WI',
    latitude: '44.955122',
    longitude: '-92.285779',
    timeZoneId: 'America/Chicago',
  },
  '54082': {
    zip: '54082',
    city: 'Houlton',
    state: 'WI',
    latitude: '45.07476',
    longitude: '-92.75406',
    timeZoneId: 'America/Chicago',
  },
  '54101': {
    zip: '54101',
    city: 'Abrams',
    state: 'WI',
    latitude: '44.784427',
    longitude: '-88.046008',
    timeZoneId: 'America/Chicago',
  },
  '54102': {
    zip: '54102',
    city: 'Amberg',
    state: 'WI',
    latitude: '45.496805',
    longitude: '-87.929331',
    timeZoneId: 'America/Chicago',
  },
  '54103': {
    zip: '54103',
    city: 'Armstrong Creek',
    state: 'WI',
    latitude: '45.667445',
    longitude: '-88.447142',
    timeZoneId: 'America/Chicago',
  },
  '54104': {
    zip: '54104',
    city: 'Athelstane',
    state: 'WI',
    latitude: '45.460174',
    longitude: '-88.241372',
    timeZoneId: 'America/Chicago',
  },
  '54106': {
    zip: '54106',
    city: 'Black Creek',
    state: 'WI',
    latitude: '44.480124',
    longitude: '-88.446752',
    timeZoneId: 'America/Chicago',
  },
  '54107': {
    zip: '54107',
    city: 'Bonduel',
    state: 'WI',
    latitude: '44.695751',
    longitude: '-88.437835',
    timeZoneId: 'America/Chicago',
  },
  '54110': {
    zip: '54110',
    city: 'Brillion',
    state: 'WI',
    latitude: '44.174703',
    longitude: '-88.065451',
    timeZoneId: 'America/Chicago',
  },
  '54111': {
    zip: '54111',
    city: 'Cecil',
    state: 'WI',
    latitude: '44.810409',
    longitude: '-88.449834',
    timeZoneId: 'America/Chicago',
  },
  '54112': {
    zip: '54112',
    city: 'Coleman',
    state: 'WI',
    latitude: '45.036382',
    longitude: '-88.139933',
    timeZoneId: 'America/Chicago',
  },
  '54113': {
    zip: '54113',
    city: 'Combined Locks',
    state: 'WI',
    latitude: '44.268294',
    longitude: '-88.309953',
    timeZoneId: 'America/Chicago',
  },
  '54114': {
    zip: '54114',
    city: 'Crivitz',
    state: 'WI',
    latitude: '45.29321',
    longitude: '-87.99269',
    timeZoneId: 'America/Chicago',
  },
  '54115': {
    zip: '54115',
    city: 'De Pere',
    state: 'WI',
    latitude: '44.434457',
    longitude: '-88.070098',
    timeZoneId: 'America/Chicago',
  },
  '54119': {
    zip: '54119',
    city: 'Dunbar',
    state: 'WI',
    latitude: '45.62052',
    longitude: '-88.1457',
    timeZoneId: 'America/Chicago',
  },
  '54120': {
    zip: '54120',
    city: 'Fence',
    state: 'WI',
    latitude: '45.763549',
    longitude: '-88.446898',
    timeZoneId: 'America/Chicago',
  },
  '54121': {
    zip: '54121',
    city: 'Florence',
    state: 'WI',
    latitude: '45.921716',
    longitude: '-88.250804',
    timeZoneId: 'America/Chicago',
  },
  '54123': {
    zip: '54123',
    city: 'Forest Junction',
    state: 'WI',
    latitude: '44.207672',
    longitude: '-88.144352',
    timeZoneId: 'America/Chicago',
  },
  '54124': {
    zip: '54124',
    city: 'Gillett',
    state: 'WI',
    latitude: '44.903377',
    longitude: '-88.341021',
    timeZoneId: 'America/Chicago',
  },
  '54125': {
    zip: '54125',
    city: 'Goodman',
    state: 'WI',
    latitude: '45.627299',
    longitude: '-88.356646',
    timeZoneId: 'America/Chicago',
  },
  '54126': {
    zip: '54126',
    city: 'Greenleaf',
    state: 'WI',
    latitude: '44.30622',
    longitude: '-88.0248',
    timeZoneId: 'America/Chicago',
  },
  '54127': {
    zip: '54127',
    city: 'Green Valley',
    state: 'WI',
    latitude: '44.795152',
    longitude: '-88.270695',
    timeZoneId: 'America/Chicago',
  },
  '54128': {
    zip: '54128',
    city: 'Gresham',
    state: 'WI',
    latitude: '44.841941',
    longitude: '-88.817707',
    timeZoneId: 'America/Chicago',
  },
  '54129': {
    zip: '54129',
    city: 'Hilbert',
    state: 'WI',
    latitude: '44.14689',
    longitude: '-88.172336',
    timeZoneId: 'America/Chicago',
  },
  '54130': {
    zip: '54130',
    city: 'Kaukauna',
    state: 'WI',
    latitude: '44.291186',
    longitude: '-88.273071',
    timeZoneId: 'America/Chicago',
  },
  '54131': {
    zip: '54131',
    city: 'Freedom',
    state: 'WI',
    latitude: '44.386389',
    longitude: '-88.288611',
    timeZoneId: 'America/Chicago',
  },
  '54135': {
    zip: '54135',
    city: 'Keshena',
    state: 'WI',
    latitude: '44.897092',
    longitude: '-88.546594',
    timeZoneId: 'America/Chicago',
  },
  '54136': {
    zip: '54136',
    city: 'Kimberly',
    state: 'WI',
    latitude: '44.269942',
    longitude: '-88.34069',
    timeZoneId: 'America/Chicago',
  },
  '54137': {
    zip: '54137',
    city: 'Krakow',
    state: 'WI',
    latitude: '44.764066',
    longitude: '-88.228053',
    timeZoneId: 'America/Chicago',
  },
  '54138': {
    zip: '54138',
    city: 'Lakewood',
    state: 'WI',
    latitude: '45.322736',
    longitude: '-88.486596',
    timeZoneId: 'America/Chicago',
  },
  '54139': {
    zip: '54139',
    city: 'Lena',
    state: 'WI',
    latitude: '44.93526',
    longitude: '-88.05498',
    timeZoneId: 'America/Chicago',
  },
  '54140': {
    zip: '54140',
    city: 'Little Chute',
    state: 'WI',
    latitude: '44.283758',
    longitude: '-88.309465',
    timeZoneId: 'America/Chicago',
  },
  '54141': {
    zip: '54141',
    city: 'Little Suamico',
    state: 'WI',
    latitude: '44.643255',
    longitude: '-88.035252',
    timeZoneId: 'America/Chicago',
  },
  '54143': {
    zip: '54143',
    city: 'Marinette',
    state: 'WI',
    latitude: '45.091002',
    longitude: '-87.625627',
    timeZoneId: 'America/Chicago',
  },
  '54149': {
    zip: '54149',
    city: 'Mountain',
    state: 'WI',
    latitude: '45.209853',
    longitude: '-88.504452',
    timeZoneId: 'America/Chicago',
  },
  '54150': {
    zip: '54150',
    city: 'Neopit',
    state: 'WI',
    latitude: '44.988343',
    longitude: '-88.859236',
    timeZoneId: 'America/Chicago',
  },
  '54151': {
    zip: '54151',
    city: 'Niagara',
    state: 'WI',
    latitude: '45.751192',
    longitude: '-88.002009',
    timeZoneId: 'America/Chicago',
  },
  '54152': {
    zip: '54152',
    city: 'Nichols',
    state: 'WI',
    latitude: '44.565309',
    longitude: '-88.463726',
    timeZoneId: 'America/Chicago',
  },
  '54153': {
    zip: '54153',
    city: 'Oconto',
    state: 'WI',
    latitude: '44.893296',
    longitude: '-87.900009',
    timeZoneId: 'America/Chicago',
  },
  '54154': {
    zip: '54154',
    city: 'Oconto Falls',
    state: 'WI',
    latitude: '44.874146',
    longitude: '-88.164099',
    timeZoneId: 'America/Chicago',
  },
  '54155': {
    zip: '54155',
    city: 'Oneida',
    state: 'WI',
    latitude: '44.532148',
    longitude: '-88.162973',
    timeZoneId: 'America/Chicago',
  },
  '54156': {
    zip: '54156',
    city: 'Pembine',
    state: 'WI',
    latitude: '45.61038',
    longitude: '-87.9441',
    timeZoneId: 'America/Chicago',
  },
  '54157': {
    zip: '54157',
    city: 'Peshtigo',
    state: 'WI',
    latitude: '45.05522',
    longitude: '-87.758537',
    timeZoneId: 'America/Chicago',
  },
  '54159': {
    zip: '54159',
    city: 'Porterfield',
    state: 'WI',
    latitude: '45.20508',
    longitude: '-87.79518',
    timeZoneId: 'America/Chicago',
  },
  '54160': {
    zip: '54160',
    city: 'Potter',
    state: 'WI',
    latitude: '44.119133',
    longitude: '-88.096915',
    timeZoneId: 'America/Chicago',
  },
  '54161': {
    zip: '54161',
    city: 'Pound',
    state: 'WI',
    latitude: '45.098136',
    longitude: '-88.211847',
    timeZoneId: 'America/Chicago',
  },
  '54162': {
    zip: '54162',
    city: 'Pulaski',
    state: 'WI',
    latitude: '44.651006',
    longitude: '-88.212562',
    timeZoneId: 'America/Chicago',
  },
  '54165': {
    zip: '54165',
    city: 'Seymour',
    state: 'WI',
    latitude: '44.500193',
    longitude: '-88.301344',
    timeZoneId: 'America/Chicago',
  },
  '54166': {
    zip: '54166',
    city: 'Shawano',
    state: 'WI',
    latitude: '44.780208',
    longitude: '-88.590326',
    timeZoneId: 'America/Chicago',
  },
  '54169': {
    zip: '54169',
    city: 'Sherwood',
    state: 'WI',
    latitude: '44.174879',
    longitude: '-88.25861',
    timeZoneId: 'America/Chicago',
  },
  '54170': {
    zip: '54170',
    city: 'Shiocton',
    state: 'WI',
    latitude: '44.439937',
    longitude: '-88.57801',
    timeZoneId: 'America/Chicago',
  },
  '54171': {
    zip: '54171',
    city: 'Sobieski',
    state: 'WI',
    latitude: '44.7216',
    longitude: '-88.12416',
    timeZoneId: 'America/Chicago',
  },
  '54173': {
    zip: '54173',
    city: 'Suamico',
    state: 'WI',
    latitude: '44.639018',
    longitude: '-88.035848',
    timeZoneId: 'America/Chicago',
  },
  '54174': {
    zip: '54174',
    city: 'Suring',
    state: 'WI',
    latitude: '45.035299',
    longitude: '-88.37723',
    timeZoneId: 'America/Chicago',
  },
  '54175': {
    zip: '54175',
    city: 'Townsend',
    state: 'WI',
    latitude: '45.315886',
    longitude: '-88.616239',
    timeZoneId: 'America/Chicago',
  },
  '54177': {
    zip: '54177',
    city: 'Wausaukee',
    state: 'WI',
    latitude: '45.360633',
    longitude: '-87.911018',
    timeZoneId: 'America/Chicago',
  },
  '54180': {
    zip: '54180',
    city: 'Wrightstown',
    state: 'WI',
    latitude: '44.325104',
    longitude: '-88.169588',
    timeZoneId: 'America/Chicago',
  },
  '54201': {
    zip: '54201',
    city: 'Algoma',
    state: 'WI',
    latitude: '44.607804',
    longitude: '-87.447524',
    timeZoneId: 'America/Chicago',
  },
  '54202': {
    zip: '54202',
    city: 'Baileys Harbor',
    state: 'WI',
    latitude: '45.067316',
    longitude: '-87.143147',
    timeZoneId: 'America/Chicago',
  },
  '54204': {
    zip: '54204',
    city: 'Brussels',
    state: 'WI',
    latitude: '44.749355',
    longitude: '-87.634647',
    timeZoneId: 'America/Chicago',
  },
  '54205': {
    zip: '54205',
    city: 'Casco',
    state: 'WI',
    latitude: '44.554361',
    longitude: '-87.624666',
    timeZoneId: 'America/Chicago',
  },
  '54207': {
    zip: '54207',
    city: 'Collins',
    state: 'WI',
    latitude: '44.085255',
    longitude: '-87.981193',
    timeZoneId: 'America/Chicago',
  },
  '54208': {
    zip: '54208',
    city: 'Denmark',
    state: 'WI',
    latitude: '44.368075',
    longitude: '-87.854079',
    timeZoneId: 'America/Chicago',
  },
  '54209': {
    zip: '54209',
    city: 'Egg Harbor',
    state: 'WI',
    latitude: '45.017597',
    longitude: '-87.282895',
    timeZoneId: 'America/Chicago',
  },
  '54210': {
    zip: '54210',
    city: 'Ellison Bay',
    state: 'WI',
    latitude: '45.261249',
    longitude: '-87.042797',
    timeZoneId: 'America/Chicago',
  },
  '54211': {
    zip: '54211',
    city: 'Ephraim',
    state: 'WI',
    latitude: '45.155329',
    longitude: '-87.164732',
    timeZoneId: 'America/Chicago',
  },
  '54212': {
    zip: '54212',
    city: 'Fish Creek',
    state: 'WI',
    latitude: '45.105511',
    longitude: '-87.217607',
    timeZoneId: 'America/Chicago',
  },
  '54213': {
    zip: '54213',
    city: 'Forestville',
    state: 'WI',
    latitude: '44.703669',
    longitude: '-87.502686',
    timeZoneId: 'America/Chicago',
  },
  '54214': {
    zip: '54214',
    city: 'Francis Creek',
    state: 'WI',
    latitude: '44.205072',
    longitude: '-87.71925',
    timeZoneId: 'America/Chicago',
  },
  '54215': {
    zip: '54215',
    city: 'Kellnersville',
    state: 'WI',
    latitude: '44.218693',
    longitude: '-87.814613',
    timeZoneId: 'America/Chicago',
  },
  '54216': {
    zip: '54216',
    city: 'Kewaunee',
    state: 'WI',
    latitude: '44.451161',
    longitude: '-87.514802',
    timeZoneId: 'America/Chicago',
  },
  '54217': {
    zip: '54217',
    city: 'Luxemburg',
    state: 'WI',
    latitude: '44.59416',
    longitude: '-87.71592',
    timeZoneId: 'America/Chicago',
  },
  '54220': {
    zip: '54220',
    city: 'Manitowoc',
    state: 'WI',
    latitude: '44.095713',
    longitude: '-87.71106',
    timeZoneId: 'America/Chicago',
  },
  '54221': {
    zip: '54221',
    city: 'Manitowoc',
    state: 'WI',
    latitude: '44.128906',
    longitude: '-87.605902',
    timeZoneId: 'America/Chicago',
  },
  '54226': {
    zip: '54226',
    city: 'Maplewood',
    state: 'WI',
    latitude: '44.72423',
    longitude: '-87.483531',
    timeZoneId: 'America/Chicago',
  },
  '54227': {
    zip: '54227',
    city: 'Maribel',
    state: 'WI',
    latitude: '44.283146',
    longitude: '-87.809314',
    timeZoneId: 'America/Chicago',
  },
  '54228': {
    zip: '54228',
    city: 'Mishicot',
    state: 'WI',
    latitude: '44.264424',
    longitude: '-87.649973',
    timeZoneId: 'America/Chicago',
  },
  '54229': {
    zip: '54229',
    city: 'New Franken',
    state: 'WI',
    latitude: '44.559527',
    longitude: '-87.833095',
    timeZoneId: 'America/Chicago',
  },
  '54230': {
    zip: '54230',
    city: 'Reedsville',
    state: 'WI',
    latitude: '44.15139',
    longitude: '-87.921245',
    timeZoneId: 'America/Chicago',
  },
  '54232': {
    zip: '54232',
    city: 'Saint Nazianz',
    state: 'WI',
    latitude: '44.00442',
    longitude: '-87.92425',
    timeZoneId: 'America/Chicago',
  },
  '54234': {
    zip: '54234',
    city: 'Sister Bay',
    state: 'WI',
    latitude: '45.189269',
    longitude: '-87.114286',
    timeZoneId: 'America/Chicago',
  },
  '54235': {
    zip: '54235',
    city: 'Sturgeon Bay',
    state: 'WI',
    latitude: '44.84316',
    longitude: '-87.39726',
    timeZoneId: 'America/Chicago',
  },
  '54240': {
    zip: '54240',
    city: 'Tisch Mills',
    state: 'WI',
    latitude: '44.327519',
    longitude: '-87.623779',
    timeZoneId: 'America/Chicago',
  },
  '54241': {
    zip: '54241',
    city: 'Two Rivers',
    state: 'WI',
    latitude: '44.180296',
    longitude: '-87.602195',
    timeZoneId: 'America/Chicago',
  },
  '54245': {
    zip: '54245',
    city: 'Valders',
    state: 'WI',
    latitude: '44.034289',
    longitude: '-87.883227',
    timeZoneId: 'America/Chicago',
  },
  '54246': {
    zip: '54246',
    city: 'Washington Island',
    state: 'WI',
    latitude: '45.370595',
    longitude: '-86.902605',
    timeZoneId: 'America/Chicago',
  },
  '54247': {
    zip: '54247',
    city: 'Whitelaw',
    state: 'WI',
    latitude: '44.183956',
    longitude: '-87.792516',
    timeZoneId: 'America/Chicago',
  },
  '54301': {
    zip: '54301',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.4775',
    longitude: '-88.016111',
    timeZoneId: 'America/Chicago',
  },
  '54302': {
    zip: '54302',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.505133',
    longitude: '-87.971774',
    timeZoneId: 'America/Chicago',
  },
  '54303': {
    zip: '54303',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.529758',
    longitude: '-88.061259',
    timeZoneId: 'America/Chicago',
  },
  '54304': {
    zip: '54304',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.503211',
    longitude: '-88.06735',
    timeZoneId: 'America/Chicago',
  },
  '54305': {
    zip: '54305',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.533152',
    longitude: '-88.096245',
    timeZoneId: 'America/Chicago',
  },
  '54306': {
    zip: '54306',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.519509',
    longitude: '-88.017667',
    timeZoneId: 'America/Chicago',
  },
  '54307': {
    zip: '54307',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.532127',
    longitude: '-88.094902',
    timeZoneId: 'America/Chicago',
  },
  '54308': {
    zip: '54308',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.509218',
    longitude: '-87.934095',
    timeZoneId: 'America/Chicago',
  },
  '54311': {
    zip: '54311',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.493458',
    longitude: '-87.928499',
    timeZoneId: 'America/Chicago',
  },
  '54313': {
    zip: '54313',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.558732',
    longitude: '-88.102722',
    timeZoneId: 'America/Chicago',
  },
  '54324': {
    zip: '54324',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.479479',
    longitude: '-88.021349',
    timeZoneId: 'America/Chicago',
  },
  '54344': {
    zip: '54344',
    city: 'Green Bay',
    state: 'WI',
    latitude: '44.41919',
    longitude: '-88.104737',
    timeZoneId: 'America/Chicago',
  },
  '54401': {
    zip: '54401',
    city: 'Wausau',
    state: 'WI',
    latitude: '44.96054',
    longitude: '-89.656218',
    timeZoneId: 'America/Chicago',
  },
  '54402': {
    zip: '54402',
    city: 'Wausau',
    state: 'WI',
    latitude: '45.020657',
    longitude: '-89.647204',
    timeZoneId: 'America/Chicago',
  },
  '54403': {
    zip: '54403',
    city: 'Wausau',
    state: 'WI',
    latitude: '44.960699',
    longitude: '-89.599298',
    timeZoneId: 'America/Chicago',
  },
  '54404': {
    zip: '54404',
    city: 'Marshfield',
    state: 'WI',
    latitude: '44.667961',
    longitude: '-90.168447',
    timeZoneId: 'America/Chicago',
  },
  '54405': {
    zip: '54405',
    city: 'Abbotsford',
    state: 'WI',
    latitude: '44.948218',
    longitude: '-90.321911',
    timeZoneId: 'America/Chicago',
  },
  '54406': {
    zip: '54406',
    city: 'Amherst',
    state: 'WI',
    latitude: '44.424411',
    longitude: '-89.318885',
    timeZoneId: 'America/Chicago',
  },
  '54407': {
    zip: '54407',
    city: 'Amherst Junction',
    state: 'WI',
    latitude: '44.518339',
    longitude: '-89.294289',
    timeZoneId: 'America/Chicago',
  },
  '54408': {
    zip: '54408',
    city: 'Aniwa',
    state: 'WI',
    latitude: '45.045303',
    longitude: '-89.291462',
    timeZoneId: 'America/Chicago',
  },
  '54409': {
    zip: '54409',
    city: 'Antigo',
    state: 'WI',
    latitude: '45.137836',
    longitude: '-89.151432',
    timeZoneId: 'America/Chicago',
  },
  '54410': {
    zip: '54410',
    city: 'Arpin',
    state: 'WI',
    latitude: '44.542991',
    longitude: '-90.047517',
    timeZoneId: 'America/Chicago',
  },
  '54411': {
    zip: '54411',
    city: 'Athens',
    state: 'WI',
    latitude: '45.034525',
    longitude: '-90.084577',
    timeZoneId: 'America/Chicago',
  },
  '54412': {
    zip: '54412',
    city: 'Auburndale',
    state: 'WI',
    latitude: '44.636525',
    longitude: '-90.00453',
    timeZoneId: 'America/Chicago',
  },
  '54413': {
    zip: '54413',
    city: 'Babcock',
    state: 'WI',
    latitude: '44.28085',
    longitude: '-90.112996',
    timeZoneId: 'America/Chicago',
  },
  '54414': {
    zip: '54414',
    city: 'Birnamwood',
    state: 'WI',
    latitude: '44.932433',
    longitude: '-89.211831',
    timeZoneId: 'America/Chicago',
  },
  '54415': {
    zip: '54415',
    city: 'Blenker',
    state: 'WI',
    latitude: '44.618384',
    longitude: '-89.918069',
    timeZoneId: 'America/Chicago',
  },
  '54416': {
    zip: '54416',
    city: 'Bowler',
    state: 'WI',
    latitude: '44.862605',
    longitude: '-88.981079',
    timeZoneId: 'America/Chicago',
  },
  '54417': {
    zip: '54417',
    city: 'Brokaw',
    state: 'WI',
    latitude: '45.02775',
    longitude: '-89.651093',
    timeZoneId: 'America/Chicago',
  },
  '54418': {
    zip: '54418',
    city: 'Bryant',
    state: 'WI',
    latitude: '45.22032',
    longitude: '-88.96866',
    timeZoneId: 'America/Chicago',
  },
  '54420': {
    zip: '54420',
    city: 'Chili',
    state: 'WI',
    latitude: '44.574394',
    longitude: '-90.3003',
    timeZoneId: 'America/Chicago',
  },
  '54421': {
    zip: '54421',
    city: 'Colby',
    state: 'WI',
    latitude: '44.912425',
    longitude: '-90.319914',
    timeZoneId: 'America/Chicago',
  },
  '54422': {
    zip: '54422',
    city: 'Curtiss',
    state: 'WI',
    latitude: '44.953643',
    longitude: '-90.433401',
    timeZoneId: 'America/Chicago',
  },
  '54423': {
    zip: '54423',
    city: 'Custer',
    state: 'WI',
    latitude: '44.582844',
    longitude: '-89.419872',
    timeZoneId: 'America/Chicago',
  },
  '54424': {
    zip: '54424',
    city: 'Deerbrook',
    state: 'WI',
    latitude: '45.24245',
    longitude: '-89.15616',
    timeZoneId: 'America/Chicago',
  },
  '54425': {
    zip: '54425',
    city: 'Dorchester',
    state: 'WI',
    latitude: '45.006873',
    longitude: '-90.331133',
    timeZoneId: 'America/Chicago',
  },
  '54426': {
    zip: '54426',
    city: 'Edgar',
    state: 'WI',
    latitude: '44.892081',
    longitude: '-89.992307',
    timeZoneId: 'America/Chicago',
  },
  '54427': {
    zip: '54427',
    city: 'Eland',
    state: 'WI',
    latitude: '44.821512',
    longitude: '-89.266141',
    timeZoneId: 'America/Chicago',
  },
  '54428': {
    zip: '54428',
    city: 'Elcho',
    state: 'WI',
    latitude: '45.45876',
    longitude: '-89.11014',
    timeZoneId: 'America/Chicago',
  },
  '54429': {
    zip: '54429',
    city: 'Elderon',
    state: 'WI',
    latitude: '44.782305',
    longitude: '-89.246015',
    timeZoneId: 'America/Chicago',
  },
  '54430': {
    zip: '54430',
    city: 'Elton',
    state: 'WI',
    latitude: '45.150241',
    longitude: '-88.895219',
    timeZoneId: 'America/Chicago',
  },
  '54433': {
    zip: '54433',
    city: 'Gilman',
    state: 'WI',
    latitude: '45.214708',
    longitude: '-90.818461',
    timeZoneId: 'America/Chicago',
  },
  '54435': {
    zip: '54435',
    city: 'Gleason',
    state: 'WI',
    latitude: '45.400965',
    longitude: '-89.462214',
    timeZoneId: 'America/Chicago',
  },
  '54436': {
    zip: '54436',
    city: 'Granton',
    state: 'WI',
    latitude: '44.569207',
    longitude: '-90.452286',
    timeZoneId: 'America/Chicago',
  },
  '54437': {
    zip: '54437',
    city: 'Greenwood',
    state: 'WI',
    latitude: '44.768935',
    longitude: '-90.603954',
    timeZoneId: 'America/Chicago',
  },
  '54440': {
    zip: '54440',
    city: 'Hatley',
    state: 'WI',
    latitude: '44.822844',
    longitude: '-89.379647',
    timeZoneId: 'America/Chicago',
  },
  '54441': {
    zip: '54441',
    city: 'Hewitt',
    state: 'WI',
    latitude: '44.642149',
    longitude: '-90.103456',
    timeZoneId: 'America/Chicago',
  },
  '54442': {
    zip: '54442',
    city: 'Irma',
    state: 'WI',
    latitude: '45.423722',
    longitude: '-89.692568',
    timeZoneId: 'America/Chicago',
  },
  '54443': {
    zip: '54443',
    city: 'Junction City',
    state: 'WI',
    latitude: '44.604785',
    longitude: '-89.748588',
    timeZoneId: 'America/Chicago',
  },
  '54446': {
    zip: '54446',
    city: 'Loyal',
    state: 'WI',
    latitude: '44.736705',
    longitude: '-90.493341',
    timeZoneId: 'America/Chicago',
  },
  '54447': {
    zip: '54447',
    city: 'Lublin',
    state: 'WI',
    latitude: '45.122508',
    longitude: '-90.693652',
    timeZoneId: 'America/Chicago',
  },
  '54448': {
    zip: '54448',
    city: 'Marathon',
    state: 'WI',
    latitude: '44.930712',
    longitude: '-89.834421',
    timeZoneId: 'America/Chicago',
  },
  '54449': {
    zip: '54449',
    city: 'Marshfield',
    state: 'WI',
    latitude: '44.6121',
    longitude: '-90.18792',
    timeZoneId: 'America/Chicago',
  },
  '54450': {
    zip: '54450',
    city: 'Mattoon',
    state: 'WI',
    latitude: '45.005716',
    longitude: '-89.042156',
    timeZoneId: 'America/Chicago',
  },
  '54451': {
    zip: '54451',
    city: 'Medford',
    state: 'WI',
    latitude: '45.17028',
    longitude: '-90.37074',
    timeZoneId: 'America/Chicago',
  },
  '54452': {
    zip: '54452',
    city: 'Merrill',
    state: 'WI',
    latitude: '45.182325',
    longitude: '-89.694701',
    timeZoneId: 'America/Chicago',
  },
  '54454': {
    zip: '54454',
    city: 'Milladore',
    state: 'WI',
    latitude: '44.602472',
    longitude: '-89.883051',
    timeZoneId: 'America/Chicago',
  },
  '54455': {
    zip: '54455',
    city: 'Mosinee',
    state: 'WI',
    latitude: '44.788648',
    longitude: '-89.687985',
    timeZoneId: 'America/Chicago',
  },
  '54456': {
    zip: '54456',
    city: 'Neillsville',
    state: 'WI',
    latitude: '44.563991',
    longitude: '-90.593555',
    timeZoneId: 'America/Chicago',
  },
  '54457': {
    zip: '54457',
    city: 'Nekoosa',
    state: 'WI',
    latitude: '44.25192',
    longitude: '-89.86104',
    timeZoneId: 'America/Chicago',
  },
  '54458': {
    zip: '54458',
    city: 'Nelsonville',
    state: 'WI',
    latitude: '44.493237',
    longitude: '-89.311107',
    timeZoneId: 'America/Chicago',
  },
  '54459': {
    zip: '54459',
    city: 'Ogema',
    state: 'WI',
    latitude: '45.43596',
    longitude: '-90.242894',
    timeZoneId: 'America/Chicago',
  },
  '54460': {
    zip: '54460',
    city: 'Owen',
    state: 'WI',
    latitude: '44.947848',
    longitude: '-90.563733',
    timeZoneId: 'America/Chicago',
  },
  '54462': {
    zip: '54462',
    city: 'Pearson',
    state: 'WI',
    latitude: '45.388277',
    longitude: '-89.085985',
    timeZoneId: 'America/Chicago',
  },
  '54463': {
    zip: '54463',
    city: 'Pelican Lake',
    state: 'WI',
    latitude: '45.502399',
    longitude: '-89.159806',
    timeZoneId: 'America/Chicago',
  },
  '54464': {
    zip: '54464',
    city: 'Phlox',
    state: 'WI',
    latitude: '45.049754',
    longitude: '-89.016863',
    timeZoneId: 'America/Chicago',
  },
  '54465': {
    zip: '54465',
    city: 'Pickerel',
    state: 'WI',
    latitude: '45.374025',
    longitude: '-88.89595',
    timeZoneId: 'America/Chicago',
  },
  '54466': {
    zip: '54466',
    city: 'Pittsville',
    state: 'WI',
    latitude: '44.4481',
    longitude: '-90.177921',
    timeZoneId: 'America/Chicago',
  },
  '54467': {
    zip: '54467',
    city: 'Plover',
    state: 'WI',
    latitude: '44.43981',
    longitude: '-89.544706',
    timeZoneId: 'America/Chicago',
  },
  '54469': {
    zip: '54469',
    city: 'Port Edwards',
    state: 'WI',
    latitude: '44.350304',
    longitude: '-89.868238',
    timeZoneId: 'America/Chicago',
  },
  '54470': {
    zip: '54470',
    city: 'Rib Lake',
    state: 'WI',
    latitude: '45.320557',
    longitude: '-90.194188',
    timeZoneId: 'America/Chicago',
  },
  '54471': {
    zip: '54471',
    city: 'Ringle',
    state: 'WI',
    latitude: '44.896444',
    longitude: '-89.426641',
    timeZoneId: 'America/Chicago',
  },
  '54472': {
    zip: '54472',
    city: 'Marshfield',
    state: 'WI',
    latitude: '44.662245',
    longitude: '-90.175187',
    timeZoneId: 'America/Chicago',
  },
  '54473': {
    zip: '54473',
    city: 'Rosholt',
    state: 'WI',
    latitude: '44.630777',
    longitude: '-89.318589',
    timeZoneId: 'America/Chicago',
  },
  '54474': {
    zip: '54474',
    city: 'Rothschild',
    state: 'WI',
    latitude: '44.933333',
    longitude: '-89.6',
    timeZoneId: 'America/Chicago',
  },
  '54475': {
    zip: '54475',
    city: 'Rudolph',
    state: 'WI',
    latitude: '44.479985',
    longitude: '-89.802355',
    timeZoneId: 'America/Chicago',
  },
  '54476': {
    zip: '54476',
    city: 'Schofield',
    state: 'WI',
    latitude: '44.906068',
    longitude: '-89.571594',
    timeZoneId: 'America/Chicago',
  },
  '54479': {
    zip: '54479',
    city: 'Spencer',
    state: 'WI',
    latitude: '44.756325',
    longitude: '-90.297794',
    timeZoneId: 'America/Chicago',
  },
  '54480': {
    zip: '54480',
    city: 'Stetsonville',
    state: 'WI',
    latitude: '45.078102',
    longitude: '-90.315158',
    timeZoneId: 'America/Chicago',
  },
  '54481': {
    zip: '54481',
    city: 'Stevens Point',
    state: 'WI',
    latitude: '44.54298',
    longitude: '-89.56446',
    timeZoneId: 'America/Chicago',
  },
  '54482': {
    zip: '54482',
    city: 'Stevens Point',
    state: 'WI',
    latitude: '44.573483',
    longitude: '-89.546894',
    timeZoneId: 'America/Chicago',
  },
  '54484': {
    zip: '54484',
    city: 'Stratford',
    state: 'WI',
    latitude: '44.797647',
    longitude: '-90.067948',
    timeZoneId: 'America/Chicago',
  },
  '54485': {
    zip: '54485',
    city: 'Summit Lake',
    state: 'WI',
    latitude: '45.364927',
    longitude: '-89.187376',
    timeZoneId: 'America/Chicago',
  },
  '54486': {
    zip: '54486',
    city: 'Tigerton',
    state: 'WI',
    latitude: '44.743866',
    longitude: '-89.041687',
    timeZoneId: 'America/Chicago',
  },
  '54487': {
    zip: '54487',
    city: 'Tomahawk',
    state: 'WI',
    latitude: '45.47452',
    longitude: '-89.727183',
    timeZoneId: 'America/Chicago',
  },
  '54488': {
    zip: '54488',
    city: 'Unity',
    state: 'WI',
    latitude: '44.849654',
    longitude: '-90.320639',
    timeZoneId: 'America/Chicago',
  },
  '54489': {
    zip: '54489',
    city: 'Vesper',
    state: 'WI',
    latitude: '44.46139',
    longitude: '-89.993344',
    timeZoneId: 'America/Chicago',
  },
  '54490': {
    zip: '54490',
    city: 'Westboro',
    state: 'WI',
    latitude: '45.332916',
    longitude: '-90.359136',
    timeZoneId: 'America/Chicago',
  },
  '54491': {
    zip: '54491',
    city: 'White Lake',
    state: 'WI',
    latitude: '45.1878',
    longitude: '-88.7313',
    timeZoneId: 'America/Chicago',
  },
  '54492': {
    zip: '54492',
    city: 'Stevens Point',
    state: 'WI',
    latitude: '44.491605',
    longitude: '-89.529732',
    timeZoneId: 'America/Chicago',
  },
  '54493': {
    zip: '54493',
    city: 'Willard',
    state: 'WI',
    latitude: '44.697478',
    longitude: '-90.815604',
    timeZoneId: 'America/Chicago',
  },
  '54494': {
    zip: '54494',
    city: 'Wisconsin Rapids',
    state: 'WI',
    latitude: '44.35332',
    longitude: '-89.74872',
    timeZoneId: 'America/Chicago',
  },
  '54495': {
    zip: '54495',
    city: 'Wisconsin Rapids',
    state: 'WI',
    latitude: '44.404404',
    longitude: '-89.867303',
    timeZoneId: 'America/Chicago',
  },
  '54498': {
    zip: '54498',
    city: 'Withee',
    state: 'WI',
    latitude: '44.953103',
    longitude: '-90.595538',
    timeZoneId: 'America/Chicago',
  },
  '54499': {
    zip: '54499',
    city: 'Wittenberg',
    state: 'WI',
    latitude: '44.825108',
    longitude: '-89.169054',
    timeZoneId: 'America/Chicago',
  },
  '54501': {
    zip: '54501',
    city: 'Rhinelander',
    state: 'WI',
    latitude: '45.69132',
    longitude: '-89.3496',
    timeZoneId: 'America/Chicago',
  },
  '54511': {
    zip: '54511',
    city: 'Argonne',
    state: 'WI',
    latitude: '45.724974',
    longitude: '-88.860373',
    timeZoneId: 'America/Chicago',
  },
  '54512': {
    zip: '54512',
    city: 'Boulder Junction',
    state: 'WI',
    latitude: '46.096191',
    longitude: '-89.653823',
    timeZoneId: 'America/Chicago',
  },
  '54513': {
    zip: '54513',
    city: 'Brantwood',
    state: 'WI',
    latitude: '45.569485',
    longitude: '-90.125463',
    timeZoneId: 'America/Chicago',
  },
  '54514': {
    zip: '54514',
    city: 'Butternut',
    state: 'WI',
    latitude: '46.086253',
    longitude: '-90.251505',
    timeZoneId: 'America/Chicago',
  },
  '54515': {
    zip: '54515',
    city: 'Catawba',
    state: 'WI',
    latitude: '45.538877',
    longitude: '-90.527494',
    timeZoneId: 'America/Chicago',
  },
  '54517': {
    zip: '54517',
    city: 'Clam Lake',
    state: 'WI',
    latitude: '46.269533',
    longitude: '-90.90174',
    timeZoneId: 'America/Chicago',
  },
  '54519': {
    zip: '54519',
    city: 'Conover',
    state: 'WI',
    latitude: '46.044005',
    longitude: '-89.26851',
    timeZoneId: 'America/Chicago',
  },
  '54520': {
    zip: '54520',
    city: 'Crandon',
    state: 'WI',
    latitude: '45.568279',
    longitude: '-88.904809',
    timeZoneId: 'America/Chicago',
  },
  '54521': {
    zip: '54521',
    city: 'Eagle River',
    state: 'WI',
    latitude: '45.9105',
    longitude: '-89.2245',
    timeZoneId: 'America/Chicago',
  },
  '54524': {
    zip: '54524',
    city: 'Fifield',
    state: 'WI',
    latitude: '45.875946',
    longitude: '-90.41707',
    timeZoneId: 'America/Chicago',
  },
  '54525': {
    zip: '54525',
    city: 'Gile',
    state: 'WI',
    latitude: '46.43006',
    longitude: '-90.222289',
    timeZoneId: 'America/Chicago',
  },
  '54526': {
    zip: '54526',
    city: 'Glen Flora',
    state: 'WI',
    latitude: '45.50478',
    longitude: '-90.85962',
    timeZoneId: 'America/Chicago',
  },
  '54527': {
    zip: '54527',
    city: 'Glidden',
    state: 'WI',
    latitude: '46.135765',
    longitude: '-90.576088',
    timeZoneId: 'America/Chicago',
  },
  '54529': {
    zip: '54529',
    city: 'Harshaw',
    state: 'WI',
    latitude: '45.67302',
    longitude: '-89.67156',
    timeZoneId: 'America/Chicago',
  },
  '54530': {
    zip: '54530',
    city: 'Hawkins',
    state: 'WI',
    latitude: '45.510622',
    longitude: '-90.712444',
    timeZoneId: 'America/Chicago',
  },
  '54531': {
    zip: '54531',
    city: 'Hazelhurst',
    state: 'WI',
    latitude: '45.766734',
    longitude: '-89.756601',
    timeZoneId: 'America/Chicago',
  },
  '54532': {
    zip: '54532',
    city: 'Heafford Junction',
    state: 'WI',
    latitude: '45.5472',
    longitude: '-89.71518',
    timeZoneId: 'America/Chicago',
  },
  '54534': {
    zip: '54534',
    city: 'Hurley',
    state: 'WI',
    latitude: '46.481944',
    longitude: '-90.305833',
    timeZoneId: 'America/Chicago',
  },
  '54536': {
    zip: '54536',
    city: 'Iron Belt',
    state: 'WI',
    latitude: '46.414662',
    longitude: '-90.271853',
    timeZoneId: 'America/Chicago',
  },
  '54537': {
    zip: '54537',
    city: 'Kennan',
    state: 'WI',
    latitude: '45.530331',
    longitude: '-90.591477',
    timeZoneId: 'America/Chicago',
  },
  '54538': {
    zip: '54538',
    city: 'Lac du Flambeau',
    state: 'WI',
    latitude: '45.966884',
    longitude: '-89.889537',
    timeZoneId: 'America/Chicago',
  },
  '54539': {
    zip: '54539',
    city: 'Lake Tomahawk',
    state: 'WI',
    latitude: '45.807785',
    longitude: '-89.581053',
    timeZoneId: 'America/Chicago',
  },
  '54540': {
    zip: '54540',
    city: 'Land O Lakes',
    state: 'WI',
    latitude: '46.159664',
    longitude: '-89.313769',
    timeZoneId: 'America/Chicago',
  },
  '54541': {
    zip: '54541',
    city: 'Laona',
    state: 'WI',
    latitude: '45.55848',
    longitude: '-88.66428',
    timeZoneId: 'America/Chicago',
  },
  '54542': {
    zip: '54542',
    city: 'Long Lake',
    state: 'WI',
    latitude: '45.84',
    longitude: '-88.66',
    timeZoneId: 'America/Chicago',
  },
  '54543': {
    zip: '54543',
    city: 'Mc Naughton',
    state: 'WI',
    latitude: '45.767082',
    longitude: '-89.514971',
    timeZoneId: 'America/Chicago',
  },
  '54545': {
    zip: '54545',
    city: 'Manitowish Waters',
    state: 'WI',
    latitude: '46.088375',
    longitude: '-89.821821',
    timeZoneId: 'America/Chicago',
  },
  '54546': {
    zip: '54546',
    city: 'Mellen',
    state: 'WI',
    latitude: '46.324004',
    longitude: '-90.656945',
    timeZoneId: 'America/Chicago',
  },
  '54547': {
    zip: '54547',
    city: 'Mercer',
    state: 'WI',
    latitude: '46.1892',
    longitude: '-90.07668',
    timeZoneId: 'America/Chicago',
  },
  '54548': {
    zip: '54548',
    city: 'Minocqua',
    state: 'WI',
    latitude: '45.862814',
    longitude: '-89.759011',
    timeZoneId: 'America/Chicago',
  },
  '54550': {
    zip: '54550',
    city: 'Montreal',
    state: 'WI',
    latitude: '46.415615',
    longitude: '-90.262829',
    timeZoneId: 'America/Chicago',
  },
  '54552': {
    zip: '54552',
    city: 'Park Falls',
    state: 'WI',
    latitude: '45.93138',
    longitude: '-90.39714',
    timeZoneId: 'America/Chicago',
  },
  '54554': {
    zip: '54554',
    city: 'Phelps',
    state: 'WI',
    latitude: '46.063958',
    longitude: '-89.06936',
    timeZoneId: 'America/Chicago',
  },
  '54555': {
    zip: '54555',
    city: 'Phillips',
    state: 'WI',
    latitude: '45.691966',
    longitude: '-90.401212',
    timeZoneId: 'America/Chicago',
  },
  '54556': {
    zip: '54556',
    city: 'Prentice',
    state: 'WI',
    latitude: '45.545892',
    longitude: '-90.290937',
    timeZoneId: 'America/Chicago',
  },
  '54557': {
    zip: '54557',
    city: 'Presque Isle',
    state: 'WI',
    latitude: '46.22382',
    longitude: '-89.75892',
    timeZoneId: 'America/Chicago',
  },
  '54558': {
    zip: '54558',
    city: 'Saint Germain',
    state: 'WI',
    latitude: '45.926415',
    longitude: '-89.481221',
    timeZoneId: 'America/Chicago',
  },
  '54559': {
    zip: '54559',
    city: 'Saxon',
    state: 'WI',
    latitude: '46.522463',
    longitude: '-90.55624',
    timeZoneId: 'America/Chicago',
  },
  '54560': {
    zip: '54560',
    city: 'Sayner',
    state: 'WI',
    latitude: '45.993836',
    longitude: '-89.52014',
    timeZoneId: 'America/Chicago',
  },
  '54561': {
    zip: '54561',
    city: 'Star Lake',
    state: 'WI',
    latitude: '46.054444',
    longitude: '-89.457952',
    timeZoneId: 'America/Chicago',
  },
  '54562': {
    zip: '54562',
    city: 'Three Lakes',
    state: 'WI',
    latitude: '45.805375',
    longitude: '-89.123651',
    timeZoneId: 'America/Chicago',
  },
  '54563': {
    zip: '54563',
    city: 'Tony',
    state: 'WI',
    latitude: '45.573335',
    longitude: '-90.953398',
    timeZoneId: 'America/Chicago',
  },
  '54564': {
    zip: '54564',
    city: 'Tripoli',
    state: 'WI',
    latitude: '45.646975',
    longitude: '-89.98235',
    timeZoneId: 'America/Chicago',
  },
  '54565': {
    zip: '54565',
    city: 'Upson',
    state: 'WI',
    latitude: '46.342704',
    longitude: '-90.470449',
    timeZoneId: 'America/Chicago',
  },
  '54566': {
    zip: '54566',
    city: 'Wabeno',
    state: 'WI',
    latitude: '45.438',
    longitude: '-88.63128',
    timeZoneId: 'America/Chicago',
  },
  '54568': {
    zip: '54568',
    city: 'Woodruff',
    state: 'WI',
    latitude: '45.96816',
    longitude: '-89.69526',
    timeZoneId: 'America/Chicago',
  },
  '54601': {
    zip: '54601',
    city: 'La Crosse',
    state: 'WI',
    latitude: '43.795836',
    longitude: '-91.221089',
    timeZoneId: 'America/Chicago',
  },
  '54602': {
    zip: '54602',
    city: 'La Crosse',
    state: 'WI',
    latitude: '43.827618',
    longitude: '-91.232393',
    timeZoneId: 'America/Chicago',
  },
  '54603': {
    zip: '54603',
    city: 'La Crosse',
    state: 'WI',
    latitude: '43.848862',
    longitude: '-91.249344',
    timeZoneId: 'America/Chicago',
  },
  '54610': {
    zip: '54610',
    city: 'Alma',
    state: 'WI',
    latitude: '44.32505',
    longitude: '-91.912041',
    timeZoneId: 'America/Chicago',
  },
  '54611': {
    zip: '54611',
    city: 'Alma Center',
    state: 'WI',
    latitude: '44.433888',
    longitude: '-90.911967',
    timeZoneId: 'America/Chicago',
  },
  '54612': {
    zip: '54612',
    city: 'Arcadia',
    state: 'WI',
    latitude: '44.249616',
    longitude: '-91.495065',
    timeZoneId: 'America/Chicago',
  },
  '54613': {
    zip: '54613',
    city: 'Arkdale',
    state: 'WI',
    latitude: '44.064139',
    longitude: '-89.898982',
    timeZoneId: 'America/Chicago',
  },
  '54614': {
    zip: '54614',
    city: 'Bangor',
    state: 'WI',
    latitude: '43.890535',
    longitude: '-90.983181',
    timeZoneId: 'America/Chicago',
  },
  '54615': {
    zip: '54615',
    city: 'Black River Falls',
    state: 'WI',
    latitude: '44.290491',
    longitude: '-90.846708',
    timeZoneId: 'America/Chicago',
  },
  '54616': {
    zip: '54616',
    city: 'Blair',
    state: 'WI',
    latitude: '44.291331',
    longitude: '-91.231237',
    timeZoneId: 'America/Chicago',
  },
  '54618': {
    zip: '54618',
    city: 'Camp Douglas',
    state: 'WI',
    latitude: '43.921069',
    longitude: '-90.271708',
    timeZoneId: 'America/Chicago',
  },
  '54619': {
    zip: '54619',
    city: 'Cashton',
    state: 'WI',
    latitude: '43.74354',
    longitude: '-90.77712',
    timeZoneId: 'America/Chicago',
  },
  '54620': {
    zip: '54620',
    city: 'Cataract',
    state: 'WI',
    latitude: '44.091177',
    longitude: '-90.842545',
    timeZoneId: 'America/Chicago',
  },
  '54621': {
    zip: '54621',
    city: 'Chaseburg',
    state: 'WI',
    latitude: '43.655814',
    longitude: '-91.012569',
    timeZoneId: 'America/Chicago',
  },
  '54622': {
    zip: '54622',
    city: 'Cochrane',
    state: 'WI',
    latitude: '44.27178',
    longitude: '-91.8732',
    timeZoneId: 'America/Chicago',
  },
  '54623': {
    zip: '54623',
    city: 'Coon Valley',
    state: 'WI',
    latitude: '43.702221',
    longitude: '-91.011762',
    timeZoneId: 'America/Chicago',
  },
  '54624': {
    zip: '54624',
    city: 'De Soto',
    state: 'WI',
    latitude: '43.485556',
    longitude: '-91.2125',
    timeZoneId: 'America/Chicago',
  },
  '54625': {
    zip: '54625',
    city: 'Dodge',
    state: 'WI',
    latitude: '44.24714',
    longitude: '-91.491184',
    timeZoneId: 'America/Chicago',
  },
  '54626': {
    zip: '54626',
    city: 'Eastman',
    state: 'WI',
    latitude: '43.193536',
    longitude: '-91.014896',
    timeZoneId: 'America/Chicago',
  },
  '54627': {
    zip: '54627',
    city: 'Ettrick',
    state: 'WI',
    latitude: '44.174018',
    longitude: '-91.271068',
    timeZoneId: 'America/Chicago',
  },
  '54628': {
    zip: '54628',
    city: 'Ferryville',
    state: 'WI',
    latitude: '43.381985',
    longitude: '-91.00356',
    timeZoneId: 'America/Chicago',
  },
  '54629': {
    zip: '54629',
    city: 'Fountain City',
    state: 'WI',
    latitude: '44.128251',
    longitude: '-91.715157',
    timeZoneId: 'America/Chicago',
  },
  '54630': {
    zip: '54630',
    city: 'Galesville',
    state: 'WI',
    latitude: '44.076034',
    longitude: '-91.362035',
    timeZoneId: 'America/Chicago',
  },
  '54631': {
    zip: '54631',
    city: 'Gays Mills',
    state: 'WI',
    latitude: '43.291823',
    longitude: '-90.867048',
    timeZoneId: 'America/Chicago',
  },
  '54632': {
    zip: '54632',
    city: 'Genoa',
    state: 'WI',
    latitude: '43.563766',
    longitude: '-91.228395',
    timeZoneId: 'America/Chicago',
  },
  '54634': {
    zip: '54634',
    city: 'Hillsboro',
    state: 'WI',
    latitude: '43.653481',
    longitude: '-90.344014',
    timeZoneId: 'America/Chicago',
  },
  '54635': {
    zip: '54635',
    city: 'Hixton',
    state: 'WI',
    latitude: '44.394',
    longitude: '-91.03032',
    timeZoneId: 'America/Chicago',
  },
  '54636': {
    zip: '54636',
    city: 'Holmen',
    state: 'WI',
    latitude: '43.964762',
    longitude: '-91.25845',
    timeZoneId: 'America/Chicago',
  },
  '54637': {
    zip: '54637',
    city: 'Hustler',
    state: 'WI',
    latitude: '43.876945',
    longitude: '-90.275207',
    timeZoneId: 'America/Chicago',
  },
  '54638': {
    zip: '54638',
    city: 'Kendall',
    state: 'WI',
    latitude: '43.811197',
    longitude: '-90.403788',
    timeZoneId: 'America/Chicago',
  },
  '54639': {
    zip: '54639',
    city: 'La Farge',
    state: 'WI',
    latitude: '43.616403',
    longitude: '-90.624741',
    timeZoneId: 'America/Chicago',
  },
  '54641': {
    zip: '54641',
    city: 'Mather',
    state: 'WI',
    latitude: '44.144423',
    longitude: '-90.308515',
    timeZoneId: 'America/Chicago',
  },
  '54642': {
    zip: '54642',
    city: 'Melrose',
    state: 'WI',
    latitude: '44.135999',
    longitude: '-90.993372',
    timeZoneId: 'America/Chicago',
  },
  '54643': {
    zip: '54643',
    city: 'Millston',
    state: 'WI',
    latitude: '44.192529',
    longitude: '-90.64936',
    timeZoneId: 'America/Chicago',
  },
  '54644': {
    zip: '54644',
    city: 'Mindoro',
    state: 'WI',
    latitude: '44.027989',
    longitude: '-91.050927',
    timeZoneId: 'America/Chicago',
  },
  '54645': {
    zip: '54645',
    city: 'Mount Sterling',
    state: 'WI',
    latitude: '43.314232',
    longitude: '-90.930144',
    timeZoneId: 'America/Chicago',
  },
  '54646': {
    zip: '54646',
    city: 'Necedah',
    state: 'WI',
    latitude: '44.03343',
    longitude: '-90.083429',
    timeZoneId: 'America/Chicago',
  },
  '54648': {
    zip: '54648',
    city: 'Norwalk',
    state: 'WI',
    latitude: '43.822777',
    longitude: '-90.660681',
    timeZoneId: 'America/Chicago',
  },
  '54649': {
    zip: '54649',
    city: 'Oakdale',
    state: 'WI',
    latitude: '43.960617',
    longitude: '-90.381272',
    timeZoneId: 'America/Chicago',
  },
  '54650': {
    zip: '54650',
    city: 'Onalaska',
    state: 'WI',
    latitude: '43.88582',
    longitude: '-91.21725',
    timeZoneId: 'America/Chicago',
  },
  '54651': {
    zip: '54651',
    city: 'Ontario',
    state: 'WI',
    latitude: '43.723689',
    longitude: '-90.589316',
    timeZoneId: 'America/Chicago',
  },
  '54652': {
    zip: '54652',
    city: 'Readstown',
    state: 'WI',
    latitude: '43.454272',
    longitude: '-90.76295',
    timeZoneId: 'America/Chicago',
  },
  '54653': {
    zip: '54653',
    city: 'Rockland',
    state: 'WI',
    latitude: '43.905028',
    longitude: '-90.915548',
    timeZoneId: 'America/Chicago',
  },
  '54654': {
    zip: '54654',
    city: 'Seneca',
    state: 'WI',
    latitude: '43.263385',
    longitude: '-90.956187',
    timeZoneId: 'America/Chicago',
  },
  '54655': {
    zip: '54655',
    city: 'Soldiers Grove',
    state: 'WI',
    latitude: '43.385327',
    longitude: '-90.769115',
    timeZoneId: 'America/Chicago',
  },
  '54656': {
    zip: '54656',
    city: 'Sparta',
    state: 'WI',
    latitude: '43.948001',
    longitude: '-90.811171',
    timeZoneId: 'America/Chicago',
  },
  '54657': {
    zip: '54657',
    city: 'Steuben',
    state: 'WI',
    latitude: '43.202357',
    longitude: '-90.856293',
    timeZoneId: 'America/Chicago',
  },
  '54658': {
    zip: '54658',
    city: 'Stoddard',
    state: 'WI',
    latitude: '43.663195',
    longitude: '-91.218401',
    timeZoneId: 'America/Chicago',
  },
  '54659': {
    zip: '54659',
    city: 'Taylor',
    state: 'WI',
    latitude: '44.328279',
    longitude: '-91.108533',
    timeZoneId: 'America/Chicago',
  },
  '54660': {
    zip: '54660',
    city: 'Tomah',
    state: 'WI',
    latitude: '43.9941',
    longitude: '-90.45972',
    timeZoneId: 'America/Chicago',
  },
  '54661': {
    zip: '54661',
    city: 'Trempealeau',
    state: 'WI',
    latitude: '44.006596',
    longitude: '-91.437566',
    timeZoneId: 'America/Chicago',
  },
  '54662': {
    zip: '54662',
    city: 'Tunnel City',
    state: 'WI',
    latitude: '43.999554',
    longitude: '-90.560139',
    timeZoneId: 'America/Chicago',
  },
  '54664': {
    zip: '54664',
    city: 'Viola',
    state: 'WI',
    latitude: '43.443473',
    longitude: '-90.676698',
    timeZoneId: 'America/Chicago',
  },
  '54665': {
    zip: '54665',
    city: 'Viroqua',
    state: 'WI',
    latitude: '43.555035',
    longitude: '-90.889322',
    timeZoneId: 'America/Chicago',
  },
  '54666': {
    zip: '54666',
    city: 'Warrens',
    state: 'WI',
    latitude: '44.130962',
    longitude: '-90.500162',
    timeZoneId: 'America/Chicago',
  },
  '54667': {
    zip: '54667',
    city: 'Westby',
    state: 'WI',
    latitude: '43.653908',
    longitude: '-90.859455',
    timeZoneId: 'America/Chicago',
  },
  '54669': {
    zip: '54669',
    city: 'West Salem',
    state: 'WI',
    latitude: '43.899638',
    longitude: '-91.087027',
    timeZoneId: 'America/Chicago',
  },
  '54670': {
    zip: '54670',
    city: 'Wilton',
    state: 'WI',
    latitude: '43.814109',
    longitude: '-90.531731',
    timeZoneId: 'America/Chicago',
  },
  '54701': {
    zip: '54701',
    city: 'Eau Claire',
    state: 'WI',
    latitude: '44.794997',
    longitude: '-91.48326',
    timeZoneId: 'America/Chicago',
  },
  '54702': {
    zip: '54702',
    city: 'Eau Claire',
    state: 'WI',
    latitude: '44.81426',
    longitude: '-91.501979',
    timeZoneId: 'America/Chicago',
  },
  '54703': {
    zip: '54703',
    city: 'Eau Claire',
    state: 'WI',
    latitude: '44.824477',
    longitude: '-91.498625',
    timeZoneId: 'America/Chicago',
  },
  '54720': {
    zip: '54720',
    city: 'Altoona',
    state: 'WI',
    latitude: '44.801507',
    longitude: '-91.436012',
    timeZoneId: 'America/Chicago',
  },
  '54721': {
    zip: '54721',
    city: 'Arkansaw',
    state: 'WI',
    latitude: '44.619614',
    longitude: '-92.069589',
    timeZoneId: 'America/Chicago',
  },
  '54722': {
    zip: '54722',
    city: 'Augusta',
    state: 'WI',
    latitude: '44.686753',
    longitude: '-91.128347',
    timeZoneId: 'America/Chicago',
  },
  '54723': {
    zip: '54723',
    city: 'Bay City',
    state: 'WI',
    latitude: '44.588796',
    longitude: '-92.45338',
    timeZoneId: 'America/Chicago',
  },
  '54724': {
    zip: '54724',
    city: 'Bloomer',
    state: 'WI',
    latitude: '45.100309',
    longitude: '-91.483314',
    timeZoneId: 'America/Chicago',
  },
  '54725': {
    zip: '54725',
    city: 'Boyceville',
    state: 'WI',
    latitude: '45.042696',
    longitude: '-92.037334',
    timeZoneId: 'America/Chicago',
  },
  '54726': {
    zip: '54726',
    city: 'Boyd',
    state: 'WI',
    latitude: '44.951426',
    longitude: '-91.041064',
    timeZoneId: 'America/Chicago',
  },
  '54727': {
    zip: '54727',
    city: 'Cadott',
    state: 'WI',
    latitude: '44.939274',
    longitude: '-91.171476',
    timeZoneId: 'America/Chicago',
  },
  '54728': {
    zip: '54728',
    city: 'Chetek',
    state: 'WI',
    latitude: '45.317036',
    longitude: '-91.673079',
    timeZoneId: 'America/Chicago',
  },
  '54729': {
    zip: '54729',
    city: 'Chippewa Falls',
    state: 'WI',
    latitude: '45.031667',
    longitude: '-91.395833',
    timeZoneId: 'America/Chicago',
  },
  '54730': {
    zip: '54730',
    city: 'Colfax',
    state: 'WI',
    latitude: '44.999975',
    longitude: '-91.723838',
    timeZoneId: 'America/Chicago',
  },
  '54731': {
    zip: '54731',
    city: 'Conrath',
    state: 'WI',
    latitude: '45.371134',
    longitude: '-91.060975',
    timeZoneId: 'America/Chicago',
  },
  '54732': {
    zip: '54732',
    city: 'Cornell',
    state: 'WI',
    latitude: '45.153053',
    longitude: '-91.159144',
    timeZoneId: 'America/Chicago',
  },
  '54733': {
    zip: '54733',
    city: 'Dallas',
    state: 'WI',
    latitude: '45.262056',
    longitude: '-91.842404',
    timeZoneId: 'America/Chicago',
  },
  '54734': {
    zip: '54734',
    city: 'Downing',
    state: 'WI',
    latitude: '45.044489',
    longitude: '-92.107248',
    timeZoneId: 'America/Chicago',
  },
  '54735': {
    zip: '54735',
    city: 'Downsville',
    state: 'WI',
    latitude: '44.775978',
    longitude: '-91.930577',
    timeZoneId: 'America/Chicago',
  },
  '54736': {
    zip: '54736',
    city: 'Durand',
    state: 'WI',
    latitude: '44.628057',
    longitude: '-91.96196',
    timeZoneId: 'America/Chicago',
  },
  '54737': {
    zip: '54737',
    city: 'Eau Galle',
    state: 'WI',
    latitude: '44.723211',
    longitude: '-92.050773',
    timeZoneId: 'America/Chicago',
  },
  '54738': {
    zip: '54738',
    city: 'Eleva',
    state: 'WI',
    latitude: '44.572874',
    longitude: '-91.472242',
    timeZoneId: 'America/Chicago',
  },
  '54739': {
    zip: '54739',
    city: 'Elk Mound',
    state: 'WI',
    latitude: '44.874354',
    longitude: '-91.690543',
    timeZoneId: 'America/Chicago',
  },
  '54740': {
    zip: '54740',
    city: 'Elmwood',
    state: 'WI',
    latitude: '44.782104',
    longitude: '-92.151312',
    timeZoneId: 'America/Chicago',
  },
  '54741': {
    zip: '54741',
    city: 'Fairchild',
    state: 'WI',
    latitude: '44.599672',
    longitude: '-90.960257',
    timeZoneId: 'America/Chicago',
  },
  '54742': {
    zip: '54742',
    city: 'Fall Creek',
    state: 'WI',
    latitude: '44.754625',
    longitude: '-91.331294',
    timeZoneId: 'America/Chicago',
  },
  '54743': {
    zip: '54743',
    city: 'Gilmanton',
    state: 'WI',
    latitude: '44.468305',
    longitude: '-91.675117',
    timeZoneId: 'America/Chicago',
  },
  '54745': {
    zip: '54745',
    city: 'Holcombe',
    state: 'WI',
    latitude: '45.243772',
    longitude: '-91.204691',
    timeZoneId: 'America/Chicago',
  },
  '54746': {
    zip: '54746',
    city: 'Humbird',
    state: 'WI',
    latitude: '44.544399',
    longitude: '-90.870773',
    timeZoneId: 'America/Chicago',
  },
  '54747': {
    zip: '54747',
    city: 'Independence',
    state: 'WI',
    latitude: '44.358587',
    longitude: '-91.422932',
    timeZoneId: 'America/Chicago',
  },
  '54748': {
    zip: '54748',
    city: 'Jim Falls',
    state: 'WI',
    latitude: '45.06805',
    longitude: '-91.259361',
    timeZoneId: 'America/Chicago',
  },
  '54749': {
    zip: '54749',
    city: 'Knapp',
    state: 'WI',
    latitude: '44.962067',
    longitude: '-92.073568',
    timeZoneId: 'America/Chicago',
  },
  '54750': {
    zip: '54750',
    city: 'Maiden Rock',
    state: 'WI',
    latitude: '44.562824',
    longitude: '-92.308633',
    timeZoneId: 'America/Chicago',
  },
  '54751': {
    zip: '54751',
    city: 'Menomonie',
    state: 'WI',
    latitude: '44.877251',
    longitude: '-91.923866',
    timeZoneId: 'America/Chicago',
  },
  '54754': {
    zip: '54754',
    city: 'Merrillan',
    state: 'WI',
    latitude: '44.44674',
    longitude: '-90.81816',
    timeZoneId: 'America/Chicago',
  },
  '54755': {
    zip: '54755',
    city: 'Mondovi',
    state: 'WI',
    latitude: '44.566394',
    longitude: '-91.671809',
    timeZoneId: 'America/Chicago',
  },
  '54756': {
    zip: '54756',
    city: 'Nelson',
    state: 'WI',
    latitude: '44.440524',
    longitude: '-92.014778',
    timeZoneId: 'America/Chicago',
  },
  '54757': {
    zip: '54757',
    city: 'New Auburn',
    state: 'WI',
    latitude: '45.24504',
    longitude: '-91.47024',
    timeZoneId: 'America/Chicago',
  },
  '54758': {
    zip: '54758',
    city: 'Osseo',
    state: 'WI',
    latitude: '44.576212',
    longitude: '-91.220707',
    timeZoneId: 'America/Chicago',
  },
  '54759': {
    zip: '54759',
    city: 'Pepin',
    state: 'WI',
    latitude: '44.441752',
    longitude: '-92.149129',
    timeZoneId: 'America/Chicago',
  },
  '54760': {
    zip: '54760',
    city: 'Pigeon Falls',
    state: 'WI',
    latitude: '44.426866',
    longitude: '-91.20448',
    timeZoneId: 'America/Chicago',
  },
  '54761': {
    zip: '54761',
    city: 'Plum City',
    state: 'WI',
    latitude: '44.633891',
    longitude: '-92.192436',
    timeZoneId: 'America/Chicago',
  },
  '54762': {
    zip: '54762',
    city: 'Prairie Farm',
    state: 'WI',
    latitude: '45.255879',
    longitude: '-91.978677',
    timeZoneId: 'America/Chicago',
  },
  '54763': {
    zip: '54763',
    city: 'Ridgeland',
    state: 'WI',
    latitude: '45.217676',
    longitude: '-91.906935',
    timeZoneId: 'America/Chicago',
  },
  '54764': {
    zip: '54764',
    city: 'Rock Falls',
    state: 'WI',
    latitude: '44.718965',
    longitude: '-91.691873',
    timeZoneId: 'America/Chicago',
  },
  '54765': {
    zip: '54765',
    city: 'Sand Creek',
    state: 'WI',
    latitude: '45.170922',
    longitude: '-91.702804',
    timeZoneId: 'America/Chicago',
  },
  '54766': {
    zip: '54766',
    city: 'Sheldon',
    state: 'WI',
    latitude: '45.309937',
    longitude: '-90.902709',
    timeZoneId: 'America/Chicago',
  },
  '54767': {
    zip: '54767',
    city: 'Spring Valley',
    state: 'WI',
    latitude: '44.846147',
    longitude: '-92.241815',
    timeZoneId: 'America/Chicago',
  },
  '54768': {
    zip: '54768',
    city: 'Stanley',
    state: 'WI',
    latitude: '44.964604',
    longitude: '-90.935717',
    timeZoneId: 'America/Chicago',
  },
  '54769': {
    zip: '54769',
    city: 'Stockholm',
    state: 'WI',
    latitude: '44.483986',
    longitude: '-92.266896',
    timeZoneId: 'America/Chicago',
  },
  '54770': {
    zip: '54770',
    city: 'Strum',
    state: 'WI',
    latitude: '44.546891',
    longitude: '-91.38904',
    timeZoneId: 'America/Chicago',
  },
  '54771': {
    zip: '54771',
    city: 'Thorp',
    state: 'WI',
    latitude: '44.957639',
    longitude: '-90.80096',
    timeZoneId: 'America/Chicago',
  },
  '54772': {
    zip: '54772',
    city: 'Wheeler',
    state: 'WI',
    latitude: '45.042301',
    longitude: '-91.908178',
    timeZoneId: 'America/Chicago',
  },
  '54773': {
    zip: '54773',
    city: 'Whitehall',
    state: 'WI',
    latitude: '44.367306',
    longitude: '-91.320405',
    timeZoneId: 'America/Chicago',
  },
  '54774': {
    zip: '54774',
    city: 'Chippewa Falls',
    state: 'WI',
    latitude: '44.933706',
    longitude: '-91.39151',
    timeZoneId: 'America/Chicago',
  },
  '54801': {
    zip: '54801',
    city: 'Spooner',
    state: 'WI',
    latitude: '45.892439',
    longitude: '-92.071809',
    timeZoneId: 'America/Chicago',
  },
  '54805': {
    zip: '54805',
    city: 'Almena',
    state: 'WI',
    latitude: '45.414226',
    longitude: '-92.029086',
    timeZoneId: 'America/Chicago',
  },
  '54806': {
    zip: '54806',
    city: 'Ashland',
    state: 'WI',
    latitude: '46.590566',
    longitude: '-90.878699',
    timeZoneId: 'America/Chicago',
  },
  '54810': {
    zip: '54810',
    city: 'Balsam Lake',
    state: 'WI',
    latitude: '45.470233',
    longitude: '-92.457874',
    timeZoneId: 'America/Chicago',
  },
  '54812': {
    zip: '54812',
    city: 'Barron',
    state: 'WI',
    latitude: '45.401861',
    longitude: '-91.872294',
    timeZoneId: 'America/Chicago',
  },
  '54813': {
    zip: '54813',
    city: 'Barronett',
    state: 'WI',
    latitude: '45.673321',
    longitude: '-92.101259',
    timeZoneId: 'America/Chicago',
  },
  '54814': {
    zip: '54814',
    city: 'Bayfield',
    state: 'WI',
    latitude: '46.82436',
    longitude: '-90.83046',
    timeZoneId: 'America/Chicago',
  },
  '54816': {
    zip: '54816',
    city: 'Benoit',
    state: 'WI',
    latitude: '46.503624',
    longitude: '-91.0772',
    timeZoneId: 'America/Chicago',
  },
  '54817': {
    zip: '54817',
    city: 'Birchwood',
    state: 'WI',
    latitude: '45.61963',
    longitude: '-91.595128',
    timeZoneId: 'America/Chicago',
  },
  '54819': {
    zip: '54819',
    city: 'Bruce',
    state: 'WI',
    latitude: '45.458529',
    longitude: '-91.274154',
    timeZoneId: 'America/Chicago',
  },
  '54820': {
    zip: '54820',
    city: 'Brule',
    state: 'WI',
    latitude: '46.536562',
    longitude: '-91.557372',
    timeZoneId: 'America/Chicago',
  },
  '54821': {
    zip: '54821',
    city: 'Cable',
    state: 'WI',
    latitude: '46.225837',
    longitude: '-91.170886',
    timeZoneId: 'America/Chicago',
  },
  '54822': {
    zip: '54822',
    city: 'Cameron',
    state: 'WI',
    latitude: '45.397937',
    longitude: '-91.713596',
    timeZoneId: 'America/Chicago',
  },
  '54824': {
    zip: '54824',
    city: 'Centuria',
    state: 'WI',
    latitude: '45.448916',
    longitude: '-92.546255',
    timeZoneId: 'America/Chicago',
  },
  '54826': {
    zip: '54826',
    city: 'Comstock',
    state: 'WI',
    latitude: '45.486641',
    longitude: '-92.088794',
    timeZoneId: 'America/Chicago',
  },
  '54827': {
    zip: '54827',
    city: 'Cornucopia',
    state: 'WI',
    latitude: '46.853475',
    longitude: '-91.092584',
    timeZoneId: 'America/Chicago',
  },
  '54828': {
    zip: '54828',
    city: 'Couderay',
    state: 'WI',
    latitude: '45.796363',
    longitude: '-91.343774',
    timeZoneId: 'America/Chicago',
  },
  '54829': {
    zip: '54829',
    city: 'Cumberland',
    state: 'WI',
    latitude: '45.535961',
    longitude: '-92.018076',
    timeZoneId: 'America/Chicago',
  },
  '54830': {
    zip: '54830',
    city: 'Danbury',
    state: 'WI',
    latitude: '45.998019',
    longitude: '-92.252388',
    timeZoneId: 'America/Chicago',
  },
  '54832': {
    zip: '54832',
    city: 'Drummond',
    state: 'WI',
    latitude: '46.33419',
    longitude: '-91.33582',
    timeZoneId: 'America/Chicago',
  },
  '54835': {
    zip: '54835',
    city: 'Exeland',
    state: 'WI',
    latitude: '45.676386',
    longitude: '-91.234509',
    timeZoneId: 'America/Chicago',
  },
  '54836': {
    zip: '54836',
    city: 'Foxboro',
    state: 'WI',
    latitude: '46.484657',
    longitude: '-92.187393',
    timeZoneId: 'America/Chicago',
  },
  '54837': {
    zip: '54837',
    city: 'Frederic',
    state: 'WI',
    latitude: '45.703572',
    longitude: '-92.475946',
    timeZoneId: 'America/Chicago',
  },
  '54838': {
    zip: '54838',
    city: 'Gordon',
    state: 'WI',
    latitude: '46.198672',
    longitude: '-91.897453',
    timeZoneId: 'America/Chicago',
  },
  '54839': {
    zip: '54839',
    city: 'Grand View',
    state: 'WI',
    latitude: '46.347438',
    longitude: '-91.10413',
    timeZoneId: 'America/Chicago',
  },
  '54840': {
    zip: '54840',
    city: 'Grantsburg',
    state: 'WI',
    latitude: '45.751715',
    longitude: '-92.652724',
    timeZoneId: 'America/Chicago',
  },
  '54841': {
    zip: '54841',
    city: 'Haugen',
    state: 'WI',
    latitude: '45.611851',
    longitude: '-91.7817',
    timeZoneId: 'America/Chicago',
  },
  '54842': {
    zip: '54842',
    city: 'Hawthorne',
    state: 'WI',
    latitude: '46.503826',
    longitude: '-91.863004',
    timeZoneId: 'America/Chicago',
  },
  '54843': {
    zip: '54843',
    city: 'Hayward',
    state: 'WI',
    latitude: '46.018712',
    longitude: '-91.46296',
    timeZoneId: 'America/Chicago',
  },
  '54844': {
    zip: '54844',
    city: 'Herbster',
    state: 'WI',
    latitude: '46.776022',
    longitude: '-91.22842',
    timeZoneId: 'America/Chicago',
  },
  '54845': {
    zip: '54845',
    city: 'Hertel',
    state: 'WI',
    latitude: '45.805378',
    longitude: '-92.133134',
    timeZoneId: 'America/Chicago',
  },
  '54846': {
    zip: '54846',
    city: 'High Bridge',
    state: 'WI',
    latitude: '46.381203',
    longitude: '-90.737197',
    timeZoneId: 'America/Chicago',
  },
  '54847': {
    zip: '54847',
    city: 'Iron River',
    state: 'WI',
    latitude: '46.562142',
    longitude: '-91.413369',
    timeZoneId: 'America/Chicago',
  },
  '54848': {
    zip: '54848',
    city: 'Ladysmith',
    state: 'WI',
    latitude: '45.465609',
    longitude: '-91.094582',
    timeZoneId: 'America/Chicago',
  },
  '54849': {
    zip: '54849',
    city: 'Lake Nebagamon',
    state: 'WI',
    latitude: '46.491177',
    longitude: '-91.743284',
    timeZoneId: 'America/Chicago',
  },
  '54850': {
    zip: '54850',
    city: 'La Pointe',
    state: 'WI',
    latitude: '46.937248',
    longitude: '-90.579819',
    timeZoneId: 'America/Chicago',
  },
  '54853': {
    zip: '54853',
    city: 'Luck',
    state: 'WI',
    latitude: '45.569924',
    longitude: '-92.473493',
    timeZoneId: 'America/Chicago',
  },
  '54854': {
    zip: '54854',
    city: 'Maple',
    state: 'WI',
    latitude: '46.641739',
    longitude: '-91.676601',
    timeZoneId: 'America/Chicago',
  },
  '54855': {
    zip: '54855',
    city: 'Marengo',
    state: 'WI',
    latitude: '46.356214',
    longitude: '-90.846867',
    timeZoneId: 'America/Chicago',
  },
  '54856': {
    zip: '54856',
    city: 'Mason',
    state: 'WI',
    latitude: '46.43928',
    longitude: '-91.08588',
    timeZoneId: 'America/Chicago',
  },
  '54857': {
    zip: '54857',
    city: 'Mikana',
    state: 'WI',
    latitude: '45.590843',
    longitude: '-91.599726',
    timeZoneId: 'America/Chicago',
  },
  '54858': {
    zip: '54858',
    city: 'Milltown',
    state: 'WI',
    latitude: '45.527734',
    longitude: '-92.506351',
    timeZoneId: 'America/Chicago',
  },
  '54859': {
    zip: '54859',
    city: 'Minong',
    state: 'WI',
    latitude: '46.102673',
    longitude: '-91.850913',
    timeZoneId: 'America/Chicago',
  },
  '54861': {
    zip: '54861',
    city: 'Odanah',
    state: 'WI',
    latitude: '46.56458',
    longitude: '-90.658043',
    timeZoneId: 'America/Chicago',
  },
  '54862': {
    zip: '54862',
    city: 'Ojibwa',
    state: 'WI',
    latitude: '45.803741',
    longitude: '-91.114126',
    timeZoneId: 'America/Chicago',
  },
  '54864': {
    zip: '54864',
    city: 'Poplar',
    state: 'WI',
    latitude: '46.57627',
    longitude: '-91.819109',
    timeZoneId: 'America/Chicago',
  },
  '54865': {
    zip: '54865',
    city: 'Port Wing',
    state: 'WI',
    latitude: '46.730386',
    longitude: '-91.391591',
    timeZoneId: 'America/Chicago',
  },
  '54867': {
    zip: '54867',
    city: 'Radisson',
    state: 'WI',
    latitude: '45.804361',
    longitude: '-91.228172',
    timeZoneId: 'America/Chicago',
  },
  '54868': {
    zip: '54868',
    city: 'Rice Lake',
    state: 'WI',
    latitude: '45.516544',
    longitude: '-91.733836',
    timeZoneId: 'America/Chicago',
  },
  '54870': {
    zip: '54870',
    city: 'Sarona',
    state: 'WI',
    latitude: '45.632426',
    longitude: '-91.723092',
    timeZoneId: 'America/Chicago',
  },
  '54871': {
    zip: '54871',
    city: 'Shell Lake',
    state: 'WI',
    latitude: '45.760737',
    longitude: '-92.105442',
    timeZoneId: 'America/Chicago',
  },
  '54872': {
    zip: '54872',
    city: 'Siren',
    state: 'WI',
    latitude: '45.77631',
    longitude: '-92.384046',
    timeZoneId: 'America/Chicago',
  },
  '54873': {
    zip: '54873',
    city: 'Solon Springs',
    state: 'WI',
    latitude: '46.39914',
    longitude: '-91.74804',
    timeZoneId: 'America/Chicago',
  },
  '54874': {
    zip: '54874',
    city: 'South Range',
    state: 'WI',
    latitude: '46.55856',
    longitude: '-91.92096',
    timeZoneId: 'America/Chicago',
  },
  '54875': {
    zip: '54875',
    city: 'Springbrook',
    state: 'WI',
    latitude: '45.986961',
    longitude: '-91.676053',
    timeZoneId: 'America/Chicago',
  },
  '54876': {
    zip: '54876',
    city: 'Stone Lake',
    state: 'WI',
    latitude: '45.810108',
    longitude: '-91.53002',
    timeZoneId: 'America/Chicago',
  },
  '54880': {
    zip: '54880',
    city: 'Superior',
    state: 'WI',
    latitude: '46.705941',
    longitude: '-92.074691',
    timeZoneId: 'America/Chicago',
  },
  '54888': {
    zip: '54888',
    city: 'Trego',
    state: 'WI',
    latitude: '45.988585',
    longitude: '-91.893391',
    timeZoneId: 'America/Chicago',
  },
  '54889': {
    zip: '54889',
    city: 'Turtle Lake',
    state: 'WI',
    latitude: '45.396821',
    longitude: '-92.143546',
    timeZoneId: 'America/Chicago',
  },
  '54890': {
    zip: '54890',
    city: 'Wascott',
    state: 'WI',
    latitude: '46.175902',
    longitude: '-91.798524',
    timeZoneId: 'America/Chicago',
  },
  '54891': {
    zip: '54891',
    city: 'Washburn',
    state: 'WI',
    latitude: '46.672529',
    longitude: '-90.896655',
    timeZoneId: 'America/Chicago',
  },
  '54893': {
    zip: '54893',
    city: 'Webster',
    state: 'WI',
    latitude: '45.87883',
    longitude: '-92.299184',
    timeZoneId: 'America/Chicago',
  },
  '54895': {
    zip: '54895',
    city: 'Weyerhaeuser',
    state: 'WI',
    latitude: '45.425272',
    longitude: '-91.413452',
    timeZoneId: 'America/Chicago',
  },
  '54896': {
    zip: '54896',
    city: 'Winter',
    state: 'WI',
    latitude: '45.8421',
    longitude: '-90.90762',
    timeZoneId: 'America/Chicago',
  },
  '54901': {
    zip: '54901',
    city: 'Oshkosh',
    state: 'WI',
    latitude: '44.017138',
    longitude: '-88.538364',
    timeZoneId: 'America/Chicago',
  },
  '54902': {
    zip: '54902',
    city: 'Oshkosh',
    state: 'WI',
    latitude: '43.999126',
    longitude: '-88.669619',
    timeZoneId: 'America/Chicago',
  },
  '54903': {
    zip: '54903',
    city: 'Oshkosh',
    state: 'WI',
    latitude: '43.997702',
    longitude: '-88.560337',
    timeZoneId: 'America/Chicago',
  },
  '54904': {
    zip: '54904',
    city: 'Oshkosh',
    state: 'WI',
    latitude: '44.024527',
    longitude: '-88.617096',
    timeZoneId: 'America/Chicago',
  },
  '54906': {
    zip: '54906',
    city: 'Oshkosh',
    state: 'WI',
    latitude: '44.018997',
    longitude: '-88.553426',
    timeZoneId: 'America/Chicago',
  },
  '54909': {
    zip: '54909',
    city: 'Almond',
    state: 'WI',
    latitude: '44.303021',
    longitude: '-89.341236',
    timeZoneId: 'America/Chicago',
  },
  '54911': {
    zip: '54911',
    city: 'Appleton',
    state: 'WI',
    latitude: '44.27522',
    longitude: '-88.395755',
    timeZoneId: 'America/Chicago',
  },
  '54912': {
    zip: '54912',
    city: 'Appleton',
    state: 'WI',
    latitude: '44.266623',
    longitude: '-88.434674',
    timeZoneId: 'America/Chicago',
  },
  '54913': {
    zip: '54913',
    city: 'Appleton',
    state: 'WI',
    latitude: '44.263597',
    longitude: '-88.413736',
    timeZoneId: 'America/Chicago',
  },
  '54914': {
    zip: '54914',
    city: 'Appleton',
    state: 'WI',
    latitude: '44.271885',
    longitude: '-88.437781',
    timeZoneId: 'America/Chicago',
  },
  '54915': {
    zip: '54915',
    city: 'Appleton',
    state: 'WI',
    latitude: '44.282485',
    longitude: '-88.41276',
    timeZoneId: 'America/Chicago',
  },
  '54919': {
    zip: '54919',
    city: 'Appleton',
    state: 'WI',
    latitude: '44.268304',
    longitude: '-88.396324',
    timeZoneId: 'America/Chicago',
  },
  '54921': {
    zip: '54921',
    city: 'Bancroft',
    state: 'WI',
    latitude: '44.304038',
    longitude: '-89.543303',
    timeZoneId: 'America/Chicago',
  },
  '54922': {
    zip: '54922',
    city: 'Bear Creek',
    state: 'WI',
    latitude: '44.53028',
    longitude: '-88.724483',
    timeZoneId: 'America/Chicago',
  },
  '54923': {
    zip: '54923',
    city: 'Berlin',
    state: 'WI',
    latitude: '43.969316',
    longitude: '-88.947033',
    timeZoneId: 'America/Chicago',
  },
  '54926': {
    zip: '54926',
    city: 'Big Falls',
    state: 'WI',
    latitude: '44.622482',
    longitude: '-89.01758',
    timeZoneId: 'America/Chicago',
  },
  '54927': {
    zip: '54927',
    city: 'Butte des Morts',
    state: 'WI',
    latitude: '44.103693',
    longitude: '-88.651664',
    timeZoneId: 'America/Chicago',
  },
  '54928': {
    zip: '54928',
    city: 'Caroline',
    state: 'WI',
    latitude: '44.7524',
    longitude: '-88.897245',
    timeZoneId: 'America/Chicago',
  },
  '54929': {
    zip: '54929',
    city: 'Clintonville',
    state: 'WI',
    latitude: '44.624076',
    longitude: '-88.755627',
    timeZoneId: 'America/Chicago',
  },
  '54930': {
    zip: '54930',
    city: 'Coloma',
    state: 'WI',
    latitude: '44.024459',
    longitude: '-89.636788',
    timeZoneId: 'America/Chicago',
  },
  '54931': {
    zip: '54931',
    city: 'Dale',
    state: 'WI',
    latitude: '44.274314',
    longitude: '-88.67717',
    timeZoneId: 'America/Chicago',
  },
  '54932': {
    zip: '54932',
    city: 'Eldorado',
    state: 'WI',
    latitude: '43.848173',
    longitude: '-88.647961',
    timeZoneId: 'America/Chicago',
  },
  '54933': {
    zip: '54933',
    city: 'Embarrass',
    state: 'WI',
    latitude: '44.667842',
    longitude: '-88.705156',
    timeZoneId: 'America/Chicago',
  },
  '54935': {
    zip: '54935',
    city: 'Fond du Lac',
    state: 'WI',
    latitude: '43.775242',
    longitude: '-88.431561',
    timeZoneId: 'America/Chicago',
  },
  '54936': {
    zip: '54936',
    city: 'Fond du Lac',
    state: 'WI',
    latitude: '43.773509',
    longitude: '-88.447801',
    timeZoneId: 'America/Chicago',
  },
  '54937': {
    zip: '54937',
    city: 'Fond du Lac',
    state: 'WI',
    latitude: '43.808088',
    longitude: '-88.480729',
    timeZoneId: 'America/Chicago',
  },
  '54940': {
    zip: '54940',
    city: 'Fremont',
    state: 'WI',
    latitude: '44.21135',
    longitude: '-88.827485',
    timeZoneId: 'America/Chicago',
  },
  '54941': {
    zip: '54941',
    city: 'Green Lake',
    state: 'WI',
    latitude: '43.839465',
    longitude: '-88.969575',
    timeZoneId: 'America/Chicago',
  },
  '54942': {
    zip: '54942',
    city: 'Greenville',
    state: 'WI',
    latitude: '44.308055',
    longitude: '-88.550927',
    timeZoneId: 'America/Chicago',
  },
  '54943': {
    zip: '54943',
    city: 'Hancock',
    state: 'WI',
    latitude: '44.132261',
    longitude: '-89.692475',
    timeZoneId: 'America/Chicago',
  },
  '54944': {
    zip: '54944',
    city: 'Hortonville',
    state: 'WI',
    latitude: '44.320068',
    longitude: '-88.622498',
    timeZoneId: 'America/Chicago',
  },
  '54945': {
    zip: '54945',
    city: 'Iola',
    state: 'WI',
    latitude: '44.510909',
    longitude: '-89.133958',
    timeZoneId: 'America/Chicago',
  },
  '54946': {
    zip: '54946',
    city: 'King',
    state: 'WI',
    latitude: '44.358509',
    longitude: '-89.119468',
    timeZoneId: 'America/Chicago',
  },
  '54947': {
    zip: '54947',
    city: 'Larsen',
    state: 'WI',
    latitude: '44.195234',
    longitude: '-88.695873',
    timeZoneId: 'America/Chicago',
  },
  '54948': {
    zip: '54948',
    city: 'Leopolis',
    state: 'WI',
    latitude: '44.778897',
    longitude: '-88.888412',
    timeZoneId: 'America/Chicago',
  },
  '54949': {
    zip: '54949',
    city: 'Manawa',
    state: 'WI',
    latitude: '44.465057',
    longitude: '-88.920215',
    timeZoneId: 'America/Chicago',
  },
  '54950': {
    zip: '54950',
    city: 'Marion',
    state: 'WI',
    latitude: '44.669625',
    longitude: '-88.88609',
    timeZoneId: 'America/Chicago',
  },
  '54952': {
    zip: '54952',
    city: 'Menasha',
    state: 'WI',
    latitude: '44.217032',
    longitude: '-88.430451',
    timeZoneId: 'America/Chicago',
  },
  '54956': {
    zip: '54956',
    city: 'Neenah',
    state: 'WI',
    latitude: '44.183094',
    longitude: '-88.487397',
    timeZoneId: 'America/Chicago',
  },
  '54957': {
    zip: '54957',
    city: 'Neenah',
    state: 'WI',
    latitude: '44.183332',
    longitude: '-88.463405',
    timeZoneId: 'America/Chicago',
  },
  '54960': {
    zip: '54960',
    city: 'Neshkoro',
    state: 'WI',
    latitude: '43.936871',
    longitude: '-89.25693',
    timeZoneId: 'America/Chicago',
  },
  '54961': {
    zip: '54961',
    city: 'New London',
    state: 'WI',
    latitude: '44.385363',
    longitude: '-88.748158',
    timeZoneId: 'America/Chicago',
  },
  '54962': {
    zip: '54962',
    city: 'Ogdensburg',
    state: 'WI',
    latitude: '44.489062',
    longitude: '-89.018982',
    timeZoneId: 'America/Chicago',
  },
  '54963': {
    zip: '54963',
    city: 'Omro',
    state: 'WI',
    latitude: '44.044243',
    longitude: '-88.759053',
    timeZoneId: 'America/Chicago',
  },
  '54964': {
    zip: '54964',
    city: 'Pickett',
    state: 'WI',
    latitude: '43.929257',
    longitude: '-88.713331',
    timeZoneId: 'America/Chicago',
  },
  '54965': {
    zip: '54965',
    city: 'Pine River',
    state: 'WI',
    latitude: '44.174648',
    longitude: '-89.085052',
    timeZoneId: 'America/Chicago',
  },
  '54966': {
    zip: '54966',
    city: 'Plainfield',
    state: 'WI',
    latitude: '44.214264',
    longitude: '-89.498003',
    timeZoneId: 'America/Chicago',
  },
  '54967': {
    zip: '54967',
    city: 'Poy Sippi',
    state: 'WI',
    latitude: '44.121602',
    longitude: '-88.924124',
    timeZoneId: 'America/Chicago',
  },
  '54968': {
    zip: '54968',
    city: 'Princeton',
    state: 'WI',
    latitude: '43.850369',
    longitude: '-89.128752',
    timeZoneId: 'America/Chicago',
  },
  '54969': {
    zip: '54969',
    city: 'Readfield',
    state: 'WI',
    latitude: '44.27353',
    longitude: '-88.767867',
    timeZoneId: 'America/Chicago',
  },
  '54970': {
    zip: '54970',
    city: 'Redgranite',
    state: 'WI',
    latitude: '44.0484',
    longitude: '-89.08494',
    timeZoneId: 'America/Chicago',
  },
  '54971': {
    zip: '54971',
    city: 'Ripon',
    state: 'WI',
    latitude: '43.848219',
    longitude: '-88.841595',
    timeZoneId: 'America/Chicago',
  },
  '54974': {
    zip: '54974',
    city: 'Rosendale',
    state: 'WI',
    latitude: '43.810083',
    longitude: '-88.671512',
    timeZoneId: 'America/Chicago',
  },
  '54976': {
    zip: '54976',
    city: 'Saxeville',
    state: 'WI',
    latitude: '44.175303',
    longitude: '-89.114101',
    timeZoneId: 'America/Chicago',
  },
  '54977': {
    zip: '54977',
    city: 'Scandinavia',
    state: 'WI',
    latitude: '44.463678',
    longitude: '-89.148098',
    timeZoneId: 'America/Chicago',
  },
  '54978': {
    zip: '54978',
    city: 'Tilleda',
    state: 'WI',
    latitude: '44.782821',
    longitude: '-88.897067',
    timeZoneId: 'America/Chicago',
  },
  '54979': {
    zip: '54979',
    city: 'Van Dyne',
    state: 'WI',
    latitude: '43.898676',
    longitude: '-88.550846',
    timeZoneId: 'America/Chicago',
  },
  '54980': {
    zip: '54980',
    city: 'Waukau',
    state: 'WI',
    latitude: '43.990733',
    longitude: '-88.769713',
    timeZoneId: 'America/Chicago',
  },
  '54981': {
    zip: '54981',
    city: 'Waupaca',
    state: 'WI',
    latitude: '44.354239',
    longitude: '-89.084923',
    timeZoneId: 'America/Chicago',
  },
  '54982': {
    zip: '54982',
    city: 'Wautoma',
    state: 'WI',
    latitude: '44.069079',
    longitude: '-89.290782',
    timeZoneId: 'America/Chicago',
  },
  '54983': {
    zip: '54983',
    city: 'Weyauwega',
    state: 'WI',
    latitude: '44.322182',
    longitude: '-88.930339',
    timeZoneId: 'America/Chicago',
  },
  '54984': {
    zip: '54984',
    city: 'Wild Rose',
    state: 'WI',
    latitude: '44.16867',
    longitude: '-89.214247',
    timeZoneId: 'America/Chicago',
  },
  '54985': {
    zip: '54985',
    city: 'Winnebago',
    state: 'WI',
    latitude: '44.077792',
    longitude: '-88.524229',
    timeZoneId: 'America/Chicago',
  },
  '54986': {
    zip: '54986',
    city: 'Winneconne',
    state: 'WI',
    latitude: '44.118546',
    longitude: '-88.735465',
    timeZoneId: 'America/Chicago',
  },
  '54990': {
    zip: '54990',
    city: 'Iola',
    state: 'WI',
    latitude: '44.507553',
    longitude: '-89.128307',
    timeZoneId: 'America/Chicago',
  },
  '55001': {
    zip: '55001',
    city: 'Afton',
    state: 'MN',
    latitude: '44.904954',
    longitude: '-92.816718',
    timeZoneId: 'America/Chicago',
  },
  '55003': {
    zip: '55003',
    city: 'Bayport',
    state: 'MN',
    latitude: '45.01476',
    longitude: '-92.78148',
    timeZoneId: 'America/Chicago',
  },
  '55005': {
    zip: '55005',
    city: 'Bethel',
    state: 'MN',
    latitude: '45.39264',
    longitude: '-93.2118',
    timeZoneId: 'America/Chicago',
  },
  '55006': {
    zip: '55006',
    city: 'Braham',
    state: 'MN',
    latitude: '45.724622',
    longitude: '-93.172881',
    timeZoneId: 'America/Chicago',
  },
  '55007': {
    zip: '55007',
    city: 'Brook Park',
    state: 'MN',
    latitude: '45.946447',
    longitude: '-92.864586',
    timeZoneId: 'America/Chicago',
  },
  '55008': {
    zip: '55008',
    city: 'Cambridge',
    state: 'MN',
    latitude: '45.5624',
    longitude: '-93.228686',
    timeZoneId: 'America/Chicago',
  },
  '55009': {
    zip: '55009',
    city: 'Cannon Falls',
    state: 'MN',
    latitude: '44.507743',
    longitude: '-92.905112',
    timeZoneId: 'America/Chicago',
  },
  '55010': {
    zip: '55010',
    city: 'Castle Rock',
    state: 'MN',
    latitude: '44.572845',
    longitude: '-93.124072',
    timeZoneId: 'America/Chicago',
  },
  '55011': {
    zip: '55011',
    city: 'Cedar',
    state: 'MN',
    latitude: '45.348975',
    longitude: '-93.246677',
    timeZoneId: 'America/Chicago',
  },
  '55012': {
    zip: '55012',
    city: 'Center City',
    state: 'MN',
    latitude: '45.396474',
    longitude: '-92.816791',
    timeZoneId: 'America/Chicago',
  },
  '55013': {
    zip: '55013',
    city: 'Chisago City',
    state: 'MN',
    latitude: '45.374138',
    longitude: '-92.89268',
    timeZoneId: 'America/Chicago',
  },
  '55014': {
    zip: '55014',
    city: 'Circle Pines',
    state: 'MN',
    latitude: '45.17976',
    longitude: '-93.13224',
    timeZoneId: 'America/Chicago',
  },
  '55016': {
    zip: '55016',
    city: 'Cottage Grove',
    state: 'MN',
    latitude: '44.840257',
    longitude: '-92.894321',
    timeZoneId: 'America/Chicago',
  },
  '55017': {
    zip: '55017',
    city: 'Dalbo',
    state: 'MN',
    latitude: '45.664916',
    longitude: '-93.403636',
    timeZoneId: 'America/Chicago',
  },
  '55018': {
    zip: '55018',
    city: 'Dennison',
    state: 'MN',
    latitude: '44.40714',
    longitude: '-93.01848',
    timeZoneId: 'America/Chicago',
  },
  '55019': {
    zip: '55019',
    city: 'Dundas',
    state: 'MN',
    latitude: '44.41479',
    longitude: '-93.241114',
    timeZoneId: 'America/Chicago',
  },
  '55020': {
    zip: '55020',
    city: 'Elko New Market',
    state: 'MN',
    latitude: '44.567439',
    longitude: '-93.328186',
    timeZoneId: 'America/Chicago',
  },
  '55021': {
    zip: '55021',
    city: 'Faribault',
    state: 'MN',
    latitude: '44.294178',
    longitude: '-93.276667',
    timeZoneId: 'America/Chicago',
  },
  '55024': {
    zip: '55024',
    city: 'Farmington',
    state: 'MN',
    latitude: '44.638608',
    longitude: '-93.149008',
    timeZoneId: 'America/Chicago',
  },
  '55025': {
    zip: '55025',
    city: 'Forest Lake',
    state: 'MN',
    latitude: '45.277195',
    longitude: '-92.990033',
    timeZoneId: 'America/Chicago',
  },
  '55026': {
    zip: '55026',
    city: 'Frontenac',
    state: 'MN',
    latitude: '44.498086',
    longitude: '-92.359643',
    timeZoneId: 'America/Chicago',
  },
  '55027': {
    zip: '55027',
    city: 'Goodhue',
    state: 'MN',
    latitude: '44.407008',
    longitude: '-92.585148',
    timeZoneId: 'America/Chicago',
  },
  '55029': {
    zip: '55029',
    city: 'Grandy',
    state: 'MN',
    latitude: '45.563895',
    longitude: '-93.341851',
    timeZoneId: 'America/Chicago',
  },
  '55030': {
    zip: '55030',
    city: 'Grasston',
    state: 'MN',
    latitude: '45.858693',
    longitude: '-93.055173',
    timeZoneId: 'America/Chicago',
  },
  '55031': {
    zip: '55031',
    city: 'Hampton',
    state: 'MN',
    latitude: '44.606486',
    longitude: '-93.000228',
    timeZoneId: 'America/Chicago',
  },
  '55032': {
    zip: '55032',
    city: 'Harris',
    state: 'MN',
    latitude: '45.590923',
    longitude: '-92.982345',
    timeZoneId: 'America/Chicago',
  },
  '55033': {
    zip: '55033',
    city: 'Hastings',
    state: 'MN',
    latitude: '44.732642',
    longitude: '-92.850259',
    timeZoneId: 'America/Chicago',
  },
  '55036': {
    zip: '55036',
    city: 'Henriette',
    state: 'MN',
    latitude: '45.839035',
    longitude: '-92.958857',
    timeZoneId: 'America/Chicago',
  },
  '55037': {
    zip: '55037',
    city: 'Hinckley',
    state: 'MN',
    latitude: '46.012922',
    longitude: '-92.942087',
    timeZoneId: 'America/Chicago',
  },
  '55038': {
    zip: '55038',
    city: 'Hugo',
    state: 'MN',
    latitude: '45.16358',
    longitude: '-92.95643',
    timeZoneId: 'America/Chicago',
  },
  '55040': {
    zip: '55040',
    city: 'Isanti',
    state: 'MN',
    latitude: '45.492258',
    longitude: '-93.250696',
    timeZoneId: 'America/Chicago',
  },
  '55041': {
    zip: '55041',
    city: 'Lake City',
    state: 'MN',
    latitude: '44.444479',
    longitude: '-92.269817',
    timeZoneId: 'America/Chicago',
  },
  '55042': {
    zip: '55042',
    city: 'Lake Elmo',
    state: 'MN',
    latitude: '44.993774',
    longitude: '-92.907951',
    timeZoneId: 'America/Chicago',
  },
  '55043': {
    zip: '55043',
    city: 'Lakeland',
    state: 'MN',
    latitude: '44.952548',
    longitude: '-92.771548',
    timeZoneId: 'America/Chicago',
  },
  '55044': {
    zip: '55044',
    city: 'Lakeville',
    state: 'MN',
    latitude: '44.652317',
    longitude: '-93.247168',
    timeZoneId: 'America/Chicago',
  },
  '55045': {
    zip: '55045',
    city: 'Lindstrom',
    state: 'MN',
    latitude: '45.388851',
    longitude: '-92.838679',
    timeZoneId: 'America/Chicago',
  },
  '55046': {
    zip: '55046',
    city: 'Lonsdale',
    state: 'MN',
    latitude: '44.4456',
    longitude: '-93.41328',
    timeZoneId: 'America/Chicago',
  },
  '55047': {
    zip: '55047',
    city: 'Marine on Saint Croix',
    state: 'MN',
    latitude: '45.20286',
    longitude: '-92.82528',
    timeZoneId: 'America/Chicago',
  },
  '55049': {
    zip: '55049',
    city: 'Medford',
    state: 'MN',
    latitude: '44.171579',
    longitude: '-93.246887',
    timeZoneId: 'America/Chicago',
  },
  '55051': {
    zip: '55051',
    city: 'Mora',
    state: 'MN',
    latitude: '45.915556',
    longitude: '-93.173056',
    timeZoneId: 'America/Chicago',
  },
  '55052': {
    zip: '55052',
    city: 'Morristown',
    state: 'MN',
    latitude: '44.231894',
    longitude: '-93.437267',
    timeZoneId: 'America/Chicago',
  },
  '55053': {
    zip: '55053',
    city: 'Nerstrand',
    state: 'MN',
    latitude: '44.346698',
    longitude: '-93.090205',
    timeZoneId: 'America/Chicago',
  },
  '55054': {
    zip: '55054',
    city: 'Elko New Market',
    state: 'MN',
    latitude: '44.571168',
    longitude: '-93.354431',
    timeZoneId: 'America/Chicago',
  },
  '55055': {
    zip: '55055',
    city: 'Newport',
    state: 'MN',
    latitude: '44.872086',
    longitude: '-92.997031',
    timeZoneId: 'America/Chicago',
  },
  '55056': {
    zip: '55056',
    city: 'North Branch',
    state: 'MN',
    latitude: '45.50448',
    longitude: '-92.8779',
    timeZoneId: 'America/Chicago',
  },
  '55057': {
    zip: '55057',
    city: 'Northfield',
    state: 'MN',
    latitude: '44.445033',
    longitude: '-93.181524',
    timeZoneId: 'America/Chicago',
  },
  '55060': {
    zip: '55060',
    city: 'Owatonna',
    state: 'MN',
    latitude: '44.087587',
    longitude: '-93.221024',
    timeZoneId: 'America/Chicago',
  },
  '55063': {
    zip: '55063',
    city: 'Pine City',
    state: 'MN',
    latitude: '45.910278',
    longitude: '-92.955',
    timeZoneId: 'America/Chicago',
  },
  '55065': {
    zip: '55065',
    city: 'Randolph',
    state: 'MN',
    latitude: '44.524884',
    longitude: '-93.017991',
    timeZoneId: 'America/Chicago',
  },
  '55066': {
    zip: '55066',
    city: 'Red Wing',
    state: 'MN',
    latitude: '44.557341',
    longitude: '-92.547612',
    timeZoneId: 'America/Chicago',
  },
  '55067': {
    zip: '55067',
    city: 'Rock Creek',
    state: 'MN',
    latitude: '45.7623',
    longitude: '-92.9262',
    timeZoneId: 'America/Chicago',
  },
  '55068': {
    zip: '55068',
    city: 'Rosemount',
    state: 'MN',
    latitude: '44.746607',
    longitude: '-93.077394',
    timeZoneId: 'America/Chicago',
  },
  '55069': {
    zip: '55069',
    city: 'Rush City',
    state: 'MN',
    latitude: '45.68441',
    longitude: '-92.967539',
    timeZoneId: 'America/Chicago',
  },
  '55070': {
    zip: '55070',
    city: 'Saint Francis',
    state: 'MN',
    latitude: '45.394713',
    longitude: '-93.363178',
    timeZoneId: 'America/Chicago',
  },
  '55071': {
    zip: '55071',
    city: 'Saint Paul Park',
    state: 'MN',
    latitude: '44.826',
    longitude: '-92.9901',
    timeZoneId: 'America/Chicago',
  },
  '55072': {
    zip: '55072',
    city: 'Sandstone',
    state: 'MN',
    latitude: '46.126511',
    longitude: '-92.866999',
    timeZoneId: 'America/Chicago',
  },
  '55073': {
    zip: '55073',
    city: 'Scandia',
    state: 'MN',
    latitude: '45.2719',
    longitude: '-92.83186',
    timeZoneId: 'America/Chicago',
  },
  '55074': {
    zip: '55074',
    city: 'Shafer',
    state: 'MN',
    latitude: '45.387412',
    longitude: '-92.746332',
    timeZoneId: 'America/Chicago',
  },
  '55075': {
    zip: '55075',
    city: 'South Saint Paul',
    state: 'MN',
    latitude: '44.892636',
    longitude: '-93.040178',
    timeZoneId: 'America/Chicago',
  },
  '55076': {
    zip: '55076',
    city: 'Inver Grove Heights',
    state: 'MN',
    latitude: '44.849779',
    longitude: '-93.034529',
    timeZoneId: 'America/Chicago',
  },
  '55077': {
    zip: '55077',
    city: 'Inver Grove Heights',
    state: 'MN',
    latitude: '44.839723',
    longitude: '-93.07693',
    timeZoneId: 'America/Chicago',
  },
  '55078': {
    zip: '55078',
    city: 'Stacy',
    state: 'MN',
    latitude: '45.39751',
    longitude: '-92.989336',
    timeZoneId: 'America/Chicago',
  },
  '55079': {
    zip: '55079',
    city: 'Stacy',
    state: 'MN',
    latitude: '45.397384',
    longitude: '-92.987057',
    timeZoneId: 'America/Chicago',
  },
  '55080': {
    zip: '55080',
    city: 'Stanchfield',
    state: 'MN',
    latitude: '45.663805',
    longitude: '-93.233436',
    timeZoneId: 'America/Chicago',
  },
  '55082': {
    zip: '55082',
    city: 'Stillwater',
    state: 'MN',
    latitude: '45.05262',
    longitude: '-92.8464',
    timeZoneId: 'America/Chicago',
  },
  '55083': {
    zip: '55083',
    city: 'Stillwater',
    state: 'MN',
    latitude: '45.058398',
    longitude: '-92.825832',
    timeZoneId: 'America/Chicago',
  },
  '55084': {
    zip: '55084',
    city: 'Taylors Falls',
    state: 'MN',
    latitude: '45.411517',
    longitude: '-92.664152',
    timeZoneId: 'America/Chicago',
  },
  '55085': {
    zip: '55085',
    city: 'Vermillion',
    state: 'MN',
    latitude: '44.672835',
    longitude: '-92.966482',
    timeZoneId: 'America/Chicago',
  },
  '55087': {
    zip: '55087',
    city: 'Warsaw',
    state: 'MN',
    latitude: '44.24197',
    longitude: '-93.399857',
    timeZoneId: 'America/Chicago',
  },
  '55088': {
    zip: '55088',
    city: 'Webster',
    state: 'MN',
    latitude: '44.518717',
    longitude: '-93.365425',
    timeZoneId: 'America/Chicago',
  },
  '55089': {
    zip: '55089',
    city: 'Welch',
    state: 'MN',
    latitude: '44.619771',
    longitude: '-92.65169',
    timeZoneId: 'America/Chicago',
  },
  '55090': {
    zip: '55090',
    city: 'Willernie',
    state: 'MN',
    latitude: '45.052511',
    longitude: '-92.957501',
    timeZoneId: 'America/Chicago',
  },
  '55092': {
    zip: '55092',
    city: 'Wyoming',
    state: 'MN',
    latitude: '45.32964',
    longitude: '-93.0747',
    timeZoneId: 'America/Chicago',
  },
  '55101': {
    zip: '55101',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.972055',
    longitude: '-93.082709',
    timeZoneId: 'America/Chicago',
  },
  '55102': {
    zip: '55102',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.9306',
    longitude: '-93.122103',
    timeZoneId: 'America/Chicago',
  },
  '55103': {
    zip: '55103',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.96303',
    longitude: '-93.125559',
    timeZoneId: 'America/Chicago',
  },
  '55104': {
    zip: '55104',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.955483',
    longitude: '-93.160531',
    timeZoneId: 'America/Chicago',
  },
  '55105': {
    zip: '55105',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.936784',
    longitude: '-93.164881',
    timeZoneId: 'America/Chicago',
  },
  '55106': {
    zip: '55106',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.957208',
    longitude: '-93.052916',
    timeZoneId: 'America/Chicago',
  },
  '55107': {
    zip: '55107',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.932638',
    longitude: '-93.077152',
    timeZoneId: 'America/Chicago',
  },
  '55108': {
    zip: '55108',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.982543',
    longitude: '-93.179571',
    timeZoneId: 'America/Chicago',
  },
  '55109': {
    zip: '55109',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.013271',
    longitude: '-93.029187',
    timeZoneId: 'America/Chicago',
  },
  '55110': {
    zip: '55110',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.087946',
    longitude: '-93.005825',
    timeZoneId: 'America/Chicago',
  },
  '55111': {
    zip: '55111',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.880467',
    longitude: '-93.196044',
    timeZoneId: 'America/Chicago',
  },
  '55112': {
    zip: '55112',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.07938',
    longitude: '-93.1872',
    timeZoneId: 'America/Chicago',
  },
  '55113': {
    zip: '55113',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.9949',
    longitude: '-93.1812',
    timeZoneId: 'America/Chicago',
  },
  '55114': {
    zip: '55114',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.964378',
    longitude: '-93.195095',
    timeZoneId: 'America/Chicago',
  },
  '55115': {
    zip: '55115',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.0702',
    longitude: '-92.9529',
    timeZoneId: 'America/Chicago',
  },
  '55116': {
    zip: '55116',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.913706',
    longitude: '-93.174993',
    timeZoneId: 'America/Chicago',
  },
  '55117': {
    zip: '55117',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.000144',
    longitude: '-93.083067',
    timeZoneId: 'America/Chicago',
  },
  '55118': {
    zip: '55118',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.8935',
    longitude: '-93.10836',
    timeZoneId: 'America/Chicago',
  },
  '55119': {
    zip: '55119',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.938568',
    longitude: '-93.002788',
    timeZoneId: 'America/Chicago',
  },
  '55120': {
    zip: '55120',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.87382',
    longitude: '-93.1407',
    timeZoneId: 'America/Chicago',
  },
  '55121': {
    zip: '55121',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.848185',
    longitude: '-93.145649',
    timeZoneId: 'America/Chicago',
  },
  '55122': {
    zip: '55122',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.801661',
    longitude: '-93.199294',
    timeZoneId: 'America/Chicago',
  },
  '55123': {
    zip: '55123',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.801532',
    longitude: '-93.141757',
    timeZoneId: 'America/Chicago',
  },
  '55124': {
    zip: '55124',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.74662',
    longitude: '-93.20184',
    timeZoneId: 'America/Chicago',
  },
  '55125': {
    zip: '55125',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.90532',
    longitude: '-92.92326',
    timeZoneId: 'America/Chicago',
  },
  '55126': {
    zip: '55126',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.085314',
    longitude: '-93.134715',
    timeZoneId: 'America/Chicago',
  },
  '55127': {
    zip: '55127',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.07998',
    longitude: '-93.08712',
    timeZoneId: 'America/Chicago',
  },
  '55128': {
    zip: '55128',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '45.00726',
    longitude: '-92.96412',
    timeZoneId: 'America/Chicago',
  },
  '55129': {
    zip: '55129',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.88963',
    longitude: '-92.91884',
    timeZoneId: 'America/Chicago',
  },
  '55130': {
    zip: '55130',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.944207',
    longitude: '-93.005941',
    timeZoneId: 'America/Chicago',
  },
  '55131': {
    zip: '55131',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.944207',
    longitude: '-93.005941',
    timeZoneId: 'America/Chicago',
  },
  '55133': {
    zip: '55133',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.94452',
    longitude: '-93.005804',
    timeZoneId: 'America/Chicago',
  },
  '55144': {
    zip: '55144',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.925118',
    longitude: '-93.048795',
    timeZoneId: 'America/Chicago',
  },
  '55145': {
    zip: '55145',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.945326',
    longitude: '-93.094113',
    timeZoneId: 'America/Chicago',
  },
  '55146': {
    zip: '55146',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.941944',
    longitude: '-93.084462',
    timeZoneId: 'America/Chicago',
  },
  '55150': {
    zip: '55150',
    city: 'Mendota',
    state: 'MN',
    latitude: '44.886247',
    longitude: '-93.161578',
    timeZoneId: 'America/Chicago',
  },
  '55155': {
    zip: '55155',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.962818',
    longitude: '-93.123683',
    timeZoneId: 'America/Chicago',
  },
  '55164': {
    zip: '55164',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.966267',
    longitude: '-93.084445',
    timeZoneId: 'America/Chicago',
  },
  '55165': {
    zip: '55165',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.965239',
    longitude: '-93.085789',
    timeZoneId: 'America/Chicago',
  },
  '55170': {
    zip: '55170',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.944443',
    longitude: '-93.092404',
    timeZoneId: 'America/Chicago',
  },
  '55175': {
    zip: '55175',
    city: 'Saint Paul',
    state: 'MN',
    latitude: '44.96653',
    longitude: '-93.087695',
    timeZoneId: 'America/Chicago',
  },
  '55301': {
    zip: '55301',
    city: 'Albertville',
    state: 'MN',
    latitude: '45.239613',
    longitude: '-93.654947',
    timeZoneId: 'America/Chicago',
  },
  '55302': {
    zip: '55302',
    city: 'Annandale',
    state: 'MN',
    latitude: '45.26375',
    longitude: '-94.12417',
    timeZoneId: 'America/Chicago',
  },
  '55303': {
    zip: '55303',
    city: 'Anoka',
    state: 'MN',
    latitude: '45.261223',
    longitude: '-93.440539',
    timeZoneId: 'America/Chicago',
  },
  '55304': {
    zip: '55304',
    city: 'Andover',
    state: 'MN',
    latitude: '45.2547',
    longitude: '-93.24048',
    timeZoneId: 'America/Chicago',
  },
  '55305': {
    zip: '55305',
    city: 'Hopkins',
    state: 'MN',
    latitude: '44.95248',
    longitude: '-93.42666',
    timeZoneId: 'America/Chicago',
  },
  '55306': {
    zip: '55306',
    city: 'Burnsville',
    state: 'MN',
    latitude: '44.733364',
    longitude: '-93.289531',
    timeZoneId: 'America/Chicago',
  },
  '55307': {
    zip: '55307',
    city: 'Arlington',
    state: 'MN',
    latitude: '44.604877',
    longitude: '-94.078182',
    timeZoneId: 'America/Chicago',
  },
  '55308': {
    zip: '55308',
    city: 'Becker',
    state: 'MN',
    latitude: '45.411381',
    longitude: '-93.841945',
    timeZoneId: 'America/Chicago',
  },
  '55309': {
    zip: '55309',
    city: 'Big Lake',
    state: 'MN',
    latitude: '45.332043',
    longitude: '-93.745422',
    timeZoneId: 'America/Chicago',
  },
  '55310': {
    zip: '55310',
    city: 'Bird Island',
    state: 'MN',
    latitude: '44.764029',
    longitude: '-94.89619',
    timeZoneId: 'America/Chicago',
  },
  '55311': {
    zip: '55311',
    city: 'Maple Grove',
    state: 'MN',
    latitude: '45.1188',
    longitude: '-93.48666',
    timeZoneId: 'America/Chicago',
  },
  '55312': {
    zip: '55312',
    city: 'Brownton',
    state: 'MN',
    latitude: '44.732043',
    longitude: '-94.35105',
    timeZoneId: 'America/Chicago',
  },
  '55313': {
    zip: '55313',
    city: 'Buffalo',
    state: 'MN',
    latitude: '45.17524',
    longitude: '-93.873386',
    timeZoneId: 'America/Chicago',
  },
  '55314': {
    zip: '55314',
    city: 'Buffalo Lake',
    state: 'MN',
    latitude: '44.737743',
    longitude: '-94.625994',
    timeZoneId: 'America/Chicago',
  },
  '55315': {
    zip: '55315',
    city: 'Carver',
    state: 'MN',
    latitude: '44.716995',
    longitude: '-93.687641',
    timeZoneId: 'America/Chicago',
  },
  '55316': {
    zip: '55316',
    city: 'Champlin',
    state: 'MN',
    latitude: '45.174742',
    longitude: '-93.390657',
    timeZoneId: 'America/Chicago',
  },
  '55317': {
    zip: '55317',
    city: 'Chanhassen',
    state: 'MN',
    latitude: '44.855238',
    longitude: '-93.551193',
    timeZoneId: 'America/Chicago',
  },
  '55318': {
    zip: '55318',
    city: 'Chaska',
    state: 'MN',
    latitude: '44.811514',
    longitude: '-93.610998',
    timeZoneId: 'America/Chicago',
  },
  '55319': {
    zip: '55319',
    city: 'Clear Lake',
    state: 'MN',
    latitude: '45.449652',
    longitude: '-93.997845',
    timeZoneId: 'America/Chicago',
  },
  '55320': {
    zip: '55320',
    city: 'Clearwater',
    state: 'MN',
    latitude: '45.413142',
    longitude: '-94.11949',
    timeZoneId: 'America/Chicago',
  },
  '55321': {
    zip: '55321',
    city: 'Cokato',
    state: 'MN',
    latitude: '45.077418',
    longitude: '-94.190139',
    timeZoneId: 'America/Chicago',
  },
  '55322': {
    zip: '55322',
    city: 'Cologne',
    state: 'MN',
    latitude: '44.771368',
    longitude: '-93.787282',
    timeZoneId: 'America/Chicago',
  },
  '55323': {
    zip: '55323',
    city: 'Crystal Bay',
    state: 'MN',
    latitude: '44.954569',
    longitude: '-93.57638',
    timeZoneId: 'America/Chicago',
  },
  '55324': {
    zip: '55324',
    city: 'Darwin',
    state: 'MN',
    latitude: '45.108352',
    longitude: '-94.438355',
    timeZoneId: 'America/Chicago',
  },
  '55325': {
    zip: '55325',
    city: 'Dassel',
    state: 'MN',
    latitude: '45.09294',
    longitude: '-94.31586',
    timeZoneId: 'America/Chicago',
  },
  '55327': {
    zip: '55327',
    city: 'Dayton',
    state: 'MN',
    latitude: '45.202456',
    longitude: '-93.46879',
    timeZoneId: 'America/Chicago',
  },
  '55328': {
    zip: '55328',
    city: 'Delano',
    state: 'MN',
    latitude: '45.036101',
    longitude: '-93.81658',
    timeZoneId: 'America/Chicago',
  },
  '55329': {
    zip: '55329',
    city: 'Eden Valley',
    state: 'MN',
    latitude: '45.344424',
    longitude: '-94.562439',
    timeZoneId: 'America/Chicago',
  },
  '55330': {
    zip: '55330',
    city: 'Elk River',
    state: 'MN',
    latitude: '45.3192',
    longitude: '-93.54528',
    timeZoneId: 'America/Chicago',
  },
  '55331': {
    zip: '55331',
    city: 'Excelsior',
    state: 'MN',
    latitude: '44.90388',
    longitude: '-93.573146',
    timeZoneId: 'America/Chicago',
  },
  '55332': {
    zip: '55332',
    city: 'Fairfax',
    state: 'MN',
    latitude: '44.544084',
    longitude: '-94.746915',
    timeZoneId: 'America/Chicago',
  },
  '55333': {
    zip: '55333',
    city: 'Franklin',
    state: 'MN',
    latitude: '44.526881',
    longitude: '-94.883318',
    timeZoneId: 'America/Chicago',
  },
  '55334': {
    zip: '55334',
    city: 'Gaylord',
    state: 'MN',
    latitude: '44.554134',
    longitude: '-94.216762',
    timeZoneId: 'America/Chicago',
  },
  '55335': {
    zip: '55335',
    city: 'Gibbon',
    state: 'MN',
    latitude: '44.528499',
    longitude: '-94.527041',
    timeZoneId: 'America/Chicago',
  },
  '55336': {
    zip: '55336',
    city: 'Glencoe',
    state: 'MN',
    latitude: '44.771232',
    longitude: '-94.149805',
    timeZoneId: 'America/Chicago',
  },
  '55337': {
    zip: '55337',
    city: 'Burnsville',
    state: 'MN',
    latitude: '44.770175',
    longitude: '-93.281177',
    timeZoneId: 'America/Chicago',
  },
  '55338': {
    zip: '55338',
    city: 'Green Isle',
    state: 'MN',
    latitude: '44.647904',
    longitude: '-93.903079',
    timeZoneId: 'America/Chicago',
  },
  '55339': {
    zip: '55339',
    city: 'Hamburg',
    state: 'MN',
    latitude: '44.733143',
    longitude: '-93.968679',
    timeZoneId: 'America/Chicago',
  },
  '55340': {
    zip: '55340',
    city: 'Hamel',
    state: 'MN',
    latitude: '45.072108',
    longitude: '-93.576508',
    timeZoneId: 'America/Chicago',
  },
  '55341': {
    zip: '55341',
    city: 'Hanover',
    state: 'MN',
    latitude: '45.161338',
    longitude: '-93.655523',
    timeZoneId: 'America/Chicago',
  },
  '55342': {
    zip: '55342',
    city: 'Hector',
    state: 'MN',
    latitude: '44.742203',
    longitude: '-94.718005',
    timeZoneId: 'America/Chicago',
  },
  '55343': {
    zip: '55343',
    city: 'Hopkins',
    state: 'MN',
    latitude: '44.923136',
    longitude: '-93.438889',
    timeZoneId: 'America/Chicago',
  },
  '55344': {
    zip: '55344',
    city: 'Eden Prairie',
    state: 'MN',
    latitude: '44.86172',
    longitude: '-93.448107',
    timeZoneId: 'America/Chicago',
  },
  '55345': {
    zip: '55345',
    city: 'Minnetonka',
    state: 'MN',
    latitude: '44.921543',
    longitude: '-93.482675',
    timeZoneId: 'America/Chicago',
  },
  '55346': {
    zip: '55346',
    city: 'Eden Prairie',
    state: 'MN',
    latitude: '44.881077',
    longitude: '-93.482453',
    timeZoneId: 'America/Chicago',
  },
  '55347': {
    zip: '55347',
    city: 'Eden Prairie',
    state: 'MN',
    latitude: '44.849847',
    longitude: '-93.487285',
    timeZoneId: 'America/Chicago',
  },
  '55349': {
    zip: '55349',
    city: 'Howard Lake',
    state: 'MN',
    latitude: '45.056504',
    longitude: '-94.066174',
    timeZoneId: 'America/Chicago',
  },
  '55350': {
    zip: '55350',
    city: 'Hutchinson',
    state: 'MN',
    latitude: '44.890688',
    longitude: '-94.37023',
    timeZoneId: 'America/Chicago',
  },
  '55352': {
    zip: '55352',
    city: 'Jordan',
    state: 'MN',
    latitude: '44.672093',
    longitude: '-93.633196',
    timeZoneId: 'America/Chicago',
  },
  '55353': {
    zip: '55353',
    city: 'Kimball',
    state: 'MN',
    latitude: '45.359002',
    longitude: '-94.296954',
    timeZoneId: 'America/Chicago',
  },
  '55354': {
    zip: '55354',
    city: 'Lester Prairie',
    state: 'MN',
    latitude: '44.883546',
    longitude: '-94.040436',
    timeZoneId: 'America/Chicago',
  },
  '55355': {
    zip: '55355',
    city: 'Litchfield',
    state: 'MN',
    latitude: '45.126741',
    longitude: '-94.52701',
    timeZoneId: 'America/Chicago',
  },
  '55356': {
    zip: '55356',
    city: 'Long Lake',
    state: 'MN',
    latitude: '44.987459',
    longitude: '-93.581675',
    timeZoneId: 'America/Chicago',
  },
  '55357': {
    zip: '55357',
    city: 'Loretto',
    state: 'MN',
    latitude: '45.100321',
    longitude: '-93.661093',
    timeZoneId: 'America/Chicago',
  },
  '55358': {
    zip: '55358',
    city: 'Maple Lake',
    state: 'MN',
    latitude: '45.233674',
    longitude: '-94.004626',
    timeZoneId: 'America/Chicago',
  },
  '55359': {
    zip: '55359',
    city: 'Maple Plain',
    state: 'MN',
    latitude: '44.997812',
    longitude: '-93.687245',
    timeZoneId: 'America/Chicago',
  },
  '55360': {
    zip: '55360',
    city: 'Mayer',
    state: 'MN',
    latitude: '44.882967',
    longitude: '-93.888168',
    timeZoneId: 'America/Chicago',
  },
  '55361': {
    zip: '55361',
    city: 'Minnetonka Beach',
    state: 'MN',
    latitude: '44.94042',
    longitude: '-93.597',
    timeZoneId: 'America/Chicago',
  },
  '55362': {
    zip: '55362',
    city: 'Monticello',
    state: 'MN',
    latitude: '45.303033',
    longitude: '-93.798977',
    timeZoneId: 'America/Chicago',
  },
  '55363': {
    zip: '55363',
    city: 'Montrose',
    state: 'MN',
    latitude: '45.065699',
    longitude: '-93.91279',
    timeZoneId: 'America/Chicago',
  },
  '55364': {
    zip: '55364',
    city: 'Mound',
    state: 'MN',
    latitude: '44.935114',
    longitude: '-93.66291',
    timeZoneId: 'America/Chicago',
  },
  '55366': {
    zip: '55366',
    city: 'New Auburn',
    state: 'MN',
    latitude: '44.677597',
    longitude: '-94.206761',
    timeZoneId: 'America/Chicago',
  },
  '55367': {
    zip: '55367',
    city: 'New Germany',
    state: 'MN',
    latitude: '44.885194',
    longitude: '-93.968497',
    timeZoneId: 'America/Chicago',
  },
  '55368': {
    zip: '55368',
    city: 'Norwood Young America',
    state: 'MN',
    latitude: '44.752993',
    longitude: '-93.920341',
    timeZoneId: 'America/Chicago',
  },
  '55369': {
    zip: '55369',
    city: 'Osseo',
    state: 'MN',
    latitude: '45.12186',
    longitude: '-93.4572',
    timeZoneId: 'America/Chicago',
  },
  '55370': {
    zip: '55370',
    city: 'Plato',
    state: 'MN',
    latitude: '44.774436',
    longitude: '-94.038969',
    timeZoneId: 'America/Chicago',
  },
  '55371': {
    zip: '55371',
    city: 'Princeton',
    state: 'MN',
    latitude: '45.571897',
    longitude: '-93.588076',
    timeZoneId: 'America/Chicago',
  },
  '55372': {
    zip: '55372',
    city: 'Prior Lake',
    state: 'MN',
    latitude: '44.683929',
    longitude: '-93.39976',
    timeZoneId: 'America/Chicago',
  },
  '55373': {
    zip: '55373',
    city: 'Rockford',
    state: 'MN',
    latitude: '45.089673',
    longitude: '-93.734933',
    timeZoneId: 'America/Chicago',
  },
  '55374': {
    zip: '55374',
    city: 'Rogers',
    state: 'MN',
    latitude: '45.173877',
    longitude: '-93.567994',
    timeZoneId: 'America/Chicago',
  },
  '55375': {
    zip: '55375',
    city: 'Saint Bonifacius',
    state: 'MN',
    latitude: '44.904785',
    longitude: '-93.749054',
    timeZoneId: 'America/Chicago',
  },
  '55376': {
    zip: '55376',
    city: 'Saint Michael',
    state: 'MN',
    latitude: '45.201094',
    longitude: '-93.672766',
    timeZoneId: 'America/Chicago',
  },
  '55377': {
    zip: '55377',
    city: 'Santiago',
    state: 'MN',
    latitude: '45.54115',
    longitude: '-93.812186',
    timeZoneId: 'America/Chicago',
  },
  '55378': {
    zip: '55378',
    city: 'Savage',
    state: 'MN',
    latitude: '44.763814',
    longitude: '-93.350815',
    timeZoneId: 'America/Chicago',
  },
  '55379': {
    zip: '55379',
    city: 'Shakopee',
    state: 'MN',
    latitude: '44.79143',
    longitude: '-93.516743',
    timeZoneId: 'America/Chicago',
  },
  '55381': {
    zip: '55381',
    city: 'Silver Lake',
    state: 'MN',
    latitude: '44.905043',
    longitude: '-94.200088',
    timeZoneId: 'America/Chicago',
  },
  '55382': {
    zip: '55382',
    city: 'South Haven',
    state: 'MN',
    latitude: '45.34766',
    longitude: '-94.185903',
    timeZoneId: 'America/Chicago',
  },
  '55384': {
    zip: '55384',
    city: 'Spring Park',
    state: 'MN',
    latitude: '44.932917',
    longitude: '-93.632453',
    timeZoneId: 'America/Chicago',
  },
  '55385': {
    zip: '55385',
    city: 'Stewart',
    state: 'MN',
    latitude: '44.722483',
    longitude: '-94.485164',
    timeZoneId: 'America/Chicago',
  },
  '55386': {
    zip: '55386',
    city: 'Victoria',
    state: 'MN',
    latitude: '44.869314',
    longitude: '-93.66893',
    timeZoneId: 'America/Chicago',
  },
  '55387': {
    zip: '55387',
    city: 'Waconia',
    state: 'MN',
    latitude: '44.847413',
    longitude: '-93.788794',
    timeZoneId: 'America/Chicago',
  },
  '55388': {
    zip: '55388',
    city: 'Watertown',
    state: 'MN',
    latitude: '44.954544',
    longitude: '-93.846907',
    timeZoneId: 'America/Chicago',
  },
  '55389': {
    zip: '55389',
    city: 'Watkins',
    state: 'MN',
    latitude: '45.357665',
    longitude: '-94.432239',
    timeZoneId: 'America/Chicago',
  },
  '55390': {
    zip: '55390',
    city: 'Waverly',
    state: 'MN',
    latitude: '45.06653',
    longitude: '-93.96814',
    timeZoneId: 'America/Chicago',
  },
  '55391': {
    zip: '55391',
    city: 'Wayzata',
    state: 'MN',
    latitude: '44.96604',
    longitude: '-93.5418',
    timeZoneId: 'America/Chicago',
  },
  '55392': {
    zip: '55392',
    city: 'Navarre',
    state: 'MN',
    latitude: '44.9549',
    longitude: '-93.541055',
    timeZoneId: 'America/Chicago',
  },
  '55395': {
    zip: '55395',
    city: 'Winsted',
    state: 'MN',
    latitude: '44.964803',
    longitude: '-94.048794',
    timeZoneId: 'America/Chicago',
  },
  '55396': {
    zip: '55396',
    city: 'Winthrop',
    state: 'MN',
    latitude: '44.542475',
    longitude: '-94.367319',
    timeZoneId: 'America/Chicago',
  },
  '55397': {
    zip: '55397',
    city: 'Young America',
    state: 'MN',
    latitude: '44.803025',
    longitude: '-93.930766',
    timeZoneId: 'America/Chicago',
  },
  '55398': {
    zip: '55398',
    city: 'Zimmerman',
    state: 'MN',
    latitude: '45.445056',
    longitude: '-93.592028',
    timeZoneId: 'America/Chicago',
  },
  '55401': {
    zip: '55401',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.984777',
    longitude: '-93.270181',
    timeZoneId: 'America/Chicago',
  },
  '55402': {
    zip: '55402',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.978169',
    longitude: '-93.269129',
    timeZoneId: 'America/Chicago',
  },
  '55403': {
    zip: '55403',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.971974',
    longitude: '-93.283965',
    timeZoneId: 'America/Chicago',
  },
  '55404': {
    zip: '55404',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.961027',
    longitude: '-93.263429',
    timeZoneId: 'America/Chicago',
  },
  '55405': {
    zip: '55405',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.974363',
    longitude: '-93.305344',
    timeZoneId: 'America/Chicago',
  },
  '55406': {
    zip: '55406',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.940817',
    longitude: '-93.224478',
    timeZoneId: 'America/Chicago',
  },
  '55407': {
    zip: '55407',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.940372',
    longitude: '-93.252678',
    timeZoneId: 'America/Chicago',
  },
  '55408': {
    zip: '55408',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.946848',
    longitude: '-93.29097',
    timeZoneId: 'America/Chicago',
  },
  '55409': {
    zip: '55409',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.927946',
    longitude: '-93.288078',
    timeZoneId: 'America/Chicago',
  },
  '55410': {
    zip: '55410',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.913077',
    longitude: '-93.319293',
    timeZoneId: 'America/Chicago',
  },
  '55411': {
    zip: '55411',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.996374',
    longitude: '-93.299888',
    timeZoneId: 'America/Chicago',
  },
  '55412': {
    zip: '55412',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.026523',
    longitude: '-93.303691',
    timeZoneId: 'America/Chicago',
  },
  '55413': {
    zip: '55413',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.001226',
    longitude: '-93.245526',
    timeZoneId: 'America/Chicago',
  },
  '55414': {
    zip: '55414',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.978546',
    longitude: '-93.226011',
    timeZoneId: 'America/Chicago',
  },
  '55415': {
    zip: '55415',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.979183',
    longitude: '-93.261094',
    timeZoneId: 'America/Chicago',
  },
  '55416': {
    zip: '55416',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.948525',
    longitude: '-93.344253',
    timeZoneId: 'America/Chicago',
  },
  '55417': {
    zip: '55417',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.904554',
    longitude: '-93.227606',
    timeZoneId: 'America/Chicago',
  },
  '55418': {
    zip: '55418',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.022134',
    longitude: '-93.244686',
    timeZoneId: 'America/Chicago',
  },
  '55419': {
    zip: '55419',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.903308',
    longitude: '-93.289371',
    timeZoneId: 'America/Chicago',
  },
  '55420': {
    zip: '55420',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.835722',
    longitude: '-93.275326',
    timeZoneId: 'America/Chicago',
  },
  '55421': {
    zip: '55421',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.05112',
    longitude: '-93.24438',
    timeZoneId: 'America/Chicago',
  },
  '55422': {
    zip: '55422',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.00822',
    longitude: '-93.3393',
    timeZoneId: 'America/Chicago',
  },
  '55423': {
    zip: '55423',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.876412',
    longitude: '-93.28685',
    timeZoneId: 'America/Chicago',
  },
  '55424': {
    zip: '55424',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.902538',
    longitude: '-93.339411',
    timeZoneId: 'America/Chicago',
  },
  '55425': {
    zip: '55425',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.84262',
    longitude: '-93.23976',
    timeZoneId: 'America/Chicago',
  },
  '55426': {
    zip: '55426',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.952405',
    longitude: '-93.37786',
    timeZoneId: 'America/Chicago',
  },
  '55427': {
    zip: '55427',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.99634',
    longitude: '-93.37998',
    timeZoneId: 'America/Chicago',
  },
  '55428': {
    zip: '55428',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.064712',
    longitude: '-93.381245',
    timeZoneId: 'America/Chicago',
  },
  '55429': {
    zip: '55429',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.064504',
    longitude: '-93.341046',
    timeZoneId: 'America/Chicago',
  },
  '55430': {
    zip: '55430',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.05624',
    longitude: '-93.30139',
    timeZoneId: 'America/Chicago',
  },
  '55431': {
    zip: '55431',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.824971',
    longitude: '-93.316218',
    timeZoneId: 'America/Chicago',
  },
  '55432': {
    zip: '55432',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.09864',
    longitude: '-93.254963',
    timeZoneId: 'America/Chicago',
  },
  '55433': {
    zip: '55433',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.167194',
    longitude: '-93.324436',
    timeZoneId: 'America/Chicago',
  },
  '55434': {
    zip: '55434',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.167369',
    longitude: '-93.248268',
    timeZoneId: 'America/Chicago',
  },
  '55435': {
    zip: '55435',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.874',
    longitude: '-93.34386',
    timeZoneId: 'America/Chicago',
  },
  '55436': {
    zip: '55436',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.901982',
    longitude: '-93.372906',
    timeZoneId: 'America/Chicago',
  },
  '55437': {
    zip: '55437',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.822599',
    longitude: '-93.34154',
    timeZoneId: 'America/Chicago',
  },
  '55438': {
    zip: '55438',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.827962',
    longitude: '-93.378236',
    timeZoneId: 'America/Chicago',
  },
  '55439': {
    zip: '55439',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.875459',
    longitude: '-93.37435',
    timeZoneId: 'America/Chicago',
  },
  '55440': {
    zip: '55440',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.987435',
    longitude: '-93.271271',
    timeZoneId: 'America/Chicago',
  },
  '55441': {
    zip: '55441',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.004561',
    longitude: '-93.426516',
    timeZoneId: 'America/Chicago',
  },
  '55442': {
    zip: '55442',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.0505',
    longitude: '-93.428851',
    timeZoneId: 'America/Chicago',
  },
  '55443': {
    zip: '55443',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.076111',
    longitude: '-93.3325',
    timeZoneId: 'America/Chicago',
  },
  '55444': {
    zip: '55444',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.08805',
    longitude: '-93.296289',
    timeZoneId: 'America/Chicago',
  },
  '55445': {
    zip: '55445',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.10633',
    longitude: '-93.38407',
    timeZoneId: 'America/Chicago',
  },
  '55446': {
    zip: '55446',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.043463',
    longitude: '-93.491662',
    timeZoneId: 'America/Chicago',
  },
  '55447': {
    zip: '55447',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.000722',
    longitude: '-93.488055',
    timeZoneId: 'America/Chicago',
  },
  '55448': {
    zip: '55448',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.18761',
    longitude: '-93.298576',
    timeZoneId: 'America/Chicago',
  },
  '55449': {
    zip: '55449',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '45.1668',
    longitude: '-93.18966',
    timeZoneId: 'America/Chicago',
  },
  '55450': {
    zip: '55450',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.878006',
    longitude: '-93.218677',
    timeZoneId: 'America/Chicago',
  },
  '55454': {
    zip: '55454',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.964379',
    longitude: '-93.244559',
    timeZoneId: 'America/Chicago',
  },
  '55455': {
    zip: '55455',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.972981',
    longitude: '-93.237826',
    timeZoneId: 'America/Chicago',
  },
  '55458': {
    zip: '55458',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.984312',
    longitude: '-93.273338',
    timeZoneId: 'America/Chicago',
  },
  '55459': {
    zip: '55459',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.986773',
    longitude: '-93.271401',
    timeZoneId: 'America/Chicago',
  },
  '55460': {
    zip: '55460',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.984357',
    longitude: '-93.269822',
    timeZoneId: 'America/Chicago',
  },
  '55467': {
    zip: '55467',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.98',
    longitude: '-93.263611',
    timeZoneId: 'America/Chicago',
  },
  '55470': {
    zip: '55470',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.986577',
    longitude: '-93.271874',
    timeZoneId: 'America/Chicago',
  },
  '55472': {
    zip: '55472',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.980177',
    longitude: '-93.268143',
    timeZoneId: 'America/Chicago',
  },
  '55474': {
    zip: '55474',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.981774',
    longitude: '-93.263916',
    timeZoneId: 'America/Chicago',
  },
  '55478': {
    zip: '55478',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.983515',
    longitude: '-93.264921',
    timeZoneId: 'America/Chicago',
  },
  '55479': {
    zip: '55479',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.986463',
    longitude: '-93.266403',
    timeZoneId: 'America/Chicago',
  },
  '55480': {
    zip: '55480',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.984744',
    longitude: '-93.269393',
    timeZoneId: 'America/Chicago',
  },
  '55483': {
    zip: '55483',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.935113',
    longitude: '-93.283739',
    timeZoneId: 'America/Chicago',
  },
  '55484': {
    zip: '55484',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.970317',
    longitude: '-93.280682',
    timeZoneId: 'America/Chicago',
  },
  '55485': {
    zip: '55485',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.936905',
    longitude: '-93.221555',
    timeZoneId: 'America/Chicago',
  },
  '55486': {
    zip: '55486',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.974141',
    longitude: '-93.267706',
    timeZoneId: 'America/Chicago',
  },
  '55487': {
    zip: '55487',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.978547',
    longitude: '-93.265919',
    timeZoneId: 'America/Chicago',
  },
  '55488': {
    zip: '55488',
    city: 'Minneapolis',
    state: 'MN',
    latitude: '44.981963',
    longitude: '-93.26551',
    timeZoneId: 'America/Chicago',
  },
  '55554': {
    zip: '55554',
    city: 'Norwood',
    state: 'MN',
    latitude: '44.771087',
    longitude: '-93.92778',
    timeZoneId: 'America/Chicago',
  },
  '55569': {
    zip: '55569',
    city: 'Osseo',
    state: 'MN',
    latitude: '45.115998',
    longitude: '-93.397431',
    timeZoneId: 'America/Chicago',
  },
  '55572': {
    zip: '55572',
    city: 'Rockford',
    state: 'MN',
    latitude: '44.99606',
    longitude: '-93.708483',
    timeZoneId: 'America/Chicago',
  },
  '55573': {
    zip: '55573',
    city: 'Young America',
    state: 'MN',
    latitude: '44.814145',
    longitude: '-93.918567',
    timeZoneId: 'America/Chicago',
  },
  '55575': {
    zip: '55575',
    city: 'Howard Lake',
    state: 'MN',
    latitude: '45.057284',
    longitude: '-94.067372',
    timeZoneId: 'America/Chicago',
  },
  '55577': {
    zip: '55577',
    city: 'Rockford',
    state: 'MN',
    latitude: '44.994299',
    longitude: '-93.710007',
    timeZoneId: 'America/Chicago',
  },
  '55592': {
    zip: '55592',
    city: 'Maple Plain',
    state: 'MN',
    latitude: '44.991775',
    longitude: '-93.711712',
    timeZoneId: 'America/Chicago',
  },
  '55593': {
    zip: '55593',
    city: 'Maple Plain',
    state: 'MN',
    latitude: '44.991976',
    longitude: '-93.711101',
    timeZoneId: 'America/Chicago',
  },
  '55599': {
    zip: '55599',
    city: 'Loretto',
    state: 'MN',
    latitude: '45.058193',
    longitude: '-93.658925',
    timeZoneId: 'America/Chicago',
  },
  '55601': {
    zip: '55601',
    city: 'Beaver Bay',
    state: 'MN',
    latitude: '47.265555',
    longitude: '-91.315132',
    timeZoneId: 'America/Chicago',
  },
  '55602': {
    zip: '55602',
    city: 'Brimson',
    state: 'MN',
    latitude: '47.309551',
    longitude: '-91.835899',
    timeZoneId: 'America/Chicago',
  },
  '55603': {
    zip: '55603',
    city: 'Finland',
    state: 'MN',
    latitude: '47.469373',
    longitude: '-91.279679',
    timeZoneId: 'America/Chicago',
  },
  '55604': {
    zip: '55604',
    city: 'Grand Marais',
    state: 'MN',
    latitude: '47.8',
    longitude: '-90.3',
    timeZoneId: 'America/Chicago',
  },
  '55605': {
    zip: '55605',
    city: 'Grand Portage',
    state: 'MN',
    latitude: '47.961796',
    longitude: '-89.760453',
    timeZoneId: 'America/Chicago',
  },
  '55606': {
    zip: '55606',
    city: 'Hovland',
    state: 'MN',
    latitude: '47.877386',
    longitude: '-89.959755',
    timeZoneId: 'America/Chicago',
  },
  '55607': {
    zip: '55607',
    city: 'Isabella',
    state: 'MN',
    latitude: '47.629215',
    longitude: '-91.392801',
    timeZoneId: 'America/Chicago',
  },
  '55609': {
    zip: '55609',
    city: 'Knife River',
    state: 'MN',
    latitude: '46.949401',
    longitude: '-91.779693',
    timeZoneId: 'America/Chicago',
  },
  '55612': {
    zip: '55612',
    city: 'Lutsen',
    state: 'MN',
    latitude: '47.699223',
    longitude: '-90.623254',
    timeZoneId: 'America/Chicago',
  },
  '55613': {
    zip: '55613',
    city: 'Schroeder',
    state: 'MN',
    latitude: '47.690229',
    longitude: '-90.961245',
    timeZoneId: 'America/Chicago',
  },
  '55614': {
    zip: '55614',
    city: 'Silver Bay',
    state: 'MN',
    latitude: '47.30526',
    longitude: '-91.24746',
    timeZoneId: 'America/Chicago',
  },
  '55615': {
    zip: '55615',
    city: 'Tofte',
    state: 'MN',
    latitude: '47.739914',
    longitude: '-90.829716',
    timeZoneId: 'America/Chicago',
  },
  '55616': {
    zip: '55616',
    city: 'Two Harbors',
    state: 'MN',
    latitude: '47.029764',
    longitude: '-91.6712',
    timeZoneId: 'America/Chicago',
  },
  '55702': {
    zip: '55702',
    city: 'Alborn',
    state: 'MN',
    latitude: '46.974185',
    longitude: '-92.60075',
    timeZoneId: 'America/Chicago',
  },
  '55703': {
    zip: '55703',
    city: 'Angora',
    state: 'MN',
    latitude: '47.758363',
    longitude: '-92.771783',
    timeZoneId: 'America/Chicago',
  },
  '55704': {
    zip: '55704',
    city: 'Askov',
    state: 'MN',
    latitude: '46.272907',
    longitude: '-92.812752',
    timeZoneId: 'America/Chicago',
  },
  '55705': {
    zip: '55705',
    city: 'Aurora',
    state: 'MN',
    latitude: '47.4987',
    longitude: '-92.23578',
    timeZoneId: 'America/Chicago',
  },
  '55706': {
    zip: '55706',
    city: 'Babbitt',
    state: 'MN',
    latitude: '47.720713',
    longitude: '-91.960638',
    timeZoneId: 'America/Chicago',
  },
  '55707': {
    zip: '55707',
    city: 'Barnum',
    state: 'MN',
    latitude: '46.576488',
    longitude: '-92.717904',
    timeZoneId: 'America/Chicago',
  },
  '55708': {
    zip: '55708',
    city: 'Biwabik',
    state: 'MN',
    latitude: '47.546084',
    longitude: '-92.335052',
    timeZoneId: 'America/Chicago',
  },
  '55709': {
    zip: '55709',
    city: 'Bovey',
    state: 'MN',
    latitude: '47.248534',
    longitude: '-93.364213',
    timeZoneId: 'America/Chicago',
  },
  '55710': {
    zip: '55710',
    city: 'Britt',
    state: 'MN',
    latitude: '47.648089',
    longitude: '-92.655516',
    timeZoneId: 'America/Chicago',
  },
  '55711': {
    zip: '55711',
    city: 'Brookston',
    state: 'MN',
    latitude: '46.856672',
    longitude: '-92.673076',
    timeZoneId: 'America/Chicago',
  },
  '55712': {
    zip: '55712',
    city: 'Bruno',
    state: 'MN',
    latitude: '46.287947',
    longitude: '-92.550048',
    timeZoneId: 'America/Chicago',
  },
  '55713': {
    zip: '55713',
    city: 'Buhl',
    state: 'MN',
    latitude: '47.493443',
    longitude: '-92.773072',
    timeZoneId: 'America/Chicago',
  },
  '55716': {
    zip: '55716',
    city: 'Calumet',
    state: 'MN',
    latitude: '47.313938',
    longitude: '-93.280715',
    timeZoneId: 'America/Chicago',
  },
  '55717': {
    zip: '55717',
    city: 'Canyon',
    state: 'MN',
    latitude: '47.079441',
    longitude: '-92.470331',
    timeZoneId: 'America/Chicago',
  },
  '55718': {
    zip: '55718',
    city: 'Carlton',
    state: 'MN',
    latitude: '46.63404',
    longitude: '-92.5533',
    timeZoneId: 'America/Chicago',
  },
  '55719': {
    zip: '55719',
    city: 'Chisholm',
    state: 'MN',
    latitude: '47.519753',
    longitude: '-92.880286',
    timeZoneId: 'America/Chicago',
  },
  '55720': {
    zip: '55720',
    city: 'Cloquet',
    state: 'MN',
    latitude: '46.72938',
    longitude: '-92.45922',
    timeZoneId: 'America/Chicago',
  },
  '55721': {
    zip: '55721',
    city: 'Cohasset',
    state: 'MN',
    latitude: '47.239448',
    longitude: '-93.619584',
    timeZoneId: 'America/Chicago',
  },
  '55722': {
    zip: '55722',
    city: 'Coleraine',
    state: 'MN',
    latitude: '47.290086',
    longitude: '-93.425775',
    timeZoneId: 'America/Chicago',
  },
  '55723': {
    zip: '55723',
    city: 'Cook',
    state: 'MN',
    latitude: '47.8674',
    longitude: '-92.77926',
    timeZoneId: 'America/Chicago',
  },
  '55724': {
    zip: '55724',
    city: 'Cotton',
    state: 'MN',
    latitude: '47.154803',
    longitude: '-92.57617',
    timeZoneId: 'America/Chicago',
  },
  '55725': {
    zip: '55725',
    city: 'Crane Lake',
    state: 'MN',
    latitude: '48.268374',
    longitude: '-92.489389',
    timeZoneId: 'America/Chicago',
  },
  '55726': {
    zip: '55726',
    city: 'Cromwell',
    state: 'MN',
    latitude: '46.651117',
    longitude: '-92.904256',
    timeZoneId: 'America/Chicago',
  },
  '55730': {
    zip: '55730',
    city: 'Grand Rapids',
    state: 'MN',
    latitude: '47.238881',
    longitude: '-93.528397',
    timeZoneId: 'America/Chicago',
  },
  '55731': {
    zip: '55731',
    city: 'Ely',
    state: 'MN',
    latitude: '47.895235',
    longitude: '-91.887968',
    timeZoneId: 'America/Chicago',
  },
  '55732': {
    zip: '55732',
    city: 'Embarrass',
    state: 'MN',
    latitude: '47.667582',
    longitude: '-92.224878',
    timeZoneId: 'America/Chicago',
  },
  '55733': {
    zip: '55733',
    city: 'Esko',
    state: 'MN',
    latitude: '46.710215',
    longitude: '-92.360371',
    timeZoneId: 'America/Chicago',
  },
  '55734': {
    zip: '55734',
    city: 'Eveleth',
    state: 'MN',
    latitude: '47.43151',
    longitude: '-92.516556',
    timeZoneId: 'America/Chicago',
  },
  '55735': {
    zip: '55735',
    city: 'Finlayson',
    state: 'MN',
    latitude: '46.217584',
    longitude: '-92.953356',
    timeZoneId: 'America/Chicago',
  },
  '55736': {
    zip: '55736',
    city: 'Floodwood',
    state: 'MN',
    latitude: '46.89102',
    longitude: '-92.91852',
    timeZoneId: 'America/Chicago',
  },
  '55738': {
    zip: '55738',
    city: 'Forbes',
    state: 'MN',
    latitude: '47.271048',
    longitude: '-92.618597',
    timeZoneId: 'America/Chicago',
  },
  '55741': {
    zip: '55741',
    city: 'Gilbert',
    state: 'MN',
    latitude: '47.512565',
    longitude: '-92.411206',
    timeZoneId: 'America/Chicago',
  },
  '55742': {
    zip: '55742',
    city: 'Goodland',
    state: 'MN',
    latitude: '47.195525',
    longitude: '-93.128478',
    timeZoneId: 'America/Chicago',
  },
  '55744': {
    zip: '55744',
    city: 'Grand Rapids',
    state: 'MN',
    latitude: '47.233828',
    longitude: '-93.522105',
    timeZoneId: 'America/Chicago',
  },
  '55745': {
    zip: '55745',
    city: 'Grand Rapids',
    state: 'MN',
    latitude: '47.230302',
    longitude: '-93.524079',
    timeZoneId: 'America/Chicago',
  },
  '55746': {
    zip: '55746',
    city: 'Hibbing',
    state: 'MN',
    latitude: '47.416877',
    longitude: '-92.930377',
    timeZoneId: 'America/Chicago',
  },
  '55748': {
    zip: '55748',
    city: 'Hill City',
    state: 'MN',
    latitude: '46.9881',
    longitude: '-93.59388',
    timeZoneId: 'America/Chicago',
  },
  '55749': {
    zip: '55749',
    city: 'Holyoke',
    state: 'MN',
    latitude: '46.472378',
    longitude: '-92.392209',
    timeZoneId: 'America/Chicago',
  },
  '55750': {
    zip: '55750',
    city: 'Hoyt Lakes',
    state: 'MN',
    latitude: '47.516024',
    longitude: '-92.138667',
    timeZoneId: 'America/Chicago',
  },
  '55751': {
    zip: '55751',
    city: 'Iron',
    state: 'MN',
    latitude: '47.403605',
    longitude: '-92.653616',
    timeZoneId: 'America/Chicago',
  },
  '55752': {
    zip: '55752',
    city: 'Jacobson',
    state: 'MN',
    latitude: '46.941037',
    longitude: '-93.313905',
    timeZoneId: 'America/Chicago',
  },
  '55753': {
    zip: '55753',
    city: 'Keewatin',
    state: 'MN',
    latitude: '47.398873',
    longitude: '-93.079649',
    timeZoneId: 'America/Chicago',
  },
  '55756': {
    zip: '55756',
    city: 'Kerrick',
    state: 'MN',
    latitude: '46.298113',
    longitude: '-92.721724',
    timeZoneId: 'America/Chicago',
  },
  '55757': {
    zip: '55757',
    city: 'Kettle River',
    state: 'MN',
    latitude: '46.513559',
    longitude: '-92.920583',
    timeZoneId: 'America/Chicago',
  },
  '55758': {
    zip: '55758',
    city: 'Kinney',
    state: 'MN',
    latitude: '47.517125',
    longitude: '-92.727835',
    timeZoneId: 'America/Chicago',
  },
  '55760': {
    zip: '55760',
    city: 'McGregor',
    state: 'MN',
    latitude: '46.70778',
    longitude: '-93.29688',
    timeZoneId: 'America/Chicago',
  },
  '55763': {
    zip: '55763',
    city: 'Makinen',
    state: 'MN',
    latitude: '47.336731',
    longitude: '-92.257473',
    timeZoneId: 'America/Chicago',
  },
  '55764': {
    zip: '55764',
    city: 'Marble',
    state: 'MN',
    latitude: '47.322198',
    longitude: '-93.302759',
    timeZoneId: 'America/Chicago',
  },
  '55765': {
    zip: '55765',
    city: 'Meadowlands',
    state: 'MN',
    latitude: '47.101666',
    longitude: '-92.758853',
    timeZoneId: 'America/Chicago',
  },
  '55766': {
    zip: '55766',
    city: 'Melrude',
    state: 'MN',
    latitude: '47.2362',
    longitude: '-92.41218',
    timeZoneId: 'America/Chicago',
  },
  '55767': {
    zip: '55767',
    city: 'Moose Lake',
    state: 'MN',
    latitude: '46.452701',
    longitude: '-92.769759',
    timeZoneId: 'America/Chicago',
  },
  '55768': {
    zip: '55768',
    city: 'Mountain Iron',
    state: 'MN',
    latitude: '47.530445',
    longitude: '-92.581327',
    timeZoneId: 'America/Chicago',
  },
  '55769': {
    zip: '55769',
    city: 'Nashwauk',
    state: 'MN',
    latitude: '47.379684',
    longitude: '-93.16665',
    timeZoneId: 'America/Chicago',
  },
  '55771': {
    zip: '55771',
    city: 'Orr',
    state: 'MN',
    latitude: '48.16824',
    longitude: '-92.77992',
    timeZoneId: 'America/Chicago',
  },
  '55772': {
    zip: '55772',
    city: 'Nett Lake',
    state: 'MN',
    latitude: '48.053611',
    longitude: '-92.830833',
    timeZoneId: 'America/Chicago',
  },
  '55775': {
    zip: '55775',
    city: 'Pengilly',
    state: 'MN',
    latitude: '47.33022',
    longitude: '-93.162673',
    timeZoneId: 'America/Chicago',
  },
  '55777': {
    zip: '55777',
    city: 'Virginia',
    state: 'MN',
    latitude: '47.520334',
    longitude: '-92.532139',
    timeZoneId: 'America/Chicago',
  },
  '55779': {
    zip: '55779',
    city: 'Saginaw',
    state: 'MN',
    latitude: '46.90812',
    longitude: '-92.43906',
    timeZoneId: 'America/Chicago',
  },
  '55780': {
    zip: '55780',
    city: 'Sawyer',
    state: 'MN',
    latitude: '46.685243',
    longitude: '-92.68512',
    timeZoneId: 'America/Chicago',
  },
  '55781': {
    zip: '55781',
    city: 'Side Lake',
    state: 'MN',
    latitude: '47.679777',
    longitude: '-92.999655',
    timeZoneId: 'America/Chicago',
  },
  '55782': {
    zip: '55782',
    city: 'Soudan',
    state: 'MN',
    latitude: '47.816426',
    longitude: '-92.236907',
    timeZoneId: 'America/Chicago',
  },
  '55783': {
    zip: '55783',
    city: 'Sturgeon Lake',
    state: 'MN',
    latitude: '46.360574',
    longitude: '-92.778625',
    timeZoneId: 'America/Chicago',
  },
  '55784': {
    zip: '55784',
    city: 'Swan River',
    state: 'MN',
    latitude: '47.066819',
    longitude: '-93.187769',
    timeZoneId: 'America/Chicago',
  },
  '55785': {
    zip: '55785',
    city: 'Swatara',
    state: 'MN',
    latitude: '46.877521',
    longitude: '-93.682649',
    timeZoneId: 'America/Chicago',
  },
  '55786': {
    zip: '55786',
    city: 'Taconite',
    state: 'MN',
    latitude: '47.309263',
    longitude: '-93.382691',
    timeZoneId: 'America/Chicago',
  },
  '55787': {
    zip: '55787',
    city: 'Tamarack',
    state: 'MN',
    latitude: '46.637586',
    longitude: '-93.122746',
    timeZoneId: 'America/Chicago',
  },
  '55790': {
    zip: '55790',
    city: 'Tower',
    state: 'MN',
    latitude: '47.795868',
    longitude: '-92.353874',
    timeZoneId: 'America/Chicago',
  },
  '55791': {
    zip: '55791',
    city: 'Twig',
    state: 'MN',
    latitude: '46.895844',
    longitude: '-92.356325',
    timeZoneId: 'America/Chicago',
  },
  '55792': {
    zip: '55792',
    city: 'Virginia',
    state: 'MN',
    latitude: '47.59494',
    longitude: '-92.48112',
    timeZoneId: 'America/Chicago',
  },
  '55793': {
    zip: '55793',
    city: 'Warba',
    state: 'MN',
    latitude: '47.12925',
    longitude: '-93.26713',
    timeZoneId: 'America/Chicago',
  },
  '55795': {
    zip: '55795',
    city: 'Willow River',
    state: 'MN',
    latitude: '46.311725',
    longitude: '-92.860123',
    timeZoneId: 'America/Chicago',
  },
  '55796': {
    zip: '55796',
    city: 'Winton',
    state: 'MN',
    latitude: '47.928032',
    longitude: '-91.800164',
    timeZoneId: 'America/Chicago',
  },
  '55797': {
    zip: '55797',
    city: 'Wrenshall',
    state: 'MN',
    latitude: '46.4675',
    longitude: '-92.388333',
    timeZoneId: 'America/Chicago',
  },
  '55798': {
    zip: '55798',
    city: 'Wright',
    state: 'MN',
    latitude: '46.67472',
    longitude: '-92.98542',
    timeZoneId: 'America/Chicago',
  },
  '55801': {
    zip: '55801',
    city: 'Duluth',
    state: 'MN',
    latitude: '47.099061',
    longitude: '-91.852643',
    timeZoneId: 'America/Chicago',
  },
  '55802': {
    zip: '55802',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.767393',
    longitude: '-92.11547',
    timeZoneId: 'America/Chicago',
  },
  '55803': {
    zip: '55803',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.913546',
    longitude: '-92.116488',
    timeZoneId: 'America/Chicago',
  },
  '55804': {
    zip: '55804',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.868596',
    longitude: '-91.981305',
    timeZoneId: 'America/Chicago',
  },
  '55805': {
    zip: '55805',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.796549',
    longitude: '-92.094486',
    timeZoneId: 'America/Chicago',
  },
  '55806': {
    zip: '55806',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.774041',
    longitude: '-92.124646',
    timeZoneId: 'America/Chicago',
  },
  '55807': {
    zip: '55807',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.739838',
    longitude: '-92.167853',
    timeZoneId: 'America/Chicago',
  },
  '55808': {
    zip: '55808',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.676959',
    longitude: '-92.231918',
    timeZoneId: 'America/Chicago',
  },
  '55810': {
    zip: '55810',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.76412',
    longitude: '-92.2542',
    timeZoneId: 'America/Chicago',
  },
  '55811': {
    zip: '55811',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.83738',
    longitude: '-92.2014',
    timeZoneId: 'America/Chicago',
  },
  '55812': {
    zip: '55812',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.811881',
    longitude: '-92.075972',
    timeZoneId: 'America/Chicago',
  },
  '55814': {
    zip: '55814',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.841316',
    longitude: '-92.216319',
    timeZoneId: 'America/Chicago',
  },
  '55815': {
    zip: '55815',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.761056',
    longitude: '-92.129829',
    timeZoneId: 'America/Chicago',
  },
  '55816': {
    zip: '55816',
    city: 'Duluth',
    state: 'MN',
    latitude: '46.768155',
    longitude: '-92.133133',
    timeZoneId: 'America/Chicago',
  },
  '55901': {
    zip: '55901',
    city: 'Rochester',
    state: 'MN',
    latitude: '44.051928',
    longitude: '-92.495114',
    timeZoneId: 'America/Chicago',
  },
  '55902': {
    zip: '55902',
    city: 'Rochester',
    state: 'MN',
    latitude: '44.000389',
    longitude: '-92.492318',
    timeZoneId: 'America/Chicago',
  },
  '55903': {
    zip: '55903',
    city: 'Rochester',
    state: 'MN',
    latitude: '44.000259',
    longitude: '-92.53363',
    timeZoneId: 'America/Chicago',
  },
  '55904': {
    zip: '55904',
    city: 'Rochester',
    state: 'MN',
    latitude: '44.01404',
    longitude: '-92.422764',
    timeZoneId: 'America/Chicago',
  },
  '55905': {
    zip: '55905',
    city: 'Rochester',
    state: 'MN',
    latitude: '44.037368',
    longitude: '-92.490118',
    timeZoneId: 'America/Chicago',
  },
  '55906': {
    zip: '55906',
    city: 'Rochester',
    state: 'MN',
    latitude: '44.060636',
    longitude: '-92.439257',
    timeZoneId: 'America/Chicago',
  },
  '55909': {
    zip: '55909',
    city: 'Adams',
    state: 'MN',
    latitude: '43.567108',
    longitude: '-92.718696',
    timeZoneId: 'America/Chicago',
  },
  '55910': {
    zip: '55910',
    city: 'Altura',
    state: 'MN',
    latitude: '44.13126',
    longitude: '-91.92066',
    timeZoneId: 'America/Chicago',
  },
  '55912': {
    zip: '55912',
    city: 'Austin',
    state: 'MN',
    latitude: '43.667498',
    longitude: '-92.979475',
    timeZoneId: 'America/Chicago',
  },
  '55917': {
    zip: '55917',
    city: 'Blooming Prairie',
    state: 'MN',
    latitude: '43.9179',
    longitude: '-93.0555',
    timeZoneId: 'America/Chicago',
  },
  '55918': {
    zip: '55918',
    city: 'Brownsdale',
    state: 'MN',
    latitude: '43.731405',
    longitude: '-92.872054',
    timeZoneId: 'America/Chicago',
  },
  '55919': {
    zip: '55919',
    city: 'Brownsville',
    state: 'MN',
    latitude: '43.688294',
    longitude: '-91.294422',
    timeZoneId: 'America/Chicago',
  },
  '55920': {
    zip: '55920',
    city: 'Byron',
    state: 'MN',
    latitude: '44.032919',
    longitude: '-92.649851',
    timeZoneId: 'America/Chicago',
  },
  '55921': {
    zip: '55921',
    city: 'Caledonia',
    state: 'MN',
    latitude: '43.632279',
    longitude: '-91.501791',
    timeZoneId: 'America/Chicago',
  },
  '55922': {
    zip: '55922',
    city: 'Canton',
    state: 'MN',
    latitude: '43.587946',
    longitude: '-91.908721',
    timeZoneId: 'America/Chicago',
  },
  '55923': {
    zip: '55923',
    city: 'Chatfield',
    state: 'MN',
    latitude: '43.842309',
    longitude: '-92.183188',
    timeZoneId: 'America/Chicago',
  },
  '55924': {
    zip: '55924',
    city: 'Claremont',
    state: 'MN',
    latitude: '44.044098',
    longitude: '-92.993163',
    timeZoneId: 'America/Chicago',
  },
  '55925': {
    zip: '55925',
    city: 'Dakota',
    state: 'MN',
    latitude: '43.91712',
    longitude: '-91.39452',
    timeZoneId: 'America/Chicago',
  },
  '55926': {
    zip: '55926',
    city: 'Dexter',
    state: 'MN',
    latitude: '43.72007',
    longitude: '-92.750013',
    timeZoneId: 'America/Chicago',
  },
  '55927': {
    zip: '55927',
    city: 'Dodge Center',
    state: 'MN',
    latitude: '44.033819',
    longitude: '-92.86673',
    timeZoneId: 'America/Chicago',
  },
  '55929': {
    zip: '55929',
    city: 'Dover',
    state: 'MN',
    latitude: '43.970538',
    longitude: '-92.136636',
    timeZoneId: 'America/Chicago',
  },
  '55931': {
    zip: '55931',
    city: 'Eitzen',
    state: 'MN',
    latitude: '43.602779',
    longitude: '-91.444998',
    timeZoneId: 'America/Chicago',
  },
  '55932': {
    zip: '55932',
    city: 'Elgin',
    state: 'MN',
    latitude: '44.121402',
    longitude: '-92.250206',
    timeZoneId: 'America/Chicago',
  },
  '55933': {
    zip: '55933',
    city: 'Elkton',
    state: 'MN',
    latitude: '43.633231',
    longitude: '-92.691164',
    timeZoneId: 'America/Chicago',
  },
  '55934': {
    zip: '55934',
    city: 'Eyota',
    state: 'MN',
    latitude: '44.02206',
    longitude: '-92.27676',
    timeZoneId: 'America/Chicago',
  },
  '55935': {
    zip: '55935',
    city: 'Fountain',
    state: 'MN',
    latitude: '43.717012',
    longitude: '-92.150989',
    timeZoneId: 'America/Chicago',
  },
  '55936': {
    zip: '55936',
    city: 'Grand Meadow',
    state: 'MN',
    latitude: '43.704937',
    longitude: '-92.570979',
    timeZoneId: 'America/Chicago',
  },
  '55939': {
    zip: '55939',
    city: 'Harmony',
    state: 'MN',
    latitude: '43.557088',
    longitude: '-92.010857',
    timeZoneId: 'America/Chicago',
  },
  '55940': {
    zip: '55940',
    city: 'Hayfield',
    state: 'MN',
    latitude: '43.891882',
    longitude: '-92.849789',
    timeZoneId: 'America/Chicago',
  },
  '55941': {
    zip: '55941',
    city: 'Hokah',
    state: 'MN',
    latitude: '43.761414',
    longitude: '-91.35243',
    timeZoneId: 'America/Chicago',
  },
  '55942': {
    zip: '55942',
    city: 'Homer',
    state: 'MN',
    latitude: '43.982101',
    longitude: '-91.637823',
    timeZoneId: 'America/Chicago',
  },
  '55943': {
    zip: '55943',
    city: 'Houston',
    state: 'MN',
    latitude: '43.762527',
    longitude: '-91.57159',
    timeZoneId: 'America/Chicago',
  },
  '55944': {
    zip: '55944',
    city: 'Kasson',
    state: 'MN',
    latitude: '44.036896',
    longitude: '-92.751584',
    timeZoneId: 'America/Chicago',
  },
  '55945': {
    zip: '55945',
    city: 'Kellogg',
    state: 'MN',
    latitude: '44.308082',
    longitude: '-91.996965',
    timeZoneId: 'America/Chicago',
  },
  '55946': {
    zip: '55946',
    city: 'Kenyon',
    state: 'MN',
    latitude: '44.271167',
    longitude: '-92.988924',
    timeZoneId: 'America/Chicago',
  },
  '55947': {
    zip: '55947',
    city: 'La Crescent',
    state: 'MN',
    latitude: '43.819624',
    longitude: '-91.327279',
    timeZoneId: 'America/Chicago',
  },
  '55949': {
    zip: '55949',
    city: 'Lanesboro',
    state: 'MN',
    latitude: '43.7169',
    longitude: '-92.00658',
    timeZoneId: 'America/Chicago',
  },
  '55950': {
    zip: '55950',
    city: 'Lansing',
    state: 'MN',
    latitude: '43.71697',
    longitude: '-92.986286',
    timeZoneId: 'America/Chicago',
  },
  '55951': {
    zip: '55951',
    city: 'Le Roy',
    state: 'MN',
    latitude: '43.514128',
    longitude: '-92.501068',
    timeZoneId: 'America/Chicago',
  },
  '55952': {
    zip: '55952',
    city: 'Lewiston',
    state: 'MN',
    latitude: '43.984316',
    longitude: '-91.867324',
    timeZoneId: 'America/Chicago',
  },
  '55953': {
    zip: '55953',
    city: 'Lyle',
    state: 'MN',
    latitude: '43.504882',
    longitude: '-92.94186',
    timeZoneId: 'America/Chicago',
  },
  '55954': {
    zip: '55954',
    city: 'Mabel',
    state: 'MN',
    latitude: '43.507778',
    longitude: '-91.869444',
    timeZoneId: 'America/Chicago',
  },
  '55955': {
    zip: '55955',
    city: 'Mantorville',
    state: 'MN',
    latitude: '44.06624',
    longitude: '-92.759736',
    timeZoneId: 'America/Chicago',
  },
  '55956': {
    zip: '55956',
    city: 'Mazeppa',
    state: 'MN',
    latitude: '44.276489',
    longitude: '-92.544678',
    timeZoneId: 'America/Chicago',
  },
  '55957': {
    zip: '55957',
    city: 'Millville',
    state: 'MN',
    latitude: '44.244314',
    longitude: '-92.296084',
    timeZoneId: 'America/Chicago',
  },
  '55959': {
    zip: '55959',
    city: 'Minnesota City',
    state: 'MN',
    latitude: '44.087785',
    longitude: '-91.743808',
    timeZoneId: 'America/Chicago',
  },
  '55960': {
    zip: '55960',
    city: 'Oronoco',
    state: 'MN',
    latitude: '44.151074',
    longitude: '-92.521283',
    timeZoneId: 'America/Chicago',
  },
  '55961': {
    zip: '55961',
    city: 'Ostrander',
    state: 'MN',
    latitude: '43.571582',
    longitude: '-92.406254',
    timeZoneId: 'America/Chicago',
  },
  '55962': {
    zip: '55962',
    city: 'Peterson',
    state: 'MN',
    latitude: '43.77423',
    longitude: '-91.848858',
    timeZoneId: 'America/Chicago',
  },
  '55963': {
    zip: '55963',
    city: 'Pine Island',
    state: 'MN',
    latitude: '44.203767',
    longitude: '-92.6488',
    timeZoneId: 'America/Chicago',
  },
  '55964': {
    zip: '55964',
    city: 'Plainview',
    state: 'MN',
    latitude: '44.168343',
    longitude: '-92.168273',
    timeZoneId: 'America/Chicago',
  },
  '55965': {
    zip: '55965',
    city: 'Preston',
    state: 'MN',
    latitude: '43.667591',
    longitude: '-92.079343',
    timeZoneId: 'America/Chicago',
  },
  '55967': {
    zip: '55967',
    city: 'Racine',
    state: 'MN',
    latitude: '43.795675',
    longitude: '-92.569537',
    timeZoneId: 'America/Chicago',
  },
  '55968': {
    zip: '55968',
    city: 'Reads Landing',
    state: 'MN',
    latitude: '44.401512',
    longitude: '-92.073119',
    timeZoneId: 'America/Chicago',
  },
  '55969': {
    zip: '55969',
    city: 'Rollingstone',
    state: 'MN',
    latitude: '44.096833',
    longitude: '-91.819032',
    timeZoneId: 'America/Chicago',
  },
  '55970': {
    zip: '55970',
    city: 'Rose Creek',
    state: 'MN',
    latitude: '43.569828',
    longitude: '-92.721229',
    timeZoneId: 'America/Chicago',
  },
  '55971': {
    zip: '55971',
    city: 'Rushford',
    state: 'MN',
    latitude: '43.797936',
    longitude: '-91.757171',
    timeZoneId: 'America/Chicago',
  },
  '55972': {
    zip: '55972',
    city: 'Saint Charles',
    state: 'MN',
    latitude: '43.967246',
    longitude: '-92.063039',
    timeZoneId: 'America/Chicago',
  },
  '55973': {
    zip: '55973',
    city: 'Sargeant',
    state: 'MN',
    latitude: '43.80635',
    longitude: '-92.749222',
    timeZoneId: 'America/Chicago',
  },
  '55974': {
    zip: '55974',
    city: 'Spring Grove',
    state: 'MN',
    latitude: '43.562636',
    longitude: '-91.635986',
    timeZoneId: 'America/Chicago',
  },
  '55975': {
    zip: '55975',
    city: 'Spring Valley',
    state: 'MN',
    latitude: '43.687315',
    longitude: '-92.391053',
    timeZoneId: 'America/Chicago',
  },
  '55976': {
    zip: '55976',
    city: 'Stewartville',
    state: 'MN',
    latitude: '43.859369',
    longitude: '-92.487834',
    timeZoneId: 'America/Chicago',
  },
  '55977': {
    zip: '55977',
    city: 'Taopi',
    state: 'MN',
    latitude: '43.542226',
    longitude: '-92.628717',
    timeZoneId: 'America/Chicago',
  },
  '55979': {
    zip: '55979',
    city: 'Utica',
    state: 'MN',
    latitude: '43.945993',
    longitude: '-91.93512',
    timeZoneId: 'America/Chicago',
  },
  '55981': {
    zip: '55981',
    city: 'Wabasha',
    state: 'MN',
    latitude: '44.359337',
    longitude: '-92.023834',
    timeZoneId: 'America/Chicago',
  },
  '55982': {
    zip: '55982',
    city: 'Waltham',
    state: 'MN',
    latitude: '43.803937',
    longitude: '-92.868615',
    timeZoneId: 'America/Chicago',
  },
  '55983': {
    zip: '55983',
    city: 'Wanamingo',
    state: 'MN',
    latitude: '44.302169',
    longitude: '-92.788163',
    timeZoneId: 'America/Chicago',
  },
  '55985': {
    zip: '55985',
    city: 'West Concord',
    state: 'MN',
    latitude: '44.1475',
    longitude: '-92.836111',
    timeZoneId: 'America/Chicago',
  },
  '55987': {
    zip: '55987',
    city: 'Winona',
    state: 'MN',
    latitude: '44.03766',
    longitude: '-91.6749',
    timeZoneId: 'America/Chicago',
  },
  '55988': {
    zip: '55988',
    city: 'Stockton',
    state: 'MN',
    latitude: '44.027664',
    longitude: '-91.760287',
    timeZoneId: 'America/Chicago',
  },
  '55990': {
    zip: '55990',
    city: 'Wykoff',
    state: 'MN',
    latitude: '43.71348',
    longitude: '-92.26986',
    timeZoneId: 'America/Chicago',
  },
  '55991': {
    zip: '55991',
    city: 'Zumbro Falls',
    state: 'MN',
    latitude: '44.22492',
    longitude: '-92.43414',
    timeZoneId: 'America/Chicago',
  },
  '55992': {
    zip: '55992',
    city: 'Zumbrota',
    state: 'MN',
    latitude: '44.288714',
    longitude: '-92.672873',
    timeZoneId: 'America/Chicago',
  },
  '56001': {
    zip: '56001',
    city: 'Mankato',
    state: 'MN',
    latitude: '44.160927',
    longitude: '-93.995246',
    timeZoneId: 'America/Chicago',
  },
  '56002': {
    zip: '56002',
    city: 'Mankato',
    state: 'MN',
    latitude: '44.161464',
    longitude: '-94.005528',
    timeZoneId: 'America/Chicago',
  },
  '56003': {
    zip: '56003',
    city: 'Mankato',
    state: 'MN',
    latitude: '44.185763',
    longitude: '-94.056217',
    timeZoneId: 'America/Chicago',
  },
  '56007': {
    zip: '56007',
    city: 'Albert Lea',
    state: 'MN',
    latitude: '43.650835',
    longitude: '-93.371347',
    timeZoneId: 'America/Chicago',
  },
  '56009': {
    zip: '56009',
    city: 'Alden',
    state: 'MN',
    latitude: '43.668147',
    longitude: '-93.574066',
    timeZoneId: 'America/Chicago',
  },
  '56010': {
    zip: '56010',
    city: 'Amboy',
    state: 'MN',
    latitude: '43.887255',
    longitude: '-94.158648',
    timeZoneId: 'America/Chicago',
  },
  '56011': {
    zip: '56011',
    city: 'Belle Plaine',
    state: 'MN',
    latitude: '44.628797',
    longitude: '-93.778892',
    timeZoneId: 'America/Chicago',
  },
  '56013': {
    zip: '56013',
    city: 'Blue Earth',
    state: 'MN',
    latitude: '43.642656',
    longitude: '-94.093292',
    timeZoneId: 'America/Chicago',
  },
  '56014': {
    zip: '56014',
    city: 'Bricelyn',
    state: 'MN',
    latitude: '43.562123',
    longitude: '-93.814966',
    timeZoneId: 'America/Chicago',
  },
  '56016': {
    zip: '56016',
    city: 'Clarks Grove',
    state: 'MN',
    latitude: '43.762656',
    longitude: '-93.317911',
    timeZoneId: 'America/Chicago',
  },
  '56017': {
    zip: '56017',
    city: 'Cleveland',
    state: 'MN',
    latitude: '44.324172',
    longitude: '-93.834005',
    timeZoneId: 'America/Chicago',
  },
  '56019': {
    zip: '56019',
    city: 'Comfrey',
    state: 'MN',
    latitude: '44.11066',
    longitude: '-94.904546',
    timeZoneId: 'America/Chicago',
  },
  '56020': {
    zip: '56020',
    city: 'Conger',
    state: 'MN',
    latitude: '43.61825',
    longitude: '-93.532559',
    timeZoneId: 'America/Chicago',
  },
  '56021': {
    zip: '56021',
    city: 'Courtland',
    state: 'MN',
    latitude: '44.26012',
    longitude: '-94.338504',
    timeZoneId: 'America/Chicago',
  },
  '56022': {
    zip: '56022',
    city: 'Darfur',
    state: 'MN',
    latitude: '44.049791',
    longitude: '-94.83618',
    timeZoneId: 'America/Chicago',
  },
  '56023': {
    zip: '56023',
    city: 'Delavan',
    state: 'MN',
    latitude: '43.768968',
    longitude: '-94.003264',
    timeZoneId: 'America/Chicago',
  },
  '56024': {
    zip: '56024',
    city: 'Eagle Lake',
    state: 'MN',
    latitude: '44.165184',
    longitude: '-93.882855',
    timeZoneId: 'America/Chicago',
  },
  '56025': {
    zip: '56025',
    city: 'Easton',
    state: 'MN',
    latitude: '43.760588',
    longitude: '-93.94961',
    timeZoneId: 'America/Chicago',
  },
  '56026': {
    zip: '56026',
    city: 'Ellendale',
    state: 'MN',
    latitude: '43.874568',
    longitude: '-93.302086',
    timeZoneId: 'America/Chicago',
  },
  '56027': {
    zip: '56027',
    city: 'Elmore',
    state: 'MN',
    latitude: '43.503204',
    longitude: '-94.082426',
    timeZoneId: 'America/Chicago',
  },
  '56028': {
    zip: '56028',
    city: 'Elysian',
    state: 'MN',
    latitude: '44.233426',
    longitude: '-93.704695',
    timeZoneId: 'America/Chicago',
  },
  '56029': {
    zip: '56029',
    city: 'Emmons',
    state: 'MN',
    latitude: '43.503746',
    longitude: '-93.491262',
    timeZoneId: 'America/Chicago',
  },
  '56030': {
    zip: '56030',
    city: 'Essig',
    state: 'MN',
    latitude: '44.319831',
    longitude: '-94.580016',
    timeZoneId: 'America/Chicago',
  },
  '56031': {
    zip: '56031',
    city: 'Fairmont',
    state: 'MN',
    latitude: '43.645556',
    longitude: '-94.45926',
    timeZoneId: 'America/Chicago',
  },
  '56032': {
    zip: '56032',
    city: 'Freeborn',
    state: 'MN',
    latitude: '43.767534',
    longitude: '-93.562106',
    timeZoneId: 'America/Chicago',
  },
  '56033': {
    zip: '56033',
    city: 'Frost',
    state: 'MN',
    latitude: '43.587427',
    longitude: '-93.921214',
    timeZoneId: 'America/Chicago',
  },
  '56034': {
    zip: '56034',
    city: 'Garden City',
    state: 'MN',
    latitude: '44.047389',
    longitude: '-94.164236',
    timeZoneId: 'America/Chicago',
  },
  '56035': {
    zip: '56035',
    city: 'Geneva',
    state: 'MN',
    latitude: '43.828762',
    longitude: '-93.269864',
    timeZoneId: 'America/Chicago',
  },
  '56036': {
    zip: '56036',
    city: 'Glenville',
    state: 'MN',
    latitude: '43.571784',
    longitude: '-93.274151',
    timeZoneId: 'America/Chicago',
  },
  '56037': {
    zip: '56037',
    city: 'Good Thunder',
    state: 'MN',
    latitude: '44.004561',
    longitude: '-94.070275',
    timeZoneId: 'America/Chicago',
  },
  '56039': {
    zip: '56039',
    city: 'Granada',
    state: 'MN',
    latitude: '43.700103',
    longitude: '-94.342434',
    timeZoneId: 'America/Chicago',
  },
  '56041': {
    zip: '56041',
    city: 'Hanska',
    state: 'MN',
    latitude: '44.1521',
    longitude: '-94.493356',
    timeZoneId: 'America/Chicago',
  },
  '56042': {
    zip: '56042',
    city: 'Hartland',
    state: 'MN',
    latitude: '43.805754',
    longitude: '-93.490778',
    timeZoneId: 'America/Chicago',
  },
  '56043': {
    zip: '56043',
    city: 'Hayward',
    state: 'MN',
    latitude: '43.646014',
    longitude: '-93.245401',
    timeZoneId: 'America/Chicago',
  },
  '56044': {
    zip: '56044',
    city: 'Henderson',
    state: 'MN',
    latitude: '44.526232',
    longitude: '-93.90574',
    timeZoneId: 'America/Chicago',
  },
  '56045': {
    zip: '56045',
    city: 'Hollandale',
    state: 'MN',
    latitude: '43.758613',
    longitude: '-93.202523',
    timeZoneId: 'America/Chicago',
  },
  '56046': {
    zip: '56046',
    city: 'Hope',
    state: 'MN',
    latitude: '43.97998',
    longitude: '-93.343192',
    timeZoneId: 'America/Chicago',
  },
  '56047': {
    zip: '56047',
    city: 'Huntley',
    state: 'MN',
    latitude: '43.722763',
    longitude: '-94.192025',
    timeZoneId: 'America/Chicago',
  },
  '56048': {
    zip: '56048',
    city: 'Janesville',
    state: 'MN',
    latitude: '44.116681',
    longitude: '-93.707',
    timeZoneId: 'America/Chicago',
  },
  '56050': {
    zip: '56050',
    city: 'Kasota',
    state: 'MN',
    latitude: '44.269587',
    longitude: '-93.966467',
    timeZoneId: 'America/Chicago',
  },
  '56051': {
    zip: '56051',
    city: 'Kiester',
    state: 'MN',
    latitude: '43.538911',
    longitude: '-93.71344',
    timeZoneId: 'America/Chicago',
  },
  '56052': {
    zip: '56052',
    city: 'Kilkenny',
    state: 'MN',
    latitude: '44.334649',
    longitude: '-93.485933',
    timeZoneId: 'America/Chicago',
  },
  '56054': {
    zip: '56054',
    city: 'Lafayette',
    state: 'MN',
    latitude: '44.445577',
    longitude: '-94.394643',
    timeZoneId: 'America/Chicago',
  },
  '56055': {
    zip: '56055',
    city: 'Lake Crystal',
    state: 'MN',
    latitude: '44.11499',
    longitude: '-94.215898',
    timeZoneId: 'America/Chicago',
  },
  '56056': {
    zip: '56056',
    city: 'La Salle',
    state: 'MN',
    latitude: '43.981769',
    longitude: '-94.626721',
    timeZoneId: 'America/Chicago',
  },
  '56057': {
    zip: '56057',
    city: 'Le Center',
    state: 'MN',
    latitude: '44.392242',
    longitude: '-93.735328',
    timeZoneId: 'America/Chicago',
  },
  '56058': {
    zip: '56058',
    city: 'Le Sueur',
    state: 'MN',
    latitude: '44.457962',
    longitude: '-93.905418',
    timeZoneId: 'America/Chicago',
  },
  '56060': {
    zip: '56060',
    city: 'Lewisville',
    state: 'MN',
    latitude: '43.923635',
    longitude: '-94.433285',
    timeZoneId: 'America/Chicago',
  },
  '56062': {
    zip: '56062',
    city: 'Madelia',
    state: 'MN',
    latitude: '44.048762',
    longitude: '-94.418055',
    timeZoneId: 'America/Chicago',
  },
  '56063': {
    zip: '56063',
    city: 'Madison Lake',
    state: 'MN',
    latitude: '44.202965',
    longitude: '-93.811225',
    timeZoneId: 'America/Chicago',
  },
  '56065': {
    zip: '56065',
    city: 'Mapleton',
    state: 'MN',
    latitude: '43.926749',
    longitude: '-93.955128',
    timeZoneId: 'America/Chicago',
  },
  '56068': {
    zip: '56068',
    city: 'Minnesota Lake',
    state: 'MN',
    latitude: '43.840609',
    longitude: '-93.831847',
    timeZoneId: 'America/Chicago',
  },
  '56069': {
    zip: '56069',
    city: 'Montgomery',
    state: 'MN',
    latitude: '44.436114',
    longitude: '-93.587101',
    timeZoneId: 'America/Chicago',
  },
  '56071': {
    zip: '56071',
    city: 'New Prague',
    state: 'MN',
    latitude: '44.562435',
    longitude: '-93.565297',
    timeZoneId: 'America/Chicago',
  },
  '56072': {
    zip: '56072',
    city: 'New Richland',
    state: 'MN',
    latitude: '43.896674',
    longitude: '-93.488835',
    timeZoneId: 'America/Chicago',
  },
  '56073': {
    zip: '56073',
    city: 'New Ulm',
    state: 'MN',
    latitude: '44.312771',
    longitude: '-94.465735',
    timeZoneId: 'America/Chicago',
  },
  '56074': {
    zip: '56074',
    city: 'Nicollet',
    state: 'MN',
    latitude: '44.277125',
    longitude: '-94.189225',
    timeZoneId: 'America/Chicago',
  },
  '56075': {
    zip: '56075',
    city: 'Northrop',
    state: 'MN',
    latitude: '43.720118',
    longitude: '-94.442171',
    timeZoneId: 'America/Chicago',
  },
  '56078': {
    zip: '56078',
    city: 'Pemberton',
    state: 'MN',
    latitude: '44.00621',
    longitude: '-93.784178',
    timeZoneId: 'America/Chicago',
  },
  '56080': {
    zip: '56080',
    city: 'Saint Clair',
    state: 'MN',
    latitude: '44.080602',
    longitude: '-93.853972',
    timeZoneId: 'America/Chicago',
  },
  '56081': {
    zip: '56081',
    city: 'Saint James',
    state: 'MN',
    latitude: '43.984257',
    longitude: '-94.625393',
    timeZoneId: 'America/Chicago',
  },
  '56082': {
    zip: '56082',
    city: 'Saint Peter',
    state: 'MN',
    latitude: '44.330953',
    longitude: '-93.959962',
    timeZoneId: 'America/Chicago',
  },
  '56083': {
    zip: '56083',
    city: 'Sanborn',
    state: 'MN',
    latitude: '44.211909',
    longitude: '-95.127962',
    timeZoneId: 'America/Chicago',
  },
  '56084': {
    zip: '56084',
    city: 'Searles',
    state: 'MN',
    latitude: '44.22729',
    longitude: '-94.433366',
    timeZoneId: 'America/Chicago',
  },
  '56085': {
    zip: '56085',
    city: 'Sleepy Eye',
    state: 'MN',
    latitude: '44.2961',
    longitude: '-94.723907',
    timeZoneId: 'America/Chicago',
  },
  '56087': {
    zip: '56087',
    city: 'Springfield',
    state: 'MN',
    latitude: '44.239012',
    longitude: '-94.979096',
    timeZoneId: 'America/Chicago',
  },
  '56088': {
    zip: '56088',
    city: 'Truman',
    state: 'MN',
    latitude: '43.82874',
    longitude: '-94.436798',
    timeZoneId: 'America/Chicago',
  },
  '56089': {
    zip: '56089',
    city: 'Twin Lakes',
    state: 'MN',
    latitude: '43.560978',
    longitude: '-93.423372',
    timeZoneId: 'America/Chicago',
  },
  '56090': {
    zip: '56090',
    city: 'Vernon Center',
    state: 'MN',
    latitude: '43.960899',
    longitude: '-94.165264',
    timeZoneId: 'America/Chicago',
  },
  '56091': {
    zip: '56091',
    city: 'Waldorf',
    state: 'MN',
    latitude: '43.934133',
    longitude: '-93.697286',
    timeZoneId: 'America/Chicago',
  },
  '56093': {
    zip: '56093',
    city: 'Waseca',
    state: 'MN',
    latitude: '44.080423',
    longitude: '-93.503192',
    timeZoneId: 'America/Chicago',
  },
  '56096': {
    zip: '56096',
    city: 'Waterville',
    state: 'MN',
    latitude: '44.217758',
    longitude: '-93.568226',
    timeZoneId: 'America/Chicago',
  },
  '56097': {
    zip: '56097',
    city: 'Wells',
    state: 'MN',
    latitude: '43.74174',
    longitude: '-93.728328',
    timeZoneId: 'America/Chicago',
  },
  '56098': {
    zip: '56098',
    city: 'Winnebago',
    state: 'MN',
    latitude: '43.766076',
    longitude: '-94.171269',
    timeZoneId: 'America/Chicago',
  },
  '56101': {
    zip: '56101',
    city: 'Windom',
    state: 'MN',
    latitude: '43.8738',
    longitude: '-95.15316',
    timeZoneId: 'America/Chicago',
  },
  '56110': {
    zip: '56110',
    city: 'Adrian',
    state: 'MN',
    latitude: '43.632558',
    longitude: '-95.938582',
    timeZoneId: 'America/Chicago',
  },
  '56111': {
    zip: '56111',
    city: 'Alpha',
    state: 'MN',
    latitude: '43.585344',
    longitude: '-94.914793',
    timeZoneId: 'America/Chicago',
  },
  '56113': {
    zip: '56113',
    city: 'Arco',
    state: 'MN',
    latitude: '44.412524',
    longitude: '-96.199843',
    timeZoneId: 'America/Chicago',
  },
  '56114': {
    zip: '56114',
    city: 'Avoca',
    state: 'MN',
    latitude: '43.946521',
    longitude: '-95.646379',
    timeZoneId: 'America/Chicago',
  },
  '56115': {
    zip: '56115',
    city: 'Balaton',
    state: 'MN',
    latitude: '44.234463',
    longitude: '-95.871098',
    timeZoneId: 'America/Chicago',
  },
  '56116': {
    zip: '56116',
    city: 'Beaver Creek',
    state: 'MN',
    latitude: '43.629992',
    longitude: '-96.373627',
    timeZoneId: 'America/Chicago',
  },
  '56117': {
    zip: '56117',
    city: 'Bigelow',
    state: 'MN',
    latitude: '43.541969',
    longitude: '-95.63089',
    timeZoneId: 'America/Chicago',
  },
  '56118': {
    zip: '56118',
    city: 'Bingham Lake',
    state: 'MN',
    latitude: '43.90911',
    longitude: '-95.047383',
    timeZoneId: 'America/Chicago',
  },
  '56119': {
    zip: '56119',
    city: 'Brewster',
    state: 'MN',
    latitude: '43.696031',
    longitude: '-95.46478',
    timeZoneId: 'America/Chicago',
  },
  '56120': {
    zip: '56120',
    city: 'Butterfield',
    state: 'MN',
    latitude: '43.957764',
    longitude: '-94.792469',
    timeZoneId: 'America/Chicago',
  },
  '56121': {
    zip: '56121',
    city: 'Ceylon',
    state: 'MN',
    latitude: '43.534586',
    longitude: '-94.636503',
    timeZoneId: 'America/Chicago',
  },
  '56122': {
    zip: '56122',
    city: 'Chandler',
    state: 'MN',
    latitude: '43.930909',
    longitude: '-95.947259',
    timeZoneId: 'America/Chicago',
  },
  '56123': {
    zip: '56123',
    city: 'Currie',
    state: 'MN',
    latitude: '44.041029',
    longitude: '-95.435345',
    timeZoneId: 'America/Chicago',
  },
  '56125': {
    zip: '56125',
    city: 'Dovray',
    state: 'MN',
    latitude: '44.052198',
    longitude: '-95.548045',
    timeZoneId: 'America/Chicago',
  },
  '56127': {
    zip: '56127',
    city: 'Dunnell',
    state: 'MN',
    latitude: '43.544606',
    longitude: '-94.794035',
    timeZoneId: 'America/Chicago',
  },
  '56128': {
    zip: '56128',
    city: 'Edgerton',
    state: 'MN',
    latitude: '43.872956',
    longitude: '-96.12956',
    timeZoneId: 'America/Chicago',
  },
  '56129': {
    zip: '56129',
    city: 'Ellsworth',
    state: 'MN',
    latitude: '43.520829',
    longitude: '-96.019571',
    timeZoneId: 'America/Chicago',
  },
  '56131': {
    zip: '56131',
    city: 'Fulda',
    state: 'MN',
    latitude: '43.871151',
    longitude: '-95.602641',
    timeZoneId: 'America/Chicago',
  },
  '56132': {
    zip: '56132',
    city: 'Garvin',
    state: 'MN',
    latitude: '44.216569',
    longitude: '-95.757821',
    timeZoneId: 'America/Chicago',
  },
  '56134': {
    zip: '56134',
    city: 'Hardwick',
    state: 'MN',
    latitude: '43.776007',
    longitude: '-96.201761',
    timeZoneId: 'America/Chicago',
  },
  '56136': {
    zip: '56136',
    city: 'Hendricks',
    state: 'MN',
    latitude: '44.504854',
    longitude: '-96.411555',
    timeZoneId: 'America/Chicago',
  },
  '56137': {
    zip: '56137',
    city: 'Heron Lake',
    state: 'MN',
    latitude: '43.793828',
    longitude: '-95.319084',
    timeZoneId: 'America/Chicago',
  },
  '56138': {
    zip: '56138',
    city: 'Hills',
    state: 'MN',
    latitude: '43.525924',
    longitude: '-96.360077',
    timeZoneId: 'America/Chicago',
  },
  '56139': {
    zip: '56139',
    city: 'Holland',
    state: 'MN',
    latitude: '44.087975',
    longitude: '-96.188304',
    timeZoneId: 'America/Chicago',
  },
  '56140': {
    zip: '56140',
    city: 'Ihlen',
    state: 'MN',
    latitude: '43.908457',
    longitude: '-96.366548',
    timeZoneId: 'America/Chicago',
  },
  '56141': {
    zip: '56141',
    city: 'Iona',
    state: 'MN',
    latitude: '43.914769',
    longitude: '-95.785439',
    timeZoneId: 'America/Chicago',
  },
  '56142': {
    zip: '56142',
    city: 'Ivanhoe',
    state: 'MN',
    latitude: '44.462473',
    longitude: '-96.244829',
    timeZoneId: 'America/Chicago',
  },
  '56143': {
    zip: '56143',
    city: 'Jackson',
    state: 'MN',
    latitude: '43.622545',
    longitude: '-94.991359',
    timeZoneId: 'America/Chicago',
  },
  '56144': {
    zip: '56144',
    city: 'Jasper',
    state: 'MN',
    latitude: '43.854508',
    longitude: '-96.397055',
    timeZoneId: 'America/Chicago',
  },
  '56145': {
    zip: '56145',
    city: 'Jeffers',
    state: 'MN',
    latitude: '44.056447',
    longitude: '-95.196093',
    timeZoneId: 'America/Chicago',
  },
  '56146': {
    zip: '56146',
    city: 'Kanaranzi',
    state: 'MN',
    latitude: '43.548093',
    longitude: '-96.110546',
    timeZoneId: 'America/Chicago',
  },
  '56147': {
    zip: '56147',
    city: 'Kenneth',
    state: 'MN',
    latitude: '43.760221',
    longitude: '-96.115402',
    timeZoneId: 'America/Chicago',
  },
  '56149': {
    zip: '56149',
    city: 'Lake Benton',
    state: 'MN',
    latitude: '44.259146',
    longitude: '-96.285442',
    timeZoneId: 'America/Chicago',
  },
  '56150': {
    zip: '56150',
    city: 'Lakefield',
    state: 'MN',
    latitude: '43.67219',
    longitude: '-95.168243',
    timeZoneId: 'America/Chicago',
  },
  '56151': {
    zip: '56151',
    city: 'Lake Wilson',
    state: 'MN',
    latitude: '44.00826',
    longitude: '-95.90634',
    timeZoneId: 'America/Chicago',
  },
  '56152': {
    zip: '56152',
    city: 'Lamberton',
    state: 'MN',
    latitude: '44.228272',
    longitude: '-95.266139',
    timeZoneId: 'America/Chicago',
  },
  '56153': {
    zip: '56153',
    city: 'Leota',
    state: 'MN',
    latitude: '43.820813',
    longitude: '-95.99524',
    timeZoneId: 'America/Chicago',
  },
  '56155': {
    zip: '56155',
    city: 'Lismore',
    state: 'MN',
    latitude: '43.716167',
    longitude: '-95.994464',
    timeZoneId: 'America/Chicago',
  },
  '56156': {
    zip: '56156',
    city: 'Luverne',
    state: 'MN',
    latitude: '43.659593',
    longitude: '-96.207701',
    timeZoneId: 'America/Chicago',
  },
  '56157': {
    zip: '56157',
    city: 'Lynd',
    state: 'MN',
    latitude: '44.415366',
    longitude: '-95.95853',
    timeZoneId: 'America/Chicago',
  },
  '56158': {
    zip: '56158',
    city: 'Magnolia',
    state: 'MN',
    latitude: '43.63155',
    longitude: '-96.111505',
    timeZoneId: 'America/Chicago',
  },
  '56159': {
    zip: '56159',
    city: 'Mountain Lake',
    state: 'MN',
    latitude: '43.939928',
    longitude: '-94.928701',
    timeZoneId: 'America/Chicago',
  },
  '56160': {
    zip: '56160',
    city: 'Odin',
    state: 'MN',
    latitude: '43.864519',
    longitude: '-94.74331',
    timeZoneId: 'America/Chicago',
  },
  '56161': {
    zip: '56161',
    city: 'Okabena',
    state: 'MN',
    latitude: '43.713379',
    longitude: '-95.345675',
    timeZoneId: 'America/Chicago',
  },
  '56162': {
    zip: '56162',
    city: 'Ormsby',
    state: 'MN',
    latitude: '43.849621',
    longitude: '-94.700156',
    timeZoneId: 'America/Chicago',
  },
  '56164': {
    zip: '56164',
    city: 'Pipestone',
    state: 'MN',
    latitude: '43.994823',
    longitude: '-96.317095',
    timeZoneId: 'America/Chicago',
  },
  '56165': {
    zip: '56165',
    city: 'Reading',
    state: 'MN',
    latitude: '43.712991',
    longitude: '-95.758482',
    timeZoneId: 'America/Chicago',
  },
  '56166': {
    zip: '56166',
    city: 'Revere',
    state: 'MN',
    latitude: '44.218769',
    longitude: '-95.362766',
    timeZoneId: 'America/Chicago',
  },
  '56167': {
    zip: '56167',
    city: 'Round Lake',
    state: 'MN',
    latitude: '43.538215',
    longitude: '-95.465928',
    timeZoneId: 'America/Chicago',
  },
  '56168': {
    zip: '56168',
    city: 'Rushmore',
    state: 'MN',
    latitude: '43.632693',
    longitude: '-95.753484',
    timeZoneId: 'America/Chicago',
  },
  '56169': {
    zip: '56169',
    city: 'Russell',
    state: 'MN',
    latitude: '44.322829',
    longitude: '-95.950801',
    timeZoneId: 'America/Chicago',
  },
  '56170': {
    zip: '56170',
    city: 'Ruthton',
    state: 'MN',
    latitude: '44.18802',
    longitude: '-96.0717',
    timeZoneId: 'America/Chicago',
  },
  '56171': {
    zip: '56171',
    city: 'Sherburn',
    state: 'MN',
    latitude: '43.653934',
    longitude: '-94.726837',
    timeZoneId: 'America/Chicago',
  },
  '56172': {
    zip: '56172',
    city: 'Slayton',
    state: 'MN',
    latitude: '43.983778',
    longitude: '-95.758806',
    timeZoneId: 'America/Chicago',
  },
  '56173': {
    zip: '56173',
    city: 'Steen',
    state: 'MN',
    latitude: '43.545343',
    longitude: '-96.234404',
    timeZoneId: 'America/Chicago',
  },
  '56174': {
    zip: '56174',
    city: 'Storden',
    state: 'MN',
    latitude: '44.036411',
    longitude: '-95.318955',
    timeZoneId: 'America/Chicago',
  },
  '56175': {
    zip: '56175',
    city: 'Tracy',
    state: 'MN',
    latitude: '44.234148',
    longitude: '-95.619944',
    timeZoneId: 'America/Chicago',
  },
  '56176': {
    zip: '56176',
    city: 'Trimont',
    state: 'MN',
    latitude: '43.762998',
    longitude: '-94.711798',
    timeZoneId: 'America/Chicago',
  },
  '56178': {
    zip: '56178',
    city: 'Tyler',
    state: 'MN',
    latitude: '44.274416',
    longitude: '-96.136308',
    timeZoneId: 'America/Chicago',
  },
  '56180': {
    zip: '56180',
    city: 'Walnut Grove',
    state: 'MN',
    latitude: '44.239402',
    longitude: '-95.510201',
    timeZoneId: 'America/Chicago',
  },
  '56181': {
    zip: '56181',
    city: 'Welcome',
    state: 'MN',
    latitude: '43.66971',
    longitude: '-94.621039',
    timeZoneId: 'America/Chicago',
  },
  '56183': {
    zip: '56183',
    city: 'Westbrook',
    state: 'MN',
    latitude: '44.0439',
    longitude: '-95.436643',
    timeZoneId: 'America/Chicago',
  },
  '56185': {
    zip: '56185',
    city: 'Wilmont',
    state: 'MN',
    latitude: '43.77425',
    longitude: '-95.832133',
    timeZoneId: 'America/Chicago',
  },
  '56186': {
    zip: '56186',
    city: 'Woodstock',
    state: 'MN',
    latitude: '43.978957',
    longitude: '-96.123573',
    timeZoneId: 'America/Chicago',
  },
  '56187': {
    zip: '56187',
    city: 'Worthington',
    state: 'MN',
    latitude: '43.622198',
    longitude: '-95.600942',
    timeZoneId: 'America/Chicago',
  },
  '56201': {
    zip: '56201',
    city: 'Willmar',
    state: 'MN',
    latitude: '45.1167',
    longitude: '-95.043173',
    timeZoneId: 'America/Chicago',
  },
  '56207': {
    zip: '56207',
    city: 'Alberta',
    state: 'MN',
    latitude: '45.540417',
    longitude: '-96.053822',
    timeZoneId: 'America/Chicago',
  },
  '56208': {
    zip: '56208',
    city: 'Appleton',
    state: 'MN',
    latitude: '45.202224',
    longitude: '-96.017184',
    timeZoneId: 'America/Chicago',
  },
  '56209': {
    zip: '56209',
    city: 'Atwater',
    state: 'MN',
    latitude: '45.129185',
    longitude: '-94.804925',
    timeZoneId: 'America/Chicago',
  },
  '56210': {
    zip: '56210',
    city: 'Barry',
    state: 'MN',
    latitude: '45.553791',
    longitude: '-96.557068',
    timeZoneId: 'America/Chicago',
  },
  '56211': {
    zip: '56211',
    city: 'Beardsley',
    state: 'MN',
    latitude: '45.55892',
    longitude: '-96.71221',
    timeZoneId: 'America/Chicago',
  },
  '56212': {
    zip: '56212',
    city: 'Bellingham',
    state: 'MN',
    latitude: '45.172093',
    longitude: '-96.326214',
    timeZoneId: 'America/Chicago',
  },
  '56214': {
    zip: '56214',
    city: 'Belview',
    state: 'MN',
    latitude: '44.814489',
    longitude: '-95.543118',
    timeZoneId: 'America/Chicago',
  },
  '56215': {
    zip: '56215',
    city: 'Benson',
    state: 'MN',
    latitude: '45.314278',
    longitude: '-95.601862',
    timeZoneId: 'America/Chicago',
  },
  '56216': {
    zip: '56216',
    city: 'Blomkest',
    state: 'MN',
    latitude: '44.96886',
    longitude: '-95.05644',
    timeZoneId: 'America/Chicago',
  },
  '56218': {
    zip: '56218',
    city: 'Boyd',
    state: 'MN',
    latitude: '44.849747',
    longitude: '-95.971269',
    timeZoneId: 'America/Chicago',
  },
  '56219': {
    zip: '56219',
    city: 'Browns Valley',
    state: 'MN',
    latitude: '45.64997',
    longitude: '-96.722741',
    timeZoneId: 'America/Chicago',
  },
  '56220': {
    zip: '56220',
    city: 'Canby',
    state: 'MN',
    latitude: '44.718043',
    longitude: '-96.267661',
    timeZoneId: 'America/Chicago',
  },
  '56221': {
    zip: '56221',
    city: 'Chokio',
    state: 'MN',
    latitude: '45.572737',
    longitude: '-96.176855',
    timeZoneId: 'America/Chicago',
  },
  '56222': {
    zip: '56222',
    city: 'Clara City',
    state: 'MN',
    latitude: '44.956332',
    longitude: '-95.366841',
    timeZoneId: 'America/Chicago',
  },
  '56223': {
    zip: '56223',
    city: 'Clarkfield',
    state: 'MN',
    latitude: '44.792882',
    longitude: '-95.810742',
    timeZoneId: 'America/Chicago',
  },
  '56224': {
    zip: '56224',
    city: 'Clements',
    state: 'MN',
    latitude: '44.409578',
    longitude: '-95.046022',
    timeZoneId: 'America/Chicago',
  },
  '56225': {
    zip: '56225',
    city: 'Clinton',
    state: 'MN',
    latitude: '45.458909',
    longitude: '-96.435317',
    timeZoneId: 'America/Chicago',
  },
  '56226': {
    zip: '56226',
    city: 'Clontarf',
    state: 'MN',
    latitude: '45.377964',
    longitude: '-95.679393',
    timeZoneId: 'America/Chicago',
  },
  '56227': {
    zip: '56227',
    city: 'Correll',
    state: 'MN',
    latitude: '45.303856',
    longitude: '-96.17707',
    timeZoneId: 'America/Chicago',
  },
  '56228': {
    zip: '56228',
    city: 'Cosmos',
    state: 'MN',
    latitude: '44.939095',
    longitude: '-94.696931',
    timeZoneId: 'America/Chicago',
  },
  '56229': {
    zip: '56229',
    city: 'Cottonwood',
    state: 'MN',
    latitude: '44.606261',
    longitude: '-95.671125',
    timeZoneId: 'America/Chicago',
  },
  '56230': {
    zip: '56230',
    city: 'Danube',
    state: 'MN',
    latitude: '44.806228',
    longitude: '-95.053722',
    timeZoneId: 'America/Chicago',
  },
  '56231': {
    zip: '56231',
    city: 'Danvers',
    state: 'MN',
    latitude: '45.283861',
    longitude: '-95.754014',
    timeZoneId: 'America/Chicago',
  },
  '56232': {
    zip: '56232',
    city: 'Dawson',
    state: 'MN',
    latitude: '44.935077',
    longitude: '-96.064883',
    timeZoneId: 'America/Chicago',
  },
  '56235': {
    zip: '56235',
    city: 'Donnelly',
    state: 'MN',
    latitude: '45.716501',
    longitude: '-96.124586',
    timeZoneId: 'America/Chicago',
  },
  '56236': {
    zip: '56236',
    city: 'Dumont',
    state: 'MN',
    latitude: '45.71661',
    longitude: '-96.423526',
    timeZoneId: 'America/Chicago',
  },
  '56237': {
    zip: '56237',
    city: 'Echo',
    state: 'MN',
    latitude: '44.617955',
    longitude: '-95.413718',
    timeZoneId: 'America/Chicago',
  },
  '56239': {
    zip: '56239',
    city: 'Ghent',
    state: 'MN',
    latitude: '44.514365',
    longitude: '-95.891974',
    timeZoneId: 'America/Chicago',
  },
  '56240': {
    zip: '56240',
    city: 'Graceville',
    state: 'MN',
    latitude: '45.568603',
    longitude: '-96.436884',
    timeZoneId: 'America/Chicago',
  },
  '56241': {
    zip: '56241',
    city: 'Granite Falls',
    state: 'MN',
    latitude: '44.808478',
    longitude: '-95.548738',
    timeZoneId: 'America/Chicago',
  },
  '56243': {
    zip: '56243',
    city: 'Grove City',
    state: 'MN',
    latitude: '45.152697',
    longitude: '-94.681564',
    timeZoneId: 'America/Chicago',
  },
  '56244': {
    zip: '56244',
    city: 'Hancock',
    state: 'MN',
    latitude: '45.495918',
    longitude: '-95.791984',
    timeZoneId: 'America/Chicago',
  },
  '56245': {
    zip: '56245',
    city: 'Hanley Falls',
    state: 'MN',
    latitude: '44.695514',
    longitude: '-95.620333',
    timeZoneId: 'America/Chicago',
  },
  '56248': {
    zip: '56248',
    city: 'Herman',
    state: 'MN',
    latitude: '45.80862',
    longitude: '-96.138498',
    timeZoneId: 'America/Chicago',
  },
  '56249': {
    zip: '56249',
    city: 'Holloway',
    state: 'MN',
    latitude: '45.282645',
    longitude: '-95.934264',
    timeZoneId: 'America/Chicago',
  },
  '56251': {
    zip: '56251',
    city: 'Kandiyohi',
    state: 'MN',
    latitude: '45.143967',
    longitude: '-94.926175',
    timeZoneId: 'America/Chicago',
  },
  '56252': {
    zip: '56252',
    city: 'Kerkhoven',
    state: 'MN',
    latitude: '45.238951',
    longitude: '-95.311927',
    timeZoneId: 'America/Chicago',
  },
  '56253': {
    zip: '56253',
    city: 'Lake Lillian',
    state: 'MN',
    latitude: '44.971457',
    longitude: '-94.888107',
    timeZoneId: 'America/Chicago',
  },
  '56255': {
    zip: '56255',
    city: 'Lucan',
    state: 'MN',
    latitude: '44.408035',
    longitude: '-95.41079',
    timeZoneId: 'America/Chicago',
  },
  '56256': {
    zip: '56256',
    city: 'Madison',
    state: 'MN',
    latitude: '45.166111',
    longitude: '-96.170833',
    timeZoneId: 'America/Chicago',
  },
  '56257': {
    zip: '56257',
    city: 'Marietta',
    state: 'MN',
    latitude: '44.931601',
    longitude: '-96.397949',
    timeZoneId: 'America/Chicago',
  },
  '56258': {
    zip: '56258',
    city: 'Marshall',
    state: 'MN',
    latitude: '44.445234',
    longitude: '-95.78262',
    timeZoneId: 'America/Chicago',
  },
  '56260': {
    zip: '56260',
    city: 'Maynard',
    state: 'MN',
    latitude: '44.904798',
    longitude: '-95.466408',
    timeZoneId: 'America/Chicago',
  },
  '56262': {
    zip: '56262',
    city: 'Milan',
    state: 'MN',
    latitude: '45.106428',
    longitude: '-95.913279',
    timeZoneId: 'America/Chicago',
  },
  '56263': {
    zip: '56263',
    city: 'Milroy',
    state: 'MN',
    latitude: '44.450251',
    longitude: '-95.550562',
    timeZoneId: 'America/Chicago',
  },
  '56264': {
    zip: '56264',
    city: 'Minneota',
    state: 'MN',
    latitude: '44.563139',
    longitude: '-95.985774',
    timeZoneId: 'America/Chicago',
  },
  '56265': {
    zip: '56265',
    city: 'Montevideo',
    state: 'MN',
    latitude: '44.947794',
    longitude: '-95.714191',
    timeZoneId: 'America/Chicago',
  },
  '56266': {
    zip: '56266',
    city: 'Morgan',
    state: 'MN',
    latitude: '44.415886',
    longitude: '-94.924541',
    timeZoneId: 'America/Chicago',
  },
  '56267': {
    zip: '56267',
    city: 'Morris',
    state: 'MN',
    latitude: '45.58327',
    longitude: '-95.913998',
    timeZoneId: 'America/Chicago',
  },
  '56270': {
    zip: '56270',
    city: 'Morton',
    state: 'MN',
    latitude: '44.58799',
    longitude: '-95.043274',
    timeZoneId: 'America/Chicago',
  },
  '56271': {
    zip: '56271',
    city: 'Murdock',
    state: 'MN',
    latitude: '45.22481',
    longitude: '-95.391809',
    timeZoneId: 'America/Chicago',
  },
  '56273': {
    zip: '56273',
    city: 'New London',
    state: 'MN',
    latitude: '45.310623',
    longitude: '-94.991718',
    timeZoneId: 'America/Chicago',
  },
  '56274': {
    zip: '56274',
    city: 'Norcross',
    state: 'MN',
    latitude: '45.89103',
    longitude: '-96.129385',
    timeZoneId: 'America/Chicago',
  },
  '56276': {
    zip: '56276',
    city: 'Odessa',
    state: 'MN',
    latitude: '45.327256',
    longitude: '-96.301095',
    timeZoneId: 'America/Chicago',
  },
  '56277': {
    zip: '56277',
    city: 'Olivia',
    state: 'MN',
    latitude: '44.773937',
    longitude: '-94.988415',
    timeZoneId: 'America/Chicago',
  },
  '56278': {
    zip: '56278',
    city: 'Ortonville',
    state: 'MN',
    latitude: '45.315111',
    longitude: '-96.44236',
    timeZoneId: 'America/Chicago',
  },
  '56279': {
    zip: '56279',
    city: 'Pennock',
    state: 'MN',
    latitude: '45.21537',
    longitude: '-95.169095',
    timeZoneId: 'America/Chicago',
  },
  '56280': {
    zip: '56280',
    city: 'Porter',
    state: 'MN',
    latitude: '44.672238',
    longitude: '-96.154789',
    timeZoneId: 'America/Chicago',
  },
  '56281': {
    zip: '56281',
    city: 'Prinsburg',
    state: 'MN',
    latitude: '44.949845',
    longitude: '-95.177085',
    timeZoneId: 'America/Chicago',
  },
  '56282': {
    zip: '56282',
    city: 'Raymond',
    state: 'MN',
    latitude: '45.020902',
    longitude: '-95.192812',
    timeZoneId: 'America/Chicago',
  },
  '56283': {
    zip: '56283',
    city: 'Redwood Falls',
    state: 'MN',
    latitude: '44.540219',
    longitude: '-95.111345',
    timeZoneId: 'America/Chicago',
  },
  '56284': {
    zip: '56284',
    city: 'Renville',
    state: 'MN',
    latitude: '44.91252',
    longitude: '-95.190513',
    timeZoneId: 'America/Chicago',
  },
  '56285': {
    zip: '56285',
    city: 'Sacred Heart',
    state: 'MN',
    latitude: '44.782863',
    longitude: '-95.36302',
    timeZoneId: 'America/Chicago',
  },
  '56287': {
    zip: '56287',
    city: 'Seaforth',
    state: 'MN',
    latitude: '44.485083',
    longitude: '-95.31194',
    timeZoneId: 'America/Chicago',
  },
  '56288': {
    zip: '56288',
    city: 'Spicer',
    state: 'MN',
    latitude: '45.246236',
    longitude: '-94.950221',
    timeZoneId: 'America/Chicago',
  },
  '56289': {
    zip: '56289',
    city: 'Sunburg',
    state: 'MN',
    latitude: '45.333396',
    longitude: '-95.184601',
    timeZoneId: 'America/Chicago',
  },
  '56291': {
    zip: '56291',
    city: 'Taunton',
    state: 'MN',
    latitude: '44.589436',
    longitude: '-96.053997',
    timeZoneId: 'America/Chicago',
  },
  '56292': {
    zip: '56292',
    city: 'Vesta',
    state: 'MN',
    latitude: '44.49709',
    longitude: '-95.41052',
    timeZoneId: 'America/Chicago',
  },
  '56293': {
    zip: '56293',
    city: 'Wabasso',
    state: 'MN',
    latitude: '44.402248',
    longitude: '-95.248733',
    timeZoneId: 'America/Chicago',
  },
  '56294': {
    zip: '56294',
    city: 'Wanda',
    state: 'MN',
    latitude: '44.320054',
    longitude: '-95.178001',
    timeZoneId: 'America/Chicago',
  },
  '56295': {
    zip: '56295',
    city: 'Watson',
    state: 'MN',
    latitude: '45.024558',
    longitude: '-95.789905',
    timeZoneId: 'America/Chicago',
  },
  '56296': {
    zip: '56296',
    city: 'Wheaton',
    state: 'MN',
    latitude: '45.804163',
    longitude: '-96.496006',
    timeZoneId: 'America/Chicago',
  },
  '56297': {
    zip: '56297',
    city: 'Wood Lake',
    state: 'MN',
    latitude: '44.65168',
    longitude: '-95.5411',
    timeZoneId: 'America/Chicago',
  },
  '56301': {
    zip: '56301',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.531141',
    longitude: '-94.209162',
    timeZoneId: 'America/Chicago',
  },
  '56302': {
    zip: '56302',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.560955',
    longitude: '-94.164449',
    timeZoneId: 'America/Chicago',
  },
  '56303': {
    zip: '56303',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.583801',
    longitude: '-94.2037',
    timeZoneId: 'America/Chicago',
  },
  '56304': {
    zip: '56304',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.527177',
    longitude: '-94.067207',
    timeZoneId: 'America/Chicago',
  },
  '56307': {
    zip: '56307',
    city: 'Albany',
    state: 'MN',
    latitude: '45.637483',
    longitude: '-94.577068',
    timeZoneId: 'America/Chicago',
  },
  '56308': {
    zip: '56308',
    city: 'Alexandria',
    state: 'MN',
    latitude: '45.892797',
    longitude: '-95.365967',
    timeZoneId: 'America/Chicago',
  },
  '56309': {
    zip: '56309',
    city: 'Ashby',
    state: 'MN',
    latitude: '46.092873',
    longitude: '-95.819457',
    timeZoneId: 'America/Chicago',
  },
  '56310': {
    zip: '56310',
    city: 'Avon',
    state: 'MN',
    latitude: '45.624787',
    longitude: '-94.445931',
    timeZoneId: 'America/Chicago',
  },
  '56311': {
    zip: '56311',
    city: 'Barrett',
    state: 'MN',
    latitude: '45.891548',
    longitude: '-95.883332',
    timeZoneId: 'America/Chicago',
  },
  '56312': {
    zip: '56312',
    city: 'Belgrade',
    state: 'MN',
    latitude: '45.486795',
    longitude: '-94.95527',
    timeZoneId: 'America/Chicago',
  },
  '56313': {
    zip: '56313',
    city: 'Bock',
    state: 'MN',
    latitude: '45.785033',
    longitude: '-93.551708',
    timeZoneId: 'America/Chicago',
  },
  '56314': {
    zip: '56314',
    city: 'Bowlus',
    state: 'MN',
    latitude: '45.770146',
    longitude: '-94.355231',
    timeZoneId: 'America/Chicago',
  },
  '56315': {
    zip: '56315',
    city: 'Brandon',
    state: 'MN',
    latitude: '45.966274',
    longitude: '-95.593817',
    timeZoneId: 'America/Chicago',
  },
  '56316': {
    zip: '56316',
    city: 'Brooten',
    state: 'MN',
    latitude: '45.527831',
    longitude: '-95.089575',
    timeZoneId: 'America/Chicago',
  },
  '56317': {
    zip: '56317',
    city: 'Buckman',
    state: 'MN',
    latitude: '45.897683',
    longitude: '-94.092696',
    timeZoneId: 'America/Chicago',
  },
  '56318': {
    zip: '56318',
    city: 'Burtrum',
    state: 'MN',
    latitude: '45.866944',
    longitude: '-94.686134',
    timeZoneId: 'America/Chicago',
  },
  '56319': {
    zip: '56319',
    city: 'Carlos',
    state: 'MN',
    latitude: '45.969695',
    longitude: '-95.293448',
    timeZoneId: 'America/Chicago',
  },
  '56320': {
    zip: '56320',
    city: 'Cold Spring',
    state: 'MN',
    latitude: '45.460008',
    longitude: '-94.426927',
    timeZoneId: 'America/Chicago',
  },
  '56321': {
    zip: '56321',
    city: 'Collegeville',
    state: 'MN',
    latitude: '45.573343',
    longitude: '-94.368311',
    timeZoneId: 'America/Chicago',
  },
  '56323': {
    zip: '56323',
    city: 'Cyrus',
    state: 'MN',
    latitude: '45.614317',
    longitude: '-95.739191',
    timeZoneId: 'America/Chicago',
  },
  '56324': {
    zip: '56324',
    city: 'Dalton',
    state: 'MN',
    latitude: '46.151717',
    longitude: '-95.830472',
    timeZoneId: 'America/Chicago',
  },
  '56325': {
    zip: '56325',
    city: 'Elrosa',
    state: 'MN',
    latitude: '45.562811',
    longitude: '-94.945219',
    timeZoneId: 'America/Chicago',
  },
  '56326': {
    zip: '56326',
    city: 'Evansville',
    state: 'MN',
    latitude: '46.006155',
    longitude: '-95.687882',
    timeZoneId: 'America/Chicago',
  },
  '56327': {
    zip: '56327',
    city: 'Farwell',
    state: 'MN',
    latitude: '45.769314',
    longitude: '-95.626709',
    timeZoneId: 'America/Chicago',
  },
  '56328': {
    zip: '56328',
    city: 'Flensburg',
    state: 'MN',
    latitude: '45.952423',
    longitude: '-94.533435',
    timeZoneId: 'America/Chicago',
  },
  '56329': {
    zip: '56329',
    city: 'Foley',
    state: 'MN',
    latitude: '45.689579',
    longitude: '-93.912459',
    timeZoneId: 'America/Chicago',
  },
  '56330': {
    zip: '56330',
    city: 'Foreston',
    state: 'MN',
    latitude: '45.759384',
    longitude: '-93.777218',
    timeZoneId: 'America/Chicago',
  },
  '56331': {
    zip: '56331',
    city: 'Freeport',
    state: 'MN',
    latitude: '45.681666',
    longitude: '-94.680948',
    timeZoneId: 'America/Chicago',
  },
  '56332': {
    zip: '56332',
    city: 'Garfield',
    state: 'MN',
    latitude: '46.019226',
    longitude: '-95.458464',
    timeZoneId: 'America/Chicago',
  },
  '56333': {
    zip: '56333',
    city: 'Gilman',
    state: 'MN',
    latitude: '45.743733',
    longitude: '-93.965639',
    timeZoneId: 'America/Chicago',
  },
  '56334': {
    zip: '56334',
    city: 'Glenwood',
    state: 'MN',
    latitude: '45.651444',
    longitude: '-95.386131',
    timeZoneId: 'America/Chicago',
  },
  '56335': {
    zip: '56335',
    city: 'Greenwald',
    state: 'MN',
    latitude: '45.598995',
    longitude: '-94.859424',
    timeZoneId: 'America/Chicago',
  },
  '56336': {
    zip: '56336',
    city: 'Grey Eagle',
    state: 'MN',
    latitude: '45.820975',
    longitude: '-94.747578',
    timeZoneId: 'America/Chicago',
  },
  '56338': {
    zip: '56338',
    city: 'Hillman',
    state: 'MN',
    latitude: '46.030871',
    longitude: '-93.858263',
    timeZoneId: 'America/Chicago',
  },
  '56339': {
    zip: '56339',
    city: 'Hoffman',
    state: 'MN',
    latitude: '45.83041',
    longitude: '-95.793251',
    timeZoneId: 'America/Chicago',
  },
  '56340': {
    zip: '56340',
    city: 'Holdingford',
    state: 'MN',
    latitude: '45.73493',
    longitude: '-94.431926',
    timeZoneId: 'America/Chicago',
  },
  '56341': {
    zip: '56341',
    city: 'Holmes City',
    state: 'MN',
    latitude: '45.807434',
    longitude: '-95.573123',
    timeZoneId: 'America/Chicago',
  },
  '56342': {
    zip: '56342',
    city: 'Isle',
    state: 'MN',
    latitude: '46.199853',
    longitude: '-93.490646',
    timeZoneId: 'America/Chicago',
  },
  '56343': {
    zip: '56343',
    city: 'Kensington',
    state: 'MN',
    latitude: '45.847542',
    longitude: '-95.693324',
    timeZoneId: 'America/Chicago',
  },
  '56344': {
    zip: '56344',
    city: 'Lastrup',
    state: 'MN',
    latitude: '46.038336',
    longitude: '-94.063923',
    timeZoneId: 'America/Chicago',
  },
  '56345': {
    zip: '56345',
    city: 'Little Falls',
    state: 'MN',
    latitude: '45.979362',
    longitude: '-94.362403',
    timeZoneId: 'America/Chicago',
  },
  '56347': {
    zip: '56347',
    city: 'Long Prairie',
    state: 'MN',
    latitude: '45.863889',
    longitude: '-94.918056',
    timeZoneId: 'America/Chicago',
  },
  '56349': {
    zip: '56349',
    city: 'Lowry',
    state: 'MN',
    latitude: '45.702683',
    longitude: '-95.524007',
    timeZoneId: 'America/Chicago',
  },
  '56350': {
    zip: '56350',
    city: 'Mc Grath',
    state: 'MN',
    latitude: '46.289865',
    longitude: '-93.239993',
    timeZoneId: 'America/Chicago',
  },
  '56352': {
    zip: '56352',
    city: 'Melrose',
    state: 'MN',
    latitude: '45.652875',
    longitude: '-94.805558',
    timeZoneId: 'America/Chicago',
  },
  '56353': {
    zip: '56353',
    city: 'Milaca',
    state: 'MN',
    latitude: '45.752989',
    longitude: '-93.650614',
    timeZoneId: 'America/Chicago',
  },
  '56354': {
    zip: '56354',
    city: 'Miltona',
    state: 'MN',
    latitude: '46.046559',
    longitude: '-95.294043',
    timeZoneId: 'America/Chicago',
  },
  '56355': {
    zip: '56355',
    city: 'Nelson',
    state: 'MN',
    latitude: '45.933714',
    longitude: '-95.218886',
    timeZoneId: 'America/Chicago',
  },
  '56356': {
    zip: '56356',
    city: 'New Munich',
    state: 'MN',
    latitude: '45.630092',
    longitude: '-94.753033',
    timeZoneId: 'America/Chicago',
  },
  '56357': {
    zip: '56357',
    city: 'Oak Park',
    state: 'MN',
    latitude: '45.664722',
    longitude: '-93.909444',
    timeZoneId: 'America/Chicago',
  },
  '56358': {
    zip: '56358',
    city: 'Ogilvie',
    state: 'MN',
    latitude: '45.830614',
    longitude: '-93.425896',
    timeZoneId: 'America/Chicago',
  },
  '56359': {
    zip: '56359',
    city: 'Onamia',
    state: 'MN',
    latitude: '46.074699',
    longitude: '-93.662677',
    timeZoneId: 'America/Chicago',
  },
  '56360': {
    zip: '56360',
    city: 'Osakis',
    state: 'MN',
    latitude: '45.869297',
    longitude: '-95.165575',
    timeZoneId: 'America/Chicago',
  },
  '56361': {
    zip: '56361',
    city: 'Parkers Prairie',
    state: 'MN',
    latitude: '46.153362',
    longitude: '-95.330911',
    timeZoneId: 'America/Chicago',
  },
  '56362': {
    zip: '56362',
    city: 'Paynesville',
    state: 'MN',
    latitude: '45.407807',
    longitude: '-94.699248',
    timeZoneId: 'America/Chicago',
  },
  '56363': {
    zip: '56363',
    city: 'Pease',
    state: 'MN',
    latitude: '45.697754',
    longitude: '-93.647912',
    timeZoneId: 'America/Chicago',
  },
  '56364': {
    zip: '56364',
    city: 'Pierz',
    state: 'MN',
    latitude: '45.978574',
    longitude: '-94.097687',
    timeZoneId: 'America/Chicago',
  },
  '56367': {
    zip: '56367',
    city: 'Rice',
    state: 'MN',
    latitude: '45.753912',
    longitude: '-94.154601',
    timeZoneId: 'America/Chicago',
  },
  '56368': {
    zip: '56368',
    city: 'Richmond',
    state: 'MN',
    latitude: '45.443713',
    longitude: '-94.525876',
    timeZoneId: 'America/Chicago',
  },
  '56369': {
    zip: '56369',
    city: 'Rockville',
    state: 'MN',
    latitude: '45.466387',
    longitude: '-94.342233',
    timeZoneId: 'America/Chicago',
  },
  '56371': {
    zip: '56371',
    city: 'Roscoe',
    state: 'MN',
    latitude: '45.433706',
    longitude: '-94.637291',
    timeZoneId: 'America/Chicago',
  },
  '56372': {
    zip: '56372',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.562988',
    longitude: '-94.218716',
    timeZoneId: 'America/Chicago',
  },
  '56373': {
    zip: '56373',
    city: 'Royalton',
    state: 'MN',
    latitude: '45.832001',
    longitude: '-94.289638',
    timeZoneId: 'America/Chicago',
  },
  '56374': {
    zip: '56374',
    city: 'Saint Joseph',
    state: 'MN',
    latitude: '45.586745',
    longitude: '-94.33003',
    timeZoneId: 'America/Chicago',
  },
  '56375': {
    zip: '56375',
    city: 'Saint Stephen',
    state: 'MN',
    latitude: '45.700959',
    longitude: '-94.266485',
    timeZoneId: 'America/Chicago',
  },
  '56376': {
    zip: '56376',
    city: 'Saint Martin',
    state: 'MN',
    latitude: '45.498646',
    longitude: '-94.663967',
    timeZoneId: 'America/Chicago',
  },
  '56377': {
    zip: '56377',
    city: 'Sartell',
    state: 'MN',
    latitude: '45.61926',
    longitude: '-94.207387',
    timeZoneId: 'America/Chicago',
  },
  '56378': {
    zip: '56378',
    city: 'Sauk Centre',
    state: 'MN',
    latitude: '45.714514',
    longitude: '-94.959722',
    timeZoneId: 'America/Chicago',
  },
  '56379': {
    zip: '56379',
    city: 'Sauk Rapids',
    state: 'MN',
    latitude: '45.616973',
    longitude: '-94.118408',
    timeZoneId: 'America/Chicago',
  },
  '56381': {
    zip: '56381',
    city: 'Starbuck',
    state: 'MN',
    latitude: '45.613548',
    longitude: '-95.530926',
    timeZoneId: 'America/Chicago',
  },
  '56382': {
    zip: '56382',
    city: 'Swanville',
    state: 'MN',
    latitude: '45.946803',
    longitude: '-94.625252',
    timeZoneId: 'America/Chicago',
  },
  '56384': {
    zip: '56384',
    city: 'Upsala',
    state: 'MN',
    latitude: '45.81094',
    longitude: '-94.580977',
    timeZoneId: 'America/Chicago',
  },
  '56385': {
    zip: '56385',
    city: 'Villard',
    state: 'MN',
    latitude: '45.688264',
    longitude: '-95.116517',
    timeZoneId: 'America/Chicago',
  },
  '56386': {
    zip: '56386',
    city: 'Wahkon',
    state: 'MN',
    latitude: '46.067388',
    longitude: '-93.494578',
    timeZoneId: 'America/Chicago',
  },
  '56387': {
    zip: '56387',
    city: 'Waite Park',
    state: 'MN',
    latitude: '45.552241',
    longitude: '-94.216617',
    timeZoneId: 'America/Chicago',
  },
  '56388': {
    zip: '56388',
    city: 'Waite Park',
    state: 'MN',
    latitude: '45.557519',
    longitude: '-94.224792',
    timeZoneId: 'America/Chicago',
  },
  '56389': {
    zip: '56389',
    city: 'West Union',
    state: 'MN',
    latitude: '45.798359',
    longitude: '-95.079178',
    timeZoneId: 'America/Chicago',
  },
  '56393': {
    zip: '56393',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.567555',
    longitude: '-94.217111',
    timeZoneId: 'America/Chicago',
  },
  '56395': {
    zip: '56395',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.561027',
    longitude: '-94.161673',
    timeZoneId: 'America/Chicago',
  },
  '56396': {
    zip: '56396',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.568127',
    longitude: '-94.214279',
    timeZoneId: 'America/Chicago',
  },
  '56397': {
    zip: '56397',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.482753',
    longitude: '-94.250285',
    timeZoneId: 'America/Chicago',
  },
  '56398': {
    zip: '56398',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.482713',
    longitude: '-94.248423',
    timeZoneId: 'America/Chicago',
  },
  '56399': {
    zip: '56399',
    city: 'Saint Cloud',
    state: 'MN',
    latitude: '45.549763',
    longitude: '-94.227348',
    timeZoneId: 'America/Chicago',
  },
  '56401': {
    zip: '56401',
    city: 'Brainerd',
    state: 'MN',
    latitude: '46.34172',
    longitude: '-94.14228',
    timeZoneId: 'America/Chicago',
  },
  '56425': {
    zip: '56425',
    city: 'Baxter',
    state: 'MN',
    latitude: '46.34156',
    longitude: '-94.288961',
    timeZoneId: 'America/Chicago',
  },
  '56431': {
    zip: '56431',
    city: 'Aitkin',
    state: 'MN',
    latitude: '46.529143',
    longitude: '-93.707325',
    timeZoneId: 'America/Chicago',
  },
  '56433': {
    zip: '56433',
    city: 'Akeley',
    state: 'MN',
    latitude: '47.022069',
    longitude: '-94.724608',
    timeZoneId: 'America/Chicago',
  },
  '56434': {
    zip: '56434',
    city: 'Aldrich',
    state: 'MN',
    latitude: '46.411535',
    longitude: '-94.969127',
    timeZoneId: 'America/Chicago',
  },
  '56435': {
    zip: '56435',
    city: 'Backus',
    state: 'MN',
    latitude: '46.855877',
    longitude: '-94.369375',
    timeZoneId: 'America/Chicago',
  },
  '56436': {
    zip: '56436',
    city: 'Benedict',
    state: 'MN',
    latitude: '47.157652',
    longitude: '-94.690431',
    timeZoneId: 'America/Chicago',
  },
  '56437': {
    zip: '56437',
    city: 'Bertha',
    state: 'MN',
    latitude: '46.215448',
    longitude: '-95.049762',
    timeZoneId: 'America/Chicago',
  },
  '56438': {
    zip: '56438',
    city: 'Browerville',
    state: 'MN',
    latitude: '46.083198',
    longitude: '-94.869079',
    timeZoneId: 'America/Chicago',
  },
  '56440': {
    zip: '56440',
    city: 'Clarissa',
    state: 'MN',
    latitude: '46.132806',
    longitude: '-94.948964',
    timeZoneId: 'America/Chicago',
  },
  '56441': {
    zip: '56441',
    city: 'Crosby',
    state: 'MN',
    latitude: '46.482415',
    longitude: '-93.958313',
    timeZoneId: 'America/Chicago',
  },
  '56442': {
    zip: '56442',
    city: 'Crosslake',
    state: 'MN',
    latitude: '46.6773',
    longitude: '-94.1121',
    timeZoneId: 'America/Chicago',
  },
  '56443': {
    zip: '56443',
    city: 'Cushing',
    state: 'MN',
    latitude: '46.196146',
    longitude: '-94.570357',
    timeZoneId: 'America/Chicago',
  },
  '56444': {
    zip: '56444',
    city: 'Deerwood',
    state: 'MN',
    latitude: '46.473714',
    longitude: '-93.904656',
    timeZoneId: 'America/Chicago',
  },
  '56446': {
    zip: '56446',
    city: 'Eagle Bend',
    state: 'MN',
    latitude: '46.16201',
    longitude: '-95.027241',
    timeZoneId: 'America/Chicago',
  },
  '56447': {
    zip: '56447',
    city: 'Emily',
    state: 'MN',
    latitude: '46.761467',
    longitude: '-93.903243',
    timeZoneId: 'America/Chicago',
  },
  '56448': {
    zip: '56448',
    city: 'Fifty Lakes',
    state: 'MN',
    latitude: '46.80171',
    longitude: '-94.017035',
    timeZoneId: 'America/Chicago',
  },
  '56449': {
    zip: '56449',
    city: 'Fort Ripley',
    state: 'MN',
    latitude: '46.204333',
    longitude: '-94.228316',
    timeZoneId: 'America/Chicago',
  },
  '56450': {
    zip: '56450',
    city: 'Garrison',
    state: 'MN',
    latitude: '46.240872',
    longitude: '-93.936433',
    timeZoneId: 'America/Chicago',
  },
  '56452': {
    zip: '56452',
    city: 'Hackensack',
    state: 'MN',
    latitude: '47.000063',
    longitude: '-94.499213',
    timeZoneId: 'America/Chicago',
  },
  '56453': {
    zip: '56453',
    city: 'Hewitt',
    state: 'MN',
    latitude: '46.324158',
    longitude: '-95.029177',
    timeZoneId: 'America/Chicago',
  },
  '56455': {
    zip: '56455',
    city: 'Ironton',
    state: 'MN',
    latitude: '46.478503',
    longitude: '-93.978413',
    timeZoneId: 'America/Chicago',
  },
  '56456': {
    zip: '56456',
    city: 'Jenkins',
    state: 'MN',
    latitude: '46.649886',
    longitude: '-94.335802',
    timeZoneId: 'America/Chicago',
  },
  '56458': {
    zip: '56458',
    city: 'Lake George',
    state: 'MN',
    latitude: '47.282516',
    longitude: '-95.048993',
    timeZoneId: 'America/Chicago',
  },
  '56459': {
    zip: '56459',
    city: 'Lake Hubert',
    state: 'MN',
    latitude: '46.502147',
    longitude: '-94.257255',
    timeZoneId: 'America/Chicago',
  },
  '56461': {
    zip: '56461',
    city: 'Laporte',
    state: 'MN',
    latitude: '47.22702',
    longitude: '-94.76688',
    timeZoneId: 'America/Chicago',
  },
  '56464': {
    zip: '56464',
    city: 'Menahga',
    state: 'MN',
    latitude: '46.761393',
    longitude: '-94.974444',
    timeZoneId: 'America/Chicago',
  },
  '56465': {
    zip: '56465',
    city: 'Merrifield',
    state: 'MN',
    latitude: '46.462831',
    longitude: '-94.175144',
    timeZoneId: 'America/Chicago',
  },
  '56466': {
    zip: '56466',
    city: 'Motley',
    state: 'MN',
    latitude: '46.32696',
    longitude: '-94.62486',
    timeZoneId: 'America/Chicago',
  },
  '56467': {
    zip: '56467',
    city: 'Nevis',
    state: 'MN',
    latitude: '46.876449',
    longitude: '-94.807054',
    timeZoneId: 'America/Chicago',
  },
  '56468': {
    zip: '56468',
    city: 'Nisswa',
    state: 'MN',
    latitude: '46.504936',
    longitude: '-94.283424',
    timeZoneId: 'America/Chicago',
  },
  '56469': {
    zip: '56469',
    city: 'Palisade',
    state: 'MN',
    latitude: '46.726598',
    longitude: '-93.612719',
    timeZoneId: 'America/Chicago',
  },
  '56470': {
    zip: '56470',
    city: 'Park Rapids',
    state: 'MN',
    latitude: '46.921086',
    longitude: '-95.056585',
    timeZoneId: 'America/Chicago',
  },
  '56472': {
    zip: '56472',
    city: 'Pequot Lakes',
    state: 'MN',
    latitude: '46.61592',
    longitude: '-94.28142',
    timeZoneId: 'America/Chicago',
  },
  '56473': {
    zip: '56473',
    city: 'Pillager',
    state: 'MN',
    latitude: '46.295738',
    longitude: '-94.330254',
    timeZoneId: 'America/Chicago',
  },
  '56474': {
    zip: '56474',
    city: 'Pine River',
    state: 'MN',
    latitude: '46.745939',
    longitude: '-94.372764',
    timeZoneId: 'America/Chicago',
  },
  '56475': {
    zip: '56475',
    city: 'Randall',
    state: 'MN',
    latitude: '46.064416',
    longitude: '-94.520309',
    timeZoneId: 'America/Chicago',
  },
  '56477': {
    zip: '56477',
    city: 'Sebeka',
    state: 'MN',
    latitude: '46.627873',
    longitude: '-95.089864',
    timeZoneId: 'America/Chicago',
  },
  '56479': {
    zip: '56479',
    city: 'Staples',
    state: 'MN',
    latitude: '46.353921',
    longitude: '-94.795227',
    timeZoneId: 'America/Chicago',
  },
  '56481': {
    zip: '56481',
    city: 'Verndale',
    state: 'MN',
    latitude: '46.399714',
    longitude: '-95.014769',
    timeZoneId: 'America/Chicago',
  },
  '56482': {
    zip: '56482',
    city: 'Wadena',
    state: 'MN',
    latitude: '46.439072',
    longitude: '-95.137559',
    timeZoneId: 'America/Chicago',
  },
  '56484': {
    zip: '56484',
    city: 'Walker',
    state: 'MN',
    latitude: '47.124271',
    longitude: '-94.607274',
    timeZoneId: 'America/Chicago',
  },
  '56501': {
    zip: '56501',
    city: 'Detroit Lakes',
    state: 'MN',
    latitude: '46.815249',
    longitude: '-95.846334',
    timeZoneId: 'America/Chicago',
  },
  '56502': {
    zip: '56502',
    city: 'Detroit Lakes',
    state: 'MN',
    latitude: '46.820385',
    longitude: '-95.843944',
    timeZoneId: 'America/Chicago',
  },
  '56510': {
    zip: '56510',
    city: 'Ada',
    state: 'MN',
    latitude: '47.298022',
    longitude: '-96.510903',
    timeZoneId: 'America/Chicago',
  },
  '56511': {
    zip: '56511',
    city: 'Audubon',
    state: 'MN',
    latitude: '46.860418',
    longitude: '-95.980967',
    timeZoneId: 'America/Chicago',
  },
  '56514': {
    zip: '56514',
    city: 'Barnesville',
    state: 'MN',
    latitude: '46.67004',
    longitude: '-96.39156',
    timeZoneId: 'America/Chicago',
  },
  '56515': {
    zip: '56515',
    city: 'Battle Lake',
    state: 'MN',
    latitude: '46.287269',
    longitude: '-95.713207',
    timeZoneId: 'America/Chicago',
  },
  '56516': {
    zip: '56516',
    city: 'Bejou',
    state: 'MN',
    latitude: '47.453105',
    longitude: '-95.935493',
    timeZoneId: 'America/Chicago',
  },
  '56517': {
    zip: '56517',
    city: 'Beltrami',
    state: 'MN',
    latitude: '47.800102',
    longitude: '-96.433917',
    timeZoneId: 'America/Chicago',
  },
  '56518': {
    zip: '56518',
    city: 'Bluffton',
    state: 'MN',
    latitude: '46.497703',
    longitude: '-95.220066',
    timeZoneId: 'America/Chicago',
  },
  '56519': {
    zip: '56519',
    city: 'Borup',
    state: 'MN',
    latitude: '47.196075',
    longitude: '-96.561026',
    timeZoneId: 'America/Chicago',
  },
  '56520': {
    zip: '56520',
    city: 'Breckenridge',
    state: 'MN',
    latitude: '46.268423',
    longitude: '-96.587538',
    timeZoneId: 'America/Chicago',
  },
  '56521': {
    zip: '56521',
    city: 'Callaway',
    state: 'MN',
    latitude: '47.022579',
    longitude: '-95.999418',
    timeZoneId: 'America/Chicago',
  },
  '56522': {
    zip: '56522',
    city: 'Campbell',
    state: 'MN',
    latitude: '46.1259',
    longitude: '-96.44178',
    timeZoneId: 'America/Chicago',
  },
  '56523': {
    zip: '56523',
    city: 'Climax',
    state: 'MN',
    latitude: '47.63946',
    longitude: '-96.79866',
    timeZoneId: 'America/Chicago',
  },
  '56524': {
    zip: '56524',
    city: 'Clitherall',
    state: 'MN',
    latitude: '46.327298',
    longitude: '-95.592298',
    timeZoneId: 'America/Chicago',
  },
  '56525': {
    zip: '56525',
    city: 'Comstock',
    state: 'MN',
    latitude: '46.677765',
    longitude: '-96.742324',
    timeZoneId: 'America/Chicago',
  },
  '56527': {
    zip: '56527',
    city: 'Deer Creek',
    state: 'MN',
    latitude: '46.414918',
    longitude: '-95.281404',
    timeZoneId: 'America/Chicago',
  },
  '56528': {
    zip: '56528',
    city: 'Dent',
    state: 'MN',
    latitude: '46.542914',
    longitude: '-95.781515',
    timeZoneId: 'America/Chicago',
  },
  '56529': {
    zip: '56529',
    city: 'Dilworth',
    state: 'MN',
    latitude: '46.879898',
    longitude: '-96.702553',
    timeZoneId: 'America/Chicago',
  },
  '56531': {
    zip: '56531',
    city: 'Elbow Lake',
    state: 'MN',
    latitude: '45.994893',
    longitude: '-95.969068',
    timeZoneId: 'America/Chicago',
  },
  '56533': {
    zip: '56533',
    city: 'Elizabeth',
    state: 'MN',
    latitude: '46.411743',
    longitude: '-96.094139',
    timeZoneId: 'America/Chicago',
  },
  '56534': {
    zip: '56534',
    city: 'Erhard',
    state: 'MN',
    latitude: '46.4992',
    longitude: '-96.031562',
    timeZoneId: 'America/Chicago',
  },
  '56535': {
    zip: '56535',
    city: 'Erskine',
    state: 'MN',
    latitude: '47.668141',
    longitude: '-96.006004',
    timeZoneId: 'America/Chicago',
  },
  '56536': {
    zip: '56536',
    city: 'Felton',
    state: 'MN',
    latitude: '47.050669',
    longitude: '-96.495429',
    timeZoneId: 'America/Chicago',
  },
  '56537': {
    zip: '56537',
    city: 'Fergus Falls',
    state: 'MN',
    latitude: '46.29102',
    longitude: '-96.07482',
    timeZoneId: 'America/Chicago',
  },
  '56538': {
    zip: '56538',
    city: 'Fergus Falls',
    state: 'MN',
    latitude: '46.282796',
    longitude: '-96.074809',
    timeZoneId: 'America/Chicago',
  },
  '56540': {
    zip: '56540',
    city: 'Fertile',
    state: 'MN',
    latitude: '47.5248',
    longitude: '-96.24066',
    timeZoneId: 'America/Chicago',
  },
  '56541': {
    zip: '56541',
    city: 'Flom',
    state: 'MN',
    latitude: '47.167634',
    longitude: '-96.12921',
    timeZoneId: 'America/Chicago',
  },
  '56542': {
    zip: '56542',
    city: 'Fosston',
    state: 'MN',
    latitude: '47.579072',
    longitude: '-95.751821',
    timeZoneId: 'America/Chicago',
  },
  '56543': {
    zip: '56543',
    city: 'Foxhome',
    state: 'MN',
    latitude: '46.255126',
    longitude: '-96.324827',
    timeZoneId: 'America/Chicago',
  },
  '56544': {
    zip: '56544',
    city: 'Frazee',
    state: 'MN',
    latitude: '46.736683',
    longitude: '-95.718421',
    timeZoneId: 'America/Chicago',
  },
  '56545': {
    zip: '56545',
    city: 'Gary',
    state: 'MN',
    latitude: '47.36916',
    longitude: '-96.193999',
    timeZoneId: 'America/Chicago',
  },
  '56546': {
    zip: '56546',
    city: 'Georgetown',
    state: 'MN',
    latitude: '47.108619',
    longitude: '-96.693214',
    timeZoneId: 'America/Chicago',
  },
  '56547': {
    zip: '56547',
    city: 'Glyndon',
    state: 'MN',
    latitude: '46.874486',
    longitude: '-96.578028',
    timeZoneId: 'America/Chicago',
  },
  '56548': {
    zip: '56548',
    city: 'Halstad',
    state: 'MN',
    latitude: '47.351884',
    longitude: '-96.82553',
    timeZoneId: 'America/Chicago',
  },
  '56549': {
    zip: '56549',
    city: 'Hawley',
    state: 'MN',
    latitude: '46.74',
    longitude: '-96.237778',
    timeZoneId: 'America/Chicago',
  },
  '56550': {
    zip: '56550',
    city: 'Hendrum',
    state: 'MN',
    latitude: '47.260273',
    longitude: '-96.809725',
    timeZoneId: 'America/Chicago',
  },
  '56551': {
    zip: '56551',
    city: 'Henning',
    state: 'MN',
    latitude: '46.322593',
    longitude: '-95.346305',
    timeZoneId: 'America/Chicago',
  },
  '56552': {
    zip: '56552',
    city: 'Hitterdal',
    state: 'MN',
    latitude: '47.020788',
    longitude: '-96.31665',
    timeZoneId: 'America/Chicago',
  },
  '56553': {
    zip: '56553',
    city: 'Kent',
    state: 'MN',
    latitude: '46.426018',
    longitude: '-96.677907',
    timeZoneId: 'America/Chicago',
  },
  '56554': {
    zip: '56554',
    city: 'Lake Park',
    state: 'MN',
    latitude: '46.826541',
    longitude: '-96.08163',
    timeZoneId: 'America/Chicago',
  },
  '56556': {
    zip: '56556',
    city: 'McIntosh',
    state: 'MN',
    latitude: '47.65818',
    longitude: '-95.8899',
    timeZoneId: 'America/Chicago',
  },
  '56557': {
    zip: '56557',
    city: 'Mahnomen',
    state: 'MN',
    latitude: '47.32404',
    longitude: '-95.92938',
    timeZoneId: 'America/Chicago',
  },
  '56560': {
    zip: '56560',
    city: 'Moorhead',
    state: 'MN',
    latitude: '46.89012',
    longitude: '-96.74472',
    timeZoneId: 'America/Chicago',
  },
  '56561': {
    zip: '56561',
    city: 'Moorhead',
    state: 'MN',
    latitude: '46.926751',
    longitude: '-96.715269',
    timeZoneId: 'America/Chicago',
  },
  '56562': {
    zip: '56562',
    city: 'Moorhead',
    state: 'MN',
    latitude: '46.862982',
    longitude: '-96.763781',
    timeZoneId: 'America/Chicago',
  },
  '56563': {
    zip: '56563',
    city: 'Moorhead',
    state: 'MN',
    latitude: '46.867452',
    longitude: '-96.756248',
    timeZoneId: 'America/Chicago',
  },
  '56565': {
    zip: '56565',
    city: 'Nashua',
    state: 'MN',
    latitude: '46.059997',
    longitude: '-96.345073',
    timeZoneId: 'America/Chicago',
  },
  '56566': {
    zip: '56566',
    city: 'Naytahwaush',
    state: 'MN',
    latitude: '47.236957',
    longitude: '-95.615631',
    timeZoneId: 'America/Chicago',
  },
  '56567': {
    zip: '56567',
    city: 'New York Mills',
    state: 'MN',
    latitude: '46.688056',
    longitude: '-95.352778',
    timeZoneId: 'America/Chicago',
  },
  '56568': {
    zip: '56568',
    city: 'Nielsville',
    state: 'MN',
    latitude: '47.543914',
    longitude: '-96.717293',
    timeZoneId: 'America/Chicago',
  },
  '56569': {
    zip: '56569',
    city: 'Ogema',
    state: 'MN',
    latitude: '47.107446',
    longitude: '-95.998438',
    timeZoneId: 'America/Chicago',
  },
  '56570': {
    zip: '56570',
    city: 'Osage',
    state: 'MN',
    latitude: '46.932684',
    longitude: '-95.224283',
    timeZoneId: 'America/Chicago',
  },
  '56571': {
    zip: '56571',
    city: 'Ottertail',
    state: 'MN',
    latitude: '46.410156',
    longitude: '-95.533267',
    timeZoneId: 'America/Chicago',
  },
  '56572': {
    zip: '56572',
    city: 'Pelican Rapids',
    state: 'MN',
    latitude: '46.61832',
    longitude: '-96.0729',
    timeZoneId: 'America/Chicago',
  },
  '56573': {
    zip: '56573',
    city: 'Perham',
    state: 'MN',
    latitude: '46.596594',
    longitude: '-95.573756',
    timeZoneId: 'America/Chicago',
  },
  '56574': {
    zip: '56574',
    city: 'Perley',
    state: 'MN',
    latitude: '47.194663',
    longitude: '-96.759572',
    timeZoneId: 'America/Chicago',
  },
  '56575': {
    zip: '56575',
    city: 'Ponsford',
    state: 'MN',
    latitude: '47.039423',
    longitude: '-95.308645',
    timeZoneId: 'America/Chicago',
  },
  '56576': {
    zip: '56576',
    city: 'Richville',
    state: 'MN',
    latitude: '46.496075',
    longitude: '-95.593354',
    timeZoneId: 'America/Chicago',
  },
  '56577': {
    zip: '56577',
    city: 'Richwood',
    state: 'MN',
    latitude: '46.93832',
    longitude: '-95.86232',
    timeZoneId: 'America/Chicago',
  },
  '56578': {
    zip: '56578',
    city: 'Rochert',
    state: 'MN',
    latitude: '46.8716',
    longitude: '-95.595803',
    timeZoneId: 'America/Chicago',
  },
  '56579': {
    zip: '56579',
    city: 'Rothsay',
    state: 'MN',
    latitude: '46.471961',
    longitude: '-96.27635',
    timeZoneId: 'America/Chicago',
  },
  '56580': {
    zip: '56580',
    city: 'Sabin',
    state: 'MN',
    latitude: '46.774844',
    longitude: '-96.655765',
    timeZoneId: 'America/Chicago',
  },
  '56581': {
    zip: '56581',
    city: 'Shelly',
    state: 'MN',
    latitude: '47.457649',
    longitude: '-96.720417',
    timeZoneId: 'America/Chicago',
  },
  '56583': {
    zip: '56583',
    city: 'Tintah',
    state: 'MN',
    latitude: '45.98628',
    longitude: '-96.40578',
    timeZoneId: 'America/Chicago',
  },
  '56584': {
    zip: '56584',
    city: 'Twin Valley',
    state: 'MN',
    latitude: '47.284811',
    longitude: '-96.264019',
    timeZoneId: 'America/Chicago',
  },
  '56585': {
    zip: '56585',
    city: 'Ulen',
    state: 'MN',
    latitude: '47.076843',
    longitude: '-96.257061',
    timeZoneId: 'America/Chicago',
  },
  '56586': {
    zip: '56586',
    city: 'Underwood',
    state: 'MN',
    latitude: '46.326075',
    longitude: '-95.839007',
    timeZoneId: 'America/Chicago',
  },
  '56587': {
    zip: '56587',
    city: 'Vergas',
    state: 'MN',
    latitude: '46.671193',
    longitude: '-95.793552',
    timeZoneId: 'America/Chicago',
  },
  '56588': {
    zip: '56588',
    city: 'Vining',
    state: 'MN',
    latitude: '46.240869',
    longitude: '-95.583181',
    timeZoneId: 'America/Chicago',
  },
  '56589': {
    zip: '56589',
    city: 'Waubun',
    state: 'MN',
    latitude: '47.194333',
    longitude: '-95.870248',
    timeZoneId: 'America/Chicago',
  },
  '56590': {
    zip: '56590',
    city: 'Wendell',
    state: 'MN',
    latitude: '46.061875',
    longitude: '-96.139321',
    timeZoneId: 'America/Chicago',
  },
  '56591': {
    zip: '56591',
    city: 'White Earth',
    state: 'MN',
    latitude: '47.096509',
    longitude: '-95.84292',
    timeZoneId: 'America/Chicago',
  },
  '56592': {
    zip: '56592',
    city: 'Winger',
    state: 'MN',
    latitude: '47.630484',
    longitude: '-95.888684',
    timeZoneId: 'America/Chicago',
  },
  '56593': {
    zip: '56593',
    city: 'Wolf Lake',
    state: 'MN',
    latitude: '46.837826',
    longitude: '-95.369866',
    timeZoneId: 'America/Chicago',
  },
  '56594': {
    zip: '56594',
    city: 'Wolverton',
    state: 'MN',
    latitude: '46.544595',
    longitude: '-96.57554',
    timeZoneId: 'America/Chicago',
  },
  '56601': {
    zip: '56601',
    city: 'Bemidji',
    state: 'MN',
    latitude: '47.504512',
    longitude: '-94.8576',
    timeZoneId: 'America/Chicago',
  },
  '56619': {
    zip: '56619',
    city: 'Bemidji',
    state: 'MN',
    latitude: '47.473727',
    longitude: '-94.879123',
    timeZoneId: 'America/Chicago',
  },
  '56621': {
    zip: '56621',
    city: 'Bagley',
    state: 'MN',
    latitude: '47.522156',
    longitude: '-95.39789',
    timeZoneId: 'America/Chicago',
  },
  '56623': {
    zip: '56623',
    city: 'Baudette',
    state: 'MN',
    latitude: '48.690833',
    longitude: '-94.436111',
    timeZoneId: 'America/Chicago',
  },
  '56626': {
    zip: '56626',
    city: 'Bena',
    state: 'MN',
    latitude: '47.315217',
    longitude: '-94.206922',
    timeZoneId: 'America/Chicago',
  },
  '56627': {
    zip: '56627',
    city: 'Big Falls',
    state: 'MN',
    latitude: '48.157929',
    longitude: '-93.863003',
    timeZoneId: 'America/Chicago',
  },
  '56628': {
    zip: '56628',
    city: 'Bigfork',
    state: 'MN',
    latitude: '47.76625',
    longitude: '-93.707164',
    timeZoneId: 'America/Chicago',
  },
  '56629': {
    zip: '56629',
    city: 'Birchdale',
    state: 'MN',
    latitude: '48.564892',
    longitude: '-94.239618',
    timeZoneId: 'America/Chicago',
  },
  '56630': {
    zip: '56630',
    city: 'Blackduck',
    state: 'MN',
    latitude: '47.814758',
    longitude: '-94.539269',
    timeZoneId: 'America/Chicago',
  },
  '56631': {
    zip: '56631',
    city: 'Bowstring',
    state: 'MN',
    latitude: '47.541969',
    longitude: '-93.798456',
    timeZoneId: 'America/Chicago',
  },
  '56633': {
    zip: '56633',
    city: 'Cass Lake',
    state: 'MN',
    latitude: '47.379804',
    longitude: '-94.604123',
    timeZoneId: 'America/Chicago',
  },
  '56634': {
    zip: '56634',
    city: 'Clearbrook',
    state: 'MN',
    latitude: '47.831879',
    longitude: '-95.319135',
    timeZoneId: 'America/Chicago',
  },
  '56636': {
    zip: '56636',
    city: 'Deer River',
    state: 'MN',
    latitude: '47.37996',
    longitude: '-93.86136',
    timeZoneId: 'America/Chicago',
  },
  '56637': {
    zip: '56637',
    city: 'Talmoon',
    state: 'MN',
    latitude: '47.589712',
    longitude: '-93.775457',
    timeZoneId: 'America/Chicago',
  },
  '56639': {
    zip: '56639',
    city: 'Effie',
    state: 'MN',
    latitude: '47.852565',
    longitude: '-93.622901',
    timeZoneId: 'America/Chicago',
  },
  '56641': {
    zip: '56641',
    city: 'Federal Dam',
    state: 'MN',
    latitude: '47.197495',
    longitude: '-94.238032',
    timeZoneId: 'America/Chicago',
  },
  '56644': {
    zip: '56644',
    city: 'Gonvick',
    state: 'MN',
    latitude: '47.849231',
    longitude: '-95.48064',
    timeZoneId: 'America/Chicago',
  },
  '56646': {
    zip: '56646',
    city: 'Gully',
    state: 'MN',
    latitude: '47.803463',
    longitude: '-95.64351',
    timeZoneId: 'America/Chicago',
  },
  '56647': {
    zip: '56647',
    city: 'Hines',
    state: 'MN',
    latitude: '47.758791',
    longitude: '-94.680589',
    timeZoneId: 'America/Chicago',
  },
  '56649': {
    zip: '56649',
    city: 'International Falls',
    state: 'MN',
    latitude: '48.5822',
    longitude: '-93.411492',
    timeZoneId: 'America/Chicago',
  },
  '56650': {
    zip: '56650',
    city: 'Kelliher',
    state: 'MN',
    latitude: '47.957836',
    longitude: '-94.483902',
    timeZoneId: 'America/Chicago',
  },
  '56651': {
    zip: '56651',
    city: 'Lengby',
    state: 'MN',
    latitude: '47.544425',
    longitude: '-95.618999',
    timeZoneId: 'America/Chicago',
  },
  '56652': {
    zip: '56652',
    city: 'Leonard',
    state: 'MN',
    latitude: '47.63808',
    longitude: '-95.3835',
    timeZoneId: 'America/Chicago',
  },
  '56653': {
    zip: '56653',
    city: 'Littlefork',
    state: 'MN',
    latitude: '48.36276',
    longitude: '-93.50772',
    timeZoneId: 'America/Chicago',
  },
  '56654': {
    zip: '56654',
    city: 'Loman',
    state: 'MN',
    latitude: '48.517326',
    longitude: '-93.899334',
    timeZoneId: 'America/Chicago',
  },
  '56655': {
    zip: '56655',
    city: 'Longville',
    state: 'MN',
    latitude: '47.067212',
    longitude: '-94.161631',
    timeZoneId: 'America/Chicago',
  },
  '56657': {
    zip: '56657',
    city: 'Marcell',
    state: 'MN',
    latitude: '47.637327',
    longitude: '-93.668318',
    timeZoneId: 'America/Chicago',
  },
  '56658': {
    zip: '56658',
    city: 'Margie',
    state: 'MN',
    latitude: '48.095867',
    longitude: '-93.939313',
    timeZoneId: 'America/Chicago',
  },
  '56659': {
    zip: '56659',
    city: 'Max',
    state: 'MN',
    latitude: '47.629486',
    longitude: '-93.984984',
    timeZoneId: 'America/Chicago',
  },
  '56660': {
    zip: '56660',
    city: 'Mizpah',
    state: 'MN',
    latitude: '47.98781',
    longitude: '-94.214799',
    timeZoneId: 'America/Chicago',
  },
  '56661': {
    zip: '56661',
    city: 'Northome',
    state: 'MN',
    latitude: '47.828166',
    longitude: '-94.14347',
    timeZoneId: 'America/Chicago',
  },
  '56662': {
    zip: '56662',
    city: 'Outing',
    state: 'MN',
    latitude: '46.846424',
    longitude: '-93.901398',
    timeZoneId: 'America/Chicago',
  },
  '56663': {
    zip: '56663',
    city: 'Pennington',
    state: 'MN',
    latitude: '47.455328',
    longitude: '-94.478035',
    timeZoneId: 'America/Chicago',
  },
  '56666': {
    zip: '56666',
    city: 'Ponemah',
    state: 'MN',
    latitude: '48.074235',
    longitude: '-94.849018',
    timeZoneId: 'America/Chicago',
  },
  '56667': {
    zip: '56667',
    city: 'Puposky',
    state: 'MN',
    latitude: '47.74632',
    longitude: '-95.00622',
    timeZoneId: 'America/Chicago',
  },
  '56668': {
    zip: '56668',
    city: 'Ranier',
    state: 'MN',
    latitude: '48.613378',
    longitude: '-93.349817',
    timeZoneId: 'America/Chicago',
  },
  '56669': {
    zip: '56669',
    city: 'Kabetogama',
    state: 'MN',
    latitude: '48.41118',
    longitude: '-93.1947',
    timeZoneId: 'America/Chicago',
  },
  '56670': {
    zip: '56670',
    city: 'Redby',
    state: 'MN',
    latitude: '47.859549',
    longitude: '-94.921538',
    timeZoneId: 'America/Chicago',
  },
  '56671': {
    zip: '56671',
    city: 'Redlake',
    state: 'MN',
    latitude: '47.85083',
    longitude: '-95.060653',
    timeZoneId: 'America/Chicago',
  },
  '56672': {
    zip: '56672',
    city: 'Remer',
    state: 'MN',
    latitude: '47.087607',
    longitude: '-93.899234',
    timeZoneId: 'America/Chicago',
  },
  '56673': {
    zip: '56673',
    city: 'Roosevelt',
    state: 'MN',
    latitude: '48.779677',
    longitude: '-95.251697',
    timeZoneId: 'America/Chicago',
  },
  '56676': {
    zip: '56676',
    city: 'Shevlin',
    state: 'MN',
    latitude: '47.38345',
    longitude: '-95.23297',
    timeZoneId: 'America/Chicago',
  },
  '56678': {
    zip: '56678',
    city: 'Solway',
    state: 'MN',
    latitude: '47.5554',
    longitude: '-95.13282',
    timeZoneId: 'America/Chicago',
  },
  '56679': {
    zip: '56679',
    city: 'South International Falls',
    state: 'MN',
    latitude: '48.587388',
    longitude: '-93.399406',
    timeZoneId: 'America/Chicago',
  },
  '56680': {
    zip: '56680',
    city: 'Spring Lake',
    state: 'MN',
    latitude: '47.633333',
    longitude: '-93.966667',
    timeZoneId: 'America/Chicago',
  },
  '56681': {
    zip: '56681',
    city: 'Squaw Lake',
    state: 'MN',
    latitude: '47.631447',
    longitude: '-94.176224',
    timeZoneId: 'America/Chicago',
  },
  '56683': {
    zip: '56683',
    city: 'Tenstrike',
    state: 'MN',
    latitude: '47.661284',
    longitude: '-94.681663',
    timeZoneId: 'America/Chicago',
  },
  '56684': {
    zip: '56684',
    city: 'Trail',
    state: 'MN',
    latitude: '47.76612',
    longitude: '-95.76342',
    timeZoneId: 'America/Chicago',
  },
  '56685': {
    zip: '56685',
    city: 'Waskish',
    state: 'MN',
    latitude: '48.196353',
    longitude: '-94.484298',
    timeZoneId: 'America/Chicago',
  },
  '56686': {
    zip: '56686',
    city: 'Williams',
    state: 'MN',
    latitude: '48.977222',
    longitude: '-94.959722',
    timeZoneId: 'America/Chicago',
  },
  '56687': {
    zip: '56687',
    city: 'Wilton',
    state: 'MN',
    latitude: '47.52245',
    longitude: '-94.996355',
    timeZoneId: 'America/Chicago',
  },
  '56688': {
    zip: '56688',
    city: 'Wirt',
    state: 'MN',
    latitude: '47.718698',
    longitude: '-93.962032',
    timeZoneId: 'America/Chicago',
  },
  '56701': {
    zip: '56701',
    city: 'Thief River Falls',
    state: 'MN',
    latitude: '48.115399',
    longitude: '-96.17417',
    timeZoneId: 'America/Chicago',
  },
  '56710': {
    zip: '56710',
    city: 'Alvarado',
    state: 'MN',
    latitude: '48.228671',
    longitude: '-96.955952',
    timeZoneId: 'America/Chicago',
  },
  '56711': {
    zip: '56711',
    city: 'Angle Inlet',
    state: 'MN',
    latitude: '49.309708',
    longitude: '-95.090831',
    timeZoneId: 'America/Chicago',
  },
  '56713': {
    zip: '56713',
    city: 'Argyle',
    state: 'MN',
    latitude: '48.332402',
    longitude: '-96.821678',
    timeZoneId: 'America/Chicago',
  },
  '56714': {
    zip: '56714',
    city: 'Badger',
    state: 'MN',
    latitude: '48.857405',
    longitude: '-96.209079',
    timeZoneId: 'America/Chicago',
  },
  '56715': {
    zip: '56715',
    city: 'Brooks',
    state: 'MN',
    latitude: '47.803547',
    longitude: '-96.030632',
    timeZoneId: 'America/Chicago',
  },
  '56716': {
    zip: '56716',
    city: 'Crookston',
    state: 'MN',
    latitude: '47.774782',
    longitude: '-96.6083',
    timeZoneId: 'America/Chicago',
  },
  '56720': {
    zip: '56720',
    city: 'Donaldson',
    state: 'MN',
    latitude: '48.586837',
    longitude: '-96.863331',
    timeZoneId: 'America/Chicago',
  },
  '56721': {
    zip: '56721',
    city: 'East Grand Forks',
    state: 'MN',
    latitude: '47.929448',
    longitude: '-97.017008',
    timeZoneId: 'America/Chicago',
  },
  '56722': {
    zip: '56722',
    city: 'Euclid',
    state: 'MN',
    latitude: '48.047293',
    longitude: '-96.890781',
    timeZoneId: 'America/Chicago',
  },
  '56723': {
    zip: '56723',
    city: 'Fisher',
    state: 'MN',
    latitude: '47.800325',
    longitude: '-96.797924',
    timeZoneId: 'America/Chicago',
  },
  '56724': {
    zip: '56724',
    city: 'Gatzke',
    state: 'MN',
    latitude: '48.413315',
    longitude: '-95.794605',
    timeZoneId: 'America/Chicago',
  },
  '56725': {
    zip: '56725',
    city: 'Goodridge',
    state: 'MN',
    latitude: '48.061717',
    longitude: '-95.716096',
    timeZoneId: 'America/Chicago',
  },
  '56726': {
    zip: '56726',
    city: 'Greenbush',
    state: 'MN',
    latitude: '48.72249',
    longitude: '-96.209221',
    timeZoneId: 'America/Chicago',
  },
  '56727': {
    zip: '56727',
    city: 'Grygla',
    state: 'MN',
    latitude: '48.281501',
    longitude: '-95.37848',
    timeZoneId: 'America/Chicago',
  },
  '56728': {
    zip: '56728',
    city: 'Hallock',
    state: 'MN',
    latitude: '48.774233',
    longitude: '-96.944459',
    timeZoneId: 'America/Chicago',
  },
  '56729': {
    zip: '56729',
    city: 'Halma',
    state: 'MN',
    latitude: '48.672798',
    longitude: '-96.583668',
    timeZoneId: 'America/Chicago',
  },
  '56731': {
    zip: '56731',
    city: 'Humboldt',
    state: 'MN',
    latitude: '48.920986',
    longitude: '-97.092786',
    timeZoneId: 'America/Chicago',
  },
  '56732': {
    zip: '56732',
    city: 'Karlstad',
    state: 'MN',
    latitude: '48.579667',
    longitude: '-96.528558',
    timeZoneId: 'America/Chicago',
  },
  '56733': {
    zip: '56733',
    city: 'Kennedy',
    state: 'MN',
    latitude: '48.63454',
    longitude: '-96.978652',
    timeZoneId: 'America/Chicago',
  },
  '56734': {
    zip: '56734',
    city: 'Lake Bronson',
    state: 'MN',
    latitude: '48.845443',
    longitude: '-96.562653',
    timeZoneId: 'America/Chicago',
  },
  '56735': {
    zip: '56735',
    city: 'Lancaster',
    state: 'MN',
    latitude: '48.912614',
    longitude: '-96.843421',
    timeZoneId: 'America/Chicago',
  },
  '56736': {
    zip: '56736',
    city: 'Mentor',
    state: 'MN',
    latitude: '47.671395',
    longitude: '-96.211116',
    timeZoneId: 'America/Chicago',
  },
  '56737': {
    zip: '56737',
    city: 'Middle River',
    state: 'MN',
    latitude: '48.459145',
    longitude: '-96.060323',
    timeZoneId: 'America/Chicago',
  },
  '56738': {
    zip: '56738',
    city: 'Newfolden',
    state: 'MN',
    latitude: '48.2844',
    longitude: '-96.22728',
    timeZoneId: 'America/Chicago',
  },
  '56740': {
    zip: '56740',
    city: 'Noyes',
    state: 'MN',
    latitude: '48.977594',
    longitude: '-97.142343',
    timeZoneId: 'America/Chicago',
  },
  '56741': {
    zip: '56741',
    city: 'Oak Island',
    state: 'MN',
    latitude: '49.31028',
    longitude: '-94.84978',
    timeZoneId: 'America/Chicago',
  },
  '56742': {
    zip: '56742',
    city: 'Oklee',
    state: 'MN',
    latitude: '47.835019',
    longitude: '-95.852182',
    timeZoneId: 'America/Chicago',
  },
  '56744': {
    zip: '56744',
    city: 'Oslo',
    state: 'MN',
    latitude: '48.226185',
    longitude: '-97.08487',
    timeZoneId: 'America/Chicago',
  },
  '56748': {
    zip: '56748',
    city: 'Plummer',
    state: 'MN',
    latitude: '47.903815',
    longitude: '-95.901783',
    timeZoneId: 'America/Chicago',
  },
  '56750': {
    zip: '56750',
    city: 'Red Lake Falls',
    state: 'MN',
    latitude: '47.882061',
    longitude: '-96.271559',
    timeZoneId: 'America/Chicago',
  },
  '56751': {
    zip: '56751',
    city: 'Roseau',
    state: 'MN',
    latitude: '48.846666',
    longitude: '-95.757088',
    timeZoneId: 'America/Chicago',
  },
  '56754': {
    zip: '56754',
    city: 'Saint Hilaire',
    state: 'MN',
    latitude: '47.999422',
    longitude: '-96.277601',
    timeZoneId: 'America/Chicago',
  },
  '56755': {
    zip: '56755',
    city: 'Saint Vincent',
    state: 'MN',
    latitude: '48.936466',
    longitude: '-97.165652',
    timeZoneId: 'America/Chicago',
  },
  '56756': {
    zip: '56756',
    city: 'Salol',
    state: 'MN',
    latitude: '48.842985',
    longitude: '-95.549737',
    timeZoneId: 'America/Chicago',
  },
  '56757': {
    zip: '56757',
    city: 'Stephen',
    state: 'MN',
    latitude: '48.467526',
    longitude: '-96.872246',
    timeZoneId: 'America/Chicago',
  },
  '56758': {
    zip: '56758',
    city: 'Strandquist',
    state: 'MN',
    latitude: '48.454725',
    longitude: '-96.453374',
    timeZoneId: 'America/Chicago',
  },
  '56759': {
    zip: '56759',
    city: 'Strathcona',
    state: 'MN',
    latitude: '48.623727',
    longitude: '-96.114734',
    timeZoneId: 'America/Chicago',
  },
  '56760': {
    zip: '56760',
    city: 'Viking',
    state: 'MN',
    latitude: '48.241794',
    longitude: '-96.498626',
    timeZoneId: 'America/Chicago',
  },
  '56761': {
    zip: '56761',
    city: 'Wannaska',
    state: 'MN',
    latitude: '48.630234',
    longitude: '-95.733971',
    timeZoneId: 'America/Chicago',
  },
  '56762': {
    zip: '56762',
    city: 'Warren',
    state: 'MN',
    latitude: '48.197279',
    longitude: '-96.769251',
    timeZoneId: 'America/Chicago',
  },
  '56763': {
    zip: '56763',
    city: 'Warroad',
    state: 'MN',
    latitude: '48.908263',
    longitude: '-95.317658',
    timeZoneId: 'America/Chicago',
  },
  '56901': {
    zip: '56901',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56902': {
    zip: '56902',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56904': {
    zip: '56904',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56908': {
    zip: '56908',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.929366',
    longitude: '-77.033614',
    timeZoneId: 'America/New_York',
  },
  '56915': {
    zip: '56915',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.896263',
    longitude: '-77.03649',
    timeZoneId: 'America/New_York',
  },
  '56920': {
    zip: '56920',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.895216',
    longitude: '-77.036514',
    timeZoneId: 'America/New_York',
  },
  '56933': {
    zip: '56933',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56935': {
    zip: '56935',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56944': {
    zip: '56944',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56945': {
    zip: '56945',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56950': {
    zip: '56950',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56965': {
    zip: '56965',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56966': {
    zip: '56966',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56967': {
    zip: '56967',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56968': {
    zip: '56968',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56969': {
    zip: '56969',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56970': {
    zip: '56970',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56972': {
    zip: '56972',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.899101',
    longitude: '-77.028999',
    timeZoneId: 'America/New_York',
  },
  '56998': {
    zip: '56998',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.8952',
    longitude: '-77.0365',
    timeZoneId: 'America/New_York',
  },
  '56999': {
    zip: '56999',
    city: 'Parcel Return Service',
    state: 'DC',
    latitude: '38.895391',
    longitude: '-77.036656',
    timeZoneId: 'America/New_York',
  },
  '57001': {
    zip: '57001',
    city: 'Alcester',
    state: 'SD',
    latitude: '43.02193',
    longitude: '-96.622613',
    timeZoneId: 'America/Chicago',
  },
  '57002': {
    zip: '57002',
    city: 'Aurora',
    state: 'SD',
    latitude: '44.288735',
    longitude: '-96.713704',
    timeZoneId: 'America/Chicago',
  },
  '57003': {
    zip: '57003',
    city: 'Baltic',
    state: 'SD',
    latitude: '43.749361',
    longitude: '-96.73413',
    timeZoneId: 'America/Chicago',
  },
  '57004': {
    zip: '57004',
    city: 'Beresford',
    state: 'SD',
    latitude: '43.0842',
    longitude: '-96.78066',
    timeZoneId: 'America/Chicago',
  },
  '57005': {
    zip: '57005',
    city: 'Brandon',
    state: 'SD',
    latitude: '43.587111',
    longitude: '-96.580716',
    timeZoneId: 'America/Chicago',
  },
  '57006': {
    zip: '57006',
    city: 'Brookings',
    state: 'SD',
    latitude: '44.2875',
    longitude: '-96.7914',
    timeZoneId: 'America/Chicago',
  },
  '57007': {
    zip: '57007',
    city: 'Brookings',
    state: 'SD',
    latitude: '44.306715',
    longitude: '-96.786041',
    timeZoneId: 'America/Chicago',
  },
  '57010': {
    zip: '57010',
    city: 'Burbank',
    state: 'SD',
    latitude: '42.604183',
    longitude: '-96.564281',
    timeZoneId: 'America/Chicago',
  },
  '57012': {
    zip: '57012',
    city: 'Canistota',
    state: 'SD',
    latitude: '43.59628',
    longitude: '-97.293355',
    timeZoneId: 'America/Chicago',
  },
  '57013': {
    zip: '57013',
    city: 'Canton',
    state: 'SD',
    latitude: '43.303968',
    longitude: '-96.59593',
    timeZoneId: 'America/Chicago',
  },
  '57014': {
    zip: '57014',
    city: 'Centerville',
    state: 'SD',
    latitude: '43.140734',
    longitude: '-96.992306',
    timeZoneId: 'America/Chicago',
  },
  '57015': {
    zip: '57015',
    city: 'Chancellor',
    state: 'SD',
    latitude: '43.369918',
    longitude: '-96.983301',
    timeZoneId: 'America/Chicago',
  },
  '57016': {
    zip: '57016',
    city: 'Chester',
    state: 'SD',
    latitude: '43.894594',
    longitude: '-97.007429',
    timeZoneId: 'America/Chicago',
  },
  '57017': {
    zip: '57017',
    city: 'Colman',
    state: 'SD',
    latitude: '43.98449',
    longitude: '-96.81325',
    timeZoneId: 'America/Chicago',
  },
  '57018': {
    zip: '57018',
    city: 'Colton',
    state: 'SD',
    latitude: '43.785894',
    longitude: '-96.927978',
    timeZoneId: 'America/Chicago',
  },
  '57020': {
    zip: '57020',
    city: 'Crooks',
    state: 'SD',
    latitude: '43.661406',
    longitude: '-96.808287',
    timeZoneId: 'America/Chicago',
  },
  '57021': {
    zip: '57021',
    city: 'Davis',
    state: 'SD',
    latitude: '43.289557',
    longitude: '-96.986298',
    timeZoneId: 'America/Chicago',
  },
  '57022': {
    zip: '57022',
    city: 'Dell Rapids',
    state: 'SD',
    latitude: '43.826202',
    longitude: '-96.717843',
    timeZoneId: 'America/Chicago',
  },
  '57024': {
    zip: '57024',
    city: 'Egan',
    state: 'SD',
    latitude: '43.999147',
    longitude: '-96.64286',
    timeZoneId: 'America/Chicago',
  },
  '57025': {
    zip: '57025',
    city: 'Elk Point',
    state: 'SD',
    latitude: '42.7857',
    longitude: '-96.6969',
    timeZoneId: 'America/Chicago',
  },
  '57026': {
    zip: '57026',
    city: 'Elkton',
    state: 'SD',
    latitude: '44.232906',
    longitude: '-96.484595',
    timeZoneId: 'America/Chicago',
  },
  '57027': {
    zip: '57027',
    city: 'Fairview',
    state: 'SD',
    latitude: '43.207357',
    longitude: '-96.590848',
    timeZoneId: 'America/Chicago',
  },
  '57028': {
    zip: '57028',
    city: 'Flandreau',
    state: 'SD',
    latitude: '44.045537',
    longitude: '-96.595757',
    timeZoneId: 'America/Chicago',
  },
  '57029': {
    zip: '57029',
    city: 'Freeman',
    state: 'SD',
    latitude: '43.348042',
    longitude: '-97.427889',
    timeZoneId: 'America/Chicago',
  },
  '57030': {
    zip: '57030',
    city: 'Garretson',
    state: 'SD',
    latitude: '43.71474',
    longitude: '-96.51942',
    timeZoneId: 'America/Chicago',
  },
  '57031': {
    zip: '57031',
    city: 'Gayville',
    state: 'SD',
    latitude: '42.88977',
    longitude: '-97.174801',
    timeZoneId: 'America/Chicago',
  },
  '57032': {
    zip: '57032',
    city: 'Harrisburg',
    state: 'SD',
    latitude: '43.427784',
    longitude: '-96.697425',
    timeZoneId: 'America/Chicago',
  },
  '57033': {
    zip: '57033',
    city: 'Hartford',
    state: 'SD',
    latitude: '43.627967',
    longitude: '-96.94297',
    timeZoneId: 'America/Chicago',
  },
  '57034': {
    zip: '57034',
    city: 'Hudson',
    state: 'SD',
    latitude: '43.13042',
    longitude: '-96.45523',
    timeZoneId: 'America/Chicago',
  },
  '57035': {
    zip: '57035',
    city: 'Humboldt',
    state: 'SD',
    latitude: '43.711272',
    longitude: '-96.886802',
    timeZoneId: 'America/Chicago',
  },
  '57036': {
    zip: '57036',
    city: 'Hurley',
    state: 'SD',
    latitude: '43.28724',
    longitude: '-97.16034',
    timeZoneId: 'America/Chicago',
  },
  '57037': {
    zip: '57037',
    city: 'Irene',
    state: 'SD',
    latitude: '43.111845',
    longitude: '-97.285292',
    timeZoneId: 'America/Chicago',
  },
  '57038': {
    zip: '57038',
    city: 'Jefferson',
    state: 'SD',
    latitude: '42.573514',
    longitude: '-96.539367',
    timeZoneId: 'America/Chicago',
  },
  '57039': {
    zip: '57039',
    city: 'Lennox',
    state: 'SD',
    latitude: '43.353196',
    longitude: '-96.89274',
    timeZoneId: 'America/Chicago',
  },
  '57040': {
    zip: '57040',
    city: 'Lesterville',
    state: 'SD',
    latitude: '43.07634',
    longitude: '-97.546836',
    timeZoneId: 'America/Chicago',
  },
  '57041': {
    zip: '57041',
    city: 'Lyons',
    state: 'SD',
    latitude: '43.786932',
    longitude: '-96.929752',
    timeZoneId: 'America/Chicago',
  },
  '57042': {
    zip: '57042',
    city: 'Madison',
    state: 'SD',
    latitude: '44.00812',
    longitude: '-97.113629',
    timeZoneId: 'America/Chicago',
  },
  '57043': {
    zip: '57043',
    city: 'Marion',
    state: 'SD',
    latitude: '43.424986',
    longitude: '-97.260003',
    timeZoneId: 'America/Chicago',
  },
  '57045': {
    zip: '57045',
    city: 'Menno',
    state: 'SD',
    latitude: '43.237787',
    longitude: '-97.576619',
    timeZoneId: 'America/Chicago',
  },
  '57046': {
    zip: '57046',
    city: 'Mission Hill',
    state: 'SD',
    latitude: '42.997714',
    longitude: '-97.338079',
    timeZoneId: 'America/Chicago',
  },
  '57047': {
    zip: '57047',
    city: 'Monroe',
    state: 'SD',
    latitude: '43.479273',
    longitude: '-97.21853',
    timeZoneId: 'America/Chicago',
  },
  '57048': {
    zip: '57048',
    city: 'Montrose',
    state: 'SD',
    latitude: '43.700076',
    longitude: '-97.186816',
    timeZoneId: 'America/Chicago',
  },
  '57049': {
    zip: '57049',
    city: 'North Sioux City',
    state: 'SD',
    latitude: '42.52806',
    longitude: '-96.50328',
    timeZoneId: 'America/Chicago',
  },
  '57050': {
    zip: '57050',
    city: 'Nunda',
    state: 'SD',
    latitude: '44.155058',
    longitude: '-97.010748',
    timeZoneId: 'America/Chicago',
  },
  '57051': {
    zip: '57051',
    city: 'Oldham',
    state: 'SD',
    latitude: '44.440043',
    longitude: '-97.550634',
    timeZoneId: 'America/Chicago',
  },
  '57052': {
    zip: '57052',
    city: 'Olivet',
    state: 'SD',
    latitude: '43.282169',
    longitude: '-97.732289',
    timeZoneId: 'America/Chicago',
  },
  '57053': {
    zip: '57053',
    city: 'Parker',
    state: 'SD',
    latitude: '43.39689',
    longitude: '-97.1381',
    timeZoneId: 'America/Chicago',
  },
  '57054': {
    zip: '57054',
    city: 'Ramona',
    state: 'SD',
    latitude: '44.118225',
    longitude: '-97.211723',
    timeZoneId: 'America/Chicago',
  },
  '57055': {
    zip: '57055',
    city: 'Renner',
    state: 'SD',
    latitude: '43.669327',
    longitude: '-96.70289',
    timeZoneId: 'America/Chicago',
  },
  '57057': {
    zip: '57057',
    city: 'Rutland',
    state: 'SD',
    latitude: '44.075835',
    longitude: '-96.95453',
    timeZoneId: 'America/Chicago',
  },
  '57058': {
    zip: '57058',
    city: 'Salem',
    state: 'SD',
    latitude: '43.73724',
    longitude: '-97.38444',
    timeZoneId: 'America/Chicago',
  },
  '57059': {
    zip: '57059',
    city: 'Scotland',
    state: 'SD',
    latitude: '43.138096',
    longitude: '-97.725804',
    timeZoneId: 'America/Chicago',
  },
  '57061': {
    zip: '57061',
    city: 'Sinai',
    state: 'SD',
    latitude: '44.243038',
    longitude: '-97.067738',
    timeZoneId: 'America/Chicago',
  },
  '57062': {
    zip: '57062',
    city: 'Springfield',
    state: 'SD',
    latitude: '42.853777',
    longitude: '-97.892321',
    timeZoneId: 'America/Chicago',
  },
  '57063': {
    zip: '57063',
    city: 'Tabor',
    state: 'SD',
    latitude: '42.951432',
    longitude: '-97.665236',
    timeZoneId: 'America/Chicago',
  },
  '57064': {
    zip: '57064',
    city: 'Tea',
    state: 'SD',
    latitude: '43.447764',
    longitude: '-96.835749',
    timeZoneId: 'America/Chicago',
  },
  '57065': {
    zip: '57065',
    city: 'Trent',
    state: 'SD',
    latitude: '43.888752',
    longitude: '-96.608927',
    timeZoneId: 'America/Chicago',
  },
  '57066': {
    zip: '57066',
    city: 'Tyndall',
    state: 'SD',
    latitude: '42.989816',
    longitude: '-97.860137',
    timeZoneId: 'America/Chicago',
  },
  '57067': {
    zip: '57067',
    city: 'Utica',
    state: 'SD',
    latitude: '42.962076',
    longitude: '-97.507277',
    timeZoneId: 'America/Chicago',
  },
  '57068': {
    zip: '57068',
    city: 'Valley Springs',
    state: 'SD',
    latitude: '43.58288',
    longitude: '-96.464847',
    timeZoneId: 'America/Chicago',
  },
  '57069': {
    zip: '57069',
    city: 'Vermillion',
    state: 'SD',
    latitude: '42.781151',
    longitude: '-96.924411',
    timeZoneId: 'America/Chicago',
  },
  '57070': {
    zip: '57070',
    city: 'Viborg',
    state: 'SD',
    latitude: '43.18992',
    longitude: '-97.11696',
    timeZoneId: 'America/Chicago',
  },
  '57071': {
    zip: '57071',
    city: 'Volga',
    state: 'SD',
    latitude: '44.320521',
    longitude: '-96.925166',
    timeZoneId: 'America/Chicago',
  },
  '57072': {
    zip: '57072',
    city: 'Volin',
    state: 'SD',
    latitude: '42.998394',
    longitude: '-97.222124',
    timeZoneId: 'America/Chicago',
  },
  '57073': {
    zip: '57073',
    city: 'Wakonda',
    state: 'SD',
    latitude: '42.995805',
    longitude: '-97.043929',
    timeZoneId: 'America/Chicago',
  },
  '57075': {
    zip: '57075',
    city: 'Wentworth',
    state: 'SD',
    latitude: '43.998194',
    longitude: '-96.96879',
    timeZoneId: 'America/Chicago',
  },
  '57076': {
    zip: '57076',
    city: 'Winfred',
    state: 'SD',
    latitude: '43.956631',
    longitude: '-97.308946',
    timeZoneId: 'America/Chicago',
  },
  '57077': {
    zip: '57077',
    city: 'Worthing',
    state: 'SD',
    latitude: '43.329353',
    longitude: '-96.767294',
    timeZoneId: 'America/Chicago',
  },
  '57078': {
    zip: '57078',
    city: 'Yankton',
    state: 'SD',
    latitude: '42.879711',
    longitude: '-97.388459',
    timeZoneId: 'America/Chicago',
  },
  '57101': {
    zip: '57101',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.601314',
    longitude: '-96.704854',
    timeZoneId: 'America/Chicago',
  },
  '57103': {
    zip: '57103',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.535409',
    longitude: '-96.685536',
    timeZoneId: 'America/Chicago',
  },
  '57104': {
    zip: '57104',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.556226',
    longitude: '-96.734063',
    timeZoneId: 'America/Chicago',
  },
  '57105': {
    zip: '57105',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.521706',
    longitude: '-96.73037',
    timeZoneId: 'America/Chicago',
  },
  '57106': {
    zip: '57106',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.51703',
    longitude: '-96.789603',
    timeZoneId: 'America/Chicago',
  },
  '57107': {
    zip: '57107',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.56535',
    longitude: '-96.796958',
    timeZoneId: 'America/Chicago',
  },
  '57108': {
    zip: '57108',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.474208',
    longitude: '-96.687689',
    timeZoneId: 'America/Chicago',
  },
  '57109': {
    zip: '57109',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.520545',
    longitude: '-96.738364',
    timeZoneId: 'America/Chicago',
  },
  '57110': {
    zip: '57110',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.5443',
    longitude: '-96.638702',
    timeZoneId: 'America/Chicago',
  },
  '57117': {
    zip: '57117',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.548465',
    longitude: '-96.724916',
    timeZoneId: 'America/Chicago',
  },
  '57118': {
    zip: '57118',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.604782',
    longitude: '-96.706975',
    timeZoneId: 'America/Chicago',
  },
  '57186': {
    zip: '57186',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.5493',
    longitude: '-96.726635',
    timeZoneId: 'America/Chicago',
  },
  '57193': {
    zip: '57193',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.545553',
    longitude: '-96.720773',
    timeZoneId: 'America/Chicago',
  },
  '57197': {
    zip: '57197',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.521917',
    longitude: '-96.740725',
    timeZoneId: 'America/Chicago',
  },
  '57198': {
    zip: '57198',
    city: 'Sioux Falls',
    state: 'SD',
    latitude: '43.733737',
    longitude: '-96.628269',
    timeZoneId: 'America/Chicago',
  },
  '57201': {
    zip: '57201',
    city: 'Watertown',
    state: 'SD',
    latitude: '44.902615',
    longitude: '-97.121324',
    timeZoneId: 'America/Chicago',
  },
  '57212': {
    zip: '57212',
    city: 'Arlington',
    state: 'SD',
    latitude: '44.365792',
    longitude: '-97.151109',
    timeZoneId: 'America/Chicago',
  },
  '57213': {
    zip: '57213',
    city: 'Astoria',
    state: 'SD',
    latitude: '44.557233',
    longitude: '-96.546861',
    timeZoneId: 'America/Chicago',
  },
  '57214': {
    zip: '57214',
    city: 'Badger',
    state: 'SD',
    latitude: '44.489359',
    longitude: '-97.224349',
    timeZoneId: 'America/Chicago',
  },
  '57216': {
    zip: '57216',
    city: 'Big Stone City',
    state: 'SD',
    latitude: '45.292796',
    longitude: '-96.462397',
    timeZoneId: 'America/Chicago',
  },
  '57217': {
    zip: '57217',
    city: 'Bradley',
    state: 'SD',
    latitude: '44.90308',
    longitude: '-97.707295',
    timeZoneId: 'America/Chicago',
  },
  '57218': {
    zip: '57218',
    city: 'Brandt',
    state: 'SD',
    latitude: '44.672958',
    longitude: '-96.671048',
    timeZoneId: 'America/Chicago',
  },
  '57219': {
    zip: '57219',
    city: 'Bristol',
    state: 'SD',
    latitude: '45.26094',
    longitude: '-97.71834',
    timeZoneId: 'America/Chicago',
  },
  '57220': {
    zip: '57220',
    city: 'Bruce',
    state: 'SD',
    latitude: '44.485116',
    longitude: '-96.934663',
    timeZoneId: 'America/Chicago',
  },
  '57221': {
    zip: '57221',
    city: 'Bryant',
    state: 'SD',
    latitude: '44.629708',
    longitude: '-97.429998',
    timeZoneId: 'America/Chicago',
  },
  '57223': {
    zip: '57223',
    city: 'Castlewood',
    state: 'SD',
    latitude: '44.726786',
    longitude: '-97.027942',
    timeZoneId: 'America/Chicago',
  },
  '57224': {
    zip: '57224',
    city: 'Claire City',
    state: 'SD',
    latitude: '45.877033',
    longitude: '-97.104277',
    timeZoneId: 'America/Chicago',
  },
  '57225': {
    zip: '57225',
    city: 'Clark',
    state: 'SD',
    latitude: '44.881594',
    longitude: '-97.733744',
    timeZoneId: 'America/Chicago',
  },
  '57226': {
    zip: '57226',
    city: 'Clear Lake',
    state: 'SD',
    latitude: '44.758347',
    longitude: '-96.683015',
    timeZoneId: 'America/Chicago',
  },
  '57227': {
    zip: '57227',
    city: 'Corona',
    state: 'SD',
    latitude: '45.362558',
    longitude: '-96.668241',
    timeZoneId: 'America/Chicago',
  },
  '57231': {
    zip: '57231',
    city: 'De Smet',
    state: 'SD',
    latitude: '44.38719',
    longitude: '-97.551945',
    timeZoneId: 'America/Chicago',
  },
  '57232': {
    zip: '57232',
    city: 'Eden',
    state: 'SD',
    latitude: '45.61334',
    longitude: '-97.356059',
    timeZoneId: 'America/Chicago',
  },
  '57233': {
    zip: '57233',
    city: 'Erwin',
    state: 'SD',
    latitude: '44.49858',
    longitude: '-97.41048',
    timeZoneId: 'America/Chicago',
  },
  '57234': {
    zip: '57234',
    city: 'Estelline',
    state: 'SD',
    latitude: '44.622353',
    longitude: '-96.958682',
    timeZoneId: 'America/Chicago',
  },
  '57235': {
    zip: '57235',
    city: 'Florence',
    state: 'SD',
    latitude: '45.064359',
    longitude: '-97.26893',
    timeZoneId: 'America/Chicago',
  },
  '57236': {
    zip: '57236',
    city: 'Garden City',
    state: 'SD',
    latitude: '44.936264',
    longitude: '-97.557266',
    timeZoneId: 'America/Chicago',
  },
  '57237': {
    zip: '57237',
    city: 'Gary',
    state: 'SD',
    latitude: '44.853',
    longitude: '-96.530608',
    timeZoneId: 'America/Chicago',
  },
  '57238': {
    zip: '57238',
    city: 'Goodwin',
    state: 'SD',
    latitude: '44.8941',
    longitude: '-96.79278',
    timeZoneId: 'America/Chicago',
  },
  '57239': {
    zip: '57239',
    city: 'Grenville',
    state: 'SD',
    latitude: '45.502047',
    longitude: '-97.411324',
    timeZoneId: 'America/Chicago',
  },
  '57241': {
    zip: '57241',
    city: 'Hayti',
    state: 'SD',
    latitude: '44.677517',
    longitude: '-97.250132',
    timeZoneId: 'America/Chicago',
  },
  '57242': {
    zip: '57242',
    city: 'Hazel',
    state: 'SD',
    latitude: '44.756769',
    longitude: '-97.384225',
    timeZoneId: 'America/Chicago',
  },
  '57243': {
    zip: '57243',
    city: 'Henry',
    state: 'SD',
    latitude: '44.888142',
    longitude: '-97.432744',
    timeZoneId: 'America/Chicago',
  },
  '57245': {
    zip: '57245',
    city: 'Kranzburg',
    state: 'SD',
    latitude: '44.878739',
    longitude: '-96.943631',
    timeZoneId: 'America/Chicago',
  },
  '57246': {
    zip: '57246',
    city: 'Labolt',
    state: 'SD',
    latitude: '45.026627',
    longitude: '-96.698717',
    timeZoneId: 'America/Chicago',
  },
  '57247': {
    zip: '57247',
    city: 'Lake City',
    state: 'SD',
    latitude: '45.69855',
    longitude: '-97.352584',
    timeZoneId: 'America/Chicago',
  },
  '57248': {
    zip: '57248',
    city: 'Lake Norden',
    state: 'SD',
    latitude: '44.58182',
    longitude: '-97.211073',
    timeZoneId: 'America/Chicago',
  },
  '57249': {
    zip: '57249',
    city: 'Lake Preston',
    state: 'SD',
    latitude: '44.360596',
    longitude: '-97.361268',
    timeZoneId: 'America/Chicago',
  },
  '57251': {
    zip: '57251',
    city: 'Marvin',
    state: 'SD',
    latitude: '45.282954',
    longitude: '-96.941539',
    timeZoneId: 'America/Chicago',
  },
  '57252': {
    zip: '57252',
    city: 'Milbank',
    state: 'SD',
    latitude: '45.218981',
    longitude: '-96.632324',
    timeZoneId: 'America/Chicago',
  },
  '57255': {
    zip: '57255',
    city: 'New Effington',
    state: 'SD',
    latitude: '45.87714',
    longitude: '-96.91898',
    timeZoneId: 'America/Chicago',
  },
  '57256': {
    zip: '57256',
    city: 'Ortley',
    state: 'SD',
    latitude: '45.15495',
    longitude: '-97.204176',
    timeZoneId: 'America/Chicago',
  },
  '57257': {
    zip: '57257',
    city: 'Peever',
    state: 'SD',
    latitude: '45.500243',
    longitude: '-97.044431',
    timeZoneId: 'America/Chicago',
  },
  '57258': {
    zip: '57258',
    city: 'Raymond',
    state: 'SD',
    latitude: '44.889418',
    longitude: '-97.92159',
    timeZoneId: 'America/Chicago',
  },
  '57259': {
    zip: '57259',
    city: 'Revillo',
    state: 'SD',
    latitude: '45.017266',
    longitude: '-96.548264',
    timeZoneId: 'America/Chicago',
  },
  '57260': {
    zip: '57260',
    city: 'Rosholt',
    state: 'SD',
    latitude: '45.878034',
    longitude: '-96.713253',
    timeZoneId: 'America/Chicago',
  },
  '57261': {
    zip: '57261',
    city: 'Roslyn',
    state: 'SD',
    latitude: '45.513278',
    longitude: '-97.587947',
    timeZoneId: 'America/Chicago',
  },
  '57262': {
    zip: '57262',
    city: 'Sisseton',
    state: 'SD',
    latitude: '45.664922',
    longitude: '-97.048055',
    timeZoneId: 'America/Chicago',
  },
  '57263': {
    zip: '57263',
    city: 'South Shore',
    state: 'SD',
    latitude: '45.10855',
    longitude: '-97.022712',
    timeZoneId: 'America/Chicago',
  },
  '57264': {
    zip: '57264',
    city: 'Stockholm',
    state: 'SD',
    latitude: '45.108718',
    longitude: '-96.82281',
    timeZoneId: 'America/Chicago',
  },
  '57265': {
    zip: '57265',
    city: 'Strandburg',
    state: 'SD',
    latitude: '45.017187',
    longitude: '-96.820946',
    timeZoneId: 'America/Chicago',
  },
  '57266': {
    zip: '57266',
    city: 'Summit',
    state: 'SD',
    latitude: '45.387337',
    longitude: '-97.043929',
    timeZoneId: 'America/Chicago',
  },
  '57268': {
    zip: '57268',
    city: 'Toronto',
    state: 'SD',
    latitude: '44.574557',
    longitude: '-96.644841',
    timeZoneId: 'America/Chicago',
  },
  '57269': {
    zip: '57269',
    city: 'Twin Brooks',
    state: 'SD',
    latitude: '45.212479',
    longitude: '-97.022662',
    timeZoneId: 'America/Chicago',
  },
  '57270': {
    zip: '57270',
    city: 'Veblen',
    state: 'SD',
    latitude: '45.864739',
    longitude: '-97.284259',
    timeZoneId: 'America/Chicago',
  },
  '57271': {
    zip: '57271',
    city: 'Vienna',
    state: 'SD',
    latitude: '44.676806',
    longitude: '-97.547404',
    timeZoneId: 'America/Chicago',
  },
  '57272': {
    zip: '57272',
    city: 'Wallace',
    state: 'SD',
    latitude: '45.064905',
    longitude: '-97.434058',
    timeZoneId: 'America/Chicago',
  },
  '57273': {
    zip: '57273',
    city: 'Waubay',
    state: 'SD',
    latitude: '45.335539',
    longitude: '-97.310586',
    timeZoneId: 'America/Chicago',
  },
  '57274': {
    zip: '57274',
    city: 'Webster',
    state: 'SD',
    latitude: '45.32562',
    longitude: '-97.51956',
    timeZoneId: 'America/Chicago',
  },
  '57276': {
    zip: '57276',
    city: 'White',
    state: 'SD',
    latitude: '44.38548',
    longitude: '-96.62652',
    timeZoneId: 'America/Chicago',
  },
  '57278': {
    zip: '57278',
    city: 'Willow Lake',
    state: 'SD',
    latitude: '44.632757',
    longitude: '-97.734977',
    timeZoneId: 'America/Chicago',
  },
  '57279': {
    zip: '57279',
    city: 'Wilmot',
    state: 'SD',
    latitude: '45.414998',
    longitude: '-96.867332',
    timeZoneId: 'America/Chicago',
  },
  '57301': {
    zip: '57301',
    city: 'Mitchell',
    state: 'SD',
    latitude: '43.7274',
    longitude: '-98.04618',
    timeZoneId: 'America/Chicago',
  },
  '57311': {
    zip: '57311',
    city: 'Alexandria',
    state: 'SD',
    latitude: '43.64592',
    longitude: '-97.7613',
    timeZoneId: 'America/Chicago',
  },
  '57312': {
    zip: '57312',
    city: 'Alpena',
    state: 'SD',
    latitude: '44.155775',
    longitude: '-98.452371',
    timeZoneId: 'America/Chicago',
  },
  '57313': {
    zip: '57313',
    city: 'Armour',
    state: 'SD',
    latitude: '43.318948',
    longitude: '-98.345478',
    timeZoneId: 'America/Chicago',
  },
  '57314': {
    zip: '57314',
    city: 'Artesian',
    state: 'SD',
    latitude: '44.04026',
    longitude: '-97.958503',
    timeZoneId: 'America/Chicago',
  },
  '57315': {
    zip: '57315',
    city: 'Avon',
    state: 'SD',
    latitude: '43.007771',
    longitude: '-98.056898',
    timeZoneId: 'America/Chicago',
  },
  '57317': {
    zip: '57317',
    city: 'Bonesteel',
    state: 'SD',
    latitude: '43.058084',
    longitude: '-99.032139',
    timeZoneId: 'America/Chicago',
  },
  '57319': {
    zip: '57319',
    city: 'Bridgewater',
    state: 'SD',
    latitude: '43.5291',
    longitude: '-97.44642',
    timeZoneId: 'America/Chicago',
  },
  '57321': {
    zip: '57321',
    city: 'Canova',
    state: 'SD',
    latitude: '43.89312',
    longitude: '-97.54242',
    timeZoneId: 'America/Chicago',
  },
  '57322': {
    zip: '57322',
    city: 'Carpenter',
    state: 'SD',
    latitude: '44.674688',
    longitude: '-97.914803',
    timeZoneId: 'America/Chicago',
  },
  '57323': {
    zip: '57323',
    city: 'Carthage',
    state: 'SD',
    latitude: '44.174556',
    longitude: '-97.719752',
    timeZoneId: 'America/Chicago',
  },
  '57324': {
    zip: '57324',
    city: 'Cavour',
    state: 'SD',
    latitude: '44.367668',
    longitude: '-98.036362',
    timeZoneId: 'America/Chicago',
  },
  '57325': {
    zip: '57325',
    city: 'Chamberlain',
    state: 'SD',
    latitude: '43.806473',
    longitude: '-99.327811',
    timeZoneId: 'America/Chicago',
  },
  '57326': {
    zip: '57326',
    city: 'Chamberlain',
    state: 'SD',
    latitude: '43.808959',
    longitude: '-99.317832',
    timeZoneId: 'America/Chicago',
  },
  '57328': {
    zip: '57328',
    city: 'Corsica',
    state: 'SD',
    latitude: '43.426269',
    longitude: '-98.40702',
    timeZoneId: 'America/Chicago',
  },
  '57329': {
    zip: '57329',
    city: 'Dante',
    state: 'SD',
    latitude: '43.040312',
    longitude: '-98.184995',
    timeZoneId: 'America/Chicago',
  },
  '57330': {
    zip: '57330',
    city: 'Delmont',
    state: 'SD',
    latitude: '43.299981',
    longitude: '-98.212417',
    timeZoneId: 'America/Chicago',
  },
  '57331': {
    zip: '57331',
    city: 'Dimock',
    state: 'SD',
    latitude: '43.463498',
    longitude: '-97.99463',
    timeZoneId: 'America/Chicago',
  },
  '57332': {
    zip: '57332',
    city: 'Emery',
    state: 'SD',
    latitude: '43.605292',
    longitude: '-97.622528',
    timeZoneId: 'America/Chicago',
  },
  '57334': {
    zip: '57334',
    city: 'Ethan',
    state: 'SD',
    latitude: '43.546469',
    longitude: '-97.982906',
    timeZoneId: 'America/Chicago',
  },
  '57335': {
    zip: '57335',
    city: 'Fairfax',
    state: 'SD',
    latitude: '43.06086',
    longitude: '-98.766233',
    timeZoneId: 'America/Chicago',
  },
  '57337': {
    zip: '57337',
    city: 'Fedora',
    state: 'SD',
    latitude: '43.980581',
    longitude: '-97.790792',
    timeZoneId: 'America/Chicago',
  },
  '57339': {
    zip: '57339',
    city: 'Fort Thompson',
    state: 'SD',
    latitude: '44.065203',
    longitude: '-99.32645',
    timeZoneId: 'America/Chicago',
  },
  '57340': {
    zip: '57340',
    city: 'Fulton',
    state: 'SD',
    latitude: '43.726969',
    longitude: '-97.822202',
    timeZoneId: 'America/Chicago',
  },
  '57341': {
    zip: '57341',
    city: 'Gann Valley',
    state: 'SD',
    latitude: '44.085809',
    longitude: '-99.0686',
    timeZoneId: 'America/Chicago',
  },
  '57342': {
    zip: '57342',
    city: 'Geddes',
    state: 'SD',
    latitude: '43.251792',
    longitude: '-98.68847',
    timeZoneId: 'America/Chicago',
  },
  '57344': {
    zip: '57344',
    city: 'Harrison',
    state: 'SD',
    latitude: '43.455028',
    longitude: '-98.525175',
    timeZoneId: 'America/Chicago',
  },
  '57345': {
    zip: '57345',
    city: 'Highmore',
    state: 'SD',
    latitude: '44.520425',
    longitude: '-99.443461',
    timeZoneId: 'America/Chicago',
  },
  '57346': {
    zip: '57346',
    city: 'Stephan',
    state: 'SD',
    latitude: '44.249295',
    longitude: '-99.452534',
    timeZoneId: 'America/Chicago',
  },
  '57348': {
    zip: '57348',
    city: 'Hitchcock',
    state: 'SD',
    latitude: '44.631361',
    longitude: '-98.408782',
    timeZoneId: 'America/Chicago',
  },
  '57349': {
    zip: '57349',
    city: 'Howard',
    state: 'SD',
    latitude: '44.014323',
    longitude: '-97.529484',
    timeZoneId: 'America/Chicago',
  },
  '57350': {
    zip: '57350',
    city: 'Huron',
    state: 'SD',
    latitude: '44.35954',
    longitude: '-98.217645',
    timeZoneId: 'America/Chicago',
  },
  '57353': {
    zip: '57353',
    city: 'Iroquois',
    state: 'SD',
    latitude: '44.365169',
    longitude: '-97.849047',
    timeZoneId: 'America/Chicago',
  },
  '57354': {
    zip: '57354',
    city: 'Kaylor',
    state: 'SD',
    latitude: '43.206175',
    longitude: '-97.825362',
    timeZoneId: 'America/Chicago',
  },
  '57355': {
    zip: '57355',
    city: 'Kimball',
    state: 'SD',
    latitude: '43.716989',
    longitude: '-98.93498',
    timeZoneId: 'America/Chicago',
  },
  '57356': {
    zip: '57356',
    city: 'Lake Andes',
    state: 'SD',
    latitude: '43.157659',
    longitude: '-98.538151',
    timeZoneId: 'America/Chicago',
  },
  '57358': {
    zip: '57358',
    city: 'Lane',
    state: 'SD',
    latitude: '44.073104',
    longitude: '-98.405479',
    timeZoneId: 'America/Chicago',
  },
  '57359': {
    zip: '57359',
    city: 'Letcher',
    state: 'SD',
    latitude: '43.896104',
    longitude: '-98.208027',
    timeZoneId: 'America/Chicago',
  },
  '57361': {
    zip: '57361',
    city: 'Marty',
    state: 'SD',
    latitude: '42.959043',
    longitude: '-98.392182',
    timeZoneId: 'America/Chicago',
  },
  '57362': {
    zip: '57362',
    city: 'Miller',
    state: 'SD',
    latitude: '44.522526',
    longitude: '-98.983752',
    timeZoneId: 'America/Chicago',
  },
  '57363': {
    zip: '57363',
    city: 'Mount Vernon',
    state: 'SD',
    latitude: '43.720808',
    longitude: '-98.265908',
    timeZoneId: 'America/Chicago',
  },
  '57364': {
    zip: '57364',
    city: 'New Holland',
    state: 'SD',
    latitude: '43.438098',
    longitude: '-98.647921',
    timeZoneId: 'America/Chicago',
  },
  '57365': {
    zip: '57365',
    city: 'Oacoma',
    state: 'SD',
    latitude: '43.79823',
    longitude: '-99.397818',
    timeZoneId: 'America/Chicago',
  },
  '57366': {
    zip: '57366',
    city: 'Parkston',
    state: 'SD',
    latitude: '43.394273',
    longitude: '-97.98821',
    timeZoneId: 'America/Chicago',
  },
  '57367': {
    zip: '57367',
    city: 'Pickstown',
    state: 'SD',
    latitude: '43.067064',
    longitude: '-98.533498',
    timeZoneId: 'America/Chicago',
  },
  '57368': {
    zip: '57368',
    city: 'Plankinton',
    state: 'SD',
    latitude: '43.713793',
    longitude: '-98.481568',
    timeZoneId: 'America/Chicago',
  },
  '57369': {
    zip: '57369',
    city: 'Platte',
    state: 'SD',
    latitude: '43.40526',
    longitude: '-98.88804',
    timeZoneId: 'America/Chicago',
  },
  '57370': {
    zip: '57370',
    city: 'Pukwana',
    state: 'SD',
    latitude: '43.777669',
    longitude: '-99.173379',
    timeZoneId: 'America/Chicago',
  },
  '57371': {
    zip: '57371',
    city: 'Ree Heights',
    state: 'SD',
    latitude: '44.594136',
    longitude: '-99.247882',
    timeZoneId: 'America/Chicago',
  },
  '57373': {
    zip: '57373',
    city: 'Saint Lawrence',
    state: 'SD',
    latitude: '44.531049',
    longitude: '-98.806533',
    timeZoneId: 'America/Chicago',
  },
  '57374': {
    zip: '57374',
    city: 'Spencer',
    state: 'SD',
    latitude: '43.775257',
    longitude: '-97.591921',
    timeZoneId: 'America/Chicago',
  },
  '57375': {
    zip: '57375',
    city: 'Stickney',
    state: 'SD',
    latitude: '43.58625',
    longitude: '-98.561284',
    timeZoneId: 'America/Chicago',
  },
  '57376': {
    zip: '57376',
    city: 'Tripp',
    state: 'SD',
    latitude: '43.254844',
    longitude: '-97.989423',
    timeZoneId: 'America/Chicago',
  },
  '57379': {
    zip: '57379',
    city: 'Virgil',
    state: 'SD',
    latitude: '44.322364',
    longitude: '-98.408134',
    timeZoneId: 'America/Chicago',
  },
  '57380': {
    zip: '57380',
    city: 'Wagner',
    state: 'SD',
    latitude: '43.078076',
    longitude: '-98.2927',
    timeZoneId: 'America/Chicago',
  },
  '57381': {
    zip: '57381',
    city: 'Wessington',
    state: 'SD',
    latitude: '44.373351',
    longitude: '-98.680654',
    timeZoneId: 'America/Chicago',
  },
  '57382': {
    zip: '57382',
    city: 'Wessington Springs',
    state: 'SD',
    latitude: '44.08485',
    longitude: '-98.568185',
    timeZoneId: 'America/Chicago',
  },
  '57383': {
    zip: '57383',
    city: 'White Lake',
    state: 'SD',
    latitude: '43.804787',
    longitude: '-98.684321',
    timeZoneId: 'America/Chicago',
  },
  '57384': {
    zip: '57384',
    city: 'Wolsey',
    state: 'SD',
    latitude: '44.413182',
    longitude: '-98.472035',
    timeZoneId: 'America/Chicago',
  },
  '57385': {
    zip: '57385',
    city: 'Woonsocket',
    state: 'SD',
    latitude: '44.06717',
    longitude: '-98.211946',
    timeZoneId: 'America/Chicago',
  },
  '57386': {
    zip: '57386',
    city: 'Yale',
    state: 'SD',
    latitude: '44.531154',
    longitude: '-97.981398',
    timeZoneId: 'America/Chicago',
  },
  '57399': {
    zip: '57399',
    city: 'Huron',
    state: 'SD',
    latitude: '44.363185',
    longitude: '-98.218918',
    timeZoneId: 'America/Chicago',
  },
  '57401': {
    zip: '57401',
    city: 'Aberdeen',
    state: 'SD',
    latitude: '45.464854',
    longitude: '-98.4923',
    timeZoneId: 'America/Chicago',
  },
  '57402': {
    zip: '57402',
    city: 'Aberdeen',
    state: 'SD',
    latitude: '45.493924',
    longitude: '-98.49454',
    timeZoneId: 'America/Chicago',
  },
  '57420': {
    zip: '57420',
    city: 'Akaska',
    state: 'SD',
    latitude: '45.244797',
    longitude: '-99.803819',
    timeZoneId: 'America/Chicago',
  },
  '57421': {
    zip: '57421',
    city: 'Amherst',
    state: 'SD',
    latitude: '45.717219',
    longitude: '-97.920215',
    timeZoneId: 'America/Chicago',
  },
  '57422': {
    zip: '57422',
    city: 'Andover',
    state: 'SD',
    latitude: '45.414053',
    longitude: '-97.916761',
    timeZoneId: 'America/Chicago',
  },
  '57424': {
    zip: '57424',
    city: 'Ashton',
    state: 'SD',
    latitude: '45.0219',
    longitude: '-98.4945',
    timeZoneId: 'America/Chicago',
  },
  '57426': {
    zip: '57426',
    city: 'Barnard',
    state: 'SD',
    latitude: '45.717662',
    longitude: '-98.606716',
    timeZoneId: 'America/Chicago',
  },
  '57427': {
    zip: '57427',
    city: 'Bath',
    state: 'SD',
    latitude: '45.457975',
    longitude: '-98.329526',
    timeZoneId: 'America/Chicago',
  },
  '57428': {
    zip: '57428',
    city: 'Bowdle',
    state: 'SD',
    latitude: '45.452252',
    longitude: '-99.657884',
    timeZoneId: 'America/Chicago',
  },
  '57429': {
    zip: '57429',
    city: 'Brentford',
    state: 'SD',
    latitude: '45.162829',
    longitude: '-98.32325',
    timeZoneId: 'America/Chicago',
  },
  '57430': {
    zip: '57430',
    city: 'Britton',
    state: 'SD',
    latitude: '45.8067',
    longitude: '-97.72218',
    timeZoneId: 'America/Chicago',
  },
  '57432': {
    zip: '57432',
    city: 'Claremont',
    state: 'SD',
    latitude: '45.66726',
    longitude: '-98.064619',
    timeZoneId: 'America/Chicago',
  },
  '57433': {
    zip: '57433',
    city: 'Columbia',
    state: 'SD',
    latitude: '45.67932',
    longitude: '-98.30868',
    timeZoneId: 'America/Chicago',
  },
  '57434': {
    zip: '57434',
    city: 'Conde',
    state: 'SD',
    latitude: '45.165262',
    longitude: '-98.015514',
    timeZoneId: 'America/Chicago',
  },
  '57435': {
    zip: '57435',
    city: 'Cresbard',
    state: 'SD',
    latitude: '45.17398',
    longitude: '-98.939794',
    timeZoneId: 'America/Chicago',
  },
  '57436': {
    zip: '57436',
    city: 'Doland',
    state: 'SD',
    latitude: '44.785446',
    longitude: '-98.116565',
    timeZoneId: 'America/Chicago',
  },
  '57437': {
    zip: '57437',
    city: 'Eureka',
    state: 'SD',
    latitude: '45.80262',
    longitude: '-99.6186',
    timeZoneId: 'America/Chicago',
  },
  '57438': {
    zip: '57438',
    city: 'Faulkton',
    state: 'SD',
    latitude: '45.035596',
    longitude: '-99.120562',
    timeZoneId: 'America/Chicago',
  },
  '57439': {
    zip: '57439',
    city: 'Ferney',
    state: 'SD',
    latitude: '45.329454',
    longitude: '-98.096459',
    timeZoneId: 'America/Chicago',
  },
  '57440': {
    zip: '57440',
    city: 'Frankfort',
    state: 'SD',
    latitude: '44.760514',
    longitude: '-98.27971',
    timeZoneId: 'America/Chicago',
  },
  '57441': {
    zip: '57441',
    city: 'Frederick',
    state: 'SD',
    latitude: '45.834238',
    longitude: '-98.506058',
    timeZoneId: 'America/Chicago',
  },
  '57442': {
    zip: '57442',
    city: 'Gettysburg',
    state: 'SD',
    latitude: '45.013247',
    longitude: '-99.998682',
    timeZoneId: 'America/Chicago',
  },
  '57445': {
    zip: '57445',
    city: 'Groton',
    state: 'SD',
    latitude: '45.451773',
    longitude: '-98.101092',
    timeZoneId: 'America/Chicago',
  },
  '57446': {
    zip: '57446',
    city: 'Hecla',
    state: 'SD',
    latitude: '45.880108',
    longitude: '-98.147006',
    timeZoneId: 'America/Chicago',
  },
  '57448': {
    zip: '57448',
    city: 'Hosmer',
    state: 'SD',
    latitude: '45.548331',
    longitude: '-99.524184',
    timeZoneId: 'America/Chicago',
  },
  '57449': {
    zip: '57449',
    city: 'Houghton',
    state: 'SD',
    latitude: '45.805233',
    longitude: '-98.103312',
    timeZoneId: 'America/Chicago',
  },
  '57450': {
    zip: '57450',
    city: 'Hoven',
    state: 'SD',
    latitude: '45.239765',
    longitude: '-99.778414',
    timeZoneId: 'America/Chicago',
  },
  '57451': {
    zip: '57451',
    city: 'Ipswich',
    state: 'SD',
    latitude: '45.448625',
    longitude: '-99.029411',
    timeZoneId: 'America/Chicago',
  },
  '57452': {
    zip: '57452',
    city: 'Java',
    state: 'SD',
    latitude: '45.419759',
    longitude: '-99.810831',
    timeZoneId: 'America/Chicago',
  },
  '57454': {
    zip: '57454',
    city: 'Langford',
    state: 'SD',
    latitude: '45.629492',
    longitude: '-97.729855',
    timeZoneId: 'America/Chicago',
  },
  '57455': {
    zip: '57455',
    city: 'Lebanon',
    state: 'SD',
    latitude: '44.978593',
    longitude: '-99.717349',
    timeZoneId: 'America/Chicago',
  },
  '57456': {
    zip: '57456',
    city: 'Leola',
    state: 'SD',
    latitude: '45.624679',
    longitude: '-98.769904',
    timeZoneId: 'America/Chicago',
  },
  '57457': {
    zip: '57457',
    city: 'Long Lake',
    state: 'SD',
    latitude: '45.77341',
    longitude: '-99.248283',
    timeZoneId: 'America/Chicago',
  },
  '57460': {
    zip: '57460',
    city: 'Mansfield',
    state: 'SD',
    latitude: '45.216824',
    longitude: '-98.632295',
    timeZoneId: 'America/Chicago',
  },
  '57461': {
    zip: '57461',
    city: 'Mellette',
    state: 'SD',
    latitude: '45.160243',
    longitude: '-98.478011',
    timeZoneId: 'America/Chicago',
  },
  '57465': {
    zip: '57465',
    city: 'Northville',
    state: 'SD',
    latitude: '45.1665',
    longitude: '-98.68878',
    timeZoneId: 'America/Chicago',
  },
  '57466': {
    zip: '57466',
    city: 'Onaka',
    state: 'SD',
    latitude: '45.201699',
    longitude: '-99.451295',
    timeZoneId: 'America/Chicago',
  },
  '57467': {
    zip: '57467',
    city: 'Orient',
    state: 'SD',
    latitude: '44.835346',
    longitude: '-99.110013',
    timeZoneId: 'America/Chicago',
  },
  '57468': {
    zip: '57468',
    city: 'Pierpont',
    state: 'SD',
    latitude: '45.500668',
    longitude: '-97.794951',
    timeZoneId: 'America/Chicago',
  },
  '57469': {
    zip: '57469',
    city: 'Redfield',
    state: 'SD',
    latitude: '44.874469',
    longitude: '-98.514591',
    timeZoneId: 'America/Chicago',
  },
  '57470': {
    zip: '57470',
    city: 'Rockham',
    state: 'SD',
    latitude: '45.025175',
    longitude: '-98.778074',
    timeZoneId: 'America/Chicago',
  },
  '57471': {
    zip: '57471',
    city: 'Roscoe',
    state: 'SD',
    latitude: '45.42912',
    longitude: '-99.33678',
    timeZoneId: 'America/Chicago',
  },
  '57472': {
    zip: '57472',
    city: 'Selby',
    state: 'SD',
    latitude: '45.41286',
    longitude: '-100.0215',
    timeZoneId: 'America/Chicago',
  },
  '57473': {
    zip: '57473',
    city: 'Seneca',
    state: 'SD',
    latitude: '45.038006',
    longitude: '-99.131468',
    timeZoneId: 'America/Chicago',
  },
  '57474': {
    zip: '57474',
    city: 'Stratford',
    state: 'SD',
    latitude: '45.28606',
    longitude: '-98.280146',
    timeZoneId: 'America/Chicago',
  },
  '57475': {
    zip: '57475',
    city: 'Tolstoy',
    state: 'SD',
    latitude: '45.139364',
    longitude: '-99.620884',
    timeZoneId: 'America/Chicago',
  },
  '57476': {
    zip: '57476',
    city: 'Tulare',
    state: 'SD',
    latitude: '44.718766',
    longitude: '-98.584758',
    timeZoneId: 'America/Chicago',
  },
  '57477': {
    zip: '57477',
    city: 'Turton',
    state: 'SD',
    latitude: '45.023662',
    longitude: '-98.108189',
    timeZoneId: 'America/Chicago',
  },
  '57479': {
    zip: '57479',
    city: 'Warner',
    state: 'SD',
    latitude: '45.35026',
    longitude: '-98.443385',
    timeZoneId: 'America/Chicago',
  },
  '57481': {
    zip: '57481',
    city: 'Westport',
    state: 'SD',
    latitude: '45.62688',
    longitude: '-98.613',
    timeZoneId: 'America/Chicago',
  },
  '57501': {
    zip: '57501',
    city: 'Pierre',
    state: 'SD',
    latitude: '44.44692',
    longitude: '-100.18752',
    timeZoneId: 'America/Chicago',
  },
  '57520': {
    zip: '57520',
    city: 'Agar',
    state: 'SD',
    latitude: '44.845395',
    longitude: '-100.057684',
    timeZoneId: 'America/Chicago',
  },
  '57521': {
    zip: '57521',
    city: 'Belvidere',
    state: 'SD',
    latitude: '43.901848',
    longitude: '-101.246893',
    timeZoneId: 'America/Denver',
  },
  '57522': {
    zip: '57522',
    city: 'Blunt',
    state: 'SD',
    latitude: '44.513605',
    longitude: '-99.988191',
    timeZoneId: 'America/Chicago',
  },
  '57523': {
    zip: '57523',
    city: 'Burke',
    state: 'SD',
    latitude: '43.24482',
    longitude: '-99.23922',
    timeZoneId: 'America/Chicago',
  },
  '57528': {
    zip: '57528',
    city: 'Colome',
    state: 'SD',
    latitude: '43.262769',
    longitude: '-99.716287',
    timeZoneId: 'America/Chicago',
  },
  '57529': {
    zip: '57529',
    city: 'Dallas',
    state: 'SD',
    latitude: '43.234545',
    longitude: '-99.51164',
    timeZoneId: 'America/Chicago',
  },
  '57531': {
    zip: '57531',
    city: 'Draper',
    state: 'SD',
    latitude: '43.937546',
    longitude: '-100.467728',
    timeZoneId: 'America/Chicago',
  },
  '57532': {
    zip: '57532',
    city: 'Fort Pierre',
    state: 'SD',
    latitude: '44.388787',
    longitude: '-100.468252',
    timeZoneId: 'America/Denver',
  },
  '57533': {
    zip: '57533',
    city: 'Gregory',
    state: 'SD',
    latitude: '43.233905',
    longitude: '-99.427134',
    timeZoneId: 'America/Chicago',
  },
  '57534': {
    zip: '57534',
    city: 'Hamill',
    state: 'SD',
    latitude: '43.669954',
    longitude: '-99.699051',
    timeZoneId: 'America/Chicago',
  },
  '57536': {
    zip: '57536',
    city: 'Harrold',
    state: 'SD',
    latitude: '44.524105',
    longitude: '-99.741834',
    timeZoneId: 'America/Chicago',
  },
  '57537': {
    zip: '57537',
    city: 'Hayes',
    state: 'SD',
    latitude: '44.43364',
    longitude: '-100.54712',
    timeZoneId: 'America/Denver',
  },
  '57538': {
    zip: '57538',
    city: 'Herrick',
    state: 'SD',
    latitude: '43.113395',
    longitude: '-99.19792',
    timeZoneId: 'America/Chicago',
  },
  '57540': {
    zip: '57540',
    city: 'Holabird',
    state: 'SD',
    latitude: '44.515664',
    longitude: '-99.587927',
    timeZoneId: 'America/Chicago',
  },
  '57541': {
    zip: '57541',
    city: 'Ideal',
    state: 'SD',
    latitude: '43.587976',
    longitude: '-99.964462',
    timeZoneId: 'America/Chicago',
  },
  '57543': {
    zip: '57543',
    city: 'Kadoka',
    state: 'SD',
    latitude: '43.833284',
    longitude: '-101.486542',
    timeZoneId: 'America/Denver',
  },
  '57544': {
    zip: '57544',
    city: 'Kennebec',
    state: 'SD',
    latitude: '43.89102',
    longitude: '-99.84732',
    timeZoneId: 'America/Chicago',
  },
  '57547': {
    zip: '57547',
    city: 'Long Valley',
    state: 'SD',
    latitude: '43.557373',
    longitude: '-101.366701',
    timeZoneId: 'America/Denver',
  },
  '57548': {
    zip: '57548',
    city: 'Lower Brule',
    state: 'SD',
    latitude: '44.060655',
    longitude: '-99.695554',
    timeZoneId: 'America/Chicago',
  },
  '57551': {
    zip: '57551',
    city: 'Martin',
    state: 'SD',
    latitude: '43.21878',
    longitude: '-101.6667',
    timeZoneId: 'America/Denver',
  },
  '57552': {
    zip: '57552',
    city: 'Midland',
    state: 'SD',
    latitude: '44.2479',
    longitude: '-101.32068',
    timeZoneId: 'America/Denver',
  },
  '57553': {
    zip: '57553',
    city: 'Milesville',
    state: 'SD',
    latitude: '44.417024',
    longitude: '-101.80479',
    timeZoneId: 'America/Denver',
  },
  '57555': {
    zip: '57555',
    city: 'Mission',
    state: 'SD',
    latitude: '43.194176',
    longitude: '-100.477794',
    timeZoneId: 'America/Chicago',
  },
  '57559': {
    zip: '57559',
    city: 'Murdo',
    state: 'SD',
    latitude: '43.958192',
    longitude: '-100.699101',
    timeZoneId: 'America/Chicago',
  },
  '57560': {
    zip: '57560',
    city: 'Norris',
    state: 'SD',
    latitude: '43.473034',
    longitude: '-101.079381',
    timeZoneId: 'America/Chicago',
  },
  '57562': {
    zip: '57562',
    city: 'Okaton',
    state: 'SD',
    latitude: '43.988791',
    longitude: '-100.927838',
    timeZoneId: 'America/Chicago',
  },
  '57563': {
    zip: '57563',
    city: 'Okreek',
    state: 'SD',
    latitude: '43.360694',
    longitude: '-100.389419',
    timeZoneId: 'America/Chicago',
  },
  '57564': {
    zip: '57564',
    city: 'Onida',
    state: 'SD',
    latitude: '44.703712',
    longitude: '-100.063844',
    timeZoneId: 'America/Chicago',
  },
  '57566': {
    zip: '57566',
    city: 'Parmelee',
    state: 'SD',
    latitude: '43.331201',
    longitude: '-101.013019',
    timeZoneId: 'America/Chicago',
  },
  '57567': {
    zip: '57567',
    city: 'Philip',
    state: 'SD',
    latitude: '44.039632',
    longitude: '-101.663435',
    timeZoneId: 'America/Denver',
  },
  '57568': {
    zip: '57568',
    city: 'Presho',
    state: 'SD',
    latitude: '43.908664',
    longitude: '-100.053403',
    timeZoneId: 'America/Chicago',
  },
  '57569': {
    zip: '57569',
    city: 'Reliance',
    state: 'SD',
    latitude: '43.832195',
    longitude: '-99.527936',
    timeZoneId: 'America/Chicago',
  },
  '57570': {
    zip: '57570',
    city: 'Rosebud',
    state: 'SD',
    latitude: '43.268101',
    longitude: '-100.736849',
    timeZoneId: 'America/Chicago',
  },
  '57571': {
    zip: '57571',
    city: 'Saint Charles',
    state: 'SD',
    latitude: '43.108303',
    longitude: '-99.115247',
    timeZoneId: 'America/Chicago',
  },
  '57572': {
    zip: '57572',
    city: 'Saint Francis',
    state: 'SD',
    latitude: '43.142534',
    longitude: '-100.967723',
    timeZoneId: 'America/Chicago',
  },
  '57574': {
    zip: '57574',
    city: 'Tuthill',
    state: 'SD',
    latitude: '43.095698',
    longitude: '-101.439977',
    timeZoneId: 'America/Denver',
  },
  '57576': {
    zip: '57576',
    city: 'Vivian',
    state: 'SD',
    latitude: '44.021919',
    longitude: '-100.306929',
    timeZoneId: 'America/Chicago',
  },
  '57577': {
    zip: '57577',
    city: 'Wanblee',
    state: 'SD',
    latitude: '43.551517',
    longitude: '-101.805742',
    timeZoneId: 'America/Denver',
  },
  '57579': {
    zip: '57579',
    city: 'White River',
    state: 'SD',
    latitude: '43.564463',
    longitude: '-100.748538',
    timeZoneId: 'America/Chicago',
  },
  '57580': {
    zip: '57580',
    city: 'Winner',
    state: 'SD',
    latitude: '43.367573',
    longitude: '-99.865593',
    timeZoneId: 'America/Chicago',
  },
  '57584': {
    zip: '57584',
    city: 'Witten',
    state: 'SD',
    latitude: '43.463176',
    longitude: '-100.065744',
    timeZoneId: 'America/Chicago',
  },
  '57585': {
    zip: '57585',
    city: 'Wood',
    state: 'SD',
    latitude: '43.563165',
    longitude: '-100.408076',
    timeZoneId: 'America/Chicago',
  },
  '57601': {
    zip: '57601',
    city: 'Mobridge',
    state: 'SD',
    latitude: '45.53819',
    longitude: '-100.429946',
    timeZoneId: 'America/Chicago',
  },
  '57620': {
    zip: '57620',
    city: 'Bison',
    state: 'SD',
    latitude: '45.522518',
    longitude: '-102.472513',
    timeZoneId: 'America/Denver',
  },
  '57621': {
    zip: '57621',
    city: 'Bullhead',
    state: 'SD',
    latitude: '45.910278',
    longitude: '-101.09',
    timeZoneId: 'America/Denver',
  },
  '57622': {
    zip: '57622',
    city: 'Cherry Creek',
    state: 'SD',
    latitude: '44.611279',
    longitude: '-101.79628',
    timeZoneId: 'America/Denver',
  },
  '57623': {
    zip: '57623',
    city: 'Dupree',
    state: 'SD',
    latitude: '44.990004',
    longitude: '-101.647447',
    timeZoneId: 'America/Denver',
  },
  '57625': {
    zip: '57625',
    city: 'Eagle Butte',
    state: 'SD',
    latitude: '44.994139',
    longitude: '-101.237386',
    timeZoneId: 'America/Denver',
  },
  '57626': {
    zip: '57626',
    city: 'Faith',
    state: 'SD',
    latitude: '45.027132',
    longitude: '-102.038778',
    timeZoneId: 'America/Denver',
  },
  '57630': {
    zip: '57630',
    city: 'Glencross',
    state: 'SD',
    latitude: '45.435633',
    longitude: '-100.887977',
    timeZoneId: 'America/Denver',
  },
  '57631': {
    zip: '57631',
    city: 'Glenham',
    state: 'SD',
    latitude: '45.531715',
    longitude: '-100.271893',
    timeZoneId: 'America/Chicago',
  },
  '57632': {
    zip: '57632',
    city: 'Herreid',
    state: 'SD',
    latitude: '45.853332',
    longitude: '-100.024984',
    timeZoneId: 'America/Chicago',
  },
  '57633': {
    zip: '57633',
    city: 'Isabel',
    state: 'SD',
    latitude: '45.392234',
    longitude: '-101.430938',
    timeZoneId: 'America/Denver',
  },
  '57634': {
    zip: '57634',
    city: 'Keldron',
    state: 'SD',
    latitude: '45.880133',
    longitude: '-101.912927',
    timeZoneId: 'America/Denver',
  },
  '57636': {
    zip: '57636',
    city: 'Lantry',
    state: 'SD',
    latitude: '45.020239',
    longitude: '-101.433441',
    timeZoneId: 'America/Denver',
  },
  '57638': {
    zip: '57638',
    city: 'Lemmon',
    state: 'SD',
    latitude: '45.937575',
    longitude: '-102.160093',
    timeZoneId: 'America/Denver',
  },
  '57639': {
    zip: '57639',
    city: 'Little Eagle',
    state: 'SD',
    latitude: '45.679175',
    longitude: '-100.799069',
    timeZoneId: 'America/Denver',
  },
  '57640': {
    zip: '57640',
    city: 'Lodgepole',
    state: 'SD',
    latitude: '45.842233',
    longitude: '-102.75277',
    timeZoneId: 'America/Denver',
  },
  '57641': {
    zip: '57641',
    city: 'Mc Intosh',
    state: 'SD',
    latitude: '45.92181',
    longitude: '-101.35049',
    timeZoneId: 'America/Denver',
  },
  '57642': {
    zip: '57642',
    city: 'Mc Laughlin',
    state: 'SD',
    latitude: '45.810968',
    longitude: '-100.813494',
    timeZoneId: 'America/Denver',
  },
  '57644': {
    zip: '57644',
    city: 'Meadow',
    state: 'SD',
    latitude: '45.291739',
    longitude: '-102.295248',
    timeZoneId: 'America/Denver',
  },
  '57645': {
    zip: '57645',
    city: 'Morristown',
    state: 'SD',
    latitude: '45.882477',
    longitude: '-101.699955',
    timeZoneId: 'America/Denver',
  },
  '57646': {
    zip: '57646',
    city: 'Mound City',
    state: 'SD',
    latitude: '45.6753',
    longitude: '-100.04922',
    timeZoneId: 'America/Chicago',
  },
  '57648': {
    zip: '57648',
    city: 'Pollock',
    state: 'SD',
    latitude: '45.862843',
    longitude: '-100.320882',
    timeZoneId: 'America/Chicago',
  },
  '57649': {
    zip: '57649',
    city: 'Prairie City',
    state: 'SD',
    latitude: '45.603149',
    longitude: '-102.825211',
    timeZoneId: 'America/Denver',
  },
  '57650': {
    zip: '57650',
    city: 'Ralph',
    state: 'SD',
    latitude: '45.854527',
    longitude: '-103.034233',
    timeZoneId: 'America/Denver',
  },
  '57651': {
    zip: '57651',
    city: 'Reva',
    state: 'SD',
    latitude: '45.520913',
    longitude: '-103.062315',
    timeZoneId: 'America/Denver',
  },
  '57652': {
    zip: '57652',
    city: 'Ridgeview',
    state: 'SD',
    latitude: '45.085',
    longitude: '-100.8',
    timeZoneId: 'America/Denver',
  },
  '57656': {
    zip: '57656',
    city: 'Timber Lake',
    state: 'SD',
    latitude: '45.4277',
    longitude: '-101.074481',
    timeZoneId: 'America/Denver',
  },
  '57657': {
    zip: '57657',
    city: 'Trail City',
    state: 'SD',
    latitude: '45.41351',
    longitude: '-100.704693',
    timeZoneId: 'America/Denver',
  },
  '57658': {
    zip: '57658',
    city: 'Wakpala',
    state: 'SD',
    latitude: '45.786177',
    longitude: '-100.51483',
    timeZoneId: 'America/Denver',
  },
  '57659': {
    zip: '57659',
    city: 'Walker',
    state: 'SD',
    latitude: '45.907025',
    longitude: '-101.090746',
    timeZoneId: 'America/Denver',
  },
  '57660': {
    zip: '57660',
    city: 'Watauga',
    state: 'SD',
    latitude: '45.924308',
    longitude: '-101.512821',
    timeZoneId: 'America/Denver',
  },
  '57661': {
    zip: '57661',
    city: 'Whitehorse',
    state: 'SD',
    latitude: '45.268514',
    longitude: '-100.888467',
    timeZoneId: 'America/Denver',
  },
  '57701': {
    zip: '57701',
    city: 'Rapid City',
    state: 'SD',
    latitude: '44.08092',
    longitude: '-103.17294',
    timeZoneId: 'America/Denver',
  },
  '57702': {
    zip: '57702',
    city: 'Rapid City',
    state: 'SD',
    latitude: '44.063037',
    longitude: '-103.280916',
    timeZoneId: 'America/Denver',
  },
  '57703': {
    zip: '57703',
    city: 'Rapid City',
    state: 'SD',
    latitude: '44.079971',
    longitude: '-103.231979',
    timeZoneId: 'America/Denver',
  },
  '57706': {
    zip: '57706',
    city: 'Ellsworth AFB',
    state: 'SD',
    latitude: '44.150732',
    longitude: '-103.071312',
    timeZoneId: 'America/Denver',
  },
  '57709': {
    zip: '57709',
    city: 'Rapid City',
    state: 'SD',
    latitude: '44.232095',
    longitude: '-103.38932',
    timeZoneId: 'America/Denver',
  },
  '57714': {
    zip: '57714',
    city: 'Allen',
    state: 'SD',
    latitude: '43.303738',
    longitude: '-101.912773',
    timeZoneId: 'America/Denver',
  },
  '57716': {
    zip: '57716',
    city: 'Batesland',
    state: 'SD',
    latitude: '43.10412',
    longitude: '-102.19788',
    timeZoneId: 'America/Denver',
  },
  '57717': {
    zip: '57717',
    city: 'Belle Fourche',
    state: 'SD',
    latitude: '44.665219',
    longitude: '-103.847783',
    timeZoneId: 'America/Denver',
  },
  '57718': {
    zip: '57718',
    city: 'Black Hawk',
    state: 'SD',
    latitude: '44.156289',
    longitude: '-103.325645',
    timeZoneId: 'America/Denver',
  },
  '57719': {
    zip: '57719',
    city: 'Box Elder',
    state: 'SD',
    latitude: '44.116272',
    longitude: '-103.078498',
    timeZoneId: 'America/Denver',
  },
  '57720': {
    zip: '57720',
    city: 'Buffalo',
    state: 'SD',
    latitude: '45.50718',
    longitude: '-103.65618',
    timeZoneId: 'America/Denver',
  },
  '57722': {
    zip: '57722',
    city: 'Buffalo Gap',
    state: 'SD',
    latitude: '43.516065',
    longitude: '-103.261524',
    timeZoneId: 'America/Denver',
  },
  '57724': {
    zip: '57724',
    city: 'Camp Crook',
    state: 'SD',
    latitude: '45.61542',
    longitude: '-103.93308',
    timeZoneId: 'America/Denver',
  },
  '57725': {
    zip: '57725',
    city: 'Caputa',
    state: 'SD',
    latitude: '43.9743',
    longitude: '-102.95916',
    timeZoneId: 'America/Denver',
  },
  '57730': {
    zip: '57730',
    city: 'Custer',
    state: 'SD',
    latitude: '43.767311',
    longitude: '-103.595844',
    timeZoneId: 'America/Denver',
  },
  '57732': {
    zip: '57732',
    city: 'Deadwood',
    state: 'SD',
    latitude: '44.275833',
    longitude: '-103.665833',
    timeZoneId: 'America/Denver',
  },
  '57735': {
    zip: '57735',
    city: 'Edgemont',
    state: 'SD',
    latitude: '43.29601',
    longitude: '-103.829609',
    timeZoneId: 'America/Denver',
  },
  '57737': {
    zip: '57737',
    city: 'Enning',
    state: 'SD',
    latitude: '44.530268',
    longitude: '-102.590501',
    timeZoneId: 'America/Denver',
  },
  '57738': {
    zip: '57738',
    city: 'Fairburn',
    state: 'SD',
    latitude: '43.651331',
    longitude: '-103.221411',
    timeZoneId: 'America/Denver',
  },
  '57741': {
    zip: '57741',
    city: 'Fort Meade',
    state: 'SD',
    latitude: '44.409562',
    longitude: '-103.474998',
    timeZoneId: 'America/Denver',
  },
  '57744': {
    zip: '57744',
    city: 'Hermosa',
    state: 'SD',
    latitude: '43.838417',
    longitude: '-103.190898',
    timeZoneId: 'America/Denver',
  },
  '57745': {
    zip: '57745',
    city: 'Hill City',
    state: 'SD',
    latitude: '44.12446',
    longitude: '-103.71954',
    timeZoneId: 'America/Denver',
  },
  '57747': {
    zip: '57747',
    city: 'Hot Springs',
    state: 'SD',
    latitude: '43.433844',
    longitude: '-103.476204',
    timeZoneId: 'America/Denver',
  },
  '57748': {
    zip: '57748',
    city: 'Howes',
    state: 'SD',
    latitude: '44.541189',
    longitude: '-102.211846',
    timeZoneId: 'America/Denver',
  },
  '57750': {
    zip: '57750',
    city: 'Interior',
    state: 'SD',
    latitude: '43.73952',
    longitude: '-101.93328',
    timeZoneId: 'America/Denver',
  },
  '57751': {
    zip: '57751',
    city: 'Keystone',
    state: 'SD',
    latitude: '43.942765',
    longitude: '-103.362425',
    timeZoneId: 'America/Denver',
  },
  '57752': {
    zip: '57752',
    city: 'Kyle',
    state: 'SD',
    latitude: '43.535696',
    longitude: '-102.320814',
    timeZoneId: 'America/Denver',
  },
  '57754': {
    zip: '57754',
    city: 'Lead',
    state: 'SD',
    latitude: '44.350616',
    longitude: '-103.768341',
    timeZoneId: 'America/Denver',
  },
  '57755': {
    zip: '57755',
    city: 'Ludlow',
    state: 'SD',
    latitude: '45.848971',
    longitude: '-103.364264',
    timeZoneId: 'America/Denver',
  },
  '57756': {
    zip: '57756',
    city: 'Manderson',
    state: 'SD',
    latitude: '43.300674',
    longitude: '-102.383533',
    timeZoneId: 'America/Denver',
  },
  '57758': {
    zip: '57758',
    city: 'Mud Butte',
    state: 'SD',
    latitude: '44.89255',
    longitude: '-102.45181',
    timeZoneId: 'America/Denver',
  },
  '57759': {
    zip: '57759',
    city: 'Nemo',
    state: 'SD',
    latitude: '44.199962',
    longitude: '-103.516384',
    timeZoneId: 'America/Denver',
  },
  '57760': {
    zip: '57760',
    city: 'Newell',
    state: 'SD',
    latitude: '44.718125',
    longitude: '-103.420575',
    timeZoneId: 'America/Denver',
  },
  '57761': {
    zip: '57761',
    city: 'New Underwood',
    state: 'SD',
    latitude: '44.095452',
    longitude: '-102.832789',
    timeZoneId: 'America/Denver',
  },
  '57762': {
    zip: '57762',
    city: 'Nisland',
    state: 'SD',
    latitude: '44.673863',
    longitude: '-103.556841',
    timeZoneId: 'America/Denver',
  },
  '57763': {
    zip: '57763',
    city: 'Oelrichs',
    state: 'SD',
    latitude: '43.1791',
    longitude: '-103.232005',
    timeZoneId: 'America/Denver',
  },
  '57764': {
    zip: '57764',
    city: 'Oglala',
    state: 'SD',
    latitude: '43.185802',
    longitude: '-102.739517',
    timeZoneId: 'America/Denver',
  },
  '57766': {
    zip: '57766',
    city: 'Oral',
    state: 'SD',
    latitude: '43.36321',
    longitude: '-103.135325',
    timeZoneId: 'America/Denver',
  },
  '57767': {
    zip: '57767',
    city: 'Owanka',
    state: 'SD',
    latitude: '43.9944',
    longitude: '-102.5919',
    timeZoneId: 'America/Denver',
  },
  '57769': {
    zip: '57769',
    city: 'Piedmont',
    state: 'SD',
    latitude: '44.229112',
    longitude: '-103.390064',
    timeZoneId: 'America/Denver',
  },
  '57770': {
    zip: '57770',
    city: 'Pine Ridge',
    state: 'SD',
    latitude: '43.23222',
    longitude: '-102.69672',
    timeZoneId: 'America/Denver',
  },
  '57772': {
    zip: '57772',
    city: 'Porcupine',
    state: 'SD',
    latitude: '43.49746',
    longitude: '-102.50168',
    timeZoneId: 'America/Denver',
  },
  '57773': {
    zip: '57773',
    city: 'Pringle',
    state: 'SD',
    latitude: '43.609429',
    longitude: '-103.594212',
    timeZoneId: 'America/Denver',
  },
  '57775': {
    zip: '57775',
    city: 'Quinn',
    state: 'SD',
    latitude: '44.0973',
    longitude: '-102.10992',
    timeZoneId: 'America/Denver',
  },
  '57776': {
    zip: '57776',
    city: 'Redig',
    state: 'SD',
    latitude: '45.268253',
    longitude: '-103.547357',
    timeZoneId: 'America/Denver',
  },
  '57779': {
    zip: '57779',
    city: 'Saint Onge',
    state: 'SD',
    latitude: '44.561575',
    longitude: '-103.748203',
    timeZoneId: 'America/Denver',
  },
  '57780': {
    zip: '57780',
    city: 'Scenic',
    state: 'SD',
    latitude: '43.794115',
    longitude: '-102.431907',
    timeZoneId: 'America/Denver',
  },
  '57782': {
    zip: '57782',
    city: 'Smithwick',
    state: 'SD',
    latitude: '43.267905',
    longitude: '-103.166889',
    timeZoneId: 'America/Denver',
  },
  '57783': {
    zip: '57783',
    city: 'Spearfish',
    state: 'SD',
    latitude: '44.49478',
    longitude: '-103.867617',
    timeZoneId: 'America/Denver',
  },
  '57785': {
    zip: '57785',
    city: 'Sturgis',
    state: 'SD',
    latitude: '44.408276',
    longitude: '-103.511437',
    timeZoneId: 'America/Denver',
  },
  '57787': {
    zip: '57787',
    city: 'Union Center',
    state: 'SD',
    latitude: '44.733611',
    longitude: '-102.654167',
    timeZoneId: 'America/Denver',
  },
  '57788': {
    zip: '57788',
    city: 'Vale',
    state: 'SD',
    latitude: '44.629977',
    longitude: '-103.368084',
    timeZoneId: 'America/Denver',
  },
  '57790': {
    zip: '57790',
    city: 'Wall',
    state: 'SD',
    latitude: '44.25528',
    longitude: '-102.20779',
    timeZoneId: 'America/Denver',
  },
  '57791': {
    zip: '57791',
    city: 'Wasta',
    state: 'SD',
    latitude: '44.09497',
    longitude: '-102.335284',
    timeZoneId: 'America/Denver',
  },
  '57792': {
    zip: '57792',
    city: 'White Owl',
    state: 'SD',
    latitude: '44.614087',
    longitude: '-102.426405',
    timeZoneId: 'America/Denver',
  },
  '57793': {
    zip: '57793',
    city: 'Whitewood',
    state: 'SD',
    latitude: '44.464081',
    longitude: '-103.637351',
    timeZoneId: 'America/Denver',
  },
  '57794': {
    zip: '57794',
    city: 'Wounded Knee',
    state: 'SD',
    latitude: '43.160272',
    longitude: '-102.359904',
    timeZoneId: 'America/Denver',
  },
  '57799': {
    zip: '57799',
    city: 'Spearfish',
    state: 'SD',
    latitude: '44.485188',
    longitude: '-103.85954',
    timeZoneId: 'America/Denver',
  },
  '58001': {
    zip: '58001',
    city: 'Abercrombie',
    state: 'ND',
    latitude: '46.416396',
    longitude: '-96.715727',
    timeZoneId: 'America/Chicago',
  },
  '58002': {
    zip: '58002',
    city: 'Absaraka',
    state: 'ND',
    latitude: '46.97781',
    longitude: '-97.39463',
    timeZoneId: 'America/Chicago',
  },
  '58004': {
    zip: '58004',
    city: 'Amenia',
    state: 'ND',
    latitude: '47.022377',
    longitude: '-97.249357',
    timeZoneId: 'America/Chicago',
  },
  '58005': {
    zip: '58005',
    city: 'Argusville',
    state: 'ND',
    latitude: '47.143799',
    longitude: '-96.887381',
    timeZoneId: 'America/Chicago',
  },
  '58006': {
    zip: '58006',
    city: 'Arthur',
    state: 'ND',
    latitude: '47.109108',
    longitude: '-97.195403',
    timeZoneId: 'America/Chicago',
  },
  '58007': {
    zip: '58007',
    city: 'Ayr',
    state: 'ND',
    latitude: '47.020062',
    longitude: '-97.579977',
    timeZoneId: 'America/Chicago',
  },
  '58008': {
    zip: '58008',
    city: 'Barney',
    state: 'ND',
    latitude: '46.26551',
    longitude: '-96.999489',
    timeZoneId: 'America/Chicago',
  },
  '58009': {
    zip: '58009',
    city: 'Blanchard',
    state: 'ND',
    latitude: '47.367844',
    longitude: '-97.267873',
    timeZoneId: 'America/Chicago',
  },
  '58011': {
    zip: '58011',
    city: 'Buffalo',
    state: 'ND',
    latitude: '46.933102',
    longitude: '-97.490429',
    timeZoneId: 'America/Chicago',
  },
  '58012': {
    zip: '58012',
    city: 'Casselton',
    state: 'ND',
    latitude: '46.902199',
    longitude: '-97.211966',
    timeZoneId: 'America/Chicago',
  },
  '58013': {
    zip: '58013',
    city: 'Cayuga',
    state: 'ND',
    latitude: '46.138155',
    longitude: '-97.426616',
    timeZoneId: 'America/Chicago',
  },
  '58015': {
    zip: '58015',
    city: 'Christine',
    state: 'ND',
    latitude: '46.53261',
    longitude: '-96.78293',
    timeZoneId: 'America/Chicago',
  },
  '58016': {
    zip: '58016',
    city: 'Clifford',
    state: 'ND',
    latitude: '47.36709',
    longitude: '-97.410181',
    timeZoneId: 'America/Chicago',
  },
  '58017': {
    zip: '58017',
    city: 'Cogswell',
    state: 'ND',
    latitude: '46.10064',
    longitude: '-97.80942',
    timeZoneId: 'America/Chicago',
  },
  '58018': {
    zip: '58018',
    city: 'Colfax',
    state: 'ND',
    latitude: '46.500867',
    longitude: '-97.048646',
    timeZoneId: 'America/Chicago',
  },
  '58021': {
    zip: '58021',
    city: 'Davenport',
    state: 'ND',
    latitude: '46.715969',
    longitude: '-97.069115',
    timeZoneId: 'America/Chicago',
  },
  '58027': {
    zip: '58027',
    city: 'Enderlin',
    state: 'ND',
    latitude: '46.62157',
    longitude: '-97.601885',
    timeZoneId: 'America/Chicago',
  },
  '58029': {
    zip: '58029',
    city: 'Erie',
    state: 'ND',
    latitude: '47.110593',
    longitude: '-97.387357',
    timeZoneId: 'America/Chicago',
  },
  '58030': {
    zip: '58030',
    city: 'Fairmount',
    state: 'ND',
    latitude: '46.05405',
    longitude: '-96.603632',
    timeZoneId: 'America/Chicago',
  },
  '58031': {
    zip: '58031',
    city: 'Fingal',
    state: 'ND',
    latitude: '46.761371',
    longitude: '-97.791535',
    timeZoneId: 'America/Chicago',
  },
  '58032': {
    zip: '58032',
    city: 'Forman',
    state: 'ND',
    latitude: '46.107812',
    longitude: '-97.638188',
    timeZoneId: 'America/Chicago',
  },
  '58033': {
    zip: '58033',
    city: 'Fort Ransom',
    state: 'ND',
    latitude: '46.4337',
    longitude: '-97.8624',
    timeZoneId: 'America/Chicago',
  },
  '58035': {
    zip: '58035',
    city: 'Galesburg',
    state: 'ND',
    latitude: '47.281586',
    longitude: '-97.326614',
    timeZoneId: 'America/Chicago',
  },
  '58036': {
    zip: '58036',
    city: 'Gardner',
    state: 'ND',
    latitude: '47.108096',
    longitude: '-97.007998',
    timeZoneId: 'America/Chicago',
  },
  '58038': {
    zip: '58038',
    city: 'Grandin',
    state: 'ND',
    latitude: '47.195959',
    longitude: '-97.068257',
    timeZoneId: 'America/Chicago',
  },
  '58040': {
    zip: '58040',
    city: 'Gwinner',
    state: 'ND',
    latitude: '46.22448',
    longitude: '-97.76064',
    timeZoneId: 'America/Chicago',
  },
  '58041': {
    zip: '58041',
    city: 'Hankinson',
    state: 'ND',
    latitude: '46.073429',
    longitude: '-96.899282',
    timeZoneId: 'America/Chicago',
  },
  '58042': {
    zip: '58042',
    city: 'Harwood',
    state: 'ND',
    latitude: '46.99614',
    longitude: '-96.89946',
    timeZoneId: 'America/Chicago',
  },
  '58043': {
    zip: '58043',
    city: 'Havana',
    state: 'ND',
    latitude: '45.977636',
    longitude: '-97.626381',
    timeZoneId: 'America/Chicago',
  },
  '58045': {
    zip: '58045',
    city: 'Hillsboro',
    state: 'ND',
    latitude: '47.400171',
    longitude: '-97.060426',
    timeZoneId: 'America/Chicago',
  },
  '58046': {
    zip: '58046',
    city: 'Hope',
    state: 'ND',
    latitude: '47.20002',
    longitude: '-97.78392',
    timeZoneId: 'America/Chicago',
  },
  '58047': {
    zip: '58047',
    city: 'Horace',
    state: 'ND',
    latitude: '46.71762',
    longitude: '-96.85668',
    timeZoneId: 'America/Chicago',
  },
  '58048': {
    zip: '58048',
    city: 'Hunter',
    state: 'ND',
    latitude: '47.19587',
    longitude: '-97.323836',
    timeZoneId: 'America/Chicago',
  },
  '58049': {
    zip: '58049',
    city: 'Kathryn',
    state: 'ND',
    latitude: '46.7019',
    longitude: '-97.9854',
    timeZoneId: 'America/Chicago',
  },
  '58051': {
    zip: '58051',
    city: 'Kindred',
    state: 'ND',
    latitude: '46.650631',
    longitude: '-97.014151',
    timeZoneId: 'America/Chicago',
  },
  '58052': {
    zip: '58052',
    city: 'Leonard',
    state: 'ND',
    latitude: '46.651382',
    longitude: '-97.245736',
    timeZoneId: 'America/Chicago',
  },
  '58053': {
    zip: '58053',
    city: 'Lidgerwood',
    state: 'ND',
    latitude: '46.077722',
    longitude: '-97.15076',
    timeZoneId: 'America/Chicago',
  },
  '58054': {
    zip: '58054',
    city: 'Lisbon',
    state: 'ND',
    latitude: '46.41534',
    longitude: '-97.65312',
    timeZoneId: 'America/Chicago',
  },
  '58056': {
    zip: '58056',
    city: 'Luverne',
    state: 'ND',
    latitude: '47.311519',
    longitude: '-97.738327',
    timeZoneId: 'America/Chicago',
  },
  '58057': {
    zip: '58057',
    city: 'McLeod',
    state: 'ND',
    latitude: '46.506063',
    longitude: '-97.187561',
    timeZoneId: 'America/Chicago',
  },
  '58058': {
    zip: '58058',
    city: 'Mantador',
    state: 'ND',
    latitude: '46.16444',
    longitude: '-96.976254',
    timeZoneId: 'America/Chicago',
  },
  '58059': {
    zip: '58059',
    city: 'Mapleton',
    state: 'ND',
    latitude: '46.86714',
    longitude: '-97.06932',
    timeZoneId: 'America/Chicago',
  },
  '58060': {
    zip: '58060',
    city: 'Milnor',
    state: 'ND',
    latitude: '46.25664',
    longitude: '-97.43484',
    timeZoneId: 'America/Chicago',
  },
  '58061': {
    zip: '58061',
    city: 'Mooreton',
    state: 'ND',
    latitude: '46.270018',
    longitude: '-96.875133',
    timeZoneId: 'America/Chicago',
  },
  '58062': {
    zip: '58062',
    city: 'Nome',
    state: 'ND',
    latitude: '46.672685',
    longitude: '-97.785523',
    timeZoneId: 'America/Chicago',
  },
  '58063': {
    zip: '58063',
    city: 'Oriska',
    state: 'ND',
    latitude: '46.948433',
    longitude: '-97.776839',
    timeZoneId: 'America/Chicago',
  },
  '58064': {
    zip: '58064',
    city: 'Page',
    state: 'ND',
    latitude: '47.142437',
    longitude: '-97.615224',
    timeZoneId: 'America/Chicago',
  },
  '58065': {
    zip: '58065',
    city: 'Pillsbury',
    state: 'ND',
    latitude: '47.207337',
    longitude: '-97.798558',
    timeZoneId: 'America/Chicago',
  },
  '58067': {
    zip: '58067',
    city: 'Rutland',
    state: 'ND',
    latitude: '46.054802',
    longitude: '-97.502278',
    timeZoneId: 'America/Chicago',
  },
  '58068': {
    zip: '58068',
    city: 'Sheldon',
    state: 'ND',
    latitude: '46.531057',
    longitude: '-97.411056',
    timeZoneId: 'America/Chicago',
  },
  '58069': {
    zip: '58069',
    city: 'Stirum',
    state: 'ND',
    latitude: '46.245831',
    longitude: '-97.645578',
    timeZoneId: 'America/Chicago',
  },
  '58071': {
    zip: '58071',
    city: 'Tower City',
    state: 'ND',
    latitude: '46.893335',
    longitude: '-97.634254',
    timeZoneId: 'America/Chicago',
  },
  '58072': {
    zip: '58072',
    city: 'Valley City',
    state: 'ND',
    latitude: '46.924715',
    longitude: '-98.00287',
    timeZoneId: 'America/Chicago',
  },
  '58074': {
    zip: '58074',
    city: 'Wahpeton',
    state: 'ND',
    latitude: '46.281699',
    longitude: '-96.609083',
    timeZoneId: 'America/Chicago',
  },
  '58075': {
    zip: '58075',
    city: 'Wahpeton',
    state: 'ND',
    latitude: '46.273495',
    longitude: '-96.609475',
    timeZoneId: 'America/Chicago',
  },
  '58076': {
    zip: '58076',
    city: 'Wahpeton',
    state: 'ND',
    latitude: '46.268399',
    longitude: '-96.607702',
    timeZoneId: 'America/Chicago',
  },
  '58077': {
    zip: '58077',
    city: 'Walcott',
    state: 'ND',
    latitude: '46.502565',
    longitude: '-97.072903',
    timeZoneId: 'America/Chicago',
  },
  '58078': {
    zip: '58078',
    city: 'West Fargo',
    state: 'ND',
    latitude: '46.886111',
    longitude: '-96.9',
    timeZoneId: 'America/Chicago',
  },
  '58079': {
    zip: '58079',
    city: 'Wheatland',
    state: 'ND',
    latitude: '46.877214',
    longitude: '-97.409644',
    timeZoneId: 'America/Chicago',
  },
  '58081': {
    zip: '58081',
    city: 'Wyndmere',
    state: 'ND',
    latitude: '46.262652',
    longitude: '-97.133102',
    timeZoneId: 'America/Chicago',
  },
  '58102': {
    zip: '58102',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.893312',
    longitude: '-96.798609',
    timeZoneId: 'America/Chicago',
  },
  '58103': {
    zip: '58103',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.852',
    longitude: '-96.814801',
    timeZoneId: 'America/Chicago',
  },
  '58104': {
    zip: '58104',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.819706',
    longitude: '-96.808039',
    timeZoneId: 'America/Chicago',
  },
  '58105': {
    zip: '58105',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.911206',
    longitude: '-96.7937',
    timeZoneId: 'America/Chicago',
  },
  '58106': {
    zip: '58106',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.85283',
    longitude: '-96.823352',
    timeZoneId: 'America/Chicago',
  },
  '58107': {
    zip: '58107',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.825832',
    longitude: '-96.848634',
    timeZoneId: 'America/Chicago',
  },
  '58108': {
    zip: '58108',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.851768',
    longitude: '-96.800808',
    timeZoneId: 'America/Chicago',
  },
  '58109': {
    zip: '58109',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.808187',
    longitude: '-96.86228',
    timeZoneId: 'America/Chicago',
  },
  '58121': {
    zip: '58121',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.865114',
    longitude: '-96.861885',
    timeZoneId: 'America/Chicago',
  },
  '58122': {
    zip: '58122',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.881557',
    longitude: '-96.784709',
    timeZoneId: 'America/Chicago',
  },
  '58124': {
    zip: '58124',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.873899',
    longitude: '-96.786609',
    timeZoneId: 'America/Chicago',
  },
  '58125': {
    zip: '58125',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.879774',
    longitude: '-96.814895',
    timeZoneId: 'America/Chicago',
  },
  '58126': {
    zip: '58126',
    city: 'Fargo',
    state: 'ND',
    latitude: '46.874888',
    longitude: '-96.784488',
    timeZoneId: 'America/Chicago',
  },
  '58201': {
    zip: '58201',
    city: 'Grand Forks',
    state: 'ND',
    latitude: '47.912435',
    longitude: '-97.052752',
    timeZoneId: 'America/Chicago',
  },
  '58202': {
    zip: '58202',
    city: 'Grand Forks',
    state: 'ND',
    latitude: '47.918885',
    longitude: '-97.07318',
    timeZoneId: 'America/Chicago',
  },
  '58203': {
    zip: '58203',
    city: 'Grand Forks',
    state: 'ND',
    latitude: '47.929943',
    longitude: '-97.071986',
    timeZoneId: 'America/Chicago',
  },
  '58204': {
    zip: '58204',
    city: 'Grand Forks AFB',
    state: 'ND',
    latitude: '47.960971',
    longitude: '-97.370375',
    timeZoneId: 'America/Chicago',
  },
  '58205': {
    zip: '58205',
    city: 'Grand Forks AFB',
    state: 'ND',
    latitude: '47.925264',
    longitude: '-97.241434',
    timeZoneId: 'America/Chicago',
  },
  '58206': {
    zip: '58206',
    city: 'Grand Forks',
    state: 'ND',
    latitude: '47.899294',
    longitude: '-97.067616',
    timeZoneId: 'America/Chicago',
  },
  '58207': {
    zip: '58207',
    city: 'Grand Forks',
    state: 'ND',
    latitude: '47.91674',
    longitude: '-97.367273',
    timeZoneId: 'America/Chicago',
  },
  '58208': {
    zip: '58208',
    city: 'Grand Forks',
    state: 'ND',
    latitude: '47.880381',
    longitude: '-97.062167',
    timeZoneId: 'America/Chicago',
  },
  '58210': {
    zip: '58210',
    city: 'Adams',
    state: 'ND',
    latitude: '48.412292',
    longitude: '-98.1218',
    timeZoneId: 'America/Chicago',
  },
  '58212': {
    zip: '58212',
    city: 'Aneta',
    state: 'ND',
    latitude: '47.7595',
    longitude: '-97.948747',
    timeZoneId: 'America/Chicago',
  },
  '58214': {
    zip: '58214',
    city: 'Arvilla',
    state: 'ND',
    latitude: '47.889871',
    longitude: '-97.497276',
    timeZoneId: 'America/Chicago',
  },
  '58216': {
    zip: '58216',
    city: 'Bathgate',
    state: 'ND',
    latitude: '48.860344',
    longitude: '-97.489203',
    timeZoneId: 'America/Chicago',
  },
  '58218': {
    zip: '58218',
    city: 'Buxton',
    state: 'ND',
    latitude: '47.603307',
    longitude: '-97.100502',
    timeZoneId: 'America/Chicago',
  },
  '58219': {
    zip: '58219',
    city: 'Caledonia',
    state: 'ND',
    latitude: '47.450596',
    longitude: '-96.917137',
    timeZoneId: 'America/Chicago',
  },
  '58220': {
    zip: '58220',
    city: 'Cavalier',
    state: 'ND',
    latitude: '48.782525',
    longitude: '-97.607866',
    timeZoneId: 'America/Chicago',
  },
  '58222': {
    zip: '58222',
    city: 'Crystal',
    state: 'ND',
    latitude: '48.586179',
    longitude: '-97.666904',
    timeZoneId: 'America/Chicago',
  },
  '58223': {
    zip: '58223',
    city: 'Cummings',
    state: 'ND',
    latitude: '47.543956',
    longitude: '-96.976085',
    timeZoneId: 'America/Chicago',
  },
  '58224': {
    zip: '58224',
    city: 'Dahlen',
    state: 'ND',
    latitude: '48.168809',
    longitude: '-97.96676',
    timeZoneId: 'America/Chicago',
  },
  '58225': {
    zip: '58225',
    city: 'Drayton',
    state: 'ND',
    latitude: '48.567554',
    longitude: '-97.176905',
    timeZoneId: 'America/Chicago',
  },
  '58227': {
    zip: '58227',
    city: 'Edinburg',
    state: 'ND',
    latitude: '48.48822',
    longitude: '-97.9158',
    timeZoneId: 'America/Chicago',
  },
  '58228': {
    zip: '58228',
    city: 'Emerado',
    state: 'ND',
    latitude: '47.919406',
    longitude: '-97.369328',
    timeZoneId: 'America/Chicago',
  },
  '58229': {
    zip: '58229',
    city: 'Fairdale',
    state: 'ND',
    latitude: '48.472054',
    longitude: '-98.208653',
    timeZoneId: 'America/Chicago',
  },
  '58230': {
    zip: '58230',
    city: 'Finley',
    state: 'ND',
    latitude: '47.537087',
    longitude: '-97.710357',
    timeZoneId: 'America/Chicago',
  },
  '58231': {
    zip: '58231',
    city: 'Fordville',
    state: 'ND',
    latitude: '48.241082',
    longitude: '-97.838469',
    timeZoneId: 'America/Chicago',
  },
  '58233': {
    zip: '58233',
    city: 'Forest River',
    state: 'ND',
    latitude: '48.237188',
    longitude: '-97.447403',
    timeZoneId: 'America/Chicago',
  },
  '58235': {
    zip: '58235',
    city: 'Gilby',
    state: 'ND',
    latitude: '48.104302',
    longitude: '-97.450273',
    timeZoneId: 'America/Chicago',
  },
  '58236': {
    zip: '58236',
    city: 'Glasston',
    state: 'ND',
    latitude: '48.70457',
    longitude: '-97.446544',
    timeZoneId: 'America/Chicago',
  },
  '58237': {
    zip: '58237',
    city: 'Grafton',
    state: 'ND',
    latitude: '48.4152',
    longitude: '-97.41174',
    timeZoneId: 'America/Chicago',
  },
  '58238': {
    zip: '58238',
    city: 'Hamilton',
    state: 'ND',
    latitude: '48.773014',
    longitude: '-97.492711',
    timeZoneId: 'America/Chicago',
  },
  '58239': {
    zip: '58239',
    city: 'Hannah',
    state: 'ND',
    latitude: '48.947689',
    longitude: '-98.737664',
    timeZoneId: 'America/Chicago',
  },
  '58240': {
    zip: '58240',
    city: 'Hatton',
    state: 'ND',
    latitude: '47.639762',
    longitude: '-97.456978',
    timeZoneId: 'America/Chicago',
  },
  '58241': {
    zip: '58241',
    city: 'Hensel',
    state: 'ND',
    latitude: '48.674622',
    longitude: '-97.600004',
    timeZoneId: 'America/Chicago',
  },
  '58243': {
    zip: '58243',
    city: 'Hoople',
    state: 'ND',
    latitude: '48.506766',
    longitude: '-97.581561',
    timeZoneId: 'America/Chicago',
  },
  '58244': {
    zip: '58244',
    city: 'Inkster',
    state: 'ND',
    latitude: '48.11688',
    longitude: '-97.72338',
    timeZoneId: 'America/Chicago',
  },
  '58249': {
    zip: '58249',
    city: 'Langdon',
    state: 'ND',
    latitude: '48.763045',
    longitude: '-98.375077',
    timeZoneId: 'America/Chicago',
  },
  '58250': {
    zip: '58250',
    city: 'Lankin',
    state: 'ND',
    latitude: '48.289211',
    longitude: '-98.059895',
    timeZoneId: 'America/Chicago',
  },
  '58251': {
    zip: '58251',
    city: 'Larimore',
    state: 'ND',
    latitude: '47.89836',
    longitude: '-97.66914',
    timeZoneId: 'America/Chicago',
  },
  '58254': {
    zip: '58254',
    city: 'McVille',
    state: 'ND',
    latitude: '47.77668',
    longitude: '-98.1648',
    timeZoneId: 'America/Chicago',
  },
  '58255': {
    zip: '58255',
    city: 'Maida',
    state: 'ND',
    latitude: '48.943927',
    longitude: '-98.395342',
    timeZoneId: 'America/Chicago',
  },
  '58256': {
    zip: '58256',
    city: 'Manvel',
    state: 'ND',
    latitude: '48.100804',
    longitude: '-97.246341',
    timeZoneId: 'America/Chicago',
  },
  '58257': {
    zip: '58257',
    city: 'Mayville',
    state: 'ND',
    latitude: '47.49838',
    longitude: '-97.323749',
    timeZoneId: 'America/Chicago',
  },
  '58258': {
    zip: '58258',
    city: 'Mekinock',
    state: 'ND',
    latitude: '47.978812',
    longitude: '-97.507382',
    timeZoneId: 'America/Chicago',
  },
  '58259': {
    zip: '58259',
    city: 'Michigan',
    state: 'ND',
    latitude: '48.0624',
    longitude: '-98.12538',
    timeZoneId: 'America/Chicago',
  },
  '58260': {
    zip: '58260',
    city: 'Milton',
    state: 'ND',
    latitude: '48.629355',
    longitude: '-97.990765',
    timeZoneId: 'America/Chicago',
  },
  '58261': {
    zip: '58261',
    city: 'Minto',
    state: 'ND',
    latitude: '48.29113',
    longitude: '-97.372047',
    timeZoneId: 'America/Chicago',
  },
  '58262': {
    zip: '58262',
    city: 'Mountain',
    state: 'ND',
    latitude: '48.673234',
    longitude: '-97.797096',
    timeZoneId: 'America/Chicago',
  },
  '58265': {
    zip: '58265',
    city: 'Neche',
    state: 'ND',
    latitude: '48.946127',
    longitude: '-97.483185',
    timeZoneId: 'America/Chicago',
  },
  '58266': {
    zip: '58266',
    city: 'Niagara',
    state: 'ND',
    latitude: '47.975136',
    longitude: '-97.813747',
    timeZoneId: 'America/Chicago',
  },
  '58267': {
    zip: '58267',
    city: 'Northwood',
    state: 'ND',
    latitude: '47.74392',
    longitude: '-97.58388',
    timeZoneId: 'America/Chicago',
  },
  '58269': {
    zip: '58269',
    city: 'Osnabrock',
    state: 'ND',
    latitude: '48.631626',
    longitude: '-98.250432',
    timeZoneId: 'America/Chicago',
  },
  '58270': {
    zip: '58270',
    city: 'Park River',
    state: 'ND',
    latitude: '48.399566',
    longitude: '-97.744639',
    timeZoneId: 'America/Chicago',
  },
  '58271': {
    zip: '58271',
    city: 'Pembina',
    state: 'ND',
    latitude: '48.818056',
    longitude: '-97.227222',
    timeZoneId: 'America/Chicago',
  },
  '58272': {
    zip: '58272',
    city: 'Petersburg',
    state: 'ND',
    latitude: '47.974888',
    longitude: '-97.960513',
    timeZoneId: 'America/Chicago',
  },
  '58273': {
    zip: '58273',
    city: 'Pisek',
    state: 'ND',
    latitude: '48.279886',
    longitude: '-97.709129',
    timeZoneId: 'America/Chicago',
  },
  '58274': {
    zip: '58274',
    city: 'Portland',
    state: 'ND',
    latitude: '47.499181',
    longitude: '-97.372975',
    timeZoneId: 'America/Chicago',
  },
  '58275': {
    zip: '58275',
    city: 'Reynolds',
    state: 'ND',
    latitude: '47.671123',
    longitude: '-97.104503',
    timeZoneId: 'America/Chicago',
  },
  '58276': {
    zip: '58276',
    city: 'Saint Thomas',
    state: 'ND',
    latitude: '48.6297',
    longitude: '-97.469949',
    timeZoneId: 'America/Chicago',
  },
  '58277': {
    zip: '58277',
    city: 'Sharon',
    state: 'ND',
    latitude: '47.632977',
    longitude: '-97.921048',
    timeZoneId: 'America/Chicago',
  },
  '58278': {
    zip: '58278',
    city: 'Thompson',
    state: 'ND',
    latitude: '47.775505',
    longitude: '-97.103322',
    timeZoneId: 'America/Chicago',
  },
  '58281': {
    zip: '58281',
    city: 'Wales',
    state: 'ND',
    latitude: '48.946518',
    longitude: '-98.541361',
    timeZoneId: 'America/Chicago',
  },
  '58282': {
    zip: '58282',
    city: 'Walhalla',
    state: 'ND',
    latitude: '48.91614',
    longitude: '-97.89156',
    timeZoneId: 'America/Chicago',
  },
  '58301': {
    zip: '58301',
    city: 'Devils Lake',
    state: 'ND',
    latitude: '48.112685',
    longitude: '-98.856665',
    timeZoneId: 'America/Chicago',
  },
  '58310': {
    zip: '58310',
    city: 'Agate',
    state: 'ND',
    latitude: '48.62309',
    longitude: '-99.43906',
    timeZoneId: 'America/Chicago',
  },
  '58311': {
    zip: '58311',
    city: 'Alsen',
    state: 'ND',
    latitude: '48.629815',
    longitude: '-98.589847',
    timeZoneId: 'America/Chicago',
  },
  '58313': {
    zip: '58313',
    city: 'Balta',
    state: 'ND',
    latitude: '48.164163',
    longitude: '-100.03631',
    timeZoneId: 'America/Chicago',
  },
  '58316': {
    zip: '58316',
    city: 'Belcourt',
    state: 'ND',
    latitude: '48.664977',
    longitude: '-99.841349',
    timeZoneId: 'America/Chicago',
  },
  '58317': {
    zip: '58317',
    city: 'Bisbee',
    state: 'ND',
    latitude: '48.624825',
    longitude: '-99.379428',
    timeZoneId: 'America/Chicago',
  },
  '58318': {
    zip: '58318',
    city: 'Bottineau',
    state: 'ND',
    latitude: '48.9051',
    longitude: '-100.42452',
    timeZoneId: 'America/Chicago',
  },
  '58321': {
    zip: '58321',
    city: 'Brocket',
    state: 'ND',
    latitude: '48.239414',
    longitude: '-98.356267',
    timeZoneId: 'America/Chicago',
  },
  '58323': {
    zip: '58323',
    city: 'Calvin',
    state: 'ND',
    latitude: '48.802865',
    longitude: '-98.936626',
    timeZoneId: 'America/Chicago',
  },
  '58324': {
    zip: '58324',
    city: 'Cando',
    state: 'ND',
    latitude: '48.488286',
    longitude: '-99.201112',
    timeZoneId: 'America/Chicago',
  },
  '58325': {
    zip: '58325',
    city: 'Churchs Ferry',
    state: 'ND',
    latitude: '48.317148',
    longitude: '-99.084451',
    timeZoneId: 'America/Chicago',
  },
  '58327': {
    zip: '58327',
    city: 'Crary',
    state: 'ND',
    latitude: '48.013856',
    longitude: '-98.604066',
    timeZoneId: 'America/Chicago',
  },
  '58329': {
    zip: '58329',
    city: 'Dunseith',
    state: 'ND',
    latitude: '48.815206',
    longitude: '-100.06216',
    timeZoneId: 'America/Chicago',
  },
  '58330': {
    zip: '58330',
    city: 'Edmore',
    state: 'ND',
    latitude: '48.458045',
    longitude: '-98.448032',
    timeZoneId: 'America/Chicago',
  },
  '58331': {
    zip: '58331',
    city: 'Egeland',
    state: 'ND',
    latitude: '48.63111',
    longitude: '-99.099433',
    timeZoneId: 'America/Chicago',
  },
  '58332': {
    zip: '58332',
    city: 'Esmond',
    state: 'ND',
    latitude: '48.09948',
    longitude: '-99.7056',
    timeZoneId: 'America/Chicago',
  },
  '58335': {
    zip: '58335',
    city: 'Fort Totten',
    state: 'ND',
    latitude: '47.975298',
    longitude: '-98.994286',
    timeZoneId: 'America/Chicago',
  },
  '58338': {
    zip: '58338',
    city: 'Hampden',
    state: 'ND',
    latitude: '48.503581',
    longitude: '-98.643672',
    timeZoneId: 'America/Chicago',
  },
  '58339': {
    zip: '58339',
    city: 'Hansboro',
    state: 'ND',
    latitude: '48.848122',
    longitude: '-99.381035',
    timeZoneId: 'America/Chicago',
  },
  '58341': {
    zip: '58341',
    city: 'Harvey',
    state: 'ND',
    latitude: '47.7756',
    longitude: '-99.90642',
    timeZoneId: 'America/Chicago',
  },
  '58343': {
    zip: '58343',
    city: 'Knox',
    state: 'ND',
    latitude: '48.327756',
    longitude: '-99.654741',
    timeZoneId: 'America/Chicago',
  },
  '58344': {
    zip: '58344',
    city: 'Lakota',
    state: 'ND',
    latitude: '48.042397',
    longitude: '-98.341319',
    timeZoneId: 'America/Chicago',
  },
  '58345': {
    zip: '58345',
    city: 'Lawton',
    state: 'ND',
    latitude: '48.294882',
    longitude: '-98.445192',
    timeZoneId: 'America/Chicago',
  },
  '58346': {
    zip: '58346',
    city: 'Leeds',
    state: 'ND',
    latitude: '48.27642',
    longitude: '-99.43746',
    timeZoneId: 'America/Chicago',
  },
  '58348': {
    zip: '58348',
    city: 'Maddock',
    state: 'ND',
    latitude: '47.932284',
    longitude: '-99.553226',
    timeZoneId: 'America/Chicago',
  },
  '58351': {
    zip: '58351',
    city: 'Minnewaukan',
    state: 'ND',
    latitude: '48.068926',
    longitude: '-99.258727',
    timeZoneId: 'America/Chicago',
  },
  '58352': {
    zip: '58352',
    city: 'Munich',
    state: 'ND',
    latitude: '48.6558',
    longitude: '-98.85072',
    timeZoneId: 'America/Chicago',
  },
  '58353': {
    zip: '58353',
    city: 'Mylo',
    state: 'ND',
    latitude: '48.635764',
    longitude: '-99.621503',
    timeZoneId: 'America/Chicago',
  },
  '58355': {
    zip: '58355',
    city: 'Nekoma',
    state: 'ND',
    latitude: '48.578015',
    longitude: '-98.368658',
    timeZoneId: 'America/Chicago',
  },
  '58356': {
    zip: '58356',
    city: 'New Rockford',
    state: 'ND',
    latitude: '47.684884',
    longitude: '-99.133524',
    timeZoneId: 'America/Chicago',
  },
  '58357': {
    zip: '58357',
    city: 'Oberon',
    state: 'ND',
    latitude: '47.961515',
    longitude: '-99.163469',
    timeZoneId: 'America/Chicago',
  },
  '58361': {
    zip: '58361',
    city: 'Pekin',
    state: 'ND',
    latitude: '47.762536',
    longitude: '-98.333124',
    timeZoneId: 'America/Chicago',
  },
  '58362': {
    zip: '58362',
    city: 'Penn',
    state: 'ND',
    latitude: '48.233319',
    longitude: '-99.049103',
    timeZoneId: 'America/Chicago',
  },
  '58363': {
    zip: '58363',
    city: 'Perth',
    state: 'ND',
    latitude: '48.682409',
    longitude: '-99.396186',
    timeZoneId: 'America/Chicago',
  },
  '58365': {
    zip: '58365',
    city: 'Rocklake',
    state: 'ND',
    latitude: '48.857949',
    longitude: '-99.129655',
    timeZoneId: 'America/Chicago',
  },
  '58366': {
    zip: '58366',
    city: 'Rolette',
    state: 'ND',
    latitude: '48.6543',
    longitude: '-99.8901',
    timeZoneId: 'America/Chicago',
  },
  '58367': {
    zip: '58367',
    city: 'Rolla',
    state: 'ND',
    latitude: '48.867168',
    longitude: '-99.613739',
    timeZoneId: 'America/Chicago',
  },
  '58368': {
    zip: '58368',
    city: 'Rugby',
    state: 'ND',
    latitude: '48.30366',
    longitude: '-99.99444',
    timeZoneId: 'America/Chicago',
  },
  '58369': {
    zip: '58369',
    city: 'Saint John',
    state: 'ND',
    latitude: '48.943535',
    longitude: '-99.785137',
    timeZoneId: 'America/Chicago',
  },
  '58370': {
    zip: '58370',
    city: 'Saint Michael',
    state: 'ND',
    latitude: '47.941869',
    longitude: '-98.90506',
    timeZoneId: 'America/Chicago',
  },
  '58372': {
    zip: '58372',
    city: 'Sarles',
    state: 'ND',
    latitude: '48.94557',
    longitude: '-98.934117',
    timeZoneId: 'America/Chicago',
  },
  '58374': {
    zip: '58374',
    city: 'Sheyenne',
    state: 'ND',
    latitude: '47.828806',
    longitude: '-99.117344',
    timeZoneId: 'America/Chicago',
  },
  '58377': {
    zip: '58377',
    city: 'Starkweather',
    state: 'ND',
    latitude: '48.449454',
    longitude: '-98.802536',
    timeZoneId: 'America/Chicago',
  },
  '58379': {
    zip: '58379',
    city: 'Tokio',
    state: 'ND',
    latitude: '47.921485',
    longitude: '-98.817673',
    timeZoneId: 'America/Chicago',
  },
  '58380': {
    zip: '58380',
    city: 'Tolna',
    state: 'ND',
    latitude: '47.79',
    longitude: '-98.5068',
    timeZoneId: 'America/Chicago',
  },
  '58381': {
    zip: '58381',
    city: 'Warwick',
    state: 'ND',
    latitude: '47.907807',
    longitude: '-98.671215',
    timeZoneId: 'America/Chicago',
  },
  '58382': {
    zip: '58382',
    city: 'Webster',
    state: 'ND',
    latitude: '48.329263',
    longitude: '-98.875167',
    timeZoneId: 'America/Chicago',
  },
  '58384': {
    zip: '58384',
    city: 'Willow City',
    state: 'ND',
    latitude: '48.61734',
    longitude: '-100.299168',
    timeZoneId: 'America/Chicago',
  },
  '58385': {
    zip: '58385',
    city: 'Wolford',
    state: 'ND',
    latitude: '48.45796',
    longitude: '-99.630438',
    timeZoneId: 'America/Chicago',
  },
  '58386': {
    zip: '58386',
    city: 'York',
    state: 'ND',
    latitude: '48.329524',
    longitude: '-99.526269',
    timeZoneId: 'America/Chicago',
  },
  '58401': {
    zip: '58401',
    city: 'Jamestown',
    state: 'ND',
    latitude: '46.910219',
    longitude: '-98.706119',
    timeZoneId: 'America/Chicago',
  },
  '58402': {
    zip: '58402',
    city: 'Jamestown',
    state: 'ND',
    latitude: '46.905091',
    longitude: '-98.732378',
    timeZoneId: 'America/Chicago',
  },
  '58405': {
    zip: '58405',
    city: 'Jamestown',
    state: 'ND',
    latitude: '46.910278',
    longitude: '-98.696046',
    timeZoneId: 'America/Chicago',
  },
  '58413': {
    zip: '58413',
    city: 'Ashley',
    state: 'ND',
    latitude: '46.03603',
    longitude: '-99.371223',
    timeZoneId: 'America/Chicago',
  },
  '58415': {
    zip: '58415',
    city: 'Berlin',
    state: 'ND',
    latitude: '46.4424',
    longitude: '-98.4837',
    timeZoneId: 'America/Chicago',
  },
  '58416': {
    zip: '58416',
    city: 'Binford',
    state: 'ND',
    latitude: '47.583836',
    longitude: '-98.369868',
    timeZoneId: 'America/Chicago',
  },
  '58418': {
    zip: '58418',
    city: 'Bowdon',
    state: 'ND',
    latitude: '47.394246',
    longitude: '-99.712026',
    timeZoneId: 'America/Chicago',
  },
  '58420': {
    zip: '58420',
    city: 'Buchanan',
    state: 'ND',
    latitude: '47.060715',
    longitude: '-98.831051',
    timeZoneId: 'America/Chicago',
  },
  '58421': {
    zip: '58421',
    city: 'Carrington',
    state: 'ND',
    latitude: '47.448882',
    longitude: '-99.124405',
    timeZoneId: 'America/Chicago',
  },
  '58422': {
    zip: '58422',
    city: 'Cathay',
    state: 'ND',
    latitude: '47.54808',
    longitude: '-99.4425',
    timeZoneId: 'America/Chicago',
  },
  '58423': {
    zip: '58423',
    city: 'Chaseley',
    state: 'ND',
    latitude: '47.458186',
    longitude: '-99.839456',
    timeZoneId: 'America/Chicago',
  },
  '58424': {
    zip: '58424',
    city: 'Cleveland',
    state: 'ND',
    latitude: '46.89408',
    longitude: '-99.09594',
    timeZoneId: 'America/Chicago',
  },
  '58425': {
    zip: '58425',
    city: 'Cooperstown',
    state: 'ND',
    latitude: '47.445786',
    longitude: '-98.118678',
    timeZoneId: 'America/Chicago',
  },
  '58426': {
    zip: '58426',
    city: 'Courtenay',
    state: 'ND',
    latitude: '47.221503',
    longitude: '-98.566893',
    timeZoneId: 'America/Chicago',
  },
  '58428': {
    zip: '58428',
    city: 'Dawson',
    state: 'ND',
    latitude: '46.807596',
    longitude: '-99.764035',
    timeZoneId: 'America/Chicago',
  },
  '58429': {
    zip: '58429',
    city: 'Dazey',
    state: 'ND',
    latitude: '47.202',
    longitude: '-98.13888',
    timeZoneId: 'America/Chicago',
  },
  '58430': {
    zip: '58430',
    city: 'Denhoff',
    state: 'ND',
    latitude: '47.564837',
    longitude: '-100.256442',
    timeZoneId: 'America/Chicago',
  },
  '58431': {
    zip: '58431',
    city: 'Dickey',
    state: 'ND',
    latitude: '46.542335',
    longitude: '-98.471216',
    timeZoneId: 'America/Chicago',
  },
  '58433': {
    zip: '58433',
    city: 'Edgeley',
    state: 'ND',
    latitude: '46.32132',
    longitude: '-98.72172',
    timeZoneId: 'America/Chicago',
  },
  '58436': {
    zip: '58436',
    city: 'Ellendale',
    state: 'ND',
    latitude: '46.002161',
    longitude: '-98.521819',
    timeZoneId: 'America/Chicago',
  },
  '58438': {
    zip: '58438',
    city: 'Fessenden',
    state: 'ND',
    latitude: '47.652404',
    longitude: '-99.623115',
    timeZoneId: 'America/Chicago',
  },
  '58439': {
    zip: '58439',
    city: 'Forbes',
    state: 'ND',
    latitude: '46.032513',
    longitude: '-98.844625',
    timeZoneId: 'America/Chicago',
  },
  '58440': {
    zip: '58440',
    city: 'Fredonia',
    state: 'ND',
    latitude: '46.365495',
    longitude: '-99.289657',
    timeZoneId: 'America/Chicago',
  },
  '58441': {
    zip: '58441',
    city: 'Fullerton',
    state: 'ND',
    latitude: '46.195764',
    longitude: '-98.379125',
    timeZoneId: 'America/Chicago',
  },
  '58442': {
    zip: '58442',
    city: 'Gackle',
    state: 'ND',
    latitude: '46.623711',
    longitude: '-99.140681',
    timeZoneId: 'America/Chicago',
  },
  '58443': {
    zip: '58443',
    city: 'Glenfield',
    state: 'ND',
    latitude: '47.44818',
    longitude: '-98.68068',
    timeZoneId: 'America/Chicago',
  },
  '58444': {
    zip: '58444',
    city: 'Goodrich',
    state: 'ND',
    latitude: '47.479344',
    longitude: '-100.123247',
    timeZoneId: 'America/Chicago',
  },
  '58445': {
    zip: '58445',
    city: 'Grace City',
    state: 'ND',
    latitude: '47.541934',
    longitude: '-98.816327',
    timeZoneId: 'America/Chicago',
  },
  '58448': {
    zip: '58448',
    city: 'Hannaford',
    state: 'ND',
    latitude: '47.269722',
    longitude: '-98.194167',
    timeZoneId: 'America/Chicago',
  },
  '58451': {
    zip: '58451',
    city: 'Hurdsfield',
    state: 'ND',
    latitude: '47.413057',
    longitude: '-99.972447',
    timeZoneId: 'America/Chicago',
  },
  '58452': {
    zip: '58452',
    city: 'Jessie',
    state: 'ND',
    latitude: '47.541441',
    longitude: '-98.237747',
    timeZoneId: 'America/Chicago',
  },
  '58454': {
    zip: '58454',
    city: 'Jud',
    state: 'ND',
    latitude: '46.488265',
    longitude: '-98.813122',
    timeZoneId: 'America/Chicago',
  },
  '58455': {
    zip: '58455',
    city: 'Kensal',
    state: 'ND',
    latitude: '47.301327',
    longitude: '-98.735992',
    timeZoneId: 'America/Chicago',
  },
  '58456': {
    zip: '58456',
    city: 'Kulm',
    state: 'ND',
    latitude: '46.298329',
    longitude: '-98.948073',
    timeZoneId: 'America/Chicago',
  },
  '58458': {
    zip: '58458',
    city: 'Lamoure',
    state: 'ND',
    latitude: '46.3974',
    longitude: '-98.28324',
    timeZoneId: 'America/Chicago',
  },
  '58460': {
    zip: '58460',
    city: 'Lehr',
    state: 'ND',
    latitude: '46.281795',
    longitude: '-99.354875',
    timeZoneId: 'America/Chicago',
  },
  '58461': {
    zip: '58461',
    city: 'Litchville',
    state: 'ND',
    latitude: '46.718067',
    longitude: '-98.207019',
    timeZoneId: 'America/Chicago',
  },
  '58463': {
    zip: '58463',
    city: 'McClusky',
    state: 'ND',
    latitude: '47.483889',
    longitude: '-100.443661',
    timeZoneId: 'America/Chicago',
  },
  '58464': {
    zip: '58464',
    city: 'McHenry',
    state: 'ND',
    latitude: '47.541956',
    longitude: '-98.562354',
    timeZoneId: 'America/Chicago',
  },
  '58466': {
    zip: '58466',
    city: 'Marion',
    state: 'ND',
    latitude: '46.562135',
    longitude: '-98.244893',
    timeZoneId: 'America/Chicago',
  },
  '58467': {
    zip: '58467',
    city: 'Medina',
    state: 'ND',
    latitude: '46.893964',
    longitude: '-99.302871',
    timeZoneId: 'America/Chicago',
  },
  '58472': {
    zip: '58472',
    city: 'Montpelier',
    state: 'ND',
    latitude: '46.666944',
    longitude: '-98.759167',
    timeZoneId: 'America/Chicago',
  },
  '58474': {
    zip: '58474',
    city: 'Oakes',
    state: 'ND',
    latitude: '46.13352',
    longitude: '-98.10018',
    timeZoneId: 'America/Chicago',
  },
  '58475': {
    zip: '58475',
    city: 'Pettibone',
    state: 'ND',
    latitude: '47.10931',
    longitude: '-99.546142',
    timeZoneId: 'America/Chicago',
  },
  '58476': {
    zip: '58476',
    city: 'Pingree',
    state: 'ND',
    latitude: '47.134183',
    longitude: '-99.032822',
    timeZoneId: 'America/Chicago',
  },
  '58477': {
    zip: '58477',
    city: 'Regan',
    state: 'ND',
    latitude: '47.157277',
    longitude: '-100.522793',
    timeZoneId: 'America/Chicago',
  },
  '58478': {
    zip: '58478',
    city: 'Robinson',
    state: 'ND',
    latitude: '47.157419',
    longitude: '-99.686502',
    timeZoneId: 'America/Chicago',
  },
  '58479': {
    zip: '58479',
    city: 'Rogers',
    state: 'ND',
    latitude: '47.05404',
    longitude: '-98.211954',
    timeZoneId: 'America/Chicago',
  },
  '58480': {
    zip: '58480',
    city: 'Sanborn',
    state: 'ND',
    latitude: '46.890564',
    longitude: '-98.248569',
    timeZoneId: 'America/Chicago',
  },
  '58481': {
    zip: '58481',
    city: 'Spiritwood',
    state: 'ND',
    latitude: '46.93597',
    longitude: '-98.33336',
    timeZoneId: 'America/Chicago',
  },
  '58482': {
    zip: '58482',
    city: 'Steele',
    state: 'ND',
    latitude: '46.858883',
    longitude: '-99.914607',
    timeZoneId: 'America/Chicago',
  },
  '58483': {
    zip: '58483',
    city: 'Streeter',
    state: 'ND',
    latitude: '46.659173',
    longitude: '-99.360588',
    timeZoneId: 'America/Chicago',
  },
  '58484': {
    zip: '58484',
    city: 'Sutton',
    state: 'ND',
    latitude: '47.372934',
    longitude: '-98.431736',
    timeZoneId: 'America/Chicago',
  },
  '58486': {
    zip: '58486',
    city: 'Sykeston',
    state: 'ND',
    latitude: '47.410353',
    longitude: '-99.39203',
    timeZoneId: 'America/Chicago',
  },
  '58487': {
    zip: '58487',
    city: 'Tappen',
    state: 'ND',
    latitude: '46.808647',
    longitude: '-99.573506',
    timeZoneId: 'America/Chicago',
  },
  '58488': {
    zip: '58488',
    city: 'Tuttle',
    state: 'ND',
    latitude: '47.200872',
    longitude: '-99.991392',
    timeZoneId: 'America/Chicago',
  },
  '58490': {
    zip: '58490',
    city: 'Verona',
    state: 'ND',
    latitude: '46.368792',
    longitude: '-98.100113',
    timeZoneId: 'America/Chicago',
  },
  '58492': {
    zip: '58492',
    city: 'Wimbledon',
    state: 'ND',
    latitude: '47.17254',
    longitude: '-98.458847',
    timeZoneId: 'America/Chicago',
  },
  '58494': {
    zip: '58494',
    city: 'Wing',
    state: 'ND',
    latitude: '47.15214',
    longitude: '-100.332354',
    timeZoneId: 'America/Chicago',
  },
  '58495': {
    zip: '58495',
    city: 'Wishek',
    state: 'ND',
    latitude: '46.25514',
    longitude: '-99.554676',
    timeZoneId: 'America/Chicago',
  },
  '58496': {
    zip: '58496',
    city: 'Woodworth',
    state: 'ND',
    latitude: '47.141743',
    longitude: '-99.300422',
    timeZoneId: 'America/Chicago',
  },
  '58497': {
    zip: '58497',
    city: 'Ypsilanti',
    state: 'ND',
    latitude: '46.761553',
    longitude: '-98.437967',
    timeZoneId: 'America/Chicago',
  },
  '58501': {
    zip: '58501',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.819042',
    longitude: '-100.780969',
    timeZoneId: 'America/Chicago',
  },
  '58502': {
    zip: '58502',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.851023',
    longitude: '-100.717405',
    timeZoneId: 'America/Chicago',
  },
  '58503': {
    zip: '58503',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.859748',
    longitude: '-100.767872',
    timeZoneId: 'America/Chicago',
  },
  '58504': {
    zip: '58504',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.76082',
    longitude: '-100.69938',
    timeZoneId: 'America/Chicago',
  },
  '58505': {
    zip: '58505',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.847657',
    longitude: '-100.716693',
    timeZoneId: 'America/Chicago',
  },
  '58506': {
    zip: '58506',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.727975',
    longitude: '-100.618831',
    timeZoneId: 'America/Chicago',
  },
  '58507': {
    zip: '58507',
    city: 'Bismarck',
    state: 'ND',
    latitude: '46.796368',
    longitude: '-100.756084',
    timeZoneId: 'America/Chicago',
  },
  '58520': {
    zip: '58520',
    city: 'Almont',
    state: 'ND',
    latitude: '46.695511',
    longitude: '-101.540211',
    timeZoneId: 'America/Chicago',
  },
  '58521': {
    zip: '58521',
    city: 'Baldwin',
    state: 'ND',
    latitude: '46.980509',
    longitude: '-100.749644',
    timeZoneId: 'America/Chicago',
  },
  '58523': {
    zip: '58523',
    city: 'Beulah',
    state: 'ND',
    latitude: '47.269077',
    longitude: '-101.779299',
    timeZoneId: 'America/North_Dakota/Beulah',
  },
  '58524': {
    zip: '58524',
    city: 'Braddock',
    state: 'ND',
    latitude: '46.591605',
    longitude: '-100.266823',
    timeZoneId: 'America/Chicago',
  },
  '58528': {
    zip: '58528',
    city: 'Cannon Ball',
    state: 'ND',
    latitude: '46.378635',
    longitude: '-100.614493',
    timeZoneId: 'America/Chicago',
  },
  '58529': {
    zip: '58529',
    city: 'Carson',
    state: 'ND',
    latitude: '46.5021',
    longitude: '-101.5029',
    timeZoneId: 'America/Denver',
  },
  '58530': {
    zip: '58530',
    city: 'Center',
    state: 'ND',
    latitude: '47.08601',
    longitude: '-100.94139',
    timeZoneId: 'America/Chicago',
  },
  '58531': {
    zip: '58531',
    city: 'Coleharbor',
    state: 'ND',
    latitude: '47.516137',
    longitude: '-100.918707',
    timeZoneId: 'America/Chicago',
  },
  '58532': {
    zip: '58532',
    city: 'Driscoll',
    state: 'ND',
    latitude: '46.851407',
    longitude: '-100.140454',
    timeZoneId: 'America/Chicago',
  },
  '58533': {
    zip: '58533',
    city: 'Elgin',
    state: 'ND',
    latitude: '46.40964',
    longitude: '-101.83128',
    timeZoneId: 'America/Denver',
  },
  '58535': {
    zip: '58535',
    city: 'Flasher',
    state: 'ND',
    latitude: '46.46652',
    longitude: '-101.17896',
    timeZoneId: 'America/Chicago',
  },
  '58538': {
    zip: '58538',
    city: 'Fort Yates',
    state: 'ND',
    latitude: '46.075961',
    longitude: '-100.641076',
    timeZoneId: 'America/Chicago',
  },
  '58540': {
    zip: '58540',
    city: 'Garrison',
    state: 'ND',
    latitude: '47.653205',
    longitude: '-101.416654',
    timeZoneId: 'America/Chicago',
  },
  '58541': {
    zip: '58541',
    city: 'Golden Valley',
    state: 'ND',
    latitude: '47.299471',
    longitude: '-102.067172',
    timeZoneId: 'America/North_Dakota/Beulah',
  },
  '58542': {
    zip: '58542',
    city: 'Hague',
    state: 'ND',
    latitude: '46.06369',
    longitude: '-99.942884',
    timeZoneId: 'America/Chicago',
  },
  '58544': {
    zip: '58544',
    city: 'Hazelton',
    state: 'ND',
    latitude: '46.481145',
    longitude: '-100.277086',
    timeZoneId: 'America/Chicago',
  },
  '58545': {
    zip: '58545',
    city: 'Hazen',
    state: 'ND',
    latitude: '47.33532',
    longitude: '-101.62392',
    timeZoneId: 'America/North_Dakota/Beulah',
  },
  '58549': {
    zip: '58549',
    city: 'Kintyre',
    state: 'ND',
    latitude: '46.589346',
    longitude: '-99.981332',
    timeZoneId: 'America/Chicago',
  },
  '58552': {
    zip: '58552',
    city: 'Linton',
    state: 'ND',
    latitude: '46.28898',
    longitude: '-100.22358',
    timeZoneId: 'America/Chicago',
  },
  '58554': {
    zip: '58554',
    city: 'Mandan',
    state: 'ND',
    latitude: '46.828248',
    longitude: '-100.892053',
    timeZoneId: 'America/Chicago',
  },
  '58558': {
    zip: '58558',
    city: 'Menoken',
    state: 'ND',
    latitude: '46.820879',
    longitude: '-100.600093',
    timeZoneId: 'America/Chicago',
  },
  '58559': {
    zip: '58559',
    city: 'Mercer',
    state: 'ND',
    latitude: '47.459618',
    longitude: '-100.736951',
    timeZoneId: 'America/Chicago',
  },
  '58560': {
    zip: '58560',
    city: 'Moffit',
    state: 'ND',
    latitude: '46.676728',
    longitude: '-100.268751',
    timeZoneId: 'America/Chicago',
  },
  '58561': {
    zip: '58561',
    city: 'Napoleon',
    state: 'ND',
    latitude: '46.502292',
    longitude: '-99.768821',
    timeZoneId: 'America/Chicago',
  },
  '58562': {
    zip: '58562',
    city: 'New Leipzig',
    state: 'ND',
    latitude: '46.40598',
    longitude: '-101.89542',
    timeZoneId: 'America/Denver',
  },
  '58563': {
    zip: '58563',
    city: 'New Salem',
    state: 'ND',
    latitude: '46.8537',
    longitude: '-101.43558',
    timeZoneId: 'America/Chicago',
  },
  '58564': {
    zip: '58564',
    city: 'Raleigh',
    state: 'ND',
    latitude: '46.337217',
    longitude: '-101.260167',
    timeZoneId: 'America/Denver',
  },
  '58565': {
    zip: '58565',
    city: 'Riverdale',
    state: 'ND',
    latitude: '47.502299',
    longitude: '-101.368973',
    timeZoneId: 'America/Chicago',
  },
  '58566': {
    zip: '58566',
    city: 'Saint Anthony',
    state: 'ND',
    latitude: '46.577847',
    longitude: '-100.891742',
    timeZoneId: 'America/Chicago',
  },
  '58568': {
    zip: '58568',
    city: 'Selfridge',
    state: 'ND',
    latitude: '46.038271',
    longitude: '-101.279372',
    timeZoneId: 'America/Chicago',
  },
  '58569': {
    zip: '58569',
    city: 'Shields',
    state: 'ND',
    latitude: '46.200991',
    longitude: '-101.235194',
    timeZoneId: 'America/Denver',
  },
  '58570': {
    zip: '58570',
    city: 'Solen',
    state: 'ND',
    latitude: '46.276735',
    longitude: '-100.808228',
    timeZoneId: 'America/Chicago',
  },
  '58571': {
    zip: '58571',
    city: 'Stanton',
    state: 'ND',
    latitude: '47.384708',
    longitude: '-101.394519',
    timeZoneId: 'America/Chicago',
  },
  '58572': {
    zip: '58572',
    city: 'Sterling',
    state: 'ND',
    latitude: '46.850141',
    longitude: '-100.264685',
    timeZoneId: 'America/Chicago',
  },
  '58573': {
    zip: '58573',
    city: 'Strasburg',
    state: 'ND',
    latitude: '46.281781',
    longitude: '-100.21135',
    timeZoneId: 'America/Chicago',
  },
  '58575': {
    zip: '58575',
    city: 'Turtle Lake',
    state: 'ND',
    latitude: '47.518146',
    longitude: '-100.890776',
    timeZoneId: 'America/Chicago',
  },
  '58576': {
    zip: '58576',
    city: 'Underwood',
    state: 'ND',
    latitude: '47.451649',
    longitude: '-101.13753',
    timeZoneId: 'America/Chicago',
  },
  '58577': {
    zip: '58577',
    city: 'Washburn',
    state: 'ND',
    latitude: '47.31858',
    longitude: '-101.01702',
    timeZoneId: 'America/Chicago',
  },
  '58579': {
    zip: '58579',
    city: 'Wilton',
    state: 'ND',
    latitude: '47.158773',
    longitude: '-100.783228',
    timeZoneId: 'America/Chicago',
  },
  '58580': {
    zip: '58580',
    city: 'Zap',
    state: 'ND',
    latitude: '47.30084',
    longitude: '-101.931572',
    timeZoneId: 'America/North_Dakota/Beulah',
  },
  '58581': {
    zip: '58581',
    city: 'Zeeland',
    state: 'ND',
    latitude: '46.028615',
    longitude: '-99.725988',
    timeZoneId: 'America/Chicago',
  },
  '58601': {
    zip: '58601',
    city: 'Dickinson',
    state: 'ND',
    latitude: '46.882628',
    longitude: '-102.783495',
    timeZoneId: 'America/Denver',
  },
  '58602': {
    zip: '58602',
    city: 'Dickinson',
    state: 'ND',
    latitude: '46.884926',
    longitude: '-102.78373',
    timeZoneId: 'America/Denver',
  },
  '58620': {
    zip: '58620',
    city: 'Amidon',
    state: 'ND',
    latitude: '46.47811',
    longitude: '-103.32281',
    timeZoneId: 'America/Denver',
  },
  '58621': {
    zip: '58621',
    city: 'Beach',
    state: 'ND',
    latitude: '46.931605',
    longitude: '-104.007445',
    timeZoneId: 'America/Denver',
  },
  '58622': {
    zip: '58622',
    city: 'Belfield',
    state: 'ND',
    latitude: '46.88472',
    longitude: '-103.1808',
    timeZoneId: 'America/Denver',
  },
  '58623': {
    zip: '58623',
    city: 'Bowman',
    state: 'ND',
    latitude: '46.179366',
    longitude: '-103.400993',
    timeZoneId: 'America/Denver',
  },
  '58625': {
    zip: '58625',
    city: 'Dodge',
    state: 'ND',
    latitude: '47.300721',
    longitude: '-102.177895',
    timeZoneId: 'America/Denver',
  },
  '58626': {
    zip: '58626',
    city: 'Dunn Center',
    state: 'ND',
    latitude: '47.349834',
    longitude: '-102.564317',
    timeZoneId: 'America/Denver',
  },
  '58627': {
    zip: '58627',
    city: 'Fairfield',
    state: 'ND',
    latitude: '47.12052',
    longitude: '-103.314',
    timeZoneId: 'America/Denver',
  },
  '58630': {
    zip: '58630',
    city: 'Gladstone',
    state: 'ND',
    latitude: '46.857633',
    longitude: '-102.564564',
    timeZoneId: 'America/Denver',
  },
  '58631': {
    zip: '58631',
    city: 'Glen Ullin',
    state: 'ND',
    latitude: '46.818421',
    longitude: '-101.839984',
    timeZoneId: 'America/Chicago',
  },
  '58632': {
    zip: '58632',
    city: 'Golva',
    state: 'ND',
    latitude: '46.662838',
    longitude: '-103.940794',
    timeZoneId: 'America/Denver',
  },
  '58634': {
    zip: '58634',
    city: 'Grassy Butte',
    state: 'ND',
    latitude: '47.450197',
    longitude: '-103.343816',
    timeZoneId: 'America/Denver',
  },
  '58636': {
    zip: '58636',
    city: 'Halliday',
    state: 'ND',
    latitude: '47.34231',
    longitude: '-102.334132',
    timeZoneId: 'America/Denver',
  },
  '58638': {
    zip: '58638',
    city: 'Hebron',
    state: 'ND',
    latitude: '46.900954',
    longitude: '-102.033164',
    timeZoneId: 'America/Chicago',
  },
  '58639': {
    zip: '58639',
    city: 'Hettinger',
    state: 'ND',
    latitude: '46.03584',
    longitude: '-102.57012',
    timeZoneId: 'America/Denver',
  },
  '58640': {
    zip: '58640',
    city: 'Killdeer',
    state: 'ND',
    latitude: '47.367775',
    longitude: '-102.755571',
    timeZoneId: 'America/Denver',
  },
  '58641': {
    zip: '58641',
    city: 'Lefor',
    state: 'ND',
    latitude: '46.715881',
    longitude: '-102.768022',
    timeZoneId: 'America/Denver',
  },
  '58642': {
    zip: '58642',
    city: 'Manning',
    state: 'ND',
    latitude: '47.115146',
    longitude: '-102.641338',
    timeZoneId: 'America/Denver',
  },
  '58643': {
    zip: '58643',
    city: 'Marmarth',
    state: 'ND',
    latitude: '46.384587',
    longitude: '-103.850071',
    timeZoneId: 'America/Denver',
  },
  '58644': {
    zip: '58644',
    city: 'Marshall',
    state: 'ND',
    latitude: '47.136433',
    longitude: '-102.333475',
    timeZoneId: 'America/Denver',
  },
  '58645': {
    zip: '58645',
    city: 'Medora',
    state: 'ND',
    latitude: '46.769016',
    longitude: '-103.424849',
    timeZoneId: 'America/Denver',
  },
  '58646': {
    zip: '58646',
    city: 'Mott',
    state: 'ND',
    latitude: '46.39578',
    longitude: '-102.42816',
    timeZoneId: 'America/Denver',
  },
  '58647': {
    zip: '58647',
    city: 'New England',
    state: 'ND',
    latitude: '46.53898',
    longitude: '-102.864983',
    timeZoneId: 'America/Denver',
  },
  '58649': {
    zip: '58649',
    city: 'Reeder',
    state: 'ND',
    latitude: '46.113167',
    longitude: '-102.932725',
    timeZoneId: 'America/Denver',
  },
  '58650': {
    zip: '58650',
    city: 'Regent',
    state: 'ND',
    latitude: '46.437876',
    longitude: '-102.613448',
    timeZoneId: 'America/Denver',
  },
  '58651': {
    zip: '58651',
    city: 'Rhame',
    state: 'ND',
    latitude: '46.186233',
    longitude: '-103.396002',
    timeZoneId: 'America/Denver',
  },
  '58652': {
    zip: '58652',
    city: 'Richardton',
    state: 'ND',
    latitude: '46.884107',
    longitude: '-102.320815',
    timeZoneId: 'America/Denver',
  },
  '58653': {
    zip: '58653',
    city: 'Scranton',
    state: 'ND',
    latitude: '46.112711',
    longitude: '-103.12011',
    timeZoneId: 'America/Denver',
  },
  '58654': {
    zip: '58654',
    city: 'Sentinel Butte',
    state: 'ND',
    latitude: '46.80199',
    longitude: '-103.732852',
    timeZoneId: 'America/Denver',
  },
  '58655': {
    zip: '58655',
    city: 'South Heart',
    state: 'ND',
    latitude: '46.722356',
    longitude: '-103.059738',
    timeZoneId: 'America/Denver',
  },
  '58656': {
    zip: '58656',
    city: 'Taylor',
    state: 'ND',
    latitude: '46.951621',
    longitude: '-102.315515',
    timeZoneId: 'America/Denver',
  },
  '58701': {
    zip: '58701',
    city: 'Minot',
    state: 'ND',
    latitude: '48.230821',
    longitude: '-101.294119',
    timeZoneId: 'America/Chicago',
  },
  '58702': {
    zip: '58702',
    city: 'Minot',
    state: 'ND',
    latitude: '48.228996',
    longitude: '-101.308292',
    timeZoneId: 'America/Chicago',
  },
  '58703': {
    zip: '58703',
    city: 'Minot',
    state: 'ND',
    latitude: '48.266541',
    longitude: '-101.312717',
    timeZoneId: 'America/Chicago',
  },
  '58704': {
    zip: '58704',
    city: 'Minot AFB',
    state: 'ND',
    latitude: '48.2325',
    longitude: '-101.295833',
    timeZoneId: 'America/Chicago',
  },
  '58705': {
    zip: '58705',
    city: 'Minot AFB',
    state: 'ND',
    latitude: '48.2307',
    longitude: '-101.3079',
    timeZoneId: 'America/Chicago',
  },
  '58707': {
    zip: '58707',
    city: 'Minot',
    state: 'ND',
    latitude: '48.235211',
    longitude: '-101.294711',
    timeZoneId: 'America/Chicago',
  },
  '58710': {
    zip: '58710',
    city: 'Anamoose',
    state: 'ND',
    latitude: '47.848945',
    longitude: '-100.262163',
    timeZoneId: 'America/Chicago',
  },
  '58711': {
    zip: '58711',
    city: 'Antler',
    state: 'ND',
    latitude: '48.948044',
    longitude: '-101.362952',
    timeZoneId: 'America/Chicago',
  },
  '58712': {
    zip: '58712',
    city: 'Balfour',
    state: 'ND',
    latitude: '47.9784',
    longitude: '-100.519271',
    timeZoneId: 'America/Chicago',
  },
  '58713': {
    zip: '58713',
    city: 'Bantry',
    state: 'ND',
    latitude: '48.500739',
    longitude: '-100.784923',
    timeZoneId: 'America/Chicago',
  },
  '58716': {
    zip: '58716',
    city: 'Benedict',
    state: 'ND',
    latitude: '47.759459',
    longitude: '-101.035659',
    timeZoneId: 'America/Chicago',
  },
  '58718': {
    zip: '58718',
    city: 'Berthold',
    state: 'ND',
    latitude: '48.328239',
    longitude: '-101.921232',
    timeZoneId: 'America/Chicago',
  },
  '58721': {
    zip: '58721',
    city: 'Bowbells',
    state: 'ND',
    latitude: '48.812366',
    longitude: '-102.205909',
    timeZoneId: 'America/Chicago',
  },
  '58722': {
    zip: '58722',
    city: 'Burlington',
    state: 'ND',
    latitude: '48.261494',
    longitude: '-101.439227',
    timeZoneId: 'America/Chicago',
  },
  '58723': {
    zip: '58723',
    city: 'Butte',
    state: 'ND',
    latitude: '47.764294',
    longitude: '-100.649272',
    timeZoneId: 'America/Chicago',
  },
  '58725': {
    zip: '58725',
    city: 'Carpio',
    state: 'ND',
    latitude: '48.414749',
    longitude: '-101.712035',
    timeZoneId: 'America/Chicago',
  },
  '58727': {
    zip: '58727',
    city: 'Columbus',
    state: 'ND',
    latitude: '48.8628',
    longitude: '-102.8151',
    timeZoneId: 'America/Chicago',
  },
  '58730': {
    zip: '58730',
    city: 'Crosby',
    state: 'ND',
    latitude: '48.91305',
    longitude: '-103.296401',
    timeZoneId: 'America/Chicago',
  },
  '58731': {
    zip: '58731',
    city: 'Deering',
    state: 'ND',
    latitude: '48.415654',
    longitude: '-100.99325',
    timeZoneId: 'America/Chicago',
  },
  '58733': {
    zip: '58733',
    city: 'Des Lacs',
    state: 'ND',
    latitude: '48.239635',
    longitude: '-101.595213',
    timeZoneId: 'America/Chicago',
  },
  '58734': {
    zip: '58734',
    city: 'Donnybrook',
    state: 'ND',
    latitude: '48.4752',
    longitude: '-101.90418',
    timeZoneId: 'America/Chicago',
  },
  '58735': {
    zip: '58735',
    city: 'Douglas',
    state: 'ND',
    latitude: '47.892601',
    longitude: '-101.552246',
    timeZoneId: 'America/Chicago',
  },
  '58736': {
    zip: '58736',
    city: 'Drake',
    state: 'ND',
    latitude: '47.926901',
    longitude: '-100.37261',
    timeZoneId: 'America/Chicago',
  },
  '58737': {
    zip: '58737',
    city: 'Flaxton',
    state: 'ND',
    latitude: '48.916096',
    longitude: '-102.296386',
    timeZoneId: 'America/Chicago',
  },
  '58740': {
    zip: '58740',
    city: 'Glenburn',
    state: 'ND',
    latitude: '48.519799',
    longitude: '-101.238037',
    timeZoneId: 'America/Chicago',
  },
  '58741': {
    zip: '58741',
    city: 'Granville',
    state: 'ND',
    latitude: '48.255272',
    longitude: '-100.782829',
    timeZoneId: 'America/Chicago',
  },
  '58744': {
    zip: '58744',
    city: 'Karlsruhe',
    state: 'ND',
    latitude: '48.138431',
    longitude: '-100.487239',
    timeZoneId: 'America/Chicago',
  },
  '58746': {
    zip: '58746',
    city: 'Kenmare',
    state: 'ND',
    latitude: '48.678244',
    longitude: '-102.068686',
    timeZoneId: 'America/Chicago',
  },
  '58748': {
    zip: '58748',
    city: 'Kramer',
    state: 'ND',
    latitude: '48.678784',
    longitude: '-100.658855',
    timeZoneId: 'America/Chicago',
  },
  '58750': {
    zip: '58750',
    city: 'Lansford',
    state: 'ND',
    latitude: '48.617118',
    longitude: '-101.405976',
    timeZoneId: 'America/Chicago',
  },
  '58752': {
    zip: '58752',
    city: 'Lignite',
    state: 'ND',
    latitude: '48.80592',
    longitude: '-102.549291',
    timeZoneId: 'America/Chicago',
  },
  '58755': {
    zip: '58755',
    city: 'McGregor',
    state: 'ND',
    latitude: '48.591101',
    longitude: '-102.949822',
    timeZoneId: 'America/Chicago',
  },
  '58756': {
    zip: '58756',
    city: 'Makoti',
    state: 'ND',
    latitude: '48.020926',
    longitude: '-101.834279',
    timeZoneId: 'America/Chicago',
  },
  '58757': {
    zip: '58757',
    city: 'Mandaree',
    state: 'ND',
    latitude: '47.845427',
    longitude: '-102.709656',
    timeZoneId: 'America/Chicago',
  },
  '58758': {
    zip: '58758',
    city: 'Martin',
    state: 'ND',
    latitude: '47.719024',
    longitude: '-100.120917',
    timeZoneId: 'America/Chicago',
  },
  '58759': {
    zip: '58759',
    city: 'Max',
    state: 'ND',
    latitude: '47.819626',
    longitude: '-101.296615',
    timeZoneId: 'America/Chicago',
  },
  '58760': {
    zip: '58760',
    city: 'Maxbass',
    state: 'ND',
    latitude: '48.799553',
    longitude: '-101.327522',
    timeZoneId: 'America/Chicago',
  },
  '58761': {
    zip: '58761',
    city: 'Mohall',
    state: 'ND',
    latitude: '48.763084',
    longitude: '-101.524319',
    timeZoneId: 'America/Chicago',
  },
  '58762': {
    zip: '58762',
    city: 'Newburg',
    state: 'ND',
    latitude: '48.68718',
    longitude: '-100.9428',
    timeZoneId: 'America/Chicago',
  },
  '58763': {
    zip: '58763',
    city: 'New Town',
    state: 'ND',
    latitude: '47.9727',
    longitude: '-102.48162',
    timeZoneId: 'America/Chicago',
  },
  '58765': {
    zip: '58765',
    city: 'Noonan',
    state: 'ND',
    latitude: '48.88994',
    longitude: '-103.00998',
    timeZoneId: 'America/Chicago',
  },
  '58768': {
    zip: '58768',
    city: 'Norwich',
    state: 'ND',
    latitude: '48.23952',
    longitude: '-100.95192',
    timeZoneId: 'America/Chicago',
  },
  '58769': {
    zip: '58769',
    city: 'Palermo',
    state: 'ND',
    latitude: '48.329944',
    longitude: '-102.245364',
    timeZoneId: 'America/Chicago',
  },
  '58770': {
    zip: '58770',
    city: 'Parshall',
    state: 'ND',
    latitude: '47.94463',
    longitude: '-102.128265',
    timeZoneId: 'America/Chicago',
  },
  '58771': {
    zip: '58771',
    city: 'Plaza',
    state: 'ND',
    latitude: '48.020768',
    longitude: '-101.958837',
    timeZoneId: 'America/Chicago',
  },
  '58772': {
    zip: '58772',
    city: 'Portal',
    state: 'ND',
    latitude: '48.948464',
    longitude: '-102.546422',
    timeZoneId: 'America/Chicago',
  },
  '58773': {
    zip: '58773',
    city: 'Powers Lake',
    state: 'ND',
    latitude: '48.61878',
    longitude: '-102.624',
    timeZoneId: 'America/Chicago',
  },
  '58775': {
    zip: '58775',
    city: 'Roseglen',
    state: 'ND',
    latitude: '47.696198',
    longitude: '-101.679686',
    timeZoneId: 'America/Chicago',
  },
  '58776': {
    zip: '58776',
    city: 'Ross',
    state: 'ND',
    latitude: '48.328261',
    longitude: '-102.500895',
    timeZoneId: 'America/Chicago',
  },
  '58778': {
    zip: '58778',
    city: 'Ruso',
    state: 'ND',
    latitude: '47.759221',
    longitude: '-100.844798',
    timeZoneId: 'America/Chicago',
  },
  '58779': {
    zip: '58779',
    city: 'Ryder',
    state: 'ND',
    latitude: '47.85108',
    longitude: '-101.78982',
    timeZoneId: 'America/Chicago',
  },
  '58781': {
    zip: '58781',
    city: 'Sawyer',
    state: 'ND',
    latitude: '48.066443',
    longitude: '-101.078571',
    timeZoneId: 'America/Chicago',
  },
  '58782': {
    zip: '58782',
    city: 'Sherwood',
    state: 'ND',
    latitude: '48.947485',
    longitude: '-101.821254',
    timeZoneId: 'America/Chicago',
  },
  '58783': {
    zip: '58783',
    city: 'Souris',
    state: 'ND',
    latitude: '48.92076',
    longitude: '-100.82562',
    timeZoneId: 'America/Chicago',
  },
  '58784': {
    zip: '58784',
    city: 'Stanley',
    state: 'ND',
    latitude: '48.312403',
    longitude: '-102.392847',
    timeZoneId: 'America/Chicago',
  },
  '58785': {
    zip: '58785',
    city: 'Surrey',
    state: 'ND',
    latitude: '48.23941',
    longitude: '-101.135245',
    timeZoneId: 'America/Chicago',
  },
  '58787': {
    zip: '58787',
    city: 'Tolley',
    state: 'ND',
    latitude: '48.819983',
    longitude: '-101.86992',
    timeZoneId: 'America/Chicago',
  },
  '58788': {
    zip: '58788',
    city: 'Towner',
    state: 'ND',
    latitude: '48.353802',
    longitude: '-100.40548',
    timeZoneId: 'America/Chicago',
  },
  '58789': {
    zip: '58789',
    city: 'Upham',
    state: 'ND',
    latitude: '48.591119',
    longitude: '-100.734209',
    timeZoneId: 'America/Chicago',
  },
  '58790': {
    zip: '58790',
    city: 'Velva',
    state: 'ND',
    latitude: '48.058466',
    longitude: '-100.930511',
    timeZoneId: 'America/Chicago',
  },
  '58792': {
    zip: '58792',
    city: 'Voltaire',
    state: 'ND',
    latitude: '47.933754',
    longitude: '-100.775619',
    timeZoneId: 'America/Chicago',
  },
  '58793': {
    zip: '58793',
    city: 'Westhope',
    state: 'ND',
    latitude: '48.895227',
    longitude: '-101.090296',
    timeZoneId: 'America/Chicago',
  },
  '58794': {
    zip: '58794',
    city: 'White Earth',
    state: 'ND',
    latitude: '48.457091',
    longitude: '-102.820249',
    timeZoneId: 'America/Chicago',
  },
  '58795': {
    zip: '58795',
    city: 'Wildrose',
    state: 'ND',
    latitude: '48.60942',
    longitude: '-103.20252',
    timeZoneId: 'America/Chicago',
  },
  '58801': {
    zip: '58801',
    city: 'Williston',
    state: 'ND',
    latitude: '48.41687',
    longitude: '-103.82386',
    timeZoneId: 'America/Chicago',
  },
  '58802': {
    zip: '58802',
    city: 'Williston',
    state: 'ND',
    latitude: '48.152834',
    longitude: '-103.616711',
    timeZoneId: 'America/Chicago',
  },
  '58803': {
    zip: '58803',
    city: 'Williston',
    state: 'ND',
    latitude: '48.1564',
    longitude: '-103.6281',
    timeZoneId: 'America/Chicago',
  },
  '58830': {
    zip: '58830',
    city: 'Alamo',
    state: 'ND',
    latitude: '48.589714',
    longitude: '-103.408726',
    timeZoneId: 'America/Chicago',
  },
  '58831': {
    zip: '58831',
    city: 'Alexander',
    state: 'ND',
    latitude: '47.855481',
    longitude: '-103.642519',
    timeZoneId: 'America/Chicago',
  },
  '58833': {
    zip: '58833',
    city: 'Ambrose',
    state: 'ND',
    latitude: '48.787611',
    longitude: '-103.726147',
    timeZoneId: 'America/Chicago',
  },
  '58835': {
    zip: '58835',
    city: 'Arnegard',
    state: 'ND',
    latitude: '47.809287',
    longitude: '-103.439855',
    timeZoneId: 'America/Chicago',
  },
  '58838': {
    zip: '58838',
    city: 'Cartwright',
    state: 'ND',
    latitude: '47.613409',
    longitude: '-103.839867',
    timeZoneId: 'America/Denver',
  },
  '58843': {
    zip: '58843',
    city: 'Epping',
    state: 'ND',
    latitude: '48.242437',
    longitude: '-103.343766',
    timeZoneId: 'America/Chicago',
  },
  '58844': {
    zip: '58844',
    city: 'Fortuna',
    state: 'ND',
    latitude: '48.948333',
    longitude: '-103.627778',
    timeZoneId: 'America/Chicago',
  },
  '58845': {
    zip: '58845',
    city: 'Grenora',
    state: 'ND',
    latitude: '48.6024',
    longitude: '-103.91718',
    timeZoneId: 'America/Chicago',
  },
  '58847': {
    zip: '58847',
    city: 'Keene',
    state: 'ND',
    latitude: '47.959396',
    longitude: '-102.865396',
    timeZoneId: 'America/Chicago',
  },
  '58849': {
    zip: '58849',
    city: 'Ray',
    state: 'ND',
    latitude: '48.3228',
    longitude: '-103.2009',
    timeZoneId: 'America/Chicago',
  },
  '58852': {
    zip: '58852',
    city: 'Tioga',
    state: 'ND',
    latitude: '48.38736',
    longitude: '-102.97146',
    timeZoneId: 'America/Chicago',
  },
  '58853': {
    zip: '58853',
    city: 'Trenton',
    state: 'ND',
    latitude: '48.047911',
    longitude: '-103.897497',
    timeZoneId: 'America/Chicago',
  },
  '58854': {
    zip: '58854',
    city: 'Watford City',
    state: 'ND',
    latitude: '47.802475',
    longitude: '-103.279579',
    timeZoneId: 'America/Chicago',
  },
  '58856': {
    zip: '58856',
    city: 'Zahl',
    state: 'ND',
    latitude: '48.589053',
    longitude: '-103.66853',
    timeZoneId: 'America/Chicago',
  },
  '59001': {
    zip: '59001',
    city: 'Absarokee',
    state: 'MT',
    latitude: '45.521872',
    longitude: '-109.441232',
    timeZoneId: 'America/Denver',
  },
  '59002': {
    zip: '59002',
    city: 'Acton',
    state: 'MT',
    latitude: '45.924789',
    longitude: '-108.695498',
    timeZoneId: 'America/Denver',
  },
  '59003': {
    zip: '59003',
    city: 'Ashland',
    state: 'MT',
    latitude: '45.546223',
    longitude: '-106.268659',
    timeZoneId: 'America/Denver',
  },
  '59004': {
    zip: '59004',
    city: 'Ashland',
    state: 'MT',
    latitude: '45.621966',
    longitude: '-106.314153',
    timeZoneId: 'America/Denver',
  },
  '59006': {
    zip: '59006',
    city: 'Ballantine',
    state: 'MT',
    latitude: '45.95681',
    longitude: '-108.097366',
    timeZoneId: 'America/Denver',
  },
  '59007': {
    zip: '59007',
    city: 'Bearcreek',
    state: 'MT',
    latitude: '45.161087',
    longitude: '-109.156095',
    timeZoneId: 'America/Denver',
  },
  '59008': {
    zip: '59008',
    city: 'Belfry',
    state: 'MT',
    latitude: '45.065698',
    longitude: '-109.164591',
    timeZoneId: 'America/Denver',
  },
  '59010': {
    zip: '59010',
    city: 'Bighorn',
    state: 'MT',
    latitude: '46.0797',
    longitude: '-107.171186',
    timeZoneId: 'America/Denver',
  },
  '59011': {
    zip: '59011',
    city: 'Big Timber',
    state: 'MT',
    latitude: '45.835144',
    longitude: '-109.951625',
    timeZoneId: 'America/Denver',
  },
  '59012': {
    zip: '59012',
    city: 'Birney',
    state: 'MT',
    latitude: '45.306004',
    longitude: '-106.491116',
    timeZoneId: 'America/Denver',
  },
  '59013': {
    zip: '59013',
    city: 'Boyd',
    state: 'MT',
    latitude: '45.455285',
    longitude: '-109.063818',
    timeZoneId: 'America/Denver',
  },
  '59014': {
    zip: '59014',
    city: 'Bridger',
    state: 'MT',
    latitude: '45.292794',
    longitude: '-108.913596',
    timeZoneId: 'America/Denver',
  },
  '59015': {
    zip: '59015',
    city: 'Broadview',
    state: 'MT',
    latitude: '46.197479',
    longitude: '-108.913459',
    timeZoneId: 'America/Denver',
  },
  '59016': {
    zip: '59016',
    city: 'Busby',
    state: 'MT',
    latitude: '45.516379',
    longitude: '-106.872747',
    timeZoneId: 'America/Denver',
  },
  '59018': {
    zip: '59018',
    city: 'Clyde Park',
    state: 'MT',
    latitude: '45.707497',
    longitude: '-110.456916',
    timeZoneId: 'America/Denver',
  },
  '59019': {
    zip: '59019',
    city: 'Columbus',
    state: 'MT',
    latitude: '45.634341',
    longitude: '-109.255058',
    timeZoneId: 'America/Denver',
  },
  '59020': {
    zip: '59020',
    city: 'Cooke City',
    state: 'MT',
    latitude: '45.018847',
    longitude: '-109.934351',
    timeZoneId: 'America/Denver',
  },
  '59022': {
    zip: '59022',
    city: 'Crow Agency',
    state: 'MT',
    latitude: '45.643077',
    longitude: '-107.468227',
    timeZoneId: 'America/Denver',
  },
  '59024': {
    zip: '59024',
    city: 'Custer',
    state: 'MT',
    latitude: '46.206932',
    longitude: '-107.676388',
    timeZoneId: 'America/Denver',
  },
  '59025': {
    zip: '59025',
    city: 'Decker',
    state: 'MT',
    latitude: '45.129792',
    longitude: '-106.695652',
    timeZoneId: 'America/Denver',
  },
  '59026': {
    zip: '59026',
    city: 'Edgar',
    state: 'MT',
    latitude: '45.506601',
    longitude: '-109.009043',
    timeZoneId: 'America/Denver',
  },
  '59027': {
    zip: '59027',
    city: 'Emigrant',
    state: 'MT',
    latitude: '45.18284',
    longitude: '-110.937072',
    timeZoneId: 'America/Denver',
  },
  '59028': {
    zip: '59028',
    city: 'Fishtail',
    state: 'MT',
    latitude: '45.298974',
    longitude: '-109.788067',
    timeZoneId: 'America/Denver',
  },
  '59029': {
    zip: '59029',
    city: 'Fromberg',
    state: 'MT',
    latitude: '45.393397',
    longitude: '-108.908205',
    timeZoneId: 'America/Denver',
  },
  '59030': {
    zip: '59030',
    city: 'Gardiner',
    state: 'MT',
    latitude: '45.031682',
    longitude: '-110.705747',
    timeZoneId: 'America/Denver',
  },
  '59031': {
    zip: '59031',
    city: 'Garryowen',
    state: 'MT',
    latitude: '45.471093',
    longitude: '-107.261777',
    timeZoneId: 'America/Denver',
  },
  '59032': {
    zip: '59032',
    city: 'Grass Range',
    state: 'MT',
    latitude: '46.99191',
    longitude: '-108.90207',
    timeZoneId: 'America/Denver',
  },
  '59033': {
    zip: '59033',
    city: 'Greycliff',
    state: 'MT',
    latitude: '45.775614',
    longitude: '-109.687545',
    timeZoneId: 'America/Denver',
  },
  '59034': {
    zip: '59034',
    city: 'Hardin',
    state: 'MT',
    latitude: '45.714719',
    longitude: '-107.61874',
    timeZoneId: 'America/Denver',
  },
  '59035': {
    zip: '59035',
    city: 'Fort Smith',
    state: 'MT',
    latitude: '45.294259',
    longitude: '-107.822552',
    timeZoneId: 'America/Denver',
  },
  '59036': {
    zip: '59036',
    city: 'Harlowton',
    state: 'MT',
    latitude: '46.436792',
    longitude: '-109.831908',
    timeZoneId: 'America/Denver',
  },
  '59037': {
    zip: '59037',
    city: 'Huntley',
    state: 'MT',
    latitude: '45.890286',
    longitude: '-108.269545',
    timeZoneId: 'America/Denver',
  },
  '59038': {
    zip: '59038',
    city: 'Hysham',
    state: 'MT',
    latitude: '46.288514',
    longitude: '-107.232251',
    timeZoneId: 'America/Denver',
  },
  '59039': {
    zip: '59039',
    city: 'Ingomar',
    state: 'MT',
    latitude: '46.666043',
    longitude: '-107.528931',
    timeZoneId: 'America/Denver',
  },
  '59041': {
    zip: '59041',
    city: 'Joliet',
    state: 'MT',
    latitude: '45.49902',
    longitude: '-108.98958',
    timeZoneId: 'America/Denver',
  },
  '59043': {
    zip: '59043',
    city: 'Lame Deer',
    state: 'MT',
    latitude: '45.577559',
    longitude: '-106.530746',
    timeZoneId: 'America/Denver',
  },
  '59044': {
    zip: '59044',
    city: 'Laurel',
    state: 'MT',
    latitude: '45.672778',
    longitude: '-108.760361',
    timeZoneId: 'America/Denver',
  },
  '59046': {
    zip: '59046',
    city: 'Lavina',
    state: 'MT',
    latitude: '46.227533',
    longitude: '-108.713743',
    timeZoneId: 'America/Denver',
  },
  '59047': {
    zip: '59047',
    city: 'Livingston',
    state: 'MT',
    latitude: '45.652211',
    longitude: '-110.568153',
    timeZoneId: 'America/Denver',
  },
  '59050': {
    zip: '59050',
    city: 'Lodge Grass',
    state: 'MT',
    latitude: '45.294103',
    longitude: '-107.820532',
    timeZoneId: 'America/Denver',
  },
  '59052': {
    zip: '59052',
    city: 'Mc Leod',
    state: 'MT',
    latitude: '45.539768',
    longitude: '-110.306341',
    timeZoneId: 'America/Denver',
  },
  '59053': {
    zip: '59053',
    city: 'Martinsdale',
    state: 'MT',
    latitude: '46.416111',
    longitude: '-110.5425',
    timeZoneId: 'America/Denver',
  },
  '59054': {
    zip: '59054',
    city: 'Melstone',
    state: 'MT',
    latitude: '46.600643',
    longitude: '-107.866709',
    timeZoneId: 'America/Denver',
  },
  '59055': {
    zip: '59055',
    city: 'Melville',
    state: 'MT',
    latitude: '46.072838',
    longitude: '-109.879094',
    timeZoneId: 'America/Denver',
  },
  '59057': {
    zip: '59057',
    city: 'Molt',
    state: 'MT',
    latitude: '45.861923',
    longitude: '-108.802347',
    timeZoneId: 'America/Denver',
  },
  '59058': {
    zip: '59058',
    city: 'Mosby',
    state: 'MT',
    latitude: '46.925179',
    longitude: '-107.780982',
    timeZoneId: 'America/Denver',
  },
  '59059': {
    zip: '59059',
    city: 'Musselshell',
    state: 'MT',
    latitude: '46.514989',
    longitude: '-108.090082',
    timeZoneId: 'America/Denver',
  },
  '59061': {
    zip: '59061',
    city: 'Nye',
    state: 'MT',
    latitude: '45.439173',
    longitude: '-109.832978',
    timeZoneId: 'America/Denver',
  },
  '59062': {
    zip: '59062',
    city: 'Otter',
    state: 'MT',
    latitude: '45.397871',
    longitude: '-105.983338',
    timeZoneId: 'America/Denver',
  },
  '59063': {
    zip: '59063',
    city: 'Park City',
    state: 'MT',
    latitude: '45.633195',
    longitude: '-108.912996',
    timeZoneId: 'America/Denver',
  },
  '59064': {
    zip: '59064',
    city: 'Pompeys Pillar',
    state: 'MT',
    latitude: '45.949977',
    longitude: '-107.981373',
    timeZoneId: 'America/Denver',
  },
  '59065': {
    zip: '59065',
    city: 'Pray',
    state: 'MT',
    latitude: '45.437484',
    longitude: '-110.684001',
    timeZoneId: 'America/Denver',
  },
  '59066': {
    zip: '59066',
    city: 'Pryor',
    state: 'MT',
    latitude: '45.42079',
    longitude: '-108.529611',
    timeZoneId: 'America/Denver',
  },
  '59067': {
    zip: '59067',
    city: 'Rapelje',
    state: 'MT',
    latitude: '45.97898',
    longitude: '-109.320062',
    timeZoneId: 'America/Denver',
  },
  '59068': {
    zip: '59068',
    city: 'Red Lodge',
    state: 'MT',
    latitude: '45.186715',
    longitude: '-109.251476',
    timeZoneId: 'America/Denver',
  },
  '59069': {
    zip: '59069',
    city: 'Reed Point',
    state: 'MT',
    latitude: '45.704228',
    longitude: '-109.542867',
    timeZoneId: 'America/Denver',
  },
  '59070': {
    zip: '59070',
    city: 'Roberts',
    state: 'MT',
    latitude: '45.395254',
    longitude: '-109.169029',
    timeZoneId: 'America/Denver',
  },
  '59071': {
    zip: '59071',
    city: 'Roscoe',
    state: 'MT',
    latitude: '45.377011',
    longitude: '-109.424177',
    timeZoneId: 'America/Denver',
  },
  '59072': {
    zip: '59072',
    city: 'Roundup',
    state: 'MT',
    latitude: '46.410128',
    longitude: '-108.54663',
    timeZoneId: 'America/Denver',
  },
  '59073': {
    zip: '59073',
    city: 'Roundup',
    state: 'MT',
    latitude: '46.504722',
    longitude: '-108.218611',
    timeZoneId: 'America/Denver',
  },
  '59074': {
    zip: '59074',
    city: 'Ryegate',
    state: 'MT',
    latitude: '46.279039',
    longitude: '-109.286955',
    timeZoneId: 'America/Denver',
  },
  '59075': {
    zip: '59075',
    city: 'Saint Xavier',
    state: 'MT',
    latitude: '45.47849',
    longitude: '-107.877691',
    timeZoneId: 'America/Denver',
  },
  '59076': {
    zip: '59076',
    city: 'Sanders',
    state: 'MT',
    latitude: '46.292117',
    longitude: '-107.095156',
    timeZoneId: 'America/Denver',
  },
  '59077': {
    zip: '59077',
    city: 'Sand Springs',
    state: 'MT',
    latitude: '47.013392',
    longitude: '-107.275528',
    timeZoneId: 'America/Denver',
  },
  '59078': {
    zip: '59078',
    city: 'Shawmut',
    state: 'MT',
    latitude: '46.377137',
    longitude: '-109.592192',
    timeZoneId: 'America/Denver',
  },
  '59079': {
    zip: '59079',
    city: 'Shepherd',
    state: 'MT',
    latitude: '45.94681',
    longitude: '-108.35472',
    timeZoneId: 'America/Denver',
  },
  '59081': {
    zip: '59081',
    city: 'Silver Gate',
    state: 'MT',
    latitude: '45.104688',
    longitude: '-110.221461',
    timeZoneId: 'America/Denver',
  },
  '59082': {
    zip: '59082',
    city: 'Springdale',
    state: 'MT',
    latitude: '45.736616',
    longitude: '-110.227181',
    timeZoneId: 'America/Denver',
  },
  '59083': {
    zip: '59083',
    city: 'Sumatra',
    state: 'MT',
    latitude: '46.616991',
    longitude: '-107.553123',
    timeZoneId: 'America/Denver',
  },
  '59084': {
    zip: '59084',
    city: 'Teigen',
    state: 'MT',
    latitude: '47.039156',
    longitude: '-108.610344',
    timeZoneId: 'America/Denver',
  },
  '59085': {
    zip: '59085',
    city: 'Two Dot',
    state: 'MT',
    latitude: '46.424441',
    longitude: '-110.068651',
    timeZoneId: 'America/Denver',
  },
  '59086': {
    zip: '59086',
    city: 'Wilsall',
    state: 'MT',
    latitude: '46.055668',
    longitude: '-110.692681',
    timeZoneId: 'America/Denver',
  },
  '59087': {
    zip: '59087',
    city: 'Winnett',
    state: 'MT',
    latitude: '47.06528',
    longitude: '-108.00556',
    timeZoneId: 'America/Denver',
  },
  '59088': {
    zip: '59088',
    city: 'Worden',
    state: 'MT',
    latitude: '45.98879',
    longitude: '-108.173617',
    timeZoneId: 'America/Denver',
  },
  '59089': {
    zip: '59089',
    city: 'Wyola',
    state: 'MT',
    latitude: '45.081583',
    longitude: '-107.669356',
    timeZoneId: 'America/Denver',
  },
  '59101': {
    zip: '59101',
    city: 'Billings',
    state: 'MT',
    latitude: '45.774634',
    longitude: '-108.479727',
    timeZoneId: 'America/Denver',
  },
  '59102': {
    zip: '59102',
    city: 'Billings',
    state: 'MT',
    latitude: '45.785595',
    longitude: '-108.576337',
    timeZoneId: 'America/Denver',
  },
  '59103': {
    zip: '59103',
    city: 'Billings',
    state: 'MT',
    latitude: '45.637937',
    longitude: '-108.361329',
    timeZoneId: 'America/Denver',
  },
  '59104': {
    zip: '59104',
    city: 'Billings',
    state: 'MT',
    latitude: '45.77209',
    longitude: '-108.581076',
    timeZoneId: 'America/Denver',
  },
  '59105': {
    zip: '59105',
    city: 'Billings',
    state: 'MT',
    latitude: '45.840397',
    longitude: '-108.464534',
    timeZoneId: 'America/Denver',
  },
  '59106': {
    zip: '59106',
    city: 'Billings',
    state: 'MT',
    latitude: '45.76763',
    longitude: '-108.662527',
    timeZoneId: 'America/Denver',
  },
  '59107': {
    zip: '59107',
    city: 'Billings',
    state: 'MT',
    latitude: '45.813869',
    longitude: '-108.505776',
    timeZoneId: 'America/Denver',
  },
  '59108': {
    zip: '59108',
    city: 'Billings',
    state: 'MT',
    latitude: '45.775122',
    longitude: '-108.582655',
    timeZoneId: 'America/Denver',
  },
  '59111': {
    zip: '59111',
    city: 'Billings',
    state: 'MT',
    latitude: '45.634161',
    longitude: '-108.361047',
    timeZoneId: 'America/Denver',
  },
  '59112': {
    zip: '59112',
    city: 'Billings',
    state: 'MT',
    latitude: '45.791631',
    longitude: '-108.539787',
    timeZoneId: 'America/Denver',
  },
  '59114': {
    zip: '59114',
    city: 'Billings',
    state: 'MT',
    latitude: '45.641786',
    longitude: '-108.398787',
    timeZoneId: 'America/Denver',
  },
  '59115': {
    zip: '59115',
    city: 'Billings',
    state: 'MT',
    latitude: '45.785907',
    longitude: '-108.508798',
    timeZoneId: 'America/Denver',
  },
  '59116': {
    zip: '59116',
    city: 'Billings',
    state: 'MT',
    latitude: '45.767072',
    longitude: '-108.581669',
    timeZoneId: 'America/Denver',
  },
  '59117': {
    zip: '59117',
    city: 'Billings',
    state: 'MT',
    latitude: '45.783606',
    longitude: '-108.505138',
    timeZoneId: 'America/Denver',
  },
  '59201': {
    zip: '59201',
    city: 'Wolf Point',
    state: 'MT',
    latitude: '48.108925',
    longitude: '-105.569239',
    timeZoneId: 'America/Denver',
  },
  '59211': {
    zip: '59211',
    city: 'Antelope',
    state: 'MT',
    latitude: '48.689739',
    longitude: '-104.451099',
    timeZoneId: 'America/Denver',
  },
  '59212': {
    zip: '59212',
    city: 'Bainville',
    state: 'MT',
    latitude: '48.139108',
    longitude: '-104.22259',
    timeZoneId: 'America/Denver',
  },
  '59213': {
    zip: '59213',
    city: 'Brockton',
    state: 'MT',
    latitude: '48.329383',
    longitude: '-104.870593',
    timeZoneId: 'America/Denver',
  },
  '59214': {
    zip: '59214',
    city: 'Brockway',
    state: 'MT',
    latitude: '47.214915',
    longitude: '-105.839514',
    timeZoneId: 'America/Denver',
  },
  '59215': {
    zip: '59215',
    city: 'Circle',
    state: 'MT',
    latitude: '47.428645',
    longitude: '-105.619788',
    timeZoneId: 'America/Denver',
  },
  '59217': {
    zip: '59217',
    city: 'Crane',
    state: 'MT',
    latitude: '47.711877',
    longitude: '-104.182796',
    timeZoneId: 'America/Denver',
  },
  '59218': {
    zip: '59218',
    city: 'Culbertson',
    state: 'MT',
    latitude: '48.143909',
    longitude: '-104.51857',
    timeZoneId: 'America/Denver',
  },
  '59219': {
    zip: '59219',
    city: 'Dagmar',
    state: 'MT',
    latitude: '48.605863',
    longitude: '-104.247793',
    timeZoneId: 'America/Denver',
  },
  '59221': {
    zip: '59221',
    city: 'Fairview',
    state: 'MT',
    latitude: '47.853273',
    longitude: '-104.04859',
    timeZoneId: 'America/Denver',
  },
  '59222': {
    zip: '59222',
    city: 'Flaxville',
    state: 'MT',
    latitude: '48.710057',
    longitude: '-105.149161',
    timeZoneId: 'America/Denver',
  },
  '59223': {
    zip: '59223',
    city: 'Fort Peck',
    state: 'MT',
    latitude: '48.004486',
    longitude: '-106.444604',
    timeZoneId: 'America/Denver',
  },
  '59225': {
    zip: '59225',
    city: 'Frazer',
    state: 'MT',
    latitude: '48.4',
    longitude: '-105.883333',
    timeZoneId: 'America/Denver',
  },
  '59226': {
    zip: '59226',
    city: 'Froid',
    state: 'MT',
    latitude: '48.337849',
    longitude: '-104.491623',
    timeZoneId: 'America/Denver',
  },
  '59230': {
    zip: '59230',
    city: 'Glasgow',
    state: 'MT',
    latitude: '48.198389',
    longitude: '-106.625201',
    timeZoneId: 'America/Denver',
  },
  '59231': {
    zip: '59231',
    city: 'Saint Marie',
    state: 'MT',
    latitude: '48.402929',
    longitude: '-106.543693',
    timeZoneId: 'America/Denver',
  },
  '59240': {
    zip: '59240',
    city: 'Glentana',
    state: 'MT',
    latitude: '48.848625',
    longitude: '-106.248421',
    timeZoneId: 'America/Denver',
  },
  '59241': {
    zip: '59241',
    city: 'Hinsdale',
    state: 'MT',
    latitude: '48.393109',
    longitude: '-107.084294',
    timeZoneId: 'America/Denver',
  },
  '59242': {
    zip: '59242',
    city: 'Homestead',
    state: 'MT',
    latitude: '48.439875',
    longitude: '-104.59775',
    timeZoneId: 'America/Denver',
  },
  '59243': {
    zip: '59243',
    city: 'Lambert',
    state: 'MT',
    latitude: '47.757632',
    longitude: '-104.736737',
    timeZoneId: 'America/Denver',
  },
  '59244': {
    zip: '59244',
    city: 'Larslan',
    state: 'MT',
    latitude: '48.546336',
    longitude: '-106.225926',
    timeZoneId: 'America/Denver',
  },
  '59247': {
    zip: '59247',
    city: 'Medicine Lake',
    state: 'MT',
    latitude: '48.504952',
    longitude: '-104.501297',
    timeZoneId: 'America/Denver',
  },
  '59248': {
    zip: '59248',
    city: 'Nashua',
    state: 'MT',
    latitude: '48.141937',
    longitude: '-106.357405',
    timeZoneId: 'America/Denver',
  },
  '59250': {
    zip: '59250',
    city: 'Opheim',
    state: 'MT',
    latitude: '48.892429',
    longitude: '-106.475312',
    timeZoneId: 'America/Denver',
  },
  '59252': {
    zip: '59252',
    city: 'Outlook',
    state: 'MT',
    latitude: '48.896471',
    longitude: '-104.739387',
    timeZoneId: 'America/Denver',
  },
  '59253': {
    zip: '59253',
    city: 'Peerless',
    state: 'MT',
    latitude: '48.782296',
    longitude: '-105.799736',
    timeZoneId: 'America/Denver',
  },
  '59254': {
    zip: '59254',
    city: 'Plentywood',
    state: 'MT',
    latitude: '48.815609',
    longitude: '-104.661196',
    timeZoneId: 'America/Denver',
  },
  '59255': {
    zip: '59255',
    city: 'Poplar',
    state: 'MT',
    latitude: '48.108682',
    longitude: '-105.192014',
    timeZoneId: 'America/Denver',
  },
  '59256': {
    zip: '59256',
    city: 'Raymond',
    state: 'MT',
    latitude: '48.782168',
    longitude: '-104.556725',
    timeZoneId: 'America/Denver',
  },
  '59257': {
    zip: '59257',
    city: 'Redstone',
    state: 'MT',
    latitude: '48.83315',
    longitude: '-104.922222',
    timeZoneId: 'America/Denver',
  },
  '59258': {
    zip: '59258',
    city: 'Reserve',
    state: 'MT',
    latitude: '48.571511',
    longitude: '-104.690538',
    timeZoneId: 'America/Denver',
  },
  '59259': {
    zip: '59259',
    city: 'Richey',
    state: 'MT',
    latitude: '47.64064',
    longitude: '-104.980628',
    timeZoneId: 'America/Denver',
  },
  '59260': {
    zip: '59260',
    city: 'Richland',
    state: 'MT',
    latitude: '48.715921',
    longitude: '-106.267026',
    timeZoneId: 'America/Denver',
  },
  '59261': {
    zip: '59261',
    city: 'Saco',
    state: 'MT',
    latitude: '48.457358',
    longitude: '-107.338613',
    timeZoneId: 'America/Denver',
  },
  '59262': {
    zip: '59262',
    city: 'Savage',
    state: 'MT',
    latitude: '47.453876',
    longitude: '-104.345096',
    timeZoneId: 'America/Denver',
  },
  '59263': {
    zip: '59263',
    city: 'Scobey',
    state: 'MT',
    latitude: '48.790791',
    longitude: '-105.423135',
    timeZoneId: 'America/Denver',
  },
  '59270': {
    zip: '59270',
    city: 'Sidney',
    state: 'MT',
    latitude: '47.715554',
    longitude: '-104.161624',
    timeZoneId: 'America/Denver',
  },
  '59273': {
    zip: '59273',
    city: 'Vandalia',
    state: 'MT',
    latitude: '48.356238',
    longitude: '-106.909833',
    timeZoneId: 'America/Denver',
  },
  '59274': {
    zip: '59274',
    city: 'Vida',
    state: 'MT',
    latitude: '47.844345',
    longitude: '-105.771091',
    timeZoneId: 'America/Denver',
  },
  '59275': {
    zip: '59275',
    city: 'Westby',
    state: 'MT',
    latitude: '48.844757',
    longitude: '-104.193137',
    timeZoneId: 'America/Denver',
  },
  '59276': {
    zip: '59276',
    city: 'Whitetail',
    state: 'MT',
    latitude: '48.89936',
    longitude: '-105.162911',
    timeZoneId: 'America/Denver',
  },
  '59301': {
    zip: '59301',
    city: 'Miles City',
    state: 'MT',
    latitude: '46.405886',
    longitude: '-105.841129',
    timeZoneId: 'America/Denver',
  },
  '59311': {
    zip: '59311',
    city: 'Alzada',
    state: 'MT',
    latitude: '45.184649',
    longitude: '-104.249236',
    timeZoneId: 'America/Denver',
  },
  '59312': {
    zip: '59312',
    city: 'Angela',
    state: 'MT',
    latitude: '46.67284',
    longitude: '-106.397622',
    timeZoneId: 'America/Denver',
  },
  '59313': {
    zip: '59313',
    city: 'Baker',
    state: 'MT',
    latitude: '46.360175',
    longitude: '-104.270068',
    timeZoneId: 'America/Denver',
  },
  '59314': {
    zip: '59314',
    city: 'Biddle',
    state: 'MT',
    latitude: '45.286436',
    longitude: '-105.315136',
    timeZoneId: 'America/Denver',
  },
  '59315': {
    zip: '59315',
    city: 'Bloomfield',
    state: 'MT',
    latitude: '47.380907',
    longitude: '-104.944266',
    timeZoneId: 'America/Denver',
  },
  '59316': {
    zip: '59316',
    city: 'Boyes',
    state: 'MT',
    latitude: '45.507523',
    longitude: '-104.910869',
    timeZoneId: 'America/Denver',
  },
  '59317': {
    zip: '59317',
    city: 'Broadus',
    state: 'MT',
    latitude: '45.41354',
    longitude: '-105.82986',
    timeZoneId: 'America/Denver',
  },
  '59318': {
    zip: '59318',
    city: 'Brusett',
    state: 'MT',
    latitude: '47.31115',
    longitude: '-107.626383',
    timeZoneId: 'America/Denver',
  },
  '59319': {
    zip: '59319',
    city: 'Capitol',
    state: 'MT',
    latitude: '45.467901',
    longitude: '-104.184538',
    timeZoneId: 'America/Denver',
  },
  '59322': {
    zip: '59322',
    city: 'Cohagen',
    state: 'MT',
    latitude: '47.087081',
    longitude: '-106.512996',
    timeZoneId: 'America/Denver',
  },
  '59323': {
    zip: '59323',
    city: 'Colstrip',
    state: 'MT',
    latitude: '45.899305',
    longitude: '-106.624828',
    timeZoneId: 'America/Denver',
  },
  '59324': {
    zip: '59324',
    city: 'Ekalaka',
    state: 'MT',
    latitude: '45.85314',
    longitude: '-104.21735',
    timeZoneId: 'America/Denver',
  },
  '59326': {
    zip: '59326',
    city: 'Fallon',
    state: 'MT',
    latitude: '46.6758',
    longitude: '-105.139405',
    timeZoneId: 'America/Denver',
  },
  '59327': {
    zip: '59327',
    city: 'Forsyth',
    state: 'MT',
    latitude: '46.266629',
    longitude: '-106.680593',
    timeZoneId: 'America/Denver',
  },
  '59330': {
    zip: '59330',
    city: 'Glendive',
    state: 'MT',
    latitude: '47.111083',
    longitude: '-104.721775',
    timeZoneId: 'America/Denver',
  },
  '59332': {
    zip: '59332',
    city: 'Hammond',
    state: 'MT',
    latitude: '45.369487',
    longitude: '-104.653365',
    timeZoneId: 'America/Denver',
  },
  '59333': {
    zip: '59333',
    city: 'Hathaway',
    state: 'MT',
    latitude: '46.275921',
    longitude: '-106.197425',
    timeZoneId: 'America/Denver',
  },
  '59336': {
    zip: '59336',
    city: 'Ismay',
    state: 'MT',
    latitude: '46.498305',
    longitude: '-104.793911',
    timeZoneId: 'America/Denver',
  },
  '59337': {
    zip: '59337',
    city: 'Jordan',
    state: 'MT',
    latitude: '47.543154',
    longitude: '-106.79227',
    timeZoneId: 'America/Denver',
  },
  '59338': {
    zip: '59338',
    city: 'Kinsey',
    state: 'MT',
    latitude: '46.662103',
    longitude: '-105.872622',
    timeZoneId: 'America/Denver',
  },
  '59339': {
    zip: '59339',
    city: 'Lindsay',
    state: 'MT',
    latitude: '47.195082',
    longitude: '-105.200493',
    timeZoneId: 'America/Denver',
  },
  '59341': {
    zip: '59341',
    city: 'Mildred',
    state: 'MT',
    latitude: '46.741221',
    longitude: '-104.782243',
    timeZoneId: 'America/Denver',
  },
  '59343': {
    zip: '59343',
    city: 'Olive',
    state: 'MT',
    latitude: '45.543207',
    longitude: '-105.650812',
    timeZoneId: 'America/Denver',
  },
  '59344': {
    zip: '59344',
    city: 'Plevna',
    state: 'MT',
    latitude: '46.388263',
    longitude: '-104.642861',
    timeZoneId: 'America/Denver',
  },
  '59345': {
    zip: '59345',
    city: 'Powderville',
    state: 'MT',
    latitude: '45.741289',
    longitude: '-105.248925',
    timeZoneId: 'America/Denver',
  },
  '59347': {
    zip: '59347',
    city: 'Rosebud',
    state: 'MT',
    latitude: '46.274581',
    longitude: '-106.441343',
    timeZoneId: 'America/Denver',
  },
  '59349': {
    zip: '59349',
    city: 'Terry',
    state: 'MT',
    latitude: '46.791173',
    longitude: '-105.314661',
    timeZoneId: 'America/Denver',
  },
  '59351': {
    zip: '59351',
    city: 'Volborg',
    state: 'MT',
    latitude: '46.035585',
    longitude: '-105.624324',
    timeZoneId: 'America/Denver',
  },
  '59353': {
    zip: '59353',
    city: 'Wibaux',
    state: 'MT',
    latitude: '46.983568',
    longitude: '-104.188289',
    timeZoneId: 'America/Denver',
  },
  '59354': {
    zip: '59354',
    city: 'Willard',
    state: 'MT',
    latitude: '46.130042',
    longitude: '-104.458001',
    timeZoneId: 'America/Denver',
  },
  '59401': {
    zip: '59401',
    city: 'Great Falls',
    state: 'MT',
    latitude: '47.509333',
    longitude: '-111.288029',
    timeZoneId: 'America/Denver',
  },
  '59402': {
    zip: '59402',
    city: 'Malmstrom AFB',
    state: 'MT',
    latitude: '47.509367',
    longitude: '-111.194562',
    timeZoneId: 'America/Denver',
  },
  '59403': {
    zip: '59403',
    city: 'Great Falls',
    state: 'MT',
    latitude: '47.553383',
    longitude: '-111.540413',
    timeZoneId: 'America/Denver',
  },
  '59404': {
    zip: '59404',
    city: 'Great Falls',
    state: 'MT',
    latitude: '47.51092',
    longitude: '-111.352773',
    timeZoneId: 'America/Denver',
  },
  '59405': {
    zip: '59405',
    city: 'Great Falls',
    state: 'MT',
    latitude: '47.473605',
    longitude: '-111.259493',
    timeZoneId: 'America/Denver',
  },
  '59406': {
    zip: '59406',
    city: 'Great Falls',
    state: 'MT',
    latitude: '47.481052',
    longitude: '-111.253398',
    timeZoneId: 'America/Denver',
  },
  '59410': {
    zip: '59410',
    city: 'Augusta',
    state: 'MT',
    latitude: '47.392784',
    longitude: '-112.635264',
    timeZoneId: 'America/Denver',
  },
  '59411': {
    zip: '59411',
    city: 'Babb',
    state: 'MT',
    latitude: '48.85167',
    longitude: '-113.43599',
    timeZoneId: 'America/Denver',
  },
  '59412': {
    zip: '59412',
    city: 'Belt',
    state: 'MT',
    latitude: '47.371142',
    longitude: '-110.903119',
    timeZoneId: 'America/Denver',
  },
  '59414': {
    zip: '59414',
    city: 'Black Eagle',
    state: 'MT',
    latitude: '47.514757',
    longitude: '-111.298086',
    timeZoneId: 'America/Denver',
  },
  '59416': {
    zip: '59416',
    city: 'Brady',
    state: 'MT',
    latitude: '48.028026',
    longitude: '-111.657445',
    timeZoneId: 'America/Denver',
  },
  '59417': {
    zip: '59417',
    city: 'Browning',
    state: 'MT',
    latitude: '48.571299',
    longitude: '-113.206612',
    timeZoneId: 'America/Denver',
  },
  '59418': {
    zip: '59418',
    city: 'Buffalo',
    state: 'MT',
    latitude: '46.797946',
    longitude: '-109.687162',
    timeZoneId: 'America/Denver',
  },
  '59419': {
    zip: '59419',
    city: 'Bynum',
    state: 'MT',
    latitude: '47.981196',
    longitude: '-112.307571',
    timeZoneId: 'America/Denver',
  },
  '59420': {
    zip: '59420',
    city: 'Carter',
    state: 'MT',
    latitude: '47.78572',
    longitude: '-110.999607',
    timeZoneId: 'America/Denver',
  },
  '59421': {
    zip: '59421',
    city: 'Cascade',
    state: 'MT',
    latitude: '47.205603',
    longitude: '-111.699843',
    timeZoneId: 'America/Denver',
  },
  '59422': {
    zip: '59422',
    city: 'Choteau',
    state: 'MT',
    latitude: '47.830951',
    longitude: '-112.163398',
    timeZoneId: 'America/Denver',
  },
  '59424': {
    zip: '59424',
    city: 'Coffee Creek',
    state: 'MT',
    latitude: '47.350313',
    longitude: '-110.085182',
    timeZoneId: 'America/Denver',
  },
  '59425': {
    zip: '59425',
    city: 'Conrad',
    state: 'MT',
    latitude: '48.169762',
    longitude: '-111.949697',
    timeZoneId: 'America/Denver',
  },
  '59427': {
    zip: '59427',
    city: 'Cut Bank',
    state: 'MT',
    latitude: '48.647289',
    longitude: '-112.350965',
    timeZoneId: 'America/Denver',
  },
  '59430': {
    zip: '59430',
    city: 'Denton',
    state: 'MT',
    latitude: '47.320831',
    longitude: '-109.946899',
    timeZoneId: 'America/Denver',
  },
  '59432': {
    zip: '59432',
    city: 'Dupuyer',
    state: 'MT',
    latitude: '48.215159',
    longitude: '-112.671463',
    timeZoneId: 'America/Denver',
  },
  '59433': {
    zip: '59433',
    city: 'Dutton',
    state: 'MT',
    latitude: '47.846215',
    longitude: '-111.709429',
    timeZoneId: 'America/Denver',
  },
  '59434': {
    zip: '59434',
    city: 'East Glacier Park',
    state: 'MT',
    latitude: '48.444373',
    longitude: '-113.218214',
    timeZoneId: 'America/Denver',
  },
  '59435': {
    zip: '59435',
    city: 'Ethridge',
    state: 'MT',
    latitude: '48.594725',
    longitude: '-112.101698',
    timeZoneId: 'America/Denver',
  },
  '59436': {
    zip: '59436',
    city: 'Fairfield',
    state: 'MT',
    latitude: '47.614592',
    longitude: '-112.099185',
    timeZoneId: 'America/Denver',
  },
  '59440': {
    zip: '59440',
    city: 'Floweree',
    state: 'MT',
    latitude: '47.655537',
    longitude: '-111.198143',
    timeZoneId: 'America/Denver',
  },
  '59441': {
    zip: '59441',
    city: 'Forest Grove',
    state: 'MT',
    latitude: '46.86725',
    longitude: '-109.053316',
    timeZoneId: 'America/Denver',
  },
  '59442': {
    zip: '59442',
    city: 'Fort Benton',
    state: 'MT',
    latitude: '47.819573',
    longitude: '-110.670979',
    timeZoneId: 'America/Denver',
  },
  '59443': {
    zip: '59443',
    city: 'Fort Shaw',
    state: 'MT',
    latitude: '47.557372',
    longitude: '-111.809255',
    timeZoneId: 'America/Denver',
  },
  '59444': {
    zip: '59444',
    city: 'Galata',
    state: 'MT',
    latitude: '48.734732',
    longitude: '-111.46832',
    timeZoneId: 'America/Denver',
  },
  '59446': {
    zip: '59446',
    city: 'Geraldine',
    state: 'MT',
    latitude: '47.601836',
    longitude: '-110.26977',
    timeZoneId: 'America/Denver',
  },
  '59447': {
    zip: '59447',
    city: 'Geyser',
    state: 'MT',
    latitude: '47.220782',
    longitude: '-110.430563',
    timeZoneId: 'America/Denver',
  },
  '59448': {
    zip: '59448',
    city: 'Heart Butte',
    state: 'MT',
    latitude: '48.315582',
    longitude: '-112.929607',
    timeZoneId: 'America/Denver',
  },
  '59450': {
    zip: '59450',
    city: 'Highwood',
    state: 'MT',
    latitude: '47.628889',
    longitude: '-110.571944',
    timeZoneId: 'America/Denver',
  },
  '59451': {
    zip: '59451',
    city: 'Hilger',
    state: 'MT',
    latitude: '47.067997',
    longitude: '-109.432142',
    timeZoneId: 'America/Denver',
  },
  '59452': {
    zip: '59452',
    city: 'Hobson',
    state: 'MT',
    latitude: '46.869446',
    longitude: '-110.205075',
    timeZoneId: 'America/Denver',
  },
  '59453': {
    zip: '59453',
    city: 'Judith Gap',
    state: 'MT',
    latitude: '46.605514',
    longitude: '-109.599361',
    timeZoneId: 'America/Denver',
  },
  '59454': {
    zip: '59454',
    city: 'Kevin',
    state: 'MT',
    latitude: '48.746263',
    longitude: '-112.014265',
    timeZoneId: 'America/Denver',
  },
  '59456': {
    zip: '59456',
    city: 'Ledger',
    state: 'MT',
    latitude: '48.290217',
    longitude: '-111.742416',
    timeZoneId: 'America/Denver',
  },
  '59457': {
    zip: '59457',
    city: 'Lewistown',
    state: 'MT',
    latitude: '47.04978',
    longitude: '-109.4253',
    timeZoneId: 'America/Denver',
  },
  '59460': {
    zip: '59460',
    city: 'Loma',
    state: 'MT',
    latitude: '48.00334',
    longitude: '-110.389738',
    timeZoneId: 'America/Denver',
  },
  '59461': {
    zip: '59461',
    city: 'Lothair',
    state: 'MT',
    latitude: '48.471787',
    longitude: '-111.232307',
    timeZoneId: 'America/Denver',
  },
  '59462': {
    zip: '59462',
    city: 'Moccasin',
    state: 'MT',
    latitude: '47.108054',
    longitude: '-109.880806',
    timeZoneId: 'America/Denver',
  },
  '59463': {
    zip: '59463',
    city: 'Monarch',
    state: 'MT',
    latitude: '47.078415',
    longitude: '-110.835427',
    timeZoneId: 'America/Denver',
  },
  '59464': {
    zip: '59464',
    city: 'Moore',
    state: 'MT',
    latitude: '46.971054',
    longitude: '-109.694698',
    timeZoneId: 'America/Denver',
  },
  '59465': {
    zip: '59465',
    city: 'Neihart',
    state: 'MT',
    latitude: '47.046281',
    longitude: '-110.77049',
    timeZoneId: 'America/Denver',
  },
  '59466': {
    zip: '59466',
    city: 'Oilmont',
    state: 'MT',
    latitude: '48.754167',
    longitude: '-111.774167',
    timeZoneId: 'America/Denver',
  },
  '59467': {
    zip: '59467',
    city: 'Pendroy',
    state: 'MT',
    latitude: '48.080537',
    longitude: '-112.278112',
    timeZoneId: 'America/Denver',
  },
  '59468': {
    zip: '59468',
    city: 'Power',
    state: 'MT',
    latitude: '47.650996',
    longitude: '-111.479871',
    timeZoneId: 'America/Denver',
  },
  '59469': {
    zip: '59469',
    city: 'Raynesford',
    state: 'MT',
    latitude: '47.248401',
    longitude: '-110.684347',
    timeZoneId: 'America/Denver',
  },
  '59471': {
    zip: '59471',
    city: 'Roy',
    state: 'MT',
    latitude: '47.406535',
    longitude: '-108.739056',
    timeZoneId: 'America/Denver',
  },
  '59472': {
    zip: '59472',
    city: 'Sand Coulee',
    state: 'MT',
    latitude: '47.394577',
    longitude: '-111.164252',
    timeZoneId: 'America/Denver',
  },
  '59474': {
    zip: '59474',
    city: 'Shelby',
    state: 'MT',
    latitude: '48.50959',
    longitude: '-111.830333',
    timeZoneId: 'America/Denver',
  },
  '59477': {
    zip: '59477',
    city: 'Simms',
    state: 'MT',
    latitude: '47.496332',
    longitude: '-111.92872',
    timeZoneId: 'America/Denver',
  },
  '59479': {
    zip: '59479',
    city: 'Stanford',
    state: 'MT',
    latitude: '47.14392',
    longitude: '-110.17038',
    timeZoneId: 'America/Denver',
  },
  '59480': {
    zip: '59480',
    city: 'Stockett',
    state: 'MT',
    latitude: '47.237996',
    longitude: '-111.146478',
    timeZoneId: 'America/Denver',
  },
  '59482': {
    zip: '59482',
    city: 'Sunburst',
    state: 'MT',
    latitude: '48.879734',
    longitude: '-111.911966',
    timeZoneId: 'America/Denver',
  },
  '59483': {
    zip: '59483',
    city: 'Sun River',
    state: 'MT',
    latitude: '47.502761',
    longitude: '-111.746669',
    timeZoneId: 'America/Denver',
  },
  '59484': {
    zip: '59484',
    city: 'Sweet Grass',
    state: 'MT',
    latitude: '48.964779',
    longitude: '-112.030107',
    timeZoneId: 'America/Denver',
  },
  '59485': {
    zip: '59485',
    city: 'Ulm',
    state: 'MT',
    latitude: '47.421211',
    longitude: '-111.544461',
    timeZoneId: 'America/Denver',
  },
  '59486': {
    zip: '59486',
    city: 'Valier',
    state: 'MT',
    latitude: '48.306088',
    longitude: '-112.253949',
    timeZoneId: 'America/Denver',
  },
  '59487': {
    zip: '59487',
    city: 'Vaughn',
    state: 'MT',
    latitude: '47.57006',
    longitude: '-111.616366',
    timeZoneId: 'America/Denver',
  },
  '59489': {
    zip: '59489',
    city: 'Winifred',
    state: 'MT',
    latitude: '47.631387',
    longitude: '-109.214912',
    timeZoneId: 'America/Denver',
  },
  '59501': {
    zip: '59501',
    city: 'Havre',
    state: 'MT',
    latitude: '48.544915',
    longitude: '-109.682429',
    timeZoneId: 'America/Denver',
  },
  '59520': {
    zip: '59520',
    city: 'Big Sandy',
    state: 'MT',
    latitude: '48.059026',
    longitude: '-110.023117',
    timeZoneId: 'America/Denver',
  },
  '59521': {
    zip: '59521',
    city: 'Box Elder',
    state: 'MT',
    latitude: '48.320436',
    longitude: '-109.832831',
    timeZoneId: 'America/Denver',
  },
  '59522': {
    zip: '59522',
    city: 'Chester',
    state: 'MT',
    latitude: '48.510703',
    longitude: '-110.964702',
    timeZoneId: 'America/Denver',
  },
  '59523': {
    zip: '59523',
    city: 'Chinook',
    state: 'MT',
    latitude: '48.588629',
    longitude: '-109.229533',
    timeZoneId: 'America/Denver',
  },
  '59524': {
    zip: '59524',
    city: 'Dodson',
    state: 'MT',
    latitude: '48.201646',
    longitude: '-108.522788',
    timeZoneId: 'America/Denver',
  },
  '59525': {
    zip: '59525',
    city: 'Gildford',
    state: 'MT',
    latitude: '48.601564',
    longitude: '-110.267779',
    timeZoneId: 'America/Denver',
  },
  '59526': {
    zip: '59526',
    city: 'Harlem',
    state: 'MT',
    latitude: '48.600839',
    longitude: '-108.85251',
    timeZoneId: 'America/Denver',
  },
  '59527': {
    zip: '59527',
    city: 'Hays',
    state: 'MT',
    latitude: '48.2096',
    longitude: '-108.804992',
    timeZoneId: 'America/Denver',
  },
  '59528': {
    zip: '59528',
    city: 'Hingham',
    state: 'MT',
    latitude: '48.595491',
    longitude: '-110.425625',
    timeZoneId: 'America/Denver',
  },
  '59529': {
    zip: '59529',
    city: 'Hogeland',
    state: 'MT',
    latitude: '48.877402',
    longitude: '-108.722102',
    timeZoneId: 'America/Denver',
  },
  '59530': {
    zip: '59530',
    city: 'Inverness',
    state: 'MT',
    latitude: '48.625245',
    longitude: '-110.686243',
    timeZoneId: 'America/Denver',
  },
  '59531': {
    zip: '59531',
    city: 'Joplin',
    state: 'MT',
    latitude: '48.697325',
    longitude: '-110.825078',
    timeZoneId: 'America/Denver',
  },
  '59532': {
    zip: '59532',
    city: 'Kremlin',
    state: 'MT',
    latitude: '48.567704',
    longitude: '-110.047892',
    timeZoneId: 'America/Denver',
  },
  '59535': {
    zip: '59535',
    city: 'Lloyd',
    state: 'MT',
    latitude: '48.043936',
    longitude: '-109.213597',
    timeZoneId: 'America/Denver',
  },
  '59537': {
    zip: '59537',
    city: 'Loring',
    state: 'MT',
    latitude: '48.818755',
    longitude: '-107.913955',
    timeZoneId: 'America/Denver',
  },
  '59538': {
    zip: '59538',
    city: 'Malta',
    state: 'MT',
    latitude: '48.34368',
    longitude: '-107.83938',
    timeZoneId: 'America/Denver',
  },
  '59540': {
    zip: '59540',
    city: 'Rudyard',
    state: 'MT',
    latitude: '48.653209',
    longitude: '-110.555719',
    timeZoneId: 'America/Denver',
  },
  '59542': {
    zip: '59542',
    city: 'Turner',
    state: 'MT',
    latitude: '48.876686',
    longitude: '-108.374004',
    timeZoneId: 'America/Denver',
  },
  '59544': {
    zip: '59544',
    city: 'Whitewater',
    state: 'MT',
    latitude: '48.874101',
    longitude: '-107.391343',
    timeZoneId: 'America/Denver',
  },
  '59545': {
    zip: '59545',
    city: 'Whitlash',
    state: 'MT',
    latitude: '48.839116',
    longitude: '-111.102519',
    timeZoneId: 'America/Denver',
  },
  '59546': {
    zip: '59546',
    city: 'Zortman',
    state: 'MT',
    latitude: '47.91743',
    longitude: '-108.53018',
    timeZoneId: 'America/Denver',
  },
  '59547': {
    zip: '59547',
    city: 'Zurich',
    state: 'MT',
    latitude: '48.654051',
    longitude: '-109.212878',
    timeZoneId: 'America/Denver',
  },
  '59601': {
    zip: '59601',
    city: 'Helena',
    state: 'MT',
    latitude: '46.625304',
    longitude: '-112.009553',
    timeZoneId: 'America/Denver',
  },
  '59602': {
    zip: '59602',
    city: 'Helena',
    state: 'MT',
    latitude: '46.645305',
    longitude: '-111.974322',
    timeZoneId: 'America/Denver',
  },
  '59604': {
    zip: '59604',
    city: 'Helena',
    state: 'MT',
    latitude: '46.615752',
    longitude: '-111.963247',
    timeZoneId: 'America/Denver',
  },
  '59620': {
    zip: '59620',
    city: 'Helena',
    state: 'MT',
    latitude: '46.532823',
    longitude: '-112.157489',
    timeZoneId: 'America/Denver',
  },
  '59623': {
    zip: '59623',
    city: 'Helena',
    state: 'MT',
    latitude: '46.608066',
    longitude: '-112.014893',
    timeZoneId: 'America/Denver',
  },
  '59624': {
    zip: '59624',
    city: 'Helena',
    state: 'MT',
    latitude: '46.597949',
    longitude: '-112.031022',
    timeZoneId: 'America/Denver',
  },
  '59625': {
    zip: '59625',
    city: 'Helena',
    state: 'MT',
    latitude: '46.609086',
    longitude: '-112.039756',
    timeZoneId: 'America/Denver',
  },
  '59626': {
    zip: '59626',
    city: 'Helena',
    state: 'MT',
    latitude: '46.603688',
    longitude: '-112.036306',
    timeZoneId: 'America/Denver',
  },
  '59631': {
    zip: '59631',
    city: 'Basin',
    state: 'MT',
    latitude: '46.303194',
    longitude: '-112.367722',
    timeZoneId: 'America/Denver',
  },
  '59632': {
    zip: '59632',
    city: 'Boulder',
    state: 'MT',
    latitude: '46.202844',
    longitude: '-112.081611',
    timeZoneId: 'America/Denver',
  },
  '59633': {
    zip: '59633',
    city: 'Canyon Creek',
    state: 'MT',
    latitude: '46.839213',
    longitude: '-112.306088',
    timeZoneId: 'America/Denver',
  },
  '59634': {
    zip: '59634',
    city: 'Clancy',
    state: 'MT',
    latitude: '46.49538',
    longitude: '-111.9675',
    timeZoneId: 'America/Denver',
  },
  '59635': {
    zip: '59635',
    city: 'East Helena',
    state: 'MT',
    latitude: '46.60124',
    longitude: '-111.890548',
    timeZoneId: 'America/Denver',
  },
  '59636': {
    zip: '59636',
    city: 'Fort Harrison',
    state: 'MT',
    latitude: '46.604791',
    longitude: '-112.091661',
    timeZoneId: 'America/Denver',
  },
  '59638': {
    zip: '59638',
    city: 'Jefferson City',
    state: 'MT',
    latitude: '46.383852',
    longitude: '-112.059319',
    timeZoneId: 'America/Denver',
  },
  '59639': {
    zip: '59639',
    city: 'Lincoln',
    state: 'MT',
    latitude: '46.9463',
    longitude: '-112.914727',
    timeZoneId: 'America/Denver',
  },
  '59640': {
    zip: '59640',
    city: 'Marysville',
    state: 'MT',
    latitude: '46.753933',
    longitude: '-112.302114',
    timeZoneId: 'America/Denver',
  },
  '59641': {
    zip: '59641',
    city: 'Radersburg',
    state: 'MT',
    latitude: '46.200663',
    longitude: '-111.63195',
    timeZoneId: 'America/Denver',
  },
  '59642': {
    zip: '59642',
    city: 'Ringling',
    state: 'MT',
    latitude: '46.301734',
    longitude: '-110.872484',
    timeZoneId: 'America/Denver',
  },
  '59643': {
    zip: '59643',
    city: 'Toston',
    state: 'MT',
    latitude: '46.175683',
    longitude: '-111.481307',
    timeZoneId: 'America/Denver',
  },
  '59644': {
    zip: '59644',
    city: 'Townsend',
    state: 'MT',
    latitude: '46.316771',
    longitude: '-111.4904',
    timeZoneId: 'America/Denver',
  },
  '59645': {
    zip: '59645',
    city: 'White Sulphur Springs',
    state: 'MT',
    latitude: '46.539963',
    longitude: '-110.910288',
    timeZoneId: 'America/Denver',
  },
  '59647': {
    zip: '59647',
    city: 'Winston',
    state: 'MT',
    latitude: '46.363288',
    longitude: '-111.641755',
    timeZoneId: 'America/Denver',
  },
  '59648': {
    zip: '59648',
    city: 'Wolf Creek',
    state: 'MT',
    latitude: '47.044107',
    longitude: '-112.063648',
    timeZoneId: 'America/Denver',
  },
  '59701': {
    zip: '59701',
    city: 'Butte',
    state: 'MT',
    latitude: '45.94674',
    longitude: '-112.51734',
    timeZoneId: 'America/Denver',
  },
  '59702': {
    zip: '59702',
    city: 'Butte',
    state: 'MT',
    latitude: '45.908075',
    longitude: '-112.496591',
    timeZoneId: 'America/Denver',
  },
  '59703': {
    zip: '59703',
    city: 'Butte',
    state: 'MT',
    latitude: '45.988187',
    longitude: '-112.551908',
    timeZoneId: 'America/Denver',
  },
  '59707': {
    zip: '59707',
    city: 'Butte',
    state: 'MT',
    latitude: '46.005208',
    longitude: '-112.53493',
    timeZoneId: 'America/Denver',
  },
  '59710': {
    zip: '59710',
    city: 'Alder',
    state: 'MT',
    latitude: '45.289372',
    longitude: '-112.104091',
    timeZoneId: 'America/Denver',
  },
  '59711': {
    zip: '59711',
    city: 'Anaconda',
    state: 'MT',
    latitude: '46.13982',
    longitude: '-113.04594',
    timeZoneId: 'America/Denver',
  },
  '59713': {
    zip: '59713',
    city: 'Avon',
    state: 'MT',
    latitude: '46.781637',
    longitude: '-112.75714',
    timeZoneId: 'America/Denver',
  },
  '59714': {
    zip: '59714',
    city: 'Belgrade',
    state: 'MT',
    latitude: '45.816111',
    longitude: '-111.161003',
    timeZoneId: 'America/Denver',
  },
  '59715': {
    zip: '59715',
    city: 'Bozeman',
    state: 'MT',
    latitude: '45.674022',
    longitude: '-111.064218',
    timeZoneId: 'America/Denver',
  },
  '59716': {
    zip: '59716',
    city: 'Big Sky',
    state: 'MT',
    latitude: '45.264965',
    longitude: '-111.25312',
    timeZoneId: 'America/Denver',
  },
  '59717': {
    zip: '59717',
    city: 'Bozeman',
    state: 'MT',
    latitude: '45.661963',
    longitude: '-111.059937',
    timeZoneId: 'America/Denver',
  },
  '59718': {
    zip: '59718',
    city: 'Bozeman',
    state: 'MT',
    latitude: '45.646519',
    longitude: '-111.204847',
    timeZoneId: 'America/Denver',
  },
  '59719': {
    zip: '59719',
    city: 'Bozeman',
    state: 'MT',
    latitude: '45.678181',
    longitude: '-111.036579',
    timeZoneId: 'America/Denver',
  },
  '59720': {
    zip: '59720',
    city: 'Cameron',
    state: 'MT',
    latitude: '44.97768',
    longitude: '-111.619208',
    timeZoneId: 'America/Denver',
  },
  '59721': {
    zip: '59721',
    city: 'Cardwell',
    state: 'MT',
    latitude: '45.862721',
    longitude: '-111.977005',
    timeZoneId: 'America/Denver',
  },
  '59722': {
    zip: '59722',
    city: 'Deer Lodge',
    state: 'MT',
    latitude: '46.38042',
    longitude: '-112.72236',
    timeZoneId: 'America/Denver',
  },
  '59724': {
    zip: '59724',
    city: 'Dell',
    state: 'MT',
    latitude: '44.72399',
    longitude: '-112.70015',
    timeZoneId: 'America/Denver',
  },
  '59725': {
    zip: '59725',
    city: 'Dillon',
    state: 'MT',
    latitude: '45.21189',
    longitude: '-112.649262',
    timeZoneId: 'America/Denver',
  },
  '59727': {
    zip: '59727',
    city: 'Divide',
    state: 'MT',
    latitude: '45.780438',
    longitude: '-112.710053',
    timeZoneId: 'America/Denver',
  },
  '59728': {
    zip: '59728',
    city: 'Elliston',
    state: 'MT',
    latitude: '46.574757',
    longitude: '-112.497615',
    timeZoneId: 'America/Denver',
  },
  '59729': {
    zip: '59729',
    city: 'Ennis',
    state: 'MT',
    latitude: '45.34276',
    longitude: '-111.72733',
    timeZoneId: 'America/Denver',
  },
  '59730': {
    zip: '59730',
    city: 'Gallatin Gateway',
    state: 'MT',
    latitude: '45.438705',
    longitude: '-111.215519',
    timeZoneId: 'America/Denver',
  },
  '59731': {
    zip: '59731',
    city: 'Garrison',
    state: 'MT',
    latitude: '46.521788',
    longitude: '-112.859419',
    timeZoneId: 'America/Denver',
  },
  '59732': {
    zip: '59732',
    city: 'Glen',
    state: 'MT',
    latitude: '45.480685',
    longitude: '-112.692146',
    timeZoneId: 'America/Denver',
  },
  '59733': {
    zip: '59733',
    city: 'Gold Creek',
    state: 'MT',
    latitude: '46.559875',
    longitude: '-112.937996',
    timeZoneId: 'America/Denver',
  },
  '59735': {
    zip: '59735',
    city: 'Harrison',
    state: 'MT',
    latitude: '45.73286',
    longitude: '-111.801147',
    timeZoneId: 'America/Denver',
  },
  '59736': {
    zip: '59736',
    city: 'Jackson',
    state: 'MT',
    latitude: '45.398432',
    longitude: '-113.455323',
    timeZoneId: 'America/Denver',
  },
  '59739': {
    zip: '59739',
    city: 'Lima',
    state: 'MT',
    latitude: '44.643198',
    longitude: '-112.601232',
    timeZoneId: 'America/Denver',
  },
  '59740': {
    zip: '59740',
    city: 'Mc Allister',
    state: 'MT',
    latitude: '45.448495',
    longitude: '-111.784925',
    timeZoneId: 'America/Denver',
  },
  '59741': {
    zip: '59741',
    city: 'Manhattan',
    state: 'MT',
    latitude: '45.76488',
    longitude: '-111.33894',
    timeZoneId: 'America/Denver',
  },
  '59743': {
    zip: '59743',
    city: 'Melrose',
    state: 'MT',
    latitude: '45.63169',
    longitude: '-112.680504',
    timeZoneId: 'America/Denver',
  },
  '59745': {
    zip: '59745',
    city: 'Norris',
    state: 'MT',
    latitude: '45.542794',
    longitude: '-111.721642',
    timeZoneId: 'America/Denver',
  },
  '59746': {
    zip: '59746',
    city: 'Polaris',
    state: 'MT',
    latitude: '45.368244',
    longitude: '-113.118995',
    timeZoneId: 'America/Denver',
  },
  '59747': {
    zip: '59747',
    city: 'Pony',
    state: 'MT',
    latitude: '45.605632',
    longitude: '-111.932296',
    timeZoneId: 'America/Denver',
  },
  '59748': {
    zip: '59748',
    city: 'Ramsay',
    state: 'MT',
    latitude: '46.107165',
    longitude: '-112.642452',
    timeZoneId: 'America/Denver',
  },
  '59749': {
    zip: '59749',
    city: 'Sheridan',
    state: 'MT',
    latitude: '45.43669',
    longitude: '-112.181736',
    timeZoneId: 'America/Denver',
  },
  '59750': {
    zip: '59750',
    city: 'Butte',
    state: 'MT',
    latitude: '45.974422',
    longitude: '-112.808021',
    timeZoneId: 'America/Denver',
  },
  '59751': {
    zip: '59751',
    city: 'Silver Star',
    state: 'MT',
    latitude: '45.695744',
    longitude: '-112.271489',
    timeZoneId: 'America/Denver',
  },
  '59752': {
    zip: '59752',
    city: 'Three Forks',
    state: 'MT',
    latitude: '45.88002',
    longitude: '-111.57624',
    timeZoneId: 'America/Denver',
  },
  '59754': {
    zip: '59754',
    city: 'Twin Bridges',
    state: 'MT',
    latitude: '45.57253',
    longitude: '-112.392846',
    timeZoneId: 'America/Denver',
  },
  '59755': {
    zip: '59755',
    city: 'Virginia City',
    state: 'MT',
    latitude: '45.294302',
    longitude: '-111.94173',
    timeZoneId: 'America/Denver',
  },
  '59756': {
    zip: '59756',
    city: 'Warm Springs',
    state: 'MT',
    latitude: '46.221341',
    longitude: '-112.793192',
    timeZoneId: 'America/Denver',
  },
  '59758': {
    zip: '59758',
    city: 'West Yellowstone',
    state: 'MT',
    latitude: '44.711443',
    longitude: '-111.150995',
    timeZoneId: 'America/Denver',
  },
  '59759': {
    zip: '59759',
    city: 'Whitehall',
    state: 'MT',
    latitude: '45.87016',
    longitude: '-112.140493',
    timeZoneId: 'America/Denver',
  },
  '59760': {
    zip: '59760',
    city: 'Willow Creek',
    state: 'MT',
    latitude: '45.823617',
    longitude: '-111.635853',
    timeZoneId: 'America/Denver',
  },
  '59761': {
    zip: '59761',
    city: 'Wisdom',
    state: 'MT',
    latitude: '45.700642',
    longitude: '-113.604601',
    timeZoneId: 'America/Denver',
  },
  '59762': {
    zip: '59762',
    city: 'Wise River',
    state: 'MT',
    latitude: '45.695538',
    longitude: '-113.082229',
    timeZoneId: 'America/Denver',
  },
  '59771': {
    zip: '59771',
    city: 'Bozeman',
    state: 'MT',
    latitude: '45.680005',
    longitude: '-111.14623',
    timeZoneId: 'America/Denver',
  },
  '59772': {
    zip: '59772',
    city: 'Bozeman',
    state: 'MT',
    latitude: '45.66142',
    longitude: '-111.113994',
    timeZoneId: 'America/Denver',
  },
  '59801': {
    zip: '59801',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.85865',
    longitude: '-114.039769',
    timeZoneId: 'America/Denver',
  },
  '59802': {
    zip: '59802',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.894225',
    longitude: '-114.012626',
    timeZoneId: 'America/Denver',
  },
  '59803': {
    zip: '59803',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.821982',
    longitude: '-114.017179',
    timeZoneId: 'America/Denver',
  },
  '59804': {
    zip: '59804',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.854972',
    longitude: '-114.192637',
    timeZoneId: 'America/Denver',
  },
  '59806': {
    zip: '59806',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.916293',
    longitude: '-114.152007',
    timeZoneId: 'America/Denver',
  },
  '59807': {
    zip: '59807',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.968666',
    longitude: '-113.967458',
    timeZoneId: 'America/Denver',
  },
  '59808': {
    zip: '59808',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.849245',
    longitude: '-114.014432',
    timeZoneId: 'America/Denver',
  },
  '59812': {
    zip: '59812',
    city: 'Missoula',
    state: 'MT',
    latitude: '46.858954',
    longitude: '-113.98647',
    timeZoneId: 'America/Denver',
  },
  '59820': {
    zip: '59820',
    city: 'Alberton',
    state: 'MT',
    latitude: '47.006105',
    longitude: '-114.508962',
    timeZoneId: 'America/Denver',
  },
  '59821': {
    zip: '59821',
    city: 'Arlee',
    state: 'MT',
    latitude: '47.161056',
    longitude: '-114.051408',
    timeZoneId: 'America/Denver',
  },
  '59823': {
    zip: '59823',
    city: 'Bonner',
    state: 'MT',
    latitude: '46.877569',
    longitude: '-113.629257',
    timeZoneId: 'America/Denver',
  },
  '59824': {
    zip: '59824',
    city: 'Charlo',
    state: 'MT',
    latitude: '47.370556',
    longitude: '-114.265',
    timeZoneId: 'America/Denver',
  },
  '59825': {
    zip: '59825',
    city: 'Clinton',
    state: 'MT',
    latitude: '46.76106',
    longitude: '-113.67738',
    timeZoneId: 'America/Denver',
  },
  '59826': {
    zip: '59826',
    city: 'Condon',
    state: 'MT',
    latitude: '47.490393',
    longitude: '-113.715644',
    timeZoneId: 'America/Denver',
  },
  '59827': {
    zip: '59827',
    city: 'Conner',
    state: 'MT',
    latitude: '45.856111',
    longitude: '-114.335036',
    timeZoneId: 'America/Denver',
  },
  '59828': {
    zip: '59828',
    city: 'Corvallis',
    state: 'MT',
    latitude: '46.318428',
    longitude: '-114.086766',
    timeZoneId: 'America/Denver',
  },
  '59829': {
    zip: '59829',
    city: 'Darby',
    state: 'MT',
    latitude: '45.99256',
    longitude: '-114.180694',
    timeZoneId: 'America/Denver',
  },
  '59830': {
    zip: '59830',
    city: 'De Borgia',
    state: 'MT',
    latitude: '47.393745',
    longitude: '-115.346115',
    timeZoneId: 'America/Denver',
  },
  '59831': {
    zip: '59831',
    city: 'Dixon',
    state: 'MT',
    latitude: '47.306648',
    longitude: '-114.369745',
    timeZoneId: 'America/Denver',
  },
  '59832': {
    zip: '59832',
    city: 'Drummond',
    state: 'MT',
    latitude: '46.660732',
    longitude: '-113.170929',
    timeZoneId: 'America/Denver',
  },
  '59833': {
    zip: '59833',
    city: 'Florence',
    state: 'MT',
    latitude: '46.628966',
    longitude: '-114.065961',
    timeZoneId: 'America/Denver',
  },
  '59834': {
    zip: '59834',
    city: 'Frenchtown',
    state: 'MT',
    latitude: '47.047842',
    longitude: '-114.238465',
    timeZoneId: 'America/Denver',
  },
  '59835': {
    zip: '59835',
    city: 'Grantsdale',
    state: 'MT',
    latitude: '46.200933',
    longitude: '-114.142552',
    timeZoneId: 'America/Denver',
  },
  '59837': {
    zip: '59837',
    city: 'Hall',
    state: 'MT',
    latitude: '46.396225',
    longitude: '-113.279865',
    timeZoneId: 'America/Denver',
  },
  '59840': {
    zip: '59840',
    city: 'Hamilton',
    state: 'MT',
    latitude: '46.229862',
    longitude: '-114.164719',
    timeZoneId: 'America/Denver',
  },
  '59841': {
    zip: '59841',
    city: 'Pinesdale',
    state: 'MT',
    latitude: '46.328126',
    longitude: '-114.218919',
    timeZoneId: 'America/Denver',
  },
  '59842': {
    zip: '59842',
    city: 'Haugan',
    state: 'MT',
    latitude: '47.387646',
    longitude: '-115.396885',
    timeZoneId: 'America/Denver',
  },
  '59843': {
    zip: '59843',
    city: 'Helmville',
    state: 'MT',
    latitude: '46.8791',
    longitude: '-112.992491',
    timeZoneId: 'America/Denver',
  },
  '59844': {
    zip: '59844',
    city: 'Heron',
    state: 'MT',
    latitude: '48.045445',
    longitude: '-115.955721',
    timeZoneId: 'America/Denver',
  },
  '59845': {
    zip: '59845',
    city: 'Hot Springs',
    state: 'MT',
    latitude: '47.594102',
    longitude: '-114.676664',
    timeZoneId: 'America/Denver',
  },
  '59846': {
    zip: '59846',
    city: 'Huson',
    state: 'MT',
    latitude: '47.056338',
    longitude: '-114.368199',
    timeZoneId: 'America/Denver',
  },
  '59847': {
    zip: '59847',
    city: 'Lolo',
    state: 'MT',
    latitude: '46.755902',
    longitude: '-114.104293',
    timeZoneId: 'America/Denver',
  },
  '59848': {
    zip: '59848',
    city: 'Lonepine',
    state: 'MT',
    latitude: '47.672451',
    longitude: '-114.665611',
    timeZoneId: 'America/Denver',
  },
  '59851': {
    zip: '59851',
    city: 'Milltown',
    state: 'MT',
    latitude: '46.873703',
    longitude: '-113.87904',
    timeZoneId: 'America/Denver',
  },
  '59853': {
    zip: '59853',
    city: 'Noxon',
    state: 'MT',
    latitude: '48.038456',
    longitude: '-115.821818',
    timeZoneId: 'America/Denver',
  },
  '59854': {
    zip: '59854',
    city: 'Ovando',
    state: 'MT',
    latitude: '47.033331',
    longitude: '-113.032408',
    timeZoneId: 'America/Denver',
  },
  '59855': {
    zip: '59855',
    city: 'Pablo',
    state: 'MT',
    latitude: '47.617619',
    longitude: '-114.12787',
    timeZoneId: 'America/Denver',
  },
  '59856': {
    zip: '59856',
    city: 'Paradise',
    state: 'MT',
    latitude: '47.38976',
    longitude: '-114.798329',
    timeZoneId: 'America/Denver',
  },
  '59858': {
    zip: '59858',
    city: 'Philipsburg',
    state: 'MT',
    latitude: '46.298846',
    longitude: '-113.361385',
    timeZoneId: 'America/Denver',
  },
  '59859': {
    zip: '59859',
    city: 'Plains',
    state: 'MT',
    latitude: '47.48194',
    longitude: '-114.882862',
    timeZoneId: 'America/Denver',
  },
  '59860': {
    zip: '59860',
    city: 'Polson',
    state: 'MT',
    latitude: '47.698356',
    longitude: '-114.140847',
    timeZoneId: 'America/Denver',
  },
  '59863': {
    zip: '59863',
    city: 'Ravalli',
    state: 'MT',
    latitude: '47.276988',
    longitude: '-114.180225',
    timeZoneId: 'America/Denver',
  },
  '59864': {
    zip: '59864',
    city: 'Ronan',
    state: 'MT',
    latitude: '47.541838',
    longitude: '-114.139907',
    timeZoneId: 'America/Denver',
  },
  '59865': {
    zip: '59865',
    city: 'Saint Ignatius',
    state: 'MT',
    latitude: '47.357879',
    longitude: '-114.052628',
    timeZoneId: 'America/Denver',
  },
  '59866': {
    zip: '59866',
    city: 'Saint Regis',
    state: 'MT',
    latitude: '47.298848',
    longitude: '-115.094482',
    timeZoneId: 'America/Denver',
  },
  '59867': {
    zip: '59867',
    city: 'Saltese',
    state: 'MT',
    latitude: '47.409754',
    longitude: '-115.507715',
    timeZoneId: 'America/Denver',
  },
  '59868': {
    zip: '59868',
    city: 'Seeley Lake',
    state: 'MT',
    latitude: '47.224412',
    longitude: '-113.499488',
    timeZoneId: 'America/Denver',
  },
  '59870': {
    zip: '59870',
    city: 'Stevensville',
    state: 'MT',
    latitude: '46.517886',
    longitude: '-114.053727',
    timeZoneId: 'America/Denver',
  },
  '59871': {
    zip: '59871',
    city: 'Sula',
    state: 'MT',
    latitude: '45.787998',
    longitude: '-113.882464',
    timeZoneId: 'America/Denver',
  },
  '59872': {
    zip: '59872',
    city: 'Superior',
    state: 'MT',
    latitude: '47.178911',
    longitude: '-114.885047',
    timeZoneId: 'America/Denver',
  },
  '59873': {
    zip: '59873',
    city: 'Thompson Falls',
    state: 'MT',
    latitude: '47.621869',
    longitude: '-115.34115',
    timeZoneId: 'America/Denver',
  },
  '59874': {
    zip: '59874',
    city: 'Trout Creek',
    state: 'MT',
    latitude: '47.788105',
    longitude: '-115.556441',
    timeZoneId: 'America/Denver',
  },
  '59875': {
    zip: '59875',
    city: 'Victor',
    state: 'MT',
    latitude: '46.402361',
    longitude: '-114.16702',
    timeZoneId: 'America/Denver',
  },
  '59901': {
    zip: '59901',
    city: 'Kalispell',
    state: 'MT',
    latitude: '48.211236',
    longitude: '-114.293904',
    timeZoneId: 'America/Denver',
  },
  '59903': {
    zip: '59903',
    city: 'Kalispell',
    state: 'MT',
    latitude: '48.193033',
    longitude: '-114.357878',
    timeZoneId: 'America/Denver',
  },
  '59904': {
    zip: '59904',
    city: 'Kalispell',
    state: 'MT',
    latitude: '48.194518',
    longitude: '-114.312264',
    timeZoneId: 'America/Denver',
  },
  '59910': {
    zip: '59910',
    city: 'Big Arm',
    state: 'MT',
    latitude: '47.793773',
    longitude: '-114.294753',
    timeZoneId: 'America/Denver',
  },
  '59911': {
    zip: '59911',
    city: 'Bigfork',
    state: 'MT',
    latitude: '47.88186',
    longitude: '-113.96508',
    timeZoneId: 'America/Denver',
  },
  '59912': {
    zip: '59912',
    city: 'Columbia Falls',
    state: 'MT',
    latitude: '48.365309',
    longitude: '-114.192688',
    timeZoneId: 'America/Denver',
  },
  '59913': {
    zip: '59913',
    city: 'Coram',
    state: 'MT',
    latitude: '48.436737',
    longitude: '-114.050697',
    timeZoneId: 'America/Denver',
  },
  '59914': {
    zip: '59914',
    city: 'Dayton',
    state: 'MT',
    latitude: '47.862589',
    longitude: '-114.305026',
    timeZoneId: 'America/Denver',
  },
  '59915': {
    zip: '59915',
    city: 'Elmo',
    state: 'MT',
    latitude: '47.817641',
    longitude: '-114.391045',
    timeZoneId: 'America/Denver',
  },
  '59916': {
    zip: '59916',
    city: 'Essex',
    state: 'MT',
    latitude: '48.617497',
    longitude: '-113.909544',
    timeZoneId: 'America/Denver',
  },
  '59917': {
    zip: '59917',
    city: 'Eureka',
    state: 'MT',
    latitude: '48.87624',
    longitude: '-115.050699',
    timeZoneId: 'America/Denver',
  },
  '59918': {
    zip: '59918',
    city: 'Fortine',
    state: 'MT',
    latitude: '48.788062',
    longitude: '-114.88673',
    timeZoneId: 'America/Denver',
  },
  '59919': {
    zip: '59919',
    city: 'Hungry Horse',
    state: 'MT',
    latitude: '48.389687',
    longitude: '-114.063005',
    timeZoneId: 'America/Denver',
  },
  '59920': {
    zip: '59920',
    city: 'Kila',
    state: 'MT',
    latitude: '48.065157',
    longitude: '-114.502262',
    timeZoneId: 'America/Denver',
  },
  '59921': {
    zip: '59921',
    city: 'Lake Mc Donald',
    state: 'MT',
    latitude: '48.621891',
    longitude: '-113.873901',
    timeZoneId: 'America/Denver',
  },
  '59922': {
    zip: '59922',
    city: 'Lakeside',
    state: 'MT',
    latitude: '48.013825',
    longitude: '-114.222798',
    timeZoneId: 'America/Denver',
  },
  '59923': {
    zip: '59923',
    city: 'Libby',
    state: 'MT',
    latitude: '48.354099',
    longitude: '-115.518696',
    timeZoneId: 'America/Denver',
  },
  '59925': {
    zip: '59925',
    city: 'Marion',
    state: 'MT',
    latitude: '48.110021',
    longitude: '-114.757542',
    timeZoneId: 'America/Denver',
  },
  '59926': {
    zip: '59926',
    city: 'Martin City',
    state: 'MT',
    latitude: '48.391343',
    longitude: '-114.039284',
    timeZoneId: 'America/Denver',
  },
  '59927': {
    zip: '59927',
    city: 'Olney',
    state: 'MT',
    latitude: '48.542035',
    longitude: '-114.572329',
    timeZoneId: 'America/Denver',
  },
  '59928': {
    zip: '59928',
    city: 'Polebridge',
    state: 'MT',
    latitude: '48.901396',
    longitude: '-114.402528',
    timeZoneId: 'America/Denver',
  },
  '59929': {
    zip: '59929',
    city: 'Proctor',
    state: 'MT',
    latitude: '47.926738',
    longitude: '-114.371692',
    timeZoneId: 'America/Denver',
  },
  '59930': {
    zip: '59930',
    city: 'Rexford',
    state: 'MT',
    latitude: '48.917495',
    longitude: '-115.17353',
    timeZoneId: 'America/Denver',
  },
  '59931': {
    zip: '59931',
    city: 'Rollins',
    state: 'MT',
    latitude: '47.923649',
    longitude: '-114.259943',
    timeZoneId: 'America/Denver',
  },
  '59932': {
    zip: '59932',
    city: 'Somers',
    state: 'MT',
    latitude: '48.079312',
    longitude: '-114.238947',
    timeZoneId: 'America/Denver',
  },
  '59933': {
    zip: '59933',
    city: 'Stryker',
    state: 'MT',
    latitude: '48.6739',
    longitude: '-114.769336',
    timeZoneId: 'America/Denver',
  },
  '59934': {
    zip: '59934',
    city: 'Trego',
    state: 'MT',
    latitude: '48.702155',
    longitude: '-114.871301',
    timeZoneId: 'America/Denver',
  },
  '59935': {
    zip: '59935',
    city: 'Troy',
    state: 'MT',
    latitude: '48.462428',
    longitude: '-115.874402',
    timeZoneId: 'America/Denver',
  },
  '59936': {
    zip: '59936',
    city: 'West Glacier',
    state: 'MT',
    latitude: '48.4972',
    longitude: '-113.989197',
    timeZoneId: 'America/Denver',
  },
  '59937': {
    zip: '59937',
    city: 'Whitefish',
    state: 'MT',
    latitude: '48.408343',
    longitude: '-114.352231',
    timeZoneId: 'America/Denver',
  },
  '60002': {
    zip: '60002',
    city: 'Antioch',
    state: 'IL',
    latitude: '42.4685',
    longitude: '-88.109296',
    timeZoneId: 'America/Chicago',
  },
  '60004': {
    zip: '60004',
    city: 'Arlington Heights',
    state: 'IL',
    latitude: '42.108547',
    longitude: '-87.974705',
    timeZoneId: 'America/Chicago',
  },
  '60005': {
    zip: '60005',
    city: 'Arlington Heights',
    state: 'IL',
    latitude: '42.065315',
    longitude: '-87.983562',
    timeZoneId: 'America/Chicago',
  },
  '60006': {
    zip: '60006',
    city: 'Arlington Heights',
    state: 'IL',
    latitude: '42.13061',
    longitude: '-88.092341',
    timeZoneId: 'America/Chicago',
  },
  '60007': {
    zip: '60007',
    city: 'Elk Grove Village',
    state: 'IL',
    latitude: '42.008834',
    longitude: '-88.001385',
    timeZoneId: 'America/Chicago',
  },
  '60008': {
    zip: '60008',
    city: 'Rolling Meadows',
    state: 'IL',
    latitude: '42.07783',
    longitude: '-88.032318',
    timeZoneId: 'America/Chicago',
  },
  '60009': {
    zip: '60009',
    city: 'Elk Grove Village',
    state: 'IL',
    latitude: '42.008382',
    longitude: '-87.991526',
    timeZoneId: 'America/Chicago',
  },
  '60010': {
    zip: '60010',
    city: 'Barrington',
    state: 'IL',
    latitude: '42.194268',
    longitude: '-88.135521',
    timeZoneId: 'America/Chicago',
  },
  '60011': {
    zip: '60011',
    city: 'Barrington',
    state: 'IL',
    latitude: '42.186028',
    longitude: '-88.220256',
    timeZoneId: 'America/Chicago',
  },
  '60012': {
    zip: '60012',
    city: 'Crystal Lake',
    state: 'IL',
    latitude: '42.27',
    longitude: '-88.30422',
    timeZoneId: 'America/Chicago',
  },
  '60013': {
    zip: '60013',
    city: 'Cary',
    state: 'IL',
    latitude: '42.219192',
    longitude: '-88.239708',
    timeZoneId: 'America/Chicago',
  },
  '60014': {
    zip: '60014',
    city: 'Crystal Lake',
    state: 'IL',
    latitude: '42.23086',
    longitude: '-88.326944',
    timeZoneId: 'America/Chicago',
  },
  '60015': {
    zip: '60015',
    city: 'Deerfield',
    state: 'IL',
    latitude: '42.172896',
    longitude: '-87.878422',
    timeZoneId: 'America/Chicago',
  },
  '60016': {
    zip: '60016',
    city: 'Des Plaines',
    state: 'IL',
    latitude: '42.047152',
    longitude: '-87.891674',
    timeZoneId: 'America/Chicago',
  },
  '60017': {
    zip: '60017',
    city: 'Des Plaines',
    state: 'IL',
    latitude: '42.024452',
    longitude: '-87.902856',
    timeZoneId: 'America/Chicago',
  },
  '60018': {
    zip: '60018',
    city: 'Des Plaines',
    state: 'IL',
    latitude: '42.0027',
    longitude: '-87.90084',
    timeZoneId: 'America/Chicago',
  },
  '60019': {
    zip: '60019',
    city: 'Des Plaines',
    state: 'IL',
    latitude: '42.024981',
    longitude: '-87.899607',
    timeZoneId: 'America/Chicago',
  },
  '60020': {
    zip: '60020',
    city: 'Fox Lake',
    state: 'IL',
    latitude: '42.394881',
    longitude: '-88.176816',
    timeZoneId: 'America/Chicago',
  },
  '60021': {
    zip: '60021',
    city: 'Fox River Grove',
    state: 'IL',
    latitude: '42.192301',
    longitude: '-88.222307',
    timeZoneId: 'America/Chicago',
  },
  '60022': {
    zip: '60022',
    city: 'Glencoe',
    state: 'IL',
    latitude: '42.134264',
    longitude: '-87.764318',
    timeZoneId: 'America/Chicago',
  },
  '60025': {
    zip: '60025',
    city: 'Glenview',
    state: 'IL',
    latitude: '42.078155',
    longitude: '-87.817359',
    timeZoneId: 'America/Chicago',
  },
  '60026': {
    zip: '60026',
    city: 'Glenview',
    state: 'IL',
    latitude: '42.086421',
    longitude: '-87.826831',
    timeZoneId: 'America/Chicago',
  },
  '60029': {
    zip: '60029',
    city: 'Golf',
    state: 'IL',
    latitude: '42.058706',
    longitude: '-87.790933',
    timeZoneId: 'America/Chicago',
  },
  '60030': {
    zip: '60030',
    city: 'Grayslake',
    state: 'IL',
    latitude: '42.341153',
    longitude: '-88.024884',
    timeZoneId: 'America/Chicago',
  },
  '60031': {
    zip: '60031',
    city: 'Gurnee',
    state: 'IL',
    latitude: '42.372254',
    longitude: '-87.932034',
    timeZoneId: 'America/Chicago',
  },
  '60033': {
    zip: '60033',
    city: 'Harvard',
    state: 'IL',
    latitude: '42.422653',
    longitude: '-88.603857',
    timeZoneId: 'America/Chicago',
  },
  '60034': {
    zip: '60034',
    city: 'Hebron',
    state: 'IL',
    latitude: '42.458103',
    longitude: '-88.417579',
    timeZoneId: 'America/Chicago',
  },
  '60035': {
    zip: '60035',
    city: 'Highland Park',
    state: 'IL',
    latitude: '42.182657',
    longitude: '-87.805479',
    timeZoneId: 'America/Chicago',
  },
  '60037': {
    zip: '60037',
    city: 'Fort Sheridan',
    state: 'IL',
    latitude: '42.209412',
    longitude: '-87.806565',
    timeZoneId: 'America/Chicago',
  },
  '60038': {
    zip: '60038',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.096596',
    longitude: '-88.011307',
    timeZoneId: 'America/Chicago',
  },
  '60039': {
    zip: '60039',
    city: 'Crystal Lake',
    state: 'IL',
    latitude: '42.228371',
    longitude: '-88.331231',
    timeZoneId: 'America/Chicago',
  },
  '60040': {
    zip: '60040',
    city: 'Highwood',
    state: 'IL',
    latitude: '42.203499',
    longitude: '-87.813543',
    timeZoneId: 'America/Chicago',
  },
  '60041': {
    zip: '60041',
    city: 'Ingleside',
    state: 'IL',
    latitude: '42.357735',
    longitude: '-88.163789',
    timeZoneId: 'America/Chicago',
  },
  '60042': {
    zip: '60042',
    city: 'Island Lake',
    state: 'IL',
    latitude: '42.276449',
    longitude: '-88.211479',
    timeZoneId: 'America/Chicago',
  },
  '60043': {
    zip: '60043',
    city: 'Kenilworth',
    state: 'IL',
    latitude: '42.08873',
    longitude: '-87.714867',
    timeZoneId: 'America/Chicago',
  },
  '60044': {
    zip: '60044',
    city: 'Lake Bluff',
    state: 'IL',
    latitude: '42.286331',
    longitude: '-87.857964',
    timeZoneId: 'America/Chicago',
  },
  '60045': {
    zip: '60045',
    city: 'Lake Forest',
    state: 'IL',
    latitude: '42.233956',
    longitude: '-87.863856',
    timeZoneId: 'America/Chicago',
  },
  '60046': {
    zip: '60046',
    city: 'Lake Villa',
    state: 'IL',
    latitude: '42.410921',
    longitude: '-88.063011',
    timeZoneId: 'America/Chicago',
  },
  '60047': {
    zip: '60047',
    city: 'Lake Zurich',
    state: 'IL',
    latitude: '42.195624',
    longitude: '-88.058166',
    timeZoneId: 'America/Chicago',
  },
  '60048': {
    zip: '60048',
    city: 'Libertyville',
    state: 'IL',
    latitude: '42.293735',
    longitude: '-87.944893',
    timeZoneId: 'America/Chicago',
  },
  '60050': {
    zip: '60050',
    city: 'McHenry',
    state: 'IL',
    latitude: '42.347392',
    longitude: '-88.259759',
    timeZoneId: 'America/Chicago',
  },
  '60051': {
    zip: '60051',
    city: 'McHenry',
    state: 'IL',
    latitude: '42.342172',
    longitude: '-88.289042',
    timeZoneId: 'America/Chicago',
  },
  '60053': {
    zip: '60053',
    city: 'Morton Grove',
    state: 'IL',
    latitude: '42.044087',
    longitude: '-87.791267',
    timeZoneId: 'America/Chicago',
  },
  '60055': {
    zip: '60055',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.125215',
    longitude: '-88.075032',
    timeZoneId: 'America/Chicago',
  },
  '60056': {
    zip: '60056',
    city: 'Mount Prospect',
    state: 'IL',
    latitude: '42.068179',
    longitude: '-87.932697',
    timeZoneId: 'America/Chicago',
  },
  '60060': {
    zip: '60060',
    city: 'Mundelein',
    state: 'IL',
    latitude: '42.26892',
    longitude: '-88.06098',
    timeZoneId: 'America/Chicago',
  },
  '60061': {
    zip: '60061',
    city: 'Vernon Hills',
    state: 'IL',
    latitude: '42.234248',
    longitude: '-87.970272',
    timeZoneId: 'America/Chicago',
  },
  '60062': {
    zip: '60062',
    city: 'Northbrook',
    state: 'IL',
    latitude: '42.126945',
    longitude: '-87.842535',
    timeZoneId: 'America/Chicago',
  },
  '60064': {
    zip: '60064',
    city: 'North Chicago',
    state: 'IL',
    latitude: '42.326825',
    longitude: '-87.857674',
    timeZoneId: 'America/Chicago',
  },
  '60065': {
    zip: '60065',
    city: 'Northbrook',
    state: 'IL',
    latitude: '42.216196',
    longitude: '-87.883916',
    timeZoneId: 'America/Chicago',
  },
  '60067': {
    zip: '60067',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.112431',
    longitude: '-88.051211',
    timeZoneId: 'America/Chicago',
  },
  '60068': {
    zip: '60068',
    city: 'Park Ridge',
    state: 'IL',
    latitude: '42.013931',
    longitude: '-87.873326',
    timeZoneId: 'America/Chicago',
  },
  '60069': {
    zip: '60069',
    city: 'Lincolnshire',
    state: 'IL',
    latitude: '42.19446',
    longitude: '-87.91074',
    timeZoneId: 'America/Chicago',
  },
  '60070': {
    zip: '60070',
    city: 'Prospect Heights',
    state: 'IL',
    latitude: '42.104997',
    longitude: '-87.93617',
    timeZoneId: 'America/Chicago',
  },
  '60071': {
    zip: '60071',
    city: 'Richmond',
    state: 'IL',
    latitude: '42.466736',
    longitude: '-88.288525',
    timeZoneId: 'America/Chicago',
  },
  '60072': {
    zip: '60072',
    city: 'Ringwood',
    state: 'IL',
    latitude: '42.408534',
    longitude: '-88.298655',
    timeZoneId: 'America/Chicago',
  },
  '60073': {
    zip: '60073',
    city: 'Round Lake',
    state: 'IL',
    latitude: '42.35178',
    longitude: '-88.09248',
    timeZoneId: 'America/Chicago',
  },
  '60074': {
    zip: '60074',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.14569',
    longitude: '-88.027592',
    timeZoneId: 'America/Chicago',
  },
  '60075': {
    zip: '60075',
    city: 'Russell',
    state: 'IL',
    latitude: '42.491134',
    longitude: '-87.92518',
    timeZoneId: 'America/Chicago',
  },
  '60076': {
    zip: '60076',
    city: 'Skokie',
    state: 'IL',
    latitude: '42.033599',
    longitude: '-87.729142',
    timeZoneId: 'America/Chicago',
  },
  '60077': {
    zip: '60077',
    city: 'Skokie',
    state: 'IL',
    latitude: '42.034146',
    longitude: '-87.756196',
    timeZoneId: 'America/Chicago',
  },
  '60078': {
    zip: '60078',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.141956',
    longitude: '-87.922457',
    timeZoneId: 'America/Chicago',
  },
  '60079': {
    zip: '60079',
    city: 'Waukegan',
    state: 'IL',
    latitude: '42.37022',
    longitude: '-87.868544',
    timeZoneId: 'America/Chicago',
  },
  '60081': {
    zip: '60081',
    city: 'Spring Grove',
    state: 'IL',
    latitude: '42.437393',
    longitude: '-88.225911',
    timeZoneId: 'America/Chicago',
  },
  '60082': {
    zip: '60082',
    city: 'Techny',
    state: 'IL',
    latitude: '42.108147',
    longitude: '-87.807496',
    timeZoneId: 'America/Chicago',
  },
  '60083': {
    zip: '60083',
    city: 'Wadsworth',
    state: 'IL',
    latitude: '42.429918',
    longitude: '-87.932049',
    timeZoneId: 'America/Chicago',
  },
  '60084': {
    zip: '60084',
    city: 'Wauconda',
    state: 'IL',
    latitude: '42.262122',
    longitude: '-88.139439',
    timeZoneId: 'America/Chicago',
  },
  '60085': {
    zip: '60085',
    city: 'Waukegan',
    state: 'IL',
    latitude: '42.360907',
    longitude: '-87.862378',
    timeZoneId: 'America/Chicago',
  },
  '60086': {
    zip: '60086',
    city: 'North Chicago',
    state: 'IL',
    latitude: '42.32027',
    longitude: '-87.859647',
    timeZoneId: 'America/Chicago',
  },
  '60087': {
    zip: '60087',
    city: 'Waukegan',
    state: 'IL',
    latitude: '42.405095',
    longitude: '-87.859493',
    timeZoneId: 'America/Chicago',
  },
  '60088': {
    zip: '60088',
    city: 'Great Lakes',
    state: 'IL',
    latitude: '42.308646',
    longitude: '-87.854501',
    timeZoneId: 'America/Chicago',
  },
  '60089': {
    zip: '60089',
    city: 'Buffalo Grove',
    state: 'IL',
    latitude: '42.174011',
    longitude: '-87.964322',
    timeZoneId: 'America/Chicago',
  },
  '60090': {
    zip: '60090',
    city: 'Wheeling',
    state: 'IL',
    latitude: '42.133221',
    longitude: '-87.934315',
    timeZoneId: 'America/Chicago',
  },
  '60091': {
    zip: '60091',
    city: 'Wilmette',
    state: 'IL',
    latitude: '42.076251',
    longitude: '-87.72842',
    timeZoneId: 'America/Chicago',
  },
  '60093': {
    zip: '60093',
    city: 'Winnetka',
    state: 'IL',
    latitude: '42.10105',
    longitude: '-87.757641',
    timeZoneId: 'America/Chicago',
  },
  '60094': {
    zip: '60094',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.099153',
    longitude: '-88.097509',
    timeZoneId: 'America/Chicago',
  },
  '60095': {
    zip: '60095',
    city: 'Palatine',
    state: 'IL',
    latitude: '42.098083',
    longitude: '-88.010648',
    timeZoneId: 'America/Chicago',
  },
  '60096': {
    zip: '60096',
    city: 'Winthrop Harbor',
    state: 'IL',
    latitude: '42.478195',
    longitude: '-87.831505',
    timeZoneId: 'America/Chicago',
  },
  '60097': {
    zip: '60097',
    city: 'Wonder Lake',
    state: 'IL',
    latitude: '42.394351',
    longitude: '-88.354139',
    timeZoneId: 'America/Chicago',
  },
  '60098': {
    zip: '60098',
    city: 'Woodstock',
    state: 'IL',
    latitude: '42.320586',
    longitude: '-88.447623',
    timeZoneId: 'America/Chicago',
  },
  '60099': {
    zip: '60099',
    city: 'Zion',
    state: 'IL',
    latitude: '42.450382',
    longitude: '-87.859034',
    timeZoneId: 'America/Chicago',
  },
  '60101': {
    zip: '60101',
    city: 'Addison',
    state: 'IL',
    latitude: '41.93266',
    longitude: '-88.002222',
    timeZoneId: 'America/Chicago',
  },
  '60102': {
    zip: '60102',
    city: 'Algonquin',
    state: 'IL',
    latitude: '42.17082',
    longitude: '-88.31874',
    timeZoneId: 'America/Chicago',
  },
  '60103': {
    zip: '60103',
    city: 'Bartlett',
    state: 'IL',
    latitude: '41.966534',
    longitude: '-88.165433',
    timeZoneId: 'America/Chicago',
  },
  '60104': {
    zip: '60104',
    city: 'Bellwood',
    state: 'IL',
    latitude: '41.882079',
    longitude: '-87.878103',
    timeZoneId: 'America/Chicago',
  },
  '60105': {
    zip: '60105',
    city: 'Bensenville',
    state: 'IL',
    latitude: '41.956863',
    longitude: '-87.941819',
    timeZoneId: 'America/Chicago',
  },
  '60106': {
    zip: '60106',
    city: 'Bensenville',
    state: 'IL',
    latitude: '41.955274',
    longitude: '-87.948789',
    timeZoneId: 'America/Chicago',
  },
  '60107': {
    zip: '60107',
    city: 'Streamwood',
    state: 'IL',
    latitude: '42.022506',
    longitude: '-88.168156',
    timeZoneId: 'America/Chicago',
  },
  '60108': {
    zip: '60108',
    city: 'Bloomingdale',
    state: 'IL',
    latitude: '41.950862',
    longitude: '-88.080804',
    timeZoneId: 'America/Chicago',
  },
  '60109': {
    zip: '60109',
    city: 'Burlington',
    state: 'IL',
    latitude: '42.063706',
    longitude: '-88.514428',
    timeZoneId: 'America/Chicago',
  },
  '60110': {
    zip: '60110',
    city: 'Carpentersville',
    state: 'IL',
    latitude: '42.125226',
    longitude: '-88.263423',
    timeZoneId: 'America/Chicago',
  },
  '60111': {
    zip: '60111',
    city: 'Clare',
    state: 'IL',
    latitude: '42.026711',
    longitude: '-88.843818',
    timeZoneId: 'America/Chicago',
  },
  '60112': {
    zip: '60112',
    city: 'Cortland',
    state: 'IL',
    latitude: '41.921249',
    longitude: '-88.691137',
    timeZoneId: 'America/Chicago',
  },
  '60113': {
    zip: '60113',
    city: 'Creston',
    state: 'IL',
    latitude: '41.928976',
    longitude: '-88.966367',
    timeZoneId: 'America/Chicago',
  },
  '60115': {
    zip: '60115',
    city: 'Dekalb',
    state: 'IL',
    latitude: '41.929327',
    longitude: '-88.750764',
    timeZoneId: 'America/Chicago',
  },
  '60116': {
    zip: '60116',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '41.913702',
    longitude: '-88.133828',
    timeZoneId: 'America/Chicago',
  },
  '60117': {
    zip: '60117',
    city: 'Bloomingdale',
    state: 'IL',
    latitude: '41.94579',
    longitude: '-88.084824',
    timeZoneId: 'America/Chicago',
  },
  '60118': {
    zip: '60118',
    city: 'Dundee',
    state: 'IL',
    latitude: '42.11028',
    longitude: '-88.29654',
    timeZoneId: 'America/Chicago',
  },
  '60119': {
    zip: '60119',
    city: 'Elburn',
    state: 'IL',
    latitude: '41.881407',
    longitude: '-88.488714',
    timeZoneId: 'America/Chicago',
  },
  '60120': {
    zip: '60120',
    city: 'Elgin',
    state: 'IL',
    latitude: '42.039135',
    longitude: '-88.281994',
    timeZoneId: 'America/Chicago',
  },
  '60121': {
    zip: '60121',
    city: 'Elgin',
    state: 'IL',
    latitude: '42.037863',
    longitude: '-88.279642',
    timeZoneId: 'America/Chicago',
  },
  '60122': {
    zip: '60122',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '42.036725',
    longitude: '-88.288337',
    timeZoneId: 'America/Chicago',
  },
  '60123': {
    zip: '60123',
    city: 'Elgin',
    state: 'IL',
    latitude: '42.040703',
    longitude: '-88.311045',
    timeZoneId: 'America/Chicago',
  },
  '60124': {
    zip: '60124',
    city: 'Elgin',
    state: 'IL',
    latitude: '42.040262',
    longitude: '-88.310402',
    timeZoneId: 'America/Chicago',
  },
  '60126': {
    zip: '60126',
    city: 'Elmhurst',
    state: 'IL',
    latitude: '41.893914',
    longitude: '-87.94058',
    timeZoneId: 'America/Chicago',
  },
  '60128': {
    zip: '60128',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '41.916845',
    longitude: '-88.105772',
    timeZoneId: 'America/Chicago',
  },
  '60129': {
    zip: '60129',
    city: 'Esmond',
    state: 'IL',
    latitude: '42.025271',
    longitude: '-88.934243',
    timeZoneId: 'America/Chicago',
  },
  '60130': {
    zip: '60130',
    city: 'Forest Park',
    state: 'IL',
    latitude: '41.871581',
    longitude: '-87.815134',
    timeZoneId: 'America/Chicago',
  },
  '60131': {
    zip: '60131',
    city: 'Franklin Park',
    state: 'IL',
    latitude: '41.936119',
    longitude: '-87.875866',
    timeZoneId: 'America/Chicago',
  },
  '60132': {
    zip: '60132',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '41.919511',
    longitude: '-88.100953',
    timeZoneId: 'America/Chicago',
  },
  '60133': {
    zip: '60133',
    city: 'Hanover Park',
    state: 'IL',
    latitude: '41.966626',
    longitude: '-88.161383',
    timeZoneId: 'America/Chicago',
  },
  '60134': {
    zip: '60134',
    city: 'Geneva',
    state: 'IL',
    latitude: '41.887291',
    longitude: '-88.309915',
    timeZoneId: 'America/Chicago',
  },
  '60135': {
    zip: '60135',
    city: 'Genoa',
    state: 'IL',
    latitude: '42.105564',
    longitude: '-88.669624',
    timeZoneId: 'America/Chicago',
  },
  '60136': {
    zip: '60136',
    city: 'Gilberts',
    state: 'IL',
    latitude: '42.090606',
    longitude: '-88.361975',
    timeZoneId: 'America/Chicago',
  },
  '60137': {
    zip: '60137',
    city: 'Glen Ellyn',
    state: 'IL',
    latitude: '41.871806',
    longitude: '-88.065073',
    timeZoneId: 'America/Chicago',
  },
  '60138': {
    zip: '60138',
    city: 'Glen Ellyn',
    state: 'IL',
    latitude: '41.879817',
    longitude: '-88.064336',
    timeZoneId: 'America/Chicago',
  },
  '60139': {
    zip: '60139',
    city: 'Glendale Heights',
    state: 'IL',
    latitude: '41.918876',
    longitude: '-88.076541',
    timeZoneId: 'America/Chicago',
  },
  '60140': {
    zip: '60140',
    city: 'Hampshire',
    state: 'IL',
    latitude: '42.11652',
    longitude: '-88.50222',
    timeZoneId: 'America/Chicago',
  },
  '60141': {
    zip: '60141',
    city: 'Hines',
    state: 'IL',
    latitude: '41.854028',
    longitude: '-87.833373',
    timeZoneId: 'America/Chicago',
  },
  '60142': {
    zip: '60142',
    city: 'Huntley',
    state: 'IL',
    latitude: '42.187342',
    longitude: '-88.434247',
    timeZoneId: 'America/Chicago',
  },
  '60143': {
    zip: '60143',
    city: 'Itasca',
    state: 'IL',
    latitude: '41.973492',
    longitude: '-88.015802',
    timeZoneId: 'America/Chicago',
  },
  '60144': {
    zip: '60144',
    city: 'Kaneville',
    state: 'IL',
    latitude: '41.8322',
    longitude: '-88.517235',
    timeZoneId: 'America/Chicago',
  },
  '60145': {
    zip: '60145',
    city: 'Kingston',
    state: 'IL',
    latitude: '42.11142',
    longitude: '-88.80036',
    timeZoneId: 'America/Chicago',
  },
  '60146': {
    zip: '60146',
    city: 'Kirkland',
    state: 'IL',
    latitude: '42.11964',
    longitude: '-88.89102',
    timeZoneId: 'America/Chicago',
  },
  '60147': {
    zip: '60147',
    city: 'Lafox',
    state: 'IL',
    latitude: '41.884161',
    longitude: '-88.432446',
    timeZoneId: 'America/Chicago',
  },
  '60148': {
    zip: '60148',
    city: 'Lombard',
    state: 'IL',
    latitude: '41.869897',
    longitude: '-88.011733',
    timeZoneId: 'America/Chicago',
  },
  '60150': {
    zip: '60150',
    city: 'Malta',
    state: 'IL',
    latitude: '41.926212',
    longitude: '-88.857968',
    timeZoneId: 'America/Chicago',
  },
  '60151': {
    zip: '60151',
    city: 'Maple Park',
    state: 'IL',
    latitude: '41.903604',
    longitude: '-88.594699',
    timeZoneId: 'America/Chicago',
  },
  '60152': {
    zip: '60152',
    city: 'Marengo',
    state: 'IL',
    latitude: '42.233492',
    longitude: '-88.630366',
    timeZoneId: 'America/Chicago',
  },
  '60153': {
    zip: '60153',
    city: 'Maywood',
    state: 'IL',
    latitude: '41.87208',
    longitude: '-87.84732',
    timeZoneId: 'America/Chicago',
  },
  '60154': {
    zip: '60154',
    city: 'Westchester',
    state: 'IL',
    latitude: '41.853882',
    longitude: '-87.882073',
    timeZoneId: 'America/Chicago',
  },
  '60155': {
    zip: '60155',
    city: 'Broadview',
    state: 'IL',
    latitude: '41.853946',
    longitude: '-87.855949',
    timeZoneId: 'America/Chicago',
  },
  '60156': {
    zip: '60156',
    city: 'Lake in the Hills',
    state: 'IL',
    latitude: '42.180245',
    longitude: '-88.33147',
    timeZoneId: 'America/Chicago',
  },
  '60157': {
    zip: '60157',
    city: 'Medinah',
    state: 'IL',
    latitude: '41.971617',
    longitude: '-88.048633',
    timeZoneId: 'America/Chicago',
  },
  '60159': {
    zip: '60159',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.036623',
    longitude: '-88.083304',
    timeZoneId: 'America/Chicago',
  },
  '60160': {
    zip: '60160',
    city: 'Melrose Park',
    state: 'IL',
    latitude: '41.901365',
    longitude: '-87.860604',
    timeZoneId: 'America/Chicago',
  },
  '60161': {
    zip: '60161',
    city: 'Melrose Park',
    state: 'IL',
    latitude: '41.904029',
    longitude: '-87.861129',
    timeZoneId: 'America/Chicago',
  },
  '60162': {
    zip: '60162',
    city: 'Hillside',
    state: 'IL',
    latitude: '41.874464',
    longitude: '-87.898242',
    timeZoneId: 'America/Chicago',
  },
  '60163': {
    zip: '60163',
    city: 'Berkeley',
    state: 'IL',
    latitude: '41.88402',
    longitude: '-87.908634',
    timeZoneId: 'America/Chicago',
  },
  '60164': {
    zip: '60164',
    city: 'Melrose Park',
    state: 'IL',
    latitude: '41.924177',
    longitude: '-87.889271',
    timeZoneId: 'America/Chicago',
  },
  '60165': {
    zip: '60165',
    city: 'Stone Park',
    state: 'IL',
    latitude: '41.900556',
    longitude: '-87.856667',
    timeZoneId: 'America/Chicago',
  },
  '60168': {
    zip: '60168',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.045434',
    longitude: '-88.126629',
    timeZoneId: 'America/Chicago',
  },
  '60169': {
    zip: '60169',
    city: 'Hoffman Estates',
    state: 'IL',
    latitude: '42.045153',
    longitude: '-88.125805',
    timeZoneId: 'America/Chicago',
  },
  '60171': {
    zip: '60171',
    city: 'River Grove',
    state: 'IL',
    latitude: '41.924233',
    longitude: '-87.8411',
    timeZoneId: 'America/Chicago',
  },
  '60172': {
    zip: '60172',
    city: 'Roselle',
    state: 'IL',
    latitude: '41.97756',
    longitude: '-88.07364',
    timeZoneId: 'America/Chicago',
  },
  '60173': {
    zip: '60173',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.042778',
    longitude: '-88.079722',
    timeZoneId: 'America/Chicago',
  },
  '60174': {
    zip: '60174',
    city: 'Saint Charles',
    state: 'IL',
    latitude: '41.922012',
    longitude: '-88.304234',
    timeZoneId: 'America/Chicago',
  },
  '60175': {
    zip: '60175',
    city: 'Saint Charles',
    state: 'IL',
    latitude: '41.952674',
    longitude: '-88.346033',
    timeZoneId: 'America/Chicago',
  },
  '60176': {
    zip: '60176',
    city: 'Schiller Park',
    state: 'IL',
    latitude: '41.957359',
    longitude: '-87.86752',
    timeZoneId: 'America/Chicago',
  },
  '60177': {
    zip: '60177',
    city: 'South Elgin',
    state: 'IL',
    latitude: '41.993436',
    longitude: '-88.295863',
    timeZoneId: 'America/Chicago',
  },
  '60178': {
    zip: '60178',
    city: 'Sycamore',
    state: 'IL',
    latitude: '42.003043',
    longitude: '-88.681897',
    timeZoneId: 'America/Chicago',
  },
  '60179': {
    zip: '60179',
    city: 'Hoffman Estates',
    state: 'IL',
    latitude: '42.079712',
    longitude: '-88.224255',
    timeZoneId: 'America/Chicago',
  },
  '60180': {
    zip: '60180',
    city: 'Union',
    state: 'IL',
    latitude: '42.223418',
    longitude: '-88.525155',
    timeZoneId: 'America/Chicago',
  },
  '60181': {
    zip: '60181',
    city: 'Villa Park',
    state: 'IL',
    latitude: '41.882953',
    longitude: '-87.975404',
    timeZoneId: 'America/Chicago',
  },
  '60183': {
    zip: '60183',
    city: 'Wasco',
    state: 'IL',
    latitude: '41.939492',
    longitude: '-88.401527',
    timeZoneId: 'America/Chicago',
  },
  '60184': {
    zip: '60184',
    city: 'Wayne',
    state: 'IL',
    latitude: '41.948067',
    longitude: '-88.230588',
    timeZoneId: 'America/Chicago',
  },
  '60185': {
    zip: '60185',
    city: 'West Chicago',
    state: 'IL',
    latitude: '41.887855',
    longitude: '-88.198103',
    timeZoneId: 'America/Chicago',
  },
  '60186': {
    zip: '60186',
    city: 'West Chicago',
    state: 'IL',
    latitude: '41.884907',
    longitude: '-88.206513',
    timeZoneId: 'America/Chicago',
  },
  '60187': {
    zip: '60187',
    city: 'Wheaton',
    state: 'IL',
    latitude: '41.857559',
    longitude: '-88.112465',
    timeZoneId: 'America/Chicago',
  },
  '60188': {
    zip: '60188',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '41.920273',
    longitude: '-88.143298',
    timeZoneId: 'America/Chicago',
  },
  '60189': {
    zip: '60189',
    city: 'Wheaton',
    state: 'IL',
    latitude: '41.836781',
    longitude: '-88.128893',
    timeZoneId: 'America/Chicago',
  },
  '60190': {
    zip: '60190',
    city: 'Winfield',
    state: 'IL',
    latitude: '41.86971',
    longitude: '-88.150265',
    timeZoneId: 'America/Chicago',
  },
  '60191': {
    zip: '60191',
    city: 'Wood Dale',
    state: 'IL',
    latitude: '41.959472',
    longitude: '-87.980528',
    timeZoneId: 'America/Chicago',
  },
  '60192': {
    zip: '60192',
    city: 'Hoffman Estates',
    state: 'IL',
    latitude: '42.073812',
    longitude: '-88.106821',
    timeZoneId: 'America/Chicago',
  },
  '60193': {
    zip: '60193',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.012042',
    longitude: '-88.090697',
    timeZoneId: 'America/Chicago',
  },
  '60194': {
    zip: '60194',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.044152',
    longitude: '-88.110153',
    timeZoneId: 'America/Chicago',
  },
  '60195': {
    zip: '60195',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.07632',
    longitude: '-88.11012',
    timeZoneId: 'America/Chicago',
  },
  '60196': {
    zip: '60196',
    city: 'Schaumburg',
    state: 'IL',
    latitude: '42.071476',
    longitude: '-88.049239',
    timeZoneId: 'America/Chicago',
  },
  '60197': {
    zip: '60197',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '41.914461',
    longitude: '-88.138313',
    timeZoneId: 'America/Chicago',
  },
  '60199': {
    zip: '60199',
    city: 'Carol Stream',
    state: 'IL',
    latitude: '41.916124',
    longitude: '-88.103082',
    timeZoneId: 'America/Chicago',
  },
  '60201': {
    zip: '60201',
    city: 'Evanston',
    state: 'IL',
    latitude: '42.057696',
    longitude: '-87.696561',
    timeZoneId: 'America/Chicago',
  },
  '60202': {
    zip: '60202',
    city: 'Evanston',
    state: 'IL',
    latitude: '42.030528',
    longitude: '-87.691792',
    timeZoneId: 'America/Chicago',
  },
  '60203': {
    zip: '60203',
    city: 'Evanston',
    state: 'IL',
    latitude: '42.051639',
    longitude: '-87.717787',
    timeZoneId: 'America/Chicago',
  },
  '60204': {
    zip: '60204',
    city: 'Evanston',
    state: 'IL',
    latitude: '42.057783',
    longitude: '-87.695374',
    timeZoneId: 'America/Chicago',
  },
  '60208': {
    zip: '60208',
    city: 'Evanston',
    state: 'IL',
    latitude: '42.05801',
    longitude: '-87.674825',
    timeZoneId: 'America/Chicago',
  },
  '60301': {
    zip: '60301',
    city: 'Oak Park',
    state: 'IL',
    latitude: '41.888041',
    longitude: '-87.797275',
    timeZoneId: 'America/Chicago',
  },
  '60302': {
    zip: '60302',
    city: 'Oak Park',
    state: 'IL',
    latitude: '41.897775',
    longitude: '-87.788982',
    timeZoneId: 'America/Chicago',
  },
  '60303': {
    zip: '60303',
    city: 'Oak Park',
    state: 'IL',
    latitude: '41.889253',
    longitude: '-87.790848',
    timeZoneId: 'America/Chicago',
  },
  '60304': {
    zip: '60304',
    city: 'Oak Park',
    state: 'IL',
    latitude: '41.874171',
    longitude: '-87.789179',
    timeZoneId: 'America/Chicago',
  },
  '60305': {
    zip: '60305',
    city: 'River Forest',
    state: 'IL',
    latitude: '41.894703',
    longitude: '-87.818109',
    timeZoneId: 'America/Chicago',
  },
  '60399': {
    zip: '60399',
    city: 'Wood Dale',
    state: 'IL',
    latitude: '41.95888',
    longitude: '-87.941435',
    timeZoneId: 'America/Chicago',
  },
  '60401': {
    zip: '60401',
    city: 'Beecher',
    state: 'IL',
    latitude: '41.355724',
    longitude: '-87.607332',
    timeZoneId: 'America/Chicago',
  },
  '60402': {
    zip: '60402',
    city: 'Berwyn',
    state: 'IL',
    latitude: '41.838828',
    longitude: '-87.791023',
    timeZoneId: 'America/Chicago',
  },
  '60403': {
    zip: '60403',
    city: 'Crest Hill',
    state: 'IL',
    latitude: '41.558561',
    longitude: '-88.168833',
    timeZoneId: 'America/Chicago',
  },
  '60404': {
    zip: '60404',
    city: 'Shorewood',
    state: 'IL',
    latitude: '41.514639',
    longitude: '-88.198385',
    timeZoneId: 'America/Chicago',
  },
  '60406': {
    zip: '60406',
    city: 'Blue Island',
    state: 'IL',
    latitude: '41.654838',
    longitude: '-87.678683',
    timeZoneId: 'America/Chicago',
  },
  '60407': {
    zip: '60407',
    city: 'Braceville',
    state: 'IL',
    latitude: '41.24064',
    longitude: '-88.26012',
    timeZoneId: 'America/Chicago',
  },
  '60408': {
    zip: '60408',
    city: 'Braidwood',
    state: 'IL',
    latitude: '41.265297',
    longitude: '-88.216117',
    timeZoneId: 'America/Chicago',
  },
  '60409': {
    zip: '60409',
    city: 'Calumet City',
    state: 'IL',
    latitude: '41.614779',
    longitude: '-87.551336',
    timeZoneId: 'America/Chicago',
  },
  '60410': {
    zip: '60410',
    city: 'Channahon',
    state: 'IL',
    latitude: '41.436384',
    longitude: '-88.187708',
    timeZoneId: 'America/Chicago',
  },
  '60411': {
    zip: '60411',
    city: 'Chicago Heights',
    state: 'IL',
    latitude: '41.505061',
    longitude: '-87.606173',
    timeZoneId: 'America/Chicago',
  },
  '60412': {
    zip: '60412',
    city: 'Chicago Heights',
    state: 'IL',
    latitude: '41.510829',
    longitude: '-87.636167',
    timeZoneId: 'America/Chicago',
  },
  '60415': {
    zip: '60415',
    city: 'Chicago Ridge',
    state: 'IL',
    latitude: '41.702093',
    longitude: '-87.777421',
    timeZoneId: 'America/Chicago',
  },
  '60416': {
    zip: '60416',
    city: 'Coal City',
    state: 'IL',
    latitude: '41.291648',
    longitude: '-88.285221',
    timeZoneId: 'America/Chicago',
  },
  '60417': {
    zip: '60417',
    city: 'Crete',
    state: 'IL',
    latitude: '41.444101',
    longitude: '-87.592909',
    timeZoneId: 'America/Chicago',
  },
  '60418': {
    zip: '60418',
    city: 'Crestwood',
    state: 'IL',
    latitude: '0',
    longitude: '0',
    timeZoneId: 'America/Chicago',
  },
  '60419': {
    zip: '60419',
    city: 'Dolton',
    state: 'IL',
    latitude: '41.626741',
    longitude: '-87.595887',
    timeZoneId: 'America/Chicago',
  },
  '60420': {
    zip: '60420',
    city: 'Dwight',
    state: 'IL',
    latitude: '41.089777',
    longitude: '-88.426759',
    timeZoneId: 'America/Chicago',
  },
  '60421': {
    zip: '60421',
    city: 'Elwood',
    state: 'IL',
    latitude: '41.424204',
    longitude: '-88.08653',
    timeZoneId: 'America/Chicago',
  },
  '60422': {
    zip: '60422',
    city: 'Flossmoor',
    state: 'IL',
    latitude: '41.540319',
    longitude: '-87.682222',
    timeZoneId: 'America/Chicago',
  },
  '60423': {
    zip: '60423',
    city: 'Frankfort',
    state: 'IL',
    latitude: '41.491927',
    longitude: '-87.833795',
    timeZoneId: 'America/Chicago',
  },
  '60424': {
    zip: '60424',
    city: 'Gardner',
    state: 'IL',
    latitude: '41.169508',
    longitude: '-88.320503',
    timeZoneId: 'America/Chicago',
  },
  '60425': {
    zip: '60425',
    city: 'Glenwood',
    state: 'IL',
    latitude: '41.548622',
    longitude: '-87.612394',
    timeZoneId: 'America/Chicago',
  },
  '60426': {
    zip: '60426',
    city: 'Harvey',
    state: 'IL',
    latitude: '41.604606',
    longitude: '-87.66903',
    timeZoneId: 'America/Chicago',
  },
  '60428': {
    zip: '60428',
    city: 'Markham',
    state: 'IL',
    latitude: '41.608419',
    longitude: '-87.666114',
    timeZoneId: 'America/Chicago',
  },
  '60429': {
    zip: '60429',
    city: 'Hazel Crest',
    state: 'IL',
    latitude: '41.56806',
    longitude: '-87.67344',
    timeZoneId: 'America/Chicago',
  },
  '60430': {
    zip: '60430',
    city: 'Homewood',
    state: 'IL',
    latitude: '41.554935',
    longitude: '-87.672332',
    timeZoneId: 'America/Chicago',
  },
  '60431': {
    zip: '60431',
    city: 'Joliet',
    state: 'IL',
    latitude: '41.523431',
    longitude: '-88.158761',
    timeZoneId: 'America/Chicago',
  },
  '60432': {
    zip: '60432',
    city: 'Joliet',
    state: 'IL',
    latitude: '41.535383',
    longitude: '-88.053442',
    timeZoneId: 'America/Chicago',
  },
  '60433': {
    zip: '60433',
    city: 'Joliet',
    state: 'IL',
    latitude: '41.513131',
    longitude: '-88.052737',
    timeZoneId: 'America/Chicago',
  },
  '60434': {
    zip: '60434',
    city: 'Joliet',
    state: 'IL',
    latitude: '41.539292',
    longitude: '-88.076927',
    timeZoneId: 'America/Chicago',
  },
  '60435': {
    zip: '60435',
    city: 'Joliet',
    state: 'IL',
    latitude: '41.536992',
    longitude: '-88.125341',
    timeZoneId: 'America/Chicago',
  },
  '60436': {
    zip: '60436',
    city: 'Joliet',
    state: 'IL',
    latitude: '41.48964',
    longitude: '-88.14534',
    timeZoneId: 'America/Chicago',
  },
  '60437': {
    zip: '60437',
    city: 'Kinsman',
    state: 'IL',
    latitude: '41.193043',
    longitude: '-88.55705',
    timeZoneId: 'America/Chicago',
  },
  '60438': {
    zip: '60438',
    city: 'Lansing',
    state: 'IL',
    latitude: '41.567222',
    longitude: '-87.550503',
    timeZoneId: 'America/Chicago',
  },
  '60439': {
    zip: '60439',
    city: 'Lemont',
    state: 'IL',
    latitude: '41.702649',
    longitude: '-88.072694',
    timeZoneId: 'America/Chicago',
  },
  '60440': {
    zip: '60440',
    city: 'Bolingbrook',
    state: 'IL',
    latitude: '41.704671',
    longitude: '-88.075236',
    timeZoneId: 'America/Chicago',
  },
  '60441': {
    zip: '60441',
    city: 'Lockport',
    state: 'IL',
    latitude: '41.608015',
    longitude: '-88.013188',
    timeZoneId: 'America/Chicago',
  },
  '60442': {
    zip: '60442',
    city: 'Manhattan',
    state: 'IL',
    latitude: '41.434474',
    longitude: '-87.984338',
    timeZoneId: 'America/Chicago',
  },
  '60443': {
    zip: '60443',
    city: 'Matteson',
    state: 'IL',
    latitude: '41.507329',
    longitude: '-87.735557',
    timeZoneId: 'America/Chicago',
  },
  '60444': {
    zip: '60444',
    city: 'Mazon',
    state: 'IL',
    latitude: '41.250355',
    longitude: '-88.413014',
    timeZoneId: 'America/Chicago',
  },
  '60445': {
    zip: '60445',
    city: 'Midlothian',
    state: 'IL',
    latitude: '41.635673',
    longitude: '-87.738641',
    timeZoneId: 'America/Chicago',
  },
  '60446': {
    zip: '60446',
    city: 'Romeoville',
    state: 'IL',
    latitude: '41.591798',
    longitude: '-88.060557',
    timeZoneId: 'America/Chicago',
  },
  '60447': {
    zip: '60447',
    city: 'Minooka',
    state: 'IL',
    latitude: '41.507187',
    longitude: '-88.334647',
    timeZoneId: 'America/Chicago',
  },
  '60448': {
    zip: '60448',
    city: 'Mokena',
    state: 'IL',
    latitude: '41.533318',
    longitude: '-87.888034',
    timeZoneId: 'America/Chicago',
  },
  '60449': {
    zip: '60449',
    city: 'Monee',
    state: 'IL',
    latitude: '41.419345',
    longitude: '-87.774708',
    timeZoneId: 'America/Chicago',
  },
  '60450': {
    zip: '60450',
    city: 'Morris',
    state: 'IL',
    latitude: '41.364155',
    longitude: '-88.421363',
    timeZoneId: 'America/Chicago',
  },
  '60451': {
    zip: '60451',
    city: 'New Lenox',
    state: 'IL',
    latitude: '41.510578',
    longitude: '-87.963544',
    timeZoneId: 'America/Chicago',
  },
  '60452': {
    zip: '60452',
    city: 'Oak Forest',
    state: 'IL',
    latitude: '41.608681',
    longitude: '-87.757635',
    timeZoneId: 'America/Chicago',
  },
  '60453': {
    zip: '60453',
    city: 'Oak Lawn',
    state: 'IL',
    latitude: '41.713314',
    longitude: '-87.756652',
    timeZoneId: 'America/Chicago',
  },
  '60454': {
    zip: '60454',
    city: 'Oak Lawn',
    state: 'IL',
    latitude: '41.710601',
    longitude: '-87.805343',
    timeZoneId: 'America/Chicago',
  },
  '60455': {
    zip: '60455',
    city: 'Bridgeview',
    state: 'IL',
    latitude: '41.737459',
    longitude: '-87.803915',
    timeZoneId: 'America/Chicago',
  },
  '60456': {
    zip: '60456',
    city: 'Hometown',
    state: 'IL',
    latitude: '41.734578',
    longitude: '-87.732449',
    timeZoneId: 'America/Chicago',
  },
  '60457': {
    zip: '60457',
    city: 'Hickory Hills',
    state: 'IL',
    latitude: '41.727067',
    longitude: '-87.828588',
    timeZoneId: 'America/Chicago',
  },
  '60458': {
    zip: '60458',
    city: 'Justice',
    state: 'IL',
    latitude: '41.745877',
    longitude: '-87.836217',
    timeZoneId: 'America/Chicago',
  },
  '60459': {
    zip: '60459',
    city: 'Burbank',
    state: 'IL',
    latitude: '41.745512',
    longitude: '-87.772445',
    timeZoneId: 'America/Chicago',
  },
  '60460': {
    zip: '60460',
    city: 'Odell',
    state: 'IL',
    latitude: '41.00565',
    longitude: '-88.522179',
    timeZoneId: 'America/Chicago',
  },
  '60461': {
    zip: '60461',
    city: 'Olympia Fields',
    state: 'IL',
    latitude: '41.515245',
    longitude: '-87.687123',
    timeZoneId: 'America/Chicago',
  },
  '60462': {
    zip: '60462',
    city: 'Orland Park',
    state: 'IL',
    latitude: '41.62359',
    longitude: '-87.845563',
    timeZoneId: 'America/Chicago',
  },
  '60463': {
    zip: '60463',
    city: 'Palos Heights',
    state: 'IL',
    latitude: '41.658291',
    longitude: '-87.787612',
    timeZoneId: 'America/Chicago',
  },
  '60464': {
    zip: '60464',
    city: 'Palos Park',
    state: 'IL',
    latitude: '41.671566',
    longitude: '-87.859791',
    timeZoneId: 'America/Chicago',
  },
  '60465': {
    zip: '60465',
    city: 'Palos Hills',
    state: 'IL',
    latitude: '41.702277',
    longitude: '-87.82835',
    timeZoneId: 'America/Chicago',
  },
  '60466': {
    zip: '60466',
    city: 'Park Forest',
    state: 'IL',
    latitude: '41.46474',
    longitude: '-87.699',
    timeZoneId: 'America/Chicago',
  },
  '60467': {
    zip: '60467',
    city: 'Orland Park',
    state: 'IL',
    latitude: '41.587062',
    longitude: '-87.883775',
    timeZoneId: 'America/Chicago',
  },
  '60468': {
    zip: '60468',
    city: 'Peotone',
    state: 'IL',
    latitude: '41.335735',
    longitude: '-87.792013',
    timeZoneId: 'America/Chicago',
  },
  '60469': {
    zip: '60469',
    city: 'Posen',
    state: 'IL',
    latitude: '41.626682',
    longitude: '-87.686685',
    timeZoneId: 'America/Chicago',
  },
  '60470': {
    zip: '60470',
    city: 'Ransom',
    state: 'IL',
    latitude: '41.157396',
    longitude: '-88.65791',
    timeZoneId: 'America/Chicago',
  },
  '60471': {
    zip: '60471',
    city: 'Richton Park',
    state: 'IL',
    latitude: '41.481197',
    longitude: '-87.725978',
    timeZoneId: 'America/Chicago',
  },
  '60472': {
    zip: '60472',
    city: 'Robbins',
    state: 'IL',
    latitude: '41.641841',
    longitude: '-87.700278',
    timeZoneId: 'America/Chicago',
  },
  '60473': {
    zip: '60473',
    city: 'South Holland',
    state: 'IL',
    latitude: '41.598979',
    longitude: '-87.596242',
    timeZoneId: 'America/Chicago',
  },
  '60474': {
    zip: '60474',
    city: 'South Wilmington',
    state: 'IL',
    latitude: '41.173333',
    longitude: '-88.263889',
    timeZoneId: 'America/Chicago',
  },
  '60475': {
    zip: '60475',
    city: 'Steger',
    state: 'IL',
    latitude: '41.466203',
    longitude: '-87.638399',
    timeZoneId: 'America/Chicago',
  },
  '60476': {
    zip: '60476',
    city: 'Thornton',
    state: 'IL',
    latitude: '41.571347',
    longitude: '-87.61059',
    timeZoneId: 'America/Chicago',
  },
  '60477': {
    zip: '60477',
    city: 'Tinley Park',
    state: 'IL',
    latitude: '41.5674',
    longitude: '-87.79266',
    timeZoneId: 'America/Chicago',
  },
  '60478': {
    zip: '60478',
    city: 'Country Club Hills',
    state: 'IL',
    latitude: '41.559523',
    longitude: '-87.722149',
    timeZoneId: 'America/Chicago',
  },
  '60479': {
    zip: '60479',
    city: 'Verona',
    state: 'IL',
    latitude: '41.227482',
    longitude: '-88.522792',
    timeZoneId: 'America/Chicago',
  },
  '60480': {
    zip: '60480',
    city: 'Willow Springs',
    state: 'IL',
    latitude: '41.725603',
    longitude: '-87.874259',
    timeZoneId: 'America/Chicago',
  },
  '60481': {
    zip: '60481',
    city: 'Wilmington',
    state: 'IL',
    latitude: '41.294293',
    longitude: '-88.124154',
    timeZoneId: 'America/Chicago',
  },
  '60482': {
    zip: '60482',
    city: 'Worth',
    state: 'IL',
    latitude: '41.68896',
    longitude: '-87.77484',
    timeZoneId: 'America/Chicago',
  },
  '60484': {
    zip: '60484',
    city: 'University Park',
    state: 'IL',
    latitude: '41.466347',
    longitude: '-87.698762',
    timeZoneId: 'America/Chicago',
  },
  '60487': {
    zip: '60487',
    city: 'Tinley Park',
    state: 'IL',
    latitude: '41.567359',
    longitude: '-87.79198',
    timeZoneId: 'America/Chicago',
  },
  '60490': {
    zip: '60490',
    city: 'Bolingbrook',
    state: 'IL',
    latitude: '41.698611',
    longitude: '-88.068333',
    timeZoneId: 'America/Chicago',
  },
  '60491': {
    zip: '60491',
    city: 'Homer Glen',
    state: 'IL',
    latitude: '41.607647',
    longitude: '-88.014981',
    timeZoneId: 'America/Chicago',
  },
  '60499': {
    zip: '60499',
    city: 'Bedford Park',
    state: 'IL',
    latitude: '41.782323',
    longitude: '-87.767936',
    timeZoneId: 'America/Chicago',
  },
  '60501': {
    zip: '60501',
    city: 'Summit Argo',
    state: 'IL',
    latitude: '41.782868',
    longitude: '-87.813794',
    timeZoneId: 'America/Chicago',
  },
  '60502': {
    zip: '60502',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.7843',
    longitude: '-88.2513',
    timeZoneId: 'America/Chicago',
  },
  '60503': {
    zip: '60503',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.7199',
    longitude: '-88.2548',
    timeZoneId: 'America/Chicago',
  },
  '60504': {
    zip: '60504',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.755826',
    longitude: '-88.241597',
    timeZoneId: 'America/Chicago',
  },
  '60505': {
    zip: '60505',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.758169',
    longitude: '-88.297124',
    timeZoneId: 'America/Chicago',
  },
  '60506': {
    zip: '60506',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.762574',
    longitude: '-88.353484',
    timeZoneId: 'America/Chicago',
  },
  '60507': {
    zip: '60507',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.78532',
    longitude: '-88.318452',
    timeZoneId: 'America/Chicago',
  },
  '60510': {
    zip: '60510',
    city: 'Batavia',
    state: 'IL',
    latitude: '41.845761',
    longitude: '-88.31066',
    timeZoneId: 'America/Chicago',
  },
  '60511': {
    zip: '60511',
    city: 'Big Rock',
    state: 'IL',
    latitude: '41.748526',
    longitude: '-88.532219',
    timeZoneId: 'America/Chicago',
  },
  '60512': {
    zip: '60512',
    city: 'Bristol',
    state: 'IL',
    latitude: '41.710574',
    longitude: '-88.413874',
    timeZoneId: 'America/Chicago',
  },
  '60513': {
    zip: '60513',
    city: 'Brookfield',
    state: 'IL',
    latitude: '41.824933',
    longitude: '-87.845112',
    timeZoneId: 'America/Chicago',
  },
  '60514': {
    zip: '60514',
    city: 'Clarendon Hills',
    state: 'IL',
    latitude: '41.77248',
    longitude: '-87.95718',
    timeZoneId: 'America/Chicago',
  },
  '60515': {
    zip: '60515',
    city: 'Downers Grove',
    state: 'IL',
    latitude: '41.807564',
    longitude: '-88.019241',
    timeZoneId: 'America/Chicago',
  },
  '60516': {
    zip: '60516',
    city: 'Downers Grove',
    state: 'IL',
    latitude: '41.759097',
    longitude: '-88.015319',
    timeZoneId: 'America/Chicago',
  },
  '60517': {
    zip: '60517',
    city: 'Woodridge',
    state: 'IL',
    latitude: '41.75424',
    longitude: '-88.06104',
    timeZoneId: 'America/Chicago',
  },
  '60518': {
    zip: '60518',
    city: 'Earlville',
    state: 'IL',
    latitude: '41.59272',
    longitude: '-88.89222',
    timeZoneId: 'America/Chicago',
  },
  '60519': {
    zip: '60519',
    city: 'Eola',
    state: 'IL',
    latitude: '41.77453',
    longitude: '-88.242378',
    timeZoneId: 'America/Chicago',
  },
  '60520': {
    zip: '60520',
    city: 'Hinckley',
    state: 'IL',
    latitude: '41.768822',
    longitude: '-88.64873',
    timeZoneId: 'America/Chicago',
  },
  '60521': {
    zip: '60521',
    city: 'Hinsdale',
    state: 'IL',
    latitude: '41.790281',
    longitude: '-87.94813',
    timeZoneId: 'America/Chicago',
  },
  '60522': {
    zip: '60522',
    city: 'Hinsdale',
    state: 'IL',
    latitude: '41.832778',
    longitude: '-87.928889',
    timeZoneId: 'America/Chicago',
  },
  '60523': {
    zip: '60523',
    city: 'Oak Brook',
    state: 'IL',
    latitude: '41.800833',
    longitude: '-87.936944',
    timeZoneId: 'America/Chicago',
  },
  '60525': {
    zip: '60525',
    city: 'La Grange',
    state: 'IL',
    latitude: '41.795251',
    longitude: '-87.875697',
    timeZoneId: 'America/Chicago',
  },
  '60526': {
    zip: '60526',
    city: 'La Grange Park',
    state: 'IL',
    latitude: '41.831029',
    longitude: '-87.873348',
    timeZoneId: 'America/Chicago',
  },
  '60527': {
    zip: '60527',
    city: 'Willowbrook',
    state: 'IL',
    latitude: '41.84182',
    longitude: '-87.95104',
    timeZoneId: 'America/Chicago',
  },
  '60530': {
    zip: '60530',
    city: 'Lee',
    state: 'IL',
    latitude: '41.792838',
    longitude: '-88.972591',
    timeZoneId: 'America/Chicago',
  },
  '60531': {
    zip: '60531',
    city: 'Leland',
    state: 'IL',
    latitude: '41.601884',
    longitude: '-88.780679',
    timeZoneId: 'America/Chicago',
  },
  '60532': {
    zip: '60532',
    city: 'Lisle',
    state: 'IL',
    latitude: '41.790983',
    longitude: '-88.075644',
    timeZoneId: 'America/Chicago',
  },
  '60534': {
    zip: '60534',
    city: 'Lyons',
    state: 'IL',
    latitude: '41.811846',
    longitude: '-87.820628',
    timeZoneId: 'America/Chicago',
  },
  '60536': {
    zip: '60536',
    city: 'Millbrook',
    state: 'IL',
    latitude: '41.595389',
    longitude: '-88.563155',
    timeZoneId: 'America/Chicago',
  },
  '60537': {
    zip: '60537',
    city: 'Millington',
    state: 'IL',
    latitude: '41.560782',
    longitude: '-88.601057',
    timeZoneId: 'America/Chicago',
  },
  '60538': {
    zip: '60538',
    city: 'Montgomery',
    state: 'IL',
    latitude: '41.709552',
    longitude: '-88.336361',
    timeZoneId: 'America/Chicago',
  },
  '60539': {
    zip: '60539',
    city: 'Mooseheart',
    state: 'IL',
    latitude: '41.823889',
    longitude: '-88.334722',
    timeZoneId: 'America/Chicago',
  },
  '60540': {
    zip: '60540',
    city: 'Naperville',
    state: 'IL',
    latitude: '41.774282',
    longitude: '-88.147187',
    timeZoneId: 'America/Chicago',
  },
  '60541': {
    zip: '60541',
    city: 'Newark',
    state: 'IL',
    latitude: '41.514251',
    longitude: '-88.510263',
    timeZoneId: 'America/Chicago',
  },
  '60542': {
    zip: '60542',
    city: 'North Aurora',
    state: 'IL',
    latitude: '41.807193',
    longitude: '-88.324757',
    timeZoneId: 'America/Chicago',
  },
  '60543': {
    zip: '60543',
    city: 'Oswego',
    state: 'IL',
    latitude: '41.666294',
    longitude: '-88.319681',
    timeZoneId: 'America/Chicago',
  },
  '60544': {
    zip: '60544',
    city: 'Plainfield',
    state: 'IL',
    latitude: '41.618385',
    longitude: '-88.199459',
    timeZoneId: 'America/Chicago',
  },
  '60545': {
    zip: '60545',
    city: 'Plano',
    state: 'IL',
    latitude: '41.668485',
    longitude: '-88.531045',
    timeZoneId: 'America/Chicago',
  },
  '60546': {
    zip: '60546',
    city: 'Riverside',
    state: 'IL',
    latitude: '41.835506',
    longitude: '-87.82164',
    timeZoneId: 'America/Chicago',
  },
  '60548': {
    zip: '60548',
    city: 'Sandwich',
    state: 'IL',
    latitude: '41.646803',
    longitude: '-88.623355',
    timeZoneId: 'America/Chicago',
  },
  '60549': {
    zip: '60549',
    city: 'Serena',
    state: 'IL',
    latitude: '41.458846',
    longitude: '-88.768915',
    timeZoneId: 'America/Chicago',
  },
  '60550': {
    zip: '60550',
    city: 'Shabbona',
    state: 'IL',
    latitude: '41.768314',
    longitude: '-88.869678',
    timeZoneId: 'America/Chicago',
  },
  '60551': {
    zip: '60551',
    city: 'Sheridan',
    state: 'IL',
    latitude: '41.51634',
    longitude: '-88.71678',
    timeZoneId: 'America/Chicago',
  },
  '60552': {
    zip: '60552',
    city: 'Somonauk',
    state: 'IL',
    latitude: '41.651699',
    longitude: '-88.677362',
    timeZoneId: 'America/Chicago',
  },
  '60553': {
    zip: '60553',
    city: 'Steward',
    state: 'IL',
    latitude: '41.812414',
    longitude: '-89.021117',
    timeZoneId: 'America/Chicago',
  },
  '60554': {
    zip: '60554',
    city: 'Sugar Grove',
    state: 'IL',
    latitude: '41.771338',
    longitude: '-88.459795',
    timeZoneId: 'America/Chicago',
  },
  '60555': {
    zip: '60555',
    city: 'Warrenville',
    state: 'IL',
    latitude: '41.825491',
    longitude: '-88.183156',
    timeZoneId: 'America/Chicago',
  },
  '60556': {
    zip: '60556',
    city: 'Waterman',
    state: 'IL',
    latitude: '41.749083',
    longitude: '-88.773261',
    timeZoneId: 'America/Chicago',
  },
  '60557': {
    zip: '60557',
    city: 'Wedron',
    state: 'IL',
    latitude: '41.435185',
    longitude: '-88.769788',
    timeZoneId: 'America/Chicago',
  },
  '60558': {
    zip: '60558',
    city: 'Western Springs',
    state: 'IL',
    latitude: '41.804602',
    longitude: '-87.896988',
    timeZoneId: 'America/Chicago',
  },
  '60559': {
    zip: '60559',
    city: 'Westmont',
    state: 'IL',
    latitude: '41.79421',
    longitude: '-87.977021',
    timeZoneId: 'America/Chicago',
  },
  '60560': {
    zip: '60560',
    city: 'Yorkville',
    state: 'IL',
    latitude: '41.622305',
    longitude: '-88.43204',
    timeZoneId: 'America/Chicago',
  },
  '60561': {
    zip: '60561',
    city: 'Darien',
    state: 'IL',
    latitude: '41.74994',
    longitude: '-87.977041',
    timeZoneId: 'America/Chicago',
  },
  '60563': {
    zip: '60563',
    city: 'Naperville',
    state: 'IL',
    latitude: '41.792577',
    longitude: '-88.165647',
    timeZoneId: 'America/Chicago',
  },
  '60564': {
    zip: '60564',
    city: 'Naperville',
    state: 'IL',
    latitude: '41.710744',
    longitude: '-88.200262',
    timeZoneId: 'America/Chicago',
  },
  '60565': {
    zip: '60565',
    city: 'Naperville',
    state: 'IL',
    latitude: '41.738696',
    longitude: '-88.124239',
    timeZoneId: 'America/Chicago',
  },
  '60566': {
    zip: '60566',
    city: 'Naperville',
    state: 'IL',
    latitude: '41.744942',
    longitude: '-88.127269',
    timeZoneId: 'America/Chicago',
  },
  '60567': {
    zip: '60567',
    city: 'Naperville',
    state: 'IL',
    latitude: '41.783823',
    longitude: '-88.146897',
    timeZoneId: 'America/Chicago',
  },
  '60568': {
    zip: '60568',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.773392',
    longitude: '-88.247376',
    timeZoneId: 'America/Chicago',
  },
  '60569': {
    zip: '60569',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.773392',
    longitude: '-88.247376',
    timeZoneId: 'America/Chicago',
  },
  '60572': {
    zip: '60572',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.76606',
    longitude: '-88.320017',
    timeZoneId: 'America/Chicago',
  },
  '60585': {
    zip: '60585',
    city: 'Plainfield',
    state: 'IL',
    latitude: '41.6553',
    longitude: '-88.2079',
    timeZoneId: 'America/Chicago',
  },
  '60586': {
    zip: '60586',
    city: 'Plainfield',
    state: 'IL',
    latitude: '41.5677',
    longitude: '-88.2106',
    timeZoneId: 'America/Chicago',
  },
  '60598': {
    zip: '60598',
    city: 'Aurora',
    state: 'IL',
    latitude: '41.77478',
    longitude: '-88.250675',
    timeZoneId: 'America/Chicago',
  },
  '60599': {
    zip: '60599',
    city: 'Fox Valley',
    state: 'IL',
    latitude: '41.772102',
    longitude: '-88.357402',
    timeZoneId: 'America/Chicago',
  },
  '60601': {
    zip: '60601',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.885144',
    longitude: '-87.625994',
    timeZoneId: 'America/Chicago',
  },
  '60602': {
    zip: '60602',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.88149',
    longitude: '-87.624392',
    timeZoneId: 'America/Chicago',
  },
  '60603': {
    zip: '60603',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.879623',
    longitude: '-87.628479',
    timeZoneId: 'America/Chicago',
  },
  '60604': {
    zip: '60604',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.875734',
    longitude: '-87.625562',
    timeZoneId: 'America/Chicago',
  },
  '60605': {
    zip: '60605',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.865699',
    longitude: '-87.627039',
    timeZoneId: 'America/Chicago',
  },
  '60606': {
    zip: '60606',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.883784',
    longitude: '-87.637668',
    timeZoneId: 'America/Chicago',
  },
  '60607': {
    zip: '60607',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.87941',
    longitude: '-87.650582',
    timeZoneId: 'America/Chicago',
  },
  '60608': {
    zip: '60608',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.849103',
    longitude: '-87.667335',
    timeZoneId: 'America/Chicago',
  },
  '60609': {
    zip: '60609',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.812837',
    longitude: '-87.654243',
    timeZoneId: 'America/Chicago',
  },
  '60610': {
    zip: '60610',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.898992',
    longitude: '-87.636455',
    timeZoneId: 'America/Chicago',
  },
  '60611': {
    zip: '60611',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.894244',
    longitude: '-87.622841',
    timeZoneId: 'America/Chicago',
  },
  '60612': {
    zip: '60612',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.881072',
    longitude: '-87.68713',
    timeZoneId: 'America/Chicago',
  },
  '60613': {
    zip: '60613',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.955735',
    longitude: '-87.66121',
    timeZoneId: 'America/Chicago',
  },
  '60614': {
    zip: '60614',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.923621',
    longitude: '-87.653367',
    timeZoneId: 'America/Chicago',
  },
  '60615': {
    zip: '60615',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.800635',
    longitude: '-87.604568',
    timeZoneId: 'America/Chicago',
  },
  '60616': {
    zip: '60616',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.844775',
    longitude: '-87.626719',
    timeZoneId: 'America/Chicago',
  },
  '60617': {
    zip: '60617',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.716207',
    longitude: '-87.554349',
    timeZoneId: 'America/Chicago',
  },
  '60618': {
    zip: '60618',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.944335',
    longitude: '-87.702859',
    timeZoneId: 'America/Chicago',
  },
  '60619': {
    zip: '60619',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.745767',
    longitude: '-87.607643',
    timeZoneId: 'America/Chicago',
  },
  '60620': {
    zip: '60620',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.742065',
    longitude: '-87.655494',
    timeZoneId: 'America/Chicago',
  },
  '60621': {
    zip: '60621',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.778204',
    longitude: '-87.641571',
    timeZoneId: 'America/Chicago',
  },
  '60622': {
    zip: '60622',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.897111',
    longitude: '-87.67088',
    timeZoneId: 'America/Chicago',
  },
  '60623': {
    zip: '60623',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.846636',
    longitude: '-87.71724',
    timeZoneId: 'America/Chicago',
  },
  '60624': {
    zip: '60624',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.877445',
    longitude: '-87.723846',
    timeZoneId: 'America/Chicago',
  },
  '60625': {
    zip: '60625',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.968521',
    longitude: '-87.703667',
    timeZoneId: 'America/Chicago',
  },
  '60626': {
    zip: '60626',
    city: 'Chicago',
    state: 'IL',
    latitude: '42.005107',
    longitude: '-87.669133',
    timeZoneId: 'America/Chicago',
  },
  '60628': {
    zip: '60628',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.69154',
    longitude: '-87.62171',
    timeZoneId: 'America/Chicago',
  },
  '60629': {
    zip: '60629',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.777493',
    longitude: '-87.709271',
    timeZoneId: 'America/Chicago',
  },
  '60630': {
    zip: '60630',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.971136',
    longitude: '-87.757805',
    timeZoneId: 'America/Chicago',
  },
  '60631': {
    zip: '60631',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.989117',
    longitude: '-87.805412',
    timeZoneId: 'America/Chicago',
  },
  '60632': {
    zip: '60632',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.808605',
    longitude: '-87.711782',
    timeZoneId: 'America/Chicago',
  },
  '60633': {
    zip: '60633',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.653807',
    longitude: '-87.559353',
    timeZoneId: 'America/Chicago',
  },
  '60634': {
    zip: '60634',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.944287',
    longitude: '-87.811483',
    timeZoneId: 'America/Chicago',
  },
  '60636': {
    zip: '60636',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.774317',
    longitude: '-87.670123',
    timeZoneId: 'America/Chicago',
  },
  '60637': {
    zip: '60637',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.78128',
    longitude: '-87.603401',
    timeZoneId: 'America/Chicago',
  },
  '60638': {
    zip: '60638',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.781649',
    longitude: '-87.774356',
    timeZoneId: 'America/Chicago',
  },
  '60639': {
    zip: '60639',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.918417',
    longitude: '-87.755959',
    timeZoneId: 'America/Chicago',
  },
  '60640': {
    zip: '60640',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.9726',
    longitude: '-87.666715',
    timeZoneId: 'America/Chicago',
  },
  '60641': {
    zip: '60641',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.944704',
    longitude: '-87.745925',
    timeZoneId: 'America/Chicago',
  },
  '60642': {
    zip: '60642',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.944713',
    longitude: '-87.745937',
    timeZoneId: 'America/Chicago',
  },
  '60643': {
    zip: '60643',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.699379',
    longitude: '-87.659992',
    timeZoneId: 'America/Chicago',
  },
  '60644': {
    zip: '60644',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.881955',
    longitude: '-87.757606',
    timeZoneId: 'America/Chicago',
  },
  '60645': {
    zip: '60645',
    city: 'Chicago',
    state: 'IL',
    latitude: '42.008098',
    longitude: '-87.706039',
    timeZoneId: 'America/Chicago',
  },
  '60646': {
    zip: '60646',
    city: 'Chicago',
    state: 'IL',
    latitude: '42.007469',
    longitude: '-87.741972',
    timeZoneId: 'America/Chicago',
  },
  '60647': {
    zip: '60647',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.918536',
    longitude: '-87.698156',
    timeZoneId: 'America/Chicago',
  },
  '60649': {
    zip: '60649',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.762027',
    longitude: '-87.567858',
    timeZoneId: 'America/Chicago',
  },
  '60651': {
    zip: '60651',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.89987',
    longitude: '-87.741859',
    timeZoneId: 'America/Chicago',
  },
  '60652': {
    zip: '60652',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.743986',
    longitude: '-87.712805',
    timeZoneId: 'America/Chicago',
  },
  '60653': {
    zip: '60653',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.820185',
    longitude: '-87.612036',
    timeZoneId: 'America/Chicago',
  },
  '60654': {
    zip: '60654',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.887141',
    longitude: '-87.636511',
    timeZoneId: 'America/Chicago',
  },
  '60655': {
    zip: '60655',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.691946',
    longitude: '-87.709304',
    timeZoneId: 'America/Chicago',
  },
  '60656': {
    zip: '60656',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.975199',
    longitude: '-87.825937',
    timeZoneId: 'America/Chicago',
  },
  '60657': {
    zip: '60657',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.940734',
    longitude: '-87.654198',
    timeZoneId: 'America/Chicago',
  },
  '60659': {
    zip: '60659',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.98637',
    longitude: '-87.70116',
    timeZoneId: 'America/Chicago',
  },
  '60660': {
    zip: '60660',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.990183',
    longitude: '-87.6648',
    timeZoneId: 'America/Chicago',
  },
  '60661': {
    zip: '60661',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.883434',
    longitude: '-87.640507',
    timeZoneId: 'America/Chicago',
  },
  '60664': {
    zip: '60664',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.873171',
    longitude: '-87.654296',
    timeZoneId: 'America/Chicago',
  },
  '60666': {
    zip: '60666',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.972411',
    longitude: '-87.895058',
    timeZoneId: 'America/Chicago',
  },
  '60668': {
    zip: '60668',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.881773',
    longitude: '-87.62648',
    timeZoneId: 'America/Chicago',
  },
  '60669': {
    zip: '60669',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.875708',
    longitude: '-87.650623',
    timeZoneId: 'America/Chicago',
  },
  '60670': {
    zip: '60670',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.88648',
    longitude: '-87.622247',
    timeZoneId: 'America/Chicago',
  },
  '60673': {
    zip: '60673',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.887505',
    longitude: '-87.628813',
    timeZoneId: 'America/Chicago',
  },
  '60674': {
    zip: '60674',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.876571',
    longitude: '-87.64948',
    timeZoneId: 'America/Chicago',
  },
  '60675': {
    zip: '60675',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.880651',
    longitude: '-87.630041',
    timeZoneId: 'America/Chicago',
  },
  '60677': {
    zip: '60677',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.881607',
    longitude: '-87.639724',
    timeZoneId: 'America/Chicago',
  },
  '60678': {
    zip: '60678',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.883935',
    longitude: '-87.631729',
    timeZoneId: 'America/Chicago',
  },
  '60680': {
    zip: '60680',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.866893',
    longitude: '-87.648013',
    timeZoneId: 'America/Chicago',
  },
  '60681': {
    zip: '60681',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.876672',
    longitude: '-87.646485',
    timeZoneId: 'America/Chicago',
  },
  '60682': {
    zip: '60682',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.835903',
    longitude: '-87.686615',
    timeZoneId: 'America/Chicago',
  },
  '60684': {
    zip: '60684',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.870185',
    longitude: '-87.671984',
    timeZoneId: 'America/Chicago',
  },
  '60685': {
    zip: '60685',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.881488',
    longitude: '-87.62412',
    timeZoneId: 'America/Chicago',
  },
  '60686': {
    zip: '60686',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.850116',
    longitude: '-87.650659',
    timeZoneId: 'America/Chicago',
  },
  '60687': {
    zip: '60687',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.878579',
    longitude: '-87.625823',
    timeZoneId: 'America/Chicago',
  },
  '60688': {
    zip: '60688',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.846809',
    longitude: '-87.649947',
    timeZoneId: 'America/Chicago',
  },
  '60689': {
    zip: '60689',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.850211',
    longitude: '-87.652468',
    timeZoneId: 'America/Chicago',
  },
  '60690': {
    zip: '60690',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.882153',
    longitude: '-87.630933',
    timeZoneId: 'America/Chicago',
  },
  '60691': {
    zip: '60691',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.875911',
    longitude: '-87.61472',
    timeZoneId: 'America/Chicago',
  },
  '60693': {
    zip: '60693',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.879153',
    longitude: '-87.612931',
    timeZoneId: 'America/Chicago',
  },
  '60694': {
    zip: '60694',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.877882',
    longitude: '-87.629688',
    timeZoneId: 'America/Chicago',
  },
  '60695': {
    zip: '60695',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.878332',
    longitude: '-87.633108',
    timeZoneId: 'America/Chicago',
  },
  '60696': {
    zip: '60696',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.848295',
    longitude: '-87.649618',
    timeZoneId: 'America/Chicago',
  },
  '60697': {
    zip: '60697',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.879482',
    longitude: '-87.615588',
    timeZoneId: 'America/Chicago',
  },
  '60699': {
    zip: '60699',
    city: 'Chicago',
    state: 'IL',
    latitude: '41.875983',
    longitude: '-87.646718',
    timeZoneId: 'America/Chicago',
  },
  '60701': {
    zip: '60701',
    city: 'Chicago',
    state: 'IL',
    latitude: '42.029394',
    longitude: '-87.807553',
    timeZoneId: 'America/Chicago',
  },
  '60706': {
    zip: '60706',
    city: 'Harwood Heights',
    state: 'IL',
    latitude: '41.962443',
    longitude: '-87.817391',
    timeZoneId: 'America/Chicago',
  },
  '60707': {
    zip: '60707',
    city: 'Elmwood Park',
    state: 'IL',
    latitude: '41.923558',
    longitude: '-87.816578',
    timeZoneId: 'America/Chicago',
  },
  '60712': {
    zip: '60712',
    city: 'Lincolnwood',
    state: 'IL',
    latitude: '42.003155',
    longitude: '-87.734888',
    timeZoneId: 'America/Chicago',
  },
  '60714': {
    zip: '60714',
    city: 'Niles',
    state: 'IL',
    latitude: '42.027217',
    longitude: '-87.813189',
    timeZoneId: 'America/Chicago',
  },
  '60803': {
    zip: '60803',
    city: 'Alsip',
    state: 'IL',
    latitude: '41.66721',
    longitude: '-87.740131',
    timeZoneId: 'America/Chicago',
  },
  '60804': {
    zip: '60804',
    city: 'Cicero',
    state: 'IL',
    latitude: '41.843375',
    longitude: '-87.763267',
    timeZoneId: 'America/Chicago',
  },
  '60805': {
    zip: '60805',
    city: 'Evergreen Park',
    state: 'IL',
    latitude: '41.722096',
    longitude: '-87.702938',
    timeZoneId: 'America/Chicago',
  },
  '60827': {
    zip: '60827',
    city: 'Riverdale',
    state: 'IL',
    latitude: '41.64704',
    longitude: '-87.62067',
    timeZoneId: 'America/Chicago',
  },
  '60901': {
    zip: '60901',
    city: 'Kankakee',
    state: 'IL',
    latitude: '41.113633',
    longitude: '-87.860293',
    timeZoneId: 'America/Chicago',
  },
  '60910': {
    zip: '60910',
    city: 'Aroma Park',
    state: 'IL',
    latitude: '41.083343',
    longitude: '-87.805365',
    timeZoneId: 'America/Chicago',
  },
  '60911': {
    zip: '60911',
    city: 'Ashkum',
    state: 'IL',
    latitude: '40.877958',
    longitude: '-87.946738',
    timeZoneId: 'America/Chicago',
  },
  '60912': {
    zip: '60912',
    city: 'Beaverville',
    state: 'IL',
    latitude: '40.947997',
    longitude: '-87.671577',
    timeZoneId: 'America/Chicago',
  },
  '60913': {
    zip: '60913',
    city: 'Bonfield',
    state: 'IL',
    latitude: '41.148125',
    longitude: '-88.056609',
    timeZoneId: 'America/Chicago',
  },
  '60914': {
    zip: '60914',
    city: 'Bourbonnais',
    state: 'IL',
    latitude: '41.161134',
    longitude: '-87.881497',
    timeZoneId: 'America/Chicago',
  },
  '60915': {
    zip: '60915',
    city: 'Bradley',
    state: 'IL',
    latitude: '41.146337',
    longitude: '-87.86369',
    timeZoneId: 'America/Chicago',
  },
  '60917': {
    zip: '60917',
    city: 'Buckingham',
    state: 'IL',
    latitude: '41.044008',
    longitude: '-88.170607',
    timeZoneId: 'America/Chicago',
  },
  '60918': {
    zip: '60918',
    city: 'Buckley',
    state: 'IL',
    latitude: '40.59723',
    longitude: '-88.039569',
    timeZoneId: 'America/Chicago',
  },
  '60919': {
    zip: '60919',
    city: 'Cabery',
    state: 'IL',
    latitude: '40.949053',
    longitude: '-88.151418',
    timeZoneId: 'America/Chicago',
  },
  '60920': {
    zip: '60920',
    city: 'Campus',
    state: 'IL',
    latitude: '41.024026',
    longitude: '-88.30661',
    timeZoneId: 'America/Chicago',
  },
  '60921': {
    zip: '60921',
    city: 'Chatsworth',
    state: 'IL',
    latitude: '40.752055',
    longitude: '-88.291202',
    timeZoneId: 'America/Chicago',
  },
  '60922': {
    zip: '60922',
    city: 'Chebanse',
    state: 'IL',
    latitude: '41.006701',
    longitude: '-87.909574',
    timeZoneId: 'America/Chicago',
  },
  '60924': {
    zip: '60924',
    city: 'Cissna Park',
    state: 'IL',
    latitude: '40.568326',
    longitude: '-87.896441',
    timeZoneId: 'America/Chicago',
  },
  '60926': {
    zip: '60926',
    city: 'Claytonville',
    state: 'IL',
    latitude: '40.569513',
    longitude: '-87.823184',
    timeZoneId: 'America/Chicago',
  },
  '60927': {
    zip: '60927',
    city: 'Clifton',
    state: 'IL',
    latitude: '40.932602',
    longitude: '-87.935467',
    timeZoneId: 'America/Chicago',
  },
  '60928': {
    zip: '60928',
    city: 'Crescent City',
    state: 'IL',
    latitude: '40.770708',
    longitude: '-87.856446',
    timeZoneId: 'America/Chicago',
  },
  '60929': {
    zip: '60929',
    city: 'Cullom',
    state: 'IL',
    latitude: '40.879928',
    longitude: '-88.267031',
    timeZoneId: 'America/Chicago',
  },
  '60930': {
    zip: '60930',
    city: 'Danforth',
    state: 'IL',
    latitude: '40.824845',
    longitude: '-87.981206',
    timeZoneId: 'America/Chicago',
  },
  '60931': {
    zip: '60931',
    city: 'Donovan',
    state: 'IL',
    latitude: '40.88716',
    longitude: '-87.614177',
    timeZoneId: 'America/Chicago',
  },
  '60932': {
    zip: '60932',
    city: 'East Lynn',
    state: 'IL',
    latitude: '40.217614',
    longitude: '-87.594217',
    timeZoneId: 'America/Chicago',
  },
  '60933': {
    zip: '60933',
    city: 'Elliott',
    state: 'IL',
    latitude: '40.463544',
    longitude: '-88.274723',
    timeZoneId: 'America/Chicago',
  },
  '60934': {
    zip: '60934',
    city: 'Emington',
    state: 'IL',
    latitude: '40.971773',
    longitude: '-88.359505',
    timeZoneId: 'America/Chicago',
  },
  '60935': {
    zip: '60935',
    city: 'Essex',
    state: 'IL',
    latitude: '41.177901',
    longitude: '-88.192704',
    timeZoneId: 'America/Chicago',
  },
  '60936': {
    zip: '60936',
    city: 'Gibson City',
    state: 'IL',
    latitude: '40.465996',
    longitude: '-88.370237',
    timeZoneId: 'America/Chicago',
  },
  '60938': {
    zip: '60938',
    city: 'Gilman',
    state: 'IL',
    latitude: '40.770329',
    longitude: '-87.987922',
    timeZoneId: 'America/Chicago',
  },
  '60939': {
    zip: '60939',
    city: 'Goodwine',
    state: 'IL',
    latitude: '40.569005',
    longitude: '-87.785909',
    timeZoneId: 'America/Chicago',
  },
  '60940': {
    zip: '60940',
    city: 'Grant Park',
    state: 'IL',
    latitude: '41.239371',
    longitude: '-87.648677',
    timeZoneId: 'America/Chicago',
  },
  '60941': {
    zip: '60941',
    city: 'Herscher',
    state: 'IL',
    latitude: '41.049589',
    longitude: '-88.094294',
    timeZoneId: 'America/Chicago',
  },
  '60942': {
    zip: '60942',
    city: 'Hoopeston',
    state: 'IL',
    latitude: '40.463479',
    longitude: '-87.670731',
    timeZoneId: 'America/Chicago',
  },
  '60944': {
    zip: '60944',
    city: 'Hopkins Park',
    state: 'IL',
    latitude: '41.071609',
    longitude: '-87.61734',
    timeZoneId: 'America/Chicago',
  },
  '60945': {
    zip: '60945',
    city: 'Iroquois',
    state: 'IL',
    latitude: '40.827046',
    longitude: '-87.583107',
    timeZoneId: 'America/Chicago',
  },
  '60946': {
    zip: '60946',
    city: 'Kempton',
    state: 'IL',
    latitude: '40.933757',
    longitude: '-88.236069',
    timeZoneId: 'America/Chicago',
  },
  '60948': {
    zip: '60948',
    city: 'Loda',
    state: 'IL',
    latitude: '40.525158',
    longitude: '-88.094325',
    timeZoneId: 'America/Chicago',
  },
  '60949': {
    zip: '60949',
    city: 'Ludlow',
    state: 'IL',
    latitude: '40.387738',
    longitude: '-88.125579',
    timeZoneId: 'America/Chicago',
  },
  '60950': {
    zip: '60950',
    city: 'Manteno',
    state: 'IL',
    latitude: '41.224823',
    longitude: '-87.837831',
    timeZoneId: 'America/Chicago',
  },
  '60951': {
    zip: '60951',
    city: 'Martinton',
    state: 'IL',
    latitude: '40.913951',
    longitude: '-87.726692',
    timeZoneId: 'America/Chicago',
  },
  '60952': {
    zip: '60952',
    city: 'Melvin',
    state: 'IL',
    latitude: '40.575987',
    longitude: '-88.247029',
    timeZoneId: 'America/Chicago',
  },
  '60953': {
    zip: '60953',
    city: 'Milford',
    state: 'IL',
    latitude: '40.644326',
    longitude: '-87.696895',
    timeZoneId: 'America/Chicago',
  },
  '60954': {
    zip: '60954',
    city: 'Momence',
    state: 'IL',
    latitude: '41.163429',
    longitude: '-87.662302',
    timeZoneId: 'America/Chicago',
  },
  '60955': {
    zip: '60955',
    city: 'Onarga',
    state: 'IL',
    latitude: '40.720202',
    longitude: '-87.994605',
    timeZoneId: 'America/Chicago',
  },
  '60956': {
    zip: '60956',
    city: 'Papineau',
    state: 'IL',
    latitude: '40.969356',
    longitude: '-87.716292',
    timeZoneId: 'America/Chicago',
  },
  '60957': {
    zip: '60957',
    city: 'Paxton',
    state: 'IL',
    latitude: '40.457648',
    longitude: '-88.094231',
    timeZoneId: 'America/Chicago',
  },
  '60958': {
    zip: '60958',
    city: 'Pembroke Township',
    state: 'IL',
    latitude: '41.234742',
    longitude: '-87.635744',
    timeZoneId: 'America/Chicago',
  },
  '60959': {
    zip: '60959',
    city: 'Piper City',
    state: 'IL',
    latitude: '40.759016',
    longitude: '-88.187314',
    timeZoneId: 'America/Chicago',
  },
  '60960': {
    zip: '60960',
    city: 'Rankin',
    state: 'IL',
    latitude: '40.46432',
    longitude: '-87.896604',
    timeZoneId: 'America/Chicago',
  },
  '60961': {
    zip: '60961',
    city: 'Reddick',
    state: 'IL',
    latitude: '41.097063',
    longitude: '-88.250941',
    timeZoneId: 'America/Chicago',
  },
  '60962': {
    zip: '60962',
    city: 'Roberts',
    state: 'IL',
    latitude: '40.615108',
    longitude: '-88.180646',
    timeZoneId: 'America/Chicago',
  },
  '60963': {
    zip: '60963',
    city: 'Rossville',
    state: 'IL',
    latitude: '40.380311',
    longitude: '-87.664396',
    timeZoneId: 'America/Chicago',
  },
  '60964': {
    zip: '60964',
    city: 'Saint Anne',
    state: 'IL',
    latitude: '41.023177',
    longitude: '-87.716807',
    timeZoneId: 'America/Chicago',
  },
  '60966': {
    zip: '60966',
    city: 'Sheldon',
    state: 'IL',
    latitude: '40.773437',
    longitude: '-87.5639',
    timeZoneId: 'America/Chicago',
  },
  '60967': {
    zip: '60967',
    city: 'Stockland',
    state: 'IL',
    latitude: '40.61372',
    longitude: '-87.5952',
    timeZoneId: 'America/Chicago',
  },
  '60968': {
    zip: '60968',
    city: 'Thawville',
    state: 'IL',
    latitude: '40.674254',
    longitude: '-88.115142',
    timeZoneId: 'America/Chicago',
  },
  '60969': {
    zip: '60969',
    city: 'Union Hill',
    state: 'IL',
    latitude: '41.109158',
    longitude: '-88.144731',
    timeZoneId: 'America/Chicago',
  },
  '60970': {
    zip: '60970',
    city: 'Watseka',
    state: 'IL',
    latitude: '40.776658',
    longitude: '-87.739943',
    timeZoneId: 'America/Chicago',
  },
  '60973': {
    zip: '60973',
    city: 'Wellington',
    state: 'IL',
    latitude: '40.539251',
    longitude: '-87.6766',
    timeZoneId: 'America/Chicago',
  },
  '60974': {
    zip: '60974',
    city: 'Woodland',
    state: 'IL',
    latitude: '40.714106',
    longitude: '-87.733104',
    timeZoneId: 'America/Chicago',
  },
  '61001': {
    zip: '61001',
    city: 'Apple River',
    state: 'IL',
    latitude: '42.478132',
    longitude: '-90.106068',
    timeZoneId: 'America/Chicago',
  },
  '61006': {
    zip: '61006',
    city: 'Ashton',
    state: 'IL',
    latitude: '41.848194',
    longitude: '-89.20165',
    timeZoneId: 'America/Chicago',
  },
  '61007': {
    zip: '61007',
    city: 'Baileyville',
    state: 'IL',
    latitude: '42.190432',
    longitude: '-89.593184',
    timeZoneId: 'America/Chicago',
  },
  '61008': {
    zip: '61008',
    city: 'Belvidere',
    state: 'IL',
    latitude: '42.262789',
    longitude: '-88.853441',
    timeZoneId: 'America/Chicago',
  },
  '61010': {
    zip: '61010',
    city: 'Byron',
    state: 'IL',
    latitude: '42.126357',
    longitude: '-89.262545',
    timeZoneId: 'America/Chicago',
  },
  '61011': {
    zip: '61011',
    city: 'Caledonia',
    state: 'IL',
    latitude: '42.387191',
    longitude: '-88.893272',
    timeZoneId: 'America/Chicago',
  },
  '61012': {
    zip: '61012',
    city: 'Capron',
    state: 'IL',
    latitude: '42.406793',
    longitude: '-88.752439',
    timeZoneId: 'America/Chicago',
  },
  '61013': {
    zip: '61013',
    city: 'Cedarville',
    state: 'IL',
    latitude: '42.376654',
    longitude: '-89.635738',
    timeZoneId: 'America/Chicago',
  },
  '61014': {
    zip: '61014',
    city: 'Chadwick',
    state: 'IL',
    latitude: '41.919179',
    longitude: '-89.82951',
    timeZoneId: 'America/Chicago',
  },
  '61015': {
    zip: '61015',
    city: 'Chana',
    state: 'IL',
    latitude: '41.989953',
    longitude: '-89.208021',
    timeZoneId: 'America/Chicago',
  },
  '61016': {
    zip: '61016',
    city: 'Cherry Valley',
    state: 'IL',
    latitude: '42.217093',
    longitude: '-88.965911',
    timeZoneId: 'America/Chicago',
  },
  '61018': {
    zip: '61018',
    city: 'Dakota',
    state: 'IL',
    latitude: '42.405407',
    longitude: '-89.5492',
    timeZoneId: 'America/Chicago',
  },
  '61019': {
    zip: '61019',
    city: 'Davis',
    state: 'IL',
    latitude: '42.440537',
    longitude: '-89.419639',
    timeZoneId: 'America/Chicago',
  },
  '61020': {
    zip: '61020',
    city: 'Davis Junction',
    state: 'IL',
    latitude: '42.103439',
    longitude: '-89.09016',
    timeZoneId: 'America/Chicago',
  },
  '61021': {
    zip: '61021',
    city: 'Dixon',
    state: 'IL',
    latitude: '41.838841',
    longitude: '-89.492572',
    timeZoneId: 'America/Chicago',
  },
  '61024': {
    zip: '61024',
    city: 'Durand',
    state: 'IL',
    latitude: '42.441983',
    longitude: '-89.312535',
    timeZoneId: 'America/Chicago',
  },
  '61025': {
    zip: '61025',
    city: 'East Dubuque',
    state: 'IL',
    latitude: '42.46638',
    longitude: '-90.57102',
    timeZoneId: 'America/Chicago',
  },
  '61027': {
    zip: '61027',
    city: 'Eleroy',
    state: 'IL',
    latitude: '42.339428',
    longitude: '-89.754978',
    timeZoneId: 'America/Chicago',
  },
  '61028': {
    zip: '61028',
    city: 'Elizabeth',
    state: 'IL',
    latitude: '42.309202',
    longitude: '-90.187779',
    timeZoneId: 'America/Chicago',
  },
  '61030': {
    zip: '61030',
    city: 'Forreston',
    state: 'IL',
    latitude: '42.116467',
    longitude: '-89.581432',
    timeZoneId: 'America/Chicago',
  },
  '61031': {
    zip: '61031',
    city: 'Franklin Grove',
    state: 'IL',
    latitude: '41.830924',
    longitude: '-89.306726',
    timeZoneId: 'America/Chicago',
  },
  '61032': {
    zip: '61032',
    city: 'Freeport',
    state: 'IL',
    latitude: '42.302242',
    longitude: '-89.635039',
    timeZoneId: 'America/Chicago',
  },
  '61036': {
    zip: '61036',
    city: 'Galena',
    state: 'IL',
    latitude: '42.408396',
    longitude: '-90.371098',
    timeZoneId: 'America/Chicago',
  },
  '61037': {
    zip: '61037',
    city: 'Galt',
    state: 'IL',
    latitude: '41.788009',
    longitude: '-89.75892',
    timeZoneId: 'America/Chicago',
  },
  '61038': {
    zip: '61038',
    city: 'Garden Prairie',
    state: 'IL',
    latitude: '42.249088',
    longitude: '-88.739224',
    timeZoneId: 'America/Chicago',
  },
  '61039': {
    zip: '61039',
    city: 'German Valley',
    state: 'IL',
    latitude: '42.218936',
    longitude: '-89.465538',
    timeZoneId: 'America/Chicago',
  },
  '61041': {
    zip: '61041',
    city: 'Hanover',
    state: 'IL',
    latitude: '42.27066',
    longitude: '-90.299921',
    timeZoneId: 'America/Chicago',
  },
  '61042': {
    zip: '61042',
    city: 'Harmon',
    state: 'IL',
    latitude: '41.694727',
    longitude: '-89.557402',
    timeZoneId: 'America/Chicago',
  },
  '61043': {
    zip: '61043',
    city: 'Holcomb',
    state: 'IL',
    latitude: '42.066698',
    longitude: '-89.098415',
    timeZoneId: 'America/Chicago',
  },
  '61044': {
    zip: '61044',
    city: 'Kent',
    state: 'IL',
    latitude: '42.322141',
    longitude: '-89.902021',
    timeZoneId: 'America/Chicago',
  },
  '61046': {
    zip: '61046',
    city: 'Lanark',
    state: 'IL',
    latitude: '42.113145',
    longitude: '-89.835553',
    timeZoneId: 'America/Chicago',
  },
  '61047': {
    zip: '61047',
    city: 'Leaf River',
    state: 'IL',
    latitude: '42.16524',
    longitude: '-89.40126',
    timeZoneId: 'America/Chicago',
  },
  '61048': {
    zip: '61048',
    city: 'Lena',
    state: 'IL',
    latitude: '42.383724',
    longitude: '-89.822437',
    timeZoneId: 'America/Chicago',
  },
  '61049': {
    zip: '61049',
    city: 'Lindenwood',
    state: 'IL',
    latitude: '42.049974',
    longitude: '-89.024262',
    timeZoneId: 'America/Chicago',
  },
  '61050': {
    zip: '61050',
    city: 'Mc Connell',
    state: 'IL',
    latitude: '42.439584',
    longitude: '-89.731064',
    timeZoneId: 'America/Chicago',
  },
  '61051': {
    zip: '61051',
    city: 'Milledgeville',
    state: 'IL',
    latitude: '41.962847',
    longitude: '-89.774668',
    timeZoneId: 'America/Chicago',
  },
  '61052': {
    zip: '61052',
    city: 'Monroe Center',
    state: 'IL',
    latitude: '42.107139',
    longitude: '-89.003254',
    timeZoneId: 'America/Chicago',
  },
  '61053': {
    zip: '61053',
    city: 'Mount Carroll',
    state: 'IL',
    latitude: '42.098801',
    longitude: '-89.975105',
    timeZoneId: 'America/Chicago',
  },
  '61054': {
    zip: '61054',
    city: 'Mount Morris',
    state: 'IL',
    latitude: '42.052377',
    longitude: '-89.432066',
    timeZoneId: 'America/Chicago',
  },
  '61057': {
    zip: '61057',
    city: 'Nachusa',
    state: 'IL',
    latitude: '41.829658',
    longitude: '-89.391748',
    timeZoneId: 'America/Chicago',
  },
  '61059': {
    zip: '61059',
    city: 'Nora',
    state: 'IL',
    latitude: '42.457286',
    longitude: '-89.943451',
    timeZoneId: 'America/Chicago',
  },
  '61060': {
    zip: '61060',
    city: 'Orangeville',
    state: 'IL',
    latitude: '42.473749',
    longitude: '-89.636004',
    timeZoneId: 'America/Chicago',
  },
  '61061': {
    zip: '61061',
    city: 'Oregon',
    state: 'IL',
    latitude: '42.002186',
    longitude: '-89.338593',
    timeZoneId: 'America/Chicago',
  },
  '61062': {
    zip: '61062',
    city: 'Pearl City',
    state: 'IL',
    latitude: '42.259707',
    longitude: '-89.829814',
    timeZoneId: 'America/Chicago',
  },
  '61063': {
    zip: '61063',
    city: 'Pecatonica',
    state: 'IL',
    latitude: '42.308534',
    longitude: '-89.332123',
    timeZoneId: 'America/Chicago',
  },
  '61064': {
    zip: '61064',
    city: 'Polo',
    state: 'IL',
    latitude: '41.993531',
    longitude: '-89.587964',
    timeZoneId: 'America/Chicago',
  },
  '61065': {
    zip: '61065',
    city: 'Poplar Grove',
    state: 'IL',
    latitude: '42.380237',
    longitude: '-88.844362',
    timeZoneId: 'America/Chicago',
  },
  '61067': {
    zip: '61067',
    city: 'Ridott',
    state: 'IL',
    latitude: '42.304197',
    longitude: '-89.487101',
    timeZoneId: 'America/Chicago',
  },
  '61068': {
    zip: '61068',
    city: 'Rochelle',
    state: 'IL',
    latitude: '41.94822',
    longitude: '-89.05536',
    timeZoneId: 'America/Chicago',
  },
  '61070': {
    zip: '61070',
    city: 'Rock City',
    state: 'IL',
    latitude: '42.40979',
    longitude: '-89.473497',
    timeZoneId: 'America/Chicago',
  },
  '61071': {
    zip: '61071',
    city: 'Rock Falls',
    state: 'IL',
    latitude: '41.75847',
    longitude: '-89.690648',
    timeZoneId: 'America/Chicago',
  },
  '61072': {
    zip: '61072',
    city: 'Rockton',
    state: 'IL',
    latitude: '42.43812',
    longitude: '-89.14482',
    timeZoneId: 'America/Chicago',
  },
  '61073': {
    zip: '61073',
    city: 'Roscoe',
    state: 'IL',
    latitude: '42.423292',
    longitude: '-88.992945',
    timeZoneId: 'America/Chicago',
  },
  '61074': {
    zip: '61074',
    city: 'Savanna',
    state: 'IL',
    latitude: '42.099549',
    longitude: '-90.124934',
    timeZoneId: 'America/Chicago',
  },
  '61075': {
    zip: '61075',
    city: 'Scales Mound',
    state: 'IL',
    latitude: '42.466454',
    longitude: '-90.253838',
    timeZoneId: 'America/Chicago',
  },
  '61077': {
    zip: '61077',
    city: 'Seward',
    state: 'IL',
    latitude: '42.239655',
    longitude: '-89.355356',
    timeZoneId: 'America/Chicago',
  },
  '61078': {
    zip: '61078',
    city: 'Shannon',
    state: 'IL',
    latitude: '42.147996',
    longitude: '-89.73552',
    timeZoneId: 'America/Chicago',
  },
  '61079': {
    zip: '61079',
    city: 'Shirland',
    state: 'IL',
    latitude: '42.445358',
    longitude: '-89.198015',
    timeZoneId: 'America/Chicago',
  },
  '61080': {
    zip: '61080',
    city: 'South Beloit',
    state: 'IL',
    latitude: '42.47718',
    longitude: '-88.97166',
    timeZoneId: 'America/Chicago',
  },
  '61081': {
    zip: '61081',
    city: 'Sterling',
    state: 'IL',
    latitude: '41.818523',
    longitude: '-89.718838',
    timeZoneId: 'America/Chicago',
  },
  '61084': {
    zip: '61084',
    city: 'Stillman Valley',
    state: 'IL',
    latitude: '42.114831',
    longitude: '-89.191478',
    timeZoneId: 'America/Chicago',
  },
  '61085': {
    zip: '61085',
    city: 'Stockton',
    state: 'IL',
    latitude: '42.33054',
    longitude: '-90.04974',
    timeZoneId: 'America/Chicago',
  },
  '61087': {
    zip: '61087',
    city: 'Warren',
    state: 'IL',
    latitude: '42.489012',
    longitude: '-89.991849',
    timeZoneId: 'America/Chicago',
  },
  '61088': {
    zip: '61088',
    city: 'Winnebago',
    state: 'IL',
    latitude: '42.273946',
    longitude: '-89.250627',
    timeZoneId: 'America/Chicago',
  },
  '61089': {
    zip: '61089',
    city: 'Winslow',
    state: 'IL',
    latitude: '42.470476',
    longitude: '-89.804771',
    timeZoneId: 'America/Chicago',
  },
  '61091': {
    zip: '61091',
    city: 'Woosung',
    state: 'IL',
    latitude: '41.907852',
    longitude: '-89.543238',
    timeZoneId: 'America/Chicago',
  },
  '61101': {
    zip: '61101',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.299194',
    longitude: '-89.130429',
    timeZoneId: 'America/Chicago',
  },
  '61102': {
    zip: '61102',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.253136',
    longitude: '-89.131474',
    timeZoneId: 'America/Chicago',
  },
  '61103': {
    zip: '61103',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.29919',
    longitude: '-89.103195',
    timeZoneId: 'America/Chicago',
  },
  '61104': {
    zip: '61104',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.255647',
    longitude: '-89.081171',
    timeZoneId: 'America/Chicago',
  },
  '61105': {
    zip: '61105',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.267533',
    longitude: '-89.098361',
    timeZoneId: 'America/Chicago',
  },
  '61106': {
    zip: '61106',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.271831',
    longitude: '-89.064619',
    timeZoneId: 'America/Chicago',
  },
  '61107': {
    zip: '61107',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.280904',
    longitude: '-89.027308',
    timeZoneId: 'America/Chicago',
  },
  '61108': {
    zip: '61108',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.251933',
    longitude: '-89.029605',
    timeZoneId: 'America/Chicago',
  },
  '61109': {
    zip: '61109',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.1947',
    longitude: '-89.06268',
    timeZoneId: 'America/Chicago',
  },
  '61110': {
    zip: '61110',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.267481',
    longitude: '-89.081654',
    timeZoneId: 'America/Chicago',
  },
  '61111': {
    zip: '61111',
    city: 'Loves Park',
    state: 'IL',
    latitude: '42.3281',
    longitude: '-89.026827',
    timeZoneId: 'America/Chicago',
  },
  '61112': {
    zip: '61112',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.237664',
    longitude: '-88.978952',
    timeZoneId: 'America/Chicago',
  },
  '61114': {
    zip: '61114',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.307574',
    longitude: '-89.013353',
    timeZoneId: 'America/Chicago',
  },
  '61115': {
    zip: '61115',
    city: 'Machesney Park',
    state: 'IL',
    latitude: '42.354402',
    longitude: '-89.04013',
    timeZoneId: 'America/Chicago',
  },
  '61125': {
    zip: '61125',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.237359',
    longitude: '-89.012382',
    timeZoneId: 'America/Chicago',
  },
  '61126': {
    zip: '61126',
    city: 'Rockford',
    state: 'IL',
    latitude: '42.253632',
    longitude: '-89.017952',
    timeZoneId: 'America/Chicago',
  },
  '61130': {
    zip: '61130',
    city: 'Loves Park',
    state: 'IL',
    latitude: '42.325927',
    longitude: '-89.003941',
    timeZoneId: 'America/Chicago',
  },
  '61131': {
    zip: '61131',
    city: 'Loves Park',
    state: 'IL',
    latitude: '42.351915',
    longitude: '-88.995909',
    timeZoneId: 'America/Chicago',
  },
  '61132': {
    zip: '61132',
    city: 'Loves Park',
    state: 'IL',
    latitude: '42.352184',
    longitude: '-88.996195',
    timeZoneId: 'America/Chicago',
  },
  '61201': {
    zip: '61201',
    city: 'Rock Island',
    state: 'IL',
    latitude: '41.487481',
    longitude: '-90.569937',
    timeZoneId: 'America/Chicago',
  },
  '61204': {
    zip: '61204',
    city: 'Rock Island',
    state: 'IL',
    latitude: '41.490736',
    longitude: '-90.569725',
    timeZoneId: 'America/Chicago',
  },
  '61230': {
    zip: '61230',
    city: 'Albany',
    state: 'IL',
    latitude: '41.766402',
    longitude: '-90.214367',
    timeZoneId: 'America/Chicago',
  },
  '61231': {
    zip: '61231',
    city: 'Aledo',
    state: 'IL',
    latitude: '41.202727',
    longitude: '-90.754088',
    timeZoneId: 'America/Chicago',
  },
  '61232': {
    zip: '61232',
    city: 'Andalusia',
    state: 'IL',
    latitude: '41.436997',
    longitude: '-90.72514',
    timeZoneId: 'America/Chicago',
  },
  '61233': {
    zip: '61233',
    city: 'Andover',
    state: 'IL',
    latitude: '41.294778',
    longitude: '-90.288072',
    timeZoneId: 'America/Chicago',
  },
  '61234': {
    zip: '61234',
    city: 'Annawan',
    state: 'IL',
    latitude: '41.397338',
    longitude: '-89.909448',
    timeZoneId: 'America/Chicago',
  },
  '61235': {
    zip: '61235',
    city: 'Atkinson',
    state: 'IL',
    latitude: '41.417664',
    longitude: '-90.016623',
    timeZoneId: 'America/Chicago',
  },
  '61236': {
    zip: '61236',
    city: 'Barstow',
    state: 'IL',
    latitude: '41.51689',
    longitude: '-90.371243',
    timeZoneId: 'America/Chicago',
  },
  '61237': {
    zip: '61237',
    city: 'Buffalo Prairie',
    state: 'IL',
    latitude: '41.384604',
    longitude: '-90.911022',
    timeZoneId: 'America/Chicago',
  },
  '61238': {
    zip: '61238',
    city: 'Cambridge',
    state: 'IL',
    latitude: '41.305313',
    longitude: '-90.190486',
    timeZoneId: 'America/Chicago',
  },
  '61239': {
    zip: '61239',
    city: 'Carbon Cliff',
    state: 'IL',
    latitude: '41.494663',
    longitude: '-90.390578',
    timeZoneId: 'America/Chicago',
  },
  '61240': {
    zip: '61240',
    city: 'Coal Valley',
    state: 'IL',
    latitude: '41.41044',
    longitude: '-90.47592',
    timeZoneId: 'America/Chicago',
  },
  '61241': {
    zip: '61241',
    city: 'Colona',
    state: 'IL',
    latitude: '41.481385',
    longitude: '-90.329909',
    timeZoneId: 'America/Chicago',
  },
  '61242': {
    zip: '61242',
    city: 'Cordova',
    state: 'IL',
    latitude: '41.687319',
    longitude: '-90.292709',
    timeZoneId: 'America/Chicago',
  },
  '61243': {
    zip: '61243',
    city: 'Deer Grove',
    state: 'IL',
    latitude: '41.618286',
    longitude: '-89.681883',
    timeZoneId: 'America/Chicago',
  },
  '61244': {
    zip: '61244',
    city: 'East Moline',
    state: 'IL',
    latitude: '41.510378',
    longitude: '-90.418471',
    timeZoneId: 'America/Chicago',
  },
  '61250': {
    zip: '61250',
    city: 'Erie',
    state: 'IL',
    latitude: '41.670529',
    longitude: '-90.096703',
    timeZoneId: 'America/Chicago',
  },
  '61251': {
    zip: '61251',
    city: 'Fenton',
    state: 'IL',
    latitude: '41.729808',
    longitude: '-90.072365',
    timeZoneId: 'America/Chicago',
  },
  '61252': {
    zip: '61252',
    city: 'Fulton',
    state: 'IL',
    latitude: '41.855867',
    longitude: '-90.142898',
    timeZoneId: 'America/Chicago',
  },
  '61254': {
    zip: '61254',
    city: 'Geneseo',
    state: 'IL',
    latitude: '41.457579',
    longitude: '-90.163141',
    timeZoneId: 'America/Chicago',
  },
  '61256': {
    zip: '61256',
    city: 'Hampton',
    state: 'IL',
    latitude: '41.55312',
    longitude: '-90.40592',
    timeZoneId: 'America/Chicago',
  },
  '61257': {
    zip: '61257',
    city: 'Hillsdale',
    state: 'IL',
    latitude: '41.59608',
    longitude: '-90.2307',
    timeZoneId: 'America/Chicago',
  },
  '61258': {
    zip: '61258',
    city: 'Hooppole',
    state: 'IL',
    latitude: '41.524393',
    longitude: '-89.911984',
    timeZoneId: 'America/Chicago',
  },
  '61259': {
    zip: '61259',
    city: 'Illinois City',
    state: 'IL',
    latitude: '41.380938',
    longitude: '-90.89723',
    timeZoneId: 'America/Chicago',
  },
  '61260': {
    zip: '61260',
    city: 'Joy',
    state: 'IL',
    latitude: '41.19659',
    longitude: '-90.881652',
    timeZoneId: 'America/Chicago',
  },
  '61261': {
    zip: '61261',
    city: 'Lyndon',
    state: 'IL',
    latitude: '41.72556',
    longitude: '-89.915984',
    timeZoneId: 'America/Chicago',
  },
  '61262': {
    zip: '61262',
    city: 'Lynn Center',
    state: 'IL',
    latitude: '41.28702',
    longitude: '-90.33252',
    timeZoneId: 'America/Chicago',
  },
  '61263': {
    zip: '61263',
    city: 'Matherville',
    state: 'IL',
    latitude: '41.27508',
    longitude: '-90.60939',
    timeZoneId: 'America/Chicago',
  },
  '61264': {
    zip: '61264',
    city: 'Milan',
    state: 'IL',
    latitude: '41.4039',
    longitude: '-90.57768',
    timeZoneId: 'America/Chicago',
  },
  '61265': {
    zip: '61265',
    city: 'Moline',
    state: 'IL',
    latitude: '41.491529',
    longitude: '-90.495895',
    timeZoneId: 'America/Chicago',
  },
  '61266': {
    zip: '61266',
    city: 'Moline',
    state: 'IL',
    latitude: '41.505858',
    longitude: '-90.51407',
    timeZoneId: 'America/Chicago',
  },
  '61270': {
    zip: '61270',
    city: 'Morrison',
    state: 'IL',
    latitude: '41.821788',
    longitude: '-89.965907',
    timeZoneId: 'America/Chicago',
  },
  '61272': {
    zip: '61272',
    city: 'New Boston',
    state: 'IL',
    latitude: '41.20014',
    longitude: '-90.99168',
    timeZoneId: 'America/Chicago',
  },
  '61273': {
    zip: '61273',
    city: 'Orion',
    state: 'IL',
    latitude: '41.36688',
    longitude: '-90.43314',
    timeZoneId: 'America/Chicago',
  },
  '61274': {
    zip: '61274',
    city: 'Osco',
    state: 'IL',
    latitude: '41.368894',
    longitude: '-90.26059',
    timeZoneId: 'America/Chicago',
  },
  '61275': {
    zip: '61275',
    city: 'Port Byron',
    state: 'IL',
    latitude: '41.598088',
    longitude: '-90.31711',
    timeZoneId: 'America/Chicago',
  },
  '61276': {
    zip: '61276',
    city: 'Preemption',
    state: 'IL',
    latitude: '41.275943',
    longitude: '-90.608721',
    timeZoneId: 'America/Chicago',
  },
  '61277': {
    zip: '61277',
    city: 'Prophetstown',
    state: 'IL',
    latitude: '41.657855',
    longitude: '-89.920163',
    timeZoneId: 'America/Chicago',
  },
  '61278': {
    zip: '61278',
    city: 'Rapids City',
    state: 'IL',
    latitude: '41.579534',
    longitude: '-90.349147',
    timeZoneId: 'America/Chicago',
  },
  '61279': {
    zip: '61279',
    city: 'Reynolds',
    state: 'IL',
    latitude: '41.342093',
    longitude: '-90.705564',
    timeZoneId: 'America/Chicago',
  },
  '61281': {
    zip: '61281',
    city: 'Sherrard',
    state: 'IL',
    latitude: '41.312272',
    longitude: '-90.493734',
    timeZoneId: 'America/Chicago',
  },
  '61282': {
    zip: '61282',
    city: 'Silvis',
    state: 'IL',
    latitude: '41.500274',
    longitude: '-90.416164',
    timeZoneId: 'America/Chicago',
  },
  '61283': {
    zip: '61283',
    city: 'Tampico',
    state: 'IL',
    latitude: '41.637702',
    longitude: '-89.784655',
    timeZoneId: 'America/Chicago',
  },
  '61284': {
    zip: '61284',
    city: 'Taylor Ridge',
    state: 'IL',
    latitude: '41.38938',
    longitude: '-90.72516',
    timeZoneId: 'America/Chicago',
  },
  '61285': {
    zip: '61285',
    city: 'Thomson',
    state: 'IL',
    latitude: '41.962948',
    longitude: '-90.103044',
    timeZoneId: 'America/Chicago',
  },
  '61299': {
    zip: '61299',
    city: 'Rock Island',
    state: 'IL',
    latitude: '41.517464',
    longitude: '-90.531148',
    timeZoneId: 'America/Chicago',
  },
  '61301': {
    zip: '61301',
    city: 'La Salle',
    state: 'IL',
    latitude: '41.35563',
    longitude: '-89.094865',
    timeZoneId: 'America/Chicago',
  },
  '61310': {
    zip: '61310',
    city: 'Amboy',
    state: 'IL',
    latitude: '41.704186',
    longitude: '-89.342017',
    timeZoneId: 'America/Chicago',
  },
  '61311': {
    zip: '61311',
    city: 'Ancona',
    state: 'IL',
    latitude: '41.05098',
    longitude: '-88.930999',
    timeZoneId: 'America/Chicago',
  },
  '61312': {
    zip: '61312',
    city: 'Arlington',
    state: 'IL',
    latitude: '41.451376',
    longitude: '-89.221462',
    timeZoneId: 'America/Chicago',
  },
  '61313': {
    zip: '61313',
    city: 'Blackstone',
    state: 'IL',
    latitude: '41.063846',
    longitude: '-88.642597',
    timeZoneId: 'America/Chicago',
  },
  '61314': {
    zip: '61314',
    city: 'Buda',
    state: 'IL',
    latitude: '41.325459',
    longitude: '-89.680873',
    timeZoneId: 'America/Chicago',
  },
  '61315': {
    zip: '61315',
    city: 'Bureau',
    state: 'IL',
    latitude: '41.286868',
    longitude: '-89.369862',
    timeZoneId: 'America/Chicago',
  },
  '61316': {
    zip: '61316',
    city: 'Cedar Point',
    state: 'IL',
    latitude: '41.261982',
    longitude: '-89.126798',
    timeZoneId: 'America/Chicago',
  },
  '61317': {
    zip: '61317',
    city: 'Cherry',
    state: 'IL',
    latitude: '41.427679',
    longitude: '-89.210064',
    timeZoneId: 'America/Chicago',
  },
  '61318': {
    zip: '61318',
    city: 'Compton',
    state: 'IL',
    latitude: '41.699592',
    longitude: '-89.081857',
    timeZoneId: 'America/Chicago',
  },
  '61319': {
    zip: '61319',
    city: 'Cornell',
    state: 'IL',
    latitude: '40.989667',
    longitude: '-88.729988',
    timeZoneId: 'America/Chicago',
  },
  '61320': {
    zip: '61320',
    city: 'Dalzell',
    state: 'IL',
    latitude: '41.35891',
    longitude: '-89.17708',
    timeZoneId: 'America/Chicago',
  },
  '61321': {
    zip: '61321',
    city: 'Dana',
    state: 'IL',
    latitude: '40.979794',
    longitude: '-88.965342',
    timeZoneId: 'America/Chicago',
  },
  '61322': {
    zip: '61322',
    city: 'Depue',
    state: 'IL',
    latitude: '41.325484',
    longitude: '-89.303078',
    timeZoneId: 'America/Chicago',
  },
  '61323': {
    zip: '61323',
    city: 'Dover',
    state: 'IL',
    latitude: '41.363716',
    longitude: '-89.39857',
    timeZoneId: 'America/Chicago',
  },
  '61324': {
    zip: '61324',
    city: 'Eldena',
    state: 'IL',
    latitude: '41.771678',
    longitude: '-89.413115',
    timeZoneId: 'America/Chicago',
  },
  '61325': {
    zip: '61325',
    city: 'Grand Ridge',
    state: 'IL',
    latitude: '41.22942',
    longitude: '-88.79082',
    timeZoneId: 'America/Chicago',
  },
  '61326': {
    zip: '61326',
    city: 'Granville',
    state: 'IL',
    latitude: '41.262196',
    longitude: '-89.232755',
    timeZoneId: 'America/Chicago',
  },
  '61327': {
    zip: '61327',
    city: 'Hennepin',
    state: 'IL',
    latitude: '41.252075',
    longitude: '-89.337202',
    timeZoneId: 'America/Chicago',
  },
  '61328': {
    zip: '61328',
    city: 'Kasbeer',
    state: 'IL',
    latitude: '41.507221',
    longitude: '-89.465243',
    timeZoneId: 'America/Chicago',
  },
  '61329': {
    zip: '61329',
    city: 'Ladd',
    state: 'IL',
    latitude: '41.378377',
    longitude: '-89.217491',
    timeZoneId: 'America/Chicago',
  },
  '61330': {
    zip: '61330',
    city: 'La Moille',
    state: 'IL',
    latitude: '41.530997',
    longitude: '-89.280508',
    timeZoneId: 'America/Chicago',
  },
  '61331': {
    zip: '61331',
    city: 'Lee Center',
    state: 'IL',
    latitude: '41.748893',
    longitude: '-89.27534',
    timeZoneId: 'America/Chicago',
  },
  '61332': {
    zip: '61332',
    city: 'Leonore',
    state: 'IL',
    latitude: '41.191149',
    longitude: '-88.982226',
    timeZoneId: 'America/Chicago',
  },
  '61333': {
    zip: '61333',
    city: 'Long Point',
    state: 'IL',
    latitude: '41.003788',
    longitude: '-88.89531',
    timeZoneId: 'America/Chicago',
  },
  '61334': {
    zip: '61334',
    city: 'Lostant',
    state: 'IL',
    latitude: '41.141825',
    longitude: '-89.08067',
    timeZoneId: 'America/Chicago',
  },
  '61335': {
    zip: '61335',
    city: 'Mc Nabb',
    state: 'IL',
    latitude: '41.177164',
    longitude: '-89.208757',
    timeZoneId: 'America/Chicago',
  },
  '61336': {
    zip: '61336',
    city: 'Magnolia',
    state: 'IL',
    latitude: '41.122586',
    longitude: '-89.24964',
    timeZoneId: 'America/Chicago',
  },
  '61337': {
    zip: '61337',
    city: 'Malden',
    state: 'IL',
    latitude: '41.425406',
    longitude: '-89.368028',
    timeZoneId: 'America/Chicago',
  },
  '61338': {
    zip: '61338',
    city: 'Manlius',
    state: 'IL',
    latitude: '41.455833',
    longitude: '-89.66786',
    timeZoneId: 'America/Chicago',
  },
  '61340': {
    zip: '61340',
    city: 'Mark',
    state: 'IL',
    latitude: '41.263937',
    longitude: '-89.24768',
    timeZoneId: 'America/Chicago',
  },
  '61341': {
    zip: '61341',
    city: 'Marseilles',
    state: 'IL',
    latitude: '41.330121',
    longitude: '-88.70834',
    timeZoneId: 'America/Chicago',
  },
  '61342': {
    zip: '61342',
    city: 'Mendota',
    state: 'IL',
    latitude: '41.540805',
    longitude: '-89.106262',
    timeZoneId: 'America/Chicago',
  },
  '61344': {
    zip: '61344',
    city: 'Mineral',
    state: 'IL',
    latitude: '41.383083',
    longitude: '-89.840185',
    timeZoneId: 'America/Chicago',
  },
  '61345': {
    zip: '61345',
    city: 'Neponset',
    state: 'IL',
    latitude: '41.30107',
    longitude: '-89.795298',
    timeZoneId: 'America/Chicago',
  },
  '61346': {
    zip: '61346',
    city: 'New Bedford',
    state: 'IL',
    latitude: '41.537255',
    longitude: '-89.755983',
    timeZoneId: 'America/Chicago',
  },
  '61348': {
    zip: '61348',
    city: 'Oglesby',
    state: 'IL',
    latitude: '41.291493',
    longitude: '-89.048588',
    timeZoneId: 'America/Chicago',
  },
  '61349': {
    zip: '61349',
    city: 'Ohio',
    state: 'IL',
    latitude: '41.602605',
    longitude: '-89.452512',
    timeZoneId: 'America/Chicago',
  },
  '61350': {
    zip: '61350',
    city: 'Ottawa',
    state: 'IL',
    latitude: '41.333611',
    longitude: '-88.878056',
    timeZoneId: 'America/Chicago',
  },
  '61353': {
    zip: '61353',
    city: 'Paw Paw',
    state: 'IL',
    latitude: '41.691444',
    longitude: '-88.994013',
    timeZoneId: 'America/Chicago',
  },
  '61354': {
    zip: '61354',
    city: 'Peru',
    state: 'IL',
    latitude: '41.333179',
    longitude: '-89.12888',
    timeZoneId: 'America/Chicago',
  },
  '61356': {
    zip: '61356',
    city: 'Princeton',
    state: 'IL',
    latitude: '41.371332',
    longitude: '-89.463511',
    timeZoneId: 'America/Chicago',
  },
  '61358': {
    zip: '61358',
    city: 'Rutland',
    state: 'IL',
    latitude: '40.98361',
    longitude: '-89.03766',
    timeZoneId: 'America/Chicago',
  },
  '61359': {
    zip: '61359',
    city: 'Seatonville',
    state: 'IL',
    latitude: '41.362889',
    longitude: '-89.276334',
    timeZoneId: 'America/Chicago',
  },
  '61360': {
    zip: '61360',
    city: 'Seneca',
    state: 'IL',
    latitude: '41.329446',
    longitude: '-88.610406',
    timeZoneId: 'America/Chicago',
  },
  '61361': {
    zip: '61361',
    city: 'Sheffield',
    state: 'IL',
    latitude: '41.354486',
    longitude: '-89.738058',
    timeZoneId: 'America/Chicago',
  },
  '61362': {
    zip: '61362',
    city: 'Spring Valley',
    state: 'IL',
    latitude: '41.329992',
    longitude: '-89.220913',
    timeZoneId: 'America/Chicago',
  },
  '61363': {
    zip: '61363',
    city: 'Standard',
    state: 'IL',
    latitude: '41.257659',
    longitude: '-89.177782',
    timeZoneId: 'America/Chicago',
  },
  '61364': {
    zip: '61364',
    city: 'Streator',
    state: 'IL',
    latitude: '41.17326',
    longitude: '-88.82256',
    timeZoneId: 'America/Chicago',
  },
  '61367': {
    zip: '61367',
    city: 'Sublette',
    state: 'IL',
    latitude: '41.627163',
    longitude: '-89.256614',
    timeZoneId: 'America/Chicago',
  },
  '61368': {
    zip: '61368',
    city: 'Tiskilwa',
    state: 'IL',
    latitude: '41.293034',
    longitude: '-89.507464',
    timeZoneId: 'America/Chicago',
  },
  '61369': {
    zip: '61369',
    city: 'Toluca',
    state: 'IL',
    latitude: '41.004179',
    longitude: '-89.126813',
    timeZoneId: 'America/Chicago',
  },
  '61370': {
    zip: '61370',
    city: 'Tonica',
    state: 'IL',
    latitude: '41.22882',
    longitude: '-89.11086',
    timeZoneId: 'America/Chicago',
  },
  '61371': {
    zip: '61371',
    city: 'Triumph',
    state: 'IL',
    latitude: '41.498164',
    longitude: '-89.024348',
    timeZoneId: 'America/Chicago',
  },
  '61372': {
    zip: '61372',
    city: 'Troy Grove',
    state: 'IL',
    latitude: '41.467466',
    longitude: '-89.082529',
    timeZoneId: 'America/Chicago',
  },
  '61373': {
    zip: '61373',
    city: 'Utica',
    state: 'IL',
    latitude: '41.38694',
    longitude: '-88.997371',
    timeZoneId: 'America/Chicago',
  },
  '61374': {
    zip: '61374',
    city: 'Van Orin',
    state: 'IL',
    latitude: '41.550875',
    longitude: '-89.35517',
    timeZoneId: 'America/Chicago',
  },
  '61375': {
    zip: '61375',
    city: 'Varna',
    state: 'IL',
    latitude: '41.064923',
    longitude: '-89.26046',
    timeZoneId: 'America/Chicago',
  },
  '61376': {
    zip: '61376',
    city: 'Walnut',
    state: 'IL',
    latitude: '41.534821',
    longitude: '-89.602442',
    timeZoneId: 'America/Chicago',
  },
  '61377': {
    zip: '61377',
    city: 'Wenona',
    state: 'IL',
    latitude: '41.051074',
    longitude: '-89.052018',
    timeZoneId: 'America/Chicago',
  },
  '61378': {
    zip: '61378',
    city: 'West Brooklyn',
    state: 'IL',
    latitude: '41.719636',
    longitude: '-89.16341',
    timeZoneId: 'America/Chicago',
  },
  '61379': {
    zip: '61379',
    city: 'Wyanet',
    state: 'IL',
    latitude: '41.363337',
    longitude: '-89.578893',
    timeZoneId: 'America/Chicago',
  },
  '61401': {
    zip: '61401',
    city: 'Galesburg',
    state: 'IL',
    latitude: '40.949094',
    longitude: '-90.36929',
    timeZoneId: 'America/Chicago',
  },
  '61402': {
    zip: '61402',
    city: 'Galesburg',
    state: 'IL',
    latitude: '40.948927',
    longitude: '-90.368798',
    timeZoneId: 'America/Chicago',
  },
  '61410': {
    zip: '61410',
    city: 'Abingdon',
    state: 'IL',
    latitude: '40.802476',
    longitude: '-90.401919',
    timeZoneId: 'America/Chicago',
  },
  '61411': {
    zip: '61411',
    city: 'Adair',
    state: 'IL',
    latitude: '40.294685',
    longitude: '-90.423851',
    timeZoneId: 'America/Chicago',
  },
  '61412': {
    zip: '61412',
    city: 'Alexis',
    state: 'IL',
    latitude: '41.064856',
    longitude: '-90.557525',
    timeZoneId: 'America/Chicago',
  },
  '61413': {
    zip: '61413',
    city: 'Alpha',
    state: 'IL',
    latitude: '41.190339',
    longitude: '-90.374656',
    timeZoneId: 'America/Chicago',
  },
  '61414': {
    zip: '61414',
    city: 'Altona',
    state: 'IL',
    latitude: '41.114661',
    longitude: '-90.162252',
    timeZoneId: 'America/Chicago',
  },
  '61415': {
    zip: '61415',
    city: 'Avon',
    state: 'IL',
    latitude: '40.66024',
    longitude: '-90.433285',
    timeZoneId: 'America/Chicago',
  },
  '61416': {
    zip: '61416',
    city: 'Bardolph',
    state: 'IL',
    latitude: '40.497945',
    longitude: '-90.565639',
    timeZoneId: 'America/Chicago',
  },
  '61417': {
    zip: '61417',
    city: 'Berwick',
    state: 'IL',
    latitude: '40.762909',
    longitude: '-90.501186',
    timeZoneId: 'America/Chicago',
  },
  '61418': {
    zip: '61418',
    city: 'Biggsville',
    state: 'IL',
    latitude: '40.853434',
    longitude: '-90.856874',
    timeZoneId: 'America/Chicago',
  },
  '61419': {
    zip: '61419',
    city: 'Bishop Hill',
    state: 'IL',
    latitude: '41.199134',
    longitude: '-90.116322',
    timeZoneId: 'America/Chicago',
  },
  '61420': {
    zip: '61420',
    city: 'Blandinsville',
    state: 'IL',
    latitude: '40.556556',
    longitude: '-90.829213',
    timeZoneId: 'America/Chicago',
  },
  '61421': {
    zip: '61421',
    city: 'Bradford',
    state: 'IL',
    latitude: '41.177575',
    longitude: '-89.653011',
    timeZoneId: 'America/Chicago',
  },
  '61422': {
    zip: '61422',
    city: 'Bushnell',
    state: 'IL',
    latitude: '40.553035',
    longitude: '-90.508826',
    timeZoneId: 'America/Chicago',
  },
  '61423': {
    zip: '61423',
    city: 'Cameron',
    state: 'IL',
    latitude: '40.88056',
    longitude: '-90.498553',
    timeZoneId: 'America/Chicago',
  },
  '61424': {
    zip: '61424',
    city: 'Camp Grove',
    state: 'IL',
    latitude: '41.078897',
    longitude: '-89.632291',
    timeZoneId: 'America/Chicago',
  },
  '61425': {
    zip: '61425',
    city: 'Carman',
    state: 'IL',
    latitude: '40.764786',
    longitude: '-91.060793',
    timeZoneId: 'America/Chicago',
  },
  '61426': {
    zip: '61426',
    city: 'Castleton',
    state: 'IL',
    latitude: '41.117801',
    longitude: '-89.706054',
    timeZoneId: 'America/Chicago',
  },
  '61427': {
    zip: '61427',
    city: 'Cuba',
    state: 'IL',
    latitude: '40.493831',
    longitude: '-90.196232',
    timeZoneId: 'America/Chicago',
  },
  '61428': {
    zip: '61428',
    city: 'Dahinda',
    state: 'IL',
    latitude: '40.963678',
    longitude: '-90.139944',
    timeZoneId: 'America/Chicago',
  },
  '61430': {
    zip: '61430',
    city: 'East Galesburg',
    state: 'IL',
    latitude: '40.948243',
    longitude: '-90.312728',
    timeZoneId: 'America/Chicago',
  },
  '61431': {
    zip: '61431',
    city: 'Ellisville',
    state: 'IL',
    latitude: '40.601583',
    longitude: '-90.281616',
    timeZoneId: 'America/Chicago',
  },
  '61432': {
    zip: '61432',
    city: 'Fairview',
    state: 'IL',
    latitude: '40.632317',
    longitude: '-90.164403',
    timeZoneId: 'America/Chicago',
  },
  '61433': {
    zip: '61433',
    city: 'Fiatt',
    state: 'IL',
    latitude: '40.580115',
    longitude: '-90.156551',
    timeZoneId: 'America/Chicago',
  },
  '61434': {
    zip: '61434',
    city: 'Galva',
    state: 'IL',
    latitude: '41.168134',
    longitude: '-90.041316',
    timeZoneId: 'America/Chicago',
  },
  '61435': {
    zip: '61435',
    city: 'Gerlaw',
    state: 'IL',
    latitude: '41.000364',
    longitude: '-90.61354',
    timeZoneId: 'America/Chicago',
  },
  '61436': {
    zip: '61436',
    city: 'Gilson',
    state: 'IL',
    latitude: '40.875827',
    longitude: '-90.156586',
    timeZoneId: 'America/Chicago',
  },
  '61437': {
    zip: '61437',
    city: 'Gladstone',
    state: 'IL',
    latitude: '40.846849',
    longitude: '-90.982709',
    timeZoneId: 'America/Chicago',
  },
  '61438': {
    zip: '61438',
    city: 'Good Hope',
    state: 'IL',
    latitude: '40.55639',
    longitude: '-90.67136',
    timeZoneId: 'America/Chicago',
  },
  '61439': {
    zip: '61439',
    city: 'Henderson',
    state: 'IL',
    latitude: '41.02427',
    longitude: '-90.356495',
    timeZoneId: 'America/Chicago',
  },
  '61440': {
    zip: '61440',
    city: 'Industry',
    state: 'IL',
    latitude: '40.325478',
    longitude: '-90.604881',
    timeZoneId: 'America/Chicago',
  },
  '61441': {
    zip: '61441',
    city: 'Ipava',
    state: 'IL',
    latitude: '40.365743',
    longitude: '-90.275106',
    timeZoneId: 'America/Chicago',
  },
  '61442': {
    zip: '61442',
    city: 'Keithsburg',
    state: 'IL',
    latitude: '41.096626',
    longitude: '-90.936722',
    timeZoneId: 'America/Chicago',
  },
  '61443': {
    zip: '61443',
    city: 'Kewanee',
    state: 'IL',
    latitude: '41.240215',
    longitude: '-89.930304',
    timeZoneId: 'America/Chicago',
  },
  '61447': {
    zip: '61447',
    city: 'Kirkwood',
    state: 'IL',
    latitude: '40.868978',
    longitude: '-90.754051',
    timeZoneId: 'America/Chicago',
  },
  '61448': {
    zip: '61448',
    city: 'Knoxville',
    state: 'IL',
    latitude: '40.906224',
    longitude: '-90.279802',
    timeZoneId: 'America/Chicago',
  },
  '61449': {
    zip: '61449',
    city: 'La Fayette',
    state: 'IL',
    latitude: '41.110846',
    longitude: '-89.970143',
    timeZoneId: 'America/Chicago',
  },
  '61450': {
    zip: '61450',
    city: 'La Harpe',
    state: 'IL',
    latitude: '40.584041',
    longitude: '-90.968298',
    timeZoneId: 'America/Chicago',
  },
  '61451': {
    zip: '61451',
    city: 'Laura',
    state: 'IL',
    latitude: '40.944652',
    longitude: '-89.961612',
    timeZoneId: 'America/Chicago',
  },
  '61452': {
    zip: '61452',
    city: 'Littleton',
    state: 'IL',
    latitude: '40.240692',
    longitude: '-90.619987',
    timeZoneId: 'America/Chicago',
  },
  '61453': {
    zip: '61453',
    city: 'Little York',
    state: 'IL',
    latitude: '41.009373',
    longitude: '-90.745049',
    timeZoneId: 'America/Chicago',
  },
  '61454': {
    zip: '61454',
    city: 'Lomax',
    state: 'IL',
    latitude: '40.679418',
    longitude: '-91.010819',
    timeZoneId: 'America/Chicago',
  },
  '61455': {
    zip: '61455',
    city: 'Macomb',
    state: 'IL',
    latitude: '40.457219',
    longitude: '-90.674006',
    timeZoneId: 'America/Chicago',
  },
  '61458': {
    zip: '61458',
    city: 'Maquon',
    state: 'IL',
    latitude: '40.794691',
    longitude: '-90.166255',
    timeZoneId: 'America/Chicago',
  },
  '61459': {
    zip: '61459',
    city: 'Marietta',
    state: 'IL',
    latitude: '40.496541',
    longitude: '-90.393466',
    timeZoneId: 'America/Chicago',
  },
  '61460': {
    zip: '61460',
    city: 'Media',
    state: 'IL',
    latitude: '40.770156',
    longitude: '-90.851521',
    timeZoneId: 'America/Chicago',
  },
  '61462': {
    zip: '61462',
    city: 'Monmouth',
    state: 'IL',
    latitude: '40.855',
    longitude: '-90.613056',
    timeZoneId: 'America/Chicago',
  },
  '61465': {
    zip: '61465',
    city: 'New Windsor',
    state: 'IL',
    latitude: '41.20139',
    longitude: '-90.439906',
    timeZoneId: 'America/Chicago',
  },
  '61466': {
    zip: '61466',
    city: 'North Henderson',
    state: 'IL',
    latitude: '41.105464',
    longitude: '-90.476855',
    timeZoneId: 'America/Chicago',
  },
  '61467': {
    zip: '61467',
    city: 'Oneida',
    state: 'IL',
    latitude: '41.074033',
    longitude: '-90.227297',
    timeZoneId: 'America/Chicago',
  },
  '61468': {
    zip: '61468',
    city: 'Ophiem',
    state: 'IL',
    latitude: '41.252242',
    longitude: '-90.382634',
    timeZoneId: 'America/Chicago',
  },
  '61469': {
    zip: '61469',
    city: 'Oquawka',
    state: 'IL',
    latitude: '40.944763',
    longitude: '-90.883365',
    timeZoneId: 'America/Chicago',
  },
  '61470': {
    zip: '61470',
    city: 'Prairie City',
    state: 'IL',
    latitude: '40.606546',
    longitude: '-90.501629',
    timeZoneId: 'America/Chicago',
  },
  '61471': {
    zip: '61471',
    city: 'Raritan',
    state: 'IL',
    latitude: '40.678942',
    longitude: '-90.847713',
    timeZoneId: 'America/Chicago',
  },
  '61472': {
    zip: '61472',
    city: 'Rio',
    state: 'IL',
    latitude: '41.109478',
    longitude: '-90.395822',
    timeZoneId: 'America/Chicago',
  },
  '61473': {
    zip: '61473',
    city: 'Roseville',
    state: 'IL',
    latitude: '40.667778',
    longitude: '-90.654722',
    timeZoneId: 'America/Chicago',
  },
  '61474': {
    zip: '61474',
    city: 'Saint Augustine',
    state: 'IL',
    latitude: '40.734471',
    longitude: '-90.357068',
    timeZoneId: 'America/Chicago',
  },
  '61475': {
    zip: '61475',
    city: 'Sciota',
    state: 'IL',
    latitude: '40.587814',
    longitude: '-90.730089',
    timeZoneId: 'America/Chicago',
  },
  '61476': {
    zip: '61476',
    city: 'Seaton',
    state: 'IL',
    latitude: '41.100178',
    longitude: '-90.797496',
    timeZoneId: 'America/Chicago',
  },
  '61477': {
    zip: '61477',
    city: 'Smithfield',
    state: 'IL',
    latitude: '40.495882',
    longitude: '-90.287939',
    timeZoneId: 'America/Chicago',
  },
  '61478': {
    zip: '61478',
    city: 'Smithshire',
    state: 'IL',
    latitude: '40.722363',
    longitude: '-90.727749',
    timeZoneId: 'America/Chicago',
  },
  '61479': {
    zip: '61479',
    city: 'Speer',
    state: 'IL',
    latitude: '41.017323',
    longitude: '-89.699335',
    timeZoneId: 'America/Chicago',
  },
  '61480': {
    zip: '61480',
    city: 'Stronghurst',
    state: 'IL',
    latitude: '40.746561',
    longitude: '-90.908216',
    timeZoneId: 'America/Chicago',
  },
  '61482': {
    zip: '61482',
    city: 'Table Grove',
    state: 'IL',
    latitude: '40.404264',
    longitude: '-90.393296',
    timeZoneId: 'America/Chicago',
  },
  '61483': {
    zip: '61483',
    city: 'Toulon',
    state: 'IL',
    latitude: '41.092649',
    longitude: '-89.863127',
    timeZoneId: 'America/Chicago',
  },
  '61484': {
    zip: '61484',
    city: 'Vermont',
    state: 'IL',
    latitude: '40.294595',
    longitude: '-90.430475',
    timeZoneId: 'America/Chicago',
  },
  '61485': {
    zip: '61485',
    city: 'Victoria',
    state: 'IL',
    latitude: '41.033668',
    longitude: '-90.097689',
    timeZoneId: 'America/Chicago',
  },
  '61486': {
    zip: '61486',
    city: 'Viola',
    state: 'IL',
    latitude: '41.204504',
    longitude: '-90.587069',
    timeZoneId: 'America/Chicago',
  },
  '61488': {
    zip: '61488',
    city: 'Wataga',
    state: 'IL',
    latitude: '41.023137',
    longitude: '-90.276172',
    timeZoneId: 'America/Chicago',
  },
  '61489': {
    zip: '61489',
    city: 'Williamsfield',
    state: 'IL',
    latitude: '40.887171',
    longitude: '-89.974114',
    timeZoneId: 'America/Chicago',
  },
  '61490': {
    zip: '61490',
    city: 'Woodhull',
    state: 'IL',
    latitude: '41.178623',
    longitude: '-90.315302',
    timeZoneId: 'America/Chicago',
  },
  '61491': {
    zip: '61491',
    city: 'Wyoming',
    state: 'IL',
    latitude: '41.066868',
    longitude: '-89.78896',
    timeZoneId: 'America/Chicago',
  },
  '61501': {
    zip: '61501',
    city: 'Astoria',
    state: 'IL',
    latitude: '40.227008',
    longitude: '-90.353086',
    timeZoneId: 'America/Chicago',
  },
  '61516': {
    zip: '61516',
    city: 'Benson',
    state: 'IL',
    latitude: '40.851271',
    longitude: '-89.119521',
    timeZoneId: 'America/Chicago',
  },
  '61517': {
    zip: '61517',
    city: 'Brimfield',
    state: 'IL',
    latitude: '40.825159',
    longitude: '-89.850815',
    timeZoneId: 'America/Chicago',
  },
  '61519': {
    zip: '61519',
    city: 'Bryant',
    state: 'IL',
    latitude: '40.478281',
    longitude: '-90.069774',
    timeZoneId: 'America/Chicago',
  },
  '61520': {
    zip: '61520',
    city: 'Canton',
    state: 'IL',
    latitude: '40.516944',
    longitude: '-89.908333',
    timeZoneId: 'America/Chicago',
  },
  '61523': {
    zip: '61523',
    city: 'Chillicothe',
    state: 'IL',
    latitude: '40.907358',
    longitude: '-89.516204',
    timeZoneId: 'America/Chicago',
  },
  '61524': {
    zip: '61524',
    city: 'Dunfermline',
    state: 'IL',
    latitude: '40.487157',
    longitude: '-90.020667',
    timeZoneId: 'America/Chicago',
  },
  '61525': {
    zip: '61525',
    city: 'Dunlap',
    state: 'IL',
    latitude: '40.843082',
    longitude: '-89.645087',
    timeZoneId: 'America/Chicago',
  },
  '61526': {
    zip: '61526',
    city: 'Edelstein',
    state: 'IL',
    latitude: '40.9587',
    longitude: '-89.58822',
    timeZoneId: 'America/Chicago',
  },
  '61528': {
    zip: '61528',
    city: 'Edwards',
    state: 'IL',
    latitude: '40.79',
    longitude: '-89.751111',
    timeZoneId: 'America/Chicago',
  },
  '61529': {
    zip: '61529',
    city: 'Elmwood',
    state: 'IL',
    latitude: '40.778521',
    longitude: '-89.950179',
    timeZoneId: 'America/Chicago',
  },
  '61530': {
    zip: '61530',
    city: 'Eureka',
    state: 'IL',
    latitude: '40.716571',
    longitude: '-89.276742',
    timeZoneId: 'America/Chicago',
  },
  '61531': {
    zip: '61531',
    city: 'Farmington',
    state: 'IL',
    latitude: '40.704444',
    longitude: '-90.099444',
    timeZoneId: 'America/Chicago',
  },
  '61532': {
    zip: '61532',
    city: 'Forest City',
    state: 'IL',
    latitude: '40.225226',
    longitude: '-90.006004',
    timeZoneId: 'America/Chicago',
  },
  '61533': {
    zip: '61533',
    city: 'Glasford',
    state: 'IL',
    latitude: '40.591944',
    longitude: '-89.815159',
    timeZoneId: 'America/Chicago',
  },
  '61534': {
    zip: '61534',
    city: 'Green Valley',
    state: 'IL',
    latitude: '40.447032',
    longitude: '-89.651245',
    timeZoneId: 'America/Chicago',
  },
  '61535': {
    zip: '61535',
    city: 'Groveland',
    state: 'IL',
    latitude: '40.593891',
    longitude: '-89.535053',
    timeZoneId: 'America/Chicago',
  },
  '61536': {
    zip: '61536',
    city: 'Hanna City',
    state: 'IL',
    latitude: '40.687089',
    longitude: '-89.792578',
    timeZoneId: 'America/Chicago',
  },
  '61537': {
    zip: '61537',
    city: 'Henry',
    state: 'IL',
    latitude: '41.113512',
    longitude: '-89.3617',
    timeZoneId: 'America/Chicago',
  },
  '61539': {
    zip: '61539',
    city: 'Kingston Mines',
    state: 'IL',
    latitude: '40.557947',
    longitude: '-89.769951',
    timeZoneId: 'America/Chicago',
  },
  '61540': {
    zip: '61540',
    city: 'Lacon',
    state: 'IL',
    latitude: '41.020963',
    longitude: '-89.405973',
    timeZoneId: 'America/Chicago',
  },
  '61541': {
    zip: '61541',
    city: 'La Rose',
    state: 'IL',
    latitude: '40.982691',
    longitude: '-89.238779',
    timeZoneId: 'America/Chicago',
  },
  '61542': {
    zip: '61542',
    city: 'Lewistown',
    state: 'IL',
    latitude: '40.405833',
    longitude: '-90.034444',
    timeZoneId: 'America/Chicago',
  },
  '61543': {
    zip: '61543',
    city: 'Liverpool',
    state: 'IL',
    latitude: '40.425856',
    longitude: '-90.035203',
    timeZoneId: 'America/Chicago',
  },
  '61544': {
    zip: '61544',
    city: 'London Mills',
    state: 'IL',
    latitude: '40.71098',
    longitude: '-90.265154',
    timeZoneId: 'America/Chicago',
  },
  '61545': {
    zip: '61545',
    city: 'Lowpoint',
    state: 'IL',
    latitude: '40.850278',
    longitude: '-89.331944',
    timeZoneId: 'America/Chicago',
  },
  '61546': {
    zip: '61546',
    city: 'Manito',
    state: 'IL',
    latitude: '40.421867',
    longitude: '-89.77968',
    timeZoneId: 'America/Chicago',
  },
  '61547': {
    zip: '61547',
    city: 'Mapleton',
    state: 'IL',
    latitude: '40.613781',
    longitude: '-89.743465',
    timeZoneId: 'America/Chicago',
  },
  '61548': {
    zip: '61548',
    city: 'Metamora',
    state: 'IL',
    latitude: '40.78848',
    longitude: '-89.42718',
    timeZoneId: 'America/Chicago',
  },
  '61550': {
    zip: '61550',
    city: 'Morton',
    state: 'IL',
    latitude: '40.613787',
    longitude: '-89.463814',
    timeZoneId: 'America/Chicago',
  },
  '61552': {
    zip: '61552',
    city: 'Mossville',
    state: 'IL',
    latitude: '40.816227',
    longitude: '-89.565188',
    timeZoneId: 'America/Chicago',
  },
  '61553': {
    zip: '61553',
    city: 'Norris',
    state: 'IL',
    latitude: '40.628846',
    longitude: '-90.031799',
    timeZoneId: 'America/Chicago',
  },
  '61554': {
    zip: '61554',
    city: 'Pekin',
    state: 'IL',
    latitude: '40.56042',
    longitude: '-89.6091',
    timeZoneId: 'America/Chicago',
  },
  '61555': {
    zip: '61555',
    city: 'Pekin',
    state: 'IL',
    latitude: '40.566754',
    longitude: '-89.64293',
    timeZoneId: 'America/Chicago',
  },
  '61558': {
    zip: '61558',
    city: 'Pekin',
    state: 'IL',
    latitude: '40.567474',
    longitude: '-89.638253',
    timeZoneId: 'America/Chicago',
  },
  '61559': {
    zip: '61559',
    city: 'Princeville',
    state: 'IL',
    latitude: '40.89414',
    longitude: '-89.77542',
    timeZoneId: 'America/Chicago',
  },
  '61560': {
    zip: '61560',
    city: 'Putnam',
    state: 'IL',
    latitude: '41.203217',
    longitude: '-89.441455',
    timeZoneId: 'America/Chicago',
  },
  '61561': {
    zip: '61561',
    city: 'Roanoke',
    state: 'IL',
    latitude: '40.797589',
    longitude: '-89.20035',
    timeZoneId: 'America/Chicago',
  },
  '61562': {
    zip: '61562',
    city: 'Rome',
    state: 'IL',
    latitude: '40.880087',
    longitude: '-89.503756',
    timeZoneId: 'America/Chicago',
  },
  '61563': {
    zip: '61563',
    city: 'Saint David',
    state: 'IL',
    latitude: '40.494712',
    longitude: '-90.04675',
    timeZoneId: 'America/Chicago',
  },
  '61564': {
    zip: '61564',
    city: 'South Pekin',
    state: 'IL',
    latitude: '40.493654',
    longitude: '-89.656135',
    timeZoneId: 'America/Chicago',
  },
  '61565': {
    zip: '61565',
    city: 'Sparland',
    state: 'IL',
    latitude: '40.995506',
    longitude: '-89.451602',
    timeZoneId: 'America/Chicago',
  },
  '61567': {
    zip: '61567',
    city: 'Topeka',
    state: 'IL',
    latitude: '40.372662',
    longitude: '-89.936217',
    timeZoneId: 'America/Chicago',
  },
  '61568': {
    zip: '61568',
    city: 'Tremont',
    state: 'IL',
    latitude: '40.525564',
    longitude: '-89.488301',
    timeZoneId: 'America/Chicago',
  },
  '61569': {
    zip: '61569',
    city: 'Trivoli',
    state: 'IL',
    latitude: '40.674929',
    longitude: '-89.900903',
    timeZoneId: 'America/Chicago',
  },
  '61570': {
    zip: '61570',
    city: 'Washburn',
    state: 'IL',
    latitude: '40.919991',
    longitude: '-89.292237',
    timeZoneId: 'America/Chicago',
  },
  '61571': {
    zip: '61571',
    city: 'Washington',
    state: 'IL',
    latitude: '40.694863',
    longitude: '-89.479364',
    timeZoneId: 'America/Chicago',
  },
  '61572': {
    zip: '61572',
    city: 'Yates City',
    state: 'IL',
    latitude: '40.785951',
    longitude: '-90.046044',
    timeZoneId: 'America/Chicago',
  },
  '61601': {
    zip: '61601',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.694012',
    longitude: '-89.59085',
    timeZoneId: 'America/Chicago',
  },
  '61602': {
    zip: '61602',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.682781',
    longitude: '-89.601876',
    timeZoneId: 'America/Chicago',
  },
  '61603': {
    zip: '61603',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.729594',
    longitude: '-89.577784',
    timeZoneId: 'America/Chicago',
  },
  '61604': {
    zip: '61604',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.7',
    longitude: '-89.683333',
    timeZoneId: 'America/Chicago',
  },
  '61605': {
    zip: '61605',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.680845',
    longitude: '-89.627061',
    timeZoneId: 'America/Chicago',
  },
  '61606': {
    zip: '61606',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.698588',
    longitude: '-89.609827',
    timeZoneId: 'America/Chicago',
  },
  '61607': {
    zip: '61607',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.652745',
    longitude: '-89.668522',
    timeZoneId: 'America/Chicago',
  },
  '61610': {
    zip: '61610',
    city: 'Creve Coeur',
    state: 'IL',
    latitude: '40.66011',
    longitude: '-89.578136',
    timeZoneId: 'America/Chicago',
  },
  '61611': {
    zip: '61611',
    city: 'East Peoria',
    state: 'IL',
    latitude: '40.661495',
    longitude: '-89.554256',
    timeZoneId: 'America/Chicago',
  },
  '61612': {
    zip: '61612',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.681656',
    longitude: '-89.594486',
    timeZoneId: 'America/Chicago',
  },
  '61613': {
    zip: '61613',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.742374',
    longitude: '-89.628187',
    timeZoneId: 'America/Chicago',
  },
  '61614': {
    zip: '61614',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.746805',
    longitude: '-89.575662',
    timeZoneId: 'America/Chicago',
  },
  '61615': {
    zip: '61615',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.786669',
    longitude: '-89.632384',
    timeZoneId: 'America/Chicago',
  },
  '61616': {
    zip: '61616',
    city: 'Peoria Heights',
    state: 'IL',
    latitude: '40.75171',
    longitude: '-89.600183',
    timeZoneId: 'America/Chicago',
  },
  '61625': {
    zip: '61625',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.845091',
    longitude: '-89.582017',
    timeZoneId: 'America/Chicago',
  },
  '61629': {
    zip: '61629',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.692997',
    longitude: '-89.593053',
    timeZoneId: 'America/Chicago',
  },
  '61630': {
    zip: '61630',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.682843',
    longitude: '-89.601814',
    timeZoneId: 'America/Chicago',
  },
  '61633': {
    zip: '61633',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.70828',
    longitude: '-89.662648',
    timeZoneId: 'America/Chicago',
  },
  '61634': {
    zip: '61634',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.691385',
    longitude: '-89.588711',
    timeZoneId: 'America/Chicago',
  },
  '61635': {
    zip: '61635',
    city: 'East Peoria',
    state: 'IL',
    latitude: '40.708299',
    longitude: '-89.522384',
    timeZoneId: 'America/Chicago',
  },
  '61636': {
    zip: '61636',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.700195',
    longitude: '-89.598587',
    timeZoneId: 'America/Chicago',
  },
  '61637': {
    zip: '61637',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.699505',
    longitude: '-89.590142',
    timeZoneId: 'America/Chicago',
  },
  '61638': {
    zip: '61638',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.80424',
    longitude: '-89.629708',
    timeZoneId: 'America/Chicago',
  },
  '61639': {
    zip: '61639',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.709663',
    longitude: '-89.5664',
    timeZoneId: 'America/Chicago',
  },
  '61641': {
    zip: '61641',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.653063',
    longitude: '-89.683748',
    timeZoneId: 'America/Chicago',
  },
  '61643': {
    zip: '61643',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.724136',
    longitude: '-89.556863',
    timeZoneId: 'America/Chicago',
  },
  '61650': {
    zip: '61650',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.637504',
    longitude: '-89.496844',
    timeZoneId: 'America/Chicago',
  },
  '61651': {
    zip: '61651',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.679871',
    longitude: '-89.602186',
    timeZoneId: 'America/Chicago',
  },
  '61652': {
    zip: '61652',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.678847',
    longitude: '-89.607597',
    timeZoneId: 'America/Chicago',
  },
  '61653': {
    zip: '61653',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.679996',
    longitude: '-89.604273',
    timeZoneId: 'America/Chicago',
  },
  '61654': {
    zip: '61654',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.677695',
    longitude: '-89.603707',
    timeZoneId: 'America/Chicago',
  },
  '61655': {
    zip: '61655',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.683663',
    longitude: '-89.595192',
    timeZoneId: 'America/Chicago',
  },
  '61656': {
    zip: '61656',
    city: 'Peoria',
    state: 'IL',
    latitude: '40.683509',
    longitude: '-89.606531',
    timeZoneId: 'America/Chicago',
  },
  '61701': {
    zip: '61701',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.47558',
    longitude: '-89.00472',
    timeZoneId: 'America/Chicago',
  },
  '61702': {
    zip: '61702',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.606096',
    longitude: '-88.982513',
    timeZoneId: 'America/Chicago',
  },
  '61704': {
    zip: '61704',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.479447',
    longitude: '-88.950844',
    timeZoneId: 'America/Chicago',
  },
  '61705': {
    zip: '61705',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.479451',
    longitude: '-88.950862',
    timeZoneId: 'America/Chicago',
  },
  '61709': {
    zip: '61709',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.458619',
    longitude: '-88.961117',
    timeZoneId: 'America/Chicago',
  },
  '61710': {
    zip: '61710',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.478286',
    longitude: '-88.952728',
    timeZoneId: 'America/Chicago',
  },
  '61720': {
    zip: '61720',
    city: 'Anchor',
    state: 'IL',
    latitude: '40.526876',
    longitude: '-88.517491',
    timeZoneId: 'America/Chicago',
  },
  '61721': {
    zip: '61721',
    city: 'Armington',
    state: 'IL',
    latitude: '40.341244',
    longitude: '-89.310815',
    timeZoneId: 'America/Chicago',
  },
  '61722': {
    zip: '61722',
    city: 'Arrowsmith',
    state: 'IL',
    latitude: '40.449086',
    longitude: '-88.632987',
    timeZoneId: 'America/Chicago',
  },
  '61723': {
    zip: '61723',
    city: 'Atlanta',
    state: 'IL',
    latitude: '40.261033',
    longitude: '-89.235255',
    timeZoneId: 'America/Chicago',
  },
  '61724': {
    zip: '61724',
    city: 'Bellflower',
    state: 'IL',
    latitude: '40.341562',
    longitude: '-88.515542',
    timeZoneId: 'America/Chicago',
  },
  '61725': {
    zip: '61725',
    city: 'Carlock',
    state: 'IL',
    latitude: '40.583898',
    longitude: '-89.131546',
    timeZoneId: 'America/Chicago',
  },
  '61726': {
    zip: '61726',
    city: 'Chenoa',
    state: 'IL',
    latitude: '40.74372',
    longitude: '-88.71732',
    timeZoneId: 'America/Chicago',
  },
  '61727': {
    zip: '61727',
    city: 'Clinton',
    state: 'IL',
    latitude: '40.153108',
    longitude: '-88.956925',
    timeZoneId: 'America/Chicago',
  },
  '61728': {
    zip: '61728',
    city: 'Colfax',
    state: 'IL',
    latitude: '40.56712',
    longitude: '-88.612858',
    timeZoneId: 'America/Chicago',
  },
  '61729': {
    zip: '61729',
    city: 'Congerville',
    state: 'IL',
    latitude: '40.619023',
    longitude: '-89.207894',
    timeZoneId: 'America/Chicago',
  },
  '61730': {
    zip: '61730',
    city: 'Cooksville',
    state: 'IL',
    latitude: '40.527376',
    longitude: '-88.746598',
    timeZoneId: 'America/Chicago',
  },
  '61731': {
    zip: '61731',
    city: 'Cropsey',
    state: 'IL',
    latitude: '40.595594',
    longitude: '-88.513792',
    timeZoneId: 'America/Chicago',
  },
  '61732': {
    zip: '61732',
    city: 'Danvers',
    state: 'IL',
    latitude: '40.529284',
    longitude: '-89.178783',
    timeZoneId: 'America/Chicago',
  },
  '61733': {
    zip: '61733',
    city: 'Deer Creek',
    state: 'IL',
    latitude: '40.628434',
    longitude: '-89.327437',
    timeZoneId: 'America/Chicago',
  },
  '61734': {
    zip: '61734',
    city: 'Delavan',
    state: 'IL',
    latitude: '40.372475',
    longitude: '-89.541913',
    timeZoneId: 'America/Chicago',
  },
  '61735': {
    zip: '61735',
    city: 'Dewitt',
    state: 'IL',
    latitude: '40.185739',
    longitude: '-88.78786',
    timeZoneId: 'America/Chicago',
  },
  '61736': {
    zip: '61736',
    city: 'Downs',
    state: 'IL',
    latitude: '40.3647',
    longitude: '-88.86822',
    timeZoneId: 'America/Chicago',
  },
  '61737': {
    zip: '61737',
    city: 'Ellsworth',
    state: 'IL',
    latitude: '40.446184',
    longitude: '-88.718903',
    timeZoneId: 'America/Chicago',
  },
  '61738': {
    zip: '61738',
    city: 'El Paso',
    state: 'IL',
    latitude: '40.740699',
    longitude: '-89.012596',
    timeZoneId: 'America/Chicago',
  },
  '61739': {
    zip: '61739',
    city: 'Fairbury',
    state: 'IL',
    latitude: '40.747916',
    longitude: '-88.513741',
    timeZoneId: 'America/Chicago',
  },
  '61740': {
    zip: '61740',
    city: 'Flanagan',
    state: 'IL',
    latitude: '40.876326',
    longitude: '-88.861363',
    timeZoneId: 'America/Chicago',
  },
  '61741': {
    zip: '61741',
    city: 'Forrest',
    state: 'IL',
    latitude: '40.751704',
    longitude: '-88.4122',
    timeZoneId: 'America/Chicago',
  },
  '61742': {
    zip: '61742',
    city: 'Goodfield',
    state: 'IL',
    latitude: '40.632122',
    longitude: '-89.278523',
    timeZoneId: 'America/Chicago',
  },
  '61743': {
    zip: '61743',
    city: 'Graymont',
    state: 'IL',
    latitude: '40.909715',
    longitude: '-88.756025',
    timeZoneId: 'America/Chicago',
  },
  '61744': {
    zip: '61744',
    city: 'Gridley',
    state: 'IL',
    latitude: '40.742252',
    longitude: '-88.880157',
    timeZoneId: 'America/Chicago',
  },
  '61745': {
    zip: '61745',
    city: 'Heyworth',
    state: 'IL',
    latitude: '40.318102',
    longitude: '-88.971388',
    timeZoneId: 'America/Chicago',
  },
  '61747': {
    zip: '61747',
    city: 'Hopedale',
    state: 'IL',
    latitude: '40.42493',
    longitude: '-89.415612',
    timeZoneId: 'America/Chicago',
  },
  '61748': {
    zip: '61748',
    city: 'Hudson',
    state: 'IL',
    latitude: '40.601797',
    longitude: '-88.991264',
    timeZoneId: 'America/Chicago',
  },
  '61749': {
    zip: '61749',
    city: 'Kenney',
    state: 'IL',
    latitude: '40.093731',
    longitude: '-89.081482',
    timeZoneId: 'America/Chicago',
  },
  '61750': {
    zip: '61750',
    city: 'Lane',
    state: 'IL',
    latitude: '40.080356',
    longitude: '-88.846052',
    timeZoneId: 'America/Chicago',
  },
  '61751': {
    zip: '61751',
    city: 'Lawndale',
    state: 'IL',
    latitude: '40.220019',
    longitude: '-89.285105',
    timeZoneId: 'America/Chicago',
  },
  '61752': {
    zip: '61752',
    city: 'Le Roy',
    state: 'IL',
    latitude: '40.346851',
    longitude: '-88.76692',
    timeZoneId: 'America/Chicago',
  },
  '61753': {
    zip: '61753',
    city: 'Lexington',
    state: 'IL',
    latitude: '40.620011',
    longitude: '-88.806012',
    timeZoneId: 'America/Chicago',
  },
  '61754': {
    zip: '61754',
    city: 'Mc Lean',
    state: 'IL',
    latitude: '40.314947',
    longitude: '-89.170467',
    timeZoneId: 'America/Chicago',
  },
  '61755': {
    zip: '61755',
    city: 'Mackinaw',
    state: 'IL',
    latitude: '40.540609',
    longitude: '-89.340753',
    timeZoneId: 'America/Chicago',
  },
  '61756': {
    zip: '61756',
    city: 'Maroa',
    state: 'IL',
    latitude: '40.037374',
    longitude: '-88.958544',
    timeZoneId: 'America/Chicago',
  },
  '61758': {
    zip: '61758',
    city: 'Merna',
    state: 'IL',
    latitude: '40.514258',
    longitude: '-88.826158',
    timeZoneId: 'America/Chicago',
  },
  '61759': {
    zip: '61759',
    city: 'Minier',
    state: 'IL',
    latitude: '40.43211',
    longitude: '-89.310172',
    timeZoneId: 'America/Chicago',
  },
  '61760': {
    zip: '61760',
    city: 'Minonk',
    state: 'IL',
    latitude: '40.89318',
    longitude: '-89.03568',
    timeZoneId: 'America/Chicago',
  },
  '61761': {
    zip: '61761',
    city: 'Normal',
    state: 'IL',
    latitude: '40.510903',
    longitude: '-88.987912',
    timeZoneId: 'America/Chicago',
  },
  '61764': {
    zip: '61764',
    city: 'Pontiac',
    state: 'IL',
    latitude: '40.881129',
    longitude: '-88.634278',
    timeZoneId: 'America/Chicago',
  },
  '61769': {
    zip: '61769',
    city: 'Saunemin',
    state: 'IL',
    latitude: '40.893501',
    longitude: '-88.404022',
    timeZoneId: 'America/Chicago',
  },
  '61770': {
    zip: '61770',
    city: 'Saybrook',
    state: 'IL',
    latitude: '40.425378',
    longitude: '-88.527308',
    timeZoneId: 'America/Chicago',
  },
  '61771': {
    zip: '61771',
    city: 'Secor',
    state: 'IL',
    latitude: '40.740576',
    longitude: '-89.133676',
    timeZoneId: 'America/Chicago',
  },
  '61772': {
    zip: '61772',
    city: 'Shirley',
    state: 'IL',
    latitude: '40.418702',
    longitude: '-89.091981',
    timeZoneId: 'America/Chicago',
  },
  '61773': {
    zip: '61773',
    city: 'Sibley',
    state: 'IL',
    latitude: '40.588171',
    longitude: '-88.380978',
    timeZoneId: 'America/Chicago',
  },
  '61774': {
    zip: '61774',
    city: 'Stanford',
    state: 'IL',
    latitude: '40.435071',
    longitude: '-89.22352',
    timeZoneId: 'America/Chicago',
  },
  '61775': {
    zip: '61775',
    city: 'Strawn',
    state: 'IL',
    latitude: '40.651172',
    longitude: '-88.395957',
    timeZoneId: 'America/Chicago',
  },
  '61776': {
    zip: '61776',
    city: 'Towanda',
    state: 'IL',
    latitude: '40.563669',
    longitude: '-88.896787',
    timeZoneId: 'America/Chicago',
  },
  '61777': {
    zip: '61777',
    city: 'Wapella',
    state: 'IL',
    latitude: '40.220098',
    longitude: '-88.960314',
    timeZoneId: 'America/Chicago',
  },
  '61778': {
    zip: '61778',
    city: 'Waynesville',
    state: 'IL',
    latitude: '40.24257',
    longitude: '-89.126495',
    timeZoneId: 'America/Chicago',
  },
  '61790': {
    zip: '61790',
    city: 'Normal',
    state: 'IL',
    latitude: '40.51729',
    longitude: '-88.999162',
    timeZoneId: 'America/Chicago',
  },
  '61791': {
    zip: '61791',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.474272',
    longitude: '-88.955768',
    timeZoneId: 'America/Chicago',
  },
  '61799': {
    zip: '61799',
    city: 'Bloomington',
    state: 'IL',
    latitude: '40.42847',
    longitude: '-88.98691',
    timeZoneId: 'America/Chicago',
  },
  '61801': {
    zip: '61801',
    city: 'Urbana',
    state: 'IL',
    latitude: '40.111668',
    longitude: '-88.198416',
    timeZoneId: 'America/Chicago',
  },
  '61802': {
    zip: '61802',
    city: 'Urbana',
    state: 'IL',
    latitude: '40.126026',
    longitude: '-88.171678',
    timeZoneId: 'America/Chicago',
  },
  '61803': {
    zip: '61803',
    city: 'Urbana',
    state: 'IL',
    latitude: '40.106393',
    longitude: '-88.21405',
    timeZoneId: 'America/Chicago',
  },
  '61810': {
    zip: '61810',
    city: 'Allerton',
    state: 'IL',
    latitude: '39.912526',
    longitude: '-87.93342',
    timeZoneId: 'America/Chicago',
  },
  '61811': {
    zip: '61811',
    city: 'Alvin',
    state: 'IL',
    latitude: '40.309944',
    longitude: '-87.607061',
    timeZoneId: 'America/Chicago',
  },
  '61812': {
    zip: '61812',
    city: 'Armstrong',
    state: 'IL',
    latitude: '40.218225',
    longitude: '-87.895541',
    timeZoneId: 'America/Chicago',
  },
  '61813': {
    zip: '61813',
    city: 'Bement',
    state: 'IL',
    latitude: '39.923087',
    longitude: '-88.579119',
    timeZoneId: 'America/Chicago',
  },
  '61814': {
    zip: '61814',
    city: 'Bismarck',
    state: 'IL',
    latitude: '40.261951',
    longitude: '-87.610694',
    timeZoneId: 'America/Chicago',
  },
  '61815': {
    zip: '61815',
    city: 'Bondville',
    state: 'IL',
    latitude: '40.111439',
    longitude: '-88.368949',
    timeZoneId: 'America/Chicago',
  },
  '61816': {
    zip: '61816',
    city: 'Broadlands',
    state: 'IL',
    latitude: '39.905173',
    longitude: '-87.994769',
    timeZoneId: 'America/Chicago',
  },
  '61817': {
    zip: '61817',
    city: 'Catlin',
    state: 'IL',
    latitude: '40.068082',
    longitude: '-87.710463',
    timeZoneId: 'America/Chicago',
  },
  '61818': {
    zip: '61818',
    city: 'Cerro Gordo',
    state: 'IL',
    latitude: '39.890715',
    longitude: '-88.73213',
    timeZoneId: 'America/Chicago',
  },
  '61820': {
    zip: '61820',
    city: 'Champaign',
    state: 'IL',
    latitude: '40.109868',
    longitude: '-88.247163',
    timeZoneId: 'America/Chicago',
  },
  '61821': {
    zip: '61821',
    city: 'Champaign',
    state: 'IL',
    latitude: '40.106564',
    longitude: '-88.283856',
    timeZoneId: 'America/Chicago',
  },
  '61822': {
    zip: '61822',
    city: 'Champaign',
    state: 'IL',
    latitude: '40.130816',
    longitude: '-88.309971',
    timeZoneId: 'America/Chicago',
  },
  '61824': {
    zip: '61824',
    city: 'Champaign',
    state: 'IL',
    latitude: '40.120771',
    longitude: '-88.243698',
    timeZoneId: 'America/Chicago',
  },
  '61825': {
    zip: '61825',
    city: 'Champaign',
    state: 'IL',
    latitude: '40.108793',
    longitude: '-88.243278',
    timeZoneId: 'America/Chicago',
  },
  '61826': {
    zip: '61826',
    city: 'Champaign',
    state: 'IL',
    latitude: '40.137064',
    longitude: '-88.278526',
    timeZoneId: 'America/Chicago',
  },
  '61830': {
    zip: '61830',
    city: 'Cisco',
    state: 'IL',
    latitude: '39.985463',
    longitude: '-88.678689',
    timeZoneId: 'America/Chicago',
  },
  '61831': {
    zip: '61831',
    city: 'Collison',
    state: 'IL',
    latitude: '40.215157',
    longitude: '-87.804838',
    timeZoneId: 'America/Chicago',
  },
  '61832': {
    zip: '61832',
    city: 'Danville',
    state: 'IL',
    latitude: '40.136438',
    longitude: '-87.632393',
    timeZoneId: 'America/Chicago',
  },
  '61833': {
    zip: '61833',
    city: 'Tilton',
    state: 'IL',
    latitude: '40.124444',
    longitude: '-87.63',
    timeZoneId: 'America/Chicago',
  },
  '61834': {
    zip: '61834',
    city: 'Danville',
    state: 'IL',
    latitude: '40.130702',
    longitude: '-87.629223',
    timeZoneId: 'America/Chicago',
  },
  '61839': {
    zip: '61839',
    city: 'De Land',
    state: 'IL',
    latitude: '40.122514',
    longitude: '-88.649386',
    timeZoneId: 'America/Chicago',
  },
  '61840': {
    zip: '61840',
    city: 'Dewey',
    state: 'IL',
    latitude: '40.316175',
    longitude: '-88.286483',
    timeZoneId: 'America/Chicago',
  },
  '61841': {
    zip: '61841',
    city: 'Fairmount',
    state: 'IL',
    latitude: '40.046166',
    longitude: '-87.832084',
    timeZoneId: 'America/Chicago',
  },
  '61842': {
    zip: '61842',
    city: 'Farmer City',
    state: 'IL',
    latitude: '40.243766',
    longitude: '-88.640662',
    timeZoneId: 'America/Chicago',
  },
  '61843': {
    zip: '61843',
    city: 'Fisher',
    state: 'IL',
    latitude: '40.319041',
    longitude: '-88.348527',
    timeZoneId: 'America/Chicago',
  },
  '61844': {
    zip: '61844',
    city: 'Fithian',
    state: 'IL',
    latitude: '40.071139',
    longitude: '-87.775363',
    timeZoneId: 'America/Chicago',
  },
  '61845': {
    zip: '61845',
    city: 'Foosland',
    state: 'IL',
    latitude: '40.358156',
    longitude: '-88.428229',
    timeZoneId: 'America/Chicago',
  },
  '61846': {
    zip: '61846',
    city: 'Georgetown',
    state: 'IL',
    latitude: '39.982066',
    longitude: '-87.633758',
    timeZoneId: 'America/Chicago',
  },
  '61847': {
    zip: '61847',
    city: 'Gifford',
    state: 'IL',
    latitude: '40.307841',
    longitude: '-88.020156',
    timeZoneId: 'America/Chicago',
  },
  '61848': {
    zip: '61848',
    city: 'Henning',
    state: 'IL',
    latitude: '40.305422',
    longitude: '-87.699149',
    timeZoneId: 'America/Chicago',
  },
  '61849': {
    zip: '61849',
    city: 'Homer',
    state: 'IL',
    latitude: '40.037249',
    longitude: '-87.963012',
    timeZoneId: 'America/Chicago',
  },
  '61850': {
    zip: '61850',
    city: 'Indianola',
    state: 'IL',
    latitude: '39.92691',
    longitude: '-87.739525',
    timeZoneId: 'America/Chicago',
  },
  '61851': {
    zip: '61851',
    city: 'Ivesdale',
    state: 'IL',
    latitude: '39.944163',
    longitude: '-88.454228',
    timeZoneId: 'America/Chicago',
  },
  '61852': {
    zip: '61852',
    city: 'Longview',
    state: 'IL',
    latitude: '39.885257',
    longitude: '-88.059748',
    timeZoneId: 'America/Chicago',
  },
  '61853': {
    zip: '61853',
    city: 'Mahomet',
    state: 'IL',
    latitude: '40.198333',
    longitude: '-88.389388',
    timeZoneId: 'America/Chicago',
  },
  '61854': {
    zip: '61854',
    city: 'Mansfield',
    state: 'IL',
    latitude: '40.210375',
    longitude: '-88.505603',
    timeZoneId: 'America/Chicago',
  },
  '61855': {
    zip: '61855',
    city: 'Milmine',
    state: 'IL',
    latitude: '39.889725',
    longitude: '-88.641072',
    timeZoneId: 'America/Chicago',
  },
  '61856': {
    zip: '61856',
    city: 'Monticello',
    state: 'IL',
    latitude: '40.021922',
    longitude: '-88.574094',
    timeZoneId: 'America/Chicago',
  },
  '61857': {
    zip: '61857',
    city: 'Muncie',
    state: 'IL',
    latitude: '40.114015',
    longitude: '-87.84395',
    timeZoneId: 'America/Chicago',
  },
  '61858': {
    zip: '61858',
    city: 'Oakwood',
    state: 'IL',
    latitude: '40.107624',
    longitude: '-87.776626',
    timeZoneId: 'America/Chicago',
  },
  '61859': {
    zip: '61859',
    city: 'Ogden',
    state: 'IL',
    latitude: '40.115714',
    longitude: '-87.956868',
    timeZoneId: 'America/Chicago',
  },
  '61862': {
    zip: '61862',
    city: 'Penfield',
    state: 'IL',
    latitude: '40.273045',
    longitude: '-88.042103',
    timeZoneId: 'America/Chicago',
  },
  '61863': {
    zip: '61863',
    city: 'Pesotum',
    state: 'IL',
    latitude: '39.916306',
    longitude: '-88.276128',
    timeZoneId: 'America/Chicago',
  },
  '61864': {
    zip: '61864',
    city: 'Philo',
    state: 'IL',
    latitude: '40.004891',
    longitude: '-88.156932',
    timeZoneId: 'America/Chicago',
  },
  '61865': {
    zip: '61865',
    city: 'Potomac',
    state: 'IL',
    latitude: '40.307563',
    longitude: '-87.802402',
    timeZoneId: 'America/Chicago',
  },
  '61866': {
    zip: '61866',
    city: 'Rantoul',
    state: 'IL',
    latitude: '40.309571',
    longitude: '-88.149445',
    timeZoneId: 'America/Chicago',
  },
  '61870': {
    zip: '61870',
    city: 'Ridge Farm',
    state: 'IL',
    latitude: '39.895436',
    longitude: '-87.651249',
    timeZoneId: 'America/Chicago',
  },
  '61871': {
    zip: '61871',
    city: 'Royal',
    state: 'IL',
    latitude: '40.192021',
    longitude: '-87.968846',
    timeZoneId: 'America/Chicago',
  },
  '61872': {
    zip: '61872',
    city: 'Sadorus',
    state: 'IL',
    latitude: '39.967676',
    longitude: '-88.348051',
    timeZoneId: 'America/Chicago',
  },
  '61873': {
    zip: '61873',
    city: 'Saint Joseph',
    state: 'IL',
    latitude: '40.113531',
    longitude: '-88.044951',
    timeZoneId: 'America/Chicago',
  },
  '61874': {
    zip: '61874',
    city: 'Savoy',
    state: 'IL',
    latitude: '40.063921',
    longitude: '-88.250201',
    timeZoneId: 'America/Chicago',
  },
  '61875': {
    zip: '61875',
    city: 'Seymour',
    state: 'IL',
    latitude: '40.159817',
    longitude: '-88.434465',
    timeZoneId: 'America/Chicago',
  },
  '61876': {
    zip: '61876',
    city: 'Sidell',
    state: 'IL',
    latitude: '39.908281',
    longitude: '-87.824884',
    timeZoneId: 'America/Chicago',
  },
  '61877': {
    zip: '61877',
    city: 'Sidney',
    state: 'IL',
    latitude: '40.023964',
    longitude: '-88.069269',
    timeZoneId: 'America/Chicago',
  },
  '61878': {
    zip: '61878',
    city: 'Thomasboro',
    state: 'IL',
    latitude: '40.242995',
    longitude: '-88.181488',
    timeZoneId: 'America/Chicago',
  },
  '61880': {
    zip: '61880',
    city: 'Tolono',
    state: 'IL',
    latitude: '39.986321',
    longitude: '-88.263536',
    timeZoneId: 'America/Chicago',
  },
  '61882': {
    zip: '61882',
    city: 'Weldon',
    state: 'IL',
    latitude: '40.123992',
    longitude: '-88.750523',
    timeZoneId: 'America/Chicago',
  },
  '61883': {
    zip: '61883',
    city: 'Westville',
    state: 'IL',
    latitude: '40.044517',
    longitude: '-87.640012',
    timeZoneId: 'America/Chicago',
  },
  '61884': {
    zip: '61884',
    city: 'White Heath',
    state: 'IL',
    latitude: '40.101302',
    longitude: '-88.50795',
    timeZoneId: 'America/Chicago',
  },
  '61910': {
    zip: '61910',
    city: 'Arcola',
    state: 'IL',
    latitude: '39.681798',
    longitude: '-88.308311',
    timeZoneId: 'America/Chicago',
  },
  '61911': {
    zip: '61911',
    city: 'Arthur',
    state: 'IL',
    latitude: '39.703333',
    longitude: '-88.391111',
    timeZoneId: 'America/Chicago',
  },
  '61912': {
    zip: '61912',
    city: 'Ashmore',
    state: 'IL',
    latitude: '39.526497',
    longitude: '-88.018046',
    timeZoneId: 'America/Chicago',
  },
  '61913': {
    zip: '61913',
    city: 'Atwood',
    state: 'IL',
    latitude: '39.81432',
    longitude: '-88.43688',
    timeZoneId: 'America/Chicago',
  },
  '61914': {
    zip: '61914',
    city: 'Bethany',
    state: 'IL',
    latitude: '39.64671',
    longitude: '-88.736185',
    timeZoneId: 'America/Chicago',
  },
  '61917': {
    zip: '61917',
    city: 'Brocton',
    state: 'IL',
    latitude: '39.693347',
    longitude: '-87.671412',
    timeZoneId: 'America/Chicago',
  },
  '61919': {
    zip: '61919',
    city: 'Camargo',
    state: 'IL',
    latitude: '39.804516',
    longitude: '-88.12482',
    timeZoneId: 'America/Chicago',
  },
  '61920': {
    zip: '61920',
    city: 'Charleston',
    state: 'IL',
    latitude: '39.604167',
    longitude: '-88.101667',
    timeZoneId: 'America/Chicago',
  },
  '61924': {
    zip: '61924',
    city: 'Chrisman',
    state: 'IL',
    latitude: '39.801374',
    longitude: '-87.674371',
    timeZoneId: 'America/Chicago',
  },
  '61925': {
    zip: '61925',
    city: 'Dalton City',
    state: 'IL',
    latitude: '39.713827',
    longitude: '-88.807278',
    timeZoneId: 'America/Chicago',
  },
  '61928': {
    zip: '61928',
    city: 'Gays',
    state: 'IL',
    latitude: '39.486579',
    longitude: '-88.541705',
    timeZoneId: 'America/Chicago',
  },
  '61929': {
    zip: '61929',
    city: 'Hammond',
    state: 'IL',
    latitude: '39.801837',
    longitude: '-88.565868',
    timeZoneId: 'America/Chicago',
  },
  '61930': {
    zip: '61930',
    city: 'Hindsboro',
    state: 'IL',
    latitude: '39.686989',
    longitude: '-88.133993',
    timeZoneId: 'America/Chicago',
  },
  '61931': {
    zip: '61931',
    city: 'Humboldt',
    state: 'IL',
    latitude: '39.603165',
    longitude: '-88.319354',
    timeZoneId: 'America/Chicago',
  },
  '61932': {
    zip: '61932',
    city: 'Hume',
    state: 'IL',
    latitude: '39.795616',
    longitude: '-87.867417',
    timeZoneId: 'America/Chicago',
  },
  '61933': {
    zip: '61933',
    city: 'Kansas',
    state: 'IL',
    latitude: '39.555979',
    longitude: '-87.938472',
    timeZoneId: 'America/Chicago',
  },
  '61936': {
    zip: '61936',
    city: 'La Place',
    state: 'IL',
    latitude: '39.802335',
    longitude: '-88.713608',
    timeZoneId: 'America/Chicago',
  },
  '61937': {
    zip: '61937',
    city: 'Lovington',
    state: 'IL',
    latitude: '39.711745',
    longitude: '-88.639682',
    timeZoneId: 'America/Chicago',
  },
  '61938': {
    zip: '61938',
    city: 'Mattoon',
    state: 'IL',
    latitude: '39.481399',
    longitude: '-88.374478',
    timeZoneId: 'America/Chicago',
  },
  '61940': {
    zip: '61940',
    city: 'Metcalf',
    state: 'IL',
    latitude: '39.804258',
    longitude: '-87.807725',
    timeZoneId: 'America/Chicago',
  },
  '61941': {
    zip: '61941',
    city: 'Murdock',
    state: 'IL',
    latitude: '39.799954',
    longitude: '-88.080692',
    timeZoneId: 'America/Chicago',
  },
  '61942': {
    zip: '61942',
    city: 'Newman',
    state: 'IL',
    latitude: '39.797928',
    longitude: '-87.988218',
    timeZoneId: 'America/Chicago',
  },
  '61943': {
    zip: '61943',
    city: 'Oakland',
    state: 'IL',
    latitude: '39.655691',
    longitude: '-88.026167',
    timeZoneId: 'America/Chicago',
  },
  '61944': {
    zip: '61944',
    city: 'Paris',
    state: 'IL',
    latitude: '39.613075',
    longitude: '-87.697934',
    timeZoneId: 'America/Chicago',
  },
  '61949': {
    zip: '61949',
    city: 'Redmon',
    state: 'IL',
    latitude: '39.641755',
    longitude: '-87.862916',
    timeZoneId: 'America/Chicago',
  },
  '61951': {
    zip: '61951',
    city: 'Sullivan',
    state: 'IL',
    latitude: '39.597396',
    longitude: '-88.609239',
    timeZoneId: 'America/Chicago',
  },
  '61953': {
    zip: '61953',
    city: 'Tuscola',
    state: 'IL',
    latitude: '39.796141',
    longitude: '-88.282209',
    timeZoneId: 'America/Chicago',
  },
  '61955': {
    zip: '61955',
    city: 'Vermilion',
    state: 'IL',
    latitude: '39.581259',
    longitude: '-87.587427',
    timeZoneId: 'America/Chicago',
  },
  '61956': {
    zip: '61956',
    city: 'Villa Grove',
    state: 'IL',
    latitude: '39.863078',
    longitude: '-88.156017',
    timeZoneId: 'America/Chicago',
  },
  '61957': {
    zip: '61957',
    city: 'Windsor',
    state: 'IL',
    latitude: '39.441537',
    longitude: '-88.600965',
    timeZoneId: 'America/Chicago',
  },
  '62001': {
    zip: '62001',
    city: 'Alhambra',
    state: 'IL',
    latitude: '38.880319',
    longitude: '-89.747069',
    timeZoneId: 'America/Chicago',
  },
  '62002': {
    zip: '62002',
    city: 'Alton',
    state: 'IL',
    latitude: '38.901935',
    longitude: '-90.165499',
    timeZoneId: 'America/Chicago',
  },
  '62006': {
    zip: '62006',
    city: 'Batchtown',
    state: 'IL',
    latitude: '39.08714',
    longitude: '-90.668661',
    timeZoneId: 'America/Chicago',
  },
  '62009': {
    zip: '62009',
    city: 'Benld',
    state: 'IL',
    latitude: '39.091339',
    longitude: '-89.805938',
    timeZoneId: 'America/Chicago',
  },
  '62010': {
    zip: '62010',
    city: 'Bethalto',
    state: 'IL',
    latitude: '38.907799',
    longitude: '-90.0444',
    timeZoneId: 'America/Chicago',
  },
  '62011': {
    zip: '62011',
    city: 'Bingham',
    state: 'IL',
    latitude: '39.151303',
    longitude: '-89.197692',
    timeZoneId: 'America/Chicago',
  },
  '62012': {
    zip: '62012',
    city: 'Brighton',
    state: 'IL',
    latitude: '39.036979',
    longitude: '-90.137327',
    timeZoneId: 'America/Chicago',
  },
  '62013': {
    zip: '62013',
    city: 'Brussels',
    state: 'IL',
    latitude: '38.96628',
    longitude: '-90.60012',
    timeZoneId: 'America/Chicago',
  },
  '62014': {
    zip: '62014',
    city: 'Bunker Hill',
    state: 'IL',
    latitude: '39.04426',
    longitude: '-89.951789',
    timeZoneId: 'America/Chicago',
  },
  '62015': {
    zip: '62015',
    city: 'Butler',
    state: 'IL',
    latitude: '39.221811',
    longitude: '-89.534923',
    timeZoneId: 'America/Chicago',
  },
  '62016': {
    zip: '62016',
    city: 'Carrollton',
    state: 'IL',
    latitude: '39.299962',
    longitude: '-90.410116',
    timeZoneId: 'America/Chicago',
  },
  '62017': {
    zip: '62017',
    city: 'Coffeen',
    state: 'IL',
    latitude: '39.090493',
    longitude: '-89.394809',
    timeZoneId: 'America/Chicago',
  },
  '62018': {
    zip: '62018',
    city: 'Cottage Hills',
    state: 'IL',
    latitude: '38.910511',
    longitude: '-90.080602',
    timeZoneId: 'America/Chicago',
  },
  '62019': {
    zip: '62019',
    city: 'Donnellson',
    state: 'IL',
    latitude: '39.035736',
    longitude: '-89.476722',
    timeZoneId: 'America/Chicago',
  },
  '62021': {
    zip: '62021',
    city: 'Dorsey',
    state: 'IL',
    latitude: '38.981048',
    longitude: '-89.972493',
    timeZoneId: 'America/Chicago',
  },
  '62022': {
    zip: '62022',
    city: 'Dow',
    state: 'IL',
    latitude: '38.964281',
    longitude: '-90.299373',
    timeZoneId: 'America/Chicago',
  },
  '62023': {
    zip: '62023',
    city: 'Eagarville',
    state: 'IL',
    latitude: '39.10972',
    longitude: '-89.78372',
    timeZoneId: 'America/Chicago',
  },
  '62024': {
    zip: '62024',
    city: 'East Alton',
    state: 'IL',
    latitude: '38.856649',
    longitude: '-90.050801',
    timeZoneId: 'America/Chicago',
  },
  '62025': {
    zip: '62025',
    city: 'Edwardsville',
    state: 'IL',
    latitude: '38.808317',
    longitude: '-89.950081',
    timeZoneId: 'America/Chicago',
  },
  '62026': {
    zip: '62026',
    city: 'Edwardsville',
    state: 'IL',
    latitude: '38.811295',
    longitude: '-89.956641',
    timeZoneId: 'America/Chicago',
  },
  '62027': {
    zip: '62027',
    city: 'Eldred',
    state: 'IL',
    latitude: '39.286541',
    longitude: '-90.553196',
    timeZoneId: 'America/Chicago',
  },
  '62028': {
    zip: '62028',
    city: 'Elsah',
    state: 'IL',
    latitude: '38.96412',
    longitude: '-90.33192',
    timeZoneId: 'America/Chicago',
  },
  '62030': {
    zip: '62030',
    city: 'Fidelity',
    state: 'IL',
    latitude: '39.128225',
    longitude: '-90.195318',
    timeZoneId: 'America/Chicago',
  },
  '62031': {
    zip: '62031',
    city: 'Fieldon',
    state: 'IL',
    latitude: '39.101331',
    longitude: '-90.556238',
    timeZoneId: 'America/Chicago',
  },
  '62032': {
    zip: '62032',
    city: 'Fillmore',
    state: 'IL',
    latitude: '39.103643',
    longitude: '-89.305669',
    timeZoneId: 'America/Chicago',
  },
  '62033': {
    zip: '62033',
    city: 'Gillespie',
    state: 'IL',
    latitude: '39.10734',
    longitude: '-89.84208',
    timeZoneId: 'America/Chicago',
  },
  '62034': {
    zip: '62034',
    city: 'Glen Carbon',
    state: 'IL',
    latitude: '38.76054',
    longitude: '-89.96766',
    timeZoneId: 'America/Chicago',
  },
  '62035': {
    zip: '62035',
    city: 'Godfrey',
    state: 'IL',
    latitude: '38.96167',
    longitude: '-90.217939',
    timeZoneId: 'America/Chicago',
  },
  '62036': {
    zip: '62036',
    city: 'Golden Eagle',
    state: 'IL',
    latitude: '38.890847',
    longitude: '-90.569074',
    timeZoneId: 'America/Chicago',
  },
  '62037': {
    zip: '62037',
    city: 'Grafton',
    state: 'IL',
    latitude: '38.971812',
    longitude: '-90.445426',
    timeZoneId: 'America/Chicago',
  },
  '62040': {
    zip: '62040',
    city: 'Granite City',
    state: 'IL',
    latitude: '38.761356',
    longitude: '-90.096335',
    timeZoneId: 'America/Chicago',
  },
  '62044': {
    zip: '62044',
    city: 'Greenfield',
    state: 'IL',
    latitude: '39.340418',
    longitude: '-90.214125',
    timeZoneId: 'America/Chicago',
  },
  '62045': {
    zip: '62045',
    city: 'Hamburg',
    state: 'IL',
    latitude: '39.213437',
    longitude: '-90.69002',
    timeZoneId: 'America/Chicago',
  },
  '62046': {
    zip: '62046',
    city: 'Hamel',
    state: 'IL',
    latitude: '38.889943',
    longitude: '-89.846942',
    timeZoneId: 'America/Chicago',
  },
  '62047': {
    zip: '62047',
    city: 'Hardin',
    state: 'IL',
    latitude: '39.15332',
    longitude: '-90.623852',
    timeZoneId: 'America/Chicago',
  },
  '62048': {
    zip: '62048',
    city: 'Hartford',
    state: 'IL',
    latitude: '38.834452',
    longitude: '-90.093735',
    timeZoneId: 'America/Chicago',
  },
  '62049': {
    zip: '62049',
    city: 'Hillsboro',
    state: 'IL',
    latitude: '39.155644',
    longitude: '-89.482574',
    timeZoneId: 'America/Chicago',
  },
  '62050': {
    zip: '62050',
    city: 'Hillview',
    state: 'IL',
    latitude: '39.473831',
    longitude: '-90.525724',
    timeZoneId: 'America/Chicago',
  },
  '62051': {
    zip: '62051',
    city: 'Irving',
    state: 'IL',
    latitude: '39.204818',
    longitude: '-89.405456',
    timeZoneId: 'America/Chicago',
  },
  '62052': {
    zip: '62052',
    city: 'Jerseyville',
    state: 'IL',
    latitude: '39.13578',
    longitude: '-90.333',
    timeZoneId: 'America/Chicago',
  },
  '62053': {
    zip: '62053',
    city: 'Kampsville',
    state: 'IL',
    latitude: '39.323201',
    longitude: '-90.645352',
    timeZoneId: 'America/Chicago',
  },
  '62054': {
    zip: '62054',
    city: 'Kane',
    state: 'IL',
    latitude: '39.217022',
    longitude: '-90.382913',
    timeZoneId: 'America/Chicago',
  },
  '62056': {
    zip: '62056',
    city: 'Litchfield',
    state: 'IL',
    latitude: '39.19038',
    longitude: '-89.63778',
    timeZoneId: 'America/Chicago',
  },
  '62058': {
    zip: '62058',
    city: 'Livingston',
    state: 'IL',
    latitude: '38.967135',
    longitude: '-89.759212',
    timeZoneId: 'America/Chicago',
  },
  '62059': {
    zip: '62059',
    city: 'Lovejoy',
    state: 'IL',
    latitude: '38.656184',
    longitude: '-90.166082',
    timeZoneId: 'America/Chicago',
  },
  '62060': {
    zip: '62060',
    city: 'Madison',
    state: 'IL',
    latitude: '38.686572',
    longitude: '-90.153079',
    timeZoneId: 'America/Chicago',
  },
  '62061': {
    zip: '62061',
    city: 'Marine',
    state: 'IL',
    latitude: '38.78699',
    longitude: '-89.77891',
    timeZoneId: 'America/Chicago',
  },
  '62062': {
    zip: '62062',
    city: 'Maryville',
    state: 'IL',
    latitude: '38.72365',
    longitude: '-89.95446',
    timeZoneId: 'America/Chicago',
  },
  '62063': {
    zip: '62063',
    city: 'Medora',
    state: 'IL',
    latitude: '39.205251',
    longitude: '-90.16221',
    timeZoneId: 'America/Chicago',
  },
  '62065': {
    zip: '62065',
    city: 'Michael',
    state: 'IL',
    latitude: '39.228894',
    longitude: '-90.630458',
    timeZoneId: 'America/Chicago',
  },
  '62067': {
    zip: '62067',
    city: 'Moro',
    state: 'IL',
    latitude: '38.923668',
    longitude: '-90.008',
    timeZoneId: 'America/Chicago',
  },
  '62069': {
    zip: '62069',
    city: 'Mount Olive',
    state: 'IL',
    latitude: '39.072236',
    longitude: '-89.724462',
    timeZoneId: 'America/Chicago',
  },
  '62070': {
    zip: '62070',
    city: 'Mozier',
    state: 'IL',
    latitude: '39.291007',
    longitude: '-90.72278',
    timeZoneId: 'America/Chicago',
  },
  '62071': {
    zip: '62071',
    city: 'National Stock Yards',
    state: 'IL',
    latitude: '38.646662',
    longitude: '-90.157901',
    timeZoneId: 'America/Chicago',
  },
  '62074': {
    zip: '62074',
    city: 'New Douglas',
    state: 'IL',
    latitude: '38.966208',
    longitude: '-89.691628',
    timeZoneId: 'America/Chicago',
  },
  '62075': {
    zip: '62075',
    city: 'Nokomis',
    state: 'IL',
    latitude: '39.28584',
    longitude: '-89.31258',
    timeZoneId: 'America/Chicago',
  },
  '62076': {
    zip: '62076',
    city: 'Ohlman',
    state: 'IL',
    latitude: '39.346133',
    longitude: '-89.220212',
    timeZoneId: 'America/Chicago',
  },
  '62077': {
    zip: '62077',
    city: 'Panama',
    state: 'IL',
    latitude: '39.05',
    longitude: '-89.533333',
    timeZoneId: 'America/Chicago',
  },
  '62078': {
    zip: '62078',
    city: 'Patterson',
    state: 'IL',
    latitude: '39.482705',
    longitude: '-90.481614',
    timeZoneId: 'America/Chicago',
  },
  '62079': {
    zip: '62079',
    city: 'Piasa',
    state: 'IL',
    latitude: '39.130671',
    longitude: '-90.120036',
    timeZoneId: 'America/Chicago',
  },
  '62080': {
    zip: '62080',
    city: 'Ramsey',
    state: 'IL',
    latitude: '39.144853',
    longitude: '-89.109512',
    timeZoneId: 'America/Chicago',
  },
  '62081': {
    zip: '62081',
    city: 'Rockbridge',
    state: 'IL',
    latitude: '39.285885',
    longitude: '-90.270095',
    timeZoneId: 'America/Chicago',
  },
  '62082': {
    zip: '62082',
    city: 'Roodhouse',
    state: 'IL',
    latitude: '39.479516',
    longitude: '-90.373279',
    timeZoneId: 'America/Chicago',
  },
  '62083': {
    zip: '62083',
    city: 'Rosamond',
    state: 'IL',
    latitude: '39.393306',
    longitude: '-89.194206',
    timeZoneId: 'America/Chicago',
  },
  '62084': {
    zip: '62084',
    city: 'Roxana',
    state: 'IL',
    latitude: '38.847419',
    longitude: '-90.078484',
    timeZoneId: 'America/Chicago',
  },
  '62085': {
    zip: '62085',
    city: 'Sawyerville',
    state: 'IL',
    latitude: '39.075347',
    longitude: '-89.802966',
    timeZoneId: 'America/Chicago',
  },
  '62086': {
    zip: '62086',
    city: 'Sorento',
    state: 'IL',
    latitude: '38.97732',
    longitude: '-89.6001',
    timeZoneId: 'America/Chicago',
  },
  '62087': {
    zip: '62087',
    city: 'South Roxana',
    state: 'IL',
    latitude: '38.828197',
    longitude: '-90.056705',
    timeZoneId: 'America/Chicago',
  },
  '62088': {
    zip: '62088',
    city: 'Staunton',
    state: 'IL',
    latitude: '39.011425',
    longitude: '-89.78707',
    timeZoneId: 'America/Chicago',
  },
  '62089': {
    zip: '62089',
    city: 'Taylor Springs',
    state: 'IL',
    latitude: '39.130995',
    longitude: '-89.494582',
    timeZoneId: 'America/Chicago',
  },
  '62090': {
    zip: '62090',
    city: 'Venice',
    state: 'IL',
    latitude: '38.668857',
    longitude: '-90.167405',
    timeZoneId: 'America/Chicago',
  },
  '62091': {
    zip: '62091',
    city: 'Walshville',
    state: 'IL',
    latitude: '39.042917',
    longitude: '-89.643014',
    timeZoneId: 'America/Chicago',
  },
  '62092': {
    zip: '62092',
    city: 'White Hall',
    state: 'IL',
    latitude: '39.440324',
    longitude: '-90.402625',
    timeZoneId: 'America/Chicago',
  },
  '62093': {
    zip: '62093',
    city: 'Wilsonville',
    state: 'IL',
    latitude: '39.066788',
    longitude: '-89.853304',
    timeZoneId: 'America/Chicago',
  },
  '62094': {
    zip: '62094',
    city: 'Witt',
    state: 'IL',
    latitude: '39.258265',
    longitude: '-89.347637',
    timeZoneId: 'America/Chicago',
  },
  '62095': {
    zip: '62095',
    city: 'Wood River',
    state: 'IL',
    latitude: '38.861219',
    longitude: '-90.089817',
    timeZoneId: 'America/Chicago',
  },
  '62097': {
    zip: '62097',
    city: 'Worden',
    state: 'IL',
    latitude: '38.95788',
    longitude: '-89.87004',
    timeZoneId: 'America/Chicago',
  },
  '62098': {
    zip: '62098',
    city: 'Wrights',
    state: 'IL',
    latitude: '39.379455',
    longitude: '-90.29352',
    timeZoneId: 'America/Chicago',
  },
  '62201': {
    zip: '62201',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.628113',
    longitude: '-90.138442',
    timeZoneId: 'America/Chicago',
  },
  '62202': {
    zip: '62202',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.616644',
    longitude: '-90.160951',
    timeZoneId: 'America/Chicago',
  },
  '62203': {
    zip: '62203',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.598771',
    longitude: '-90.076739',
    timeZoneId: 'America/Chicago',
  },
  '62204': {
    zip: '62204',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.633044',
    longitude: '-90.101683',
    timeZoneId: 'America/Chicago',
  },
  '62205': {
    zip: '62205',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.615813',
    longitude: '-90.128903',
    timeZoneId: 'America/Chicago',
  },
  '62206': {
    zip: '62206',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.561749',
    longitude: '-90.161656',
    timeZoneId: 'America/Chicago',
  },
  '62207': {
    zip: '62207',
    city: 'East Saint Louis',
    state: 'IL',
    latitude: '38.6103',
    longitude: '-90.15618',
    timeZoneId: 'America/Chicago',
  },
  '62208': {
    zip: '62208',
    city: 'Fairview Heights',
    state: 'IL',
    latitude: '38.59966',
    longitude: '-90.009909',
    timeZoneId: 'America/Chicago',
  },
  '62214': {
    zip: '62214',
    city: 'Addieville',
    state: 'IL',
    latitude: '38.381708',
    longitude: '-89.611975',
    timeZoneId: 'America/Chicago',
  },
  '62215': {
    zip: '62215',
    city: 'Albers',
    state: 'IL',
    latitude: '38.51',
    longitude: '-89.619444',
    timeZoneId: 'America/Chicago',
  },
  '62216': {
    zip: '62216',
    city: 'Aviston',
    state: 'IL',
    latitude: '38.611076',
    longitude: '-89.607818',
    timeZoneId: 'America/Chicago',
  },
  '62217': {
    zip: '62217',
    city: 'Baldwin',
    state: 'IL',
    latitude: '38.18516',
    longitude: '-89.845373',
    timeZoneId: 'America/Chicago',
  },
  '62218': {
    zip: '62218',
    city: 'Bartelso',
    state: 'IL',
    latitude: '38.522043',
    longitude: '-89.480239',
    timeZoneId: 'America/Chicago',
  },
  '62219': {
    zip: '62219',
    city: 'Beckemeyer',
    state: 'IL',
    latitude: '38.604637',
    longitude: '-89.435667',
    timeZoneId: 'America/Chicago',
  },
  '62220': {
    zip: '62220',
    city: 'Belleville',
    state: 'IL',
    latitude: '38.525719',
    longitude: '-89.991068',
    timeZoneId: 'America/Chicago',
  },
  '62221': {
    zip: '62221',
    city: 'Belleville',
    state: 'IL',
    latitude: '38.550823',
    longitude: '-89.984437',
    timeZoneId: 'America/Chicago',
  },
  '62222': {
    zip: '62222',
    city: 'Belleville',
    state: 'IL',
    latitude: '38.468453',
    longitude: '-89.981456',
    timeZoneId: 'America/Chicago',
  },
  '62223': {
    zip: '62223',
    city: 'Belleville',
    state: 'IL',
    latitude: '38.535955',
    longitude: '-90.002379',
    timeZoneId: 'America/Chicago',
  },
  '62225': {
    zip: '62225',
    city: 'Scott Air Force Base',
    state: 'IL',
    latitude: '38.539124',
    longitude: '-89.851513',
    timeZoneId: 'America/Chicago',
  },
  '62226': {
    zip: '62226',
    city: 'Belleville',
    state: 'IL',
    latitude: '38.544506',
    longitude: '-90.00082',
    timeZoneId: 'America/Chicago',
  },
  '62230': {
    zip: '62230',
    city: 'Breese',
    state: 'IL',
    latitude: '38.608157',
    longitude: '-89.527489',
    timeZoneId: 'America/Chicago',
  },
  '62231': {
    zip: '62231',
    city: 'Carlyle',
    state: 'IL',
    latitude: '38.60931',
    longitude: '-89.376252',
    timeZoneId: 'America/Chicago',
  },
  '62232': {
    zip: '62232',
    city: 'Caseyville',
    state: 'IL',
    latitude: '38.634957',
    longitude: '-90.012889',
    timeZoneId: 'America/Chicago',
  },
  '62233': {
    zip: '62233',
    city: 'Chester',
    state: 'IL',
    latitude: '37.9236',
    longitude: '-89.81862',
    timeZoneId: 'America/Chicago',
  },
  '62234': {
    zip: '62234',
    city: 'Collinsville',
    state: 'IL',
    latitude: '38.687371',
    longitude: '-89.983306',
    timeZoneId: 'America/Chicago',
  },
  '62236': {
    zip: '62236',
    city: 'Columbia',
    state: 'IL',
    latitude: '38.441727',
    longitude: '-90.205289',
    timeZoneId: 'America/Chicago',
  },
  '62237': {
    zip: '62237',
    city: 'Coulterville',
    state: 'IL',
    latitude: '38.19822',
    longitude: '-89.59374',
    timeZoneId: 'America/Chicago',
  },
  '62238': {
    zip: '62238',
    city: 'Cutler',
    state: 'IL',
    latitude: '37.980409',
    longitude: '-89.362381',
    timeZoneId: 'America/Chicago',
  },
  '62239': {
    zip: '62239',
    city: 'Dupo',
    state: 'IL',
    latitude: '38.523233',
    longitude: '-90.198401',
    timeZoneId: 'America/Chicago',
  },
  '62240': {
    zip: '62240',
    city: 'East Carondelet',
    state: 'IL',
    latitude: '38.525161',
    longitude: '-90.22392',
    timeZoneId: 'America/Chicago',
  },
  '62241': {
    zip: '62241',
    city: 'Ellis Grove',
    state: 'IL',
    latitude: '38.0043',
    longitude: '-89.89876',
    timeZoneId: 'America/Chicago',
  },
  '62242': {
    zip: '62242',
    city: 'Evansville',
    state: 'IL',
    latitude: '38.096033',
    longitude: '-89.906301',
    timeZoneId: 'America/Chicago',
  },
  '62243': {
    zip: '62243',
    city: 'Freeburg',
    state: 'IL',
    latitude: '38.426232',
    longitude: '-89.904712',
    timeZoneId: 'America/Chicago',
  },
  '62244': {
    zip: '62244',
    city: 'Fults',
    state: 'IL',
    latitude: '38.16374',
    longitude: '-90.201178',
    timeZoneId: 'America/Chicago',
  },
  '62245': {
    zip: '62245',
    city: 'Germantown',
    state: 'IL',
    latitude: '38.561065',
    longitude: '-89.582763',
    timeZoneId: 'America/Chicago',
  },
  '62246': {
    zip: '62246',
    city: 'Greenville',
    state: 'IL',
    latitude: '38.888242',
    longitude: '-89.407911',
    timeZoneId: 'America/Chicago',
  },
  '62248': {
    zip: '62248',
    city: 'Hecker',
    state: 'IL',
    latitude: '38.306219',
    longitude: '-89.991457',
    timeZoneId: 'America/Chicago',
  },
  '62249': {
    zip: '62249',
    city: 'Highland',
    state: 'IL',
    latitude: '38.7921',
    longitude: '-89.68758',
    timeZoneId: 'America/Chicago',
  },
  '62250': {
    zip: '62250',
    city: 'Hoffman',
    state: 'IL',
    latitude: '38.540845',
    longitude: '-89.264477',
    timeZoneId: 'America/Chicago',
  },
  '62252': {
    zip: '62252',
    city: 'Huey',
    state: 'IL',
    latitude: '38.604272',
    longitude: '-89.290238',
    timeZoneId: 'America/Chicago',
  },
  '62253': {
    zip: '62253',
    city: 'Keyesport',
    state: 'IL',
    latitude: '38.739872',
    longitude: '-89.312992',
    timeZoneId: 'America/Chicago',
  },
  '62254': {
    zip: '62254',
    city: 'Lebanon',
    state: 'IL',
    latitude: '38.606738',
    longitude: '-89.813433',
    timeZoneId: 'America/Chicago',
  },
  '62255': {
    zip: '62255',
    city: 'Lenzburg',
    state: 'IL',
    latitude: '38.312685',
    longitude: '-89.764727',
    timeZoneId: 'America/Chicago',
  },
  '62256': {
    zip: '62256',
    city: 'Maeystown',
    state: 'IL',
    latitude: '38.233238',
    longitude: '-90.221839',
    timeZoneId: 'America/Chicago',
  },
  '62257': {
    zip: '62257',
    city: 'Marissa',
    state: 'IL',
    latitude: '38.25373',
    longitude: '-89.758689',
    timeZoneId: 'America/Chicago',
  },
  '62258': {
    zip: '62258',
    city: 'Mascoutah',
    state: 'IL',
    latitude: '38.45178',
    longitude: '-89.76102',
    timeZoneId: 'America/Chicago',
  },
  '62259': {
    zip: '62259',
    city: 'Menard',
    state: 'IL',
    latitude: '37.910186',
    longitude: '-89.839804',
    timeZoneId: 'America/Chicago',
  },
  '62260': {
    zip: '62260',
    city: 'Millstadt',
    state: 'IL',
    latitude: '38.463742',
    longitude: '-90.092279',
    timeZoneId: 'America/Chicago',
  },
  '62261': {
    zip: '62261',
    city: 'Modoc',
    state: 'IL',
    latitude: '38.03712',
    longitude: '-90.00738',
    timeZoneId: 'America/Chicago',
  },
  '62262': {
    zip: '62262',
    city: 'Mulberry Grove',
    state: 'IL',
    latitude: '38.92418',
    longitude: '-89.270352',
    timeZoneId: 'America/Chicago',
  },
  '62263': {
    zip: '62263',
    city: 'Nashville',
    state: 'IL',
    latitude: '38.441389',
    longitude: '-89.370278',
    timeZoneId: 'America/Chicago',
  },
  '62264': {
    zip: '62264',
    city: 'New Athens',
    state: 'IL',
    latitude: '38.323227',
    longitude: '-89.876837',
    timeZoneId: 'America/Chicago',
  },
  '62265': {
    zip: '62265',
    city: 'New Baden',
    state: 'IL',
    latitude: '38.497891',
    longitude: '-89.664427',
    timeZoneId: 'America/Chicago',
  },
  '62266': {
    zip: '62266',
    city: 'New Memphis',
    state: 'IL',
    latitude: '38.476425',
    longitude: '-89.676827',
    timeZoneId: 'America/Chicago',
  },
  '62268': {
    zip: '62268',
    city: 'Oakdale',
    state: 'IL',
    latitude: '38.263313',
    longitude: '-89.588808',
    timeZoneId: 'America/Chicago',
  },
  '62269': {
    zip: '62269',
    city: 'O Fallon',
    state: 'IL',
    latitude: '38.562834',
    longitude: '-89.896654',
    timeZoneId: 'America/Chicago',
  },
  '62271': {
    zip: '62271',
    city: 'Okawville',
    state: 'IL',
    latitude: '38.41812',
    longitude: '-89.52462',
    timeZoneId: 'America/Chicago',
  },
  '62272': {
    zip: '62272',
    city: 'Percy',
    state: 'IL',
    latitude: '38.011598',
    longitude: '-89.636794',
    timeZoneId: 'America/Chicago',
  },
  '62273': {
    zip: '62273',
    city: 'Pierron',
    state: 'IL',
    latitude: '38.782644',
    longitude: '-89.565101',
    timeZoneId: 'America/Chicago',
  },
  '62274': {
    zip: '62274',
    city: 'Pinckneyville',
    state: 'IL',
    latitude: '38.078533',
    longitude: '-89.388584',
    timeZoneId: 'America/Chicago',
  },
  '62275': {
    zip: '62275',
    city: 'Pocahontas',
    state: 'IL',
    latitude: '38.770822',
    longitude: '-89.501779',
    timeZoneId: 'America/Chicago',
  },
  '62277': {
    zip: '62277',
    city: 'Prairie du Rocher',
    state: 'IL',
    latitude: '38.082633',
    longitude: '-90.09891',
    timeZoneId: 'America/Chicago',
  },
  '62278': {
    zip: '62278',
    city: 'Red Bud',
    state: 'IL',
    latitude: '38.1792',
    longitude: '-89.9796',
    timeZoneId: 'America/Chicago',
  },
  '62279': {
    zip: '62279',
    city: 'Renault',
    state: 'IL',
    latitude: '38.168555',
    longitude: '-90.090299',
    timeZoneId: 'America/Chicago',
  },
  '62280': {
    zip: '62280',
    city: 'Rockwood',
    state: 'IL',
    latitude: '37.829257',
    longitude: '-89.624565',
    timeZoneId: 'America/Chicago',
  },
  '62281': {
    zip: '62281',
    city: 'Saint Jacob',
    state: 'IL',
    latitude: '38.701425',
    longitude: '-89.784039',
    timeZoneId: 'America/Chicago',
  },
  '62282': {
    zip: '62282',
    city: 'Saint Libory',
    state: 'IL',
    latitude: '38.359282',
    longitude: '-89.712548',
    timeZoneId: 'America/Chicago',
  },
  '62284': {
    zip: '62284',
    city: 'Smithboro',
    state: 'IL',
    latitude: '38.8764',
    longitude: '-89.3238',
    timeZoneId: 'America/Chicago',
  },
  '62285': {
    zip: '62285',
    city: 'Smithton',
    state: 'IL',
    latitude: '38.390956',
    longitude: '-90.011007',
    timeZoneId: 'America/Chicago',
  },
  '62286': {
    zip: '62286',
    city: 'Sparta',
    state: 'IL',
    latitude: '38.15154',
    longitude: '-89.72316',
    timeZoneId: 'America/Chicago',
  },
  '62288': {
    zip: '62288',
    city: 'Steeleville',
    state: 'IL',
    latitude: '38.005855',
    longitude: '-89.658943',
    timeZoneId: 'America/Chicago',
  },
  '62289': {
    zip: '62289',
    city: 'Summerfield',
    state: 'IL',
    latitude: '38.595452',
    longitude: '-89.752268',
    timeZoneId: 'America/Chicago',
  },
  '62292': {
    zip: '62292',
    city: 'Tilden',
    state: 'IL',
    latitude: '38.21215',
    longitude: '-89.683049',
    timeZoneId: 'America/Chicago',
  },
  '62293': {
    zip: '62293',
    city: 'Trenton',
    state: 'IL',
    latitude: '38.607272',
    longitude: '-89.683388',
    timeZoneId: 'America/Chicago',
  },
  '62294': {
    zip: '62294',
    city: 'Troy',
    state: 'IL',
    latitude: '38.7068',
    longitude: '-89.873032',
    timeZoneId: 'America/Chicago',
  },
  '62295': {
    zip: '62295',
    city: 'Valmeyer',
    state: 'IL',
    latitude: '38.280452',
    longitude: '-90.340506',
    timeZoneId: 'America/Chicago',
  },
  '62297': {
    zip: '62297',
    city: 'Walsh',
    state: 'IL',
    latitude: '38.018461',
    longitude: '-89.827173',
    timeZoneId: 'America/Chicago',
  },
  '62298': {
    zip: '62298',
    city: 'Waterloo',
    state: 'IL',
    latitude: '38.33929',
    longitude: '-90.15153',
    timeZoneId: 'America/Chicago',
  },
  '62301': {
    zip: '62301',
    city: 'Quincy',
    state: 'IL',
    latitude: '39.931262',
    longitude: '-91.37972',
    timeZoneId: 'America/Chicago',
  },
  '62305': {
    zip: '62305',
    city: 'Quincy',
    state: 'IL',
    latitude: '39.929831',
    longitude: '-91.304424',
    timeZoneId: 'America/Chicago',
  },
  '62306': {
    zip: '62306',
    city: 'Quincy',
    state: 'IL',
    latitude: '39.932961',
    longitude: '-91.30595',
    timeZoneId: 'America/Chicago',
  },
  '62311': {
    zip: '62311',
    city: 'Augusta',
    state: 'IL',
    latitude: '40.232617',
    longitude: '-90.950322',
    timeZoneId: 'America/Chicago',
  },
  '62312': {
    zip: '62312',
    city: 'Barry',
    state: 'IL',
    latitude: '39.691709',
    longitude: '-91.023573',
    timeZoneId: 'America/Chicago',
  },
  '62313': {
    zip: '62313',
    city: 'Basco',
    state: 'IL',
    latitude: '40.327265',
    longitude: '-91.19795',
    timeZoneId: 'America/Chicago',
  },
  '62314': {
    zip: '62314',
    city: 'Baylis',
    state: 'IL',
    latitude: '39.781675',
    longitude: '-90.85758',
    timeZoneId: 'America/Chicago',
  },
  '62316': {
    zip: '62316',
    city: 'Bowen',
    state: 'IL',
    latitude: '40.238218',
    longitude: '-91.085563',
    timeZoneId: 'America/Chicago',
  },
  '62319': {
    zip: '62319',
    city: 'Camden',
    state: 'IL',
    latitude: '40.149043',
    longitude: '-90.737657',
    timeZoneId: 'America/Chicago',
  },
  '62320': {
    zip: '62320',
    city: 'Camp Point',
    state: 'IL',
    latitude: '40.038481',
    longitude: '-91.06492',
    timeZoneId: 'America/Chicago',
  },
  '62321': {
    zip: '62321',
    city: 'Carthage',
    state: 'IL',
    latitude: '40.344444',
    longitude: '-91.111667',
    timeZoneId: 'America/Chicago',
  },
  '62323': {
    zip: '62323',
    city: 'Chambersburg',
    state: 'IL',
    latitude: '39.778405',
    longitude: '-90.749454',
    timeZoneId: 'America/Chicago',
  },
  '62324': {
    zip: '62324',
    city: 'Clayton',
    state: 'IL',
    latitude: '40.030973',
    longitude: '-90.95719',
    timeZoneId: 'America/Chicago',
  },
  '62325': {
    zip: '62325',
    city: 'Coatsburg',
    state: 'IL',
    latitude: '40.03187',
    longitude: '-91.158865',
    timeZoneId: 'America/Chicago',
  },
  '62326': {
    zip: '62326',
    city: 'Colchester',
    state: 'IL',
    latitude: '40.423848',
    longitude: '-90.792932',
    timeZoneId: 'America/Chicago',
  },
  '62329': {
    zip: '62329',
    city: 'Colusa',
    state: 'IL',
    latitude: '40.598632',
    longitude: '-91.137878',
    timeZoneId: 'America/Chicago',
  },
  '62330': {
    zip: '62330',
    city: 'Dallas City',
    state: 'IL',
    latitude: '40.632034',
    longitude: '-91.172512',
    timeZoneId: 'America/Chicago',
  },
  '62334': {
    zip: '62334',
    city: 'Elvaston',
    state: 'IL',
    latitude: '40.416211',
    longitude: '-91.199203',
    timeZoneId: 'America/Chicago',
  },
  '62336': {
    zip: '62336',
    city: 'Ferris',
    state: 'IL',
    latitude: '40.503256',
    longitude: '-91.134894',
    timeZoneId: 'America/Chicago',
  },
  '62338': {
    zip: '62338',
    city: 'Fowler',
    state: 'IL',
    latitude: '39.98812',
    longitude: '-91.236325',
    timeZoneId: 'America/Chicago',
  },
  '62339': {
    zip: '62339',
    city: 'Golden',
    state: 'IL',
    latitude: '40.11017',
    longitude: '-91.019598',
    timeZoneId: 'America/Chicago',
  },
  '62340': {
    zip: '62340',
    city: 'Griggsville',
    state: 'IL',
    latitude: '39.710725',
    longitude: '-90.727921',
    timeZoneId: 'America/Chicago',
  },
  '62341': {
    zip: '62341',
    city: 'Hamilton',
    state: 'IL',
    latitude: '40.394115',
    longitude: '-91.335644',
    timeZoneId: 'America/Chicago',
  },
  '62343': {
    zip: '62343',
    city: 'Hull',
    state: 'IL',
    latitude: '39.720145',
    longitude: '-91.262764',
    timeZoneId: 'America/Chicago',
  },
  '62344': {
    zip: '62344',
    city: 'Huntsville',
    state: 'IL',
    latitude: '40.150965',
    longitude: '-90.852473',
    timeZoneId: 'America/Chicago',
  },
  '62345': {
    zip: '62345',
    city: 'Kinderhook',
    state: 'IL',
    latitude: '39.647467',
    longitude: '-91.185504',
    timeZoneId: 'America/Chicago',
  },
  '62346': {
    zip: '62346',
    city: 'La Prairie',
    state: 'IL',
    latitude: '40.165596',
    longitude: '-90.970804',
    timeZoneId: 'America/Chicago',
  },
  '62347': {
    zip: '62347',
    city: 'Liberty',
    state: 'IL',
    latitude: '39.89178',
    longitude: '-91.04502',
    timeZoneId: 'America/Chicago',
  },
  '62348': {
    zip: '62348',
    city: 'Lima',
    state: 'IL',
    latitude: '40.166554',
    longitude: '-91.406349',
    timeZoneId: 'America/Chicago',
  },
  '62349': {
    zip: '62349',
    city: 'Loraine',
    state: 'IL',
    latitude: '40.153679',
    longitude: '-91.205575',
    timeZoneId: 'America/Chicago',
  },
  '62351': {
    zip: '62351',
    city: 'Mendon',
    state: 'IL',
    latitude: '40.08659',
    longitude: '-91.284958',
    timeZoneId: 'America/Chicago',
  },
  '62352': {
    zip: '62352',
    city: 'Milton',
    state: 'IL',
    latitude: '39.564157',
    longitude: '-90.648463',
    timeZoneId: 'America/Chicago',
  },
  '62353': {
    zip: '62353',
    city: 'Mount Sterling',
    state: 'IL',
    latitude: '39.985995',
    longitude: '-90.767312',
    timeZoneId: 'America/Chicago',
  },
  '62354': {
    zip: '62354',
    city: 'Nauvoo',
    state: 'IL',
    latitude: '40.548906',
    longitude: '-91.37849',
    timeZoneId: 'America/Chicago',
  },
  '62355': {
    zip: '62355',
    city: 'Nebo',
    state: 'IL',
    latitude: '39.398619',
    longitude: '-90.771723',
    timeZoneId: 'America/Chicago',
  },
  '62356': {
    zip: '62356',
    city: 'New Canton',
    state: 'IL',
    latitude: '39.625007',
    longitude: '-91.086931',
    timeZoneId: 'America/Chicago',
  },
  '62357': {
    zip: '62357',
    city: 'New Salem',
    state: 'IL',
    latitude: '39.694763',
    longitude: '-90.85538',
    timeZoneId: 'America/Chicago',
  },
  '62358': {
    zip: '62358',
    city: 'Niota',
    state: 'IL',
    latitude: '40.61559',
    longitude: '-91.286393',
    timeZoneId: 'America/Chicago',
  },
  '62359': {
    zip: '62359',
    city: 'Paloma',
    state: 'IL',
    latitude: '40.052796',
    longitude: '-91.224158',
    timeZoneId: 'America/Chicago',
  },
  '62360': {
    zip: '62360',
    city: 'Payson',
    state: 'IL',
    latitude: '39.818387',
    longitude: '-91.239589',
    timeZoneId: 'America/Chicago',
  },
  '62361': {
    zip: '62361',
    city: 'Pearl',
    state: 'IL',
    latitude: '39.428363',
    longitude: '-90.649756',
    timeZoneId: 'America/Chicago',
  },
  '62362': {
    zip: '62362',
    city: 'Perry',
    state: 'IL',
    latitude: '39.785162',
    longitude: '-90.744207',
    timeZoneId: 'America/Chicago',
  },
  '62363': {
    zip: '62363',
    city: 'Pittsfield',
    state: 'IL',
    latitude: '39.609331',
    longitude: '-90.807822',
    timeZoneId: 'America/Chicago',
  },
  '62365': {
    zip: '62365',
    city: 'Plainville',
    state: 'IL',
    latitude: '39.828285',
    longitude: '-91.19315',
    timeZoneId: 'America/Chicago',
  },
  '62366': {
    zip: '62366',
    city: 'Pleasant Hill',
    state: 'IL',
    latitude: '39.440122',
    longitude: '-90.871294',
    timeZoneId: 'America/Chicago',
  },
  '62367': {
    zip: '62367',
    city: 'Plymouth',
    state: 'IL',
    latitude: '40.29018',
    longitude: '-90.89376',
    timeZoneId: 'America/Chicago',
  },
  '62370': {
    zip: '62370',
    city: 'Rockport',
    state: 'IL',
    latitude: '39.535316',
    longitude: '-91.017575',
    timeZoneId: 'America/Chicago',
  },
  '62373': {
    zip: '62373',
    city: 'Sutter',
    state: 'IL',
    latitude: '40.292944',
    longitude: '-91.386089',
    timeZoneId: 'America/Chicago',
  },
  '62374': {
    zip: '62374',
    city: 'Tennessee',
    state: 'IL',
    latitude: '40.424866',
    longitude: '-90.799019',
    timeZoneId: 'America/Chicago',
  },
  '62375': {
    zip: '62375',
    city: 'Timewell',
    state: 'IL',
    latitude: '40.015652',
    longitude: '-90.855642',
    timeZoneId: 'America/Chicago',
  },
  '62376': {
    zip: '62376',
    city: 'Ursa',
    state: 'IL',
    latitude: '40.080169',
    longitude: '-91.411941',
    timeZoneId: 'America/Chicago',
  },
  '62378': {
    zip: '62378',
    city: 'Versailles',
    state: 'IL',
    latitude: '39.889092',
    longitude: '-90.657738',
    timeZoneId: 'America/Chicago',
  },
  '62379': {
    zip: '62379',
    city: 'Warsaw',
    state: 'IL',
    latitude: '40.353789',
    longitude: '-91.425514',
    timeZoneId: 'America/Chicago',
  },
  '62380': {
    zip: '62380',
    city: 'West Point',
    state: 'IL',
    latitude: '40.2435',
    longitude: '-91.2516',
    timeZoneId: 'America/Chicago',
  },
  '62401': {
    zip: '62401',
    city: 'Effingham',
    state: 'IL',
    latitude: '39.12264',
    longitude: '-88.58796',
    timeZoneId: 'America/Chicago',
  },
  '62410': {
    zip: '62410',
    city: 'Allendale',
    state: 'IL',
    latitude: '38.527991',
    longitude: '-87.708425',
    timeZoneId: 'America/Chicago',
  },
  '62411': {
    zip: '62411',
    city: 'Altamont',
    state: 'IL',
    latitude: '39.084206',
    longitude: '-88.746416',
    timeZoneId: 'America/Chicago',
  },
  '62413': {
    zip: '62413',
    city: 'Annapolis',
    state: 'IL',
    latitude: '39.123916',
    longitude: '-87.798072',
    timeZoneId: 'America/Chicago',
  },
  '62414': {
    zip: '62414',
    city: 'Beecher City',
    state: 'IL',
    latitude: '39.176936',
    longitude: '-88.821983',
    timeZoneId: 'America/Chicago',
  },
  '62417': {
    zip: '62417',
    city: 'Bridgeport',
    state: 'IL',
    latitude: '38.710903',
    longitude: '-87.758111',
    timeZoneId: 'America/Chicago',
  },
  '62418': {
    zip: '62418',
    city: 'Brownstown',
    state: 'IL',
    latitude: '38.995023',
    longitude: '-88.952593',
    timeZoneId: 'America/Chicago',
  },
  '62419': {
    zip: '62419',
    city: 'Calhoun',
    state: 'IL',
    latitude: '38.72392',
    longitude: '-88.185778',
    timeZoneId: 'America/Chicago',
  },
  '62420': {
    zip: '62420',
    city: 'Casey',
    state: 'IL',
    latitude: '39.300331',
    longitude: '-87.989447',
    timeZoneId: 'America/Chicago',
  },
  '62421': {
    zip: '62421',
    city: 'Claremont',
    state: 'IL',
    latitude: '38.755773',
    longitude: '-87.965113',
    timeZoneId: 'America/Chicago',
  },
  '62422': {
    zip: '62422',
    city: 'Cowden',
    state: 'IL',
    latitude: '39.216769',
    longitude: '-88.90385',
    timeZoneId: 'America/Chicago',
  },
  '62423': {
    zip: '62423',
    city: 'Dennison',
    state: 'IL',
    latitude: '39.450635',
    longitude: '-87.609931',
    timeZoneId: 'America/Chicago',
  },
  '62424': {
    zip: '62424',
    city: 'Dieterich',
    state: 'IL',
    latitude: '39.062733',
    longitude: '-88.380295',
    timeZoneId: 'America/Chicago',
  },
  '62425': {
    zip: '62425',
    city: 'Dundas',
    state: 'IL',
    latitude: '38.829215',
    longitude: '-88.106167',
    timeZoneId: 'America/Chicago',
  },
  '62426': {
    zip: '62426',
    city: 'Edgewood',
    state: 'IL',
    latitude: '38.921946',
    longitude: '-88.664835',
    timeZoneId: 'America/Chicago',
  },
  '62427': {
    zip: '62427',
    city: 'Flat Rock',
    state: 'IL',
    latitude: '38.900944',
    longitude: '-87.672357',
    timeZoneId: 'America/Chicago',
  },
  '62428': {
    zip: '62428',
    city: 'Greenup',
    state: 'IL',
    latitude: '39.202222',
    longitude: '-88.041111',
    timeZoneId: 'America/Chicago',
  },
  '62431': {
    zip: '62431',
    city: 'Herrick',
    state: 'IL',
    latitude: '39.239384',
    longitude: '-88.971246',
    timeZoneId: 'America/Chicago',
  },
  '62432': {
    zip: '62432',
    city: 'Hidalgo',
    state: 'IL',
    latitude: '39.117001',
    longitude: '-88.129813',
    timeZoneId: 'America/Chicago',
  },
  '62433': {
    zip: '62433',
    city: 'Hutsonville',
    state: 'IL',
    latitude: '39.109922',
    longitude: '-87.659516',
    timeZoneId: 'America/Chicago',
  },
  '62434': {
    zip: '62434',
    city: 'Ingraham',
    state: 'IL',
    latitude: '38.842024',
    longitude: '-88.31112',
    timeZoneId: 'America/Chicago',
  },
  '62436': {
    zip: '62436',
    city: 'Jewett',
    state: 'IL',
    latitude: '39.201079',
    longitude: '-88.247263',
    timeZoneId: 'America/Chicago',
  },
  '62438': {
    zip: '62438',
    city: 'Lakewood',
    state: 'IL',
    latitude: '39.313558',
    longitude: '-88.860318',
    timeZoneId: 'America/Chicago',
  },
  '62439': {
    zip: '62439',
    city: 'Lawrenceville',
    state: 'IL',
    latitude: '38.72088',
    longitude: '-87.70794',
    timeZoneId: 'America/Chicago',
  },
  '62440': {
    zip: '62440',
    city: 'Lerna',
    state: 'IL',
    latitude: '39.417463',
    longitude: '-88.287007',
    timeZoneId: 'America/Chicago',
  },
  '62441': {
    zip: '62441',
    city: 'Marshall',
    state: 'IL',
    latitude: '39.4002',
    longitude: '-87.69468',
    timeZoneId: 'America/Chicago',
  },
  '62442': {
    zip: '62442',
    city: 'Martinsville',
    state: 'IL',
    latitude: '39.330353',
    longitude: '-87.886995',
    timeZoneId: 'America/Chicago',
  },
  '62443': {
    zip: '62443',
    city: 'Mason',
    state: 'IL',
    latitude: '38.961718',
    longitude: '-88.635948',
    timeZoneId: 'America/Chicago',
  },
  '62444': {
    zip: '62444',
    city: 'Mode',
    state: 'IL',
    latitude: '39.278154',
    longitude: '-88.636713',
    timeZoneId: 'America/Chicago',
  },
  '62445': {
    zip: '62445',
    city: 'Montrose',
    state: 'IL',
    latitude: '39.14888',
    longitude: '-88.320381',
    timeZoneId: 'America/Chicago',
  },
  '62446': {
    zip: '62446',
    city: 'Mount Erie',
    state: 'IL',
    latitude: '38.380617',
    longitude: '-88.406635',
    timeZoneId: 'America/Chicago',
  },
  '62447': {
    zip: '62447',
    city: 'Neoga',
    state: 'IL',
    latitude: '39.321626',
    longitude: '-88.454756',
    timeZoneId: 'America/Chicago',
  },
  '62448': {
    zip: '62448',
    city: 'Newton',
    state: 'IL',
    latitude: '38.988361',
    longitude: '-88.163646',
    timeZoneId: 'America/Chicago',
  },
  '62449': {
    zip: '62449',
    city: 'Oblong',
    state: 'IL',
    latitude: '39.001962',
    longitude: '-87.906869',
    timeZoneId: 'America/Chicago',
  },
  '62450': {
    zip: '62450',
    city: 'Olney',
    state: 'IL',
    latitude: '38.730805',
    longitude: '-88.084555',
    timeZoneId: 'America/Chicago',
  },
  '62451': {
    zip: '62451',
    city: 'Palestine',
    state: 'IL',
    latitude: '39.002766',
    longitude: '-87.615625',
    timeZoneId: 'America/Chicago',
  },
  '62452': {
    zip: '62452',
    city: 'Parkersburg',
    state: 'IL',
    latitude: '38.592078',
    longitude: '-88.064006',
    timeZoneId: 'America/Chicago',
  },
  '62454': {
    zip: '62454',
    city: 'Robinson',
    state: 'IL',
    latitude: '39.00576',
    longitude: '-87.77514',
    timeZoneId: 'America/Chicago',
  },
  '62458': {
    zip: '62458',
    city: 'Saint Elmo',
    state: 'IL',
    latitude: '39.029314',
    longitude: '-88.854347',
    timeZoneId: 'America/Chicago',
  },
  '62459': {
    zip: '62459',
    city: 'Sainte Marie',
    state: 'IL',
    latitude: '38.934009',
    longitude: '-88.021589',
    timeZoneId: 'America/Chicago',
  },
  '62460': {
    zip: '62460',
    city: 'Saint Francisville',
    state: 'IL',
    latitude: '38.591789',
    longitude: '-87.646603',
    timeZoneId: 'America/Chicago',
  },
  '62461': {
    zip: '62461',
    city: 'Shumway',
    state: 'IL',
    latitude: '39.193658',
    longitude: '-88.639329',
    timeZoneId: 'America/Chicago',
  },
  '62462': {
    zip: '62462',
    city: 'Sigel',
    state: 'IL',
    latitude: '39.227201',
    longitude: '-88.491182',
    timeZoneId: 'America/Chicago',
  },
  '62463': {
    zip: '62463',
    city: 'Stewardson',
    state: 'IL',
    latitude: '39.26647',
    longitude: '-88.628735',
    timeZoneId: 'America/Chicago',
  },
  '62464': {
    zip: '62464',
    city: 'Stoy',
    state: 'IL',
    latitude: '38.993997',
    longitude: '-87.833521',
    timeZoneId: 'America/Chicago',
  },
  '62465': {
    zip: '62465',
    city: 'Strasburg',
    state: 'IL',
    latitude: '39.385371',
    longitude: '-88.64405',
    timeZoneId: 'America/Chicago',
  },
  '62466': {
    zip: '62466',
    city: 'Sumner',
    state: 'IL',
    latitude: '38.7306',
    longitude: '-87.77238',
    timeZoneId: 'America/Chicago',
  },
  '62467': {
    zip: '62467',
    city: 'Teutopolis',
    state: 'IL',
    latitude: '39.131471',
    longitude: '-88.475901',
    timeZoneId: 'America/Chicago',
  },
  '62468': {
    zip: '62468',
    city: 'Toledo',
    state: 'IL',
    latitude: '39.269624',
    longitude: '-88.244943',
    timeZoneId: 'America/Chicago',
  },
  '62469': {
    zip: '62469',
    city: 'Trilla',
    state: 'IL',
    latitude: '39.389938',
    longitude: '-88.33958',
    timeZoneId: 'America/Chicago',
  },
  '62471': {
    zip: '62471',
    city: 'Vandalia',
    state: 'IL',
    latitude: '38.967418',
    longitude: '-89.105783',
    timeZoneId: 'America/Chicago',
  },
  '62473': {
    zip: '62473',
    city: 'Watson',
    state: 'IL',
    latitude: '39.035209',
    longitude: '-88.57324',
    timeZoneId: 'America/Chicago',
  },
  '62474': {
    zip: '62474',
    city: 'Westfield',
    state: 'IL',
    latitude: '39.4544',
    longitude: '-87.997271',
    timeZoneId: 'America/Chicago',
  },
  '62475': {
    zip: '62475',
    city: 'West Liberty',
    state: 'IL',
    latitude: '38.906032',
    longitude: '-88.096973',
    timeZoneId: 'America/Chicago',
  },
  '62476': {
    zip: '62476',
    city: 'West Salem',
    state: 'IL',
    latitude: '38.52',
    longitude: '-88.02066',
    timeZoneId: 'America/Chicago',
  },
  '62477': {
    zip: '62477',
    city: 'West Union',
    state: 'IL',
    latitude: '39.2478',
    longitude: '-87.6447',
    timeZoneId: 'America/Chicago',
  },
  '62478': {
    zip: '62478',
    city: 'West York',
    state: 'IL',
    latitude: '39.206796',
    longitude: '-87.728257',
    timeZoneId: 'America/Chicago',
  },
  '62479': {
    zip: '62479',
    city: 'Wheeler',
    state: 'IL',
    latitude: '39.018789',
    longitude: '-88.311313',
    timeZoneId: 'America/Chicago',
  },
  '62480': {
    zip: '62480',
    city: 'Willow Hill',
    state: 'IL',
    latitude: '38.976776',
    longitude: '-88.0113',
    timeZoneId: 'America/Chicago',
  },
  '62481': {
    zip: '62481',
    city: 'Yale',
    state: 'IL',
    latitude: '39.132003',
    longitude: '-88.00418',
    timeZoneId: 'America/Chicago',
  },
  '62501': {
    zip: '62501',
    city: 'Argenta',
    state: 'IL',
    latitude: '39.984413',
    longitude: '-88.81835',
    timeZoneId: 'America/Chicago',
  },
  '62510': {
    zip: '62510',
    city: 'Assumption',
    state: 'IL',
    latitude: '39.518917',
    longitude: '-89.050981',
    timeZoneId: 'America/Chicago',
  },
  '62512': {
    zip: '62512',
    city: 'Beason',
    state: 'IL',
    latitude: '40.141151',
    longitude: '-89.200183',
    timeZoneId: 'America/Chicago',
  },
  '62513': {
    zip: '62513',
    city: 'Blue Mound',
    state: 'IL',
    latitude: '39.69997',
    longitude: '-89.118469',
    timeZoneId: 'America/Chicago',
  },
  '62514': {
    zip: '62514',
    city: 'Boody',
    state: 'IL',
    latitude: '39.768379',
    longitude: '-89.047204',
    timeZoneId: 'America/Chicago',
  },
  '62515': {
    zip: '62515',
    city: 'Buffalo',
    state: 'IL',
    latitude: '39.847849',
    longitude: '-89.406204',
    timeZoneId: 'America/Chicago',
  },
  '62517': {
    zip: '62517',
    city: 'Bulpitt',
    state: 'IL',
    latitude: '39.595675',
    longitude: '-89.426448',
    timeZoneId: 'America/Chicago',
  },
  '62518': {
    zip: '62518',
    city: 'Chestnut',
    state: 'IL',
    latitude: '40.064221',
    longitude: '-89.196549',
    timeZoneId: 'America/Chicago',
  },
  '62519': {
    zip: '62519',
    city: 'Cornland',
    state: 'IL',
    latitude: '39.935898',
    longitude: '-89.40057',
    timeZoneId: 'America/Chicago',
  },
  '62520': {
    zip: '62520',
    city: 'Dawson',
    state: 'IL',
    latitude: '39.855253',
    longitude: '-89.459959',
    timeZoneId: 'America/Chicago',
  },
  '62521': {
    zip: '62521',
    city: 'Decatur',
    state: 'IL',
    latitude: '39.827104',
    longitude: '-88.924086',
    timeZoneId: 'America/Chicago',
  },
  '62522': {
    zip: '62522',
    city: 'Decatur',
    state: 'IL',
    latitude: '39.845988',
    longitude: '-88.993212',
    timeZoneId: 'America/Chicago',
  },
  '62523': {
    zip: '62523',
    city: 'Decatur',
    state: 'IL',
    latitude: '39.842913',
    longitude: '-88.949476',
    timeZoneId: 'America/Chicago',
  },
  '62524': {
    zip: '62524',
    city: 'Decatur',
    state: 'IL',
    latitude: '39.904113',
    longitude: '-88.966664',
    timeZoneId: 'America/Chicago',
  },
  '62525': {
    zip: '62525',
    city: 'Decatur',
    state: 'IL',
    latitude: '39.832819',
    longitude: '-88.944395',
    timeZoneId: 'America/Chicago',
  },
  '62526': {
    zip: '62526',
    city: 'Decatur',
    state: 'IL',
    latitude: '39.875033',
    longitude: '-88.956342',
    timeZoneId: 'America/Chicago',
  },
  '62530': {
    zip: '62530',
    city: 'Divernon',
    state: 'IL',
    latitude: '39.565165',
    longitude: '-89.656848',
    timeZoneId: 'America/Chicago',
  },
  '62531': {
    zip: '62531',
    city: 'Edinburg',
    state: 'IL',
    latitude: '39.661648',
    longitude: '-89.392376',
    timeZoneId: 'America/Chicago',
  },
  '62532': {
    zip: '62532',
    city: 'Elwin',
    state: 'IL',
    latitude: '39.777219',
    longitude: '-88.98154',
    timeZoneId: 'America/Chicago',
  },
  '62533': {
    zip: '62533',
    city: 'Farmersville',
    state: 'IL',
    latitude: '39.43902',
    longitude: '-89.649092',
    timeZoneId: 'America/Chicago',
  },
  '62534': {
    zip: '62534',
    city: 'Findlay',
    state: 'IL',
    latitude: '39.520163',
    longitude: '-88.754757',
    timeZoneId: 'America/Chicago',
  },
  '62535': {
    zip: '62535',
    city: 'Forsyth',
    state: 'IL',
    latitude: '39.929267',
    longitude: '-88.949924',
    timeZoneId: 'America/Chicago',
  },
  '62536': {
    zip: '62536',
    city: 'Glenarm',
    state: 'IL',
    latitude: '39.637226',
    longitude: '-89.634543',
    timeZoneId: 'America/Chicago',
  },
  '62537': {
    zip: '62537',
    city: 'Harristown',
    state: 'IL',
    latitude: '39.839537',
    longitude: '-89.05937',
    timeZoneId: 'America/Chicago',
  },
  '62538': {
    zip: '62538',
    city: 'Harvel',
    state: 'IL',
    latitude: '39.385957',
    longitude: '-89.543556',
    timeZoneId: 'America/Chicago',
  },
  '62539': {
    zip: '62539',
    city: 'Illiopolis',
    state: 'IL',
    latitude: '39.854937',
    longitude: '-89.246577',
    timeZoneId: 'America/Chicago',
  },
  '62540': {
    zip: '62540',
    city: 'Kincaid',
    state: 'IL',
    latitude: '39.586052',
    longitude: '-89.414602',
    timeZoneId: 'America/Chicago',
  },
  '62541': {
    zip: '62541',
    city: 'Lake Fork',
    state: 'IL',
    latitude: '39.969958',
    longitude: '-89.351644',
    timeZoneId: 'America/Chicago',
  },
  '62543': {
    zip: '62543',
    city: 'Latham',
    state: 'IL',
    latitude: '39.967567',
    longitude: '-89.164007',
    timeZoneId: 'America/Chicago',
  },
  '62544': {
    zip: '62544',
    city: 'Macon',
    state: 'IL',
    latitude: '39.716477',
    longitude: '-88.998481',
    timeZoneId: 'America/Chicago',
  },
  '62545': {
    zip: '62545',
    city: 'Mechanicsburg',
    state: 'IL',
    latitude: '39.809097',
    longitude: '-89.397015',
    timeZoneId: 'America/Chicago',
  },
  '62546': {
    zip: '62546',
    city: 'Morrisonville',
    state: 'IL',
    latitude: '39.420129',
    longitude: '-89.460129',
    timeZoneId: 'America/Chicago',
  },
  '62547': {
    zip: '62547',
    city: 'Mount Auburn',
    state: 'IL',
    latitude: '39.761091',
    longitude: '-89.213265',
    timeZoneId: 'America/Chicago',
  },
  '62548': {
    zip: '62548',
    city: 'Mount Pulaski',
    state: 'IL',
    latitude: '40.009094',
    longitude: '-89.284725',
    timeZoneId: 'America/Chicago',
  },
  '62549': {
    zip: '62549',
    city: 'Mt Zion',
    state: 'IL',
    latitude: '39.785894',
    longitude: '-88.870832',
    timeZoneId: 'America/Chicago',
  },
  '62550': {
    zip: '62550',
    city: 'Moweaqua',
    state: 'IL',
    latitude: '39.624412',
    longitude: '-89.016482',
    timeZoneId: 'America/Chicago',
  },
  '62551': {
    zip: '62551',
    city: 'Niantic',
    state: 'IL',
    latitude: '39.852509',
    longitude: '-89.167148',
    timeZoneId: 'America/Chicago',
  },
  '62553': {
    zip: '62553',
    city: 'Oconee',
    state: 'IL',
    latitude: '39.280213',
    longitude: '-89.084082',
    timeZoneId: 'America/Chicago',
  },
  '62554': {
    zip: '62554',
    city: 'Oreana',
    state: 'IL',
    latitude: '39.932312',
    longitude: '-88.860876',
    timeZoneId: 'America/Chicago',
  },
  '62555': {
    zip: '62555',
    city: 'Owaneco',
    state: 'IL',
    latitude: '39.481883',
    longitude: '-89.192031',
    timeZoneId: 'America/Chicago',
  },
  '62556': {
    zip: '62556',
    city: 'Palmer',
    state: 'IL',
    latitude: '39.47952',
    longitude: '-89.4324',
    timeZoneId: 'America/Chicago',
  },
  '62557': {
    zip: '62557',
    city: 'Pana',
    state: 'IL',
    latitude: '39.38631',
    longitude: '-89.080983',
    timeZoneId: 'America/Chicago',
  },
  '62558': {
    zip: '62558',
    city: 'Pawnee',
    state: 'IL',
    latitude: '39.592339',
    longitude: '-89.581873',
    timeZoneId: 'America/Chicago',
  },
  '62560': {
    zip: '62560',
    city: 'Raymond',
    state: 'IL',
    latitude: '39.322024',
    longitude: '-89.572809',
    timeZoneId: 'America/Chicago',
  },
  '62561': {
    zip: '62561',
    city: 'Riverton',
    state: 'IL',
    latitude: '39.85446',
    longitude: '-89.5077',
    timeZoneId: 'America/Chicago',
  },
  '62563': {
    zip: '62563',
    city: 'Rochester',
    state: 'IL',
    latitude: '39.748414',
    longitude: '-89.542262',
    timeZoneId: 'America/Chicago',
  },
  '62565': {
    zip: '62565',
    city: 'Shelbyville',
    state: 'IL',
    latitude: '39.408284',
    longitude: '-88.799436',
    timeZoneId: 'America/Chicago',
  },
  '62567': {
    zip: '62567',
    city: 'Stonington',
    state: 'IL',
    latitude: '39.637026',
    longitude: '-89.18852',
    timeZoneId: 'America/Chicago',
  },
  '62568': {
    zip: '62568',
    city: 'Taylorville',
    state: 'IL',
    latitude: '39.549894',
    longitude: '-89.297628',
    timeZoneId: 'America/Chicago',
  },
  '62570': {
    zip: '62570',
    city: 'Tovey',
    state: 'IL',
    latitude: '39.58738',
    longitude: '-89.448612',
    timeZoneId: 'America/Chicago',
  },
  '62571': {
    zip: '62571',
    city: 'Tower Hill',
    state: 'IL',
    latitude: '39.390541',
    longitude: '-88.964526',
    timeZoneId: 'America/Chicago',
  },
  '62572': {
    zip: '62572',
    city: 'Waggoner',
    state: 'IL',
    latitude: '39.378868',
    longitude: '-89.648886',
    timeZoneId: 'America/Chicago',
  },
  '62573': {
    zip: '62573',
    city: 'Warrensburg',
    state: 'IL',
    latitude: '39.929287',
    longitude: '-89.062013',
    timeZoneId: 'America/Chicago',
  },
  '62601': {
    zip: '62601',
    city: 'Alexander',
    state: 'IL',
    latitude: '39.731933',
    longitude: '-90.038036',
    timeZoneId: 'America/Chicago',
  },
  '62610': {
    zip: '62610',
    city: 'Alsey',
    state: 'IL',
    latitude: '39.557166',
    longitude: '-90.433258',
    timeZoneId: 'America/Chicago',
  },
  '62611': {
    zip: '62611',
    city: 'Arenzville',
    state: 'IL',
    latitude: '39.876706',
    longitude: '-90.369361',
    timeZoneId: 'America/Chicago',
  },
  '62612': {
    zip: '62612',
    city: 'Ashland',
    state: 'IL',
    latitude: '39.886126',
    longitude: '-90.009613',
    timeZoneId: 'America/Chicago',
  },
  '62613': {
    zip: '62613',
    city: 'Athens',
    state: 'IL',
    latitude: '39.94506',
    longitude: '-89.72502',
    timeZoneId: 'America/Chicago',
  },
  '62615': {
    zip: '62615',
    city: 'Auburn',
    state: 'IL',
    latitude: '39.589571',
    longitude: '-89.746086',
    timeZoneId: 'America/Chicago',
  },
  '62617': {
    zip: '62617',
    city: 'Bath',
    state: 'IL',
    latitude: '40.136329',
    longitude: '-90.16977',
    timeZoneId: 'America/Chicago',
  },
  '62618': {
    zip: '62618',
    city: 'Beardstown',
    state: 'IL',
    latitude: '40.011462',
    longitude: '-90.424051',
    timeZoneId: 'America/Chicago',
  },
  '62621': {
    zip: '62621',
    city: 'Bluffs',
    state: 'IL',
    latitude: '39.73411',
    longitude: '-90.522678',
    timeZoneId: 'America/Chicago',
  },
  '62622': {
    zip: '62622',
    city: 'Bluff Springs',
    state: 'IL',
    latitude: '39.986241',
    longitude: '-90.343935',
    timeZoneId: 'America/Chicago',
  },
  '62624': {
    zip: '62624',
    city: 'Browning',
    state: 'IL',
    latitude: '40.16005',
    longitude: '-90.34972',
    timeZoneId: 'America/Chicago',
  },
  '62625': {
    zip: '62625',
    city: 'Cantrall',
    state: 'IL',
    latitude: '39.926649',
    longitude: '-89.669971',
    timeZoneId: 'America/Chicago',
  },
  '62626': {
    zip: '62626',
    city: 'Carlinville',
    state: 'IL',
    latitude: '39.282298',
    longitude: '-89.878029',
    timeZoneId: 'America/Chicago',
  },
  '62627': {
    zip: '62627',
    city: 'Chandlerville',
    state: 'IL',
    latitude: '40.051276',
    longitude: '-90.147153',
    timeZoneId: 'America/Chicago',
  },
  '62628': {
    zip: '62628',
    city: 'Chapin',
    state: 'IL',
    latitude: '39.767526',
    longitude: '-90.403632',
    timeZoneId: 'America/Chicago',
  },
  '62629': {
    zip: '62629',
    city: 'Chatham',
    state: 'IL',
    latitude: '39.674929',
    longitude: '-89.696026',
    timeZoneId: 'America/Chicago',
  },
  '62630': {
    zip: '62630',
    city: 'Chesterfield',
    state: 'IL',
    latitude: '39.277225',
    longitude: '-90.092676',
    timeZoneId: 'America/Chicago',
  },
  '62631': {
    zip: '62631',
    city: 'Concord',
    state: 'IL',
    latitude: '39.827443',
    longitude: '-90.370445',
    timeZoneId: 'America/Chicago',
  },
  '62633': {
    zip: '62633',
    city: 'Easton',
    state: 'IL',
    latitude: '40.234297',
    longitude: '-89.847735',
    timeZoneId: 'America/Chicago',
  },
  '62634': {
    zip: '62634',
    city: 'Elkhart',
    state: 'IL',
    latitude: '40.016327',
    longitude: '-89.4802',
    timeZoneId: 'America/Chicago',
  },
  '62635': {
    zip: '62635',
    city: 'Emden',
    state: 'IL',
    latitude: '40.298491',
    longitude: '-89.484616',
    timeZoneId: 'America/Chicago',
  },
  '62638': {
    zip: '62638',
    city: 'Franklin',
    state: 'IL',
    latitude: '39.621696',
    longitude: '-90.042782',
    timeZoneId: 'America/Chicago',
  },
  '62639': {
    zip: '62639',
    city: 'Frederick',
    state: 'IL',
    latitude: '40.056023',
    longitude: '-90.495285',
    timeZoneId: 'America/Chicago',
  },
  '62640': {
    zip: '62640',
    city: 'Girard',
    state: 'IL',
    latitude: '39.446714',
    longitude: '-89.796627',
    timeZoneId: 'America/Chicago',
  },
  '62642': {
    zip: '62642',
    city: 'Greenview',
    state: 'IL',
    latitude: '40.081772',
    longitude: '-89.743074',
    timeZoneId: 'America/Chicago',
  },
  '62643': {
    zip: '62643',
    city: 'Hartsburg',
    state: 'IL',
    latitude: '40.252616',
    longitude: '-89.439891',
    timeZoneId: 'America/Chicago',
  },
  '62644': {
    zip: '62644',
    city: 'Havana',
    state: 'IL',
    latitude: '40.297332',
    longitude: '-90.063176',
    timeZoneId: 'America/Chicago',
  },
  '62649': {
    zip: '62649',
    city: 'Hettick',
    state: 'IL',
    latitude: '39.390113',
    longitude: '-90.093325',
    timeZoneId: 'America/Chicago',
  },
  '62650': {
    zip: '62650',
    city: 'Jacksonville',
    state: 'IL',
    latitude: '39.730959',
    longitude: '-90.238625',
    timeZoneId: 'America/Chicago',
  },
  '62651': {
    zip: '62651',
    city: 'Jacksonville',
    state: 'IL',
    latitude: '39.727574',
    longitude: '-90.264027',
    timeZoneId: 'America/Chicago',
  },
  '62655': {
    zip: '62655',
    city: 'Kilbourne',
    state: 'IL',
    latitude: '40.151058',
    longitude: '-90.009308',
    timeZoneId: 'America/Chicago',
  },
  '62656': {
    zip: '62656',
    city: 'Lincoln',
    state: 'IL',
    latitude: '40.153604',
    longitude: '-89.369109',
    timeZoneId: 'America/Chicago',
  },
  '62659': {
    zip: '62659',
    city: 'Lincolns New Salem',
    state: 'IL',
    latitude: '40.01006',
    longitude: '-89.84984',
    timeZoneId: 'America/Chicago',
  },
  '62660': {
    zip: '62660',
    city: 'Literberry',
    state: 'IL',
    latitude: '39.856774',
    longitude: '-90.197612',
    timeZoneId: 'America/Chicago',
  },
  '62661': {
    zip: '62661',
    city: 'Loami',
    state: 'IL',
    latitude: '39.654098',
    longitude: '-89.882073',
    timeZoneId: 'America/Chicago',
  },
  '62662': {
    zip: '62662',
    city: 'Lowder',
    state: 'IL',
    latitude: '39.553094',
    longitude: '-89.846458',
    timeZoneId: 'America/Chicago',
  },
  '62663': {
    zip: '62663',
    city: 'Manchester',
    state: 'IL',
    latitude: '39.542922',
    longitude: '-90.331459',
    timeZoneId: 'America/Chicago',
  },
  '62664': {
    zip: '62664',
    city: 'Mason City',
    state: 'IL',
    latitude: '40.200803',
    longitude: '-89.695417',
    timeZoneId: 'America/Chicago',
  },
  '62665': {
    zip: '62665',
    city: 'Meredosia',
    state: 'IL',
    latitude: '39.82966',
    longitude: '-90.559448',
    timeZoneId: 'America/Chicago',
  },
  '62666': {
    zip: '62666',
    city: 'Middletown',
    state: 'IL',
    latitude: '40.100321',
    longitude: '-89.588736',
    timeZoneId: 'America/Chicago',
  },
  '62667': {
    zip: '62667',
    city: 'Modesto',
    state: 'IL',
    latitude: '39.478096',
    longitude: '-89.981342',
    timeZoneId: 'America/Chicago',
  },
  '62668': {
    zip: '62668',
    city: 'Murrayville',
    state: 'IL',
    latitude: '39.5867',
    longitude: '-90.247679',
    timeZoneId: 'America/Chicago',
  },
  '62670': {
    zip: '62670',
    city: 'New Berlin',
    state: 'IL',
    latitude: '39.734757',
    longitude: '-89.908806',
    timeZoneId: 'America/Chicago',
  },
  '62671': {
    zip: '62671',
    city: 'New Holland',
    state: 'IL',
    latitude: '40.18421',
    longitude: '-89.579853',
    timeZoneId: 'America/Chicago',
  },
  '62672': {
    zip: '62672',
    city: 'Nilwood',
    state: 'IL',
    latitude: '39.386383',
    longitude: '-89.78335',
    timeZoneId: 'America/Chicago',
  },
  '62673': {
    zip: '62673',
    city: 'Oakford',
    state: 'IL',
    latitude: '40.10244',
    longitude: '-89.965555',
    timeZoneId: 'America/Chicago',
  },
  '62674': {
    zip: '62674',
    city: 'Palmyra',
    state: 'IL',
    latitude: '39.439755',
    longitude: '-89.9252',
    timeZoneId: 'America/Chicago',
  },
  '62675': {
    zip: '62675',
    city: 'Petersburg',
    state: 'IL',
    latitude: '40.003483',
    longitude: '-89.851315',
    timeZoneId: 'America/Chicago',
  },
  '62677': {
    zip: '62677',
    city: 'Pleasant Plains',
    state: 'IL',
    latitude: '39.875106',
    longitude: '-89.916317',
    timeZoneId: 'America/Chicago',
  },
  '62681': {
    zip: '62681',
    city: 'Rushville',
    state: 'IL',
    latitude: '40.121657',
    longitude: '-90.560514',
    timeZoneId: 'America/Chicago',
  },
  '62682': {
    zip: '62682',
    city: 'San Jose',
    state: 'IL',
    latitude: '40.302815',
    longitude: '-89.605112',
    timeZoneId: 'America/Chicago',
  },
  '62683': {
    zip: '62683',
    city: 'Scottville',
    state: 'IL',
    latitude: '39.473489',
    longitude: '-90.097437',
    timeZoneId: 'America/Chicago',
  },
  '62684': {
    zip: '62684',
    city: 'Sherman',
    state: 'IL',
    latitude: '39.894681',
    longitude: '-89.600868',
    timeZoneId: 'America/Chicago',
  },
  '62685': {
    zip: '62685',
    city: 'Shipman',
    state: 'IL',
    latitude: '39.116628',
    longitude: '-90.005444',
    timeZoneId: 'America/Chicago',
  },
  '62688': {
    zip: '62688',
    city: 'Tallula',
    state: 'IL',
    latitude: '39.942864',
    longitude: '-89.901619',
    timeZoneId: 'America/Chicago',
  },
  '62689': {
    zip: '62689',
    city: 'Thayer',
    state: 'IL',
    latitude: '39.539231',
    longitude: '-89.762271',
    timeZoneId: 'America/Chicago',
  },
  '62690': {
    zip: '62690',
    city: 'Virden',
    state: 'IL',
    latitude: '39.503887',
    longitude: '-89.767276',
    timeZoneId: 'America/Chicago',
  },
  '62691': {
    zip: '62691',
    city: 'Virginia',
    state: 'IL',
    latitude: '39.948431',
    longitude: '-90.202632',
    timeZoneId: 'America/Chicago',
  },
  '62692': {
    zip: '62692',
    city: 'Waverly',
    state: 'IL',
    latitude: '39.591043',
    longitude: '-89.956391',
    timeZoneId: 'America/Chicago',
  },
  '62693': {
    zip: '62693',
    city: 'Williamsville',
    state: 'IL',
    latitude: '39.953762',
    longitude: '-89.54424',
    timeZoneId: 'America/Chicago',
  },
  '62694': {
    zip: '62694',
    city: 'Winchester',
    state: 'IL',
    latitude: '39.550556',
    longitude: '-90.48',
    timeZoneId: 'America/Chicago',
  },
  '62695': {
    zip: '62695',
    city: 'Woodson',
    state: 'IL',
    latitude: '39.629808',
    longitude: '-90.220028',
    timeZoneId: 'America/Chicago',
  },
  '62701': {
    zip: '62701',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.798379',
    longitude: '-89.646561',
    timeZoneId: 'America/Chicago',
  },
  '62702': {
    zip: '62702',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.82998',
    longitude: '-89.65308',
    timeZoneId: 'America/Chicago',
  },
  '62703': {
    zip: '62703',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.772322',
    longitude: '-89.632328',
    timeZoneId: 'America/Chicago',
  },
  '62704': {
    zip: '62704',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.7725',
    longitude: '-89.68368',
    timeZoneId: 'America/Chicago',
  },
  '62705': {
    zip: '62705',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.799861',
    longitude: '-89.647762',
    timeZoneId: 'America/Chicago',
  },
  '62706': {
    zip: '62706',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.7999',
    longitude: '-89.654773',
    timeZoneId: 'America/Chicago',
  },
  '62707': {
    zip: '62707',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.777482',
    longitude: '-89.650787',
    timeZoneId: 'America/Chicago',
  },
  '62708': {
    zip: '62708',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.759789',
    longitude: '-89.635179',
    timeZoneId: 'America/Chicago',
  },
  '62711': {
    zip: '62711',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.76332',
    longitude: '-89.725579',
    timeZoneId: 'America/Chicago',
  },
  '62712': {
    zip: '62712',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.74181',
    longitude: '-89.59436',
    timeZoneId: 'America/Chicago',
  },
  '62715': {
    zip: '62715',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.803359',
    longitude: '-89.646811',
    timeZoneId: 'America/Chicago',
  },
  '62716': {
    zip: '62716',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.748155',
    longitude: '-89.61177',
    timeZoneId: 'America/Chicago',
  },
  '62719': {
    zip: '62719',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.801546',
    longitude: '-89.652704',
    timeZoneId: 'America/Chicago',
  },
  '62722': {
    zip: '62722',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.797223',
    longitude: '-89.653566',
    timeZoneId: 'America/Chicago',
  },
  '62723': {
    zip: '62723',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.759431',
    longitude: '-89.634086',
    timeZoneId: 'America/Chicago',
  },
  '62726': {
    zip: '62726',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.805194',
    longitude: '-89.652979',
    timeZoneId: 'America/Chicago',
  },
  '62736': {
    zip: '62736',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.803461',
    longitude: '-89.651934',
    timeZoneId: 'America/Chicago',
  },
  '62739': {
    zip: '62739',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.798125',
    longitude: '-89.646516',
    timeZoneId: 'America/Chicago',
  },
  '62756': {
    zip: '62756',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.797359',
    longitude: '-89.654776',
    timeZoneId: 'America/Chicago',
  },
  '62757': {
    zip: '62757',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.799583',
    longitude: '-89.645158',
    timeZoneId: 'America/Chicago',
  },
  '62761': {
    zip: '62761',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.804222',
    longitude: '-89.660644',
    timeZoneId: 'America/Chicago',
  },
  '62762': {
    zip: '62762',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.787857',
    longitude: '-89.652226',
    timeZoneId: 'America/Chicago',
  },
  '62763': {
    zip: '62763',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.786222',
    longitude: '-89.656263',
    timeZoneId: 'America/Chicago',
  },
  '62764': {
    zip: '62764',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.757628',
    longitude: '-89.635423',
    timeZoneId: 'America/Chicago',
  },
  '62765': {
    zip: '62765',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.801449',
    longitude: '-89.645216',
    timeZoneId: 'America/Chicago',
  },
  '62766': {
    zip: '62766',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.774254',
    longitude: '-89.607845',
    timeZoneId: 'America/Chicago',
  },
  '62767': {
    zip: '62767',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.804644',
    longitude: '-89.655319',
    timeZoneId: 'America/Chicago',
  },
  '62769': {
    zip: '62769',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.805964',
    longitude: '-89.643223',
    timeZoneId: 'America/Chicago',
  },
  '62776': {
    zip: '62776',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.802184',
    longitude: '-89.654242',
    timeZoneId: 'America/Chicago',
  },
  '62777': {
    zip: '62777',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.800049',
    longitude: '-89.654254',
    timeZoneId: 'America/Chicago',
  },
  '62781': {
    zip: '62781',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.810838',
    longitude: '-89.655757',
    timeZoneId: 'America/Chicago',
  },
  '62786': {
    zip: '62786',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.803138',
    longitude: '-89.658075',
    timeZoneId: 'America/Chicago',
  },
  '62791': {
    zip: '62791',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.851073',
    longitude: '-89.631848',
    timeZoneId: 'America/Chicago',
  },
  '62794': {
    zip: '62794',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.761841',
    longitude: '-89.631213',
    timeZoneId: 'America/Chicago',
  },
  '62796': {
    zip: '62796',
    city: 'Springfield',
    state: 'IL',
    latitude: '39.797299',
    longitude: '-89.621525',
    timeZoneId: 'America/Chicago',
  },
  '62801': {
    zip: '62801',
    city: 'Centralia',
    state: 'IL',
    latitude: '38.53212',
    longitude: '-89.13702',
    timeZoneId: 'America/Chicago',
  },
  '62803': {
    zip: '62803',
    city: 'Hoyleton',
    state: 'IL',
    latitude: '38.451703',
    longitude: '-89.313807',
    timeZoneId: 'America/Chicago',
  },
  '62806': {
    zip: '62806',
    city: 'Albion',
    state: 'IL',
    latitude: '38.377481',
    longitude: '-88.056083',
    timeZoneId: 'America/Chicago',
  },
  '62807': {
    zip: '62807',
    city: 'Alma',
    state: 'IL',
    latitude: '38.735222',
    longitude: '-88.922226',
    timeZoneId: 'America/Chicago',
  },
  '62808': {
    zip: '62808',
    city: 'Ashley',
    state: 'IL',
    latitude: '38.328964',
    longitude: '-89.188569',
    timeZoneId: 'America/Chicago',
  },
  '62809': {
    zip: '62809',
    city: 'Barnhill',
    state: 'IL',
    latitude: '38.275356',
    longitude: '-88.388782',
    timeZoneId: 'America/Chicago',
  },
  '62810': {
    zip: '62810',
    city: 'Belle Rive',
    state: 'IL',
    latitude: '38.190078',
    longitude: '-88.761816',
    timeZoneId: 'America/Chicago',
  },
  '62811': {
    zip: '62811',
    city: 'Bellmont',
    state: 'IL',
    latitude: '38.383961',
    longitude: '-87.911522',
    timeZoneId: 'America/Chicago',
  },
  '62812': {
    zip: '62812',
    city: 'Benton',
    state: 'IL',
    latitude: '38.003056',
    longitude: '-88.938333',
    timeZoneId: 'America/Chicago',
  },
  '62814': {
    zip: '62814',
    city: 'Bluford',
    state: 'IL',
    latitude: '38.438972',
    longitude: '-88.714863',
    timeZoneId: 'America/Chicago',
  },
  '62815': {
    zip: '62815',
    city: 'Bone Gap',
    state: 'IL',
    latitude: '38.449282',
    longitude: '-87.999631',
    timeZoneId: 'America/Chicago',
  },
  '62816': {
    zip: '62816',
    city: 'Bonnie',
    state: 'IL',
    latitude: '38.196392',
    longitude: '-88.939503',
    timeZoneId: 'America/Chicago',
  },
  '62817': {
    zip: '62817',
    city: 'Broughton',
    state: 'IL',
    latitude: '37.960013',
    longitude: '-88.453753',
    timeZoneId: 'America/Chicago',
  },
  '62818': {
    zip: '62818',
    city: 'Browns',
    state: 'IL',
    latitude: '38.370994',
    longitude: '-87.996316',
    timeZoneId: 'America/Chicago',
  },
  '62819': {
    zip: '62819',
    city: 'Buckner',
    state: 'IL',
    latitude: '37.983174',
    longitude: '-89.01302',
    timeZoneId: 'America/Chicago',
  },
  '62820': {
    zip: '62820',
    city: 'Burnt Prairie',
    state: 'IL',
    latitude: '38.190633',
    longitude: '-88.210081',
    timeZoneId: 'America/Chicago',
  },
  '62821': {
    zip: '62821',
    city: 'Carmi',
    state: 'IL',
    latitude: '38.06622',
    longitude: '-88.13832',
    timeZoneId: 'America/Chicago',
  },
  '62822': {
    zip: '62822',
    city: 'Christopher',
    state: 'IL',
    latitude: '37.973941',
    longitude: '-89.051983',
    timeZoneId: 'America/Chicago',
  },
  '62823': {
    zip: '62823',
    city: 'Cisne',
    state: 'IL',
    latitude: '38.515855',
    longitude: '-88.36641',
    timeZoneId: 'America/Chicago',
  },
  '62824': {
    zip: '62824',
    city: 'Clay City',
    state: 'IL',
    latitude: '38.688177',
    longitude: '-88.360321',
    timeZoneId: 'America/Chicago',
  },
  '62825': {
    zip: '62825',
    city: 'Coello',
    state: 'IL',
    latitude: '37.994474',
    longitude: '-89.051247',
    timeZoneId: 'America/Chicago',
  },
  '62827': {
    zip: '62827',
    city: 'Crossville',
    state: 'IL',
    latitude: '38.169678',
    longitude: '-88.066046',
    timeZoneId: 'America/Chicago',
  },
  '62828': {
    zip: '62828',
    city: 'Dahlgren',
    state: 'IL',
    latitude: '38.189495',
    longitude: '-88.540853',
    timeZoneId: 'America/Chicago',
  },
  '62829': {
    zip: '62829',
    city: 'Dale',
    state: 'IL',
    latitude: '37.995516',
    longitude: '-88.532996',
    timeZoneId: 'America/Chicago',
  },
  '62830': {
    zip: '62830',
    city: 'Dix',
    state: 'IL',
    latitude: '38.441733',
    longitude: '-88.935689',
    timeZoneId: 'America/Chicago',
  },
  '62831': {
    zip: '62831',
    city: 'Du Bois',
    state: 'IL',
    latitude: '38.25659',
    longitude: '-89.20371',
    timeZoneId: 'America/Chicago',
  },
  '62832': {
    zip: '62832',
    city: 'Du Quoin',
    state: 'IL',
    latitude: '38.009216',
    longitude: '-89.237329',
    timeZoneId: 'America/Chicago',
  },
  '62833': {
    zip: '62833',
    city: 'Ellery',
    state: 'IL',
    latitude: '38.35777',
    longitude: '-88.133643',
    timeZoneId: 'America/Chicago',
  },
  '62834': {
    zip: '62834',
    city: 'Emma',
    state: 'IL',
    latitude: '37.973611',
    longitude: '-88.121084',
    timeZoneId: 'America/Chicago',
  },
  '62835': {
    zip: '62835',
    city: 'Enfield',
    state: 'IL',
    latitude: '38.101164',
    longitude: '-88.336661',
    timeZoneId: 'America/Chicago',
  },
  '62836': {
    zip: '62836',
    city: 'Ewing',
    state: 'IL',
    latitude: '38.074329',
    longitude: '-88.801938',
    timeZoneId: 'America/Chicago',
  },
  '62837': {
    zip: '62837',
    city: 'Fairfield',
    state: 'IL',
    latitude: '38.382854',
    longitude: '-88.364987',
    timeZoneId: 'America/Chicago',
  },
  '62838': {
    zip: '62838',
    city: 'Farina',
    state: 'IL',
    latitude: '38.8314',
    longitude: '-88.770151',
    timeZoneId: 'America/Chicago',
  },
  '62839': {
    zip: '62839',
    city: 'Flora',
    state: 'IL',
    latitude: '38.669142',
    longitude: '-88.485148',
    timeZoneId: 'America/Chicago',
  },
  '62840': {
    zip: '62840',
    city: 'Frankfort Heights',
    state: 'IL',
    latitude: '37.900295',
    longitude: '-88.840796',
    timeZoneId: 'America/Chicago',
  },
  '62841': {
    zip: '62841',
    city: 'Freeman Spur',
    state: 'IL',
    latitude: '37.801882',
    longitude: '-89.008751',
    timeZoneId: 'America/Chicago',
  },
  '62842': {
    zip: '62842',
    city: 'Geff',
    state: 'IL',
    latitude: '38.440349',
    longitude: '-88.426303',
    timeZoneId: 'America/Chicago',
  },
  '62843': {
    zip: '62843',
    city: 'Golden Gate',
    state: 'IL',
    latitude: '38.361539',
    longitude: '-88.20391',
    timeZoneId: 'America/Chicago',
  },
  '62844': {
    zip: '62844',
    city: 'Grayville',
    state: 'IL',
    latitude: '38.235615',
    longitude: '-88.015319',
    timeZoneId: 'America/Chicago',
  },
  '62846': {
    zip: '62846',
    city: 'Ina',
    state: 'IL',
    latitude: '38.15239',
    longitude: '-88.904498',
    timeZoneId: 'America/Chicago',
  },
  '62848': {
    zip: '62848',
    city: 'Irvington',
    state: 'IL',
    latitude: '38.438762',
    longitude: '-89.164269',
    timeZoneId: 'America/Chicago',
  },
  '62849': {
    zip: '62849',
    city: 'Iuka',
    state: 'IL',
    latitude: '38.613375',
    longitude: '-88.790283',
    timeZoneId: 'America/Chicago',
  },
  '62850': {
    zip: '62850',
    city: 'Johnsonville',
    state: 'IL',
    latitude: '38.52648',
    longitude: '-88.59012',
    timeZoneId: 'America/Chicago',
  },
  '62851': {
    zip: '62851',
    city: 'Keenes',
    state: 'IL',
    latitude: '38.3643',
    longitude: '-88.65612',
    timeZoneId: 'America/Chicago',
  },
  '62852': {
    zip: '62852',
    city: 'Keensburg',
    state: 'IL',
    latitude: '38.350337',
    longitude: '-87.864112',
    timeZoneId: 'America/Chicago',
  },
  '62853': {
    zip: '62853',
    city: 'Kell',
    state: 'IL',
    latitude: '38.541111',
    longitude: '-88.912222',
    timeZoneId: 'America/Chicago',
  },
  '62854': {
    zip: '62854',
    city: 'Kinmundy',
    state: 'IL',
    latitude: '38.77047',
    longitude: '-88.849169',
    timeZoneId: 'America/Chicago',
  },
  '62856': {
    zip: '62856',
    city: 'Logan',
    state: 'IL',
    latitude: '37.954367',
    longitude: '-88.836731',
    timeZoneId: 'America/Chicago',
  },
  '62858': {
    zip: '62858',
    city: 'Louisville',
    state: 'IL',
    latitude: '38.8263',
    longitude: '-88.48368',
    timeZoneId: 'America/Chicago',
  },
  '62859': {
    zip: '62859',
    city: 'Mc Leansboro',
    state: 'IL',
    latitude: '38.12',
    longitude: '-88.453056',
    timeZoneId: 'America/Chicago',
  },
  '62860': {
    zip: '62860',
    city: 'Macedonia',
    state: 'IL',
    latitude: '38.009242',
    longitude: '-88.683189',
    timeZoneId: 'America/Chicago',
  },
  '62861': {
    zip: '62861',
    city: 'Maunie',
    state: 'IL',
    latitude: '38.034049',
    longitude: '-88.045662',
    timeZoneId: 'America/Chicago',
  },
  '62862': {
    zip: '62862',
    city: 'Mill Shoals',
    state: 'IL',
    latitude: '38.088088',
    longitude: '-88.171431',
    timeZoneId: 'America/Chicago',
  },
  '62863': {
    zip: '62863',
    city: 'Mount Carmel',
    state: 'IL',
    latitude: '38.416747',
    longitude: '-87.765751',
    timeZoneId: 'America/Chicago',
  },
  '62864': {
    zip: '62864',
    city: 'Mount Vernon',
    state: 'IL',
    latitude: '38.31764',
    longitude: '-88.909298',
    timeZoneId: 'America/Chicago',
  },
  '62865': {
    zip: '62865',
    city: 'Mulkeytown',
    state: 'IL',
    latitude: '37.98918',
    longitude: '-89.109184',
    timeZoneId: 'America/Chicago',
  },
  '62866': {
    zip: '62866',
    city: 'Nason',
    state: 'IL',
    latitude: '38.161713',
    longitude: '-88.979469',
    timeZoneId: 'America/Chicago',
  },
  '62867': {
    zip: '62867',
    city: 'New Haven',
    state: 'IL',
    latitude: '37.85482',
    longitude: '-88.099558',
    timeZoneId: 'America/Chicago',
  },
  '62868': {
    zip: '62868',
    city: 'Noble',
    state: 'IL',
    latitude: '38.700634',
    longitude: '-88.223601',
    timeZoneId: 'America/Chicago',
  },
  '62869': {
    zip: '62869',
    city: 'Norris City',
    state: 'IL',
    latitude: '37.977903',
    longitude: '-88.328317',
    timeZoneId: 'America/Chicago',
  },
  '62870': {
    zip: '62870',
    city: 'Odin',
    state: 'IL',
    latitude: '38.614783',
    longitude: '-89.051397',
    timeZoneId: 'America/Chicago',
  },
  '62871': {
    zip: '62871',
    city: 'Omaha',
    state: 'IL',
    latitude: '37.886303',
    longitude: '-88.267731',
    timeZoneId: 'America/Chicago',
  },
  '62872': {
    zip: '62872',
    city: 'Opdyke',
    state: 'IL',
    latitude: '38.276278',
    longitude: '-88.754542',
    timeZoneId: 'America/Chicago',
  },
  '62874': {
    zip: '62874',
    city: 'Orient',
    state: 'IL',
    latitude: '37.916687',
    longitude: '-88.973759',
    timeZoneId: 'America/Chicago',
  },
  '62875': {
    zip: '62875',
    city: 'Patoka',
    state: 'IL',
    latitude: '38.753045',
    longitude: '-89.096352',
    timeZoneId: 'America/Chicago',
  },
  '62876': {
    zip: '62876',
    city: 'Radom',
    state: 'IL',
    latitude: '38.282401',
    longitude: '-89.192991',
    timeZoneId: 'America/Chicago',
  },
  '62877': {
    zip: '62877',
    city: 'Richview',
    state: 'IL',
    latitude: '38.402191',
    longitude: '-89.201216',
    timeZoneId: 'America/Chicago',
  },
  '62878': {
    zip: '62878',
    city: 'Rinard',
    state: 'IL',
    latitude: '38.583362',
    longitude: '-88.476223',
    timeZoneId: 'America/Chicago',
  },
  '62879': {
    zip: '62879',
    city: 'Sailor Springs',
    state: 'IL',
    latitude: '38.763612',
    longitude: '-88.359594',
    timeZoneId: 'America/Chicago',
  },
  '62880': {
    zip: '62880',
    city: 'Saint Peter',
    state: 'IL',
    latitude: '38.868041',
    longitude: '-88.865839',
    timeZoneId: 'America/Chicago',
  },
  '62881': {
    zip: '62881',
    city: 'Salem',
    state: 'IL',
    latitude: '38.62836',
    longitude: '-88.946423',
    timeZoneId: 'America/Chicago',
  },
  '62882': {
    zip: '62882',
    city: 'Sandoval',
    state: 'IL',
    latitude: '38.612584',
    longitude: '-89.121037',
    timeZoneId: 'America/Chicago',
  },
  '62883': {
    zip: '62883',
    city: 'Scheller',
    state: 'IL',
    latitude: '38.167601',
    longitude: '-89.094075',
    timeZoneId: 'America/Chicago',
  },
  '62884': {
    zip: '62884',
    city: 'Sesser',
    state: 'IL',
    latitude: '38.090961',
    longitude: '-89.049578',
    timeZoneId: 'America/Chicago',
  },
  '62885': {
    zip: '62885',
    city: 'Shobonier',
    state: 'IL',
    latitude: '38.842965',
    longitude: '-89.082598',
    timeZoneId: 'America/Chicago',
  },
  '62886': {
    zip: '62886',
    city: 'Sims',
    state: 'IL',
    latitude: '38.411879',
    longitude: '-88.528805',
    timeZoneId: 'America/Chicago',
  },
  '62887': {
    zip: '62887',
    city: 'Springerton',
    state: 'IL',
    latitude: '38.164229',
    longitude: '-88.374663',
    timeZoneId: 'America/Chicago',
  },
  '62888': {
    zip: '62888',
    city: 'Tamaroa',
    state: 'IL',
    latitude: '38.148503',
    longitude: '-89.210204',
    timeZoneId: 'America/Chicago',
  },
  '62889': {
    zip: '62889',
    city: 'Texico',
    state: 'IL',
    latitude: '38.466107',
    longitude: '-88.752074',
    timeZoneId: 'America/Chicago',
  },
  '62890': {
    zip: '62890',
    city: 'Thompsonville',
    state: 'IL',
    latitude: '37.916668',
    longitude: '-88.763454',
    timeZoneId: 'America/Chicago',
  },
  '62891': {
    zip: '62891',
    city: 'Valier',
    state: 'IL',
    latitude: '38.019529',
    longitude: '-89.043211',
    timeZoneId: 'America/Chicago',
  },
  '62892': {
    zip: '62892',
    city: 'Vernon',
    state: 'IL',
    latitude: '38.805',
    longitude: '-89.08295',
    timeZoneId: 'America/Chicago',
  },
  '62893': {
    zip: '62893',
    city: 'Walnut Hill',
    state: 'IL',
    latitude: '38.517277',
    longitude: '-88.978021',
    timeZoneId: 'America/Chicago',
  },
  '62894': {
    zip: '62894',
    city: 'Waltonville',
    state: 'IL',
    latitude: '38.316963',
    longitude: '-88.911647',
    timeZoneId: 'America/Chicago',
  },
  '62895': {
    zip: '62895',
    city: 'Wayne City',
    state: 'IL',
    latitude: '38.3166',
    longitude: '-88.57068',
    timeZoneId: 'America/Chicago',
  },
  '62896': {
    zip: '62896',
    city: 'West Frankfort',
    state: 'IL',
    latitude: '37.898902',
    longitude: '-88.922036',
    timeZoneId: 'America/Chicago',
  },
  '62897': {
    zip: '62897',
    city: 'Whittington',
    state: 'IL',
    latitude: '38.107866',
    longitude: '-88.875437',
    timeZoneId: 'America/Chicago',
  },
  '62898': {
    zip: '62898',
    city: 'Woodlawn',
    state: 'IL',
    latitude: '38.38701',
    longitude: '-89.091555',
    timeZoneId: 'America/Chicago',
  },
  '62899': {
    zip: '62899',
    city: 'Xenia',
    state: 'IL',
    latitude: '38.697541',
    longitude: '-88.638859',
    timeZoneId: 'America/Chicago',
  },
  '62901': {
    zip: '62901',
    city: 'Carbondale',
    state: 'IL',
    latitude: '37.728408',
    longitude: '-89.222946',
    timeZoneId: 'America/Chicago',
  },
  '62902': {
    zip: '62902',
    city: 'Carbondale',
    state: 'IL',
    latitude: '37.721102',
    longitude: '-89.194086',
    timeZoneId: 'America/Chicago',
  },
  '62903': {
    zip: '62903',
    city: 'Carbondale',
    state: 'IL',
    latitude: '37.727269',
    longitude: '-89.209493',
    timeZoneId: 'America/Chicago',
  },
  '62905': {
    zip: '62905',
    city: 'Alto Pass',
    state: 'IL',
    latitude: '37.55705',
    longitude: '-89.33965',
    timeZoneId: 'America/Chicago',
  },
  '62906': {
    zip: '62906',
    city: 'Anna',
    state: 'IL',
    latitude: '37.4634',
    longitude: '-89.22996',
    timeZoneId: 'America/Chicago',
  },
  '62907': {
    zip: '62907',
    city: 'Ava',
    state: 'IL',
    latitude: '37.882657',
    longitude: '-89.448679',
    timeZoneId: 'America/Chicago',
  },
  '62908': {
    zip: '62908',
    city: 'Belknap',
    state: 'IL',
    latitude: '37.328034',
    longitude: '-88.95083',
    timeZoneId: 'America/Chicago',
  },
  '62909': {
    zip: '62909',
    city: 'Boles',
    state: 'IL',
    latitude: '37.465808',
    longitude: '-88.996359',
    timeZoneId: 'America/Chicago',
  },
  '62910': {
    zip: '62910',
    city: 'Brookport',
    state: 'IL',
    latitude: '37.125868',
    longitude: '-88.629383',
    timeZoneId: 'America/Chicago',
  },
  '62912': {
    zip: '62912',
    city: 'Buncombe',
    state: 'IL',
    latitude: '37.464327',
    longitude: '-88.986086',
    timeZoneId: 'America/Chicago',
  },
  '62914': {
    zip: '62914',
    city: 'Cairo',
    state: 'IL',
    latitude: '37.00984',
    longitude: '-89.180112',
    timeZoneId: 'America/Chicago',
  },
  '62915': {
    zip: '62915',
    city: 'Cambria',
    state: 'IL',
    latitude: '37.781551',
    longitude: '-89.119596',
    timeZoneId: 'America/Chicago',
  },
  '62916': {
    zip: '62916',
    city: 'Campbell Hill',
    state: 'IL',
    latitude: '37.915585',
    longitude: '-89.593551',
    timeZoneId: 'America/Chicago',
  },
  '62917': {
    zip: '62917',
    city: 'Carrier Mills',
    state: 'IL',
    latitude: '37.690197',
    longitude: '-88.627956',
    timeZoneId: 'America/Chicago',
  },
  '62918': {
    zip: '62918',
    city: 'Carterville',
    state: 'IL',
    latitude: '37.78902',
    longitude: '-89.09652',
    timeZoneId: 'America/Chicago',
  },
  '62919': {
    zip: '62919',
    city: 'Cave in Rock',
    state: 'IL',
    latitude: '37.539067',
    longitude: '-88.238667',
    timeZoneId: 'America/Chicago',
  },
  '62920': {
    zip: '62920',
    city: 'Cobden',
    state: 'IL',
    latitude: '37.533523',
    longitude: '-89.254303',
    timeZoneId: 'America/Chicago',
  },
  '62921': {
    zip: '62921',
    city: 'Colp',
    state: 'IL',
    latitude: '37.804393',
    longitude: '-89.081265',
    timeZoneId: 'America/Chicago',
  },
  '62922': {
    zip: '62922',
    city: 'Creal Springs',
    state: 'IL',
    latitude: '37.618809',
    longitude: '-88.837781',
    timeZoneId: 'America/Chicago',
  },
  '62923': {
    zip: '62923',
    city: 'Cypress',
    state: 'IL',
    latitude: '37.451755',
    longitude: '-89.273521',
    timeZoneId: 'America/Chicago',
  },
  '62924': {
    zip: '62924',
    city: 'De Soto',
    state: 'IL',
    latitude: '37.82418',
    longitude: '-89.22486',
    timeZoneId: 'America/Chicago',
  },
  '62926': {
    zip: '62926',
    city: 'Dongola',
    state: 'IL',
    latitude: '37.36069',
    longitude: '-89.167094',
    timeZoneId: 'America/Chicago',
  },
  '62927': {
    zip: '62927',
    city: 'Dowell',
    state: 'IL',
    latitude: '37.942727',
    longitude: '-89.237983',
    timeZoneId: 'America/Chicago',
  },
  '62928': {
    zip: '62928',
    city: 'Eddyville',
    state: 'IL',
    latitude: '37.537118',
    longitude: '-88.596434',
    timeZoneId: 'America/Chicago',
  },
  '62930': {
    zip: '62930',
    city: 'Eldorado',
    state: 'IL',
    latitude: '37.813759',
    longitude: '-88.441389',
    timeZoneId: 'America/Chicago',
  },
  '62931': {
    zip: '62931',
    city: 'Elizabethtown',
    state: 'IL',
    latitude: '37.487388',
    longitude: '-88.268499',
    timeZoneId: 'America/Chicago',
  },
  '62932': {
    zip: '62932',
    city: 'Elkville',
    state: 'IL',
    latitude: '37.911722',
    longitude: '-89.233161',
    timeZoneId: 'America/Chicago',
  },
  '62933': {
    zip: '62933',
    city: 'Energy',
    state: 'IL',
    latitude: '37.774589',
    longitude: '-89.022459',
    timeZoneId: 'America/Chicago',
  },
  '62934': {
    zip: '62934',
    city: 'Equality',
    state: 'IL',
    latitude: '37.731422',
    longitude: '-88.34347',
    timeZoneId: 'America/Chicago',
  },
  '62935': {
    zip: '62935',
    city: 'Galatia',
    state: 'IL',
    latitude: '37.844203',
    longitude: '-88.609011',
    timeZoneId: 'America/Chicago',
  },
  '62938': {
    zip: '62938',
    city: 'Golconda',
    state: 'IL',
    latitude: '37.357533',
    longitude: '-88.488804',
    timeZoneId: 'America/Chicago',
  },
  '62939': {
    zip: '62939',
    city: 'Goreville',
    state: 'IL',
    latitude: '37.554162',
    longitude: '-88.969995',
    timeZoneId: 'America/Chicago',
  },
  '62940': {
    zip: '62940',
    city: 'Gorham',
    state: 'IL',
    latitude: '37.745311',
    longitude: '-89.441223',
    timeZoneId: 'America/Chicago',
  },
  '62941': {
    zip: '62941',
    city: 'Grand Chain',
    state: 'IL',
    latitude: '37.250785',
    longitude: '-89.010847',
    timeZoneId: 'America/Chicago',
  },
  '62942': {
    zip: '62942',
    city: 'Grand Tower',
    state: 'IL',
    latitude: '37.639885',
    longitude: '-89.467041',
    timeZoneId: 'America/Chicago',
  },
  '62943': {
    zip: '62943',
    city: 'Grantsburg',
    state: 'IL',
    latitude: '37.379602',
    longitude: '-88.76419',
    timeZoneId: 'America/Chicago',
  },
  '62946': {
    zip: '62946',
    city: 'Harrisburg',
    state: 'IL',
    latitude: '37.735404',
    longitude: '-88.544975',
    timeZoneId: 'America/Chicago',
  },
  '62947': {
    zip: '62947',
    city: 'Herod',
    state: 'IL',
    latitude: '37.491775',
    longitude: '-88.478187',
    timeZoneId: 'America/Chicago',
  },
  '62948': {
    zip: '62948',
    city: 'Herrin',
    state: 'IL',
    latitude: '37.801582',
    longitude: '-89.028075',
    timeZoneId: 'America/Chicago',
  },
  '62949': {
    zip: '62949',
    city: 'Hurst',
    state: 'IL',
    latitude: '37.831859',
    longitude: '-89.141272',
    timeZoneId: 'America/Chicago',
  },
  '62950': {
    zip: '62950',
    city: 'Jacob',
    state: 'IL',
    latitude: '37.747337',
    longitude: '-89.546002',
    timeZoneId: 'America/Chicago',
  },
  '62951': {
    zip: '62951',
    city: 'Johnston City',
    state: 'IL',
    latitude: '37.819324',
    longitude: '-88.924585',
    timeZoneId: 'America/Chicago',
  },
  '62952': {
    zip: '62952',
    city: 'Jonesboro',
    state: 'IL',
    latitude: '37.452013',
    longitude: '-89.269945',
    timeZoneId: 'America/Chicago',
  },
  '62953': {
    zip: '62953',
    city: 'Joppa',
    state: 'IL',
    latitude: '37.208604',
    longitude: '-88.846425',
    timeZoneId: 'America/Chicago',
  },
  '62954': {
    zip: '62954',
    city: 'Junction',
    state: 'IL',
    latitude: '37.680834',
    longitude: '-88.257353',
    timeZoneId: 'America/Chicago',
  },
  '62956': {
    zip: '62956',
    city: 'Karnak',
    state: 'IL',
    latitude: '37.292272',
    longitude: '-88.977403',
    timeZoneId: 'America/Chicago',
  },
  '62957': {
    zip: '62957',
    city: 'Mc Clure',
    state: 'IL',
    latitude: '37.295028',
    longitude: '-89.495807',
    timeZoneId: 'America/Chicago',
  },
  '62958': {
    zip: '62958',
    city: 'Makanda',
    state: 'IL',
    latitude: '37.637065',
    longitude: '-89.186902',
    timeZoneId: 'America/Chicago',
  },
  '62959': {
    zip: '62959',
    city: 'Marion',
    state: 'IL',
    latitude: '37.722939',
    longitude: '-88.873368',
    timeZoneId: 'America/Chicago',
  },
  '62960': {
    zip: '62960',
    city: 'Metropolis',
    state: 'IL',
    latitude: '37.154433',
    longitude: '-88.723675',
    timeZoneId: 'America/Chicago',
  },
  '62961': {
    zip: '62961',
    city: 'Millcreek',
    state: 'IL',
    latitude: '37.351655',
    longitude: '-89.272492',
    timeZoneId: 'America/Chicago',
  },
  '62962': {
    zip: '62962',
    city: 'Miller City',
    state: 'IL',
    latitude: '37.086225',
    longitude: '-89.352866',
    timeZoneId: 'America/Chicago',
  },
  '62963': {
    zip: '62963',
    city: 'Mound City',
    state: 'IL',
    latitude: '37.091182',
    longitude: '-89.16735',
    timeZoneId: 'America/Chicago',
  },
  '62964': {
    zip: '62964',
    city: 'Mounds',
    state: 'IL',
    latitude: '37.116221',
    longitude: '-89.194753',
    timeZoneId: 'America/Chicago',
  },
  '62965': {
    zip: '62965',
    city: 'Muddy',
    state: 'IL',
    latitude: '37.764433',
    longitude: '-88.517106',
    timeZoneId: 'America/Chicago',
  },
  '62966': {
    zip: '62966',
    city: 'Murphysboro',
    state: 'IL',
    latitude: '37.76515',
    longitude: '-89.342282',
    timeZoneId: 'America/Chicago',
  },
  '62967': {
    zip: '62967',
    city: 'New Burnside',
    state: 'IL',
    latitude: '37.580076',
    longitude: '-88.76214',
    timeZoneId: 'America/Chicago',
  },
  '62969': {
    zip: '62969',
    city: 'Olive Branch',
    state: 'IL',
    latitude: '37.167231',
    longitude: '-89.355121',
    timeZoneId: 'America/Chicago',
  },
  '62970': {
    zip: '62970',
    city: 'Olmsted',
    state: 'IL',
    latitude: '37.210434',
    longitude: '-89.098545',
    timeZoneId: 'America/Chicago',
  },
  '62972': {
    zip: '62972',
    city: 'Ozark',
    state: 'IL',
    latitude: '37.53389',
    longitude: '-88.763201',
    timeZoneId: 'America/Chicago',
  },
  '62973': {
    zip: '62973',
    city: 'Perks',
    state: 'IL',
    latitude: '37.308548',
    longitude: '-89.079704',
    timeZoneId: 'America/Chicago',
  },
  '62974': {
    zip: '62974',
    city: 'Pittsburg',
    state: 'IL',
    latitude: '37.787286',
    longitude: '-88.865717',
    timeZoneId: 'America/Chicago',
  },
  '62975': {
    zip: '62975',
    city: 'Pomona',
    state: 'IL',
    latitude: '37.643077',
    longitude: '-89.349507',
    timeZoneId: 'America/Chicago',
  },
  '62976': {
    zip: '62976',
    city: 'Pulaski',
    state: 'IL',
    latitude: '37.213602',
    longitude: '-89.199138',
    timeZoneId: 'America/Chicago',
  },
  '62977': {
    zip: '62977',
    city: 'Raleigh',
    state: 'IL',
    latitude: '37.824107',
    longitude: '-88.545944',
    timeZoneId: 'America/Chicago',
  },
  '62979': {
    zip: '62979',
    city: 'Ridgway',
    state: 'IL',
    latitude: '37.80444',
    longitude: '-88.26',
    timeZoneId: 'America/Chicago',
  },
  '62982': {
    zip: '62982',
    city: 'Rosiclare',
    state: 'IL',
    latitude: '37.421501',
    longitude: '-88.35685',
    timeZoneId: 'America/Chicago',
  },
  '62983': {
    zip: '62983',
    city: 'Royalton',
    state: 'IL',
    latitude: '37.883227',
    longitude: '-89.105089',
    timeZoneId: 'America/Chicago',
  },
  '62984': {
    zip: '62984',
    city: 'Shawneetown',
    state: 'IL',
    latitude: '37.715092',
    longitude: '-88.188836',
    timeZoneId: 'America/Chicago',
  },
  '62985': {
    zip: '62985',
    city: 'Simpson',
    state: 'IL',
    latitude: '37.443539',
    longitude: '-88.639922',
    timeZoneId: 'America/Chicago',
  },
  '62987': {
    zip: '62987',
    city: 'Stonefort',
    state: 'IL',
    latitude: '37.63714',
    longitude: '-88.740772',
    timeZoneId: 'America/Chicago',
  },
  '62988': {
    zip: '62988',
    city: 'Tamms',
    state: 'IL',
    latitude: '37.234196',
    longitude: '-89.266755',
    timeZoneId: 'America/Chicago',
  },
  '62990': {
    zip: '62990',
    city: 'Thebes',
    state: 'IL',
    latitude: '37.189836',
    longitude: '-89.393166',
    timeZoneId: 'America/Chicago',
  },
  '62992': {
    zip: '62992',
    city: 'Ullin',
    state: 'IL',
    latitude: '37.269766',
    longitude: '-89.17684',
    timeZoneId: 'America/Chicago',
  },
  '62993': {
    zip: '62993',
    city: 'Unity',
    state: 'IL',
    latitude: '37.236409',
    longitude: '-89.269604',
    timeZoneId: 'America/Chicago',
  },
  '62994': {
    zip: '62994',
    city: 'Vergennes',
    state: 'IL',
    latitude: '37.910383',
    longitude: '-89.321947',
    timeZoneId: 'America/Chicago',
  },
  '62995': {
    zip: '62995',
    city: 'Vienna',
    state: 'IL',
    latitude: '37.418189',
    longitude: '-88.898747',
    timeZoneId: 'America/Chicago',
  },
  '62996': {
    zip: '62996',
    city: 'Villa Ridge',
    state: 'IL',
    latitude: '37.157145',
    longitude: '-89.175913',
    timeZoneId: 'America/Chicago',
  },
  '62997': {
    zip: '62997',
    city: 'Willisville',
    state: 'IL',
    latitude: '37.983109',
    longitude: '-89.55848',
    timeZoneId: 'America/Chicago',
  },
  '62998': {
    zip: '62998',
    city: 'Wolf Lake',
    state: 'IL',
    latitude: '37.532673',
    longitude: '-89.44385',
    timeZoneId: 'America/Chicago',
  },
  '62999': {
    zip: '62999',
    city: 'Zeigler',
    state: 'IL',
    latitude: '37.902282',
    longitude: '-89.055677',
    timeZoneId: 'America/Chicago',
  },
  '63005': {
    zip: '63005',
    city: 'Chesterfield',
    state: 'MO',
    latitude: '38.634884',
    longitude: '-90.619343',
    timeZoneId: 'America/Chicago',
  },
  '63006': {
    zip: '63006',
    city: 'Chesterfield',
    state: 'MO',
    latitude: '38.662973',
    longitude: '-90.575371',
    timeZoneId: 'America/Chicago',
  },
  '63010': {
    zip: '63010',
    city: 'Arnold',
    state: 'MO',
    latitude: '38.431711',
    longitude: '-90.398001',
    timeZoneId: 'America/Chicago',
  },
  '63011': {
    zip: '63011',
    city: 'Ballwin',
    state: 'MO',
    latitude: '38.598725',
    longitude: '-90.547046',
    timeZoneId: 'America/Chicago',
  },
  '63012': {
    zip: '63012',
    city: 'Barnhart',
    state: 'MO',
    latitude: '38.336208',
    longitude: '-90.437797',
    timeZoneId: 'America/Chicago',
  },
  '63013': {
    zip: '63013',
    city: 'Beaufort',
    state: 'MO',
    latitude: '38.420673',
    longitude: '-91.18459',
    timeZoneId: 'America/Chicago',
  },
  '63014': {
    zip: '63014',
    city: 'Berger',
    state: 'MO',
    latitude: '38.65266',
    longitude: '-91.31928',
    timeZoneId: 'America/Chicago',
  },
  '63015': {
    zip: '63015',
    city: 'Catawissa',
    state: 'MO',
    latitude: '38.411262',
    longitude: '-90.765192',
    timeZoneId: 'America/Chicago',
  },
  '63016': {
    zip: '63016',
    city: 'Cedar Hill',
    state: 'MO',
    latitude: '38.352981',
    longitude: '-90.644798',
    timeZoneId: 'America/Chicago',
  },
  '63017': {
    zip: '63017',
    city: 'Chesterfield',
    state: 'MO',
    latitude: '38.647818',
    longitude: '-90.545814',
    timeZoneId: 'America/Chicago',
  },
  '63019': {
    zip: '63019',
    city: 'Crystal City',
    state: 'MO',
    latitude: '38.229875',
    longitude: '-90.373397',
    timeZoneId: 'America/Chicago',
  },
  '63020': {
    zip: '63020',
    city: 'De Soto',
    state: 'MO',
    latitude: '38.09046',
    longitude: '-90.5667',
    timeZoneId: 'America/Chicago',
  },
  '63021': {
    zip: '63021',
    city: 'Ballwin',
    state: 'MO',
    latitude: '38.576504',
    longitude: '-90.528928',
    timeZoneId: 'America/Chicago',
  },
  '63022': {
    zip: '63022',
    city: 'Ballwin',
    state: 'MO',
    latitude: '38.596552',
    longitude: '-90.546397',
    timeZoneId: 'America/Chicago',
  },
  '63023': {
    zip: '63023',
    city: 'Dittmer',
    state: 'MO',
    latitude: '38.305762',
    longitude: '-90.680759',
    timeZoneId: 'America/Chicago',
  },
  '63024': {
    zip: '63024',
    city: 'Ballwin',
    state: 'MO',
    latitude: '38.596264',
    longitude: '-90.543221',
    timeZoneId: 'America/Chicago',
  },
  '63025': {
    zip: '63025',
    city: 'Eureka',
    state: 'MO',
    latitude: '38.510003',
    longitude: '-90.642759',
    timeZoneId: 'America/Chicago',
  },
  '63026': {
    zip: '63026',
    city: 'Fenton',
    state: 'MO',
    latitude: '38.501758',
    longitude: '-90.456792',
    timeZoneId: 'America/Chicago',
  },
  '63028': {
    zip: '63028',
    city: 'Festus',
    state: 'MO',
    latitude: '38.180295',
    longitude: '-90.405608',
    timeZoneId: 'America/Chicago',
  },
  '63030': {
    zip: '63030',
    city: 'Fletcher',
    state: 'MO',
    latitude: '38.143599',
    longitude: '-90.75145',
    timeZoneId: 'America/Chicago',
  },
  '63031': {
    zip: '63031',
    city: 'Florissant',
    state: 'MO',
    latitude: '38.806089',
    longitude: '-90.343437',
    timeZoneId: 'America/Chicago',
  },
  '63032': {
    zip: '63032',
    city: 'Florissant',
    state: 'MO',
    latitude: '38.803152',
    longitude: '-90.311355',
    timeZoneId: 'America/Chicago',
  },
  '63033': {
    zip: '63033',
    city: 'Florissant',
    state: 'MO',
    latitude: '38.794669',
    longitude: '-90.277021',
    timeZoneId: 'America/Chicago',
  },
  '63034': {
    zip: '63034',
    city: 'Florissant',
    state: 'MO',
    latitude: '38.835444',
    longitude: '-90.289954',
    timeZoneId: 'America/Chicago',
  },
  '63036': {
    zip: '63036',
    city: 'French Village',
    state: 'MO',
    latitude: '37.764518',
    longitude: '-90.427334',
    timeZoneId: 'America/Chicago',
  },
  '63037': {
    zip: '63037',
    city: 'Gerald',
    state: 'MO',
    latitude: '38.398981',
    longitude: '-91.330048',
    timeZoneId: 'America/Chicago',
  },
  '63038': {
    zip: '63038',
    city: 'Wildwood',
    state: 'MO',
    latitude: '38.582772',
    longitude: '-90.65222',
    timeZoneId: 'America/Chicago',
  },
  '63039': {
    zip: '63039',
    city: 'Gray Summit',
    state: 'MO',
    latitude: '38.496101',
    longitude: '-90.83841',
    timeZoneId: 'America/Chicago',
  },
  '63040': {
    zip: '63040',
    city: 'Wildwood',
    state: 'MO',
    latitude: '38.576576',
    longitude: '-90.628174',
    timeZoneId: 'America/Chicago',
  },
  '63041': {
    zip: '63041',
    city: 'Grubville',
    state: 'MO',
    latitude: '38.231698',
    longitude: '-90.793851',
    timeZoneId: 'America/Chicago',
  },
  '63042': {
    zip: '63042',
    city: 'Hazelwood',
    state: 'MO',
    latitude: '38.777796',
    longitude: '-90.371867',
    timeZoneId: 'America/Chicago',
  },
  '63043': {
    zip: '63043',
    city: 'Maryland Heights',
    state: 'MO',
    latitude: '38.724027',
    longitude: '-90.439555',
    timeZoneId: 'America/Chicago',
  },
  '63044': {
    zip: '63044',
    city: 'Bridgeton',
    state: 'MO',
    latitude: '38.76078',
    longitude: '-90.41286',
    timeZoneId: 'America/Chicago',
  },
  '63045': {
    zip: '63045',
    city: 'Earth City',
    state: 'MO',
    latitude: '38.768694',
    longitude: '-90.463908',
    timeZoneId: 'America/Chicago',
  },
  '63047': {
    zip: '63047',
    city: 'Hematite',
    state: 'MO',
    latitude: '38.201959',
    longitude: '-90.47823',
    timeZoneId: 'America/Chicago',
  },
  '63048': {
    zip: '63048',
    city: 'Herculaneum',
    state: 'MO',
    latitude: '38.265637',
    longitude: '-90.384048',
    timeZoneId: 'America/Chicago',
  },
  '63049': {
    zip: '63049',
    city: 'High Ridge',
    state: 'MO',
    latitude: '38.470505',
    longitude: '-90.523777',
    timeZoneId: 'America/Chicago',
  },
  '63050': {
    zip: '63050',
    city: 'Hillsboro',
    state: 'MO',
    latitude: '38.266141',
    longitude: '-90.565313',
    timeZoneId: 'America/Chicago',
  },
  '63051': {
    zip: '63051',
    city: 'House Springs',
    state: 'MO',
    latitude: '38.412034',
    longitude: '-90.5589',
    timeZoneId: 'America/Chicago',
  },
  '63052': {
    zip: '63052',
    city: 'Imperial',
    state: 'MO',
    latitude: '38.390733',
    longitude: '-90.422015',
    timeZoneId: 'America/Chicago',
  },
  '63053': {
    zip: '63053',
    city: 'Kimmswick',
    state: 'MO',
    latitude: '38.3679',
    longitude: '-90.365393',
    timeZoneId: 'America/Chicago',
  },
  '63055': {
    zip: '63055',
    city: 'Labadie',
    state: 'MO',
    latitude: '38.52147',
    longitude: '-90.835673',
    timeZoneId: 'America/Chicago',
  },
  '63056': {
    zip: '63056',
    city: 'Leslie',
    state: 'MO',
    latitude: '38.445236',
    longitude: '-91.223446',
    timeZoneId: 'America/Chicago',
  },
  '63057': {
    zip: '63057',
    city: 'Liguori',
    state: 'MO',
    latitude: '38.33832',
    longitude: '-90.39882',
    timeZoneId: 'America/Chicago',
  },
  '63060': {
    zip: '63060',
    city: 'Lonedell',
    state: 'MO',
    latitude: '38.300532',
    longitude: '-90.830331',
    timeZoneId: 'America/Chicago',
  },
  '63061': {
    zip: '63061',
    city: 'Luebbering',
    state: 'MO',
    latitude: '38.267851',
    longitude: '-90.818825',
    timeZoneId: 'America/Chicago',
  },
  '63065': {
    zip: '63065',
    city: 'Mapaville',
    state: 'MO',
    latitude: '38.251399',
    longitude: '-90.485804',
    timeZoneId: 'America/Chicago',
  },
  '63066': {
    zip: '63066',
    city: 'Morse Mill',
    state: 'MO',
    latitude: '38.278052',
    longitude: '-90.653066',
    timeZoneId: 'America/Chicago',
  },
  '63068': {
    zip: '63068',
    city: 'New Haven',
    state: 'MO',
    latitude: '38.55654',
    longitude: '-91.25046',
    timeZoneId: 'America/Chicago',
  },
  '63069': {
    zip: '63069',
    city: 'Pacific',
    state: 'MO',
    latitude: '38.491435',
    longitude: '-90.765094',
    timeZoneId: 'America/Chicago',
  },
  '63070': {
    zip: '63070',
    city: 'Pevely',
    state: 'MO',
    latitude: '38.28378',
    longitude: '-90.44046',
    timeZoneId: 'America/Chicago',
  },
  '63071': {
    zip: '63071',
    city: 'Richwoods',
    state: 'MO',
    latitude: '38.153582',
    longitude: '-90.832931',
    timeZoneId: 'America/Chicago',
  },
  '63072': {
    zip: '63072',
    city: 'Robertsville',
    state: 'MO',
    latitude: '38.397409',
    longitude: '-90.828096',
    timeZoneId: 'America/Chicago',
  },
  '63073': {
    zip: '63073',
    city: 'Saint Albans',
    state: 'MO',
    latitude: '38.5791',
    longitude: '-90.77502',
    timeZoneId: 'America/Chicago',
  },
  '63074': {
    zip: '63074',
    city: 'Saint Ann',
    state: 'MO',
    latitude: '38.727416',
    longitude: '-90.387327',
    timeZoneId: 'America/Chicago',
  },
  '63077': {
    zip: '63077',
    city: 'Saint Clair',
    state: 'MO',
    latitude: '38.335319',
    longitude: '-90.973118',
    timeZoneId: 'America/Chicago',
  },
  '63079': {
    zip: '63079',
    city: 'Stanton',
    state: 'MO',
    latitude: '38.275896',
    longitude: '-91.104717',
    timeZoneId: 'America/Chicago',
  },
  '63080': {
    zip: '63080',
    city: 'Sullivan',
    state: 'MO',
    latitude: '38.26128',
    longitude: '-91.14156',
    timeZoneId: 'America/Chicago',
  },
  '63084': {
    zip: '63084',
    city: 'Union',
    state: 'MO',
    latitude: '38.429607',
    longitude: '-90.998487',
    timeZoneId: 'America/Chicago',
  },
  '63087': {
    zip: '63087',
    city: 'Valles Mines',
    state: 'MO',
    latitude: '37.934605',
    longitude: '-90.452399',
    timeZoneId: 'America/Chicago',
  },
  '63088': {
    zip: '63088',
    city: 'Valley Park',
    state: 'MO',
    latitude: '38.550608',
    longitude: '-90.490282',
    timeZoneId: 'America/Chicago',
  },
  '63089': {
    zip: '63089',
    city: 'Villa Ridge',
    state: 'MO',
    latitude: '38.463535',
    longitude: '-90.874188',
    timeZoneId: 'America/Chicago',
  },
  '63090': {
    zip: '63090',
    city: 'Washington',
    state: 'MO',
    latitude: '38.546118',
    longitude: '-91.015131',
    timeZoneId: 'America/Chicago',
  },
  '63091': {
    zip: '63091',
    city: 'Rosebud',
    state: 'MO',
    latitude: '38.381574',
    longitude: '-91.390301',
    timeZoneId: 'America/Chicago',
  },
  '63099': {
    zip: '63099',
    city: 'Fenton',
    state: 'MO',
    latitude: '38.536522',
    longitude: '-90.448052',
    timeZoneId: 'America/Chicago',
  },
  '63101': {
    zip: '63101',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.631974',
    longitude: '-90.191176',
    timeZoneId: 'America/Chicago',
  },
  '63102': {
    zip: '63102',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.636396',
    longitude: '-90.185776',
    timeZoneId: 'America/Chicago',
  },
  '63103': {
    zip: '63103',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.633829',
    longitude: '-90.213138',
    timeZoneId: 'America/Chicago',
  },
  '63104': {
    zip: '63104',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.613662',
    longitude: '-90.214255',
    timeZoneId: 'America/Chicago',
  },
  '63105': {
    zip: '63105',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.643582',
    longitude: '-90.328636',
    timeZoneId: 'America/Chicago',
  },
  '63106': {
    zip: '63106',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.643705',
    longitude: '-90.203509',
    timeZoneId: 'America/Chicago',
  },
  '63107': {
    zip: '63107',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.663781',
    longitude: '-90.213079',
    timeZoneId: 'America/Chicago',
  },
  '63108': {
    zip: '63108',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.642683',
    longitude: '-90.253485',
    timeZoneId: 'America/Chicago',
  },
  '63109': {
    zip: '63109',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.586076',
    longitude: '-90.295323',
    timeZoneId: 'America/Chicago',
  },
  '63110': {
    zip: '63110',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.61838',
    longitude: '-90.259354',
    timeZoneId: 'America/Chicago',
  },
  '63111': {
    zip: '63111',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.55854',
    longitude: '-90.251529',
    timeZoneId: 'America/Chicago',
  },
  '63112': {
    zip: '63112',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.661545',
    longitude: '-90.285509',
    timeZoneId: 'America/Chicago',
  },
  '63113': {
    zip: '63113',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.654969',
    longitude: '-90.245104',
    timeZoneId: 'America/Chicago',
  },
  '63114': {
    zip: '63114',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.703764',
    longitude: '-90.367077',
    timeZoneId: 'America/Chicago',
  },
  '63115': {
    zip: '63115',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.673685',
    longitude: '-90.238923',
    timeZoneId: 'America/Chicago',
  },
  '63116': {
    zip: '63116',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.582028',
    longitude: '-90.264153',
    timeZoneId: 'America/Chicago',
  },
  '63117': {
    zip: '63117',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.628611',
    longitude: '-90.319444',
    timeZoneId: 'America/Chicago',
  },
  '63118': {
    zip: '63118',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.595639',
    longitude: '-90.224066',
    timeZoneId: 'America/Chicago',
  },
  '63119': {
    zip: '63119',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.588549',
    longitude: '-90.350542',
    timeZoneId: 'America/Chicago',
  },
  '63120': {
    zip: '63120',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.690003',
    longitude: '-90.261886',
    timeZoneId: 'America/Chicago',
  },
  '63121': {
    zip: '63121',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.703607',
    longitude: '-90.29885',
    timeZoneId: 'America/Chicago',
  },
  '63122': {
    zip: '63122',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.584479',
    longitude: '-90.41402',
    timeZoneId: 'America/Chicago',
  },
  '63123': {
    zip: '63123',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.54388',
    longitude: '-90.31842',
    timeZoneId: 'America/Chicago',
  },
  '63124': {
    zip: '63124',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.642678',
    longitude: '-90.378302',
    timeZoneId: 'America/Chicago',
  },
  '63125': {
    zip: '63125',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.523552',
    longitude: '-90.29741',
    timeZoneId: 'America/Chicago',
  },
  '63126': {
    zip: '63126',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.550063',
    longitude: '-90.377582',
    timeZoneId: 'America/Chicago',
  },
  '63127': {
    zip: '63127',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.5413',
    longitude: '-90.407963',
    timeZoneId: 'America/Chicago',
  },
  '63128': {
    zip: '63128',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.50254',
    longitude: '-90.38184',
    timeZoneId: 'America/Chicago',
  },
  '63129': {
    zip: '63129',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.4644',
    longitude: '-90.332031',
    timeZoneId: 'America/Chicago',
  },
  '63130': {
    zip: '63130',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.664368',
    longitude: '-90.323797',
    timeZoneId: 'America/Chicago',
  },
  '63131': {
    zip: '63131',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.616726',
    longitude: '-90.446097',
    timeZoneId: 'America/Chicago',
  },
  '63132': {
    zip: '63132',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.671787',
    longitude: '-90.377495',
    timeZoneId: 'America/Chicago',
  },
  '63133': {
    zip: '63133',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.678252',
    longitude: '-90.305602',
    timeZoneId: 'America/Chicago',
  },
  '63134': {
    zip: '63134',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.738992',
    longitude: '-90.342092',
    timeZoneId: 'America/Chicago',
  },
  '63135': {
    zip: '63135',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.7489',
    longitude: '-90.303899',
    timeZoneId: 'America/Chicago',
  },
  '63136': {
    zip: '63136',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.74359',
    longitude: '-90.261616',
    timeZoneId: 'America/Chicago',
  },
  '63137': {
    zip: '63137',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.748404',
    longitude: '-90.218419',
    timeZoneId: 'America/Chicago',
  },
  '63138': {
    zip: '63138',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.79522',
    longitude: '-90.20904',
    timeZoneId: 'America/Chicago',
  },
  '63139': {
    zip: '63139',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.612066',
    longitude: '-90.290206',
    timeZoneId: 'America/Chicago',
  },
  '63140': {
    zip: '63140',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.737643',
    longitude: '-90.323138',
    timeZoneId: 'America/Chicago',
  },
  '63141': {
    zip: '63141',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.659172',
    longitude: '-90.456634',
    timeZoneId: 'America/Chicago',
  },
  '63143': {
    zip: '63143',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.741558',
    longitude: '-90.362467',
    timeZoneId: 'America/Chicago',
  },
  '63144': {
    zip: '63144',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.616418',
    longitude: '-90.349634',
    timeZoneId: 'America/Chicago',
  },
  '63145': {
    zip: '63145',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.74885',
    longitude: '-90.361025',
    timeZoneId: 'America/Chicago',
  },
  '63146': {
    zip: '63146',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.695246',
    longitude: '-90.458647',
    timeZoneId: 'America/Chicago',
  },
  '63147': {
    zip: '63147',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.695585',
    longitude: '-90.223216',
    timeZoneId: 'America/Chicago',
  },
  '63150': {
    zip: '63150',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.632631',
    longitude: '-90.193043',
    timeZoneId: 'America/Chicago',
  },
  '63151': {
    zip: '63151',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.468598',
    longitude: '-90.305114',
    timeZoneId: 'America/Chicago',
  },
  '63155': {
    zip: '63155',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.630199',
    longitude: '-90.204551',
    timeZoneId: 'America/Chicago',
  },
  '63156': {
    zip: '63156',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.634618',
    longitude: '-90.241991',
    timeZoneId: 'America/Chicago',
  },
  '63157': {
    zip: '63157',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.616454',
    longitude: '-90.215267',
    timeZoneId: 'America/Chicago',
  },
  '63158': {
    zip: '63158',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.604594',
    longitude: '-90.223675',
    timeZoneId: 'America/Chicago',
  },
  '63160': {
    zip: '63160',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.637559',
    longitude: '-90.186991',
    timeZoneId: 'America/Chicago',
  },
  '63163': {
    zip: '63163',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.597345',
    longitude: '-90.242181',
    timeZoneId: 'America/Chicago',
  },
  '63164': {
    zip: '63164',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.636218',
    longitude: '-90.185817',
    timeZoneId: 'America/Chicago',
  },
  '63166': {
    zip: '63166',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.630444',
    longitude: '-90.217497',
    timeZoneId: 'America/Chicago',
  },
  '63167': {
    zip: '63167',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.660153',
    longitude: '-90.455607',
    timeZoneId: 'America/Chicago',
  },
  '63169': {
    zip: '63169',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.629081',
    longitude: '-90.190015',
    timeZoneId: 'America/Chicago',
  },
  '63171': {
    zip: '63171',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.597715',
    longitude: '-90.326395',
    timeZoneId: 'America/Chicago',
  },
  '63177': {
    zip: '63177',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.629158',
    longitude: '-90.217012',
    timeZoneId: 'America/Chicago',
  },
  '63178': {
    zip: '63178',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.629922',
    longitude: '-90.217361',
    timeZoneId: 'America/Chicago',
  },
  '63179': {
    zip: '63179',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.630674',
    longitude: '-90.216355',
    timeZoneId: 'America/Chicago',
  },
  '63180': {
    zip: '63180',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.632421',
    longitude: '-90.202951',
    timeZoneId: 'America/Chicago',
  },
  '63188': {
    zip: '63188',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.629191',
    longitude: '-90.197195',
    timeZoneId: 'America/Chicago',
  },
  '63195': {
    zip: '63195',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.651601',
    longitude: '-90.339813',
    timeZoneId: 'America/Chicago',
  },
  '63197': {
    zip: '63197',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.626874',
    longitude: '-90.201876',
    timeZoneId: 'America/Chicago',
  },
  '63199': {
    zip: '63199',
    city: 'Saint Louis',
    state: 'MO',
    latitude: '38.645844',
    longitude: '-90.331287',
    timeZoneId: 'America/Chicago',
  },
  '63301': {
    zip: '63301',
    city: 'Saint Charles',
    state: 'MO',
    latitude: '38.84742',
    longitude: '-90.477',
    timeZoneId: 'America/Chicago',
  },
  '63302': {
    zip: '63302',
    city: 'Saint Charles',
    state: 'MO',
    latitude: '38.783385',
    longitude: '-90.487303',
    timeZoneId: 'America/Chicago',
  },
  '63303': {
    zip: '63303',
    city: 'Saint Charles',
    state: 'MO',
    latitude: '38.7522',
    longitude: '-90.53772',
    timeZoneId: 'America/Chicago',
  },
  '63304': {
    zip: '63304',
    city: 'Saint Charles',
    state: 'MO',
    latitude: '38.733326',
    longitude: '-90.622121',
    timeZoneId: 'America/Chicago',
  },
  '63330': {
    zip: '63330',
    city: 'Annada',
    state: 'MO',
    latitude: '39.255883',
    longitude: '-90.803454',
    timeZoneId: 'America/Chicago',
  },
  '63332': {
    zip: '63332',
    city: 'Augusta',
    state: 'MO',
    latitude: '38.595378',
    longitude: '-90.88388',
    timeZoneId: 'America/Chicago',
  },
  '63333': {
    zip: '63333',
    city: 'Bellflower',
    state: 'MO',
    latitude: '39.001005',
    longitude: '-91.334577',
    timeZoneId: 'America/Chicago',
  },
  '63334': {
    zip: '63334',
    city: 'Bowling Green',
    state: 'MO',
    latitude: '39.339811',
    longitude: '-91.197015',
    timeZoneId: 'America/Chicago',
  },
  '63336': {
    zip: '63336',
    city: 'Clarksville',
    state: 'MO',
    latitude: '39.367306',
    longitude: '-90.901583',
    timeZoneId: 'America/Chicago',
  },
  '63338': {
    zip: '63338',
    city: 'Cottleville',
    state: 'MO',
    latitude: '38.746957',
    longitude: '-90.653962',
    timeZoneId: 'America/Chicago',
  },
  '63339': {
    zip: '63339',
    city: 'Curryville',
    state: 'MO',
    latitude: '39.345193',
    longitude: '-91.340748',
    timeZoneId: 'America/Chicago',
  },
  '63341': {
    zip: '63341',
    city: 'Defiance',
    state: 'MO',
    latitude: '38.664113',
    longitude: '-90.81708',
    timeZoneId: 'America/Chicago',
  },
  '63342': {
    zip: '63342',
    city: 'Dutzow',
    state: 'MO',
    latitude: '38.594815',
    longitude: '-91.001311',
    timeZoneId: 'America/Chicago',
  },
  '63343': {
    zip: '63343',
    city: 'Elsberry',
    state: 'MO',
    latitude: '39.157945',
    longitude: '-90.815451',
    timeZoneId: 'America/Chicago',
  },
  '63344': {
    zip: '63344',
    city: 'Eolia',
    state: 'MO',
    latitude: '39.252646',
    longitude: '-91.006354',
    timeZoneId: 'America/Chicago',
  },
  '63345': {
    zip: '63345',
    city: 'Farber',
    state: 'MO',
    latitude: '39.277771',
    longitude: '-91.577512',
    timeZoneId: 'America/Chicago',
  },
  '63346': {
    zip: '63346',
    city: 'Flinthill',
    state: 'MO',
    latitude: '38.855883',
    longitude: '-90.86577',
    timeZoneId: 'America/Chicago',
  },
  '63347': {
    zip: '63347',
    city: 'Foley',
    state: 'MO',
    latitude: '39.067091',
    longitude: '-90.74323',
    timeZoneId: 'America/Chicago',
  },
  '63348': {
    zip: '63348',
    city: 'Foristell',
    state: 'MO',
    latitude: '38.790081',
    longitude: '-90.936659',
    timeZoneId: 'America/Chicago',
  },
  '63349': {
    zip: '63349',
    city: 'Hawk Point',
    state: 'MO',
    latitude: '38.97328',
    longitude: '-91.137883',
    timeZoneId: 'America/Chicago',
  },
  '63350': {
    zip: '63350',
    city: 'High Hill',
    state: 'MO',
    latitude: '38.900995',
    longitude: '-91.372028',
    timeZoneId: 'America/Chicago',
  },
  '63351': {
    zip: '63351',
    city: 'Jonesburg',
    state: 'MO',
    latitude: '38.909001',
    longitude: '-91.298192',
    timeZoneId: 'America/Chicago',
  },
  '63352': {
    zip: '63352',
    city: 'Laddonia',
    state: 'MO',
    latitude: '39.242116',
    longitude: '-91.642579',
    timeZoneId: 'America/Chicago',
  },
  '63353': {
    zip: '63353',
    city: 'Louisiana',
    state: 'MO',
    latitude: '39.441104',
    longitude: '-91.055048',
    timeZoneId: 'America/Chicago',
  },
  '63357': {
    zip: '63357',
    city: 'Marthasville',
    state: 'MO',
    latitude: '38.634',
    longitude: '-91.10016',
    timeZoneId: 'America/Chicago',
  },
  '63359': {
    zip: '63359',
    city: 'Middletown',
    state: 'MO',
    latitude: '39.093735',
    longitude: '-91.367918',
    timeZoneId: 'America/Chicago',
  },
  '63361': {
    zip: '63361',
    city: 'Montgomery City',
    state: 'MO',
    latitude: '38.978952',
    longitude: '-91.505241',
    timeZoneId: 'America/Chicago',
  },
  '63362': {
    zip: '63362',
    city: 'Moscow Mills',
    state: 'MO',
    latitude: '38.931175',
    longitude: '-90.881331',
    timeZoneId: 'America/Chicago',
  },
  '63363': {
    zip: '63363',
    city: 'New Florence',
    state: 'MO',
    latitude: '38.8947',
    longitude: '-91.5171',
    timeZoneId: 'America/Chicago',
  },
  '63365': {
    zip: '63365',
    city: 'New Melle',
    state: 'MO',
    latitude: '38.711202',
    longitude: '-90.880752',
    timeZoneId: 'America/Chicago',
  },
  '63366': {
    zip: '63366',
    city: 'O Fallon',
    state: 'MO',
    latitude: '38.83506',
    longitude: '-90.74232',
    timeZoneId: 'America/Chicago',
  },
  '63367': {
    zip: '63367',
    city: 'Lake Saint Louis',
    state: 'MO',
    latitude: '38.793015',
    longitude: '-90.782477',
    timeZoneId: 'America/Chicago',
  },
  '63368': {
    zip: '63368',
    city: 'O Fallon',
    state: 'MO',
    latitude: '38.757',
    longitude: '-90.7247',
    timeZoneId: 'America/Chicago',
  },
  '63369': {
    zip: '63369',
    city: 'Old Monroe',
    state: 'MO',
    latitude: '38.942597',
    longitude: '-90.763542',
    timeZoneId: 'America/Chicago',
  },
  '63370': {
    zip: '63370',
    city: 'Olney',
    state: 'MO',
    latitude: '39.097858',
    longitude: '-91.212285',
    timeZoneId: 'America/Chicago',
  },
  '63373': {
    zip: '63373',
    city: 'Portage des Sioux',
    state: 'MO',
    latitude: '38.924848',
    longitude: '-90.364053',
    timeZoneId: 'America/Chicago',
  },
  '63376': {
    zip: '63376',
    city: 'Saint Peters',
    state: 'MO',
    latitude: '38.78027',
    longitude: '-90.62405',
    timeZoneId: 'America/Chicago',
  },
  '63377': {
    zip: '63377',
    city: 'Silex',
    state: 'MO',
    latitude: '39.140541',
    longitude: '-91.061883',
    timeZoneId: 'America/Chicago',
  },
  '63378': {
    zip: '63378',
    city: 'Treloar',
    state: 'MO',
    latitude: '38.641449',
    longitude: '-91.187134',
    timeZoneId: 'America/Chicago',
  },
  '63379': {
    zip: '63379',
    city: 'Troy',
    state: 'MO',
    latitude: '39.00564',
    longitude: '-90.96246',
    timeZoneId: 'America/Chicago',
  },
  '63380': {
    zip: '63380',
    city: 'Truesdale',
    state: 'MO',
    latitude: '38.77122',
    longitude: '-91.22436',
    timeZoneId: 'America/Chicago',
  },
  '63381': {
    zip: '63381',
    city: 'Truxton',
    state: 'MO',
    latitude: '38.962877',
    longitude: '-91.225396',
    timeZoneId: 'America/Chicago',
  },
  '63382': {
    zip: '63382',
    city: 'Vandalia',
    state: 'MO',
    latitude: '39.307467',
    longitude: '-91.491613',
    timeZoneId: 'America/Chicago',
  },
  '63383': {
    zip: '63383',
    city: 'Warrenton',
    state: 'MO',
    latitude: '38.77122',
    longitude: '-91.22436',
    timeZoneId: 'America/Chicago',
  },
  '63384': {
    zip: '63384',
    city: 'Wellsville',
    state: 'MO',
    latitude: '39.07354',
    longitude: '-91.567092',
    timeZoneId: 'America/Chicago',
  },
  '63385': {
    zip: '63385',
    city: 'Wentzville',
    state: 'MO',
    latitude: '38.798044',
    longitude: '-90.860524',
    timeZoneId: 'America/Chicago',
  },
  '63386': {
    zip: '63386',
    city: 'West Alton',
    state: 'MO',
    latitude: '38.869479',
    longitude: '-90.215269',
    timeZoneId: 'America/Chicago',
  },
  '63387': {
    zip: '63387',
    city: 'Whiteside',
    state: 'MO',
    latitude: '39.184376',
    longitude: '-91.018211',
    timeZoneId: 'America/Chicago',
  },
  '63388': {
    zip: '63388',
    city: 'Williamsburg',
    state: 'MO',
    latitude: '38.836617',
    longitude: '-92.002176',
    timeZoneId: 'America/Chicago',
  },
  '63389': {
    zip: '63389',
    city: 'Winfield',
    state: 'MO',
    latitude: '39.008098',
    longitude: '-90.74629',
    timeZoneId: 'America/Chicago',
  },
  '63390': {
    zip: '63390',
    city: 'Wright City',
    state: 'MO',
    latitude: '38.80492',
    longitude: '-91.039421',
    timeZoneId: 'America/Chicago',
  },
  '63401': {
    zip: '63401',
    city: 'Hannibal',
    state: 'MO',
    latitude: '39.708581',
    longitude: '-91.380823',
    timeZoneId: 'America/Chicago',
  },
  '63430': {
    zip: '63430',
    city: 'Alexandria',
    state: 'MO',
    latitude: '40.35702',
    longitude: '-91.457994',
    timeZoneId: 'America/Chicago',
  },
  '63431': {
    zip: '63431',
    city: 'Anabel',
    state: 'MO',
    latitude: '39.737067',
    longitude: '-92.346965',
    timeZoneId: 'America/Chicago',
  },
  '63432': {
    zip: '63432',
    city: 'Arbela',
    state: 'MO',
    latitude: '40.503569',
    longitude: '-91.998959',
    timeZoneId: 'America/Chicago',
  },
  '63433': {
    zip: '63433',
    city: 'Ashburn',
    state: 'MO',
    latitude: '39.523283',
    longitude: '-91.160736',
    timeZoneId: 'America/Chicago',
  },
  '63434': {
    zip: '63434',
    city: 'Bethel',
    state: 'MO',
    latitude: '39.906987',
    longitude: '-92.040748',
    timeZoneId: 'America/Chicago',
  },
  '63435': {
    zip: '63435',
    city: 'Canton',
    state: 'MO',
    latitude: '40.130488',
    longitude: '-91.527483',
    timeZoneId: 'America/Chicago',
  },
  '63436': {
    zip: '63436',
    city: 'Center',
    state: 'MO',
    latitude: '39.506337',
    longitude: '-91.532429',
    timeZoneId: 'America/Chicago',
  },
  '63437': {
    zip: '63437',
    city: 'Clarence',
    state: 'MO',
    latitude: '39.738419',
    longitude: '-92.263023',
    timeZoneId: 'America/Chicago',
  },
  '63438': {
    zip: '63438',
    city: 'Durham',
    state: 'MO',
    latitude: '39.956865',
    longitude: '-91.675935',
    timeZoneId: 'America/Chicago',
  },
  '63439': {
    zip: '63439',
    city: 'Emden',
    state: 'MO',
    latitude: '39.860243',
    longitude: '-91.889631',
    timeZoneId: 'America/Chicago',
  },
  '63440': {
    zip: '63440',
    city: 'Ewing',
    state: 'MO',
    latitude: '40.006771',
    longitude: '-91.712936',
    timeZoneId: 'America/Chicago',
  },
  '63441': {
    zip: '63441',
    city: 'Frankford',
    state: 'MO',
    latitude: '39.490026',
    longitude: '-91.278196',
    timeZoneId: 'America/Chicago',
  },
  '63442': {
    zip: '63442',
    city: 'Granger',
    state: 'MO',
    latitude: '40.503604',
    longitude: '-92.003793',
    timeZoneId: 'America/Chicago',
  },
  '63443': {
    zip: '63443',
    city: 'Hunnewell',
    state: 'MO',
    latitude: '39.668204',
    longitude: '-91.858753',
    timeZoneId: 'America/Chicago',
  },
  '63445': {
    zip: '63445',
    city: 'Kahoka',
    state: 'MO',
    latitude: '40.434444',
    longitude: '-91.775833',
    timeZoneId: 'America/Chicago',
  },
  '63446': {
    zip: '63446',
    city: 'Knox City',
    state: 'MO',
    latitude: '40.127713',
    longitude: '-92.003683',
    timeZoneId: 'America/Chicago',
  },
  '63447': {
    zip: '63447',
    city: 'La Belle',
    state: 'MO',
    latitude: '40.105774',
    longitude: '-91.914071',
    timeZoneId: 'America/Chicago',
  },
  '63448': {
    zip: '63448',
    city: 'La Grange',
    state: 'MO',
    latitude: '40.038368',
    longitude: '-91.501988',
    timeZoneId: 'America/Chicago',
  },
  '63450': {
    zip: '63450',
    city: 'Lentner',
    state: 'MO',
    latitude: '39.720412',
    longitude: '-92.145558',
    timeZoneId: 'America/Chicago',
  },
  '63451': {
    zip: '63451',
    city: 'Leonard',
    state: 'MO',
    latitude: '39.908313',
    longitude: '-92.206345',
    timeZoneId: 'America/Chicago',
  },
  '63452': {
    zip: '63452',
    city: 'Lewistown',
    state: 'MO',
    latitude: '40.082877',
    longitude: '-91.813182',
    timeZoneId: 'America/Chicago',
  },
  '63453': {
    zip: '63453',
    city: 'Luray',
    state: 'MO',
    latitude: '40.510718',
    longitude: '-91.888478',
    timeZoneId: 'America/Chicago',
  },
  '63454': {
    zip: '63454',
    city: 'Maywood',
    state: 'MO',
    latitude: '39.940896',
    longitude: '-91.602623',
    timeZoneId: 'America/Chicago',
  },
  '63456': {
    zip: '63456',
    city: 'Monroe City',
    state: 'MO',
    latitude: '39.65318',
    longitude: '-91.730329',
    timeZoneId: 'America/Chicago',
  },
  '63457': {
    zip: '63457',
    city: 'Monticello',
    state: 'MO',
    latitude: '40.115404',
    longitude: '-91.708634',
    timeZoneId: 'America/Chicago',
  },
  '63458': {
    zip: '63458',
    city: 'Newark',
    state: 'MO',
    latitude: '39.992921',
    longitude: '-92.007717',
    timeZoneId: 'America/Chicago',
  },
  '63459': {
    zip: '63459',
    city: 'New London',
    state: 'MO',
    latitude: '39.586253',
    longitude: '-91.398191',
    timeZoneId: 'America/Chicago',
  },
  '63460': {
    zip: '63460',
    city: 'Novelty',
    state: 'MO',
    latitude: '40.03792',
    longitude: '-92.257514',
    timeZoneId: 'America/Chicago',
  },
  '63461': {
    zip: '63461',
    city: 'Palmyra',
    state: 'MO',
    latitude: '39.797789',
    longitude: '-91.5263',
    timeZoneId: 'America/Chicago',
  },
  '63462': {
    zip: '63462',
    city: 'Perry',
    state: 'MO',
    latitude: '39.429064',
    longitude: '-91.669127',
    timeZoneId: 'America/Chicago',
  },
  '63463': {
    zip: '63463',
    city: 'Philadelphia',
    state: 'MO',
    latitude: '39.837162',
    longitude: '-91.765993',
    timeZoneId: 'America/Chicago',
  },
  '63465': {
    zip: '63465',
    city: 'Revere',
    state: 'MO',
    latitude: '40.49502',
    longitude: '-91.67748',
    timeZoneId: 'America/Chicago',
  },
  '63466': {
    zip: '63466',
    city: 'Saint Patrick',
    state: 'MO',
    latitude: '40.317858',
    longitude: '-91.663974',
    timeZoneId: 'America/Chicago',
  },
  '63467': {
    zip: '63467',
    city: 'Saverton',
    state: 'MO',
    latitude: '39.531879',
    longitude: '-91.437369',
    timeZoneId: 'America/Chicago',
  },
  '63468': {
    zip: '63468',
    city: 'Shelbina',
    state: 'MO',
    latitude: '39.694269',
    longitude: '-92.042102',
    timeZoneId: 'America/Chicago',
  },
  '63469': {
    zip: '63469',
    city: 'Shelbyville',
    state: 'MO',
    latitude: '39.822053',
    longitude: '-92.070853',
    timeZoneId: 'America/Chicago',
  },
  '63471': {
    zip: '63471',
    city: 'Taylor',
    state: 'MO',
    latitude: '39.91392',
    longitude: '-91.51068',
    timeZoneId: 'America/Chicago',
  },
  '63472': {
    zip: '63472',
    city: 'Wayland',
    state: 'MO',
    latitude: '40.397041',
    longitude: '-91.582082',
    timeZoneId: 'America/Chicago',
  },
  '63473': {
    zip: '63473',
    city: 'Williamstown',
    state: 'MO',
    latitude: '40.203555',
    longitude: '-91.867788',
    timeZoneId: 'America/Chicago',
  },
  '63474': {
    zip: '63474',
    city: 'Wyaconda',
    state: 'MO',
    latitude: '40.336883',
    longitude: '-91.890611',
    timeZoneId: 'America/Chicago',
  },
  '63501': {
    zip: '63501',
    city: 'Kirksville',
    state: 'MO',
    latitude: '40.193771',
    longitude: '-92.583295',
    timeZoneId: 'America/Chicago',
  },
  '63530': {
    zip: '63530',
    city: 'Atlanta',
    state: 'MO',
    latitude: '39.9284',
    longitude: '-92.451724',
    timeZoneId: 'America/Chicago',
  },
  '63531': {
    zip: '63531',
    city: 'Baring',
    state: 'MO',
    latitude: '40.263106',
    longitude: '-92.26244',
    timeZoneId: 'America/Chicago',
  },
  '63532': {
    zip: '63532',
    city: 'Bevier',
    state: 'MO',
    latitude: '39.750077',
    longitude: '-92.56252',
    timeZoneId: 'America/Chicago',
  },
  '63533': {
    zip: '63533',
    city: 'Brashear',
    state: 'MO',
    latitude: '40.220112',
    longitude: '-92.432085',
    timeZoneId: 'America/Chicago',
  },
  '63534': {
    zip: '63534',
    city: 'Callao',
    state: 'MO',
    latitude: '39.739621',
    longitude: '-92.64557',
    timeZoneId: 'America/Chicago',
  },
  '63535': {
    zip: '63535',
    city: 'Coatsville',
    state: 'MO',
    latitude: '40.570519',
    longitude: '-92.620601',
    timeZoneId: 'America/Chicago',
  },
  '63536': {
    zip: '63536',
    city: 'Downing',
    state: 'MO',
    latitude: '40.472383',
    longitude: '-92.413598',
    timeZoneId: 'America/Chicago',
  },
  '63537': {
    zip: '63537',
    city: 'Edina',
    state: 'MO',
    latitude: '40.169229',
    longitude: '-92.170868',
    timeZoneId: 'America/Chicago',
  },
  '63538': {
    zip: '63538',
    city: 'Elmer',
    state: 'MO',
    latitude: '39.931558',
    longitude: '-92.652345',
    timeZoneId: 'America/Chicago',
  },
  '63539': {
    zip: '63539',
    city: 'Ethel',
    state: 'MO',
    latitude: '39.930787',
    longitude: '-92.773951',
    timeZoneId: 'America/Chicago',
  },
  '63540': {
    zip: '63540',
    city: 'Gibbs',
    state: 'MO',
    latitude: '40.082101',
    longitude: '-92.432799',
    timeZoneId: 'America/Chicago',
  },
  '63541': {
    zip: '63541',
    city: 'Glenwood',
    state: 'MO',
    latitude: '40.507166',
    longitude: '-92.618509',
    timeZoneId: 'America/Chicago',
  },
  '63543': {
    zip: '63543',
    city: 'Gorin',
    state: 'MO',
    latitude: '40.356102',
    longitude: '-92.004461',
    timeZoneId: 'America/Chicago',
  },
  '63544': {
    zip: '63544',
    city: 'Green Castle',
    state: 'MO',
    latitude: '40.312191',
    longitude: '-92.88095',
    timeZoneId: 'America/Chicago',
  },
  '63545': {
    zip: '63545',
    city: 'Green City',
    state: 'MO',
    latitude: '40.266332',
    longitude: '-92.952465',
    timeZoneId: 'America/Chicago',
  },
  '63546': {
    zip: '63546',
    city: 'Greentop',
    state: 'MO',
    latitude: '40.344581',
    longitude: '-92.537453',
    timeZoneId: 'America/Chicago',
  },
  '63547': {
    zip: '63547',
    city: 'Hurdland',
    state: 'MO',
    latitude: '40.145709',
    longitude: '-92.302124',
    timeZoneId: 'America/Chicago',
  },
  '63548': {
    zip: '63548',
    city: 'Lancaster',
    state: 'MO',
    latitude: '40.532296',
    longitude: '-92.515275',
    timeZoneId: 'America/Chicago',
  },
  '63549': {
    zip: '63549',
    city: 'La Plata',
    state: 'MO',
    latitude: '40.023008',
    longitude: '-92.494977',
    timeZoneId: 'America/Chicago',
  },
  '63551': {
    zip: '63551',
    city: 'Livonia',
    state: 'MO',
    latitude: '40.523706',
    longitude: '-92.739132',
    timeZoneId: 'America/Chicago',
  },
  '63552': {
    zip: '63552',
    city: 'Macon',
    state: 'MO',
    latitude: '39.738606',
    longitude: '-92.473253',
    timeZoneId: 'America/Chicago',
  },
  '63555': {
    zip: '63555',
    city: 'Memphis',
    state: 'MO',
    latitude: '40.457615',
    longitude: '-92.169013',
    timeZoneId: 'America/Chicago',
  },
  '63556': {
    zip: '63556',
    city: 'Milan',
    state: 'MO',
    latitude: '40.201394',
    longitude: '-93.12492',
    timeZoneId: 'America/Chicago',
  },
  '63557': {
    zip: '63557',
    city: 'New Boston',
    state: 'MO',
    latitude: '39.762044',
    longitude: '-93.117461',
    timeZoneId: 'America/Chicago',
  },
  '63558': {
    zip: '63558',
    city: 'New Cambria',
    state: 'MO',
    latitude: '39.758059',
    longitude: '-92.769919',
    timeZoneId: 'America/Chicago',
  },
  '63559': {
    zip: '63559',
    city: 'Novinger',
    state: 'MO',
    latitude: '40.297763',
    longitude: '-92.752391',
    timeZoneId: 'America/Chicago',
  },
  '63560': {
    zip: '63560',
    city: 'Pollock',
    state: 'MO',
    latitude: '40.34302',
    longitude: '-93.123704',
    timeZoneId: 'America/Chicago',
  },
  '63561': {
    zip: '63561',
    city: 'Queen City',
    state: 'MO',
    latitude: '40.420358',
    longitude: '-92.567187',
    timeZoneId: 'America/Chicago',
  },
  '63563': {
    zip: '63563',
    city: 'Rutledge',
    state: 'MO',
    latitude: '40.348929',
    longitude: '-92.107301',
    timeZoneId: 'America/Chicago',
  },
  '63565': {
    zip: '63565',
    city: 'Unionville',
    state: 'MO',
    latitude: '40.4973',
    longitude: '-93.00576',
    timeZoneId: 'America/Chicago',
  },
  '63566': {
    zip: '63566',
    city: 'Winigan',
    state: 'MO',
    latitude: '40.041679',
    longitude: '-92.929334',
    timeZoneId: 'America/Chicago',
  },
  '63567': {
    zip: '63567',
    city: 'Worthington',
    state: 'MO',
    latitude: '40.424019',
    longitude: '-92.76573',
    timeZoneId: 'America/Chicago',
  },
  '63601': {
    zip: '63601',
    city: 'Park Hills',
    state: 'MO',
    latitude: '37.855031',
    longitude: '-90.518894',
    timeZoneId: 'America/Chicago',
  },
  '63620': {
    zip: '63620',
    city: 'Annapolis',
    state: 'MO',
    latitude: '37.429977',
    longitude: '-90.655396',
    timeZoneId: 'America/Chicago',
  },
  '63621': {
    zip: '63621',
    city: 'Arcadia',
    state: 'MO',
    latitude: '37.587754',
    longitude: '-90.626482',
    timeZoneId: 'America/Chicago',
  },
  '63622': {
    zip: '63622',
    city: 'Belgrade',
    state: 'MO',
    latitude: '37.788108',
    longitude: '-90.848823',
    timeZoneId: 'America/Chicago',
  },
  '63623': {
    zip: '63623',
    city: 'Belleview',
    state: 'MO',
    latitude: '37.669675',
    longitude: '-90.853916',
    timeZoneId: 'America/Chicago',
  },
  '63624': {
    zip: '63624',
    city: 'Bismarck',
    state: 'MO',
    latitude: '37.770124',
    longitude: '-90.624578',
    timeZoneId: 'America/Chicago',
  },
  '63625': {
    zip: '63625',
    city: 'Black',
    state: 'MO',
    latitude: '37.55532',
    longitude: '-91.00308',
    timeZoneId: 'America/Chicago',
  },
  '63626': {
    zip: '63626',
    city: 'Blackwell',
    state: 'MO',
    latitude: '38.068875',
    longitude: '-90.709883',
    timeZoneId: 'America/Chicago',
  },
  '63627': {
    zip: '63627',
    city: 'Bloomsdale',
    state: 'MO',
    latitude: '38.033035',
    longitude: '-90.256122',
    timeZoneId: 'America/Chicago',
  },
  '63628': {
    zip: '63628',
    city: 'Bonne Terre',
    state: 'MO',
    latitude: '37.915562',
    longitude: '-90.589053',
    timeZoneId: 'America/Chicago',
  },
  '63629': {
    zip: '63629',
    city: 'Bunker',
    state: 'MO',
    latitude: '37.510008',
    longitude: '-91.186057',
    timeZoneId: 'America/Chicago',
  },
  '63630': {
    zip: '63630',
    city: 'Cadet',
    state: 'MO',
    latitude: '38.03448',
    longitude: '-90.72168',
    timeZoneId: 'America/Chicago',
  },
  '63631': {
    zip: '63631',
    city: 'Caledonia',
    state: 'MO',
    latitude: '37.773412',
    longitude: '-90.799689',
    timeZoneId: 'America/Chicago',
  },
  '63632': {
    zip: '63632',
    city: 'Cascade',
    state: 'MO',
    latitude: '37.3',
    longitude: '-90.268317',
    timeZoneId: 'America/Chicago',
  },
  '63633': {
    zip: '63633',
    city: 'Centerville',
    state: 'MO',
    latitude: '37.440471',
    longitude: '-90.995157',
    timeZoneId: 'America/Chicago',
  },
  '63636': {
    zip: '63636',
    city: 'Des Arc',
    state: 'MO',
    latitude: '37.306207',
    longitude: '-90.613975',
    timeZoneId: 'America/Chicago',
  },
  '63637': {
    zip: '63637',
    city: 'Doe Run',
    state: 'MO',
    latitude: '37.743759',
    longitude: '-90.499528',
    timeZoneId: 'America/Chicago',
  },
  '63638': {
    zip: '63638',
    city: 'Ellington',
    state: 'MO',
    latitude: '37.222438',
    longitude: '-90.974306',
    timeZoneId: 'America/Chicago',
  },
  '63640': {
    zip: '63640',
    city: 'Farmington',
    state: 'MO',
    latitude: '37.780722',
    longitude: '-90.421005',
    timeZoneId: 'America/Chicago',
  },
  '63645': {
    zip: '63645',
    city: 'Fredericktown',
    state: 'MO',
    latitude: '37.559762',
    longitude: '-90.292995',
    timeZoneId: 'America/Chicago',
  },
  '63648': {
    zip: '63648',
    city: 'Irondale',
    state: 'MO',
    latitude: '37.819577',
    longitude: '-90.709864',
    timeZoneId: 'America/Chicago',
  },
  '63650': {
    zip: '63650',
    city: 'Ironton',
    state: 'MO',
    latitude: '37.601823',
    longitude: '-90.629002',
    timeZoneId: 'America/Chicago',
  },
  '63651': {
    zip: '63651',
    city: 'Knob Lick',
    state: 'MO',
    latitude: '37.675439',
    longitude: '-90.370288',
    timeZoneId: 'America/Chicago',
  },
  '63653': {
    zip: '63653',
    city: 'Leadwood',
    state: 'MO',
    latitude: '37.863336',
    longitude: '-90.592582',
    timeZoneId: 'America/Chicago',
  },
  '63654': {
    zip: '63654',
    city: 'Lesterville',
    state: 'MO',
    latitude: '37.481049',
    longitude: '-90.833927',
    timeZoneId: 'America/Chicago',
  },
  '63655': {
    zip: '63655',
    city: 'Marquand',
    state: 'MO',
    latitude: '37.403467',
    longitude: '-90.19736',
    timeZoneId: 'America/Chicago',
  },
  '63656': {
    zip: '63656',
    city: 'Middle Brook',
    state: 'MO',
    latitude: '37.697252',
    longitude: '-90.683551',
    timeZoneId: 'America/Chicago',
  },
  '63660': {
    zip: '63660',
    city: 'Mineral Point',
    state: 'MO',
    latitude: '37.9161',
    longitude: '-90.71028',
    timeZoneId: 'America/Chicago',
  },
  '63662': {
    zip: '63662',
    city: 'Patton',
    state: 'MO',
    latitude: '37.416507',
    longitude: '-90.064187',
    timeZoneId: 'America/Chicago',
  },
  '63663': {
    zip: '63663',
    city: 'Pilot Knob',
    state: 'MO',
    latitude: '37.620975',
    longitude: '-90.637685',
    timeZoneId: 'America/Chicago',
  },
  '63664': {
    zip: '63664',
    city: 'Potosi',
    state: 'MO',
    latitude: '37.938045',
    longitude: '-90.784876',
    timeZoneId: 'America/Chicago',
  },
  '63665': {
    zip: '63665',
    city: 'Redford',
    state: 'MO',
    latitude: '37.314885',
    longitude: '-90.916174',
    timeZoneId: 'America/Chicago',
  },
  '63666': {
    zip: '63666',
    city: 'Reynolds',
    state: 'MO',
    latitude: '37.2433',
    longitude: '-90.952046',
    timeZoneId: 'America/Chicago',
  },
  '63670': {
    zip: '63670',
    city: 'Sainte Genevieve',
    state: 'MO',
    latitude: '37.977891',
    longitude: '-90.049578',
    timeZoneId: 'America/Chicago',
  },
  '63673': {
    zip: '63673',
    city: 'Saint Mary',
    state: 'MO',
    latitude: '37.8276',
    longitude: '-90.0396',
    timeZoneId: 'America/Chicago',
  },
  '63674': {
    zip: '63674',
    city: 'Tiff',
    state: 'MO',
    latitude: '38.018253',
    longitude: '-90.651893',
    timeZoneId: 'America/Chicago',
  },
  '63675': {
    zip: '63675',
    city: 'Vulcan',
    state: 'MO',
    latitude: '37.308062',
    longitude: '-90.711151',
    timeZoneId: 'America/Chicago',
  },
  '63701': {
    zip: '63701',
    city: 'Cape Girardeau',
    state: 'MO',
    latitude: '37.318976',
    longitude: '-89.540608',
    timeZoneId: 'America/Chicago',
  },
  '63702': {
    zip: '63702',
    city: 'Cape Girardeau',
    state: 'MO',
    latitude: '37.31095',
    longitude: '-89.625937',
    timeZoneId: 'America/Chicago',
  },
  '63703': {
    zip: '63703',
    city: 'Cape Girardeau',
    state: 'MO',
    latitude: '37.296342',
    longitude: '-89.537946',
    timeZoneId: 'America/Chicago',
  },
  '63730': {
    zip: '63730',
    city: 'Advance',
    state: 'MO',
    latitude: '37.09482',
    longitude: '-89.91006',
    timeZoneId: 'America/Chicago',
  },
  '63732': {
    zip: '63732',
    city: 'Altenburg',
    state: 'MO',
    latitude: '37.5237',
    longitude: '-89.59728',
    timeZoneId: 'America/Chicago',
  },
  '63735': {
    zip: '63735',
    city: 'Bell City',
    state: 'MO',
    latitude: '36.989276',
    longitude: '-89.779173',
    timeZoneId: 'America/Chicago',
  },
  '63736': {
    zip: '63736',
    city: 'Benton',
    state: 'MO',
    latitude: '37.0722',
    longitude: '-89.5698',
    timeZoneId: 'America/Chicago',
  },
  '63737': {
    zip: '63737',
    city: 'Brazeau',
    state: 'MO',
    latitude: '37.659303',
    longitude: '-89.654551',
    timeZoneId: 'America/Chicago',
  },
  '63738': {
    zip: '63738',
    city: 'Brownwood',
    state: 'MO',
    latitude: '37.080255',
    longitude: '-89.955275',
    timeZoneId: 'America/Chicago',
  },
  '63739': {
    zip: '63739',
    city: 'Burfordville',
    state: 'MO',
    latitude: '37.355779',
    longitude: '-89.815404',
    timeZoneId: 'America/Chicago',
  },
  '63740': {
    zip: '63740',
    city: 'Chaffee',
    state: 'MO',
    latitude: '37.18572',
    longitude: '-89.64792',
    timeZoneId: 'America/Chicago',
  },
  '63742': {
    zip: '63742',
    city: 'Commerce',
    state: 'MO',
    latitude: '37.158771',
    longitude: '-89.443457',
    timeZoneId: 'America/Chicago',
  },
  '63743': {
    zip: '63743',
    city: 'Daisy',
    state: 'MO',
    latitude: '37.512497',
    longitude: '-89.826349',
    timeZoneId: 'America/Chicago',
  },
  '63744': {
    zip: '63744',
    city: 'Delta',
    state: 'MO',
    latitude: '37.192154',
    longitude: '-89.749348',
    timeZoneId: 'America/Chicago',
  },
  '63745': {
    zip: '63745',
    city: 'Dutchtown',
    state: 'MO',
    latitude: '37.252156',
    longitude: '-89.659063',
    timeZoneId: 'America/Chicago',
  },
  '63746': {
    zip: '63746',
    city: 'Farrar',
    state: 'MO',
    latitude: '37.70207',
    longitude: '-89.690412',
    timeZoneId: 'America/Chicago',
  },
  '63747': {
    zip: '63747',
    city: 'Friedheim',
    state: 'MO',
    latitude: '37.556658',
    longitude: '-89.843268',
    timeZoneId: 'America/Chicago',
  },
  '63748': {
    zip: '63748',
    city: 'Frohna',
    state: 'MO',
    latitude: '37.67572',
    longitude: '-89.667036',
    timeZoneId: 'America/Chicago',
  },
  '63750': {
    zip: '63750',
    city: 'Gipsy',
    state: 'MO',
    latitude: '37.131004',
    longitude: '-90.199333',
    timeZoneId: 'America/Chicago',
  },
  '63751': {
    zip: '63751',
    city: 'Glenallen',
    state: 'MO',
    latitude: '37.327212',
    longitude: '-90.061063',
    timeZoneId: 'America/Chicago',
  },
  '63752': {
    zip: '63752',
    city: 'Gordonville',
    state: 'MO',
    latitude: '37.313943',
    longitude: '-89.678711',
    timeZoneId: 'America/Chicago',
  },
  '63755': {
    zip: '63755',
    city: 'Jackson',
    state: 'MO',
    latitude: '37.384771',
    longitude: '-89.659833',
    timeZoneId: 'America/Chicago',
  },
  '63758': {
    zip: '63758',
    city: 'Kelso',
    state: 'MO',
    latitude: '37.19096',
    longitude: '-89.550208',
    timeZoneId: 'America/Chicago',
  },
  '63760': {
    zip: '63760',
    city: 'Leopold',
    state: 'MO',
    latitude: '37.263829',
    longitude: '-89.909492',
    timeZoneId: 'America/Chicago',
  },
  '63763': {
    zip: '63763',
    city: 'Mc Gee',
    state: 'MO',
    latitude: '37.042092',
    longitude: '-90.185137',
    timeZoneId: 'America/Chicago',
  },
  '63764': {
    zip: '63764',
    city: 'Marble Hill',
    state: 'MO',
    latitude: '37.30344',
    longitude: '-89.97198',
    timeZoneId: 'America/Chicago',
  },
  '63766': {
    zip: '63766',
    city: 'Millersville',
    state: 'MO',
    latitude: '37.445388',
    longitude: '-89.810383',
    timeZoneId: 'America/Chicago',
  },
  '63767': {
    zip: '63767',
    city: 'Morley',
    state: 'MO',
    latitude: '37.043348',
    longitude: '-89.610551',
    timeZoneId: 'America/Chicago',
  },
  '63769': {
    zip: '63769',
    city: 'Oak Ridge',
    state: 'MO',
    latitude: '37.5171',
    longitude: '-89.7216',
    timeZoneId: 'America/Chicago',
  },
  '63770': {
    zip: '63770',
    city: 'Old Appleton',
    state: 'MO',
    latitude: '37.569514',
    longitude: '-89.703774',
    timeZoneId: 'America/Chicago',
  },
  '63771': {
    zip: '63771',
    city: 'Oran',
    state: 'MO',
    latitude: '37.085317',
    longitude: '-89.648175',
    timeZoneId: 'America/Chicago',
  },
  '63774': {
    zip: '63774',
    city: 'Perkins',
    state: 'MO',
    latitude: '37.095312',
    longitude: '-89.775153',
    timeZoneId: 'America/Chicago',
  },
  '63775': {
    zip: '63775',
    city: 'Perryville',
    state: 'MO',
    latitude: '37.71948',
    longitude: '-89.81016',
    timeZoneId: 'America/Chicago',
  },
  '63776': {
    zip: '63776',
    city: 'Mc Bride',
    state: 'MO',
    latitude: '37.714454',
    longitude: '-89.890647',
    timeZoneId: 'America/Chicago',
  },
  '63779': {
    zip: '63779',
    city: 'Pocahontas',
    state: 'MO',
    latitude: '37.499441',
    longitude: '-89.642156',
    timeZoneId: 'America/Chicago',
  },
  '63780': {
    zip: '63780',
    city: 'Scott City',
    state: 'MO',
    latitude: '37.218328',
    longitude: '-89.518577',
    timeZoneId: 'America/Chicago',
  },
  '63781': {
    zip: '63781',
    city: 'Sedgewickville',
    state: 'MO',
    latitude: '37.545458',
    longitude: '-89.928209',
    timeZoneId: 'America/Chicago',
  },
  '63782': {
    zip: '63782',
    city: 'Sturdivant',
    state: 'MO',
    latitude: '37.071576',
    longitude: '-90.008984',
    timeZoneId: 'America/Chicago',
  },
  '63783': {
    zip: '63783',
    city: 'Uniontown',
    state: 'MO',
    latitude: '37.60877',
    longitude: '-89.716808',
    timeZoneId: 'America/Chicago',
  },
  '63784': {
    zip: '63784',
    city: 'Vanduser',
    state: 'MO',
    latitude: '36.992092',
    longitude: '-89.684917',
    timeZoneId: 'America/Chicago',
  },
  '63785': {
    zip: '63785',
    city: 'Whitewater',
    state: 'MO',
    latitude: '37.274009',
    longitude: '-89.75847',
    timeZoneId: 'America/Chicago',
  },
  '63787': {
    zip: '63787',
    city: 'Zalma',
    state: 'MO',
    latitude: '37.131892',
    longitude: '-90.084809',
    timeZoneId: 'America/Chicago',
  },
  '63801': {
    zip: '63801',
    city: 'Sikeston',
    state: 'MO',
    latitude: '36.889917',
    longitude: '-89.578212',
    timeZoneId: 'America/Chicago',
  },
  '63820': {
    zip: '63820',
    city: 'Anniston',
    state: 'MO',
    latitude: '36.834055',
    longitude: '-89.364267',
    timeZoneId: 'America/Chicago',
  },
  '63821': {
    zip: '63821',
    city: 'Arbyrd',
    state: 'MO',
    latitude: '36.079265',
    longitude: '-90.204869',
    timeZoneId: 'America/Chicago',
  },
  '63822': {
    zip: '63822',
    city: 'Bernie',
    state: 'MO',
    latitude: '36.668746',
    longitude: '-89.969821',
    timeZoneId: 'America/Chicago',
  },
  '63823': {
    zip: '63823',
    city: 'Bertrand',
    state: 'MO',
    latitude: '36.911363',
    longitude: '-89.446282',
    timeZoneId: 'America/Chicago',
  },
  '63824': {
    zip: '63824',
    city: 'Blodgett',
    state: 'MO',
    latitude: '37.002542',
    longitude: '-89.525191',
    timeZoneId: 'America/Chicago',
  },
  '63825': {
    zip: '63825',
    city: 'Bloomfield',
    state: 'MO',
    latitude: '36.886309',
    longitude: '-89.930714',
    timeZoneId: 'America/Chicago',
  },
  '63826': {
    zip: '63826',
    city: 'Braggadocio',
    state: 'MO',
    latitude: '36.177959',
    longitude: '-89.673229',
    timeZoneId: 'America/Chicago',
  },
  '63827': {
    zip: '63827',
    city: 'Bragg City',
    state: 'MO',
    latitude: '36.267636',
    longitude: '-89.913435',
    timeZoneId: 'America/Chicago',
  },
  '63828': {
    zip: '63828',
    city: 'Canalou',
    state: 'MO',
    latitude: '36.754307',
    longitude: '-89.686524',
    timeZoneId: 'America/Chicago',
  },
  '63829': {
    zip: '63829',
    city: 'Cardwell',
    state: 'MO',
    latitude: '36.045006',
    longitude: '-90.292385',
    timeZoneId: 'America/Chicago',
  },
  '63830': {
    zip: '63830',
    city: 'Caruthersville',
    state: 'MO',
    latitude: '36.185729',
    longitude: '-89.658756',
    timeZoneId: 'America/Chicago',
  },
  '63833': {
    zip: '63833',
    city: 'Catron',
    state: 'MO',
    latitude: '36.692876',
    longitude: '-89.75525',
    timeZoneId: 'America/Chicago',
  },
  '63834': {
    zip: '63834',
    city: 'Charleston',
    state: 'MO',
    latitude: '36.92226',
    longitude: '-89.29548',
    timeZoneId: 'America/Chicago',
  },
  '63837': {
    zip: '63837',
    city: 'Clarkton',
    state: 'MO',
    latitude: '36.450728',
    longitude: '-89.964168',
    timeZoneId: 'America/Chicago',
  },
  '63839': {
    zip: '63839',
    city: 'Cooter',
    state: 'MO',
    latitude: '36.04577',
    longitude: '-89.808946',
    timeZoneId: 'America/Chicago',
  },
  '63840': {
    zip: '63840',
    city: 'Deering',
    state: 'MO',
    latitude: '36.189281',
    longitude: '-89.883509',
    timeZoneId: 'America/Chicago',
  },
  '63841': {
    zip: '63841',
    city: 'Dexter',
    state: 'MO',
    latitude: '36.794865',
    longitude: '-89.959662',
    timeZoneId: 'America/Chicago',
  },
  '63845': {
    zip: '63845',
    city: 'East Prairie',
    state: 'MO',
    latitude: '36.77993',
    longitude: '-89.384669',
    timeZoneId: 'America/Chicago',
  },
  '63846': {
    zip: '63846',
    city: 'Essex',
    state: 'MO',
    latitude: '36.801227',
    longitude: '-89.806089',
    timeZoneId: 'America/Chicago',
  },
  '63847': {
    zip: '63847',
    city: 'Gibson',
    state: 'MO',
    latitude: '36.456793',
    longitude: '-90.010085',
    timeZoneId: 'America/Chicago',
  },
  '63848': {
    zip: '63848',
    city: 'Gideon',
    state: 'MO',
    latitude: '36.45095',
    longitude: '-89.916845',
    timeZoneId: 'America/Chicago',
  },
  '63849': {
    zip: '63849',
    city: 'Gobler',
    state: 'MO',
    latitude: '36.156209',
    longitude: '-89.934704',
    timeZoneId: 'America/Chicago',
  },
  '63850': {
    zip: '63850',
    city: 'Grayridge',
    state: 'MO',
    latitude: '36.821694',
    longitude: '-89.783673',
    timeZoneId: 'America/Chicago',
  },
  '63851': {
    zip: '63851',
    city: 'Hayti',
    state: 'MO',
    latitude: '36.235589',
    longitude: '-89.749057',
    timeZoneId: 'America/Chicago',
  },
  '63852': {
    zip: '63852',
    city: 'Holcomb',
    state: 'MO',
    latitude: '36.403506',
    longitude: '-90.025878',
    timeZoneId: 'America/Chicago',
  },
  '63853': {
    zip: '63853',
    city: 'Holland',
    state: 'MO',
    latitude: '36.054895',
    longitude: '-89.870395',
    timeZoneId: 'America/Chicago',
  },
  '63855': {
    zip: '63855',
    city: 'Hornersville',
    state: 'MO',
    latitude: '36.041107',
    longitude: '-90.110624',
    timeZoneId: 'America/Chicago',
  },
  '63857': {
    zip: '63857',
    city: 'Kennett',
    state: 'MO',
    latitude: '36.23981',
    longitude: '-90.047625',
    timeZoneId: 'America/Chicago',
  },
  '63860': {
    zip: '63860',
    city: 'Kewanee',
    state: 'MO',
    latitude: '36.673159',
    longitude: '-89.572175',
    timeZoneId: 'America/Chicago',
  },
  '63862': {
    zip: '63862',
    city: 'Lilbourn',
    state: 'MO',
    latitude: '36.588932',
    longitude: '-89.617667',
    timeZoneId: 'America/Chicago',
  },
  '63863': {
    zip: '63863',
    city: 'Malden',
    state: 'MO',
    latitude: '36.558432',
    longitude: '-89.974113',
    timeZoneId: 'America/Chicago',
  },
  '63866': {
    zip: '63866',
    city: 'Marston',
    state: 'MO',
    latitude: '36.52762',
    longitude: '-89.606608',
    timeZoneId: 'America/Chicago',
  },
  '63867': {
    zip: '63867',
    city: 'Matthews',
    state: 'MO',
    latitude: '36.757869',
    longitude: '-89.587967',
    timeZoneId: 'America/Chicago',
  },
  '63868': {
    zip: '63868',
    city: 'Morehouse',
    state: 'MO',
    latitude: '36.845406',
    longitude: '-89.689939',
    timeZoneId: 'America/Chicago',
  },
  '63869': {
    zip: '63869',
    city: 'New Madrid',
    state: 'MO',
    latitude: '36.594',
    longitude: '-89.55864',
    timeZoneId: 'America/Chicago',
  },
  '63870': {
    zip: '63870',
    city: 'Parma',
    state: 'MO',
    latitude: '36.612439',
    longitude: '-89.818165',
    timeZoneId: 'America/Chicago',
  },
  '63873': {
    zip: '63873',
    city: 'Portageville',
    state: 'MO',
    latitude: '36.430781',
    longitude: '-89.697849',
    timeZoneId: 'America/Chicago',
  },
  '63874': {
    zip: '63874',
    city: 'Risco',
    state: 'MO',
    latitude: '36.548426',
    longitude: '-89.819413',
    timeZoneId: 'America/Chicago',
  },
  '63875': {
    zip: '63875',
    city: 'Rives',
    state: 'MO',
    latitude: '36.095992',
    longitude: '-90.014795',
    timeZoneId: 'America/Chicago',
  },
  '63876': {
    zip: '63876',
    city: 'Senath',
    state: 'MO',
    latitude: '36.137887',
    longitude: '-90.163119',
    timeZoneId: 'America/Chicago',
  },
  '63877': {
    zip: '63877',
    city: 'Steele',
    state: 'MO',
    latitude: '36.084889',
    longitude: '-89.832085',
    timeZoneId: 'America/Chicago',
  },
  '63878': {
    zip: '63878',
    city: 'Tallapoosa',
    state: 'MO',
    latitude: '36.506889',
    longitude: '-89.82166',
    timeZoneId: 'America/Chicago',
  },
  '63879': {
    zip: '63879',
    city: 'Wardell',
    state: 'MO',
    latitude: '36.35208',
    longitude: '-89.82036',
    timeZoneId: 'America/Chicago',
  },
  '63880': {
    zip: '63880',
    city: 'Whiteoak',
    state: 'MO',
    latitude: '36.1281',
    longitude: '-90.159041',
    timeZoneId: 'America/Chicago',
  },
  '63881': {
    zip: '63881',
    city: 'Wolf Island',
    state: 'MO',
    latitude: '36.737905',
    longitude: '-89.211829',
    timeZoneId: 'America/Chicago',
  },
  '63882': {
    zip: '63882',
    city: 'Wyatt',
    state: 'MO',
    latitude: '36.917072',
    longitude: '-89.223405',
    timeZoneId: 'America/Chicago',
  },
  '63901': {
    zip: '63901',
    city: 'Poplar Bluff',
    state: 'MO',
    latitude: '36.760411',
    longitude: '-90.402714',
    timeZoneId: 'America/Chicago',
  },
  '63902': {
    zip: '63902',
    city: 'Poplar Bluff',
    state: 'MO',
    latitude: '36.755596',
    longitude: '-90.395196',
    timeZoneId: 'America/Chicago',
  },
  '63931': {
    zip: '63931',
    city: 'Briar',
    state: 'MO',
    latitude: '36.641594',
    longitude: '-90.959266',
    timeZoneId: 'America/Chicago',
  },
  '63932': {
    zip: '63932',
    city: 'Broseley',
    state: 'MO',
    latitude: '36.707461',
    longitude: '-90.239409',
    timeZoneId: 'America/Chicago',
  },
  '63933': {
    zip: '63933',
    city: 'Campbell',
    state: 'MO',
    latitude: '36.49559',
    longitude: '-90.070204',
    timeZoneId: 'America/Chicago',
  },
  '63934': {
    zip: '63934',
    city: 'Clubb',
    state: 'MO',
    latitude: '37.235017',
    longitude: '-90.363369',
    timeZoneId: 'America/Chicago',
  },
  '63935': {
    zip: '63935',
    city: 'Doniphan',
    state: 'MO',
    latitude: '36.6225',
    longitude: '-90.8328',
    timeZoneId: 'America/Chicago',
  },
  '63936': {
    zip: '63936',
    city: 'Dudley',
    state: 'MO',
    latitude: '36.818178',
    longitude: '-90.132212',
    timeZoneId: 'America/Chicago',
  },
  '63937': {
    zip: '63937',
    city: 'Ellsinore',
    state: 'MO',
    latitude: '36.968779',
    longitude: '-90.751065',
    timeZoneId: 'America/Chicago',
  },
  '63939': {
    zip: '63939',
    city: 'Fairdealing',
    state: 'MO',
    latitude: '36.675012',
    longitude: '-90.644378',
    timeZoneId: 'America/Chicago',
  },
  '63940': {
    zip: '63940',
    city: 'Fisk',
    state: 'MO',
    latitude: '36.78331',
    longitude: '-90.209025',
    timeZoneId: 'America/Chicago',
  },
  '63941': {
    zip: '63941',
    city: 'Fremont',
    state: 'MO',
    latitude: '36.855192',
    longitude: '-91.124656',
    timeZoneId: 'America/Chicago',
  },
  '63942': {
    zip: '63942',
    city: 'Gatewood',
    state: 'MO',
    latitude: '36.560987',
    longitude: '-91.07423',
    timeZoneId: 'America/Chicago',
  },
  '63943': {
    zip: '63943',
    city: 'Grandin',
    state: 'MO',
    latitude: '36.816255',
    longitude: '-90.766353',
    timeZoneId: 'America/Chicago',
  },
  '63944': {
    zip: '63944',
    city: 'Greenville',
    state: 'MO',
    latitude: '37.092403',
    longitude: '-90.45114',
    timeZoneId: 'America/Chicago',
  },
  '63945': {
    zip: '63945',
    city: 'Harviell',
    state: 'MO',
    latitude: '36.684454',
    longitude: '-90.571343',
    timeZoneId: 'America/Chicago',
  },
  '63950': {
    zip: '63950',
    city: 'Lodi',
    state: 'MO',
    latitude: '37.245256',
    longitude: '-90.465916',
    timeZoneId: 'America/Chicago',
  },
  '63951': {
    zip: '63951',
    city: 'Lowndes',
    state: 'MO',
    latitude: '37.1345',
    longitude: '-90.251288',
    timeZoneId: 'America/Chicago',
  },
  '63952': {
    zip: '63952',
    city: 'Mill Spring',
    state: 'MO',
    latitude: '37.046271',
    longitude: '-90.642507',
    timeZoneId: 'America/Chicago',
  },
  '63953': {
    zip: '63953',
    city: 'Naylor',
    state: 'MO',
    latitude: '36.557758',
    longitude: '-90.628939',
    timeZoneId: 'America/Chicago',
  },
  '63954': {
    zip: '63954',
    city: 'Neelyville',
    state: 'MO',
    latitude: '36.571386',
    longitude: '-90.476614',
    timeZoneId: 'America/Chicago',
  },
  '63955': {
    zip: '63955',
    city: 'Oxly',
    state: 'MO',
    latitude: '36.583578',
    longitude: '-90.688128',
    timeZoneId: 'America/Chicago',
  },
  '63956': {
    zip: '63956',
    city: 'Patterson',
    state: 'MO',
    latitude: '37.210022',
    longitude: '-90.571496',
    timeZoneId: 'America/Chicago',
  },
  '63957': {
    zip: '63957',
    city: 'Piedmont',
    state: 'MO',
    latitude: '37.155164',
    longitude: '-90.690663',
    timeZoneId: 'America/Chicago',
  },
  '63960': {
    zip: '63960',
    city: 'Puxico',
    state: 'MO',
    latitude: '36.952919',
    longitude: '-90.15721',
    timeZoneId: 'America/Chicago',
  },
  '63961': {
    zip: '63961',
    city: 'Qulin',
    state: 'MO',
    latitude: '36.566301',
    longitude: '-90.271861',
    timeZoneId: 'America/Chicago',
  },
  '63962': {
    zip: '63962',
    city: 'Rombauer',
    state: 'MO',
    latitude: '36.842527',
    longitude: '-90.281788',
    timeZoneId: 'America/Chicago',
  },
  '63964': {
    zip: '63964',
    city: 'Silva',
    state: 'MO',
    latitude: '37.22598',
    longitude: '-90.4419',
    timeZoneId: 'America/Chicago',
  },
  '63965': {
    zip: '63965',
    city: 'Van Buren',
    state: 'MO',
    latitude: '36.99912',
    longitude: '-90.98712',
    timeZoneId: 'America/Chicago',
  },
  '63966': {
    zip: '63966',
    city: 'Wappapello',
    state: 'MO',
    latitude: '36.996844',
    longitude: '-90.274498',
    timeZoneId: 'America/Chicago',
  },
  '63967': {
    zip: '63967',
    city: 'Williamsville',
    state: 'MO',
    latitude: '36.961217',
    longitude: '-90.489841',
    timeZoneId: 'America/Chicago',
  },
  '64001': {
    zip: '64001',
    city: 'Alma',
    state: 'MO',
    latitude: '39.103607',
    longitude: '-93.546252',
    timeZoneId: 'America/Chicago',
  },
  '64002': {
    zip: '64002',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '39.104258',
    longitude: '-93.546629',
    timeZoneId: 'America/Chicago',
  },
  '64011': {
    zip: '64011',
    city: 'Bates City',
    state: 'MO',
    latitude: '38.979798',
    longitude: '-94.07089',
    timeZoneId: 'America/Chicago',
  },
  '64012': {
    zip: '64012',
    city: 'Belton',
    state: 'MO',
    latitude: '38.806411',
    longitude: '-94.535486',
    timeZoneId: 'America/Chicago',
  },
  '64013': {
    zip: '64013',
    city: 'Blue Springs',
    state: 'MO',
    latitude: '39.019361',
    longitude: '-94.282467',
    timeZoneId: 'America/Chicago',
  },
  '64014': {
    zip: '64014',
    city: 'Blue Springs',
    state: 'MO',
    latitude: '39.017346',
    longitude: '-94.260374',
    timeZoneId: 'America/Chicago',
  },
  '64015': {
    zip: '64015',
    city: 'Blue Springs',
    state: 'MO',
    latitude: '39.0177',
    longitude: '-94.31064',
    timeZoneId: 'America/Chicago',
  },
  '64016': {
    zip: '64016',
    city: 'Buckner',
    state: 'MO',
    latitude: '39.123058',
    longitude: '-94.198901',
    timeZoneId: 'America/Chicago',
  },
  '64017': {
    zip: '64017',
    city: 'Camden',
    state: 'MO',
    latitude: '39.192889',
    longitude: '-94.012716',
    timeZoneId: 'America/Chicago',
  },
  '64018': {
    zip: '64018',
    city: 'Camden Point',
    state: 'MO',
    latitude: '39.449535',
    longitude: '-94.731841',
    timeZoneId: 'America/Chicago',
  },
  '64019': {
    zip: '64019',
    city: 'Centerview',
    state: 'MO',
    latitude: '38.792943',
    longitude: '-93.873742',
    timeZoneId: 'America/Chicago',
  },
  '64020': {
    zip: '64020',
    city: 'Concordia',
    state: 'MO',
    latitude: '38.95523',
    longitude: '-93.584522',
    timeZoneId: 'America/Chicago',
  },
  '64021': {
    zip: '64021',
    city: 'Corder',
    state: 'MO',
    latitude: '39.111496',
    longitude: '-93.637089',
    timeZoneId: 'America/Chicago',
  },
  '64022': {
    zip: '64022',
    city: 'Dover',
    state: 'MO',
    latitude: '39.184485',
    longitude: '-93.663696',
    timeZoneId: 'America/Chicago',
  },
  '64024': {
    zip: '64024',
    city: 'Excelsior Springs',
    state: 'MO',
    latitude: '39.341594',
    longitude: '-94.236203',
    timeZoneId: 'America/Chicago',
  },
  '64028': {
    zip: '64028',
    city: 'Farley',
    state: 'MO',
    latitude: '39.283269',
    longitude: '-94.83181',
    timeZoneId: 'America/Chicago',
  },
  '64029': {
    zip: '64029',
    city: 'Grain Valley',
    state: 'MO',
    latitude: '39.005657',
    longitude: '-94.212745',
    timeZoneId: 'America/Chicago',
  },
  '64030': {
    zip: '64030',
    city: 'Grandview',
    state: 'MO',
    latitude: '38.884351',
    longitude: '-94.519596',
    timeZoneId: 'America/Chicago',
  },
  '64034': {
    zip: '64034',
    city: 'Greenwood',
    state: 'MO',
    latitude: '38.84904',
    longitude: '-94.28706',
    timeZoneId: 'America/Chicago',
  },
  '64035': {
    zip: '64035',
    city: 'Hardin',
    state: 'MO',
    latitude: '39.299385',
    longitude: '-93.812175',
    timeZoneId: 'America/Chicago',
  },
  '64036': {
    zip: '64036',
    city: 'Henrietta',
    state: 'MO',
    latitude: '39.209474',
    longitude: '-93.898888',
    timeZoneId: 'America/Chicago',
  },
  '64037': {
    zip: '64037',
    city: 'Higginsville',
    state: 'MO',
    latitude: '39.033181',
    longitude: '-93.707924',
    timeZoneId: 'America/Chicago',
  },
  '64040': {
    zip: '64040',
    city: 'Holden',
    state: 'MO',
    latitude: '38.716416',
    longitude: '-93.987397',
    timeZoneId: 'America/Chicago',
  },
  '64048': {
    zip: '64048',
    city: 'Holt',
    state: 'MO',
    latitude: '39.426632',
    longitude: '-94.377199',
    timeZoneId: 'America/Chicago',
  },
  '64050': {
    zip: '64050',
    city: 'Independence',
    state: 'MO',
    latitude: '39.094148',
    longitude: '-94.399135',
    timeZoneId: 'America/Chicago',
  },
  '64051': {
    zip: '64051',
    city: 'Independence',
    state: 'MO',
    latitude: '39.093706',
    longitude: '-94.417679',
    timeZoneId: 'America/Chicago',
  },
  '64052': {
    zip: '64052',
    city: 'Independence',
    state: 'MO',
    latitude: '39.072429',
    longitude: '-94.446384',
    timeZoneId: 'America/Chicago',
  },
  '64053': {
    zip: '64053',
    city: 'Independence',
    state: 'MO',
    latitude: '39.10616',
    longitude: '-94.463378',
    timeZoneId: 'America/Chicago',
  },
  '64054': {
    zip: '64054',
    city: 'Independence',
    state: 'MO',
    latitude: '39.111863',
    longitude: '-94.441141',
    timeZoneId: 'America/Chicago',
  },
  '64055': {
    zip: '64055',
    city: 'Independence',
    state: 'MO',
    latitude: '39.049222',
    longitude: '-94.401477',
    timeZoneId: 'America/Chicago',
  },
  '64056': {
    zip: '64056',
    city: 'Independence',
    state: 'MO',
    latitude: '39.118237',
    longitude: '-94.347327',
    timeZoneId: 'America/Chicago',
  },
  '64057': {
    zip: '64057',
    city: 'Independence',
    state: 'MO',
    latitude: '39.072219',
    longitude: '-94.346464',
    timeZoneId: 'America/Chicago',
  },
  '64058': {
    zip: '64058',
    city: 'Independence',
    state: 'MO',
    latitude: '39.141498',
    longitude: '-94.353728',
    timeZoneId: 'America/Chicago',
  },
  '64060': {
    zip: '64060',
    city: 'Kearney',
    state: 'MO',
    latitude: '39.363857',
    longitude: '-94.361037',
    timeZoneId: 'America/Chicago',
  },
  '64061': {
    zip: '64061',
    city: 'Kingsville',
    state: 'MO',
    latitude: '38.839976',
    longitude: '-94.118479',
    timeZoneId: 'America/Chicago',
  },
  '64062': {
    zip: '64062',
    city: 'Lawson',
    state: 'MO',
    latitude: '39.438047',
    longitude: '-94.203353',
    timeZoneId: 'America/Chicago',
  },
  '64063': {
    zip: '64063',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '38.917563',
    longitude: '-94.362282',
    timeZoneId: 'America/Chicago',
  },
  '64064': {
    zip: '64064',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '38.982769',
    longitude: '-94.365575',
    timeZoneId: 'America/Chicago',
  },
  '64065': {
    zip: '64065',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '38.949632',
    longitude: '-94.405068',
    timeZoneId: 'America/Chicago',
  },
  '64066': {
    zip: '64066',
    city: 'Levasy',
    state: 'MO',
    latitude: '39.135185',
    longitude: '-94.137788',
    timeZoneId: 'America/Chicago',
  },
  '64067': {
    zip: '64067',
    city: 'Lexington',
    state: 'MO',
    latitude: '39.14938',
    longitude: '-93.846657',
    timeZoneId: 'America/Chicago',
  },
  '64068': {
    zip: '64068',
    city: 'Liberty',
    state: 'MO',
    latitude: '39.27648',
    longitude: '-94.42326',
    timeZoneId: 'America/Chicago',
  },
  '64069': {
    zip: '64069',
    city: 'Liberty',
    state: 'MO',
    latitude: '39.24178',
    longitude: '-94.417223',
    timeZoneId: 'America/Chicago',
  },
  '64070': {
    zip: '64070',
    city: 'Lone Jack',
    state: 'MO',
    latitude: '38.895637',
    longitude: '-94.160372',
    timeZoneId: 'America/Chicago',
  },
  '64071': {
    zip: '64071',
    city: 'Mayview',
    state: 'MO',
    latitude: '39.040903',
    longitude: '-93.830524',
    timeZoneId: 'America/Chicago',
  },
  '64072': {
    zip: '64072',
    city: 'Missouri City',
    state: 'MO',
    latitude: '39.238267',
    longitude: '-94.291412',
    timeZoneId: 'America/Chicago',
  },
  '64073': {
    zip: '64073',
    city: 'Mosby',
    state: 'MO',
    latitude: '39.31391',
    longitude: '-94.29154',
    timeZoneId: 'America/Chicago',
  },
  '64074': {
    zip: '64074',
    city: 'Napoleon',
    state: 'MO',
    latitude: '39.107839',
    longitude: '-94.066239',
    timeZoneId: 'America/Chicago',
  },
  '64075': {
    zip: '64075',
    city: 'Oak Grove',
    state: 'MO',
    latitude: '38.998544',
    longitude: '-94.144642',
    timeZoneId: 'America/Chicago',
  },
  '64076': {
    zip: '64076',
    city: 'Odessa',
    state: 'MO',
    latitude: '38.996451',
    longitude: '-93.953938',
    timeZoneId: 'America/Chicago',
  },
  '64077': {
    zip: '64077',
    city: 'Orrick',
    state: 'MO',
    latitude: '39.248992',
    longitude: '-94.043368',
    timeZoneId: 'America/Chicago',
  },
  '64078': {
    zip: '64078',
    city: 'Peculiar',
    state: 'MO',
    latitude: '38.725247',
    longitude: '-94.455413',
    timeZoneId: 'America/Chicago',
  },
  '64079': {
    zip: '64079',
    city: 'Platte City',
    state: 'MO',
    latitude: '39.35484',
    longitude: '-94.7961',
    timeZoneId: 'America/Chicago',
  },
  '64080': {
    zip: '64080',
    city: 'Pleasant Hill',
    state: 'MO',
    latitude: '38.794067',
    longitude: '-94.269275',
    timeZoneId: 'America/Chicago',
  },
  '64081': {
    zip: '64081',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '38.912082',
    longitude: '-94.4061',
    timeZoneId: 'America/Chicago',
  },
  '64082': {
    zip: '64082',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '38.860336',
    longitude: '-94.400056',
    timeZoneId: 'America/Chicago',
  },
  '64083': {
    zip: '64083',
    city: 'Raymore',
    state: 'MO',
    latitude: '38.803006',
    longitude: '-94.446895',
    timeZoneId: 'America/Chicago',
  },
  '64084': {
    zip: '64084',
    city: 'Rayville',
    state: 'MO',
    latitude: '39.394887',
    longitude: '-94.039494',
    timeZoneId: 'America/Chicago',
  },
  '64085': {
    zip: '64085',
    city: 'Richmond',
    state: 'MO',
    latitude: '39.279528',
    longitude: '-93.976207',
    timeZoneId: 'America/Chicago',
  },
  '64086': {
    zip: '64086',
    city: 'Lees Summit',
    state: 'MO',
    latitude: '38.91918',
    longitude: '-94.32084',
    timeZoneId: 'America/Chicago',
  },
  '64088': {
    zip: '64088',
    city: 'Sibley',
    state: 'MO',
    latitude: '39.160798',
    longitude: '-94.18848',
    timeZoneId: 'America/Chicago',
  },
  '64089': {
    zip: '64089',
    city: 'Smithville',
    state: 'MO',
    latitude: '39.39816',
    longitude: '-94.53978',
    timeZoneId: 'America/Chicago',
  },
  '64090': {
    zip: '64090',
    city: 'Strasburg',
    state: 'MO',
    latitude: '38.76088',
    longitude: '-94.166075',
    timeZoneId: 'America/Chicago',
  },
  '64092': {
    zip: '64092',
    city: 'Waldron',
    state: 'MO',
    latitude: '39.222803',
    longitude: '-94.793089',
    timeZoneId: 'America/Chicago',
  },
  '64093': {
    zip: '64093',
    city: 'Warrensburg',
    state: 'MO',
    latitude: '38.758746',
    longitude: '-93.732134',
    timeZoneId: 'America/Chicago',
  },
  '64096': {
    zip: '64096',
    city: 'Waverly',
    state: 'MO',
    latitude: '39.202923',
    longitude: '-93.523427',
    timeZoneId: 'America/Chicago',
  },
  '64097': {
    zip: '64097',
    city: 'Wellington',
    state: 'MO',
    latitude: '39.13202',
    longitude: '-93.986179',
    timeZoneId: 'America/Chicago',
  },
  '64098': {
    zip: '64098',
    city: 'Weston',
    state: 'MO',
    latitude: '39.461895',
    longitude: '-94.911413',
    timeZoneId: 'America/Chicago',
  },
  '64101': {
    zip: '64101',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.104257',
    longitude: '-94.597017',
    timeZoneId: 'America/Chicago',
  },
  '64102': {
    zip: '64102',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.096511',
    longitude: '-94.606378',
    timeZoneId: 'America/Chicago',
  },
  '64105': {
    zip: '64105',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.105634',
    longitude: '-94.518958',
    timeZoneId: 'America/Chicago',
  },
  '64106': {
    zip: '64106',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.103459',
    longitude: '-94.569988',
    timeZoneId: 'America/Chicago',
  },
  '64108': {
    zip: '64108',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.08439',
    longitude: '-94.584545',
    timeZoneId: 'America/Chicago',
  },
  '64109': {
    zip: '64109',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.063189',
    longitude: '-94.569192',
    timeZoneId: 'America/Chicago',
  },
  '64110': {
    zip: '64110',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.034852',
    longitude: '-94.57235',
    timeZoneId: 'America/Chicago',
  },
  '64111': {
    zip: '64111',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.058627',
    longitude: '-94.592111',
    timeZoneId: 'America/Chicago',
  },
  '64112': {
    zip: '64112',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.038303',
    longitude: '-94.593927',
    timeZoneId: 'America/Chicago',
  },
  '64113': {
    zip: '64113',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.012892',
    longitude: '-94.596815',
    timeZoneId: 'America/Chicago',
  },
  '64114': {
    zip: '64114',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.963244',
    longitude: '-94.597784',
    timeZoneId: 'America/Chicago',
  },
  '64116': {
    zip: '64116',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.14784',
    longitude: '-94.56888',
    timeZoneId: 'America/Chicago',
  },
  '64117': {
    zip: '64117',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.154167',
    longitude: '-94.546667',
    timeZoneId: 'America/Chicago',
  },
  '64118': {
    zip: '64118',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.215793',
    longitude: '-94.572799',
    timeZoneId: 'America/Chicago',
  },
  '64119': {
    zip: '64119',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.208712',
    longitude: '-94.518737',
    timeZoneId: 'America/Chicago',
  },
  '64120': {
    zip: '64120',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.12391',
    longitude: '-94.5372',
    timeZoneId: 'America/Chicago',
  },
  '64121': {
    zip: '64121',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.100769',
    longitude: '-94.578994',
    timeZoneId: 'America/Chicago',
  },
  '64123': {
    zip: '64123',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.111153',
    longitude: '-94.523025',
    timeZoneId: 'America/Chicago',
  },
  '64124': {
    zip: '64124',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.109884',
    longitude: '-94.541043',
    timeZoneId: 'America/Chicago',
  },
  '64125': {
    zip: '64125',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.102846',
    longitude: '-94.491598',
    timeZoneId: 'America/Chicago',
  },
  '64126': {
    zip: '64126',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.092631',
    longitude: '-94.497302',
    timeZoneId: 'America/Chicago',
  },
  '64127': {
    zip: '64127',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.092263',
    longitude: '-94.537745',
    timeZoneId: 'America/Chicago',
  },
  '64128': {
    zip: '64128',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.067188',
    longitude: '-94.536792',
    timeZoneId: 'America/Chicago',
  },
  '64129': {
    zip: '64129',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.021792',
    longitude: '-94.489653',
    timeZoneId: 'America/Chicago',
  },
  '64130': {
    zip: '64130',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.032795',
    longitude: '-94.545329',
    timeZoneId: 'America/Chicago',
  },
  '64131': {
    zip: '64131',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.967545',
    longitude: '-94.573365',
    timeZoneId: 'America/Chicago',
  },
  '64132': {
    zip: '64132',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.988907',
    longitude: '-94.545827',
    timeZoneId: 'America/Chicago',
  },
  '64133': {
    zip: '64133',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.02202',
    longitude: '-94.46454',
    timeZoneId: 'America/Chicago',
  },
  '64134': {
    zip: '64134',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.933195',
    longitude: '-94.494689',
    timeZoneId: 'America/Chicago',
  },
  '64136': {
    zip: '64136',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.006121',
    longitude: '-94.39943',
    timeZoneId: 'America/Chicago',
  },
  '64137': {
    zip: '64137',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.933186',
    longitude: '-94.540154',
    timeZoneId: 'America/Chicago',
  },
  '64138': {
    zip: '64138',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.971599',
    longitude: '-94.474727',
    timeZoneId: 'America/Chicago',
  },
  '64139': {
    zip: '64139',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.969393',
    longitude: '-94.41307',
    timeZoneId: 'America/Chicago',
  },
  '64141': {
    zip: '64141',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.085594',
    longitude: '-94.585241',
    timeZoneId: 'America/Chicago',
  },
  '64144': {
    zip: '64144',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.143477',
    longitude: '-94.571588',
    timeZoneId: 'America/Chicago',
  },
  '64145': {
    zip: '64145',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.883941',
    longitude: '-94.595219',
    timeZoneId: 'America/Chicago',
  },
  '64146': {
    zip: '64146',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.871759',
    longitude: '-94.572443',
    timeZoneId: 'America/Chicago',
  },
  '64147': {
    zip: '64147',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.852262',
    longitude: '-94.547801',
    timeZoneId: 'America/Chicago',
  },
  '64148': {
    zip: '64148',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.088651',
    longitude: '-94.581287',
    timeZoneId: 'America/Chicago',
  },
  '64149': {
    zip: '64149',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.861361',
    longitude: '-94.461681',
    timeZoneId: 'America/Chicago',
  },
  '64150': {
    zip: '64150',
    city: 'Riverside',
    state: 'MO',
    latitude: '39.170613',
    longitude: '-94.62881',
    timeZoneId: 'America/Chicago',
  },
  '64151': {
    zip: '64151',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.210632',
    longitude: '-94.629615',
    timeZoneId: 'America/Chicago',
  },
  '64152': {
    zip: '64152',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.21816',
    longitude: '-94.7307',
    timeZoneId: 'America/Chicago',
  },
  '64153': {
    zip: '64153',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.27337',
    longitude: '-94.691489',
    timeZoneId: 'America/Chicago',
  },
  '64154': {
    zip: '64154',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.276392',
    longitude: '-94.627724',
    timeZoneId: 'America/Chicago',
  },
  '64155': {
    zip: '64155',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.27431',
    longitude: '-94.569245',
    timeZoneId: 'America/Chicago',
  },
  '64156': {
    zip: '64156',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.277465',
    longitude: '-94.525144',
    timeZoneId: 'America/Chicago',
  },
  '64157': {
    zip: '64157',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.277775',
    longitude: '-94.470561',
    timeZoneId: 'America/Chicago',
  },
  '64158': {
    zip: '64158',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.236716',
    longitude: '-94.481086',
    timeZoneId: 'America/Chicago',
  },
  '64161': {
    zip: '64161',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.161625',
    longitude: '-94.466674',
    timeZoneId: 'America/Chicago',
  },
  '64162': {
    zip: '64162',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.161625',
    longitude: '-94.466674',
    timeZoneId: 'America/Chicago',
  },
  '64163': {
    zip: '64163',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.33674',
    longitude: '-94.74472',
    timeZoneId: 'America/Chicago',
  },
  '64164': {
    zip: '64164',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.324928',
    longitude: '-94.62188',
    timeZoneId: 'America/Chicago',
  },
  '64165': {
    zip: '64165',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.325453',
    longitude: '-94.562798',
    timeZoneId: 'America/Chicago',
  },
  '64166': {
    zip: '64166',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.320343',
    longitude: '-94.520486',
    timeZoneId: 'America/Chicago',
  },
  '64167': {
    zip: '64167',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.319126',
    longitude: '-94.48513',
    timeZoneId: 'America/Chicago',
  },
  '64168': {
    zip: '64168',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.168601',
    longitude: '-94.631918',
    timeZoneId: 'America/Chicago',
  },
  '64170': {
    zip: '64170',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.951885',
    longitude: '-94.546834',
    timeZoneId: 'America/Chicago',
  },
  '64171': {
    zip: '64171',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.05094',
    longitude: '-94.588104',
    timeZoneId: 'America/Chicago',
  },
  '64179': {
    zip: '64179',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.12322',
    longitude: '-94.555049',
    timeZoneId: 'America/Chicago',
  },
  '64180': {
    zip: '64180',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.101274',
    longitude: '-94.582363',
    timeZoneId: 'America/Chicago',
  },
  '64184': {
    zip: '64184',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.1073',
    longitude: '-94.592461',
    timeZoneId: 'America/Chicago',
  },
  '64187': {
    zip: '64187',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.099481',
    longitude: '-94.577936',
    timeZoneId: 'America/Chicago',
  },
  '64188': {
    zip: '64188',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.099722',
    longitude: '-94.578333',
    timeZoneId: 'America/Chicago',
  },
  '64190': {
    zip: '64190',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.188576',
    longitude: '-94.682108',
    timeZoneId: 'America/Chicago',
  },
  '64191': {
    zip: '64191',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.087629',
    longitude: '-94.584711',
    timeZoneId: 'America/Chicago',
  },
  '64195': {
    zip: '64195',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.30204',
    longitude: '-94.718377',
    timeZoneId: 'America/Chicago',
  },
  '64196': {
    zip: '64196',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.098923',
    longitude: '-94.583366',
    timeZoneId: 'America/Chicago',
  },
  '64197': {
    zip: '64197',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.956872',
    longitude: '-94.571143',
    timeZoneId: 'America/Chicago',
  },
  '64198': {
    zip: '64198',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.101834',
    longitude: '-94.582276',
    timeZoneId: 'America/Chicago',
  },
  '64199': {
    zip: '64199',
    city: 'Kansas City',
    state: 'MO',
    latitude: '39.104105',
    longitude: '-94.583828',
    timeZoneId: 'America/Chicago',
  },
  '64401': {
    zip: '64401',
    city: 'Agency',
    state: 'MO',
    latitude: '39.663257',
    longitude: '-94.685736',
    timeZoneId: 'America/Chicago',
  },
  '64402': {
    zip: '64402',
    city: 'Albany',
    state: 'MO',
    latitude: '40.248635',
    longitude: '-94.331441',
    timeZoneId: 'America/Chicago',
  },
  '64420': {
    zip: '64420',
    city: 'Allendale',
    state: 'MO',
    latitude: '40.483158',
    longitude: '-94.288233',
    timeZoneId: 'America/Chicago',
  },
  '64421': {
    zip: '64421',
    city: 'Amazonia',
    state: 'MO',
    latitude: '39.9136',
    longitude: '-94.923408',
    timeZoneId: 'America/Chicago',
  },
  '64422': {
    zip: '64422',
    city: 'Amity',
    state: 'MO',
    latitude: '39.88912',
    longitude: '-94.521091',
    timeZoneId: 'America/Chicago',
  },
  '64423': {
    zip: '64423',
    city: 'Barnard',
    state: 'MO',
    latitude: '40.357435',
    longitude: '-94.891923',
    timeZoneId: 'America/Chicago',
  },
  '64424': {
    zip: '64424',
    city: 'Bethany',
    state: 'MO',
    latitude: '40.265514',
    longitude: '-94.034115',
    timeZoneId: 'America/Chicago',
  },
  '64426': {
    zip: '64426',
    city: 'Blythedale',
    state: 'MO',
    latitude: '40.471399',
    longitude: '-93.926357',
    timeZoneId: 'America/Chicago',
  },
  '64427': {
    zip: '64427',
    city: 'Bolckow',
    state: 'MO',
    latitude: '40.112616',
    longitude: '-94.822092',
    timeZoneId: 'America/Chicago',
  },
  '64428': {
    zip: '64428',
    city: 'Burlington Junction',
    state: 'MO',
    latitude: '40.436052',
    longitude: '-95.019402',
    timeZoneId: 'America/Chicago',
  },
  '64429': {
    zip: '64429',
    city: 'Cameron',
    state: 'MO',
    latitude: '39.735615',
    longitude: '-94.243823',
    timeZoneId: 'America/Chicago',
  },
  '64430': {
    zip: '64430',
    city: 'Clarksdale',
    state: 'MO',
    latitude: '39.813236',
    longitude: '-94.522762',
    timeZoneId: 'America/Chicago',
  },
  '64431': {
    zip: '64431',
    city: 'Clearmont',
    state: 'MO',
    latitude: '40.506061',
    longitude: '-95.034696',
    timeZoneId: 'America/Chicago',
  },
  '64432': {
    zip: '64432',
    city: 'Clyde',
    state: 'MO',
    latitude: '40.271216',
    longitude: '-94.639774',
    timeZoneId: 'America/Chicago',
  },
  '64433': {
    zip: '64433',
    city: 'Conception',
    state: 'MO',
    latitude: '40.229469',
    longitude: '-94.674235',
    timeZoneId: 'America/Chicago',
  },
  '64434': {
    zip: '64434',
    city: 'Conception Junction',
    state: 'MO',
    latitude: '40.276364',
    longitude: '-94.718527',
    timeZoneId: 'America/Chicago',
  },
  '64436': {
    zip: '64436',
    city: 'Cosby',
    state: 'MO',
    latitude: '39.850155',
    longitude: '-94.682207',
    timeZoneId: 'America/Chicago',
  },
  '64437': {
    zip: '64437',
    city: 'Craig',
    state: 'MO',
    latitude: '40.156812',
    longitude: '-95.358379',
    timeZoneId: 'America/Chicago',
  },
  '64438': {
    zip: '64438',
    city: 'Darlington',
    state: 'MO',
    latitude: '40.18873',
    longitude: '-94.413883',
    timeZoneId: 'America/Chicago',
  },
  '64439': {
    zip: '64439',
    city: 'Dearborn',
    state: 'MO',
    latitude: '39.527161',
    longitude: '-94.767966',
    timeZoneId: 'America/Chicago',
  },
  '64440': {
    zip: '64440',
    city: 'De Kalb',
    state: 'MO',
    latitude: '39.575413',
    longitude: '-94.939986',
    timeZoneId: 'America/Chicago',
  },
  '64441': {
    zip: '64441',
    city: 'Denver',
    state: 'MO',
    latitude: '40.427871',
    longitude: '-94.290489',
    timeZoneId: 'America/Chicago',
  },
  '64442': {
    zip: '64442',
    city: 'Eagleville',
    state: 'MO',
    latitude: '40.519342',
    longitude: '-94.028691',
    timeZoneId: 'America/Chicago',
  },
  '64443': {
    zip: '64443',
    city: 'Easton',
    state: 'MO',
    latitude: '39.764417',
    longitude: '-94.661851',
    timeZoneId: 'America/Chicago',
  },
  '64444': {
    zip: '64444',
    city: 'Edgerton',
    state: 'MO',
    latitude: '39.505807',
    longitude: '-94.633325',
    timeZoneId: 'America/Chicago',
  },
  '64445': {
    zip: '64445',
    city: 'Elmo',
    state: 'MO',
    latitude: '40.515953',
    longitude: '-95.127844',
    timeZoneId: 'America/Chicago',
  },
  '64446': {
    zip: '64446',
    city: 'Fairfax',
    state: 'MO',
    latitude: '40.33906',
    longitude: '-95.392618',
    timeZoneId: 'America/Chicago',
  },
  '64448': {
    zip: '64448',
    city: 'Faucett',
    state: 'MO',
    latitude: '39.585293',
    longitude: '-94.799734',
    timeZoneId: 'America/Chicago',
  },
  '64449': {
    zip: '64449',
    city: 'Fillmore',
    state: 'MO',
    latitude: '40.003091',
    longitude: '-94.948412',
    timeZoneId: 'America/Chicago',
  },
  '64451': {
    zip: '64451',
    city: 'Forest City',
    state: 'MO',
    latitude: '39.983626',
    longitude: '-95.222753',
    timeZoneId: 'America/Chicago',
  },
  '64453': {
    zip: '64453',
    city: 'Gentry',
    state: 'MO',
    latitude: '40.33182',
    longitude: '-94.42099',
    timeZoneId: 'America/Chicago',
  },
  '64454': {
    zip: '64454',
    city: 'Gower',
    state: 'MO',
    latitude: '39.591475',
    longitude: '-94.589642',
    timeZoneId: 'America/Chicago',
  },
  '64455': {
    zip: '64455',
    city: 'Graham',
    state: 'MO',
    latitude: '40.204423',
    longitude: '-95.043228',
    timeZoneId: 'America/Chicago',
  },
  '64456': {
    zip: '64456',
    city: 'Grant City',
    state: 'MO',
    latitude: '40.484416',
    longitude: '-94.411236',
    timeZoneId: 'America/Chicago',
  },
  '64457': {
    zip: '64457',
    city: 'Guilford',
    state: 'MO',
    latitude: '40.172689',
    longitude: '-94.676814',
    timeZoneId: 'America/Chicago',
  },
  '64458': {
    zip: '64458',
    city: 'Hatfield',
    state: 'MO',
    latitude: '40.521238',
    longitude: '-94.177381',
    timeZoneId: 'America/Chicago',
  },
  '64459': {
    zip: '64459',
    city: 'Helena',
    state: 'MO',
    latitude: '39.935056',
    longitude: '-94.690651',
    timeZoneId: 'America/Chicago',
  },
  '64461': {
    zip: '64461',
    city: 'Hopkins',
    state: 'MO',
    latitude: '40.541882',
    longitude: '-94.820256',
    timeZoneId: 'America/Chicago',
  },
  '64463': {
    zip: '64463',
    city: 'King City',
    state: 'MO',
    latitude: '40.050966',
    longitude: '-94.519029',
    timeZoneId: 'America/Chicago',
  },
  '64465': {
    zip: '64465',
    city: 'Lathrop',
    state: 'MO',
    latitude: '39.543217',
    longitude: '-94.300166',
    timeZoneId: 'America/Chicago',
  },
  '64466': {
    zip: '64466',
    city: 'Maitland',
    state: 'MO',
    latitude: '40.20326',
    longitude: '-95.076602',
    timeZoneId: 'America/Chicago',
  },
  '64467': {
    zip: '64467',
    city: 'Martinsville',
    state: 'MO',
    latitude: '40.380894',
    longitude: '-94.158772',
    timeZoneId: 'America/Chicago',
  },
  '64468': {
    zip: '64468',
    city: 'Maryville',
    state: 'MO',
    latitude: '40.346452',
    longitude: '-94.874254',
    timeZoneId: 'America/Chicago',
  },
  '64469': {
    zip: '64469',
    city: 'Maysville',
    state: 'MO',
    latitude: '39.888652',
    longitude: '-94.356601',
    timeZoneId: 'America/Chicago',
  },
  '64470': {
    zip: '64470',
    city: 'Mound City',
    state: 'MO',
    latitude: '40.133489',
    longitude: '-95.230985',
    timeZoneId: 'America/Chicago',
  },
  '64471': {
    zip: '64471',
    city: 'New Hampton',
    state: 'MO',
    latitude: '40.215738',
    longitude: '-94.158435',
    timeZoneId: 'America/Chicago',
  },
  '64473': {
    zip: '64473',
    city: 'Oregon',
    state: 'MO',
    latitude: '39.993891',
    longitude: '-95.139533',
    timeZoneId: 'America/Chicago',
  },
  '64474': {
    zip: '64474',
    city: 'Osborn',
    state: 'MO',
    latitude: '39.791289',
    longitude: '-94.290578',
    timeZoneId: 'America/Chicago',
  },
  '64475': {
    zip: '64475',
    city: 'Parnell',
    state: 'MO',
    latitude: '40.49744',
    longitude: '-94.682353',
    timeZoneId: 'America/Chicago',
  },
  '64476': {
    zip: '64476',
    city: 'Pickering',
    state: 'MO',
    latitude: '40.467593',
    longitude: '-94.833148',
    timeZoneId: 'America/Chicago',
  },
  '64477': {
    zip: '64477',
    city: 'Plattsburg',
    state: 'MO',
    latitude: '39.56275',
    longitude: '-94.455169',
    timeZoneId: 'America/Chicago',
  },
  '64479': {
    zip: '64479',
    city: 'Ravenwood',
    state: 'MO',
    latitude: '40.351795',
    longitude: '-94.672436',
    timeZoneId: 'America/Chicago',
  },
  '64480': {
    zip: '64480',
    city: 'Rea',
    state: 'MO',
    latitude: '40.058207',
    longitude: '-94.688636',
    timeZoneId: 'America/Chicago',
  },
  '64481': {
    zip: '64481',
    city: 'Ridgeway',
    state: 'MO',
    latitude: '40.41812',
    longitude: '-93.986501',
    timeZoneId: 'America/Chicago',
  },
  '64482': {
    zip: '64482',
    city: 'Rock Port',
    state: 'MO',
    latitude: '40.411278',
    longitude: '-95.516834',
    timeZoneId: 'America/Chicago',
  },
  '64483': {
    zip: '64483',
    city: 'Rosendale',
    state: 'MO',
    latitude: '40.042615',
    longitude: '-94.834753',
    timeZoneId: 'America/Chicago',
  },
  '64484': {
    zip: '64484',
    city: 'Rushville',
    state: 'MO',
    latitude: '39.587737',
    longitude: '-95.016396',
    timeZoneId: 'America/Chicago',
  },
  '64485': {
    zip: '64485',
    city: 'Savannah',
    state: 'MO',
    latitude: '39.940137',
    longitude: '-94.827263',
    timeZoneId: 'America/Chicago',
  },
  '64486': {
    zip: '64486',
    city: 'Sheridan',
    state: 'MO',
    latitude: '40.487896',
    longitude: '-94.545144',
    timeZoneId: 'America/Chicago',
  },
  '64487': {
    zip: '64487',
    city: 'Skidmore',
    state: 'MO',
    latitude: '40.290788',
    longitude: '-95.07394',
    timeZoneId: 'America/Chicago',
  },
  '64489': {
    zip: '64489',
    city: 'Stanberry',
    state: 'MO',
    latitude: '40.218266',
    longitude: '-94.538896',
    timeZoneId: 'America/Chicago',
  },
  '64490': {
    zip: '64490',
    city: 'Stewartsville',
    state: 'MO',
    latitude: '39.7539',
    longitude: '-94.5027',
    timeZoneId: 'America/Chicago',
  },
  '64491': {
    zip: '64491',
    city: 'Tarkio',
    state: 'MO',
    latitude: '40.437869',
    longitude: '-95.386243',
    timeZoneId: 'America/Chicago',
  },
  '64492': {
    zip: '64492',
    city: 'Trimble',
    state: 'MO',
    latitude: '39.494736',
    longitude: '-94.563052',
    timeZoneId: 'America/Chicago',
  },
  '64493': {
    zip: '64493',
    city: 'Turney',
    state: 'MO',
    latitude: '39.629025',
    longitude: '-94.288659',
    timeZoneId: 'America/Chicago',
  },
  '64494': {
    zip: '64494',
    city: 'Union Star',
    state: 'MO',
    latitude: '39.978656',
    longitude: '-94.597443',
    timeZoneId: 'America/Chicago',
  },
  '64496': {
    zip: '64496',
    city: 'Watson',
    state: 'MO',
    latitude: '40.481889',
    longitude: '-95.62734',
    timeZoneId: 'America/Chicago',
  },
  '64497': {
    zip: '64497',
    city: 'Weatherby',
    state: 'MO',
    latitude: '39.889149',
    longitude: '-94.261031',
    timeZoneId: 'America/Chicago',
  },
  '64498': {
    zip: '64498',
    city: 'Westboro',
    state: 'MO',
    latitude: '40.527669',
    longitude: '-95.31443',
    timeZoneId: 'America/Chicago',
  },
  '64499': {
    zip: '64499',
    city: 'Worth',
    state: 'MO',
    latitude: '40.42653',
    longitude: '-94.421383',
    timeZoneId: 'America/Chicago',
  },
  '64501': {
    zip: '64501',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.765162',
    longitude: '-94.844255',
    timeZoneId: 'America/Chicago',
  },
  '64502': {
    zip: '64502',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.763607',
    longitude: '-94.849982',
    timeZoneId: 'America/Chicago',
  },
  '64503': {
    zip: '64503',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.738976',
    longitude: '-94.817292',
    timeZoneId: 'America/Chicago',
  },
  '64504': {
    zip: '64504',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.714268',
    longitude: '-94.865023',
    timeZoneId: 'America/Chicago',
  },
  '64505': {
    zip: '64505',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.791709',
    longitude: '-94.841238',
    timeZoneId: 'America/Chicago',
  },
  '64506': {
    zip: '64506',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.788843',
    longitude: '-94.802142',
    timeZoneId: 'America/Chicago',
  },
  '64507': {
    zip: '64507',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.748921',
    longitude: '-94.808435',
    timeZoneId: 'America/Chicago',
  },
  '64508': {
    zip: '64508',
    city: 'Saint Joseph',
    state: 'MO',
    latitude: '39.776769',
    longitude: '-94.801029',
    timeZoneId: 'America/Chicago',
  },
  '64601': {
    zip: '64601',
    city: 'Chillicothe',
    state: 'MO',
    latitude: '39.793244',
    longitude: '-93.553948',
    timeZoneId: 'America/Chicago',
  },
  '64620': {
    zip: '64620',
    city: 'Altamont',
    state: 'MO',
    latitude: '39.932494',
    longitude: '-94.078627',
    timeZoneId: 'America/Chicago',
  },
  '64622': {
    zip: '64622',
    city: 'Bogard',
    state: 'MO',
    latitude: '39.315895',
    longitude: '-93.498092',
    timeZoneId: 'America/Chicago',
  },
  '64623': {
    zip: '64623',
    city: 'Bosworth',
    state: 'MO',
    latitude: '39.468808',
    longitude: '-93.339284',
    timeZoneId: 'America/Chicago',
  },
  '64624': {
    zip: '64624',
    city: 'Braymer',
    state: 'MO',
    latitude: '39.593217',
    longitude: '-93.788696',
    timeZoneId: 'America/Chicago',
  },
  '64625': {
    zip: '64625',
    city: 'Breckenridge',
    state: 'MO',
    latitude: '39.745818',
    longitude: '-93.81666',
    timeZoneId: 'America/Chicago',
  },
  '64628': {
    zip: '64628',
    city: 'Brookfield',
    state: 'MO',
    latitude: '39.788893',
    longitude: '-93.073091',
    timeZoneId: 'America/Chicago',
  },
  '64630': {
    zip: '64630',
    city: 'Browning',
    state: 'MO',
    latitude: '40.008468',
    longitude: '-93.170966',
    timeZoneId: 'America/Chicago',
  },
  '64631': {
    zip: '64631',
    city: 'Bucklin',
    state: 'MO',
    latitude: '39.784881',
    longitude: '-92.887121',
    timeZoneId: 'America/Chicago',
  },
  '64632': {
    zip: '64632',
    city: 'Cainsville',
    state: 'MO',
    latitude: '40.481913',
    longitude: '-93.743319',
    timeZoneId: 'America/Chicago',
  },
  '64633': {
    zip: '64633',
    city: 'Carrollton',
    state: 'MO',
    latitude: '39.364841',
    longitude: '-93.49329',
    timeZoneId: 'America/Chicago',
  },
  '64635': {
    zip: '64635',
    city: 'Chula',
    state: 'MO',
    latitude: '39.92212',
    longitude: '-93.477648',
    timeZoneId: 'America/Chicago',
  },
  '64636': {
    zip: '64636',
    city: 'Coffey',
    state: 'MO',
    latitude: '40.091422',
    longitude: '-94.050445',
    timeZoneId: 'America/Chicago',
  },
  '64637': {
    zip: '64637',
    city: 'Cowgill',
    state: 'MO',
    latitude: '39.569295',
    longitude: '-93.927257',
    timeZoneId: 'America/Chicago',
  },
  '64638': {
    zip: '64638',
    city: 'Dawn',
    state: 'MO',
    latitude: '39.664408',
    longitude: '-93.588363',
    timeZoneId: 'America/Chicago',
  },
  '64639': {
    zip: '64639',
    city: 'De Witt',
    state: 'MO',
    latitude: '39.35089',
    longitude: '-93.296628',
    timeZoneId: 'America/Chicago',
  },
  '64640': {
    zip: '64640',
    city: 'Gallatin',
    state: 'MO',
    latitude: '39.90282',
    longitude: '-93.9687',
    timeZoneId: 'America/Chicago',
  },
  '64641': {
    zip: '64641',
    city: 'Galt',
    state: 'MO',
    latitude: '40.15695',
    longitude: '-93.412411',
    timeZoneId: 'America/Chicago',
  },
  '64642': {
    zip: '64642',
    city: 'Gilman City',
    state: 'MO',
    latitude: '40.14708',
    longitude: '-93.84612',
    timeZoneId: 'America/Chicago',
  },
  '64643': {
    zip: '64643',
    city: 'Hale',
    state: 'MO',
    latitude: '39.569873',
    longitude: '-93.346196',
    timeZoneId: 'America/Chicago',
  },
  '64644': {
    zip: '64644',
    city: 'Hamilton',
    state: 'MO',
    latitude: '39.743022',
    longitude: '-93.997309',
    timeZoneId: 'America/Chicago',
  },
  '64645': {
    zip: '64645',
    city: 'Harris',
    state: 'MO',
    latitude: '40.312175',
    longitude: '-93.345961',
    timeZoneId: 'America/Chicago',
  },
  '64646': {
    zip: '64646',
    city: 'Humphreys',
    state: 'MO',
    latitude: '40.116732',
    longitude: '-93.295002',
    timeZoneId: 'America/Chicago',
  },
  '64647': {
    zip: '64647',
    city: 'Jameson',
    state: 'MO',
    latitude: '40.011109',
    longitude: '-93.96',
    timeZoneId: 'America/Chicago',
  },
  '64648': {
    zip: '64648',
    city: 'Jamesport',
    state: 'MO',
    latitude: '40.011038',
    longitude: '-93.792366',
    timeZoneId: 'America/Chicago',
  },
  '64649': {
    zip: '64649',
    city: 'Kidder',
    state: 'MO',
    latitude: '39.74125',
    longitude: '-94.151132',
    timeZoneId: 'America/Chicago',
  },
  '64650': {
    zip: '64650',
    city: 'Kingston',
    state: 'MO',
    latitude: '39.654749',
    longitude: '-94.093356',
    timeZoneId: 'America/Chicago',
  },
  '64651': {
    zip: '64651',
    city: 'Laclede',
    state: 'MO',
    latitude: '39.787818',
    longitude: '-93.16606',
    timeZoneId: 'America/Chicago',
  },
  '64652': {
    zip: '64652',
    city: 'Laredo',
    state: 'MO',
    latitude: '39.996154',
    longitude: '-93.433679',
    timeZoneId: 'America/Chicago',
  },
  '64653': {
    zip: '64653',
    city: 'Linneus',
    state: 'MO',
    latitude: '39.775581',
    longitude: '-93.062095',
    timeZoneId: 'America/Chicago',
  },
  '64654': {
    zip: '64654',
    city: 'Lock Springs',
    state: 'MO',
    latitude: '39.903622',
    longitude: '-93.817115',
    timeZoneId: 'America/Chicago',
  },
  '64655': {
    zip: '64655',
    city: 'Lucerne',
    state: 'MO',
    latitude: '40.430295',
    longitude: '-93.284993',
    timeZoneId: 'America/Chicago',
  },
  '64656': {
    zip: '64656',
    city: 'Ludlow',
    state: 'MO',
    latitude: '39.655647',
    longitude: '-93.703058',
    timeZoneId: 'America/Chicago',
  },
  '64657': {
    zip: '64657',
    city: 'Mc Fall',
    state: 'MO',
    latitude: '40.104867',
    longitude: '-94.328531',
    timeZoneId: 'America/Chicago',
  },
  '64658': {
    zip: '64658',
    city: 'Marceline',
    state: 'MO',
    latitude: '39.714416',
    longitude: '-92.94732',
    timeZoneId: 'America/Chicago',
  },
  '64659': {
    zip: '64659',
    city: 'Meadville',
    state: 'MO',
    latitude: '39.78024',
    longitude: '-93.29994',
    timeZoneId: 'America/Chicago',
  },
  '64660': {
    zip: '64660',
    city: 'Mendon',
    state: 'MO',
    latitude: '39.589805',
    longitude: '-93.132076',
    timeZoneId: 'America/Chicago',
  },
  '64661': {
    zip: '64661',
    city: 'Mercer',
    state: 'MO',
    latitude: '40.527222',
    longitude: '-93.497307',
    timeZoneId: 'America/Chicago',
  },
  '64664': {
    zip: '64664',
    city: 'Mooresville',
    state: 'MO',
    latitude: '39.742218',
    longitude: '-93.716811',
    timeZoneId: 'America/Chicago',
  },
  '64667': {
    zip: '64667',
    city: 'Newtown',
    state: 'MO',
    latitude: '40.345569',
    longitude: '-93.268683',
    timeZoneId: 'America/Chicago',
  },
  '64668': {
    zip: '64668',
    city: 'Norborne',
    state: 'MO',
    latitude: '39.299291',
    longitude: '-93.677905',
    timeZoneId: 'America/Chicago',
  },
  '64670': {
    zip: '64670',
    city: 'Pattonsburg',
    state: 'MO',
    latitude: '40.043173',
    longitude: '-94.136185',
    timeZoneId: 'America/Chicago',
  },
  '64671': {
    zip: '64671',
    city: 'Polo',
    state: 'MO',
    latitude: '39.556678',
    longitude: '-94.038416',
    timeZoneId: 'America/Chicago',
  },
  '64672': {
    zip: '64672',
    city: 'Powersville',
    state: 'MO',
    latitude: '40.528235',
    longitude: '-93.285829',
    timeZoneId: 'America/Chicago',
  },
  '64673': {
    zip: '64673',
    city: 'Princeton',
    state: 'MO',
    latitude: '40.396487',
    longitude: '-93.583391',
    timeZoneId: 'America/Chicago',
  },
  '64674': {
    zip: '64674',
    city: 'Purdin',
    state: 'MO',
    latitude: '39.957574',
    longitude: '-93.176712',
    timeZoneId: 'America/Chicago',
  },
  '64676': {
    zip: '64676',
    city: 'Rothville',
    state: 'MO',
    latitude: '39.653317',
    longitude: '-93.0634',
    timeZoneId: 'America/Chicago',
  },
  '64679': {
    zip: '64679',
    city: 'Spickard',
    state: 'MO',
    latitude: '40.233193',
    longitude: '-93.498769',
    timeZoneId: 'America/Chicago',
  },
  '64680': {
    zip: '64680',
    city: 'Stet',
    state: 'MO',
    latitude: '39.424328',
    longitude: '-93.757542',
    timeZoneId: 'America/Chicago',
  },
  '64681': {
    zip: '64681',
    city: 'Sumner',
    state: 'MO',
    latitude: '39.655667',
    longitude: '-93.244717',
    timeZoneId: 'America/Chicago',
  },
  '64682': {
    zip: '64682',
    city: 'Tina',
    state: 'MO',
    latitude: '39.570852',
    longitude: '-93.476707',
    timeZoneId: 'America/Chicago',
  },
  '64683': {
    zip: '64683',
    city: 'Trenton',
    state: 'MO',
    latitude: '40.078927',
    longitude: '-93.611226',
    timeZoneId: 'America/Chicago',
  },
  '64686': {
    zip: '64686',
    city: 'Utica',
    state: 'MO',
    latitude: '39.73782',
    longitude: '-93.627947',
    timeZoneId: 'America/Chicago',
  },
  '64688': {
    zip: '64688',
    city: 'Wheeling',
    state: 'MO',
    latitude: '39.805261',
    longitude: '-93.393424',
    timeZoneId: 'America/Chicago',
  },
  '64689': {
    zip: '64689',
    city: 'Winston',
    state: 'MO',
    latitude: '39.871939',
    longitude: '-94.138417',
    timeZoneId: 'America/Chicago',
  },
  '64701': {
    zip: '64701',
    city: 'Harrisonville',
    state: 'MO',
    latitude: '38.629786',
    longitude: '-94.326071',
    timeZoneId: 'America/Chicago',
  },
  '64720': {
    zip: '64720',
    city: 'Adrian',
    state: 'MO',
    latitude: '38.397993',
    longitude: '-94.348187',
    timeZoneId: 'America/Chicago',
  },
  '64722': {
    zip: '64722',
    city: 'Amoret',
    state: 'MO',
    latitude: '38.270029',
    longitude: '-94.560921',
    timeZoneId: 'America/Chicago',
  },
  '64723': {
    zip: '64723',
    city: 'Amsterdam',
    state: 'MO',
    latitude: '38.394087',
    longitude: '-94.56304',
    timeZoneId: 'America/Chicago',
  },
  '64724': {
    zip: '64724',
    city: 'Appleton City',
    state: 'MO',
    latitude: '38.188111',
    longitude: '-94.027023',
    timeZoneId: 'America/Chicago',
  },
  '64725': {
    zip: '64725',
    city: 'Archie',
    state: 'MO',
    latitude: '38.482614',
    longitude: '-94.355052',
    timeZoneId: 'America/Chicago',
  },
  '64726': {
    zip: '64726',
    city: 'Blairstown',
    state: 'MO',
    latitude: '38.554804',
    longitude: '-93.958139',
    timeZoneId: 'America/Chicago',
  },
  '64728': {
    zip: '64728',
    city: 'Bronaugh',
    state: 'MO',
    latitude: '37.690562',
    longitude: '-94.46302',
    timeZoneId: 'America/Chicago',
  },
  '64730': {
    zip: '64730',
    city: 'Butler',
    state: 'MO',
    latitude: '38.256683',
    longitude: '-94.337631',
    timeZoneId: 'America/Chicago',
  },
  '64733': {
    zip: '64733',
    city: 'Chilhowee',
    state: 'MO',
    latitude: '38.586839',
    longitude: '-93.857218',
    timeZoneId: 'America/Chicago',
  },
  '64734': {
    zip: '64734',
    city: 'Cleveland',
    state: 'MO',
    latitude: '38.678619',
    longitude: '-94.592674',
    timeZoneId: 'America/Chicago',
  },
  '64735': {
    zip: '64735',
    city: 'Clinton',
    state: 'MO',
    latitude: '38.370798',
    longitude: '-93.768621',
    timeZoneId: 'America/Chicago',
  },
  '64738': {
    zip: '64738',
    city: 'Collins',
    state: 'MO',
    latitude: '37.869606',
    longitude: '-93.678666',
    timeZoneId: 'America/Chicago',
  },
  '64739': {
    zip: '64739',
    city: 'Creighton',
    state: 'MO',
    latitude: '38.504829',
    longitude: '-94.095951',
    timeZoneId: 'America/Chicago',
  },
  '64740': {
    zip: '64740',
    city: 'Deepwater',
    state: 'MO',
    latitude: '38.262928',
    longitude: '-93.77834',
    timeZoneId: 'America/Chicago',
  },
  '64741': {
    zip: '64741',
    city: 'Deerfield',
    state: 'MO',
    latitude: '37.810088',
    longitude: '-94.512447',
    timeZoneId: 'America/Chicago',
  },
  '64742': {
    zip: '64742',
    city: 'Drexel',
    state: 'MO',
    latitude: '38.479745',
    longitude: '-94.607715',
    timeZoneId: 'America/Chicago',
  },
  '64743': {
    zip: '64743',
    city: 'East Lynne',
    state: 'MO',
    latitude: '38.668198',
    longitude: '-94.227681',
    timeZoneId: 'America/Chicago',
  },
  '64744': {
    zip: '64744',
    city: 'El Dorado Springs',
    state: 'MO',
    latitude: '37.87184',
    longitude: '-94.022563',
    timeZoneId: 'America/Chicago',
  },
  '64745': {
    zip: '64745',
    city: 'Foster',
    state: 'MO',
    latitude: '38.173144',
    longitude: '-94.553762',
    timeZoneId: 'America/Chicago',
  },
  '64746': {
    zip: '64746',
    city: 'Freeman',
    state: 'MO',
    latitude: '38.610172',
    longitude: '-94.477945',
    timeZoneId: 'America/Chicago',
  },
  '64747': {
    zip: '64747',
    city: 'Garden City',
    state: 'MO',
    latitude: '38.555303',
    longitude: '-94.188013',
    timeZoneId: 'America/Chicago',
  },
  '64748': {
    zip: '64748',
    city: 'Golden City',
    state: 'MO',
    latitude: '37.392379',
    longitude: '-94.092888',
    timeZoneId: 'America/Chicago',
  },
  '64750': {
    zip: '64750',
    city: 'Harwood',
    state: 'MO',
    latitude: '37.94958',
    longitude: '-94.1157',
    timeZoneId: 'America/Chicago',
  },
  '64752': {
    zip: '64752',
    city: 'Hume',
    state: 'MO',
    latitude: '38.090159',
    longitude: '-94.586446',
    timeZoneId: 'America/Chicago',
  },
  '64755': {
    zip: '64755',
    city: 'Jasper',
    state: 'MO',
    latitude: '37.326541',
    longitude: '-94.302019',
    timeZoneId: 'America/Chicago',
  },
  '64756': {
    zip: '64756',
    city: 'Jerico Springs',
    state: 'MO',
    latitude: '37.669139',
    longitude: '-94.008138',
    timeZoneId: 'America/Chicago',
  },
  '64759': {
    zip: '64759',
    city: 'Lamar',
    state: 'MO',
    latitude: '37.493876',
    longitude: '-94.271176',
    timeZoneId: 'America/Chicago',
  },
  '64761': {
    zip: '64761',
    city: 'Leeton',
    state: 'MO',
    latitude: '38.583887',
    longitude: '-93.691195',
    timeZoneId: 'America/Chicago',
  },
  '64762': {
    zip: '64762',
    city: 'Liberal',
    state: 'MO',
    latitude: '37.557891',
    longitude: '-94.514277',
    timeZoneId: 'America/Chicago',
  },
  '64763': {
    zip: '64763',
    city: 'Lowry City',
    state: 'MO',
    latitude: '38.137469',
    longitude: '-93.726117',
    timeZoneId: 'America/Chicago',
  },
  '64765': {
    zip: '64765',
    city: 'Metz',
    state: 'MO',
    latitude: '37.996719',
    longitude: '-94.444093',
    timeZoneId: 'America/Chicago',
  },
  '64766': {
    zip: '64766',
    city: 'Milford',
    state: 'MO',
    latitude: '37.586167',
    longitude: '-94.155511',
    timeZoneId: 'America/Chicago',
  },
  '64767': {
    zip: '64767',
    city: 'Milo',
    state: 'MO',
    latitude: '37.736932',
    longitude: '-94.297334',
    timeZoneId: 'America/Chicago',
  },
  '64769': {
    zip: '64769',
    city: 'Mindenmines',
    state: 'MO',
    latitude: '37.421285',
    longitude: '-94.557183',
    timeZoneId: 'America/Chicago',
  },
  '64770': {
    zip: '64770',
    city: 'Montrose',
    state: 'MO',
    latitude: '38.258039',
    longitude: '-93.984093',
    timeZoneId: 'America/Chicago',
  },
  '64771': {
    zip: '64771',
    city: 'Moundville',
    state: 'MO',
    latitude: '37.744332',
    longitude: '-94.49454',
    timeZoneId: 'America/Chicago',
  },
  '64772': {
    zip: '64772',
    city: 'Nevada',
    state: 'MO',
    latitude: '37.838803',
    longitude: '-94.36126',
    timeZoneId: 'America/Chicago',
  },
  '64776': {
    zip: '64776',
    city: 'Osceola',
    state: 'MO',
    latitude: '38.040765',
    longitude: '-93.704717',
    timeZoneId: 'America/Chicago',
  },
  '64778': {
    zip: '64778',
    city: 'Richards',
    state: 'MO',
    latitude: '37.941915',
    longitude: '-94.557009',
    timeZoneId: 'America/Chicago',
  },
  '64779': {
    zip: '64779',
    city: 'Rich Hill',
    state: 'MO',
    latitude: '38.09659',
    longitude: '-94.36633',
    timeZoneId: 'America/Chicago',
  },
  '64780': {
    zip: '64780',
    city: 'Rockville',
    state: 'MO',
    latitude: '38.07552',
    longitude: '-94.14732',
    timeZoneId: 'America/Chicago',
  },
  '64781': {
    zip: '64781',
    city: 'Roscoe',
    state: 'MO',
    latitude: '37.976815',
    longitude: '-93.812387',
    timeZoneId: 'America/Chicago',
  },
  '64783': {
    zip: '64783',
    city: 'Schell City',
    state: 'MO',
    latitude: '38.000678',
    longitude: '-94.18369',
    timeZoneId: 'America/Chicago',
  },
  '64784': {
    zip: '64784',
    city: 'Sheldon',
    state: 'MO',
    latitude: '37.658454',
    longitude: '-94.297359',
    timeZoneId: 'America/Chicago',
  },
  '64788': {
    zip: '64788',
    city: 'Urich',
    state: 'MO',
    latitude: '38.458971',
    longitude: '-93.995235',
    timeZoneId: 'America/Chicago',
  },
  '64790': {
    zip: '64790',
    city: 'Walker',
    state: 'MO',
    latitude: '37.888483',
    longitude: '-94.232012',
    timeZoneId: 'America/Chicago',
  },
  '64801': {
    zip: '64801',
    city: 'Joplin',
    state: 'MO',
    latitude: '37.1085',
    longitude: '-94.51506',
    timeZoneId: 'America/Chicago',
  },
  '64802': {
    zip: '64802',
    city: 'Joplin',
    state: 'MO',
    latitude: '37.055577',
    longitude: '-94.543038',
    timeZoneId: 'America/Chicago',
  },
  '64803': {
    zip: '64803',
    city: 'Joplin',
    state: 'MO',
    latitude: '37.034415',
    longitude: '-94.509317',
    timeZoneId: 'America/Chicago',
  },
  '64804': {
    zip: '64804',
    city: 'Joplin',
    state: 'MO',
    latitude: '37.064704',
    longitude: '-94.509874',
    timeZoneId: 'America/Chicago',
  },
  '64830': {
    zip: '64830',
    city: 'Alba',
    state: 'MO',
    latitude: '37.23559',
    longitude: '-94.416734',
    timeZoneId: 'America/Chicago',
  },
  '64831': {
    zip: '64831',
    city: 'Anderson',
    state: 'MO',
    latitude: '36.648293',
    longitude: '-94.447841',
    timeZoneId: 'America/Chicago',
  },
  '64832': {
    zip: '64832',
    city: 'Asbury',
    state: 'MO',
    latitude: '37.272981',
    longitude: '-94.609466',
    timeZoneId: 'America/Chicago',
  },
  '64833': {
    zip: '64833',
    city: 'Avilla',
    state: 'MO',
    latitude: '37.193512',
    longitude: '-94.132474',
    timeZoneId: 'America/Chicago',
  },
  '64834': {
    zip: '64834',
    city: 'Carl Junction',
    state: 'MO',
    latitude: '37.170235',
    longitude: '-94.542468',
    timeZoneId: 'America/Chicago',
  },
  '64835': {
    zip: '64835',
    city: 'Carterville',
    state: 'MO',
    latitude: '37.148199',
    longitude: '-94.439147',
    timeZoneId: 'America/Chicago',
  },
  '64836': {
    zip: '64836',
    city: 'Carthage',
    state: 'MO',
    latitude: '37.16829',
    longitude: '-94.314586',
    timeZoneId: 'America/Chicago',
  },
  '64840': {
    zip: '64840',
    city: 'Diamond',
    state: 'MO',
    latitude: '36.837638',
    longitude: '-94.376662',
    timeZoneId: 'America/Chicago',
  },
  '64841': {
    zip: '64841',
    city: 'Duenweg',
    state: 'MO',
    latitude: '37.07785',
    longitude: '-94.410853',
    timeZoneId: 'America/Chicago',
  },
  '64842': {
    zip: '64842',
    city: 'Fairview',
    state: 'MO',
    latitude: '36.818748',
    longitude: '-94.083802',
    timeZoneId: 'America/Chicago',
  },
  '64843': {
    zip: '64843',
    city: 'Goodman',
    state: 'MO',
    latitude: '36.737322',
    longitude: '-94.406831',
    timeZoneId: 'America/Chicago',
  },
  '64844': {
    zip: '64844',
    city: 'Granby',
    state: 'MO',
    latitude: '36.92021',
    longitude: '-94.267372',
    timeZoneId: 'America/Chicago',
  },
  '64847': {
    zip: '64847',
    city: 'Lanagan',
    state: 'MO',
    latitude: '36.598418',
    longitude: '-94.457108',
    timeZoneId: 'America/Chicago',
  },
  '64848': {
    zip: '64848',
    city: 'La Russell',
    state: 'MO',
    latitude: '37.193142',
    longitude: '-94.01614',
    timeZoneId: 'America/Chicago',
  },
  '64849': {
    zip: '64849',
    city: 'Neck City',
    state: 'MO',
    latitude: '37.258053',
    longitude: '-94.444753',
    timeZoneId: 'America/Chicago',
  },
  '64850': {
    zip: '64850',
    city: 'Neosho',
    state: 'MO',
    latitude: '36.863757',
    longitude: '-94.373223',
    timeZoneId: 'America/Chicago',
  },
  '64853': {
    zip: '64853',
    city: 'Newtonia',
    state: 'MO',
    latitude: '36.877158',
    longitude: '-94.187271',
    timeZoneId: 'America/Chicago',
  },
  '64854': {
    zip: '64854',
    city: 'Noel',
    state: 'MO',
    latitude: '36.541955',
    longitude: '-94.486016',
    timeZoneId: 'America/Chicago',
  },
  '64855': {
    zip: '64855',
    city: 'Oronogo',
    state: 'MO',
    latitude: '37.189393',
    longitude: '-94.466033',
    timeZoneId: 'America/Chicago',
  },
  '64856': {
    zip: '64856',
    city: 'Pineville',
    state: 'MO',
    latitude: '36.58854',
    longitude: '-94.36998',
    timeZoneId: 'America/Chicago',
  },
  '64857': {
    zip: '64857',
    city: 'Purcell',
    state: 'MO',
    latitude: '37.241722',
    longitude: '-94.435865',
    timeZoneId: 'America/Chicago',
  },
  '64858': {
    zip: '64858',
    city: 'Racine',
    state: 'MO',
    latitude: '36.896329',
    longitude: '-94.52582',
    timeZoneId: 'America/Chicago',
  },
  '64859': {
    zip: '64859',
    city: 'Reeds',
    state: 'MO',
    latitude: '37.130651',
    longitude: '-94.150465',
    timeZoneId: 'America/Chicago',
  },
  '64861': {
    zip: '64861',
    city: 'Rocky Comfort',
    state: 'MO',
    latitude: '36.701',
    longitude: '-94.121763',
    timeZoneId: 'America/Chicago',
  },
  '64862': {
    zip: '64862',
    city: 'Sarcoxie',
    state: 'MO',
    latitude: '37.06782',
    longitude: '-94.126452',
    timeZoneId: 'America/Chicago',
  },
  '64863': {
    zip: '64863',
    city: 'South West City',
    state: 'MO',
    latitude: '36.510903',
    longitude: '-94.612197',
    timeZoneId: 'America/Chicago',
  },
  '64864': {
    zip: '64864',
    city: 'Saginaw',
    state: 'MO',
    latitude: '37.025742',
    longitude: '-94.470028',
    timeZoneId: 'America/Chicago',
  },
  '64865': {
    zip: '64865',
    city: 'Seneca',
    state: 'MO',
    latitude: '36.839888',
    longitude: '-94.609979',
    timeZoneId: 'America/Chicago',
  },
  '64866': {
    zip: '64866',
    city: 'Stark City',
    state: 'MO',
    latitude: '36.885513',
    longitude: '-94.142051',
    timeZoneId: 'America/Chicago',
  },
  '64867': {
    zip: '64867',
    city: 'Stella',
    state: 'MO',
    latitude: '36.747612',
    longitude: '-94.218145',
    timeZoneId: 'America/Chicago',
  },
  '64868': {
    zip: '64868',
    city: 'Tiff City',
    state: 'MO',
    latitude: '36.66728',
    longitude: '-94.598784',
    timeZoneId: 'America/Chicago',
  },
  '64870': {
    zip: '64870',
    city: 'Webb City',
    state: 'MO',
    latitude: '37.144119',
    longitude: '-94.470557',
    timeZoneId: 'America/Chicago',
  },
  '64873': {
    zip: '64873',
    city: 'Wentworth',
    state: 'MO',
    latitude: '36.98708',
    longitude: '-94.134257',
    timeZoneId: 'America/Chicago',
  },
  '64874': {
    zip: '64874',
    city: 'Wheaton',
    state: 'MO',
    latitude: '36.765366',
    longitude: '-94.056563',
    timeZoneId: 'America/Chicago',
  },
  '64999': {
    zip: '64999',
    city: 'Kansas City',
    state: 'MO',
    latitude: '38.953479',
    longitude: '-94.549666',
    timeZoneId: 'America/Chicago',
  },
  '65001': {
    zip: '65001',
    city: 'Argyle',
    state: 'MO',
    latitude: '38.299331',
    longitude: '-92.007748',
    timeZoneId: 'America/Chicago',
  },
  '65010': {
    zip: '65010',
    city: 'Ashland',
    state: 'MO',
    latitude: '38.789046',
    longitude: '-92.259669',
    timeZoneId: 'America/Chicago',
  },
  '65011': {
    zip: '65011',
    city: 'Barnett',
    state: 'MO',
    latitude: '38.400396',
    longitude: '-92.756743',
    timeZoneId: 'America/Chicago',
  },
  '65013': {
    zip: '65013',
    city: 'Belle',
    state: 'MO',
    latitude: '38.276622',
    longitude: '-91.726297',
    timeZoneId: 'America/Chicago',
  },
  '65014': {
    zip: '65014',
    city: 'Bland',
    state: 'MO',
    latitude: '38.302146',
    longitude: '-91.63488',
    timeZoneId: 'America/Chicago',
  },
  '65016': {
    zip: '65016',
    city: 'Bonnots Mill',
    state: 'MO',
    latitude: '38.562513',
    longitude: '-91.91976',
    timeZoneId: 'America/Chicago',
  },
  '65017': {
    zip: '65017',
    city: 'Brumley',
    state: 'MO',
    latitude: '38.088152',
    longitude: '-92.530146',
    timeZoneId: 'America/Chicago',
  },
  '65018': {
    zip: '65018',
    city: 'California',
    state: 'MO',
    latitude: '38.631897',
    longitude: '-92.56772',
    timeZoneId: 'America/Chicago',
  },
  '65020': {
    zip: '65020',
    city: 'Camdenton',
    state: 'MO',
    latitude: '38.0253',
    longitude: '-92.77866',
    timeZoneId: 'America/Chicago',
  },
  '65023': {
    zip: '65023',
    city: 'Centertown',
    state: 'MO',
    latitude: '38.612674',
    longitude: '-92.385139',
    timeZoneId: 'America/Chicago',
  },
  '65024': {
    zip: '65024',
    city: 'Chamois',
    state: 'MO',
    latitude: '38.66424',
    longitude: '-91.76916',
    timeZoneId: 'America/Chicago',
  },
  '65025': {
    zip: '65025',
    city: 'Clarksburg',
    state: 'MO',
    latitude: '38.630773',
    longitude: '-92.682499',
    timeZoneId: 'America/Chicago',
  },
  '65026': {
    zip: '65026',
    city: 'Eldon',
    state: 'MO',
    latitude: '38.348224',
    longitude: '-92.579976',
    timeZoneId: 'America/Chicago',
  },
  '65032': {
    zip: '65032',
    city: 'Eugene',
    state: 'MO',
    latitude: '38.386782',
    longitude: '-92.366414',
    timeZoneId: 'America/Chicago',
  },
  '65034': {
    zip: '65034',
    city: 'Fortuna',
    state: 'MO',
    latitude: '38.57784',
    longitude: '-92.771549',
    timeZoneId: 'America/Chicago',
  },
  '65035': {
    zip: '65035',
    city: 'Freeburg',
    state: 'MO',
    latitude: '38.33652',
    longitude: '-91.92822',
    timeZoneId: 'America/Chicago',
  },
  '65036': {
    zip: '65036',
    city: 'Gasconade',
    state: 'MO',
    latitude: '38.672778',
    longitude: '-91.636111',
    timeZoneId: 'America/Chicago',
  },
  '65037': {
    zip: '65037',
    city: 'Gravois Mills',
    state: 'MO',
    latitude: '38.20192',
    longitude: '-92.83719',
    timeZoneId: 'America/Chicago',
  },
  '65038': {
    zip: '65038',
    city: 'Laurie',
    state: 'MO',
    latitude: '38.199727',
    longitude: '-92.833349',
    timeZoneId: 'America/Chicago',
  },
  '65039': {
    zip: '65039',
    city: 'Hartsburg',
    state: 'MO',
    latitude: '38.72943',
    longitude: '-92.304428',
    timeZoneId: 'America/Chicago',
  },
  '65040': {
    zip: '65040',
    city: 'Henley',
    state: 'MO',
    latitude: '38.37972',
    longitude: '-92.332906',
    timeZoneId: 'America/Chicago',
  },
  '65041': {
    zip: '65041',
    city: 'Hermann',
    state: 'MO',
    latitude: '38.697938',
    longitude: '-91.437726',
    timeZoneId: 'America/Chicago',
  },
  '65042': {
    zip: '65042',
    city: 'High Point',
    state: 'MO',
    latitude: '38.478593',
    longitude: '-92.606101',
    timeZoneId: 'America/Chicago',
  },
  '65043': {
    zip: '65043',
    city: 'Holts Summit',
    state: 'MO',
    latitude: '38.639139',
    longitude: '-92.116345',
    timeZoneId: 'America/Chicago',
  },
  '65046': {
    zip: '65046',
    city: 'Jamestown',
    state: 'MO',
    latitude: '38.790635',
    longitude: '-92.483677',
    timeZoneId: 'America/Chicago',
  },
  '65047': {
    zip: '65047',
    city: 'Kaiser',
    state: 'MO',
    latitude: '38.172871',
    longitude: '-92.564265',
    timeZoneId: 'America/Chicago',
  },
  '65048': {
    zip: '65048',
    city: 'Koeltztown',
    state: 'MO',
    latitude: '38.321876',
    longitude: '-92.052887',
    timeZoneId: 'America/Chicago',
  },
  '65049': {
    zip: '65049',
    city: 'Lake Ozark',
    state: 'MO',
    latitude: '38.200554',
    longitude: '-92.711201',
    timeZoneId: 'America/Chicago',
  },
  '65050': {
    zip: '65050',
    city: 'Latham',
    state: 'MO',
    latitude: '38.547307',
    longitude: '-92.64725',
    timeZoneId: 'America/Chicago',
  },
  '65051': {
    zip: '65051',
    city: 'Linn',
    state: 'MO',
    latitude: '38.478325',
    longitude: '-91.844999',
    timeZoneId: 'America/Chicago',
  },
  '65052': {
    zip: '65052',
    city: 'Linn Creek',
    state: 'MO',
    latitude: '38.040796',
    longitude: '-92.70604',
    timeZoneId: 'America/Chicago',
  },
  '65053': {
    zip: '65053',
    city: 'Lohman',
    state: 'MO',
    latitude: '38.541668',
    longitude: '-92.33486',
    timeZoneId: 'America/Chicago',
  },
  '65054': {
    zip: '65054',
    city: 'Loose Creek',
    state: 'MO',
    latitude: '38.462264',
    longitude: '-91.955107',
    timeZoneId: 'America/Chicago',
  },
  '65055': {
    zip: '65055',
    city: 'Mc Girk',
    state: 'MO',
    latitude: '38.627659',
    longitude: '-92.566101',
    timeZoneId: 'America/Chicago',
  },
  '65058': {
    zip: '65058',
    city: 'Meta',
    state: 'MO',
    latitude: '38.270435',
    longitude: '-92.133364',
    timeZoneId: 'America/Chicago',
  },
  '65059': {
    zip: '65059',
    city: 'Mokane',
    state: 'MO',
    latitude: '38.701839',
    longitude: '-91.883986',
    timeZoneId: 'America/Chicago',
  },
  '65061': {
    zip: '65061',
    city: 'Morrison',
    state: 'MO',
    latitude: '38.502518',
    longitude: '-91.534868',
    timeZoneId: 'America/Chicago',
  },
  '65062': {
    zip: '65062',
    city: 'Mount Sterling',
    state: 'MO',
    latitude: '38.531941',
    longitude: '-91.612913',
    timeZoneId: 'America/Chicago',
  },
  '65063': {
    zip: '65063',
    city: 'New Bloomfield',
    state: 'MO',
    latitude: '38.74806',
    longitude: '-92.08044',
    timeZoneId: 'America/Chicago',
  },
  '65064': {
    zip: '65064',
    city: 'Olean',
    state: 'MO',
    latitude: '38.401211',
    longitude: '-92.52525',
    timeZoneId: 'America/Chicago',
  },
  '65065': {
    zip: '65065',
    city: 'Osage Beach',
    state: 'MO',
    latitude: '38.136059',
    longitude: '-92.6705',
    timeZoneId: 'America/Chicago',
  },
  '65066': {
    zip: '65066',
    city: 'Owensville',
    state: 'MO',
    latitude: '38.348543',
    longitude: '-91.49903',
    timeZoneId: 'America/Chicago',
  },
  '65067': {
    zip: '65067',
    city: 'Portland',
    state: 'MO',
    latitude: '38.797987',
    longitude: '-91.700471',
    timeZoneId: 'America/Chicago',
  },
  '65068': {
    zip: '65068',
    city: 'Prairie Home',
    state: 'MO',
    latitude: '38.827855',
    longitude: '-92.60212',
    timeZoneId: 'America/Chicago',
  },
  '65069': {
    zip: '65069',
    city: 'Rhineland',
    state: 'MO',
    latitude: '38.757968',
    longitude: '-91.534285',
    timeZoneId: 'America/Chicago',
  },
  '65072': {
    zip: '65072',
    city: 'Rocky Mount',
    state: 'MO',
    latitude: '38.292715',
    longitude: '-92.713864',
    timeZoneId: 'America/Chicago',
  },
  '65074': {
    zip: '65074',
    city: 'Russellville',
    state: 'MO',
    latitude: '38.48514',
    longitude: '-92.40396',
    timeZoneId: 'America/Chicago',
  },
  '65075': {
    zip: '65075',
    city: 'Saint Elizabeth',
    state: 'MO',
    latitude: '38.286194',
    longitude: '-92.274802',
    timeZoneId: 'America/Chicago',
  },
  '65076': {
    zip: '65076',
    city: 'Saint Thomas',
    state: 'MO',
    latitude: '38.507852',
    longitude: '-92.441678',
    timeZoneId: 'America/Chicago',
  },
  '65077': {
    zip: '65077',
    city: 'Steedman',
    state: 'MO',
    latitude: '38.757061',
    longitude: '-91.782634',
    timeZoneId: 'America/Chicago',
  },
  '65078': {
    zip: '65078',
    city: 'Stover',
    state: 'MO',
    latitude: '38.444823',
    longitude: '-92.992709',
    timeZoneId: 'America/Chicago',
  },
  '65079': {
    zip: '65079',
    city: 'Sunrise Beach',
    state: 'MO',
    latitude: '38.167464',
    longitude: '-92.692727',
    timeZoneId: 'America/Chicago',
  },
  '65080': {
    zip: '65080',
    city: 'Tebbetts',
    state: 'MO',
    latitude: '38.646909',
    longitude: '-91.957996',
    timeZoneId: 'America/Chicago',
  },
  '65081': {
    zip: '65081',
    city: 'Tipton',
    state: 'MO',
    latitude: '38.654674',
    longitude: '-92.779444',
    timeZoneId: 'America/Chicago',
  },
  '65082': {
    zip: '65082',
    city: 'Tuscumbia',
    state: 'MO',
    latitude: '38.23811',
    longitude: '-92.482845',
    timeZoneId: 'America/Chicago',
  },
  '65083': {
    zip: '65083',
    city: 'Ulman',
    state: 'MO',
    latitude: '38.130959',
    longitude: '-92.463161',
    timeZoneId: 'America/Chicago',
  },
  '65084': {
    zip: '65084',
    city: 'Versailles',
    state: 'MO',
    latitude: '38.432613',
    longitude: '-92.84418',
    timeZoneId: 'America/Chicago',
  },
  '65085': {
    zip: '65085',
    city: 'Westphalia',
    state: 'MO',
    latitude: '38.42826',
    longitude: '-92.02314',
    timeZoneId: 'America/Chicago',
  },
  '65101': {
    zip: '65101',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.50164',
    longitude: '-92.15556',
    timeZoneId: 'America/Chicago',
  },
  '65102': {
    zip: '65102',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.579476',
    longitude: '-92.170918',
    timeZoneId: 'America/Chicago',
  },
  '65103': {
    zip: '65103',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.578727',
    longitude: '-92.174092',
    timeZoneId: 'America/Chicago',
  },
  '65104': {
    zip: '65104',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.575066',
    longitude: '-92.173635',
    timeZoneId: 'America/Chicago',
  },
  '65105': {
    zip: '65105',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.577411',
    longitude: '-92.174184',
    timeZoneId: 'America/Chicago',
  },
  '65106': {
    zip: '65106',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.578011',
    longitude: '-92.174363',
    timeZoneId: 'America/Chicago',
  },
  '65107': {
    zip: '65107',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.578678',
    longitude: '-92.173699',
    timeZoneId: 'America/Chicago',
  },
  '65108': {
    zip: '65108',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.573978',
    longitude: '-92.171679',
    timeZoneId: 'America/Chicago',
  },
  '65109': {
    zip: '65109',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.5554',
    longitude: '-92.28054',
    timeZoneId: 'America/Chicago',
  },
  '65110': {
    zip: '65110',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.576911',
    longitude: '-92.175487',
    timeZoneId: 'America/Chicago',
  },
  '65111': {
    zip: '65111',
    city: 'Jefferson City',
    state: 'MO',
    latitude: '38.551103',
    longitude: '-92.118815',
    timeZoneId: 'America/Chicago',
  },
  '65201': {
    zip: '65201',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.89644',
    longitude: '-92.24628',
    timeZoneId: 'America/Chicago',
  },
  '65202': {
    zip: '65202',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.995035',
    longitude: '-92.315503',
    timeZoneId: 'America/Chicago',
  },
  '65203': {
    zip: '65203',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.929931',
    longitude: '-92.372948',
    timeZoneId: 'America/Chicago',
  },
  '65205': {
    zip: '65205',
    city: 'Columbia',
    state: 'MO',
    latitude: '39.049268',
    longitude: '-92.240473',
    timeZoneId: 'America/Chicago',
  },
  '65211': {
    zip: '65211',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.948231',
    longitude: '-92.327335',
    timeZoneId: 'America/Chicago',
  },
  '65212': {
    zip: '65212',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.909422',
    longitude: '-92.247037',
    timeZoneId: 'America/Chicago',
  },
  '65215': {
    zip: '65215',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.953037',
    longitude: '-92.325011',
    timeZoneId: 'America/Chicago',
  },
  '65216': {
    zip: '65216',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.96105',
    longitude: '-92.325791',
    timeZoneId: 'America/Chicago',
  },
  '65217': {
    zip: '65217',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.897983',
    longitude: '-92.33364',
    timeZoneId: 'America/Chicago',
  },
  '65218': {
    zip: '65218',
    city: 'Columbia',
    state: 'MO',
    latitude: '38.952596',
    longitude: '-92.366496',
    timeZoneId: 'America/Chicago',
  },
  '65230': {
    zip: '65230',
    city: 'Armstrong',
    state: 'MO',
    latitude: '39.269004',
    longitude: '-92.703942',
    timeZoneId: 'America/Chicago',
  },
  '65231': {
    zip: '65231',
    city: 'Auxvasse',
    state: 'MO',
    latitude: '39.015325',
    longitude: '-91.894565',
    timeZoneId: 'America/Chicago',
  },
  '65232': {
    zip: '65232',
    city: 'Benton City',
    state: 'MO',
    latitude: '39.105465',
    longitude: '-91.77435',
    timeZoneId: 'America/Chicago',
  },
  '65233': {
    zip: '65233',
    city: 'Boonville',
    state: 'MO',
    latitude: '38.964646',
    longitude: '-92.743139',
    timeZoneId: 'America/Chicago',
  },
  '65236': {
    zip: '65236',
    city: 'Brunswick',
    state: 'MO',
    latitude: '39.4308',
    longitude: '-93.1239',
    timeZoneId: 'America/Chicago',
  },
  '65237': {
    zip: '65237',
    city: 'Bunceton',
    state: 'MO',
    latitude: '38.787039',
    longitude: '-92.799726',
    timeZoneId: 'America/Chicago',
  },
  '65239': {
    zip: '65239',
    city: 'Cairo',
    state: 'MO',
    latitude: '39.520714',
    longitude: '-92.420763',
    timeZoneId: 'America/Chicago',
  },
  '65240': {
    zip: '65240',
    city: 'Centralia',
    state: 'MO',
    latitude: '39.199769',
    longitude: '-92.146039',
    timeZoneId: 'America/Chicago',
  },
  '65243': {
    zip: '65243',
    city: 'Clark',
    state: 'MO',
    latitude: '39.211871',
    longitude: '-92.388655',
    timeZoneId: 'America/Chicago',
  },
  '65244': {
    zip: '65244',
    city: 'Clifton Hill',
    state: 'MO',
    latitude: '39.42593',
    longitude: '-92.66522',
    timeZoneId: 'America/Chicago',
  },
  '65246': {
    zip: '65246',
    city: 'Dalton',
    state: 'MO',
    latitude: '39.39058',
    longitude: '-92.993274',
    timeZoneId: 'America/Chicago',
  },
  '65247': {
    zip: '65247',
    city: 'Excello',
    state: 'MO',
    latitude: '39.65111',
    longitude: '-92.451199',
    timeZoneId: 'America/Chicago',
  },
  '65248': {
    zip: '65248',
    city: 'Fayette',
    state: 'MO',
    latitude: '39.146465',
    longitude: '-92.685942',
    timeZoneId: 'America/Chicago',
  },
  '65250': {
    zip: '65250',
    city: 'Franklin',
    state: 'MO',
    latitude: '39.06231',
    longitude: '-92.839917',
    timeZoneId: 'America/Chicago',
  },
  '65251': {
    zip: '65251',
    city: 'Fulton',
    state: 'MO',
    latitude: '38.852815',
    longitude: '-91.946319',
    timeZoneId: 'America/Chicago',
  },
  '65254': {
    zip: '65254',
    city: 'Glasgow',
    state: 'MO',
    latitude: '39.228178',
    longitude: '-92.839068',
    timeZoneId: 'America/Chicago',
  },
  '65255': {
    zip: '65255',
    city: 'Hallsville',
    state: 'MO',
    latitude: '39.104694',
    longitude: '-92.22306',
    timeZoneId: 'America/Chicago',
  },
  '65256': {
    zip: '65256',
    city: 'Harrisburg',
    state: 'MO',
    latitude: '39.126631',
    longitude: '-92.438081',
    timeZoneId: 'America/Chicago',
  },
  '65257': {
    zip: '65257',
    city: 'Higbee',
    state: 'MO',
    latitude: '39.307992',
    longitude: '-92.520101',
    timeZoneId: 'America/Chicago',
  },
  '65258': {
    zip: '65258',
    city: 'Holliday',
    state: 'MO',
    latitude: '39.483806',
    longitude: '-92.138585',
    timeZoneId: 'America/Chicago',
  },
  '65259': {
    zip: '65259',
    city: 'Huntsville',
    state: 'MO',
    latitude: '39.435709',
    longitude: '-92.545665',
    timeZoneId: 'America/Chicago',
  },
  '65260': {
    zip: '65260',
    city: 'Jacksonville',
    state: 'MO',
    latitude: '39.579424',
    longitude: '-92.419085',
    timeZoneId: 'America/Chicago',
  },
  '65261': {
    zip: '65261',
    city: 'Keytesville',
    state: 'MO',
    latitude: '39.432638',
    longitude: '-92.935494',
    timeZoneId: 'America/Chicago',
  },
  '65262': {
    zip: '65262',
    city: 'Kingdom City',
    state: 'MO',
    latitude: '38.957522',
    longitude: '-91.962837',
    timeZoneId: 'America/Chicago',
  },
  '65263': {
    zip: '65263',
    city: 'Madison',
    state: 'MO',
    latitude: '39.47329',
    longitude: '-92.21439',
    timeZoneId: 'America/Chicago',
  },
  '65264': {
    zip: '65264',
    city: 'Martinsburg',
    state: 'MO',
    latitude: '39.099199',
    longitude: '-91.646881',
    timeZoneId: 'America/Chicago',
  },
  '65265': {
    zip: '65265',
    city: 'Mexico',
    state: 'MO',
    latitude: '39.16734',
    longitude: '-91.87044',
    timeZoneId: 'America/Chicago',
  },
  '65270': {
    zip: '65270',
    city: 'Moberly',
    state: 'MO',
    latitude: '39.418214',
    longitude: '-92.439228',
    timeZoneId: 'America/Chicago',
  },
  '65274': {
    zip: '65274',
    city: 'New Franklin',
    state: 'MO',
    latitude: '39.014736',
    longitude: '-92.740471',
    timeZoneId: 'America/Chicago',
  },
  '65275': {
    zip: '65275',
    city: 'Paris',
    state: 'MO',
    latitude: '39.481474',
    longitude: '-92.009398',
    timeZoneId: 'America/Chicago',
  },
  '65276': {
    zip: '65276',
    city: 'Pilot Grove',
    state: 'MO',
    latitude: '38.870589',
    longitude: '-92.907783',
    timeZoneId: 'America/Chicago',
  },
  '65278': {
    zip: '65278',
    city: 'Renick',
    state: 'MO',
    latitude: '39.291553',
    longitude: '-92.346488',
    timeZoneId: 'America/Chicago',
  },
  '65279': {
    zip: '65279',
    city: 'Rocheport',
    state: 'MO',
    latitude: '39.000717',
    longitude: '-92.518405',
    timeZoneId: 'America/Chicago',
  },
  '65280': {
    zip: '65280',
    city: 'Rush Hill',
    state: 'MO',
    latitude: '39.192334',
    longitude: '-91.678711',
    timeZoneId: 'America/Chicago',
  },
  '65281': {
    zip: '65281',
    city: 'Salisbury',
    state: 'MO',
    latitude: '39.420951',
    longitude: '-92.803243',
    timeZoneId: 'America/Chicago',
  },
  '65282': {
    zip: '65282',
    city: 'Santa Fe',
    state: 'MO',
    latitude: '39.403821',
    longitude: '-91.824295',
    timeZoneId: 'America/Chicago',
  },
  '65283': {
    zip: '65283',
    city: 'Stoutsville',
    state: 'MO',
    latitude: '39.546823',
    longitude: '-91.835464',
    timeZoneId: 'America/Chicago',
  },
  '65284': {
    zip: '65284',
    city: 'Sturgeon',
    state: 'MO',
    latitude: '39.204824',
    longitude: '-92.308046',
    timeZoneId: 'America/Chicago',
  },
  '65285': {
    zip: '65285',
    city: 'Thompson',
    state: 'MO',
    latitude: '39.201379',
    longitude: '-92.039616',
    timeZoneId: 'America/Chicago',
  },
  '65286': {
    zip: '65286',
    city: 'Triplett',
    state: 'MO',
    latitude: '39.510264',
    longitude: '-93.212915',
    timeZoneId: 'America/Chicago',
  },
  '65287': {
    zip: '65287',
    city: 'Wooldridge',
    state: 'MO',
    latitude: '38.919004',
    longitude: '-92.582291',
    timeZoneId: 'America/Chicago',
  },
  '65299': {
    zip: '65299',
    city: 'Columbia',
    state: 'MO',
    latitude: '39.023561',
    longitude: '-92.248218',
    timeZoneId: 'America/Chicago',
  },
  '65301': {
    zip: '65301',
    city: 'Sedalia',
    state: 'MO',
    latitude: '38.69508',
    longitude: '-93.24216',
    timeZoneId: 'America/Chicago',
  },
  '65302': {
    zip: '65302',
    city: 'Sedalia',
    state: 'MO',
    latitude: '38.708185',
    longitude: '-93.222337',
    timeZoneId: 'America/Chicago',
  },
  '65305': {
    zip: '65305',
    city: 'Whiteman Air Force Base',
    state: 'MO',
    latitude: '38.728246',
    longitude: '-93.565357',
    timeZoneId: 'America/Chicago',
  },
  '65320': {
    zip: '65320',
    city: 'Arrow Rock',
    state: 'MO',
    latitude: '39.005498',
    longitude: '-93.045171',
    timeZoneId: 'America/Chicago',
  },
  '65321': {
    zip: '65321',
    city: 'Blackburn',
    state: 'MO',
    latitude: '39.104512',
    longitude: '-93.486773',
    timeZoneId: 'America/Chicago',
  },
  '65322': {
    zip: '65322',
    city: 'Blackwater',
    state: 'MO',
    latitude: '38.979071',
    longitude: '-92.993099',
    timeZoneId: 'America/Chicago',
  },
  '65323': {
    zip: '65323',
    city: 'Calhoun',
    state: 'MO',
    latitude: '38.48274',
    longitude: '-93.64188',
    timeZoneId: 'America/Chicago',
  },
  '65324': {
    zip: '65324',
    city: 'Climax Springs',
    state: 'MO',
    latitude: '38.125197',
    longitude: '-92.961855',
    timeZoneId: 'America/Chicago',
  },
  '65325': {
    zip: '65325',
    city: 'Cole Camp',
    state: 'MO',
    latitude: '38.449974',
    longitude: '-93.217748',
    timeZoneId: 'America/Chicago',
  },
  '65326': {
    zip: '65326',
    city: 'Edwards',
    state: 'MO',
    latitude: '38.16761',
    longitude: '-93.149233',
    timeZoneId: 'America/Chicago',
  },
  '65327': {
    zip: '65327',
    city: 'Emma',
    state: 'MO',
    latitude: '38.973396',
    longitude: '-93.491435',
    timeZoneId: 'America/Chicago',
  },
  '65329': {
    zip: '65329',
    city: 'Florence',
    state: 'MO',
    latitude: '38.61014',
    longitude: '-92.998452',
    timeZoneId: 'America/Chicago',
  },
  '65330': {
    zip: '65330',
    city: 'Gilliam',
    state: 'MO',
    latitude: '39.231979',
    longitude: '-93.005248',
    timeZoneId: 'America/Chicago',
  },
  '65332': {
    zip: '65332',
    city: 'Green Ridge',
    state: 'MO',
    latitude: '38.615745',
    longitude: '-93.452496',
    timeZoneId: 'America/Chicago',
  },
  '65333': {
    zip: '65333',
    city: 'Houstonia',
    state: 'MO',
    latitude: '38.901082',
    longitude: '-93.360319',
    timeZoneId: 'America/Chicago',
  },
  '65334': {
    zip: '65334',
    city: 'Hughesville',
    state: 'MO',
    latitude: '38.855775',
    longitude: '-93.208469',
    timeZoneId: 'America/Chicago',
  },
  '65335': {
    zip: '65335',
    city: 'Ionia',
    state: 'MO',
    latitude: '38.485951',
    longitude: '-93.31657',
    timeZoneId: 'America/Chicago',
  },
  '65336': {
    zip: '65336',
    city: 'Knob Noster',
    state: 'MO',
    latitude: '38.764378',
    longitude: '-93.556549',
    timeZoneId: 'America/Chicago',
  },
  '65337': {
    zip: '65337',
    city: 'La Monte',
    state: 'MO',
    latitude: '38.772698',
    longitude: '-93.426159',
    timeZoneId: 'America/Chicago',
  },
  '65338': {
    zip: '65338',
    city: 'Lincoln',
    state: 'MO',
    latitude: '38.436452',
    longitude: '-93.404512',
    timeZoneId: 'America/Chicago',
  },
  '65339': {
    zip: '65339',
    city: 'Malta Bend',
    state: 'MO',
    latitude: '39.19728',
    longitude: '-93.38028',
    timeZoneId: 'America/Chicago',
  },
  '65340': {
    zip: '65340',
    city: 'Marshall',
    state: 'MO',
    latitude: '39.114311',
    longitude: '-93.196522',
    timeZoneId: 'America/Chicago',
  },
  '65344': {
    zip: '65344',
    city: 'Miami',
    state: 'MO',
    latitude: '39.321331',
    longitude: '-93.226168',
    timeZoneId: 'America/Chicago',
  },
  '65345': {
    zip: '65345',
    city: 'Mora',
    state: 'MO',
    latitude: '38.531213',
    longitude: '-93.218254',
    timeZoneId: 'America/Chicago',
  },
  '65347': {
    zip: '65347',
    city: 'Nelson',
    state: 'MO',
    latitude: '38.996587',
    longitude: '-93.031634',
    timeZoneId: 'America/Chicago',
  },
  '65348': {
    zip: '65348',
    city: 'Otterville',
    state: 'MO',
    latitude: '38.704032',
    longitude: '-93.003128',
    timeZoneId: 'America/Chicago',
  },
  '65349': {
    zip: '65349',
    city: 'Slater',
    state: 'MO',
    latitude: '39.214173',
    longitude: '-93.069492',
    timeZoneId: 'America/Chicago',
  },
  '65350': {
    zip: '65350',
    city: 'Smithton',
    state: 'MO',
    latitude: '38.680224',
    longitude: '-93.092795',
    timeZoneId: 'America/Chicago',
  },
  '65351': {
    zip: '65351',
    city: 'Sweet Springs',
    state: 'MO',
    latitude: '38.964117',
    longitude: '-93.418774',
    timeZoneId: 'America/Chicago',
  },
  '65354': {
    zip: '65354',
    city: 'Syracuse',
    state: 'MO',
    latitude: '38.613552',
    longitude: '-92.889351',
    timeZoneId: 'America/Chicago',
  },
  '65355': {
    zip: '65355',
    city: 'Warsaw',
    state: 'MO',
    latitude: '38.24832',
    longitude: '-93.3588',
    timeZoneId: 'America/Chicago',
  },
  '65360': {
    zip: '65360',
    city: 'Windsor',
    state: 'MO',
    latitude: '38.529467',
    longitude: '-93.525003',
    timeZoneId: 'America/Chicago',
  },
  '65401': {
    zip: '65401',
    city: 'Rolla',
    state: 'MO',
    latitude: '37.950864',
    longitude: '-91.763227',
    timeZoneId: 'America/Chicago',
  },
  '65402': {
    zip: '65402',
    city: 'Rolla',
    state: 'MO',
    latitude: '37.941764',
    longitude: '-91.77215',
    timeZoneId: 'America/Chicago',
  },
  '65409': {
    zip: '65409',
    city: 'Rolla',
    state: 'MO',
    latitude: '37.956712',
    longitude: '-91.771492',
    timeZoneId: 'America/Chicago',
  },
  '65436': {
    zip: '65436',
    city: 'Beulah',
    state: 'MO',
    latitude: '37.647062',
    longitude: '-91.87738',
    timeZoneId: 'America/Chicago',
  },
  '65438': {
    zip: '65438',
    city: 'Birch Tree',
    state: 'MO',
    latitude: '36.912332',
    longitude: '-91.493803',
    timeZoneId: 'America/Chicago',
  },
  '65439': {
    zip: '65439',
    city: 'Bixby',
    state: 'MO',
    latitude: '37.665087',
    longitude: '-91.069445',
    timeZoneId: 'America/Chicago',
  },
  '65440': {
    zip: '65440',
    city: 'Boss',
    state: 'MO',
    latitude: '37.64286',
    longitude: '-91.18984',
    timeZoneId: 'America/Chicago',
  },
  '65441': {
    zip: '65441',
    city: 'Bourbon',
    state: 'MO',
    latitude: '38.151641',
    longitude: '-91.252655',
    timeZoneId: 'America/Chicago',
  },
  '65443': {
    zip: '65443',
    city: 'Brinktown',
    state: 'MO',
    latitude: '38.09035',
    longitude: '-92.117392',
    timeZoneId: 'America/Chicago',
  },
  '65444': {
    zip: '65444',
    city: 'Bucyrus',
    state: 'MO',
    latitude: '37.399403',
    longitude: '-92.038861',
    timeZoneId: 'America/Chicago',
  },
  '65446': {
    zip: '65446',
    city: 'Cherryville',
    state: 'MO',
    latitude: '37.783556',
    longitude: '-91.235854',
    timeZoneId: 'America/Chicago',
  },
  '65449': {
    zip: '65449',
    city: 'Cook Sta',
    state: 'MO',
    latitude: '37.847287',
    longitude: '-91.464367',
    timeZoneId: 'America/Chicago',
  },
  '65452': {
    zip: '65452',
    city: 'Crocker',
    state: 'MO',
    latitude: '37.955148',
    longitude: '-92.29313',
    timeZoneId: 'America/Chicago',
  },
  '65453': {
    zip: '65453',
    city: 'Cuba',
    state: 'MO',
    latitude: '38.068626',
    longitude: '-91.414238',
    timeZoneId: 'America/Chicago',
  },
  '65456': {
    zip: '65456',
    city: 'Davisville',
    state: 'MO',
    latitude: '37.803075',
    longitude: '-91.14002',
    timeZoneId: 'America/Chicago',
  },
  '65457': {
    zip: '65457',
    city: 'Devils Elbow',
    state: 'MO',
    latitude: '37.823341',
    longitude: '-92.065045',
    timeZoneId: 'America/Chicago',
  },
  '65459': {
    zip: '65459',
    city: 'Dixon',
    state: 'MO',
    latitude: '37.999415',
    longitude: '-92.102681',
    timeZoneId: 'America/Chicago',
  },
  '65461': {
    zip: '65461',
    city: 'Duke',
    state: 'MO',
    latitude: '37.655315',
    longitude: '-91.986358',
    timeZoneId: 'America/Chicago',
  },
  '65462': {
    zip: '65462',
    city: 'Edgar Springs',
    state: 'MO',
    latitude: '37.75021',
    longitude: '-91.912584',
    timeZoneId: 'America/Chicago',
  },
  '65463': {
    zip: '65463',
    city: 'Eldridge',
    state: 'MO',
    latitude: '37.82114',
    longitude: '-92.773551',
    timeZoneId: 'America/Chicago',
  },
  '65464': {
    zip: '65464',
    city: 'Elk Creek',
    state: 'MO',
    latitude: '37.215181',
    longitude: '-91.994666',
    timeZoneId: 'America/Chicago',
  },
  '65466': {
    zip: '65466',
    city: 'Eminence',
    state: 'MO',
    latitude: '37.1697',
    longitude: '-91.47108',
    timeZoneId: 'America/Chicago',
  },
  '65468': {
    zip: '65468',
    city: 'Eunice',
    state: 'MO',
    latitude: '37.214118',
    longitude: '-91.729342',
    timeZoneId: 'America/Chicago',
  },
  '65470': {
    zip: '65470',
    city: 'Falcon',
    state: 'MO',
    latitude: '37.61142',
    longitude: '-92.3505',
    timeZoneId: 'America/Chicago',
  },
  '65473': {
    zip: '65473',
    city: 'Fort Leonard Wood',
    state: 'MO',
    latitude: '37.826765',
    longitude: '-92.218586',
    timeZoneId: 'America/Chicago',
  },
  '65479': {
    zip: '65479',
    city: 'Hartshorn',
    state: 'MO',
    latitude: '37.293088',
    longitude: '-91.699999',
    timeZoneId: 'America/Chicago',
  },
  '65483': {
    zip: '65483',
    city: 'Houston',
    state: 'MO',
    latitude: '37.323082',
    longitude: '-91.964295',
    timeZoneId: 'America/Chicago',
  },
  '65484': {
    zip: '65484',
    city: 'Huggins',
    state: 'MO',
    latitude: '37.27154',
    longitude: '-92.151285',
    timeZoneId: 'America/Chicago',
  },
  '65486': {
    zip: '65486',
    city: 'Iberia',
    state: 'MO',
    latitude: '38.094073',
    longitude: '-92.298448',
    timeZoneId: 'America/Chicago',
  },
  '65501': {
    zip: '65501',
    city: 'Jadwin',
    state: 'MO',
    latitude: '37.46409',
    longitude: '-91.508089',
    timeZoneId: 'America/Chicago',
  },
  '65529': {
    zip: '65529',
    city: 'Jerome',
    state: 'MO',
    latitude: '37.915035',
    longitude: '-91.991499',
    timeZoneId: 'America/Chicago',
  },
  '65532': {
    zip: '65532',
    city: 'Lake Spring',
    state: 'MO',
    latitude: '37.781951',
    longitude: '-91.679376',
    timeZoneId: 'America/Chicago',
  },
  '65534': {
    zip: '65534',
    city: 'Laquey',
    state: 'MO',
    latitude: '37.72589',
    longitude: '-92.340893',
    timeZoneId: 'America/Chicago',
  },
  '65535': {
    zip: '65535',
    city: 'Leasburg',
    state: 'MO',
    latitude: '38.06286',
    longitude: '-91.197995',
    timeZoneId: 'America/Chicago',
  },
  '65536': {
    zip: '65536',
    city: 'Lebanon',
    state: 'MO',
    latitude: '37.678149',
    longitude: '-92.6648',
    timeZoneId: 'America/Chicago',
  },
  '65541': {
    zip: '65541',
    city: 'Lenox',
    state: 'MO',
    latitude: '37.6458',
    longitude: '-91.735893',
    timeZoneId: 'America/Chicago',
  },
  '65542': {
    zip: '65542',
    city: 'Licking',
    state: 'MO',
    latitude: '37.497845',
    longitude: '-91.860699',
    timeZoneId: 'America/Chicago',
  },
  '65543': {
    zip: '65543',
    city: 'Lynchburg',
    state: 'MO',
    latitude: '37.494383',
    longitude: '-92.308408',
    timeZoneId: 'America/Chicago',
  },
  '65546': {
    zip: '65546',
    city: 'Montier',
    state: 'MO',
    latitude: '36.987875',
    longitude: '-91.575003',
    timeZoneId: 'America/Chicago',
  },
  '65548': {
    zip: '65548',
    city: 'Mountain View',
    state: 'MO',
    latitude: '36.995415',
    longitude: '-91.718827',
    timeZoneId: 'America/Chicago',
  },
  '65550': {
    zip: '65550',
    city: 'Newburg',
    state: 'MO',
    latitude: '37.92018',
    longitude: '-91.8861',
    timeZoneId: 'America/Chicago',
  },
  '65552': {
    zip: '65552',
    city: 'Plato',
    state: 'MO',
    latitude: '37.494347',
    longitude: '-92.183812',
    timeZoneId: 'America/Chicago',
  },
  '65555': {
    zip: '65555',
    city: 'Raymondville',
    state: 'MO',
    latitude: '37.362406',
    longitude: '-91.771692',
    timeZoneId: 'America/Chicago',
  },
  '65556': {
    zip: '65556',
    city: 'Richland',
    state: 'MO',
    latitude: '37.857338',
    longitude: '-92.400215',
    timeZoneId: 'America/Chicago',
  },
  '65557': {
    zip: '65557',
    city: 'Roby',
    state: 'MO',
    latitude: '37.496161',
    longitude: '-92.114035',
    timeZoneId: 'America/Chicago',
  },
  '65559': {
    zip: '65559',
    city: 'Saint James',
    state: 'MO',
    latitude: '37.998771',
    longitude: '-91.613832',
    timeZoneId: 'America/Chicago',
  },
  '65560': {
    zip: '65560',
    city: 'Salem',
    state: 'MO',
    latitude: '37.60836',
    longitude: '-91.5243',
    timeZoneId: 'America/Chicago',
  },
  '65564': {
    zip: '65564',
    city: 'Solo',
    state: 'MO',
    latitude: '37.164907',
    longitude: '-91.946563',
    timeZoneId: 'America/Chicago',
  },
  '65565': {
    zip: '65565',
    city: 'Steelville',
    state: 'MO',
    latitude: '37.968536',
    longitude: '-91.358',
    timeZoneId: 'America/Chicago',
  },
  '65566': {
    zip: '65566',
    city: 'Viburnum',
    state: 'MO',
    latitude: '37.71792',
    longitude: '-91.1292',
    timeZoneId: 'America/Chicago',
  },
  '65567': {
    zip: '65567',
    city: 'Stoutland',
    state: 'MO',
    latitude: '37.849307',
    longitude: '-92.439987',
    timeZoneId: 'America/Chicago',
  },
  '65570': {
    zip: '65570',
    city: 'Success',
    state: 'MO',
    latitude: '37.484447',
    longitude: '-92.009869',
    timeZoneId: 'America/Chicago',
  },
  '65571': {
    zip: '65571',
    city: 'Summersville',
    state: 'MO',
    latitude: '37.130857',
    longitude: '-91.705703',
    timeZoneId: 'America/Chicago',
  },
  '65580': {
    zip: '65580',
    city: 'Vichy',
    state: 'MO',
    latitude: '38.103854',
    longitude: '-91.797318',
    timeZoneId: 'America/Chicago',
  },
  '65582': {
    zip: '65582',
    city: 'Vienna',
    state: 'MO',
    latitude: '38.199868',
    longitude: '-91.933432',
    timeZoneId: 'America/Chicago',
  },
  '65583': {
    zip: '65583',
    city: 'Waynesville',
    state: 'MO',
    latitude: '37.82106',
    longitude: '-92.18682',
    timeZoneId: 'America/Chicago',
  },
  '65584': {
    zip: '65584',
    city: 'Saint Robert',
    state: 'MO',
    latitude: '37.828919',
    longitude: '-92.200348',
    timeZoneId: 'America/Chicago',
  },
  '65586': {
    zip: '65586',
    city: 'Wesco',
    state: 'MO',
    latitude: '37.859098',
    longitude: '-91.356689',
    timeZoneId: 'America/Chicago',
  },
  '65588': {
    zip: '65588',
    city: 'Winona',
    state: 'MO',
    latitude: '37.056692',
    longitude: '-91.230196',
    timeZoneId: 'America/Chicago',
  },
  '65589': {
    zip: '65589',
    city: 'Yukon',
    state: 'MO',
    latitude: '37.209732',
    longitude: '-91.814544',
    timeZoneId: 'America/Chicago',
  },
  '65590': {
    zip: '65590',
    city: 'Long Lane',
    state: 'MO',
    latitude: '37.573975',
    longitude: '-92.932919',
    timeZoneId: 'America/Chicago',
  },
  '65591': {
    zip: '65591',
    city: 'Montreal',
    state: 'MO',
    latitude: '37.989338',
    longitude: '-92.519461',
    timeZoneId: 'America/Chicago',
  },
  '65601': {
    zip: '65601',
    city: 'Aldrich',
    state: 'MO',
    latitude: '37.506631',
    longitude: '-93.562372',
    timeZoneId: 'America/Chicago',
  },
  '65603': {
    zip: '65603',
    city: 'Arcola',
    state: 'MO',
    latitude: '37.533701',
    longitude: '-93.862321',
    timeZoneId: 'America/Chicago',
  },
  '65604': {
    zip: '65604',
    city: 'Ash Grove',
    state: 'MO',
    latitude: '37.31562',
    longitude: '-93.580809',
    timeZoneId: 'America/Chicago',
  },
  '65605': {
    zip: '65605',
    city: 'Aurora',
    state: 'MO',
    latitude: '36.9561',
    longitude: '-93.72054',
    timeZoneId: 'America/Chicago',
  },
  '65606': {
    zip: '65606',
    city: 'Alton',
    state: 'MO',
    latitude: '36.7086',
    longitude: '-91.3674',
    timeZoneId: 'America/Chicago',
  },
  '65607': {
    zip: '65607',
    city: 'Caplinger Mills',
    state: 'MO',
    latitude: '37.794964',
    longitude: '-93.804523',
    timeZoneId: 'America/Chicago',
  },
  '65608': {
    zip: '65608',
    city: 'Ava',
    state: 'MO',
    latitude: '36.960651',
    longitude: '-92.661201',
    timeZoneId: 'America/Chicago',
  },
  '65609': {
    zip: '65609',
    city: 'Bakersfield',
    state: 'MO',
    latitude: '36.537074',
    longitude: '-92.157175',
    timeZoneId: 'America/Chicago',
  },
  '65610': {
    zip: '65610',
    city: 'Billings',
    state: 'MO',
    latitude: '37.063707',
    longitude: '-93.556821',
    timeZoneId: 'America/Chicago',
  },
  '65611': {
    zip: '65611',
    city: 'Blue Eye',
    state: 'MO',
    latitude: '36.517044',
    longitude: '-93.449482',
    timeZoneId: 'America/Chicago',
  },
  '65612': {
    zip: '65612',
    city: 'Bois D Arc',
    state: 'MO',
    latitude: '37.221093',
    longitude: '-93.560283',
    timeZoneId: 'America/Chicago',
  },
  '65613': {
    zip: '65613',
    city: 'Bolivar',
    state: 'MO',
    latitude: '37.613564',
    longitude: '-93.414989',
    timeZoneId: 'America/Chicago',
  },
  '65614': {
    zip: '65614',
    city: 'Bradleyville',
    state: 'MO',
    latitude: '36.727158',
    longitude: '-92.87807',
    timeZoneId: 'America/Chicago',
  },
  '65615': {
    zip: '65615',
    city: 'Branson',
    state: 'MO',
    latitude: '36.642143',
    longitude: '-93.216875',
    timeZoneId: 'America/Chicago',
  },
  '65616': {
    zip: '65616',
    city: 'Branson',
    state: 'MO',
    latitude: '36.643271',
    longitude: '-93.232983',
    timeZoneId: 'America/Chicago',
  },
  '65617': {
    zip: '65617',
    city: 'Brighton',
    state: 'MO',
    latitude: '37.480535',
    longitude: '-93.362368',
    timeZoneId: 'America/Chicago',
  },
  '65618': {
    zip: '65618',
    city: 'Brixey',
    state: 'MO',
    latitude: '36.753995',
    longitude: '-92.392466',
    timeZoneId: 'America/Chicago',
  },
  '65619': {
    zip: '65619',
    city: 'Brookline',
    state: 'MO',
    latitude: '37.12914',
    longitude: '-93.3867',
    timeZoneId: 'America/Chicago',
  },
  '65620': {
    zip: '65620',
    city: 'Bruner',
    state: 'MO',
    latitude: '36.98039',
    longitude: '-92.953868',
    timeZoneId: 'America/Chicago',
  },
  '65622': {
    zip: '65622',
    city: 'Buffalo',
    state: 'MO',
    latitude: '37.646688',
    longitude: '-93.094711',
    timeZoneId: 'America/Chicago',
  },
  '65623': {
    zip: '65623',
    city: 'Butterfield',
    state: 'MO',
    latitude: '36.745097',
    longitude: '-93.91404',
    timeZoneId: 'America/Chicago',
  },
  '65624': {
    zip: '65624',
    city: 'Cape Fair',
    state: 'MO',
    latitude: '36.675135',
    longitude: '-93.528191',
    timeZoneId: 'America/Chicago',
  },
  '65625': {
    zip: '65625',
    city: 'Cassville',
    state: 'MO',
    latitude: '36.679611',
    longitude: '-93.872639',
    timeZoneId: 'America/Chicago',
  },
  '65626': {
    zip: '65626',
    city: 'Caulfield',
    state: 'MO',
    latitude: '36.59754',
    longitude: '-92.075058',
    timeZoneId: 'America/Chicago',
  },
  '65627': {
    zip: '65627',
    city: 'Cedarcreek',
    state: 'MO',
    latitude: '36.560928',
    longitude: '-93.01753',
    timeZoneId: 'America/Chicago',
  },
  '65629': {
    zip: '65629',
    city: 'Chadwick',
    state: 'MO',
    latitude: '36.9196',
    longitude: '-93.039979',
    timeZoneId: 'America/Chicago',
  },
  '65630': {
    zip: '65630',
    city: 'Chestnutridge',
    state: 'MO',
    latitude: '36.859987',
    longitude: '-93.124983',
    timeZoneId: 'America/Chicago',
  },
  '65631': {
    zip: '65631',
    city: 'Clever',
    state: 'MO',
    latitude: '37.022884',
    longitude: '-93.47212',
    timeZoneId: 'America/Chicago',
  },
  '65632': {
    zip: '65632',
    city: 'Conway',
    state: 'MO',
    latitude: '37.518099',
    longitude: '-92.751594',
    timeZoneId: 'America/Chicago',
  },
  '65633': {
    zip: '65633',
    city: 'Crane',
    state: 'MO',
    latitude: '36.903431',
    longitude: '-93.566967',
    timeZoneId: 'America/Chicago',
  },
  '65634': {
    zip: '65634',
    city: 'Cross Timbers',
    state: 'MO',
    latitude: '38.020364',
    longitude: '-93.203182',
    timeZoneId: 'America/Chicago',
  },
  '65635': {
    zip: '65635',
    city: 'Dadeville',
    state: 'MO',
    latitude: '37.529834',
    longitude: '-93.708779',
    timeZoneId: 'America/Chicago',
  },
  '65636': {
    zip: '65636',
    city: 'Diggins',
    state: 'MO',
    latitude: '37.172542',
    longitude: '-92.853143',
    timeZoneId: 'America/Chicago',
  },
  '65637': {
    zip: '65637',
    city: 'Dora',
    state: 'MO',
    latitude: '36.758509',
    longitude: '-92.222637',
    timeZoneId: 'America/Chicago',
  },
  '65638': {
    zip: '65638',
    city: 'Drury',
    state: 'MO',
    latitude: '36.930415',
    longitude: '-92.365377',
    timeZoneId: 'America/Chicago',
  },
  '65640': {
    zip: '65640',
    city: 'Dunnegan',
    state: 'MO',
    latitude: '37.704497',
    longitude: '-93.522116',
    timeZoneId: 'America/Chicago',
  },
  '65641': {
    zip: '65641',
    city: 'Eagle Rock',
    state: 'MO',
    latitude: '36.563526',
    longitude: '-93.755814',
    timeZoneId: 'America/Chicago',
  },
  '65644': {
    zip: '65644',
    city: 'Elkland',
    state: 'MO',
    latitude: '37.438921',
    longitude: '-93.017937',
    timeZoneId: 'America/Chicago',
  },
  '65645': {
    zip: '65645',
    city: 'Morrisville',
    state: 'MO',
    latitude: '37.481243',
    longitude: '-93.537123',
    timeZoneId: 'America/Chicago',
  },
  '65646': {
    zip: '65646',
    city: 'Everton',
    state: 'MO',
    latitude: '37.357835',
    longitude: '-93.711731',
    timeZoneId: 'America/Chicago',
  },
  '65647': {
    zip: '65647',
    city: 'Exeter',
    state: 'MO',
    latitude: '36.670764',
    longitude: '-93.933797',
    timeZoneId: 'America/Chicago',
  },
  '65648': {
    zip: '65648',
    city: 'Fair Grove',
    state: 'MO',
    latitude: '37.374876',
    longitude: '-93.133028',
    timeZoneId: 'America/Chicago',
  },
  '65649': {
    zip: '65649',
    city: 'Fair Play',
    state: 'MO',
    latitude: '37.632203',
    longitude: '-93.624657',
    timeZoneId: 'America/Chicago',
  },
  '65650': {
    zip: '65650',
    city: 'Flemington',
    state: 'MO',
    latitude: '37.773493',
    longitude: '-93.447679',
    timeZoneId: 'America/Chicago',
  },
  '65652': {
    zip: '65652',
    city: 'Fordland',
    state: 'MO',
    latitude: '37.116309',
    longitude: '-93.062582',
    timeZoneId: 'America/Chicago',
  },
  '65653': {
    zip: '65653',
    city: 'Forsyth',
    state: 'MO',
    latitude: '36.667383',
    longitude: '-93.12157',
    timeZoneId: 'America/Chicago',
  },
  '65654': {
    zip: '65654',
    city: 'Freistatt',
    state: 'MO',
    latitude: '37.017336',
    longitude: '-93.897462',
    timeZoneId: 'America/Chicago',
  },
  '65655': {
    zip: '65655',
    city: 'Gainesville',
    state: 'MO',
    latitude: '36.574662',
    longitude: '-92.412396',
    timeZoneId: 'America/Chicago',
  },
  '65656': {
    zip: '65656',
    city: 'Galena',
    state: 'MO',
    latitude: '36.837668',
    longitude: '-93.479981',
    timeZoneId: 'America/Chicago',
  },
  '65657': {
    zip: '65657',
    city: 'Garrison',
    state: 'MO',
    latitude: '36.855073',
    longitude: '-92.987344',
    timeZoneId: 'America/Chicago',
  },
  '65658': {
    zip: '65658',
    city: 'Golden',
    state: 'MO',
    latitude: '36.542445',
    longitude: '-93.630518',
    timeZoneId: 'America/Chicago',
  },
  '65660': {
    zip: '65660',
    city: 'Graff',
    state: 'MO',
    latitude: '37.114647',
    longitude: '-92.315813',
    timeZoneId: 'America/Chicago',
  },
  '65661': {
    zip: '65661',
    city: 'Greenfield',
    state: 'MO',
    latitude: '37.415238',
    longitude: '-93.844974',
    timeZoneId: 'America/Chicago',
  },
  '65662': {
    zip: '65662',
    city: 'Grovespring',
    state: 'MO',
    latitude: '37.472989',
    longitude: '-92.575018',
    timeZoneId: 'America/Chicago',
  },
  '65663': {
    zip: '65663',
    city: 'Half Way',
    state: 'MO',
    latitude: '37.601355',
    longitude: '-93.248196',
    timeZoneId: 'America/Chicago',
  },
  '65664': {
    zip: '65664',
    city: 'Halltown',
    state: 'MO',
    latitude: '37.193643',
    longitude: '-93.629207',
    timeZoneId: 'America/Chicago',
  },
  '65666': {
    zip: '65666',
    city: 'Hardenville',
    state: 'MO',
    latitude: '36.594912',
    longitude: '-92.37199',
    timeZoneId: 'America/Chicago',
  },
  '65667': {
    zip: '65667',
    city: 'Hartville',
    state: 'MO',
    latitude: '37.295466',
    longitude: '-92.507359',
    timeZoneId: 'America/Chicago',
  },
  '65668': {
    zip: '65668',
    city: 'Hermitage',
    state: 'MO',
    latitude: '37.937897',
    longitude: '-93.31867',
    timeZoneId: 'America/Chicago',
  },
  '65669': {
    zip: '65669',
    city: 'Highlandville',
    state: 'MO',
    latitude: '36.972165',
    longitude: '-93.353272',
    timeZoneId: 'America/Chicago',
  },
  '65672': {
    zip: '65672',
    city: 'Hollister',
    state: 'MO',
    latitude: '36.628402',
    longitude: '-93.207631',
    timeZoneId: 'America/Chicago',
  },
  '65673': {
    zip: '65673',
    city: 'Hollister',
    state: 'MO',
    latitude: '36.619201',
    longitude: '-93.217698',
    timeZoneId: 'America/Chicago',
  },
  '65674': {
    zip: '65674',
    city: 'Humansville',
    state: 'MO',
    latitude: '37.795619',
    longitude: '-93.579699',
    timeZoneId: 'America/Chicago',
  },
  '65675': {
    zip: '65675',
    city: 'Hurley',
    state: 'MO',
    latitude: '36.924539',
    longitude: '-93.475964',
    timeZoneId: 'America/Chicago',
  },
  '65676': {
    zip: '65676',
    city: 'Isabella',
    state: 'MO',
    latitude: '36.582288',
    longitude: '-92.606559',
    timeZoneId: 'America/Chicago',
  },
  '65679': {
    zip: '65679',
    city: 'Kirbyville',
    state: 'MO',
    latitude: '36.5676',
    longitude: '-93.11448',
    timeZoneId: 'America/Chicago',
  },
  '65680': {
    zip: '65680',
    city: 'Kissee Mills',
    state: 'MO',
    latitude: '36.665728',
    longitude: '-93.02525',
    timeZoneId: 'America/Chicago',
  },
  '65681': {
    zip: '65681',
    city: 'Lampe',
    state: 'MO',
    latitude: '36.582305',
    longitude: '-93.444246',
    timeZoneId: 'America/Chicago',
  },
  '65682': {
    zip: '65682',
    city: 'Lockwood',
    state: 'MO',
    latitude: '37.384939',
    longitude: '-93.950106',
    timeZoneId: 'America/Chicago',
  },
  '65685': {
    zip: '65685',
    city: 'Louisburg',
    state: 'MO',
    latitude: '37.728371',
    longitude: '-93.100606',
    timeZoneId: 'America/Chicago',
  },
  '65686': {
    zip: '65686',
    city: 'Kimberling City',
    state: 'MO',
    latitude: '36.640893',
    longitude: '-93.423901',
    timeZoneId: 'America/Chicago',
  },
  '65688': {
    zip: '65688',
    city: 'Brandsville',
    state: 'MO',
    latitude: '36.578766',
    longitude: '-91.730231',
    timeZoneId: 'America/Chicago',
  },
  '65689': {
    zip: '65689',
    city: 'Cabool',
    state: 'MO',
    latitude: '37.124655',
    longitude: '-92.102085',
    timeZoneId: 'America/Chicago',
  },
  '65690': {
    zip: '65690',
    city: 'Couch',
    state: 'MO',
    latitude: '36.567378',
    longitude: '-91.299734',
    timeZoneId: 'America/Chicago',
  },
  '65692': {
    zip: '65692',
    city: 'Koshkonong',
    state: 'MO',
    latitude: '36.615535',
    longitude: '-91.619978',
    timeZoneId: 'America/Chicago',
  },
  '65702': {
    zip: '65702',
    city: 'Macomb',
    state: 'MO',
    latitude: '37.105865',
    longitude: '-92.483028',
    timeZoneId: 'America/Chicago',
  },
  '65704': {
    zip: '65704',
    city: 'Mansfield',
    state: 'MO',
    latitude: '37.111073',
    longitude: '-92.584098',
    timeZoneId: 'America/Chicago',
  },
  '65705': {
    zip: '65705',
    city: 'Marionville',
    state: 'MO',
    latitude: '37.001346',
    longitude: '-93.642179',
    timeZoneId: 'America/Chicago',
  },
  '65706': {
    zip: '65706',
    city: 'Marshfield',
    state: 'MO',
    latitude: '37.339115',
    longitude: '-92.905687',
    timeZoneId: 'America/Chicago',
  },
  '65707': {
    zip: '65707',
    city: 'Miller',
    state: 'MO',
    latitude: '37.231449',
    longitude: '-93.849869',
    timeZoneId: 'America/Chicago',
  },
  '65708': {
    zip: '65708',
    city: 'Monett',
    state: 'MO',
    latitude: '36.918466',
    longitude: '-93.9275',
    timeZoneId: 'America/Chicago',
  },
  '65710': {
    zip: '65710',
    city: 'Morrisville',
    state: 'MO',
    latitude: '37.467448',
    longitude: '-93.429803',
    timeZoneId: 'America/Chicago',
  },
  '65711': {
    zip: '65711',
    city: 'Mountain Grove',
    state: 'MO',
    latitude: '37.37897',
    longitude: '-92.36702',
    timeZoneId: 'America/Chicago',
  },
  '65712': {
    zip: '65712',
    city: 'Mount Vernon',
    state: 'MO',
    latitude: '37.098522',
    longitude: '-93.815768',
    timeZoneId: 'America/Chicago',
  },
  '65713': {
    zip: '65713',
    city: 'Niangua',
    state: 'MO',
    latitude: '37.399093',
    longitude: '-92.742234',
    timeZoneId: 'America/Chicago',
  },
  '65714': {
    zip: '65714',
    city: 'Nixa',
    state: 'MO',
    latitude: '37.047697',
    longitude: '-93.294106',
    timeZoneId: 'America/Chicago',
  },
  '65715': {
    zip: '65715',
    city: 'Noble',
    state: 'MO',
    latitude: '36.736051',
    longitude: '-92.579387',
    timeZoneId: 'America/Chicago',
  },
  '65717': {
    zip: '65717',
    city: 'Norwood',
    state: 'MO',
    latitude: '37.03878',
    longitude: '-92.4217',
    timeZoneId: 'America/Chicago',
  },
  '65720': {
    zip: '65720',
    city: 'Oldfield',
    state: 'MO',
    latitude: '36.967713',
    longitude: '-93.024561',
    timeZoneId: 'America/Chicago',
  },
  '65721': {
    zip: '65721',
    city: 'Ozark',
    state: 'MO',
    latitude: '37.01422',
    longitude: '-93.204822',
    timeZoneId: 'America/Chicago',
  },
  '65722': {
    zip: '65722',
    city: 'Phillipsburg',
    state: 'MO',
    latitude: '37.574495',
    longitude: '-92.768681',
    timeZoneId: 'America/Chicago',
  },
  '65723': {
    zip: '65723',
    city: 'Pierce City',
    state: 'MO',
    latitude: '36.946566',
    longitude: '-94.004128',
    timeZoneId: 'America/Chicago',
  },
  '65724': {
    zip: '65724',
    city: 'Pittsburg',
    state: 'MO',
    latitude: '37.844802',
    longitude: '-93.330659',
    timeZoneId: 'America/Chicago',
  },
  '65725': {
    zip: '65725',
    city: 'Pleasant Hope',
    state: 'MO',
    latitude: '37.475864',
    longitude: '-93.255307',
    timeZoneId: 'America/Chicago',
  },
  '65726': {
    zip: '65726',
    city: 'Point Lookout',
    state: 'MO',
    latitude: '36.591308',
    longitude: '-93.23737',
    timeZoneId: 'America/Chicago',
  },
  '65727': {
    zip: '65727',
    city: 'Polk',
    state: 'MO',
    latitude: '37.754824',
    longitude: '-93.292646',
    timeZoneId: 'America/Chicago',
  },
  '65728': {
    zip: '65728',
    city: 'Ponce de Leon',
    state: 'MO',
    latitude: '36.890333',
    longitude: '-93.372403',
    timeZoneId: 'America/Chicago',
  },
  '65729': {
    zip: '65729',
    city: 'Pontiac',
    state: 'MO',
    latitude: '36.523201',
    longitude: '-92.547871',
    timeZoneId: 'America/Chicago',
  },
  '65730': {
    zip: '65730',
    city: 'Powell',
    state: 'MO',
    latitude: '36.57695',
    longitude: '-94.155588',
    timeZoneId: 'America/Chicago',
  },
  '65731': {
    zip: '65731',
    city: 'Powersite',
    state: 'MO',
    latitude: '36.637501',
    longitude: '-93.080078',
    timeZoneId: 'America/Chicago',
  },
  '65732': {
    zip: '65732',
    city: 'Preston',
    state: 'MO',
    latitude: '37.944168',
    longitude: '-93.163517',
    timeZoneId: 'America/Chicago',
  },
  '65733': {
    zip: '65733',
    city: 'Protem',
    state: 'MO',
    latitude: '36.559987',
    longitude: '-92.847701',
    timeZoneId: 'America/Chicago',
  },
  '65734': {
    zip: '65734',
    city: 'Purdy',
    state: 'MO',
    latitude: '36.816767',
    longitude: '-93.920454',
    timeZoneId: 'America/Chicago',
  },
  '65735': {
    zip: '65735',
    city: 'Quincy',
    state: 'MO',
    latitude: '38.007925',
    longitude: '-93.46841',
    timeZoneId: 'America/Chicago',
  },
  '65737': {
    zip: '65737',
    city: 'Reeds Spring',
    state: 'MO',
    latitude: '36.6792',
    longitude: '-93.37068',
    timeZoneId: 'America/Chicago',
  },
  '65738': {
    zip: '65738',
    city: 'Republic',
    state: 'MO',
    latitude: '37.120403',
    longitude: '-93.473396',
    timeZoneId: 'America/Chicago',
  },
  '65739': {
    zip: '65739',
    city: 'Ridgedale',
    state: 'MO',
    latitude: '36.539284',
    longitude: '-93.280373',
    timeZoneId: 'America/Chicago',
  },
  '65740': {
    zip: '65740',
    city: 'Rockaway Beach',
    state: 'MO',
    latitude: '36.701943',
    longitude: '-93.161158',
    timeZoneId: 'America/Chicago',
  },
  '65741': {
    zip: '65741',
    city: 'Rockbridge',
    state: 'MO',
    latitude: '36.788867',
    longitude: '-92.409967',
    timeZoneId: 'America/Chicago',
  },
  '65742': {
    zip: '65742',
    city: 'Rogersville',
    state: 'MO',
    latitude: '37.12194',
    longitude: '-93.13878',
    timeZoneId: 'America/Chicago',
  },
  '65744': {
    zip: '65744',
    city: 'Rueter',
    state: 'MO',
    latitude: '36.638557',
    longitude: '-92.912454',
    timeZoneId: 'America/Chicago',
  },
  '65745': {
    zip: '65745',
    city: 'Seligman',
    state: 'MO',
    latitude: '36.526601',
    longitude: '-93.938072',
    timeZoneId: 'America/Chicago',
  },
  '65746': {
    zip: '65746',
    city: 'Seymour',
    state: 'MO',
    latitude: '37.147408',
    longitude: '-92.767863',
    timeZoneId: 'America/Chicago',
  },
  '65747': {
    zip: '65747',
    city: 'Shell Knob',
    state: 'MO',
    latitude: '36.6171',
    longitude: '-93.62778',
    timeZoneId: 'America/Chicago',
  },
  '65752': {
    zip: '65752',
    city: 'South Greenfield',
    state: 'MO',
    latitude: '37.366087',
    longitude: '-93.844568',
    timeZoneId: 'America/Chicago',
  },
  '65753': {
    zip: '65753',
    city: 'Sparta',
    state: 'MO',
    latitude: '36.96468',
    longitude: '-93.1272',
    timeZoneId: 'America/Chicago',
  },
  '65754': {
    zip: '65754',
    city: 'Spokane',
    state: 'MO',
    latitude: '36.855001',
    longitude: '-93.269804',
    timeZoneId: 'America/Chicago',
  },
  '65755': {
    zip: '65755',
    city: 'Squires',
    state: 'MO',
    latitude: '36.848501',
    longitude: '-92.582552',
    timeZoneId: 'America/Chicago',
  },
  '65756': {
    zip: '65756',
    city: 'Stotts City',
    state: 'MO',
    latitude: '37.111315',
    longitude: '-93.957456',
    timeZoneId: 'America/Chicago',
  },
  '65757': {
    zip: '65757',
    city: 'Strafford',
    state: 'MO',
    latitude: '37.271244',
    longitude: '-93.115517',
    timeZoneId: 'America/Chicago',
  },
  '65759': {
    zip: '65759',
    city: 'Taneyville',
    state: 'MO',
    latitude: '36.751275',
    longitude: '-93.011754',
    timeZoneId: 'America/Chicago',
  },
  '65760': {
    zip: '65760',
    city: 'Tecumseh',
    state: 'MO',
    latitude: '36.584742',
    longitude: '-92.258059',
    timeZoneId: 'America/Chicago',
  },
  '65761': {
    zip: '65761',
    city: 'Theodosia',
    state: 'MO',
    latitude: '36.57798',
    longitude: '-92.70678',
    timeZoneId: 'America/Chicago',
  },
  '65762': {
    zip: '65762',
    city: 'Thornfield',
    state: 'MO',
    latitude: '36.707587',
    longitude: '-92.654958',
    timeZoneId: 'America/Chicago',
  },
  '65764': {
    zip: '65764',
    city: 'Tunas',
    state: 'MO',
    latitude: '37.835835',
    longitude: '-92.962045',
    timeZoneId: 'America/Chicago',
  },
  '65765': {
    zip: '65765',
    city: 'Turners',
    state: 'MO',
    latitude: '37.181516',
    longitude: '-93.157205',
    timeZoneId: 'America/Chicago',
  },
  '65766': {
    zip: '65766',
    city: 'Udall',
    state: 'MO',
    latitude: '36.526066',
    longitude: '-92.23375',
    timeZoneId: 'America/Chicago',
  },
  '65767': {
    zip: '65767',
    city: 'Urbana',
    state: 'MO',
    latitude: '37.84081',
    longitude: '-93.16908',
    timeZoneId: 'America/Chicago',
  },
  '65768': {
    zip: '65768',
    city: 'Vanzant',
    state: 'MO',
    latitude: '36.968695',
    longitude: '-92.197398',
    timeZoneId: 'America/Chicago',
  },
  '65769': {
    zip: '65769',
    city: 'Verona',
    state: 'MO',
    latitude: '36.961558',
    longitude: '-93.795732',
    timeZoneId: 'America/Chicago',
  },
  '65770': {
    zip: '65770',
    city: 'Walnut Grove',
    state: 'MO',
    latitude: '37.413249',
    longitude: '-93.548626',
    timeZoneId: 'America/Chicago',
  },
  '65771': {
    zip: '65771',
    city: 'Walnut Shade',
    state: 'MO',
    latitude: '36.76753',
    longitude: '-93.228332',
    timeZoneId: 'America/Chicago',
  },
  '65772': {
    zip: '65772',
    city: 'Washburn',
    state: 'MO',
    latitude: '36.675572',
    longitude: '-93.880587',
    timeZoneId: 'America/Chicago',
  },
  '65773': {
    zip: '65773',
    city: 'Wasola',
    state: 'MO',
    latitude: '36.745108',
    longitude: '-92.521187',
    timeZoneId: 'America/Chicago',
  },
  '65774': {
    zip: '65774',
    city: 'Weaubleau',
    state: 'MO',
    latitude: '37.889769',
    longitude: '-93.539293',
    timeZoneId: 'America/Chicago',
  },
  '65775': {
    zip: '65775',
    city: 'West Plains',
    state: 'MO',
    latitude: '36.51388',
    longitude: '-91.80154',
    timeZoneId: 'America/Chicago',
  },
  '65777': {
    zip: '65777',
    city: 'Moody',
    state: 'MO',
    latitude: '36.535679',
    longitude: '-91.989585',
    timeZoneId: 'America/Chicago',
  },
  '65778': {
    zip: '65778',
    city: 'Myrtle',
    state: 'MO',
    latitude: '36.518344',
    longitude: '-91.257862',
    timeZoneId: 'America/Chicago',
  },
  '65779': {
    zip: '65779',
    city: 'Wheatland',
    state: 'MO',
    latitude: '37.91052',
    longitude: '-93.3966',
    timeZoneId: 'America/Chicago',
  },
  '65781': {
    zip: '65781',
    city: 'Willard',
    state: 'MO',
    latitude: '37.304579',
    longitude: '-93.436613',
    timeZoneId: 'America/Chicago',
  },
  '65783': {
    zip: '65783',
    city: 'Windyville',
    state: 'MO',
    latitude: '37.713918',
    longitude: '-92.933038',
    timeZoneId: 'America/Chicago',
  },
  '65784': {
    zip: '65784',
    city: 'Zanoni',
    state: 'MO',
    latitude: '36.683625',
    longitude: '-92.304487',
    timeZoneId: 'America/Chicago',
  },
  '65785': {
    zip: '65785',
    city: 'Stockton',
    state: 'MO',
    latitude: '37.693951',
    longitude: '-93.801056',
    timeZoneId: 'America/Chicago',
  },
  '65786': {
    zip: '65786',
    city: 'Macks Creek',
    state: 'MO',
    latitude: '37.976586',
    longitude: '-92.968476',
    timeZoneId: 'America/Chicago',
  },
  '65787': {
    zip: '65787',
    city: 'Roach',
    state: 'MO',
    latitude: '38.010123',
    longitude: '-92.807334',
    timeZoneId: 'America/Chicago',
  },
  '65788': {
    zip: '65788',
    city: 'Peace Valley',
    state: 'MO',
    latitude: '36.838442',
    longitude: '-91.760467',
    timeZoneId: 'America/Chicago',
  },
  '65789': {
    zip: '65789',
    city: 'Pomona',
    state: 'MO',
    latitude: '36.847064',
    longitude: '-91.915901',
    timeZoneId: 'America/Chicago',
  },
  '65790': {
    zip: '65790',
    city: 'Pottersville',
    state: 'MO',
    latitude: '36.702688',
    longitude: '-92.046284',
    timeZoneId: 'America/Chicago',
  },
  '65791': {
    zip: '65791',
    city: 'Thayer',
    state: 'MO',
    latitude: '36.523905',
    longitude: '-91.544323',
    timeZoneId: 'America/Chicago',
  },
  '65793': {
    zip: '65793',
    city: 'Willow Springs',
    state: 'MO',
    latitude: '36.994754',
    longitude: '-91.969717',
    timeZoneId: 'America/Chicago',
  },
  '65801': {
    zip: '65801',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.131172',
    longitude: '-93.294336',
    timeZoneId: 'America/Chicago',
  },
  '65802': {
    zip: '65802',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.212257',
    longitude: '-93.306677',
    timeZoneId: 'America/Chicago',
  },
  '65803': {
    zip: '65803',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.244329',
    longitude: '-93.294373',
    timeZoneId: 'America/Chicago',
  },
  '65804': {
    zip: '65804',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.164369',
    longitude: '-93.244291',
    timeZoneId: 'America/Chicago',
  },
  '65805': {
    zip: '65805',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.254806',
    longitude: '-93.315329',
    timeZoneId: 'America/Chicago',
  },
  '65806': {
    zip: '65806',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.203058',
    longitude: '-93.297901',
    timeZoneId: 'America/Chicago',
  },
  '65807': {
    zip: '65807',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.166766',
    longitude: '-93.312621',
    timeZoneId: 'America/Chicago',
  },
  '65808': {
    zip: '65808',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.189794',
    longitude: '-93.262342',
    timeZoneId: 'America/Chicago',
  },
  '65809': {
    zip: '65809',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.176716',
    longitude: '-93.207149',
    timeZoneId: 'America/Chicago',
  },
  '65810': {
    zip: '65810',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.123311',
    longitude: '-93.320088',
    timeZoneId: 'America/Chicago',
  },
  '65814': {
    zip: '65814',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.216841',
    longitude: '-93.300022',
    timeZoneId: 'America/Chicago',
  },
  '65817': {
    zip: '65817',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.169066',
    longitude: '-93.326524',
    timeZoneId: 'America/Chicago',
  },
  '65890': {
    zip: '65890',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.205863',
    longitude: '-93.354799',
    timeZoneId: 'America/Chicago',
  },
  '65897': {
    zip: '65897',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.1991',
    longitude: '-93.2761',
    timeZoneId: 'America/Chicago',
  },
  '65898': {
    zip: '65898',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.21338',
    longitude: '-93.292201',
    timeZoneId: 'America/Chicago',
  },
  '65899': {
    zip: '65899',
    city: 'Springfield',
    state: 'MO',
    latitude: '37.150347',
    longitude: '-93.248417',
    timeZoneId: 'America/Chicago',
  },
  '66002': {
    zip: '66002',
    city: 'Atchison',
    state: 'KS',
    latitude: '39.562045',
    longitude: '-95.129084',
    timeZoneId: 'America/Chicago',
  },
  '66006': {
    zip: '66006',
    city: 'Baldwin City',
    state: 'KS',
    latitude: '38.776889',
    longitude: '-95.187756',
    timeZoneId: 'America/Chicago',
  },
  '66007': {
    zip: '66007',
    city: 'Basehor',
    state: 'KS',
    latitude: '39.13639',
    longitude: '-94.93198',
    timeZoneId: 'America/Chicago',
  },
  '66008': {
    zip: '66008',
    city: 'Bendena',
    state: 'KS',
    latitude: '39.702742',
    longitude: '-95.179457',
    timeZoneId: 'America/Chicago',
  },
  '66010': {
    zip: '66010',
    city: 'Blue Mound',
    state: 'KS',
    latitude: '38.095828',
    longitude: '-95.004477',
    timeZoneId: 'America/Chicago',
  },
  '66012': {
    zip: '66012',
    city: 'Bonner Springs',
    state: 'KS',
    latitude: '39.073262',
    longitude: '-94.884623',
    timeZoneId: 'America/Chicago',
  },
  '66013': {
    zip: '66013',
    city: 'Bucyrus',
    state: 'KS',
    latitude: '38.782596',
    longitude: '-94.699097',
    timeZoneId: 'America/Chicago',
  },
  '66014': {
    zip: '66014',
    city: 'Centerville',
    state: 'KS',
    latitude: '38.211339',
    longitude: '-94.986151',
    timeZoneId: 'America/Chicago',
  },
  '66015': {
    zip: '66015',
    city: 'Colony',
    state: 'KS',
    latitude: '38.072175',
    longitude: '-95.363939',
    timeZoneId: 'America/Chicago',
  },
  '66016': {
    zip: '66016',
    city: 'Cummings',
    state: 'KS',
    latitude: '39.482336',
    longitude: '-95.294104',
    timeZoneId: 'America/Chicago',
  },
  '66017': {
    zip: '66017',
    city: 'Denton',
    state: 'KS',
    latitude: '39.73282',
    longitude: '-95.26971',
    timeZoneId: 'America/Chicago',
  },
  '66018': {
    zip: '66018',
    city: 'De Soto',
    state: 'KS',
    latitude: '38.970431',
    longitude: '-94.967565',
    timeZoneId: 'America/Chicago',
  },
  '66020': {
    zip: '66020',
    city: 'Easton',
    state: 'KS',
    latitude: '39.34657',
    longitude: '-95.116302',
    timeZoneId: 'America/Chicago',
  },
  '66021': {
    zip: '66021',
    city: 'Edgerton',
    state: 'KS',
    latitude: '38.769465',
    longitude: '-95.000783',
    timeZoneId: 'America/Chicago',
  },
  '66023': {
    zip: '66023',
    city: 'Effingham',
    state: 'KS',
    latitude: '39.486207',
    longitude: '-95.45198',
    timeZoneId: 'America/Chicago',
  },
  '66024': {
    zip: '66024',
    city: 'Elwood',
    state: 'KS',
    latitude: '39.758137',
    longitude: '-94.877538',
    timeZoneId: 'America/Chicago',
  },
  '66025': {
    zip: '66025',
    city: 'Eudora',
    state: 'KS',
    latitude: '38.941893',
    longitude: '-95.101497',
    timeZoneId: 'America/Chicago',
  },
  '66026': {
    zip: '66026',
    city: 'Fontana',
    state: 'KS',
    latitude: '38.396275',
    longitude: '-94.874247',
    timeZoneId: 'America/Chicago',
  },
  '66027': {
    zip: '66027',
    city: 'Fort Leavenworth',
    state: 'KS',
    latitude: '39.36143',
    longitude: '-94.923664',
    timeZoneId: 'America/Chicago',
  },
  '66030': {
    zip: '66030',
    city: 'Gardner',
    state: 'KS',
    latitude: '38.815013',
    longitude: '-94.93308',
    timeZoneId: 'America/Chicago',
  },
  '66031': {
    zip: '66031',
    city: 'New Century',
    state: 'KS',
    latitude: '38.813738',
    longitude: '-94.929095',
    timeZoneId: 'America/Chicago',
  },
  '66032': {
    zip: '66032',
    city: 'Garnett',
    state: 'KS',
    latitude: '38.282629',
    longitude: '-95.241151',
    timeZoneId: 'America/Chicago',
  },
  '66033': {
    zip: '66033',
    city: 'Greeley',
    state: 'KS',
    latitude: '38.367258',
    longitude: '-95.126185',
    timeZoneId: 'America/Chicago',
  },
  '66035': {
    zip: '66035',
    city: 'Highland',
    state: 'KS',
    latitude: '39.860584',
    longitude: '-95.266266',
    timeZoneId: 'America/Chicago',
  },
  '66036': {
    zip: '66036',
    city: 'Hillsdale',
    state: 'KS',
    latitude: '38.662298',
    longitude: '-94.852003',
    timeZoneId: 'America/Chicago',
  },
  '66039': {
    zip: '66039',
    city: 'Kincaid',
    state: 'KS',
    latitude: '38.103006',
    longitude: '-95.128986',
    timeZoneId: 'America/Chicago',
  },
  '66040': {
    zip: '66040',
    city: 'La Cygne',
    state: 'KS',
    latitude: '38.356638',
    longitude: '-94.741025',
    timeZoneId: 'America/Chicago',
  },
  '66041': {
    zip: '66041',
    city: 'Lancaster',
    state: 'KS',
    latitude: '39.60312',
    longitude: '-95.3259',
    timeZoneId: 'America/Chicago',
  },
  '66042': {
    zip: '66042',
    city: 'Lane',
    state: 'KS',
    latitude: '38.439184',
    longitude: '-95.085753',
    timeZoneId: 'America/Chicago',
  },
  '66043': {
    zip: '66043',
    city: 'Lansing',
    state: 'KS',
    latitude: '39.253586',
    longitude: '-94.901087',
    timeZoneId: 'America/Chicago',
  },
  '66044': {
    zip: '66044',
    city: 'Lawrence',
    state: 'KS',
    latitude: '38.962471',
    longitude: '-95.254516',
    timeZoneId: 'America/Chicago',
  },
  '66045': {
    zip: '66045',
    city: 'Lawrence',
    state: 'KS',
    latitude: '38.959171',
    longitude: '-95.264228',
    timeZoneId: 'America/Chicago',
  },
  '66046': {
    zip: '66046',
    city: 'Lawrence',
    state: 'KS',
    latitude: '38.943832',
    longitude: '-95.255319',
    timeZoneId: 'America/Chicago',
  },
  '66047': {
    zip: '66047',
    city: 'Lawrence',
    state: 'KS',
    latitude: '38.946781',
    longitude: '-95.281917',
    timeZoneId: 'America/Chicago',
  },
  '66048': {
    zip: '66048',
    city: 'Leavenworth',
    state: 'KS',
    latitude: '39.306584',
    longitude: '-94.928404',
    timeZoneId: 'America/Chicago',
  },
  '66049': {
    zip: '66049',
    city: 'Lawrence',
    state: 'KS',
    latitude: '38.970249',
    longitude: '-95.279946',
    timeZoneId: 'America/Chicago',
  },
  '66050': {
    zip: '66050',
    city: 'Lecompton',
    state: 'KS',
    latitude: '39.039869',
    longitude: '-95.402286',
    timeZoneId: 'America/Chicago',
  },
  '66051': {
    zip: '66051',
    city: 'Olathe',
    state: 'KS',
    latitude: '38.880565',
    longitude: '-94.8175',
    timeZoneId: 'America/Chicago',
  },
  '66052': {
    zip: '66052',
    city: 'Linwood',
    state: 'KS',
    latitude: '39.000948',
    longitude: '-95.038348',
    timeZoneId: 'America/Chicago',
  },
  '66053': {
    zip: '66053',
    city: 'Louisburg',
    state: 'KS',
    latitude: '38.622878',
    longitude: '-94.67524',
    timeZoneId: 'America/Chicago',
  },
  '66054': {
    zip: '66054',
    city: 'Mc Louth',
    state: 'KS',
    latitude: '39.192952',
    longitude: '-95.206733',
    timeZoneId: 'America/Chicago',
  },
  '66056': {
    zip: '66056',
    city: 'Mound City',
    state: 'KS',
    latitude: '38.142278',
    longitude: '-94.808789',
    timeZoneId: 'America/Chicago',
  },
  '66058': {
    zip: '66058',
    city: 'Muscotah',
    state: 'KS',
    latitude: '39.59818',
    longitude: '-95.489987',
    timeZoneId: 'America/Chicago',
  },
  '66060': {
    zip: '66060',
    city: 'Nortonville',
    state: 'KS',
    latitude: '39.420131',
    longitude: '-95.330822',
    timeZoneId: 'America/Chicago',
  },
  '66061': {
    zip: '66061',
    city: 'Olathe',
    state: 'KS',
    latitude: '38.884071',
    longitude: '-94.870529',
    timeZoneId: 'America/Chicago',
  },
  '66062': {
    zip: '66062',
    city: 'Olathe',
    state: 'KS',
    latitude: '38.840421',
    longitude: '-94.78191',
    timeZoneId: 'America/Chicago',
  },
  '66063': {
    zip: '66063',
    city: 'Olathe',
    state: 'KS',
    latitude: '38.880069',
    longitude: '-94.816954',
    timeZoneId: 'America/Chicago',
  },
  '66064': {
    zip: '66064',
    city: 'Osawatomie',
    state: 'KS',
    latitude: '38.474864',
    longitude: '-94.973904',
    timeZoneId: 'America/Chicago',
  },
  '66066': {
    zip: '66066',
    city: 'Oskaloosa',
    state: 'KS',
    latitude: '39.213571',
    longitude: '-95.314577',
    timeZoneId: 'America/Chicago',
  },
  '66067': {
    zip: '66067',
    city: 'Ottawa',
    state: 'KS',
    latitude: '38.607557',
    longitude: '-95.267627',
    timeZoneId: 'America/Chicago',
  },
  '66070': {
    zip: '66070',
    city: 'Ozawkie',
    state: 'KS',
    latitude: '39.205018',
    longitude: '-95.449424',
    timeZoneId: 'America/Chicago',
  },
  '66071': {
    zip: '66071',
    city: 'Paola',
    state: 'KS',
    latitude: '38.577177',
    longitude: '-94.870515',
    timeZoneId: 'America/Chicago',
  },
  '66072': {
    zip: '66072',
    city: 'Parker',
    state: 'KS',
    latitude: '38.327153',
    longitude: '-94.990741',
    timeZoneId: 'America/Chicago',
  },
  '66073': {
    zip: '66073',
    city: 'Perry',
    state: 'KS',
    latitude: '39.075627',
    longitude: '-95.393017',
    timeZoneId: 'America/Chicago',
  },
  '66075': {
    zip: '66075',
    city: 'Pleasanton',
    state: 'KS',
    latitude: '38.174432',
    longitude: '-94.709568',
    timeZoneId: 'America/Chicago',
  },
  '66076': {
    zip: '66076',
    city: 'Pomona',
    state: 'KS',
    latitude: '38.608081',
    longitude: '-95.451714',
    timeZoneId: 'America/Chicago',
  },
  '66078': {
    zip: '66078',
    city: 'Princeton',
    state: 'KS',
    latitude: '38.490104',
    longitude: '-95.276057',
    timeZoneId: 'America/Chicago',
  },
  '66079': {
    zip: '66079',
    city: 'Rantoul',
    state: 'KS',
    latitude: '38.547975',
    longitude: '-95.097538',
    timeZoneId: 'America/Chicago',
  },
  '66080': {
    zip: '66080',
    city: 'Richmond',
    state: 'KS',
    latitude: '38.402738',
    longitude: '-95.253134',
    timeZoneId: 'America/Chicago',
  },
  '66083': {
    zip: '66083',
    city: 'Spring Hill',
    state: 'KS',
    latitude: '38.732116',
    longitude: '-94.808736',
    timeZoneId: 'America/Chicago',
  },
  '66085': {
    zip: '66085',
    city: 'Stilwell',
    state: 'KS',
    latitude: '38.8002',
    longitude: '-94.647263',
    timeZoneId: 'America/Chicago',
  },
  '66086': {
    zip: '66086',
    city: 'Tonganoxie',
    state: 'KS',
    latitude: '39.107235',
    longitude: '-95.08828',
    timeZoneId: 'America/Chicago',
  },
  '66087': {
    zip: '66087',
    city: 'Troy',
    state: 'KS',
    latitude: '39.78054',
    longitude: '-95.0886',
    timeZoneId: 'America/Chicago',
  },
  '66088': {
    zip: '66088',
    city: 'Valley Falls',
    state: 'KS',
    latitude: '39.344898',
    longitude: '-95.460968',
    timeZoneId: 'America/Chicago',
  },
  '66090': {
    zip: '66090',
    city: 'Wathena',
    state: 'KS',
    latitude: '39.762419',
    longitude: '-94.948109',
    timeZoneId: 'America/Chicago',
  },
  '66091': {
    zip: '66091',
    city: 'Welda',
    state: 'KS',
    latitude: '38.16944',
    longitude: '-95.29559',
    timeZoneId: 'America/Chicago',
  },
  '66092': {
    zip: '66092',
    city: 'Wellsville',
    state: 'KS',
    latitude: '38.695895',
    longitude: '-95.149356',
    timeZoneId: 'America/Chicago',
  },
  '66093': {
    zip: '66093',
    city: 'Westphalia',
    state: 'KS',
    latitude: '38.152826',
    longitude: '-95.456557',
    timeZoneId: 'America/Chicago',
  },
  '66094': {
    zip: '66094',
    city: 'White Cloud',
    state: 'KS',
    latitude: '39.944895',
    longitude: '-95.286941',
    timeZoneId: 'America/Chicago',
  },
  '66095': {
    zip: '66095',
    city: 'Williamsburg',
    state: 'KS',
    latitude: '38.480623',
    longitude: '-95.46493',
    timeZoneId: 'America/Chicago',
  },
  '66097': {
    zip: '66097',
    city: 'Winchester',
    state: 'KS',
    latitude: '39.304737',
    longitude: '-95.274703',
    timeZoneId: 'America/Chicago',
  },
  '66101': {
    zip: '66101',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.119223',
    longitude: '-94.627014',
    timeZoneId: 'America/Chicago',
  },
  '66102': {
    zip: '66102',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.114418',
    longitude: '-94.681239',
    timeZoneId: 'America/Chicago',
  },
  '66103': {
    zip: '66103',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.056699',
    longitude: '-94.629221',
    timeZoneId: 'America/Chicago',
  },
  '66104': {
    zip: '66104',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.155349',
    longitude: '-94.693675',
    timeZoneId: 'America/Chicago',
  },
  '66105': {
    zip: '66105',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.079775',
    longitude: '-94.632837',
    timeZoneId: 'America/Chicago',
  },
  '66106': {
    zip: '66106',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.06252',
    longitude: '-94.71774',
    timeZoneId: 'America/Chicago',
  },
  '66109': {
    zip: '66109',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.143284',
    longitude: '-94.799949',
    timeZoneId: 'America/Chicago',
  },
  '66110': {
    zip: '66110',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.113228',
    longitude: '-94.694979',
    timeZoneId: 'America/Chicago',
  },
  '66111': {
    zip: '66111',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.08958',
    longitude: '-94.7397',
    timeZoneId: 'America/Chicago',
  },
  '66112': {
    zip: '66112',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.114713',
    longitude: '-94.761266',
    timeZoneId: 'America/Chicago',
  },
  '66113': {
    zip: '66113',
    city: 'Edwardsville',
    state: 'KS',
    latitude: '39.06108',
    longitude: '-94.81938',
    timeZoneId: 'America/Chicago',
  },
  '66115': {
    zip: '66115',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.14535',
    longitude: '-94.610031',
    timeZoneId: 'America/Chicago',
  },
  '66117': {
    zip: '66117',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.116401',
    longitude: '-94.624298',
    timeZoneId: 'America/Chicago',
  },
  '66118': {
    zip: '66118',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.103935',
    longitude: '-94.612021',
    timeZoneId: 'America/Chicago',
  },
  '66119': {
    zip: '66119',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.083719',
    longitude: '-94.639027',
    timeZoneId: 'America/Chicago',
  },
  '66160': {
    zip: '66160',
    city: 'Kansas City',
    state: 'KS',
    latitude: '39.058215',
    longitude: '-94.609992',
    timeZoneId: 'America/Chicago',
  },
  '66201': {
    zip: '66201',
    city: 'Mission',
    state: 'KS',
    latitude: '38.969026',
    longitude: '-94.676138',
    timeZoneId: 'America/Chicago',
  },
  '66202': {
    zip: '66202',
    city: 'Mission',
    state: 'KS',
    latitude: '39.022936',
    longitude: '-94.668789',
    timeZoneId: 'America/Chicago',
  },
  '66203': {
    zip: '66203',
    city: 'Shawnee',
    state: 'KS',
    latitude: '39.01776',
    longitude: '-94.69686',
    timeZoneId: 'America/Chicago',
  },
  '66204': {
    zip: '66204',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.992504',
    longitude: '-94.682925',
    timeZoneId: 'America/Chicago',
  },
  '66205': {
    zip: '66205',
    city: 'Mission',
    state: 'KS',
    latitude: '39.032921',
    longitude: '-94.634324',
    timeZoneId: 'America/Chicago',
  },
  '66206': {
    zip: '66206',
    city: 'Leawood',
    state: 'KS',
    latitude: '38.9508',
    longitude: '-94.626',
    timeZoneId: 'America/Chicago',
  },
  '66207': {
    zip: '66207',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.956388',
    longitude: '-94.640303',
    timeZoneId: 'America/Chicago',
  },
  '66208': {
    zip: '66208',
    city: 'Prairie Village',
    state: 'KS',
    latitude: '38.997171',
    longitude: '-94.632694',
    timeZoneId: 'America/Chicago',
  },
  '66209': {
    zip: '66209',
    city: 'Leawood',
    state: 'KS',
    latitude: '38.900603',
    longitude: '-94.632767',
    timeZoneId: 'America/Chicago',
  },
  '66210': {
    zip: '66210',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.9247',
    longitude: '-94.67832',
    timeZoneId: 'America/Chicago',
  },
  '66211': {
    zip: '66211',
    city: 'Leawood',
    state: 'KS',
    latitude: '38.924984',
    longitude: '-94.64217',
    timeZoneId: 'America/Chicago',
  },
  '66212': {
    zip: '66212',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.955997',
    longitude: '-94.682048',
    timeZoneId: 'America/Chicago',
  },
  '66213': {
    zip: '66213',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.902439',
    longitude: '-94.703976',
    timeZoneId: 'America/Chicago',
  },
  '66214': {
    zip: '66214',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.99148',
    longitude: '-94.71042',
    timeZoneId: 'America/Chicago',
  },
  '66215': {
    zip: '66215',
    city: 'Lenexa',
    state: 'KS',
    latitude: '38.94918',
    longitude: '-94.74246',
    timeZoneId: 'America/Chicago',
  },
  '66216': {
    zip: '66216',
    city: 'Shawnee',
    state: 'KS',
    latitude: '39.008231',
    longitude: '-94.740275',
    timeZoneId: 'America/Chicago',
  },
  '66217': {
    zip: '66217',
    city: 'Shawnee',
    state: 'KS',
    latitude: '39.01001',
    longitude: '-94.780199',
    timeZoneId: 'America/Chicago',
  },
  '66218': {
    zip: '66218',
    city: 'Shawnee',
    state: 'KS',
    latitude: '39.014399',
    longitude: '-94.817167',
    timeZoneId: 'America/Chicago',
  },
  '66219': {
    zip: '66219',
    city: 'Lenexa',
    state: 'KS',
    latitude: '38.965992',
    longitude: '-94.764646',
    timeZoneId: 'America/Chicago',
  },
  '66220': {
    zip: '66220',
    city: 'Lenexa',
    state: 'KS',
    latitude: '38.968891',
    longitude: '-94.81786',
    timeZoneId: 'America/Chicago',
  },
  '66221': {
    zip: '66221',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.86182',
    longitude: '-94.71264',
    timeZoneId: 'America/Chicago',
  },
  '66222': {
    zip: '66222',
    city: 'Mission',
    state: 'KS',
    latitude: '39.023477',
    longitude: '-94.648849',
    timeZoneId: 'America/Chicago',
  },
  '66223': {
    zip: '66223',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.854674',
    longitude: '-94.667175',
    timeZoneId: 'America/Chicago',
  },
  '66224': {
    zip: '66224',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.866937',
    longitude: '-94.629184',
    timeZoneId: 'America/Chicago',
  },
  '66225': {
    zip: '66225',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.888339',
    longitude: '-94.686133',
    timeZoneId: 'America/Chicago',
  },
  '66226': {
    zip: '66226',
    city: 'Shawnee',
    state: 'KS',
    latitude: '39.00492',
    longitude: '-94.85886',
    timeZoneId: 'America/Chicago',
  },
  '66227': {
    zip: '66227',
    city: 'Lenexa',
    state: 'KS',
    latitude: '38.980518',
    longitude: '-94.869055',
    timeZoneId: 'America/Chicago',
  },
  '66250': {
    zip: '66250',
    city: 'Lenexa',
    state: 'KS',
    latitude: '38.955067',
    longitude: '-94.786621',
    timeZoneId: 'America/Chicago',
  },
  '66251': {
    zip: '66251',
    city: 'Overland Park',
    state: 'KS',
    latitude: '39.020332',
    longitude: '-94.663201',
    timeZoneId: 'America/Chicago',
  },
  '66276': {
    zip: '66276',
    city: 'Lenexa',
    state: 'KS',
    latitude: '39.002659',
    longitude: '-94.669998',
    timeZoneId: 'America/Chicago',
  },
  '66282': {
    zip: '66282',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.953539',
    longitude: '-94.688284',
    timeZoneId: 'America/Chicago',
  },
  '66283': {
    zip: '66283',
    city: 'Overland Park',
    state: 'KS',
    latitude: '38.861823',
    longitude: '-94.670864',
    timeZoneId: 'America/Chicago',
  },
  '66285': {
    zip: '66285',
    city: 'Lenexa',
    state: 'KS',
    latitude: '39.016846',
    longitude: '-94.663276',
    timeZoneId: 'America/Chicago',
  },
  '66286': {
    zip: '66286',
    city: 'Shawnee',
    state: 'KS',
    latitude: '38.993781',
    longitude: '-94.871495',
    timeZoneId: 'America/Chicago',
  },
  '66401': {
    zip: '66401',
    city: 'Alma',
    state: 'KS',
    latitude: '38.963736',
    longitude: '-96.242316',
    timeZoneId: 'America/Chicago',
  },
  '66402': {
    zip: '66402',
    city: 'Auburn',
    state: 'KS',
    latitude: '38.907621',
    longitude: '-95.829763',
    timeZoneId: 'America/Chicago',
  },
  '66403': {
    zip: '66403',
    city: 'Axtell',
    state: 'KS',
    latitude: '39.871651',
    longitude: '-96.258115',
    timeZoneId: 'America/Chicago',
  },
  '66404': {
    zip: '66404',
    city: 'Baileyville',
    state: 'KS',
    latitude: '39.842643',
    longitude: '-96.184862',
    timeZoneId: 'America/Chicago',
  },
  '66406': {
    zip: '66406',
    city: 'Beattie',
    state: 'KS',
    latitude: '39.861607',
    longitude: '-96.420383',
    timeZoneId: 'America/Chicago',
  },
  '66407': {
    zip: '66407',
    city: 'Belvue',
    state: 'KS',
    latitude: '39.243209',
    longitude: '-96.174224',
    timeZoneId: 'America/Chicago',
  },
  '66408': {
    zip: '66408',
    city: 'Bern',
    state: 'KS',
    latitude: '39.955795',
    longitude: '-95.95497',
    timeZoneId: 'America/Chicago',
  },
  '66409': {
    zip: '66409',
    city: 'Berryton',
    state: 'KS',
    latitude: '38.923456',
    longitude: '-95.591604',
    timeZoneId: 'America/Chicago',
  },
  '66411': {
    zip: '66411',
    city: 'Blue Rapids',
    state: 'KS',
    latitude: '39.683914',
    longitude: '-96.659363',
    timeZoneId: 'America/Chicago',
  },
  '66412': {
    zip: '66412',
    city: 'Bremen',
    state: 'KS',
    latitude: '39.872869',
    longitude: '-96.746923',
    timeZoneId: 'America/Chicago',
  },
  '66413': {
    zip: '66413',
    city: 'Burlingame',
    state: 'KS',
    latitude: '38.752959',
    longitude: '-95.823846',
    timeZoneId: 'America/Chicago',
  },
  '66414': {
    zip: '66414',
    city: 'Carbondale',
    state: 'KS',
    latitude: '38.819916',
    longitude: '-95.691821',
    timeZoneId: 'America/Chicago',
  },
  '66415': {
    zip: '66415',
    city: 'Centralia',
    state: 'KS',
    latitude: '39.726064',
    longitude: '-96.129109',
    timeZoneId: 'America/Chicago',
  },
  '66416': {
    zip: '66416',
    city: 'Circleville',
    state: 'KS',
    latitude: '39.507485',
    longitude: '-95.859636',
    timeZoneId: 'America/Chicago',
  },
  '66417': {
    zip: '66417',
    city: 'Corning',
    state: 'KS',
    latitude: '39.640946',
    longitude: '-96.094728',
    timeZoneId: 'America/Chicago',
  },
  '66418': {
    zip: '66418',
    city: 'Delia',
    state: 'KS',
    latitude: '39.285279',
    longitude: '-95.955597',
    timeZoneId: 'America/Chicago',
  },
  '66419': {
    zip: '66419',
    city: 'Denison',
    state: 'KS',
    latitude: '39.461982',
    longitude: '-95.742722',
    timeZoneId: 'America/Chicago',
  },
  '66420': {
    zip: '66420',
    city: 'Dover',
    state: 'KS',
    latitude: '38.970459',
    longitude: '-95.932794',
    timeZoneId: 'America/Chicago',
  },
  '66422': {
    zip: '66422',
    city: 'Emmett',
    state: 'KS',
    latitude: '39.30902',
    longitude: '-96.056094',
    timeZoneId: 'America/Chicago',
  },
  '66423': {
    zip: '66423',
    city: 'Eskridge',
    state: 'KS',
    latitude: '38.858181',
    longitude: '-96.104822',
    timeZoneId: 'America/Chicago',
  },
  '66424': {
    zip: '66424',
    city: 'Everest',
    state: 'KS',
    latitude: '39.707519',
    longitude: '-95.396757',
    timeZoneId: 'America/Chicago',
  },
  '66425': {
    zip: '66425',
    city: 'Fairview',
    state: 'KS',
    latitude: '39.848405',
    longitude: '-95.704379',
    timeZoneId: 'America/Chicago',
  },
  '66426': {
    zip: '66426',
    city: 'Fostoria',
    state: 'KS',
    latitude: '39.427312',
    longitude: '-96.491448',
    timeZoneId: 'America/Chicago',
  },
  '66427': {
    zip: '66427',
    city: 'Frankfort',
    state: 'KS',
    latitude: '39.705297',
    longitude: '-96.415675',
    timeZoneId: 'America/Chicago',
  },
  '66428': {
    zip: '66428',
    city: 'Goff',
    state: 'KS',
    latitude: '39.651949',
    longitude: '-95.95755',
    timeZoneId: 'America/Chicago',
  },
  '66429': {
    zip: '66429',
    city: 'Grantville',
    state: 'KS',
    latitude: '39.094005',
    longitude: '-95.527752',
    timeZoneId: 'America/Chicago',
  },
  '66431': {
    zip: '66431',
    city: 'Harveyville',
    state: 'KS',
    latitude: '38.791821',
    longitude: '-95.961435',
    timeZoneId: 'America/Chicago',
  },
  '66432': {
    zip: '66432',
    city: 'Havensville',
    state: 'KS',
    latitude: '39.517992',
    longitude: '-96.120433',
    timeZoneId: 'America/Chicago',
  },
  '66434': {
    zip: '66434',
    city: 'Hiawatha',
    state: 'KS',
    latitude: '39.854303',
    longitude: '-95.534279',
    timeZoneId: 'America/Chicago',
  },
  '66436': {
    zip: '66436',
    city: 'Holton',
    state: 'KS',
    latitude: '39.465523',
    longitude: '-95.740235',
    timeZoneId: 'America/Chicago',
  },
  '66438': {
    zip: '66438',
    city: 'Home',
    state: 'KS',
    latitude: '39.839077',
    longitude: '-96.523842',
    timeZoneId: 'America/Chicago',
  },
  '66439': {
    zip: '66439',
    city: 'Horton',
    state: 'KS',
    latitude: '39.663723',
    longitude: '-95.526639',
    timeZoneId: 'America/Chicago',
  },
  '66440': {
    zip: '66440',
    city: 'Hoyt',
    state: 'KS',
    latitude: '39.247703',
    longitude: '-95.704724',
    timeZoneId: 'America/Chicago',
  },
  '66441': {
    zip: '66441',
    city: 'Junction City',
    state: 'KS',
    latitude: '39.0268',
    longitude: '-96.836313',
    timeZoneId: 'America/Chicago',
  },
  '66442': {
    zip: '66442',
    city: 'Fort Riley',
    state: 'KS',
    latitude: '39.05664',
    longitude: '-96.77022',
    timeZoneId: 'America/Chicago',
  },
  '66449': {
    zip: '66449',
    city: 'Leonardville',
    state: 'KS',
    latitude: '39.360412',
    longitude: '-96.832043',
    timeZoneId: 'America/Chicago',
  },
  '66451': {
    zip: '66451',
    city: 'Lyndon',
    state: 'KS',
    latitude: '38.611022',
    longitude: '-95.685015',
    timeZoneId: 'America/Chicago',
  },
  '66501': {
    zip: '66501',
    city: 'Mc Farland',
    state: 'KS',
    latitude: '39.052131',
    longitude: '-96.237299',
    timeZoneId: 'America/Chicago',
  },
  '66502': {
    zip: '66502',
    city: 'Manhattan',
    state: 'KS',
    latitude: '39.197419',
    longitude: '-96.59728',
    timeZoneId: 'America/Chicago',
  },
  '66503': {
    zip: '66503',
    city: 'Manhattan',
    state: 'KS',
    latitude: '39.209573',
    longitude: '-96.640658',
    timeZoneId: 'America/Chicago',
  },
  '66505': {
    zip: '66505',
    city: 'Manhattan',
    state: 'KS',
    latitude: '39.188109',
    longitude: '-96.590646',
    timeZoneId: 'America/Chicago',
  },
  '66506': {
    zip: '66506',
    city: 'Manhattan',
    state: 'KS',
    latitude: '39.185143',
    longitude: '-96.571471',
    timeZoneId: 'America/Chicago',
  },
  '66507': {
    zip: '66507',
    city: 'Maple Hill',
    state: 'KS',
    latitude: '39.084901',
    longitude: '-96.027133',
    timeZoneId: 'America/Chicago',
  },
  '66508': {
    zip: '66508',
    city: 'Marysville',
    state: 'KS',
    latitude: '39.846378',
    longitude: '-96.645294',
    timeZoneId: 'America/Chicago',
  },
  '66509': {
    zip: '66509',
    city: 'Mayetta',
    state: 'KS',
    latitude: '39.339878',
    longitude: '-95.717014',
    timeZoneId: 'America/Chicago',
  },
  '66510': {
    zip: '66510',
    city: 'Melvern',
    state: 'KS',
    latitude: '38.506646',
    longitude: '-95.635334',
    timeZoneId: 'America/Chicago',
  },
  '66512': {
    zip: '66512',
    city: 'Meriden',
    state: 'KS',
    latitude: '39.188271',
    longitude: '-95.569174',
    timeZoneId: 'America/Chicago',
  },
  '66514': {
    zip: '66514',
    city: 'Milford',
    state: 'KS',
    latitude: '39.173318',
    longitude: '-96.914453',
    timeZoneId: 'America/Chicago',
  },
  '66515': {
    zip: '66515',
    city: 'Morrill',
    state: 'KS',
    latitude: '39.950909',
    longitude: '-95.727527',
    timeZoneId: 'America/Chicago',
  },
  '66516': {
    zip: '66516',
    city: 'Netawaka',
    state: 'KS',
    latitude: '39.607386',
    longitude: '-95.734371',
    timeZoneId: 'America/Chicago',
  },
  '66517': {
    zip: '66517',
    city: 'Ogden',
    state: 'KS',
    latitude: '39.114104',
    longitude: '-96.708239',
    timeZoneId: 'America/Chicago',
  },
  '66518': {
    zip: '66518',
    city: 'Oketo',
    state: 'KS',
    latitude: '39.961003',
    longitude: '-96.635095',
    timeZoneId: 'America/Chicago',
  },
  '66520': {
    zip: '66520',
    city: 'Olsburg',
    state: 'KS',
    latitude: '39.415248',
    longitude: '-96.599074',
    timeZoneId: 'America/Chicago',
  },
  '66521': {
    zip: '66521',
    city: 'Onaga',
    state: 'KS',
    latitude: '39.491343',
    longitude: '-96.171545',
    timeZoneId: 'America/Chicago',
  },
  '66522': {
    zip: '66522',
    city: 'Oneida',
    state: 'KS',
    latitude: '39.871331',
    longitude: '-95.957222',
    timeZoneId: 'America/Chicago',
  },
  '66523': {
    zip: '66523',
    city: 'Osage City',
    state: 'KS',
    latitude: '38.631972',
    longitude: '-95.827197',
    timeZoneId: 'America/Chicago',
  },
  '66524': {
    zip: '66524',
    city: 'Overbrook',
    state: 'KS',
    latitude: '38.773836',
    longitude: '-95.558779',
    timeZoneId: 'America/Chicago',
  },
  '66526': {
    zip: '66526',
    city: 'Paxico',
    state: 'KS',
    latitude: '39.070019',
    longitude: '-96.16887',
    timeZoneId: 'America/Chicago',
  },
  '66527': {
    zip: '66527',
    city: 'Powhattan',
    state: 'KS',
    latitude: '39.76174',
    longitude: '-95.63331',
    timeZoneId: 'America/Chicago',
  },
  '66528': {
    zip: '66528',
    city: 'Quenemo',
    state: 'KS',
    latitude: '38.582564',
    longitude: '-95.529845',
    timeZoneId: 'America/Chicago',
  },
  '66531': {
    zip: '66531',
    city: 'Riley',
    state: 'KS',
    latitude: '39.294454',
    longitude: '-96.813036',
    timeZoneId: 'America/Chicago',
  },
  '66532': {
    zip: '66532',
    city: 'Robinson',
    state: 'KS',
    latitude: '39.8169',
    longitude: '-95.39382',
    timeZoneId: 'America/Chicago',
  },
  '66533': {
    zip: '66533',
    city: 'Rossville',
    state: 'KS',
    latitude: '39.152719',
    longitude: '-95.965164',
    timeZoneId: 'America/Chicago',
  },
  '66534': {
    zip: '66534',
    city: 'Sabetha',
    state: 'KS',
    latitude: '39.901237',
    longitude: '-95.797438',
    timeZoneId: 'America/Chicago',
  },
  '66535': {
    zip: '66535',
    city: 'Saint George',
    state: 'KS',
    latitude: '39.224971',
    longitude: '-96.438051',
    timeZoneId: 'America/Chicago',
  },
  '66536': {
    zip: '66536',
    city: 'Saint Marys',
    state: 'KS',
    latitude: '39.194394',
    longitude: '-96.06484',
    timeZoneId: 'America/Chicago',
  },
  '66537': {
    zip: '66537',
    city: 'Scranton',
    state: 'KS',
    latitude: '38.780681',
    longitude: '-95.736223',
    timeZoneId: 'America/Chicago',
  },
  '66538': {
    zip: '66538',
    city: 'Seneca',
    state: 'KS',
    latitude: '39.83664',
    longitude: '-96.06108',
    timeZoneId: 'America/Chicago',
  },
  '66539': {
    zip: '66539',
    city: 'Silver Lake',
    state: 'KS',
    latitude: '39.142234',
    longitude: '-95.865541',
    timeZoneId: 'America/Chicago',
  },
  '66540': {
    zip: '66540',
    city: 'Soldier',
    state: 'KS',
    latitude: '39.53633',
    longitude: '-95.96546',
    timeZoneId: 'America/Chicago',
  },
  '66541': {
    zip: '66541',
    city: 'Summerfield',
    state: 'KS',
    latitude: '39.956625',
    longitude: '-96.297143',
    timeZoneId: 'America/Chicago',
  },
  '66542': {
    zip: '66542',
    city: 'Tecumseh',
    state: 'KS',
    latitude: '39.020394',
    longitude: '-95.553498',
    timeZoneId: 'America/Chicago',
  },
  '66543': {
    zip: '66543',
    city: 'Vassar',
    state: 'KS',
    latitude: '38.648951',
    longitude: '-95.621088',
    timeZoneId: 'America/Chicago',
  },
  '66544': {
    zip: '66544',
    city: 'Vermillion',
    state: 'KS',
    latitude: '39.691182',
    longitude: '-96.283657',
    timeZoneId: 'America/Chicago',
  },
  '66546': {
    zip: '66546',
    city: 'Wakarusa',
    state: 'KS',
    latitude: '38.894226',
    longitude: '-95.710815',
    timeZoneId: 'America/Chicago',
  },
  '66547': {
    zip: '66547',
    city: 'Wamego',
    state: 'KS',
    latitude: '39.211156',
    longitude: '-96.316796',
    timeZoneId: 'America/Chicago',
  },
  '66548': {
    zip: '66548',
    city: 'Waterville',
    state: 'KS',
    latitude: '39.688984',
    longitude: '-96.749155',
    timeZoneId: 'America/Chicago',
  },
  '66549': {
    zip: '66549',
    city: 'Westmoreland',
    state: 'KS',
    latitude: '39.474166',
    longitude: '-96.415994',
    timeZoneId: 'America/Chicago',
  },
  '66550': {
    zip: '66550',
    city: 'Wetmore',
    state: 'KS',
    latitude: '39.653054',
    longitude: '-95.846655',
    timeZoneId: 'America/Chicago',
  },
  '66552': {
    zip: '66552',
    city: 'Whiting',
    state: 'KS',
    latitude: '39.612836',
    longitude: '-95.621934',
    timeZoneId: 'America/Chicago',
  },
  '66554': {
    zip: '66554',
    city: 'Randolph',
    state: 'KS',
    latitude: '39.419266',
    longitude: '-96.771329',
    timeZoneId: 'America/Chicago',
  },
  '66601': {
    zip: '66601',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.057109',
    longitude: '-95.676547',
    timeZoneId: 'America/Chicago',
  },
  '66603': {
    zip: '66603',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.058392',
    longitude: '-95.676444',
    timeZoneId: 'America/Chicago',
  },
  '66604': {
    zip: '66604',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.0412',
    longitude: '-95.728358',
    timeZoneId: 'America/Chicago',
  },
  '66605': {
    zip: '66605',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.014671',
    longitude: '-95.635278',
    timeZoneId: 'America/Chicago',
  },
  '66606': {
    zip: '66606',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.060395',
    longitude: '-95.716596',
    timeZoneId: 'America/Chicago',
  },
  '66607': {
    zip: '66607',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.041009',
    longitude: '-95.642616',
    timeZoneId: 'America/Chicago',
  },
  '66608': {
    zip: '66608',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.074827',
    longitude: '-95.666827',
    timeZoneId: 'America/Chicago',
  },
  '66609': {
    zip: '66609',
    city: 'Topeka',
    state: 'KS',
    latitude: '38.991057',
    longitude: '-95.65453',
    timeZoneId: 'America/Chicago',
  },
  '66610': {
    zip: '66610',
    city: 'Topeka',
    state: 'KS',
    latitude: '38.981187',
    longitude: '-95.797562',
    timeZoneId: 'America/Chicago',
  },
  '66611': {
    zip: '66611',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.016771',
    longitude: '-95.696466',
    timeZoneId: 'America/Chicago',
  },
  '66612': {
    zip: '66612',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.039267',
    longitude: '-95.677633',
    timeZoneId: 'America/Chicago',
  },
  '66614': {
    zip: '66614',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.016655',
    longitude: '-95.766357',
    timeZoneId: 'America/Chicago',
  },
  '66615': {
    zip: '66615',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.054663',
    longitude: '-95.853761',
    timeZoneId: 'America/Chicago',
  },
  '66616': {
    zip: '66616',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.065147',
    longitude: '-95.638885',
    timeZoneId: 'America/Chicago',
  },
  '66617': {
    zip: '66617',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.128896',
    longitude: '-95.639498',
    timeZoneId: 'America/Chicago',
  },
  '66618': {
    zip: '66618',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.135789',
    longitude: '-95.731085',
    timeZoneId: 'America/Chicago',
  },
  '66619': {
    zip: '66619',
    city: 'Topeka',
    state: 'KS',
    latitude: '38.952172',
    longitude: '-95.713215',
    timeZoneId: 'America/Chicago',
  },
  '66620': {
    zip: '66620',
    city: 'Topeka',
    state: 'KS',
    latitude: '38.946624',
    longitude: '-95.715745',
    timeZoneId: 'America/Chicago',
  },
  '66621': {
    zip: '66621',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.043224',
    longitude: '-95.726236',
    timeZoneId: 'America/Chicago',
  },
  '66622': {
    zip: '66622',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.012994',
    longitude: '-95.824145',
    timeZoneId: 'America/Chicago',
  },
  '66624': {
    zip: '66624',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.016351',
    longitude: '-95.672071',
    timeZoneId: 'America/Chicago',
  },
  '66625': {
    zip: '66625',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.047811',
    longitude: '-95.683248',
    timeZoneId: 'America/Chicago',
  },
  '66626': {
    zip: '66626',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.047246',
    longitude: '-95.68282',
    timeZoneId: 'America/Chicago',
  },
  '66629': {
    zip: '66629',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.048063',
    longitude: '-95.682616',
    timeZoneId: 'America/Chicago',
  },
  '66630': {
    zip: '66630',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.021734',
    longitude: '-95.713905',
    timeZoneId: 'America/Chicago',
  },
  '66636': {
    zip: '66636',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.056337',
    longitude: '-95.675658',
    timeZoneId: 'America/Chicago',
  },
  '66647': {
    zip: '66647',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.03883',
    longitude: '-95.725226',
    timeZoneId: 'America/Chicago',
  },
  '66667': {
    zip: '66667',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.041848',
    longitude: '-95.727354',
    timeZoneId: 'America/Chicago',
  },
  '66675': {
    zip: '66675',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.068882',
    longitude: '-95.666739',
    timeZoneId: 'America/Chicago',
  },
  '66683': {
    zip: '66683',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.055847',
    longitude: '-95.673223',
    timeZoneId: 'America/Chicago',
  },
  '66699': {
    zip: '66699',
    city: 'Topeka',
    state: 'KS',
    latitude: '39.053504',
    longitude: '-95.673075',
    timeZoneId: 'America/Chicago',
  },
  '66701': {
    zip: '66701',
    city: 'Fort Scott',
    state: 'KS',
    latitude: '37.82274',
    longitude: '-94.70154',
    timeZoneId: 'America/Chicago',
  },
  '66710': {
    zip: '66710',
    city: 'Altoona',
    state: 'KS',
    latitude: '37.520445',
    longitude: '-95.658609',
    timeZoneId: 'America/Chicago',
  },
  '66711': {
    zip: '66711',
    city: 'Arcadia',
    state: 'KS',
    latitude: '37.625557',
    longitude: '-94.701799',
    timeZoneId: 'America/Chicago',
  },
  '66712': {
    zip: '66712',
    city: 'Arma',
    state: 'KS',
    latitude: '37.566997',
    longitude: '-94.729298',
    timeZoneId: 'America/Chicago',
  },
  '66713': {
    zip: '66713',
    city: 'Baxter Springs',
    state: 'KS',
    latitude: '37.022713',
    longitude: '-94.73915',
    timeZoneId: 'America/Chicago',
  },
  '66714': {
    zip: '66714',
    city: 'Benedict',
    state: 'KS',
    latitude: '37.635924',
    longitude: '-95.634951',
    timeZoneId: 'America/Chicago',
  },
  '66716': {
    zip: '66716',
    city: 'Bronson',
    state: 'KS',
    latitude: '37.945353',
    longitude: '-94.999462',
    timeZoneId: 'America/Chicago',
  },
  '66717': {
    zip: '66717',
    city: 'Buffalo',
    state: 'KS',
    latitude: '37.689303',
    longitude: '-95.723736',
    timeZoneId: 'America/Chicago',
  },
  '66720': {
    zip: '66720',
    city: 'Chanute',
    state: 'KS',
    latitude: '37.64082',
    longitude: '-95.46498',
    timeZoneId: 'America/Chicago',
  },
  '66724': {
    zip: '66724',
    city: 'Cherokee',
    state: 'KS',
    latitude: '37.34247',
    longitude: '-94.830186',
    timeZoneId: 'America/Chicago',
  },
  '66725': {
    zip: '66725',
    city: 'Columbus',
    state: 'KS',
    latitude: '37.167381',
    longitude: '-94.844493',
    timeZoneId: 'America/Chicago',
  },
  '66728': {
    zip: '66728',
    city: 'Crestline',
    state: 'KS',
    latitude: '37.158411',
    longitude: '-94.669975',
    timeZoneId: 'America/Chicago',
  },
  '66732': {
    zip: '66732',
    city: 'Elsmore',
    state: 'KS',
    latitude: '37.816229',
    longitude: '-95.157167',
    timeZoneId: 'America/Chicago',
  },
  '66733': {
    zip: '66733',
    city: 'Erie',
    state: 'KS',
    latitude: '37.567699',
    longitude: '-95.244417',
    timeZoneId: 'America/Chicago',
  },
  '66734': {
    zip: '66734',
    city: 'Farlington',
    state: 'KS',
    latitude: '37.618046',
    longitude: '-94.863425',
    timeZoneId: 'America/Chicago',
  },
  '66735': {
    zip: '66735',
    city: 'Franklin',
    state: 'KS',
    latitude: '37.526013',
    longitude: '-94.712368',
    timeZoneId: 'America/Chicago',
  },
  '66736': {
    zip: '66736',
    city: 'Fredonia',
    state: 'KS',
    latitude: '37.535127',
    longitude: '-95.823027',
    timeZoneId: 'America/Chicago',
  },
  '66738': {
    zip: '66738',
    city: 'Fulton',
    state: 'KS',
    latitude: '38.009208',
    longitude: '-94.718191',
    timeZoneId: 'America/Chicago',
  },
  '66739': {
    zip: '66739',
    city: 'Galena',
    state: 'KS',
    latitude: '37.06939',
    longitude: '-94.633351',
    timeZoneId: 'America/Chicago',
  },
  '66740': {
    zip: '66740',
    city: 'Galesburg',
    state: 'KS',
    latitude: '37.467613',
    longitude: '-95.309733',
    timeZoneId: 'America/Chicago',
  },
  '66741': {
    zip: '66741',
    city: 'Garland',
    state: 'KS',
    latitude: '37.714958',
    longitude: '-94.683076',
    timeZoneId: 'America/Chicago',
  },
  '66742': {
    zip: '66742',
    city: 'Gas',
    state: 'KS',
    latitude: '37.924172',
    longitude: '-95.347532',
    timeZoneId: 'America/Chicago',
  },
  '66743': {
    zip: '66743',
    city: 'Girard',
    state: 'KS',
    latitude: '37.50696',
    longitude: '-94.86396',
    timeZoneId: 'America/Chicago',
  },
  '66746': {
    zip: '66746',
    city: 'Hepler',
    state: 'KS',
    latitude: '37.653504',
    longitude: '-95.016741',
    timeZoneId: 'America/Chicago',
  },
  '66748': {
    zip: '66748',
    city: 'Humboldt',
    state: 'KS',
    latitude: '37.812264',
    longitude: '-95.433752',
    timeZoneId: 'America/Chicago',
  },
  '66749': {
    zip: '66749',
    city: 'Iola',
    state: 'KS',
    latitude: '37.92648',
    longitude: '-95.40282',
    timeZoneId: 'America/Chicago',
  },
  '66751': {
    zip: '66751',
    city: 'La Harpe',
    state: 'KS',
    latitude: '37.917335',
    longitude: '-95.313982',
    timeZoneId: 'America/Chicago',
  },
  '66753': {
    zip: '66753',
    city: 'Mc Cune',
    state: 'KS',
    latitude: '37.352484',
    longitude: '-95.021069',
    timeZoneId: 'America/Chicago',
  },
  '66754': {
    zip: '66754',
    city: 'Mapleton',
    state: 'KS',
    latitude: '38.017399',
    longitude: '-94.876798',
    timeZoneId: 'America/Chicago',
  },
  '66755': {
    zip: '66755',
    city: 'Moran',
    state: 'KS',
    latitude: '37.943643',
    longitude: '-95.150312',
    timeZoneId: 'America/Chicago',
  },
  '66756': {
    zip: '66756',
    city: 'Mulberry',
    state: 'KS',
    latitude: '37.549625',
    longitude: '-94.679242',
    timeZoneId: 'America/Chicago',
  },
  '66757': {
    zip: '66757',
    city: 'Neodesha',
    state: 'KS',
    latitude: '37.428154',
    longitude: '-95.680875',
    timeZoneId: 'America/Chicago',
  },
  '66758': {
    zip: '66758',
    city: 'Neosho Falls',
    state: 'KS',
    latitude: '37.972738',
    longitude: '-95.553451',
    timeZoneId: 'America/Chicago',
  },
  '66760': {
    zip: '66760',
    city: 'Opolis',
    state: 'KS',
    latitude: '37.347211',
    longitude: '-94.619694',
    timeZoneId: 'America/Chicago',
  },
  '66761': {
    zip: '66761',
    city: 'Piqua',
    state: 'KS',
    latitude: '37.86557',
    longitude: '-95.589348',
    timeZoneId: 'America/Chicago',
  },
  '66762': {
    zip: '66762',
    city: 'Pittsburg',
    state: 'KS',
    latitude: '37.412275',
    longitude: '-94.698359',
    timeZoneId: 'America/Chicago',
  },
  '66763': {
    zip: '66763',
    city: 'Frontenac',
    state: 'KS',
    latitude: '37.452009',
    longitude: '-94.696379',
    timeZoneId: 'America/Chicago',
  },
  '66767': {
    zip: '66767',
    city: 'Prescott',
    state: 'KS',
    latitude: '38.180379',
    longitude: '-94.704409',
    timeZoneId: 'America/Chicago',
  },
  '66769': {
    zip: '66769',
    city: 'Redfield',
    state: 'KS',
    latitude: '37.86052',
    longitude: '-94.831824',
    timeZoneId: 'America/Chicago',
  },
  '66770': {
    zip: '66770',
    city: 'Riverton',
    state: 'KS',
    latitude: '37.102246',
    longitude: '-94.669909',
    timeZoneId: 'America/Chicago',
  },
  '66771': {
    zip: '66771',
    city: 'Saint Paul',
    state: 'KS',
    latitude: '37.517288',
    longitude: '-95.169242',
    timeZoneId: 'America/Chicago',
  },
  '66772': {
    zip: '66772',
    city: 'Savonburg',
    state: 'KS',
    latitude: '37.75578',
    longitude: '-95.159662',
    timeZoneId: 'America/Chicago',
  },
  '66773': {
    zip: '66773',
    city: 'Scammon',
    state: 'KS',
    latitude: '37.267281',
    longitude: '-94.853706',
    timeZoneId: 'America/Chicago',
  },
  '66775': {
    zip: '66775',
    city: 'Stark',
    state: 'KS',
    latitude: '37.688022',
    longitude: '-95.127942',
    timeZoneId: 'America/Chicago',
  },
  '66776': {
    zip: '66776',
    city: 'Thayer',
    state: 'KS',
    latitude: '37.489369',
    longitude: '-95.476701',
    timeZoneId: 'America/Chicago',
  },
  '66777': {
    zip: '66777',
    city: 'Toronto',
    state: 'KS',
    latitude: '37.800799',
    longitude: '-95.947736',
    timeZoneId: 'America/Chicago',
  },
  '66778': {
    zip: '66778',
    city: 'Treece',
    state: 'KS',
    latitude: '37.003569',
    longitude: '-94.844982',
    timeZoneId: 'America/Chicago',
  },
  '66779': {
    zip: '66779',
    city: 'Uniontown',
    state: 'KS',
    latitude: '37.765781',
    longitude: '-94.991098',
    timeZoneId: 'America/Chicago',
  },
  '66780': {
    zip: '66780',
    city: 'Walnut',
    state: 'KS',
    latitude: '37.600473',
    longitude: '-95.081169',
    timeZoneId: 'America/Chicago',
  },
  '66781': {
    zip: '66781',
    city: 'Weir',
    state: 'KS',
    latitude: '37.29804',
    longitude: '-94.755',
    timeZoneId: 'America/Chicago',
  },
  '66782': {
    zip: '66782',
    city: 'West Mineral',
    state: 'KS',
    latitude: '37.286058',
    longitude: '-94.924505',
    timeZoneId: 'America/Chicago',
  },
  '66783': {
    zip: '66783',
    city: 'Yates Center',
    state: 'KS',
    latitude: '37.881629',
    longitude: '-95.728588',
    timeZoneId: 'America/Chicago',
  },
  '66801': {
    zip: '66801',
    city: 'Emporia',
    state: 'KS',
    latitude: '38.409293',
    longitude: '-96.189099',
    timeZoneId: 'America/Chicago',
  },
  '66830': {
    zip: '66830',
    city: 'Admire',
    state: 'KS',
    latitude: '38.638623',
    longitude: '-96.09905',
    timeZoneId: 'America/Chicago',
  },
  '66833': {
    zip: '66833',
    city: 'Allen',
    state: 'KS',
    latitude: '38.6415',
    longitude: '-96.25362',
    timeZoneId: 'America/Chicago',
  },
  '66834': {
    zip: '66834',
    city: 'Alta Vista',
    state: 'KS',
    latitude: '38.866739',
    longitude: '-96.48822',
    timeZoneId: 'America/Chicago',
  },
  '66835': {
    zip: '66835',
    city: 'Americus',
    state: 'KS',
    latitude: '38.505257',
    longitude: '-96.263158',
    timeZoneId: 'America/Chicago',
  },
  '66838': {
    zip: '66838',
    city: 'Burdick',
    state: 'KS',
    latitude: '38.567261',
    longitude: '-96.820334',
    timeZoneId: 'America/Chicago',
  },
  '66839': {
    zip: '66839',
    city: 'Burlington',
    state: 'KS',
    latitude: '38.195231',
    longitude: '-95.743973',
    timeZoneId: 'America/Chicago',
  },
  '66840': {
    zip: '66840',
    city: 'Burns',
    state: 'KS',
    latitude: '38.086035',
    longitude: '-96.884066',
    timeZoneId: 'America/Chicago',
  },
  '66842': {
    zip: '66842',
    city: 'Cassoday',
    state: 'KS',
    latitude: '38.030185',
    longitude: '-96.651453',
    timeZoneId: 'America/Chicago',
  },
  '66843': {
    zip: '66843',
    city: 'Cedar Point',
    state: 'KS',
    latitude: '38.287465',
    longitude: '-96.749099',
    timeZoneId: 'America/Chicago',
  },
  '66845': {
    zip: '66845',
    city: 'Cottonwood Falls',
    state: 'KS',
    latitude: '38.365873',
    longitude: '-96.545943',
    timeZoneId: 'America/Chicago',
  },
  '66846': {
    zip: '66846',
    city: 'Council Grove',
    state: 'KS',
    latitude: '38.660778',
    longitude: '-96.489119',
    timeZoneId: 'America/Chicago',
  },
  '66849': {
    zip: '66849',
    city: 'Dwight',
    state: 'KS',
    latitude: '38.828275',
    longitude: '-96.563586',
    timeZoneId: 'America/Chicago',
  },
  '66850': {
    zip: '66850',
    city: 'Elmdale',
    state: 'KS',
    latitude: '38.380293',
    longitude: '-96.713873',
    timeZoneId: 'America/Chicago',
  },
  '66851': {
    zip: '66851',
    city: 'Florence',
    state: 'KS',
    latitude: '38.241223',
    longitude: '-96.926666',
    timeZoneId: 'America/Chicago',
  },
  '66852': {
    zip: '66852',
    city: 'Gridley',
    state: 'KS',
    latitude: '38.095862',
    longitude: '-95.882201',
    timeZoneId: 'America/Chicago',
  },
  '66853': {
    zip: '66853',
    city: 'Hamilton',
    state: 'KS',
    latitude: '37.986532',
    longitude: '-96.199761',
    timeZoneId: 'America/Chicago',
  },
  '66854': {
    zip: '66854',
    city: 'Hartford',
    state: 'KS',
    latitude: '38.30711',
    longitude: '-95.957044',
    timeZoneId: 'America/Chicago',
  },
  '66855': {
    zip: '66855',
    city: 'Lamont',
    state: 'KS',
    latitude: '38.116882',
    longitude: '-96.022522',
    timeZoneId: 'America/Chicago',
  },
  '66856': {
    zip: '66856',
    city: 'Lebo',
    state: 'KS',
    latitude: '38.389155',
    longitude: '-95.844704',
    timeZoneId: 'America/Chicago',
  },
  '66857': {
    zip: '66857',
    city: 'Le Roy',
    state: 'KS',
    latitude: '38.083205',
    longitude: '-95.630882',
    timeZoneId: 'America/Chicago',
  },
  '66858': {
    zip: '66858',
    city: 'Lincolnville',
    state: 'KS',
    latitude: '38.48526',
    longitude: '-96.9579',
    timeZoneId: 'America/Chicago',
  },
  '66859': {
    zip: '66859',
    city: 'Lost Springs',
    state: 'KS',
    latitude: '38.566381',
    longitude: '-96.986853',
    timeZoneId: 'America/Chicago',
  },
  '66860': {
    zip: '66860',
    city: 'Madison',
    state: 'KS',
    latitude: '38.136417',
    longitude: '-96.13424',
    timeZoneId: 'America/Chicago',
  },
  '66861': {
    zip: '66861',
    city: 'Marion',
    state: 'KS',
    latitude: '38.348861',
    longitude: '-97.015964',
    timeZoneId: 'America/Chicago',
  },
  '66862': {
    zip: '66862',
    city: 'Matfield Green',
    state: 'KS',
    latitude: '38.143372',
    longitude: '-96.497559',
    timeZoneId: 'America/Chicago',
  },
  '66863': {
    zip: '66863',
    city: 'Neal',
    state: 'KS',
    latitude: '37.838379',
    longitude: '-96.078599',
    timeZoneId: 'America/Chicago',
  },
  '66864': {
    zip: '66864',
    city: 'Neosho Rapids',
    state: 'KS',
    latitude: '38.397821',
    longitude: '-96.025281',
    timeZoneId: 'America/Chicago',
  },
  '66865': {
    zip: '66865',
    city: 'Olpe',
    state: 'KS',
    latitude: '38.246277',
    longitude: '-96.25135',
    timeZoneId: 'America/Chicago',
  },
  '66866': {
    zip: '66866',
    city: 'Peabody',
    state: 'KS',
    latitude: '38.170926',
    longitude: '-97.109433',
    timeZoneId: 'America/Chicago',
  },
  '66868': {
    zip: '66868',
    city: 'Reading',
    state: 'KS',
    latitude: '38.542055',
    longitude: '-96.020909',
    timeZoneId: 'America/Chicago',
  },
  '66869': {
    zip: '66869',
    city: 'Strong City',
    state: 'KS',
    latitude: '38.395198',
    longitude: '-96.536456',
    timeZoneId: 'America/Chicago',
  },
  '66870': {
    zip: '66870',
    city: 'Virgil',
    state: 'KS',
    latitude: '37.869892',
    longitude: '-96.036586',
    timeZoneId: 'America/Chicago',
  },
  '66871': {
    zip: '66871',
    city: 'Waverly',
    state: 'KS',
    latitude: '38.387387',
    longitude: '-95.596753',
    timeZoneId: 'America/Chicago',
  },
  '66872': {
    zip: '66872',
    city: 'White City',
    state: 'KS',
    latitude: '38.797402',
    longitude: '-96.733987',
    timeZoneId: 'America/Chicago',
  },
  '66873': {
    zip: '66873',
    city: 'Wilsey',
    state: 'KS',
    latitude: '38.654363',
    longitude: '-96.693462',
    timeZoneId: 'America/Chicago',
  },
  '66901': {
    zip: '66901',
    city: 'Concordia',
    state: 'KS',
    latitude: '39.567727',
    longitude: '-97.660244',
    timeZoneId: 'America/Chicago',
  },
  '66930': {
    zip: '66930',
    city: 'Agenda',
    state: 'KS',
    latitude: '39.694671',
    longitude: '-97.426245',
    timeZoneId: 'America/Chicago',
  },
  '66932': {
    zip: '66932',
    city: 'Athol',
    state: 'KS',
    latitude: '39.810288',
    longitude: '-98.557279',
    timeZoneId: 'America/Chicago',
  },
  '66933': {
    zip: '66933',
    city: 'Barnes',
    state: 'KS',
    latitude: '39.654157',
    longitude: '-96.862314',
    timeZoneId: 'America/Chicago',
  },
  '66935': {
    zip: '66935',
    city: 'Belleville',
    state: 'KS',
    latitude: '39.822698',
    longitude: '-97.630773',
    timeZoneId: 'America/Chicago',
  },
  '66936': {
    zip: '66936',
    city: 'Burr Oak',
    state: 'KS',
    latitude: '39.91632',
    longitude: '-98.385571',
    timeZoneId: 'America/Chicago',
  },
  '66937': {
    zip: '66937',
    city: 'Clifton',
    state: 'KS',
    latitude: '39.563357',
    longitude: '-97.27897',
    timeZoneId: 'America/Chicago',
  },
  '66938': {
    zip: '66938',
    city: 'Clyde',
    state: 'KS',
    latitude: '39.593388',
    longitude: '-97.396828',
    timeZoneId: 'America/Chicago',
  },
  '66939': {
    zip: '66939',
    city: 'Courtland',
    state: 'KS',
    latitude: '39.784496',
    longitude: '-97.89528',
    timeZoneId: 'America/Chicago',
  },
  '66940': {
    zip: '66940',
    city: 'Cuba',
    state: 'KS',
    latitude: '39.78616',
    longitude: '-97.423643',
    timeZoneId: 'America/Chicago',
  },
  '66941': {
    zip: '66941',
    city: 'Esbon',
    state: 'KS',
    latitude: '39.825092',
    longitude: '-98.429205',
    timeZoneId: 'America/Chicago',
  },
  '66942': {
    zip: '66942',
    city: 'Formoso',
    state: 'KS',
    latitude: '39.775298',
    longitude: '-97.994109',
    timeZoneId: 'America/Chicago',
  },
  '66943': {
    zip: '66943',
    city: 'Greenleaf',
    state: 'KS',
    latitude: '39.726397',
    longitude: '-96.977347',
    timeZoneId: 'America/Chicago',
  },
  '66944': {
    zip: '66944',
    city: 'Haddam',
    state: 'KS',
    latitude: '39.855669',
    longitude: '-97.302858',
    timeZoneId: 'America/Chicago',
  },
  '66945': {
    zip: '66945',
    city: 'Hanover',
    state: 'KS',
    latitude: '39.88959',
    longitude: '-96.887758',
    timeZoneId: 'America/Chicago',
  },
  '66946': {
    zip: '66946',
    city: 'Hollenberg',
    state: 'KS',
    latitude: '39.955656',
    longitude: '-96.972145',
    timeZoneId: 'America/Chicago',
  },
  '66948': {
    zip: '66948',
    city: 'Jamestown',
    state: 'KS',
    latitude: '39.600603',
    longitude: '-97.857281',
    timeZoneId: 'America/Chicago',
  },
  '66949': {
    zip: '66949',
    city: 'Jewell',
    state: 'KS',
    latitude: '39.67218',
    longitude: '-98.10882',
    timeZoneId: 'America/Chicago',
  },
  '66951': {
    zip: '66951',
    city: 'Kensington',
    state: 'KS',
    latitude: '39.766393',
    longitude: '-99.03329',
    timeZoneId: 'America/Chicago',
  },
  '66952': {
    zip: '66952',
    city: 'Lebanon',
    state: 'KS',
    latitude: '39.813397',
    longitude: '-98.555445',
    timeZoneId: 'America/Chicago',
  },
  '66953': {
    zip: '66953',
    city: 'Linn',
    state: 'KS',
    latitude: '39.686371',
    longitude: '-97.07453',
    timeZoneId: 'America/Chicago',
  },
  '66955': {
    zip: '66955',
    city: 'Mahaska',
    state: 'KS',
    latitude: '39.958442',
    longitude: '-97.312093',
    timeZoneId: 'America/Chicago',
  },
  '66956': {
    zip: '66956',
    city: 'Mankato',
    state: 'KS',
    latitude: '39.789686',
    longitude: '-98.211883',
    timeZoneId: 'America/Chicago',
  },
  '66958': {
    zip: '66958',
    city: 'Morrowville',
    state: 'KS',
    latitude: '39.842905',
    longitude: '-97.169596',
    timeZoneId: 'America/Chicago',
  },
  '66959': {
    zip: '66959',
    city: 'Munden',
    state: 'KS',
    latitude: '39.901903',
    longitude: '-97.534541',
    timeZoneId: 'America/Chicago',
  },
  '66960': {
    zip: '66960',
    city: 'Narka',
    state: 'KS',
    latitude: '39.959514',
    longitude: '-97.422459',
    timeZoneId: 'America/Chicago',
  },
  '66961': {
    zip: '66961',
    city: 'Norway',
    state: 'KS',
    latitude: '39.698981',
    longitude: '-97.829778',
    timeZoneId: 'America/Chicago',
  },
  '66962': {
    zip: '66962',
    city: 'Palmer',
    state: 'KS',
    latitude: '39.61043',
    longitude: '-97.089539',
    timeZoneId: 'America/Chicago',
  },
  '66963': {
    zip: '66963',
    city: 'Randall',
    state: 'KS',
    latitude: '39.610152',
    longitude: '-98.100119',
    timeZoneId: 'America/Chicago',
  },
  '66964': {
    zip: '66964',
    city: 'Republic',
    state: 'KS',
    latitude: '39.923093',
    longitude: '-97.825399',
    timeZoneId: 'America/Chicago',
  },
  '66966': {
    zip: '66966',
    city: 'Scandia',
    state: 'KS',
    latitude: '39.795975',
    longitude: '-97.784791',
    timeZoneId: 'America/Chicago',
  },
  '66967': {
    zip: '66967',
    city: 'Smith Center',
    state: 'KS',
    latitude: '39.779397',
    longitude: '-98.783094',
    timeZoneId: 'America/Chicago',
  },
  '66968': {
    zip: '66968',
    city: 'Washington',
    state: 'KS',
    latitude: '39.816009',
    longitude: '-97.051399',
    timeZoneId: 'America/Chicago',
  },
  '66970': {
    zip: '66970',
    city: 'Webber',
    state: 'KS',
    latitude: '39.914805',
    longitude: '-97.986799',
    timeZoneId: 'America/Chicago',
  },
  '67001': {
    zip: '67001',
    city: 'Andale',
    state: 'KS',
    latitude: '37.78723',
    longitude: '-97.633073',
    timeZoneId: 'America/Chicago',
  },
  '67002': {
    zip: '67002',
    city: 'Andover',
    state: 'KS',
    latitude: '37.700039',
    longitude: '-97.13831',
    timeZoneId: 'America/Chicago',
  },
  '67003': {
    zip: '67003',
    city: 'Anthony',
    state: 'KS',
    latitude: '37.155954',
    longitude: '-98.029197',
    timeZoneId: 'America/Chicago',
  },
  '67004': {
    zip: '67004',
    city: 'Argonia',
    state: 'KS',
    latitude: '37.26566',
    longitude: '-97.76594',
    timeZoneId: 'America/Chicago',
  },
  '67005': {
    zip: '67005',
    city: 'Arkansas City',
    state: 'KS',
    latitude: '37.071',
    longitude: '-97.05972',
    timeZoneId: 'America/Chicago',
  },
  '67008': {
    zip: '67008',
    city: 'Atlanta',
    state: 'KS',
    latitude: '37.430972',
    longitude: '-96.768361',
    timeZoneId: 'America/Chicago',
  },
  '67009': {
    zip: '67009',
    city: 'Attica',
    state: 'KS',
    latitude: '37.24309',
    longitude: '-98.226796',
    timeZoneId: 'America/Chicago',
  },
  '67010': {
    zip: '67010',
    city: 'Augusta',
    state: 'KS',
    latitude: '37.691488',
    longitude: '-96.976061',
    timeZoneId: 'America/Chicago',
  },
  '67012': {
    zip: '67012',
    city: 'Beaumont',
    state: 'KS',
    latitude: '37.65892',
    longitude: '-96.53396',
    timeZoneId: 'America/Chicago',
  },
  '67013': {
    zip: '67013',
    city: 'Belle Plaine',
    state: 'KS',
    latitude: '37.395834',
    longitude: '-97.277595',
    timeZoneId: 'America/Chicago',
  },
  '67016': {
    zip: '67016',
    city: 'Bentley',
    state: 'KS',
    latitude: '37.889978',
    longitude: '-97.517609',
    timeZoneId: 'America/Chicago',
  },
  '67017': {
    zip: '67017',
    city: 'Benton',
    state: 'KS',
    latitude: '37.789149',
    longitude: '-97.110068',
    timeZoneId: 'America/Chicago',
  },
  '67018': {
    zip: '67018',
    city: 'Bluff City',
    state: 'KS',
    latitude: '37.070411',
    longitude: '-97.877456',
    timeZoneId: 'America/Chicago',
  },
  '67019': {
    zip: '67019',
    city: 'Burden',
    state: 'KS',
    latitude: '37.321597',
    longitude: '-96.753042',
    timeZoneId: 'America/Chicago',
  },
  '67020': {
    zip: '67020',
    city: 'Burrton',
    state: 'KS',
    latitude: '38.027977',
    longitude: '-97.557538',
    timeZoneId: 'America/Chicago',
  },
  '67021': {
    zip: '67021',
    city: 'Byers',
    state: 'KS',
    latitude: '37.77925',
    longitude: '-98.907252',
    timeZoneId: 'America/Chicago',
  },
  '67022': {
    zip: '67022',
    city: 'Caldwell',
    state: 'KS',
    latitude: '37.040523',
    longitude: '-97.615566',
    timeZoneId: 'America/Chicago',
  },
  '67023': {
    zip: '67023',
    city: 'Cambridge',
    state: 'KS',
    latitude: '37.181533',
    longitude: '-96.717333',
    timeZoneId: 'America/Chicago',
  },
  '67024': {
    zip: '67024',
    city: 'Cedar Vale',
    state: 'KS',
    latitude: '37.106753',
    longitude: '-96.499615',
    timeZoneId: 'America/Chicago',
  },
  '67025': {
    zip: '67025',
    city: 'Cheney',
    state: 'KS',
    latitude: '37.628991',
    longitude: '-97.778762',
    timeZoneId: 'America/Chicago',
  },
  '67026': {
    zip: '67026',
    city: 'Clearwater',
    state: 'KS',
    latitude: '37.539314',
    longitude: '-97.493326',
    timeZoneId: 'America/Chicago',
  },
  '67028': {
    zip: '67028',
    city: 'Coats',
    state: 'KS',
    latitude: '37.514225',
    longitude: '-98.90214',
    timeZoneId: 'America/Chicago',
  },
  '67029': {
    zip: '67029',
    city: 'Coldwater',
    state: 'KS',
    latitude: '37.268276',
    longitude: '-99.320591',
    timeZoneId: 'America/Chicago',
  },
  '67030': {
    zip: '67030',
    city: 'Colwich',
    state: 'KS',
    latitude: '37.757205',
    longitude: '-97.532781',
    timeZoneId: 'America/Chicago',
  },
  '67031': {
    zip: '67031',
    city: 'Conway Springs',
    state: 'KS',
    latitude: '37.388963',
    longitude: '-97.644705',
    timeZoneId: 'America/Chicago',
  },
  '67035': {
    zip: '67035',
    city: 'Cunningham',
    state: 'KS',
    latitude: '37.66674',
    longitude: '-98.40258',
    timeZoneId: 'America/Chicago',
  },
  '67036': {
    zip: '67036',
    city: 'Danville',
    state: 'KS',
    latitude: '37.255729',
    longitude: '-97.867543',
    timeZoneId: 'America/Chicago',
  },
  '67037': {
    zip: '67037',
    city: 'Derby',
    state: 'KS',
    latitude: '37.55715',
    longitude: '-97.243212',
    timeZoneId: 'America/Chicago',
  },
  '67038': {
    zip: '67038',
    city: 'Dexter',
    state: 'KS',
    latitude: '37.18135',
    longitude: '-96.714685',
    timeZoneId: 'America/Chicago',
  },
  '67039': {
    zip: '67039',
    city: 'Douglass',
    state: 'KS',
    latitude: '37.514862',
    longitude: '-97.010503',
    timeZoneId: 'America/Chicago',
  },
  '67041': {
    zip: '67041',
    city: 'Elbing',
    state: 'KS',
    latitude: '38.055539',
    longitude: '-97.124644',
    timeZoneId: 'America/Chicago',
  },
  '67042': {
    zip: '67042',
    city: 'El Dorado',
    state: 'KS',
    latitude: '37.821764',
    longitude: '-96.865086',
    timeZoneId: 'America/Chicago',
  },
  '67045': {
    zip: '67045',
    city: 'Eureka',
    state: 'KS',
    latitude: '37.8432',
    longitude: '-96.31188',
    timeZoneId: 'America/Chicago',
  },
  '67047': {
    zip: '67047',
    city: 'Fall River',
    state: 'KS',
    latitude: '37.609772',
    longitude: '-96.028373',
    timeZoneId: 'America/Chicago',
  },
  '67049': {
    zip: '67049',
    city: 'Freeport',
    state: 'KS',
    latitude: '37.179576',
    longitude: '-97.857772',
    timeZoneId: 'America/Chicago',
  },
  '67050': {
    zip: '67050',
    city: 'Garden Plain',
    state: 'KS',
    latitude: '37.67169',
    longitude: '-97.675091',
    timeZoneId: 'America/Chicago',
  },
  '67051': {
    zip: '67051',
    city: 'Geuda Springs',
    state: 'KS',
    latitude: '37.06152',
    longitude: '-97.16922',
    timeZoneId: 'America/Chicago',
  },
  '67052': {
    zip: '67052',
    city: 'Goddard',
    state: 'KS',
    latitude: '37.656893',
    longitude: '-97.579974',
    timeZoneId: 'America/Chicago',
  },
  '67053': {
    zip: '67053',
    city: 'Goessel',
    state: 'KS',
    latitude: '38.247761',
    longitude: '-97.352728',
    timeZoneId: 'America/Chicago',
  },
  '67054': {
    zip: '67054',
    city: 'Greensburg',
    state: 'KS',
    latitude: '37.6049',
    longitude: '-99.294874',
    timeZoneId: 'America/Chicago',
  },
  '67055': {
    zip: '67055',
    city: 'Greenwich',
    state: 'KS',
    latitude: '37.781435',
    longitude: '-97.202874',
    timeZoneId: 'America/Chicago',
  },
  '67056': {
    zip: '67056',
    city: 'Halstead',
    state: 'KS',
    latitude: '38.000707',
    longitude: '-97.509005',
    timeZoneId: 'America/Chicago',
  },
  '67057': {
    zip: '67057',
    city: 'Hardtner',
    state: 'KS',
    latitude: '37.016334',
    longitude: '-98.648322',
    timeZoneId: 'America/Chicago',
  },
  '67058': {
    zip: '67058',
    city: 'Harper',
    state: 'KS',
    latitude: '37.285895',
    longitude: '-98.026088',
    timeZoneId: 'America/Chicago',
  },
  '67059': {
    zip: '67059',
    city: 'Haviland',
    state: 'KS',
    latitude: '37.616471',
    longitude: '-99.10484',
    timeZoneId: 'America/Chicago',
  },
  '67060': {
    zip: '67060',
    city: 'Haysville',
    state: 'KS',
    latitude: '37.565138',
    longitude: '-97.350958',
    timeZoneId: 'America/Chicago',
  },
  '67061': {
    zip: '67061',
    city: 'Hazelton',
    state: 'KS',
    latitude: '37.124251',
    longitude: '-98.404243',
    timeZoneId: 'America/Chicago',
  },
  '67062': {
    zip: '67062',
    city: 'Hesston',
    state: 'KS',
    latitude: '38.13894',
    longitude: '-97.430948',
    timeZoneId: 'America/Chicago',
  },
  '67063': {
    zip: '67063',
    city: 'Hillsboro',
    state: 'KS',
    latitude: '38.35182',
    longitude: '-97.202504',
    timeZoneId: 'America/Chicago',
  },
  '67065': {
    zip: '67065',
    city: 'Isabel',
    state: 'KS',
    latitude: '37.427224',
    longitude: '-98.522012',
    timeZoneId: 'America/Chicago',
  },
  '67066': {
    zip: '67066',
    city: 'Iuka',
    state: 'KS',
    latitude: '37.755818',
    longitude: '-98.740454',
    timeZoneId: 'America/Chicago',
  },
  '67067': {
    zip: '67067',
    city: 'Kechi',
    state: 'KS',
    latitude: '37.770844',
    longitude: '-97.293167',
    timeZoneId: 'America/Chicago',
  },
  '67068': {
    zip: '67068',
    city: 'Kingman',
    state: 'KS',
    latitude: '37.588441',
    longitude: '-98.072269',
    timeZoneId: 'America/Chicago',
  },
  '67070': {
    zip: '67070',
    city: 'Kiowa',
    state: 'KS',
    latitude: '37.013888',
    longitude: '-98.483557',
    timeZoneId: 'America/Chicago',
  },
  '67071': {
    zip: '67071',
    city: 'Lake City',
    state: 'KS',
    latitude: '37.335115',
    longitude: '-98.803382',
    timeZoneId: 'America/Chicago',
  },
  '67072': {
    zip: '67072',
    city: 'Latham',
    state: 'KS',
    latitude: '37.535765',
    longitude: '-96.642723',
    timeZoneId: 'America/Chicago',
  },
  '67073': {
    zip: '67073',
    city: 'Lehigh',
    state: 'KS',
    latitude: '38.375851',
    longitude: '-97.304362',
    timeZoneId: 'America/Chicago',
  },
  '67074': {
    zip: '67074',
    city: 'Leon',
    state: 'KS',
    latitude: '37.692456',
    longitude: '-96.784325',
    timeZoneId: 'America/Chicago',
  },
  '67101': {
    zip: '67101',
    city: 'Maize',
    state: 'KS',
    latitude: '37.775757',
    longitude: '-97.470221',
    timeZoneId: 'America/Chicago',
  },
  '67102': {
    zip: '67102',
    city: 'Maple City',
    state: 'KS',
    latitude: '37.075655',
    longitude: '-96.787476',
    timeZoneId: 'America/Chicago',
  },
  '67103': {
    zip: '67103',
    city: 'Mayfield',
    state: 'KS',
    latitude: '37.256785',
    longitude: '-97.530549',
    timeZoneId: 'America/Chicago',
  },
  '67104': {
    zip: '67104',
    city: 'Medicine Lodge',
    state: 'KS',
    latitude: '37.283493',
    longitude: '-98.578275',
    timeZoneId: 'America/Chicago',
  },
  '67105': {
    zip: '67105',
    city: 'Milan',
    state: 'KS',
    latitude: '37.257763',
    longitude: '-97.67528',
    timeZoneId: 'America/Chicago',
  },
  '67106': {
    zip: '67106',
    city: 'Milton',
    state: 'KS',
    latitude: '37.42792',
    longitude: '-97.749981',
    timeZoneId: 'America/Chicago',
  },
  '67107': {
    zip: '67107',
    city: 'Moundridge',
    state: 'KS',
    latitude: '38.205168',
    longitude: '-97.520678',
    timeZoneId: 'America/Chicago',
  },
  '67108': {
    zip: '67108',
    city: 'Mount Hope',
    state: 'KS',
    latitude: '37.859374',
    longitude: '-97.66353',
    timeZoneId: 'America/Chicago',
  },
  '67109': {
    zip: '67109',
    city: 'Mullinville',
    state: 'KS',
    latitude: '37.588569',
    longitude: '-99.459868',
    timeZoneId: 'America/Chicago',
  },
  '67110': {
    zip: '67110',
    city: 'Mulvane',
    state: 'KS',
    latitude: '37.488321',
    longitude: '-97.249776',
    timeZoneId: 'America/Chicago',
  },
  '67111': {
    zip: '67111',
    city: 'Murdock',
    state: 'KS',
    latitude: '37.603749',
    longitude: '-97.973333',
    timeZoneId: 'America/Chicago',
  },
  '67112': {
    zip: '67112',
    city: 'Nashville',
    state: 'KS',
    latitude: '37.425589',
    longitude: '-98.41132',
    timeZoneId: 'America/Chicago',
  },
  '67114': {
    zip: '67114',
    city: 'Newton',
    state: 'KS',
    latitude: '38.046304',
    longitude: '-97.3506',
    timeZoneId: 'America/Chicago',
  },
  '67117': {
    zip: '67117',
    city: 'North Newton',
    state: 'KS',
    latitude: '38.13408',
    longitude: '-97.32162',
    timeZoneId: 'America/Chicago',
  },
  '67118': {
    zip: '67118',
    city: 'Norwich',
    state: 'KS',
    latitude: '37.456046',
    longitude: '-97.846473',
    timeZoneId: 'America/Chicago',
  },
  '67119': {
    zip: '67119',
    city: 'Oxford',
    state: 'KS',
    latitude: '37.276202',
    longitude: '-97.170267',
    timeZoneId: 'America/Chicago',
  },
  '67120': {
    zip: '67120',
    city: 'Peck',
    state: 'KS',
    latitude: '37.50081',
    longitude: '-97.385952',
    timeZoneId: 'America/Chicago',
  },
  '67122': {
    zip: '67122',
    city: 'Piedmont',
    state: 'KS',
    latitude: '37.635562',
    longitude: '-96.409749',
    timeZoneId: 'America/Chicago',
  },
  '67123': {
    zip: '67123',
    city: 'Potwin',
    state: 'KS',
    latitude: '37.937501',
    longitude: '-97.016481',
    timeZoneId: 'America/Chicago',
  },
  '67124': {
    zip: '67124',
    city: 'Pratt',
    state: 'KS',
    latitude: '37.648212',
    longitude: '-98.738461',
    timeZoneId: 'America/Chicago',
  },
  '67127': {
    zip: '67127',
    city: 'Protection',
    state: 'KS',
    latitude: '37.198884',
    longitude: '-99.485702',
    timeZoneId: 'America/Chicago',
  },
  '67131': {
    zip: '67131',
    city: 'Rock',
    state: 'KS',
    latitude: '37.430201',
    longitude: '-96.931267',
    timeZoneId: 'America/Chicago',
  },
  '67132': {
    zip: '67132',
    city: 'Rosalia',
    state: 'KS',
    latitude: '37.781806',
    longitude: '-96.622408',
    timeZoneId: 'America/Chicago',
  },
  '67133': {
    zip: '67133',
    city: 'Rose Hill',
    state: 'KS',
    latitude: '37.574951',
    longitude: '-97.131651',
    timeZoneId: 'America/Chicago',
  },
  '67134': {
    zip: '67134',
    city: 'Sawyer',
    state: 'KS',
    latitude: '37.515252',
    longitude: '-98.626573',
    timeZoneId: 'America/Chicago',
  },
  '67135': {
    zip: '67135',
    city: 'Sedgwick',
    state: 'KS',
    latitude: '37.89418',
    longitude: '-97.438249',
    timeZoneId: 'America/Chicago',
  },
  '67137': {
    zip: '67137',
    city: 'Severy',
    state: 'KS',
    latitude: '37.64718',
    longitude: '-96.22692',
    timeZoneId: 'America/Chicago',
  },
  '67138': {
    zip: '67138',
    city: 'Sharon',
    state: 'KS',
    latitude: '37.251556',
    longitude: '-98.415419',
    timeZoneId: 'America/Chicago',
  },
  '67140': {
    zip: '67140',
    city: 'South Haven',
    state: 'KS',
    latitude: '37.093316',
    longitude: '-97.350358',
    timeZoneId: 'America/Chicago',
  },
  '67142': {
    zip: '67142',
    city: 'Spivey',
    state: 'KS',
    latitude: '37.426412',
    longitude: '-98.190505',
    timeZoneId: 'America/Chicago',
  },
  '67143': {
    zip: '67143',
    city: 'Sun City',
    state: 'KS',
    latitude: '37.398571',
    longitude: '-98.900639',
    timeZoneId: 'America/Chicago',
  },
  '67144': {
    zip: '67144',
    city: 'Towanda',
    state: 'KS',
    latitude: '37.795767',
    longitude: '-96.999851',
    timeZoneId: 'America/Chicago',
  },
  '67146': {
    zip: '67146',
    city: 'Udall',
    state: 'KS',
    latitude: '37.385176',
    longitude: '-97.123103',
    timeZoneId: 'America/Chicago',
  },
  '67147': {
    zip: '67147',
    city: 'Valley Center',
    state: 'KS',
    latitude: '37.841324',
    longitude: '-97.33973',
    timeZoneId: 'America/Chicago',
  },
  '67149': {
    zip: '67149',
    city: 'Viola',
    state: 'KS',
    latitude: '37.554253',
    longitude: '-97.605505',
    timeZoneId: 'America/Chicago',
  },
  '67150': {
    zip: '67150',
    city: 'Waldron',
    state: 'KS',
    latitude: '37.082141',
    longitude: '-98.239469',
    timeZoneId: 'America/Chicago',
  },
  '67151': {
    zip: '67151',
    city: 'Walton',
    state: 'KS',
    latitude: '38.117243',
    longitude: '-97.255176',
    timeZoneId: 'America/Chicago',
  },
  '67152': {
    zip: '67152',
    city: 'Wellington',
    state: 'KS',
    latitude: '37.269025',
    longitude: '-97.394271',
    timeZoneId: 'America/Chicago',
  },
  '67154': {
    zip: '67154',
    city: 'Whitewater',
    state: 'KS',
    latitude: '37.964462',
    longitude: '-97.142875',
    timeZoneId: 'America/Chicago',
  },
  '67155': {
    zip: '67155',
    city: 'Wilmore',
    state: 'KS',
    latitude: '37.3351',
    longitude: '-99.209536',
    timeZoneId: 'America/Chicago',
  },
  '67156': {
    zip: '67156',
    city: 'Winfield',
    state: 'KS',
    latitude: '37.23282',
    longitude: '-96.95484',
    timeZoneId: 'America/Chicago',
  },
  '67159': {
    zip: '67159',
    city: 'Zenda',
    state: 'KS',
    latitude: '37.42864',
    longitude: '-98.299926',
    timeZoneId: 'America/Chicago',
  },
  '67201': {
    zip: '67201',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.68821',
    longitude: '-97.343',
    timeZoneId: 'America/Chicago',
  },
  '67202': {
    zip: '67202',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.687225',
    longitude: '-97.335079',
    timeZoneId: 'America/Chicago',
  },
  '67203': {
    zip: '67203',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.706617',
    longitude: '-97.363073',
    timeZoneId: 'America/Chicago',
  },
  '67204': {
    zip: '67204',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.752075',
    longitude: '-97.357444',
    timeZoneId: 'America/Chicago',
  },
  '67205': {
    zip: '67205',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.747829',
    longitude: '-97.431522',
    timeZoneId: 'America/Chicago',
  },
  '67206': {
    zip: '67206',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.703526',
    longitude: '-97.234538',
    timeZoneId: 'America/Chicago',
  },
  '67207': {
    zip: '67207',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.66853',
    longitude: '-97.231169',
    timeZoneId: 'America/Chicago',
  },
  '67208': {
    zip: '67208',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.703821',
    longitude: '-97.277212',
    timeZoneId: 'America/Chicago',
  },
  '67209': {
    zip: '67209',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.66319',
    longitude: '-97.422577',
    timeZoneId: 'America/Chicago',
  },
  '67210': {
    zip: '67210',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.627375',
    longitude: '-97.263435',
    timeZoneId: 'America/Chicago',
  },
  '67211': {
    zip: '67211',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.667341',
    longitude: '-97.314775',
    timeZoneId: 'America/Chicago',
  },
  '67212': {
    zip: '67212',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.698135',
    longitude: '-97.436018',
    timeZoneId: 'America/Chicago',
  },
  '67213': {
    zip: '67213',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.66909',
    longitude: '-97.361883',
    timeZoneId: 'America/Chicago',
  },
  '67214': {
    zip: '67214',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.702727',
    longitude: '-97.318572',
    timeZoneId: 'America/Chicago',
  },
  '67215': {
    zip: '67215',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.628626',
    longitude: '-97.431951',
    timeZoneId: 'America/Chicago',
  },
  '67216': {
    zip: '67216',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.618484',
    longitude: '-97.317836',
    timeZoneId: 'America/Chicago',
  },
  '67217': {
    zip: '67217',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.624467',
    longitude: '-97.36185',
    timeZoneId: 'America/Chicago',
  },
  '67218': {
    zip: '67218',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.670737',
    longitude: '-97.280969',
    timeZoneId: 'America/Chicago',
  },
  '67219': {
    zip: '67219',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.756398',
    longitude: '-97.317064',
    timeZoneId: 'America/Chicago',
  },
  '67220': {
    zip: '67220',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.74682',
    longitude: '-97.27478',
    timeZoneId: 'America/Chicago',
  },
  '67221': {
    zip: '67221',
    city: 'McConnell AFB',
    state: 'KS',
    latitude: '37.63248',
    longitude: '-97.25034',
    timeZoneId: 'America/Chicago',
  },
  '67223': {
    zip: '67223',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.727416',
    longitude: '-97.490242',
    timeZoneId: 'America/Chicago',
  },
  '67226': {
    zip: '67226',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.742159',
    longitude: '-97.242969',
    timeZoneId: 'America/Chicago',
  },
  '67227': {
    zip: '67227',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.583944',
    longitude: '-97.448223',
    timeZoneId: 'America/Chicago',
  },
  '67228': {
    zip: '67228',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.765407',
    longitude: '-97.207113',
    timeZoneId: 'America/Chicago',
  },
  '67230': {
    zip: '67230',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.675534',
    longitude: '-97.175784',
    timeZoneId: 'America/Chicago',
  },
  '67232': {
    zip: '67232',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.642922',
    longitude: '-97.167324',
    timeZoneId: 'America/Chicago',
  },
  '67235': {
    zip: '67235',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.681344',
    longitude: '-97.487473',
    timeZoneId: 'America/Chicago',
  },
  '67260': {
    zip: '67260',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.707488',
    longitude: '-97.281218',
    timeZoneId: 'America/Chicago',
  },
  '67275': {
    zip: '67275',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.674977',
    longitude: '-97.445146',
    timeZoneId: 'America/Chicago',
  },
  '67276': {
    zip: '67276',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.660214',
    longitude: '-97.415614',
    timeZoneId: 'America/Chicago',
  },
  '67277': {
    zip: '67277',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.662437',
    longitude: '-97.428894',
    timeZoneId: 'America/Chicago',
  },
  '67278': {
    zip: '67278',
    city: 'Wichita',
    state: 'KS',
    latitude: '37.69302',
    longitude: '-97.337204',
    timeZoneId: 'America/Chicago',
  },
  '67301': {
    zip: '67301',
    city: 'Independence',
    state: 'KS',
    latitude: '37.225582',
    longitude: '-95.713959',
    timeZoneId: 'America/Chicago',
  },
  '67330': {
    zip: '67330',
    city: 'Altamont',
    state: 'KS',
    latitude: '37.19744',
    longitude: '-95.30335',
    timeZoneId: 'America/Chicago',
  },
  '67332': {
    zip: '67332',
    city: 'Bartlett',
    state: 'KS',
    latitude: '37.062529',
    longitude: '-95.210812',
    timeZoneId: 'America/Chicago',
  },
  '67333': {
    zip: '67333',
    city: 'Caney',
    state: 'KS',
    latitude: '37.014294',
    longitude: '-95.931916',
    timeZoneId: 'America/Chicago',
  },
  '67334': {
    zip: '67334',
    city: 'Chautauqua',
    state: 'KS',
    latitude: '37.021829',
    longitude: '-96.177323',
    timeZoneId: 'America/Chicago',
  },
  '67335': {
    zip: '67335',
    city: 'Cherryvale',
    state: 'KS',
    latitude: '37.2645',
    longitude: '-95.551419',
    timeZoneId: 'America/Chicago',
  },
  '67336': {
    zip: '67336',
    city: 'Chetopa',
    state: 'KS',
    latitude: '37.037596',
    longitude: '-95.090918',
    timeZoneId: 'America/Chicago',
  },
  '67337': {
    zip: '67337',
    city: 'Coffeyville',
    state: 'KS',
    latitude: '37.039645',
    longitude: '-95.630692',
    timeZoneId: 'America/Chicago',
  },
  '67340': {
    zip: '67340',
    city: 'Dearing',
    state: 'KS',
    latitude: '37.058653',
    longitude: '-95.714024',
    timeZoneId: 'America/Chicago',
  },
  '67341': {
    zip: '67341',
    city: 'Dennis',
    state: 'KS',
    latitude: '37.31887',
    longitude: '-95.414151',
    timeZoneId: 'America/Chicago',
  },
  '67342': {
    zip: '67342',
    city: 'Edna',
    state: 'KS',
    latitude: '37.064483',
    longitude: '-95.357511',
    timeZoneId: 'America/Chicago',
  },
  '67344': {
    zip: '67344',
    city: 'Elk City',
    state: 'KS',
    latitude: '37.2882',
    longitude: '-95.911482',
    timeZoneId: 'America/Chicago',
  },
  '67345': {
    zip: '67345',
    city: 'Elk Falls',
    state: 'KS',
    latitude: '37.384719',
    longitude: '-96.271609',
    timeZoneId: 'America/Chicago',
  },
  '67346': {
    zip: '67346',
    city: 'Grenola',
    state: 'KS',
    latitude: '37.346368',
    longitude: '-96.449744',
    timeZoneId: 'America/Chicago',
  },
  '67347': {
    zip: '67347',
    city: 'Havana',
    state: 'KS',
    latitude: '37.09426',
    longitude: '-95.941335',
    timeZoneId: 'America/Chicago',
  },
  '67349': {
    zip: '67349',
    city: 'Howard',
    state: 'KS',
    latitude: '37.513469',
    longitude: '-96.231906',
    timeZoneId: 'America/Chicago',
  },
  '67351': {
    zip: '67351',
    city: 'Liberty',
    state: 'KS',
    latitude: '37.159662',
    longitude: '-95.600773',
    timeZoneId: 'America/Chicago',
  },
  '67352': {
    zip: '67352',
    city: 'Longton',
    state: 'KS',
    latitude: '37.426126',
    longitude: '-96.070333',
    timeZoneId: 'America/Chicago',
  },
  '67353': {
    zip: '67353',
    city: 'Moline',
    state: 'KS',
    latitude: '37.367151',
    longitude: '-96.301387',
    timeZoneId: 'America/Chicago',
  },
  '67354': {
    zip: '67354',
    city: 'Mound Valley',
    state: 'KS',
    latitude: '37.208363',
    longitude: '-95.449695',
    timeZoneId: 'America/Chicago',
  },
  '67355': {
    zip: '67355',
    city: 'Niotaze',
    state: 'KS',
    latitude: '37.012687',
    longitude: '-95.924207',
    timeZoneId: 'America/Chicago',
  },
  '67356': {
    zip: '67356',
    city: 'Oswego',
    state: 'KS',
    latitude: '37.18932',
    longitude: '-95.13816',
    timeZoneId: 'America/Chicago',
  },
  '67357': {
    zip: '67357',
    city: 'Parsons',
    state: 'KS',
    latitude: '37.339316',
    longitude: '-95.268057',
    timeZoneId: 'America/Chicago',
  },
  '67360': {
    zip: '67360',
    city: 'Peru',
    state: 'KS',
    latitude: '37.054965',
    longitude: '-96.154501',
    timeZoneId: 'America/Chicago',
  },
  '67361': {
    zip: '67361',
    city: 'Sedan',
    state: 'KS',
    latitude: '37.127555',
    longitude: '-96.184822',
    timeZoneId: 'America/Chicago',
  },
  '67363': {
    zip: '67363',
    city: 'Sycamore',
    state: 'KS',
    latitude: '37.328779',
    longitude: '-95.714544',
    timeZoneId: 'America/Chicago',
  },
  '67364': {
    zip: '67364',
    city: 'Tyro',
    state: 'KS',
    latitude: '37.039062',
    longitude: '-95.820975',
    timeZoneId: 'America/Chicago',
  },
  '67401': {
    zip: '67401',
    city: 'Salina',
    state: 'KS',
    latitude: '38.829855',
    longitude: '-97.616195',
    timeZoneId: 'America/Chicago',
  },
  '67402': {
    zip: '67402',
    city: 'Salina',
    state: 'KS',
    latitude: '38.838881',
    longitude: '-97.608195',
    timeZoneId: 'America/Chicago',
  },
  '67410': {
    zip: '67410',
    city: 'Abilene',
    state: 'KS',
    latitude: '38.921079',
    longitude: '-97.220096',
    timeZoneId: 'America/Chicago',
  },
  '67416': {
    zip: '67416',
    city: 'Assaria',
    state: 'KS',
    latitude: '38.669599',
    longitude: '-97.583065',
    timeZoneId: 'America/Chicago',
  },
  '67417': {
    zip: '67417',
    city: 'Aurora',
    state: 'KS',
    latitude: '39.412093',
    longitude: '-97.51205',
    timeZoneId: 'America/Chicago',
  },
  '67418': {
    zip: '67418',
    city: 'Barnard',
    state: 'KS',
    latitude: '39.178066',
    longitude: '-98.098256',
    timeZoneId: 'America/Chicago',
  },
  '67420': {
    zip: '67420',
    city: 'Beloit',
    state: 'KS',
    latitude: '39.45114',
    longitude: '-98.11008',
    timeZoneId: 'America/Chicago',
  },
  '67422': {
    zip: '67422',
    city: 'Bennington',
    state: 'KS',
    latitude: '39.032683',
    longitude: '-97.594152',
    timeZoneId: 'America/Chicago',
  },
  '67423': {
    zip: '67423',
    city: 'Beverly',
    state: 'KS',
    latitude: '38.958805',
    longitude: '-97.980819',
    timeZoneId: 'America/Chicago',
  },
  '67425': {
    zip: '67425',
    city: 'Brookville',
    state: 'KS',
    latitude: '38.821582',
    longitude: '-97.859072',
    timeZoneId: 'America/Chicago',
  },
  '67427': {
    zip: '67427',
    city: 'Bushton',
    state: 'KS',
    latitude: '38.513006',
    longitude: '-98.395487',
    timeZoneId: 'America/Chicago',
  },
  '67428': {
    zip: '67428',
    city: 'Canton',
    state: 'KS',
    latitude: '38.385452',
    longitude: '-97.428295',
    timeZoneId: 'America/Chicago',
  },
  '67430': {
    zip: '67430',
    city: 'Cawker City',
    state: 'KS',
    latitude: '39.502984',
    longitude: '-98.383572',
    timeZoneId: 'America/Chicago',
  },
  '67431': {
    zip: '67431',
    city: 'Chapman',
    state: 'KS',
    latitude: '38.971941',
    longitude: '-97.021979',
    timeZoneId: 'America/Chicago',
  },
  '67432': {
    zip: '67432',
    city: 'Clay Center',
    state: 'KS',
    latitude: '39.38249',
    longitude: '-97.120208',
    timeZoneId: 'America/Chicago',
  },
  '67436': {
    zip: '67436',
    city: 'Delphos',
    state: 'KS',
    latitude: '39.27511',
    longitude: '-97.762963',
    timeZoneId: 'America/Chicago',
  },
  '67437': {
    zip: '67437',
    city: 'Downs',
    state: 'KS',
    latitude: '39.504401',
    longitude: '-98.545281',
    timeZoneId: 'America/Chicago',
  },
  '67438': {
    zip: '67438',
    city: 'Durham',
    state: 'KS',
    latitude: '38.485024',
    longitude: '-97.223571',
    timeZoneId: 'America/Chicago',
  },
  '67439': {
    zip: '67439',
    city: 'Ellsworth',
    state: 'KS',
    latitude: '38.732749',
    longitude: '-98.22571',
    timeZoneId: 'America/Chicago',
  },
  '67441': {
    zip: '67441',
    city: 'Enterprise',
    state: 'KS',
    latitude: '38.903547',
    longitude: '-97.115537',
    timeZoneId: 'America/Chicago',
  },
  '67442': {
    zip: '67442',
    city: 'Falun',
    state: 'KS',
    latitude: '38.656835',
    longitude: '-97.730557',
    timeZoneId: 'America/Chicago',
  },
  '67443': {
    zip: '67443',
    city: 'Galva',
    state: 'KS',
    latitude: '38.386476',
    longitude: '-97.550684',
    timeZoneId: 'America/Chicago',
  },
  '67444': {
    zip: '67444',
    city: 'Geneseo',
    state: 'KS',
    latitude: '38.516502',
    longitude: '-98.151849',
    timeZoneId: 'America/Chicago',
  },
  '67445': {
    zip: '67445',
    city: 'Glasco',
    state: 'KS',
    latitude: '39.359529',
    longitude: '-97.836641',
    timeZoneId: 'America/Chicago',
  },
  '67446': {
    zip: '67446',
    city: 'Glen Elder',
    state: 'KS',
    latitude: '39.498957',
    longitude: '-98.305444',
    timeZoneId: 'America/Chicago',
  },
  '67447': {
    zip: '67447',
    city: 'Green',
    state: 'KS',
    latitude: '39.42927',
    longitude: '-97.002267',
    timeZoneId: 'America/Chicago',
  },
  '67448': {
    zip: '67448',
    city: 'Gypsum',
    state: 'KS',
    latitude: '38.702809',
    longitude: '-97.424184',
    timeZoneId: 'America/Chicago',
  },
  '67449': {
    zip: '67449',
    city: 'Herington',
    state: 'KS',
    latitude: '38.68902',
    longitude: '-96.99216',
    timeZoneId: 'America/Chicago',
  },
  '67450': {
    zip: '67450',
    city: 'Holyrood',
    state: 'KS',
    latitude: '38.588065',
    longitude: '-98.414179',
    timeZoneId: 'America/Chicago',
  },
  '67451': {
    zip: '67451',
    city: 'Hope',
    state: 'KS',
    latitude: '38.67942',
    longitude: '-97.10772',
    timeZoneId: 'America/Chicago',
  },
  '67452': {
    zip: '67452',
    city: 'Hunter',
    state: 'KS',
    latitude: '39.264312',
    longitude: '-98.432653',
    timeZoneId: 'America/Chicago',
  },
  '67454': {
    zip: '67454',
    city: 'Kanopolis',
    state: 'KS',
    latitude: '38.709641',
    longitude: '-98.158302',
    timeZoneId: 'America/Chicago',
  },
  '67455': {
    zip: '67455',
    city: 'Lincoln',
    state: 'KS',
    latitude: '39.03588',
    longitude: '-98.14464',
    timeZoneId: 'America/Chicago',
  },
  '67456': {
    zip: '67456',
    city: 'Lindsborg',
    state: 'KS',
    latitude: '38.573248',
    longitude: '-97.674221',
    timeZoneId: 'America/Chicago',
  },
  '67457': {
    zip: '67457',
    city: 'Little River',
    state: 'KS',
    latitude: '38.39482',
    longitude: '-98.012446',
    timeZoneId: 'America/Chicago',
  },
  '67458': {
    zip: '67458',
    city: 'Longford',
    state: 'KS',
    latitude: '39.16961',
    longitude: '-97.33079',
    timeZoneId: 'America/Chicago',
  },
  '67459': {
    zip: '67459',
    city: 'Lorraine',
    state: 'KS',
    latitude: '38.566848',
    longitude: '-98.317829',
    timeZoneId: 'America/Chicago',
  },
  '67460': {
    zip: '67460',
    city: 'McPherson',
    state: 'KS',
    latitude: '38.38716',
    longitude: '-97.65654',
    timeZoneId: 'America/Chicago',
  },
  '67464': {
    zip: '67464',
    city: 'Marquette',
    state: 'KS',
    latitude: '38.557377',
    longitude: '-97.834152',
    timeZoneId: 'America/Chicago',
  },
  '67466': {
    zip: '67466',
    city: 'Miltonvale',
    state: 'KS',
    latitude: '39.348238',
    longitude: '-97.453339',
    timeZoneId: 'America/Chicago',
  },
  '67467': {
    zip: '67467',
    city: 'Minneapolis',
    state: 'KS',
    latitude: '39.125123',
    longitude: '-97.703693',
    timeZoneId: 'America/Chicago',
  },
  '67468': {
    zip: '67468',
    city: 'Morganville',
    state: 'KS',
    latitude: '39.487741',
    longitude: '-97.271667',
    timeZoneId: 'America/Chicago',
  },
  '67470': {
    zip: '67470',
    city: 'New Cambria',
    state: 'KS',
    latitude: '38.905756',
    longitude: '-97.498694',
    timeZoneId: 'America/Chicago',
  },
  '67473': {
    zip: '67473',
    city: 'Osborne',
    state: 'KS',
    latitude: '39.443497',
    longitude: '-98.699927',
    timeZoneId: 'America/Chicago',
  },
  '67474': {
    zip: '67474',
    city: 'Portis',
    state: 'KS',
    latitude: '39.522247',
    longitude: '-98.711636',
    timeZoneId: 'America/Chicago',
  },
  '67475': {
    zip: '67475',
    city: 'Ramona',
    state: 'KS',
    latitude: '38.566326',
    longitude: '-97.097596',
    timeZoneId: 'America/Chicago',
  },
  '67476': {
    zip: '67476',
    city: 'Roxbury',
    state: 'KS',
    latitude: '38.490858',
    longitude: '-97.437793',
    timeZoneId: 'America/Chicago',
  },
  '67478': {
    zip: '67478',
    city: 'Simpson',
    state: 'KS',
    latitude: '39.3828',
    longitude: '-97.933251',
    timeZoneId: 'America/Chicago',
  },
  '67480': {
    zip: '67480',
    city: 'Solomon',
    state: 'KS',
    latitude: '38.918024',
    longitude: '-97.371141',
    timeZoneId: 'America/Chicago',
  },
  '67481': {
    zip: '67481',
    city: 'Sylvan Grove',
    state: 'KS',
    latitude: '39.046332',
    longitude: '-98.376685',
    timeZoneId: 'America/Chicago',
  },
  '67482': {
    zip: '67482',
    city: 'Talmage',
    state: 'KS',
    latitude: '39.009162',
    longitude: '-97.3171',
    timeZoneId: 'America/Chicago',
  },
  '67483': {
    zip: '67483',
    city: 'Tampa',
    state: 'KS',
    latitude: '38.564314',
    longitude: '-97.203721',
    timeZoneId: 'America/Chicago',
  },
  '67484': {
    zip: '67484',
    city: 'Tescott',
    state: 'KS',
    latitude: '38.9874',
    longitude: '-97.78854',
    timeZoneId: 'America/Chicago',
  },
  '67485': {
    zip: '67485',
    city: 'Tipton',
    state: 'KS',
    latitude: '39.340353',
    longitude: '-98.472284',
    timeZoneId: 'America/Chicago',
  },
  '67487': {
    zip: '67487',
    city: 'Wakefield',
    state: 'KS',
    latitude: '39.217739',
    longitude: '-97.017957',
    timeZoneId: 'America/Chicago',
  },
  '67490': {
    zip: '67490',
    city: 'Wilson',
    state: 'KS',
    latitude: '38.822228',
    longitude: '-98.476081',
    timeZoneId: 'America/Chicago',
  },
  '67491': {
    zip: '67491',
    city: 'Windom',
    state: 'KS',
    latitude: '38.386168',
    longitude: '-97.906197',
    timeZoneId: 'America/Chicago',
  },
  '67492': {
    zip: '67492',
    city: 'Woodbine',
    state: 'KS',
    latitude: '38.830477',
    longitude: '-96.969022',
    timeZoneId: 'America/Chicago',
  },
  '67501': {
    zip: '67501',
    city: 'Hutchinson',
    state: 'KS',
    latitude: '38.056168',
    longitude: '-97.912213',
    timeZoneId: 'America/Chicago',
  },
  '67502': {
    zip: '67502',
    city: 'Hutchinson',
    state: 'KS',
    latitude: '38.102658',
    longitude: '-97.919629',
    timeZoneId: 'America/Chicago',
  },
  '67504': {
    zip: '67504',
    city: 'Hutchinson',
    state: 'KS',
    latitude: '38.025338',
    longitude: '-97.936497',
    timeZoneId: 'America/Chicago',
  },
  '67505': {
    zip: '67505',
    city: 'South Hutchinson',
    state: 'KS',
    latitude: '38.01942',
    longitude: '-97.97334',
    timeZoneId: 'America/Chicago',
  },
  '67510': {
    zip: '67510',
    city: 'Abbyville',
    state: 'KS',
    latitude: '38.005433',
    longitude: '-98.208083',
    timeZoneId: 'America/Chicago',
  },
  '67511': {
    zip: '67511',
    city: 'Albert',
    state: 'KS',
    latitude: '38.451782',
    longitude: '-99.010688',
    timeZoneId: 'America/Chicago',
  },
  '67512': {
    zip: '67512',
    city: 'Alden',
    state: 'KS',
    latitude: '38.240493',
    longitude: '-98.310946',
    timeZoneId: 'America/Chicago',
  },
  '67513': {
    zip: '67513',
    city: 'Alexander',
    state: 'KS',
    latitude: '38.468846',
    longitude: '-99.552228',
    timeZoneId: 'America/Chicago',
  },
  '67514': {
    zip: '67514',
    city: 'Arlington',
    state: 'KS',
    latitude: '37.884729',
    longitude: '-98.185498',
    timeZoneId: 'America/Chicago',
  },
  '67515': {
    zip: '67515',
    city: 'Arnold',
    state: 'KS',
    latitude: '38.608938',
    longitude: '-100.006515',
    timeZoneId: 'America/Chicago',
  },
  '67516': {
    zip: '67516',
    city: 'Bazine',
    state: 'KS',
    latitude: '38.478513',
    longitude: '-99.69703',
    timeZoneId: 'America/Chicago',
  },
  '67518': {
    zip: '67518',
    city: 'Beeler',
    state: 'KS',
    latitude: '38.475558',
    longitude: '-100.15013',
    timeZoneId: 'America/Chicago',
  },
  '67519': {
    zip: '67519',
    city: 'Belpre',
    state: 'KS',
    latitude: '37.924307',
    longitude: '-99.090767',
    timeZoneId: 'America/Chicago',
  },
  '67520': {
    zip: '67520',
    city: 'Bison',
    state: 'KS',
    latitude: '38.518768',
    longitude: '-99.199095',
    timeZoneId: 'America/Chicago',
  },
  '67521': {
    zip: '67521',
    city: 'Brownell',
    state: 'KS',
    latitude: '38.622656',
    longitude: '-99.697491',
    timeZoneId: 'America/Chicago',
  },
  '67522': {
    zip: '67522',
    city: 'Buhler',
    state: 'KS',
    latitude: '38.135527',
    longitude: '-97.777796',
    timeZoneId: 'America/Chicago',
  },
  '67523': {
    zip: '67523',
    city: 'Burdett',
    state: 'KS',
    latitude: '38.262009',
    longitude: '-99.519236',
    timeZoneId: 'America/Chicago',
  },
  '67524': {
    zip: '67524',
    city: 'Chase',
    state: 'KS',
    latitude: '38.35862',
    longitude: '-98.318064',
    timeZoneId: 'America/Chicago',
  },
  '67525': {
    zip: '67525',
    city: 'Claflin',
    state: 'KS',
    latitude: '38.524583',
    longitude: '-98.534997',
    timeZoneId: 'America/Chicago',
  },
  '67526': {
    zip: '67526',
    city: 'Ellinwood',
    state: 'KS',
    latitude: '38.357981',
    longitude: '-98.581126',
    timeZoneId: 'America/Chicago',
  },
  '67529': {
    zip: '67529',
    city: 'Garfield',
    state: 'KS',
    latitude: '38.07659',
    longitude: '-99.24422',
    timeZoneId: 'America/Chicago',
  },
  '67530': {
    zip: '67530',
    city: 'Great Bend',
    state: 'KS',
    latitude: '38.368828',
    longitude: '-98.776747',
    timeZoneId: 'America/Chicago',
  },
  '67543': {
    zip: '67543',
    city: 'Haven',
    state: 'KS',
    latitude: '37.915145',
    longitude: '-97.789184',
    timeZoneId: 'America/Chicago',
  },
  '67544': {
    zip: '67544',
    city: 'Hoisington',
    state: 'KS',
    latitude: '38.607687',
    longitude: '-98.84016',
    timeZoneId: 'America/Chicago',
  },
  '67545': {
    zip: '67545',
    city: 'Hudson',
    state: 'KS',
    latitude: '38.173212',
    longitude: '-98.58148',
    timeZoneId: 'America/Chicago',
  },
  '67546': {
    zip: '67546',
    city: 'Inman',
    state: 'KS',
    latitude: '38.228449',
    longitude: '-97.772035',
    timeZoneId: 'America/Chicago',
  },
  '67547': {
    zip: '67547',
    city: 'Kinsley',
    state: 'KS',
    latitude: '37.925257',
    longitude: '-99.408329',
    timeZoneId: 'America/Chicago',
  },
  '67548': {
    zip: '67548',
    city: 'La Crosse',
    state: 'KS',
    latitude: '38.529748',
    longitude: '-99.305953',
    timeZoneId: 'America/Chicago',
  },
  '67550': {
    zip: '67550',
    city: 'Larned',
    state: 'KS',
    latitude: '38.18832',
    longitude: '-99.12072',
    timeZoneId: 'America/Chicago',
  },
  '67552': {
    zip: '67552',
    city: 'Lewis',
    state: 'KS',
    latitude: '37.93583',
    longitude: '-99.255148',
    timeZoneId: 'America/Chicago',
  },
  '67553': {
    zip: '67553',
    city: 'Liebenthal',
    state: 'KS',
    latitude: '38.653589',
    longitude: '-99.321686',
    timeZoneId: 'America/Chicago',
  },
  '67554': {
    zip: '67554',
    city: 'Lyons',
    state: 'KS',
    latitude: '38.345749',
    longitude: '-98.206137',
    timeZoneId: 'America/Chicago',
  },
  '67556': {
    zip: '67556',
    city: 'Mc Cracken',
    state: 'KS',
    latitude: '38.585369',
    longitude: '-99.573435',
    timeZoneId: 'America/Chicago',
  },
  '67557': {
    zip: '67557',
    city: 'Macksville',
    state: 'KS',
    latitude: '37.956812',
    longitude: '-98.972472',
    timeZoneId: 'America/Chicago',
  },
  '67559': {
    zip: '67559',
    city: 'Nekoma',
    state: 'KS',
    latitude: '38.415103',
    longitude: '-99.417488',
    timeZoneId: 'America/Chicago',
  },
  '67560': {
    zip: '67560',
    city: 'Ness City',
    state: 'KS',
    latitude: '38.455286',
    longitude: '-99.90553',
    timeZoneId: 'America/Chicago',
  },
  '67561': {
    zip: '67561',
    city: 'Nickerson',
    state: 'KS',
    latitude: '38.144304',
    longitude: '-98.080396',
    timeZoneId: 'America/Chicago',
  },
  '67563': {
    zip: '67563',
    city: 'Offerle',
    state: 'KS',
    latitude: '37.869811',
    longitude: '-99.515779',
    timeZoneId: 'America/Chicago',
  },
  '67564': {
    zip: '67564',
    city: 'Olmitz',
    state: 'KS',
    latitude: '38.56558',
    longitude: '-98.916485',
    timeZoneId: 'America/Chicago',
  },
  '67565': {
    zip: '67565',
    city: 'Otis',
    state: 'KS',
    latitude: '38.534948',
    longitude: '-99.052096',
    timeZoneId: 'America/Chicago',
  },
  '67566': {
    zip: '67566',
    city: 'Partridge',
    state: 'KS',
    latitude: '37.964189',
    longitude: '-98.090631',
    timeZoneId: 'America/Chicago',
  },
  '67567': {
    zip: '67567',
    city: 'Pawnee Rock',
    state: 'KS',
    latitude: '38.303688',
    longitude: '-98.973377',
    timeZoneId: 'America/Chicago',
  },
  '67568': {
    zip: '67568',
    city: 'Plevna',
    state: 'KS',
    latitude: '37.985084',
    longitude: '-98.301725',
    timeZoneId: 'America/Chicago',
  },
  '67570': {
    zip: '67570',
    city: 'Pretty Prairie',
    state: 'KS',
    latitude: '37.786862',
    longitude: '-97.941549',
    timeZoneId: 'America/Chicago',
  },
  '67572': {
    zip: '67572',
    city: 'Ransom',
    state: 'KS',
    latitude: '38.63632',
    longitude: '-99.93449',
    timeZoneId: 'America/Chicago',
  },
  '67573': {
    zip: '67573',
    city: 'Raymond',
    state: 'KS',
    latitude: '38.263006',
    longitude: '-98.41885',
    timeZoneId: 'America/Chicago',
  },
  '67574': {
    zip: '67574',
    city: 'Rozel',
    state: 'KS',
    latitude: '37.929827',
    longitude: '-99.410895',
    timeZoneId: 'America/Chicago',
  },
  '67575': {
    zip: '67575',
    city: 'Rush Center',
    state: 'KS',
    latitude: '38.463652',
    longitude: '-99.312553',
    timeZoneId: 'America/Chicago',
  },
  '67576': {
    zip: '67576',
    city: 'St John',
    state: 'KS',
    latitude: '38.003667',
    longitude: '-98.761543',
    timeZoneId: 'America/Chicago',
  },
  '67578': {
    zip: '67578',
    city: 'Stafford',
    state: 'KS',
    latitude: '37.963128',
    longitude: '-98.598634',
    timeZoneId: 'America/Chicago',
  },
  '67579': {
    zip: '67579',
    city: 'Sterling',
    state: 'KS',
    latitude: '38.210635',
    longitude: '-98.206878',
    timeZoneId: 'America/Chicago',
  },
  '67581': {
    zip: '67581',
    city: 'Sylvia',
    state: 'KS',
    latitude: '37.960585',
    longitude: '-98.405441',
    timeZoneId: 'America/Chicago',
  },
  '67583': {
    zip: '67583',
    city: 'Turon',
    state: 'KS',
    latitude: '37.807408',
    longitude: '-98.427406',
    timeZoneId: 'America/Chicago',
  },
  '67584': {
    zip: '67584',
    city: 'Utica',
    state: 'KS',
    latitude: '38.643687',
    longitude: '-100.174726',
    timeZoneId: 'America/Chicago',
  },
  '67585': {
    zip: '67585',
    city: 'Yoder',
    state: 'KS',
    latitude: '37.943107',
    longitude: '-97.868576',
    timeZoneId: 'America/Chicago',
  },
  '67601': {
    zip: '67601',
    city: 'Hays',
    state: 'KS',
    latitude: '38.82',
    longitude: '-99.40866',
    timeZoneId: 'America/Chicago',
  },
  '67621': {
    zip: '67621',
    city: 'Agra',
    state: 'KS',
    latitude: '39.87495',
    longitude: '-99.137608',
    timeZoneId: 'America/Chicago',
  },
  '67622': {
    zip: '67622',
    city: 'Almena',
    state: 'KS',
    latitude: '39.893457',
    longitude: '-99.707325',
    timeZoneId: 'America/Chicago',
  },
  '67623': {
    zip: '67623',
    city: 'Alton',
    state: 'KS',
    latitude: '39.453032',
    longitude: '-98.9576',
    timeZoneId: 'America/Chicago',
  },
  '67625': {
    zip: '67625',
    city: 'Bogue',
    state: 'KS',
    latitude: '39.418191',
    longitude: '-99.677529',
    timeZoneId: 'America/Chicago',
  },
  '67626': {
    zip: '67626',
    city: 'Bunker Hill',
    state: 'KS',
    latitude: '38.839703',
    longitude: '-98.671233',
    timeZoneId: 'America/Chicago',
  },
  '67627': {
    zip: '67627',
    city: 'Catharine',
    state: 'KS',
    latitude: '38.983884',
    longitude: '-99.205458',
    timeZoneId: 'America/Chicago',
  },
  '67628': {
    zip: '67628',
    city: 'Cedar',
    state: 'KS',
    latitude: '39.662455',
    longitude: '-98.927189',
    timeZoneId: 'America/Chicago',
  },
  '67629': {
    zip: '67629',
    city: 'Clayton',
    state: 'KS',
    latitude: '39.678805',
    longitude: '-100.296418',
    timeZoneId: 'America/Chicago',
  },
  '67631': {
    zip: '67631',
    city: 'Collyer',
    state: 'KS',
    latitude: '38.916551',
    longitude: '-100.058186',
    timeZoneId: 'America/Chicago',
  },
  '67632': {
    zip: '67632',
    city: 'Damar',
    state: 'KS',
    latitude: '39.320781',
    longitude: '-99.588654',
    timeZoneId: 'America/Chicago',
  },
  '67634': {
    zip: '67634',
    city: 'Dorrance',
    state: 'KS',
    latitude: '38.846172',
    longitude: '-98.58624',
    timeZoneId: 'America/Chicago',
  },
  '67635': {
    zip: '67635',
    city: 'Dresden',
    state: 'KS',
    latitude: '39.61263',
    longitude: '-100.377412',
    timeZoneId: 'America/Chicago',
  },
  '67637': {
    zip: '67637',
    city: 'Ellis',
    state: 'KS',
    latitude: '38.938253',
    longitude: '-99.562205',
    timeZoneId: 'America/Chicago',
  },
  '67638': {
    zip: '67638',
    city: 'Gaylord',
    state: 'KS',
    latitude: '39.657956',
    longitude: '-98.781531',
    timeZoneId: 'America/Chicago',
  },
  '67639': {
    zip: '67639',
    city: 'Glade',
    state: 'KS',
    latitude: '39.644062',
    longitude: '-99.288625',
    timeZoneId: 'America/Chicago',
  },
  '67640': {
    zip: '67640',
    city: 'Gorham',
    state: 'KS',
    latitude: '38.879983',
    longitude: '-99.018706',
    timeZoneId: 'America/Chicago',
  },
  '67642': {
    zip: '67642',
    city: 'Hill City',
    state: 'KS',
    latitude: '39.364762',
    longitude: '-99.843675',
    timeZoneId: 'America/Chicago',
  },
  '67643': {
    zip: '67643',
    city: 'Jennings',
    state: 'KS',
    latitude: '39.677779',
    longitude: '-100.293941',
    timeZoneId: 'America/Chicago',
  },
  '67644': {
    zip: '67644',
    city: 'Kirwin',
    state: 'KS',
    latitude: '39.653797',
    longitude: '-99.125884',
    timeZoneId: 'America/Chicago',
  },
  '67645': {
    zip: '67645',
    city: 'Lenora',
    state: 'KS',
    latitude: '39.610537',
    longitude: '-100.001449',
    timeZoneId: 'America/Chicago',
  },
  '67646': {
    zip: '67646',
    city: 'Logan',
    state: 'KS',
    latitude: '39.666242',
    longitude: '-99.568823',
    timeZoneId: 'America/Chicago',
  },
  '67647': {
    zip: '67647',
    city: 'Long Island',
    state: 'KS',
    latitude: '39.959355',
    longitude: '-99.556644',
    timeZoneId: 'America/Chicago',
  },
  '67648': {
    zip: '67648',
    city: 'Lucas',
    state: 'KS',
    latitude: '39.061163',
    longitude: '-98.537771',
    timeZoneId: 'America/Chicago',
  },
  '67649': {
    zip: '67649',
    city: 'Luray',
    state: 'KS',
    latitude: '39.049864',
    longitude: '-98.649181',
    timeZoneId: 'America/Chicago',
  },
  '67650': {
    zip: '67650',
    city: 'Morland',
    state: 'KS',
    latitude: '39.33774',
    longitude: '-100.071414',
    timeZoneId: 'America/Chicago',
  },
  '67651': {
    zip: '67651',
    city: 'Natoma',
    state: 'KS',
    latitude: '39.188526',
    longitude: '-99.024213',
    timeZoneId: 'America/Chicago',
  },
  '67653': {
    zip: '67653',
    city: 'Norcatur',
    state: 'KS',
    latitude: '39.837957',
    longitude: '-100.189517',
    timeZoneId: 'America/Chicago',
  },
  '67654': {
    zip: '67654',
    city: 'Norton',
    state: 'KS',
    latitude: '39.834865',
    longitude: '-99.889403',
    timeZoneId: 'America/Chicago',
  },
  '67656': {
    zip: '67656',
    city: 'Ogallah',
    state: 'KS',
    latitude: '38.913103',
    longitude: '-99.688615',
    timeZoneId: 'America/Chicago',
  },
  '67657': {
    zip: '67657',
    city: 'Palco',
    state: 'KS',
    latitude: '39.252862',
    longitude: '-99.56062',
    timeZoneId: 'America/Chicago',
  },
  '67658': {
    zip: '67658',
    city: 'Paradise',
    state: 'KS',
    latitude: '39.045372',
    longitude: '-98.928358',
    timeZoneId: 'America/Chicago',
  },
  '67659': {
    zip: '67659',
    city: 'Penokee',
    state: 'KS',
    latitude: '39.392678',
    longitude: '-99.92888',
    timeZoneId: 'America/Chicago',
  },
  '67660': {
    zip: '67660',
    city: 'Pfeifer',
    state: 'KS',
    latitude: '38.731742',
    longitude: '-99.145308',
    timeZoneId: 'America/Chicago',
  },
  '67661': {
    zip: '67661',
    city: 'Phillipsburg',
    state: 'KS',
    latitude: '39.757045',
    longitude: '-99.325612',
    timeZoneId: 'America/Chicago',
  },
  '67663': {
    zip: '67663',
    city: 'Plainville',
    state: 'KS',
    latitude: '39.232902',
    longitude: '-99.303102',
    timeZoneId: 'America/Chicago',
  },
  '67664': {
    zip: '67664',
    city: 'Prairie View',
    state: 'KS',
    latitude: '39.83461',
    longitude: '-99.570086',
    timeZoneId: 'America/Chicago',
  },
  '67665': {
    zip: '67665',
    city: 'Russell',
    state: 'KS',
    latitude: '38.891284',
    longitude: '-98.858436',
    timeZoneId: 'America/Chicago',
  },
  '67667': {
    zip: '67667',
    city: 'Schoenchen',
    state: 'KS',
    latitude: '38.713957',
    longitude: '-99.33102',
    timeZoneId: 'America/Chicago',
  },
  '67669': {
    zip: '67669',
    city: 'Stockton',
    state: 'KS',
    latitude: '39.436278',
    longitude: '-99.270791',
    timeZoneId: 'America/Chicago',
  },
  '67671': {
    zip: '67671',
    city: 'Victoria',
    state: 'KS',
    latitude: '38.85156',
    longitude: '-99.147399',
    timeZoneId: 'America/Chicago',
  },
  '67672': {
    zip: '67672',
    city: 'Wakeeney',
    state: 'KS',
    latitude: '38.9874',
    longitude: '-99.88014',
    timeZoneId: 'America/Chicago',
  },
  '67673': {
    zip: '67673',
    city: 'Waldo',
    state: 'KS',
    latitude: '39.045196',
    longitude: '-98.762026',
    timeZoneId: 'America/Chicago',
  },
  '67674': {
    zip: '67674',
    city: 'Walker',
    state: 'KS',
    latitude: '38.865402',
    longitude: '-99.076678',
    timeZoneId: 'America/Chicago',
  },
  '67675': {
    zip: '67675',
    city: 'Woodston',
    state: 'KS',
    latitude: '39.437794',
    longitude: '-99.100339',
    timeZoneId: 'America/Chicago',
  },
  '67701': {
    zip: '67701',
    city: 'Colby',
    state: 'KS',
    latitude: '39.394672',
    longitude: '-101.047317',
    timeZoneId: 'America/Chicago',
  },
  '67730': {
    zip: '67730',
    city: 'Atwood',
    state: 'KS',
    latitude: '39.810937',
    longitude: '-101.039229',
    timeZoneId: 'America/Chicago',
  },
  '67731': {
    zip: '67731',
    city: 'Bird City',
    state: 'KS',
    latitude: '39.749763',
    longitude: '-101.534504',
    timeZoneId: 'America/Chicago',
  },
  '67732': {
    zip: '67732',
    city: 'Brewster',
    state: 'KS',
    latitude: '39.361269',
    longitude: '-101.375215',
    timeZoneId: 'America/Chicago',
  },
  '67733': {
    zip: '67733',
    city: 'Edson',
    state: 'KS',
    latitude: '39.350422',
    longitude: '-101.499003',
    timeZoneId: 'America/Denver',
  },
  '67734': {
    zip: '67734',
    city: 'Gem',
    state: 'KS',
    latitude: '39.439272',
    longitude: '-100.88388',
    timeZoneId: 'America/Chicago',
  },
  '67735': {
    zip: '67735',
    city: 'Goodland',
    state: 'KS',
    latitude: '39.348821',
    longitude: '-101.712088',
    timeZoneId: 'America/Denver',
  },
  '67736': {
    zip: '67736',
    city: 'Gove',
    state: 'KS',
    latitude: '38.856239',
    longitude: '-100.50206',
    timeZoneId: 'America/Chicago',
  },
  '67737': {
    zip: '67737',
    city: 'Grainfield',
    state: 'KS',
    latitude: '39.110945',
    longitude: '-100.464358',
    timeZoneId: 'America/Chicago',
  },
  '67738': {
    zip: '67738',
    city: 'Grinnell',
    state: 'KS',
    latitude: '39.123089',
    longitude: '-100.630849',
    timeZoneId: 'America/Chicago',
  },
  '67739': {
    zip: '67739',
    city: 'Herndon',
    state: 'KS',
    latitude: '39.89148',
    longitude: '-100.827986',
    timeZoneId: 'America/Chicago',
  },
  '67740': {
    zip: '67740',
    city: 'Hoxie',
    state: 'KS',
    latitude: '39.35278',
    longitude: '-100.16378',
    timeZoneId: 'America/Chicago',
  },
  '67741': {
    zip: '67741',
    city: 'Kanorado',
    state: 'KS',
    latitude: '39.339102',
    longitude: '-102.036209',
    timeZoneId: 'America/Denver',
  },
  '67743': {
    zip: '67743',
    city: 'Levant',
    state: 'KS',
    latitude: '39.375878',
    longitude: '-101.225432',
    timeZoneId: 'America/Chicago',
  },
  '67744': {
    zip: '67744',
    city: 'Ludell',
    state: 'KS',
    latitude: '39.871108',
    longitude: '-100.94054',
    timeZoneId: 'America/Chicago',
  },
  '67745': {
    zip: '67745',
    city: 'Mc Donald',
    state: 'KS',
    latitude: '39.785928',
    longitude: '-101.296984',
    timeZoneId: 'America/Chicago',
  },
  '67747': {
    zip: '67747',
    city: 'Monument',
    state: 'KS',
    latitude: '38.960076',
    longitude: '-101.064031',
    timeZoneId: 'America/Chicago',
  },
  '67748': {
    zip: '67748',
    city: 'Oakley',
    state: 'KS',
    latitude: '39.12527',
    longitude: '-100.852996',
    timeZoneId: 'America/Chicago',
  },
  '67749': {
    zip: '67749',
    city: 'Oberlin',
    state: 'KS',
    latitude: '39.82061',
    longitude: '-100.531041',
    timeZoneId: 'America/Chicago',
  },
  '67751': {
    zip: '67751',
    city: 'Park',
    state: 'KS',
    latitude: '39.063984',
    longitude: '-100.233233',
    timeZoneId: 'America/Chicago',
  },
  '67752': {
    zip: '67752',
    city: 'Quinter',
    state: 'KS',
    latitude: '38.915534',
    longitude: '-100.242238',
    timeZoneId: 'America/Chicago',
  },
  '67753': {
    zip: '67753',
    city: 'Rexford',
    state: 'KS',
    latitude: '39.41322',
    longitude: '-100.74234',
    timeZoneId: 'America/Chicago',
  },
  '67756': {
    zip: '67756',
    city: 'Saint Francis',
    state: 'KS',
    latitude: '39.77478',
    longitude: '-101.8104',
    timeZoneId: 'America/Chicago',
  },
  '67757': {
    zip: '67757',
    city: 'Selden',
    state: 'KS',
    latitude: '39.538463',
    longitude: '-100.56544',
    timeZoneId: 'America/Chicago',
  },
  '67758': {
    zip: '67758',
    city: 'Sharon Springs',
    state: 'KS',
    latitude: '38.901914',
    longitude: '-101.747317',
    timeZoneId: 'America/Denver',
  },
  '67761': {
    zip: '67761',
    city: 'Wallace',
    state: 'KS',
    latitude: '38.917299',
    longitude: '-101.565301',
    timeZoneId: 'America/Denver',
  },
  '67762': {
    zip: '67762',
    city: 'Weskan',
    state: 'KS',
    latitude: '38.885719',
    longitude: '-101.946745',
    timeZoneId: 'America/Denver',
  },
  '67764': {
    zip: '67764',
    city: 'Winona',
    state: 'KS',
    latitude: '39.046384',
    longitude: '-101.228293',
    timeZoneId: 'America/Chicago',
  },
  '67801': {
    zip: '67801',
    city: 'Dodge City',
    state: 'KS',
    latitude: '37.757785',
    longitude: '-100.018943',
    timeZoneId: 'America/Chicago',
  },
  '67831': {
    zip: '67831',
    city: 'Ashland',
    state: 'KS',
    latitude: '37.189068',
    longitude: '-99.770945',
    timeZoneId: 'America/Chicago',
  },
  '67834': {
    zip: '67834',
    city: 'Bucklin',
    state: 'KS',
    latitude: '37.550242',
    longitude: '-99.635022',
    timeZoneId: 'America/Chicago',
  },
  '67835': {
    zip: '67835',
    city: 'Cimarron',
    state: 'KS',
    latitude: '37.90458',
    longitude: '-100.33278',
    timeZoneId: 'America/Chicago',
  },
  '67836': {
    zip: '67836',
    city: 'Coolidge',
    state: 'KS',
    latitude: '38.037855',
    longitude: '-102.009987',
    timeZoneId: 'America/Denver',
  },
  '67837': {
    zip: '67837',
    city: 'Copeland',
    state: 'KS',
    latitude: '37.542791',
    longitude: '-100.628155',
    timeZoneId: 'America/Chicago',
  },
  '67838': {
    zip: '67838',
    city: 'Deerfield',
    state: 'KS',
    latitude: '37.983227',
    longitude: '-101.130217',
    timeZoneId: 'America/Chicago',
  },
  '67839': {
    zip: '67839',
    city: 'Dighton',
    state: 'KS',
    latitude: '38.46246',
    longitude: '-100.43514',
    timeZoneId: 'America/Chicago',
  },
  '67840': {
    zip: '67840',
    city: 'Englewood',
    state: 'KS',
    latitude: '37.03859',
    longitude: '-99.98207',
    timeZoneId: 'America/Chicago',
  },
  '67841': {
    zip: '67841',
    city: 'Ensign',
    state: 'KS',
    latitude: '37.652226',
    longitude: '-100.230612',
    timeZoneId: 'America/Chicago',
  },
  '67842': {
    zip: '67842',
    city: 'Ford',
    state: 'KS',
    latitude: '37.634089',
    longitude: '-99.755515',
    timeZoneId: 'America/Chicago',
  },
  '67843': {
    zip: '67843',
    city: 'Fort Dodge',
    state: 'KS',
    latitude: '37.665605',
    longitude: '-99.94454',
    timeZoneId: 'America/Chicago',
  },
  '67844': {
    zip: '67844',
    city: 'Fowler',
    state: 'KS',
    latitude: '37.382512',
    longitude: '-100.194629',
    timeZoneId: 'America/Chicago',
  },
  '67846': {
    zip: '67846',
    city: 'Garden City',
    state: 'KS',
    latitude: '37.97343',
    longitude: '-100.849523',
    timeZoneId: 'America/Chicago',
  },
  '67849': {
    zip: '67849',
    city: 'Hanston',
    state: 'KS',
    latitude: '38.123791',
    longitude: '-99.713284',
    timeZoneId: 'America/Chicago',
  },
  '67850': {
    zip: '67850',
    city: 'Healy',
    state: 'KS',
    latitude: '38.483146',
    longitude: '-100.613523',
    timeZoneId: 'America/Chicago',
  },
  '67851': {
    zip: '67851',
    city: 'Holcomb',
    state: 'KS',
    latitude: '38.005295',
    longitude: '-100.984166',
    timeZoneId: 'America/Chicago',
  },
  '67853': {
    zip: '67853',
    city: 'Ingalls',
    state: 'KS',
    latitude: '37.958397',
    longitude: '-100.52647',
    timeZoneId: 'America/Chicago',
  },
  '67854': {
    zip: '67854',
    city: 'Jetmore',
    state: 'KS',
    latitude: '38.080999',
    longitude: '-99.894098',
    timeZoneId: 'America/Chicago',
  },
  '67855': {
    zip: '67855',
    city: 'Johnson',
    state: 'KS',
    latitude: '37.57188',
    longitude: '-101.71476',
    timeZoneId: 'America/Chicago',
  },
  '67857': {
    zip: '67857',
    city: 'Kendall',
    state: 'KS',
    latitude: '37.933701',
    longitude: '-101.541951',
    timeZoneId: 'America/Denver',
  },
  '67859': {
    zip: '67859',
    city: 'Kismet',
    state: 'KS',
    latitude: '37.204966',
    longitude: '-100.702144',
    timeZoneId: 'America/Chicago',
  },
  '67860': {
    zip: '67860',
    city: 'Lakin',
    state: 'KS',
    latitude: '37.944208',
    longitude: '-101.258073',
    timeZoneId: 'America/Chicago',
  },
  '67861': {
    zip: '67861',
    city: 'Leoti',
    state: 'KS',
    latitude: '38.483861',
    longitude: '-101.356671',
    timeZoneId: 'America/Chicago',
  },
  '67862': {
    zip: '67862',
    city: 'Manter',
    state: 'KS',
    latitude: '37.524757',
    longitude: '-101.881634',
    timeZoneId: 'America/Chicago',
  },
  '67863': {
    zip: '67863',
    city: 'Marienthal',
    state: 'KS',
    latitude: '38.4858',
    longitude: '-101.0367',
    timeZoneId: 'America/Chicago',
  },
  '67864': {
    zip: '67864',
    city: 'Meade',
    state: 'KS',
    latitude: '37.283989',
    longitude: '-100.33892',
    timeZoneId: 'America/Chicago',
  },
  '67865': {
    zip: '67865',
    city: 'Minneola',
    state: 'KS',
    latitude: '37.42116',
    longitude: '-99.91704',
    timeZoneId: 'America/Chicago',
  },
  '67867': {
    zip: '67867',
    city: 'Montezuma',
    state: 'KS',
    latitude: '37.593985',
    longitude: '-100.442446',
    timeZoneId: 'America/Chicago',
  },
  '67868': {
    zip: '67868',
    city: 'Pierceville',
    state: 'KS',
    latitude: '37.83276',
    longitude: '-100.695287',
    timeZoneId: 'America/Chicago',
  },
  '67869': {
    zip: '67869',
    city: 'Plains',
    state: 'KS',
    latitude: '37.260083',
    longitude: '-100.592316',
    timeZoneId: 'America/Chicago',
  },
  '67870': {
    zip: '67870',
    city: 'Satanta',
    state: 'KS',
    latitude: '37.448615',
    longitude: '-101.012179',
    timeZoneId: 'America/Chicago',
  },
  '67871': {
    zip: '67871',
    city: 'Scott City',
    state: 'KS',
    latitude: '38.47902',
    longitude: '-100.89144',
    timeZoneId: 'America/Chicago',
  },
  '67876': {
    zip: '67876',
    city: 'Spearville',
    state: 'KS',
    latitude: '37.846187',
    longitude: '-99.756989',
    timeZoneId: 'America/Chicago',
  },
  '67877': {
    zip: '67877',
    city: 'Sublette',
    state: 'KS',
    latitude: '37.483963',
    longitude: '-100.842343',
    timeZoneId: 'America/Chicago',
  },
  '67878': {
    zip: '67878',
    city: 'Syracuse',
    state: 'KS',
    latitude: '37.984523',
    longitude: '-101.747922',
    timeZoneId: 'America/Denver',
  },
  '67879': {
    zip: '67879',
    city: 'Tribune',
    state: 'KS',
    latitude: '38.475095',
    longitude: '-101.748829',
    timeZoneId: 'America/Denver',
  },
  '67880': {
    zip: '67880',
    city: 'Ulysses',
    state: 'KS',
    latitude: '37.58353',
    longitude: '-101.358939',
    timeZoneId: 'America/Chicago',
  },
  '67882': {
    zip: '67882',
    city: 'Wright',
    state: 'KS',
    latitude: '37.82229',
    longitude: '-99.93233',
    timeZoneId: 'America/Chicago',
  },
  '67901': {
    zip: '67901',
    city: 'Liberal',
    state: 'KS',
    latitude: '37.041883',
    longitude: '-100.928884',
    timeZoneId: 'America/Chicago',
  },
  '67905': {
    zip: '67905',
    city: 'Liberal',
    state: 'KS',
    latitude: '37.029534',
    longitude: '-100.931646',
    timeZoneId: 'America/Chicago',
  },
  '67950': {
    zip: '67950',
    city: 'Elkhart',
    state: 'KS',
    latitude: '37.000631',
    longitude: '-101.903047',
    timeZoneId: 'America/Chicago',
  },
  '67951': {
    zip: '67951',
    city: 'Hugoton',
    state: 'KS',
    latitude: '37.175657',
    longitude: '-101.346497',
    timeZoneId: 'America/Chicago',
  },
  '67952': {
    zip: '67952',
    city: 'Moscow',
    state: 'KS',
    latitude: '37.312499',
    longitude: '-101.293672',
    timeZoneId: 'America/Chicago',
  },
  '67953': {
    zip: '67953',
    city: 'Richfield',
    state: 'KS',
    latitude: '37.28322',
    longitude: '-101.736158',
    timeZoneId: 'America/Chicago',
  },
  '67954': {
    zip: '67954',
    city: 'Rolla',
    state: 'KS',
    latitude: '37.117068',
    longitude: '-101.630756',
    timeZoneId: 'America/Chicago',
  },
  '68001': {
    zip: '68001',
    city: 'Abie',
    state: 'NE',
    latitude: '41.345618',
    longitude: '-96.964103',
    timeZoneId: 'America/Chicago',
  },
  '68002': {
    zip: '68002',
    city: 'Arlington',
    state: 'NE',
    latitude: '41.457952',
    longitude: '-96.352472',
    timeZoneId: 'America/Chicago',
  },
  '68003': {
    zip: '68003',
    city: 'Ashland',
    state: 'NE',
    latitude: '41.042189',
    longitude: '-96.375917',
    timeZoneId: 'America/Chicago',
  },
  '68004': {
    zip: '68004',
    city: 'Bancroft',
    state: 'NE',
    latitude: '42.013383',
    longitude: '-96.574975',
    timeZoneId: 'America/Chicago',
  },
  '68005': {
    zip: '68005',
    city: 'Bellevue',
    state: 'NE',
    latitude: '41.150489',
    longitude: '-95.908507',
    timeZoneId: 'America/Chicago',
  },
  '68007': {
    zip: '68007',
    city: 'Bennington',
    state: 'NE',
    latitude: '41.354344',
    longitude: '-96.169168',
    timeZoneId: 'America/Chicago',
  },
  '68008': {
    zip: '68008',
    city: 'Blair',
    state: 'NE',
    latitude: '41.54333',
    longitude: '-96.141019',
    timeZoneId: 'America/Chicago',
  },
  '68010': {
    zip: '68010',
    city: 'Boys Town',
    state: 'NE',
    latitude: '41.259761',
    longitude: '-96.128888',
    timeZoneId: 'America/Chicago',
  },
  '68014': {
    zip: '68014',
    city: 'Bruno',
    state: 'NE',
    latitude: '41.264706',
    longitude: '-96.96795',
    timeZoneId: 'America/Chicago',
  },
  '68015': {
    zip: '68015',
    city: 'Cedar Bluffs',
    state: 'NE',
    latitude: '41.397107',
    longitude: '-96.60814',
    timeZoneId: 'America/Chicago',
  },
  '68016': {
    zip: '68016',
    city: 'Cedar Creek',
    state: 'NE',
    latitude: '41.044111',
    longitude: '-96.102145',
    timeZoneId: 'America/Chicago',
  },
  '68017': {
    zip: '68017',
    city: 'Ceresco',
    state: 'NE',
    latitude: '41.061704',
    longitude: '-96.64763',
    timeZoneId: 'America/Chicago',
  },
  '68018': {
    zip: '68018',
    city: 'Colon',
    state: 'NE',
    latitude: '41.301021',
    longitude: '-96.606339',
    timeZoneId: 'America/Chicago',
  },
  '68019': {
    zip: '68019',
    city: 'Craig',
    state: 'NE',
    latitude: '41.781961',
    longitude: '-96.354588',
    timeZoneId: 'America/Chicago',
  },
  '68020': {
    zip: '68020',
    city: 'Decatur',
    state: 'NE',
    latitude: '42.008059',
    longitude: '-96.248686',
    timeZoneId: 'America/Chicago',
  },
  '68022': {
    zip: '68022',
    city: 'Elkhorn',
    state: 'NE',
    latitude: '41.272116',
    longitude: '-96.241943',
    timeZoneId: 'America/Chicago',
  },
  '68023': {
    zip: '68023',
    city: 'Fort Calhoun',
    state: 'NE',
    latitude: '41.4562',
    longitude: '-96.024852',
    timeZoneId: 'America/Chicago',
  },
  '68025': {
    zip: '68025',
    city: 'Fremont',
    state: 'NE',
    latitude: '41.441269',
    longitude: '-96.494304',
    timeZoneId: 'America/Chicago',
  },
  '68026': {
    zip: '68026',
    city: 'Fremont',
    state: 'NE',
    latitude: '41.440321',
    longitude: '-96.489748',
    timeZoneId: 'America/Chicago',
  },
  '68028': {
    zip: '68028',
    city: 'Gretna',
    state: 'NE',
    latitude: '41.127216',
    longitude: '-96.250421',
    timeZoneId: 'America/Chicago',
  },
  '68029': {
    zip: '68029',
    city: 'Herman',
    state: 'NE',
    latitude: '41.642508',
    longitude: '-96.321076',
    timeZoneId: 'America/Chicago',
  },
  '68030': {
    zip: '68030',
    city: 'Homer',
    state: 'NE',
    latitude: '42.32113',
    longitude: '-96.493233',
    timeZoneId: 'America/Chicago',
  },
  '68031': {
    zip: '68031',
    city: 'Hooper',
    state: 'NE',
    latitude: '41.610766',
    longitude: '-96.546766',
    timeZoneId: 'America/Chicago',
  },
  '68033': {
    zip: '68033',
    city: 'Ithaca',
    state: 'NE',
    latitude: '41.164062',
    longitude: '-96.542654',
    timeZoneId: 'America/Chicago',
  },
  '68034': {
    zip: '68034',
    city: 'Kennard',
    state: 'NE',
    latitude: '41.472',
    longitude: '-96.203364',
    timeZoneId: 'America/Chicago',
  },
  '68036': {
    zip: '68036',
    city: 'Linwood',
    state: 'NE',
    latitude: '41.41271',
    longitude: '-96.93218',
    timeZoneId: 'America/Chicago',
  },
  '68037': {
    zip: '68037',
    city: 'Louisville',
    state: 'NE',
    latitude: '40.99985',
    longitude: '-96.159694',
    timeZoneId: 'America/Chicago',
  },
  '68038': {
    zip: '68038',
    city: 'Lyons',
    state: 'NE',
    latitude: '41.935485',
    longitude: '-96.473601',
    timeZoneId: 'America/Chicago',
  },
  '68039': {
    zip: '68039',
    city: 'Macy',
    state: 'NE',
    latitude: '42.013041',
    longitude: '-96.574982',
    timeZoneId: 'America/Chicago',
  },
  '68040': {
    zip: '68040',
    city: 'Malmo',
    state: 'NE',
    latitude: '41.302222',
    longitude: '-96.73163',
    timeZoneId: 'America/Chicago',
  },
  '68041': {
    zip: '68041',
    city: 'Mead',
    state: 'NE',
    latitude: '41.213989',
    longitude: '-96.519748',
    timeZoneId: 'America/Chicago',
  },
  '68042': {
    zip: '68042',
    city: 'Memphis',
    state: 'NE',
    latitude: '41.091772',
    longitude: '-96.43185',
    timeZoneId: 'America/Chicago',
  },
  '68044': {
    zip: '68044',
    city: 'Nickerson',
    state: 'NE',
    latitude: '41.521374',
    longitude: '-96.49854',
    timeZoneId: 'America/Chicago',
  },
  '68045': {
    zip: '68045',
    city: 'Oakland',
    state: 'NE',
    latitude: '41.835451',
    longitude: '-96.465532',
    timeZoneId: 'America/Chicago',
  },
  '68046': {
    zip: '68046',
    city: 'Papillion',
    state: 'NE',
    latitude: '41.154802',
    longitude: '-96.037961',
    timeZoneId: 'America/Chicago',
  },
  '68047': {
    zip: '68047',
    city: 'Pender',
    state: 'NE',
    latitude: '42.112399',
    longitude: '-96.711991',
    timeZoneId: 'America/Chicago',
  },
  '68048': {
    zip: '68048',
    city: 'Plattsmouth',
    state: 'NE',
    latitude: '41.011668',
    longitude: '-95.889911',
    timeZoneId: 'America/Chicago',
  },
  '68050': {
    zip: '68050',
    city: 'Prague',
    state: 'NE',
    latitude: '41.296249',
    longitude: '-96.852961',
    timeZoneId: 'America/Chicago',
  },
  '68055': {
    zip: '68055',
    city: 'Rosalie',
    state: 'NE',
    latitude: '42.058749',
    longitude: '-96.471206',
    timeZoneId: 'America/Chicago',
  },
  '68056': {
    zip: '68056',
    city: 'St Columbans',
    state: 'NE',
    latitude: '41.091485',
    longitude: '-96.053536',
    timeZoneId: 'America/Chicago',
  },
  '68057': {
    zip: '68057',
    city: 'Scribner',
    state: 'NE',
    latitude: '41.661517',
    longitude: '-96.655134',
    timeZoneId: 'America/Chicago',
  },
  '68058': {
    zip: '68058',
    city: 'South Bend',
    state: 'NE',
    latitude: '41.000221',
    longitude: '-96.246892',
    timeZoneId: 'America/Chicago',
  },
  '68059': {
    zip: '68059',
    city: 'Springfield',
    state: 'NE',
    latitude: '41.06845',
    longitude: '-96.150271',
    timeZoneId: 'America/Chicago',
  },
  '68061': {
    zip: '68061',
    city: 'Tekamah',
    state: 'NE',
    latitude: '41.775728',
    longitude: '-96.223201',
    timeZoneId: 'America/Chicago',
  },
  '68062': {
    zip: '68062',
    city: 'Thurston',
    state: 'NE',
    latitude: '42.196266',
    longitude: '-96.682949',
    timeZoneId: 'America/Chicago',
  },
  '68063': {
    zip: '68063',
    city: 'Uehling',
    state: 'NE',
    latitude: '41.734407',
    longitude: '-96.505658',
    timeZoneId: 'America/Chicago',
  },
  '68064': {
    zip: '68064',
    city: 'Valley',
    state: 'NE',
    latitude: '41.312896',
    longitude: '-96.347618',
    timeZoneId: 'America/Chicago',
  },
  '68065': {
    zip: '68065',
    city: 'Valparaiso',
    state: 'NE',
    latitude: '41.078461',
    longitude: '-96.829772',
    timeZoneId: 'America/Chicago',
  },
  '68066': {
    zip: '68066',
    city: 'Wahoo',
    state: 'NE',
    latitude: '41.209769',
    longitude: '-96.62285',
    timeZoneId: 'America/Chicago',
  },
  '68067': {
    zip: '68067',
    city: 'Walthill',
    state: 'NE',
    latitude: '42.147',
    longitude: '-96.490211',
    timeZoneId: 'America/Chicago',
  },
  '68068': {
    zip: '68068',
    city: 'Washington',
    state: 'NE',
    latitude: '41.394305',
    longitude: '-96.207856',
    timeZoneId: 'America/Chicago',
  },
  '68069': {
    zip: '68069',
    city: 'Waterloo',
    state: 'NE',
    latitude: '41.285288',
    longitude: '-96.280956',
    timeZoneId: 'America/Chicago',
  },
  '68070': {
    zip: '68070',
    city: 'Weston',
    state: 'NE',
    latitude: '41.17861',
    longitude: '-96.794009',
    timeZoneId: 'America/Chicago',
  },
  '68071': {
    zip: '68071',
    city: 'Winnebago',
    state: 'NE',
    latitude: '42.238339',
    longitude: '-96.472001',
    timeZoneId: 'America/Chicago',
  },
  '68072': {
    zip: '68072',
    city: 'Winslow',
    state: 'NE',
    latitude: '41.608193',
    longitude: '-96.505496',
    timeZoneId: 'America/Chicago',
  },
  '68073': {
    zip: '68073',
    city: 'Yutan',
    state: 'NE',
    latitude: '41.2412',
    longitude: '-96.400277',
    timeZoneId: 'America/Chicago',
  },
  '68101': {
    zip: '68101',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.262185',
    longitude: '-95.931331',
    timeZoneId: 'America/Chicago',
  },
  '68102': {
    zip: '68102',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.260653',
    longitude: '-95.93434',
    timeZoneId: 'America/Chicago',
  },
  '68103': {
    zip: '68103',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.257105',
    longitude: '-95.944998',
    timeZoneId: 'America/Chicago',
  },
  '68104': {
    zip: '68104',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.291657',
    longitude: '-95.996811',
    timeZoneId: 'America/Chicago',
  },
  '68105': {
    zip: '68105',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.239537',
    longitude: '-95.962523',
    timeZoneId: 'America/Chicago',
  },
  '68106': {
    zip: '68106',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.237222',
    longitude: '-96.001713',
    timeZoneId: 'America/Chicago',
  },
  '68107': {
    zip: '68107',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.20725',
    longitude: '-95.95527',
    timeZoneId: 'America/Chicago',
  },
  '68108': {
    zip: '68108',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.235658',
    longitude: '-95.928687',
    timeZoneId: 'America/Chicago',
  },
  '68109': {
    zip: '68109',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.237586',
    longitude: '-95.927592',
    timeZoneId: 'America/Chicago',
  },
  '68110': {
    zip: '68110',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.291646',
    longitude: '-95.926442',
    timeZoneId: 'America/Chicago',
  },
  '68111': {
    zip: '68111',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.290325',
    longitude: '-95.967165',
    timeZoneId: 'America/Chicago',
  },
  '68112': {
    zip: '68112',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.338789',
    longitude: '-95.974358',
    timeZoneId: 'America/Chicago',
  },
  '68113': {
    zip: '68113',
    city: 'Offutt AFB',
    state: 'NE',
    latitude: '41.1249',
    longitude: '-95.91672',
    timeZoneId: 'America/Chicago',
  },
  '68114': {
    zip: '68114',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.264936',
    longitude: '-96.051169',
    timeZoneId: 'America/Chicago',
  },
  '68116': {
    zip: '68116',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.289542',
    longitude: '-96.170593',
    timeZoneId: 'America/Chicago',
  },
  '68117': {
    zip: '68117',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.206624',
    longitude: '-95.999613',
    timeZoneId: 'America/Chicago',
  },
  '68118': {
    zip: '68118',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.263933',
    longitude: '-96.177075',
    timeZoneId: 'America/Chicago',
  },
  '68119': {
    zip: '68119',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.3066',
    longitude: '-95.90513',
    timeZoneId: 'America/Chicago',
  },
  '68122': {
    zip: '68122',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.3387',
    longitude: '-96.072049',
    timeZoneId: 'America/Chicago',
  },
  '68123': {
    zip: '68123',
    city: 'Bellevue',
    state: 'NE',
    latitude: '41.11212',
    longitude: '-95.9574',
    timeZoneId: 'America/Chicago',
  },
  '68124': {
    zip: '68124',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.238073',
    longitude: '-96.055759',
    timeZoneId: 'America/Chicago',
  },
  '68127': {
    zip: '68127',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.204126',
    longitude: '-96.054148',
    timeZoneId: 'America/Chicago',
  },
  '68128': {
    zip: '68128',
    city: 'La Vista',
    state: 'NE',
    latitude: '41.183889',
    longitude: '-96.030833',
    timeZoneId: 'America/Chicago',
  },
  '68130': {
    zip: '68130',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.237517',
    longitude: '-96.169978',
    timeZoneId: 'America/Chicago',
  },
  '68131': {
    zip: '68131',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.271024',
    longitude: '-95.962638',
    timeZoneId: 'America/Chicago',
  },
  '68132': {
    zip: '68132',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.267177',
    longitude: '-96.001881',
    timeZoneId: 'America/Chicago',
  },
  '68133': {
    zip: '68133',
    city: 'Papillion',
    state: 'NE',
    latitude: '41.13402',
    longitude: '-96.0018',
    timeZoneId: 'America/Chicago',
  },
  '68134': {
    zip: '68134',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.299209',
    longitude: '-96.052153',
    timeZoneId: 'America/Chicago',
  },
  '68135': {
    zip: '68135',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.207735',
    longitude: '-96.175838',
    timeZoneId: 'America/Chicago',
  },
  '68136': {
    zip: '68136',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.166069',
    longitude: '-96.174813',
    timeZoneId: 'America/Chicago',
  },
  '68137': {
    zip: '68137',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.206074',
    longitude: '-96.122007',
    timeZoneId: 'America/Chicago',
  },
  '68138': {
    zip: '68138',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.169533',
    longitude: '-96.127845',
    timeZoneId: 'America/Chicago',
  },
  '68139': {
    zip: '68139',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.22151',
    longitude: '-96.120245',
    timeZoneId: 'America/Chicago',
  },
  '68142': {
    zip: '68142',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.339409',
    longitude: '-96.09543',
    timeZoneId: 'America/Chicago',
  },
  '68144': {
    zip: '68144',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.23321',
    longitude: '-96.113706',
    timeZoneId: 'America/Chicago',
  },
  '68145': {
    zip: '68145',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.257619',
    longitude: '-95.936656',
    timeZoneId: 'America/Chicago',
  },
  '68147': {
    zip: '68147',
    city: 'Bellevue',
    state: 'NE',
    latitude: '41.177581',
    longitude: '-95.955671',
    timeZoneId: 'America/Chicago',
  },
  '68152': {
    zip: '68152',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.341128',
    longitude: '-95.998908',
    timeZoneId: 'America/Chicago',
  },
  '68154': {
    zip: '68154',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.261363',
    longitude: '-96.115709',
    timeZoneId: 'America/Chicago',
  },
  '68157': {
    zip: '68157',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.184654',
    longitude: '-95.994302',
    timeZoneId: 'America/Chicago',
  },
  '68164': {
    zip: '68164',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.295609',
    longitude: '-96.109098',
    timeZoneId: 'America/Chicago',
  },
  '68172': {
    zip: '68172',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.260689',
    longitude: '-95.94059',
    timeZoneId: 'America/Chicago',
  },
  '68175': {
    zip: '68175',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.266258',
    longitude: '-95.964336',
    timeZoneId: 'America/Chicago',
  },
  '68176': {
    zip: '68176',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.260689',
    longitude: '-95.94059',
    timeZoneId: 'America/Chicago',
  },
  '68178': {
    zip: '68178',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.266599',
    longitude: '-95.946811',
    timeZoneId: 'America/Chicago',
  },
  '68179': {
    zip: '68179',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.26277',
    longitude: '-95.934386',
    timeZoneId: 'America/Chicago',
  },
  '68180': {
    zip: '68180',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.233224',
    longitude: '-96.053004',
    timeZoneId: 'America/Chicago',
  },
  '68182': {
    zip: '68182',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.254745',
    longitude: '-96.009873',
    timeZoneId: 'America/Chicago',
  },
  '68183': {
    zip: '68183',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.258306',
    longitude: '-95.938217',
    timeZoneId: 'America/Chicago',
  },
  '68197': {
    zip: '68197',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.259112',
    longitude: '-95.937493',
    timeZoneId: 'America/Chicago',
  },
  '68198': {
    zip: '68198',
    city: 'Omaha',
    state: 'NE',
    latitude: '41.265996',
    longitude: '-96.010026',
    timeZoneId: 'America/Chicago',
  },
  '68301': {
    zip: '68301',
    city: 'Adams',
    state: 'NE',
    latitude: '40.456355',
    longitude: '-96.510084',
    timeZoneId: 'America/Chicago',
  },
  '68303': {
    zip: '68303',
    city: 'Alexandria',
    state: 'NE',
    latitude: '40.250787',
    longitude: '-97.389823',
    timeZoneId: 'America/Chicago',
  },
  '68304': {
    zip: '68304',
    city: 'Alvo',
    state: 'NE',
    latitude: '40.869803',
    longitude: '-96.38597',
    timeZoneId: 'America/Chicago',
  },
  '68305': {
    zip: '68305',
    city: 'Auburn',
    state: 'NE',
    latitude: '40.390284',
    longitude: '-95.842607',
    timeZoneId: 'America/Chicago',
  },
  '68307': {
    zip: '68307',
    city: 'Avoca',
    state: 'NE',
    latitude: '40.798417',
    longitude: '-96.119486',
    timeZoneId: 'America/Chicago',
  },
  '68309': {
    zip: '68309',
    city: 'Barneston',
    state: 'NE',
    latitude: '40.049316',
    longitude: '-96.575317',
    timeZoneId: 'America/Chicago',
  },
  '68310': {
    zip: '68310',
    city: 'Beatrice',
    state: 'NE',
    latitude: '40.267056',
    longitude: '-96.743096',
    timeZoneId: 'America/Chicago',
  },
  '68313': {
    zip: '68313',
    city: 'Beaver Crossing',
    state: 'NE',
    latitude: '40.780014',
    longitude: '-97.268905',
    timeZoneId: 'America/Chicago',
  },
  '68314': {
    zip: '68314',
    city: 'Bee',
    state: 'NE',
    latitude: '41.007694',
    longitude: '-97.054158',
    timeZoneId: 'America/Chicago',
  },
  '68315': {
    zip: '68315',
    city: 'Belvidere',
    state: 'NE',
    latitude: '40.253359',
    longitude: '-97.535575',
    timeZoneId: 'America/Chicago',
  },
  '68316': {
    zip: '68316',
    city: 'Benedict',
    state: 'NE',
    latitude: '41.005076',
    longitude: '-97.603338',
    timeZoneId: 'America/Chicago',
  },
  '68317': {
    zip: '68317',
    city: 'Bennet',
    state: 'NE',
    latitude: '40.6792',
    longitude: '-96.512593',
    timeZoneId: 'America/Chicago',
  },
  '68318': {
    zip: '68318',
    city: 'Blue Springs',
    state: 'NE',
    latitude: '40.139141',
    longitude: '-96.666383',
    timeZoneId: 'America/Chicago',
  },
  '68319': {
    zip: '68319',
    city: 'Bradshaw',
    state: 'NE',
    latitude: '40.884339',
    longitude: '-97.745935',
    timeZoneId: 'America/Chicago',
  },
  '68320': {
    zip: '68320',
    city: 'Brock',
    state: 'NE',
    latitude: '40.477179',
    longitude: '-95.95958',
    timeZoneId: 'America/Chicago',
  },
  '68321': {
    zip: '68321',
    city: 'Brownville',
    state: 'NE',
    latitude: '40.397517',
    longitude: '-95.715698',
    timeZoneId: 'America/Chicago',
  },
  '68322': {
    zip: '68322',
    city: 'Bruning',
    state: 'NE',
    latitude: '40.336633',
    longitude: '-97.564394',
    timeZoneId: 'America/Chicago',
  },
  '68323': {
    zip: '68323',
    city: 'Burchard',
    state: 'NE',
    latitude: '40.089542',
    longitude: '-96.352947',
    timeZoneId: 'America/Chicago',
  },
  '68324': {
    zip: '68324',
    city: 'Burr',
    state: 'NE',
    latitude: '40.537964',
    longitude: '-96.303129',
    timeZoneId: 'America/Chicago',
  },
  '68325': {
    zip: '68325',
    city: 'Byron',
    state: 'NE',
    latitude: '40.041978',
    longitude: '-97.752191',
    timeZoneId: 'America/Chicago',
  },
  '68326': {
    zip: '68326',
    city: 'Carleton',
    state: 'NE',
    latitude: '40.297938',
    longitude: '-97.665585',
    timeZoneId: 'America/Chicago',
  },
  '68327': {
    zip: '68327',
    city: 'Chester',
    state: 'NE',
    latitude: '40.009914',
    longitude: '-97.619397',
    timeZoneId: 'America/Chicago',
  },
  '68328': {
    zip: '68328',
    city: 'Clatonia',
    state: 'NE',
    latitude: '40.465354',
    longitude: '-96.849788',
    timeZoneId: 'America/Chicago',
  },
  '68329': {
    zip: '68329',
    city: 'Cook',
    state: 'NE',
    latitude: '40.508304',
    longitude: '-96.163432',
    timeZoneId: 'America/Chicago',
  },
  '68330': {
    zip: '68330',
    city: 'Cordova',
    state: 'NE',
    latitude: '40.71533',
    longitude: '-97.350442',
    timeZoneId: 'America/Chicago',
  },
  '68331': {
    zip: '68331',
    city: 'Cortland',
    state: 'NE',
    latitude: '40.503475',
    longitude: '-96.706289',
    timeZoneId: 'America/Chicago',
  },
  '68332': {
    zip: '68332',
    city: 'Crab Orchard',
    state: 'NE',
    latitude: '40.305568',
    longitude: '-96.406515',
    timeZoneId: 'America/Chicago',
  },
  '68333': {
    zip: '68333',
    city: 'Crete',
    state: 'NE',
    latitude: '40.622672',
    longitude: '-96.959633',
    timeZoneId: 'America/Chicago',
  },
  '68335': {
    zip: '68335',
    city: 'Davenport',
    state: 'NE',
    latitude: '40.278417',
    longitude: '-97.793557',
    timeZoneId: 'America/Chicago',
  },
  '68336': {
    zip: '68336',
    city: 'Davey',
    state: 'NE',
    latitude: '40.973815',
    longitude: '-96.69255',
    timeZoneId: 'America/Chicago',
  },
  '68337': {
    zip: '68337',
    city: 'Dawson',
    state: 'NE',
    latitude: '40.130784',
    longitude: '-95.839206',
    timeZoneId: 'America/Chicago',
  },
  '68338': {
    zip: '68338',
    city: 'Daykin',
    state: 'NE',
    latitude: '40.30033',
    longitude: '-97.277589',
    timeZoneId: 'America/Chicago',
  },
  '68339': {
    zip: '68339',
    city: 'Denton',
    state: 'NE',
    latitude: '40.737195',
    longitude: '-96.848512',
    timeZoneId: 'America/Chicago',
  },
  '68340': {
    zip: '68340',
    city: 'Deshler',
    state: 'NE',
    latitude: '40.13733',
    longitude: '-97.723815',
    timeZoneId: 'America/Chicago',
  },
  '68341': {
    zip: '68341',
    city: 'De Witt',
    state: 'NE',
    latitude: '40.391721',
    longitude: '-96.921087',
    timeZoneId: 'America/Chicago',
  },
  '68342': {
    zip: '68342',
    city: 'Diller',
    state: 'NE',
    latitude: '40.110011',
    longitude: '-96.934026',
    timeZoneId: 'America/Chicago',
  },
  '68343': {
    zip: '68343',
    city: 'Dorchester',
    state: 'NE',
    latitude: '40.648808',
    longitude: '-97.117598',
    timeZoneId: 'America/Chicago',
  },
  '68344': {
    zip: '68344',
    city: 'Douglas',
    state: 'NE',
    latitude: '40.594843',
    longitude: '-96.384446',
    timeZoneId: 'America/Chicago',
  },
  '68345': {
    zip: '68345',
    city: 'Du Bois',
    state: 'NE',
    latitude: '40.041',
    longitude: '-96.05808',
    timeZoneId: 'America/Chicago',
  },
  '68346': {
    zip: '68346',
    city: 'Dunbar',
    state: 'NE',
    latitude: '40.655568',
    longitude: '-96.007618',
    timeZoneId: 'America/Chicago',
  },
  '68347': {
    zip: '68347',
    city: 'Eagle',
    state: 'NE',
    latitude: '40.815516',
    longitude: '-96.430676',
    timeZoneId: 'America/Chicago',
  },
  '68348': {
    zip: '68348',
    city: 'Elk Creek',
    state: 'NE',
    latitude: '40.32717',
    longitude: '-96.135905',
    timeZoneId: 'America/Chicago',
  },
  '68349': {
    zip: '68349',
    city: 'Elmwood',
    state: 'NE',
    latitude: '40.841581',
    longitude: '-96.295682',
    timeZoneId: 'America/Chicago',
  },
  '68350': {
    zip: '68350',
    city: 'Endicott',
    state: 'NE',
    latitude: '40.079665',
    longitude: '-97.093532',
    timeZoneId: 'America/Chicago',
  },
  '68351': {
    zip: '68351',
    city: 'Exeter',
    state: 'NE',
    latitude: '40.64252',
    longitude: '-97.446271',
    timeZoneId: 'America/Chicago',
  },
  '68352': {
    zip: '68352',
    city: 'Fairbury',
    state: 'NE',
    latitude: '40.15608',
    longitude: '-97.1718',
    timeZoneId: 'America/Chicago',
  },
  '68354': {
    zip: '68354',
    city: 'Fairmont',
    state: 'NE',
    latitude: '40.632986',
    longitude: '-97.584084',
    timeZoneId: 'America/Chicago',
  },
  '68355': {
    zip: '68355',
    city: 'Falls City',
    state: 'NE',
    latitude: '40.063372',
    longitude: '-95.60158',
    timeZoneId: 'America/Chicago',
  },
  '68357': {
    zip: '68357',
    city: 'Filley',
    state: 'NE',
    latitude: '40.304907',
    longitude: '-96.519067',
    timeZoneId: 'America/Chicago',
  },
  '68358': {
    zip: '68358',
    city: 'Firth',
    state: 'NE',
    latitude: '40.536757',
    longitude: '-96.608791',
    timeZoneId: 'America/Chicago',
  },
  '68359': {
    zip: '68359',
    city: 'Friend',
    state: 'NE',
    latitude: '40.640909',
    longitude: '-97.269963',
    timeZoneId: 'America/Chicago',
  },
  '68360': {
    zip: '68360',
    city: 'Garland',
    state: 'NE',
    latitude: '40.943834',
    longitude: '-96.983258',
    timeZoneId: 'America/Chicago',
  },
  '68361': {
    zip: '68361',
    city: 'Geneva',
    state: 'NE',
    latitude: '40.529762',
    longitude: '-97.599851',
    timeZoneId: 'America/Chicago',
  },
  '68362': {
    zip: '68362',
    city: 'Gilead',
    state: 'NE',
    latitude: '40.166389',
    longitude: '-97.585556',
    timeZoneId: 'America/Chicago',
  },
  '68364': {
    zip: '68364',
    city: 'Goehner',
    state: 'NE',
    latitude: '40.833094',
    longitude: '-97.203137',
    timeZoneId: 'America/Chicago',
  },
  '68365': {
    zip: '68365',
    city: 'Grafton',
    state: 'NE',
    latitude: '40.626754',
    longitude: '-97.713563',
    timeZoneId: 'America/Chicago',
  },
  '68366': {
    zip: '68366',
    city: 'Greenwood',
    state: 'NE',
    latitude: '40.962494',
    longitude: '-96.441281',
    timeZoneId: 'America/Chicago',
  },
  '68367': {
    zip: '68367',
    city: 'Gresham',
    state: 'NE',
    latitude: '41.00628',
    longitude: '-97.421986',
    timeZoneId: 'America/Chicago',
  },
  '68368': {
    zip: '68368',
    city: 'Hallam',
    state: 'NE',
    latitude: '40.560143',
    longitude: '-96.792049',
    timeZoneId: 'America/Chicago',
  },
  '68370': {
    zip: '68370',
    city: 'Hebron',
    state: 'NE',
    latitude: '40.169252',
    longitude: '-97.588399',
    timeZoneId: 'America/Chicago',
  },
  '68371': {
    zip: '68371',
    city: 'Henderson',
    state: 'NE',
    latitude: '40.785',
    longitude: '-97.78332',
    timeZoneId: 'America/Chicago',
  },
  '68372': {
    zip: '68372',
    city: 'Hickman',
    state: 'NE',
    latitude: '40.58862',
    longitude: '-96.6177',
    timeZoneId: 'America/Chicago',
  },
  '68375': {
    zip: '68375',
    city: 'Hubbell',
    state: 'NE',
    latitude: '40.010558',
    longitude: '-97.50168',
    timeZoneId: 'America/Chicago',
  },
  '68376': {
    zip: '68376',
    city: 'Humboldt',
    state: 'NE',
    latitude: '40.164101',
    longitude: '-95.945902',
    timeZoneId: 'America/Chicago',
  },
  '68377': {
    zip: '68377',
    city: 'Jansen',
    state: 'NE',
    latitude: '40.20156',
    longitude: '-97.02684',
    timeZoneId: 'America/Chicago',
  },
  '68378': {
    zip: '68378',
    city: 'Johnson',
    state: 'NE',
    latitude: '40.40148',
    longitude: '-95.9838',
    timeZoneId: 'America/Chicago',
  },
  '68379': {
    zip: '68379',
    city: 'Julian',
    state: 'NE',
    latitude: '40.522306',
    longitude: '-95.868586',
    timeZoneId: 'America/Chicago',
  },
  '68380': {
    zip: '68380',
    city: 'Lewiston',
    state: 'NE',
    latitude: '40.242026',
    longitude: '-96.406983',
    timeZoneId: 'America/Chicago',
  },
  '68381': {
    zip: '68381',
    city: 'Liberty',
    state: 'NE',
    latitude: '40.082959',
    longitude: '-96.516995',
    timeZoneId: 'America/Chicago',
  },
  '68382': {
    zip: '68382',
    city: 'Lorton',
    state: 'NE',
    latitude: '40.5956',
    longitude: '-96.0242',
    timeZoneId: 'America/Chicago',
  },
  '68401': {
    zip: '68401',
    city: 'Mc Cool Junction',
    state: 'NE',
    latitude: '40.744573',
    longitude: '-97.595253',
    timeZoneId: 'America/Chicago',
  },
  '68402': {
    zip: '68402',
    city: 'Malcolm',
    state: 'NE',
    latitude: '40.91099',
    longitude: '-96.868127',
    timeZoneId: 'America/Chicago',
  },
  '68403': {
    zip: '68403',
    city: 'Manley',
    state: 'NE',
    latitude: '40.919042',
    longitude: '-96.164769',
    timeZoneId: 'America/Chicago',
  },
  '68404': {
    zip: '68404',
    city: 'Martell',
    state: 'NE',
    latitude: '40.64262',
    longitude: '-96.74712',
    timeZoneId: 'America/Chicago',
  },
  '68405': {
    zip: '68405',
    city: 'Milford',
    state: 'NE',
    latitude: '40.775066',
    longitude: '-97.054666',
    timeZoneId: 'America/Chicago',
  },
  '68406': {
    zip: '68406',
    city: 'Milligan',
    state: 'NE',
    latitude: '40.501769',
    longitude: '-97.387605',
    timeZoneId: 'America/Chicago',
  },
  '68407': {
    zip: '68407',
    city: 'Murdock',
    state: 'NE',
    latitude: '40.923635',
    longitude: '-96.282292',
    timeZoneId: 'America/Chicago',
  },
  '68409': {
    zip: '68409',
    city: 'Murray',
    state: 'NE',
    latitude: '40.915033',
    longitude: '-95.928545',
    timeZoneId: 'America/Chicago',
  },
  '68410': {
    zip: '68410',
    city: 'Nebraska City',
    state: 'NE',
    latitude: '40.674994',
    longitude: '-95.861844',
    timeZoneId: 'America/Chicago',
  },
  '68413': {
    zip: '68413',
    city: 'Nehawka',
    state: 'NE',
    latitude: '40.833385',
    longitude: '-95.995045',
    timeZoneId: 'America/Chicago',
  },
  '68414': {
    zip: '68414',
    city: 'Nemaha',
    state: 'NE',
    latitude: '40.338842',
    longitude: '-95.67542',
    timeZoneId: 'America/Chicago',
  },
  '68415': {
    zip: '68415',
    city: 'Odell',
    state: 'NE',
    latitude: '40.046798',
    longitude: '-96.803063',
    timeZoneId: 'America/Chicago',
  },
  '68416': {
    zip: '68416',
    city: 'Ohiowa',
    state: 'NE',
    latitude: '40.410905',
    longitude: '-97.452822',
    timeZoneId: 'America/Chicago',
  },
  '68417': {
    zip: '68417',
    city: 'Otoe',
    state: 'NE',
    latitude: '40.724541',
    longitude: '-96.121219',
    timeZoneId: 'America/Chicago',
  },
  '68418': {
    zip: '68418',
    city: 'Palmyra',
    state: 'NE',
    latitude: '40.703411',
    longitude: '-96.394378',
    timeZoneId: 'America/Chicago',
  },
  '68419': {
    zip: '68419',
    city: 'Panama',
    state: 'NE',
    latitude: '40.603592',
    longitude: '-96.507775',
    timeZoneId: 'America/Chicago',
  },
  '68420': {
    zip: '68420',
    city: 'Pawnee City',
    state: 'NE',
    latitude: '40.114423',
    longitude: '-96.15382',
    timeZoneId: 'America/Chicago',
  },
  '68421': {
    zip: '68421',
    city: 'Peru',
    state: 'NE',
    latitude: '40.479889',
    longitude: '-95.731319',
    timeZoneId: 'America/Chicago',
  },
  '68422': {
    zip: '68422',
    city: 'Pickrell',
    state: 'NE',
    latitude: '40.392206',
    longitude: '-96.741093',
    timeZoneId: 'America/Chicago',
  },
  '68423': {
    zip: '68423',
    city: 'Pleasant Dale',
    state: 'NE',
    latitude: '40.791794',
    longitude: '-96.930323',
    timeZoneId: 'America/Chicago',
  },
  '68424': {
    zip: '68424',
    city: 'Plymouth',
    state: 'NE',
    latitude: '40.303913',
    longitude: '-96.98949',
    timeZoneId: 'America/Chicago',
  },
  '68428': {
    zip: '68428',
    city: 'Raymond',
    state: 'NE',
    latitude: '40.97075',
    longitude: '-96.78666',
    timeZoneId: 'America/Chicago',
  },
  '68429': {
    zip: '68429',
    city: 'Reynolds',
    state: 'NE',
    latitude: '40.046208',
    longitude: '-97.312464',
    timeZoneId: 'America/Chicago',
  },
  '68430': {
    zip: '68430',
    city: 'Roca',
    state: 'NE',
    latitude: '40.674181',
    longitude: '-96.66451',
    timeZoneId: 'America/Chicago',
  },
  '68431': {
    zip: '68431',
    city: 'Rulo',
    state: 'NE',
    latitude: '40.04631',
    longitude: '-95.442036',
    timeZoneId: 'America/Chicago',
  },
  '68433': {
    zip: '68433',
    city: 'Salem',
    state: 'NE',
    latitude: '40.043233',
    longitude: '-95.726489',
    timeZoneId: 'America/Chicago',
  },
  '68434': {
    zip: '68434',
    city: 'Seward',
    state: 'NE',
    latitude: '40.91136',
    longitude: '-97.1523',
    timeZoneId: 'America/Chicago',
  },
  '68436': {
    zip: '68436',
    city: 'Shickley',
    state: 'NE',
    latitude: '40.41501',
    longitude: '-97.726502',
    timeZoneId: 'America/Chicago',
  },
  '68437': {
    zip: '68437',
    city: 'Shubert',
    state: 'NE',
    latitude: '40.236902',
    longitude: '-95.683475',
    timeZoneId: 'America/Chicago',
  },
  '68438': {
    zip: '68438',
    city: 'Sprague',
    state: 'NE',
    latitude: '40.62589',
    longitude: '-96.743445',
    timeZoneId: 'America/Chicago',
  },
  '68439': {
    zip: '68439',
    city: 'Staplehurst',
    state: 'NE',
    latitude: '40.973735',
    longitude: '-97.175172',
    timeZoneId: 'America/Chicago',
  },
  '68440': {
    zip: '68440',
    city: 'Steele City',
    state: 'NE',
    latitude: '40.047836',
    longitude: '-96.973471',
    timeZoneId: 'America/Chicago',
  },
  '68441': {
    zip: '68441',
    city: 'Steinauer',
    state: 'NE',
    latitude: '40.207893',
    longitude: '-96.23182',
    timeZoneId: 'America/Chicago',
  },
  '68442': {
    zip: '68442',
    city: 'Stella',
    state: 'NE',
    latitude: '40.229088',
    longitude: '-95.773985',
    timeZoneId: 'America/Chicago',
  },
  '68443': {
    zip: '68443',
    city: 'Sterling',
    state: 'NE',
    latitude: '40.462807',
    longitude: '-96.379563',
    timeZoneId: 'America/Chicago',
  },
  '68444': {
    zip: '68444',
    city: 'Strang',
    state: 'NE',
    latitude: '40.396596',
    longitude: '-97.540543',
    timeZoneId: 'America/Chicago',
  },
  '68445': {
    zip: '68445',
    city: 'Swanton',
    state: 'NE',
    latitude: '40.392864',
    longitude: '-97.086327',
    timeZoneId: 'America/Chicago',
  },
  '68446': {
    zip: '68446',
    city: 'Syracuse',
    state: 'NE',
    latitude: '40.658087',
    longitude: '-96.183226',
    timeZoneId: 'America/Chicago',
  },
  '68447': {
    zip: '68447',
    city: 'Table Rock',
    state: 'NE',
    latitude: '40.18026',
    longitude: '-96.095765',
    timeZoneId: 'America/Chicago',
  },
  '68448': {
    zip: '68448',
    city: 'Talmage',
    state: 'NE',
    latitude: '40.531822',
    longitude: '-96.025289',
    timeZoneId: 'America/Chicago',
  },
  '68450': {
    zip: '68450',
    city: 'Tecumseh',
    state: 'NE',
    latitude: '40.3693',
    longitude: '-96.193526',
    timeZoneId: 'America/Chicago',
  },
  '68452': {
    zip: '68452',
    city: 'Ong',
    state: 'NE',
    latitude: '40.394395',
    longitude: '-97.879427',
    timeZoneId: 'America/Chicago',
  },
  '68453': {
    zip: '68453',
    city: 'Tobias',
    state: 'NE',
    latitude: '40.420152',
    longitude: '-97.340201',
    timeZoneId: 'America/Chicago',
  },
  '68454': {
    zip: '68454',
    city: 'Unadilla',
    state: 'NE',
    latitude: '40.683866',
    longitude: '-96.272409',
    timeZoneId: 'America/Chicago',
  },
  '68455': {
    zip: '68455',
    city: 'Union',
    state: 'NE',
    latitude: '40.828465',
    longitude: '-95.897147',
    timeZoneId: 'America/Chicago',
  },
  '68456': {
    zip: '68456',
    city: 'Utica',
    state: 'NE',
    latitude: '40.896389',
    longitude: '-97.346355',
    timeZoneId: 'America/Chicago',
  },
  '68457': {
    zip: '68457',
    city: 'Verdon',
    state: 'NE',
    latitude: '40.149112',
    longitude: '-95.709711',
    timeZoneId: 'America/Chicago',
  },
  '68458': {
    zip: '68458',
    city: 'Virginia',
    state: 'NE',
    latitude: '40.2312',
    longitude: '-96.52068',
    timeZoneId: 'America/Chicago',
  },
  '68460': {
    zip: '68460',
    city: 'Waco',
    state: 'NE',
    latitude: '40.925505',
    longitude: '-97.443237',
    timeZoneId: 'America/Chicago',
  },
  '68461': {
    zip: '68461',
    city: 'Walton',
    state: 'NE',
    latitude: '40.755755',
    longitude: '-96.512662',
    timeZoneId: 'America/Chicago',
  },
  '68462': {
    zip: '68462',
    city: 'Waverly',
    state: 'NE',
    latitude: '40.918377',
    longitude: '-96.528739',
    timeZoneId: 'America/Chicago',
  },
  '68463': {
    zip: '68463',
    city: 'Weeping Water',
    state: 'NE',
    latitude: '40.866222',
    longitude: '-96.140444',
    timeZoneId: 'America/Chicago',
  },
  '68464': {
    zip: '68464',
    city: 'Western',
    state: 'NE',
    latitude: '40.39536',
    longitude: '-97.232299',
    timeZoneId: 'America/Chicago',
  },
  '68465': {
    zip: '68465',
    city: 'Wilber',
    state: 'NE',
    latitude: '40.481162',
    longitude: '-96.964186',
    timeZoneId: 'America/Chicago',
  },
  '68466': {
    zip: '68466',
    city: 'Wymore',
    state: 'NE',
    latitude: '40.118874',
    longitude: '-96.667542',
    timeZoneId: 'America/Chicago',
  },
  '68467': {
    zip: '68467',
    city: 'York',
    state: 'NE',
    latitude: '40.871762',
    longitude: '-97.587892',
    timeZoneId: 'America/Chicago',
  },
  '68501': {
    zip: '68501',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.823788',
    longitude: '-96.77509',
    timeZoneId: 'America/Chicago',
  },
  '68502': {
    zip: '68502',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.786507',
    longitude: '-96.714244',
    timeZoneId: 'America/Chicago',
  },
  '68503': {
    zip: '68503',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.823017',
    longitude: '-96.673442',
    timeZoneId: 'America/Chicago',
  },
  '68504': {
    zip: '68504',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.837188',
    longitude: '-96.659433',
    timeZoneId: 'America/Chicago',
  },
  '68505': {
    zip: '68505',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.83925',
    longitude: '-96.624615',
    timeZoneId: 'America/Chicago',
  },
  '68506': {
    zip: '68506',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.787792',
    longitude: '-96.653231',
    timeZoneId: 'America/Chicago',
  },
  '68507': {
    zip: '68507',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.850262',
    longitude: '-96.626115',
    timeZoneId: 'America/Chicago',
  },
  '68508': {
    zip: '68508',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.81708',
    longitude: '-96.712063',
    timeZoneId: 'America/Chicago',
  },
  '68509': {
    zip: '68509',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.816704',
    longitude: '-96.709171',
    timeZoneId: 'America/Chicago',
  },
  '68510': {
    zip: '68510',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.804463',
    longitude: '-96.651746',
    timeZoneId: 'America/Chicago',
  },
  '68512': {
    zip: '68512',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.756847',
    longitude: '-96.694879',
    timeZoneId: 'America/Chicago',
  },
  '68514': {
    zip: '68514',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.917763',
    longitude: '-96.648637',
    timeZoneId: 'America/Chicago',
  },
  '68516': {
    zip: '68516',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.752821',
    longitude: '-96.645302',
    timeZoneId: 'America/Chicago',
  },
  '68517': {
    zip: '68517',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.926162',
    longitude: '-96.604232',
    timeZoneId: 'America/Chicago',
  },
  '68520': {
    zip: '68520',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.796037',
    longitude: '-96.58452',
    timeZoneId: 'America/Chicago',
  },
  '68521': {
    zip: '68521',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.848806',
    longitude: '-96.715959',
    timeZoneId: 'America/Chicago',
  },
  '68522': {
    zip: '68522',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.791967',
    longitude: '-96.738854',
    timeZoneId: 'America/Chicago',
  },
  '68523': {
    zip: '68523',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.735318',
    longitude: '-96.757947',
    timeZoneId: 'America/Chicago',
  },
  '68524': {
    zip: '68524',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.857067',
    longitude: '-96.786673',
    timeZoneId: 'America/Chicago',
  },
  '68526': {
    zip: '68526',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.73088',
    longitude: '-96.55146',
    timeZoneId: 'America/Chicago',
  },
  '68527': {
    zip: '68527',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.842546',
    longitude: '-96.535412',
    timeZoneId: 'America/Chicago',
  },
  '68528': {
    zip: '68528',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.81956',
    longitude: '-96.81306',
    timeZoneId: 'America/Chicago',
  },
  '68529': {
    zip: '68529',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.852903',
    longitude: '-96.61311',
    timeZoneId: 'America/Chicago',
  },
  '68531': {
    zip: '68531',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.88528',
    longitude: '-96.713107',
    timeZoneId: 'America/Chicago',
  },
  '68532': {
    zip: '68532',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.792402',
    longitude: '-96.854537',
    timeZoneId: 'America/Chicago',
  },
  '68542': {
    zip: '68542',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.787298',
    longitude: '-96.700531',
    timeZoneId: 'America/Chicago',
  },
  '68544': {
    zip: '68544',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.764099',
    longitude: '-96.693388',
    timeZoneId: 'America/Chicago',
  },
  '68583': {
    zip: '68583',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.828399',
    longitude: '-96.663016',
    timeZoneId: 'America/Chicago',
  },
  '68588': {
    zip: '68588',
    city: 'Lincoln',
    state: 'NE',
    latitude: '40.821099',
    longitude: '-96.711844',
    timeZoneId: 'America/Chicago',
  },
  '68601': {
    zip: '68601',
    city: 'Columbus',
    state: 'NE',
    latitude: '41.4492',
    longitude: '-97.37196',
    timeZoneId: 'America/Chicago',
  },
  '68602': {
    zip: '68602',
    city: 'Columbus',
    state: 'NE',
    latitude: '41.446287',
    longitude: '-97.307733',
    timeZoneId: 'America/Chicago',
  },
  '68620': {
    zip: '68620',
    city: 'Albion',
    state: 'NE',
    latitude: '41.70948',
    longitude: '-98.0022',
    timeZoneId: 'America/Chicago',
  },
  '68621': {
    zip: '68621',
    city: 'Ames',
    state: 'NE',
    latitude: '41.460139',
    longitude: '-96.63913',
    timeZoneId: 'America/Chicago',
  },
  '68622': {
    zip: '68622',
    city: 'Bartlett',
    state: 'NE',
    latitude: '41.863327',
    longitude: '-98.544854',
    timeZoneId: 'America/Chicago',
  },
  '68623': {
    zip: '68623',
    city: 'Belgrade',
    state: 'NE',
    latitude: '41.440895',
    longitude: '-98.115067',
    timeZoneId: 'America/Chicago',
  },
  '68624': {
    zip: '68624',
    city: 'Bellwood',
    state: 'NE',
    latitude: '41.345741',
    longitude: '-97.254735',
    timeZoneId: 'America/Chicago',
  },
  '68626': {
    zip: '68626',
    city: 'Brainard',
    state: 'NE',
    latitude: '41.175159',
    longitude: '-96.967982',
    timeZoneId: 'America/Chicago',
  },
  '68627': {
    zip: '68627',
    city: 'Cedar Rapids',
    state: 'NE',
    latitude: '41.558896',
    longitude: '-98.143523',
    timeZoneId: 'America/Chicago',
  },
  '68628': {
    zip: '68628',
    city: 'Clarks',
    state: 'NE',
    latitude: '41.254383',
    longitude: '-97.849012',
    timeZoneId: 'America/Chicago',
  },
  '68629': {
    zip: '68629',
    city: 'Clarkson',
    state: 'NE',
    latitude: '41.721442',
    longitude: '-97.118226',
    timeZoneId: 'America/Chicago',
  },
  '68631': {
    zip: '68631',
    city: 'Creston',
    state: 'NE',
    latitude: '41.656578',
    longitude: '-97.367518',
    timeZoneId: 'America/Chicago',
  },
  '68632': {
    zip: '68632',
    city: 'David City',
    state: 'NE',
    latitude: '41.243121',
    longitude: '-97.131923',
    timeZoneId: 'America/Chicago',
  },
  '68633': {
    zip: '68633',
    city: 'Dodge',
    state: 'NE',
    latitude: '41.72483',
    longitude: '-96.880938',
    timeZoneId: 'America/Chicago',
  },
  '68634': {
    zip: '68634',
    city: 'Duncan',
    state: 'NE',
    latitude: '41.390994',
    longitude: '-97.538674',
    timeZoneId: 'America/Chicago',
  },
  '68635': {
    zip: '68635',
    city: 'Dwight',
    state: 'NE',
    latitude: '41.089148',
    longitude: '-96.962053',
    timeZoneId: 'America/Chicago',
  },
  '68636': {
    zip: '68636',
    city: 'Elgin',
    state: 'NE',
    latitude: '41.959792',
    longitude: '-98.06542',
    timeZoneId: 'America/Chicago',
  },
  '68637': {
    zip: '68637',
    city: 'Ericson',
    state: 'NE',
    latitude: '41.799293',
    longitude: '-98.662453',
    timeZoneId: 'America/Chicago',
  },
  '68638': {
    zip: '68638',
    city: 'Fullerton',
    state: 'NE',
    latitude: '41.36132',
    longitude: '-97.974859',
    timeZoneId: 'America/Chicago',
  },
  '68640': {
    zip: '68640',
    city: 'Genoa',
    state: 'NE',
    latitude: '41.448122',
    longitude: '-97.730421',
    timeZoneId: 'America/Chicago',
  },
  '68641': {
    zip: '68641',
    city: 'Howells',
    state: 'NE',
    latitude: '41.725645',
    longitude: '-97.002347',
    timeZoneId: 'America/Chicago',
  },
  '68642': {
    zip: '68642',
    city: 'Humphrey',
    state: 'NE',
    latitude: '41.688712',
    longitude: '-97.489112',
    timeZoneId: 'America/Chicago',
  },
  '68643': {
    zip: '68643',
    city: 'Leigh',
    state: 'NE',
    latitude: '41.704228',
    longitude: '-97.231539',
    timeZoneId: 'America/Chicago',
  },
  '68644': {
    zip: '68644',
    city: 'Lindsay',
    state: 'NE',
    latitude: '41.70508',
    longitude: '-97.697472',
    timeZoneId: 'America/Chicago',
  },
  '68647': {
    zip: '68647',
    city: 'Monroe',
    state: 'NE',
    latitude: '41.470368',
    longitude: '-97.612457',
    timeZoneId: 'America/Chicago',
  },
  '68648': {
    zip: '68648',
    city: 'Morse Bluff',
    state: 'NE',
    latitude: '41.41549',
    longitude: '-96.803662',
    timeZoneId: 'America/Chicago',
  },
  '68649': {
    zip: '68649',
    city: 'North Bend',
    state: 'NE',
    latitude: '41.462461',
    longitude: '-96.783977',
    timeZoneId: 'America/Chicago',
  },
  '68651': {
    zip: '68651',
    city: 'Osceola',
    state: 'NE',
    latitude: '41.246471',
    longitude: '-97.572487',
    timeZoneId: 'America/Chicago',
  },
  '68652': {
    zip: '68652',
    city: 'Petersburg',
    state: 'NE',
    latitude: '41.854671',
    longitude: '-98.148496',
    timeZoneId: 'America/Chicago',
  },
  '68653': {
    zip: '68653',
    city: 'Platte Center',
    state: 'NE',
    latitude: '41.526326',
    longitude: '-97.482444',
    timeZoneId: 'America/Chicago',
  },
  '68654': {
    zip: '68654',
    city: 'Polk',
    state: 'NE',
    latitude: '41.075128',
    longitude: '-97.787548',
    timeZoneId: 'America/Chicago',
  },
  '68655': {
    zip: '68655',
    city: 'Primrose',
    state: 'NE',
    latitude: '41.644973',
    longitude: '-98.235707',
    timeZoneId: 'America/Chicago',
  },
  '68658': {
    zip: '68658',
    city: 'Rising City',
    state: 'NE',
    latitude: '41.198815',
    longitude: '-97.295333',
    timeZoneId: 'America/Chicago',
  },
  '68659': {
    zip: '68659',
    city: 'Rogers',
    state: 'NE',
    latitude: '41.468839',
    longitude: '-96.955803',
    timeZoneId: 'America/Chicago',
  },
  '68660': {
    zip: '68660',
    city: 'Saint Edward',
    state: 'NE',
    latitude: '41.572148',
    longitude: '-97.861191',
    timeZoneId: 'America/Chicago',
  },
  '68661': {
    zip: '68661',
    city: 'Schuyler',
    state: 'NE',
    latitude: '41.447978',
    longitude: '-97.057723',
    timeZoneId: 'America/Chicago',
  },
  '68662': {
    zip: '68662',
    city: 'Shelby',
    state: 'NE',
    latitude: '41.267341',
    longitude: '-97.43486',
    timeZoneId: 'America/Chicago',
  },
  '68663': {
    zip: '68663',
    city: 'Silver Creek',
    state: 'NE',
    latitude: '41.315738',
    longitude: '-97.66503',
    timeZoneId: 'America/Chicago',
  },
  '68664': {
    zip: '68664',
    city: 'Snyder',
    state: 'NE',
    latitude: '41.703092',
    longitude: '-96.789139',
    timeZoneId: 'America/Chicago',
  },
  '68665': {
    zip: '68665',
    city: 'Spalding',
    state: 'NE',
    latitude: '41.6987',
    longitude: '-98.395511',
    timeZoneId: 'America/Chicago',
  },
  '68666': {
    zip: '68666',
    city: 'Stromsburg',
    state: 'NE',
    latitude: '41.11466',
    longitude: '-97.592891',
    timeZoneId: 'America/Chicago',
  },
  '68667': {
    zip: '68667',
    city: 'Surprise',
    state: 'NE',
    latitude: '41.09718',
    longitude: '-97.3134',
    timeZoneId: 'America/Chicago',
  },
  '68669': {
    zip: '68669',
    city: 'Ulysses',
    state: 'NE',
    latitude: '41.070396',
    longitude: '-97.202236',
    timeZoneId: 'America/Chicago',
  },
  '68701': {
    zip: '68701',
    city: 'Norfolk',
    state: 'NE',
    latitude: '42.033639',
    longitude: '-97.421914',
    timeZoneId: 'America/Chicago',
  },
  '68702': {
    zip: '68702',
    city: 'Norfolk',
    state: 'NE',
    latitude: '42.026207',
    longitude: '-97.419556',
    timeZoneId: 'America/Chicago',
  },
  '68710': {
    zip: '68710',
    city: 'Allen',
    state: 'NE',
    latitude: '42.414736',
    longitude: '-96.840752',
    timeZoneId: 'America/Chicago',
  },
  '68711': {
    zip: '68711',
    city: 'Amelia',
    state: 'NE',
    latitude: '42.21526',
    longitude: '-98.84291',
    timeZoneId: 'America/Chicago',
  },
  '68713': {
    zip: '68713',
    city: 'Atkinson',
    state: 'NE',
    latitude: '42.530554',
    longitude: '-98.978943',
    timeZoneId: 'America/Chicago',
  },
  '68714': {
    zip: '68714',
    city: 'Bassett',
    state: 'NE',
    latitude: '42.580331',
    longitude: '-99.530917',
    timeZoneId: 'America/Chicago',
  },
  '68715': {
    zip: '68715',
    city: 'Battle Creek',
    state: 'NE',
    latitude: '41.999323',
    longitude: '-97.599609',
    timeZoneId: 'America/Chicago',
  },
  '68716': {
    zip: '68716',
    city: 'Beemer',
    state: 'NE',
    latitude: '41.929617',
    longitude: '-96.809699',
    timeZoneId: 'America/Chicago',
  },
  '68717': {
    zip: '68717',
    city: 'Belden',
    state: 'NE',
    latitude: '42.393887',
    longitude: '-97.193244',
    timeZoneId: 'America/Chicago',
  },
  '68718': {
    zip: '68718',
    city: 'Bloomfield',
    state: 'NE',
    latitude: '42.596572',
    longitude: '-97.648618',
    timeZoneId: 'America/Chicago',
  },
  '68719': {
    zip: '68719',
    city: 'Bristow',
    state: 'NE',
    latitude: '42.89273',
    longitude: '-98.602953',
    timeZoneId: 'America/Chicago',
  },
  '68720': {
    zip: '68720',
    city: 'Brunswick',
    state: 'NE',
    latitude: '42.352542',
    longitude: '-97.952099',
    timeZoneId: 'America/Chicago',
  },
  '68722': {
    zip: '68722',
    city: 'Butte',
    state: 'NE',
    latitude: '42.916707',
    longitude: '-98.831811',
    timeZoneId: 'America/Chicago',
  },
  '68723': {
    zip: '68723',
    city: 'Carroll',
    state: 'NE',
    latitude: '42.284958',
    longitude: '-97.191946',
    timeZoneId: 'America/Chicago',
  },
  '68724': {
    zip: '68724',
    city: 'Center',
    state: 'NE',
    latitude: '42.614295',
    longitude: '-97.892406',
    timeZoneId: 'America/Chicago',
  },
  '68725': {
    zip: '68725',
    city: 'Chambers',
    state: 'NE',
    latitude: '42.145872',
    longitude: '-98.734888',
    timeZoneId: 'America/Chicago',
  },
  '68726': {
    zip: '68726',
    city: 'Clearwater',
    state: 'NE',
    latitude: '42.089728',
    longitude: '-98.180321',
    timeZoneId: 'America/Chicago',
  },
  '68727': {
    zip: '68727',
    city: 'Coleridge',
    state: 'NE',
    latitude: '42.506829',
    longitude: '-97.203319',
    timeZoneId: 'America/Chicago',
  },
  '68728': {
    zip: '68728',
    city: 'Concord',
    state: 'NE',
    latitude: '42.374325',
    longitude: '-96.965134',
    timeZoneId: 'America/Chicago',
  },
  '68729': {
    zip: '68729',
    city: 'Creighton',
    state: 'NE',
    latitude: '42.4627',
    longitude: '-97.906128',
    timeZoneId: 'America/Chicago',
  },
  '68730': {
    zip: '68730',
    city: 'Crofton',
    state: 'NE',
    latitude: '42.744114',
    longitude: '-97.529215',
    timeZoneId: 'America/Chicago',
  },
  '68731': {
    zip: '68731',
    city: 'Dakota City',
    state: 'NE',
    latitude: '42.411172',
    longitude: '-96.41822',
    timeZoneId: 'America/Chicago',
  },
  '68732': {
    zip: '68732',
    city: 'Dixon',
    state: 'NE',
    latitude: '42.414922',
    longitude: '-96.955534',
    timeZoneId: 'America/Chicago',
  },
  '68733': {
    zip: '68733',
    city: 'Emerson',
    state: 'NE',
    latitude: '42.282459',
    longitude: '-96.727307',
    timeZoneId: 'America/Chicago',
  },
  '68734': {
    zip: '68734',
    city: 'Emmet',
    state: 'NE',
    latitude: '42.436057',
    longitude: '-98.830831',
    timeZoneId: 'America/Chicago',
  },
  '68735': {
    zip: '68735',
    city: 'Ewing',
    state: 'NE',
    latitude: '42.257906',
    longitude: '-98.346574',
    timeZoneId: 'America/Chicago',
  },
  '68736': {
    zip: '68736',
    city: 'Fordyce',
    state: 'NE',
    latitude: '42.735874',
    longitude: '-97.36748',
    timeZoneId: 'America/Chicago',
  },
  '68738': {
    zip: '68738',
    city: 'Hadar',
    state: 'NE',
    latitude: '42.106986',
    longitude: '-97.449535',
    timeZoneId: 'America/Chicago',
  },
  '68739': {
    zip: '68739',
    city: 'Hartington',
    state: 'NE',
    latitude: '42.620002',
    longitude: '-97.262062',
    timeZoneId: 'America/Chicago',
  },
  '68740': {
    zip: '68740',
    city: 'Hoskins',
    state: 'NE',
    latitude: '42.178142',
    longitude: '-97.308965',
    timeZoneId: 'America/Chicago',
  },
  '68741': {
    zip: '68741',
    city: 'Hubbard',
    state: 'NE',
    latitude: '42.407532',
    longitude: '-96.62294',
    timeZoneId: 'America/Chicago',
  },
  '68742': {
    zip: '68742',
    city: 'Inman',
    state: 'NE',
    latitude: '42.344111',
    longitude: '-98.553943',
    timeZoneId: 'America/Chicago',
  },
  '68743': {
    zip: '68743',
    city: 'Jackson',
    state: 'NE',
    latitude: '42.467929',
    longitude: '-96.580412',
    timeZoneId: 'America/Chicago',
  },
  '68745': {
    zip: '68745',
    city: 'Laurel',
    state: 'NE',
    latitude: '42.439551',
    longitude: '-97.104567',
    timeZoneId: 'America/Chicago',
  },
  '68746': {
    zip: '68746',
    city: 'Lynch',
    state: 'NE',
    latitude: '42.859206',
    longitude: '-98.43997',
    timeZoneId: 'America/Chicago',
  },
  '68747': {
    zip: '68747',
    city: 'McLean',
    state: 'NE',
    latitude: '42.395646',
    longitude: '-97.475489',
    timeZoneId: 'America/Chicago',
  },
  '68748': {
    zip: '68748',
    city: 'Madison',
    state: 'NE',
    latitude: '41.825641',
    longitude: '-97.456917',
    timeZoneId: 'America/Chicago',
  },
  '68749': {
    zip: '68749',
    city: 'Magnet',
    state: 'NE',
    latitude: '42.476088',
    longitude: '-97.435638',
    timeZoneId: 'America/Chicago',
  },
  '68751': {
    zip: '68751',
    city: 'Maskell',
    state: 'NE',
    latitude: '42.688741',
    longitude: '-96.984169',
    timeZoneId: 'America/Chicago',
  },
  '68752': {
    zip: '68752',
    city: 'Meadow Grove',
    state: 'NE',
    latitude: '42.027426',
    longitude: '-97.733894',
    timeZoneId: 'America/Chicago',
  },
  '68753': {
    zip: '68753',
    city: 'Mills',
    state: 'NE',
    latitude: '42.890184',
    longitude: '-99.453616',
    timeZoneId: 'America/Chicago',
  },
  '68755': {
    zip: '68755',
    city: 'Naper',
    state: 'NE',
    latitude: '42.927102',
    longitude: '-99.089391',
    timeZoneId: 'America/Chicago',
  },
  '68756': {
    zip: '68756',
    city: 'Neligh',
    state: 'NE',
    latitude: '42.129599',
    longitude: '-98.028352',
    timeZoneId: 'America/Chicago',
  },
  '68757': {
    zip: '68757',
    city: 'Newcastle',
    state: 'NE',
    latitude: '42.659384',
    longitude: '-96.88799',
    timeZoneId: 'America/Chicago',
  },
  '68758': {
    zip: '68758',
    city: 'Newman Grove',
    state: 'NE',
    latitude: '41.745732',
    longitude: '-97.776985',
    timeZoneId: 'America/Chicago',
  },
  '68759': {
    zip: '68759',
    city: 'Newport',
    state: 'NE',
    latitude: '42.595928',
    longitude: '-99.336785',
    timeZoneId: 'America/Chicago',
  },
  '68760': {
    zip: '68760',
    city: 'Niobrara',
    state: 'NE',
    latitude: '42.76848',
    longitude: '-98.00988',
    timeZoneId: 'America/Chicago',
  },
  '68761': {
    zip: '68761',
    city: 'Oakdale',
    state: 'NE',
    latitude: '42.047944',
    longitude: '-97.951963',
    timeZoneId: 'America/Chicago',
  },
  '68763': {
    zip: '68763',
    city: 'Oneill',
    state: 'NE',
    latitude: '42.456401',
    longitude: '-98.648879',
    timeZoneId: 'America/Chicago',
  },
  '68764': {
    zip: '68764',
    city: 'Orchard',
    state: 'NE',
    latitude: '42.337337',
    longitude: '-98.2404',
    timeZoneId: 'America/Chicago',
  },
  '68765': {
    zip: '68765',
    city: 'Osmond',
    state: 'NE',
    latitude: '42.344594',
    longitude: '-97.56158',
    timeZoneId: 'America/Chicago',
  },
  '68766': {
    zip: '68766',
    city: 'Page',
    state: 'NE',
    latitude: '42.430282',
    longitude: '-98.374931',
    timeZoneId: 'America/Chicago',
  },
  '68767': {
    zip: '68767',
    city: 'Pierce',
    state: 'NE',
    latitude: '42.199417',
    longitude: '-97.528592',
    timeZoneId: 'America/Chicago',
  },
  '68768': {
    zip: '68768',
    city: 'Pilger',
    state: 'NE',
    latitude: '42.007328',
    longitude: '-97.052909',
    timeZoneId: 'America/Chicago',
  },
  '68769': {
    zip: '68769',
    city: 'Plainview',
    state: 'NE',
    latitude: '42.35309',
    longitude: '-97.783657',
    timeZoneId: 'America/Chicago',
  },
  '68770': {
    zip: '68770',
    city: 'Ponca',
    state: 'NE',
    latitude: '42.581657',
    longitude: '-96.725252',
    timeZoneId: 'America/Chicago',
  },
  '68771': {
    zip: '68771',
    city: 'Randolph',
    state: 'NE',
    latitude: '42.376438',
    longitude: '-97.356521',
    timeZoneId: 'America/Chicago',
  },
  '68773': {
    zip: '68773',
    city: 'Royal',
    state: 'NE',
    latitude: '42.309636',
    longitude: '-98.125775',
    timeZoneId: 'America/Chicago',
  },
  '68774': {
    zip: '68774',
    city: 'Saint Helena',
    state: 'NE',
    latitude: '42.821267',
    longitude: '-97.340702',
    timeZoneId: 'America/Chicago',
  },
  '68776': {
    zip: '68776',
    city: 'South Sioux City',
    state: 'NE',
    latitude: '42.471705',
    longitude: '-96.414132',
    timeZoneId: 'America/Chicago',
  },
  '68777': {
    zip: '68777',
    city: 'Spencer',
    state: 'NE',
    latitude: '42.899532',
    longitude: '-98.71865',
    timeZoneId: 'America/Chicago',
  },
  '68778': {
    zip: '68778',
    city: 'Springview',
    state: 'NE',
    latitude: '42.871901',
    longitude: '-99.877057',
    timeZoneId: 'America/Chicago',
  },
  '68779': {
    zip: '68779',
    city: 'Stanton',
    state: 'NE',
    latitude: '41.952724',
    longitude: '-97.22612',
    timeZoneId: 'America/Chicago',
  },
  '68780': {
    zip: '68780',
    city: 'Stuart',
    state: 'NE',
    latitude: '42.605196',
    longitude: '-99.14277',
    timeZoneId: 'America/Chicago',
  },
  '68781': {
    zip: '68781',
    city: 'Tilden',
    state: 'NE',
    latitude: '42.043913',
    longitude: '-97.828926',
    timeZoneId: 'America/Chicago',
  },
  '68783': {
    zip: '68783',
    city: 'Verdigre',
    state: 'NE',
    latitude: '42.598647',
    longitude: '-98.038208',
    timeZoneId: 'America/Chicago',
  },
  '68784': {
    zip: '68784',
    city: 'Wakefield',
    state: 'NE',
    latitude: '42.268578',
    longitude: '-96.866526',
    timeZoneId: 'America/Chicago',
  },
  '68785': {
    zip: '68785',
    city: 'Waterbury',
    state: 'NE',
    latitude: '42.459205',
    longitude: '-96.743675',
    timeZoneId: 'America/Chicago',
  },
  '68786': {
    zip: '68786',
    city: 'Wausa',
    state: 'NE',
    latitude: '42.498268',
    longitude: '-97.538422',
    timeZoneId: 'America/Chicago',
  },
  '68787': {
    zip: '68787',
    city: 'Wayne',
    state: 'NE',
    latitude: '42.238544',
    longitude: '-97.015602',
    timeZoneId: 'America/Chicago',
  },
  '68788': {
    zip: '68788',
    city: 'West Point',
    state: 'NE',
    latitude: '41.841621',
    longitude: '-96.709733',
    timeZoneId: 'America/Chicago',
  },
  '68789': {
    zip: '68789',
    city: 'Winnetoon',
    state: 'NE',
    latitude: '42.496222',
    longitude: '-98.051704',
    timeZoneId: 'America/Chicago',
  },
  '68790': {
    zip: '68790',
    city: 'Winside',
    state: 'NE',
    latitude: '42.155093',
    longitude: '-97.193363',
    timeZoneId: 'America/Chicago',
  },
  '68791': {
    zip: '68791',
    city: 'Wisner',
    state: 'NE',
    latitude: '41.990286',
    longitude: '-96.914729',
    timeZoneId: 'America/Chicago',
  },
  '68792': {
    zip: '68792',
    city: 'Wynot',
    state: 'NE',
    latitude: '42.741296',
    longitude: '-97.141101',
    timeZoneId: 'America/Chicago',
  },
  '68801': {
    zip: '68801',
    city: 'Grand Island',
    state: 'NE',
    latitude: '40.92332',
    longitude: '-98.33643',
    timeZoneId: 'America/Chicago',
  },
  '68802': {
    zip: '68802',
    city: 'Grand Island',
    state: 'NE',
    latitude: '40.910403',
    longitude: '-98.350673',
    timeZoneId: 'America/Chicago',
  },
  '68803': {
    zip: '68803',
    city: 'Grand Island',
    state: 'NE',
    latitude: '40.926575',
    longitude: '-98.377333',
    timeZoneId: 'America/Chicago',
  },
  '68810': {
    zip: '68810',
    city: 'Alda',
    state: 'NE',
    latitude: '40.87318',
    longitude: '-98.456185',
    timeZoneId: 'America/Chicago',
  },
  '68812': {
    zip: '68812',
    city: 'Amherst',
    state: 'NE',
    latitude: '40.872675',
    longitude: '-99.252513',
    timeZoneId: 'America/Chicago',
  },
  '68813': {
    zip: '68813',
    city: 'Anselmo',
    state: 'NE',
    latitude: '41.64192',
    longitude: '-99.77112',
    timeZoneId: 'America/Chicago',
  },
  '68814': {
    zip: '68814',
    city: 'Ansley',
    state: 'NE',
    latitude: '41.329198',
    longitude: '-99.32679',
    timeZoneId: 'America/Chicago',
  },
  '68815': {
    zip: '68815',
    city: 'Arcadia',
    state: 'NE',
    latitude: '41.52436',
    longitude: '-99.039205',
    timeZoneId: 'America/Chicago',
  },
  '68816': {
    zip: '68816',
    city: 'Archer',
    state: 'NE',
    latitude: '41.177012',
    longitude: '-98.113761',
    timeZoneId: 'America/Chicago',
  },
  '68817': {
    zip: '68817',
    city: 'Ashton',
    state: 'NE',
    latitude: '41.302577',
    longitude: '-98.817867',
    timeZoneId: 'America/Chicago',
  },
  '68818': {
    zip: '68818',
    city: 'Aurora',
    state: 'NE',
    latitude: '40.85508',
    longitude: '-98.0127',
    timeZoneId: 'America/Chicago',
  },
  '68820': {
    zip: '68820',
    city: 'Boelus',
    state: 'NE',
    latitude: '41.120885',
    longitude: '-98.68476',
    timeZoneId: 'America/Chicago',
  },
  '68821': {
    zip: '68821',
    city: 'Brewster',
    state: 'NE',
    latitude: '41.929163',
    longitude: '-99.809807',
    timeZoneId: 'America/Chicago',
  },
  '68822': {
    zip: '68822',
    city: 'Broken Bow',
    state: 'NE',
    latitude: '41.404251',
    longitude: '-99.640958',
    timeZoneId: 'America/Chicago',
  },
  '68823': {
    zip: '68823',
    city: 'Burwell',
    state: 'NE',
    latitude: '41.780417',
    longitude: '-99.133393',
    timeZoneId: 'America/Chicago',
  },
  '68824': {
    zip: '68824',
    city: 'Cairo',
    state: 'NE',
    latitude: '40.999381',
    longitude: '-98.612998',
    timeZoneId: 'America/Chicago',
  },
  '68825': {
    zip: '68825',
    city: 'Callaway',
    state: 'NE',
    latitude: '41.288611',
    longitude: '-99.923023',
    timeZoneId: 'America/Chicago',
  },
  '68826': {
    zip: '68826',
    city: 'Central City',
    state: 'NE',
    latitude: '41.111822',
    longitude: '-98.002519',
    timeZoneId: 'America/Chicago',
  },
  '68827': {
    zip: '68827',
    city: 'Chapman',
    state: 'NE',
    latitude: '40.999123',
    longitude: '-98.204492',
    timeZoneId: 'America/Chicago',
  },
  '68828': {
    zip: '68828',
    city: 'Comstock',
    state: 'NE',
    latitude: '41.536564',
    longitude: '-99.308281',
    timeZoneId: 'America/Chicago',
  },
  '68831': {
    zip: '68831',
    city: 'Dannebrog',
    state: 'NE',
    latitude: '41.126147',
    longitude: '-98.544364',
    timeZoneId: 'America/Chicago',
  },
  '68832': {
    zip: '68832',
    city: 'Doniphan',
    state: 'NE',
    latitude: '40.772243',
    longitude: '-98.370227',
    timeZoneId: 'America/Chicago',
  },
  '68833': {
    zip: '68833',
    city: 'Dunning',
    state: 'NE',
    latitude: '41.787232',
    longitude: '-100.060338',
    timeZoneId: 'America/Chicago',
  },
  '68834': {
    zip: '68834',
    city: 'Eddyville',
    state: 'NE',
    latitude: '41.014295',
    longitude: '-99.70287',
    timeZoneId: 'America/Chicago',
  },
  '68835': {
    zip: '68835',
    city: 'Elba',
    state: 'NE',
    latitude: '41.285075',
    longitude: '-98.591395',
    timeZoneId: 'America/Chicago',
  },
  '68836': {
    zip: '68836',
    city: 'Elm Creek',
    state: 'NE',
    latitude: '40.718701',
    longitude: '-99.373782',
    timeZoneId: 'America/Chicago',
  },
  '68837': {
    zip: '68837',
    city: 'Elyria',
    state: 'NE',
    latitude: '41.695233',
    longitude: '-99.068791',
    timeZoneId: 'America/Chicago',
  },
  '68838': {
    zip: '68838',
    city: 'Farwell',
    state: 'NE',
    latitude: '41.227083',
    longitude: '-98.673138',
    timeZoneId: 'America/Chicago',
  },
  '68840': {
    zip: '68840',
    city: 'Gibbon',
    state: 'NE',
    latitude: '40.745127',
    longitude: '-98.841582',
    timeZoneId: 'America/Chicago',
  },
  '68841': {
    zip: '68841',
    city: 'Giltner',
    state: 'NE',
    latitude: '40.742673',
    longitude: '-98.112964',
    timeZoneId: 'America/Chicago',
  },
  '68842': {
    zip: '68842',
    city: 'Greeley',
    state: 'NE',
    latitude: '41.549174',
    longitude: '-98.536561',
    timeZoneId: 'America/Chicago',
  },
  '68843': {
    zip: '68843',
    city: 'Hampton',
    state: 'NE',
    latitude: '40.881097',
    longitude: '-97.885145',
    timeZoneId: 'America/Chicago',
  },
  '68844': {
    zip: '68844',
    city: 'Hazard',
    state: 'NE',
    latitude: '41.025534',
    longitude: '-98.916251',
    timeZoneId: 'America/Chicago',
  },
  '68845': {
    zip: '68845',
    city: 'Kearney',
    state: 'NE',
    latitude: '40.728578',
    longitude: '-99.160032',
    timeZoneId: 'America/Chicago',
  },
  '68846': {
    zip: '68846',
    city: 'Hordville',
    state: 'NE',
    latitude: '41.084527',
    longitude: '-97.890303',
    timeZoneId: 'America/Chicago',
  },
  '68847': {
    zip: '68847',
    city: 'Kearney',
    state: 'NE',
    latitude: '40.742085',
    longitude: '-99.060441',
    timeZoneId: 'America/Chicago',
  },
  '68848': {
    zip: '68848',
    city: 'Kearney',
    state: 'NE',
    latitude: '40.699406',
    longitude: '-99.08167',
    timeZoneId: 'America/Chicago',
  },
  '68849': {
    zip: '68849',
    city: 'Kearney',
    state: 'NE',
    latitude: '40.702013',
    longitude: '-99.099483',
    timeZoneId: 'America/Chicago',
  },
  '68850': {
    zip: '68850',
    city: 'Lexington',
    state: 'NE',
    latitude: '40.781237',
    longitude: '-99.742805',
    timeZoneId: 'America/Chicago',
  },
  '68852': {
    zip: '68852',
    city: 'Litchfield',
    state: 'NE',
    latitude: '41.199056',
    longitude: '-99.120955',
    timeZoneId: 'America/Chicago',
  },
  '68853': {
    zip: '68853',
    city: 'Loup City',
    state: 'NE',
    latitude: '41.279151',
    longitude: '-98.969658',
    timeZoneId: 'America/Chicago',
  },
  '68854': {
    zip: '68854',
    city: 'Marquette',
    state: 'NE',
    latitude: '41.00597',
    longitude: '-98.008746',
    timeZoneId: 'America/Chicago',
  },
  '68855': {
    zip: '68855',
    city: 'Mason City',
    state: 'NE',
    latitude: '41.162665',
    longitude: '-99.310489',
    timeZoneId: 'America/Chicago',
  },
  '68856': {
    zip: '68856',
    city: 'Merna',
    state: 'NE',
    latitude: '41.395529',
    longitude: '-99.846927',
    timeZoneId: 'America/Chicago',
  },
  '68858': {
    zip: '68858',
    city: 'Miller',
    state: 'NE',
    latitude: '40.958042',
    longitude: '-99.36564',
    timeZoneId: 'America/Chicago',
  },
  '68859': {
    zip: '68859',
    city: 'North Loup',
    state: 'NE',
    latitude: '41.466866',
    longitude: '-98.70589',
    timeZoneId: 'America/Chicago',
  },
  '68860': {
    zip: '68860',
    city: 'Oconto',
    state: 'NE',
    latitude: '41.130711',
    longitude: '-99.664931',
    timeZoneId: 'America/Chicago',
  },
  '68861': {
    zip: '68861',
    city: 'Odessa',
    state: 'NE',
    latitude: '40.711415',
    longitude: '-99.262025',
    timeZoneId: 'America/Chicago',
  },
  '68862': {
    zip: '68862',
    city: 'Ord',
    state: 'NE',
    latitude: '41.599708',
    longitude: '-98.930085',
    timeZoneId: 'America/Chicago',
  },
  '68863': {
    zip: '68863',
    city: 'Overton',
    state: 'NE',
    latitude: '40.739926',
    longitude: '-99.533777',
    timeZoneId: 'America/Chicago',
  },
  '68864': {
    zip: '68864',
    city: 'Palmer',
    state: 'NE',
    latitude: '41.169772',
    longitude: '-98.216393',
    timeZoneId: 'America/Chicago',
  },
  '68865': {
    zip: '68865',
    city: 'Phillips',
    state: 'NE',
    latitude: '40.898339',
    longitude: '-98.21504',
    timeZoneId: 'America/Chicago',
  },
  '68866': {
    zip: '68866',
    city: 'Pleasanton',
    state: 'NE',
    latitude: '40.971884',
    longitude: '-99.085142',
    timeZoneId: 'America/Chicago',
  },
  '68869': {
    zip: '68869',
    city: 'Ravenna',
    state: 'NE',
    latitude: '41.02458',
    longitude: '-98.89302',
    timeZoneId: 'America/Chicago',
  },
  '68870': {
    zip: '68870',
    city: 'Riverdale',
    state: 'NE',
    latitude: '40.784307',
    longitude: '-99.160939',
    timeZoneId: 'America/Chicago',
  },
  '68871': {
    zip: '68871',
    city: 'Rockville',
    state: 'NE',
    latitude: '41.119338',
    longitude: '-98.859015',
    timeZoneId: 'America/Chicago',
  },
  '68872': {
    zip: '68872',
    city: 'Saint Libory',
    state: 'NE',
    latitude: '41.082458',
    longitude: '-98.362472',
    timeZoneId: 'America/Chicago',
  },
  '68873': {
    zip: '68873',
    city: 'Saint Paul',
    state: 'NE',
    latitude: '41.23452',
    longitude: '-98.45052',
    timeZoneId: 'America/Chicago',
  },
  '68874': {
    zip: '68874',
    city: 'Sargent',
    state: 'NE',
    latitude: '41.639988',
    longitude: '-99.371879',
    timeZoneId: 'America/Chicago',
  },
  '68875': {
    zip: '68875',
    city: 'Scotia',
    state: 'NE',
    latitude: '41.469255',
    longitude: '-98.702666',
    timeZoneId: 'America/Chicago',
  },
  '68876': {
    zip: '68876',
    city: 'Shelton',
    state: 'NE',
    latitude: '40.779648',
    longitude: '-98.730476',
    timeZoneId: 'America/Chicago',
  },
  '68878': {
    zip: '68878',
    city: 'Sumner',
    state: 'NE',
    latitude: '40.947194',
    longitude: '-99.527507',
    timeZoneId: 'America/Chicago',
  },
  '68879': {
    zip: '68879',
    city: 'Taylor',
    state: 'NE',
    latitude: '41.85312',
    longitude: '-99.44058',
    timeZoneId: 'America/Chicago',
  },
  '68881': {
    zip: '68881',
    city: 'Westerville',
    state: 'NE',
    latitude: '41.414776',
    longitude: '-99.392022',
    timeZoneId: 'America/Chicago',
  },
  '68882': {
    zip: '68882',
    city: 'Wolbach',
    state: 'NE',
    latitude: '41.468087',
    longitude: '-98.385202',
    timeZoneId: 'America/Chicago',
  },
  '68883': {
    zip: '68883',
    city: 'Wood River',
    state: 'NE',
    latitude: '40.819408',
    longitude: '-98.59785',
    timeZoneId: 'America/Chicago',
  },
  '68901': {
    zip: '68901',
    city: 'Hastings',
    state: 'NE',
    latitude: '40.59276',
    longitude: '-98.41596',
    timeZoneId: 'America/Chicago',
  },
  '68902': {
    zip: '68902',
    city: 'Hastings',
    state: 'NE',
    latitude: '40.585426',
    longitude: '-98.385122',
    timeZoneId: 'America/Chicago',
  },
  '68920': {
    zip: '68920',
    city: 'Alma',
    state: 'NE',
    latitude: '40.102175',
    longitude: '-99.36181',
    timeZoneId: 'America/Chicago',
  },
  '68922': {
    zip: '68922',
    city: 'Arapahoe',
    state: 'NE',
    latitude: '40.307147',
    longitude: '-99.900593',
    timeZoneId: 'America/Chicago',
  },
  '68923': {
    zip: '68923',
    city: 'Atlanta',
    state: 'NE',
    latitude: '40.453315',
    longitude: '-99.34323',
    timeZoneId: 'America/Chicago',
  },
  '68924': {
    zip: '68924',
    city: 'Axtell',
    state: 'NE',
    latitude: '40.480326',
    longitude: '-99.125483',
    timeZoneId: 'America/Chicago',
  },
  '68925': {
    zip: '68925',
    city: 'Ayr',
    state: 'NE',
    latitude: '40.440577',
    longitude: '-98.442822',
    timeZoneId: 'America/Chicago',
  },
  '68926': {
    zip: '68926',
    city: 'Beaver City',
    state: 'NE',
    latitude: '40.138817',
    longitude: '-99.82644',
    timeZoneId: 'America/Chicago',
  },
  '68927': {
    zip: '68927',
    city: 'Bertrand',
    state: 'NE',
    latitude: '40.526584',
    longitude: '-99.632651',
    timeZoneId: 'America/Chicago',
  },
  '68928': {
    zip: '68928',
    city: 'Bladen',
    state: 'NE',
    latitude: '40.26195',
    longitude: '-98.615407',
    timeZoneId: 'America/Chicago',
  },
  '68929': {
    zip: '68929',
    city: 'Bloomington',
    state: 'NE',
    latitude: '40.135092',
    longitude: '-99.009196',
    timeZoneId: 'America/Chicago',
  },
  '68930': {
    zip: '68930',
    city: 'Blue Hill',
    state: 'NE',
    latitude: '40.329281',
    longitude: '-98.45089',
    timeZoneId: 'America/Chicago',
  },
  '68932': {
    zip: '68932',
    city: 'Campbell',
    state: 'NE',
    latitude: '40.298761',
    longitude: '-98.729244',
    timeZoneId: 'America/Chicago',
  },
  '68933': {
    zip: '68933',
    city: 'Clay Center',
    state: 'NE',
    latitude: '40.528949',
    longitude: '-98.058999',
    timeZoneId: 'America/Chicago',
  },
  '68934': {
    zip: '68934',
    city: 'Deweese',
    state: 'NE',
    latitude: '40.35503',
    longitude: '-98.13811',
    timeZoneId: 'America/Chicago',
  },
  '68935': {
    zip: '68935',
    city: 'Edgar',
    state: 'NE',
    latitude: '40.371362',
    longitude: '-97.974067',
    timeZoneId: 'America/Chicago',
  },
  '68936': {
    zip: '68936',
    city: 'Edison',
    state: 'NE',
    latitude: '40.262436',
    longitude: '-99.799141',
    timeZoneId: 'America/Chicago',
  },
  '68937': {
    zip: '68937',
    city: 'Elwood',
    state: 'NE',
    latitude: '40.55784',
    longitude: '-99.85638',
    timeZoneId: 'America/Chicago',
  },
  '68938': {
    zip: '68938',
    city: 'Fairfield',
    state: 'NE',
    latitude: '40.429297',
    longitude: '-98.109807',
    timeZoneId: 'America/Chicago',
  },
  '68939': {
    zip: '68939',
    city: 'Franklin',
    state: 'NE',
    latitude: '40.097909',
    longitude: '-98.95117',
    timeZoneId: 'America/Chicago',
  },
  '68940': {
    zip: '68940',
    city: 'Funk',
    state: 'NE',
    latitude: '40.466264',
    longitude: '-99.248065',
    timeZoneId: 'America/Chicago',
  },
  '68941': {
    zip: '68941',
    city: 'Glenvil',
    state: 'NE',
    latitude: '40.483464',
    longitude: '-98.219562',
    timeZoneId: 'America/Chicago',
  },
  '68942': {
    zip: '68942',
    city: 'Guide Rock',
    state: 'NE',
    latitude: '40.072859',
    longitude: '-98.328721',
    timeZoneId: 'America/Chicago',
  },
  '68943': {
    zip: '68943',
    city: 'Hardy',
    state: 'NE',
    latitude: '40.009261',
    longitude: '-97.92318',
    timeZoneId: 'America/Chicago',
  },
  '68944': {
    zip: '68944',
    city: 'Harvard',
    state: 'NE',
    latitude: '40.622196',
    longitude: '-98.097457',
    timeZoneId: 'America/Chicago',
  },
  '68945': {
    zip: '68945',
    city: 'Heartwell',
    state: 'NE',
    latitude: '40.569121',
    longitude: '-98.779825',
    timeZoneId: 'America/Chicago',
  },
  '68946': {
    zip: '68946',
    city: 'Hendley',
    state: 'NE',
    latitude: '40.089534',
    longitude: '-99.970161',
    timeZoneId: 'America/Chicago',
  },
  '68947': {
    zip: '68947',
    city: 'Hildreth',
    state: 'NE',
    latitude: '40.337326',
    longitude: '-99.045308',
    timeZoneId: 'America/Chicago',
  },
  '68948': {
    zip: '68948',
    city: 'Holbrook',
    state: 'NE',
    latitude: '40.305242',
    longitude: '-100.000159',
    timeZoneId: 'America/Chicago',
  },
  '68949': {
    zip: '68949',
    city: 'Holdrege',
    state: 'NE',
    latitude: '40.4408',
    longitude: '-99.370426',
    timeZoneId: 'America/Chicago',
  },
  '68950': {
    zip: '68950',
    city: 'Holstein',
    state: 'NE',
    latitude: '40.434655',
    longitude: '-98.666771',
    timeZoneId: 'America/Chicago',
  },
  '68952': {
    zip: '68952',
    city: 'Inavale',
    state: 'NE',
    latitude: '40.085139',
    longitude: '-98.667264',
    timeZoneId: 'America/Chicago',
  },
  '68954': {
    zip: '68954',
    city: 'Inland',
    state: 'NE',
    latitude: '40.571077',
    longitude: '-98.228337',
    timeZoneId: 'America/Chicago',
  },
  '68955': {
    zip: '68955',
    city: 'Juniata',
    state: 'NE',
    latitude: '40.58748',
    longitude: '-98.51676',
    timeZoneId: 'America/Chicago',
  },
  '68956': {
    zip: '68956',
    city: 'Kenesaw',
    state: 'NE',
    latitude: '40.625091',
    longitude: '-98.657971',
    timeZoneId: 'America/Chicago',
  },
  '68957': {
    zip: '68957',
    city: 'Lawrence',
    state: 'NE',
    latitude: '40.261367',
    longitude: '-98.214325',
    timeZoneId: 'America/Chicago',
  },
  '68958': {
    zip: '68958',
    city: 'Loomis',
    state: 'NE',
    latitude: '40.475637',
    longitude: '-99.508161',
    timeZoneId: 'America/Chicago',
  },
  '68959': {
    zip: '68959',
    city: 'Minden',
    state: 'NE',
    latitude: '40.493757',
    longitude: '-98.949409',
    timeZoneId: 'America/Chicago',
  },
  '68960': {
    zip: '68960',
    city: 'Naponee',
    state: 'NE',
    latitude: '40.075142',
    longitude: '-99.139486',
    timeZoneId: 'America/Chicago',
  },
  '68961': {
    zip: '68961',
    city: 'Nelson',
    state: 'NE',
    latitude: '40.198823',
    longitude: '-98.070094',
    timeZoneId: 'America/Chicago',
  },
  '68964': {
    zip: '68964',
    city: 'Oak',
    state: 'NE',
    latitude: '40.261972',
    longitude: '-97.878336',
    timeZoneId: 'America/Chicago',
  },
  '68966': {
    zip: '68966',
    city: 'Orleans',
    state: 'NE',
    latitude: '40.176268',
    longitude: '-99.461273',
    timeZoneId: 'America/Chicago',
  },
  '68967': {
    zip: '68967',
    city: 'Oxford',
    state: 'NE',
    latitude: '40.251107',
    longitude: '-99.635999',
    timeZoneId: 'America/Chicago',
  },
  '68969': {
    zip: '68969',
    city: 'Ragan',
    state: 'NE',
    latitude: '40.310839',
    longitude: '-99.25462',
    timeZoneId: 'America/Chicago',
  },
  '68970': {
    zip: '68970',
    city: 'Red Cloud',
    state: 'NE',
    latitude: '40.107696',
    longitude: '-98.518914',
    timeZoneId: 'America/Chicago',
  },
  '68971': {
    zip: '68971',
    city: 'Republican City',
    state: 'NE',
    latitude: '40.100098',
    longitude: '-99.231767',
    timeZoneId: 'America/Chicago',
  },
  '68972': {
    zip: '68972',
    city: 'Riverton',
    state: 'NE',
    latitude: '40.11299',
    longitude: '-98.813748',
    timeZoneId: 'America/Chicago',
  },
  '68973': {
    zip: '68973',
    city: 'Roseland',
    state: 'NE',
    latitude: '40.437498',
    longitude: '-98.550739',
    timeZoneId: 'America/Chicago',
  },
  '68974': {
    zip: '68974',
    city: 'Ruskin',
    state: 'NE',
    latitude: '40.129971',
    longitude: '-97.877465',
    timeZoneId: 'America/Chicago',
  },
  '68975': {
    zip: '68975',
    city: 'Saronville',
    state: 'NE',
    latitude: '40.570578',
    longitude: '-97.938162',
    timeZoneId: 'America/Chicago',
  },
  '68976': {
    zip: '68976',
    city: 'Smithfield',
    state: 'NE',
    latitude: '40.587296',
    longitude: '-99.859973',
    timeZoneId: 'America/Chicago',
  },
  '68977': {
    zip: '68977',
    city: 'Stamford',
    state: 'NE',
    latitude: '40.089103',
    longitude: '-99.575329',
    timeZoneId: 'America/Chicago',
  },
  '68978': {
    zip: '68978',
    city: 'Superior',
    state: 'NE',
    latitude: '40.020913',
    longitude: '-98.064163',
    timeZoneId: 'America/Chicago',
  },
  '68979': {
    zip: '68979',
    city: 'Sutton',
    state: 'NE',
    latitude: '40.605438',
    longitude: '-97.861006',
    timeZoneId: 'America/Chicago',
  },
  '68980': {
    zip: '68980',
    city: 'Trumbull',
    state: 'NE',
    latitude: '40.627431',
    longitude: '-98.095825',
    timeZoneId: 'America/Chicago',
  },
  '68981': {
    zip: '68981',
    city: 'Upland',
    state: 'NE',
    latitude: '40.306386',
    longitude: '-98.894267',
    timeZoneId: 'America/Chicago',
  },
  '68982': {
    zip: '68982',
    city: 'Wilcox',
    state: 'NE',
    latitude: '40.392938',
    longitude: '-99.121319',
    timeZoneId: 'America/Chicago',
  },
  '69001': {
    zip: '69001',
    city: 'Mc Cook',
    state: 'NE',
    latitude: '40.176361',
    longitude: '-100.65334',
    timeZoneId: 'America/Chicago',
  },
  '69020': {
    zip: '69020',
    city: 'Bartley',
    state: 'NE',
    latitude: '40.253074',
    longitude: '-100.305798',
    timeZoneId: 'America/Chicago',
  },
  '69021': {
    zip: '69021',
    city: 'Benkelman',
    state: 'NE',
    latitude: '40.051824',
    longitude: '-101.532367',
    timeZoneId: 'America/Denver',
  },
  '69022': {
    zip: '69022',
    city: 'Cambridge',
    state: 'NE',
    latitude: '40.285841',
    longitude: '-100.171518',
    timeZoneId: 'America/Chicago',
  },
  '69023': {
    zip: '69023',
    city: 'Champion',
    state: 'NE',
    latitude: '40.57241',
    longitude: '-101.97858',
    timeZoneId: 'America/Denver',
  },
  '69024': {
    zip: '69024',
    city: 'Culbertson',
    state: 'NE',
    latitude: '40.228479',
    longitude: '-100.838653',
    timeZoneId: 'America/Chicago',
  },
  '69025': {
    zip: '69025',
    city: 'Curtis',
    state: 'NE',
    latitude: '40.632762',
    longitude: '-100.512458',
    timeZoneId: 'America/Chicago',
  },
  '69026': {
    zip: '69026',
    city: 'Danbury',
    state: 'NE',
    latitude: '40.044247',
    longitude: '-100.420473',
    timeZoneId: 'America/Chicago',
  },
  '69027': {
    zip: '69027',
    city: 'Enders',
    state: 'NE',
    latitude: '40.480349',
    longitude: '-101.515048',
    timeZoneId: 'America/Denver',
  },
  '69028': {
    zip: '69028',
    city: 'Eustis',
    state: 'NE',
    latitude: '40.659907',
    longitude: '-100.032461',
    timeZoneId: 'America/Chicago',
  },
  '69029': {
    zip: '69029',
    city: 'Farnam',
    state: 'NE',
    latitude: '40.708789',
    longitude: '-100.213088',
    timeZoneId: 'America/Chicago',
  },
  '69030': {
    zip: '69030',
    city: 'Haigler',
    state: 'NE',
    latitude: '40.160205',
    longitude: '-101.94528',
    timeZoneId: 'America/Denver',
  },
  '69032': {
    zip: '69032',
    city: 'Hayes Center',
    state: 'NE',
    latitude: '40.542924',
    longitude: '-101.033581',
    timeZoneId: 'America/Chicago',
  },
  '69033': {
    zip: '69033',
    city: 'Imperial',
    state: 'NE',
    latitude: '40.519882',
    longitude: '-101.644043',
    timeZoneId: 'America/Denver',
  },
  '69034': {
    zip: '69034',
    city: 'Indianola',
    state: 'NE',
    latitude: '40.23876',
    longitude: '-100.420615',
    timeZoneId: 'America/Chicago',
  },
  '69036': {
    zip: '69036',
    city: 'Lebanon',
    state: 'NE',
    latitude: '40.086094',
    longitude: '-100.246584',
    timeZoneId: 'America/Chicago',
  },
  '69037': {
    zip: '69037',
    city: 'Max',
    state: 'NE',
    latitude: '40.111864',
    longitude: '-101.380433',
    timeZoneId: 'America/Denver',
  },
  '69038': {
    zip: '69038',
    city: 'Maywood',
    state: 'NE',
    latitude: '40.526811',
    longitude: '-100.66663',
    timeZoneId: 'America/Chicago',
  },
  '69039': {
    zip: '69039',
    city: 'Moorefield',
    state: 'NE',
    latitude: '40.54889',
    longitude: '-100.287277',
    timeZoneId: 'America/Chicago',
  },
  '69040': {
    zip: '69040',
    city: 'Palisade',
    state: 'NE',
    latitude: '40.293079',
    longitude: '-101.075084',
    timeZoneId: 'America/Chicago',
  },
  '69041': {
    zip: '69041',
    city: 'Parks',
    state: 'NE',
    latitude: '40.174827',
    longitude: '-101.766836',
    timeZoneId: 'America/Denver',
  },
  '69042': {
    zip: '69042',
    city: 'Stockville',
    state: 'NE',
    latitude: '40.482959',
    longitude: '-100.381585',
    timeZoneId: 'America/Chicago',
  },
  '69043': {
    zip: '69043',
    city: 'Stratton',
    state: 'NE',
    latitude: '40.149059',
    longitude: '-101.228232',
    timeZoneId: 'America/Chicago',
  },
  '69044': {
    zip: '69044',
    city: 'Trenton',
    state: 'NE',
    latitude: '40.134519',
    longitude: '-101.039622',
    timeZoneId: 'America/Chicago',
  },
  '69045': {
    zip: '69045',
    city: 'Wauneta',
    state: 'NE',
    latitude: '40.414392',
    longitude: '-101.371343',
    timeZoneId: 'America/Denver',
  },
  '69046': {
    zip: '69046',
    city: 'Wilsonville',
    state: 'NE',
    latitude: '40.091024',
    longitude: '-100.135732',
    timeZoneId: 'America/Chicago',
  },
  '69101': {
    zip: '69101',
    city: 'North Platte',
    state: 'NE',
    latitude: '41.133414',
    longitude: '-100.771684',
    timeZoneId: 'America/Chicago',
  },
  '69103': {
    zip: '69103',
    city: 'North Platte',
    state: 'NE',
    latitude: '41.136419',
    longitude: '-100.767544',
    timeZoneId: 'America/Chicago',
  },
  '69120': {
    zip: '69120',
    city: 'Arnold',
    state: 'NE',
    latitude: '41.425135',
    longitude: '-100.195601',
    timeZoneId: 'America/Chicago',
  },
  '69121': {
    zip: '69121',
    city: 'Arthur',
    state: 'NE',
    latitude: '41.570396',
    longitude: '-101.694174',
    timeZoneId: 'America/Denver',
  },
  '69122': {
    zip: '69122',
    city: 'Big Springs',
    state: 'NE',
    latitude: '41.109609',
    longitude: '-102.144422',
    timeZoneId: 'America/Denver',
  },
  '69123': {
    zip: '69123',
    city: 'Brady',
    state: 'NE',
    latitude: '41.06212',
    longitude: '-100.373638',
    timeZoneId: 'America/Chicago',
  },
  '69125': {
    zip: '69125',
    city: 'Broadwater',
    state: 'NE',
    latitude: '41.598355',
    longitude: '-102.850741',
    timeZoneId: 'America/Denver',
  },
  '69127': {
    zip: '69127',
    city: 'Brule',
    state: 'NE',
    latitude: '41.093785',
    longitude: '-101.889393',
    timeZoneId: 'America/Denver',
  },
  '69128': {
    zip: '69128',
    city: 'Bushnell',
    state: 'NE',
    latitude: '41.194059',
    longitude: '-103.92275',
    timeZoneId: 'America/Denver',
  },
  '69129': {
    zip: '69129',
    city: 'Chappell',
    state: 'NE',
    latitude: '41.09273',
    longitude: '-102.4704',
    timeZoneId: 'America/Denver',
  },
  '69130': {
    zip: '69130',
    city: 'Cozad',
    state: 'NE',
    latitude: '40.861917',
    longitude: '-99.983057',
    timeZoneId: 'America/Chicago',
  },
  '69131': {
    zip: '69131',
    city: 'Dalton',
    state: 'NE',
    latitude: '41.408249',
    longitude: '-102.969507',
    timeZoneId: 'America/Denver',
  },
  '69132': {
    zip: '69132',
    city: 'Dickens',
    state: 'NE',
    latitude: '40.816152',
    longitude: '-100.991147',
    timeZoneId: 'America/Chicago',
  },
  '69133': {
    zip: '69133',
    city: 'Dix',
    state: 'NE',
    latitude: '41.200815',
    longitude: '-103.468834',
    timeZoneId: 'America/Denver',
  },
  '69134': {
    zip: '69134',
    city: 'Elsie',
    state: 'NE',
    latitude: '40.851137',
    longitude: '-101.366563',
    timeZoneId: 'America/Denver',
  },
  '69135': {
    zip: '69135',
    city: 'Elsmere',
    state: 'NE',
    latitude: '42.291393',
    longitude: '-100.289209',
    timeZoneId: 'America/Chicago',
  },
  '69138': {
    zip: '69138',
    city: 'Gothenburg',
    state: 'NE',
    latitude: '40.931096',
    longitude: '-100.161748',
    timeZoneId: 'America/Chicago',
  },
  '69140': {
    zip: '69140',
    city: 'Grant',
    state: 'NE',
    latitude: '40.86102',
    longitude: '-101.7084',
    timeZoneId: 'America/Denver',
  },
  '69141': {
    zip: '69141',
    city: 'Gurley',
    state: 'NE',
    latitude: '41.283246',
    longitude: '-102.992375',
    timeZoneId: 'America/Denver',
  },
  '69142': {
    zip: '69142',
    city: 'Halsey',
    state: 'NE',
    latitude: '41.890944',
    longitude: '-100.352183',
    timeZoneId: 'America/Chicago',
  },
  '69143': {
    zip: '69143',
    city: 'Hershey',
    state: 'NE',
    latitude: '41.150252',
    longitude: '-101.004063',
    timeZoneId: 'America/Chicago',
  },
  '69144': {
    zip: '69144',
    city: 'Keystone',
    state: 'NE',
    latitude: '41.304038',
    longitude: '-101.607568',
    timeZoneId: 'America/Denver',
  },
  '69145': {
    zip: '69145',
    city: 'Kimball',
    state: 'NE',
    latitude: '41.234572',
    longitude: '-103.657979',
    timeZoneId: 'America/Denver',
  },
  '69146': {
    zip: '69146',
    city: 'Lemoyne',
    state: 'NE',
    latitude: '41.337848',
    longitude: '-101.877466',
    timeZoneId: 'America/Denver',
  },
  '69147': {
    zip: '69147',
    city: 'Lewellen',
    state: 'NE',
    latitude: '41.44111',
    longitude: '-102.10952',
    timeZoneId: 'America/Denver',
  },
  '69148': {
    zip: '69148',
    city: 'Lisco',
    state: 'NE',
    latitude: '41.567373',
    longitude: '-102.545654',
    timeZoneId: 'America/Denver',
  },
  '69149': {
    zip: '69149',
    city: 'Lodgepole',
    state: 'NE',
    latitude: '41.16786',
    longitude: '-102.65556',
    timeZoneId: 'America/Denver',
  },
  '69150': {
    zip: '69150',
    city: 'Madrid',
    state: 'NE',
    latitude: '40.85238',
    longitude: '-101.534267',
    timeZoneId: 'America/Denver',
  },
  '69151': {
    zip: '69151',
    city: 'Maxwell',
    state: 'NE',
    latitude: '41.081286',
    longitude: '-100.523552',
    timeZoneId: 'America/Chicago',
  },
  '69152': {
    zip: '69152',
    city: 'Mullen',
    state: 'NE',
    latitude: '42.040896',
    longitude: '-101.045057',
    timeZoneId: 'America/Denver',
  },
  '69153': {
    zip: '69153',
    city: 'Ogallala',
    state: 'NE',
    latitude: '41.15496',
    longitude: '-101.67744',
    timeZoneId: 'America/Denver',
  },
  '69154': {
    zip: '69154',
    city: 'Oshkosh',
    state: 'NE',
    latitude: '41.40828',
    longitude: '-102.349472',
    timeZoneId: 'America/Denver',
  },
  '69155': {
    zip: '69155',
    city: 'Paxton',
    state: 'NE',
    latitude: '41.211049',
    longitude: '-101.375901',
    timeZoneId: 'America/Denver',
  },
  '69156': {
    zip: '69156',
    city: 'Potter',
    state: 'NE',
    latitude: '41.220036',
    longitude: '-103.314887',
    timeZoneId: 'America/Denver',
  },
  '69157': {
    zip: '69157',
    city: 'Purdum',
    state: 'NE',
    latitude: '41.960068',
    longitude: '-100.103031',
    timeZoneId: 'America/Chicago',
  },
  '69160': {
    zip: '69160',
    city: 'Sidney',
    state: 'NE',
    latitude: '41.141884',
    longitude: '-102.978079',
    timeZoneId: 'America/Denver',
  },
  '69161': {
    zip: '69161',
    city: 'Seneca',
    state: 'NE',
    latitude: '41.91383',
    longitude: '-100.764572',
    timeZoneId: 'America/Chicago',
  },
  '69162': {
    zip: '69162',
    city: 'Sidney',
    state: 'NE',
    latitude: '41.13132',
    longitude: '-103.03158',
    timeZoneId: 'America/Denver',
  },
  '69163': {
    zip: '69163',
    city: 'Stapleton',
    state: 'NE',
    latitude: '41.5077',
    longitude: '-100.4928',
    timeZoneId: 'America/Chicago',
  },
  '69165': {
    zip: '69165',
    city: 'Sutherland',
    state: 'NE',
    latitude: '41.162515',
    longitude: '-101.123437',
    timeZoneId: 'America/Chicago',
  },
  '69166': {
    zip: '69166',
    city: 'Thedford',
    state: 'NE',
    latitude: '41.978979',
    longitude: '-100.570282',
    timeZoneId: 'America/Chicago',
  },
  '69167': {
    zip: '69167',
    city: 'Tryon',
    state: 'NE',
    latitude: '41.56884',
    longitude: '-101.061231',
    timeZoneId: 'America/Chicago',
  },
  '69168': {
    zip: '69168',
    city: 'Venango',
    state: 'NE',
    latitude: '40.849784',
    longitude: '-101.93608',
    timeZoneId: 'America/Denver',
  },
  '69169': {
    zip: '69169',
    city: 'Wallace',
    state: 'NE',
    latitude: '40.83613',
    longitude: '-101.159212',
    timeZoneId: 'America/Chicago',
  },
  '69170': {
    zip: '69170',
    city: 'Wellfleet',
    state: 'NE',
    latitude: '40.796412',
    longitude: '-100.729124',
    timeZoneId: 'America/Chicago',
  },
  '69171': {
    zip: '69171',
    city: 'Willow Island',
    state: 'NE',
    latitude: '40.852075',
    longitude: '-100.026775',
    timeZoneId: 'America/Chicago',
  },
  '69190': {
    zip: '69190',
    city: 'Oshkosh',
    state: 'NE',
    latitude: '41.40818',
    longitude: '-102.343473',
    timeZoneId: 'America/Denver',
  },
  '69201': {
    zip: '69201',
    city: 'Valentine',
    state: 'NE',
    latitude: '42.876491',
    longitude: '-100.569122',
    timeZoneId: 'America/Chicago',
  },
  '69210': {
    zip: '69210',
    city: 'Ainsworth',
    state: 'NE',
    latitude: '42.548318',
    longitude: '-99.85919',
    timeZoneId: 'America/Chicago',
  },
  '69211': {
    zip: '69211',
    city: 'Cody',
    state: 'NE',
    latitude: '42.93588',
    longitude: '-101.25346',
    timeZoneId: 'America/Chicago',
  },
  '69212': {
    zip: '69212',
    city: 'Crookston',
    state: 'NE',
    latitude: '42.899594',
    longitude: '-100.794946',
    timeZoneId: 'America/Chicago',
  },
  '69214': {
    zip: '69214',
    city: 'Johnstown',
    state: 'NE',
    latitude: '42.459054',
    longitude: '-100.070999',
    timeZoneId: 'America/Chicago',
  },
  '69216': {
    zip: '69216',
    city: 'Kilgore',
    state: 'NE',
    latitude: '42.873857',
    longitude: '-101.013927',
    timeZoneId: 'America/Chicago',
  },
  '69217': {
    zip: '69217',
    city: 'Long Pine',
    state: 'NE',
    latitude: '42.436196',
    longitude: '-99.750801',
    timeZoneId: 'America/Chicago',
  },
  '69218': {
    zip: '69218',
    city: 'Merriman',
    state: 'NE',
    latitude: '42.92159',
    longitude: '-101.70665',
    timeZoneId: 'America/Denver',
  },
  '69219': {
    zip: '69219',
    city: 'Nenzel',
    state: 'NE',
    latitude: '42.92542',
    longitude: '-101.102445',
    timeZoneId: 'America/Chicago',
  },
  '69220': {
    zip: '69220',
    city: 'Sparks',
    state: 'NE',
    latitude: '42.938895',
    longitude: '-100.25996',
    timeZoneId: 'America/Chicago',
  },
  '69221': {
    zip: '69221',
    city: 'Wood Lake',
    state: 'NE',
    latitude: '42.630562',
    longitude: '-100.298622',
    timeZoneId: 'America/Chicago',
  },
  '69301': {
    zip: '69301',
    city: 'Alliance',
    state: 'NE',
    latitude: '42.12438',
    longitude: '-102.8964',
    timeZoneId: 'America/Denver',
  },
  '69331': {
    zip: '69331',
    city: 'Angora',
    state: 'NE',
    latitude: '41.895889',
    longitude: '-103.070624',
    timeZoneId: 'America/Denver',
  },
  '69333': {
    zip: '69333',
    city: 'Ashby',
    state: 'NE',
    latitude: '41.908275',
    longitude: '-101.952587',
    timeZoneId: 'America/Denver',
  },
  '69334': {
    zip: '69334',
    city: 'Bayard',
    state: 'NE',
    latitude: '41.759415',
    longitude: '-103.322311',
    timeZoneId: 'America/Denver',
  },
  '69335': {
    zip: '69335',
    city: 'Bingham',
    state: 'NE',
    latitude: '42.266896',
    longitude: '-102.155767',
    timeZoneId: 'America/Denver',
  },
  '69336': {
    zip: '69336',
    city: 'Bridgeport',
    state: 'NE',
    latitude: '41.67474',
    longitude: '-103.05426',
    timeZoneId: 'America/Denver',
  },
  '69337': {
    zip: '69337',
    city: 'Chadron',
    state: 'NE',
    latitude: '42.831016',
    longitude: '-103.001366',
    timeZoneId: 'America/Denver',
  },
  '69339': {
    zip: '69339',
    city: 'Crawford',
    state: 'NE',
    latitude: '42.65832',
    longitude: '-103.40994',
    timeZoneId: 'America/Denver',
  },
  '69340': {
    zip: '69340',
    city: 'Ellsworth',
    state: 'NE',
    latitude: '42.174219',
    longitude: '-102.458438',
    timeZoneId: 'America/Denver',
  },
  '69341': {
    zip: '69341',
    city: 'Gering',
    state: 'NE',
    latitude: '41.80464',
    longitude: '-103.71696',
    timeZoneId: 'America/Denver',
  },
  '69343': {
    zip: '69343',
    city: 'Gordon',
    state: 'NE',
    latitude: '42.808801',
    longitude: '-102.202166',
    timeZoneId: 'America/Denver',
  },
  '69345': {
    zip: '69345',
    city: 'Harrisburg',
    state: 'NE',
    latitude: '41.547935',
    longitude: '-103.710058',
    timeZoneId: 'America/Denver',
  },
  '69346': {
    zip: '69346',
    city: 'Harrison',
    state: 'NE',
    latitude: '42.688129',
    longitude: '-103.88256',
    timeZoneId: 'America/Denver',
  },
  '69347': {
    zip: '69347',
    city: 'Hay Springs',
    state: 'NE',
    latitude: '42.682114',
    longitude: '-102.690841',
    timeZoneId: 'America/Denver',
  },
  '69348': {
    zip: '69348',
    city: 'Hemingford',
    state: 'NE',
    latitude: '42.316869',
    longitude: '-103.075882',
    timeZoneId: 'America/Denver',
  },
  '69350': {
    zip: '69350',
    city: 'Hyannis',
    state: 'NE',
    latitude: '42.002999',
    longitude: '-101.762656',
    timeZoneId: 'America/Denver',
  },
  '69351': {
    zip: '69351',
    city: 'Lakeside',
    state: 'NE',
    latitude: '42.073311',
    longitude: '-102.407964',
    timeZoneId: 'America/Denver',
  },
  '69352': {
    zip: '69352',
    city: 'Lyman',
    state: 'NE',
    latitude: '41.914106',
    longitude: '-104.037046',
    timeZoneId: 'America/Denver',
  },
  '69353': {
    zip: '69353',
    city: 'McGrew',
    state: 'NE',
    latitude: '41.785535',
    longitude: '-103.681149',
    timeZoneId: 'America/Denver',
  },
  '69354': {
    zip: '69354',
    city: 'Marsland',
    state: 'NE',
    latitude: '42.527744',
    longitude: '-103.106295',
    timeZoneId: 'America/Denver',
  },
  '69355': {
    zip: '69355',
    city: 'Melbeta',
    state: 'NE',
    latitude: '41.781301',
    longitude: '-103.682107',
    timeZoneId: 'America/Denver',
  },
  '69356': {
    zip: '69356',
    city: 'Minatare',
    state: 'NE',
    latitude: '41.853491',
    longitude: '-103.615644',
    timeZoneId: 'America/Denver',
  },
  '69357': {
    zip: '69357',
    city: 'Mitchell',
    state: 'NE',
    latitude: '41.940372',
    longitude: '-103.80844',
    timeZoneId: 'America/Denver',
  },
  '69358': {
    zip: '69358',
    city: 'Morrill',
    state: 'NE',
    latitude: '41.962994',
    longitude: '-103.926289',
    timeZoneId: 'America/Denver',
  },
  '69360': {
    zip: '69360',
    city: 'Rushville',
    state: 'NE',
    latitude: '42.719148',
    longitude: '-102.456609',
    timeZoneId: 'America/Denver',
  },
  '69361': {
    zip: '69361',
    city: 'Scottsbluff',
    state: 'NE',
    latitude: '41.866912',
    longitude: '-103.658986',
    timeZoneId: 'America/Denver',
  },
  '69363': {
    zip: '69363',
    city: 'Scottsbluff',
    state: 'NE',
    latitude: '41.868379',
    longitude: '-103.658193',
    timeZoneId: 'America/Denver',
  },
  '69365': {
    zip: '69365',
    city: 'Whiteclay',
    state: 'NE',
    latitude: '42.99652',
    longitude: '-102.55571',
    timeZoneId: 'America/Denver',
  },
  '69366': {
    zip: '69366',
    city: 'Whitman',
    state: 'NE',
    latitude: '41.918254',
    longitude: '-101.535684',
    timeZoneId: 'America/Denver',
  },
  '69367': {
    zip: '69367',
    city: 'Whitney',
    state: 'NE',
    latitude: '42.739218',
    longitude: '-103.225707',
    timeZoneId: 'America/Denver',
  },
  '70001': {
    zip: '70001',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.986801',
    longitude: '-90.166314',
    timeZoneId: 'America/Chicago',
  },
  '70002': {
    zip: '70002',
    city: 'Metairie',
    state: 'LA',
    latitude: '30.009645',
    longitude: '-90.160453',
    timeZoneId: 'America/Chicago',
  },
  '70003': {
    zip: '70003',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.9956',
    longitude: '-90.214291',
    timeZoneId: 'America/Chicago',
  },
  '70004': {
    zip: '70004',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.999438',
    longitude: '-90.179568',
    timeZoneId: 'America/Chicago',
  },
  '70005': {
    zip: '70005',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.999098',
    longitude: '-90.135663',
    timeZoneId: 'America/Chicago',
  },
  '70006': {
    zip: '70006',
    city: 'Metairie',
    state: 'LA',
    latitude: '30.01368',
    longitude: '-90.194627',
    timeZoneId: 'America/Chicago',
  },
  '70009': {
    zip: '70009',
    city: 'Metairie',
    state: 'LA',
    latitude: '30.008629',
    longitude: '-90.16379',
    timeZoneId: 'America/Chicago',
  },
  '70010': {
    zip: '70010',
    city: 'Metairie',
    state: 'LA',
    latitude: '30.010121',
    longitude: '-90.161717',
    timeZoneId: 'America/Chicago',
  },
  '70011': {
    zip: '70011',
    city: 'Metairie',
    state: 'LA',
    latitude: '30.009593',
    longitude: '-90.155327',
    timeZoneId: 'America/Chicago',
  },
  '70030': {
    zip: '70030',
    city: 'Des Allemands',
    state: 'LA',
    latitude: '29.822943',
    longitude: '-90.464715',
    timeZoneId: 'America/Chicago',
  },
  '70031': {
    zip: '70031',
    city: 'Ama',
    state: 'LA',
    latitude: '29.853609',
    longitude: '-90.298706',
    timeZoneId: 'America/Chicago',
  },
  '70032': {
    zip: '70032',
    city: 'Arabi',
    state: 'LA',
    latitude: '29.957958',
    longitude: '-89.997724',
    timeZoneId: 'America/Chicago',
  },
  '70033': {
    zip: '70033',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.997577',
    longitude: '-90.213594',
    timeZoneId: 'America/Chicago',
  },
  '70036': {
    zip: '70036',
    city: 'Barataria',
    state: 'LA',
    latitude: '29.693729',
    longitude: '-90.104339',
    timeZoneId: 'America/Chicago',
  },
  '70037': {
    zip: '70037',
    city: 'Belle Chasse',
    state: 'LA',
    latitude: '29.81468',
    longitude: '-90.009629',
    timeZoneId: 'America/Chicago',
  },
  '70038': {
    zip: '70038',
    city: 'Boothville',
    state: 'LA',
    latitude: '29.346675',
    longitude: '-89.42251',
    timeZoneId: 'America/Chicago',
  },
  '70039': {
    zip: '70039',
    city: 'Boutte',
    state: 'LA',
    latitude: '29.885582',
    longitude: '-90.38995',
    timeZoneId: 'America/Chicago',
  },
  '70040': {
    zip: '70040',
    city: 'Braithwaite',
    state: 'LA',
    latitude: '29.827869',
    longitude: '-89.963279',
    timeZoneId: 'America/Chicago',
  },
  '70041': {
    zip: '70041',
    city: 'Buras',
    state: 'LA',
    latitude: '29.3529',
    longitude: '-89.50206',
    timeZoneId: 'America/Chicago',
  },
  '70043': {
    zip: '70043',
    city: 'Chalmette',
    state: 'LA',
    latitude: '29.946707',
    longitude: '-89.963929',
    timeZoneId: 'America/Chicago',
  },
  '70044': {
    zip: '70044',
    city: 'Chalmette',
    state: 'LA',
    latitude: '29.951794',
    longitude: '-89.959355',
    timeZoneId: 'America/Chicago',
  },
  '70047': {
    zip: '70047',
    city: 'Destrehan',
    state: 'LA',
    latitude: '29.963928',
    longitude: '-90.375524',
    timeZoneId: 'America/Chicago',
  },
  '70049': {
    zip: '70049',
    city: 'Edgard',
    state: 'LA',
    latitude: '30.03366',
    longitude: '-90.55662',
    timeZoneId: 'America/Chicago',
  },
  '70050': {
    zip: '70050',
    city: 'Empire',
    state: 'LA',
    latitude: '29.370658',
    longitude: '-89.570686',
    timeZoneId: 'America/Chicago',
  },
  '70051': {
    zip: '70051',
    city: 'Garyville',
    state: 'LA',
    latitude: '30.124963',
    longitude: '-90.609115',
    timeZoneId: 'America/Chicago',
  },
  '70052': {
    zip: '70052',
    city: 'Gramercy',
    state: 'LA',
    latitude: '30.102717',
    longitude: '-90.695822',
    timeZoneId: 'America/Chicago',
  },
  '70053': {
    zip: '70053',
    city: 'Gretna',
    state: 'LA',
    latitude: '29.911883',
    longitude: '-90.052716',
    timeZoneId: 'America/Chicago',
  },
  '70054': {
    zip: '70054',
    city: 'Gretna',
    state: 'LA',
    latitude: '29.912367',
    longitude: '-90.054585',
    timeZoneId: 'America/Chicago',
  },
  '70055': {
    zip: '70055',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.998968',
    longitude: '-90.134242',
    timeZoneId: 'America/Chicago',
  },
  '70056': {
    zip: '70056',
    city: 'Gretna',
    state: 'LA',
    latitude: '29.890719',
    longitude: '-90.030182',
    timeZoneId: 'America/Chicago',
  },
  '70057': {
    zip: '70057',
    city: 'Hahnville',
    state: 'LA',
    latitude: '29.938506',
    longitude: '-90.48059',
    timeZoneId: 'America/Chicago',
  },
  '70058': {
    zip: '70058',
    city: 'Harvey',
    state: 'LA',
    latitude: '29.878094',
    longitude: '-90.069635',
    timeZoneId: 'America/Chicago',
  },
  '70059': {
    zip: '70059',
    city: 'Harvey',
    state: 'LA',
    latitude: '29.904042',
    longitude: '-90.079366',
    timeZoneId: 'America/Chicago',
  },
  '70060': {
    zip: '70060',
    city: 'Metairie',
    state: 'LA',
    latitude: '29.867768',
    longitude: '-90.068648',
    timeZoneId: 'America/Chicago',
  },
  '70062': {
    zip: '70062',
    city: 'Kenner',
    state: 'LA',
    latitude: '29.988618',
    longitude: '-90.250441',
    timeZoneId: 'America/Chicago',
  },
  '70063': {
    zip: '70063',
    city: 'Kenner',
    state: 'LA',
    latitude: '29.992959',
    longitude: '-90.253629',
    timeZoneId: 'America/Chicago',
  },
  '70064': {
    zip: '70064',
    city: 'Kenner',
    state: 'LA',
    latitude: '30.023196',
    longitude: '-90.251979',
    timeZoneId: 'America/Chicago',
  },
  '70065': {
    zip: '70065',
    city: 'Kenner',
    state: 'LA',
    latitude: '30.024262',
    longitude: '-90.249125',
    timeZoneId: 'America/Chicago',
  },
  '70067': {
    zip: '70067',
    city: 'Lafitte',
    state: 'LA',
    latitude: '29.721001',
    longitude: '-90.123913',
    timeZoneId: 'America/Chicago',
  },
  '70068': {
    zip: '70068',
    city: 'La Place',
    state: 'LA',
    latitude: '30.05496',
    longitude: '-90.48054',
    timeZoneId: 'America/Chicago',
  },
  '70069': {
    zip: '70069',
    city: 'La Place',
    state: 'LA',
    latitude: '30.06438',
    longitude: '-90.48252',
    timeZoneId: 'America/Chicago',
  },
  '70070': {
    zip: '70070',
    city: 'Luling',
    state: 'LA',
    latitude: '29.92587',
    longitude: '-90.372616',
    timeZoneId: 'America/Chicago',
  },
  '70071': {
    zip: '70071',
    city: 'Lutcher',
    state: 'LA',
    latitude: '30.060777',
    longitude: '-90.709558',
    timeZoneId: 'America/Chicago',
  },
  '70072': {
    zip: '70072',
    city: 'Marrero',
    state: 'LA',
    latitude: '29.85768',
    longitude: '-90.13116',
    timeZoneId: 'America/Chicago',
  },
  '70073': {
    zip: '70073',
    city: 'Marrero',
    state: 'LA',
    latitude: '29.862287',
    longitude: '-90.119262',
    timeZoneId: 'America/Chicago',
  },
  '70075': {
    zip: '70075',
    city: 'Meraux',
    state: 'LA',
    latitude: '29.94887',
    longitude: '-89.8986',
    timeZoneId: 'America/Chicago',
  },
  '70076': {
    zip: '70076',
    city: 'Mount Airy',
    state: 'LA',
    latitude: '30.060295',
    longitude: '-90.636603',
    timeZoneId: 'America/Chicago',
  },
  '70078': {
    zip: '70078',
    city: 'New Sarpy',
    state: 'LA',
    latitude: '29.98685',
    longitude: '-90.386567',
    timeZoneId: 'America/Chicago',
  },
  '70079': {
    zip: '70079',
    city: 'Norco',
    state: 'LA',
    latitude: '30.0105',
    longitude: '-90.42066',
    timeZoneId: 'America/Chicago',
  },
  '70080': {
    zip: '70080',
    city: 'Paradis',
    state: 'LA',
    latitude: '29.879077',
    longitude: '-90.437083',
    timeZoneId: 'America/Chicago',
  },
  '70081': {
    zip: '70081',
    city: 'Pilottown',
    state: 'LA',
    latitude: '29.179853',
    longitude: '-89.256974',
    timeZoneId: 'America/Chicago',
  },
  '70082': {
    zip: '70082',
    city: 'Pointe A la Hache',
    state: 'LA',
    latitude: '29.575598',
    longitude: '-89.793242',
    timeZoneId: 'America/Chicago',
  },
  '70083': {
    zip: '70083',
    city: 'Port Sulphur',
    state: 'LA',
    latitude: '29.446151',
    longitude: '-89.764678',
    timeZoneId: 'America/Chicago',
  },
  '70084': {
    zip: '70084',
    city: 'Reserve',
    state: 'LA',
    latitude: '30.069968',
    longitude: '-90.557182',
    timeZoneId: 'America/Chicago',
  },
  '70085': {
    zip: '70085',
    city: 'Saint Bernard',
    state: 'LA',
    latitude: '29.86428',
    longitude: '-89.78958',
    timeZoneId: 'America/Chicago',
  },
  '70086': {
    zip: '70086',
    city: 'Saint James',
    state: 'LA',
    latitude: '29.970697',
    longitude: '-90.862644',
    timeZoneId: 'America/Chicago',
  },
  '70087': {
    zip: '70087',
    city: 'Saint Rose',
    state: 'LA',
    latitude: '29.982463',
    longitude: '-90.313325',
    timeZoneId: 'America/Chicago',
  },
  '70090': {
    zip: '70090',
    city: 'Vacherie',
    state: 'LA',
    latitude: '29.961304',
    longitude: '-90.706982',
    timeZoneId: 'America/Chicago',
  },
  '70091': {
    zip: '70091',
    city: 'Venice',
    state: 'LA',
    latitude: '29.209341',
    longitude: '-89.231358',
    timeZoneId: 'America/Chicago',
  },
  '70092': {
    zip: '70092',
    city: 'Violet',
    state: 'LA',
    latitude: '29.915069',
    longitude: '-89.862837',
    timeZoneId: 'America/Chicago',
  },
  '70093': {
    zip: '70093',
    city: 'Belle Chasse',
    state: 'LA',
    latitude: '29.85013',
    longitude: '-89.988599',
    timeZoneId: 'America/Chicago',
  },
  '70094': {
    zip: '70094',
    city: 'Westwego',
    state: 'LA',
    latitude: '29.92698',
    longitude: '-90.20412',
    timeZoneId: 'America/Chicago',
  },
  '70096': {
    zip: '70096',
    city: 'Westwego',
    state: 'LA',
    latitude: '29.905251',
    longitude: '-90.142705',
    timeZoneId: 'America/Chicago',
  },
  '70097': {
    zip: '70097',
    city: 'Kenner',
    state: 'LA',
    latitude: '29.98988',
    longitude: '-90.242058',
    timeZoneId: 'America/Chicago',
  },
  '70112': {
    zip: '70112',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.956043',
    longitude: '-90.079214',
    timeZoneId: 'America/Chicago',
  },
  '70113': {
    zip: '70113',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.943595',
    longitude: '-90.08315',
    timeZoneId: 'America/Chicago',
  },
  '70114': {
    zip: '70114',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.93076',
    longitude: '-90.03282',
    timeZoneId: 'America/Chicago',
  },
  '70115': {
    zip: '70115',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.924584',
    longitude: '-90.101956',
    timeZoneId: 'America/Chicago',
  },
  '70116': {
    zip: '70116',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.967019',
    longitude: '-90.068695',
    timeZoneId: 'America/Chicago',
  },
  '70117': {
    zip: '70117',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.96924',
    longitude: '-90.029607',
    timeZoneId: 'America/Chicago',
  },
  '70118': {
    zip: '70118',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.954635',
    longitude: '-90.123422',
    timeZoneId: 'America/Chicago',
  },
  '70119': {
    zip: '70119',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.974249',
    longitude: '-90.087137',
    timeZoneId: 'America/Chicago',
  },
  '70121': {
    zip: '70121',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.964212',
    longitude: '-90.156847',
    timeZoneId: 'America/Chicago',
  },
  '70122': {
    zip: '70122',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.005955',
    longitude: '-90.065104',
    timeZoneId: 'America/Chicago',
  },
  '70123': {
    zip: '70123',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.956536',
    longitude: '-90.207527',
    timeZoneId: 'America/Chicago',
  },
  '70124': {
    zip: '70124',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.00852',
    longitude: '-90.106859',
    timeZoneId: 'America/Chicago',
  },
  '70125': {
    zip: '70125',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.952064',
    longitude: '-90.105984',
    timeZoneId: 'America/Chicago',
  },
  '70126': {
    zip: '70126',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.017111',
    longitude: '-90.019351',
    timeZoneId: 'America/Chicago',
  },
  '70127': {
    zip: '70127',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.030503',
    longitude: '-89.979564',
    timeZoneId: 'America/Chicago',
  },
  '70128': {
    zip: '70128',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.051625',
    longitude: '-89.957703',
    timeZoneId: 'America/Chicago',
  },
  '70129': {
    zip: '70129',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.046012',
    longitude: '-89.902076',
    timeZoneId: 'America/Chicago',
  },
  '70130': {
    zip: '70130',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.935313',
    longitude: '-90.068825',
    timeZoneId: 'America/Chicago',
  },
  '70131': {
    zip: '70131',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.917334',
    longitude: '-89.993719',
    timeZoneId: 'America/Chicago',
  },
  '70139': {
    zip: '70139',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.949141',
    longitude: '-90.071942',
    timeZoneId: 'America/Chicago',
  },
  '70141': {
    zip: '70141',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.99056',
    longitude: '-90.255338',
    timeZoneId: 'America/Chicago',
  },
  '70142': {
    zip: '70142',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.947342',
    longitude: '-90.030163',
    timeZoneId: 'America/Chicago',
  },
  '70143': {
    zip: '70143',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.822294',
    longitude: '-90.011918',
    timeZoneId: 'America/Chicago',
  },
  '70145': {
    zip: '70145',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.961146',
    longitude: '-90.035413',
    timeZoneId: 'America/Chicago',
  },
  '70146': {
    zip: '70146',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.033612',
    longitude: '-90.041438',
    timeZoneId: 'America/Chicago',
  },
  '70148': {
    zip: '70148',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.029172',
    longitude: '-90.068447',
    timeZoneId: 'America/Chicago',
  },
  '70150': {
    zip: '70150',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.944559',
    longitude: '-90.084953',
    timeZoneId: 'America/Chicago',
  },
  '70151': {
    zip: '70151',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.946059',
    longitude: '-90.083357',
    timeZoneId: 'America/Chicago',
  },
  '70152': {
    zip: '70152',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.941971',
    longitude: '-90.084458',
    timeZoneId: 'America/Chicago',
  },
  '70153': {
    zip: '70153',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.941366',
    longitude: '-90.085663',
    timeZoneId: 'America/Chicago',
  },
  '70154': {
    zip: '70154',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.920683',
    longitude: '-90.097011',
    timeZoneId: 'America/Chicago',
  },
  '70156': {
    zip: '70156',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.944269',
    longitude: '-90.085697',
    timeZoneId: 'America/Chicago',
  },
  '70157': {
    zip: '70157',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.944867',
    longitude: '-90.082037',
    timeZoneId: 'America/Chicago',
  },
  '70158': {
    zip: '70158',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.922943',
    longitude: '-90.068665',
    timeZoneId: 'America/Chicago',
  },
  '70159': {
    zip: '70159',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.969507',
    longitude: '-90.028428',
    timeZoneId: 'America/Chicago',
  },
  '70160': {
    zip: '70160',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.940419',
    longitude: '-90.084959',
    timeZoneId: 'America/Chicago',
  },
  '70161': {
    zip: '70161',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.941124',
    longitude: '-90.080453',
    timeZoneId: 'America/Chicago',
  },
  '70162': {
    zip: '70162',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.958855',
    longitude: '-90.07491',
    timeZoneId: 'America/Chicago',
  },
  '70163': {
    zip: '70163',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.952763',
    longitude: '-90.076684',
    timeZoneId: 'America/Chicago',
  },
  '70164': {
    zip: '70164',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.0036',
    longitude: '-90.00273',
    timeZoneId: 'America/Chicago',
  },
  '70165': {
    zip: '70165',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.944694',
    longitude: '-90.071602',
    timeZoneId: 'America/Chicago',
  },
  '70166': {
    zip: '70166',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.948632',
    longitude: '-90.07374',
    timeZoneId: 'America/Chicago',
  },
  '70167': {
    zip: '70167',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.964802',
    longitude: '-90.084829',
    timeZoneId: 'America/Chicago',
  },
  '70170': {
    zip: '70170',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.951633',
    longitude: '-90.071628',
    timeZoneId: 'America/Chicago',
  },
  '70172': {
    zip: '70172',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.955546',
    longitude: '-90.072693',
    timeZoneId: 'America/Chicago',
  },
  '70174': {
    zip: '70174',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.926628',
    longitude: '-90.017422',
    timeZoneId: 'America/Chicago',
  },
  '70175': {
    zip: '70175',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.924771',
    longitude: '-90.104056',
    timeZoneId: 'America/Chicago',
  },
  '70176': {
    zip: '70176',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.933672',
    longitude: '-90.072503',
    timeZoneId: 'America/Chicago',
  },
  '70177': {
    zip: '70177',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.98138',
    longitude: '-90.003173',
    timeZoneId: 'America/Chicago',
  },
  '70178': {
    zip: '70178',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.944263',
    longitude: '-90.12277',
    timeZoneId: 'America/Chicago',
  },
  '70179': {
    zip: '70179',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.974498',
    longitude: '-90.08715',
    timeZoneId: 'America/Chicago',
  },
  '70181': {
    zip: '70181',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.96385',
    longitude: '-90.0671',
    timeZoneId: 'America/Chicago',
  },
  '70182': {
    zip: '70182',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.011522',
    longitude: '-90.062783',
    timeZoneId: 'America/Chicago',
  },
  '70183': {
    zip: '70183',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.954215',
    longitude: '-90.204675',
    timeZoneId: 'America/Chicago',
  },
  '70184': {
    zip: '70184',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.006203',
    longitude: '-90.107282',
    timeZoneId: 'America/Chicago',
  },
  '70185': {
    zip: '70185',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.952425',
    longitude: '-90.104368',
    timeZoneId: 'America/Chicago',
  },
  '70186': {
    zip: '70186',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.004149',
    longitude: '-90.001369',
    timeZoneId: 'America/Chicago',
  },
  '70187': {
    zip: '70187',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.026248',
    longitude: '-89.973423',
    timeZoneId: 'America/Chicago',
  },
  '70189': {
    zip: '70189',
    city: 'New Orleans',
    state: 'LA',
    latitude: '30.073336',
    longitude: '-89.817298',
    timeZoneId: 'America/Chicago',
  },
  '70190': {
    zip: '70190',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.940241',
    longitude: '-90.069378',
    timeZoneId: 'America/Chicago',
  },
  '70195': {
    zip: '70195',
    city: 'New Orleans',
    state: 'LA',
    latitude: '29.953286',
    longitude: '-90.071666',
    timeZoneId: 'America/Chicago',
  },
  '70301': {
    zip: '70301',
    city: 'Thibodaux',
    state: 'LA',
    latitude: '29.803218',
    longitude: '-90.813933',
    timeZoneId: 'America/Chicago',
  },
  '70302': {
    zip: '70302',
    city: 'Thibodaux',
    state: 'LA',
    latitude: '29.803372',
    longitude: '-90.830448',
    timeZoneId: 'America/Chicago',
  },
  '70310': {
    zip: '70310',
    city: 'Thibodaux',
    state: 'LA',
    latitude: '29.789115',
    longitude: '-90.822503',
    timeZoneId: 'America/Chicago',
  },
  '70339': {
    zip: '70339',
    city: 'Pierre Part',
    state: 'LA',
    latitude: '29.962452',
    longitude: '-91.201838',
    timeZoneId: 'America/Chicago',
  },
  '70340': {
    zip: '70340',
    city: 'Amelia',
    state: 'LA',
    latitude: '29.697608',
    longitude: '-91.275999',
    timeZoneId: 'America/Chicago',
  },
  '70341': {
    zip: '70341',
    city: 'Belle Rose',
    state: 'LA',
    latitude: '30.0417',
    longitude: '-91.0674',
    timeZoneId: 'America/Chicago',
  },
  '70342': {
    zip: '70342',
    city: 'Berwick',
    state: 'LA',
    latitude: '29.694615',
    longitude: '-91.225759',
    timeZoneId: 'America/Chicago',
  },
  '70343': {
    zip: '70343',
    city: 'Bourg',
    state: 'LA',
    latitude: '29.550346',
    longitude: '-90.60911',
    timeZoneId: 'America/Chicago',
  },
  '70344': {
    zip: '70344',
    city: 'Chauvin',
    state: 'LA',
    latitude: '29.25058',
    longitude: '-90.66218',
    timeZoneId: 'America/Chicago',
  },
  '70345': {
    zip: '70345',
    city: 'Cut Off',
    state: 'LA',
    latitude: '29.514897',
    longitude: '-90.335946',
    timeZoneId: 'America/Chicago',
  },
  '70346': {
    zip: '70346',
    city: 'Donaldsonville',
    state: 'LA',
    latitude: '30.100345',
    longitude: '-91.001444',
    timeZoneId: 'America/Chicago',
  },
  '70352': {
    zip: '70352',
    city: 'Donner',
    state: 'LA',
    latitude: '29.693839',
    longitude: '-90.952049',
    timeZoneId: 'America/Chicago',
  },
  '70353': {
    zip: '70353',
    city: 'Dulac',
    state: 'LA',
    latitude: '29.399038',
    longitude: '-90.707271',
    timeZoneId: 'America/Chicago',
  },
  '70354': {
    zip: '70354',
    city: 'Galliano',
    state: 'LA',
    latitude: '29.431366',
    longitude: '-90.291973',
    timeZoneId: 'America/Chicago',
  },
  '70355': {
    zip: '70355',
    city: 'Gheens',
    state: 'LA',
    latitude: '29.707466',
    longitude: '-90.48784',
    timeZoneId: 'America/Chicago',
  },
  '70356': {
    zip: '70356',
    city: 'Gibson',
    state: 'LA',
    latitude: '29.657141',
    longitude: '-90.95894',
    timeZoneId: 'America/Chicago',
  },
  '70357': {
    zip: '70357',
    city: 'Golden Meadow',
    state: 'LA',
    latitude: '29.3721',
    longitude: '-90.26334',
    timeZoneId: 'America/Chicago',
  },
  '70358': {
    zip: '70358',
    city: 'Grand Isle',
    state: 'LA',
    latitude: '29.212841',
    longitude: '-90.036496',
    timeZoneId: 'America/Chicago',
  },
  '70359': {
    zip: '70359',
    city: 'Gray',
    state: 'LA',
    latitude: '29.690321',
    longitude: '-90.77783',
    timeZoneId: 'America/Chicago',
  },
  '70360': {
    zip: '70360',
    city: 'Houma',
    state: 'LA',
    latitude: '29.60736',
    longitude: '-90.7824',
    timeZoneId: 'America/Chicago',
  },
  '70361': {
    zip: '70361',
    city: 'Houma',
    state: 'LA',
    latitude: '29.598605',
    longitude: '-90.721756',
    timeZoneId: 'America/Chicago',
  },
  '70363': {
    zip: '70363',
    city: 'Houma',
    state: 'LA',
    latitude: '29.576504',
    longitude: '-90.688555',
    timeZoneId: 'America/Chicago',
  },
  '70364': {
    zip: '70364',
    city: 'Houma',
    state: 'LA',
    latitude: '29.623107',
    longitude: '-90.729507',
    timeZoneId: 'America/Chicago',
  },
  '70371': {
    zip: '70371',
    city: 'Kraemer',
    state: 'LA',
    latitude: '29.867564',
    longitude: '-90.598121',
    timeZoneId: 'America/Chicago',
  },
  '70372': {
    zip: '70372',
    city: 'Labadieville',
    state: 'LA',
    latitude: '29.834073',
    longitude: '-90.958964',
    timeZoneId: 'America/Chicago',
  },
  '70373': {
    zip: '70373',
    city: 'Larose',
    state: 'LA',
    latitude: '29.570192',
    longitude: '-90.387385',
    timeZoneId: 'America/Chicago',
  },
  '70374': {
    zip: '70374',
    city: 'Lockport',
    state: 'LA',
    latitude: '29.61744',
    longitude: '-90.46314',
    timeZoneId: 'America/Chicago',
  },
  '70375': {
    zip: '70375',
    city: 'Mathews',
    state: 'LA',
    latitude: '29.688017',
    longitude: '-90.554771',
    timeZoneId: 'America/Chicago',
  },
  '70377': {
    zip: '70377',
    city: 'Montegut',
    state: 'LA',
    latitude: '29.476743',
    longitude: '-90.543778',
    timeZoneId: 'America/Chicago',
  },
  '70380': {
    zip: '70380',
    city: 'Morgan City',
    state: 'LA',
    latitude: '29.699185',
    longitude: '-91.21104',
    timeZoneId: 'America/Chicago',
  },
  '70381': {
    zip: '70381',
    city: 'Morgan City',
    state: 'LA',
    latitude: '29.697242',
    longitude: '-91.195094',
    timeZoneId: 'America/Chicago',
  },
  '70390': {
    zip: '70390',
    city: 'Napoleonville',
    state: 'LA',
    latitude: '29.90816',
    longitude: '-91.027806',
    timeZoneId: 'America/Chicago',
  },
  '70391': {
    zip: '70391',
    city: 'Paincourtville',
    state: 'LA',
    latitude: '29.990731',
    longitude: '-91.055924',
    timeZoneId: 'America/Chicago',
  },
  '70392': {
    zip: '70392',
    city: 'Patterson',
    state: 'LA',
    latitude: '29.69889',
    longitude: '-91.305847',
    timeZoneId: 'America/Chicago',
  },
  '70393': {
    zip: '70393',
    city: 'Plattenville',
    state: 'LA',
    latitude: '29.986849',
    longitude: '-91.021779',
    timeZoneId: 'America/Chicago',
  },
  '70394': {
    zip: '70394',
    city: 'Raceland',
    state: 'LA',
    latitude: '29.709855',
    longitude: '-90.605109',
    timeZoneId: 'America/Chicago',
  },
  '70395': {
    zip: '70395',
    city: 'Schriever',
    state: 'LA',
    latitude: '29.713283',
    longitude: '-90.830197',
    timeZoneId: 'America/Chicago',
  },
  '70397': {
    zip: '70397',
    city: 'Theriot',
    state: 'LA',
    latitude: '29.416105',
    longitude: '-90.779879',
    timeZoneId: 'America/Chicago',
  },
  '70401': {
    zip: '70401',
    city: 'Hammond',
    state: 'LA',
    latitude: '30.516364',
    longitude: '-90.476064',
    timeZoneId: 'America/Chicago',
  },
  '70402': {
    zip: '70402',
    city: 'Hammond',
    state: 'LA',
    latitude: '30.510346',
    longitude: '-90.459565',
    timeZoneId: 'America/Chicago',
  },
  '70403': {
    zip: '70403',
    city: 'Hammond',
    state: 'LA',
    latitude: '30.48572',
    longitude: '-90.47534',
    timeZoneId: 'America/Chicago',
  },
  '70404': {
    zip: '70404',
    city: 'Hammond',
    state: 'LA',
    latitude: '30.512272',
    longitude: '-90.456313',
    timeZoneId: 'America/Chicago',
  },
  '70420': {
    zip: '70420',
    city: 'Abita Springs',
    state: 'LA',
    latitude: '30.486971',
    longitude: '-89.957312',
    timeZoneId: 'America/Chicago',
  },
  '70421': {
    zip: '70421',
    city: 'Akers',
    state: 'LA',
    latitude: '30.397042',
    longitude: '-90.368525',
    timeZoneId: 'America/Chicago',
  },
  '70422': {
    zip: '70422',
    city: 'Amite',
    state: 'LA',
    latitude: '30.725646',
    longitude: '-90.507562',
    timeZoneId: 'America/Chicago',
  },
  '70426': {
    zip: '70426',
    city: 'Angie',
    state: 'LA',
    latitude: '30.965129',
    longitude: '-89.812871',
    timeZoneId: 'America/Chicago',
  },
  '70427': {
    zip: '70427',
    city: 'Bogalusa',
    state: 'LA',
    latitude: '30.772216',
    longitude: '-89.862599',
    timeZoneId: 'America/Chicago',
  },
  '70429': {
    zip: '70429',
    city: 'Bogalusa',
    state: 'LA',
    latitude: '30.787781',
    longitude: '-89.848334',
    timeZoneId: 'America/Chicago',
  },
  '70431': {
    zip: '70431',
    city: 'Bush',
    state: 'LA',
    latitude: '30.599866',
    longitude: '-89.983285',
    timeZoneId: 'America/Chicago',
  },
  '70433': {
    zip: '70433',
    city: 'Covington',
    state: 'LA',
    latitude: '30.457687',
    longitude: '-90.114529',
    timeZoneId: 'America/Chicago',
  },
  '70434': {
    zip: '70434',
    city: 'Covington',
    state: 'LA',
    latitude: '30.466355',
    longitude: '-90.092629',
    timeZoneId: 'America/Chicago',
  },
  '70435': {
    zip: '70435',
    city: 'Covington',
    state: 'LA',
    latitude: '30.536408',
    longitude: '-90.107225',
    timeZoneId: 'America/Chicago',
  },
  '70436': {
    zip: '70436',
    city: 'Fluker',
    state: 'LA',
    latitude: '30.826849',
    longitude: '-90.538479',
    timeZoneId: 'America/Chicago',
  },
  '70437': {
    zip: '70437',
    city: 'Folsom',
    state: 'LA',
    latitude: '30.605587',
    longitude: '-90.181639',
    timeZoneId: 'America/Chicago',
  },
  '70438': {
    zip: '70438',
    city: 'Franklinton',
    state: 'LA',
    latitude: '30.848512',
    longitude: '-90.146024',
    timeZoneId: 'America/Chicago',
  },
  '70441': {
    zip: '70441',
    city: 'Greensburg',
    state: 'LA',
    latitude: '30.885526',
    longitude: '-90.749525',
    timeZoneId: 'America/Chicago',
  },
  '70442': {
    zip: '70442',
    city: 'Husser',
    state: 'LA',
    latitude: '30.594823',
    longitude: '-90.337836',
    timeZoneId: 'America/Chicago',
  },
  '70443': {
    zip: '70443',
    city: 'Independence',
    state: 'LA',
    latitude: '30.628982',
    longitude: '-90.498195',
    timeZoneId: 'America/Chicago',
  },
  '70444': {
    zip: '70444',
    city: 'Kentwood',
    state: 'LA',
    latitude: '30.936684',
    longitude: '-90.517939',
    timeZoneId: 'America/Chicago',
  },
  '70445': {
    zip: '70445',
    city: 'Lacombe',
    state: 'LA',
    latitude: '30.338855',
    longitude: '-89.905927',
    timeZoneId: 'America/Chicago',
  },
  '70446': {
    zip: '70446',
    city: 'Loranger',
    state: 'LA',
    latitude: '30.63657',
    longitude: '-90.445237',
    timeZoneId: 'America/Chicago',
  },
  '70447': {
    zip: '70447',
    city: 'Madisonville',
    state: 'LA',
    latitude: '30.429143',
    longitude: '-90.182432',
    timeZoneId: 'America/Chicago',
  },
  '70448': {
    zip: '70448',
    city: 'Mandeville',
    state: 'LA',
    latitude: '30.366783',
    longitude: '-90.034817',
    timeZoneId: 'America/Chicago',
  },
  '70449': {
    zip: '70449',
    city: 'Maurepas',
    state: 'LA',
    latitude: '30.272741',
    longitude: '-90.69825',
    timeZoneId: 'America/Chicago',
  },
  '70450': {
    zip: '70450',
    city: 'Mount Hermon',
    state: 'LA',
    latitude: '30.953068',
    longitude: '-90.275705',
    timeZoneId: 'America/Chicago',
  },
  '70451': {
    zip: '70451',
    city: 'Natalbany',
    state: 'LA',
    latitude: '30.544927',
    longitude: '-90.486193',
    timeZoneId: 'America/Chicago',
  },
  '70452': {
    zip: '70452',
    city: 'Pearl River',
    state: 'LA',
    latitude: '30.402734',
    longitude: '-89.77334',
    timeZoneId: 'America/Chicago',
  },
  '70453': {
    zip: '70453',
    city: 'Pine Grove',
    state: 'LA',
    latitude: '30.696601',
    longitude: '-90.773856',
    timeZoneId: 'America/Chicago',
  },
  '70454': {
    zip: '70454',
    city: 'Ponchatoula',
    state: 'LA',
    latitude: '30.44116',
    longitude: '-90.397448',
    timeZoneId: 'America/Chicago',
  },
  '70455': {
    zip: '70455',
    city: 'Robert',
    state: 'LA',
    latitude: '30.521644',
    longitude: '-90.334674',
    timeZoneId: 'America/Chicago',
  },
  '70456': {
    zip: '70456',
    city: 'Roseland',
    state: 'LA',
    latitude: '30.765787',
    longitude: '-90.51242',
    timeZoneId: 'America/Chicago',
  },
  '70457': {
    zip: '70457',
    city: 'Saint Benedict',
    state: 'LA',
    latitude: '30.524851',
    longitude: '-90.094566',
    timeZoneId: 'America/Chicago',
  },
  '70458': {
    zip: '70458',
    city: 'Slidell',
    state: 'LA',
    latitude: '30.272675',
    longitude: '-89.772151',
    timeZoneId: 'America/Chicago',
  },
  '70459': {
    zip: '70459',
    city: 'Slidell',
    state: 'LA',
    latitude: '30.286348',
    longitude: '-89.772643',
    timeZoneId: 'America/Chicago',
  },
  '70460': {
    zip: '70460',
    city: 'Slidell',
    state: 'LA',
    latitude: '30.293114',
    longitude: '-89.820906',
    timeZoneId: 'America/Chicago',
  },
  '70461': {
    zip: '70461',
    city: 'Slidell',
    state: 'LA',
    latitude: '30.268846',
    longitude: '-89.678399',
    timeZoneId: 'America/Chicago',
  },
  '70462': {
    zip: '70462',
    city: 'Springfield',
    state: 'LA',
    latitude: '30.383769',
    longitude: '-90.574931',
    timeZoneId: 'America/Chicago',
  },
  '70463': {
    zip: '70463',
    city: 'Sun',
    state: 'LA',
    latitude: '30.622959',
    longitude: '-89.971746',
    timeZoneId: 'America/Chicago',
  },
  '70464': {
    zip: '70464',
    city: 'Talisheek',
    state: 'LA',
    latitude: '30.531567',
    longitude: '-89.876619',
    timeZoneId: 'America/Chicago',
  },
  '70465': {
    zip: '70465',
    city: 'Tangipahoa',
    state: 'LA',
    latitude: '30.874802',
    longitude: '-90.516132',
    timeZoneId: 'America/Chicago',
  },
  '70466': {
    zip: '70466',
    city: 'Tickfaw',
    state: 'LA',
    latitude: '30.559296',
    longitude: '-90.499148',
    timeZoneId: 'America/Chicago',
  },
  '70467': {
    zip: '70467',
    city: 'Varnado',
    state: 'LA',
    latitude: '30.893611',
    longitude: '-89.829444',
    timeZoneId: 'America/Chicago',
  },
  '70469': {
    zip: '70469',
    city: 'Slidell',
    state: 'LA',
    latitude: '30.280259',
    longitude: '-89.776565',
    timeZoneId: 'America/Chicago',
  },
  '70470': {
    zip: '70470',
    city: 'Mandeville',
    state: 'LA',
    latitude: '30.388185',
    longitude: '-90.08131',
    timeZoneId: 'America/Chicago',
  },
  '70471': {
    zip: '70471',
    city: 'Mandeville',
    state: 'LA',
    latitude: '30.415777',
    longitude: '-90.010249',
    timeZoneId: 'America/Chicago',
  },
  '70501': {
    zip: '70501',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.236054',
    longitude: '-92.006492',
    timeZoneId: 'America/Chicago',
  },
  '70502': {
    zip: '70502',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.224108',
    longitude: '-92.022604',
    timeZoneId: 'America/Chicago',
  },
  '70503': {
    zip: '70503',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.189233',
    longitude: '-92.044087',
    timeZoneId: 'America/Chicago',
  },
  '70504': {
    zip: '70504',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.190224',
    longitude: '-92.037106',
    timeZoneId: 'America/Chicago',
  },
  '70505': {
    zip: '70505',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.226159',
    longitude: '-92.044067',
    timeZoneId: 'America/Chicago',
  },
  '70506': {
    zip: '70506',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.209397',
    longitude: '-92.065502',
    timeZoneId: 'America/Chicago',
  },
  '70507': {
    zip: '70507',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.281565',
    longitude: '-92.021505',
    timeZoneId: 'America/Chicago',
  },
  '70508': {
    zip: '70508',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.157004',
    longitude: '-92.02749',
    timeZoneId: 'America/Chicago',
  },
  '70509': {
    zip: '70509',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.235928',
    longitude: '-92.006147',
    timeZoneId: 'America/Chicago',
  },
  '70510': {
    zip: '70510',
    city: 'Abbeville',
    state: 'LA',
    latitude: '29.90484',
    longitude: '-92.13348',
    timeZoneId: 'America/Chicago',
  },
  '70511': {
    zip: '70511',
    city: 'Abbeville',
    state: 'LA',
    latitude: '29.969682',
    longitude: '-92.13184',
    timeZoneId: 'America/Chicago',
  },
  '70512': {
    zip: '70512',
    city: 'Arnaudville',
    state: 'LA',
    latitude: '30.361045',
    longitude: '-91.925819',
    timeZoneId: 'America/Chicago',
  },
  '70513': {
    zip: '70513',
    city: 'Avery Island',
    state: 'LA',
    latitude: '30.044609',
    longitude: '-91.880175',
    timeZoneId: 'America/Chicago',
  },
  '70514': {
    zip: '70514',
    city: 'Baldwin',
    state: 'LA',
    latitude: '29.838927',
    longitude: '-91.546126',
    timeZoneId: 'America/Chicago',
  },
  '70515': {
    zip: '70515',
    city: 'Basile',
    state: 'LA',
    latitude: '30.48637',
    longitude: '-92.596043',
    timeZoneId: 'America/Chicago',
  },
  '70516': {
    zip: '70516',
    city: 'Branch',
    state: 'LA',
    latitude: '30.334327',
    longitude: '-92.340085',
    timeZoneId: 'America/Chicago',
  },
  '70517': {
    zip: '70517',
    city: 'Breaux Bridge',
    state: 'LA',
    latitude: '30.279139',
    longitude: '-91.898037',
    timeZoneId: 'America/Chicago',
  },
  '70518': {
    zip: '70518',
    city: 'Broussard',
    state: 'LA',
    latitude: '30.139449',
    longitude: '-91.957153',
    timeZoneId: 'America/Chicago',
  },
  '70519': {
    zip: '70519',
    city: 'Cade',
    state: 'LA',
    latitude: '30.105088',
    longitude: '-91.913628',
    timeZoneId: 'America/Chicago',
  },
  '70520': {
    zip: '70520',
    city: 'Carencro',
    state: 'LA',
    latitude: '30.323535',
    longitude: '-92.039176',
    timeZoneId: 'America/Chicago',
  },
  '70521': {
    zip: '70521',
    city: 'Cecilia',
    state: 'LA',
    latitude: '30.336287',
    longitude: '-91.847997',
    timeZoneId: 'America/Chicago',
  },
  '70522': {
    zip: '70522',
    city: 'Centerville',
    state: 'LA',
    latitude: '29.758439',
    longitude: '-91.429998',
    timeZoneId: 'America/Chicago',
  },
  '70523': {
    zip: '70523',
    city: 'Charenton',
    state: 'LA',
    latitude: '29.890745',
    longitude: '-91.524411',
    timeZoneId: 'America/Chicago',
  },
  '70524': {
    zip: '70524',
    city: 'Chataignier',
    state: 'LA',
    latitude: '30.570463',
    longitude: '-92.321665',
    timeZoneId: 'America/Chicago',
  },
  '70525': {
    zip: '70525',
    city: 'Church Point',
    state: 'LA',
    latitude: '30.40741',
    longitude: '-92.214247',
    timeZoneId: 'America/Chicago',
  },
  '70526': {
    zip: '70526',
    city: 'Crowley',
    state: 'LA',
    latitude: '30.213433',
    longitude: '-92.373777',
    timeZoneId: 'America/Chicago',
  },
  '70527': {
    zip: '70527',
    city: 'Crowley',
    state: 'LA',
    latitude: '30.231737',
    longitude: '-92.378907',
    timeZoneId: 'America/Chicago',
  },
  '70528': {
    zip: '70528',
    city: 'Delcambre',
    state: 'LA',
    latitude: '29.949875',
    longitude: '-91.992886',
    timeZoneId: 'America/Chicago',
  },
  '70529': {
    zip: '70529',
    city: 'Duson',
    state: 'LA',
    latitude: '30.192719',
    longitude: '-92.149791',
    timeZoneId: 'America/Chicago',
  },
  '70531': {
    zip: '70531',
    city: 'Egan',
    state: 'LA',
    latitude: '30.257687',
    longitude: '-92.492462',
    timeZoneId: 'America/Chicago',
  },
  '70532': {
    zip: '70532',
    city: 'Elton',
    state: 'LA',
    latitude: '30.445573',
    longitude: '-92.706131',
    timeZoneId: 'America/Chicago',
  },
  '70533': {
    zip: '70533',
    city: 'Erath',
    state: 'LA',
    latitude: '29.9601',
    longitude: '-92.034375',
    timeZoneId: 'America/Chicago',
  },
  '70534': {
    zip: '70534',
    city: 'Estherwood',
    state: 'LA',
    latitude: '30.185184',
    longitude: '-92.462192',
    timeZoneId: 'America/Chicago',
  },
  '70535': {
    zip: '70535',
    city: 'Eunice',
    state: 'LA',
    latitude: '30.493932',
    longitude: '-92.414064',
    timeZoneId: 'America/Chicago',
  },
  '70537': {
    zip: '70537',
    city: 'Evangeline',
    state: 'LA',
    latitude: '30.259924',
    longitude: '-92.564263',
    timeZoneId: 'America/Chicago',
  },
  '70538': {
    zip: '70538',
    city: 'Franklin',
    state: 'LA',
    latitude: '29.796186',
    longitude: '-91.507053',
    timeZoneId: 'America/Chicago',
  },
  '70540': {
    zip: '70540',
    city: 'Garden City',
    state: 'LA',
    latitude: '29.765709',
    longitude: '-91.467397',
    timeZoneId: 'America/Chicago',
  },
  '70541': {
    zip: '70541',
    city: 'Grand Coteau',
    state: 'LA',
    latitude: '30.417494',
    longitude: '-92.045151',
    timeZoneId: 'America/Chicago',
  },
  '70542': {
    zip: '70542',
    city: 'Gueydan',
    state: 'LA',
    latitude: '30.0255',
    longitude: '-92.51012',
    timeZoneId: 'America/Chicago',
  },
  '70543': {
    zip: '70543',
    city: 'Iota',
    state: 'LA',
    latitude: '30.325152',
    longitude: '-92.513107',
    timeZoneId: 'America/Chicago',
  },
  '70544': {
    zip: '70544',
    city: 'Jeanerette',
    state: 'LA',
    latitude: '29.916877',
    longitude: '-91.674463',
    timeZoneId: 'America/Chicago',
  },
  '70546': {
    zip: '70546',
    city: 'Jennings',
    state: 'LA',
    latitude: '30.223918',
    longitude: '-92.654187',
    timeZoneId: 'America/Chicago',
  },
  '70548': {
    zip: '70548',
    city: 'Kaplan',
    state: 'LA',
    latitude: '30.005479',
    longitude: '-92.282679',
    timeZoneId: 'America/Chicago',
  },
  '70549': {
    zip: '70549',
    city: 'Lake Arthur',
    state: 'LA',
    latitude: '30.084531',
    longitude: '-92.676676',
    timeZoneId: 'America/Chicago',
  },
  '70550': {
    zip: '70550',
    city: 'Lawtell',
    state: 'LA',
    latitude: '30.514286',
    longitude: '-92.177092',
    timeZoneId: 'America/Chicago',
  },
  '70551': {
    zip: '70551',
    city: 'Leonville',
    state: 'LA',
    latitude: '30.466648',
    longitude: '-91.991172',
    timeZoneId: 'America/Chicago',
  },
  '70552': {
    zip: '70552',
    city: 'Loreauville',
    state: 'LA',
    latitude: '30.052012',
    longitude: '-91.747984',
    timeZoneId: 'America/Chicago',
  },
  '70554': {
    zip: '70554',
    city: 'Mamou',
    state: 'LA',
    latitude: '30.635111',
    longitude: '-92.414083',
    timeZoneId: 'America/Chicago',
  },
  '70555': {
    zip: '70555',
    city: 'Maurice',
    state: 'LA',
    latitude: '30.104494',
    longitude: '-92.125962',
    timeZoneId: 'America/Chicago',
  },
  '70556': {
    zip: '70556',
    city: 'Mermentau',
    state: 'LA',
    latitude: '30.187929',
    longitude: '-92.577927',
    timeZoneId: 'America/Chicago',
  },
  '70558': {
    zip: '70558',
    city: 'Milton',
    state: 'LA',
    latitude: '30.103997',
    longitude: '-92.077768',
    timeZoneId: 'America/Chicago',
  },
  '70559': {
    zip: '70559',
    city: 'Morse',
    state: 'LA',
    latitude: '30.098183',
    longitude: '-92.502091',
    timeZoneId: 'America/Chicago',
  },
  '70560': {
    zip: '70560',
    city: 'New Iberia',
    state: 'LA',
    latitude: '30.003568',
    longitude: '-91.819418',
    timeZoneId: 'America/Chicago',
  },
  '70562': {
    zip: '70562',
    city: 'New Iberia',
    state: 'LA',
    latitude: '29.990518',
    longitude: '-91.817232',
    timeZoneId: 'America/Chicago',
  },
  '70563': {
    zip: '70563',
    city: 'New Iberia',
    state: 'LA',
    latitude: '30.045023',
    longitude: '-91.742451',
    timeZoneId: 'America/Chicago',
  },
  '70569': {
    zip: '70569',
    city: 'Lydia',
    state: 'LA',
    latitude: '29.926025',
    longitude: '-91.782561',
    timeZoneId: 'America/Chicago',
  },
  '70570': {
    zip: '70570',
    city: 'Opelousas',
    state: 'LA',
    latitude: '30.53255',
    longitude: '-92.084616',
    timeZoneId: 'America/Chicago',
  },
  '70571': {
    zip: '70571',
    city: 'Opelousas',
    state: 'LA',
    latitude: '30.534539',
    longitude: '-92.080148',
    timeZoneId: 'America/Chicago',
  },
  '70575': {
    zip: '70575',
    city: 'Perry',
    state: 'LA',
    latitude: '29.946075',
    longitude: '-92.158608',
    timeZoneId: 'America/Chicago',
  },
  '70576': {
    zip: '70576',
    city: 'Pine Prairie',
    state: 'LA',
    latitude: '30.784223',
    longitude: '-92.42443',
    timeZoneId: 'America/Chicago',
  },
  '70577': {
    zip: '70577',
    city: 'Port Barre',
    state: 'LA',
    latitude: '30.541871',
    longitude: '-91.96395',
    timeZoneId: 'America/Chicago',
  },
  '70578': {
    zip: '70578',
    city: 'Rayne',
    state: 'LA',
    latitude: '30.234611',
    longitude: '-92.266913',
    timeZoneId: 'America/Chicago',
  },
  '70580': {
    zip: '70580',
    city: 'Reddell',
    state: 'LA',
    latitude: '30.672058',
    longitude: '-92.426456',
    timeZoneId: 'America/Chicago',
  },
  '70581': {
    zip: '70581',
    city: 'Roanoke',
    state: 'LA',
    latitude: '30.344861',
    longitude: '-92.738491',
    timeZoneId: 'America/Chicago',
  },
  '70582': {
    zip: '70582',
    city: 'Saint Martinville',
    state: 'LA',
    latitude: '30.139877',
    longitude: '-91.822998',
    timeZoneId: 'America/Chicago',
  },
  '70583': {
    zip: '70583',
    city: 'Scott',
    state: 'LA',
    latitude: '30.246725',
    longitude: '-92.103925',
    timeZoneId: 'America/Chicago',
  },
  '70584': {
    zip: '70584',
    city: 'Sunset',
    state: 'LA',
    latitude: '30.38178',
    longitude: '-92.09202',
    timeZoneId: 'America/Chicago',
  },
  '70585': {
    zip: '70585',
    city: 'Turkey Creek',
    state: 'LA',
    latitude: '30.678169',
    longitude: '-92.279405',
    timeZoneId: 'America/Chicago',
  },
  '70586': {
    zip: '70586',
    city: 'Ville Platte',
    state: 'LA',
    latitude: '30.690732',
    longitude: '-92.281579',
    timeZoneId: 'America/Chicago',
  },
  '70589': {
    zip: '70589',
    city: 'Washington',
    state: 'LA',
    latitude: '30.611138',
    longitude: '-92.058035',
    timeZoneId: 'America/Chicago',
  },
  '70591': {
    zip: '70591',
    city: 'Welsh',
    state: 'LA',
    latitude: '30.23593',
    longitude: '-92.817753',
    timeZoneId: 'America/Chicago',
  },
  '70592': {
    zip: '70592',
    city: 'Youngsville',
    state: 'LA',
    latitude: '30.099803',
    longitude: '-92.000885',
    timeZoneId: 'America/Chicago',
  },
  '70593': {
    zip: '70593',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.21431',
    longitude: '-92.028589',
    timeZoneId: 'America/Chicago',
  },
  '70596': {
    zip: '70596',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.22032',
    longitude: '-92.017052',
    timeZoneId: 'America/Chicago',
  },
  '70598': {
    zip: '70598',
    city: 'Lafayette',
    state: 'LA',
    latitude: '30.175952',
    longitude: '-92.009989',
    timeZoneId: 'America/Chicago',
  },
  '70601': {
    zip: '70601',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.228174',
    longitude: '-93.194503',
    timeZoneId: 'America/Chicago',
  },
  '70602': {
    zip: '70602',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.225539',
    longitude: '-93.216157',
    timeZoneId: 'America/Chicago',
  },
  '70605': {
    zip: '70605',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.12564',
    longitude: '-93.20268',
    timeZoneId: 'America/Chicago',
  },
  '70606': {
    zip: '70606',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.165337',
    longitude: '-93.232376',
    timeZoneId: 'America/Chicago',
  },
  '70607': {
    zip: '70607',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.088912',
    longitude: '-93.176486',
    timeZoneId: 'America/Chicago',
  },
  '70609': {
    zip: '70609',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.181629',
    longitude: '-93.213153',
    timeZoneId: 'America/Chicago',
  },
  '70611': {
    zip: '70611',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.33666',
    longitude: '-93.20304',
    timeZoneId: 'America/Chicago',
  },
  '70612': {
    zip: '70612',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.241086',
    longitude: '-93.179996',
    timeZoneId: 'America/Chicago',
  },
  '70615': {
    zip: '70615',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.232902',
    longitude: '-93.163514',
    timeZoneId: 'America/Chicago',
  },
  '70616': {
    zip: '70616',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.224615',
    longitude: '-93.212779',
    timeZoneId: 'America/Chicago',
  },
  '70629': {
    zip: '70629',
    city: 'Lake Charles',
    state: 'LA',
    latitude: '30.227087',
    longitude: '-93.214488',
    timeZoneId: 'America/Chicago',
  },
  '70630': {
    zip: '70630',
    city: 'Bell City',
    state: 'LA',
    latitude: '30.097651',
    longitude: '-93.013132',
    timeZoneId: 'America/Chicago',
  },
  '70631': {
    zip: '70631',
    city: 'Cameron',
    state: 'LA',
    latitude: '29.826351',
    longitude: '-93.397555',
    timeZoneId: 'America/Chicago',
  },
  '70632': {
    zip: '70632',
    city: 'Creole',
    state: 'LA',
    latitude: '29.785822',
    longitude: '-93.024222',
    timeZoneId: 'America/Chicago',
  },
  '70633': {
    zip: '70633',
    city: 'Dequincy',
    state: 'LA',
    latitude: '30.447654',
    longitude: '-93.429202',
    timeZoneId: 'America/Chicago',
  },
  '70634': {
    zip: '70634',
    city: 'Deridder',
    state: 'LA',
    latitude: '30.849546',
    longitude: '-93.293114',
    timeZoneId: 'America/Chicago',
  },
  '70637': {
    zip: '70637',
    city: 'Dry Creek',
    state: 'LA',
    latitude: '30.780646',
    longitude: '-92.955128',
    timeZoneId: 'America/Chicago',
  },
  '70638': {
    zip: '70638',
    city: 'Elizabeth',
    state: 'LA',
    latitude: '30.894266',
    longitude: '-92.789657',
    timeZoneId: 'America/Chicago',
  },
  '70639': {
    zip: '70639',
    city: 'Evans',
    state: 'LA',
    latitude: '30.996277',
    longitude: '-93.448813',
    timeZoneId: 'America/Chicago',
  },
  '70640': {
    zip: '70640',
    city: 'Fenton',
    state: 'LA',
    latitude: '30.365246',
    longitude: '-92.919012',
    timeZoneId: 'America/Chicago',
  },
  '70643': {
    zip: '70643',
    city: 'Grand Chenier',
    state: 'LA',
    latitude: '29.795445',
    longitude: '-92.783145',
    timeZoneId: 'America/Chicago',
  },
  '70644': {
    zip: '70644',
    city: 'Grant',
    state: 'LA',
    latitude: '30.678321',
    longitude: '-92.93485',
    timeZoneId: 'America/Chicago',
  },
  '70645': {
    zip: '70645',
    city: 'Hackberry',
    state: 'LA',
    latitude: '29.9826',
    longitude: '-93.39636',
    timeZoneId: 'America/Chicago',
  },
  '70646': {
    zip: '70646',
    city: 'Hayes',
    state: 'LA',
    latitude: '30.134607',
    longitude: '-92.927376',
    timeZoneId: 'America/Chicago',
  },
  '70647': {
    zip: '70647',
    city: 'Iowa',
    state: 'LA',
    latitude: '30.236653',
    longitude: '-93.01465',
    timeZoneId: 'America/Chicago',
  },
  '70648': {
    zip: '70648',
    city: 'Kinder',
    state: 'LA',
    latitude: '30.485538',
    longitude: '-92.846763',
    timeZoneId: 'America/Chicago',
  },
  '70650': {
    zip: '70650',
    city: 'Lacassine',
    state: 'LA',
    latitude: '30.102183',
    longitude: '-92.897773',
    timeZoneId: 'America/Chicago',
  },
  '70651': {
    zip: '70651',
    city: 'Leblanc',
    state: 'LA',
    latitude: '30.507078',
    longitude: '-92.941459',
    timeZoneId: 'America/Chicago',
  },
  '70652': {
    zip: '70652',
    city: 'Longville',
    state: 'LA',
    latitude: '30.594402',
    longitude: '-93.2695',
    timeZoneId: 'America/Chicago',
  },
  '70653': {
    zip: '70653',
    city: 'Merryville',
    state: 'LA',
    latitude: '30.709468',
    longitude: '-93.55203',
    timeZoneId: 'America/Chicago',
  },
  '70654': {
    zip: '70654',
    city: 'Mittie',
    state: 'LA',
    latitude: '30.678133',
    longitude: '-92.932056',
    timeZoneId: 'America/Chicago',
  },
  '70655': {
    zip: '70655',
    city: 'Oberlin',
    state: 'LA',
    latitude: '30.620192',
    longitude: '-92.76069',
    timeZoneId: 'America/Chicago',
  },
  '70656': {
    zip: '70656',
    city: 'Pitkin',
    state: 'LA',
    latitude: '30.939362',
    longitude: '-92.772121',
    timeZoneId: 'America/Chicago',
  },
  '70657': {
    zip: '70657',
    city: 'Ragley',
    state: 'LA',
    latitude: '30.435901',
    longitude: '-93.113078',
    timeZoneId: 'America/Chicago',
  },
  '70658': {
    zip: '70658',
    city: 'Reeves',
    state: 'LA',
    latitude: '30.435432',
    longitude: '-93.115704',
    timeZoneId: 'America/Chicago',
  },
  '70659': {
    zip: '70659',
    city: 'Rosepine',
    state: 'LA',
    latitude: '30.917057',
    longitude: '-93.284478',
    timeZoneId: 'America/Chicago',
  },
  '70660': {
    zip: '70660',
    city: 'Singer',
    state: 'LA',
    latitude: '30.539937',
    longitude: '-93.516576',
    timeZoneId: 'America/Chicago',
  },
  '70661': {
    zip: '70661',
    city: 'Starks',
    state: 'LA',
    latitude: '30.319269',
    longitude: '-93.624742',
    timeZoneId: 'America/Chicago',
  },
  '70662': {
    zip: '70662',
    city: 'Sugartown',
    state: 'LA',
    latitude: '30.832576',
    longitude: '-93.017024',
    timeZoneId: 'America/Chicago',
  },
  '70663': {
    zip: '70663',
    city: 'Sulphur',
    state: 'LA',
    latitude: '30.228528',
    longitude: '-93.357785',
    timeZoneId: 'America/Chicago',
  },
  '70664': {
    zip: '70664',
    city: 'Sulphur',
    state: 'LA',
    latitude: '30.260305',
    longitude: '-93.372461',
    timeZoneId: 'America/Chicago',
  },
  '70665': {
    zip: '70665',
    city: 'Sulphur',
    state: 'LA',
    latitude: '30.23502',
    longitude: '-93.374336',
    timeZoneId: 'America/Chicago',
  },
  '70668': {
    zip: '70668',
    city: 'Vinton',
    state: 'LA',
    latitude: '30.190438',
    longitude: '-93.581492',
    timeZoneId: 'America/Chicago',
  },
  '70669': {
    zip: '70669',
    city: 'Westlake',
    state: 'LA',
    latitude: '30.259227',
    longitude: '-93.263167',
    timeZoneId: 'America/Chicago',
  },
  '70704': {
    zip: '70704',
    city: 'Baker',
    state: 'LA',
    latitude: '30.583683',
    longitude: '-91.154281',
    timeZoneId: 'America/Chicago',
  },
  '70706': {
    zip: '70706',
    city: 'Denham Springs',
    state: 'LA',
    latitude: '30.606884',
    longitude: '-90.920617',
    timeZoneId: 'America/Chicago',
  },
  '70707': {
    zip: '70707',
    city: 'Gonzales',
    state: 'LA',
    latitude: '30.231692',
    longitude: '-90.90539',
    timeZoneId: 'America/Chicago',
  },
  '70710': {
    zip: '70710',
    city: 'Addis',
    state: 'LA',
    latitude: '30.352671',
    longitude: '-91.266929',
    timeZoneId: 'America/Chicago',
  },
  '70711': {
    zip: '70711',
    city: 'Albany',
    state: 'LA',
    latitude: '30.51783',
    longitude: '-90.59187',
    timeZoneId: 'America/Chicago',
  },
  '70712': {
    zip: '70712',
    city: 'Angola',
    state: 'LA',
    latitude: '30.996727',
    longitude: '-91.540468',
    timeZoneId: 'America/Chicago',
  },
  '70714': {
    zip: '70714',
    city: 'Baker',
    state: 'LA',
    latitude: '30.583252',
    longitude: '-91.139787',
    timeZoneId: 'America/Chicago',
  },
  '70715': {
    zip: '70715',
    city: 'Batchelor',
    state: 'LA',
    latitude: '30.806705',
    longitude: '-91.666728',
    timeZoneId: 'America/Chicago',
  },
  '70718': {
    zip: '70718',
    city: 'Brittany',
    state: 'LA',
    latitude: '30.208673',
    longitude: '-90.891672',
    timeZoneId: 'America/Chicago',
  },
  '70719': {
    zip: '70719',
    city: 'Brusly',
    state: 'LA',
    latitude: '30.388636',
    longitude: '-91.254126',
    timeZoneId: 'America/Chicago',
  },
  '70721': {
    zip: '70721',
    city: 'Carville',
    state: 'LA',
    latitude: '30.217565',
    longitude: '-91.116119',
    timeZoneId: 'America/Chicago',
  },
  '70722': {
    zip: '70722',
    city: 'Clinton',
    state: 'LA',
    latitude: '30.709871',
    longitude: '-90.896667',
    timeZoneId: 'America/Chicago',
  },
  '70723': {
    zip: '70723',
    city: 'Convent',
    state: 'LA',
    latitude: '30.064945',
    longitude: '-90.835684',
    timeZoneId: 'America/Chicago',
  },
  '70725': {
    zip: '70725',
    city: 'Darrow',
    state: 'LA',
    latitude: '30.133676',
    longitude: '-90.966684',
    timeZoneId: 'America/Chicago',
  },
  '70726': {
    zip: '70726',
    city: 'Denham Springs',
    state: 'LA',
    latitude: '30.495187',
    longitude: '-90.921746',
    timeZoneId: 'America/Chicago',
  },
  '70727': {
    zip: '70727',
    city: 'Denham Springs',
    state: 'LA',
    latitude: '30.449864',
    longitude: '-90.967801',
    timeZoneId: 'America/Chicago',
  },
  '70728': {
    zip: '70728',
    city: 'Duplessis',
    state: 'LA',
    latitude: '30.272768',
    longitude: '-90.943938',
    timeZoneId: 'America/Chicago',
  },
  '70729': {
    zip: '70729',
    city: 'Erwinville',
    state: 'LA',
    latitude: '30.534205',
    longitude: '-91.395293',
    timeZoneId: 'America/Chicago',
  },
  '70730': {
    zip: '70730',
    city: 'Ethel',
    state: 'LA',
    latitude: '30.814852',
    longitude: '-91.118263',
    timeZoneId: 'America/Chicago',
  },
  '70732': {
    zip: '70732',
    city: 'Fordoche',
    state: 'LA',
    latitude: '30.609062',
    longitude: '-91.701767',
    timeZoneId: 'America/Chicago',
  },
  '70733': {
    zip: '70733',
    city: 'French Settlement',
    state: 'LA',
    latitude: '30.31115',
    longitude: '-90.799661',
    timeZoneId: 'America/Chicago',
  },
  '70734': {
    zip: '70734',
    city: 'Geismar',
    state: 'LA',
    latitude: '30.243229',
    longitude: '-90.990969',
    timeZoneId: 'America/Chicago',
  },
  '70736': {
    zip: '70736',
    city: 'Glynn',
    state: 'LA',
    latitude: '30.63927',
    longitude: '-91.337346',
    timeZoneId: 'America/Chicago',
  },
  '70737': {
    zip: '70737',
    city: 'Gonzales',
    state: 'LA',
    latitude: '30.245951',
    longitude: '-90.91605',
    timeZoneId: 'America/Chicago',
  },
  '70738': {
    zip: '70738',
    city: 'Burnside',
    state: 'LA',
    latitude: '30.140739',
    longitude: '-90.924234',
    timeZoneId: 'America/Chicago',
  },
  '70739': {
    zip: '70739',
    city: 'Greenwell Springs',
    state: 'LA',
    latitude: '30.539595',
    longitude: '-91.000712',
    timeZoneId: 'America/Chicago',
  },
  '70740': {
    zip: '70740',
    city: 'Grosse Tete',
    state: 'LA',
    latitude: '30.412537',
    longitude: '-91.430224',
    timeZoneId: 'America/Chicago',
  },
  '70743': {
    zip: '70743',
    city: 'Hester',
    state: 'LA',
    latitude: '30.020132',
    longitude: '-90.770944',
    timeZoneId: 'America/Chicago',
  },
  '70744': {
    zip: '70744',
    city: 'Holden',
    state: 'LA',
    latitude: '30.510212',
    longitude: '-90.65856',
    timeZoneId: 'America/Chicago',
  },
  '70747': {
    zip: '70747',
    city: 'Innis',
    state: 'LA',
    latitude: '30.877689',
    longitude: '-91.680562',
    timeZoneId: 'America/Chicago',
  },
  '70748': {
    zip: '70748',
    city: 'Jackson',
    state: 'LA',
    latitude: '30.777809',
    longitude: '-91.210024',
    timeZoneId: 'America/Chicago',
  },
  '70749': {
    zip: '70749',
    city: 'Jarreau',
    state: 'LA',
    latitude: '30.630766',
    longitude: '-91.431298',
    timeZoneId: 'America/Chicago',
  },
  '70750': {
    zip: '70750',
    city: 'Krotz Springs',
    state: 'LA',
    latitude: '30.541914',
    longitude: '-91.758543',
    timeZoneId: 'America/Chicago',
  },
  '70752': {
    zip: '70752',
    city: 'Lakeland',
    state: 'LA',
    latitude: '30.571113',
    longitude: '-91.428625',
    timeZoneId: 'America/Chicago',
  },
  '70753': {
    zip: '70753',
    city: 'Lettsworth',
    state: 'LA',
    latitude: '30.93845',
    longitude: '-91.731716',
    timeZoneId: 'America/Chicago',
  },
  '70754': {
    zip: '70754',
    city: 'Livingston',
    state: 'LA',
    latitude: '30.446103',
    longitude: '-90.758829',
    timeZoneId: 'America/Chicago',
  },
  '70755': {
    zip: '70755',
    city: 'Livonia',
    state: 'LA',
    latitude: '30.568384',
    longitude: '-91.545355',
    timeZoneId: 'America/Chicago',
  },
  '70756': {
    zip: '70756',
    city: 'Lottie',
    state: 'LA',
    latitude: '30.520831',
    longitude: '-91.688091',
    timeZoneId: 'America/Chicago',
  },
  '70757': {
    zip: '70757',
    city: 'Maringouin',
    state: 'LA',
    latitude: '30.491099',
    longitude: '-91.515729',
    timeZoneId: 'America/Chicago',
  },
  '70759': {
    zip: '70759',
    city: 'Morganza',
    state: 'LA',
    latitude: '30.690421',
    longitude: '-91.610359',
    timeZoneId: 'America/Chicago',
  },
  '70760': {
    zip: '70760',
    city: 'New Roads',
    state: 'LA',
    latitude: '30.698418',
    longitude: '-91.437962',
    timeZoneId: 'America/Chicago',
  },
  '70761': {
    zip: '70761',
    city: 'Norwood',
    state: 'LA',
    latitude: '30.930863',
    longitude: '-91.032481',
    timeZoneId: 'America/Chicago',
  },
  '70762': {
    zip: '70762',
    city: 'Oscar',
    state: 'LA',
    latitude: '30.615901',
    longitude: '-91.463133',
    timeZoneId: 'America/Chicago',
  },
  '70763': {
    zip: '70763',
    city: 'Paulina',
    state: 'LA',
    latitude: '30.078087',
    longitude: '-90.771139',
    timeZoneId: 'America/Chicago',
  },
  '70764': {
    zip: '70764',
    city: 'Plaquemine',
    state: 'LA',
    latitude: '30.279526',
    longitude: '-91.238445',
    timeZoneId: 'America/Chicago',
  },
  '70765': {
    zip: '70765',
    city: 'Plaquemine',
    state: 'LA',
    latitude: '30.288255',
    longitude: '-91.233565',
    timeZoneId: 'America/Chicago',
  },
  '70767': {
    zip: '70767',
    city: 'Port Allen',
    state: 'LA',
    latitude: '30.478469',
    longitude: '-91.276421',
    timeZoneId: 'America/Chicago',
  },
  '70769': {
    zip: '70769',
    city: 'Prairieville',
    state: 'LA',
    latitude: '30.311773',
    longitude: '-90.937896',
    timeZoneId: 'America/Chicago',
  },
  '70770': {
    zip: '70770',
    city: 'Pride',
    state: 'LA',
    latitude: '30.619832',
    longitude: '-90.999546',
    timeZoneId: 'America/Chicago',
  },
  '70772': {
    zip: '70772',
    city: 'Rosedale',
    state: 'LA',
    latitude: '30.409574',
    longitude: '-91.434613',
    timeZoneId: 'America/Chicago',
  },
  '70773': {
    zip: '70773',
    city: 'Rougon',
    state: 'LA',
    latitude: '30.597677',
    longitude: '-91.373909',
    timeZoneId: 'America/Chicago',
  },
  '70774': {
    zip: '70774',
    city: 'Saint Amant',
    state: 'LA',
    latitude: '30.237841',
    longitude: '-90.848047',
    timeZoneId: 'America/Chicago',
  },
  '70775': {
    zip: '70775',
    city: 'Saint Francisville',
    state: 'LA',
    latitude: '30.862864',
    longitude: '-91.332969',
    timeZoneId: 'America/Chicago',
  },
  '70776': {
    zip: '70776',
    city: 'Saint Gabriel',
    state: 'LA',
    latitude: '30.2874',
    longitude: '-91.07334',
    timeZoneId: 'America/Chicago',
  },
  '70777': {
    zip: '70777',
    city: 'Slaughter',
    state: 'LA',
    latitude: '30.685136',
    longitude: '-91.114161',
    timeZoneId: 'America/Chicago',
  },
  '70778': {
    zip: '70778',
    city: 'Sorrento',
    state: 'LA',
    latitude: '30.183229',
    longitude: '-90.864373',
    timeZoneId: 'America/Chicago',
  },
  '70780': {
    zip: '70780',
    city: 'Sunshine',
    state: 'LA',
    latitude: '30.308649',
    longitude: '-91.180634',
    timeZoneId: 'America/Chicago',
  },
  '70782': {
    zip: '70782',
    city: 'Tunica',
    state: 'LA',
    latitude: '30.929599',
    longitude: '-91.55501',
    timeZoneId: 'America/Chicago',
  },
  '70783': {
    zip: '70783',
    city: 'Ventress',
    state: 'LA',
    latitude: '30.68609',
    longitude: '-91.386692',
    timeZoneId: 'America/Chicago',
  },
  '70784': {
    zip: '70784',
    city: 'Wakefield',
    state: 'LA',
    latitude: '30.886627',
    longitude: '-91.353153',
    timeZoneId: 'America/Chicago',
  },
  '70785': {
    zip: '70785',
    city: 'Walker',
    state: 'LA',
    latitude: '30.519892',
    longitude: '-90.851667',
    timeZoneId: 'America/Chicago',
  },
  '70786': {
    zip: '70786',
    city: 'Watson',
    state: 'LA',
    latitude: '30.585614',
    longitude: '-90.939345',
    timeZoneId: 'America/Chicago',
  },
  '70787': {
    zip: '70787',
    city: 'Weyanoke',
    state: 'LA',
    latitude: '30.967035',
    longitude: '-91.467995',
    timeZoneId: 'America/Chicago',
  },
  '70788': {
    zip: '70788',
    city: 'White Castle',
    state: 'LA',
    latitude: '30.167164',
    longitude: '-91.148219',
    timeZoneId: 'America/Chicago',
  },
  '70789': {
    zip: '70789',
    city: 'Wilson',
    state: 'LA',
    latitude: '30.946656',
    longitude: '-91.052709',
    timeZoneId: 'America/Chicago',
  },
  '70791': {
    zip: '70791',
    city: 'Zachary',
    state: 'LA',
    latitude: '30.656276',
    longitude: '-91.135331',
    timeZoneId: 'America/Chicago',
  },
  '70792': {
    zip: '70792',
    city: 'Uncle Sam',
    state: 'LA',
    latitude: '30.023483',
    longitude: '-90.777906',
    timeZoneId: 'America/Chicago',
  },
  '70801': {
    zip: '70801',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.446043',
    longitude: '-91.185434',
    timeZoneId: 'America/Chicago',
  },
  '70802': {
    zip: '70802',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.447918',
    longitude: '-91.167399',
    timeZoneId: 'America/Chicago',
  },
  '70803': {
    zip: '70803',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.408018',
    longitude: '-91.188669',
    timeZoneId: 'America/Chicago',
  },
  '70804': {
    zip: '70804',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.449243',
    longitude: '-91.174051',
    timeZoneId: 'America/Chicago',
  },
  '70805': {
    zip: '70805',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.48806',
    longitude: '-91.150049',
    timeZoneId: 'America/Chicago',
  },
  '70806': {
    zip: '70806',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.447231',
    longitude: '-91.129088',
    timeZoneId: 'America/Chicago',
  },
  '70807': {
    zip: '70807',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.536692',
    longitude: '-91.174565',
    timeZoneId: 'America/Chicago',
  },
  '70808': {
    zip: '70808',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.409457',
    longitude: '-91.139053',
    timeZoneId: 'America/Chicago',
  },
  '70809': {
    zip: '70809',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.40859',
    longitude: '-91.086924',
    timeZoneId: 'America/Chicago',
  },
  '70810': {
    zip: '70810',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.366525',
    longitude: '-91.092542',
    timeZoneId: 'America/Chicago',
  },
  '70811': {
    zip: '70811',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.532352',
    longitude: '-91.124008',
    timeZoneId: 'America/Chicago',
  },
  '70812': {
    zip: '70812',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.505615',
    longitude: '-91.119318',
    timeZoneId: 'America/Chicago',
  },
  '70813': {
    zip: '70813',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.426458',
    longitude: '-91.109438',
    timeZoneId: 'America/Chicago',
  },
  '70814': {
    zip: '70814',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.486773',
    longitude: '-91.067329',
    timeZoneId: 'America/Chicago',
  },
  '70815': {
    zip: '70815',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.454915',
    longitude: '-91.063684',
    timeZoneId: 'America/Chicago',
  },
  '70816': {
    zip: '70816',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.423198',
    longitude: '-91.03685',
    timeZoneId: 'America/Chicago',
  },
  '70817': {
    zip: '70817',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.384984',
    longitude: '-90.995591',
    timeZoneId: 'America/Chicago',
  },
  '70818': {
    zip: '70818',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.544903',
    longitude: '-91.049706',
    timeZoneId: 'America/Chicago',
  },
  '70819': {
    zip: '70819',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.469095',
    longitude: '-91.012658',
    timeZoneId: 'America/Chicago',
  },
  '70820': {
    zip: '70820',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.375696',
    longitude: '-91.170416',
    timeZoneId: 'America/Chicago',
  },
  '70821': {
    zip: '70821',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.457289',
    longitude: '-91.124882',
    timeZoneId: 'America/Chicago',
  },
  '70822': {
    zip: '70822',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.450296',
    longitude: '-91.129317',
    timeZoneId: 'America/Chicago',
  },
  '70823': {
    zip: '70823',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.445781',
    longitude: '-91.189226',
    timeZoneId: 'America/Chicago',
  },
  '70825': {
    zip: '70825',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.427794',
    longitude: '-91.021838',
    timeZoneId: 'America/Chicago',
  },
  '70826': {
    zip: '70826',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.401161',
    longitude: '-91.077146',
    timeZoneId: 'America/Chicago',
  },
  '70827': {
    zip: '70827',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.434309',
    longitude: '-91.082897',
    timeZoneId: 'America/Chicago',
  },
  '70831': {
    zip: '70831',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.44889',
    longitude: '-91.185962',
    timeZoneId: 'America/Chicago',
  },
  '70833': {
    zip: '70833',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.407464',
    longitude: '-91.142909',
    timeZoneId: 'America/Chicago',
  },
  '70835': {
    zip: '70835',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.428404',
    longitude: '-91.024943',
    timeZoneId: 'America/Chicago',
  },
  '70836': {
    zip: '70836',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.451013',
    longitude: '-91.183204',
    timeZoneId: 'America/Chicago',
  },
  '70837': {
    zip: '70837',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.550769',
    longitude: '-91.203604',
    timeZoneId: 'America/Chicago',
  },
  '70873': {
    zip: '70873',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.443359',
    longitude: '-91.186981',
    timeZoneId: 'America/Chicago',
  },
  '70874': {
    zip: '70874',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.443359',
    longitude: '-91.186981',
    timeZoneId: 'America/Chicago',
  },
  '70879': {
    zip: '70879',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.375009',
    longitude: '-90.980828',
    timeZoneId: 'America/Chicago',
  },
  '70884': {
    zip: '70884',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.377259',
    longitude: '-91.09601',
    timeZoneId: 'America/Chicago',
  },
  '70891': {
    zip: '70891',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.44924',
    longitude: '-91.154732',
    timeZoneId: 'America/Chicago',
  },
  '70892': {
    zip: '70892',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.447143',
    longitude: '-91.156736',
    timeZoneId: 'America/Chicago',
  },
  '70893': {
    zip: '70893',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.412199',
    longitude: '-91.172514',
    timeZoneId: 'America/Chicago',
  },
  '70894': {
    zip: '70894',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.415846',
    longitude: '-91.177469',
    timeZoneId: 'America/Chicago',
  },
  '70895': {
    zip: '70895',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.455864',
    longitude: '-91.075438',
    timeZoneId: 'America/Chicago',
  },
  '70896': {
    zip: '70896',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.36767',
    longitude: '-91.03668',
    timeZoneId: 'America/Chicago',
  },
  '70898': {
    zip: '70898',
    city: 'Baton Rouge',
    state: 'LA',
    latitude: '30.418422',
    longitude: '-91.139518',
    timeZoneId: 'America/Chicago',
  },
  '71001': {
    zip: '71001',
    city: 'Arcadia',
    state: 'LA',
    latitude: '32.553515',
    longitude: '-92.922337',
    timeZoneId: 'America/Chicago',
  },
  '71002': {
    zip: '71002',
    city: 'Ashland',
    state: 'LA',
    latitude: '32.116351',
    longitude: '-93.114423',
    timeZoneId: 'America/Chicago',
  },
  '71003': {
    zip: '71003',
    city: 'Athens',
    state: 'LA',
    latitude: '32.781553',
    longitude: '-92.99606',
    timeZoneId: 'America/Chicago',
  },
  '71004': {
    zip: '71004',
    city: 'Belcher',
    state: 'LA',
    latitude: '32.756233',
    longitude: '-93.856029',
    timeZoneId: 'America/Chicago',
  },
  '71006': {
    zip: '71006',
    city: 'Benton',
    state: 'LA',
    latitude: '32.679257',
    longitude: '-93.707187',
    timeZoneId: 'America/Chicago',
  },
  '71007': {
    zip: '71007',
    city: 'Bethany',
    state: 'LA',
    latitude: '32.364041',
    longitude: '-94.017496',
    timeZoneId: 'America/Chicago',
  },
  '71008': {
    zip: '71008',
    city: 'Bienville',
    state: 'LA',
    latitude: '32.30172',
    longitude: '-92.925316',
    timeZoneId: 'America/Chicago',
  },
  '71009': {
    zip: '71009',
    city: 'Blanchard',
    state: 'LA',
    latitude: '32.584935',
    longitude: '-93.895957',
    timeZoneId: 'America/Chicago',
  },
  '71016': {
    zip: '71016',
    city: 'Castor',
    state: 'LA',
    latitude: '32.18742',
    longitude: '-93.133374',
    timeZoneId: 'America/Chicago',
  },
  '71018': {
    zip: '71018',
    city: 'Cotton Valley',
    state: 'LA',
    latitude: '32.838968',
    longitude: '-93.453323',
    timeZoneId: 'America/Chicago',
  },
  '71019': {
    zip: '71019',
    city: 'Coushatta',
    state: 'LA',
    latitude: '31.98624',
    longitude: '-93.27024',
    timeZoneId: 'America/Chicago',
  },
  '71021': {
    zip: '71021',
    city: 'Cullen',
    state: 'LA',
    latitude: '32.968959',
    longitude: '-93.445228',
    timeZoneId: 'America/Chicago',
  },
  '71023': {
    zip: '71023',
    city: 'Doyline',
    state: 'LA',
    latitude: '32.526535',
    longitude: '-93.409703',
    timeZoneId: 'America/Chicago',
  },
  '71024': {
    zip: '71024',
    city: 'Dubberly',
    state: 'LA',
    latitude: '32.489041',
    longitude: '-93.201393',
    timeZoneId: 'America/Chicago',
  },
  '71027': {
    zip: '71027',
    city: 'Frierson',
    state: 'LA',
    latitude: '32.235654',
    longitude: '-93.677166',
    timeZoneId: 'America/Chicago',
  },
  '71028': {
    zip: '71028',
    city: 'Gibsland',
    state: 'LA',
    latitude: '32.51832',
    longitude: '-93.07098',
    timeZoneId: 'America/Chicago',
  },
  '71029': {
    zip: '71029',
    city: 'Gilliam',
    state: 'LA',
    latitude: '32.805224',
    longitude: '-93.830904',
    timeZoneId: 'America/Chicago',
  },
  '71030': {
    zip: '71030',
    city: 'Gloster',
    state: 'LA',
    latitude: '32.199593',
    longitude: '-93.847234',
    timeZoneId: 'America/Chicago',
  },
  '71031': {
    zip: '71031',
    city: 'Goldonna',
    state: 'LA',
    latitude: '31.990819',
    longitude: '-92.97885',
    timeZoneId: 'America/Chicago',
  },
  '71032': {
    zip: '71032',
    city: 'Grand Cane',
    state: 'LA',
    latitude: '32.11722',
    longitude: '-93.77694',
    timeZoneId: 'America/Chicago',
  },
  '71033': {
    zip: '71033',
    city: 'Greenwood',
    state: 'LA',
    latitude: '32.437841',
    longitude: '-93.988179',
    timeZoneId: 'America/Chicago',
  },
  '71034': {
    zip: '71034',
    city: 'Hall Summit',
    state: 'LA',
    latitude: '32.174719',
    longitude: '-93.301587',
    timeZoneId: 'America/Chicago',
  },
  '71037': {
    zip: '71037',
    city: 'Haughton',
    state: 'LA',
    latitude: '32.5368',
    longitude: '-93.5349',
    timeZoneId: 'America/Chicago',
  },
  '71038': {
    zip: '71038',
    city: 'Haynesville',
    state: 'LA',
    latitude: '32.9469',
    longitude: '-93.12294',
    timeZoneId: 'America/Chicago',
  },
  '71039': {
    zip: '71039',
    city: 'Heflin',
    state: 'LA',
    latitude: '32.441009',
    longitude: '-93.295609',
    timeZoneId: 'America/Chicago',
  },
  '71040': {
    zip: '71040',
    city: 'Homer',
    state: 'LA',
    latitude: '32.787891',
    longitude: '-93.05993',
    timeZoneId: 'America/Chicago',
  },
  '71043': {
    zip: '71043',
    city: 'Hosston',
    state: 'LA',
    latitude: '32.897358',
    longitude: '-93.887395',
    timeZoneId: 'America/Chicago',
  },
  '71044': {
    zip: '71044',
    city: 'Ida',
    state: 'LA',
    latitude: '32.95722',
    longitude: '-93.90372',
    timeZoneId: 'America/Chicago',
  },
  '71045': {
    zip: '71045',
    city: 'Jamestown',
    state: 'LA',
    latitude: '32.378476',
    longitude: '-93.183299',
    timeZoneId: 'America/Chicago',
  },
  '71046': {
    zip: '71046',
    city: 'Keatchie',
    state: 'LA',
    latitude: '32.21495',
    longitude: '-93.998948',
    timeZoneId: 'America/Chicago',
  },
  '71047': {
    zip: '71047',
    city: 'Keithville',
    state: 'LA',
    latitude: '32.316074',
    longitude: '-93.893584',
    timeZoneId: 'America/Chicago',
  },
  '71048': {
    zip: '71048',
    city: 'Lisbon',
    state: 'LA',
    latitude: '32.842916',
    longitude: '-92.85799',
    timeZoneId: 'America/Chicago',
  },
  '71049': {
    zip: '71049',
    city: 'Logansport',
    state: 'LA',
    latitude: '32.017906',
    longitude: '-93.933956',
    timeZoneId: 'America/Chicago',
  },
  '71050': {
    zip: '71050',
    city: 'Longstreet',
    state: 'LA',
    latitude: '32.098082',
    longitude: '-93.950883',
    timeZoneId: 'America/Chicago',
  },
  '71051': {
    zip: '71051',
    city: 'Elm Grove',
    state: 'LA',
    latitude: '32.362958',
    longitude: '-93.497801',
    timeZoneId: 'America/Chicago',
  },
  '71052': {
    zip: '71052',
    city: 'Mansfield',
    state: 'LA',
    latitude: '32.034409',
    longitude: '-93.70056',
    timeZoneId: 'America/Chicago',
  },
  '71055': {
    zip: '71055',
    city: 'Minden',
    state: 'LA',
    latitude: '32.617484',
    longitude: '-93.281086',
    timeZoneId: 'America/Chicago',
  },
  '71058': {
    zip: '71058',
    city: 'Minden',
    state: 'LA',
    latitude: '32.612262',
    longitude: '-93.295173',
    timeZoneId: 'America/Chicago',
  },
  '71060': {
    zip: '71060',
    city: 'Mooringsport',
    state: 'LA',
    latitude: '32.658065',
    longitude: '-93.977436',
    timeZoneId: 'America/Chicago',
  },
  '71061': {
    zip: '71061',
    city: 'Oil City',
    state: 'LA',
    latitude: '32.749688',
    longitude: '-93.980602',
    timeZoneId: 'America/Chicago',
  },
  '71063': {
    zip: '71063',
    city: 'Pelican',
    state: 'LA',
    latitude: '31.888361',
    longitude: '-93.556746',
    timeZoneId: 'America/Chicago',
  },
  '71064': {
    zip: '71064',
    city: 'Plain Dealing',
    state: 'LA',
    latitude: '32.905021',
    longitude: '-93.702244',
    timeZoneId: 'America/Chicago',
  },
  '71065': {
    zip: '71065',
    city: 'Pleasant Hill',
    state: 'LA',
    latitude: '31.822235',
    longitude: '-93.513309',
    timeZoneId: 'America/Chicago',
  },
  '71066': {
    zip: '71066',
    city: 'Powhatan',
    state: 'LA',
    latitude: '31.844948',
    longitude: '-93.168138',
    timeZoneId: 'America/Chicago',
  },
  '71067': {
    zip: '71067',
    city: 'Princeton',
    state: 'LA',
    latitude: '32.569499',
    longitude: '-93.551303',
    timeZoneId: 'America/Chicago',
  },
  '71068': {
    zip: '71068',
    city: 'Ringgold',
    state: 'LA',
    latitude: '32.31698',
    longitude: '-93.299756',
    timeZoneId: 'America/Chicago',
  },
  '71069': {
    zip: '71069',
    city: 'Rodessa',
    state: 'LA',
    latitude: '32.975017',
    longitude: '-94.004397',
    timeZoneId: 'America/Chicago',
  },
  '71070': {
    zip: '71070',
    city: 'Saline',
    state: 'LA',
    latitude: '32.11878',
    longitude: '-92.97534',
    timeZoneId: 'America/Chicago',
  },
  '71071': {
    zip: '71071',
    city: 'Sarepta',
    state: 'LA',
    latitude: '32.93821',
    longitude: '-93.434653',
    timeZoneId: 'America/Chicago',
  },
  '71072': {
    zip: '71072',
    city: 'Shongaloo',
    state: 'LA',
    latitude: '32.958698',
    longitude: '-93.281039',
    timeZoneId: 'America/Chicago',
  },
  '71073': {
    zip: '71073',
    city: 'Sibley',
    state: 'LA',
    latitude: '32.52522',
    longitude: '-93.29502',
    timeZoneId: 'America/Chicago',
  },
  '71075': {
    zip: '71075',
    city: 'Springhill',
    state: 'LA',
    latitude: '33.001957',
    longitude: '-93.458909',
    timeZoneId: 'America/Chicago',
  },
  '71078': {
    zip: '71078',
    city: 'Stonewall',
    state: 'LA',
    latitude: '32.314232',
    longitude: '-93.777593',
    timeZoneId: 'America/Chicago',
  },
  '71079': {
    zip: '71079',
    city: 'Summerfield',
    state: 'LA',
    latitude: '32.935341',
    longitude: '-92.805364',
    timeZoneId: 'America/Chicago',
  },
  '71080': {
    zip: '71080',
    city: 'Taylor',
    state: 'LA',
    latitude: '32.501239',
    longitude: '-93.072806',
    timeZoneId: 'America/Chicago',
  },
  '71082': {
    zip: '71082',
    city: 'Vivian',
    state: 'LA',
    latitude: '32.862552',
    longitude: '-93.989497',
    timeZoneId: 'America/Chicago',
  },
  '71101': {
    zip: '71101',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.504787',
    longitude: '-93.746306',
    timeZoneId: 'America/Chicago',
  },
  '71102': {
    zip: '71102',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.493336',
    longitude: '-93.771521',
    timeZoneId: 'America/Chicago',
  },
  '71103': {
    zip: '71103',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.491335',
    longitude: '-93.773159',
    timeZoneId: 'America/Chicago',
  },
  '71104': {
    zip: '71104',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.484075',
    longitude: '-93.73302',
    timeZoneId: 'America/Chicago',
  },
  '71105': {
    zip: '71105',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.454589',
    longitude: '-93.712715',
    timeZoneId: 'America/Chicago',
  },
  '71106': {
    zip: '71106',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.426395',
    longitude: '-93.738506',
    timeZoneId: 'America/Chicago',
  },
  '71107': {
    zip: '71107',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.60862',
    longitude: '-93.86208',
    timeZoneId: 'America/Chicago',
  },
  '71108': {
    zip: '71108',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.441723',
    longitude: '-93.787148',
    timeZoneId: 'America/Chicago',
  },
  '71109': {
    zip: '71109',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.4654',
    longitude: '-93.81402',
    timeZoneId: 'America/Chicago',
  },
  '71110': {
    zip: '71110',
    city: 'Barksdale AFB',
    state: 'LA',
    latitude: '32.50668',
    longitude: '-93.64026',
    timeZoneId: 'America/Chicago',
  },
  '71111': {
    zip: '71111',
    city: 'Bossier City',
    state: 'LA',
    latitude: '32.545307',
    longitude: '-93.704765',
    timeZoneId: 'America/Chicago',
  },
  '71112': {
    zip: '71112',
    city: 'Bossier City',
    state: 'LA',
    latitude: '32.486057',
    longitude: '-93.671643',
    timeZoneId: 'America/Chicago',
  },
  '71113': {
    zip: '71113',
    city: 'Bossier City',
    state: 'LA',
    latitude: '32.496399',
    longitude: '-93.687401',
    timeZoneId: 'America/Chicago',
  },
  '71115': {
    zip: '71115',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.383613',
    longitude: '-93.665812',
    timeZoneId: 'America/Chicago',
  },
  '71118': {
    zip: '71118',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.395437',
    longitude: '-93.803021',
    timeZoneId: 'America/Chicago',
  },
  '71119': {
    zip: '71119',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.476576',
    longitude: '-93.879452',
    timeZoneId: 'America/Chicago',
  },
  '71120': {
    zip: '71120',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.504406',
    longitude: '-93.744686',
    timeZoneId: 'America/Chicago',
  },
  '71129': {
    zip: '71129',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.424689',
    longitude: '-93.897547',
    timeZoneId: 'America/Chicago',
  },
  '71130': {
    zip: '71130',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.491049',
    longitude: '-93.769298',
    timeZoneId: 'America/Chicago',
  },
  '71133': {
    zip: '71133',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.488715',
    longitude: '-93.768133',
    timeZoneId: 'America/Chicago',
  },
  '71134': {
    zip: '71134',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.478625',
    longitude: '-93.735233',
    timeZoneId: 'America/Chicago',
  },
  '71135': {
    zip: '71135',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.442002',
    longitude: '-93.707741',
    timeZoneId: 'America/Chicago',
  },
  '71136': {
    zip: '71136',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.418732',
    longitude: '-93.760904',
    timeZoneId: 'America/Chicago',
  },
  '71137': {
    zip: '71137',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.592995',
    longitude: '-93.854112',
    timeZoneId: 'America/Chicago',
  },
  '71138': {
    zip: '71138',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.405769',
    longitude: '-93.796006',
    timeZoneId: 'America/Chicago',
  },
  '71148': {
    zip: '71148',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.4572',
    longitude: '-93.777759',
    timeZoneId: 'America/Chicago',
  },
  '71149': {
    zip: '71149',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.508077',
    longitude: '-93.741235',
    timeZoneId: 'America/Chicago',
  },
  '71150': {
    zip: '71150',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.51461',
    longitude: '-93.747269',
    timeZoneId: 'America/Chicago',
  },
  '71151': {
    zip: '71151',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.51461',
    longitude: '-93.747269',
    timeZoneId: 'America/Chicago',
  },
  '71152': {
    zip: '71152',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.440924',
    longitude: '-93.788435',
    timeZoneId: 'America/Chicago',
  },
  '71153': {
    zip: '71153',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.515347',
    longitude: '-93.749932',
    timeZoneId: 'America/Chicago',
  },
  '71154': {
    zip: '71154',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.492906',
    longitude: '-93.766371',
    timeZoneId: 'America/Chicago',
  },
  '71156': {
    zip: '71156',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.511335',
    longitude: '-93.748082',
    timeZoneId: 'America/Chicago',
  },
  '71161': {
    zip: '71161',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.497981',
    longitude: '-93.751988',
    timeZoneId: 'America/Chicago',
  },
  '71162': {
    zip: '71162',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.503045',
    longitude: '-93.741033',
    timeZoneId: 'America/Chicago',
  },
  '71163': {
    zip: '71163',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.507808',
    longitude: '-93.744894',
    timeZoneId: 'America/Chicago',
  },
  '71164': {
    zip: '71164',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.504198',
    longitude: '-93.743651',
    timeZoneId: 'America/Chicago',
  },
  '71165': {
    zip: '71165',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.502437',
    longitude: '-93.743855',
    timeZoneId: 'America/Chicago',
  },
  '71166': {
    zip: '71166',
    city: 'Shreveport',
    state: 'LA',
    latitude: '32.505253',
    longitude: '-93.741197',
    timeZoneId: 'America/Chicago',
  },
  '71171': {
    zip: '71171',
    city: 'Bossier City',
    state: 'LA',
    latitude: '32.513854',
    longitude: '-93.731075',
    timeZoneId: 'America/Chicago',
  },
  '71172': {
    zip: '71172',
    city: 'Bossier City',
    state: 'LA',
    latitude: '32.530317',
    longitude: '-93.652612',
    timeZoneId: 'America/Chicago',
  },
  '71201': {
    zip: '71201',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.522484',
    longitude: '-92.103332',
    timeZoneId: 'America/Chicago',
  },
  '71202': {
    zip: '71202',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.48196',
    longitude: '-92.08002',
    timeZoneId: 'America/Chicago',
  },
  '71203': {
    zip: '71203',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.547557',
    longitude: '-92.044046',
    timeZoneId: 'America/Chicago',
  },
  '71207': {
    zip: '71207',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.515839',
    longitude: '-92.115614',
    timeZoneId: 'America/Chicago',
  },
  '71209': {
    zip: '71209',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.52815',
    longitude: '-92.072246',
    timeZoneId: 'America/Chicago',
  },
  '71210': {
    zip: '71210',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.504942',
    longitude: '-92.103613',
    timeZoneId: 'America/Chicago',
  },
  '71211': {
    zip: '71211',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.699598',
    longitude: '-92.073593',
    timeZoneId: 'America/Chicago',
  },
  '71212': {
    zip: '71212',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.510013',
    longitude: '-92.120718',
    timeZoneId: 'America/Chicago',
  },
  '71213': {
    zip: '71213',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.509651',
    longitude: '-92.119049',
    timeZoneId: 'America/Chicago',
  },
  '71217': {
    zip: '71217',
    city: 'Monroe',
    state: 'LA',
    latitude: '32.509651',
    longitude: '-92.119049',
    timeZoneId: 'America/Chicago',
  },
  '71218': {
    zip: '71218',
    city: 'Archibald',
    state: 'LA',
    latitude: '32.349414',
    longitude: '-91.775739',
    timeZoneId: 'America/Chicago',
  },
  '71219': {
    zip: '71219',
    city: 'Baskin',
    state: 'LA',
    latitude: '32.316337',
    longitude: '-91.687881',
    timeZoneId: 'America/Chicago',
  },
  '71220': {
    zip: '71220',
    city: 'Bastrop',
    state: 'LA',
    latitude: '32.776951',
    longitude: '-91.913001',
    timeZoneId: 'America/Chicago',
  },
  '71221': {
    zip: '71221',
    city: 'Bastrop',
    state: 'LA',
    latitude: '32.756362',
    longitude: '-91.87619',
    timeZoneId: 'America/Chicago',
  },
  '71222': {
    zip: '71222',
    city: 'Bernice',
    state: 'LA',
    latitude: '32.8308',
    longitude: '-92.64342',
    timeZoneId: 'America/Chicago',
  },
  '71223': {
    zip: '71223',
    city: 'Bonita',
    state: 'LA',
    latitude: '32.901055',
    longitude: '-91.680149',
    timeZoneId: 'America/Chicago',
  },
  '71225': {
    zip: '71225',
    city: 'Calhoun',
    state: 'LA',
    latitude: '32.527647',
    longitude: '-92.314023',
    timeZoneId: 'America/Chicago',
  },
  '71226': {
    zip: '71226',
    city: 'Chatham',
    state: 'LA',
    latitude: '32.24946',
    longitude: '-92.4168',
    timeZoneId: 'America/Chicago',
  },
  '71227': {
    zip: '71227',
    city: 'Choudrant',
    state: 'LA',
    latitude: '32.49678',
    longitude: '-92.47332',
    timeZoneId: 'America/Chicago',
  },
  '71229': {
    zip: '71229',
    city: 'Collinston',
    state: 'LA',
    latitude: '32.686654',
    longitude: '-91.816401',
    timeZoneId: 'America/Chicago',
  },
  '71230': {
    zip: '71230',
    city: 'Crowville',
    state: 'LA',
    latitude: '32.142357',
    longitude: '-91.701688',
    timeZoneId: 'America/Chicago',
  },
  '71232': {
    zip: '71232',
    city: 'Delhi',
    state: 'LA',
    latitude: '32.453833',
    longitude: '-91.489746',
    timeZoneId: 'America/Chicago',
  },
  '71233': {
    zip: '71233',
    city: 'Delta',
    state: 'LA',
    latitude: '32.32075',
    longitude: '-90.925348',
    timeZoneId: 'America/Chicago',
  },
  '71234': {
    zip: '71234',
    city: 'Downsville',
    state: 'LA',
    latitude: '32.58198',
    longitude: '-92.3451',
    timeZoneId: 'America/Chicago',
  },
  '71235': {
    zip: '71235',
    city: 'Dubach',
    state: 'LA',
    latitude: '32.71218',
    longitude: '-92.65602',
    timeZoneId: 'America/Chicago',
  },
  '71237': {
    zip: '71237',
    city: 'Epps',
    state: 'LA',
    latitude: '32.602579',
    longitude: '-91.481584',
    timeZoneId: 'America/Chicago',
  },
  '71238': {
    zip: '71238',
    city: 'Eros',
    state: 'LA',
    latitude: '32.3643',
    longitude: '-92.36148',
    timeZoneId: 'America/Chicago',
  },
  '71240': {
    zip: '71240',
    city: 'Fairbanks',
    state: 'LA',
    latitude: '32.643216',
    longitude: '-92.03588',
    timeZoneId: 'America/Chicago',
  },
  '71241': {
    zip: '71241',
    city: 'Farmerville',
    state: 'LA',
    latitude: '32.77152',
    longitude: '-92.33262',
    timeZoneId: 'America/Chicago',
  },
  '71242': {
    zip: '71242',
    city: 'Forest',
    state: 'LA',
    latitude: '32.859905',
    longitude: '-91.39079',
    timeZoneId: 'America/Chicago',
  },
  '71243': {
    zip: '71243',
    city: 'Fort Necessity',
    state: 'LA',
    latitude: '32.04492',
    longitude: '-91.82028',
    timeZoneId: 'America/Chicago',
  },
  '71245': {
    zip: '71245',
    city: 'Grambling',
    state: 'LA',
    latitude: '32.523791',
    longitude: '-92.715177',
    timeZoneId: 'America/Chicago',
  },
  '71247': {
    zip: '71247',
    city: 'Hodge',
    state: 'LA',
    latitude: '32.278002',
    longitude: '-92.71224',
    timeZoneId: 'America/Chicago',
  },
  '71249': {
    zip: '71249',
    city: 'Jigger',
    state: 'LA',
    latitude: '32.034328',
    longitude: '-91.746559',
    timeZoneId: 'America/Chicago',
  },
  '71250': {
    zip: '71250',
    city: 'Jones',
    state: 'LA',
    latitude: '32.939158',
    longitude: '-91.554592',
    timeZoneId: 'America/Chicago',
  },
  '71251': {
    zip: '71251',
    city: 'Jonesboro',
    state: 'LA',
    latitude: '32.23782',
    longitude: '-92.64204',
    timeZoneId: 'America/Chicago',
  },
  '71253': {
    zip: '71253',
    city: 'Kilbourne',
    state: 'LA',
    latitude: '32.870714',
    longitude: '-91.42753',
    timeZoneId: 'America/Chicago',
  },
  '71254': {
    zip: '71254',
    city: 'Lake Providence',
    state: 'LA',
    latitude: '32.804753',
    longitude: '-91.177769',
    timeZoneId: 'America/Chicago',
  },
  '71256': {
    zip: '71256',
    city: 'Lillie',
    state: 'LA',
    latitude: '32.925196',
    longitude: '-92.691728',
    timeZoneId: 'America/Chicago',
  },
  '71259': {
    zip: '71259',
    city: 'Mangham',
    state: 'LA',
    latitude: '32.295425',
    longitude: '-91.832115',
    timeZoneId: 'America/Chicago',
  },
  '71260': {
    zip: '71260',
    city: 'Marion',
    state: 'LA',
    latitude: '32.909183',
    longitude: '-92.241002',
    timeZoneId: 'America/Chicago',
  },
  '71261': {
    zip: '71261',
    city: 'Mer Rouge',
    state: 'LA',
    latitude: '32.766146',
    longitude: '-91.751212',
    timeZoneId: 'America/Chicago',
  },
  '71263': {
    zip: '71263',
    city: 'Oak Grove',
    state: 'LA',
    latitude: '32.84016',
    longitude: '-91.46778',
    timeZoneId: 'America/Chicago',
  },
  '71264': {
    zip: '71264',
    city: 'Oak Ridge',
    state: 'LA',
    latitude: '32.645846',
    longitude: '-91.746526',
    timeZoneId: 'America/Chicago',
  },
  '71266': {
    zip: '71266',
    city: 'Pioneer',
    state: 'LA',
    latitude: '32.709261',
    longitude: '-91.482032',
    timeZoneId: 'America/Chicago',
  },
  '71268': {
    zip: '71268',
    city: 'Quitman',
    state: 'LA',
    latitude: '32.36326',
    longitude: '-92.65082',
    timeZoneId: 'America/Chicago',
  },
  '71269': {
    zip: '71269',
    city: 'Rayville',
    state: 'LA',
    latitude: '32.48136',
    longitude: '-91.76166',
    timeZoneId: 'America/Chicago',
  },
  '71270': {
    zip: '71270',
    city: 'Ruston',
    state: 'LA',
    latitude: '32.48418',
    longitude: '-92.6682',
    timeZoneId: 'America/Chicago',
  },
  '71272': {
    zip: '71272',
    city: 'Ruston',
    state: 'LA',
    latitude: '32.529801',
    longitude: '-92.65016',
    timeZoneId: 'America/Chicago',
  },
  '71273': {
    zip: '71273',
    city: 'Ruston',
    state: 'LA',
    latitude: '32.558247',
    longitude: '-92.590363',
    timeZoneId: 'America/Chicago',
  },
  '71275': {
    zip: '71275',
    city: 'Simsboro',
    state: 'LA',
    latitude: '32.53047',
    longitude: '-92.786586',
    timeZoneId: 'America/Chicago',
  },
  '71276': {
    zip: '71276',
    city: 'Sondheimer',
    state: 'LA',
    latitude: '32.571944',
    longitude: '-91.182222',
    timeZoneId: 'America/Chicago',
  },
  '71277': {
    zip: '71277',
    city: 'Spearsville',
    state: 'LA',
    latitude: '32.897756',
    longitude: '-92.60921',
    timeZoneId: 'America/Chicago',
  },
  '71279': {
    zip: '71279',
    city: 'Start',
    state: 'LA',
    latitude: '32.489072',
    longitude: '-91.858583',
    timeZoneId: 'America/Chicago',
  },
  '71280': {
    zip: '71280',
    city: 'Sterlington',
    state: 'LA',
    latitude: '32.616',
    longitude: '-92.15742',
    timeZoneId: 'America/Chicago',
  },
  '71281': {
    zip: '71281',
    city: 'Swartz',
    state: 'LA',
    latitude: '32.595352',
    longitude: '-92.020992',
    timeZoneId: 'America/Chicago',
  },
  '71282': {
    zip: '71282',
    city: 'Tallulah',
    state: 'LA',
    latitude: '32.37468',
    longitude: '-91.06548',
    timeZoneId: 'America/Chicago',
  },
  '71284': {
    zip: '71284',
    city: 'Tallulah',
    state: 'LA',
    latitude: '32.406598',
    longitude: '-91.186321',
    timeZoneId: 'America/Chicago',
  },
  '71286': {
    zip: '71286',
    city: 'Transylvania',
    state: 'LA',
    latitude: '32.664934',
    longitude: '-91.25406',
    timeZoneId: 'America/Chicago',
  },
  '71291': {
    zip: '71291',
    city: 'West Monroe',
    state: 'LA',
    latitude: '32.503812',
    longitude: '-92.191028',
    timeZoneId: 'America/Chicago',
  },
  '71292': {
    zip: '71292',
    city: 'West Monroe',
    state: 'LA',
    latitude: '32.444493',
    longitude: '-92.203495',
    timeZoneId: 'America/Chicago',
  },
  '71294': {
    zip: '71294',
    city: 'West Monroe',
    state: 'LA',
    latitude: '32.511082',
    longitude: '-92.182026',
    timeZoneId: 'America/Chicago',
  },
  '71295': {
    zip: '71295',
    city: 'Winnsboro',
    state: 'LA',
    latitude: '32.15836',
    longitude: '-91.72343',
    timeZoneId: 'America/Chicago',
  },
  '71301': {
    zip: '71301',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.28496',
    longitude: '-92.46546',
    timeZoneId: 'America/Chicago',
  },
  '71302': {
    zip: '71302',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.266926',
    longitude: '-92.417063',
    timeZoneId: 'America/Chicago',
  },
  '71303': {
    zip: '71303',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.300867',
    longitude: '-92.503466',
    timeZoneId: 'America/Chicago',
  },
  '71306': {
    zip: '71306',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.194386',
    longitude: '-92.671547',
    timeZoneId: 'America/Chicago',
  },
  '71307': {
    zip: '71307',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.284883',
    longitude: '-92.45227',
    timeZoneId: 'America/Chicago',
  },
  '71309': {
    zip: '71309',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.255733',
    longitude: '-92.470566',
    timeZoneId: 'America/Chicago',
  },
  '71315': {
    zip: '71315',
    city: 'Alexandria',
    state: 'LA',
    latitude: '31.25506',
    longitude: '-92.471756',
    timeZoneId: 'America/Chicago',
  },
  '71316': {
    zip: '71316',
    city: 'Acme',
    state: 'LA',
    latitude: '31.260852',
    longitude: '-91.7667',
    timeZoneId: 'America/Chicago',
  },
  '71320': {
    zip: '71320',
    city: 'Bordelonville',
    state: 'LA',
    latitude: '31.108017',
    longitude: '-91.906707',
    timeZoneId: 'America/Chicago',
  },
  '71322': {
    zip: '71322',
    city: 'Bunkie',
    state: 'LA',
    latitude: '30.94338',
    longitude: '-92.18526',
    timeZoneId: 'America/Chicago',
  },
  '71323': {
    zip: '71323',
    city: 'Center Point',
    state: 'LA',
    latitude: '31.253134',
    longitude: '-92.240147',
    timeZoneId: 'America/Chicago',
  },
  '71324': {
    zip: '71324',
    city: 'Chase',
    state: 'LA',
    latitude: '32.094945',
    longitude: '-91.700927',
    timeZoneId: 'America/Chicago',
  },
  '71325': {
    zip: '71325',
    city: 'Cheneyville',
    state: 'LA',
    latitude: '31.018235',
    longitude: '-92.292222',
    timeZoneId: 'America/Chicago',
  },
  '71326': {
    zip: '71326',
    city: 'Clayton',
    state: 'LA',
    latitude: '31.74648',
    longitude: '-91.49532',
    timeZoneId: 'America/Chicago',
  },
  '71327': {
    zip: '71327',
    city: 'Cottonport',
    state: 'LA',
    latitude: '30.988947',
    longitude: '-92.052747',
    timeZoneId: 'America/Chicago',
  },
  '71328': {
    zip: '71328',
    city: 'Deville',
    state: 'LA',
    latitude: '31.359785',
    longitude: '-92.203943',
    timeZoneId: 'America/Chicago',
  },
  '71329': {
    zip: '71329',
    city: 'Dupont',
    state: 'LA',
    latitude: '30.93021',
    longitude: '-91.947325',
    timeZoneId: 'America/Chicago',
  },
  '71330': {
    zip: '71330',
    city: 'Echo',
    state: 'LA',
    latitude: '31.121655',
    longitude: '-92.255093',
    timeZoneId: 'America/Chicago',
  },
  '71331': {
    zip: '71331',
    city: 'Effie',
    state: 'LA',
    latitude: '31.21956',
    longitude: '-92.1186',
    timeZoneId: 'America/Chicago',
  },
  '71333': {
    zip: '71333',
    city: 'Evergreen',
    state: 'LA',
    latitude: '30.946851',
    longitude: '-92.094994',
    timeZoneId: 'America/Chicago',
  },
  '71334': {
    zip: '71334',
    city: 'Ferriday',
    state: 'LA',
    latitude: '31.62308',
    longitude: '-91.551226',
    timeZoneId: 'America/Chicago',
  },
  '71336': {
    zip: '71336',
    city: 'Gilbert',
    state: 'LA',
    latitude: '32.00988',
    longitude: '-91.56853',
    timeZoneId: 'America/Chicago',
  },
  '71339': {
    zip: '71339',
    city: 'Hamburg',
    state: 'LA',
    latitude: '31.061489',
    longitude: '-91.921786',
    timeZoneId: 'America/Chicago',
  },
  '71340': {
    zip: '71340',
    city: 'Harrisonburg',
    state: 'LA',
    latitude: '31.79511',
    longitude: '-91.893312',
    timeZoneId: 'America/Chicago',
  },
  '71341': {
    zip: '71341',
    city: 'Hessmer',
    state: 'LA',
    latitude: '31.055529',
    longitude: '-92.141657',
    timeZoneId: 'America/Chicago',
  },
  '71342': {
    zip: '71342',
    city: 'Jena',
    state: 'LA',
    latitude: '31.64292',
    longitude: '-92.0979',
    timeZoneId: 'America/Chicago',
  },
  '71343': {
    zip: '71343',
    city: 'Jonesville',
    state: 'LA',
    latitude: '31.651441',
    longitude: '-91.826706',
    timeZoneId: 'America/Chicago',
  },
  '71345': {
    zip: '71345',
    city: 'Lebeau',
    state: 'LA',
    latitude: '30.73357',
    longitude: '-91.976292',
    timeZoneId: 'America/Chicago',
  },
  '71346': {
    zip: '71346',
    city: 'Lecompte',
    state: 'LA',
    latitude: '31.107087',
    longitude: '-92.401052',
    timeZoneId: 'America/Chicago',
  },
  '71348': {
    zip: '71348',
    city: 'Libuse',
    state: 'LA',
    latitude: '31.26357',
    longitude: '-92.424744',
    timeZoneId: 'America/Chicago',
  },
  '71350': {
    zip: '71350',
    city: 'Mansura',
    state: 'LA',
    latitude: '31.083712',
    longitude: '-92.022496',
    timeZoneId: 'America/Chicago',
  },
  '71351': {
    zip: '71351',
    city: 'Marksville',
    state: 'LA',
    latitude: '31.16148',
    longitude: '-92.09502',
    timeZoneId: 'America/Chicago',
  },
  '71353': {
    zip: '71353',
    city: 'Melville',
    state: 'LA',
    latitude: '30.655155',
    longitude: '-91.789849',
    timeZoneId: 'America/Chicago',
  },
  '71354': {
    zip: '71354',
    city: 'Monterey',
    state: 'LA',
    latitude: '31.345723',
    longitude: '-91.668555',
    timeZoneId: 'America/Chicago',
  },
  '71355': {
    zip: '71355',
    city: 'Moreauville',
    state: 'LA',
    latitude: '31.042265',
    longitude: '-91.98192',
    timeZoneId: 'America/Chicago',
  },
  '71356': {
    zip: '71356',
    city: 'Morrow',
    state: 'LA',
    latitude: '30.82422',
    longitude: '-92.04168',
    timeZoneId: 'America/Chicago',
  },
  '71357': {
    zip: '71357',
    city: 'Newellton',
    state: 'LA',
    latitude: '32.07648',
    longitude: '-91.2438',
    timeZoneId: 'America/Chicago',
  },
  '71358': {
    zip: '71358',
    city: 'Palmetto',
    state: 'LA',
    latitude: '30.699586',
    longitude: '-91.915616',
    timeZoneId: 'America/Chicago',
  },
  '71359': {
    zip: '71359',
    city: 'Pineville',
    state: 'LA',
    latitude: '31.323914',
    longitude: '-92.427031',
    timeZoneId: 'America/Chicago',
  },
  '71360': {
    zip: '71360',
    city: 'Pineville',
    state: 'LA',
    latitude: '31.348955',
    longitude: '-92.392557',
    timeZoneId: 'America/Chicago',
  },
  '71361': {
    zip: '71361',
    city: 'Pineville',
    state: 'LA',
    latitude: '31.356431',
    longitude: '-92.406647',
    timeZoneId: 'America/Chicago',
  },
  '71362': {
    zip: '71362',
    city: 'Plaucheville',
    state: 'LA',
    latitude: '30.920973',
    longitude: '-91.990157',
    timeZoneId: 'America/Chicago',
  },
  '71363': {
    zip: '71363',
    city: 'Rhinehart',
    state: 'LA',
    latitude: '31.633967',
    longitude: '-91.998846',
    timeZoneId: 'America/Chicago',
  },
  '71365': {
    zip: '71365',
    city: 'Ruby',
    state: 'LA',
    latitude: '31.189844',
    longitude: '-92.246366',
    timeZoneId: 'America/Chicago',
  },
  '71366': {
    zip: '71366',
    city: 'Saint Joseph',
    state: 'LA',
    latitude: '31.91583',
    longitude: '-91.239809',
    timeZoneId: 'America/Chicago',
  },
  '71367': {
    zip: '71367',
    city: 'Saint Landry',
    state: 'LA',
    latitude: '30.850864',
    longitude: '-92.412602',
    timeZoneId: 'America/Chicago',
  },
  '71368': {
    zip: '71368',
    city: 'Sicily Island',
    state: 'LA',
    latitude: '31.85457',
    longitude: '-91.693483',
    timeZoneId: 'America/Chicago',
  },
  '71369': {
    zip: '71369',
    city: 'Simmesport',
    state: 'LA',
    latitude: '30.989911',
    longitude: '-91.825861',
    timeZoneId: 'America/Chicago',
  },
  '71371': {
    zip: '71371',
    city: 'Trout',
    state: 'LA',
    latitude: '31.705',
    longitude: '-92.228056',
    timeZoneId: 'America/Chicago',
  },
  '71373': {
    zip: '71373',
    city: 'Vidalia',
    state: 'LA',
    latitude: '31.59012',
    longitude: '-91.48704',
    timeZoneId: 'America/Chicago',
  },
  '71375': {
    zip: '71375',
    city: 'Waterproof',
    state: 'LA',
    latitude: '31.822058',
    longitude: '-91.382756',
    timeZoneId: 'America/Chicago',
  },
  '71377': {
    zip: '71377',
    city: 'Wildsville',
    state: 'LA',
    latitude: '31.614891',
    longitude: '-91.781568',
    timeZoneId: 'America/Chicago',
  },
  '71378': {
    zip: '71378',
    city: 'Wisner',
    state: 'LA',
    latitude: '31.971417',
    longitude: '-91.657424',
    timeZoneId: 'America/Chicago',
  },
  '71401': {
    zip: '71401',
    city: 'Aimwell',
    state: 'LA',
    latitude: '31.754412',
    longitude: '-91.996022',
    timeZoneId: 'America/Chicago',
  },
  '71403': {
    zip: '71403',
    city: 'Anacoco',
    state: 'LA',
    latitude: '31.21078',
    longitude: '-93.391572',
    timeZoneId: 'America/Chicago',
  },
  '71404': {
    zip: '71404',
    city: 'Atlanta',
    state: 'LA',
    latitude: '31.832832',
    longitude: '-92.788989',
    timeZoneId: 'America/Chicago',
  },
  '71405': {
    zip: '71405',
    city: 'Ball',
    state: 'LA',
    latitude: '31.415594',
    longitude: '-92.405924',
    timeZoneId: 'America/Chicago',
  },
  '71406': {
    zip: '71406',
    city: 'Belmont',
    state: 'LA',
    latitude: '31.709761',
    longitude: '-93.498938',
    timeZoneId: 'America/Chicago',
  },
  '71407': {
    zip: '71407',
    city: 'Bentley',
    state: 'LA',
    latitude: '31.483526',
    longitude: '-92.497502',
    timeZoneId: 'America/Chicago',
  },
  '71409': {
    zip: '71409',
    city: 'Boyce',
    state: 'LA',
    latitude: '31.30986',
    longitude: '-92.70708',
    timeZoneId: 'America/Chicago',
  },
  '71410': {
    zip: '71410',
    city: 'Calvin',
    state: 'LA',
    latitude: '31.965432',
    longitude: '-92.775052',
    timeZoneId: 'America/Chicago',
  },
  '71411': {
    zip: '71411',
    city: 'Campti',
    state: 'LA',
    latitude: '31.896095',
    longitude: '-93.116422',
    timeZoneId: 'America/Chicago',
  },
  '71414': {
    zip: '71414',
    city: 'Clarence',
    state: 'LA',
    latitude: '31.820111',
    longitude: '-93.027243',
    timeZoneId: 'America/Chicago',
  },
  '71415': {
    zip: '71415',
    city: 'Clarks',
    state: 'LA',
    latitude: '32.029604',
    longitude: '-92.13975',
    timeZoneId: 'America/Chicago',
  },
  '71416': {
    zip: '71416',
    city: 'Cloutierville',
    state: 'LA',
    latitude: '31.58484',
    longitude: '-92.901',
    timeZoneId: 'America/Chicago',
  },
  '71417': {
    zip: '71417',
    city: 'Colfax',
    state: 'LA',
    latitude: '31.51919',
    longitude: '-92.705968',
    timeZoneId: 'America/Chicago',
  },
  '71418': {
    zip: '71418',
    city: 'Columbia',
    state: 'LA',
    latitude: '32.12886',
    longitude: '-92.15376',
    timeZoneId: 'America/Chicago',
  },
  '71419': {
    zip: '71419',
    city: 'Converse',
    state: 'LA',
    latitude: '31.723825',
    longitude: '-93.637496',
    timeZoneId: 'America/Chicago',
  },
  '71422': {
    zip: '71422',
    city: 'Dodson',
    state: 'LA',
    latitude: '32.06688',
    longitude: '-92.6454',
    timeZoneId: 'America/Chicago',
  },
  '71423': {
    zip: '71423',
    city: 'Dry Prong',
    state: 'LA',
    latitude: '31.6185',
    longitude: '-92.5704',
    timeZoneId: 'America/Chicago',
  },
  '71424': {
    zip: '71424',
    city: 'Elmer',
    state: 'LA',
    latitude: '31.151862',
    longitude: '-92.708766',
    timeZoneId: 'America/Chicago',
  },
  '71425': {
    zip: '71425',
    city: 'Enterprise',
    state: 'LA',
    latitude: '31.891021',
    longitude: '-91.881818',
    timeZoneId: 'America/Chicago',
  },
  '71426': {
    zip: '71426',
    city: 'Fisher',
    state: 'LA',
    latitude: '31.498074',
    longitude: '-93.469893',
    timeZoneId: 'America/Chicago',
  },
  '71427': {
    zip: '71427',
    city: 'Flatwoods',
    state: 'LA',
    latitude: '31.389387',
    longitude: '-92.875928',
    timeZoneId: 'America/Chicago',
  },
  '71428': {
    zip: '71428',
    city: 'Flora',
    state: 'LA',
    latitude: '31.616825',
    longitude: '-93.094642',
    timeZoneId: 'America/Chicago',
  },
  '71429': {
    zip: '71429',
    city: 'Florien',
    state: 'LA',
    latitude: '31.562771',
    longitude: '-93.47879',
    timeZoneId: 'America/Chicago',
  },
  '71430': {
    zip: '71430',
    city: 'Forest Hill',
    state: 'LA',
    latitude: '31.051',
    longitude: '-92.507059',
    timeZoneId: 'America/Chicago',
  },
  '71431': {
    zip: '71431',
    city: 'Gardner',
    state: 'LA',
    latitude: '31.266691',
    longitude: '-92.693747',
    timeZoneId: 'America/Chicago',
  },
  '71432': {
    zip: '71432',
    city: 'Georgetown',
    state: 'LA',
    latitude: '31.7382',
    longitude: '-92.391755',
    timeZoneId: 'America/Chicago',
  },
  '71433': {
    zip: '71433',
    city: 'Glenmora',
    state: 'LA',
    latitude: '30.984468',
    longitude: '-92.639592',
    timeZoneId: 'America/Chicago',
  },
  '71434': {
    zip: '71434',
    city: 'Gorum',
    state: 'LA',
    latitude: '31.432994',
    longitude: '-92.943122',
    timeZoneId: 'America/Chicago',
  },
  '71435': {
    zip: '71435',
    city: 'Grayson',
    state: 'LA',
    latitude: '32.008428',
    longitude: '-91.951004',
    timeZoneId: 'America/Chicago',
  },
  '71438': {
    zip: '71438',
    city: 'Hineston',
    state: 'LA',
    latitude: '31.149021',
    longitude: '-92.748532',
    timeZoneId: 'America/Chicago',
  },
  '71439': {
    zip: '71439',
    city: 'Hornbeck',
    state: 'LA',
    latitude: '31.320895',
    longitude: '-93.312646',
    timeZoneId: 'America/Chicago',
  },
  '71440': {
    zip: '71440',
    city: 'Joyce',
    state: 'LA',
    latitude: '31.94043',
    longitude: '-92.599937',
    timeZoneId: 'America/Chicago',
  },
  '71441': {
    zip: '71441',
    city: 'Kelly',
    state: 'LA',
    latitude: '32.001711',
    longitude: '-92.149787',
    timeZoneId: 'America/Chicago',
  },
  '71443': {
    zip: '71443',
    city: 'Kurthwood',
    state: 'LA',
    latitude: '31.334766',
    longitude: '-93.164776',
    timeZoneId: 'America/Chicago',
  },
  '71446': {
    zip: '71446',
    city: 'Leesville',
    state: 'LA',
    latitude: '31.123769',
    longitude: '-93.277892',
    timeZoneId: 'America/Chicago',
  },
  '71447': {
    zip: '71447',
    city: 'Lena',
    state: 'LA',
    latitude: '31.41762',
    longitude: '-92.777658',
    timeZoneId: 'America/Chicago',
  },
  '71448': {
    zip: '71448',
    city: 'Longleaf',
    state: 'LA',
    latitude: '31.008725',
    longitude: '-92.553968',
    timeZoneId: 'America/Chicago',
  },
  '71449': {
    zip: '71449',
    city: 'Many',
    state: 'LA',
    latitude: '31.564189',
    longitude: '-93.481045',
    timeZoneId: 'America/Chicago',
  },
  '71450': {
    zip: '71450',
    city: 'Marthaville',
    state: 'LA',
    latitude: '31.79304',
    longitude: '-93.39966',
    timeZoneId: 'America/Chicago',
  },
  '71452': {
    zip: '71452',
    city: 'Melrose',
    state: 'LA',
    latitude: '31.650694',
    longitude: '-93.01679',
    timeZoneId: 'America/Chicago',
  },
  '71454': {
    zip: '71454',
    city: 'Montgomery',
    state: 'LA',
    latitude: '31.6261',
    longitude: '-92.818663',
    timeZoneId: 'America/Chicago',
  },
  '71455': {
    zip: '71455',
    city: 'Mora',
    state: 'LA',
    latitude: '31.330922',
    longitude: '-92.88315',
    timeZoneId: 'America/Chicago',
  },
  '71456': {
    zip: '71456',
    city: 'Natchez',
    state: 'LA',
    latitude: '31.63866',
    longitude: '-92.99706',
    timeZoneId: 'America/Chicago',
  },
  '71457': {
    zip: '71457',
    city: 'Natchitoches',
    state: 'LA',
    latitude: '31.78722',
    longitude: '-93.12816',
    timeZoneId: 'America/Chicago',
  },
  '71458': {
    zip: '71458',
    city: 'Natchitoches',
    state: 'LA',
    latitude: '31.757608',
    longitude: '-93.086235',
    timeZoneId: 'America/Chicago',
  },
  '71459': {
    zip: '71459',
    city: 'Fort Polk',
    state: 'LA',
    latitude: '30.99582',
    longitude: '-93.2469',
    timeZoneId: 'America/Chicago',
  },
  '71460': {
    zip: '71460',
    city: 'Negreet',
    state: 'LA',
    latitude: '31.471486',
    longitude: '-93.572687',
    timeZoneId: 'America/Chicago',
  },
  '71461': {
    zip: '71461',
    city: 'New Llano',
    state: 'LA',
    latitude: '31.115114',
    longitude: '-93.283142',
    timeZoneId: 'America/Chicago',
  },
  '71462': {
    zip: '71462',
    city: 'Noble',
    state: 'LA',
    latitude: '31.69284',
    longitude: '-93.69936',
    timeZoneId: 'America/Chicago',
  },
  '71463': {
    zip: '71463',
    city: 'Oakdale',
    state: 'LA',
    latitude: '30.817482',
    longitude: '-92.658202',
    timeZoneId: 'America/Chicago',
  },
  '71465': {
    zip: '71465',
    city: 'Olla',
    state: 'LA',
    latitude: '31.907286',
    longitude: '-92.223113',
    timeZoneId: 'America/Chicago',
  },
  '71466': {
    zip: '71466',
    city: 'Otis',
    state: 'LA',
    latitude: '31.21838',
    longitude: '-92.742689',
    timeZoneId: 'America/Chicago',
  },
  '71467': {
    zip: '71467',
    city: 'Pollock',
    state: 'LA',
    latitude: '31.49814',
    longitude: '-92.42142',
    timeZoneId: 'America/Chicago',
  },
  '71468': {
    zip: '71468',
    city: 'Provencal',
    state: 'LA',
    latitude: '31.5381',
    longitude: '-93.11526',
    timeZoneId: 'America/Chicago',
  },
  '71469': {
    zip: '71469',
    city: 'Robeline',
    state: 'LA',
    latitude: '31.807619',
    longitude: '-93.309894',
    timeZoneId: 'America/Chicago',
  },
  '71471': {
    zip: '71471',
    city: 'Saint Maurice',
    state: 'LA',
    latitude: '31.757113',
    longitude: '-92.962011',
    timeZoneId: 'America/Chicago',
  },
  '71472': {
    zip: '71472',
    city: 'Sieper',
    state: 'LA',
    latitude: '31.205098',
    longitude: '-92.787908',
    timeZoneId: 'America/Chicago',
  },
  '71473': {
    zip: '71473',
    city: 'Sikes',
    state: 'LA',
    latitude: '32.0673',
    longitude: '-92.4225',
    timeZoneId: 'America/Chicago',
  },
  '71474': {
    zip: '71474',
    city: 'Simpson',
    state: 'LA',
    latitude: '31.242969',
    longitude: '-93.015952',
    timeZoneId: 'America/Chicago',
  },
  '71475': {
    zip: '71475',
    city: 'Slagle',
    state: 'LA',
    latitude: '31.198011',
    longitude: '-93.136354',
    timeZoneId: 'America/Chicago',
  },
  '71477': {
    zip: '71477',
    city: 'Tioga',
    state: 'LA',
    latitude: '31.394424',
    longitude: '-92.434818',
    timeZoneId: 'America/Chicago',
  },
  '71479': {
    zip: '71479',
    city: 'Tullos',
    state: 'LA',
    latitude: '31.858786',
    longitude: '-92.289315',
    timeZoneId: 'America/Chicago',
  },
  '71480': {
    zip: '71480',
    city: 'Urania',
    state: 'LA',
    latitude: '31.863803',
    longitude: '-92.290883',
    timeZoneId: 'America/Chicago',
  },
  '71483': {
    zip: '71483',
    city: 'Winnfield',
    state: 'LA',
    latitude: '31.922636',
    longitude: '-92.645336',
    timeZoneId: 'America/Chicago',
  },
  '71485': {
    zip: '71485',
    city: 'Woodworth',
    state: 'LA',
    latitude: '31.160578',
    longitude: '-92.499928',
    timeZoneId: 'America/Chicago',
  },
  '71486': {
    zip: '71486',
    city: 'Zwolle',
    state: 'LA',
    latitude: '31.623819',
    longitude: '-93.634378',
    timeZoneId: 'America/Chicago',
  },
  '71496': {
    zip: '71496',
    city: 'Leesville',
    state: 'LA',
    latitude: '31.143588',
    longitude: '-93.258388',
    timeZoneId: 'America/Chicago',
  },
  '71497': {
    zip: '71497',
    city: 'Natchitoches',
    state: 'LA',
    latitude: '31.755063',
    longitude: '-93.10124',
    timeZoneId: 'America/Chicago',
  },
  '71601': {
    zip: '71601',
    city: 'Pine Bluff',
    state: 'AR',
    latitude: '34.20168',
    longitude: '-91.929399',
    timeZoneId: 'America/Chicago',
  },
  '71602': {
    zip: '71602',
    city: 'White Hall',
    state: 'AR',
    latitude: '34.261382',
    longitude: '-92.132192',
    timeZoneId: 'America/Chicago',
  },
  '71603': {
    zip: '71603',
    city: 'Pine Bluff',
    state: 'AR',
    latitude: '34.198278',
    longitude: '-92.034403',
    timeZoneId: 'America/Chicago',
  },
  '71611': {
    zip: '71611',
    city: 'Pine Bluff',
    state: 'AR',
    latitude: '34.200673',
    longitude: '-91.939537',
    timeZoneId: 'America/Chicago',
  },
  '71612': {
    zip: '71612',
    city: 'White Hall',
    state: 'AR',
    latitude: '34.228866',
    longitude: '-92.00089',
    timeZoneId: 'America/Chicago',
  },
  '71613': {
    zip: '71613',
    city: 'Pine Bluff',
    state: 'AR',
    latitude: '34.140197',
    longitude: '-92.10718',
    timeZoneId: 'America/Chicago',
  },
  '71630': {
    zip: '71630',
    city: 'Arkansas City',
    state: 'AR',
    latitude: '33.590628',
    longitude: '-91.248596',
    timeZoneId: 'America/Chicago',
  },
  '71631': {
    zip: '71631',
    city: 'Banks',
    state: 'AR',
    latitude: '33.5443',
    longitude: '-92.26543',
    timeZoneId: 'America/Chicago',
  },
  '71635': {
    zip: '71635',
    city: 'Crossett',
    state: 'AR',
    latitude: '33.129096',
    longitude: '-91.960667',
    timeZoneId: 'America/Chicago',
  },
  '71638': {
    zip: '71638',
    city: 'Dermott',
    state: 'AR',
    latitude: '33.525724',
    longitude: '-91.435385',
    timeZoneId: 'America/Chicago',
  },
  '71639': {
    zip: '71639',
    city: 'Dumas',
    state: 'AR',
    latitude: '33.888705',
    longitude: '-91.490885',
    timeZoneId: 'America/Chicago',
  },
  '71640': {
    zip: '71640',
    city: 'Eudora',
    state: 'AR',
    latitude: '33.10548',
    longitude: '-91.25988',
    timeZoneId: 'America/Chicago',
  },
  '71642': {
    zip: '71642',
    city: 'Fountain Hill',
    state: 'AR',
    latitude: '33.344234',
    longitude: '-91.818649',
    timeZoneId: 'America/Chicago',
  },
  '71643': {
    zip: '71643',
    city: 'Gould',
    state: 'AR',
    latitude: '33.984603',
    longitude: '-91.556472',
    timeZoneId: 'America/Chicago',
  },
  '71644': {
    zip: '71644',
    city: 'Grady',
    state: 'AR',
    latitude: '34.08996',
    longitude: '-91.73424',
    timeZoneId: 'America/Chicago',
  },
  '71646': {
    zip: '71646',
    city: 'Hamburg',
    state: 'AR',
    latitude: '33.18696',
    longitude: '-91.85364',
    timeZoneId: 'America/Chicago',
  },
  '71647': {
    zip: '71647',
    city: 'Hermitage',
    state: 'AR',
    latitude: '33.39942',
    longitude: '-92.1237',
    timeZoneId: 'America/Chicago',
  },
  '71651': {
    zip: '71651',
    city: 'Jersey',
    state: 'AR',
    latitude: '33.347207',
    longitude: '-92.285793',
    timeZoneId: 'America/Chicago',
  },
  '71652': {
    zip: '71652',
    city: 'Kingsland',
    state: 'AR',
    latitude: '33.877586',
    longitude: '-92.306949',
    timeZoneId: 'America/Chicago',
  },
  '71653': {
    zip: '71653',
    city: 'Lake Village',
    state: 'AR',
    latitude: '33.330484',
    longitude: '-91.284186',
    timeZoneId: 'America/Chicago',
  },
  '71654': {
    zip: '71654',
    city: 'Mc Gehee',
    state: 'AR',
    latitude: '33.65646',
    longitude: '-91.40802',
    timeZoneId: 'America/Chicago',
  },
  '71655': {
    zip: '71655',
    city: 'Monticello',
    state: 'AR',
    latitude: '33.6249',
    longitude: '-91.8009',
    timeZoneId: 'America/Chicago',
  },
  '71656': {
    zip: '71656',
    city: 'Monticello',
    state: 'AR',
    latitude: '33.630719',
    longitude: '-91.791081',
    timeZoneId: 'America/Chicago',
  },
  '71657': {
    zip: '71657',
    city: 'Monticello',
    state: 'AR',
    latitude: '33.625295',
    longitude: '-91.789337',
    timeZoneId: 'America/Chicago',
  },
  '71658': {
    zip: '71658',
    city: 'Montrose',
    state: 'AR',
    latitude: '33.30654',
    longitude: '-91.51518',
    timeZoneId: 'America/Chicago',
  },
  '71659': {
    zip: '71659',
    city: 'Moscow',
    state: 'AR',
    latitude: '34.146589',
    longitude: '-91.795504',
    timeZoneId: 'America/Chicago',
  },
  '71660': {
    zip: '71660',
    city: 'New Edinburg',
    state: 'AR',
    latitude: '33.763918',
    longitude: '-92.192406',
    timeZoneId: 'America/Chicago',
  },
  '71661': {
    zip: '71661',
    city: 'Parkdale',
    state: 'AR',
    latitude: '33.112866',
    longitude: '-91.52822',
    timeZoneId: 'America/Chicago',
  },
  '71662': {
    zip: '71662',
    city: 'Pickens',
    state: 'AR',
    latitude: '33.81228',
    longitude: '-91.4031',
    timeZoneId: 'America/Chicago',
  },
  '71663': {
    zip: '71663',
    city: 'Portland',
    state: 'AR',
    latitude: '33.238405',
    longitude: '-91.516386',
    timeZoneId: 'America/Chicago',
  },
  '71665': {
    zip: '71665',
    city: 'Rison',
    state: 'AR',
    latitude: '34.00866',
    longitude: '-92.1411',
    timeZoneId: 'America/Chicago',
  },
  '71666': {
    zip: '71666',
    city: 'Rohwer',
    state: 'AR',
    latitude: '33.659552',
    longitude: '-91.194003',
    timeZoneId: 'America/Chicago',
  },
  '71667': {
    zip: '71667',
    city: 'Star City',
    state: 'AR',
    latitude: '33.939645',
    longitude: '-91.844739',
    timeZoneId: 'America/Chicago',
  },
  '71670': {
    zip: '71670',
    city: 'Tillar',
    state: 'AR',
    latitude: '33.67218',
    longitude: '-91.54062',
    timeZoneId: 'America/Chicago',
  },
  '71671': {
    zip: '71671',
    city: 'Warren',
    state: 'AR',
    latitude: '33.609178',
    longitude: '-92.070158',
    timeZoneId: 'America/Chicago',
  },
  '71674': {
    zip: '71674',
    city: 'Watson',
    state: 'AR',
    latitude: '33.798097',
    longitude: '-91.302406',
    timeZoneId: 'America/Chicago',
  },
  '71675': {
    zip: '71675',
    city: 'Wilmar',
    state: 'AR',
    latitude: '33.62142',
    longitude: '-91.9278',
    timeZoneId: 'America/Chicago',
  },
  '71676': {
    zip: '71676',
    city: 'Wilmot',
    state: 'AR',
    latitude: '33.053808',
    longitude: '-91.571053',
    timeZoneId: 'America/Chicago',
  },
  '71677': {
    zip: '71677',
    city: 'Winchester',
    state: 'AR',
    latitude: '33.750509',
    longitude: '-91.549042',
    timeZoneId: 'America/Chicago',
  },
  '71678': {
    zip: '71678',
    city: 'Yorktown',
    state: 'AR',
    latitude: '34.032116',
    longitude: '-91.788646',
    timeZoneId: 'America/Chicago',
  },
  '71701': {
    zip: '71701',
    city: 'Camden',
    state: 'AR',
    latitude: '33.5814',
    longitude: '-92.81934',
    timeZoneId: 'America/Chicago',
  },
  '71711': {
    zip: '71711',
    city: 'Camden',
    state: 'AR',
    latitude: '33.581943',
    longitude: '-92.834036',
    timeZoneId: 'America/Chicago',
  },
  '71720': {
    zip: '71720',
    city: 'Bearden',
    state: 'AR',
    latitude: '33.73061',
    longitude: '-92.636797',
    timeZoneId: 'America/Chicago',
  },
  '71721': {
    zip: '71721',
    city: 'Beirne',
    state: 'AR',
    latitude: '33.890766',
    longitude: '-93.202851',
    timeZoneId: 'America/Chicago',
  },
  '71722': {
    zip: '71722',
    city: 'Bluff City',
    state: 'AR',
    latitude: '33.698184',
    longitude: '-93.198955',
    timeZoneId: 'America/Chicago',
  },
  '71724': {
    zip: '71724',
    city: 'Calion',
    state: 'AR',
    latitude: '33.323766',
    longitude: '-92.548566',
    timeZoneId: 'America/Chicago',
  },
  '71725': {
    zip: '71725',
    city: 'Carthage',
    state: 'AR',
    latitude: '34.045382',
    longitude: '-92.647954',
    timeZoneId: 'America/Chicago',
  },
  '71726': {
    zip: '71726',
    city: 'Chidester',
    state: 'AR',
    latitude: '33.707156',
    longitude: '-93.02885',
    timeZoneId: 'America/Chicago',
  },
  '71728': {
    zip: '71728',
    city: 'Curtis',
    state: 'AR',
    latitude: '33.997111',
    longitude: '-93.108204',
    timeZoneId: 'America/Chicago',
  },
  '71730': {
    zip: '71730',
    city: 'El Dorado',
    state: 'AR',
    latitude: '33.214492',
    longitude: '-92.664791',
    timeZoneId: 'America/Chicago',
  },
  '71731': {
    zip: '71731',
    city: 'El Dorado',
    state: 'AR',
    latitude: '33.209369',
    longitude: '-92.66693',
    timeZoneId: 'America/Chicago',
  },
  '71740': {
    zip: '71740',
    city: 'Emerson',
    state: 'AR',
    latitude: '33.093825',
    longitude: '-93.188033',
    timeZoneId: 'America/Chicago',
  },
  '71742': {
    zip: '71742',
    city: 'Fordyce',
    state: 'AR',
    latitude: '33.813506',
    longitude: '-92.420535',
    timeZoneId: 'America/Chicago',
  },
  '71743': {
    zip: '71743',
    city: 'Gurdon',
    state: 'AR',
    latitude: '33.917085',
    longitude: '-93.145662',
    timeZoneId: 'America/Chicago',
  },
  '71744': {
    zip: '71744',
    city: 'Hampton',
    state: 'AR',
    latitude: '33.513001',
    longitude: '-92.562851',
    timeZoneId: 'America/Chicago',
  },
  '71745': {
    zip: '71745',
    city: 'Harrell',
    state: 'AR',
    latitude: '33.502995',
    longitude: '-92.392347',
    timeZoneId: 'America/Chicago',
  },
  '71747': {
    zip: '71747',
    city: 'Huttig',
    state: 'AR',
    latitude: '33.057457',
    longitude: '-92.182408',
    timeZoneId: 'America/Chicago',
  },
  '71748': {
    zip: '71748',
    city: 'Ivan',
    state: 'AR',
    latitude: '33.908253',
    longitude: '-92.443094',
    timeZoneId: 'America/Chicago',
  },
  '71749': {
    zip: '71749',
    city: 'Junction City',
    state: 'AR',
    latitude: '33.016895',
    longitude: '-92.725033',
    timeZoneId: 'America/Chicago',
  },
  '71750': {
    zip: '71750',
    city: 'Lawson',
    state: 'AR',
    latitude: '33.195209',
    longitude: '-92.481515',
    timeZoneId: 'America/Chicago',
  },
  '71751': {
    zip: '71751',
    city: 'Louann',
    state: 'AR',
    latitude: '33.409352',
    longitude: '-92.779759',
    timeZoneId: 'America/Chicago',
  },
  '71752': {
    zip: '71752',
    city: 'Mc Neil',
    state: 'AR',
    latitude: '33.384319',
    longitude: '-93.19479',
    timeZoneId: 'America/Chicago',
  },
  '71753': {
    zip: '71753',
    city: 'Magnolia',
    state: 'AR',
    latitude: '33.264345',
    longitude: '-93.233796',
    timeZoneId: 'America/Chicago',
  },
  '71754': {
    zip: '71754',
    city: 'Magnolia',
    state: 'AR',
    latitude: '33.267422',
    longitude: '-93.23923',
    timeZoneId: 'America/Chicago',
  },
  '71758': {
    zip: '71758',
    city: 'Mount Holly',
    state: 'AR',
    latitude: '33.314945',
    longitude: '-92.942896',
    timeZoneId: 'America/Chicago',
  },
  '71759': {
    zip: '71759',
    city: 'Norphlet',
    state: 'AR',
    latitude: '33.324963',
    longitude: '-92.666277',
    timeZoneId: 'America/Chicago',
  },
  '71762': {
    zip: '71762',
    city: 'Smackover',
    state: 'AR',
    latitude: '33.36565',
    longitude: '-92.728056',
    timeZoneId: 'America/Chicago',
  },
  '71763': {
    zip: '71763',
    city: 'Sparkman',
    state: 'AR',
    latitude: '34.021111',
    longitude: '-92.792778',
    timeZoneId: 'America/Chicago',
  },
  '71764': {
    zip: '71764',
    city: 'Stephens',
    state: 'AR',
    latitude: '33.418904',
    longitude: '-93.030723',
    timeZoneId: 'America/Chicago',
  },
  '71765': {
    zip: '71765',
    city: 'Strong',
    state: 'AR',
    latitude: '33.209097',
    longitude: '-92.443857',
    timeZoneId: 'America/Chicago',
  },
  '71766': {
    zip: '71766',
    city: 'Thornton',
    state: 'AR',
    latitude: '33.753543',
    longitude: '-92.456546',
    timeZoneId: 'America/Chicago',
  },
  '71770': {
    zip: '71770',
    city: 'Waldo',
    state: 'AR',
    latitude: '33.352964',
    longitude: '-93.291433',
    timeZoneId: 'America/Chicago',
  },
  '71772': {
    zip: '71772',
    city: 'Whelen Springs',
    state: 'AR',
    latitude: '33.831489',
    longitude: '-93.126424',
    timeZoneId: 'America/Chicago',
  },
  '71801': {
    zip: '71801',
    city: 'Hope',
    state: 'AR',
    latitude: '33.661513',
    longitude: '-93.58943',
    timeZoneId: 'America/Chicago',
  },
  '71802': {
    zip: '71802',
    city: 'Hope',
    state: 'AR',
    latitude: '33.666096',
    longitude: '-93.587333',
    timeZoneId: 'America/Chicago',
  },
  '71820': {
    zip: '71820',
    city: 'Alleene',
    state: 'AR',
    latitude: '33.76695',
    longitude: '-94.262426',
    timeZoneId: 'America/Chicago',
  },
  '71822': {
    zip: '71822',
    city: 'Ashdown',
    state: 'AR',
    latitude: '33.674109',
    longitude: '-94.125689',
    timeZoneId: 'America/Chicago',
  },
  '71823': {
    zip: '71823',
    city: 'Ben Lomond',
    state: 'AR',
    latitude: '33.83518',
    longitude: '-94.11906',
    timeZoneId: 'America/Chicago',
  },
  '71825': {
    zip: '71825',
    city: 'Blevins',
    state: 'AR',
    latitude: '33.888971',
    longitude: '-93.519746',
    timeZoneId: 'America/Chicago',
  },
  '71826': {
    zip: '71826',
    city: 'Bradley',
    state: 'AR',
    latitude: '33.112881',
    longitude: '-93.649259',
    timeZoneId: 'America/Chicago',
  },
  '71827': {
    zip: '71827',
    city: 'Buckner',
    state: 'AR',
    latitude: '33.367353',
    longitude: '-93.446445',
    timeZoneId: 'America/Chicago',
  },
  '71828': {
    zip: '71828',
    city: 'Cale',
    state: 'AR',
    latitude: '33.625138',
    longitude: '-93.260649',
    timeZoneId: 'America/Chicago',
  },
  '71831': {
    zip: '71831',
    city: 'Columbus',
    state: 'AR',
    latitude: '33.751594',
    longitude: '-93.821408',
    timeZoneId: 'America/Chicago',
  },
  '71832': {
    zip: '71832',
    city: 'De Queen',
    state: 'AR',
    latitude: '34.044054',
    longitude: '-94.343484',
    timeZoneId: 'America/Chicago',
  },
  '71833': {
    zip: '71833',
    city: 'Dierks',
    state: 'AR',
    latitude: '33.993503',
    longitude: '-93.841005',
    timeZoneId: 'America/Chicago',
  },
  '71834': {
    zip: '71834',
    city: 'Doddridge',
    state: 'AR',
    latitude: '33.118192',
    longitude: '-93.958398',
    timeZoneId: 'America/Chicago',
  },
  '71835': {
    zip: '71835',
    city: 'Emmet',
    state: 'AR',
    latitude: '33.66952',
    longitude: '-93.40107',
    timeZoneId: 'America/Chicago',
  },
  '71836': {
    zip: '71836',
    city: 'Foreman',
    state: 'AR',
    latitude: '33.697375',
    longitude: '-94.40226',
    timeZoneId: 'America/Chicago',
  },
  '71837': {
    zip: '71837',
    city: 'Fouke',
    state: 'AR',
    latitude: '33.286971',
    longitude: '-93.891637',
    timeZoneId: 'America/Chicago',
  },
  '71838': {
    zip: '71838',
    city: 'Fulton',
    state: 'AR',
    latitude: '33.629501',
    longitude: '-93.816318',
    timeZoneId: 'America/Chicago',
  },
  '71839': {
    zip: '71839',
    city: 'Garland City',
    state: 'AR',
    latitude: '33.317011',
    longitude: '-93.760241',
    timeZoneId: 'America/Chicago',
  },
  '71840': {
    zip: '71840',
    city: 'Genoa',
    state: 'AR',
    latitude: '33.383776',
    longitude: '-93.908965',
    timeZoneId: 'America/Chicago',
  },
  '71841': {
    zip: '71841',
    city: 'Gillham',
    state: 'AR',
    latitude: '34.149902',
    longitude: '-94.31418',
    timeZoneId: 'America/Chicago',
  },
  '71842': {
    zip: '71842',
    city: 'Horatio',
    state: 'AR',
    latitude: '33.904506',
    longitude: '-94.265675',
    timeZoneId: 'America/Chicago',
  },
  '71845': {
    zip: '71845',
    city: 'Lewisville',
    state: 'AR',
    latitude: '33.360581',
    longitude: '-93.579901',
    timeZoneId: 'America/Chicago',
  },
  '71846': {
    zip: '71846',
    city: 'Lockesburg',
    state: 'AR',
    latitude: '33.917861',
    longitude: '-94.115216',
    timeZoneId: 'America/Chicago',
  },
  '71847': {
    zip: '71847',
    city: 'Mc Caskill',
    state: 'AR',
    latitude: '33.932758',
    longitude: '-93.639952',
    timeZoneId: 'America/Chicago',
  },
  '71851': {
    zip: '71851',
    city: 'Mineral Springs',
    state: 'AR',
    latitude: '33.871355',
    longitude: '-93.917645',
    timeZoneId: 'America/Chicago',
  },
  '71852': {
    zip: '71852',
    city: 'Nashville',
    state: 'AR',
    latitude: '33.945803',
    longitude: '-93.850288',
    timeZoneId: 'America/Chicago',
  },
  '71853': {
    zip: '71853',
    city: 'Ogden',
    state: 'AR',
    latitude: '33.59707',
    longitude: '-93.973796',
    timeZoneId: 'America/Chicago',
  },
  '71854': {
    zip: '71854',
    city: 'Texarkana',
    state: 'AR',
    latitude: '33.436576',
    longitude: '-94.019848',
    timeZoneId: 'America/Chicago',
  },
  '71855': {
    zip: '71855',
    city: 'Ozan',
    state: 'AR',
    latitude: '33.878935',
    longitude: '-93.742793',
    timeZoneId: 'America/Chicago',
  },
  '71857': {
    zip: '71857',
    city: 'Prescott',
    state: 'AR',
    latitude: '33.802675',
    longitude: '-93.377033',
    timeZoneId: 'America/Chicago',
  },
  '71858': {
    zip: '71858',
    city: 'Rosston',
    state: 'AR',
    latitude: '33.569569',
    longitude: '-93.289236',
    timeZoneId: 'America/Chicago',
  },
  '71859': {
    zip: '71859',
    city: 'Saratoga',
    state: 'AR',
    latitude: '33.774332',
    longitude: '-93.884417',
    timeZoneId: 'America/Chicago',
  },
  '71860': {
    zip: '71860',
    city: 'Stamps',
    state: 'AR',
    latitude: '33.361637',
    longitude: '-93.503365',
    timeZoneId: 'America/Chicago',
  },
  '71861': {
    zip: '71861',
    city: 'Taylor',
    state: 'AR',
    latitude: '33.111405',
    longitude: '-93.447276',
    timeZoneId: 'America/Chicago',
  },
  '71862': {
    zip: '71862',
    city: 'Washington',
    state: 'AR',
    latitude: '33.750451',
    longitude: '-93.668049',
    timeZoneId: 'America/Chicago',
  },
  '71864': {
    zip: '71864',
    city: 'Willisville',
    state: 'AR',
    latitude: '33.489851',
    longitude: '-93.301468',
    timeZoneId: 'America/Chicago',
  },
  '71865': {
    zip: '71865',
    city: 'Wilton',
    state: 'AR',
    latitude: '33.739315',
    longitude: '-94.111223',
    timeZoneId: 'America/Chicago',
  },
  '71866': {
    zip: '71866',
    city: 'Winthrop',
    state: 'AR',
    latitude: '33.869003',
    longitude: '-94.403839',
    timeZoneId: 'America/Chicago',
  },
  '71901': {
    zip: '71901',
    city: 'Hot Springs National Park',
    state: 'AR',
    latitude: '34.496486',
    longitude: '-93.032712',
    timeZoneId: 'America/Chicago',
  },
  '71902': {
    zip: '71902',
    city: 'Hot Springs National Park',
    state: 'AR',
    latitude: '34.50235',
    longitude: '-93.057544',
    timeZoneId: 'America/Chicago',
  },
  '71903': {
    zip: '71903',
    city: 'Hot Springs National Park',
    state: 'AR',
    latitude: '34.525891',
    longitude: '-92.99636',
    timeZoneId: 'America/Chicago',
  },
  '71909': {
    zip: '71909',
    city: 'Hot Springs Village',
    state: 'AR',
    latitude: '34.61058',
    longitude: '-93.01476',
    timeZoneId: 'America/Chicago',
  },
  '71910': {
    zip: '71910',
    city: 'Hot Springs Village',
    state: 'AR',
    latitude: '34.668247',
    longitude: '-93.001118',
    timeZoneId: 'America/Chicago',
  },
  '71913': {
    zip: '71913',
    city: 'Hot Springs National Park',
    state: 'AR',
    latitude: '34.42469',
    longitude: '-93.09629',
    timeZoneId: 'America/Chicago',
  },
  '71914': {
    zip: '71914',
    city: 'Hot Springs National Park',
    state: 'AR',
    latitude: '34.529159',
    longitude: '-93.051519',
    timeZoneId: 'America/Chicago',
  },
  '71920': {
    zip: '71920',
    city: 'Alpine',
    state: 'AR',
    latitude: '34.264722',
    longitude: '-93.460833',
    timeZoneId: 'America/Chicago',
  },
  '71921': {
    zip: '71921',
    city: 'Amity',
    state: 'AR',
    latitude: '34.2441',
    longitude: '-93.37992',
    timeZoneId: 'America/Chicago',
  },
  '71922': {
    zip: '71922',
    city: 'Antoine',
    state: 'AR',
    latitude: '34.027118',
    longitude: '-93.45016',
    timeZoneId: 'America/Chicago',
  },
  '71923': {
    zip: '71923',
    city: 'Arkadelphia',
    state: 'AR',
    latitude: '34.085898',
    longitude: '-93.045684',
    timeZoneId: 'America/Chicago',
  },
  '71929': {
    zip: '71929',
    city: 'Bismarck',
    state: 'AR',
    latitude: '34.336448',
    longitude: '-93.193258',
    timeZoneId: 'America/Chicago',
  },
  '71932': {
    zip: '71932',
    city: 'Board Camp',
    state: 'AR',
    latitude: '34.552927',
    longitude: '-94.204398',
    timeZoneId: 'America/Chicago',
  },
  '71933': {
    zip: '71933',
    city: 'Bonnerdale',
    state: 'AR',
    latitude: '34.347207',
    longitude: '-93.316503',
    timeZoneId: 'America/Chicago',
  },
  '71935': {
    zip: '71935',
    city: 'Caddo Gap',
    state: 'AR',
    latitude: '34.358611',
    longitude: '-93.688611',
    timeZoneId: 'America/Chicago',
  },
  '71937': {
    zip: '71937',
    city: 'Cove',
    state: 'AR',
    latitude: '34.429351',
    longitude: '-94.417896',
    timeZoneId: 'America/Chicago',
  },
  '71940': {
    zip: '71940',
    city: 'Delight',
    state: 'AR',
    latitude: '34.057278',
    longitude: '-93.530098',
    timeZoneId: 'America/Chicago',
  },
  '71941': {
    zip: '71941',
    city: 'Donaldson',
    state: 'AR',
    latitude: '34.199333',
    longitude: '-92.931422',
    timeZoneId: 'America/Chicago',
  },
  '71942': {
    zip: '71942',
    city: 'Friendship',
    state: 'AR',
    latitude: '34.244521',
    longitude: '-92.982342',
    timeZoneId: 'America/Chicago',
  },
  '71943': {
    zip: '71943',
    city: 'Glenwood',
    state: 'AR',
    latitude: '34.324416',
    longitude: '-93.547401',
    timeZoneId: 'America/Chicago',
  },
  '71944': {
    zip: '71944',
    city: 'Grannis',
    state: 'AR',
    latitude: '34.230254',
    longitude: '-94.36213',
    timeZoneId: 'America/Chicago',
  },
  '71945': {
    zip: '71945',
    city: 'Hatfield',
    state: 'AR',
    latitude: '34.487192',
    longitude: '-94.35293',
    timeZoneId: 'America/Chicago',
  },
  '71949': {
    zip: '71949',
    city: 'Jessieville',
    state: 'AR',
    latitude: '34.710102',
    longitude: '-93.220625',
    timeZoneId: 'America/Chicago',
  },
  '71950': {
    zip: '71950',
    city: 'Kirby',
    state: 'AR',
    latitude: '34.220101',
    longitude: '-93.58301',
    timeZoneId: 'America/Chicago',
  },
  '71952': {
    zip: '71952',
    city: 'Langley',
    state: 'AR',
    latitude: '34.310476',
    longitude: '-93.846112',
    timeZoneId: 'America/Chicago',
  },
  '71953': {
    zip: '71953',
    city: 'Mena',
    state: 'AR',
    latitude: '34.60764',
    longitude: '-94.2135',
    timeZoneId: 'America/Chicago',
  },
  '71956': {
    zip: '71956',
    city: 'Mountain Pine',
    state: 'AR',
    latitude: '34.58496',
    longitude: '-93.15696',
    timeZoneId: 'America/Chicago',
  },
  '71957': {
    zip: '71957',
    city: 'Mount Ida',
    state: 'AR',
    latitude: '34.534979',
    longitude: '-93.504073',
    timeZoneId: 'America/Chicago',
  },
  '71958': {
    zip: '71958',
    city: 'Murfreesboro',
    state: 'AR',
    latitude: '34.06095',
    longitude: '-93.689939',
    timeZoneId: 'America/Chicago',
  },
  '71959': {
    zip: '71959',
    city: 'Newhope',
    state: 'AR',
    latitude: '34.219416',
    longitude: '-93.852143',
    timeZoneId: 'America/Chicago',
  },
  '71960': {
    zip: '71960',
    city: 'Norman',
    state: 'AR',
    latitude: '34.457226',
    longitude: '-93.657823',
    timeZoneId: 'America/Chicago',
  },
  '71961': {
    zip: '71961',
    city: 'Oden',
    state: 'AR',
    latitude: '34.604027',
    longitude: '-93.83182',
    timeZoneId: 'America/Chicago',
  },
  '71962': {
    zip: '71962',
    city: 'Okolona',
    state: 'AR',
    latitude: '34.080979',
    longitude: '-93.314393',
    timeZoneId: 'America/Chicago',
  },
  '71964': {
    zip: '71964',
    city: 'Pearcy',
    state: 'AR',
    latitude: '34.450158',
    longitude: '-93.197251',
    timeZoneId: 'America/Chicago',
  },
  '71965': {
    zip: '71965',
    city: 'Pencil Bluff',
    state: 'AR',
    latitude: '34.652412',
    longitude: '-93.747235',
    timeZoneId: 'America/Chicago',
  },
  '71966': {
    zip: '71966',
    city: 'Oden',
    state: 'AR',
    latitude: '34.585833',
    longitude: '-93.91',
    timeZoneId: 'America/Chicago',
  },
  '71968': {
    zip: '71968',
    city: 'Royal',
    state: 'AR',
    latitude: '34.523895',
    longitude: '-93.299582',
    timeZoneId: 'America/Chicago',
  },
  '71969': {
    zip: '71969',
    city: 'Sims',
    state: 'AR',
    latitude: '34.66805',
    longitude: '-93.666032',
    timeZoneId: 'America/Chicago',
  },
  '71970': {
    zip: '71970',
    city: 'Story',
    state: 'AR',
    latitude: '34.677222',
    longitude: '-93.560177',
    timeZoneId: 'America/Chicago',
  },
  '71971': {
    zip: '71971',
    city: 'Umpire',
    state: 'AR',
    latitude: '34.29048',
    longitude: '-94.09482',
    timeZoneId: 'America/Chicago',
  },
  '71972': {
    zip: '71972',
    city: 'Vandervoort',
    state: 'AR',
    latitude: '34.375951',
    longitude: '-94.357777',
    timeZoneId: 'America/Chicago',
  },
  '71973': {
    zip: '71973',
    city: 'Wickes',
    state: 'AR',
    latitude: '34.312438',
    longitude: '-94.346936',
    timeZoneId: 'America/Chicago',
  },
  '71998': {
    zip: '71998',
    city: 'Arkadelphia',
    state: 'AR',
    latitude: '34.129042',
    longitude: '-93.051992',
    timeZoneId: 'America/Chicago',
  },
  '71999': {
    zip: '71999',
    city: 'Arkadelphia',
    state: 'AR',
    latitude: '34.124529',
    longitude: '-93.072399',
    timeZoneId: 'America/Chicago',
  },
  '72001': {
    zip: '72001',
    city: 'Adona',
    state: 'AR',
    latitude: '35.047793',
    longitude: '-92.905101',
    timeZoneId: 'America/Chicago',
  },
  '72002': {
    zip: '72002',
    city: 'Alexander',
    state: 'AR',
    latitude: '34.632983',
    longitude: '-92.486426',
    timeZoneId: 'America/Chicago',
  },
  '72003': {
    zip: '72003',
    city: 'Almyra',
    state: 'AR',
    latitude: '34.297589',
    longitude: '-91.345171',
    timeZoneId: 'America/Chicago',
  },
  '72004': {
    zip: '72004',
    city: 'Altheimer',
    state: 'AR',
    latitude: '34.318415',
    longitude: '-91.842383',
    timeZoneId: 'America/Chicago',
  },
  '72005': {
    zip: '72005',
    city: 'Amagon',
    state: 'AR',
    latitude: '35.554257',
    longitude: '-91.080965',
    timeZoneId: 'America/Chicago',
  },
  '72006': {
    zip: '72006',
    city: 'Augusta',
    state: 'AR',
    latitude: '35.284958',
    longitude: '-91.362504',
    timeZoneId: 'America/Chicago',
  },
  '72007': {
    zip: '72007',
    city: 'Austin',
    state: 'AR',
    latitude: '34.997111',
    longitude: '-91.970805',
    timeZoneId: 'America/Chicago',
  },
  '72010': {
    zip: '72010',
    city: 'Bald Knob',
    state: 'AR',
    latitude: '35.305421',
    longitude: '-91.585816',
    timeZoneId: 'America/Chicago',
  },
  '72011': {
    zip: '72011',
    city: 'Bauxite',
    state: 'AR',
    latitude: '34.532579',
    longitude: '-92.47729',
    timeZoneId: 'America/Chicago',
  },
  '72012': {
    zip: '72012',
    city: 'Beebe',
    state: 'AR',
    latitude: '35.073551',
    longitude: '-91.896331',
    timeZoneId: 'America/Chicago',
  },
  '72013': {
    zip: '72013',
    city: 'Bee Branch',
    state: 'AR',
    latitude: '35.423104',
    longitude: '-92.413884',
    timeZoneId: 'America/Chicago',
  },
  '72014': {
    zip: '72014',
    city: 'Beedeville',
    state: 'AR',
    latitude: '35.427637',
    longitude: '-91.107215',
    timeZoneId: 'America/Chicago',
  },
  '72015': {
    zip: '72015',
    city: 'Benton',
    state: 'AR',
    latitude: '34.566563',
    longitude: '-92.583045',
    timeZoneId: 'America/Chicago',
  },
  '72016': {
    zip: '72016',
    city: 'Bigelow',
    state: 'AR',
    latitude: '34.881567',
    longitude: '-92.692504',
    timeZoneId: 'America/Chicago',
  },
  '72017': {
    zip: '72017',
    city: 'Biscoe',
    state: 'AR',
    latitude: '34.836954',
    longitude: '-91.538165',
    timeZoneId: 'America/Chicago',
  },
  '72018': {
    zip: '72018',
    city: 'Benton',
    state: 'AR',
    latitude: '34.562736',
    longitude: '-92.586155',
    timeZoneId: 'America/Chicago',
  },
  '72019': {
    zip: '72019',
    city: 'Benton',
    state: 'AR',
    latitude: '34.56369',
    longitude: '-92.586207',
    timeZoneId: 'America/Chicago',
  },
  '72020': {
    zip: '72020',
    city: 'Bradford',
    state: 'AR',
    latitude: '35.54995',
    longitude: '-91.438267',
    timeZoneId: 'America/Chicago',
  },
  '72021': {
    zip: '72021',
    city: 'Brinkley',
    state: 'AR',
    latitude: '34.891991',
    longitude: '-91.193555',
    timeZoneId: 'America/Chicago',
  },
  '72022': {
    zip: '72022',
    city: 'Bryant',
    state: 'AR',
    latitude: '34.598058',
    longitude: '-92.488227',
    timeZoneId: 'America/Chicago',
  },
  '72023': {
    zip: '72023',
    city: 'Cabot',
    state: 'AR',
    latitude: '34.964998',
    longitude: '-92.015595',
    timeZoneId: 'America/Chicago',
  },
  '72024': {
    zip: '72024',
    city: 'Carlisle',
    state: 'AR',
    latitude: '34.788454',
    longitude: '-91.746133',
    timeZoneId: 'America/Chicago',
  },
  '72025': {
    zip: '72025',
    city: 'Casa',
    state: 'AR',
    latitude: '35.030179',
    longitude: '-93.036863',
    timeZoneId: 'America/Chicago',
  },
  '72026': {
    zip: '72026',
    city: 'Casscoe',
    state: 'AR',
    latitude: '34.455148',
    longitude: '-91.327569',
    timeZoneId: 'America/Chicago',
  },
  '72027': {
    zip: '72027',
    city: 'Center Ridge',
    state: 'AR',
    latitude: '35.401579',
    longitude: '-92.560518',
    timeZoneId: 'America/Chicago',
  },
  '72028': {
    zip: '72028',
    city: 'Choctaw',
    state: 'AR',
    latitude: '35.521105',
    longitude: '-92.41774',
    timeZoneId: 'America/Chicago',
  },
  '72029': {
    zip: '72029',
    city: 'Clarendon',
    state: 'AR',
    latitude: '34.695459',
    longitude: '-91.30756',
    timeZoneId: 'America/Chicago',
  },
  '72030': {
    zip: '72030',
    city: 'Cleveland',
    state: 'AR',
    latitude: '35.366302',
    longitude: '-92.705971',
    timeZoneId: 'America/Chicago',
  },
  '72031': {
    zip: '72031',
    city: 'Clinton',
    state: 'AR',
    latitude: '35.6136',
    longitude: '-92.50578',
    timeZoneId: 'America/Chicago',
  },
  '72032': {
    zip: '72032',
    city: 'Conway',
    state: 'AR',
    latitude: '35.0673',
    longitude: '-92.3808',
    timeZoneId: 'America/Chicago',
  },
  '72033': {
    zip: '72033',
    city: 'Conway',
    state: 'AR',
    latitude: '35.082476',
    longitude: '-92.37509',
    timeZoneId: 'America/Chicago',
  },
  '72034': {
    zip: '72034',
    city: 'Conway',
    state: 'AR',
    latitude: '35.089669',
    longitude: '-92.442567',
    timeZoneId: 'America/Chicago',
  },
  '72035': {
    zip: '72035',
    city: 'Conway',
    state: 'AR',
    latitude: '35.073922',
    longitude: '-92.457553',
    timeZoneId: 'America/Chicago',
  },
  '72036': {
    zip: '72036',
    city: 'Cotton Plant',
    state: 'AR',
    latitude: '35.003283',
    longitude: '-91.250233',
    timeZoneId: 'America/Chicago',
  },
  '72037': {
    zip: '72037',
    city: 'Coy',
    state: 'AR',
    latitude: '34.537779',
    longitude: '-91.873895',
    timeZoneId: 'America/Chicago',
  },
  '72038': {
    zip: '72038',
    city: 'Crocketts Bluff',
    state: 'AR',
    latitude: '34.421824',
    longitude: '-91.227791',
    timeZoneId: 'America/Chicago',
  },
  '72039': {
    zip: '72039',
    city: 'Damascus',
    state: 'AR',
    latitude: '35.298334',
    longitude: '-92.439056',
    timeZoneId: 'America/Chicago',
  },
  '72040': {
    zip: '72040',
    city: 'Des Arc',
    state: 'AR',
    latitude: '34.958367',
    longitude: '-91.509222',
    timeZoneId: 'America/Chicago',
  },
  '72041': {
    zip: '72041',
    city: 'De Valls Bluff',
    state: 'AR',
    latitude: '34.715356',
    longitude: '-91.542833',
    timeZoneId: 'America/Chicago',
  },
  '72042': {
    zip: '72042',
    city: 'De Witt',
    state: 'AR',
    latitude: '34.292792',
    longitude: '-91.337922',
    timeZoneId: 'America/Chicago',
  },
  '72043': {
    zip: '72043',
    city: 'Diaz',
    state: 'AR',
    latitude: '35.637842',
    longitude: '-91.257878',
    timeZoneId: 'America/Chicago',
  },
  '72044': {
    zip: '72044',
    city: 'Edgemont',
    state: 'AR',
    latitude: '35.645789',
    longitude: '-92.190482',
    timeZoneId: 'America/Chicago',
  },
  '72045': {
    zip: '72045',
    city: 'El Paso',
    state: 'AR',
    latitude: '35.106575',
    longitude: '-92.087418',
    timeZoneId: 'America/Chicago',
  },
  '72046': {
    zip: '72046',
    city: 'England',
    state: 'AR',
    latitude: '34.57434',
    longitude: '-91.97706',
    timeZoneId: 'America/Chicago',
  },
  '72047': {
    zip: '72047',
    city: 'Enola',
    state: 'AR',
    latitude: '35.217403',
    longitude: '-92.213012',
    timeZoneId: 'America/Chicago',
  },
  '72048': {
    zip: '72048',
    city: 'Ethel',
    state: 'AR',
    latitude: '34.193665',
    longitude: '-91.132556',
    timeZoneId: 'America/Chicago',
  },
  '72051': {
    zip: '72051',
    city: 'Fox',
    state: 'AR',
    latitude: '35.762178',
    longitude: '-92.304721',
    timeZoneId: 'America/Chicago',
  },
  '72052': {
    zip: '72052',
    city: 'Garner',
    state: 'AR',
    latitude: '35.121614',
    longitude: '-91.729578',
    timeZoneId: 'America/Chicago',
  },
  '72053': {
    zip: '72053',
    city: 'College Station',
    state: 'AR',
    latitude: '34.71166',
    longitude: '-92.22229',
    timeZoneId: 'America/Chicago',
  },
  '72055': {
    zip: '72055',
    city: 'Gillett',
    state: 'AR',
    latitude: '34.120469',
    longitude: '-91.378469',
    timeZoneId: 'America/Chicago',
  },
  '72057': {
    zip: '72057',
    city: 'Grapevine',
    state: 'AR',
    latitude: '34.118272',
    longitude: '-92.308042',
    timeZoneId: 'America/Chicago',
  },
  '72058': {
    zip: '72058',
    city: 'Greenbrier',
    state: 'AR',
    latitude: '35.21928',
    longitude: '-92.3241',
    timeZoneId: 'America/Chicago',
  },
  '72059': {
    zip: '72059',
    city: 'Gregory',
    state: 'AR',
    latitude: '35.155922',
    longitude: '-91.346107',
    timeZoneId: 'America/Chicago',
  },
  '72060': {
    zip: '72060',
    city: 'Griffithville',
    state: 'AR',
    latitude: '35.121245',
    longitude: '-91.647567',
    timeZoneId: 'America/Chicago',
  },
  '72061': {
    zip: '72061',
    city: 'Guy',
    state: 'AR',
    latitude: '35.320292',
    longitude: '-92.327885',
    timeZoneId: 'America/Chicago',
  },
  '72063': {
    zip: '72063',
    city: 'Hattieville',
    state: 'AR',
    latitude: '35.28978',
    longitude: '-92.784',
    timeZoneId: 'America/Chicago',
  },
  '72064': {
    zip: '72064',
    city: 'Hazen',
    state: 'AR',
    latitude: '34.797715',
    longitude: '-91.570184',
    timeZoneId: 'America/Chicago',
  },
  '72065': {
    zip: '72065',
    city: 'Hensley',
    state: 'AR',
    latitude: '34.52952',
    longitude: '-92.22246',
    timeZoneId: 'America/Chicago',
  },
  '72066': {
    zip: '72066',
    city: 'Hickory Plains',
    state: 'AR',
    latitude: '34.976537',
    longitude: '-91.747656',
    timeZoneId: 'America/Chicago',
  },
  '72067': {
    zip: '72067',
    city: 'Higden',
    state: 'AR',
    latitude: '35.57004',
    longitude: '-92.17464',
    timeZoneId: 'America/Chicago',
  },
  '72068': {
    zip: '72068',
    city: 'Higginson',
    state: 'AR',
    latitude: '35.181008',
    longitude: '-91.715439',
    timeZoneId: 'America/Chicago',
  },
  '72069': {
    zip: '72069',
    city: 'Holly Grove',
    state: 'AR',
    latitude: '34.602963',
    longitude: '-91.165029',
    timeZoneId: 'America/Chicago',
  },
  '72070': {
    zip: '72070',
    city: 'Houston',
    state: 'AR',
    latitude: '35.045804',
    longitude: '-92.690848',
    timeZoneId: 'America/Chicago',
  },
  '72072': {
    zip: '72072',
    city: 'Humnoke',
    state: 'AR',
    latitude: '34.542157',
    longitude: '-91.732209',
    timeZoneId: 'America/Chicago',
  },
  '72073': {
    zip: '72073',
    city: 'Humphrey',
    state: 'AR',
    latitude: '34.380437',
    longitude: '-91.660081',
    timeZoneId: 'America/Chicago',
  },
  '72074': {
    zip: '72074',
    city: 'Hunter',
    state: 'AR',
    latitude: '35.050948',
    longitude: '-91.126664',
    timeZoneId: 'America/Chicago',
  },
  '72075': {
    zip: '72075',
    city: 'Jacksonport',
    state: 'AR',
    latitude: '35.637828',
    longitude: '-91.190996',
    timeZoneId: 'America/Chicago',
  },
  '72076': {
    zip: '72076',
    city: 'Jacksonville',
    state: 'AR',
    latitude: '34.874693',
    longitude: '-92.122352',
    timeZoneId: 'America/Chicago',
  },
  '72078': {
    zip: '72078',
    city: 'Jacksonville',
    state: 'AR',
    latitude: '34.86416',
    longitude: '-92.122487',
    timeZoneId: 'America/Chicago',
  },
  '72079': {
    zip: '72079',
    city: 'Jefferson',
    state: 'AR',
    latitude: '34.385634',
    longitude: '-92.168401',
    timeZoneId: 'America/Chicago',
  },
  '72080': {
    zip: '72080',
    city: 'Jerusalem',
    state: 'AR',
    latitude: '35.395533',
    longitude: '-92.806007',
    timeZoneId: 'America/Chicago',
  },
  '72081': {
    zip: '72081',
    city: 'Judsonia',
    state: 'AR',
    latitude: '35.42928',
    longitude: '-91.69092',
    timeZoneId: 'America/Chicago',
  },
  '72082': {
    zip: '72082',
    city: 'Kensett',
    state: 'AR',
    latitude: '35.232917',
    longitude: '-91.670949',
    timeZoneId: 'America/Chicago',
  },
  '72083': {
    zip: '72083',
    city: 'Keo',
    state: 'AR',
    latitude: '34.607506',
    longitude: '-92.002984',
    timeZoneId: 'America/Chicago',
  },
  '72084': {
    zip: '72084',
    city: 'Leola',
    state: 'AR',
    latitude: '34.219063',
    longitude: '-92.603497',
    timeZoneId: 'America/Chicago',
  },
  '72085': {
    zip: '72085',
    city: 'Letona',
    state: 'AR',
    latitude: '35.362925',
    longitude: '-91.82971',
    timeZoneId: 'America/Chicago',
  },
  '72086': {
    zip: '72086',
    city: 'Lonoke',
    state: 'AR',
    latitude: '34.78812',
    longitude: '-91.94886',
    timeZoneId: 'America/Chicago',
  },
  '72087': {
    zip: '72087',
    city: 'Lonsdale',
    state: 'AR',
    latitude: '34.566522',
    longitude: '-92.816237',
    timeZoneId: 'America/Chicago',
  },
  '72088': {
    zip: '72088',
    city: 'Fairfield Bay',
    state: 'AR',
    latitude: '35.5941',
    longitude: '-92.27772',
    timeZoneId: 'America/Chicago',
  },
  '72089': {
    zip: '72089',
    city: 'Bryant',
    state: 'AR',
    latitude: '34.596252',
    longitude: '-92.486894',
    timeZoneId: 'America/Chicago',
  },
  '72099': {
    zip: '72099',
    city: 'Little Rock Air Force Base',
    state: 'AR',
    latitude: '34.891861',
    longitude: '-92.158312',
    timeZoneId: 'America/Chicago',
  },
  '72101': {
    zip: '72101',
    city: 'Mc Crory',
    state: 'AR',
    latitude: '35.262221',
    longitude: '-91.203001',
    timeZoneId: 'America/Chicago',
  },
  '72102': {
    zip: '72102',
    city: 'Mc Rae',
    state: 'AR',
    latitude: '35.114602',
    longitude: '-91.823524',
    timeZoneId: 'America/Chicago',
  },
  '72103': {
    zip: '72103',
    city: 'Mabelvale',
    state: 'AR',
    latitude: '34.590766',
    longitude: '-92.37946',
    timeZoneId: 'America/Chicago',
  },
  '72104': {
    zip: '72104',
    city: 'Malvern',
    state: 'AR',
    latitude: '34.40154',
    longitude: '-92.83632',
    timeZoneId: 'America/Chicago',
  },
  '72106': {
    zip: '72106',
    city: 'Mayflower',
    state: 'AR',
    latitude: '34.969289',
    longitude: '-92.431561',
    timeZoneId: 'America/Chicago',
  },
  '72107': {
    zip: '72107',
    city: 'Menifee',
    state: 'AR',
    latitude: '35.146522',
    longitude: '-92.55359',
    timeZoneId: 'America/Chicago',
  },
  '72108': {
    zip: '72108',
    city: 'Monroe',
    state: 'AR',
    latitude: '34.735002',
    longitude: '-91.099721',
    timeZoneId: 'America/Chicago',
  },
  '72110': {
    zip: '72110',
    city: 'Morrilton',
    state: 'AR',
    latitude: '35.23866',
    longitude: '-92.67108',
    timeZoneId: 'America/Chicago',
  },
  '72111': {
    zip: '72111',
    city: 'Mount Vernon',
    state: 'AR',
    latitude: '35.185421',
    longitude: '-92.073842',
    timeZoneId: 'America/Chicago',
  },
  '72112': {
    zip: '72112',
    city: 'Newport',
    state: 'AR',
    latitude: '35.55396',
    longitude: '-91.1925',
    timeZoneId: 'America/Chicago',
  },
  '72113': {
    zip: '72113',
    city: 'Maumelle',
    state: 'AR',
    latitude: '34.852398',
    longitude: '-92.404691',
    timeZoneId: 'America/Chicago',
  },
  '72114': {
    zip: '72114',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.767166',
    longitude: '-92.254875',
    timeZoneId: 'America/Chicago',
  },
  '72115': {
    zip: '72115',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.766295',
    longitude: '-92.259072',
    timeZoneId: 'America/Chicago',
  },
  '72116': {
    zip: '72116',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.802035',
    longitude: '-92.235768',
    timeZoneId: 'America/Chicago',
  },
  '72117': {
    zip: '72117',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.78092',
    longitude: '-92.15208',
    timeZoneId: 'America/Chicago',
  },
  '72118': {
    zip: '72118',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.802369',
    longitude: '-92.31696',
    timeZoneId: 'America/Chicago',
  },
  '72119': {
    zip: '72119',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.763985',
    longitude: '-92.258586',
    timeZoneId: 'America/Chicago',
  },
  '72120': {
    zip: '72120',
    city: 'Sherwood',
    state: 'AR',
    latitude: '34.892804',
    longitude: '-92.231859',
    timeZoneId: 'America/Chicago',
  },
  '72121': {
    zip: '72121',
    city: 'Pangburn',
    state: 'AR',
    latitude: '35.423264',
    longitude: '-91.839892',
    timeZoneId: 'America/Chicago',
  },
  '72122': {
    zip: '72122',
    city: 'Paron',
    state: 'AR',
    latitude: '34.80463',
    longitude: '-92.850568',
    timeZoneId: 'America/Chicago',
  },
  '72123': {
    zip: '72123',
    city: 'Patterson',
    state: 'AR',
    latitude: '35.255496',
    longitude: '-91.235121',
    timeZoneId: 'America/Chicago',
  },
  '72124': {
    zip: '72124',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.811333',
    longitude: '-92.234881',
    timeZoneId: 'America/Chicago',
  },
  '72125': {
    zip: '72125',
    city: 'Perry',
    state: 'AR',
    latitude: '35.037223',
    longitude: '-92.794614',
    timeZoneId: 'America/Chicago',
  },
  '72126': {
    zip: '72126',
    city: 'Perryville',
    state: 'AR',
    latitude: '34.97292',
    longitude: '-92.87172',
    timeZoneId: 'America/Chicago',
  },
  '72127': {
    zip: '72127',
    city: 'Plumerville',
    state: 'AR',
    latitude: '35.160737',
    longitude: '-92.634377',
    timeZoneId: 'America/Chicago',
  },
  '72128': {
    zip: '72128',
    city: 'Poyen',
    state: 'AR',
    latitude: '34.359293',
    longitude: '-92.607618',
    timeZoneId: 'America/Chicago',
  },
  '72129': {
    zip: '72129',
    city: 'Prattsville',
    state: 'AR',
    latitude: '34.294448',
    longitude: '-92.504614',
    timeZoneId: 'America/Chicago',
  },
  '72130': {
    zip: '72130',
    city: 'Prim',
    state: 'AR',
    latitude: '35.689015',
    longitude: '-92.144913',
    timeZoneId: 'America/Chicago',
  },
  '72131': {
    zip: '72131',
    city: 'Quitman',
    state: 'AR',
    latitude: '35.378007',
    longitude: '-92.210229',
    timeZoneId: 'America/Chicago',
  },
  '72132': {
    zip: '72132',
    city: 'Redfield',
    state: 'AR',
    latitude: '34.460182',
    longitude: '-92.17719',
    timeZoneId: 'America/Chicago',
  },
  '72133': {
    zip: '72133',
    city: 'Reydell',
    state: 'AR',
    latitude: '34.175799',
    longitude: '-91.627993',
    timeZoneId: 'America/Chicago',
  },
  '72134': {
    zip: '72134',
    city: 'Roe',
    state: 'AR',
    latitude: '34.603333',
    longitude: '-91.341667',
    timeZoneId: 'America/Chicago',
  },
  '72135': {
    zip: '72135',
    city: 'Roland',
    state: 'AR',
    latitude: '34.869776',
    longitude: '-92.522556',
    timeZoneId: 'America/Chicago',
  },
  '72136': {
    zip: '72136',
    city: 'Romance',
    state: 'AR',
    latitude: '35.216583',
    longitude: '-92.069911',
    timeZoneId: 'America/Chicago',
  },
  '72137': {
    zip: '72137',
    city: 'Rose Bud',
    state: 'AR',
    latitude: '35.312759',
    longitude: '-91.980405',
    timeZoneId: 'America/Chicago',
  },
  '72139': {
    zip: '72139',
    city: 'Russell',
    state: 'AR',
    latitude: '35.361358',
    longitude: '-91.509058',
    timeZoneId: 'America/Chicago',
  },
  '72140': {
    zip: '72140',
    city: 'Saint Charles',
    state: 'AR',
    latitude: '34.33774',
    longitude: '-91.181444',
    timeZoneId: 'America/Chicago',
  },
  '72141': {
    zip: '72141',
    city: 'Scotland',
    state: 'AR',
    latitude: '35.522817',
    longitude: '-92.668783',
    timeZoneId: 'America/Chicago',
  },
  '72142': {
    zip: '72142',
    city: 'Scott',
    state: 'AR',
    latitude: '34.776533',
    longitude: '-92.06399',
    timeZoneId: 'America/Chicago',
  },
  '72143': {
    zip: '72143',
    city: 'Searcy',
    state: 'AR',
    latitude: '35.25228',
    longitude: '-91.84926',
    timeZoneId: 'America/Chicago',
  },
  '72145': {
    zip: '72145',
    city: 'Searcy',
    state: 'AR',
    latitude: '35.252087',
    longitude: '-91.742855',
    timeZoneId: 'America/Chicago',
  },
  '72149': {
    zip: '72149',
    city: 'Searcy',
    state: 'AR',
    latitude: '35.247481',
    longitude: '-91.726679',
    timeZoneId: 'America/Chicago',
  },
  '72150': {
    zip: '72150',
    city: 'Sheridan',
    state: 'AR',
    latitude: '34.305789',
    longitude: '-92.40026',
    timeZoneId: 'America/Chicago',
  },
  '72152': {
    zip: '72152',
    city: 'Sherrill',
    state: 'AR',
    latitude: '34.35636',
    longitude: '-91.98708',
    timeZoneId: 'America/Chicago',
  },
  '72153': {
    zip: '72153',
    city: 'Shirley',
    state: 'AR',
    latitude: '35.5794',
    longitude: '-92.29158',
    timeZoneId: 'America/Chicago',
  },
  '72156': {
    zip: '72156',
    city: 'Solgohachia',
    state: 'AR',
    latitude: '35.275653',
    longitude: '-92.675788',
    timeZoneId: 'America/Chicago',
  },
  '72157': {
    zip: '72157',
    city: 'Springfield',
    state: 'AR',
    latitude: '35.28012',
    longitude: '-92.53692',
    timeZoneId: 'America/Chicago',
  },
  '72158': {
    zip: '72158',
    city: 'Benton',
    state: 'AR',
    latitude: '34.569207',
    longitude: '-92.578913',
    timeZoneId: 'America/Chicago',
  },
  '72160': {
    zip: '72160',
    city: 'Stuttgart',
    state: 'AR',
    latitude: '34.22361',
    longitude: '-91.52111',
    timeZoneId: 'America/Chicago',
  },
  '72164': {
    zip: '72164',
    city: 'Sweet Home',
    state: 'AR',
    latitude: '34.675748',
    longitude: '-92.237351',
    timeZoneId: 'America/Chicago',
  },
  '72165': {
    zip: '72165',
    city: 'Thida',
    state: 'AR',
    latitude: '35.554588',
    longitude: '-91.474166',
    timeZoneId: 'America/Chicago',
  },
  '72166': {
    zip: '72166',
    city: 'Tichnor',
    state: 'AR',
    latitude: '34.057122',
    longitude: '-91.234851',
    timeZoneId: 'America/Chicago',
  },
  '72167': {
    zip: '72167',
    city: 'Traskwood',
    state: 'AR',
    latitude: '34.450558',
    longitude: '-92.654895',
    timeZoneId: 'America/Chicago',
  },
  '72168': {
    zip: '72168',
    city: 'Tucker',
    state: 'AR',
    latitude: '34.438521',
    longitude: '-91.893833',
    timeZoneId: 'America/Chicago',
  },
  '72169': {
    zip: '72169',
    city: 'Tupelo',
    state: 'AR',
    latitude: '35.391187',
    longitude: '-91.22719',
    timeZoneId: 'America/Chicago',
  },
  '72170': {
    zip: '72170',
    city: 'Ulm',
    state: 'AR',
    latitude: '34.576457',
    longitude: '-91.531458',
    timeZoneId: 'America/Chicago',
  },
  '72173': {
    zip: '72173',
    city: 'Vilonia',
    state: 'AR',
    latitude: '35.06694',
    longitude: '-92.17476',
    timeZoneId: 'America/Chicago',
  },
  '72175': {
    zip: '72175',
    city: 'Wabbaseka',
    state: 'AR',
    latitude: '34.360225',
    longitude: '-91.79301',
    timeZoneId: 'America/Chicago',
  },
  '72176': {
    zip: '72176',
    city: 'Ward',
    state: 'AR',
    latitude: '35.018174',
    longitude: '-91.94649',
    timeZoneId: 'America/Chicago',
  },
  '72178': {
    zip: '72178',
    city: 'West Point',
    state: 'AR',
    latitude: '35.206381',
    longitude: '-91.608079',
    timeZoneId: 'America/Chicago',
  },
  '72179': {
    zip: '72179',
    city: 'Wilburn',
    state: 'AR',
    latitude: '35.44751',
    longitude: '-91.900253',
    timeZoneId: 'America/Chicago',
  },
  '72180': {
    zip: '72180',
    city: 'Woodson',
    state: 'AR',
    latitude: '34.530195',
    longitude: '-92.211883',
    timeZoneId: 'America/Chicago',
  },
  '72181': {
    zip: '72181',
    city: 'Wooster',
    state: 'AR',
    latitude: '35.193472',
    longitude: '-92.465202',
    timeZoneId: 'America/Chicago',
  },
  '72182': {
    zip: '72182',
    city: 'Wright',
    state: 'AR',
    latitude: '34.44549',
    longitude: '-92.03839',
    timeZoneId: 'America/Chicago',
  },
  '72183': {
    zip: '72183',
    city: 'Wrightsville',
    state: 'AR',
    latitude: '34.598156',
    longitude: '-92.216135',
    timeZoneId: 'America/Chicago',
  },
  '72190': {
    zip: '72190',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.798097',
    longitude: '-92.256024',
    timeZoneId: 'America/Chicago',
  },
  '72199': {
    zip: '72199',
    city: 'North Little Rock',
    state: 'AR',
    latitude: '34.785804',
    longitude: '-92.255214',
    timeZoneId: 'America/Chicago',
  },
  '72201': {
    zip: '72201',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.748078',
    longitude: '-92.276457',
    timeZoneId: 'America/Chicago',
  },
  '72202': {
    zip: '72202',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.7389',
    longitude: '-92.274633',
    timeZoneId: 'America/Chicago',
  },
  '72203': {
    zip: '72203',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.633321',
    longitude: '-92.226709',
    timeZoneId: 'America/Chicago',
  },
  '72204': {
    zip: '72204',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.723843',
    longitude: '-92.347871',
    timeZoneId: 'America/Chicago',
  },
  '72205': {
    zip: '72205',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.751922',
    longitude: '-92.345672',
    timeZoneId: 'America/Chicago',
  },
  '72206': {
    zip: '72206',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.681307',
    longitude: '-92.27776',
    timeZoneId: 'America/Chicago',
  },
  '72207': {
    zip: '72207',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.773082',
    longitude: '-92.358471',
    timeZoneId: 'America/Chicago',
  },
  '72209': {
    zip: '72209',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.672097',
    longitude: '-92.362416',
    timeZoneId: 'America/Chicago',
  },
  '72210': {
    zip: '72210',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.712586',
    longitude: '-92.457803',
    timeZoneId: 'America/Chicago',
  },
  '72211': {
    zip: '72211',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.760749',
    longitude: '-92.445427',
    timeZoneId: 'America/Chicago',
  },
  '72212': {
    zip: '72212',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.790223',
    longitude: '-92.422264',
    timeZoneId: 'America/Chicago',
  },
  '72214': {
    zip: '72214',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.775891',
    longitude: '-92.277155',
    timeZoneId: 'America/Chicago',
  },
  '72215': {
    zip: '72215',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.753071',
    longitude: '-92.347004',
    timeZoneId: 'America/Chicago',
  },
  '72216': {
    zip: '72216',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.82589',
    longitude: '-92.216914',
    timeZoneId: 'America/Chicago',
  },
  '72217': {
    zip: '72217',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.775922',
    longitude: '-92.357628',
    timeZoneId: 'America/Chicago',
  },
  '72219': {
    zip: '72219',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.680928',
    longitude: '-92.3472',
    timeZoneId: 'America/Chicago',
  },
  '72221': {
    zip: '72221',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.793038',
    longitude: '-92.535999',
    timeZoneId: 'America/Chicago',
  },
  '72222': {
    zip: '72222',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.72453',
    longitude: '-92.353702',
    timeZoneId: 'America/Chicago',
  },
  '72223': {
    zip: '72223',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.746004',
    longitude: '-92.29084',
    timeZoneId: 'America/Chicago',
  },
  '72225': {
    zip: '72225',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.752796',
    longitude: '-92.349189',
    timeZoneId: 'America/Chicago',
  },
  '72227': {
    zip: '72227',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.774106',
    longitude: '-92.374415',
    timeZoneId: 'America/Chicago',
  },
  '72231': {
    zip: '72231',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.746261',
    longitude: '-92.276659',
    timeZoneId: 'America/Chicago',
  },
  '72255': {
    zip: '72255',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.757912',
    longitude: '-92.32476',
    timeZoneId: 'America/Chicago',
  },
  '72260': {
    zip: '72260',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.747293',
    longitude: '-92.286658',
    timeZoneId: 'America/Chicago',
  },
  '72295': {
    zip: '72295',
    city: 'Little Rock',
    state: 'AR',
    latitude: '34.745708',
    longitude: '-92.27978',
    timeZoneId: 'America/Chicago',
  },
  '72301': {
    zip: '72301',
    city: 'West Memphis',
    state: 'AR',
    latitude: '35.146563',
    longitude: '-90.177352',
    timeZoneId: 'America/Chicago',
  },
  '72303': {
    zip: '72303',
    city: 'West Memphis',
    state: 'AR',
    latitude: '35.14701',
    longitude: '-90.185924',
    timeZoneId: 'America/Chicago',
  },
  '72310': {
    zip: '72310',
    city: 'Armorel',
    state: 'AR',
    latitude: '35.91768',
    longitude: '-89.79886',
    timeZoneId: 'America/Chicago',
  },
  '72311': {
    zip: '72311',
    city: 'Aubrey',
    state: 'AR',
    latitude: '34.687108',
    longitude: '-90.939626',
    timeZoneId: 'America/Chicago',
  },
  '72312': {
    zip: '72312',
    city: 'Barton',
    state: 'AR',
    latitude: '34.493787',
    longitude: '-90.713159',
    timeZoneId: 'America/Chicago',
  },
  '72313': {
    zip: '72313',
    city: 'Bassett',
    state: 'AR',
    latitude: '35.515803',
    longitude: '-90.196734',
    timeZoneId: 'America/Chicago',
  },
  '72315': {
    zip: '72315',
    city: 'Blytheville',
    state: 'AR',
    latitude: '35.94318',
    longitude: '-89.89932',
    timeZoneId: 'America/Chicago',
  },
  '72316': {
    zip: '72316',
    city: 'Blytheville',
    state: 'AR',
    latitude: '35.92993',
    longitude: '-89.916897',
    timeZoneId: 'America/Chicago',
  },
  '72319': {
    zip: '72319',
    city: 'Gosnell',
    state: 'AR',
    latitude: '35.961585',
    longitude: '-89.974738',
    timeZoneId: 'America/Chicago',
  },
  '72320': {
    zip: '72320',
    city: 'Brickeys',
    state: 'AR',
    latitude: '34.844026',
    longitude: '-90.528188',
    timeZoneId: 'America/Chicago',
  },
  '72321': {
    zip: '72321',
    city: 'Burdette',
    state: 'AR',
    latitude: '35.816597',
    longitude: '-89.939769',
    timeZoneId: 'America/Chicago',
  },
  '72322': {
    zip: '72322',
    city: 'Caldwell',
    state: 'AR',
    latitude: '35.105433',
    longitude: '-90.771374',
    timeZoneId: 'America/Chicago',
  },
  '72324': {
    zip: '72324',
    city: 'Cherry Valley',
    state: 'AR',
    latitude: '35.394814',
    longitude: '-90.79663',
    timeZoneId: 'America/Chicago',
  },
  '72325': {
    zip: '72325',
    city: 'Clarkedale',
    state: 'AR',
    latitude: '35.219721',
    longitude: '-90.324168',
    timeZoneId: 'America/Chicago',
  },
  '72326': {
    zip: '72326',
    city: 'Colt',
    state: 'AR',
    latitude: '35.102623',
    longitude: '-90.76959',
    timeZoneId: 'America/Chicago',
  },
  '72327': {
    zip: '72327',
    city: 'Crawfordsville',
    state: 'AR',
    latitude: '35.20758',
    longitude: '-90.34398',
    timeZoneId: 'America/Chicago',
  },
  '72328': {
    zip: '72328',
    city: 'Crumrod',
    state: 'AR',
    latitude: '34.193874',
    longitude: '-90.9472',
    timeZoneId: 'America/Chicago',
  },
  '72329': {
    zip: '72329',
    city: 'Driver',
    state: 'AR',
    latitude: '35.55072',
    longitude: '-89.949775',
    timeZoneId: 'America/Chicago',
  },
  '72330': {
    zip: '72330',
    city: 'Dyess',
    state: 'AR',
    latitude: '35.594515',
    longitude: '-90.218765',
    timeZoneId: 'America/Chicago',
  },
  '72331': {
    zip: '72331',
    city: 'Earle',
    state: 'AR',
    latitude: '35.269497',
    longitude: '-90.46142',
    timeZoneId: 'America/Chicago',
  },
  '72332': {
    zip: '72332',
    city: 'Edmondson',
    state: 'AR',
    latitude: '35.099676',
    longitude: '-90.305839',
    timeZoneId: 'America/Chicago',
  },
  '72333': {
    zip: '72333',
    city: 'Elaine',
    state: 'AR',
    latitude: '34.304716',
    longitude: '-90.852738',
    timeZoneId: 'America/Chicago',
  },
  '72335': {
    zip: '72335',
    city: 'Forrest City',
    state: 'AR',
    latitude: '35.012397',
    longitude: '-90.782355',
    timeZoneId: 'America/Chicago',
  },
  '72336': {
    zip: '72336',
    city: 'Forrest City',
    state: 'AR',
    latitude: '35.013162',
    longitude: '-90.785455',
    timeZoneId: 'America/Chicago',
  },
  '72338': {
    zip: '72338',
    city: 'Frenchmans Bayou',
    state: 'AR',
    latitude: '35.467529',
    longitude: '-90.059363',
    timeZoneId: 'America/Chicago',
  },
  '72339': {
    zip: '72339',
    city: 'Gilmore',
    state: 'AR',
    latitude: '35.395515',
    longitude: '-90.24902',
    timeZoneId: 'America/Chicago',
  },
  '72340': {
    zip: '72340',
    city: 'Goodwin',
    state: 'AR',
    latitude: '34.970296',
    longitude: '-90.992775',
    timeZoneId: 'America/Chicago',
  },
  '72341': {
    zip: '72341',
    city: 'Haynes',
    state: 'AR',
    latitude: '34.871575',
    longitude: '-90.729171',
    timeZoneId: 'America/Chicago',
  },
  '72342': {
    zip: '72342',
    city: 'Helena',
    state: 'AR',
    latitude: '34.533018',
    longitude: '-90.601243',
    timeZoneId: 'America/Chicago',
  },
  '72346': {
    zip: '72346',
    city: 'Heth',
    state: 'AR',
    latitude: '35.06712',
    longitude: '-90.45228',
    timeZoneId: 'America/Chicago',
  },
  '72347': {
    zip: '72347',
    city: 'Hickory Ridge',
    state: 'AR',
    latitude: '35.404017',
    longitude: '-90.994778',
    timeZoneId: 'America/Chicago',
  },
  '72348': {
    zip: '72348',
    city: 'Hughes',
    state: 'AR',
    latitude: '34.920523',
    longitude: '-90.311029',
    timeZoneId: 'America/Chicago',
  },
  '72350': {
    zip: '72350',
    city: 'Joiner',
    state: 'AR',
    latitude: '35.513409',
    longitude: '-90.145986',
    timeZoneId: 'America/Chicago',
  },
  '72351': {
    zip: '72351',
    city: 'Keiser',
    state: 'AR',
    latitude: '35.694638',
    longitude: '-90.102767',
    timeZoneId: 'America/Chicago',
  },
  '72352': {
    zip: '72352',
    city: 'La Grange',
    state: 'AR',
    latitude: '34.780461',
    longitude: '-90.746676',
    timeZoneId: 'America/Chicago',
  },
  '72353': {
    zip: '72353',
    city: 'Lambrook',
    state: 'AR',
    latitude: '34.398323',
    longitude: '-90.866902',
    timeZoneId: 'America/Chicago',
  },
  '72354': {
    zip: '72354',
    city: 'Lepanto',
    state: 'AR',
    latitude: '35.607107',
    longitude: '-90.33552',
    timeZoneId: 'America/Chicago',
  },
  '72355': {
    zip: '72355',
    city: 'Lexa',
    state: 'AR',
    latitude: '34.67764',
    longitude: '-90.767453',
    timeZoneId: 'America/Chicago',
  },
  '72358': {
    zip: '72358',
    city: 'Luxora',
    state: 'AR',
    latitude: '35.756918',
    longitude: '-89.932328',
    timeZoneId: 'America/Chicago',
  },
  '72359': {
    zip: '72359',
    city: 'Madison',
    state: 'AR',
    latitude: '35.013056',
    longitude: '-90.7225',
    timeZoneId: 'America/Chicago',
  },
  '72360': {
    zip: '72360',
    city: 'Marianna',
    state: 'AR',
    latitude: '34.772374',
    longitude: '-90.762665',
    timeZoneId: 'America/Chicago',
  },
  '72364': {
    zip: '72364',
    city: 'Marion',
    state: 'AR',
    latitude: '35.213006',
    longitude: '-90.202685',
    timeZoneId: 'America/Chicago',
  },
  '72365': {
    zip: '72365',
    city: 'Marked Tree',
    state: 'AR',
    latitude: '35.530804',
    longitude: '-90.414142',
    timeZoneId: 'America/Chicago',
  },
  '72366': {
    zip: '72366',
    city: 'Marvell',
    state: 'AR',
    latitude: '34.54282',
    longitude: '-90.986332',
    timeZoneId: 'America/Chicago',
  },
  '72367': {
    zip: '72367',
    city: 'Mellwood',
    state: 'AR',
    latitude: '34.19965',
    longitude: '-91.039272',
    timeZoneId: 'America/Chicago',
  },
  '72368': {
    zip: '72368',
    city: 'Moro',
    state: 'AR',
    latitude: '34.763741',
    longitude: '-90.766708',
    timeZoneId: 'America/Chicago',
  },
  '72369': {
    zip: '72369',
    city: 'Oneida',
    state: 'AR',
    latitude: '34.399355',
    longitude: '-90.865962',
    timeZoneId: 'America/Chicago',
  },
  '72370': {
    zip: '72370',
    city: 'Osceola',
    state: 'AR',
    latitude: '35.703523',
    longitude: '-89.974581',
    timeZoneId: 'America/Chicago',
  },
  '72372': {
    zip: '72372',
    city: 'Palestine',
    state: 'AR',
    latitude: '34.95207',
    longitude: '-90.904029',
    timeZoneId: 'America/Chicago',
  },
  '72373': {
    zip: '72373',
    city: 'Parkin',
    state: 'AR',
    latitude: '35.271241',
    longitude: '-90.558288',
    timeZoneId: 'America/Chicago',
  },
  '72374': {
    zip: '72374',
    city: 'Poplar Grove',
    state: 'AR',
    latitude: '34.538156',
    longitude: '-90.879149',
    timeZoneId: 'America/Chicago',
  },
  '72376': {
    zip: '72376',
    city: 'Proctor',
    state: 'AR',
    latitude: '35.097258',
    longitude: '-90.282934',
    timeZoneId: 'America/Chicago',
  },
  '72377': {
    zip: '72377',
    city: 'Rivervale',
    state: 'AR',
    latitude: '35.558436',
    longitude: '-90.432112',
    timeZoneId: 'America/Chicago',
  },
  '72379': {
    zip: '72379',
    city: 'Snow Lake',
    state: 'AR',
    latitude: '34.043221',
    longitude: '-91.043702',
    timeZoneId: 'America/Chicago',
  },
  '72383': {
    zip: '72383',
    city: 'Turner',
    state: 'AR',
    latitude: '34.54409',
    longitude: '-90.983569',
    timeZoneId: 'America/Chicago',
  },
  '72384': {
    zip: '72384',
    city: 'Turrell',
    state: 'AR',
    latitude: '35.339209',
    longitude: '-90.255939',
    timeZoneId: 'America/Chicago',
  },
  '72386': {
    zip: '72386',
    city: 'Tyronza',
    state: 'AR',
    latitude: '35.485574',
    longitude: '-90.371039',
    timeZoneId: 'America/Chicago',
  },
  '72387': {
    zip: '72387',
    city: 'Vanndale',
    state: 'AR',
    latitude: '35.255454',
    longitude: '-90.807725',
    timeZoneId: 'America/Chicago',
  },
  '72389': {
    zip: '72389',
    city: 'Wabash',
    state: 'AR',
    latitude: '34.396758',
    longitude: '-90.863995',
    timeZoneId: 'America/Chicago',
  },
  '72390': {
    zip: '72390',
    city: 'West Helena',
    state: 'AR',
    latitude: '34.546617',
    longitude: '-90.645529',
    timeZoneId: 'America/Chicago',
  },
  '72391': {
    zip: '72391',
    city: 'West Ridge',
    state: 'AR',
    latitude: '35.595321',
    longitude: '-90.214914',
    timeZoneId: 'America/Chicago',
  },
  '72392': {
    zip: '72392',
    city: 'Wheatley',
    state: 'AR',
    latitude: '34.953612',
    longitude: '-91.099638',
    timeZoneId: 'America/Chicago',
  },
  '72394': {
    zip: '72394',
    city: 'Widener',
    state: 'AR',
    latitude: '35.022701',
    longitude: '-90.686118',
    timeZoneId: 'America/Chicago',
  },
  '72395': {
    zip: '72395',
    city: 'Wilson',
    state: 'AR',
    latitude: '35.572882',
    longitude: '-90.043975',
    timeZoneId: 'America/Chicago',
  },
  '72396': {
    zip: '72396',
    city: 'Wynne',
    state: 'AR',
    latitude: '35.225405',
    longitude: '-90.788153',
    timeZoneId: 'America/Chicago',
  },
  '72401': {
    zip: '72401',
    city: 'Jonesboro',
    state: 'AR',
    latitude: '35.824808',
    longitude: '-90.697175',
    timeZoneId: 'America/Chicago',
  },
  '72402': {
    zip: '72402',
    city: 'Jonesboro',
    state: 'AR',
    latitude: '35.806103',
    longitude: '-90.774552',
    timeZoneId: 'America/Chicago',
  },
  '72403': {
    zip: '72403',
    city: 'Jonesboro',
    state: 'AR',
    latitude: '35.869765',
    longitude: '-90.640487',
    timeZoneId: 'America/Chicago',
  },
  '72404': {
    zip: '72404',
    city: 'Jonesboro',
    state: 'AR',
    latitude: '35.800265',
    longitude: '-90.714862',
    timeZoneId: 'America/Chicago',
  },
  '72405': {
    zip: '72405',
    city: 'Jonesboro',
    state: 'AR',
    latitude: '35.800265',
    longitude: '-90.714862',
    timeZoneId: 'America/Chicago',
  },
  '72410': {
    zip: '72410',
    city: 'Alicia',
    state: 'AR',
    latitude: '36.127822',
    longitude: '-90.924239',
    timeZoneId: 'America/Chicago',
  },
  '72411': {
    zip: '72411',
    city: 'Bay',
    state: 'AR',
    latitude: '35.741926',
    longitude: '-90.560407',
    timeZoneId: 'America/Chicago',
  },
  '72412': {
    zip: '72412',
    city: 'Beech Grove',
    state: 'AR',
    latitude: '36.207876',
    longitude: '-90.621506',
    timeZoneId: 'America/Chicago',
  },
  '72413': {
    zip: '72413',
    city: 'Biggers',
    state: 'AR',
    latitude: '36.241727',
    longitude: '-90.960054',
    timeZoneId: 'America/Chicago',
  },
  '72414': {
    zip: '72414',
    city: 'Black Oak',
    state: 'AR',
    latitude: '35.83637',
    longitude: '-90.366266',
    timeZoneId: 'America/Chicago',
  },
  '72415': {
    zip: '72415',
    city: 'Black Rock',
    state: 'AR',
    latitude: '36.122987',
    longitude: '-91.133935',
    timeZoneId: 'America/Chicago',
  },
  '72416': {
    zip: '72416',
    city: 'Bono',
    state: 'AR',
    latitude: '35.902843',
    longitude: '-90.78556',
    timeZoneId: 'America/Chicago',
  },
  '72417': {
    zip: '72417',
    city: 'Brookland',
    state: 'AR',
    latitude: '35.901357',
    longitude: '-90.58088',
    timeZoneId: 'America/Chicago',
  },
  '72419': {
    zip: '72419',
    city: 'Caraway',
    state: 'AR',
    latitude: '35.757685',
    longitude: '-90.320635',
    timeZoneId: 'America/Chicago',
  },
  '72421': {
    zip: '72421',
    city: 'Cash',
    state: 'AR',
    latitude: '35.840201',
    longitude: '-90.956267',
    timeZoneId: 'America/Chicago',
  },
  '72422': {
    zip: '72422',
    city: 'Corning',
    state: 'AR',
    latitude: '36.411931',
    longitude: '-90.587967',
    timeZoneId: 'America/Chicago',
  },
  '72424': {
    zip: '72424',
    city: 'Datto',
    state: 'AR',
    latitude: '36.381098',
    longitude: '-90.72394',
    timeZoneId: 'America/Chicago',
  },
  '72425': {
    zip: '72425',
    city: 'Delaplaine',
    state: 'AR',
    latitude: '36.192222',
    longitude: '-90.730252',
    timeZoneId: 'America/Chicago',
  },
  '72426': {
    zip: '72426',
    city: 'Dell',
    state: 'AR',
    latitude: '35.855661',
    longitude: '-90.045048',
    timeZoneId: 'America/Chicago',
  },
  '72427': {
    zip: '72427',
    city: 'Egypt',
    state: 'AR',
    latitude: '35.863806',
    longitude: '-90.951982',
    timeZoneId: 'America/Chicago',
  },
  '72428': {
    zip: '72428',
    city: 'Etowah',
    state: 'AR',
    latitude: '35.715896',
    longitude: '-90.198184',
    timeZoneId: 'America/Chicago',
  },
  '72429': {
    zip: '72429',
    city: 'Fisher',
    state: 'AR',
    latitude: '35.509779',
    longitude: '-90.929907',
    timeZoneId: 'America/Chicago',
  },
  '72430': {
    zip: '72430',
    city: 'Greenway',
    state: 'AR',
    latitude: '36.339055',
    longitude: '-90.22285',
    timeZoneId: 'America/Chicago',
  },
  '72431': {
    zip: '72431',
    city: 'Grubbs',
    state: 'AR',
    latitude: '35.655714',
    longitude: '-91.076929',
    timeZoneId: 'America/Chicago',
  },
  '72432': {
    zip: '72432',
    city: 'Harrisburg',
    state: 'AR',
    latitude: '35.561729',
    longitude: '-90.72215',
    timeZoneId: 'America/Chicago',
  },
  '72433': {
    zip: '72433',
    city: 'Hoxie',
    state: 'AR',
    latitude: '36.047283',
    longitude: '-90.973982',
    timeZoneId: 'America/Chicago',
  },
  '72434': {
    zip: '72434',
    city: 'Imboden',
    state: 'AR',
    latitude: '36.180475',
    longitude: '-91.194025',
    timeZoneId: 'America/Chicago',
  },
  '72435': {
    zip: '72435',
    city: 'Knobel',
    state: 'AR',
    latitude: '36.315336',
    longitude: '-90.586579',
    timeZoneId: 'America/Chicago',
  },
  '72436': {
    zip: '72436',
    city: 'Lafe',
    state: 'AR',
    latitude: '36.21',
    longitude: '-90.51222',
    timeZoneId: 'America/Chicago',
  },
  '72437': {
    zip: '72437',
    city: 'Lake City',
    state: 'AR',
    latitude: '35.816616',
    longitude: '-90.441207',
    timeZoneId: 'America/Chicago',
  },
  '72438': {
    zip: '72438',
    city: 'Leachville',
    state: 'AR',
    latitude: '35.932079',
    longitude: '-90.252668',
    timeZoneId: 'America/Chicago',
  },
  '72440': {
    zip: '72440',
    city: 'Lynn',
    state: 'AR',
    latitude: '35.997577',
    longitude: '-91.247233',
    timeZoneId: 'America/Chicago',
  },
  '72441': {
    zip: '72441',
    city: 'Mc Dougal',
    state: 'AR',
    latitude: '36.385775',
    longitude: '-90.394279',
    timeZoneId: 'America/Chicago',
  },
  '72442': {
    zip: '72442',
    city: 'Manila',
    state: 'AR',
    latitude: '35.883098',
    longitude: '-90.168223',
    timeZoneId: 'America/Chicago',
  },
  '72443': {
    zip: '72443',
    city: 'Marmaduke',
    state: 'AR',
    latitude: '36.190078',
    longitude: '-90.38319',
    timeZoneId: 'America/Chicago',
  },
  '72444': {
    zip: '72444',
    city: 'Maynard',
    state: 'AR',
    latitude: '36.424145',
    longitude: '-90.898712',
    timeZoneId: 'America/Chicago',
  },
  '72445': {
    zip: '72445',
    city: 'Minturn',
    state: 'AR',
    latitude: '35.969792',
    longitude: '-91.028835',
    timeZoneId: 'America/Chicago',
  },
  '72447': {
    zip: '72447',
    city: 'Monette',
    state: 'AR',
    latitude: '35.883241',
    longitude: '-90.35184',
    timeZoneId: 'America/Chicago',
  },
  '72449': {
    zip: '72449',
    city: 'O Kean',
    state: 'AR',
    latitude: '36.180656',
    longitude: '-90.834326',
    timeZoneId: 'America/Chicago',
  },
  '72450': {
    zip: '72450',
    city: 'Paragould',
    state: 'AR',
    latitude: '36.057247',
    longitude: '-90.502389',
    timeZoneId: 'America/Chicago',
  },
  '72451': {
    zip: '72451',
    city: 'Paragould',
    state: 'AR',
    latitude: '36.058786',
    longitude: '-90.499707',
    timeZoneId: 'America/Chicago',
  },
  '72453': {
    zip: '72453',
    city: 'Peach Orchard',
    state: 'AR',
    latitude: '36.278302',
    longitude: '-90.662335',
    timeZoneId: 'America/Chicago',
  },
  '72454': {
    zip: '72454',
    city: 'Piggott',
    state: 'AR',
    latitude: '36.3834',
    longitude: '-90.195043',
    timeZoneId: 'America/Chicago',
  },
  '72455': {
    zip: '72455',
    city: 'Pocahontas',
    state: 'AR',
    latitude: '36.3105',
    longitude: '-91.03878',
    timeZoneId: 'America/Chicago',
  },
  '72456': {
    zip: '72456',
    city: 'Pollard',
    state: 'AR',
    latitude: '36.443108',
    longitude: '-90.270438',
    timeZoneId: 'America/Chicago',
  },
  '72457': {
    zip: '72457',
    city: 'Portia',
    state: 'AR',
    latitude: '36.077829',
    longitude: '-91.064155',
    timeZoneId: 'America/Chicago',
  },
  '72458': {
    zip: '72458',
    city: 'Powhatan',
    state: 'AR',
    latitude: '36.052816',
    longitude: '-91.149604',
    timeZoneId: 'America/Chicago',
  },
  '72459': {
    zip: '72459',
    city: 'Ravenden',
    state: 'AR',
    latitude: '36.18688',
    longitude: '-91.285302',
    timeZoneId: 'America/Chicago',
  },
  '72460': {
    zip: '72460',
    city: 'Ravenden Springs',
    state: 'AR',
    latitude: '36.360741',
    longitude: '-91.24368',
    timeZoneId: 'America/Chicago',
  },
  '72461': {
    zip: '72461',
    city: 'Rector',
    state: 'AR',
    latitude: '36.265856',
    longitude: '-90.293377',
    timeZoneId: 'America/Chicago',
  },
  '72462': {
    zip: '72462',
    city: 'Reyno',
    state: 'AR',
    latitude: '36.358163',
    longitude: '-90.758478',
    timeZoneId: 'America/Chicago',
  },
  '72464': {
    zip: '72464',
    city: 'Saint Francis',
    state: 'AR',
    latitude: '36.452763',
    longitude: '-90.141674',
    timeZoneId: 'America/Chicago',
  },
  '72465': {
    zip: '72465',
    city: 'Sedgwick',
    state: 'AR',
    latitude: '35.986948',
    longitude: '-90.879291',
    timeZoneId: 'America/Chicago',
  },
  '72466': {
    zip: '72466',
    city: 'Smithville',
    state: 'AR',
    latitude: '36.097394',
    longitude: '-91.274131',
    timeZoneId: 'America/Chicago',
  },
  '72467': {
    zip: '72467',
    city: 'State University',
    state: 'AR',
    latitude: '35.849336',
    longitude: '-90.679349',
    timeZoneId: 'America/Chicago',
  },
  '72469': {
    zip: '72469',
    city: 'Strawberry',
    state: 'AR',
    latitude: '35.97282',
    longitude: '-91.3839',
    timeZoneId: 'America/Chicago',
  },
  '72470': {
    zip: '72470',
    city: 'Success',
    state: 'AR',
    latitude: '36.453426',
    longitude: '-90.724904',
    timeZoneId: 'America/Chicago',
  },
  '72471': {
    zip: '72471',
    city: 'Swifton',
    state: 'AR',
    latitude: '35.836973',
    longitude: '-91.118711',
    timeZoneId: 'America/Chicago',
  },
  '72472': {
    zip: '72472',
    city: 'Trumann',
    state: 'AR',
    latitude: '35.66694',
    longitude: '-90.53916',
    timeZoneId: 'America/Chicago',
  },
  '72473': {
    zip: '72473',
    city: 'Tuckerman',
    state: 'AR',
    latitude: '35.73442',
    longitude: '-91.198661',
    timeZoneId: 'America/Chicago',
  },
  '72474': {
    zip: '72474',
    city: 'Walcott',
    state: 'AR',
    latitude: '36.044624',
    longitude: '-90.672594',
    timeZoneId: 'America/Chicago',
  },
  '72475': {
    zip: '72475',
    city: 'Waldenburg',
    state: 'AR',
    latitude: '35.565622',
    longitude: '-90.936173',
    timeZoneId: 'America/Chicago',
  },
  '72476': {
    zip: '72476',
    city: 'Walnut Ridge',
    state: 'AR',
    latitude: '36.09072',
    longitude: '-90.95958',
    timeZoneId: 'America/Chicago',
  },
  '72478': {
    zip: '72478',
    city: 'Warm Springs',
    state: 'AR',
    latitude: '36.465273',
    longitude: '-91.042048',
    timeZoneId: 'America/Chicago',
  },
  '72479': {
    zip: '72479',
    city: 'Weiner',
    state: 'AR',
    latitude: '35.643977',
    longitude: '-90.929712',
    timeZoneId: 'America/Chicago',
  },
  '72482': {
    zip: '72482',
    city: 'Williford',
    state: 'AR',
    latitude: '36.316801',
    longitude: '-91.391813',
    timeZoneId: 'America/Chicago',
  },
  '72501': {
    zip: '72501',
    city: 'Batesville',
    state: 'AR',
    latitude: '35.773469',
    longitude: '-91.6381',
    timeZoneId: 'America/Chicago',
  },
  '72503': {
    zip: '72503',
    city: 'Batesville',
    state: 'AR',
    latitude: '35.727389',
    longitude: '-91.673733',
    timeZoneId: 'America/Chicago',
  },
  '72512': {
    zip: '72512',
    city: 'Horseshoe Bend',
    state: 'AR',
    latitude: '36.228348',
    longitude: '-91.752774',
    timeZoneId: 'America/Chicago',
  },
  '72513': {
    zip: '72513',
    city: 'Ash Flat',
    state: 'AR',
    latitude: '36.222517',
    longitude: '-91.61178',
    timeZoneId: 'America/Chicago',
  },
  '72515': {
    zip: '72515',
    city: 'Bexar',
    state: 'AR',
    latitude: '36.30245',
    longitude: '-91.978351',
    timeZoneId: 'America/Chicago',
  },
  '72517': {
    zip: '72517',
    city: 'Brockwell',
    state: 'AR',
    latitude: '36.132138',
    longitude: '-91.962572',
    timeZoneId: 'America/Chicago',
  },
  '72519': {
    zip: '72519',
    city: 'Calico Rock',
    state: 'AR',
    latitude: '36.152503',
    longitude: '-92.016533',
    timeZoneId: 'America/Chicago',
  },
  '72520': {
    zip: '72520',
    city: 'Camp',
    state: 'AR',
    latitude: '36.391882',
    longitude: '-91.727113',
    timeZoneId: 'America/Chicago',
  },
  '72521': {
    zip: '72521',
    city: 'Cave City',
    state: 'AR',
    latitude: '35.894197',
    longitude: '-91.503465',
    timeZoneId: 'America/Chicago',
  },
  '72522': {
    zip: '72522',
    city: 'Charlotte',
    state: 'AR',
    latitude: '35.8041',
    longitude: '-91.47648',
    timeZoneId: 'America/Chicago',
  },
  '72523': {
    zip: '72523',
    city: 'Concord',
    state: 'AR',
    latitude: '35.623195',
    longitude: '-91.782919',
    timeZoneId: 'America/Chicago',
  },
  '72524': {
    zip: '72524',
    city: 'Cord',
    state: 'AR',
    latitude: '35.83077',
    longitude: '-91.348165',
    timeZoneId: 'America/Chicago',
  },
  '72525': {
    zip: '72525',
    city: 'Cherokee Village',
    state: 'AR',
    latitude: '36.315833',
    longitude: '-91.4825',
    timeZoneId: 'America/Chicago',
  },
  '72526': {
    zip: '72526',
    city: 'Cushman',
    state: 'AR',
    latitude: '35.877635',
    longitude: '-91.76058',
    timeZoneId: 'America/Chicago',
  },
  '72527': {
    zip: '72527',
    city: 'Desha',
    state: 'AR',
    latitude: '35.732739',
    longitude: '-91.681637',
    timeZoneId: 'America/Chicago',
  },
  '72528': {
    zip: '72528',
    city: 'Dolph',
    state: 'AR',
    latitude: '36.228212',
    longitude: '-92.107943',
    timeZoneId: 'America/Chicago',
  },
  '72529': {
    zip: '72529',
    city: 'Cherokee Village',
    state: 'AR',
    latitude: '36.29543',
    longitude: '-91.540492',
    timeZoneId: 'America/Chicago',
  },
  '72530': {
    zip: '72530',
    city: 'Drasco',
    state: 'AR',
    latitude: '35.512977',
    longitude: '-92.031354',
    timeZoneId: 'America/Chicago',
  },
  '72531': {
    zip: '72531',
    city: 'Elizabeth',
    state: 'AR',
    latitude: '36.314549',
    longitude: '-92.09861',
    timeZoneId: 'America/Chicago',
  },
  '72532': {
    zip: '72532',
    city: 'Evening Shade',
    state: 'AR',
    latitude: '36.092593',
    longitude: '-91.579676',
    timeZoneId: 'America/Chicago',
  },
  '72533': {
    zip: '72533',
    city: 'Fifty Six',
    state: 'AR',
    latitude: '36.01218',
    longitude: '-92.2155',
    timeZoneId: 'America/Chicago',
  },
  '72534': {
    zip: '72534',
    city: 'Floral',
    state: 'AR',
    latitude: '35.605698',
    longitude: '-91.730413',
    timeZoneId: 'America/Chicago',
  },
  '72536': {
    zip: '72536',
    city: 'Franklin',
    state: 'AR',
    latitude: '36.123302',
    longitude: '-91.808456',
    timeZoneId: 'America/Chicago',
  },
  '72537': {
    zip: '72537',
    city: 'Gamaliel',
    state: 'AR',
    latitude: '36.368676',
    longitude: '-92.459573',
    timeZoneId: 'America/Chicago',
  },
  '72538': {
    zip: '72538',
    city: 'Gepp',
    state: 'AR',
    latitude: '36.441864',
    longitude: '-92.095472',
    timeZoneId: 'America/Chicago',
  },
  '72539': {
    zip: '72539',
    city: 'Glencoe',
    state: 'AR',
    latitude: '36.294145',
    longitude: '-91.77599',
    timeZoneId: 'America/Chicago',
  },
  '72540': {
    zip: '72540',
    city: 'Guion',
    state: 'AR',
    latitude: '35.957204',
    longitude: '-91.938344',
    timeZoneId: 'America/Chicago',
  },
  '72542': {
    zip: '72542',
    city: 'Hardy',
    state: 'AR',
    latitude: '36.256526',
    longitude: '-91.53818',
    timeZoneId: 'America/Chicago',
  },
  '72543': {
    zip: '72543',
    city: 'Heber Springs',
    state: 'AR',
    latitude: '35.493501',
    longitude: '-92.031852',
    timeZoneId: 'America/Chicago',
  },
  '72544': {
    zip: '72544',
    city: 'Henderson',
    state: 'AR',
    latitude: '36.349196',
    longitude: '-92.231716',
    timeZoneId: 'America/Chicago',
  },
  '72545': {
    zip: '72545',
    city: 'Heber Springs',
    state: 'AR',
    latitude: '35.487914',
    longitude: '-92.055731',
    timeZoneId: 'America/Chicago',
  },
  '72546': {
    zip: '72546',
    city: 'Ida',
    state: 'AR',
    latitude: '35.596815',
    longitude: '-91.946535',
    timeZoneId: 'America/Chicago',
  },
  '72550': {
    zip: '72550',
    city: 'Locust Grove',
    state: 'AR',
    latitude: '35.722685',
    longitude: '-91.763142',
    timeZoneId: 'America/Chicago',
  },
  '72553': {
    zip: '72553',
    city: 'Magness',
    state: 'AR',
    latitude: '35.706177',
    longitude: '-91.482775',
    timeZoneId: 'America/Chicago',
  },
  '72554': {
    zip: '72554',
    city: 'Mammoth Spring',
    state: 'AR',
    latitude: '36.497654',
    longitude: '-91.54194',
    timeZoneId: 'America/Chicago',
  },
  '72555': {
    zip: '72555',
    city: 'Marcella',
    state: 'AR',
    latitude: '35.751456',
    longitude: '-91.944708',
    timeZoneId: 'America/Chicago',
  },
  '72556': {
    zip: '72556',
    city: 'Melbourne',
    state: 'AR',
    latitude: '36.06217',
    longitude: '-91.89924',
    timeZoneId: 'America/Chicago',
  },
  '72560': {
    zip: '72560',
    city: 'Mountain View',
    state: 'AR',
    latitude: '35.867456',
    longitude: '-92.123865',
    timeZoneId: 'America/Chicago',
  },
  '72561': {
    zip: '72561',
    city: 'Mount Pleasant',
    state: 'AR',
    latitude: '35.964212',
    longitude: '-91.806374',
    timeZoneId: 'America/Chicago',
  },
  '72562': {
    zip: '72562',
    city: 'Newark',
    state: 'AR',
    latitude: '35.727707',
    longitude: '-91.435632',
    timeZoneId: 'America/Chicago',
  },
  '72564': {
    zip: '72564',
    city: 'Oil Trough',
    state: 'AR',
    latitude: '35.612233',
    longitude: '-91.438675',
    timeZoneId: 'America/Chicago',
  },
  '72565': {
    zip: '72565',
    city: 'Oxford',
    state: 'AR',
    latitude: '36.21472',
    longitude: '-91.918823',
    timeZoneId: 'America/Chicago',
  },
  '72566': {
    zip: '72566',
    city: 'Pineville',
    state: 'AR',
    latitude: '36.174282',
    longitude: '-92.092744',
    timeZoneId: 'America/Chicago',
  },
  '72567': {
    zip: '72567',
    city: 'Pleasant Grove',
    state: 'AR',
    latitude: '35.842601',
    longitude: '-91.925046',
    timeZoneId: 'America/Chicago',
  },
  '72568': {
    zip: '72568',
    city: 'Pleasant Plains',
    state: 'AR',
    latitude: '35.564579',
    longitude: '-91.626652',
    timeZoneId: 'America/Chicago',
  },
  '72569': {
    zip: '72569',
    city: 'Poughkeepsie',
    state: 'AR',
    latitude: '36.083169',
    longitude: '-91.437209',
    timeZoneId: 'America/Chicago',
  },
  '72571': {
    zip: '72571',
    city: 'Rosie',
    state: 'AR',
    latitude: '35.645202',
    longitude: '-91.533615',
    timeZoneId: 'America/Chicago',
  },
  '72572': {
    zip: '72572',
    city: 'Saffell',
    state: 'AR',
    latitude: '35.917914',
    longitude: '-91.298942',
    timeZoneId: 'America/Chicago',
  },
  '72573': {
    zip: '72573',
    city: 'Sage',
    state: 'AR',
    latitude: '36.040356',
    longitude: '-91.821692',
    timeZoneId: 'America/Chicago',
  },
  '72575': {
    zip: '72575',
    city: 'Salado',
    state: 'AR',
    latitude: '35.691013',
    longitude: '-91.590512',
    timeZoneId: 'America/Chicago',
  },
  '72576': {
    zip: '72576',
    city: 'Salem',
    state: 'AR',
    latitude: '36.367489',
    longitude: '-91.825824',
    timeZoneId: 'America/Chicago',
  },
  '72577': {
    zip: '72577',
    city: 'Sidney',
    state: 'AR',
    latitude: '35.984477',
    longitude: '-91.639615',
    timeZoneId: 'America/Chicago',
  },
  '72578': {
    zip: '72578',
    city: 'Sturkie',
    state: 'AR',
    latitude: '36.465622',
    longitude: '-91.990164',
    timeZoneId: 'America/Chicago',
  },
  '72579': {
    zip: '72579',
    city: 'Sulphur Rock',
    state: 'AR',
    latitude: '35.799327',
    longitude: '-91.472922',
    timeZoneId: 'America/Chicago',
  },
  '72581': {
    zip: '72581',
    city: 'Tumbling Shoals',
    state: 'AR',
    latitude: '35.549597',
    longitude: '-91.952473',
    timeZoneId: 'America/Chicago',
  },
  '72583': {
    zip: '72583',
    city: 'Viola',
    state: 'AR',
    latitude: '36.386789',
    longitude: '-91.991298',
    timeZoneId: 'America/Chicago',
  },
  '72584': {
    zip: '72584',
    city: 'Violet Hill',
    state: 'AR',
    latitude: '36.157784',
    longitude: '-91.85142',
    timeZoneId: 'America/Chicago',
  },
  '72585': {
    zip: '72585',
    city: 'Wideman',
    state: 'AR',
    latitude: '36.215052',
    longitude: '-92.007034',
    timeZoneId: 'America/Chicago',
  },
  '72587': {
    zip: '72587',
    city: 'Wiseman',
    state: 'AR',
    latitude: '36.226251',
    longitude: '-91.854109',
    timeZoneId: 'America/Chicago',
  },
  '72601': {
    zip: '72601',
    city: 'Harrison',
    state: 'AR',
    latitude: '36.238821',
    longitude: '-93.114328',
    timeZoneId: 'America/Chicago',
  },
  '72602': {
    zip: '72602',
    city: 'Harrison',
    state: 'AR',
    latitude: '36.22696',
    longitude: '-93.115091',
    timeZoneId: 'America/Chicago',
  },
  '72611': {
    zip: '72611',
    city: 'Alpena',
    state: 'AR',
    latitude: '36.300437',
    longitude: '-93.274506',
    timeZoneId: 'America/Chicago',
  },
  '72613': {
    zip: '72613',
    city: 'Beaver',
    state: 'AR',
    latitude: '36.38642',
    longitude: '-93.617629',
    timeZoneId: 'America/Chicago',
  },
  '72615': {
    zip: '72615',
    city: 'Bergman',
    state: 'AR',
    latitude: '36.264197',
    longitude: '-93.103432',
    timeZoneId: 'America/Chicago',
  },
  '72616': {
    zip: '72616',
    city: 'Berryville',
    state: 'AR',
    latitude: '36.36702',
    longitude: '-93.58014',
    timeZoneId: 'America/Chicago',
  },
  '72617': {
    zip: '72617',
    city: 'Big Flat',
    state: 'AR',
    latitude: '36.031701',
    longitude: '-92.357454',
    timeZoneId: 'America/Chicago',
  },
  '72619': {
    zip: '72619',
    city: 'Bull Shoals',
    state: 'AR',
    latitude: '36.382828',
    longitude: '-92.579818',
    timeZoneId: 'America/Chicago',
  },
  '72623': {
    zip: '72623',
    city: 'Clarkridge',
    state: 'AR',
    latitude: '36.449082',
    longitude: '-92.349269',
    timeZoneId: 'America/Chicago',
  },
  '72624': {
    zip: '72624',
    city: 'Compton',
    state: 'AR',
    latitude: '36.084866',
    longitude: '-93.306736',
    timeZoneId: 'America/Chicago',
  },
  '72626': {
    zip: '72626',
    city: 'Cotter',
    state: 'AR',
    latitude: '36.319081',
    longitude: '-92.534547',
    timeZoneId: 'America/Chicago',
  },
  '72628': {
    zip: '72628',
    city: 'Deer',
    state: 'AR',
    latitude: '35.842526',
    longitude: '-93.350809',
    timeZoneId: 'America/Chicago',
  },
  '72629': {
    zip: '72629',
    city: 'Dennard',
    state: 'AR',
    latitude: '35.721345',
    longitude: '-92.586703',
    timeZoneId: 'America/Chicago',
  },
  '72630': {
    zip: '72630',
    city: 'Diamond City',
    state: 'AR',
    latitude: '36.464444',
    longitude: '-92.92',
    timeZoneId: 'America/Chicago',
  },
  '72631': {
    zip: '72631',
    city: 'Eureka Springs',
    state: 'AR',
    latitude: '36.400948',
    longitude: '-93.736695',
    timeZoneId: 'America/Chicago',
  },
  '72632': {
    zip: '72632',
    city: 'Eureka Springs',
    state: 'AR',
    latitude: '36.444978',
    longitude: '-93.740676',
    timeZoneId: 'America/Chicago',
  },
  '72633': {
    zip: '72633',
    city: 'Everton',
    state: 'AR',
    latitude: '36.154251',
    longitude: '-92.914436',
    timeZoneId: 'America/Chicago',
  },
  '72634': {
    zip: '72634',
    city: 'Flippin',
    state: 'AR',
    latitude: '36.279343',
    longitude: '-92.593895',
    timeZoneId: 'America/Chicago',
  },
  '72635': {
    zip: '72635',
    city: 'Gassville',
    state: 'AR',
    latitude: '36.30264',
    longitude: '-92.48448',
    timeZoneId: 'America/Chicago',
  },
  '72636': {
    zip: '72636',
    city: 'Gilbert',
    state: 'AR',
    latitude: '35.966279',
    longitude: '-92.813057',
    timeZoneId: 'America/Chicago',
  },
  '72638': {
    zip: '72638',
    city: 'Green Forest',
    state: 'AR',
    latitude: '36.3312',
    longitude: '-93.4236',
    timeZoneId: 'America/Chicago',
  },
  '72639': {
    zip: '72639',
    city: 'Harriet',
    state: 'AR',
    latitude: '36.045',
    longitude: '-92.501389',
    timeZoneId: 'America/Chicago',
  },
  '72640': {
    zip: '72640',
    city: 'Hasty',
    state: 'AR',
    latitude: '36.017949',
    longitude: '-93.041336',
    timeZoneId: 'America/Chicago',
  },
  '72641': {
    zip: '72641',
    city: 'Jasper',
    state: 'AR',
    latitude: '36.002568',
    longitude: '-93.217954',
    timeZoneId: 'America/Chicago',
  },
  '72642': {
    zip: '72642',
    city: 'Lakeview',
    state: 'AR',
    latitude: '36.38443',
    longitude: '-92.521129',
    timeZoneId: 'America/Chicago',
  },
  '72643': {
    zip: '72643',
    city: 'Lead Hill',
    state: 'MO',
    latitude: '36.937340',
    longitude: '-92.944161',
    timeZoneId: 'America/Chicago',
  },
  '72644': {
    zip: '72644',
    city: 'Lead Hill',
    state: 'AR',
    latitude: '36.469449',
    longitude: '-92.916329',
    timeZoneId: 'America/Chicago',
  },
  '72645': {
    zip: '72645',
    city: 'Leslie',
    state: 'AR',
    latitude: '35.82678',
    longitude: '-92.57502',
    timeZoneId: 'America/Chicago',
  },
  '72648': {
    zip: '72648',
    city: 'Marble Falls',
    state: 'AR',
    latitude: '36.084759',
    longitude: '-93.14693',
    timeZoneId: 'America/Chicago',
  },
  '72650': {
    zip: '72650',
    city: 'Marshall',
    state: 'AR',
    latitude: '35.940312',
    longitude: '-92.656563',
    timeZoneId: 'America/Chicago',
  },
  '72651': {
    zip: '72651',
    city: 'Midway',
    state: 'AR',
    latitude: '36.28759',
    longitude: '-92.438972',
    timeZoneId: 'America/Chicago',
  },
  '72653': {
    zip: '72653',
    city: 'Mountain Home',
    state: 'AR',
    latitude: '36.35262',
    longitude: '-92.3667',
    timeZoneId: 'America/Chicago',
  },
  '72654': {
    zip: '72654',
    city: 'Mountain Home',
    state: 'AR',
    latitude: '36.336533',
    longitude: '-92.384535',
    timeZoneId: 'America/Chicago',
  },
  '72655': {
    zip: '72655',
    city: 'Mount Judea',
    state: 'AR',
    latitude: '35.92',
    longitude: '-93.06',
    timeZoneId: 'America/Chicago',
  },
  '72657': {
    zip: '72657',
    city: 'Timbo',
    state: 'AR',
    latitude: '35.8948',
    longitude: '-92.246904',
    timeZoneId: 'America/Chicago',
  },
  '72658': {
    zip: '72658',
    city: 'Norfork',
    state: 'AR',
    latitude: '36.2085',
    longitude: '-92.26668',
    timeZoneId: 'America/Chicago',
  },
  '72659': {
    zip: '72659',
    city: 'Norfork',
    state: 'AR',
    latitude: '36.207031',
    longitude: '-92.282934',
    timeZoneId: 'America/Chicago',
  },
  '72660': {
    zip: '72660',
    city: 'Oak Grove',
    state: 'AR',
    latitude: '36.459428',
    longitude: '-93.431893',
    timeZoneId: 'America/Chicago',
  },
  '72661': {
    zip: '72661',
    city: 'Oakland',
    state: 'AR',
    latitude: '36.449464',
    longitude: '-92.574135',
    timeZoneId: 'America/Chicago',
  },
  '72662': {
    zip: '72662',
    city: 'Omaha',
    state: 'AR',
    latitude: '36.456548',
    longitude: '-93.172793',
    timeZoneId: 'America/Chicago',
  },
  '72663': {
    zip: '72663',
    city: 'Onia',
    state: 'AR',
    latitude: '35.943278',
    longitude: '-92.334065',
    timeZoneId: 'America/Chicago',
  },
  '72666': {
    zip: '72666',
    city: 'Parthenon',
    state: 'AR',
    latitude: '35.93805',
    longitude: '-93.282571',
    timeZoneId: 'America/Chicago',
  },
  '72668': {
    zip: '72668',
    city: 'Peel',
    state: 'AR',
    latitude: '36.41783',
    longitude: '-92.766644',
    timeZoneId: 'America/Chicago',
  },
  '72669': {
    zip: '72669',
    city: 'Pindall',
    state: 'AR',
    latitude: '36.072776',
    longitude: '-92.886999',
    timeZoneId: 'America/Chicago',
  },
  '72670': {
    zip: '72670',
    city: 'Ponca',
    state: 'AR',
    latitude: '36.053135',
    longitude: '-93.409673',
    timeZoneId: 'America/Chicago',
  },
  '72672': {
    zip: '72672',
    city: 'Pyatt',
    state: 'AR',
    latitude: '36.217134',
    longitude: '-92.737719',
    timeZoneId: 'America/Chicago',
  },
  '72675': {
    zip: '72675',
    city: 'Saint Joe',
    state: 'AR',
    latitude: '35.965276',
    longitude: '-92.812156',
    timeZoneId: 'America/Chicago',
  },
  '72677': {
    zip: '72677',
    city: 'Summit',
    state: 'AR',
    latitude: '36.217652',
    longitude: '-92.740882',
    timeZoneId: 'America/Chicago',
  },
  '72679': {
    zip: '72679',
    city: 'Tilly',
    state: 'AR',
    latitude: '35.699195',
    longitude: '-92.863769',
    timeZoneId: 'America/Chicago',
  },
  '72680': {
    zip: '72680',
    city: 'Timbo',
    state: 'AR',
    latitude: '35.842844',
    longitude: '-92.354172',
    timeZoneId: 'America/Chicago',
  },
  '72682': {
    zip: '72682',
    city: 'Valley Springs',
    state: 'AR',
    latitude: '36.143149',
    longitude: '-92.983087',
    timeZoneId: 'America/Chicago',
  },
  '72683': {
    zip: '72683',
    city: 'Vendor',
    state: 'AR',
    latitude: '35.941766',
    longitude: '-93.109327',
    timeZoneId: 'America/Chicago',
  },
  '72685': {
    zip: '72685',
    city: 'Western Grove',
    state: 'AR',
    latitude: '36.042357',
    longitude: '-92.985835',
    timeZoneId: 'America/Chicago',
  },
  '72686': {
    zip: '72686',
    city: 'Witts Springs',
    state: 'AR',
    latitude: '35.789648',
    longitude: '-92.835353',
    timeZoneId: 'America/Chicago',
  },
  '72687': {
    zip: '72687',
    city: 'Yellville',
    state: 'AR',
    latitude: '36.223245',
    longitude: '-92.682994',
    timeZoneId: 'America/Chicago',
  },
  '72701': {
    zip: '72701',
    city: 'Fayetteville',
    state: 'AR',
    latitude: '36.05094',
    longitude: '-94.1364',
    timeZoneId: 'America/Chicago',
  },
  '72702': {
    zip: '72702',
    city: 'Fayetteville',
    state: 'AR',
    latitude: '36.063066',
    longitude: '-94.162463',
    timeZoneId: 'America/Chicago',
  },
  '72703': {
    zip: '72703',
    city: 'Fayetteville',
    state: 'AR',
    latitude: '36.099773',
    longitude: '-94.153894',
    timeZoneId: 'America/Chicago',
  },
  '72704': {
    zip: '72704',
    city: 'Fayetteville',
    state: 'AR',
    latitude: '36.080874',
    longitude: '-94.201559',
    timeZoneId: 'America/Chicago',
  },
  '72711': {
    zip: '72711',
    city: 'Avoca',
    state: 'AR',
    latitude: '36.3554',
    longitude: '-94.044946',
    timeZoneId: 'America/Chicago',
  },
  '72712': {
    zip: '72712',
    city: 'Bentonville',
    state: 'AR',
    latitude: '36.375647',
    longitude: '-94.209338',
    timeZoneId: 'America/Chicago',
  },
  '72713': {
    zip: '72713',
    city: 'Bentonville',
    state: 'AR',
    latitude: '36.362149',
    longitude: '-94.239857',
    timeZoneId: 'America/Chicago',
  },
  '72714': {
    zip: '72714',
    city: 'Bella Vista',
    state: 'AR',
    latitude: '36.45714',
    longitude: '-94.23732',
    timeZoneId: 'America/Chicago',
  },
  '72715': {
    zip: '72715',
    city: 'Bella Vista',
    state: 'AR',
    latitude: '36.467949',
    longitude: '-94.297268',
    timeZoneId: 'America/Chicago',
  },
  '72716': {
    zip: '72716',
    city: 'Bentonville',
    state: 'AR',
    latitude: '36.32258',
    longitude: '-94.18085',
    timeZoneId: 'America/Chicago',
  },
  '72717': {
    zip: '72717',
    city: 'Canehill',
    state: 'AR',
    latitude: '35.909848',
    longitude: '-94.37716',
    timeZoneId: 'America/Chicago',
  },
  '72718': {
    zip: '72718',
    city: 'Cave Springs',
    state: 'AR',
    latitude: '36.275844',
    longitude: '-94.203808',
    timeZoneId: 'America/Chicago',
  },
  '72719': {
    zip: '72719',
    city: 'Centerton',
    state: 'AR',
    latitude: '36.374201',
    longitude: '-94.317969',
    timeZoneId: 'America/Chicago',
  },
  '72721': {
    zip: '72721',
    city: 'Combs',
    state: 'AR',
    latitude: '35.846802',
    longitude: '-93.819393',
    timeZoneId: 'America/Chicago',
  },
  '72722': {
    zip: '72722',
    city: 'Decatur',
    state: 'AR',
    latitude: '36.342647',
    longitude: '-94.43934',
    timeZoneId: 'America/Chicago',
  },
  '72727': {
    zip: '72727',
    city: 'Elkins',
    state: 'AR',
    latitude: '36.052406',
    longitude: '-93.995694',
    timeZoneId: 'America/Chicago',
  },
  '72728': {
    zip: '72728',
    city: 'Elm Springs',
    state: 'AR',
    latitude: '36.205388',
    longitude: '-94.231692',
    timeZoneId: 'America/Chicago',
  },
  '72729': {
    zip: '72729',
    city: 'Evansville',
    state: 'AR',
    latitude: '35.803586',
    longitude: '-94.473781',
    timeZoneId: 'America/Chicago',
  },
  '72730': {
    zip: '72730',
    city: 'Farmington',
    state: 'AR',
    latitude: '36.043692',
    longitude: '-94.238565',
    timeZoneId: 'America/Chicago',
  },
  '72732': {
    zip: '72732',
    city: 'Garfield',
    state: 'AR',
    latitude: '36.391692',
    longitude: '-93.912175',
    timeZoneId: 'America/Chicago',
  },
  '72733': {
    zip: '72733',
    city: 'Gateway',
    state: 'AR',
    latitude: '36.47989',
    longitude: '-93.919021',
    timeZoneId: 'America/Chicago',
  },
  '72734': {
    zip: '72734',
    city: 'Gentry',
    state: 'AR',
    latitude: '36.268323',
    longitude: '-94.486877',
    timeZoneId: 'America/Chicago',
  },
  '72735': {
    zip: '72735',
    city: 'Goshen',
    state: 'AR',
    latitude: '36.100183',
    longitude: '-93.989097',
    timeZoneId: 'America/Chicago',
  },
  '72736': {
    zip: '72736',
    city: 'Gravette',
    state: 'AR',
    latitude: '36.42222',
    longitude: '-94.447295',
    timeZoneId: 'America/Chicago',
  },
  '72737': {
    zip: '72737',
    city: 'Greenland',
    state: 'AR',
    latitude: '35.994467',
    longitude: '-94.174409',
    timeZoneId: 'America/Chicago',
  },
  '72738': {
    zip: '72738',
    city: 'Hindsville',
    state: 'AR',
    latitude: '36.141271',
    longitude: '-93.868502',
    timeZoneId: 'America/Chicago',
  },
  '72739': {
    zip: '72739',
    city: 'Hiwasse',
    state: 'AR',
    latitude: '36.478701',
    longitude: '-94.328444',
    timeZoneId: 'America/Chicago',
  },
  '72740': {
    zip: '72740',
    city: 'Huntsville',
    state: 'AR',
    latitude: '36.085667',
    longitude: '-93.734435',
    timeZoneId: 'America/Chicago',
  },
  '72741': {
    zip: '72741',
    city: 'Johnson',
    state: 'AR',
    latitude: '36.129144',
    longitude: '-94.164122',
    timeZoneId: 'America/Chicago',
  },
  '72742': {
    zip: '72742',
    city: 'Kingston',
    state: 'AR',
    latitude: '36.037868',
    longitude: '-93.500749',
    timeZoneId: 'America/Chicago',
  },
  '72744': {
    zip: '72744',
    city: 'Lincoln',
    state: 'AR',
    latitude: '35.950316',
    longitude: '-94.413006',
    timeZoneId: 'America/Chicago',
  },
  '72745': {
    zip: '72745',
    city: 'Lowell',
    state: 'AR',
    latitude: '36.258693',
    longitude: '-94.130198',
    timeZoneId: 'America/Chicago',
  },
  '72747': {
    zip: '72747',
    city: 'Maysville',
    state: 'AR',
    latitude: '36.398981',
    longitude: '-94.573958',
    timeZoneId: 'America/Chicago',
  },
  '72749': {
    zip: '72749',
    city: 'Morrow',
    state: 'AR',
    latitude: '35.852571',
    longitude: '-94.430226',
    timeZoneId: 'America/Chicago',
  },
  '72751': {
    zip: '72751',
    city: 'Pea Ridge',
    state: 'AR',
    latitude: '36.447789',
    longitude: '-94.115116',
    timeZoneId: 'America/Chicago',
  },
  '72752': {
    zip: '72752',
    city: 'Pettigrew',
    state: 'AR',
    latitude: '35.839705',
    longitude: '-93.597634',
    timeZoneId: 'America/Chicago',
  },
  '72753': {
    zip: '72753',
    city: 'Prairie Grove',
    state: 'AR',
    latitude: '35.97675',
    longitude: '-94.319194',
    timeZoneId: 'America/Chicago',
  },
  '72756': {
    zip: '72756',
    city: 'Rogers',
    state: 'AR',
    latitude: '36.322705',
    longitude: '-94.02785',
    timeZoneId: 'America/Chicago',
  },
  '72757': {
    zip: '72757',
    city: 'Rogers',
    state: 'AR',
    latitude: '36.333653',
    longitude: '-94.12731',
    timeZoneId: 'America/Chicago',
  },
  '72758': {
    zip: '72758',
    city: 'Rogers',
    state: 'AR',
    latitude: '36.329665',
    longitude: '-94.127155',
    timeZoneId: 'America/Chicago',
  },
  '72760': {
    zip: '72760',
    city: 'Saint Paul',
    state: 'AR',
    latitude: '35.855662',
    longitude: '-93.723204',
    timeZoneId: 'America/Chicago',
  },
  '72761': {
    zip: '72761',
    city: 'Siloam Springs',
    state: 'AR',
    latitude: '36.183359',
    longitude: '-94.539153',
    timeZoneId: 'America/Chicago',
  },
  '72762': {
    zip: '72762',
    city: 'Springdale',
    state: 'AR',
    latitude: '36.180785',
    longitude: '-94.167717',
    timeZoneId: 'America/Chicago',
  },
  '72764': {
    zip: '72764',
    city: 'Springdale',
    state: 'AR',
    latitude: '36.179938',
    longitude: '-94.145696',
    timeZoneId: 'America/Chicago',
  },
  '72765': {
    zip: '72765',
    city: 'Springdale',
    state: 'AR',
    latitude: '36.195888',
    longitude: '-94.102795',
    timeZoneId: 'America/Chicago',
  },
  '72766': {
    zip: '72766',
    city: 'Springdale',
    state: 'AR',
    latitude: '36.185364',
    longitude: '-94.130656',
    timeZoneId: 'America/Chicago',
  },
  '72768': {
    zip: '72768',
    city: 'Sulphur Springs',
    state: 'AR',
    latitude: '36.478438',
    longitude: '-94.462102',
    timeZoneId: 'America/Chicago',
  },
  '72769': {
    zip: '72769',
    city: 'Summers',
    state: 'AR',
    latitude: '36.021731',
    longitude: '-94.497053',
    timeZoneId: 'America/Chicago',
  },
  '72770': {
    zip: '72770',
    city: 'Tontitown',
    state: 'AR',
    latitude: '36.187972',
    longitude: '-94.227786',
    timeZoneId: 'America/Chicago',
  },
  '72773': {
    zip: '72773',
    city: 'Wesley',
    state: 'AR',
    latitude: '35.925152',
    longitude: '-93.907474',
    timeZoneId: 'America/Chicago',
  },
  '72774': {
    zip: '72774',
    city: 'West Fork',
    state: 'AR',
    latitude: '35.928209',
    longitude: '-94.183808',
    timeZoneId: 'America/Chicago',
  },
  '72776': {
    zip: '72776',
    city: 'Witter',
    state: 'AR',
    latitude: '35.93919',
    longitude: '-93.603214',
    timeZoneId: 'America/Chicago',
  },
  '72801': {
    zip: '72801',
    city: 'Russellville',
    state: 'AR',
    latitude: '35.282948',
    longitude: '-93.138428',
    timeZoneId: 'America/Chicago',
  },
  '72802': {
    zip: '72802',
    city: 'Russellville',
    state: 'AR',
    latitude: '35.31758',
    longitude: '-93.091084',
    timeZoneId: 'America/Chicago',
  },
  '72811': {
    zip: '72811',
    city: 'Russellville',
    state: 'AR',
    latitude: '35.278223',
    longitude: '-93.134189',
    timeZoneId: 'America/Chicago',
  },
  '72812': {
    zip: '72812',
    city: 'Russellville',
    state: 'AR',
    latitude: '35.223193',
    longitude: '-93.120324',
    timeZoneId: 'America/Chicago',
  },
  '72820': {
    zip: '72820',
    city: 'Alix',
    state: 'AR',
    latitude: '35.505512',
    longitude: '-93.844411',
    timeZoneId: 'America/Chicago',
  },
  '72821': {
    zip: '72821',
    city: 'Altus',
    state: 'AR',
    latitude: '35.439891',
    longitude: '-93.763349',
    timeZoneId: 'America/Chicago',
  },
  '72823': {
    zip: '72823',
    city: 'Atkins',
    state: 'AR',
    latitude: '35.244334',
    longitude: '-92.936881',
    timeZoneId: 'America/Chicago',
  },
  '72824': {
    zip: '72824',
    city: 'Belleville',
    state: 'AR',
    latitude: '35.10648',
    longitude: '-93.45198',
    timeZoneId: 'America/Chicago',
  },
  '72826': {
    zip: '72826',
    city: 'Blue Mountain',
    state: 'AR',
    latitude: '35.145286',
    longitude: '-93.721189',
    timeZoneId: 'America/Chicago',
  },
  '72827': {
    zip: '72827',
    city: 'Bluffton',
    state: 'AR',
    latitude: '34.895706',
    longitude: '-93.595583',
    timeZoneId: 'America/Chicago',
  },
  '72828': {
    zip: '72828',
    city: 'Briggsville',
    state: 'AR',
    latitude: '34.946389',
    longitude: '-93.405278',
    timeZoneId: 'America/Chicago',
  },
  '72829': {
    zip: '72829',
    city: 'Centerville',
    state: 'AR',
    latitude: '35.168243',
    longitude: '-93.223463',
    timeZoneId: 'America/Chicago',
  },
  '72830': {
    zip: '72830',
    city: 'Clarksville',
    state: 'AR',
    latitude: '35.465085',
    longitude: '-93.471562',
    timeZoneId: 'America/Chicago',
  },
  '72832': {
    zip: '72832',
    city: 'Coal Hill',
    state: 'AR',
    latitude: '35.43513',
    longitude: '-93.671096',
    timeZoneId: 'America/Chicago',
  },
  '72833': {
    zip: '72833',
    city: 'Danville',
    state: 'AR',
    latitude: '35.06796',
    longitude: '-93.373459',
    timeZoneId: 'America/Chicago',
  },
  '72834': {
    zip: '72834',
    city: 'Dardanelle',
    state: 'AR',
    latitude: '35.221232',
    longitude: '-93.160284',
    timeZoneId: 'America/Chicago',
  },
  '72835': {
    zip: '72835',
    city: 'Delaware',
    state: 'AR',
    latitude: '35.239495',
    longitude: '-93.444305',
    timeZoneId: 'America/Chicago',
  },
  '72837': {
    zip: '72837',
    city: 'Dover',
    state: 'AR',
    latitude: '35.371485',
    longitude: '-93.116823',
    timeZoneId: 'America/Chicago',
  },
  '72838': {
    zip: '72838',
    city: 'Gravelly',
    state: 'AR',
    latitude: '34.890905',
    longitude: '-93.674751',
    timeZoneId: 'America/Chicago',
  },
  '72839': {
    zip: '72839',
    city: 'Hagarville',
    state: 'AR',
    latitude: '35.526713',
    longitude: '-93.339763',
    timeZoneId: 'America/Chicago',
  },
  '72840': {
    zip: '72840',
    city: 'Hartman',
    state: 'AR',
    latitude: '35.440758',
    longitude: '-93.662707',
    timeZoneId: 'America/Chicago',
  },
  '72841': {
    zip: '72841',
    city: 'Harvey',
    state: 'AR',
    latitude: '34.862851',
    longitude: '-93.763621',
    timeZoneId: 'America/Chicago',
  },
  '72842': {
    zip: '72842',
    city: 'Havana',
    state: 'AR',
    latitude: '35.096421',
    longitude: '-93.612292',
    timeZoneId: 'America/Chicago',
  },
  '72843': {
    zip: '72843',
    city: 'Hector',
    state: 'AR',
    latitude: '35.509314',
    longitude: '-92.975107',
    timeZoneId: 'America/Chicago',
  },
  '72845': {
    zip: '72845',
    city: 'Knoxville',
    state: 'AR',
    latitude: '35.369083',
    longitude: '-93.364086',
    timeZoneId: 'America/Chicago',
  },
  '72846': {
    zip: '72846',
    city: 'Lamar',
    state: 'AR',
    latitude: '35.438642',
    longitude: '-93.391685',
    timeZoneId: 'America/Chicago',
  },
  '72847': {
    zip: '72847',
    city: 'London',
    state: 'AR',
    latitude: '35.341599',
    longitude: '-93.239244',
    timeZoneId: 'America/Chicago',
  },
  '72851': {
    zip: '72851',
    city: 'New Blaine',
    state: 'AR',
    latitude: '35.330381',
    longitude: '-93.441665',
    timeZoneId: 'America/Chicago',
  },
  '72852': {
    zip: '72852',
    city: 'Oark',
    state: 'AR',
    latitude: '35.714433',
    longitude: '-93.57523',
    timeZoneId: 'America/Chicago',
  },
  '72853': {
    zip: '72853',
    city: 'Ola',
    state: 'AR',
    latitude: '35.04457',
    longitude: '-93.182835',
    timeZoneId: 'America/Chicago',
  },
  '72854': {
    zip: '72854',
    city: 'Ozone',
    state: 'AR',
    latitude: '35.670828',
    longitude: '-93.43045',
    timeZoneId: 'America/Chicago',
  },
  '72855': {
    zip: '72855',
    city: 'Paris',
    state: 'AR',
    latitude: '35.288262',
    longitude: '-93.728734',
    timeZoneId: 'America/Chicago',
  },
  '72856': {
    zip: '72856',
    city: 'Pelsor',
    state: 'AR',
    latitude: '35.690402',
    longitude: '-93.054572',
    timeZoneId: 'America/Chicago',
  },
  '72857': {
    zip: '72857',
    city: 'Plainview',
    state: 'AR',
    latitude: '34.990157',
    longitude: '-93.299478',
    timeZoneId: 'America/Chicago',
  },
  '72858': {
    zip: '72858',
    city: 'Pottsville',
    state: 'AR',
    latitude: '35.213796',
    longitude: '-92.995721',
    timeZoneId: 'America/Chicago',
  },
  '72860': {
    zip: '72860',
    city: 'Rover',
    state: 'AR',
    latitude: '34.932257',
    longitude: '-93.40388',
    timeZoneId: 'America/Chicago',
  },
  '72863': {
    zip: '72863',
    city: 'Scranton',
    state: 'AR',
    latitude: '35.330908',
    longitude: '-93.544797',
    timeZoneId: 'America/Chicago',
  },
  '72865': {
    zip: '72865',
    city: 'Subiaco',
    state: 'AR',
    latitude: '35.327466',
    longitude: '-93.634301',
    timeZoneId: 'America/Chicago',
  },
  '72901': {
    zip: '72901',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.366',
    longitude: '-94.413642',
    timeZoneId: 'America/Chicago',
  },
  '72902': {
    zip: '72902',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.3197',
    longitude: '-94.35318',
    timeZoneId: 'America/Chicago',
  },
  '72903': {
    zip: '72903',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.3455',
    longitude: '-94.37758',
    timeZoneId: 'America/Chicago',
  },
  '72904': {
    zip: '72904',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.40339',
    longitude: '-94.38977',
    timeZoneId: 'America/Chicago',
  },
  '72905': {
    zip: '72905',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.286594',
    longitude: '-94.276239',
    timeZoneId: 'America/Chicago',
  },
  '72906': {
    zip: '72906',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.330629',
    longitude: '-94.396602',
    timeZoneId: 'America/Chicago',
  },
  '72908': {
    zip: '72908',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.2996',
    longitude: '-94.409478',
    timeZoneId: 'America/Chicago',
  },
  '72913': {
    zip: '72913',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.38415',
    longitude: '-94.376166',
    timeZoneId: 'America/Chicago',
  },
  '72914': {
    zip: '72914',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.385872',
    longitude: '-94.399733',
    timeZoneId: 'America/Chicago',
  },
  '72916': {
    zip: '72916',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.326437',
    longitude: '-94.423658',
    timeZoneId: 'America/Chicago',
  },
  '72917': {
    zip: '72917',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.331225',
    longitude: '-94.37681',
    timeZoneId: 'America/Chicago',
  },
  '72918': {
    zip: '72918',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.301729',
    longitude: '-94.408019',
    timeZoneId: 'America/Chicago',
  },
  '72919': {
    zip: '72919',
    city: 'Fort Smith',
    state: 'AR',
    latitude: '35.352237',
    longitude: '-94.353023',
    timeZoneId: 'America/Chicago',
  },
  '72921': {
    zip: '72921',
    city: 'Alma',
    state: 'AR',
    latitude: '35.486757',
    longitude: '-94.227174',
    timeZoneId: 'America/Chicago',
  },
  '72923': {
    zip: '72923',
    city: 'Barling',
    state: 'AR',
    latitude: '35.329328',
    longitude: '-94.306177',
    timeZoneId: 'America/Chicago',
  },
  '72926': {
    zip: '72926',
    city: 'Boles',
    state: 'AR',
    latitude: '34.713356',
    longitude: '-94.028142',
    timeZoneId: 'America/Chicago',
  },
  '72927': {
    zip: '72927',
    city: 'Booneville',
    state: 'AR',
    latitude: '35.137425',
    longitude: '-93.920695',
    timeZoneId: 'America/Chicago',
  },
  '72928': {
    zip: '72928',
    city: 'Branch',
    state: 'AR',
    latitude: '35.287852',
    longitude: '-93.943319',
    timeZoneId: 'America/Chicago',
  },
  '72930': {
    zip: '72930',
    city: 'Cecil',
    state: 'AR',
    latitude: '35.434165',
    longitude: '-93.94488',
    timeZoneId: 'America/Chicago',
  },
  '72932': {
    zip: '72932',
    city: 'Cedarville',
    state: 'AR',
    latitude: '35.606741',
    longitude: '-94.327052',
    timeZoneId: 'America/Chicago',
  },
  '72933': {
    zip: '72933',
    city: 'Charleston',
    state: 'AR',
    latitude: '35.30192',
    longitude: '-94.042905',
    timeZoneId: 'America/Chicago',
  },
  '72934': {
    zip: '72934',
    city: 'Chester',
    state: 'AR',
    latitude: '35.703482',
    longitude: '-94.236518',
    timeZoneId: 'America/Chicago',
  },
  '72935': {
    zip: '72935',
    city: 'Dyer',
    state: 'AR',
    latitude: '35.497374',
    longitude: '-94.188404',
    timeZoneId: 'America/Chicago',
  },
  '72936': {
    zip: '72936',
    city: 'Greenwood',
    state: 'AR',
    latitude: '35.217776',
    longitude: '-94.260958',
    timeZoneId: 'America/Chicago',
  },
  '72937': {
    zip: '72937',
    city: 'Hackett',
    state: 'AR',
    latitude: '35.189791',
    longitude: '-94.413379',
    timeZoneId: 'America/Chicago',
  },
  '72938': {
    zip: '72938',
    city: 'Hartford',
    state: 'AR',
    latitude: '35.00519',
    longitude: '-94.389892',
    timeZoneId: 'America/Chicago',
  },
  '72940': {
    zip: '72940',
    city: 'Huntington',
    state: 'AR',
    latitude: '35.080324',
    longitude: '-94.261638',
    timeZoneId: 'America/Chicago',
  },
  '72941': {
    zip: '72941',
    city: 'Lavaca',
    state: 'AR',
    latitude: '35.336949',
    longitude: '-94.170533',
    timeZoneId: 'America/Chicago',
  },
  '72943': {
    zip: '72943',
    city: 'Magazine',
    state: 'AR',
    latitude: '35.169105',
    longitude: '-93.784677',
    timeZoneId: 'America/Chicago',
  },
  '72944': {
    zip: '72944',
    city: 'Mansfield',
    state: 'AR',
    latitude: '35.017534',
    longitude: '-94.201396',
    timeZoneId: 'America/Chicago',
  },
  '72945': {
    zip: '72945',
    city: 'Midland',
    state: 'AR',
    latitude: '35.106879',
    longitude: '-94.363723',
    timeZoneId: 'America/Chicago',
  },
  '72946': {
    zip: '72946',
    city: 'Mountainburg',
    state: 'AR',
    latitude: '35.629888',
    longitude: '-94.086149',
    timeZoneId: 'America/Chicago',
  },
  '72947': {
    zip: '72947',
    city: 'Mulberry',
    state: 'AR',
    latitude: '35.501887',
    longitude: '-94.056353',
    timeZoneId: 'America/Chicago',
  },
  '72948': {
    zip: '72948',
    city: 'Natural Dam',
    state: 'AR',
    latitude: '35.696396',
    longitude: '-94.42181',
    timeZoneId: 'America/Chicago',
  },
  '72949': {
    zip: '72949',
    city: 'Ozark',
    state: 'AR',
    latitude: '35.490486',
    longitude: '-93.83857',
    timeZoneId: 'America/Chicago',
  },
  '72950': {
    zip: '72950',
    city: 'Parks',
    state: 'AR',
    latitude: '34.792808',
    longitude: '-93.875162',
    timeZoneId: 'America/Chicago',
  },
  '72951': {
    zip: '72951',
    city: 'Ratcliff',
    state: 'AR',
    latitude: '35.25995',
    longitude: '-93.833359',
    timeZoneId: 'America/Chicago',
  },
  '72952': {
    zip: '72952',
    city: 'Rudy',
    state: 'AR',
    latitude: '35.466713',
    longitude: '-94.316469',
    timeZoneId: 'America/Chicago',
  },
  '72955': {
    zip: '72955',
    city: 'Uniontown',
    state: 'AR',
    latitude: '35.585182',
    longitude: '-94.433559',
    timeZoneId: 'America/Chicago',
  },
  '72956': {
    zip: '72956',
    city: 'Van Buren',
    state: 'AR',
    latitude: '35.443498',
    longitude: '-94.34022',
    timeZoneId: 'America/Chicago',
  },
  '72957': {
    zip: '72957',
    city: 'Van Buren',
    state: 'AR',
    latitude: '35.438608',
    longitude: '-94.352594',
    timeZoneId: 'America/Chicago',
  },
  '72958': {
    zip: '72958',
    city: 'Waldron',
    state: 'AR',
    latitude: '34.899469',
    longitude: '-94.094254',
    timeZoneId: 'America/Chicago',
  },
  '72959': {
    zip: '72959',
    city: 'Winslow',
    state: 'AR',
    latitude: '35.81138',
    longitude: '-94.106103',
    timeZoneId: 'America/Chicago',
  },
  '73001': {
    zip: '73001',
    city: 'Albert',
    state: 'OK',
    latitude: '35.239299',
    longitude: '-98.424106',
    timeZoneId: 'America/Chicago',
  },
  '73002': {
    zip: '73002',
    city: 'Alex',
    state: 'OK',
    latitude: '34.916023',
    longitude: '-97.777631',
    timeZoneId: 'America/Chicago',
  },
  '73003': {
    zip: '73003',
    city: 'Edmond',
    state: 'OK',
    latitude: '35.704729',
    longitude: '-97.568619',
    timeZoneId: 'America/Chicago',
  },
  '73004': {
    zip: '73004',
    city: 'Amber',
    state: 'OK',
    latitude: '35.130401',
    longitude: '-97.79012',
    timeZoneId: 'America/Chicago',
  },
  '73005': {
    zip: '73005',
    city: 'Anadarko',
    state: 'OK',
    latitude: '35.072673',
    longitude: '-98.245206',
    timeZoneId: 'America/Chicago',
  },
  '73006': {
    zip: '73006',
    city: 'Apache',
    state: 'OK',
    latitude: '34.896478',
    longitude: '-98.358744',
    timeZoneId: 'America/Chicago',
  },
  '73007': {
    zip: '73007',
    city: 'Arcadia',
    state: 'OK',
    latitude: '35.681792',
    longitude: '-97.330576',
    timeZoneId: 'America/Chicago',
  },
  '73008': {
    zip: '73008',
    city: 'Bethany',
    state: 'OK',
    latitude: '35.50294',
    longitude: '-97.64479',
    timeZoneId: 'America/Chicago',
  },
  '73009': {
    zip: '73009',
    city: 'Binger',
    state: 'OK',
    latitude: '35.311026',
    longitude: '-98.347123',
    timeZoneId: 'America/Chicago',
  },
  '73010': {
    zip: '73010',
    city: 'Blanchard',
    state: 'OK',
    latitude: '35.136801',
    longitude: '-97.648526',
    timeZoneId: 'America/Chicago',
  },
  '73011': {
    zip: '73011',
    city: 'Bradley',
    state: 'OK',
    latitude: '34.858774',
    longitude: '-97.723616',
    timeZoneId: 'America/Chicago',
  },
  '73012': {
    zip: '73012',
    city: 'Edmond',
    state: 'OK',
    latitude: '35.645555',
    longitude: '-97.519322',
    timeZoneId: 'America/Chicago',
  },
  '73013': {
    zip: '73013',
    city: 'Edmond',
    state: 'OK',
    latitude: '35.622482',
    longitude: '-97.470627',
    timeZoneId: 'America/Chicago',
  },
  '73014': {
    zip: '73014',
    city: 'Calumet',
    state: 'OK',
    latitude: '35.603749',
    longitude: '-98.176851',
    timeZoneId: 'America/Chicago',
  },
  '73015': {
    zip: '73015',
    city: 'Carnegie',
    state: 'OK',
    latitude: '35.103495',
    longitude: '-98.599878',
    timeZoneId: 'America/Chicago',
  },
  '73016': {
    zip: '73016',
    city: 'Cashion',
    state: 'OK',
    latitude: '35.799856',
    longitude: '-97.676823',
    timeZoneId: 'America/Chicago',
  },
  '73017': {
    zip: '73017',
    city: 'Cement',
    state: 'OK',
    latitude: '34.934206',
    longitude: '-98.138908',
    timeZoneId: 'America/Chicago',
  },
  '73018': {
    zip: '73018',
    city: 'Chickasha',
    state: 'OK',
    latitude: '35.033843',
    longitude: '-97.947357',
    timeZoneId: 'America/Chicago',
  },
  '73019': {
    zip: '73019',
    city: 'Norman',
    state: 'OK',
    latitude: '35.207241',
    longitude: '-97.446305',
    timeZoneId: 'America/Chicago',
  },
  '73020': {
    zip: '73020',
    city: 'Choctaw',
    state: 'OK',
    latitude: '35.461457',
    longitude: '-97.263145',
    timeZoneId: 'America/Chicago',
  },
  '73021': {
    zip: '73021',
    city: 'Colony',
    state: 'OK',
    latitude: '35.33611',
    longitude: '-98.673629',
    timeZoneId: 'America/Chicago',
  },
  '73022': {
    zip: '73022',
    city: 'Concho',
    state: 'OK',
    latitude: '35.617992',
    longitude: '-97.995354',
    timeZoneId: 'America/Chicago',
  },
  '73023': {
    zip: '73023',
    city: 'Chickasha',
    state: 'OK',
    latitude: '35.042198',
    longitude: '-97.934517',
    timeZoneId: 'America/Chicago',
  },
  '73024': {
    zip: '73024',
    city: 'Corn',
    state: 'OK',
    latitude: '35.377248',
    longitude: '-98.782494',
    timeZoneId: 'America/Chicago',
  },
  '73025': {
    zip: '73025',
    city: 'Edmond',
    state: 'OK',
    latitude: '35.70768',
    longitude: '-97.605383',
    timeZoneId: 'America/Chicago',
  },
  '73026': {
    zip: '73026',
    city: 'Norman',
    state: 'OK',
    latitude: '35.234976',
    longitude: '-97.361332',
    timeZoneId: 'America/Chicago',
  },
  '73027': {
    zip: '73027',
    city: 'Coyle',
    state: 'OK',
    latitude: '35.905758',
    longitude: '-97.219176',
    timeZoneId: 'America/Chicago',
  },
  '73028': {
    zip: '73028',
    city: 'Crescent',
    state: 'OK',
    latitude: '35.952496',
    longitude: '-97.595964',
    timeZoneId: 'America/Chicago',
  },
  '73029': {
    zip: '73029',
    city: 'Cyril',
    state: 'OK',
    latitude: '34.897244',
    longitude: '-98.203676',
    timeZoneId: 'America/Chicago',
  },
  '73030': {
    zip: '73030',
    city: 'Davis',
    state: 'OK',
    latitude: '34.506038',
    longitude: '-97.113104',
    timeZoneId: 'America/Chicago',
  },
  '73031': {
    zip: '73031',
    city: 'Dibble',
    state: 'OK',
    latitude: '35.031916',
    longitude: '-97.62684',
    timeZoneId: 'America/Chicago',
  },
  '73032': {
    zip: '73032',
    city: 'Dougherty',
    state: 'OK',
    latitude: '34.401782',
    longitude: '-97.051471',
    timeZoneId: 'America/Chicago',
  },
  '73033': {
    zip: '73033',
    city: 'Eakly',
    state: 'OK',
    latitude: '35.305581',
    longitude: '-98.557549',
    timeZoneId: 'America/Chicago',
  },
  '73034': {
    zip: '73034',
    city: 'Edmond',
    state: 'OK',
    latitude: '35.697131',
    longitude: '-97.433009',
    timeZoneId: 'America/Chicago',
  },
  '73036': {
    zip: '73036',
    city: 'El Reno',
    state: 'OK',
    latitude: '35.528207',
    longitude: '-97.953685',
    timeZoneId: 'America/Chicago',
  },
  '73038': {
    zip: '73038',
    city: 'Fort Cobb',
    state: 'OK',
    latitude: '35.11962',
    longitude: '-98.43372',
    timeZoneId: 'America/Chicago',
  },
  '73039': {
    zip: '73039',
    city: 'Davis',
    state: 'OK',
    latitude: '34.6149',
    longitude: '-97.4911',
    timeZoneId: 'America/Chicago',
  },
  '73040': {
    zip: '73040',
    city: 'Geary',
    state: 'OK',
    latitude: '35.628001',
    longitude: '-98.31818',
    timeZoneId: 'America/Chicago',
  },
  '73041': {
    zip: '73041',
    city: 'Gotebo',
    state: 'OK',
    latitude: '35.070239',
    longitude: '-98.869719',
    timeZoneId: 'America/Chicago',
  },
  '73042': {
    zip: '73042',
    city: 'Gracemont',
    state: 'OK',
    latitude: '35.187323',
    longitude: '-98.25854',
    timeZoneId: 'America/Chicago',
  },
  '73043': {
    zip: '73043',
    city: 'Greenfield',
    state: 'OK',
    latitude: '35.746969',
    longitude: '-98.387828',
    timeZoneId: 'America/Chicago',
  },
  '73044': {
    zip: '73044',
    city: 'Guthrie',
    state: 'OK',
    latitude: '35.864031',
    longitude: '-97.424514',
    timeZoneId: 'America/Chicago',
  },
  '73045': {
    zip: '73045',
    city: 'Harrah',
    state: 'OK',
    latitude: '35.504304',
    longitude: '-97.150314',
    timeZoneId: 'America/Chicago',
  },
  '73047': {
    zip: '73047',
    city: 'Hinton',
    state: 'OK',
    latitude: '35.473849',
    longitude: '-98.355464',
    timeZoneId: 'America/Chicago',
  },
  '73048': {
    zip: '73048',
    city: 'Hydro',
    state: 'OK',
    latitude: '35.543372',
    longitude: '-98.55841',
    timeZoneId: 'America/Chicago',
  },
  '73049': {
    zip: '73049',
    city: 'Jones',
    state: 'OK',
    latitude: '35.570334',
    longitude: '-97.323953',
    timeZoneId: 'America/Chicago',
  },
  '73050': {
    zip: '73050',
    city: 'Langston',
    state: 'OK',
    latitude: '35.94231',
    longitude: '-97.252196',
    timeZoneId: 'America/Chicago',
  },
  '73051': {
    zip: '73051',
    city: 'Lexington',
    state: 'OK',
    latitude: '35.01633',
    longitude: '-97.333336',
    timeZoneId: 'America/Chicago',
  },
  '73052': {
    zip: '73052',
    city: 'Lindsay',
    state: 'OK',
    latitude: '34.840396',
    longitude: '-97.607974',
    timeZoneId: 'America/Chicago',
  },
  '73053': {
    zip: '73053',
    city: 'Lookeba',
    state: 'OK',
    latitude: '35.370593',
    longitude: '-98.394538',
    timeZoneId: 'America/Chicago',
  },
  '73054': {
    zip: '73054',
    city: 'Luther',
    state: 'OK',
    latitude: '35.654937',
    longitude: '-97.194078',
    timeZoneId: 'America/Chicago',
  },
  '73055': {
    zip: '73055',
    city: 'Marlow',
    state: 'OK',
    latitude: '34.644252',
    longitude: '-97.96145',
    timeZoneId: 'America/Chicago',
  },
  '73056': {
    zip: '73056',
    city: 'Marshall',
    state: 'OK',
    latitude: '36.125463',
    longitude: '-97.59161',
    timeZoneId: 'America/Chicago',
  },
  '73057': {
    zip: '73057',
    city: 'Maysville',
    state: 'OK',
    latitude: '34.818329',
    longitude: '-97.412594',
    timeZoneId: 'America/Chicago',
  },
  '73058': {
    zip: '73058',
    city: 'Meridian',
    state: 'OK',
    latitude: '35.806872',
    longitude: '-97.250642',
    timeZoneId: 'America/Chicago',
  },
  '73059': {
    zip: '73059',
    city: 'Minco',
    state: 'OK',
    latitude: '35.310905',
    longitude: '-97.948522',
    timeZoneId: 'America/Chicago',
  },
  '73061': {
    zip: '73061',
    city: 'Morrison',
    state: 'OK',
    latitude: '36.295834',
    longitude: '-97.006773',
    timeZoneId: 'America/Chicago',
  },
  '73062': {
    zip: '73062',
    city: 'Mountain View',
    state: 'OK',
    latitude: '35.101135',
    longitude: '-98.753299',
    timeZoneId: 'America/Chicago',
  },
  '73063': {
    zip: '73063',
    city: 'Mulhall',
    state: 'OK',
    latitude: '36.05346',
    longitude: '-97.41923',
    timeZoneId: 'America/Chicago',
  },
  '73064': {
    zip: '73064',
    city: 'Mustang',
    state: 'OK',
    latitude: '35.390347',
    longitude: '-97.728864',
    timeZoneId: 'America/Chicago',
  },
  '73065': {
    zip: '73065',
    city: 'Newcastle',
    state: 'OK',
    latitude: '35.249529',
    longitude: '-97.626363',
    timeZoneId: 'America/Chicago',
  },
  '73066': {
    zip: '73066',
    city: 'Nicoma Park',
    state: 'OK',
    latitude: '35.495083',
    longitude: '-97.32496',
    timeZoneId: 'America/Chicago',
  },
  '73067': {
    zip: '73067',
    city: 'Ninnekah',
    state: 'OK',
    latitude: '34.945945',
    longitude: '-97.916959',
    timeZoneId: 'America/Chicago',
  },
  '73068': {
    zip: '73068',
    city: 'Noble',
    state: 'OK',
    latitude: '35.143049',
    longitude: '-97.366525',
    timeZoneId: 'America/Chicago',
  },
  '73069': {
    zip: '73069',
    city: 'Norman',
    state: 'OK',
    latitude: '35.252751',
    longitude: '-97.460386',
    timeZoneId: 'America/Chicago',
  },
  '73070': {
    zip: '73070',
    city: 'Norman',
    state: 'OK',
    latitude: '35.222428',
    longitude: '-97.368391',
    timeZoneId: 'America/Chicago',
  },
  '73071': {
    zip: '73071',
    city: 'Norman',
    state: 'OK',
    latitude: '35.244565',
    longitude: '-97.415094',
    timeZoneId: 'America/Chicago',
  },
  '73072': {
    zip: '73072',
    city: 'Norman',
    state: 'OK',
    latitude: '35.229803',
    longitude: '-97.504824',
    timeZoneId: 'America/Chicago',
  },
  '73073': {
    zip: '73073',
    city: 'Orlando',
    state: 'OK',
    latitude: '36.132163',
    longitude: '-97.439865',
    timeZoneId: 'America/Chicago',
  },
  '73074': {
    zip: '73074',
    city: 'Paoli',
    state: 'OK',
    latitude: '34.828366',
    longitude: '-97.259896',
    timeZoneId: 'America/Chicago',
  },
  '73075': {
    zip: '73075',
    city: 'Pauls Valley',
    state: 'OK',
    latitude: '34.759938',
    longitude: '-97.206699',
    timeZoneId: 'America/Chicago',
  },
  '73077': {
    zip: '73077',
    city: 'Perry',
    state: 'OK',
    latitude: '36.292043',
    longitude: '-97.287861',
    timeZoneId: 'America/Chicago',
  },
  '73078': {
    zip: '73078',
    city: 'Piedmont',
    state: 'OK',
    latitude: '35.660181',
    longitude: '-97.757489',
    timeZoneId: 'America/Chicago',
  },
  '73079': {
    zip: '73079',
    city: 'Pocasset',
    state: 'OK',
    latitude: '35.159277',
    longitude: '-98.001409',
    timeZoneId: 'America/Chicago',
  },
  '73080': {
    zip: '73080',
    city: 'Purcell',
    state: 'OK',
    latitude: '35.017904',
    longitude: '-97.366998',
    timeZoneId: 'America/Chicago',
  },
  '73082': {
    zip: '73082',
    city: 'Rush Springs',
    state: 'OK',
    latitude: '34.784317',
    longitude: '-97.957507',
    timeZoneId: 'America/Chicago',
  },
  '73083': {
    zip: '73083',
    city: 'Edmond',
    state: 'OK',
    latitude: '35.736726',
    longitude: '-97.480951',
    timeZoneId: 'America/Chicago',
  },
  '73084': {
    zip: '73084',
    city: 'Spencer',
    state: 'OK',
    latitude: '35.515551',
    longitude: '-97.346404',
    timeZoneId: 'America/Chicago',
  },
  '73085': {
    zip: '73085',
    city: 'Yukon',
    state: 'OK',
    latitude: '35.488148',
    longitude: '-97.736367',
    timeZoneId: 'America/Chicago',
  },
  '73086': {
    zip: '73086',
    city: 'Sulphur',
    state: 'OK',
    latitude: '34.507938',
    longitude: '-96.973516',
    timeZoneId: 'America/Chicago',
  },
  '73089': {
    zip: '73089',
    city: 'Tuttle',
    state: 'OK',
    latitude: '35.289877',
    longitude: '-97.78178',
    timeZoneId: 'America/Chicago',
  },
  '73090': {
    zip: '73090',
    city: 'Union City',
    state: 'OK',
    latitude: '35.395797',
    longitude: '-97.93531',
    timeZoneId: 'America/Chicago',
  },
  '73092': {
    zip: '73092',
    city: 'Verden',
    state: 'OK',
    latitude: '35.074099',
    longitude: '-98.0562',
    timeZoneId: 'America/Chicago',
  },
  '73093': {
    zip: '73093',
    city: 'Washington',
    state: 'OK',
    latitude: '35.128596',
    longitude: '-97.486449',
    timeZoneId: 'America/Chicago',
  },
  '73095': {
    zip: '73095',
    city: 'Wayne',
    state: 'OK',
    latitude: '34.918055',
    longitude: '-97.31453',
    timeZoneId: 'America/Chicago',
  },
  '73096': {
    zip: '73096',
    city: 'Weatherford',
    state: 'OK',
    latitude: '35.536765',
    longitude: '-98.696109',
    timeZoneId: 'America/Chicago',
  },
  '73097': {
    zip: '73097',
    city: 'Wheatland',
    state: 'OK',
    latitude: '35.397374',
    longitude: '-97.652196',
    timeZoneId: 'America/Chicago',
  },
  '73098': {
    zip: '73098',
    city: 'Wynnewood',
    state: 'OK',
    latitude: '34.64137',
    longitude: '-97.165867',
    timeZoneId: 'America/Chicago',
  },
  '73099': {
    zip: '73099',
    city: 'Yukon',
    state: 'OK',
    latitude: '35.496256',
    longitude: '-97.732383',
    timeZoneId: 'America/Chicago',
  },
  '73101': {
    zip: '73101',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.490436',
    longitude: '-97.560145',
    timeZoneId: 'America/Chicago',
  },
  '73102': {
    zip: '73102',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.471927',
    longitude: '-97.518257',
    timeZoneId: 'America/Chicago',
  },
  '73103': {
    zip: '73103',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.48899',
    longitude: '-97.51895',
    timeZoneId: 'America/Chicago',
  },
  '73104': {
    zip: '73104',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.47459',
    longitude: '-97.505034',
    timeZoneId: 'America/Chicago',
  },
  '73105': {
    zip: '73105',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.512654',
    longitude: '-97.504104',
    timeZoneId: 'America/Chicago',
  },
  '73106': {
    zip: '73106',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.483548',
    longitude: '-97.534998',
    timeZoneId: 'America/Chicago',
  },
  '73107': {
    zip: '73107',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.483265',
    longitude: '-97.577589',
    timeZoneId: 'America/Chicago',
  },
  '73108': {
    zip: '73108',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.447049',
    longitude: '-97.564806',
    timeZoneId: 'America/Chicago',
  },
  '73109': {
    zip: '73109',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.43377',
    longitude: '-97.518393',
    timeZoneId: 'America/Chicago',
  },
  '73110': {
    zip: '73110',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.460687',
    longitude: '-97.397356',
    timeZoneId: 'America/Chicago',
  },
  '73111': {
    zip: '73111',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.508822',
    longitude: '-97.47258',
    timeZoneId: 'America/Chicago',
  },
  '73112': {
    zip: '73112',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.519414',
    longitude: '-97.5753',
    timeZoneId: 'America/Chicago',
  },
  '73113': {
    zip: '73113',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.564075',
    longitude: '-97.501487',
    timeZoneId: 'America/Chicago',
  },
  '73114': {
    zip: '73114',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.566667',
    longitude: '-97.522044',
    timeZoneId: 'America/Chicago',
  },
  '73115': {
    zip: '73115',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.43866',
    longitude: '-97.439577',
    timeZoneId: 'America/Chicago',
  },
  '73116': {
    zip: '73116',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.540626',
    longitude: '-97.556678',
    timeZoneId: 'America/Chicago',
  },
  '73117': {
    zip: '73117',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.48006',
    longitude: '-97.473067',
    timeZoneId: 'America/Chicago',
  },
  '73118': {
    zip: '73118',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.515114',
    longitude: '-97.526477',
    timeZoneId: 'America/Chicago',
  },
  '73119': {
    zip: '73119',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.420064',
    longitude: '-97.570604',
    timeZoneId: 'America/Chicago',
  },
  '73120': {
    zip: '73120',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.584064',
    longitude: '-97.574272',
    timeZoneId: 'America/Chicago',
  },
  '73121': {
    zip: '73121',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.509891',
    longitude: '-97.446247',
    timeZoneId: 'America/Chicago',
  },
  '73122': {
    zip: '73122',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.522969',
    longitude: '-97.61403',
    timeZoneId: 'America/Chicago',
  },
  '73123': {
    zip: '73123',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.537598',
    longitude: '-97.621042',
    timeZoneId: 'America/Chicago',
  },
  '73124': {
    zip: '73124',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.431919',
    longitude: '-97.525309',
    timeZoneId: 'America/Chicago',
  },
  '73125': {
    zip: '73125',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.456217',
    longitude: '-97.528691',
    timeZoneId: 'America/Chicago',
  },
  '73126': {
    zip: '73126',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.457182',
    longitude: '-97.490781',
    timeZoneId: 'America/Chicago',
  },
  '73127': {
    zip: '73127',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.48436',
    longitude: '-97.631411',
    timeZoneId: 'America/Chicago',
  },
  '73128': {
    zip: '73128',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.451515',
    longitude: '-97.620639',
    timeZoneId: 'America/Chicago',
  },
  '73129': {
    zip: '73129',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.429134',
    longitude: '-97.486641',
    timeZoneId: 'America/Chicago',
  },
  '73130': {
    zip: '73130',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.4674',
    longitude: '-97.3365',
    timeZoneId: 'America/Chicago',
  },
  '73131': {
    zip: '73131',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.578851',
    longitude: '-97.470162',
    timeZoneId: 'America/Chicago',
  },
  '73132': {
    zip: '73132',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.545017',
    longitude: '-97.635324',
    timeZoneId: 'America/Chicago',
  },
  '73134': {
    zip: '73134',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.616422',
    longitude: '-97.571744',
    timeZoneId: 'America/Chicago',
  },
  '73135': {
    zip: '73135',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.391618',
    longitude: '-97.423072',
    timeZoneId: 'America/Chicago',
  },
  '73136': {
    zip: '73136',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.589704',
    longitude: '-97.456239',
    timeZoneId: 'America/Chicago',
  },
  '73137': {
    zip: '73137',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.481011',
    longitude: '-97.633607',
    timeZoneId: 'America/Chicago',
  },
  '73139': {
    zip: '73139',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.393759',
    longitude: '-97.524997',
    timeZoneId: 'America/Chicago',
  },
  '73140': {
    zip: '73140',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.464047',
    longitude: '-97.383601',
    timeZoneId: 'America/Chicago',
  },
  '73141': {
    zip: '73141',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.510127',
    longitude: '-97.394891',
    timeZoneId: 'America/Chicago',
  },
  '73142': {
    zip: '73142',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.606201',
    longitude: '-97.641926',
    timeZoneId: 'America/Chicago',
  },
  '73143': {
    zip: '73143',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.435074',
    longitude: '-97.5267',
    timeZoneId: 'America/Chicago',
  },
  '73144': {
    zip: '73144',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.411174',
    longitude: '-97.55698',
    timeZoneId: 'America/Chicago',
  },
  '73145': {
    zip: '73145',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.415801',
    longitude: '-97.392307',
    timeZoneId: 'America/Chicago',
  },
  '73146': {
    zip: '73146',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.494268',
    longitude: '-97.530684',
    timeZoneId: 'America/Chicago',
  },
  '73147': {
    zip: '73147',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.483574',
    longitude: '-97.582013',
    timeZoneId: 'America/Chicago',
  },
  '73148': {
    zip: '73148',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.452945',
    longitude: '-97.551956',
    timeZoneId: 'America/Chicago',
  },
  '73149': {
    zip: '73149',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.392069',
    longitude: '-97.490844',
    timeZoneId: 'America/Chicago',
  },
  '73150': {
    zip: '73150',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.403293',
    longitude: '-97.341978',
    timeZoneId: 'America/Chicago',
  },
  '73151': {
    zip: '73151',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.567203',
    longitude: '-97.404773',
    timeZoneId: 'America/Chicago',
  },
  '73152': {
    zip: '73152',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.51868',
    longitude: '-97.505079',
    timeZoneId: 'America/Chicago',
  },
  '73153': {
    zip: '73153',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.335006',
    longitude: '-97.492751',
    timeZoneId: 'America/Chicago',
  },
  '73154': {
    zip: '73154',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.521987',
    longitude: '-97.52756',
    timeZoneId: 'America/Chicago',
  },
  '73155': {
    zip: '73155',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.418558',
    longitude: '-97.435219',
    timeZoneId: 'America/Chicago',
  },
  '73156': {
    zip: '73156',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.566986',
    longitude: '-97.550405',
    timeZoneId: 'America/Chicago',
  },
  '73157': {
    zip: '73157',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.42329',
    longitude: '-97.569614',
    timeZoneId: 'America/Chicago',
  },
  '73159': {
    zip: '73159',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.39069',
    longitude: '-97.55387',
    timeZoneId: 'America/Chicago',
  },
  '73160': {
    zip: '73160',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.38116',
    longitude: '-97.47588',
    timeZoneId: 'America/Chicago',
  },
  '73162': {
    zip: '73162',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.579721',
    longitude: '-97.641491',
    timeZoneId: 'America/Chicago',
  },
  '73163': {
    zip: '73163',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.472',
    longitude: '-97.520348',
    timeZoneId: 'America/Chicago',
  },
  '73164': {
    zip: '73164',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.472',
    longitude: '-97.520348',
    timeZoneId: 'America/Chicago',
  },
  '73165': {
    zip: '73165',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.3343',
    longitude: '-97.34586',
    timeZoneId: 'America/Chicago',
  },
  '73167': {
    zip: '73167',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.460945',
    longitude: '-97.520644',
    timeZoneId: 'America/Chicago',
  },
  '73169': {
    zip: '73169',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.394847',
    longitude: '-97.644712',
    timeZoneId: 'America/Chicago',
  },
  '73170': {
    zip: '73170',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.3733',
    longitude: '-97.56246',
    timeZoneId: 'America/Chicago',
  },
  '73172': {
    zip: '73172',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.419424',
    longitude: '-97.569451',
    timeZoneId: 'America/Chicago',
  },
  '73173': {
    zip: '73173',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.339186',
    longitude: '-97.618675',
    timeZoneId: 'America/Chicago',
  },
  '73178': {
    zip: '73178',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.53549',
    longitude: '-97.567572',
    timeZoneId: 'America/Chicago',
  },
  '73179': {
    zip: '73179',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.425455',
    longitude: '-97.641765',
    timeZoneId: 'America/Chicago',
  },
  '73184': {
    zip: '73184',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.620834',
    longitude: '-97.571442',
    timeZoneId: 'America/Chicago',
  },
  '73189': {
    zip: '73189',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.395812',
    longitude: '-97.578752',
    timeZoneId: 'America/Chicago',
  },
  '73190': {
    zip: '73190',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.458463',
    longitude: '-97.516594',
    timeZoneId: 'America/Chicago',
  },
  '73194': {
    zip: '73194',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.517415',
    longitude: '-97.503803',
    timeZoneId: 'America/Chicago',
  },
  '73195': {
    zip: '73195',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.564526',
    longitude: '-97.518216',
    timeZoneId: 'America/Chicago',
  },
  '73196': {
    zip: '73196',
    city: 'Oklahoma City',
    state: 'OK',
    latitude: '35.460523',
    longitude: '-97.398837',
    timeZoneId: 'America/Chicago',
  },
  '73301': {
    zip: '73301',
    city: 'Austin',
    state: 'TX',
    latitude: '30.220228',
    longitude: '-97.749942',
    timeZoneId: 'America/Chicago',
  },
  '73344': {
    zip: '73344',
    city: 'Austin',
    state: 'TX',
    latitude: '30.176174',
    longitude: '-97.729218',
    timeZoneId: 'America/Chicago',
  },
  '73401': {
    zip: '73401',
    city: 'Ardmore',
    state: 'OK',
    latitude: '34.33249',
    longitude: '-97.33532',
    timeZoneId: 'America/Chicago',
  },
  '73402': {
    zip: '73402',
    city: 'Ardmore',
    state: 'OK',
    latitude: '34.166953',
    longitude: '-97.105573',
    timeZoneId: 'America/Chicago',
  },
  '73403': {
    zip: '73403',
    city: 'Ardmore',
    state: 'OK',
    latitude: '34.172863',
    longitude: '-97.144409',
    timeZoneId: 'America/Chicago',
  },
  '73425': {
    zip: '73425',
    city: 'Countyline',
    state: 'OK',
    latitude: '34.447952',
    longitude: '-97.560974',
    timeZoneId: 'America/Chicago',
  },
  '73430': {
    zip: '73430',
    city: 'Burneyville',
    state: 'OK',
    latitude: '33.924514',
    longitude: '-97.31276',
    timeZoneId: 'America/Chicago',
  },
  '73432': {
    zip: '73432',
    city: 'Coleman',
    state: 'OK',
    latitude: '34.283307',
    longitude: '-96.485701',
    timeZoneId: 'America/Chicago',
  },
  '73433': {
    zip: '73433',
    city: 'Elmore City',
    state: 'OK',
    latitude: '34.615413',
    longitude: '-97.404541',
    timeZoneId: 'America/Chicago',
  },
  '73434': {
    zip: '73434',
    city: 'Foster',
    state: 'OK',
    latitude: '34.638177',
    longitude: '-97.5581',
    timeZoneId: 'America/Chicago',
  },
  '73435': {
    zip: '73435',
    city: 'Fox',
    state: 'OK',
    latitude: '34.365777',
    longitude: '-97.49005',
    timeZoneId: 'America/Chicago',
  },
  '73436': {
    zip: '73436',
    city: 'Gene Autry',
    state: 'OK',
    latitude: '34.291154',
    longitude: '-97.036168',
    timeZoneId: 'America/Chicago',
  },
  '73437': {
    zip: '73437',
    city: 'Graham',
    state: 'OK',
    latitude: '34.322331',
    longitude: '-97.502472',
    timeZoneId: 'America/Chicago',
  },
  '73438': {
    zip: '73438',
    city: 'Healdton',
    state: 'OK',
    latitude: '34.228519',
    longitude: '-97.484391',
    timeZoneId: 'America/Chicago',
  },
  '73439': {
    zip: '73439',
    city: 'Kingston',
    state: 'OK',
    latitude: '33.988351',
    longitude: '-96.707799',
    timeZoneId: 'America/Chicago',
  },
  '73440': {
    zip: '73440',
    city: 'Lebanon',
    state: 'OK',
    latitude: '33.964525',
    longitude: '-96.875097',
    timeZoneId: 'America/Chicago',
  },
  '73441': {
    zip: '73441',
    city: 'Leon',
    state: 'OK',
    latitude: '33.950511',
    longitude: '-97.461153',
    timeZoneId: 'America/Chicago',
  },
  '73442': {
    zip: '73442',
    city: 'Loco',
    state: 'OK',
    latitude: '34.323988',
    longitude: '-97.688378',
    timeZoneId: 'America/Chicago',
  },
  '73443': {
    zip: '73443',
    city: 'Lone Grove',
    state: 'OK',
    latitude: '34.175811',
    longitude: '-97.261787',
    timeZoneId: 'America/Chicago',
  },
  '73444': {
    zip: '73444',
    city: 'Hennepin',
    state: 'OK',
    latitude: '34.473917',
    longitude: '-97.411006',
    timeZoneId: 'America/Chicago',
  },
  '73446': {
    zip: '73446',
    city: 'Madill',
    state: 'OK',
    latitude: '34.085514',
    longitude: '-96.773997',
    timeZoneId: 'America/Chicago',
  },
  '73447': {
    zip: '73447',
    city: 'Mannsville',
    state: 'OK',
    latitude: '34.198181',
    longitude: '-96.79068',
    timeZoneId: 'America/Chicago',
  },
  '73448': {
    zip: '73448',
    city: 'Marietta',
    state: 'OK',
    latitude: '33.938346',
    longitude: '-97.123259',
    timeZoneId: 'America/Chicago',
  },
  '73449': {
    zip: '73449',
    city: 'Mead',
    state: 'OK',
    latitude: '33.943247',
    longitude: '-96.404271',
    timeZoneId: 'America/Chicago',
  },
  '73450': {
    zip: '73450',
    city: 'Milburn',
    state: 'OK',
    latitude: '34.175396',
    longitude: '-96.528999',
    timeZoneId: 'America/Chicago',
  },
  '73453': {
    zip: '73453',
    city: 'Overbrook',
    state: 'OK',
    latitude: '34.047702',
    longitude: '-97.119298',
    timeZoneId: 'America/Chicago',
  },
  '73455': {
    zip: '73455',
    city: 'Ravia',
    state: 'OK',
    latitude: '34.243035',
    longitude: '-96.749987',
    timeZoneId: 'America/Chicago',
  },
  '73456': {
    zip: '73456',
    city: 'Ringling',
    state: 'OK',
    latitude: '34.179287',
    longitude: '-97.61072',
    timeZoneId: 'America/Chicago',
  },
  '73458': {
    zip: '73458',
    city: 'Springer',
    state: 'OK',
    latitude: '34.314982',
    longitude: '-97.129095',
    timeZoneId: 'America/Chicago',
  },
  '73459': {
    zip: '73459',
    city: 'Thackerville',
    state: 'OK',
    latitude: '33.789735',
    longitude: '-97.135258',
    timeZoneId: 'America/Chicago',
  },
  '73460': {
    zip: '73460',
    city: 'Tishomingo',
    state: 'OK',
    latitude: '34.232285',
    longitude: '-96.673345',
    timeZoneId: 'America/Chicago',
  },
  '73461': {
    zip: '73461',
    city: 'Wapanucka',
    state: 'OK',
    latitude: '34.38879',
    longitude: '-96.501557',
    timeZoneId: 'America/Chicago',
  },
  '73463': {
    zip: '73463',
    city: 'Wilson',
    state: 'OK',
    latitude: '34.161452',
    longitude: '-97.425765',
    timeZoneId: 'America/Chicago',
  },
  '73481': {
    zip: '73481',
    city: 'Ratliff City',
    state: 'OK',
    latitude: '34.409849',
    longitude: '-97.511917',
    timeZoneId: 'America/Chicago',
  },
  '73487': {
    zip: '73487',
    city: 'Tatums',
    state: 'OK',
    latitude: '34.479887',
    longitude: '-97.459668',
    timeZoneId: 'America/Chicago',
  },
  '73488': {
    zip: '73488',
    city: 'Tussy',
    state: 'OK',
    latitude: '34.482407',
    longitude: '-97.534246',
    timeZoneId: 'America/Chicago',
  },
  '73491': {
    zip: '73491',
    city: 'Velma',
    state: 'OK',
    latitude: '34.461264',
    longitude: '-97.671275',
    timeZoneId: 'America/Chicago',
  },
  '73501': {
    zip: '73501',
    city: 'Lawton',
    state: 'OK',
    latitude: '34.61124',
    longitude: '-98.39599',
    timeZoneId: 'America/Chicago',
  },
  '73502': {
    zip: '73502',
    city: 'Lawton',
    state: 'OK',
    latitude: '34.600793',
    longitude: '-98.393741',
    timeZoneId: 'America/Chicago',
  },
  '73503': {
    zip: '73503',
    city: 'Fort Sill',
    state: 'OK',
    latitude: '34.66356',
    longitude: '-98.44296',
    timeZoneId: 'America/Chicago',
  },
  '73505': {
    zip: '73505',
    city: 'Lawton',
    state: 'OK',
    latitude: '34.616916',
    longitude: '-98.457365',
    timeZoneId: 'America/Chicago',
  },
  '73506': {
    zip: '73506',
    city: 'Lawton',
    state: 'OK',
    latitude: '34.621221',
    longitude: '-98.459587',
    timeZoneId: 'America/Chicago',
  },
  '73507': {
    zip: '73507',
    city: 'Lawton',
    state: 'OK',
    latitude: '34.621344',
    longitude: '-98.380949',
    timeZoneId: 'America/Chicago',
  },
  '73520': {
    zip: '73520',
    city: 'Addington',
    state: 'OK',
    latitude: '34.241461',
    longitude: '-97.96855',
    timeZoneId: 'America/Chicago',
  },
  '73521': {
    zip: '73521',
    city: 'Altus',
    state: 'OK',
    latitude: '34.642895',
    longitude: '-99.327035',
    timeZoneId: 'America/Chicago',
  },
  '73522': {
    zip: '73522',
    city: 'Altus',
    state: 'OK',
    latitude: '34.647815',
    longitude: '-99.336331',
    timeZoneId: 'America/Chicago',
  },
  '73523': {
    zip: '73523',
    city: 'Altus AFB',
    state: 'OK',
    latitude: '34.682908',
    longitude: '-99.344706',
    timeZoneId: 'America/Chicago',
  },
  '73526': {
    zip: '73526',
    city: 'Blair',
    state: 'OK',
    latitude: '34.777382',
    longitude: '-99.331725',
    timeZoneId: 'America/Chicago',
  },
  '73527': {
    zip: '73527',
    city: 'Cache',
    state: 'OK',
    latitude: '34.631259',
    longitude: '-98.62551',
    timeZoneId: 'America/Chicago',
  },
  '73528': {
    zip: '73528',
    city: 'Chattanooga',
    state: 'OK',
    latitude: '34.423232',
    longitude: '-98.646524',
    timeZoneId: 'America/Chicago',
  },
  '73529': {
    zip: '73529',
    city: 'Comanche',
    state: 'OK',
    latitude: '34.366492',
    longitude: '-97.970381',
    timeZoneId: 'America/Chicago',
  },
  '73530': {
    zip: '73530',
    city: 'Davidson',
    state: 'OK',
    latitude: '34.241213',
    longitude: '-99.079574',
    timeZoneId: 'America/Chicago',
  },
  '73531': {
    zip: '73531',
    city: 'Devol',
    state: 'OK',
    latitude: '34.21652',
    longitude: '-98.565803',
    timeZoneId: 'America/Chicago',
  },
  '73532': {
    zip: '73532',
    city: 'Duke',
    state: 'OK',
    latitude: '34.661943',
    longitude: '-99.569012',
    timeZoneId: 'America/Chicago',
  },
  '73533': {
    zip: '73533',
    city: 'Duncan',
    state: 'OK',
    latitude: '34.513258',
    longitude: '-97.960374',
    timeZoneId: 'America/Chicago',
  },
  '73534': {
    zip: '73534',
    city: 'Duncan',
    state: 'OK',
    latitude: '34.508526',
    longitude: '-97.965888',
    timeZoneId: 'America/Chicago',
  },
  '73536': {
    zip: '73536',
    city: 'Duncan',
    state: 'OK',
    latitude: '34.449703',
    longitude: '-97.832209',
    timeZoneId: 'America/Chicago',
  },
  '73537': {
    zip: '73537',
    city: 'Eldorado',
    state: 'OK',
    latitude: '34.472637',
    longitude: '-99.647878',
    timeZoneId: 'America/Chicago',
  },
  '73538': {
    zip: '73538',
    city: 'Elgin',
    state: 'OK',
    latitude: '34.723178',
    longitude: '-98.260077',
    timeZoneId: 'America/Chicago',
  },
  '73539': {
    zip: '73539',
    city: 'Elmer',
    state: 'OK',
    latitude: '34.506248',
    longitude: '-99.295805',
    timeZoneId: 'America/Chicago',
  },
  '73540': {
    zip: '73540',
    city: 'Faxon',
    state: 'OK',
    latitude: '34.464413',
    longitude: '-98.545707',
    timeZoneId: 'America/Chicago',
  },
  '73541': {
    zip: '73541',
    city: 'Fletcher',
    state: 'OK',
    latitude: '34.822949',
    longitude: '-98.234952',
    timeZoneId: 'America/Chicago',
  },
  '73542': {
    zip: '73542',
    city: 'Frederick',
    state: 'OK',
    latitude: '34.393428',
    longitude: '-99.010477',
    timeZoneId: 'America/Chicago',
  },
  '73543': {
    zip: '73543',
    city: 'Geronimo',
    state: 'OK',
    latitude: '34.481839',
    longitude: '-98.379366',
    timeZoneId: 'America/Chicago',
  },
  '73544': {
    zip: '73544',
    city: 'Gould',
    state: 'OK',
    latitude: '34.669452',
    longitude: '-99.782748',
    timeZoneId: 'America/Chicago',
  },
  '73546': {
    zip: '73546',
    city: 'Grandfield',
    state: 'OK',
    latitude: '34.22971',
    longitude: '-98.690114',
    timeZoneId: 'America/Chicago',
  },
  '73547': {
    zip: '73547',
    city: 'Granite',
    state: 'OK',
    latitude: '34.960595',
    longitude: '-99.380775',
    timeZoneId: 'America/Chicago',
  },
  '73548': {
    zip: '73548',
    city: 'Hastings',
    state: 'OK',
    latitude: '34.218401',
    longitude: '-98.105249',
    timeZoneId: 'America/Chicago',
  },
  '73549': {
    zip: '73549',
    city: 'Headrick',
    state: 'OK',
    latitude: '34.627655',
    longitude: '-99.139731',
    timeZoneId: 'America/Chicago',
  },
  '73550': {
    zip: '73550',
    city: 'Hollis',
    state: 'OK',
    latitude: '34.688629',
    longitude: '-99.915553',
    timeZoneId: 'America/Chicago',
  },
  '73551': {
    zip: '73551',
    city: 'Hollister',
    state: 'OK',
    latitude: '34.362803',
    longitude: '-98.885749',
    timeZoneId: 'America/Chicago',
  },
  '73552': {
    zip: '73552',
    city: 'Indiahoma',
    state: 'OK',
    latitude: '34.619739',
    longitude: '-98.754144',
    timeZoneId: 'America/Chicago',
  },
  '73553': {
    zip: '73553',
    city: 'Loveland',
    state: 'OK',
    latitude: '34.366219',
    longitude: '-98.73761',
    timeZoneId: 'America/Chicago',
  },
  '73554': {
    zip: '73554',
    city: 'Mangum',
    state: 'OK',
    latitude: '34.876209',
    longitude: '-99.504161',
    timeZoneId: 'America/Chicago',
  },
  '73555': {
    zip: '73555',
    city: 'Manitou',
    state: 'OK',
    latitude: '34.507307',
    longitude: '-98.978423',
    timeZoneId: 'America/Chicago',
  },
  '73556': {
    zip: '73556',
    city: 'Martha',
    state: 'OK',
    latitude: '34.722941',
    longitude: '-99.386429',
    timeZoneId: 'America/Chicago',
  },
  '73557': {
    zip: '73557',
    city: 'Medicine Park',
    state: 'OK',
    latitude: '34.727409',
    longitude: '-98.462061',
    timeZoneId: 'America/Chicago',
  },
  '73558': {
    zip: '73558',
    city: 'Meers',
    state: 'OK',
    latitude: '34.782945',
    longitude: '-98.577817',
    timeZoneId: 'America/Chicago',
  },
  '73559': {
    zip: '73559',
    city: 'Mountain Park',
    state: 'OK',
    latitude: '34.699521',
    longitude: '-98.94754',
    timeZoneId: 'America/Chicago',
  },
  '73560': {
    zip: '73560',
    city: 'Olustee',
    state: 'OK',
    latitude: '34.547089',
    longitude: '-99.423379',
    timeZoneId: 'America/Chicago',
  },
  '73561': {
    zip: '73561',
    city: 'Oscar',
    state: 'OK',
    latitude: '33.959973',
    longitude: '-97.761343',
    timeZoneId: 'America/Chicago',
  },
  '73562': {
    zip: '73562',
    city: 'Randlett',
    state: 'OK',
    latitude: '34.177916',
    longitude: '-98.434828',
    timeZoneId: 'America/Chicago',
  },
  '73564': {
    zip: '73564',
    city: 'Roosevelt',
    state: 'OK',
    latitude: '34.850654',
    longitude: '-99.024694',
    timeZoneId: 'America/Chicago',
  },
  '73565': {
    zip: '73565',
    city: 'Ryan',
    state: 'OK',
    latitude: '34.031354',
    longitude: '-97.874536',
    timeZoneId: 'America/Chicago',
  },
  '73566': {
    zip: '73566',
    city: 'Snyder',
    state: 'OK',
    latitude: '34.657358',
    longitude: '-98.952847',
    timeZoneId: 'America/Chicago',
  },
  '73567': {
    zip: '73567',
    city: 'Sterling',
    state: 'OK',
    latitude: '34.752387',
    longitude: '-98.160528',
    timeZoneId: 'America/Chicago',
  },
  '73568': {
    zip: '73568',
    city: 'Temple',
    state: 'OK',
    latitude: '34.274774',
    longitude: '-98.231968',
    timeZoneId: 'America/Chicago',
  },
  '73569': {
    zip: '73569',
    city: 'Terral',
    state: 'OK',
    latitude: '34.02199',
    longitude: '-97.66448',
    timeZoneId: 'America/Chicago',
  },
  '73570': {
    zip: '73570',
    city: 'Tipton',
    state: 'OK',
    latitude: '34.50036',
    longitude: '-99.142663',
    timeZoneId: 'America/Chicago',
  },
  '73571': {
    zip: '73571',
    city: 'Vinson',
    state: 'OK',
    latitude: '34.886963',
    longitude: '-99.813852',
    timeZoneId: 'America/Chicago',
  },
  '73572': {
    zip: '73572',
    city: 'Walters',
    state: 'OK',
    latitude: '34.350544',
    longitude: '-98.306032',
    timeZoneId: 'America/Chicago',
  },
  '73573': {
    zip: '73573',
    city: 'Waurika',
    state: 'OK',
    latitude: '34.167818',
    longitude: '-97.995767',
    timeZoneId: 'America/Chicago',
  },
  '73601': {
    zip: '73601',
    city: 'Clinton',
    state: 'OK',
    latitude: '35.515594',
    longitude: '-98.97114',
    timeZoneId: 'America/Chicago',
  },
  '73620': {
    zip: '73620',
    city: 'Arapaho',
    state: 'OK',
    latitude: '35.580064',
    longitude: '-98.962591',
    timeZoneId: 'America/Chicago',
  },
  '73622': {
    zip: '73622',
    city: 'Bessie',
    state: 'OK',
    latitude: '35.386487',
    longitude: '-98.997525',
    timeZoneId: 'America/Chicago',
  },
  '73624': {
    zip: '73624',
    city: 'Burns Flat',
    state: 'OK',
    latitude: '35.352897',
    longitude: '-99.173437',
    timeZoneId: 'America/Chicago',
  },
  '73625': {
    zip: '73625',
    city: 'Butler',
    state: 'OK',
    latitude: '35.647194',
    longitude: '-99.259907',
    timeZoneId: 'America/Chicago',
  },
  '73626': {
    zip: '73626',
    city: 'Canute',
    state: 'OK',
    latitude: '35.41676',
    longitude: '-99.280266',
    timeZoneId: 'America/Chicago',
  },
  '73627': {
    zip: '73627',
    city: 'Carter',
    state: 'OK',
    latitude: '35.214475',
    longitude: '-99.455448',
    timeZoneId: 'America/Chicago',
  },
  '73628': {
    zip: '73628',
    city: 'Cheyenne',
    state: 'OK',
    latitude: '35.62512',
    longitude: '-99.68718',
    timeZoneId: 'America/Chicago',
  },
  '73632': {
    zip: '73632',
    city: 'Cordell',
    state: 'OK',
    latitude: '35.293592',
    longitude: '-98.992219',
    timeZoneId: 'America/Chicago',
  },
  '73638': {
    zip: '73638',
    city: 'Crawford',
    state: 'OK',
    latitude: '35.848214',
    longitude: '-99.810085',
    timeZoneId: 'America/Chicago',
  },
  '73639': {
    zip: '73639',
    city: 'Custer City',
    state: 'OK',
    latitude: '35.69346',
    longitude: '-98.92392',
    timeZoneId: 'America/Chicago',
  },
  '73641': {
    zip: '73641',
    city: 'Dill City',
    state: 'OK',
    latitude: '35.278526',
    longitude: '-99.129216',
    timeZoneId: 'America/Chicago',
  },
  '73642': {
    zip: '73642',
    city: 'Durham',
    state: 'OK',
    latitude: '35.847476',
    longitude: '-99.919531',
    timeZoneId: 'America/Chicago',
  },
  '73644': {
    zip: '73644',
    city: 'Elk City',
    state: 'OK',
    latitude: '35.410421',
    longitude: '-99.413649',
    timeZoneId: 'America/Chicago',
  },
  '73645': {
    zip: '73645',
    city: 'Erick',
    state: 'OK',
    latitude: '35.212348',
    longitude: '-99.868165',
    timeZoneId: 'America/Chicago',
  },
  '73646': {
    zip: '73646',
    city: 'Fay',
    state: 'OK',
    latitude: '35.834829',
    longitude: '-98.678039',
    timeZoneId: 'America/Chicago',
  },
  '73647': {
    zip: '73647',
    city: 'Foss',
    state: 'OK',
    latitude: '35.396386',
    longitude: '-99.108663',
    timeZoneId: 'America/Chicago',
  },
  '73648': {
    zip: '73648',
    city: 'Elk City',
    state: 'OK',
    latitude: '35.407089',
    longitude: '-99.420825',
    timeZoneId: 'America/Chicago',
  },
  '73650': {
    zip: '73650',
    city: 'Hammon',
    state: 'OK',
    latitude: '35.688205',
    longitude: '-99.473097',
    timeZoneId: 'America/Chicago',
  },
  '73651': {
    zip: '73651',
    city: 'Hobart',
    state: 'OK',
    latitude: '35.024018',
    longitude: '-99.09137',
    timeZoneId: 'America/Chicago',
  },
  '73654': {
    zip: '73654',
    city: 'Leedey',
    state: 'OK',
    latitude: '35.869321',
    longitude: '-99.343665',
    timeZoneId: 'America/Chicago',
  },
  '73655': {
    zip: '73655',
    city: 'Lone Wolf',
    state: 'OK',
    latitude: '34.991436',
    longitude: '-99.245561',
    timeZoneId: 'America/Chicago',
  },
  '73658': {
    zip: '73658',
    city: 'Oakwood',
    state: 'OK',
    latitude: '35.933395',
    longitude: '-98.705777',
    timeZoneId: 'America/Chicago',
  },
  '73659': {
    zip: '73659',
    city: 'Putnam',
    state: 'OK',
    latitude: '35.845181',
    longitude: '-98.942549',
    timeZoneId: 'America/Chicago',
  },
  '73660': {
    zip: '73660',
    city: 'Reydon',
    state: 'OK',
    latitude: '35.656724',
    longitude: '-99.90103',
    timeZoneId: 'America/Chicago',
  },
  '73661': {
    zip: '73661',
    city: 'Rocky',
    state: 'OK',
    latitude: '35.15812',
    longitude: '-99.059953',
    timeZoneId: 'America/Chicago',
  },
  '73662': {
    zip: '73662',
    city: 'Sayre',
    state: 'OK',
    latitude: '35.299435',
    longitude: '-99.638752',
    timeZoneId: 'America/Chicago',
  },
  '73663': {
    zip: '73663',
    city: 'Seiling',
    state: 'OK',
    latitude: '36.141917',
    longitude: '-98.927264',
    timeZoneId: 'America/Chicago',
  },
  '73664': {
    zip: '73664',
    city: 'Sentinel',
    state: 'OK',
    latitude: '35.156247',
    longitude: '-99.175583',
    timeZoneId: 'America/Chicago',
  },
  '73666': {
    zip: '73666',
    city: 'Sweetwater',
    state: 'OK',
    latitude: '35.443145',
    longitude: '-99.912331',
    timeZoneId: 'America/Chicago',
  },
  '73667': {
    zip: '73667',
    city: 'Taloga',
    state: 'OK',
    latitude: '36.043902',
    longitude: '-98.959272',
    timeZoneId: 'America/Chicago',
  },
  '73668': {
    zip: '73668',
    city: 'Texola',
    state: 'OK',
    latitude: '35.233193',
    longitude: '-99.96692',
    timeZoneId: 'America/Chicago',
  },
  '73669': {
    zip: '73669',
    city: 'Thomas',
    state: 'OK',
    latitude: '35.748255',
    longitude: '-98.744954',
    timeZoneId: 'America/Chicago',
  },
  '73673': {
    zip: '73673',
    city: 'Willow',
    state: 'OK',
    latitude: '35.046358',
    longitude: '-99.643262',
    timeZoneId: 'America/Chicago',
  },
  '73701': {
    zip: '73701',
    city: 'Enid',
    state: 'OK',
    latitude: '36.405255',
    longitude: '-97.874299',
    timeZoneId: 'America/Chicago',
  },
  '73702': {
    zip: '73702',
    city: 'Enid',
    state: 'OK',
    latitude: '36.395323',
    longitude: '-97.873628',
    timeZoneId: 'America/Chicago',
  },
  '73703': {
    zip: '73703',
    city: 'Enid',
    state: 'OK',
    latitude: '36.397422',
    longitude: '-97.917494',
    timeZoneId: 'America/Chicago',
  },
  '73705': {
    zip: '73705',
    city: 'Enid',
    state: 'OK',
    latitude: '36.39552',
    longitude: '-97.878',
    timeZoneId: 'America/Chicago',
  },
  '73706': {
    zip: '73706',
    city: 'Enid',
    state: 'OK',
    latitude: '36.398528',
    longitude: '-97.93139',
    timeZoneId: 'America/Chicago',
  },
  '73716': {
    zip: '73716',
    city: 'Aline',
    state: 'OK',
    latitude: '36.512405',
    longitude: '-98.45145',
    timeZoneId: 'America/Chicago',
  },
  '73717': {
    zip: '73717',
    city: 'Alva',
    state: 'OK',
    latitude: '36.796749',
    longitude: '-98.668264',
    timeZoneId: 'America/Chicago',
  },
  '73718': {
    zip: '73718',
    city: 'Ames',
    state: 'OK',
    latitude: '36.23077',
    longitude: '-98.180193',
    timeZoneId: 'America/Chicago',
  },
  '73719': {
    zip: '73719',
    city: 'Amorita',
    state: 'OK',
    latitude: '36.953667',
    longitude: '-98.225375',
    timeZoneId: 'America/Chicago',
  },
  '73720': {
    zip: '73720',
    city: 'Bison',
    state: 'OK',
    latitude: '36.191013',
    longitude: '-97.887494',
    timeZoneId: 'America/Chicago',
  },
  '73722': {
    zip: '73722',
    city: 'Burlington',
    state: 'OK',
    latitude: '36.90972',
    longitude: '-98.43552',
    timeZoneId: 'America/Chicago',
  },
  '73724': {
    zip: '73724',
    city: 'Canton',
    state: 'OK',
    latitude: '36.054808',
    longitude: '-98.589971',
    timeZoneId: 'America/Chicago',
  },
  '73726': {
    zip: '73726',
    city: 'Carmen',
    state: 'OK',
    latitude: '36.577425',
    longitude: '-98.459621',
    timeZoneId: 'America/Chicago',
  },
  '73727': {
    zip: '73727',
    city: 'Carrier',
    state: 'OK',
    latitude: '36.526404',
    longitude: '-98.013999',
    timeZoneId: 'America/Chicago',
  },
  '73728': {
    zip: '73728',
    city: 'Cherokee',
    state: 'OK',
    latitude: '36.75562',
    longitude: '-98.350679',
    timeZoneId: 'America/Chicago',
  },
  '73729': {
    zip: '73729',
    city: 'Cleo Springs',
    state: 'OK',
    latitude: '36.405837',
    longitude: '-98.440699',
    timeZoneId: 'America/Chicago',
  },
  '73730': {
    zip: '73730',
    city: 'Covington',
    state: 'OK',
    latitude: '36.298352',
    longitude: '-97.541055',
    timeZoneId: 'America/Chicago',
  },
  '73731': {
    zip: '73731',
    city: 'Dacoma',
    state: 'OK',
    latitude: '36.660848',
    longitude: '-98.566763',
    timeZoneId: 'America/Chicago',
  },
  '73733': {
    zip: '73733',
    city: 'Douglas',
    state: 'OK',
    latitude: '36.233929',
    longitude: '-97.689016',
    timeZoneId: 'America/Chicago',
  },
  '73734': {
    zip: '73734',
    city: 'Dover',
    state: 'OK',
    latitude: '35.979509',
    longitude: '-97.912821',
    timeZoneId: 'America/Chicago',
  },
  '73735': {
    zip: '73735',
    city: 'Drummond',
    state: 'OK',
    latitude: '36.302135',
    longitude: '-98.0379',
    timeZoneId: 'America/Chicago',
  },
  '73736': {
    zip: '73736',
    city: 'Fairmont',
    state: 'OK',
    latitude: '36.37623',
    longitude: '-97.701868',
    timeZoneId: 'America/Chicago',
  },
  '73737': {
    zip: '73737',
    city: 'Fairview',
    state: 'OK',
    latitude: '36.26892',
    longitude: '-98.51388',
    timeZoneId: 'America/Chicago',
  },
  '73738': {
    zip: '73738',
    city: 'Garber',
    state: 'OK',
    latitude: '36.436312',
    longitude: '-97.581717',
    timeZoneId: 'America/Chicago',
  },
  '73739': {
    zip: '73739',
    city: 'Goltry',
    state: 'OK',
    latitude: '36.538739',
    longitude: '-98.160146',
    timeZoneId: 'America/Chicago',
  },
  '73741': {
    zip: '73741',
    city: 'Helena',
    state: 'OK',
    latitude: '36.547541',
    longitude: '-98.275187',
    timeZoneId: 'America/Chicago',
  },
  '73742': {
    zip: '73742',
    city: 'Hennessey',
    state: 'OK',
    latitude: '36.107231',
    longitude: '-97.899726',
    timeZoneId: 'America/Chicago',
  },
  '73743': {
    zip: '73743',
    city: 'Hillsdale',
    state: 'OK',
    latitude: '36.561817',
    longitude: '-97.989483',
    timeZoneId: 'America/Chicago',
  },
  '73744': {
    zip: '73744',
    city: 'Hitchcock',
    state: 'OK',
    latitude: '35.969989',
    longitude: '-98.351985',
    timeZoneId: 'America/Chicago',
  },
  '73746': {
    zip: '73746',
    city: 'Hopeton',
    state: 'OK',
    latitude: '36.685412',
    longitude: '-98.664762',
    timeZoneId: 'America/Chicago',
  },
  '73747': {
    zip: '73747',
    city: 'Isabella',
    state: 'OK',
    latitude: '36.234371',
    longitude: '-98.338643',
    timeZoneId: 'America/Chicago',
  },
  '73749': {
    zip: '73749',
    city: 'Jet',
    state: 'OK',
    latitude: '36.670491',
    longitude: '-98.179568',
    timeZoneId: 'America/Chicago',
  },
  '73750': {
    zip: '73750',
    city: 'Kingfisher',
    state: 'OK',
    latitude: '35.848233',
    longitude: '-97.936158',
    timeZoneId: 'America/Chicago',
  },
  '73753': {
    zip: '73753',
    city: 'Kremlin',
    state: 'OK',
    latitude: '36.529606',
    longitude: '-97.835904',
    timeZoneId: 'America/Chicago',
  },
  '73754': {
    zip: '73754',
    city: 'Lahoma',
    state: 'OK',
    latitude: '36.385915',
    longitude: '-98.094738',
    timeZoneId: 'America/Chicago',
  },
  '73755': {
    zip: '73755',
    city: 'Longdale',
    state: 'OK',
    latitude: '36.114029',
    longitude: '-98.556798',
    timeZoneId: 'America/Chicago',
  },
  '73756': {
    zip: '73756',
    city: 'Loyal',
    state: 'OK',
    latitude: '35.964311',
    longitude: '-98.112473',
    timeZoneId: 'America/Chicago',
  },
  '73757': {
    zip: '73757',
    city: 'Lucien',
    state: 'OK',
    latitude: '36.271246',
    longitude: '-97.450039',
    timeZoneId: 'America/Chicago',
  },
  '73758': {
    zip: '73758',
    city: 'Manchester',
    state: 'OK',
    latitude: '36.954762',
    longitude: '-98.011661',
    timeZoneId: 'America/Chicago',
  },
  '73759': {
    zip: '73759',
    city: 'Medford',
    state: 'OK',
    latitude: '36.807871',
    longitude: '-97.741275',
    timeZoneId: 'America/Chicago',
  },
  '73760': {
    zip: '73760',
    city: 'Meno',
    state: 'OK',
    latitude: '36.387867',
    longitude: '-98.176168',
    timeZoneId: 'America/Chicago',
  },
  '73761': {
    zip: '73761',
    city: 'Nash',
    state: 'OK',
    latitude: '36.66539',
    longitude: '-98.052849',
    timeZoneId: 'America/Chicago',
  },
  '73762': {
    zip: '73762',
    city: 'Okarche',
    state: 'OK',
    latitude: '35.729377',
    longitude: '-97.97598',
    timeZoneId: 'America/Chicago',
  },
  '73763': {
    zip: '73763',
    city: 'Okeene',
    state: 'OK',
    latitude: '36.117634',
    longitude: '-98.330975',
    timeZoneId: 'America/Chicago',
  },
  '73764': {
    zip: '73764',
    city: 'Omega',
    state: 'OK',
    latitude: '35.847849',
    longitude: '-98.178035',
    timeZoneId: 'America/Chicago',
  },
  '73766': {
    zip: '73766',
    city: 'Pond Creek',
    state: 'OK',
    latitude: '36.667062',
    longitude: '-97.798587',
    timeZoneId: 'America/Chicago',
  },
  '73768': {
    zip: '73768',
    city: 'Ringwood',
    state: 'OK',
    latitude: '36.380403',
    longitude: '-98.243833',
    timeZoneId: 'America/Chicago',
  },
  '73770': {
    zip: '73770',
    city: 'Southard',
    state: 'OK',
    latitude: '36.075847',
    longitude: '-98.456345',
    timeZoneId: 'America/Chicago',
  },
  '73771': {
    zip: '73771',
    city: 'Wakita',
    state: 'OK',
    latitude: '36.883918',
    longitude: '-97.926796',
    timeZoneId: 'America/Chicago',
  },
  '73772': {
    zip: '73772',
    city: 'Watonga',
    state: 'OK',
    latitude: '35.855545',
    longitude: '-98.41044',
    timeZoneId: 'America/Chicago',
  },
  '73773': {
    zip: '73773',
    city: 'Waukomis',
    state: 'OK',
    latitude: '36.281331',
    longitude: '-97.898034',
    timeZoneId: 'America/Chicago',
  },
  '73801': {
    zip: '73801',
    city: 'Woodward',
    state: 'OK',
    latitude: '36.432094',
    longitude: '-99.399622',
    timeZoneId: 'America/Chicago',
  },
  '73802': {
    zip: '73802',
    city: 'Woodward',
    state: 'OK',
    latitude: '36.422068',
    longitude: '-99.388358',
    timeZoneId: 'America/Chicago',
  },
  '73832': {
    zip: '73832',
    city: 'Arnett',
    state: 'OK',
    latitude: '36.0837',
    longitude: '-99.7458',
    timeZoneId: 'America/Chicago',
  },
  '73834': {
    zip: '73834',
    city: 'Buffalo',
    state: 'OK',
    latitude: '36.82698',
    longitude: '-99.59238',
    timeZoneId: 'America/Chicago',
  },
  '73835': {
    zip: '73835',
    city: 'Camargo',
    state: 'OK',
    latitude: '36.030675',
    longitude: '-99.282535',
    timeZoneId: 'America/Chicago',
  },
  '73838': {
    zip: '73838',
    city: 'Chester',
    state: 'OK',
    latitude: '36.329878',
    longitude: '-98.872945',
    timeZoneId: 'America/Chicago',
  },
  '73840': {
    zip: '73840',
    city: 'Fargo',
    state: 'OK',
    latitude: '36.373253',
    longitude: '-99.624852',
    timeZoneId: 'America/Chicago',
  },
  '73841': {
    zip: '73841',
    city: 'Fort Supply',
    state: 'OK',
    latitude: '36.568109',
    longitude: '-99.569072',
    timeZoneId: 'America/Chicago',
  },
  '73842': {
    zip: '73842',
    city: 'Freedom',
    state: 'OK',
    latitude: '36.881486',
    longitude: '-99.165009',
    timeZoneId: 'America/Chicago',
  },
  '73843': {
    zip: '73843',
    city: 'Gage',
    state: 'OK',
    latitude: '36.316177',
    longitude: '-99.756588',
    timeZoneId: 'America/Chicago',
  },
  '73844': {
    zip: '73844',
    city: 'Gate',
    state: 'OK',
    latitude: '36.91457',
    longitude: '-100.128511',
    timeZoneId: 'America/Chicago',
  },
  '73848': {
    zip: '73848',
    city: 'Laverne',
    state: 'OK',
    latitude: '36.71658',
    longitude: '-99.880538',
    timeZoneId: 'America/Chicago',
  },
  '73851': {
    zip: '73851',
    city: 'May',
    state: 'OK',
    latitude: '36.635502',
    longitude: '-99.683794',
    timeZoneId: 'America/Chicago',
  },
  '73852': {
    zip: '73852',
    city: 'Mooreland',
    state: 'OK',
    latitude: '36.442238',
    longitude: '-99.205025',
    timeZoneId: 'America/Chicago',
  },
  '73853': {
    zip: '73853',
    city: 'Mutual',
    state: 'OK',
    latitude: '36.145542',
    longitude: '-99.29839',
    timeZoneId: 'America/Chicago',
  },
  '73855': {
    zip: '73855',
    city: 'Rosston',
    state: 'OK',
    latitude: '36.89767',
    longitude: '-99.887961',
    timeZoneId: 'America/Chicago',
  },
  '73857': {
    zip: '73857',
    city: 'Sharon',
    state: 'OK',
    latitude: '36.262341',
    longitude: '-99.394198',
    timeZoneId: 'America/Chicago',
  },
  '73858': {
    zip: '73858',
    city: 'Shattuck',
    state: 'OK',
    latitude: '36.269773',
    longitude: '-99.876079',
    timeZoneId: 'America/Chicago',
  },
  '73859': {
    zip: '73859',
    city: 'Vici',
    state: 'OK',
    latitude: '36.149345',
    longitude: '-99.301235',
    timeZoneId: 'America/Chicago',
  },
  '73860': {
    zip: '73860',
    city: 'Waynoka',
    state: 'OK',
    latitude: '36.587353',
    longitude: '-98.880471',
    timeZoneId: 'America/Chicago',
  },
  '73901': {
    zip: '73901',
    city: 'Adams',
    state: 'OK',
    latitude: '36.829861',
    longitude: '-101.155354',
    timeZoneId: 'America/Chicago',
  },
  '73931': {
    zip: '73931',
    city: 'Balko',
    state: 'OK',
    latitude: '36.588033',
    longitude: '-100.68063',
    timeZoneId: 'America/Chicago',
  },
  '73932': {
    zip: '73932',
    city: 'Beaver',
    state: 'OK',
    latitude: '36.73818',
    longitude: '-100.5483',
    timeZoneId: 'America/Chicago',
  },
  '73933': {
    zip: '73933',
    city: 'Boise City',
    state: 'OK',
    latitude: '36.730203',
    longitude: '-102.507607',
    timeZoneId: 'America/Chicago',
  },
  '73937': {
    zip: '73937',
    city: 'Felt',
    state: 'OK',
    latitude: '36.554028',
    longitude: '-102.838653',
    timeZoneId: 'America/Chicago',
  },
  '73938': {
    zip: '73938',
    city: 'Forgan',
    state: 'OK',
    latitude: '36.906831',
    longitude: '-100.534086',
    timeZoneId: 'America/Chicago',
  },
  '73939': {
    zip: '73939',
    city: 'Goodwell',
    state: 'OK',
    latitude: '36.586421',
    longitude: '-101.643317',
    timeZoneId: 'America/Chicago',
  },
  '73942': {
    zip: '73942',
    city: 'Guymon',
    state: 'OK',
    latitude: '36.684157',
    longitude: '-101.479147',
    timeZoneId: 'America/Chicago',
  },
  '73944': {
    zip: '73944',
    city: 'Hardesty',
    state: 'OK',
    latitude: '36.588448',
    longitude: '-101.163603',
    timeZoneId: 'America/Chicago',
  },
  '73945': {
    zip: '73945',
    city: 'Hooker',
    state: 'OK',
    latitude: '36.82728',
    longitude: '-101.1822',
    timeZoneId: 'America/Chicago',
  },
  '73946': {
    zip: '73946',
    city: 'Kenton',
    state: 'OK',
    latitude: '36.851936',
    longitude: '-102.905823',
    timeZoneId: 'America/Chicago',
  },
  '73947': {
    zip: '73947',
    city: 'Keyes',
    state: 'OK',
    latitude: '36.807788',
    longitude: '-102.252542',
    timeZoneId: 'America/Chicago',
  },
  '73949': {
    zip: '73949',
    city: 'Texhoma',
    state: 'OK',
    latitude: '36.508709',
    longitude: '-101.775332',
    timeZoneId: 'America/Chicago',
  },
  '73950': {
    zip: '73950',
    city: 'Turpin',
    state: 'OK',
    latitude: '36.8766',
    longitude: '-100.8516',
    timeZoneId: 'America/Chicago',
  },
  '73951': {
    zip: '73951',
    city: 'Tyrone',
    state: 'OK',
    latitude: '36.949359',
    longitude: '-101.024269',
    timeZoneId: 'America/Chicago',
  },
  '73960': {
    zip: '73960',
    city: 'Texhoma',
    state: 'TX',
    latitude: '36.498133',
    longitude: '-101.783297',
    timeZoneId: 'America/Chicago',
  },
  '74001': {
    zip: '74001',
    city: 'Avant',
    state: 'OK',
    latitude: '36.489618',
    longitude: '-96.05984',
    timeZoneId: 'America/Chicago',
  },
  '74002': {
    zip: '74002',
    city: 'Barnsdall',
    state: 'OK',
    latitude: '36.558589',
    longitude: '-96.162993',
    timeZoneId: 'America/Chicago',
  },
  '74003': {
    zip: '74003',
    city: 'Bartlesville',
    state: 'OK',
    latitude: '36.73788',
    longitude: '-96.0054',
    timeZoneId: 'America/Chicago',
  },
  '74004': {
    zip: '74004',
    city: 'Bartlesville',
    state: 'OK',
    latitude: '36.739762',
    longitude: '-95.948625',
    timeZoneId: 'America/Chicago',
  },
  '74005': {
    zip: '74005',
    city: 'Bartlesville',
    state: 'OK',
    latitude: '36.748499',
    longitude: '-95.980696',
    timeZoneId: 'America/Chicago',
  },
  '74006': {
    zip: '74006',
    city: 'Bartlesville',
    state: 'OK',
    latitude: '36.738109',
    longitude: '-95.93326',
    timeZoneId: 'America/Chicago',
  },
  '74008': {
    zip: '74008',
    city: 'Bixby',
    state: 'OK',
    latitude: '35.951012',
    longitude: '-95.885108',
    timeZoneId: 'America/Chicago',
  },
  '74010': {
    zip: '74010',
    city: 'Bristow',
    state: 'OK',
    latitude: '35.831459',
    longitude: '-96.387712',
    timeZoneId: 'America/Chicago',
  },
  '74011': {
    zip: '74011',
    city: 'Broken Arrow',
    state: 'OK',
    latitude: '35.987755',
    longitude: '-95.81074',
    timeZoneId: 'America/Chicago',
  },
  '74012': {
    zip: '74012',
    city: 'Broken Arrow',
    state: 'OK',
    latitude: '36.04503',
    longitude: '-95.808242',
    timeZoneId: 'America/Chicago',
  },
  '74013': {
    zip: '74013',
    city: 'Broken Arrow',
    state: 'OK',
    latitude: '36.045952',
    longitude: '-95.789603',
    timeZoneId: 'America/Chicago',
  },
  '74014': {
    zip: '74014',
    city: 'Broken Arrow',
    state: 'OK',
    latitude: '36.054889',
    longitude: '-95.716187',
    timeZoneId: 'America/Chicago',
  },
  '74015': {
    zip: '74015',
    city: 'Catoosa',
    state: 'OK',
    latitude: '36.186268',
    longitude: '-95.744614',
    timeZoneId: 'America/Chicago',
  },
  '74016': {
    zip: '74016',
    city: 'Chelsea',
    state: 'OK',
    latitude: '36.54096',
    longitude: '-95.44692',
    timeZoneId: 'America/Chicago',
  },
  '74017': {
    zip: '74017',
    city: 'Claremore',
    state: 'OK',
    latitude: '36.34302',
    longitude: '-95.59668',
    timeZoneId: 'America/Chicago',
  },
  '74018': {
    zip: '74018',
    city: 'Claremore',
    state: 'OK',
    latitude: '36.319001',
    longitude: '-95.586801',
    timeZoneId: 'America/Chicago',
  },
  '74019': {
    zip: '74019',
    city: 'Claremore',
    state: 'OK',
    latitude: '36.318395',
    longitude: '-95.619824',
    timeZoneId: 'America/Chicago',
  },
  '74020': {
    zip: '74020',
    city: 'Cleveland',
    state: 'OK',
    latitude: '36.27348',
    longitude: '-96.43008',
    timeZoneId: 'America/Chicago',
  },
  '74021': {
    zip: '74021',
    city: 'Collinsville',
    state: 'OK',
    latitude: '36.360682',
    longitude: '-95.850702',
    timeZoneId: 'America/Chicago',
  },
  '74022': {
    zip: '74022',
    city: 'Copan',
    state: 'OK',
    latitude: '36.896936',
    longitude: '-95.924587',
    timeZoneId: 'America/Chicago',
  },
  '74023': {
    zip: '74023',
    city: 'Cushing',
    state: 'OK',
    latitude: '35.976349',
    longitude: '-96.764819',
    timeZoneId: 'America/Chicago',
  },
  '74026': {
    zip: '74026',
    city: 'Davenport',
    state: 'OK',
    latitude: '35.70746',
    longitude: '-96.764061',
    timeZoneId: 'America/Chicago',
  },
  '74027': {
    zip: '74027',
    city: 'Delaware',
    state: 'OK',
    latitude: '36.778584',
    longitude: '-95.640408',
    timeZoneId: 'America/Chicago',
  },
  '74028': {
    zip: '74028',
    city: 'Depew',
    state: 'OK',
    latitude: '35.803536',
    longitude: '-96.508453',
    timeZoneId: 'America/Chicago',
  },
  '74029': {
    zip: '74029',
    city: 'Dewey',
    state: 'OK',
    latitude: '36.794446',
    longitude: '-95.936311',
    timeZoneId: 'America/Chicago',
  },
  '74030': {
    zip: '74030',
    city: 'Drumright',
    state: 'OK',
    latitude: '35.990679',
    longitude: '-96.602438',
    timeZoneId: 'America/Chicago',
  },
  '74031': {
    zip: '74031',
    city: 'Foyil',
    state: 'OK',
    latitude: '36.436238',
    longitude: '-95.518349',
    timeZoneId: 'America/Chicago',
  },
  '74032': {
    zip: '74032',
    city: 'Glencoe',
    state: 'OK',
    latitude: '36.224896',
    longitude: '-96.927932',
    timeZoneId: 'America/Chicago',
  },
  '74033': {
    zip: '74033',
    city: 'Glenpool',
    state: 'OK',
    latitude: '35.956351',
    longitude: '-95.996719',
    timeZoneId: 'America/Chicago',
  },
  '74034': {
    zip: '74034',
    city: 'Hallett',
    state: 'OK',
    latitude: '36.233896',
    longitude: '-96.567733',
    timeZoneId: 'America/Chicago',
  },
  '74035': {
    zip: '74035',
    city: 'Hominy',
    state: 'OK',
    latitude: '36.414067',
    longitude: '-96.394679',
    timeZoneId: 'America/Chicago',
  },
  '74036': {
    zip: '74036',
    city: 'Inola',
    state: 'OK',
    latitude: '36.147671',
    longitude: '-95.509727',
    timeZoneId: 'America/Chicago',
  },
  '74037': {
    zip: '74037',
    city: 'Jenks',
    state: 'OK',
    latitude: '36.013289',
    longitude: '-95.979724',
    timeZoneId: 'America/Chicago',
  },
  '74038': {
    zip: '74038',
    city: 'Jennings',
    state: 'OK',
    latitude: '36.190677',
    longitude: '-96.587636',
    timeZoneId: 'America/Chicago',
  },
  '74039': {
    zip: '74039',
    city: 'Kellyville',
    state: 'OK',
    latitude: '35.947865',
    longitude: '-96.210406',
    timeZoneId: 'America/Chicago',
  },
  '74041': {
    zip: '74041',
    city: 'Kiefer',
    state: 'OK',
    latitude: '35.945888',
    longitude: '-96.059791',
    timeZoneId: 'America/Chicago',
  },
  '74042': {
    zip: '74042',
    city: 'Lenapah',
    state: 'OK',
    latitude: '36.854195',
    longitude: '-95.636555',
    timeZoneId: 'America/Chicago',
  },
  '74043': {
    zip: '74043',
    city: 'Leonard',
    state: 'OK',
    latitude: '35.946668',
    longitude: '-95.785019',
    timeZoneId: 'America/Chicago',
  },
  '74044': {
    zip: '74044',
    city: 'Mannford',
    state: 'OK',
    latitude: '36.121473',
    longitude: '-96.344224',
    timeZoneId: 'America/Chicago',
  },
  '74045': {
    zip: '74045',
    city: 'Maramec',
    state: 'OK',
    latitude: '36.216245',
    longitude: '-96.677382',
    timeZoneId: 'America/Chicago',
  },
  '74046': {
    zip: '74046',
    city: 'Milfay',
    state: 'OK',
    latitude: '35.755493',
    longitude: '-96.565776',
    timeZoneId: 'America/Chicago',
  },
  '74047': {
    zip: '74047',
    city: 'Mounds',
    state: 'OK',
    latitude: '35.873661',
    longitude: '-96.059648',
    timeZoneId: 'America/Chicago',
  },
  '74048': {
    zip: '74048',
    city: 'Nowata',
    state: 'OK',
    latitude: '36.697172',
    longitude: '-95.639359',
    timeZoneId: 'America/Chicago',
  },
  '74050': {
    zip: '74050',
    city: 'Oakhurst',
    state: 'OK',
    latitude: '36.076749',
    longitude: '-96.069276',
    timeZoneId: 'America/Chicago',
  },
  '74051': {
    zip: '74051',
    city: 'Ochelata',
    state: 'OK',
    latitude: '36.603241',
    longitude: '-95.984384',
    timeZoneId: 'America/Chicago',
  },
  '74052': {
    zip: '74052',
    city: 'Oilton',
    state: 'OK',
    latitude: '36.08933',
    longitude: '-96.582972',
    timeZoneId: 'America/Chicago',
  },
  '74053': {
    zip: '74053',
    city: 'Oologah',
    state: 'OK',
    latitude: '36.444855',
    longitude: '-95.708823',
    timeZoneId: 'America/Chicago',
  },
  '74054': {
    zip: '74054',
    city: 'Osage',
    state: 'OK',
    latitude: '36.294059',
    longitude: '-96.383486',
    timeZoneId: 'America/Chicago',
  },
  '74055': {
    zip: '74055',
    city: 'Owasso',
    state: 'OK',
    latitude: '36.279959',
    longitude: '-95.850118',
    timeZoneId: 'America/Chicago',
  },
  '74056': {
    zip: '74056',
    city: 'Pawhuska',
    state: 'OK',
    latitude: '36.823412',
    longitude: '-96.36717',
    timeZoneId: 'America/Chicago',
  },
  '74058': {
    zip: '74058',
    city: 'Pawnee',
    state: 'OK',
    latitude: '36.331099',
    longitude: '-96.777425',
    timeZoneId: 'America/Chicago',
  },
  '74059': {
    zip: '74059',
    city: 'Perkins',
    state: 'OK',
    latitude: '35.983101',
    longitude: '-97.024469',
    timeZoneId: 'America/Chicago',
  },
  '74060': {
    zip: '74060',
    city: 'Prue',
    state: 'OK',
    latitude: '36.281438',
    longitude: '-96.242323',
    timeZoneId: 'America/Chicago',
  },
  '74061': {
    zip: '74061',
    city: 'Ramona',
    state: 'OK',
    latitude: '36.562678',
    longitude: '-95.905588',
    timeZoneId: 'America/Chicago',
  },
  '74062': {
    zip: '74062',
    city: 'Ripley',
    state: 'OK',
    latitude: '35.984342',
    longitude: '-96.889919',
    timeZoneId: 'America/Chicago',
  },
  '74063': {
    zip: '74063',
    city: 'Sand Springs',
    state: 'OK',
    latitude: '36.122622',
    longitude: '-96.150607',
    timeZoneId: 'America/Chicago',
  },
  '74066': {
    zip: '74066',
    city: 'Sapulpa',
    state: 'OK',
    latitude: '36.000197',
    longitude: '-96.113994',
    timeZoneId: 'America/Chicago',
  },
  '74067': {
    zip: '74067',
    city: 'Sapulpa',
    state: 'OK',
    latitude: '35.986469',
    longitude: '-96.117351',
    timeZoneId: 'America/Chicago',
  },
  '74068': {
    zip: '74068',
    city: 'Shamrock',
    state: 'OK',
    latitude: '35.909019',
    longitude: '-96.575047',
    timeZoneId: 'America/Chicago',
  },
  '74070': {
    zip: '74070',
    city: 'Skiatook',
    state: 'OK',
    latitude: '36.360002',
    longitude: '-95.98374',
    timeZoneId: 'America/Chicago',
  },
  '74071': {
    zip: '74071',
    city: 'Slick',
    state: 'OK',
    latitude: '35.776835',
    longitude: '-96.264564',
    timeZoneId: 'America/Chicago',
  },
  '74072': {
    zip: '74072',
    city: 'S Coffeyville',
    state: 'OK',
    latitude: '36.97572',
    longitude: '-95.5872',
    timeZoneId: 'America/Chicago',
  },
  '74073': {
    zip: '74073',
    city: 'Sperry',
    state: 'OK',
    latitude: '36.291646',
    longitude: '-95.967837',
    timeZoneId: 'America/Chicago',
  },
  '74074': {
    zip: '74074',
    city: 'Stillwater',
    state: 'OK',
    latitude: '36.10796',
    longitude: '-97.076194',
    timeZoneId: 'America/Chicago',
  },
  '74075': {
    zip: '74075',
    city: 'Stillwater',
    state: 'OK',
    latitude: '36.140565',
    longitude: '-97.061624',
    timeZoneId: 'America/Chicago',
  },
  '74076': {
    zip: '74076',
    city: 'Stillwater',
    state: 'OK',
    latitude: '36.12342',
    longitude: '-97.031945',
    timeZoneId: 'America/Chicago',
  },
  '74077': {
    zip: '74077',
    city: 'Stillwater',
    state: 'OK',
    latitude: '36.118222',
    longitude: '-97.06213',
    timeZoneId: 'America/Chicago',
  },
  '74078': {
    zip: '74078',
    city: 'Stillwater',
    state: 'OK',
    latitude: '36.115627',
    longitude: '-97.058038',
    timeZoneId: 'America/Chicago',
  },
  '74079': {
    zip: '74079',
    city: 'Stroud',
    state: 'OK',
    latitude: '35.7603',
    longitude: '-96.7023',
    timeZoneId: 'America/Chicago',
  },
  '74080': {
    zip: '74080',
    city: 'Talala',
    state: 'OK',
    latitude: '36.530859',
    longitude: '-95.697833',
    timeZoneId: 'America/Chicago',
  },
  '74081': {
    zip: '74081',
    city: 'Terlton',
    state: 'OK',
    latitude: '36.192149',
    longitude: '-96.486493',
    timeZoneId: 'America/Chicago',
  },
  '74082': {
    zip: '74082',
    city: 'Vera',
    state: 'OK',
    latitude: '36.451834',
    longitude: '-95.881907',
    timeZoneId: 'America/Chicago',
  },
  '74083': {
    zip: '74083',
    city: 'Wann',
    state: 'OK',
    latitude: '36.931887',
    longitude: '-95.771943',
    timeZoneId: 'America/Chicago',
  },
  '74084': {
    zip: '74084',
    city: 'Wynona',
    state: 'OK',
    latitude: '36.550067',
    longitude: '-96.321628',
    timeZoneId: 'America/Chicago',
  },
  '74085': {
    zip: '74085',
    city: 'Yale',
    state: 'OK',
    latitude: '36.115757',
    longitude: '-96.700338',
    timeZoneId: 'America/Chicago',
  },
  '74101': {
    zip: '74101',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.072287',
    longitude: '-95.902002',
    timeZoneId: 'America/Chicago',
  },
  '74102': {
    zip: '74102',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.244111',
    longitude: '-95.828066',
    timeZoneId: 'America/Chicago',
  },
  '74103': {
    zip: '74103',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.159273',
    longitude: '-95.994723',
    timeZoneId: 'America/Chicago',
  },
  '74104': {
    zip: '74104',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.149',
    longitude: '-95.953469',
    timeZoneId: 'America/Chicago',
  },
  '74105': {
    zip: '74105',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.099428',
    longitude: '-95.959689',
    timeZoneId: 'America/Chicago',
  },
  '74106': {
    zip: '74106',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.186382',
    longitude: '-95.98432',
    timeZoneId: 'America/Chicago',
  },
  '74107': {
    zip: '74107',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.100711',
    longitude: '-96.030063',
    timeZoneId: 'America/Chicago',
  },
  '74108': {
    zip: '74108',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.144971',
    longitude: '-95.800496',
    timeZoneId: 'America/Chicago',
  },
  '74110': {
    zip: '74110',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.182072',
    longitude: '-95.949848',
    timeZoneId: 'America/Chicago',
  },
  '74112': {
    zip: '74112',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.145326',
    longitude: '-95.90613',
    timeZoneId: 'America/Chicago',
  },
  '74114': {
    zip: '74114',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.125061',
    longitude: '-95.950201',
    timeZoneId: 'America/Chicago',
  },
  '74115': {
    zip: '74115',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.178703',
    longitude: '-95.908519',
    timeZoneId: 'America/Chicago',
  },
  '74116': {
    zip: '74116',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.172746',
    longitude: '-95.841579',
    timeZoneId: 'America/Chicago',
  },
  '74117': {
    zip: '74117',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.235013',
    longitude: '-95.885947',
    timeZoneId: 'America/Chicago',
  },
  '74119': {
    zip: '74119',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.139674',
    longitude: '-95.987503',
    timeZoneId: 'America/Chicago',
  },
  '74120': {
    zip: '74120',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.150477',
    longitude: '-95.975853',
    timeZoneId: 'America/Chicago',
  },
  '74121': {
    zip: '74121',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.148687',
    longitude: '-95.980328',
    timeZoneId: 'America/Chicago',
  },
  '74126': {
    zip: '74126',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.238869',
    longitude: '-95.9852',
    timeZoneId: 'America/Chicago',
  },
  '74127': {
    zip: '74127',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.15339',
    longitude: '-96.026756',
    timeZoneId: 'America/Chicago',
  },
  '74128': {
    zip: '74128',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.144415',
    longitude: '-95.853927',
    timeZoneId: 'America/Chicago',
  },
  '74129': {
    zip: '74129',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.126438',
    longitude: '-95.862391',
    timeZoneId: 'America/Chicago',
  },
  '74130': {
    zip: '74130',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.23961',
    longitude: '-95.954496',
    timeZoneId: 'America/Chicago',
  },
  '74131': {
    zip: '74131',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.05036',
    longitude: '-96.066439',
    timeZoneId: 'America/Chicago',
  },
  '74132': {
    zip: '74132',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.060047',
    longitude: '-96.016045',
    timeZoneId: 'America/Chicago',
  },
  '74133': {
    zip: '74133',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.050515',
    longitude: '-95.881034',
    timeZoneId: 'America/Chicago',
  },
  '74134': {
    zip: '74134',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.112907',
    longitude: '-95.82026',
    timeZoneId: 'America/Chicago',
  },
  '74135': {
    zip: '74135',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.098476',
    longitude: '-95.922076',
    timeZoneId: 'America/Chicago',
  },
  '74136': {
    zip: '74136',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.060952',
    longitude: '-95.939506',
    timeZoneId: 'America/Chicago',
  },
  '74137': {
    zip: '74137',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.025399',
    longitude: '-95.924266',
    timeZoneId: 'America/Chicago',
  },
  '74141': {
    zip: '74141',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.133837',
    longitude: '-95.876959',
    timeZoneId: 'America/Chicago',
  },
  '74145': {
    zip: '74145',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.096758',
    longitude: '-95.885146',
    timeZoneId: 'America/Chicago',
  },
  '74146': {
    zip: '74146',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.104742',
    longitude: '-95.852838',
    timeZoneId: 'America/Chicago',
  },
  '74147': {
    zip: '74147',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.097393',
    longitude: '-95.887182',
    timeZoneId: 'America/Chicago',
  },
  '74148': {
    zip: '74148',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.190107',
    longitude: '-96.004902',
    timeZoneId: 'America/Chicago',
  },
  '74149': {
    zip: '74149',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.158937',
    longitude: '-96.032786',
    timeZoneId: 'America/Chicago',
  },
  '74150': {
    zip: '74150',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.16085',
    longitude: '-95.956181',
    timeZoneId: 'America/Chicago',
  },
  '74152': {
    zip: '74152',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.126786',
    longitude: '-95.94493',
    timeZoneId: 'America/Chicago',
  },
  '74153': {
    zip: '74153',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.096902',
    longitude: '-95.921925',
    timeZoneId: 'America/Chicago',
  },
  '74155': {
    zip: '74155',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.098789',
    longitude: '-95.876661',
    timeZoneId: 'America/Chicago',
  },
  '74156': {
    zip: '74156',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.246098',
    longitude: '-95.997005',
    timeZoneId: 'America/Chicago',
  },
  '74157': {
    zip: '74157',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.077399',
    longitude: '-96.054854',
    timeZoneId: 'America/Chicago',
  },
  '74158': {
    zip: '74158',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.167813',
    longitude: '-95.918382',
    timeZoneId: 'America/Chicago',
  },
  '74159': {
    zip: '74159',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.147599',
    longitude: '-95.954697',
    timeZoneId: 'America/Chicago',
  },
  '74169': {
    zip: '74169',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.127872',
    longitude: '-95.868871',
    timeZoneId: 'America/Chicago',
  },
  '74170': {
    zip: '74170',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.042184',
    longitude: '-95.947467',
    timeZoneId: 'America/Chicago',
  },
  '74171': {
    zip: '74171',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.149777',
    longitude: '-95.995378',
    timeZoneId: 'America/Chicago',
  },
  '74172': {
    zip: '74172',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.150461',
    longitude: '-95.972719',
    timeZoneId: 'America/Chicago',
  },
  '74182': {
    zip: '74182',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.1541',
    longitude: '-95.994899',
    timeZoneId: 'America/Chicago',
  },
  '74186': {
    zip: '74186',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.152163',
    longitude: '-95.995',
    timeZoneId: 'America/Chicago',
  },
  '74187': {
    zip: '74187',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.147918',
    longitude: '-95.994333',
    timeZoneId: 'America/Chicago',
  },
  '74192': {
    zip: '74192',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.153591',
    longitude: '-95.995611',
    timeZoneId: 'America/Chicago',
  },
  '74193': {
    zip: '74193',
    city: 'Tulsa',
    state: 'OK',
    latitude: '36.06165',
    longitude: '-95.923301',
    timeZoneId: 'America/Chicago',
  },
  '74301': {
    zip: '74301',
    city: 'Vinita',
    state: 'OK',
    latitude: '36.642087',
    longitude: '-95.156105',
    timeZoneId: 'America/Chicago',
  },
  '74330': {
    zip: '74330',
    city: 'Adair',
    state: 'OK',
    latitude: '36.438562',
    longitude: '-95.264386',
    timeZoneId: 'America/Chicago',
  },
  '74331': {
    zip: '74331',
    city: 'Afton',
    state: 'OK',
    latitude: '36.692974',
    longitude: '-94.960304',
    timeZoneId: 'America/Chicago',
  },
  '74332': {
    zip: '74332',
    city: 'Big Cabin',
    state: 'OK',
    latitude: '36.674801',
    longitude: '-95.297955',
    timeZoneId: 'America/Chicago',
  },
  '74333': {
    zip: '74333',
    city: 'Bluejacket',
    state: 'OK',
    latitude: '36.80642',
    longitude: '-95.121074',
    timeZoneId: 'America/Chicago',
  },
  '74335': {
    zip: '74335',
    city: 'Cardin',
    state: 'OK',
    latitude: '36.971642',
    longitude: '-94.852373',
    timeZoneId: 'America/Chicago',
  },
  '74337': {
    zip: '74337',
    city: 'Chouteau',
    state: 'OK',
    latitude: '36.189386',
    longitude: '-95.333812',
    timeZoneId: 'America/Chicago',
  },
  '74338': {
    zip: '74338',
    city: 'Colcord',
    state: 'OK',
    latitude: '36.248548',
    longitude: '-94.673222',
    timeZoneId: 'America/Chicago',
  },
  '74339': {
    zip: '74339',
    city: 'Commerce',
    state: 'OK',
    latitude: '36.931772',
    longitude: '-94.869953',
    timeZoneId: 'America/Chicago',
  },
  '74340': {
    zip: '74340',
    city: 'Disney',
    state: 'OK',
    latitude: '36.480968',
    longitude: '-95.017109',
    timeZoneId: 'America/Chicago',
  },
  '74342': {
    zip: '74342',
    city: 'Eucha',
    state: 'OK',
    latitude: '36.372818',
    longitude: '-94.91547',
    timeZoneId: 'America/Chicago',
  },
  '74343': {
    zip: '74343',
    city: 'Fairland',
    state: 'OK',
    latitude: '36.753338',
    longitude: '-94.848103',
    timeZoneId: 'America/Chicago',
  },
  '74344': {
    zip: '74344',
    city: 'Grove',
    state: 'OK',
    latitude: '36.589899',
    longitude: '-94.77219',
    timeZoneId: 'America/Chicago',
  },
  '74345': {
    zip: '74345',
    city: 'Grove',
    state: 'OK',
    latitude: '36.595226',
    longitude: '-94.787452',
    timeZoneId: 'America/Chicago',
  },
  '74346': {
    zip: '74346',
    city: 'Jay',
    state: 'OK',
    latitude: '36.418698',
    longitude: '-94.795826',
    timeZoneId: 'America/Chicago',
  },
  '74347': {
    zip: '74347',
    city: 'Kansas',
    state: 'OK',
    latitude: '36.240107',
    longitude: '-94.821653',
    timeZoneId: 'America/Chicago',
  },
  '74349': {
    zip: '74349',
    city: 'Ketchum',
    state: 'OK',
    latitude: '36.522655',
    longitude: '-95.024834',
    timeZoneId: 'America/Chicago',
  },
  '74350': {
    zip: '74350',
    city: 'Langley',
    state: 'OK',
    latitude: '36.463504',
    longitude: '-95.047589',
    timeZoneId: 'America/Chicago',
  },
  '74352': {
    zip: '74352',
    city: 'Locust Grove',
    state: 'OK',
    latitude: '36.199737',
    longitude: '-95.167459',
    timeZoneId: 'America/Chicago',
  },
  '74354': {
    zip: '74354',
    city: 'Miami',
    state: 'OK',
    latitude: '36.880147',
    longitude: '-94.87431',
    timeZoneId: 'America/Chicago',
  },
  '74355': {
    zip: '74355',
    city: 'Miami',
    state: 'OK',
    latitude: '36.890711',
    longitude: '-94.876486',
    timeZoneId: 'America/Chicago',
  },
  '74358': {
    zip: '74358',
    city: 'North Miami',
    state: 'OK',
    latitude: '36.917496',
    longitude: '-94.882505',
    timeZoneId: 'America/Chicago',
  },
  '74359': {
    zip: '74359',
    city: 'Oaks',
    state: 'OK',
    latitude: '36.171831',
    longitude: '-94.860683',
    timeZoneId: 'America/Chicago',
  },
  '74360': {
    zip: '74360',
    city: 'Picher',
    state: 'OK',
    latitude: '36.985528',
    longitude: '-94.833169',
    timeZoneId: 'America/Chicago',
  },
  '74361': {
    zip: '74361',
    city: 'Pryor',
    state: 'OK',
    latitude: '36.29894',
    longitude: '-95.310823',
    timeZoneId: 'America/Chicago',
  },
  '74362': {
    zip: '74362',
    city: 'Pryor',
    state: 'OK',
    latitude: '36.288695',
    longitude: '-95.319273',
    timeZoneId: 'America/Chicago',
  },
  '74363': {
    zip: '74363',
    city: 'Quapaw',
    state: 'OK',
    latitude: '36.942',
    longitude: '-94.73292',
    timeZoneId: 'America/Chicago',
  },
  '74364': {
    zip: '74364',
    city: 'Rose',
    state: 'OK',
    latitude: '36.219382',
    longitude: '-94.986746',
    timeZoneId: 'America/Chicago',
  },
  '74365': {
    zip: '74365',
    city: 'Salina',
    state: 'OK',
    latitude: '36.295542',
    longitude: '-95.15223',
    timeZoneId: 'America/Chicago',
  },
  '74366': {
    zip: '74366',
    city: 'Spavinaw',
    state: 'OK',
    latitude: '36.430107',
    longitude: '-95.011716',
    timeZoneId: 'America/Chicago',
  },
  '74367': {
    zip: '74367',
    city: 'Strang',
    state: 'OK',
    latitude: '36.454942',
    longitude: '-95.083903',
    timeZoneId: 'America/Chicago',
  },
  '74368': {
    zip: '74368',
    city: 'Twin Oaks',
    state: 'OK',
    latitude: '36.196118',
    longitude: '-94.866615',
    timeZoneId: 'America/Chicago',
  },
  '74369': {
    zip: '74369',
    city: 'Welch',
    state: 'OK',
    latitude: '36.87638',
    longitude: '-95.089888',
    timeZoneId: 'America/Chicago',
  },
  '74370': {
    zip: '74370',
    city: 'Wyandotte',
    state: 'OK',
    latitude: '36.798358',
    longitude: '-94.718688',
    timeZoneId: 'America/Chicago',
  },
  '74401': {
    zip: '74401',
    city: 'Muskogee',
    state: 'OK',
    latitude: '35.7399',
    longitude: '-95.38494',
    timeZoneId: 'America/Chicago',
  },
  '74402': {
    zip: '74402',
    city: 'Muskogee',
    state: 'OK',
    latitude: '35.761119',
    longitude: '-95.375475',
    timeZoneId: 'America/Chicago',
  },
  '74403': {
    zip: '74403',
    city: 'Muskogee',
    state: 'OK',
    latitude: '35.740245',
    longitude: '-95.348429',
    timeZoneId: 'America/Chicago',
  },
  '74421': {
    zip: '74421',
    city: 'Beggs',
    state: 'OK',
    latitude: '35.743595',
    longitude: '-96.06823',
    timeZoneId: 'America/Chicago',
  },
  '74422': {
    zip: '74422',
    city: 'Boynton',
    state: 'OK',
    latitude: '35.664613',
    longitude: '-95.672648',
    timeZoneId: 'America/Chicago',
  },
  '74423': {
    zip: '74423',
    city: 'Braggs',
    state: 'OK',
    latitude: '35.666178',
    longitude: '-95.179407',
    timeZoneId: 'America/Chicago',
  },
  '74425': {
    zip: '74425',
    city: 'Canadian',
    state: 'OK',
    latitude: '35.156646',
    longitude: '-95.618903',
    timeZoneId: 'America/Chicago',
  },
  '74426': {
    zip: '74426',
    city: 'Checotah',
    state: 'OK',
    latitude: '35.4177',
    longitude: '-95.55378',
    timeZoneId: 'America/Chicago',
  },
  '74427': {
    zip: '74427',
    city: 'Cookson',
    state: 'OK',
    latitude: '35.81181',
    longitude: '-95.076551',
    timeZoneId: 'America/Chicago',
  },
  '74428': {
    zip: '74428',
    city: 'Council Hill',
    state: 'OK',
    latitude: '35.536613',
    longitude: '-95.713225',
    timeZoneId: 'America/Chicago',
  },
  '74429': {
    zip: '74429',
    city: 'Coweta',
    state: 'OK',
    latitude: '35.956129',
    longitude: '-95.658301',
    timeZoneId: 'America/Chicago',
  },
  '74430': {
    zip: '74430',
    city: 'Crowder',
    state: 'OK',
    latitude: '35.125899',
    longitude: '-95.670092',
    timeZoneId: 'America/Chicago',
  },
  '74431': {
    zip: '74431',
    city: 'Dewar',
    state: 'OK',
    latitude: '35.447591',
    longitude: '-95.96415',
    timeZoneId: 'America/Chicago',
  },
  '74432': {
    zip: '74432',
    city: 'Eufaula',
    state: 'OK',
    latitude: '35.288414',
    longitude: '-95.585124',
    timeZoneId: 'America/Chicago',
  },
  '74434': {
    zip: '74434',
    city: 'Fort Gibson',
    state: 'OK',
    latitude: '35.80185',
    longitude: '-95.25093',
    timeZoneId: 'America/Chicago',
  },
  '74435': {
    zip: '74435',
    city: 'Gore',
    state: 'OK',
    latitude: '35.530194',
    longitude: '-95.1179',
    timeZoneId: 'America/Chicago',
  },
  '74436': {
    zip: '74436',
    city: 'Haskell',
    state: 'OK',
    latitude: '35.820529',
    longitude: '-95.670862',
    timeZoneId: 'America/Chicago',
  },
  '74437': {
    zip: '74437',
    city: 'Henryetta',
    state: 'OK',
    latitude: '35.44128',
    longitude: '-95.96868',
    timeZoneId: 'America/Chicago',
  },
  '74438': {
    zip: '74438',
    city: 'Hitchita',
    state: 'OK',
    latitude: '35.519756',
    longitude: '-95.752635',
    timeZoneId: 'America/Chicago',
  },
  '74439': {
    zip: '74439',
    city: 'Braggs',
    state: 'OK',
    latitude: '35.66777',
    longitude: '-95.198709',
    timeZoneId: 'America/Chicago',
  },
  '74440': {
    zip: '74440',
    city: 'Hoyt',
    state: 'OK',
    latitude: '35.271675',
    longitude: '-95.303078',
    timeZoneId: 'America/Chicago',
  },
  '74441': {
    zip: '74441',
    city: 'Hulbert',
    state: 'OK',
    latitude: '35.94667',
    longitude: '-95.23003',
    timeZoneId: 'America/Chicago',
  },
  '74442': {
    zip: '74442',
    city: 'Indianola',
    state: 'OK',
    latitude: '35.098337',
    longitude: '-95.798323',
    timeZoneId: 'America/Chicago',
  },
  '74444': {
    zip: '74444',
    city: 'Moodys',
    state: 'OK',
    latitude: '36.028524',
    longitude: '-94.955765',
    timeZoneId: 'America/Chicago',
  },
  '74445': {
    zip: '74445',
    city: 'Morris',
    state: 'OK',
    latitude: '35.60874',
    longitude: '-95.861578',
    timeZoneId: 'America/Chicago',
  },
  '74446': {
    zip: '74446',
    city: 'Okay',
    state: 'OK',
    latitude: '35.848789',
    longitude: '-95.315507',
    timeZoneId: 'America/Chicago',
  },
  '74447': {
    zip: '74447',
    city: 'Okmulgee',
    state: 'OK',
    latitude: '35.623341',
    longitude: '-95.970246',
    timeZoneId: 'America/Chicago',
  },
  '74450': {
    zip: '74450',
    city: 'Oktaha',
    state: 'OK',
    latitude: '35.631644',
    longitude: '-95.511202',
    timeZoneId: 'America/Chicago',
  },
  '74451': {
    zip: '74451',
    city: 'Park Hill',
    state: 'OK',
    latitude: '35.649292',
    longitude: '-95.023172',
    timeZoneId: 'America/Chicago',
  },
  '74452': {
    zip: '74452',
    city: 'Peggs',
    state: 'OK',
    latitude: '36.081793',
    longitude: '-94.980751',
    timeZoneId: 'America/Chicago',
  },
  '74454': {
    zip: '74454',
    city: 'Porter',
    state: 'OK',
    latitude: '35.847041',
    longitude: '-95.511485',
    timeZoneId: 'America/Chicago',
  },
  '74455': {
    zip: '74455',
    city: 'Porum',
    state: 'OK',
    latitude: '35.364609',
    longitude: '-95.248583',
    timeZoneId: 'America/Chicago',
  },
  '74456': {
    zip: '74456',
    city: 'Preston',
    state: 'OK',
    latitude: '35.710379',
    longitude: '-95.990731',
    timeZoneId: 'America/Chicago',
  },
  '74457': {
    zip: '74457',
    city: 'Proctor',
    state: 'OK',
    latitude: '35.96872',
    longitude: '-94.744798',
    timeZoneId: 'America/Chicago',
  },
  '74458': {
    zip: '74458',
    city: 'Redbird',
    state: 'OK',
    latitude: '35.885284',
    longitude: '-95.587137',
    timeZoneId: 'America/Chicago',
  },
  '74459': {
    zip: '74459',
    city: 'Rentiesville',
    state: 'OK',
    latitude: '35.521333',
    longitude: '-95.492865',
    timeZoneId: 'America/Chicago',
  },
  '74460': {
    zip: '74460',
    city: 'Schulter',
    state: 'OK',
    latitude: '35.512604',
    longitude: '-95.95885',
    timeZoneId: 'America/Chicago',
  },
  '74461': {
    zip: '74461',
    city: 'Stidham',
    state: 'OK',
    latitude: '35.38673',
    longitude: '-95.708509',
    timeZoneId: 'America/Chicago',
  },
  '74462': {
    zip: '74462',
    city: 'Stigler',
    state: 'OK',
    latitude: '35.26584',
    longitude: '-95.11506',
    timeZoneId: 'America/Chicago',
  },
  '74463': {
    zip: '74463',
    city: 'Taft',
    state: 'OK',
    latitude: '35.761067',
    longitude: '-95.546426',
    timeZoneId: 'America/Chicago',
  },
  '74464': {
    zip: '74464',
    city: 'Tahlequah',
    state: 'OK',
    latitude: '35.913588',
    longitude: '-94.967828',
    timeZoneId: 'America/Chicago',
  },
  '74465': {
    zip: '74465',
    city: 'Tahlequah',
    state: 'OK',
    latitude: '35.906127',
    longitude: '-94.985529',
    timeZoneId: 'America/Chicago',
  },
  '74467': {
    zip: '74467',
    city: 'Wagoner',
    state: 'OK',
    latitude: '35.957074',
    longitude: '-95.369537',
    timeZoneId: 'America/Chicago',
  },
  '74468': {
    zip: '74468',
    city: 'Wainwright',
    state: 'OK',
    latitude: '35.616788',
    longitude: '-95.565724',
    timeZoneId: 'America/Chicago',
  },
  '74469': {
    zip: '74469',
    city: 'Warner',
    state: 'OK',
    latitude: '35.489455',
    longitude: '-95.302917',
    timeZoneId: 'America/Chicago',
  },
  '74470': {
    zip: '74470',
    city: 'Webbers Falls',
    state: 'OK',
    latitude: '35.508872',
    longitude: '-95.126074',
    timeZoneId: 'America/Chicago',
  },
  '74471': {
    zip: '74471',
    city: 'Welling',
    state: 'OK',
    latitude: '35.918573',
    longitude: '-94.853344',
    timeZoneId: 'America/Chicago',
  },
  '74472': {
    zip: '74472',
    city: 'Whitefield',
    state: 'OK',
    latitude: '35.250058',
    longitude: '-95.226723',
    timeZoneId: 'America/Chicago',
  },
  '74477': {
    zip: '74477',
    city: 'Wagoner',
    state: 'OK',
    latitude: '35.959752',
    longitude: '-95.371077',
    timeZoneId: 'America/Chicago',
  },
  '74501': {
    zip: '74501',
    city: 'McAlester',
    state: 'OK',
    latitude: '34.935281',
    longitude: '-95.767139',
    timeZoneId: 'America/Chicago',
  },
  '74502': {
    zip: '74502',
    city: 'McAlester',
    state: 'OK',
    latitude: '34.930217',
    longitude: '-95.766611',
    timeZoneId: 'America/Chicago',
  },
  '74521': {
    zip: '74521',
    city: 'Albion',
    state: 'OK',
    latitude: '34.661736',
    longitude: '-95.098277',
    timeZoneId: 'America/Chicago',
  },
  '74522': {
    zip: '74522',
    city: 'Alderson',
    state: 'OK',
    latitude: '34.89891',
    longitude: '-95.693249',
    timeZoneId: 'America/Chicago',
  },
  '74523': {
    zip: '74523',
    city: 'Antlers',
    state: 'OK',
    latitude: '34.232845',
    longitude: '-95.618454',
    timeZoneId: 'America/Chicago',
  },
  '74525': {
    zip: '74525',
    city: 'Atoka',
    state: 'OK',
    latitude: '34.385284',
    longitude: '-96.13211',
    timeZoneId: 'America/Chicago',
  },
  '74528': {
    zip: '74528',
    city: 'Blanco',
    state: 'OK',
    latitude: '34.769841',
    longitude: '-95.79596',
    timeZoneId: 'America/Chicago',
  },
  '74529': {
    zip: '74529',
    city: 'Blocker',
    state: 'OK',
    latitude: '35.065008',
    longitude: '-95.569088',
    timeZoneId: 'America/Chicago',
  },
  '74530': {
    zip: '74530',
    city: 'Bromide',
    state: 'OK',
    latitude: '34.418606',
    longitude: '-96.498273',
    timeZoneId: 'America/Chicago',
  },
  '74531': {
    zip: '74531',
    city: 'Calvin',
    state: 'OK',
    latitude: '34.835012',
    longitude: '-96.293203',
    timeZoneId: 'America/Chicago',
  },
  '74533': {
    zip: '74533',
    city: 'Caney',
    state: 'OK',
    latitude: '34.212503',
    longitude: '-96.268979',
    timeZoneId: 'America/Chicago',
  },
  '74534': {
    zip: '74534',
    city: 'Centrahoma',
    state: 'OK',
    latitude: '34.596642',
    longitude: '-96.323303',
    timeZoneId: 'America/Chicago',
  },
  '74535': {
    zip: '74535',
    city: 'Clarita',
    state: 'OK',
    latitude: '34.485498',
    longitude: '-96.430578',
    timeZoneId: 'America/Chicago',
  },
  '74536': {
    zip: '74536',
    city: 'Clayton',
    state: 'OK',
    latitude: '34.574176',
    longitude: '-95.482504',
    timeZoneId: 'America/Chicago',
  },
  '74538': {
    zip: '74538',
    city: 'Coalgate',
    state: 'OK',
    latitude: '34.534003',
    longitude: '-96.219571',
    timeZoneId: 'America/Chicago',
  },
  '74540': {
    zip: '74540',
    city: 'Daisy',
    state: 'OK',
    latitude: '34.548938',
    longitude: '-95.733571',
    timeZoneId: 'America/Chicago',
  },
  '74543': {
    zip: '74543',
    city: 'Finley',
    state: 'OK',
    latitude: '34.347092',
    longitude: '-95.550322',
    timeZoneId: 'America/Chicago',
  },
  '74545': {
    zip: '74545',
    city: 'Gowen',
    state: 'OK',
    latitude: '34.881825',
    longitude: '-95.479038',
    timeZoneId: 'America/Chicago',
  },
  '74546': {
    zip: '74546',
    city: 'Haileyville',
    state: 'OK',
    latitude: '34.845081',
    longitude: '-95.566691',
    timeZoneId: 'America/Chicago',
  },
  '74547': {
    zip: '74547',
    city: 'Hartshorne',
    state: 'OK',
    latitude: '34.846686',
    longitude: '-95.558479',
    timeZoneId: 'America/Chicago',
  },
  '74549': {
    zip: '74549',
    city: 'Honobia',
    state: 'OK',
    latitude: '34.53931',
    longitude: '-94.93819',
    timeZoneId: 'America/Chicago',
  },
  '74552': {
    zip: '74552',
    city: 'Kinta',
    state: 'OK',
    latitude: '35.176937',
    longitude: '-95.297329',
    timeZoneId: 'America/Chicago',
  },
  '74553': {
    zip: '74553',
    city: 'Kiowa',
    state: 'OK',
    latitude: '34.724208',
    longitude: '-95.904396',
    timeZoneId: 'America/Chicago',
  },
  '74554': {
    zip: '74554',
    city: 'Krebs',
    state: 'OK',
    latitude: '34.930835',
    longitude: '-95.722322',
    timeZoneId: 'America/Chicago',
  },
  '74555': {
    zip: '74555',
    city: 'Lane',
    state: 'OK',
    latitude: '34.389315',
    longitude: '-96.124352',
    timeZoneId: 'America/Chicago',
  },
  '74556': {
    zip: '74556',
    city: 'Lehigh',
    state: 'OK',
    latitude: '34.473773',
    longitude: '-96.221551',
    timeZoneId: 'America/Chicago',
  },
  '74557': {
    zip: '74557',
    city: 'Moyers',
    state: 'OK',
    latitude: '34.35274',
    longitude: '-95.672687',
    timeZoneId: 'America/Chicago',
  },
  '74558': {
    zip: '74558',
    city: 'Nashoba',
    state: 'OK',
    latitude: '34.529936',
    longitude: '-95.182144',
    timeZoneId: 'America/Chicago',
  },
  '74559': {
    zip: '74559',
    city: 'Panola',
    state: 'OK',
    latitude: '34.928265',
    longitude: '-95.211064',
    timeZoneId: 'America/Chicago',
  },
  '74560': {
    zip: '74560',
    city: 'Pittsburg',
    state: 'OK',
    latitude: '34.658705',
    longitude: '-95.813697',
    timeZoneId: 'America/Chicago',
  },
  '74561': {
    zip: '74561',
    city: 'Quinton',
    state: 'OK',
    latitude: '35.108516',
    longitude: '-95.511932',
    timeZoneId: 'America/Chicago',
  },
  '74562': {
    zip: '74562',
    city: 'Rattan',
    state: 'OK',
    latitude: '34.308134',
    longitude: '-95.266861',
    timeZoneId: 'America/Chicago',
  },
  '74563': {
    zip: '74563',
    city: 'Red Oak',
    state: 'OK',
    latitude: '34.94249',
    longitude: '-95.080667',
    timeZoneId: 'America/Chicago',
  },
  '74565': {
    zip: '74565',
    city: 'Savanna',
    state: 'OK',
    latitude: '34.828744',
    longitude: '-95.834824',
    timeZoneId: 'America/Chicago',
  },
  '74567': {
    zip: '74567',
    city: 'Snow',
    state: 'OK',
    latitude: '34.442369',
    longitude: '-95.423848',
    timeZoneId: 'America/Chicago',
  },
  '74569': {
    zip: '74569',
    city: 'Stringtown',
    state: 'OK',
    latitude: '34.465763',
    longitude: '-96.049636',
    timeZoneId: 'America/Chicago',
  },
  '74570': {
    zip: '74570',
    city: 'Stuart',
    state: 'OK',
    latitude: '34.895073',
    longitude: '-96.157838',
    timeZoneId: 'America/Chicago',
  },
  '74571': {
    zip: '74571',
    city: 'Talihina',
    state: 'OK',
    latitude: '34.752849',
    longitude: '-95.033114',
    timeZoneId: 'America/Chicago',
  },
  '74572': {
    zip: '74572',
    city: 'Tupelo',
    state: 'OK',
    latitude: '34.604978',
    longitude: '-96.414543',
    timeZoneId: 'America/Chicago',
  },
  '74574': {
    zip: '74574',
    city: 'Tuskahoma',
    state: 'OK',
    latitude: '34.739958',
    longitude: '-95.264133',
    timeZoneId: 'America/Chicago',
  },
  '74576': {
    zip: '74576',
    city: 'Wardville',
    state: 'OK',
    latitude: '34.609432',
    longitude: '-95.997575',
    timeZoneId: 'America/Chicago',
  },
  '74577': {
    zip: '74577',
    city: 'Whitesboro',
    state: 'OK',
    latitude: '34.690803',
    longitude: '-94.857714',
    timeZoneId: 'America/Chicago',
  },
  '74578': {
    zip: '74578',
    city: 'Wilburton',
    state: 'OK',
    latitude: '34.914894',
    longitude: '-95.309389',
    timeZoneId: 'America/Chicago',
  },
  '74601': {
    zip: '74601',
    city: 'Ponca City',
    state: 'OK',
    latitude: '36.709082',
    longitude: '-97.087737',
    timeZoneId: 'America/Chicago',
  },
  '74602': {
    zip: '74602',
    city: 'Ponca City',
    state: 'OK',
    latitude: '36.720461',
    longitude: '-97.080194',
    timeZoneId: 'America/Chicago',
  },
  '74604': {
    zip: '74604',
    city: 'Ponca City',
    state: 'OK',
    latitude: '36.72576',
    longitude: '-97.042075',
    timeZoneId: 'America/Chicago',
  },
  '74630': {
    zip: '74630',
    city: 'Billings',
    state: 'OK',
    latitude: '36.530507',
    longitude: '-97.448759',
    timeZoneId: 'America/Chicago',
  },
  '74631': {
    zip: '74631',
    city: 'Blackwell',
    state: 'OK',
    latitude: '36.799089',
    longitude: '-97.288017',
    timeZoneId: 'America/Chicago',
  },
  '74632': {
    zip: '74632',
    city: 'Braman',
    state: 'OK',
    latitude: '36.92341',
    longitude: '-97.333871',
    timeZoneId: 'America/Chicago',
  },
  '74633': {
    zip: '74633',
    city: 'Burbank',
    state: 'OK',
    latitude: '36.69426',
    longitude: '-96.730275',
    timeZoneId: 'America/Chicago',
  },
  '74636': {
    zip: '74636',
    city: 'Deer Creek',
    state: 'OK',
    latitude: '36.804851',
    longitude: '-97.507404',
    timeZoneId: 'America/Chicago',
  },
  '74637': {
    zip: '74637',
    city: 'Fairfax',
    state: 'OK',
    latitude: '36.570143',
    longitude: '-96.705536',
    timeZoneId: 'America/Chicago',
  },
  '74640': {
    zip: '74640',
    city: 'Hunter',
    state: 'OK',
    latitude: '36.569161',
    longitude: '-97.599711',
    timeZoneId: 'America/Chicago',
  },
  '74641': {
    zip: '74641',
    city: 'Kaw City',
    state: 'OK',
    latitude: '36.76435',
    longitude: '-96.861771',
    timeZoneId: 'America/Chicago',
  },
  '74643': {
    zip: '74643',
    city: 'Lamont',
    state: 'OK',
    latitude: '36.690943',
    longitude: '-97.556922',
    timeZoneId: 'America/Chicago',
  },
  '74644': {
    zip: '74644',
    city: 'Marland',
    state: 'OK',
    latitude: '36.558251',
    longitude: '-97.153129',
    timeZoneId: 'America/Chicago',
  },
  '74646': {
    zip: '74646',
    city: 'Nardin',
    state: 'OK',
    latitude: '36.806061',
    longitude: '-97.449659',
    timeZoneId: 'America/Chicago',
  },
  '74647': {
    zip: '74647',
    city: 'Newkirk',
    state: 'OK',
    latitude: '36.884362',
    longitude: '-97.05562',
    timeZoneId: 'America/Chicago',
  },
  '74650': {
    zip: '74650',
    city: 'Ralston',
    state: 'OK',
    latitude: '36.494627',
    longitude: '-96.782266',
    timeZoneId: 'America/Chicago',
  },
  '74651': {
    zip: '74651',
    city: 'Red Rock',
    state: 'OK',
    latitude: '36.476998',
    longitude: '-97.170438',
    timeZoneId: 'America/Chicago',
  },
  '74652': {
    zip: '74652',
    city: 'Shidler',
    state: 'OK',
    latitude: '36.872222',
    longitude: '-96.565278',
    timeZoneId: 'America/Chicago',
  },
  '74653': {
    zip: '74653',
    city: 'Tonkawa',
    state: 'OK',
    latitude: '36.679522',
    longitude: '-97.302551',
    timeZoneId: 'America/Chicago',
  },
  '74701': {
    zip: '74701',
    city: 'Durant',
    state: 'OK',
    latitude: '34.003629',
    longitude: '-96.383237',
    timeZoneId: 'America/Chicago',
  },
  '74702': {
    zip: '74702',
    city: 'Durant',
    state: 'OK',
    latitude: '33.932203',
    longitude: '-96.435229',
    timeZoneId: 'America/Chicago',
  },
  '74720': {
    zip: '74720',
    city: 'Achille',
    state: 'OK',
    latitude: '33.924563',
    longitude: '-96.265123',
    timeZoneId: 'America/Chicago',
  },
  '74721': {
    zip: '74721',
    city: 'Albany',
    state: 'OK',
    latitude: '33.879118',
    longitude: '-96.16339',
    timeZoneId: 'America/Chicago',
  },
  '74722': {
    zip: '74722',
    city: 'Battiest',
    state: 'OK',
    latitude: '34.392641',
    longitude: '-94.924286',
    timeZoneId: 'America/Chicago',
  },
  '74723': {
    zip: '74723',
    city: 'Bennington',
    state: 'OK',
    latitude: '33.99828',
    longitude: '-96.01392',
    timeZoneId: 'America/Chicago',
  },
  '74724': {
    zip: '74724',
    city: 'Bethel',
    state: 'OK',
    latitude: '34.354735',
    longitude: '-94.843908',
    timeZoneId: 'America/Chicago',
  },
  '74726': {
    zip: '74726',
    city: 'Bokchito',
    state: 'OK',
    latitude: '34.016496',
    longitude: '-96.139454',
    timeZoneId: 'America/Chicago',
  },
  '74727': {
    zip: '74727',
    city: 'Boswell',
    state: 'OK',
    latitude: '34.032558',
    longitude: '-95.831904',
    timeZoneId: 'America/Chicago',
  },
  '74728': {
    zip: '74728',
    city: 'Broken Bow',
    state: 'OK',
    latitude: '34.027544',
    longitude: '-94.73475',
    timeZoneId: 'America/Chicago',
  },
  '74729': {
    zip: '74729',
    city: 'Caddo',
    state: 'OK',
    latitude: '34.125607',
    longitude: '-96.265459',
    timeZoneId: 'America/Chicago',
  },
  '74730': {
    zip: '74730',
    city: 'Calera',
    state: 'OK',
    latitude: '33.93633',
    longitude: '-96.430353',
    timeZoneId: 'America/Chicago',
  },
  '74731': {
    zip: '74731',
    city: 'Cartwright',
    state: 'OK',
    latitude: '33.885022',
    longitude: '-96.547185',
    timeZoneId: 'America/Chicago',
  },
  '74733': {
    zip: '74733',
    city: 'Colbert',
    state: 'OK',
    latitude: '33.858023',
    longitude: '-96.505622',
    timeZoneId: 'America/Chicago',
  },
  '74734': {
    zip: '74734',
    city: 'Eagletown',
    state: 'OK',
    latitude: '34.072256',
    longitude: '-94.559329',
    timeZoneId: 'America/Chicago',
  },
  '74735': {
    zip: '74735',
    city: 'Fort Towson',
    state: 'OK',
    latitude: '34.0492',
    longitude: '-95.260121',
    timeZoneId: 'America/Chicago',
  },
  '74736': {
    zip: '74736',
    city: 'Garvin',
    state: 'OK',
    latitude: '33.922045',
    longitude: '-94.933085',
    timeZoneId: 'America/Chicago',
  },
  '74737': {
    zip: '74737',
    city: 'Golden',
    state: 'OK',
    latitude: '34.035793',
    longitude: '-94.893397',
    timeZoneId: 'America/Chicago',
  },
  '74738': {
    zip: '74738',
    city: 'Grant',
    state: 'OK',
    latitude: '33.91125',
    longitude: '-95.467687',
    timeZoneId: 'America/Chicago',
  },
  '74740': {
    zip: '74740',
    city: 'Haworth',
    state: 'OK',
    latitude: '33.845727',
    longitude: '-94.648373',
    timeZoneId: 'America/Chicago',
  },
  '74741': {
    zip: '74741',
    city: 'Hendrix',
    state: 'OK',
    latitude: '33.792515',
    longitude: '-96.312344',
    timeZoneId: 'America/Chicago',
  },
  '74743': {
    zip: '74743',
    city: 'Hugo',
    state: 'OK',
    latitude: '34.00566',
    longitude: '-95.513972',
    timeZoneId: 'America/Chicago',
  },
  '74745': {
    zip: '74745',
    city: 'Idabel',
    state: 'OK',
    latitude: '33.894371',
    longitude: '-94.822174',
    timeZoneId: 'America/Chicago',
  },
  '74747': {
    zip: '74747',
    city: 'Kemp',
    state: 'OK',
    latitude: '33.769653',
    longitude: '-96.355469',
    timeZoneId: 'America/Chicago',
  },
  '74748': {
    zip: '74748',
    city: 'Kenefic',
    state: 'OK',
    latitude: '34.124931',
    longitude: '-96.409322',
    timeZoneId: 'America/Chicago',
  },
  '74750': {
    zip: '74750',
    city: 'Millerton',
    state: 'OK',
    latitude: '33.991302',
    longitude: '-95.020656',
    timeZoneId: 'America/Chicago',
  },
  '74752': {
    zip: '74752',
    city: 'Pickens',
    state: 'OK',
    latitude: '34.394987',
    longitude: '-95.012194',
    timeZoneId: 'America/Chicago',
  },
  '74753': {
    zip: '74753',
    city: 'Platter',
    state: 'OK',
    latitude: '33.906949',
    longitude: '-96.532507',
    timeZoneId: 'America/Chicago',
  },
  '74754': {
    zip: '74754',
    city: 'Ringold',
    state: 'OK',
    latitude: '34.195523',
    longitude: '-95.071816',
    timeZoneId: 'America/Chicago',
  },
  '74755': {
    zip: '74755',
    city: 'Rufe',
    state: 'OK',
    latitude: '34.159058',
    longitude: '-95.125044',
    timeZoneId: 'America/Chicago',
  },
  '74756': {
    zip: '74756',
    city: 'Sawyer',
    state: 'OK',
    latitude: '34.047128',
    longitude: '-95.363473',
    timeZoneId: 'America/Chicago',
  },
  '74759': {
    zip: '74759',
    city: 'Soper',
    state: 'OK',
    latitude: '34.020552',
    longitude: '-95.687894',
    timeZoneId: 'America/Chicago',
  },
  '74760': {
    zip: '74760',
    city: 'Spencerville',
    state: 'OK',
    latitude: '34.132245',
    longitude: '-95.393533',
    timeZoneId: 'America/Chicago',
  },
  '74761': {
    zip: '74761',
    city: 'Swink',
    state: 'OK',
    latitude: '34.017732',
    longitude: '-95.198626',
    timeZoneId: 'America/Chicago',
  },
  '74764': {
    zip: '74764',
    city: 'Valliant',
    state: 'OK',
    latitude: '34.00026',
    longitude: '-95.092994',
    timeZoneId: 'America/Chicago',
  },
  '74766': {
    zip: '74766',
    city: 'Wright City',
    state: 'OK',
    latitude: '34.153986',
    longitude: '-94.980004',
    timeZoneId: 'America/Chicago',
  },
  '74801': {
    zip: '74801',
    city: 'Shawnee',
    state: 'OK',
    latitude: '35.309893',
    longitude: '-96.920802',
    timeZoneId: 'America/Chicago',
  },
  '74802': {
    zip: '74802',
    city: 'Shawnee',
    state: 'OK',
    latitude: '35.325355',
    longitude: '-96.922237',
    timeZoneId: 'America/Chicago',
  },
  '74804': {
    zip: '74804',
    city: 'Shawnee',
    state: 'OK',
    latitude: '35.405591',
    longitude: '-96.853291',
    timeZoneId: 'America/Chicago',
  },
  '74818': {
    zip: '74818',
    city: 'Seminole',
    state: 'OK',
    latitude: '35.225036',
    longitude: '-96.67054',
    timeZoneId: 'America/Chicago',
  },
  '74820': {
    zip: '74820',
    city: 'Ada',
    state: 'OK',
    latitude: '34.77192',
    longitude: '-96.67998',
    timeZoneId: 'America/Chicago',
  },
  '74821': {
    zip: '74821',
    city: 'Ada',
    state: 'OK',
    latitude: '34.777081',
    longitude: '-96.679427',
    timeZoneId: 'America/Chicago',
  },
  '74824': {
    zip: '74824',
    city: 'Agra',
    state: 'OK',
    latitude: '35.896999',
    longitude: '-96.873429',
    timeZoneId: 'America/Chicago',
  },
  '74825': {
    zip: '74825',
    city: 'Allen',
    state: 'OK',
    latitude: '34.877567',
    longitude: '-96.412377',
    timeZoneId: 'America/Chicago',
  },
  '74826': {
    zip: '74826',
    city: 'Asher',
    state: 'OK',
    latitude: '34.966755',
    longitude: '-96.860038',
    timeZoneId: 'America/Chicago',
  },
  '74827': {
    zip: '74827',
    city: 'Atwood',
    state: 'OK',
    latitude: '34.930129',
    longitude: '-96.347737',
    timeZoneId: 'America/Chicago',
  },
  '74829': {
    zip: '74829',
    city: 'Boley',
    state: 'OK',
    latitude: '35.499313',
    longitude: '-96.485265',
    timeZoneId: 'America/Chicago',
  },
  '74830': {
    zip: '74830',
    city: 'Bowlegs',
    state: 'OK',
    latitude: '35.14772',
    longitude: '-96.671721',
    timeZoneId: 'America/Chicago',
  },
  '74831': {
    zip: '74831',
    city: 'Byars',
    state: 'OK',
    latitude: '34.873693',
    longitude: '-97.047649',
    timeZoneId: 'America/Chicago',
  },
  '74832': {
    zip: '74832',
    city: 'Carney',
    state: 'OK',
    latitude: '35.806618',
    longitude: '-97.018059',
    timeZoneId: 'America/Chicago',
  },
  '74833': {
    zip: '74833',
    city: 'Castle',
    state: 'OK',
    latitude: '35.481185',
    longitude: '-96.369933',
    timeZoneId: 'America/Chicago',
  },
  '74834': {
    zip: '74834',
    city: 'Chandler',
    state: 'OK',
    latitude: '35.705912',
    longitude: '-96.884924',
    timeZoneId: 'America/Chicago',
  },
  '74836': {
    zip: '74836',
    city: 'Connerville',
    state: 'OK',
    latitude: '34.357545',
    longitude: '-96.659671',
    timeZoneId: 'America/Chicago',
  },
  '74837': {
    zip: '74837',
    city: 'Cromwell',
    state: 'OK',
    latitude: '35.347991',
    longitude: '-96.457583',
    timeZoneId: 'America/Chicago',
  },
  '74839': {
    zip: '74839',
    city: 'Dustin',
    state: 'OK',
    latitude: '35.225025',
    longitude: '-96.067976',
    timeZoneId: 'America/Chicago',
  },
  '74840': {
    zip: '74840',
    city: 'Earlsboro',
    state: 'OK',
    latitude: '35.250122',
    longitude: '-96.803354',
    timeZoneId: 'America/Chicago',
  },
  '74842': {
    zip: '74842',
    city: 'Fittstown',
    state: 'OK',
    latitude: '34.623212',
    longitude: '-96.67366',
    timeZoneId: 'America/Chicago',
  },
  '74843': {
    zip: '74843',
    city: 'Fitzhugh',
    state: 'OK',
    latitude: '34.660154',
    longitude: '-96.766516',
    timeZoneId: 'America/Chicago',
  },
  '74844': {
    zip: '74844',
    city: 'Francis',
    state: 'OK',
    latitude: '34.873807',
    longitude: '-96.591929',
    timeZoneId: 'America/Chicago',
  },
  '74845': {
    zip: '74845',
    city: 'Hanna',
    state: 'OK',
    latitude: '35.215556',
    longitude: '-95.925556',
    timeZoneId: 'America/Chicago',
  },
  '74848': {
    zip: '74848',
    city: 'Holdenville',
    state: 'OK',
    latitude: '35.083745',
    longitude: '-96.39905',
    timeZoneId: 'America/Chicago',
  },
  '74849': {
    zip: '74849',
    city: 'Konawa',
    state: 'OK',
    latitude: '34.958396',
    longitude: '-96.751473',
    timeZoneId: 'America/Chicago',
  },
  '74850': {
    zip: '74850',
    city: 'Lamar',
    state: 'OK',
    latitude: '35.102236',
    longitude: '-96.095414',
    timeZoneId: 'America/Chicago',
  },
  '74851': {
    zip: '74851',
    city: 'McLoud',
    state: 'OK',
    latitude: '35.4255',
    longitude: '-97.1046',
    timeZoneId: 'America/Chicago',
  },
  '74852': {
    zip: '74852',
    city: 'Macomb',
    state: 'OK',
    latitude: '35.118011',
    longitude: '-97.038526',
    timeZoneId: 'America/Chicago',
  },
  '74854': {
    zip: '74854',
    city: 'Maud',
    state: 'OK',
    latitude: '35.130126',
    longitude: '-96.778888',
    timeZoneId: 'America/Chicago',
  },
  '74855': {
    zip: '74855',
    city: 'Meeker',
    state: 'OK',
    latitude: '35.501783',
    longitude: '-96.896055',
    timeZoneId: 'America/Chicago',
  },
  '74856': {
    zip: '74856',
    city: 'Mill Creek',
    state: 'OK',
    latitude: '34.338856',
    longitude: '-96.821143',
    timeZoneId: 'America/Chicago',
  },
  '74857': {
    zip: '74857',
    city: 'Newalla',
    state: 'OK',
    latitude: '35.332436',
    longitude: '-97.199877',
    timeZoneId: 'America/Chicago',
  },
  '74859': {
    zip: '74859',
    city: 'Okemah',
    state: 'OK',
    latitude: '35.43146',
    longitude: '-96.305173',
    timeZoneId: 'America/Chicago',
  },
  '74860': {
    zip: '74860',
    city: 'Paden',
    state: 'OK',
    latitude: '35.506603',
    longitude: '-96.567615',
    timeZoneId: 'America/Chicago',
  },
  '74864': {
    zip: '74864',
    city: 'Prague',
    state: 'OK',
    latitude: '35.48513',
    longitude: '-96.685572',
    timeZoneId: 'America/Chicago',
  },
  '74865': {
    zip: '74865',
    city: 'Roff',
    state: 'OK',
    latitude: '34.632966',
    longitude: '-96.841801',
    timeZoneId: 'America/Chicago',
  },
  '74866': {
    zip: '74866',
    city: 'Saint Louis',
    state: 'OK',
    latitude: '35.081038',
    longitude: '-96.85348',
    timeZoneId: 'America/Chicago',
  },
  '74867': {
    zip: '74867',
    city: 'Sasakwa',
    state: 'OK',
    latitude: '34.954799',
    longitude: '-96.546147',
    timeZoneId: 'America/Chicago',
  },
  '74868': {
    zip: '74868',
    city: 'Seminole',
    state: 'OK',
    latitude: '35.234976',
    longitude: '-96.675123',
    timeZoneId: 'America/Chicago',
  },
  '74869': {
    zip: '74869',
    city: 'Sparks',
    state: 'OK',
    latitude: '35.623229',
    longitude: '-96.813151',
    timeZoneId: 'America/Chicago',
  },
  '74871': {
    zip: '74871',
    city: 'Stonewall',
    state: 'OK',
    latitude: '34.658077',
    longitude: '-96.52313',
    timeZoneId: 'America/Chicago',
  },
  '74872': {
    zip: '74872',
    city: 'Stratford',
    state: 'OK',
    latitude: '34.799153',
    longitude: '-96.953781',
    timeZoneId: 'America/Chicago',
  },
  '74873': {
    zip: '74873',
    city: 'Tecumseh',
    state: 'OK',
    latitude: '35.25996',
    longitude: '-96.97434',
    timeZoneId: 'America/Chicago',
  },
  '74875': {
    zip: '74875',
    city: 'Tryon',
    state: 'OK',
    latitude: '35.862942',
    longitude: '-97.04106',
    timeZoneId: 'America/Chicago',
  },
  '74878': {
    zip: '74878',
    city: 'Wanette',
    state: 'OK',
    latitude: '34.990948',
    longitude: '-97.03568',
    timeZoneId: 'America/Chicago',
  },
  '74880': {
    zip: '74880',
    city: 'Weleetka',
    state: 'OK',
    latitude: '35.334178',
    longitude: '-96.130338',
    timeZoneId: 'America/Chicago',
  },
  '74881': {
    zip: '74881',
    city: 'Wellston',
    state: 'OK',
    latitude: '35.6917',
    longitude: '-97.065941',
    timeZoneId: 'America/Chicago',
  },
  '74883': {
    zip: '74883',
    city: 'Wetumka',
    state: 'OK',
    latitude: '35.237743',
    longitude: '-96.239432',
    timeZoneId: 'America/Chicago',
  },
  '74884': {
    zip: '74884',
    city: 'Wewoka',
    state: 'OK',
    latitude: '35.16096',
    longitude: '-96.50334',
    timeZoneId: 'America/Chicago',
  },
  '74901': {
    zip: '74901',
    city: 'Arkoma',
    state: 'OK',
    latitude: '35.353477',
    longitude: '-94.437512',
    timeZoneId: 'America/Chicago',
  },
  '74902': {
    zip: '74902',
    city: 'Pocola',
    state: 'OK',
    latitude: '35.275011',
    longitude: '-94.448501',
    timeZoneId: 'America/Chicago',
  },
  '74930': {
    zip: '74930',
    city: 'Bokoshe',
    state: 'OK',
    latitude: '35.126751',
    longitude: '-94.801363',
    timeZoneId: 'America/Chicago',
  },
  '74931': {
    zip: '74931',
    city: 'Bunch',
    state: 'OK',
    latitude: '35.720151',
    longitude: '-94.741191',
    timeZoneId: 'America/Chicago',
  },
  '74932': {
    zip: '74932',
    city: 'Cameron',
    state: 'OK',
    latitude: '35.1502',
    longitude: '-94.494784',
    timeZoneId: 'America/Chicago',
  },
  '74935': {
    zip: '74935',
    city: 'Fanshawe',
    state: 'OK',
    latitude: '34.952107',
    longitude: '-94.906017',
    timeZoneId: 'America/Chicago',
  },
  '74936': {
    zip: '74936',
    city: 'Gans',
    state: 'OK',
    latitude: '35.389562',
    longitude: '-94.693076',
    timeZoneId: 'America/Chicago',
  },
  '74937': {
    zip: '74937',
    city: 'Heavener',
    state: 'OK',
    latitude: '34.886869',
    longitude: '-94.603696',
    timeZoneId: 'America/Chicago',
  },
  '74939': {
    zip: '74939',
    city: 'Hodgen',
    state: 'OK',
    latitude: '34.756875',
    longitude: '-94.602781',
    timeZoneId: 'America/Chicago',
  },
  '74940': {
    zip: '74940',
    city: 'Howe',
    state: 'OK',
    latitude: '34.92798',
    longitude: '-94.699886',
    timeZoneId: 'America/Chicago',
  },
  '74941': {
    zip: '74941',
    city: 'Keota',
    state: 'OK',
    latitude: '35.289455',
    longitude: '-94.900018',
    timeZoneId: 'America/Chicago',
  },
  '74942': {
    zip: '74942',
    city: 'Leflore',
    state: 'OK',
    latitude: '34.895363',
    longitude: '-94.979495',
    timeZoneId: 'America/Chicago',
  },
  '74943': {
    zip: '74943',
    city: 'Lequire',
    state: 'OK',
    latitude: '35.103491',
    longitude: '-95.110276',
    timeZoneId: 'America/Chicago',
  },
  '74944': {
    zip: '74944',
    city: 'McCurtain',
    state: 'OK',
    latitude: '35.130662',
    longitude: '-95.024409',
    timeZoneId: 'America/Chicago',
  },
  '74945': {
    zip: '74945',
    city: 'Marble City',
    state: 'OK',
    latitude: '35.579795',
    longitude: '-94.821443',
    timeZoneId: 'America/Chicago',
  },
  '74946': {
    zip: '74946',
    city: 'Moffett',
    state: 'OK',
    latitude: '35.389201',
    longitude: '-94.447746',
    timeZoneId: 'America/Chicago',
  },
  '74947': {
    zip: '74947',
    city: 'Monroe',
    state: 'OK',
    latitude: '34.993178',
    longitude: '-94.514611',
    timeZoneId: 'America/Chicago',
  },
  '74948': {
    zip: '74948',
    city: 'Muldrow',
    state: 'OK',
    latitude: '35.406109',
    longitude: '-94.595446',
    timeZoneId: 'America/Chicago',
  },
  '74949': {
    zip: '74949',
    city: 'Muse',
    state: 'OK',
    latitude: '34.676508',
    longitude: '-94.691874',
    timeZoneId: 'America/Chicago',
  },
  '74951': {
    zip: '74951',
    city: 'Panama',
    state: 'OK',
    latitude: '35.166368',
    longitude: '-94.667683',
    timeZoneId: 'America/Chicago',
  },
  '74953': {
    zip: '74953',
    city: 'Poteau',
    state: 'OK',
    latitude: '35.063987',
    longitude: '-94.618703',
    timeZoneId: 'America/Chicago',
  },
  '74954': {
    zip: '74954',
    city: 'Roland',
    state: 'OK',
    latitude: '35.417716',
    longitude: '-94.509455',
    timeZoneId: 'America/Chicago',
  },
  '74955': {
    zip: '74955',
    city: 'Sallisaw',
    state: 'OK',
    latitude: '35.458935',
    longitude: '-94.786707',
    timeZoneId: 'America/Chicago',
  },
  '74956': {
    zip: '74956',
    city: 'Shady Point',
    state: 'OK',
    latitude: '35.124932',
    longitude: '-94.673619',
    timeZoneId: 'America/Chicago',
  },
  '74957': {
    zip: '74957',
    city: 'Smithville',
    state: 'OK',
    latitude: '34.481497',
    longitude: '-94.582854',
    timeZoneId: 'America/Chicago',
  },
  '74959': {
    zip: '74959',
    city: 'Spiro',
    state: 'OK',
    latitude: '35.243226',
    longitude: '-94.61107',
    timeZoneId: 'America/Chicago',
  },
  '74960': {
    zip: '74960',
    city: 'Stilwell',
    state: 'OK',
    latitude: '35.817757',
    longitude: '-94.62605',
    timeZoneId: 'America/Chicago',
  },
  '74962': {
    zip: '74962',
    city: 'Vian',
    state: 'OK',
    latitude: '35.52728',
    longitude: '-94.963056',
    timeZoneId: 'America/Chicago',
  },
  '74963': {
    zip: '74963',
    city: 'Watson',
    state: 'OK',
    latitude: '34.315507',
    longitude: '-94.568242',
    timeZoneId: 'America/Chicago',
  },
  '74964': {
    zip: '74964',
    city: 'Watts',
    state: 'OK',
    latitude: '36.116613',
    longitude: '-94.660947',
    timeZoneId: 'America/Chicago',
  },
  '74965': {
    zip: '74965',
    city: 'Westville',
    state: 'OK',
    latitude: '35.989517',
    longitude: '-94.57226',
    timeZoneId: 'America/Chicago',
  },
  '74966': {
    zip: '74966',
    city: 'Wister',
    state: 'OK',
    latitude: '34.94172',
    longitude: '-94.815',
    timeZoneId: 'America/Chicago',
  },
  '75001': {
    zip: '75001',
    city: 'Addison',
    state: 'TX',
    latitude: '32.961429',
    longitude: '-96.829542',
    timeZoneId: 'America/Chicago',
  },
  '75002': {
    zip: '75002',
    city: 'Allen',
    state: 'TX',
    latitude: '33.08712',
    longitude: '-96.61698',
    timeZoneId: 'America/Chicago',
  },
  '75006': {
    zip: '75006',
    city: 'Carrollton',
    state: 'TX',
    latitude: '32.961351',
    longitude: '-96.891307',
    timeZoneId: 'America/Chicago',
  },
  '75007': {
    zip: '75007',
    city: 'Carrollton',
    state: 'TX',
    latitude: '33.006252',
    longitude: '-96.8866',
    timeZoneId: 'America/Chicago',
  },
  '75009': {
    zip: '75009',
    city: 'Celina',
    state: 'TX',
    latitude: '33.339332',
    longitude: '-96.761179',
    timeZoneId: 'America/Chicago',
  },
  '75010': {
    zip: '75010',
    city: 'Carrollton',
    state: 'TX',
    latitude: '33.027899',
    longitude: '-96.875883',
    timeZoneId: 'America/Chicago',
  },
  '75011': {
    zip: '75011',
    city: 'Carrollton',
    state: 'TX',
    latitude: '32.954424',
    longitude: '-96.889584',
    timeZoneId: 'America/Chicago',
  },
  '75013': {
    zip: '75013',
    city: 'Allen',
    state: 'TX',
    latitude: '33.113364',
    longitude: '-96.696357',
    timeZoneId: 'America/Chicago',
  },
  '75014': {
    zip: '75014',
    city: 'Irving',
    state: 'TX',
    latitude: '32.842082',
    longitude: '-96.970227',
    timeZoneId: 'America/Chicago',
  },
  '75015': {
    zip: '75015',
    city: 'Irving',
    state: 'TX',
    latitude: '32.895571',
    longitude: '-96.973722',
    timeZoneId: 'America/Chicago',
  },
  '75016': {
    zip: '75016',
    city: 'Irving',
    state: 'TX',
    latitude: '32.9019',
    longitude: '-96.978136',
    timeZoneId: 'America/Chicago',
  },
  '75017': {
    zip: '75017',
    city: 'Irving',
    state: 'TX',
    latitude: '32.811971',
    longitude: '-96.947107',
    timeZoneId: 'America/Chicago',
  },
  '75019': {
    zip: '75019',
    city: 'Coppell',
    state: 'TX',
    latitude: '32.96451',
    longitude: '-96.984901',
    timeZoneId: 'America/Chicago',
  },
  '75020': {
    zip: '75020',
    city: 'Denison',
    state: 'TX',
    latitude: '33.750177',
    longitude: '-96.559463',
    timeZoneId: 'America/Chicago',
  },
  '75021': {
    zip: '75021',
    city: 'Denison',
    state: 'TX',
    latitude: '33.755548',
    longitude: '-96.537942',
    timeZoneId: 'America/Chicago',
  },
  '75022': {
    zip: '75022',
    city: 'Flower Mound',
    state: 'TX',
    latitude: '33.014444',
    longitude: '-97.096667',
    timeZoneId: 'America/Chicago',
  },
  '75023': {
    zip: '75023',
    city: 'Plano',
    state: 'TX',
    latitude: '33.057653',
    longitude: '-96.737455',
    timeZoneId: 'America/Chicago',
  },
  '75024': {
    zip: '75024',
    city: 'Plano',
    state: 'TX',
    latitude: '33.076641',
    longitude: '-96.804029',
    timeZoneId: 'America/Chicago',
  },
  '75025': {
    zip: '75025',
    city: 'Plano',
    state: 'TX',
    latitude: '33.082934',
    longitude: '-96.752101',
    timeZoneId: 'America/Chicago',
  },
  '75026': {
    zip: '75026',
    city: 'Plano',
    state: 'TX',
    latitude: '33.039326',
    longitude: '-96.733277',
    timeZoneId: 'America/Chicago',
  },
  '75027': {
    zip: '75027',
    city: 'Flower Mound',
    state: 'TX',
    latitude: '33.026466',
    longitude: '-97.093775',
    timeZoneId: 'America/Chicago',
  },
  '75028': {
    zip: '75028',
    city: 'Flower Mound',
    state: 'TX',
    latitude: '33.033405',
    longitude: '-97.067657',
    timeZoneId: 'America/Chicago',
  },
  '75029': {
    zip: '75029',
    city: 'Lewisville',
    state: 'TX',
    latitude: '33.045911',
    longitude: '-97.009351',
    timeZoneId: 'America/Chicago',
  },
  '75030': {
    zip: '75030',
    city: 'Rowlett',
    state: 'TX',
    latitude: '32.899918',
    longitude: '-96.56373',
    timeZoneId: 'America/Chicago',
  },
  '75032': {
    zip: '75032',
    city: 'Rockwall',
    state: 'TX',
    latitude: '32.86941',
    longitude: '-96.40895',
    timeZoneId: 'America/Chicago',
  },
  '75033': {
    zip: '75033',
    city: 'Frisco',
    state: 'TX',
    latitude: '33.153543',
    longitude: '-96.848348',
    timeZoneId: 'America/Chicago',
  },
  '75034': {
    zip: '75034',
    city: 'Frisco',
    state: 'TX',
    latitude: '33.153776',
    longitude: '-96.848685',
    timeZoneId: 'America/Chicago',
  },
  '75035': {
    zip: '75035',
    city: 'Frisco',
    state: 'TX',
    latitude: '33.164995',
    longitude: '-96.777523',
    timeZoneId: 'America/Chicago',
  },
  '75036': {
    zip: '75036',
    city: 'Frisco',
    state: 'TX',
    latitude: '33.142713',
    longitude: '-96.794162',
    timeZoneId: 'America/Chicago',
  },
  '75038': {
    zip: '75038',
    city: 'Irving',
    state: 'TX',
    latitude: '32.868383',
    longitude: '-96.979437',
    timeZoneId: 'America/Chicago',
  },
  '75039': {
    zip: '75039',
    city: 'Irving',
    state: 'TX',
    latitude: '32.888686',
    longitude: '-96.943428',
    timeZoneId: 'America/Chicago',
  },
  '75040': {
    zip: '75040',
    city: 'Garland',
    state: 'TX',
    latitude: '32.925157',
    longitude: '-96.622452',
    timeZoneId: 'America/Chicago',
  },
  '75041': {
    zip: '75041',
    city: 'Garland',
    state: 'TX',
    latitude: '32.88306',
    longitude: '-96.642717',
    timeZoneId: 'America/Chicago',
  },
  '75042': {
    zip: '75042',
    city: 'Garland',
    state: 'TX',
    latitude: '32.911721',
    longitude: '-96.672526',
    timeZoneId: 'America/Chicago',
  },
  '75043': {
    zip: '75043',
    city: 'Garland',
    state: 'TX',
    latitude: '32.857549',
    longitude: '-96.597118',
    timeZoneId: 'America/Chicago',
  },
  '75044': {
    zip: '75044',
    city: 'Garland',
    state: 'TX',
    latitude: '32.966795',
    longitude: '-96.665145',
    timeZoneId: 'America/Chicago',
  },
  '75045': {
    zip: '75045',
    city: 'Garland',
    state: 'TX',
    latitude: '32.945848',
    longitude: '-96.682933',
    timeZoneId: 'America/Chicago',
  },
  '75046': {
    zip: '75046',
    city: 'Garland',
    state: 'TX',
    latitude: '32.918699',
    longitude: '-96.644353',
    timeZoneId: 'America/Chicago',
  },
  '75047': {
    zip: '75047',
    city: 'Garland',
    state: 'TX',
    latitude: '32.876253',
    longitude: '-96.647106',
    timeZoneId: 'America/Chicago',
  },
  '75048': {
    zip: '75048',
    city: 'Sachse',
    state: 'TX',
    latitude: '32.9682',
    longitude: '-96.58326',
    timeZoneId: 'America/Chicago',
  },
  '75049': {
    zip: '75049',
    city: 'Garland',
    state: 'TX',
    latitude: '32.858943',
    longitude: '-96.60296',
    timeZoneId: 'America/Chicago',
  },
  '75050': {
    zip: '75050',
    city: 'Grand Prairie',
    state: 'TX',
    latitude: '32.768436',
    longitude: '-97.002967',
    timeZoneId: 'America/Chicago',
  },
  '75051': {
    zip: '75051',
    city: 'Grand Prairie',
    state: 'TX',
    latitude: '32.715451',
    longitude: '-96.996819',
    timeZoneId: 'America/Chicago',
  },
  '75052': {
    zip: '75052',
    city: 'Grand Prairie',
    state: 'TX',
    latitude: '32.679596',
    longitude: '-97.013708',
    timeZoneId: 'America/Chicago',
  },
  '75053': {
    zip: '75053',
    city: 'Grand Prairie',
    state: 'TX',
    latitude: '32.752686',
    longitude: '-97.024986',
    timeZoneId: 'America/Chicago',
  },
  '75054': {
    zip: '75054',
    city: 'Grand Prairie',
    state: 'TX',
    latitude: '32.747058',
    longitude: '-96.99646',
    timeZoneId: 'America/Chicago',
  },
  '75056': {
    zip: '75056',
    city: 'The Colony',
    state: 'TX',
    latitude: '33.09768',
    longitude: '-96.88392',
    timeZoneId: 'America/Chicago',
  },
  '75057': {
    zip: '75057',
    city: 'Lewisville',
    state: 'TX',
    latitude: '33.050693',
    longitude: '-96.996496',
    timeZoneId: 'America/Chicago',
  },
  '75058': {
    zip: '75058',
    city: 'Gunter',
    state: 'TX',
    latitude: '33.448112',
    longitude: '-96.747724',
    timeZoneId: 'America/Chicago',
  },
  '75059': {
    zip: '75059',
    city: 'Irving',
    state: 'TX',
    latitude: '32.798903',
    longitude: '-96.950146',
    timeZoneId: 'America/Chicago',
  },
  '75060': {
    zip: '75060',
    city: 'Irving',
    state: 'TX',
    latitude: '32.798903',
    longitude: '-96.950146',
    timeZoneId: 'America/Chicago',
  },
  '75061': {
    zip: '75061',
    city: 'Irving',
    state: 'TX',
    latitude: '32.824483',
    longitude: '-96.953242',
    timeZoneId: 'America/Chicago',
  },
  '75062': {
    zip: '75062',
    city: 'Irving',
    state: 'TX',
    latitude: '32.846175',
    longitude: '-96.963386',
    timeZoneId: 'America/Chicago',
  },
  '75063': {
    zip: '75063',
    city: 'Irving',
    state: 'TX',
    latitude: '32.916909',
    longitude: '-96.97227',
    timeZoneId: 'America/Chicago',
  },
  '75064': {
    zip: '75064',
    city: 'Irving',
    state: 'TX',
    latitude: '32.840499',
    longitude: '-96.997481',
    timeZoneId: 'America/Chicago',
  },
  '75065': {
    zip: '75065',
    city: 'Lake Dallas',
    state: 'TX',
    latitude: '33.12205',
    longitude: '-97.027006',
    timeZoneId: 'America/Chicago',
  },
  '75067': {
    zip: '75067',
    city: 'Lewisville',
    state: 'TX',
    latitude: '33.077549',
    longitude: '-97.04926',
    timeZoneId: 'America/Chicago',
  },
  '75068': {
    zip: '75068',
    city: 'Little Elm',
    state: 'TX',
    latitude: '33.168674',
    longitude: '-96.961589',
    timeZoneId: 'America/Chicago',
  },
  '75069': {
    zip: '75069',
    city: 'McKinney',
    state: 'TX',
    latitude: '33.17928',
    longitude: '-96.60102',
    timeZoneId: 'America/Chicago',
  },
  '75070': {
    zip: '75070',
    city: 'McKinney',
    state: 'TX',
    latitude: '33.16912',
    longitude: '-96.691924',
    timeZoneId: 'America/Chicago',
  },
  '75071': {
    zip: '75071',
    city: 'McKinney',
    state: 'TX',
    latitude: '33.167006',
    longitude: '-96.597465',
    timeZoneId: 'America/Chicago',
  },
  '75072': {
    zip: '75072',
    city: 'McKinney',
    state: 'TX',
    latitude: '33.213306',
    longitude: '-96.677463',
    timeZoneId: 'America/Chicago',
  },
  '75074': {
    zip: '75074',
    city: 'Plano',
    state: 'TX',
    latitude: '33.029555',
    longitude: '-96.675345',
    timeZoneId: 'America/Chicago',
  },
  '75075': {
    zip: '75075',
    city: 'Plano',
    state: 'TX',
    latitude: '33.015315',
    longitude: '-96.738972',
    timeZoneId: 'America/Chicago',
  },
  '75076': {
    zip: '75076',
    city: 'Pottsboro',
    state: 'TX',
    latitude: '33.772595',
    longitude: '-96.668922',
    timeZoneId: 'America/Chicago',
  },
  '75077': {
    zip: '75077',
    city: 'Lewisville',
    state: 'TX',
    latitude: '33.047969',
    longitude: '-96.994857',
    timeZoneId: 'America/Chicago',
  },
  '75078': {
    zip: '75078',
    city: 'Prosper',
    state: 'TX',
    latitude: '33.247578',
    longitude: '-96.811198',
    timeZoneId: 'America/Chicago',
  },
  '75080': {
    zip: '75080',
    city: 'Richardson',
    state: 'TX',
    latitude: '32.963287',
    longitude: '-96.745163',
    timeZoneId: 'America/Chicago',
  },
  '75081': {
    zip: '75081',
    city: 'Richardson',
    state: 'TX',
    latitude: '32.95019',
    longitude: '-96.710942',
    timeZoneId: 'America/Chicago',
  },
  '75082': {
    zip: '75082',
    city: 'Richardson',
    state: 'TX',
    latitude: '32.995373',
    longitude: '-96.682873',
    timeZoneId: 'America/Chicago',
  },
  '75083': {
    zip: '75083',
    city: 'Richardson',
    state: 'TX',
    latitude: '32.973276',
    longitude: '-96.745268',
    timeZoneId: 'America/Chicago',
  },
  '75085': {
    zip: '75085',
    city: 'Richardson',
    state: 'TX',
    latitude: '32.952906',
    longitude: '-96.703839',
    timeZoneId: 'America/Chicago',
  },
  '75086': {
    zip: '75086',
    city: 'Plano',
    state: 'TX',
    latitude: '33.025974',
    longitude: '-96.699035',
    timeZoneId: 'America/Chicago',
  },
  '75087': {
    zip: '75087',
    city: 'Rockwall',
    state: 'TX',
    latitude: '32.89932',
    longitude: '-96.41208',
    timeZoneId: 'America/Chicago',
  },
  '75088': {
    zip: '75088',
    city: 'Rowlett',
    state: 'TX',
    latitude: '32.893933',
    longitude: '-96.553094',
    timeZoneId: 'America/Chicago',
  },
  '75089': {
    zip: '75089',
    city: 'Rowlett',
    state: 'TX',
    latitude: '32.933137',
    longitude: '-96.556218',
    timeZoneId: 'America/Chicago',
  },
  '75090': {
    zip: '75090',
    city: 'Sherman',
    state: 'TX',
    latitude: '33.642018',
    longitude: '-96.607173',
    timeZoneId: 'America/Chicago',
  },
  '75091': {
    zip: '75091',
    city: 'Sherman',
    state: 'TX',
    latitude: '33.634874',
    longitude: '-96.609047',
    timeZoneId: 'America/Chicago',
  },
  '75092': {
    zip: '75092',
    city: 'Sherman',
    state: 'TX',
    latitude: '33.645158',
    longitude: '-96.633453',
    timeZoneId: 'America/Chicago',
  },
  '75093': {
    zip: '75093',
    city: 'Plano',
    state: 'TX',
    latitude: '33.032072',
    longitude: '-96.802811',
    timeZoneId: 'America/Chicago',
  },
  '75094': {
    zip: '75094',
    city: 'Plano',
    state: 'TX',
    latitude: '33.01776',
    longitude: '-96.62442',
    timeZoneId: 'America/Chicago',
  },
  '75097': {
    zip: '75097',
    city: 'Weston',
    state: 'TX',
    latitude: '33.34878',
    longitude: '-96.66912',
    timeZoneId: 'America/Chicago',
  },
  '75098': {
    zip: '75098',
    city: 'Wylie',
    state: 'TX',
    latitude: '33.021961',
    longitude: '-96.5372',
    timeZoneId: 'America/Chicago',
  },
  '75099': {
    zip: '75099',
    city: 'Coppell',
    state: 'TX',
    latitude: '32.954431',
    longitude: '-97.015214',
    timeZoneId: 'America/Chicago',
  },
  '75101': {
    zip: '75101',
    city: 'Bardwell',
    state: 'TX',
    latitude: '32.308557',
    longitude: '-96.798879',
    timeZoneId: 'America/Chicago',
  },
  '75102': {
    zip: '75102',
    city: 'Barry',
    state: 'TX',
    latitude: '32.086692',
    longitude: '-96.709496',
    timeZoneId: 'America/Chicago',
  },
  '75103': {
    zip: '75103',
    city: 'Canton',
    state: 'TX',
    latitude: '32.552553',
    longitude: '-95.870563',
    timeZoneId: 'America/Chicago',
  },
  '75104': {
    zip: '75104',
    city: 'Cedar Hill',
    state: 'TX',
    latitude: '32.58856',
    longitude: '-96.943285',
    timeZoneId: 'America/Chicago',
  },
  '75105': {
    zip: '75105',
    city: 'Chatfield',
    state: 'TX',
    latitude: '32.2904',
    longitude: '-96.368573',
    timeZoneId: 'America/Chicago',
  },
  '75106': {
    zip: '75106',
    city: 'Cedar Hill',
    state: 'TX',
    latitude: '32.587715',
    longitude: '-96.956151',
    timeZoneId: 'America/Chicago',
  },
  '75109': {
    zip: '75109',
    city: 'Corsicana',
    state: 'TX',
    latitude: '32.091381',
    longitude: '-96.474504',
    timeZoneId: 'America/Chicago',
  },
  '75110': {
    zip: '75110',
    city: 'Corsicana',
    state: 'TX',
    latitude: '32.06016',
    longitude: '-96.43116',
    timeZoneId: 'America/Chicago',
  },
  '75114': {
    zip: '75114',
    city: 'Crandall',
    state: 'TX',
    latitude: '32.619327',
    longitude: '-96.444364',
    timeZoneId: 'America/Chicago',
  },
  '75115': {
    zip: '75115',
    city: 'Desoto',
    state: 'TX',
    latitude: '32.596805',
    longitude: '-96.866719',
    timeZoneId: 'America/Chicago',
  },
  '75116': {
    zip: '75116',
    city: 'Duncanville',
    state: 'TX',
    latitude: '32.658363',
    longitude: '-96.91511',
    timeZoneId: 'America/Chicago',
  },
  '75117': {
    zip: '75117',
    city: 'Edgewood',
    state: 'TX',
    latitude: '32.697255',
    longitude: '-95.884524',
    timeZoneId: 'America/Chicago',
  },
  '75118': {
    zip: '75118',
    city: 'Elmo',
    state: 'TX',
    latitude: '32.685089',
    longitude: '-96.214589',
    timeZoneId: 'America/Chicago',
  },
  '75119': {
    zip: '75119',
    city: 'Ennis',
    state: 'TX',
    latitude: '32.339376',
    longitude: '-96.566955',
    timeZoneId: 'America/Chicago',
  },
  '75120': {
    zip: '75120',
    city: 'Ennis',
    state: 'TX',
    latitude: '32.354195',
    longitude: '-96.62592',
    timeZoneId: 'America/Chicago',
  },
  '75121': {
    zip: '75121',
    city: 'Copeville',
    state: 'TX',
    latitude: '33.171629',
    longitude: '-96.37204',
    timeZoneId: 'America/Chicago',
  },
  '75123': {
    zip: '75123',
    city: 'Desoto',
    state: 'TX',
    latitude: '32.588347',
    longitude: '-96.86032',
    timeZoneId: 'America/Chicago',
  },
  '75124': {
    zip: '75124',
    city: 'Eustace',
    state: 'TX',
    latitude: '32.30538',
    longitude: '-95.988221',
    timeZoneId: 'America/Chicago',
  },
  '75125': {
    zip: '75125',
    city: 'Ferris',
    state: 'TX',
    latitude: '32.519486',
    longitude: '-96.617282',
    timeZoneId: 'America/Chicago',
  },
  '75126': {
    zip: '75126',
    city: 'Forney',
    state: 'TX',
    latitude: '32.745419',
    longitude: '-96.455203',
    timeZoneId: 'America/Chicago',
  },
  '75127': {
    zip: '75127',
    city: 'Fruitvale',
    state: 'TX',
    latitude: '32.684251',
    longitude: '-95.796947',
    timeZoneId: 'America/Chicago',
  },
  '75132': {
    zip: '75132',
    city: 'Fate',
    state: 'TX',
    latitude: '32.969939',
    longitude: '-96.374664',
    timeZoneId: 'America/Chicago',
  },
  '75134': {
    zip: '75134',
    city: 'Lancaster',
    state: 'TX',
    latitude: '32.618452',
    longitude: '-96.780593',
    timeZoneId: 'America/Chicago',
  },
  '75135': {
    zip: '75135',
    city: 'Caddo Mills',
    state: 'TX',
    latitude: '33.068967',
    longitude: '-96.229757',
    timeZoneId: 'America/Chicago',
  },
  '75137': {
    zip: '75137',
    city: 'Duncanville',
    state: 'TX',
    latitude: '32.632128',
    longitude: '-96.90995',
    timeZoneId: 'America/Chicago',
  },
  '75138': {
    zip: '75138',
    city: 'Duncanville',
    state: 'TX',
    latitude: '32.674151',
    longitude: '-96.916724',
    timeZoneId: 'America/Chicago',
  },
  '75140': {
    zip: '75140',
    city: 'Grand Saline',
    state: 'TX',
    latitude: '32.681237',
    longitude: '-95.714325',
    timeZoneId: 'America/Chicago',
  },
  '75141': {
    zip: '75141',
    city: 'Hutchins',
    state: 'TX',
    latitude: '32.645353',
    longitude: '-96.707711',
    timeZoneId: 'America/Chicago',
  },
  '75142': {
    zip: '75142',
    city: 'Kaufman',
    state: 'TX',
    latitude: '32.584662',
    longitude: '-96.302241',
    timeZoneId: 'America/Chicago',
  },
  '75143': {
    zip: '75143',
    city: 'Kemp',
    state: 'TX',
    latitude: '32.36604',
    longitude: '-96.28668',
    timeZoneId: 'America/Chicago',
  },
  '75144': {
    zip: '75144',
    city: 'Kerens',
    state: 'TX',
    latitude: '32.128167',
    longitude: '-96.225164',
    timeZoneId: 'America/Chicago',
  },
  '75146': {
    zip: '75146',
    city: 'Lancaster',
    state: 'TX',
    latitude: '32.579465',
    longitude: '-96.747249',
    timeZoneId: 'America/Chicago',
  },
  '75147': {
    zip: '75147',
    city: 'Mabank',
    state: 'TX',
    latitude: '32.3193',
    longitude: '-96.11808',
    timeZoneId: 'America/Chicago',
  },
  '75148': {
    zip: '75148',
    city: 'Malakoff',
    state: 'TX',
    latitude: '32.186698',
    longitude: '-96.026721',
    timeZoneId: 'America/Chicago',
  },
  '75149': {
    zip: '75149',
    city: 'Mesquite',
    state: 'TX',
    latitude: '32.770243',
    longitude: '-96.613013',
    timeZoneId: 'America/Chicago',
  },
  '75150': {
    zip: '75150',
    city: 'Mesquite',
    state: 'TX',
    latitude: '32.814395',
    longitude: '-96.625303',
    timeZoneId: 'America/Chicago',
  },
  '75151': {
    zip: '75151',
    city: 'Corsicana',
    state: 'TX',
    latitude: '32.053898',
    longitude: '-96.52343',
    timeZoneId: 'America/Chicago',
  },
  '75152': {
    zip: '75152',
    city: 'Palmer',
    state: 'TX',
    latitude: '32.42821',
    longitude: '-96.663681',
    timeZoneId: 'America/Chicago',
  },
  '75153': {
    zip: '75153',
    city: 'Powell',
    state: 'TX',
    latitude: '32.207237',
    longitude: '-96.35944',
    timeZoneId: 'America/Chicago',
  },
  '75154': {
    zip: '75154',
    city: 'Red Oak',
    state: 'TX',
    latitude: '32.525442',
    longitude: '-96.814963',
    timeZoneId: 'America/Chicago',
  },
  '75155': {
    zip: '75155',
    city: 'Rice',
    state: 'TX',
    latitude: '32.216974',
    longitude: '-96.413231',
    timeZoneId: 'America/Chicago',
  },
  '75156': {
    zip: '75156',
    city: 'Mabank',
    state: 'TX',
    latitude: '32.29596',
    longitude: '-96.118738',
    timeZoneId: 'America/Chicago',
  },
  '75157': {
    zip: '75157',
    city: 'Rosser',
    state: 'TX',
    latitude: '32.583808',
    longitude: '-96.450152',
    timeZoneId: 'America/Chicago',
  },
  '75158': {
    zip: '75158',
    city: 'Scurry',
    state: 'TX',
    latitude: '32.467435',
    longitude: '-96.405307',
    timeZoneId: 'America/Chicago',
  },
  '75159': {
    zip: '75159',
    city: 'Seagoville',
    state: 'TX',
    latitude: '32.649678',
    longitude: '-96.563503',
    timeZoneId: 'America/Chicago',
  },
  '75160': {
    zip: '75160',
    city: 'Terrell',
    state: 'TX',
    latitude: '32.748186',
    longitude: '-96.316878',
    timeZoneId: 'America/Chicago',
  },
  '75161': {
    zip: '75161',
    city: 'Terrell',
    state: 'TX',
    latitude: '32.721077',
    longitude: '-96.165376',
    timeZoneId: 'America/Chicago',
  },
  '75163': {
    zip: '75163',
    city: 'Trinidad',
    state: 'TX',
    latitude: '32.201032',
    longitude: '-96.107269',
    timeZoneId: 'America/Chicago',
  },
  '75164': {
    zip: '75164',
    city: 'Josephine',
    state: 'TX',
    latitude: '33.058595',
    longitude: '-96.382568',
    timeZoneId: 'America/Chicago',
  },
  '75165': {
    zip: '75165',
    city: 'Waxahachie',
    state: 'TX',
    latitude: '32.37815',
    longitude: '-96.789203',
    timeZoneId: 'America/Chicago',
  },
  '75166': {
    zip: '75166',
    city: 'Lavon',
    state: 'TX',
    latitude: '33.019982',
    longitude: '-96.428233',
    timeZoneId: 'America/Chicago',
  },
  '75167': {
    zip: '75167',
    city: 'Waxahachie',
    state: 'TX',
    latitude: '32.330887',
    longitude: '-96.909444',
    timeZoneId: 'America/Chicago',
  },
  '75168': {
    zip: '75168',
    city: 'Waxahachie',
    state: 'TX',
    latitude: '32.305345',
    longitude: '-96.769099',
    timeZoneId: 'America/Chicago',
  },
  '75169': {
    zip: '75169',
    city: 'Wills Point',
    state: 'TX',
    latitude: '32.733837',
    longitude: '-96.013236',
    timeZoneId: 'America/Chicago',
  },
  '75172': {
    zip: '75172',
    city: 'Wilmer',
    state: 'TX',
    latitude: '32.596587',
    longitude: '-96.681089',
    timeZoneId: 'America/Chicago',
  },
  '75173': {
    zip: '75173',
    city: 'Nevada',
    state: 'TX',
    latitude: '33.057445',
    longitude: '-96.379449',
    timeZoneId: 'America/Chicago',
  },
  '75180': {
    zip: '75180',
    city: 'Balch Springs',
    state: 'TX',
    latitude: '32.717417',
    longitude: '-96.619426',
    timeZoneId: 'America/Chicago',
  },
  '75181': {
    zip: '75181',
    city: 'Mesquite',
    state: 'TX',
    latitude: '32.722807',
    longitude: '-96.552108',
    timeZoneId: 'America/Chicago',
  },
  '75182': {
    zip: '75182',
    city: 'Sunnyvale',
    state: 'TX',
    latitude: '32.782434',
    longitude: '-96.554366',
    timeZoneId: 'America/Chicago',
  },
  '75185': {
    zip: '75185',
    city: 'Mesquite',
    state: 'TX',
    latitude: '32.771611',
    longitude: '-96.616492',
    timeZoneId: 'America/Chicago',
  },
  '75187': {
    zip: '75187',
    city: 'Mesquite',
    state: 'TX',
    latitude: '32.770412',
    longitude: '-96.614054',
    timeZoneId: 'America/Chicago',
  },
  '75189': {
    zip: '75189',
    city: 'Royse City',
    state: 'TX',
    latitude: '32.966297',
    longitude: '-96.303766',
    timeZoneId: 'America/Chicago',
  },
  '75201': {
    zip: '75201',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.78722',
    longitude: '-96.798491',
    timeZoneId: 'America/Chicago',
  },
  '75202': {
    zip: '75202',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.781455',
    longitude: '-96.801814',
    timeZoneId: 'America/Chicago',
  },
  '75203': {
    zip: '75203',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.743356',
    longitude: '-96.806968',
    timeZoneId: 'America/Chicago',
  },
  '75204': {
    zip: '75204',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.798752',
    longitude: '-96.788683',
    timeZoneId: 'America/Chicago',
  },
  '75205': {
    zip: '75205',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.836236',
    longitude: '-96.794663',
    timeZoneId: 'America/Chicago',
  },
  '75206': {
    zip: '75206',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.830488',
    longitude: '-96.772733',
    timeZoneId: 'America/Chicago',
  },
  '75207': {
    zip: '75207',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.787059',
    longitude: '-96.820335',
    timeZoneId: 'America/Chicago',
  },
  '75208': {
    zip: '75208',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.754908',
    longitude: '-96.83946',
    timeZoneId: 'America/Chicago',
  },
  '75209': {
    zip: '75209',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.848647',
    longitude: '-96.82508',
    timeZoneId: 'America/Chicago',
  },
  '75210': {
    zip: '75210',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.770501',
    longitude: '-96.750436',
    timeZoneId: 'America/Chicago',
  },
  '75211': {
    zip: '75211',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.81622',
    longitude: '-96.88866',
    timeZoneId: 'America/Chicago',
  },
  '75212': {
    zip: '75212',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.782293',
    longitude: '-96.875996',
    timeZoneId: 'America/Chicago',
  },
  '75214': {
    zip: '75214',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.822865',
    longitude: '-96.748359',
    timeZoneId: 'America/Chicago',
  },
  '75215': {
    zip: '75215',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.750302',
    longitude: '-96.761227',
    timeZoneId: 'America/Chicago',
  },
  '75216': {
    zip: '75216',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.707957',
    longitude: '-96.787266',
    timeZoneId: 'America/Chicago',
  },
  '75217': {
    zip: '75217',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.72015',
    longitude: '-96.676927',
    timeZoneId: 'America/Chicago',
  },
  '75218': {
    zip: '75218',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.843593',
    longitude: '-96.697965',
    timeZoneId: 'America/Chicago',
  },
  '75219': {
    zip: '75219',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.814032',
    longitude: '-96.815005',
    timeZoneId: 'America/Chicago',
  },
  '75220': {
    zip: '75220',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.863782',
    longitude: '-96.873944',
    timeZoneId: 'America/Chicago',
  },
  '75221': {
    zip: '75221',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.691806',
    longitude: '-96.824376',
    timeZoneId: 'America/Chicago',
  },
  '75222': {
    zip: '75222',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.754611',
    longitude: '-96.840877',
    timeZoneId: 'America/Chicago',
  },
  '75223': {
    zip: '75223',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.789977',
    longitude: '-96.752931',
    timeZoneId: 'America/Chicago',
  },
  '75224': {
    zip: '75224',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.713449',
    longitude: '-96.837214',
    timeZoneId: 'America/Chicago',
  },
  '75225': {
    zip: '75225',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.866007',
    longitude: '-96.784662',
    timeZoneId: 'America/Chicago',
  },
  '75226': {
    zip: '75226',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.783088',
    longitude: '-96.775161',
    timeZoneId: 'America/Chicago',
  },
  '75227': {
    zip: '75227',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.766974',
    longitude: '-96.683948',
    timeZoneId: 'America/Chicago',
  },
  '75228': {
    zip: '75228',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.825581',
    longitude: '-96.681423',
    timeZoneId: 'America/Chicago',
  },
  '75229': {
    zip: '75229',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.893259',
    longitude: '-96.864726',
    timeZoneId: 'America/Chicago',
  },
  '75230': {
    zip: '75230',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.902378',
    longitude: '-96.789669',
    timeZoneId: 'America/Chicago',
  },
  '75231': {
    zip: '75231',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.876553',
    longitude: '-96.751035',
    timeZoneId: 'America/Chicago',
  },
  '75232': {
    zip: '75232',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.65564',
    longitude: '-96.842603',
    timeZoneId: 'America/Chicago',
  },
  '75233': {
    zip: '75233',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.702691',
    longitude: '-96.877091',
    timeZoneId: 'America/Chicago',
  },
  '75234': {
    zip: '75234',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.91575',
    longitude: '-96.89614',
    timeZoneId: 'America/Chicago',
  },
  '75235': {
    zip: '75235',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.828634',
    longitude: '-96.842696',
    timeZoneId: 'America/Chicago',
  },
  '75236': {
    zip: '75236',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.688333',
    longitude: '-96.938424',
    timeZoneId: 'America/Chicago',
  },
  '75237': {
    zip: '75237',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.669182',
    longitude: '-96.875904',
    timeZoneId: 'America/Chicago',
  },
  '75238': {
    zip: '75238',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.878483',
    longitude: '-96.709053',
    timeZoneId: 'America/Chicago',
  },
  '75240': {
    zip: '75240',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.931962',
    longitude: '-96.789191',
    timeZoneId: 'America/Chicago',
  },
  '75241': {
    zip: '75241',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.656089',
    longitude: '-96.746634',
    timeZoneId: 'America/Chicago',
  },
  '75242': {
    zip: '75242',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.775963',
    longitude: '-96.803741',
    timeZoneId: 'America/Chicago',
  },
  '75243': {
    zip: '75243',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.911604',
    longitude: '-96.732266',
    timeZoneId: 'America/Chicago',
  },
  '75244': {
    zip: '75244',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.93949',
    longitude: '-96.83873',
    timeZoneId: 'America/Chicago',
  },
  '75246': {
    zip: '75246',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.796315',
    longitude: '-96.773453',
    timeZoneId: 'America/Chicago',
  },
  '75247': {
    zip: '75247',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.814097',
    longitude: '-96.879621',
    timeZoneId: 'America/Chicago',
  },
  '75248': {
    zip: '75248',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.966396',
    longitude: '-96.798494',
    timeZoneId: 'America/Chicago',
  },
  '75249': {
    zip: '75249',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.648113',
    longitude: '-96.958024',
    timeZoneId: 'America/Chicago',
  },
  '75250': {
    zip: '75250',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.782956',
    longitude: '-96.800159',
    timeZoneId: 'America/Chicago',
  },
  '75251': {
    zip: '75251',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.915992',
    longitude: '-96.770079',
    timeZoneId: 'America/Chicago',
  },
  '75252': {
    zip: '75252',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.995735',
    longitude: '-96.784576',
    timeZoneId: 'America/Chicago',
  },
  '75253': {
    zip: '75253',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.687055',
    longitude: '-96.602168',
    timeZoneId: 'America/Chicago',
  },
  '75254': {
    zip: '75254',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.934482',
    longitude: '-96.78932',
    timeZoneId: 'America/Chicago',
  },
  '75260': {
    zip: '75260',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.772403',
    longitude: '-96.825763',
    timeZoneId: 'America/Chicago',
  },
  '75261': {
    zip: '75261',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.873864',
    longitude: '-96.965862',
    timeZoneId: 'America/Chicago',
  },
  '75262': {
    zip: '75262',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.749718',
    longitude: '-96.841253',
    timeZoneId: 'America/Chicago',
  },
  '75263': {
    zip: '75263',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.752718',
    longitude: '-96.842401',
    timeZoneId: 'America/Chicago',
  },
  '75264': {
    zip: '75264',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.754729',
    longitude: '-96.842395',
    timeZoneId: 'America/Chicago',
  },
  '75265': {
    zip: '75265',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.743228',
    longitude: '-97.00109',
    timeZoneId: 'America/Chicago',
  },
  '75266': {
    zip: '75266',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.780984',
    longitude: '-96.881769',
    timeZoneId: 'America/Chicago',
  },
  '75267': {
    zip: '75267',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.753689',
    longitude: '-96.842948',
    timeZoneId: 'America/Chicago',
  },
  '75270': {
    zip: '75270',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.775253',
    longitude: '-96.805716',
    timeZoneId: 'America/Chicago',
  },
  '75275': {
    zip: '75275',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.843618',
    longitude: '-96.783951',
    timeZoneId: 'America/Chicago',
  },
  '75277': {
    zip: '75277',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.738399',
    longitude: '-96.746366',
    timeZoneId: 'America/Chicago',
  },
  '75283': {
    zip: '75283',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.768227',
    longitude: '-96.830324',
    timeZoneId: 'America/Chicago',
  },
  '75284': {
    zip: '75284',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.768664',
    longitude: '-96.827092',
    timeZoneId: 'America/Chicago',
  },
  '75285': {
    zip: '75285',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.767941',
    longitude: '-96.827934',
    timeZoneId: 'America/Chicago',
  },
  '75287': {
    zip: '75287',
    city: 'Dallas',
    state: 'TX',
    latitude: '33.000097',
    longitude: '-96.842497',
    timeZoneId: 'America/Chicago',
  },
  '75301': {
    zip: '75301',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.661511',
    longitude: '-96.885392',
    timeZoneId: 'America/Chicago',
  },
  '75303': {
    zip: '75303',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.780372',
    longitude: '-96.776012',
    timeZoneId: 'America/Chicago',
  },
  '75312': {
    zip: '75312',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.769299',
    longitude: '-96.82701',
    timeZoneId: 'America/Chicago',
  },
  '75313': {
    zip: '75313',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.785602',
    longitude: '-96.799853',
    timeZoneId: 'America/Chicago',
  },
  '75315': {
    zip: '75315',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.770497',
    longitude: '-96.769117',
    timeZoneId: 'America/Chicago',
  },
  '75320': {
    zip: '75320',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.777839',
    longitude: '-96.79863',
    timeZoneId: 'America/Chicago',
  },
  '75326': {
    zip: '75326',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.867626',
    longitude: '-96.982402',
    timeZoneId: 'America/Chicago',
  },
  '75336': {
    zip: '75336',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.674559',
    longitude: '-96.619806',
    timeZoneId: 'America/Chicago',
  },
  '75339': {
    zip: '75339',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.716432',
    longitude: '-96.78307',
    timeZoneId: 'America/Chicago',
  },
  '75342': {
    zip: '75342',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.769998',
    longitude: '-96.863516',
    timeZoneId: 'America/Chicago',
  },
  '75354': {
    zip: '75354',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.86385',
    longitude: '-96.886097',
    timeZoneId: 'America/Chicago',
  },
  '75355': {
    zip: '75355',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.880114',
    longitude: '-96.705112',
    timeZoneId: 'America/Chicago',
  },
  '75356': {
    zip: '75356',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.815147',
    longitude: '-96.877005',
    timeZoneId: 'America/Chicago',
  },
  '75357': {
    zip: '75357',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.824932',
    longitude: '-96.678883',
    timeZoneId: 'America/Chicago',
  },
  '75358': {
    zip: '75358',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.812543',
    longitude: '-96.686454',
    timeZoneId: 'America/Chicago',
  },
  '75359': {
    zip: '75359',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.812818',
    longitude: '-96.75656',
    timeZoneId: 'America/Chicago',
  },
  '75360': {
    zip: '75360',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.939795',
    longitude: '-96.792819',
    timeZoneId: 'America/Chicago',
  },
  '75367': {
    zip: '75367',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.902155',
    longitude: '-96.792763',
    timeZoneId: 'America/Chicago',
  },
  '75368': {
    zip: '75368',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.920785',
    longitude: '-96.974939',
    timeZoneId: 'America/Chicago',
  },
  '75370': {
    zip: '75370',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.999788',
    longitude: '-96.8417',
    timeZoneId: 'America/Chicago',
  },
  '75371': {
    zip: '75371',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.791615',
    longitude: '-96.772623',
    timeZoneId: 'America/Chicago',
  },
  '75372': {
    zip: '75372',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.839532',
    longitude: '-96.773841',
    timeZoneId: 'America/Chicago',
  },
  '75373': {
    zip: '75373',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.78384',
    longitude: '-96.82912',
    timeZoneId: 'America/Chicago',
  },
  '75374': {
    zip: '75374',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.912949',
    longitude: '-96.734628',
    timeZoneId: 'America/Chicago',
  },
  '75376': {
    zip: '75376',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.711568',
    longitude: '-96.839218',
    timeZoneId: 'America/Chicago',
  },
  '75378': {
    zip: '75378',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.892085',
    longitude: '-96.873247',
    timeZoneId: 'America/Chicago',
  },
  '75379': {
    zip: '75379',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.991318',
    longitude: '-96.803012',
    timeZoneId: 'America/Chicago',
  },
  '75380': {
    zip: '75380',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.931693',
    longitude: '-96.815948',
    timeZoneId: 'America/Chicago',
  },
  '75381': {
    zip: '75381',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.944758',
    longitude: '-96.888462',
    timeZoneId: 'America/Chicago',
  },
  '75382': {
    zip: '75382',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.878734',
    longitude: '-96.749853',
    timeZoneId: 'America/Chicago',
  },
  '75389': {
    zip: '75389',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.770456',
    longitude: '-96.687485',
    timeZoneId: 'America/Chicago',
  },
  '75390': {
    zip: '75390',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.842526',
    longitude: '-96.701666',
    timeZoneId: 'America/Chicago',
  },
  '75391': {
    zip: '75391',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.949228',
    longitude: '-96.825524',
    timeZoneId: 'America/Chicago',
  },
  '75392': {
    zip: '75392',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.76886',
    longitude: '-96.824914',
    timeZoneId: 'America/Chicago',
  },
  '75393': {
    zip: '75393',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.801369',
    longitude: '-96.790487',
    timeZoneId: 'America/Chicago',
  },
  '75394': {
    zip: '75394',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.830895',
    longitude: '-96.706192',
    timeZoneId: 'America/Chicago',
  },
  '75395': {
    zip: '75395',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.708936',
    longitude: '-96.838941',
    timeZoneId: 'America/Chicago',
  },
  '75397': {
    zip: '75397',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.767255',
    longitude: '-96.829088',
    timeZoneId: 'America/Chicago',
  },
  '75398': {
    zip: '75398',
    city: 'Dallas',
    state: 'TX',
    latitude: '32.765788',
    longitude: '-96.85689',
    timeZoneId: 'America/Chicago',
  },
  '75401': {
    zip: '75401',
    city: 'Greenville',
    state: 'TX',
    latitude: '33.14718',
    longitude: '-96.10398',
    timeZoneId: 'America/Chicago',
  },
  '75402': {
    zip: '75402',
    city: 'Greenville',
    state: 'TX',
    latitude: '33.071077',
    longitude: '-96.085608',
    timeZoneId: 'America/Chicago',
  },
  '75403': {
    zip: '75403',
    city: 'Greenville',
    state: 'TX',
    latitude: '33.195705',
    longitude: '-96.105966',
    timeZoneId: 'America/Chicago',
  },
  '75404': {
    zip: '75404',
    city: 'Greenville',
    state: 'TX',
    latitude: '33.192834',
    longitude: '-96.105463',
    timeZoneId: 'America/Chicago',
  },
  '75407': {
    zip: '75407',
    city: 'Princeton',
    state: 'TX',
    latitude: '33.172085',
    longitude: '-96.492064',
    timeZoneId: 'America/Chicago',
  },
  '75409': {
    zip: '75409',
    city: 'Anna',
    state: 'TX',
    latitude: '33.345806',
    longitude: '-96.577245',
    timeZoneId: 'America/Chicago',
  },
  '75410': {
    zip: '75410',
    city: 'Alba',
    state: 'TX',
    latitude: '32.792512',
    longitude: '-95.634414',
    timeZoneId: 'America/Chicago',
  },
  '75411': {
    zip: '75411',
    city: 'Arthur City',
    state: 'TX',
    latitude: '33.849066',
    longitude: '-95.459112',
    timeZoneId: 'America/Chicago',
  },
  '75412': {
    zip: '75412',
    city: 'Bagwell',
    state: 'TX',
    latitude: '33.829427',
    longitude: '-95.149043',
    timeZoneId: 'America/Chicago',
  },
  '75413': {
    zip: '75413',
    city: 'Bailey',
    state: 'TX',
    latitude: '33.433133',
    longitude: '-96.164564',
    timeZoneId: 'America/Chicago',
  },
  '75414': {
    zip: '75414',
    city: 'Bells',
    state: 'TX',
    latitude: '33.614156',
    longitude: '-96.423406',
    timeZoneId: 'America/Chicago',
  },
  '75415': {
    zip: '75415',
    city: 'Ben Franklin',
    state: 'TX',
    latitude: '33.37993',
    longitude: '-95.85966',
    timeZoneId: 'America/Chicago',
  },
  '75416': {
    zip: '75416',
    city: 'Blossom',
    state: 'TX',
    latitude: '33.647332',
    longitude: '-95.419532',
    timeZoneId: 'America/Chicago',
  },
  '75417': {
    zip: '75417',
    city: 'Bogata',
    state: 'TX',
    latitude: '33.470271',
    longitude: '-95.211469',
    timeZoneId: 'America/Chicago',
  },
  '75418': {
    zip: '75418',
    city: 'Bonham',
    state: 'TX',
    latitude: '33.585686',
    longitude: '-96.177204',
    timeZoneId: 'America/Chicago',
  },
  '75420': {
    zip: '75420',
    city: 'Brashear',
    state: 'TX',
    latitude: '33.120054',
    longitude: '-95.726889',
    timeZoneId: 'America/Chicago',
  },
  '75421': {
    zip: '75421',
    city: 'Brookston',
    state: 'TX',
    latitude: '33.65549',
    longitude: '-95.646227',
    timeZoneId: 'America/Chicago',
  },
  '75422': {
    zip: '75422',
    city: 'Campbell',
    state: 'TX',
    latitude: '33.135561',
    longitude: '-95.944998',
    timeZoneId: 'America/Chicago',
  },
  '75423': {
    zip: '75423',
    city: 'Celeste',
    state: 'TX',
    latitude: '33.294316',
    longitude: '-96.198477',
    timeZoneId: 'America/Chicago',
  },
  '75424': {
    zip: '75424',
    city: 'Blue Ridge',
    state: 'TX',
    latitude: '33.306343',
    longitude: '-96.385782',
    timeZoneId: 'America/Chicago',
  },
  '75425': {
    zip: '75425',
    city: 'Chicota',
    state: 'TX',
    latitude: '33.771506',
    longitude: '-95.713612',
    timeZoneId: 'America/Chicago',
  },
  '75426': {
    zip: '75426',
    city: 'Clarksville',
    state: 'TX',
    latitude: '33.610301',
    longitude: '-95.0498',
    timeZoneId: 'America/Chicago',
  },
  '75428': {
    zip: '75428',
    city: 'Commerce',
    state: 'TX',
    latitude: '33.258059',
    longitude: '-95.931868',
    timeZoneId: 'America/Chicago',
  },
  '75429': {
    zip: '75429',
    city: 'Commerce',
    state: 'TX',
    latitude: '33.248968',
    longitude: '-95.898144',
    timeZoneId: 'America/Chicago',
  },
  '75431': {
    zip: '75431',
    city: 'Como',
    state: 'TX',
    latitude: '33.07944',
    longitude: '-95.368016',
    timeZoneId: 'America/Chicago',
  },
  '75432': {
    zip: '75432',
    city: 'Cooper',
    state: 'TX',
    latitude: '33.370458',
    longitude: '-95.689502',
    timeZoneId: 'America/Chicago',
  },
  '75433': {
    zip: '75433',
    city: 'Cumby',
    state: 'TX',
    latitude: '33.137761',
    longitude: '-95.84189',
    timeZoneId: 'America/Chicago',
  },
  '75434': {
    zip: '75434',
    city: 'Cunningham',
    state: 'TX',
    latitude: '33.501422',
    longitude: '-95.377887',
    timeZoneId: 'America/Chicago',
  },
  '75435': {
    zip: '75435',
    city: 'Deport',
    state: 'TX',
    latitude: '33.499435',
    longitude: '-95.38',
    timeZoneId: 'America/Chicago',
  },
  '75436': {
    zip: '75436',
    city: 'Detroit',
    state: 'TX',
    latitude: '33.662519',
    longitude: '-95.230975',
    timeZoneId: 'America/Chicago',
  },
  '75437': {
    zip: '75437',
    city: 'Dike',
    state: 'TX',
    latitude: '33.192096',
    longitude: '-95.466019',
    timeZoneId: 'America/Chicago',
  },
  '75438': {
    zip: '75438',
    city: 'Dodd City',
    state: 'TX',
    latitude: '33.577173',
    longitude: '-96.075681',
    timeZoneId: 'America/Chicago',
  },
  '75439': {
    zip: '75439',
    city: 'Ector',
    state: 'TX',
    latitude: '33.574815',
    longitude: '-96.268692',
    timeZoneId: 'America/Chicago',
  },
  '75440': {
    zip: '75440',
    city: 'Emory',
    state: 'TX',
    latitude: '32.876644',
    longitude: '-95.756763',
    timeZoneId: 'America/Chicago',
  },
  '75441': {
    zip: '75441',
    city: 'Enloe',
    state: 'TX',
    latitude: '33.399031',
    longitude: '-95.589198',
    timeZoneId: 'America/Chicago',
  },
  '75442': {
    zip: '75442',
    city: 'Farmersville',
    state: 'TX',
    latitude: '33.169385',
    longitude: '-96.36807',
    timeZoneId: 'America/Chicago',
  },
  '75443': {
    zip: '75443',
    city: 'Gober',
    state: 'TX',
    latitude: '33.554307',
    longitude: '-96.044109',
    timeZoneId: 'America/Chicago',
  },
  '75444': {
    zip: '75444',
    city: 'Golden',
    state: 'TX',
    latitude: '32.777209',
    longitude: '-95.588657',
    timeZoneId: 'America/Chicago',
  },
  '75446': {
    zip: '75446',
    city: 'Honey Grove',
    state: 'TX',
    latitude: '33.583513',
    longitude: '-95.910691',
    timeZoneId: 'America/Chicago',
  },
  '75447': {
    zip: '75447',
    city: 'Ivanhoe',
    state: 'TX',
    latitude: '33.678755',
    longitude: '-96.172238',
    timeZoneId: 'America/Chicago',
  },
  '75448': {
    zip: '75448',
    city: 'Klondike',
    state: 'TX',
    latitude: '33.297945',
    longitude: '-95.805253',
    timeZoneId: 'America/Chicago',
  },
  '75449': {
    zip: '75449',
    city: 'Ladonia',
    state: 'TX',
    latitude: '33.421846',
    longitude: '-95.970257',
    timeZoneId: 'America/Chicago',
  },
  '75450': {
    zip: '75450',
    city: 'Lake Creek',
    state: 'TX',
    latitude: '33.465233',
    longitude: '-95.612832',
    timeZoneId: 'America/Chicago',
  },
  '75451': {
    zip: '75451',
    city: 'Leesburg',
    state: 'TX',
    latitude: '32.963146',
    longitude: '-95.107158',
    timeZoneId: 'America/Chicago',
  },
  '75452': {
    zip: '75452',
    city: 'Leonard',
    state: 'TX',
    latitude: '33.38619',
    longitude: '-96.242571',
    timeZoneId: 'America/Chicago',
  },
  '75453': {
    zip: '75453',
    city: 'Lone Oak',
    state: 'TX',
    latitude: '32.99584',
    longitude: '-95.940373',
    timeZoneId: 'America/Chicago',
  },
  '75454': {
    zip: '75454',
    city: 'Melissa',
    state: 'TX',
    latitude: '33.278837',
    longitude: '-96.587184',
    timeZoneId: 'America/Chicago',
  },
  '75455': {
    zip: '75455',
    city: 'Mount Pleasant',
    state: 'TX',
    latitude: '33.156354',
    longitude: '-94.96891',
    timeZoneId: 'America/Chicago',
  },
  '75456': {
    zip: '75456',
    city: 'Mount Pleasant',
    state: 'TX',
    latitude: '33.158187',
    longitude: '-94.968045',
    timeZoneId: 'America/Chicago',
  },
  '75457': {
    zip: '75457',
    city: 'Mount Vernon',
    state: 'TX',
    latitude: '33.180969',
    longitude: '-95.227426',
    timeZoneId: 'America/Chicago',
  },
  '75458': {
    zip: '75458',
    city: 'Merit',
    state: 'TX',
    latitude: '33.282104',
    longitude: '-96.209391',
    timeZoneId: 'America/Chicago',
  },
  '75459': {
    zip: '75459',
    city: 'Howe',
    state: 'TX',
    latitude: '33.5175',
    longitude: '-96.64038',
    timeZoneId: 'America/Chicago',
  },
  '75460': {
    zip: '75460',
    city: 'Paris',
    state: 'TX',
    latitude: '33.665531',
    longitude: '-95.543795',
    timeZoneId: 'America/Chicago',
  },
  '75461': {
    zip: '75461',
    city: 'Paris',
    state: 'TX',
    latitude: '33.661821',
    longitude: '-95.552451',
    timeZoneId: 'America/Chicago',
  },
  '75462': {
    zip: '75462',
    city: 'Paris',
    state: 'TX',
    latitude: '33.66738',
    longitude: '-95.50032',
    timeZoneId: 'America/Chicago',
  },
  '75468': {
    zip: '75468',
    city: 'Pattonville',
    state: 'TX',
    latitude: '33.562976',
    longitude: '-95.392329',
    timeZoneId: 'America/Chicago',
  },
  '75469': {
    zip: '75469',
    city: 'Pecan Gap',
    state: 'TX',
    latitude: '33.406499',
    longitude: '-95.804842',
    timeZoneId: 'America/Chicago',
  },
  '75470': {
    zip: '75470',
    city: 'Petty',
    state: 'TX',
    latitude: '33.618704',
    longitude: '-95.794676',
    timeZoneId: 'America/Chicago',
  },
  '75471': {
    zip: '75471',
    city: 'Pickton',
    state: 'TX',
    latitude: '33.029259',
    longitude: '-95.45373',
    timeZoneId: 'America/Chicago',
  },
  '75472': {
    zip: '75472',
    city: 'Point',
    state: 'TX',
    latitude: '32.892875',
    longitude: '-95.866525',
    timeZoneId: 'America/Chicago',
  },
  '75473': {
    zip: '75473',
    city: 'Powderly',
    state: 'TX',
    latitude: '33.777112',
    longitude: '-95.533147',
    timeZoneId: 'America/Chicago',
  },
  '75474': {
    zip: '75474',
    city: 'Quinlan',
    state: 'TX',
    latitude: '32.910912',
    longitude: '-96.135472',
    timeZoneId: 'America/Chicago',
  },
  '75475': {
    zip: '75475',
    city: 'Randolph',
    state: 'TX',
    latitude: '33.553789',
    longitude: '-96.201365',
    timeZoneId: 'America/Chicago',
  },
  '75476': {
    zip: '75476',
    city: 'Ravenna',
    state: 'TX',
    latitude: '33.669604',
    longitude: '-96.240457',
    timeZoneId: 'America/Chicago',
  },
  '75477': {
    zip: '75477',
    city: 'Roxton',
    state: 'TX',
    latitude: '33.545748',
    longitude: '-95.724221',
    timeZoneId: 'America/Chicago',
  },
  '75478': {
    zip: '75478',
    city: 'Saltillo',
    state: 'TX',
    latitude: '33.196298',
    longitude: '-95.346022',
    timeZoneId: 'America/Chicago',
  },
  '75479': {
    zip: '75479',
    city: 'Savoy',
    state: 'TX',
    latitude: '33.570716',
    longitude: '-96.329163',
    timeZoneId: 'America/Chicago',
  },
  '75480': {
    zip: '75480',
    city: 'Scroggins',
    state: 'TX',
    latitude: '33.066811',
    longitude: '-95.203665',
    timeZoneId: 'America/Chicago',
  },
  '75481': {
    zip: '75481',
    city: 'Sulphur Bluff',
    state: 'TX',
    latitude: '33.337441',
    longitude: '-95.361881',
    timeZoneId: 'America/Chicago',
  },
  '75482': {
    zip: '75482',
    city: 'Sulphur Springs',
    state: 'TX',
    latitude: '33.128095',
    longitude: '-95.598595',
    timeZoneId: 'America/Chicago',
  },
  '75483': {
    zip: '75483',
    city: 'Sulphur Springs',
    state: 'TX',
    latitude: '33.142219',
    longitude: '-95.597859',
    timeZoneId: 'America/Chicago',
  },
  '75485': {
    zip: '75485',
    city: 'Westminster',
    state: 'TX',
    latitude: '33.305597',
    longitude: '-96.384095',
    timeZoneId: 'America/Chicago',
  },
  '75486': {
    zip: '75486',
    city: 'Sumner',
    state: 'TX',
    latitude: '33.775975',
    longitude: '-95.712769',
    timeZoneId: 'America/Chicago',
  },
  '75487': {
    zip: '75487',
    city: 'Talco',
    state: 'TX',
    latitude: '33.362033',
    longitude: '-95.103676',
    timeZoneId: 'America/Chicago',
  },
  '75488': {
    zip: '75488',
    city: 'Telephone',
    state: 'TX',
    latitude: '33.809494',
    longitude: '-96.004066',
    timeZoneId: 'America/Chicago',
  },
  '75489': {
    zip: '75489',
    city: 'Tom Bean',
    state: 'TX',
    latitude: '33.521558',
    longitude: '-96.478321',
    timeZoneId: 'America/Chicago',
  },
  '75490': {
    zip: '75490',
    city: 'Trenton',
    state: 'TX',
    latitude: '33.428209',
    longitude: '-96.334339',
    timeZoneId: 'America/Chicago',
  },
  '75491': {
    zip: '75491',
    city: 'Whitewright',
    state: 'TX',
    latitude: '33.508961',
    longitude: '-96.40174',
    timeZoneId: 'America/Chicago',
  },
  '75492': {
    zip: '75492',
    city: 'Windom',
    state: 'TX',
    latitude: '33.561572',
    longitude: '-96.000259',
    timeZoneId: 'America/Chicago',
  },
  '75493': {
    zip: '75493',
    city: 'Winfield',
    state: 'TX',
    latitude: '33.164074',
    longitude: '-95.110079',
    timeZoneId: 'America/Chicago',
  },
  '75494': {
    zip: '75494',
    city: 'Winnsboro',
    state: 'TX',
    latitude: '32.948208',
    longitude: '-95.293669',
    timeZoneId: 'America/Chicago',
  },
  '75495': {
    zip: '75495',
    city: 'Van Alstyne',
    state: 'TX',
    latitude: '33.436674',
    longitude: '-96.518227',
    timeZoneId: 'America/Chicago',
  },
  '75496': {
    zip: '75496',
    city: 'Wolfe City',
    state: 'TX',
    latitude: '33.341662',
    longitude: '-96.033254',
    timeZoneId: 'America/Chicago',
  },
  '75497': {
    zip: '75497',
    city: 'Yantis',
    state: 'TX',
    latitude: '32.891648',
    longitude: '-95.532085',
    timeZoneId: 'America/Chicago',
  },
  '75501': {
    zip: '75501',
    city: 'Texarkana',
    state: 'TX',
    latitude: '33.424489',
    longitude: '-94.102297',
    timeZoneId: 'America/Chicago',
  },
  '75503': {
    zip: '75503',
    city: 'Texarkana',
    state: 'TX',
    latitude: '33.461416',
    longitude: '-94.072824',
    timeZoneId: 'America/Chicago',
  },
  '75504': {
    zip: '75504',
    city: 'Texarkana',
    state: 'TX',
    latitude: '33.352727',
    longitude: '-94.218718',
    timeZoneId: 'America/Chicago',
  },
  '75505': {
    zip: '75505',
    city: 'Texarkana',
    state: 'TX',
    latitude: '33.396381',
    longitude: '-94.250523',
    timeZoneId: 'America/Chicago',
  },
  '75507': {
    zip: '75507',
    city: 'Texarkana',
    state: 'TX',
    latitude: '33.354678',
    longitude: '-94.219325',
    timeZoneId: 'America/Chicago',
  },
  '75550': {
    zip: '75550',
    city: 'Annona',
    state: 'TX',
    latitude: '33.527852',
    longitude: '-94.896667',
    timeZoneId: 'America/Chicago',
  },
  '75551': {
    zip: '75551',
    city: 'Atlanta',
    state: 'TX',
    latitude: '33.115529',
    longitude: '-94.170131',
    timeZoneId: 'America/Chicago',
  },
  '75554': {
    zip: '75554',
    city: 'Avery',
    state: 'TX',
    latitude: '33.486322',
    longitude: '-94.793653',
    timeZoneId: 'America/Chicago',
  },
  '75555': {
    zip: '75555',
    city: 'Bivins',
    state: 'TX',
    latitude: '32.9397',
    longitude: '-94.1478',
    timeZoneId: 'America/Chicago',
  },
  '75556': {
    zip: '75556',
    city: 'Bloomburg',
    state: 'TX',
    latitude: '33.135162',
    longitude: '-94.065436',
    timeZoneId: 'America/Chicago',
  },
  '75558': {
    zip: '75558',
    city: 'Cookville',
    state: 'TX',
    latitude: '33.18678',
    longitude: '-94.86828',
    timeZoneId: 'America/Chicago',
  },
  '75559': {
    zip: '75559',
    city: 'De Kalb',
    state: 'TX',
    latitude: '33.503381',
    longitude: '-94.61552',
    timeZoneId: 'America/Chicago',
  },
  '75560': {
    zip: '75560',
    city: 'Douglassville',
    state: 'TX',
    latitude: '33.190651',
    longitude: '-94.354656',
    timeZoneId: 'America/Chicago',
  },
  '75561': {
    zip: '75561',
    city: 'Hooks',
    state: 'TX',
    latitude: '33.46932',
    longitude: '-94.31502',
    timeZoneId: 'America/Chicago',
  },
  '75562': {
    zip: '75562',
    city: 'Kildare',
    state: 'TX',
    latitude: '33.010406',
    longitude: '-94.36419',
    timeZoneId: 'America/Chicago',
  },
  '75563': {
    zip: '75563',
    city: 'Linden',
    state: 'TX',
    latitude: '33.009734',
    longitude: '-94.366056',
    timeZoneId: 'America/Chicago',
  },
  '75564': {
    zip: '75564',
    city: 'Lodi',
    state: 'TX',
    latitude: '32.738598',
    longitude: '-94.308278',
    timeZoneId: 'America/Chicago',
  },
  '75565': {
    zip: '75565',
    city: 'Mc Leod',
    state: 'TX',
    latitude: '32.951167',
    longitude: '-94.079416',
    timeZoneId: 'America/Chicago',
  },
  '75566': {
    zip: '75566',
    city: 'Marietta',
    state: 'TX',
    latitude: '33.196373',
    longitude: '-94.536138',
    timeZoneId: 'America/Chicago',
  },
  '75567': {
    zip: '75567',
    city: 'Maud',
    state: 'TX',
    latitude: '33.3642',
    longitude: '-94.47222',
    timeZoneId: 'America/Chicago',
  },
  '75568': {
    zip: '75568',
    city: 'Naples',
    state: 'TX',
    latitude: '33.1926',
    longitude: '-94.69362',
    timeZoneId: 'America/Chicago',
  },
  '75569': {
    zip: '75569',
    city: 'Nash',
    state: 'TX',
    latitude: '33.439691',
    longitude: '-94.124358',
    timeZoneId: 'America/Chicago',
  },
  '75570': {
    zip: '75570',
    city: 'New Boston',
    state: 'TX',
    latitude: '33.45708',
    longitude: '-94.45092',
    timeZoneId: 'America/Chicago',
  },
  '75571': {
    zip: '75571',
    city: 'Omaha',
    state: 'TX',
    latitude: '33.180927',
    longitude: '-94.741576',
    timeZoneId: 'America/Chicago',
  },
  '75572': {
    zip: '75572',
    city: 'Queen City',
    state: 'TX',
    latitude: '33.17526',
    longitude: '-94.1523',
    timeZoneId: 'America/Chicago',
  },
  '75573': {
    zip: '75573',
    city: 'Redwater',
    state: 'TX',
    latitude: '33.358117',
    longitude: '-94.255285',
    timeZoneId: 'America/Chicago',
  },
  '75574': {
    zip: '75574',
    city: 'Simms',
    state: 'TX',
    latitude: '33.487706',
    longitude: '-94.579844',
    timeZoneId: 'America/Chicago',
  },
  '75599': {
    zip: '75599',
    city: 'Texarkana',
    state: 'TX',
    latitude: '33.447126',
    longitude: '-94.077525',
    timeZoneId: 'America/Chicago',
  },
  '75601': {
    zip: '75601',
    city: 'Longview',
    state: 'TX',
    latitude: '32.522353',
    longitude: '-94.733035',
    timeZoneId: 'America/Chicago',
  },
  '75602': {
    zip: '75602',
    city: 'Longview',
    state: 'TX',
    latitude: '32.47392',
    longitude: '-94.68486',
    timeZoneId: 'America/Chicago',
  },
  '75603': {
    zip: '75603',
    city: 'Longview',
    state: 'TX',
    latitude: '32.435729',
    longitude: '-94.725812',
    timeZoneId: 'America/Chicago',
  },
  '75604': {
    zip: '75604',
    city: 'Longview',
    state: 'TX',
    latitude: '32.522216',
    longitude: '-94.799589',
    timeZoneId: 'America/Chicago',
  },
  '75605': {
    zip: '75605',
    city: 'Longview',
    state: 'TX',
    latitude: '32.55605',
    longitude: '-94.782395',
    timeZoneId: 'America/Chicago',
  },
  '75606': {
    zip: '75606',
    city: 'Longview',
    state: 'TX',
    latitude: '32.495736',
    longitude: '-94.738464',
    timeZoneId: 'America/Chicago',
  },
  '75607': {
    zip: '75607',
    city: 'Longview',
    state: 'TX',
    latitude: '32.578408',
    longitude: '-94.779284',
    timeZoneId: 'America/Chicago',
  },
  '75608': {
    zip: '75608',
    city: 'Longview',
    state: 'TX',
    latitude: '32.55609',
    longitude: '-94.801494',
    timeZoneId: 'America/Chicago',
  },
  '75615': {
    zip: '75615',
    city: 'Longview',
    state: 'TX',
    latitude: '32.464337',
    longitude: '-94.728472',
    timeZoneId: 'America/Chicago',
  },
  '75630': {
    zip: '75630',
    city: 'Avinger',
    state: 'TX',
    latitude: '32.868195',
    longitude: '-94.579545',
    timeZoneId: 'America/Chicago',
  },
  '75631': {
    zip: '75631',
    city: 'Beckville',
    state: 'TX',
    latitude: '32.252312',
    longitude: '-94.452675',
    timeZoneId: 'America/Chicago',
  },
  '75633': {
    zip: '75633',
    city: 'Carthage',
    state: 'TX',
    latitude: '32.155436',
    longitude: '-94.342182',
    timeZoneId: 'America/Chicago',
  },
  '75636': {
    zip: '75636',
    city: 'Cason',
    state: 'TX',
    latitude: '32.943509',
    longitude: '-94.705178',
    timeZoneId: 'America/Chicago',
  },
  '75637': {
    zip: '75637',
    city: 'Clayton',
    state: 'TX',
    latitude: '32.100862',
    longitude: '-94.472734',
    timeZoneId: 'America/Chicago',
  },
  '75638': {
    zip: '75638',
    city: 'Daingerfield',
    state: 'TX',
    latitude: '32.976111',
    longitude: '-94.718333',
    timeZoneId: 'America/Chicago',
  },
  '75639': {
    zip: '75639',
    city: 'De Berry',
    state: 'TX',
    latitude: '32.25048',
    longitude: '-94.1394',
    timeZoneId: 'America/Chicago',
  },
  '75640': {
    zip: '75640',
    city: 'Diana',
    state: 'TX',
    latitude: '32.699271',
    longitude: '-94.668973',
    timeZoneId: 'America/Chicago',
  },
  '75641': {
    zip: '75641',
    city: 'Easton',
    state: 'TX',
    latitude: '32.373227',
    longitude: '-94.588435',
    timeZoneId: 'America/Chicago',
  },
  '75642': {
    zip: '75642',
    city: 'Elysian Fields',
    state: 'TX',
    latitude: '32.366441',
    longitude: '-94.185982',
    timeZoneId: 'America/Chicago',
  },
  '75643': {
    zip: '75643',
    city: 'Gary',
    state: 'TX',
    latitude: '32.031808',
    longitude: '-94.395253',
    timeZoneId: 'America/Chicago',
  },
  '75644': {
    zip: '75644',
    city: 'Gilmer',
    state: 'TX',
    latitude: '32.7306',
    longitude: '-94.95894',
    timeZoneId: 'America/Chicago',
  },
  '75645': {
    zip: '75645',
    city: 'Gilmer',
    state: 'TX',
    latitude: '32.721056',
    longitude: '-94.930677',
    timeZoneId: 'America/Chicago',
  },
  '75647': {
    zip: '75647',
    city: 'Gladewater',
    state: 'TX',
    latitude: '32.528514',
    longitude: '-94.941835',
    timeZoneId: 'America/Chicago',
  },
  '75650': {
    zip: '75650',
    city: 'Hallsville',
    state: 'TX',
    latitude: '32.500539',
    longitude: '-94.575687',
    timeZoneId: 'America/Chicago',
  },
  '75651': {
    zip: '75651',
    city: 'Harleton',
    state: 'TX',
    latitude: '32.642141',
    longitude: '-94.538825',
    timeZoneId: 'America/Chicago',
  },
  '75652': {
    zip: '75652',
    city: 'Henderson',
    state: 'TX',
    latitude: '31.93931',
    longitude: '-94.96103',
    timeZoneId: 'America/Chicago',
  },
  '75653': {
    zip: '75653',
    city: 'Henderson',
    state: 'TX',
    latitude: '32.161816',
    longitude: '-94.805683',
    timeZoneId: 'America/Chicago',
  },
  '75654': {
    zip: '75654',
    city: 'Henderson',
    state: 'TX',
    latitude: '32.15613',
    longitude: '-94.797864',
    timeZoneId: 'America/Chicago',
  },
  '75656': {
    zip: '75656',
    city: 'Hughes Springs',
    state: 'TX',
    latitude: '32.941001',
    longitude: '-94.707975',
    timeZoneId: 'America/Chicago',
  },
  '75657': {
    zip: '75657',
    city: 'Jefferson',
    state: 'TX',
    latitude: '32.78767',
    longitude: '-94.07313',
    timeZoneId: 'America/Chicago',
  },
  '75658': {
    zip: '75658',
    city: 'Joinerville',
    state: 'TX',
    latitude: '32.18168',
    longitude: '-94.907333',
    timeZoneId: 'America/Chicago',
  },
  '75659': {
    zip: '75659',
    city: 'Jonesville',
    state: 'TX',
    latitude: '32.497692',
    longitude: '-94.112507',
    timeZoneId: 'America/Chicago',
  },
  '75660': {
    zip: '75660',
    city: 'Judson',
    state: 'TX',
    latitude: '32.585634',
    longitude: '-94.756181',
    timeZoneId: 'America/Chicago',
  },
  '75661': {
    zip: '75661',
    city: 'Karnack',
    state: 'TX',
    latitude: '32.6283',
    longitude: '-94.17528',
    timeZoneId: 'America/Chicago',
  },
  '75662': {
    zip: '75662',
    city: 'Kilgore',
    state: 'TX',
    latitude: '32.40024',
    longitude: '-94.89018',
    timeZoneId: 'America/Chicago',
  },
  '75663': {
    zip: '75663',
    city: 'Kilgore',
    state: 'TX',
    latitude: '32.375233',
    longitude: '-94.865339',
    timeZoneId: 'America/Chicago',
  },
  '75666': {
    zip: '75666',
    city: 'Laird Hill',
    state: 'TX',
    latitude: '32.354875',
    longitude: '-94.903025',
    timeZoneId: 'America/Chicago',
  },
  '75667': {
    zip: '75667',
    city: 'Laneville',
    state: 'TX',
    latitude: '31.945127',
    longitude: '-94.853612',
    timeZoneId: 'America/Chicago',
  },
  '75668': {
    zip: '75668',
    city: 'Lone Star',
    state: 'TX',
    latitude: '32.943829',
    longitude: '-94.708459',
    timeZoneId: 'America/Chicago',
  },
  '75669': {
    zip: '75669',
    city: 'Long Branch',
    state: 'TX',
    latitude: '32.072778',
    longitude: '-94.567778',
    timeZoneId: 'America/Chicago',
  },
  '75670': {
    zip: '75670',
    city: 'Marshall',
    state: 'TX',
    latitude: '32.5167',
    longitude: '-94.36158',
    timeZoneId: 'America/Chicago',
  },
  '75671': {
    zip: '75671',
    city: 'Marshall',
    state: 'TX',
    latitude: '32.545597',
    longitude: '-94.369373',
    timeZoneId: 'America/Chicago',
  },
  '75672': {
    zip: '75672',
    city: 'Marshall',
    state: 'TX',
    latitude: '32.534636',
    longitude: '-94.353464',
    timeZoneId: 'America/Chicago',
  },
  '75680': {
    zip: '75680',
    city: 'Minden',
    state: 'TX',
    latitude: '32.147707',
    longitude: '-94.798976',
    timeZoneId: 'America/Chicago',
  },
  '75681': {
    zip: '75681',
    city: 'Mount Enterprise',
    state: 'TX',
    latitude: '31.909489',
    longitude: '-94.613298',
    timeZoneId: 'America/Chicago',
  },
  '75682': {
    zip: '75682',
    city: 'New London',
    state: 'TX',
    latitude: '32.242434',
    longitude: '-94.946652',
    timeZoneId: 'America/Chicago',
  },
  '75683': {
    zip: '75683',
    city: 'Ore City',
    state: 'TX',
    latitude: '32.790463',
    longitude: '-94.654091',
    timeZoneId: 'America/Chicago',
  },
  '75684': {
    zip: '75684',
    city: 'Overton',
    state: 'TX',
    latitude: '32.26662',
    longitude: '-94.95498',
    timeZoneId: 'America/Chicago',
  },
  '75685': {
    zip: '75685',
    city: 'Panola',
    state: 'TX',
    latitude: '32.441546',
    longitude: '-93.973308',
    timeZoneId: 'America/Chicago',
  },
  '75686': {
    zip: '75686',
    city: 'Pittsburg',
    state: 'TX',
    latitude: '32.997204',
    longitude: '-94.967696',
    timeZoneId: 'America/Chicago',
  },
  '75687': {
    zip: '75687',
    city: 'Price',
    state: 'TX',
    latitude: '32.097208',
    longitude: '-94.941505',
    timeZoneId: 'America/Chicago',
  },
  '75688': {
    zip: '75688',
    city: 'Scottsville',
    state: 'TX',
    latitude: '32.534173',
    longitude: '-94.240709',
    timeZoneId: 'America/Chicago',
  },
  '75689': {
    zip: '75689',
    city: 'Selman City',
    state: 'TX',
    latitude: '32.183333',
    longitude: '-94.966667',
    timeZoneId: 'America/Chicago',
  },
  '75691': {
    zip: '75691',
    city: 'Tatum',
    state: 'TX',
    latitude: '32.329081',
    longitude: '-94.530614',
    timeZoneId: 'America/Chicago',
  },
  '75692': {
    zip: '75692',
    city: 'Waskom',
    state: 'TX',
    latitude: '32.476919',
    longitude: '-94.063815',
    timeZoneId: 'America/Chicago',
  },
  '75693': {
    zip: '75693',
    city: 'White Oak',
    state: 'TX',
    latitude: '32.540043',
    longitude: '-94.857003',
    timeZoneId: 'America/Chicago',
  },
  '75694': {
    zip: '75694',
    city: 'Woodlawn',
    state: 'TX',
    latitude: '32.670639',
    longitude: '-94.345255',
    timeZoneId: 'America/Chicago',
  },
  '75701': {
    zip: '75701',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.326456',
    longitude: '-95.294488',
    timeZoneId: 'America/Chicago',
  },
  '75702': {
    zip: '75702',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.362178',
    longitude: '-95.309998',
    timeZoneId: 'America/Chicago',
  },
  '75703': {
    zip: '75703',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.27878',
    longitude: '-95.303581',
    timeZoneId: 'America/Chicago',
  },
  '75704': {
    zip: '75704',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.405184',
    longitude: '-95.432727',
    timeZoneId: 'America/Chicago',
  },
  '75705': {
    zip: '75705',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.361239',
    longitude: '-95.084145',
    timeZoneId: 'America/Chicago',
  },
  '75706': {
    zip: '75706',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.46451',
    longitude: '-95.27839',
    timeZoneId: 'America/Chicago',
  },
  '75707': {
    zip: '75707',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.29728',
    longitude: '-95.2029',
    timeZoneId: 'America/Chicago',
  },
  '75708': {
    zip: '75708',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.398669',
    longitude: '-95.243419',
    timeZoneId: 'America/Chicago',
  },
  '75709': {
    zip: '75709',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.331224',
    longitude: '-95.364973',
    timeZoneId: 'America/Chicago',
  },
  '75710': {
    zip: '75710',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.364819',
    longitude: '-95.317669',
    timeZoneId: 'America/Chicago',
  },
  '75711': {
    zip: '75711',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.393615',
    longitude: '-95.409413',
    timeZoneId: 'America/Chicago',
  },
  '75712': {
    zip: '75712',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.323638',
    longitude: '-95.293056',
    timeZoneId: 'America/Chicago',
  },
  '75713': {
    zip: '75713',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.324098',
    longitude: '-95.293736',
    timeZoneId: 'America/Chicago',
  },
  '75750': {
    zip: '75750',
    city: 'Arp',
    state: 'TX',
    latitude: '32.254343',
    longitude: '-95.069941',
    timeZoneId: 'America/Chicago',
  },
  '75751': {
    zip: '75751',
    city: 'Athens',
    state: 'TX',
    latitude: '32.1627',
    longitude: '-95.82228',
    timeZoneId: 'America/Chicago',
  },
  '75752': {
    zip: '75752',
    city: 'Athens',
    state: 'TX',
    latitude: '32.276517',
    longitude: '-95.808593',
    timeZoneId: 'America/Chicago',
  },
  '75754': {
    zip: '75754',
    city: 'Ben Wheeler',
    state: 'TX',
    latitude: '32.41452',
    longitude: '-95.64912',
    timeZoneId: 'America/Chicago',
  },
  '75755': {
    zip: '75755',
    city: 'Big Sandy',
    state: 'TX',
    latitude: '32.58326',
    longitude: '-95.112002',
    timeZoneId: 'America/Chicago',
  },
  '75756': {
    zip: '75756',
    city: 'Brownsboro',
    state: 'TX',
    latitude: '32.29512',
    longitude: '-95.6235',
    timeZoneId: 'America/Chicago',
  },
  '75757': {
    zip: '75757',
    city: 'Bullard',
    state: 'TX',
    latitude: '32.150666',
    longitude: '-95.372322',
    timeZoneId: 'America/Chicago',
  },
  '75758': {
    zip: '75758',
    city: 'Chandler',
    state: 'TX',
    latitude: '32.241628',
    longitude: '-95.532762',
    timeZoneId: 'America/Chicago',
  },
  '75759': {
    zip: '75759',
    city: 'Cuney',
    state: 'TX',
    latitude: '31.872734',
    longitude: '-95.226061',
    timeZoneId: 'America/Chicago',
  },
  '75760': {
    zip: '75760',
    city: 'Cushing',
    state: 'TX',
    latitude: '31.765157',
    longitude: '-94.857374',
    timeZoneId: 'America/Chicago',
  },
  '75762': {
    zip: '75762',
    city: 'Flint',
    state: 'TX',
    latitude: '32.22174',
    longitude: '-95.3964',
    timeZoneId: 'America/Chicago',
  },
  '75763': {
    zip: '75763',
    city: 'Frankston',
    state: 'TX',
    latitude: '32.05368',
    longitude: '-95.49654',
    timeZoneId: 'America/Chicago',
  },
  '75764': {
    zip: '75764',
    city: 'Gallatin',
    state: 'TX',
    latitude: '31.894007',
    longitude: '-95.155587',
    timeZoneId: 'America/Chicago',
  },
  '75765': {
    zip: '75765',
    city: 'Hawkins',
    state: 'TX',
    latitude: '32.65572',
    longitude: '-95.197003',
    timeZoneId: 'America/Chicago',
  },
  '75766': {
    zip: '75766',
    city: 'Jacksonville',
    state: 'TX',
    latitude: '31.95006',
    longitude: '-95.2575',
    timeZoneId: 'America/Chicago',
  },
  '75770': {
    zip: '75770',
    city: 'Larue',
    state: 'TX',
    latitude: '32.14992',
    longitude: '-95.6136',
    timeZoneId: 'America/Chicago',
  },
  '75771': {
    zip: '75771',
    city: 'Lindale',
    state: 'TX',
    latitude: '32.514723',
    longitude: '-95.430859',
    timeZoneId: 'America/Chicago',
  },
  '75772': {
    zip: '75772',
    city: 'Maydelle',
    state: 'TX',
    latitude: '31.800804',
    longitude: '-95.303845',
    timeZoneId: 'America/Chicago',
  },
  '75773': {
    zip: '75773',
    city: 'Mineola',
    state: 'TX',
    latitude: '32.6469',
    longitude: '-95.51646',
    timeZoneId: 'America/Chicago',
  },
  '75778': {
    zip: '75778',
    city: 'Murchison',
    state: 'TX',
    latitude: '32.3328',
    longitude: '-95.77308',
    timeZoneId: 'America/Chicago',
  },
  '75779': {
    zip: '75779',
    city: 'Neches',
    state: 'TX',
    latitude: '31.86688',
    longitude: '-95.495787',
    timeZoneId: 'America/Chicago',
  },
  '75780': {
    zip: '75780',
    city: 'New Summerfield',
    state: 'TX',
    latitude: '31.98567',
    longitude: '-95.107132',
    timeZoneId: 'America/Chicago',
  },
  '75782': {
    zip: '75782',
    city: 'Poynor',
    state: 'TX',
    latitude: '32.077631',
    longitude: '-95.593471',
    timeZoneId: 'America/Chicago',
  },
  '75783': {
    zip: '75783',
    city: 'Quitman',
    state: 'TX',
    latitude: '32.795818',
    longitude: '-95.445299',
    timeZoneId: 'America/Chicago',
  },
  '75784': {
    zip: '75784',
    city: 'Reklaw',
    state: 'TX',
    latitude: '31.905784',
    longitude: '-95.017688',
    timeZoneId: 'America/Chicago',
  },
  '75785': {
    zip: '75785',
    city: 'Rusk',
    state: 'TX',
    latitude: '31.79922',
    longitude: '-95.16486',
    timeZoneId: 'America/Chicago',
  },
  '75788': {
    zip: '75788',
    city: 'Sacul',
    state: 'TX',
    latitude: '31.826668',
    longitude: '-94.917381',
    timeZoneId: 'America/Chicago',
  },
  '75789': {
    zip: '75789',
    city: 'Troup',
    state: 'TX',
    latitude: '32.13162',
    longitude: '-95.15478',
    timeZoneId: 'America/Chicago',
  },
  '75790': {
    zip: '75790',
    city: 'Van',
    state: 'TX',
    latitude: '32.52924',
    longitude: '-95.63749',
    timeZoneId: 'America/Chicago',
  },
  '75791': {
    zip: '75791',
    city: 'Whitehouse',
    state: 'TX',
    latitude: '32.228348',
    longitude: '-95.224044',
    timeZoneId: 'America/Chicago',
  },
  '75792': {
    zip: '75792',
    city: 'Winona',
    state: 'TX',
    latitude: '32.455404',
    longitude: '-95.128624',
    timeZoneId: 'America/Chicago',
  },
  '75797': {
    zip: '75797',
    city: 'Big Sandy',
    state: 'TX',
    latitude: '32.579684',
    longitude: '-95.108732',
    timeZoneId: 'America/Chicago',
  },
  '75798': {
    zip: '75798',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.334479',
    longitude: '-95.285516',
    timeZoneId: 'America/Chicago',
  },
  '75799': {
    zip: '75799',
    city: 'Tyler',
    state: 'TX',
    latitude: '32.328487',
    longitude: '-95.30378',
    timeZoneId: 'America/Chicago',
  },
  '75801': {
    zip: '75801',
    city: 'Palestine',
    state: 'TX',
    latitude: '31.78944',
    longitude: '-95.63202',
    timeZoneId: 'America/Chicago',
  },
  '75802': {
    zip: '75802',
    city: 'Palestine',
    state: 'TX',
    latitude: '31.761164',
    longitude: '-95.633435',
    timeZoneId: 'America/Chicago',
  },
  '75803': {
    zip: '75803',
    city: 'Palestine',
    state: 'TX',
    latitude: '31.755991',
    longitude: '-95.638246',
    timeZoneId: 'America/Chicago',
  },
  '75831': {
    zip: '75831',
    city: 'Buffalo',
    state: 'TX',
    latitude: '31.461433',
    longitude: '-96.074604',
    timeZoneId: 'America/Chicago',
  },
  '75832': {
    zip: '75832',
    city: 'Cayuga',
    state: 'TX',
    latitude: '31.958674',
    longitude: '-95.973425',
    timeZoneId: 'America/Chicago',
  },
  '75833': {
    zip: '75833',
    city: 'Centerville',
    state: 'TX',
    latitude: '31.249797',
    longitude: '-95.973492',
    timeZoneId: 'America/Chicago',
  },
  '75834': {
    zip: '75834',
    city: 'Centralia',
    state: 'TX',
    latitude: '31.258369',
    longitude: '-95.040616',
    timeZoneId: 'America/Chicago',
  },
  '75835': {
    zip: '75835',
    city: 'Crockett',
    state: 'TX',
    latitude: '31.270556',
    longitude: '-95.608889',
    timeZoneId: 'America/Chicago',
  },
  '75838': {
    zip: '75838',
    city: 'Donie',
    state: 'TX',
    latitude: '31.479552',
    longitude: '-96.251191',
    timeZoneId: 'America/Chicago',
  },
  '75839': {
    zip: '75839',
    city: 'Elkhart',
    state: 'TX',
    latitude: '31.62024',
    longitude: '-95.51982',
    timeZoneId: 'America/Chicago',
  },
  '75840': {
    zip: '75840',
    city: 'Fairfield',
    state: 'TX',
    latitude: '31.722324',
    longitude: '-96.157345',
    timeZoneId: 'America/Chicago',
  },
  '75844': {
    zip: '75844',
    city: 'Grapeland',
    state: 'TX',
    latitude: '31.49242',
    longitude: '-95.483017',
    timeZoneId: 'America/Chicago',
  },
  '75845': {
    zip: '75845',
    city: 'Groveton',
    state: 'TX',
    latitude: '31.06026',
    longitude: '-95.09184',
    timeZoneId: 'America/Chicago',
  },
  '75846': {
    zip: '75846',
    city: 'Jewett',
    state: 'TX',
    latitude: '31.375296',
    longitude: '-96.221448',
    timeZoneId: 'America/Chicago',
  },
  '75847': {
    zip: '75847',
    city: 'Kennard',
    state: 'TX',
    latitude: '31.349522',
    longitude: '-95.125937',
    timeZoneId: 'America/Chicago',
  },
  '75848': {
    zip: '75848',
    city: 'Kirvin',
    state: 'TX',
    latitude: '31.766052',
    longitude: '-96.332854',
    timeZoneId: 'America/Chicago',
  },
  '75849': {
    zip: '75849',
    city: 'Latexo',
    state: 'TX',
    latitude: '31.396803',
    longitude: '-95.477311',
    timeZoneId: 'America/Chicago',
  },
  '75850': {
    zip: '75850',
    city: 'Leona',
    state: 'TX',
    latitude: '31.133565',
    longitude: '-95.909582',
    timeZoneId: 'America/Chicago',
  },
  '75851': {
    zip: '75851',
    city: 'Lovelady',
    state: 'TX',
    latitude: '31.101806',
    longitude: '-95.51993',
    timeZoneId: 'America/Chicago',
  },
  '75852': {
    zip: '75852',
    city: 'Midway',
    state: 'TX',
    latitude: '31.01892',
    longitude: '-95.7582',
    timeZoneId: 'America/Chicago',
  },
  '75853': {
    zip: '75853',
    city: 'Montalba',
    state: 'TX',
    latitude: '31.94178',
    longitude: '-95.76312',
    timeZoneId: 'America/Chicago',
  },
  '75855': {
    zip: '75855',
    city: 'Oakwood',
    state: 'TX',
    latitude: '31.580983',
    longitude: '-95.892859',
    timeZoneId: 'America/Chicago',
  },
  '75856': {
    zip: '75856',
    city: 'Pennington',
    state: 'TX',
    latitude: '31.157608',
    longitude: '-95.160082',
    timeZoneId: 'America/Chicago',
  },
  '75858': {
    zip: '75858',
    city: 'Ratcliff',
    state: 'TX',
    latitude: '31.392189',
    longitude: '-95.142575',
    timeZoneId: 'America/Chicago',
  },
  '75859': {
    zip: '75859',
    city: 'Streetman',
    state: 'TX',
    latitude: '31.92156',
    longitude: '-96.28092',
    timeZoneId: 'America/Chicago',
  },
  '75860': {
    zip: '75860',
    city: 'Teague',
    state: 'TX',
    latitude: '31.624665',
    longitude: '-96.281615',
    timeZoneId: 'America/Chicago',
  },
  '75861': {
    zip: '75861',
    city: 'Tennessee Colony',
    state: 'TX',
    latitude: '31.885591',
    longitude: '-95.920349',
    timeZoneId: 'America/Chicago',
  },
  '75862': {
    zip: '75862',
    city: 'Trinity',
    state: 'TX',
    latitude: '30.9459',
    longitude: '-95.35542',
    timeZoneId: 'America/Chicago',
  },
  '75865': {
    zip: '75865',
    city: 'Woodlake',
    state: 'TX',
    latitude: '31.030787',
    longitude: '-95.034157',
    timeZoneId: 'America/Chicago',
  },
  '75880': {
    zip: '75880',
    city: 'Tennessee Colony',
    state: 'TX',
    latitude: '31.835895',
    longitude: '-95.839416',
    timeZoneId: 'America/Chicago',
  },
  '75882': {
    zip: '75882',
    city: 'Palestine',
    state: 'TX',
    latitude: '31.757811',
    longitude: '-95.644004',
    timeZoneId: 'America/Chicago',
  },
  '75884': {
    zip: '75884',
    city: 'Tennessee Colony',
    state: 'TX',
    latitude: '31.834403',
    longitude: '-95.84054',
    timeZoneId: 'America/Chicago',
  },
  '75886': {
    zip: '75886',
    city: 'Tennessee Colony',
    state: 'TX',
    latitude: '31.836617',
    longitude: '-95.838882',
    timeZoneId: 'America/Chicago',
  },
  '75901': {
    zip: '75901',
    city: 'Lufkin',
    state: 'TX',
    latitude: '31.330215',
    longitude: '-94.721876',
    timeZoneId: 'America/Chicago',
  },
  '75902': {
    zip: '75902',
    city: 'Lufkin',
    state: 'TX',
    latitude: '31.322803',
    longitude: '-94.801068',
    timeZoneId: 'America/Chicago',
  },
  '75903': {
    zip: '75903',
    city: 'Lufkin',
    state: 'TX',
    latitude: '31.355643',
    longitude: '-94.767354',
    timeZoneId: 'America/Chicago',
  },
  '75904': {
    zip: '75904',
    city: 'Lufkin',
    state: 'TX',
    latitude: '31.282943',
    longitude: '-94.807267',
    timeZoneId: 'America/Chicago',
  },
  '75915': {
    zip: '75915',
    city: 'Lufkin',
    state: 'TX',
    latitude: '31.335282',
    longitude: '-94.72871',
    timeZoneId: 'America/Chicago',
  },
  '75925': {
    zip: '75925',
    city: 'Alto',
    state: 'TX',
    latitude: '31.64208',
    longitude: '-95.0871',
    timeZoneId: 'America/Chicago',
  },
  '75926': {
    zip: '75926',
    city: 'Apple Springs',
    state: 'TX',
    latitude: '31.2596',
    longitude: '-94.971845',
    timeZoneId: 'America/Chicago',
  },
  '75928': {
    zip: '75928',
    city: 'Bon Wier',
    state: 'TX',
    latitude: '30.680792',
    longitude: '-93.761316',
    timeZoneId: 'America/Chicago',
  },
  '75929': {
    zip: '75929',
    city: 'Broaddus',
    state: 'TX',
    latitude: '31.332978',
    longitude: '-94.226451',
    timeZoneId: 'America/Chicago',
  },
  '75930': {
    zip: '75930',
    city: 'Bronson',
    state: 'TX',
    latitude: '31.3287',
    longitude: '-94.0197',
    timeZoneId: 'America/Chicago',
  },
  '75931': {
    zip: '75931',
    city: 'Brookeland',
    state: 'TX',
    latitude: '31.0983',
    longitude: '-93.99918',
    timeZoneId: 'America/Chicago',
  },
  '75932': {
    zip: '75932',
    city: 'Burkeville',
    state: 'TX',
    latitude: '31.02822',
    longitude: '-93.64308',
    timeZoneId: 'America/Chicago',
  },
  '75933': {
    zip: '75933',
    city: 'Call',
    state: 'TX',
    latitude: '30.517146',
    longitude: '-93.809106',
    timeZoneId: 'America/Chicago',
  },
  '75934': {
    zip: '75934',
    city: 'Camden',
    state: 'TX',
    latitude: '30.917797',
    longitude: '-94.735098',
    timeZoneId: 'America/Chicago',
  },
  '75935': {
    zip: '75935',
    city: 'Center',
    state: 'TX',
    latitude: '31.79508',
    longitude: '-94.16418',
    timeZoneId: 'America/Chicago',
  },
  '75936': {
    zip: '75936',
    city: 'Chester',
    state: 'TX',
    latitude: '30.767579',
    longitude: '-94.45824',
    timeZoneId: 'America/Chicago',
  },
  '75937': {
    zip: '75937',
    city: 'Chireno',
    state: 'TX',
    latitude: '31.484579',
    longitude: '-94.414781',
    timeZoneId: 'America/Chicago',
  },
  '75938': {
    zip: '75938',
    city: 'Colmesneil',
    state: 'TX',
    latitude: '30.91278',
    longitude: '-94.4202',
    timeZoneId: 'America/Chicago',
  },
  '75939': {
    zip: '75939',
    city: 'Corrigan',
    state: 'TX',
    latitude: '31.001922',
    longitude: '-94.818309',
    timeZoneId: 'America/Chicago',
  },
  '75941': {
    zip: '75941',
    city: 'Diboll',
    state: 'TX',
    latitude: '31.187357',
    longitude: '-94.786365',
    timeZoneId: 'America/Chicago',
  },
  '75942': {
    zip: '75942',
    city: 'Doucette',
    state: 'TX',
    latitude: '30.818426',
    longitude: '-94.431567',
    timeZoneId: 'America/Chicago',
  },
  '75943': {
    zip: '75943',
    city: 'Douglass',
    state: 'TX',
    latitude: '31.659041',
    longitude: '-94.873128',
    timeZoneId: 'America/Chicago',
  },
  '75944': {
    zip: '75944',
    city: 'Etoile',
    state: 'TX',
    latitude: '31.373726',
    longitude: '-94.430148',
    timeZoneId: 'America/Chicago',
  },
  '75946': {
    zip: '75946',
    city: 'Garrison',
    state: 'TX',
    latitude: '31.79928',
    longitude: '-94.4853',
    timeZoneId: 'America/Chicago',
  },
  '75948': {
    zip: '75948',
    city: 'Hemphill',
    state: 'TX',
    latitude: '31.30872',
    longitude: '-93.7716',
    timeZoneId: 'America/Chicago',
  },
  '75949': {
    zip: '75949',
    city: 'Huntington',
    state: 'TX',
    latitude: '31.286661',
    longitude: '-94.578663',
    timeZoneId: 'America/Chicago',
  },
  '75951': {
    zip: '75951',
    city: 'Jasper',
    state: 'TX',
    latitude: '30.93528',
    longitude: '-94.01658',
    timeZoneId: 'America/Chicago',
  },
  '75954': {
    zip: '75954',
    city: 'Joaquin',
    state: 'TX',
    latitude: '31.93872',
    longitude: '-94.0554',
    timeZoneId: 'America/Chicago',
  },
  '75956': {
    zip: '75956',
    city: 'Kirbyville',
    state: 'TX',
    latitude: '30.659279',
    longitude: '-93.896201',
    timeZoneId: 'America/Chicago',
  },
  '75958': {
    zip: '75958',
    city: 'Martinsville',
    state: 'TX',
    latitude: '31.641707',
    longitude: '-94.413269',
    timeZoneId: 'America/Chicago',
  },
  '75959': {
    zip: '75959',
    city: 'Milam',
    state: 'TX',
    latitude: '31.48128',
    longitude: '-93.83832',
    timeZoneId: 'America/Chicago',
  },
  '75960': {
    zip: '75960',
    city: 'Moscow',
    state: 'TX',
    latitude: '30.921945',
    longitude: '-94.936938',
    timeZoneId: 'America/Chicago',
  },
  '75961': {
    zip: '75961',
    city: 'Nacogdoches',
    state: 'TX',
    latitude: '31.609182',
    longitude: '-94.650569',
    timeZoneId: 'America/Chicago',
  },
  '75962': {
    zip: '75962',
    city: 'Nacogdoches',
    state: 'TX',
    latitude: '31.615701',
    longitude: '-94.651749',
    timeZoneId: 'America/Chicago',
  },
  '75963': {
    zip: '75963',
    city: 'Nacogdoches',
    state: 'TX',
    latitude: '31.632096',
    longitude: '-94.63732',
    timeZoneId: 'America/Chicago',
  },
  '75964': {
    zip: '75964',
    city: 'Nacogdoches',
    state: 'TX',
    latitude: '31.599177',
    longitude: '-94.666841',
    timeZoneId: 'America/Chicago',
  },
  '75965': {
    zip: '75965',
    city: 'Nacogdoches',
    state: 'TX',
    latitude: '31.612825',
    longitude: '-94.649551',
    timeZoneId: 'America/Chicago',
  },
  '75966': {
    zip: '75966',
    city: 'Newton',
    state: 'TX',
    latitude: '30.842427',
    longitude: '-93.758074',
    timeZoneId: 'America/Chicago',
  },
  '75968': {
    zip: '75968',
    city: 'Pineland',
    state: 'TX',
    latitude: '31.247979',
    longitude: '-93.978384',
    timeZoneId: 'America/Chicago',
  },
  '75969': {
    zip: '75969',
    city: 'Pollok',
    state: 'TX',
    latitude: '31.415556',
    longitude: '-94.938889',
    timeZoneId: 'America/Chicago',
  },
  '75972': {
    zip: '75972',
    city: 'San Augustine',
    state: 'TX',
    latitude: '31.54896',
    longitude: '-94.09566',
    timeZoneId: 'America/Chicago',
  },
  '75973': {
    zip: '75973',
    city: 'Shelbyville',
    state: 'TX',
    latitude: '31.72152',
    longitude: '-93.93258',
    timeZoneId: 'America/Chicago',
  },
  '75974': {
    zip: '75974',
    city: 'Tenaha',
    state: 'TX',
    latitude: '31.939621',
    longitude: '-94.255138',
    timeZoneId: 'America/Chicago',
  },
  '75975': {
    zip: '75975',
    city: 'Timpson',
    state: 'TX',
    latitude: '31.89072',
    longitude: '-94.39014',
    timeZoneId: 'America/Chicago',
  },
  '75976': {
    zip: '75976',
    city: 'Wells',
    state: 'TX',
    latitude: '31.509036',
    longitude: '-94.986968',
    timeZoneId: 'America/Chicago',
  },
  '75977': {
    zip: '75977',
    city: 'Wiergate',
    state: 'TX',
    latitude: '31.07364',
    longitude: '-93.809691',
    timeZoneId: 'America/Chicago',
  },
  '75978': {
    zip: '75978',
    city: 'Woden',
    state: 'TX',
    latitude: '31.503223',
    longitude: '-94.522749',
    timeZoneId: 'America/Chicago',
  },
  '75979': {
    zip: '75979',
    city: 'Woodville',
    state: 'TX',
    latitude: '30.754889',
    longitude: '-94.393444',
    timeZoneId: 'America/Chicago',
  },
  '75980': {
    zip: '75980',
    city: 'Zavalla',
    state: 'TX',
    latitude: '31.16064',
    longitude: '-94.4022',
    timeZoneId: 'America/Chicago',
  },
  '75990': {
    zip: '75990',
    city: 'Woodville',
    state: 'TX',
    latitude: '30.773964',
    longitude: '-94.423527',
    timeZoneId: 'America/Chicago',
  },
  '76001': {
    zip: '76001',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.621097',
    longitude: '-97.154295',
    timeZoneId: 'America/Chicago',
  },
  '76002': {
    zip: '76002',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.629271',
    longitude: '-97.077873',
    timeZoneId: 'America/Chicago',
  },
  '76003': {
    zip: '76003',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.654691',
    longitude: '-97.175539',
    timeZoneId: 'America/Chicago',
  },
  '76004': {
    zip: '76004',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.735488',
    longitude: '-97.106764',
    timeZoneId: 'America/Chicago',
  },
  '76005': {
    zip: '76005',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.749659',
    longitude: '-97.057175',
    timeZoneId: 'America/Chicago',
  },
  '76006': {
    zip: '76006',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.778606',
    longitude: '-97.081409',
    timeZoneId: 'America/Chicago',
  },
  '76007': {
    zip: '76007',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.721384',
    longitude: '-97.08191',
    timeZoneId: 'America/Chicago',
  },
  '76008': {
    zip: '76008',
    city: 'Aledo',
    state: 'TX',
    latitude: '32.699617',
    longitude: '-97.628794',
    timeZoneId: 'America/Chicago',
  },
  '76009': {
    zip: '76009',
    city: 'Alvarado',
    state: 'TX',
    latitude: '32.432973',
    longitude: '-97.195949',
    timeZoneId: 'America/Chicago',
  },
  '76010': {
    zip: '76010',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.722794',
    longitude: '-97.084692',
    timeZoneId: 'America/Chicago',
  },
  '76011': {
    zip: '76011',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.756259',
    longitude: '-97.084801',
    timeZoneId: 'America/Chicago',
  },
  '76012': {
    zip: '76012',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.756084',
    longitude: '-97.135798',
    timeZoneId: 'America/Chicago',
  },
  '76013': {
    zip: '76013',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.717191',
    longitude: '-97.152038',
    timeZoneId: 'America/Chicago',
  },
  '76014': {
    zip: '76014',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.696281',
    longitude: '-97.088027',
    timeZoneId: 'America/Chicago',
  },
  '76015': {
    zip: '76015',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.692305',
    longitude: '-97.133908',
    timeZoneId: 'America/Chicago',
  },
  '76016': {
    zip: '76016',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.689966',
    longitude: '-97.191463',
    timeZoneId: 'America/Chicago',
  },
  '76017': {
    zip: '76017',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.656676',
    longitude: '-97.160065',
    timeZoneId: 'America/Chicago',
  },
  '76018': {
    zip: '76018',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.652642',
    longitude: '-97.094943',
    timeZoneId: 'America/Chicago',
  },
  '76019': {
    zip: '76019',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.728201',
    longitude: '-97.112604',
    timeZoneId: 'America/Chicago',
  },
  '76020': {
    zip: '76020',
    city: 'Azle',
    state: 'TX',
    latitude: '32.91',
    longitude: '-97.55724',
    timeZoneId: 'America/Chicago',
  },
  '76021': {
    zip: '76021',
    city: 'Bedford',
    state: 'TX',
    latitude: '32.855156',
    longitude: '-97.139353',
    timeZoneId: 'America/Chicago',
  },
  '76022': {
    zip: '76022',
    city: 'Bedford',
    state: 'TX',
    latitude: '32.828966',
    longitude: '-97.147631',
    timeZoneId: 'America/Chicago',
  },
  '76023': {
    zip: '76023',
    city: 'Boyd',
    state: 'TX',
    latitude: '33.136623',
    longitude: '-97.646998',
    timeZoneId: 'America/Chicago',
  },
  '76028': {
    zip: '76028',
    city: 'Burleson',
    state: 'TX',
    latitude: '32.529531',
    longitude: '-97.311978',
    timeZoneId: 'America/Chicago',
  },
  '76031': {
    zip: '76031',
    city: 'Cleburne',
    state: 'TX',
    latitude: '32.348683',
    longitude: '-97.392831',
    timeZoneId: 'America/Chicago',
  },
  '76033': {
    zip: '76033',
    city: 'Cleburne',
    state: 'TX',
    latitude: '32.345415',
    longitude: '-97.387647',
    timeZoneId: 'America/Chicago',
  },
  '76034': {
    zip: '76034',
    city: 'Colleyville',
    state: 'TX',
    latitude: '32.889382',
    longitude: '-97.145416',
    timeZoneId: 'America/Chicago',
  },
  '76035': {
    zip: '76035',
    city: 'Cresson',
    state: 'TX',
    latitude: '32.567743',
    longitude: '-97.681973',
    timeZoneId: 'America/Chicago',
  },
  '76036': {
    zip: '76036',
    city: 'Crowley',
    state: 'TX',
    latitude: '32.584696',
    longitude: '-97.371992',
    timeZoneId: 'America/Chicago',
  },
  '76039': {
    zip: '76039',
    city: 'Euless',
    state: 'TX',
    latitude: '32.853924',
    longitude: '-97.086444',
    timeZoneId: 'America/Chicago',
  },
  '76040': {
    zip: '76040',
    city: 'Euless',
    state: 'TX',
    latitude: '32.828032',
    longitude: '-97.104702',
    timeZoneId: 'America/Chicago',
  },
  '76041': {
    zip: '76041',
    city: 'Forreston',
    state: 'TX',
    latitude: '32.235041',
    longitude: '-96.854712',
    timeZoneId: 'America/Chicago',
  },
  '76043': {
    zip: '76043',
    city: 'Glen Rose',
    state: 'TX',
    latitude: '32.233527',
    longitude: '-97.750119',
    timeZoneId: 'America/Chicago',
  },
  '76044': {
    zip: '76044',
    city: 'Godley',
    state: 'TX',
    latitude: '32.449349',
    longitude: '-97.530941',
    timeZoneId: 'America/Chicago',
  },
  '76048': {
    zip: '76048',
    city: 'Granbury',
    state: 'TX',
    latitude: '32.431238',
    longitude: '-97.762143',
    timeZoneId: 'America/Chicago',
  },
  '76049': {
    zip: '76049',
    city: 'Granbury',
    state: 'TX',
    latitude: '32.449949',
    longitude: '-97.737417',
    timeZoneId: 'America/Chicago',
  },
  '76050': {
    zip: '76050',
    city: 'Grandview',
    state: 'TX',
    latitude: '32.266227',
    longitude: '-97.177163',
    timeZoneId: 'America/Chicago',
  },
  '76051': {
    zip: '76051',
    city: 'Grapevine',
    state: 'TX',
    latitude: '32.933618',
    longitude: '-97.097514',
    timeZoneId: 'America/Chicago',
  },
  '76052': {
    zip: '76052',
    city: 'Haslet',
    state: 'TX',
    latitude: '32.95636',
    longitude: '-97.34603',
    timeZoneId: 'America/Chicago',
  },
  '76053': {
    zip: '76053',
    city: 'Hurst',
    state: 'TX',
    latitude: '32.824147',
    longitude: '-97.176902',
    timeZoneId: 'America/Chicago',
  },
  '76054': {
    zip: '76054',
    city: 'Hurst',
    state: 'TX',
    latitude: '32.860151',
    longitude: '-97.177784',
    timeZoneId: 'America/Chicago',
  },
  '76055': {
    zip: '76055',
    city: 'Itasca',
    state: 'TX',
    latitude: '32.161273',
    longitude: '-97.150457',
    timeZoneId: 'America/Chicago',
  },
  '76058': {
    zip: '76058',
    city: 'Joshua',
    state: 'TX',
    latitude: '32.46565',
    longitude: '-97.396046',
    timeZoneId: 'America/Chicago',
  },
  '76059': {
    zip: '76059',
    city: 'Keene',
    state: 'TX',
    latitude: '32.39304',
    longitude: '-97.327486',
    timeZoneId: 'America/Chicago',
  },
  '76060': {
    zip: '76060',
    city: 'Kennedale',
    state: 'TX',
    latitude: '32.642683',
    longitude: '-97.211734',
    timeZoneId: 'America/Chicago',
  },
  '76061': {
    zip: '76061',
    city: 'Lillian',
    state: 'TX',
    latitude: '32.503898',
    longitude: '-97.190717',
    timeZoneId: 'America/Chicago',
  },
  '76063': {
    zip: '76063',
    city: 'Mansfield',
    state: 'TX',
    latitude: '32.580527',
    longitude: '-97.138872',
    timeZoneId: 'America/Chicago',
  },
  '76064': {
    zip: '76064',
    city: 'Maypearl',
    state: 'TX',
    latitude: '32.308249',
    longitude: '-97.028525',
    timeZoneId: 'America/Chicago',
  },
  '76065': {
    zip: '76065',
    city: 'Midlothian',
    state: 'TX',
    latitude: '32.478311',
    longitude: '-96.981773',
    timeZoneId: 'America/Chicago',
  },
  '76066': {
    zip: '76066',
    city: 'Millsap',
    state: 'TX',
    latitude: '32.757899',
    longitude: '-98.005545',
    timeZoneId: 'America/Chicago',
  },
  '76067': {
    zip: '76067',
    city: 'Mineral Wells',
    state: 'TX',
    latitude: '32.82678',
    longitude: '-98.09718',
    timeZoneId: 'America/Chicago',
  },
  '76068': {
    zip: '76068',
    city: 'Mineral Wells',
    state: 'TX',
    latitude: '32.807949',
    longitude: '-98.112596',
    timeZoneId: 'America/Chicago',
  },
  '76070': {
    zip: '76070',
    city: 'Nemo',
    state: 'TX',
    latitude: '32.283795',
    longitude: '-97.648649',
    timeZoneId: 'America/Chicago',
  },
  '76071': {
    zip: '76071',
    city: 'Newark',
    state: 'TX',
    latitude: '33.00977',
    longitude: '-97.536559',
    timeZoneId: 'America/Chicago',
  },
  '76073': {
    zip: '76073',
    city: 'Paradise',
    state: 'TX',
    latitude: '33.099525',
    longitude: '-97.777889',
    timeZoneId: 'America/Chicago',
  },
  '76077': {
    zip: '76077',
    city: 'Rainbow',
    state: 'TX',
    latitude: '32.288273',
    longitude: '-97.698503',
    timeZoneId: 'America/Chicago',
  },
  '76078': {
    zip: '76078',
    city: 'Rhome',
    state: 'TX',
    latitude: '33.046961',
    longitude: '-97.472602',
    timeZoneId: 'America/Chicago',
  },
  '76082': {
    zip: '76082',
    city: 'Springtown',
    state: 'TX',
    latitude: '32.960236',
    longitude: '-97.682574',
    timeZoneId: 'America/Chicago',
  },
  '76084': {
    zip: '76084',
    city: 'Venus',
    state: 'TX',
    latitude: '32.445612',
    longitude: '-97.100962',
    timeZoneId: 'America/Chicago',
  },
  '76085': {
    zip: '76085',
    city: 'Weatherford',
    state: 'TX',
    latitude: '32.757858',
    longitude: '-97.794211',
    timeZoneId: 'America/Chicago',
  },
  '76086': {
    zip: '76086',
    city: 'Weatherford',
    state: 'TX',
    latitude: '32.787926',
    longitude: '-97.817546',
    timeZoneId: 'America/Chicago',
  },
  '76087': {
    zip: '76087',
    city: 'Weatherford',
    state: 'TX',
    latitude: '32.699394',
    longitude: '-97.77265',
    timeZoneId: 'America/Chicago',
  },
  '76088': {
    zip: '76088',
    city: 'Weatherford',
    state: 'TX',
    latitude: '32.766147',
    longitude: '-97.803836',
    timeZoneId: 'America/Chicago',
  },
  '76092': {
    zip: '76092',
    city: 'Southlake',
    state: 'TX',
    latitude: '32.953968',
    longitude: '-97.150453',
    timeZoneId: 'America/Chicago',
  },
  '76093': {
    zip: '76093',
    city: 'Rio Vista',
    state: 'TX',
    latitude: '32.245704',
    longitude: '-97.38365',
    timeZoneId: 'America/Chicago',
  },
  '76094': {
    zip: '76094',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.724092',
    longitude: '-97.150735',
    timeZoneId: 'America/Chicago',
  },
  '76095': {
    zip: '76095',
    city: 'Bedford',
    state: 'TX',
    latitude: '32.855376',
    longitude: '-97.149283',
    timeZoneId: 'America/Chicago',
  },
  '76096': {
    zip: '76096',
    city: 'Arlington',
    state: 'TX',
    latitude: '32.672124',
    longitude: '-97.091014',
    timeZoneId: 'America/Chicago',
  },
  '76097': {
    zip: '76097',
    city: 'Burleson',
    state: 'TX',
    latitude: '32.54091',
    longitude: '-97.321069',
    timeZoneId: 'America/Chicago',
  },
  '76098': {
    zip: '76098',
    city: 'Azle',
    state: 'TX',
    latitude: '32.898709',
    longitude: '-97.540906',
    timeZoneId: 'America/Chicago',
  },
  '76099': {
    zip: '76099',
    city: 'Grapevine',
    state: 'TX',
    latitude: '32.931793',
    longitude: '-97.078104',
    timeZoneId: 'America/Chicago',
  },
  '76101': {
    zip: '76101',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.759439',
    longitude: '-97.329119',
    timeZoneId: 'America/Chicago',
  },
  '76102': {
    zip: '76102',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.757178',
    longitude: '-97.323972',
    timeZoneId: 'America/Chicago',
  },
  '76103': {
    zip: '76103',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.747249',
    longitude: '-97.265412',
    timeZoneId: 'America/Chicago',
  },
  '76104': {
    zip: '76104',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.72882',
    longitude: '-97.319414',
    timeZoneId: 'America/Chicago',
  },
  '76105': {
    zip: '76105',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.722238',
    longitude: '-97.26743',
    timeZoneId: 'America/Chicago',
  },
  '76106': {
    zip: '76106',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.799177',
    longitude: '-97.358089',
    timeZoneId: 'America/Chicago',
  },
  '76107': {
    zip: '76107',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.74332',
    longitude: '-97.38048',
    timeZoneId: 'America/Chicago',
  },
  '76108': {
    zip: '76108',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.78154',
    longitude: '-97.50378',
    timeZoneId: 'America/Chicago',
  },
  '76109': {
    zip: '76109',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.701611',
    longitude: '-97.38225',
    timeZoneId: 'America/Chicago',
  },
  '76110': {
    zip: '76110',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.704715',
    longitude: '-97.334989',
    timeZoneId: 'America/Chicago',
  },
  '76111': {
    zip: '76111',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.779783',
    longitude: '-97.302729',
    timeZoneId: 'America/Chicago',
  },
  '76112': {
    zip: '76112',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.749775',
    longitude: '-97.211509',
    timeZoneId: 'America/Chicago',
  },
  '76113': {
    zip: '76113',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.746555',
    longitude: '-97.328024',
    timeZoneId: 'America/Chicago',
  },
  '76114': {
    zip: '76114',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.77932',
    longitude: '-97.39188',
    timeZoneId: 'America/Chicago',
  },
  '76115': {
    zip: '76115',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.682825',
    longitude: '-97.3323',
    timeZoneId: 'America/Chicago',
  },
  '76116': {
    zip: '76116',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.714361',
    longitude: '-97.464571',
    timeZoneId: 'America/Chicago',
  },
  '76117': {
    zip: '76117',
    city: 'Haltom City',
    state: 'TX',
    latitude: '32.802093',
    longitude: '-97.265859',
    timeZoneId: 'America/Chicago',
  },
  '76118': {
    zip: '76118',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.80662',
    longitude: '-97.21026',
    timeZoneId: 'America/Chicago',
  },
  '76119': {
    zip: '76119',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.6805',
    longitude: '-97.26498',
    timeZoneId: 'America/Chicago',
  },
  '76120': {
    zip: '76120',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.765356',
    longitude: '-97.186397',
    timeZoneId: 'America/Chicago',
  },
  '76121': {
    zip: '76121',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.722031',
    longitude: '-97.44986',
    timeZoneId: 'America/Chicago',
  },
  '76122': {
    zip: '76122',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.725278',
    longitude: '-97.320556',
    timeZoneId: 'America/Chicago',
  },
  '76123': {
    zip: '76123',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.625915',
    longitude: '-97.368031',
    timeZoneId: 'America/Chicago',
  },
  '76124': {
    zip: '76124',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.758223',
    longitude: '-97.205267',
    timeZoneId: 'America/Chicago',
  },
  '76126': {
    zip: '76126',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.586584',
    longitude: '-97.538996',
    timeZoneId: 'America/Chicago',
  },
  '76127': {
    zip: '76127',
    city: 'Naval Air Station Jrb',
    state: 'TX',
    latitude: '32.7765',
    longitude: '-97.42338',
    timeZoneId: 'America/Chicago',
  },
  '76129': {
    zip: '76129',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.7059',
    longitude: '-97.35938',
    timeZoneId: 'America/Chicago',
  },
  '76130': {
    zip: '76130',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.706926',
    longitude: '-97.362225',
    timeZoneId: 'America/Chicago',
  },
  '76131': {
    zip: '76131',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.858146',
    longitude: '-97.330601',
    timeZoneId: 'America/Chicago',
  },
  '76132': {
    zip: '76132',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.671991',
    longitude: '-97.432311',
    timeZoneId: 'America/Chicago',
  },
  '76133': {
    zip: '76133',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.652107',
    longitude: '-97.377051',
    timeZoneId: 'America/Chicago',
  },
  '76134': {
    zip: '76134',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.642955',
    longitude: '-97.327416',
    timeZoneId: 'America/Chicago',
  },
  '76135': {
    zip: '76135',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.825756',
    longitude: '-97.451279',
    timeZoneId: 'America/Chicago',
  },
  '76136': {
    zip: '76136',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.899378',
    longitude: '-97.458124',
    timeZoneId: 'America/Chicago',
  },
  '76137': {
    zip: '76137',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.859978',
    longitude: '-97.29343',
    timeZoneId: 'America/Chicago',
  },
  '76140': {
    zip: '76140',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.612498',
    longitude: '-97.271398',
    timeZoneId: 'America/Chicago',
  },
  '76147': {
    zip: '76147',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.7481',
    longitude: '-97.365374',
    timeZoneId: 'America/Chicago',
  },
  '76148': {
    zip: '76148',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.862745',
    longitude: '-97.248844',
    timeZoneId: 'America/Chicago',
  },
  '76150': {
    zip: '76150',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.751455',
    longitude: '-97.338993',
    timeZoneId: 'America/Chicago',
  },
  '76155': {
    zip: '76155',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.823263',
    longitude: '-97.054863',
    timeZoneId: 'America/Chicago',
  },
  '76161': {
    zip: '76161',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.805706',
    longitude: '-97.353001',
    timeZoneId: 'America/Chicago',
  },
  '76162': {
    zip: '76162',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.663309',
    longitude: '-97.377859',
    timeZoneId: 'America/Chicago',
  },
  '76163': {
    zip: '76163',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.664187',
    longitude: '-97.374241',
    timeZoneId: 'America/Chicago',
  },
  '76164': {
    zip: '76164',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.782966',
    longitude: '-97.347496',
    timeZoneId: 'America/Chicago',
  },
  '76166': {
    zip: '76166',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.783918',
    longitude: '-97.347748',
    timeZoneId: 'America/Chicago',
  },
  '76177': {
    zip: '76177',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.972817',
    longitude: '-97.33337',
    timeZoneId: 'America/Chicago',
  },
  '76179': {
    zip: '76179',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.9064',
    longitude: '-97.43322',
    timeZoneId: 'America/Chicago',
  },
  '76180': {
    zip: '76180',
    city: 'North Richland Hills',
    state: 'TX',
    latitude: '32.861011',
    longitude: '-97.21613',
    timeZoneId: 'America/Chicago',
  },
  '76181': {
    zip: '76181',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.813066',
    longitude: '-97.22823',
    timeZoneId: 'America/Chicago',
  },
  '76182': {
    zip: '76182',
    city: 'North Richland Hills',
    state: 'TX',
    latitude: '32.798211',
    longitude: '-97.191792',
    timeZoneId: 'America/Chicago',
  },
  '76185': {
    zip: '76185',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.861974',
    longitude: '-97.216601',
    timeZoneId: 'America/Chicago',
  },
  '76190': {
    zip: '76190',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.800813',
    longitude: '-97.289319',
    timeZoneId: 'America/Chicago',
  },
  '76191': {
    zip: '76191',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.808015',
    longitude: '-97.354309',
    timeZoneId: 'America/Chicago',
  },
  '76192': {
    zip: '76192',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.932938',
    longitude: '-97.435253',
    timeZoneId: 'America/Chicago',
  },
  '76193': {
    zip: '76193',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.747377',
    longitude: '-97.330613',
    timeZoneId: 'America/Chicago',
  },
  '76195': {
    zip: '76195',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.726738',
    longitude: '-97.317356',
    timeZoneId: 'America/Chicago',
  },
  '76196': {
    zip: '76196',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.755869',
    longitude: '-97.338178',
    timeZoneId: 'America/Chicago',
  },
  '76197': {
    zip: '76197',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.777097',
    longitude: '-97.299046',
    timeZoneId: 'America/Chicago',
  },
  '76198': {
    zip: '76198',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.755079',
    longitude: '-97.332446',
    timeZoneId: 'America/Chicago',
  },
  '76199': {
    zip: '76199',
    city: 'Fort Worth',
    state: 'TX',
    latitude: '32.752429',
    longitude: '-97.334282',
    timeZoneId: 'America/Chicago',
  },
  '76201': {
    zip: '76201',
    city: 'Denton',
    state: 'TX',
    latitude: '33.220863',
    longitude: '-97.144294',
    timeZoneId: 'America/Chicago',
  },
  '76202': {
    zip: '76202',
    city: 'Denton',
    state: 'TX',
    latitude: '33.176509',
    longitude: '-97.18345',
    timeZoneId: 'America/Chicago',
  },
  '76203': {
    zip: '76203',
    city: 'Denton',
    state: 'TX',
    latitude: '33.216321',
    longitude: '-97.130801',
    timeZoneId: 'America/Chicago',
  },
  '76204': {
    zip: '76204',
    city: 'Denton',
    state: 'TX',
    latitude: '33.214045',
    longitude: '-97.13265',
    timeZoneId: 'America/Chicago',
  },
  '76205': {
    zip: '76205',
    city: 'Denton',
    state: 'TX',
    latitude: '33.16518',
    longitude: '-97.10538',
    timeZoneId: 'America/Chicago',
  },
  '76206': {
    zip: '76206',
    city: 'Denton',
    state: 'TX',
    latitude: '33.198893',
    longitude: '-97.120262',
    timeZoneId: 'America/Chicago',
  },
  '76207': {
    zip: '76207',
    city: 'Denton',
    state: 'TX',
    latitude: '33.237391',
    longitude: '-97.180102',
    timeZoneId: 'America/Chicago',
  },
  '76208': {
    zip: '76208',
    city: 'Denton',
    state: 'TX',
    latitude: '33.19485',
    longitude: '-97.056584',
    timeZoneId: 'America/Chicago',
  },
  '76209': {
    zip: '76209',
    city: 'Denton',
    state: 'TX',
    latitude: '33.232875',
    longitude: '-97.107192',
    timeZoneId: 'America/Chicago',
  },
  '76210': {
    zip: '76210',
    city: 'Denton',
    state: 'TX',
    latitude: '33.145623',
    longitude: '-97.090468',
    timeZoneId: 'America/Chicago',
  },
  '76225': {
    zip: '76225',
    city: 'Alvord',
    state: 'TX',
    latitude: '33.36006',
    longitude: '-97.6918',
    timeZoneId: 'America/Chicago',
  },
  '76226': {
    zip: '76226',
    city: 'Argyle',
    state: 'TX',
    latitude: '33.10483',
    longitude: '-97.164815',
    timeZoneId: 'America/Chicago',
  },
  '76227': {
    zip: '76227',
    city: 'Aubrey',
    state: 'TX',
    latitude: '33.292349',
    longitude: '-96.986964',
    timeZoneId: 'America/Chicago',
  },
  '76228': {
    zip: '76228',
    city: 'Bellevue',
    state: 'TX',
    latitude: '33.593612',
    longitude: '-98.166185',
    timeZoneId: 'America/Chicago',
  },
  '76230': {
    zip: '76230',
    city: 'Bowie',
    state: 'TX',
    latitude: '33.561402',
    longitude: '-97.838818',
    timeZoneId: 'America/Chicago',
  },
  '76233': {
    zip: '76233',
    city: 'Collinsville',
    state: 'TX',
    latitude: '33.556066',
    longitude: '-96.910921',
    timeZoneId: 'America/Chicago',
  },
  '76234': {
    zip: '76234',
    city: 'Decatur',
    state: 'TX',
    latitude: '33.231308',
    longitude: '-97.588741',
    timeZoneId: 'America/Chicago',
  },
  '76238': {
    zip: '76238',
    city: 'Era',
    state: 'TX',
    latitude: '33.496281',
    longitude: '-97.300529',
    timeZoneId: 'America/Chicago',
  },
  '76239': {
    zip: '76239',
    city: 'Forestburg',
    state: 'TX',
    latitude: '33.545695',
    longitude: '-97.596154',
    timeZoneId: 'America/Chicago',
  },
  '76240': {
    zip: '76240',
    city: 'Gainesville',
    state: 'TX',
    latitude: '33.621754',
    longitude: '-97.121151',
    timeZoneId: 'America/Chicago',
  },
  '76241': {
    zip: '76241',
    city: 'Gainesville',
    state: 'TX',
    latitude: '33.623974',
    longitude: '-97.149609',
    timeZoneId: 'America/Chicago',
  },
  '76244': {
    zip: '76244',
    city: 'Keller',
    state: 'TX',
    latitude: '32.934024',
    longitude: '-97.250604',
    timeZoneId: 'America/Chicago',
  },
  '76245': {
    zip: '76245',
    city: 'Gordonville',
    state: 'TX',
    latitude: '33.857262',
    longitude: '-96.807983',
    timeZoneId: 'America/Chicago',
  },
  '76246': {
    zip: '76246',
    city: 'Greenwood',
    state: 'TX',
    latitude: '33.378499',
    longitude: '-97.474475',
    timeZoneId: 'America/Chicago',
  },
  '76247': {
    zip: '76247',
    city: 'Justin',
    state: 'TX',
    latitude: '33.070947',
    longitude: '-97.302229',
    timeZoneId: 'America/Chicago',
  },
  '76248': {
    zip: '76248',
    city: 'Keller',
    state: 'TX',
    latitude: '32.930266',
    longitude: '-97.247254',
    timeZoneId: 'America/Chicago',
  },
  '76249': {
    zip: '76249',
    city: 'Krum',
    state: 'TX',
    latitude: '33.260888',
    longitude: '-97.237388',
    timeZoneId: 'America/Chicago',
  },
  '76250': {
    zip: '76250',
    city: 'Lindsay',
    state: 'TX',
    latitude: '33.637342',
    longitude: '-97.210865',
    timeZoneId: 'America/Chicago',
  },
  '76251': {
    zip: '76251',
    city: 'Montague',
    state: 'TX',
    latitude: '33.673585',
    longitude: '-97.73242',
    timeZoneId: 'America/Chicago',
  },
  '76252': {
    zip: '76252',
    city: 'Muenster',
    state: 'TX',
    latitude: '33.652773',
    longitude: '-97.377391',
    timeZoneId: 'America/Chicago',
  },
  '76253': {
    zip: '76253',
    city: 'Myra',
    state: 'TX',
    latitude: '33.624448',
    longitude: '-97.314779',
    timeZoneId: 'America/Chicago',
  },
  '76255': {
    zip: '76255',
    city: 'Nocona',
    state: 'TX',
    latitude: '33.796166',
    longitude: '-97.713828',
    timeZoneId: 'America/Chicago',
  },
  '76258': {
    zip: '76258',
    city: 'Pilot Point',
    state: 'TX',
    latitude: '33.384159',
    longitude: '-96.947915',
    timeZoneId: 'America/Chicago',
  },
  '76259': {
    zip: '76259',
    city: 'Ponder',
    state: 'TX',
    latitude: '33.190172',
    longitude: '-97.280414',
    timeZoneId: 'America/Chicago',
  },
  '76261': {
    zip: '76261',
    city: 'Ringgold',
    state: 'TX',
    latitude: '33.804982',
    longitude: '-97.949011',
    timeZoneId: 'America/Chicago',
  },
  '76262': {
    zip: '76262',
    city: 'Roanoke',
    state: 'TX',
    latitude: '33.007049',
    longitude: '-97.194352',
    timeZoneId: 'America/Chicago',
  },
  '76263': {
    zip: '76263',
    city: 'Rosston',
    state: 'TX',
    latitude: '33.479695',
    longitude: '-97.45707',
    timeZoneId: 'America/Chicago',
  },
  '76264': {
    zip: '76264',
    city: 'Sadler',
    state: 'TX',
    latitude: '33.737011',
    longitude: '-96.831865',
    timeZoneId: 'America/Chicago',
  },
  '76265': {
    zip: '76265',
    city: 'Saint Jo',
    state: 'TX',
    latitude: '33.700478',
    longitude: '-97.526008',
    timeZoneId: 'America/Chicago',
  },
  '76266': {
    zip: '76266',
    city: 'Sanger',
    state: 'TX',
    latitude: '33.359586',
    longitude: '-97.179563',
    timeZoneId: 'America/Chicago',
  },
  '76267': {
    zip: '76267',
    city: 'Slidell',
    state: 'TX',
    latitude: '33.356794',
    longitude: '-97.391325',
    timeZoneId: 'America/Chicago',
  },
  '76268': {
    zip: '76268',
    city: 'Southmayd',
    state: 'TX',
    latitude: '33.622991',
    longitude: '-96.7193',
    timeZoneId: 'America/Chicago',
  },
  '76270': {
    zip: '76270',
    city: 'Sunset',
    state: 'TX',
    latitude: '33.459316',
    longitude: '-97.7754',
    timeZoneId: 'America/Chicago',
  },
  '76271': {
    zip: '76271',
    city: 'Tioga',
    state: 'TX',
    latitude: '33.471938',
    longitude: '-96.888923',
    timeZoneId: 'America/Chicago',
  },
  '76272': {
    zip: '76272',
    city: 'Valley View',
    state: 'TX',
    latitude: '33.476767',
    longitude: '-97.104797',
    timeZoneId: 'America/Chicago',
  },
  '76273': {
    zip: '76273',
    city: 'Whitesboro',
    state: 'TX',
    latitude: '33.658844',
    longitude: '-96.908512',
    timeZoneId: 'America/Chicago',
  },
  '76301': {
    zip: '76301',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.911774',
    longitude: '-98.4986',
    timeZoneId: 'America/Chicago',
  },
  '76302': {
    zip: '76302',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.866229',
    longitude: '-98.491331',
    timeZoneId: 'America/Chicago',
  },
  '76305': {
    zip: '76305',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.934579',
    longitude: '-98.538803',
    timeZoneId: 'America/Chicago',
  },
  '76306': {
    zip: '76306',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.98077',
    longitude: '-98.523004',
    timeZoneId: 'America/Chicago',
  },
  '76307': {
    zip: '76307',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.908686',
    longitude: '-98.492673',
    timeZoneId: 'America/Chicago',
  },
  '76308': {
    zip: '76308',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.86977',
    longitude: '-98.538219',
    timeZoneId: 'America/Chicago',
  },
  '76309': {
    zip: '76309',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.893069',
    longitude: '-98.535802',
    timeZoneId: 'America/Chicago',
  },
  '76310': {
    zip: '76310',
    city: 'Wichita Falls',
    state: 'TX',
    latitude: '33.862452',
    longitude: '-98.576512',
    timeZoneId: 'America/Chicago',
  },
  '76311': {
    zip: '76311',
    city: 'Sheppard AFB',
    state: 'TX',
    latitude: '34.014',
    longitude: '-98.49738',
    timeZoneId: 'America/Chicago',
  },
  '76351': {
    zip: '76351',
    city: 'Archer City',
    state: 'TX',
    latitude: '33.587932',
    longitude: '-98.622907',
    timeZoneId: 'America/Chicago',
  },
  '76352': {
    zip: '76352',
    city: 'Bluegrove',
    state: 'TX',
    latitude: '33.676136',
    longitude: '-98.229576',
    timeZoneId: 'America/Chicago',
  },
  '76354': {
    zip: '76354',
    city: 'Burkburnett',
    state: 'TX',
    latitude: '34.088591',
    longitude: '-98.579862',
    timeZoneId: 'America/Chicago',
  },
  '76357': {
    zip: '76357',
    city: 'Byers',
    state: 'TX',
    latitude: '34.063392',
    longitude: '-98.192731',
    timeZoneId: 'America/Chicago',
  },
  '76360': {
    zip: '76360',
    city: 'Electra',
    state: 'TX',
    latitude: '34.018548',
    longitude: '-98.896354',
    timeZoneId: 'America/Chicago',
  },
  '76363': {
    zip: '76363',
    city: 'Goree',
    state: 'TX',
    latitude: '33.495442',
    longitude: '-99.527854',
    timeZoneId: 'America/Chicago',
  },
  '76364': {
    zip: '76364',
    city: 'Harrold',
    state: 'TX',
    latitude: '33.97356',
    longitude: '-99.0258',
    timeZoneId: 'America/Chicago',
  },
  '76365': {
    zip: '76365',
    city: 'Henrietta',
    state: 'TX',
    latitude: '33.816635',
    longitude: '-98.186549',
    timeZoneId: 'America/Chicago',
  },
  '76366': {
    zip: '76366',
    city: 'Holliday',
    state: 'TX',
    latitude: '33.62022',
    longitude: '-98.6898',
    timeZoneId: 'America/Chicago',
  },
  '76367': {
    zip: '76367',
    city: 'Iowa Park',
    state: 'TX',
    latitude: '33.946652',
    longitude: '-98.69018',
    timeZoneId: 'America/Chicago',
  },
  '76369': {
    zip: '76369',
    city: 'Kamay',
    state: 'TX',
    latitude: '33.866687',
    longitude: '-98.801923',
    timeZoneId: 'America/Chicago',
  },
  '76370': {
    zip: '76370',
    city: 'Megargel',
    state: 'TX',
    latitude: '33.357592',
    longitude: '-98.760534',
    timeZoneId: 'America/Chicago',
  },
  '76371': {
    zip: '76371',
    city: 'Munday',
    state: 'TX',
    latitude: '33.447449',
    longitude: '-99.623475',
    timeZoneId: 'America/Chicago',
  },
  '76372': {
    zip: '76372',
    city: 'Newcastle',
    state: 'TX',
    latitude: '33.19272',
    longitude: '-98.80848',
    timeZoneId: 'America/Chicago',
  },
  '76373': {
    zip: '76373',
    city: 'Oklaunion',
    state: 'TX',
    latitude: '34.083436',
    longitude: '-99.159824',
    timeZoneId: 'America/Chicago',
  },
  '76374': {
    zip: '76374',
    city: 'Olney',
    state: 'TX',
    latitude: '33.368531',
    longitude: '-98.762652',
    timeZoneId: 'America/Chicago',
  },
  '76377': {
    zip: '76377',
    city: 'Petrolia',
    state: 'TX',
    latitude: '34.018615',
    longitude: '-98.234478',
    timeZoneId: 'America/Chicago',
  },
  '76379': {
    zip: '76379',
    city: 'Scotland',
    state: 'TX',
    latitude: '33.654963',
    longitude: '-98.467222',
    timeZoneId: 'America/Chicago',
  },
  '76380': {
    zip: '76380',
    city: 'Seymour',
    state: 'TX',
    latitude: '33.591147',
    longitude: '-99.263331',
    timeZoneId: 'America/Chicago',
  },
  '76384': {
    zip: '76384',
    city: 'Vernon',
    state: 'TX',
    latitude: '34.14813',
    longitude: '-99.295162',
    timeZoneId: 'America/Chicago',
  },
  '76385': {
    zip: '76385',
    city: 'Vernon',
    state: 'TX',
    latitude: '34.154095',
    longitude: '-99.264642',
    timeZoneId: 'America/Chicago',
  },
  '76388': {
    zip: '76388',
    city: 'Weinert',
    state: 'TX',
    latitude: '33.325271',
    longitude: '-99.612451',
    timeZoneId: 'America/Chicago',
  },
  '76389': {
    zip: '76389',
    city: 'Windthorst',
    state: 'TX',
    latitude: '33.580829',
    longitude: '-98.431065',
    timeZoneId: 'America/Chicago',
  },
  '76401': {
    zip: '76401',
    city: 'Stephenville',
    state: 'TX',
    latitude: '32.220415',
    longitude: '-98.216583',
    timeZoneId: 'America/Chicago',
  },
  '76402': {
    zip: '76402',
    city: 'Stephenville',
    state: 'TX',
    latitude: '32.21853',
    longitude: '-98.217122',
    timeZoneId: 'America/Chicago',
  },
  '76424': {
    zip: '76424',
    city: 'Breckenridge',
    state: 'TX',
    latitude: '32.752306',
    longitude: '-98.909708',
    timeZoneId: 'America/Chicago',
  },
  '76426': {
    zip: '76426',
    city: 'Bridgeport',
    state: 'TX',
    latitude: '33.151387',
    longitude: '-97.821373',
    timeZoneId: 'America/Chicago',
  },
  '76427': {
    zip: '76427',
    city: 'Bryson',
    state: 'TX',
    latitude: '33.12984',
    longitude: '-98.344304',
    timeZoneId: 'America/Chicago',
  },
  '76429': {
    zip: '76429',
    city: 'Caddo',
    state: 'TX',
    latitude: '32.685585',
    longitude: '-98.642606',
    timeZoneId: 'America/Chicago',
  },
  '76430': {
    zip: '76430',
    city: 'Albany',
    state: 'TX',
    latitude: '32.73023',
    longitude: '-99.293869',
    timeZoneId: 'America/Chicago',
  },
  '76431': {
    zip: '76431',
    city: 'Chico',
    state: 'TX',
    latitude: '33.345712',
    longitude: '-97.814572',
    timeZoneId: 'America/Chicago',
  },
  '76432': {
    zip: '76432',
    city: 'Blanket',
    state: 'TX',
    latitude: '31.824212',
    longitude: '-98.78639',
    timeZoneId: 'America/Chicago',
  },
  '76433': {
    zip: '76433',
    city: 'Bluff Dale',
    state: 'TX',
    latitude: '32.352313',
    longitude: '-98.021842',
    timeZoneId: 'America/Chicago',
  },
  '76435': {
    zip: '76435',
    city: 'Carbon',
    state: 'TX',
    latitude: '32.094721',
    longitude: '-98.965524',
    timeZoneId: 'America/Chicago',
  },
  '76436': {
    zip: '76436',
    city: 'Carlton',
    state: 'TX',
    latitude: '31.904013',
    longitude: '-98.129873',
    timeZoneId: 'America/Chicago',
  },
  '76437': {
    zip: '76437',
    city: 'Cisco',
    state: 'TX',
    latitude: '32.387328',
    longitude: '-98.981735',
    timeZoneId: 'America/Chicago',
  },
  '76439': {
    zip: '76439',
    city: 'Dennis',
    state: 'TX',
    latitude: '32.619991',
    longitude: '-97.92446',
    timeZoneId: 'America/Chicago',
  },
  '76442': {
    zip: '76442',
    city: 'Comanche',
    state: 'TX',
    latitude: '31.936389',
    longitude: '-98.488333',
    timeZoneId: 'America/Chicago',
  },
  '76443': {
    zip: '76443',
    city: 'Cross Plains',
    state: 'TX',
    latitude: '32.127979',
    longitude: '-99.16722',
    timeZoneId: 'America/Chicago',
  },
  '76444': {
    zip: '76444',
    city: 'De Leon',
    state: 'TX',
    latitude: '32.106459',
    longitude: '-98.537498',
    timeZoneId: 'America/Chicago',
  },
  '76445': {
    zip: '76445',
    city: 'Desdemona',
    state: 'TX',
    latitude: '32.303279',
    longitude: '-98.565945',
    timeZoneId: 'America/Chicago',
  },
  '76446': {
    zip: '76446',
    city: 'Dublin',
    state: 'TX',
    latitude: '32.087767',
    longitude: '-98.341739',
    timeZoneId: 'America/Chicago',
  },
  '76448': {
    zip: '76448',
    city: 'Eastland',
    state: 'TX',
    latitude: '32.399569',
    longitude: '-98.823133',
    timeZoneId: 'America/Chicago',
  },
  '76449': {
    zip: '76449',
    city: 'Graford',
    state: 'TX',
    latitude: '32.925153',
    longitude: '-98.303425',
    timeZoneId: 'America/Chicago',
  },
  '76450': {
    zip: '76450',
    city: 'Graham',
    state: 'TX',
    latitude: '33.063225',
    longitude: '-98.633058',
    timeZoneId: 'America/Chicago',
  },
  '76452': {
    zip: '76452',
    city: 'Energy',
    state: 'TX',
    latitude: '31.762877',
    longitude: '-98.368173',
    timeZoneId: 'America/Chicago',
  },
  '76453': {
    zip: '76453',
    city: 'Gordon',
    state: 'TX',
    latitude: '32.575298',
    longitude: '-98.352459',
    timeZoneId: 'America/Chicago',
  },
  '76454': {
    zip: '76454',
    city: 'Gorman',
    state: 'TX',
    latitude: '32.213362',
    longitude: '-98.669901',
    timeZoneId: 'America/Chicago',
  },
  '76455': {
    zip: '76455',
    city: 'Gustine',
    state: 'TX',
    latitude: '31.859419',
    longitude: '-98.360506',
    timeZoneId: 'America/Chicago',
  },
  '76457': {
    zip: '76457',
    city: 'Hico',
    state: 'TX',
    latitude: '31.990782',
    longitude: '-98.024841',
    timeZoneId: 'America/Chicago',
  },
  '76458': {
    zip: '76458',
    city: 'Jacksboro',
    state: 'TX',
    latitude: '33.302798',
    longitude: '-98.156777',
    timeZoneId: 'America/Chicago',
  },
  '76459': {
    zip: '76459',
    city: 'Jermyn',
    state: 'TX',
    latitude: '33.260472',
    longitude: '-98.401293',
    timeZoneId: 'America/Chicago',
  },
  '76460': {
    zip: '76460',
    city: 'Loving',
    state: 'TX',
    latitude: '33.271184',
    longitude: '-98.488628',
    timeZoneId: 'America/Chicago',
  },
  '76461': {
    zip: '76461',
    city: 'Lingleville',
    state: 'TX',
    latitude: '32.244567',
    longitude: '-98.375276',
    timeZoneId: 'America/Chicago',
  },
  '76462': {
    zip: '76462',
    city: 'Lipan',
    state: 'TX',
    latitude: '32.479209',
    longitude: '-98.050086',
    timeZoneId: 'America/Chicago',
  },
  '76463': {
    zip: '76463',
    city: 'Mingus',
    state: 'TX',
    latitude: '32.651561',
    longitude: '-98.433211',
    timeZoneId: 'America/Chicago',
  },
  '76464': {
    zip: '76464',
    city: 'Moran',
    state: 'TX',
    latitude: '32.54441',
    longitude: '-99.168805',
    timeZoneId: 'America/Chicago',
  },
  '76465': {
    zip: '76465',
    city: 'Morgan Mill',
    state: 'TX',
    latitude: '32.387195',
    longitude: '-98.165449',
    timeZoneId: 'America/Chicago',
  },
  '76466': {
    zip: '76466',
    city: 'Olden',
    state: 'TX',
    latitude: '32.42063',
    longitude: '-98.748842',
    timeZoneId: 'America/Chicago',
  },
  '76467': {
    zip: '76467',
    city: 'Paluxy',
    state: 'TX',
    latitude: '32.270586',
    longitude: '-97.905031',
    timeZoneId: 'America/Chicago',
  },
  '76468': {
    zip: '76468',
    city: 'Proctor',
    state: 'TX',
    latitude: '31.985493',
    longitude: '-98.430272',
    timeZoneId: 'America/Chicago',
  },
  '76469': {
    zip: '76469',
    city: 'Putnam',
    state: 'TX',
    latitude: '32.37468',
    longitude: '-99.200657',
    timeZoneId: 'America/Chicago',
  },
  '76470': {
    zip: '76470',
    city: 'Ranger',
    state: 'TX',
    latitude: '32.457305',
    longitude: '-98.688287',
    timeZoneId: 'America/Chicago',
  },
  '76471': {
    zip: '76471',
    city: 'Rising Star',
    state: 'TX',
    latitude: '32.0964',
    longitude: '-98.965407',
    timeZoneId: 'America/Chicago',
  },
  '76472': {
    zip: '76472',
    city: 'Santo',
    state: 'TX',
    latitude: '32.588111',
    longitude: '-98.183089',
    timeZoneId: 'America/Chicago',
  },
  '76474': {
    zip: '76474',
    city: 'Sidney',
    state: 'TX',
    latitude: '31.930933',
    longitude: '-98.77363',
    timeZoneId: 'America/Chicago',
  },
  '76475': {
    zip: '76475',
    city: 'Strawn',
    state: 'TX',
    latitude: '32.556935',
    longitude: '-98.497761',
    timeZoneId: 'America/Chicago',
  },
  '76476': {
    zip: '76476',
    city: 'Tolar',
    state: 'TX',
    latitude: '32.407909',
    longitude: '-97.838613',
    timeZoneId: 'America/Chicago',
  },
  '76481': {
    zip: '76481',
    city: 'South Bend',
    state: 'TX',
    latitude: '32.960083',
    longitude: '-98.76029',
    timeZoneId: 'America/Chicago',
  },
  '76483': {
    zip: '76483',
    city: 'Throckmorton',
    state: 'TX',
    latitude: '33.186968',
    longitude: '-99.27672',
    timeZoneId: 'America/Chicago',
  },
  '76484': {
    zip: '76484',
    city: 'Palo Pinto',
    state: 'TX',
    latitude: '32.749548',
    longitude: '-98.288689',
    timeZoneId: 'America/Chicago',
  },
  '76485': {
    zip: '76485',
    city: 'Peaster',
    state: 'TX',
    latitude: '32.871991',
    longitude: '-97.869134',
    timeZoneId: 'America/Chicago',
  },
  '76486': {
    zip: '76486',
    city: 'Perrin',
    state: 'TX',
    latitude: '33.081552',
    longitude: '-98.052484',
    timeZoneId: 'America/Chicago',
  },
  '76487': {
    zip: '76487',
    city: 'Poolville',
    state: 'TX',
    latitude: '32.969424',
    longitude: '-97.882911',
    timeZoneId: 'America/Chicago',
  },
  '76490': {
    zip: '76490',
    city: 'Whitt',
    state: 'TX',
    latitude: '32.968194',
    longitude: '-98.022376',
    timeZoneId: 'America/Chicago',
  },
  '76491': {
    zip: '76491',
    city: 'Woodson',
    state: 'TX',
    latitude: '33.01523',
    longitude: '-99.05598',
    timeZoneId: 'America/Chicago',
  },
  '76501': {
    zip: '76501',
    city: 'Temple',
    state: 'TX',
    latitude: '31.0854',
    longitude: '-97.30968',
    timeZoneId: 'America/Chicago',
  },
  '76502': {
    zip: '76502',
    city: 'Temple',
    state: 'TX',
    latitude: '31.090167',
    longitude: '-97.379055',
    timeZoneId: 'America/Chicago',
  },
  '76503': {
    zip: '76503',
    city: 'Temple',
    state: 'TX',
    latitude: '31.155172',
    longitude: '-97.441303',
    timeZoneId: 'America/Chicago',
  },
  '76504': {
    zip: '76504',
    city: 'Temple',
    state: 'TX',
    latitude: '31.093709',
    longitude: '-97.369617',
    timeZoneId: 'America/Chicago',
  },
  '76508': {
    zip: '76508',
    city: 'Temple',
    state: 'TX',
    latitude: '31.092892',
    longitude: '-97.364817',
    timeZoneId: 'America/Chicago',
  },
  '76511': {
    zip: '76511',
    city: 'Bartlett',
    state: 'TX',
    latitude: '30.797024',
    longitude: '-97.428615',
    timeZoneId: 'America/Chicago',
  },
  '76513': {
    zip: '76513',
    city: 'Belton',
    state: 'TX',
    latitude: '31.097391',
    longitude: '-97.467293',
    timeZoneId: 'America/Chicago',
  },
  '76518': {
    zip: '76518',
    city: 'Buckholts',
    state: 'TX',
    latitude: '30.894745',
    longitude: '-97.123059',
    timeZoneId: 'America/Chicago',
  },
  '76519': {
    zip: '76519',
    city: 'Burlington',
    state: 'TX',
    latitude: '30.95998',
    longitude: '-96.867177',
    timeZoneId: 'America/Chicago',
  },
  '76520': {
    zip: '76520',
    city: 'Cameron',
    state: 'TX',
    latitude: '30.851242',
    longitude: '-96.976377',
    timeZoneId: 'America/Chicago',
  },
  '76522': {
    zip: '76522',
    city: 'Copperas Cove',
    state: 'TX',
    latitude: '31.13838',
    longitude: '-97.93422',
    timeZoneId: 'America/Chicago',
  },
  '76523': {
    zip: '76523',
    city: 'Davilla',
    state: 'TX',
    latitude: '30.766149',
    longitude: '-97.183525',
    timeZoneId: 'America/Chicago',
  },
  '76524': {
    zip: '76524',
    city: 'Eddy',
    state: 'TX',
    latitude: '31.33572',
    longitude: '-97.27782',
    timeZoneId: 'America/Chicago',
  },
  '76525': {
    zip: '76525',
    city: 'Evant',
    state: 'TX',
    latitude: '31.409762',
    longitude: '-98.104299',
    timeZoneId: 'America/Chicago',
  },
  '76526': {
    zip: '76526',
    city: 'Flat',
    state: 'TX',
    latitude: '31.31204',
    longitude: '-97.572218',
    timeZoneId: 'America/Chicago',
  },
  '76527': {
    zip: '76527',
    city: 'Florence',
    state: 'TX',
    latitude: '30.838651',
    longitude: '-97.790208',
    timeZoneId: 'America/Chicago',
  },
  '76528': {
    zip: '76528',
    city: 'Gatesville',
    state: 'TX',
    latitude: '31.432575',
    longitude: '-97.740289',
    timeZoneId: 'America/Chicago',
  },
  '76530': {
    zip: '76530',
    city: 'Granger',
    state: 'TX',
    latitude: '30.73008',
    longitude: '-97.43598',
    timeZoneId: 'America/Chicago',
  },
  '76531': {
    zip: '76531',
    city: 'Hamilton',
    state: 'TX',
    latitude: '31.66424',
    longitude: '-98.41357',
    timeZoneId: 'America/Chicago',
  },
  '76533': {
    zip: '76533',
    city: 'Heidenheimer',
    state: 'TX',
    latitude: '31.080372',
    longitude: '-97.323755',
    timeZoneId: 'America/Chicago',
  },
  '76534': {
    zip: '76534',
    city: 'Holland',
    state: 'TX',
    latitude: '30.877288',
    longitude: '-97.404648',
    timeZoneId: 'America/Chicago',
  },
  '76537': {
    zip: '76537',
    city: 'Jarrell',
    state: 'TX',
    latitude: '30.78888',
    longitude: '-97.60602',
    timeZoneId: 'America/Chicago',
  },
  '76538': {
    zip: '76538',
    city: 'Jonesboro',
    state: 'TX',
    latitude: '31.59824',
    longitude: '-97.759127',
    timeZoneId: 'America/Chicago',
  },
  '76539': {
    zip: '76539',
    city: 'Kempner',
    state: 'TX',
    latitude: '31.08003',
    longitude: '-98.000076',
    timeZoneId: 'America/Chicago',
  },
  '76540': {
    zip: '76540',
    city: 'Killeen',
    state: 'TX',
    latitude: '31.121623',
    longitude: '-97.720447',
    timeZoneId: 'America/Chicago',
  },
  '76541': {
    zip: '76541',
    city: 'Killeen',
    state: 'TX',
    latitude: '31.114961',
    longitude: '-97.723782',
    timeZoneId: 'America/Chicago',
  },
  '76542': {
    zip: '76542',
    city: 'Killeen',
    state: 'TX',
    latitude: '31.04652',
    longitude: '-97.7238',
    timeZoneId: 'America/Chicago',
  },
  '76543': {
    zip: '76543',
    city: 'Killeen',
    state: 'TX',
    latitude: '31.08966',
    longitude: '-97.64226',
    timeZoneId: 'America/Chicago',
  },
  '76544': {
    zip: '76544',
    city: 'Fort Hood',
    state: 'TX',
    latitude: '31.16718',
    longitude: '-97.7727',
    timeZoneId: 'America/Chicago',
  },
  '76547': {
    zip: '76547',
    city: 'Killeen',
    state: 'TX',
    latitude: '31.118935',
    longitude: '-97.726595',
    timeZoneId: 'America/Chicago',
  },
  '76548': {
    zip: '76548',
    city: 'Harker Heights',
    state: 'TX',
    latitude: '31.116944',
    longitude: '-97.7275',
    timeZoneId: 'America/Chicago',
  },
  '76549': {
    zip: '76549',
    city: 'Killeen',
    state: 'TX',
    latitude: '31.119841',
    longitude: '-97.72972',
    timeZoneId: 'America/Chicago',
  },
  '76550': {
    zip: '76550',
    city: 'Lampasas',
    state: 'TX',
    latitude: '31.066265',
    longitude: '-98.180696',
    timeZoneId: 'America/Chicago',
  },
  '76554': {
    zip: '76554',
    city: 'Little River Academy',
    state: 'TX',
    latitude: '30.98862',
    longitude: '-97.36578',
    timeZoneId: 'America/Chicago',
  },
  '76556': {
    zip: '76556',
    city: 'Milano',
    state: 'TX',
    latitude: '30.73578',
    longitude: '-96.807',
    timeZoneId: 'America/Chicago',
  },
  '76557': {
    zip: '76557',
    city: 'Moody',
    state: 'TX',
    latitude: '31.26978',
    longitude: '-97.37952',
    timeZoneId: 'America/Chicago',
  },
  '76558': {
    zip: '76558',
    city: 'Mound',
    state: 'TX',
    latitude: '31.307996',
    longitude: '-97.569666',
    timeZoneId: 'America/Chicago',
  },
  '76559': {
    zip: '76559',
    city: 'Nolanville',
    state: 'TX',
    latitude: '31.077418',
    longitude: '-97.601807',
    timeZoneId: 'America/Chicago',
  },
  '76561': {
    zip: '76561',
    city: 'Oglesby',
    state: 'TX',
    latitude: '31.453052',
    longitude: '-97.567079',
    timeZoneId: 'America/Chicago',
  },
  '76564': {
    zip: '76564',
    city: 'Pendleton',
    state: 'TX',
    latitude: '31.156371',
    longitude: '-97.255613',
    timeZoneId: 'America/Chicago',
  },
  '76565': {
    zip: '76565',
    city: 'Pottsville',
    state: 'TX',
    latitude: '31.685832',
    longitude: '-98.358306',
    timeZoneId: 'America/Chicago',
  },
  '76566': {
    zip: '76566',
    city: 'Purmela',
    state: 'TX',
    latitude: '31.466689',
    longitude: '-97.904546',
    timeZoneId: 'America/Chicago',
  },
  '76567': {
    zip: '76567',
    city: 'Rockdale',
    state: 'TX',
    latitude: '30.657577',
    longitude: '-97.009947',
    timeZoneId: 'America/Chicago',
  },
  '76569': {
    zip: '76569',
    city: 'Rogers',
    state: 'TX',
    latitude: '30.92633',
    longitude: '-97.230983',
    timeZoneId: 'America/Chicago',
  },
  '76570': {
    zip: '76570',
    city: 'Rosebud',
    state: 'TX',
    latitude: '31.073808',
    longitude: '-96.972971',
    timeZoneId: 'America/Chicago',
  },
  '76571': {
    zip: '76571',
    city: 'Salado',
    state: 'TX',
    latitude: '30.944482',
    longitude: '-97.516912',
    timeZoneId: 'America/Chicago',
  },
  '76573': {
    zip: '76573',
    city: 'Schwertner',
    state: 'TX',
    latitude: '30.722403',
    longitude: '-97.434814',
    timeZoneId: 'America/Chicago',
  },
  '76574': {
    zip: '76574',
    city: 'Taylor',
    state: 'TX',
    latitude: '30.575729',
    longitude: '-97.415968',
    timeZoneId: 'America/Chicago',
  },
  '76577': {
    zip: '76577',
    city: 'Thorndale',
    state: 'TX',
    latitude: '30.60744',
    longitude: '-97.17672',
    timeZoneId: 'America/Chicago',
  },
  '76578': {
    zip: '76578',
    city: 'Thrall',
    state: 'TX',
    latitude: '30.588909',
    longitude: '-97.293903',
    timeZoneId: 'America/Chicago',
  },
  '76579': {
    zip: '76579',
    city: 'Troy',
    state: 'TX',
    latitude: '31.204456',
    longitude: '-97.308221',
    timeZoneId: 'America/Chicago',
  },
  '76596': {
    zip: '76596',
    city: 'Gatesville',
    state: 'TX',
    latitude: '31.489084',
    longitude: '-97.737219',
    timeZoneId: 'America/Chicago',
  },
  '76597': {
    zip: '76597',
    city: 'Gatesville',
    state: 'TX',
    latitude: '31.434052',
    longitude: '-97.746657',
    timeZoneId: 'America/Chicago',
  },
  '76598': {
    zip: '76598',
    city: 'Gatesville',
    state: 'TX',
    latitude: '31.445596',
    longitude: '-97.743272',
    timeZoneId: 'America/Chicago',
  },
  '76599': {
    zip: '76599',
    city: 'Gatesville',
    state: 'TX',
    latitude: '31.47463',
    longitude: '-97.739824',
    timeZoneId: 'America/Chicago',
  },
  '76621': {
    zip: '76621',
    city: 'Abbott',
    state: 'TX',
    latitude: '31.90008',
    longitude: '-97.07418',
    timeZoneId: 'America/Chicago',
  },
  '76622': {
    zip: '76622',
    city: 'Aquilla',
    state: 'TX',
    latitude: '31.868513',
    longitude: '-97.206051',
    timeZoneId: 'America/Chicago',
  },
  '76623': {
    zip: '76623',
    city: 'Avalon',
    state: 'TX',
    latitude: '32.309699',
    longitude: '-96.795874',
    timeZoneId: 'America/Chicago',
  },
  '76624': {
    zip: '76624',
    city: 'Axtell',
    state: 'TX',
    latitude: '31.6731',
    longitude: '-96.98178',
    timeZoneId: 'America/Chicago',
  },
  '76626': {
    zip: '76626',
    city: 'Blooming Grove',
    state: 'TX',
    latitude: '32.093811',
    longitude: '-96.720007',
    timeZoneId: 'America/Chicago',
  },
  '76627': {
    zip: '76627',
    city: 'Blum',
    state: 'TX',
    latitude: '32.105518',
    longitude: '-97.378381',
    timeZoneId: 'America/Chicago',
  },
  '76628': {
    zip: '76628',
    city: 'Brandon',
    state: 'TX',
    latitude: '32.047616',
    longitude: '-97.103411',
    timeZoneId: 'America/Chicago',
  },
  '76629': {
    zip: '76629',
    city: 'Bremond',
    state: 'TX',
    latitude: '31.1493',
    longitude: '-96.67632',
    timeZoneId: 'America/Chicago',
  },
  '76630': {
    zip: '76630',
    city: 'Bruceville',
    state: 'TX',
    latitude: '31.331159',
    longitude: '-97.249823',
    timeZoneId: 'America/Chicago',
  },
  '76631': {
    zip: '76631',
    city: 'Bynum',
    state: 'TX',
    latitude: '31.985653',
    longitude: '-96.968659',
    timeZoneId: 'America/Chicago',
  },
  '76632': {
    zip: '76632',
    city: 'Chilton',
    state: 'TX',
    latitude: '31.300172',
    longitude: '-97.10983',
    timeZoneId: 'America/Chicago',
  },
  '76633': {
    zip: '76633',
    city: 'China Spring',
    state: 'TX',
    latitude: '31.642621',
    longitude: '-97.294158',
    timeZoneId: 'America/Chicago',
  },
  '76634': {
    zip: '76634',
    city: 'Clifton',
    state: 'TX',
    latitude: '31.8117',
    longitude: '-97.49082',
    timeZoneId: 'America/Chicago',
  },
  '76635': {
    zip: '76635',
    city: 'Coolidge',
    state: 'TX',
    latitude: '31.7427',
    longitude: '-96.67242',
    timeZoneId: 'America/Chicago',
  },
  '76636': {
    zip: '76636',
    city: 'Covington',
    state: 'TX',
    latitude: '32.159516',
    longitude: '-97.250808',
    timeZoneId: 'America/Chicago',
  },
  '76637': {
    zip: '76637',
    city: 'Cranfills Gap',
    state: 'TX',
    latitude: '31.78287',
    longitude: '-97.766797',
    timeZoneId: 'America/Chicago',
  },
  '76638': {
    zip: '76638',
    city: 'Crawford',
    state: 'TX',
    latitude: '31.57164',
    longitude: '-97.38222',
    timeZoneId: 'America/Chicago',
  },
  '76639': {
    zip: '76639',
    city: 'Dawson',
    state: 'TX',
    latitude: '31.89312',
    longitude: '-96.7041',
    timeZoneId: 'America/Chicago',
  },
  '76640': {
    zip: '76640',
    city: 'Elm Mott',
    state: 'TX',
    latitude: '31.672519',
    longitude: '-97.09784',
    timeZoneId: 'America/Chicago',
  },
  '76641': {
    zip: '76641',
    city: 'Frost',
    state: 'TX',
    latitude: '32.029561',
    longitude: '-96.778412',
    timeZoneId: 'America/Chicago',
  },
  '76642': {
    zip: '76642',
    city: 'Groesbeck',
    state: 'TX',
    latitude: '31.5462',
    longitude: '-96.52908',
    timeZoneId: 'America/Chicago',
  },
  '76643': {
    zip: '76643',
    city: 'Hewitt',
    state: 'TX',
    latitude: '31.460339',
    longitude: '-97.196015',
    timeZoneId: 'America/Chicago',
  },
  '76644': {
    zip: '76644',
    city: 'Laguna Park',
    state: 'TX',
    latitude: '31.858882',
    longitude: '-97.376822',
    timeZoneId: 'America/Chicago',
  },
  '76645': {
    zip: '76645',
    city: 'Hillsboro',
    state: 'TX',
    latitude: '32.01474',
    longitude: '-97.10886',
    timeZoneId: 'America/Chicago',
  },
  '76648': {
    zip: '76648',
    city: 'Hubbard',
    state: 'TX',
    latitude: '31.846845',
    longitude: '-96.799912',
    timeZoneId: 'America/Chicago',
  },
  '76649': {
    zip: '76649',
    city: 'Iredell',
    state: 'TX',
    latitude: '31.973779',
    longitude: '-97.869839',
    timeZoneId: 'America/Chicago',
  },
  '76650': {
    zip: '76650',
    city: 'Irene',
    state: 'TX',
    latitude: '31.989969',
    longitude: '-96.871536',
    timeZoneId: 'America/Chicago',
  },
  '76651': {
    zip: '76651',
    city: 'Italy',
    state: 'TX',
    latitude: '32.181612',
    longitude: '-96.885798',
    timeZoneId: 'America/Chicago',
  },
  '76652': {
    zip: '76652',
    city: 'Kopperl',
    state: 'TX',
    latitude: '32.121465',
    longitude: '-97.581963',
    timeZoneId: 'America/Chicago',
  },
  '76653': {
    zip: '76653',
    city: 'Kosse',
    state: 'TX',
    latitude: '31.31478',
    longitude: '-96.621',
    timeZoneId: 'America/Chicago',
  },
  '76654': {
    zip: '76654',
    city: 'Leroy',
    state: 'TX',
    latitude: '31.655482',
    longitude: '-96.979211',
    timeZoneId: 'America/Chicago',
  },
  '76655': {
    zip: '76655',
    city: 'Lorena',
    state: 'TX',
    latitude: '31.42212',
    longitude: '-97.23792',
    timeZoneId: 'America/Chicago',
  },
  '76656': {
    zip: '76656',
    city: 'Lott',
    state: 'TX',
    latitude: '31.076389',
    longitude: '-97.109167',
    timeZoneId: 'America/Chicago',
  },
  '76657': {
    zip: '76657',
    city: 'Mc Gregor',
    state: 'TX',
    latitude: '31.438425',
    longitude: '-97.399576',
    timeZoneId: 'America/Chicago',
  },
  '76660': {
    zip: '76660',
    city: 'Malone',
    state: 'TX',
    latitude: '31.934685',
    longitude: '-96.885797',
    timeZoneId: 'America/Chicago',
  },
  '76661': {
    zip: '76661',
    city: 'Marlin',
    state: 'TX',
    latitude: '31.310786',
    longitude: '-96.891028',
    timeZoneId: 'America/Chicago',
  },
  '76664': {
    zip: '76664',
    city: 'Mart',
    state: 'TX',
    latitude: '31.548099',
    longitude: '-96.837534',
    timeZoneId: 'America/Chicago',
  },
  '76665': {
    zip: '76665',
    city: 'Meridian',
    state: 'TX',
    latitude: '31.9239',
    longitude: '-97.656966',
    timeZoneId: 'America/Chicago',
  },
  '76666': {
    zip: '76666',
    city: 'Mertens',
    state: 'TX',
    latitude: '32.024409',
    longitude: '-96.897306',
    timeZoneId: 'America/Chicago',
  },
  '76667': {
    zip: '76667',
    city: 'Mexia',
    state: 'TX',
    latitude: '31.680599',
    longitude: '-96.478818',
    timeZoneId: 'America/Chicago',
  },
  '76670': {
    zip: '76670',
    city: 'Milford',
    state: 'TX',
    latitude: '32.142009',
    longitude: '-96.958075',
    timeZoneId: 'America/Chicago',
  },
  '76671': {
    zip: '76671',
    city: 'Morgan',
    state: 'TX',
    latitude: '32.020833',
    longitude: '-97.493611',
    timeZoneId: 'America/Chicago',
  },
  '76673': {
    zip: '76673',
    city: 'Mount Calm',
    state: 'TX',
    latitude: '31.762263',
    longitude: '-96.911319',
    timeZoneId: 'America/Chicago',
  },
  '76676': {
    zip: '76676',
    city: 'Penelope',
    state: 'TX',
    latitude: '31.855369',
    longitude: '-96.943156',
    timeZoneId: 'America/Chicago',
  },
  '76678': {
    zip: '76678',
    city: 'Prairie Hill',
    state: 'TX',
    latitude: '31.651957',
    longitude: '-96.786893',
    timeZoneId: 'America/Chicago',
  },
  '76679': {
    zip: '76679',
    city: 'Purdon',
    state: 'TX',
    latitude: '31.949322',
    longitude: '-96.573146',
    timeZoneId: 'America/Chicago',
  },
  '76680': {
    zip: '76680',
    city: 'Reagan',
    state: 'TX',
    latitude: '31.238487',
    longitude: '-96.754011',
    timeZoneId: 'America/Chicago',
  },
  '76681': {
    zip: '76681',
    city: 'Richland',
    state: 'TX',
    latitude: '31.879527',
    longitude: '-96.446943',
    timeZoneId: 'America/Chicago',
  },
  '76682': {
    zip: '76682',
    city: 'Riesel',
    state: 'TX',
    latitude: '31.473889',
    longitude: '-96.920114',
    timeZoneId: 'America/Chicago',
  },
  '76684': {
    zip: '76684',
    city: 'Ross',
    state: 'TX',
    latitude: '31.669367',
    longitude: '-97.100626',
    timeZoneId: 'America/Chicago',
  },
  '76685': {
    zip: '76685',
    city: 'Satin',
    state: 'TX',
    latitude: '31.29971',
    longitude: '-97.11234',
    timeZoneId: 'America/Chicago',
  },
  '76686': {
    zip: '76686',
    city: 'Tehuacana',
    state: 'TX',
    latitude: '31.687428',
    longitude: '-96.510048',
    timeZoneId: 'America/Chicago',
  },
  '76687': {
    zip: '76687',
    city: 'Thornton',
    state: 'TX',
    latitude: '31.402692',
    longitude: '-96.489597',
    timeZoneId: 'America/Chicago',
  },
  '76689': {
    zip: '76689',
    city: 'Valley Mills',
    state: 'TX',
    latitude: '31.67412',
    longitude: '-97.5093',
    timeZoneId: 'America/Chicago',
  },
  '76690': {
    zip: '76690',
    city: 'Walnut Springs',
    state: 'TX',
    latitude: '32.0649',
    longitude: '-97.76358',
    timeZoneId: 'America/Chicago',
  },
  '76691': {
    zip: '76691',
    city: 'West',
    state: 'TX',
    latitude: '31.77546',
    longitude: '-97.10406',
    timeZoneId: 'America/Chicago',
  },
  '76692': {
    zip: '76692',
    city: 'Whitney',
    state: 'TX',
    latitude: '31.950379',
    longitude: '-97.316515',
    timeZoneId: 'America/Chicago',
  },
  '76693': {
    zip: '76693',
    city: 'Wortham',
    state: 'TX',
    latitude: '31.784137',
    longitude: '-96.463313',
    timeZoneId: 'America/Chicago',
  },
  '76701': {
    zip: '76701',
    city: 'Waco',
    state: 'TX',
    latitude: '31.551963',
    longitude: '-97.136548',
    timeZoneId: 'America/Chicago',
  },
  '76702': {
    zip: '76702',
    city: 'Waco',
    state: 'TX',
    latitude: '31.544973',
    longitude: '-97.142627',
    timeZoneId: 'America/Chicago',
  },
  '76703': {
    zip: '76703',
    city: 'Waco',
    state: 'TX',
    latitude: '31.558118',
    longitude: '-97.127509',
    timeZoneId: 'America/Chicago',
  },
  '76704': {
    zip: '76704',
    city: 'Waco',
    state: 'TX',
    latitude: '31.58208',
    longitude: '-97.125',
    timeZoneId: 'America/Chicago',
  },
  '76705': {
    zip: '76705',
    city: 'Waco',
    state: 'TX',
    latitude: '31.57938',
    longitude: '-97.13568',
    timeZoneId: 'America/Chicago',
  },
  '76706': {
    zip: '76706',
    city: 'Waco',
    state: 'TX',
    latitude: '31.48032',
    longitude: '-97.11588',
    timeZoneId: 'America/Chicago',
  },
  '76707': {
    zip: '76707',
    city: 'Waco',
    state: 'TX',
    latitude: '31.554463',
    longitude: '-97.158932',
    timeZoneId: 'America/Chicago',
  },
  '76708': {
    zip: '76708',
    city: 'Waco',
    state: 'TX',
    latitude: '31.6095',
    longitude: '-97.21344',
    timeZoneId: 'America/Chicago',
  },
  '76710': {
    zip: '76710',
    city: 'Waco',
    state: 'TX',
    latitude: '31.54176',
    longitude: '-97.1847',
    timeZoneId: 'America/Chicago',
  },
  '76711': {
    zip: '76711',
    city: 'Waco',
    state: 'TX',
    latitude: '31.512973',
    longitude: '-97.16358',
    timeZoneId: 'America/Chicago',
  },
  '76712': {
    zip: '76712',
    city: 'Woodway',
    state: 'TX',
    latitude: '31.491114',
    longitude: '-97.225849',
    timeZoneId: 'America/Chicago',
  },
  '76714': {
    zip: '76714',
    city: 'Waco',
    state: 'TX',
    latitude: '31.528528',
    longitude: '-97.19454',
    timeZoneId: 'America/Chicago',
  },
  '76715': {
    zip: '76715',
    city: 'Waco',
    state: 'TX',
    latitude: '31.603053',
    longitude: '-97.081317',
    timeZoneId: 'America/Chicago',
  },
  '76716': {
    zip: '76716',
    city: 'Waco',
    state: 'TX',
    latitude: '31.456986',
    longitude: '-97.096657',
    timeZoneId: 'America/Chicago',
  },
  '76797': {
    zip: '76797',
    city: 'Waco',
    state: 'TX',
    latitude: '31.532449',
    longitude: '-97.190218',
    timeZoneId: 'America/Chicago',
  },
  '76798': {
    zip: '76798',
    city: 'Waco',
    state: 'TX',
    latitude: '31.45549',
    longitude: '-97.098132',
    timeZoneId: 'America/Chicago',
  },
  '76799': {
    zip: '76799',
    city: 'Waco',
    state: 'TX',
    latitude: '31.531269',
    longitude: '-97.19223',
    timeZoneId: 'America/Chicago',
  },
  '76801': {
    zip: '76801',
    city: 'Brownwood',
    state: 'TX',
    latitude: '31.6887',
    longitude: '-98.96772',
    timeZoneId: 'America/Chicago',
  },
  '76802': {
    zip: '76802',
    city: 'Early',
    state: 'TX',
    latitude: '31.753994',
    longitude: '-98.924325',
    timeZoneId: 'America/Chicago',
  },
  '76803': {
    zip: '76803',
    city: 'Early',
    state: 'TX',
    latitude: '31.717112',
    longitude: '-98.976776',
    timeZoneId: 'America/Chicago',
  },
  '76804': {
    zip: '76804',
    city: 'Brownwood',
    state: 'TX',
    latitude: '31.605035',
    longitude: '-99.017479',
    timeZoneId: 'America/Chicago',
  },
  '76820': {
    zip: '76820',
    city: 'Art',
    state: 'TX',
    latitude: '30.773207',
    longitude: '-99.068384',
    timeZoneId: 'America/Chicago',
  },
  '76821': {
    zip: '76821',
    city: 'Ballinger',
    state: 'TX',
    latitude: '31.73412',
    longitude: '-99.9615',
    timeZoneId: 'America/Chicago',
  },
  '76823': {
    zip: '76823',
    city: 'Bangs',
    state: 'TX',
    latitude: '31.715094',
    longitude: '-99.126441',
    timeZoneId: 'America/Chicago',
  },
  '76824': {
    zip: '76824',
    city: 'Bend',
    state: 'TX',
    latitude: '31.083168',
    longitude: '-98.500007',
    timeZoneId: 'America/Chicago',
  },
  '76825': {
    zip: '76825',
    city: 'Brady',
    state: 'TX',
    latitude: '31.39071',
    longitude: '-99.38431',
    timeZoneId: 'America/Chicago',
  },
  '76827': {
    zip: '76827',
    city: 'Brookesmith',
    state: 'TX',
    latitude: '31.519708',
    longitude: '-99.137923',
    timeZoneId: 'America/Chicago',
  },
  '76828': {
    zip: '76828',
    city: 'Burkett',
    state: 'TX',
    latitude: '32.008116',
    longitude: '-99.31107',
    timeZoneId: 'America/Chicago',
  },
  '76831': {
    zip: '76831',
    city: 'Castell',
    state: 'TX',
    latitude: '30.694716',
    longitude: '-98.923327',
    timeZoneId: 'America/Chicago',
  },
  '76832': {
    zip: '76832',
    city: 'Cherokee',
    state: 'TX',
    latitude: '30.976993',
    longitude: '-98.713025',
    timeZoneId: 'America/Chicago',
  },
  '76834': {
    zip: '76834',
    city: 'Coleman',
    state: 'TX',
    latitude: '31.8336',
    longitude: '-99.4155',
    timeZoneId: 'America/Chicago',
  },
  '76836': {
    zip: '76836',
    city: 'Doole',
    state: 'TX',
    latitude: '31.415361',
    longitude: '-99.551339',
    timeZoneId: 'America/Chicago',
  },
  '76837': {
    zip: '76837',
    city: 'Eden',
    state: 'TX',
    latitude: '31.213843',
    longitude: '-99.833002',
    timeZoneId: 'America/Chicago',
  },
  '76841': {
    zip: '76841',
    city: 'Fort Mc Kavett',
    state: 'TX',
    latitude: '30.930384',
    longitude: '-99.828136',
    timeZoneId: 'America/Chicago',
  },
  '76842': {
    zip: '76842',
    city: 'Fredonia',
    state: 'TX',
    latitude: '30.914652',
    longitude: '-99.13844',
    timeZoneId: 'America/Chicago',
  },
  '76844': {
    zip: '76844',
    city: 'Goldthwaite',
    state: 'TX',
    latitude: '31.4388',
    longitude: '-98.57514',
    timeZoneId: 'America/Chicago',
  },
  '76845': {
    zip: '76845',
    city: 'Gouldbusk',
    state: 'TX',
    latitude: '31.513687',
    longitude: '-99.531879',
    timeZoneId: 'America/Chicago',
  },
  '76848': {
    zip: '76848',
    city: 'Hext',
    state: 'TX',
    latitude: '30.882662',
    longitude: '-99.543216',
    timeZoneId: 'America/Chicago',
  },
  '76849': {
    zip: '76849',
    city: 'Junction',
    state: 'TX',
    latitude: '30.46284',
    longitude: '-99.71004',
    timeZoneId: 'America/Chicago',
  },
  '76852': {
    zip: '76852',
    city: 'Lohn',
    state: 'TX',
    latitude: '31.340312',
    longitude: '-99.380947',
    timeZoneId: 'America/Chicago',
  },
  '76853': {
    zip: '76853',
    city: 'Lometa',
    state: 'TX',
    latitude: '31.20997',
    longitude: '-98.398425',
    timeZoneId: 'America/Chicago',
  },
  '76854': {
    zip: '76854',
    city: 'London',
    state: 'TX',
    latitude: '30.624246',
    longitude: '-99.65854',
    timeZoneId: 'America/Chicago',
  },
  '76855': {
    zip: '76855',
    city: 'Lowake',
    state: 'TX',
    latitude: '31.44989',
    longitude: '-100.059135',
    timeZoneId: 'America/Chicago',
  },
  '76856': {
    zip: '76856',
    city: 'Mason',
    state: 'TX',
    latitude: '30.7371',
    longitude: '-99.2274',
    timeZoneId: 'America/Chicago',
  },
  '76857': {
    zip: '76857',
    city: 'May',
    state: 'TX',
    latitude: '31.977693',
    longitude: '-98.996483',
    timeZoneId: 'America/Chicago',
  },
  '76858': {
    zip: '76858',
    city: 'Melvin',
    state: 'TX',
    latitude: '31.140851',
    longitude: '-99.521319',
    timeZoneId: 'America/Chicago',
  },
  '76859': {
    zip: '76859',
    city: 'Menard',
    state: 'TX',
    latitude: '30.908626',
    longitude: '-99.787372',
    timeZoneId: 'America/Chicago',
  },
  '76861': {
    zip: '76861',
    city: 'Miles',
    state: 'TX',
    latitude: '31.595322',
    longitude: '-100.184718',
    timeZoneId: 'America/Chicago',
  },
  '76862': {
    zip: '76862',
    city: 'Millersview',
    state: 'TX',
    latitude: '31.45021',
    longitude: '-99.708549',
    timeZoneId: 'America/Chicago',
  },
  '76864': {
    zip: '76864',
    city: 'Mullin',
    state: 'TX',
    latitude: '31.572787',
    longitude: '-98.700643',
    timeZoneId: 'America/Chicago',
  },
  '76865': {
    zip: '76865',
    city: 'Norton',
    state: 'TX',
    latitude: '31.888355',
    longitude: '-100.132829',
    timeZoneId: 'America/Chicago',
  },
  '76866': {
    zip: '76866',
    city: 'Paint Rock',
    state: 'TX',
    latitude: '31.499721',
    longitude: '-99.896856',
    timeZoneId: 'America/Chicago',
  },
  '76869': {
    zip: '76869',
    city: 'Pontotoc',
    state: 'TX',
    latitude: '30.875755',
    longitude: '-99.020982',
    timeZoneId: 'America/Chicago',
  },
  '76870': {
    zip: '76870',
    city: 'Priddy',
    state: 'TX',
    latitude: '31.691993',
    longitude: '-98.504331',
    timeZoneId: 'America/Chicago',
  },
  '76871': {
    zip: '76871',
    city: 'Richland Springs',
    state: 'TX',
    latitude: '31.304072',
    longitude: '-98.817416',
    timeZoneId: 'America/Chicago',
  },
  '76872': {
    zip: '76872',
    city: 'Rochelle',
    state: 'TX',
    latitude: '31.328255',
    longitude: '-99.142167',
    timeZoneId: 'America/Chicago',
  },
  '76873': {
    zip: '76873',
    city: 'Rockwood',
    state: 'TX',
    latitude: '31.488285',
    longitude: '-99.372601',
    timeZoneId: 'America/Chicago',
  },
  '76874': {
    zip: '76874',
    city: 'Roosevelt',
    state: 'TX',
    latitude: '30.547687',
    longitude: '-99.958726',
    timeZoneId: 'America/Chicago',
  },
  '76875': {
    zip: '76875',
    city: 'Rowena',
    state: 'TX',
    latitude: '31.636478',
    longitude: '-99.966581',
    timeZoneId: 'America/Chicago',
  },
  '76877': {
    zip: '76877',
    city: 'San Saba',
    state: 'TX',
    latitude: '31.19561',
    longitude: '-98.725363',
    timeZoneId: 'America/Chicago',
  },
  '76878': {
    zip: '76878',
    city: 'Santa Anna',
    state: 'TX',
    latitude: '31.49038',
    longitude: '-99.30694',
    timeZoneId: 'America/Chicago',
  },
  '76880': {
    zip: '76880',
    city: 'Star',
    state: 'TX',
    latitude: '31.479071',
    longitude: '-98.414538',
    timeZoneId: 'America/Chicago',
  },
  '76882': {
    zip: '76882',
    city: 'Talpa',
    state: 'TX',
    latitude: '31.779442',
    longitude: '-99.663049',
    timeZoneId: 'America/Chicago',
  },
  '76883': {
    zip: '76883',
    city: 'Telegraph',
    state: 'TX',
    latitude: '30.336485',
    longitude: '-99.983284',
    timeZoneId: 'America/Chicago',
  },
  '76884': {
    zip: '76884',
    city: 'Valera',
    state: 'TX',
    latitude: '31.772003',
    longitude: '-99.543672',
    timeZoneId: 'America/Chicago',
  },
  '76885': {
    zip: '76885',
    city: 'Valley Spring',
    state: 'TX',
    latitude: '30.838374',
    longitude: '-98.843426',
    timeZoneId: 'America/Chicago',
  },
  '76886': {
    zip: '76886',
    city: 'Veribest',
    state: 'TX',
    latitude: '31.352852',
    longitude: '-100.495041',
    timeZoneId: 'America/Chicago',
  },
  '76887': {
    zip: '76887',
    city: 'Voca',
    state: 'TX',
    latitude: '30.984719',
    longitude: '-99.147972',
    timeZoneId: 'America/Chicago',
  },
  '76888': {
    zip: '76888',
    city: 'Voss',
    state: 'TX',
    latitude: '31.567778',
    longitude: '-99.672778',
    timeZoneId: 'America/Chicago',
  },
  '76890': {
    zip: '76890',
    city: 'Zephyr',
    state: 'TX',
    latitude: '31.654972',
    longitude: '-98.832717',
    timeZoneId: 'America/Chicago',
  },
  '76901': {
    zip: '76901',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.465353',
    longitude: '-100.493525',
    timeZoneId: 'America/Chicago',
  },
  '76902': {
    zip: '76902',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.453553',
    longitude: '-100.424771',
    timeZoneId: 'America/Chicago',
  },
  '76903': {
    zip: '76903',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.467826',
    longitude: '-100.436224',
    timeZoneId: 'America/Chicago',
  },
  '76904': {
    zip: '76904',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.418391',
    longitude: '-100.478565',
    timeZoneId: 'America/Chicago',
  },
  '76905': {
    zip: '76905',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.463731',
    longitude: '-100.392259',
    timeZoneId: 'America/Chicago',
  },
  '76906': {
    zip: '76906',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.46009',
    longitude: '-100.436751',
    timeZoneId: 'America/Chicago',
  },
  '76908': {
    zip: '76908',
    city: 'Goodfellow AFB',
    state: 'TX',
    latitude: '31.387191',
    longitude: '-100.562725',
    timeZoneId: 'America/Chicago',
  },
  '76909': {
    zip: '76909',
    city: 'San Angelo',
    state: 'TX',
    latitude: '31.446944',
    longitude: '-100.450967',
    timeZoneId: 'America/Chicago',
  },
  '76930': {
    zip: '76930',
    city: 'Barnhart',
    state: 'TX',
    latitude: '31.190776',
    longitude: '-101.192246',
    timeZoneId: 'America/Chicago',
  },
  '76932': {
    zip: '76932',
    city: 'Big Lake',
    state: 'TX',
    latitude: '31.212019',
    longitude: '-101.46334',
    timeZoneId: 'America/Chicago',
  },
  '76933': {
    zip: '76933',
    city: 'Bronte',
    state: 'TX',
    latitude: '31.886888',
    longitude: '-100.294029',
    timeZoneId: 'America/Chicago',
  },
  '76934': {
    zip: '76934',
    city: 'Carlsbad',
    state: 'TX',
    latitude: '31.591803',
    longitude: '-100.711528',
    timeZoneId: 'America/Chicago',
  },
  '76935': {
    zip: '76935',
    city: 'Christoval',
    state: 'TX',
    latitude: '31.198516',
    longitude: '-100.534953',
    timeZoneId: 'America/Chicago',
  },
  '76936': {
    zip: '76936',
    city: 'Eldorado',
    state: 'TX',
    latitude: '30.861414',
    longitude: '-100.591893',
    timeZoneId: 'America/Chicago',
  },
  '76937': {
    zip: '76937',
    city: 'Eola',
    state: 'TX',
    latitude: '31.450555',
    longitude: '-100.058489',
    timeZoneId: 'America/Chicago',
  },
  '76939': {
    zip: '76939',
    city: 'Knickerbocker',
    state: 'TX',
    latitude: '31.267109',
    longitude: '-100.624006',
    timeZoneId: 'America/Chicago',
  },
  '76940': {
    zip: '76940',
    city: 'Mereta',
    state: 'TX',
    latitude: '31.487707',
    longitude: '-100.181602',
    timeZoneId: 'America/Chicago',
  },
  '76941': {
    zip: '76941',
    city: 'Mertzon',
    state: 'TX',
    latitude: '31.29168',
    longitude: '-100.86018',
    timeZoneId: 'America/Chicago',
  },
  '76943': {
    zip: '76943',
    city: 'Ozona',
    state: 'TX',
    latitude: '30.71096',
    longitude: '-101.211744',
    timeZoneId: 'America/Chicago',
  },
  '76945': {
    zip: '76945',
    city: 'Robert Lee',
    state: 'TX',
    latitude: '31.896228',
    longitude: '-100.482117',
    timeZoneId: 'America/Chicago',
  },
  '76949': {
    zip: '76949',
    city: 'Silver',
    state: 'TX',
    latitude: '32.041663',
    longitude: '-100.681797',
    timeZoneId: 'America/Chicago',
  },
  '76950': {
    zip: '76950',
    city: 'Sonora',
    state: 'TX',
    latitude: '30.568862',
    longitude: '-100.642173',
    timeZoneId: 'America/Chicago',
  },
  '76951': {
    zip: '76951',
    city: 'Sterling City',
    state: 'TX',
    latitude: '31.836769',
    longitude: '-100.98102',
    timeZoneId: 'America/Chicago',
  },
  '76953': {
    zip: '76953',
    city: 'Tennyson',
    state: 'TX',
    latitude: '31.739252',
    longitude: '-100.288223',
    timeZoneId: 'America/Chicago',
  },
  '76955': {
    zip: '76955',
    city: 'Vancourt',
    state: 'TX',
    latitude: '31.223551',
    longitude: '-100.208379',
    timeZoneId: 'America/Chicago',
  },
  '76957': {
    zip: '76957',
    city: 'Wall',
    state: 'TX',
    latitude: '31.31522',
    longitude: '-100.334746',
    timeZoneId: 'America/Chicago',
  },
  '76958': {
    zip: '76958',
    city: 'Water Valley',
    state: 'TX',
    latitude: '31.66677',
    longitude: '-100.716389',
    timeZoneId: 'America/Chicago',
  },
  '77001': {
    zip: '77001',
    city: 'Houston',
    state: 'TX',
    latitude: '29.720395',
    longitude: '-95.222061',
    timeZoneId: 'America/Chicago',
  },
  '77002': {
    zip: '77002',
    city: 'Houston',
    state: 'TX',
    latitude: '29.757918',
    longitude: '-95.361884',
    timeZoneId: 'America/Chicago',
  },
  '77003': {
    zip: '77003',
    city: 'Houston',
    state: 'TX',
    latitude: '29.749706',
    longitude: '-95.346364',
    timeZoneId: 'America/Chicago',
  },
  '77004': {
    zip: '77004',
    city: 'Houston',
    state: 'TX',
    latitude: '29.728205',
    longitude: '-95.366222',
    timeZoneId: 'America/Chicago',
  },
  '77005': {
    zip: '77005',
    city: 'Houston',
    state: 'TX',
    latitude: '29.718966',
    longitude: '-95.421416',
    timeZoneId: 'America/Chicago',
  },
  '77006': {
    zip: '77006',
    city: 'Houston',
    state: 'TX',
    latitude: '29.742676',
    longitude: '-95.390925',
    timeZoneId: 'America/Chicago',
  },
  '77007': {
    zip: '77007',
    city: 'Houston',
    state: 'TX',
    latitude: '29.772971',
    longitude: '-95.402249',
    timeZoneId: 'America/Chicago',
  },
  '77008': {
    zip: '77008',
    city: 'Houston',
    state: 'TX',
    latitude: '29.796325',
    longitude: '-95.417235',
    timeZoneId: 'America/Chicago',
  },
  '77009': {
    zip: '77009',
    city: 'Houston',
    state: 'TX',
    latitude: '29.796922',
    longitude: '-95.368503',
    timeZoneId: 'America/Chicago',
  },
  '77010': {
    zip: '77010',
    city: 'Houston',
    state: 'TX',
    latitude: '29.754759',
    longitude: '-95.361326',
    timeZoneId: 'America/Chicago',
  },
  '77011': {
    zip: '77011',
    city: 'Houston',
    state: 'TX',
    latitude: '29.742104',
    longitude: '-95.305993',
    timeZoneId: 'America/Chicago',
  },
  '77012': {
    zip: '77012',
    city: 'Houston',
    state: 'TX',
    latitude: '29.718212',
    longitude: '-95.201945',
    timeZoneId: 'America/Chicago',
  },
  '77013': {
    zip: '77013',
    city: 'Houston',
    state: 'TX',
    latitude: '29.795292',
    longitude: '-95.23979',
    timeZoneId: 'America/Chicago',
  },
  '77014': {
    zip: '77014',
    city: 'Houston',
    state: 'TX',
    latitude: '29.981923',
    longitude: '-95.468159',
    timeZoneId: 'America/Chicago',
  },
  '77015': {
    zip: '77015',
    city: 'Houston',
    state: 'TX',
    latitude: '29.770126',
    longitude: '-95.173187',
    timeZoneId: 'America/Chicago',
  },
  '77016': {
    zip: '77016',
    city: 'Houston',
    state: 'TX',
    latitude: '29.856433',
    longitude: '-95.303513',
    timeZoneId: 'America/Chicago',
  },
  '77017': {
    zip: '77017',
    city: 'Houston',
    state: 'TX',
    latitude: '29.681768',
    longitude: '-95.258078',
    timeZoneId: 'America/Chicago',
  },
  '77018': {
    zip: '77018',
    city: 'Houston',
    state: 'TX',
    latitude: '29.827979',
    longitude: '-95.426715',
    timeZoneId: 'America/Chicago',
  },
  '77019': {
    zip: '77019',
    city: 'Houston',
    state: 'TX',
    latitude: '29.754852',
    longitude: '-95.415733',
    timeZoneId: 'America/Chicago',
  },
  '77020': {
    zip: '77020',
    city: 'Houston',
    state: 'TX',
    latitude: '29.772705',
    longitude: '-95.312443',
    timeZoneId: 'America/Chicago',
  },
  '77021': {
    zip: '77021',
    city: 'Houston',
    state: 'TX',
    latitude: '29.698994',
    longitude: '-95.355219',
    timeZoneId: 'America/Chicago',
  },
  '77022': {
    zip: '77022',
    city: 'Houston',
    state: 'TX',
    latitude: '29.829065',
    longitude: '-95.374595',
    timeZoneId: 'America/Chicago',
  },
  '77023': {
    zip: '77023',
    city: 'Houston',
    state: 'TX',
    latitude: '29.722464',
    longitude: '-95.322941',
    timeZoneId: 'America/Chicago',
  },
  '77024': {
    zip: '77024',
    city: 'Houston',
    state: 'TX',
    latitude: '29.76444',
    longitude: '-95.50962',
    timeZoneId: 'America/Chicago',
  },
  '77025': {
    zip: '77025',
    city: 'Houston',
    state: 'TX',
    latitude: '29.690087',
    longitude: '-95.433831',
    timeZoneId: 'America/Chicago',
  },
  '77026': {
    zip: '77026',
    city: 'Houston',
    state: 'TX',
    latitude: '29.791713',
    longitude: '-95.332479',
    timeZoneId: 'America/Chicago',
  },
  '77027': {
    zip: '77027',
    city: 'Houston',
    state: 'TX',
    latitude: '29.738237',
    longitude: '-95.446438',
    timeZoneId: 'America/Chicago',
  },
  '77028': {
    zip: '77028',
    city: 'Houston',
    state: 'TX',
    latitude: '29.826296',
    longitude: '-95.287438',
    timeZoneId: 'America/Chicago',
  },
  '77029': {
    zip: '77029',
    city: 'Houston',
    state: 'TX',
    latitude: '29.767222',
    longitude: '-95.233611',
    timeZoneId: 'America/Chicago',
  },
  '77030': {
    zip: '77030',
    city: 'Houston',
    state: 'TX',
    latitude: '29.70926',
    longitude: '-95.400851',
    timeZoneId: 'America/Chicago',
  },
  '77031': {
    zip: '77031',
    city: 'Houston',
    state: 'TX',
    latitude: '29.655163',
    longitude: '-95.546135',
    timeZoneId: 'America/Chicago',
  },
  '77032': {
    zip: '77032',
    city: 'Houston',
    state: 'TX',
    latitude: '29.940786',
    longitude: '-95.345853',
    timeZoneId: 'America/Chicago',
  },
  '77033': {
    zip: '77033',
    city: 'Houston',
    state: 'TX',
    latitude: '29.663012',
    longitude: '-95.339493',
    timeZoneId: 'America/Chicago',
  },
  '77034': {
    zip: '77034',
    city: 'Houston',
    state: 'TX',
    latitude: '29.612502',
    longitude: '-95.2009',
    timeZoneId: 'America/Chicago',
  },
  '77035': {
    zip: '77035',
    city: 'Houston',
    state: 'TX',
    latitude: '29.651703',
    longitude: '-95.479871',
    timeZoneId: 'America/Chicago',
  },
  '77036': {
    zip: '77036',
    city: 'Houston',
    state: 'TX',
    latitude: '29.701008',
    longitude: '-95.536659',
    timeZoneId: 'America/Chicago',
  },
  '77037': {
    zip: '77037',
    city: 'Houston',
    state: 'TX',
    latitude: '29.888126',
    longitude: '-95.390473',
    timeZoneId: 'America/Chicago',
  },
  '77038': {
    zip: '77038',
    city: 'Houston',
    state: 'TX',
    latitude: '29.917841',
    longitude: '-95.439728',
    timeZoneId: 'America/Chicago',
  },
  '77039': {
    zip: '77039',
    city: 'Houston',
    state: 'TX',
    latitude: '29.909223',
    longitude: '-95.334826',
    timeZoneId: 'America/Chicago',
  },
  '77040': {
    zip: '77040',
    city: 'Houston',
    state: 'TX',
    latitude: '29.89416',
    longitude: '-95.57934',
    timeZoneId: 'America/Chicago',
  },
  '77041': {
    zip: '77041',
    city: 'Houston',
    state: 'TX',
    latitude: '29.857122',
    longitude: '-95.572778',
    timeZoneId: 'America/Chicago',
  },
  '77042': {
    zip: '77042',
    city: 'Houston',
    state: 'TX',
    latitude: '29.746724',
    longitude: '-95.559671',
    timeZoneId: 'America/Chicago',
  },
  '77043': {
    zip: '77043',
    city: 'Houston',
    state: 'TX',
    latitude: '29.804733',
    longitude: '-95.560006',
    timeZoneId: 'America/Chicago',
  },
  '77044': {
    zip: '77044',
    city: 'Houston',
    state: 'TX',
    latitude: '29.899678',
    longitude: '-95.180002',
    timeZoneId: 'America/Chicago',
  },
  '77045': {
    zip: '77045',
    city: 'Houston',
    state: 'TX',
    latitude: '29.632758',
    longitude: '-95.42406',
    timeZoneId: 'America/Chicago',
  },
  '77046': {
    zip: '77046',
    city: 'Houston',
    state: 'TX',
    latitude: '29.73738',
    longitude: '-95.393776',
    timeZoneId: 'America/Chicago',
  },
  '77047': {
    zip: '77047',
    city: 'Houston',
    state: 'TX',
    latitude: '29.604738',
    longitude: '-95.386165',
    timeZoneId: 'America/Chicago',
  },
  '77048': {
    zip: '77048',
    city: 'Houston',
    state: 'TX',
    latitude: '29.620649',
    longitude: '-95.330504',
    timeZoneId: 'America/Chicago',
  },
  '77049': {
    zip: '77049',
    city: 'Houston',
    state: 'TX',
    latitude: '29.836704',
    longitude: '-95.149474',
    timeZoneId: 'America/Chicago',
  },
  '77050': {
    zip: '77050',
    city: 'Houston',
    state: 'TX',
    latitude: '29.899585',
    longitude: '-95.270162',
    timeZoneId: 'America/Chicago',
  },
  '77051': {
    zip: '77051',
    city: 'Houston',
    state: 'TX',
    latitude: '29.651308',
    longitude: '-95.379997',
    timeZoneId: 'America/Chicago',
  },
  '77052': {
    zip: '77052',
    city: 'Houston',
    state: 'TX',
    latitude: '29.756932',
    longitude: '-95.358959',
    timeZoneId: 'America/Chicago',
  },
  '77053': {
    zip: '77053',
    city: 'Houston',
    state: 'TX',
    latitude: '29.59564',
    longitude: '-95.454151',
    timeZoneId: 'America/Chicago',
  },
  '77054': {
    zip: '77054',
    city: 'Houston',
    state: 'TX',
    latitude: '29.682326',
    longitude: '-95.40041',
    timeZoneId: 'America/Chicago',
  },
  '77055': {
    zip: '77055',
    city: 'Houston',
    state: 'TX',
    latitude: '29.797867',
    longitude: '-95.497137',
    timeZoneId: 'America/Chicago',
  },
  '77056': {
    zip: '77056',
    city: 'Houston',
    state: 'TX',
    latitude: '29.748168',
    longitude: '-95.467449',
    timeZoneId: 'America/Chicago',
  },
  '77057': {
    zip: '77057',
    city: 'Houston',
    state: 'TX',
    latitude: '29.745957',
    longitude: '-95.490821',
    timeZoneId: 'America/Chicago',
  },
  '77058': {
    zip: '77058',
    city: 'Houston',
    state: 'TX',
    latitude: '29.553866',
    longitude: '-95.095044',
    timeZoneId: 'America/Chicago',
  },
  '77059': {
    zip: '77059',
    city: 'Houston',
    state: 'TX',
    latitude: '29.608785',
    longitude: '-95.114041',
    timeZoneId: 'America/Chicago',
  },
  '77060': {
    zip: '77060',
    city: 'Houston',
    state: 'TX',
    latitude: '29.926865',
    longitude: '-95.395351',
    timeZoneId: 'America/Chicago',
  },
  '77061': {
    zip: '77061',
    city: 'Houston',
    state: 'TX',
    latitude: '29.659716',
    longitude: '-95.284114',
    timeZoneId: 'America/Chicago',
  },
  '77062': {
    zip: '77062',
    city: 'Houston',
    state: 'TX',
    latitude: '29.577603',
    longitude: '-95.138853',
    timeZoneId: 'America/Chicago',
  },
  '77063': {
    zip: '77063',
    city: 'Houston',
    state: 'TX',
    latitude: '29.73219',
    longitude: '-95.521538',
    timeZoneId: 'America/Chicago',
  },
  '77064': {
    zip: '77064',
    city: 'Houston',
    state: 'TX',
    latitude: '29.917765',
    longitude: '-95.555032',
    timeZoneId: 'America/Chicago',
  },
  '77065': {
    zip: '77065',
    city: 'Houston',
    state: 'TX',
    latitude: '29.930003',
    longitude: '-95.605601',
    timeZoneId: 'America/Chicago',
  },
  '77066': {
    zip: '77066',
    city: 'Houston',
    state: 'TX',
    latitude: '29.958349',
    longitude: '-95.50021',
    timeZoneId: 'America/Chicago',
  },
  '77067': {
    zip: '77067',
    city: 'Houston',
    state: 'TX',
    latitude: '29.954058',
    longitude: '-95.455417',
    timeZoneId: 'America/Chicago',
  },
  '77068': {
    zip: '77068',
    city: 'Houston',
    state: 'TX',
    latitude: '30.002024',
    longitude: '-95.486495',
    timeZoneId: 'America/Chicago',
  },
  '77069': {
    zip: '77069',
    city: 'Houston',
    state: 'TX',
    latitude: '29.985545',
    longitude: '-95.522847',
    timeZoneId: 'America/Chicago',
  },
  '77070': {
    zip: '77070',
    city: 'Houston',
    state: 'TX',
    latitude: '29.972794',
    longitude: '-95.577432',
    timeZoneId: 'America/Chicago',
  },
  '77071': {
    zip: '77071',
    city: 'Houston',
    state: 'TX',
    latitude: '29.645598',
    longitude: '-95.523668',
    timeZoneId: 'America/Chicago',
  },
  '77072': {
    zip: '77072',
    city: 'Houston',
    state: 'TX',
    latitude: '29.697439',
    longitude: '-95.587015',
    timeZoneId: 'America/Chicago',
  },
  '77073': {
    zip: '77073',
    city: 'Houston',
    state: 'TX',
    latitude: '30.005562',
    longitude: '-95.399856',
    timeZoneId: 'America/Chicago',
  },
  '77074': {
    zip: '77074',
    city: 'Houston',
    state: 'TX',
    latitude: '29.678125',
    longitude: '-95.519229',
    timeZoneId: 'America/Chicago',
  },
  '77075': {
    zip: '77075',
    city: 'Houston',
    state: 'TX',
    latitude: '29.620035',
    longitude: '-95.268602',
    timeZoneId: 'America/Chicago',
  },
  '77076': {
    zip: '77076',
    city: 'Houston',
    state: 'TX',
    latitude: '29.859606',
    longitude: '-95.384462',
    timeZoneId: 'America/Chicago',
  },
  '77077': {
    zip: '77077',
    city: 'Houston',
    state: 'TX',
    latitude: '29.750197',
    longitude: '-95.611073',
    timeZoneId: 'America/Chicago',
  },
  '77078': {
    zip: '77078',
    city: 'Houston',
    state: 'TX',
    latitude: '29.858532',
    longitude: '-95.255477',
    timeZoneId: 'America/Chicago',
  },
  '77079': {
    zip: '77079',
    city: 'Houston',
    state: 'TX',
    latitude: '29.781784',
    longitude: '-95.602535',
    timeZoneId: 'America/Chicago',
  },
  '77080': {
    zip: '77080',
    city: 'Houston',
    state: 'TX',
    latitude: '29.81684',
    longitude: '-95.522482',
    timeZoneId: 'America/Chicago',
  },
  '77081': {
    zip: '77081',
    city: 'Houston',
    state: 'TX',
    latitude: '29.713931',
    longitude: '-95.479494',
    timeZoneId: 'America/Chicago',
  },
  '77082': {
    zip: '77082',
    city: 'Houston',
    state: 'TX',
    latitude: '29.720866',
    longitude: '-95.627625',
    timeZoneId: 'America/Chicago',
  },
  '77083': {
    zip: '77083',
    city: 'Houston',
    state: 'TX',
    latitude: '29.690885',
    longitude: '-95.651136',
    timeZoneId: 'America/Chicago',
  },
  '77084': {
    zip: '77084',
    city: 'Houston',
    state: 'TX',
    latitude: '29.82114',
    longitude: '-95.640017',
    timeZoneId: 'America/Chicago',
  },
  '77085': {
    zip: '77085',
    city: 'Houston',
    state: 'TX',
    latitude: '29.623765',
    longitude: '-95.493777',
    timeZoneId: 'America/Chicago',
  },
  '77086': {
    zip: '77086',
    city: 'Houston',
    state: 'TX',
    latitude: '29.921015',
    longitude: '-95.493478',
    timeZoneId: 'America/Chicago',
  },
  '77087': {
    zip: '77087',
    city: 'Houston',
    state: 'TX',
    latitude: '29.682888',
    longitude: '-95.30484',
    timeZoneId: 'America/Chicago',
  },
  '77088': {
    zip: '77088',
    city: 'Houston',
    state: 'TX',
    latitude: '29.880263',
    longitude: '-95.453243',
    timeZoneId: 'America/Chicago',
  },
  '77089': {
    zip: '77089',
    city: 'Houston',
    state: 'TX',
    latitude: '29.586462',
    longitude: '-95.220419',
    timeZoneId: 'America/Chicago',
  },
  '77090': {
    zip: '77090',
    city: 'Houston',
    state: 'TX',
    latitude: '30.011863',
    longitude: '-95.446521',
    timeZoneId: 'America/Chicago',
  },
  '77091': {
    zip: '77091',
    city: 'Houston',
    state: 'TX',
    latitude: '29.853167',
    longitude: '-95.436756',
    timeZoneId: 'America/Chicago',
  },
  '77092': {
    zip: '77092',
    city: 'Houston',
    state: 'TX',
    latitude: '29.83355',
    longitude: '-95.473861',
    timeZoneId: 'America/Chicago',
  },
  '77093': {
    zip: '77093',
    city: 'Houston',
    state: 'TX',
    latitude: '29.86261',
    longitude: '-95.339011',
    timeZoneId: 'America/Chicago',
  },
  '77094': {
    zip: '77094',
    city: 'Houston',
    state: 'TX',
    latitude: '29.757352',
    longitude: '-95.680631',
    timeZoneId: 'America/Chicago',
  },
  '77095': {
    zip: '77095',
    city: 'Houston',
    state: 'TX',
    latitude: '29.906371',
    longitude: '-95.655232',
    timeZoneId: 'America/Chicago',
  },
  '77096': {
    zip: '77096',
    city: 'Houston',
    state: 'TX',
    latitude: '29.671906',
    longitude: '-95.479578',
    timeZoneId: 'America/Chicago',
  },
  '77098': {
    zip: '77098',
    city: 'Houston',
    state: 'TX',
    latitude: '29.736613',
    longitude: '-95.41868',
    timeZoneId: 'America/Chicago',
  },
  '77099': {
    zip: '77099',
    city: 'Houston',
    state: 'TX',
    latitude: '29.670332',
    longitude: '-95.586361',
    timeZoneId: 'America/Chicago',
  },
  '77201': {
    zip: '77201',
    city: 'Houston',
    state: 'TX',
    latitude: '29.763934',
    longitude: '-95.363754',
    timeZoneId: 'America/Chicago',
  },
  '77202': {
    zip: '77202',
    city: 'Houston',
    state: 'TX',
    latitude: '29.758072',
    longitude: '-95.36555',
    timeZoneId: 'America/Chicago',
  },
  '77203': {
    zip: '77203',
    city: 'Houston',
    state: 'TX',
    latitude: '29.758943',
    longitude: '-95.367728',
    timeZoneId: 'America/Chicago',
  },
  '77204': {
    zip: '77204',
    city: 'Houston',
    state: 'TX',
    latitude: '29.765745',
    longitude: '-95.389271',
    timeZoneId: 'America/Chicago',
  },
  '77205': {
    zip: '77205',
    city: 'Houston',
    state: 'TX',
    latitude: '29.955096',
    longitude: '-95.335741',
    timeZoneId: 'America/Chicago',
  },
  '77206': {
    zip: '77206',
    city: 'Houston',
    state: 'TX',
    latitude: '29.827129',
    longitude: '-95.426254',
    timeZoneId: 'America/Chicago',
  },
  '77207': {
    zip: '77207',
    city: 'Houston',
    state: 'TX',
    latitude: '29.687816',
    longitude: '-95.301088',
    timeZoneId: 'America/Chicago',
  },
  '77208': {
    zip: '77208',
    city: 'Houston',
    state: 'TX',
    latitude: '29.758968',
    longitude: '-95.36594',
    timeZoneId: 'America/Chicago',
  },
  '77209': {
    zip: '77209',
    city: 'Houston',
    state: 'TX',
    latitude: '29.620069',
    longitude: '-95.190018',
    timeZoneId: 'America/Chicago',
  },
  '77210': {
    zip: '77210',
    city: 'Houston',
    state: 'TX',
    latitude: '29.757742',
    longitude: '-95.363878',
    timeZoneId: 'America/Chicago',
  },
  '77212': {
    zip: '77212',
    city: 'Houston',
    state: 'TX',
    latitude: '29.763737',
    longitude: '-95.365297',
    timeZoneId: 'America/Chicago',
  },
  '77213': {
    zip: '77213',
    city: 'Houston',
    state: 'TX',
    latitude: '29.794698',
    longitude: '-95.240665',
    timeZoneId: 'America/Chicago',
  },
  '77215': {
    zip: '77215',
    city: 'Houston',
    state: 'TX',
    latitude: '29.736504',
    longitude: '-95.521168',
    timeZoneId: 'America/Chicago',
  },
  '77216': {
    zip: '77216',
    city: 'Houston',
    state: 'TX',
    latitude: '29.615732',
    longitude: '-95.216522',
    timeZoneId: 'America/Chicago',
  },
  '77217': {
    zip: '77217',
    city: 'Houston',
    state: 'TX',
    latitude: '29.702057',
    longitude: '-95.266235',
    timeZoneId: 'America/Chicago',
  },
  '77218': {
    zip: '77218',
    city: 'Houston',
    state: 'TX',
    latitude: '29.828144',
    longitude: '-95.653044',
    timeZoneId: 'America/Chicago',
  },
  '77219': {
    zip: '77219',
    city: 'Houston',
    state: 'TX',
    latitude: '29.75716',
    longitude: '-95.411957',
    timeZoneId: 'America/Chicago',
  },
  '77220': {
    zip: '77220',
    city: 'Houston',
    state: 'TX',
    latitude: '29.772371',
    longitude: '-95.31509',
    timeZoneId: 'America/Chicago',
  },
  '77221': {
    zip: '77221',
    city: 'Houston',
    state: 'TX',
    latitude: '29.701556',
    longitude: '-95.352515',
    timeZoneId: 'America/Chicago',
  },
  '77222': {
    zip: '77222',
    city: 'Houston',
    state: 'TX',
    latitude: '29.829642',
    longitude: '-95.372234',
    timeZoneId: 'America/Chicago',
  },
  '77223': {
    zip: '77223',
    city: 'Houston',
    state: 'TX',
    latitude: '29.727156',
    longitude: '-95.320928',
    timeZoneId: 'America/Chicago',
  },
  '77224': {
    zip: '77224',
    city: 'Houston',
    state: 'TX',
    latitude: '29.771587',
    longitude: '-95.516816',
    timeZoneId: 'America/Chicago',
  },
  '77225': {
    zip: '77225',
    city: 'Houston',
    state: 'TX',
    latitude: '29.693106',
    longitude: '-95.416462',
    timeZoneId: 'America/Chicago',
  },
  '77226': {
    zip: '77226',
    city: 'Houston',
    state: 'TX',
    latitude: '29.801613',
    longitude: '-95.328864',
    timeZoneId: 'America/Chicago',
  },
  '77227': {
    zip: '77227',
    city: 'Houston',
    state: 'TX',
    latitude: '29.736978',
    longitude: '-95.447271',
    timeZoneId: 'America/Chicago',
  },
  '77228': {
    zip: '77228',
    city: 'Houston',
    state: 'TX',
    latitude: '29.874659',
    longitude: '-95.260396',
    timeZoneId: 'America/Chicago',
  },
  '77229': {
    zip: '77229',
    city: 'Houston',
    state: 'TX',
    latitude: '29.764959',
    longitude: '-95.269432',
    timeZoneId: 'America/Chicago',
  },
  '77230': {
    zip: '77230',
    city: 'Houston',
    state: 'TX',
    latitude: '29.698082',
    longitude: '-95.384159',
    timeZoneId: 'America/Chicago',
  },
  '77231': {
    zip: '77231',
    city: 'Houston',
    state: 'TX',
    latitude: '29.654326',
    longitude: '-95.475403',
    timeZoneId: 'America/Chicago',
  },
  '77233': {
    zip: '77233',
    city: 'Houston',
    state: 'TX',
    latitude: '29.655059',
    longitude: '-95.358217',
    timeZoneId: 'America/Chicago',
  },
  '77234': {
    zip: '77234',
    city: 'Houston',
    state: 'TX',
    latitude: '29.618205',
    longitude: '-95.19113',
    timeZoneId: 'America/Chicago',
  },
  '77235': {
    zip: '77235',
    city: 'Houston',
    state: 'TX',
    latitude: '29.654102',
    longitude: '-95.481116',
    timeZoneId: 'America/Chicago',
  },
  '77236': {
    zip: '77236',
    city: 'Houston',
    state: 'TX',
    latitude: '29.707243',
    longitude: '-95.497967',
    timeZoneId: 'America/Chicago',
  },
  '77237': {
    zip: '77237',
    city: 'Houston',
    state: 'TX',
    latitude: '29.734098',
    longitude: '-95.498638',
    timeZoneId: 'America/Chicago',
  },
  '77238': {
    zip: '77238',
    city: 'Houston',
    state: 'TX',
    latitude: '29.920983',
    longitude: '-95.443148',
    timeZoneId: 'America/Chicago',
  },
  '77240': {
    zip: '77240',
    city: 'Houston',
    state: 'TX',
    latitude: '29.876306',
    longitude: '-95.525353',
    timeZoneId: 'America/Chicago',
  },
  '77241': {
    zip: '77241',
    city: 'Houston',
    state: 'TX',
    latitude: '29.858836',
    longitude: '-95.537055',
    timeZoneId: 'America/Chicago',
  },
  '77242': {
    zip: '77242',
    city: 'Houston',
    state: 'TX',
    latitude: '29.732567',
    longitude: '-95.559784',
    timeZoneId: 'America/Chicago',
  },
  '77243': {
    zip: '77243',
    city: 'Houston',
    state: 'TX',
    latitude: '29.796788',
    longitude: '-95.491971',
    timeZoneId: 'America/Chicago',
  },
  '77244': {
    zip: '77244',
    city: 'Houston',
    state: 'TX',
    latitude: '29.77755',
    longitude: '-95.603263',
    timeZoneId: 'America/Chicago',
  },
  '77245': {
    zip: '77245',
    city: 'Houston',
    state: 'TX',
    latitude: '29.640355',
    longitude: '-95.435857',
    timeZoneId: 'America/Chicago',
  },
  '77248': {
    zip: '77248',
    city: 'Houston',
    state: 'TX',
    latitude: '29.799923',
    longitude: '-95.417313',
    timeZoneId: 'America/Chicago',
  },
  '77249': {
    zip: '77249',
    city: 'Houston',
    state: 'TX',
    latitude: '29.804629',
    longitude: '-95.375621',
    timeZoneId: 'America/Chicago',
  },
  '77251': {
    zip: '77251',
    city: 'Houston',
    state: 'TX',
    latitude: '29.702599',
    longitude: '-95.538992',
    timeZoneId: 'America/Chicago',
  },
  '77252': {
    zip: '77252',
    city: 'Houston',
    state: 'TX',
    latitude: '29.759003',
    longitude: '-95.366897',
    timeZoneId: 'America/Chicago',
  },
  '77253': {
    zip: '77253',
    city: 'Houston',
    state: 'TX',
    latitude: '29.756169',
    longitude: '-95.368198',
    timeZoneId: 'America/Chicago',
  },
  '77254': {
    zip: '77254',
    city: 'Houston',
    state: 'TX',
    latitude: '29.732223',
    longitude: '-95.413869',
    timeZoneId: 'America/Chicago',
  },
  '77255': {
    zip: '77255',
    city: 'Houston',
    state: 'TX',
    latitude: '29.804405',
    longitude: '-95.49623',
    timeZoneId: 'America/Chicago',
  },
  '77256': {
    zip: '77256',
    city: 'Houston',
    state: 'TX',
    latitude: '29.738334',
    longitude: '-95.436312',
    timeZoneId: 'America/Chicago',
  },
  '77257': {
    zip: '77257',
    city: 'Houston',
    state: 'TX',
    latitude: '29.74458',
    longitude: '-95.486171',
    timeZoneId: 'America/Chicago',
  },
  '77258': {
    zip: '77258',
    city: 'Houston',
    state: 'TX',
    latitude: '29.571123',
    longitude: '-95.079244',
    timeZoneId: 'America/Chicago',
  },
  '77259': {
    zip: '77259',
    city: 'Houston',
    state: 'TX',
    latitude: '29.574345',
    longitude: '-95.141093',
    timeZoneId: 'America/Chicago',
  },
  '77261': {
    zip: '77261',
    city: 'Houston',
    state: 'TX',
    latitude: '29.675671',
    longitude: '-95.244406',
    timeZoneId: 'America/Chicago',
  },
  '77262': {
    zip: '77262',
    city: 'Houston',
    state: 'TX',
    latitude: '29.723146',
    longitude: '-95.27893',
    timeZoneId: 'America/Chicago',
  },
  '77263': {
    zip: '77263',
    city: 'Houston',
    state: 'TX',
    latitude: '29.728564',
    longitude: '-95.519201',
    timeZoneId: 'America/Chicago',
  },
  '77265': {
    zip: '77265',
    city: 'Houston',
    state: 'TX',
    latitude: '29.71984',
    longitude: '-95.430807',
    timeZoneId: 'America/Chicago',
  },
  '77266': {
    zip: '77266',
    city: 'Houston',
    state: 'TX',
    latitude: '29.746626',
    longitude: '-95.391501',
    timeZoneId: 'America/Chicago',
  },
  '77267': {
    zip: '77267',
    city: 'Houston',
    state: 'TX',
    latitude: '29.953948',
    longitude: '-95.444857',
    timeZoneId: 'America/Chicago',
  },
  '77268': {
    zip: '77268',
    city: 'Houston',
    state: 'TX',
    latitude: '30.007153',
    longitude: '-95.486701',
    timeZoneId: 'America/Chicago',
  },
  '77269': {
    zip: '77269',
    city: 'Houston',
    state: 'TX',
    latitude: '29.978817',
    longitude: '-95.568207',
    timeZoneId: 'America/Chicago',
  },
  '77270': {
    zip: '77270',
    city: 'Houston',
    state: 'TX',
    latitude: '29.798454',
    longitude: '-95.41901',
    timeZoneId: 'America/Chicago',
  },
  '77271': {
    zip: '77271',
    city: 'Houston',
    state: 'TX',
    latitude: '29.659656',
    longitude: '-95.526379',
    timeZoneId: 'America/Chicago',
  },
  '77272': {
    zip: '77272',
    city: 'Houston',
    state: 'TX',
    latitude: '29.699844',
    longitude: '-95.582693',
    timeZoneId: 'America/Chicago',
  },
  '77273': {
    zip: '77273',
    city: 'Houston',
    state: 'TX',
    latitude: '30.000875',
    longitude: '-95.398266',
    timeZoneId: 'America/Chicago',
  },
  '77274': {
    zip: '77274',
    city: 'Houston',
    state: 'TX',
    latitude: '29.687989',
    longitude: '-95.516437',
    timeZoneId: 'America/Chicago',
  },
  '77275': {
    zip: '77275',
    city: 'Houston',
    state: 'TX',
    latitude: '29.757451',
    longitude: '-95.366785',
    timeZoneId: 'America/Chicago',
  },
  '77277': {
    zip: '77277',
    city: 'Houston',
    state: 'TX',
    latitude: '29.716725',
    longitude: '-95.42814',
    timeZoneId: 'America/Chicago',
  },
  '77279': {
    zip: '77279',
    city: 'Houston',
    state: 'TX',
    latitude: '29.768115',
    longitude: '-95.510337',
    timeZoneId: 'America/Chicago',
  },
  '77280': {
    zip: '77280',
    city: 'Houston',
    state: 'TX',
    latitude: '29.860314',
    longitude: '-95.585484',
    timeZoneId: 'America/Chicago',
  },
  '77282': {
    zip: '77282',
    city: 'Houston',
    state: 'TX',
    latitude: '29.745408',
    longitude: '-95.613335',
    timeZoneId: 'America/Chicago',
  },
  '77284': {
    zip: '77284',
    city: 'Houston',
    state: 'TX',
    latitude: '29.755455',
    longitude: '-95.367866',
    timeZoneId: 'America/Chicago',
  },
  '77287': {
    zip: '77287',
    city: 'Houston',
    state: 'TX',
    latitude: '29.674496',
    longitude: '-95.247573',
    timeZoneId: 'America/Chicago',
  },
  '77288': {
    zip: '77288',
    city: 'Houston',
    state: 'TX',
    latitude: '29.726969',
    longitude: '-95.361707',
    timeZoneId: 'America/Chicago',
  },
  '77289': {
    zip: '77289',
    city: 'Houston',
    state: 'TX',
    latitude: '29.606322',
    longitude: '-95.128467',
    timeZoneId: 'America/Chicago',
  },
  '77290': {
    zip: '77290',
    city: 'Houston',
    state: 'TX',
    latitude: '29.984528',
    longitude: '-95.466634',
    timeZoneId: 'America/Chicago',
  },
  '77291': {
    zip: '77291',
    city: 'Houston',
    state: 'TX',
    latitude: '29.872287',
    longitude: '-95.415915',
    timeZoneId: 'America/Chicago',
  },
  '77292': {
    zip: '77292',
    city: 'Houston',
    state: 'TX',
    latitude: '29.826185',
    longitude: '-95.428773',
    timeZoneId: 'America/Chicago',
  },
  '77293': {
    zip: '77293',
    city: 'Houston',
    state: 'TX',
    latitude: '29.861517',
    longitude: '-95.343954',
    timeZoneId: 'America/Chicago',
  },
  '77297': {
    zip: '77297',
    city: 'Houston',
    state: 'TX',
    latitude: '29.762572',
    longitude: '-95.364143',
    timeZoneId: 'America/Chicago',
  },
  '77299': {
    zip: '77299',
    city: 'Houston',
    state: 'TX',
    latitude: '29.770633',
    longitude: '-95.414819',
    timeZoneId: 'America/Chicago',
  },
  '77301': {
    zip: '77301',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.311394',
    longitude: '-95.433846',
    timeZoneId: 'America/Chicago',
  },
  '77302': {
    zip: '77302',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.244988',
    longitude: '-95.403556',
    timeZoneId: 'America/Chicago',
  },
  '77303': {
    zip: '77303',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.36051',
    longitude: '-95.414641',
    timeZoneId: 'America/Chicago',
  },
  '77304': {
    zip: '77304',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.34284',
    longitude: '-95.47788',
    timeZoneId: 'America/Chicago',
  },
  '77305': {
    zip: '77305',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.322008',
    longitude: '-95.462195',
    timeZoneId: 'America/Chicago',
  },
  '77306': {
    zip: '77306',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.32332',
    longitude: '-95.471373',
    timeZoneId: 'America/Chicago',
  },
  '77315': {
    zip: '77315',
    city: 'North Houston',
    state: 'TX',
    latitude: '29.929363',
    longitude: '-95.317465',
    timeZoneId: 'America/Chicago',
  },
  '77316': {
    zip: '77316',
    city: 'Montgomery',
    state: 'TX',
    latitude: '30.321767',
    longitude: '-95.68558',
    timeZoneId: 'America/Chicago',
  },
  '77318': {
    zip: '77318',
    city: 'Willis',
    state: 'TX',
    latitude: '30.452396',
    longitude: '-95.537675',
    timeZoneId: 'America/Chicago',
  },
  '77320': {
    zip: '77320',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.81913',
    longitude: '-95.578507',
    timeZoneId: 'America/Chicago',
  },
  '77325': {
    zip: '77325',
    city: 'Kingwood',
    state: 'TX',
    latitude: '30.06828',
    longitude: '-95.21628',
    timeZoneId: 'America/Chicago',
  },
  '77326': {
    zip: '77326',
    city: 'Ace',
    state: 'TX',
    latitude: '30.520029',
    longitude: '-94.810503',
    timeZoneId: 'America/Chicago',
  },
  '77327': {
    zip: '77327',
    city: 'Cleveland',
    state: 'TX',
    latitude: '30.33804',
    longitude: '-95.02362',
    timeZoneId: 'America/Chicago',
  },
  '77328': {
    zip: '77328',
    city: 'Cleveland',
    state: 'TX',
    latitude: '30.341748',
    longitude: '-95.086861',
    timeZoneId: 'America/Chicago',
  },
  '77331': {
    zip: '77331',
    city: 'Coldspring',
    state: 'TX',
    latitude: '30.59838',
    longitude: '-95.11452',
    timeZoneId: 'America/Chicago',
  },
  '77332': {
    zip: '77332',
    city: 'Dallardsville',
    state: 'TX',
    latitude: '30.626197',
    longitude: '-94.630939',
    timeZoneId: 'America/Chicago',
  },
  '77333': {
    zip: '77333',
    city: 'Dobbin',
    state: 'TX',
    latitude: '30.367215',
    longitude: '-95.772479',
    timeZoneId: 'America/Chicago',
  },
  '77334': {
    zip: '77334',
    city: 'Dodge',
    state: 'TX',
    latitude: '30.744321',
    longitude: '-95.396593',
    timeZoneId: 'America/Chicago',
  },
  '77335': {
    zip: '77335',
    city: 'Goodrich',
    state: 'TX',
    latitude: '30.551965',
    longitude: '-94.877464',
    timeZoneId: 'America/Chicago',
  },
  '77336': {
    zip: '77336',
    city: 'Huffman',
    state: 'TX',
    latitude: '30.043878',
    longitude: '-95.101533',
    timeZoneId: 'America/Chicago',
  },
  '77337': {
    zip: '77337',
    city: 'Hufsmith',
    state: 'TX',
    latitude: '30.122113',
    longitude: '-95.593231',
    timeZoneId: 'America/Chicago',
  },
  '77338': {
    zip: '77338',
    city: 'Humble',
    state: 'TX',
    latitude: '30.003446',
    longitude: '-95.277832',
    timeZoneId: 'America/Chicago',
  },
  '77339': {
    zip: '77339',
    city: 'Kingwood',
    state: 'TX',
    latitude: '30.0528',
    longitude: '-95.22072',
    timeZoneId: 'America/Chicago',
  },
  '77340': {
    zip: '77340',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.6885',
    longitude: '-95.57238',
    timeZoneId: 'America/Chicago',
  },
  '77341': {
    zip: '77341',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.714355',
    longitude: '-95.543506',
    timeZoneId: 'America/Chicago',
  },
  '77342': {
    zip: '77342',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.722392',
    longitude: '-95.531478',
    timeZoneId: 'America/Chicago',
  },
  '77343': {
    zip: '77343',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.722802',
    longitude: '-95.548721',
    timeZoneId: 'America/Chicago',
  },
  '77344': {
    zip: '77344',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.722043',
    longitude: '-95.552789',
    timeZoneId: 'America/Chicago',
  },
  '77345': {
    zip: '77345',
    city: 'Kingwood',
    state: 'TX',
    latitude: '30.055815',
    longitude: '-95.169196',
    timeZoneId: 'America/Chicago',
  },
  '77346': {
    zip: '77346',
    city: 'Humble',
    state: 'TX',
    latitude: '30.033333',
    longitude: '-95.25',
    timeZoneId: 'America/Chicago',
  },
  '77347': {
    zip: '77347',
    city: 'Humble',
    state: 'TX',
    latitude: '29.991455',
    longitude: '-95.251652',
    timeZoneId: 'America/Chicago',
  },
  '77348': {
    zip: '77348',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.722858',
    longitude: '-95.553325',
    timeZoneId: 'America/Chicago',
  },
  '77349': {
    zip: '77349',
    city: 'Huntsville',
    state: 'TX',
    latitude: '30.721806',
    longitude: '-95.55114',
    timeZoneId: 'America/Chicago',
  },
  '77350': {
    zip: '77350',
    city: 'Leggett',
    state: 'TX',
    latitude: '30.817983',
    longitude: '-94.870287',
    timeZoneId: 'America/Chicago',
  },
  '77351': {
    zip: '77351',
    city: 'Livingston',
    state: 'TX',
    latitude: '30.57717',
    longitude: '-94.68621',
    timeZoneId: 'America/Chicago',
  },
  '77353': {
    zip: '77353',
    city: 'Magnolia',
    state: 'TX',
    latitude: '30.21166',
    longitude: '-95.75701',
    timeZoneId: 'America/Chicago',
  },
  '77354': {
    zip: '77354',
    city: 'Magnolia',
    state: 'TX',
    latitude: '30.221346',
    longitude: '-95.67672',
    timeZoneId: 'America/Chicago',
  },
  '77355': {
    zip: '77355',
    city: 'Magnolia',
    state: 'TX',
    latitude: '30.151734',
    longitude: '-95.743252',
    timeZoneId: 'America/Chicago',
  },
  '77356': {
    zip: '77356',
    city: 'Montgomery',
    state: 'TX',
    latitude: '30.457345',
    longitude: '-95.673436',
    timeZoneId: 'America/Chicago',
  },
  '77357': {
    zip: '77357',
    city: 'New Caney',
    state: 'TX',
    latitude: '30.13446',
    longitude: '-95.1807',
    timeZoneId: 'America/Chicago',
  },
  '77358': {
    zip: '77358',
    city: 'New Waverly',
    state: 'TX',
    latitude: '30.548896',
    longitude: '-95.49357',
    timeZoneId: 'America/Chicago',
  },
  '77359': {
    zip: '77359',
    city: 'Oakhurst',
    state: 'TX',
    latitude: '30.699015',
    longitude: '-95.300239',
    timeZoneId: 'America/Chicago',
  },
  '77360': {
    zip: '77360',
    city: 'Onalaska',
    state: 'TX',
    latitude: '30.806106',
    longitude: '-95.117992',
    timeZoneId: 'America/Chicago',
  },
  '77362': {
    zip: '77362',
    city: 'Pinehurst',
    state: 'TX',
    latitude: '30.1631',
    longitude: '-95.682001',
    timeZoneId: 'America/Chicago',
  },
  '77363': {
    zip: '77363',
    city: 'Plantersville',
    state: 'TX',
    latitude: '30.269093',
    longitude: '-95.86593',
    timeZoneId: 'America/Chicago',
  },
  '77364': {
    zip: '77364',
    city: 'Pointblank',
    state: 'TX',
    latitude: '30.767519',
    longitude: '-95.251998',
    timeZoneId: 'America/Chicago',
  },
  '77365': {
    zip: '77365',
    city: 'Porter',
    state: 'TX',
    latitude: '30.105908',
    longitude: '-95.260353',
    timeZoneId: 'America/Chicago',
  },
  '77367': {
    zip: '77367',
    city: 'Riverside',
    state: 'TX',
    latitude: '30.843263',
    longitude: '-95.370764',
    timeZoneId: 'America/Chicago',
  },
  '77368': {
    zip: '77368',
    city: 'Romayor',
    state: 'TX',
    latitude: '30.448695',
    longitude: '-94.843557',
    timeZoneId: 'America/Chicago',
  },
  '77369': {
    zip: '77369',
    city: 'Rye',
    state: 'TX',
    latitude: '30.450867',
    longitude: '-94.765224',
    timeZoneId: 'America/Chicago',
  },
  '77371': {
    zip: '77371',
    city: 'Shepherd',
    state: 'TX',
    latitude: '30.479972',
    longitude: '-94.991246',
    timeZoneId: 'America/Chicago',
  },
  '77372': {
    zip: '77372',
    city: 'Splendora',
    state: 'TX',
    latitude: '30.220286',
    longitude: '-95.158358',
    timeZoneId: 'America/Chicago',
  },
  '77373': {
    zip: '77373',
    city: 'Spring',
    state: 'TX',
    latitude: '30.05463',
    longitude: '-95.382975',
    timeZoneId: 'America/Chicago',
  },
  '77374': {
    zip: '77374',
    city: 'Thicket',
    state: 'TX',
    latitude: '30.397106',
    longitude: '-94.629621',
    timeZoneId: 'America/Chicago',
  },
  '77375': {
    zip: '77375',
    city: 'Tomball',
    state: 'TX',
    latitude: '30.081648',
    longitude: '-95.615932',
    timeZoneId: 'America/Chicago',
  },
  '77376': {
    zip: '77376',
    city: 'Votaw',
    state: 'TX',
    latitude: '30.436867',
    longitude: '-94.675073',
    timeZoneId: 'America/Chicago',
  },
  '77377': {
    zip: '77377',
    city: 'Tomball',
    state: 'TX',
    latitude: '30.098706',
    longitude: '-95.614258',
    timeZoneId: 'America/Chicago',
  },
  '77378': {
    zip: '77378',
    city: 'Willis',
    state: 'TX',
    latitude: '30.417704',
    longitude: '-95.408157',
    timeZoneId: 'America/Chicago',
  },
  '77379': {
    zip: '77379',
    city: 'Spring',
    state: 'TX',
    latitude: '30.027227',
    longitude: '-95.529294',
    timeZoneId: 'America/Chicago',
  },
  '77380': {
    zip: '77380',
    city: 'Spring',
    state: 'TX',
    latitude: '30.140487',
    longitude: '-95.471451',
    timeZoneId: 'America/Chicago',
  },
  '77381': {
    zip: '77381',
    city: 'Spring',
    state: 'TX',
    latitude: '30.16848',
    longitude: '-95.48964',
    timeZoneId: 'America/Chicago',
  },
  '77382': {
    zip: '77382',
    city: 'Spring',
    state: 'TX',
    latitude: '30.22938',
    longitude: '-95.51082',
    timeZoneId: 'America/Chicago',
  },
  '77383': {
    zip: '77383',
    city: 'Spring',
    state: 'TX',
    latitude: '30.074289',
    longitude: '-95.42279',
    timeZoneId: 'America/Chicago',
  },
  '77384': {
    zip: '77384',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.230149',
    longitude: '-95.490453',
    timeZoneId: 'America/Chicago',
  },
  '77385': {
    zip: '77385',
    city: 'Conroe',
    state: 'TX',
    latitude: '30.185643',
    longitude: '-95.428944',
    timeZoneId: 'America/Chicago',
  },
  '77386': {
    zip: '77386',
    city: 'Spring',
    state: 'TX',
    latitude: '30.131735',
    longitude: '-95.416441',
    timeZoneId: 'America/Chicago',
  },
  '77387': {
    zip: '77387',
    city: 'Spring',
    state: 'TX',
    latitude: '30.077102',
    longitude: '-95.417741',
    timeZoneId: 'America/Chicago',
  },
  '77388': {
    zip: '77388',
    city: 'Spring',
    state: 'TX',
    latitude: '30.050527',
    longitude: '-95.465969',
    timeZoneId: 'America/Chicago',
  },
  '77389': {
    zip: '77389',
    city: 'Spring',
    state: 'TX',
    latitude: '30.0475',
    longitude: '-95.532222',
    timeZoneId: 'America/Chicago',
  },
  '77391': {
    zip: '77391',
    city: 'Spring',
    state: 'TX',
    latitude: '30.021537',
    longitude: '-95.569341',
    timeZoneId: 'America/Chicago',
  },
  '77393': {
    zip: '77393',
    city: 'Spring',
    state: 'TX',
    latitude: '30.175872',
    longitude: '-95.504233',
    timeZoneId: 'America/Chicago',
  },
  '77396': {
    zip: '77396',
    city: 'Humble',
    state: 'TX',
    latitude: '29.952397',
    longitude: '-95.268704',
    timeZoneId: 'America/Chicago',
  },
  '77399': {
    zip: '77399',
    city: 'Livingston',
    state: 'TX',
    latitude: '30.713086',
    longitude: '-94.935409',
    timeZoneId: 'America/Chicago',
  },
  '77401': {
    zip: '77401',
    city: 'Bellaire',
    state: 'TX',
    latitude: '29.705063',
    longitude: '-95.458755',
    timeZoneId: 'America/Chicago',
  },
  '77402': {
    zip: '77402',
    city: 'Bellaire',
    state: 'TX',
    latitude: '29.706618',
    longitude: '-95.475338',
    timeZoneId: 'America/Chicago',
  },
  '77404': {
    zip: '77404',
    city: 'Bay City',
    state: 'TX',
    latitude: '28.83214',
    longitude: '-95.67105',
    timeZoneId: 'America/Chicago',
  },
  '77406': {
    zip: '77406',
    city: 'Richmond',
    state: 'TX',
    latitude: '29.62271',
    longitude: '-95.647993',
    timeZoneId: 'America/Chicago',
  },
  '77407': {
    zip: '77407',
    city: 'Richmond',
    state: 'TX',
    latitude: '29.622726',
    longitude: '-95.647873',
    timeZoneId: 'America/Chicago',
  },
  '77410': {
    zip: '77410',
    city: 'Cypress',
    state: 'TX',
    latitude: '29.972663',
    longitude: '-95.696811',
    timeZoneId: 'America/Chicago',
  },
  '77411': {
    zip: '77411',
    city: 'Alief',
    state: 'TX',
    latitude: '29.730802',
    longitude: '-95.592618',
    timeZoneId: 'America/Chicago',
  },
  '77412': {
    zip: '77412',
    city: 'Altair',
    state: 'TX',
    latitude: '29.640974',
    longitude: '-96.510571',
    timeZoneId: 'America/Chicago',
  },
  '77413': {
    zip: '77413',
    city: 'Barker',
    state: 'TX',
    latitude: '29.770634',
    longitude: '-95.702302',
    timeZoneId: 'America/Chicago',
  },
  '77414': {
    zip: '77414',
    city: 'Bay City',
    state: 'TX',
    latitude: '28.963849',
    longitude: '-95.94808',
    timeZoneId: 'America/Chicago',
  },
  '77415': {
    zip: '77415',
    city: 'Cedar Lane',
    state: 'TX',
    latitude: '28.945698',
    longitude: '-95.735313',
    timeZoneId: 'America/Chicago',
  },
  '77417': {
    zip: '77417',
    city: 'Beasley',
    state: 'TX',
    latitude: '29.47458',
    longitude: '-95.97132',
    timeZoneId: 'America/Chicago',
  },
  '77418': {
    zip: '77418',
    city: 'Bellville',
    state: 'TX',
    latitude: '29.98968',
    longitude: '-96.25404',
    timeZoneId: 'America/Chicago',
  },
  '77419': {
    zip: '77419',
    city: 'Blessing',
    state: 'TX',
    latitude: '28.851796',
    longitude: '-96.229757',
    timeZoneId: 'America/Chicago',
  },
  '77420': {
    zip: '77420',
    city: 'Boling',
    state: 'TX',
    latitude: '29.26716',
    longitude: '-95.94432',
    timeZoneId: 'America/Chicago',
  },
  '77422': {
    zip: '77422',
    city: 'Brazoria',
    state: 'TX',
    latitude: '29.043342',
    longitude: '-95.567119',
    timeZoneId: 'America/Chicago',
  },
  '77423': {
    zip: '77423',
    city: 'Brookshire',
    state: 'TX',
    latitude: '29.79894',
    longitude: '-95.962284',
    timeZoneId: 'America/Chicago',
  },
  '77426': {
    zip: '77426',
    city: 'Chappell Hill',
    state: 'TX',
    latitude: '30.052487',
    longitude: '-96.20676',
    timeZoneId: 'America/Chicago',
  },
  '77428': {
    zip: '77428',
    city: 'Collegeport',
    state: 'TX',
    latitude: '28.723708',
    longitude: '-96.176127',
    timeZoneId: 'America/Chicago',
  },
  '77429': {
    zip: '77429',
    city: 'Cypress',
    state: 'TX',
    latitude: '29.991106',
    longitude: '-95.669038',
    timeZoneId: 'America/Chicago',
  },
  '77430': {
    zip: '77430',
    city: 'Damon',
    state: 'TX',
    latitude: '29.288027',
    longitude: '-95.684691',
    timeZoneId: 'America/Chicago',
  },
  '77431': {
    zip: '77431',
    city: 'Danciger',
    state: 'TX',
    latitude: '29.169311',
    longitude: '-95.811747',
    timeZoneId: 'America/Chicago',
  },
  '77432': {
    zip: '77432',
    city: 'Danevang',
    state: 'TX',
    latitude: '29.078906',
    longitude: '-96.175303',
    timeZoneId: 'America/Chicago',
  },
  '77433': {
    zip: '77433',
    city: 'Cypress',
    state: 'TX',
    latitude: '29.914176',
    longitude: '-95.734253',
    timeZoneId: 'America/Chicago',
  },
  '77434': {
    zip: '77434',
    city: 'Eagle Lake',
    state: 'TX',
    latitude: '29.59128',
    longitude: '-96.34044',
    timeZoneId: 'America/Chicago',
  },
  '77435': {
    zip: '77435',
    city: 'East Bernard',
    state: 'TX',
    latitude: '29.527948',
    longitude: '-96.061715',
    timeZoneId: 'America/Chicago',
  },
  '77436': {
    zip: '77436',
    city: 'Egypt',
    state: 'TX',
    latitude: '29.404861',
    longitude: '-96.239193',
    timeZoneId: 'America/Chicago',
  },
  '77437': {
    zip: '77437',
    city: 'El Campo',
    state: 'TX',
    latitude: '29.198562',
    longitude: '-96.272851',
    timeZoneId: 'America/Chicago',
  },
  '77440': {
    zip: '77440',
    city: 'Elmaton',
    state: 'TX',
    latitude: '28.831003',
    longitude: '-96.120733',
    timeZoneId: 'America/Chicago',
  },
  '77441': {
    zip: '77441',
    city: 'Fulshear',
    state: 'TX',
    latitude: '29.680355',
    longitude: '-95.926739',
    timeZoneId: 'America/Chicago',
  },
  '77442': {
    zip: '77442',
    city: 'Garwood',
    state: 'TX',
    latitude: '29.448898',
    longitude: '-96.397286',
    timeZoneId: 'America/Chicago',
  },
  '77443': {
    zip: '77443',
    city: 'Glen Flora',
    state: 'TX',
    latitude: '29.344945',
    longitude: '-96.19128',
    timeZoneId: 'America/Chicago',
  },
  '77444': {
    zip: '77444',
    city: 'Guy',
    state: 'TX',
    latitude: '29.31218',
    longitude: '-95.787708',
    timeZoneId: 'America/Chicago',
  },
  '77445': {
    zip: '77445',
    city: 'Hempstead',
    state: 'TX',
    latitude: '30.07212',
    longitude: '-96.08124',
    timeZoneId: 'America/Chicago',
  },
  '77446': {
    zip: '77446',
    city: 'Prairie View',
    state: 'TX',
    latitude: '30.081335',
    longitude: '-95.992865',
    timeZoneId: 'America/Chicago',
  },
  '77447': {
    zip: '77447',
    city: 'Hockley',
    state: 'TX',
    latitude: '30.028362',
    longitude: '-95.81942',
    timeZoneId: 'America/Chicago',
  },
  '77448': {
    zip: '77448',
    city: 'Hungerford',
    state: 'TX',
    latitude: '29.310181',
    longitude: '-96.097046',
    timeZoneId: 'America/Chicago',
  },
  '77449': {
    zip: '77449',
    city: 'Katy',
    state: 'TX',
    latitude: '29.819538',
    longitude: '-95.75226',
    timeZoneId: 'America/Chicago',
  },
  '77450': {
    zip: '77450',
    city: 'Katy',
    state: 'TX',
    latitude: '29.768276',
    longitude: '-95.743065',
    timeZoneId: 'America/Chicago',
  },
  '77451': {
    zip: '77451',
    city: 'Kendleton',
    state: 'TX',
    latitude: '29.447929',
    longitude: '-96.001002',
    timeZoneId: 'America/Chicago',
  },
  '77452': {
    zip: '77452',
    city: 'Kenney',
    state: 'TX',
    latitude: '30.046541',
    longitude: '-96.319009',
    timeZoneId: 'America/Chicago',
  },
  '77453': {
    zip: '77453',
    city: 'Lane City',
    state: 'TX',
    latitude: '29.21535',
    longitude: '-96.019905',
    timeZoneId: 'America/Chicago',
  },
  '77454': {
    zip: '77454',
    city: 'Lissie',
    state: 'TX',
    latitude: '29.554125',
    longitude: '-96.22632',
    timeZoneId: 'America/Chicago',
  },
  '77455': {
    zip: '77455',
    city: 'Louise',
    state: 'TX',
    latitude: '29.16408',
    longitude: '-96.4068',
    timeZoneId: 'America/Chicago',
  },
  '77456': {
    zip: '77456',
    city: 'Markham',
    state: 'TX',
    latitude: '28.964731',
    longitude: '-96.090617',
    timeZoneId: 'America/Chicago',
  },
  '77457': {
    zip: '77457',
    city: 'Matagorda',
    state: 'TX',
    latitude: '28.696251',
    longitude: '-95.970671',
    timeZoneId: 'America/Chicago',
  },
  '77458': {
    zip: '77458',
    city: 'Midfield',
    state: 'TX',
    latitude: '28.941692',
    longitude: '-96.241579',
    timeZoneId: 'America/Chicago',
  },
  '77459': {
    zip: '77459',
    city: 'Missouri City',
    state: 'TX',
    latitude: '29.525902',
    longitude: '-95.531735',
    timeZoneId: 'America/Chicago',
  },
  '77460': {
    zip: '77460',
    city: 'Nada',
    state: 'TX',
    latitude: '29.401396',
    longitude: '-96.386737',
    timeZoneId: 'America/Chicago',
  },
  '77461': {
    zip: '77461',
    city: 'Needville',
    state: 'TX',
    latitude: '29.394555',
    longitude: '-95.817313',
    timeZoneId: 'America/Chicago',
  },
  '77463': {
    zip: '77463',
    city: 'Old Ocean',
    state: 'TX',
    latitude: '29.07739',
    longitude: '-95.748242',
    timeZoneId: 'America/Chicago',
  },
  '77464': {
    zip: '77464',
    city: 'Orchard',
    state: 'TX',
    latitude: '29.602928',
    longitude: '-95.9654',
    timeZoneId: 'America/Chicago',
  },
  '77465': {
    zip: '77465',
    city: 'Palacios',
    state: 'TX',
    latitude: '28.709508',
    longitude: '-96.218574',
    timeZoneId: 'America/Chicago',
  },
  '77466': {
    zip: '77466',
    city: 'Pattison',
    state: 'TX',
    latitude: '29.815251',
    longitude: '-95.987036',
    timeZoneId: 'America/Chicago',
  },
  '77467': {
    zip: '77467',
    city: 'Pierce',
    state: 'TX',
    latitude: '29.236892',
    longitude: '-96.198326',
    timeZoneId: 'America/Chicago',
  },
  '77468': {
    zip: '77468',
    city: 'Pledger',
    state: 'TX',
    latitude: '29.17845',
    longitude: '-95.907313',
    timeZoneId: 'America/Chicago',
  },
  '77469': {
    zip: '77469',
    city: 'Richmond',
    state: 'TX',
    latitude: '29.590019',
    longitude: '-95.752785',
    timeZoneId: 'America/Chicago',
  },
  '77470': {
    zip: '77470',
    city: 'Rock Island',
    state: 'TX',
    latitude: '29.53175',
    longitude: '-96.574977',
    timeZoneId: 'America/Chicago',
  },
  '77471': {
    zip: '77471',
    city: 'Rosenberg',
    state: 'TX',
    latitude: '29.55237',
    longitude: '-95.852714',
    timeZoneId: 'America/Chicago',
  },
  '77473': {
    zip: '77473',
    city: 'San Felipe',
    state: 'TX',
    latitude: '29.792743',
    longitude: '-96.091014',
    timeZoneId: 'America/Chicago',
  },
  '77474': {
    zip: '77474',
    city: 'Sealy',
    state: 'TX',
    latitude: '29.783576',
    longitude: '-96.158093',
    timeZoneId: 'America/Chicago',
  },
  '77475': {
    zip: '77475',
    city: 'Sheridan',
    state: 'TX',
    latitude: '29.494031',
    longitude: '-96.668751',
    timeZoneId: 'America/Chicago',
  },
  '77476': {
    zip: '77476',
    city: 'Simonton',
    state: 'TX',
    latitude: '29.683333',
    longitude: '-95.966667',
    timeZoneId: 'America/Chicago',
  },
  '77477': {
    zip: '77477',
    city: 'Stafford',
    state: 'TX',
    latitude: '29.621316',
    longitude: '-95.562225',
    timeZoneId: 'America/Chicago',
  },
  '77478': {
    zip: '77478',
    city: 'Sugar Land',
    state: 'TX',
    latitude: '29.627645',
    longitude: '-95.635597',
    timeZoneId: 'America/Chicago',
  },
  '77479': {
    zip: '77479',
    city: 'Sugar Land',
    state: 'TX',
    latitude: '29.565407',
    longitude: '-95.63528',
    timeZoneId: 'America/Chicago',
  },
  '77480': {
    zip: '77480',
    city: 'Sweeny',
    state: 'TX',
    latitude: '29.059338',
    longitude: '-95.705377',
    timeZoneId: 'America/Chicago',
  },
  '77481': {
    zip: '77481',
    city: 'Thompsons',
    state: 'TX',
    latitude: '29.488833',
    longitude: '-95.595741',
    timeZoneId: 'America/Chicago',
  },
  '77482': {
    zip: '77482',
    city: 'Van Vleck',
    state: 'TX',
    latitude: '29.025542',
    longitude: '-95.885096',
    timeZoneId: 'America/Chicago',
  },
  '77483': {
    zip: '77483',
    city: 'Wadsworth',
    state: 'TX',
    latitude: '28.578493',
    longitude: '-96.04494',
    timeZoneId: 'America/Chicago',
  },
  '77484': {
    zip: '77484',
    city: 'Waller',
    state: 'TX',
    latitude: '30.075392',
    longitude: '-95.890763',
    timeZoneId: 'America/Chicago',
  },
  '77485': {
    zip: '77485',
    city: 'Wallis',
    state: 'TX',
    latitude: '29.605967',
    longitude: '-96.028147',
    timeZoneId: 'America/Chicago',
  },
  '77486': {
    zip: '77486',
    city: 'West Columbia',
    state: 'TX',
    latitude: '29.14206',
    longitude: '-95.66556',
    timeZoneId: 'America/Chicago',
  },
  '77487': {
    zip: '77487',
    city: 'Sugar Land',
    state: 'TX',
    latitude: '29.618596',
    longitude: '-95.636871',
    timeZoneId: 'America/Chicago',
  },
  '77488': {
    zip: '77488',
    city: 'Wharton',
    state: 'TX',
    latitude: '29.32968',
    longitude: '-96.0612',
    timeZoneId: 'America/Chicago',
  },
  '77489': {
    zip: '77489',
    city: 'Missouri City',
    state: 'TX',
    latitude: '29.596601',
    longitude: '-95.516014',
    timeZoneId: 'America/Chicago',
  },
  '77491': {
    zip: '77491',
    city: 'Katy',
    state: 'TX',
    latitude: '29.786079',
    longitude: '-95.82084',
    timeZoneId: 'America/Chicago',
  },
  '77492': {
    zip: '77492',
    city: 'Katy',
    state: 'TX',
    latitude: '29.747025',
    longitude: '-95.821656',
    timeZoneId: 'America/Chicago',
  },
  '77493': {
    zip: '77493',
    city: 'Katy',
    state: 'TX',
    latitude: '29.810252',
    longitude: '-95.806907',
    timeZoneId: 'America/Chicago',
  },
  '77494': {
    zip: '77494',
    city: 'Katy',
    state: 'TX',
    latitude: '29.753968',
    longitude: '-95.810176',
    timeZoneId: 'America/Chicago',
  },
  '77496': {
    zip: '77496',
    city: 'Sugar Land',
    state: 'TX',
    latitude: '29.620202',
    longitude: '-95.637753',
    timeZoneId: 'America/Chicago',
  },
  '77497': {
    zip: '77497',
    city: 'Stafford',
    state: 'TX',
    latitude: '29.615581',
    longitude: '-95.554671',
    timeZoneId: 'America/Chicago',
  },
  '77498': {
    zip: '77498',
    city: 'Sugar Land',
    state: 'TX',
    latitude: '29.615834',
    longitude: '-95.552346',
    timeZoneId: 'America/Chicago',
  },
  '77501': {
    zip: '77501',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.684464',
    longitude: '-95.1698',
    timeZoneId: 'America/Chicago',
  },
  '77502': {
    zip: '77502',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.679942',
    longitude: '-95.19844',
    timeZoneId: 'America/Chicago',
  },
  '77503': {
    zip: '77503',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.691128',
    longitude: '-95.162613',
    timeZoneId: 'America/Chicago',
  },
  '77504': {
    zip: '77504',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.649003',
    longitude: '-95.182296',
    timeZoneId: 'America/Chicago',
  },
  '77505': {
    zip: '77505',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.654184',
    longitude: '-95.149733',
    timeZoneId: 'America/Chicago',
  },
  '77506': {
    zip: '77506',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.705414',
    longitude: '-95.201839',
    timeZoneId: 'America/Chicago',
  },
  '77507': {
    zip: '77507',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.625223',
    longitude: '-95.057353',
    timeZoneId: 'America/Chicago',
  },
  '77508': {
    zip: '77508',
    city: 'Pasadena',
    state: 'TX',
    latitude: '29.66381',
    longitude: '-95.148185',
    timeZoneId: 'America/Chicago',
  },
  '77510': {
    zip: '77510',
    city: 'Santa Fe',
    state: 'TX',
    latitude: '29.372127',
    longitude: '-95.087892',
    timeZoneId: 'America/Chicago',
  },
  '77511': {
    zip: '77511',
    city: 'Alvin',
    state: 'TX',
    latitude: '29.4',
    longitude: '-95.23944',
    timeZoneId: 'America/Chicago',
  },
  '77512': {
    zip: '77512',
    city: 'Alvin',
    state: 'TX',
    latitude: '29.421732',
    longitude: '-95.24786',
    timeZoneId: 'America/Chicago',
  },
  '77514': {
    zip: '77514',
    city: 'Anahuac',
    state: 'TX',
    latitude: '29.768783',
    longitude: '-94.665834',
    timeZoneId: 'America/Chicago',
  },
  '77515': {
    zip: '77515',
    city: 'Angleton',
    state: 'TX',
    latitude: '29.168122',
    longitude: '-95.425065',
    timeZoneId: 'America/Chicago',
  },
  '77516': {
    zip: '77516',
    city: 'Angleton',
    state: 'TX',
    latitude: '29.168838',
    longitude: '-95.431359',
    timeZoneId: 'America/Chicago',
  },
  '77517': {
    zip: '77517',
    city: 'Santa Fe',
    state: 'TX',
    latitude: '29.383481',
    longitude: '-95.127808',
    timeZoneId: 'America/Chicago',
  },
  '77518': {
    zip: '77518',
    city: 'Bacliff',
    state: 'TX',
    latitude: '29.503662',
    longitude: '-94.988266',
    timeZoneId: 'America/Chicago',
  },
  '77519': {
    zip: '77519',
    city: 'Batson',
    state: 'TX',
    latitude: '30.232867',
    longitude: '-94.603365',
    timeZoneId: 'America/Chicago',
  },
  '77520': {
    zip: '77520',
    city: 'Baytown',
    state: 'TX',
    latitude: '29.77254',
    longitude: '-94.94484',
    timeZoneId: 'America/Chicago',
  },
  '77521': {
    zip: '77521',
    city: 'Baytown',
    state: 'TX',
    latitude: '29.795863',
    longitude: '-94.973054',
    timeZoneId: 'America/Chicago',
  },
  '77522': {
    zip: '77522',
    city: 'Baytown',
    state: 'TX',
    latitude: '29.756389',
    longitude: '-94.96837',
    timeZoneId: 'America/Chicago',
  },
  '77523': {
    zip: '77523',
    city: 'Baytown',
    state: 'TX',
    latitude: '29.756417',
    longitude: '-94.968734',
    timeZoneId: 'America/Chicago',
  },
  '77530': {
    zip: '77530',
    city: 'Channelview',
    state: 'TX',
    latitude: '29.789616',
    longitude: '-95.120167',
    timeZoneId: 'America/Chicago',
  },
  '77531': {
    zip: '77531',
    city: 'Clute',
    state: 'TX',
    latitude: '29.04606',
    longitude: '-95.40216',
    timeZoneId: 'America/Chicago',
  },
  '77532': {
    zip: '77532',
    city: 'Crosby',
    state: 'TX',
    latitude: '29.925886',
    longitude: '-95.070322',
    timeZoneId: 'America/Chicago',
  },
  '77533': {
    zip: '77533',
    city: 'Daisetta',
    state: 'TX',
    latitude: '30.115927',
    longitude: '-94.643055',
    timeZoneId: 'America/Chicago',
  },
  '77534': {
    zip: '77534',
    city: 'Danbury',
    state: 'TX',
    latitude: '29.224819',
    longitude: '-95.335793',
    timeZoneId: 'America/Chicago',
  },
  '77535': {
    zip: '77535',
    city: 'Dayton',
    state: 'TX',
    latitude: '29.99778',
    longitude: '-94.91832',
    timeZoneId: 'America/Chicago',
  },
  '77536': {
    zip: '77536',
    city: 'Deer Park',
    state: 'TX',
    latitude: '29.696919',
    longitude: '-95.112428',
    timeZoneId: 'America/Chicago',
  },
  '77538': {
    zip: '77538',
    city: 'Devers',
    state: 'TX',
    latitude: '29.992347',
    longitude: '-94.529977',
    timeZoneId: 'America/Chicago',
  },
  '77539': {
    zip: '77539',
    city: 'Dickinson',
    state: 'TX',
    latitude: '29.469',
    longitude: '-95.01438',
    timeZoneId: 'America/Chicago',
  },
  '77541': {
    zip: '77541',
    city: 'Freeport',
    state: 'TX',
    latitude: '28.95198',
    longitude: '-95.36628',
    timeZoneId: 'America/Chicago',
  },
  '77542': {
    zip: '77542',
    city: 'Freeport',
    state: 'TX',
    latitude: '28.95116',
    longitude: '-95.363282',
    timeZoneId: 'America/Chicago',
  },
  '77545': {
    zip: '77545',
    city: 'Fresno',
    state: 'TX',
    latitude: '29.547428',
    longitude: '-95.46898',
    timeZoneId: 'America/Chicago',
  },
  '77546': {
    zip: '77546',
    city: 'Friendswood',
    state: 'TX',
    latitude: '29.517354',
    longitude: '-95.201259',
    timeZoneId: 'America/Chicago',
  },
  '77547': {
    zip: '77547',
    city: 'Galena Park',
    state: 'TX',
    latitude: '29.739015',
    longitude: '-95.234217',
    timeZoneId: 'America/Chicago',
  },
  '77549': {
    zip: '77549',
    city: 'Friendswood',
    state: 'TX',
    latitude: '29.530347',
    longitude: '-95.201732',
    timeZoneId: 'America/Chicago',
  },
  '77550': {
    zip: '77550',
    city: 'Galveston',
    state: 'TX',
    latitude: '29.30124',
    longitude: '-94.78842',
    timeZoneId: 'America/Chicago',
  },
  '77551': {
    zip: '77551',
    city: 'Galveston',
    state: 'TX',
    latitude: '29.277415',
    longitude: '-94.838382',
    timeZoneId: 'America/Chicago',
  },
  '77552': {
    zip: '77552',
    city: 'Galveston',
    state: 'TX',
    latitude: '29.280987',
    longitude: '-94.815438',
    timeZoneId: 'America/Chicago',
  },
  '77553': {
    zip: '77553',
    city: 'Galveston',
    state: 'TX',
    latitude: '29.213993',
    longitude: '-94.934063',
    timeZoneId: 'America/Chicago',
  },
  '77554': {
    zip: '77554',
    city: 'Galveston',
    state: 'TX',
    latitude: '29.207422',
    longitude: '-94.965163',
    timeZoneId: 'America/Chicago',
  },
  '77555': {
    zip: '77555',
    city: 'Galveston',
    state: 'TX',
    latitude: '29.311767',
    longitude: '-94.782331',
    timeZoneId: 'America/Chicago',
  },
  '77560': {
    zip: '77560',
    city: 'Hankamer',
    state: 'TX',
    latitude: '29.875851',
    longitude: '-94.57842',
    timeZoneId: 'America/Chicago',
  },
  '77561': {
    zip: '77561',
    city: 'Hardin',
    state: 'TX',
    latitude: '30.152624',
    longitude: '-94.735691',
    timeZoneId: 'America/Chicago',
  },
  '77562': {
    zip: '77562',
    city: 'Highlands',
    state: 'TX',
    latitude: '29.819654',
    longitude: '-95.046222',
    timeZoneId: 'America/Chicago',
  },
  '77563': {
    zip: '77563',
    city: 'Hitchcock',
    state: 'TX',
    latitude: '29.342457',
    longitude: '-95.001043',
    timeZoneId: 'America/Chicago',
  },
  '77564': {
    zip: '77564',
    city: 'Hull',
    state: 'TX',
    latitude: '30.135928',
    longitude: '-94.652305',
    timeZoneId: 'America/Chicago',
  },
  '77565': {
    zip: '77565',
    city: 'Kemah',
    state: 'TX',
    latitude: '29.540679',
    longitude: '-95.030712',
    timeZoneId: 'America/Chicago',
  },
  '77566': {
    zip: '77566',
    city: 'Lake Jackson',
    state: 'TX',
    latitude: '29.041532',
    longitude: '-95.444189',
    timeZoneId: 'America/Chicago',
  },
  '77568': {
    zip: '77568',
    city: 'La Marque',
    state: 'TX',
    latitude: '29.365073',
    longitude: '-94.974666',
    timeZoneId: 'America/Chicago',
  },
  '77571': {
    zip: '77571',
    city: 'La Porte',
    state: 'TX',
    latitude: '29.658283',
    longitude: '-95.049187',
    timeZoneId: 'America/Chicago',
  },
  '77572': {
    zip: '77572',
    city: 'La Porte',
    state: 'TX',
    latitude: '29.664261',
    longitude: '-95.004094',
    timeZoneId: 'America/Chicago',
  },
  '77573': {
    zip: '77573',
    city: 'League City',
    state: 'TX',
    latitude: '29.503872',
    longitude: '-95.092521',
    timeZoneId: 'America/Chicago',
  },
  '77574': {
    zip: '77574',
    city: 'League City',
    state: 'TX',
    latitude: '29.513134',
    longitude: '-95.058105',
    timeZoneId: 'America/Chicago',
  },
  '77575': {
    zip: '77575',
    city: 'Liberty',
    state: 'TX',
    latitude: '30.067839',
    longitude: '-94.789652',
    timeZoneId: 'America/Chicago',
  },
  '77577': {
    zip: '77577',
    city: 'Liverpool',
    state: 'TX',
    latitude: '29.272215',
    longitude: '-95.255614',
    timeZoneId: 'America/Chicago',
  },
  '77578': {
    zip: '77578',
    city: 'Manvel',
    state: 'TX',
    latitude: '29.478816',
    longitude: '-95.352086',
    timeZoneId: 'America/Chicago',
  },
  '77580': {
    zip: '77580',
    city: 'Mont Belvieu',
    state: 'TX',
    latitude: '29.873761',
    longitude: '-94.855727',
    timeZoneId: 'America/Chicago',
  },
  '77581': {
    zip: '77581',
    city: 'Pearland',
    state: 'TX',
    latitude: '29.560076',
    longitude: '-95.275452',
    timeZoneId: 'America/Chicago',
  },
  '77582': {
    zip: '77582',
    city: 'Raywood',
    state: 'TX',
    latitude: '30.041095',
    longitude: '-94.674384',
    timeZoneId: 'America/Chicago',
  },
  '77583': {
    zip: '77583',
    city: 'Rosharon',
    state: 'TX',
    latitude: '29.408146',
    longitude: '-95.441666',
    timeZoneId: 'America/Chicago',
  },
  '77584': {
    zip: '77584',
    city: 'Pearland',
    state: 'TX',
    latitude: '29.544998',
    longitude: '-95.329534',
    timeZoneId: 'America/Chicago',
  },
  '77585': {
    zip: '77585',
    city: 'Saratoga',
    state: 'TX',
    latitude: '30.358816',
    longitude: '-94.559529',
    timeZoneId: 'America/Chicago',
  },
  '77586': {
    zip: '77586',
    city: 'Seabrook',
    state: 'TX',
    latitude: '29.57712',
    longitude: '-95.02122',
    timeZoneId: 'America/Chicago',
  },
  '77587': {
    zip: '77587',
    city: 'South Houston',
    state: 'TX',
    latitude: '29.664258',
    longitude: '-95.234301',
    timeZoneId: 'America/Chicago',
  },
  '77588': {
    zip: '77588',
    city: 'Pearland',
    state: 'TX',
    latitude: '29.55315',
    longitude: '-95.273774',
    timeZoneId: 'America/Chicago',
  },
  '77590': {
    zip: '77590',
    city: 'Texas City',
    state: 'TX',
    latitude: '29.39692',
    longitude: '-94.920831',
    timeZoneId: 'America/Chicago',
  },
  '77591': {
    zip: '77591',
    city: 'Texas City',
    state: 'TX',
    latitude: '29.386598',
    longitude: '-94.987485',
    timeZoneId: 'America/Chicago',
  },
  '77592': {
    zip: '77592',
    city: 'Texas City',
    state: 'TX',
    latitude: '29.508754',
    longitude: '-95.145277',
    timeZoneId: 'America/Chicago',
  },
  '77597': {
    zip: '77597',
    city: 'Wallisville',
    state: 'TX',
    latitude: '29.864771',
    longitude: '-94.692485',
    timeZoneId: 'America/Chicago',
  },
  '77598': {
    zip: '77598',
    city: 'Webster',
    state: 'TX',
    latitude: '29.540073',
    longitude: '-95.142762',
    timeZoneId: 'America/Chicago',
  },
  '77611': {
    zip: '77611',
    city: 'Bridge City',
    state: 'TX',
    latitude: '30.029508',
    longitude: '-93.839065',
    timeZoneId: 'America/Chicago',
  },
  '77612': {
    zip: '77612',
    city: 'Buna',
    state: 'TX',
    latitude: '30.293933',
    longitude: '-94.085399',
    timeZoneId: 'America/Chicago',
  },
  '77613': {
    zip: '77613',
    city: 'China',
    state: 'TX',
    latitude: '30.050864',
    longitude: '-94.306407',
    timeZoneId: 'America/Chicago',
  },
  '77614': {
    zip: '77614',
    city: 'Deweyville',
    state: 'TX',
    latitude: '30.493512',
    longitude: '-93.745014',
    timeZoneId: 'America/Chicago',
  },
  '77615': {
    zip: '77615',
    city: 'Evadale',
    state: 'TX',
    latitude: '30.353312',
    longitude: '-94.074008',
    timeZoneId: 'America/Chicago',
  },
  '77616': {
    zip: '77616',
    city: 'Fred',
    state: 'TX',
    latitude: '30.608112',
    longitude: '-94.186829',
    timeZoneId: 'America/Chicago',
  },
  '77617': {
    zip: '77617',
    city: 'Gilchrist',
    state: 'TX',
    latitude: '29.511982',
    longitude: '-94.490314',
    timeZoneId: 'America/Chicago',
  },
  '77619': {
    zip: '77619',
    city: 'Groves',
    state: 'TX',
    latitude: '29.942831',
    longitude: '-93.913486',
    timeZoneId: 'America/Chicago',
  },
  '77622': {
    zip: '77622',
    city: 'Hamshire',
    state: 'TX',
    latitude: '29.879658',
    longitude: '-94.31297',
    timeZoneId: 'America/Chicago',
  },
  '77623': {
    zip: '77623',
    city: 'High Island',
    state: 'TX',
    latitude: '29.569042',
    longitude: '-94.396675',
    timeZoneId: 'America/Chicago',
  },
  '77624': {
    zip: '77624',
    city: 'Hillister',
    state: 'TX',
    latitude: '30.68346',
    longitude: '-94.411035',
    timeZoneId: 'America/Chicago',
  },
  '77625': {
    zip: '77625',
    city: 'Kountze',
    state: 'TX',
    latitude: '30.3139',
    longitude: '-94.284907',
    timeZoneId: 'America/Chicago',
  },
  '77626': {
    zip: '77626',
    city: 'Mauriceville',
    state: 'TX',
    latitude: '30.202072',
    longitude: '-93.88464',
    timeZoneId: 'America/Chicago',
  },
  '77627': {
    zip: '77627',
    city: 'Nederland',
    state: 'TX',
    latitude: '29.975858',
    longitude: '-93.995675',
    timeZoneId: 'America/Chicago',
  },
  '77629': {
    zip: '77629',
    city: 'Nome',
    state: 'TX',
    latitude: '30.045115',
    longitude: '-94.37432',
    timeZoneId: 'America/Chicago',
  },
  '77630': {
    zip: '77630',
    city: 'Orange',
    state: 'TX',
    latitude: '30.081944',
    longitude: '-93.758056',
    timeZoneId: 'America/Chicago',
  },
  '77631': {
    zip: '77631',
    city: 'Orange',
    state: 'TX',
    latitude: '30.092765',
    longitude: '-93.73204',
    timeZoneId: 'America/Chicago',
  },
  '77632': {
    zip: '77632',
    city: 'Orange',
    state: 'TX',
    latitude: '30.16299',
    longitude: '-93.783644',
    timeZoneId: 'America/Chicago',
  },
  '77639': {
    zip: '77639',
    city: 'Orangefield',
    state: 'TX',
    latitude: '30.053212',
    longitude: '-93.856171',
    timeZoneId: 'America/Chicago',
  },
  '77640': {
    zip: '77640',
    city: 'Port Arthur',
    state: 'TX',
    latitude: '29.895365',
    longitude: '-93.955602',
    timeZoneId: 'America/Chicago',
  },
  '77641': {
    zip: '77641',
    city: 'Port Arthur',
    state: 'TX',
    latitude: '29.792326',
    longitude: '-93.934019',
    timeZoneId: 'America/Chicago',
  },
  '77642': {
    zip: '77642',
    city: 'Port Arthur',
    state: 'TX',
    latitude: '29.920768',
    longitude: '-93.931982',
    timeZoneId: 'America/Chicago',
  },
  '77643': {
    zip: '77643',
    city: 'Port Arthur',
    state: 'TX',
    latitude: '29.90075',
    longitude: '-93.931943',
    timeZoneId: 'America/Chicago',
  },
  '77650': {
    zip: '77650',
    city: 'Port Bolivar',
    state: 'TX',
    latitude: '29.449643',
    longitude: '-94.635997',
    timeZoneId: 'America/Chicago',
  },
  '77651': {
    zip: '77651',
    city: 'Port Neches',
    state: 'TX',
    latitude: '29.980985',
    longitude: '-93.961475',
    timeZoneId: 'America/Chicago',
  },
  '77655': {
    zip: '77655',
    city: 'Sabine Pass',
    state: 'TX',
    latitude: '29.725616',
    longitude: '-93.888258',
    timeZoneId: 'America/Chicago',
  },
  '77656': {
    zip: '77656',
    city: 'Silsbee',
    state: 'TX',
    latitude: '30.319984',
    longitude: '-94.189246',
    timeZoneId: 'America/Chicago',
  },
  '77657': {
    zip: '77657',
    city: 'Lumberton',
    state: 'TX',
    latitude: '30.249112',
    longitude: '-94.191872',
    timeZoneId: 'America/Chicago',
  },
  '77659': {
    zip: '77659',
    city: 'Sour Lake',
    state: 'TX',
    latitude: '30.1647',
    longitude: '-94.35096',
    timeZoneId: 'America/Chicago',
  },
  '77660': {
    zip: '77660',
    city: 'Spurger',
    state: 'TX',
    latitude: '30.726667',
    longitude: '-94.171785',
    timeZoneId: 'America/Chicago',
  },
  '77661': {
    zip: '77661',
    city: 'Stowell',
    state: 'TX',
    latitude: '29.788782',
    longitude: '-94.382618',
    timeZoneId: 'America/Chicago',
  },
  '77662': {
    zip: '77662',
    city: 'Vidor',
    state: 'TX',
    latitude: '30.145872',
    longitude: '-94.010918',
    timeZoneId: 'America/Chicago',
  },
  '77663': {
    zip: '77663',
    city: 'Village Mills',
    state: 'TX',
    latitude: '30.5',
    longitude: '-94.4',
    timeZoneId: 'America/Chicago',
  },
  '77664': {
    zip: '77664',
    city: 'Warren',
    state: 'TX',
    latitude: '30.636053',
    longitude: '-94.381639',
    timeZoneId: 'America/Chicago',
  },
  '77665': {
    zip: '77665',
    city: 'Winnie',
    state: 'TX',
    latitude: '29.797771',
    longitude: '-94.35119',
    timeZoneId: 'America/Chicago',
  },
  '77670': {
    zip: '77670',
    city: 'Vidor',
    state: 'TX',
    latitude: '30.12173',
    longitude: '-94.012004',
    timeZoneId: 'America/Chicago',
  },
  '77701': {
    zip: '77701',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.071367',
    longitude: '-94.103219',
    timeZoneId: 'America/Chicago',
  },
  '77702': {
    zip: '77702',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.085202',
    longitude: '-94.133166',
    timeZoneId: 'America/Chicago',
  },
  '77703': {
    zip: '77703',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.111926',
    longitude: '-94.118168',
    timeZoneId: 'America/Chicago',
  },
  '77704': {
    zip: '77704',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.072586',
    longitude: '-94.103117',
    timeZoneId: 'America/Chicago',
  },
  '77705': {
    zip: '77705',
    city: 'Beaumont',
    state: 'TX',
    latitude: '29.9694',
    longitude: '-94.1553',
    timeZoneId: 'America/Chicago',
  },
  '77706': {
    zip: '77706',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.094918',
    longitude: '-94.167107',
    timeZoneId: 'America/Chicago',
  },
  '77707': {
    zip: '77707',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.068757',
    longitude: '-94.173922',
    timeZoneId: 'America/Chicago',
  },
  '77708': {
    zip: '77708',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.146229',
    longitude: '-94.170262',
    timeZoneId: 'America/Chicago',
  },
  '77710': {
    zip: '77710',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.06887',
    longitude: '-94.099348',
    timeZoneId: 'America/Chicago',
  },
  '77713': {
    zip: '77713',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.146443',
    longitude: '-94.222869',
    timeZoneId: 'America/Chicago',
  },
  '77720': {
    zip: '77720',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.035549',
    longitude: '-94.159229',
    timeZoneId: 'America/Chicago',
  },
  '77725': {
    zip: '77725',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.087905',
    longitude: '-94.101933',
    timeZoneId: 'America/Chicago',
  },
  '77726': {
    zip: '77726',
    city: 'Beaumont',
    state: 'TX',
    latitude: '30.091114',
    longitude: '-94.145957',
    timeZoneId: 'America/Chicago',
  },
  '77801': {
    zip: '77801',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.88972',
    longitude: '-96.29445',
    timeZoneId: 'America/Chicago',
  },
  '77802': {
    zip: '77802',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.660096',
    longitude: '-96.332343',
    timeZoneId: 'America/Chicago',
  },
  '77803': {
    zip: '77803',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.681235',
    longitude: '-96.376593',
    timeZoneId: 'America/Chicago',
  },
  '77805': {
    zip: '77805',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.672945',
    longitude: '-96.34302',
    timeZoneId: 'America/Chicago',
  },
  '77806': {
    zip: '77806',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.675908',
    longitude: '-96.368282',
    timeZoneId: 'America/Chicago',
  },
  '77807': {
    zip: '77807',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.622933',
    longitude: '-96.385176',
    timeZoneId: 'America/Chicago',
  },
  '77808': {
    zip: '77808',
    city: 'Bryan',
    state: 'TX',
    latitude: '30.704715',
    longitude: '-96.335583',
    timeZoneId: 'America/Chicago',
  },
  '77830': {
    zip: '77830',
    city: 'Anderson',
    state: 'TX',
    latitude: '30.55632',
    longitude: '-96.01908',
    timeZoneId: 'America/Chicago',
  },
  '77831': {
    zip: '77831',
    city: 'Bedias',
    state: 'TX',
    latitude: '30.747332',
    longitude: '-95.921935',
    timeZoneId: 'America/Chicago',
  },
  '77833': {
    zip: '77833',
    city: 'Brenham',
    state: 'TX',
    latitude: '30.21684',
    longitude: '-96.39642',
    timeZoneId: 'America/Chicago',
  },
  '77834': {
    zip: '77834',
    city: 'Brenham',
    state: 'TX',
    latitude: '30.168111',
    longitude: '-96.397823',
    timeZoneId: 'America/Chicago',
  },
  '77835': {
    zip: '77835',
    city: 'Burton',
    state: 'TX',
    latitude: '30.176176',
    longitude: '-96.609965',
    timeZoneId: 'America/Chicago',
  },
  '77836': {
    zip: '77836',
    city: 'Caldwell',
    state: 'TX',
    latitude: '30.565402',
    longitude: '-96.749186',
    timeZoneId: 'America/Chicago',
  },
  '77837': {
    zip: '77837',
    city: 'Calvert',
    state: 'TX',
    latitude: '30.97617',
    longitude: '-96.667791',
    timeZoneId: 'America/Chicago',
  },
  '77838': {
    zip: '77838',
    city: 'Chriesman',
    state: 'TX',
    latitude: '30.600084',
    longitude: '-96.772451',
    timeZoneId: 'America/Chicago',
  },
  '77840': {
    zip: '77840',
    city: 'College Station',
    state: 'TX',
    latitude: '30.603583',
    longitude: '-96.311081',
    timeZoneId: 'America/Chicago',
  },
  '77841': {
    zip: '77841',
    city: 'College Station',
    state: 'TX',
    latitude: '30.572808',
    longitude: '-96.326388',
    timeZoneId: 'America/Chicago',
  },
  '77842': {
    zip: '77842',
    city: 'College Station',
    state: 'TX',
    latitude: '30.62996',
    longitude: '-96.333212',
    timeZoneId: 'America/Chicago',
  },
  '77843': {
    zip: '77843',
    city: 'College Station',
    state: 'TX',
    latitude: '30.611331',
    longitude: '-96.348373',
    timeZoneId: 'America/Chicago',
  },
  '77844': {
    zip: '77844',
    city: 'College Station',
    state: 'TX',
    latitude: '30.610719',
    longitude: '-96.354731',
    timeZoneId: 'America/Chicago',
  },
  '77845': {
    zip: '77845',
    city: 'College Station',
    state: 'TX',
    latitude: '30.583511',
    longitude: '-96.294277',
    timeZoneId: 'America/Chicago',
  },
  '77850': {
    zip: '77850',
    city: 'Concord',
    state: 'TX',
    latitude: '31.25732',
    longitude: '-96.117089',
    timeZoneId: 'America/Chicago',
  },
  '77852': {
    zip: '77852',
    city: 'Deanville',
    state: 'TX',
    latitude: '30.431374',
    longitude: '-96.757176',
    timeZoneId: 'America/Chicago',
  },
  '77853': {
    zip: '77853',
    city: 'Dime Box',
    state: 'TX',
    latitude: '30.170413',
    longitude: '-96.9797',
    timeZoneId: 'America/Chicago',
  },
  '77855': {
    zip: '77855',
    city: 'Flynn',
    state: 'TX',
    latitude: '31.149791',
    longitude: '-96.124432',
    timeZoneId: 'America/Chicago',
  },
  '77856': {
    zip: '77856',
    city: 'Franklin',
    state: 'TX',
    latitude: '31.029518',
    longitude: '-96.48534',
    timeZoneId: 'America/Chicago',
  },
  '77857': {
    zip: '77857',
    city: 'Gause',
    state: 'TX',
    latitude: '30.78383',
    longitude: '-96.722237',
    timeZoneId: 'America/Chicago',
  },
  '77859': {
    zip: '77859',
    city: 'Hearne',
    state: 'TX',
    latitude: '30.876381',
    longitude: '-96.596524',
    timeZoneId: 'America/Chicago',
  },
  '77861': {
    zip: '77861',
    city: 'Iola',
    state: 'TX',
    latitude: '30.676001',
    longitude: '-96.13081',
    timeZoneId: 'America/Chicago',
  },
  '77862': {
    zip: '77862',
    city: 'Kurten',
    state: 'TX',
    latitude: '30.784754',
    longitude: '-96.265159',
    timeZoneId: 'America/Chicago',
  },
  '77863': {
    zip: '77863',
    city: 'Lyons',
    state: 'TX',
    latitude: '30.387313',
    longitude: '-96.561285',
    timeZoneId: 'America/Chicago',
  },
  '77864': {
    zip: '77864',
    city: 'Madisonville',
    state: 'TX',
    latitude: '30.97554',
    longitude: '-95.9007',
    timeZoneId: 'America/Chicago',
  },
  '77865': {
    zip: '77865',
    city: 'Marquez',
    state: 'TX',
    latitude: '31.220641',
    longitude: '-96.236902',
    timeZoneId: 'America/Chicago',
  },
  '77866': {
    zip: '77866',
    city: 'Millican',
    state: 'TX',
    latitude: '30.465939',
    longitude: '-96.203773',
    timeZoneId: 'America/Chicago',
  },
  '77867': {
    zip: '77867',
    city: 'Mumford',
    state: 'TX',
    latitude: '30.731767',
    longitude: '-96.565295',
    timeZoneId: 'America/Chicago',
  },
  '77868': {
    zip: '77868',
    city: 'Navasota',
    state: 'TX',
    latitude: '30.388874',
    longitude: '-96.084543',
    timeZoneId: 'America/Chicago',
  },
  '77870': {
    zip: '77870',
    city: 'New Baden',
    state: 'TX',
    latitude: '31.049013',
    longitude: '-96.425612',
    timeZoneId: 'America/Chicago',
  },
  '77871': {
    zip: '77871',
    city: 'Normangee',
    state: 'TX',
    latitude: '31.08882',
    longitude: '-96.1533',
    timeZoneId: 'America/Chicago',
  },
  '77872': {
    zip: '77872',
    city: 'North Zulch',
    state: 'TX',
    latitude: '30.95352',
    longitude: '-96.09042',
    timeZoneId: 'America/Chicago',
  },
  '77873': {
    zip: '77873',
    city: 'Richards',
    state: 'TX',
    latitude: '30.548688',
    longitude: '-95.86284',
    timeZoneId: 'America/Chicago',
  },
  '77875': {
    zip: '77875',
    city: 'Roans Prairie',
    state: 'TX',
    latitude: '30.583507',
    longitude: '-95.941256',
    timeZoneId: 'America/Chicago',
  },
  '77876': {
    zip: '77876',
    city: 'Shiro',
    state: 'TX',
    latitude: '30.614572',
    longitude: '-95.886899',
    timeZoneId: 'America/Chicago',
  },
  '77878': {
    zip: '77878',
    city: 'Snook',
    state: 'TX',
    latitude: '30.488286',
    longitude: '-96.46453',
    timeZoneId: 'America/Chicago',
  },
  '77879': {
    zip: '77879',
    city: 'Somerville',
    state: 'TX',
    latitude: '30.335725',
    longitude: '-96.57142',
    timeZoneId: 'America/Chicago',
  },
  '77880': {
    zip: '77880',
    city: 'Washington',
    state: 'TX',
    latitude: '30.332626',
    longitude: '-96.22606',
    timeZoneId: 'America/Chicago',
  },
  '77881': {
    zip: '77881',
    city: 'Wellborn',
    state: 'TX',
    latitude: '30.536561',
    longitude: '-96.301446',
    timeZoneId: 'America/Chicago',
  },
  '77882': {
    zip: '77882',
    city: 'Wheelock',
    state: 'TX',
    latitude: '30.897142',
    longitude: '-96.390924',
    timeZoneId: 'America/Chicago',
  },
  '77901': {
    zip: '77901',
    city: 'Victoria',
    state: 'TX',
    latitude: '28.70675',
    longitude: '-97.18143',
    timeZoneId: 'America/Chicago',
  },
  '77902': {
    zip: '77902',
    city: 'Victoria',
    state: 'TX',
    latitude: '28.783592',
    longitude: '-96.996184',
    timeZoneId: 'America/Chicago',
  },
  '77903': {
    zip: '77903',
    city: 'Victoria',
    state: 'TX',
    latitude: '28.79488',
    longitude: '-97.004106',
    timeZoneId: 'America/Chicago',
  },
  '77904': {
    zip: '77904',
    city: 'Victoria',
    state: 'TX',
    latitude: '28.855498',
    longitude: '-97.003146',
    timeZoneId: 'America/Chicago',
  },
  '77905': {
    zip: '77905',
    city: 'Victoria',
    state: 'TX',
    latitude: '28.884742',
    longitude: '-97.170923',
    timeZoneId: 'America/Chicago',
  },
  '77950': {
    zip: '77950',
    city: 'Austwell',
    state: 'TX',
    latitude: '28.395158',
    longitude: '-96.973139',
    timeZoneId: 'America/Chicago',
  },
  '77951': {
    zip: '77951',
    city: 'Bloomington',
    state: 'TX',
    latitude: '28.648992',
    longitude: '-96.893563',
    timeZoneId: 'America/Chicago',
  },
  '77954': {
    zip: '77954',
    city: 'Cuero',
    state: 'TX',
    latitude: '29.10342',
    longitude: '-97.2813',
    timeZoneId: 'America/Chicago',
  },
  '77957': {
    zip: '77957',
    city: 'Edna',
    state: 'TX',
    latitude: '28.97418',
    longitude: '-96.64992',
    timeZoneId: 'America/Chicago',
  },
  '77960': {
    zip: '77960',
    city: 'Fannin',
    state: 'TX',
    latitude: '28.65621',
    longitude: '-97.422517',
    timeZoneId: 'America/Chicago',
  },
  '77961': {
    zip: '77961',
    city: 'Francitas',
    state: 'TX',
    latitude: '28.817096',
    longitude: '-96.445206',
    timeZoneId: 'America/Chicago',
  },
  '77962': {
    zip: '77962',
    city: 'Ganado',
    state: 'TX',
    latitude: '29.038785',
    longitude: '-96.513009',
    timeZoneId: 'America/Chicago',
  },
  '77963': {
    zip: '77963',
    city: 'Goliad',
    state: 'TX',
    latitude: '28.75746',
    longitude: '-97.42068',
    timeZoneId: 'America/Chicago',
  },
  '77964': {
    zip: '77964',
    city: 'Hallettsville',
    state: 'TX',
    latitude: '29.24861',
    longitude: '-96.70334',
    timeZoneId: 'America/Chicago',
  },
  '77967': {
    zip: '77967',
    city: 'Hochheim',
    state: 'TX',
    latitude: '29.241199',
    longitude: '-97.069618',
    timeZoneId: 'America/Chicago',
  },
  '77968': {
    zip: '77968',
    city: 'Inez',
    state: 'TX',
    latitude: '28.877985',
    longitude: '-96.832224',
    timeZoneId: 'America/Chicago',
  },
  '77969': {
    zip: '77969',
    city: 'La Salle',
    state: 'TX',
    latitude: '29.014273',
    longitude: '-96.683801',
    timeZoneId: 'America/Chicago',
  },
  '77970': {
    zip: '77970',
    city: 'La Ward',
    state: 'TX',
    latitude: '28.818428',
    longitude: '-96.442505',
    timeZoneId: 'America/Chicago',
  },
  '77971': {
    zip: '77971',
    city: 'Lolita',
    state: 'TX',
    latitude: '28.817071',
    longitude: '-96.440565',
    timeZoneId: 'America/Chicago',
  },
  '77973': {
    zip: '77973',
    city: 'McFaddin',
    state: 'TX',
    latitude: '28.720049',
    longitude: '-97.048372',
    timeZoneId: 'America/Chicago',
  },
  '77974': {
    zip: '77974',
    city: 'Meyersville',
    state: 'TX',
    latitude: '28.929757',
    longitude: '-97.302624',
    timeZoneId: 'America/Chicago',
  },
  '77975': {
    zip: '77975',
    city: 'Moulton',
    state: 'TX',
    latitude: '29.5689',
    longitude: '-97.08558',
    timeZoneId: 'America/Chicago',
  },
  '77976': {
    zip: '77976',
    city: 'Nursery',
    state: 'TX',
    latitude: '28.951978',
    longitude: '-96.983533',
    timeZoneId: 'America/Chicago',
  },
  '77977': {
    zip: '77977',
    city: 'Placedo',
    state: 'TX',
    latitude: '28.695758',
    longitude: '-96.826746',
    timeZoneId: 'America/Chicago',
  },
  '77978': {
    zip: '77978',
    city: 'Point Comfort',
    state: 'TX',
    latitude: '28.678919',
    longitude: '-96.556383',
    timeZoneId: 'America/Chicago',
  },
  '77979': {
    zip: '77979',
    city: 'Port Lavaca',
    state: 'TX',
    latitude: '28.617499',
    longitude: '-96.633202',
    timeZoneId: 'America/Chicago',
  },
  '77982': {
    zip: '77982',
    city: 'Port o Connor',
    state: 'TX',
    latitude: '28.22533',
    longitude: '-96.643456',
    timeZoneId: 'America/Chicago',
  },
  '77983': {
    zip: '77983',
    city: 'Seadrift',
    state: 'TX',
    latitude: '28.401205',
    longitude: '-96.657487',
    timeZoneId: 'America/Chicago',
  },
  '77984': {
    zip: '77984',
    city: 'Shiner',
    state: 'TX',
    latitude: '29.432036',
    longitude: '-97.17542',
    timeZoneId: 'America/Chicago',
  },
  '77986': {
    zip: '77986',
    city: 'Sublime',
    state: 'TX',
    latitude: '29.397544',
    longitude: '-96.823783',
    timeZoneId: 'America/Chicago',
  },
  '77987': {
    zip: '77987',
    city: 'Sweet Home',
    state: 'TX',
    latitude: '29.240475',
    longitude: '-97.068106',
    timeZoneId: 'America/Chicago',
  },
  '77988': {
    zip: '77988',
    city: 'Telferner',
    state: 'TX',
    latitude: '28.849598',
    longitude: '-96.887206',
    timeZoneId: 'America/Chicago',
  },
  '77989': {
    zip: '77989',
    city: 'Thomaston',
    state: 'TX',
    latitude: '29.096799',
    longitude: '-97.190375',
    timeZoneId: 'America/Chicago',
  },
  '77990': {
    zip: '77990',
    city: 'Tivoli',
    state: 'TX',
    latitude: '28.457266',
    longitude: '-96.89607',
    timeZoneId: 'America/Chicago',
  },
  '77991': {
    zip: '77991',
    city: 'Vanderbilt',
    state: 'TX',
    latitude: '29.009568',
    longitude: '-96.684356',
    timeZoneId: 'America/Chicago',
  },
  '77993': {
    zip: '77993',
    city: 'Weesatche',
    state: 'TX',
    latitude: '28.654748',
    longitude: '-97.426186',
    timeZoneId: 'America/Chicago',
  },
  '77994': {
    zip: '77994',
    city: 'Westhoff',
    state: 'TX',
    latitude: '29.080804',
    longitude: '-97.358612',
    timeZoneId: 'America/Chicago',
  },
  '77995': {
    zip: '77995',
    city: 'Yoakum',
    state: 'TX',
    latitude: '29.3208',
    longitude: '-97.12536',
    timeZoneId: 'America/Chicago',
  },
  '78001': {
    zip: '78001',
    city: 'Artesia Wells',
    state: 'TX',
    latitude: '28.280605',
    longitude: '-99.282621',
    timeZoneId: 'America/Chicago',
  },
  '78002': {
    zip: '78002',
    city: 'Atascosa',
    state: 'TX',
    latitude: '29.287174',
    longitude: '-98.733294',
    timeZoneId: 'America/Chicago',
  },
  '78003': {
    zip: '78003',
    city: 'Bandera',
    state: 'TX',
    latitude: '29.735076',
    longitude: '-99.04007',
    timeZoneId: 'America/Chicago',
  },
  '78004': {
    zip: '78004',
    city: 'Bergheim',
    state: 'TX',
    latitude: '29.841567',
    longitude: '-98.582498',
    timeZoneId: 'America/Chicago',
  },
  '78005': {
    zip: '78005',
    city: 'Bigfoot',
    state: 'TX',
    latitude: '29.214864',
    longitude: '-98.7088',
    timeZoneId: 'America/Chicago',
  },
  '78006': {
    zip: '78006',
    city: 'Boerne',
    state: 'TX',
    latitude: '29.71803',
    longitude: '-98.651343',
    timeZoneId: 'America/Chicago',
  },
  '78007': {
    zip: '78007',
    city: 'Calliham',
    state: 'TX',
    latitude: '28.345836',
    longitude: '-98.421438',
    timeZoneId: 'America/Chicago',
  },
  '78008': {
    zip: '78008',
    city: 'Campbellton',
    state: 'TX',
    latitude: '28.792111',
    longitude: '-98.245657',
    timeZoneId: 'America/Chicago',
  },
  '78009': {
    zip: '78009',
    city: 'Castroville',
    state: 'TX',
    latitude: '29.348707',
    longitude: '-98.887254',
    timeZoneId: 'America/Chicago',
  },
  '78010': {
    zip: '78010',
    city: 'Center Point',
    state: 'TX',
    latitude: '29.93562',
    longitude: '-99.01002',
    timeZoneId: 'America/Chicago',
  },
  '78011': {
    zip: '78011',
    city: 'Charlotte',
    state: 'TX',
    latitude: '28.818671',
    longitude: '-98.711561',
    timeZoneId: 'America/Chicago',
  },
  '78012': {
    zip: '78012',
    city: 'Christine',
    state: 'TX',
    latitude: '28.793868',
    longitude: '-98.512593',
    timeZoneId: 'America/Chicago',
  },
  '78013': {
    zip: '78013',
    city: 'Comfort',
    state: 'TX',
    latitude: '29.984861',
    longitude: '-98.839637',
    timeZoneId: 'America/Chicago',
  },
  '78014': {
    zip: '78014',
    city: 'Cotulla',
    state: 'TX',
    latitude: '28.424112',
    longitude: '-99.237016',
    timeZoneId: 'America/Chicago',
  },
  '78015': {
    zip: '78015',
    city: 'Boerne',
    state: 'TX',
    latitude: '29.801349',
    longitude: '-98.741246',
    timeZoneId: 'America/Chicago',
  },
  '78016': {
    zip: '78016',
    city: 'Devine',
    state: 'TX',
    latitude: '29.146284',
    longitude: '-98.910239',
    timeZoneId: 'America/Chicago',
  },
  '78017': {
    zip: '78017',
    city: 'Dilley',
    state: 'TX',
    latitude: '28.68912',
    longitude: '-99.19656',
    timeZoneId: 'America/Chicago',
  },
  '78019': {
    zip: '78019',
    city: 'Encinal',
    state: 'TX',
    latitude: '28.038753',
    longitude: '-99.353485',
    timeZoneId: 'America/Chicago',
  },
  '78021': {
    zip: '78021',
    city: 'Fowlerton',
    state: 'TX',
    latitude: '28.530703',
    longitude: '-98.832454',
    timeZoneId: 'America/Chicago',
  },
  '78022': {
    zip: '78022',
    city: 'George West',
    state: 'TX',
    latitude: '28.328968',
    longitude: '-98.118165',
    timeZoneId: 'America/Chicago',
  },
  '78023': {
    zip: '78023',
    city: 'Helotes',
    state: 'TX',
    latitude: '29.592512',
    longitude: '-98.715028',
    timeZoneId: 'America/Chicago',
  },
  '78024': {
    zip: '78024',
    city: 'Hunt',
    state: 'TX',
    latitude: '30.075707',
    longitude: '-99.324584',
    timeZoneId: 'America/Chicago',
  },
  '78025': {
    zip: '78025',
    city: 'Ingram',
    state: 'TX',
    latitude: '30.085405',
    longitude: '-99.243167',
    timeZoneId: 'America/Chicago',
  },
  '78026': {
    zip: '78026',
    city: 'Jourdanton',
    state: 'TX',
    latitude: '28.918592',
    longitude: '-98.54152',
    timeZoneId: 'America/Chicago',
  },
  '78027': {
    zip: '78027',
    city: 'Kendalia',
    state: 'TX',
    latitude: '29.941569',
    longitude: '-98.50591',
    timeZoneId: 'America/Chicago',
  },
  '78028': {
    zip: '78028',
    city: 'Kerrville',
    state: 'TX',
    latitude: '30.038845',
    longitude: '-99.151308',
    timeZoneId: 'America/Chicago',
  },
  '78029': {
    zip: '78029',
    city: 'Kerrville',
    state: 'TX',
    latitude: '30.061153',
    longitude: '-99.156092',
    timeZoneId: 'America/Chicago',
  },
  '78039': {
    zip: '78039',
    city: 'La Coste',
    state: 'TX',
    latitude: '29.316965',
    longitude: '-98.832569',
    timeZoneId: 'America/Chicago',
  },
  '78040': {
    zip: '78040',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.512693',
    longitude: '-99.488193',
    timeZoneId: 'America/Chicago',
  },
  '78041': {
    zip: '78041',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.549459',
    longitude: '-99.488974',
    timeZoneId: 'America/Chicago',
  },
  '78042': {
    zip: '78042',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.505646',
    longitude: '-99.507523',
    timeZoneId: 'America/Chicago',
  },
  '78043': {
    zip: '78043',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.49191',
    longitude: '-99.47171',
    timeZoneId: 'America/Chicago',
  },
  '78044': {
    zip: '78044',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.460208',
    longitude: '-99.488931',
    timeZoneId: 'America/Chicago',
  },
  '78045': {
    zip: '78045',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.594073',
    longitude: '-99.51285',
    timeZoneId: 'America/Chicago',
  },
  '78046': {
    zip: '78046',
    city: 'Laredo',
    state: 'TX',
    latitude: '27.43465',
    longitude: '-99.473074',
    timeZoneId: 'America/Chicago',
  },
  '78050': {
    zip: '78050',
    city: 'Leming',
    state: 'TX',
    latitude: '29.072213',
    longitude: '-98.485195',
    timeZoneId: 'America/Chicago',
  },
  '78052': {
    zip: '78052',
    city: 'Lytle',
    state: 'TX',
    latitude: '29.223945',
    longitude: '-98.788386',
    timeZoneId: 'America/Chicago',
  },
  '78054': {
    zip: '78054',
    city: 'Macdona',
    state: 'TX',
    latitude: '29.324013',
    longitude: '-98.693558',
    timeZoneId: 'America/Chicago',
  },
  '78055': {
    zip: '78055',
    city: 'Medina',
    state: 'TX',
    latitude: '29.938918',
    longitude: '-99.226717',
    timeZoneId: 'America/Chicago',
  },
  '78056': {
    zip: '78056',
    city: 'Mico',
    state: 'TX',
    latitude: '29.57964',
    longitude: '-98.87496',
    timeZoneId: 'America/Chicago',
  },
  '78057': {
    zip: '78057',
    city: 'Moore',
    state: 'TX',
    latitude: '29.000816',
    longitude: '-98.936703',
    timeZoneId: 'America/Chicago',
  },
  '78058': {
    zip: '78058',
    city: 'Mountain Home',
    state: 'TX',
    latitude: '30.238758',
    longitude: '-99.349106',
    timeZoneId: 'America/Chicago',
  },
  '78059': {
    zip: '78059',
    city: 'Natalia',
    state: 'TX',
    latitude: '29.18564',
    longitude: '-98.853745',
    timeZoneId: 'America/Chicago',
  },
  '78060': {
    zip: '78060',
    city: 'Oakville',
    state: 'TX',
    latitude: '28.46',
    longitude: '-98.182222',
    timeZoneId: 'America/Chicago',
  },
  '78061': {
    zip: '78061',
    city: 'Pearsall',
    state: 'TX',
    latitude: '28.891692',
    longitude: '-99.095347',
    timeZoneId: 'America/Chicago',
  },
  '78062': {
    zip: '78062',
    city: 'Peggy',
    state: 'TX',
    latitude: '28.741105',
    longitude: '-98.177857',
    timeZoneId: 'America/Chicago',
  },
  '78063': {
    zip: '78063',
    city: 'Pipe Creek',
    state: 'TX',
    latitude: '29.6568',
    longitude: '-98.90754',
    timeZoneId: 'America/Chicago',
  },
  '78064': {
    zip: '78064',
    city: 'Pleasanton',
    state: 'TX',
    latitude: '28.968189',
    longitude: '-98.487854',
    timeZoneId: 'America/Chicago',
  },
  '78065': {
    zip: '78065',
    city: 'Poteet',
    state: 'TX',
    latitude: '29.13128',
    longitude: '-98.534502',
    timeZoneId: 'America/Chicago',
  },
  '78066': {
    zip: '78066',
    city: 'Rio Medina',
    state: 'TX',
    latitude: '29.500765',
    longitude: '-98.869143',
    timeZoneId: 'America/Chicago',
  },
  '78067': {
    zip: '78067',
    city: 'San Ygnacio',
    state: 'TX',
    latitude: '27.038421',
    longitude: '-99.425687',
    timeZoneId: 'America/Chicago',
  },
  '78069': {
    zip: '78069',
    city: 'Somerset',
    state: 'TX',
    latitude: '29.197679',
    longitude: '-98.675868',
    timeZoneId: 'America/Chicago',
  },
  '78070': {
    zip: '78070',
    city: 'Spring Branch',
    state: 'TX',
    latitude: '29.839116',
    longitude: '-98.409301',
    timeZoneId: 'America/Chicago',
  },
  '78071': {
    zip: '78071',
    city: 'Three Rivers',
    state: 'TX',
    latitude: '28.356491',
    longitude: '-98.135804',
    timeZoneId: 'America/Chicago',
  },
  '78072': {
    zip: '78072',
    city: 'Tilden',
    state: 'TX',
    latitude: '28.347668',
    longitude: '-98.653364',
    timeZoneId: 'America/Chicago',
  },
  '78073': {
    zip: '78073',
    city: 'Von Ormy',
    state: 'TX',
    latitude: '29.254866',
    longitude: '-98.613495',
    timeZoneId: 'America/Chicago',
  },
  '78074': {
    zip: '78074',
    city: 'Waring',
    state: 'TX',
    latitude: '29.821529',
    longitude: '-98.752234',
    timeZoneId: 'America/Chicago',
  },
  '78075': {
    zip: '78075',
    city: 'Whitsett',
    state: 'TX',
    latitude: '28.633889',
    longitude: '-98.271667',
    timeZoneId: 'America/Chicago',
  },
  '78076': {
    zip: '78076',
    city: 'Zapata',
    state: 'TX',
    latitude: '26.896429',
    longitude: '-99.262993',
    timeZoneId: 'America/Chicago',
  },
  '78101': {
    zip: '78101',
    city: 'Adkins',
    state: 'TX',
    latitude: '29.349505',
    longitude: '-98.223103',
    timeZoneId: 'America/Chicago',
  },
  '78102': {
    zip: '78102',
    city: 'Beeville',
    state: 'TX',
    latitude: '28.403356',
    longitude: '-97.749054',
    timeZoneId: 'America/Chicago',
  },
  '78104': {
    zip: '78104',
    city: 'Beeville',
    state: 'TX',
    latitude: '28.41083',
    longitude: '-97.760692',
    timeZoneId: 'America/Chicago',
  },
  '78107': {
    zip: '78107',
    city: 'Berclair',
    state: 'TX',
    latitude: '28.532318',
    longitude: '-97.588001',
    timeZoneId: 'America/Chicago',
  },
  '78108': {
    zip: '78108',
    city: 'Cibolo',
    state: 'TX',
    latitude: '29.568245',
    longitude: '-98.229209',
    timeZoneId: 'America/Chicago',
  },
  '78109': {
    zip: '78109',
    city: 'Converse',
    state: 'TX',
    latitude: '29.478932',
    longitude: '-98.274069',
    timeZoneId: 'America/Chicago',
  },
  '78111': {
    zip: '78111',
    city: 'Ecleto',
    state: 'TX',
    latitude: '29.046111',
    longitude: '-97.751111',
    timeZoneId: 'America/Chicago',
  },
  '78112': {
    zip: '78112',
    city: 'Elmendorf',
    state: 'TX',
    latitude: '29.226523',
    longitude: '-98.36697',
    timeZoneId: 'America/Chicago',
  },
  '78113': {
    zip: '78113',
    city: 'Falls City',
    state: 'TX',
    latitude: '28.931521',
    longitude: '-98.063035',
    timeZoneId: 'America/Chicago',
  },
  '78114': {
    zip: '78114',
    city: 'Floresville',
    state: 'TX',
    latitude: '29.1601',
    longitude: '-98.179706',
    timeZoneId: 'America/Chicago',
  },
  '78115': {
    zip: '78115',
    city: 'Geronimo',
    state: 'TX',
    latitude: '29.610238',
    longitude: '-98.02557',
    timeZoneId: 'America/Chicago',
  },
  '78116': {
    zip: '78116',
    city: 'Gillett',
    state: 'TX',
    latitude: '29.076588',
    longitude: '-97.791088',
    timeZoneId: 'America/Chicago',
  },
  '78117': {
    zip: '78117',
    city: 'Hobson',
    state: 'TX',
    latitude: '28.936255',
    longitude: '-97.982346',
    timeZoneId: 'America/Chicago',
  },
  '78118': {
    zip: '78118',
    city: 'Karnes City',
    state: 'TX',
    latitude: '28.891304',
    longitude: '-97.900158',
    timeZoneId: 'America/Chicago',
  },
  '78119': {
    zip: '78119',
    city: 'Kenedy',
    state: 'TX',
    latitude: '28.813002',
    longitude: '-97.849725',
    timeZoneId: 'America/Chicago',
  },
  '78121': {
    zip: '78121',
    city: 'La Vernia',
    state: 'TX',
    latitude: '29.336135',
    longitude: '-98.095702',
    timeZoneId: 'America/Chicago',
  },
  '78122': {
    zip: '78122',
    city: 'Leesville',
    state: 'TX',
    latitude: '29.404362',
    longitude: '-97.744031',
    timeZoneId: 'America/Chicago',
  },
  '78123': {
    zip: '78123',
    city: 'Mc Queeney',
    state: 'TX',
    latitude: '29.605956',
    longitude: '-98.034172',
    timeZoneId: 'America/Chicago',
  },
  '78124': {
    zip: '78124',
    city: 'Marion',
    state: 'TX',
    latitude: '29.574239',
    longitude: '-98.150612',
    timeZoneId: 'America/Chicago',
  },
  '78125': {
    zip: '78125',
    city: 'Mineral',
    state: 'TX',
    latitude: '28.55032',
    longitude: '-97.903107',
    timeZoneId: 'America/Chicago',
  },
  '78130': {
    zip: '78130',
    city: 'New Braunfels',
    state: 'TX',
    latitude: '29.71962',
    longitude: '-98.0808',
    timeZoneId: 'America/Chicago',
  },
  '78131': {
    zip: '78131',
    city: 'New Braunfels',
    state: 'TX',
    latitude: '29.71498',
    longitude: '-98.143724',
    timeZoneId: 'America/Chicago',
  },
  '78132': {
    zip: '78132',
    city: 'New Braunfels',
    state: 'TX',
    latitude: '29.77722',
    longitude: '-98.15298',
    timeZoneId: 'America/Chicago',
  },
  '78133': {
    zip: '78133',
    city: 'Canyon Lake',
    state: 'TX',
    latitude: '29.8809',
    longitude: '-98.2152',
    timeZoneId: 'America/Chicago',
  },
  '78135': {
    zip: '78135',
    city: 'New Braunfels',
    state: 'TX',
    latitude: '29.703865',
    longitude: '-98.117523',
    timeZoneId: 'America/Chicago',
  },
  '78140': {
    zip: '78140',
    city: 'Nixon',
    state: 'TX',
    latitude: '29.272127',
    longitude: '-97.765242',
    timeZoneId: 'America/Chicago',
  },
  '78141': {
    zip: '78141',
    city: 'Nordheim',
    state: 'TX',
    latitude: '28.898681',
    longitude: '-97.563751',
    timeZoneId: 'America/Chicago',
  },
  '78142': {
    zip: '78142',
    city: 'Normanna',
    state: 'TX',
    latitude: '28.530161',
    longitude: '-97.783158',
    timeZoneId: 'America/Chicago',
  },
  '78143': {
    zip: '78143',
    city: 'Pandora',
    state: 'TX',
    latitude: '29.246576',
    longitude: '-97.834339',
    timeZoneId: 'America/Chicago',
  },
  '78144': {
    zip: '78144',
    city: 'Panna Maria',
    state: 'TX',
    latitude: '28.95585',
    longitude: '-97.896534',
    timeZoneId: 'America/Chicago',
  },
  '78145': {
    zip: '78145',
    city: 'Pawnee',
    state: 'TX',
    latitude: '28.654255',
    longitude: '-98.001202',
    timeZoneId: 'America/Chicago',
  },
  '78146': {
    zip: '78146',
    city: 'Pettus',
    state: 'TX',
    latitude: '28.614976',
    longitude: '-97.80304',
    timeZoneId: 'America/Chicago',
  },
  '78147': {
    zip: '78147',
    city: 'Poth',
    state: 'TX',
    latitude: '29.035954',
    longitude: '-98.100932',
    timeZoneId: 'America/Chicago',
  },
  '78148': {
    zip: '78148',
    city: 'Universal City',
    state: 'TX',
    latitude: '29.551704',
    longitude: '-98.302464',
    timeZoneId: 'America/Chicago',
  },
  '78150': {
    zip: '78150',
    city: 'Jbsa Randolph',
    state: 'TX',
    latitude: '29.527918',
    longitude: '-98.274563',
    timeZoneId: 'America/Chicago',
  },
  '78151': {
    zip: '78151',
    city: 'Runge',
    state: 'TX',
    latitude: '28.87635',
    longitude: '-97.704928',
    timeZoneId: 'America/Chicago',
  },
  '78152': {
    zip: '78152',
    city: 'Saint Hedwig',
    state: 'TX',
    latitude: '29.436023',
    longitude: '-98.201979',
    timeZoneId: 'America/Chicago',
  },
  '78154': {
    zip: '78154',
    city: 'Schertz',
    state: 'TX',
    latitude: '29.587509',
    longitude: '-98.288298',
    timeZoneId: 'America/Chicago',
  },
  '78155': {
    zip: '78155',
    city: 'Seguin',
    state: 'TX',
    latitude: '29.56666',
    longitude: '-97.965413',
    timeZoneId: 'America/Chicago',
  },
  '78156': {
    zip: '78156',
    city: 'Seguin',
    state: 'TX',
    latitude: '29.567321',
    longitude: '-97.930858',
    timeZoneId: 'America/Chicago',
  },
  '78159': {
    zip: '78159',
    city: 'Smiley',
    state: 'TX',
    latitude: '29.241001',
    longitude: '-97.593291',
    timeZoneId: 'America/Chicago',
  },
  '78160': {
    zip: '78160',
    city: 'Stockdale',
    state: 'TX',
    latitude: '29.232249',
    longitude: '-97.963196',
    timeZoneId: 'America/Chicago',
  },
  '78161': {
    zip: '78161',
    city: 'Sutherland Springs',
    state: 'TX',
    latitude: '29.263031',
    longitude: '-98.06752',
    timeZoneId: 'America/Chicago',
  },
  '78162': {
    zip: '78162',
    city: 'Tuleta',
    state: 'TX',
    latitude: '28.571375',
    longitude: '-97.796871',
    timeZoneId: 'America/Chicago',
  },
  '78163': {
    zip: '78163',
    city: 'Bulverde',
    state: 'TX',
    latitude: '29.679028',
    longitude: '-98.411488',
    timeZoneId: 'America/Chicago',
  },
  '78164': {
    zip: '78164',
    city: 'Yorktown',
    state: 'TX',
    latitude: '28.980914',
    longitude: '-97.505632',
    timeZoneId: 'America/Chicago',
  },
  '78201': {
    zip: '78201',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.46724',
    longitude: '-98.530042',
    timeZoneId: 'America/Chicago',
  },
  '78202': {
    zip: '78202',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.421825',
    longitude: '-98.465817',
    timeZoneId: 'America/Chicago',
  },
  '78203': {
    zip: '78203',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.414826',
    longitude: '-98.462021',
    timeZoneId: 'America/Chicago',
  },
  '78204': {
    zip: '78204',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.396742',
    longitude: '-98.499996',
    timeZoneId: 'America/Chicago',
  },
  '78205': {
    zip: '78205',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.423472',
    longitude: '-98.487398',
    timeZoneId: 'America/Chicago',
  },
  '78206': {
    zip: '78206',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.416807',
    longitude: '-98.498525',
    timeZoneId: 'America/Chicago',
  },
  '78207': {
    zip: '78207',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.421586',
    longitude: '-98.523179',
    timeZoneId: 'America/Chicago',
  },
  '78208': {
    zip: '78208',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.438357',
    longitude: '-98.457025',
    timeZoneId: 'America/Chicago',
  },
  '78209': {
    zip: '78209',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.484722',
    longitude: '-98.465556',
    timeZoneId: 'America/Chicago',
  },
  '78210': {
    zip: '78210',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.399131',
    longitude: '-98.47039',
    timeZoneId: 'America/Chicago',
  },
  '78211': {
    zip: '78211',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.357305',
    longitude: '-98.559866',
    timeZoneId: 'America/Chicago',
  },
  '78212': {
    zip: '78212',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.478611',
    longitude: '-98.487222',
    timeZoneId: 'America/Chicago',
  },
  '78213': {
    zip: '78213',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.512114',
    longitude: '-98.523892',
    timeZoneId: 'America/Chicago',
  },
  '78214': {
    zip: '78214',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.365513',
    longitude: '-98.487443',
    timeZoneId: 'America/Chicago',
  },
  '78215': {
    zip: '78215',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.437563',
    longitude: '-98.481061',
    timeZoneId: 'America/Chicago',
  },
  '78216': {
    zip: '78216',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.533684',
    longitude: '-98.489444',
    timeZoneId: 'America/Chicago',
  },
  '78217': {
    zip: '78217',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.543976',
    longitude: '-98.419822',
    timeZoneId: 'America/Chicago',
  },
  '78218': {
    zip: '78218',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.5013',
    longitude: '-98.397733',
    timeZoneId: 'America/Chicago',
  },
  '78219': {
    zip: '78219',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.44692',
    longitude: '-98.38098',
    timeZoneId: 'America/Chicago',
  },
  '78220': {
    zip: '78220',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.411125',
    longitude: '-98.40384',
    timeZoneId: 'America/Chicago',
  },
  '78221': {
    zip: '78221',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.319342',
    longitude: '-98.49625',
    timeZoneId: 'America/Chicago',
  },
  '78222': {
    zip: '78222',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.386912',
    longitude: '-98.391814',
    timeZoneId: 'America/Chicago',
  },
  '78223': {
    zip: '78223',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.321261',
    longitude: '-98.409516',
    timeZoneId: 'America/Chicago',
  },
  '78224': {
    zip: '78224',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.31201',
    longitude: '-98.539722',
    timeZoneId: 'America/Chicago',
  },
  '78225': {
    zip: '78225',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.388465',
    longitude: '-98.531438',
    timeZoneId: 'America/Chicago',
  },
  '78226': {
    zip: '78226',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.396389',
    longitude: '-98.546677',
    timeZoneId: 'America/Chicago',
  },
  '78227': {
    zip: '78227',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.41514',
    longitude: '-98.635627',
    timeZoneId: 'America/Chicago',
  },
  '78228': {
    zip: '78228',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.459623',
    longitude: '-98.574755',
    timeZoneId: 'America/Chicago',
  },
  '78229': {
    zip: '78229',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.501242',
    longitude: '-98.573979',
    timeZoneId: 'America/Chicago',
  },
  '78230': {
    zip: '78230',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.539652',
    longitude: '-98.551914',
    timeZoneId: 'America/Chicago',
  },
  '78231': {
    zip: '78231',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.5884',
    longitude: '-98.54991',
    timeZoneId: 'America/Chicago',
  },
  '78232': {
    zip: '78232',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.584537',
    longitude: '-98.475424',
    timeZoneId: 'America/Chicago',
  },
  '78233': {
    zip: '78233',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.565',
    longitude: '-98.336111',
    timeZoneId: 'America/Chicago',
  },
  '78234': {
    zip: '78234',
    city: 'Jbsa Ft Sam Houston',
    state: 'TX',
    latitude: '29.457977',
    longitude: '-98.493044',
    timeZoneId: 'America/Chicago',
  },
  '78235': {
    zip: '78235',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.341979',
    longitude: '-98.442498',
    timeZoneId: 'America/Chicago',
  },
  '78236': {
    zip: '78236',
    city: 'Jbsa Lackland',
    state: 'TX',
    latitude: '29.404744',
    longitude: '-98.61264',
    timeZoneId: 'America/Chicago',
  },
  '78237': {
    zip: '78237',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.422667',
    longitude: '-98.565747',
    timeZoneId: 'America/Chicago',
  },
  '78238': {
    zip: '78238',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.47999',
    longitude: '-98.61033',
    timeZoneId: 'America/Chicago',
  },
  '78239': {
    zip: '78239',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.511329',
    longitude: '-98.367675',
    timeZoneId: 'America/Chicago',
  },
  '78240': {
    zip: '78240',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.49858',
    longitude: '-98.60574',
    timeZoneId: 'America/Chicago',
  },
  '78241': {
    zip: '78241',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.376814',
    longitude: '-98.546634',
    timeZoneId: 'America/Chicago',
  },
  '78242': {
    zip: '78242',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.353568',
    longitude: '-98.609845',
    timeZoneId: 'America/Chicago',
  },
  '78243': {
    zip: '78243',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.381316',
    longitude: '-98.579896',
    timeZoneId: 'America/Chicago',
  },
  '78244': {
    zip: '78244',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.479985',
    longitude: '-98.352567',
    timeZoneId: 'America/Chicago',
  },
  '78245': {
    zip: '78245',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.406773',
    longitude: '-98.734865',
    timeZoneId: 'America/Chicago',
  },
  '78246': {
    zip: '78246',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.53529',
    longitude: '-98.4862',
    timeZoneId: 'America/Chicago',
  },
  '78247': {
    zip: '78247',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.588133',
    longitude: '-98.402025',
    timeZoneId: 'America/Chicago',
  },
  '78248': {
    zip: '78248',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.584143',
    longitude: '-98.523204',
    timeZoneId: 'America/Chicago',
  },
  '78249': {
    zip: '78249',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.567119',
    longitude: '-98.605723',
    timeZoneId: 'America/Chicago',
  },
  '78250': {
    zip: '78250',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.50803',
    longitude: '-98.664842',
    timeZoneId: 'America/Chicago',
  },
  '78251': {
    zip: '78251',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.459501',
    longitude: '-98.669147',
    timeZoneId: 'America/Chicago',
  },
  '78252': {
    zip: '78252',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.349236',
    longitude: '-98.694536',
    timeZoneId: 'America/Chicago',
  },
  '78253': {
    zip: '78253',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.459263',
    longitude: '-98.747046',
    timeZoneId: 'America/Chicago',
  },
  '78254': {
    zip: '78254',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.536152',
    longitude: '-98.72416',
    timeZoneId: 'America/Chicago',
  },
  '78255': {
    zip: '78255',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.643943',
    longitude: '-98.664338',
    timeZoneId: 'America/Chicago',
  },
  '78256': {
    zip: '78256',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.623424',
    longitude: '-98.624731',
    timeZoneId: 'America/Chicago',
  },
  '78257': {
    zip: '78257',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.656582',
    longitude: '-98.617388',
    timeZoneId: 'America/Chicago',
  },
  '78258': {
    zip: '78258',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.665238',
    longitude: '-98.502296',
    timeZoneId: 'America/Chicago',
  },
  '78259': {
    zip: '78259',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.626494',
    longitude: '-98.445839',
    timeZoneId: 'America/Chicago',
  },
  '78260': {
    zip: '78260',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.70729',
    longitude: '-98.483007',
    timeZoneId: 'America/Chicago',
  },
  '78261': {
    zip: '78261',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.706348',
    longitude: '-98.398494',
    timeZoneId: 'America/Chicago',
  },
  '78263': {
    zip: '78263',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.354295',
    longitude: '-98.331761',
    timeZoneId: 'America/Chicago',
  },
  '78264': {
    zip: '78264',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.192675',
    longitude: '-98.517416',
    timeZoneId: 'America/Chicago',
  },
  '78265': {
    zip: '78265',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.536959',
    longitude: '-98.421181',
    timeZoneId: 'America/Chicago',
  },
  '78266': {
    zip: '78266',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.630375',
    longitude: '-98.305497',
    timeZoneId: 'America/Chicago',
  },
  '78268': {
    zip: '78268',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.470275',
    longitude: '-98.617172',
    timeZoneId: 'America/Chicago',
  },
  '78269': {
    zip: '78269',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.567517',
    longitude: '-98.61548',
    timeZoneId: 'America/Chicago',
  },
  '78270': {
    zip: '78270',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.588376',
    longitude: '-98.473568',
    timeZoneId: 'America/Chicago',
  },
  '78278': {
    zip: '78278',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.562987',
    longitude: '-98.562275',
    timeZoneId: 'America/Chicago',
  },
  '78279': {
    zip: '78279',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.533211',
    longitude: '-98.491012',
    timeZoneId: 'America/Chicago',
  },
  '78280': {
    zip: '78280',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.561734',
    longitude: '-98.355891',
    timeZoneId: 'America/Chicago',
  },
  '78283': {
    zip: '78283',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.411113',
    longitude: '-98.503666',
    timeZoneId: 'America/Chicago',
  },
  '78284': {
    zip: '78284',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.472455',
    longitude: '-98.518993',
    timeZoneId: 'America/Chicago',
  },
  '78285': {
    zip: '78285',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.422258',
    longitude: '-98.525584',
    timeZoneId: 'America/Chicago',
  },
  '78288': {
    zip: '78288',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.425828',
    longitude: '-98.491586',
    timeZoneId: 'America/Chicago',
  },
  '78289': {
    zip: '78289',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.532254',
    longitude: '-98.412276',
    timeZoneId: 'America/Chicago',
  },
  '78291': {
    zip: '78291',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.425516',
    longitude: '-98.488267',
    timeZoneId: 'America/Chicago',
  },
  '78292': {
    zip: '78292',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.425547',
    longitude: '-98.485755',
    timeZoneId: 'America/Chicago',
  },
  '78293': {
    zip: '78293',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.425368',
    longitude: '-98.486732',
    timeZoneId: 'America/Chicago',
  },
  '78294': {
    zip: '78294',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.427115',
    longitude: '-98.486051',
    timeZoneId: 'America/Chicago',
  },
  '78295': {
    zip: '78295',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.428181',
    longitude: '-98.488309',
    timeZoneId: 'America/Chicago',
  },
  '78296': {
    zip: '78296',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.427516',
    longitude: '-98.487585',
    timeZoneId: 'America/Chicago',
  },
  '78297': {
    zip: '78297',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.425414',
    longitude: '-98.486372',
    timeZoneId: 'America/Chicago',
  },
  '78298': {
    zip: '78298',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.457351',
    longitude: '-98.501991',
    timeZoneId: 'America/Chicago',
  },
  '78299': {
    zip: '78299',
    city: 'San Antonio',
    state: 'TX',
    latitude: '29.42543',
    longitude: '-98.486926',
    timeZoneId: 'America/Chicago',
  },
  '78330': {
    zip: '78330',
    city: 'Agua Dulce',
    state: 'TX',
    latitude: '27.78246',
    longitude: '-97.909035',
    timeZoneId: 'America/Chicago',
  },
  '78332': {
    zip: '78332',
    city: 'Alice',
    state: 'TX',
    latitude: '27.74268',
    longitude: '-98.0859',
    timeZoneId: 'America/Chicago',
  },
  '78333': {
    zip: '78333',
    city: 'Alice',
    state: 'TX',
    latitude: '27.742522',
    longitude: '-98.073138',
    timeZoneId: 'America/Chicago',
  },
  '78335': {
    zip: '78335',
    city: 'Aransas Pass',
    state: 'TX',
    latitude: '27.909394',
    longitude: '-97.150015',
    timeZoneId: 'America/Chicago',
  },
  '78336': {
    zip: '78336',
    city: 'Aransas Pass',
    state: 'TX',
    latitude: '27.904408',
    longitude: '-97.160091',
    timeZoneId: 'America/Chicago',
  },
  '78338': {
    zip: '78338',
    city: 'Armstrong',
    state: 'TX',
    latitude: '26.826414',
    longitude: '-97.750931',
    timeZoneId: 'America/Chicago',
  },
  '78339': {
    zip: '78339',
    city: 'Banquete',
    state: 'TX',
    latitude: '27.913915',
    longitude: '-97.778587',
    timeZoneId: 'America/Chicago',
  },
  '78340': {
    zip: '78340',
    city: 'Bayside',
    state: 'TX',
    latitude: '28.099979',
    longitude: '-97.203803',
    timeZoneId: 'America/Chicago',
  },
  '78341': {
    zip: '78341',
    city: 'Benavides',
    state: 'TX',
    latitude: '27.526925',
    longitude: '-98.381021',
    timeZoneId: 'America/Chicago',
  },
  '78342': {
    zip: '78342',
    city: 'Ben Bolt',
    state: 'TX',
    latitude: '27.751944',
    longitude: '-98.069444',
    timeZoneId: 'America/Chicago',
  },
  '78343': {
    zip: '78343',
    city: 'Bishop',
    state: 'TX',
    latitude: '27.585618',
    longitude: '-97.797045',
    timeZoneId: 'America/Chicago',
  },
  '78344': {
    zip: '78344',
    city: 'Bruni',
    state: 'TX',
    latitude: '27.418495',
    longitude: '-98.853745',
    timeZoneId: 'America/Chicago',
  },
  '78347': {
    zip: '78347',
    city: 'Chapman Ranch',
    state: 'TX',
    latitude: '27.635423',
    longitude: '-97.45341',
    timeZoneId: 'America/Chicago',
  },
  '78349': {
    zip: '78349',
    city: 'Concepcion',
    state: 'TX',
    latitude: '27.531404',
    longitude: '-98.383724',
    timeZoneId: 'America/Chicago',
  },
  '78350': {
    zip: '78350',
    city: 'Dinero',
    state: 'TX',
    latitude: '28.2261',
    longitude: '-97.96128',
    timeZoneId: 'America/Chicago',
  },
  '78351': {
    zip: '78351',
    city: 'Driscoll',
    state: 'TX',
    latitude: '27.642001',
    longitude: '-97.770569',
    timeZoneId: 'America/Chicago',
  },
  '78352': {
    zip: '78352',
    city: 'Edroy',
    state: 'TX',
    latitude: '27.956153',
    longitude: '-97.579874',
    timeZoneId: 'America/Chicago',
  },
  '78353': {
    zip: '78353',
    city: 'Encino',
    state: 'TX',
    latitude: '26.983969',
    longitude: '-98.209608',
    timeZoneId: 'America/Chicago',
  },
  '78355': {
    zip: '78355',
    city: 'Falfurrias',
    state: 'TX',
    latitude: '27.22536',
    longitude: '-98.1437',
    timeZoneId: 'America/Chicago',
  },
  '78357': {
    zip: '78357',
    city: 'Freer',
    state: 'TX',
    latitude: '27.861524',
    longitude: '-98.563007',
    timeZoneId: 'America/Chicago',
  },
  '78358': {
    zip: '78358',
    city: 'Fulton',
    state: 'TX',
    latitude: '28.07983',
    longitude: '-97.03203',
    timeZoneId: 'America/Chicago',
  },
  '78359': {
    zip: '78359',
    city: 'Gregory',
    state: 'TX',
    latitude: '27.921211',
    longitude: '-97.289753',
    timeZoneId: 'America/Chicago',
  },
  '78360': {
    zip: '78360',
    city: 'Guerra',
    state: 'TX',
    latitude: '26.926237',
    longitude: '-98.886303',
    timeZoneId: 'America/Chicago',
  },
  '78361': {
    zip: '78361',
    city: 'Hebbronville',
    state: 'TX',
    latitude: '27.283639',
    longitude: '-98.691312',
    timeZoneId: 'America/Chicago',
  },
  '78362': {
    zip: '78362',
    city: 'Ingleside',
    state: 'TX',
    latitude: '27.869269',
    longitude: '-97.204245',
    timeZoneId: 'America/Chicago',
  },
  '78363': {
    zip: '78363',
    city: 'Kingsville',
    state: 'TX',
    latitude: '27.514425',
    longitude: '-97.86085',
    timeZoneId: 'America/Chicago',
  },
  '78364': {
    zip: '78364',
    city: 'Kingsville',
    state: 'TX',
    latitude: '27.524434',
    longitude: '-97.868903',
    timeZoneId: 'America/Chicago',
  },
  '78368': {
    zip: '78368',
    city: 'Mathis',
    state: 'TX',
    latitude: '28.091531',
    longitude: '-97.831561',
    timeZoneId: 'America/Chicago',
  },
  '78369': {
    zip: '78369',
    city: 'Mirando City',
    state: 'TX',
    latitude: '27.441138',
    longitude: '-99.02421',
    timeZoneId: 'America/Chicago',
  },
  '78370': {
    zip: '78370',
    city: 'Odem',
    state: 'TX',
    latitude: '27.947711',
    longitude: '-97.586188',
    timeZoneId: 'America/Chicago',
  },
  '78371': {
    zip: '78371',
    city: 'Oilton',
    state: 'TX',
    latitude: '27.42036',
    longitude: '-98.85603',
    timeZoneId: 'America/Chicago',
  },
  '78372': {
    zip: '78372',
    city: 'Orange Grove',
    state: 'TX',
    latitude: '27.951121',
    longitude: '-98.073418',
    timeZoneId: 'America/Chicago',
  },
  '78373': {
    zip: '78373',
    city: 'Port Aransas',
    state: 'TX',
    latitude: '27.825283',
    longitude: '-97.064208',
    timeZoneId: 'America/Chicago',
  },
  '78374': {
    zip: '78374',
    city: 'Portland',
    state: 'TX',
    latitude: '27.881212',
    longitude: '-97.31869',
    timeZoneId: 'America/Chicago',
  },
  '78375': {
    zip: '78375',
    city: 'Premont',
    state: 'TX',
    latitude: '27.355708',
    longitude: '-98.129917',
    timeZoneId: 'America/Chicago',
  },
  '78376': {
    zip: '78376',
    city: 'Realitos',
    state: 'TX',
    latitude: '27.518196',
    longitude: '-98.63746',
    timeZoneId: 'America/Chicago',
  },
  '78377': {
    zip: '78377',
    city: 'Refugio',
    state: 'TX',
    latitude: '28.298118',
    longitude: '-97.281607',
    timeZoneId: 'America/Chicago',
  },
  '78379': {
    zip: '78379',
    city: 'Riviera',
    state: 'TX',
    latitude: '27.31938',
    longitude: '-97.86252',
    timeZoneId: 'America/Chicago',
  },
  '78380': {
    zip: '78380',
    city: 'Robstown',
    state: 'TX',
    latitude: '27.76248',
    longitude: '-97.74258',
    timeZoneId: 'America/Chicago',
  },
  '78381': {
    zip: '78381',
    city: 'Rockport',
    state: 'TX',
    latitude: '28.007465',
    longitude: '-97.060724',
    timeZoneId: 'America/Chicago',
  },
  '78382': {
    zip: '78382',
    city: 'Rockport',
    state: 'TX',
    latitude: '28.02228',
    longitude: '-97.09908',
    timeZoneId: 'America/Chicago',
  },
  '78383': {
    zip: '78383',
    city: 'Sandia',
    state: 'TX',
    latitude: '28.08942',
    longitude: '-97.9422',
    timeZoneId: 'America/Chicago',
  },
  '78384': {
    zip: '78384',
    city: 'San Diego',
    state: 'TX',
    latitude: '27.760852',
    longitude: '-98.243736',
    timeZoneId: 'America/Chicago',
  },
  '78385': {
    zip: '78385',
    city: 'Sarita',
    state: 'TX',
    latitude: '27.153295',
    longitude: '-97.691283',
    timeZoneId: 'America/Chicago',
  },
  '78387': {
    zip: '78387',
    city: 'Sinton',
    state: 'TX',
    latitude: '28.033412',
    longitude: '-97.505169',
    timeZoneId: 'America/Chicago',
  },
  '78389': {
    zip: '78389',
    city: 'Skidmore',
    state: 'TX',
    latitude: '28.254041',
    longitude: '-97.650357',
    timeZoneId: 'America/Chicago',
  },
  '78390': {
    zip: '78390',
    city: 'Taft',
    state: 'TX',
    latitude: '27.975862',
    longitude: '-97.394258',
    timeZoneId: 'America/Chicago',
  },
  '78391': {
    zip: '78391',
    city: 'Tynan',
    state: 'TX',
    latitude: '28.171961',
    longitude: '-97.74824',
    timeZoneId: 'America/Chicago',
  },
  '78393': {
    zip: '78393',
    city: 'Woodsboro',
    state: 'TX',
    latitude: '28.1675',
    longitude: '-97.299722',
    timeZoneId: 'America/Chicago',
  },
  '78401': {
    zip: '78401',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.798095',
    longitude: '-97.404668',
    timeZoneId: 'America/Chicago',
  },
  '78402': {
    zip: '78402',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.824407',
    longitude: '-97.388526',
    timeZoneId: 'America/Chicago',
  },
  '78403': {
    zip: '78403',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.786386',
    longitude: '-97.395977',
    timeZoneId: 'America/Chicago',
  },
  '78404': {
    zip: '78404',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.766532',
    longitude: '-97.401972',
    timeZoneId: 'America/Chicago',
  },
  '78405': {
    zip: '78405',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.776027',
    longitude: '-97.428025',
    timeZoneId: 'America/Chicago',
  },
  '78406': {
    zip: '78406',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.777491',
    longitude: '-97.516057',
    timeZoneId: 'America/Chicago',
  },
  '78407': {
    zip: '78407',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.805931',
    longitude: '-97.436894',
    timeZoneId: 'America/Chicago',
  },
  '78408': {
    zip: '78408',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.797109',
    longitude: '-97.435885',
    timeZoneId: 'America/Chicago',
  },
  '78409': {
    zip: '78409',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.811342',
    longitude: '-97.516007',
    timeZoneId: 'America/Chicago',
  },
  '78410': {
    zip: '78410',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.837609',
    longitude: '-97.590692',
    timeZoneId: 'America/Chicago',
  },
  '78411': {
    zip: '78411',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.743325',
    longitude: '-97.390984',
    timeZoneId: 'America/Chicago',
  },
  '78412': {
    zip: '78412',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.705823',
    longitude: '-97.348066',
    timeZoneId: 'America/Chicago',
  },
  '78413': {
    zip: '78413',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.687141',
    longitude: '-97.40047',
    timeZoneId: 'America/Chicago',
  },
  '78414': {
    zip: '78414',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.67126',
    longitude: '-97.36806',
    timeZoneId: 'America/Chicago',
  },
  '78415': {
    zip: '78415',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.733183',
    longitude: '-97.414174',
    timeZoneId: 'America/Chicago',
  },
  '78416': {
    zip: '78416',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.751777',
    longitude: '-97.435129',
    timeZoneId: 'America/Chicago',
  },
  '78417': {
    zip: '78417',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.728238',
    longitude: '-97.443545',
    timeZoneId: 'America/Chicago',
  },
  '78418': {
    zip: '78418',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.641172',
    longitude: '-97.277723',
    timeZoneId: 'America/Chicago',
  },
  '78419': {
    zip: '78419',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.693737',
    longitude: '-97.264702',
    timeZoneId: 'America/Chicago',
  },
  '78426': {
    zip: '78426',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.840566',
    longitude: '-97.597016',
    timeZoneId: 'America/Chicago',
  },
  '78427': {
    zip: '78427',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.795288',
    longitude: '-97.403051',
    timeZoneId: 'America/Chicago',
  },
  '78460': {
    zip: '78460',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.831211',
    longitude: '-97.596211',
    timeZoneId: 'America/Chicago',
  },
  '78463': {
    zip: '78463',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.774768',
    longitude: '-97.396997',
    timeZoneId: 'America/Chicago',
  },
  '78465': {
    zip: '78465',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.776147',
    longitude: '-97.419137',
    timeZoneId: 'America/Chicago',
  },
  '78466': {
    zip: '78466',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.74404',
    longitude: '-97.381702',
    timeZoneId: 'America/Chicago',
  },
  '78467': {
    zip: '78467',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.748683',
    longitude: '-97.420344',
    timeZoneId: 'America/Chicago',
  },
  '78468': {
    zip: '78468',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.706826',
    longitude: '-97.355976',
    timeZoneId: 'America/Chicago',
  },
  '78469': {
    zip: '78469',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.796865',
    longitude: '-97.445946',
    timeZoneId: 'America/Chicago',
  },
  '78472': {
    zip: '78472',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.6937',
    longitude: '-97.398467',
    timeZoneId: 'America/Chicago',
  },
  '78480': {
    zip: '78480',
    city: 'Corpus Christi',
    state: 'TX',
    latitude: '27.797353',
    longitude: '-97.396512',
    timeZoneId: 'America/Chicago',
  },
  '78501': {
    zip: '78501',
    city: 'McAllen',
    state: 'TX',
    latitude: '26.21359',
    longitude: '-98.235546',
    timeZoneId: 'America/Chicago',
  },
  '78502': {
    zip: '78502',
    city: 'McAllen',
    state: 'TX',
    latitude: '26.198279',
    longitude: '-98.246967',
    timeZoneId: 'America/Chicago',
  },
  '78503': {
    zip: '78503',
    city: 'McAllen',
    state: 'TX',
    latitude: '26.177511',
    longitude: '-98.241408',
    timeZoneId: 'America/Chicago',
  },
  '78504': {
    zip: '78504',
    city: 'McAllen',
    state: 'TX',
    latitude: '26.255155',
    longitude: '-98.226059',
    timeZoneId: 'America/Chicago',
  },
  '78505': {
    zip: '78505',
    city: 'McAllen',
    state: 'TX',
    latitude: '26.200156',
    longitude: '-98.232966',
    timeZoneId: 'America/Chicago',
  },
  '78516': {
    zip: '78516',
    city: 'Alamo',
    state: 'TX',
    latitude: '26.184742',
    longitude: '-98.117053',
    timeZoneId: 'America/Chicago',
  },
  '78520': {
    zip: '78520',
    city: 'Brownsville',
    state: 'TX',
    latitude: '25.98174',
    longitude: '-97.53534',
    timeZoneId: 'America/Chicago',
  },
  '78521': {
    zip: '78521',
    city: 'Brownsville',
    state: 'TX',
    latitude: '25.939204',
    longitude: '-97.447349',
    timeZoneId: 'America/Chicago',
  },
  '78522': {
    zip: '78522',
    city: 'Brownsville',
    state: 'TX',
    latitude: '26.002417',
    longitude: '-97.571137',
    timeZoneId: 'America/Chicago',
  },
  '78523': {
    zip: '78523',
    city: 'Brownsville',
    state: 'TX',
    latitude: '26.002329',
    longitude: '-97.57273',
    timeZoneId: 'America/Chicago',
  },
  '78526': {
    zip: '78526',
    city: 'Brownsville',
    state: 'TX',
    latitude: '25.931144',
    longitude: '-97.496733',
    timeZoneId: 'America/Chicago',
  },
  '78535': {
    zip: '78535',
    city: 'Combes',
    state: 'TX',
    latitude: '26.250563',
    longitude: '-97.729406',
    timeZoneId: 'America/Chicago',
  },
  '78536': {
    zip: '78536',
    city: 'Delmita',
    state: 'TX',
    latitude: '26.643548',
    longitude: '-98.403199',
    timeZoneId: 'America/Chicago',
  },
  '78537': {
    zip: '78537',
    city: 'Donna',
    state: 'TX',
    latitude: '26.171006',
    longitude: '-98.055232',
    timeZoneId: 'America/Chicago',
  },
  '78538': {
    zip: '78538',
    city: 'Edcouch',
    state: 'TX',
    latitude: '26.30982',
    longitude: '-97.959',
    timeZoneId: 'America/Chicago',
  },
  '78539': {
    zip: '78539',
    city: 'Edinburg',
    state: 'TX',
    latitude: '26.33202',
    longitude: '-98.16384',
    timeZoneId: 'America/Chicago',
  },
  '78540': {
    zip: '78540',
    city: 'Edinburg',
    state: 'TX',
    latitude: '26.301321',
    longitude: '-98.159909',
    timeZoneId: 'America/Chicago',
  },
  '78541': {
    zip: '78541',
    city: 'Edinburg',
    state: 'TX',
    latitude: '26.295361',
    longitude: '-98.16343',
    timeZoneId: 'America/Chicago',
  },
  '78542': {
    zip: '78542',
    city: 'Edinburg',
    state: 'TX',
    latitude: '26.295473',
    longitude: '-98.163736',
    timeZoneId: 'America/Chicago',
  },
  '78543': {
    zip: '78543',
    city: 'Elsa',
    state: 'TX',
    latitude: '26.297711',
    longitude: '-97.99334',
    timeZoneId: 'America/Chicago',
  },
  '78545': {
    zip: '78545',
    city: 'Falcon Heights',
    state: 'TX',
    latitude: '26.581595',
    longitude: '-98.985987',
    timeZoneId: 'America/Chicago',
  },
  '78547': {
    zip: '78547',
    city: 'Garciasville',
    state: 'TX',
    latitude: '26.376044',
    longitude: '-98.652216',
    timeZoneId: 'America/Chicago',
  },
  '78548': {
    zip: '78548',
    city: 'Grulla',
    state: 'TX',
    latitude: '26.273939',
    longitude: '-98.638445',
    timeZoneId: 'America/Chicago',
  },
  '78549': {
    zip: '78549',
    city: 'Hargill',
    state: 'TX',
    latitude: '26.421554',
    longitude: '-97.978474',
    timeZoneId: 'America/Chicago',
  },
  '78550': {
    zip: '78550',
    city: 'Harlingen',
    state: 'TX',
    latitude: '26.194287',
    longitude: '-97.686999',
    timeZoneId: 'America/Chicago',
  },
  '78551': {
    zip: '78551',
    city: 'Harlingen',
    state: 'TX',
    latitude: '26.207392',
    longitude: '-97.735067',
    timeZoneId: 'America/Chicago',
  },
  '78552': {
    zip: '78552',
    city: 'Harlingen',
    state: 'TX',
    latitude: '26.187337',
    longitude: '-97.732062',
    timeZoneId: 'America/Chicago',
  },
  '78553': {
    zip: '78553',
    city: 'Harlingen',
    state: 'TX',
    latitude: '26.209958',
    longitude: '-97.695299',
    timeZoneId: 'America/Chicago',
  },
  '78557': {
    zip: '78557',
    city: 'Hidalgo',
    state: 'TX',
    latitude: '26.100919',
    longitude: '-98.244193',
    timeZoneId: 'America/Chicago',
  },
  '78558': {
    zip: '78558',
    city: 'La Blanca',
    state: 'TX',
    latitude: '26.310755',
    longitude: '-97.938575',
    timeZoneId: 'America/Chicago',
  },
  '78559': {
    zip: '78559',
    city: 'La Feria',
    state: 'TX',
    latitude: '26.156158',
    longitude: '-97.824324',
    timeZoneId: 'America/Chicago',
  },
  '78560': {
    zip: '78560',
    city: 'La Joya',
    state: 'TX',
    latitude: '26.24658',
    longitude: '-98.48112',
    timeZoneId: 'America/Chicago',
  },
  '78561': {
    zip: '78561',
    city: 'Lasara',
    state: 'TX',
    latitude: '26.468286',
    longitude: '-97.909697',
    timeZoneId: 'America/Chicago',
  },
  '78562': {
    zip: '78562',
    city: 'La Villa',
    state: 'TX',
    latitude: '26.298317',
    longitude: '-97.929404',
    timeZoneId: 'America/Chicago',
  },
  '78563': {
    zip: '78563',
    city: 'Linn',
    state: 'TX',
    latitude: '26.58192',
    longitude: '-98.17152',
    timeZoneId: 'America/Chicago',
  },
  '78564': {
    zip: '78564',
    city: 'Lopeno',
    state: 'TX',
    latitude: '26.900251',
    longitude: '-99.137082',
    timeZoneId: 'America/Chicago',
  },
  '78565': {
    zip: '78565',
    city: 'Los Ebanos',
    state: 'TX',
    latitude: '26.314051',
    longitude: '-98.391367',
    timeZoneId: 'America/Chicago',
  },
  '78566': {
    zip: '78566',
    city: 'Los Fresnos',
    state: 'TX',
    latitude: '26.085',
    longitude: '-97.4616',
    timeZoneId: 'America/Chicago',
  },
  '78567': {
    zip: '78567',
    city: 'Los Indios',
    state: 'TX',
    latitude: '26.096505',
    longitude: '-97.669322',
    timeZoneId: 'America/Chicago',
  },
  '78568': {
    zip: '78568',
    city: 'Lozano',
    state: 'TX',
    latitude: '26.10685',
    longitude: '-97.479596',
    timeZoneId: 'America/Chicago',
  },
  '78569': {
    zip: '78569',
    city: 'Lyford',
    state: 'TX',
    latitude: '26.4105',
    longitude: '-97.76442',
    timeZoneId: 'America/Chicago',
  },
  '78570': {
    zip: '78570',
    city: 'Mercedes',
    state: 'TX',
    latitude: '26.16048',
    longitude: '-97.91982',
    timeZoneId: 'America/Chicago',
  },
  '78572': {
    zip: '78572',
    city: 'Mission',
    state: 'TX',
    latitude: '26.21466',
    longitude: '-98.35164',
    timeZoneId: 'America/Chicago',
  },
  '78573': {
    zip: '78573',
    city: 'Mission',
    state: 'TX',
    latitude: '26.214239',
    longitude: '-98.325921',
    timeZoneId: 'America/Chicago',
  },
  '78574': {
    zip: '78574',
    city: 'Mission',
    state: 'TX',
    latitude: '26.219063',
    longitude: '-98.325099',
    timeZoneId: 'America/Chicago',
  },
  '78575': {
    zip: '78575',
    city: 'Olmito',
    state: 'TX',
    latitude: '26.02128',
    longitude: '-97.53378',
    timeZoneId: 'America/Chicago',
  },
  '78576': {
    zip: '78576',
    city: 'Penitas',
    state: 'TX',
    latitude: '26.314445',
    longitude: '-98.391453',
    timeZoneId: 'America/Chicago',
  },
  '78577': {
    zip: '78577',
    city: 'Pharr',
    state: 'TX',
    latitude: '26.18745',
    longitude: '-98.183954',
    timeZoneId: 'America/Chicago',
  },
  '78578': {
    zip: '78578',
    city: 'Port Isabel',
    state: 'TX',
    latitude: '26.084744',
    longitude: '-97.24158',
    timeZoneId: 'America/Chicago',
  },
  '78579': {
    zip: '78579',
    city: 'Progreso',
    state: 'TX',
    latitude: '26.167309',
    longitude: '-97.987909',
    timeZoneId: 'America/Chicago',
  },
  '78580': {
    zip: '78580',
    city: 'Raymondville',
    state: 'TX',
    latitude: '26.481214',
    longitude: '-97.78357',
    timeZoneId: 'America/Chicago',
  },
  '78582': {
    zip: '78582',
    city: 'Rio Grande City',
    state: 'TX',
    latitude: '26.49192',
    longitude: '-98.7393',
    timeZoneId: 'America/Chicago',
  },
  '78583': {
    zip: '78583',
    city: 'Rio Hondo',
    state: 'TX',
    latitude: '26.236846',
    longitude: '-97.578736',
    timeZoneId: 'America/Chicago',
  },
  '78584': {
    zip: '78584',
    city: 'Roma',
    state: 'TX',
    latitude: '26.378999',
    longitude: '-98.81498',
    timeZoneId: 'America/Chicago',
  },
  '78585': {
    zip: '78585',
    city: 'Salineno',
    state: 'TX',
    latitude: '26.581894',
    longitude: '-98.989067',
    timeZoneId: 'America/Chicago',
  },
  '78586': {
    zip: '78586',
    city: 'San Benito',
    state: 'TX',
    latitude: '26.18562',
    longitude: '-97.66464',
    timeZoneId: 'America/Chicago',
  },
  '78588': {
    zip: '78588',
    city: 'San Isidro',
    state: 'TX',
    latitude: '26.737743',
    longitude: '-98.400419',
    timeZoneId: 'America/Chicago',
  },
  '78589': {
    zip: '78589',
    city: 'San Juan',
    state: 'TX',
    latitude: '26.19648',
    longitude: '-98.15334',
    timeZoneId: 'America/Chicago',
  },
  '78590': {
    zip: '78590',
    city: 'San Perlita',
    state: 'TX',
    latitude: '26.467593',
    longitude: '-97.57972',
    timeZoneId: 'America/Chicago',
  },
  '78591': {
    zip: '78591',
    city: 'Santa Elena',
    state: 'TX',
    latitude: '26.7441',
    longitude: '-98.51478',
    timeZoneId: 'America/Chicago',
  },
  '78592': {
    zip: '78592',
    city: 'Santa Maria',
    state: 'TX',
    latitude: '26.073889',
    longitude: '-97.822778',
    timeZoneId: 'America/Chicago',
  },
  '78593': {
    zip: '78593',
    city: 'Santa Rosa',
    state: 'TX',
    latitude: '26.250864',
    longitude: '-97.825711',
    timeZoneId: 'America/Chicago',
  },
  '78594': {
    zip: '78594',
    city: 'Sebastian',
    state: 'TX',
    latitude: '26.345568',
    longitude: '-97.791107',
    timeZoneId: 'America/Chicago',
  },
  '78595': {
    zip: '78595',
    city: 'Sullivan City',
    state: 'TX',
    latitude: '26.260256',
    longitude: '-98.560333',
    timeZoneId: 'America/Chicago',
  },
  '78596': {
    zip: '78596',
    city: 'Weslaco',
    state: 'TX',
    latitude: '26.160167',
    longitude: '-97.98636',
    timeZoneId: 'America/Chicago',
  },
  '78597': {
    zip: '78597',
    city: 'South Padre Island',
    state: 'TX',
    latitude: '26.10955',
    longitude: '-97.168003',
    timeZoneId: 'America/Chicago',
  },
  '78598': {
    zip: '78598',
    city: 'Port Mansfield',
    state: 'TX',
    latitude: '26.560268',
    longitude: '-97.43137',
    timeZoneId: 'America/Chicago',
  },
  '78599': {
    zip: '78599',
    city: 'Weslaco',
    state: 'TX',
    latitude: '26.158854',
    longitude: '-97.992492',
    timeZoneId: 'America/Chicago',
  },
  '78602': {
    zip: '78602',
    city: 'Bastrop',
    state: 'TX',
    latitude: '30.1158',
    longitude: '-97.27464',
    timeZoneId: 'America/Chicago',
  },
  '78604': {
    zip: '78604',
    city: 'Belmont',
    state: 'TX',
    latitude: '29.523929',
    longitude: '-97.681918',
    timeZoneId: 'America/Chicago',
  },
  '78605': {
    zip: '78605',
    city: 'Bertram',
    state: 'TX',
    latitude: '30.745034',
    longitude: '-98.053979',
    timeZoneId: 'America/Chicago',
  },
  '78606': {
    zip: '78606',
    city: 'Blanco',
    state: 'TX',
    latitude: '30.106568',
    longitude: '-98.420607',
    timeZoneId: 'America/Chicago',
  },
  '78607': {
    zip: '78607',
    city: 'Bluffton',
    state: 'TX',
    latitude: '30.832905',
    longitude: '-98.492899',
    timeZoneId: 'America/Chicago',
  },
  '78608': {
    zip: '78608',
    city: 'Briggs',
    state: 'TX',
    latitude: '30.935647',
    longitude: '-97.969927',
    timeZoneId: 'America/Chicago',
  },
  '78609': {
    zip: '78609',
    city: 'Buchanan Dam',
    state: 'TX',
    latitude: '30.798498',
    longitude: '-98.465383',
    timeZoneId: 'America/Chicago',
  },
  '78610': {
    zip: '78610',
    city: 'Buda',
    state: 'TX',
    latitude: '30.082691',
    longitude: '-97.843934',
    timeZoneId: 'America/Chicago',
  },
  '78611': {
    zip: '78611',
    city: 'Burnet',
    state: 'TX',
    latitude: '30.759861',
    longitude: '-98.225244',
    timeZoneId: 'America/Chicago',
  },
  '78612': {
    zip: '78612',
    city: 'Cedar Creek',
    state: 'TX',
    latitude: '30.072856',
    longitude: '-97.521573',
    timeZoneId: 'America/Chicago',
  },
  '78613': {
    zip: '78613',
    city: 'Cedar Park',
    state: 'TX',
    latitude: '30.494138',
    longitude: '-97.829836',
    timeZoneId: 'America/Chicago',
  },
  '78614': {
    zip: '78614',
    city: 'Cost',
    state: 'TX',
    latitude: '29.420313',
    longitude: '-97.554007',
    timeZoneId: 'America/Chicago',
  },
  '78615': {
    zip: '78615',
    city: 'Coupland',
    state: 'TX',
    latitude: '30.54462',
    longitude: '-97.32198',
    timeZoneId: 'America/Chicago',
  },
  '78616': {
    zip: '78616',
    city: 'Dale',
    state: 'TX',
    latitude: '30.029845',
    longitude: '-97.571615',
    timeZoneId: 'America/Chicago',
  },
  '78617': {
    zip: '78617',
    city: 'Del Valle',
    state: 'TX',
    latitude: '30.15102',
    longitude: '-97.59444',
    timeZoneId: 'America/Chicago',
  },
  '78618': {
    zip: '78618',
    city: 'Doss',
    state: 'TX',
    latitude: '30.469922',
    longitude: '-99.146186',
    timeZoneId: 'America/Chicago',
  },
  '78619': {
    zip: '78619',
    city: 'Driftwood',
    state: 'TX',
    latitude: '30.076279',
    longitude: '-98.024355',
    timeZoneId: 'America/Chicago',
  },
  '78620': {
    zip: '78620',
    city: 'Dripping Springs',
    state: 'TX',
    latitude: '30.25026',
    longitude: '-98.12016',
    timeZoneId: 'America/Chicago',
  },
  '78621': {
    zip: '78621',
    city: 'Elgin',
    state: 'TX',
    latitude: '30.32352',
    longitude: '-97.37952',
    timeZoneId: 'America/Chicago',
  },
  '78622': {
    zip: '78622',
    city: 'Fentress',
    state: 'TX',
    latitude: '29.756855',
    longitude: '-97.779629',
    timeZoneId: 'America/Chicago',
  },
  '78623': {
    zip: '78623',
    city: 'Fischer',
    state: 'TX',
    latitude: '29.998616',
    longitude: '-98.295262',
    timeZoneId: 'America/Chicago',
  },
  '78624': {
    zip: '78624',
    city: 'Fredericksburg',
    state: 'TX',
    latitude: '30.24985',
    longitude: '-99.09982',
    timeZoneId: 'America/Chicago',
  },
  '78626': {
    zip: '78626',
    city: 'Georgetown',
    state: 'TX',
    latitude: '30.63762',
    longitude: '-97.65984',
    timeZoneId: 'America/Chicago',
  },
  '78627': {
    zip: '78627',
    city: 'Georgetown',
    state: 'TX',
    latitude: '30.636976',
    longitude: '-97.674651',
    timeZoneId: 'America/Chicago',
  },
  '78628': {
    zip: '78628',
    city: 'Georgetown',
    state: 'TX',
    latitude: '30.665203',
    longitude: '-97.707564',
    timeZoneId: 'America/Chicago',
  },
  '78629': {
    zip: '78629',
    city: 'Gonzales',
    state: 'TX',
    latitude: '29.510053',
    longitude: '-97.452034',
    timeZoneId: 'America/Chicago',
  },
  '78630': {
    zip: '78630',
    city: 'Cedar Park',
    state: 'TX',
    latitude: '30.504171',
    longitude: '-97.820136',
    timeZoneId: 'America/Chicago',
  },
  '78631': {
    zip: '78631',
    city: 'Harper',
    state: 'TX',
    latitude: '30.317834',
    longitude: '-99.222875',
    timeZoneId: 'America/Chicago',
  },
  '78632': {
    zip: '78632',
    city: 'Harwood',
    state: 'TX',
    latitude: '29.660518',
    longitude: '-97.479686',
    timeZoneId: 'America/Chicago',
  },
  '78633': {
    zip: '78633',
    city: 'Georgetown',
    state: 'TX',
    latitude: '30.753944',
    longitude: '-97.655586',
    timeZoneId: 'America/Chicago',
  },
  '78634': {
    zip: '78634',
    city: 'Hutto',
    state: 'TX',
    latitude: '30.55386',
    longitude: '-97.547946',
    timeZoneId: 'America/Chicago',
  },
  '78635': {
    zip: '78635',
    city: 'Hye',
    state: 'TX',
    latitude: '30.221566',
    longitude: '-98.532558',
    timeZoneId: 'America/Chicago',
  },
  '78636': {
    zip: '78636',
    city: 'Johnson City',
    state: 'TX',
    latitude: '30.27614',
    longitude: '-98.408543',
    timeZoneId: 'America/Chicago',
  },
  '78638': {
    zip: '78638',
    city: 'Kingsbury',
    state: 'TX',
    latitude: '29.687386',
    longitude: '-97.803855',
    timeZoneId: 'America/Chicago',
  },
  '78639': {
    zip: '78639',
    city: 'Kingsland',
    state: 'TX',
    latitude: '30.66258',
    longitude: '-98.45442',
    timeZoneId: 'America/Chicago',
  },
  '78640': {
    zip: '78640',
    city: 'Kyle',
    state: 'TX',
    latitude: '30.0066',
    longitude: '-97.80714',
    timeZoneId: 'America/Chicago',
  },
  '78641': {
    zip: '78641',
    city: 'Leander',
    state: 'TX',
    latitude: '30.52788',
    longitude: '-97.88034',
    timeZoneId: 'America/Chicago',
  },
  '78642': {
    zip: '78642',
    city: 'Liberty Hill',
    state: 'TX',
    latitude: '30.684999',
    longitude: '-97.91043',
    timeZoneId: 'America/Chicago',
  },
  '78643': {
    zip: '78643',
    city: 'Llano',
    state: 'TX',
    latitude: '30.66432',
    longitude: '-98.57766',
    timeZoneId: 'America/Chicago',
  },
  '78644': {
    zip: '78644',
    city: 'Lockhart',
    state: 'TX',
    latitude: '29.87964',
    longitude: '-97.66674',
    timeZoneId: 'America/Chicago',
  },
  '78645': {
    zip: '78645',
    city: 'Leander',
    state: 'TX',
    latitude: '30.447624',
    longitude: '-97.980615',
    timeZoneId: 'America/Chicago',
  },
  '78646': {
    zip: '78646',
    city: 'Leander',
    state: 'TX',
    latitude: '30.579561',
    longitude: '-97.849263',
    timeZoneId: 'America/Chicago',
  },
  '78648': {
    zip: '78648',
    city: 'Luling',
    state: 'TX',
    latitude: '29.678992',
    longitude: '-97.646226',
    timeZoneId: 'America/Chicago',
  },
  '78650': {
    zip: '78650',
    city: 'Mc Dade',
    state: 'TX',
    latitude: '30.306005',
    longitude: '-97.219545',
    timeZoneId: 'America/Chicago',
  },
  '78651': {
    zip: '78651',
    city: 'Mc Neil',
    state: 'TX',
    latitude: '30.452693',
    longitude: '-97.723155',
    timeZoneId: 'America/Chicago',
  },
  '78652': {
    zip: '78652',
    city: 'Manchaca',
    state: 'TX',
    latitude: '30.131837',
    longitude: '-97.824948',
    timeZoneId: 'America/Chicago',
  },
  '78653': {
    zip: '78653',
    city: 'Manor',
    state: 'TX',
    latitude: '30.31896',
    longitude: '-97.53696',
    timeZoneId: 'America/Chicago',
  },
  '78654': {
    zip: '78654',
    city: 'Marble Falls',
    state: 'TX',
    latitude: '30.58113',
    longitude: '-98.336553',
    timeZoneId: 'America/Chicago',
  },
  '78655': {
    zip: '78655',
    city: 'Martindale',
    state: 'TX',
    latitude: '29.849653',
    longitude: '-97.847507',
    timeZoneId: 'America/Chicago',
  },
  '78656': {
    zip: '78656',
    city: 'Maxwell',
    state: 'TX',
    latitude: '29.861942',
    longitude: '-97.858517',
    timeZoneId: 'America/Chicago',
  },
  '78657': {
    zip: '78657',
    city: 'Horseshoe Bay',
    state: 'TX',
    latitude: '30.579706',
    longitude: '-98.271069',
    timeZoneId: 'America/Chicago',
  },
  '78658': {
    zip: '78658',
    city: 'Ottine',
    state: 'TX',
    latitude: '29.59903',
    longitude: '-97.584414',
    timeZoneId: 'America/Chicago',
  },
  '78659': {
    zip: '78659',
    city: 'Paige',
    state: 'TX',
    latitude: '30.1986',
    longitude: '-97.10538',
    timeZoneId: 'America/Chicago',
  },
  '78660': {
    zip: '78660',
    city: 'Pflugerville',
    state: 'TX',
    latitude: '30.44195',
    longitude: '-97.627258',
    timeZoneId: 'America/Chicago',
  },
  '78661': {
    zip: '78661',
    city: 'Prairie Lea',
    state: 'TX',
    latitude: '29.732556',
    longitude: '-97.753335',
    timeZoneId: 'America/Chicago',
  },
  '78662': {
    zip: '78662',
    city: 'Red Rock',
    state: 'TX',
    latitude: '29.946816',
    longitude: '-97.438931',
    timeZoneId: 'America/Chicago',
  },
  '78663': {
    zip: '78663',
    city: 'Round Mountain',
    state: 'TX',
    latitude: '30.441858',
    longitude: '-98.468024',
    timeZoneId: 'America/Chicago',
  },
  '78664': {
    zip: '78664',
    city: 'Round Rock',
    state: 'TX',
    latitude: '30.512352',
    longitude: '-97.670283',
    timeZoneId: 'America/Chicago',
  },
  '78665': {
    zip: '78665',
    city: 'Round Rock',
    state: 'TX',
    latitude: '30.530921',
    longitude: '-97.651317',
    timeZoneId: 'America/Chicago',
  },
  '78666': {
    zip: '78666',
    city: 'San Marcos',
    state: 'TX',
    latitude: '29.882645',
    longitude: '-97.945088',
    timeZoneId: 'America/Chicago',
  },
  '78667': {
    zip: '78667',
    city: 'San Marcos',
    state: 'TX',
    latitude: '29.858313',
    longitude: '-97.942973',
    timeZoneId: 'America/Chicago',
  },
  '78669': {
    zip: '78669',
    city: 'Spicewood',
    state: 'TX',
    latitude: '30.418049',
    longitude: '-98.052665',
    timeZoneId: 'America/Chicago',
  },
  '78670': {
    zip: '78670',
    city: 'Staples',
    state: 'TX',
    latitude: '29.780717',
    longitude: '-97.836673',
    timeZoneId: 'America/Chicago',
  },
  '78671': {
    zip: '78671',
    city: 'Stonewall',
    state: 'TX',
    latitude: '30.209657',
    longitude: '-98.657123',
    timeZoneId: 'America/Chicago',
  },
  '78672': {
    zip: '78672',
    city: 'Tow',
    state: 'TX',
    latitude: '30.871549',
    longitude: '-98.514047',
    timeZoneId: 'America/Chicago',
  },
  '78673': {
    zip: '78673',
    city: 'Walburg',
    state: 'TX',
    latitude: '30.736534',
    longitude: '-97.577601',
    timeZoneId: 'America/Chicago',
  },
  '78674': {
    zip: '78674',
    city: 'Weir',
    state: 'TX',
    latitude: '30.673248',
    longitude: '-97.588641',
    timeZoneId: 'America/Chicago',
  },
  '78675': {
    zip: '78675',
    city: 'Willow City',
    state: 'TX',
    latitude: '30.449825',
    longitude: '-98.726331',
    timeZoneId: 'America/Chicago',
  },
  '78676': {
    zip: '78676',
    city: 'Wimberley',
    state: 'TX',
    latitude: '30.03906',
    longitude: '-98.13978',
    timeZoneId: 'America/Chicago',
  },
  '78677': {
    zip: '78677',
    city: 'Wrightsboro',
    state: 'TX',
    latitude: '29.351201',
    longitude: '-97.503023',
    timeZoneId: 'America/Chicago',
  },
  '78680': {
    zip: '78680',
    city: 'Round Rock',
    state: 'TX',
    latitude: '30.446285',
    longitude: '-97.668129',
    timeZoneId: 'America/Chicago',
  },
  '78681': {
    zip: '78681',
    city: 'Round Rock',
    state: 'TX',
    latitude: '30.513382',
    longitude: '-97.712018',
    timeZoneId: 'America/Chicago',
  },
  '78682': {
    zip: '78682',
    city: 'Round Rock',
    state: 'TX',
    latitude: '30.515111',
    longitude: '-97.672706',
    timeZoneId: 'America/Chicago',
  },
  '78683': {
    zip: '78683',
    city: 'Round Rock',
    state: 'TX',
    latitude: '30.509363',
    longitude: '-97.676254',
    timeZoneId: 'America/Chicago',
  },
  '78691': {
    zip: '78691',
    city: 'Pflugerville',
    state: 'TX',
    latitude: '30.440689',
    longitude: '-97.621429',
    timeZoneId: 'America/Chicago',
  },
  '78701': {
    zip: '78701',
    city: 'Austin',
    state: 'TX',
    latitude: '30.271567',
    longitude: '-97.741886',
    timeZoneId: 'America/Chicago',
  },
  '78702': {
    zip: '78702',
    city: 'Austin',
    state: 'TX',
    latitude: '30.266794',
    longitude: '-97.714339',
    timeZoneId: 'America/Chicago',
  },
  '78703': {
    zip: '78703',
    city: 'Austin',
    state: 'TX',
    latitude: '30.294325',
    longitude: '-97.763573',
    timeZoneId: 'America/Chicago',
  },
  '78704': {
    zip: '78704',
    city: 'Austin',
    state: 'TX',
    latitude: '30.244144',
    longitude: '-97.76286',
    timeZoneId: 'America/Chicago',
  },
  '78705': {
    zip: '78705',
    city: 'Austin',
    state: 'TX',
    latitude: '30.291487',
    longitude: '-97.733878',
    timeZoneId: 'America/Chicago',
  },
  '78708': {
    zip: '78708',
    city: 'Austin',
    state: 'TX',
    latitude: '30.385313',
    longitude: '-97.705249',
    timeZoneId: 'America/Chicago',
  },
  '78709': {
    zip: '78709',
    city: 'Austin',
    state: 'TX',
    latitude: '30.235021',
    longitude: '-97.847636',
    timeZoneId: 'America/Chicago',
  },
  '78710': {
    zip: '78710',
    city: 'Austin',
    state: 'TX',
    latitude: '30.35342',
    longitude: '-97.656761',
    timeZoneId: 'America/Chicago',
  },
  '78711': {
    zip: '78711',
    city: 'Austin',
    state: 'TX',
    latitude: '30.279684',
    longitude: '-97.738098',
    timeZoneId: 'America/Chicago',
  },
  '78712': {
    zip: '78712',
    city: 'Austin',
    state: 'TX',
    latitude: '30.28513',
    longitude: '-97.737776',
    timeZoneId: 'America/Chicago',
  },
  '78713': {
    zip: '78713',
    city: 'Austin',
    state: 'TX',
    latitude: '30.283922',
    longitude: '-97.741323',
    timeZoneId: 'America/Chicago',
  },
  '78714': {
    zip: '78714',
    city: 'Austin',
    state: 'TX',
    latitude: '30.26884',
    longitude: '-97.741223',
    timeZoneId: 'America/Chicago',
  },
  '78715': {
    zip: '78715',
    city: 'Austin',
    state: 'TX',
    latitude: '30.206573',
    longitude: '-97.796232',
    timeZoneId: 'America/Chicago',
  },
  '78716': {
    zip: '78716',
    city: 'Austin',
    state: 'TX',
    latitude: '30.322895',
    longitude: '-97.864038',
    timeZoneId: 'America/Chicago',
  },
  '78717': {
    zip: '78717',
    city: 'Austin',
    state: 'TX',
    latitude: '30.503015',
    longitude: '-97.742252',
    timeZoneId: 'America/Chicago',
  },
  '78718': {
    zip: '78718',
    city: 'Austin',
    state: 'TX',
    latitude: '30.390334',
    longitude: '-97.706191',
    timeZoneId: 'America/Chicago',
  },
  '78719': {
    zip: '78719',
    city: 'Austin',
    state: 'TX',
    latitude: '30.127369',
    longitude: '-97.672339',
    timeZoneId: 'America/Chicago',
  },
  '78720': {
    zip: '78720',
    city: 'Austin',
    state: 'TX',
    latitude: '30.401849',
    longitude: '-97.754127',
    timeZoneId: 'America/Chicago',
  },
  '78721': {
    zip: '78721',
    city: 'Austin',
    state: 'TX',
    latitude: '30.272454',
    longitude: '-97.686158',
    timeZoneId: 'America/Chicago',
  },
  '78722': {
    zip: '78722',
    city: 'Austin',
    state: 'TX',
    latitude: '30.285321',
    longitude: '-97.714963',
    timeZoneId: 'America/Chicago',
  },
  '78723': {
    zip: '78723',
    city: 'Austin',
    state: 'TX',
    latitude: '30.306657',
    longitude: '-97.68314',
    timeZoneId: 'America/Chicago',
  },
  '78724': {
    zip: '78724',
    city: 'Austin',
    state: 'TX',
    latitude: '30.2942',
    longitude: '-97.628001',
    timeZoneId: 'America/Chicago',
  },
  '78725': {
    zip: '78725',
    city: 'Austin',
    state: 'TX',
    latitude: '30.234974',
    longitude: '-97.59756',
    timeZoneId: 'America/Chicago',
  },
  '78726': {
    zip: '78726',
    city: 'Austin',
    state: 'TX',
    latitude: '30.422763',
    longitude: '-97.849596',
    timeZoneId: 'America/Chicago',
  },
  '78727': {
    zip: '78727',
    city: 'Austin',
    state: 'TX',
    latitude: '30.424886',
    longitude: '-97.713998',
    timeZoneId: 'America/Chicago',
  },
  '78728': {
    zip: '78728',
    city: 'Austin',
    state: 'TX',
    latitude: '30.445565',
    longitude: '-97.681089',
    timeZoneId: 'America/Chicago',
  },
  '78729': {
    zip: '78729',
    city: 'Austin',
    state: 'TX',
    latitude: '30.45618',
    longitude: '-97.76508',
    timeZoneId: 'America/Chicago',
  },
  '78730': {
    zip: '78730',
    city: 'Austin',
    state: 'TX',
    latitude: '30.364835',
    longitude: '-97.825721',
    timeZoneId: 'America/Chicago',
  },
  '78731': {
    zip: '78731',
    city: 'Austin',
    state: 'TX',
    latitude: '30.345829',
    longitude: '-97.765628',
    timeZoneId: 'America/Chicago',
  },
  '78732': {
    zip: '78732',
    city: 'Austin',
    state: 'TX',
    latitude: '30.37422',
    longitude: '-97.8927',
    timeZoneId: 'America/Chicago',
  },
  '78733': {
    zip: '78733',
    city: 'Austin',
    state: 'TX',
    latitude: '30.32178',
    longitude: '-97.8951',
    timeZoneId: 'America/Chicago',
  },
  '78734': {
    zip: '78734',
    city: 'Austin',
    state: 'TX',
    latitude: '30.3711',
    longitude: '-97.8699',
    timeZoneId: 'America/Chicago',
  },
  '78735': {
    zip: '78735',
    city: 'Austin',
    state: 'TX',
    latitude: '30.2643',
    longitude: '-97.86534',
    timeZoneId: 'America/Chicago',
  },
  '78736': {
    zip: '78736',
    city: 'Austin',
    state: 'TX',
    latitude: '30.250073',
    longitude: '-97.926724',
    timeZoneId: 'America/Chicago',
  },
  '78737': {
    zip: '78737',
    city: 'Austin',
    state: 'TX',
    latitude: '30.216676',
    longitude: '-97.915859',
    timeZoneId: 'America/Chicago',
  },
  '78738': {
    zip: '78738',
    city: 'Austin',
    state: 'TX',
    latitude: '30.319171',
    longitude: '-97.93746',
    timeZoneId: 'America/Chicago',
  },
  '78739': {
    zip: '78739',
    city: 'Austin',
    state: 'TX',
    latitude: '30.170527',
    longitude: '-97.876902',
    timeZoneId: 'America/Chicago',
  },
  '78741': {
    zip: '78741',
    city: 'Austin',
    state: 'TX',
    latitude: '30.234123',
    longitude: '-97.718296',
    timeZoneId: 'America/Chicago',
  },
  '78742': {
    zip: '78742',
    city: 'Austin',
    state: 'TX',
    latitude: '30.234425',
    longitude: '-97.672765',
    timeZoneId: 'America/Chicago',
  },
  '78744': {
    zip: '78744',
    city: 'Austin',
    state: 'TX',
    latitude: '30.14568',
    longitude: '-97.70022',
    timeZoneId: 'America/Chicago',
  },
  '78745': {
    zip: '78745',
    city: 'Austin',
    state: 'TX',
    latitude: '30.208076',
    longitude: '-97.800448',
    timeZoneId: 'America/Chicago',
  },
  '78746': {
    zip: '78746',
    city: 'Austin',
    state: 'TX',
    latitude: '30.30108',
    longitude: '-97.81488',
    timeZoneId: 'America/Chicago',
  },
  '78747': {
    zip: '78747',
    city: 'Austin',
    state: 'TX',
    latitude: '30.11826',
    longitude: '-97.74954',
    timeZoneId: 'America/Chicago',
  },
  '78748': {
    zip: '78748',
    city: 'Austin',
    state: 'TX',
    latitude: '30.168174',
    longitude: '-97.822906',
    timeZoneId: 'America/Chicago',
  },
  '78749': {
    zip: '78749',
    city: 'Austin',
    state: 'TX',
    latitude: '30.21162',
    longitude: '-97.85184',
    timeZoneId: 'America/Chicago',
  },
  '78750': {
    zip: '78750',
    city: 'Austin',
    state: 'TX',
    latitude: '30.415054',
    longitude: '-97.797138',
    timeZoneId: 'America/Chicago',
  },
  '78751': {
    zip: '78751',
    city: 'Austin',
    state: 'TX',
    latitude: '30.307949',
    longitude: '-97.724524',
    timeZoneId: 'America/Chicago',
  },
  '78752': {
    zip: '78752',
    city: 'Austin',
    state: 'TX',
    latitude: '30.33104',
    longitude: '-97.69903',
    timeZoneId: 'America/Chicago',
  },
  '78753': {
    zip: '78753',
    city: 'Austin',
    state: 'TX',
    latitude: '30.371378',
    longitude: '-97.671747',
    timeZoneId: 'America/Chicago',
  },
  '78754': {
    zip: '78754',
    city: 'Austin',
    state: 'TX',
    latitude: '30.345825',
    longitude: '-97.661056',
    timeZoneId: 'America/Chicago',
  },
  '78755': {
    zip: '78755',
    city: 'Austin',
    state: 'TX',
    latitude: '30.359842',
    longitude: '-97.76093',
    timeZoneId: 'America/Chicago',
  },
  '78756': {
    zip: '78756',
    city: 'Austin',
    state: 'TX',
    latitude: '30.320939',
    longitude: '-97.743082',
    timeZoneId: 'America/Chicago',
  },
  '78757': {
    zip: '78757',
    city: 'Austin',
    state: 'TX',
    latitude: '30.350518',
    longitude: '-97.732097',
    timeZoneId: 'America/Chicago',
  },
  '78758': {
    zip: '78758',
    city: 'Austin',
    state: 'TX',
    latitude: '30.378845',
    longitude: '-97.711093',
    timeZoneId: 'America/Chicago',
  },
  '78759': {
    zip: '78759',
    city: 'Austin',
    state: 'TX',
    latitude: '30.3972',
    longitude: '-97.75176',
    timeZoneId: 'America/Chicago',
  },
  '78760': {
    zip: '78760',
    city: 'Austin',
    state: 'TX',
    latitude: '30.212563',
    longitude: '-97.73332',
    timeZoneId: 'America/Chicago',
  },
  '78761': {
    zip: '78761',
    city: 'Austin',
    state: 'TX',
    latitude: '30.342101',
    longitude: '-97.681888',
    timeZoneId: 'America/Chicago',
  },
  '78762': {
    zip: '78762',
    city: 'Austin',
    state: 'TX',
    latitude: '30.260427',
    longitude: '-97.722951',
    timeZoneId: 'America/Chicago',
  },
  '78763': {
    zip: '78763',
    city: 'Austin',
    state: 'TX',
    latitude: '30.297111',
    longitude: '-97.766854',
    timeZoneId: 'America/Chicago',
  },
  '78764': {
    zip: '78764',
    city: 'Austin',
    state: 'TX',
    latitude: '30.446819',
    longitude: '-97.659758',
    timeZoneId: 'America/Chicago',
  },
  '78765': {
    zip: '78765',
    city: 'Austin',
    state: 'TX',
    latitude: '30.308282',
    longitude: '-97.728923',
    timeZoneId: 'America/Chicago',
  },
  '78766': {
    zip: '78766',
    city: 'Austin',
    state: 'TX',
    latitude: '30.352149',
    longitude: '-97.72972',
    timeZoneId: 'America/Chicago',
  },
  '78767': {
    zip: '78767',
    city: 'Austin',
    state: 'TX',
    latitude: '30.272823',
    longitude: '-97.742829',
    timeZoneId: 'America/Chicago',
  },
  '78768': {
    zip: '78768',
    city: 'Austin',
    state: 'TX',
    latitude: '30.270459',
    longitude: '-97.744069',
    timeZoneId: 'America/Chicago',
  },
  '78772': {
    zip: '78772',
    city: 'Austin',
    state: 'TX',
    latitude: '30.224319',
    longitude: '-97.750289',
    timeZoneId: 'America/Chicago',
  },
  '78773': {
    zip: '78773',
    city: 'Austin',
    state: 'TX',
    latitude: '30.331091',
    longitude: '-97.706506',
    timeZoneId: 'America/Chicago',
  },
  '78774': {
    zip: '78774',
    city: 'Austin',
    state: 'TX',
    latitude: '30.279861',
    longitude: '-97.739184',
    timeZoneId: 'America/Chicago',
  },
  '78778': {
    zip: '78778',
    city: 'Austin',
    state: 'TX',
    latitude: '30.276212',
    longitude: '-97.739618',
    timeZoneId: 'America/Chicago',
  },
  '78779': {
    zip: '78779',
    city: 'Austin',
    state: 'TX',
    latitude: '30.348092',
    longitude: '-97.768262',
    timeZoneId: 'America/Chicago',
  },
  '78783': {
    zip: '78783',
    city: 'Austin',
    state: 'TX',
    latitude: '30.246407',
    longitude: '-97.766387',
    timeZoneId: 'America/Chicago',
  },
  '78799': {
    zip: '78799',
    city: 'Austin',
    state: 'TX',
    latitude: '30.267104',
    longitude: '-97.744615',
    timeZoneId: 'America/Chicago',
  },
  '78801': {
    zip: '78801',
    city: 'Uvalde',
    state: 'TX',
    latitude: '29.213418',
    longitude: '-99.791665',
    timeZoneId: 'America/Chicago',
  },
  '78802': {
    zip: '78802',
    city: 'Uvalde',
    state: 'TX',
    latitude: '29.21002',
    longitude: '-99.788699',
    timeZoneId: 'America/Chicago',
  },
  '78827': {
    zip: '78827',
    city: 'Asherton',
    state: 'TX',
    latitude: '28.455861',
    longitude: '-99.759092',
    timeZoneId: 'America/Chicago',
  },
  '78828': {
    zip: '78828',
    city: 'Barksdale',
    state: 'TX',
    latitude: '29.73648',
    longitude: '-100.1034',
    timeZoneId: 'America/Chicago',
  },
  '78829': {
    zip: '78829',
    city: 'Batesville',
    state: 'TX',
    latitude: '28.935822',
    longitude: '-99.619519',
    timeZoneId: 'America/Chicago',
  },
  '78830': {
    zip: '78830',
    city: 'Big Wells',
    state: 'TX',
    latitude: '28.553553',
    longitude: '-99.556489',
    timeZoneId: 'America/Chicago',
  },
  '78832': {
    zip: '78832',
    city: 'Brackettville',
    state: 'TX',
    latitude: '29.3106',
    longitude: '-100.418012',
    timeZoneId: 'America/Chicago',
  },
  '78833': {
    zip: '78833',
    city: 'Camp Wood',
    state: 'TX',
    latitude: '29.66854',
    longitude: '-100.013494',
    timeZoneId: 'America/Chicago',
  },
  '78834': {
    zip: '78834',
    city: 'Carrizo Springs',
    state: 'TX',
    latitude: '28.5207',
    longitude: '-99.88848',
    timeZoneId: 'America/Chicago',
  },
  '78836': {
    zip: '78836',
    city: 'Catarina',
    state: 'TX',
    latitude: '28.347138',
    longitude: '-99.612818',
    timeZoneId: 'America/Chicago',
  },
  '78837': {
    zip: '78837',
    city: 'Comstock',
    state: 'TX',
    latitude: '29.68873',
    longitude: '-101.1744',
    timeZoneId: 'America/Chicago',
  },
  '78838': {
    zip: '78838',
    city: 'Concan',
    state: 'TX',
    latitude: '29.505014',
    longitude: '-99.683308',
    timeZoneId: 'America/Chicago',
  },
  '78839': {
    zip: '78839',
    city: 'Crystal City',
    state: 'TX',
    latitude: '28.682775',
    longitude: '-99.829297',
    timeZoneId: 'America/Chicago',
  },
  '78840': {
    zip: '78840',
    city: 'Del Rio',
    state: 'TX',
    latitude: '29.367308',
    longitude: '-100.890893',
    timeZoneId: 'America/Chicago',
  },
  '78841': {
    zip: '78841',
    city: 'Del Rio',
    state: 'TX',
    latitude: '29.346565',
    longitude: '-100.91312',
    timeZoneId: 'America/Chicago',
  },
  '78842': {
    zip: '78842',
    city: 'Del Rio',
    state: 'TX',
    latitude: '29.381977',
    longitude: '-100.900262',
    timeZoneId: 'America/Chicago',
  },
  '78843': {
    zip: '78843',
    city: 'Laughlin AFB',
    state: 'TX',
    latitude: '29.361815',
    longitude: '-100.894575',
    timeZoneId: 'America/Chicago',
  },
  '78847': {
    zip: '78847',
    city: 'Del Rio',
    state: 'TX',
    latitude: '29.375719',
    longitude: '-100.895923',
    timeZoneId: 'America/Chicago',
  },
  '78850': {
    zip: '78850',
    city: 'D Hanis',
    state: 'TX',
    latitude: '29.349952',
    longitude: '-99.311444',
    timeZoneId: 'America/Chicago',
  },
  '78851': {
    zip: '78851',
    city: 'Dryden',
    state: 'TX',
    latitude: '30.226942',
    longitude: '-102.077824',
    timeZoneId: 'America/Chicago',
  },
  '78852': {
    zip: '78852',
    city: 'Eagle Pass',
    state: 'TX',
    latitude: '28.715762',
    longitude: '-100.492839',
    timeZoneId: 'America/Chicago',
  },
  '78853': {
    zip: '78853',
    city: 'Eagle Pass',
    state: 'TX',
    latitude: '28.682171',
    longitude: '-100.48406',
    timeZoneId: 'America/Chicago',
  },
  '78860': {
    zip: '78860',
    city: 'El Indio',
    state: 'TX',
    latitude: '28.516711',
    longitude: '-100.312589',
    timeZoneId: 'America/Chicago',
  },
  '78861': {
    zip: '78861',
    city: 'Hondo',
    state: 'TX',
    latitude: '29.35692',
    longitude: '-99.10158',
    timeZoneId: 'America/Chicago',
  },
  '78870': {
    zip: '78870',
    city: 'Knippa',
    state: 'TX',
    latitude: '29.291676',
    longitude: '-99.634336',
    timeZoneId: 'America/Chicago',
  },
  '78871': {
    zip: '78871',
    city: 'Langtry',
    state: 'TX',
    latitude: '29.807539',
    longitude: '-101.561034',
    timeZoneId: 'America/Chicago',
  },
  '78872': {
    zip: '78872',
    city: 'La Pryor',
    state: 'TX',
    latitude: '28.951466',
    longitude: '-99.848475',
    timeZoneId: 'America/Chicago',
  },
  '78873': {
    zip: '78873',
    city: 'Leakey',
    state: 'TX',
    latitude: '29.861693',
    longitude: '-99.800137',
    timeZoneId: 'America/Chicago',
  },
  '78877': {
    zip: '78877',
    city: 'Quemado',
    state: 'TX',
    latitude: '28.9272',
    longitude: '-100.4262',
    timeZoneId: 'America/Chicago',
  },
  '78879': {
    zip: '78879',
    city: 'Rio Frio',
    state: 'TX',
    latitude: '29.651527',
    longitude: '-99.774446',
    timeZoneId: 'America/Chicago',
  },
  '78880': {
    zip: '78880',
    city: 'Rocksprings',
    state: 'TX',
    latitude: '30.004685',
    longitude: '-100.313091',
    timeZoneId: 'America/Chicago',
  },
  '78881': {
    zip: '78881',
    city: 'Sabinal',
    state: 'TX',
    latitude: '29.314635',
    longitude: '-99.472472',
    timeZoneId: 'America/Chicago',
  },
  '78883': {
    zip: '78883',
    city: 'Tarpley',
    state: 'TX',
    latitude: '29.645218',
    longitude: '-99.224408',
    timeZoneId: 'America/Chicago',
  },
  '78884': {
    zip: '78884',
    city: 'Utopia',
    state: 'TX',
    latitude: '29.567999',
    longitude: '-99.569137',
    timeZoneId: 'America/Chicago',
  },
  '78885': {
    zip: '78885',
    city: 'Vanderpool',
    state: 'TX',
    latitude: '29.782637',
    longitude: '-99.552484',
    timeZoneId: 'America/Chicago',
  },
  '78886': {
    zip: '78886',
    city: 'Yancey',
    state: 'TX',
    latitude: '29.139067',
    longitude: '-99.141918',
    timeZoneId: 'America/Chicago',
  },
  '78931': {
    zip: '78931',
    city: 'Bleiblerville',
    state: 'TX',
    latitude: '29.940764',
    longitude: '-96.40425',
    timeZoneId: 'America/Chicago',
  },
  '78932': {
    zip: '78932',
    city: 'Carmine',
    state: 'TX',
    latitude: '30.131389',
    longitude: '-96.693393',
    timeZoneId: 'America/Chicago',
  },
  '78933': {
    zip: '78933',
    city: 'Cat Spring',
    state: 'TX',
    latitude: '29.7456',
    longitude: '-96.39198',
    timeZoneId: 'America/Chicago',
  },
  '78934': {
    zip: '78934',
    city: 'Columbus',
    state: 'TX',
    latitude: '29.69826',
    longitude: '-96.5664',
    timeZoneId: 'America/Chicago',
  },
  '78935': {
    zip: '78935',
    city: 'Alleyton',
    state: 'TX',
    latitude: '29.745337',
    longitude: '-96.458108',
    timeZoneId: 'America/Chicago',
  },
  '78938': {
    zip: '78938',
    city: 'Ellinger',
    state: 'TX',
    latitude: '29.836939',
    longitude: '-96.697511',
    timeZoneId: 'America/Chicago',
  },
  '78940': {
    zip: '78940',
    city: 'Fayetteville',
    state: 'TX',
    latitude: '29.94264',
    longitude: '-96.6813',
    timeZoneId: 'America/Chicago',
  },
  '78941': {
    zip: '78941',
    city: 'Flatonia',
    state: 'TX',
    latitude: '29.687084',
    longitude: '-97.108065',
    timeZoneId: 'America/Chicago',
  },
  '78942': {
    zip: '78942',
    city: 'Giddings',
    state: 'TX',
    latitude: '30.18618',
    longitude: '-96.934577',
    timeZoneId: 'America/Chicago',
  },
  '78943': {
    zip: '78943',
    city: 'Glidden',
    state: 'TX',
    latitude: '29.707561',
    longitude: '-96.539912',
    timeZoneId: 'America/Chicago',
  },
  '78944': {
    zip: '78944',
    city: 'Industry',
    state: 'TX',
    latitude: '29.989859',
    longitude: '-96.530885',
    timeZoneId: 'America/Chicago',
  },
  '78945': {
    zip: '78945',
    city: 'La Grange',
    state: 'TX',
    latitude: '29.913724',
    longitude: '-96.871432',
    timeZoneId: 'America/Chicago',
  },
  '78946': {
    zip: '78946',
    city: 'Ledbetter',
    state: 'TX',
    latitude: '30.2319',
    longitude: '-96.77562',
    timeZoneId: 'America/Chicago',
  },
  '78947': {
    zip: '78947',
    city: 'Lexington',
    state: 'TX',
    latitude: '30.391667',
    longitude: '-97.147222',
    timeZoneId: 'America/Chicago',
  },
  '78948': {
    zip: '78948',
    city: 'Lincoln',
    state: 'TX',
    latitude: '30.316524',
    longitude: '-96.97637',
    timeZoneId: 'America/Chicago',
  },
  '78949': {
    zip: '78949',
    city: 'Muldoon',
    state: 'TX',
    latitude: '29.798228',
    longitude: '-97.092113',
    timeZoneId: 'America/Chicago',
  },
  '78950': {
    zip: '78950',
    city: 'New Ulm',
    state: 'TX',
    latitude: '29.886455',
    longitude: '-96.485737',
    timeZoneId: 'America/Chicago',
  },
  '78951': {
    zip: '78951',
    city: 'Oakland',
    state: 'TX',
    latitude: '29.603491',
    longitude: '-96.828916',
    timeZoneId: 'America/Chicago',
  },
  '78952': {
    zip: '78952',
    city: 'Plum',
    state: 'TX',
    latitude: '29.936363',
    longitude: '-96.968195',
    timeZoneId: 'America/Chicago',
  },
  '78953': {
    zip: '78953',
    city: 'Rosanky',
    state: 'TX',
    latitude: '29.881875',
    longitude: '-97.323178',
    timeZoneId: 'America/Chicago',
  },
  '78954': {
    zip: '78954',
    city: 'Round Top',
    state: 'TX',
    latitude: '30.054884',
    longitude: '-96.755586',
    timeZoneId: 'America/Chicago',
  },
  '78956': {
    zip: '78956',
    city: 'Schulenburg',
    state: 'TX',
    latitude: '29.680967',
    longitude: '-96.906472',
    timeZoneId: 'America/Chicago',
  },
  '78957': {
    zip: '78957',
    city: 'Smithville',
    state: 'TX',
    latitude: '30.013892',
    longitude: '-97.156138',
    timeZoneId: 'America/Chicago',
  },
  '78959': {
    zip: '78959',
    city: 'Waelder',
    state: 'TX',
    latitude: '29.695667',
    longitude: '-97.293035',
    timeZoneId: 'America/Chicago',
  },
  '78960': {
    zip: '78960',
    city: 'Warda',
    state: 'TX',
    latitude: '30.053576',
    longitude: '-96.915576',
    timeZoneId: 'America/Chicago',
  },
  '78961': {
    zip: '78961',
    city: 'Warrenton',
    state: 'TX',
    latitude: '30.017222',
    longitude: '-96.731667',
    timeZoneId: 'America/Chicago',
  },
  '78962': {
    zip: '78962',
    city: 'Weimar',
    state: 'TX',
    latitude: '29.68566',
    longitude: '-96.7284',
    timeZoneId: 'America/Chicago',
  },
  '78963': {
    zip: '78963',
    city: 'West Point',
    state: 'TX',
    latitude: '29.960773',
    longitude: '-97.03612',
    timeZoneId: 'America/Chicago',
  },
  '79001': {
    zip: '79001',
    city: 'Adrian',
    state: 'TX',
    latitude: '35.38267',
    longitude: '-102.854016',
    timeZoneId: 'America/Chicago',
  },
  '79002': {
    zip: '79002',
    city: 'Alanreed',
    state: 'TX',
    latitude: '35.43301',
    longitude: '-100.743604',
    timeZoneId: 'America/Chicago',
  },
  '79003': {
    zip: '79003',
    city: 'Allison',
    state: 'TX',
    latitude: '35.544911',
    longitude: '-100.145217',
    timeZoneId: 'America/Chicago',
  },
  '79005': {
    zip: '79005',
    city: 'Booker',
    state: 'TX',
    latitude: '36.455591',
    longitude: '-100.524472',
    timeZoneId: 'America/Chicago',
  },
  '79007': {
    zip: '79007',
    city: 'Borger',
    state: 'TX',
    latitude: '35.67798',
    longitude: '-101.41638',
    timeZoneId: 'America/Chicago',
  },
  '79008': {
    zip: '79008',
    city: 'Borger',
    state: 'TX',
    latitude: '35.669658',
    longitude: '-101.38813',
    timeZoneId: 'America/Chicago',
  },
  '79009': {
    zip: '79009',
    city: 'Bovina',
    state: 'TX',
    latitude: '34.516381',
    longitude: '-102.885183',
    timeZoneId: 'America/Chicago',
  },
  '79010': {
    zip: '79010',
    city: 'Boys Ranch',
    state: 'TX',
    latitude: '35.433035',
    longitude: '-102.442266',
    timeZoneId: 'America/Chicago',
  },
  '79011': {
    zip: '79011',
    city: 'Briscoe',
    state: 'TX',
    latitude: '35.547074',
    longitude: '-100.14431',
    timeZoneId: 'America/Chicago',
  },
  '79012': {
    zip: '79012',
    city: 'Bushland',
    state: 'TX',
    latitude: '35.116284',
    longitude: '-102.052636',
    timeZoneId: 'America/Chicago',
  },
  '79013': {
    zip: '79013',
    city: 'Cactus',
    state: 'TX',
    latitude: '36.052481',
    longitude: '-101.997921',
    timeZoneId: 'America/Chicago',
  },
  '79014': {
    zip: '79014',
    city: 'Canadian',
    state: 'TX',
    latitude: '35.8731',
    longitude: '-100.33542',
    timeZoneId: 'America/Chicago',
  },
  '79015': {
    zip: '79015',
    city: 'Canyon',
    state: 'TX',
    latitude: '34.983625',
    longitude: '-101.923289',
    timeZoneId: 'America/Chicago',
  },
  '79016': {
    zip: '79016',
    city: 'Canyon',
    state: 'TX',
    latitude: '34.979592',
    longitude: '-101.929451',
    timeZoneId: 'America/Chicago',
  },
  '79018': {
    zip: '79018',
    city: 'Channing',
    state: 'TX',
    latitude: '35.77452',
    longitude: '-102.41328',
    timeZoneId: 'America/Chicago',
  },
  '79019': {
    zip: '79019',
    city: 'Claude',
    state: 'TX',
    latitude: '35.117381',
    longitude: '-101.402531',
    timeZoneId: 'America/Chicago',
  },
  '79021': {
    zip: '79021',
    city: 'Cotton Center',
    state: 'TX',
    latitude: '34.069917',
    longitude: '-101.934182',
    timeZoneId: 'America/Chicago',
  },
  '79022': {
    zip: '79022',
    city: 'Dalhart',
    state: 'TX',
    latitude: '36.063187',
    longitude: '-102.519966',
    timeZoneId: 'America/Chicago',
  },
  '79024': {
    zip: '79024',
    city: 'Darrouzett',
    state: 'TX',
    latitude: '36.442021',
    longitude: '-100.326189',
    timeZoneId: 'America/Chicago',
  },
  '79025': {
    zip: '79025',
    city: 'Dawn',
    state: 'TX',
    latitude: '34.90988',
    longitude: '-102.20072',
    timeZoneId: 'America/Chicago',
  },
  '79027': {
    zip: '79027',
    city: 'Dimmitt',
    state: 'TX',
    latitude: '34.546258',
    longitude: '-102.316894',
    timeZoneId: 'America/Chicago',
  },
  '79029': {
    zip: '79029',
    city: 'Dumas',
    state: 'TX',
    latitude: '35.862274',
    longitude: '-101.966232',
    timeZoneId: 'America/Chicago',
  },
  '79031': {
    zip: '79031',
    city: 'Earth',
    state: 'TX',
    latitude: '34.228607',
    longitude: '-102.449215',
    timeZoneId: 'America/Chicago',
  },
  '79032': {
    zip: '79032',
    city: 'Edmonson',
    state: 'TX',
    latitude: '34.201031',
    longitude: '-101.786421',
    timeZoneId: 'America/Chicago',
  },
  '79033': {
    zip: '79033',
    city: 'Farnsworth',
    state: 'TX',
    latitude: '36.393458',
    longitude: '-100.760558',
    timeZoneId: 'America/Chicago',
  },
  '79034': {
    zip: '79034',
    city: 'Follett',
    state: 'TX',
    latitude: '36.432488',
    longitude: '-100.141057',
    timeZoneId: 'America/Chicago',
  },
  '79035': {
    zip: '79035',
    city: 'Friona',
    state: 'TX',
    latitude: '34.637034',
    longitude: '-102.723022',
    timeZoneId: 'America/Chicago',
  },
  '79036': {
    zip: '79036',
    city: 'Fritch',
    state: 'TX',
    latitude: '35.634953',
    longitude: '-101.594623',
    timeZoneId: 'America/Chicago',
  },
  '79039': {
    zip: '79039',
    city: 'Groom',
    state: 'TX',
    latitude: '35.205874',
    longitude: '-101.114366',
    timeZoneId: 'America/Chicago',
  },
  '79040': {
    zip: '79040',
    city: 'Gruver',
    state: 'TX',
    latitude: '36.262897',
    longitude: '-101.400686',
    timeZoneId: 'America/Chicago',
  },
  '79041': {
    zip: '79041',
    city: 'Hale Center',
    state: 'TX',
    latitude: '34.062137',
    longitude: '-101.847807',
    timeZoneId: 'America/Chicago',
  },
  '79042': {
    zip: '79042',
    city: 'Happy',
    state: 'TX',
    latitude: '34.744043',
    longitude: '-101.856272',
    timeZoneId: 'America/Chicago',
  },
  '79043': {
    zip: '79043',
    city: 'Hart',
    state: 'TX',
    latitude: '34.392192',
    longitude: '-102.114554',
    timeZoneId: 'America/Chicago',
  },
  '79044': {
    zip: '79044',
    city: 'Hartley',
    state: 'TX',
    latitude: '35.882162',
    longitude: '-102.397209',
    timeZoneId: 'America/Chicago',
  },
  '79045': {
    zip: '79045',
    city: 'Hereford',
    state: 'TX',
    latitude: '34.821088',
    longitude: '-102.402643',
    timeZoneId: 'America/Chicago',
  },
  '79046': {
    zip: '79046',
    city: 'Higgins',
    state: 'TX',
    latitude: '36.120498',
    longitude: '-100.024049',
    timeZoneId: 'America/Chicago',
  },
  '79051': {
    zip: '79051',
    city: 'Kerrick',
    state: 'TX',
    latitude: '36.48514',
    longitude: '-102.24545',
    timeZoneId: 'America/Chicago',
  },
  '79052': {
    zip: '79052',
    city: 'Kress',
    state: 'TX',
    latitude: '34.364723',
    longitude: '-101.748118',
    timeZoneId: 'America/Chicago',
  },
  '79053': {
    zip: '79053',
    city: 'Lazbuddie',
    state: 'TX',
    latitude: '34.394728',
    longitude: '-102.889672',
    timeZoneId: 'America/Chicago',
  },
  '79054': {
    zip: '79054',
    city: 'Lefors',
    state: 'TX',
    latitude: '35.436648',
    longitude: '-100.806171',
    timeZoneId: 'America/Chicago',
  },
  '79056': {
    zip: '79056',
    city: 'Lipscomb',
    state: 'TX',
    latitude: '36.222453',
    longitude: '-100.287339',
    timeZoneId: 'America/Chicago',
  },
  '79057': {
    zip: '79057',
    city: 'McLean',
    state: 'TX',
    latitude: '35.23497',
    longitude: '-100.598223',
    timeZoneId: 'America/Chicago',
  },
  '79058': {
    zip: '79058',
    city: 'Masterson',
    state: 'TX',
    latitude: '35.638349',
    longitude: '-101.959682',
    timeZoneId: 'America/Chicago',
  },
  '79059': {
    zip: '79059',
    city: 'Miami',
    state: 'TX',
    latitude: '35.6901',
    longitude: '-100.639117',
    timeZoneId: 'America/Chicago',
  },
  '79061': {
    zip: '79061',
    city: 'Mobeetie',
    state: 'TX',
    latitude: '35.535764',
    longitude: '-100.441825',
    timeZoneId: 'America/Chicago',
  },
  '79062': {
    zip: '79062',
    city: 'Morse',
    state: 'TX',
    latitude: '36.075797',
    longitude: '-101.518203',
    timeZoneId: 'America/Chicago',
  },
  '79063': {
    zip: '79063',
    city: 'Nazareth',
    state: 'TX',
    latitude: '34.551554',
    longitude: '-102.114728',
    timeZoneId: 'America/Chicago',
  },
  '79064': {
    zip: '79064',
    city: 'Olton',
    state: 'TX',
    latitude: '34.18345',
    longitude: '-102.136365',
    timeZoneId: 'America/Chicago',
  },
  '79065': {
    zip: '79065',
    city: 'Pampa',
    state: 'TX',
    latitude: '35.541949',
    longitude: '-100.962049',
    timeZoneId: 'America/Chicago',
  },
  '79066': {
    zip: '79066',
    city: 'Pampa',
    state: 'TX',
    latitude: '35.53148',
    longitude: '-100.976929',
    timeZoneId: 'America/Chicago',
  },
  '79068': {
    zip: '79068',
    city: 'Panhandle',
    state: 'TX',
    latitude: '35.348164',
    longitude: '-101.380933',
    timeZoneId: 'America/Chicago',
  },
  '79070': {
    zip: '79070',
    city: 'Perryton',
    state: 'TX',
    latitude: '36.390481',
    longitude: '-100.804889',
    timeZoneId: 'America/Chicago',
  },
  '79072': {
    zip: '79072',
    city: 'Plainview',
    state: 'TX',
    latitude: '34.192932',
    longitude: '-101.716093',
    timeZoneId: 'America/Chicago',
  },
  '79073': {
    zip: '79073',
    city: 'Plainview',
    state: 'TX',
    latitude: '34.190366',
    longitude: '-101.695464',
    timeZoneId: 'America/Chicago',
  },
  '79077': {
    zip: '79077',
    city: 'Samnorwood',
    state: 'TX',
    latitude: '34.84146',
    longitude: '-100.203785',
    timeZoneId: 'America/Chicago',
  },
  '79078': {
    zip: '79078',
    city: 'Sanford',
    state: 'TX',
    latitude: '35.700908',
    longitude: '-101.531288',
    timeZoneId: 'America/Chicago',
  },
  '79079': {
    zip: '79079',
    city: 'Shamrock',
    state: 'TX',
    latitude: '35.2161',
    longitude: '-100.24538',
    timeZoneId: 'America/Chicago',
  },
  '79080': {
    zip: '79080',
    city: 'Skellytown',
    state: 'TX',
    latitude: '35.574356',
    longitude: '-101.172571',
    timeZoneId: 'America/Chicago',
  },
  '79081': {
    zip: '79081',
    city: 'Spearman',
    state: 'TX',
    latitude: '36.191789',
    longitude: '-101.193662',
    timeZoneId: 'America/Chicago',
  },
  '79082': {
    zip: '79082',
    city: 'Springlake',
    state: 'TX',
    latitude: '34.249949',
    longitude: '-102.315536',
    timeZoneId: 'America/Chicago',
  },
  '79083': {
    zip: '79083',
    city: 'Stinnett',
    state: 'TX',
    latitude: '35.822707',
    longitude: '-101.443863',
    timeZoneId: 'America/Chicago',
  },
  '79084': {
    zip: '79084',
    city: 'Stratford',
    state: 'TX',
    latitude: '36.337253',
    longitude: '-102.072473',
    timeZoneId: 'America/Chicago',
  },
  '79085': {
    zip: '79085',
    city: 'Summerfield',
    state: 'TX',
    latitude: '34.711045',
    longitude: '-102.48051',
    timeZoneId: 'America/Chicago',
  },
  '79086': {
    zip: '79086',
    city: 'Sunray',
    state: 'TX',
    latitude: '36.017388',
    longitude: '-101.827006',
    timeZoneId: 'America/Chicago',
  },
  '79087': {
    zip: '79087',
    city: 'Texline',
    state: 'TX',
    latitude: '36.377897',
    longitude: '-103.02057',
    timeZoneId: 'America/Chicago',
  },
  '79088': {
    zip: '79088',
    city: 'Tulia',
    state: 'TX',
    latitude: '34.65164',
    longitude: '-101.49838',
    timeZoneId: 'America/Chicago',
  },
  '79091': {
    zip: '79091',
    city: 'Umbarger',
    state: 'TX',
    latitude: '34.88743',
    longitude: '-101.906946',
    timeZoneId: 'America/Chicago',
  },
  '79092': {
    zip: '79092',
    city: 'Vega',
    state: 'TX',
    latitude: '35.244444',
    longitude: '-102.426615',
    timeZoneId: 'America/Chicago',
  },
  '79093': {
    zip: '79093',
    city: 'Waka',
    state: 'TX',
    latitude: '36.279937',
    longitude: '-100.816771',
    timeZoneId: 'America/Chicago',
  },
  '79094': {
    zip: '79094',
    city: 'Wayside',
    state: 'TX',
    latitude: '34.812325',
    longitude: '-101.541694',
    timeZoneId: 'America/Chicago',
  },
  '79095': {
    zip: '79095',
    city: 'Wellington',
    state: 'TX',
    latitude: '34.851051',
    longitude: '-100.214468',
    timeZoneId: 'America/Chicago',
  },
  '79096': {
    zip: '79096',
    city: 'Wheeler',
    state: 'TX',
    latitude: '35.440865',
    longitude: '-100.274083',
    timeZoneId: 'America/Chicago',
  },
  '79097': {
    zip: '79097',
    city: 'White Deer',
    state: 'TX',
    latitude: '35.431497',
    longitude: '-101.175997',
    timeZoneId: 'America/Chicago',
  },
  '79098': {
    zip: '79098',
    city: 'Wildorado',
    state: 'TX',
    latitude: '35.21762',
    longitude: '-102.245498',
    timeZoneId: 'America/Chicago',
  },
  '79101': {
    zip: '79101',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.207679',
    longitude: '-101.839393',
    timeZoneId: 'America/Chicago',
  },
  '79102': {
    zip: '79102',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.200174',
    longitude: '-101.841114',
    timeZoneId: 'America/Chicago',
  },
  '79103': {
    zip: '79103',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.186941',
    longitude: '-101.804473',
    timeZoneId: 'America/Chicago',
  },
  '79104': {
    zip: '79104',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.197497',
    longitude: '-101.788687',
    timeZoneId: 'America/Chicago',
  },
  '79105': {
    zip: '79105',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.062024',
    longitude: '-101.786283',
    timeZoneId: 'America/Chicago',
  },
  '79106': {
    zip: '79106',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.212981',
    longitude: '-101.901659',
    timeZoneId: 'America/Chicago',
  },
  '79107': {
    zip: '79107',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.227161',
    longitude: '-101.810403',
    timeZoneId: 'America/Chicago',
  },
  '79108': {
    zip: '79108',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.276377',
    longitude: '-101.830355',
    timeZoneId: 'America/Chicago',
  },
  '79109': {
    zip: '79109',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.164304',
    longitude: '-101.888752',
    timeZoneId: 'America/Chicago',
  },
  '79110': {
    zip: '79110',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.149965',
    longitude: '-101.872798',
    timeZoneId: 'America/Chicago',
  },
  '79111': {
    zip: '79111',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.217768',
    longitude: '-101.689855',
    timeZoneId: 'America/Chicago',
  },
  '79114': {
    zip: '79114',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.165864',
    longitude: '-101.879301',
    timeZoneId: 'America/Chicago',
  },
  '79116': {
    zip: '79116',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.213165',
    longitude: '-101.885407',
    timeZoneId: 'America/Chicago',
  },
  '79117': {
    zip: '79117',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.223025',
    longitude: '-101.810326',
    timeZoneId: 'America/Chicago',
  },
  '79118': {
    zip: '79118',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.06543',
    longitude: '-101.852052',
    timeZoneId: 'America/Chicago',
  },
  '79119': {
    zip: '79119',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.066563',
    longitude: '-101.925375',
    timeZoneId: 'America/Chicago',
  },
  '79120': {
    zip: '79120',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.192767',
    longitude: '-101.760356',
    timeZoneId: 'America/Chicago',
  },
  '79121': {
    zip: '79121',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.175263',
    longitude: '-101.931175',
    timeZoneId: 'America/Chicago',
  },
  '79124': {
    zip: '79124',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.248182',
    longitude: '-101.907512',
    timeZoneId: 'America/Chicago',
  },
  '79159': {
    zip: '79159',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.237256',
    longitude: '-102.038624',
    timeZoneId: 'America/Chicago',
  },
  '79166': {
    zip: '79166',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.204349',
    longitude: '-101.831265',
    timeZoneId: 'America/Chicago',
  },
  '79168': {
    zip: '79168',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.157736',
    longitude: '-101.801454',
    timeZoneId: 'America/Chicago',
  },
  '79172': {
    zip: '79172',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.202037',
    longitude: '-101.833183',
    timeZoneId: 'America/Chicago',
  },
  '79174': {
    zip: '79174',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.203258',
    longitude: '-101.834253',
    timeZoneId: 'America/Chicago',
  },
  '79178': {
    zip: '79178',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.187943',
    longitude: '-101.848673',
    timeZoneId: 'America/Chicago',
  },
  '79185': {
    zip: '79185',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.083202',
    longitude: '-101.91847',
    timeZoneId: 'America/Chicago',
  },
  '79189': {
    zip: '79189',
    city: 'Amarillo',
    state: 'TX',
    latitude: '35.199732',
    longitude: '-101.816887',
    timeZoneId: 'America/Chicago',
  },
  '79201': {
    zip: '79201',
    city: 'Childress',
    state: 'TX',
    latitude: '34.4325',
    longitude: '-100.14582',
    timeZoneId: 'America/Chicago',
  },
  '79220': {
    zip: '79220',
    city: 'Afton',
    state: 'TX',
    latitude: '33.78608',
    longitude: '-100.734282',
    timeZoneId: 'America/Chicago',
  },
  '79221': {
    zip: '79221',
    city: 'Aiken',
    state: 'TX',
    latitude: '34.119881',
    longitude: '-101.446013',
    timeZoneId: 'America/Chicago',
  },
  '79223': {
    zip: '79223',
    city: 'Cee Vee',
    state: 'TX',
    latitude: '34.069136',
    longitude: '-100.277443',
    timeZoneId: 'America/Chicago',
  },
  '79225': {
    zip: '79225',
    city: 'Chillicothe',
    state: 'TX',
    latitude: '34.254787',
    longitude: '-99.514254',
    timeZoneId: 'America/Chicago',
  },
  '79226': {
    zip: '79226',
    city: 'Clarendon',
    state: 'TX',
    latitude: '34.936209',
    longitude: '-100.888972',
    timeZoneId: 'America/Chicago',
  },
  '79227': {
    zip: '79227',
    city: 'Crowell',
    state: 'TX',
    latitude: '33.988449',
    longitude: '-99.725128',
    timeZoneId: 'America/Chicago',
  },
  '79229': {
    zip: '79229',
    city: 'Dickens',
    state: 'TX',
    latitude: '33.619214',
    longitude: '-100.835382',
    timeZoneId: 'America/Chicago',
  },
  '79230': {
    zip: '79230',
    city: 'Dodson',
    state: 'TX',
    latitude: '34.732132',
    longitude: '-100.065137',
    timeZoneId: 'America/Chicago',
  },
  '79231': {
    zip: '79231',
    city: 'Dougherty',
    state: 'TX',
    latitude: '33.940185',
    longitude: '-101.301349',
    timeZoneId: 'America/Chicago',
  },
  '79233': {
    zip: '79233',
    city: 'Estelline',
    state: 'TX',
    latitude: '34.447478',
    longitude: '-100.688935',
    timeZoneId: 'America/Chicago',
  },
  '79234': {
    zip: '79234',
    city: 'Flomot',
    state: 'TX',
    latitude: '34.200068',
    longitude: '-100.997843',
    timeZoneId: 'America/Chicago',
  },
  '79235': {
    zip: '79235',
    city: 'Floydada',
    state: 'TX',
    latitude: '33.981778',
    longitude: '-101.339054',
    timeZoneId: 'America/Chicago',
  },
  '79236': {
    zip: '79236',
    city: 'Guthrie',
    state: 'TX',
    latitude: '33.616903',
    longitude: '-100.260065',
    timeZoneId: 'America/Chicago',
  },
  '79237': {
    zip: '79237',
    city: 'Hedley',
    state: 'TX',
    latitude: '34.86171',
    longitude: '-100.678751',
    timeZoneId: 'America/Chicago',
  },
  '79239': {
    zip: '79239',
    city: 'Lakeview',
    state: 'TX',
    latitude: '34.664285',
    longitude: '-100.759763',
    timeZoneId: 'America/Chicago',
  },
  '79240': {
    zip: '79240',
    city: 'Lelia Lake',
    state: 'TX',
    latitude: '34.862395',
    longitude: '-100.678668',
    timeZoneId: 'America/Chicago',
  },
  '79241': {
    zip: '79241',
    city: 'Lockney',
    state: 'TX',
    latitude: '34.119383',
    longitude: '-101.443723',
    timeZoneId: 'America/Chicago',
  },
  '79243': {
    zip: '79243',
    city: 'McAdoo',
    state: 'TX',
    latitude: '33.776371',
    longitude: '-100.963219',
    timeZoneId: 'America/Chicago',
  },
  '79244': {
    zip: '79244',
    city: 'Matador',
    state: 'TX',
    latitude: '34.012122',
    longitude: '-100.820634',
    timeZoneId: 'America/Chicago',
  },
  '79245': {
    zip: '79245',
    city: 'Memphis',
    state: 'TX',
    latitude: '34.727267',
    longitude: '-100.539322',
    timeZoneId: 'America/Chicago',
  },
  '79247': {
    zip: '79247',
    city: 'Odell',
    state: 'TX',
    latitude: '34.11639',
    longitude: '-99.34203',
    timeZoneId: 'America/Chicago',
  },
  '79248': {
    zip: '79248',
    city: 'Paducah',
    state: 'TX',
    latitude: '34.010623',
    longitude: '-100.302278',
    timeZoneId: 'America/Chicago',
  },
  '79250': {
    zip: '79250',
    city: 'Petersburg',
    state: 'TX',
    latitude: '33.873156',
    longitude: '-101.597301',
    timeZoneId: 'America/Chicago',
  },
  '79251': {
    zip: '79251',
    city: 'Quail',
    state: 'TX',
    latitude: '34.930795',
    longitude: '-100.443044',
    timeZoneId: 'America/Chicago',
  },
  '79252': {
    zip: '79252',
    city: 'Quanah',
    state: 'TX',
    latitude: '34.293199',
    longitude: '-99.745234',
    timeZoneId: 'America/Chicago',
  },
  '79255': {
    zip: '79255',
    city: 'Quitaque',
    state: 'TX',
    latitude: '34.363373',
    longitude: '-101.053649',
    timeZoneId: 'America/Chicago',
  },
  '79256': {
    zip: '79256',
    city: 'Roaring Springs',
    state: 'TX',
    latitude: '33.90155',
    longitude: '-100.855415',
    timeZoneId: 'America/Chicago',
  },
  '79257': {
    zip: '79257',
    city: 'Silverton',
    state: 'TX',
    latitude: '34.47161',
    longitude: '-101.308331',
    timeZoneId: 'America/Chicago',
  },
  '79258': {
    zip: '79258',
    city: 'South Plains',
    state: 'TX',
    latitude: '34.178649',
    longitude: '-101.30377',
    timeZoneId: 'America/Chicago',
  },
  '79259': {
    zip: '79259',
    city: 'Tell',
    state: 'TX',
    latitude: '34.357313',
    longitude: '-100.390466',
    timeZoneId: 'America/Chicago',
  },
  '79261': {
    zip: '79261',
    city: 'Turkey',
    state: 'TX',
    latitude: '34.396166',
    longitude: '-100.893325',
    timeZoneId: 'America/Chicago',
  },
  '79311': {
    zip: '79311',
    city: 'Abernathy',
    state: 'TX',
    latitude: '33.925509',
    longitude: '-101.911126',
    timeZoneId: 'America/Chicago',
  },
  '79312': {
    zip: '79312',
    city: 'Amherst',
    state: 'TX',
    latitude: '34.016464',
    longitude: '-102.416863',
    timeZoneId: 'America/Chicago',
  },
  '79313': {
    zip: '79313',
    city: 'Anton',
    state: 'TX',
    latitude: '33.812829',
    longitude: '-102.160095',
    timeZoneId: 'America/Chicago',
  },
  '79314': {
    zip: '79314',
    city: 'Bledsoe',
    state: 'TX',
    latitude: '33.638888',
    longitude: '-102.875067',
    timeZoneId: 'America/Chicago',
  },
  '79316': {
    zip: '79316',
    city: 'Brownfield',
    state: 'TX',
    latitude: '33.178367',
    longitude: '-102.272062',
    timeZoneId: 'America/Chicago',
  },
  '79322': {
    zip: '79322',
    city: 'Crosbyton',
    state: 'TX',
    latitude: '33.656196',
    longitude: '-101.240625',
    timeZoneId: 'America/Chicago',
  },
  '79323': {
    zip: '79323',
    city: 'Denver City',
    state: 'TX',
    latitude: '32.967963',
    longitude: '-102.828509',
    timeZoneId: 'America/Chicago',
  },
  '79324': {
    zip: '79324',
    city: 'Enochs',
    state: 'TX',
    latitude: '33.846757',
    longitude: '-102.766227',
    timeZoneId: 'America/Chicago',
  },
  '79325': {
    zip: '79325',
    city: 'Farwell',
    state: 'TX',
    latitude: '34.38753',
    longitude: '-103.04223',
    timeZoneId: 'America/Chicago',
  },
  '79326': {
    zip: '79326',
    city: 'Fieldton',
    state: 'TX',
    latitude: '34.090937',
    longitude: '-102.277811',
    timeZoneId: 'America/Chicago',
  },
  '79329': {
    zip: '79329',
    city: 'Idalou',
    state: 'TX',
    latitude: '33.663106',
    longitude: '-101.685738',
    timeZoneId: 'America/Chicago',
  },
  '79330': {
    zip: '79330',
    city: 'Justiceburg',
    state: 'TX',
    latitude: '33.180818',
    longitude: '-101.297478',
    timeZoneId: 'America/Chicago',
  },
  '79331': {
    zip: '79331',
    city: 'Lamesa',
    state: 'TX',
    latitude: '32.73451',
    longitude: '-101.95585',
    timeZoneId: 'America/Chicago',
  },
  '79336': {
    zip: '79336',
    city: 'Levelland',
    state: 'TX',
    latitude: '33.55416',
    longitude: '-102.384',
    timeZoneId: 'America/Chicago',
  },
  '79338': {
    zip: '79338',
    city: 'Levelland',
    state: 'TX',
    latitude: '33.592383',
    longitude: '-102.357242',
    timeZoneId: 'America/Chicago',
  },
  '79339': {
    zip: '79339',
    city: 'Littlefield',
    state: 'TX',
    latitude: '33.921519',
    longitude: '-102.335271',
    timeZoneId: 'America/Chicago',
  },
  '79342': {
    zip: '79342',
    city: 'Loop',
    state: 'TX',
    latitude: '32.91153',
    longitude: '-102.41787',
    timeZoneId: 'America/Chicago',
  },
  '79343': {
    zip: '79343',
    city: 'Lorenzo',
    state: 'TX',
    latitude: '33.673352',
    longitude: '-101.537127',
    timeZoneId: 'America/Chicago',
  },
  '79344': {
    zip: '79344',
    city: 'Maple',
    state: 'TX',
    latitude: '33.859618',
    longitude: '-102.948109',
    timeZoneId: 'America/Chicago',
  },
  '79345': {
    zip: '79345',
    city: 'Meadow',
    state: 'TX',
    latitude: '33.326384',
    longitude: '-102.332558',
    timeZoneId: 'America/Chicago',
  },
  '79346': {
    zip: '79346',
    city: 'Morton',
    state: 'TX',
    latitude: '33.723906',
    longitude: '-102.756807',
    timeZoneId: 'America/Chicago',
  },
  '79347': {
    zip: '79347',
    city: 'Muleshoe',
    state: 'TX',
    latitude: '34.224482',
    longitude: '-102.731179',
    timeZoneId: 'America/Chicago',
  },
  '79350': {
    zip: '79350',
    city: 'New Deal',
    state: 'TX',
    latitude: '33.71884',
    longitude: '-101.837287',
    timeZoneId: 'America/Chicago',
  },
  '79351': {
    zip: '79351',
    city: 'Odonnell',
    state: 'TX',
    latitude: '32.966548',
    longitude: '-101.82819',
    timeZoneId: 'America/Chicago',
  },
  '79353': {
    zip: '79353',
    city: 'Pep',
    state: 'TX',
    latitude: '33.793592',
    longitude: '-102.578907',
    timeZoneId: 'America/Chicago',
  },
  '79355': {
    zip: '79355',
    city: 'Plains',
    state: 'TX',
    latitude: '33.196972',
    longitude: '-102.829053',
    timeZoneId: 'America/Chicago',
  },
  '79356': {
    zip: '79356',
    city: 'Post',
    state: 'TX',
    latitude: '33.194872',
    longitude: '-101.378281',
    timeZoneId: 'America/Chicago',
  },
  '79357': {
    zip: '79357',
    city: 'Ralls',
    state: 'TX',
    latitude: '33.65376',
    longitude: '-101.37738',
    timeZoneId: 'America/Chicago',
  },
  '79358': {
    zip: '79358',
    city: 'Ropesville',
    state: 'TX',
    latitude: '33.414944',
    longitude: '-102.151007',
    timeZoneId: 'America/Chicago',
  },
  '79359': {
    zip: '79359',
    city: 'Seagraves',
    state: 'TX',
    latitude: '32.940274',
    longitude: '-102.560095',
    timeZoneId: 'America/Chicago',
  },
  '79360': {
    zip: '79360',
    city: 'Seminole',
    state: 'TX',
    latitude: '32.717704',
    longitude: '-102.674594',
    timeZoneId: 'America/Chicago',
  },
  '79363': {
    zip: '79363',
    city: 'Shallowater',
    state: 'TX',
    latitude: '33.688333',
    longitude: '-101.99376',
    timeZoneId: 'America/Chicago',
  },
  '79364': {
    zip: '79364',
    city: 'Slaton',
    state: 'TX',
    latitude: '33.436854',
    longitude: '-101.646192',
    timeZoneId: 'America/Chicago',
  },
  '79366': {
    zip: '79366',
    city: 'Ransom Canyon',
    state: 'TX',
    latitude: '33.533874',
    longitude: '-101.681093',
    timeZoneId: 'America/Chicago',
  },
  '79367': {
    zip: '79367',
    city: 'Smyer',
    state: 'TX',
    latitude: '33.586042',
    longitude: '-102.161581',
    timeZoneId: 'America/Chicago',
  },
  '79369': {
    zip: '79369',
    city: 'Spade',
    state: 'TX',
    latitude: '33.933813',
    longitude: '-102.238376',
    timeZoneId: 'America/Chicago',
  },
  '79370': {
    zip: '79370',
    city: 'Spur',
    state: 'TX',
    latitude: '33.478932',
    longitude: '-100.858511',
    timeZoneId: 'America/Chicago',
  },
  '79371': {
    zip: '79371',
    city: 'Sudan',
    state: 'TX',
    latitude: '34.062057',
    longitude: '-102.523858',
    timeZoneId: 'America/Chicago',
  },
  '79372': {
    zip: '79372',
    city: 'Sundown',
    state: 'TX',
    latitude: '33.455283',
    longitude: '-102.48829',
    timeZoneId: 'America/Chicago',
  },
  '79373': {
    zip: '79373',
    city: 'Tahoka',
    state: 'TX',
    latitude: '33.169336',
    longitude: '-101.802852',
    timeZoneId: 'America/Chicago',
  },
  '79376': {
    zip: '79376',
    city: 'Tokio',
    state: 'TX',
    latitude: '33.182296',
    longitude: '-102.564204',
    timeZoneId: 'America/Chicago',
  },
  '79377': {
    zip: '79377',
    city: 'Welch',
    state: 'TX',
    latitude: '32.838864',
    longitude: '-102.099742',
    timeZoneId: 'America/Chicago',
  },
  '79378': {
    zip: '79378',
    city: 'Wellman',
    state: 'TX',
    latitude: '33.044827',
    longitude: '-102.427981',
    timeZoneId: 'America/Chicago',
  },
  '79379': {
    zip: '79379',
    city: 'Whiteface',
    state: 'TX',
    latitude: '33.508578',
    longitude: '-102.707047',
    timeZoneId: 'America/Chicago',
  },
  '79380': {
    zip: '79380',
    city: 'Whitharral',
    state: 'TX',
    latitude: '33.736476',
    longitude: '-102.325231',
    timeZoneId: 'America/Chicago',
  },
  '79381': {
    zip: '79381',
    city: 'Wilson',
    state: 'TX',
    latitude: '33.321238',
    longitude: '-101.724872',
    timeZoneId: 'America/Chicago',
  },
  '79382': {
    zip: '79382',
    city: 'Wolfforth',
    state: 'TX',
    latitude: '33.505467',
    longitude: '-102.009006',
    timeZoneId: 'America/Chicago',
  },
  '79383': {
    zip: '79383',
    city: 'New Home',
    state: 'TX',
    latitude: '33.20542',
    longitude: '-101.843641',
    timeZoneId: 'America/Chicago',
  },
  '79401': {
    zip: '79401',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.584601',
    longitude: '-101.846676',
    timeZoneId: 'America/Chicago',
  },
  '79402': {
    zip: '79402',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.588324',
    longitude: '-101.846875',
    timeZoneId: 'America/Chicago',
  },
  '79403': {
    zip: '79403',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.603447',
    longitude: '-101.814744',
    timeZoneId: 'America/Chicago',
  },
  '79404': {
    zip: '79404',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.553575',
    longitude: '-101.821365',
    timeZoneId: 'America/Chicago',
  },
  '79406': {
    zip: '79406',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.580255',
    longitude: '-101.880255',
    timeZoneId: 'America/Chicago',
  },
  '79407': {
    zip: '79407',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.569595',
    longitude: '-101.948489',
    timeZoneId: 'America/Chicago',
  },
  '79408': {
    zip: '79408',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.580757',
    longitude: '-101.977652',
    timeZoneId: 'America/Chicago',
  },
  '79409': {
    zip: '79409',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.586056',
    longitude: '-101.853812',
    timeZoneId: 'America/Chicago',
  },
  '79410': {
    zip: '79410',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.568027',
    longitude: '-101.89465',
    timeZoneId: 'America/Chicago',
  },
  '79411': {
    zip: '79411',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.570446',
    longitude: '-101.861236',
    timeZoneId: 'America/Chicago',
  },
  '79412': {
    zip: '79412',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.54512',
    longitude: '-101.854537',
    timeZoneId: 'America/Chicago',
  },
  '79413': {
    zip: '79413',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.543755',
    longitude: '-101.890228',
    timeZoneId: 'America/Chicago',
  },
  '79414': {
    zip: '79414',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.548719',
    longitude: '-101.922888',
    timeZoneId: 'America/Chicago',
  },
  '79415': {
    zip: '79415',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.605722',
    longitude: '-101.873513',
    timeZoneId: 'America/Chicago',
  },
  '79416': {
    zip: '79416',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.589644',
    longitude: '-101.934284',
    timeZoneId: 'America/Chicago',
  },
  '79423': {
    zip: '79423',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.5153',
    longitude: '-101.882849',
    timeZoneId: 'America/Chicago',
  },
  '79424': {
    zip: '79424',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.522541',
    longitude: '-101.927003',
    timeZoneId: 'America/Chicago',
  },
  '79430': {
    zip: '79430',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.592746',
    longitude: '-101.898787',
    timeZoneId: 'America/Chicago',
  },
  '79452': {
    zip: '79452',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.548871',
    longitude: '-101.847573',
    timeZoneId: 'America/Chicago',
  },
  '79453': {
    zip: '79453',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.57648',
    longitude: '-101.854349',
    timeZoneId: 'America/Chicago',
  },
  '79457': {
    zip: '79457',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.584608',
    longitude: '-101.851658',
    timeZoneId: 'America/Chicago',
  },
  '79464': {
    zip: '79464',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.53547',
    longitude: '-101.916226',
    timeZoneId: 'America/Chicago',
  },
  '79490': {
    zip: '79490',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.576556',
    longitude: '-101.982812',
    timeZoneId: 'America/Chicago',
  },
  '79491': {
    zip: '79491',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.504419',
    longitude: '-101.877558',
    timeZoneId: 'America/Chicago',
  },
  '79493': {
    zip: '79493',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.547975',
    longitude: '-101.884706',
    timeZoneId: 'America/Chicago',
  },
  '79499': {
    zip: '79499',
    city: 'Lubbock',
    state: 'TX',
    latitude: '33.608049',
    longitude: '-101.907364',
    timeZoneId: 'America/Chicago',
  },
  '79501': {
    zip: '79501',
    city: 'Anson',
    state: 'TX',
    latitude: '32.759811',
    longitude: '-99.892214',
    timeZoneId: 'America/Chicago',
  },
  '79502': {
    zip: '79502',
    city: 'Aspermont',
    state: 'TX',
    latitude: '33.1092',
    longitude: '-100.2708',
    timeZoneId: 'America/Chicago',
  },
  '79503': {
    zip: '79503',
    city: 'Avoca',
    state: 'TX',
    latitude: '32.892087',
    longitude: '-99.694537',
    timeZoneId: 'America/Chicago',
  },
  '79504': {
    zip: '79504',
    city: 'Baird',
    state: 'TX',
    latitude: '32.395547',
    longitude: '-99.397885',
    timeZoneId: 'America/Chicago',
  },
  '79505': {
    zip: '79505',
    city: 'Benjamin',
    state: 'TX',
    latitude: '33.633757',
    longitude: '-99.797004',
    timeZoneId: 'America/Chicago',
  },
  '79506': {
    zip: '79506',
    city: 'Blackwell',
    state: 'TX',
    latitude: '32.080538',
    longitude: '-100.322878',
    timeZoneId: 'America/Chicago',
  },
  '79508': {
    zip: '79508',
    city: 'Buffalo Gap',
    state: 'TX',
    latitude: '32.338793',
    longitude: '-99.753848',
    timeZoneId: 'America/Chicago',
  },
  '79510': {
    zip: '79510',
    city: 'Clyde',
    state: 'TX',
    latitude: '32.407474',
    longitude: '-99.501877',
    timeZoneId: 'America/Chicago',
  },
  '79511': {
    zip: '79511',
    city: 'Coahoma',
    state: 'TX',
    latitude: '32.296057',
    longitude: '-101.313531',
    timeZoneId: 'America/Chicago',
  },
  '79512': {
    zip: '79512',
    city: 'Colorado City',
    state: 'TX',
    latitude: '32.394374',
    longitude: '-100.859048',
    timeZoneId: 'America/Chicago',
  },
  '79516': {
    zip: '79516',
    city: 'Dunn',
    state: 'TX',
    latitude: '32.5669',
    longitude: '-100.885554',
    timeZoneId: 'America/Chicago',
  },
  '79517': {
    zip: '79517',
    city: 'Fluvanna',
    state: 'TX',
    latitude: '32.816035',
    longitude: '-101.085495',
    timeZoneId: 'America/Chicago',
  },
  '79518': {
    zip: '79518',
    city: 'Girard',
    state: 'TX',
    latitude: '33.227158',
    longitude: '-100.558396',
    timeZoneId: 'America/Chicago',
  },
  '79519': {
    zip: '79519',
    city: 'Goldsboro',
    state: 'TX',
    latitude: '32.049645',
    longitude: '-99.654439',
    timeZoneId: 'America/Chicago',
  },
  '79520': {
    zip: '79520',
    city: 'Hamlin',
    state: 'TX',
    latitude: '32.88122',
    longitude: '-100.126722',
    timeZoneId: 'America/Chicago',
  },
  '79521': {
    zip: '79521',
    city: 'Haskell',
    state: 'TX',
    latitude: '33.159597',
    longitude: '-99.735874',
    timeZoneId: 'America/Chicago',
  },
  '79525': {
    zip: '79525',
    city: 'Hawley',
    state: 'TX',
    latitude: '32.595013',
    longitude: '-99.864848',
    timeZoneId: 'America/Chicago',
  },
  '79526': {
    zip: '79526',
    city: 'Hermleigh',
    state: 'TX',
    latitude: '32.636276',
    longitude: '-100.757915',
    timeZoneId: 'America/Chicago',
  },
  '79527': {
    zip: '79527',
    city: 'Ira',
    state: 'TX',
    latitude: '32.62137',
    longitude: '-101.06633',
    timeZoneId: 'America/Chicago',
  },
  '79528': {
    zip: '79528',
    city: 'Jayton',
    state: 'TX',
    latitude: '33.264461',
    longitude: '-100.581108',
    timeZoneId: 'America/Chicago',
  },
  '79529': {
    zip: '79529',
    city: 'Knox City',
    state: 'TX',
    latitude: '33.41528',
    longitude: '-99.812074',
    timeZoneId: 'America/Chicago',
  },
  '79530': {
    zip: '79530',
    city: 'Lawn',
    state: 'TX',
    latitude: '32.125523',
    longitude: '-99.708958',
    timeZoneId: 'America/Chicago',
  },
  '79532': {
    zip: '79532',
    city: 'Loraine',
    state: 'TX',
    latitude: '32.406737',
    longitude: '-100.718705',
    timeZoneId: 'America/Chicago',
  },
  '79533': {
    zip: '79533',
    city: 'Lueders',
    state: 'TX',
    latitude: '32.760281',
    longitude: '-99.687839',
    timeZoneId: 'America/Chicago',
  },
  '79534': {
    zip: '79534',
    city: 'Mc Caulley',
    state: 'TX',
    latitude: '32.79832',
    longitude: '-100.225032',
    timeZoneId: 'America/Chicago',
  },
  '79535': {
    zip: '79535',
    city: 'Maryneal',
    state: 'TX',
    latitude: '32.164395',
    longitude: '-100.550022',
    timeZoneId: 'America/Chicago',
  },
  '79536': {
    zip: '79536',
    city: 'Merkel',
    state: 'TX',
    latitude: '32.44986',
    longitude: '-99.98874',
    timeZoneId: 'America/Chicago',
  },
  '79537': {
    zip: '79537',
    city: 'Nolan',
    state: 'TX',
    latitude: '32.284164',
    longitude: '-100.247928',
    timeZoneId: 'America/Chicago',
  },
  '79538': {
    zip: '79538',
    city: 'Novice',
    state: 'TX',
    latitude: '32.006901',
    longitude: '-99.564507',
    timeZoneId: 'America/Chicago',
  },
  '79539': {
    zip: '79539',
    city: 'O Brien',
    state: 'TX',
    latitude: '33.365964',
    longitude: '-99.85701',
    timeZoneId: 'America/Chicago',
  },
  '79540': {
    zip: '79540',
    city: 'Old Glory',
    state: 'TX',
    latitude: '33.264549',
    longitude: '-100.140769',
    timeZoneId: 'America/Chicago',
  },
  '79541': {
    zip: '79541',
    city: 'Ovalo',
    state: 'TX',
    latitude: '32.134044',
    longitude: '-99.829405',
    timeZoneId: 'America/Chicago',
  },
  '79543': {
    zip: '79543',
    city: 'Roby',
    state: 'TX',
    latitude: '32.72034',
    longitude: '-100.41918',
    timeZoneId: 'America/Chicago',
  },
  '79544': {
    zip: '79544',
    city: 'Rochester',
    state: 'TX',
    latitude: '33.301989',
    longitude: '-99.846699',
    timeZoneId: 'America/Chicago',
  },
  '79545': {
    zip: '79545',
    city: 'Roscoe',
    state: 'TX',
    latitude: '32.440465',
    longitude: '-100.529095',
    timeZoneId: 'America/Chicago',
  },
  '79546': {
    zip: '79546',
    city: 'Rotan',
    state: 'TX',
    latitude: '32.854577',
    longitude: '-100.463181',
    timeZoneId: 'America/Chicago',
  },
  '79547': {
    zip: '79547',
    city: 'Rule',
    state: 'TX',
    latitude: '33.177322',
    longitude: '-99.891093',
    timeZoneId: 'America/Chicago',
  },
  '79548': {
    zip: '79548',
    city: 'Rule',
    state: 'TX',
    latitude: '33.184444',
    longitude: '-99.893333',
    timeZoneId: 'America/Chicago',
  },
  '79549': {
    zip: '79549',
    city: 'Snyder',
    state: 'TX',
    latitude: '32.76468',
    longitude: '-100.85892',
    timeZoneId: 'America/Chicago',
  },
  '79550': {
    zip: '79550',
    city: 'Snyder',
    state: 'TX',
    latitude: '32.718525',
    longitude: '-100.919601',
    timeZoneId: 'America/Chicago',
  },
  '79553': {
    zip: '79553',
    city: 'Stamford',
    state: 'TX',
    latitude: '32.94822',
    longitude: '-99.76986',
    timeZoneId: 'America/Chicago',
  },
  '79556': {
    zip: '79556',
    city: 'Sweetwater',
    state: 'TX',
    latitude: '32.46594',
    longitude: '-100.38624',
    timeZoneId: 'America/Chicago',
  },
  '79560': {
    zip: '79560',
    city: 'Sylvester',
    state: 'TX',
    latitude: '32.715578',
    longitude: '-100.226775',
    timeZoneId: 'America/Chicago',
  },
  '79561': {
    zip: '79561',
    city: 'Trent',
    state: 'TX',
    latitude: '32.489551',
    longitude: '-100.12039',
    timeZoneId: 'America/Chicago',
  },
  '79562': {
    zip: '79562',
    city: 'Tuscola',
    state: 'TX',
    latitude: '32.199975',
    longitude: '-99.911111',
    timeZoneId: 'America/Chicago',
  },
  '79563': {
    zip: '79563',
    city: 'Tye',
    state: 'TX',
    latitude: '32.450724',
    longitude: '-99.867551',
    timeZoneId: 'America/Chicago',
  },
  '79565': {
    zip: '79565',
    city: 'Westbrook',
    state: 'TX',
    latitude: '32.359444',
    longitude: '-101.013905',
    timeZoneId: 'America/Chicago',
  },
  '79566': {
    zip: '79566',
    city: 'Wingate',
    state: 'TX',
    latitude: '32.017027',
    longitude: '-100.147498',
    timeZoneId: 'America/Chicago',
  },
  '79567': {
    zip: '79567',
    city: 'Winters',
    state: 'TX',
    latitude: '31.97616',
    longitude: '-99.90246',
    timeZoneId: 'America/Chicago',
  },
  '79601': {
    zip: '79601',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.47902',
    longitude: '-99.70002',
    timeZoneId: 'America/Chicago',
  },
  '79602': {
    zip: '79602',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.429788',
    longitude: '-99.719438',
    timeZoneId: 'America/Chicago',
  },
  '79603': {
    zip: '79603',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.470693',
    longitude: '-99.765045',
    timeZoneId: 'America/Chicago',
  },
  '79604': {
    zip: '79604',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.453184',
    longitude: '-99.732364',
    timeZoneId: 'America/Chicago',
  },
  '79605': {
    zip: '79605',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.430449',
    longitude: '-99.776943',
    timeZoneId: 'America/Chicago',
  },
  '79606': {
    zip: '79606',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.3868',
    longitude: '-99.771092',
    timeZoneId: 'America/Chicago',
  },
  '79607': {
    zip: '79607',
    city: 'Dyess AFB',
    state: 'TX',
    latitude: '32.431896',
    longitude: '-99.811677',
    timeZoneId: 'America/Chicago',
  },
  '79608': {
    zip: '79608',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.418222',
    longitude: '-99.748423',
    timeZoneId: 'America/Chicago',
  },
  '79697': {
    zip: '79697',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.432945',
    longitude: '-99.747844',
    timeZoneId: 'America/Chicago',
  },
  '79698': {
    zip: '79698',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.474862',
    longitude: '-99.732695',
    timeZoneId: 'America/Chicago',
  },
  '79699': {
    zip: '79699',
    city: 'Abilene',
    state: 'TX',
    latitude: '32.470981',
    longitude: '-99.707622',
    timeZoneId: 'America/Chicago',
  },
  '79701': {
    zip: '79701',
    city: 'Midland',
    state: 'TX',
    latitude: '31.989057',
    longitude: '-102.0819',
    timeZoneId: 'America/Chicago',
  },
  '79702': {
    zip: '79702',
    city: 'Midland',
    state: 'TX',
    latitude: '31.974932',
    longitude: '-102.07866',
    timeZoneId: 'America/Chicago',
  },
  '79703': {
    zip: '79703',
    city: 'Midland',
    state: 'TX',
    latitude: '31.975158',
    longitude: '-102.116534',
    timeZoneId: 'America/Chicago',
  },
  '79704': {
    zip: '79704',
    city: 'Midland',
    state: 'TX',
    latitude: '31.911944',
    longitude: '-102.118442',
    timeZoneId: 'America/Chicago',
  },
  '79705': {
    zip: '79705',
    city: 'Midland',
    state: 'TX',
    latitude: '32.030406',
    longitude: '-102.091318',
    timeZoneId: 'America/Chicago',
  },
  '79706': {
    zip: '79706',
    city: 'Midland',
    state: 'TX',
    latitude: '31.989044',
    longitude: '-102.073733',
    timeZoneId: 'America/Chicago',
  },
  '79707': {
    zip: '79707',
    city: 'Midland',
    state: 'TX',
    latitude: '32.022609',
    longitude: '-102.141467',
    timeZoneId: 'America/Chicago',
  },
  '79708': {
    zip: '79708',
    city: 'Midland',
    state: 'TX',
    latitude: '32.018202',
    longitude: '-102.123854',
    timeZoneId: 'America/Chicago',
  },
  '79710': {
    zip: '79710',
    city: 'Midland',
    state: 'TX',
    latitude: '32.061722',
    longitude: '-102.021755',
    timeZoneId: 'America/Chicago',
  },
  '79711': {
    zip: '79711',
    city: 'Midland',
    state: 'TX',
    latitude: '31.997626',
    longitude: '-102.077428',
    timeZoneId: 'America/Chicago',
  },
  '79712': {
    zip: '79712',
    city: 'Midland',
    state: 'TX',
    latitude: '31.947125',
    longitude: '-102.171522',
    timeZoneId: 'America/Chicago',
  },
  '79713': {
    zip: '79713',
    city: 'Ackerly',
    state: 'TX',
    latitude: '32.632701',
    longitude: '-101.800055',
    timeZoneId: 'America/Chicago',
  },
  '79714': {
    zip: '79714',
    city: 'Andrews',
    state: 'TX',
    latitude: '32.32711',
    longitude: '-102.548597',
    timeZoneId: 'America/Chicago',
  },
  '79718': {
    zip: '79718',
    city: 'Balmorhea',
    state: 'TX',
    latitude: '31.034627',
    longitude: '-103.64271',
    timeZoneId: 'America/Chicago',
  },
  '79719': {
    zip: '79719',
    city: 'Barstow',
    state: 'TX',
    latitude: '31.480361',
    longitude: '-103.329587',
    timeZoneId: 'America/Chicago',
  },
  '79720': {
    zip: '79720',
    city: 'Big Spring',
    state: 'TX',
    latitude: '32.51461',
    longitude: '-101.57574',
    timeZoneId: 'America/Chicago',
  },
  '79721': {
    zip: '79721',
    city: 'Big Spring',
    state: 'TX',
    latitude: '32.250099',
    longitude: '-101.457516',
    timeZoneId: 'America/Chicago',
  },
  '79730': {
    zip: '79730',
    city: 'Coyanosa',
    state: 'TX',
    latitude: '31.199049',
    longitude: '-103.064347',
    timeZoneId: 'America/Chicago',
  },
  '79731': {
    zip: '79731',
    city: 'Crane',
    state: 'TX',
    latitude: '31.392825',
    longitude: '-102.35161',
    timeZoneId: 'America/Chicago',
  },
  '79733': {
    zip: '79733',
    city: 'Forsan',
    state: 'TX',
    latitude: '32.111014',
    longitude: '-101.365484',
    timeZoneId: 'America/Chicago',
  },
  '79734': {
    zip: '79734',
    city: 'Fort Davis',
    state: 'TX',
    latitude: '30.709108',
    longitude: '-103.914129',
    timeZoneId: 'America/Chicago',
  },
  '79735': {
    zip: '79735',
    city: 'Fort Stockton',
    state: 'TX',
    latitude: '30.887216',
    longitude: '-102.881646',
    timeZoneId: 'America/Chicago',
  },
  '79738': {
    zip: '79738',
    city: 'Gail',
    state: 'TX',
    latitude: '32.746164',
    longitude: '-101.431131',
    timeZoneId: 'America/Chicago',
  },
  '79739': {
    zip: '79739',
    city: 'Garden City',
    state: 'TX',
    latitude: '31.867441',
    longitude: '-101.521411',
    timeZoneId: 'America/Chicago',
  },
  '79740': {
    zip: '79740',
    city: 'Girvin',
    state: 'TX',
    latitude: '31.061833',
    longitude: '-102.390693',
    timeZoneId: 'America/Chicago',
  },
  '79741': {
    zip: '79741',
    city: 'Goldsmith',
    state: 'TX',
    latitude: '31.98341',
    longitude: '-102.612281',
    timeZoneId: 'America/Chicago',
  },
  '79742': {
    zip: '79742',
    city: 'Grandfalls',
    state: 'TX',
    latitude: '31.374995',
    longitude: '-102.887887',
    timeZoneId: 'America/Chicago',
  },
  '79743': {
    zip: '79743',
    city: 'Imperial',
    state: 'TX',
    latitude: '31.174613',
    longitude: '-102.7585',
    timeZoneId: 'America/Chicago',
  },
  '79744': {
    zip: '79744',
    city: 'Iraan',
    state: 'TX',
    latitude: '30.914266',
    longitude: '-101.898345',
    timeZoneId: 'America/Chicago',
  },
  '79745': {
    zip: '79745',
    city: 'Kermit',
    state: 'TX',
    latitude: '31.853325',
    longitude: '-103.090864',
    timeZoneId: 'America/Chicago',
  },
  '79748': {
    zip: '79748',
    city: 'Knott',
    state: 'TX',
    latitude: '32.402484',
    longitude: '-101.64038',
    timeZoneId: 'America/Chicago',
  },
  '79749': {
    zip: '79749',
    city: 'Lenorah',
    state: 'TX',
    latitude: '32.409782',
    longitude: '-101.784514',
    timeZoneId: 'America/Chicago',
  },
  '79752': {
    zip: '79752',
    city: 'Mc Camey',
    state: 'TX',
    latitude: '31.131143',
    longitude: '-102.220417',
    timeZoneId: 'America/Chicago',
  },
  '79754': {
    zip: '79754',
    city: 'Mentone',
    state: 'TX',
    latitude: '31.786479',
    longitude: '-103.629093',
    timeZoneId: 'America/Chicago',
  },
  '79755': {
    zip: '79755',
    city: 'Midkiff',
    state: 'TX',
    latitude: '31.387007',
    longitude: '-101.958596',
    timeZoneId: 'America/Chicago',
  },
  '79756': {
    zip: '79756',
    city: 'Monahans',
    state: 'TX',
    latitude: '31.58163',
    longitude: '-102.896507',
    timeZoneId: 'America/Chicago',
  },
  '79758': {
    zip: '79758',
    city: 'Gardendale',
    state: 'TX',
    latitude: '32.025936',
    longitude: '-102.385544',
    timeZoneId: 'America/Chicago',
  },
  '79759': {
    zip: '79759',
    city: 'Notrees',
    state: 'TX',
    latitude: '31.918114',
    longitude: '-102.756169',
    timeZoneId: 'America/Chicago',
  },
  '79760': {
    zip: '79760',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.827713',
    longitude: '-102.434573',
    timeZoneId: 'America/Chicago',
  },
  '79761': {
    zip: '79761',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.854911',
    longitude: '-102.352859',
    timeZoneId: 'America/Chicago',
  },
  '79762': {
    zip: '79762',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.888806',
    longitude: '-102.365185',
    timeZoneId: 'America/Chicago',
  },
  '79763': {
    zip: '79763',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.841408',
    longitude: '-102.429466',
    timeZoneId: 'America/Chicago',
  },
  '79764': {
    zip: '79764',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.879344',
    longitude: '-102.43678',
    timeZoneId: 'America/Chicago',
  },
  '79765': {
    zip: '79765',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.934041',
    longitude: '-102.385595',
    timeZoneId: 'America/Chicago',
  },
  '79766': {
    zip: '79766',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.812902',
    longitude: '-102.338633',
    timeZoneId: 'America/Chicago',
  },
  '79768': {
    zip: '79768',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.845279',
    longitude: '-102.364363',
    timeZoneId: 'America/Chicago',
  },
  '79769': {
    zip: '79769',
    city: 'Odessa',
    state: 'TX',
    latitude: '31.844554',
    longitude: '-102.363978',
    timeZoneId: 'America/Chicago',
  },
  '79770': {
    zip: '79770',
    city: 'Orla',
    state: 'TX',
    latitude: '31.82789',
    longitude: '-103.91303',
    timeZoneId: 'America/Chicago',
  },
  '79772': {
    zip: '79772',
    city: 'Pecos',
    state: 'TX',
    latitude: '31.375544',
    longitude: '-103.523013',
    timeZoneId: 'America/Chicago',
  },
  '79776': {
    zip: '79776',
    city: 'Penwell',
    state: 'TX',
    latitude: '31.735015',
    longitude: '-102.590183',
    timeZoneId: 'America/Chicago',
  },
  '79777': {
    zip: '79777',
    city: 'Pyote',
    state: 'TX',
    latitude: '31.537597',
    longitude: '-103.125871',
    timeZoneId: 'America/Chicago',
  },
  '79778': {
    zip: '79778',
    city: 'Rankin',
    state: 'TX',
    latitude: '31.225493',
    longitude: '-101.943149',
    timeZoneId: 'America/Chicago',
  },
  '79780': {
    zip: '79780',
    city: 'Saragosa',
    state: 'TX',
    latitude: '31.024343',
    longitude: '-103.661626',
    timeZoneId: 'America/Chicago',
  },
  '79781': {
    zip: '79781',
    city: 'Sheffield',
    state: 'TX',
    latitude: '30.687886',
    longitude: '-101.846066',
    timeZoneId: 'America/Chicago',
  },
  '79782': {
    zip: '79782',
    city: 'Stanton',
    state: 'TX',
    latitude: '32.131726',
    longitude: '-101.792877',
    timeZoneId: 'America/Chicago',
  },
  '79783': {
    zip: '79783',
    city: 'Tarzan',
    state: 'TX',
    latitude: '32.395431',
    longitude: '-102.032317',
    timeZoneId: 'America/Chicago',
  },
  '79785': {
    zip: '79785',
    city: 'Toyah',
    state: 'TX',
    latitude: '31.313466',
    longitude: '-103.792586',
    timeZoneId: 'America/Chicago',
  },
  '79786': {
    zip: '79786',
    city: 'Toyahvale',
    state: 'TX',
    latitude: '30.942795',
    longitude: '-103.769038',
    timeZoneId: 'America/Chicago',
  },
  '79788': {
    zip: '79788',
    city: 'Wickett',
    state: 'TX',
    latitude: '31.565637',
    longitude: '-103.005351',
    timeZoneId: 'America/Chicago',
  },
  '79789': {
    zip: '79789',
    city: 'Wink',
    state: 'TX',
    latitude: '31.750379',
    longitude: '-103.157737',
    timeZoneId: 'America/Chicago',
  },
  '79821': {
    zip: '79821',
    city: 'Anthony',
    state: 'TX',
    latitude: '31.970939',
    longitude: '-106.597447',
    timeZoneId: 'America/Denver',
  },
  '79830': {
    zip: '79830',
    city: 'Alpine',
    state: 'TX',
    latitude: '30.347023',
    longitude: '-103.658015',
    timeZoneId: 'America/Chicago',
  },
  '79831': {
    zip: '79831',
    city: 'Alpine',
    state: 'TX',
    latitude: '30.363181',
    longitude: '-103.666231',
    timeZoneId: 'America/Chicago',
  },
  '79832': {
    zip: '79832',
    city: 'Alpine',
    state: 'TX',
    latitude: '30.36235',
    longitude: '-103.672744',
    timeZoneId: 'America/Chicago',
  },
  '79834': {
    zip: '79834',
    city: 'Big Bend National Park',
    state: 'TX',
    latitude: '29.29099',
    longitude: '-103.182413',
    timeZoneId: 'America/Chicago',
  },
  '79835': {
    zip: '79835',
    city: 'Canutillo',
    state: 'TX',
    latitude: '31.929899',
    longitude: '-106.59169',
    timeZoneId: 'America/Denver',
  },
  '79836': {
    zip: '79836',
    city: 'Clint',
    state: 'TX',
    latitude: '31.593077',
    longitude: '-106.239404',
    timeZoneId: 'America/Denver',
  },
  '79837': {
    zip: '79837',
    city: 'Dell City',
    state: 'TX',
    latitude: '31.957919',
    longitude: '-105.20244',
    timeZoneId: 'America/Denver',
  },
  '79838': {
    zip: '79838',
    city: 'Fabens',
    state: 'TX',
    latitude: '31.496203',
    longitude: '-106.154272',
    timeZoneId: 'America/Denver',
  },
  '79839': {
    zip: '79839',
    city: 'Fort Hancock',
    state: 'TX',
    latitude: '31.29751',
    longitude: '-105.83757',
    timeZoneId: 'America/Denver',
  },
  '79842': {
    zip: '79842',
    city: 'Marathon',
    state: 'TX',
    latitude: '30.125699',
    longitude: '-103.140048',
    timeZoneId: 'America/Chicago',
  },
  '79843': {
    zip: '79843',
    city: 'Marfa',
    state: 'TX',
    latitude: '30.307094',
    longitude: '-104.037151',
    timeZoneId: 'America/Chicago',
  },
  '79845': {
    zip: '79845',
    city: 'Presidio',
    state: 'TX',
    latitude: '29.568952',
    longitude: '-104.374495',
    timeZoneId: 'America/Chicago',
  },
  '79846': {
    zip: '79846',
    city: 'Redford',
    state: 'TX',
    latitude: '29.44',
    longitude: '-104.18',
    timeZoneId: 'America/Chicago',
  },
  '79847': {
    zip: '79847',
    city: 'Salt Flat',
    state: 'TX',
    latitude: '31.808783',
    longitude: '-105.277133',
    timeZoneId: 'America/Denver',
  },
  '79848': {
    zip: '79848',
    city: 'Sanderson',
    state: 'TX',
    latitude: '30.141379',
    longitude: '-102.389078',
    timeZoneId: 'America/Chicago',
  },
  '79849': {
    zip: '79849',
    city: 'San Elizario',
    state: 'TX',
    latitude: '31.584257',
    longitude: '-106.272101',
    timeZoneId: 'America/Denver',
  },
  '79851': {
    zip: '79851',
    city: 'Sierra Blanca',
    state: 'TX',
    latitude: '31.228663',
    longitude: '-105.18715',
    timeZoneId: 'America/Denver',
  },
  '79852': {
    zip: '79852',
    city: 'Terlingua',
    state: 'TX',
    latitude: '29.330664',
    longitude: '-103.591563',
    timeZoneId: 'America/Chicago',
  },
  '79853': {
    zip: '79853',
    city: 'Tornillo',
    state: 'TX',
    latitude: '31.432754',
    longitude: '-106.07783',
    timeZoneId: 'America/Denver',
  },
  '79854': {
    zip: '79854',
    city: 'Valentine',
    state: 'TX',
    latitude: '30.729283',
    longitude: '-104.450344',
    timeZoneId: 'America/Chicago',
  },
  '79855': {
    zip: '79855',
    city: 'Van Horn',
    state: 'TX',
    latitude: '31.06',
    longitude: '-104.21',
    timeZoneId: 'America/Chicago',
  },
  '79901': {
    zip: '79901',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.760394',
    longitude: '-106.487292',
    timeZoneId: 'America/Denver',
  },
  '79902': {
    zip: '79902',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.789027',
    longitude: '-106.499183',
    timeZoneId: 'America/Denver',
  },
  '79903': {
    zip: '79903',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.78433',
    longitude: '-106.445689',
    timeZoneId: 'America/Denver',
  },
  '79904': {
    zip: '79904',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.857581',
    longitude: '-106.438197',
    timeZoneId: 'America/Denver',
  },
  '79905': {
    zip: '79905',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766785',
    longitude: '-106.428416',
    timeZoneId: 'America/Denver',
  },
  '79906': {
    zip: '79906',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.82304',
    longitude: '-106.4238',
    timeZoneId: 'America/Denver',
  },
  '79907': {
    zip: '79907',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.711168',
    longitude: '-106.331389',
    timeZoneId: 'America/Denver',
  },
  '79908': {
    zip: '79908',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.838148',
    longitude: '-106.375434',
    timeZoneId: 'America/Denver',
  },
  '79910': {
    zip: '79910',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766131',
    longitude: '-106.426133',
    timeZoneId: 'America/Denver',
  },
  '79911': {
    zip: '79911',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.837558',
    longitude: '-106.562277',
    timeZoneId: 'America/Denver',
  },
  '79912': {
    zip: '79912',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.842644',
    longitude: '-106.540333',
    timeZoneId: 'America/Denver',
  },
  '79913': {
    zip: '79913',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.839613',
    longitude: '-106.564487',
    timeZoneId: 'America/Denver',
  },
  '79914': {
    zip: '79914',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.902609',
    longitude: '-106.414916',
    timeZoneId: 'America/Denver',
  },
  '79915': {
    zip: '79915',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.751969',
    longitude: '-106.381303',
    timeZoneId: 'America/Denver',
  },
  '79916': {
    zip: '79916',
    city: 'Fort Bliss',
    state: 'TX',
    latitude: '31.944107',
    longitude: '-106.179974',
    timeZoneId: 'America/Denver',
  },
  '79917': {
    zip: '79917',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.706371',
    longitude: '-106.32502',
    timeZoneId: 'America/Denver',
  },
  '79918': {
    zip: '79918',
    city: 'Fort Bliss',
    state: 'TX',
    latitude: '31.847479',
    longitude: '-106.378199',
    timeZoneId: 'America/Denver',
  },
  '79920': {
    zip: '79920',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.823595',
    longitude: '-106.463282',
    timeZoneId: 'America/Denver',
  },
  '79922': {
    zip: '79922',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.822081',
    longitude: '-106.568367',
    timeZoneId: 'America/Denver',
  },
  '79923': {
    zip: '79923',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.780293',
    longitude: '-106.459121',
    timeZoneId: 'America/Denver',
  },
  '79924': {
    zip: '79924',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.899322',
    longitude: '-106.414327',
    timeZoneId: 'America/Denver',
  },
  '79925': {
    zip: '79925',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.77928',
    longitude: '-106.365646',
    timeZoneId: 'America/Denver',
  },
  '79926': {
    zip: '79926',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.723605',
    longitude: '-106.296182',
    timeZoneId: 'America/Denver',
  },
  '79927': {
    zip: '79927',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.65522',
    longitude: '-106.22826',
    timeZoneId: 'America/Denver',
  },
  '79928': {
    zip: '79928',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.757176',
    longitude: '-106.486005',
    timeZoneId: 'America/Denver',
  },
  '79929': {
    zip: '79929',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.691648',
    longitude: '-106.159445',
    timeZoneId: 'America/Denver',
  },
  '79930': {
    zip: '79930',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.806169',
    longitude: '-106.468363',
    timeZoneId: 'America/Denver',
  },
  '79931': {
    zip: '79931',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.809604',
    longitude: '-106.443567',
    timeZoneId: 'America/Denver',
  },
  '79932': {
    zip: '79932',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.865419',
    longitude: '-106.595931',
    timeZoneId: 'America/Denver',
  },
  '79934': {
    zip: '79934',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.939849',
    longitude: '-106.402095',
    timeZoneId: 'America/Denver',
  },
  '79935': {
    zip: '79935',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.774787',
    longitude: '-106.332818',
    timeZoneId: 'America/Denver',
  },
  '79936': {
    zip: '79936',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.786132',
    longitude: '-106.260892',
    timeZoneId: 'America/Denver',
  },
  '79937': {
    zip: '79937',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.78577',
    longitude: '-106.336864',
    timeZoneId: 'America/Denver',
  },
  '79938': {
    zip: '79938',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.822773',
    longitude: '-106.187046',
    timeZoneId: 'America/Denver',
  },
  '79940': {
    zip: '79940',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.771329',
    longitude: '-106.426299',
    timeZoneId: 'America/Denver',
  },
  '79941': {
    zip: '79941',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.758186',
    longitude: '-106.483108',
    timeZoneId: 'America/Denver',
  },
  '79942': {
    zip: '79942',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.762715',
    longitude: '-106.478179',
    timeZoneId: 'America/Denver',
  },
  '79943': {
    zip: '79943',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.761236',
    longitude: '-106.481343',
    timeZoneId: 'America/Denver',
  },
  '79944': {
    zip: '79944',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.763223',
    longitude: '-106.480051',
    timeZoneId: 'America/Denver',
  },
  '79945': {
    zip: '79945',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.761453',
    longitude: '-106.479316',
    timeZoneId: 'America/Denver',
  },
  '79946': {
    zip: '79946',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.762602',
    longitude: '-106.47865',
    timeZoneId: 'America/Denver',
  },
  '79947': {
    zip: '79947',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.761334',
    longitude: '-106.478777',
    timeZoneId: 'America/Denver',
  },
  '79948': {
    zip: '79948',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.761851',
    longitude: '-106.479879',
    timeZoneId: 'America/Denver',
  },
  '79949': {
    zip: '79949',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.761865',
    longitude: '-106.48173',
    timeZoneId: 'America/Denver',
  },
  '79950': {
    zip: '79950',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.762458',
    longitude: '-106.480372',
    timeZoneId: 'America/Denver',
  },
  '79951': {
    zip: '79951',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.758593',
    longitude: '-106.483795',
    timeZoneId: 'America/Denver',
  },
  '79952': {
    zip: '79952',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76192',
    longitude: '-106.480169',
    timeZoneId: 'America/Denver',
  },
  '79953': {
    zip: '79953',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.761675',
    longitude: '-106.478965',
    timeZoneId: 'America/Denver',
  },
  '79954': {
    zip: '79954',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.75796',
    longitude: '-106.488675',
    timeZoneId: 'America/Denver',
  },
  '79955': {
    zip: '79955',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.763644',
    longitude: '-106.480213',
    timeZoneId: 'America/Denver',
  },
  '79958': {
    zip: '79958',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.755406',
    longitude: '-106.489477',
    timeZoneId: 'America/Denver',
  },
  '79960': {
    zip: '79960',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.758857',
    longitude: '-106.487767',
    timeZoneId: 'America/Denver',
  },
  '79961': {
    zip: '79961',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.774025',
    longitude: '-106.375278',
    timeZoneId: 'America/Denver',
  },
  '79968': {
    zip: '79968',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.773335',
    longitude: '-106.504435',
    timeZoneId: 'America/Denver',
  },
  '79976': {
    zip: '79976',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.817144',
    longitude: '-106.442286',
    timeZoneId: 'America/Denver',
  },
  '79978': {
    zip: '79978',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76077',
    longitude: '-106.488302',
    timeZoneId: 'America/Denver',
  },
  '79980': {
    zip: '79980',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.746134',
    longitude: '-106.375219',
    timeZoneId: 'America/Denver',
  },
  '79995': {
    zip: '79995',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768254',
    longitude: '-106.423095',
    timeZoneId: 'America/Denver',
  },
  '79996': {
    zip: '79996',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.850216',
    longitude: '-106.438303',
    timeZoneId: 'America/Denver',
  },
  '79997': {
    zip: '79997',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766474',
    longitude: '-106.42575',
    timeZoneId: 'America/Denver',
  },
  '79998': {
    zip: '79998',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76983',
    longitude: '-106.428395',
    timeZoneId: 'America/Denver',
  },
  '79999': {
    zip: '79999',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768099',
    longitude: '-106.428742',
    timeZoneId: 'America/Denver',
  },
  '80001': {
    zip: '80001',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.801977',
    longitude: '-105.087152',
    timeZoneId: 'America/Denver',
  },
  '80002': {
    zip: '80002',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.794577',
    longitude: '-105.095896',
    timeZoneId: 'America/Denver',
  },
  '80003': {
    zip: '80003',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.824737',
    longitude: '-105.061827',
    timeZoneId: 'America/Denver',
  },
  '80004': {
    zip: '80004',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.816646',
    longitude: '-105.127381',
    timeZoneId: 'America/Denver',
  },
  '80005': {
    zip: '80005',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.841453',
    longitude: '-105.117853',
    timeZoneId: 'America/Denver',
  },
  '80006': {
    zip: '80006',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.844402',
    longitude: '-105.07851',
    timeZoneId: 'America/Denver',
  },
  '80007': {
    zip: '80007',
    city: 'Arvada',
    state: 'CO',
    latitude: '39.858393',
    longitude: '-105.196556',
    timeZoneId: 'America/Denver',
  },
  '80010': {
    zip: '80010',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.735849',
    longitude: '-104.865186',
    timeZoneId: 'America/Denver',
  },
  '80011': {
    zip: '80011',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.74165',
    longitude: '-104.791421',
    timeZoneId: 'America/Denver',
  },
  '80012': {
    zip: '80012',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.694487',
    longitude: '-104.832515',
    timeZoneId: 'America/Denver',
  },
  '80013': {
    zip: '80013',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.660246',
    longitude: '-104.779822',
    timeZoneId: 'America/Denver',
  },
  '80014': {
    zip: '80014',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.65988',
    longitude: '-104.83698',
    timeZoneId: 'America/Denver',
  },
  '80015': {
    zip: '80015',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.624696',
    longitude: '-104.7888',
    timeZoneId: 'America/Denver',
  },
  '80016': {
    zip: '80016',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.584457',
    longitude: '-104.758155',
    timeZoneId: 'America/Denver',
  },
  '80017': {
    zip: '80017',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.692691',
    longitude: '-104.785113',
    timeZoneId: 'America/Denver',
  },
  '80018': {
    zip: '80018',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.713568',
    longitude: '-104.709729',
    timeZoneId: 'America/Denver',
  },
  '80019': {
    zip: '80019',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.771487',
    longitude: '-104.679038',
    timeZoneId: 'America/Denver',
  },
  '80020': {
    zip: '80020',
    city: 'Broomfield',
    state: 'CO',
    latitude: '39.93468',
    longitude: '-105.07662',
    timeZoneId: 'America/Denver',
  },
  '80021': {
    zip: '80021',
    city: 'Broomfield',
    state: 'CO',
    latitude: '39.883964',
    longitude: '-105.106613',
    timeZoneId: 'America/Denver',
  },
  '80022': {
    zip: '80022',
    city: 'Commerce City',
    state: 'CO',
    latitude: '39.831897',
    longitude: '-104.894182',
    timeZoneId: 'America/Denver',
  },
  '80023': {
    zip: '80023',
    city: 'Broomfield',
    state: 'CO',
    latitude: '39.953501',
    longitude: '-105.036717',
    timeZoneId: 'America/Denver',
  },
  '80024': {
    zip: '80024',
    city: 'Dupont',
    state: 'CO',
    latitude: '39.846218',
    longitude: '-104.919442',
    timeZoneId: 'America/Denver',
  },
  '80025': {
    zip: '80025',
    city: 'Eldorado Springs',
    state: 'CO',
    latitude: '39.935729',
    longitude: '-105.280038',
    timeZoneId: 'America/Denver',
  },
  '80026': {
    zip: '80026',
    city: 'Lafayette',
    state: 'CO',
    latitude: '40.000519',
    longitude: '-105.098843',
    timeZoneId: 'America/Denver',
  },
  '80027': {
    zip: '80027',
    city: 'Louisville',
    state: 'CO',
    latitude: '39.96642',
    longitude: '-105.1542',
    timeZoneId: 'America/Denver',
  },
  '80030': {
    zip: '80030',
    city: 'Westminster',
    state: 'CO',
    latitude: '39.828893',
    longitude: '-105.036679',
    timeZoneId: 'America/Denver',
  },
  '80031': {
    zip: '80031',
    city: 'Westminster',
    state: 'CO',
    latitude: '39.861937',
    longitude: '-105.041266',
    timeZoneId: 'America/Denver',
  },
  '80033': {
    zip: '80033',
    city: 'Wheat Ridge',
    state: 'CO',
    latitude: '39.772041',
    longitude: '-105.101411',
    timeZoneId: 'America/Denver',
  },
  '80034': {
    zip: '80034',
    city: 'Wheat Ridge',
    state: 'CO',
    latitude: '39.777375',
    longitude: '-105.081686',
    timeZoneId: 'America/Denver',
  },
  '80035': {
    zip: '80035',
    city: 'Westminster',
    state: 'CO',
    latitude: '39.862046',
    longitude: '-105.039789',
    timeZoneId: 'America/Denver',
  },
  '80036': {
    zip: '80036',
    city: 'Westminster',
    state: 'CO',
    latitude: '39.859851',
    longitude: '-105.037313',
    timeZoneId: 'America/Denver',
  },
  '80037': {
    zip: '80037',
    city: 'Commerce City',
    state: 'CO',
    latitude: '39.819298',
    longitude: '-104.916784',
    timeZoneId: 'America/Denver',
  },
  '80038': {
    zip: '80038',
    city: 'Broomfield',
    state: 'CO',
    latitude: '39.919548',
    longitude: '-105.085569',
    timeZoneId: 'America/Denver',
  },
  '80040': {
    zip: '80040',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.723696',
    longitude: '-104.767316',
    timeZoneId: 'America/Denver',
  },
  '80041': {
    zip: '80041',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.700882',
    longitude: '-104.836569',
    timeZoneId: 'America/Denver',
  },
  '80042': {
    zip: '80042',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.739084',
    longitude: '-104.809365',
    timeZoneId: 'America/Denver',
  },
  '80044': {
    zip: '80044',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.749887',
    longitude: '-104.827068',
    timeZoneId: 'America/Denver',
  },
  '80045': {
    zip: '80045',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.748262',
    longitude: '-104.839178',
    timeZoneId: 'America/Denver',
  },
  '80046': {
    zip: '80046',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.628034',
    longitude: '-104.798698',
    timeZoneId: 'America/Denver',
  },
  '80047': {
    zip: '80047',
    city: 'Aurora',
    state: 'CO',
    latitude: '39.69891',
    longitude: '-104.765103',
    timeZoneId: 'America/Denver',
  },
  '80101': {
    zip: '80101',
    city: 'Agate',
    state: 'CO',
    latitude: '39.406711',
    longitude: '-104.020756',
    timeZoneId: 'America/Denver',
  },
  '80102': {
    zip: '80102',
    city: 'Bennett',
    state: 'CO',
    latitude: '39.751155',
    longitude: '-104.448047',
    timeZoneId: 'America/Denver',
  },
  '80103': {
    zip: '80103',
    city: 'Byers',
    state: 'CO',
    latitude: '39.663695',
    longitude: '-104.136365',
    timeZoneId: 'America/Denver',
  },
  '80104': {
    zip: '80104',
    city: 'Castle Rock',
    state: 'CO',
    latitude: '39.390403',
    longitude: '-104.85871',
    timeZoneId: 'America/Denver',
  },
  '80105': {
    zip: '80105',
    city: 'Deer Trail',
    state: 'CO',
    latitude: '39.6136',
    longitude: '-104.044202',
    timeZoneId: 'America/Denver',
  },
  '80106': {
    zip: '80106',
    city: 'Elbert',
    state: 'CO',
    latitude: '39.056631',
    longitude: '-104.593326',
    timeZoneId: 'America/Denver',
  },
  '80107': {
    zip: '80107',
    city: 'Elizabeth',
    state: 'CO',
    latitude: '39.384034',
    longitude: '-104.593094',
    timeZoneId: 'America/Denver',
  },
  '80108': {
    zip: '80108',
    city: 'Castle Rock',
    state: 'CO',
    latitude: '39.391907',
    longitude: '-104.859358',
    timeZoneId: 'America/Denver',
  },
  '80109': {
    zip: '80109',
    city: 'Castle Rock',
    state: 'CO',
    latitude: '39.388964',
    longitude: '-104.860057',
    timeZoneId: 'America/Denver',
  },
  '80110': {
    zip: '80110',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.64896',
    longitude: '-104.98086',
    timeZoneId: 'America/Denver',
  },
  '80111': {
    zip: '80111',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.612706',
    longitude: '-104.883398',
    timeZoneId: 'America/Denver',
  },
  '80112': {
    zip: '80112',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.575499',
    longitude: '-104.861971',
    timeZoneId: 'America/Denver',
  },
  '80113': {
    zip: '80113',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.643479',
    longitude: '-104.95604',
    timeZoneId: 'America/Denver',
  },
  '80116': {
    zip: '80116',
    city: 'Franktown',
    state: 'CO',
    latitude: '39.353282',
    longitude: '-104.711853',
    timeZoneId: 'America/Denver',
  },
  '80117': {
    zip: '80117',
    city: 'Kiowa',
    state: 'CO',
    latitude: '39.328704',
    longitude: '-104.45147',
    timeZoneId: 'America/Denver',
  },
  '80118': {
    zip: '80118',
    city: 'Larkspur',
    state: 'CO',
    latitude: '39.206007',
    longitude: '-104.888401',
    timeZoneId: 'America/Denver',
  },
  '80120': {
    zip: '80120',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.600857',
    longitude: '-105.00645',
    timeZoneId: 'America/Denver',
  },
  '80121': {
    zip: '80121',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.612173',
    longitude: '-104.954964',
    timeZoneId: 'America/Denver',
  },
  '80122': {
    zip: '80122',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.58044',
    longitude: '-104.955859',
    timeZoneId: 'America/Denver',
  },
  '80123': {
    zip: '80123',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.595296',
    longitude: '-105.08786',
    timeZoneId: 'America/Denver',
  },
  '80124': {
    zip: '80124',
    city: 'Lone Tree',
    state: 'CO',
    latitude: '39.546295',
    longitude: '-104.896772',
    timeZoneId: 'America/Denver',
  },
  '80125': {
    zip: '80125',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.482672',
    longitude: '-105.055501',
    timeZoneId: 'America/Denver',
  },
  '80126': {
    zip: '80126',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.548022',
    longitude: '-104.963984',
    timeZoneId: 'America/Denver',
  },
  '80127': {
    zip: '80127',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.593034',
    longitude: '-105.138642',
    timeZoneId: 'America/Denver',
  },
  '80128': {
    zip: '80128',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.599307',
    longitude: '-105.128459',
    timeZoneId: 'America/Denver',
  },
  '80129': {
    zip: '80129',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.543276',
    longitude: '-105.007567',
    timeZoneId: 'America/Denver',
  },
  '80130': {
    zip: '80130',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.544318',
    longitude: '-104.920225',
    timeZoneId: 'America/Denver',
  },
  '80131': {
    zip: '80131',
    city: 'Louviers',
    state: 'CO',
    latitude: '39.475712',
    longitude: '-105.006529',
    timeZoneId: 'America/Denver',
  },
  '80132': {
    zip: '80132',
    city: 'Monument',
    state: 'CO',
    latitude: '39.100182',
    longitude: '-104.849748',
    timeZoneId: 'America/Denver',
  },
  '80133': {
    zip: '80133',
    city: 'Palmer Lake',
    state: 'CO',
    latitude: '39.11725',
    longitude: '-104.913143',
    timeZoneId: 'America/Denver',
  },
  '80134': {
    zip: '80134',
    city: 'Parker',
    state: 'CO',
    latitude: '39.471996',
    longitude: '-104.768182',
    timeZoneId: 'America/Denver',
  },
  '80135': {
    zip: '80135',
    city: 'Sedalia',
    state: 'CO',
    latitude: '39.257643',
    longitude: '-105.225649',
    timeZoneId: 'America/Denver',
  },
  '80136': {
    zip: '80136',
    city: 'Strasburg',
    state: 'CO',
    latitude: '39.751552',
    longitude: '-104.331296',
    timeZoneId: 'America/Denver',
  },
  '80137': {
    zip: '80137',
    city: 'Watkins',
    state: 'CO',
    latitude: '39.764027',
    longitude: '-104.653104',
    timeZoneId: 'America/Denver',
  },
  '80138': {
    zip: '80138',
    city: 'Parker',
    state: 'CO',
    latitude: '39.515707',
    longitude: '-104.662026',
    timeZoneId: 'America/Denver',
  },
  '80150': {
    zip: '80150',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.643214',
    longitude: '-104.985443',
    timeZoneId: 'America/Denver',
  },
  '80151': {
    zip: '80151',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.643942',
    longitude: '-104.984296',
    timeZoneId: 'America/Denver',
  },
  '80155': {
    zip: '80155',
    city: 'Englewood',
    state: 'CO',
    latitude: '39.591335',
    longitude: '-104.876947',
    timeZoneId: 'America/Denver',
  },
  '80160': {
    zip: '80160',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.612973',
    longitude: '-105.016519',
    timeZoneId: 'America/Denver',
  },
  '80161': {
    zip: '80161',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.596945',
    longitude: '-104.961633',
    timeZoneId: 'America/Denver',
  },
  '80162': {
    zip: '80162',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.599349',
    longitude: '-105.106619',
    timeZoneId: 'America/Denver',
  },
  '80163': {
    zip: '80163',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.545881',
    longitude: '-104.974412',
    timeZoneId: 'America/Denver',
  },
  '80165': {
    zip: '80165',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.595884',
    longitude: '-105.008265',
    timeZoneId: 'America/Denver',
  },
  '80166': {
    zip: '80166',
    city: 'Littleton',
    state: 'CO',
    latitude: '39.594031',
    longitude: '-105.009802',
    timeZoneId: 'America/Denver',
  },
  '80201': {
    zip: '80201',
    city: 'Denver',
    state: 'CO',
    latitude: '39.695496',
    longitude: '-105.084115',
    timeZoneId: 'America/Denver',
  },
  '80202': {
    zip: '80202',
    city: 'Denver',
    state: 'CO',
    latitude: '39.749412',
    longitude: '-104.993661',
    timeZoneId: 'America/Denver',
  },
  '80203': {
    zip: '80203',
    city: 'Denver',
    state: 'CO',
    latitude: '39.731434',
    longitude: '-104.981595',
    timeZoneId: 'America/Denver',
  },
  '80204': {
    zip: '80204',
    city: 'Denver',
    state: 'CO',
    latitude: '39.736603',
    longitude: '-105.02123',
    timeZoneId: 'America/Denver',
  },
  '80205': {
    zip: '80205',
    city: 'Denver',
    state: 'CO',
    latitude: '39.762697',
    longitude: '-104.973357',
    timeZoneId: 'America/Denver',
  },
  '80206': {
    zip: '80206',
    city: 'Denver',
    state: 'CO',
    latitude: '39.7285',
    longitude: '-104.954037',
    timeZoneId: 'America/Denver',
  },
  '80207': {
    zip: '80207',
    city: 'Denver',
    state: 'CO',
    latitude: '39.757333',
    longitude: '-104.912703',
    timeZoneId: 'America/Denver',
  },
  '80208': {
    zip: '80208',
    city: 'Denver',
    state: 'CO',
    latitude: '39.680475',
    longitude: '-104.962996',
    timeZoneId: 'America/Denver',
  },
  '80209': {
    zip: '80209',
    city: 'Denver',
    state: 'CO',
    latitude: '39.704419',
    longitude: '-104.959341',
    timeZoneId: 'America/Denver',
  },
  '80210': {
    zip: '80210',
    city: 'Denver',
    state: 'CO',
    latitude: '39.675422',
    longitude: '-104.96163',
    timeZoneId: 'America/Denver',
  },
  '80211': {
    zip: '80211',
    city: 'Denver',
    state: 'CO',
    latitude: '39.767956',
    longitude: '-105.014457',
    timeZoneId: 'America/Denver',
  },
  '80212': {
    zip: '80212',
    city: 'Denver',
    state: 'CO',
    latitude: '39.772488',
    longitude: '-105.05111',
    timeZoneId: 'America/Denver',
  },
  '80214': {
    zip: '80214',
    city: 'Denver',
    state: 'CO',
    latitude: '39.745789',
    longitude: '-105.059616',
    timeZoneId: 'America/Denver',
  },
  '80215': {
    zip: '80215',
    city: 'Denver',
    state: 'CO',
    latitude: '39.745057',
    longitude: '-105.104454',
    timeZoneId: 'America/Denver',
  },
  '80216': {
    zip: '80216',
    city: 'Denver',
    state: 'CO',
    latitude: '39.784552',
    longitude: '-104.964547',
    timeZoneId: 'America/Denver',
  },
  '80217': {
    zip: '80217',
    city: 'Denver',
    state: 'CO',
    latitude: '39.751137',
    longitude: '-104.99699',
    timeZoneId: 'America/Denver',
  },
  '80218': {
    zip: '80218',
    city: 'Denver',
    state: 'CO',
    latitude: '39.733269',
    longitude: '-104.96973',
    timeZoneId: 'America/Denver',
  },
  '80219': {
    zip: '80219',
    city: 'Denver',
    state: 'CO',
    latitude: '39.698319',
    longitude: '-105.032695',
    timeZoneId: 'America/Denver',
  },
  '80220': {
    zip: '80220',
    city: 'Denver',
    state: 'CO',
    latitude: '39.735435',
    longitude: '-104.917507',
    timeZoneId: 'America/Denver',
  },
  '80221': {
    zip: '80221',
    city: 'Denver',
    state: 'CO',
    latitude: '39.83442',
    longitude: '-105.00954',
    timeZoneId: 'America/Denver',
  },
  '80222': {
    zip: '80222',
    city: 'Denver',
    state: 'CO',
    latitude: '39.680076',
    longitude: '-104.923754',
    timeZoneId: 'America/Denver',
  },
  '80223': {
    zip: '80223',
    city: 'Denver',
    state: 'CO',
    latitude: '39.698343',
    longitude: '-105.002952',
    timeZoneId: 'America/Denver',
  },
  '80224': {
    zip: '80224',
    city: 'Denver',
    state: 'CO',
    latitude: '39.6877',
    longitude: '-104.909814',
    timeZoneId: 'America/Denver',
  },
  '80225': {
    zip: '80225',
    city: 'Denver',
    state: 'CO',
    latitude: '39.724284',
    longitude: '-105.110169',
    timeZoneId: 'America/Denver',
  },
  '80226': {
    zip: '80226',
    city: 'Denver',
    state: 'CO',
    latitude: '39.713584',
    longitude: '-105.065468',
    timeZoneId: 'America/Denver',
  },
  '80227': {
    zip: '80227',
    city: 'Denver',
    state: 'CO',
    latitude: '39.66654',
    longitude: '-105.09618',
    timeZoneId: 'America/Denver',
  },
  '80228': {
    zip: '80228',
    city: 'Denver',
    state: 'CO',
    latitude: '39.69798',
    longitude: '-105.15588',
    timeZoneId: 'America/Denver',
  },
  '80229': {
    zip: '80229',
    city: 'Denver',
    state: 'CO',
    latitude: '39.8502',
    longitude: '-104.96988',
    timeZoneId: 'America/Denver',
  },
  '80230': {
    zip: '80230',
    city: 'Denver',
    state: 'CO',
    latitude: '39.730904',
    longitude: '-104.903794',
    timeZoneId: 'America/Denver',
  },
  '80231': {
    zip: '80231',
    city: 'Denver',
    state: 'CO',
    latitude: '39.675732',
    longitude: '-104.886871',
    timeZoneId: 'America/Denver',
  },
  '80232': {
    zip: '80232',
    city: 'Denver',
    state: 'CO',
    latitude: '39.690079',
    longitude: '-105.085043',
    timeZoneId: 'America/Denver',
  },
  '80233': {
    zip: '80233',
    city: 'Denver',
    state: 'CO',
    latitude: '39.903291',
    longitude: '-104.948178',
    timeZoneId: 'America/Denver',
  },
  '80234': {
    zip: '80234',
    city: 'Denver',
    state: 'CO',
    latitude: '39.903133',
    longitude: '-105.004743',
    timeZoneId: 'America/Denver',
  },
  '80235': {
    zip: '80235',
    city: 'Denver',
    state: 'CO',
    latitude: '39.645945',
    longitude: '-105.081888',
    timeZoneId: 'America/Denver',
  },
  '80236': {
    zip: '80236',
    city: 'Denver',
    state: 'CO',
    latitude: '39.655391',
    longitude: '-105.022433',
    timeZoneId: 'America/Denver',
  },
  '80237': {
    zip: '80237',
    city: 'Denver',
    state: 'CO',
    latitude: '39.644373',
    longitude: '-104.899832',
    timeZoneId: 'America/Denver',
  },
  '80238': {
    zip: '80238',
    city: 'Denver',
    state: 'CO',
    latitude: '39.736803',
    longitude: '-104.982989',
    timeZoneId: 'America/Denver',
  },
  '80239': {
    zip: '80239',
    city: 'Denver',
    state: 'CO',
    latitude: '39.785062',
    longitude: '-104.824573',
    timeZoneId: 'America/Denver',
  },
  '80241': {
    zip: '80241',
    city: 'Thornton',
    state: 'CO',
    latitude: '39.928789',
    longitude: '-104.945551',
    timeZoneId: 'America/Denver',
  },
  '80243': {
    zip: '80243',
    city: 'Denver',
    state: 'CO',
    latitude: '39.686348',
    longitude: '-104.961946',
    timeZoneId: 'America/Denver',
  },
  '80244': {
    zip: '80244',
    city: 'Denver',
    state: 'CO',
    latitude: '39.749741',
    longitude: '-104.991002',
    timeZoneId: 'America/Denver',
  },
  '80246': {
    zip: '80246',
    city: 'Denver',
    state: 'CO',
    latitude: '39.703',
    longitude: '-104.929994',
    timeZoneId: 'America/Denver',
  },
  '80247': {
    zip: '80247',
    city: 'Denver',
    state: 'CO',
    latitude: '39.675696',
    longitude: '-104.885137',
    timeZoneId: 'America/Denver',
  },
  '80248': {
    zip: '80248',
    city: 'Denver',
    state: 'CO',
    latitude: '39.748464',
    longitude: '-104.999727',
    timeZoneId: 'America/Denver',
  },
  '80249': {
    zip: '80249',
    city: 'Denver',
    state: 'CO',
    latitude: '39.776761',
    longitude: '-104.756428',
    timeZoneId: 'America/Denver',
  },
  '80250': {
    zip: '80250',
    city: 'Denver',
    state: 'CO',
    latitude: '39.679099',
    longitude: '-104.944689',
    timeZoneId: 'America/Denver',
  },
  '80251': {
    zip: '80251',
    city: 'Denver',
    state: 'CO',
    latitude: '39.66279',
    longitude: '-104.853146',
    timeZoneId: 'America/Denver',
  },
  '80256': {
    zip: '80256',
    city: 'Denver',
    state: 'CO',
    latitude: '39.750699',
    longitude: '-104.989735',
    timeZoneId: 'America/Denver',
  },
  '80257': {
    zip: '80257',
    city: 'Denver',
    state: 'CO',
    latitude: '39.751093',
    longitude: '-104.999498',
    timeZoneId: 'America/Denver',
  },
  '80259': {
    zip: '80259',
    city: 'Denver',
    state: 'CO',
    latitude: '39.740324',
    longitude: '-104.987582',
    timeZoneId: 'America/Denver',
  },
  '80260': {
    zip: '80260',
    city: 'Denver',
    state: 'CO',
    latitude: '39.866033',
    longitude: '-105.004313',
    timeZoneId: 'America/Denver',
  },
  '80261': {
    zip: '80261',
    city: 'Denver',
    state: 'CO',
    latitude: '39.691209',
    longitude: '-104.984621',
    timeZoneId: 'America/Denver',
  },
  '80262': {
    zip: '80262',
    city: 'Denver',
    state: 'CO',
    latitude: '39.731445',
    longitude: '-104.938641',
    timeZoneId: 'America/Denver',
  },
  '80263': {
    zip: '80263',
    city: 'Denver',
    state: 'CO',
    latitude: '39.832482',
    longitude: '-105.007481',
    timeZoneId: 'America/Denver',
  },
  '80264': {
    zip: '80264',
    city: 'Denver',
    state: 'CO',
    latitude: '39.741063',
    longitude: '-104.984981',
    timeZoneId: 'America/Denver',
  },
  '80265': {
    zip: '80265',
    city: 'Denver',
    state: 'CO',
    latitude: '39.75073',
    longitude: '-104.995441',
    timeZoneId: 'America/Denver',
  },
  '80266': {
    zip: '80266',
    city: 'Denver',
    state: 'CO',
    latitude: '39.750435',
    longitude: '-104.999154',
    timeZoneId: 'America/Denver',
  },
  '80271': {
    zip: '80271',
    city: 'Denver',
    state: 'CO',
    latitude: '39.744563',
    longitude: '-104.990066',
    timeZoneId: 'America/Denver',
  },
  '80273': {
    zip: '80273',
    city: 'Denver',
    state: 'CO',
    latitude: '39.727577',
    longitude: '-104.988288',
    timeZoneId: 'America/Denver',
  },
  '80274': {
    zip: '80274',
    city: 'Denver',
    state: 'CO',
    latitude: '39.742322',
    longitude: '-104.989512',
    timeZoneId: 'America/Denver',
  },
  '80281': {
    zip: '80281',
    city: 'Denver',
    state: 'CO',
    latitude: '39.74422',
    longitude: '-104.98891',
    timeZoneId: 'America/Denver',
  },
  '80290': {
    zip: '80290',
    city: 'Denver',
    state: 'CO',
    latitude: '39.75264',
    longitude: '-104.991856',
    timeZoneId: 'America/Denver',
  },
  '80291': {
    zip: '80291',
    city: 'Denver',
    state: 'CO',
    latitude: '39.743207',
    longitude: '-104.985884',
    timeZoneId: 'America/Denver',
  },
  '80293': {
    zip: '80293',
    city: 'Denver',
    state: 'CO',
    latitude: '39.745651',
    longitude: '-104.990424',
    timeZoneId: 'America/Denver',
  },
  '80294': {
    zip: '80294',
    city: 'Denver',
    state: 'CO',
    latitude: '39.753064',
    longitude: '-104.990775',
    timeZoneId: 'America/Denver',
  },
  '80299': {
    zip: '80299',
    city: 'Denver',
    state: 'CO',
    latitude: '39.74687',
    longitude: '-104.99312',
    timeZoneId: 'America/Denver',
  },
  '80301': {
    zip: '80301',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.045435',
    longitude: '-105.213243',
    timeZoneId: 'America/Denver',
  },
  '80302': {
    zip: '80302',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.035467',
    longitude: '-105.306748',
    timeZoneId: 'America/Denver',
  },
  '80303': {
    zip: '80303',
    city: 'Boulder',
    state: 'CO',
    latitude: '39.991717',
    longitude: '-105.2274',
    timeZoneId: 'America/Denver',
  },
  '80304': {
    zip: '80304',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.038088',
    longitude: '-105.282837',
    timeZoneId: 'America/Denver',
  },
  '80305': {
    zip: '80305',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.015905',
    longitude: '-105.270922',
    timeZoneId: 'America/Denver',
  },
  '80306': {
    zip: '80306',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.040316',
    longitude: '-105.308452',
    timeZoneId: 'America/Denver',
  },
  '80307': {
    zip: '80307',
    city: 'Boulder',
    state: 'CO',
    latitude: '39.985473',
    longitude: '-105.235857',
    timeZoneId: 'America/Denver',
  },
  '80308': {
    zip: '80308',
    city: 'Boulder',
    state: 'CO',
    latitude: '39.959537',
    longitude: '-105.509752',
    timeZoneId: 'America/Denver',
  },
  '80309': {
    zip: '80309',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.009437',
    longitude: '-105.269917',
    timeZoneId: 'America/Denver',
  },
  '80310': {
    zip: '80310',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.009234',
    longitude: '-105.269921',
    timeZoneId: 'America/Denver',
  },
  '80314': {
    zip: '80314',
    city: 'Boulder',
    state: 'CO',
    latitude: '40.087784',
    longitude: '-105.199407',
    timeZoneId: 'America/Denver',
  },
  '80401': {
    zip: '80401',
    city: 'Golden',
    state: 'CO',
    latitude: '39.712606',
    longitude: '-105.229868',
    timeZoneId: 'America/Denver',
  },
  '80402': {
    zip: '80402',
    city: 'Golden',
    state: 'CO',
    latitude: '39.756447',
    longitude: '-105.216934',
    timeZoneId: 'America/Denver',
  },
  '80403': {
    zip: '80403',
    city: 'Golden',
    state: 'CO',
    latitude: '39.818028',
    longitude: '-105.25137',
    timeZoneId: 'America/Denver',
  },
  '80419': {
    zip: '80419',
    city: 'Golden',
    state: 'CO',
    latitude: '39.742698',
    longitude: '-105.216726',
    timeZoneId: 'America/Denver',
  },
  '80420': {
    zip: '80420',
    city: 'Alma',
    state: 'CO',
    latitude: '39.296764',
    longitude: '-106.066278',
    timeZoneId: 'America/Denver',
  },
  '80421': {
    zip: '80421',
    city: 'Bailey',
    state: 'CO',
    latitude: '39.458026',
    longitude: '-105.484852',
    timeZoneId: 'America/Denver',
  },
  '80422': {
    zip: '80422',
    city: 'Black Hawk',
    state: 'CO',
    latitude: '39.821401',
    longitude: '-105.500162',
    timeZoneId: 'America/Denver',
  },
  '80423': {
    zip: '80423',
    city: 'Bond',
    state: 'CO',
    latitude: '39.906108',
    longitude: '-106.632347',
    timeZoneId: 'America/Denver',
  },
  '80424': {
    zip: '80424',
    city: 'Breckenridge',
    state: 'CO',
    latitude: '39.472151',
    longitude: '-106.040778',
    timeZoneId: 'America/Denver',
  },
  '80425': {
    zip: '80425',
    city: 'Buffalo Creek',
    state: 'CO',
    latitude: '39.36729',
    longitude: '-105.268466',
    timeZoneId: 'America/Denver',
  },
  '80426': {
    zip: '80426',
    city: 'Burns',
    state: 'CO',
    latitude: '39.88275',
    longitude: '-106.965193',
    timeZoneId: 'America/Denver',
  },
  '80427': {
    zip: '80427',
    city: 'Central City',
    state: 'CO',
    latitude: '39.797305',
    longitude: '-105.514119',
    timeZoneId: 'America/Denver',
  },
  '80428': {
    zip: '80428',
    city: 'Clark',
    state: 'CO',
    latitude: '40.738657',
    longitude: '-106.91443',
    timeZoneId: 'America/Denver',
  },
  '80429': {
    zip: '80429',
    city: 'Climax',
    state: 'CO',
    latitude: '39.32658',
    longitude: '-106.23408',
    timeZoneId: 'America/Denver',
  },
  '80430': {
    zip: '80430',
    city: 'Coalmont',
    state: 'CO',
    latitude: '40.5625',
    longitude: '-106.443889',
    timeZoneId: 'America/Denver',
  },
  '80432': {
    zip: '80432',
    city: 'Como',
    state: 'CO',
    latitude: '39.218495',
    longitude: '-105.745536',
    timeZoneId: 'America/Denver',
  },
  '80433': {
    zip: '80433',
    city: 'Conifer',
    state: 'CO',
    latitude: '39.515866',
    longitude: '-105.311973',
    timeZoneId: 'America/Denver',
  },
  '80434': {
    zip: '80434',
    city: 'Cowdrey',
    state: 'CO',
    latitude: '40.858108',
    longitude: '-106.310219',
    timeZoneId: 'America/Denver',
  },
  '80435': {
    zip: '80435',
    city: 'Dillon',
    state: 'CO',
    latitude: '39.618708',
    longitude: '-106.041714',
    timeZoneId: 'America/Denver',
  },
  '80436': {
    zip: '80436',
    city: 'Dumont',
    state: 'CO',
    latitude: '39.790428',
    longitude: '-105.642576',
    timeZoneId: 'America/Denver',
  },
  '80437': {
    zip: '80437',
    city: 'Evergreen',
    state: 'CO',
    latitude: '39.635589',
    longitude: '-105.340787',
    timeZoneId: 'America/Denver',
  },
  '80438': {
    zip: '80438',
    city: 'Empire',
    state: 'CO',
    latitude: '39.766658',
    longitude: '-105.71116',
    timeZoneId: 'America/Denver',
  },
  '80439': {
    zip: '80439',
    city: 'Evergreen',
    state: 'CO',
    latitude: '39.608071',
    longitude: '-105.332386',
    timeZoneId: 'America/Denver',
  },
  '80440': {
    zip: '80440',
    city: 'Fairplay',
    state: 'CO',
    latitude: '39.207943',
    longitude: '-106.00152',
    timeZoneId: 'America/Denver',
  },
  '80442': {
    zip: '80442',
    city: 'Fraser',
    state: 'CO',
    latitude: '39.951119',
    longitude: '-105.814806',
    timeZoneId: 'America/Denver',
  },
  '80443': {
    zip: '80443',
    city: 'Frisco',
    state: 'CO',
    latitude: '39.57438',
    longitude: '-106.09692',
    timeZoneId: 'America/Denver',
  },
  '80444': {
    zip: '80444',
    city: 'Georgetown',
    state: 'CO',
    latitude: '39.708322',
    longitude: '-105.694429',
    timeZoneId: 'America/Denver',
  },
  '80446': {
    zip: '80446',
    city: 'Granby',
    state: 'CO',
    latitude: '40.0617',
    longitude: '-105.87894',
    timeZoneId: 'America/Denver',
  },
  '80447': {
    zip: '80447',
    city: 'Grand Lake',
    state: 'CO',
    latitude: '40.212899',
    longitude: '-105.855002',
    timeZoneId: 'America/Denver',
  },
  '80448': {
    zip: '80448',
    city: 'Grant',
    state: 'CO',
    latitude: '39.48432',
    longitude: '-105.74181',
    timeZoneId: 'America/Denver',
  },
  '80449': {
    zip: '80449',
    city: 'Hartsel',
    state: 'CO',
    latitude: '38.861422',
    longitude: '-105.804729',
    timeZoneId: 'America/Denver',
  },
  '80451': {
    zip: '80451',
    city: 'Hot Sulphur Springs',
    state: 'CO',
    latitude: '40.074422',
    longitude: '-106.104276',
    timeZoneId: 'America/Denver',
  },
  '80452': {
    zip: '80452',
    city: 'Idaho Springs',
    state: 'CO',
    latitude: '39.736355',
    longitude: '-105.522767',
    timeZoneId: 'America/Denver',
  },
  '80453': {
    zip: '80453',
    city: 'Idledale',
    state: 'CO',
    latitude: '39.666725',
    longitude: '-105.253606',
    timeZoneId: 'America/Denver',
  },
  '80454': {
    zip: '80454',
    city: 'Indian Hills',
    state: 'CO',
    latitude: '39.631893',
    longitude: '-105.251516',
    timeZoneId: 'America/Denver',
  },
  '80455': {
    zip: '80455',
    city: 'Jamestown',
    state: 'CO',
    latitude: '40.09798',
    longitude: '-105.404295',
    timeZoneId: 'America/Denver',
  },
  '80456': {
    zip: '80456',
    city: 'Jefferson',
    state: 'CO',
    latitude: '39.224722',
    longitude: '-106.001389',
    timeZoneId: 'America/Denver',
  },
  '80457': {
    zip: '80457',
    city: 'Kittredge',
    state: 'CO',
    latitude: '39.65471',
    longitude: '-105.295448',
    timeZoneId: 'America/Denver',
  },
  '80459': {
    zip: '80459',
    city: 'Kremmling',
    state: 'CO',
    latitude: '40.074207',
    longitude: '-106.407762',
    timeZoneId: 'America/Denver',
  },
  '80461': {
    zip: '80461',
    city: 'Leadville',
    state: 'CO',
    latitude: '39.249613',
    longitude: '-106.297523',
    timeZoneId: 'America/Denver',
  },
  '80463': {
    zip: '80463',
    city: 'Mc Coy',
    state: 'CO',
    latitude: '39.876018',
    longitude: '-106.758054',
    timeZoneId: 'America/Denver',
  },
  '80465': {
    zip: '80465',
    city: 'Morrison',
    state: 'CO',
    latitude: '39.612381',
    longitude: '-105.226694',
    timeZoneId: 'America/Denver',
  },
  '80466': {
    zip: '80466',
    city: 'Nederland',
    state: 'CO',
    latitude: '39.96326',
    longitude: '-105.492728',
    timeZoneId: 'America/Denver',
  },
  '80467': {
    zip: '80467',
    city: 'Oak Creek',
    state: 'CO',
    latitude: '40.263177',
    longitude: '-106.887857',
    timeZoneId: 'America/Denver',
  },
  '80468': {
    zip: '80468',
    city: 'Parshall',
    state: 'CO',
    latitude: '39.940874',
    longitude: '-106.154665',
    timeZoneId: 'America/Denver',
  },
  '80469': {
    zip: '80469',
    city: 'Phippsburg',
    state: 'CO',
    latitude: '40.217516',
    longitude: '-106.9621',
    timeZoneId: 'America/Denver',
  },
  '80470': {
    zip: '80470',
    city: 'Pine',
    state: 'CO',
    latitude: '39.462199',
    longitude: '-105.367398',
    timeZoneId: 'America/Denver',
  },
  '80471': {
    zip: '80471',
    city: 'Pinecliffe',
    state: 'CO',
    latitude: '39.940262',
    longitude: '-105.463365',
    timeZoneId: 'America/Denver',
  },
  '80473': {
    zip: '80473',
    city: 'Rand',
    state: 'CO',
    latitude: '40.464742',
    longitude: '-106.185505',
    timeZoneId: 'America/Denver',
  },
  '80474': {
    zip: '80474',
    city: 'Rollinsville',
    state: 'CO',
    latitude: '39.879622',
    longitude: '-105.485351',
    timeZoneId: 'America/Denver',
  },
  '80475': {
    zip: '80475',
    city: 'Shawnee',
    state: 'CO',
    latitude: '39.434466',
    longitude: '-105.587075',
    timeZoneId: 'America/Denver',
  },
  '80476': {
    zip: '80476',
    city: 'Silver Plume',
    state: 'CO',
    latitude: '39.694255',
    longitude: '-105.73504',
    timeZoneId: 'America/Denver',
  },
  '80477': {
    zip: '80477',
    city: 'Steamboat Springs',
    state: 'CO',
    latitude: '40.477411',
    longitude: '-106.84547',
    timeZoneId: 'America/Denver',
  },
  '80478': {
    zip: '80478',
    city: 'Tabernash',
    state: 'CO',
    latitude: '40.002845',
    longitude: '-105.883499',
    timeZoneId: 'America/Denver',
  },
  '80479': {
    zip: '80479',
    city: 'Toponas',
    state: 'CO',
    latitude: '40.050052',
    longitude: '-106.834689',
    timeZoneId: 'America/Denver',
  },
  '80480': {
    zip: '80480',
    city: 'Walden',
    state: 'CO',
    latitude: '40.647089',
    longitude: '-106.301099',
    timeZoneId: 'America/Denver',
  },
  '80481': {
    zip: '80481',
    city: 'Ward',
    state: 'CO',
    latitude: '40.095849',
    longitude: '-105.475897',
    timeZoneId: 'America/Denver',
  },
  '80482': {
    zip: '80482',
    city: 'Winter Park',
    state: 'CO',
    latitude: '39.938856',
    longitude: '-105.795816',
    timeZoneId: 'America/Denver',
  },
  '80483': {
    zip: '80483',
    city: 'Yampa',
    state: 'CO',
    latitude: '40.148581',
    longitude: '-106.924602',
    timeZoneId: 'America/Denver',
  },
  '80487': {
    zip: '80487',
    city: 'Steamboat Springs',
    state: 'CO',
    latitude: '40.488148',
    longitude: '-106.868862',
    timeZoneId: 'America/Denver',
  },
  '80488': {
    zip: '80488',
    city: 'Steamboat Springs',
    state: 'CO',
    latitude: '40.476275',
    longitude: '-106.850394',
    timeZoneId: 'America/Denver',
  },
  '80497': {
    zip: '80497',
    city: 'Silverthorne',
    state: 'CO',
    latitude: '39.640551',
    longitude: '-106.062121',
    timeZoneId: 'America/Denver',
  },
  '80498': {
    zip: '80498',
    city: 'Silverthorne',
    state: 'CO',
    latitude: '40.06',
    longitude: '-106.4',
    timeZoneId: 'America/Denver',
  },
  '80501': {
    zip: '80501',
    city: 'Longmont',
    state: 'CO',
    latitude: '40.166839',
    longitude: '-105.134916',
    timeZoneId: 'America/Denver',
  },
  '80502': {
    zip: '80502',
    city: 'Longmont',
    state: 'CO',
    latitude: '40.027278',
    longitude: '-105.29667',
    timeZoneId: 'America/Denver',
  },
  '80503': {
    zip: '80503',
    city: 'Longmont',
    state: 'CO',
    latitude: '40.153029',
    longitude: '-105.1713',
    timeZoneId: 'America/Denver',
  },
  '80504': {
    zip: '80504',
    city: 'Longmont',
    state: 'CO',
    latitude: '40.160065',
    longitude: '-104.966226',
    timeZoneId: 'America/Denver',
  },
  '80510': {
    zip: '80510',
    city: 'Allenspark',
    state: 'CO',
    latitude: '40.217787',
    longitude: '-105.524901',
    timeZoneId: 'America/Denver',
  },
  '80511': {
    zip: '80511',
    city: 'Estes Park',
    state: 'CO',
    latitude: '40.338655',
    longitude: '-105.563994',
    timeZoneId: 'America/Denver',
  },
  '80512': {
    zip: '80512',
    city: 'Bellvue',
    state: 'CO',
    latitude: '40.636798',
    longitude: '-105.327467',
    timeZoneId: 'America/Denver',
  },
  '80513': {
    zip: '80513',
    city: 'Berthoud',
    state: 'CO',
    latitude: '40.29499',
    longitude: '-105.123816',
    timeZoneId: 'America/Denver',
  },
  '80514': {
    zip: '80514',
    city: 'Dacono',
    state: 'CO',
    latitude: '40.084772',
    longitude: '-104.929982',
    timeZoneId: 'America/Denver',
  },
  '80515': {
    zip: '80515',
    city: 'Drake',
    state: 'CO',
    latitude: '40.441457',
    longitude: '-105.369149',
    timeZoneId: 'America/Denver',
  },
  '80516': {
    zip: '80516',
    city: 'Erie',
    state: 'CO',
    latitude: '40.055613',
    longitude: '-105.026348',
    timeZoneId: 'America/Denver',
  },
  '80517': {
    zip: '80517',
    city: 'Estes Park',
    state: 'CO',
    latitude: '40.365932',
    longitude: '-105.515995',
    timeZoneId: 'America/Denver',
  },
  '80520': {
    zip: '80520',
    city: 'Firestone',
    state: 'CO',
    latitude: '40.10983',
    longitude: '-104.93468',
    timeZoneId: 'America/Denver',
  },
  '80521': {
    zip: '80521',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.584979',
    longitude: '-105.114124',
    timeZoneId: 'America/Denver',
  },
  '80522': {
    zip: '80522',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.594542',
    longitude: '-105.127399',
    timeZoneId: 'America/Denver',
  },
  '80523': {
    zip: '80523',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.57224',
    longitude: '-105.086088',
    timeZoneId: 'America/Denver',
  },
  '80524': {
    zip: '80524',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.63551',
    longitude: '-105.049839',
    timeZoneId: 'America/Denver',
  },
  '80525': {
    zip: '80525',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.534158',
    longitude: '-105.043374',
    timeZoneId: 'America/Denver',
  },
  '80526': {
    zip: '80526',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.54558',
    longitude: '-105.112604',
    timeZoneId: 'America/Denver',
  },
  '80527': {
    zip: '80527',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.533235',
    longitude: '-105.071191',
    timeZoneId: 'America/Denver',
  },
  '80528': {
    zip: '80528',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.583017',
    longitude: '-105.08245',
    timeZoneId: 'America/Denver',
  },
  '80530': {
    zip: '80530',
    city: 'Frederick',
    state: 'CO',
    latitude: '40.101205',
    longitude: '-104.935223',
    timeZoneId: 'America/Denver',
  },
  '80532': {
    zip: '80532',
    city: 'Glen Haven',
    state: 'CO',
    latitude: '40.457347',
    longitude: '-105.443964',
    timeZoneId: 'America/Denver',
  },
  '80533': {
    zip: '80533',
    city: 'Hygiene',
    state: 'CO',
    latitude: '40.199537',
    longitude: '-105.188054',
    timeZoneId: 'America/Denver',
  },
  '80534': {
    zip: '80534',
    city: 'Johnstown',
    state: 'CO',
    latitude: '40.30575',
    longitude: '-105.07455',
    timeZoneId: 'America/Denver',
  },
  '80535': {
    zip: '80535',
    city: 'Laporte',
    state: 'CO',
    latitude: '40.658354',
    longitude: '-105.160262',
    timeZoneId: 'America/Denver',
  },
  '80536': {
    zip: '80536',
    city: 'Livermore',
    state: 'CO',
    latitude: '40.85886',
    longitude: '-105.37332',
    timeZoneId: 'America/Denver',
  },
  '80537': {
    zip: '80537',
    city: 'Loveland',
    state: 'CO',
    latitude: '40.388634',
    longitude: '-105.105597',
    timeZoneId: 'America/Denver',
  },
  '80538': {
    zip: '80538',
    city: 'Loveland',
    state: 'CO',
    latitude: '40.427281',
    longitude: '-105.093422',
    timeZoneId: 'America/Denver',
  },
  '80539': {
    zip: '80539',
    city: 'Loveland',
    state: 'CO',
    latitude: '40.396427',
    longitude: '-105.07438',
    timeZoneId: 'America/Denver',
  },
  '80540': {
    zip: '80540',
    city: 'Lyons',
    state: 'CO',
    latitude: '40.217295',
    longitude: '-105.30057',
    timeZoneId: 'America/Denver',
  },
  '80541': {
    zip: '80541',
    city: 'Masonville',
    state: 'CO',
    latitude: '40.55497',
    longitude: '-105.389763',
    timeZoneId: 'America/Denver',
  },
  '80542': {
    zip: '80542',
    city: 'Mead',
    state: 'CO',
    latitude: '40.239028',
    longitude: '-104.998324',
    timeZoneId: 'America/Denver',
  },
  '80543': {
    zip: '80543',
    city: 'Milliken',
    state: 'CO',
    latitude: '40.335711',
    longitude: '-104.855069',
    timeZoneId: 'America/Denver',
  },
  '80544': {
    zip: '80544',
    city: 'Niwot',
    state: 'CO',
    latitude: '40.102204',
    longitude: '-105.174894',
    timeZoneId: 'America/Denver',
  },
  '80545': {
    zip: '80545',
    city: 'Red Feather Lakes',
    state: 'CO',
    latitude: '40.804665',
    longitude: '-105.593375',
    timeZoneId: 'America/Denver',
  },
  '80546': {
    zip: '80546',
    city: 'Severance',
    state: 'CO',
    latitude: '40.525571',
    longitude: '-104.850084',
    timeZoneId: 'America/Denver',
  },
  '80547': {
    zip: '80547',
    city: 'Timnath',
    state: 'CO',
    latitude: '40.530372',
    longitude: '-104.98376',
    timeZoneId: 'America/Denver',
  },
  '80549': {
    zip: '80549',
    city: 'Wellington',
    state: 'CO',
    latitude: '40.746861',
    longitude: '-105.02639',
    timeZoneId: 'America/Denver',
  },
  '80550': {
    zip: '80550',
    city: 'Windsor',
    state: 'CO',
    latitude: '40.480502',
    longitude: '-104.904076',
    timeZoneId: 'America/Denver',
  },
  '80551': {
    zip: '80551',
    city: 'Windsor',
    state: 'CO',
    latitude: '40.4775',
    longitude: '-104.900833',
    timeZoneId: 'America/Denver',
  },
  '80553': {
    zip: '80553',
    city: 'Fort Collins',
    state: 'CO',
    latitude: '40.565319',
    longitude: '-105.045445',
    timeZoneId: 'America/Denver',
  },
  '80601': {
    zip: '80601',
    city: 'Brighton',
    state: 'CO',
    latitude: '39.964059',
    longitude: '-104.817375',
    timeZoneId: 'America/Denver',
  },
  '80602': {
    zip: '80602',
    city: 'Brighton',
    state: 'CO',
    latitude: '39.960305',
    longitude: '-104.910703',
    timeZoneId: 'America/Denver',
  },
  '80603': {
    zip: '80603',
    city: 'Brighton',
    state: 'CO',
    latitude: '39.99159',
    longitude: '-104.696847',
    timeZoneId: 'America/Denver',
  },
  '80610': {
    zip: '80610',
    city: 'Ault',
    state: 'CO',
    latitude: '40.619513',
    longitude: '-104.701943',
    timeZoneId: 'America/Denver',
  },
  '80611': {
    zip: '80611',
    city: 'Briggsdale',
    state: 'CO',
    latitude: '40.636008',
    longitude: '-104.34713',
    timeZoneId: 'America/Denver',
  },
  '80612': {
    zip: '80612',
    city: 'Carr',
    state: 'CO',
    latitude: '40.85029',
    longitude: '-104.86004',
    timeZoneId: 'America/Denver',
  },
  '80614': {
    zip: '80614',
    city: 'Eastlake',
    state: 'CO',
    latitude: '39.933629',
    longitude: '-104.958201',
    timeZoneId: 'America/Denver',
  },
  '80615': {
    zip: '80615',
    city: 'Eaton',
    state: 'CO',
    latitude: '40.524123',
    longitude: '-104.687892',
    timeZoneId: 'America/Denver',
  },
  '80620': {
    zip: '80620',
    city: 'Evans',
    state: 'CO',
    latitude: '40.379688',
    longitude: '-104.698376',
    timeZoneId: 'America/Denver',
  },
  '80621': {
    zip: '80621',
    city: 'Fort Lupton',
    state: 'CO',
    latitude: '40.099919',
    longitude: '-104.805331',
    timeZoneId: 'America/Denver',
  },
  '80622': {
    zip: '80622',
    city: 'Galeton',
    state: 'CO',
    latitude: '40.504673',
    longitude: '-104.623046',
    timeZoneId: 'America/Denver',
  },
  '80623': {
    zip: '80623',
    city: 'Gilcrest',
    state: 'CO',
    latitude: '40.284835',
    longitude: '-104.776641',
    timeZoneId: 'America/Denver',
  },
  '80624': {
    zip: '80624',
    city: 'Gill',
    state: 'CO',
    latitude: '40.475625',
    longitude: '-104.52559',
    timeZoneId: 'America/Denver',
  },
  '80631': {
    zip: '80631',
    city: 'Greeley',
    state: 'CO',
    latitude: '40.418082',
    longitude: '-104.708389',
    timeZoneId: 'America/Denver',
  },
  '80632': {
    zip: '80632',
    city: 'Greeley',
    state: 'CO',
    latitude: '40.422987',
    longitude: '-104.698006',
    timeZoneId: 'America/Denver',
  },
  '80633': {
    zip: '80633',
    city: 'Greeley',
    state: 'CO',
    latitude: '40.42383',
    longitude: '-104.69538',
    timeZoneId: 'America/Denver',
  },
  '80634': {
    zip: '80634',
    city: 'Greeley',
    state: 'CO',
    latitude: '40.408834',
    longitude: '-104.760402',
    timeZoneId: 'America/Denver',
  },
  '80638': {
    zip: '80638',
    city: 'Greeley',
    state: 'CO',
    latitude: '40.42176',
    longitude: '-104.744042',
    timeZoneId: 'America/Denver',
  },
  '80639': {
    zip: '80639',
    city: 'Greeley',
    state: 'CO',
    latitude: '40.401197',
    longitude: '-104.701365',
    timeZoneId: 'America/Denver',
  },
  '80640': {
    zip: '80640',
    city: 'Henderson',
    state: 'CO',
    latitude: '39.871048',
    longitude: '-104.883399',
    timeZoneId: 'America/Denver',
  },
  '80642': {
    zip: '80642',
    city: 'Hudson',
    state: 'CO',
    latitude: '40.077349',
    longitude: '-104.641907',
    timeZoneId: 'America/Denver',
  },
  '80643': {
    zip: '80643',
    city: 'Keenesburg',
    state: 'CO',
    latitude: '40.096167',
    longitude: '-104.505829',
    timeZoneId: 'America/Denver',
  },
  '80644': {
    zip: '80644',
    city: 'Kersey',
    state: 'CO',
    latitude: '40.341639',
    longitude: '-104.529139',
    timeZoneId: 'America/Denver',
  },
  '80645': {
    zip: '80645',
    city: 'La Salle',
    state: 'CO',
    latitude: '40.336011',
    longitude: '-104.686585',
    timeZoneId: 'America/Denver',
  },
  '80646': {
    zip: '80646',
    city: 'Lucerne',
    state: 'CO',
    latitude: '40.483405',
    longitude: '-104.699549',
    timeZoneId: 'America/Denver',
  },
  '80648': {
    zip: '80648',
    city: 'Nunn',
    state: 'CO',
    latitude: '40.753345',
    longitude: '-104.741291',
    timeZoneId: 'America/Denver',
  },
  '80649': {
    zip: '80649',
    city: 'Orchard',
    state: 'CO',
    latitude: '40.371009',
    longitude: '-104.099016',
    timeZoneId: 'America/Denver',
  },
  '80650': {
    zip: '80650',
    city: 'Pierce',
    state: 'CO',
    latitude: '40.640189',
    longitude: '-104.758624',
    timeZoneId: 'America/Denver',
  },
  '80651': {
    zip: '80651',
    city: 'Platteville',
    state: 'CO',
    latitude: '40.223774',
    longitude: '-104.822498',
    timeZoneId: 'America/Denver',
  },
  '80652': {
    zip: '80652',
    city: 'Roggen',
    state: 'CO',
    latitude: '40.115915',
    longitude: '-104.314832',
    timeZoneId: 'America/Denver',
  },
  '80653': {
    zip: '80653',
    city: 'Weldona',
    state: 'CO',
    latitude: '40.36017',
    longitude: '-104.01975',
    timeZoneId: 'America/Denver',
  },
  '80654': {
    zip: '80654',
    city: 'Wiggins',
    state: 'CO',
    latitude: '40.157963',
    longitude: '-104.074736',
    timeZoneId: 'America/Denver',
  },
  '80701': {
    zip: '80701',
    city: 'Fort Morgan',
    state: 'CO',
    latitude: '40.256639',
    longitude: '-103.806491',
    timeZoneId: 'America/Denver',
  },
  '80705': {
    zip: '80705',
    city: 'Log Lane Village',
    state: 'CO',
    latitude: '40.2609',
    longitude: '-103.81854',
    timeZoneId: 'America/Denver',
  },
  '80720': {
    zip: '80720',
    city: 'Akron',
    state: 'CO',
    latitude: '40.054042',
    longitude: '-103.205127',
    timeZoneId: 'America/Denver',
  },
  '80721': {
    zip: '80721',
    city: 'Amherst',
    state: 'CO',
    latitude: '40.680501',
    longitude: '-102.168012',
    timeZoneId: 'America/Denver',
  },
  '80722': {
    zip: '80722',
    city: 'Atwood',
    state: 'CO',
    latitude: '40.529862',
    longitude: '-103.266379',
    timeZoneId: 'America/Denver',
  },
  '80723': {
    zip: '80723',
    city: 'Brush',
    state: 'CO',
    latitude: '40.250157',
    longitude: '-103.619204',
    timeZoneId: 'America/Denver',
  },
  '80726': {
    zip: '80726',
    city: 'Crook',
    state: 'CO',
    latitude: '40.909481',
    longitude: '-102.812552',
    timeZoneId: 'America/Denver',
  },
  '80727': {
    zip: '80727',
    city: 'Eckley',
    state: 'CO',
    latitude: '40.026993',
    longitude: '-102.521039',
    timeZoneId: 'America/Denver',
  },
  '80728': {
    zip: '80728',
    city: 'Fleming',
    state: 'CO',
    latitude: '40.618591',
    longitude: '-102.853003',
    timeZoneId: 'America/Denver',
  },
  '80729': {
    zip: '80729',
    city: 'Grover',
    state: 'CO',
    latitude: '40.855794',
    longitude: '-104.11104',
    timeZoneId: 'America/Denver',
  },
  '80731': {
    zip: '80731',
    city: 'Haxtun',
    state: 'CO',
    latitude: '40.640627',
    longitude: '-102.627341',
    timeZoneId: 'America/Denver',
  },
  '80732': {
    zip: '80732',
    city: 'Hereford',
    state: 'CO',
    latitude: '40.974649',
    longitude: '-104.303001',
    timeZoneId: 'America/Denver',
  },
  '80733': {
    zip: '80733',
    city: 'Hillrose',
    state: 'CO',
    latitude: '40.333267',
    longitude: '-103.510516',
    timeZoneId: 'America/Denver',
  },
  '80734': {
    zip: '80734',
    city: 'Holyoke',
    state: 'CO',
    latitude: '40.583683',
    longitude: '-102.302044',
    timeZoneId: 'America/Denver',
  },
  '80735': {
    zip: '80735',
    city: 'Idalia',
    state: 'CO',
    latitude: '39.71976',
    longitude: '-102.25464',
    timeZoneId: 'America/Denver',
  },
  '80736': {
    zip: '80736',
    city: 'Iliff',
    state: 'CO',
    latitude: '40.808431',
    longitude: '-103.046988',
    timeZoneId: 'America/Denver',
  },
  '80737': {
    zip: '80737',
    city: 'Julesburg',
    state: 'CO',
    latitude: '40.88384',
    longitude: '-102.185425',
    timeZoneId: 'America/Denver',
  },
  '80740': {
    zip: '80740',
    city: 'Lindon',
    state: 'CO',
    latitude: '39.750888',
    longitude: '-103.385923',
    timeZoneId: 'America/Denver',
  },
  '80741': {
    zip: '80741',
    city: 'Merino',
    state: 'CO',
    latitude: '40.53192',
    longitude: '-103.41324',
    timeZoneId: 'America/Denver',
  },
  '80742': {
    zip: '80742',
    city: 'New Raymer',
    state: 'CO',
    latitude: '40.643775',
    longitude: '-103.873642',
    timeZoneId: 'America/Denver',
  },
  '80743': {
    zip: '80743',
    city: 'Otis',
    state: 'CO',
    latitude: '40.208232',
    longitude: '-102.961898',
    timeZoneId: 'America/Denver',
  },
  '80744': {
    zip: '80744',
    city: 'Ovid',
    state: 'CO',
    latitude: '40.934084',
    longitude: '-102.381984',
    timeZoneId: 'America/Denver',
  },
  '80745': {
    zip: '80745',
    city: 'Padroni',
    state: 'CO',
    latitude: '40.842172',
    longitude: '-103.291729',
    timeZoneId: 'America/Denver',
  },
  '80746': {
    zip: '80746',
    city: 'Paoli',
    state: 'CO',
    latitude: '40.60804',
    longitude: '-102.549851',
    timeZoneId: 'America/Denver',
  },
  '80747': {
    zip: '80747',
    city: 'Peetz',
    state: 'CO',
    latitude: '40.950335',
    longitude: '-103.070573',
    timeZoneId: 'America/Denver',
  },
  '80749': {
    zip: '80749',
    city: 'Sedgwick',
    state: 'CO',
    latitude: '40.915405',
    longitude: '-102.533321',
    timeZoneId: 'America/Denver',
  },
  '80750': {
    zip: '80750',
    city: 'Snyder',
    state: 'CO',
    latitude: '40.385974',
    longitude: '-103.615588',
    timeZoneId: 'America/Denver',
  },
  '80751': {
    zip: '80751',
    city: 'Sterling',
    state: 'CO',
    latitude: '40.636291',
    longitude: '-103.210799',
    timeZoneId: 'America/Denver',
  },
  '80754': {
    zip: '80754',
    city: 'Stoneham',
    state: 'CO',
    latitude: '40.597586',
    longitude: '-103.648467',
    timeZoneId: 'America/Denver',
  },
  '80755': {
    zip: '80755',
    city: 'Vernon',
    state: 'CO',
    latitude: '39.927165',
    longitude: '-102.357424',
    timeZoneId: 'America/Denver',
  },
  '80757': {
    zip: '80757',
    city: 'Woodrow',
    state: 'CO',
    latitude: '39.733095',
    longitude: '-103.505678',
    timeZoneId: 'America/Denver',
  },
  '80758': {
    zip: '80758',
    city: 'Wray',
    state: 'CO',
    latitude: '40.19268',
    longitude: '-102.27186',
    timeZoneId: 'America/Denver',
  },
  '80759': {
    zip: '80759',
    city: 'Yuma',
    state: 'CO',
    latitude: '40.133756',
    longitude: '-102.693014',
    timeZoneId: 'America/Denver',
  },
  '80801': {
    zip: '80801',
    city: 'Anton',
    state: 'CO',
    latitude: '39.709723',
    longitude: '-103.281853',
    timeZoneId: 'America/Denver',
  },
  '80802': {
    zip: '80802',
    city: 'Arapahoe',
    state: 'CO',
    latitude: '38.920045',
    longitude: '-102.226031',
    timeZoneId: 'America/Denver',
  },
  '80804': {
    zip: '80804',
    city: 'Arriba',
    state: 'CO',
    latitude: '39.285797',
    longitude: '-103.297244',
    timeZoneId: 'America/Denver',
  },
  '80805': {
    zip: '80805',
    city: 'Bethune',
    state: 'CO',
    latitude: '39.313695',
    longitude: '-102.437199',
    timeZoneId: 'America/Denver',
  },
  '80807': {
    zip: '80807',
    city: 'Burlington',
    state: 'CO',
    latitude: '39.304827',
    longitude: '-102.246973',
    timeZoneId: 'America/Denver',
  },
  '80808': {
    zip: '80808',
    city: 'Calhan',
    state: 'CO',
    latitude: '38.976792',
    longitude: '-104.316048',
    timeZoneId: 'America/Denver',
  },
  '80809': {
    zip: '80809',
    city: 'Cascade',
    state: 'CO',
    latitude: '38.911495',
    longitude: '-104.985682',
    timeZoneId: 'America/Denver',
  },
  '80810': {
    zip: '80810',
    city: 'Cheyenne Wells',
    state: 'CO',
    latitude: '38.850458',
    longitude: '-102.381819',
    timeZoneId: 'America/Denver',
  },
  '80812': {
    zip: '80812',
    city: 'Cope',
    state: 'CO',
    latitude: '39.686758',
    longitude: '-102.877262',
    timeZoneId: 'America/Denver',
  },
  '80813': {
    zip: '80813',
    city: 'Cripple Creek',
    state: 'CO',
    latitude: '38.776126',
    longitude: '-105.186093',
    timeZoneId: 'America/Denver',
  },
  '80814': {
    zip: '80814',
    city: 'Divide',
    state: 'CO',
    latitude: '38.942016',
    longitude: '-105.180968',
    timeZoneId: 'America/Denver',
  },
  '80815': {
    zip: '80815',
    city: 'Flagler',
    state: 'CO',
    latitude: '39.321415',
    longitude: '-103.060033',
    timeZoneId: 'America/Denver',
  },
  '80816': {
    zip: '80816',
    city: 'Florissant',
    state: 'CO',
    latitude: '38.891057',
    longitude: '-105.264451',
    timeZoneId: 'America/Denver',
  },
  '80817': {
    zip: '80817',
    city: 'Fountain',
    state: 'CO',
    latitude: '38.698619',
    longitude: '-104.696917',
    timeZoneId: 'America/Denver',
  },
  '80818': {
    zip: '80818',
    city: 'Genoa',
    state: 'CO',
    latitude: '39.275097',
    longitude: '-103.500657',
    timeZoneId: 'America/Denver',
  },
  '80819': {
    zip: '80819',
    city: 'Green Mountain Falls',
    state: 'CO',
    latitude: '38.932824',
    longitude: '-105.016988',
    timeZoneId: 'America/Denver',
  },
  '80820': {
    zip: '80820',
    city: 'Guffey',
    state: 'CO',
    latitude: '38.77997',
    longitude: '-105.510388',
    timeZoneId: 'America/Denver',
  },
  '80821': {
    zip: '80821',
    city: 'Hugo',
    state: 'CO',
    latitude: '38.94289',
    longitude: '-103.26991',
    timeZoneId: 'America/Denver',
  },
  '80822': {
    zip: '80822',
    city: 'Joes',
    state: 'CO',
    latitude: '39.745819',
    longitude: '-102.62312',
    timeZoneId: 'America/Denver',
  },
  '80823': {
    zip: '80823',
    city: 'Karval',
    state: 'CO',
    latitude: '38.697405',
    longitude: '-103.42135',
    timeZoneId: 'America/Denver',
  },
  '80824': {
    zip: '80824',
    city: 'Kirk',
    state: 'CO',
    latitude: '39.667666',
    longitude: '-102.417352',
    timeZoneId: 'America/Denver',
  },
  '80825': {
    zip: '80825',
    city: 'Kit Carson',
    state: 'CO',
    latitude: '38.802746',
    longitude: '-102.772985',
    timeZoneId: 'America/Denver',
  },
  '80826': {
    zip: '80826',
    city: 'Limon',
    state: 'CO',
    latitude: '39.263889',
    longitude: '-103.692415',
    timeZoneId: 'America/Denver',
  },
  '80827': {
    zip: '80827',
    city: 'Lake George',
    state: 'CO',
    latitude: '39.06372',
    longitude: '-105.48684',
    timeZoneId: 'America/Denver',
  },
  '80828': {
    zip: '80828',
    city: 'Limon',
    state: 'CO',
    latitude: '39.261367',
    longitude: '-103.688061',
    timeZoneId: 'America/Denver',
  },
  '80829': {
    zip: '80829',
    city: 'Manitou Springs',
    state: 'CO',
    latitude: '38.855979',
    longitude: '-104.906332',
    timeZoneId: 'America/Denver',
  },
  '80830': {
    zip: '80830',
    city: 'Matheson',
    state: 'CO',
    latitude: '39.09297',
    longitude: '-103.918338',
    timeZoneId: 'America/Denver',
  },
  '80831': {
    zip: '80831',
    city: 'Peyton',
    state: 'CO',
    latitude: '38.98998',
    longitude: '-104.50518',
    timeZoneId: 'America/Denver',
  },
  '80832': {
    zip: '80832',
    city: 'Ramah',
    state: 'CO',
    latitude: '39.088054',
    longitude: '-104.081653',
    timeZoneId: 'America/Denver',
  },
  '80833': {
    zip: '80833',
    city: 'Rush',
    state: 'CO',
    latitude: '38.923499',
    longitude: '-104.031203',
    timeZoneId: 'America/Denver',
  },
  '80834': {
    zip: '80834',
    city: 'Seibert',
    state: 'CO',
    latitude: '39.284699',
    longitude: '-102.885676',
    timeZoneId: 'America/Denver',
  },
  '80835': {
    zip: '80835',
    city: 'Simla',
    state: 'CO',
    latitude: '39.182899',
    longitude: '-104.08289',
    timeZoneId: 'America/Denver',
  },
  '80836': {
    zip: '80836',
    city: 'Stratton',
    state: 'CO',
    latitude: '39.311476',
    longitude: '-102.580991',
    timeZoneId: 'America/Denver',
  },
  '80840': {
    zip: '80840',
    city: 'USAF Academy',
    state: 'CO',
    latitude: '38.999759',
    longitude: '-104.695596',
    timeZoneId: 'America/Denver',
  },
  '80841': {
    zip: '80841',
    city: 'USAF Academy',
    state: 'CO',
    latitude: '39.01074',
    longitude: '-104.866903',
    timeZoneId: 'America/Denver',
  },
  '80860': {
    zip: '80860',
    city: 'Victor',
    state: 'CO',
    latitude: '38.739224',
    longitude: '-105.125564',
    timeZoneId: 'America/Denver',
  },
  '80861': {
    zip: '80861',
    city: 'Vona',
    state: 'CO',
    latitude: '39.35388',
    longitude: '-102.735678',
    timeZoneId: 'America/Denver',
  },
  '80862': {
    zip: '80862',
    city: 'Wild Horse',
    state: 'CO',
    latitude: '38.914328',
    longitude: '-103.045161',
    timeZoneId: 'America/Denver',
  },
  '80863': {
    zip: '80863',
    city: 'Woodland Park',
    state: 'CO',
    latitude: '38.992976',
    longitude: '-105.071027',
    timeZoneId: 'America/Denver',
  },
  '80864': {
    zip: '80864',
    city: 'Yoder',
    state: 'CO',
    latitude: '38.751983',
    longitude: '-104.20064',
    timeZoneId: 'America/Denver',
  },
  '80866': {
    zip: '80866',
    city: 'Woodland Park',
    state: 'CO',
    latitude: '38.994835',
    longitude: '-105.060746',
    timeZoneId: 'America/Denver',
  },
  '80901': {
    zip: '80901',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.834608',
    longitude: '-104.82255',
    timeZoneId: 'America/Denver',
  },
  '80902': {
    zip: '80902',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.759197',
    longitude: '-104.809176',
    timeZoneId: 'America/Denver',
  },
  '80903': {
    zip: '80903',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.831839',
    longitude: '-104.818927',
    timeZoneId: 'America/Denver',
  },
  '80904': {
    zip: '80904',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.855129',
    longitude: '-104.865127',
    timeZoneId: 'America/Denver',
  },
  '80905': {
    zip: '80905',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.836829',
    longitude: '-104.837659',
    timeZoneId: 'America/Denver',
  },
  '80906': {
    zip: '80906',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.791623',
    longitude: '-104.825814',
    timeZoneId: 'America/Denver',
  },
  '80907': {
    zip: '80907',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.881982',
    longitude: '-104.814334',
    timeZoneId: 'America/Denver',
  },
  '80908': {
    zip: '80908',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '39.04641',
    longitude: '-104.66991',
    timeZoneId: 'America/Denver',
  },
  '80909': {
    zip: '80909',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.852846',
    longitude: '-104.761164',
    timeZoneId: 'America/Denver',
  },
  '80910': {
    zip: '80910',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.816626',
    longitude: '-104.777849',
    timeZoneId: 'America/Denver',
  },
  '80911': {
    zip: '80911',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.73881',
    longitude: '-104.70781',
    timeZoneId: 'America/Denver',
  },
  '80912': {
    zip: '80912',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '39.047451',
    longitude: '-104.696155',
    timeZoneId: 'America/Denver',
  },
  '80913': {
    zip: '80913',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.609596',
    longitude: '-104.809735',
    timeZoneId: 'America/Denver',
  },
  '80914': {
    zip: '80914',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.786821',
    longitude: '-104.714138',
    timeZoneId: 'America/Denver',
  },
  '80915': {
    zip: '80915',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.856009',
    longitude: '-104.716668',
    timeZoneId: 'America/Denver',
  },
  '80916': {
    zip: '80916',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.803293',
    longitude: '-104.707538',
    timeZoneId: 'America/Denver',
  },
  '80917': {
    zip: '80917',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.88764',
    longitude: '-104.738816',
    timeZoneId: 'America/Denver',
  },
  '80918': {
    zip: '80918',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.915062',
    longitude: '-104.773227',
    timeZoneId: 'America/Denver',
  },
  '80919': {
    zip: '80919',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.928018',
    longitude: '-104.848484',
    timeZoneId: 'America/Denver',
  },
  '80920': {
    zip: '80920',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.958459',
    longitude: '-104.757058',
    timeZoneId: 'America/Denver',
  },
  '80921': {
    zip: '80921',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '39.047737',
    longitude: '-104.816446',
    timeZoneId: 'America/Denver',
  },
  '80922': {
    zip: '80922',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.898824',
    longitude: '-104.700007',
    timeZoneId: 'America/Denver',
  },
  '80923': {
    zip: '80923',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.899457',
    longitude: '-104.699208',
    timeZoneId: 'America/Denver',
  },
  '80924': {
    zip: '80924',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.739417',
    longitude: '-104.63517',
    timeZoneId: 'America/Denver',
  },
  '80925': {
    zip: '80925',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.739647',
    longitude: '-104.635922',
    timeZoneId: 'America/Denver',
  },
  '80926': {
    zip: '80926',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.663492',
    longitude: '-104.909353',
    timeZoneId: 'America/Denver',
  },
  '80927': {
    zip: '80927',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.664269',
    longitude: '-104.909568',
    timeZoneId: 'America/Denver',
  },
  '80928': {
    zip: '80928',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.588656',
    longitude: '-104.442148',
    timeZoneId: 'America/Denver',
  },
  '80929': {
    zip: '80929',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.814846',
    longitude: '-104.619185',
    timeZoneId: 'America/Denver',
  },
  '80930': {
    zip: '80930',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.830904',
    longitude: '-104.51363',
    timeZoneId: 'America/Denver',
  },
  '80931': {
    zip: '80931',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.75272',
    longitude: '-104.731586',
    timeZoneId: 'America/Denver',
  },
  '80932': {
    zip: '80932',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.848511',
    longitude: '-104.780034',
    timeZoneId: 'America/Denver',
  },
  '80933': {
    zip: '80933',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.934825',
    longitude: '-105.004782',
    timeZoneId: 'America/Denver',
  },
  '80934': {
    zip: '80934',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.844726',
    longitude: '-104.860092',
    timeZoneId: 'America/Denver',
  },
  '80935': {
    zip: '80935',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.833696',
    longitude: '-104.818441',
    timeZoneId: 'America/Denver',
  },
  '80936': {
    zip: '80936',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.906063',
    longitude: '-104.753199',
    timeZoneId: 'America/Denver',
  },
  '80937': {
    zip: '80937',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.798001',
    longitude: '-104.836875',
    timeZoneId: 'America/Denver',
  },
  '80938': {
    zip: '80938',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.79769',
    longitude: '-104.837067',
    timeZoneId: 'America/Denver',
  },
  '80939': {
    zip: '80939',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.798565',
    longitude: '-104.836169',
    timeZoneId: 'America/Denver',
  },
  '80941': {
    zip: '80941',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.866594',
    longitude: '-104.755324',
    timeZoneId: 'America/Denver',
  },
  '80942': {
    zip: '80942',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.834659',
    longitude: '-104.822936',
    timeZoneId: 'America/Denver',
  },
  '80946': {
    zip: '80946',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.847888',
    longitude: '-104.824771',
    timeZoneId: 'America/Denver',
  },
  '80947': {
    zip: '80947',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.832634',
    longitude: '-104.819249',
    timeZoneId: 'America/Denver',
  },
  '80949': {
    zip: '80949',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.929049',
    longitude: '-104.855865',
    timeZoneId: 'America/Denver',
  },
  '80950': {
    zip: '80950',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.837558',
    longitude: '-104.795156',
    timeZoneId: 'America/Denver',
  },
  '80951': {
    zip: '80951',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.837139',
    longitude: '-104.795539',
    timeZoneId: 'America/Denver',
  },
  '80960': {
    zip: '80960',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.810217',
    longitude: '-104.816961',
    timeZoneId: 'America/Denver',
  },
  '80962': {
    zip: '80962',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.954393',
    longitude: '-104.733997',
    timeZoneId: 'America/Denver',
  },
  '80970': {
    zip: '80970',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.848525',
    longitude: '-104.7177',
    timeZoneId: 'America/Denver',
  },
  '80977': {
    zip: '80977',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.772039',
    longitude: '-104.665048',
    timeZoneId: 'America/Denver',
  },
  '80995': {
    zip: '80995',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.964661',
    longitude: '-104.792493',
    timeZoneId: 'America/Denver',
  },
  '80997': {
    zip: '80997',
    city: 'Colorado Springs',
    state: 'CO',
    latitude: '38.877688',
    longitude: '-104.819099',
    timeZoneId: 'America/Denver',
  },
  '81001': {
    zip: '81001',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.285226',
    longitude: '-104.577144',
    timeZoneId: 'America/Denver',
  },
  '81002': {
    zip: '81002',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.253797',
    longitude: '-104.607766',
    timeZoneId: 'America/Denver',
  },
  '81003': {
    zip: '81003',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.282078',
    longitude: '-104.625815',
    timeZoneId: 'America/Denver',
  },
  '81004': {
    zip: '81004',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.226239',
    longitude: '-104.660953',
    timeZoneId: 'America/Denver',
  },
  '81005': {
    zip: '81005',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.233107',
    longitude: '-104.710769',
    timeZoneId: 'America/Denver',
  },
  '81006': {
    zip: '81006',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.244574',
    longitude: '-104.524364',
    timeZoneId: 'America/Denver',
  },
  '81007': {
    zip: '81007',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.3466',
    longitude: '-104.78886',
    timeZoneId: 'America/Denver',
  },
  '81008': {
    zip: '81008',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.327052',
    longitude: '-104.62595',
    timeZoneId: 'America/Denver',
  },
  '81009': {
    zip: '81009',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.279229',
    longitude: '-104.489956',
    timeZoneId: 'America/Denver',
  },
  '81010': {
    zip: '81010',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.275773',
    longitude: '-104.607888',
    timeZoneId: 'America/Denver',
  },
  '81011': {
    zip: '81011',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.276026',
    longitude: '-104.610181',
    timeZoneId: 'America/Denver',
  },
  '81012': {
    zip: '81012',
    city: 'Pueblo',
    state: 'CO',
    latitude: '38.272962',
    longitude: '-104.609114',
    timeZoneId: 'America/Denver',
  },
  '81019': {
    zip: '81019',
    city: 'Colorado City',
    state: 'CO',
    latitude: '37.943546',
    longitude: '-104.846617',
    timeZoneId: 'America/Denver',
  },
  '81020': {
    zip: '81020',
    city: 'Aguilar',
    state: 'CO',
    latitude: '37.400582',
    longitude: '-104.64219',
    timeZoneId: 'America/Denver',
  },
  '81021': {
    zip: '81021',
    city: 'Arlington',
    state: 'CO',
    latitude: '38.390735',
    longitude: '-103.369404',
    timeZoneId: 'America/Denver',
  },
  '81022': {
    zip: '81022',
    city: 'Avondale',
    state: 'CO',
    latitude: '38.179817',
    longitude: '-104.390761',
    timeZoneId: 'America/Denver',
  },
  '81023': {
    zip: '81023',
    city: 'Beulah',
    state: 'CO',
    latitude: '38.073947',
    longitude: '-104.953614',
    timeZoneId: 'America/Denver',
  },
  '81024': {
    zip: '81024',
    city: 'Boncarbo',
    state: 'CO',
    latitude: '37.217437',
    longitude: '-104.698315',
    timeZoneId: 'America/Denver',
  },
  '81025': {
    zip: '81025',
    city: 'Boone',
    state: 'CO',
    latitude: '38.284292',
    longitude: '-104.217474',
    timeZoneId: 'America/Denver',
  },
  '81027': {
    zip: '81027',
    city: 'Branson',
    state: 'CO',
    latitude: '37.132345',
    longitude: '-103.773649',
    timeZoneId: 'America/Denver',
  },
  '81029': {
    zip: '81029',
    city: 'Campo',
    state: 'CO',
    latitude: '37.092964',
    longitude: '-102.569773',
    timeZoneId: 'America/Denver',
  },
  '81030': {
    zip: '81030',
    city: 'Cheraw',
    state: 'CO',
    latitude: '38.108149',
    longitude: '-103.506915',
    timeZoneId: 'America/Denver',
  },
  '81033': {
    zip: '81033',
    city: 'Crowley',
    state: 'CO',
    latitude: '38.190478',
    longitude: '-103.859733',
    timeZoneId: 'America/Denver',
  },
  '81034': {
    zip: '81034',
    city: 'Ordway',
    state: 'CO',
    latitude: '38.19235',
    longitude: '-103.856264',
    timeZoneId: 'America/Denver',
  },
  '81036': {
    zip: '81036',
    city: 'Eads',
    state: 'CO',
    latitude: '38.463',
    longitude: '-102.65286',
    timeZoneId: 'America/Denver',
  },
  '81038': {
    zip: '81038',
    city: 'Fort Lyon',
    state: 'CO',
    latitude: '37.910961',
    longitude: '-103.098113',
    timeZoneId: 'America/Denver',
  },
  '81039': {
    zip: '81039',
    city: 'Fowler',
    state: 'CO',
    latitude: '38.123114',
    longitude: '-104.018939',
    timeZoneId: 'America/Denver',
  },
  '81040': {
    zip: '81040',
    city: 'Gardner',
    state: 'CO',
    latitude: '37.786005',
    longitude: '-105.182854',
    timeZoneId: 'America/Denver',
  },
  '81041': {
    zip: '81041',
    city: 'Granada',
    state: 'CO',
    latitude: '37.913104',
    longitude: '-102.348905',
    timeZoneId: 'America/Denver',
  },
  '81043': {
    zip: '81043',
    city: 'Hartman',
    state: 'CO',
    latitude: '38.088807',
    longitude: '-102.169281',
    timeZoneId: 'America/Denver',
  },
  '81044': {
    zip: '81044',
    city: 'Hasty',
    state: 'CO',
    latitude: '38.061389',
    longitude: '-102.926667',
    timeZoneId: 'America/Denver',
  },
  '81045': {
    zip: '81045',
    city: 'Haswell',
    state: 'CO',
    latitude: '38.437686',
    longitude: '-103.124591',
    timeZoneId: 'America/Denver',
  },
  '81046': {
    zip: '81046',
    city: 'Hoehne',
    state: 'CO',
    latitude: '37.169175',
    longitude: '-104.502607',
    timeZoneId: 'America/Denver',
  },
  '81047': {
    zip: '81047',
    city: 'Holly',
    state: 'CO',
    latitude: '37.982565',
    longitude: '-102.140804',
    timeZoneId: 'America/Denver',
  },
  '81049': {
    zip: '81049',
    city: 'Kim',
    state: 'CO',
    latitude: '37.306177',
    longitude: '-103.387984',
    timeZoneId: 'America/Denver',
  },
  '81050': {
    zip: '81050',
    city: 'La Junta',
    state: 'CO',
    latitude: '37.81822',
    longitude: '-103.77405',
    timeZoneId: 'America/Denver',
  },
  '81052': {
    zip: '81052',
    city: 'Lamar',
    state: 'CO',
    latitude: '37.95864',
    longitude: '-102.5787',
    timeZoneId: 'America/Denver',
  },
  '81054': {
    zip: '81054',
    city: 'Las Animas',
    state: 'CO',
    latitude: '38.047431',
    longitude: '-103.208858',
    timeZoneId: 'America/Denver',
  },
  '81055': {
    zip: '81055',
    city: 'La Veta',
    state: 'CO',
    latitude: '37.46112',
    longitude: '-105.03918',
    timeZoneId: 'America/Denver',
  },
  '81057': {
    zip: '81057',
    city: 'Mc Clave',
    state: 'CO',
    latitude: '38.134649',
    longitude: '-102.869788',
    timeZoneId: 'America/Denver',
  },
  '81058': {
    zip: '81058',
    city: 'Manzanola',
    state: 'CO',
    latitude: '38.10462',
    longitude: '-103.872321',
    timeZoneId: 'America/Denver',
  },
  '81059': {
    zip: '81059',
    city: 'Model',
    state: 'CO',
    latitude: '37.498583',
    longitude: '-104.151083',
    timeZoneId: 'America/Denver',
  },
  '81062': {
    zip: '81062',
    city: 'Olney Springs',
    state: 'CO',
    latitude: '38.212062',
    longitude: '-103.937865',
    timeZoneId: 'America/Denver',
  },
  '81063': {
    zip: '81063',
    city: 'Ordway',
    state: 'CO',
    latitude: '38.237711',
    longitude: '-103.784046',
    timeZoneId: 'America/Denver',
  },
  '81064': {
    zip: '81064',
    city: 'Pritchett',
    state: 'CO',
    latitude: '37.237984',
    longitude: '-103.164023',
    timeZoneId: 'America/Denver',
  },
  '81067': {
    zip: '81067',
    city: 'Rocky Ford',
    state: 'CO',
    latitude: '38.044708',
    longitude: '-103.717601',
    timeZoneId: 'America/Denver',
  },
  '81069': {
    zip: '81069',
    city: 'Rye',
    state: 'CO',
    latitude: '37.927576',
    longitude: '-104.902916',
    timeZoneId: 'America/Denver',
  },
  '81071': {
    zip: '81071',
    city: 'Sheridan Lake',
    state: 'CO',
    latitude: '38.446999',
    longitude: '-102.218718',
    timeZoneId: 'America/Denver',
  },
  '81073': {
    zip: '81073',
    city: 'Springfield',
    state: 'CO',
    latitude: '37.397087',
    longitude: '-102.621835',
    timeZoneId: 'America/Denver',
  },
  '81076': {
    zip: '81076',
    city: 'Sugar City',
    state: 'CO',
    latitude: '38.251787',
    longitude: '-103.665519',
    timeZoneId: 'America/Denver',
  },
  '81077': {
    zip: '81077',
    city: 'Swink',
    state: 'CO',
    latitude: '38.015209',
    longitude: '-103.627743',
    timeZoneId: 'America/Denver',
  },
  '81081': {
    zip: '81081',
    city: 'Trinchera',
    state: 'CO',
    latitude: '37.012494',
    longitude: '-103.883558',
    timeZoneId: 'America/Denver',
  },
  '81082': {
    zip: '81082',
    city: 'Trinidad',
    state: 'CO',
    latitude: '37.173925',
    longitude: '-104.512742',
    timeZoneId: 'America/Denver',
  },
  '81084': {
    zip: '81084',
    city: 'Two Buttes',
    state: 'CO',
    latitude: '37.693548',
    longitude: '-102.354655',
    timeZoneId: 'America/Denver',
  },
  '81087': {
    zip: '81087',
    city: 'Vilas',
    state: 'CO',
    latitude: '37.389264',
    longitude: '-102.435317',
    timeZoneId: 'America/Denver',
  },
  '81089': {
    zip: '81089',
    city: 'Walsenburg',
    state: 'CO',
    latitude: '37.61716',
    longitude: '-104.817267',
    timeZoneId: 'America/Denver',
  },
  '81090': {
    zip: '81090',
    city: 'Walsh',
    state: 'CO',
    latitude: '37.351772',
    longitude: '-102.26043',
    timeZoneId: 'America/Denver',
  },
  '81091': {
    zip: '81091',
    city: 'Weston',
    state: 'CO',
    latitude: '37.17456',
    longitude: '-104.89554',
    timeZoneId: 'America/Denver',
  },
  '81092': {
    zip: '81092',
    city: 'Wiley',
    state: 'CO',
    latitude: '38.158784',
    longitude: '-102.71406',
    timeZoneId: 'America/Denver',
  },
  '81101': {
    zip: '81101',
    city: 'Alamosa',
    state: 'CO',
    latitude: '37.472954',
    longitude: '-105.866537',
    timeZoneId: 'America/Denver',
  },
  '81102': {
    zip: '81102',
    city: 'Alamosa',
    state: 'CO',
    latitude: '37.47193',
    longitude: '-105.861118',
    timeZoneId: 'America/Denver',
  },
  '81120': {
    zip: '81120',
    city: 'Antonito',
    state: 'CO',
    latitude: '37.0731',
    longitude: '-106.2468',
    timeZoneId: 'America/Denver',
  },
  '81121': {
    zip: '81121',
    city: 'Arboles',
    state: 'CO',
    latitude: '37.0267',
    longitude: '-107.483555',
    timeZoneId: 'America/Denver',
  },
  '81122': {
    zip: '81122',
    city: 'Bayfield',
    state: 'CO',
    latitude: '37.31442',
    longitude: '-107.61228',
    timeZoneId: 'America/Denver',
  },
  '81123': {
    zip: '81123',
    city: 'Blanca',
    state: 'CO',
    latitude: '37.431657',
    longitude: '-105.523346',
    timeZoneId: 'America/Denver',
  },
  '81124': {
    zip: '81124',
    city: 'Capulin',
    state: 'CO',
    latitude: '37.281746',
    longitude: '-106.101224',
    timeZoneId: 'America/Denver',
  },
  '81125': {
    zip: '81125',
    city: 'Center',
    state: 'CO',
    latitude: '37.779965',
    longitude: '-106.09591',
    timeZoneId: 'America/Denver',
  },
  '81126': {
    zip: '81126',
    city: 'Chama',
    state: 'CO',
    latitude: '37.171408',
    longitude: '-105.390972',
    timeZoneId: 'America/Denver',
  },
  '81128': {
    zip: '81128',
    city: 'Chromo',
    state: 'CO',
    latitude: '37.038716',
    longitude: '-106.843999',
    timeZoneId: 'America/Denver',
  },
  '81129': {
    zip: '81129',
    city: 'Conejos',
    state: 'CO',
    latitude: '37.174976',
    longitude: '-105.940061',
    timeZoneId: 'America/Denver',
  },
  '81130': {
    zip: '81130',
    city: 'Creede',
    state: 'CO',
    latitude: '37.843042',
    longitude: '-106.926914',
    timeZoneId: 'America/Denver',
  },
  '81131': {
    zip: '81131',
    city: 'Crestone',
    state: 'CO',
    latitude: '37.992916',
    longitude: '-105.700693',
    timeZoneId: 'America/Denver',
  },
  '81132': {
    zip: '81132',
    city: 'Del Norte',
    state: 'CO',
    latitude: '37.680294',
    longitude: '-106.365099',
    timeZoneId: 'America/Denver',
  },
  '81133': {
    zip: '81133',
    city: 'Fort Garland',
    state: 'CO',
    latitude: '37.413562',
    longitude: '-105.393706',
    timeZoneId: 'America/Denver',
  },
  '81135': {
    zip: '81135',
    city: 'Homelake',
    state: 'CO',
    latitude: '37.578239',
    longitude: '-106.147242',
    timeZoneId: 'America/Denver',
  },
  '81136': {
    zip: '81136',
    city: 'Hooper',
    state: 'CO',
    latitude: '37.740979',
    longitude: '-105.903868',
    timeZoneId: 'America/Denver',
  },
  '81137': {
    zip: '81137',
    city: 'Ignacio',
    state: 'CO',
    latitude: '37.105788',
    longitude: '-107.597693',
    timeZoneId: 'America/Denver',
  },
  '81138': {
    zip: '81138',
    city: 'Jaroso',
    state: 'CO',
    latitude: '37.003263',
    longitude: '-105.624634',
    timeZoneId: 'America/Denver',
  },
  '81140': {
    zip: '81140',
    city: 'La Jara',
    state: 'CO',
    latitude: '37.30896',
    longitude: '-106.03524',
    timeZoneId: 'America/Denver',
  },
  '81141': {
    zip: '81141',
    city: 'Manassa',
    state: 'CO',
    latitude: '37.173854',
    longitude: '-105.934271',
    timeZoneId: 'America/Denver',
  },
  '81143': {
    zip: '81143',
    city: 'Moffat',
    state: 'CO',
    latitude: '38.079015',
    longitude: '-105.89452',
    timeZoneId: 'America/Denver',
  },
  '81144': {
    zip: '81144',
    city: 'Monte Vista',
    state: 'CO',
    latitude: '37.566562',
    longitude: '-106.148089',
    timeZoneId: 'America/Denver',
  },
  '81146': {
    zip: '81146',
    city: 'Mosca',
    state: 'CO',
    latitude: '37.648702',
    longitude: '-105.700616',
    timeZoneId: 'America/Denver',
  },
  '81147': {
    zip: '81147',
    city: 'Pagosa Springs',
    state: 'CO',
    latitude: '37.256494',
    longitude: '-107.086521',
    timeZoneId: 'America/Denver',
  },
  '81148': {
    zip: '81148',
    city: 'Romeo',
    state: 'CO',
    latitude: '37.173951',
    longitude: '-105.983484',
    timeZoneId: 'America/Denver',
  },
  '81149': {
    zip: '81149',
    city: 'Saguache',
    state: 'CO',
    latitude: '38.08143',
    longitude: '-106.131788',
    timeZoneId: 'America/Denver',
  },
  '81151': {
    zip: '81151',
    city: 'Sanford',
    state: 'CO',
    latitude: '37.265658',
    longitude: '-105.84832',
    timeZoneId: 'America/Denver',
  },
  '81152': {
    zip: '81152',
    city: 'San Luis',
    state: 'CO',
    latitude: '37.08138',
    longitude: '-105.6225',
    timeZoneId: 'America/Denver',
  },
  '81154': {
    zip: '81154',
    city: 'South Fork',
    state: 'CO',
    latitude: '37.672402',
    longitude: '-106.630824',
    timeZoneId: 'America/Denver',
  },
  '81155': {
    zip: '81155',
    city: 'Villa Grove',
    state: 'CO',
    latitude: '38.248665',
    longitude: '-106.081758',
    timeZoneId: 'America/Denver',
  },
  '81157': {
    zip: '81157',
    city: 'Pagosa Springs',
    state: 'CO',
    latitude: '37.268072',
    longitude: '-107.088673',
    timeZoneId: 'America/Denver',
  },
  '81201': {
    zip: '81201',
    city: 'Salida',
    state: 'CO',
    latitude: '38.53398',
    longitude: '-106.03878',
    timeZoneId: 'America/Denver',
  },
  '81210': {
    zip: '81210',
    city: 'Almont',
    state: 'CO',
    latitude: '38.81454',
    longitude: '-106.65996',
    timeZoneId: 'America/Denver',
  },
  '81211': {
    zip: '81211',
    city: 'Buena Vista',
    state: 'CO',
    latitude: '38.849213',
    longitude: '-106.155646',
    timeZoneId: 'America/Denver',
  },
  '81212': {
    zip: '81212',
    city: 'Canon City',
    state: 'CO',
    latitude: '38.448695',
    longitude: '-105.23279',
    timeZoneId: 'America/Denver',
  },
  '81215': {
    zip: '81215',
    city: 'Canon City',
    state: 'CO',
    latitude: '38.447689',
    longitude: '-105.222893',
    timeZoneId: 'America/Denver',
  },
  '81220': {
    zip: '81220',
    city: 'Cimarron',
    state: 'CO',
    latitude: '38.320133',
    longitude: '-107.454381',
    timeZoneId: 'America/Denver',
  },
  '81221': {
    zip: '81221',
    city: 'Coal Creek',
    state: 'CO',
    latitude: '38.361447',
    longitude: '-105.148019',
    timeZoneId: 'America/Denver',
  },
  '81222': {
    zip: '81222',
    city: 'Coaldale',
    state: 'CO',
    latitude: '38.387714',
    longitude: '-105.769656',
    timeZoneId: 'America/Denver',
  },
  '81223': {
    zip: '81223',
    city: 'Cotopaxi',
    state: 'CO',
    latitude: '38.36298',
    longitude: '-105.4956',
    timeZoneId: 'America/Denver',
  },
  '81224': {
    zip: '81224',
    city: 'Crested Butte',
    state: 'CO',
    latitude: '38.860596',
    longitude: '-106.956766',
    timeZoneId: 'America/Denver',
  },
  '81225': {
    zip: '81225',
    city: 'Crested Butte',
    state: 'CO',
    latitude: '38.900406',
    longitude: '-106.965249',
    timeZoneId: 'America/Denver',
  },
  '81226': {
    zip: '81226',
    city: 'Florence',
    state: 'CO',
    latitude: '38.37006',
    longitude: '-105.11976',
    timeZoneId: 'America/Denver',
  },
  '81227': {
    zip: '81227',
    city: 'Monarch',
    state: 'CO',
    latitude: '38.532188',
    longitude: '-105.998639',
    timeZoneId: 'America/Denver',
  },
  '81228': {
    zip: '81228',
    city: 'Granite',
    state: 'CO',
    latitude: '39.045472',
    longitude: '-106.262136',
    timeZoneId: 'America/Denver',
  },
  '81230': {
    zip: '81230',
    city: 'Gunnison',
    state: 'CO',
    latitude: '38.45342',
    longitude: '-106.60986',
    timeZoneId: 'America/Denver',
  },
  '81231': {
    zip: '81231',
    city: 'Gunnison',
    state: 'CO',
    latitude: '38.548638',
    longitude: '-106.918609',
    timeZoneId: 'America/Denver',
  },
  '81232': {
    zip: '81232',
    city: 'Hillside',
    state: 'CO',
    latitude: '38.27188',
    longitude: '-105.60746',
    timeZoneId: 'America/Denver',
  },
  '81233': {
    zip: '81233',
    city: 'Howard',
    state: 'CO',
    latitude: '38.426401',
    longitude: '-105.833365',
    timeZoneId: 'America/Denver',
  },
  '81235': {
    zip: '81235',
    city: 'Lake City',
    state: 'CO',
    latitude: '38.023092',
    longitude: '-107.316944',
    timeZoneId: 'America/Denver',
  },
  '81236': {
    zip: '81236',
    city: 'Nathrop',
    state: 'CO',
    latitude: '38.700006',
    longitude: '-106.141598',
    timeZoneId: 'America/Denver',
  },
  '81237': {
    zip: '81237',
    city: 'Ohio City',
    state: 'CO',
    latitude: '38.579635',
    longitude: '-106.579304',
    timeZoneId: 'America/Denver',
  },
  '81239': {
    zip: '81239',
    city: 'Parlin',
    state: 'CO',
    latitude: '38.505298',
    longitude: '-106.636113',
    timeZoneId: 'America/Denver',
  },
  '81240': {
    zip: '81240',
    city: 'Penrose',
    state: 'CO',
    latitude: '38.431671',
    longitude: '-105.009121',
    timeZoneId: 'America/Denver',
  },
  '81241': {
    zip: '81241',
    city: 'Pitkin',
    state: 'CO',
    latitude: '38.609178',
    longitude: '-106.515547',
    timeZoneId: 'America/Denver',
  },
  '81242': {
    zip: '81242',
    city: 'Poncha Springs',
    state: 'CO',
    latitude: '38.514511',
    longitude: '-106.074083',
    timeZoneId: 'America/Denver',
  },
  '81243': {
    zip: '81243',
    city: 'Powderhorn',
    state: 'CO',
    latitude: '38.268871',
    longitude: '-107.190705',
    timeZoneId: 'America/Denver',
  },
  '81244': {
    zip: '81244',
    city: 'Rockvale',
    state: 'CO',
    latitude: '38.367179',
    longitude: '-105.165393',
    timeZoneId: 'America/Denver',
  },
  '81248': {
    zip: '81248',
    city: 'Sargents',
    state: 'CO',
    latitude: '38.539419',
    longitude: '-106.395042',
    timeZoneId: 'America/Denver',
  },
  '81251': {
    zip: '81251',
    city: 'Twin Lakes',
    state: 'CO',
    latitude: '39.07122',
    longitude: '-106.44852',
    timeZoneId: 'America/Denver',
  },
  '81252': {
    zip: '81252',
    city: 'Westcliffe',
    state: 'CO',
    latitude: '38.114881',
    longitude: '-105.429189',
    timeZoneId: 'America/Denver',
  },
  '81253': {
    zip: '81253',
    city: 'Wetmore',
    state: 'CO',
    latitude: '38.178706',
    longitude: '-105.106327',
    timeZoneId: 'America/Denver',
  },
  '81290': {
    zip: '81290',
    city: 'Florence',
    state: 'CO',
    latitude: '38.388104',
    longitude: '-105.118653',
    timeZoneId: 'America/Denver',
  },
  '81301': {
    zip: '81301',
    city: 'Durango',
    state: 'CO',
    latitude: '37.32684',
    longitude: '-107.83146',
    timeZoneId: 'America/Denver',
  },
  '81302': {
    zip: '81302',
    city: 'Durango',
    state: 'CO',
    latitude: '37.313813',
    longitude: '-107.877231',
    timeZoneId: 'America/Denver',
  },
  '81303': {
    zip: '81303',
    city: 'Durango',
    state: 'CO',
    latitude: '37.275776',
    longitude: '-107.876808',
    timeZoneId: 'America/Denver',
  },
  '81320': {
    zip: '81320',
    city: 'Cahone',
    state: 'CO',
    latitude: '37.692194',
    longitude: '-108.757665',
    timeZoneId: 'America/Denver',
  },
  '81321': {
    zip: '81321',
    city: 'Cortez',
    state: 'CO',
    latitude: '37.347',
    longitude: '-108.73632',
    timeZoneId: 'America/Denver',
  },
  '81323': {
    zip: '81323',
    city: 'Dolores',
    state: 'CO',
    latitude: '37.45541',
    longitude: '-108.516324',
    timeZoneId: 'America/Denver',
  },
  '81324': {
    zip: '81324',
    city: 'Dove Creek',
    state: 'CO',
    latitude: '37.765803',
    longitude: '-108.904715',
    timeZoneId: 'America/Denver',
  },
  '81325': {
    zip: '81325',
    city: 'Egnar',
    state: 'CO',
    latitude: '37.91',
    longitude: '-108.93',
    timeZoneId: 'America/Denver',
  },
  '81326': {
    zip: '81326',
    city: 'Hesperus',
    state: 'CO',
    latitude: '37.215884',
    longitude: '-108.087279',
    timeZoneId: 'America/Denver',
  },
  '81327': {
    zip: '81327',
    city: 'Lewis',
    state: 'CO',
    latitude: '37.498381',
    longitude: '-108.671871',
    timeZoneId: 'America/Denver',
  },
  '81328': {
    zip: '81328',
    city: 'Mancos',
    state: 'CO',
    latitude: '37.369314',
    longitude: '-108.348608',
    timeZoneId: 'America/Denver',
  },
  '81329': {
    zip: '81329',
    city: 'Marvel',
    state: 'CO',
    latitude: '37.113282',
    longitude: '-108.124637',
    timeZoneId: 'America/Denver',
  },
  '81330': {
    zip: '81330',
    city: 'Mesa Verde National Park',
    state: 'CO',
    latitude: '37.297111',
    longitude: '-108.420076',
    timeZoneId: 'America/Denver',
  },
  '81331': {
    zip: '81331',
    city: 'Pleasant View',
    state: 'CO',
    latitude: '37.551903',
    longitude: '-108.804936',
    timeZoneId: 'America/Denver',
  },
  '81332': {
    zip: '81332',
    city: 'Rico',
    state: 'CO',
    latitude: '37.68701',
    longitude: '-108.030947',
    timeZoneId: 'America/Denver',
  },
  '81334': {
    zip: '81334',
    city: 'Towaoc',
    state: 'CO',
    latitude: '37.191688',
    longitude: '-108.741033',
    timeZoneId: 'America/Denver',
  },
  '81335': {
    zip: '81335',
    city: 'Yellow Jacket',
    state: 'CO',
    latitude: '37.497977',
    longitude: '-108.773677',
    timeZoneId: 'America/Denver',
  },
  '81401': {
    zip: '81401',
    city: 'Montrose',
    state: 'CO',
    latitude: '38.412',
    longitude: '-107.8629',
    timeZoneId: 'America/Denver',
  },
  '81402': {
    zip: '81402',
    city: 'Montrose',
    state: 'CO',
    latitude: '38.484963',
    longitude: '-107.838023',
    timeZoneId: 'America/Denver',
  },
  '81403': {
    zip: '81403',
    city: 'Montrose',
    state: 'CO',
    latitude: '38.487341',
    longitude: '-107.838735',
    timeZoneId: 'America/Denver',
  },
  '81410': {
    zip: '81410',
    city: 'Austin',
    state: 'CO',
    latitude: '38.792114',
    longitude: '-107.97251',
    timeZoneId: 'America/Denver',
  },
  '81411': {
    zip: '81411',
    city: 'Bedrock',
    state: 'CO',
    latitude: '38.334387',
    longitude: '-108.885548',
    timeZoneId: 'America/Denver',
  },
  '81413': {
    zip: '81413',
    city: 'Cedaredge',
    state: 'CO',
    latitude: '38.9571',
    longitude: '-107.91396',
    timeZoneId: 'America/Denver',
  },
  '81414': {
    zip: '81414',
    city: 'Cory',
    state: 'CO',
    latitude: '38.788359',
    longitude: '-107.986539',
    timeZoneId: 'America/Denver',
  },
  '81415': {
    zip: '81415',
    city: 'Crawford',
    state: 'CO',
    latitude: '38.710743',
    longitude: '-107.615416',
    timeZoneId: 'America/Denver',
  },
  '81416': {
    zip: '81416',
    city: 'Delta',
    state: 'CO',
    latitude: '38.740747',
    longitude: '-108.05012',
    timeZoneId: 'America/Denver',
  },
  '81418': {
    zip: '81418',
    city: 'Eckert',
    state: 'CO',
    latitude: '38.852404',
    longitude: '-107.967183',
    timeZoneId: 'America/Denver',
  },
  '81419': {
    zip: '81419',
    city: 'Hotchkiss',
    state: 'CO',
    latitude: '38.817069',
    longitude: '-107.748719',
    timeZoneId: 'America/Denver',
  },
  '81420': {
    zip: '81420',
    city: 'Lazear',
    state: 'CO',
    latitude: '38.776803',
    longitude: '-107.781887',
    timeZoneId: 'America/Denver',
  },
  '81422': {
    zip: '81422',
    city: 'Naturita',
    state: 'CO',
    latitude: '38.230861',
    longitude: '-108.586113',
    timeZoneId: 'America/Denver',
  },
  '81423': {
    zip: '81423',
    city: 'Norwood',
    state: 'CO',
    latitude: '38.125191',
    longitude: '-108.27958',
    timeZoneId: 'America/Denver',
  },
  '81424': {
    zip: '81424',
    city: 'Nucla',
    state: 'CO',
    latitude: '38.265437',
    longitude: '-108.553494',
    timeZoneId: 'America/Denver',
  },
  '81425': {
    zip: '81425',
    city: 'Olathe',
    state: 'CO',
    latitude: '38.602831',
    longitude: '-107.999572',
    timeZoneId: 'America/Denver',
  },
  '81426': {
    zip: '81426',
    city: 'Ophir',
    state: 'CO',
    latitude: '37.862652',
    longitude: '-107.874103',
    timeZoneId: 'America/Denver',
  },
  '81427': {
    zip: '81427',
    city: 'Ouray',
    state: 'CO',
    latitude: '38.058846',
    longitude: '-107.70159',
    timeZoneId: 'America/Denver',
  },
  '81428': {
    zip: '81428',
    city: 'Paonia',
    state: 'CO',
    latitude: '38.8953',
    longitude: '-107.58798',
    timeZoneId: 'America/Denver',
  },
  '81429': {
    zip: '81429',
    city: 'Paradox',
    state: 'CO',
    latitude: '38.365643',
    longitude: '-108.96368',
    timeZoneId: 'America/Denver',
  },
  '81430': {
    zip: '81430',
    city: 'Placerville',
    state: 'CO',
    latitude: '38.040148',
    longitude: '-108.064432',
    timeZoneId: 'America/Denver',
  },
  '81431': {
    zip: '81431',
    city: 'Redvale',
    state: 'CO',
    latitude: '38.189865',
    longitude: '-108.42643',
    timeZoneId: 'America/Denver',
  },
  '81432': {
    zip: '81432',
    city: 'Ridgway',
    state: 'CO',
    latitude: '38.158922',
    longitude: '-107.749219',
    timeZoneId: 'America/Denver',
  },
  '81433': {
    zip: '81433',
    city: 'Silverton',
    state: 'CO',
    latitude: '37.811743',
    longitude: '-107.664195',
    timeZoneId: 'America/Denver',
  },
  '81434': {
    zip: '81434',
    city: 'Somerset',
    state: 'CO',
    latitude: '38.96381',
    longitude: '-107.296606',
    timeZoneId: 'America/Denver',
  },
  '81435': {
    zip: '81435',
    city: 'Telluride',
    state: 'CO',
    latitude: '37.979875',
    longitude: '-107.941486',
    timeZoneId: 'America/Denver',
  },
  '81501': {
    zip: '81501',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.080461',
    longitude: '-108.541256',
    timeZoneId: 'America/Denver',
  },
  '81502': {
    zip: '81502',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.074393',
    longitude: '-108.631686',
    timeZoneId: 'America/Denver',
  },
  '81503': {
    zip: '81503',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.039',
    longitude: '-108.496612',
    timeZoneId: 'America/Denver',
  },
  '81504': {
    zip: '81504',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.083097',
    longitude: '-108.486204',
    timeZoneId: 'America/Denver',
  },
  '81505': {
    zip: '81505',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.107861',
    longitude: '-108.607362',
    timeZoneId: 'America/Denver',
  },
  '81506': {
    zip: '81506',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.105108',
    longitude: '-108.548046',
    timeZoneId: 'America/Denver',
  },
  '81507': {
    zip: '81507',
    city: 'Grand Junction',
    state: 'CO',
    latitude: '39.105236',
    longitude: '-108.548263',
    timeZoneId: 'America/Denver',
  },
  '81520': {
    zip: '81520',
    city: 'Clifton',
    state: 'CO',
    latitude: '39.085313',
    longitude: '-108.445806',
    timeZoneId: 'America/Denver',
  },
  '81521': {
    zip: '81521',
    city: 'Fruita',
    state: 'CO',
    latitude: '39.165497',
    longitude: '-108.720895',
    timeZoneId: 'America/Denver',
  },
  '81522': {
    zip: '81522',
    city: 'Gateway',
    state: 'CO',
    latitude: '38.788558',
    longitude: '-108.7465',
    timeZoneId: 'America/Denver',
  },
  '81523': {
    zip: '81523',
    city: 'Glade Park',
    state: 'CO',
    latitude: '38.997277',
    longitude: '-108.740054',
    timeZoneId: 'America/Denver',
  },
  '81524': {
    zip: '81524',
    city: 'Loma',
    state: 'CO',
    latitude: '39.227527',
    longitude: '-108.800442',
    timeZoneId: 'America/Denver',
  },
  '81525': {
    zip: '81525',
    city: 'Mack',
    state: 'CO',
    latitude: '39.23637',
    longitude: '-108.849029',
    timeZoneId: 'America/Denver',
  },
  '81526': {
    zip: '81526',
    city: 'Palisade',
    state: 'CO',
    latitude: '39.097318',
    longitude: '-108.363606',
    timeZoneId: 'America/Denver',
  },
  '81527': {
    zip: '81527',
    city: 'Whitewater',
    state: 'CO',
    latitude: '38.958952',
    longitude: '-108.364465',
    timeZoneId: 'America/Denver',
  },
  '81601': {
    zip: '81601',
    city: 'Glenwood Springs',
    state: 'CO',
    latitude: '39.528426',
    longitude: '-107.338503',
    timeZoneId: 'America/Denver',
  },
  '81602': {
    zip: '81602',
    city: 'Glenwood Springs',
    state: 'CO',
    latitude: '39.494181',
    longitude: '-107.328253',
    timeZoneId: 'America/Denver',
  },
  '81610': {
    zip: '81610',
    city: 'Dinosaur',
    state: 'CO',
    latitude: '40.334596',
    longitude: '-108.822045',
    timeZoneId: 'America/Denver',
  },
  '81611': {
    zip: '81611',
    city: 'Aspen',
    state: 'CO',
    latitude: '39.194434',
    longitude: '-106.826452',
    timeZoneId: 'America/Denver',
  },
  '81612': {
    zip: '81612',
    city: 'Aspen',
    state: 'CO',
    latitude: '39.207734',
    longitude: '-106.85082',
    timeZoneId: 'America/Denver',
  },
  '81615': {
    zip: '81615',
    city: 'Snowmass Village',
    state: 'CO',
    latitude: '39.209019',
    longitude: '-106.93613',
    timeZoneId: 'America/Denver',
  },
  '81620': {
    zip: '81620',
    city: 'Avon',
    state: 'CO',
    latitude: '39.63132',
    longitude: '-106.5216',
    timeZoneId: 'America/Denver',
  },
  '81621': {
    zip: '81621',
    city: 'Basalt',
    state: 'CO',
    latitude: '39.375819',
    longitude: '-106.992926',
    timeZoneId: 'America/Denver',
  },
  '81623': {
    zip: '81623',
    city: 'Carbondale',
    state: 'CO',
    latitude: '39.416703',
    longitude: '-107.189562',
    timeZoneId: 'America/Denver',
  },
  '81624': {
    zip: '81624',
    city: 'Collbran',
    state: 'CO',
    latitude: '39.237222',
    longitude: '-107.981111',
    timeZoneId: 'America/Denver',
  },
  '81625': {
    zip: '81625',
    city: 'Craig',
    state: 'CO',
    latitude: '40.548784',
    longitude: '-107.564262',
    timeZoneId: 'America/Denver',
  },
  '81626': {
    zip: '81626',
    city: 'Craig',
    state: 'CO',
    latitude: '40.562311',
    longitude: '-107.571271',
    timeZoneId: 'America/Denver',
  },
  '81630': {
    zip: '81630',
    city: 'De Beque',
    state: 'CO',
    latitude: '39.33121',
    longitude: '-108.216061',
    timeZoneId: 'America/Denver',
  },
  '81631': {
    zip: '81631',
    city: 'Eagle',
    state: 'CO',
    latitude: '39.646581',
    longitude: '-106.837237',
    timeZoneId: 'America/Denver',
  },
  '81632': {
    zip: '81632',
    city: 'Edwards',
    state: 'CO',
    latitude: '39.642795',
    longitude: '-106.607621',
    timeZoneId: 'America/Denver',
  },
  '81633': {
    zip: '81633',
    city: 'Dinosaur',
    state: 'CO',
    latitude: '40.314931',
    longitude: '-108.350141',
    timeZoneId: 'America/Denver',
  },
  '81635': {
    zip: '81635',
    city: 'Parachute',
    state: 'CO',
    latitude: '39.460598',
    longitude: '-108.009596',
    timeZoneId: 'America/Denver',
  },
  '81636': {
    zip: '81636',
    city: 'Battlement Mesa',
    state: 'CO',
    latitude: '39.43865',
    longitude: '-108.025498',
    timeZoneId: 'America/Denver',
  },
  '81637': {
    zip: '81637',
    city: 'Gypsum',
    state: 'CO',
    latitude: '39.633376',
    longitude: '-106.954148',
    timeZoneId: 'America/Denver',
  },
  '81638': {
    zip: '81638',
    city: 'Hamilton',
    state: 'CO',
    latitude: '40.331158',
    longitude: '-107.548854',
    timeZoneId: 'America/Denver',
  },
  '81639': {
    zip: '81639',
    city: 'Hayden',
    state: 'CO',
    latitude: '40.485154',
    longitude: '-107.270273',
    timeZoneId: 'America/Denver',
  },
  '81640': {
    zip: '81640',
    city: 'Maybell',
    state: 'CO',
    latitude: '40.530669',
    longitude: '-108.14321',
    timeZoneId: 'America/Denver',
  },
  '81641': {
    zip: '81641',
    city: 'Meeker',
    state: 'CO',
    latitude: '40.045459',
    longitude: '-107.946922',
    timeZoneId: 'America/Denver',
  },
  '81642': {
    zip: '81642',
    city: 'Meredith',
    state: 'CO',
    latitude: '39.343479',
    longitude: '-106.678875',
    timeZoneId: 'America/Denver',
  },
  '81643': {
    zip: '81643',
    city: 'Mesa',
    state: 'CO',
    latitude: '39.147763',
    longitude: '-108.131264',
    timeZoneId: 'America/Denver',
  },
  '81645': {
    zip: '81645',
    city: 'Minturn',
    state: 'CO',
    latitude: '39.570257',
    longitude: '-106.418899',
    timeZoneId: 'America/Denver',
  },
  '81646': {
    zip: '81646',
    city: 'Molina',
    state: 'CO',
    latitude: '39.189509',
    longitude: '-108.061834',
    timeZoneId: 'America/Denver',
  },
  '81647': {
    zip: '81647',
    city: 'New Castle',
    state: 'CO',
    latitude: '39.559479',
    longitude: '-107.545886',
    timeZoneId: 'America/Denver',
  },
  '81648': {
    zip: '81648',
    city: 'Rangely',
    state: 'CO',
    latitude: '40.090824',
    longitude: '-108.80079',
    timeZoneId: 'America/Denver',
  },
  '81649': {
    zip: '81649',
    city: 'Red Cliff',
    state: 'CO',
    latitude: '39.486718',
    longitude: '-106.376152',
    timeZoneId: 'America/Denver',
  },
  '81650': {
    zip: '81650',
    city: 'Rifle',
    state: 'CO',
    latitude: '39.540153',
    longitude: '-107.790633',
    timeZoneId: 'America/Denver',
  },
  '81652': {
    zip: '81652',
    city: 'Silt',
    state: 'CO',
    latitude: '39.506975',
    longitude: '-107.653558',
    timeZoneId: 'America/Denver',
  },
  '81653': {
    zip: '81653',
    city: 'Slater',
    state: 'CO',
    latitude: '40.914059',
    longitude: '-107.505659',
    timeZoneId: 'America/Denver',
  },
  '81654': {
    zip: '81654',
    city: 'Snowmass',
    state: 'CO',
    latitude: '39.286141',
    longitude: '-106.979196',
    timeZoneId: 'America/Denver',
  },
  '81655': {
    zip: '81655',
    city: 'Wolcott',
    state: 'CO',
    latitude: '39.683061',
    longitude: '-106.673851',
    timeZoneId: 'America/Denver',
  },
  '81656': {
    zip: '81656',
    city: 'Woody Creek',
    state: 'CO',
    latitude: '39.269418',
    longitude: '-106.863474',
    timeZoneId: 'America/Denver',
  },
  '81657': {
    zip: '81657',
    city: 'Vail',
    state: 'CO',
    latitude: '39.65022',
    longitude: '-106.36074',
    timeZoneId: 'America/Denver',
  },
  '81658': {
    zip: '81658',
    city: 'Vail',
    state: 'CO',
    latitude: '39.621866',
    longitude: '-106.500236',
    timeZoneId: 'America/Denver',
  },
  '82001': {
    zip: '82001',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.143114',
    longitude: '-104.792918',
    timeZoneId: 'America/Denver',
  },
  '82002': {
    zip: '82002',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.142242',
    longitude: '-104.822841',
    timeZoneId: 'America/Denver',
  },
  '82003': {
    zip: '82003',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.166322',
    longitude: '-104.788243',
    timeZoneId: 'America/Denver',
  },
  '82005': {
    zip: '82005',
    city: 'Fe Warren Afb',
    state: 'WY',
    latitude: '41.13708',
    longitude: '-104.81802',
    timeZoneId: 'America/Denver',
  },
  '82006': {
    zip: '82006',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.404407',
    longitude: '-104.857277',
    timeZoneId: 'America/Denver',
  },
  '82007': {
    zip: '82007',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.111908',
    longitude: '-104.863666',
    timeZoneId: 'America/Denver',
  },
  '82009': {
    zip: '82009',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.207382',
    longitude: '-104.827712',
    timeZoneId: 'America/Denver',
  },
  '82010': {
    zip: '82010',
    city: 'Cheyenne',
    state: 'WY',
    latitude: '41.103913',
    longitude: '-104.783119',
    timeZoneId: 'America/Denver',
  },
  '82050': {
    zip: '82050',
    city: 'Albin',
    state: 'WY',
    latitude: '41.419483',
    longitude: '-104.356295',
    timeZoneId: 'America/Denver',
  },
  '82051': {
    zip: '82051',
    city: 'Bosler',
    state: 'WY',
    latitude: '41.576111',
    longitude: '-105.694722',
    timeZoneId: 'America/Denver',
  },
  '82052': {
    zip: '82052',
    city: 'Buford',
    state: 'WY',
    latitude: '41.111872',
    longitude: '-105.308655',
    timeZoneId: 'America/Denver',
  },
  '82053': {
    zip: '82053',
    city: 'Burns',
    state: 'WY',
    latitude: '41.218272',
    longitude: '-104.362776',
    timeZoneId: 'America/Denver',
  },
  '82054': {
    zip: '82054',
    city: 'Carpenter',
    state: 'WY',
    latitude: '41.060046',
    longitude: '-104.366017',
    timeZoneId: 'America/Denver',
  },
  '82055': {
    zip: '82055',
    city: 'Centennial',
    state: 'WY',
    latitude: '41.302928',
    longitude: '-106.137752',
    timeZoneId: 'America/Denver',
  },
  '82058': {
    zip: '82058',
    city: 'Garrett',
    state: 'WY',
    latitude: '42.196069',
    longitude: '-105.678821',
    timeZoneId: 'America/Denver',
  },
  '82059': {
    zip: '82059',
    city: 'Granite Canon',
    state: 'WY',
    latitude: '41.017795',
    longitude: '-105.249499',
    timeZoneId: 'America/Denver',
  },
  '82060': {
    zip: '82060',
    city: 'Hillsdale',
    state: 'WY',
    latitude: '41.21085',
    longitude: '-104.479294',
    timeZoneId: 'America/Denver',
  },
  '82061': {
    zip: '82061',
    city: 'Horse Creek',
    state: 'WY',
    latitude: '41.395577',
    longitude: '-105.363112',
    timeZoneId: 'America/Denver',
  },
  '82063': {
    zip: '82063',
    city: 'Jelm',
    state: 'WY',
    latitude: '41.066066',
    longitude: '-106.026224',
    timeZoneId: 'America/Denver',
  },
  '82070': {
    zip: '82070',
    city: 'Laramie',
    state: 'WY',
    latitude: '41.297825',
    longitude: '-105.635487',
    timeZoneId: 'America/Denver',
  },
  '82071': {
    zip: '82071',
    city: 'Laramie',
    state: 'WY',
    latitude: '41.422527',
    longitude: '-105.607196',
    timeZoneId: 'America/Denver',
  },
  '82072': {
    zip: '82072',
    city: 'Laramie',
    state: 'WY',
    latitude: '41.06136',
    longitude: '-106.01475',
    timeZoneId: 'America/Denver',
  },
  '82073': {
    zip: '82073',
    city: 'Laramie',
    state: 'WY',
    latitude: '41.311168',
    longitude: '-105.580954',
    timeZoneId: 'America/Denver',
  },
  '82081': {
    zip: '82081',
    city: 'Meriden',
    state: 'WY',
    latitude: '41.562155',
    longitude: '-104.369259',
    timeZoneId: 'America/Denver',
  },
  '82082': {
    zip: '82082',
    city: 'Pine Bluffs',
    state: 'WY',
    latitude: '41.18838',
    longitude: '-104.07648',
    timeZoneId: 'America/Denver',
  },
  '82083': {
    zip: '82083',
    city: 'Rock River',
    state: 'WY',
    latitude: '41.782089',
    longitude: '-105.885219',
    timeZoneId: 'America/Denver',
  },
  '82084': {
    zip: '82084',
    city: 'Tie Siding',
    state: 'WY',
    latitude: '41.053617',
    longitude: '-105.441046',
    timeZoneId: 'America/Denver',
  },
  '82190': {
    zip: '82190',
    city: 'Yellowstone National Park',
    state: 'WY',
    latitude: '44.97515',
    longitude: '-110.69471',
    timeZoneId: 'America/Denver',
  },
  '82201': {
    zip: '82201',
    city: 'Wheatland',
    state: 'WY',
    latitude: '42.031976',
    longitude: '-104.976675',
    timeZoneId: 'America/Denver',
  },
  '82210': {
    zip: '82210',
    city: 'Chugwater',
    state: 'WY',
    latitude: '41.728605',
    longitude: '-104.847774',
    timeZoneId: 'America/Denver',
  },
  '82212': {
    zip: '82212',
    city: 'Fort Laramie',
    state: 'WY',
    latitude: '42.212319',
    longitude: '-104.514555',
    timeZoneId: 'America/Denver',
  },
  '82213': {
    zip: '82213',
    city: 'Glendo',
    state: 'WY',
    latitude: '42.498239',
    longitude: '-104.895783',
    timeZoneId: 'America/Denver',
  },
  '82214': {
    zip: '82214',
    city: 'Guernsey',
    state: 'WY',
    latitude: '42.272833',
    longitude: '-104.766893',
    timeZoneId: 'America/Denver',
  },
  '82215': {
    zip: '82215',
    city: 'Hartville',
    state: 'WY',
    latitude: '42.3261',
    longitude: '-104.682',
    timeZoneId: 'America/Denver',
  },
  '82217': {
    zip: '82217',
    city: 'Hawk Springs',
    state: 'WY',
    latitude: '41.730644',
    longitude: '-104.361015',
    timeZoneId: 'America/Denver',
  },
  '82218': {
    zip: '82218',
    city: 'Huntley',
    state: 'WY',
    latitude: '41.931563',
    longitude: '-104.145205',
    timeZoneId: 'America/Denver',
  },
  '82219': {
    zip: '82219',
    city: 'Jay Em',
    state: 'WY',
    latitude: '42.384406',
    longitude: '-104.332696',
    timeZoneId: 'America/Denver',
  },
  '82221': {
    zip: '82221',
    city: 'Lagrange',
    state: 'WY',
    latitude: '41.633566',
    longitude: '-104.354319',
    timeZoneId: 'America/Denver',
  },
  '82222': {
    zip: '82222',
    city: 'Lance Creek',
    state: 'WY',
    latitude: '43.179868',
    longitude: '-104.600423',
    timeZoneId: 'America/Denver',
  },
  '82223': {
    zip: '82223',
    city: 'Lingle',
    state: 'WY',
    latitude: '42.00232',
    longitude: '-104.57623',
    timeZoneId: 'America/Denver',
  },
  '82224': {
    zip: '82224',
    city: 'Lost Springs',
    state: 'WY',
    latitude: '42.76551',
    longitude: '-104.9251',
    timeZoneId: 'America/Denver',
  },
  '82225': {
    zip: '82225',
    city: 'Lusk',
    state: 'WY',
    latitude: '42.803734',
    longitude: '-104.337239',
    timeZoneId: 'America/Denver',
  },
  '82227': {
    zip: '82227',
    city: 'Manville',
    state: 'WY',
    latitude: '42.778823',
    longitude: '-104.616179',
    timeZoneId: 'America/Denver',
  },
  '82229': {
    zip: '82229',
    city: 'Shawnee',
    state: 'WY',
    latitude: '42.674994',
    longitude: '-105.023349',
    timeZoneId: 'America/Denver',
  },
  '82240': {
    zip: '82240',
    city: 'Torrington',
    state: 'WY',
    latitude: '42.065743',
    longitude: '-104.183152',
    timeZoneId: 'America/Denver',
  },
  '82242': {
    zip: '82242',
    city: 'Van Tassell',
    state: 'WY',
    latitude: '42.694487',
    longitude: '-104.114695',
    timeZoneId: 'America/Denver',
  },
  '82243': {
    zip: '82243',
    city: 'Veteran',
    state: 'WY',
    latitude: '41.971969',
    longitude: '-104.364572',
    timeZoneId: 'America/Denver',
  },
  '82244': {
    zip: '82244',
    city: 'Yoder',
    state: 'WY',
    latitude: '41.958631',
    longitude: '-104.46234',
    timeZoneId: 'America/Denver',
  },
  '82301': {
    zip: '82301',
    city: 'Rawlins',
    state: 'WY',
    latitude: '41.78887',
    longitude: '-107.199009',
    timeZoneId: 'America/Denver',
  },
  '82310': {
    zip: '82310',
    city: 'Jeffrey City',
    state: 'WY',
    latitude: '42.512682',
    longitude: '-107.892119',
    timeZoneId: 'America/Denver',
  },
  '82321': {
    zip: '82321',
    city: 'Baggs',
    state: 'WY',
    latitude: '41.032392',
    longitude: '-107.608311',
    timeZoneId: 'America/Denver',
  },
  '82322': {
    zip: '82322',
    city: 'Bairoil',
    state: 'WY',
    latitude: '42.23841',
    longitude: '-107.560162',
    timeZoneId: 'America/Denver',
  },
  '82323': {
    zip: '82323',
    city: 'Dixon',
    state: 'WY',
    latitude: '41.091246',
    longitude: '-107.534368',
    timeZoneId: 'America/Denver',
  },
  '82324': {
    zip: '82324',
    city: 'Elk Mountain',
    state: 'WY',
    latitude: '41.690182',
    longitude: '-106.414493',
    timeZoneId: 'America/Denver',
  },
  '82325': {
    zip: '82325',
    city: 'Encampment',
    state: 'WY',
    latitude: '41.2175',
    longitude: '-106.778333',
    timeZoneId: 'America/Denver',
  },
  '82327': {
    zip: '82327',
    city: 'Hanna',
    state: 'WY',
    latitude: '41.878504',
    longitude: '-106.556303',
    timeZoneId: 'America/Denver',
  },
  '82329': {
    zip: '82329',
    city: 'Medicine Bow',
    state: 'WY',
    latitude: '41.899425',
    longitude: '-106.20324',
    timeZoneId: 'America/Denver',
  },
  '82331': {
    zip: '82331',
    city: 'Saratoga',
    state: 'WY',
    latitude: '41.32134',
    longitude: '-106.50271',
    timeZoneId: 'America/Denver',
  },
  '82332': {
    zip: '82332',
    city: 'Savery',
    state: 'WY',
    latitude: '41.080988',
    longitude: '-107.348315',
    timeZoneId: 'America/Denver',
  },
  '82334': {
    zip: '82334',
    city: 'Sinclair',
    state: 'WY',
    latitude: '41.777516',
    longitude: '-107.115829',
    timeZoneId: 'America/Denver',
  },
  '82335': {
    zip: '82335',
    city: 'Walcott',
    state: 'WY',
    latitude: '41.763315',
    longitude: '-106.845197',
    timeZoneId: 'America/Denver',
  },
  '82336': {
    zip: '82336',
    city: 'Wamsutter',
    state: 'WY',
    latitude: '41.62957',
    longitude: '-108.26482',
    timeZoneId: 'America/Denver',
  },
  '82401': {
    zip: '82401',
    city: 'Worland',
    state: 'WY',
    latitude: '44.026278',
    longitude: '-107.95507',
    timeZoneId: 'America/Denver',
  },
  '82410': {
    zip: '82410',
    city: 'Basin',
    state: 'WY',
    latitude: '44.378527',
    longitude: '-108.035395',
    timeZoneId: 'America/Denver',
  },
  '82411': {
    zip: '82411',
    city: 'Burlington',
    state: 'WY',
    latitude: '44.433274',
    longitude: '-108.429369',
    timeZoneId: 'America/Denver',
  },
  '82412': {
    zip: '82412',
    city: 'Byron',
    state: 'WY',
    latitude: '44.795553',
    longitude: '-108.507741',
    timeZoneId: 'America/Denver',
  },
  '82414': {
    zip: '82414',
    city: 'Cody',
    state: 'WY',
    latitude: '44.511852',
    longitude: '-109.136009',
    timeZoneId: 'America/Denver',
  },
  '82420': {
    zip: '82420',
    city: 'Cowley',
    state: 'WY',
    latitude: '44.880677',
    longitude: '-108.468859',
    timeZoneId: 'America/Denver',
  },
  '82421': {
    zip: '82421',
    city: 'Deaver',
    state: 'WY',
    latitude: '44.893147',
    longitude: '-108.591631',
    timeZoneId: 'America/Denver',
  },
  '82422': {
    zip: '82422',
    city: 'Emblem',
    state: 'WY',
    latitude: '44.504822',
    longitude: '-108.389275',
    timeZoneId: 'America/Denver',
  },
  '82423': {
    zip: '82423',
    city: 'Frannie',
    state: 'WY',
    latitude: '44.969383',
    longitude: '-108.619848',
    timeZoneId: 'America/Denver',
  },
  '82426': {
    zip: '82426',
    city: 'Greybull',
    state: 'WY',
    latitude: '44.491759',
    longitude: '-108.042418',
    timeZoneId: 'America/Denver',
  },
  '82428': {
    zip: '82428',
    city: 'Hyattville',
    state: 'WY',
    latitude: '44.274515',
    longitude: '-107.588416',
    timeZoneId: 'America/Denver',
  },
  '82430': {
    zip: '82430',
    city: 'Kirby',
    state: 'WY',
    latitude: '43.804158',
    longitude: '-108.174567',
    timeZoneId: 'America/Denver',
  },
  '82431': {
    zip: '82431',
    city: 'Lovell',
    state: 'WY',
    latitude: '44.799584',
    longitude: '-108.346495',
    timeZoneId: 'America/Denver',
  },
  '82432': {
    zip: '82432',
    city: 'Manderson',
    state: 'WY',
    latitude: '44.27333',
    longitude: '-107.946537',
    timeZoneId: 'America/Denver',
  },
  '82433': {
    zip: '82433',
    city: 'Meeteetse',
    state: 'WY',
    latitude: '44.2148',
    longitude: '-108.828859',
    timeZoneId: 'America/Denver',
  },
  '82434': {
    zip: '82434',
    city: 'Otto',
    state: 'WY',
    latitude: '44.409748',
    longitude: '-108.289207',
    timeZoneId: 'America/Denver',
  },
  '82435': {
    zip: '82435',
    city: 'Powell',
    state: 'WY',
    latitude: '44.755778',
    longitude: '-108.760087',
    timeZoneId: 'America/Denver',
  },
  '82440': {
    zip: '82440',
    city: 'Ralston',
    state: 'WY',
    latitude: '44.716065',
    longitude: '-108.866082',
    timeZoneId: 'America/Denver',
  },
  '82441': {
    zip: '82441',
    city: 'Shell',
    state: 'WY',
    latitude: '44.613962',
    longitude: '-107.811554',
    timeZoneId: 'America/Denver',
  },
  '82442': {
    zip: '82442',
    city: 'Ten Sleep',
    state: 'WY',
    latitude: '44.034301',
    longitude: '-107.445849',
    timeZoneId: 'America/Denver',
  },
  '82443': {
    zip: '82443',
    city: 'Thermopolis',
    state: 'WY',
    latitude: '43.77251',
    longitude: '-108.57515',
    timeZoneId: 'America/Denver',
  },
  '82450': {
    zip: '82450',
    city: 'Wapiti',
    state: 'WY',
    latitude: '44.480556',
    longitude: '-109.72447',
    timeZoneId: 'America/Denver',
  },
  '82501': {
    zip: '82501',
    city: 'Riverton',
    state: 'WY',
    latitude: '42.999319',
    longitude: '-108.386674',
    timeZoneId: 'America/Denver',
  },
  '82510': {
    zip: '82510',
    city: 'Arapahoe',
    state: 'WY',
    latitude: '42.989002',
    longitude: '-108.542725',
    timeZoneId: 'America/Denver',
  },
  '82512': {
    zip: '82512',
    city: 'Crowheart',
    state: 'WY',
    latitude: '43.379445',
    longitude: '-109.188086',
    timeZoneId: 'America/Denver',
  },
  '82513': {
    zip: '82513',
    city: 'Dubois',
    state: 'WY',
    latitude: '43.544616',
    longitude: '-109.663371',
    timeZoneId: 'America/Denver',
  },
  '82514': {
    zip: '82514',
    city: 'Fort Washakie',
    state: 'WY',
    latitude: '42.999395',
    longitude: '-108.88077',
    timeZoneId: 'America/Denver',
  },
  '82515': {
    zip: '82515',
    city: 'Hudson',
    state: 'WY',
    latitude: '42.912358',
    longitude: '-108.56597',
    timeZoneId: 'America/Denver',
  },
  '82516': {
    zip: '82516',
    city: 'Kinnear',
    state: 'WY',
    latitude: '43.238224',
    longitude: '-108.81585',
    timeZoneId: 'America/Denver',
  },
  '82520': {
    zip: '82520',
    city: 'Lander',
    state: 'WY',
    latitude: '42.841536',
    longitude: '-108.726957',
    timeZoneId: 'America/Denver',
  },
  '82523': {
    zip: '82523',
    city: 'Pavillion',
    state: 'WY',
    latitude: '43.255946',
    longitude: '-108.65079',
    timeZoneId: 'America/Denver',
  },
  '82524': {
    zip: '82524',
    city: 'Saint Stephens',
    state: 'WY',
    latitude: '42.98609',
    longitude: '-108.418042',
    timeZoneId: 'America/Denver',
  },
  '82601': {
    zip: '82601',
    city: 'Casper',
    state: 'WY',
    latitude: '42.840004',
    longitude: '-106.32161',
    timeZoneId: 'America/Denver',
  },
  '82602': {
    zip: '82602',
    city: 'Casper',
    state: 'WY',
    latitude: '42.844076',
    longitude: '-106.336675',
    timeZoneId: 'America/Denver',
  },
  '82604': {
    zip: '82604',
    city: 'Casper',
    state: 'WY',
    latitude: '42.826825',
    longitude: '-106.381639',
    timeZoneId: 'America/Denver',
  },
  '82605': {
    zip: '82605',
    city: 'Casper',
    state: 'WY',
    latitude: '42.868032',
    longitude: '-106.312773',
    timeZoneId: 'America/Denver',
  },
  '82609': {
    zip: '82609',
    city: 'Casper',
    state: 'WY',
    latitude: '42.84645',
    longitude: '-106.274603',
    timeZoneId: 'America/Denver',
  },
  '82615': {
    zip: '82615',
    city: 'Shirley Basin',
    state: 'WY',
    latitude: '42.031',
    longitude: '-106.545746',
    timeZoneId: 'America/Denver',
  },
  '82620': {
    zip: '82620',
    city: 'Alcova',
    state: 'WY',
    latitude: '42.526185',
    longitude: '-106.774157',
    timeZoneId: 'America/Denver',
  },
  '82630': {
    zip: '82630',
    city: 'Arminto',
    state: 'WY',
    latitude: '43.1217',
    longitude: '-107.371907',
    timeZoneId: 'America/Denver',
  },
  '82633': {
    zip: '82633',
    city: 'Douglas',
    state: 'WY',
    latitude: '42.65256',
    longitude: '-105.18991',
    timeZoneId: 'America/Denver',
  },
  '82635': {
    zip: '82635',
    city: 'Edgerton',
    state: 'WY',
    latitude: '43.410816',
    longitude: '-106.254934',
    timeZoneId: 'America/Denver',
  },
  '82636': {
    zip: '82636',
    city: 'Evansville',
    state: 'WY',
    latitude: '42.858536',
    longitude: '-106.200733',
    timeZoneId: 'America/Denver',
  },
  '82637': {
    zip: '82637',
    city: 'Glenrock',
    state: 'WY',
    latitude: '42.831726',
    longitude: '-105.843281',
    timeZoneId: 'America/Denver',
  },
  '82638': {
    zip: '82638',
    city: 'Hiland',
    state: 'WY',
    latitude: '43.161514',
    longitude: '-107.722759',
    timeZoneId: 'America/Denver',
  },
  '82639': {
    zip: '82639',
    city: 'Kaycee',
    state: 'WY',
    latitude: '43.748572',
    longitude: '-106.551114',
    timeZoneId: 'America/Denver',
  },
  '82640': {
    zip: '82640',
    city: 'Linch',
    state: 'WY',
    latitude: '43.603763',
    longitude: '-106.191855',
    timeZoneId: 'America/Denver',
  },
  '82642': {
    zip: '82642',
    city: 'Lysite',
    state: 'WY',
    latitude: '43.300855',
    longitude: '-107.638296',
    timeZoneId: 'America/Denver',
  },
  '82643': {
    zip: '82643',
    city: 'Midwest',
    state: 'WY',
    latitude: '43.409443',
    longitude: '-106.276916',
    timeZoneId: 'America/Denver',
  },
  '82644': {
    zip: '82644',
    city: 'Mills',
    state: 'WY',
    latitude: '42.866408',
    longitude: '-106.410162',
    timeZoneId: 'America/Denver',
  },
  '82646': {
    zip: '82646',
    city: 'Natrona',
    state: 'WY',
    latitude: '43.029483',
    longitude: '-106.809656',
    timeZoneId: 'America/Denver',
  },
  '82648': {
    zip: '82648',
    city: 'Powder River',
    state: 'WY',
    latitude: '43.113054',
    longitude: '-107.014093',
    timeZoneId: 'America/Denver',
  },
  '82649': {
    zip: '82649',
    city: 'Shoshoni',
    state: 'WY',
    latitude: '43.215469',
    longitude: '-108.206055',
    timeZoneId: 'America/Denver',
  },
  '82701': {
    zip: '82701',
    city: 'Newcastle',
    state: 'WY',
    latitude: '43.834953',
    longitude: '-104.292024',
    timeZoneId: 'America/Denver',
  },
  '82710': {
    zip: '82710',
    city: 'Aladdin',
    state: 'WY',
    latitude: '44.823233',
    longitude: '-104.206686',
    timeZoneId: 'America/Denver',
  },
  '82711': {
    zip: '82711',
    city: 'Alva',
    state: 'WY',
    latitude: '44.695788',
    longitude: '-104.438573',
    timeZoneId: 'America/Denver',
  },
  '82712': {
    zip: '82712',
    city: 'Beulah',
    state: 'WY',
    latitude: '44.55936',
    longitude: '-104.135611',
    timeZoneId: 'America/Denver',
  },
  '82714': {
    zip: '82714',
    city: 'Devils Tower',
    state: 'WY',
    latitude: '44.642126',
    longitude: '-104.845991',
    timeZoneId: 'America/Denver',
  },
  '82715': {
    zip: '82715',
    city: 'Four Corners',
    state: 'WY',
    latitude: '44.123796',
    longitude: '-104.1184',
    timeZoneId: 'America/Denver',
  },
  '82716': {
    zip: '82716',
    city: 'Gillette',
    state: 'WY',
    latitude: '44.317614',
    longitude: '-105.505943',
    timeZoneId: 'America/Denver',
  },
  '82717': {
    zip: '82717',
    city: 'Gillette',
    state: 'WY',
    latitude: '44.257303',
    longitude: '-105.385939',
    timeZoneId: 'America/Denver',
  },
  '82718': {
    zip: '82718',
    city: 'Gillette',
    state: 'WY',
    latitude: '44.266688',
    longitude: '-105.525118',
    timeZoneId: 'America/Denver',
  },
  '82720': {
    zip: '82720',
    city: 'Hulett',
    state: 'WY',
    latitude: '44.694848',
    longitude: '-104.748765',
    timeZoneId: 'America/Denver',
  },
  '82721': {
    zip: '82721',
    city: 'Moorcroft',
    state: 'WY',
    latitude: '44.58808',
    longitude: '-104.93792',
    timeZoneId: 'America/Denver',
  },
  '82723': {
    zip: '82723',
    city: 'Osage',
    state: 'WY',
    latitude: '43.986823',
    longitude: '-104.417332',
    timeZoneId: 'America/Denver',
  },
  '82725': {
    zip: '82725',
    city: 'Recluse',
    state: 'WY',
    latitude: '44.899551',
    longitude: '-105.67044',
    timeZoneId: 'America/Denver',
  },
  '82727': {
    zip: '82727',
    city: 'Rozet',
    state: 'WY',
    latitude: '44.2481',
    longitude: '-105.25265',
    timeZoneId: 'America/Denver',
  },
  '82729': {
    zip: '82729',
    city: 'Sundance',
    state: 'WY',
    latitude: '44.400249',
    longitude: '-104.372054',
    timeZoneId: 'America/Denver',
  },
  '82730': {
    zip: '82730',
    city: 'Upton',
    state: 'WY',
    latitude: '44.076742',
    longitude: '-104.695617',
    timeZoneId: 'America/Denver',
  },
  '82731': {
    zip: '82731',
    city: 'Weston',
    state: 'WY',
    latitude: '44.75681',
    longitude: '-105.296341',
    timeZoneId: 'America/Denver',
  },
  '82732': {
    zip: '82732',
    city: 'Wright',
    state: 'WY',
    latitude: '44.291111',
    longitude: '-105.501667',
    timeZoneId: 'America/Denver',
  },
  '82801': {
    zip: '82801',
    city: 'Sheridan',
    state: 'WY',
    latitude: '44.786539',
    longitude: '-106.96808',
    timeZoneId: 'America/Denver',
  },
  '82831': {
    zip: '82831',
    city: 'Arvada',
    state: 'WY',
    latitude: '44.705531',
    longitude: '-106.101313',
    timeZoneId: 'America/Denver',
  },
  '82832': {
    zip: '82832',
    city: 'Banner',
    state: 'WY',
    latitude: '44.645411',
    longitude: '-106.798869',
    timeZoneId: 'America/Denver',
  },
  '82833': {
    zip: '82833',
    city: 'Big Horn',
    state: 'WY',
    latitude: '44.666155',
    longitude: '-107.005303',
    timeZoneId: 'America/Denver',
  },
  '82834': {
    zip: '82834',
    city: 'Buffalo',
    state: 'WY',
    latitude: '44.343046',
    longitude: '-106.711767',
    timeZoneId: 'America/Denver',
  },
  '82835': {
    zip: '82835',
    city: 'Clearmont',
    state: 'WY',
    latitude: '44.672925',
    longitude: '-106.421526',
    timeZoneId: 'America/Denver',
  },
  '82836': {
    zip: '82836',
    city: 'Dayton',
    state: 'WY',
    latitude: '44.878388',
    longitude: '-107.258812',
    timeZoneId: 'America/Denver',
  },
  '82837': {
    zip: '82837',
    city: 'Leiter',
    state: 'WY',
    latitude: '44.748625',
    longitude: '-106.215831',
    timeZoneId: 'America/Denver',
  },
  '82838': {
    zip: '82838',
    city: 'Parkman',
    state: 'WY',
    latitude: '44.969359',
    longitude: '-107.316636',
    timeZoneId: 'America/Denver',
  },
  '82839': {
    zip: '82839',
    city: 'Ranchester',
    state: 'WY',
    latitude: '44.903574',
    longitude: '-107.143746',
    timeZoneId: 'America/Denver',
  },
  '82840': {
    zip: '82840',
    city: 'Saddlestring',
    state: 'WY',
    latitude: '44.34404',
    longitude: '-106.620111',
    timeZoneId: 'America/Denver',
  },
  '82842': {
    zip: '82842',
    city: 'Story',
    state: 'WY',
    latitude: '44.578395',
    longitude: '-106.899253',
    timeZoneId: 'America/Denver',
  },
  '82844': {
    zip: '82844',
    city: 'Wolf',
    state: 'WY',
    latitude: '44.704782',
    longitude: '-107.303285',
    timeZoneId: 'America/Denver',
  },
  '82845': {
    zip: '82845',
    city: 'Wyarno',
    state: 'WY',
    latitude: '44.806855',
    longitude: '-106.773099',
    timeZoneId: 'America/Denver',
  },
  '82901': {
    zip: '82901',
    city: 'Rock Springs',
    state: 'WY',
    latitude: '41.59755',
    longitude: '-109.234183',
    timeZoneId: 'America/Denver',
  },
  '82902': {
    zip: '82902',
    city: 'Rock Springs',
    state: 'WY',
    latitude: '41.589546',
    longitude: '-109.202827',
    timeZoneId: 'America/Denver',
  },
  '82922': {
    zip: '82922',
    city: 'Bondurant',
    state: 'WY',
    latitude: '43.160717',
    longitude: '-110.344275',
    timeZoneId: 'America/Denver',
  },
  '82923': {
    zip: '82923',
    city: 'Boulder',
    state: 'WY',
    latitude: '42.730018',
    longitude: '-109.684887',
    timeZoneId: 'America/Denver',
  },
  '82925': {
    zip: '82925',
    city: 'Cora',
    state: 'WY',
    latitude: '43.112794',
    longitude: '-109.988839',
    timeZoneId: 'America/Denver',
  },
  '82929': {
    zip: '82929',
    city: 'Little America',
    state: 'WY',
    latitude: '41.542836',
    longitude: '-109.857942',
    timeZoneId: 'America/Denver',
  },
  '82930': {
    zip: '82930',
    city: 'Evanston',
    state: 'WY',
    latitude: '41.249255',
    longitude: '-110.959297',
    timeZoneId: 'America/Denver',
  },
  '82931': {
    zip: '82931',
    city: 'Evanston',
    state: 'WY',
    latitude: '41.288857',
    longitude: '-110.966642',
    timeZoneId: 'America/Denver',
  },
  '82932': {
    zip: '82932',
    city: 'Farson',
    state: 'WY',
    latitude: '42.11',
    longitude: '-109.44',
    timeZoneId: 'America/Denver',
  },
  '82933': {
    zip: '82933',
    city: 'Fort Bridger',
    state: 'WY',
    latitude: '41.299522',
    longitude: '-110.39008',
    timeZoneId: 'America/Denver',
  },
  '82934': {
    zip: '82934',
    city: 'Granger',
    state: 'WY',
    latitude: '41.595416',
    longitude: '-109.965628',
    timeZoneId: 'America/Denver',
  },
  '82935': {
    zip: '82935',
    city: 'Green River',
    state: 'WY',
    latitude: '41.512073',
    longitude: '-109.465736',
    timeZoneId: 'America/Denver',
  },
  '82936': {
    zip: '82936',
    city: 'Lonetree',
    state: 'WY',
    latitude: '41.02828',
    longitude: '-110.137088',
    timeZoneId: 'America/Denver',
  },
  '82937': {
    zip: '82937',
    city: 'Lyman',
    state: 'WY',
    latitude: '41.317669',
    longitude: '-110.299141',
    timeZoneId: 'America/Denver',
  },
  '82938': {
    zip: '82938',
    city: 'Mc Kinnon',
    state: 'WY',
    latitude: '41.027778',
    longitude: '-109.935278',
    timeZoneId: 'America/Denver',
  },
  '82939': {
    zip: '82939',
    city: 'Mountain View',
    state: 'WY',
    latitude: '41.217362',
    longitude: '-110.368583',
    timeZoneId: 'America/Denver',
  },
  '82941': {
    zip: '82941',
    city: 'Pinedale',
    state: 'WY',
    latitude: '42.866522',
    longitude: '-109.870027',
    timeZoneId: 'America/Denver',
  },
  '82942': {
    zip: '82942',
    city: 'Point of Rocks',
    state: 'WY',
    latitude: '41.680884',
    longitude: '-108.784929',
    timeZoneId: 'America/Denver',
  },
  '82943': {
    zip: '82943',
    city: 'Reliance',
    state: 'WY',
    latitude: '41.668898',
    longitude: '-109.193833',
    timeZoneId: 'America/Denver',
  },
  '82944': {
    zip: '82944',
    city: 'Robertson',
    state: 'WY',
    latitude: '41.088076',
    longitude: '-110.560957',
    timeZoneId: 'America/Denver',
  },
  '82945': {
    zip: '82945',
    city: 'Superior',
    state: 'WY',
    latitude: '41.765269',
    longitude: '-108.966414',
    timeZoneId: 'America/Denver',
  },
  '83001': {
    zip: '83001',
    city: 'Jackson',
    state: 'WY',
    latitude: '43.472028',
    longitude: '-110.76616',
    timeZoneId: 'America/Denver',
  },
  '83002': {
    zip: '83002',
    city: 'Jackson',
    state: 'WY',
    latitude: '43.472865',
    longitude: '-110.763529',
    timeZoneId: 'America/Denver',
  },
  '83011': {
    zip: '83011',
    city: 'Kelly',
    state: 'WY',
    latitude: '43.605445',
    longitude: '-110.329035',
    timeZoneId: 'America/Denver',
  },
  '83012': {
    zip: '83012',
    city: 'Moose',
    state: 'WY',
    latitude: '43.602951',
    longitude: '-110.740568',
    timeZoneId: 'America/Denver',
  },
  '83013': {
    zip: '83013',
    city: 'Moran',
    state: 'WY',
    latitude: '43.940831',
    longitude: '-110.310607',
    timeZoneId: 'America/Denver',
  },
  '83014': {
    zip: '83014',
    city: 'Wilson',
    state: 'WY',
    latitude: '43.476928',
    longitude: '-110.856179',
    timeZoneId: 'America/Denver',
  },
  '83025': {
    zip: '83025',
    city: 'Teton Village',
    state: 'WY',
    latitude: '43.58802',
    longitude: '-110.8272',
    timeZoneId: 'America/Denver',
  },
  '83101': {
    zip: '83101',
    city: 'Kemmerer',
    state: 'WY',
    latitude: '41.796146',
    longitude: '-110.53994',
    timeZoneId: 'America/Denver',
  },
  '83110': {
    zip: '83110',
    city: 'Afton',
    state: 'WY',
    latitude: '42.720239',
    longitude: '-110.943627',
    timeZoneId: 'America/Denver',
  },
  '83111': {
    zip: '83111',
    city: 'Auburn',
    state: 'WY',
    latitude: '42.791902',
    longitude: '-110.962688',
    timeZoneId: 'America/Denver',
  },
  '83112': {
    zip: '83112',
    city: 'Bedford',
    state: 'WY',
    latitude: '42.9',
    longitude: '-110.933333',
    timeZoneId: 'America/Denver',
  },
  '83113': {
    zip: '83113',
    city: 'Big Piney',
    state: 'WY',
    latitude: '42.5571',
    longitude: '-110.11344',
    timeZoneId: 'America/Denver',
  },
  '83114': {
    zip: '83114',
    city: 'Cokeville',
    state: 'WY',
    latitude: '42.083358',
    longitude: '-110.96557',
    timeZoneId: 'America/Denver',
  },
  '83115': {
    zip: '83115',
    city: 'Daniel',
    state: 'WY',
    latitude: '42.89468',
    longitude: '-110.178746',
    timeZoneId: 'America/Denver',
  },
  '83116': {
    zip: '83116',
    city: 'Diamondville',
    state: 'WY',
    latitude: '41.778944',
    longitude: '-110.537592',
    timeZoneId: 'America/Denver',
  },
  '83118': {
    zip: '83118',
    city: 'Etna',
    state: 'WY',
    latitude: '43.052486',
    longitude: '-111.00986',
    timeZoneId: 'America/Denver',
  },
  '83119': {
    zip: '83119',
    city: 'Fairview',
    state: 'WY',
    latitude: '42.680197',
    longitude: '-111.001229',
    timeZoneId: 'America/Denver',
  },
  '83120': {
    zip: '83120',
    city: 'Freedom',
    state: 'WY',
    latitude: '43.044737',
    longitude: '-111.029966',
    timeZoneId: 'America/Denver',
  },
  '83121': {
    zip: '83121',
    city: 'Frontier',
    state: 'WY',
    latitude: '41.812622',
    longitude: '-110.536977',
    timeZoneId: 'America/Denver',
  },
  '83122': {
    zip: '83122',
    city: 'Grover',
    state: 'WY',
    latitude: '42.800116',
    longitude: '-110.939832',
    timeZoneId: 'America/Denver',
  },
  '83123': {
    zip: '83123',
    city: 'La Barge',
    state: 'WY',
    latitude: '42.257113',
    longitude: '-110.205952',
    timeZoneId: 'America/Denver',
  },
  '83124': {
    zip: '83124',
    city: 'Opal',
    state: 'WY',
    latitude: '41.769911',
    longitude: '-110.321322',
    timeZoneId: 'America/Denver',
  },
  '83126': {
    zip: '83126',
    city: 'Smoot',
    state: 'WY',
    latitude: '42.609653',
    longitude: '-110.908814',
    timeZoneId: 'America/Denver',
  },
  '83127': {
    zip: '83127',
    city: 'Thayne',
    state: 'WY',
    latitude: '42.97289',
    longitude: '-110.971354',
    timeZoneId: 'America/Denver',
  },
  '83128': {
    zip: '83128',
    city: 'Alpine',
    state: 'WY',
    latitude: '43.121071',
    longitude: '-111.022495',
    timeZoneId: 'America/Denver',
  },
  '83201': {
    zip: '83201',
    city: 'Pocatello',
    state: 'ID',
    latitude: '42.882088',
    longitude: '-112.423285',
    timeZoneId: 'America/Denver',
  },
  '83202': {
    zip: '83202',
    city: 'Pocatello',
    state: 'ID',
    latitude: '42.93726',
    longitude: '-112.48854',
    timeZoneId: 'America/Denver',
  },
  '83203': {
    zip: '83203',
    city: 'Fort Hall',
    state: 'ID',
    latitude: '42.990305',
    longitude: '-112.424758',
    timeZoneId: 'America/Denver',
  },
  '83204': {
    zip: '83204',
    city: 'Pocatello',
    state: 'ID',
    latitude: '42.838617',
    longitude: '-112.426137',
    timeZoneId: 'America/Denver',
  },
  '83205': {
    zip: '83205',
    city: 'Pocatello',
    state: 'ID',
    latitude: '42.843492',
    longitude: '-112.466169',
    timeZoneId: 'America/Denver',
  },
  '83206': {
    zip: '83206',
    city: 'Pocatello',
    state: 'ID',
    latitude: '42.86382',
    longitude: '-112.453085',
    timeZoneId: 'America/Denver',
  },
  '83209': {
    zip: '83209',
    city: 'Pocatello',
    state: 'ID',
    latitude: '42.87564',
    longitude: '-112.47088',
    timeZoneId: 'America/Denver',
  },
  '83210': {
    zip: '83210',
    city: 'Aberdeen',
    state: 'ID',
    latitude: '43.0125',
    longitude: '-112.82922',
    timeZoneId: 'America/Denver',
  },
  '83211': {
    zip: '83211',
    city: 'American Falls',
    state: 'ID',
    latitude: '42.75204',
    longitude: '-112.96464',
    timeZoneId: 'America/Denver',
  },
  '83212': {
    zip: '83212',
    city: 'Arbon',
    state: 'ID',
    latitude: '42.550969',
    longitude: '-112.520939',
    timeZoneId: 'America/Denver',
  },
  '83213': {
    zip: '83213',
    city: 'Arco',
    state: 'ID',
    latitude: '43.636568',
    longitude: '-113.293312',
    timeZoneId: 'America/Denver',
  },
  '83214': {
    zip: '83214',
    city: 'Arimo',
    state: 'ID',
    latitude: '42.554001',
    longitude: '-112.229068',
    timeZoneId: 'America/Denver',
  },
  '83215': {
    zip: '83215',
    city: 'Atomic City',
    state: 'ID',
    latitude: '43.444204',
    longitude: '-112.811672',
    timeZoneId: 'America/Denver',
  },
  '83217': {
    zip: '83217',
    city: 'Bancroft',
    state: 'ID',
    latitude: '42.742325',
    longitude: '-111.887397',
    timeZoneId: 'America/Denver',
  },
  '83218': {
    zip: '83218',
    city: 'Basalt',
    state: 'ID',
    latitude: '43.313907',
    longitude: '-112.164559',
    timeZoneId: 'America/Denver',
  },
  '83220': {
    zip: '83220',
    city: 'Bern',
    state: 'ID',
    latitude: '42.32808',
    longitude: '-111.38748',
    timeZoneId: 'America/Denver',
  },
  '83221': {
    zip: '83221',
    city: 'Blackfoot',
    state: 'ID',
    latitude: '43.32624',
    longitude: '-112.41132',
    timeZoneId: 'America/Denver',
  },
  '83223': {
    zip: '83223',
    city: 'Bloomington',
    state: 'ID',
    latitude: '42.187392',
    longitude: '-111.401138',
    timeZoneId: 'America/Denver',
  },
  '83226': {
    zip: '83226',
    city: 'Challis',
    state: 'ID',
    latitude: '44.502833',
    longitude: '-114.224223',
    timeZoneId: 'America/Denver',
  },
  '83227': {
    zip: '83227',
    city: 'Clayton',
    state: 'ID',
    latitude: '44.298351',
    longitude: '-114.40918',
    timeZoneId: 'America/Denver',
  },
  '83228': {
    zip: '83228',
    city: 'Clifton',
    state: 'ID',
    latitude: '42.23215',
    longitude: '-112.012326',
    timeZoneId: 'America/Denver',
  },
  '83229': {
    zip: '83229',
    city: 'Cobalt',
    state: 'ID',
    latitude: '45.111576',
    longitude: '-114.436081',
    timeZoneId: 'America/Denver',
  },
  '83230': {
    zip: '83230',
    city: 'Conda',
    state: 'ID',
    latitude: '42.73272',
    longitude: '-111.53352',
    timeZoneId: 'America/Denver',
  },
  '83232': {
    zip: '83232',
    city: 'Dayton',
    state: 'ID',
    latitude: '42.125313',
    longitude: '-112.013069',
    timeZoneId: 'America/Denver',
  },
  '83233': {
    zip: '83233',
    city: 'Dingle',
    state: 'ID',
    latitude: '42.229841',
    longitude: '-111.280722',
    timeZoneId: 'America/Denver',
  },
  '83234': {
    zip: '83234',
    city: 'Downey',
    state: 'ID',
    latitude: '42.45189',
    longitude: '-112.157149',
    timeZoneId: 'America/Denver',
  },
  '83235': {
    zip: '83235',
    city: 'Ellis',
    state: 'ID',
    latitude: '44.851277',
    longitude: '-114.092051',
    timeZoneId: 'America/Denver',
  },
  '83236': {
    zip: '83236',
    city: 'Firth',
    state: 'ID',
    latitude: '43.28666',
    longitude: '-112.129557',
    timeZoneId: 'America/Denver',
  },
  '83237': {
    zip: '83237',
    city: 'Franklin',
    state: 'ID',
    latitude: '42.027323',
    longitude: '-111.796522',
    timeZoneId: 'America/Denver',
  },
  '83238': {
    zip: '83238',
    city: 'Geneva',
    state: 'ID',
    latitude: '42.319818',
    longitude: '-111.061024',
    timeZoneId: 'America/Denver',
  },
  '83239': {
    zip: '83239',
    city: 'Georgetown',
    state: 'ID',
    latitude: '42.471629',
    longitude: '-111.396953',
    timeZoneId: 'America/Denver',
  },
  '83241': {
    zip: '83241',
    city: 'Grace',
    state: 'ID',
    latitude: '42.526431',
    longitude: '-111.759461',
    timeZoneId: 'America/Denver',
  },
  '83243': {
    zip: '83243',
    city: 'Holbrook',
    state: 'ID',
    latitude: '42.26795',
    longitude: '-112.616196',
    timeZoneId: 'America/Denver',
  },
  '83244': {
    zip: '83244',
    city: 'Howe',
    state: 'ID',
    latitude: '43.797498',
    longitude: '-113.09306',
    timeZoneId: 'America/Denver',
  },
  '83245': {
    zip: '83245',
    city: 'Inkom',
    state: 'ID',
    latitude: '42.795065',
    longitude: '-112.23601',
    timeZoneId: 'America/Denver',
  },
  '83246': {
    zip: '83246',
    city: 'Lava Hot Springs',
    state: 'ID',
    latitude: '42.609094',
    longitude: '-112.026692',
    timeZoneId: 'America/Denver',
  },
  '83250': {
    zip: '83250',
    city: 'McCammon',
    state: 'ID',
    latitude: '42.643759',
    longitude: '-112.195942',
    timeZoneId: 'America/Denver',
  },
  '83251': {
    zip: '83251',
    city: 'Mackay',
    state: 'ID',
    latitude: '43.93338',
    longitude: '-113.7795',
    timeZoneId: 'America/Denver',
  },
  '83252': {
    zip: '83252',
    city: 'Malad City',
    state: 'ID',
    latitude: '42.192381',
    longitude: '-112.248421',
    timeZoneId: 'America/Denver',
  },
  '83253': {
    zip: '83253',
    city: 'May',
    state: 'ID',
    latitude: '44.577861',
    longitude: '-113.809881',
    timeZoneId: 'America/Denver',
  },
  '83254': {
    zip: '83254',
    city: 'Montpelier',
    state: 'ID',
    latitude: '42.31586',
    longitude: '-111.295308',
    timeZoneId: 'America/Denver',
  },
  '83255': {
    zip: '83255',
    city: 'Moore',
    state: 'ID',
    latitude: '43.86916',
    longitude: '-113.078054',
    timeZoneId: 'America/Denver',
  },
  '83256': {
    zip: '83256',
    city: 'Moreland',
    state: 'ID',
    latitude: '43.224524',
    longitude: '-112.44283',
    timeZoneId: 'America/Denver',
  },
  '83261': {
    zip: '83261',
    city: 'Paris',
    state: 'ID',
    latitude: '42.229162',
    longitude: '-111.407694',
    timeZoneId: 'America/Denver',
  },
  '83262': {
    zip: '83262',
    city: 'Pingree',
    state: 'ID',
    latitude: '43.14855',
    longitude: '-112.629588',
    timeZoneId: 'America/Denver',
  },
  '83263': {
    zip: '83263',
    city: 'Preston',
    state: 'ID',
    latitude: '42.20988',
    longitude: '-111.7536',
    timeZoneId: 'America/Denver',
  },
  '83271': {
    zip: '83271',
    city: 'Rockland',
    state: 'ID',
    latitude: '42.521281',
    longitude: '-112.846469',
    timeZoneId: 'America/Denver',
  },
  '83272': {
    zip: '83272',
    city: 'Saint Charles',
    state: 'ID',
    latitude: '42.077452',
    longitude: '-111.314009',
    timeZoneId: 'America/Denver',
  },
  '83274': {
    zip: '83274',
    city: 'Shelley',
    state: 'ID',
    latitude: '43.379837',
    longitude: '-112.12474',
    timeZoneId: 'America/Denver',
  },
  '83276': {
    zip: '83276',
    city: 'Soda Springs',
    state: 'ID',
    latitude: '42.702141',
    longitude: '-111.569814',
    timeZoneId: 'America/Denver',
  },
  '83277': {
    zip: '83277',
    city: 'Springfield',
    state: 'ID',
    latitude: '43.062037',
    longitude: '-112.663946',
    timeZoneId: 'America/Denver',
  },
  '83278': {
    zip: '83278',
    city: 'Stanley',
    state: 'ID',
    latitude: '44.330002',
    longitude: '-114.863022',
    timeZoneId: 'America/Denver',
  },
  '83281': {
    zip: '83281',
    city: 'Swanlake',
    state: 'ID',
    latitude: '42.325765',
    longitude: '-111.997695',
    timeZoneId: 'America/Denver',
  },
  '83283': {
    zip: '83283',
    city: 'Thatcher',
    state: 'ID',
    latitude: '42.387191',
    longitude: '-111.717415',
    timeZoneId: 'America/Denver',
  },
  '83285': {
    zip: '83285',
    city: 'Wayan',
    state: 'ID',
    latitude: '42.951741',
    longitude: '-111.173683',
    timeZoneId: 'America/Denver',
  },
  '83286': {
    zip: '83286',
    city: 'Weston',
    state: 'ID',
    latitude: '42.033727',
    longitude: '-112.014264',
    timeZoneId: 'America/Denver',
  },
  '83287': {
    zip: '83287',
    city: 'Fish Haven',
    state: 'ID',
    latitude: '42.019022',
    longitude: '-111.423286',
    timeZoneId: 'America/Denver',
  },
  '83301': {
    zip: '83301',
    city: 'Twin Falls',
    state: 'ID',
    latitude: '42.44736',
    longitude: '-114.5382',
    timeZoneId: 'America/Denver',
  },
  '83302': {
    zip: '83302',
    city: 'Rogerson',
    state: 'ID',
    latitude: '42.208715',
    longitude: '-114.593476',
    timeZoneId: 'America/Denver',
  },
  '83303': {
    zip: '83303',
    city: 'Twin Falls',
    state: 'ID',
    latitude: '42.560847',
    longitude: '-114.464815',
    timeZoneId: 'America/Denver',
  },
  '83311': {
    zip: '83311',
    city: 'Albion',
    state: 'ID',
    latitude: '42.391988',
    longitude: '-113.548093',
    timeZoneId: 'America/Denver',
  },
  '83312': {
    zip: '83312',
    city: 'Almo',
    state: 'ID',
    latitude: '42.12311',
    longitude: '-113.62282',
    timeZoneId: 'America/Denver',
  },
  '83313': {
    zip: '83313',
    city: 'Bellevue',
    state: 'ID',
    latitude: '43.414624',
    longitude: '-114.19899',
    timeZoneId: 'America/Denver',
  },
  '83314': {
    zip: '83314',
    city: 'Bliss',
    state: 'ID',
    latitude: '42.94686',
    longitude: '-114.9342',
    timeZoneId: 'America/Denver',
  },
  '83316': {
    zip: '83316',
    city: 'Buhl',
    state: 'ID',
    latitude: '42.604315',
    longitude: '-114.762452',
    timeZoneId: 'America/Denver',
  },
  '83318': {
    zip: '83318',
    city: 'Burley',
    state: 'ID',
    latitude: '42.528311',
    longitude: '-113.790822',
    timeZoneId: 'America/Denver',
  },
  '83320': {
    zip: '83320',
    city: 'Carey',
    state: 'ID',
    latitude: '43.28718',
    longitude: '-113.9247',
    timeZoneId: 'America/Denver',
  },
  '83321': {
    zip: '83321',
    city: 'Castleford',
    state: 'ID',
    latitude: '42.522145',
    longitude: '-114.871179',
    timeZoneId: 'America/Denver',
  },
  '83322': {
    zip: '83322',
    city: 'Corral',
    state: 'ID',
    latitude: '43.322434',
    longitude: '-114.99876',
    timeZoneId: 'America/Denver',
  },
  '83323': {
    zip: '83323',
    city: 'Declo',
    state: 'ID',
    latitude: '42.518218',
    longitude: '-113.627087',
    timeZoneId: 'America/Denver',
  },
  '83324': {
    zip: '83324',
    city: 'Dietrich',
    state: 'ID',
    latitude: '42.92196',
    longitude: '-114.27384',
    timeZoneId: 'America/Denver',
  },
  '83325': {
    zip: '83325',
    city: 'Eden',
    state: 'ID',
    latitude: '42.60298',
    longitude: '-114.22109',
    timeZoneId: 'America/Denver',
  },
  '83327': {
    zip: '83327',
    city: 'Fairfield',
    state: 'ID',
    latitude: '43.487841',
    longitude: '-114.769284',
    timeZoneId: 'America/Denver',
  },
  '83328': {
    zip: '83328',
    city: 'Filer',
    state: 'ID',
    latitude: '42.55962',
    longitude: '-114.62256',
    timeZoneId: 'America/Denver',
  },
  '83330': {
    zip: '83330',
    city: 'Gooding',
    state: 'ID',
    latitude: '42.937777',
    longitude: '-114.71703',
    timeZoneId: 'America/Denver',
  },
  '83332': {
    zip: '83332',
    city: 'Hagerman',
    state: 'ID',
    latitude: '42.822866',
    longitude: '-114.883889',
    timeZoneId: 'America/Denver',
  },
  '83333': {
    zip: '83333',
    city: 'Hailey',
    state: 'ID',
    latitude: '43.520227',
    longitude: '-114.306418',
    timeZoneId: 'America/Denver',
  },
  '83334': {
    zip: '83334',
    city: 'Hansen',
    state: 'ID',
    latitude: '42.515005',
    longitude: '-114.303296',
    timeZoneId: 'America/Denver',
  },
  '83335': {
    zip: '83335',
    city: 'Hazelton',
    state: 'ID',
    latitude: '42.589603',
    longitude: '-114.08473',
    timeZoneId: 'America/Denver',
  },
  '83336': {
    zip: '83336',
    city: 'Heyburn',
    state: 'ID',
    latitude: '42.562718',
    longitude: '-113.763959',
    timeZoneId: 'America/Denver',
  },
  '83337': {
    zip: '83337',
    city: 'Hill City',
    state: 'ID',
    latitude: '43.301826',
    longitude: '-115.050884',
    timeZoneId: 'America/Denver',
  },
  '83338': {
    zip: '83338',
    city: 'Jerome',
    state: 'ID',
    latitude: '42.709237',
    longitude: '-114.464322',
    timeZoneId: 'America/Denver',
  },
  '83340': {
    zip: '83340',
    city: 'Ketchum',
    state: 'ID',
    latitude: '43.733354',
    longitude: '-114.512415',
    timeZoneId: 'America/Denver',
  },
  '83341': {
    zip: '83341',
    city: 'Kimberly',
    state: 'ID',
    latitude: '42.542008',
    longitude: '-114.362786',
    timeZoneId: 'America/Denver',
  },
  '83342': {
    zip: '83342',
    city: 'Malta',
    state: 'ID',
    latitude: '42.305271',
    longitude: '-113.370505',
    timeZoneId: 'America/Denver',
  },
  '83343': {
    zip: '83343',
    city: 'Minidoka',
    state: 'ID',
    latitude: '42.753889',
    longitude: '-113.489444',
    timeZoneId: 'America/Denver',
  },
  '83344': {
    zip: '83344',
    city: 'Murtaugh',
    state: 'ID',
    latitude: '42.493358',
    longitude: '-114.161857',
    timeZoneId: 'America/Denver',
  },
  '83346': {
    zip: '83346',
    city: 'Oakley',
    state: 'ID',
    latitude: '42.303',
    longitude: '-113.99628',
    timeZoneId: 'America/Denver',
  },
  '83347': {
    zip: '83347',
    city: 'Paul',
    state: 'ID',
    latitude: '42.617107',
    longitude: '-113.751518',
    timeZoneId: 'America/Denver',
  },
  '83348': {
    zip: '83348',
    city: 'Picabo',
    state: 'ID',
    latitude: '43.301478',
    longitude: '-114.09516',
    timeZoneId: 'America/Denver',
  },
  '83349': {
    zip: '83349',
    city: 'Richfield',
    state: 'ID',
    latitude: '43.054018',
    longitude: '-114.149995',
    timeZoneId: 'America/Denver',
  },
  '83350': {
    zip: '83350',
    city: 'Rupert',
    state: 'ID',
    latitude: '42.62028',
    longitude: '-113.76732',
    timeZoneId: 'America/Denver',
  },
  '83352': {
    zip: '83352',
    city: 'Shoshone',
    state: 'ID',
    latitude: '42.939059',
    longitude: '-114.405702',
    timeZoneId: 'America/Denver',
  },
  '83353': {
    zip: '83353',
    city: 'Sun Valley',
    state: 'ID',
    latitude: '43.727645',
    longitude: '-114.26493',
    timeZoneId: 'America/Denver',
  },
  '83354': {
    zip: '83354',
    city: 'Sun Valley',
    state: 'ID',
    latitude: '43.726589',
    longitude: '-114.262377',
    timeZoneId: 'America/Denver',
  },
  '83355': {
    zip: '83355',
    city: 'Wendell',
    state: 'ID',
    latitude: '42.760933',
    longitude: '-114.702018',
    timeZoneId: 'America/Denver',
  },
  '83401': {
    zip: '83401',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.52664',
    longitude: '-111.80646',
    timeZoneId: 'America/Denver',
  },
  '83402': {
    zip: '83402',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.496857',
    longitude: '-112.113288',
    timeZoneId: 'America/Denver',
  },
  '83403': {
    zip: '83403',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.479402',
    longitude: '-111.982911',
    timeZoneId: 'America/Denver',
  },
  '83404': {
    zip: '83404',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.47462',
    longitude: '-112.012784',
    timeZoneId: 'America/Denver',
  },
  '83405': {
    zip: '83405',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.489274',
    longitude: '-112.11022',
    timeZoneId: 'America/Denver',
  },
  '83406': {
    zip: '83406',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.45105',
    longitude: '-111.965181',
    timeZoneId: 'America/Denver',
  },
  '83414': {
    zip: '83414',
    city: 'Alta',
    state: 'WY',
    latitude: '43.748639',
    longitude: '-110.935969',
    timeZoneId: 'America/Denver',
  },
  '83415': {
    zip: '83415',
    city: 'Idaho Falls',
    state: 'ID',
    latitude: '43.491978',
    longitude: '-112.033059',
    timeZoneId: 'America/Denver',
  },
  '83420': {
    zip: '83420',
    city: 'Ashton',
    state: 'ID',
    latitude: '44.04276',
    longitude: '-111.37542',
    timeZoneId: 'America/Denver',
  },
  '83421': {
    zip: '83421',
    city: 'Chester',
    state: 'ID',
    latitude: '43.999806',
    longitude: '-111.568989',
    timeZoneId: 'America/Denver',
  },
  '83422': {
    zip: '83422',
    city: 'Driggs',
    state: 'ID',
    latitude: '43.734162',
    longitude: '-111.221574',
    timeZoneId: 'America/Denver',
  },
  '83423': {
    zip: '83423',
    city: 'Dubois',
    state: 'ID',
    latitude: '44.179107',
    longitude: '-112.228646',
    timeZoneId: 'America/Denver',
  },
  '83424': {
    zip: '83424',
    city: 'Felt',
    state: 'ID',
    latitude: '43.888177',
    longitude: '-111.176883',
    timeZoneId: 'America/Denver',
  },
  '83425': {
    zip: '83425',
    city: 'Hamer',
    state: 'ID',
    latitude: '43.906168',
    longitude: '-112.144117',
    timeZoneId: 'America/Denver',
  },
  '83427': {
    zip: '83427',
    city: 'Iona',
    state: 'ID',
    latitude: '43.457371',
    longitude: '-111.868061',
    timeZoneId: 'America/Denver',
  },
  '83428': {
    zip: '83428',
    city: 'Irwin',
    state: 'ID',
    latitude: '43.410639',
    longitude: '-111.291199',
    timeZoneId: 'America/Denver',
  },
  '83429': {
    zip: '83429',
    city: 'Island Park',
    state: 'ID',
    latitude: '44.459589',
    longitude: '-111.345873',
    timeZoneId: 'America/Denver',
  },
  '83431': {
    zip: '83431',
    city: 'Lewisville',
    state: 'ID',
    latitude: '43.695725',
    longitude: '-112.011916',
    timeZoneId: 'America/Denver',
  },
  '83433': {
    zip: '83433',
    city: 'Macks Inn',
    state: 'ID',
    latitude: '44.499167',
    longitude: '-111.336944',
    timeZoneId: 'America/Denver',
  },
  '83434': {
    zip: '83434',
    city: 'Menan',
    state: 'ID',
    latitude: '43.722596',
    longitude: '-111.991921',
    timeZoneId: 'America/Denver',
  },
  '83435': {
    zip: '83435',
    city: 'Monteview',
    state: 'ID',
    latitude: '43.872249',
    longitude: '-112.543616',
    timeZoneId: 'America/Denver',
  },
  '83436': {
    zip: '83436',
    city: 'Newdale',
    state: 'ID',
    latitude: '43.860779',
    longitude: '-111.515462',
    timeZoneId: 'America/Denver',
  },
  '83438': {
    zip: '83438',
    city: 'Parker',
    state: 'ID',
    latitude: '43.96029',
    longitude: '-111.763921',
    timeZoneId: 'America/Denver',
  },
  '83440': {
    zip: '83440',
    city: 'Rexburg',
    state: 'ID',
    latitude: '43.831739',
    longitude: '-111.783266',
    timeZoneId: 'America/Denver',
  },
  '83441': {
    zip: '83441',
    city: 'Rexburg',
    state: 'ID',
    latitude: '43.762902',
    longitude: '-111.609618',
    timeZoneId: 'America/Denver',
  },
  '83442': {
    zip: '83442',
    city: 'Rigby',
    state: 'ID',
    latitude: '43.68648',
    longitude: '-111.8796',
    timeZoneId: 'America/Denver',
  },
  '83443': {
    zip: '83443',
    city: 'Ririe',
    state: 'ID',
    latitude: '43.595448',
    longitude: '-111.660076',
    timeZoneId: 'America/Denver',
  },
  '83444': {
    zip: '83444',
    city: 'Roberts',
    state: 'ID',
    latitude: '43.733673',
    longitude: '-112.12703',
    timeZoneId: 'America/Denver',
  },
  '83445': {
    zip: '83445',
    city: 'Saint Anthony',
    state: 'ID',
    latitude: '44.15724',
    longitude: '-111.64506',
    timeZoneId: 'America/Denver',
  },
  '83446': {
    zip: '83446',
    city: 'Spencer',
    state: 'ID',
    latitude: '44.352939',
    longitude: '-112.002532',
    timeZoneId: 'America/Denver',
  },
  '83448': {
    zip: '83448',
    city: 'Sugar City',
    state: 'ID',
    latitude: '43.874217',
    longitude: '-111.734555',
    timeZoneId: 'America/Denver',
  },
  '83449': {
    zip: '83449',
    city: 'Swan Valley',
    state: 'ID',
    latitude: '43.435853',
    longitude: '-111.293388',
    timeZoneId: 'America/Denver',
  },
  '83450': {
    zip: '83450',
    city: 'Terreton',
    state: 'ID',
    latitude: '43.8594',
    longitude: '-112.43538',
    timeZoneId: 'America/Denver',
  },
  '83451': {
    zip: '83451',
    city: 'Teton',
    state: 'ID',
    latitude: '43.905534',
    longitude: '-111.657469',
    timeZoneId: 'America/Denver',
  },
  '83452': {
    zip: '83452',
    city: 'Tetonia',
    state: 'ID',
    latitude: '43.7987',
    longitude: '-111.181167',
    timeZoneId: 'America/Denver',
  },
  '83454': {
    zip: '83454',
    city: 'Ucon',
    state: 'ID',
    latitude: '43.588504',
    longitude: '-111.962864',
    timeZoneId: 'America/Denver',
  },
  '83455': {
    zip: '83455',
    city: 'Victor',
    state: 'ID',
    latitude: '43.606526',
    longitude: '-111.128907',
    timeZoneId: 'America/Denver',
  },
  '83460': {
    zip: '83460',
    city: 'Rexburg',
    state: 'ID',
    latitude: '43.818343',
    longitude: '-111.782192',
    timeZoneId: 'America/Denver',
  },
  '83462': {
    zip: '83462',
    city: 'Carmen',
    state: 'ID',
    latitude: '45.269758',
    longitude: '-113.845022',
    timeZoneId: 'America/Denver',
  },
  '83463': {
    zip: '83463',
    city: 'Gibbonsville',
    state: 'ID',
    latitude: '45.518343',
    longitude: '-113.983536',
    timeZoneId: 'America/Denver',
  },
  '83464': {
    zip: '83464',
    city: 'Leadore',
    state: 'ID',
    latitude: '44.601224',
    longitude: '-113.355918',
    timeZoneId: 'America/Denver',
  },
  '83465': {
    zip: '83465',
    city: 'Lemhi',
    state: 'ID',
    latitude: '44.84993',
    longitude: '-113.617058',
    timeZoneId: 'America/Denver',
  },
  '83466': {
    zip: '83466',
    city: 'North Fork',
    state: 'ID',
    latitude: '45.379133',
    longitude: '-113.867936',
    timeZoneId: 'America/Denver',
  },
  '83467': {
    zip: '83467',
    city: 'Salmon',
    state: 'ID',
    latitude: '45.172897',
    longitude: '-113.898929',
    timeZoneId: 'America/Denver',
  },
  '83468': {
    zip: '83468',
    city: 'Tendoy',
    state: 'ID',
    latitude: '44.960904',
    longitude: '-113.641348',
    timeZoneId: 'America/Denver',
  },
  '83469': {
    zip: '83469',
    city: 'Shoup',
    state: 'ID',
    latitude: '45.107842',
    longitude: '-114.43407',
    timeZoneId: 'America/Denver',
  },
  '83501': {
    zip: '83501',
    city: 'Lewiston',
    state: 'ID',
    latitude: '46.388958',
    longitude: '-116.986393',
    timeZoneId: 'America/Los_Angeles',
  },
  '83520': {
    zip: '83520',
    city: 'Ahsahka',
    state: 'ID',
    latitude: '46.57773',
    longitude: '-116.251715',
    timeZoneId: 'America/Los_Angeles',
  },
  '83522': {
    zip: '83522',
    city: 'Cottonwood',
    state: 'ID',
    latitude: '46.023442',
    longitude: '-116.268584',
    timeZoneId: 'America/Los_Angeles',
  },
  '83523': {
    zip: '83523',
    city: 'Craigmont',
    state: 'ID',
    latitude: '46.243004',
    longitude: '-116.47091',
    timeZoneId: 'America/Los_Angeles',
  },
  '83524': {
    zip: '83524',
    city: 'Culdesac',
    state: 'ID',
    latitude: '46.353838',
    longitude: '-116.634758',
    timeZoneId: 'America/Los_Angeles',
  },
  '83525': {
    zip: '83525',
    city: 'Elk City',
    state: 'ID',
    latitude: '45.823543',
    longitude: '-115.443122',
    timeZoneId: 'America/Los_Angeles',
  },
  '83526': {
    zip: '83526',
    city: 'Ferdinand',
    state: 'ID',
    latitude: '46.127853',
    longitude: '-116.420041',
    timeZoneId: 'America/Los_Angeles',
  },
  '83530': {
    zip: '83530',
    city: 'Grangeville',
    state: 'ID',
    latitude: '45.929393',
    longitude: '-116.118593',
    timeZoneId: 'America/Los_Angeles',
  },
  '83531': {
    zip: '83531',
    city: 'Fenn',
    state: 'ID',
    latitude: '45.926329',
    longitude: '-116.125155',
    timeZoneId: 'America/Los_Angeles',
  },
  '83533': {
    zip: '83533',
    city: 'Greencreek',
    state: 'ID',
    latitude: '46.048611',
    longitude: '-116.348611',
    timeZoneId: 'America/Los_Angeles',
  },
  '83535': {
    zip: '83535',
    city: 'Juliaetta',
    state: 'ID',
    latitude: '46.573013',
    longitude: '-116.748611',
    timeZoneId: 'America/Los_Angeles',
  },
  '83536': {
    zip: '83536',
    city: 'Kamiah',
    state: 'ID',
    latitude: '46.225754',
    longitude: '-116.027528',
    timeZoneId: 'America/Los_Angeles',
  },
  '83537': {
    zip: '83537',
    city: 'Kendrick',
    state: 'ID',
    latitude: '46.643793',
    longitude: '-116.541608',
    timeZoneId: 'America/Los_Angeles',
  },
  '83539': {
    zip: '83539',
    city: 'Kooskia',
    state: 'ID',
    latitude: '46.15608',
    longitude: '-115.88622',
    timeZoneId: 'America/Los_Angeles',
  },
  '83540': {
    zip: '83540',
    city: 'Lapwai',
    state: 'ID',
    latitude: '46.404644',
    longitude: '-116.807243',
    timeZoneId: 'America/Los_Angeles',
  },
  '83541': {
    zip: '83541',
    city: 'Lenore',
    state: 'ID',
    latitude: '46.530058',
    longitude: '-116.400502',
    timeZoneId: 'America/Los_Angeles',
  },
  '83542': {
    zip: '83542',
    city: 'Lucile',
    state: 'ID',
    latitude: '45.535466',
    longitude: '-116.317589',
    timeZoneId: 'America/Denver',
  },
  '83543': {
    zip: '83543',
    city: 'Nezperce',
    state: 'ID',
    latitude: '46.219251',
    longitude: '-116.248211',
    timeZoneId: 'America/Los_Angeles',
  },
  '83544': {
    zip: '83544',
    city: 'Orofino',
    state: 'ID',
    latitude: '46.51782',
    longitude: '-116.11128',
    timeZoneId: 'America/Los_Angeles',
  },
  '83545': {
    zip: '83545',
    city: 'Peck',
    state: 'ID',
    latitude: '46.471981',
    longitude: '-116.419698',
    timeZoneId: 'America/Los_Angeles',
  },
  '83546': {
    zip: '83546',
    city: 'Pierce',
    state: 'ID',
    latitude: '46.48842',
    longitude: '-115.79988',
    timeZoneId: 'America/Los_Angeles',
  },
  '83547': {
    zip: '83547',
    city: 'Pollock',
    state: 'ID',
    latitude: '45.283647',
    longitude: '-116.35123',
    timeZoneId: 'America/Denver',
  },
  '83548': {
    zip: '83548',
    city: 'Reubens',
    state: 'ID',
    latitude: '46.34472',
    longitude: '-116.5419',
    timeZoneId: 'America/Los_Angeles',
  },
  '83549': {
    zip: '83549',
    city: 'Riggins',
    state: 'ID',
    latitude: '45.367969',
    longitude: '-116.395099',
    timeZoneId: 'America/Denver',
  },
  '83552': {
    zip: '83552',
    city: 'Stites',
    state: 'ID',
    latitude: '46.120893',
    longitude: '-115.974113',
    timeZoneId: 'America/Los_Angeles',
  },
  '83553': {
    zip: '83553',
    city: 'Weippe',
    state: 'ID',
    latitude: '46.380448',
    longitude: '-115.947701',
    timeZoneId: 'America/Los_Angeles',
  },
  '83554': {
    zip: '83554',
    city: 'White Bird',
    state: 'ID',
    latitude: '45.750001',
    longitude: '-116.206388',
    timeZoneId: 'America/Los_Angeles',
  },
  '83555': {
    zip: '83555',
    city: 'Winchester',
    state: 'ID',
    latitude: '46.242252',
    longitude: '-116.607936',
    timeZoneId: 'America/Los_Angeles',
  },
  '83602': {
    zip: '83602',
    city: 'Banks',
    state: 'ID',
    latitude: '44.172548',
    longitude: '-116.028276',
    timeZoneId: 'America/Denver',
  },
  '83604': {
    zip: '83604',
    city: 'Bruneau',
    state: 'ID',
    latitude: '42.681724',
    longitude: '-115.929733',
    timeZoneId: 'America/Denver',
  },
  '83605': {
    zip: '83605',
    city: 'Caldwell',
    state: 'ID',
    latitude: '43.658798',
    longitude: '-116.716481',
    timeZoneId: 'America/Denver',
  },
  '83606': {
    zip: '83606',
    city: 'Caldwell',
    state: 'ID',
    latitude: '43.717644',
    longitude: '-116.742415',
    timeZoneId: 'America/Denver',
  },
  '83607': {
    zip: '83607',
    city: 'Caldwell',
    state: 'ID',
    latitude: '43.662016',
    longitude: '-116.685127',
    timeZoneId: 'America/Denver',
  },
  '83610': {
    zip: '83610',
    city: 'Cambridge',
    state: 'ID',
    latitude: '44.581764',
    longitude: '-116.658632',
    timeZoneId: 'America/Denver',
  },
  '83611': {
    zip: '83611',
    city: 'Cascade',
    state: 'ID',
    latitude: '44.39346',
    longitude: '-115.98126',
    timeZoneId: 'America/Denver',
  },
  '83612': {
    zip: '83612',
    city: 'Council',
    state: 'ID',
    latitude: '44.695761',
    longitude: '-116.461989',
    timeZoneId: 'America/Denver',
  },
  '83615': {
    zip: '83615',
    city: 'Donnelly',
    state: 'ID',
    latitude: '44.710118',
    longitude: '-116.095636',
    timeZoneId: 'America/Denver',
  },
  '83616': {
    zip: '83616',
    city: 'Eagle',
    state: 'ID',
    latitude: '43.711993',
    longitude: '-116.374414',
    timeZoneId: 'America/Denver',
  },
  '83617': {
    zip: '83617',
    city: 'Emmett',
    state: 'ID',
    latitude: '43.8969',
    longitude: '-116.50278',
    timeZoneId: 'America/Denver',
  },
  '83619': {
    zip: '83619',
    city: 'Fruitland',
    state: 'ID',
    latitude: '43.995655',
    longitude: '-116.909525',
    timeZoneId: 'America/Denver',
  },
  '83622': {
    zip: '83622',
    city: 'Garden Valley',
    state: 'ID',
    latitude: '44.05572',
    longitude: '-115.62438',
    timeZoneId: 'America/Denver',
  },
  '83623': {
    zip: '83623',
    city: 'Glenns Ferry',
    state: 'ID',
    latitude: '42.950143',
    longitude: '-115.297354',
    timeZoneId: 'America/Denver',
  },
  '83624': {
    zip: '83624',
    city: 'Grand View',
    state: 'ID',
    latitude: '42.9853',
    longitude: '-116.088493',
    timeZoneId: 'America/Denver',
  },
  '83626': {
    zip: '83626',
    city: 'Greenleaf',
    state: 'ID',
    latitude: '43.669826',
    longitude: '-116.818546',
    timeZoneId: 'America/Denver',
  },
  '83627': {
    zip: '83627',
    city: 'Hammett',
    state: 'ID',
    latitude: '42.977016',
    longitude: '-115.564362',
    timeZoneId: 'America/Denver',
  },
  '83628': {
    zip: '83628',
    city: 'Homedale',
    state: 'ID',
    latitude: '43.604314',
    longitude: '-116.911816',
    timeZoneId: 'America/Denver',
  },
  '83629': {
    zip: '83629',
    city: 'Horseshoe Bend',
    state: 'ID',
    latitude: '43.928831',
    longitude: '-116.292814',
    timeZoneId: 'America/Denver',
  },
  '83630': {
    zip: '83630',
    city: 'Huston',
    state: 'ID',
    latitude: '43.594852',
    longitude: '-116.781421',
    timeZoneId: 'America/Denver',
  },
  '83631': {
    zip: '83631',
    city: 'Idaho City',
    state: 'ID',
    latitude: '43.869698',
    longitude: '-115.774376',
    timeZoneId: 'America/Denver',
  },
  '83632': {
    zip: '83632',
    city: 'Indian Valley',
    state: 'ID',
    latitude: '44.530683',
    longitude: '-116.347764',
    timeZoneId: 'America/Denver',
  },
  '83633': {
    zip: '83633',
    city: 'King Hill',
    state: 'ID',
    latitude: '42.859902',
    longitude: '-115.243209',
    timeZoneId: 'America/Denver',
  },
  '83634': {
    zip: '83634',
    city: 'Kuna',
    state: 'ID',
    latitude: '43.477369',
    longitude: '-116.392',
    timeZoneId: 'America/Denver',
  },
  '83635': {
    zip: '83635',
    city: 'Lake Fork',
    state: 'ID',
    latitude: '44.911111',
    longitude: '-116.097778',
    timeZoneId: 'America/Denver',
  },
  '83636': {
    zip: '83636',
    city: 'Letha',
    state: 'ID',
    latitude: '43.89831',
    longitude: '-116.648901',
    timeZoneId: 'America/Denver',
  },
  '83637': {
    zip: '83637',
    city: 'Lowman',
    state: 'ID',
    latitude: '44.140322',
    longitude: '-115.552484',
    timeZoneId: 'America/Denver',
  },
  '83638': {
    zip: '83638',
    city: 'McCall',
    state: 'ID',
    latitude: '44.914318',
    longitude: '-116.098389',
    timeZoneId: 'America/Denver',
  },
  '83639': {
    zip: '83639',
    city: 'Marsing',
    state: 'ID',
    latitude: '43.516707',
    longitude: '-116.778404',
    timeZoneId: 'America/Denver',
  },
  '83641': {
    zip: '83641',
    city: 'Melba',
    state: 'ID',
    latitude: '43.380775',
    longitude: '-116.549761',
    timeZoneId: 'America/Denver',
  },
  '83642': {
    zip: '83642',
    city: 'Meridian',
    state: 'ID',
    latitude: '43.609109',
    longitude: '-116.398114',
    timeZoneId: 'America/Denver',
  },
  '83643': {
    zip: '83643',
    city: 'Mesa',
    state: 'ID',
    latitude: '44.668855',
    longitude: '-116.379583',
    timeZoneId: 'America/Denver',
  },
  '83644': {
    zip: '83644',
    city: 'Middleton',
    state: 'ID',
    latitude: '43.722252',
    longitude: '-116.60359',
    timeZoneId: 'America/Denver',
  },
  '83645': {
    zip: '83645',
    city: 'Midvale',
    state: 'ID',
    latitude: '44.389678',
    longitude: '-116.611081',
    timeZoneId: 'America/Denver',
  },
  '83646': {
    zip: '83646',
    city: 'Meridian',
    state: 'ID',
    latitude: '43.609657',
    longitude: '-116.398343',
    timeZoneId: 'America/Denver',
  },
  '83647': {
    zip: '83647',
    city: 'Mountain Home',
    state: 'ID',
    latitude: '43.16976',
    longitude: '-115.71378',
    timeZoneId: 'America/Denver',
  },
  '83648': {
    zip: '83648',
    city: 'Mountain Home AFB',
    state: 'ID',
    latitude: '43.059898',
    longitude: '-115.854735',
    timeZoneId: 'America/Denver',
  },
  '83650': {
    zip: '83650',
    city: 'Murphy',
    state: 'ID',
    latitude: '43.053611',
    longitude: '-116.394167',
    timeZoneId: 'America/Denver',
  },
  '83651': {
    zip: '83651',
    city: 'Nampa',
    state: 'ID',
    latitude: '43.582086',
    longitude: '-116.590913',
    timeZoneId: 'America/Denver',
  },
  '83652': {
    zip: '83652',
    city: 'Nampa',
    state: 'ID',
    latitude: '43.70733',
    longitude: '-116.621299',
    timeZoneId: 'America/Denver',
  },
  '83653': {
    zip: '83653',
    city: 'Nampa',
    state: 'ID',
    latitude: '43.63473',
    longitude: '-116.498083',
    timeZoneId: 'America/Denver',
  },
  '83654': {
    zip: '83654',
    city: 'New Meadows',
    state: 'ID',
    latitude: '44.98056',
    longitude: '-116.2638',
    timeZoneId: 'America/Denver',
  },
  '83655': {
    zip: '83655',
    city: 'New Plymouth',
    state: 'ID',
    latitude: '43.958901',
    longitude: '-116.807565',
    timeZoneId: 'America/Denver',
  },
  '83656': {
    zip: '83656',
    city: 'Notus',
    state: 'ID',
    latitude: '43.729743',
    longitude: '-116.802904',
    timeZoneId: 'America/Denver',
  },
  '83657': {
    zip: '83657',
    city: 'Ola',
    state: 'ID',
    latitude: '44.250761',
    longitude: '-116.298415',
    timeZoneId: 'America/Denver',
  },
  '83660': {
    zip: '83660',
    city: 'Parma',
    state: 'ID',
    latitude: '43.774723',
    longitude: '-116.935468',
    timeZoneId: 'America/Denver',
  },
  '83661': {
    zip: '83661',
    city: 'Payette',
    state: 'ID',
    latitude: '44.075448',
    longitude: '-116.901792',
    timeZoneId: 'America/Denver',
  },
  '83666': {
    zip: '83666',
    city: 'Placerville',
    state: 'ID',
    latitude: '43.939898',
    longitude: '-115.943131',
    timeZoneId: 'America/Denver',
  },
  '83669': {
    zip: '83669',
    city: 'Star',
    state: 'ID',
    latitude: '43.703271',
    longitude: '-116.488941',
    timeZoneId: 'America/Denver',
  },
  '83670': {
    zip: '83670',
    city: 'Sweet',
    state: 'ID',
    latitude: '43.996035',
    longitude: '-116.323667',
    timeZoneId: 'America/Denver',
  },
  '83671': {
    zip: '83671',
    city: 'Warren',
    state: 'ID',
    latitude: '45.264266',
    longitude: '-115.675808',
    timeZoneId: 'America/Denver',
  },
  '83672': {
    zip: '83672',
    city: 'Weiser',
    state: 'ID',
    latitude: '44.2797',
    longitude: '-116.98542',
    timeZoneId: 'America/Denver',
  },
  '83676': {
    zip: '83676',
    city: 'Wilder',
    state: 'ID',
    latitude: '43.655554',
    longitude: '-116.906886',
    timeZoneId: 'America/Denver',
  },
  '83677': {
    zip: '83677',
    city: 'Yellow Pine',
    state: 'ID',
    latitude: '45.043964',
    longitude: '-115.369443',
    timeZoneId: 'America/Denver',
  },
  '83680': {
    zip: '83680',
    city: 'Meridian',
    state: 'ID',
    latitude: '43.607726',
    longitude: '-116.400726',
    timeZoneId: 'America/Denver',
  },
  '83686': {
    zip: '83686',
    city: 'Nampa',
    state: 'ID',
    latitude: '43.533311',
    longitude: '-116.574597',
    timeZoneId: 'America/Denver',
  },
  '83687': {
    zip: '83687',
    city: 'Nampa',
    state: 'ID',
    latitude: '43.595223',
    longitude: '-116.536483',
    timeZoneId: 'America/Denver',
  },
  '83701': {
    zip: '83701',
    city: 'Boise',
    state: 'ID',
    latitude: '43.62234',
    longitude: '-116.322069',
    timeZoneId: 'America/Denver',
  },
  '83702': {
    zip: '83702',
    city: 'Boise',
    state: 'ID',
    latitude: '43.628437',
    longitude: '-116.207449',
    timeZoneId: 'America/Denver',
  },
  '83703': {
    zip: '83703',
    city: 'Boise',
    state: 'ID',
    latitude: '43.627724',
    longitude: '-116.263707',
    timeZoneId: 'America/Denver',
  },
  '83704': {
    zip: '83704',
    city: 'Boise',
    state: 'ID',
    latitude: '43.630174',
    longitude: '-116.297422',
    timeZoneId: 'America/Denver',
  },
  '83705': {
    zip: '83705',
    city: 'Boise',
    state: 'ID',
    latitude: '43.580123',
    longitude: '-116.219041',
    timeZoneId: 'America/Denver',
  },
  '83706': {
    zip: '83706',
    city: 'Boise',
    state: 'ID',
    latitude: '43.590476',
    longitude: '-116.189842',
    timeZoneId: 'America/Denver',
  },
  '83707': {
    zip: '83707',
    city: 'Boise',
    state: 'ID',
    latitude: '43.65938',
    longitude: '-116.166616',
    timeZoneId: 'America/Denver',
  },
  '83708': {
    zip: '83708',
    city: 'Boise',
    state: 'ID',
    latitude: '43.610841',
    longitude: '-116.213183',
    timeZoneId: 'America/Denver',
  },
  '83709': {
    zip: '83709',
    city: 'Boise',
    state: 'ID',
    latitude: '43.574795',
    longitude: '-116.289166',
    timeZoneId: 'America/Denver',
  },
  '83711': {
    zip: '83711',
    city: 'Boise',
    state: 'ID',
    latitude: '43.653226',
    longitude: '-116.281124',
    timeZoneId: 'America/Denver',
  },
  '83712': {
    zip: '83712',
    city: 'Boise',
    state: 'ID',
    latitude: '43.59907',
    longitude: '-116.162434',
    timeZoneId: 'America/Denver',
  },
  '83713': {
    zip: '83713',
    city: 'Boise',
    state: 'ID',
    latitude: '43.637663',
    longitude: '-116.304201',
    timeZoneId: 'America/Denver',
  },
  '83714': {
    zip: '83714',
    city: 'Garden City',
    state: 'ID',
    latitude: '43.6419',
    longitude: '-116.2935',
    timeZoneId: 'America/Denver',
  },
  '83715': {
    zip: '83715',
    city: 'Boise',
    state: 'ID',
    latitude: '43.560976',
    longitude: '-116.218248',
    timeZoneId: 'America/Denver',
  },
  '83716': {
    zip: '83716',
    city: 'Boise',
    state: 'ID',
    latitude: '43.531746',
    longitude: '-116.1402',
    timeZoneId: 'America/Denver',
  },
  '83717': {
    zip: '83717',
    city: 'Boise',
    state: 'ID',
    latitude: '43.610151',
    longitude: '-116.203847',
    timeZoneId: 'America/Denver',
  },
  '83719': {
    zip: '83719',
    city: 'Boise',
    state: 'ID',
    latitude: '43.546011',
    longitude: '-116.286598',
    timeZoneId: 'America/Denver',
  },
  '83720': {
    zip: '83720',
    city: 'Boise',
    state: 'ID',
    latitude: '43.613494',
    longitude: '-116.216151',
    timeZoneId: 'America/Denver',
  },
  '83722': {
    zip: '83722',
    city: 'Boise',
    state: 'ID',
    latitude: '43.614327',
    longitude: '-116.216254',
    timeZoneId: 'America/Denver',
  },
  '83724': {
    zip: '83724',
    city: 'Boise',
    state: 'ID',
    latitude: '43.618309',
    longitude: '-116.195538',
    timeZoneId: 'America/Denver',
  },
  '83725': {
    zip: '83725',
    city: 'Boise',
    state: 'ID',
    latitude: '43.605516',
    longitude: '-116.19918',
    timeZoneId: 'America/Denver',
  },
  '83726': {
    zip: '83726',
    city: 'Boise',
    state: 'ID',
    latitude: '43.435557',
    longitude: '-116.004295',
    timeZoneId: 'America/Denver',
  },
  '83728': {
    zip: '83728',
    city: 'Boise',
    state: 'ID',
    latitude: '43.618134',
    longitude: '-116.202744',
    timeZoneId: 'America/Denver',
  },
  '83729': {
    zip: '83729',
    city: 'Boise',
    state: 'ID',
    latitude: '43.605939',
    longitude: '-116.193296',
    timeZoneId: 'America/Denver',
  },
  '83732': {
    zip: '83732',
    city: 'Boise',
    state: 'ID',
    latitude: '43.546838',
    longitude: '-116.288502',
    timeZoneId: 'America/Denver',
  },
  '83735': {
    zip: '83735',
    city: 'Boise',
    state: 'ID',
    latitude: '43.610738',
    longitude: '-116.199409',
    timeZoneId: 'America/Denver',
  },
  '83756': {
    zip: '83756',
    city: 'Boise',
    state: 'ID',
    latitude: '43.611127',
    longitude: '-116.215521',
    timeZoneId: 'America/Denver',
  },
  '83799': {
    zip: '83799',
    city: 'Boise',
    state: 'ID',
    latitude: '43.613913',
    longitude: '-116.200919',
    timeZoneId: 'America/Denver',
  },
  '83801': {
    zip: '83801',
    city: 'Athol',
    state: 'ID',
    latitude: '47.936824',
    longitude: '-116.675482',
    timeZoneId: 'America/Los_Angeles',
  },
  '83802': {
    zip: '83802',
    city: 'Avery',
    state: 'ID',
    latitude: '47.223188',
    longitude: '-115.81591',
    timeZoneId: 'America/Los_Angeles',
  },
  '83803': {
    zip: '83803',
    city: 'Bayview',
    state: 'ID',
    latitude: '47.98478',
    longitude: '-116.564588',
    timeZoneId: 'America/Los_Angeles',
  },
  '83804': {
    zip: '83804',
    city: 'Blanchard',
    state: 'ID',
    latitude: '48.013661',
    longitude: '-116.955335',
    timeZoneId: 'America/Los_Angeles',
  },
  '83805': {
    zip: '83805',
    city: 'Bonners Ferry',
    state: 'ID',
    latitude: '48.698029',
    longitude: '-116.318139',
    timeZoneId: 'America/Los_Angeles',
  },
  '83806': {
    zip: '83806',
    city: 'Bovill',
    state: 'ID',
    latitude: '46.863681',
    longitude: '-116.391792',
    timeZoneId: 'America/Los_Angeles',
  },
  '83808': {
    zip: '83808',
    city: 'Calder',
    state: 'ID',
    latitude: '47.255334',
    longitude: '-116.212663',
    timeZoneId: 'America/Los_Angeles',
  },
  '83809': {
    zip: '83809',
    city: 'Careywood',
    state: 'ID',
    latitude: '48.06852',
    longitude: '-116.5995',
    timeZoneId: 'America/Los_Angeles',
  },
  '83810': {
    zip: '83810',
    city: 'Cataldo',
    state: 'ID',
    latitude: '47.53194',
    longitude: '-116.45724',
    timeZoneId: 'America/Los_Angeles',
  },
  '83811': {
    zip: '83811',
    city: 'Clark Fork',
    state: 'ID',
    latitude: '48.147478',
    longitude: '-116.17828',
    timeZoneId: 'America/Los_Angeles',
  },
  '83812': {
    zip: '83812',
    city: 'Clarkia',
    state: 'ID',
    latitude: '47.0416',
    longitude: '-116.2591',
    timeZoneId: 'America/Los_Angeles',
  },
  '83813': {
    zip: '83813',
    city: 'Cocolalla',
    state: 'ID',
    latitude: '48.114657',
    longitude: '-116.651538',
    timeZoneId: 'America/Los_Angeles',
  },
  '83814': {
    zip: '83814',
    city: 'Coeur D Alene',
    state: 'ID',
    latitude: '47.687824',
    longitude: '-116.779638',
    timeZoneId: 'America/Los_Angeles',
  },
  '83815': {
    zip: '83815',
    city: 'Coeur D Alene',
    state: 'ID',
    latitude: '47.725192',
    longitude: '-116.786915',
    timeZoneId: 'America/Los_Angeles',
  },
  '83816': {
    zip: '83816',
    city: 'Coeur D Alene',
    state: 'ID',
    latitude: '47.66727',
    longitude: '-116.779061',
    timeZoneId: 'America/Los_Angeles',
  },
  '83821': {
    zip: '83821',
    city: 'Coolin',
    state: 'ID',
    latitude: '48.498602',
    longitude: '-116.837884',
    timeZoneId: 'America/Los_Angeles',
  },
  '83822': {
    zip: '83822',
    city: 'Oldtown',
    state: 'ID',
    latitude: '48.182406',
    longitude: '-117.033951',
    timeZoneId: 'America/Los_Angeles',
  },
  '83823': {
    zip: '83823',
    city: 'Deary',
    state: 'ID',
    latitude: '46.7781',
    longitude: '-116.51532',
    timeZoneId: 'America/Los_Angeles',
  },
  '83824': {
    zip: '83824',
    city: 'Desmet',
    state: 'ID',
    latitude: '47.145012',
    longitude: '-116.916574',
    timeZoneId: 'America/Los_Angeles',
  },
  '83825': {
    zip: '83825',
    city: 'Dover',
    state: 'ID',
    latitude: '48.252165',
    longitude: '-116.600833',
    timeZoneId: 'America/Los_Angeles',
  },
  '83826': {
    zip: '83826',
    city: 'Eastport',
    state: 'ID',
    latitude: '48.991329',
    longitude: '-116.179173',
    timeZoneId: 'America/Los_Angeles',
  },
  '83827': {
    zip: '83827',
    city: 'Elk River',
    state: 'ID',
    latitude: '46.779323',
    longitude: '-116.178085',
    timeZoneId: 'America/Los_Angeles',
  },
  '83830': {
    zip: '83830',
    city: 'Fernwood',
    state: 'ID',
    latitude: '47.120761',
    longitude: '-116.366132',
    timeZoneId: 'America/Los_Angeles',
  },
  '83832': {
    zip: '83832',
    city: 'Genesee',
    state: 'ID',
    latitude: '46.556134',
    longitude: '-116.943048',
    timeZoneId: 'America/Los_Angeles',
  },
  '83833': {
    zip: '83833',
    city: 'Harrison',
    state: 'ID',
    latitude: '47.452307',
    longitude: '-116.775887',
    timeZoneId: 'America/Los_Angeles',
  },
  '83834': {
    zip: '83834',
    city: 'Harvard',
    state: 'ID',
    latitude: '46.943273',
    longitude: '-116.583704',
    timeZoneId: 'America/Los_Angeles',
  },
  '83835': {
    zip: '83835',
    city: 'Hayden',
    state: 'ID',
    latitude: '47.79066',
    longitude: '-116.76438',
    timeZoneId: 'America/Los_Angeles',
  },
  '83836': {
    zip: '83836',
    city: 'Hope',
    state: 'ID',
    latitude: '48.241015',
    longitude: '-116.292794',
    timeZoneId: 'America/Los_Angeles',
  },
  '83837': {
    zip: '83837',
    city: 'Kellogg',
    state: 'ID',
    latitude: '47.58504',
    longitude: '-116.1348',
    timeZoneId: 'America/Los_Angeles',
  },
  '83839': {
    zip: '83839',
    city: 'Kingston',
    state: 'ID',
    latitude: '47.55186',
    longitude: '-116.29062',
    timeZoneId: 'America/Los_Angeles',
  },
  '83840': {
    zip: '83840',
    city: 'Kootenai',
    state: 'ID',
    latitude: '48.3102',
    longitude: '-116.5125',
    timeZoneId: 'America/Los_Angeles',
  },
  '83841': {
    zip: '83841',
    city: 'Laclede',
    state: 'ID',
    latitude: '48.186274',
    longitude: '-116.904565',
    timeZoneId: 'America/Los_Angeles',
  },
  '83842': {
    zip: '83842',
    city: 'Medimont',
    state: 'ID',
    latitude: '47.437822',
    longitude: '-116.535665',
    timeZoneId: 'America/Los_Angeles',
  },
  '83843': {
    zip: '83843',
    city: 'Moscow',
    state: 'ID',
    latitude: '46.728363',
    longitude: '-116.968109',
    timeZoneId: 'America/Los_Angeles',
  },
  '83844': {
    zip: '83844',
    city: 'Moscow',
    state: 'ID',
    latitude: '46.730287',
    longitude: '-116.996847',
    timeZoneId: 'America/Los_Angeles',
  },
  '83845': {
    zip: '83845',
    city: 'Moyie Springs',
    state: 'ID',
    latitude: '48.698926',
    longitude: '-116.305595',
    timeZoneId: 'America/Los_Angeles',
  },
  '83846': {
    zip: '83846',
    city: 'Mullan',
    state: 'ID',
    latitude: '47.469843',
    longitude: '-115.796934',
    timeZoneId: 'America/Los_Angeles',
  },
  '83847': {
    zip: '83847',
    city: 'Naples',
    state: 'ID',
    latitude: '48.694387',
    longitude: '-116.303452',
    timeZoneId: 'America/Los_Angeles',
  },
  '83848': {
    zip: '83848',
    city: 'Nordman',
    state: 'ID',
    latitude: '48.663925',
    longitude: '-116.94818',
    timeZoneId: 'America/Los_Angeles',
  },
  '83849': {
    zip: '83849',
    city: 'Osburn',
    state: 'ID',
    latitude: '47.507229',
    longitude: '-115.998526',
    timeZoneId: 'America/Los_Angeles',
  },
  '83850': {
    zip: '83850',
    city: 'Pinehurst',
    state: 'ID',
    latitude: '47.538336',
    longitude: '-116.235291',
    timeZoneId: 'America/Los_Angeles',
  },
  '83851': {
    zip: '83851',
    city: 'Plummer',
    state: 'ID',
    latitude: '47.341163',
    longitude: '-116.878709',
    timeZoneId: 'America/Los_Angeles',
  },
  '83852': {
    zip: '83852',
    city: 'Ponderay',
    state: 'ID',
    latitude: '48.305269',
    longitude: '-116.523954',
    timeZoneId: 'America/Los_Angeles',
  },
  '83853': {
    zip: '83853',
    city: 'Porthill',
    state: 'ID',
    latitude: '48.976571',
    longitude: '-116.478266',
    timeZoneId: 'America/Los_Angeles',
  },
  '83854': {
    zip: '83854',
    city: 'Post Falls',
    state: 'ID',
    latitude: '47.74092',
    longitude: '-116.9448',
    timeZoneId: 'America/Los_Angeles',
  },
  '83855': {
    zip: '83855',
    city: 'Potlatch',
    state: 'ID',
    latitude: '47.011509',
    longitude: '-116.928485',
    timeZoneId: 'America/Los_Angeles',
  },
  '83856': {
    zip: '83856',
    city: 'Priest River',
    state: 'ID',
    latitude: '48.186078',
    longitude: '-116.913115',
    timeZoneId: 'America/Los_Angeles',
  },
  '83857': {
    zip: '83857',
    city: 'Princeton',
    state: 'ID',
    latitude: '46.911973',
    longitude: '-116.812875',
    timeZoneId: 'America/Los_Angeles',
  },
  '83858': {
    zip: '83858',
    city: 'Rathdrum',
    state: 'ID',
    latitude: '47.84004',
    longitude: '-116.9031',
    timeZoneId: 'America/Los_Angeles',
  },
  '83860': {
    zip: '83860',
    city: 'Sagle',
    state: 'ID',
    latitude: '48.199188',
    longitude: '-116.56978',
    timeZoneId: 'America/Los_Angeles',
  },
  '83861': {
    zip: '83861',
    city: 'Saint Maries',
    state: 'ID',
    latitude: '47.316709',
    longitude: '-116.572684',
    timeZoneId: 'America/Los_Angeles',
  },
  '83864': {
    zip: '83864',
    city: 'Sandpoint',
    state: 'ID',
    latitude: '48.299419',
    longitude: '-116.535216',
    timeZoneId: 'America/Los_Angeles',
  },
  '83865': {
    zip: '83865',
    city: 'Colburn',
    state: 'ID',
    latitude: '48.399606',
    longitude: '-116.537553',
    timeZoneId: 'America/Los_Angeles',
  },
  '83866': {
    zip: '83866',
    city: 'Santa',
    state: 'ID',
    latitude: '47.240469',
    longitude: '-116.548417',
    timeZoneId: 'America/Los_Angeles',
  },
  '83867': {
    zip: '83867',
    city: 'Silverton',
    state: 'ID',
    latitude: '47.495375',
    longitude: '-115.955531',
    timeZoneId: 'America/Los_Angeles',
  },
  '83868': {
    zip: '83868',
    city: 'Smelterville',
    state: 'ID',
    latitude: '47.544033',
    longitude: '-116.181812',
    timeZoneId: 'America/Los_Angeles',
  },
  '83869': {
    zip: '83869',
    city: 'Spirit Lake',
    state: 'ID',
    latitude: '47.968207',
    longitude: '-116.854846',
    timeZoneId: 'America/Los_Angeles',
  },
  '83870': {
    zip: '83870',
    city: 'Tensed',
    state: 'ID',
    latitude: '47.183819',
    longitude: '-116.871974',
    timeZoneId: 'America/Los_Angeles',
  },
  '83871': {
    zip: '83871',
    city: 'Troy',
    state: 'ID',
    latitude: '46.740363',
    longitude: '-116.768003',
    timeZoneId: 'America/Los_Angeles',
  },
  '83872': {
    zip: '83872',
    city: 'Viola',
    state: 'ID',
    latitude: '46.861727',
    longitude: '-116.975585',
    timeZoneId: 'America/Los_Angeles',
  },
  '83873': {
    zip: '83873',
    city: 'Wallace',
    state: 'ID',
    latitude: '47.39652',
    longitude: '-115.94946',
    timeZoneId: 'America/Los_Angeles',
  },
  '83874': {
    zip: '83874',
    city: 'Murray',
    state: 'ID',
    latitude: '47.623381',
    longitude: '-115.857511',
    timeZoneId: 'America/Los_Angeles',
  },
  '83876': {
    zip: '83876',
    city: 'Worley',
    state: 'ID',
    latitude: '47.446445',
    longitude: '-116.913723',
    timeZoneId: 'America/Los_Angeles',
  },
  '83877': {
    zip: '83877',
    city: 'Post Falls',
    state: 'ID',
    latitude: '47.714861',
    longitude: '-116.945541',
    timeZoneId: 'America/Los_Angeles',
  },
  '84001': {
    zip: '84001',
    city: 'Altamont',
    state: 'UT',
    latitude: '40.398937',
    longitude: '-110.465521',
    timeZoneId: 'America/Denver',
  },
  '84002': {
    zip: '84002',
    city: 'Altonah',
    state: 'UT',
    latitude: '40.462397',
    longitude: '-110.4669',
    timeZoneId: 'America/Denver',
  },
  '84003': {
    zip: '84003',
    city: 'American Fork',
    state: 'UT',
    latitude: '40.41924',
    longitude: '-111.56526',
    timeZoneId: 'America/Denver',
  },
  '84004': {
    zip: '84004',
    city: 'Alpine',
    state: 'UT',
    latitude: '40.456346',
    longitude: '-111.76973',
    timeZoneId: 'America/Denver',
  },
  '84005': {
    zip: '84005',
    city: 'Eagle Mountain',
    state: 'UT',
    latitude: '40.352471',
    longitude: '-111.983329',
    timeZoneId: 'America/Denver',
  },
  '84006': {
    zip: '84006',
    city: 'Bingham Canyon',
    state: 'UT',
    latitude: '40.533333',
    longitude: '-112.15',
    timeZoneId: 'America/Denver',
  },
  '84007': {
    zip: '84007',
    city: 'Bluebell',
    state: 'UT',
    latitude: '40.336272',
    longitude: '-110.33241',
    timeZoneId: 'America/Denver',
  },
  '84008': {
    zip: '84008',
    city: 'Bonanza',
    state: 'UT',
    latitude: '40.019053',
    longitude: '-109.175359',
    timeZoneId: 'America/Denver',
  },
  '84009': {
    zip: '84009',
    city: 'South Jordan',
    state: 'UT',
    latitude: '40.559479',
    longitude: '-111.983028',
    timeZoneId: 'America/Denver',
  },
  '84010': {
    zip: '84010',
    city: 'Bountiful',
    state: 'UT',
    latitude: '40.871662',
    longitude: '-111.890486',
    timeZoneId: 'America/Denver',
  },
  '84011': {
    zip: '84011',
    city: 'Bountiful',
    state: 'UT',
    latitude: '40.875659',
    longitude: '-111.865622',
    timeZoneId: 'America/Denver',
  },
  '84013': {
    zip: '84013',
    city: 'Cedar Valley',
    state: 'UT',
    latitude: '40.24758',
    longitude: '-112.06206',
    timeZoneId: 'America/Denver',
  },
  '84014': {
    zip: '84014',
    city: 'Centerville',
    state: 'UT',
    latitude: '40.924885',
    longitude: '-111.877285',
    timeZoneId: 'America/Denver',
  },
  '84015': {
    zip: '84015',
    city: 'Clearfield',
    state: 'UT',
    latitude: '41.119274',
    longitude: '-112.028647',
    timeZoneId: 'America/Denver',
  },
  '84016': {
    zip: '84016',
    city: 'Clearfield',
    state: 'UT',
    latitude: '41.104372',
    longitude: '-112.020693',
    timeZoneId: 'America/Denver',
  },
  '84017': {
    zip: '84017',
    city: 'Coalville',
    state: 'UT',
    latitude: '40.80468',
    longitude: '-111.29634',
    timeZoneId: 'America/Denver',
  },
  '84018': {
    zip: '84018',
    city: 'Croydon',
    state: 'UT',
    latitude: '41.098081',
    longitude: '-111.501214',
    timeZoneId: 'America/Denver',
  },
  '84020': {
    zip: '84020',
    city: 'Draper',
    state: 'UT',
    latitude: '40.51108',
    longitude: '-111.870014',
    timeZoneId: 'America/Denver',
  },
  '84021': {
    zip: '84021',
    city: 'Duchesne',
    state: 'UT',
    latitude: '40.1697',
    longitude: '-110.398451',
    timeZoneId: 'America/Denver',
  },
  '84022': {
    zip: '84022',
    city: 'Dugway',
    state: 'UT',
    latitude: '40.28814',
    longitude: '-112.81746',
    timeZoneId: 'America/Denver',
  },
  '84023': {
    zip: '84023',
    city: 'Dutch John',
    state: 'UT',
    latitude: '40.932867',
    longitude: '-109.392096',
    timeZoneId: 'America/Denver',
  },
  '84024': {
    zip: '84024',
    city: 'Echo',
    state: 'UT',
    latitude: '40.9676',
    longitude: '-111.421819',
    timeZoneId: 'America/Denver',
  },
  '84025': {
    zip: '84025',
    city: 'Farmington',
    state: 'UT',
    latitude: '40.983682',
    longitude: '-111.870619',
    timeZoneId: 'America/Denver',
  },
  '84026': {
    zip: '84026',
    city: 'Fort Duchesne',
    state: 'UT',
    latitude: '40.294221',
    longitude: '-109.839602',
    timeZoneId: 'America/Denver',
  },
  '84027': {
    zip: '84027',
    city: 'Fruitland',
    state: 'UT',
    latitude: '40.210774',
    longitude: '-110.839417',
    timeZoneId: 'America/Denver',
  },
  '84028': {
    zip: '84028',
    city: 'Garden City',
    state: 'UT',
    latitude: '41.914447',
    longitude: '-111.400556',
    timeZoneId: 'America/Denver',
  },
  '84029': {
    zip: '84029',
    city: 'Grantsville',
    state: 'UT',
    latitude: '40.604525',
    longitude: '-112.45618',
    timeZoneId: 'America/Denver',
  },
  '84031': {
    zip: '84031',
    city: 'Hanna',
    state: 'UT',
    latitude: '40.436388',
    longitude: '-110.826187',
    timeZoneId: 'America/Denver',
  },
  '84032': {
    zip: '84032',
    city: 'Heber City',
    state: 'UT',
    latitude: '40.40178',
    longitude: '-111.2463',
    timeZoneId: 'America/Denver',
  },
  '84033': {
    zip: '84033',
    city: 'Henefer',
    state: 'UT',
    latitude: '41.020988',
    longitude: '-111.501774',
    timeZoneId: 'America/Denver',
  },
  '84034': {
    zip: '84034',
    city: 'Ibapah',
    state: 'UT',
    latitude: '40.037817',
    longitude: '-113.985828',
    timeZoneId: 'America/Denver',
  },
  '84035': {
    zip: '84035',
    city: 'Jensen',
    state: 'UT',
    latitude: '40.382255',
    longitude: '-109.314471',
    timeZoneId: 'America/Denver',
  },
  '84036': {
    zip: '84036',
    city: 'Kamas',
    state: 'UT',
    latitude: '40.645602',
    longitude: '-111.284311',
    timeZoneId: 'America/Denver',
  },
  '84037': {
    zip: '84037',
    city: 'Kaysville',
    state: 'UT',
    latitude: '41.031',
    longitude: '-111.942',
    timeZoneId: 'America/Denver',
  },
  '84038': {
    zip: '84038',
    city: 'Laketown',
    state: 'UT',
    latitude: '41.847331',
    longitude: '-111.346321',
    timeZoneId: 'America/Denver',
  },
  '84039': {
    zip: '84039',
    city: 'Lapoint',
    state: 'UT',
    latitude: '40.384027',
    longitude: '-109.797883',
    timeZoneId: 'America/Denver',
  },
  '84040': {
    zip: '84040',
    city: 'Layton',
    state: 'UT',
    latitude: '41.075677',
    longitude: '-111.891767',
    timeZoneId: 'America/Denver',
  },
  '84041': {
    zip: '84041',
    city: 'Layton',
    state: 'UT',
    latitude: '41.059983',
    longitude: '-112.008085',
    timeZoneId: 'America/Denver',
  },
  '84042': {
    zip: '84042',
    city: 'Lindon',
    state: 'UT',
    latitude: '40.344492',
    longitude: '-111.730542',
    timeZoneId: 'America/Denver',
  },
  '84043': {
    zip: '84043',
    city: 'Lehi',
    state: 'UT',
    latitude: '40.39361',
    longitude: '-111.846309',
    timeZoneId: 'America/Denver',
  },
  '84044': {
    zip: '84044',
    city: 'Magna',
    state: 'UT',
    latitude: '40.66949',
    longitude: '-112.140714',
    timeZoneId: 'America/Denver',
  },
  '84045': {
    zip: '84045',
    city: 'Saratoga Springs',
    state: 'UT',
    latitude: '40.345179',
    longitude: '-111.911529',
    timeZoneId: 'America/Denver',
  },
  '84046': {
    zip: '84046',
    city: 'Manila',
    state: 'UT',
    latitude: '40.992285',
    longitude: '-109.715572',
    timeZoneId: 'America/Denver',
  },
  '84047': {
    zip: '84047',
    city: 'Midvale',
    state: 'UT',
    latitude: '40.616513',
    longitude: '-111.879899',
    timeZoneId: 'America/Denver',
  },
  '84049': {
    zip: '84049',
    city: 'Midway',
    state: 'UT',
    latitude: '40.521857',
    longitude: '-111.468543',
    timeZoneId: 'America/Denver',
  },
  '84050': {
    zip: '84050',
    city: 'Morgan',
    state: 'UT',
    latitude: '41.02986',
    longitude: '-111.73188',
    timeZoneId: 'America/Denver',
  },
  '84051': {
    zip: '84051',
    city: 'Mountain Home',
    state: 'UT',
    latitude: '40.20855',
    longitude: '-110.74103',
    timeZoneId: 'America/Denver',
  },
  '84052': {
    zip: '84052',
    city: 'Myton',
    state: 'UT',
    latitude: '40.197437',
    longitude: '-110.058695',
    timeZoneId: 'America/Denver',
  },
  '84053': {
    zip: '84053',
    city: 'Neola',
    state: 'UT',
    latitude: '40.512629',
    longitude: '-110.096294',
    timeZoneId: 'America/Denver',
  },
  '84054': {
    zip: '84054',
    city: 'North Salt Lake',
    state: 'UT',
    latitude: '40.837299',
    longitude: '-111.919323',
    timeZoneId: 'America/Denver',
  },
  '84055': {
    zip: '84055',
    city: 'Oakley',
    state: 'UT',
    latitude: '40.711397',
    longitude: '-111.292326',
    timeZoneId: 'America/Denver',
  },
  '84056': {
    zip: '84056',
    city: 'Hill AFB',
    state: 'UT',
    latitude: '41.120889',
    longitude: '-111.982055',
    timeZoneId: 'America/Denver',
  },
  '84057': {
    zip: '84057',
    city: 'Orem',
    state: 'UT',
    latitude: '40.31514',
    longitude: '-111.69587',
    timeZoneId: 'America/Denver',
  },
  '84058': {
    zip: '84058',
    city: 'Orem',
    state: 'UT',
    latitude: '40.281391',
    longitude: '-111.69842',
    timeZoneId: 'America/Denver',
  },
  '84059': {
    zip: '84059',
    city: 'Vineyard',
    state: 'UT',
    latitude: '40.319582',
    longitude: '-111.722564',
    timeZoneId: 'America/Denver',
  },
  '84060': {
    zip: '84060',
    city: 'Park City',
    state: 'UT',
    latitude: '40.697533',
    longitude: '-111.526589',
    timeZoneId: 'America/Denver',
  },
  '84061': {
    zip: '84061',
    city: 'Peoa',
    state: 'UT',
    latitude: '40.714412',
    longitude: '-111.334069',
    timeZoneId: 'America/Denver',
  },
  '84062': {
    zip: '84062',
    city: 'Pleasant Grove',
    state: 'UT',
    latitude: '40.38462',
    longitude: '-111.73638',
    timeZoneId: 'America/Denver',
  },
  '84063': {
    zip: '84063',
    city: 'Randlett',
    state: 'UT',
    latitude: '40.211092',
    longitude: '-109.729363',
    timeZoneId: 'America/Denver',
  },
  '84064': {
    zip: '84064',
    city: 'Randolph',
    state: 'UT',
    latitude: '41.696212',
    longitude: '-111.151274',
    timeZoneId: 'America/Denver',
  },
  '84065': {
    zip: '84065',
    city: 'Riverton',
    state: 'UT',
    latitude: '40.535262',
    longitude: '-111.962723',
    timeZoneId: 'America/Denver',
  },
  '84066': {
    zip: '84066',
    city: 'Roosevelt',
    state: 'UT',
    latitude: '40.298824',
    longitude: '-109.998125',
    timeZoneId: 'America/Denver',
  },
  '84067': {
    zip: '84067',
    city: 'Roy',
    state: 'UT',
    latitude: '41.16996',
    longitude: '-112.046185',
    timeZoneId: 'America/Denver',
  },
  '84068': {
    zip: '84068',
    city: 'Park City',
    state: 'UT',
    latitude: '40.671636',
    longitude: '-111.50012',
    timeZoneId: 'America/Denver',
  },
  '84069': {
    zip: '84069',
    city: 'Rush Valley',
    state: 'UT',
    latitude: '40.346188',
    longitude: '-112.454949',
    timeZoneId: 'America/Denver',
  },
  '84070': {
    zip: '84070',
    city: 'Sandy',
    state: 'UT',
    latitude: '40.571551',
    longitude: '-111.884711',
    timeZoneId: 'America/Denver',
  },
  '84071': {
    zip: '84071',
    city: 'Stockton',
    state: 'UT',
    latitude: '40.449676',
    longitude: '-112.359074',
    timeZoneId: 'America/Denver',
  },
  '84072': {
    zip: '84072',
    city: 'Tabiona',
    state: 'UT',
    latitude: '40.374733',
    longitude: '-110.677193',
    timeZoneId: 'America/Denver',
  },
  '84073': {
    zip: '84073',
    city: 'Talmage',
    state: 'UT',
    latitude: '40.174992',
    longitude: '-110.393823',
    timeZoneId: 'America/Denver',
  },
  '84074': {
    zip: '84074',
    city: 'Tooele',
    state: 'UT',
    latitude: '40.57128',
    longitude: '-112.31862',
    timeZoneId: 'America/Denver',
  },
  '84075': {
    zip: '84075',
    city: 'Syracuse',
    state: 'UT',
    latitude: '41.082208',
    longitude: '-112.075504',
    timeZoneId: 'America/Denver',
  },
  '84076': {
    zip: '84076',
    city: 'Tridell',
    state: 'UT',
    latitude: '40.46681',
    longitude: '-109.813447',
    timeZoneId: 'America/Denver',
  },
  '84078': {
    zip: '84078',
    city: 'Vernal',
    state: 'UT',
    latitude: '40.47432',
    longitude: '-109.570104',
    timeZoneId: 'America/Denver',
  },
  '84079': {
    zip: '84079',
    city: 'Vernal',
    state: 'UT',
    latitude: '40.451292',
    longitude: '-109.536992',
    timeZoneId: 'America/Denver',
  },
  '84080': {
    zip: '84080',
    city: 'Vernon',
    state: 'UT',
    latitude: '40.036117',
    longitude: '-112.388825',
    timeZoneId: 'America/Denver',
  },
  '84081': {
    zip: '84081',
    city: 'West Jordan',
    state: 'UT',
    latitude: '40.618845',
    longitude: '-111.967345',
    timeZoneId: 'America/Denver',
  },
  '84082': {
    zip: '84082',
    city: 'Wallsburg',
    state: 'UT',
    latitude: '40.374397',
    longitude: '-111.402007',
    timeZoneId: 'America/Denver',
  },
  '84083': {
    zip: '84083',
    city: 'Wendover',
    state: 'UT',
    latitude: '40.7381',
    longitude: '-114.021398',
    timeZoneId: 'America/Denver',
  },
  '84084': {
    zip: '84084',
    city: 'West Jordan',
    state: 'UT',
    latitude: '40.618542',
    longitude: '-111.967531',
    timeZoneId: 'America/Denver',
  },
  '84085': {
    zip: '84085',
    city: 'Whiterocks',
    state: 'UT',
    latitude: '40.486689',
    longitude: '-109.917677',
    timeZoneId: 'America/Denver',
  },
  '84086': {
    zip: '84086',
    city: 'Woodruff',
    state: 'UT',
    latitude: '41.490256',
    longitude: '-111.16008',
    timeZoneId: 'America/Denver',
  },
  '84087': {
    zip: '84087',
    city: 'Woods Cross',
    state: 'UT',
    latitude: '40.88856',
    longitude: '-111.91224',
    timeZoneId: 'America/Denver',
  },
  '84088': {
    zip: '84088',
    city: 'West Jordan',
    state: 'UT',
    latitude: '40.59673',
    longitude: '-112.001045',
    timeZoneId: 'America/Denver',
  },
  '84089': {
    zip: '84089',
    city: 'Clearfield',
    state: 'UT',
    latitude: '41.11073',
    longitude: '-112.024956',
    timeZoneId: 'America/Denver',
  },
  '84090': {
    zip: '84090',
    city: 'Sandy',
    state: 'UT',
    latitude: '40.572386',
    longitude: '-111.852583',
    timeZoneId: 'America/Denver',
  },
  '84091': {
    zip: '84091',
    city: 'Sandy',
    state: 'UT',
    latitude: '40.584648',
    longitude: '-111.821231',
    timeZoneId: 'America/Denver',
  },
  '84092': {
    zip: '84092',
    city: 'Sandy',
    state: 'UT',
    latitude: '40.56696',
    longitude: '-111.81456',
    timeZoneId: 'America/Denver',
  },
  '84093': {
    zip: '84093',
    city: 'Sandy',
    state: 'UT',
    latitude: '40.590896',
    longitude: '-111.828061',
    timeZoneId: 'America/Denver',
  },
  '84094': {
    zip: '84094',
    city: 'Sandy',
    state: 'UT',
    latitude: '40.563547',
    longitude: '-111.859471',
    timeZoneId: 'America/Denver',
  },
  '84095': {
    zip: '84095',
    city: 'South Jordan',
    state: 'UT',
    latitude: '40.569297',
    longitude: '-111.967229',
    timeZoneId: 'America/Denver',
  },
  '84096': {
    zip: '84096',
    city: 'Herriman',
    state: 'UT',
    latitude: '40.569038',
    longitude: '-111.96655',
    timeZoneId: 'America/Denver',
  },
  '84097': {
    zip: '84097',
    city: 'Orem',
    state: 'UT',
    latitude: '40.306409',
    longitude: '-111.675292',
    timeZoneId: 'America/Denver',
  },
  '84098': {
    zip: '84098',
    city: 'Park City',
    state: 'UT',
    latitude: '40.65751',
    longitude: '-111.495033',
    timeZoneId: 'America/Denver',
  },
  '84101': {
    zip: '84101',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.756416',
    longitude: '-111.89907',
    timeZoneId: 'America/Denver',
  },
  '84102': {
    zip: '84102',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.758805',
    longitude: '-111.865417',
    timeZoneId: 'America/Denver',
  },
  '84103': {
    zip: '84103',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.783965',
    longitude: '-111.876047',
    timeZoneId: 'America/Denver',
  },
  '84104': {
    zip: '84104',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.74985',
    longitude: '-111.934638',
    timeZoneId: 'America/Denver',
  },
  '84105': {
    zip: '84105',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.734214',
    longitude: '-111.856179',
    timeZoneId: 'America/Denver',
  },
  '84106': {
    zip: '84106',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.702966',
    longitude: '-111.856027',
    timeZoneId: 'America/Denver',
  },
  '84107': {
    zip: '84107',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.65846',
    longitude: '-111.881914',
    timeZoneId: 'America/Denver',
  },
  '84108': {
    zip: '84108',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.738359',
    longitude: '-111.812479',
    timeZoneId: 'America/Denver',
  },
  '84109': {
    zip: '84109',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.701306',
    longitude: '-111.815899',
    timeZoneId: 'America/Denver',
  },
  '84110': {
    zip: '84110',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.756105',
    longitude: '-111.897245',
    timeZoneId: 'America/Denver',
  },
  '84111': {
    zip: '84111',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.75346',
    longitude: '-111.879217',
    timeZoneId: 'America/Denver',
  },
  '84112': {
    zip: '84112',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.763779',
    longitude: '-111.838359',
    timeZoneId: 'America/Denver',
  },
  '84113': {
    zip: '84113',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.775548',
    longitude: '-111.831028',
    timeZoneId: 'America/Denver',
  },
  '84114': {
    zip: '84114',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.775628',
    longitude: '-111.891834',
    timeZoneId: 'America/Denver',
  },
  '84115': {
    zip: '84115',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.713441',
    longitude: '-111.889049',
    timeZoneId: 'America/Denver',
  },
  '84116': {
    zip: '84116',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.785043',
    longitude: '-111.930074',
    timeZoneId: 'America/Denver',
  },
  '84117': {
    zip: '84117',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.665128',
    longitude: '-111.835513',
    timeZoneId: 'America/Denver',
  },
  '84118': {
    zip: '84118',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.654603',
    longitude: '-111.984034',
    timeZoneId: 'America/Denver',
  },
  '84119': {
    zip: '84119',
    city: 'West Valley City',
    state: 'UT',
    latitude: '40.692079',
    longitude: '-111.947832',
    timeZoneId: 'America/Denver',
  },
  '84120': {
    zip: '84120',
    city: 'West Valley City',
    state: 'UT',
    latitude: '40.69656',
    longitude: '-112.01748',
    timeZoneId: 'America/Denver',
  },
  '84121': {
    zip: '84121',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.622101',
    longitude: '-111.82424',
    timeZoneId: 'America/Denver',
  },
  '84122': {
    zip: '84122',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.798588',
    longitude: '-111.960684',
    timeZoneId: 'America/Denver',
  },
  '84123': {
    zip: '84123',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.659098',
    longitude: '-111.923072',
    timeZoneId: 'America/Denver',
  },
  '84124': {
    zip: '84124',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.685813',
    longitude: '-111.829118',
    timeZoneId: 'America/Denver',
  },
  '84125': {
    zip: '84125',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.701548',
    longitude: '-111.94591',
    timeZoneId: 'America/Denver',
  },
  '84126': {
    zip: '84126',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.700114',
    longitude: '-111.948523',
    timeZoneId: 'America/Denver',
  },
  '84127': {
    zip: '84127',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.700486',
    longitude: '-111.945715',
    timeZoneId: 'America/Denver',
  },
  '84128': {
    zip: '84128',
    city: 'West Valley City',
    state: 'UT',
    latitude: '40.704442',
    longitude: '-112.04452',
    timeZoneId: 'America/Denver',
  },
  '84129': {
    zip: '84129',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.696347',
    longitude: '-112.017349',
    timeZoneId: 'America/Denver',
  },
  '84130': {
    zip: '84130',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.70077',
    longitude: '-111.94711',
    timeZoneId: 'America/Denver',
  },
  '84131': {
    zip: '84131',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.700156',
    longitude: '-111.944519',
    timeZoneId: 'America/Denver',
  },
  '84132': {
    zip: '84132',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.765465',
    longitude: '-111.840905',
    timeZoneId: 'America/Denver',
  },
  '84133': {
    zip: '84133',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.770852',
    longitude: '-111.892118',
    timeZoneId: 'America/Denver',
  },
  '84134': {
    zip: '84134',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.774204',
    longitude: '-111.94634',
    timeZoneId: 'America/Denver',
  },
  '84138': {
    zip: '84138',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.752433',
    longitude: '-111.880834',
    timeZoneId: 'America/Denver',
  },
  '84139': {
    zip: '84139',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.768936',
    longitude: '-111.887445',
    timeZoneId: 'America/Denver',
  },
  '84141': {
    zip: '84141',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.668129',
    longitude: '-111.861408',
    timeZoneId: 'America/Denver',
  },
  '84143': {
    zip: '84143',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.779354',
    longitude: '-111.880031',
    timeZoneId: 'America/Denver',
  },
  '84145': {
    zip: '84145',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.754454',
    longitude: '-111.897732',
    timeZoneId: 'America/Denver',
  },
  '84147': {
    zip: '84147',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.758841',
    longitude: '-111.883105',
    timeZoneId: 'America/Denver',
  },
  '84148': {
    zip: '84148',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.755635',
    longitude: '-111.835281',
    timeZoneId: 'America/Denver',
  },
  '84150': {
    zip: '84150',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.772019',
    longitude: '-111.889801',
    timeZoneId: 'America/Denver',
  },
  '84151': {
    zip: '84151',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.787872',
    longitude: '-111.938789',
    timeZoneId: 'America/Denver',
  },
  '84152': {
    zip: '84152',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.725327',
    longitude: '-111.859692',
    timeZoneId: 'America/Denver',
  },
  '84157': {
    zip: '84157',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.660687',
    longitude: '-111.884858',
    timeZoneId: 'America/Denver',
  },
  '84158': {
    zip: '84158',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.739667',
    longitude: '-111.817677',
    timeZoneId: 'America/Denver',
  },
  '84165': {
    zip: '84165',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.714872',
    longitude: '-111.892415',
    timeZoneId: 'America/Denver',
  },
  '84170': {
    zip: '84170',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.701243',
    longitude: '-112.010773',
    timeZoneId: 'America/Denver',
  },
  '84171': {
    zip: '84171',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.620584',
    longitude: '-111.806052',
    timeZoneId: 'America/Denver',
  },
  '84180': {
    zip: '84180',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.767274',
    longitude: '-111.898078',
    timeZoneId: 'America/Denver',
  },
  '84184': {
    zip: '84184',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.679483',
    longitude: '-111.954813',
    timeZoneId: 'America/Denver',
  },
  '84190': {
    zip: '84190',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.725005',
    longitude: '-111.886915',
    timeZoneId: 'America/Denver',
  },
  '84199': {
    zip: '84199',
    city: 'Salt Lake City',
    state: 'UT',
    latitude: '40.725101',
    longitude: '-111.939531',
    timeZoneId: 'America/Denver',
  },
  '84201': {
    zip: '84201',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.244252',
    longitude: '-112.000722',
    timeZoneId: 'America/Denver',
  },
  '84244': {
    zip: '84244',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.260364',
    longitude: '-111.970792',
    timeZoneId: 'America/Denver',
  },
  '84301': {
    zip: '84301',
    city: 'Bear River City',
    state: 'UT',
    latitude: '41.59487',
    longitude: '-112.127635',
    timeZoneId: 'America/Denver',
  },
  '84302': {
    zip: '84302',
    city: 'Brigham City',
    state: 'UT',
    latitude: '41.53608',
    longitude: '-112.0488',
    timeZoneId: 'America/Denver',
  },
  '84304': {
    zip: '84304',
    city: 'Cache Junction',
    state: 'UT',
    latitude: '41.832974',
    longitude: '-112.00203',
    timeZoneId: 'America/Denver',
  },
  '84305': {
    zip: '84305',
    city: 'Clarkston',
    state: 'UT',
    latitude: '41.919228',
    longitude: '-112.037397',
    timeZoneId: 'America/Denver',
  },
  '84306': {
    zip: '84306',
    city: 'Collinston',
    state: 'UT',
    latitude: '41.787743',
    longitude: '-112.07273',
    timeZoneId: 'America/Denver',
  },
  '84307': {
    zip: '84307',
    city: 'Corinne',
    state: 'UT',
    latitude: '41.40642',
    longitude: '-112.29396',
    timeZoneId: 'America/Denver',
  },
  '84308': {
    zip: '84308',
    city: 'Cornish',
    state: 'UT',
    latitude: '41.967723',
    longitude: '-112.004942',
    timeZoneId: 'America/Denver',
  },
  '84309': {
    zip: '84309',
    city: 'Deweyville',
    state: 'UT',
    latitude: '41.707724',
    longitude: '-112.092685',
    timeZoneId: 'America/Denver',
  },
  '84310': {
    zip: '84310',
    city: 'Eden',
    state: 'UT',
    latitude: '41.338807',
    longitude: '-111.865237',
    timeZoneId: 'America/Denver',
  },
  '84311': {
    zip: '84311',
    city: 'Fielding',
    state: 'UT',
    latitude: '41.812797',
    longitude: '-112.121505',
    timeZoneId: 'America/Denver',
  },
  '84312': {
    zip: '84312',
    city: 'Garland',
    state: 'UT',
    latitude: '41.746854',
    longitude: '-112.149193',
    timeZoneId: 'America/Denver',
  },
  '84313': {
    zip: '84313',
    city: 'Grouse Creek',
    state: 'UT',
    latitude: '41.44169',
    longitude: '-113.769269',
    timeZoneId: 'America/Denver',
  },
  '84314': {
    zip: '84314',
    city: 'Honeyville',
    state: 'UT',
    latitude: '41.641228',
    longitude: '-112.090819',
    timeZoneId: 'America/Denver',
  },
  '84315': {
    zip: '84315',
    city: 'Hooper',
    state: 'UT',
    latitude: '41.175095',
    longitude: '-112.082261',
    timeZoneId: 'America/Denver',
  },
  '84316': {
    zip: '84316',
    city: 'Howell',
    state: 'UT',
    latitude: '41.78334',
    longitude: '-112.429895',
    timeZoneId: 'America/Denver',
  },
  '84317': {
    zip: '84317',
    city: 'Huntsville',
    state: 'UT',
    latitude: '41.287874',
    longitude: '-111.684215',
    timeZoneId: 'America/Denver',
  },
  '84318': {
    zip: '84318',
    city: 'Hyde Park',
    state: 'UT',
    latitude: '41.798642',
    longitude: '-111.816026',
    timeZoneId: 'America/Denver',
  },
  '84319': {
    zip: '84319',
    city: 'Hyrum',
    state: 'UT',
    latitude: '41.632165',
    longitude: '-111.835497',
    timeZoneId: 'America/Denver',
  },
  '84320': {
    zip: '84320',
    city: 'Lewiston',
    state: 'UT',
    latitude: '41.9622',
    longitude: '-111.87348',
    timeZoneId: 'America/Denver',
  },
  '84321': {
    zip: '84321',
    city: 'Logan',
    state: 'UT',
    latitude: '41.73648',
    longitude: '-111.6738',
    timeZoneId: 'America/Denver',
  },
  '84322': {
    zip: '84322',
    city: 'Logan',
    state: 'UT',
    latitude: '41.746323',
    longitude: '-111.810684',
    timeZoneId: 'America/Denver',
  },
  '84323': {
    zip: '84323',
    city: 'Logan',
    state: 'UT',
    latitude: '41.684762',
    longitude: '-111.649874',
    timeZoneId: 'America/Denver',
  },
  '84324': {
    zip: '84324',
    city: 'Mantua',
    state: 'UT',
    latitude: '41.494534',
    longitude: '-111.938697',
    timeZoneId: 'America/Denver',
  },
  '84325': {
    zip: '84325',
    city: 'Mendon',
    state: 'UT',
    latitude: '41.74998',
    longitude: '-111.98106',
    timeZoneId: 'America/Denver',
  },
  '84326': {
    zip: '84326',
    city: 'Millville',
    state: 'UT',
    latitude: '41.683925',
    longitude: '-111.820524',
    timeZoneId: 'America/Denver',
  },
  '84327': {
    zip: '84327',
    city: 'Newton',
    state: 'UT',
    latitude: '41.863903',
    longitude: '-111.989872',
    timeZoneId: 'America/Denver',
  },
  '84328': {
    zip: '84328',
    city: 'Paradise',
    state: 'UT',
    latitude: '41.55666',
    longitude: '-111.81834',
    timeZoneId: 'America/Denver',
  },
  '84329': {
    zip: '84329',
    city: 'Park Valley',
    state: 'UT',
    latitude: '41.82721',
    longitude: '-113.26945',
    timeZoneId: 'America/Denver',
  },
  '84330': {
    zip: '84330',
    city: 'Plymouth',
    state: 'UT',
    latitude: '41.909014',
    longitude: '-112.15714',
    timeZoneId: 'America/Denver',
  },
  '84331': {
    zip: '84331',
    city: 'Portage',
    state: 'UT',
    latitude: '41.975569',
    longitude: '-112.19116',
    timeZoneId: 'America/Denver',
  },
  '84332': {
    zip: '84332',
    city: 'Providence',
    state: 'UT',
    latitude: '41.699994',
    longitude: '-111.806147',
    timeZoneId: 'America/Denver',
  },
  '84333': {
    zip: '84333',
    city: 'Richmond',
    state: 'UT',
    latitude: '41.920704',
    longitude: '-111.806595',
    timeZoneId: 'America/Denver',
  },
  '84334': {
    zip: '84334',
    city: 'Riverside',
    state: 'UT',
    latitude: '41.785486',
    longitude: '-112.132417',
    timeZoneId: 'America/Denver',
  },
  '84335': {
    zip: '84335',
    city: 'Smithfield',
    state: 'UT',
    latitude: '41.83584',
    longitude: '-111.87096',
    timeZoneId: 'America/Denver',
  },
  '84336': {
    zip: '84336',
    city: 'Snowville',
    state: 'UT',
    latitude: '41.839382',
    longitude: '-112.595114',
    timeZoneId: 'America/Denver',
  },
  '84337': {
    zip: '84337',
    city: 'Tremonton',
    state: 'UT',
    latitude: '41.67642',
    longitude: '-112.2678',
    timeZoneId: 'America/Denver',
  },
  '84338': {
    zip: '84338',
    city: 'Trenton',
    state: 'UT',
    latitude: '41.929311',
    longitude: '-111.93273',
    timeZoneId: 'America/Denver',
  },
  '84339': {
    zip: '84339',
    city: 'Wellsville',
    state: 'UT',
    latitude: '41.64504',
    longitude: '-111.91806',
    timeZoneId: 'America/Denver',
  },
  '84340': {
    zip: '84340',
    city: 'Willard',
    state: 'UT',
    latitude: '41.403126',
    longitude: '-112.0361',
    timeZoneId: 'America/Denver',
  },
  '84341': {
    zip: '84341',
    city: 'Logan',
    state: 'UT',
    latitude: '41.781188',
    longitude: '-111.822443',
    timeZoneId: 'America/Denver',
  },
  '84401': {
    zip: '84401',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.2198',
    longitude: '-112.016418',
    timeZoneId: 'America/Denver',
  },
  '84402': {
    zip: '84402',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.223691',
    longitude: '-112.021721',
    timeZoneId: 'America/Denver',
  },
  '84403': {
    zip: '84403',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.17818',
    longitude: '-111.96354',
    timeZoneId: 'America/Denver',
  },
  '84404': {
    zip: '84404',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.276379',
    longitude: '-111.987441',
    timeZoneId: 'America/Denver',
  },
  '84405': {
    zip: '84405',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.180942',
    longitude: '-111.970409',
    timeZoneId: 'America/Denver',
  },
  '84407': {
    zip: '84407',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.243163',
    longitude: '-111.967258',
    timeZoneId: 'America/Denver',
  },
  '84408': {
    zip: '84408',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.188154',
    longitude: '-111.942473',
    timeZoneId: 'America/Denver',
  },
  '84409': {
    zip: '84409',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.189279',
    longitude: '-111.946572',
    timeZoneId: 'America/Denver',
  },
  '84412': {
    zip: '84412',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.300877',
    longitude: '-111.946406',
    timeZoneId: 'America/Denver',
  },
  '84414': {
    zip: '84414',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.315661',
    longitude: '-111.978351',
    timeZoneId: 'America/Denver',
  },
  '84415': {
    zip: '84415',
    city: 'Ogden',
    state: 'UT',
    latitude: '41.222904',
    longitude: '-111.971747',
    timeZoneId: 'America/Denver',
  },
  '84501': {
    zip: '84501',
    city: 'Price',
    state: 'UT',
    latitude: '39.35994',
    longitude: '-110.94456',
    timeZoneId: 'America/Denver',
  },
  '84510': {
    zip: '84510',
    city: 'Aneth',
    state: 'UT',
    latitude: '37.210842',
    longitude: '-109.289967',
    timeZoneId: 'America/Denver',
  },
  '84511': {
    zip: '84511',
    city: 'Blanding',
    state: 'UT',
    latitude: '37.644259',
    longitude: '-109.463425',
    timeZoneId: 'America/Denver',
  },
  '84512': {
    zip: '84512',
    city: 'Bluff',
    state: 'UT',
    latitude: '37.280282',
    longitude: '-109.568737',
    timeZoneId: 'America/Denver',
  },
  '84513': {
    zip: '84513',
    city: 'Castle Dale',
    state: 'UT',
    latitude: '39.215189',
    longitude: '-111.028483',
    timeZoneId: 'America/Denver',
  },
  '84515': {
    zip: '84515',
    city: 'Cisco',
    state: 'UT',
    latitude: '38.973058',
    longitude: '-109.317915',
    timeZoneId: 'America/Denver',
  },
  '84516': {
    zip: '84516',
    city: 'Clawson',
    state: 'UT',
    latitude: '39.134526',
    longitude: '-111.09758',
    timeZoneId: 'America/Denver',
  },
  '84518': {
    zip: '84518',
    city: 'Cleveland',
    state: 'UT',
    latitude: '39.34786',
    longitude: '-110.850481',
    timeZoneId: 'America/Denver',
  },
  '84520': {
    zip: '84520',
    city: 'East Carbon',
    state: 'UT',
    latitude: '39.543972',
    longitude: '-110.414477',
    timeZoneId: 'America/Denver',
  },
  '84521': {
    zip: '84521',
    city: 'Elmo',
    state: 'UT',
    latitude: '39.392279',
    longitude: '-110.818565',
    timeZoneId: 'America/Denver',
  },
  '84522': {
    zip: '84522',
    city: 'Emery',
    state: 'UT',
    latitude: '38.920269',
    longitude: '-111.251922',
    timeZoneId: 'America/Denver',
  },
  '84523': {
    zip: '84523',
    city: 'Ferron',
    state: 'UT',
    latitude: '39.089869',
    longitude: '-111.126484',
    timeZoneId: 'America/Denver',
  },
  '84525': {
    zip: '84525',
    city: 'Green River',
    state: 'UT',
    latitude: '39.000232',
    longitude: '-110.160774',
    timeZoneId: 'America/Denver',
  },
  '84526': {
    zip: '84526',
    city: 'Helper',
    state: 'UT',
    latitude: '39.679255',
    longitude: '-110.852494',
    timeZoneId: 'America/Denver',
  },
  '84528': {
    zip: '84528',
    city: 'Huntington',
    state: 'UT',
    latitude: '39.3156',
    longitude: '-110.98068',
    timeZoneId: 'America/Denver',
  },
  '84529': {
    zip: '84529',
    city: 'Kenilworth',
    state: 'UT',
    latitude: '39.689784',
    longitude: '-110.807257',
    timeZoneId: 'America/Denver',
  },
  '84530': {
    zip: '84530',
    city: 'La Sal',
    state: 'UT',
    latitude: '38.311013',
    longitude: '-109.255668',
    timeZoneId: 'America/Denver',
  },
  '84531': {
    zip: '84531',
    city: 'Mexican Hat',
    state: 'UT',
    latitude: '37.090427',
    longitude: '-109.963375',
    timeZoneId: 'America/Denver',
  },
  '84532': {
    zip: '84532',
    city: 'Moab',
    state: 'UT',
    latitude: '38.554444',
    longitude: '-109.52434',
    timeZoneId: 'America/Denver',
  },
  '84533': {
    zip: '84533',
    city: 'Lake Powell',
    state: 'UT',
    latitude: '37.66867',
    longitude: '-110.698851',
    timeZoneId: 'America/Denver',
  },
  '84534': {
    zip: '84534',
    city: 'Montezuma Creek',
    state: 'UT',
    latitude: '37.258327',
    longitude: '-109.313639',
    timeZoneId: 'America/Denver',
  },
  '84535': {
    zip: '84535',
    city: 'Monticello',
    state: 'UT',
    latitude: '37.869249',
    longitude: '-109.348501',
    timeZoneId: 'America/Denver',
  },
  '84536': {
    zip: '84536',
    city: 'Monument Valley',
    state: 'UT',
    latitude: '37.154424',
    longitude: '-110.741712',
    timeZoneId: 'America/Denver',
  },
  '84537': {
    zip: '84537',
    city: 'Orangeville',
    state: 'UT',
    latitude: '39.232973',
    longitude: '-111.055146',
    timeZoneId: 'America/Denver',
  },
  '84539': {
    zip: '84539',
    city: 'Sunnyside',
    state: 'UT',
    latitude: '39.549386',
    longitude: '-110.388166',
    timeZoneId: 'America/Denver',
  },
  '84540': {
    zip: '84540',
    city: 'Thompson',
    state: 'UT',
    latitude: '39.035892',
    longitude: '-109.696213',
    timeZoneId: 'America/Denver',
  },
  '84542': {
    zip: '84542',
    city: 'Wellington',
    state: 'UT',
    latitude: '39.551983',
    longitude: '-110.731494',
    timeZoneId: 'America/Denver',
  },
  '84601': {
    zip: '84601',
    city: 'Provo',
    state: 'UT',
    latitude: '40.221703',
    longitude: '-111.692438',
    timeZoneId: 'America/Denver',
  },
  '84602': {
    zip: '84602',
    city: 'Provo',
    state: 'UT',
    latitude: '40.226908',
    longitude: '-111.6914',
    timeZoneId: 'America/Denver',
  },
  '84603': {
    zip: '84603',
    city: 'Provo',
    state: 'UT',
    latitude: '40.225947',
    longitude: '-111.699351',
    timeZoneId: 'America/Denver',
  },
  '84604': {
    zip: '84604',
    city: 'Provo',
    state: 'UT',
    latitude: '40.264063',
    longitude: '-111.623041',
    timeZoneId: 'America/Denver',
  },
  '84605': {
    zip: '84605',
    city: 'Provo',
    state: 'UT',
    latitude: '40.226387',
    longitude: '-111.677308',
    timeZoneId: 'America/Denver',
  },
  '84606': {
    zip: '84606',
    city: 'Provo',
    state: 'UT',
    latitude: '40.215754',
    longitude: '-111.634446',
    timeZoneId: 'America/Denver',
  },
  '84620': {
    zip: '84620',
    city: 'Aurora',
    state: 'UT',
    latitude: '38.92788',
    longitude: '-111.919505',
    timeZoneId: 'America/Denver',
  },
  '84621': {
    zip: '84621',
    city: 'Axtell',
    state: 'UT',
    latitude: '39.118389',
    longitude: '-111.773799',
    timeZoneId: 'America/Denver',
  },
  '84622': {
    zip: '84622',
    city: 'Centerfield',
    state: 'UT',
    latitude: '39.121114',
    longitude: '-111.818428',
    timeZoneId: 'America/Denver',
  },
  '84623': {
    zip: '84623',
    city: 'Chester',
    state: 'UT',
    latitude: '39.475304',
    longitude: '-111.561202',
    timeZoneId: 'America/Denver',
  },
  '84624': {
    zip: '84624',
    city: 'Delta',
    state: 'UT',
    latitude: '39.362915',
    longitude: '-112.574399',
    timeZoneId: 'America/Denver',
  },
  '84626': {
    zip: '84626',
    city: 'Elberta',
    state: 'UT',
    latitude: '40.162382',
    longitude: '-112.031361',
    timeZoneId: 'America/Denver',
  },
  '84627': {
    zip: '84627',
    city: 'Ephraim',
    state: 'UT',
    latitude: '39.324313',
    longitude: '-111.614299',
    timeZoneId: 'America/Denver',
  },
  '84628': {
    zip: '84628',
    city: 'Eureka',
    state: 'UT',
    latitude: '39.95345',
    longitude: '-112.113013',
    timeZoneId: 'America/Denver',
  },
  '84629': {
    zip: '84629',
    city: 'Fairview',
    state: 'UT',
    latitude: '39.85482',
    longitude: '-111.44532',
    timeZoneId: 'America/Denver',
  },
  '84630': {
    zip: '84630',
    city: 'Fayette',
    state: 'UT',
    latitude: '39.220217',
    longitude: '-111.869307',
    timeZoneId: 'America/Denver',
  },
  '84631': {
    zip: '84631',
    city: 'Fillmore',
    state: 'UT',
    latitude: '38.901194',
    longitude: '-112.632166',
    timeZoneId: 'America/Denver',
  },
  '84632': {
    zip: '84632',
    city: 'Fountain Green',
    state: 'UT',
    latitude: '39.536945',
    longitude: '-111.680191',
    timeZoneId: 'America/Denver',
  },
  '84633': {
    zip: '84633',
    city: 'Goshen',
    state: 'UT',
    latitude: '39.95429',
    longitude: '-111.903284',
    timeZoneId: 'America/Denver',
  },
  '84634': {
    zip: '84634',
    city: 'Gunnison',
    state: 'UT',
    latitude: '39.143543',
    longitude: '-111.841228',
    timeZoneId: 'America/Denver',
  },
  '84635': {
    zip: '84635',
    city: 'Hinckley',
    state: 'UT',
    latitude: '39.36444',
    longitude: '-112.7193',
    timeZoneId: 'America/Denver',
  },
  '84636': {
    zip: '84636',
    city: 'Holden',
    state: 'UT',
    latitude: '39.1041',
    longitude: '-112.269847',
    timeZoneId: 'America/Denver',
  },
  '84637': {
    zip: '84637',
    city: 'Kanosh',
    state: 'UT',
    latitude: '38.804613',
    longitude: '-112.437798',
    timeZoneId: 'America/Denver',
  },
  '84638': {
    zip: '84638',
    city: 'Leamington',
    state: 'UT',
    latitude: '39.525274',
    longitude: '-112.286779',
    timeZoneId: 'America/Denver',
  },
  '84639': {
    zip: '84639',
    city: 'Levan',
    state: 'UT',
    latitude: '39.554285',
    longitude: '-111.859135',
    timeZoneId: 'America/Denver',
  },
  '84640': {
    zip: '84640',
    city: 'Lynndyl',
    state: 'UT',
    latitude: '39.521782',
    longitude: '-112.369499',
    timeZoneId: 'America/Denver',
  },
  '84642': {
    zip: '84642',
    city: 'Manti',
    state: 'UT',
    latitude: '39.266611',
    longitude: '-111.63816',
    timeZoneId: 'America/Denver',
  },
  '84643': {
    zip: '84643',
    city: 'Mayfield',
    state: 'UT',
    latitude: '39.113088',
    longitude: '-111.702993',
    timeZoneId: 'America/Denver',
  },
  '84644': {
    zip: '84644',
    city: 'Meadow',
    state: 'UT',
    latitude: '38.888096',
    longitude: '-112.406351',
    timeZoneId: 'America/Denver',
  },
  '84645': {
    zip: '84645',
    city: 'Mona',
    state: 'UT',
    latitude: '39.819573',
    longitude: '-111.852966',
    timeZoneId: 'America/Denver',
  },
  '84646': {
    zip: '84646',
    city: 'Moroni',
    state: 'UT',
    latitude: '39.526809',
    longitude: '-111.58542',
    timeZoneId: 'America/Denver',
  },
  '84647': {
    zip: '84647',
    city: 'Mount Pleasant',
    state: 'UT',
    latitude: '39.55449',
    longitude: '-111.491057',
    timeZoneId: 'America/Denver',
  },
  '84648': {
    zip: '84648',
    city: 'Nephi',
    state: 'UT',
    latitude: '39.710909',
    longitude: '-111.844866',
    timeZoneId: 'America/Denver',
  },
  '84649': {
    zip: '84649',
    city: 'Oak City',
    state: 'UT',
    latitude: '39.376918',
    longitude: '-112.335547',
    timeZoneId: 'America/Denver',
  },
  '84651': {
    zip: '84651',
    city: 'Payson',
    state: 'UT',
    latitude: '40.026844',
    longitude: '-111.705036',
    timeZoneId: 'America/Denver',
  },
  '84652': {
    zip: '84652',
    city: 'Redmond',
    state: 'UT',
    latitude: '39.005668',
    longitude: '-111.86905',
    timeZoneId: 'America/Denver',
  },
  '84653': {
    zip: '84653',
    city: 'Salem',
    state: 'UT',
    latitude: '40.03233',
    longitude: '-111.658857',
    timeZoneId: 'America/Denver',
  },
  '84654': {
    zip: '84654',
    city: 'Salina',
    state: 'UT',
    latitude: '38.951768',
    longitude: '-111.860719',
    timeZoneId: 'America/Denver',
  },
  '84655': {
    zip: '84655',
    city: 'Santaquin',
    state: 'UT',
    latitude: '40.05624',
    longitude: '-111.75462',
    timeZoneId: 'America/Denver',
  },
  '84656': {
    zip: '84656',
    city: 'Scipio',
    state: 'UT',
    latitude: '39.252203',
    longitude: '-112.101774',
    timeZoneId: 'America/Denver',
  },
  '84657': {
    zip: '84657',
    city: 'Sigurd',
    state: 'UT',
    latitude: '38.840732',
    longitude: '-111.97086',
    timeZoneId: 'America/Denver',
  },
  '84660': {
    zip: '84660',
    city: 'Spanish Fork',
    state: 'UT',
    latitude: '40.113',
    longitude: '-111.6735',
    timeZoneId: 'America/Denver',
  },
  '84662': {
    zip: '84662',
    city: 'Spring City',
    state: 'UT',
    latitude: '39.483558',
    longitude: '-111.494443',
    timeZoneId: 'America/Denver',
  },
  '84663': {
    zip: '84663',
    city: 'Springville',
    state: 'UT',
    latitude: '40.162967',
    longitude: '-111.60305',
    timeZoneId: 'America/Denver',
  },
  '84664': {
    zip: '84664',
    city: 'Mapleton',
    state: 'UT',
    latitude: '40.137545',
    longitude: '-111.574793',
    timeZoneId: 'America/Denver',
  },
  '84665': {
    zip: '84665',
    city: 'Sterling',
    state: 'UT',
    latitude: '39.197144',
    longitude: '-111.682371',
    timeZoneId: 'America/Denver',
  },
  '84667': {
    zip: '84667',
    city: 'Wales',
    state: 'UT',
    latitude: '39.487834',
    longitude: '-111.634109',
    timeZoneId: 'America/Denver',
  },
  '84701': {
    zip: '84701',
    city: 'Richfield',
    state: 'UT',
    latitude: '38.67516',
    longitude: '-111.98982',
    timeZoneId: 'America/Denver',
  },
  '84710': {
    zip: '84710',
    city: 'Alton',
    state: 'UT',
    latitude: '37.475471',
    longitude: '-112.586334',
    timeZoneId: 'America/Denver',
  },
  '84711': {
    zip: '84711',
    city: 'Annabella',
    state: 'UT',
    latitude: '38.707368',
    longitude: '-112.062387',
    timeZoneId: 'America/Denver',
  },
  '84712': {
    zip: '84712',
    city: 'Antimony',
    state: 'UT',
    latitude: '37.939733',
    longitude: '-112.138116',
    timeZoneId: 'America/Denver',
  },
  '84713': {
    zip: '84713',
    city: 'Beaver',
    state: 'UT',
    latitude: '38.298252',
    longitude: '-112.716345',
    timeZoneId: 'America/Denver',
  },
  '84714': {
    zip: '84714',
    city: 'Beryl',
    state: 'UT',
    latitude: '37.703796',
    longitude: '-113.652604',
    timeZoneId: 'America/Denver',
  },
  '84715': {
    zip: '84715',
    city: 'Bicknell',
    state: 'UT',
    latitude: '38.350142',
    longitude: '-111.554345',
    timeZoneId: 'America/Denver',
  },
  '84716': {
    zip: '84716',
    city: 'Boulder',
    state: 'UT',
    latitude: '37.831807',
    longitude: '-111.204558',
    timeZoneId: 'America/Denver',
  },
  '84718': {
    zip: '84718',
    city: 'Cannonville',
    state: 'UT',
    latitude: '37.564981',
    longitude: '-112.057416',
    timeZoneId: 'America/Denver',
  },
  '84719': {
    zip: '84719',
    city: 'Brian Head',
    state: 'UT',
    latitude: '37.698178',
    longitude: '-112.849139',
    timeZoneId: 'America/Denver',
  },
  '84720': {
    zip: '84720',
    city: 'Cedar City',
    state: 'UT',
    latitude: '37.713174',
    longitude: '-113.085548',
    timeZoneId: 'America/Denver',
  },
  '84721': {
    zip: '84721',
    city: 'Cedar City',
    state: 'UT',
    latitude: '37.662085',
    longitude: '-113.196253',
    timeZoneId: 'America/Denver',
  },
  '84722': {
    zip: '84722',
    city: 'Central',
    state: 'UT',
    latitude: '37.403728',
    longitude: '-113.560056',
    timeZoneId: 'America/Denver',
  },
  '84723': {
    zip: '84723',
    city: 'Circleville',
    state: 'UT',
    latitude: '38.171505',
    longitude: '-112.272478',
    timeZoneId: 'America/Denver',
  },
  '84724': {
    zip: '84724',
    city: 'Elsinore',
    state: 'UT',
    latitude: '38.674418',
    longitude: '-112.146821',
    timeZoneId: 'America/Denver',
  },
  '84725': {
    zip: '84725',
    city: 'Enterprise',
    state: 'UT',
    latitude: '37.573577',
    longitude: '-113.71608',
    timeZoneId: 'America/Denver',
  },
  '84726': {
    zip: '84726',
    city: 'Escalante',
    state: 'UT',
    latitude: '37.764744',
    longitude: '-111.592375',
    timeZoneId: 'America/Denver',
  },
  '84728': {
    zip: '84728',
    city: 'Garrison',
    state: 'UT',
    latitude: '38.93511',
    longitude: '-114.03451',
    timeZoneId: 'America/Denver',
  },
  '84729': {
    zip: '84729',
    city: 'Glendale',
    state: 'UT',
    latitude: '37.316984',
    longitude: '-112.593213',
    timeZoneId: 'America/Denver',
  },
  '84730': {
    zip: '84730',
    city: 'Glenwood',
    state: 'UT',
    latitude: '38.763372',
    longitude: '-111.973567',
    timeZoneId: 'America/Denver',
  },
  '84731': {
    zip: '84731',
    city: 'Greenville',
    state: 'UT',
    latitude: '38.265985',
    longitude: '-112.729199',
    timeZoneId: 'America/Denver',
  },
  '84732': {
    zip: '84732',
    city: 'Greenwich',
    state: 'UT',
    latitude: '38.288642',
    longitude: '-112.066087',
    timeZoneId: 'America/Denver',
  },
  '84733': {
    zip: '84733',
    city: 'Gunlock',
    state: 'UT',
    latitude: '37.284384',
    longitude: '-113.758335',
    timeZoneId: 'America/Denver',
  },
  '84734': {
    zip: '84734',
    city: 'Hanksville',
    state: 'UT',
    latitude: '38.322787',
    longitude: '-110.693233',
    timeZoneId: 'America/Denver',
  },
  '84735': {
    zip: '84735',
    city: 'Hatch',
    state: 'UT',
    latitude: '37.65176',
    longitude: '-112.432889',
    timeZoneId: 'America/Denver',
  },
  '84736': {
    zip: '84736',
    city: 'Henrieville',
    state: 'UT',
    latitude: '37.561268',
    longitude: '-111.995387',
    timeZoneId: 'America/Denver',
  },
  '84737': {
    zip: '84737',
    city: 'Hurricane',
    state: 'UT',
    latitude: '37.24248',
    longitude: '-113.20086',
    timeZoneId: 'America/Denver',
  },
  '84738': {
    zip: '84738',
    city: 'Ivins',
    state: 'UT',
    latitude: '37.1678',
    longitude: '-113.667546',
    timeZoneId: 'America/Denver',
  },
  '84739': {
    zip: '84739',
    city: 'Joseph',
    state: 'UT',
    latitude: '38.620592',
    longitude: '-112.216866',
    timeZoneId: 'America/Denver',
  },
  '84740': {
    zip: '84740',
    city: 'Junction',
    state: 'UT',
    latitude: '38.24227',
    longitude: '-112.220822',
    timeZoneId: 'America/Denver',
  },
  '84741': {
    zip: '84741',
    city: 'Kanab',
    state: 'UT',
    latitude: '37.051804',
    longitude: '-112.533746',
    timeZoneId: 'America/Denver',
  },
  '84742': {
    zip: '84742',
    city: 'Kanarraville',
    state: 'UT',
    latitude: '37.535865',
    longitude: '-113.176078',
    timeZoneId: 'America/Denver',
  },
  '84743': {
    zip: '84743',
    city: 'Kingston',
    state: 'UT',
    latitude: '38.208632',
    longitude: '-112.187039',
    timeZoneId: 'America/Denver',
  },
  '84744': {
    zip: '84744',
    city: 'Koosharem',
    state: 'UT',
    latitude: '38.513397',
    longitude: '-111.882136',
    timeZoneId: 'America/Denver',
  },
  '84745': {
    zip: '84745',
    city: 'La Verkin',
    state: 'UT',
    latitude: '37.210385',
    longitude: '-113.272861',
    timeZoneId: 'America/Denver',
  },
  '84746': {
    zip: '84746',
    city: 'Leeds',
    state: 'UT',
    latitude: '37.235864',
    longitude: '-113.361897',
    timeZoneId: 'America/Denver',
  },
  '84747': {
    zip: '84747',
    city: 'Loa',
    state: 'UT',
    latitude: '38.441923',
    longitude: '-111.59313',
    timeZoneId: 'America/Denver',
  },
  '84749': {
    zip: '84749',
    city: 'Lyman',
    state: 'UT',
    latitude: '38.434907',
    longitude: '-111.611696',
    timeZoneId: 'America/Denver',
  },
  '84750': {
    zip: '84750',
    city: 'Marysvale',
    state: 'UT',
    latitude: '38.431118',
    longitude: '-112.218185',
    timeZoneId: 'America/Denver',
  },
  '84751': {
    zip: '84751',
    city: 'Milford',
    state: 'UT',
    latitude: '38.351737',
    longitude: '-112.98695',
    timeZoneId: 'America/Denver',
  },
  '84752': {
    zip: '84752',
    city: 'Minersville',
    state: 'UT',
    latitude: '38.216645',
    longitude: '-112.921086',
    timeZoneId: 'America/Denver',
  },
  '84753': {
    zip: '84753',
    city: 'Modena',
    state: 'UT',
    latitude: '37.798279',
    longitude: '-113.906685',
    timeZoneId: 'America/Denver',
  },
  '84754': {
    zip: '84754',
    city: 'Monroe',
    state: 'UT',
    latitude: '38.642486',
    longitude: '-112.135978',
    timeZoneId: 'America/Denver',
  },
  '84755': {
    zip: '84755',
    city: 'Mount Carmel',
    state: 'UT',
    latitude: '37.222039',
    longitude: '-112.743904',
    timeZoneId: 'America/Denver',
  },
  '84756': {
    zip: '84756',
    city: 'Newcastle',
    state: 'UT',
    latitude: '37.823414',
    longitude: '-113.72144',
    timeZoneId: 'America/Denver',
  },
  '84757': {
    zip: '84757',
    city: 'New Harmony',
    state: 'UT',
    latitude: '37.487664',
    longitude: '-113.312512',
    timeZoneId: 'America/Denver',
  },
  '84758': {
    zip: '84758',
    city: 'Orderville',
    state: 'UT',
    latitude: '37.268426',
    longitude: '-112.746951',
    timeZoneId: 'America/Denver',
  },
  '84759': {
    zip: '84759',
    city: 'Panguitch',
    state: 'UT',
    latitude: '37.774394',
    longitude: '-112.590019',
    timeZoneId: 'America/Denver',
  },
  '84760': {
    zip: '84760',
    city: 'Paragonah',
    state: 'UT',
    latitude: '37.886218',
    longitude: '-112.775555',
    timeZoneId: 'America/Denver',
  },
  '84761': {
    zip: '84761',
    city: 'Parowan',
    state: 'UT',
    latitude: '37.844667',
    longitude: '-112.82774',
    timeZoneId: 'America/Denver',
  },
  '84762': {
    zip: '84762',
    city: 'Duck Creek Village',
    state: 'UT',
    latitude: '37.529935',
    longitude: '-112.666908',
    timeZoneId: 'America/Denver',
  },
  '84763': {
    zip: '84763',
    city: 'Rockville',
    state: 'UT',
    latitude: '37.151777',
    longitude: '-113.060415',
    timeZoneId: 'America/Denver',
  },
  '84764': {
    zip: '84764',
    city: 'Bryce',
    state: 'UT',
    latitude: '37.6771',
    longitude: '-112.155661',
    timeZoneId: 'America/Denver',
  },
  '84765': {
    zip: '84765',
    city: 'Santa Clara',
    state: 'UT',
    latitude: '37.131214',
    longitude: '-113.642055',
    timeZoneId: 'America/Denver',
  },
  '84766': {
    zip: '84766',
    city: 'Sevier',
    state: 'UT',
    latitude: '38.59354',
    longitude: '-112.24387',
    timeZoneId: 'America/Denver',
  },
  '84767': {
    zip: '84767',
    city: 'Springdale',
    state: 'UT',
    latitude: '37.185515',
    longitude: '-112.998069',
    timeZoneId: 'America/Denver',
  },
  '84770': {
    zip: '84770',
    city: 'Saint George',
    state: 'UT',
    latitude: '37.084694',
    longitude: '-113.585569',
    timeZoneId: 'America/Denver',
  },
  '84771': {
    zip: '84771',
    city: 'Saint George',
    state: 'UT',
    latitude: '37.133484',
    longitude: '-113.490057',
    timeZoneId: 'America/Denver',
  },
  '84772': {
    zip: '84772',
    city: 'Summit',
    state: 'UT',
    latitude: '37.797218',
    longitude: '-112.942812',
    timeZoneId: 'America/Denver',
  },
  '84773': {
    zip: '84773',
    city: 'Teasdale',
    state: 'UT',
    latitude: '38.283757',
    longitude: '-111.472299',
    timeZoneId: 'America/Denver',
  },
  '84774': {
    zip: '84774',
    city: 'Toquerville',
    state: 'UT',
    latitude: '37.246668',
    longitude: '-113.286048',
    timeZoneId: 'America/Denver',
  },
  '84775': {
    zip: '84775',
    city: 'Torrey',
    state: 'UT',
    latitude: '38.302647',
    longitude: '-111.367243',
    timeZoneId: 'America/Denver',
  },
  '84776': {
    zip: '84776',
    city: 'Tropic',
    state: 'UT',
    latitude: '37.621375',
    longitude: '-112.086482',
    timeZoneId: 'America/Denver',
  },
  '84779': {
    zip: '84779',
    city: 'Virgin',
    state: 'UT',
    latitude: '37.188889',
    longitude: '-113',
    timeZoneId: 'America/Denver',
  },
  '84780': {
    zip: '84780',
    city: 'Washington',
    state: 'UT',
    latitude: '37.134587',
    longitude: '-113.509682',
    timeZoneId: 'America/Denver',
  },
  '84781': {
    zip: '84781',
    city: 'Pine Valley',
    state: 'UT',
    latitude: '37.326961',
    longitude: '-113.405734',
    timeZoneId: 'America/Denver',
  },
  '84782': {
    zip: '84782',
    city: 'Veyo',
    state: 'UT',
    latitude: '37.37694',
    longitude: '-113.66148',
    timeZoneId: 'America/Denver',
  },
  '84783': {
    zip: '84783',
    city: 'Dammeron Valley',
    state: 'UT',
    latitude: '37.31382',
    longitude: '-113.66862',
    timeZoneId: 'America/Denver',
  },
  '84784': {
    zip: '84784',
    city: 'Hildale',
    state: 'UT',
    latitude: '37.010071',
    longitude: '-112.966011',
    timeZoneId: 'America/Denver',
  },
  '84790': {
    zip: '84790',
    city: 'Saint George',
    state: 'UT',
    latitude: '37.080345',
    longitude: '-113.575391',
    timeZoneId: 'America/Denver',
  },
  '84791': {
    zip: '84791',
    city: 'Saint George',
    state: 'UT',
    latitude: '37.087282',
    longitude: '-113.556082',
    timeZoneId: 'America/Denver',
  },
  '85001': {
    zip: '85001',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.449215',
    longitude: '-112.069209',
    timeZoneId: 'America/Phoenix',
  },
  '85002': {
    zip: '85002',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.451112',
    longitude: '-112.067779',
    timeZoneId: 'America/Phoenix',
  },
  '85003': {
    zip: '85003',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.451747',
    longitude: '-112.075141',
    timeZoneId: 'America/Phoenix',
  },
  '85004': {
    zip: '85004',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.450504',
    longitude: '-112.06756',
    timeZoneId: 'America/Phoenix',
  },
  '85005': {
    zip: '85005',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.447818',
    longitude: '-112.127906',
    timeZoneId: 'America/Phoenix',
  },
  '85006': {
    zip: '85006',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.468847',
    longitude: '-112.045503',
    timeZoneId: 'America/Phoenix',
  },
  '85007': {
    zip: '85007',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.447641',
    longitude: '-112.089757',
    timeZoneId: 'America/Phoenix',
  },
  '85008': {
    zip: '85008',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.469624',
    longitude: '-111.998569',
    timeZoneId: 'America/Phoenix',
  },
  '85009': {
    zip: '85009',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.456603',
    longitude: '-112.123087',
    timeZoneId: 'America/Phoenix',
  },
  '85010': {
    zip: '85010',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.465638',
    longitude: '-111.987116',
    timeZoneId: 'America/Phoenix',
  },
  '85011': {
    zip: '85011',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.508799',
    longitude: '-112.057469',
    timeZoneId: 'America/Phoenix',
  },
  '85012': {
    zip: '85012',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.512706',
    longitude: '-112.066178',
    timeZoneId: 'America/Phoenix',
  },
  '85013': {
    zip: '85013',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.509914',
    longitude: '-112.080218',
    timeZoneId: 'America/Phoenix',
  },
  '85014': {
    zip: '85014',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.513505',
    longitude: '-112.05273',
    timeZoneId: 'America/Phoenix',
  },
  '85015': {
    zip: '85015',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.507977',
    longitude: '-112.101173',
    timeZoneId: 'America/Phoenix',
  },
  '85016': {
    zip: '85016',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.504248',
    longitude: '-112.029405',
    timeZoneId: 'America/Phoenix',
  },
  '85017': {
    zip: '85017',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.510589',
    longitude: '-112.122065',
    timeZoneId: 'America/Phoenix',
  },
  '85018': {
    zip: '85018',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.502501',
    longitude: '-111.982899',
    timeZoneId: 'America/Phoenix',
  },
  '85019': {
    zip: '85019',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.512656',
    longitude: '-112.141833',
    timeZoneId: 'America/Phoenix',
  },
  '85020': {
    zip: '85020',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.558755',
    longitude: '-112.056402',
    timeZoneId: 'America/Phoenix',
  },
  '85021': {
    zip: '85021',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.558995',
    longitude: '-112.088925',
    timeZoneId: 'America/Phoenix',
  },
  '85022': {
    zip: '85022',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.627465',
    longitude: '-112.051962',
    timeZoneId: 'America/Phoenix',
  },
  '85023': {
    zip: '85023',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.632395',
    longitude: '-112.111758',
    timeZoneId: 'America/Phoenix',
  },
  '85024': {
    zip: '85024',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.687628',
    longitude: '-112.050217',
    timeZoneId: 'America/Phoenix',
  },
  '85026': {
    zip: '85026',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.456034',
    longitude: '-111.97923',
    timeZoneId: 'America/Phoenix',
  },
  '85027': {
    zip: '85027',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.82895',
    longitude: '-112.1217',
    timeZoneId: 'America/Phoenix',
  },
  '85028': {
    zip: '85028',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.579575',
    longitude: '-112.006348',
    timeZoneId: 'America/Phoenix',
  },
  '85029': {
    zip: '85029',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.59741',
    longitude: '-112.118455',
    timeZoneId: 'America/Phoenix',
  },
  '85030': {
    zip: '85030',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.450771',
    longitude: '-112.076932',
    timeZoneId: 'America/Phoenix',
  },
  '85031': {
    zip: '85031',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.495264',
    longitude: '-112.170694',
    timeZoneId: 'America/Phoenix',
  },
  '85032': {
    zip: '85032',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.619208',
    longitude: '-112.004108',
    timeZoneId: 'America/Phoenix',
  },
  '85033': {
    zip: '85033',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.490937',
    longitude: '-112.213503',
    timeZoneId: 'America/Phoenix',
  },
  '85034': {
    zip: '85034',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.441779',
    longitude: '-112.025717',
    timeZoneId: 'America/Phoenix',
  },
  '85035': {
    zip: '85035',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.473497',
    longitude: '-112.187607',
    timeZoneId: 'America/Phoenix',
  },
  '85036': {
    zip: '85036',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.631873',
    longitude: '-112.1526',
    timeZoneId: 'America/Phoenix',
  },
  '85037': {
    zip: '85037',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.494055',
    longitude: '-112.254448',
    timeZoneId: 'America/Phoenix',
  },
  '85038': {
    zip: '85038',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.466583',
    longitude: '-111.988162',
    timeZoneId: 'America/Phoenix',
  },
  '85039': {
    zip: '85039',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.493473',
    longitude: '-112.288528',
    timeZoneId: 'America/Phoenix',
  },
  '85040': {
    zip: '85040',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.408851',
    longitude: '-112.026341',
    timeZoneId: 'America/Phoenix',
  },
  '85041': {
    zip: '85041',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.385171',
    longitude: '-112.103206',
    timeZoneId: 'America/Phoenix',
  },
  '85042': {
    zip: '85042',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.375254',
    longitude: '-112.031642',
    timeZoneId: 'America/Phoenix',
  },
  '85043': {
    zip: '85043',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.439449',
    longitude: '-112.191012',
    timeZoneId: 'America/Phoenix',
  },
  '85044': {
    zip: '85044',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.32404',
    longitude: '-112.001214',
    timeZoneId: 'America/Phoenix',
  },
  '85045': {
    zip: '85045',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.303621',
    longitude: '-112.11419',
    timeZoneId: 'America/Phoenix',
  },
  '85046': {
    zip: '85046',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.623245',
    longitude: '-112.003464',
    timeZoneId: 'America/Phoenix',
  },
  '85048': {
    zip: '85048',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.331272',
    longitude: '-112.047146',
    timeZoneId: 'America/Phoenix',
  },
  '85050': {
    zip: '85050',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.693798',
    longitude: '-111.999557',
    timeZoneId: 'America/Phoenix',
  },
  '85051': {
    zip: '85051',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.560619',
    longitude: '-112.134084',
    timeZoneId: 'America/Phoenix',
  },
  '85053': {
    zip: '85053',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.631811',
    longitude: '-112.131427',
    timeZoneId: 'America/Phoenix',
  },
  '85054': {
    zip: '85054',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.675343',
    longitude: '-111.936202',
    timeZoneId: 'America/Phoenix',
  },
  '85060': {
    zip: '85060',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.500897',
    longitude: '-111.981897',
    timeZoneId: 'America/Phoenix',
  },
  '85061': {
    zip: '85061',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.519632',
    longitude: '-112.127047',
    timeZoneId: 'America/Phoenix',
  },
  '85062': {
    zip: '85062',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.46418',
    longitude: '-111.98788',
    timeZoneId: 'America/Phoenix',
  },
  '85063': {
    zip: '85063',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.502937',
    longitude: '-112.171819',
    timeZoneId: 'America/Phoenix',
  },
  '85064': {
    zip: '85064',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.508942',
    longitude: '-112.032374',
    timeZoneId: 'America/Phoenix',
  },
  '85065': {
    zip: '85065',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.436281',
    longitude: '-112.048927',
    timeZoneId: 'America/Phoenix',
  },
  '85066': {
    zip: '85066',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.394343',
    longitude: '-112.03237',
    timeZoneId: 'America/Phoenix',
  },
  '85067': {
    zip: '85067',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.512131',
    longitude: '-112.081821',
    timeZoneId: 'America/Phoenix',
  },
  '85068': {
    zip: '85068',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.677489',
    longitude: '-112.061267',
    timeZoneId: 'America/Phoenix',
  },
  '85069': {
    zip: '85069',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.562209',
    longitude: '-112.098364',
    timeZoneId: 'America/Phoenix',
  },
  '85070': {
    zip: '85070',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.304598',
    longitude: '-112.047711',
    timeZoneId: 'America/Phoenix',
  },
  '85071': {
    zip: '85071',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.593614',
    longitude: '-112.108962',
    timeZoneId: 'America/Phoenix',
  },
  '85072': {
    zip: '85072',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.453483',
    longitude: '-112.009671',
    timeZoneId: 'America/Phoenix',
  },
  '85073': {
    zip: '85073',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.451601',
    longitude: '-112.073406',
    timeZoneId: 'America/Phoenix',
  },
  '85074': {
    zip: '85074',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.452352',
    longitude: '-112.091819',
    timeZoneId: 'America/Phoenix',
  },
  '85075': {
    zip: '85075',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.498318',
    longitude: '-112.213836',
    timeZoneId: 'America/Phoenix',
  },
  '85076': {
    zip: '85076',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.33064',
    longitude: '-111.989021',
    timeZoneId: 'America/Phoenix',
  },
  '85078': {
    zip: '85078',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.623985',
    longitude: '-112.005782',
    timeZoneId: 'America/Phoenix',
  },
  '85079': {
    zip: '85079',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.508957',
    longitude: '-112.120287',
    timeZoneId: 'America/Phoenix',
  },
  '85080': {
    zip: '85080',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.633614',
    longitude: '-112.111004',
    timeZoneId: 'America/Phoenix',
  },
  '85082': {
    zip: '85082',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.465447',
    longitude: '-111.985085',
    timeZoneId: 'America/Phoenix',
  },
  '85083': {
    zip: '85083',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.709608',
    longitude: '-112.121939',
    timeZoneId: 'America/Phoenix',
  },
  '85085': {
    zip: '85085',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.76361',
    longitude: '-112.110839',
    timeZoneId: 'America/Phoenix',
  },
  '85086': {
    zip: '85086',
    city: 'Phoenix',
    state: 'AZ',
    latitude: '33.84668',
    longitude: '-112.093223',
    timeZoneId: 'America/Phoenix',
  },
  '85087': {
    zip: '85087',
    city: 'New River',
    state: 'AZ',
    latitude: '33.915238',
    longitude: '-112.136717',
    timeZoneId: 'America/Phoenix',
  },
  '85117': {
    zip: '85117',
    city: 'Apache Junction',
    state: 'AZ',
    latitude: '33.421692',
    longitude: '-111.548959',
    timeZoneId: 'America/Phoenix',
  },
  '85118': {
    zip: '85118',
    city: 'Gold Canyon',
    state: 'AZ',
    latitude: '33.359605',
    longitude: '-111.440833',
    timeZoneId: 'America/Phoenix',
  },
  '85119': {
    zip: '85119',
    city: 'Apache Junction',
    state: 'AZ',
    latitude: '33.417293',
    longitude: '-111.528765',
    timeZoneId: 'America/Phoenix',
  },
  '85120': {
    zip: '85120',
    city: 'Apache Junction',
    state: 'AZ',
    latitude: '33.412396',
    longitude: '-111.589933',
    timeZoneId: 'America/Phoenix',
  },
  '85121': {
    zip: '85121',
    city: 'Bapchule',
    state: 'AZ',
    latitude: '33.135274',
    longitude: '-111.872419',
    timeZoneId: 'America/Phoenix',
  },
  '85122': {
    zip: '85122',
    city: 'Casa Grande',
    state: 'AZ',
    latitude: '32.893933',
    longitude: '-111.74503',
    timeZoneId: 'America/Phoenix',
  },
  '85123': {
    zip: '85123',
    city: 'Arizona City',
    state: 'AZ',
    latitude: '32.749965',
    longitude: '-111.676936',
    timeZoneId: 'America/Phoenix',
  },
  '85127': {
    zip: '85127',
    city: 'Chandler Heights',
    state: 'AZ',
    latitude: '33.210185',
    longitude: '-111.687173',
    timeZoneId: 'America/Phoenix',
  },
  '85128': {
    zip: '85128',
    city: 'Coolidge',
    state: 'AZ',
    latitude: '32.983925',
    longitude: '-111.524534',
    timeZoneId: 'America/Phoenix',
  },
  '85130': {
    zip: '85130',
    city: 'Casa Grande',
    state: 'AZ',
    latitude: '32.879539',
    longitude: '-111.75032',
    timeZoneId: 'America/Phoenix',
  },
  '85131': {
    zip: '85131',
    city: 'Eloy',
    state: 'AZ',
    latitude: '32.74132',
    longitude: '-111.58537',
    timeZoneId: 'America/Phoenix',
  },
  '85132': {
    zip: '85132',
    city: 'Florence',
    state: 'AZ',
    latitude: '33.009241',
    longitude: '-111.360789',
    timeZoneId: 'America/Phoenix',
  },
  '85135': {
    zip: '85135',
    city: 'Hayden',
    state: 'AZ',
    latitude: '32.998859',
    longitude: '-110.786873',
    timeZoneId: 'America/Phoenix',
  },
  '85137': {
    zip: '85137',
    city: 'Kearny',
    state: 'AZ',
    latitude: '33.08991',
    longitude: '-110.939051',
    timeZoneId: 'America/Phoenix',
  },
  '85138': {
    zip: '85138',
    city: 'Maricopa',
    state: 'AZ',
    latitude: '33.05379',
    longitude: '-112.004917',
    timeZoneId: 'America/Phoenix',
  },
  '85139': {
    zip: '85139',
    city: 'Maricopa',
    state: 'AZ',
    latitude: '32.98011',
    longitude: '-112.108719',
    timeZoneId: 'America/Phoenix',
  },
  '85140': {
    zip: '85140',
    city: 'San Tan Valley',
    state: 'AZ',
    latitude: '33.23662',
    longitude: '-111.54369',
    timeZoneId: 'America/Phoenix',
  },
  '85141': {
    zip: '85141',
    city: 'Picacho',
    state: 'AZ',
    latitude: '32.713145',
    longitude: '-111.498506',
    timeZoneId: 'America/Phoenix',
  },
  '85142': {
    zip: '85142',
    city: 'Queen Creek',
    state: 'AZ',
    latitude: '33.228018',
    longitude: '-111.637649',
    timeZoneId: 'America/Phoenix',
  },
  '85143': {
    zip: '85143',
    city: 'San Tan Valley',
    state: 'AZ',
    latitude: '33.16178',
    longitude: '-111.550939',
    timeZoneId: 'America/Phoenix',
  },
  '85145': {
    zip: '85145',
    city: 'Red Rock',
    state: 'AZ',
    latitude: '32.559128',
    longitude: '-111.367378',
    timeZoneId: 'America/Phoenix',
  },
  '85147': {
    zip: '85147',
    city: 'Sacaton',
    state: 'AZ',
    latitude: '33.08223',
    longitude: '-111.752999',
    timeZoneId: 'America/Phoenix',
  },
  '85172': {
    zip: '85172',
    city: 'Stanfield',
    state: 'AZ',
    latitude: '32.88473',
    longitude: '-111.960609',
    timeZoneId: 'America/Phoenix',
  },
  '85173': {
    zip: '85173',
    city: 'Superior',
    state: 'AZ',
    latitude: '33.280529',
    longitude: '-111.115992',
    timeZoneId: 'America/Phoenix',
  },
  '85178': {
    zip: '85178',
    city: 'Apache Junction',
    state: 'AZ',
    latitude: '33.42318',
    longitude: '-111.546089',
    timeZoneId: 'America/Phoenix',
  },
  '85190': {
    zip: '85190',
    city: 'Tortilla Flat',
    state: 'AZ',
    latitude: '33.414513',
    longitude: '-111.581113',
    timeZoneId: 'America/Phoenix',
  },
  '85191': {
    zip: '85191',
    city: 'Valley Farms',
    state: 'AZ',
    latitude: '32.988404',
    longitude: '-111.449661',
    timeZoneId: 'America/Phoenix',
  },
  '85192': {
    zip: '85192',
    city: 'Winkelman',
    state: 'AZ',
    latitude: '32.915624',
    longitude: '-110.721518',
    timeZoneId: 'America/Phoenix',
  },
  '85193': {
    zip: '85193',
    city: 'Casa Grande',
    state: 'AZ',
    latitude: '32.893136',
    longitude: '-111.734051',
    timeZoneId: 'America/Phoenix',
  },
  '85194': {
    zip: '85194',
    city: 'Casa Grande',
    state: 'AZ',
    latitude: '32.908923',
    longitude: '-111.627289',
    timeZoneId: 'America/Phoenix',
  },
  '85201': {
    zip: '85201',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.430515',
    longitude: '-111.840854',
    timeZoneId: 'America/Phoenix',
  },
  '85202': {
    zip: '85202',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.38223',
    longitude: '-111.867598',
    timeZoneId: 'America/Phoenix',
  },
  '85203': {
    zip: '85203',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.438641',
    longitude: '-111.800882',
    timeZoneId: 'America/Phoenix',
  },
  '85204': {
    zip: '85204',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.401183',
    longitude: '-111.790791',
    timeZoneId: 'America/Phoenix',
  },
  '85205': {
    zip: '85205',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.437801',
    longitude: '-111.714585',
    timeZoneId: 'America/Phoenix',
  },
  '85206': {
    zip: '85206',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.402178',
    longitude: '-111.727568',
    timeZoneId: 'America/Phoenix',
  },
  '85207': {
    zip: '85207',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.454568',
    longitude: '-111.650423',
    timeZoneId: 'America/Phoenix',
  },
  '85208': {
    zip: '85208',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.390705',
    longitude: '-111.648725',
    timeZoneId: 'America/Phoenix',
  },
  '85209': {
    zip: '85209',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.3773',
    longitude: '-111.6595',
    timeZoneId: 'America/Phoenix',
  },
  '85210': {
    zip: '85210',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.390402',
    longitude: '-111.84445',
    timeZoneId: 'America/Phoenix',
  },
  '85211': {
    zip: '85211',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.442359',
    longitude: '-111.849093',
    timeZoneId: 'America/Phoenix',
  },
  '85212': {
    zip: '85212',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.34288',
    longitude: '-111.621592',
    timeZoneId: 'America/Phoenix',
  },
  '85213': {
    zip: '85213',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.439179',
    longitude: '-111.772612',
    timeZoneId: 'America/Phoenix',
  },
  '85214': {
    zip: '85214',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.399958',
    longitude: '-111.788572',
    timeZoneId: 'America/Phoenix',
  },
  '85215': {
    zip: '85215',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.468094',
    longitude: '-111.706926',
    timeZoneId: 'America/Phoenix',
  },
  '85216': {
    zip: '85216',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.396902',
    longitude: '-111.723184',
    timeZoneId: 'America/Phoenix',
  },
  '85224': {
    zip: '85224',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.321325',
    longitude: '-111.877926',
    timeZoneId: 'America/Phoenix',
  },
  '85225': {
    zip: '85225',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.31629',
    longitude: '-111.831035',
    timeZoneId: 'America/Phoenix',
  },
  '85226': {
    zip: '85226',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.306009',
    longitude: '-111.919281',
    timeZoneId: 'America/Phoenix',
  },
  '85233': {
    zip: '85233',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.355672',
    longitude: '-111.803866',
    timeZoneId: 'America/Phoenix',
  },
  '85234': {
    zip: '85234',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.359611',
    longitude: '-111.750767',
    timeZoneId: 'America/Phoenix',
  },
  '85236': {
    zip: '85236',
    city: 'Higley',
    state: 'AZ',
    latitude: '33.330307',
    longitude: '-111.696802',
    timeZoneId: 'America/Phoenix',
  },
  '85244': {
    zip: '85244',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.235097',
    longitude: '-111.761257',
    timeZoneId: 'America/Phoenix',
  },
  '85246': {
    zip: '85246',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.292302',
    longitude: '-111.867612',
    timeZoneId: 'America/Phoenix',
  },
  '85248': {
    zip: '85248',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.2475',
    longitude: '-111.87918',
    timeZoneId: 'America/Phoenix',
  },
  '85249': {
    zip: '85249',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.243258',
    longitude: '-111.790921',
    timeZoneId: 'America/Phoenix',
  },
  '85250': {
    zip: '85250',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.523133',
    longitude: '-111.906659',
    timeZoneId: 'America/Phoenix',
  },
  '85251': {
    zip: '85251',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.493889',
    longitude: '-111.922875',
    timeZoneId: 'America/Phoenix',
  },
  '85252': {
    zip: '85252',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.489653',
    longitude: '-111.924556',
    timeZoneId: 'America/Phoenix',
  },
  '85253': {
    zip: '85253',
    city: 'Paradise Valley',
    state: 'AZ',
    latitude: '33.546',
    longitude: '-111.96828',
    timeZoneId: 'America/Phoenix',
  },
  '85254': {
    zip: '85254',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.616346',
    longitude: '-111.954949',
    timeZoneId: 'America/Phoenix',
  },
  '85255': {
    zip: '85255',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.67842',
    longitude: '-111.806618',
    timeZoneId: 'America/Phoenix',
  },
  '85256': {
    zip: '85256',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.525852',
    longitude: '-111.785406',
    timeZoneId: 'America/Phoenix',
  },
  '85257': {
    zip: '85257',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.46674',
    longitude: '-111.917674',
    timeZoneId: 'America/Phoenix',
  },
  '85258': {
    zip: '85258',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.572368',
    longitude: '-111.899332',
    timeZoneId: 'America/Phoenix',
  },
  '85259': {
    zip: '85259',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.601991',
    longitude: '-111.813977',
    timeZoneId: 'America/Phoenix',
  },
  '85260': {
    zip: '85260',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.599601',
    longitude: '-111.889956',
    timeZoneId: 'America/Phoenix',
  },
  '85261': {
    zip: '85261',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.497144',
    longitude: '-111.9206',
    timeZoneId: 'America/Phoenix',
  },
  '85262': {
    zip: '85262',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.815653',
    longitude: '-111.784575',
    timeZoneId: 'America/Phoenix',
  },
  '85263': {
    zip: '85263',
    city: 'Rio Verde',
    state: 'AZ',
    latitude: '33.72252',
    longitude: '-111.675',
    timeZoneId: 'America/Phoenix',
  },
  '85264': {
    zip: '85264',
    city: 'Fort McDowell',
    state: 'AZ',
    latitude: '33.625402',
    longitude: '-111.66226',
    timeZoneId: 'America/Phoenix',
  },
  '85266': {
    zip: '85266',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.487674',
    longitude: '-111.926487',
    timeZoneId: 'America/Phoenix',
  },
  '85267': {
    zip: '85267',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.612228',
    longitude: '-111.889294',
    timeZoneId: 'America/Phoenix',
  },
  '85268': {
    zip: '85268',
    city: 'Fountain Hills',
    state: 'AZ',
    latitude: '33.608995',
    longitude: '-111.729537',
    timeZoneId: 'America/Phoenix',
  },
  '85269': {
    zip: '85269',
    city: 'Fountain Hills',
    state: 'AZ',
    latitude: '33.603722',
    longitude: '-111.720365',
    timeZoneId: 'America/Phoenix',
  },
  '85271': {
    zip: '85271',
    city: 'Scottsdale',
    state: 'AZ',
    latitude: '33.466987',
    longitude: '-111.914908',
    timeZoneId: 'America/Phoenix',
  },
  '85274': {
    zip: '85274',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.386818',
    longitude: '-111.87396',
    timeZoneId: 'America/Phoenix',
  },
  '85275': {
    zip: '85275',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.454213',
    longitude: '-111.767337',
    timeZoneId: 'America/Phoenix',
  },
  '85277': {
    zip: '85277',
    city: 'Mesa',
    state: 'AZ',
    latitude: '33.457507',
    longitude: '-111.722491',
    timeZoneId: 'America/Phoenix',
  },
  '85280': {
    zip: '85280',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.406776',
    longitude: '-111.926582',
    timeZoneId: 'America/Phoenix',
  },
  '85281': {
    zip: '85281',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.422399',
    longitude: '-111.927588',
    timeZoneId: 'America/Phoenix',
  },
  '85282': {
    zip: '85282',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.39087',
    longitude: '-111.925598',
    timeZoneId: 'America/Phoenix',
  },
  '85283': {
    zip: '85283',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.365103',
    longitude: '-111.928523',
    timeZoneId: 'America/Phoenix',
  },
  '85284': {
    zip: '85284',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.336825',
    longitude: '-111.921246',
    timeZoneId: 'America/Phoenix',
  },
  '85285': {
    zip: '85285',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.395328',
    longitude: '-111.929313',
    timeZoneId: 'America/Phoenix',
  },
  '85286': {
    zip: '85286',
    city: 'Chandler',
    state: 'AZ',
    latitude: '33.254994',
    longitude: '-111.833255',
    timeZoneId: 'America/Phoenix',
  },
  '85287': {
    zip: '85287',
    city: 'Tempe',
    state: 'AZ',
    latitude: '33.428352',
    longitude: '-111.932123',
    timeZoneId: 'America/Phoenix',
  },
  '85295': {
    zip: '85295',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.317422',
    longitude: '-111.730771',
    timeZoneId: 'America/Phoenix',
  },
  '85296': {
    zip: '85296',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.313543',
    longitude: '-111.772549',
    timeZoneId: 'America/Phoenix',
  },
  '85297': {
    zip: '85297',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.316433',
    longitude: '-111.712441',
    timeZoneId: 'America/Phoenix',
  },
  '85298': {
    zip: '85298',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.239786',
    longitude: '-111.717632',
    timeZoneId: 'America/Phoenix',
  },
  '85299': {
    zip: '85299',
    city: 'Gilbert',
    state: 'AZ',
    latitude: '33.332877',
    longitude: '-111.763848',
    timeZoneId: 'America/Phoenix',
  },
  '85301': {
    zip: '85301',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.536288',
    longitude: '-112.176745',
    timeZoneId: 'America/Phoenix',
  },
  '85302': {
    zip: '85302',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.568348',
    longitude: '-112.178491',
    timeZoneId: 'America/Phoenix',
  },
  '85303': {
    zip: '85303',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.522173',
    longitude: '-112.218359',
    timeZoneId: 'America/Phoenix',
  },
  '85304': {
    zip: '85304',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.59373',
    longitude: '-112.176099',
    timeZoneId: 'America/Phoenix',
  },
  '85305': {
    zip: '85305',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.527535',
    longitude: '-112.250863',
    timeZoneId: 'America/Phoenix',
  },
  '85306': {
    zip: '85306',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.620994',
    longitude: '-112.181517',
    timeZoneId: 'America/Phoenix',
  },
  '85307': {
    zip: '85307',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.538998',
    longitude: '-112.347366',
    timeZoneId: 'America/Phoenix',
  },
  '85308': {
    zip: '85308',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.651823',
    longitude: '-112.167339',
    timeZoneId: 'America/Phoenix',
  },
  '85309': {
    zip: '85309',
    city: 'Luke Air Force Base',
    state: 'AZ',
    latitude: '33.538316',
    longitude: '-112.370171',
    timeZoneId: 'America/Phoenix',
  },
  '85310': {
    zip: '85310',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.702625',
    longitude: '-112.161285',
    timeZoneId: 'America/Phoenix',
  },
  '85311': {
    zip: '85311',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.533405',
    longitude: '-112.179559',
    timeZoneId: 'America/Phoenix',
  },
  '85312': {
    zip: '85312',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.623634',
    longitude: '-112.180873',
    timeZoneId: 'America/Phoenix',
  },
  '85318': {
    zip: '85318',
    city: 'Glendale',
    state: 'AZ',
    latitude: '33.660359',
    longitude: '-112.184575',
    timeZoneId: 'America/Phoenix',
  },
  '85320': {
    zip: '85320',
    city: 'Aguila',
    state: 'AZ',
    latitude: '33.927739',
    longitude: '-113.176579',
    timeZoneId: 'America/Phoenix',
  },
  '85321': {
    zip: '85321',
    city: 'Ajo',
    state: 'AZ',
    latitude: '32.385764',
    longitude: '-112.866344',
    timeZoneId: 'America/Phoenix',
  },
  '85322': {
    zip: '85322',
    city: 'Arlington',
    state: 'AZ',
    latitude: '33.304639',
    longitude: '-112.780664',
    timeZoneId: 'America/Phoenix',
  },
  '85323': {
    zip: '85323',
    city: 'Avondale',
    state: 'AZ',
    latitude: '33.429286',
    longitude: '-112.345055',
    timeZoneId: 'America/Phoenix',
  },
  '85324': {
    zip: '85324',
    city: 'Black Canyon City',
    state: 'AZ',
    latitude: '34.085943',
    longitude: '-112.1416',
    timeZoneId: 'America/Phoenix',
  },
  '85325': {
    zip: '85325',
    city: 'Bouse',
    state: 'AZ',
    latitude: '33.931773',
    longitude: '-114.003599',
    timeZoneId: 'America/Phoenix',
  },
  '85326': {
    zip: '85326',
    city: 'Buckeye',
    state: 'AZ',
    latitude: '33.382557',
    longitude: '-112.568263',
    timeZoneId: 'America/Phoenix',
  },
  '85327': {
    zip: '85327',
    city: 'Cave Creek',
    state: 'AZ',
    latitude: '33.826462',
    longitude: '-111.963469',
    timeZoneId: 'America/Phoenix',
  },
  '85328': {
    zip: '85328',
    city: 'Cibola',
    state: 'AZ',
    latitude: '33.533467',
    longitude: '-114.432903',
    timeZoneId: 'America/Phoenix',
  },
  '85329': {
    zip: '85329',
    city: 'Cashion',
    state: 'AZ',
    latitude: '33.42769',
    longitude: '-112.305076',
    timeZoneId: 'America/Phoenix',
  },
  '85331': {
    zip: '85331',
    city: 'Cave Creek',
    state: 'AZ',
    latitude: '33.823249',
    longitude: '-111.949908',
    timeZoneId: 'America/Phoenix',
  },
  '85332': {
    zip: '85332',
    city: 'Congress',
    state: 'AZ',
    latitude: '34.164079',
    longitude: '-112.857349',
    timeZoneId: 'America/Phoenix',
  },
  '85333': {
    zip: '85333',
    city: 'Dateland',
    state: 'AZ',
    latitude: '32.877172',
    longitude: '-113.426851',
    timeZoneId: 'America/Phoenix',
  },
  '85334': {
    zip: '85334',
    city: 'Ehrenberg',
    state: 'AZ',
    latitude: '33.620992',
    longitude: '-114.496847',
    timeZoneId: 'America/Phoenix',
  },
  '85335': {
    zip: '85335',
    city: 'El Mirage',
    state: 'AZ',
    latitude: '33.61348',
    longitude: '-112.31769',
    timeZoneId: 'America/Phoenix',
  },
  '85336': {
    zip: '85336',
    city: 'Gadsden',
    state: 'AZ',
    latitude: '32.551578',
    longitude: '-114.772541',
    timeZoneId: 'America/Phoenix',
  },
  '85337': {
    zip: '85337',
    city: 'Gila Bend',
    state: 'AZ',
    latitude: '32.984592',
    longitude: '-112.679461',
    timeZoneId: 'America/Phoenix',
  },
  '85338': {
    zip: '85338',
    city: 'Goodyear',
    state: 'AZ',
    latitude: '33.434691',
    longitude: '-112.383814',
    timeZoneId: 'America/Phoenix',
  },
  '85339': {
    zip: '85339',
    city: 'Laveen',
    state: 'AZ',
    latitude: '33.350644',
    longitude: '-112.198727',
    timeZoneId: 'America/Phoenix',
  },
  '85340': {
    zip: '85340',
    city: 'Litchfield Park',
    state: 'AZ',
    latitude: '33.497954',
    longitude: '-112.376641',
    timeZoneId: 'America/Phoenix',
  },
  '85341': {
    zip: '85341',
    city: 'Lukeville',
    state: 'AZ',
    latitude: '31.881005',
    longitude: '-112.815265',
    timeZoneId: 'America/Hermosillo',
  },
  '85342': {
    zip: '85342',
    city: 'Morristown',
    state: 'AZ',
    latitude: '33.85032',
    longitude: '-112.60932',
    timeZoneId: 'America/Phoenix',
  },
  '85343': {
    zip: '85343',
    city: 'Palo Verde',
    state: 'AZ',
    latitude: '33.33124',
    longitude: '-112.703013',
    timeZoneId: 'America/Phoenix',
  },
  '85344': {
    zip: '85344',
    city: 'Parker',
    state: 'AZ',
    latitude: '34.155315',
    longitude: '-114.26303',
    timeZoneId: 'America/Phoenix',
  },
  '85345': {
    zip: '85345',
    city: 'Peoria',
    state: 'AZ',
    latitude: '33.569542',
    longitude: '-112.246397',
    timeZoneId: 'America/Phoenix',
  },
  '85346': {
    zip: '85346',
    city: 'Quartzsite',
    state: 'AZ',
    latitude: '33.666731',
    longitude: '-114.214619',
    timeZoneId: 'America/Phoenix',
  },
  '85347': {
    zip: '85347',
    city: 'Roll',
    state: 'AZ',
    latitude: '32.749589',
    longitude: '-113.912286',
    timeZoneId: 'America/Phoenix',
  },
  '85348': {
    zip: '85348',
    city: 'Salome',
    state: 'AZ',
    latitude: '33.8465',
    longitude: '-113.607319',
    timeZoneId: 'America/Phoenix',
  },
  '85349': {
    zip: '85349',
    city: 'San Luis',
    state: 'AZ',
    latitude: '32.499269',
    longitude: '-114.782304',
    timeZoneId: 'America/Phoenix',
  },
  '85350': {
    zip: '85350',
    city: 'Somerton',
    state: 'AZ',
    latitude: '32.596969',
    longitude: '-114.690827',
    timeZoneId: 'America/Phoenix',
  },
  '85351': {
    zip: '85351',
    city: 'Sun City',
    state: 'AZ',
    latitude: '33.604778',
    longitude: '-112.279344',
    timeZoneId: 'America/Phoenix',
  },
  '85352': {
    zip: '85352',
    city: 'Tacna',
    state: 'AZ',
    latitude: '32.701861',
    longitude: '-113.96659',
    timeZoneId: 'America/Phoenix',
  },
  '85353': {
    zip: '85353',
    city: 'Tolleson',
    state: 'AZ',
    latitude: '33.413557',
    longitude: '-112.273803',
    timeZoneId: 'America/Phoenix',
  },
  '85354': {
    zip: '85354',
    city: 'Tonopah',
    state: 'AZ',
    latitude: '33.475267',
    longitude: '-112.879075',
    timeZoneId: 'America/Phoenix',
  },
  '85355': {
    zip: '85355',
    city: 'Waddell',
    state: 'AZ',
    latitude: '33.568166',
    longitude: '-112.436072',
    timeZoneId: 'America/Phoenix',
  },
  '85356': {
    zip: '85356',
    city: 'Wellton',
    state: 'AZ',
    latitude: '32.685851',
    longitude: '-114.089045',
    timeZoneId: 'America/Phoenix',
  },
  '85357': {
    zip: '85357',
    city: 'Wenden',
    state: 'AZ',
    latitude: '33.821473',
    longitude: '-113.539461',
    timeZoneId: 'America/Phoenix',
  },
  '85358': {
    zip: '85358',
    city: 'Wickenburg',
    state: 'AZ',
    latitude: '33.963139',
    longitude: '-112.746885',
    timeZoneId: 'America/Phoenix',
  },
  '85359': {
    zip: '85359',
    city: 'Quartzsite',
    state: 'AZ',
    latitude: '33.691042',
    longitude: '-114.223077',
    timeZoneId: 'America/Phoenix',
  },
  '85360': {
    zip: '85360',
    city: 'Wikieup',
    state: 'AZ',
    latitude: '34.655225',
    longitude: '-113.755225',
    timeZoneId: 'America/Phoenix',
  },
  '85361': {
    zip: '85361',
    city: 'Wittmann',
    state: 'AZ',
    latitude: '33.752969',
    longitude: '-112.511086',
    timeZoneId: 'America/Phoenix',
  },
  '85362': {
    zip: '85362',
    city: 'Yarnell',
    state: 'AZ',
    latitude: '34.239701',
    longitude: '-112.753201',
    timeZoneId: 'America/Phoenix',
  },
  '85363': {
    zip: '85363',
    city: 'Youngtown',
    state: 'AZ',
    latitude: '33.592828',
    longitude: '-112.30205',
    timeZoneId: 'America/Phoenix',
  },
  '85364': {
    zip: '85364',
    city: 'Yuma',
    state: 'AZ',
    latitude: '32.701076',
    longitude: '-114.646859',
    timeZoneId: 'America/Phoenix',
  },
  '85365': {
    zip: '85365',
    city: 'Yuma',
    state: 'AZ',
    latitude: '32.672337',
    longitude: '-114.502398',
    timeZoneId: 'America/Phoenix',
  },
  '85366': {
    zip: '85366',
    city: 'Yuma',
    state: 'AZ',
    latitude: '32.686728',
    longitude: '-114.591916',
    timeZoneId: 'America/Phoenix',
  },
  '85367': {
    zip: '85367',
    city: 'Yuma',
    state: 'AZ',
    latitude: '32.655369',
    longitude: '-114.383585',
    timeZoneId: 'America/Phoenix',
  },
  '85369': {
    zip: '85369',
    city: 'Yuma',
    state: 'AZ',
    latitude: '32.698844',
    longitude: '-114.677928',
    timeZoneId: 'America/Phoenix',
  },
  '85371': {
    zip: '85371',
    city: 'Poston',
    state: 'AZ',
    latitude: '33.957856',
    longitude: '-114.413584',
    timeZoneId: 'America/Phoenix',
  },
  '85372': {
    zip: '85372',
    city: 'Sun City',
    state: 'AZ',
    latitude: '33.616054',
    longitude: '-112.283824',
    timeZoneId: 'America/Phoenix',
  },
  '85373': {
    zip: '85373',
    city: 'Sun City',
    state: 'AZ',
    latitude: '33.782816',
    longitude: '-112.365348',
    timeZoneId: 'America/Phoenix',
  },
  '85374': {
    zip: '85374',
    city: 'Surprise',
    state: 'AZ',
    latitude: '33.629937',
    longitude: '-112.35025',
    timeZoneId: 'America/Phoenix',
  },
  '85375': {
    zip: '85375',
    city: 'Sun City West',
    state: 'AZ',
    latitude: '33.661749',
    longitude: '-112.345339',
    timeZoneId: 'America/Phoenix',
  },
  '85376': {
    zip: '85376',
    city: 'Sun City West',
    state: 'AZ',
    latitude: '33.667527',
    longitude: '-112.351724',
    timeZoneId: 'America/Phoenix',
  },
  '85377': {
    zip: '85377',
    city: 'Carefree',
    state: 'AZ',
    latitude: '33.823262',
    longitude: '-111.90478',
    timeZoneId: 'America/Phoenix',
  },
  '85378': {
    zip: '85378',
    city: 'Surprise',
    state: 'AZ',
    latitude: '33.637662',
    longitude: '-112.340663',
    timeZoneId: 'America/Phoenix',
  },
  '85379': {
    zip: '85379',
    city: 'Surprise',
    state: 'AZ',
    latitude: '33.619029',
    longitude: '-112.409147',
    timeZoneId: 'America/Phoenix',
  },
  '85380': {
    zip: '85380',
    city: 'Peoria',
    state: 'AZ',
    latitude: '33.688644',
    longitude: '-112.244481',
    timeZoneId: 'America/Phoenix',
  },
  '85381': {
    zip: '85381',
    city: 'Peoria',
    state: 'AZ',
    latitude: '33.613599',
    longitude: '-112.23753',
    timeZoneId: 'America/Phoenix',
  },
  '85382': {
    zip: '85382',
    city: 'Peoria',
    state: 'AZ',
    latitude: '33.649198',
    longitude: '-112.247486',
    timeZoneId: 'America/Phoenix',
  },
  '85383': {
    zip: '85383',
    city: 'Peoria',
    state: 'AZ',
    latitude: '33.658608',
    longitude: '-112.247236',
    timeZoneId: 'America/Phoenix',
  },
  '85385': {
    zip: '85385',
    city: 'Peoria',
    state: 'AZ',
    latitude: '33.583811',
    longitude: '-112.234513',
    timeZoneId: 'America/Phoenix',
  },
  '85387': {
    zip: '85387',
    city: 'Surprise',
    state: 'AZ',
    latitude: '33.67805',
    longitude: '-112.468506',
    timeZoneId: 'America/Phoenix',
  },
  '85388': {
    zip: '85388',
    city: 'Surprise',
    state: 'AZ',
    latitude: '33.6134',
    longitude: '-112.4512',
    timeZoneId: 'America/Phoenix',
  },
  '85390': {
    zip: '85390',
    city: 'Wickenburg',
    state: 'AZ',
    latitude: '33.96658',
    longitude: '-112.741801',
    timeZoneId: 'America/Phoenix',
  },
  '85392': {
    zip: '85392',
    city: 'Avondale',
    state: 'AZ',
    latitude: '33.471893',
    longitude: '-112.276769',
    timeZoneId: 'America/Phoenix',
  },
  '85395': {
    zip: '85395',
    city: 'Goodyear',
    state: 'AZ',
    latitude: '33.489384',
    longitude: '-112.392612',
    timeZoneId: 'America/Phoenix',
  },
  '85396': {
    zip: '85396',
    city: 'Buckeye',
    state: 'AZ',
    latitude: '33.468',
    longitude: '-112.4787',
    timeZoneId: 'America/Phoenix',
  },
  '85501': {
    zip: '85501',
    city: 'Globe',
    state: 'AZ',
    latitude: '33.394116',
    longitude: '-110.783011',
    timeZoneId: 'America/Phoenix',
  },
  '85502': {
    zip: '85502',
    city: 'Globe',
    state: 'AZ',
    latitude: '33.390002',
    longitude: '-110.784264',
    timeZoneId: 'America/Phoenix',
  },
  '85530': {
    zip: '85530',
    city: 'Bylas',
    state: 'AZ',
    latitude: '33.10542',
    longitude: '-110.225621',
    timeZoneId: 'America/Phoenix',
  },
  '85531': {
    zip: '85531',
    city: 'Central',
    state: 'AZ',
    latitude: '32.866645',
    longitude: '-109.788905',
    timeZoneId: 'America/Phoenix',
  },
  '85532': {
    zip: '85532',
    city: 'Claypool',
    state: 'AZ',
    latitude: '33.411965',
    longitude: '-110.831503',
    timeZoneId: 'America/Phoenix',
  },
  '85533': {
    zip: '85533',
    city: 'Clifton',
    state: 'AZ',
    latitude: '33.045381',
    longitude: '-109.294642',
    timeZoneId: 'America/Phoenix',
  },
  '85534': {
    zip: '85534',
    city: 'Duncan',
    state: 'AZ',
    latitude: '32.714671',
    longitude: '-109.100465',
    timeZoneId: 'America/Phoenix',
  },
  '85535': {
    zip: '85535',
    city: 'Eden',
    state: 'AZ',
    latitude: '32.976244',
    longitude: '-109.899988',
    timeZoneId: 'America/Phoenix',
  },
  '85536': {
    zip: '85536',
    city: 'Fort Thomas',
    state: 'AZ',
    latitude: '32.994692',
    longitude: '-109.974738',
    timeZoneId: 'America/Phoenix',
  },
  '85539': {
    zip: '85539',
    city: 'Miami',
    state: 'AZ',
    latitude: '33.40842',
    longitude: '-110.868176',
    timeZoneId: 'America/Phoenix',
  },
  '85540': {
    zip: '85540',
    city: 'Morenci',
    state: 'AZ',
    latitude: '33.045935',
    longitude: '-109.32566',
    timeZoneId: 'America/Phoenix',
  },
  '85541': {
    zip: '85541',
    city: 'Payson',
    state: 'AZ',
    latitude: '34.2036',
    longitude: '-111.28986',
    timeZoneId: 'America/Phoenix',
  },
  '85542': {
    zip: '85542',
    city: 'Peridot',
    state: 'AZ',
    latitude: '33.31519',
    longitude: '-110.45154',
    timeZoneId: 'America/Phoenix',
  },
  '85543': {
    zip: '85543',
    city: 'Pima',
    state: 'AZ',
    latitude: '32.883664',
    longitude: '-109.832708',
    timeZoneId: 'America/Phoenix',
  },
  '85544': {
    zip: '85544',
    city: 'Pine',
    state: 'AZ',
    latitude: '34.396467',
    longitude: '-111.453745',
    timeZoneId: 'America/Phoenix',
  },
  '85545': {
    zip: '85545',
    city: 'Roosevelt',
    state: 'AZ',
    latitude: '33.619048',
    longitude: '-111.00027',
    timeZoneId: 'America/Phoenix',
  },
  '85546': {
    zip: '85546',
    city: 'Safford',
    state: 'AZ',
    latitude: '32.809537',
    longitude: '-109.716115',
    timeZoneId: 'America/Phoenix',
  },
  '85547': {
    zip: '85547',
    city: 'Payson',
    state: 'AZ',
    latitude: '34.258255',
    longitude: '-111.279726',
    timeZoneId: 'America/Phoenix',
  },
  '85548': {
    zip: '85548',
    city: 'Safford',
    state: 'AZ',
    latitude: '32.769268',
    longitude: '-109.711887',
    timeZoneId: 'America/Phoenix',
  },
  '85550': {
    zip: '85550',
    city: 'San Carlos',
    state: 'AZ',
    latitude: '33.36501',
    longitude: '-110.457515',
    timeZoneId: 'America/Phoenix',
  },
  '85551': {
    zip: '85551',
    city: 'Solomon',
    state: 'AZ',
    latitude: '32.816863',
    longitude: '-109.625659',
    timeZoneId: 'America/Phoenix',
  },
  '85552': {
    zip: '85552',
    city: 'Thatcher',
    state: 'AZ',
    latitude: '32.830751',
    longitude: '-109.760629',
    timeZoneId: 'America/Phoenix',
  },
  '85553': {
    zip: '85553',
    city: 'Tonto Basin',
    state: 'AZ',
    latitude: '34.090332',
    longitude: '-111.170694',
    timeZoneId: 'America/Phoenix',
  },
  '85554': {
    zip: '85554',
    city: 'Young',
    state: 'AZ',
    latitude: '34.089269',
    longitude: '-111.169619',
    timeZoneId: 'America/Phoenix',
  },
  '85601': {
    zip: '85601',
    city: 'Arivaca',
    state: 'AZ',
    latitude: '31.577995',
    longitude: '-111.329883',
    timeZoneId: 'America/Phoenix',
  },
  '85602': {
    zip: '85602',
    city: 'Benson',
    state: 'AZ',
    latitude: '31.965389',
    longitude: '-110.296656',
    timeZoneId: 'America/Phoenix',
  },
  '85603': {
    zip: '85603',
    city: 'Bisbee',
    state: 'AZ',
    latitude: '31.45884',
    longitude: '-109.9458',
    timeZoneId: 'America/Phoenix',
  },
  '85605': {
    zip: '85605',
    city: 'Bowie',
    state: 'AZ',
    latitude: '32.163358',
    longitude: '-109.335116',
    timeZoneId: 'America/Phoenix',
  },
  '85606': {
    zip: '85606',
    city: 'Cochise',
    state: 'AZ',
    latitude: '32.006439',
    longitude: '-109.880346',
    timeZoneId: 'America/Phoenix',
  },
  '85607': {
    zip: '85607',
    city: 'Douglas',
    state: 'AZ',
    latitude: '31.351622',
    longitude: '-109.543515',
    timeZoneId: 'America/Phoenix',
  },
  '85608': {
    zip: '85608',
    city: 'Douglas',
    state: 'AZ',
    latitude: '31.350792',
    longitude: '-109.550363',
    timeZoneId: 'America/Phoenix',
  },
  '85609': {
    zip: '85609',
    city: 'Dragoon',
    state: 'AZ',
    latitude: '32.029931',
    longitude: '-110.047592',
    timeZoneId: 'America/Phoenix',
  },
  '85610': {
    zip: '85610',
    city: 'Elfrida',
    state: 'AZ',
    latitude: '31.719147',
    longitude: '-109.464014',
    timeZoneId: 'America/Phoenix',
  },
  '85611': {
    zip: '85611',
    city: 'Elgin',
    state: 'AZ',
    latitude: '31.615365',
    longitude: '-110.515379',
    timeZoneId: 'America/Phoenix',
  },
  '85613': {
    zip: '85613',
    city: 'Fort Huachuca',
    state: 'AZ',
    latitude: '31.551784',
    longitude: '-110.347231',
    timeZoneId: 'America/Phoenix',
  },
  '85614': {
    zip: '85614',
    city: 'Green Valley',
    state: 'AZ',
    latitude: '31.859137',
    longitude: '-111.000231',
    timeZoneId: 'America/Phoenix',
  },
  '85615': {
    zip: '85615',
    city: 'Hereford',
    state: 'AZ',
    latitude: '31.40292',
    longitude: '-110.20734',
    timeZoneId: 'America/Phoenix',
  },
  '85616': {
    zip: '85616',
    city: 'Huachuca City',
    state: 'AZ',
    latitude: '31.680383',
    longitude: '-110.31697',
    timeZoneId: 'America/Phoenix',
  },
  '85617': {
    zip: '85617',
    city: 'Mc Neal',
    state: 'AZ',
    latitude: '31.523874',
    longitude: '-109.682735',
    timeZoneId: 'America/Phoenix',
  },
  '85618': {
    zip: '85618',
    city: 'Mammoth',
    state: 'AZ',
    latitude: '32.706378',
    longitude: '-110.655407',
    timeZoneId: 'America/Phoenix',
  },
  '85619': {
    zip: '85619',
    city: 'Mount Lemmon',
    state: 'AZ',
    latitude: '32.427139',
    longitude: '-110.799542',
    timeZoneId: 'America/Phoenix',
  },
  '85620': {
    zip: '85620',
    city: 'Naco',
    state: 'AZ',
    latitude: '31.340161',
    longitude: '-109.941367',
    timeZoneId: 'America/Phoenix',
  },
  '85621': {
    zip: '85621',
    city: 'Nogales',
    state: 'AZ',
    latitude: '31.382641',
    longitude: '-110.94176',
    timeZoneId: 'America/Phoenix',
  },
  '85622': {
    zip: '85622',
    city: 'Green Valley',
    state: 'AZ',
    latitude: '31.844512',
    longitude: '-111.009938',
    timeZoneId: 'America/Phoenix',
  },
  '85623': {
    zip: '85623',
    city: 'Oracle',
    state: 'AZ',
    latitude: '32.616554',
    longitude: '-110.794397',
    timeZoneId: 'America/Phoenix',
  },
  '85624': {
    zip: '85624',
    city: 'Patagonia',
    state: 'AZ',
    latitude: '31.532257',
    longitude: '-110.747116',
    timeZoneId: 'America/Phoenix',
  },
  '85625': {
    zip: '85625',
    city: 'Pearce',
    state: 'AZ',
    latitude: '31.935591',
    longitude: '-109.803528',
    timeZoneId: 'America/Phoenix',
  },
  '85626': {
    zip: '85626',
    city: 'Pirtleville',
    state: 'AZ',
    latitude: '31.359283',
    longitude: '-109.573433',
    timeZoneId: 'America/Phoenix',
  },
  '85627': {
    zip: '85627',
    city: 'Pomerene',
    state: 'AZ',
    latitude: '32.175856',
    longitude: '-110.256989',
    timeZoneId: 'America/Phoenix',
  },
  '85628': {
    zip: '85628',
    city: 'Nogales',
    state: 'AZ',
    latitude: '31.414484',
    longitude: '-110.893584',
    timeZoneId: 'America/Phoenix',
  },
  '85629': {
    zip: '85629',
    city: 'Sahuarita',
    state: 'AZ',
    latitude: '31.952136',
    longitude: '-110.997432',
    timeZoneId: 'America/Phoenix',
  },
  '85630': {
    zip: '85630',
    city: 'Saint David',
    state: 'AZ',
    latitude: '31.921429',
    longitude: '-110.233684',
    timeZoneId: 'America/Phoenix',
  },
  '85631': {
    zip: '85631',
    city: 'San Manuel',
    state: 'AZ',
    latitude: '32.592943',
    longitude: '-110.61482',
    timeZoneId: 'America/Phoenix',
  },
  '85632': {
    zip: '85632',
    city: 'San Simon',
    state: 'AZ',
    latitude: '32.17758',
    longitude: '-109.37988',
    timeZoneId: 'America/Phoenix',
  },
  '85633': {
    zip: '85633',
    city: 'Sasabe',
    state: 'AZ',
    latitude: '31.486582',
    longitude: '-111.543709',
    timeZoneId: 'America/Hermosillo',
  },
  '85634': {
    zip: '85634',
    city: 'Sells',
    state: 'AZ',
    latitude: '31.91',
    longitude: '-111.88',
    timeZoneId: 'America/Phoenix',
  },
  '85635': {
    zip: '85635',
    city: 'Sierra Vista',
    state: 'AZ',
    latitude: '31.528599',
    longitude: '-110.2598',
    timeZoneId: 'America/Phoenix',
  },
  '85636': {
    zip: '85636',
    city: 'Sierra Vista',
    state: 'AZ',
    latitude: '31.470032',
    longitude: '-110.187121',
    timeZoneId: 'America/Phoenix',
  },
  '85637': {
    zip: '85637',
    city: 'Sonoita',
    state: 'AZ',
    latitude: '31.622239',
    longitude: '-110.706222',
    timeZoneId: 'America/Phoenix',
  },
  '85638': {
    zip: '85638',
    city: 'Tombstone',
    state: 'AZ',
    latitude: '31.709811',
    longitude: '-110.063648',
    timeZoneId: 'America/Phoenix',
  },
  '85639': {
    zip: '85639',
    city: 'Topawa',
    state: 'AZ',
    latitude: '31.811855',
    longitude: '-111.830315',
    timeZoneId: 'America/Phoenix',
  },
  '85640': {
    zip: '85640',
    city: 'Tumacacori',
    state: 'AZ',
    latitude: '31.596899',
    longitude: '-111.04779',
    timeZoneId: 'America/Phoenix',
  },
  '85641': {
    zip: '85641',
    city: 'Vail',
    state: 'AZ',
    latitude: '31.983984',
    longitude: '-110.689294',
    timeZoneId: 'America/Phoenix',
  },
  '85643': {
    zip: '85643',
    city: 'Willcox',
    state: 'AZ',
    latitude: '32.36172',
    longitude: '-109.88676',
    timeZoneId: 'America/Phoenix',
  },
  '85644': {
    zip: '85644',
    city: 'Willcox',
    state: 'AZ',
    latitude: '32.253921',
    longitude: '-109.835996',
    timeZoneId: 'America/Phoenix',
  },
  '85645': {
    zip: '85645',
    city: 'Amado',
    state: 'AZ',
    latitude: '31.568611',
    longitude: '-111.051667',
    timeZoneId: 'America/Phoenix',
  },
  '85646': {
    zip: '85646',
    city: 'Tubac',
    state: 'AZ',
    latitude: '31.609126',
    longitude: '-111.051323',
    timeZoneId: 'America/Phoenix',
  },
  '85648': {
    zip: '85648',
    city: 'Rio Rico',
    state: 'AZ',
    latitude: '31.48626',
    longitude: '-111.003',
    timeZoneId: 'America/Phoenix',
  },
  '85650': {
    zip: '85650',
    city: 'Sierra Vista',
    state: 'AZ',
    latitude: '31.495604',
    longitude: '-110.262376',
    timeZoneId: 'America/Phoenix',
  },
  '85652': {
    zip: '85652',
    city: 'Cortaro',
    state: 'AZ',
    latitude: '32.384868',
    longitude: '-111.114433',
    timeZoneId: 'America/Phoenix',
  },
  '85653': {
    zip: '85653',
    city: 'Marana',
    state: 'AZ',
    latitude: '32.414714',
    longitude: '-111.294865',
    timeZoneId: 'America/Phoenix',
  },
  '85654': {
    zip: '85654',
    city: 'Rillito',
    state: 'AZ',
    latitude: '32.422677',
    longitude: '-111.105357',
    timeZoneId: 'America/Phoenix',
  },
  '85655': {
    zip: '85655',
    city: 'Douglas',
    state: 'AZ',
    latitude: '31.339191',
    longitude: '-109.529628',
    timeZoneId: 'America/Phoenix',
  },
  '85658': {
    zip: '85658',
    city: 'Marana',
    state: 'AZ',
    latitude: '32.436756',
    longitude: '-111.083038',
    timeZoneId: 'America/Phoenix',
  },
  '85670': {
    zip: '85670',
    city: 'Fort Huachuca',
    state: 'AZ',
    latitude: '31.55',
    longitude: '-110.35',
    timeZoneId: 'America/Phoenix',
  },
  '85671': {
    zip: '85671',
    city: 'Sierra Vista',
    state: 'AZ',
    latitude: '31.566116',
    longitude: '-110.325396',
    timeZoneId: 'America/Phoenix',
  },
  '85701': {
    zip: '85701',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.218157',
    longitude: '-110.971902',
    timeZoneId: 'America/Phoenix',
  },
  '85702': {
    zip: '85702',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.216662',
    longitude: '-110.970699',
    timeZoneId: 'America/Phoenix',
  },
  '85703': {
    zip: '85703',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.272599',
    longitude: '-110.990622',
    timeZoneId: 'America/Phoenix',
  },
  '85704': {
    zip: '85704',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.32818',
    longitude: '-110.98608',
    timeZoneId: 'America/Phoenix',
  },
  '85705': {
    zip: '85705',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.26608',
    longitude: '-110.99898',
    timeZoneId: 'America/Phoenix',
  },
  '85706': {
    zip: '85706',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.1177',
    longitude: '-110.8941',
    timeZoneId: 'America/Phoenix',
  },
  '85707': {
    zip: '85707',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.182027',
    longitude: '-110.86863',
    timeZoneId: 'America/Phoenix',
  },
  '85708': {
    zip: '85708',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.184649',
    longitude: '-110.865478',
    timeZoneId: 'America/Phoenix',
  },
  '85709': {
    zip: '85709',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.231493',
    longitude: '-111.013919',
    timeZoneId: 'America/Phoenix',
  },
  '85710': {
    zip: '85710',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.21416',
    longitude: '-110.82923',
    timeZoneId: 'America/Phoenix',
  },
  '85711': {
    zip: '85711',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.218469',
    longitude: '-110.887678',
    timeZoneId: 'America/Phoenix',
  },
  '85712': {
    zip: '85712',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.253883',
    longitude: '-110.884936',
    timeZoneId: 'America/Phoenix',
  },
  '85713': {
    zip: '85713',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.19144',
    longitude: '-111.0627',
    timeZoneId: 'America/Phoenix',
  },
  '85714': {
    zip: '85714',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.17507',
    longitude: '-110.966248',
    timeZoneId: 'America/Phoenix',
  },
  '85715': {
    zip: '85715',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.274389',
    longitude: '-110.82894',
    timeZoneId: 'America/Phoenix',
  },
  '85716': {
    zip: '85716',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.244884',
    longitude: '-110.921446',
    timeZoneId: 'America/Phoenix',
  },
  '85717': {
    zip: '85717',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.243556',
    longitude: '-110.949722',
    timeZoneId: 'America/Phoenix',
  },
  '85718': {
    zip: '85718',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.31114',
    longitude: '-110.920394',
    timeZoneId: 'America/Phoenix',
  },
  '85719': {
    zip: '85719',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.241787',
    longitude: '-110.946837',
    timeZoneId: 'America/Phoenix',
  },
  '85720': {
    zip: '85720',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.196799',
    longitude: '-110.891702',
    timeZoneId: 'America/Phoenix',
  },
  '85721': {
    zip: '85721',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.228886',
    longitude: '-110.952157',
    timeZoneId: 'America/Phoenix',
  },
  '85722': {
    zip: '85722',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.235135',
    longitude: '-110.939552',
    timeZoneId: 'America/Phoenix',
  },
  '85723': {
    zip: '85723',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.19443',
    longitude: '-111.005542',
    timeZoneId: 'America/Phoenix',
  },
  '85724': {
    zip: '85724',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.245835',
    longitude: '-110.94445',
    timeZoneId: 'America/Phoenix',
  },
  '85725': {
    zip: '85725',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.199047',
    longitude: '-111.013265',
    timeZoneId: 'America/Phoenix',
  },
  '85726': {
    zip: '85726',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.204294',
    longitude: '-110.943319',
    timeZoneId: 'America/Phoenix',
  },
  '85728': {
    zip: '85728',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.309471',
    longitude: '-110.91661',
    timeZoneId: 'America/Phoenix',
  },
  '85730': {
    zip: '85730',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.177877',
    longitude: '-110.803001',
    timeZoneId: 'America/Phoenix',
  },
  '85731': {
    zip: '85731',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.222821',
    longitude: '-110.758947',
    timeZoneId: 'America/Phoenix',
  },
  '85732': {
    zip: '85732',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.21561',
    longitude: '-110.881959',
    timeZoneId: 'America/Phoenix',
  },
  '85733': {
    zip: '85733',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.085766',
    longitude: '-111.071379',
    timeZoneId: 'America/Phoenix',
  },
  '85734': {
    zip: '85734',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.114902',
    longitude: '-110.8943',
    timeZoneId: 'America/Phoenix',
  },
  '85735': {
    zip: '85735',
    city: 'Tucson',
    state: 'AZ',
    latitude: '31.953898',
    longitude: '-111.342837',
    timeZoneId: 'America/Phoenix',
  },
  '85736': {
    zip: '85736',
    city: 'Tucson',
    state: 'AZ',
    latitude: '31.663202',
    longitude: '-111.392856',
    timeZoneId: 'America/Phoenix',
  },
  '85737': {
    zip: '85737',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.425216',
    longitude: '-110.957657',
    timeZoneId: 'America/Phoenix',
  },
  '85738': {
    zip: '85738',
    city: 'Catalina',
    state: 'AZ',
    latitude: '32.221667',
    longitude: '-110.925833',
    timeZoneId: 'America/Phoenix',
  },
  '85739': {
    zip: '85739',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.221743',
    longitude: '-110.926479',
    timeZoneId: 'America/Phoenix',
  },
  '85740': {
    zip: '85740',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.357607',
    longitude: '-111.076619',
    timeZoneId: 'America/Phoenix',
  },
  '85741': {
    zip: '85741',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.357439',
    longitude: '-111.044532',
    timeZoneId: 'America/Phoenix',
  },
  '85742': {
    zip: '85742',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.419441',
    longitude: '-111.059263',
    timeZoneId: 'America/Phoenix',
  },
  '85743': {
    zip: '85743',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.297571',
    longitude: '-111.149041',
    timeZoneId: 'America/Phoenix',
  },
  '85744': {
    zip: '85744',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.047559',
    longitude: '-110.673722',
    timeZoneId: 'America/Phoenix',
  },
  '85745': {
    zip: '85745',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.250456',
    longitude: '-111.030356',
    timeZoneId: 'America/Phoenix',
  },
  '85746': {
    zip: '85746',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.082299',
    longitude: '-111.092793',
    timeZoneId: 'America/Phoenix',
  },
  '85747': {
    zip: '85747',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.043928',
    longitude: '-110.735754',
    timeZoneId: 'America/Phoenix',
  },
  '85748': {
    zip: '85748',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.212606',
    longitude: '-110.769648',
    timeZoneId: 'America/Phoenix',
  },
  '85749': {
    zip: '85749',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.270941',
    longitude: '-110.767721',
    timeZoneId: 'America/Phoenix',
  },
  '85750': {
    zip: '85750',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.29101',
    longitude: '-110.842443',
    timeZoneId: 'America/Phoenix',
  },
  '85751': {
    zip: '85751',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.248543',
    longitude: '-110.834322',
    timeZoneId: 'America/Phoenix',
  },
  '85752': {
    zip: '85752',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.351982',
    longitude: '-111.048553',
    timeZoneId: 'America/Phoenix',
  },
  '85754': {
    zip: '85754',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.233911',
    longitude: '-111.004513',
    timeZoneId: 'America/Phoenix',
  },
  '85755': {
    zip: '85755',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.4486',
    longitude: '-110.9648',
    timeZoneId: 'America/Phoenix',
  },
  '85756': {
    zip: '85756',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.136236',
    longitude: '-111.085365',
    timeZoneId: 'America/Phoenix',
  },
  '85757': {
    zip: '85757',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.1363',
    longitude: '-111.0861',
    timeZoneId: 'America/Phoenix',
  },
  '85775': {
    zip: '85775',
    city: 'Tucson',
    state: 'AZ',
    latitude: '32.215483',
    longitude: '-110.970828',
    timeZoneId: 'America/Phoenix',
  },
  '85901': {
    zip: '85901',
    city: 'Show Low',
    state: 'AZ',
    latitude: '34.24152',
    longitude: '-110.07732',
    timeZoneId: 'America/Phoenix',
  },
  '85902': {
    zip: '85902',
    city: 'Show Low',
    state: 'AZ',
    latitude: '34.252928',
    longitude: '-110.029914',
    timeZoneId: 'America/Phoenix',
  },
  '85911': {
    zip: '85911',
    city: 'Cibecue',
    state: 'AZ',
    latitude: '34.0467',
    longitude: '-110.484957',
    timeZoneId: 'America/Phoenix',
  },
  '85912': {
    zip: '85912',
    city: 'White Mountain Lake',
    state: 'AZ',
    latitude: '34.322282',
    longitude: '-110.075598',
    timeZoneId: 'America/Phoenix',
  },
  '85920': {
    zip: '85920',
    city: 'Alpine',
    state: 'AZ',
    latitude: '33.815834',
    longitude: '-109.202378',
    timeZoneId: 'America/Phoenix',
  },
  '85922': {
    zip: '85922',
    city: 'Blue',
    state: 'AZ',
    latitude: '33.58057',
    longitude: '-109.096805',
    timeZoneId: 'America/Phoenix',
  },
  '85923': {
    zip: '85923',
    city: 'Clay Springs',
    state: 'AZ',
    latitude: '34.360411',
    longitude: '-110.296265',
    timeZoneId: 'America/Phoenix',
  },
  '85924': {
    zip: '85924',
    city: 'Concho',
    state: 'AZ',
    latitude: '34.4286',
    longitude: '-109.68912',
    timeZoneId: 'America/Phoenix',
  },
  '85925': {
    zip: '85925',
    city: 'Eagar',
    state: 'AZ',
    latitude: '34.119507',
    longitude: '-109.290239',
    timeZoneId: 'America/Phoenix',
  },
  '85926': {
    zip: '85926',
    city: 'Fort Apache',
    state: 'AZ',
    latitude: '33.789489',
    longitude: '-109.989521',
    timeZoneId: 'America/Phoenix',
  },
  '85927': {
    zip: '85927',
    city: 'Greer',
    state: 'AZ',
    latitude: '34.013292',
    longitude: '-109.457688',
    timeZoneId: 'America/Phoenix',
  },
  '85928': {
    zip: '85928',
    city: 'Heber',
    state: 'AZ',
    latitude: '34.428988',
    longitude: '-110.54312',
    timeZoneId: 'America/Phoenix',
  },
  '85929': {
    zip: '85929',
    city: 'Lakeside',
    state: 'AZ',
    latitude: '34.122542',
    longitude: '-109.947213',
    timeZoneId: 'America/Phoenix',
  },
  '85930': {
    zip: '85930',
    city: 'McNary',
    state: 'AZ',
    latitude: '33.983889',
    longitude: '-109.746389',
    timeZoneId: 'America/Phoenix',
  },
  '85931': {
    zip: '85931',
    city: 'Forest Lakes',
    state: 'AZ',
    latitude: '34.626034',
    longitude: '-111.111768',
    timeZoneId: 'America/Phoenix',
  },
  '85932': {
    zip: '85932',
    city: 'Nutrioso',
    state: 'AZ',
    latitude: '33.954516',
    longitude: '-109.209744',
    timeZoneId: 'America/Phoenix',
  },
  '85933': {
    zip: '85933',
    city: 'Overgaard',
    state: 'AZ',
    latitude: '34.389598',
    longitude: '-110.552466',
    timeZoneId: 'America/Phoenix',
  },
  '85934': {
    zip: '85934',
    city: 'Pinedale',
    state: 'AZ',
    latitude: '34.306291',
    longitude: '-110.24808',
    timeZoneId: 'America/Phoenix',
  },
  '85935': {
    zip: '85935',
    city: 'Pinetop',
    state: 'AZ',
    latitude: '34.122099',
    longitude: '-109.925677',
    timeZoneId: 'America/Phoenix',
  },
  '85936': {
    zip: '85936',
    city: 'Saint Johns',
    state: 'AZ',
    latitude: '34.506335',
    longitude: '-109.378069',
    timeZoneId: 'America/Phoenix',
  },
  '85937': {
    zip: '85937',
    city: 'Snowflake',
    state: 'AZ',
    latitude: '34.510876',
    longitude: '-110.094754',
    timeZoneId: 'America/Phoenix',
  },
  '85938': {
    zip: '85938',
    city: 'Springerville',
    state: 'AZ',
    latitude: '34.132976',
    longitude: '-109.279764',
    timeZoneId: 'America/Phoenix',
  },
  '85939': {
    zip: '85939',
    city: 'Taylor',
    state: 'AZ',
    latitude: '34.458495',
    longitude: '-110.079577',
    timeZoneId: 'America/Phoenix',
  },
  '85940': {
    zip: '85940',
    city: 'Vernon',
    state: 'AZ',
    latitude: '34.255946',
    longitude: '-109.692861',
    timeZoneId: 'America/Phoenix',
  },
  '85941': {
    zip: '85941',
    city: 'Whiteriver',
    state: 'AZ',
    latitude: '33.804762',
    longitude: '-109.995741',
    timeZoneId: 'America/Phoenix',
  },
  '85942': {
    zip: '85942',
    city: 'Woodruff',
    state: 'AZ',
    latitude: '34.782119',
    longitude: '-110.045248',
    timeZoneId: 'America/Phoenix',
  },
  '86001': {
    zip: '86001',
    city: 'Flagstaff',
    state: 'AZ',
    latitude: '35.13996',
    longitude: '-111.63408',
    timeZoneId: 'America/Phoenix',
  },
  '86002': {
    zip: '86002',
    city: 'Flagstaff',
    state: 'AZ',
    latitude: '35.23374',
    longitude: '-111.565921',
    timeZoneId: 'America/Phoenix',
  },
  '86003': {
    zip: '86003',
    city: 'Flagstaff',
    state: 'AZ',
    latitude: '35.227378',
    longitude: '-111.570364',
    timeZoneId: 'America/Phoenix',
  },
  '86004': {
    zip: '86004',
    city: 'Flagstaff',
    state: 'AZ',
    latitude: '35.225571',
    longitude: '-111.5715',
    timeZoneId: 'America/Phoenix',
  },
  '86005': {
    zip: '86005',
    city: 'Flagstaff',
    state: 'AZ',
    latitude: '35.08095',
    longitude: '-111.664819',
    timeZoneId: 'America/Phoenix',
  },
  '86011': {
    zip: '86011',
    city: 'Flagstaff',
    state: 'AZ',
    latitude: '35.188945',
    longitude: '-111.620766',
    timeZoneId: 'America/Phoenix',
  },
  '86015': {
    zip: '86015',
    city: 'Bellemont',
    state: 'AZ',
    latitude: '35.198056',
    longitude: '-111.650556',
    timeZoneId: 'America/Phoenix',
  },
  '86016': {
    zip: '86016',
    city: 'Gray Mountain',
    state: 'AZ',
    latitude: '35.746955',
    longitude: '-111.474916',
    timeZoneId: 'America/Phoenix',
  },
  '86017': {
    zip: '86017',
    city: 'Munds Park',
    state: 'AZ',
    latitude: '34.946175',
    longitude: '-111.638116',
    timeZoneId: 'America/Phoenix',
  },
  '86018': {
    zip: '86018',
    city: 'Parks',
    state: 'AZ',
    latitude: '35.260485',
    longitude: '-111.947488',
    timeZoneId: 'America/Phoenix',
  },
  '86020': {
    zip: '86020',
    city: 'Cameron',
    state: 'AZ',
    latitude: '35.848183',
    longitude: '-111.431257',
    timeZoneId: 'America/Denver',
  },
  '86021': {
    zip: '86021',
    city: 'Colorado City',
    state: 'AZ',
    latitude: '36.989828',
    longitude: '-112.977137',
    timeZoneId: 'America/Phoenix',
  },
  '86022': {
    zip: '86022',
    city: 'Fredonia',
    state: 'AZ',
    latitude: '36.945813',
    longitude: '-112.529509',
    timeZoneId: 'America/Phoenix',
  },
  '86023': {
    zip: '86023',
    city: 'Grand Canyon',
    state: 'AZ',
    latitude: '36.05442',
    longitude: '-112.13862',
    timeZoneId: 'America/Phoenix',
  },
  '86024': {
    zip: '86024',
    city: 'Happy Jack',
    state: 'AZ',
    latitude: '34.662907',
    longitude: '-111.479346',
    timeZoneId: 'America/Phoenix',
  },
  '86025': {
    zip: '86025',
    city: 'Holbrook',
    state: 'AZ',
    latitude: '34.906715',
    longitude: '-110.159202',
    timeZoneId: 'America/Phoenix',
  },
  '86028': {
    zip: '86028',
    city: 'Petrified Forest Natl Pk',
    state: 'AZ',
    latitude: '34.791446',
    longitude: '-109.878946',
    timeZoneId: 'America/Phoenix',
  },
  '86029': {
    zip: '86029',
    city: 'Sun Valley',
    state: 'AZ',
    latitude: '34.980645',
    longitude: '-110.057212',
    timeZoneId: 'America/Phoenix',
  },
  '86030': {
    zip: '86030',
    city: 'Hotevilla',
    state: 'AZ',
    latitude: '35.92141',
    longitude: '-110.67346',
    timeZoneId: 'America/Phoenix',
  },
  '86031': {
    zip: '86031',
    city: 'Indian Wells',
    state: 'AZ',
    latitude: '35.404662',
    longitude: '-110.085614',
    timeZoneId: 'America/Denver',
  },
  '86032': {
    zip: '86032',
    city: 'Joseph City',
    state: 'AZ',
    latitude: '34.964299',
    longitude: '-110.330963',
    timeZoneId: 'America/Phoenix',
  },
  '86033': {
    zip: '86033',
    city: 'Kayenta',
    state: 'AZ',
    latitude: '36.63072',
    longitude: '-110.27772',
    timeZoneId: 'America/Denver',
  },
  '86034': {
    zip: '86034',
    city: 'Keams Canyon',
    state: 'AZ',
    latitude: '35.77758',
    longitude: '-110.17182',
    timeZoneId: 'America/Denver',
  },
  '86035': {
    zip: '86035',
    city: 'Leupp',
    state: 'AZ',
    latitude: '35.288132',
    longitude: '-111.036784',
    timeZoneId: 'America/Denver',
  },
  '86036': {
    zip: '86036',
    city: 'Marble Canyon',
    state: 'AZ',
    latitude: '36.921756',
    longitude: '-111.450768',
    timeZoneId: 'America/Phoenix',
  },
  '86038': {
    zip: '86038',
    city: 'Mormon Lake',
    state: 'AZ',
    latitude: '34.944121',
    longitude: '-111.427745',
    timeZoneId: 'America/Phoenix',
  },
  '86039': {
    zip: '86039',
    city: 'Kykotsmovi Village',
    state: 'AZ',
    latitude: '35.652776',
    longitude: '-110.494156',
    timeZoneId: 'America/Phoenix',
  },
  '86040': {
    zip: '86040',
    city: 'Page',
    state: 'AZ',
    latitude: '36.913751',
    longitude: '-111.467162',
    timeZoneId: 'America/Phoenix',
  },
  '86042': {
    zip: '86042',
    city: 'Polacca',
    state: 'AZ',
    latitude: '35.967439',
    longitude: '-110.430862',
    timeZoneId: 'America/Phoenix',
  },
  '86043': {
    zip: '86043',
    city: 'Second Mesa',
    state: 'AZ',
    latitude: '35.805',
    longitude: '-110.491944',
    timeZoneId: 'America/Phoenix',
  },
  '86044': {
    zip: '86044',
    city: 'Tonalea',
    state: 'AZ',
    latitude: '35.966411',
    longitude: '-110.8766',
    timeZoneId: 'America/Phoenix',
  },
  '86045': {
    zip: '86045',
    city: 'Tuba City',
    state: 'AZ',
    latitude: '36.133993',
    longitude: '-111.242465',
    timeZoneId: 'America/Denver',
  },
  '86046': {
    zip: '86046',
    city: 'Williams',
    state: 'AZ',
    latitude: '35.289747',
    longitude: '-112.161649',
    timeZoneId: 'America/Phoenix',
  },
  '86047': {
    zip: '86047',
    city: 'Winslow',
    state: 'AZ',
    latitude: '35.27538',
    longitude: '-110.31582',
    timeZoneId: 'America/Denver',
  },
  '86052': {
    zip: '86052',
    city: 'North Rim',
    state: 'AZ',
    latitude: '36.212643',
    longitude: '-112.058276',
    timeZoneId: 'America/Phoenix',
  },
  '86053': {
    zip: '86053',
    city: 'Kaibeto',
    state: 'AZ',
    latitude: '36.59',
    longitude: '-111.07',
    timeZoneId: 'America/Denver',
  },
  '86054': {
    zip: '86054',
    city: 'Shonto',
    state: 'AZ',
    latitude: '36.592945',
    longitude: '-110.656759',
    timeZoneId: 'America/Denver',
  },
  '86301': {
    zip: '86301',
    city: 'Prescott',
    state: 'AZ',
    latitude: '34.585071',
    longitude: '-112.45906',
    timeZoneId: 'America/Phoenix',
  },
  '86302': {
    zip: '86302',
    city: 'Prescott',
    state: 'AZ',
    latitude: '34.584333',
    longitude: '-112.427352',
    timeZoneId: 'America/Phoenix',
  },
  '86303': {
    zip: '86303',
    city: 'Prescott',
    state: 'AZ',
    latitude: '34.5348',
    longitude: '-112.46994',
    timeZoneId: 'America/Phoenix',
  },
  '86304': {
    zip: '86304',
    city: 'Prescott',
    state: 'AZ',
    latitude: '34.577357',
    longitude: '-112.451141',
    timeZoneId: 'America/Phoenix',
  },
  '86305': {
    zip: '86305',
    city: 'Prescott',
    state: 'AZ',
    latitude: '34.54287',
    longitude: '-112.468447',
    timeZoneId: 'America/Phoenix',
  },
  '86312': {
    zip: '86312',
    city: 'Prescott Valley',
    state: 'AZ',
    latitude: '34.616549',
    longitude: '-112.325635',
    timeZoneId: 'America/Phoenix',
  },
  '86313': {
    zip: '86313',
    city: 'Prescott',
    state: 'AZ',
    latitude: '34.537042',
    longitude: '-112.469214',
    timeZoneId: 'America/Phoenix',
  },
  '86314': {
    zip: '86314',
    city: 'Prescott Valley',
    state: 'AZ',
    latitude: '34.605746',
    longitude: '-112.320979',
    timeZoneId: 'America/Phoenix',
  },
  '86315': {
    zip: '86315',
    city: 'Prescott Valley',
    state: 'AZ',
    latitude: '34.704297',
    longitude: '-112.291537',
    timeZoneId: 'America/Phoenix',
  },
  '86320': {
    zip: '86320',
    city: 'Ash Fork',
    state: 'AZ',
    latitude: '35.184344',
    longitude: '-112.630085',
    timeZoneId: 'America/Phoenix',
  },
  '86321': {
    zip: '86321',
    city: 'Bagdad',
    state: 'AZ',
    latitude: '34.578289',
    longitude: '-113.173531',
    timeZoneId: 'America/Phoenix',
  },
  '86322': {
    zip: '86322',
    city: 'Camp Verde',
    state: 'AZ',
    latitude: '34.557188',
    longitude: '-111.834292',
    timeZoneId: 'America/Phoenix',
  },
  '86323': {
    zip: '86323',
    city: 'Chino Valley',
    state: 'AZ',
    latitude: '34.770256',
    longitude: '-112.472328',
    timeZoneId: 'America/Phoenix',
  },
  '86324': {
    zip: '86324',
    city: 'Clarkdale',
    state: 'AZ',
    latitude: '34.759427',
    longitude: '-112.117522',
    timeZoneId: 'America/Phoenix',
  },
  '86325': {
    zip: '86325',
    city: 'Cornville',
    state: 'AZ',
    latitude: '34.724178',
    longitude: '-111.911588',
    timeZoneId: 'America/Phoenix',
  },
  '86326': {
    zip: '86326',
    city: 'Cottonwood',
    state: 'AZ',
    latitude: '34.717972',
    longitude: '-112.006354',
    timeZoneId: 'America/Phoenix',
  },
  '86327': {
    zip: '86327',
    city: 'Dewey',
    state: 'AZ',
    latitude: '34.553959',
    longitude: '-112.267851',
    timeZoneId: 'America/Phoenix',
  },
  '86329': {
    zip: '86329',
    city: 'Humboldt',
    state: 'AZ',
    latitude: '34.505187',
    longitude: '-112.22261',
    timeZoneId: 'America/Phoenix',
  },
  '86331': {
    zip: '86331',
    city: 'Jerome',
    state: 'AZ',
    latitude: '34.749967',
    longitude: '-112.105875',
    timeZoneId: 'America/Phoenix',
  },
  '86332': {
    zip: '86332',
    city: 'Kirkland',
    state: 'AZ',
    latitude: '34.433099',
    longitude: '-112.59972',
    timeZoneId: 'America/Phoenix',
  },
  '86333': {
    zip: '86333',
    city: 'Mayer',
    state: 'AZ',
    latitude: '34.316282',
    longitude: '-112.121385',
    timeZoneId: 'America/Phoenix',
  },
  '86334': {
    zip: '86334',
    city: 'Paulden',
    state: 'AZ',
    latitude: '34.885556',
    longitude: '-112.4675',
    timeZoneId: 'America/Phoenix',
  },
  '86335': {
    zip: '86335',
    city: 'Rimrock',
    state: 'AZ',
    latitude: '34.642522',
    longitude: '-111.781844',
    timeZoneId: 'America/Phoenix',
  },
  '86336': {
    zip: '86336',
    city: 'Sedona',
    state: 'AZ',
    latitude: '34.840383',
    longitude: '-111.797496',
    timeZoneId: 'America/Phoenix',
  },
  '86337': {
    zip: '86337',
    city: 'Seligman',
    state: 'AZ',
    latitude: '35.330209',
    longitude: '-112.87515',
    timeZoneId: 'America/Phoenix',
  },
  '86338': {
    zip: '86338',
    city: 'Skull Valley',
    state: 'AZ',
    latitude: '34.554634',
    longitude: '-112.710745',
    timeZoneId: 'America/Phoenix',
  },
  '86339': {
    zip: '86339',
    city: 'Sedona',
    state: 'AZ',
    latitude: '34.868999',
    longitude: '-111.766051',
    timeZoneId: 'America/Phoenix',
  },
  '86340': {
    zip: '86340',
    city: 'Sedona',
    state: 'AZ',
    latitude: '34.86435',
    longitude: '-111.79818',
    timeZoneId: 'America/Phoenix',
  },
  '86341': {
    zip: '86341',
    city: 'Sedona',
    state: 'AZ',
    latitude: '34.774138',
    longitude: '-111.762821',
    timeZoneId: 'America/Phoenix',
  },
  '86342': {
    zip: '86342',
    city: 'Lake Montezuma',
    state: 'AZ',
    latitude: '34.630923',
    longitude: '-111.776683',
    timeZoneId: 'America/Phoenix',
  },
  '86343': {
    zip: '86343',
    city: 'Crown King',
    state: 'AZ',
    latitude: '34.219023',
    longitude: '-112.305579',
    timeZoneId: 'America/Phoenix',
  },
  '86351': {
    zip: '86351',
    city: 'Sedona',
    state: 'AZ',
    latitude: '34.786583',
    longitude: '-111.761488',
    timeZoneId: 'America/Phoenix',
  },
  '86401': {
    zip: '86401',
    city: 'Kingman',
    state: 'AZ',
    latitude: '35.52402',
    longitude: '-113.99268',
    timeZoneId: 'America/Phoenix',
  },
  '86402': {
    zip: '86402',
    city: 'Kingman',
    state: 'AZ',
    latitude: '35.204732',
    longitude: '-114.070499',
    timeZoneId: 'America/Phoenix',
  },
  '86403': {
    zip: '86403',
    city: 'Lake Havasu City',
    state: 'AZ',
    latitude: '34.487063',
    longitude: '-114.330287',
    timeZoneId: 'America/Phoenix',
  },
  '86404': {
    zip: '86404',
    city: 'Lake Havasu City',
    state: 'AZ',
    latitude: '34.518339',
    longitude: '-114.324094',
    timeZoneId: 'America/Phoenix',
  },
  '86405': {
    zip: '86405',
    city: 'Lake Havasu City',
    state: 'AZ',
    latitude: '34.499836',
    longitude: '-114.3141',
    timeZoneId: 'America/Phoenix',
  },
  '86406': {
    zip: '86406',
    city: 'Lake Havasu City',
    state: 'AZ',
    latitude: '34.47411',
    longitude: '-114.284707',
    timeZoneId: 'America/Phoenix',
  },
  '86409': {
    zip: '86409',
    city: 'Kingman',
    state: 'AZ',
    latitude: '35.2445',
    longitude: '-114.0164',
    timeZoneId: 'America/Phoenix',
  },
  '86411': {
    zip: '86411',
    city: 'Hackberry',
    state: 'AZ',
    latitude: '35.369406',
    longitude: '-113.727182',
    timeZoneId: 'America/Phoenix',
  },
  '86412': {
    zip: '86412',
    city: 'Hualapai',
    state: 'AZ',
    latitude: '35.503062',
    longitude: '-113.881715',
    timeZoneId: 'America/Phoenix',
  },
  '86413': {
    zip: '86413',
    city: 'Golden Valley',
    state: 'AZ',
    latitude: '35.220638',
    longitude: '-114.219181',
    timeZoneId: 'America/Phoenix',
  },
  '86426': {
    zip: '86426',
    city: 'Fort Mohave',
    state: 'AZ',
    latitude: '35.00166',
    longitude: '-114.56306',
    timeZoneId: 'America/Phoenix',
  },
  '86427': {
    zip: '86427',
    city: 'Fort Mohave',
    state: 'AZ',
    latitude: '35.017532',
    longitude: '-114.584742',
    timeZoneId: 'America/Phoenix',
  },
  '86429': {
    zip: '86429',
    city: 'Bullhead City',
    state: 'AZ',
    latitude: '35.159216',
    longitude: '-114.476797',
    timeZoneId: 'America/Phoenix',
  },
  '86430': {
    zip: '86430',
    city: 'Bullhead City',
    state: 'AZ',
    latitude: '35.062871',
    longitude: '-114.578938',
    timeZoneId: 'America/Phoenix',
  },
  '86431': {
    zip: '86431',
    city: 'Chloride',
    state: 'AZ',
    latitude: '35.197885',
    longitude: '-114.032518',
    timeZoneId: 'America/Phoenix',
  },
  '86432': {
    zip: '86432',
    city: 'Littlefield',
    state: 'AZ',
    latitude: '36.883333',
    longitude: '-113.933333',
    timeZoneId: 'America/Phoenix',
  },
  '86433': {
    zip: '86433',
    city: 'Oatman',
    state: 'AZ',
    latitude: '35.027862',
    longitude: '-114.382287',
    timeZoneId: 'America/Phoenix',
  },
  '86434': {
    zip: '86434',
    city: 'Peach Springs',
    state: 'AZ',
    latitude: '35.5743',
    longitude: '-113.4921',
    timeZoneId: 'America/Phoenix',
  },
  '86435': {
    zip: '86435',
    city: 'Supai',
    state: 'AZ',
    latitude: '36.114214',
    longitude: '-112.523252',
    timeZoneId: 'America/Phoenix',
  },
  '86436': {
    zip: '86436',
    city: 'Topock',
    state: 'AZ',
    latitude: '34.778287',
    longitude: '-114.48391',
    timeZoneId: 'America/Phoenix',
  },
  '86437': {
    zip: '86437',
    city: 'Valentine',
    state: 'AZ',
    latitude: '35.384257',
    longitude: '-113.657991',
    timeZoneId: 'America/Phoenix',
  },
  '86438': {
    zip: '86438',
    city: 'Yucca',
    state: 'AZ',
    latitude: '34.875174',
    longitude: '-114.152406',
    timeZoneId: 'America/Phoenix',
  },
  '86439': {
    zip: '86439',
    city: 'Bullhead City',
    state: 'AZ',
    latitude: '35.148005',
    longitude: '-114.567525',
    timeZoneId: 'America/Phoenix',
  },
  '86440': {
    zip: '86440',
    city: 'Mohave Valley',
    state: 'AZ',
    latitude: '34.908895',
    longitude: '-114.541298',
    timeZoneId: 'America/Phoenix',
  },
  '86441': {
    zip: '86441',
    city: 'Dolan Springs',
    state: 'AZ',
    latitude: '35.57131',
    longitude: '-114.31171',
    timeZoneId: 'America/Phoenix',
  },
  '86442': {
    zip: '86442',
    city: 'Bullhead City',
    state: 'AZ',
    latitude: '35.097257',
    longitude: '-114.601553',
    timeZoneId: 'America/Phoenix',
  },
  '86443': {
    zip: '86443',
    city: 'Temple Bar Marina',
    state: 'AZ',
    latitude: '36.024395',
    longitude: '-114.298481',
    timeZoneId: 'America/Phoenix',
  },
  '86444': {
    zip: '86444',
    city: 'Meadview',
    state: 'AZ',
    latitude: '35.868609',
    longitude: '-114.312856',
    timeZoneId: 'America/Phoenix',
  },
  '86445': {
    zip: '86445',
    city: 'Willow Beach',
    state: 'AZ',
    latitude: '35.731831',
    longitude: '-114.378837',
    timeZoneId: 'America/Phoenix',
  },
  '86446': {
    zip: '86446',
    city: 'Mohave Valley',
    state: 'AZ',
    latitude: '34.910318',
    longitude: '-114.546307',
    timeZoneId: 'America/Phoenix',
  },
  '86502': {
    zip: '86502',
    city: 'Chambers',
    state: 'AZ',
    latitude: '35.419444',
    longitude: '-109.495833',
    timeZoneId: 'America/Denver',
  },
  '86503': {
    zip: '86503',
    city: 'Chinle',
    state: 'AZ',
    latitude: '36.159541',
    longitude: '-109.581995',
    timeZoneId: 'America/Denver',
  },
  '86504': {
    zip: '86504',
    city: 'Fort Defiance',
    state: 'AZ',
    latitude: '35.74711',
    longitude: '-109.07094',
    timeZoneId: 'America/Denver',
  },
  '86505': {
    zip: '86505',
    city: 'Ganado',
    state: 'AZ',
    latitude: '35.606072',
    longitude: '-109.455542',
    timeZoneId: 'America/Denver',
  },
  '86506': {
    zip: '86506',
    city: 'Houck',
    state: 'AZ',
    latitude: '35.389139',
    longitude: '-109.283418',
    timeZoneId: 'America/Denver',
  },
  '86507': {
    zip: '86507',
    city: 'Lukachukai',
    state: 'AZ',
    latitude: '36.4326',
    longitude: '-109.23882',
    timeZoneId: 'America/Denver',
  },
  '86508': {
    zip: '86508',
    city: 'Lupton',
    state: 'AZ',
    latitude: '35.35',
    longitude: '-109.05',
    timeZoneId: 'America/Denver',
  },
  '86510': {
    zip: '86510',
    city: 'Pinon',
    state: 'AZ',
    latitude: '35.959054',
    longitude: '-110.308821',
    timeZoneId: 'America/Phoenix',
  },
  '86511': {
    zip: '86511',
    city: 'Saint Michaels',
    state: 'AZ',
    latitude: '35.853888',
    longitude: '-109.299177',
    timeZoneId: 'America/Denver',
  },
  '86512': {
    zip: '86512',
    city: 'Sanders',
    state: 'AZ',
    latitude: '35.206267',
    longitude: '-109.332901',
    timeZoneId: 'America/Phoenix',
  },
  '86514': {
    zip: '86514',
    city: 'Teec Nos Pos',
    state: 'AZ',
    latitude: '36.750658',
    longitude: '-109.364054',
    timeZoneId: 'America/Denver',
  },
  '86515': {
    zip: '86515',
    city: 'Window Rock',
    state: 'AZ',
    latitude: '35.660965',
    longitude: '-109.102204',
    timeZoneId: 'America/Denver',
  },
  '86520': {
    zip: '86520',
    city: 'Blue Gap',
    state: 'AZ',
    latitude: '36.065671',
    longitude: '-110.08493',
    timeZoneId: 'America/Denver',
  },
  '86535': {
    zip: '86535',
    city: 'Dennehotso',
    state: 'AZ',
    latitude: '36.741436',
    longitude: '-109.85261',
    timeZoneId: 'America/Denver',
  },
  '86538': {
    zip: '86538',
    city: 'Many Farms',
    state: 'AZ',
    latitude: '36.453233',
    longitude: '-109.581542',
    timeZoneId: 'America/Denver',
  },
  '86540': {
    zip: '86540',
    city: 'Nazlini',
    state: 'AZ',
    latitude: '35.607281',
    longitude: '-109.461036',
    timeZoneId: 'America/Denver',
  },
  '86544': {
    zip: '86544',
    city: 'Red Valley',
    state: 'AZ',
    latitude: '36.750097',
    longitude: '-109.365708',
    timeZoneId: 'America/Denver',
  },
  '86545': {
    zip: '86545',
    city: 'Rock Point',
    state: 'AZ',
    latitude: '36.718409',
    longitude: '-109.622723',
    timeZoneId: 'America/Denver',
  },
  '86547': {
    zip: '86547',
    city: 'Round Rock',
    state: 'AZ',
    latitude: '36.513828',
    longitude: '-109.469489',
    timeZoneId: 'America/Denver',
  },
  '86556': {
    zip: '86556',
    city: 'Tsaile',
    state: 'AZ',
    latitude: '36.326278',
    longitude: '-109.268065',
    timeZoneId: 'America/Denver',
  },
  '87001': {
    zip: '87001',
    city: 'Algodones',
    state: 'NM',
    latitude: '35.382532',
    longitude: '-106.479515',
    timeZoneId: 'America/Denver',
  },
  '87002': {
    zip: '87002',
    city: 'Belen',
    state: 'NM',
    latitude: '34.656448',
    longitude: '-106.759548',
    timeZoneId: 'America/Denver',
  },
  '87004': {
    zip: '87004',
    city: 'Bernalillo',
    state: 'NM',
    latitude: '35.308943',
    longitude: '-106.546421',
    timeZoneId: 'America/Denver',
  },
  '87005': {
    zip: '87005',
    city: 'Bluewater',
    state: 'NM',
    latitude: '35.17691',
    longitude: '-107.897097',
    timeZoneId: 'America/Denver',
  },
  '87006': {
    zip: '87006',
    city: 'Bosque',
    state: 'NM',
    latitude: '34.561472',
    longitude: '-106.781071',
    timeZoneId: 'America/Denver',
  },
  '87007': {
    zip: '87007',
    city: 'Casa Blanca',
    state: 'NM',
    latitude: '35.000475',
    longitude: '-107.41324',
    timeZoneId: 'America/Denver',
  },
  '87008': {
    zip: '87008',
    city: 'Cedar Crest',
    state: 'NM',
    latitude: '35.133954',
    longitude: '-106.363191',
    timeZoneId: 'America/Denver',
  },
  '87009': {
    zip: '87009',
    city: 'Cedarvale',
    state: 'NM',
    latitude: '34.445675',
    longitude: '-105.733776',
    timeZoneId: 'America/Denver',
  },
  '87010': {
    zip: '87010',
    city: 'Cerrillos',
    state: 'NM',
    latitude: '35.4138',
    longitude: '-106.12482',
    timeZoneId: 'America/Denver',
  },
  '87011': {
    zip: '87011',
    city: 'Claunch',
    state: 'NM',
    latitude: '34.142312',
    longitude: '-105.992013',
    timeZoneId: 'America/Denver',
  },
  '87012': {
    zip: '87012',
    city: 'Coyote',
    state: 'NM',
    latitude: '36.178174',
    longitude: '-106.654',
    timeZoneId: 'America/Denver',
  },
  '87013': {
    zip: '87013',
    city: 'Cuba',
    state: 'NM',
    latitude: '35.86338',
    longitude: '-107.07282',
    timeZoneId: 'America/Denver',
  },
  '87014': {
    zip: '87014',
    city: 'Cubero',
    state: 'NM',
    latitude: '35.112342',
    longitude: '-107.506934',
    timeZoneId: 'America/Denver',
  },
  '87015': {
    zip: '87015',
    city: 'Edgewood',
    state: 'NM',
    latitude: '35.038748',
    longitude: '-106.187474',
    timeZoneId: 'America/Denver',
  },
  '87016': {
    zip: '87016',
    city: 'Estancia',
    state: 'NM',
    latitude: '34.77576',
    longitude: '-106.17156',
    timeZoneId: 'America/Denver',
  },
  '87017': {
    zip: '87017',
    city: 'Gallina',
    state: 'NM',
    latitude: '36.166615',
    longitude: '-106.610265',
    timeZoneId: 'America/Denver',
  },
  '87018': {
    zip: '87018',
    city: 'Counselor',
    state: 'NM',
    latitude: '36.141235',
    longitude: '-106.934837',
    timeZoneId: 'America/Denver',
  },
  '87020': {
    zip: '87020',
    city: 'Grants',
    state: 'NM',
    latitude: '35.159241',
    longitude: '-107.836997',
    timeZoneId: 'America/Denver',
  },
  '87021': {
    zip: '87021',
    city: 'Milan',
    state: 'NM',
    latitude: '35.170117',
    longitude: '-107.888998',
    timeZoneId: 'America/Denver',
  },
  '87022': {
    zip: '87022',
    city: 'Isleta',
    state: 'NM',
    latitude: '34.92205',
    longitude: '-106.699627',
    timeZoneId: 'America/Denver',
  },
  '87023': {
    zip: '87023',
    city: 'Jarales',
    state: 'NM',
    latitude: '34.599154',
    longitude: '-106.759615',
    timeZoneId: 'America/Denver',
  },
  '87024': {
    zip: '87024',
    city: 'Jemez Pueblo',
    state: 'NM',
    latitude: '35.608527',
    longitude: '-106.741853',
    timeZoneId: 'America/Denver',
  },
  '87025': {
    zip: '87025',
    city: 'Jemez Springs',
    state: 'NM',
    latitude: '35.764625',
    longitude: '-106.921242',
    timeZoneId: 'America/Denver',
  },
  '87026': {
    zip: '87026',
    city: 'Laguna',
    state: 'NM',
    latitude: '34.876024',
    longitude: '-107.285992',
    timeZoneId: 'America/Denver',
  },
  '87027': {
    zip: '87027',
    city: 'La Jara',
    state: 'NM',
    latitude: '36.110729',
    longitude: '-106.99379',
    timeZoneId: 'America/Denver',
  },
  '87028': {
    zip: '87028',
    city: 'La Joya',
    state: 'NM',
    latitude: '34.390372',
    longitude: '-106.803839',
    timeZoneId: 'America/Denver',
  },
  '87029': {
    zip: '87029',
    city: 'Lindrith',
    state: 'NM',
    latitude: '36.429722',
    longitude: '-107.087222',
    timeZoneId: 'America/Denver',
  },
  '87031': {
    zip: '87031',
    city: 'Los Lunas',
    state: 'NM',
    latitude: '34.784421',
    longitude: '-106.692838',
    timeZoneId: 'America/Denver',
  },
  '87032': {
    zip: '87032',
    city: 'McIntosh',
    state: 'NM',
    latitude: '34.748784',
    longitude: '-106.157511',
    timeZoneId: 'America/Denver',
  },
  '87034': {
    zip: '87034',
    city: 'Pueblo of Acoma',
    state: 'NM',
    latitude: '35.084674',
    longitude: '-107.598482',
    timeZoneId: 'America/Denver',
  },
  '87035': {
    zip: '87035',
    city: 'Moriarty',
    state: 'NM',
    latitude: '34.959132',
    longitude: '-106.054681',
    timeZoneId: 'America/Denver',
  },
  '87036': {
    zip: '87036',
    city: 'Mountainair',
    state: 'NM',
    latitude: '34.524327',
    longitude: '-106.237998',
    timeZoneId: 'America/Denver',
  },
  '87037': {
    zip: '87037',
    city: 'Nageezi',
    state: 'NM',
    latitude: '36.420833',
    longitude: '-107.866542',
    timeZoneId: 'America/Denver',
  },
  '87038': {
    zip: '87038',
    city: 'New Laguna',
    state: 'NM',
    latitude: '35.002693',
    longitude: '-107.413459',
    timeZoneId: 'America/Denver',
  },
  '87040': {
    zip: '87040',
    city: 'Paguate',
    state: 'NM',
    latitude: '34.875526',
    longitude: '-107.2827',
    timeZoneId: 'America/Denver',
  },
  '87041': {
    zip: '87041',
    city: 'Pena Blanca',
    state: 'NM',
    latitude: '35.63832',
    longitude: '-106.34862',
    timeZoneId: 'America/Denver',
  },
  '87042': {
    zip: '87042',
    city: 'Peralta',
    state: 'NM',
    latitude: '34.8327',
    longitude: '-106.66284',
    timeZoneId: 'America/Denver',
  },
  '87043': {
    zip: '87043',
    city: 'Placitas',
    state: 'NM',
    latitude: '35.313941',
    longitude: '-106.444467',
    timeZoneId: 'America/Denver',
  },
  '87044': {
    zip: '87044',
    city: 'Ponderosa',
    state: 'NM',
    latitude: '35.661919',
    longitude: '-106.666841',
    timeZoneId: 'America/Denver',
  },
  '87045': {
    zip: '87045',
    city: 'Prewitt',
    state: 'NM',
    latitude: '35.342097',
    longitude: '-108.112957',
    timeZoneId: 'America/Denver',
  },
  '87046': {
    zip: '87046',
    city: 'Regina',
    state: 'NM',
    latitude: '36.203051',
    longitude: '-107.057457',
    timeZoneId: 'America/Denver',
  },
  '87047': {
    zip: '87047',
    city: 'Sandia Park',
    state: 'NM',
    latitude: '35.155441',
    longitude: '-106.310313',
    timeZoneId: 'America/Denver',
  },
  '87048': {
    zip: '87048',
    city: 'Corrales',
    state: 'NM',
    latitude: '35.235304',
    longitude: '-106.619422',
    timeZoneId: 'America/Denver',
  },
  '87049': {
    zip: '87049',
    city: 'San Fidel',
    state: 'NM',
    latitude: '35.083765',
    longitude: '-107.608587',
    timeZoneId: 'America/Denver',
  },
  '87051': {
    zip: '87051',
    city: 'San Rafael',
    state: 'NM',
    latitude: '35.151665',
    longitude: '-107.873851',
    timeZoneId: 'America/Denver',
  },
  '87052': {
    zip: '87052',
    city: 'Santo Domingo Pueblo',
    state: 'NM',
    latitude: '35.533365',
    longitude: '-106.392193',
    timeZoneId: 'America/Denver',
  },
  '87053': {
    zip: '87053',
    city: 'San Ysidro',
    state: 'NM',
    latitude: '35.54328',
    longitude: '-106.7574',
    timeZoneId: 'America/Denver',
  },
  '87056': {
    zip: '87056',
    city: 'Stanley',
    state: 'NM',
    latitude: '35.1341',
    longitude: '-105.941216',
    timeZoneId: 'America/Denver',
  },
  '87059': {
    zip: '87059',
    city: 'Tijeras',
    state: 'NM',
    latitude: '35.01576',
    longitude: '-106.27968',
    timeZoneId: 'America/Denver',
  },
  '87060': {
    zip: '87060',
    city: 'Tome',
    state: 'NM',
    latitude: '34.740824',
    longitude: '-106.731384',
    timeZoneId: 'America/Denver',
  },
  '87061': {
    zip: '87061',
    city: 'Torreon',
    state: 'NM',
    latitude: '34.744837',
    longitude: '-106.156087',
    timeZoneId: 'America/Denver',
  },
  '87062': {
    zip: '87062',
    city: 'Veguita',
    state: 'NM',
    latitude: '34.488921',
    longitude: '-106.725214',
    timeZoneId: 'America/Denver',
  },
  '87063': {
    zip: '87063',
    city: 'Willard',
    state: 'NM',
    latitude: '34.449626',
    longitude: '-106.011354',
    timeZoneId: 'America/Denver',
  },
  '87064': {
    zip: '87064',
    city: 'Youngsville',
    state: 'NM',
    latitude: '36.18801',
    longitude: '-106.5576',
    timeZoneId: 'America/Denver',
  },
  '87068': {
    zip: '87068',
    city: 'Bosque Farms',
    state: 'NM',
    latitude: '34.8924',
    longitude: '-106.71264',
    timeZoneId: 'America/Denver',
  },
  '87070': {
    zip: '87070',
    city: 'Clines Corners',
    state: 'NM',
    latitude: '34.960483',
    longitude: '-105.561423',
    timeZoneId: 'America/Denver',
  },
  '87072': {
    zip: '87072',
    city: 'Cochiti Pueblo',
    state: 'NM',
    latitude: '35.609104',
    longitude: '-106.344513',
    timeZoneId: 'America/Denver',
  },
  '87083': {
    zip: '87083',
    city: 'Cochiti Lake',
    state: 'NM',
    latitude: '35.645558',
    longitude: '-106.335325',
    timeZoneId: 'America/Denver',
  },
  '87101': {
    zip: '87101',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.200486',
    longitude: '-106.643175',
    timeZoneId: 'America/Denver',
  },
  '87102': {
    zip: '87102',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.077349',
    longitude: '-106.653549',
    timeZoneId: 'America/Denver',
  },
  '87103': {
    zip: '87103',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.209758',
    longitude: '-106.629701',
    timeZoneId: 'America/Denver',
  },
  '87104': {
    zip: '87104',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.102306',
    longitude: '-106.670441',
    timeZoneId: 'America/Denver',
  },
  '87105': {
    zip: '87105',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.043217',
    longitude: '-106.694148',
    timeZoneId: 'America/Denver',
  },
  '87106': {
    zip: '87106',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.079105',
    longitude: '-106.617227',
    timeZoneId: 'America/Denver',
  },
  '87107': {
    zip: '87107',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.135912',
    longitude: '-106.643641',
    timeZoneId: 'America/Denver',
  },
  '87108': {
    zip: '87108',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.072326',
    longitude: '-106.57798',
    timeZoneId: 'America/Denver',
  },
  '87109': {
    zip: '87109',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.156553',
    longitude: '-106.565192',
    timeZoneId: 'America/Denver',
  },
  '87110': {
    zip: '87110',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.107533',
    longitude: '-106.576209',
    timeZoneId: 'America/Denver',
  },
  '87111': {
    zip: '87111',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.134826',
    longitude: '-106.518962',
    timeZoneId: 'America/Denver',
  },
  '87112': {
    zip: '87112',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.098578',
    longitude: '-106.513879',
    timeZoneId: 'America/Denver',
  },
  '87113': {
    zip: '87113',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.1782',
    longitude: '-106.596684',
    timeZoneId: 'America/Denver',
  },
  '87114': {
    zip: '87114',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.189199',
    longitude: '-106.784765',
    timeZoneId: 'America/Denver',
  },
  '87115': {
    zip: '87115',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '34.982758',
    longitude: '-106.517977',
    timeZoneId: 'America/Denver',
  },
  '87116': {
    zip: '87116',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.05919',
    longitude: '-106.554193',
    timeZoneId: 'America/Denver',
  },
  '87117': {
    zip: '87117',
    city: 'Kirtland AFB',
    state: 'NM',
    latitude: '35.052085',
    longitude: '-106.545052',
    timeZoneId: 'America/Denver',
  },
  '87119': {
    zip: '87119',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.05507',
    longitude: '-106.619497',
    timeZoneId: 'America/Denver',
  },
  '87120': {
    zip: '87120',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.141221',
    longitude: '-106.704928',
    timeZoneId: 'America/Denver',
  },
  '87121': {
    zip: '87121',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.016799',
    longitude: '-106.755725',
    timeZoneId: 'America/Denver',
  },
  '87122': {
    zip: '87122',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.181446',
    longitude: '-106.489076',
    timeZoneId: 'America/Denver',
  },
  '87123': {
    zip: '87123',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.073228',
    longitude: '-106.504635',
    timeZoneId: 'America/Denver',
  },
  '87124': {
    zip: '87124',
    city: 'Rio Rancho',
    state: 'NM',
    latitude: '35.2566',
    longitude: '-106.69656',
    timeZoneId: 'America/Denver',
  },
  '87125': {
    zip: '87125',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.093355',
    longitude: '-106.642816',
    timeZoneId: 'America/Denver',
  },
  '87131': {
    zip: '87131',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.089423',
    longitude: '-106.619821',
    timeZoneId: 'America/Denver',
  },
  '87144': {
    zip: '87144',
    city: 'Rio Rancho',
    state: 'NM',
    latitude: '35.251144',
    longitude: '-106.676305',
    timeZoneId: 'America/Denver',
  },
  '87151': {
    zip: '87151',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.02513',
    longitude: '-106.856084',
    timeZoneId: 'America/Denver',
  },
  '87153': {
    zip: '87153',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.066407',
    longitude: '-106.503424',
    timeZoneId: 'America/Denver',
  },
  '87154': {
    zip: '87154',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.145729',
    longitude: '-106.491629',
    timeZoneId: 'America/Denver',
  },
  '87158': {
    zip: '87158',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.084393',
    longitude: '-106.647676',
    timeZoneId: 'America/Denver',
  },
  '87174': {
    zip: '87174',
    city: 'Rio Rancho',
    state: 'NM',
    latitude: '35.277379',
    longitude: '-106.652579',
    timeZoneId: 'America/Denver',
  },
  '87176': {
    zip: '87176',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '34.999579',
    longitude: '-106.338211',
    timeZoneId: 'America/Denver',
  },
  '87181': {
    zip: '87181',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.105228',
    longitude: '-106.514073',
    timeZoneId: 'America/Denver',
  },
  '87184': {
    zip: '87184',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.199435',
    longitude: '-106.660676',
    timeZoneId: 'America/Denver',
  },
  '87185': {
    zip: '87185',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '34.983749',
    longitude: '-106.51606',
    timeZoneId: 'America/Denver',
  },
  '87187': {
    zip: '87187',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.170145',
    longitude: '-106.834189',
    timeZoneId: 'America/Denver',
  },
  '87190': {
    zip: '87190',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.108134',
    longitude: '-106.596139',
    timeZoneId: 'America/Denver',
  },
  '87191': {
    zip: '87191',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.134415',
    longitude: '-106.520743',
    timeZoneId: 'America/Denver',
  },
  '87192': {
    zip: '87192',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.102775',
    longitude: '-106.512743',
    timeZoneId: 'America/Denver',
  },
  '87193': {
    zip: '87193',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.190497',
    longitude: '-106.68895',
    timeZoneId: 'America/Denver',
  },
  '87194': {
    zip: '87194',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.103356',
    longitude: '-106.676611',
    timeZoneId: 'America/Denver',
  },
  '87195': {
    zip: '87195',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.064655',
    longitude: '-106.675409',
    timeZoneId: 'America/Denver',
  },
  '87196': {
    zip: '87196',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.082837',
    longitude: '-106.619087',
    timeZoneId: 'America/Denver',
  },
  '87197': {
    zip: '87197',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.118513',
    longitude: '-106.642871',
    timeZoneId: 'America/Denver',
  },
  '87198': {
    zip: '87198',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.085096',
    longitude: '-106.653084',
    timeZoneId: 'America/Denver',
  },
  '87199': {
    zip: '87199',
    city: 'Albuquerque',
    state: 'NM',
    latitude: '35.161545',
    longitude: '-106.579538',
    timeZoneId: 'America/Denver',
  },
  '87301': {
    zip: '87301',
    city: 'Gallup',
    state: 'NM',
    latitude: '35.522559',
    longitude: '-108.7258',
    timeZoneId: 'America/Denver',
  },
  '87302': {
    zip: '87302',
    city: 'Gallup',
    state: 'NM',
    latitude: '35.431205',
    longitude: '-108.728509',
    timeZoneId: 'America/Denver',
  },
  '87305': {
    zip: '87305',
    city: 'Gallup',
    state: 'NM',
    latitude: '35.469202',
    longitude: '-108.819415',
    timeZoneId: 'America/Denver',
  },
  '87310': {
    zip: '87310',
    city: 'Brimhall',
    state: 'NM',
    latitude: '35.809225',
    longitude: '-108.576279',
    timeZoneId: 'America/Denver',
  },
  '87311': {
    zip: '87311',
    city: 'Church Rock',
    state: 'NM',
    latitude: '35.532257',
    longitude: '-108.606034',
    timeZoneId: 'America/Denver',
  },
  '87312': {
    zip: '87312',
    city: 'Continental Divide',
    state: 'NM',
    latitude: '35.468748',
    longitude: '-108.455845',
    timeZoneId: 'America/Denver',
  },
  '87313': {
    zip: '87313',
    city: 'Crownpoint',
    state: 'NM',
    latitude: '35.798788',
    longitude: '-107.923273',
    timeZoneId: 'America/Denver',
  },
  '87315': {
    zip: '87315',
    city: 'Fence Lake',
    state: 'NM',
    latitude: '34.763663',
    longitude: '-108.783817',
    timeZoneId: 'America/Denver',
  },
  '87316': {
    zip: '87316',
    city: 'Fort Wingate',
    state: 'NM',
    latitude: '35.432945',
    longitude: '-108.725842',
    timeZoneId: 'America/Denver',
  },
  '87317': {
    zip: '87317',
    city: 'Gamerco',
    state: 'NM',
    latitude: '35.57055',
    longitude: '-108.763745',
    timeZoneId: 'America/Denver',
  },
  '87319': {
    zip: '87319',
    city: 'Mentmore',
    state: 'NM',
    latitude: '35.493375',
    longitude: '-108.85519',
    timeZoneId: 'America/Denver',
  },
  '87320': {
    zip: '87320',
    city: 'Mexican Springs',
    state: 'NM',
    latitude: '35.775385',
    longitude: '-108.841337',
    timeZoneId: 'America/Denver',
  },
  '87321': {
    zip: '87321',
    city: 'Ramah',
    state: 'NM',
    latitude: '35.13556',
    longitude: '-108.505655',
    timeZoneId: 'America/Denver',
  },
  '87322': {
    zip: '87322',
    city: 'Rehoboth',
    state: 'NM',
    latitude: '35.431749',
    longitude: '-108.723591',
    timeZoneId: 'America/Denver',
  },
  '87323': {
    zip: '87323',
    city: 'Thoreau',
    state: 'NM',
    latitude: '35.405658',
    longitude: '-108.218449',
    timeZoneId: 'America/Denver',
  },
  '87325': {
    zip: '87325',
    city: 'Tohatchi',
    state: 'NM',
    latitude: '35.8621',
    longitude: '-108.762199',
    timeZoneId: 'America/Denver',
  },
  '87326': {
    zip: '87326',
    city: 'Vanderwagen',
    state: 'NM',
    latitude: '35.528056',
    longitude: '-108.741944',
    timeZoneId: 'America/Denver',
  },
  '87327': {
    zip: '87327',
    city: 'Zuni',
    state: 'NM',
    latitude: '35.087346',
    longitude: '-108.790886',
    timeZoneId: 'America/Denver',
  },
  '87328': {
    zip: '87328',
    city: 'Navajo',
    state: 'NM',
    latitude: '35.899177',
    longitude: '-109.030882',
    timeZoneId: 'America/Denver',
  },
  '87347': {
    zip: '87347',
    city: 'Jamestown',
    state: 'NM',
    latitude: '35.432459',
    longitude: '-108.729828',
    timeZoneId: 'America/Denver',
  },
  '87357': {
    zip: '87357',
    city: 'Pinehill',
    state: 'NM',
    latitude: '35.1348',
    longitude: '-108.4956',
    timeZoneId: 'America/Denver',
  },
  '87364': {
    zip: '87364',
    city: 'Sheep Springs',
    state: 'NM',
    latitude: '36.13916',
    longitude: '-108.709856',
    timeZoneId: 'America/Denver',
  },
  '87365': {
    zip: '87365',
    city: 'Smith Lake',
    state: 'NM',
    latitude: '35.775009',
    longitude: '-107.989424',
    timeZoneId: 'America/Denver',
  },
  '87375': {
    zip: '87375',
    city: 'Yatahey',
    state: 'NM',
    latitude: '35.52552',
    longitude: '-108.74082',
    timeZoneId: 'America/Denver',
  },
  '87401': {
    zip: '87401',
    city: 'Farmington',
    state: 'NM',
    latitude: '36.741697',
    longitude: '-108.191411',
    timeZoneId: 'America/Denver',
  },
  '87402': {
    zip: '87402',
    city: 'Farmington',
    state: 'NM',
    latitude: '36.774143',
    longitude: '-108.140409',
    timeZoneId: 'America/Denver',
  },
  '87410': {
    zip: '87410',
    city: 'Aztec',
    state: 'NM',
    latitude: '36.839533',
    longitude: '-107.992684',
    timeZoneId: 'America/Denver',
  },
  '87412': {
    zip: '87412',
    city: 'Blanco',
    state: 'NM',
    latitude: '36.77711',
    longitude: '-107.736433',
    timeZoneId: 'America/Denver',
  },
  '87413': {
    zip: '87413',
    city: 'Bloomfield',
    state: 'NM',
    latitude: '36.713295',
    longitude: '-107.969839',
    timeZoneId: 'America/Denver',
  },
  '87415': {
    zip: '87415',
    city: 'Flora Vista',
    state: 'NM',
    latitude: '36.810837',
    longitude: '-108.085388',
    timeZoneId: 'America/Denver',
  },
  '87416': {
    zip: '87416',
    city: 'Fruitland',
    state: 'NM',
    latitude: '36.745061',
    longitude: '-108.39495',
    timeZoneId: 'America/Denver',
  },
  '87417': {
    zip: '87417',
    city: 'Kirtland',
    state: 'NM',
    latitude: '36.74713',
    longitude: '-108.356972',
    timeZoneId: 'America/Denver',
  },
  '87418': {
    zip: '87418',
    city: 'La Plata',
    state: 'NM',
    latitude: '36.960696',
    longitude: '-108.167011',
    timeZoneId: 'America/Denver',
  },
  '87419': {
    zip: '87419',
    city: 'Navajo Dam',
    state: 'NM',
    latitude: '36.905279',
    longitude: '-107.648499',
    timeZoneId: 'America/Denver',
  },
  '87420': {
    zip: '87420',
    city: 'Shiprock',
    state: 'NM',
    latitude: '36.574461',
    longitude: '-108.590087',
    timeZoneId: 'America/Denver',
  },
  '87421': {
    zip: '87421',
    city: 'Waterflow',
    state: 'NM',
    latitude: '36.742759',
    longitude: '-108.471295',
    timeZoneId: 'America/Denver',
  },
  '87455': {
    zip: '87455',
    city: 'Newcomb',
    state: 'NM',
    latitude: '36.283561',
    longitude: '-108.708314',
    timeZoneId: 'America/Denver',
  },
  '87461': {
    zip: '87461',
    city: 'Sanostee',
    state: 'NM',
    latitude: '36.433337',
    longitude: '-108.87174',
    timeZoneId: 'America/Denver',
  },
  '87499': {
    zip: '87499',
    city: 'Farmington',
    state: 'NM',
    latitude: '36.729928',
    longitude: '-108.152321',
    timeZoneId: 'America/Denver',
  },
  '87501': {
    zip: '87501',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.6997',
    longitude: '-105.9672',
    timeZoneId: 'America/Denver',
  },
  '87502': {
    zip: '87502',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.662831',
    longitude: '-105.980173',
    timeZoneId: 'America/Denver',
  },
  '87503': {
    zip: '87503',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.683677',
    longitude: '-105.938892',
    timeZoneId: 'America/Denver',
  },
  '87504': {
    zip: '87504',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.810156',
    longitude: '-105.984088',
    timeZoneId: 'America/Denver',
  },
  '87505': {
    zip: '87505',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.621141',
    longitude: '-105.967103',
    timeZoneId: 'America/Denver',
  },
  '87506': {
    zip: '87506',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.585314',
    longitude: '-106.024436',
    timeZoneId: 'America/Denver',
  },
  '87507': {
    zip: '87507',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.676786',
    longitude: '-105.957578',
    timeZoneId: 'America/Denver',
  },
  '87508': {
    zip: '87508',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.618373',
    longitude: '-105.963441',
    timeZoneId: 'America/Denver',
  },
  '87509': {
    zip: '87509',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.673194',
    longitude: '-105.954437',
    timeZoneId: 'America/Denver',
  },
  '87510': {
    zip: '87510',
    city: 'Abiquiu',
    state: 'NM',
    latitude: '36.15478',
    longitude: '-106.234086',
    timeZoneId: 'America/Denver',
  },
  '87511': {
    zip: '87511',
    city: 'Alcalde',
    state: 'NM',
    latitude: '36.103333',
    longitude: '-106.0475',
    timeZoneId: 'America/Denver',
  },
  '87512': {
    zip: '87512',
    city: 'Amalia',
    state: 'NM',
    latitude: '36.802991',
    longitude: '-105.583675',
    timeZoneId: 'America/Denver',
  },
  '87513': {
    zip: '87513',
    city: 'Arroyo Hondo',
    state: 'NM',
    latitude: '36.537421',
    longitude: '-105.668659',
    timeZoneId: 'America/Denver',
  },
  '87514': {
    zip: '87514',
    city: 'Arroyo Seco',
    state: 'NM',
    latitude: '36.500749',
    longitude: '-105.61552',
    timeZoneId: 'America/Denver',
  },
  '87515': {
    zip: '87515',
    city: 'Canjilon',
    state: 'NM',
    latitude: '36.670076',
    longitude: '-106.553112',
    timeZoneId: 'America/Denver',
  },
  '87516': {
    zip: '87516',
    city: 'Canones',
    state: 'NM',
    latitude: '36.188056',
    longitude: '-106.438889',
    timeZoneId: 'America/Denver',
  },
  '87517': {
    zip: '87517',
    city: 'Carson',
    state: 'NM',
    latitude: '36.639024',
    longitude: '-105.84208',
    timeZoneId: 'America/Denver',
  },
  '87518': {
    zip: '87518',
    city: 'Cebolla',
    state: 'NM',
    latitude: '36.674238',
    longitude: '-106.551589',
    timeZoneId: 'America/Denver',
  },
  '87519': {
    zip: '87519',
    city: 'Cerro',
    state: 'NM',
    latitude: '36.736728',
    longitude: '-105.473869',
    timeZoneId: 'America/Denver',
  },
  '87520': {
    zip: '87520',
    city: 'Chama',
    state: 'NM',
    latitude: '36.85783',
    longitude: '-106.600498',
    timeZoneId: 'America/Denver',
  },
  '87521': {
    zip: '87521',
    city: 'Chamisal',
    state: 'NM',
    latitude: '36.157505',
    longitude: '-105.731083',
    timeZoneId: 'America/Denver',
  },
  '87522': {
    zip: '87522',
    city: 'Chimayo',
    state: 'NM',
    latitude: '36.02472',
    longitude: '-105.87048',
    timeZoneId: 'America/Denver',
  },
  '87523': {
    zip: '87523',
    city: 'Cordova',
    state: 'NM',
    latitude: '36.02552',
    longitude: '-105.771929',
    timeZoneId: 'America/Denver',
  },
  '87524': {
    zip: '87524',
    city: 'Costilla',
    state: 'NM',
    latitude: '36.91449',
    longitude: '-105.460249',
    timeZoneId: 'America/Denver',
  },
  '87525': {
    zip: '87525',
    city: 'Taos Ski Valley',
    state: 'NM',
    latitude: '36.403577',
    longitude: '-105.57696',
    timeZoneId: 'America/Denver',
  },
  '87527': {
    zip: '87527',
    city: 'Dixon',
    state: 'NM',
    latitude: '36.198611',
    longitude: '-105.861667',
    timeZoneId: 'America/Denver',
  },
  '87528': {
    zip: '87528',
    city: 'Dulce',
    state: 'NM',
    latitude: '36.947461',
    longitude: '-106.988234',
    timeZoneId: 'America/Denver',
  },
  '87529': {
    zip: '87529',
    city: 'El Prado',
    state: 'NM',
    latitude: '36.44862',
    longitude: '-105.57498',
    timeZoneId: 'America/Denver',
  },
  '87530': {
    zip: '87530',
    city: 'El Rito',
    state: 'NM',
    latitude: '36.322492',
    longitude: '-106.283634',
    timeZoneId: 'America/Denver',
  },
  '87531': {
    zip: '87531',
    city: 'Embudo',
    state: 'NM',
    latitude: '36.141996',
    longitude: '-105.998001',
    timeZoneId: 'America/Denver',
  },
  '87532': {
    zip: '87532',
    city: 'Espanola',
    state: 'NM',
    latitude: '35.97684',
    longitude: '-106.15056',
    timeZoneId: 'America/Denver',
  },
  '87533': {
    zip: '87533',
    city: 'Espanola',
    state: 'NM',
    latitude: '35.99112',
    longitude: '-106.08',
    timeZoneId: 'America/Denver',
  },
  '87535': {
    zip: '87535',
    city: 'Glorieta',
    state: 'NM',
    latitude: '35.57298',
    longitude: '-105.77358',
    timeZoneId: 'America/Denver',
  },
  '87537': {
    zip: '87537',
    city: 'Hernandez',
    state: 'NM',
    latitude: '36.070638',
    longitude: '-106.160258',
    timeZoneId: 'America/Denver',
  },
  '87538': {
    zip: '87538',
    city: 'Ilfeld',
    state: 'NM',
    latitude: '35.42098',
    longitude: '-105.561446',
    timeZoneId: 'America/Denver',
  },
  '87539': {
    zip: '87539',
    city: 'La Madera',
    state: 'NM',
    latitude: '36.290427',
    longitude: '-106.105471',
    timeZoneId: 'America/Denver',
  },
  '87540': {
    zip: '87540',
    city: 'Lamy',
    state: 'NM',
    latitude: '35.397323',
    longitude: '-105.84676',
    timeZoneId: 'America/Denver',
  },
  '87543': {
    zip: '87543',
    city: 'Llano',
    state: 'NM',
    latitude: '36.167777',
    longitude: '-105.685148',
    timeZoneId: 'America/Denver',
  },
  '87544': {
    zip: '87544',
    city: 'Los Alamos',
    state: 'NM',
    latitude: '35.866912',
    longitude: '-106.265556',
    timeZoneId: 'America/Denver',
  },
  '87545': {
    zip: '87545',
    city: 'Los Alamos',
    state: 'NM',
    latitude: '35.89354',
    longitude: '-106.285048',
    timeZoneId: 'America/Denver',
  },
  '87547': {
    zip: '87547',
    city: 'White Rock',
    state: 'NM',
    latitude: '36.172397',
    longitude: '-106.137834',
    timeZoneId: 'America/Denver',
  },
  '87548': {
    zip: '87548',
    city: 'Medanales',
    state: 'NM',
    latitude: '36.152065',
    longitude: '-106.236296',
    timeZoneId: 'America/Denver',
  },
  '87549': {
    zip: '87549',
    city: 'Ojo Caliente',
    state: 'NM',
    latitude: '36.303317',
    longitude: '-106.044584',
    timeZoneId: 'America/Denver',
  },
  '87551': {
    zip: '87551',
    city: 'Los Ojos',
    state: 'NM',
    latitude: '36.673323',
    longitude: '-106.55206',
    timeZoneId: 'America/Denver',
  },
  '87552': {
    zip: '87552',
    city: 'Pecos',
    state: 'NM',
    latitude: '35.5833',
    longitude: '-105.61818',
    timeZoneId: 'America/Denver',
  },
  '87553': {
    zip: '87553',
    city: 'Penasco',
    state: 'NM',
    latitude: '36.1725',
    longitude: '-105.7452',
    timeZoneId: 'America/Denver',
  },
  '87554': {
    zip: '87554',
    city: 'Petaca',
    state: 'NM',
    latitude: '36.697135',
    longitude: '-106.133',
    timeZoneId: 'America/Denver',
  },
  '87556': {
    zip: '87556',
    city: 'Questa',
    state: 'NM',
    latitude: '36.718233',
    longitude: '-105.59912',
    timeZoneId: 'America/Denver',
  },
  '87557': {
    zip: '87557',
    city: 'Ranchos de Taos',
    state: 'NM',
    latitude: '36.30738',
    longitude: '-105.61362',
    timeZoneId: 'America/Denver',
  },
  '87558': {
    zip: '87558',
    city: 'Red River',
    state: 'NM',
    latitude: '36.73135',
    longitude: '-105.591316',
    timeZoneId: 'America/Denver',
  },
  '87560': {
    zip: '87560',
    city: 'Ribera',
    state: 'NM',
    latitude: '35.234975',
    longitude: '-105.523122',
    timeZoneId: 'America/Denver',
  },
  '87562': {
    zip: '87562',
    city: 'Rowe',
    state: 'NM',
    latitude: '35.507793',
    longitude: '-105.666812',
    timeZoneId: 'America/Denver',
  },
  '87564': {
    zip: '87564',
    city: 'San Cristobal',
    state: 'NM',
    latitude: '36.629274',
    longitude: '-105.60352',
    timeZoneId: 'America/Denver',
  },
  '87565': {
    zip: '87565',
    city: 'San Jose',
    state: 'NM',
    latitude: '35.415556',
    longitude: '-105.493611',
    timeZoneId: 'America/Denver',
  },
  '87566': {
    zip: '87566',
    city: 'Ohkay Owingeh',
    state: 'NM',
    latitude: '36.05298',
    longitude: '-106.086',
    timeZoneId: 'America/Denver',
  },
  '87567': {
    zip: '87567',
    city: 'Santa Cruz',
    state: 'NM',
    latitude: '35.994133',
    longitude: '-106.052197',
    timeZoneId: 'America/Denver',
  },
  '87569': {
    zip: '87569',
    city: 'Serafina',
    state: 'NM',
    latitude: '35.392778',
    longitude: '-105.316944',
    timeZoneId: 'America/Denver',
  },
  '87571': {
    zip: '87571',
    city: 'Taos',
    state: 'NM',
    latitude: '36.39744',
    longitude: '-105.59448',
    timeZoneId: 'America/Denver',
  },
  '87573': {
    zip: '87573',
    city: 'Tererro',
    state: 'NM',
    latitude: '35.775048',
    longitude: '-105.625041',
    timeZoneId: 'America/Denver',
  },
  '87574': {
    zip: '87574',
    city: 'Tesuque',
    state: 'NM',
    latitude: '35.806152',
    longitude: '-105.915266',
    timeZoneId: 'America/Denver',
  },
  '87575': {
    zip: '87575',
    city: 'Tierra Amarilla',
    state: 'NM',
    latitude: '36.727222',
    longitude: '-106.532778',
    timeZoneId: 'America/Denver',
  },
  '87576': {
    zip: '87576',
    city: 'Trampas',
    state: 'NM',
    latitude: '36.153207',
    longitude: '-105.622991',
    timeZoneId: 'America/Denver',
  },
  '87577': {
    zip: '87577',
    city: 'Tres Piedras',
    state: 'NM',
    latitude: '36.637988',
    longitude: '-105.845102',
    timeZoneId: 'America/Denver',
  },
  '87578': {
    zip: '87578',
    city: 'Truchas',
    state: 'NM',
    latitude: '36.038566',
    longitude: '-105.809232',
    timeZoneId: 'America/Denver',
  },
  '87579': {
    zip: '87579',
    city: 'Vadito',
    state: 'NM',
    latitude: '36.188374',
    longitude: '-105.684228',
    timeZoneId: 'America/Denver',
  },
  '87580': {
    zip: '87580',
    city: 'Valdez',
    state: 'NM',
    latitude: '36.571665',
    longitude: '-105.50328',
    timeZoneId: 'America/Denver',
  },
  '87581': {
    zip: '87581',
    city: 'Vallecitos',
    state: 'NM',
    latitude: '36.698574',
    longitude: '-106.136233',
    timeZoneId: 'America/Denver',
  },
  '87582': {
    zip: '87582',
    city: 'Velarde',
    state: 'NM',
    latitude: '36.146111',
    longitude: '-106.005',
    timeZoneId: 'America/Denver',
  },
  '87583': {
    zip: '87583',
    city: 'Villanueva',
    state: 'NM',
    latitude: '35.232598',
    longitude: '-105.52038',
    timeZoneId: 'America/Denver',
  },
  '87592': {
    zip: '87592',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.794833',
    longitude: '-105.993546',
    timeZoneId: 'America/Denver',
  },
  '87594': {
    zip: '87594',
    city: 'Santa Fe',
    state: 'NM',
    latitude: '35.793134',
    longitude: '-105.993637',
    timeZoneId: 'America/Denver',
  },
  '87654': {
    zip: '87654',
    city: 'Spaceport City',
    state: 'NM',
    latitude: '32.8812',
    longitude: '-107.0065',
    timeZoneId: 'America/Denver',
  },
  '87701': {
    zip: '87701',
    city: 'Las Vegas',
    state: 'NM',
    latitude: '35.5902',
    longitude: '-105.19812',
    timeZoneId: 'America/Denver',
  },
  '87710': {
    zip: '87710',
    city: 'Angel Fire',
    state: 'NM',
    latitude: '36.393',
    longitude: '-105.2844',
    timeZoneId: 'America/Denver',
  },
  '87711': {
    zip: '87711',
    city: 'Anton Chico',
    state: 'NM',
    latitude: '35.084468',
    longitude: '-105.060585',
    timeZoneId: 'America/Denver',
  },
  '87712': {
    zip: '87712',
    city: 'Buena Vista',
    state: 'NM',
    latitude: '35.891111',
    longitude: '-105.185278',
    timeZoneId: 'America/Denver',
  },
  '87713': {
    zip: '87713',
    city: 'Chacon',
    state: 'NM',
    latitude: '36.15583',
    longitude: '-105.385328',
    timeZoneId: 'America/Denver',
  },
  '87714': {
    zip: '87714',
    city: 'Cimarron',
    state: 'NM',
    latitude: '36.574377',
    longitude: '-105.053005',
    timeZoneId: 'America/Denver',
  },
  '87715': {
    zip: '87715',
    city: 'Cleveland',
    state: 'NM',
    latitude: '35.973436',
    longitude: '-105.543712',
    timeZoneId: 'America/Denver',
  },
  '87718': {
    zip: '87718',
    city: 'Eagle Nest',
    state: 'NM',
    latitude: '36.589838',
    longitude: '-105.275134',
    timeZoneId: 'America/Denver',
  },
  '87722': {
    zip: '87722',
    city: 'Guadalupita',
    state: 'NM',
    latitude: '36.145903',
    longitude: '-105.136117',
    timeZoneId: 'America/Denver',
  },
  '87723': {
    zip: '87723',
    city: 'Holman',
    state: 'NM',
    latitude: '36.03858',
    longitude: '-105.380259',
    timeZoneId: 'America/Denver',
  },
  '87724': {
    zip: '87724',
    city: 'La Loma',
    state: 'NM',
    latitude: '35.195854',
    longitude: '-105.084813',
    timeZoneId: 'America/Denver',
  },
  '87728': {
    zip: '87728',
    city: 'Maxwell',
    state: 'NM',
    latitude: '36.538252',
    longitude: '-104.539719',
    timeZoneId: 'America/Denver',
  },
  '87729': {
    zip: '87729',
    city: 'Miami',
    state: 'NM',
    latitude: '36.361111',
    longitude: '-104.594722',
    timeZoneId: 'America/Denver',
  },
  '87730': {
    zip: '87730',
    city: 'Mills',
    state: 'NM',
    latitude: '36.130813',
    longitude: '-104.278693',
    timeZoneId: 'America/Denver',
  },
  '87731': {
    zip: '87731',
    city: 'Montezuma',
    state: 'NM',
    latitude: '35.696667',
    longitude: '-105.382222',
    timeZoneId: 'America/Denver',
  },
  '87732': {
    zip: '87732',
    city: 'Mora',
    state: 'NM',
    latitude: '36.059286',
    longitude: '-105.288077',
    timeZoneId: 'America/Denver',
  },
  '87733': {
    zip: '87733',
    city: 'Mosquero',
    state: 'NM',
    latitude: '35.73348',
    longitude: '-103.59912',
    timeZoneId: 'America/Denver',
  },
  '87734': {
    zip: '87734',
    city: 'Ocate',
    state: 'NM',
    latitude: '36.018378',
    longitude: '-105.001748',
    timeZoneId: 'America/Denver',
  },
  '87735': {
    zip: '87735',
    city: 'Ojo Feliz',
    state: 'NM',
    latitude: '36.055745',
    longitude: '-105.117418',
    timeZoneId: 'America/Denver',
  },
  '87736': {
    zip: '87736',
    city: 'Rainsville',
    state: 'NM',
    latitude: '35.979921',
    longitude: '-105.208863',
    timeZoneId: 'America/Denver',
  },
  '87740': {
    zip: '87740',
    city: 'Raton',
    state: 'NM',
    latitude: '36.8706',
    longitude: '-104.64858',
    timeZoneId: 'America/Denver',
  },
  '87742': {
    zip: '87742',
    city: 'Rociada',
    state: 'NM',
    latitude: '35.85',
    longitude: '-105.433333',
    timeZoneId: 'America/Denver',
  },
  '87743': {
    zip: '87743',
    city: 'Roy',
    state: 'NM',
    latitude: '35.936248',
    longitude: '-104.193889',
    timeZoneId: 'America/Denver',
  },
  '87745': {
    zip: '87745',
    city: 'Sapello',
    state: 'NM',
    latitude: '35.593889',
    longitude: '-105.216667',
    timeZoneId: 'America/Denver',
  },
  '87746': {
    zip: '87746',
    city: 'Solano',
    state: 'NM',
    latitude: '35.841481',
    longitude: '-104.162543',
    timeZoneId: 'America/Denver',
  },
  '87747': {
    zip: '87747',
    city: 'Springer',
    state: 'NM',
    latitude: '36.38358',
    longitude: '-104.34402',
    timeZoneId: 'America/Denver',
  },
  '87749': {
    zip: '87749',
    city: 'Ute Park',
    state: 'NM',
    latitude: '36.562308',
    longitude: '-105.10104',
    timeZoneId: 'America/Denver',
  },
  '87750': {
    zip: '87750',
    city: 'Valmora',
    state: 'NM',
    latitude: '35.810499',
    longitude: '-104.924862',
    timeZoneId: 'America/Denver',
  },
  '87752': {
    zip: '87752',
    city: 'Wagon Mound',
    state: 'NM',
    latitude: '36.01054',
    longitude: '-104.70153',
    timeZoneId: 'America/Denver',
  },
  '87753': {
    zip: '87753',
    city: 'Watrous',
    state: 'NM',
    latitude: '36.014894',
    longitude: '-105.000961',
    timeZoneId: 'America/Denver',
  },
  '87801': {
    zip: '87801',
    city: 'Socorro',
    state: 'NM',
    latitude: '34.00632',
    longitude: '-106.79382',
    timeZoneId: 'America/Denver',
  },
  '87820': {
    zip: '87820',
    city: 'Aragon',
    state: 'NM',
    latitude: '33.949373',
    longitude: '-108.67854',
    timeZoneId: 'America/Denver',
  },
  '87821': {
    zip: '87821',
    city: 'Datil',
    state: 'NM',
    latitude: '33.9183',
    longitude: '-108.05508',
    timeZoneId: 'America/Denver',
  },
  '87823': {
    zip: '87823',
    city: 'Lemitar',
    state: 'NM',
    latitude: '34.156724',
    longitude: '-106.907083',
    timeZoneId: 'America/Denver',
  },
  '87824': {
    zip: '87824',
    city: 'Luna',
    state: 'NM',
    latitude: '33.653596',
    longitude: '-108.763903',
    timeZoneId: 'America/Denver',
  },
  '87825': {
    zip: '87825',
    city: 'Magdalena',
    state: 'NM',
    latitude: '34.42083',
    longitude: '-107.51028',
    timeZoneId: 'America/Denver',
  },
  '87827': {
    zip: '87827',
    city: 'Pie Town',
    state: 'NM',
    latitude: '34.329535',
    longitude: '-108.282746',
    timeZoneId: 'America/Denver',
  },
  '87828': {
    zip: '87828',
    city: 'Polvadera',
    state: 'NM',
    latitude: '34.217038',
    longitude: '-106.914723',
    timeZoneId: 'America/Denver',
  },
  '87829': {
    zip: '87829',
    city: 'Quemado',
    state: 'NM',
    latitude: '34.31142',
    longitude: '-108.80988',
    timeZoneId: 'America/Denver',
  },
  '87830': {
    zip: '87830',
    city: 'Reserve',
    state: 'NM',
    latitude: '33.652664',
    longitude: '-108.764998',
    timeZoneId: 'America/Denver',
  },
  '87831': {
    zip: '87831',
    city: 'San Acacia',
    state: 'NM',
    latitude: '34.233049',
    longitude: '-106.913944',
    timeZoneId: 'America/Denver',
  },
  '87832': {
    zip: '87832',
    city: 'San Antonio',
    state: 'NM',
    latitude: '33.910833',
    longitude: '-106.348889',
    timeZoneId: 'America/Denver',
  },
  '87901': {
    zip: '87901',
    city: 'Truth or Consequences',
    state: 'NM',
    latitude: '33.129932',
    longitude: '-107.251636',
    timeZoneId: 'America/Denver',
  },
  '87930': {
    zip: '87930',
    city: 'Arrey',
    state: 'NM',
    latitude: '32.739686',
    longitude: '-107.420655',
    timeZoneId: 'America/Denver',
  },
  '87931': {
    zip: '87931',
    city: 'Caballo',
    state: 'NM',
    latitude: '33.018467',
    longitude: '-107.41827',
    timeZoneId: 'America/Denver',
  },
  '87933': {
    zip: '87933',
    city: 'Derry',
    state: 'NM',
    latitude: '32.849884',
    longitude: '-107.038618',
    timeZoneId: 'America/Denver',
  },
  '87935': {
    zip: '87935',
    city: 'Elephant Butte',
    state: 'NM',
    latitude: '33.195236',
    longitude: '-107.218449',
    timeZoneId: 'America/Denver',
  },
  '87936': {
    zip: '87936',
    city: 'Garfield',
    state: 'NM',
    latitude: '32.747571',
    longitude: '-107.266826',
    timeZoneId: 'America/Denver',
  },
  '87937': {
    zip: '87937',
    city: 'Hatch',
    state: 'NM',
    latitude: '32.656667',
    longitude: '-107.135278',
    timeZoneId: 'America/Denver',
  },
  '87939': {
    zip: '87939',
    city: 'Monticello',
    state: 'NM',
    latitude: '33.347489',
    longitude: '-107.412653',
    timeZoneId: 'America/Denver',
  },
  '87940': {
    zip: '87940',
    city: 'Rincon',
    state: 'NM',
    latitude: '32.669772',
    longitude: '-107.155647',
    timeZoneId: 'America/Denver',
  },
  '87941': {
    zip: '87941',
    city: 'Salem',
    state: 'NM',
    latitude: '32.714876',
    longitude: '-107.233735',
    timeZoneId: 'America/Denver',
  },
  '87942': {
    zip: '87942',
    city: 'Williamsburg',
    state: 'NM',
    latitude: '33.0625',
    longitude: '-107.297778',
    timeZoneId: 'America/Denver',
  },
  '87943': {
    zip: '87943',
    city: 'Winston',
    state: 'NM',
    latitude: '33.338611',
    longitude: '-107.677222',
    timeZoneId: 'America/Denver',
  },
  '88001': {
    zip: '88001',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.317522',
    longitude: '-106.743509',
    timeZoneId: 'America/Denver',
  },
  '88002': {
    zip: '88002',
    city: 'White Sands Missile Range',
    state: 'NM',
    latitude: '32.263759',
    longitude: '-106.471871',
    timeZoneId: 'America/Denver',
  },
  '88003': {
    zip: '88003',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.279674',
    longitude: '-106.759683',
    timeZoneId: 'America/Denver',
  },
  '88004': {
    zip: '88004',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.333848',
    longitude: '-106.719414',
    timeZoneId: 'America/Denver',
  },
  '88005': {
    zip: '88005',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.323217',
    longitude: '-106.805117',
    timeZoneId: 'America/Denver',
  },
  '88006': {
    zip: '88006',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.322006',
    longitude: '-106.767074',
    timeZoneId: 'America/Denver',
  },
  '88007': {
    zip: '88007',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.323889',
    longitude: '-106.80357',
    timeZoneId: 'America/Denver',
  },
  '88008': {
    zip: '88008',
    city: 'Santa Teresa',
    state: 'NM',
    latitude: '31.86119',
    longitude: '-106.642311',
    timeZoneId: 'America/Denver',
  },
  '88009': {
    zip: '88009',
    city: 'Playas',
    state: 'NM',
    latitude: '32.031636',
    longitude: '-108.684444',
    timeZoneId: 'America/Denver',
  },
  '88011': {
    zip: '88011',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.330111',
    longitude: '-106.709053',
    timeZoneId: 'America/Denver',
  },
  '88012': {
    zip: '88012',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.408123',
    longitude: '-106.707551',
    timeZoneId: 'America/Denver',
  },
  '88013': {
    zip: '88013',
    city: 'Las Cruces',
    state: 'NM',
    latitude: '32.31316',
    longitude: '-106.777481',
    timeZoneId: 'America/Denver',
  },
  '88020': {
    zip: '88020',
    city: 'Animas',
    state: 'NM',
    latitude: '31.948375',
    longitude: '-108.813066',
    timeZoneId: 'America/Denver',
  },
  '88021': {
    zip: '88021',
    city: 'Anthony',
    state: 'NM',
    latitude: '31.9356',
    longitude: '-106.50552',
    timeZoneId: 'America/Denver',
  },
  '88022': {
    zip: '88022',
    city: 'Arenas Valley',
    state: 'NM',
    latitude: '32.78874',
    longitude: '-108.19434',
    timeZoneId: 'America/Denver',
  },
  '88023': {
    zip: '88023',
    city: 'Bayard',
    state: 'NM',
    latitude: '32.76519',
    longitude: '-108.135124',
    timeZoneId: 'America/Denver',
  },
  '88024': {
    zip: '88024',
    city: 'Berino',
    state: 'NM',
    latitude: '32.068056',
    longitude: '-106.623056',
    timeZoneId: 'America/Denver',
  },
  '88025': {
    zip: '88025',
    city: 'Buckhorn',
    state: 'NM',
    latitude: '32.864735',
    longitude: '-108.719779',
    timeZoneId: 'America/Denver',
  },
  '88026': {
    zip: '88026',
    city: 'Santa Clara',
    state: 'NM',
    latitude: '32.776365',
    longitude: '-108.150858',
    timeZoneId: 'America/Denver',
  },
  '88027': {
    zip: '88027',
    city: 'Chamberino',
    state: 'NM',
    latitude: '32.032495',
    longitude: '-106.662104',
    timeZoneId: 'America/Denver',
  },
  '88028': {
    zip: '88028',
    city: 'Cliff',
    state: 'NM',
    latitude: '32.961546',
    longitude: '-108.614846',
    timeZoneId: 'America/Denver',
  },
  '88029': {
    zip: '88029',
    city: 'Columbus',
    state: 'NM',
    latitude: '31.831886',
    longitude: '-107.642376',
    timeZoneId: 'America/Denver',
  },
  '88030': {
    zip: '88030',
    city: 'Deming',
    state: 'NM',
    latitude: '32.263005',
    longitude: '-107.754054',
    timeZoneId: 'America/Denver',
  },
  '88031': {
    zip: '88031',
    city: 'Deming',
    state: 'NM',
    latitude: '32.254211',
    longitude: '-107.75488',
    timeZoneId: 'America/Denver',
  },
  '88032': {
    zip: '88032',
    city: 'Dona Ana',
    state: 'NM',
    latitude: '32.388663',
    longitude: '-106.78244',
    timeZoneId: 'America/Denver',
  },
  '88033': {
    zip: '88033',
    city: 'Fairacres',
    state: 'NM',
    latitude: '32.31085',
    longitude: '-107.036454',
    timeZoneId: 'America/Denver',
  },
  '88034': {
    zip: '88034',
    city: 'Faywood',
    state: 'NM',
    latitude: '32.588497',
    longitude: '-107.933514',
    timeZoneId: 'America/Denver',
  },
  '88036': {
    zip: '88036',
    city: 'Fort Bayard',
    state: 'NM',
    latitude: '32.768885',
    longitude: '-108.143971',
    timeZoneId: 'America/Denver',
  },
  '88038': {
    zip: '88038',
    city: 'Gila',
    state: 'NM',
    latitude: '33.018554',
    longitude: '-108.282532',
    timeZoneId: 'America/Denver',
  },
  '88039': {
    zip: '88039',
    city: 'Glenwood',
    state: 'NM',
    latitude: '33.32772',
    longitude: '-108.8133',
    timeZoneId: 'America/Denver',
  },
  '88040': {
    zip: '88040',
    city: 'Hachita',
    state: 'NM',
    latitude: '31.91686',
    longitude: '-108.32259',
    timeZoneId: 'America/Denver',
  },
  '88041': {
    zip: '88041',
    city: 'Hanover',
    state: 'NM',
    latitude: '32.746667',
    longitude: '-107.885833',
    timeZoneId: 'America/Denver',
  },
  '88042': {
    zip: '88042',
    city: 'Hillsboro',
    state: 'NM',
    latitude: '32.916944',
    longitude: '-107.705556',
    timeZoneId: 'America/Denver',
  },
  '88043': {
    zip: '88043',
    city: 'Hurley',
    state: 'NM',
    latitude: '32.701595',
    longitude: '-108.130863',
    timeZoneId: 'America/Denver',
  },
  '88044': {
    zip: '88044',
    city: 'La Mesa',
    state: 'NM',
    latitude: '32.100222',
    longitude: '-106.686211',
    timeZoneId: 'America/Denver',
  },
  '88045': {
    zip: '88045',
    city: 'Lordsburg',
    state: 'NM',
    latitude: '32.347258',
    longitude: '-108.708739',
    timeZoneId: 'America/Denver',
  },
  '88046': {
    zip: '88046',
    city: 'Mesilla',
    state: 'NM',
    latitude: '32.247119',
    longitude: '-106.761443',
    timeZoneId: 'America/Denver',
  },
  '88047': {
    zip: '88047',
    city: 'Mesilla Park',
    state: 'NM',
    latitude: '32.23926',
    longitude: '-106.735038',
    timeZoneId: 'America/Denver',
  },
  '88048': {
    zip: '88048',
    city: 'Mesquite',
    state: 'NM',
    latitude: '32.103494',
    longitude: '-106.651884',
    timeZoneId: 'America/Denver',
  },
  '88049': {
    zip: '88049',
    city: 'Mimbres',
    state: 'NM',
    latitude: '32.867754',
    longitude: '-107.966597',
    timeZoneId: 'America/Denver',
  },
  '88051': {
    zip: '88051',
    city: 'Mule Creek',
    state: 'NM',
    latitude: '33.12182',
    longitude: '-108.95641',
    timeZoneId: 'America/Denver',
  },
  '88052': {
    zip: '88052',
    city: 'Organ',
    state: 'NM',
    latitude: '32.423691',
    longitude: '-106.602291',
    timeZoneId: 'America/Denver',
  },
  '88053': {
    zip: '88053',
    city: 'Pinos Altos',
    state: 'NM',
    latitude: '32.783686',
    longitude: '-108.271831',
    timeZoneId: 'America/Denver',
  },
  '88054': {
    zip: '88054',
    city: 'Radium Springs',
    state: 'NM',
    latitude: '32.641333',
    longitude: '-107.07395',
    timeZoneId: 'America/Denver',
  },
  '88055': {
    zip: '88055',
    city: 'Redrock',
    state: 'NM',
    latitude: '32.86779',
    longitude: '-108.723525',
    timeZoneId: 'America/Denver',
  },
  '88056': {
    zip: '88056',
    city: 'Rodeo',
    state: 'NM',
    latitude: '31.836121',
    longitude: '-109.031867',
    timeZoneId: 'America/Denver',
  },
  '88058': {
    zip: '88058',
    city: 'San Miguel',
    state: 'NM',
    latitude: '32.108622',
    longitude: '-106.67925',
    timeZoneId: 'America/Denver',
  },
  '88061': {
    zip: '88061',
    city: 'Silver City',
    state: 'NM',
    latitude: '32.775733',
    longitude: '-108.264777',
    timeZoneId: 'America/Denver',
  },
  '88062': {
    zip: '88062',
    city: 'Silver City',
    state: 'NM',
    latitude: '32.760196',
    longitude: '-108.276695',
    timeZoneId: 'America/Denver',
  },
  '88063': {
    zip: '88063',
    city: 'Sunland Park',
    state: 'NM',
    latitude: '31.799466',
    longitude: '-106.579887',
    timeZoneId: 'America/Denver',
  },
  '88065': {
    zip: '88065',
    city: 'Tyrone',
    state: 'NM',
    latitude: '32.669917',
    longitude: '-108.292553',
    timeZoneId: 'America/Denver',
  },
  '88072': {
    zip: '88072',
    city: 'Vado',
    state: 'NM',
    latitude: '32.121944',
    longitude: '-106.707222',
    timeZoneId: 'America/Denver',
  },
  '88081': {
    zip: '88081',
    city: 'Chaparral',
    state: 'NM',
    latitude: '32.01085',
    longitude: '-106.570172',
    timeZoneId: 'America/Denver',
  },
  '88101': {
    zip: '88101',
    city: 'Clovis',
    state: 'NM',
    latitude: '34.40538',
    longitude: '-103.24842',
    timeZoneId: 'America/Denver',
  },
  '88102': {
    zip: '88102',
    city: 'Clovis',
    state: 'NM',
    latitude: '34.392564',
    longitude: '-103.215768',
    timeZoneId: 'America/Denver',
  },
  '88103': {
    zip: '88103',
    city: 'Cannon AFB',
    state: 'NM',
    latitude: '34.3953',
    longitude: '-103.32438',
    timeZoneId: 'America/Denver',
  },
  '88112': {
    zip: '88112',
    city: 'Broadview',
    state: 'NM',
    latitude: '34.821111',
    longitude: '-103.107222',
    timeZoneId: 'America/Denver',
  },
  '88113': {
    zip: '88113',
    city: 'Causey',
    state: 'NM',
    latitude: '33.858898',
    longitude: '-103.139868',
    timeZoneId: 'America/Denver',
  },
  '88114': {
    zip: '88114',
    city: 'Crossroads',
    state: 'NM',
    latitude: '33.507396',
    longitude: '-103.341671',
    timeZoneId: 'America/Denver',
  },
  '88115': {
    zip: '88115',
    city: 'Dora',
    state: 'NM',
    latitude: '33.939222',
    longitude: '-103.334162',
    timeZoneId: 'America/Denver',
  },
  '88116': {
    zip: '88116',
    city: 'Elida',
    state: 'NM',
    latitude: '33.897027',
    longitude: '-103.617102',
    timeZoneId: 'America/Denver',
  },
  '88118': {
    zip: '88118',
    city: 'Floyd',
    state: 'NM',
    latitude: '34.290375',
    longitude: '-103.752434',
    timeZoneId: 'America/Denver',
  },
  '88119': {
    zip: '88119',
    city: 'Fort Sumner',
    state: 'NM',
    latitude: '34.471919',
    longitude: '-104.234002',
    timeZoneId: 'America/Denver',
  },
  '88120': {
    zip: '88120',
    city: 'Grady',
    state: 'NM',
    latitude: '34.821627',
    longitude: '-103.313553',
    timeZoneId: 'America/Denver',
  },
  '88121': {
    zip: '88121',
    city: 'House',
    state: 'NM',
    latitude: '34.739255',
    longitude: '-103.985028',
    timeZoneId: 'America/Denver',
  },
  '88122': {
    zip: '88122',
    city: 'Kenna',
    state: 'NM',
    latitude: '33.84057',
    longitude: '-103.775941',
    timeZoneId: 'America/Denver',
  },
  '88123': {
    zip: '88123',
    city: 'Lingo',
    state: 'NM',
    latitude: '33.652386',
    longitude: '-103.141603',
    timeZoneId: 'America/Denver',
  },
  '88124': {
    zip: '88124',
    city: 'Melrose',
    state: 'NM',
    latitude: '34.431609',
    longitude: '-103.627753',
    timeZoneId: 'America/Denver',
  },
  '88125': {
    zip: '88125',
    city: 'Milnesand',
    state: 'NM',
    latitude: '33.631904',
    longitude: '-103.286259',
    timeZoneId: 'America/Denver',
  },
  '88126': {
    zip: '88126',
    city: 'Pep',
    state: 'NM',
    latitude: '33.783671',
    longitude: '-103.358447',
    timeZoneId: 'America/Denver',
  },
  '88130': {
    zip: '88130',
    city: 'Portales',
    state: 'NM',
    latitude: '34.15494',
    longitude: '-103.33572',
    timeZoneId: 'America/Denver',
  },
  '88132': {
    zip: '88132',
    city: 'Rogers',
    state: 'NM',
    latitude: '34.03453',
    longitude: '-103.139803',
    timeZoneId: 'America/Denver',
  },
  '88133': {
    zip: '88133',
    city: 'Saint Vrain',
    state: 'NM',
    latitude: '34.485021',
    longitude: '-103.456802',
    timeZoneId: 'America/Denver',
  },
  '88134': {
    zip: '88134',
    city: 'Taiban',
    state: 'NM',
    latitude: '34.352414',
    longitude: '-104.034639',
    timeZoneId: 'America/Denver',
  },
  '88135': {
    zip: '88135',
    city: 'Texico',
    state: 'NM',
    latitude: '34.38486',
    longitude: '-103.05864',
    timeZoneId: 'America/Denver',
  },
  '88136': {
    zip: '88136',
    city: 'Yeso',
    state: 'NM',
    latitude: '34.351148',
    longitude: '-104.598484',
    timeZoneId: 'America/Denver',
  },
  '88201': {
    zip: '88201',
    city: 'Roswell',
    state: 'NM',
    latitude: '33.393099',
    longitude: '-104.522917',
    timeZoneId: 'America/Denver',
  },
  '88202': {
    zip: '88202',
    city: 'Roswell',
    state: 'NM',
    latitude: '33.348619',
    longitude: '-104.490865',
    timeZoneId: 'America/Denver',
  },
  '88203': {
    zip: '88203',
    city: 'Roswell',
    state: 'NM',
    latitude: '33.397297',
    longitude: '-104.521731',
    timeZoneId: 'America/Denver',
  },
  '88210': {
    zip: '88210',
    city: 'Artesia',
    state: 'NM',
    latitude: '32.835208',
    longitude: '-104.413737',
    timeZoneId: 'America/Denver',
  },
  '88211': {
    zip: '88211',
    city: 'Artesia',
    state: 'NM',
    latitude: '32.838496',
    longitude: '-104.425434',
    timeZoneId: 'America/Denver',
  },
  '88213': {
    zip: '88213',
    city: 'Caprock',
    state: 'NM',
    latitude: '33.404833',
    longitude: '-103.659049',
    timeZoneId: 'America/Denver',
  },
  '88220': {
    zip: '88220',
    city: 'Carlsbad',
    state: 'NM',
    latitude: '32.397966',
    longitude: '-104.241065',
    timeZoneId: 'America/Denver',
  },
  '88221': {
    zip: '88221',
    city: 'Carlsbad',
    state: 'NM',
    latitude: '32.42158',
    longitude: '-104.275688',
    timeZoneId: 'America/Denver',
  },
  '88230': {
    zip: '88230',
    city: 'Dexter',
    state: 'NM',
    latitude: '33.226907',
    longitude: '-104.407794',
    timeZoneId: 'America/Denver',
  },
  '88231': {
    zip: '88231',
    city: 'Eunice',
    state: 'NM',
    latitude: '32.441225',
    longitude: '-103.165099',
    timeZoneId: 'America/Denver',
  },
  '88232': {
    zip: '88232',
    city: 'Hagerman',
    state: 'NM',
    latitude: '33.115176',
    longitude: '-104.352664',
    timeZoneId: 'America/Denver',
  },
  '88240': {
    zip: '88240',
    city: 'Hobbs',
    state: 'NM',
    latitude: '32.725126',
    longitude: '-103.140536',
    timeZoneId: 'America/Denver',
  },
  '88241': {
    zip: '88241',
    city: 'Hobbs',
    state: 'NM',
    latitude: '32.752138',
    longitude: '-103.164834',
    timeZoneId: 'America/Denver',
  },
  '88242': {
    zip: '88242',
    city: 'Hobbs',
    state: 'NM',
    latitude: '32.813605',
    longitude: '-103.145549',
    timeZoneId: 'America/Denver',
  },
  '88244': {
    zip: '88244',
    city: 'Hobbs',
    state: 'NM',
    latitude: '32.630065',
    longitude: '-103.334409',
    timeZoneId: 'America/Denver',
  },
  '88250': {
    zip: '88250',
    city: 'Hope',
    state: 'NM',
    latitude: '32.754037',
    longitude: '-104.744343',
    timeZoneId: 'America/Denver',
  },
  '88252': {
    zip: '88252',
    city: 'Jal',
    state: 'NM',
    latitude: '32.112508',
    longitude: '-103.19498',
    timeZoneId: 'America/Denver',
  },
  '88253': {
    zip: '88253',
    city: 'Lake Arthur',
    state: 'NM',
    latitude: '32.988371',
    longitude: '-104.412521',
    timeZoneId: 'America/Denver',
  },
  '88254': {
    zip: '88254',
    city: 'Lakewood',
    state: 'NM',
    latitude: '32.638836',
    longitude: '-104.375963',
    timeZoneId: 'America/Denver',
  },
  '88255': {
    zip: '88255',
    city: 'Loco Hills',
    state: 'NM',
    latitude: '32.818534',
    longitude: '-103.978209',
    timeZoneId: 'America/Denver',
  },
  '88256': {
    zip: '88256',
    city: 'Loving',
    state: 'NM',
    latitude: '32.283356',
    longitude: '-104.097451',
    timeZoneId: 'America/Denver',
  },
  '88260': {
    zip: '88260',
    city: 'Lovington',
    state: 'NM',
    latitude: '32.93112',
    longitude: '-103.31346',
    timeZoneId: 'America/Denver',
  },
  '88262': {
    zip: '88262',
    city: 'McDonald',
    state: 'NM',
    latitude: '33.142688',
    longitude: '-103.319739',
    timeZoneId: 'America/Denver',
  },
  '88263': {
    zip: '88263',
    city: 'Malaga',
    state: 'NM',
    latitude: '32.210989',
    longitude: '-104.067615',
    timeZoneId: 'America/Denver',
  },
  '88264': {
    zip: '88264',
    city: 'Maljamar',
    state: 'NM',
    latitude: '32.825571',
    longitude: '-103.734737',
    timeZoneId: 'America/Denver',
  },
  '88265': {
    zip: '88265',
    city: 'Monument',
    state: 'NM',
    latitude: '32.638017',
    longitude: '-103.126757',
    timeZoneId: 'America/Denver',
  },
  '88267': {
    zip: '88267',
    city: 'Tatum',
    state: 'NM',
    latitude: '33.249397',
    longitude: '-103.316675',
    timeZoneId: 'America/Denver',
  },
  '88268': {
    zip: '88268',
    city: 'Whites City',
    state: 'NM',
    latitude: '32.174578',
    longitude: '-104.37404',
    timeZoneId: 'America/Denver',
  },
  '88301': {
    zip: '88301',
    city: 'Carrizozo',
    state: 'NM',
    latitude: '33.642604',
    longitude: '-105.878878',
    timeZoneId: 'America/Denver',
  },
  '88310': {
    zip: '88310',
    city: 'Alamogordo',
    state: 'NM',
    latitude: '32.899416',
    longitude: '-105.951065',
    timeZoneId: 'America/Denver',
  },
  '88311': {
    zip: '88311',
    city: 'Alamogordo',
    state: 'NM',
    latitude: '32.823829',
    longitude: '-105.978482',
    timeZoneId: 'America/Denver',
  },
  '88312': {
    zip: '88312',
    city: 'Alto',
    state: 'NM',
    latitude: '33.394388',
    longitude: '-105.666923',
    timeZoneId: 'America/Denver',
  },
  '88314': {
    zip: '88314',
    city: 'Bent',
    state: 'NM',
    latitude: '33.153515',
    longitude: '-105.883199',
    timeZoneId: 'America/Denver',
  },
  '88316': {
    zip: '88316',
    city: 'Capitan',
    state: 'NM',
    latitude: '33.54537',
    longitude: '-105.57878',
    timeZoneId: 'America/Denver',
  },
  '88317': {
    zip: '88317',
    city: 'Cloudcroft',
    state: 'NM',
    latitude: '32.94525',
    longitude: '-105.747305',
    timeZoneId: 'America/Denver',
  },
  '88318': {
    zip: '88318',
    city: 'Corona',
    state: 'NM',
    latitude: '34.24586',
    longitude: '-105.59576',
    timeZoneId: 'America/Denver',
  },
  '88321': {
    zip: '88321',
    city: 'Encino',
    state: 'NM',
    latitude: '34.593805',
    longitude: '-105.496912',
    timeZoneId: 'America/Denver',
  },
  '88323': {
    zip: '88323',
    city: 'Fort Stanton',
    state: 'NM',
    latitude: '33.498917',
    longitude: '-105.52327',
    timeZoneId: 'America/Denver',
  },
  '88324': {
    zip: '88324',
    city: 'Glencoe',
    state: 'NM',
    latitude: '33.406087',
    longitude: '-105.515545',
    timeZoneId: 'America/Denver',
  },
  '88325': {
    zip: '88325',
    city: 'High Rolls Mountain Park',
    state: 'NM',
    latitude: '33.076011',
    longitude: '-106.025093',
    timeZoneId: 'America/Denver',
  },
  '88330': {
    zip: '88330',
    city: 'Holloman Air Force Base',
    state: 'NM',
    latitude: '32.820176',
    longitude: '-105.93563',
    timeZoneId: 'America/Denver',
  },
  '88336': {
    zip: '88336',
    city: 'Hondo',
    state: 'NM',
    latitude: '33.445569',
    longitude: '-105.368703',
    timeZoneId: 'America/Denver',
  },
  '88337': {
    zip: '88337',
    city: 'La Luz',
    state: 'NM',
    latitude: '32.968274',
    longitude: '-105.947227',
    timeZoneId: 'America/Denver',
  },
  '88338': {
    zip: '88338',
    city: 'Lincoln',
    state: 'NM',
    latitude: '33.488918',
    longitude: '-105.533019',
    timeZoneId: 'America/Denver',
  },
  '88339': {
    zip: '88339',
    city: 'Mayhill',
    state: 'NM',
    latitude: '32.908298',
    longitude: '-105.467489',
    timeZoneId: 'America/Denver',
  },
  '88340': {
    zip: '88340',
    city: 'Mescalero',
    state: 'NM',
    latitude: '33.265571',
    longitude: '-105.726911',
    timeZoneId: 'America/Denver',
  },
  '88341': {
    zip: '88341',
    city: 'Nogal',
    state: 'NM',
    latitude: '33.498498',
    longitude: '-105.738234',
    timeZoneId: 'America/Denver',
  },
  '88342': {
    zip: '88342',
    city: 'Orogrande',
    state: 'NM',
    latitude: '32.379663',
    longitude: '-106.091478',
    timeZoneId: 'America/Denver',
  },
  '88343': {
    zip: '88343',
    city: 'Picacho',
    state: 'NM',
    latitude: '33.425364',
    longitude: '-105.032553',
    timeZoneId: 'America/Denver',
  },
  '88344': {
    zip: '88344',
    city: 'Pinon',
    state: 'NM',
    latitude: '32.657594',
    longitude: '-105.424795',
    timeZoneId: 'America/Denver',
  },
  '88345': {
    zip: '88345',
    city: 'Ruidoso',
    state: 'NM',
    latitude: '33.341149',
    longitude: '-105.667861',
    timeZoneId: 'America/Denver',
  },
  '88346': {
    zip: '88346',
    city: 'Ruidoso Downs',
    state: 'NM',
    latitude: '33.325236',
    longitude: '-105.620219',
    timeZoneId: 'America/Denver',
  },
  '88347': {
    zip: '88347',
    city: 'Sacramento',
    state: 'NM',
    latitude: '32.784443',
    longitude: '-105.642462',
    timeZoneId: 'America/Denver',
  },
  '88348': {
    zip: '88348',
    city: 'San Patricio',
    state: 'NM',
    latitude: '33.364897',
    longitude: '-105.358603',
    timeZoneId: 'America/Denver',
  },
  '88349': {
    zip: '88349',
    city: 'Sunspot',
    state: 'NM',
    latitude: '32.789592',
    longitude: '-105.817602',
    timeZoneId: 'America/Denver',
  },
  '88350': {
    zip: '88350',
    city: 'Timberon',
    state: 'NM',
    latitude: '32.835667',
    longitude: '-105.691772',
    timeZoneId: 'America/Denver',
  },
  '88351': {
    zip: '88351',
    city: 'Tinnie',
    state: 'NM',
    latitude: '33.274217',
    longitude: '-105.137756',
    timeZoneId: 'America/Denver',
  },
  '88352': {
    zip: '88352',
    city: 'Tularosa',
    state: 'NM',
    latitude: '33.074578',
    longitude: '-106.015424',
    timeZoneId: 'America/Denver',
  },
  '88353': {
    zip: '88353',
    city: 'Vaughn',
    state: 'NM',
    latitude: '34.686068',
    longitude: '-105.133654',
    timeZoneId: 'America/Denver',
  },
  '88354': {
    zip: '88354',
    city: 'Weed',
    state: 'NM',
    latitude: '32.804351',
    longitude: '-105.460756',
    timeZoneId: 'America/Denver',
  },
  '88355': {
    zip: '88355',
    city: 'Ruidoso',
    state: 'NM',
    latitude: '33.36749',
    longitude: '-105.643813',
    timeZoneId: 'America/Denver',
  },
  '88401': {
    zip: '88401',
    city: 'Tucumcari',
    state: 'NM',
    latitude: '35.169863',
    longitude: '-103.720274',
    timeZoneId: 'America/Denver',
  },
  '88410': {
    zip: '88410',
    city: 'Amistad',
    state: 'NM',
    latitude: '35.891766',
    longitude: '-103.212948',
    timeZoneId: 'America/Denver',
  },
  '88411': {
    zip: '88411',
    city: 'Bard',
    state: 'NM',
    latitude: '35.11566',
    longitude: '-103.31322',
    timeZoneId: 'America/Denver',
  },
  '88414': {
    zip: '88414',
    city: 'Capulin',
    state: 'NM',
    latitude: '36.726135',
    longitude: '-103.973999',
    timeZoneId: 'America/Denver',
  },
  '88415': {
    zip: '88415',
    city: 'Clayton',
    state: 'NM',
    latitude: '36.451664',
    longitude: '-103.180222',
    timeZoneId: 'America/Denver',
  },
  '88416': {
    zip: '88416',
    city: 'Conchas Dam',
    state: 'NM',
    latitude: '35.351226',
    longitude: '-104.04885',
    timeZoneId: 'America/Denver',
  },
  '88417': {
    zip: '88417',
    city: 'Cuervo',
    state: 'NM',
    latitude: '34.989184',
    longitude: '-104.378134',
    timeZoneId: 'America/Denver',
  },
  '88418': {
    zip: '88418',
    city: 'Des Moines',
    state: 'NM',
    latitude: '36.637805',
    longitude: '-103.88529',
    timeZoneId: 'America/Denver',
  },
  '88419': {
    zip: '88419',
    city: 'Folsom',
    state: 'NM',
    latitude: '36.909886',
    longitude: '-103.806885',
    timeZoneId: 'America/Denver',
  },
  '88421': {
    zip: '88421',
    city: 'Garita',
    state: 'NM',
    latitude: '35.357574',
    longitude: '-104.489215',
    timeZoneId: 'America/Denver',
  },
  '88422': {
    zip: '88422',
    city: 'Gladstone',
    state: 'NM',
    latitude: '36.321192',
    longitude: '-103.864445',
    timeZoneId: 'America/Denver',
  },
  '88424': {
    zip: '88424',
    city: 'Grenville',
    state: 'NM',
    latitude: '36.737332',
    longitude: '-103.457179',
    timeZoneId: 'America/Denver',
  },
  '88426': {
    zip: '88426',
    city: 'Logan',
    state: 'NM',
    latitude: '35.3781',
    longitude: '-103.43082',
    timeZoneId: 'America/Denver',
  },
  '88427': {
    zip: '88427',
    city: 'McAlister',
    state: 'NM',
    latitude: '34.743064',
    longitude: '-103.660694',
    timeZoneId: 'America/Denver',
  },
  '88430': {
    zip: '88430',
    city: 'Nara Visa',
    state: 'NM',
    latitude: '35.638279',
    longitude: '-103.217076',
    timeZoneId: 'America/Denver',
  },
  '88431': {
    zip: '88431',
    city: 'Newkirk',
    state: 'NM',
    latitude: '35.120222',
    longitude: '-104.233344',
    timeZoneId: 'America/Denver',
  },
  '88433': {
    zip: '88433',
    city: 'Quay',
    state: 'NM',
    latitude: '34.960345',
    longitude: '-103.807602',
    timeZoneId: 'America/Denver',
  },
  '88434': {
    zip: '88434',
    city: 'San Jon',
    state: 'NM',
    latitude: '35.263046',
    longitude: '-103.265336',
    timeZoneId: 'America/Denver',
  },
  '88435': {
    zip: '88435',
    city: 'Santa Rosa',
    state: 'NM',
    latitude: '34.936606',
    longitude: '-104.684358',
    timeZoneId: 'America/Denver',
  },
  '88436': {
    zip: '88436',
    city: 'Sedan',
    state: 'NM',
    latitude: '36.249519',
    longitude: '-103.145018',
    timeZoneId: 'America/Denver',
  },
  '88439': {
    zip: '88439',
    city: 'Trementina',
    state: 'NM',
    latitude: '35.613718',
    longitude: '-104.59511',
    timeZoneId: 'America/Denver',
  },
  '88510': {
    zip: '88510',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.809866',
    longitude: '-106.416814',
    timeZoneId: 'America/Denver',
  },
  '88511': {
    zip: '88511',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.808327',
    longitude: '-106.417641',
    timeZoneId: 'America/Denver',
  },
  '88512': {
    zip: '88512',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811289',
    longitude: '-106.418964',
    timeZoneId: 'America/Denver',
  },
  '88513': {
    zip: '88513',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.810823',
    longitude: '-106.419673',
    timeZoneId: 'America/Denver',
  },
  '88514': {
    zip: '88514',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.812685',
    longitude: '-106.415171',
    timeZoneId: 'America/Denver',
  },
  '88515': {
    zip: '88515',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.812281',
    longitude: '-106.416248',
    timeZoneId: 'America/Denver',
  },
  '88517': {
    zip: '88517',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.810543',
    longitude: '-106.415682',
    timeZoneId: 'America/Denver',
  },
  '88518': {
    zip: '88518',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811002',
    longitude: '-106.421428',
    timeZoneId: 'America/Denver',
  },
  '88519': {
    zip: '88519',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811275',
    longitude: '-106.421354',
    timeZoneId: 'America/Denver',
  },
  '88520': {
    zip: '88520',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811333',
    longitude: '-106.417046',
    timeZoneId: 'America/Denver',
  },
  '88521': {
    zip: '88521',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.814284',
    longitude: '-106.418275',
    timeZoneId: 'America/Denver',
  },
  '88523': {
    zip: '88523',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.813053',
    longitude: '-106.417368',
    timeZoneId: 'America/Denver',
  },
  '88524': {
    zip: '88524',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.810427',
    longitude: '-106.419393',
    timeZoneId: 'America/Denver',
  },
  '88525': {
    zip: '88525',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.81007',
    longitude: '-106.417365',
    timeZoneId: 'America/Denver',
  },
  '88526': {
    zip: '88526',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811387',
    longitude: '-106.417414',
    timeZoneId: 'America/Denver',
  },
  '88527': {
    zip: '88527',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.810669',
    longitude: '-106.416154',
    timeZoneId: 'America/Denver',
  },
  '88528': {
    zip: '88528',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811152',
    longitude: '-106.420635',
    timeZoneId: 'America/Denver',
  },
  '88529': {
    zip: '88529',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.810311',
    longitude: '-106.416973',
    timeZoneId: 'America/Denver',
  },
  '88530': {
    zip: '88530',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.80982',
    longitude: '-106.419658',
    timeZoneId: 'America/Denver',
  },
  '88531': {
    zip: '88531',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.809229',
    longitude: '-106.417472',
    timeZoneId: 'America/Denver',
  },
  '88532': {
    zip: '88532',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.812036',
    longitude: '-106.416055',
    timeZoneId: 'America/Denver',
  },
  '88533': {
    zip: '88533',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811084',
    longitude: '-106.416966',
    timeZoneId: 'America/Denver',
  },
  '88534': {
    zip: '88534',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.812302',
    longitude: '-106.417034',
    timeZoneId: 'America/Denver',
  },
  '88535': {
    zip: '88535',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.811411',
    longitude: '-106.417289',
    timeZoneId: 'America/Denver',
  },
  '88536': {
    zip: '88536',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.808441',
    longitude: '-106.419865',
    timeZoneId: 'America/Denver',
  },
  '88538': {
    zip: '88538',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.813386',
    longitude: '-106.419114',
    timeZoneId: 'America/Denver',
  },
  '88539': {
    zip: '88539',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.813086',
    longitude: '-106.414291',
    timeZoneId: 'America/Denver',
  },
  '88540': {
    zip: '88540',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.851513',
    longitude: '-106.438229',
    timeZoneId: 'America/Denver',
  },
  '88541': {
    zip: '88541',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.772056',
    longitude: '-106.43087',
    timeZoneId: 'America/Denver',
  },
  '88542': {
    zip: '88542',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770148',
    longitude: '-106.429958',
    timeZoneId: 'America/Denver',
  },
  '88543': {
    zip: '88543',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770994',
    longitude: '-106.429587',
    timeZoneId: 'America/Denver',
  },
  '88544': {
    zip: '88544',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769541',
    longitude: '-106.427908',
    timeZoneId: 'America/Denver',
  },
  '88545': {
    zip: '88545',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.765908',
    longitude: '-106.329967',
    timeZoneId: 'America/Denver',
  },
  '88546': {
    zip: '88546',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.765714',
    longitude: '-106.327732',
    timeZoneId: 'America/Denver',
  },
  '88547': {
    zip: '88547',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.767112',
    longitude: '-106.331411',
    timeZoneId: 'America/Denver',
  },
  '88548': {
    zip: '88548',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770652',
    longitude: '-106.329428',
    timeZoneId: 'America/Denver',
  },
  '88549': {
    zip: '88549',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770045',
    longitude: '-106.33148',
    timeZoneId: 'America/Denver',
  },
  '88550': {
    zip: '88550',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.772931',
    longitude: '-106.428174',
    timeZoneId: 'America/Denver',
  },
  '88553': {
    zip: '88553',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769808',
    longitude: '-106.33132',
    timeZoneId: 'America/Denver',
  },
  '88554': {
    zip: '88554',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76766',
    longitude: '-106.328617',
    timeZoneId: 'America/Denver',
  },
  '88555': {
    zip: '88555',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76913',
    longitude: '-106.334132',
    timeZoneId: 'America/Denver',
  },
  '88556': {
    zip: '88556',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76718',
    longitude: '-106.331086',
    timeZoneId: 'America/Denver',
  },
  '88557': {
    zip: '88557',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.76686',
    longitude: '-106.331835',
    timeZoneId: 'America/Denver',
  },
  '88558': {
    zip: '88558',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769043',
    longitude: '-106.329586',
    timeZoneId: 'America/Denver',
  },
  '88559': {
    zip: '88559',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770134',
    longitude: '-106.331168',
    timeZoneId: 'America/Denver',
  },
  '88560': {
    zip: '88560',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768994',
    longitude: '-106.328556',
    timeZoneId: 'America/Denver',
  },
  '88561': {
    zip: '88561',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.765754',
    longitude: '-106.331188',
    timeZoneId: 'America/Denver',
  },
  '88562': {
    zip: '88562',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769831',
    longitude: '-106.331231',
    timeZoneId: 'America/Denver',
  },
  '88563': {
    zip: '88563',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768358',
    longitude: '-106.327597',
    timeZoneId: 'America/Denver',
  },
  '88565': {
    zip: '88565',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766368',
    longitude: '-106.330038',
    timeZoneId: 'America/Denver',
  },
  '88566': {
    zip: '88566',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.767675',
    longitude: '-106.330083',
    timeZoneId: 'America/Denver',
  },
  '88567': {
    zip: '88567',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769857',
    longitude: '-106.328792',
    timeZoneId: 'America/Denver',
  },
  '88568': {
    zip: '88568',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769406',
    longitude: '-106.331351',
    timeZoneId: 'America/Denver',
  },
  '88569': {
    zip: '88569',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769133',
    longitude: '-106.331114',
    timeZoneId: 'America/Denver',
  },
  '88570': {
    zip: '88570',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769943',
    longitude: '-106.332058',
    timeZoneId: 'America/Denver',
  },
  '88571': {
    zip: '88571',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.767204',
    longitude: '-106.333301',
    timeZoneId: 'America/Denver',
  },
  '88572': {
    zip: '88572',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768538',
    longitude: '-106.329071',
    timeZoneId: 'America/Denver',
  },
  '88573': {
    zip: '88573',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769544',
    longitude: '-106.331668',
    timeZoneId: 'America/Denver',
  },
  '88574': {
    zip: '88574',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.764258',
    longitude: '-106.330912',
    timeZoneId: 'America/Denver',
  },
  '88575': {
    zip: '88575',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766653',
    longitude: '-106.330377',
    timeZoneId: 'America/Denver',
  },
  '88576': {
    zip: '88576',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.764372',
    longitude: '-106.329694',
    timeZoneId: 'America/Denver',
  },
  '88577': {
    zip: '88577',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.759159',
    longitude: '-106.487488',
    timeZoneId: 'America/Denver',
  },
  '88578': {
    zip: '88578',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768648',
    longitude: '-106.332785',
    timeZoneId: 'America/Denver',
  },
  '88579': {
    zip: '88579',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766416',
    longitude: '-106.330344',
    timeZoneId: 'America/Denver',
  },
  '88580': {
    zip: '88580',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.759159',
    longitude: '-106.487488',
    timeZoneId: 'America/Denver',
  },
  '88581': {
    zip: '88581',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770228',
    longitude: '-106.327182',
    timeZoneId: 'America/Denver',
  },
  '88582': {
    zip: '88582',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.767649',
    longitude: '-106.329558',
    timeZoneId: 'America/Denver',
  },
  '88583': {
    zip: '88583',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.77101',
    longitude: '-106.330129',
    timeZoneId: 'America/Denver',
  },
  '88584': {
    zip: '88584',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768405',
    longitude: '-106.328241',
    timeZoneId: 'America/Denver',
  },
  '88585': {
    zip: '88585',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.77044',
    longitude: '-106.329042',
    timeZoneId: 'America/Denver',
  },
  '88586': {
    zip: '88586',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.766467',
    longitude: '-106.332762',
    timeZoneId: 'America/Denver',
  },
  '88587': {
    zip: '88587',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.813359',
    longitude: '-106.417488',
    timeZoneId: 'America/Denver',
  },
  '88588': {
    zip: '88588',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.768459',
    longitude: '-106.42774',
    timeZoneId: 'America/Denver',
  },
  '88589': {
    zip: '88589',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.812479',
    longitude: '-106.418331',
    timeZoneId: 'America/Denver',
  },
  '88590': {
    zip: '88590',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.770296',
    longitude: '-106.426337',
    timeZoneId: 'America/Denver',
  },
  '88595': {
    zip: '88595',
    city: 'El Paso',
    state: 'TX',
    latitude: '31.769906',
    longitude: '-106.428276',
    timeZoneId: 'America/Denver',
  },
  '88888': {
    zip: '88888',
    city: 'North Pole',
    state: 'DC',
    latitude: '0.000000',
    longitude: '0.000000',
    timeZoneId: 'America/New_York',
  },
  '88901': {
    zip: '88901',
    city: 'The Lakes',
    state: 'NV',
    latitude: '36.145297',
    longitude: '-115.282899',
    timeZoneId: 'America/Los_Angeles',
  },
  '88905': {
    zip: '88905',
    city: 'The Lakes',
    state: 'NV',
    latitude: '36.143477',
    longitude: '-115.279371',
    timeZoneId: 'America/Los_Angeles',
  },
  '89001': {
    zip: '89001',
    city: 'Alamo',
    state: 'NV',
    latitude: '37.4814',
    longitude: '-115.48842',
    timeZoneId: 'America/Los_Angeles',
  },
  '89002': {
    zip: '89002',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.057986',
    longitude: '-114.960279',
    timeZoneId: 'America/Los_Angeles',
  },
  '89003': {
    zip: '89003',
    city: 'Beatty',
    state: 'NV',
    latitude: '36.876924',
    longitude: '-116.730966',
    timeZoneId: 'America/Los_Angeles',
  },
  '89004': {
    zip: '89004',
    city: 'Blue Diamond',
    state: 'NV',
    latitude: '36.077647',
    longitude: '-115.414058',
    timeZoneId: 'America/Los_Angeles',
  },
  '89005': {
    zip: '89005',
    city: 'Boulder City',
    state: 'NV',
    latitude: '35.977834',
    longitude: '-114.83585',
    timeZoneId: 'America/Los_Angeles',
  },
  '89006': {
    zip: '89006',
    city: 'Boulder City',
    state: 'NV',
    latitude: '35.965217',
    longitude: '-114.836545',
    timeZoneId: 'America/Los_Angeles',
  },
  '89007': {
    zip: '89007',
    city: 'Bunkerville',
    state: 'NV',
    latitude: '36.771438',
    longitude: '-114.12686',
    timeZoneId: 'America/Los_Angeles',
  },
  '89008': {
    zip: '89008',
    city: 'Caliente',
    state: 'NV',
    latitude: '37.655453',
    longitude: '-114.504832',
    timeZoneId: 'America/Los_Angeles',
  },
  '89009': {
    zip: '89009',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.057322',
    longitude: '-114.961265',
    timeZoneId: 'America/Los_Angeles',
  },
  '89010': {
    zip: '89010',
    city: 'Dyer',
    state: 'NV',
    latitude: '37.67783',
    longitude: '-118.084202',
    timeZoneId: 'America/Los_Angeles',
  },
  '89011': {
    zip: '89011',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.097423',
    longitude: '-114.923904',
    timeZoneId: 'America/Los_Angeles',
  },
  '89012': {
    zip: '89012',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.009449',
    longitude: '-115.04922',
    timeZoneId: 'America/Los_Angeles',
  },
  '89013': {
    zip: '89013',
    city: 'Goldfield',
    state: 'NV',
    latitude: '37.76601',
    longitude: '-117.423544',
    timeZoneId: 'America/Los_Angeles',
  },
  '89014': {
    zip: '89014',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.063431',
    longitude: '-115.054663',
    timeZoneId: 'America/Los_Angeles',
  },
  '89015': {
    zip: '89015',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.060266',
    longitude: '-114.956815',
    timeZoneId: 'America/Los_Angeles',
  },
  '89016': {
    zip: '89016',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.09311',
    longitude: '-114.943541',
    timeZoneId: 'America/Los_Angeles',
  },
  '89017': {
    zip: '89017',
    city: 'Hiko',
    state: 'NV',
    latitude: '37.591348',
    longitude: '-115.225947',
    timeZoneId: 'America/Los_Angeles',
  },
  '89018': {
    zip: '89018',
    city: 'Indian Springs',
    state: 'NV',
    latitude: '36.57055',
    longitude: '-115.669994',
    timeZoneId: 'America/Los_Angeles',
  },
  '89019': {
    zip: '89019',
    city: 'Jean',
    state: 'NV',
    latitude: '35.804011',
    longitude: '-115.635842',
    timeZoneId: 'America/Los_Angeles',
  },
  '89020': {
    zip: '89020',
    city: 'Amargosa Valley',
    state: 'NV',
    latitude: '36.490016',
    longitude: '-116.390342',
    timeZoneId: 'America/Los_Angeles',
  },
  '89021': {
    zip: '89021',
    city: 'Logandale',
    state: 'NV',
    latitude: '36.594627',
    longitude: '-114.482251',
    timeZoneId: 'America/Los_Angeles',
  },
  '89022': {
    zip: '89022',
    city: 'Manhattan',
    state: 'NV',
    latitude: '38.538447',
    longitude: '-117.076448',
    timeZoneId: 'America/Los_Angeles',
  },
  '89023': {
    zip: '89023',
    city: 'Mercury',
    state: 'NV',
    latitude: '36.661428',
    longitude: '-115.999727',
    timeZoneId: 'America/Los_Angeles',
  },
  '89024': {
    zip: '89024',
    city: 'Mesquite',
    state: 'NV',
    latitude: '36.804545',
    longitude: '-114.071143',
    timeZoneId: 'America/Los_Angeles',
  },
  '89025': {
    zip: '89025',
    city: 'Moapa',
    state: 'NV',
    latitude: '36.691114',
    longitude: '-114.59263',
    timeZoneId: 'America/Los_Angeles',
  },
  '89026': {
    zip: '89026',
    city: 'Jean',
    state: 'NV',
    latitude: '35.778436',
    longitude: '-115.325816',
    timeZoneId: 'America/Los_Angeles',
  },
  '89027': {
    zip: '89027',
    city: 'Mesquite',
    state: 'NV',
    latitude: '36.810333',
    longitude: '-114.077791',
    timeZoneId: 'America/Los_Angeles',
  },
  '89028': {
    zip: '89028',
    city: 'Laughlin',
    state: 'NV',
    latitude: '35.148523',
    longitude: '-114.625993',
    timeZoneId: 'America/Los_Angeles',
  },
  '89029': {
    zip: '89029',
    city: 'Laughlin',
    state: 'NV',
    latitude: '35.138548',
    longitude: '-114.623664',
    timeZoneId: 'America/Los_Angeles',
  },
  '89030': {
    zip: '89030',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.210991',
    longitude: '-115.128903',
    timeZoneId: 'America/Los_Angeles',
  },
  '89031': {
    zip: '89031',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.256036',
    longitude: '-115.140687',
    timeZoneId: 'America/Los_Angeles',
  },
  '89032': {
    zip: '89032',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.22437',
    longitude: '-115.171642',
    timeZoneId: 'America/Los_Angeles',
  },
  '89033': {
    zip: '89033',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.30075',
    longitude: '-115.139908',
    timeZoneId: 'America/Los_Angeles',
  },
  '89034': {
    zip: '89034',
    city: 'Mesquite',
    state: 'NV',
    latitude: '37.93877',
    longitude: '-114.449503',
    timeZoneId: 'America/Los_Angeles',
  },
  '89036': {
    zip: '89036',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.369599',
    longitude: '-114.88421',
    timeZoneId: 'America/Los_Angeles',
  },
  '89037': {
    zip: '89037',
    city: 'Coyote Springs',
    state: 'NV',
    latitude: '36.370267',
    longitude: '-114.883611',
    timeZoneId: 'America/Los_Angeles',
  },
  '89039': {
    zip: '89039',
    city: 'Cal Nev Ari',
    state: 'NV',
    latitude: '35.316311',
    longitude: '-114.8854',
    timeZoneId: 'America/Los_Angeles',
  },
  '89040': {
    zip: '89040',
    city: 'Overton',
    state: 'NV',
    latitude: '36.54246',
    longitude: '-114.45341',
    timeZoneId: 'America/Los_Angeles',
  },
  '89041': {
    zip: '89041',
    city: 'Pahrump',
    state: 'NV',
    latitude: '36.25902',
    longitude: '-116.01864',
    timeZoneId: 'America/Los_Angeles',
  },
  '89042': {
    zip: '89042',
    city: 'Panaca',
    state: 'NV',
    latitude: '37.791919',
    longitude: '-114.389113',
    timeZoneId: 'America/Los_Angeles',
  },
  '89043': {
    zip: '89043',
    city: 'Pioche',
    state: 'NV',
    latitude: '37.942563',
    longitude: '-114.451659',
    timeZoneId: 'America/Los_Angeles',
  },
  '89044': {
    zip: '89044',
    city: 'Henderson',
    state: 'NV',
    latitude: '35.99463',
    longitude: '-115.11897',
    timeZoneId: 'America/Los_Angeles',
  },
  '89045': {
    zip: '89045',
    city: 'Round Mountain',
    state: 'NV',
    latitude: '38.8083',
    longitude: '-117.1563',
    timeZoneId: 'America/Los_Angeles',
  },
  '89046': {
    zip: '89046',
    city: 'Searchlight',
    state: 'NV',
    latitude: '35.466505',
    longitude: '-114.918847',
    timeZoneId: 'America/Los_Angeles',
  },
  '89047': {
    zip: '89047',
    city: 'Silverpeak',
    state: 'NV',
    latitude: '37.804091',
    longitude: '-117.875942',
    timeZoneId: 'America/Los_Angeles',
  },
  '89048': {
    zip: '89048',
    city: 'Pahrump',
    state: 'NV',
    latitude: '36.25448',
    longitude: '-116.018558',
    timeZoneId: 'America/Los_Angeles',
  },
  '89049': {
    zip: '89049',
    city: 'Tonopah',
    state: 'NV',
    latitude: '38.064715',
    longitude: '-117.227674',
    timeZoneId: 'America/Los_Angeles',
  },
  '89052': {
    zip: '89052',
    city: 'Henderson',
    state: 'NV',
    latitude: '35.992568',
    longitude: '-115.119585',
    timeZoneId: 'America/Los_Angeles',
  },
  '89053': {
    zip: '89053',
    city: 'Henderson',
    state: 'NV',
    latitude: '35.980158',
    longitude: '-115.059027',
    timeZoneId: 'America/Los_Angeles',
  },
  '89054': {
    zip: '89054',
    city: 'Sloan',
    state: 'NV',
    latitude: '35.935345',
    longitude: '-115.206008',
    timeZoneId: 'America/Los_Angeles',
  },
  '89060': {
    zip: '89060',
    city: 'Pahrump',
    state: 'NV',
    latitude: '36.269044',
    longitude: '-116.02754',
    timeZoneId: 'America/Los_Angeles',
  },
  '89061': {
    zip: '89061',
    city: 'Pahrump',
    state: 'NV',
    latitude: '36.12278',
    longitude: '-115.928662',
    timeZoneId: 'America/Los_Angeles',
  },
  '89067': {
    zip: '89067',
    city: 'Coyote Springs',
    state: 'NV',
    latitude: '36.675655',
    longitude: '-114.620214',
    timeZoneId: 'America/Los_Angeles',
  },
  '89070': {
    zip: '89070',
    city: 'Indian Springs',
    state: 'NV',
    latitude: '36.570319',
    longitude: '-115.668132',
    timeZoneId: 'America/Los_Angeles',
  },
  '89074': {
    zip: '89074',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.040063',
    longitude: '-115.07734',
    timeZoneId: 'America/Los_Angeles',
  },
  '89077': {
    zip: '89077',
    city: 'Henderson',
    state: 'NV',
    latitude: '36.0351',
    longitude: '-114.9766',
    timeZoneId: 'America/Los_Angeles',
  },
  '89081': {
    zip: '89081',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.2577',
    longitude: '-115.1424',
    timeZoneId: 'America/Los_Angeles',
  },
  '89084': {
    zip: '89084',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.302659',
    longitude: '-115.142997',
    timeZoneId: 'America/Los_Angeles',
  },
  '89085': {
    zip: '89085',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.30003',
    longitude: '-115.14181',
    timeZoneId: 'America/Los_Angeles',
  },
  '89086': {
    zip: '89086',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.281466',
    longitude: '-115.134712',
    timeZoneId: 'America/Los_Angeles',
  },
  '89087': {
    zip: '89087',
    city: 'North Las Vegas',
    state: 'NV',
    latitude: '36.2831',
    longitude: '-115.1351',
    timeZoneId: 'America/Los_Angeles',
  },
  '89101': {
    zip: '89101',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.17496',
    longitude: '-115.123394',
    timeZoneId: 'America/Los_Angeles',
  },
  '89102': {
    zip: '89102',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.146544',
    longitude: '-115.189684',
    timeZoneId: 'America/Los_Angeles',
  },
  '89103': {
    zip: '89103',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.114166',
    longitude: '-115.215839',
    timeZoneId: 'America/Los_Angeles',
  },
  '89104': {
    zip: '89104',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.150316',
    longitude: '-115.113965',
    timeZoneId: 'America/Los_Angeles',
  },
  '89105': {
    zip: '89105',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.171909',
    longitude: '-115.139969',
    timeZoneId: 'America/Los_Angeles',
  },
  '89106': {
    zip: '89106',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.183406',
    longitude: '-115.162406',
    timeZoneId: 'America/Los_Angeles',
  },
  '89107': {
    zip: '89107',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.173181',
    longitude: '-115.209884',
    timeZoneId: 'America/Los_Angeles',
  },
  '89108': {
    zip: '89108',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.205858',
    longitude: '-115.225094',
    timeZoneId: 'America/Los_Angeles',
  },
  '89109': {
    zip: '89109',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.124358',
    longitude: '-115.145079',
    timeZoneId: 'America/Los_Angeles',
  },
  '89110': {
    zip: '89110',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.173684',
    longitude: '-115.059521',
    timeZoneId: 'America/Los_Angeles',
  },
  '89111': {
    zip: '89111',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.089397',
    longitude: '-115.148121',
    timeZoneId: 'America/Los_Angeles',
  },
  '89112': {
    zip: '89112',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.115583',
    longitude: '-115.015105',
    timeZoneId: 'America/Los_Angeles',
  },
  '89113': {
    zip: '89113',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.067463',
    longitude: '-115.255782',
    timeZoneId: 'America/Los_Angeles',
  },
  '89114': {
    zip: '89114',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.151263',
    longitude: '-115.153968',
    timeZoneId: 'America/Los_Angeles',
  },
  '89115': {
    zip: '89115',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.229916',
    longitude: '-115.07549',
    timeZoneId: 'America/Los_Angeles',
  },
  '89116': {
    zip: '89116',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.152678',
    longitude: '-115.113609',
    timeZoneId: 'America/Los_Angeles',
  },
  '89117': {
    zip: '89117',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.140602',
    longitude: '-115.27402',
    timeZoneId: 'America/Los_Angeles',
  },
  '89118': {
    zip: '89118',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.080056',
    longitude: '-115.213988',
    timeZoneId: 'America/Los_Angeles',
  },
  '89119': {
    zip: '89119',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.086953',
    longitude: '-115.137796',
    timeZoneId: 'America/Los_Angeles',
  },
  '89120': {
    zip: '89120',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.084157',
    longitude: '-115.099291',
    timeZoneId: 'America/Los_Angeles',
  },
  '89121': {
    zip: '89121',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.10451',
    longitude: '-115.06771',
    timeZoneId: 'America/Los_Angeles',
  },
  '89122': {
    zip: '89122',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.119073',
    longitude: '-115.048202',
    timeZoneId: 'America/Los_Angeles',
  },
  '89123': {
    zip: '89123',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.023482',
    longitude: '-115.150519',
    timeZoneId: 'America/Los_Angeles',
  },
  '89124': {
    zip: '89124',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '35.94461',
    longitude: '-115.22281',
    timeZoneId: 'America/Los_Angeles',
  },
  '89125': {
    zip: '89125',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.171247',
    longitude: '-115.124266',
    timeZoneId: 'America/Los_Angeles',
  },
  '89126': {
    zip: '89126',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.146852',
    longitude: '-115.20296',
    timeZoneId: 'America/Los_Angeles',
  },
  '89127': {
    zip: '89127',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.185118',
    longitude: '-115.162798',
    timeZoneId: 'America/Los_Angeles',
  },
  '89128': {
    zip: '89128',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.196444',
    longitude: '-115.296213',
    timeZoneId: 'America/Los_Angeles',
  },
  '89129': {
    zip: '89129',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.235494',
    longitude: '-115.290718',
    timeZoneId: 'America/Los_Angeles',
  },
  '89130': {
    zip: '89130',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.255509',
    longitude: '-115.227097',
    timeZoneId: 'America/Los_Angeles',
  },
  '89131': {
    zip: '89131',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.300613',
    longitude: '-115.237936',
    timeZoneId: 'America/Los_Angeles',
  },
  '89132': {
    zip: '89132',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.085836',
    longitude: '-115.148467',
    timeZoneId: 'America/Los_Angeles',
  },
  '89133': {
    zip: '89133',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.205255',
    longitude: '-115.250871',
    timeZoneId: 'America/Los_Angeles',
  },
  '89134': {
    zip: '89134',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.21132',
    longitude: '-115.29384',
    timeZoneId: 'America/Los_Angeles',
  },
  '89135': {
    zip: '89135',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.140027',
    longitude: '-115.350114',
    timeZoneId: 'America/Los_Angeles',
  },
  '89136': {
    zip: '89136',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.174109',
    longitude: '-115.134431',
    timeZoneId: 'America/Los_Angeles',
  },
  '89137': {
    zip: '89137',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.190857',
    longitude: '-115.311325',
    timeZoneId: 'America/Los_Angeles',
  },
  '89138': {
    zip: '89138',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.185165',
    longitude: '-115.38737',
    timeZoneId: 'America/Los_Angeles',
  },
  '89139': {
    zip: '89139',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.040915',
    longitude: '-115.194368',
    timeZoneId: 'America/Los_Angeles',
  },
  '89140': {
    zip: '89140',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.171909',
    longitude: '-115.139969',
    timeZoneId: 'America/Los_Angeles',
  },
  '89141': {
    zip: '89141',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '35.992372',
    longitude: '-115.211387',
    timeZoneId: 'America/Los_Angeles',
  },
  '89142': {
    zip: '89142',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.156108',
    longitude: '-115.032164',
    timeZoneId: 'America/Los_Angeles',
  },
  '89143': {
    zip: '89143',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.322533',
    longitude: '-115.283692',
    timeZoneId: 'America/Los_Angeles',
  },
  '89144': {
    zip: '89144',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.179908',
    longitude: '-115.327153',
    timeZoneId: 'America/Los_Angeles',
  },
  '89145': {
    zip: '89145',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.168465',
    longitude: '-115.276824',
    timeZoneId: 'America/Los_Angeles',
  },
  '89146': {
    zip: '89146',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.13937',
    longitude: '-115.225442',
    timeZoneId: 'America/Los_Angeles',
  },
  '89147': {
    zip: '89147',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.113539',
    longitude: '-115.272705',
    timeZoneId: 'America/Los_Angeles',
  },
  '89148': {
    zip: '89148',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.057633',
    longitude: '-115.319731',
    timeZoneId: 'America/Los_Angeles',
  },
  '89149': {
    zip: '89149',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.283793',
    longitude: '-115.30593',
    timeZoneId: 'America/Los_Angeles',
  },
  '89150': {
    zip: '89150',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.127385',
    longitude: '-115.216214',
    timeZoneId: 'America/Los_Angeles',
  },
  '89151': {
    zip: '89151',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.145667',
    longitude: '-115.227731',
    timeZoneId: 'America/Los_Angeles',
  },
  '89152': {
    zip: '89152',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.170712',
    longitude: '-115.209471',
    timeZoneId: 'America/Los_Angeles',
  },
  '89153': {
    zip: '89153',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.16229',
    longitude: '-115.203507',
    timeZoneId: 'America/Los_Angeles',
  },
  '89154': {
    zip: '89154',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.086541',
    longitude: '-115.146079',
    timeZoneId: 'America/Los_Angeles',
  },
  '89155': {
    zip: '89155',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.172209',
    longitude: '-115.120274',
    timeZoneId: 'America/Los_Angeles',
  },
  '89156': {
    zip: '89156',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.22439',
    longitude: '-115.026222',
    timeZoneId: 'America/Los_Angeles',
  },
  '89157': {
    zip: '89157',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.171909',
    longitude: '-115.139969',
    timeZoneId: 'America/Los_Angeles',
  },
  '89158': {
    zip: '89158',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.171909',
    longitude: '-115.139969',
    timeZoneId: 'America/Los_Angeles',
  },
  '89159': {
    zip: '89159',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.069731',
    longitude: '-115.143235',
    timeZoneId: 'America/Los_Angeles',
  },
  '89160': {
    zip: '89160',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.123119',
    longitude: '-115.088896',
    timeZoneId: 'America/Los_Angeles',
  },
  '89161': {
    zip: '89161',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.1226',
    longitude: '-115.0922',
    timeZoneId: 'America/Los_Angeles',
  },
  '89162': {
    zip: '89162',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.172247',
    longitude: '-115.136977',
    timeZoneId: 'America/Los_Angeles',
  },
  '89163': {
    zip: '89163',
    city: 'The Lakes',
    state: 'NV',
    latitude: '36.174641',
    longitude: '-115.138381',
    timeZoneId: 'America/Los_Angeles',
  },
  '89164': {
    zip: '89164',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.127669',
    longitude: '-115.277477',
    timeZoneId: 'America/Los_Angeles',
  },
  '89165': {
    zip: '89165',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.20099',
    longitude: '-115.267609',
    timeZoneId: 'America/Los_Angeles',
  },
  '89166': {
    zip: '89166',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.416219',
    longitude: '-115.609196',
    timeZoneId: 'America/Los_Angeles',
  },
  '89169': {
    zip: '89169',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.124179',
    longitude: '-115.141282',
    timeZoneId: 'America/Los_Angeles',
  },
  '89170': {
    zip: '89170',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.08585',
    longitude: '-115.146603',
    timeZoneId: 'America/Los_Angeles',
  },
  '89173': {
    zip: '89173',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.177757',
    longitude: '-115.134175',
    timeZoneId: 'America/Los_Angeles',
  },
  '89177': {
    zip: '89177',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.083659',
    longitude: '-115.148315',
    timeZoneId: 'America/Los_Angeles',
  },
  '89178': {
    zip: '89178',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '35.997544',
    longitude: '-115.270936',
    timeZoneId: 'America/Los_Angeles',
  },
  '89179': {
    zip: '89179',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '35.996955',
    longitude: '-115.251532',
    timeZoneId: 'America/Los_Angeles',
  },
  '89180': {
    zip: '89180',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.152556',
    longitude: '-115.107244',
    timeZoneId: 'America/Los_Angeles',
  },
  '89183': {
    zip: '89183',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.153552',
    longitude: '-115.106506',
    timeZoneId: 'America/Los_Angeles',
  },
  '89185': {
    zip: '89185',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.150862',
    longitude: '-115.113136',
    timeZoneId: 'America/Los_Angeles',
  },
  '89191': {
    zip: '89191',
    city: 'Nellis AFB',
    state: 'NV',
    latitude: '36.244017',
    longitude: '-115.040965',
    timeZoneId: 'America/Los_Angeles',
  },
  '89193': {
    zip: '89193',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.083862',
    longitude: '-115.145672',
    timeZoneId: 'America/Los_Angeles',
  },
  '89195': {
    zip: '89195',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.205334',
    longitude: '-115.223749',
    timeZoneId: 'America/Los_Angeles',
  },
  '89199': {
    zip: '89199',
    city: 'Las Vegas',
    state: 'NV',
    latitude: '36.151206',
    longitude: '-115.108207',
    timeZoneId: 'America/Los_Angeles',
  },
  '89301': {
    zip: '89301',
    city: 'Ely',
    state: 'NV',
    latitude: '39.38196',
    longitude: '-114.88344',
    timeZoneId: 'America/Los_Angeles',
  },
  '89310': {
    zip: '89310',
    city: 'Austin',
    state: 'NV',
    latitude: '39.738476',
    longitude: '-117.193253',
    timeZoneId: 'America/Los_Angeles',
  },
  '89311': {
    zip: '89311',
    city: 'Baker',
    state: 'NV',
    latitude: '39.000092',
    longitude: '-114.164202',
    timeZoneId: 'America/Los_Angeles',
  },
  '89314': {
    zip: '89314',
    city: 'Duckwater',
    state: 'NV',
    latitude: '38.946009',
    longitude: '-115.707247',
    timeZoneId: 'America/Los_Angeles',
  },
  '89315': {
    zip: '89315',
    city: 'Ely',
    state: 'NV',
    latitude: '39.265066',
    longitude: '-114.86518',
    timeZoneId: 'America/Los_Angeles',
  },
  '89316': {
    zip: '89316',
    city: 'Eureka',
    state: 'NV',
    latitude: '39.514548',
    longitude: '-115.965895',
    timeZoneId: 'America/Los_Angeles',
  },
  '89317': {
    zip: '89317',
    city: 'Lund',
    state: 'NV',
    latitude: '38.908148',
    longitude: '-115.133606',
    timeZoneId: 'America/Los_Angeles',
  },
  '89318': {
    zip: '89318',
    city: 'Mc Gill',
    state: 'NV',
    latitude: '39.391389',
    longitude: '-114.786899',
    timeZoneId: 'America/Los_Angeles',
  },
  '89319': {
    zip: '89319',
    city: 'Ruth',
    state: 'NV',
    latitude: '39.278199',
    longitude: '-114.991266',
    timeZoneId: 'America/Los_Angeles',
  },
  '89402': {
    zip: '89402',
    city: 'Crystal Bay',
    state: 'NV',
    latitude: '39.281362',
    longitude: '-119.951752',
    timeZoneId: 'America/Los_Angeles',
  },
  '89403': {
    zip: '89403',
    city: 'Dayton',
    state: 'NV',
    latitude: '39.270238',
    longitude: '-119.538034',
    timeZoneId: 'America/Los_Angeles',
  },
  '89404': {
    zip: '89404',
    city: 'Denio',
    state: 'NV',
    latitude: '41.98816',
    longitude: '-118.62364',
    timeZoneId: 'America/Los_Angeles',
  },
  '89405': {
    zip: '89405',
    city: 'Empire',
    state: 'NV',
    latitude: '40.576889',
    longitude: '-119.341788',
    timeZoneId: 'America/Los_Angeles',
  },
  '89406': {
    zip: '89406',
    city: 'Fallon',
    state: 'NV',
    latitude: '39.48066',
    longitude: '-118.76813',
    timeZoneId: 'America/Los_Angeles',
  },
  '89407': {
    zip: '89407',
    city: 'Fallon',
    state: 'NV',
    latitude: '39.471205',
    longitude: '-118.780385',
    timeZoneId: 'America/Los_Angeles',
  },
  '89408': {
    zip: '89408',
    city: 'Fernley',
    state: 'NV',
    latitude: '39.597279',
    longitude: '-119.231521',
    timeZoneId: 'America/Los_Angeles',
  },
  '89409': {
    zip: '89409',
    city: 'Gabbs',
    state: 'NV',
    latitude: '38.861774',
    longitude: '-117.925368',
    timeZoneId: 'America/Los_Angeles',
  },
  '89410': {
    zip: '89410',
    city: 'Gardnerville',
    state: 'NV',
    latitude: '38.82954',
    longitude: '-119.71458',
    timeZoneId: 'America/Los_Angeles',
  },
  '89411': {
    zip: '89411',
    city: 'Genoa',
    state: 'NV',
    latitude: '39.011047',
    longitude: '-119.841497',
    timeZoneId: 'America/Los_Angeles',
  },
  '89412': {
    zip: '89412',
    city: 'Gerlach',
    state: 'NV',
    latitude: '40.651975',
    longitude: '-119.355692',
    timeZoneId: 'America/Los_Angeles',
  },
  '89413': {
    zip: '89413',
    city: 'Glenbrook',
    state: 'NV',
    latitude: '39.081994',
    longitude: '-119.937493',
    timeZoneId: 'America/Los_Angeles',
  },
  '89414': {
    zip: '89414',
    city: 'Golconda',
    state: 'NV',
    latitude: '41.007834',
    longitude: '-117.299047',
    timeZoneId: 'America/Los_Angeles',
  },
  '89415': {
    zip: '89415',
    city: 'Hawthorne',
    state: 'NV',
    latitude: '38.59968',
    longitude: '-118.6845',
    timeZoneId: 'America/Los_Angeles',
  },
  '89418': {
    zip: '89418',
    city: 'Imlay',
    state: 'NV',
    latitude: '40.66',
    longitude: '-118.14',
    timeZoneId: 'America/Los_Angeles',
  },
  '89419': {
    zip: '89419',
    city: 'Lovelock',
    state: 'NV',
    latitude: '40.177849',
    longitude: '-118.479076',
    timeZoneId: 'America/Los_Angeles',
  },
  '89420': {
    zip: '89420',
    city: 'Luning',
    state: 'NV',
    latitude: '38.417477',
    longitude: '-118.190097',
    timeZoneId: 'America/Los_Angeles',
  },
  '89421': {
    zip: '89421',
    city: 'Mc Dermitt',
    state: 'NV',
    latitude: '41.990819',
    longitude: '-117.712764',
    timeZoneId: 'America/Los_Angeles',
  },
  '89422': {
    zip: '89422',
    city: 'Mina',
    state: 'NV',
    latitude: '38.386579',
    longitude: '-118.108409',
    timeZoneId: 'America/Los_Angeles',
  },
  '89423': {
    zip: '89423',
    city: 'Minden',
    state: 'NV',
    latitude: '39.005489',
    longitude: '-119.735877',
    timeZoneId: 'America/Los_Angeles',
  },
  '89424': {
    zip: '89424',
    city: 'Nixon',
    state: 'NV',
    latitude: '39.840656',
    longitude: '-119.364727',
    timeZoneId: 'America/Los_Angeles',
  },
  '89425': {
    zip: '89425',
    city: 'Orovada',
    state: 'NV',
    latitude: '41.738584',
    longitude: '-118.090715',
    timeZoneId: 'America/Los_Angeles',
  },
  '89426': {
    zip: '89426',
    city: 'Paradise Valley',
    state: 'NV',
    latitude: '41.600543',
    longitude: '-117.381616',
    timeZoneId: 'America/Los_Angeles',
  },
  '89427': {
    zip: '89427',
    city: 'Schurz',
    state: 'NV',
    latitude: '38.948754',
    longitude: '-118.818822',
    timeZoneId: 'America/Los_Angeles',
  },
  '89428': {
    zip: '89428',
    city: 'Silver City',
    state: 'NV',
    latitude: '39.265737',
    longitude: '-119.640036',
    timeZoneId: 'America/Los_Angeles',
  },
  '89429': {
    zip: '89429',
    city: 'Silver Springs',
    state: 'NV',
    latitude: '39.3582',
    longitude: '-119.29518',
    timeZoneId: 'America/Los_Angeles',
  },
  '89430': {
    zip: '89430',
    city: 'Smith',
    state: 'NV',
    latitude: '38.764947',
    longitude: '-119.324642',
    timeZoneId: 'America/Los_Angeles',
  },
  '89431': {
    zip: '89431',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.548066',
    longitude: '-119.734394',
    timeZoneId: 'America/Los_Angeles',
  },
  '89432': {
    zip: '89432',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.540878',
    longitude: '-119.747797',
    timeZoneId: 'America/Los_Angeles',
  },
  '89433': {
    zip: '89433',
    city: 'Sun Valley',
    state: 'NV',
    latitude: '39.596122',
    longitude: '-119.775235',
    timeZoneId: 'America/Los_Angeles',
  },
  '89434': {
    zip: '89434',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.552532',
    longitude: '-119.716592',
    timeZoneId: 'America/Los_Angeles',
  },
  '89435': {
    zip: '89435',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.544276',
    longitude: '-119.735636',
    timeZoneId: 'America/Los_Angeles',
  },
  '89436': {
    zip: '89436',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.645257',
    longitude: '-119.698716',
    timeZoneId: 'America/Los_Angeles',
  },
  '89437': {
    zip: '89437',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.645257',
    longitude: '-119.698716',
    timeZoneId: 'America/Los_Angeles',
  },
  '89438': {
    zip: '89438',
    city: 'Valmy',
    state: 'NV',
    latitude: '40.791997',
    longitude: '-117.123163',
    timeZoneId: 'America/Los_Angeles',
  },
  '89439': {
    zip: '89439',
    city: 'Verdi',
    state: 'NV',
    latitude: '39.516463',
    longitude: '-119.980547',
    timeZoneId: 'America/Los_Angeles',
  },
  '89440': {
    zip: '89440',
    city: 'Virginia City',
    state: 'NV',
    latitude: '39.345624',
    longitude: '-119.648752',
    timeZoneId: 'America/Los_Angeles',
  },
  '89441': {
    zip: '89441',
    city: 'Sparks',
    state: 'NV',
    latitude: '39.345519',
    longitude: '-119.648215',
    timeZoneId: 'America/Los_Angeles',
  },
  '89442': {
    zip: '89442',
    city: 'Wadsworth',
    state: 'NV',
    latitude: '39.642321',
    longitude: '-119.281177',
    timeZoneId: 'America/Los_Angeles',
  },
  '89444': {
    zip: '89444',
    city: 'Wellington',
    state: 'NV',
    latitude: '38.8422',
    longitude: '-119.3679',
    timeZoneId: 'America/Los_Angeles',
  },
  '89445': {
    zip: '89445',
    city: 'Winnemucca',
    state: 'NV',
    latitude: '41.12556',
    longitude: '-117.78672',
    timeZoneId: 'America/Los_Angeles',
  },
  '89446': {
    zip: '89446',
    city: 'Winnemucca',
    state: 'NV',
    latitude: '40.967126',
    longitude: '-117.728697',
    timeZoneId: 'America/Los_Angeles',
  },
  '89447': {
    zip: '89447',
    city: 'Yerington',
    state: 'NV',
    latitude: '38.92146',
    longitude: '-119.11764',
    timeZoneId: 'America/Los_Angeles',
  },
  '89448': {
    zip: '89448',
    city: 'Zephyr Cove',
    state: 'NV',
    latitude: '39.00222',
    longitude: '-119.955',
    timeZoneId: 'America/Los_Angeles',
  },
  '89449': {
    zip: '89449',
    city: 'Stateline',
    state: 'NV',
    latitude: '38.9625',
    longitude: '-119.9388',
    timeZoneId: 'America/Los_Angeles',
  },
  '89450': {
    zip: '89450',
    city: 'Incline Village',
    state: 'NV',
    latitude: '39.256452',
    longitude: '-119.955498',
    timeZoneId: 'America/Los_Angeles',
  },
  '89451': {
    zip: '89451',
    city: 'Incline Village',
    state: 'NV',
    latitude: '39.252736',
    longitude: '-119.954554',
    timeZoneId: 'America/Los_Angeles',
  },
  '89452': {
    zip: '89452',
    city: 'Incline Village',
    state: 'NV',
    latitude: '39.244739',
    longitude: '-119.964921',
    timeZoneId: 'America/Los_Angeles',
  },
  '89460': {
    zip: '89460',
    city: 'Gardnerville',
    state: 'NV',
    latitude: '38.893575',
    longitude: '-119.722788',
    timeZoneId: 'America/Los_Angeles',
  },
  '89496': {
    zip: '89496',
    city: 'Fallon',
    state: 'NV',
    latitude: '39.477965',
    longitude: '-118.78033',
    timeZoneId: 'America/Los_Angeles',
  },
  '89501': {
    zip: '89501',
    city: 'Reno',
    state: 'NV',
    latitude: '39.52774',
    longitude: '-119.812619',
    timeZoneId: 'America/Los_Angeles',
  },
  '89502': {
    zip: '89502',
    city: 'Reno',
    state: 'NV',
    latitude: '39.500666',
    longitude: '-119.765124',
    timeZoneId: 'America/Los_Angeles',
  },
  '89503': {
    zip: '89503',
    city: 'Reno',
    state: 'NV',
    latitude: '39.53898',
    longitude: '-119.838457',
    timeZoneId: 'America/Los_Angeles',
  },
  '89504': {
    zip: '89504',
    city: 'Reno',
    state: 'NV',
    latitude: '39.681276',
    longitude: '-119.841848',
    timeZoneId: 'America/Los_Angeles',
  },
  '89505': {
    zip: '89505',
    city: 'Reno',
    state: 'NV',
    latitude: '39.529321',
    longitude: '-119.804953',
    timeZoneId: 'America/Los_Angeles',
  },
  '89506': {
    zip: '89506',
    city: 'Reno',
    state: 'NV',
    latitude: '39.663392',
    longitude: '-119.884208',
    timeZoneId: 'America/Los_Angeles',
  },
  '89507': {
    zip: '89507',
    city: 'Reno',
    state: 'NV',
    latitude: '39.547923',
    longitude: '-119.819079',
    timeZoneId: 'America/Los_Angeles',
  },
  '89508': {
    zip: '89508',
    city: 'Reno',
    state: 'NV',
    latitude: '39.630232',
    longitude: '-119.870037',
    timeZoneId: 'America/Los_Angeles',
  },
  '89509': {
    zip: '89509',
    city: 'Reno',
    state: 'NV',
    latitude: '39.477902',
    longitude: '-119.897581',
    timeZoneId: 'America/Los_Angeles',
  },
  '89510': {
    zip: '89510',
    city: 'Reno',
    state: 'NV',
    latitude: '39.770408',
    longitude: '-119.617338',
    timeZoneId: 'America/Los_Angeles',
  },
  '89511': {
    zip: '89511',
    city: 'Reno',
    state: 'NV',
    latitude: '39.382155',
    longitude: '-119.75183',
    timeZoneId: 'America/Los_Angeles',
  },
  '89512': {
    zip: '89512',
    city: 'Reno',
    state: 'NV',
    latitude: '39.54989',
    longitude: '-119.797993',
    timeZoneId: 'America/Los_Angeles',
  },
  '89513': {
    zip: '89513',
    city: 'Reno',
    state: 'NV',
    latitude: '39.504939',
    longitude: '-119.856253',
    timeZoneId: 'America/Los_Angeles',
  },
  '89515': {
    zip: '89515',
    city: 'Reno',
    state: 'NV',
    latitude: '39.498337',
    longitude: '-119.746476',
    timeZoneId: 'America/Los_Angeles',
  },
  '89519': {
    zip: '89519',
    city: 'Reno',
    state: 'NV',
    latitude: '39.498928',
    longitude: '-119.745485',
    timeZoneId: 'America/Los_Angeles',
  },
  '89520': {
    zip: '89520',
    city: 'Reno',
    state: 'NV',
    latitude: '39.49496',
    longitude: '-119.742902',
    timeZoneId: 'America/Los_Angeles',
  },
  '89521': {
    zip: '89521',
    city: 'Reno',
    state: 'NV',
    latitude: '39.37992',
    longitude: '-119.753303',
    timeZoneId: 'America/Los_Angeles',
  },
  '89523': {
    zip: '89523',
    city: 'Reno',
    state: 'NV',
    latitude: '39.525676',
    longitude: '-119.890959',
    timeZoneId: 'America/Los_Angeles',
  },
  '89533': {
    zip: '89533',
    city: 'Reno',
    state: 'NV',
    latitude: '39.529229',
    longitude: '-119.881028',
    timeZoneId: 'America/Los_Angeles',
  },
  '89555': {
    zip: '89555',
    city: 'Reno',
    state: 'NV',
    latitude: '39.529646',
    longitude: '-119.812392',
    timeZoneId: 'America/Los_Angeles',
  },
  '89557': {
    zip: '89557',
    city: 'Reno',
    state: 'NV',
    latitude: '39.539719',
    longitude: '-119.818005',
    timeZoneId: 'America/Los_Angeles',
  },
  '89570': {
    zip: '89570',
    city: 'Reno',
    state: 'NV',
    latitude: '39.495119',
    longitude: '-119.747101',
    timeZoneId: 'America/Los_Angeles',
  },
  '89595': {
    zip: '89595',
    city: 'Reno',
    state: 'NV',
    latitude: '39.527847',
    longitude: '-119.782389',
    timeZoneId: 'America/Los_Angeles',
  },
  '89599': {
    zip: '89599',
    city: 'Reno',
    state: 'NV',
    latitude: '39.530106',
    longitude: '-119.81381',
    timeZoneId: 'America/Los_Angeles',
  },
  '89701': {
    zip: '89701',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.19662',
    longitude: '-119.76192',
    timeZoneId: 'America/Los_Angeles',
  },
  '89702': {
    zip: '89702',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.069397',
    longitude: '-119.792347',
    timeZoneId: 'America/Los_Angeles',
  },
  '89703': {
    zip: '89703',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.170644',
    longitude: '-119.78193',
    timeZoneId: 'America/Los_Angeles',
  },
  '89704': {
    zip: '89704',
    city: 'Washoe Valley',
    state: 'NV',
    latitude: '39.283073',
    longitude: '-119.794595',
    timeZoneId: 'America/Los_Angeles',
  },
  '89705': {
    zip: '89705',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.08904',
    longitude: '-119.788777',
    timeZoneId: 'America/Los_Angeles',
  },
  '89706': {
    zip: '89706',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.186004',
    longitude: '-119.74539',
    timeZoneId: 'America/Los_Angeles',
  },
  '89711': {
    zip: '89711',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.163917',
    longitude: '-119.765948',
    timeZoneId: 'America/Los_Angeles',
  },
  '89712': {
    zip: '89712',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.163703',
    longitude: '-119.764433',
    timeZoneId: 'America/Los_Angeles',
  },
  '89713': {
    zip: '89713',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.160959',
    longitude: '-119.765544',
    timeZoneId: 'America/Los_Angeles',
  },
  '89714': {
    zip: '89714',
    city: 'Carson City',
    state: 'NV',
    latitude: '39.13573',
    longitude: '-119.6642',
    timeZoneId: 'America/Los_Angeles',
  },
  '89721': {
    zip: '89721',
    city: 'Carson City',
    state: 'NV',
    latitude: '38.979214',
    longitude: '-119.729519',
    timeZoneId: 'America/Los_Angeles',
  },
  '89801': {
    zip: '89801',
    city: 'Elko',
    state: 'NV',
    latitude: '40.834645',
    longitude: '-115.757583',
    timeZoneId: 'America/Los_Angeles',
  },
  '89802': {
    zip: '89802',
    city: 'Elko',
    state: 'NV',
    latitude: '40.859699',
    longitude: '-115.71495',
    timeZoneId: 'America/Los_Angeles',
  },
  '89803': {
    zip: '89803',
    city: 'Elko',
    state: 'NV',
    latitude: '40.859443',
    longitude: '-115.715009',
    timeZoneId: 'America/Los_Angeles',
  },
  '89815': {
    zip: '89815',
    city: 'Spring Creek',
    state: 'NV',
    latitude: '40.551508',
    longitude: '-115.576891',
    timeZoneId: 'America/Los_Angeles',
  },
  '89820': {
    zip: '89820',
    city: 'Battle Mountain',
    state: 'NV',
    latitude: '40.619494',
    longitude: '-116.936088',
    timeZoneId: 'America/Los_Angeles',
  },
  '89821': {
    zip: '89821',
    city: 'Crescent Valley',
    state: 'NV',
    latitude: '40.58',
    longitude: '-116.48',
    timeZoneId: 'America/Los_Angeles',
  },
  '89822': {
    zip: '89822',
    city: 'Carlin',
    state: 'NV',
    latitude: '40.716972',
    longitude: '-116.105345',
    timeZoneId: 'America/Los_Angeles',
  },
  '89823': {
    zip: '89823',
    city: 'Deeth',
    state: 'NV',
    latitude: '41.08032',
    longitude: '-115.27146',
    timeZoneId: 'America/Los_Angeles',
  },
  '89825': {
    zip: '89825',
    city: 'Jackpot',
    state: 'NV',
    latitude: '41.7636',
    longitude: '-115.07472',
    timeZoneId: 'America/Los_Angeles',
  },
  '89826': {
    zip: '89826',
    city: 'Jarbidge',
    state: 'NV',
    latitude: '41.85242',
    longitude: '-115.417537',
    timeZoneId: 'America/Los_Angeles',
  },
  '89828': {
    zip: '89828',
    city: 'Lamoille',
    state: 'NV',
    latitude: '40.722475',
    longitude: '-115.561395',
    timeZoneId: 'America/Los_Angeles',
  },
  '89830': {
    zip: '89830',
    city: 'Montello',
    state: 'NV',
    latitude: '41.261351',
    longitude: '-114.19032',
    timeZoneId: 'America/Los_Angeles',
  },
  '89831': {
    zip: '89831',
    city: 'Mountain City',
    state: 'NV',
    latitude: '41.85392',
    longitude: '-115.95675',
    timeZoneId: 'America/Los_Angeles',
  },
  '89832': {
    zip: '89832',
    city: 'Owyhee',
    state: 'NV',
    latitude: '41.750437',
    longitude: '-116.013069',
    timeZoneId: 'America/Los_Angeles',
  },
  '89833': {
    zip: '89833',
    city: 'Ruby Valley',
    state: 'NV',
    latitude: '40.306038',
    longitude: '-115.284616',
    timeZoneId: 'America/Los_Angeles',
  },
  '89834': {
    zip: '89834',
    city: 'Tuscarora',
    state: 'NV',
    latitude: '41.114995',
    longitude: '-116.642583',
    timeZoneId: 'America/Los_Angeles',
  },
  '89835': {
    zip: '89835',
    city: 'Wells',
    state: 'NV',
    latitude: '41.108927',
    longitude: '-114.965942',
    timeZoneId: 'America/Los_Angeles',
  },
  '89883': {
    zip: '89883',
    city: 'West Wendover',
    state: 'NV',
    latitude: '40.69133',
    longitude: '-114.143019',
    timeZoneId: 'America/Los_Angeles',
  },
  '90001': {
    zip: '90001',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.97331',
    longitude: '-118.243612',
    timeZoneId: 'America/Los_Angeles',
  },
  '90002': {
    zip: '90002',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.948432',
    longitude: '-118.245196',
    timeZoneId: 'America/Los_Angeles',
  },
  '90003': {
    zip: '90003',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.962989',
    longitude: '-118.272517',
    timeZoneId: 'America/Los_Angeles',
  },
  '90004': {
    zip: '90004',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.076333',
    longitude: '-118.307713',
    timeZoneId: 'America/Los_Angeles',
  },
  '90005': {
    zip: '90005',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.055509',
    longitude: '-118.30922',
    timeZoneId: 'America/Los_Angeles',
  },
  '90006': {
    zip: '90006',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.045817',
    longitude: '-118.290338',
    timeZoneId: 'America/Los_Angeles',
  },
  '90007': {
    zip: '90007',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.026525',
    longitude: '-118.282408',
    timeZoneId: 'America/Los_Angeles',
  },
  '90008': {
    zip: '90008',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.006715',
    longitude: '-118.339229',
    timeZoneId: 'America/Los_Angeles',
  },
  '90009': {
    zip: '90009',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.946348',
    longitude: '-118.379884',
    timeZoneId: 'America/Los_Angeles',
  },
  '90010': {
    zip: '90010',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.063478',
    longitude: '-118.314966',
    timeZoneId: 'America/Los_Angeles',
  },
  '90011': {
    zip: '90011',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.006146',
    longitude: '-118.257237',
    timeZoneId: 'America/Los_Angeles',
  },
  '90012': {
    zip: '90012',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.063679',
    longitude: '-118.238854',
    timeZoneId: 'America/Los_Angeles',
  },
  '90013': {
    zip: '90013',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.046676',
    longitude: '-118.241961',
    timeZoneId: 'America/Los_Angeles',
  },
  '90014': {
    zip: '90014',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.041273',
    longitude: '-118.252399',
    timeZoneId: 'America/Los_Angeles',
  },
  '90015': {
    zip: '90015',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.038557',
    longitude: '-118.268695',
    timeZoneId: 'America/Los_Angeles',
  },
  '90016': {
    zip: '90016',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.027729',
    longitude: '-118.351874',
    timeZoneId: 'America/Los_Angeles',
  },
  '90017': {
    zip: '90017',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.053387',
    longitude: '-118.265378',
    timeZoneId: 'America/Los_Angeles',
  },
  '90018': {
    zip: '90018',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.030481',
    longitude: '-118.318046',
    timeZoneId: 'America/Los_Angeles',
  },
  '90019': {
    zip: '90019',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.048281',
    longitude: '-118.342487',
    timeZoneId: 'America/Los_Angeles',
  },
  '90020': {
    zip: '90020',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.068259',
    longitude: '-118.31043',
    timeZoneId: 'America/Los_Angeles',
  },
  '90021': {
    zip: '90021',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.027564',
    longitude: '-118.241023',
    timeZoneId: 'America/Los_Angeles',
  },
  '90022': {
    zip: '90022',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.022686',
    longitude: '-118.152363',
    timeZoneId: 'America/Los_Angeles',
  },
  '90023': {
    zip: '90023',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.025925',
    longitude: '-118.200657',
    timeZoneId: 'America/Los_Angeles',
  },
  '90024': {
    zip: '90024',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.060189',
    longitude: '-118.4364',
    timeZoneId: 'America/Los_Angeles',
  },
  '90025': {
    zip: '90025',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.044073',
    longitude: '-118.451381',
    timeZoneId: 'America/Los_Angeles',
  },
  '90026': {
    zip: '90026',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.079313',
    longitude: '-118.262692',
    timeZoneId: 'America/Los_Angeles',
  },
  '90027': {
    zip: '90027',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.12356',
    longitude: '-118.28784',
    timeZoneId: 'America/Los_Angeles',
  },
  '90028': {
    zip: '90028',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.099218',
    longitude: '-118.327098',
    timeZoneId: 'America/Los_Angeles',
  },
  '90029': {
    zip: '90029',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.090213',
    longitude: '-118.293835',
    timeZoneId: 'America/Los_Angeles',
  },
  '90030': {
    zip: '90030',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.062074',
    longitude: '-118.238504',
    timeZoneId: 'America/Los_Angeles',
  },
  '90031': {
    zip: '90031',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.078396',
    longitude: '-118.211918',
    timeZoneId: 'America/Los_Angeles',
  },
  '90032': {
    zip: '90032',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.080616',
    longitude: '-118.174263',
    timeZoneId: 'America/Los_Angeles',
  },
  '90033': {
    zip: '90033',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.049807',
    longitude: '-118.209245',
    timeZoneId: 'America/Los_Angeles',
  },
  '90034': {
    zip: '90034',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.028026',
    longitude: '-118.399267',
    timeZoneId: 'America/Los_Angeles',
  },
  '90035': {
    zip: '90035',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.051619',
    longitude: '-118.38358',
    timeZoneId: 'America/Los_Angeles',
  },
  '90036': {
    zip: '90036',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.067699',
    longitude: '-118.350907',
    timeZoneId: 'America/Los_Angeles',
  },
  '90037': {
    zip: '90037',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.000915',
    longitude: '-118.288342',
    timeZoneId: 'America/Los_Angeles',
  },
  '90038': {
    zip: '90038',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.091104',
    longitude: '-118.34303',
    timeZoneId: 'America/Los_Angeles',
  },
  '90039': {
    zip: '90039',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.107325',
    longitude: '-118.260934',
    timeZoneId: 'America/Los_Angeles',
  },
  '90040': {
    zip: '90040',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.97698',
    longitude: '-118.12884',
    timeZoneId: 'America/Los_Angeles',
  },
  '90041': {
    zip: '90041',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.137442',
    longitude: '-118.206258',
    timeZoneId: 'America/Los_Angeles',
  },
  '90042': {
    zip: '90042',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.118577',
    longitude: '-118.189925',
    timeZoneId: 'America/Los_Angeles',
  },
  '90043': {
    zip: '90043',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.990206',
    longitude: '-118.335759',
    timeZoneId: 'America/Los_Angeles',
  },
  '90044': {
    zip: '90044',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.956268',
    longitude: '-118.290701',
    timeZoneId: 'America/Los_Angeles',
  },
  '90045': {
    zip: '90045',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.957494',
    longitude: '-118.397884',
    timeZoneId: 'America/Los_Angeles',
  },
  '90046': {
    zip: '90046',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.105576',
    longitude: '-118.366794',
    timeZoneId: 'America/Los_Angeles',
  },
  '90047': {
    zip: '90047',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.953467',
    longitude: '-118.311177',
    timeZoneId: 'America/Los_Angeles',
  },
  '90048': {
    zip: '90048',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.073253',
    longitude: '-118.377034',
    timeZoneId: 'America/Los_Angeles',
  },
  '90049': {
    zip: '90049',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.078365',
    longitude: '-118.486589',
    timeZoneId: 'America/Los_Angeles',
  },
  '90050': {
    zip: '90050',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.121378',
    longitude: '-118.206906',
    timeZoneId: 'America/Los_Angeles',
  },
  '90051': {
    zip: '90051',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.062744',
    longitude: '-118.24314',
    timeZoneId: 'America/Los_Angeles',
  },
  '90052': {
    zip: '90052',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.974069',
    longitude: '-118.24851',
    timeZoneId: 'America/Los_Angeles',
  },
  '90053': {
    zip: '90053',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.055647',
    longitude: '-118.240465',
    timeZoneId: 'America/Los_Angeles',
  },
  '90054': {
    zip: '90054',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.059368',
    longitude: '-118.236094',
    timeZoneId: 'America/Los_Angeles',
  },
  '90055': {
    zip: '90055',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.047536',
    longitude: '-118.256556',
    timeZoneId: 'America/Los_Angeles',
  },
  '90056': {
    zip: '90056',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.984248',
    longitude: '-118.364182',
    timeZoneId: 'America/Los_Angeles',
  },
  '90057': {
    zip: '90057',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.060269',
    longitude: '-118.276734',
    timeZoneId: 'America/Los_Angeles',
  },
  '90058': {
    zip: '90058',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.0023',
    longitude: '-118.21152',
    timeZoneId: 'America/Los_Angeles',
  },
  '90059': {
    zip: '90059',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.926115',
    longitude: '-118.242201',
    timeZoneId: 'America/Los_Angeles',
  },
  '90060': {
    zip: '90060',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.995744',
    longitude: '-118.422017',
    timeZoneId: 'America/Los_Angeles',
  },
  '90061': {
    zip: '90061',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.922433',
    longitude: '-118.275162',
    timeZoneId: 'America/Los_Angeles',
  },
  '90062': {
    zip: '90062',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.00675',
    longitude: '-118.307195',
    timeZoneId: 'America/Los_Angeles',
  },
  '90063': {
    zip: '90063',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.042454',
    longitude: '-118.184723',
    timeZoneId: 'America/Los_Angeles',
  },
  '90064': {
    zip: '90064',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.035989',
    longitude: '-118.42262',
    timeZoneId: 'America/Los_Angeles',
  },
  '90065': {
    zip: '90065',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.10598',
    longitude: '-118.229781',
    timeZoneId: 'America/Los_Angeles',
  },
  '90066': {
    zip: '90066',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.993713',
    longitude: '-118.42832',
    timeZoneId: 'America/Los_Angeles',
  },
  '90067': {
    zip: '90067',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.058123',
    longitude: '-118.416403',
    timeZoneId: 'America/Los_Angeles',
  },
  '90068': {
    zip: '90068',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.120733',
    longitude: '-118.335397',
    timeZoneId: 'America/Los_Angeles',
  },
  '90069': {
    zip: '90069',
    city: 'West Hollywood',
    state: 'CA',
    latitude: '34.091537',
    longitude: '-118.38202',
    timeZoneId: 'America/Los_Angeles',
  },
  '90070': {
    zip: '90070',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.06244',
    longitude: '-118.310436',
    timeZoneId: 'America/Los_Angeles',
  },
  '90071': {
    zip: '90071',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.053598',
    longitude: '-118.255851',
    timeZoneId: 'America/Los_Angeles',
  },
  '90072': {
    zip: '90072',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.097188',
    longitude: '-118.309018',
    timeZoneId: 'America/Los_Angeles',
  },
  '90073': {
    zip: '90073',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.052501',
    longitude: '-118.455227',
    timeZoneId: 'America/Los_Angeles',
  },
  '90074': {
    zip: '90074',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.052583',
    longitude: '-118.26801',
    timeZoneId: 'America/Los_Angeles',
  },
  '90075': {
    zip: '90075',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.064493',
    longitude: '-118.309555',
    timeZoneId: 'America/Los_Angeles',
  },
  '90076': {
    zip: '90076',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.062531',
    longitude: '-118.308835',
    timeZoneId: 'America/Los_Angeles',
  },
  '90077': {
    zip: '90077',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.101509',
    longitude: '-118.452396',
    timeZoneId: 'America/Los_Angeles',
  },
  '90078': {
    zip: '90078',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.099648',
    longitude: '-118.328037',
    timeZoneId: 'America/Los_Angeles',
  },
  '90079': {
    zip: '90079',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.038986',
    longitude: '-118.256235',
    timeZoneId: 'America/Los_Angeles',
  },
  '90080': {
    zip: '90080',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.954771',
    longitude: '-118.396089',
    timeZoneId: 'America/Los_Angeles',
  },
  '90081': {
    zip: '90081',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.057006',
    longitude: '-118.26656',
    timeZoneId: 'America/Los_Angeles',
  },
  '90082': {
    zip: '90082',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.000545',
    longitude: '-118.279071',
    timeZoneId: 'America/Los_Angeles',
  },
  '90083': {
    zip: '90083',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.954859',
    longitude: '-118.400826',
    timeZoneId: 'America/Los_Angeles',
  },
  '90084': {
    zip: '90084',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.056524',
    longitude: '-118.265252',
    timeZoneId: 'America/Los_Angeles',
  },
  '90086': {
    zip: '90086',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.066364',
    longitude: '-118.239719',
    timeZoneId: 'America/Los_Angeles',
  },
  '90087': {
    zip: '90087',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.064384',
    longitude: '-118.240662',
    timeZoneId: 'America/Los_Angeles',
  },
  '90088': {
    zip: '90088',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.055128',
    longitude: '-118.261962',
    timeZoneId: 'America/Los_Angeles',
  },
  '90089': {
    zip: '90089',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.017658',
    longitude: '-118.284157',
    timeZoneId: 'America/Los_Angeles',
  },
  '90090': {
    zip: '90090',
    city: 'Dodgertown',
    state: 'CA',
    latitude: '34.013223',
    longitude: '-118.138227',
    timeZoneId: 'America/Los_Angeles',
  },
  '90091': {
    zip: '90091',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.989658',
    longitude: '-118.149153',
    timeZoneId: 'America/Los_Angeles',
  },
  '90093': {
    zip: '90093',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.098083',
    longitude: '-118.3364',
    timeZoneId: 'America/Los_Angeles',
  },
  '90094': {
    zip: '90094',
    city: 'Playa Vista',
    state: 'CA',
    latitude: '33.974553',
    longitude: '-118.425643',
    timeZoneId: 'America/Los_Angeles',
  },
  '90095': {
    zip: '90095',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.070199',
    longitude: '-118.45102',
    timeZoneId: 'America/Los_Angeles',
  },
  '90096': {
    zip: '90096',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '33.967004',
    longitude: '-118.172186',
    timeZoneId: 'America/Los_Angeles',
  },
  '90099': {
    zip: '90099',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.062936',
    longitude: '-118.236227',
    timeZoneId: 'America/Los_Angeles',
  },
  '90134': {
    zip: '90134',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.062936',
    longitude: '-118.236227',
    timeZoneId: 'America/Los_Angeles',
  },
  '90189': {
    zip: '90189',
    city: 'Los Angeles',
    state: 'CA',
    latitude: '34.051596',
    longitude: '-118.239035',
    timeZoneId: 'America/Los_Angeles',
  },
  '90201': {
    zip: '90201',
    city: 'Bell Gardens',
    state: 'CA',
    latitude: '33.969157',
    longitude: '-118.172903',
    timeZoneId: 'America/Los_Angeles',
  },
  '90202': {
    zip: '90202',
    city: 'Bell',
    state: 'CA',
    latitude: '33.969924',
    longitude: '-118.152353',
    timeZoneId: 'America/Los_Angeles',
  },
  '90209': {
    zip: '90209',
    city: 'Beverly Hills',
    state: 'CA',
    latitude: '34.071996',
    longitude: '-118.39142',
    timeZoneId: 'America/Los_Angeles',
  },
  '90210': {
    zip: '90210',
    city: 'Beverly Hills',
    state: 'CA',
    latitude: '34.096629',
    longitude: '-118.412426',
    timeZoneId: 'America/Los_Angeles',
  },
  '90211': {
    zip: '90211',
    city: 'Beverly Hills',
    state: 'CA',
    latitude: '34.062232',
    longitude: '-118.381514',
    timeZoneId: 'America/Los_Angeles',
  },
  '90212': {
    zip: '90212',
    city: 'Beverly Hills',
    state: 'CA',
    latitude: '34.061673',
    longitude: '-118.400135',
    timeZoneId: 'America/Los_Angeles',
  },
  '90213': {
    zip: '90213',
    city: 'Beverly Hills',
    state: 'CA',
    latitude: '34.054812',
    longitude: '-118.404164',
    timeZoneId: 'America/Los_Angeles',
  },
  '90220': {
    zip: '90220',
    city: 'Compton',
    state: 'CA',
    latitude: '33.890694',
    longitude: '-118.239135',
    timeZoneId: 'America/Los_Angeles',
  },
  '90221': {
    zip: '90221',
    city: 'Compton',
    state: 'CA',
    latitude: '33.87954',
    longitude: '-118.20054',
    timeZoneId: 'America/Los_Angeles',
  },
  '90222': {
    zip: '90222',
    city: 'Compton',
    state: 'CA',
    latitude: '33.911837',
    longitude: '-118.235008',
    timeZoneId: 'America/Los_Angeles',
  },
  '90223': {
    zip: '90223',
    city: 'Compton',
    state: 'CA',
    latitude: '33.892498',
    longitude: '-118.226452',
    timeZoneId: 'America/Los_Angeles',
  },
  '90224': {
    zip: '90224',
    city: 'Compton',
    state: 'CA',
    latitude: '33.871965',
    longitude: '-118.266771',
    timeZoneId: 'America/Los_Angeles',
  },
  '90230': {
    zip: '90230',
    city: 'Culver City',
    state: 'CA',
    latitude: '34.001758',
    longitude: '-118.394799',
    timeZoneId: 'America/Los_Angeles',
  },
  '90231': {
    zip: '90231',
    city: 'Culver City',
    state: 'CA',
    latitude: '33.999494',
    longitude: '-118.391303',
    timeZoneId: 'America/Los_Angeles',
  },
  '90232': {
    zip: '90232',
    city: 'Culver City',
    state: 'CA',
    latitude: '34.014991',
    longitude: '-118.394848',
    timeZoneId: 'America/Los_Angeles',
  },
  '90233': {
    zip: '90233',
    city: 'Culver City',
    state: 'CA',
    latitude: '33.983732',
    longitude: '-118.391412',
    timeZoneId: 'America/Los_Angeles',
  },
  '90239': {
    zip: '90239',
    city: 'Downey',
    state: 'CA',
    latitude: '33.932418',
    longitude: '-118.112837',
    timeZoneId: 'America/Los_Angeles',
  },
  '90240': {
    zip: '90240',
    city: 'Downey',
    state: 'CA',
    latitude: '33.95461',
    longitude: '-118.116688',
    timeZoneId: 'America/Los_Angeles',
  },
  '90241': {
    zip: '90241',
    city: 'Downey',
    state: 'CA',
    latitude: '33.940169',
    longitude: '-118.129391',
    timeZoneId: 'America/Los_Angeles',
  },
  '90242': {
    zip: '90242',
    city: 'Downey',
    state: 'CA',
    latitude: '33.924064',
    longitude: '-118.145732',
    timeZoneId: 'America/Los_Angeles',
  },
  '90245': {
    zip: '90245',
    city: 'El Segundo',
    state: 'CA',
    latitude: '33.923798',
    longitude: '-118.405527',
    timeZoneId: 'America/Los_Angeles',
  },
  '90247': {
    zip: '90247',
    city: 'Gardena',
    state: 'CA',
    latitude: '33.888193',
    longitude: '-118.295204',
    timeZoneId: 'America/Los_Angeles',
  },
  '90248': {
    zip: '90248',
    city: 'Gardena',
    state: 'CA',
    latitude: '33.879226',
    longitude: '-118.283613',
    timeZoneId: 'America/Los_Angeles',
  },
  '90249': {
    zip: '90249',
    city: 'Gardena',
    state: 'CA',
    latitude: '33.897921',
    longitude: '-118.318018',
    timeZoneId: 'America/Los_Angeles',
  },
  '90250': {
    zip: '90250',
    city: 'Hawthorne',
    state: 'CA',
    latitude: '33.9153',
    longitude: '-118.351309',
    timeZoneId: 'America/Los_Angeles',
  },
  '90251': {
    zip: '90251',
    city: 'Hawthorne',
    state: 'CA',
    latitude: '33.916941',
    longitude: '-118.359436',
    timeZoneId: 'America/Los_Angeles',
  },
  '90254': {
    zip: '90254',
    city: 'Hermosa Beach',
    state: 'CA',
    latitude: '33.867406',
    longitude: '-118.397481',
    timeZoneId: 'America/Los_Angeles',
  },
  '90255': {
    zip: '90255',
    city: 'Huntington Park',
    state: 'CA',
    latitude: '33.980311',
    longitude: '-118.209874',
    timeZoneId: 'America/Los_Angeles',
  },
  '90260': {
    zip: '90260',
    city: 'Lawndale',
    state: 'CA',
    latitude: '33.888172',
    longitude: '-118.348328',
    timeZoneId: 'America/Los_Angeles',
  },
  '90261': {
    zip: '90261',
    city: 'Lawndale',
    state: 'CA',
    latitude: '33.88958',
    longitude: '-118.352749',
    timeZoneId: 'America/Los_Angeles',
  },
  '90262': {
    zip: '90262',
    city: 'Lynwood',
    state: 'CA',
    latitude: '33.925084',
    longitude: '-118.198182',
    timeZoneId: 'America/Los_Angeles',
  },
  '90263': {
    zip: '90263',
    city: 'Malibu',
    state: 'CA',
    latitude: '34.006202',
    longitude: '-118.810015',
    timeZoneId: 'America/Los_Angeles',
  },
  '90264': {
    zip: '90264',
    city: 'Malibu',
    state: 'CA',
    latitude: '34.043513',
    longitude: '-118.771042',
    timeZoneId: 'America/Los_Angeles',
  },
  '90265': {
    zip: '90265',
    city: 'Malibu',
    state: 'CA',
    latitude: '34.044755',
    longitude: '-118.75538',
    timeZoneId: 'America/Los_Angeles',
  },
  '90266': {
    zip: '90266',
    city: 'Manhattan Beach',
    state: 'CA',
    latitude: '33.889584',
    longitude: '-118.39902',
    timeZoneId: 'America/Los_Angeles',
  },
  '90267': {
    zip: '90267',
    city: 'Manhattan Beach',
    state: 'CA',
    latitude: '33.874322',
    longitude: '-118.39546',
    timeZoneId: 'America/Los_Angeles',
  },
  '90270': {
    zip: '90270',
    city: 'Maywood',
    state: 'CA',
    latitude: '33.9775',
    longitude: '-118.186111',
    timeZoneId: 'America/Los_Angeles',
  },
  '90272': {
    zip: '90272',
    city: 'Pacific Palisades',
    state: 'CA',
    latitude: '34.053275',
    longitude: '-118.534568',
    timeZoneId: 'America/Los_Angeles',
  },
  '90274': {
    zip: '90274',
    city: 'Palos Verdes Peninsula',
    state: 'CA',
    latitude: '33.772826',
    longitude: '-118.370775',
    timeZoneId: 'America/Los_Angeles',
  },
  '90275': {
    zip: '90275',
    city: 'Rancho Palos Verdes',
    state: 'CA',
    latitude: '33.766667',
    longitude: '-118.366667',
    timeZoneId: 'America/Los_Angeles',
  },
  '90277': {
    zip: '90277',
    city: 'Redondo Beach',
    state: 'CA',
    latitude: '33.832658',
    longitude: '-118.38015',
    timeZoneId: 'America/Los_Angeles',
  },
  '90278': {
    zip: '90278',
    city: 'Redondo Beach',
    state: 'CA',
    latitude: '33.871269',
    longitude: '-118.371584',
    timeZoneId: 'America/Los_Angeles',
  },
  '90280': {
    zip: '90280',
    city: 'South Gate',
    state: 'CA',
    latitude: '33.941262',
    longitude: '-118.193702',
    timeZoneId: 'America/Los_Angeles',
  },
  '90290': {
    zip: '90290',
    city: 'Topanga',
    state: 'CA',
    latitude: '34.104396',
    longitude: '-118.604094',
    timeZoneId: 'America/Los_Angeles',
  },
  '90291': {
    zip: '90291',
    city: 'Venice',
    state: 'CA',
    latitude: '33.993348',
    longitude: '-118.465075',
    timeZoneId: 'America/Los_Angeles',
  },
  '90292': {
    zip: '90292',
    city: 'Marina del Rey',
    state: 'CA',
    latitude: '33.98551',
    longitude: '-118.45318',
    timeZoneId: 'America/Los_Angeles',
  },
  '90293': {
    zip: '90293',
    city: 'Playa del Rey',
    state: 'CA',
    latitude: '33.990833',
    longitude: '-118.459167',
    timeZoneId: 'America/Los_Angeles',
  },
  '90294': {
    zip: '90294',
    city: 'Venice',
    state: 'CA',
    latitude: '33.986206',
    longitude: '-118.468275',
    timeZoneId: 'America/Los_Angeles',
  },
  '90295': {
    zip: '90295',
    city: 'Marina del Rey',
    state: 'CA',
    latitude: '33.980879',
    longitude: '-118.438136',
    timeZoneId: 'America/Los_Angeles',
  },
  '90296': {
    zip: '90296',
    city: 'Playa del Rey',
    state: 'CA',
    latitude: '33.956096',
    longitude: '-118.449853',
    timeZoneId: 'America/Los_Angeles',
  },
  '90301': {
    zip: '90301',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.958998',
    longitude: '-118.350254',
    timeZoneId: 'America/Los_Angeles',
  },
  '90302': {
    zip: '90302',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.975295',
    longitude: '-118.354145',
    timeZoneId: 'America/Los_Angeles',
  },
  '90303': {
    zip: '90303',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.934314',
    longitude: '-118.325571',
    timeZoneId: 'America/Los_Angeles',
  },
  '90304': {
    zip: '90304',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.935693',
    longitude: '-118.355284',
    timeZoneId: 'America/Los_Angeles',
  },
  '90305': {
    zip: '90305',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.960447',
    longitude: '-118.331386',
    timeZoneId: 'America/Los_Angeles',
  },
  '90306': {
    zip: '90306',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.960821',
    longitude: '-118.35013',
    timeZoneId: 'America/Los_Angeles',
  },
  '90307': {
    zip: '90307',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.954677',
    longitude: '-118.373609',
    timeZoneId: 'America/Los_Angeles',
  },
  '90308': {
    zip: '90308',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.960454',
    longitude: '-118.351465',
    timeZoneId: 'America/Los_Angeles',
  },
  '90309': {
    zip: '90309',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.973424',
    longitude: '-118.357069',
    timeZoneId: 'America/Los_Angeles',
  },
  '90310': {
    zip: '90310',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.927761',
    longitude: '-118.319917',
    timeZoneId: 'America/Los_Angeles',
  },
  '90311': {
    zip: '90311',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.95731',
    longitude: '-118.358381',
    timeZoneId: 'America/Los_Angeles',
  },
  '90312': {
    zip: '90312',
    city: 'Inglewood',
    state: 'CA',
    latitude: '33.960629',
    longitude: '-118.350216',
    timeZoneId: 'America/Los_Angeles',
  },
  '90401': {
    zip: '90401',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.01663',
    longitude: '-118.495341',
    timeZoneId: 'America/Los_Angeles',
  },
  '90402': {
    zip: '90402',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.035904',
    longitude: '-118.502559',
    timeZoneId: 'America/Los_Angeles',
  },
  '90403': {
    zip: '90403',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.029923',
    longitude: '-118.490616',
    timeZoneId: 'America/Los_Angeles',
  },
  '90404': {
    zip: '90404',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.028496',
    longitude: '-118.470151',
    timeZoneId: 'America/Los_Angeles',
  },
  '90405': {
    zip: '90405',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.007914',
    longitude: '-118.470161',
    timeZoneId: 'America/Los_Angeles',
  },
  '90406': {
    zip: '90406',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.018176',
    longitude: '-118.497198',
    timeZoneId: 'America/Los_Angeles',
  },
  '90407': {
    zip: '90407',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.013225',
    longitude: '-118.49306',
    timeZoneId: 'America/Los_Angeles',
  },
  '90408': {
    zip: '90408',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.026164',
    longitude: '-118.491968',
    timeZoneId: 'America/Los_Angeles',
  },
  '90409': {
    zip: '90409',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.003022',
    longitude: '-118.485621',
    timeZoneId: 'America/Los_Angeles',
  },
  '90410': {
    zip: '90410',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.014649',
    longitude: '-118.4674',
    timeZoneId: 'America/Los_Angeles',
  },
  '90411': {
    zip: '90411',
    city: 'Santa Monica',
    state: 'CA',
    latitude: '34.012451',
    longitude: '-118.471282',
    timeZoneId: 'America/Los_Angeles',
  },
  '90501': {
    zip: '90501',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.829914',
    longitude: '-118.314527',
    timeZoneId: 'America/Los_Angeles',
  },
  '90502': {
    zip: '90502',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.837971',
    longitude: '-118.293025',
    timeZoneId: 'America/Los_Angeles',
  },
  '90503': {
    zip: '90503',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.842791',
    longitude: '-118.357275',
    timeZoneId: 'America/Los_Angeles',
  },
  '90504': {
    zip: '90504',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.871409',
    longitude: '-118.325688',
    timeZoneId: 'America/Los_Angeles',
  },
  '90505': {
    zip: '90505',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.809221',
    longitude: '-118.349149',
    timeZoneId: 'America/Los_Angeles',
  },
  '90506': {
    zip: '90506',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.885708',
    longitude: '-118.329022',
    timeZoneId: 'America/Los_Angeles',
  },
  '90507': {
    zip: '90507',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.831799',
    longitude: '-118.318215',
    timeZoneId: 'America/Los_Angeles',
  },
  '90508': {
    zip: '90508',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.834595',
    longitude: '-118.315387',
    timeZoneId: 'America/Los_Angeles',
  },
  '90509': {
    zip: '90509',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.844351',
    longitude: '-118.328784',
    timeZoneId: 'America/Los_Angeles',
  },
  '90510': {
    zip: '90510',
    city: 'Torrance',
    state: 'CA',
    latitude: '33.825599',
    longitude: '-118.328416',
    timeZoneId: 'America/Los_Angeles',
  },
  '90601': {
    zip: '90601',
    city: 'Whittier',
    state: 'CA',
    latitude: '34.005998',
    longitude: '-118.032175',
    timeZoneId: 'America/Los_Angeles',
  },
  '90602': {
    zip: '90602',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.973637',
    longitude: '-118.031821',
    timeZoneId: 'America/Los_Angeles',
  },
  '90603': {
    zip: '90603',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.944907',
    longitude: '-117.989049',
    timeZoneId: 'America/Los_Angeles',
  },
  '90604': {
    zip: '90604',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.934784',
    longitude: '-118.022664',
    timeZoneId: 'America/Los_Angeles',
  },
  '90605': {
    zip: '90605',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.955629',
    longitude: '-118.026647',
    timeZoneId: 'America/Los_Angeles',
  },
  '90606': {
    zip: '90606',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.980225',
    longitude: '-118.066303',
    timeZoneId: 'America/Los_Angeles',
  },
  '90607': {
    zip: '90607',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.959614',
    longitude: '-118.026554',
    timeZoneId: 'America/Los_Angeles',
  },
  '90608': {
    zip: '90608',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.979318',
    longitude: '-118.03053',
    timeZoneId: 'America/Los_Angeles',
  },
  '90609': {
    zip: '90609',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.981609',
    longitude: '-118.030546',
    timeZoneId: 'America/Los_Angeles',
  },
  '90610': {
    zip: '90610',
    city: 'Whittier',
    state: 'CA',
    latitude: '33.969836',
    longitude: '-118.071934',
    timeZoneId: 'America/Los_Angeles',
  },
  '90620': {
    zip: '90620',
    city: 'Buena Park',
    state: 'CA',
    latitude: '33.843903',
    longitude: '-118.007993',
    timeZoneId: 'America/Los_Angeles',
  },
  '90621': {
    zip: '90621',
    city: 'Buena Park',
    state: 'CA',
    latitude: '33.877168',
    longitude: '-117.989269',
    timeZoneId: 'America/Los_Angeles',
  },
  '90622': {
    zip: '90622',
    city: 'Buena Park',
    state: 'CA',
    latitude: '33.846186',
    longitude: '-118.003132',
    timeZoneId: 'America/Los_Angeles',
  },
  '90623': {
    zip: '90623',
    city: 'La Palma',
    state: 'CA',
    latitude: '33.849063',
    longitude: '-118.039814',
    timeZoneId: 'America/Los_Angeles',
  },
  '90624': {
    zip: '90624',
    city: 'Buena Park',
    state: 'CA',
    latitude: '33.858383',
    longitude: '-118.003267',
    timeZoneId: 'America/Los_Angeles',
  },
  '90630': {
    zip: '90630',
    city: 'Cypress',
    state: 'CA',
    latitude: '33.818073',
    longitude: '-118.035677',
    timeZoneId: 'America/Los_Angeles',
  },
  '90631': {
    zip: '90631',
    city: 'La Habra',
    state: 'CA',
    latitude: '33.94104',
    longitude: '-117.95442',
    timeZoneId: 'America/Los_Angeles',
  },
  '90632': {
    zip: '90632',
    city: 'La Habra',
    state: 'CA',
    latitude: '33.914328',
    longitude: '-117.954717',
    timeZoneId: 'America/Los_Angeles',
  },
  '90633': {
    zip: '90633',
    city: 'La Habra',
    state: 'CA',
    latitude: '33.934534',
    longitude: '-117.945247',
    timeZoneId: 'America/Los_Angeles',
  },
  '90637': {
    zip: '90637',
    city: 'La Mirada',
    state: 'CA',
    latitude: '33.897095',
    longitude: '-118.002372',
    timeZoneId: 'America/Los_Angeles',
  },
  '90638': {
    zip: '90638',
    city: 'La Mirada',
    state: 'CA',
    latitude: '33.906013',
    longitude: '-118.005828',
    timeZoneId: 'America/Los_Angeles',
  },
  '90639': {
    zip: '90639',
    city: 'La Mirada',
    state: 'CA',
    latitude: '33.907592',
    longitude: '-118.020573',
    timeZoneId: 'America/Los_Angeles',
  },
  '90640': {
    zip: '90640',
    city: 'Montebello',
    state: 'CA',
    latitude: '34.015369',
    longitude: '-118.111726',
    timeZoneId: 'America/Los_Angeles',
  },
  '90650': {
    zip: '90650',
    city: 'Norwalk',
    state: 'CA',
    latitude: '33.908151',
    longitude: '-118.080822',
    timeZoneId: 'America/Los_Angeles',
  },
  '90651': {
    zip: '90651',
    city: 'Norwalk',
    state: 'CA',
    latitude: '33.906859',
    longitude: '-118.076073',
    timeZoneId: 'America/Los_Angeles',
  },
  '90652': {
    zip: '90652',
    city: 'Norwalk',
    state: 'CA',
    latitude: '33.920448',
    longitude: '-118.072097',
    timeZoneId: 'America/Los_Angeles',
  },
  '90660': {
    zip: '90660',
    city: 'Pico Rivera',
    state: 'CA',
    latitude: '33.988063',
    longitude: '-118.088143',
    timeZoneId: 'America/Los_Angeles',
  },
  '90661': {
    zip: '90661',
    city: 'Pico Rivera',
    state: 'CA',
    latitude: '33.998236',
    longitude: '-118.080655',
    timeZoneId: 'America/Los_Angeles',
  },
  '90662': {
    zip: '90662',
    city: 'Pico Rivera',
    state: 'CA',
    latitude: '33.969412',
    longitude: '-118.098484',
    timeZoneId: 'America/Los_Angeles',
  },
  '90670': {
    zip: '90670',
    city: 'Santa Fe Springs',
    state: 'CA',
    latitude: '33.935015',
    longitude: '-118.068892',
    timeZoneId: 'America/Los_Angeles',
  },
  '90671': {
    zip: '90671',
    city: 'Santa Fe Springs',
    state: 'CA',
    latitude: '33.932507',
    longitude: '-118.058491',
    timeZoneId: 'America/Los_Angeles',
  },
  '90680': {
    zip: '90680',
    city: 'Stanton',
    state: 'CA',
    latitude: '33.799083',
    longitude: '-117.995649',
    timeZoneId: 'America/Los_Angeles',
  },
  '90701': {
    zip: '90701',
    city: 'Artesia',
    state: 'CA',
    latitude: '33.869028',
    longitude: '-118.081967',
    timeZoneId: 'America/Los_Angeles',
  },
  '90702': {
    zip: '90702',
    city: 'Artesia',
    state: 'CA',
    latitude: '33.863402',
    longitude: '-118.082521',
    timeZoneId: 'America/Los_Angeles',
  },
  '90703': {
    zip: '90703',
    city: 'Cerritos',
    state: 'CA',
    latitude: '33.866635',
    longitude: '-118.048458',
    timeZoneId: 'America/Los_Angeles',
  },
  '90704': {
    zip: '90704',
    city: 'Avalon',
    state: 'CA',
    latitude: '33.342834',
    longitude: '-118.327634',
    timeZoneId: 'America/Los_Angeles',
  },
  '90706': {
    zip: '90706',
    city: 'Bellflower',
    state: 'CA',
    latitude: '33.888756',
    longitude: '-118.128543',
    timeZoneId: 'America/Los_Angeles',
  },
  '90707': {
    zip: '90707',
    city: 'Bellflower',
    state: 'CA',
    latitude: '33.88235',
    longitude: '-118.122206',
    timeZoneId: 'America/Los_Angeles',
  },
  '90710': {
    zip: '90710',
    city: 'Harbor City',
    state: 'CA',
    latitude: '33.800611',
    longitude: '-118.299094',
    timeZoneId: 'America/Los_Angeles',
  },
  '90711': {
    zip: '90711',
    city: 'Lakewood',
    state: 'CA',
    latitude: '33.848941',
    longitude: '-118.146773',
    timeZoneId: 'America/Los_Angeles',
  },
  '90712': {
    zip: '90712',
    city: 'Lakewood',
    state: 'CA',
    latitude: '33.848928',
    longitude: '-118.147102',
    timeZoneId: 'America/Los_Angeles',
  },
  '90713': {
    zip: '90713',
    city: 'Lakewood',
    state: 'CA',
    latitude: '33.851668',
    longitude: '-118.107286',
    timeZoneId: 'America/Los_Angeles',
  },
  '90714': {
    zip: '90714',
    city: 'Lakewood',
    state: 'CA',
    latitude: '33.851492',
    longitude: '-118.136024',
    timeZoneId: 'America/Los_Angeles',
  },
  '90715': {
    zip: '90715',
    city: 'Lakewood',
    state: 'CA',
    latitude: '33.843382',
    longitude: '-118.076802',
    timeZoneId: 'America/Los_Angeles',
  },
  '90716': {
    zip: '90716',
    city: 'Hawaiian Gardens',
    state: 'CA',
    latitude: '33.853611',
    longitude: '-118.133056',
    timeZoneId: 'America/Los_Angeles',
  },
  '90717': {
    zip: '90717',
    city: 'Lomita',
    state: 'CA',
    latitude: '33.794899',
    longitude: '-118.31645',
    timeZoneId: 'America/Los_Angeles',
  },
  '90720': {
    zip: '90720',
    city: 'Los Alamitos',
    state: 'CA',
    latitude: '33.795614',
    longitude: '-118.064756',
    timeZoneId: 'America/Los_Angeles',
  },
  '90721': {
    zip: '90721',
    city: 'Los Alamitos',
    state: 'CA',
    latitude: '33.802254',
    longitude: '-118.068984',
    timeZoneId: 'America/Los_Angeles',
  },
  '90723': {
    zip: '90723',
    city: 'Paramount',
    state: 'CA',
    latitude: '33.897236',
    longitude: '-118.160018',
    timeZoneId: 'America/Los_Angeles',
  },
  '90731': {
    zip: '90731',
    city: 'San Pedro',
    state: 'CA',
    latitude: '33.74352',
    longitude: '-118.27944',
    timeZoneId: 'America/Los_Angeles',
  },
  '90732': {
    zip: '90732',
    city: 'San Pedro',
    state: 'CA',
    latitude: '33.764107',
    longitude: '-118.303038',
    timeZoneId: 'America/Los_Angeles',
  },
  '90733': {
    zip: '90733',
    city: 'San Pedro',
    state: 'CA',
    latitude: '33.706639',
    longitude: '-118.290674',
    timeZoneId: 'America/Los_Angeles',
  },
  '90734': {
    zip: '90734',
    city: 'San Pedro',
    state: 'CA',
    latitude: '33.756107',
    longitude: '-118.30637',
    timeZoneId: 'America/Los_Angeles',
  },
  '90740': {
    zip: '90740',
    city: 'Seal Beach',
    state: 'CA',
    latitude: '33.760225',
    longitude: '-118.080806',
    timeZoneId: 'America/Los_Angeles',
  },
  '90742': {
    zip: '90742',
    city: 'Sunset Beach',
    state: 'CA',
    latitude: '33.718777',
    longitude: '-118.069914',
    timeZoneId: 'America/Los_Angeles',
  },
  '90743': {
    zip: '90743',
    city: 'Surfside',
    state: 'CA',
    latitude: '33.726441',
    longitude: '-118.083289',
    timeZoneId: 'America/Los_Angeles',
  },
  '90744': {
    zip: '90744',
    city: 'Wilmington',
    state: 'CA',
    latitude: '33.791522',
    longitude: '-118.252215',
    timeZoneId: 'America/Los_Angeles',
  },
  '90745': {
    zip: '90745',
    city: 'Carson',
    state: 'CA',
    latitude: '33.820353',
    longitude: '-118.266179',
    timeZoneId: 'America/Los_Angeles',
  },
  '90746': {
    zip: '90746',
    city: 'Carson',
    state: 'CA',
    latitude: '33.860506',
    longitude: '-118.255622',
    timeZoneId: 'America/Los_Angeles',
  },
  '90747': {
    zip: '90747',
    city: 'Carson',
    state: 'CA',
    latitude: '33.866528',
    longitude: '-118.253036',
    timeZoneId: 'America/Los_Angeles',
  },
  '90748': {
    zip: '90748',
    city: 'Wilmington',
    state: 'CA',
    latitude: '33.784149',
    longitude: '-118.263982',
    timeZoneId: 'America/Los_Angeles',
  },
  '90749': {
    zip: '90749',
    city: 'Carson',
    state: 'CA',
    latitude: '33.819146',
    longitude: '-118.263108',
    timeZoneId: 'America/Los_Angeles',
  },
  '90755': {
    zip: '90755',
    city: 'Signal Hill',
    state: 'CA',
    latitude: '33.779826',
    longitude: '-118.150529',
    timeZoneId: 'America/Los_Angeles',
  },
  '90801': {
    zip: '90801',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.773782',
    longitude: '-118.186314',
    timeZoneId: 'America/Los_Angeles',
  },
  '90802': {
    zip: '90802',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.770005',
    longitude: '-118.190518',
    timeZoneId: 'America/Los_Angeles',
  },
  '90803': {
    zip: '90803',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.759309',
    longitude: '-118.125598',
    timeZoneId: 'America/Los_Angeles',
  },
  '90804': {
    zip: '90804',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.776725',
    longitude: '-118.14615',
    timeZoneId: 'America/Los_Angeles',
  },
  '90805': {
    zip: '90805',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.863555',
    longitude: '-118.182163',
    timeZoneId: 'America/Los_Angeles',
  },
  '90806': {
    zip: '90806',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.805737',
    longitude: '-118.181738',
    timeZoneId: 'America/Los_Angeles',
  },
  '90807': {
    zip: '90807',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.827641',
    longitude: '-118.179228',
    timeZoneId: 'America/Los_Angeles',
  },
  '90808': {
    zip: '90808',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.819908',
    longitude: '-118.105424',
    timeZoneId: 'America/Los_Angeles',
  },
  '90809': {
    zip: '90809',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.762376',
    longitude: '-118.121919',
    timeZoneId: 'America/Los_Angeles',
  },
  '90810': {
    zip: '90810',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.81798',
    longitude: '-118.22328',
    timeZoneId: 'America/Los_Angeles',
  },
  '90813': {
    zip: '90813',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.778685',
    longitude: '-118.191698',
    timeZoneId: 'America/Los_Angeles',
  },
  '90814': {
    zip: '90814',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.770764',
    longitude: '-118.150873',
    timeZoneId: 'America/Los_Angeles',
  },
  '90815': {
    zip: '90815',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.794234',
    longitude: '-118.115561',
    timeZoneId: 'America/Los_Angeles',
  },
  '90822': {
    zip: '90822',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.831262',
    longitude: '-118.238192',
    timeZoneId: 'America/Los_Angeles',
  },
  '90831': {
    zip: '90831',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.77041',
    longitude: '-118.200783',
    timeZoneId: 'America/Los_Angeles',
  },
  '90832': {
    zip: '90832',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.765894',
    longitude: '-118.198595',
    timeZoneId: 'America/Los_Angeles',
  },
  '90833': {
    zip: '90833',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.766363',
    longitude: '-118.203087',
    timeZoneId: 'America/Los_Angeles',
  },
  '90834': {
    zip: '90834',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.770162',
    longitude: '-118.203615',
    timeZoneId: 'America/Los_Angeles',
  },
  '90835': {
    zip: '90835',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.769489',
    longitude: '-118.200353',
    timeZoneId: 'America/Los_Angeles',
  },
  '90840': {
    zip: '90840',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.781444',
    longitude: '-118.118257',
    timeZoneId: 'America/Los_Angeles',
  },
  '90842': {
    zip: '90842',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.819348',
    longitude: '-118.168139',
    timeZoneId: 'America/Los_Angeles',
  },
  '90844': {
    zip: '90844',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.776755',
    longitude: '-118.191618',
    timeZoneId: 'America/Los_Angeles',
  },
  '90846': {
    zip: '90846',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.825962',
    longitude: '-118.120925',
    timeZoneId: 'America/Los_Angeles',
  },
  '90847': {
    zip: '90847',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.834248',
    longitude: '-118.189003',
    timeZoneId: 'America/Los_Angeles',
  },
  '90848': {
    zip: '90848',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.830348',
    longitude: '-118.184925',
    timeZoneId: 'America/Los_Angeles',
  },
  '90853': {
    zip: '90853',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.7604',
    longitude: '-118.132702',
    timeZoneId: 'America/Los_Angeles',
  },
  '90895': {
    zip: '90895',
    city: 'Carson',
    state: 'CA',
    latitude: '33.83968',
    longitude: '-118.25959',
    timeZoneId: 'America/Los_Angeles',
  },
  '90899': {
    zip: '90899',
    city: 'Long Beach',
    state: 'CA',
    latitude: '33.767496',
    longitude: '-118.187712',
    timeZoneId: 'America/Los_Angeles',
  },
  '91001': {
    zip: '91001',
    city: 'Altadena',
    state: 'CA',
    latitude: '34.188413',
    longitude: '-118.140109',
    timeZoneId: 'America/Los_Angeles',
  },
  '91003': {
    zip: '91003',
    city: 'Altadena',
    state: 'CA',
    latitude: '34.185055',
    longitude: '-118.130218',
    timeZoneId: 'America/Los_Angeles',
  },
  '91006': {
    zip: '91006',
    city: 'Arcadia',
    state: 'CA',
    latitude: '34.13765',
    longitude: '-118.023582',
    timeZoneId: 'America/Los_Angeles',
  },
  '91007': {
    zip: '91007',
    city: 'Arcadia',
    state: 'CA',
    latitude: '34.129883',
    longitude: '-118.051077',
    timeZoneId: 'America/Los_Angeles',
  },
  '91008': {
    zip: '91008',
    city: 'Duarte',
    state: 'CA',
    latitude: '34.152274',
    longitude: '-117.96817',
    timeZoneId: 'America/Los_Angeles',
  },
  '91009': {
    zip: '91009',
    city: 'Duarte',
    state: 'CA',
    latitude: '34.139758',
    longitude: '-117.967298',
    timeZoneId: 'America/Los_Angeles',
  },
  '91010': {
    zip: '91010',
    city: 'Duarte',
    state: 'CA',
    latitude: '34.138594',
    longitude: '-117.963204',
    timeZoneId: 'America/Los_Angeles',
  },
  '91011': {
    zip: '91011',
    city: 'La Canada Flintridge',
    state: 'CA',
    latitude: '34.206651',
    longitude: '-118.201628',
    timeZoneId: 'America/Los_Angeles',
  },
  '91012': {
    zip: '91012',
    city: 'La Canada Flintridge',
    state: 'CA',
    latitude: '34.203774',
    longitude: '-118.191187',
    timeZoneId: 'America/Los_Angeles',
  },
  '91016': {
    zip: '91016',
    city: 'Monrovia',
    state: 'CA',
    latitude: '34.143769',
    longitude: '-117.999476',
    timeZoneId: 'America/Los_Angeles',
  },
  '91017': {
    zip: '91017',
    city: 'Monrovia',
    state: 'CA',
    latitude: '34.131442',
    longitude: '-117.987304',
    timeZoneId: 'America/Los_Angeles',
  },
  '91020': {
    zip: '91020',
    city: 'Montrose',
    state: 'CA',
    latitude: '34.209369',
    longitude: '-118.229787',
    timeZoneId: 'America/Los_Angeles',
  },
  '91021': {
    zip: '91021',
    city: 'Montrose',
    state: 'CA',
    latitude: '34.206931',
    longitude: '-118.225323',
    timeZoneId: 'America/Los_Angeles',
  },
  '91023': {
    zip: '91023',
    city: 'Mount Wilson',
    state: 'CA',
    latitude: '34.226166',
    longitude: '-118.092289',
    timeZoneId: 'America/Los_Angeles',
  },
  '91024': {
    zip: '91024',
    city: 'Sierra Madre',
    state: 'CA',
    latitude: '34.164984',
    longitude: '-118.051648',
    timeZoneId: 'America/Los_Angeles',
  },
  '91025': {
    zip: '91025',
    city: 'Sierra Madre',
    state: 'CA',
    latitude: '34.157498',
    longitude: '-118.051198',
    timeZoneId: 'America/Los_Angeles',
  },
  '91030': {
    zip: '91030',
    city: 'South Pasadena',
    state: 'CA',
    latitude: '34.11187',
    longitude: '-118.155784',
    timeZoneId: 'America/Los_Angeles',
  },
  '91031': {
    zip: '91031',
    city: 'South Pasadena',
    state: 'CA',
    latitude: '34.117536',
    longitude: '-118.151757',
    timeZoneId: 'America/Los_Angeles',
  },
  '91040': {
    zip: '91040',
    city: 'Sunland',
    state: 'CA',
    latitude: '34.26003',
    longitude: '-118.332661',
    timeZoneId: 'America/Los_Angeles',
  },
  '91041': {
    zip: '91041',
    city: 'Sunland',
    state: 'CA',
    latitude: '34.262512',
    longitude: '-118.318113',
    timeZoneId: 'America/Los_Angeles',
  },
  '91042': {
    zip: '91042',
    city: 'Tujunga',
    state: 'CA',
    latitude: '34.252366',
    longitude: '-118.283924',
    timeZoneId: 'America/Los_Angeles',
  },
  '91043': {
    zip: '91043',
    city: 'Tujunga',
    state: 'CA',
    latitude: '34.252009',
    longitude: '-118.292791',
    timeZoneId: 'America/Los_Angeles',
  },
  '91046': {
    zip: '91046',
    city: 'Verdugo City',
    state: 'CA',
    latitude: '34.209793',
    longitude: '-118.241838',
    timeZoneId: 'America/Los_Angeles',
  },
  '91066': {
    zip: '91066',
    city: 'Arcadia',
    state: 'CA',
    latitude: '34.142937',
    longitude: '-118.031242',
    timeZoneId: 'America/Los_Angeles',
  },
  '91077': {
    zip: '91077',
    city: 'Arcadia',
    state: 'CA',
    latitude: '34.12539',
    longitude: '-118.05468',
    timeZoneId: 'America/Los_Angeles',
  },
  '91101': {
    zip: '91101',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.147489',
    longitude: '-118.139596',
    timeZoneId: 'America/Los_Angeles',
  },
  '91102': {
    zip: '91102',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.148314',
    longitude: '-118.143097',
    timeZoneId: 'America/Los_Angeles',
  },
  '91103': {
    zip: '91103',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.162838',
    longitude: '-118.165209',
    timeZoneId: 'America/Los_Angeles',
  },
  '91104': {
    zip: '91104',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.166482',
    longitude: '-118.120445',
    timeZoneId: 'America/Los_Angeles',
  },
  '91105': {
    zip: '91105',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.137436',
    longitude: '-118.167057',
    timeZoneId: 'America/Los_Angeles',
  },
  '91106': {
    zip: '91106',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.142301',
    longitude: '-118.125711',
    timeZoneId: 'America/Los_Angeles',
  },
  '91107': {
    zip: '91107',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.152009',
    longitude: '-118.088972',
    timeZoneId: 'America/Los_Angeles',
  },
  '91108': {
    zip: '91108',
    city: 'San Marino',
    state: 'CA',
    latitude: '34.122334',
    longitude: '-118.114252',
    timeZoneId: 'America/Los_Angeles',
  },
  '91109': {
    zip: '91109',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.167069',
    longitude: '-118.164718',
    timeZoneId: 'America/Los_Angeles',
  },
  '91110': {
    zip: '91110',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.155939',
    longitude: '-118.154292',
    timeZoneId: 'America/Los_Angeles',
  },
  '91114': {
    zip: '91114',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.168363',
    longitude: '-118.12644',
    timeZoneId: 'America/Los_Angeles',
  },
  '91115': {
    zip: '91115',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.135882',
    longitude: '-118.151588',
    timeZoneId: 'America/Los_Angeles',
  },
  '91116': {
    zip: '91116',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.143178',
    longitude: '-118.128197',
    timeZoneId: 'America/Los_Angeles',
  },
  '91117': {
    zip: '91117',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.14702',
    longitude: '-118.092722',
    timeZoneId: 'America/Los_Angeles',
  },
  '91118': {
    zip: '91118',
    city: 'San Marino',
    state: 'CA',
    latitude: '34.125011',
    longitude: '-118.092416',
    timeZoneId: 'America/Los_Angeles',
  },
  '91121': {
    zip: '91121',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.149921',
    longitude: '-118.088599',
    timeZoneId: 'America/Los_Angeles',
  },
  '91123': {
    zip: '91123',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.138331',
    longitude: '-118.152775',
    timeZoneId: 'America/Los_Angeles',
  },
  '91124': {
    zip: '91124',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.150182',
    longitude: '-118.152862',
    timeZoneId: 'America/Los_Angeles',
  },
  '91125': {
    zip: '91125',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.138929',
    longitude: '-118.123728',
    timeZoneId: 'America/Los_Angeles',
  },
  '91126': {
    zip: '91126',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.136757',
    longitude: '-118.125896',
    timeZoneId: 'America/Los_Angeles',
  },
  '91129': {
    zip: '91129',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.141146',
    longitude: '-118.156851',
    timeZoneId: 'America/Los_Angeles',
  },
  '91182': {
    zip: '91182',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.148771',
    longitude: '-118.140407',
    timeZoneId: 'America/Los_Angeles',
  },
  '91184': {
    zip: '91184',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.141599',
    longitude: '-118.157559',
    timeZoneId: 'America/Los_Angeles',
  },
  '91185': {
    zip: '91185',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.169701',
    longitude: '-117.981386',
    timeZoneId: 'America/Los_Angeles',
  },
  '91188': {
    zip: '91188',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.151026',
    longitude: '-118.143944',
    timeZoneId: 'America/Los_Angeles',
  },
  '91189': {
    zip: '91189',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.12407',
    longitude: '-118.164542',
    timeZoneId: 'America/Los_Angeles',
  },
  '91199': {
    zip: '91199',
    city: 'Pasadena',
    state: 'CA',
    latitude: '34.109919',
    longitude: '-118.125578',
    timeZoneId: 'America/Los_Angeles',
  },
  '91201': {
    zip: '91201',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.17191',
    longitude: '-118.292436',
    timeZoneId: 'America/Los_Angeles',
  },
  '91202': {
    zip: '91202',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.166714',
    longitude: '-118.268347',
    timeZoneId: 'America/Los_Angeles',
  },
  '91203': {
    zip: '91203',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.151572',
    longitude: '-118.267799',
    timeZoneId: 'America/Los_Angeles',
  },
  '91204': {
    zip: '91204',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.13345',
    longitude: '-118.262119',
    timeZoneId: 'America/Los_Angeles',
  },
  '91205': {
    zip: '91205',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.135443',
    longitude: '-118.24258',
    timeZoneId: 'America/Los_Angeles',
  },
  '91206': {
    zip: '91206',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.160263',
    longitude: '-118.219189',
    timeZoneId: 'America/Los_Angeles',
  },
  '91207': {
    zip: '91207',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.169742',
    longitude: '-118.240027',
    timeZoneId: 'America/Los_Angeles',
  },
  '91208': {
    zip: '91208',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.191213',
    longitude: '-118.231522',
    timeZoneId: 'America/Los_Angeles',
  },
  '91209': {
    zip: '91209',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.14464',
    longitude: '-118.250262',
    timeZoneId: 'America/Los_Angeles',
  },
  '91210': {
    zip: '91210',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.14191',
    longitude: '-118.260565',
    timeZoneId: 'America/Los_Angeles',
  },
  '91214': {
    zip: '91214',
    city: 'La Crescenta',
    state: 'CA',
    latitude: '34.232506',
    longitude: '-118.245761',
    timeZoneId: 'America/Los_Angeles',
  },
  '91221': {
    zip: '91221',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.165334',
    longitude: '-118.289424',
    timeZoneId: 'America/Los_Angeles',
  },
  '91222': {
    zip: '91222',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.15922',
    longitude: '-118.262561',
    timeZoneId: 'America/Los_Angeles',
  },
  '91224': {
    zip: '91224',
    city: 'La Crescenta',
    state: 'CA',
    latitude: '34.227961',
    longitude: '-118.246396',
    timeZoneId: 'America/Los_Angeles',
  },
  '91225': {
    zip: '91225',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.124264',
    longitude: '-118.254291',
    timeZoneId: 'America/Los_Angeles',
  },
  '91226': {
    zip: '91226',
    city: 'Glendale',
    state: 'CA',
    latitude: '34.160034',
    longitude: '-118.213223',
    timeZoneId: 'America/Los_Angeles',
  },
  '91301': {
    zip: '91301',
    city: 'Agoura Hills',
    state: 'CA',
    latitude: '34.144523',
    longitude: '-118.760382',
    timeZoneId: 'America/Los_Angeles',
  },
  '91302': {
    zip: '91302',
    city: 'Calabasas',
    state: 'CA',
    latitude: '34.134741',
    longitude: '-118.656723',
    timeZoneId: 'America/Los_Angeles',
  },
  '91303': {
    zip: '91303',
    city: 'Canoga Park',
    state: 'CA',
    latitude: '34.196941',
    longitude: '-118.60309',
    timeZoneId: 'America/Los_Angeles',
  },
  '91304': {
    zip: '91304',
    city: 'Canoga Park',
    state: 'CA',
    latitude: '34.218348',
    longitude: '-118.625847',
    timeZoneId: 'America/Los_Angeles',
  },
  '91305': {
    zip: '91305',
    city: 'Canoga Park',
    state: 'CA',
    latitude: '34.201051',
    longitude: '-118.604164',
    timeZoneId: 'America/Los_Angeles',
  },
  '91306': {
    zip: '91306',
    city: 'Winnetka',
    state: 'CA',
    latitude: '34.207991',
    longitude: '-118.57547',
    timeZoneId: 'America/Los_Angeles',
  },
  '91307': {
    zip: '91307',
    city: 'West Hills',
    state: 'CA',
    latitude: '34.20192',
    longitude: '-118.66098',
    timeZoneId: 'America/Los_Angeles',
  },
  '91308': {
    zip: '91308',
    city: 'West Hills',
    state: 'CA',
    latitude: '34.201111',
    longitude: '-118.597222',
    timeZoneId: 'America/Los_Angeles',
  },
  '91309': {
    zip: '91309',
    city: 'Canoga Park',
    state: 'CA',
    latitude: '34.2181',
    longitude: '-118.595483',
    timeZoneId: 'America/Los_Angeles',
  },
  '91310': {
    zip: '91310',
    city: 'Castaic',
    state: 'CA',
    latitude: '34.494638',
    longitude: '-118.614455',
    timeZoneId: 'America/Los_Angeles',
  },
  '91311': {
    zip: '91311',
    city: 'Chatsworth',
    state: 'CA',
    latitude: '34.254498',
    longitude: '-118.594763',
    timeZoneId: 'America/Los_Angeles',
  },
  '91313': {
    zip: '91313',
    city: 'Chatsworth',
    state: 'CA',
    latitude: '34.239477',
    longitude: '-118.588495',
    timeZoneId: 'America/Los_Angeles',
  },
  '91316': {
    zip: '91316',
    city: 'Encino',
    state: 'CA',
    latitude: '34.162355',
    longitude: '-118.514077',
    timeZoneId: 'America/Los_Angeles',
  },
  '91319': {
    zip: '91319',
    city: 'Newbury Park',
    state: 'CA',
    latitude: '34.184847',
    longitude: '-118.916417',
    timeZoneId: 'America/Los_Angeles',
  },
  '91320': {
    zip: '91320',
    city: 'Newbury Park',
    state: 'CA',
    latitude: '34.18536',
    longitude: '-118.92648',
    timeZoneId: 'America/Los_Angeles',
  },
  '91321': {
    zip: '91321',
    city: 'Newhall',
    state: 'CA',
    latitude: '34.379025',
    longitude: '-118.520802',
    timeZoneId: 'America/Los_Angeles',
  },
  '91322': {
    zip: '91322',
    city: 'Newhall',
    state: 'CA',
    latitude: '34.384669',
    longitude: '-118.530023',
    timeZoneId: 'America/Los_Angeles',
  },
  '91324': {
    zip: '91324',
    city: 'Northridge',
    state: 'CA',
    latitude: '34.243279',
    longitude: '-118.545849',
    timeZoneId: 'America/Los_Angeles',
  },
  '91325': {
    zip: '91325',
    city: 'Northridge',
    state: 'CA',
    latitude: '34.239807',
    longitude: '-118.516396',
    timeZoneId: 'America/Los_Angeles',
  },
  '91326': {
    zip: '91326',
    city: 'Porter Ranch',
    state: 'CA',
    latitude: '34.276626',
    longitude: '-118.543741',
    timeZoneId: 'America/Los_Angeles',
  },
  '91327': {
    zip: '91327',
    city: 'Northridge',
    state: 'CA',
    latitude: '34.273021',
    longitude: '-118.551433',
    timeZoneId: 'America/Los_Angeles',
  },
  '91328': {
    zip: '91328',
    city: 'Northridge',
    state: 'CA',
    latitude: '34.217856',
    longitude: '-118.531174',
    timeZoneId: 'America/Los_Angeles',
  },
  '91329': {
    zip: '91329',
    city: 'Northridge',
    state: 'CA',
    latitude: '34.246037',
    longitude: '-118.531914',
    timeZoneId: 'America/Los_Angeles',
  },
  '91330': {
    zip: '91330',
    city: 'Northridge',
    state: 'CA',
    latitude: '34.245875',
    longitude: '-118.501885',
    timeZoneId: 'America/Los_Angeles',
  },
  '91331': {
    zip: '91331',
    city: 'Pacoima',
    state: 'CA',
    latitude: '34.252512',
    longitude: '-118.425349',
    timeZoneId: 'America/Los_Angeles',
  },
  '91333': {
    zip: '91333',
    city: 'Pacoima',
    state: 'CA',
    latitude: '34.266069',
    longitude: '-118.426873',
    timeZoneId: 'America/Los_Angeles',
  },
  '91334': {
    zip: '91334',
    city: 'Pacoima',
    state: 'CA',
    latitude: '34.244284',
    longitude: '-118.426018',
    timeZoneId: 'America/Los_Angeles',
  },
  '91335': {
    zip: '91335',
    city: 'Reseda',
    state: 'CA',
    latitude: '34.199469',
    longitude: '-118.539891',
    timeZoneId: 'America/Los_Angeles',
  },
  '91337': {
    zip: '91337',
    city: 'Reseda',
    state: 'CA',
    latitude: '34.202639',
    longitude: '-118.534607',
    timeZoneId: 'America/Los_Angeles',
  },
  '91340': {
    zip: '91340',
    city: 'San Fernando',
    state: 'CA',
    latitude: '34.285498',
    longitude: '-118.431945',
    timeZoneId: 'America/Los_Angeles',
  },
  '91341': {
    zip: '91341',
    city: 'San Fernando',
    state: 'CA',
    latitude: '34.282219',
    longitude: '-118.442779',
    timeZoneId: 'America/Los_Angeles',
  },
  '91342': {
    zip: '91342',
    city: 'Sylmar',
    state: 'CA',
    latitude: '34.303046',
    longitude: '-118.42194',
    timeZoneId: 'America/Los_Angeles',
  },
  '91343': {
    zip: '91343',
    city: 'North Hills',
    state: 'CA',
    latitude: '34.237131',
    longitude: '-118.481042',
    timeZoneId: 'America/Los_Angeles',
  },
  '91344': {
    zip: '91344',
    city: 'Granada Hills',
    state: 'CA',
    latitude: '34.279812',
    longitude: '-118.500354',
    timeZoneId: 'America/Los_Angeles',
  },
  '91345': {
    zip: '91345',
    city: 'Mission Hills',
    state: 'CA',
    latitude: '34.281944',
    longitude: '-118.438056',
    timeZoneId: 'America/Los_Angeles',
  },
  '91346': {
    zip: '91346',
    city: 'Mission Hills',
    state: 'CA',
    latitude: '34.26798',
    longitude: '-118.46628',
    timeZoneId: 'America/Los_Angeles',
  },
  '91350': {
    zip: '91350',
    city: 'Santa Clarita',
    state: 'CA',
    latitude: '34.485022',
    longitude: '-118.42802',
    timeZoneId: 'America/Los_Angeles',
  },
  '91351': {
    zip: '91351',
    city: 'Canyon Country',
    state: 'CA',
    latitude: '34.425819',
    longitude: '-118.443437',
    timeZoneId: 'America/Los_Angeles',
  },
  '91352': {
    zip: '91352',
    city: 'Sun Valley',
    state: 'CA',
    latitude: '34.224912',
    longitude: '-118.36035',
    timeZoneId: 'America/Los_Angeles',
  },
  '91353': {
    zip: '91353',
    city: 'Sun Valley',
    state: 'CA',
    latitude: '34.217534',
    longitude: '-118.367514',
    timeZoneId: 'America/Los_Angeles',
  },
  '91354': {
    zip: '91354',
    city: 'Valencia',
    state: 'CA',
    latitude: '34.44512',
    longitude: '-118.546414',
    timeZoneId: 'America/Los_Angeles',
  },
  '91355': {
    zip: '91355',
    city: 'Valencia',
    state: 'CA',
    latitude: '34.403591',
    longitude: '-118.532784',
    timeZoneId: 'America/Los_Angeles',
  },
  '91356': {
    zip: '91356',
    city: 'Tarzana',
    state: 'CA',
    latitude: '34.162999',
    longitude: '-118.549334',
    timeZoneId: 'America/Los_Angeles',
  },
  '91357': {
    zip: '91357',
    city: 'Tarzana',
    state: 'CA',
    latitude: '34.172643',
    longitude: '-118.540408',
    timeZoneId: 'America/Los_Angeles',
  },
  '91358': {
    zip: '91358',
    city: 'Thousand Oaks',
    state: 'CA',
    latitude: '34.178907',
    longitude: '-118.874405',
    timeZoneId: 'America/Los_Angeles',
  },
  '91359': {
    zip: '91359',
    city: 'Westlake Village',
    state: 'CA',
    latitude: '34.194443',
    longitude: '-118.820014',
    timeZoneId: 'America/Los_Angeles',
  },
  '91360': {
    zip: '91360',
    city: 'Thousand Oaks',
    state: 'CA',
    latitude: '34.208451',
    longitude: '-118.874426',
    timeZoneId: 'America/Los_Angeles',
  },
  '91361': {
    zip: '91361',
    city: 'Westlake Village',
    state: 'CA',
    latitude: '34.152136',
    longitude: '-118.846704',
    timeZoneId: 'America/Los_Angeles',
  },
  '91362': {
    zip: '91362',
    city: 'Thousand Oaks',
    state: 'CA',
    latitude: '34.19292',
    longitude: '-118.82262',
    timeZoneId: 'America/Los_Angeles',
  },
  '91364': {
    zip: '91364',
    city: 'Woodland Hills',
    state: 'CA',
    latitude: '34.153026',
    longitude: '-118.602986',
    timeZoneId: 'America/Los_Angeles',
  },
  '91365': {
    zip: '91365',
    city: 'Woodland Hills',
    state: 'CA',
    latitude: '34.172838',
    longitude: '-118.605715',
    timeZoneId: 'America/Los_Angeles',
  },
  '91367': {
    zip: '91367',
    city: 'Woodland Hills',
    state: 'CA',
    latitude: '34.178189',
    longitude: '-118.623042',
    timeZoneId: 'America/Los_Angeles',
  },
  '91371': {
    zip: '91371',
    city: 'Woodland Hills',
    state: 'CA',
    latitude: '34.181136',
    longitude: '-118.570613',
    timeZoneId: 'America/Los_Angeles',
  },
  '91372': {
    zip: '91372',
    city: 'Calabasas',
    state: 'CA',
    latitude: '34.154498',
    longitude: '-118.64317',
    timeZoneId: 'America/Los_Angeles',
  },
  '91376': {
    zip: '91376',
    city: 'Agoura Hills',
    state: 'CA',
    latitude: '34.140438',
    longitude: '-118.751567',
    timeZoneId: 'America/Los_Angeles',
  },
  '91377': {
    zip: '91377',
    city: 'Oak Park',
    state: 'CA',
    latitude: '34.145227',
    longitude: '-118.756588',
    timeZoneId: 'America/Los_Angeles',
  },
  '91380': {
    zip: '91380',
    city: 'Santa Clarita',
    state: 'CA',
    latitude: '34.499933',
    longitude: '-118.394976',
    timeZoneId: 'America/Los_Angeles',
  },
  '91381': {
    zip: '91381',
    city: 'Stevenson Ranch',
    state: 'CA',
    latitude: '34.381783',
    longitude: '-118.573262',
    timeZoneId: 'America/Los_Angeles',
  },
  '91382': {
    zip: '91382',
    city: 'Santa Clarita',
    state: 'CA',
    latitude: '34.412894',
    longitude: '-118.510726',
    timeZoneId: 'America/Los_Angeles',
  },
  '91383': {
    zip: '91383',
    city: 'Santa Clarita',
    state: 'CA',
    latitude: '34.358853',
    longitude: '-118.455629',
    timeZoneId: 'America/Los_Angeles',
  },
  '91384': {
    zip: '91384',
    city: 'Castaic',
    state: 'CA',
    latitude: '34.46482',
    longitude: '-118.640594',
    timeZoneId: 'America/Los_Angeles',
  },
  '91385': {
    zip: '91385',
    city: 'Valencia',
    state: 'CA',
    latitude: '34.389797',
    longitude: '-118.558445',
    timeZoneId: 'America/Los_Angeles',
  },
  '91386': {
    zip: '91386',
    city: 'Canyon Country',
    state: 'CA',
    latitude: '34.41749',
    longitude: '-118.449656',
    timeZoneId: 'America/Los_Angeles',
  },
  '91387': {
    zip: '91387',
    city: 'Canyon Country',
    state: 'CA',
    latitude: '34.42503',
    longitude: '-118.44426',
    timeZoneId: 'America/Los_Angeles',
  },
  '91390': {
    zip: '91390',
    city: 'Santa Clarita',
    state: 'CA',
    latitude: '34.482978',
    longitude: '-118.428774',
    timeZoneId: 'America/Los_Angeles',
  },
  '91392': {
    zip: '91392',
    city: 'Sylmar',
    state: 'CA',
    latitude: '34.310015',
    longitude: '-118.431906',
    timeZoneId: 'America/Los_Angeles',
  },
  '91393': {
    zip: '91393',
    city: 'North Hills',
    state: 'CA',
    latitude: '34.230541',
    longitude: '-118.466992',
    timeZoneId: 'America/Los_Angeles',
  },
  '91394': {
    zip: '91394',
    city: 'Granada Hills',
    state: 'CA',
    latitude: '34.263508',
    longitude: '-118.526054',
    timeZoneId: 'America/Los_Angeles',
  },
  '91395': {
    zip: '91395',
    city: 'Mission Hills',
    state: 'CA',
    latitude: '34.253953',
    longitude: '-118.464623',
    timeZoneId: 'America/Los_Angeles',
  },
  '91396': {
    zip: '91396',
    city: 'Winnetka',
    state: 'CA',
    latitude: '34.210263',
    longitude: '-118.570594',
    timeZoneId: 'America/Los_Angeles',
  },
  '91401': {
    zip: '91401',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.176265',
    longitude: '-118.427626',
    timeZoneId: 'America/Los_Angeles',
  },
  '91402': {
    zip: '91402',
    city: 'Panorama City',
    state: 'CA',
    latitude: '34.222187',
    longitude: '-118.442663',
    timeZoneId: 'America/Los_Angeles',
  },
  '91403': {
    zip: '91403',
    city: 'Sherman Oaks',
    state: 'CA',
    latitude: '34.148353',
    longitude: '-118.461005',
    timeZoneId: 'America/Los_Angeles',
  },
  '91404': {
    zip: '91404',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.177503',
    longitude: '-118.43194',
    timeZoneId: 'America/Los_Angeles',
  },
  '91405': {
    zip: '91405',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.201681',
    longitude: '-118.446452',
    timeZoneId: 'America/Los_Angeles',
  },
  '91406': {
    zip: '91406',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.201751',
    longitude: '-118.492685',
    timeZoneId: 'America/Los_Angeles',
  },
  '91407': {
    zip: '91407',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.193851',
    longitude: '-118.448447',
    timeZoneId: 'America/Los_Angeles',
  },
  '91408': {
    zip: '91408',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.175689',
    longitude: '-118.430158',
    timeZoneId: 'America/Los_Angeles',
  },
  '91409': {
    zip: '91409',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.20356',
    longitude: '-118.489767',
    timeZoneId: 'America/Los_Angeles',
  },
  '91410': {
    zip: '91410',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.203639',
    longitude: '-118.492923',
    timeZoneId: 'America/Los_Angeles',
  },
  '91411': {
    zip: '91411',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.179',
    longitude: '-118.44738',
    timeZoneId: 'America/Los_Angeles',
  },
  '91412': {
    zip: '91412',
    city: 'Panorama City',
    state: 'CA',
    latitude: '34.222076',
    longitude: '-118.446033',
    timeZoneId: 'America/Los_Angeles',
  },
  '91413': {
    zip: '91413',
    city: 'Sherman Oaks',
    state: 'CA',
    latitude: '34.164355',
    longitude: '-118.458352',
    timeZoneId: 'America/Los_Angeles',
  },
  '91416': {
    zip: '91416',
    city: 'Encino',
    state: 'CA',
    latitude: '34.178039',
    longitude: '-118.517445',
    timeZoneId: 'America/Los_Angeles',
  },
  '91423': {
    zip: '91423',
    city: 'Sherman Oaks',
    state: 'CA',
    latitude: '34.151353',
    longitude: '-118.435422',
    timeZoneId: 'America/Los_Angeles',
  },
  '91426': {
    zip: '91426',
    city: 'Encino',
    state: 'CA',
    latitude: '34.155006',
    longitude: '-118.515946',
    timeZoneId: 'America/Los_Angeles',
  },
  '91436': {
    zip: '91436',
    city: 'Encino',
    state: 'CA',
    latitude: '34.16591',
    longitude: '-118.46357',
    timeZoneId: 'America/Los_Angeles',
  },
  '91470': {
    zip: '91470',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.177794',
    longitude: '-118.61468',
    timeZoneId: 'America/Los_Angeles',
  },
  '91482': {
    zip: '91482',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.201021',
    longitude: '-118.475212',
    timeZoneId: 'America/Los_Angeles',
  },
  '91495': {
    zip: '91495',
    city: 'Sherman Oaks',
    state: 'CA',
    latitude: '34.151111',
    longitude: '-118.448333',
    timeZoneId: 'America/Los_Angeles',
  },
  '91496': {
    zip: '91496',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.163186',
    longitude: '-118.456615',
    timeZoneId: 'America/Los_Angeles',
  },
  '91499': {
    zip: '91499',
    city: 'Van Nuys',
    state: 'CA',
    latitude: '34.201899',
    longitude: '-118.454395',
    timeZoneId: 'America/Los_Angeles',
  },
  '91501': {
    zip: '91501',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.188758',
    longitude: '-118.296041',
    timeZoneId: 'America/Los_Angeles',
  },
  '91502': {
    zip: '91502',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.174687',
    longitude: '-118.306185',
    timeZoneId: 'America/Los_Angeles',
  },
  '91503': {
    zip: '91503',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.180668',
    longitude: '-118.307534',
    timeZoneId: 'America/Los_Angeles',
  },
  '91504': {
    zip: '91504',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.203164',
    longitude: '-118.327142',
    timeZoneId: 'America/Los_Angeles',
  },
  '91505': {
    zip: '91505',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.17091',
    longitude: '-118.342586',
    timeZoneId: 'America/Los_Angeles',
  },
  '91506': {
    zip: '91506',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.175519',
    longitude: '-118.322799',
    timeZoneId: 'America/Los_Angeles',
  },
  '91507': {
    zip: '91507',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.168685',
    longitude: '-118.347797',
    timeZoneId: 'America/Los_Angeles',
  },
  '91508': {
    zip: '91508',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.191193',
    longitude: '-118.328476',
    timeZoneId: 'America/Los_Angeles',
  },
  '91510': {
    zip: '91510',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.173285',
    longitude: '-118.345911',
    timeZoneId: 'America/Los_Angeles',
  },
  '91521': {
    zip: '91521',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.154869',
    longitude: '-118.326276',
    timeZoneId: 'America/Los_Angeles',
  },
  '91522': {
    zip: '91522',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.146904',
    longitude: '-118.337574',
    timeZoneId: 'America/Los_Angeles',
  },
  '91523': {
    zip: '91523',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.169107',
    longitude: '-118.336597',
    timeZoneId: 'America/Los_Angeles',
  },
  '91526': {
    zip: '91526',
    city: 'Burbank',
    state: 'CA',
    latitude: '34.190452',
    longitude: '-118.325901',
    timeZoneId: 'America/Los_Angeles',
  },
  '91601': {
    zip: '91601',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.169504',
    longitude: '-118.370011',
    timeZoneId: 'America/Los_Angeles',
  },
  '91602': {
    zip: '91602',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.152564',
    longitude: '-118.367089',
    timeZoneId: 'America/Los_Angeles',
  },
  '91603': {
    zip: '91603',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.172067',
    longitude: '-118.37692',
    timeZoneId: 'America/Los_Angeles',
  },
  '91604': {
    zip: '91604',
    city: 'Studio City',
    state: 'CA',
    latitude: '34.144568',
    longitude: '-118.390161',
    timeZoneId: 'America/Los_Angeles',
  },
  '91605': {
    zip: '91605',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.209647',
    longitude: '-118.40255',
    timeZoneId: 'America/Los_Angeles',
  },
  '91606': {
    zip: '91606',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.186265',
    longitude: '-118.391357',
    timeZoneId: 'America/Los_Angeles',
  },
  '91607': {
    zip: '91607',
    city: 'Valley Village',
    state: 'CA',
    latitude: '34.165372',
    longitude: '-118.398273',
    timeZoneId: 'America/Los_Angeles',
  },
  '91608': {
    zip: '91608',
    city: 'Universal City',
    state: 'CA',
    latitude: '34.140376',
    longitude: '-118.354334',
    timeZoneId: 'America/Los_Angeles',
  },
  '91609': {
    zip: '91609',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.188269',
    longitude: '-118.386781',
    timeZoneId: 'America/Los_Angeles',
  },
  '91610': {
    zip: '91610',
    city: 'Toluca Lake',
    state: 'CA',
    latitude: '34.155836',
    longitude: '-118.379194',
    timeZoneId: 'America/Los_Angeles',
  },
  '91611': {
    zip: '91611',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.186474',
    longitude: '-118.395745',
    timeZoneId: 'America/Los_Angeles',
  },
  '91612': {
    zip: '91612',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.186548',
    longitude: '-118.396887',
    timeZoneId: 'America/Los_Angeles',
  },
  '91614': {
    zip: '91614',
    city: 'Studio City',
    state: 'CA',
    latitude: '34.144523',
    longitude: '-118.393815',
    timeZoneId: 'America/Los_Angeles',
  },
  '91615': {
    zip: '91615',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.200891',
    longitude: '-118.394821',
    timeZoneId: 'America/Los_Angeles',
  },
  '91616': {
    zip: '91616',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.184197',
    longitude: '-118.396049',
    timeZoneId: 'America/Los_Angeles',
  },
  '91617': {
    zip: '91617',
    city: 'Valley Village',
    state: 'CA',
    latitude: '34.164995',
    longitude: '-118.403562',
    timeZoneId: 'America/Los_Angeles',
  },
  '91618': {
    zip: '91618',
    city: 'North Hollywood',
    state: 'CA',
    latitude: '34.112736',
    longitude: '-118.365832',
    timeZoneId: 'America/Los_Angeles',
  },
  '91701': {
    zip: '91701',
    city: 'Rancho Cucamonga',
    state: 'CA',
    latitude: '34.15554',
    longitude: '-117.58974',
    timeZoneId: 'America/Los_Angeles',
  },
  '91702': {
    zip: '91702',
    city: 'Azusa',
    state: 'CA',
    latitude: '34.124881',
    longitude: '-117.903021',
    timeZoneId: 'America/Los_Angeles',
  },
  '91706': {
    zip: '91706',
    city: 'Baldwin Park',
    state: 'CA',
    latitude: '34.085581',
    longitude: '-117.970122',
    timeZoneId: 'America/Los_Angeles',
  },
  '91708': {
    zip: '91708',
    city: 'Chino',
    state: 'CA',
    latitude: '34.022678',
    longitude: '-117.686018',
    timeZoneId: 'America/Los_Angeles',
  },
  '91709': {
    zip: '91709',
    city: 'Chino Hills',
    state: 'CA',
    latitude: '33.98664',
    longitude: '-117.68322',
    timeZoneId: 'America/Los_Angeles',
  },
  '91710': {
    zip: '91710',
    city: 'Chino',
    state: 'CA',
    latitude: '34.016105',
    longitude: '-117.686207',
    timeZoneId: 'America/Los_Angeles',
  },
  '91711': {
    zip: '91711',
    city: 'Claremont',
    state: 'CA',
    latitude: '34.113043',
    longitude: '-117.717351',
    timeZoneId: 'America/Los_Angeles',
  },
  '91714': {
    zip: '91714',
    city: 'City of Industry',
    state: 'CA',
    latitude: '34.019446',
    longitude: '-117.957126',
    timeZoneId: 'America/Los_Angeles',
  },
  '91715': {
    zip: '91715',
    city: 'City of Industry',
    state: 'CA',
    latitude: '34.015728',
    longitude: '-117.969965',
    timeZoneId: 'America/Los_Angeles',
  },
  '91716': {
    zip: '91716',
    city: 'City of Industry',
    state: 'CA',
    latitude: '34.003756',
    longitude: '-117.974564',
    timeZoneId: 'America/Los_Angeles',
  },
  '91722': {
    zip: '91722',
    city: 'Covina',
    state: 'CA',
    latitude: '34.097496',
    longitude: '-117.902732',
    timeZoneId: 'America/Los_Angeles',
  },
  '91723': {
    zip: '91723',
    city: 'Covina',
    state: 'CA',
    latitude: '34.087728',
    longitude: '-117.886449',
    timeZoneId: 'America/Los_Angeles',
  },
  '91724': {
    zip: '91724',
    city: 'Covina',
    state: 'CA',
    latitude: '34.090101',
    longitude: '-117.858739',
    timeZoneId: 'America/Los_Angeles',
  },
  '91729': {
    zip: '91729',
    city: 'Rancho Cucamonga',
    state: 'CA',
    latitude: '34.098356',
    longitude: '-117.563036',
    timeZoneId: 'America/Los_Angeles',
  },
  '91730': {
    zip: '91730',
    city: 'Rancho Cucamonga',
    state: 'CA',
    latitude: '34.09914',
    longitude: '-117.58128',
    timeZoneId: 'America/Los_Angeles',
  },
  '91731': {
    zip: '91731',
    city: 'El Monte',
    state: 'CA',
    latitude: '34.076656',
    longitude: '-118.040904',
    timeZoneId: 'America/Los_Angeles',
  },
  '91732': {
    zip: '91732',
    city: 'El Monte',
    state: 'CA',
    latitude: '34.074497',
    longitude: '-118.011309',
    timeZoneId: 'America/Los_Angeles',
  },
  '91733': {
    zip: '91733',
    city: 'South El Monte',
    state: 'CA',
    latitude: '34.051383',
    longitude: '-118.044588',
    timeZoneId: 'America/Los_Angeles',
  },
  '91734': {
    zip: '91734',
    city: 'El Monte',
    state: 'CA',
    latitude: '34.071829',
    longitude: '-118.033314',
    timeZoneId: 'America/Los_Angeles',
  },
  '91735': {
    zip: '91735',
    city: 'El Monte',
    state: 'CA',
    latitude: '34.074913',
    longitude: '-118.028045',
    timeZoneId: 'America/Los_Angeles',
  },
  '91737': {
    zip: '91737',
    city: 'Rancho Cucamonga',
    state: 'CA',
    latitude: '34.146529',
    longitude: '-117.573112',
    timeZoneId: 'America/Los_Angeles',
  },
  '91739': {
    zip: '91739',
    city: 'Rancho Cucamonga',
    state: 'CA',
    latitude: '34.124307',
    longitude: '-117.519639',
    timeZoneId: 'America/Los_Angeles',
  },
  '91740': {
    zip: '91740',
    city: 'Glendora',
    state: 'CA',
    latitude: '34.115217',
    longitude: '-117.85934',
    timeZoneId: 'America/Los_Angeles',
  },
  '91741': {
    zip: '91741',
    city: 'Glendora',
    state: 'CA',
    latitude: '34.145314',
    longitude: '-117.853357',
    timeZoneId: 'America/Los_Angeles',
  },
  '91743': {
    zip: '91743',
    city: 'Guasti',
    state: 'CA',
    latitude: '34.064884',
    longitude: '-117.580297',
    timeZoneId: 'America/Los_Angeles',
  },
  '91744': {
    zip: '91744',
    city: 'La Puente',
    state: 'CA',
    latitude: '34.028223',
    longitude: '-117.937998',
    timeZoneId: 'America/Los_Angeles',
  },
  '91745': {
    zip: '91745',
    city: 'Hacienda Heights',
    state: 'CA',
    latitude: '33.998974',
    longitude: '-117.965163',
    timeZoneId: 'America/Los_Angeles',
  },
  '91746': {
    zip: '91746',
    city: 'La Puente',
    state: 'CA',
    latitude: '34.049002',
    longitude: '-117.979948',
    timeZoneId: 'America/Los_Angeles',
  },
  '91747': {
    zip: '91747',
    city: 'La Puente',
    state: 'CA',
    latitude: '34.023217',
    longitude: '-117.947994',
    timeZoneId: 'America/Los_Angeles',
  },
  '91748': {
    zip: '91748',
    city: 'Rowland Heights',
    state: 'CA',
    latitude: '33.979568',
    longitude: '-117.898437',
    timeZoneId: 'America/Los_Angeles',
  },
  '91749': {
    zip: '91749',
    city: 'La Puente',
    state: 'CA',
    latitude: '34.030733',
    longitude: '-117.949992',
    timeZoneId: 'America/Los_Angeles',
  },
  '91750': {
    zip: '91750',
    city: 'La Verne',
    state: 'CA',
    latitude: '34.117986',
    longitude: '-117.768243',
    timeZoneId: 'America/Los_Angeles',
  },
  '91752': {
    zip: '91752',
    city: 'Mira Loma',
    state: 'CA',
    latitude: '33.995007',
    longitude: '-117.528742',
    timeZoneId: 'America/Los_Angeles',
  },
  '91754': {
    zip: '91754',
    city: 'Monterey Park',
    state: 'CA',
    latitude: '34.048466',
    longitude: '-118.14974',
    timeZoneId: 'America/Los_Angeles',
  },
  '91755': {
    zip: '91755',
    city: 'Monterey Park',
    state: 'CA',
    latitude: '34.051246',
    longitude: '-118.117947',
    timeZoneId: 'America/Los_Angeles',
  },
  '91756': {
    zip: '91756',
    city: 'Monterey Park',
    state: 'CA',
    latitude: '34.047565',
    longitude: '-118.132799',
    timeZoneId: 'America/Los_Angeles',
  },
  '91758': {
    zip: '91758',
    city: 'Ontario',
    state: 'CA',
    latitude: '34.055007',
    longitude: '-117.608926',
    timeZoneId: 'America/Los_Angeles',
  },
  '91759': {
    zip: '91759',
    city: 'Mt Baldy',
    state: 'CA',
    latitude: '34.255822',
    longitude: '-117.635688',
    timeZoneId: 'America/Los_Angeles',
  },
  '91761': {
    zip: '91761',
    city: 'Ontario',
    state: 'CA',
    latitude: '34.036901',
    longitude: '-117.606236',
    timeZoneId: 'America/Los_Angeles',
  },
  '91762': {
    zip: '91762',
    city: 'Ontario',
    state: 'CA',
    latitude: '34.058501',
    longitude: '-117.667472',
    timeZoneId: 'America/Los_Angeles',
  },
  '91763': {
    zip: '91763',
    city: 'Montclair',
    state: 'CA',
    latitude: '34.077569',
    longitude: '-117.697687',
    timeZoneId: 'America/Los_Angeles',
  },
  '91764': {
    zip: '91764',
    city: 'Ontario',
    state: 'CA',
    latitude: '34.077008',
    longitude: '-117.617165',
    timeZoneId: 'America/Los_Angeles',
  },
  '91765': {
    zip: '91765',
    city: 'Diamond Bar',
    state: 'CA',
    latitude: '34.001426',
    longitude: '-117.815685',
    timeZoneId: 'America/Los_Angeles',
  },
  '91766': {
    zip: '91766',
    city: 'Pomona',
    state: 'CA',
    latitude: '34.041268',
    longitude: '-117.761339',
    timeZoneId: 'America/Los_Angeles',
  },
  '91767': {
    zip: '91767',
    city: 'Pomona',
    state: 'CA',
    latitude: '34.081648',
    longitude: '-117.740882',
    timeZoneId: 'America/Los_Angeles',
  },
  '91768': {
    zip: '91768',
    city: 'Pomona',
    state: 'CA',
    latitude: '34.068677',
    longitude: '-117.774134',
    timeZoneId: 'America/Los_Angeles',
  },
  '91769': {
    zip: '91769',
    city: 'Pomona',
    state: 'CA',
    latitude: '34.061362',
    longitude: '-117.75682',
    timeZoneId: 'America/Los_Angeles',
  },
  '91770': {
    zip: '91770',
    city: 'Rosemead',
    state: 'CA',
    latitude: '34.065846',
    longitude: '-118.082058',
    timeZoneId: 'America/Los_Angeles',
  },
  '91771': {
    zip: '91771',
    city: 'Rosemead',
    state: 'CA',
    latitude: '34.065056',
    longitude: '-118.083026',
    timeZoneId: 'America/Los_Angeles',
  },
  '91772': {
    zip: '91772',
    city: 'Rosemead',
    state: 'CA',
    latitude: '34.065726',
    longitude: '-118.085794',
    timeZoneId: 'America/Los_Angeles',
  },
  '91773': {
    zip: '91773',
    city: 'San Dimas',
    state: 'CA',
    latitude: '34.09885',
    longitude: '-117.81811',
    timeZoneId: 'America/Los_Angeles',
  },
  '91775': {
    zip: '91775',
    city: 'San Gabriel',
    state: 'CA',
    latitude: '34.112605',
    longitude: '-118.089861',
    timeZoneId: 'America/Los_Angeles',
  },
  '91776': {
    zip: '91776',
    city: 'San Gabriel',
    state: 'CA',
    latitude: '34.089832',
    longitude: '-118.092812',
    timeZoneId: 'America/Los_Angeles',
  },
  '91778': {
    zip: '91778',
    city: 'San Gabriel',
    state: 'CA',
    latitude: '34.101551',
    longitude: '-118.103851',
    timeZoneId: 'America/Los_Angeles',
  },
  '91780': {
    zip: '91780',
    city: 'Temple City',
    state: 'CA',
    latitude: '34.101878',
    longitude: '-118.057437',
    timeZoneId: 'America/Los_Angeles',
  },
  '91784': {
    zip: '91784',
    city: 'Upland',
    state: 'CA',
    latitude: '34.136114',
    longitude: '-117.665006',
    timeZoneId: 'America/Los_Angeles',
  },
  '91785': {
    zip: '91785',
    city: 'Upland',
    state: 'CA',
    latitude: '34.10076',
    longitude: '-117.647655',
    timeZoneId: 'America/Los_Angeles',
  },
  '91786': {
    zip: '91786',
    city: 'Upland',
    state: 'CA',
    latitude: '34.10366',
    longitude: '-117.661893',
    timeZoneId: 'America/Los_Angeles',
  },
  '91788': {
    zip: '91788',
    city: 'Walnut',
    state: 'CA',
    latitude: '34.013703',
    longitude: '-117.863721',
    timeZoneId: 'America/Los_Angeles',
  },
  '91789': {
    zip: '91789',
    city: 'Walnut',
    state: 'CA',
    latitude: '34.014965',
    longitude: '-117.856127',
    timeZoneId: 'America/Los_Angeles',
  },
  '91790': {
    zip: '91790',
    city: 'West Covina',
    state: 'CA',
    latitude: '34.065225',
    longitude: '-117.938282',
    timeZoneId: 'America/Los_Angeles',
  },
  '91791': {
    zip: '91791',
    city: 'West Covina',
    state: 'CA',
    latitude: '34.062629',
    longitude: '-117.893363',
    timeZoneId: 'America/Los_Angeles',
  },
  '91792': {
    zip: '91792',
    city: 'West Covina',
    state: 'CA',
    latitude: '34.02182',
    longitude: '-117.897867',
    timeZoneId: 'America/Los_Angeles',
  },
  '91793': {
    zip: '91793',
    city: 'West Covina',
    state: 'CA',
    latitude: '34.067473',
    longitude: '-117.927759',
    timeZoneId: 'America/Los_Angeles',
  },
  '91801': {
    zip: '91801',
    city: 'Alhambra',
    state: 'CA',
    latitude: '34.089881',
    longitude: '-118.129539',
    timeZoneId: 'America/Los_Angeles',
  },
  '91802': {
    zip: '91802',
    city: 'Alhambra',
    state: 'CA',
    latitude: '34.090903',
    longitude: '-118.124015',
    timeZoneId: 'America/Los_Angeles',
  },
  '91803': {
    zip: '91803',
    city: 'Alhambra',
    state: 'CA',
    latitude: '34.070953',
    longitude: '-118.145923',
    timeZoneId: 'America/Los_Angeles',
  },
  '91804': {
    zip: '91804',
    city: 'Alhambra',
    state: 'CA',
    latitude: '34.092015',
    longitude: '-118.128852',
    timeZoneId: 'America/Los_Angeles',
  },
  '91896': {
    zip: '91896',
    city: 'Alhambra',
    state: 'CA',
    latitude: '34.092513',
    longitude: '-118.128483',
    timeZoneId: 'America/Los_Angeles',
  },
  '91899': {
    zip: '91899',
    city: 'Alhambra',
    state: 'CA',
    latitude: '34.080816',
    longitude: '-118.135414',
    timeZoneId: 'America/Los_Angeles',
  },
  '91901': {
    zip: '91901',
    city: 'Alpine',
    state: 'CA',
    latitude: '32.826938',
    longitude: '-116.755318',
    timeZoneId: 'America/Los_Angeles',
  },
  '91902': {
    zip: '91902',
    city: 'Bonita',
    state: 'CA',
    latitude: '32.670318',
    longitude: '-117.005554',
    timeZoneId: 'America/Los_Angeles',
  },
  '91903': {
    zip: '91903',
    city: 'Alpine',
    state: 'CA',
    latitude: '32.832775',
    longitude: '-116.762806',
    timeZoneId: 'America/Los_Angeles',
  },
  '91905': {
    zip: '91905',
    city: 'Boulevard',
    state: 'CA',
    latitude: '32.724561',
    longitude: '-116.299903',
    timeZoneId: 'America/Los_Angeles',
  },
  '91906': {
    zip: '91906',
    city: 'Campo',
    state: 'CA',
    latitude: '32.693965',
    longitude: '-116.510269',
    timeZoneId: 'America/Los_Angeles',
  },
  '91908': {
    zip: '91908',
    city: 'Bonita',
    state: 'CA',
    latitude: '32.65994',
    longitude: '-117.032213',
    timeZoneId: 'America/Los_Angeles',
  },
  '91909': {
    zip: '91909',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.607717',
    longitude: '-117.070647',
    timeZoneId: 'America/Los_Angeles',
  },
  '91910': {
    zip: '91910',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.625054',
    longitude: '-117.013699',
    timeZoneId: 'America/Los_Angeles',
  },
  '91911': {
    zip: '91911',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.60808',
    longitude: '-117.053163',
    timeZoneId: 'America/Los_Angeles',
  },
  '91912': {
    zip: '91912',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.639988',
    longitude: '-117.080648',
    timeZoneId: 'America/Los_Angeles',
  },
  '91913': {
    zip: '91913',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.651899',
    longitude: '-116.982772',
    timeZoneId: 'America/Los_Angeles',
  },
  '91914': {
    zip: '91914',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.654876',
    longitude: '-116.95251',
    timeZoneId: 'America/Los_Angeles',
  },
  '91915': {
    zip: '91915',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.62974',
    longitude: '-116.939565',
    timeZoneId: 'America/Los_Angeles',
  },
  '91916': {
    zip: '91916',
    city: 'Descanso',
    state: 'CA',
    latitude: '32.922718',
    longitude: '-116.631458',
    timeZoneId: 'America/Los_Angeles',
  },
  '91917': {
    zip: '91917',
    city: 'Dulzura',
    state: 'CA',
    latitude: '32.607702',
    longitude: '-116.78615',
    timeZoneId: 'America/Los_Angeles',
  },
  '91921': {
    zip: '91921',
    city: 'Chula Vista',
    state: 'CA',
    latitude: '32.656547',
    longitude: '-116.95907',
    timeZoneId: 'America/Los_Angeles',
  },
  '91931': {
    zip: '91931',
    city: 'Guatay',
    state: 'CA',
    latitude: '32.849587',
    longitude: '-116.555021',
    timeZoneId: 'America/Los_Angeles',
  },
  '91932': {
    zip: '91932',
    city: 'Imperial Beach',
    state: 'CA',
    latitude: '32.5747',
    longitude: '-117.116328',
    timeZoneId: 'America/Los_Angeles',
  },
  '91933': {
    zip: '91933',
    city: 'Imperial Beach',
    state: 'CA',
    latitude: '32.582379',
    longitude: '-117.111447',
    timeZoneId: 'America/Los_Angeles',
  },
  '91934': {
    zip: '91934',
    city: 'Jacumba',
    state: 'CA',
    latitude: '32.615886',
    longitude: '-116.187817',
    timeZoneId: 'America/Los_Angeles',
  },
  '91935': {
    zip: '91935',
    city: 'Jamul',
    state: 'CA',
    latitude: '32.685568',
    longitude: '-116.814071',
    timeZoneId: 'America/Los_Angeles',
  },
  '91941': {
    zip: '91941',
    city: 'La Mesa',
    state: 'CA',
    latitude: '32.759711',
    longitude: '-116.997713',
    timeZoneId: 'America/Los_Angeles',
  },
  '91942': {
    zip: '91942',
    city: 'La Mesa',
    state: 'CA',
    latitude: '32.784319',
    longitude: '-117.017783',
    timeZoneId: 'America/Los_Angeles',
  },
  '91943': {
    zip: '91943',
    city: 'La Mesa',
    state: 'CA',
    latitude: '32.777819',
    longitude: '-117.010222',
    timeZoneId: 'America/Los_Angeles',
  },
  '91944': {
    zip: '91944',
    city: 'La Mesa',
    state: 'CA',
    latitude: '32.768983',
    longitude: '-117.021343',
    timeZoneId: 'America/Los_Angeles',
  },
  '91945': {
    zip: '91945',
    city: 'Lemon Grove',
    state: 'CA',
    latitude: '32.735489',
    longitude: '-117.030797',
    timeZoneId: 'America/Los_Angeles',
  },
  '91946': {
    zip: '91946',
    city: 'Lemon Grove',
    state: 'CA',
    latitude: '32.742494',
    longitude: '-117.032654',
    timeZoneId: 'America/Los_Angeles',
  },
  '91948': {
    zip: '91948',
    city: 'Mount Laguna',
    state: 'CA',
    latitude: '32.871478',
    longitude: '-116.415376',
    timeZoneId: 'America/Los_Angeles',
  },
  '91950': {
    zip: '91950',
    city: 'National City',
    state: 'CA',
    latitude: '32.66865',
    longitude: '-117.091284',
    timeZoneId: 'America/Los_Angeles',
  },
  '91951': {
    zip: '91951',
    city: 'National City',
    state: 'CA',
    latitude: '32.679002',
    longitude: '-117.100208',
    timeZoneId: 'America/Los_Angeles',
  },
  '91962': {
    zip: '91962',
    city: 'Pine Valley',
    state: 'CA',
    latitude: '32.820653',
    longitude: '-116.453373',
    timeZoneId: 'America/Los_Angeles',
  },
  '91963': {
    zip: '91963',
    city: 'Potrero',
    state: 'CA',
    latitude: '32.641102',
    longitude: '-116.60758',
    timeZoneId: 'America/Los_Angeles',
  },
  '91976': {
    zip: '91976',
    city: 'Spring Valley',
    state: 'CA',
    latitude: '32.749273',
    longitude: '-116.98731',
    timeZoneId: 'America/Los_Angeles',
  },
  '91977': {
    zip: '91977',
    city: 'Spring Valley',
    state: 'CA',
    latitude: '32.72207',
    longitude: '-116.993911',
    timeZoneId: 'America/Los_Angeles',
  },
  '91978': {
    zip: '91978',
    city: 'Spring Valley',
    state: 'CA',
    latitude: '32.705089',
    longitude: '-116.938685',
    timeZoneId: 'America/Los_Angeles',
  },
  '91979': {
    zip: '91979',
    city: 'Spring Valley',
    state: 'CA',
    latitude: '32.73352',
    longitude: '-116.973098',
    timeZoneId: 'America/Los_Angeles',
  },
  '91980': {
    zip: '91980',
    city: 'Tecate',
    state: 'CA',
    latitude: '32.587669',
    longitude: '-116.662648',
    timeZoneId: 'America/Los_Angeles',
  },
  '91987': {
    zip: '91987',
    city: 'Tecate',
    state: 'CA',
    latitude: '32.592436',
    longitude: '-116.638994',
    timeZoneId: 'America/Los_Angeles',
  },
  '92003': {
    zip: '92003',
    city: 'Bonsall',
    state: 'CA',
    latitude: '33.28472',
    longitude: '-117.19175',
    timeZoneId: 'America/Los_Angeles',
  },
  '92004': {
    zip: '92004',
    city: 'Borrego Springs',
    state: 'CA',
    latitude: '33.236443',
    longitude: '-116.349384',
    timeZoneId: 'America/Los_Angeles',
  },
  '92007': {
    zip: '92007',
    city: 'Cardiff by the Sea',
    state: 'CA',
    latitude: '33.020814',
    longitude: '-117.267083',
    timeZoneId: 'America/Los_Angeles',
  },
  '92008': {
    zip: '92008',
    city: 'Carlsbad',
    state: 'CA',
    latitude: '33.147395',
    longitude: '-117.30515',
    timeZoneId: 'America/Los_Angeles',
  },
  '92009': {
    zip: '92009',
    city: 'Carlsbad',
    state: 'CA',
    latitude: '33.099272',
    longitude: '-117.270924',
    timeZoneId: 'America/Los_Angeles',
  },
  '92010': {
    zip: '92010',
    city: 'Carlsbad',
    state: 'CA',
    latitude: '33.1069',
    longitude: '-117.287',
    timeZoneId: 'America/Los_Angeles',
  },
  '92011': {
    zip: '92011',
    city: 'Carlsbad',
    state: 'CA',
    latitude: '33.1508',
    longitude: '-117.306',
    timeZoneId: 'America/Los_Angeles',
  },
  '92013': {
    zip: '92013',
    city: 'Carlsbad',
    state: 'CA',
    latitude: '33.161398',
    longitude: '-117.349689',
    timeZoneId: 'America/Los_Angeles',
  },
  '92014': {
    zip: '92014',
    city: 'Del Mar',
    state: 'CA',
    latitude: '32.96187',
    longitude: '-117.232665',
    timeZoneId: 'America/Los_Angeles',
  },
  '92018': {
    zip: '92018',
    city: 'Carlsbad',
    state: 'CA',
    latitude: '33.162901',
    longitude: '-117.345744',
    timeZoneId: 'America/Los_Angeles',
  },
  '92019': {
    zip: '92019',
    city: 'El Cajon',
    state: 'CA',
    latitude: '32.782189',
    longitude: '-116.908056',
    timeZoneId: 'America/Los_Angeles',
  },
  '92020': {
    zip: '92020',
    city: 'El Cajon',
    state: 'CA',
    latitude: '32.793886',
    longitude: '-116.965928',
    timeZoneId: 'America/Los_Angeles',
  },
  '92021': {
    zip: '92021',
    city: 'El Cajon',
    state: 'CA',
    latitude: '32.81871',
    longitude: '-116.901089',
    timeZoneId: 'America/Los_Angeles',
  },
  '92022': {
    zip: '92022',
    city: 'El Cajon',
    state: 'CA',
    latitude: '32.730955',
    longitude: '-116.912661',
    timeZoneId: 'America/Los_Angeles',
  },
  '92023': {
    zip: '92023',
    city: 'Encinitas',
    state: 'CA',
    latitude: '33.034774',
    longitude: '-117.293255',
    timeZoneId: 'America/Los_Angeles',
  },
  '92024': {
    zip: '92024',
    city: 'Encinitas',
    state: 'CA',
    latitude: '33.055326',
    longitude: '-117.263733',
    timeZoneId: 'America/Los_Angeles',
  },
  '92025': {
    zip: '92025',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.098448',
    longitude: '-117.084591',
    timeZoneId: 'America/Los_Angeles',
  },
  '92026': {
    zip: '92026',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.1741',
    longitude: '-117.101925',
    timeZoneId: 'America/Los_Angeles',
  },
  '92027': {
    zip: '92027',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.138222',
    longitude: '-117.045024',
    timeZoneId: 'America/Los_Angeles',
  },
  '92028': {
    zip: '92028',
    city: 'Fallbrook',
    state: 'CA',
    latitude: '33.36866',
    longitude: '-117.219178',
    timeZoneId: 'America/Los_Angeles',
  },
  '92029': {
    zip: '92029',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.090581',
    longitude: '-117.108862',
    timeZoneId: 'America/Los_Angeles',
  },
  '92030': {
    zip: '92030',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.135119',
    longitude: '-117.056194',
    timeZoneId: 'America/Los_Angeles',
  },
  '92033': {
    zip: '92033',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.121068',
    longitude: '-117.086889',
    timeZoneId: 'America/Los_Angeles',
  },
  '92036': {
    zip: '92036',
    city: 'Julian',
    state: 'CA',
    latitude: '33.040532',
    longitude: '-116.546326',
    timeZoneId: 'America/Los_Angeles',
  },
  '92037': {
    zip: '92037',
    city: 'La Jolla',
    state: 'CA',
    latitude: '32.838775',
    longitude: '-117.253243',
    timeZoneId: 'America/Los_Angeles',
  },
  '92038': {
    zip: '92038',
    city: 'La Jolla',
    state: 'CA',
    latitude: '32.853445',
    longitude: '-117.251737',
    timeZoneId: 'America/Los_Angeles',
  },
  '92039': {
    zip: '92039',
    city: 'La Jolla',
    state: 'CA',
    latitude: '32.847122',
    longitude: '-117.275759',
    timeZoneId: 'America/Los_Angeles',
  },
  '92040': {
    zip: '92040',
    city: 'Lakeside',
    state: 'CA',
    latitude: '32.861292',
    longitude: '-116.915988',
    timeZoneId: 'America/Los_Angeles',
  },
  '92046': {
    zip: '92046',
    city: 'Escondido',
    state: 'CA',
    latitude: '33.125918',
    longitude: '-117.101637',
    timeZoneId: 'America/Los_Angeles',
  },
  '92049': {
    zip: '92049',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.19482',
    longitude: '-117.379671',
    timeZoneId: 'America/Los_Angeles',
  },
  '92051': {
    zip: '92051',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.201512',
    longitude: '-117.370224',
    timeZoneId: 'America/Los_Angeles',
  },
  '92052': {
    zip: '92052',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.196976',
    longitude: '-117.367059',
    timeZoneId: 'America/Los_Angeles',
  },
  '92054': {
    zip: '92054',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.205217',
    longitude: '-117.352732',
    timeZoneId: 'America/Los_Angeles',
  },
  '92055': {
    zip: '92055',
    city: 'Camp Pendleton',
    state: 'CA',
    latitude: '33.291239',
    longitude: '-117.301241',
    timeZoneId: 'America/Los_Angeles',
  },
  '92056': {
    zip: '92056',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.21463',
    longitude: '-117.289157',
    timeZoneId: 'America/Los_Angeles',
  },
  '92057': {
    zip: '92057',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.240638',
    longitude: '-117.298443',
    timeZoneId: 'America/Los_Angeles',
  },
  '92058': {
    zip: '92058',
    city: 'Oceanside',
    state: 'CA',
    latitude: '33.182717',
    longitude: '-117.295644',
    timeZoneId: 'America/Los_Angeles',
  },
  '92059': {
    zip: '92059',
    city: 'Pala',
    state: 'CA',
    latitude: '33.385181',
    longitude: '-117.069106',
    timeZoneId: 'America/Los_Angeles',
  },
  '92060': {
    zip: '92060',
    city: 'Palomar Mountain',
    state: 'CA',
    latitude: '33.336039',
    longitude: '-116.799561',
    timeZoneId: 'America/Los_Angeles',
  },
  '92061': {
    zip: '92061',
    city: 'Pauma Valley',
    state: 'CA',
    latitude: '33.306639',
    longitude: '-116.974166',
    timeZoneId: 'America/Los_Angeles',
  },
  '92064': {
    zip: '92064',
    city: 'Poway',
    state: 'CA',
    latitude: '32.980878',
    longitude: '-117.036369',
    timeZoneId: 'America/Los_Angeles',
  },
  '92065': {
    zip: '92065',
    city: 'Ramona',
    state: 'CA',
    latitude: '33.026308',
    longitude: '-116.857009',
    timeZoneId: 'America/Los_Angeles',
  },
  '92066': {
    zip: '92066',
    city: 'Ranchita',
    state: 'CA',
    latitude: '33.21924',
    longitude: '-116.52888',
    timeZoneId: 'America/Los_Angeles',
  },
  '92067': {
    zip: '92067',
    city: 'Rancho Santa Fe',
    state: 'CA',
    latitude: '33.011798',
    longitude: '-117.201867',
    timeZoneId: 'America/Los_Angeles',
  },
  '92068': {
    zip: '92068',
    city: 'San Luis Rey',
    state: 'CA',
    latitude: '33.276757',
    longitude: '-117.272701',
    timeZoneId: 'America/Los_Angeles',
  },
  '92069': {
    zip: '92069',
    city: 'San Marcos',
    state: 'CA',
    latitude: '33.149519',
    longitude: '-117.173018',
    timeZoneId: 'America/Los_Angeles',
  },
  '92070': {
    zip: '92070',
    city: 'Santa Ysabel',
    state: 'CA',
    latitude: '33.143858',
    longitude: '-116.712191',
    timeZoneId: 'America/Los_Angeles',
  },
  '92071': {
    zip: '92071',
    city: 'Santee',
    state: 'CA',
    latitude: '32.846307',
    longitude: '-116.988769',
    timeZoneId: 'America/Los_Angeles',
  },
  '92072': {
    zip: '92072',
    city: 'Santee',
    state: 'CA',
    latitude: '32.832358',
    longitude: '-117.038812',
    timeZoneId: 'America/Los_Angeles',
  },
  '92074': {
    zip: '92074',
    city: 'Poway',
    state: 'CA',
    latitude: '32.957495',
    longitude: '-117.034928',
    timeZoneId: 'America/Los_Angeles',
  },
  '92075': {
    zip: '92075',
    city: 'Solana Beach',
    state: 'CA',
    latitude: '32.996744',
    longitude: '-117.256492',
    timeZoneId: 'America/Los_Angeles',
  },
  '92078': {
    zip: '92078',
    city: 'San Marcos',
    state: 'CA',
    latitude: '33.114432',
    longitude: '-117.168445',
    timeZoneId: 'America/Los_Angeles',
  },
  '92079': {
    zip: '92079',
    city: 'San Marcos',
    state: 'CA',
    latitude: '33.142655',
    longitude: '-117.165619',
    timeZoneId: 'America/Los_Angeles',
  },
  '92081': {
    zip: '92081',
    city: 'Vista',
    state: 'CA',
    latitude: '33.192078',
    longitude: '-117.236148',
    timeZoneId: 'America/Los_Angeles',
  },
  '92082': {
    zip: '92082',
    city: 'Valley Center',
    state: 'CA',
    latitude: '33.252897',
    longitude: '-117.036867',
    timeZoneId: 'America/Los_Angeles',
  },
  '92083': {
    zip: '92083',
    city: 'Vista',
    state: 'CA',
    latitude: '33.190177',
    longitude: '-117.234888',
    timeZoneId: 'America/Los_Angeles',
  },
  '92084': {
    zip: '92084',
    city: 'Vista',
    state: 'CA',
    latitude: '33.215973',
    longitude: '-117.217092',
    timeZoneId: 'America/Los_Angeles',
  },
  '92085': {
    zip: '92085',
    city: 'Vista',
    state: 'CA',
    latitude: '33.191119',
    longitude: '-117.228812',
    timeZoneId: 'America/Los_Angeles',
  },
  '92086': {
    zip: '92086',
    city: 'Warner Springs',
    state: 'CA',
    latitude: '33.301094',
    longitude: '-116.673009',
    timeZoneId: 'America/Los_Angeles',
  },
  '92088': {
    zip: '92088',
    city: 'Fallbrook',
    state: 'CA',
    latitude: '33.365635',
    longitude: '-117.248377',
    timeZoneId: 'America/Los_Angeles',
  },
  '92091': {
    zip: '92091',
    city: 'Rancho Santa Fe',
    state: 'CA',
    latitude: '33.01416',
    longitude: '-117.241081',
    timeZoneId: 'America/Los_Angeles',
  },
  '92092': {
    zip: '92092',
    city: 'La Jolla',
    state: 'CA',
    latitude: '32.876604',
    longitude: '-117.232638',
    timeZoneId: 'America/Los_Angeles',
  },
  '92093': {
    zip: '92093',
    city: 'La Jolla',
    state: 'CA',
    latitude: '32.877058',
    longitude: '-117.230578',
    timeZoneId: 'America/Los_Angeles',
  },
  '92096': {
    zip: '92096',
    city: 'San Marcos',
    state: 'CA',
    latitude: '33.133957',
    longitude: '-117.175047',
    timeZoneId: 'America/Los_Angeles',
  },
  '92101': {
    zip: '92101',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.722628',
    longitude: '-117.165331',
    timeZoneId: 'America/Los_Angeles',
  },
  '92102': {
    zip: '92102',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.716263',
    longitude: '-117.11426',
    timeZoneId: 'America/Los_Angeles',
  },
  '92103': {
    zip: '92103',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.749777',
    longitude: '-117.168668',
    timeZoneId: 'America/Los_Angeles',
  },
  '92104': {
    zip: '92104',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.740029',
    longitude: '-117.130447',
    timeZoneId: 'America/Los_Angeles',
  },
  '92105': {
    zip: '92105',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.740294',
    longitude: '-117.091575',
    timeZoneId: 'America/Los_Angeles',
  },
  '92106': {
    zip: '92106',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.725654',
    longitude: '-117.231417',
    timeZoneId: 'America/Los_Angeles',
  },
  '92107': {
    zip: '92107',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.740687',
    longitude: '-117.238741',
    timeZoneId: 'America/Los_Angeles',
  },
  '92108': {
    zip: '92108',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.77193',
    longitude: '-117.135062',
    timeZoneId: 'America/Los_Angeles',
  },
  '92109': {
    zip: '92109',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.791495',
    longitude: '-117.236899',
    timeZoneId: 'America/Los_Angeles',
  },
  '92110': {
    zip: '92110',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.763916',
    longitude: '-117.201402',
    timeZoneId: 'America/Los_Angeles',
  },
  '92111': {
    zip: '92111',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.800458',
    longitude: '-117.171574',
    timeZoneId: 'America/Los_Angeles',
  },
  '92112': {
    zip: '92112',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.725903',
    longitude: '-117.16462',
    timeZoneId: 'America/Los_Angeles',
  },
  '92113': {
    zip: '92113',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.697656',
    longitude: '-117.117688',
    timeZoneId: 'America/Los_Angeles',
  },
  '92114': {
    zip: '92114',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.70713',
    longitude: '-117.051153',
    timeZoneId: 'America/Los_Angeles',
  },
  '92115': {
    zip: '92115',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.761679',
    longitude: '-117.070858',
    timeZoneId: 'America/Los_Angeles',
  },
  '92116': {
    zip: '92116',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.760043',
    longitude: '-117.120973',
    timeZoneId: 'America/Los_Angeles',
  },
  '92117': {
    zip: '92117',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.824555',
    longitude: '-117.200178',
    timeZoneId: 'America/Los_Angeles',
  },
  '92118': {
    zip: '92118',
    city: 'Coronado',
    state: 'CA',
    latitude: '32.65956',
    longitude: '-117.15948',
    timeZoneId: 'America/Los_Angeles',
  },
  '92119': {
    zip: '92119',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.802216',
    longitude: '-117.025108',
    timeZoneId: 'America/Los_Angeles',
  },
  '92120': {
    zip: '92120',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.795177',
    longitude: '-117.068719',
    timeZoneId: 'America/Los_Angeles',
  },
  '92121': {
    zip: '92121',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.894055',
    longitude: '-117.202589',
    timeZoneId: 'America/Los_Angeles',
  },
  '92122': {
    zip: '92122',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.854723',
    longitude: '-117.209215',
    timeZoneId: 'America/Los_Angeles',
  },
  '92123': {
    zip: '92123',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.800916',
    longitude: '-117.137146',
    timeZoneId: 'America/Los_Angeles',
  },
  '92124': {
    zip: '92124',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.82343',
    longitude: '-117.097296',
    timeZoneId: 'America/Los_Angeles',
  },
  '92126': {
    zip: '92126',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.912763',
    longitude: '-117.148011',
    timeZoneId: 'America/Los_Angeles',
  },
  '92127': {
    zip: '92127',
    city: 'San Diego',
    state: 'CA',
    latitude: '33.024076',
    longitude: '-117.103864',
    timeZoneId: 'America/Los_Angeles',
  },
  '92128': {
    zip: '92128',
    city: 'San Diego',
    state: 'CA',
    latitude: '33.010498',
    longitude: '-117.070512',
    timeZoneId: 'America/Los_Angeles',
  },
  '92129': {
    zip: '92129',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.966476',
    longitude: '-117.119682',
    timeZoneId: 'America/Los_Angeles',
  },
  '92130': {
    zip: '92130',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.951538',
    longitude: '-117.222721',
    timeZoneId: 'America/Los_Angeles',
  },
  '92131': {
    zip: '92131',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.909138',
    longitude: '-117.08669',
    timeZoneId: 'America/Los_Angeles',
  },
  '92132': {
    zip: '92132',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.719559',
    longitude: '-117.166765',
    timeZoneId: 'America/Los_Angeles',
  },
  '92134': {
    zip: '92134',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.727421',
    longitude: '-117.14706',
    timeZoneId: 'America/Los_Angeles',
  },
  '92135': {
    zip: '92135',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.699246',
    longitude: '-117.185174',
    timeZoneId: 'America/Los_Angeles',
  },
  '92136': {
    zip: '92136',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.678744',
    longitude: '-117.119958',
    timeZoneId: 'America/Los_Angeles',
  },
  '92137': {
    zip: '92137',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.8862',
    longitude: '-117.101607',
    timeZoneId: 'America/Los_Angeles',
  },
  '92138': {
    zip: '92138',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.762758',
    longitude: '-117.200398',
    timeZoneId: 'America/Los_Angeles',
  },
  '92139': {
    zip: '92139',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.675725',
    longitude: '-117.049586',
    timeZoneId: 'America/Los_Angeles',
  },
  '92140': {
    zip: '92140',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.741172',
    longitude: '-117.196705',
    timeZoneId: 'America/Los_Angeles',
  },
  '92142': {
    zip: '92142',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.842037',
    longitude: '-117.096454',
    timeZoneId: 'America/Los_Angeles',
  },
  '92143': {
    zip: '92143',
    city: 'San Ysidro',
    state: 'CA',
    latitude: '32.567534',
    longitude: '-116.974021',
    timeZoneId: 'America/Los_Angeles',
  },
  '92145': {
    zip: '92145',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.874445',
    longitude: '-117.152969',
    timeZoneId: 'America/Los_Angeles',
  },
  '92147': {
    zip: '92147',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.696705',
    longitude: '-117.203572',
    timeZoneId: 'America/Los_Angeles',
  },
  '92149': {
    zip: '92149',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.676375',
    longitude: '-117.065268',
    timeZoneId: 'America/Los_Angeles',
  },
  '92150': {
    zip: '92150',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.986294',
    longitude: '-117.081364',
    timeZoneId: 'America/Los_Angeles',
  },
  '92152': {
    zip: '92152',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.691593',
    longitude: '-117.247143',
    timeZoneId: 'America/Los_Angeles',
  },
  '92153': {
    zip: '92153',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.567607',
    longitude: '-117.08339',
    timeZoneId: 'America/Los_Angeles',
  },
  '92154': {
    zip: '92154',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.578188',
    longitude: '-117.072374',
    timeZoneId: 'America/Los_Angeles',
  },
  '92155': {
    zip: '92155',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.674947',
    longitude: '-117.16083',
    timeZoneId: 'America/Los_Angeles',
  },
  '92158': {
    zip: '92158',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.563682',
    longitude: '-116.972887',
    timeZoneId: 'America/Los_Angeles',
  },
  '92159': {
    zip: '92159',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.806702',
    longitude: '-117.02592',
    timeZoneId: 'America/Los_Angeles',
  },
  '92160': {
    zip: '92160',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.783954',
    longitude: '-117.0935',
    timeZoneId: 'America/Los_Angeles',
  },
  '92161': {
    zip: '92161',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.879947',
    longitude: '-117.228751',
    timeZoneId: 'America/Los_Angeles',
  },
  '92163': {
    zip: '92163',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.747287',
    longitude: '-117.171093',
    timeZoneId: 'America/Los_Angeles',
  },
  '92165': {
    zip: '92165',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.75196',
    longitude: '-117.10224',
    timeZoneId: 'America/Los_Angeles',
  },
  '92166': {
    zip: '92166',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.72166',
    longitude: '-117.231158',
    timeZoneId: 'America/Los_Angeles',
  },
  '92167': {
    zip: '92167',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.746726',
    longitude: '-117.244389',
    timeZoneId: 'America/Los_Angeles',
  },
  '92168': {
    zip: '92168',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.76684',
    longitude: '-117.153066',
    timeZoneId: 'America/Los_Angeles',
  },
  '92169': {
    zip: '92169',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.799866',
    longitude: '-117.25117',
    timeZoneId: 'America/Los_Angeles',
  },
  '92170': {
    zip: '92170',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.695868',
    longitude: '-117.13559',
    timeZoneId: 'America/Los_Angeles',
  },
  '92171': {
    zip: '92171',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.781734',
    longitude: '-117.17153',
    timeZoneId: 'America/Los_Angeles',
  },
  '92172': {
    zip: '92172',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.96459',
    longitude: '-117.136931',
    timeZoneId: 'America/Los_Angeles',
  },
  '92173': {
    zip: '92173',
    city: 'San Ysidro',
    state: 'CA',
    latitude: '32.56013',
    longitude: '-116.97436',
    timeZoneId: 'America/Los_Angeles',
  },
  '92174': {
    zip: '92174',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.705682',
    longitude: '-117.084537',
    timeZoneId: 'America/Los_Angeles',
  },
  '92175': {
    zip: '92175',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.765448',
    longitude: '-117.059897',
    timeZoneId: 'America/Los_Angeles',
  },
  '92176': {
    zip: '92176',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.761794',
    longitude: '-117.120515',
    timeZoneId: 'America/Los_Angeles',
  },
  '92177': {
    zip: '92177',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.823966',
    longitude: '-117.198462',
    timeZoneId: 'America/Los_Angeles',
  },
  '92178': {
    zip: '92178',
    city: 'Coronado',
    state: 'CA',
    latitude: '32.681525',
    longitude: '-117.1759',
    timeZoneId: 'America/Los_Angeles',
  },
  '92179': {
    zip: '92179',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.567887',
    longitude: '-116.970748',
    timeZoneId: 'America/Los_Angeles',
  },
  '92182': {
    zip: '92182',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.762178',
    longitude: '-117.069156',
    timeZoneId: 'America/Los_Angeles',
  },
  '92186': {
    zip: '92186',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.747783',
    longitude: '-117.202029',
    timeZoneId: 'America/Los_Angeles',
  },
  '92187': {
    zip: '92187',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.724875',
    longitude: '-117.166229',
    timeZoneId: 'America/Los_Angeles',
  },
  '92191': {
    zip: '92191',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.904114',
    longitude: '-117.21699',
    timeZoneId: 'America/Los_Angeles',
  },
  '92192': {
    zip: '92192',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.854108',
    longitude: '-117.211801',
    timeZoneId: 'America/Los_Angeles',
  },
  '92193': {
    zip: '92193',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.800654',
    longitude: '-117.141627',
    timeZoneId: 'America/Los_Angeles',
  },
  '92195': {
    zip: '92195',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.744302',
    longitude: '-117.050496',
    timeZoneId: 'America/Los_Angeles',
  },
  '92196': {
    zip: '92196',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.914241',
    longitude: '-117.126252',
    timeZoneId: 'America/Los_Angeles',
  },
  '92197': {
    zip: '92197',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.831074',
    longitude: '-117.199135',
    timeZoneId: 'America/Los_Angeles',
  },
  '92198': {
    zip: '92198',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.997052',
    longitude: '-117.070698',
    timeZoneId: 'America/Los_Angeles',
  },
  '92199': {
    zip: '92199',
    city: 'San Diego',
    state: 'CA',
    latitude: '32.980136',
    longitude: '-117.082337',
    timeZoneId: 'America/Los_Angeles',
  },
  '92201': {
    zip: '92201',
    city: 'Indio',
    state: 'CA',
    latitude: '33.707812',
    longitude: '-116.231849',
    timeZoneId: 'America/Los_Angeles',
  },
  '92202': {
    zip: '92202',
    city: 'Indio',
    state: 'CA',
    latitude: '33.661343',
    longitude: '-116.297796',
    timeZoneId: 'America/Los_Angeles',
  },
  '92203': {
    zip: '92203',
    city: 'Indio',
    state: 'CA',
    latitude: '33.75558',
    longitude: '-116.226718',
    timeZoneId: 'America/Los_Angeles',
  },
  '92210': {
    zip: '92210',
    city: 'Indian Wells',
    state: 'CA',
    latitude: '33.715709',
    longitude: '-116.334198',
    timeZoneId: 'America/Los_Angeles',
  },
  '92211': {
    zip: '92211',
    city: 'Palm Desert',
    state: 'CA',
    latitude: '33.746188',
    longitude: '-116.330491',
    timeZoneId: 'America/Los_Angeles',
  },
  '92220': {
    zip: '92220',
    city: 'Banning',
    state: 'CA',
    latitude: '33.931597',
    longitude: '-116.885541',
    timeZoneId: 'America/Los_Angeles',
  },
  '92222': {
    zip: '92222',
    city: 'Bard',
    state: 'CA',
    latitude: '32.795556',
    longitude: '-114.531521',
    timeZoneId: 'America/Los_Angeles',
  },
  '92223': {
    zip: '92223',
    city: 'Beaumont',
    state: 'CA',
    latitude: '33.952498',
    longitude: '-116.973803',
    timeZoneId: 'America/Los_Angeles',
  },
  '92225': {
    zip: '92225',
    city: 'Blythe',
    state: 'CA',
    latitude: '33.609354',
    longitude: '-114.615439',
    timeZoneId: 'America/Los_Angeles',
  },
  '92226': {
    zip: '92226',
    city: 'Blythe',
    state: 'CA',
    latitude: '33.606806',
    longitude: '-114.58789',
    timeZoneId: 'America/Los_Angeles',
  },
  '92227': {
    zip: '92227',
    city: 'Brawley',
    state: 'CA',
    latitude: '32.98851',
    longitude: '-115.521752',
    timeZoneId: 'America/Los_Angeles',
  },
  '92230': {
    zip: '92230',
    city: 'Cabazon',
    state: 'CA',
    latitude: '33.91305',
    longitude: '-116.780677',
    timeZoneId: 'America/Los_Angeles',
  },
  '92231': {
    zip: '92231',
    city: 'Calexico',
    state: 'CA',
    latitude: '32.691821',
    longitude: '-115.504813',
    timeZoneId: 'America/Los_Angeles',
  },
  '92232': {
    zip: '92232',
    city: 'Calexico',
    state: 'CA',
    latitude: '32.679271',
    longitude: '-115.503159',
    timeZoneId: 'America/Los_Angeles',
  },
  '92233': {
    zip: '92233',
    city: 'Calipatria',
    state: 'CA',
    latitude: '33.133447',
    longitude: '-115.507456',
    timeZoneId: 'America/Los_Angeles',
  },
  '92234': {
    zip: '92234',
    city: 'Cathedral City',
    state: 'CA',
    latitude: '33.822485',
    longitude: '-116.462762',
    timeZoneId: 'America/Los_Angeles',
  },
  '92235': {
    zip: '92235',
    city: 'Cathedral City',
    state: 'CA',
    latitude: '33.780606',
    longitude: '-116.461273',
    timeZoneId: 'America/Los_Angeles',
  },
  '92236': {
    zip: '92236',
    city: 'Coachella',
    state: 'CA',
    latitude: '33.68036',
    longitude: '-116.178246',
    timeZoneId: 'America/Los_Angeles',
  },
  '92239': {
    zip: '92239',
    city: 'Desert Center',
    state: 'CA',
    latitude: '33.7125',
    longitude: '-115.401389',
    timeZoneId: 'America/Los_Angeles',
  },
  '92240': {
    zip: '92240',
    city: 'Desert Hot Springs',
    state: 'CA',
    latitude: '33.963925',
    longitude: '-116.531153',
    timeZoneId: 'America/Los_Angeles',
  },
  '92241': {
    zip: '92241',
    city: 'Desert Hot Springs',
    state: 'CA',
    latitude: '33.899772',
    longitude: '-116.390191',
    timeZoneId: 'America/Los_Angeles',
  },
  '92242': {
    zip: '92242',
    city: 'Earp',
    state: 'CA',
    latitude: '34.15302',
    longitude: '-114.37782',
    timeZoneId: 'America/Los_Angeles',
  },
  '92243': {
    zip: '92243',
    city: 'El Centro',
    state: 'CA',
    latitude: '32.784553',
    longitude: '-115.571017',
    timeZoneId: 'America/Los_Angeles',
  },
  '92244': {
    zip: '92244',
    city: 'El Centro',
    state: 'CA',
    latitude: '32.794254',
    longitude: '-115.57928',
    timeZoneId: 'America/Los_Angeles',
  },
  '92247': {
    zip: '92247',
    city: 'La Quinta',
    state: 'CA',
    latitude: '33.673645',
    longitude: '-116.295092',
    timeZoneId: 'America/Los_Angeles',
  },
  '92248': {
    zip: '92248',
    city: 'La Quinta',
    state: 'CA',
    latitude: '33.675895',
    longitude: '-116.303058',
    timeZoneId: 'America/Los_Angeles',
  },
  '92249': {
    zip: '92249',
    city: 'Heber',
    state: 'CA',
    latitude: '32.724409',
    longitude: '-115.495553',
    timeZoneId: 'America/Los_Angeles',
  },
  '92250': {
    zip: '92250',
    city: 'Holtville',
    state: 'CA',
    latitude: '32.808254',
    longitude: '-115.375136',
    timeZoneId: 'America/Los_Angeles',
  },
  '92251': {
    zip: '92251',
    city: 'Imperial',
    state: 'CA',
    latitude: '32.849051',
    longitude: '-115.586227',
    timeZoneId: 'America/Los_Angeles',
  },
  '92252': {
    zip: '92252',
    city: 'Joshua Tree',
    state: 'CA',
    latitude: '34.159479',
    longitude: '-116.297129',
    timeZoneId: 'America/Los_Angeles',
  },
  '92253': {
    zip: '92253',
    city: 'La Quinta',
    state: 'CA',
    latitude: '33.671158',
    longitude: '-116.294219',
    timeZoneId: 'America/Los_Angeles',
  },
  '92254': {
    zip: '92254',
    city: 'Mecca',
    state: 'CA',
    latitude: '33.553183',
    longitude: '-116.050757',
    timeZoneId: 'America/Los_Angeles',
  },
  '92255': {
    zip: '92255',
    city: 'Palm Desert',
    state: 'CA',
    latitude: '33.746759',
    longitude: '-116.366511',
    timeZoneId: 'America/Los_Angeles',
  },
  '92256': {
    zip: '92256',
    city: 'Morongo Valley',
    state: 'CA',
    latitude: '34.080121',
    longitude: '-116.552087',
    timeZoneId: 'America/Los_Angeles',
  },
  '92257': {
    zip: '92257',
    city: 'Niland',
    state: 'CA',
    latitude: '33.32856',
    longitude: '-115.5942',
    timeZoneId: 'America/Los_Angeles',
  },
  '92258': {
    zip: '92258',
    city: 'North Palm Springs',
    state: 'CA',
    latitude: '33.922919',
    longitude: '-116.549446',
    timeZoneId: 'America/Los_Angeles',
  },
  '92259': {
    zip: '92259',
    city: 'Ocotillo',
    state: 'CA',
    latitude: '32.728348',
    longitude: '-115.953395',
    timeZoneId: 'America/Los_Angeles',
  },
  '92260': {
    zip: '92260',
    city: 'Palm Desert',
    state: 'CA',
    latitude: '33.727749',
    longitude: '-116.371851',
    timeZoneId: 'America/Los_Angeles',
  },
  '92261': {
    zip: '92261',
    city: 'Palm Desert',
    state: 'CA',
    latitude: '33.617682',
    longitude: '-116.430261',
    timeZoneId: 'America/Los_Angeles',
  },
  '92262': {
    zip: '92262',
    city: 'Palm Springs',
    state: 'CA',
    latitude: '33.856934',
    longitude: '-116.563137',
    timeZoneId: 'America/Los_Angeles',
  },
  '92263': {
    zip: '92263',
    city: 'Palm Springs',
    state: 'CA',
    latitude: '33.875893',
    longitude: '-116.566292',
    timeZoneId: 'America/Los_Angeles',
  },
  '92264': {
    zip: '92264',
    city: 'Palm Springs',
    state: 'CA',
    latitude: '33.800656',
    longitude: '-116.517356',
    timeZoneId: 'America/Los_Angeles',
  },
  '92266': {
    zip: '92266',
    city: 'Palo Verde',
    state: 'CA',
    latitude: '33.427558',
    longitude: '-114.728608',
    timeZoneId: 'America/Los_Angeles',
  },
  '92267': {
    zip: '92267',
    city: 'Parker Dam',
    state: 'CA',
    latitude: '34.29593',
    longitude: '-114.189406',
    timeZoneId: 'America/Los_Angeles',
  },
  '92268': {
    zip: '92268',
    city: 'Pioneertown',
    state: 'CA',
    latitude: '34.184357',
    longitude: '-116.516406',
    timeZoneId: 'America/Los_Angeles',
  },
  '92270': {
    zip: '92270',
    city: 'Rancho Mirage',
    state: 'CA',
    latitude: '33.766858',
    longitude: '-116.424596',
    timeZoneId: 'America/Los_Angeles',
  },
  '92273': {
    zip: '92273',
    city: 'Seeley',
    state: 'CA',
    latitude: '32.788008',
    longitude: '-115.690304',
    timeZoneId: 'America/Los_Angeles',
  },
  '92274': {
    zip: '92274',
    city: 'Thermal',
    state: 'CA',
    latitude: '33.60904',
    longitude: '-116.16206',
    timeZoneId: 'America/Los_Angeles',
  },
  '92275': {
    zip: '92275',
    city: 'Salton City',
    state: 'CA',
    latitude: '33.640389',
    longitude: '-116.141879',
    timeZoneId: 'America/Los_Angeles',
  },
  '92276': {
    zip: '92276',
    city: 'Thousand Palms',
    state: 'CA',
    latitude: '33.810639',
    longitude: '-116.376227',
    timeZoneId: 'America/Los_Angeles',
  },
  '92277': {
    zip: '92277',
    city: 'Twentynine Palms',
    state: 'CA',
    latitude: '34.161757',
    longitude: '-116.045717',
    timeZoneId: 'America/Los_Angeles',
  },
  '92278': {
    zip: '92278',
    city: 'Twentynine Palms',
    state: 'CA',
    latitude: '34.27332',
    longitude: '-116.10246',
    timeZoneId: 'America/Los_Angeles',
  },
  '92280': {
    zip: '92280',
    city: 'Vidal',
    state: 'CA',
    latitude: '34.191918',
    longitude: '-114.553587',
    timeZoneId: 'America/Los_Angeles',
  },
  '92281': {
    zip: '92281',
    city: 'Westmorland',
    state: 'CA',
    latitude: '33.034322',
    longitude: '-115.611218',
    timeZoneId: 'America/Los_Angeles',
  },
  '92282': {
    zip: '92282',
    city: 'Whitewater',
    state: 'CA',
    latitude: '33.933154',
    longitude: '-116.693211',
    timeZoneId: 'America/Los_Angeles',
  },
  '92283': {
    zip: '92283',
    city: 'Winterhaven',
    state: 'CA',
    latitude: '32.776447',
    longitude: '-114.598015',
    timeZoneId: 'America/Los_Angeles',
  },
  '92284': {
    zip: '92284',
    city: 'Yucca Valley',
    state: 'CA',
    latitude: '34.177234',
    longitude: '-116.43189',
    timeZoneId: 'America/Los_Angeles',
  },
  '92285': {
    zip: '92285',
    city: 'Landers',
    state: 'CA',
    latitude: '34.31364',
    longitude: '-116.448',
    timeZoneId: 'America/Los_Angeles',
  },
  '92286': {
    zip: '92286',
    city: 'Yucca Valley',
    state: 'CA',
    latitude: '34.184966',
    longitude: '-116.406627',
    timeZoneId: 'America/Los_Angeles',
  },
  '92301': {
    zip: '92301',
    city: 'Adelanto',
    state: 'CA',
    latitude: '34.589414',
    longitude: '-117.479821',
    timeZoneId: 'America/Los_Angeles',
  },
  '92304': {
    zip: '92304',
    city: 'Amboy',
    state: 'CA',
    latitude: '34.634003',
    longitude: '-115.751436',
    timeZoneId: 'America/Los_Angeles',
  },
  '92305': {
    zip: '92305',
    city: 'Angelus Oaks',
    state: 'CA',
    latitude: '34.113181',
    longitude: '-117.029126',
    timeZoneId: 'America/Los_Angeles',
  },
  '92307': {
    zip: '92307',
    city: 'Apple Valley',
    state: 'CA',
    latitude: '34.524715',
    longitude: '-117.197826',
    timeZoneId: 'America/Los_Angeles',
  },
  '92308': {
    zip: '92308',
    city: 'Apple Valley',
    state: 'CA',
    latitude: '34.460602',
    longitude: '-117.192247',
    timeZoneId: 'America/Los_Angeles',
  },
  '92309': {
    zip: '92309',
    city: 'Baker',
    state: 'CA',
    latitude: '35.273234',
    longitude: '-116.06733',
    timeZoneId: 'America/Los_Angeles',
  },
  '92310': {
    zip: '92310',
    city: 'Fort Irwin',
    state: 'CA',
    latitude: '35.343124',
    longitude: '-116.617236',
    timeZoneId: 'America/Los_Angeles',
  },
  '92311': {
    zip: '92311',
    city: 'Barstow',
    state: 'CA',
    latitude: '34.887322',
    longitude: '-117.037597',
    timeZoneId: 'America/Los_Angeles',
  },
  '92312': {
    zip: '92312',
    city: 'Barstow',
    state: 'CA',
    latitude: '34.976965',
    longitude: '-117.060012',
    timeZoneId: 'America/Los_Angeles',
  },
  '92313': {
    zip: '92313',
    city: 'Grand Terrace',
    state: 'CA',
    latitude: '34.061014',
    longitude: '-117.324151',
    timeZoneId: 'America/Los_Angeles',
  },
  '92314': {
    zip: '92314',
    city: 'Big Bear City',
    state: 'CA',
    latitude: '34.255983',
    longitude: '-116.837555',
    timeZoneId: 'America/Los_Angeles',
  },
  '92315': {
    zip: '92315',
    city: 'Big Bear Lake',
    state: 'CA',
    latitude: '34.24467',
    longitude: '-116.890676',
    timeZoneId: 'America/Los_Angeles',
  },
  '92316': {
    zip: '92316',
    city: 'Bloomington',
    state: 'CA',
    latitude: '34.069928',
    longitude: '-117.397247',
    timeZoneId: 'America/Los_Angeles',
  },
  '92317': {
    zip: '92317',
    city: 'Blue Jay',
    state: 'CA',
    latitude: '34.274933',
    longitude: '-117.204147',
    timeZoneId: 'America/Los_Angeles',
  },
  '92318': {
    zip: '92318',
    city: 'Bryn Mawr',
    state: 'CA',
    latitude: '34.048366',
    longitude: '-117.233667',
    timeZoneId: 'America/Los_Angeles',
  },
  '92320': {
    zip: '92320',
    city: 'Calimesa',
    state: 'CA',
    latitude: '33.99245',
    longitude: '-117.043277',
    timeZoneId: 'America/Los_Angeles',
  },
  '92321': {
    zip: '92321',
    city: 'Cedar Glen',
    state: 'CA',
    latitude: '34.254999',
    longitude: '-117.170783',
    timeZoneId: 'America/Los_Angeles',
  },
  '92322': {
    zip: '92322',
    city: 'Cedarpines Park',
    state: 'CA',
    latitude: '34.252934',
    longitude: '-117.3242',
    timeZoneId: 'America/Los_Angeles',
  },
  '92323': {
    zip: '92323',
    city: 'Cima',
    state: 'CA',
    latitude: '35.236266',
    longitude: '-115.498775',
    timeZoneId: 'America/Los_Angeles',
  },
  '92324': {
    zip: '92324',
    city: 'Colton',
    state: 'CA',
    latitude: '34.03242',
    longitude: '-117.2823',
    timeZoneId: 'America/Los_Angeles',
  },
  '92325': {
    zip: '92325',
    city: 'Crestline',
    state: 'CA',
    latitude: '34.241417',
    longitude: '-117.277125',
    timeZoneId: 'America/Los_Angeles',
  },
  '92327': {
    zip: '92327',
    city: 'Daggett',
    state: 'CA',
    latitude: '34.851756',
    longitude: '-116.826854',
    timeZoneId: 'America/Los_Angeles',
  },
  '92328': {
    zip: '92328',
    city: 'Death Valley',
    state: 'CA',
    latitude: '36.425648',
    longitude: '-117.090213',
    timeZoneId: 'America/Los_Angeles',
  },
  '92329': {
    zip: '92329',
    city: 'Phelan',
    state: 'CA',
    latitude: '34.355142',
    longitude: '-117.607156',
    timeZoneId: 'America/Los_Angeles',
  },
  '92331': {
    zip: '92331',
    city: 'Fontana',
    state: 'CA',
    latitude: '34.092178',
    longitude: '-117.460591',
    timeZoneId: 'America/Los_Angeles',
  },
  '92332': {
    zip: '92332',
    city: 'Essex',
    state: 'CA',
    latitude: '34.822579',
    longitude: '-115.069722',
    timeZoneId: 'America/Los_Angeles',
  },
  '92333': {
    zip: '92333',
    city: 'Fawnskin',
    state: 'CA',
    latitude: '34.266659',
    longitude: '-116.941287',
    timeZoneId: 'America/Los_Angeles',
  },
  '92334': {
    zip: '92334',
    city: 'Fontana',
    state: 'CA',
    latitude: '34.07503',
    longitude: '-117.469021',
    timeZoneId: 'America/Los_Angeles',
  },
  '92335': {
    zip: '92335',
    city: 'Fontana',
    state: 'CA',
    latitude: '34.093648',
    longitude: '-117.460417',
    timeZoneId: 'America/Los_Angeles',
  },
  '92336': {
    zip: '92336',
    city: 'Fontana',
    state: 'CA',
    latitude: '34.132487',
    longitude: '-117.459265',
    timeZoneId: 'America/Los_Angeles',
  },
  '92337': {
    zip: '92337',
    city: 'Fontana',
    state: 'CA',
    latitude: '34.04456',
    longitude: '-117.485731',
    timeZoneId: 'America/Los_Angeles',
  },
  '92338': {
    zip: '92338',
    city: 'Ludlow',
    state: 'CA',
    latitude: '34.721111',
    longitude: '-116.159167',
    timeZoneId: 'America/Los_Angeles',
  },
  '92339': {
    zip: '92339',
    city: 'Forest Falls',
    state: 'CA',
    latitude: '34.064027',
    longitude: '-117.035983',
    timeZoneId: 'America/Los_Angeles',
  },
  '92340': {
    zip: '92340',
    city: 'Hesperia',
    state: 'CA',
    latitude: '34.420224',
    longitude: '-117.276583',
    timeZoneId: 'America/Los_Angeles',
  },
  '92341': {
    zip: '92341',
    city: 'Green Valley Lake',
    state: 'CA',
    latitude: '34.241861',
    longitude: '-117.075564',
    timeZoneId: 'America/Los_Angeles',
  },
  '92342': {
    zip: '92342',
    city: 'Helendale',
    state: 'CA',
    latitude: '34.6884',
    longitude: '-117.29022',
    timeZoneId: 'America/Los_Angeles',
  },
  '92344': {
    zip: '92344',
    city: 'Hesperia',
    state: 'CA',
    latitude: '34.3973',
    longitude: '-117.3994',
    timeZoneId: 'America/Los_Angeles',
  },
  '92345': {
    zip: '92345',
    city: 'Hesperia',
    state: 'CA',
    latitude: '34.416478',
    longitude: '-117.307226',
    timeZoneId: 'America/Los_Angeles',
  },
  '92346': {
    zip: '92346',
    city: 'Highland',
    state: 'CA',
    latitude: '34.129046',
    longitude: '-117.19692',
    timeZoneId: 'America/Los_Angeles',
  },
  '92347': {
    zip: '92347',
    city: 'Hinkley',
    state: 'CA',
    latitude: '34.938977',
    longitude: '-117.196307',
    timeZoneId: 'America/Los_Angeles',
  },
  '92350': {
    zip: '92350',
    city: 'Loma Linda',
    state: 'CA',
    latitude: '34.052083',
    longitude: '-117.263767',
    timeZoneId: 'America/Los_Angeles',
  },
  '92352': {
    zip: '92352',
    city: 'Lake Arrowhead',
    state: 'CA',
    latitude: '34.257718',
    longitude: '-117.190169',
    timeZoneId: 'America/Los_Angeles',
  },
  '92354': {
    zip: '92354',
    city: 'Loma Linda',
    state: 'CA',
    latitude: '34.052344',
    longitude: '-117.250346',
    timeZoneId: 'America/Los_Angeles',
  },
  '92356': {
    zip: '92356',
    city: 'Lucerne Valley',
    state: 'CA',
    latitude: '34.453374',
    longitude: '-116.918928',
    timeZoneId: 'America/Los_Angeles',
  },
  '92357': {
    zip: '92357',
    city: 'Loma Linda',
    state: 'CA',
    latitude: '34.054612',
    longitude: '-117.252812',
    timeZoneId: 'America/Los_Angeles',
  },
  '92358': {
    zip: '92358',
    city: 'Lytle Creek',
    state: 'CA',
    latitude: '34.275929',
    longitude: '-117.531486',
    timeZoneId: 'America/Los_Angeles',
  },
  '92359': {
    zip: '92359',
    city: 'Mentone',
    state: 'CA',
    latitude: '34.073506',
    longitude: '-117.106254',
    timeZoneId: 'America/Los_Angeles',
  },
  '92363': {
    zip: '92363',
    city: 'Needles',
    state: 'CA',
    latitude: '34.833512',
    longitude: '-114.602406',
    timeZoneId: 'America/Los_Angeles',
  },
  '92364': {
    zip: '92364',
    city: 'Nipton',
    state: 'CA',
    latitude: '35.466595',
    longitude: '-115.482713',
    timeZoneId: 'America/Los_Angeles',
  },
  '92365': {
    zip: '92365',
    city: 'Newberry Springs',
    state: 'CA',
    latitude: '34.873491',
    longitude: '-116.656202',
    timeZoneId: 'America/Los_Angeles',
  },
  '92366': {
    zip: '92366',
    city: 'Mountain Pass',
    state: 'CA',
    latitude: '35.472787',
    longitude: '-115.527445',
    timeZoneId: 'America/Los_Angeles',
  },
  '92368': {
    zip: '92368',
    city: 'Oro Grande',
    state: 'CA',
    latitude: '34.65969',
    longitude: '-117.295181',
    timeZoneId: 'America/Los_Angeles',
  },
  '92369': {
    zip: '92369',
    city: 'Patton',
    state: 'CA',
    latitude: '34.133473',
    longitude: '-117.221198',
    timeZoneId: 'America/Los_Angeles',
  },
  '92371': {
    zip: '92371',
    city: 'Phelan',
    state: 'CA',
    latitude: '34.477063',
    longitude: '-117.534399',
    timeZoneId: 'America/Los_Angeles',
  },
  '92372': {
    zip: '92372',
    city: 'Pinon Hills',
    state: 'CA',
    latitude: '34.442809',
    longitude: '-117.636179',
    timeZoneId: 'America/Los_Angeles',
  },
  '92373': {
    zip: '92373',
    city: 'Redlands',
    state: 'CA',
    latitude: '34.050061',
    longitude: '-117.169355',
    timeZoneId: 'America/Los_Angeles',
  },
  '92374': {
    zip: '92374',
    city: 'Redlands',
    state: 'CA',
    latitude: '34.065425',
    longitude: '-117.16807',
    timeZoneId: 'America/Los_Angeles',
  },
  '92375': {
    zip: '92375',
    city: 'Redlands',
    state: 'CA',
    latitude: '34.062806',
    longitude: '-117.195587',
    timeZoneId: 'America/Los_Angeles',
  },
  '92376': {
    zip: '92376',
    city: 'Rialto',
    state: 'CA',
    latitude: '34.117034',
    longitude: '-117.376352',
    timeZoneId: 'America/Los_Angeles',
  },
  '92377': {
    zip: '92377',
    city: 'Rialto',
    state: 'CA',
    latitude: '34.150712',
    longitude: '-117.407159',
    timeZoneId: 'America/Los_Angeles',
  },
  '92378': {
    zip: '92378',
    city: 'Rimforest',
    state: 'CA',
    latitude: '34.2103',
    longitude: '-117.241852',
    timeZoneId: 'America/Los_Angeles',
  },
  '92382': {
    zip: '92382',
    city: 'Running Springs',
    state: 'CA',
    latitude: '34.206744',
    longitude: '-117.104968',
    timeZoneId: 'America/Los_Angeles',
  },
  '92384': {
    zip: '92384',
    city: 'Shoshone',
    state: 'CA',
    latitude: '36.188188',
    longitude: '-116.410849',
    timeZoneId: 'America/Los_Angeles',
  },
  '92385': {
    zip: '92385',
    city: 'Skyforest',
    state: 'CA',
    latitude: '34.232939',
    longitude: '-117.184281',
    timeZoneId: 'America/Los_Angeles',
  },
  '92386': {
    zip: '92386',
    city: 'Sugarloaf',
    state: 'CA',
    latitude: '34.261111',
    longitude: '-116.844167',
    timeZoneId: 'America/Los_Angeles',
  },
  '92389': {
    zip: '92389',
    city: 'Tecopa',
    state: 'CA',
    latitude: '35.948545',
    longitude: '-115.897853',
    timeZoneId: 'America/Los_Angeles',
  },
  '92391': {
    zip: '92391',
    city: 'Twin Peaks',
    state: 'CA',
    latitude: '34.246725',
    longitude: '-117.226889',
    timeZoneId: 'America/Los_Angeles',
  },
  '92392': {
    zip: '92392',
    city: 'Victorville',
    state: 'CA',
    latitude: '34.510298',
    longitude: '-117.31839',
    timeZoneId: 'America/Los_Angeles',
  },
  '92393': {
    zip: '92393',
    city: 'Victorville',
    state: 'CA',
    latitude: '34.483066',
    longitude: '-117.312486',
    timeZoneId: 'America/Los_Angeles',
  },
  '92394': {
    zip: '92394',
    city: 'Victorville',
    state: 'CA',
    latitude: '34.585299',
    longitude: '-117.369903',
    timeZoneId: 'America/Los_Angeles',
  },
  '92395': {
    zip: '92395',
    city: 'Victorville',
    state: 'CA',
    latitude: '34.4862',
    longitude: '-117.3661',
    timeZoneId: 'America/Los_Angeles',
  },
  '92397': {
    zip: '92397',
    city: 'Wrightwood',
    state: 'CA',
    latitude: '34.368657',
    longitude: '-117.60967',
    timeZoneId: 'America/Los_Angeles',
  },
  '92398': {
    zip: '92398',
    city: 'Yermo',
    state: 'CA',
    latitude: '34.909791',
    longitude: '-116.809901',
    timeZoneId: 'America/Los_Angeles',
  },
  '92399': {
    zip: '92399',
    city: 'Yucaipa',
    state: 'CA',
    latitude: '34.033763',
    longitude: '-117.040299',
    timeZoneId: 'America/Los_Angeles',
  },
  '92401': {
    zip: '92401',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.106372',
    longitude: '-117.291391',
    timeZoneId: 'America/Los_Angeles',
  },
  '92402': {
    zip: '92402',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.078958',
    longitude: '-117.462404',
    timeZoneId: 'America/Los_Angeles',
  },
  '92403': {
    zip: '92403',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.102131',
    longitude: '-117.269257',
    timeZoneId: 'America/Los_Angeles',
  },
  '92404': {
    zip: '92404',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.14831',
    longitude: '-117.257233',
    timeZoneId: 'America/Los_Angeles',
  },
  '92405': {
    zip: '92405',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.141998',
    longitude: '-117.299722',
    timeZoneId: 'America/Los_Angeles',
  },
  '92406': {
    zip: '92406',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.133983',
    longitude: '-117.286246',
    timeZoneId: 'America/Los_Angeles',
  },
  '92407': {
    zip: '92407',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.183703',
    longitude: '-117.342519',
    timeZoneId: 'America/Los_Angeles',
  },
  '92408': {
    zip: '92408',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.081612',
    longitude: '-117.275214',
    timeZoneId: 'America/Los_Angeles',
  },
  '92410': {
    zip: '92410',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.105653',
    longitude: '-117.294667',
    timeZoneId: 'America/Los_Angeles',
  },
  '92411': {
    zip: '92411',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.124542',
    longitude: '-117.321697',
    timeZoneId: 'America/Los_Angeles',
  },
  '92413': {
    zip: '92413',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.150103',
    longitude: '-117.25672',
    timeZoneId: 'America/Los_Angeles',
  },
  '92415': {
    zip: '92415',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.127706',
    longitude: '-117.266443',
    timeZoneId: 'America/Los_Angeles',
  },
  '92418': {
    zip: '92418',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.104145',
    longitude: '-117.290746',
    timeZoneId: 'America/Los_Angeles',
  },
  '92423': {
    zip: '92423',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.107116',
    longitude: '-117.292358',
    timeZoneId: 'America/Los_Angeles',
  },
  '92427': {
    zip: '92427',
    city: 'San Bernardino',
    state: 'CA',
    latitude: '34.199862',
    longitude: '-117.345353',
    timeZoneId: 'America/Los_Angeles',
  },
  '92501': {
    zip: '92501',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.992846',
    longitude: '-117.371026',
    timeZoneId: 'America/Los_Angeles',
  },
  '92502': {
    zip: '92502',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.976846',
    longitude: '-117.369982',
    timeZoneId: 'America/Los_Angeles',
  },
  '92503': {
    zip: '92503',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.914455',
    longitude: '-117.45606',
    timeZoneId: 'America/Los_Angeles',
  },
  '92504': {
    zip: '92504',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.924842',
    longitude: '-117.397533',
    timeZoneId: 'America/Los_Angeles',
  },
  '92505': {
    zip: '92505',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.919916',
    longitude: '-117.486689',
    timeZoneId: 'America/Los_Angeles',
  },
  '92506': {
    zip: '92506',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.940143',
    longitude: '-117.361737',
    timeZoneId: 'America/Los_Angeles',
  },
  '92507': {
    zip: '92507',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.976325',
    longitude: '-117.330414',
    timeZoneId: 'America/Los_Angeles',
  },
  '92508': {
    zip: '92508',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.8876',
    longitude: '-117.313369',
    timeZoneId: 'America/Los_Angeles',
  },
  '92509': {
    zip: '92509',
    city: 'Jurupa Valley',
    state: 'CA',
    latitude: '33.996714',
    longitude: '-117.445775',
    timeZoneId: 'America/Los_Angeles',
  },
  '92513': {
    zip: '92513',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.913065',
    longitude: '-117.459088',
    timeZoneId: 'America/Los_Angeles',
  },
  '92514': {
    zip: '92514',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.945007',
    longitude: '-117.414714',
    timeZoneId: 'America/Los_Angeles',
  },
  '92516': {
    zip: '92516',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.953169',
    longitude: '-117.394835',
    timeZoneId: 'America/Los_Angeles',
  },
  '92517': {
    zip: '92517',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.964237',
    longitude: '-117.432566',
    timeZoneId: 'America/Los_Angeles',
  },
  '92518': {
    zip: '92518',
    city: 'March Air Reserve Base',
    state: 'CA',
    latitude: '33.896243',
    longitude: '-117.267131',
    timeZoneId: 'America/Los_Angeles',
  },
  '92519': {
    zip: '92519',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.99548',
    longitude: '-117.41344',
    timeZoneId: 'America/Los_Angeles',
  },
  '92521': {
    zip: '92521',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.972585',
    longitude: '-117.350361',
    timeZoneId: 'America/Los_Angeles',
  },
  '92522': {
    zip: '92522',
    city: 'Riverside',
    state: 'CA',
    latitude: '33.940256',
    longitude: '-117.397192',
    timeZoneId: 'America/Los_Angeles',
  },
  '92530': {
    zip: '92530',
    city: 'Lake Elsinore',
    state: 'CA',
    latitude: '33.603897',
    longitude: '-117.377208',
    timeZoneId: 'America/Los_Angeles',
  },
  '92531': {
    zip: '92531',
    city: 'Lake Elsinore',
    state: 'CA',
    latitude: '33.605257',
    longitude: '-117.393897',
    timeZoneId: 'America/Los_Angeles',
  },
  '92532': {
    zip: '92532',
    city: 'Lake Elsinore',
    state: 'CA',
    latitude: '33.661456',
    longitude: '-117.278423',
    timeZoneId: 'America/Los_Angeles',
  },
  '92536': {
    zip: '92536',
    city: 'Aguanga',
    state: 'CA',
    latitude: '33.424735',
    longitude: '-116.802848',
    timeZoneId: 'America/Los_Angeles',
  },
  '92539': {
    zip: '92539',
    city: 'Anza',
    state: 'CA',
    latitude: '33.588602',
    longitude: '-116.742955',
    timeZoneId: 'America/Los_Angeles',
  },
  '92543': {
    zip: '92543',
    city: 'Hemet',
    state: 'CA',
    latitude: '33.70305',
    longitude: '-116.976717',
    timeZoneId: 'America/Los_Angeles',
  },
  '92544': {
    zip: '92544',
    city: 'Hemet',
    state: 'CA',
    latitude: '33.652188',
    longitude: '-116.899445',
    timeZoneId: 'America/Los_Angeles',
  },
  '92545': {
    zip: '92545',
    city: 'Hemet',
    state: 'CA',
    latitude: '33.734737',
    longitude: '-117.051196',
    timeZoneId: 'America/Los_Angeles',
  },
  '92546': {
    zip: '92546',
    city: 'Hemet',
    state: 'CA',
    latitude: '33.74999',
    longitude: '-116.96813',
    timeZoneId: 'America/Los_Angeles',
  },
  '92548': {
    zip: '92548',
    city: 'Homeland',
    state: 'CA',
    latitude: '33.759282',
    longitude: '-117.102312',
    timeZoneId: 'America/Los_Angeles',
  },
  '92549': {
    zip: '92549',
    city: 'Idyllwild',
    state: 'CA',
    latitude: '33.735886',
    longitude: '-116.693711',
    timeZoneId: 'America/Los_Angeles',
  },
  '92551': {
    zip: '92551',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.90269',
    longitude: '-117.189128',
    timeZoneId: 'America/Los_Angeles',
  },
  '92552': {
    zip: '92552',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.925544',
    longitude: '-117.248196',
    timeZoneId: 'America/Los_Angeles',
  },
  '92553': {
    zip: '92553',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.908611',
    longitude: '-117.238598',
    timeZoneId: 'America/Los_Angeles',
  },
  '92554': {
    zip: '92554',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.914803',
    longitude: '-117.251101',
    timeZoneId: 'America/Los_Angeles',
  },
  '92555': {
    zip: '92555',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.915796',
    longitude: '-117.163893',
    timeZoneId: 'America/Los_Angeles',
  },
  '92556': {
    zip: '92556',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.965493',
    longitude: '-117.249439',
    timeZoneId: 'America/Los_Angeles',
  },
  '92557': {
    zip: '92557',
    city: 'Moreno Valley',
    state: 'CA',
    latitude: '33.964589',
    longitude: '-117.263935',
    timeZoneId: 'America/Los_Angeles',
  },
  '92561': {
    zip: '92561',
    city: 'Mountain Center',
    state: 'CA',
    latitude: '33.518312',
    longitude: '-116.424551',
    timeZoneId: 'America/Los_Angeles',
  },
  '92562': {
    zip: '92562',
    city: 'Murrieta',
    state: 'CA',
    latitude: '33.543275',
    longitude: '-117.287556',
    timeZoneId: 'America/Los_Angeles',
  },
  '92563': {
    zip: '92563',
    city: 'Murrieta',
    state: 'CA',
    latitude: '33.564738',
    longitude: '-117.171595',
    timeZoneId: 'America/Los_Angeles',
  },
  '92564': {
    zip: '92564',
    city: 'Murrieta',
    state: 'CA',
    latitude: '33.641779',
    longitude: '-117.186255',
    timeZoneId: 'America/Los_Angeles',
  },
  '92567': {
    zip: '92567',
    city: 'Nuevo',
    state: 'CA',
    latitude: '33.834435',
    longitude: '-117.107956',
    timeZoneId: 'America/Los_Angeles',
  },
  '92570': {
    zip: '92570',
    city: 'Perris',
    state: 'CA',
    latitude: '33.783592',
    longitude: '-117.339362',
    timeZoneId: 'America/Los_Angeles',
  },
  '92571': {
    zip: '92571',
    city: 'Perris',
    state: 'CA',
    latitude: '33.820593',
    longitude: '-117.202999',
    timeZoneId: 'America/Los_Angeles',
  },
  '92572': {
    zip: '92572',
    city: 'Perris',
    state: 'CA',
    latitude: '33.785958',
    longitude: '-117.226712',
    timeZoneId: 'America/Los_Angeles',
  },
  '92581': {
    zip: '92581',
    city: 'San Jacinto',
    state: 'CA',
    latitude: '33.784708',
    longitude: '-116.959911',
    timeZoneId: 'America/Los_Angeles',
  },
  '92582': {
    zip: '92582',
    city: 'San Jacinto',
    state: 'CA',
    latitude: '33.819546',
    longitude: '-117.012729',
    timeZoneId: 'America/Los_Angeles',
  },
  '92583': {
    zip: '92583',
    city: 'San Jacinto',
    state: 'CA',
    latitude: '33.791618',
    longitude: '-116.918647',
    timeZoneId: 'America/Los_Angeles',
  },
  '92584': {
    zip: '92584',
    city: 'Menifee',
    state: 'CA',
    latitude: '33.6798',
    longitude: '-117.18948',
    timeZoneId: 'America/Los_Angeles',
  },
  '92585': {
    zip: '92585',
    city: 'Menifee',
    state: 'CA',
    latitude: '33.749416',
    longitude: '-117.174455',
    timeZoneId: 'America/Los_Angeles',
  },
  '92586': {
    zip: '92586',
    city: 'Menifee',
    state: 'CA',
    latitude: '33.702108',
    longitude: '-117.201493',
    timeZoneId: 'America/Los_Angeles',
  },
  '92587': {
    zip: '92587',
    city: 'Menifee',
    state: 'CA',
    latitude: '33.69576',
    longitude: '-117.23928',
    timeZoneId: 'America/Los_Angeles',
  },
  '92589': {
    zip: '92589',
    city: 'Temecula',
    state: 'CA',
    latitude: '33.516968',
    longitude: '-117.110213',
    timeZoneId: 'America/Los_Angeles',
  },
  '92590': {
    zip: '92590',
    city: 'Temecula',
    state: 'CA',
    latitude: '33.483865',
    longitude: '-117.22554',
    timeZoneId: 'America/Los_Angeles',
  },
  '92591': {
    zip: '92591',
    city: 'Temecula',
    state: 'CA',
    latitude: '33.531454',
    longitude: '-117.100268',
    timeZoneId: 'America/Los_Angeles',
  },
  '92592': {
    zip: '92592',
    city: 'Temecula',
    state: 'CA',
    latitude: '33.505961',
    longitude: '-117.00392',
    timeZoneId: 'America/Los_Angeles',
  },
  '92593': {
    zip: '92593',
    city: 'Temecula',
    state: 'CA',
    latitude: '33.502066',
    longitude: '-117.154829',
    timeZoneId: 'America/Los_Angeles',
  },
  '92595': {
    zip: '92595',
    city: 'Wildomar',
    state: 'CA',
    latitude: '33.594838',
    longitude: '-117.256708',
    timeZoneId: 'America/Los_Angeles',
  },
  '92596': {
    zip: '92596',
    city: 'Winchester',
    state: 'CA',
    latitude: '33.625979',
    longitude: '-117.068531',
    timeZoneId: 'America/Los_Angeles',
  },
  '92599': {
    zip: '92599',
    city: 'Perris',
    state: 'CA',
    latitude: '33.830356',
    longitude: '-117.198023',
    timeZoneId: 'America/Los_Angeles',
  },
  '92602': {
    zip: '92602',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.741875',
    longitude: '-117.746704',
    timeZoneId: 'America/Los_Angeles',
  },
  '92603': {
    zip: '92603',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.62453',
    longitude: '-117.793967',
    timeZoneId: 'America/Los_Angeles',
  },
  '92604': {
    zip: '92604',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.689885',
    longitude: '-117.786783',
    timeZoneId: 'America/Los_Angeles',
  },
  '92605': {
    zip: '92605',
    city: 'Huntington Beach',
    state: 'CA',
    latitude: '33.715124',
    longitude: '-118.007674',
    timeZoneId: 'America/Los_Angeles',
  },
  '92606': {
    zip: '92606',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.695115',
    longitude: '-117.82238',
    timeZoneId: 'America/Los_Angeles',
  },
  '92607': {
    zip: '92607',
    city: 'Laguna Niguel',
    state: 'CA',
    latitude: '33.526852',
    longitude: '-117.711714',
    timeZoneId: 'America/Los_Angeles',
  },
  '92609': {
    zip: '92609',
    city: 'El Toro',
    state: 'CA',
    latitude: '33.62401',
    longitude: '-117.690845',
    timeZoneId: 'America/Los_Angeles',
  },
  '92610': {
    zip: '92610',
    city: 'Foothill Ranch',
    state: 'CA',
    latitude: '33.68178',
    longitude: '-117.63198',
    timeZoneId: 'America/Los_Angeles',
  },
  '92612': {
    zip: '92612',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.648309',
    longitude: '-117.821853',
    timeZoneId: 'America/Los_Angeles',
  },
  '92614': {
    zip: '92614',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.682943',
    longitude: '-117.829782',
    timeZoneId: 'America/Los_Angeles',
  },
  '92615': {
    zip: '92615',
    city: 'Huntington Beach',
    state: 'CA',
    latitude: '33.656552',
    longitude: '-117.969874',
    timeZoneId: 'America/Los_Angeles',
  },
  '92616': {
    zip: '92616',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.65193',
    longitude: '-117.836104',
    timeZoneId: 'America/Los_Angeles',
  },
  '92617': {
    zip: '92617',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.64852',
    longitude: '-117.82136',
    timeZoneId: 'America/Los_Angeles',
  },
  '92618': {
    zip: '92618',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.70739',
    longitude: '-117.705377',
    timeZoneId: 'America/Los_Angeles',
  },
  '92619': {
    zip: '92619',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.6699',
    longitude: '-117.76458',
    timeZoneId: 'America/Los_Angeles',
  },
  '92620': {
    zip: '92620',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.700942',
    longitude: '-117.756405',
    timeZoneId: 'America/Los_Angeles',
  },
  '92623': {
    zip: '92623',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.694249',
    longitude: '-117.812612',
    timeZoneId: 'America/Los_Angeles',
  },
  '92624': {
    zip: '92624',
    city: 'Capistrano Beach',
    state: 'CA',
    latitude: '33.459976',
    longitude: '-117.663169',
    timeZoneId: 'America/Los_Angeles',
  },
  '92625': {
    zip: '92625',
    city: 'Corona del Mar',
    state: 'CA',
    latitude: '33.603589',
    longitude: '-117.861931',
    timeZoneId: 'America/Los_Angeles',
  },
  '92626': {
    zip: '92626',
    city: 'Costa Mesa',
    state: 'CA',
    latitude: '33.680139',
    longitude: '-117.908452',
    timeZoneId: 'America/Los_Angeles',
  },
  '92627': {
    zip: '92627',
    city: 'Costa Mesa',
    state: 'CA',
    latitude: '33.64828',
    longitude: '-117.915538',
    timeZoneId: 'America/Los_Angeles',
  },
  '92628': {
    zip: '92628',
    city: 'Costa Mesa',
    state: 'CA',
    latitude: '33.640149',
    longitude: '-117.915876',
    timeZoneId: 'America/Los_Angeles',
  },
  '92629': {
    zip: '92629',
    city: 'Dana Point',
    state: 'CA',
    latitude: '33.474313',
    longitude: '-117.696438',
    timeZoneId: 'America/Los_Angeles',
  },
  '92630': {
    zip: '92630',
    city: 'Lake Forest',
    state: 'CA',
    latitude: '33.64578',
    longitude: '-117.66912',
    timeZoneId: 'America/Los_Angeles',
  },
  '92637': {
    zip: '92637',
    city: 'Laguna Woods',
    state: 'CA',
    latitude: '33.615016',
    longitude: '-117.711378',
    timeZoneId: 'America/Los_Angeles',
  },
  '92646': {
    zip: '92646',
    city: 'Huntington Beach',
    state: 'CA',
    latitude: '33.665392',
    longitude: '-117.968576',
    timeZoneId: 'America/Los_Angeles',
  },
  '92647': {
    zip: '92647',
    city: 'Huntington Beach',
    state: 'CA',
    latitude: '33.72103',
    longitude: '-118.003329',
    timeZoneId: 'America/Los_Angeles',
  },
  '92648': {
    zip: '92648',
    city: 'Huntington Beach',
    state: 'CA',
    latitude: '33.677256',
    longitude: '-118.005064',
    timeZoneId: 'America/Los_Angeles',
  },
  '92649': {
    zip: '92649',
    city: 'Huntington Beach',
    state: 'CA',
    latitude: '33.717977',
    longitude: '-118.050451',
    timeZoneId: 'America/Los_Angeles',
  },
  '92650': {
    zip: '92650',
    city: 'East Irvine',
    state: 'CA',
    latitude: '33.679458',
    longitude: '-117.760857',
    timeZoneId: 'America/Los_Angeles',
  },
  '92651': {
    zip: '92651',
    city: 'Laguna Beach',
    state: 'CA',
    latitude: '33.542914',
    longitude: '-117.781265',
    timeZoneId: 'America/Los_Angeles',
  },
  '92652': {
    zip: '92652',
    city: 'Laguna Beach',
    state: 'CA',
    latitude: '33.543004',
    longitude: '-117.781451',
    timeZoneId: 'America/Los_Angeles',
  },
  '92653': {
    zip: '92653',
    city: 'Laguna Hills',
    state: 'CA',
    latitude: '33.601535',
    longitude: '-117.711678',
    timeZoneId: 'America/Los_Angeles',
  },
  '92654': {
    zip: '92654',
    city: 'Laguna Hills',
    state: 'CA',
    latitude: '33.604203',
    longitude: '-117.715359',
    timeZoneId: 'America/Los_Angeles',
  },
  '92655': {
    zip: '92655',
    city: 'Midway City',
    state: 'CA',
    latitude: '33.744616',
    longitude: '-117.984',
    timeZoneId: 'America/Los_Angeles',
  },
  '92656': {
    zip: '92656',
    city: 'Aliso Viejo',
    state: 'CA',
    latitude: '33.57612',
    longitude: '-117.73638',
    timeZoneId: 'America/Los_Angeles',
  },
  '92657': {
    zip: '92657',
    city: 'Newport Coast',
    state: 'CA',
    latitude: '33.58832',
    longitude: '-117.808426',
    timeZoneId: 'America/Los_Angeles',
  },
  '92658': {
    zip: '92658',
    city: 'Newport Beach',
    state: 'CA',
    latitude: '33.642217',
    longitude: '-117.863051',
    timeZoneId: 'America/Los_Angeles',
  },
  '92659': {
    zip: '92659',
    city: 'Newport Beach',
    state: 'CA',
    latitude: '33.622189',
    longitude: '-117.923518',
    timeZoneId: 'America/Los_Angeles',
  },
  '92660': {
    zip: '92660',
    city: 'Newport Beach',
    state: 'CA',
    latitude: '33.629473',
    longitude: '-117.868392',
    timeZoneId: 'America/Los_Angeles',
  },
  '92661': {
    zip: '92661',
    city: 'Newport Beach',
    state: 'CA',
    latitude: '33.604484',
    longitude: '-117.902066',
    timeZoneId: 'America/Los_Angeles',
  },
  '92662': {
    zip: '92662',
    city: 'Newport Beach',
    state: 'CA',
    latitude: '33.606234',
    longitude: '-117.893145',
    timeZoneId: 'America/Los_Angeles',
  },
  '92663': {
    zip: '92663',
    city: 'Newport Beach',
    state: 'CA',
    latitude: '33.620985',
    longitude: '-117.93211',
    timeZoneId: 'America/Los_Angeles',
  },
  '92672': {
    zip: '92672',
    city: 'San Clemente',
    state: 'CA',
    latitude: '33.436148',
    longitude: '-117.623087',
    timeZoneId: 'America/Los_Angeles',
  },
  '92673': {
    zip: '92673',
    city: 'San Clemente',
    state: 'CA',
    latitude: '33.461494',
    longitude: '-117.637465',
    timeZoneId: 'America/Los_Angeles',
  },
  '92674': {
    zip: '92674',
    city: 'San Clemente',
    state: 'CA',
    latitude: '33.440938',
    longitude: '-117.621134',
    timeZoneId: 'America/Los_Angeles',
  },
  '92675': {
    zip: '92675',
    city: 'San Juan Capistrano',
    state: 'CA',
    latitude: '33.508513',
    longitude: '-117.656472',
    timeZoneId: 'America/Los_Angeles',
  },
  '92676': {
    zip: '92676',
    city: 'Silverado',
    state: 'CA',
    latitude: '33.745069',
    longitude: '-117.615251',
    timeZoneId: 'America/Los_Angeles',
  },
  '92677': {
    zip: '92677',
    city: 'Laguna Niguel',
    state: 'CA',
    latitude: '33.52554',
    longitude: '-117.71436',
    timeZoneId: 'America/Los_Angeles',
  },
  '92678': {
    zip: '92678',
    city: 'Trabuco Canyon',
    state: 'CA',
    latitude: '33.664297',
    longitude: '-117.589602',
    timeZoneId: 'America/Los_Angeles',
  },
  '92679': {
    zip: '92679',
    city: 'Trabuco Canyon',
    state: 'CA',
    latitude: '33.641543',
    longitude: '-117.5894',
    timeZoneId: 'America/Los_Angeles',
  },
  '92683': {
    zip: '92683',
    city: 'Westminster',
    state: 'CA',
    latitude: '33.750865',
    longitude: '-117.989463',
    timeZoneId: 'America/Los_Angeles',
  },
  '92684': {
    zip: '92684',
    city: 'Westminster',
    state: 'CA',
    latitude: '33.762656',
    longitude: '-118.007203',
    timeZoneId: 'America/Los_Angeles',
  },
  '92685': {
    zip: '92685',
    city: 'Westminster',
    state: 'CA',
    latitude: '33.752811',
    longitude: '-117.995095',
    timeZoneId: 'America/Los_Angeles',
  },
  '92688': {
    zip: '92688',
    city: 'Rancho Santa Margarita',
    state: 'CA',
    latitude: '33.651232',
    longitude: '-117.593834',
    timeZoneId: 'America/Los_Angeles',
  },
  '92690': {
    zip: '92690',
    city: 'Mission Viejo',
    state: 'CA',
    latitude: '33.611708',
    longitude: '-117.64297',
    timeZoneId: 'America/Los_Angeles',
  },
  '92691': {
    zip: '92691',
    city: 'Mission Viejo',
    state: 'CA',
    latitude: '33.612814',
    longitude: '-117.662215',
    timeZoneId: 'America/Los_Angeles',
  },
  '92692': {
    zip: '92692',
    city: 'Mission Viejo',
    state: 'CA',
    latitude: '33.614433',
    longitude: '-117.643273',
    timeZoneId: 'America/Los_Angeles',
  },
  '92693': {
    zip: '92693',
    city: 'San Juan Capistrano',
    state: 'CA',
    latitude: '33.497713',
    longitude: '-117.665115',
    timeZoneId: 'America/Los_Angeles',
  },
  '92694': {
    zip: '92694',
    city: 'Ladera Ranch',
    state: 'CA',
    latitude: '33.55512',
    longitude: '-117.666532',
    timeZoneId: 'America/Los_Angeles',
  },
  '92697': {
    zip: '92697',
    city: 'Irvine',
    state: 'CA',
    latitude: '33.648513',
    longitude: '-117.838683',
    timeZoneId: 'America/Los_Angeles',
  },
  '92698': {
    zip: '92698',
    city: 'Aliso Viejo',
    state: 'CA',
    latitude: '33.668598',
    longitude: '-117.838637',
    timeZoneId: 'America/Los_Angeles',
  },
  '92701': {
    zip: '92701',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.752342',
    longitude: '-117.854139',
    timeZoneId: 'America/Los_Angeles',
  },
  '92702': {
    zip: '92702',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.736548',
    longitude: '-117.871389',
    timeZoneId: 'America/Los_Angeles',
  },
  '92703': {
    zip: '92703',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.748904',
    longitude: '-117.907165',
    timeZoneId: 'America/Los_Angeles',
  },
  '92704': {
    zip: '92704',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.724888',
    longitude: '-117.908994',
    timeZoneId: 'America/Los_Angeles',
  },
  '92705': {
    zip: '92705',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.753989',
    longitude: '-117.791852',
    timeZoneId: 'America/Los_Angeles',
  },
  '92706': {
    zip: '92706',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.769052',
    longitude: '-117.885536',
    timeZoneId: 'America/Los_Angeles',
  },
  '92707': {
    zip: '92707',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.69678',
    longitude: '-117.87012',
    timeZoneId: 'America/Los_Angeles',
  },
  '92708': {
    zip: '92708',
    city: 'Fountain Valley',
    state: 'CA',
    latitude: '33.71665',
    longitude: '-117.944594',
    timeZoneId: 'America/Los_Angeles',
  },
  '92711': {
    zip: '92711',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.766854',
    longitude: '-117.804349',
    timeZoneId: 'America/Los_Angeles',
  },
  '92712': {
    zip: '92712',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.74962',
    longitude: '-117.874983',
    timeZoneId: 'America/Los_Angeles',
  },
  '92728': {
    zip: '92728',
    city: 'Fountain Valley',
    state: 'CA',
    latitude: '33.713991',
    longitude: '-117.928354',
    timeZoneId: 'America/Los_Angeles',
  },
  '92735': {
    zip: '92735',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.718836',
    longitude: '-117.854616',
    timeZoneId: 'America/Los_Angeles',
  },
  '92780': {
    zip: '92780',
    city: 'Tustin',
    state: 'CA',
    latitude: '33.736361',
    longitude: '-117.822888',
    timeZoneId: 'America/Los_Angeles',
  },
  '92781': {
    zip: '92781',
    city: 'Tustin',
    state: 'CA',
    latitude: '33.736867',
    longitude: '-117.818051',
    timeZoneId: 'America/Los_Angeles',
  },
  '92782': {
    zip: '92782',
    city: 'Tustin',
    state: 'CA',
    latitude: '33.734584',
    longitude: '-117.786935',
    timeZoneId: 'America/Los_Angeles',
  },
  '92799': {
    zip: '92799',
    city: 'Santa Ana',
    state: 'CA',
    latitude: '33.720481',
    longitude: '-117.909846',
    timeZoneId: 'America/Los_Angeles',
  },
  '92801': {
    zip: '92801',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.842832',
    longitude: '-117.954595',
    timeZoneId: 'America/Los_Angeles',
  },
  '92802': {
    zip: '92802',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.808505',
    longitude: '-117.922777',
    timeZoneId: 'America/Los_Angeles',
  },
  '92803': {
    zip: '92803',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.839709',
    longitude: '-117.938825',
    timeZoneId: 'America/Los_Angeles',
  },
  '92804': {
    zip: '92804',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.818606',
    longitude: '-117.972857',
    timeZoneId: 'America/Los_Angeles',
  },
  '92805': {
    zip: '92805',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.835916',
    longitude: '-117.908583',
    timeZoneId: 'America/Los_Angeles',
  },
  '92806': {
    zip: '92806',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.835569',
    longitude: '-117.868059',
    timeZoneId: 'America/Los_Angeles',
  },
  '92807': {
    zip: '92807',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.854371',
    longitude: '-117.785773',
    timeZoneId: 'America/Los_Angeles',
  },
  '92808': {
    zip: '92808',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.857901',
    longitude: '-117.751326',
    timeZoneId: 'America/Los_Angeles',
  },
  '92809': {
    zip: '92809',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.842578',
    longitude: '-117.938803',
    timeZoneId: 'America/Los_Angeles',
  },
  '92811': {
    zip: '92811',
    city: 'Atwood',
    state: 'CA',
    latitude: '33.867401',
    longitude: '-117.83099',
    timeZoneId: 'America/Los_Angeles',
  },
  '92812': {
    zip: '92812',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.816996',
    longitude: '-117.928604',
    timeZoneId: 'America/Los_Angeles',
  },
  '92814': {
    zip: '92814',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.817347',
    longitude: '-117.960729',
    timeZoneId: 'America/Los_Angeles',
  },
  '92815': {
    zip: '92815',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.831943',
    longitude: '-117.912104',
    timeZoneId: 'America/Los_Angeles',
  },
  '92816': {
    zip: '92816',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.840115',
    longitude: '-117.88669',
    timeZoneId: 'America/Los_Angeles',
  },
  '92817': {
    zip: '92817',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.851223',
    longitude: '-117.791464',
    timeZoneId: 'America/Los_Angeles',
  },
  '92821': {
    zip: '92821',
    city: 'Brea',
    state: 'CA',
    latitude: '33.929135',
    longitude: '-117.884454',
    timeZoneId: 'America/Los_Angeles',
  },
  '92822': {
    zip: '92822',
    city: 'Brea',
    state: 'CA',
    latitude: '33.918656',
    longitude: '-117.889203',
    timeZoneId: 'America/Los_Angeles',
  },
  '92823': {
    zip: '92823',
    city: 'Brea',
    state: 'CA',
    latitude: '33.922954',
    longitude: '-117.797999',
    timeZoneId: 'America/Los_Angeles',
  },
  '92825': {
    zip: '92825',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.835647',
    longitude: '-117.913197',
    timeZoneId: 'America/Los_Angeles',
  },
  '92831': {
    zip: '92831',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.887306',
    longitude: '-117.894628',
    timeZoneId: 'America/Los_Angeles',
  },
  '92832': {
    zip: '92832',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.867963',
    longitude: '-117.926477',
    timeZoneId: 'America/Los_Angeles',
  },
  '92833': {
    zip: '92833',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.876643',
    longitude: '-117.955051',
    timeZoneId: 'America/Los_Angeles',
  },
  '92834': {
    zip: '92834',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.876777',
    longitude: '-117.897002',
    timeZoneId: 'America/Los_Angeles',
  },
  '92835': {
    zip: '92835',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.899369',
    longitude: '-117.906333',
    timeZoneId: 'America/Los_Angeles',
  },
  '92836': {
    zip: '92836',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.875484',
    longitude: '-117.903814',
    timeZoneId: 'America/Los_Angeles',
  },
  '92837': {
    zip: '92837',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.869533',
    longitude: '-117.961118',
    timeZoneId: 'America/Los_Angeles',
  },
  '92838': {
    zip: '92838',
    city: 'Fullerton',
    state: 'CA',
    latitude: '33.893354',
    longitude: '-117.931038',
    timeZoneId: 'America/Los_Angeles',
  },
  '92840': {
    zip: '92840',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.786931',
    longitude: '-117.927259',
    timeZoneId: 'America/Los_Angeles',
  },
  '92841': {
    zip: '92841',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.781704',
    longitude: '-117.97659',
    timeZoneId: 'America/Los_Angeles',
  },
  '92842': {
    zip: '92842',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.778341',
    longitude: '-117.94558',
    timeZoneId: 'America/Los_Angeles',
  },
  '92843': {
    zip: '92843',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.767061',
    longitude: '-117.929',
    timeZoneId: 'America/Los_Angeles',
  },
  '92844': {
    zip: '92844',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.766072',
    longitude: '-117.973804',
    timeZoneId: 'America/Los_Angeles',
  },
  '92845': {
    zip: '92845',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.778738',
    longitude: '-118.026665',
    timeZoneId: 'America/Los_Angeles',
  },
  '92846': {
    zip: '92846',
    city: 'Garden Grove',
    state: 'CA',
    latitude: '33.788031',
    longitude: '-118.032536',
    timeZoneId: 'America/Los_Angeles',
  },
  '92850': {
    zip: '92850',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.844215',
    longitude: '-117.955546',
    timeZoneId: 'America/Los_Angeles',
  },
  '92856': {
    zip: '92856',
    city: 'Orange',
    state: 'CA',
    latitude: '33.784056',
    longitude: '-117.843476',
    timeZoneId: 'America/Los_Angeles',
  },
  '92857': {
    zip: '92857',
    city: 'Orange',
    state: 'CA',
    latitude: '33.831695',
    longitude: '-117.849144',
    timeZoneId: 'America/Los_Angeles',
  },
  '92859': {
    zip: '92859',
    city: 'Orange',
    state: 'CA',
    latitude: '33.802656',
    longitude: '-117.78669',
    timeZoneId: 'America/Los_Angeles',
  },
  '92860': {
    zip: '92860',
    city: 'Norco',
    state: 'CA',
    latitude: '33.925624',
    longitude: '-117.552211',
    timeZoneId: 'America/Los_Angeles',
  },
  '92861': {
    zip: '92861',
    city: 'Villa Park',
    state: 'CA',
    latitude: '33.820489',
    longitude: '-117.81043',
    timeZoneId: 'America/Los_Angeles',
  },
  '92862': {
    zip: '92862',
    city: 'Orange',
    state: 'CA',
    latitude: '33.791502',
    longitude: '-117.714005',
    timeZoneId: 'America/Los_Angeles',
  },
  '92863': {
    zip: '92863',
    city: 'Orange',
    state: 'CA',
    latitude: '33.815348',
    longitude: '-117.827275',
    timeZoneId: 'America/Los_Angeles',
  },
  '92864': {
    zip: '92864',
    city: 'Orange',
    state: 'CA',
    latitude: '33.814252',
    longitude: '-117.830751',
    timeZoneId: 'America/Los_Angeles',
  },
  '92865': {
    zip: '92865',
    city: 'Orange',
    state: 'CA',
    latitude: '33.826298',
    longitude: '-117.85114',
    timeZoneId: 'America/Los_Angeles',
  },
  '92866': {
    zip: '92866',
    city: 'Orange',
    state: 'CA',
    latitude: '33.787684',
    longitude: '-117.842309',
    timeZoneId: 'America/Los_Angeles',
  },
  '92867': {
    zip: '92867',
    city: 'Orange',
    state: 'CA',
    latitude: '33.811025',
    longitude: '-117.849274',
    timeZoneId: 'America/Los_Angeles',
  },
  '92868': {
    zip: '92868',
    city: 'Orange',
    state: 'CA',
    latitude: '33.787473',
    longitude: '-117.877611',
    timeZoneId: 'America/Los_Angeles',
  },
  '92869': {
    zip: '92869',
    city: 'Orange',
    state: 'CA',
    latitude: '33.78683',
    longitude: '-117.79341',
    timeZoneId: 'America/Los_Angeles',
  },
  '92870': {
    zip: '92870',
    city: 'Placentia',
    state: 'CA',
    latitude: '33.874415',
    longitude: '-117.854269',
    timeZoneId: 'America/Los_Angeles',
  },
  '92871': {
    zip: '92871',
    city: 'Placentia',
    state: 'CA',
    latitude: '33.882928',
    longitude: '-117.85569',
    timeZoneId: 'America/Los_Angeles',
  },
  '92877': {
    zip: '92877',
    city: 'Corona',
    state: 'CA',
    latitude: '33.875817',
    longitude: '-117.567292',
    timeZoneId: 'America/Los_Angeles',
  },
  '92878': {
    zip: '92878',
    city: 'Corona',
    state: 'CA',
    latitude: '33.87433',
    longitude: '-117.565154',
    timeZoneId: 'America/Los_Angeles',
  },
  '92879': {
    zip: '92879',
    city: 'Corona',
    state: 'CA',
    latitude: '33.880653',
    longitude: '-117.537555',
    timeZoneId: 'America/Los_Angeles',
  },
  '92880': {
    zip: '92880',
    city: 'Corona',
    state: 'CA',
    latitude: '33.925761',
    longitude: '-117.614694',
    timeZoneId: 'America/Los_Angeles',
  },
  '92881': {
    zip: '92881',
    city: 'Corona',
    state: 'CA',
    latitude: '33.843208',
    longitude: '-117.513113',
    timeZoneId: 'America/Los_Angeles',
  },
  '92882': {
    zip: '92882',
    city: 'Corona',
    state: 'CA',
    latitude: '33.862585',
    longitude: '-117.598903',
    timeZoneId: 'America/Los_Angeles',
  },
  '92883': {
    zip: '92883',
    city: 'Corona',
    state: 'CA',
    latitude: '33.760266',
    longitude: '-117.488216',
    timeZoneId: 'America/Los_Angeles',
  },
  '92885': {
    zip: '92885',
    city: 'Yorba Linda',
    state: 'CA',
    latitude: '33.891091',
    longitude: '-117.822191',
    timeZoneId: 'America/Los_Angeles',
  },
  '92886': {
    zip: '92886',
    city: 'Yorba Linda',
    state: 'CA',
    latitude: '33.905797',
    longitude: '-117.786465',
    timeZoneId: 'America/Los_Angeles',
  },
  '92887': {
    zip: '92887',
    city: 'Yorba Linda',
    state: 'CA',
    latitude: '33.884072',
    longitude: '-117.730356',
    timeZoneId: 'America/Los_Angeles',
  },
  '92899': {
    zip: '92899',
    city: 'Anaheim',
    state: 'CA',
    latitude: '33.837257',
    longitude: '-117.871219',
    timeZoneId: 'America/Los_Angeles',
  },
  '93001': {
    zip: '93001',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.295546',
    longitude: '-119.291976',
    timeZoneId: 'America/Los_Angeles',
  },
  '93002': {
    zip: '93002',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.280703',
    longitude: '-119.286797',
    timeZoneId: 'America/Los_Angeles',
  },
  '93003': {
    zip: '93003',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.270548',
    longitude: '-119.217135',
    timeZoneId: 'America/Los_Angeles',
  },
  '93004': {
    zip: '93004',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.280121',
    longitude: '-119.168207',
    timeZoneId: 'America/Los_Angeles',
  },
  '93005': {
    zip: '93005',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.250131',
    longitude: '-119.202985',
    timeZoneId: 'America/Los_Angeles',
  },
  '93006': {
    zip: '93006',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.279885',
    longitude: '-119.291934',
    timeZoneId: 'America/Los_Angeles',
  },
  '93007': {
    zip: '93007',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.28972',
    longitude: '-119.157813',
    timeZoneId: 'America/Los_Angeles',
  },
  '93009': {
    zip: '93009',
    city: 'Ventura',
    state: 'CA',
    latitude: '34.266232',
    longitude: '-119.209181',
    timeZoneId: 'America/Los_Angeles',
  },
  '93010': {
    zip: '93010',
    city: 'Camarillo',
    state: 'CA',
    latitude: '34.228608',
    longitude: '-119.02255',
    timeZoneId: 'America/Los_Angeles',
  },
  '93011': {
    zip: '93011',
    city: 'Camarillo',
    state: 'CA',
    latitude: '34.214664',
    longitude: '-119.035591',
    timeZoneId: 'America/Los_Angeles',
  },
  '93012': {
    zip: '93012',
    city: 'Camarillo',
    state: 'CA',
    latitude: '34.224457',
    longitude: '-118.978052',
    timeZoneId: 'America/Los_Angeles',
  },
  '93013': {
    zip: '93013',
    city: 'Carpinteria',
    state: 'CA',
    latitude: '34.402368',
    longitude: '-119.518748',
    timeZoneId: 'America/Los_Angeles',
  },
  '93014': {
    zip: '93014',
    city: 'Carpinteria',
    state: 'CA',
    latitude: '34.392417',
    longitude: '-119.512519',
    timeZoneId: 'America/Los_Angeles',
  },
  '93015': {
    zip: '93015',
    city: 'Fillmore',
    state: 'CA',
    latitude: '34.39698',
    longitude: '-118.912542',
    timeZoneId: 'America/Los_Angeles',
  },
  '93016': {
    zip: '93016',
    city: 'Fillmore',
    state: 'CA',
    latitude: '34.401111',
    longitude: '-118.911775',
    timeZoneId: 'America/Los_Angeles',
  },
  '93020': {
    zip: '93020',
    city: 'Moorpark',
    state: 'CA',
    latitude: '34.284832',
    longitude: '-118.881396',
    timeZoneId: 'America/Los_Angeles',
  },
  '93021': {
    zip: '93021',
    city: 'Moorpark',
    state: 'CA',
    latitude: '34.280332',
    longitude: '-118.889449',
    timeZoneId: 'America/Los_Angeles',
  },
  '93022': {
    zip: '93022',
    city: 'Oak View',
    state: 'CA',
    latitude: '34.405173',
    longitude: '-119.297691',
    timeZoneId: 'America/Los_Angeles',
  },
  '93023': {
    zip: '93023',
    city: 'Ojai',
    state: 'CA',
    latitude: '34.448123',
    longitude: '-119.25256',
    timeZoneId: 'America/Los_Angeles',
  },
  '93024': {
    zip: '93024',
    city: 'Ojai',
    state: 'CA',
    latitude: '34.440233',
    longitude: '-119.260634',
    timeZoneId: 'America/Los_Angeles',
  },
  '93030': {
    zip: '93030',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.214999',
    longitude: '-119.179607',
    timeZoneId: 'America/Los_Angeles',
  },
  '93031': {
    zip: '93031',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.220474',
    longitude: '-119.181244',
    timeZoneId: 'America/Los_Angeles',
  },
  '93032': {
    zip: '93032',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.199525',
    longitude: '-119.176669',
    timeZoneId: 'America/Los_Angeles',
  },
  '93033': {
    zip: '93033',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.168245',
    longitude: '-119.16567',
    timeZoneId: 'America/Los_Angeles',
  },
  '93034': {
    zip: '93034',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.172552',
    longitude: '-119.177361',
    timeZoneId: 'America/Los_Angeles',
  },
  '93035': {
    zip: '93035',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.180219',
    longitude: '-119.218055',
    timeZoneId: 'America/Los_Angeles',
  },
  '93036': {
    zip: '93036',
    city: 'Oxnard',
    state: 'CA',
    latitude: '34.215746',
    longitude: '-119.179322',
    timeZoneId: 'America/Los_Angeles',
  },
  '93040': {
    zip: '93040',
    city: 'Piru',
    state: 'CA',
    latitude: '34.422467',
    longitude: '-118.78461',
    timeZoneId: 'America/Los_Angeles',
  },
  '93041': {
    zip: '93041',
    city: 'Port Hueneme',
    state: 'CA',
    latitude: '34.161105',
    longitude: '-119.189825',
    timeZoneId: 'America/Los_Angeles',
  },
  '93042': {
    zip: '93042',
    city: 'Point Mugu NAWC',
    state: 'CA',
    latitude: '34.122182',
    longitude: '-119.092865',
    timeZoneId: 'America/Los_Angeles',
  },
  '93043': {
    zip: '93043',
    city: 'Port Hueneme CBC Base',
    state: 'CA',
    latitude: '34.16239',
    longitude: '-119.201182',
    timeZoneId: 'America/Los_Angeles',
  },
  '93044': {
    zip: '93044',
    city: 'Port Hueneme',
    state: 'CA',
    latitude: '34.154211',
    longitude: '-119.187956',
    timeZoneId: 'America/Los_Angeles',
  },
  '93060': {
    zip: '93060',
    city: 'Santa Paula',
    state: 'CA',
    latitude: '34.358433',
    longitude: '-119.070823',
    timeZoneId: 'America/Los_Angeles',
  },
  '93061': {
    zip: '93061',
    city: 'Santa Paula',
    state: 'CA',
    latitude: '34.352648',
    longitude: '-119.056905',
    timeZoneId: 'America/Los_Angeles',
  },
  '93062': {
    zip: '93062',
    city: 'Simi Valley',
    state: 'CA',
    latitude: '34.287844',
    longitude: '-118.741018',
    timeZoneId: 'America/Los_Angeles',
  },
  '93063': {
    zip: '93063',
    city: 'Simi Valley',
    state: 'CA',
    latitude: '34.271667',
    longitude: '-118.708056',
    timeZoneId: 'America/Los_Angeles',
  },
  '93064': {
    zip: '93064',
    city: 'Brandeis',
    state: 'CA',
    latitude: '34.283831',
    longitude: '-118.688394',
    timeZoneId: 'America/Los_Angeles',
  },
  '93065': {
    zip: '93065',
    city: 'Simi Valley',
    state: 'CA',
    latitude: '34.268961',
    longitude: '-118.769487',
    timeZoneId: 'America/Los_Angeles',
  },
  '93066': {
    zip: '93066',
    city: 'Somis',
    state: 'CA',
    latitude: '34.273793',
    longitude: '-119.011368',
    timeZoneId: 'America/Los_Angeles',
  },
  '93067': {
    zip: '93067',
    city: 'Summerland',
    state: 'CA',
    latitude: '34.422355',
    longitude: '-119.596145',
    timeZoneId: 'America/Los_Angeles',
  },
  '93094': {
    zip: '93094',
    city: 'Simi Valley',
    state: 'CA',
    latitude: '34.272171',
    longitude: '-118.795286',
    timeZoneId: 'America/Los_Angeles',
  },
  '93099': {
    zip: '93099',
    city: 'Simi Valley',
    state: 'CA',
    latitude: '34.262932',
    longitude: '-118.75053',
    timeZoneId: 'America/Los_Angeles',
  },
  '93101': {
    zip: '93101',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.420375',
    longitude: '-119.708337',
    timeZoneId: 'America/Los_Angeles',
  },
  '93102': {
    zip: '93102',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.41961',
    longitude: '-119.705543',
    timeZoneId: 'America/Los_Angeles',
  },
  '93103': {
    zip: '93103',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.427799',
    longitude: '-119.684102',
    timeZoneId: 'America/Los_Angeles',
  },
  '93105': {
    zip: '93105',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.446863',
    longitude: '-119.741263',
    timeZoneId: 'America/Los_Angeles',
  },
  '93106': {
    zip: '93106',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.431306',
    longitude: '-119.834277',
    timeZoneId: 'America/Los_Angeles',
  },
  '93107': {
    zip: '93107',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.421154',
    longitude: '-119.858056',
    timeZoneId: 'America/Los_Angeles',
  },
  '93108': {
    zip: '93108',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.433903',
    longitude: '-119.629863',
    timeZoneId: 'America/Los_Angeles',
  },
  '93109': {
    zip: '93109',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.402587',
    longitude: '-119.718355',
    timeZoneId: 'America/Los_Angeles',
  },
  '93110': {
    zip: '93110',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.438801',
    longitude: '-119.766004',
    timeZoneId: 'America/Los_Angeles',
  },
  '93111': {
    zip: '93111',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.441286',
    longitude: '-119.800388',
    timeZoneId: 'America/Los_Angeles',
  },
  '93116': {
    zip: '93116',
    city: 'Goleta',
    state: 'CA',
    latitude: '34.491171',
    longitude: '-120.038827',
    timeZoneId: 'America/Los_Angeles',
  },
  '93117': {
    zip: '93117',
    city: 'Goleta',
    state: 'CA',
    latitude: '34.437147',
    longitude: '-119.866076',
    timeZoneId: 'America/Los_Angeles',
  },
  '93118': {
    zip: '93118',
    city: 'Goleta',
    state: 'CA',
    latitude: '34.435471',
    longitude: '-119.824927',
    timeZoneId: 'America/Los_Angeles',
  },
  '93120': {
    zip: '93120',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.421042',
    longitude: '-119.708474',
    timeZoneId: 'America/Los_Angeles',
  },
  '93121': {
    zip: '93121',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.419234',
    longitude: '-119.709176',
    timeZoneId: 'America/Los_Angeles',
  },
  '93130': {
    zip: '93130',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.441228',
    longitude: '-119.735418',
    timeZoneId: 'America/Los_Angeles',
  },
  '93140': {
    zip: '93140',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.421908',
    longitude: '-119.678834',
    timeZoneId: 'America/Los_Angeles',
  },
  '93150': {
    zip: '93150',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.434711',
    longitude: '-119.632112',
    timeZoneId: 'America/Los_Angeles',
  },
  '93160': {
    zip: '93160',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.434059',
    longitude: '-119.802492',
    timeZoneId: 'America/Los_Angeles',
  },
  '93190': {
    zip: '93190',
    city: 'Santa Barbara',
    state: 'CA',
    latitude: '34.425295',
    longitude: '-119.701451',
    timeZoneId: 'America/Los_Angeles',
  },
  '93199': {
    zip: '93199',
    city: 'Goleta',
    state: 'CA',
    latitude: '34.489743',
    longitude: '-120.039532',
    timeZoneId: 'America/Los_Angeles',
  },
  '93201': {
    zip: '93201',
    city: 'Alpaugh',
    state: 'CA',
    latitude: '35.95859',
    longitude: '-119.454962',
    timeZoneId: 'America/Los_Angeles',
  },
  '93202': {
    zip: '93202',
    city: 'Armona',
    state: 'CA',
    latitude: '36.310673',
    longitude: '-119.707822',
    timeZoneId: 'America/Los_Angeles',
  },
  '93203': {
    zip: '93203',
    city: 'Arvin',
    state: 'CA',
    latitude: '35.213446',
    longitude: '-118.854447',
    timeZoneId: 'America/Los_Angeles',
  },
  '93204': {
    zip: '93204',
    city: 'Avenal',
    state: 'CA',
    latitude: '36.008653',
    longitude: '-120.132647',
    timeZoneId: 'America/Los_Angeles',
  },
  '93205': {
    zip: '93205',
    city: 'Bodfish',
    state: 'CA',
    latitude: '35.580528',
    longitude: '-118.482763',
    timeZoneId: 'America/Los_Angeles',
  },
  '93206': {
    zip: '93206',
    city: 'Buttonwillow',
    state: 'CA',
    latitude: '35.399946',
    longitude: '-119.443058',
    timeZoneId: 'America/Los_Angeles',
  },
  '93207': {
    zip: '93207',
    city: 'California Hot Springs',
    state: 'CA',
    latitude: '35.907677',
    longitude: '-118.723924',
    timeZoneId: 'America/Los_Angeles',
  },
  '93208': {
    zip: '93208',
    city: 'Camp Nelson',
    state: 'CA',
    latitude: '36.137286',
    longitude: '-118.611308',
    timeZoneId: 'America/Los_Angeles',
  },
  '93210': {
    zip: '93210',
    city: 'Coalinga',
    state: 'CA',
    latitude: '36.170199',
    longitude: '-120.361013',
    timeZoneId: 'America/Los_Angeles',
  },
  '93212': {
    zip: '93212',
    city: 'Corcoran',
    state: 'CA',
    latitude: '36.096009',
    longitude: '-119.571693',
    timeZoneId: 'America/Los_Angeles',
  },
  '93215': {
    zip: '93215',
    city: 'Delano',
    state: 'CA',
    latitude: '35.770672',
    longitude: '-119.247345',
    timeZoneId: 'America/Los_Angeles',
  },
  '93216': {
    zip: '93216',
    city: 'Delano',
    state: 'CA',
    latitude: '35.75515',
    longitude: '-119.276056',
    timeZoneId: 'America/Los_Angeles',
  },
  '93218': {
    zip: '93218',
    city: 'Ducor',
    state: 'CA',
    latitude: '35.889584',
    longitude: '-119.097731',
    timeZoneId: 'America/Los_Angeles',
  },
  '93219': {
    zip: '93219',
    city: 'Earlimart',
    state: 'CA',
    latitude: '35.887086',
    longitude: '-119.278166',
    timeZoneId: 'America/Los_Angeles',
  },
  '93220': {
    zip: '93220',
    city: 'Edison',
    state: 'CA',
    latitude: '35.331355',
    longitude: '-118.861206',
    timeZoneId: 'America/Los_Angeles',
  },
  '93221': {
    zip: '93221',
    city: 'Exeter',
    state: 'CA',
    latitude: '36.299541',
    longitude: '-119.126188',
    timeZoneId: 'America/Los_Angeles',
  },
  '93222': {
    zip: '93222',
    city: 'Pine Mountain Club',
    state: 'CA',
    latitude: '34.83612',
    longitude: '-119.05512',
    timeZoneId: 'America/Los_Angeles',
  },
  '93223': {
    zip: '93223',
    city: 'Farmersville',
    state: 'CA',
    latitude: '36.300204',
    longitude: '-119.205963',
    timeZoneId: 'America/Los_Angeles',
  },
  '93224': {
    zip: '93224',
    city: 'Fellows',
    state: 'CA',
    latitude: '35.205777',
    longitude: '-119.553998',
    timeZoneId: 'America/Los_Angeles',
  },
  '93225': {
    zip: '93225',
    city: 'Frazier Park',
    state: 'CA',
    latitude: '34.830583',
    longitude: '-119.037797',
    timeZoneId: 'America/Los_Angeles',
  },
  '93226': {
    zip: '93226',
    city: 'Glennville',
    state: 'CA',
    latitude: '35.727787',
    longitude: '-118.695937',
    timeZoneId: 'America/Los_Angeles',
  },
  '93227': {
    zip: '93227',
    city: 'Goshen',
    state: 'CA',
    latitude: '36.344771',
    longitude: '-119.410988',
    timeZoneId: 'America/Los_Angeles',
  },
  '93230': {
    zip: '93230',
    city: 'Hanford',
    state: 'CA',
    latitude: '36.329023',
    longitude: '-119.641214',
    timeZoneId: 'America/Los_Angeles',
  },
  '93232': {
    zip: '93232',
    city: 'Hanford',
    state: 'CA',
    latitude: '36.235654',
    longitude: '-119.707191',
    timeZoneId: 'America/Los_Angeles',
  },
  '93234': {
    zip: '93234',
    city: 'Huron',
    state: 'CA',
    latitude: '36.185731',
    longitude: '-120.092781',
    timeZoneId: 'America/Los_Angeles',
  },
  '93235': {
    zip: '93235',
    city: 'Ivanhoe',
    state: 'CA',
    latitude: '36.366409',
    longitude: '-119.210787',
    timeZoneId: 'America/Los_Angeles',
  },
  '93237': {
    zip: '93237',
    city: 'Kaweah',
    state: 'CA',
    latitude: '36.47113',
    longitude: '-118.920319',
    timeZoneId: 'America/Los_Angeles',
  },
  '93238': {
    zip: '93238',
    city: 'Kernville',
    state: 'CA',
    latitude: '35.752376',
    longitude: '-118.424275',
    timeZoneId: 'America/Los_Angeles',
  },
  '93239': {
    zip: '93239',
    city: 'Kettleman City',
    state: 'CA',
    latitude: '36.025098',
    longitude: '-119.977456',
    timeZoneId: 'America/Los_Angeles',
  },
  '93240': {
    zip: '93240',
    city: 'Lake Isabella',
    state: 'CA',
    latitude: '35.62856',
    longitude: '-118.44037',
    timeZoneId: 'America/Los_Angeles',
  },
  '93241': {
    zip: '93241',
    city: 'Lamont',
    state: 'CA',
    latitude: '35.259651',
    longitude: '-118.914469',
    timeZoneId: 'America/Los_Angeles',
  },
  '93242': {
    zip: '93242',
    city: 'Laton',
    state: 'CA',
    latitude: '36.438987',
    longitude: '-119.716083',
    timeZoneId: 'America/Los_Angeles',
  },
  '93243': {
    zip: '93243',
    city: 'Lebec',
    state: 'CA',
    latitude: '34.82988',
    longitude: '-118.86348',
    timeZoneId: 'America/Los_Angeles',
  },
  '93244': {
    zip: '93244',
    city: 'Lemon Cove',
    state: 'CA',
    latitude: '36.38194',
    longitude: '-119.025651',
    timeZoneId: 'America/Los_Angeles',
  },
  '93245': {
    zip: '93245',
    city: 'Lemoore',
    state: 'CA',
    latitude: '36.3201',
    longitude: '-119.81682',
    timeZoneId: 'America/Los_Angeles',
  },
  '93246': {
    zip: '93246',
    city: 'Lemoore',
    state: 'CA',
    latitude: '36.290241',
    longitude: '-119.847834',
    timeZoneId: 'America/Los_Angeles',
  },
  '93247': {
    zip: '93247',
    city: 'Lindsay',
    state: 'CA',
    latitude: '36.209983',
    longitude: '-119.086125',
    timeZoneId: 'America/Los_Angeles',
  },
  '93249': {
    zip: '93249',
    city: 'Lost Hills',
    state: 'CA',
    latitude: '35.655395',
    longitude: '-119.76904',
    timeZoneId: 'America/Los_Angeles',
  },
  '93250': {
    zip: '93250',
    city: 'Mc Farland',
    state: 'CA',
    latitude: '35.679779',
    longitude: '-119.224936',
    timeZoneId: 'America/Los_Angeles',
  },
  '93251': {
    zip: '93251',
    city: 'Mc Kittrick',
    state: 'CA',
    latitude: '35.468972',
    longitude: '-119.676436',
    timeZoneId: 'America/Los_Angeles',
  },
  '93252': {
    zip: '93252',
    city: 'Maricopa',
    state: 'CA',
    latitude: '35.058817',
    longitude: '-119.403149',
    timeZoneId: 'America/Los_Angeles',
  },
  '93254': {
    zip: '93254',
    city: 'New Cuyama',
    state: 'CA',
    latitude: '34.942355',
    longitude: '-119.664181',
    timeZoneId: 'America/Los_Angeles',
  },
  '93255': {
    zip: '93255',
    city: 'Onyx',
    state: 'CA',
    latitude: '35.700418',
    longitude: '-118.192403',
    timeZoneId: 'America/Los_Angeles',
  },
  '93256': {
    zip: '93256',
    city: 'Pixley',
    state: 'CA',
    latitude: '35.970182',
    longitude: '-119.290216',
    timeZoneId: 'America/Los_Angeles',
  },
  '93257': {
    zip: '93257',
    city: 'Porterville',
    state: 'CA',
    latitude: '36.069871',
    longitude: '-119.021504',
    timeZoneId: 'America/Los_Angeles',
  },
  '93258': {
    zip: '93258',
    city: 'Porterville',
    state: 'CA',
    latitude: '36.066445',
    longitude: '-119.130717',
    timeZoneId: 'America/Los_Angeles',
  },
  '93260': {
    zip: '93260',
    city: 'Posey',
    state: 'CA',
    latitude: '35.802184',
    longitude: '-118.629785',
    timeZoneId: 'America/Los_Angeles',
  },
  '93261': {
    zip: '93261',
    city: 'Richgrove',
    state: 'CA',
    latitude: '35.798221',
    longitude: '-119.108668',
    timeZoneId: 'America/Los_Angeles',
  },
  '93262': {
    zip: '93262',
    city: 'Sequoia National Park',
    state: 'CA',
    latitude: '36.595201',
    longitude: '-118.840408',
    timeZoneId: 'America/Los_Angeles',
  },
  '93263': {
    zip: '93263',
    city: 'Shafter',
    state: 'CA',
    latitude: '35.493768',
    longitude: '-119.289345',
    timeZoneId: 'America/Los_Angeles',
  },
  '93265': {
    zip: '93265',
    city: 'Springville',
    state: 'CA',
    latitude: '36.119144',
    longitude: '-118.83013',
    timeZoneId: 'America/Los_Angeles',
  },
  '93266': {
    zip: '93266',
    city: 'Stratford',
    state: 'CA',
    latitude: '36.179906',
    longitude: '-119.817144',
    timeZoneId: 'America/Los_Angeles',
  },
  '93267': {
    zip: '93267',
    city: 'Strathmore',
    state: 'CA',
    latitude: '36.148701',
    longitude: '-119.064161',
    timeZoneId: 'America/Los_Angeles',
  },
  '93268': {
    zip: '93268',
    city: 'Taft',
    state: 'CA',
    latitude: '35.172101',
    longitude: '-119.4316',
    timeZoneId: 'America/Los_Angeles',
  },
  '93270': {
    zip: '93270',
    city: 'Terra Bella',
    state: 'CA',
    latitude: '35.963052',
    longitude: '-119.038932',
    timeZoneId: 'America/Los_Angeles',
  },
  '93271': {
    zip: '93271',
    city: 'Three Rivers',
    state: 'CA',
    latitude: '36.44615',
    longitude: '-118.889492',
    timeZoneId: 'America/Los_Angeles',
  },
  '93272': {
    zip: '93272',
    city: 'Tipton',
    state: 'CA',
    latitude: '36.057937',
    longitude: '-119.308812',
    timeZoneId: 'America/Los_Angeles',
  },
  '93274': {
    zip: '93274',
    city: 'Tulare',
    state: 'CA',
    latitude: '36.208357',
    longitude: '-119.341083',
    timeZoneId: 'America/Los_Angeles',
  },
  '93275': {
    zip: '93275',
    city: 'Tulare',
    state: 'CA',
    latitude: '36.20753',
    longitude: '-119.34371',
    timeZoneId: 'America/Los_Angeles',
  },
  '93276': {
    zip: '93276',
    city: 'Tupman',
    state: 'CA',
    latitude: '35.290082',
    longitude: '-119.335739',
    timeZoneId: 'America/Los_Angeles',
  },
  '93277': {
    zip: '93277',
    city: 'Visalia',
    state: 'CA',
    latitude: '36.312162',
    longitude: '-119.307419',
    timeZoneId: 'America/Los_Angeles',
  },
  '93278': {
    zip: '93278',
    city: 'Visalia',
    state: 'CA',
    latitude: '36.306081',
    longitude: '-119.312092',
    timeZoneId: 'America/Los_Angeles',
  },
  '93279': {
    zip: '93279',
    city: 'Visalia',
    state: 'CA',
    latitude: '36.329207',
    longitude: '-119.291014',
    timeZoneId: 'America/Los_Angeles',
  },
  '93280': {
    zip: '93280',
    city: 'Wasco',
    state: 'CA',
    latitude: '35.61582',
    longitude: '-119.39166',
    timeZoneId: 'America/Los_Angeles',
  },
  '93282': {
    zip: '93282',
    city: 'Waukena',
    state: 'CA',
    latitude: '36.149403',
    longitude: '-119.506075',
    timeZoneId: 'America/Los_Angeles',
  },
  '93283': {
    zip: '93283',
    city: 'Weldon',
    state: 'CA',
    latitude: '35.633847',
    longitude: '-118.286072',
    timeZoneId: 'America/Los_Angeles',
  },
  '93285': {
    zip: '93285',
    city: 'Wofford Heights',
    state: 'CA',
    latitude: '35.713219',
    longitude: '-118.475236',
    timeZoneId: 'America/Los_Angeles',
  },
  '93286': {
    zip: '93286',
    city: 'Woodlake',
    state: 'CA',
    latitude: '36.420258',
    longitude: '-119.09956',
    timeZoneId: 'America/Los_Angeles',
  },
  '93287': {
    zip: '93287',
    city: 'Woody',
    state: 'CA',
    latitude: '35.702454',
    longitude: '-118.81626',
    timeZoneId: 'America/Los_Angeles',
  },
  '93290': {
    zip: '93290',
    city: 'Visalia',
    state: 'CA',
    latitude: '36.328598',
    longitude: '-119.293861',
    timeZoneId: 'America/Los_Angeles',
  },
  '93291': {
    zip: '93291',
    city: 'Visalia',
    state: 'CA',
    latitude: '36.33989',
    longitude: '-119.302225',
    timeZoneId: 'America/Los_Angeles',
  },
  '93292': {
    zip: '93292',
    city: 'Visalia',
    state: 'CA',
    latitude: '36.334358',
    longitude: '-119.257454',
    timeZoneId: 'America/Los_Angeles',
  },
  '93301': {
    zip: '93301',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.38405',
    longitude: '-119.00846',
    timeZoneId: 'America/Los_Angeles',
  },
  '93302': {
    zip: '93302',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.375349',
    longitude: '-119.021889',
    timeZoneId: 'America/Los_Angeles',
  },
  '93303': {
    zip: '93303',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.55218',
    longitude: '-118.91904',
    timeZoneId: 'America/Los_Angeles',
  },
  '93304': {
    zip: '93304',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.34985',
    longitude: '-119.024721',
    timeZoneId: 'America/Los_Angeles',
  },
  '93305': {
    zip: '93305',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.389089',
    longitude: '-118.983059',
    timeZoneId: 'America/Los_Angeles',
  },
  '93306': {
    zip: '93306',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.390652',
    longitude: '-118.93041',
    timeZoneId: 'America/Los_Angeles',
  },
  '93307': {
    zip: '93307',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.316665',
    longitude: '-118.975058',
    timeZoneId: 'America/Los_Angeles',
  },
  '93308': {
    zip: '93308',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.54676',
    longitude: '-119.04402',
    timeZoneId: 'America/Los_Angeles',
  },
  '93309': {
    zip: '93309',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.337074',
    longitude: '-119.070042',
    timeZoneId: 'America/Los_Angeles',
  },
  '93311': {
    zip: '93311',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.290415',
    longitude: '-119.115826',
    timeZoneId: 'America/Los_Angeles',
  },
  '93312': {
    zip: '93312',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.38677',
    longitude: '-119.173597',
    timeZoneId: 'America/Los_Angeles',
  },
  '93313': {
    zip: '93313',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.254233',
    longitude: '-119.060086',
    timeZoneId: 'America/Los_Angeles',
  },
  '93314': {
    zip: '93314',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.386302',
    longitude: '-119.170023',
    timeZoneId: 'America/Los_Angeles',
  },
  '93380': {
    zip: '93380',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.550483',
    longitude: '-118.921018',
    timeZoneId: 'America/Los_Angeles',
  },
  '93383': {
    zip: '93383',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.264987',
    longitude: '-119.031953',
    timeZoneId: 'America/Los_Angeles',
  },
  '93384': {
    zip: '93384',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.373201',
    longitude: '-119.017675',
    timeZoneId: 'America/Los_Angeles',
  },
  '93385': {
    zip: '93385',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.37911',
    longitude: '-118.989648',
    timeZoneId: 'America/Los_Angeles',
  },
  '93386': {
    zip: '93386',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.375572',
    longitude: '-118.954656',
    timeZoneId: 'America/Los_Angeles',
  },
  '93387': {
    zip: '93387',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.351514',
    longitude: '-118.962431',
    timeZoneId: 'America/Los_Angeles',
  },
  '93388': {
    zip: '93388',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.418862',
    longitude: '-119.022933',
    timeZoneId: 'America/Los_Angeles',
  },
  '93389': {
    zip: '93389',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.355642',
    longitude: '-119.062999',
    timeZoneId: 'America/Los_Angeles',
  },
  '93390': {
    zip: '93390',
    city: 'Bakersfield',
    state: 'CA',
    latitude: '35.356413',
    longitude: '-119.002285',
    timeZoneId: 'America/Los_Angeles',
  },
  '93401': {
    zip: '93401',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.259509',
    longitude: '-120.652522',
    timeZoneId: 'America/Los_Angeles',
  },
  '93402': {
    zip: '93402',
    city: 'Los Osos',
    state: 'CA',
    latitude: '35.30142',
    longitude: '-120.825',
    timeZoneId: 'America/Los_Angeles',
  },
  '93403': {
    zip: '93403',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.280162',
    longitude: '-120.656816',
    timeZoneId: 'America/Los_Angeles',
  },
  '93405': {
    zip: '93405',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.27828',
    longitude: '-120.69217',
    timeZoneId: 'America/Los_Angeles',
  },
  '93406': {
    zip: '93406',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.246254',
    longitude: '-120.639327',
    timeZoneId: 'America/Los_Angeles',
  },
  '93407': {
    zip: '93407',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.298873',
    longitude: '-120.658799',
    timeZoneId: 'America/Los_Angeles',
  },
  '93408': {
    zip: '93408',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.222175',
    longitude: '-120.637046',
    timeZoneId: 'America/Los_Angeles',
  },
  '93409': {
    zip: '93409',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.22071',
    longitude: '-120.636882',
    timeZoneId: 'America/Los_Angeles',
  },
  '93410': {
    zip: '93410',
    city: 'San Luis Obispo',
    state: 'CA',
    latitude: '35.276728',
    longitude: '-120.737489',
    timeZoneId: 'America/Los_Angeles',
  },
  '93412': {
    zip: '93412',
    city: 'Los Osos',
    state: 'CA',
    latitude: '35.31168',
    longitude: '-120.83478',
    timeZoneId: 'America/Los_Angeles',
  },
  '93420': {
    zip: '93420',
    city: 'Arroyo Grande',
    state: 'CA',
    latitude: '35.120444',
    longitude: '-120.564172',
    timeZoneId: 'America/Los_Angeles',
  },
  '93421': {
    zip: '93421',
    city: 'Arroyo Grande',
    state: 'CA',
    latitude: '35.120678',
    longitude: '-120.574422',
    timeZoneId: 'America/Los_Angeles',
  },
  '93422': {
    zip: '93422',
    city: 'Atascadero',
    state: 'CA',
    latitude: '35.481363',
    longitude: '-120.669911',
    timeZoneId: 'America/Los_Angeles',
  },
  '93423': {
    zip: '93423',
    city: 'Atascadero',
    state: 'CA',
    latitude: '35.487398',
    longitude: '-120.645262',
    timeZoneId: 'America/Los_Angeles',
  },
  '93424': {
    zip: '93424',
    city: 'Avila Beach',
    state: 'CA',
    latitude: '35.183729',
    longitude: '-120.725586',
    timeZoneId: 'America/Los_Angeles',
  },
  '93426': {
    zip: '93426',
    city: 'Bradley',
    state: 'CA',
    latitude: '35.755566',
    longitude: '-120.978715',
    timeZoneId: 'America/Los_Angeles',
  },
  '93427': {
    zip: '93427',
    city: 'Buellton',
    state: 'CA',
    latitude: '34.62822',
    longitude: '-120.25409',
    timeZoneId: 'America/Los_Angeles',
  },
  '93428': {
    zip: '93428',
    city: 'Cambria',
    state: 'CA',
    latitude: '35.558529',
    longitude: '-121.081282',
    timeZoneId: 'America/Los_Angeles',
  },
  '93429': {
    zip: '93429',
    city: 'Casmalia',
    state: 'CA',
    latitude: '34.836982',
    longitude: '-120.530949',
    timeZoneId: 'America/Los_Angeles',
  },
  '93430': {
    zip: '93430',
    city: 'Cayucos',
    state: 'CA',
    latitude: '35.446852',
    longitude: '-120.88243',
    timeZoneId: 'America/Los_Angeles',
  },
  '93432': {
    zip: '93432',
    city: 'Creston',
    state: 'CA',
    latitude: '35.492172',
    longitude: '-120.496085',
    timeZoneId: 'America/Los_Angeles',
  },
  '93433': {
    zip: '93433',
    city: 'Grover Beach',
    state: 'CA',
    latitude: '35.123216',
    longitude: '-120.617031',
    timeZoneId: 'America/Los_Angeles',
  },
  '93434': {
    zip: '93434',
    city: 'Guadalupe',
    state: 'CA',
    latitude: '34.957417',
    longitude: '-120.572565',
    timeZoneId: 'America/Los_Angeles',
  },
  '93435': {
    zip: '93435',
    city: 'Harmony',
    state: 'CA',
    latitude: '35.512903',
    longitude: '-121.008442',
    timeZoneId: 'America/Los_Angeles',
  },
  '93436': {
    zip: '93436',
    city: 'Lompoc',
    state: 'CA',
    latitude: '34.651186',
    longitude: '-120.435893',
    timeZoneId: 'America/Los_Angeles',
  },
  '93437': {
    zip: '93437',
    city: 'Lompoc',
    state: 'CA',
    latitude: '34.7388',
    longitude: '-120.52506',
    timeZoneId: 'America/Los_Angeles',
  },
  '93438': {
    zip: '93438',
    city: 'Lompoc',
    state: 'CA',
    latitude: '34.63638',
    longitude: '-120.469031',
    timeZoneId: 'America/Los_Angeles',
  },
  '93440': {
    zip: '93440',
    city: 'Los Alamos',
    state: 'CA',
    latitude: '34.745944',
    longitude: '-120.301041',
    timeZoneId: 'America/Los_Angeles',
  },
  '93441': {
    zip: '93441',
    city: 'Los Olivos',
    state: 'CA',
    latitude: '34.666706',
    longitude: '-120.109409',
    timeZoneId: 'America/Los_Angeles',
  },
  '93442': {
    zip: '93442',
    city: 'Morro Bay',
    state: 'CA',
    latitude: '35.384144',
    longitude: '-120.850075',
    timeZoneId: 'America/Los_Angeles',
  },
  '93443': {
    zip: '93443',
    city: 'Morro Bay',
    state: 'CA',
    latitude: '35.367194',
    longitude: '-120.851678',
    timeZoneId: 'America/Los_Angeles',
  },
  '93444': {
    zip: '93444',
    city: 'Nipomo',
    state: 'CA',
    latitude: '35.031218',
    longitude: '-120.495313',
    timeZoneId: 'America/Los_Angeles',
  },
  '93445': {
    zip: '93445',
    city: 'Oceano',
    state: 'CA',
    latitude: '35.102497',
    longitude: '-120.611271',
    timeZoneId: 'America/Los_Angeles',
  },
  '93446': {
    zip: '93446',
    city: 'Paso Robles',
    state: 'CA',
    latitude: '35.637137',
    longitude: '-120.691676',
    timeZoneId: 'America/Los_Angeles',
  },
  '93447': {
    zip: '93447',
    city: 'Paso Robles',
    state: 'CA',
    latitude: '35.658885',
    longitude: '-120.69535',
    timeZoneId: 'America/Los_Angeles',
  },
  '93448': {
    zip: '93448',
    city: 'Pismo Beach',
    state: 'CA',
    latitude: '35.155278',
    longitude: '-120.671389',
    timeZoneId: 'America/Los_Angeles',
  },
  '93449': {
    zip: '93449',
    city: 'Pismo Beach',
    state: 'CA',
    latitude: '35.1582',
    longitude: '-120.6552',
    timeZoneId: 'America/Los_Angeles',
  },
  '93450': {
    zip: '93450',
    city: 'San Ardo',
    state: 'CA',
    latitude: '36.025296',
    longitude: '-120.902933',
    timeZoneId: 'America/Los_Angeles',
  },
  '93451': {
    zip: '93451',
    city: 'San Miguel',
    state: 'CA',
    latitude: '35.75088',
    longitude: '-120.63678',
    timeZoneId: 'America/Los_Angeles',
  },
  '93452': {
    zip: '93452',
    city: 'San Simeon',
    state: 'CA',
    latitude: '35.549785',
    longitude: '-120.950261',
    timeZoneId: 'America/Los_Angeles',
  },
  '93453': {
    zip: '93453',
    city: 'Santa Margarita',
    state: 'CA',
    latitude: '35.365892',
    longitude: '-120.392105',
    timeZoneId: 'America/Los_Angeles',
  },
  '93454': {
    zip: '93454',
    city: 'Santa Maria',
    state: 'CA',
    latitude: '34.87752',
    longitude: '-120.3318',
    timeZoneId: 'America/Los_Angeles',
  },
  '93455': {
    zip: '93455',
    city: 'Santa Maria',
    state: 'CA',
    latitude: '34.83828',
    longitude: '-120.40356',
    timeZoneId: 'America/Los_Angeles',
  },
  '93456': {
    zip: '93456',
    city: 'Santa Maria',
    state: 'CA',
    latitude: '34.942122',
    longitude: '-120.461672',
    timeZoneId: 'America/Los_Angeles',
  },
  '93457': {
    zip: '93457',
    city: 'Santa Maria',
    state: 'CA',
    latitude: '34.865278',
    longitude: '-120.435',
    timeZoneId: 'America/Los_Angeles',
  },
  '93458': {
    zip: '93458',
    city: 'Santa Maria',
    state: 'CA',
    latitude: '34.952802',
    longitude: '-120.431903',
    timeZoneId: 'America/Los_Angeles',
  },
  '93460': {
    zip: '93460',
    city: 'Santa Ynez',
    state: 'CA',
    latitude: '34.637026',
    longitude: '-120.073744',
    timeZoneId: 'America/Los_Angeles',
  },
  '93461': {
    zip: '93461',
    city: 'Shandon',
    state: 'CA',
    latitude: '35.5356',
    longitude: '-120.20328',
    timeZoneId: 'America/Los_Angeles',
  },
  '93463': {
    zip: '93463',
    city: 'Solvang',
    state: 'CA',
    latitude: '34.63212',
    longitude: '-120.116581',
    timeZoneId: 'America/Los_Angeles',
  },
  '93464': {
    zip: '93464',
    city: 'Solvang',
    state: 'CA',
    latitude: '34.554223',
    longitude: '-120.141779',
    timeZoneId: 'America/Los_Angeles',
  },
  '93465': {
    zip: '93465',
    city: 'Templeton',
    state: 'CA',
    latitude: '35.542784',
    longitude: '-120.696367',
    timeZoneId: 'America/Los_Angeles',
  },
  '93475': {
    zip: '93475',
    city: 'Oceano',
    state: 'CA',
    latitude: '35.10163',
    longitude: '-120.611837',
    timeZoneId: 'America/Los_Angeles',
  },
  '93483': {
    zip: '93483',
    city: 'Grover Beach',
    state: 'CA',
    latitude: '35.119166',
    longitude: '-120.620876',
    timeZoneId: 'America/Los_Angeles',
  },
  '93501': {
    zip: '93501',
    city: 'Mojave',
    state: 'CA',
    latitude: '35.024023',
    longitude: '-118.177512',
    timeZoneId: 'America/Los_Angeles',
  },
  '93502': {
    zip: '93502',
    city: 'Mojave',
    state: 'CA',
    latitude: '35.055697',
    longitude: '-118.175526',
    timeZoneId: 'America/Los_Angeles',
  },
  '93504': {
    zip: '93504',
    city: 'California City',
    state: 'CA',
    latitude: '35.147963',
    longitude: '-118.010042',
    timeZoneId: 'America/Los_Angeles',
  },
  '93505': {
    zip: '93505',
    city: 'California City',
    state: 'CA',
    latitude: '35.132536',
    longitude: '-117.956387',
    timeZoneId: 'America/Los_Angeles',
  },
  '93510': {
    zip: '93510',
    city: 'Acton',
    state: 'CA',
    latitude: '34.489202',
    longitude: '-118.197221',
    timeZoneId: 'America/Los_Angeles',
  },
  '93512': {
    zip: '93512',
    city: 'Benton',
    state: 'CA',
    latitude: '37.81',
    longitude: '-118.47',
    timeZoneId: 'America/Los_Angeles',
  },
  '93513': {
    zip: '93513',
    city: 'Big Pine',
    state: 'CA',
    latitude: '37.175394',
    longitude: '-118.303999',
    timeZoneId: 'America/Los_Angeles',
  },
  '93514': {
    zip: '93514',
    city: 'Bishop',
    state: 'CA',
    latitude: '37.357496',
    longitude: '-118.416618',
    timeZoneId: 'America/Los_Angeles',
  },
  '93515': {
    zip: '93515',
    city: 'Bishop',
    state: 'CA',
    latitude: '37.360144',
    longitude: '-118.401003',
    timeZoneId: 'America/Los_Angeles',
  },
  '93516': {
    zip: '93516',
    city: 'Boron',
    state: 'CA',
    latitude: '35.008562',
    longitude: '-117.655982',
    timeZoneId: 'America/Los_Angeles',
  },
  '93517': {
    zip: '93517',
    city: 'Bridgeport',
    state: 'CA',
    latitude: '38.23259',
    longitude: '-119.276186',
    timeZoneId: 'America/Los_Angeles',
  },
  '93518': {
    zip: '93518',
    city: 'Caliente',
    state: 'CA',
    latitude: '35.3184',
    longitude: '-118.4622',
    timeZoneId: 'America/Los_Angeles',
  },
  '93519': {
    zip: '93519',
    city: 'Cantil',
    state: 'CA',
    latitude: '35.305573',
    longitude: '-117.953568',
    timeZoneId: 'America/Los_Angeles',
  },
  '93522': {
    zip: '93522',
    city: 'Darwin',
    state: 'CA',
    latitude: '35.627473',
    longitude: '-117.670413',
    timeZoneId: 'America/Los_Angeles',
  },
  '93523': {
    zip: '93523',
    city: 'Edwards',
    state: 'CA',
    latitude: '34.950022',
    longitude: '-117.901246',
    timeZoneId: 'America/Los_Angeles',
  },
  '93524': {
    zip: '93524',
    city: 'Edwards',
    state: 'CA',
    latitude: '34.919545',
    longitude: '-117.946773',
    timeZoneId: 'America/Los_Angeles',
  },
  '93526': {
    zip: '93526',
    city: 'Independence',
    state: 'CA',
    latitude: '36.832098',
    longitude: '-118.233557',
    timeZoneId: 'America/Los_Angeles',
  },
  '93527': {
    zip: '93527',
    city: 'Inyokern',
    state: 'CA',
    latitude: '35.685858',
    longitude: '-117.832494',
    timeZoneId: 'America/Los_Angeles',
  },
  '93528': {
    zip: '93528',
    city: 'Johannesburg',
    state: 'CA',
    latitude: '35.368926',
    longitude: '-117.634207',
    timeZoneId: 'America/Los_Angeles',
  },
  '93529': {
    zip: '93529',
    city: 'June Lake',
    state: 'CA',
    latitude: '37.814915',
    longitude: '-119.006993',
    timeZoneId: 'America/Los_Angeles',
  },
  '93530': {
    zip: '93530',
    city: 'Keeler',
    state: 'CA',
    latitude: '36.488465',
    longitude: '-117.872813',
    timeZoneId: 'America/Los_Angeles',
  },
  '93531': {
    zip: '93531',
    city: 'Keene',
    state: 'CA',
    latitude: '35.236438',
    longitude: '-118.602974',
    timeZoneId: 'America/Los_Angeles',
  },
  '93532': {
    zip: '93532',
    city: 'Lake Hughes',
    state: 'CA',
    latitude: '34.673056',
    longitude: '-118.423925',
    timeZoneId: 'America/Los_Angeles',
  },
  '93534': {
    zip: '93534',
    city: 'Lancaster',
    state: 'CA',
    latitude: '34.692638',
    longitude: '-118.144902',
    timeZoneId: 'America/Los_Angeles',
  },
  '93535': {
    zip: '93535',
    city: 'Lancaster',
    state: 'CA',
    latitude: '34.715491',
    longitude: '-117.902577',
    timeZoneId: 'America/Los_Angeles',
  },
  '93536': {
    zip: '93536',
    city: 'Lancaster',
    state: 'CA',
    latitude: '34.775252',
    longitude: '-118.471697',
    timeZoneId: 'America/Los_Angeles',
  },
  '93539': {
    zip: '93539',
    city: 'Lancaster',
    state: 'CA',
    latitude: '34.691705',
    longitude: '-118.132025',
    timeZoneId: 'America/Los_Angeles',
  },
  '93541': {
    zip: '93541',
    city: 'Lee Vining',
    state: 'CA',
    latitude: '38.00208',
    longitude: '-119.06958',
    timeZoneId: 'America/Los_Angeles',
  },
  '93542': {
    zip: '93542',
    city: 'Little Lake',
    state: 'CA',
    latitude: '35.936934',
    longitude: '-117.907963',
    timeZoneId: 'America/Los_Angeles',
  },
  '93543': {
    zip: '93543',
    city: 'Littlerock',
    state: 'CA',
    latitude: '34.520996',
    longitude: '-117.951674',
    timeZoneId: 'America/Los_Angeles',
  },
  '93544': {
    zip: '93544',
    city: 'Llano',
    state: 'CA',
    latitude: '34.493353',
    longitude: '-117.78776',
    timeZoneId: 'America/Los_Angeles',
  },
  '93545': {
    zip: '93545',
    city: 'Lone Pine',
    state: 'CA',
    latitude: '36.588',
    longitude: '-118.056263',
    timeZoneId: 'America/Los_Angeles',
  },
  '93546': {
    zip: '93546',
    city: 'Mammoth Lakes',
    state: 'CA',
    latitude: '37.56438',
    longitude: '-118.74451',
    timeZoneId: 'America/Los_Angeles',
  },
  '93549': {
    zip: '93549',
    city: 'Olancha',
    state: 'CA',
    latitude: '36.320833',
    longitude: '-118.025556',
    timeZoneId: 'America/Los_Angeles',
  },
  '93550': {
    zip: '93550',
    city: 'Palmdale',
    state: 'CA',
    latitude: '34.568461',
    longitude: '-118.062387',
    timeZoneId: 'America/Los_Angeles',
  },
  '93551': {
    zip: '93551',
    city: 'Palmdale',
    state: 'CA',
    latitude: '34.600777',
    longitude: '-118.190371',
    timeZoneId: 'America/Los_Angeles',
  },
  '93552': {
    zip: '93552',
    city: 'Palmdale',
    state: 'CA',
    latitude: '34.558636',
    longitude: '-118.040632',
    timeZoneId: 'America/Los_Angeles',
  },
  '93553': {
    zip: '93553',
    city: 'Pearblossom',
    state: 'CA',
    latitude: '34.470395',
    longitude: '-117.896351',
    timeZoneId: 'America/Los_Angeles',
  },
  '93554': {
    zip: '93554',
    city: 'Randsburg',
    state: 'CA',
    latitude: '35.375287',
    longitude: '-117.66437',
    timeZoneId: 'America/Los_Angeles',
  },
  '93555': {
    zip: '93555',
    city: 'Ridgecrest',
    state: 'CA',
    latitude: '35.622118',
    longitude: '-117.690678',
    timeZoneId: 'America/Los_Angeles',
  },
  '93556': {
    zip: '93556',
    city: 'Ridgecrest',
    state: 'CA',
    latitude: '35.623072',
    longitude: '-117.670357',
    timeZoneId: 'America/Los_Angeles',
  },
  '93558': {
    zip: '93558',
    city: 'Red Mountain',
    state: 'CA',
    latitude: '35.351568',
    longitude: '-117.627031',
    timeZoneId: 'America/Los_Angeles',
  },
  '93560': {
    zip: '93560',
    city: 'Rosamond',
    state: 'CA',
    latitude: '34.863793',
    longitude: '-118.257708',
    timeZoneId: 'America/Los_Angeles',
  },
  '93561': {
    zip: '93561',
    city: 'Tehachapi',
    state: 'CA',
    latitude: '35.135575',
    longitude: '-118.527991',
    timeZoneId: 'America/Los_Angeles',
  },
  '93562': {
    zip: '93562',
    city: 'Trona',
    state: 'CA',
    latitude: '35.754736',
    longitude: '-117.385894',
    timeZoneId: 'America/Los_Angeles',
  },
  '93563': {
    zip: '93563',
    city: 'Valyermo',
    state: 'CA',
    latitude: '34.429622',
    longitude: '-117.797685',
    timeZoneId: 'America/Los_Angeles',
  },
  '93581': {
    zip: '93581',
    city: 'Tehachapi',
    state: 'CA',
    latitude: '35.130447',
    longitude: '-118.447638',
    timeZoneId: 'America/Los_Angeles',
  },
  '93584': {
    zip: '93584',
    city: 'Lancaster',
    state: 'CA',
    latitude: '34.699023',
    longitude: '-118.1386',
    timeZoneId: 'America/Los_Angeles',
  },
  '93586': {
    zip: '93586',
    city: 'Lancaster',
    state: 'CA',
    latitude: '34.648854',
    longitude: '-118.219319',
    timeZoneId: 'America/Los_Angeles',
  },
  '93590': {
    zip: '93590',
    city: 'Palmdale',
    state: 'CA',
    latitude: '34.579596',
    longitude: '-118.088681',
    timeZoneId: 'America/Los_Angeles',
  },
  '93591': {
    zip: '93591',
    city: 'Palmdale',
    state: 'CA',
    latitude: '34.604861',
    longitude: '-117.832601',
    timeZoneId: 'America/Los_Angeles',
  },
  '93592': {
    zip: '93592',
    city: 'Trona',
    state: 'CA',
    latitude: '35.760853',
    longitude: '-117.369077',
    timeZoneId: 'America/Los_Angeles',
  },
  '93596': {
    zip: '93596',
    city: 'Boron',
    state: 'CA',
    latitude: '35.006002',
    longitude: '-117.651389',
    timeZoneId: 'America/Los_Angeles',
  },
  '93599': {
    zip: '93599',
    city: 'Palmdale',
    state: 'CA',
    latitude: '34.577152',
    longitude: '-118.116071',
    timeZoneId: 'America/Los_Angeles',
  },
  '93601': {
    zip: '93601',
    city: 'Ahwahnee',
    state: 'CA',
    latitude: '37.380335',
    longitude: '-119.718301',
    timeZoneId: 'America/Los_Angeles',
  },
  '93602': {
    zip: '93602',
    city: 'Auberry',
    state: 'CA',
    latitude: '37.074481',
    longitude: '-119.455209',
    timeZoneId: 'America/Los_Angeles',
  },
  '93603': {
    zip: '93603',
    city: 'Badger',
    state: 'CA',
    latitude: '36.588008',
    longitude: '-119.015',
    timeZoneId: 'America/Los_Angeles',
  },
  '93604': {
    zip: '93604',
    city: 'Bass Lake',
    state: 'CA',
    latitude: '37.313',
    longitude: '-119.548394',
    timeZoneId: 'America/Los_Angeles',
  },
  '93605': {
    zip: '93605',
    city: 'Big Creek',
    state: 'CA',
    latitude: '37.21179',
    longitude: '-119.243574',
    timeZoneId: 'America/Los_Angeles',
  },
  '93606': {
    zip: '93606',
    city: 'Biola',
    state: 'CA',
    latitude: '36.802578',
    longitude: '-120.018346',
    timeZoneId: 'America/Los_Angeles',
  },
  '93607': {
    zip: '93607',
    city: 'Burrel',
    state: 'CA',
    latitude: '36.488333',
    longitude: '-119.984167',
    timeZoneId: 'America/Los_Angeles',
  },
  '93608': {
    zip: '93608',
    city: 'Cantua Creek',
    state: 'CA',
    latitude: '36.519483',
    longitude: '-120.349022',
    timeZoneId: 'America/Los_Angeles',
  },
  '93609': {
    zip: '93609',
    city: 'Caruthers',
    state: 'CA',
    latitude: '36.534467',
    longitude: '-119.840977',
    timeZoneId: 'America/Los_Angeles',
  },
  '93610': {
    zip: '93610',
    city: 'Chowchilla',
    state: 'CA',
    latitude: '37.110099',
    longitude: '-120.262938',
    timeZoneId: 'America/Los_Angeles',
  },
  '93611': {
    zip: '93611',
    city: 'Clovis',
    state: 'CA',
    latitude: '36.845587',
    longitude: '-119.652237',
    timeZoneId: 'America/Los_Angeles',
  },
  '93612': {
    zip: '93612',
    city: 'Clovis',
    state: 'CA',
    latitude: '36.828991',
    longitude: '-119.666341',
    timeZoneId: 'America/Los_Angeles',
  },
  '93613': {
    zip: '93613',
    city: 'Clovis',
    state: 'CA',
    latitude: '36.797845',
    longitude: '-119.640595',
    timeZoneId: 'America/Los_Angeles',
  },
  '93614': {
    zip: '93614',
    city: 'Coarsegold',
    state: 'CA',
    latitude: '37.21248',
    longitude: '-119.732948',
    timeZoneId: 'America/Los_Angeles',
  },
  '93615': {
    zip: '93615',
    city: 'Cutler',
    state: 'CA',
    latitude: '36.529881',
    longitude: '-119.298658',
    timeZoneId: 'America/Los_Angeles',
  },
  '93616': {
    zip: '93616',
    city: 'Del Rey',
    state: 'CA',
    latitude: '36.659348',
    longitude: '-119.587883',
    timeZoneId: 'America/Los_Angeles',
  },
  '93618': {
    zip: '93618',
    city: 'Dinuba',
    state: 'CA',
    latitude: '36.546422',
    longitude: '-119.38899',
    timeZoneId: 'America/Los_Angeles',
  },
  '93619': {
    zip: '93619',
    city: 'Clovis',
    state: 'CA',
    latitude: '36.843192',
    longitude: '-119.651814',
    timeZoneId: 'America/Los_Angeles',
  },
  '93620': {
    zip: '93620',
    city: 'Dos Palos',
    state: 'CA',
    latitude: '37.020964',
    longitude: '-120.644224',
    timeZoneId: 'America/Los_Angeles',
  },
  '93621': {
    zip: '93621',
    city: 'Dunlap',
    state: 'CA',
    latitude: '36.730872',
    longitude: '-119.108608',
    timeZoneId: 'America/Los_Angeles',
  },
  '93622': {
    zip: '93622',
    city: 'Firebaugh',
    state: 'CA',
    latitude: '36.830459',
    longitude: '-120.540226',
    timeZoneId: 'America/Los_Angeles',
  },
  '93623': {
    zip: '93623',
    city: 'Fish Camp',
    state: 'CA',
    latitude: '37.479525',
    longitude: '-119.636199',
    timeZoneId: 'America/Los_Angeles',
  },
  '93624': {
    zip: '93624',
    city: 'Five Points',
    state: 'CA',
    latitude: '36.395983',
    longitude: '-120.129336',
    timeZoneId: 'America/Los_Angeles',
  },
  '93625': {
    zip: '93625',
    city: 'Fowler',
    state: 'CA',
    latitude: '36.623292',
    longitude: '-119.667048',
    timeZoneId: 'America/Los_Angeles',
  },
  '93626': {
    zip: '93626',
    city: 'Friant',
    state: 'CA',
    latitude: '36.988336',
    longitude: '-119.693009',
    timeZoneId: 'America/Los_Angeles',
  },
  '93627': {
    zip: '93627',
    city: 'Helm',
    state: 'CA',
    latitude: '36.575023',
    longitude: '-120.081468',
    timeZoneId: 'America/Los_Angeles',
  },
  '93628': {
    zip: '93628',
    city: 'Hume',
    state: 'CA',
    latitude: '36.6925',
    longitude: '-119.051389',
    timeZoneId: 'America/Los_Angeles',
  },
  '93630': {
    zip: '93630',
    city: 'Kerman',
    state: 'CA',
    latitude: '36.735924',
    longitude: '-120.074457',
    timeZoneId: 'America/Los_Angeles',
  },
  '93631': {
    zip: '93631',
    city: 'Kingsburg',
    state: 'CA',
    latitude: '36.521236',
    longitude: '-119.555095',
    timeZoneId: 'America/Los_Angeles',
  },
  '93633': {
    zip: '93633',
    city: 'Kings Canyon National Pk',
    state: 'CA',
    latitude: '36.91818',
    longitude: '-119.03382',
    timeZoneId: 'America/Los_Angeles',
  },
  '93634': {
    zip: '93634',
    city: 'Lakeshore',
    state: 'CA',
    latitude: '37.25298',
    longitude: '-119.1738',
    timeZoneId: 'America/Los_Angeles',
  },
  '93635': {
    zip: '93635',
    city: 'Los Banos',
    state: 'CA',
    latitude: '37.052173',
    longitude: '-120.851591',
    timeZoneId: 'America/Los_Angeles',
  },
  '93636': {
    zip: '93636',
    city: 'Madera',
    state: 'CA',
    latitude: '36.958056',
    longitude: '-120.070391',
    timeZoneId: 'America/Los_Angeles',
  },
  '93637': {
    zip: '93637',
    city: 'Madera',
    state: 'CA',
    latitude: '36.958261',
    longitude: '-120.070035',
    timeZoneId: 'America/Los_Angeles',
  },
  '93638': {
    zip: '93638',
    city: 'Madera',
    state: 'CA',
    latitude: '36.974268',
    longitude: '-120.002242',
    timeZoneId: 'America/Los_Angeles',
  },
  '93639': {
    zip: '93639',
    city: 'Madera',
    state: 'CA',
    latitude: '36.964783',
    longitude: '-120.084565',
    timeZoneId: 'America/Los_Angeles',
  },
  '93640': {
    zip: '93640',
    city: 'Mendota',
    state: 'CA',
    latitude: '36.729057',
    longitude: '-120.389166',
    timeZoneId: 'America/Los_Angeles',
  },
  '93641': {
    zip: '93641',
    city: 'Miramonte',
    state: 'CA',
    latitude: '36.69392',
    longitude: '-119.035961',
    timeZoneId: 'America/Los_Angeles',
  },
  '93642': {
    zip: '93642',
    city: 'Mono Hot Springs',
    state: 'CA',
    latitude: '37.326667',
    longitude: '-119.016667',
    timeZoneId: 'America/Los_Angeles',
  },
  '93643': {
    zip: '93643',
    city: 'North Fork',
    state: 'CA',
    latitude: '37.233523',
    longitude: '-119.500796',
    timeZoneId: 'America/Los_Angeles',
  },
  '93644': {
    zip: '93644',
    city: 'Oakhurst',
    state: 'CA',
    latitude: '37.336859',
    longitude: '-119.642905',
    timeZoneId: 'America/Los_Angeles',
  },
  '93645': {
    zip: '93645',
    city: 'O Neals',
    state: 'CA',
    latitude: '37.098562',
    longitude: '-119.716499',
    timeZoneId: 'America/Los_Angeles',
  },
  '93646': {
    zip: '93646',
    city: 'Orange Cove',
    state: 'CA',
    latitude: '36.625475',
    longitude: '-119.32041',
    timeZoneId: 'America/Los_Angeles',
  },
  '93647': {
    zip: '93647',
    city: 'Orosi',
    state: 'CA',
    latitude: '36.548019',
    longitude: '-119.262283',
    timeZoneId: 'America/Los_Angeles',
  },
  '93648': {
    zip: '93648',
    city: 'Parlier',
    state: 'CA',
    latitude: '36.616031',
    longitude: '-119.530882',
    timeZoneId: 'America/Los_Angeles',
  },
  '93649': {
    zip: '93649',
    city: 'Piedra',
    state: 'CA',
    latitude: '36.823999',
    longitude: '-119.35505',
    timeZoneId: 'America/Los_Angeles',
  },
  '93650': {
    zip: '93650',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.838859',
    longitude: '-119.800966',
    timeZoneId: 'America/Los_Angeles',
  },
  '93651': {
    zip: '93651',
    city: 'Prather',
    state: 'CA',
    latitude: '37.000931',
    longitude: '-119.524077',
    timeZoneId: 'America/Los_Angeles',
  },
  '93652': {
    zip: '93652',
    city: 'Raisin City',
    state: 'CA',
    latitude: '36.609193',
    longitude: '-119.87247',
    timeZoneId: 'America/Los_Angeles',
  },
  '93653': {
    zip: '93653',
    city: 'Raymond',
    state: 'CA',
    latitude: '37.318338',
    longitude: '-120.005507',
    timeZoneId: 'America/Los_Angeles',
  },
  '93654': {
    zip: '93654',
    city: 'Reedley',
    state: 'CA',
    latitude: '36.611335',
    longitude: '-119.43415',
    timeZoneId: 'America/Los_Angeles',
  },
  '93656': {
    zip: '93656',
    city: 'Riverdale',
    state: 'CA',
    latitude: '36.43953',
    longitude: '-119.909383',
    timeZoneId: 'America/Los_Angeles',
  },
  '93657': {
    zip: '93657',
    city: 'Sanger',
    state: 'CA',
    latitude: '36.78402',
    longitude: '-119.4687',
    timeZoneId: 'America/Los_Angeles',
  },
  '93660': {
    zip: '93660',
    city: 'San Joaquin',
    state: 'CA',
    latitude: '36.612847',
    longitude: '-120.177122',
    timeZoneId: 'America/Los_Angeles',
  },
  '93661': {
    zip: '93661',
    city: 'Santa Rita Park',
    state: 'CA',
    latitude: '37.108668',
    longitude: '-120.597447',
    timeZoneId: 'America/Los_Angeles',
  },
  '93662': {
    zip: '93662',
    city: 'Selma',
    state: 'CA',
    latitude: '36.541105',
    longitude: '-119.637973',
    timeZoneId: 'America/Los_Angeles',
  },
  '93664': {
    zip: '93664',
    city: 'Shaver Lake',
    state: 'CA',
    latitude: '37.092778',
    longitude: '-119.300445',
    timeZoneId: 'America/Los_Angeles',
  },
  '93665': {
    zip: '93665',
    city: 'South Dos Palos',
    state: 'CA',
    latitude: '36.965202',
    longitude: '-120.654813',
    timeZoneId: 'America/Los_Angeles',
  },
  '93666': {
    zip: '93666',
    city: 'Sultana',
    state: 'CA',
    latitude: '36.546876',
    longitude: '-119.33567',
    timeZoneId: 'America/Los_Angeles',
  },
  '93667': {
    zip: '93667',
    city: 'Tollhouse',
    state: 'CA',
    latitude: '36.987815',
    longitude: '-119.393501',
    timeZoneId: 'America/Los_Angeles',
  },
  '93668': {
    zip: '93668',
    city: 'Tranquillity',
    state: 'CA',
    latitude: '36.644518',
    longitude: '-120.258784',
    timeZoneId: 'America/Los_Angeles',
  },
  '93669': {
    zip: '93669',
    city: 'Wishon',
    state: 'CA',
    latitude: '37.32',
    longitude: '-119.556667',
    timeZoneId: 'America/Los_Angeles',
  },
  '93670': {
    zip: '93670',
    city: 'Yettem',
    state: 'CA',
    latitude: '36.484454',
    longitude: '-119.224712',
    timeZoneId: 'America/Los_Angeles',
  },
  '93673': {
    zip: '93673',
    city: 'Traver',
    state: 'CA',
    latitude: '36.455162',
    longitude: '-119.485438',
    timeZoneId: 'America/Los_Angeles',
  },
  '93675': {
    zip: '93675',
    city: 'Squaw Valley',
    state: 'CA',
    latitude: '36.624444',
    longitude: '-119.312778',
    timeZoneId: 'America/Los_Angeles',
  },
  '93701': {
    zip: '93701',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.751098',
    longitude: '-119.789664',
    timeZoneId: 'America/Los_Angeles',
  },
  '93702': {
    zip: '93702',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.740431',
    longitude: '-119.756053',
    timeZoneId: 'America/Los_Angeles',
  },
  '93703': {
    zip: '93703',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.766864',
    longitude: '-119.760866',
    timeZoneId: 'America/Los_Angeles',
  },
  '93704': {
    zip: '93704',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.799379',
    longitude: '-119.799461',
    timeZoneId: 'America/Los_Angeles',
  },
  '93705': {
    zip: '93705',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.79235',
    longitude: '-119.828938',
    timeZoneId: 'America/Los_Angeles',
  },
  '93706': {
    zip: '93706',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.702391',
    longitude: '-119.837956',
    timeZoneId: 'America/Los_Angeles',
  },
  '93707': {
    zip: '93707',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.730268',
    longitude: '-119.786943',
    timeZoneId: 'America/Los_Angeles',
  },
  '93708': {
    zip: '93708',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.736262',
    longitude: '-119.782514',
    timeZoneId: 'America/Los_Angeles',
  },
  '93709': {
    zip: '93709',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.731597',
    longitude: '-119.784692',
    timeZoneId: 'America/Los_Angeles',
  },
  '93710': {
    zip: '93710',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.828337',
    longitude: '-119.76308',
    timeZoneId: 'America/Los_Angeles',
  },
  '93711': {
    zip: '93711',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.820061',
    longitude: '-119.843171',
    timeZoneId: 'America/Los_Angeles',
  },
  '93712': {
    zip: '93712',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.610955',
    longitude: '-119.773998',
    timeZoneId: 'America/Los_Angeles',
  },
  '93714': {
    zip: '93714',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.735181',
    longitude: '-119.785635',
    timeZoneId: 'America/Los_Angeles',
  },
  '93715': {
    zip: '93715',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.733038',
    longitude: '-119.784751',
    timeZoneId: 'America/Los_Angeles',
  },
  '93716': {
    zip: '93716',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.731026',
    longitude: '-119.786485',
    timeZoneId: 'America/Los_Angeles',
  },
  '93717': {
    zip: '93717',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.734019',
    longitude: '-119.792056',
    timeZoneId: 'America/Los_Angeles',
  },
  '93718': {
    zip: '93718',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.734971',
    longitude: '-119.782302',
    timeZoneId: 'America/Los_Angeles',
  },
  '93720': {
    zip: '93720',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.864207',
    longitude: '-119.763501',
    timeZoneId: 'America/Los_Angeles',
  },
  '93721': {
    zip: '93721',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.73329',
    longitude: '-119.779567',
    timeZoneId: 'America/Los_Angeles',
  },
  '93722': {
    zip: '93722',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.796753',
    longitude: '-119.882901',
    timeZoneId: 'America/Los_Angeles',
  },
  '93723': {
    zip: '93723',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.797657',
    longitude: '-119.881938',
    timeZoneId: 'America/Los_Angeles',
  },
  '93724': {
    zip: '93724',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.725228',
    longitude: '-119.794478',
    timeZoneId: 'America/Los_Angeles',
  },
  '93725': {
    zip: '93725',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.662145',
    longitude: '-119.740783',
    timeZoneId: 'America/Los_Angeles',
  },
  '93726': {
    zip: '93726',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.79042',
    longitude: '-119.762793',
    timeZoneId: 'America/Los_Angeles',
  },
  '93727': {
    zip: '93727',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.753992',
    longitude: '-119.698212',
    timeZoneId: 'America/Los_Angeles',
  },
  '93728': {
    zip: '93728',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.757679',
    longitude: '-119.817036',
    timeZoneId: 'America/Los_Angeles',
  },
  '93729': {
    zip: '93729',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.852444',
    longitude: '-119.765566',
    timeZoneId: 'America/Los_Angeles',
  },
  '93730': {
    zip: '93730',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.889477',
    longitude: '-119.755253',
    timeZoneId: 'America/Los_Angeles',
  },
  '93737': {
    zip: '93737',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.740681',
    longitude: '-119.785728',
    timeZoneId: 'America/Los_Angeles',
  },
  '93740': {
    zip: '93740',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.82391',
    longitude: '-119.758958',
    timeZoneId: 'America/Los_Angeles',
  },
  '93741': {
    zip: '93741',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.798392',
    longitude: '-119.801743',
    timeZoneId: 'America/Los_Angeles',
  },
  '93744': {
    zip: '93744',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.75868',
    longitude: '-119.797616',
    timeZoneId: 'America/Los_Angeles',
  },
  '93745': {
    zip: '93745',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.707049',
    longitude: '-119.7535',
    timeZoneId: 'America/Los_Angeles',
  },
  '93747': {
    zip: '93747',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.803212',
    longitude: '-119.679921',
    timeZoneId: 'America/Los_Angeles',
  },
  '93750': {
    zip: '93750',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.83872',
    longitude: '-119.788009',
    timeZoneId: 'America/Los_Angeles',
  },
  '93755': {
    zip: '93755',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.787369',
    longitude: '-119.793518',
    timeZoneId: 'America/Los_Angeles',
  },
  '93760': {
    zip: '93760',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.735811',
    longitude: '-119.778758',
    timeZoneId: 'America/Los_Angeles',
  },
  '93761': {
    zip: '93761',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.751664',
    longitude: '-119.788568',
    timeZoneId: 'America/Los_Angeles',
  },
  '93764': {
    zip: '93764',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.734126',
    longitude: '-119.784633',
    timeZoneId: 'America/Los_Angeles',
  },
  '93765': {
    zip: '93765',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.833866',
    longitude: '-119.82897',
    timeZoneId: 'America/Los_Angeles',
  },
  '93771': {
    zip: '93771',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.749697',
    longitude: '-119.792435',
    timeZoneId: 'America/Los_Angeles',
  },
  '93772': {
    zip: '93772',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.74804',
    longitude: '-119.791751',
    timeZoneId: 'America/Los_Angeles',
  },
  '93773': {
    zip: '93773',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.71998',
    longitude: '-119.858055',
    timeZoneId: 'America/Los_Angeles',
  },
  '93774': {
    zip: '93774',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.748057',
    longitude: '-119.788731',
    timeZoneId: 'America/Los_Angeles',
  },
  '93775': {
    zip: '93775',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.733005',
    longitude: '-119.747107',
    timeZoneId: 'America/Los_Angeles',
  },
  '93776': {
    zip: '93776',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.74636',
    longitude: '-119.79049',
    timeZoneId: 'America/Los_Angeles',
  },
  '93777': {
    zip: '93777',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.751091',
    longitude: '-119.79',
    timeZoneId: 'America/Los_Angeles',
  },
  '93778': {
    zip: '93778',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.747457',
    longitude: '-119.790491',
    timeZoneId: 'America/Los_Angeles',
  },
  '93779': {
    zip: '93779',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.747859',
    longitude: '-119.791661',
    timeZoneId: 'America/Los_Angeles',
  },
  '93786': {
    zip: '93786',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.638642',
    longitude: '-119.90311',
    timeZoneId: 'America/Los_Angeles',
  },
  '93790': {
    zip: '93790',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.78944',
    longitude: '-119.827606',
    timeZoneId: 'America/Los_Angeles',
  },
  '93791': {
    zip: '93791',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.790318',
    longitude: '-119.829786',
    timeZoneId: 'America/Los_Angeles',
  },
  '93792': {
    zip: '93792',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.781298',
    longitude: '-119.827169',
    timeZoneId: 'America/Los_Angeles',
  },
  '93793': {
    zip: '93793',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.787165',
    longitude: '-119.83021',
    timeZoneId: 'America/Los_Angeles',
  },
  '93794': {
    zip: '93794',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.787969',
    longitude: '-119.829498',
    timeZoneId: 'America/Los_Angeles',
  },
  '93844': {
    zip: '93844',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.753633',
    longitude: '-119.673434',
    timeZoneId: 'America/Los_Angeles',
  },
  '93888': {
    zip: '93888',
    city: 'Fresno',
    state: 'CA',
    latitude: '36.753596',
    longitude: '-119.678137',
    timeZoneId: 'America/Los_Angeles',
  },
  '93901': {
    zip: '93901',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.667014',
    longitude: '-121.654897',
    timeZoneId: 'America/Los_Angeles',
  },
  '93902': {
    zip: '93902',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.672603',
    longitude: '-121.657451',
    timeZoneId: 'America/Los_Angeles',
  },
  '93905': {
    zip: '93905',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.680613',
    longitude: '-121.618316',
    timeZoneId: 'America/Los_Angeles',
  },
  '93906': {
    zip: '93906',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.709565',
    longitude: '-121.639635',
    timeZoneId: 'America/Los_Angeles',
  },
  '93907': {
    zip: '93907',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.75732',
    longitude: '-121.66812',
    timeZoneId: 'America/Los_Angeles',
  },
  '93908': {
    zip: '93908',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.603909',
    longitude: '-121.654754',
    timeZoneId: 'America/Los_Angeles',
  },
  '93912': {
    zip: '93912',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.738648',
    longitude: '-121.68159',
    timeZoneId: 'America/Los_Angeles',
  },
  '93915': {
    zip: '93915',
    city: 'Salinas',
    state: 'CA',
    latitude: '36.675734',
    longitude: '-121.627987',
    timeZoneId: 'America/Los_Angeles',
  },
  '93920': {
    zip: '93920',
    city: 'Big Sur',
    state: 'CA',
    latitude: '36.13588',
    longitude: '-121.659477',
    timeZoneId: 'America/Los_Angeles',
  },
  '93921': {
    zip: '93921',
    city: 'Carmel by the Sea',
    state: 'CA',
    latitude: '36.562001',
    longitude: '-121.896409',
    timeZoneId: 'America/Los_Angeles',
  },
  '93922': {
    zip: '93922',
    city: 'Carmel',
    state: 'CA',
    latitude: '36.489187',
    longitude: '-121.744754',
    timeZoneId: 'America/Los_Angeles',
  },
  '93923': {
    zip: '93923',
    city: 'Carmel',
    state: 'CA',
    latitude: '36.35976',
    longitude: '-121.79628',
    timeZoneId: 'America/Los_Angeles',
  },
  '93924': {
    zip: '93924',
    city: 'Carmel Valley',
    state: 'CA',
    latitude: '36.39366',
    longitude: '-121.67502',
    timeZoneId: 'America/Los_Angeles',
  },
  '93925': {
    zip: '93925',
    city: 'Chualar',
    state: 'CA',
    latitude: '36.591206',
    longitude: '-121.465765',
    timeZoneId: 'America/Los_Angeles',
  },
  '93926': {
    zip: '93926',
    city: 'Gonzales',
    state: 'CA',
    latitude: '36.517063',
    longitude: '-121.430276',
    timeZoneId: 'America/Los_Angeles',
  },
  '93927': {
    zip: '93927',
    city: 'Greenfield',
    state: 'CA',
    latitude: '36.309634',
    longitude: '-121.319264',
    timeZoneId: 'America/Los_Angeles',
  },
  '93928': {
    zip: '93928',
    city: 'Jolon',
    state: 'CA',
    latitude: '36.048776',
    longitude: '-121.200408',
    timeZoneId: 'America/Los_Angeles',
  },
  '93930': {
    zip: '93930',
    city: 'King City',
    state: 'CA',
    latitude: '36.202389',
    longitude: '-121.137276',
    timeZoneId: 'America/Los_Angeles',
  },
  '93932': {
    zip: '93932',
    city: 'Lockwood',
    state: 'CA',
    latitude: '35.94837',
    longitude: '-121.194481',
    timeZoneId: 'America/Los_Angeles',
  },
  '93933': {
    zip: '93933',
    city: 'Marina',
    state: 'CA',
    latitude: '36.683535',
    longitude: '-121.792949',
    timeZoneId: 'America/Los_Angeles',
  },
  '93940': {
    zip: '93940',
    city: 'Monterey',
    state: 'CA',
    latitude: '36.5877',
    longitude: '-121.8492',
    timeZoneId: 'America/Los_Angeles',
  },
  '93942': {
    zip: '93942',
    city: 'Monterey',
    state: 'CA',
    latitude: '36.616965',
    longitude: '-121.898809',
    timeZoneId: 'America/Los_Angeles',
  },
  '93943': {
    zip: '93943',
    city: 'Monterey',
    state: 'CA',
    latitude: '36.580963',
    longitude: '-121.831768',
    timeZoneId: 'America/Los_Angeles',
  },
  '93944': {
    zip: '93944',
    city: 'Monterey',
    state: 'CA',
    latitude: '36.579877',
    longitude: '-121.834074',
    timeZoneId: 'America/Los_Angeles',
  },
  '93950': {
    zip: '93950',
    city: 'Pacific Grove',
    state: 'CA',
    latitude: '36.616554',
    longitude: '-121.921728',
    timeZoneId: 'America/Los_Angeles',
  },
  '93953': {
    zip: '93953',
    city: 'Pebble Beach',
    state: 'CA',
    latitude: '36.578257',
    longitude: '-121.948084',
    timeZoneId: 'America/Los_Angeles',
  },
  '93954': {
    zip: '93954',
    city: 'San Lucas',
    state: 'CA',
    latitude: '36.124304',
    longitude: '-121.021144',
    timeZoneId: 'America/Los_Angeles',
  },
  '93955': {
    zip: '93955',
    city: 'Seaside',
    state: 'CA',
    latitude: '36.608641',
    longitude: '-121.837368',
    timeZoneId: 'America/Los_Angeles',
  },
  '93960': {
    zip: '93960',
    city: 'Soledad',
    state: 'CA',
    latitude: '36.411765',
    longitude: '-121.316768',
    timeZoneId: 'America/Los_Angeles',
  },
  '93962': {
    zip: '93962',
    city: 'Spreckels',
    state: 'CA',
    latitude: '36.677778',
    longitude: '-121.654444',
    timeZoneId: 'America/Los_Angeles',
  },
  '94002': {
    zip: '94002',
    city: 'Belmont',
    state: 'CA',
    latitude: '37.51445',
    longitude: '-122.296118',
    timeZoneId: 'America/Los_Angeles',
  },
  '94005': {
    zip: '94005',
    city: 'Brisbane',
    state: 'CA',
    latitude: '37.687731',
    longitude: '-122.404012',
    timeZoneId: 'America/Los_Angeles',
  },
  '94010': {
    zip: '94010',
    city: 'Burlingame',
    state: 'CA',
    latitude: '37.570237',
    longitude: '-122.360344',
    timeZoneId: 'America/Los_Angeles',
  },
  '94011': {
    zip: '94011',
    city: 'Burlingame',
    state: 'CA',
    latitude: '37.579502',
    longitude: '-122.344321',
    timeZoneId: 'America/Los_Angeles',
  },
  '94014': {
    zip: '94014',
    city: 'Daly City',
    state: 'CA',
    latitude: '37.68948',
    longitude: '-122.43936',
    timeZoneId: 'America/Los_Angeles',
  },
  '94015': {
    zip: '94015',
    city: 'Daly City',
    state: 'CA',
    latitude: '37.679341',
    longitude: '-122.483788',
    timeZoneId: 'America/Los_Angeles',
  },
  '94016': {
    zip: '94016',
    city: 'Daly City',
    state: 'CA',
    latitude: '37.707328',
    longitude: '-122.454928',
    timeZoneId: 'America/Los_Angeles',
  },
  '94017': {
    zip: '94017',
    city: 'Daly City',
    state: 'CA',
    latitude: '37.688779',
    longitude: '-122.468923',
    timeZoneId: 'America/Los_Angeles',
  },
  '94018': {
    zip: '94018',
    city: 'El Granada',
    state: 'CA',
    latitude: '37.505155',
    longitude: '-122.470995',
    timeZoneId: 'America/Los_Angeles',
  },
  '94019': {
    zip: '94019',
    city: 'Half Moon Bay',
    state: 'CA',
    latitude: '37.47996',
    longitude: '-122.33088',
    timeZoneId: 'America/Los_Angeles',
  },
  '94020': {
    zip: '94020',
    city: 'La Honda',
    state: 'CA',
    latitude: '37.285795',
    longitude: '-122.226668',
    timeZoneId: 'America/Los_Angeles',
  },
  '94021': {
    zip: '94021',
    city: 'Loma Mar',
    state: 'CA',
    latitude: '37.242646',
    longitude: '-122.254745',
    timeZoneId: 'America/Los_Angeles',
  },
  '94022': {
    zip: '94022',
    city: 'Los Altos',
    state: 'CA',
    latitude: '37.36656',
    longitude: '-122.13696',
    timeZoneId: 'America/Los_Angeles',
  },
  '94023': {
    zip: '94023',
    city: 'Los Altos',
    state: 'CA',
    latitude: '37.376602',
    longitude: '-122.114401',
    timeZoneId: 'America/Los_Angeles',
  },
  '94024': {
    zip: '94024',
    city: 'Los Altos',
    state: 'CA',
    latitude: '37.353924',
    longitude: '-122.085935',
    timeZoneId: 'America/Los_Angeles',
  },
  '94025': {
    zip: '94025',
    city: 'Menlo Park',
    state: 'CA',
    latitude: '37.436934',
    longitude: '-122.193602',
    timeZoneId: 'America/Los_Angeles',
  },
  '94026': {
    zip: '94026',
    city: 'Menlo Park',
    state: 'CA',
    latitude: '37.481131',
    longitude: '-122.180141',
    timeZoneId: 'America/Los_Angeles',
  },
  '94027': {
    zip: '94027',
    city: 'Atherton',
    state: 'CA',
    latitude: '37.456184',
    longitude: '-122.204497',
    timeZoneId: 'America/Los_Angeles',
  },
  '94028': {
    zip: '94028',
    city: 'Portola Valley',
    state: 'CA',
    latitude: '37.37986',
    longitude: '-122.208569',
    timeZoneId: 'America/Los_Angeles',
  },
  '94030': {
    zip: '94030',
    city: 'Millbrae',
    state: 'CA',
    latitude: '37.599952',
    longitude: '-122.400126',
    timeZoneId: 'America/Los_Angeles',
  },
  '94035': {
    zip: '94035',
    city: 'Mountain View',
    state: 'CA',
    latitude: '37.412894',
    longitude: '-122.049891',
    timeZoneId: 'America/Los_Angeles',
  },
  '94037': {
    zip: '94037',
    city: 'Montara',
    state: 'CA',
    latitude: '37.543182',
    longitude: '-122.507644',
    timeZoneId: 'America/Los_Angeles',
  },
  '94038': {
    zip: '94038',
    city: 'Moss Beach',
    state: 'CA',
    latitude: '37.546285',
    longitude: '-122.498524',
    timeZoneId: 'America/Los_Angeles',
  },
  '94039': {
    zip: '94039',
    city: 'Mountain View',
    state: 'CA',
    latitude: '37.395047',
    longitude: '-122.076661',
    timeZoneId: 'America/Los_Angeles',
  },
  '94040': {
    zip: '94040',
    city: 'Mountain View',
    state: 'CA',
    latitude: '37.380469',
    longitude: '-122.08187',
    timeZoneId: 'America/Los_Angeles',
  },
  '94041': {
    zip: '94041',
    city: 'Mountain View',
    state: 'CA',
    latitude: '37.38739',
    longitude: '-122.071863',
    timeZoneId: 'America/Los_Angeles',
  },
  '94042': {
    zip: '94042',
    city: 'Mountain View',
    state: 'CA',
    latitude: '37.410772',
    longitude: '-122.087316',
    timeZoneId: 'America/Los_Angeles',
  },
  '94043': {
    zip: '94043',
    city: 'Mountain View',
    state: 'CA',
    latitude: '37.405548',
    longitude: '-122.078481',
    timeZoneId: 'America/Los_Angeles',
  },
  '94044': {
    zip: '94044',
    city: 'Pacifica',
    state: 'CA',
    latitude: '37.602347',
    longitude: '-122.485548',
    timeZoneId: 'America/Los_Angeles',
  },
  '94060': {
    zip: '94060',
    city: 'Pescadero',
    state: 'CA',
    latitude: '37.207186',
    longitude: '-122.38105',
    timeZoneId: 'America/Los_Angeles',
  },
  '94061': {
    zip: '94061',
    city: 'Redwood City',
    state: 'CA',
    latitude: '37.462453',
    longitude: '-122.234484',
    timeZoneId: 'America/Los_Angeles',
  },
  '94062': {
    zip: '94062',
    city: 'Redwood City',
    state: 'CA',
    latitude: '37.448337',
    longitude: '-122.268736',
    timeZoneId: 'America/Los_Angeles',
  },
  '94063': {
    zip: '94063',
    city: 'Redwood City',
    state: 'CA',
    latitude: '37.492395',
    longitude: '-122.203623',
    timeZoneId: 'America/Los_Angeles',
  },
  '94064': {
    zip: '94064',
    city: 'Redwood City',
    state: 'CA',
    latitude: '37.462934',
    longitude: '-122.255439',
    timeZoneId: 'America/Los_Angeles',
  },
  '94065': {
    zip: '94065',
    city: 'Redwood City',
    state: 'CA',
    latitude: '37.53347',
    longitude: '-122.250368',
    timeZoneId: 'America/Los_Angeles',
  },
  '94066': {
    zip: '94066',
    city: 'San Bruno',
    state: 'CA',
    latitude: '37.62501',
    longitude: '-122.432243',
    timeZoneId: 'America/Los_Angeles',
  },
  '94070': {
    zip: '94070',
    city: 'San Carlos',
    state: 'CA',
    latitude: '37.494512',
    longitude: '-122.269775',
    timeZoneId: 'America/Los_Angeles',
  },
  '94074': {
    zip: '94074',
    city: 'San Gregorio',
    state: 'CA',
    latitude: '37.297693',
    longitude: '-122.364423',
    timeZoneId: 'America/Los_Angeles',
  },
  '94080': {
    zip: '94080',
    city: 'South San Francisco',
    state: 'CA',
    latitude: '37.652366',
    longitude: '-122.430543',
    timeZoneId: 'America/Los_Angeles',
  },
  '94083': {
    zip: '94083',
    city: 'South San Francisco',
    state: 'CA',
    latitude: '37.643644',
    longitude: '-122.411982',
    timeZoneId: 'America/Los_Angeles',
  },
  '94085': {
    zip: '94085',
    city: 'Sunnyvale',
    state: 'CA',
    latitude: '37.3663',
    longitude: '-122.033959',
    timeZoneId: 'America/Los_Angeles',
  },
  '94086': {
    zip: '94086',
    city: 'Sunnyvale',
    state: 'CA',
    latitude: '37.3772',
    longitude: '-122.019178',
    timeZoneId: 'America/Los_Angeles',
  },
  '94087': {
    zip: '94087',
    city: 'Sunnyvale',
    state: 'CA',
    latitude: '37.348182',
    longitude: '-122.031788',
    timeZoneId: 'America/Los_Angeles',
  },
  '94088': {
    zip: '94088',
    city: 'Sunnyvale',
    state: 'CA',
    latitude: '37.390976',
    longitude: '-122.037328',
    timeZoneId: 'America/Los_Angeles',
  },
  '94089': {
    zip: '94089',
    city: 'Sunnyvale',
    state: 'CA',
    latitude: '37.401284',
    longitude: '-122.008179',
    timeZoneId: 'America/Los_Angeles',
  },
  '94102': {
    zip: '94102',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.780793',
    longitude: '-122.417576',
    timeZoneId: 'America/Los_Angeles',
  },
  '94103': {
    zip: '94103',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.773792',
    longitude: '-122.409865',
    timeZoneId: 'America/Los_Angeles',
  },
  '94104': {
    zip: '94104',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.79248',
    longitude: '-122.397651',
    timeZoneId: 'America/Los_Angeles',
  },
  '94105': {
    zip: '94105',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.786816',
    longitude: '-122.39558',
    timeZoneId: 'America/Los_Angeles',
  },
  '94107': {
    zip: '94107',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.77083',
    longitude: '-122.395463',
    timeZoneId: 'America/Los_Angeles',
  },
  '94108': {
    zip: '94108',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.789438',
    longitude: '-122.406217',
    timeZoneId: 'America/Los_Angeles',
  },
  '94109': {
    zip: '94109',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.794508',
    longitude: '-122.418244',
    timeZoneId: 'America/Los_Angeles',
  },
  '94110': {
    zip: '94110',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.748224',
    longitude: '-122.415699',
    timeZoneId: 'America/Los_Angeles',
  },
  '94111': {
    zip: '94111',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.79801',
    longitude: '-122.396965',
    timeZoneId: 'America/Los_Angeles',
  },
  '94112': {
    zip: '94112',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.721519',
    longitude: '-122.439949',
    timeZoneId: 'America/Los_Angeles',
  },
  '94114': {
    zip: '94114',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.759122',
    longitude: '-122.438712',
    timeZoneId: 'America/Los_Angeles',
  },
  '94115': {
    zip: '94115',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.782569',
    longitude: '-122.436359',
    timeZoneId: 'America/Los_Angeles',
  },
  '94116': {
    zip: '94116',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.741108',
    longitude: '-122.485687',
    timeZoneId: 'America/Los_Angeles',
  },
  '94117': {
    zip: '94117',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.770561',
    longitude: '-122.441545',
    timeZoneId: 'America/Los_Angeles',
  },
  '94118': {
    zip: '94118',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.780181',
    longitude: '-122.458762',
    timeZoneId: 'America/Los_Angeles',
  },
  '94119': {
    zip: '94119',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.785934',
    longitude: '-122.455188',
    timeZoneId: 'America/Los_Angeles',
  },
  '94120': {
    zip: '94120',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.793768',
    longitude: '-122.402158',
    timeZoneId: 'America/Los_Angeles',
  },
  '94121': {
    zip: '94121',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.77977',
    longitude: '-122.489312',
    timeZoneId: 'America/Los_Angeles',
  },
  '94122': {
    zip: '94122',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.760276',
    longitude: '-122.482029',
    timeZoneId: 'America/Los_Angeles',
  },
  '94123': {
    zip: '94123',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.801619',
    longitude: '-122.435242',
    timeZoneId: 'America/Los_Angeles',
  },
  '94124': {
    zip: '94124',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.735457',
    longitude: '-122.391588',
    timeZoneId: 'America/Los_Angeles',
  },
  '94125': {
    zip: '94125',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.731384',
    longitude: '-122.384963',
    timeZoneId: 'America/Los_Angeles',
  },
  '94126': {
    zip: '94126',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.791853',
    longitude: '-122.390378',
    timeZoneId: 'America/Los_Angeles',
  },
  '94127': {
    zip: '94127',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.735401',
    longitude: '-122.459035',
    timeZoneId: 'America/Los_Angeles',
  },
  '94128': {
    zip: '94128',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.607851',
    longitude: '-122.386722',
    timeZoneId: 'America/Los_Angeles',
  },
  '94129': {
    zip: '94129',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.7954',
    longitude: '-122.463998',
    timeZoneId: 'America/Los_Angeles',
  },
  '94130': {
    zip: '94130',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.821576',
    longitude: '-122.371703',
    timeZoneId: 'America/Los_Angeles',
  },
  '94131': {
    zip: '94131',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.740516',
    longitude: '-122.439028',
    timeZoneId: 'America/Los_Angeles',
  },
  '94132': {
    zip: '94132',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.723729',
    longitude: '-122.480389',
    timeZoneId: 'America/Los_Angeles',
  },
  '94133': {
    zip: '94133',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.798333',
    longitude: '-122.409352',
    timeZoneId: 'America/Los_Angeles',
  },
  '94134': {
    zip: '94134',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.722221',
    longitude: '-122.411294',
    timeZoneId: 'America/Los_Angeles',
  },
  '94137': {
    zip: '94137',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.793155',
    longitude: '-122.404322',
    timeZoneId: 'America/Los_Angeles',
  },
  '94139': {
    zip: '94139',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.794865',
    longitude: '-122.406188',
    timeZoneId: 'America/Los_Angeles',
  },
  '94140': {
    zip: '94140',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.7578',
    longitude: '-122.412697',
    timeZoneId: 'America/Los_Angeles',
  },
  '94141': {
    zip: '94141',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.766839',
    longitude: '-122.408909',
    timeZoneId: 'America/Los_Angeles',
  },
  '94142': {
    zip: '94142',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.784406',
    longitude: '-122.412228',
    timeZoneId: 'America/Los_Angeles',
  },
  '94143': {
    zip: '94143',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.76379',
    longitude: '-122.457389',
    timeZoneId: 'America/Los_Angeles',
  },
  '94144': {
    zip: '94144',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.783348',
    longitude: '-122.459783',
    timeZoneId: 'America/Los_Angeles',
  },
  '94145': {
    zip: '94145',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.794247',
    longitude: '-122.400377',
    timeZoneId: 'America/Los_Angeles',
  },
  '94146': {
    zip: '94146',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.760148',
    longitude: '-122.433279',
    timeZoneId: 'America/Los_Angeles',
  },
  '94147': {
    zip: '94147',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.800828',
    longitude: '-122.434822',
    timeZoneId: 'America/Los_Angeles',
  },
  '94151': {
    zip: '94151',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.793488',
    longitude: '-122.40018',
    timeZoneId: 'America/Los_Angeles',
  },
  '94158': {
    zip: '94158',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.768342',
    longitude: '-122.394191',
    timeZoneId: 'America/Los_Angeles',
  },
  '94159': {
    zip: '94159',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.784023',
    longitude: '-122.453299',
    timeZoneId: 'America/Los_Angeles',
  },
  '94160': {
    zip: '94160',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.718015',
    longitude: '-122.40439',
    timeZoneId: 'America/Los_Angeles',
  },
  '94161': {
    zip: '94161',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.79853',
    longitude: '-122.39989',
    timeZoneId: 'America/Los_Angeles',
  },
  '94163': {
    zip: '94163',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.791422',
    longitude: '-122.399414',
    timeZoneId: 'America/Los_Angeles',
  },
  '94164': {
    zip: '94164',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.787312',
    longitude: '-122.422055',
    timeZoneId: 'America/Los_Angeles',
  },
  '94172': {
    zip: '94172',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.760435',
    longitude: '-122.483159',
    timeZoneId: 'America/Los_Angeles',
  },
  '94177': {
    zip: '94177',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.791933',
    longitude: '-122.39841',
    timeZoneId: 'America/Los_Angeles',
  },
  '94188': {
    zip: '94188',
    city: 'San Francisco',
    state: 'CA',
    latitude: '37.74066',
    longitude: '-122.383372',
    timeZoneId: 'America/Los_Angeles',
  },
  '94203': {
    zip: '94203',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584351',
    longitude: '-121.493764',
    timeZoneId: 'America/Los_Angeles',
  },
  '94204': {
    zip: '94204',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.602817',
    longitude: '-121.445876',
    timeZoneId: 'America/Los_Angeles',
  },
  '94205': {
    zip: '94205',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.476582',
    longitude: '-121.442713',
    timeZoneId: 'America/Los_Angeles',
  },
  '94206': {
    zip: '94206',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.494822',
    longitude: '-121.449573',
    timeZoneId: 'America/Los_Angeles',
  },
  '94207': {
    zip: '94207',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.581693',
    longitude: '-121.488693',
    timeZoneId: 'America/Los_Angeles',
  },
  '94208': {
    zip: '94208',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584815',
    longitude: '-121.494887',
    timeZoneId: 'America/Los_Angeles',
  },
  '94209': {
    zip: '94209',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.58354',
    longitude: '-121.493379',
    timeZoneId: 'America/Los_Angeles',
  },
  '94211': {
    zip: '94211',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.586276',
    longitude: '-121.495459',
    timeZoneId: 'America/Los_Angeles',
  },
  '94229': {
    zip: '94229',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.587116',
    longitude: '-121.495126',
    timeZoneId: 'America/Los_Angeles',
  },
  '94230': {
    zip: '94230',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.474474',
    longitude: '-121.444005',
    timeZoneId: 'America/Los_Angeles',
  },
  '94232': {
    zip: '94232',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.554971',
    longitude: '-121.496277',
    timeZoneId: 'America/Los_Angeles',
  },
  '94234': {
    zip: '94234',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.586063',
    longitude: '-121.494453',
    timeZoneId: 'America/Los_Angeles',
  },
  '94235': {
    zip: '94235',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584467',
    longitude: '-121.495247',
    timeZoneId: 'America/Los_Angeles',
  },
  '94236': {
    zip: '94236',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.583196',
    longitude: '-121.490865',
    timeZoneId: 'America/Los_Angeles',
  },
  '94237': {
    zip: '94237',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585474',
    longitude: '-121.493315',
    timeZoneId: 'America/Los_Angeles',
  },
  '94239': {
    zip: '94239',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.55651',
    longitude: '-121.496477',
    timeZoneId: 'America/Los_Angeles',
  },
  '94240': {
    zip: '94240',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585287',
    longitude: '-121.494786',
    timeZoneId: 'America/Los_Angeles',
  },
  '94244': {
    zip: '94244',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.588001',
    longitude: '-121.493785',
    timeZoneId: 'America/Los_Angeles',
  },
  '94245': {
    zip: '94245',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.587191',
    longitude: '-121.491543',
    timeZoneId: 'America/Los_Angeles',
  },
  '94247': {
    zip: '94247',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.586431',
    longitude: '-121.495039',
    timeZoneId: 'America/Los_Angeles',
  },
  '94248': {
    zip: '94248',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.586457',
    longitude: '-121.493033',
    timeZoneId: 'America/Los_Angeles',
  },
  '94249': {
    zip: '94249',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.57302',
    longitude: '-121.467357',
    timeZoneId: 'America/Los_Angeles',
  },
  '94250': {
    zip: '94250',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.581619',
    longitude: '-121.491498',
    timeZoneId: 'America/Los_Angeles',
  },
  '94252': {
    zip: '94252',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.586196',
    longitude: '-121.495323',
    timeZoneId: 'America/Los_Angeles',
  },
  '94254': {
    zip: '94254',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.58508',
    longitude: '-121.491972',
    timeZoneId: 'America/Los_Angeles',
  },
  '94256': {
    zip: '94256',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.583585',
    longitude: '-121.494299',
    timeZoneId: 'America/Los_Angeles',
  },
  '94257': {
    zip: '94257',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.587116',
    longitude: '-121.493274',
    timeZoneId: 'America/Los_Angeles',
  },
  '94258': {
    zip: '94258',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585278',
    longitude: '-121.494965',
    timeZoneId: 'America/Los_Angeles',
  },
  '94259': {
    zip: '94259',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.558715',
    longitude: '-121.497522',
    timeZoneId: 'America/Los_Angeles',
  },
  '94261': {
    zip: '94261',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584987',
    longitude: '-121.493673',
    timeZoneId: 'America/Los_Angeles',
  },
  '94262': {
    zip: '94262',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.583814',
    longitude: '-121.492154',
    timeZoneId: 'America/Los_Angeles',
  },
  '94263': {
    zip: '94263',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585076',
    longitude: '-121.491659',
    timeZoneId: 'America/Los_Angeles',
  },
  '94267': {
    zip: '94267',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.582798',
    longitude: '-121.490569',
    timeZoneId: 'America/Los_Angeles',
  },
  '94268': {
    zip: '94268',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.582457',
    longitude: '-121.491815',
    timeZoneId: 'America/Los_Angeles',
  },
  '94269': {
    zip: '94269',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.555479',
    longitude: '-121.496541',
    timeZoneId: 'America/Los_Angeles',
  },
  '94271': {
    zip: '94271',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.583207',
    longitude: '-121.491295',
    timeZoneId: 'America/Los_Angeles',
  },
  '94273': {
    zip: '94273',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.587118',
    longitude: '-121.494479',
    timeZoneId: 'America/Los_Angeles',
  },
  '94274': {
    zip: '94274',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.586449',
    longitude: '-121.494078',
    timeZoneId: 'America/Los_Angeles',
  },
  '94277': {
    zip: '94277',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.58521',
    longitude: '-121.489578',
    timeZoneId: 'America/Los_Angeles',
  },
  '94278': {
    zip: '94278',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.574262',
    longitude: '-121.468927',
    timeZoneId: 'America/Los_Angeles',
  },
  '94279': {
    zip: '94279',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585681',
    longitude: '-121.494555',
    timeZoneId: 'America/Los_Angeles',
  },
  '94280': {
    zip: '94280',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.582315',
    longitude: '-121.494471',
    timeZoneId: 'America/Los_Angeles',
  },
  '94282': {
    zip: '94282',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584515',
    longitude: '-121.49449',
    timeZoneId: 'America/Los_Angeles',
  },
  '94283': {
    zip: '94283',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.477355',
    longitude: '-121.444157',
    timeZoneId: 'America/Los_Angeles',
  },
  '94284': {
    zip: '94284',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.553196',
    longitude: '-121.501479',
    timeZoneId: 'America/Los_Angeles',
  },
  '94285': {
    zip: '94285',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.552316',
    longitude: '-121.500719',
    timeZoneId: 'America/Los_Angeles',
  },
  '94287': {
    zip: '94287',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585797',
    longitude: '-121.493535',
    timeZoneId: 'America/Los_Angeles',
  },
  '94288': {
    zip: '94288',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.582186',
    longitude: '-121.492888',
    timeZoneId: 'America/Los_Angeles',
  },
  '94289': {
    zip: '94289',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.587077',
    longitude: '-121.490815',
    timeZoneId: 'America/Los_Angeles',
  },
  '94290': {
    zip: '94290',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.555426',
    longitude: '-121.499265',
    timeZoneId: 'America/Los_Angeles',
  },
  '94291': {
    zip: '94291',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.559052',
    longitude: '-121.497218',
    timeZoneId: 'America/Los_Angeles',
  },
  '94293': {
    zip: '94293',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.55478',
    longitude: '-121.495621',
    timeZoneId: 'America/Los_Angeles',
  },
  '94294': {
    zip: '94294',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.556446',
    longitude: '-121.494212',
    timeZoneId: 'America/Los_Angeles',
  },
  '94295': {
    zip: '94295',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584072',
    longitude: '-121.493284',
    timeZoneId: 'America/Los_Angeles',
  },
  '94296': {
    zip: '94296',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.570922',
    longitude: '-121.502908',
    timeZoneId: 'America/Los_Angeles',
  },
  '94297': {
    zip: '94297',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.552997',
    longitude: '-121.497372',
    timeZoneId: 'America/Los_Angeles',
  },
  '94298': {
    zip: '94298',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.58402',
    longitude: '-121.494416',
    timeZoneId: 'America/Los_Angeles',
  },
  '94299': {
    zip: '94299',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.564429',
    longitude: '-121.469141',
    timeZoneId: 'America/Los_Angeles',
  },
  '94301': {
    zip: '94301',
    city: 'Palo Alto',
    state: 'CA',
    latitude: '37.439933',
    longitude: '-122.151692',
    timeZoneId: 'America/Los_Angeles',
  },
  '94302': {
    zip: '94302',
    city: 'Palo Alto',
    state: 'CA',
    latitude: '37.447087',
    longitude: '-122.156083',
    timeZoneId: 'America/Los_Angeles',
  },
  '94303': {
    zip: '94303',
    city: 'Palo Alto',
    state: 'CA',
    latitude: '37.44936',
    longitude: '-122.12526',
    timeZoneId: 'America/Los_Angeles',
  },
  '94304': {
    zip: '94304',
    city: 'Palo Alto',
    state: 'CA',
    latitude: '37.40813',
    longitude: '-122.158663',
    timeZoneId: 'America/Los_Angeles',
  },
  '94305': {
    zip: '94305',
    city: 'Stanford',
    state: 'CA',
    latitude: '37.419371',
    longitude: '-122.163685',
    timeZoneId: 'America/Los_Angeles',
  },
  '94306': {
    zip: '94306',
    city: 'Palo Alto',
    state: 'CA',
    latitude: '37.417097',
    longitude: '-122.131989',
    timeZoneId: 'America/Los_Angeles',
  },
  '94309': {
    zip: '94309',
    city: 'Palo Alto',
    state: 'CA',
    latitude: '37.416667',
    longitude: '-122.166667',
    timeZoneId: 'America/Los_Angeles',
  },
  '94401': {
    zip: '94401',
    city: 'San Mateo',
    state: 'CA',
    latitude: '37.573151',
    longitude: '-122.32051',
    timeZoneId: 'America/Los_Angeles',
  },
  '94402': {
    zip: '94402',
    city: 'San Mateo',
    state: 'CA',
    latitude: '37.546272',
    longitude: '-122.329933',
    timeZoneId: 'America/Los_Angeles',
  },
  '94403': {
    zip: '94403',
    city: 'San Mateo',
    state: 'CA',
    latitude: '37.53982',
    longitude: '-122.303572',
    timeZoneId: 'America/Los_Angeles',
  },
  '94404': {
    zip: '94404',
    city: 'San Mateo',
    state: 'CA',
    latitude: '37.54793',
    longitude: '-122.26931',
    timeZoneId: 'America/Los_Angeles',
  },
  '94497': {
    zip: '94497',
    city: 'San Mateo',
    state: 'CA',
    latitude: '37.562555',
    longitude: '-122.324828',
    timeZoneId: 'America/Los_Angeles',
  },
  '94501': {
    zip: '94501',
    city: 'Alameda',
    state: 'CA',
    latitude: '37.760574',
    longitude: '-122.260375',
    timeZoneId: 'America/Los_Angeles',
  },
  '94502': {
    zip: '94502',
    city: 'Alameda',
    state: 'CA',
    latitude: '37.739355',
    longitude: '-122.242485',
    timeZoneId: 'America/Los_Angeles',
  },
  '94503': {
    zip: '94503',
    city: 'American Canyon',
    state: 'CA',
    latitude: '38.116667',
    longitude: '-122.233333',
    timeZoneId: 'America/Los_Angeles',
  },
  '94505': {
    zip: '94505',
    city: 'Discovery Bay',
    state: 'CA',
    latitude: '37.903278',
    longitude: '-121.602226',
    timeZoneId: 'America/Los_Angeles',
  },
  '94506': {
    zip: '94506',
    city: 'Danville',
    state: 'CA',
    latitude: '37.81065',
    longitude: '-121.912874',
    timeZoneId: 'America/Los_Angeles',
  },
  '94507': {
    zip: '94507',
    city: 'Alamo',
    state: 'CA',
    latitude: '37.852539',
    longitude: '-122.019112',
    timeZoneId: 'America/Los_Angeles',
  },
  '94508': {
    zip: '94508',
    city: 'Angwin',
    state: 'CA',
    latitude: '38.574225',
    longitude: '-122.445408',
    timeZoneId: 'America/Los_Angeles',
  },
  '94509': {
    zip: '94509',
    city: 'Antioch',
    state: 'CA',
    latitude: '37.995169',
    longitude: '-121.803953',
    timeZoneId: 'America/Los_Angeles',
  },
  '94510': {
    zip: '94510',
    city: 'Benicia',
    state: 'CA',
    latitude: '38.069204',
    longitude: '-122.164378',
    timeZoneId: 'America/Los_Angeles',
  },
  '94511': {
    zip: '94511',
    city: 'Bethel Island',
    state: 'CA',
    latitude: '38.023001',
    longitude: '-121.652091',
    timeZoneId: 'America/Los_Angeles',
  },
  '94512': {
    zip: '94512',
    city: 'Birds Landing',
    state: 'CA',
    latitude: '38.126085',
    longitude: '-121.825908',
    timeZoneId: 'America/Los_Angeles',
  },
  '94513': {
    zip: '94513',
    city: 'Brentwood',
    state: 'CA',
    latitude: '37.937735',
    longitude: '-121.695234',
    timeZoneId: 'America/Los_Angeles',
  },
  '94514': {
    zip: '94514',
    city: 'Byron',
    state: 'CA',
    latitude: '37.872',
    longitude: '-121.61364',
    timeZoneId: 'America/Los_Angeles',
  },
  '94515': {
    zip: '94515',
    city: 'Calistoga',
    state: 'CA',
    latitude: '38.584631',
    longitude: '-122.56898',
    timeZoneId: 'America/Los_Angeles',
  },
  '94516': {
    zip: '94516',
    city: 'Canyon',
    state: 'CA',
    latitude: '37.834668',
    longitude: '-122.188041',
    timeZoneId: 'America/Los_Angeles',
  },
  '94517': {
    zip: '94517',
    city: 'Clayton',
    state: 'CA',
    latitude: '37.931137',
    longitude: '-121.920029',
    timeZoneId: 'America/Los_Angeles',
  },
  '94518': {
    zip: '94518',
    city: 'Concord',
    state: 'CA',
    latitude: '37.950267',
    longitude: '-122.021455',
    timeZoneId: 'America/Los_Angeles',
  },
  '94519': {
    zip: '94519',
    city: 'Concord',
    state: 'CA',
    latitude: '37.98049',
    longitude: '-122.006612',
    timeZoneId: 'America/Los_Angeles',
  },
  '94520': {
    zip: '94520',
    city: 'Concord',
    state: 'CA',
    latitude: '37.985575',
    longitude: '-122.035167',
    timeZoneId: 'America/Los_Angeles',
  },
  '94521': {
    zip: '94521',
    city: 'Concord',
    state: 'CA',
    latitude: '37.962765',
    longitude: '-121.962736',
    timeZoneId: 'America/Los_Angeles',
  },
  '94522': {
    zip: '94522',
    city: 'Concord',
    state: 'CA',
    latitude: '38.01535',
    longitude: '-122.023314',
    timeZoneId: 'America/Los_Angeles',
  },
  '94523': {
    zip: '94523',
    city: 'Pleasant Hill',
    state: 'CA',
    latitude: '37.952057',
    longitude: '-122.074333',
    timeZoneId: 'America/Los_Angeles',
  },
  '94524': {
    zip: '94524',
    city: 'Concord',
    state: 'CA',
    latitude: '37.973586',
    longitude: '-122.05763',
    timeZoneId: 'America/Los_Angeles',
  },
  '94525': {
    zip: '94525',
    city: 'Crockett',
    state: 'CA',
    latitude: '38.052469',
    longitude: '-122.219057',
    timeZoneId: 'America/Los_Angeles',
  },
  '94526': {
    zip: '94526',
    city: 'Danville',
    state: 'CA',
    latitude: '37.818542',
    longitude: '-121.969476',
    timeZoneId: 'America/Los_Angeles',
  },
  '94527': {
    zip: '94527',
    city: 'Concord',
    state: 'CA',
    latitude: '37.976622',
    longitude: '-122.056397',
    timeZoneId: 'America/Los_Angeles',
  },
  '94528': {
    zip: '94528',
    city: 'Diablo',
    state: 'CA',
    latitude: '37.838423',
    longitude: '-121.954252',
    timeZoneId: 'America/Los_Angeles',
  },
  '94529': {
    zip: '94529',
    city: 'Concord',
    state: 'CA',
    latitude: '37.874619',
    longitude: '-122.047244',
    timeZoneId: 'America/Los_Angeles',
  },
  '94530': {
    zip: '94530',
    city: 'El Cerrito',
    state: 'CA',
    latitude: '37.920642',
    longitude: '-122.302362',
    timeZoneId: 'America/Los_Angeles',
  },
  '94531': {
    zip: '94531',
    city: 'Antioch',
    state: 'CA',
    latitude: '37.996725',
    longitude: '-121.807911',
    timeZoneId: 'America/Los_Angeles',
  },
  '94533': {
    zip: '94533',
    city: 'Fairfield',
    state: 'CA',
    latitude: '38.285247',
    longitude: '-122.008109',
    timeZoneId: 'America/Los_Angeles',
  },
  '94534': {
    zip: '94534',
    city: 'Fairfield',
    state: 'CA',
    latitude: '38.286723',
    longitude: '-122.011695',
    timeZoneId: 'America/Los_Angeles',
  },
  '94535': {
    zip: '94535',
    city: 'Travis AFB',
    state: 'CA',
    latitude: '38.249444',
    longitude: '-122.038889',
    timeZoneId: 'America/Los_Angeles',
  },
  '94536': {
    zip: '94536',
    city: 'Fremont',
    state: 'CA',
    latitude: '37.562573',
    longitude: '-121.997844',
    timeZoneId: 'America/Los_Angeles',
  },
  '94537': {
    zip: '94537',
    city: 'Fremont',
    state: 'CA',
    latitude: '37.555694',
    longitude: '-122.017336',
    timeZoneId: 'America/Los_Angeles',
  },
  '94538': {
    zip: '94538',
    city: 'Fremont',
    state: 'CA',
    latitude: '37.526834',
    longitude: '-121.965639',
    timeZoneId: 'America/Los_Angeles',
  },
  '94539': {
    zip: '94539',
    city: 'Fremont',
    state: 'CA',
    latitude: '37.517314',
    longitude: '-121.929678',
    timeZoneId: 'America/Los_Angeles',
  },
  '94540': {
    zip: '94540',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.658815',
    longitude: '-122.095048',
    timeZoneId: 'America/Los_Angeles',
  },
  '94541': {
    zip: '94541',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.673394',
    longitude: '-122.081227',
    timeZoneId: 'America/Los_Angeles',
  },
  '94542': {
    zip: '94542',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.657207',
    longitude: '-122.041013',
    timeZoneId: 'America/Los_Angeles',
  },
  '94543': {
    zip: '94543',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.673234',
    longitude: '-122.0822',
    timeZoneId: 'America/Los_Angeles',
  },
  '94544': {
    zip: '94544',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.634255',
    longitude: '-122.063172',
    timeZoneId: 'America/Los_Angeles',
  },
  '94545': {
    zip: '94545',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.63362',
    longitude: '-122.101731',
    timeZoneId: 'America/Los_Angeles',
  },
  '94546': {
    zip: '94546',
    city: 'Castro Valley',
    state: 'CA',
    latitude: '37.72608',
    longitude: '-122.08038',
    timeZoneId: 'America/Los_Angeles',
  },
  '94547': {
    zip: '94547',
    city: 'Hercules',
    state: 'CA',
    latitude: '38.011358',
    longitude: '-122.262961',
    timeZoneId: 'America/Los_Angeles',
  },
  '94548': {
    zip: '94548',
    city: 'Knightsen',
    state: 'CA',
    latitude: '37.974007',
    longitude: '-121.658741',
    timeZoneId: 'America/Los_Angeles',
  },
  '94549': {
    zip: '94549',
    city: 'Lafayette',
    state: 'CA',
    latitude: '37.897485',
    longitude: '-122.112374',
    timeZoneId: 'America/Los_Angeles',
  },
  '94550': {
    zip: '94550',
    city: 'Livermore',
    state: 'CA',
    latitude: '37.681436',
    longitude: '-121.753052',
    timeZoneId: 'America/Los_Angeles',
  },
  '94551': {
    zip: '94551',
    city: 'Livermore',
    state: 'CA',
    latitude: '37.730738',
    longitude: '-121.691989',
    timeZoneId: 'America/Los_Angeles',
  },
  '94552': {
    zip: '94552',
    city: 'Castro Valley',
    state: 'CA',
    latitude: '37.694127',
    longitude: '-122.018877',
    timeZoneId: 'America/Los_Angeles',
  },
  '94553': {
    zip: '94553',
    city: 'Martinez',
    state: 'CA',
    latitude: '37.99128',
    longitude: '-122.16432',
    timeZoneId: 'America/Los_Angeles',
  },
  '94555': {
    zip: '94555',
    city: 'Fremont',
    state: 'CA',
    latitude: '37.574646',
    longitude: '-122.046431',
    timeZoneId: 'America/Los_Angeles',
  },
  '94556': {
    zip: '94556',
    city: 'Moraga',
    state: 'CA',
    latitude: '37.843041',
    longitude: '-122.125739',
    timeZoneId: 'America/Los_Angeles',
  },
  '94557': {
    zip: '94557',
    city: 'Hayward',
    state: 'CA',
    latitude: '37.629876',
    longitude: '-122.103025',
    timeZoneId: 'America/Los_Angeles',
  },
  '94558': {
    zip: '94558',
    city: 'Napa',
    state: 'CA',
    latitude: '38.343537',
    longitude: '-122.305067',
    timeZoneId: 'America/Los_Angeles',
  },
  '94559': {
    zip: '94559',
    city: 'Napa',
    state: 'CA',
    latitude: '38.292689',
    longitude: '-122.2875',
    timeZoneId: 'America/Los_Angeles',
  },
  '94560': {
    zip: '94560',
    city: 'Newark',
    state: 'CA',
    latitude: '37.535958',
    longitude: '-122.031512',
    timeZoneId: 'America/Los_Angeles',
  },
  '94561': {
    zip: '94561',
    city: 'Oakley',
    state: 'CA',
    latitude: '37.992866',
    longitude: '-121.714643',
    timeZoneId: 'America/Los_Angeles',
  },
  '94562': {
    zip: '94562',
    city: 'Oakville',
    state: 'CA',
    latitude: '38.428074',
    longitude: '-122.403836',
    timeZoneId: 'America/Los_Angeles',
  },
  '94563': {
    zip: '94563',
    city: 'Orinda',
    state: 'CA',
    latitude: '37.879005',
    longitude: '-122.177633',
    timeZoneId: 'America/Los_Angeles',
  },
  '94564': {
    zip: '94564',
    city: 'Pinole',
    state: 'CA',
    latitude: '37.998695',
    longitude: '-122.286973',
    timeZoneId: 'America/Los_Angeles',
  },
  '94565': {
    zip: '94565',
    city: 'Pittsburg',
    state: 'CA',
    latitude: '38.017076',
    longitude: '-121.90965',
    timeZoneId: 'America/Los_Angeles',
  },
  '94566': {
    zip: '94566',
    city: 'Pleasanton',
    state: 'CA',
    latitude: '37.67287',
    longitude: '-121.884393',
    timeZoneId: 'America/Los_Angeles',
  },
  '94567': {
    zip: '94567',
    city: 'Pope Valley',
    state: 'CA',
    latitude: '38.676832',
    longitude: '-122.407523',
    timeZoneId: 'America/Los_Angeles',
  },
  '94568': {
    zip: '94568',
    city: 'Dublin',
    state: 'CA',
    latitude: '37.714234',
    longitude: '-121.930817',
    timeZoneId: 'America/Los_Angeles',
  },
  '94569': {
    zip: '94569',
    city: 'Port Costa',
    state: 'CA',
    latitude: '38.044526',
    longitude: '-122.187291',
    timeZoneId: 'America/Los_Angeles',
  },
  '94570': {
    zip: '94570',
    city: 'Moraga',
    state: 'CA',
    latitude: '38.115617',
    longitude: '-122.140088',
    timeZoneId: 'America/Los_Angeles',
  },
  '94571': {
    zip: '94571',
    city: 'Rio Vista',
    state: 'CA',
    latitude: '38.170067',
    longitude: '-121.718396',
    timeZoneId: 'America/Los_Angeles',
  },
  '94572': {
    zip: '94572',
    city: 'Rodeo',
    state: 'CA',
    latitude: '38.03056',
    longitude: '-122.261841',
    timeZoneId: 'America/Los_Angeles',
  },
  '94573': {
    zip: '94573',
    city: 'Rutherford',
    state: 'CA',
    latitude: '38.45792',
    longitude: '-122.413366',
    timeZoneId: 'America/Los_Angeles',
  },
  '94574': {
    zip: '94574',
    city: 'Saint Helena',
    state: 'CA',
    latitude: '38.509798',
    longitude: '-122.450863',
    timeZoneId: 'America/Los_Angeles',
  },
  '94575': {
    zip: '94575',
    city: 'Moraga',
    state: 'CA',
    latitude: '37.845244',
    longitude: '-122.122691',
    timeZoneId: 'America/Los_Angeles',
  },
  '94576': {
    zip: '94576',
    city: 'Deer Park',
    state: 'CA',
    latitude: '38.529291',
    longitude: '-122.468362',
    timeZoneId: 'America/Los_Angeles',
  },
  '94577': {
    zip: '94577',
    city: 'San Leandro',
    state: 'CA',
    latitude: '37.718789',
    longitude: '-122.158712',
    timeZoneId: 'America/Los_Angeles',
  },
  '94578': {
    zip: '94578',
    city: 'San Leandro',
    state: 'CA',
    latitude: '37.702893',
    longitude: '-122.123888',
    timeZoneId: 'America/Los_Angeles',
  },
  '94579': {
    zip: '94579',
    city: 'San Leandro',
    state: 'CA',
    latitude: '37.688081',
    longitude: '-122.149396',
    timeZoneId: 'America/Los_Angeles',
  },
  '94580': {
    zip: '94580',
    city: 'San Lorenzo',
    state: 'CA',
    latitude: '37.680307',
    longitude: '-122.12944',
    timeZoneId: 'America/Los_Angeles',
  },
  '94581': {
    zip: '94581',
    city: 'Napa',
    state: 'CA',
    latitude: '38.297277',
    longitude: '-122.287663',
    timeZoneId: 'America/Los_Angeles',
  },
  '94582': {
    zip: '94582',
    city: 'San Ramon',
    state: 'CA',
    latitude: '37.67245',
    longitude: '-121.889069',
    timeZoneId: 'America/Los_Angeles',
  },
  '94583': {
    zip: '94583',
    city: 'San Ramon',
    state: 'CA',
    latitude: '37.751779',
    longitude: '-121.953457',
    timeZoneId: 'America/Los_Angeles',
  },
  '94585': {
    zip: '94585',
    city: 'Suisun City',
    state: 'CA',
    latitude: '38.24616',
    longitude: '-122.02206',
    timeZoneId: 'America/Los_Angeles',
  },
  '94586': {
    zip: '94586',
    city: 'Sunol',
    state: 'CA',
    latitude: '37.603456',
    longitude: '-121.893025',
    timeZoneId: 'America/Los_Angeles',
  },
  '94587': {
    zip: '94587',
    city: 'Union City',
    state: 'CA',
    latitude: '37.594129',
    longitude: '-122.047013',
    timeZoneId: 'America/Los_Angeles',
  },
  '94588': {
    zip: '94588',
    city: 'Pleasanton',
    state: 'CA',
    latitude: '37.687222',
    longitude: '-121.894449',
    timeZoneId: 'America/Los_Angeles',
  },
  '94589': {
    zip: '94589',
    city: 'Vallejo',
    state: 'CA',
    latitude: '38.16234',
    longitude: '-122.2494',
    timeZoneId: 'America/Los_Angeles',
  },
  '94590': {
    zip: '94590',
    city: 'Vallejo',
    state: 'CA',
    latitude: '38.104516',
    longitude: '-122.24332',
    timeZoneId: 'America/Los_Angeles',
  },
  '94591': {
    zip: '94591',
    city: 'Vallejo',
    state: 'CA',
    latitude: '38.101456',
    longitude: '-122.210632',
    timeZoneId: 'America/Los_Angeles',
  },
  '94592': {
    zip: '94592',
    city: 'Vallejo',
    state: 'CA',
    latitude: '38.102623',
    longitude: '-122.275686',
    timeZoneId: 'America/Los_Angeles',
  },
  '94595': {
    zip: '94595',
    city: 'Walnut Creek',
    state: 'CA',
    latitude: '37.870524',
    longitude: '-122.072952',
    timeZoneId: 'America/Los_Angeles',
  },
  '94596': {
    zip: '94596',
    city: 'Walnut Creek',
    state: 'CA',
    latitude: '37.904944',
    longitude: '-122.054411',
    timeZoneId: 'America/Los_Angeles',
  },
  '94597': {
    zip: '94597',
    city: 'Walnut Creek',
    state: 'CA',
    latitude: '37.900911',
    longitude: '-122.059246',
    timeZoneId: 'America/Los_Angeles',
  },
  '94598': {
    zip: '94598',
    city: 'Walnut Creek',
    state: 'CA',
    latitude: '37.915859',
    longitude: '-122.023678',
    timeZoneId: 'America/Los_Angeles',
  },
  '94599': {
    zip: '94599',
    city: 'Yountville',
    state: 'CA',
    latitude: '38.407604',
    longitude: '-122.357035',
    timeZoneId: 'America/Los_Angeles',
  },
  '94601': {
    zip: '94601',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.780584',
    longitude: '-122.216306',
    timeZoneId: 'America/Los_Angeles',
  },
  '94602': {
    zip: '94602',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.802583',
    longitude: '-122.208576',
    timeZoneId: 'America/Los_Angeles',
  },
  '94603': {
    zip: '94603',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.740185',
    longitude: '-122.17499',
    timeZoneId: 'America/Los_Angeles',
  },
  '94604': {
    zip: '94604',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.80126',
    longitude: '-122.265751',
    timeZoneId: 'America/Los_Angeles',
  },
  '94605': {
    zip: '94605',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.762699',
    longitude: '-122.16',
    timeZoneId: 'America/Los_Angeles',
  },
  '94606': {
    zip: '94606',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.79127',
    longitude: '-122.243159',
    timeZoneId: 'America/Los_Angeles',
  },
  '94607': {
    zip: '94607',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.805982',
    longitude: '-122.285871',
    timeZoneId: 'America/Los_Angeles',
  },
  '94608': {
    zip: '94608',
    city: 'Emeryville',
    state: 'CA',
    latitude: '37.83444',
    longitude: '-122.28906',
    timeZoneId: 'America/Los_Angeles',
  },
  '94609': {
    zip: '94609',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.834123',
    longitude: '-122.263353',
    timeZoneId: 'America/Los_Angeles',
  },
  '94610': {
    zip: '94610',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.81506',
    longitude: '-122.239672',
    timeZoneId: 'America/Los_Angeles',
  },
  '94611': {
    zip: '94611',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.83558',
    longitude: '-122.22336',
    timeZoneId: 'America/Los_Angeles',
  },
  '94612': {
    zip: '94612',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.812882',
    longitude: '-122.26983',
    timeZoneId: 'America/Los_Angeles',
  },
  '94613': {
    zip: '94613',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.783256',
    longitude: '-122.186684',
    timeZoneId: 'America/Los_Angeles',
  },
  '94614': {
    zip: '94614',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.729709',
    longitude: '-122.200667',
    timeZoneId: 'America/Los_Angeles',
  },
  '94615': {
    zip: '94615',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.808231',
    longitude: '-122.298981',
    timeZoneId: 'America/Los_Angeles',
  },
  '94617': {
    zip: '94617',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.757976',
    longitude: '-122.147048',
    timeZoneId: 'America/Los_Angeles',
  },
  '94618': {
    zip: '94618',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.824444',
    longitude: '-122.230556',
    timeZoneId: 'America/Los_Angeles',
  },
  '94619': {
    zip: '94619',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.790966',
    longitude: '-122.181455',
    timeZoneId: 'America/Los_Angeles',
  },
  '94620': {
    zip: '94620',
    city: 'Piedmont',
    state: 'CA',
    latitude: '37.77757',
    longitude: '-122.214747',
    timeZoneId: 'America/Los_Angeles',
  },
  '94621': {
    zip: '94621',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.755868',
    longitude: '-122.188468',
    timeZoneId: 'America/Los_Angeles',
  },
  '94622': {
    zip: '94622',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.749508',
    longitude: '-122.194169',
    timeZoneId: 'America/Los_Angeles',
  },
  '94623': {
    zip: '94623',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.810666',
    longitude: '-122.29747',
    timeZoneId: 'America/Los_Angeles',
  },
  '94624': {
    zip: '94624',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.747304',
    longitude: '-122.172356',
    timeZoneId: 'America/Los_Angeles',
  },
  '94649': {
    zip: '94649',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.806936',
    longitude: '-122.263828',
    timeZoneId: 'America/Los_Angeles',
  },
  '94659': {
    zip: '94659',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.809982',
    longitude: '-122.269201',
    timeZoneId: 'America/Los_Angeles',
  },
  '94660': {
    zip: '94660',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.795912',
    longitude: '-122.269768',
    timeZoneId: 'America/Los_Angeles',
  },
  '94661': {
    zip: '94661',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.829778',
    longitude: '-122.209894',
    timeZoneId: 'America/Los_Angeles',
  },
  '94662': {
    zip: '94662',
    city: 'Emeryville',
    state: 'CA',
    latitude: '37.840484',
    longitude: '-122.293762',
    timeZoneId: 'America/Los_Angeles',
  },
  '94666': {
    zip: '94666',
    city: 'Oakland',
    state: 'CA',
    latitude: '37.809816',
    longitude: '-122.265531',
    timeZoneId: 'America/Los_Angeles',
  },
  '94701': {
    zip: '94701',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.869905',
    longitude: '-122.270672',
    timeZoneId: 'America/Los_Angeles',
  },
  '94702': {
    zip: '94702',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.866632',
    longitude: '-122.282429',
    timeZoneId: 'America/Los_Angeles',
  },
  '94703': {
    zip: '94703',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.86689',
    longitude: '-122.272342',
    timeZoneId: 'America/Los_Angeles',
  },
  '94704': {
    zip: '94704',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.870599',
    longitude: '-122.262077',
    timeZoneId: 'America/Los_Angeles',
  },
  '94705': {
    zip: '94705',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.856122',
    longitude: '-122.246704',
    timeZoneId: 'America/Los_Angeles',
  },
  '94706': {
    zip: '94706',
    city: 'Albany',
    state: 'CA',
    latitude: '37.888234',
    longitude: '-122.293954',
    timeZoneId: 'America/Los_Angeles',
  },
  '94707': {
    zip: '94707',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.8993',
    longitude: '-122.27652',
    timeZoneId: 'America/Los_Angeles',
  },
  '94708': {
    zip: '94708',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.906115',
    longitude: '-122.273476',
    timeZoneId: 'America/Los_Angeles',
  },
  '94709': {
    zip: '94709',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.879509',
    longitude: '-122.262241',
    timeZoneId: 'America/Los_Angeles',
  },
  '94710': {
    zip: '94710',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.86997',
    longitude: '-122.296314',
    timeZoneId: 'America/Los_Angeles',
  },
  '94712': {
    zip: '94712',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.868998',
    longitude: '-122.2679',
    timeZoneId: 'America/Los_Angeles',
  },
  '94720': {
    zip: '94720',
    city: 'Berkeley',
    state: 'CA',
    latitude: '37.868252',
    longitude: '-122.258486',
    timeZoneId: 'America/Los_Angeles',
  },
  '94801': {
    zip: '94801',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.942278',
    longitude: '-122.355347',
    timeZoneId: 'America/Los_Angeles',
  },
  '94802': {
    zip: '94802',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.937113',
    longitude: '-122.356577',
    timeZoneId: 'America/Los_Angeles',
  },
  '94803': {
    zip: '94803',
    city: 'El Sobrante',
    state: 'CA',
    latitude: '37.972375',
    longitude: '-122.289337',
    timeZoneId: 'America/Los_Angeles',
  },
  '94804': {
    zip: '94804',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.92456',
    longitude: '-122.335196',
    timeZoneId: 'America/Los_Angeles',
  },
  '94805': {
    zip: '94805',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.943049',
    longitude: '-122.32044',
    timeZoneId: 'America/Los_Angeles',
  },
  '94806': {
    zip: '94806',
    city: 'San Pablo',
    state: 'CA',
    latitude: '37.972918',
    longitude: '-122.333929',
    timeZoneId: 'America/Los_Angeles',
  },
  '94807': {
    zip: '94807',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.927153',
    longitude: '-122.353849',
    timeZoneId: 'America/Los_Angeles',
  },
  '94808': {
    zip: '94808',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.935999',
    longitude: '-122.341431',
    timeZoneId: 'America/Los_Angeles',
  },
  '94820': {
    zip: '94820',
    city: 'El Sobrante',
    state: 'CA',
    latitude: '37.978266',
    longitude: '-122.295281',
    timeZoneId: 'America/Los_Angeles',
  },
  '94850': {
    zip: '94850',
    city: 'Richmond',
    state: 'CA',
    latitude: '37.947097',
    longitude: '-122.381529',
    timeZoneId: 'America/Los_Angeles',
  },
  '94901': {
    zip: '94901',
    city: 'San Rafael',
    state: 'CA',
    latitude: '37.974155',
    longitude: '-122.509463',
    timeZoneId: 'America/Los_Angeles',
  },
  '94903': {
    zip: '94903',
    city: 'San Rafael',
    state: 'CA',
    latitude: '38.013354',
    longitude: '-122.548797',
    timeZoneId: 'America/Los_Angeles',
  },
  '94904': {
    zip: '94904',
    city: 'Greenbrae',
    state: 'CA',
    latitude: '37.95114',
    longitude: '-122.53956',
    timeZoneId: 'America/Los_Angeles',
  },
  '94912': {
    zip: '94912',
    city: 'San Rafael',
    state: 'CA',
    latitude: '37.97771',
    longitude: '-122.506459',
    timeZoneId: 'America/Los_Angeles',
  },
  '94913': {
    zip: '94913',
    city: 'San Rafael',
    state: 'CA',
    latitude: '38.025782',
    longitude: '-122.54487',
    timeZoneId: 'America/Los_Angeles',
  },
  '94914': {
    zip: '94914',
    city: 'Kentfield',
    state: 'CA',
    latitude: '37.94739',
    longitude: '-122.537057',
    timeZoneId: 'America/Los_Angeles',
  },
  '94915': {
    zip: '94915',
    city: 'San Rafael',
    state: 'CA',
    latitude: '37.959899',
    longitude: '-122.504023',
    timeZoneId: 'America/Los_Angeles',
  },
  '94920': {
    zip: '94920',
    city: 'Belvedere Tiburon',
    state: 'CA',
    latitude: '37.893723',
    longitude: '-122.471124',
    timeZoneId: 'America/Los_Angeles',
  },
  '94922': {
    zip: '94922',
    city: 'Bodega',
    state: 'CA',
    latitude: '38.375585',
    longitude: '-122.983422',
    timeZoneId: 'America/Los_Angeles',
  },
  '94923': {
    zip: '94923',
    city: 'Bodega Bay',
    state: 'CA',
    latitude: '38.349015',
    longitude: '-123.047387',
    timeZoneId: 'America/Los_Angeles',
  },
  '94924': {
    zip: '94924',
    city: 'Bolinas',
    state: 'CA',
    latitude: '37.912439',
    longitude: '-122.704347',
    timeZoneId: 'America/Los_Angeles',
  },
  '94925': {
    zip: '94925',
    city: 'Corte Madera',
    state: 'CA',
    latitude: '37.925771',
    longitude: '-122.518453',
    timeZoneId: 'America/Los_Angeles',
  },
  '94926': {
    zip: '94926',
    city: 'Rohnert Park',
    state: 'CA',
    latitude: '38.326476',
    longitude: '-122.712308',
    timeZoneId: 'America/Los_Angeles',
  },
  '94927': {
    zip: '94927',
    city: 'Rohnert Park',
    state: 'CA',
    latitude: '38.347442',
    longitude: '-122.698375',
    timeZoneId: 'America/Los_Angeles',
  },
  '94928': {
    zip: '94928',
    city: 'Rohnert Park',
    state: 'CA',
    latitude: '38.343934',
    longitude: '-122.699887',
    timeZoneId: 'America/Los_Angeles',
  },
  '94929': {
    zip: '94929',
    city: 'Dillon Beach',
    state: 'CA',
    latitude: '38.253378',
    longitude: '-122.965473',
    timeZoneId: 'America/Los_Angeles',
  },
  '94930': {
    zip: '94930',
    city: 'Fairfax',
    state: 'CA',
    latitude: '37.964981',
    longitude: '-122.618571',
    timeZoneId: 'America/Los_Angeles',
  },
  '94931': {
    zip: '94931',
    city: 'Cotati',
    state: 'CA',
    latitude: '38.326317',
    longitude: '-122.704613',
    timeZoneId: 'America/Los_Angeles',
  },
  '94933': {
    zip: '94933',
    city: 'Forest Knolls',
    state: 'CA',
    latitude: '38.015968',
    longitude: '-122.691503',
    timeZoneId: 'America/Los_Angeles',
  },
  '94937': {
    zip: '94937',
    city: 'Inverness',
    state: 'CA',
    latitude: '38.096305',
    longitude: '-122.859417',
    timeZoneId: 'America/Los_Angeles',
  },
  '94938': {
    zip: '94938',
    city: 'Lagunitas',
    state: 'CA',
    latitude: '38.011317',
    longitude: '-122.696447',
    timeZoneId: 'America/Los_Angeles',
  },
  '94939': {
    zip: '94939',
    city: 'Larkspur',
    state: 'CA',
    latitude: '37.93781',
    longitude: '-122.537195',
    timeZoneId: 'America/Los_Angeles',
  },
  '94940': {
    zip: '94940',
    city: 'Marshall',
    state: 'CA',
    latitude: '38.198063',
    longitude: '-122.891072',
    timeZoneId: 'America/Los_Angeles',
  },
  '94941': {
    zip: '94941',
    city: 'Mill Valley',
    state: 'CA',
    latitude: '37.8903',
    longitude: '-122.56764',
    timeZoneId: 'America/Los_Angeles',
  },
  '94942': {
    zip: '94942',
    city: 'Mill Valley',
    state: 'CA',
    latitude: '37.904574',
    longitude: '-122.522903',
    timeZoneId: 'America/Los_Angeles',
  },
  '94945': {
    zip: '94945',
    city: 'Novato',
    state: 'CA',
    latitude: '38.124448',
    longitude: '-122.550509',
    timeZoneId: 'America/Los_Angeles',
  },
  '94946': {
    zip: '94946',
    city: 'Nicasio',
    state: 'CA',
    latitude: '38.061912',
    longitude: '-122.677452',
    timeZoneId: 'America/Los_Angeles',
  },
  '94947': {
    zip: '94947',
    city: 'Novato',
    state: 'CA',
    latitude: '38.103524',
    longitude: '-122.616201',
    timeZoneId: 'America/Los_Angeles',
  },
  '94948': {
    zip: '94948',
    city: 'Novato',
    state: 'CA',
    latitude: '38.135776',
    longitude: '-122.562237',
    timeZoneId: 'America/Los_Angeles',
  },
  '94949': {
    zip: '94949',
    city: 'Novato',
    state: 'CA',
    latitude: '38.060614',
    longitude: '-122.540903',
    timeZoneId: 'America/Los_Angeles',
  },
  '94950': {
    zip: '94950',
    city: 'Olema',
    state: 'CA',
    latitude: '38.043331',
    longitude: '-122.770535',
    timeZoneId: 'America/Los_Angeles',
  },
  '94951': {
    zip: '94951',
    city: 'Penngrove',
    state: 'CA',
    latitude: '38.316',
    longitude: '-122.65798',
    timeZoneId: 'America/Los_Angeles',
  },
  '94952': {
    zip: '94952',
    city: 'Petaluma',
    state: 'CA',
    latitude: '38.244916',
    longitude: '-122.659988',
    timeZoneId: 'America/Los_Angeles',
  },
  '94953': {
    zip: '94953',
    city: 'Petaluma',
    state: 'CA',
    latitude: '38.26152',
    longitude: '-122.639077',
    timeZoneId: 'America/Los_Angeles',
  },
  '94954': {
    zip: '94954',
    city: 'Petaluma',
    state: 'CA',
    latitude: '38.253709',
    longitude: '-122.6137',
    timeZoneId: 'America/Los_Angeles',
  },
  '94955': {
    zip: '94955',
    city: 'Petaluma',
    state: 'CA',
    latitude: '38.222372',
    longitude: '-122.556183',
    timeZoneId: 'America/Los_Angeles',
  },
  '94956': {
    zip: '94956',
    city: 'Point Reyes Station',
    state: 'CA',
    latitude: '38.063493',
    longitude: '-122.809164',
    timeZoneId: 'America/Los_Angeles',
  },
  '94957': {
    zip: '94957',
    city: 'Ross',
    state: 'CA',
    latitude: '37.964848',
    longitude: '-122.555077',
    timeZoneId: 'America/Los_Angeles',
  },
  '94960': {
    zip: '94960',
    city: 'San Anselmo',
    state: 'CA',
    latitude: '37.980263',
    longitude: '-122.567823',
    timeZoneId: 'America/Los_Angeles',
  },
  '94963': {
    zip: '94963',
    city: 'San Geronimo',
    state: 'CA',
    latitude: '38.012786',
    longitude: '-122.663972',
    timeZoneId: 'America/Los_Angeles',
  },
  '94964': {
    zip: '94964',
    city: 'San Quentin',
    state: 'CA',
    latitude: '37.940537',
    longitude: '-122.482541',
    timeZoneId: 'America/Los_Angeles',
  },
  '94965': {
    zip: '94965',
    city: 'Sausalito',
    state: 'CA',
    latitude: '37.8651',
    longitude: '-122.53098',
    timeZoneId: 'America/Los_Angeles',
  },
  '94966': {
    zip: '94966',
    city: 'Sausalito',
    state: 'CA',
    latitude: '37.865865',
    longitude: '-122.496891',
    timeZoneId: 'America/Los_Angeles',
  },
  '94970': {
    zip: '94970',
    city: 'Stinson Beach',
    state: 'CA',
    latitude: '37.902526',
    longitude: '-122.643355',
    timeZoneId: 'America/Los_Angeles',
  },
  '94971': {
    zip: '94971',
    city: 'Tomales',
    state: 'CA',
    latitude: '38.248863',
    longitude: '-122.899922',
    timeZoneId: 'America/Los_Angeles',
  },
  '94972': {
    zip: '94972',
    city: 'Valley Ford',
    state: 'CA',
    latitude: '38.297258',
    longitude: '-122.941962',
    timeZoneId: 'America/Los_Angeles',
  },
  '94973': {
    zip: '94973',
    city: 'Woodacre',
    state: 'CA',
    latitude: '38.007689',
    longitude: '-122.640149',
    timeZoneId: 'America/Los_Angeles',
  },
  '94974': {
    zip: '94974',
    city: 'San Quentin',
    state: 'CA',
    latitude: '37.956461',
    longitude: '-122.539347',
    timeZoneId: 'America/Los_Angeles',
  },
  '94975': {
    zip: '94975',
    city: 'Petaluma',
    state: 'CA',
    latitude: '38.322344',
    longitude: '-122.646575',
    timeZoneId: 'America/Los_Angeles',
  },
  '94976': {
    zip: '94976',
    city: 'Corte Madera',
    state: 'CA',
    latitude: '37.927431',
    longitude: '-122.524551',
    timeZoneId: 'America/Los_Angeles',
  },
  '94977': {
    zip: '94977',
    city: 'Larkspur',
    state: 'CA',
    latitude: '37.938018',
    longitude: '-122.535263',
    timeZoneId: 'America/Los_Angeles',
  },
  '94978': {
    zip: '94978',
    city: 'Fairfax',
    state: 'CA',
    latitude: '37.984901',
    longitude: '-122.580046',
    timeZoneId: 'America/Los_Angeles',
  },
  '94979': {
    zip: '94979',
    city: 'San Anselmo',
    state: 'CA',
    latitude: '37.971976',
    longitude: '-122.562039',
    timeZoneId: 'America/Los_Angeles',
  },
  '94998': {
    zip: '94998',
    city: 'Novato',
    state: 'CA',
    latitude: '38.099734',
    longitude: '-122.571446',
    timeZoneId: 'America/Los_Angeles',
  },
  '94999': {
    zip: '94999',
    city: 'Petaluma',
    state: 'CA',
    latitude: '38.323951',
    longitude: '-122.64259',
    timeZoneId: 'America/Los_Angeles',
  },
  '95001': {
    zip: '95001',
    city: 'Aptos',
    state: 'CA',
    latitude: '36.980421',
    longitude: '-121.898405',
    timeZoneId: 'America/Los_Angeles',
  },
  '95002': {
    zip: '95002',
    city: 'Alviso',
    state: 'CA',
    latitude: '37.427296',
    longitude: '-121.970884',
    timeZoneId: 'America/Los_Angeles',
  },
  '95003': {
    zip: '95003',
    city: 'Aptos',
    state: 'CA',
    latitude: '36.99264',
    longitude: '-121.87674',
    timeZoneId: 'America/Los_Angeles',
  },
  '95004': {
    zip: '95004',
    city: 'Aromas',
    state: 'CA',
    latitude: '36.87818',
    longitude: '-121.6244',
    timeZoneId: 'America/Los_Angeles',
  },
  '95005': {
    zip: '95005',
    city: 'Ben Lomond',
    state: 'CA',
    latitude: '37.085118',
    longitude: '-122.086704',
    timeZoneId: 'America/Los_Angeles',
  },
  '95006': {
    zip: '95006',
    city: 'Boulder Creek',
    state: 'CA',
    latitude: '37.148282',
    longitude: '-122.131711',
    timeZoneId: 'America/Los_Angeles',
  },
  '95007': {
    zip: '95007',
    city: 'Brookdale',
    state: 'CA',
    latitude: '37.107721',
    longitude: '-122.109557',
    timeZoneId: 'America/Los_Angeles',
  },
  '95008': {
    zip: '95008',
    city: 'Campbell',
    state: 'CA',
    latitude: '37.278271',
    longitude: '-121.955198',
    timeZoneId: 'America/Los_Angeles',
  },
  '95009': {
    zip: '95009',
    city: 'Campbell',
    state: 'CA',
    latitude: '37.289052',
    longitude: '-121.94796',
    timeZoneId: 'America/Los_Angeles',
  },
  '95010': {
    zip: '95010',
    city: 'Capitola',
    state: 'CA',
    latitude: '36.978256',
    longitude: '-121.952464',
    timeZoneId: 'America/Los_Angeles',
  },
  '95011': {
    zip: '95011',
    city: 'Campbell',
    state: 'CA',
    latitude: '37.29502',
    longitude: '-121.960662',
    timeZoneId: 'America/Los_Angeles',
  },
  '95012': {
    zip: '95012',
    city: 'Castroville',
    state: 'CA',
    latitude: '36.780991',
    longitude: '-121.735888',
    timeZoneId: 'America/Los_Angeles',
  },
  '95013': {
    zip: '95013',
    city: 'Coyote',
    state: 'CA',
    latitude: '37.199573',
    longitude: '-121.726813',
    timeZoneId: 'America/Los_Angeles',
  },
  '95014': {
    zip: '95014',
    city: 'Cupertino',
    state: 'CA',
    latitude: '37.314123',
    longitude: '-122.044411',
    timeZoneId: 'America/Los_Angeles',
  },
  '95015': {
    zip: '95015',
    city: 'Cupertino',
    state: 'CA',
    latitude: '37.322857',
    longitude: '-122.052958',
    timeZoneId: 'America/Los_Angeles',
  },
  '95017': {
    zip: '95017',
    city: 'Davenport',
    state: 'CA',
    latitude: '37.048041',
    longitude: '-122.220016',
    timeZoneId: 'America/Los_Angeles',
  },
  '95018': {
    zip: '95018',
    city: 'Felton',
    state: 'CA',
    latitude: '37.063973',
    longitude: '-122.063202',
    timeZoneId: 'America/Los_Angeles',
  },
  '95019': {
    zip: '95019',
    city: 'Freedom',
    state: 'CA',
    latitude: '36.934894',
    longitude: '-121.777258',
    timeZoneId: 'America/Los_Angeles',
  },
  '95020': {
    zip: '95020',
    city: 'Gilroy',
    state: 'CA',
    latitude: '37.019255',
    longitude: '-121.574817',
    timeZoneId: 'America/Los_Angeles',
  },
  '95021': {
    zip: '95021',
    city: 'Gilroy',
    state: 'CA',
    latitude: '37.01446',
    longitude: '-121.549589',
    timeZoneId: 'America/Los_Angeles',
  },
  '95023': {
    zip: '95023',
    city: 'Hollister',
    state: 'CA',
    latitude: '36.848759',
    longitude: '-121.388323',
    timeZoneId: 'America/Los_Angeles',
  },
  '95024': {
    zip: '95024',
    city: 'Hollister',
    state: 'CA',
    latitude: '36.842897',
    longitude: '-121.393538',
    timeZoneId: 'America/Los_Angeles',
  },
  '95026': {
    zip: '95026',
    city: 'Holy City',
    state: 'CA',
    latitude: '37.155014',
    longitude: '-121.982515',
    timeZoneId: 'America/Los_Angeles',
  },
  '95030': {
    zip: '95030',
    city: 'Los Gatos',
    state: 'CA',
    latitude: '37.17192',
    longitude: '-121.97472',
    timeZoneId: 'America/Los_Angeles',
  },
  '95031': {
    zip: '95031',
    city: 'Los Gatos',
    state: 'CA',
    latitude: '37.130087',
    longitude: '-121.938513',
    timeZoneId: 'America/Los_Angeles',
  },
  '95032': {
    zip: '95032',
    city: 'Los Gatos',
    state: 'CA',
    latitude: '37.231042',
    longitude: '-121.949002',
    timeZoneId: 'America/Los_Angeles',
  },
  '95033': {
    zip: '95033',
    city: 'Los Gatos',
    state: 'CA',
    latitude: '37.229886',
    longitude: '-121.958736',
    timeZoneId: 'America/Los_Angeles',
  },
  '95035': {
    zip: '95035',
    city: 'Milpitas',
    state: 'CA',
    latitude: '37.438136',
    longitude: '-121.888801',
    timeZoneId: 'America/Los_Angeles',
  },
  '95036': {
    zip: '95036',
    city: 'Milpitas',
    state: 'CA',
    latitude: '37.243087',
    longitude: '-121.971656',
    timeZoneId: 'America/Los_Angeles',
  },
  '95037': {
    zip: '95037',
    city: 'Morgan Hill',
    state: 'CA',
    latitude: '37.123009',
    longitude: '-121.648882',
    timeZoneId: 'America/Los_Angeles',
  },
  '95038': {
    zip: '95038',
    city: 'Morgan Hill',
    state: 'CA',
    latitude: '37.08552',
    longitude: '-121.6098',
    timeZoneId: 'America/Los_Angeles',
  },
  '95039': {
    zip: '95039',
    city: 'Moss Landing',
    state: 'CA',
    latitude: '36.795167',
    longitude: '-121.785283',
    timeZoneId: 'America/Los_Angeles',
  },
  '95041': {
    zip: '95041',
    city: 'Mount Hermon',
    state: 'CA',
    latitude: '37.04823',
    longitude: '-122.051231',
    timeZoneId: 'America/Los_Angeles',
  },
  '95042': {
    zip: '95042',
    city: 'New Almaden',
    state: 'CA',
    latitude: '37.177537',
    longitude: '-121.818513',
    timeZoneId: 'America/Los_Angeles',
  },
  '95043': {
    zip: '95043',
    city: 'Paicines',
    state: 'CA',
    latitude: '36.54804',
    longitude: '-121.01628',
    timeZoneId: 'America/Los_Angeles',
  },
  '95044': {
    zip: '95044',
    city: 'Redwood Estates',
    state: 'CA',
    latitude: '37.158418',
    longitude: '-121.984871',
    timeZoneId: 'America/Los_Angeles',
  },
  '95045': {
    zip: '95045',
    city: 'San Juan Bautista',
    state: 'CA',
    latitude: '36.853236',
    longitude: '-121.536794',
    timeZoneId: 'America/Los_Angeles',
  },
  '95046': {
    zip: '95046',
    city: 'San Martin',
    state: 'CA',
    latitude: '37.090149',
    longitude: '-121.595743',
    timeZoneId: 'America/Los_Angeles',
  },
  '95050': {
    zip: '95050',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.349491',
    longitude: '-121.957826',
    timeZoneId: 'America/Los_Angeles',
  },
  '95051': {
    zip: '95051',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.347073',
    longitude: '-121.98335',
    timeZoneId: 'America/Los_Angeles',
  },
  '95052': {
    zip: '95052',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.355841',
    longitude: '-121.953674',
    timeZoneId: 'America/Los_Angeles',
  },
  '95053': {
    zip: '95053',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.34792',
    longitude: '-121.936525',
    timeZoneId: 'America/Los_Angeles',
  },
  '95054': {
    zip: '95054',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.352976',
    longitude: '-121.957963',
    timeZoneId: 'America/Los_Angeles',
  },
  '95055': {
    zip: '95055',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.336569',
    longitude: '-121.983835',
    timeZoneId: 'America/Los_Angeles',
  },
  '95056': {
    zip: '95056',
    city: 'Santa Clara',
    state: 'CA',
    latitude: '37.400588',
    longitude: '-121.963467',
    timeZoneId: 'America/Los_Angeles',
  },
  '95060': {
    zip: '95060',
    city: 'Santa Cruz',
    state: 'CA',
    latitude: '36.989018',
    longitude: '-122.057824',
    timeZoneId: 'America/Los_Angeles',
  },
  '95061': {
    zip: '95061',
    city: 'Santa Cruz',
    state: 'CA',
    latitude: '36.976735',
    longitude: '-122.023603',
    timeZoneId: 'America/Los_Angeles',
  },
  '95062': {
    zip: '95062',
    city: 'Santa Cruz',
    state: 'CA',
    latitude: '36.971541',
    longitude: '-121.985008',
    timeZoneId: 'America/Los_Angeles',
  },
  '95063': {
    zip: '95063',
    city: 'Santa Cruz',
    state: 'CA',
    latitude: '36.982267',
    longitude: '-122.009181',
    timeZoneId: 'America/Los_Angeles',
  },
  '95064': {
    zip: '95064',
    city: 'Santa Cruz',
    state: 'CA',
    latitude: '36.988279',
    longitude: '-122.063261',
    timeZoneId: 'America/Los_Angeles',
  },
  '95065': {
    zip: '95065',
    city: 'Santa Cruz',
    state: 'CA',
    latitude: '37.016503',
    longitude: '-121.979468',
    timeZoneId: 'America/Los_Angeles',
  },
  '95066': {
    zip: '95066',
    city: 'Scotts Valley',
    state: 'CA',
    latitude: '37.071',
    longitude: '-122.0106',
    timeZoneId: 'America/Los_Angeles',
  },
  '95067': {
    zip: '95067',
    city: 'Scotts Valley',
    state: 'CA',
    latitude: '36.974167',
    longitude: '-122.029722',
    timeZoneId: 'America/Los_Angeles',
  },
  '95070': {
    zip: '95070',
    city: 'Saratoga',
    state: 'CA',
    latitude: '37.274954',
    longitude: '-122.021747',
    timeZoneId: 'America/Los_Angeles',
  },
  '95071': {
    zip: '95071',
    city: 'Saratoga',
    state: 'CA',
    latitude: '37.25286',
    longitude: '-122.029595',
    timeZoneId: 'America/Los_Angeles',
  },
  '95073': {
    zip: '95073',
    city: 'Soquel',
    state: 'CA',
    latitude: '37.008856',
    longitude: '-121.950993',
    timeZoneId: 'America/Los_Angeles',
  },
  '95075': {
    zip: '95075',
    city: 'Tres Pinos',
    state: 'CA',
    latitude: '36.779945',
    longitude: '-121.296876',
    timeZoneId: 'America/Los_Angeles',
  },
  '95076': {
    zip: '95076',
    city: 'Watsonville',
    state: 'CA',
    latitude: '36.941261',
    longitude: '-121.782524',
    timeZoneId: 'America/Los_Angeles',
  },
  '95077': {
    zip: '95077',
    city: 'Watsonville',
    state: 'CA',
    latitude: '36.911048',
    longitude: '-121.758494',
    timeZoneId: 'America/Los_Angeles',
  },
  '95101': {
    zip: '95101',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.385199',
    longitude: '-121.896535',
    timeZoneId: 'America/Los_Angeles',
  },
  '95103': {
    zip: '95103',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.341165',
    longitude: '-121.888307',
    timeZoneId: 'America/Los_Angeles',
  },
  '95106': {
    zip: '95106',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.33854',
    longitude: '-121.891619',
    timeZoneId: 'America/Los_Angeles',
  },
  '95108': {
    zip: '95108',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.33607',
    longitude: '-122.090216',
    timeZoneId: 'America/Los_Angeles',
  },
  '95109': {
    zip: '95109',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.337284',
    longitude: '-121.893454',
    timeZoneId: 'America/Los_Angeles',
  },
  '95110': {
    zip: '95110',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.336534',
    longitude: '-121.89953',
    timeZoneId: 'America/Los_Angeles',
  },
  '95111': {
    zip: '95111',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.282801',
    longitude: '-121.825473',
    timeZoneId: 'America/Los_Angeles',
  },
  '95112': {
    zip: '95112',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.345558',
    longitude: '-121.883995',
    timeZoneId: 'America/Los_Angeles',
  },
  '95113': {
    zip: '95113',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.333274',
    longitude: '-121.890864',
    timeZoneId: 'America/Los_Angeles',
  },
  '95115': {
    zip: '95115',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.304232',
    longitude: '-121.887889',
    timeZoneId: 'America/Los_Angeles',
  },
  '95116': {
    zip: '95116',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.352823',
    longitude: '-121.849809',
    timeZoneId: 'America/Los_Angeles',
  },
  '95117': {
    zip: '95117',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.312261',
    longitude: '-121.963925',
    timeZoneId: 'America/Los_Angeles',
  },
  '95118': {
    zip: '95118',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.257586',
    longitude: '-121.889924',
    timeZoneId: 'America/Los_Angeles',
  },
  '95119': {
    zip: '95119',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.230749',
    longitude: '-121.786095',
    timeZoneId: 'America/Los_Angeles',
  },
  '95120': {
    zip: '95120',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.218511',
    longitude: '-121.857173',
    timeZoneId: 'America/Los_Angeles',
  },
  '95121': {
    zip: '95121',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.305224',
    longitude: '-121.807376',
    timeZoneId: 'America/Los_Angeles',
  },
  '95122': {
    zip: '95122',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.325993',
    longitude: '-121.832012',
    timeZoneId: 'America/Los_Angeles',
  },
  '95123': {
    zip: '95123',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.242541',
    longitude: '-121.834674',
    timeZoneId: 'America/Los_Angeles',
  },
  '95124': {
    zip: '95124',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.256921',
    longitude: '-121.922873',
    timeZoneId: 'America/Los_Angeles',
  },
  '95125': {
    zip: '95125',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.293628',
    longitude: '-121.897822',
    timeZoneId: 'America/Los_Angeles',
  },
  '95126': {
    zip: '95126',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.320261',
    longitude: '-121.917689',
    timeZoneId: 'America/Los_Angeles',
  },
  '95127': {
    zip: '95127',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.369641',
    longitude: '-121.817818',
    timeZoneId: 'America/Los_Angeles',
  },
  '95128': {
    zip: '95128',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.313617',
    longitude: '-121.93059',
    timeZoneId: 'America/Los_Angeles',
  },
  '95129': {
    zip: '95129',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.306098',
    longitude: '-121.999017',
    timeZoneId: 'America/Los_Angeles',
  },
  '95130': {
    zip: '95130',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.289556',
    longitude: '-121.975824',
    timeZoneId: 'America/Los_Angeles',
  },
  '95131': {
    zip: '95131',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.388051',
    longitude: '-121.887208',
    timeZoneId: 'America/Los_Angeles',
  },
  '95132': {
    zip: '95132',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.406513',
    longitude: '-121.855877',
    timeZoneId: 'America/Los_Angeles',
  },
  '95133': {
    zip: '95133',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.375782',
    longitude: '-121.856892',
    timeZoneId: 'America/Los_Angeles',
  },
  '95134': {
    zip: '95134',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.413678',
    longitude: '-121.946751',
    timeZoneId: 'America/Los_Angeles',
  },
  '95135': {
    zip: '95135',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.297932',
    longitude: '-121.756973',
    timeZoneId: 'America/Los_Angeles',
  },
  '95136': {
    zip: '95136',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.268106',
    longitude: '-121.844685',
    timeZoneId: 'America/Los_Angeles',
  },
  '95138': {
    zip: '95138',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.246945',
    longitude: '-121.776686',
    timeZoneId: 'America/Los_Angeles',
  },
  '95139': {
    zip: '95139',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.223089',
    longitude: '-121.768381',
    timeZoneId: 'America/Los_Angeles',
  },
  '95140': {
    zip: '95140',
    city: 'Mount Hamilton',
    state: 'CA',
    latitude: '37.3545',
    longitude: '-121.72134',
    timeZoneId: 'America/Los_Angeles',
  },
  '95141': {
    zip: '95141',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.176147',
    longitude: '-121.751682',
    timeZoneId: 'America/Los_Angeles',
  },
  '95148': {
    zip: '95148',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.329994',
    longitude: '-121.792353',
    timeZoneId: 'America/Los_Angeles',
  },
  '95150': {
    zip: '95150',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.289036',
    longitude: '-121.914383',
    timeZoneId: 'America/Los_Angeles',
  },
  '95151': {
    zip: '95151',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.291739',
    longitude: '-121.783728',
    timeZoneId: 'America/Los_Angeles',
  },
  '95152': {
    zip: '95152',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.417403',
    longitude: '-121.820034',
    timeZoneId: 'America/Los_Angeles',
  },
  '95153': {
    zip: '95153',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.248008',
    longitude: '-121.845139',
    timeZoneId: 'America/Los_Angeles',
  },
  '95154': {
    zip: '95154',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.261661',
    longitude: '-121.912816',
    timeZoneId: 'America/Los_Angeles',
  },
  '95155': {
    zip: '95155',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.3114',
    longitude: '-121.900813',
    timeZoneId: 'America/Los_Angeles',
  },
  '95156': {
    zip: '95156',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.359971',
    longitude: '-121.840023',
    timeZoneId: 'America/Los_Angeles',
  },
  '95157': {
    zip: '95157',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.299956',
    longitude: '-121.975922',
    timeZoneId: 'America/Los_Angeles',
  },
  '95158': {
    zip: '95158',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.259318',
    longitude: '-121.876807',
    timeZoneId: 'America/Los_Angeles',
  },
  '95159': {
    zip: '95159',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.31679',
    longitude: '-121.932386',
    timeZoneId: 'America/Los_Angeles',
  },
  '95160': {
    zip: '95160',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.21903',
    longitude: '-121.860381',
    timeZoneId: 'America/Los_Angeles',
  },
  '95161': {
    zip: '95161',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.38574',
    longitude: '-121.899338',
    timeZoneId: 'America/Los_Angeles',
  },
  '95164': {
    zip: '95164',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.392519',
    longitude: '-121.923858',
    timeZoneId: 'America/Los_Angeles',
  },
  '95170': {
    zip: '95170',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.31175',
    longitude: '-122.009013',
    timeZoneId: 'America/Los_Angeles',
  },
  '95172': {
    zip: '95172',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.333829',
    longitude: '-121.885149',
    timeZoneId: 'America/Los_Angeles',
  },
  '95173': {
    zip: '95173',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.334594',
    longitude: '-121.893325',
    timeZoneId: 'America/Los_Angeles',
  },
  '95190': {
    zip: '95190',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.376143',
    longitude: '-121.90232',
    timeZoneId: 'America/Los_Angeles',
  },
  '95191': {
    zip: '95191',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.324072',
    longitude: '-121.917236',
    timeZoneId: 'America/Los_Angeles',
  },
  '95192': {
    zip: '95192',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.341891',
    longitude: '-121.890214',
    timeZoneId: 'America/Los_Angeles',
  },
  '95193': {
    zip: '95193',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.244015',
    longitude: '-121.830989',
    timeZoneId: 'America/Los_Angeles',
  },
  '95194': {
    zip: '95194',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.343655',
    longitude: '-121.881178',
    timeZoneId: 'America/Los_Angeles',
  },
  '95196': {
    zip: '95196',
    city: 'San Jose',
    state: 'CA',
    latitude: '37.330413',
    longitude: '-121.891128',
    timeZoneId: 'America/Los_Angeles',
  },
  '95201': {
    zip: '95201',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.906882',
    longitude: '-121.223403',
    timeZoneId: 'America/Los_Angeles',
  },
  '95202': {
    zip: '95202',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.958445',
    longitude: '-121.28789',
    timeZoneId: 'America/Los_Angeles',
  },
  '95203': {
    zip: '95203',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.954003',
    longitude: '-121.309883',
    timeZoneId: 'America/Los_Angeles',
  },
  '95204': {
    zip: '95204',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.973809',
    longitude: '-121.31774',
    timeZoneId: 'America/Los_Angeles',
  },
  '95205': {
    zip: '95205',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.958243',
    longitude: '-121.224519',
    timeZoneId: 'America/Los_Angeles',
  },
  '95206': {
    zip: '95206',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.918977',
    longitude: '-121.312883',
    timeZoneId: 'America/Los_Angeles',
  },
  '95207': {
    zip: '95207',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.00188',
    longitude: '-121.323361',
    timeZoneId: 'America/Los_Angeles',
  },
  '95208': {
    zip: '95208',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.95595',
    longitude: '-121.441672',
    timeZoneId: 'America/Los_Angeles',
  },
  '95209': {
    zip: '95209',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.025675',
    longitude: '-121.348721',
    timeZoneId: 'America/Los_Angeles',
  },
  '95210': {
    zip: '95210',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.026084',
    longitude: '-121.295564',
    timeZoneId: 'America/Los_Angeles',
  },
  '95211': {
    zip: '95211',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.982119',
    longitude: '-121.309791',
    timeZoneId: 'America/Los_Angeles',
  },
  '95212': {
    zip: '95212',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.03988',
    longitude: '-121.237899',
    timeZoneId: 'America/Los_Angeles',
  },
  '95213': {
    zip: '95213',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.955793',
    longitude: '-121.438557',
    timeZoneId: 'America/Los_Angeles',
  },
  '95214': {
    zip: '95214',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.958267',
    longitude: '-121.271841',
    timeZoneId: 'America/Los_Angeles',
  },
  '95215': {
    zip: '95215',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.971031',
    longitude: '-121.203236',
    timeZoneId: 'America/Los_Angeles',
  },
  '95219': {
    zip: '95219',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.004042',
    longitude: '-121.354917',
    timeZoneId: 'America/Los_Angeles',
  },
  '95220': {
    zip: '95220',
    city: 'Acampo',
    state: 'CA',
    latitude: '38.196391',
    longitude: '-121.231802',
    timeZoneId: 'America/Los_Angeles',
  },
  '95221': {
    zip: '95221',
    city: 'Altaville',
    state: 'CA',
    latitude: '38.0838',
    longitude: '-120.56082',
    timeZoneId: 'America/Los_Angeles',
  },
  '95222': {
    zip: '95222',
    city: 'Angels Camp',
    state: 'CA',
    latitude: '38.074616',
    longitude: '-120.625043',
    timeZoneId: 'America/Los_Angeles',
  },
  '95223': {
    zip: '95223',
    city: 'Arnold',
    state: 'CA',
    latitude: '38.2638',
    longitude: '-120.33264',
    timeZoneId: 'America/Los_Angeles',
  },
  '95224': {
    zip: '95224',
    city: 'Avery',
    state: 'CA',
    latitude: '38.21018',
    longitude: '-120.366866',
    timeZoneId: 'America/Los_Angeles',
  },
  '95225': {
    zip: '95225',
    city: 'Burson',
    state: 'CA',
    latitude: '38.184216',
    longitude: '-120.886309',
    timeZoneId: 'America/Los_Angeles',
  },
  '95226': {
    zip: '95226',
    city: 'Campo Seco',
    state: 'CA',
    latitude: '38.224462',
    longitude: '-120.859612',
    timeZoneId: 'America/Los_Angeles',
  },
  '95227': {
    zip: '95227',
    city: 'Clements',
    state: 'CA',
    latitude: '38.201788',
    longitude: '-121.069202',
    timeZoneId: 'America/Los_Angeles',
  },
  '95228': {
    zip: '95228',
    city: 'Copperopolis',
    state: 'CA',
    latitude: '37.957753',
    longitude: '-120.638434',
    timeZoneId: 'America/Los_Angeles',
  },
  '95229': {
    zip: '95229',
    city: 'Douglas Flat',
    state: 'CA',
    latitude: '38.116433',
    longitude: '-120.457748',
    timeZoneId: 'America/Los_Angeles',
  },
  '95230': {
    zip: '95230',
    city: 'Farmington',
    state: 'CA',
    latitude: '37.908184',
    longitude: '-120.980728',
    timeZoneId: 'America/Los_Angeles',
  },
  '95231': {
    zip: '95231',
    city: 'French Camp',
    state: 'CA',
    latitude: '37.876234',
    longitude: '-121.2841',
    timeZoneId: 'America/Los_Angeles',
  },
  '95232': {
    zip: '95232',
    city: 'Glencoe',
    state: 'CA',
    latitude: '38.328099',
    longitude: '-120.543595',
    timeZoneId: 'America/Los_Angeles',
  },
  '95233': {
    zip: '95233',
    city: 'Hathaway Pines',
    state: 'CA',
    latitude: '38.185926',
    longitude: '-120.372735',
    timeZoneId: 'America/Los_Angeles',
  },
  '95234': {
    zip: '95234',
    city: 'Holt',
    state: 'CA',
    latitude: '37.934038',
    longitude: '-121.427141',
    timeZoneId: 'America/Los_Angeles',
  },
  '95236': {
    zip: '95236',
    city: 'Linden',
    state: 'CA',
    latitude: '38.027781',
    longitude: '-121.062818',
    timeZoneId: 'America/Los_Angeles',
  },
  '95237': {
    zip: '95237',
    city: 'Lockeford',
    state: 'CA',
    latitude: '38.156983',
    longitude: '-121.146692',
    timeZoneId: 'America/Los_Angeles',
  },
  '95240': {
    zip: '95240',
    city: 'Lodi',
    state: 'CA',
    latitude: '38.124431',
    longitude: '-121.267176',
    timeZoneId: 'America/Los_Angeles',
  },
  '95241': {
    zip: '95241',
    city: 'Lodi',
    state: 'CA',
    latitude: '38.152443',
    longitude: '-121.306349',
    timeZoneId: 'America/Los_Angeles',
  },
  '95242': {
    zip: '95242',
    city: 'Lodi',
    state: 'CA',
    latitude: '38.127032',
    longitude: '-121.322128',
    timeZoneId: 'America/Los_Angeles',
  },
  '95245': {
    zip: '95245',
    city: 'Mokelumne Hill',
    state: 'CA',
    latitude: '38.305939',
    longitude: '-120.598225',
    timeZoneId: 'America/Los_Angeles',
  },
  '95246': {
    zip: '95246',
    city: 'Mountain Ranch',
    state: 'CA',
    latitude: '38.242296',
    longitude: '-120.533316',
    timeZoneId: 'America/Los_Angeles',
  },
  '95247': {
    zip: '95247',
    city: 'Murphys',
    state: 'CA',
    latitude: '38.141938',
    longitude: '-120.445081',
    timeZoneId: 'America/Los_Angeles',
  },
  '95248': {
    zip: '95248',
    city: 'Rail Road Flat',
    state: 'CA',
    latitude: '38.315924',
    longitude: '-120.516711',
    timeZoneId: 'America/Los_Angeles',
  },
  '95249': {
    zip: '95249',
    city: 'San Andreas',
    state: 'CA',
    latitude: '38.198764',
    longitude: '-120.654173',
    timeZoneId: 'America/Los_Angeles',
  },
  '95251': {
    zip: '95251',
    city: 'Vallecito',
    state: 'CA',
    latitude: '38.085955',
    longitude: '-120.470775',
    timeZoneId: 'America/Los_Angeles',
  },
  '95252': {
    zip: '95252',
    city: 'Valley Springs',
    state: 'CA',
    latitude: '38.147636',
    longitude: '-120.857242',
    timeZoneId: 'America/Los_Angeles',
  },
  '95253': {
    zip: '95253',
    city: 'Victor',
    state: 'CA',
    latitude: '38.139786',
    longitude: '-121.205269',
    timeZoneId: 'America/Los_Angeles',
  },
  '95254': {
    zip: '95254',
    city: 'Wallace',
    state: 'CA',
    latitude: '38.172249',
    longitude: '-120.935009',
    timeZoneId: 'America/Los_Angeles',
  },
  '95255': {
    zip: '95255',
    city: 'West Point',
    state: 'CA',
    latitude: '38.403434',
    longitude: '-120.515493',
    timeZoneId: 'America/Los_Angeles',
  },
  '95257': {
    zip: '95257',
    city: 'Wilseyville',
    state: 'CA',
    latitude: '38.37512',
    longitude: '-120.486751',
    timeZoneId: 'America/Los_Angeles',
  },
  '95258': {
    zip: '95258',
    city: 'Woodbridge',
    state: 'CA',
    latitude: '38.154787',
    longitude: '-121.305131',
    timeZoneId: 'America/Los_Angeles',
  },
  '95267': {
    zip: '95267',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.003825',
    longitude: '-121.319038',
    timeZoneId: 'America/Los_Angeles',
  },
  '95269': {
    zip: '95269',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.018991',
    longitude: '-121.322247',
    timeZoneId: 'America/Los_Angeles',
  },
  '95296': {
    zip: '95296',
    city: 'Stockton',
    state: 'CA',
    latitude: '38.025975',
    longitude: '-121.297271',
    timeZoneId: 'America/Los_Angeles',
  },
  '95297': {
    zip: '95297',
    city: 'Stockton',
    state: 'CA',
    latitude: '37.970844',
    longitude: '-121.30788',
    timeZoneId: 'America/Los_Angeles',
  },
  '95301': {
    zip: '95301',
    city: 'Atwater',
    state: 'CA',
    latitude: '37.349553',
    longitude: '-120.616931',
    timeZoneId: 'America/Los_Angeles',
  },
  '95303': {
    zip: '95303',
    city: 'Ballico',
    state: 'CA',
    latitude: '37.447804',
    longitude: '-120.686746',
    timeZoneId: 'America/Los_Angeles',
  },
  '95304': {
    zip: '95304',
    city: 'Tracy',
    state: 'CA',
    latitude: '37.72782',
    longitude: '-121.4139',
    timeZoneId: 'America/Los_Angeles',
  },
  '95305': {
    zip: '95305',
    city: 'Big Oak Flat',
    state: 'CA',
    latitude: '37.821336',
    longitude: '-120.215805',
    timeZoneId: 'America/Los_Angeles',
  },
  '95306': {
    zip: '95306',
    city: 'Catheys Valley',
    state: 'CA',
    latitude: '37.437371',
    longitude: '-120.090278',
    timeZoneId: 'America/Los_Angeles',
  },
  '95307': {
    zip: '95307',
    city: 'Ceres',
    state: 'CA',
    latitude: '37.585454',
    longitude: '-120.956323',
    timeZoneId: 'America/Los_Angeles',
  },
  '95309': {
    zip: '95309',
    city: 'Chinese Camp',
    state: 'CA',
    latitude: '37.863817',
    longitude: '-120.440744',
    timeZoneId: 'America/Los_Angeles',
  },
  '95310': {
    zip: '95310',
    city: 'Columbia',
    state: 'CA',
    latitude: '38.043041',
    longitude: '-120.391074',
    timeZoneId: 'America/Los_Angeles',
  },
  '95311': {
    zip: '95311',
    city: 'Coulterville',
    state: 'CA',
    latitude: '37.728888',
    longitude: '-120.198361',
    timeZoneId: 'America/Los_Angeles',
  },
  '95312': {
    zip: '95312',
    city: 'Cressey',
    state: 'CA',
    latitude: '37.397581',
    longitude: '-120.618353',
    timeZoneId: 'America/Los_Angeles',
  },
  '95313': {
    zip: '95313',
    city: 'Crows Landing',
    state: 'CA',
    latitude: '37.428427',
    longitude: '-121.043212',
    timeZoneId: 'America/Los_Angeles',
  },
  '95315': {
    zip: '95315',
    city: 'Delhi',
    state: 'CA',
    latitude: '37.428901',
    longitude: '-120.771361',
    timeZoneId: 'America/Los_Angeles',
  },
  '95316': {
    zip: '95316',
    city: 'Denair',
    state: 'CA',
    latitude: '37.544007',
    longitude: '-120.792057',
    timeZoneId: 'America/Los_Angeles',
  },
  '95317': {
    zip: '95317',
    city: 'El Nido',
    state: 'CA',
    latitude: '37.12737',
    longitude: '-120.477572',
    timeZoneId: 'America/Los_Angeles',
  },
  '95318': {
    zip: '95318',
    city: 'El Portal',
    state: 'CA',
    latitude: '37.648397',
    longitude: '-119.854301',
    timeZoneId: 'America/Los_Angeles',
  },
  '95319': {
    zip: '95319',
    city: 'Empire',
    state: 'CA',
    latitude: '37.647502',
    longitude: '-120.904123',
    timeZoneId: 'America/Los_Angeles',
  },
  '95320': {
    zip: '95320',
    city: 'Escalon',
    state: 'CA',
    latitude: '37.799287',
    longitude: '-121.005121',
    timeZoneId: 'America/Los_Angeles',
  },
  '95321': {
    zip: '95321',
    city: 'Groveland',
    state: 'CA',
    latitude: '37.88511',
    longitude: '-119.85425',
    timeZoneId: 'America/Los_Angeles',
  },
  '95322': {
    zip: '95322',
    city: 'Gustine',
    state: 'CA',
    latitude: '37.1889',
    longitude: '-120.9861',
    timeZoneId: 'America/Los_Angeles',
  },
  '95323': {
    zip: '95323',
    city: 'Hickman',
    state: 'CA',
    latitude: '37.622821',
    longitude: '-120.734378',
    timeZoneId: 'America/Los_Angeles',
  },
  '95324': {
    zip: '95324',
    city: 'Hilmar',
    state: 'CA',
    latitude: '37.397801',
    longitude: '-120.871764',
    timeZoneId: 'America/Los_Angeles',
  },
  '95325': {
    zip: '95325',
    city: 'Hornitos',
    state: 'CA',
    latitude: '37.509349',
    longitude: '-120.21413',
    timeZoneId: 'America/Los_Angeles',
  },
  '95326': {
    zip: '95326',
    city: 'Hughson',
    state: 'CA',
    latitude: '37.596929',
    longitude: '-120.858572',
    timeZoneId: 'America/Los_Angeles',
  },
  '95327': {
    zip: '95327',
    city: 'Jamestown',
    state: 'CA',
    latitude: '37.919269',
    longitude: '-120.439562',
    timeZoneId: 'America/Los_Angeles',
  },
  '95328': {
    zip: '95328',
    city: 'Keyes',
    state: 'CA',
    latitude: '37.560712',
    longitude: '-120.913563',
    timeZoneId: 'America/Los_Angeles',
  },
  '95329': {
    zip: '95329',
    city: 'La Grange',
    state: 'CA',
    latitude: '37.69054',
    longitude: '-120.353143',
    timeZoneId: 'America/Los_Angeles',
  },
  '95330': {
    zip: '95330',
    city: 'Lathrop',
    state: 'CA',
    latitude: '37.818495',
    longitude: '-121.286548',
    timeZoneId: 'America/Los_Angeles',
  },
  '95333': {
    zip: '95333',
    city: 'Le Grand',
    state: 'CA',
    latitude: '37.239816',
    longitude: '-120.263215',
    timeZoneId: 'America/Los_Angeles',
  },
  '95334': {
    zip: '95334',
    city: 'Livingston',
    state: 'CA',
    latitude: '37.376697',
    longitude: '-120.726729',
    timeZoneId: 'America/Los_Angeles',
  },
  '95335': {
    zip: '95335',
    city: 'Long Barn',
    state: 'CA',
    latitude: '38.21298',
    longitude: '-120.03948',
    timeZoneId: 'America/Los_Angeles',
  },
  '95336': {
    zip: '95336',
    city: 'Manteca',
    state: 'CA',
    latitude: '37.805831',
    longitude: '-121.221492',
    timeZoneId: 'America/Los_Angeles',
  },
  '95337': {
    zip: '95337',
    city: 'Manteca',
    state: 'CA',
    latitude: '37.782913',
    longitude: '-121.231166',
    timeZoneId: 'America/Los_Angeles',
  },
  '95338': {
    zip: '95338',
    city: 'Mariposa',
    state: 'CA',
    latitude: '37.483473',
    longitude: '-119.905335',
    timeZoneId: 'America/Los_Angeles',
  },
  '95340': {
    zip: '95340',
    city: 'Merced',
    state: 'CA',
    latitude: '37.299167',
    longitude: '-120.474945',
    timeZoneId: 'America/Los_Angeles',
  },
  '95341': {
    zip: '95341',
    city: 'Merced',
    state: 'CA',
    latitude: '37.341483',
    longitude: '-120.487724',
    timeZoneId: 'America/Los_Angeles',
  },
  '95343': {
    zip: '95343',
    city: 'Merced',
    state: 'CA',
    latitude: '37.293701',
    longitude: '-120.487843',
    timeZoneId: 'America/Los_Angeles',
  },
  '95344': {
    zip: '95344',
    city: 'Merced',
    state: 'CA',
    latitude: '37.377754',
    longitude: '-120.613757',
    timeZoneId: 'America/Los_Angeles',
  },
  '95345': {
    zip: '95345',
    city: 'Midpines',
    state: 'CA',
    latitude: '37.564531',
    longitude: '-119.946697',
    timeZoneId: 'America/Los_Angeles',
  },
  '95346': {
    zip: '95346',
    city: 'Mi Wuk Village',
    state: 'CA',
    latitude: '38.067102',
    longitude: '-120.180965',
    timeZoneId: 'America/Los_Angeles',
  },
  '95347': {
    zip: '95347',
    city: 'Moccasin',
    state: 'CA',
    latitude: '37.78907',
    longitude: '-120.306245',
    timeZoneId: 'America/Los_Angeles',
  },
  '95348': {
    zip: '95348',
    city: 'Merced',
    state: 'CA',
    latitude: '37.331406',
    longitude: '-120.499796',
    timeZoneId: 'America/Los_Angeles',
  },
  '95350': {
    zip: '95350',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.664246',
    longitude: '-120.992352',
    timeZoneId: 'America/Los_Angeles',
  },
  '95351': {
    zip: '95351',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.629231',
    longitude: '-121.008022',
    timeZoneId: 'America/Los_Angeles',
  },
  '95352': {
    zip: '95352',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.663366',
    longitude: '-120.994582',
    timeZoneId: 'America/Los_Angeles',
  },
  '95353': {
    zip: '95353',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.665412',
    longitude: '-120.944142',
    timeZoneId: 'America/Los_Angeles',
  },
  '95354': {
    zip: '95354',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.643098',
    longitude: '-120.96879',
    timeZoneId: 'America/Los_Angeles',
  },
  '95355': {
    zip: '95355',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.675966',
    longitude: '-120.944878',
    timeZoneId: 'America/Los_Angeles',
  },
  '95356': {
    zip: '95356',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.700102',
    longitude: '-121.032875',
    timeZoneId: 'America/Los_Angeles',
  },
  '95357': {
    zip: '95357',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.65447',
    longitude: '-120.909982',
    timeZoneId: 'America/Los_Angeles',
  },
  '95358': {
    zip: '95358',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.627387',
    longitude: '-121.051694',
    timeZoneId: 'America/Los_Angeles',
  },
  '95360': {
    zip: '95360',
    city: 'Newman',
    state: 'CA',
    latitude: '37.346607',
    longitude: '-121.075271',
    timeZoneId: 'America/Los_Angeles',
  },
  '95361': {
    zip: '95361',
    city: 'Oakdale',
    state: 'CA',
    latitude: '37.781218',
    longitude: '-120.835377',
    timeZoneId: 'America/Los_Angeles',
  },
  '95363': {
    zip: '95363',
    city: 'Patterson',
    state: 'CA',
    latitude: '37.486062',
    longitude: '-121.129906',
    timeZoneId: 'America/Los_Angeles',
  },
  '95364': {
    zip: '95364',
    city: 'Pinecrest',
    state: 'CA',
    latitude: '38.32073',
    longitude: '-119.823875',
    timeZoneId: 'America/Los_Angeles',
  },
  '95365': {
    zip: '95365',
    city: 'Planada',
    state: 'CA',
    latitude: '37.29178',
    longitude: '-120.317118',
    timeZoneId: 'America/Los_Angeles',
  },
  '95366': {
    zip: '95366',
    city: 'Ripon',
    state: 'CA',
    latitude: '37.778832',
    longitude: '-121.124563',
    timeZoneId: 'America/Los_Angeles',
  },
  '95367': {
    zip: '95367',
    city: 'Riverbank',
    state: 'CA',
    latitude: '37.733057',
    longitude: '-120.941267',
    timeZoneId: 'America/Los_Angeles',
  },
  '95368': {
    zip: '95368',
    city: 'Salida',
    state: 'CA',
    latitude: '37.708295',
    longitude: '-121.092091',
    timeZoneId: 'America/Los_Angeles',
  },
  '95369': {
    zip: '95369',
    city: 'Snelling',
    state: 'CA',
    latitude: '37.513396',
    longitude: '-120.469416',
    timeZoneId: 'America/Los_Angeles',
  },
  '95370': {
    zip: '95370',
    city: 'Sonora',
    state: 'CA',
    latitude: '37.996763',
    longitude: '-120.330797',
    timeZoneId: 'America/Los_Angeles',
  },
  '95372': {
    zip: '95372',
    city: 'Soulsbyville',
    state: 'CA',
    latitude: '37.991804',
    longitude: '-120.263586',
    timeZoneId: 'America/Los_Angeles',
  },
  '95373': {
    zip: '95373',
    city: 'Standard',
    state: 'CA',
    latitude: '37.96662',
    longitude: '-120.3108',
    timeZoneId: 'America/Los_Angeles',
  },
  '95374': {
    zip: '95374',
    city: 'Stevinson',
    state: 'CA',
    latitude: '37.330524',
    longitude: '-120.872176',
    timeZoneId: 'America/Los_Angeles',
  },
  '95375': {
    zip: '95375',
    city: 'Strawberry',
    state: 'CA',
    latitude: '38.196233',
    longitude: '-120.013415',
    timeZoneId: 'America/Los_Angeles',
  },
  '95376': {
    zip: '95376',
    city: 'Tracy',
    state: 'CA',
    latitude: '37.732544',
    longitude: '-121.418973',
    timeZoneId: 'America/Los_Angeles',
  },
  '95377': {
    zip: '95377',
    city: 'Tracy',
    state: 'CA',
    latitude: '37.718503',
    longitude: '-121.453328',
    timeZoneId: 'America/Los_Angeles',
  },
  '95378': {
    zip: '95378',
    city: 'Tracy',
    state: 'CA',
    latitude: '37.79914',
    longitude: '-121.551767',
    timeZoneId: 'America/Los_Angeles',
  },
  '95379': {
    zip: '95379',
    city: 'Tuolumne',
    state: 'CA',
    latitude: '37.967269',
    longitude: '-120.241068',
    timeZoneId: 'America/Los_Angeles',
  },
  '95380': {
    zip: '95380',
    city: 'Turlock',
    state: 'CA',
    latitude: '37.498202',
    longitude: '-120.857364',
    timeZoneId: 'America/Los_Angeles',
  },
  '95381': {
    zip: '95381',
    city: 'Turlock',
    state: 'CA',
    latitude: '37.4964',
    longitude: '-120.858683',
    timeZoneId: 'America/Los_Angeles',
  },
  '95382': {
    zip: '95382',
    city: 'Turlock',
    state: 'CA',
    latitude: '37.520139',
    longitude: '-120.850092',
    timeZoneId: 'America/Los_Angeles',
  },
  '95383': {
    zip: '95383',
    city: 'Twain Harte',
    state: 'CA',
    latitude: '38.045218',
    longitude: '-120.226725',
    timeZoneId: 'America/Los_Angeles',
  },
  '95385': {
    zip: '95385',
    city: 'Vernalis',
    state: 'CA',
    latitude: '37.619677',
    longitude: '-121.246442',
    timeZoneId: 'America/Los_Angeles',
  },
  '95386': {
    zip: '95386',
    city: 'Waterford',
    state: 'CA',
    latitude: '37.657157',
    longitude: '-120.735523',
    timeZoneId: 'America/Los_Angeles',
  },
  '95387': {
    zip: '95387',
    city: 'Westley',
    state: 'CA',
    latitude: '37.567797',
    longitude: '-121.211323',
    timeZoneId: 'America/Los_Angeles',
  },
  '95388': {
    zip: '95388',
    city: 'Winton',
    state: 'CA',
    latitude: '37.399943',
    longitude: '-120.612748',
    timeZoneId: 'America/Los_Angeles',
  },
  '95389': {
    zip: '95389',
    city: 'Yosemite National Park',
    state: 'CA',
    latitude: '37.581703',
    longitude: '-119.658918',
    timeZoneId: 'America/Los_Angeles',
  },
  '95391': {
    zip: '95391',
    city: 'Tracy',
    state: 'CA',
    latitude: '37.760523',
    longitude: '-121.546105',
    timeZoneId: 'America/Los_Angeles',
  },
  '95397': {
    zip: '95397',
    city: 'Modesto',
    state: 'CA',
    latitude: '37.660766',
    longitude: '-120.993431',
    timeZoneId: 'America/Los_Angeles',
  },
  '95401': {
    zip: '95401',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.452109',
    longitude: '-122.761027',
    timeZoneId: 'America/Los_Angeles',
  },
  '95402': {
    zip: '95402',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.471813',
    longitude: '-122.607592',
    timeZoneId: 'America/Los_Angeles',
  },
  '95403': {
    zip: '95403',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.479575',
    longitude: '-122.750548',
    timeZoneId: 'America/Los_Angeles',
  },
  '95404': {
    zip: '95404',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.458382',
    longitude: '-122.685265',
    timeZoneId: 'America/Los_Angeles',
  },
  '95405': {
    zip: '95405',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.444478',
    longitude: '-122.655453',
    timeZoneId: 'America/Los_Angeles',
  },
  '95406': {
    zip: '95406',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.459917',
    longitude: '-122.727704',
    timeZoneId: 'America/Los_Angeles',
  },
  '95407': {
    zip: '95407',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.405151',
    longitude: '-122.735183',
    timeZoneId: 'America/Los_Angeles',
  },
  '95409': {
    zip: '95409',
    city: 'Santa Rosa',
    state: 'CA',
    latitude: '38.462708',
    longitude: '-122.638647',
    timeZoneId: 'America/Los_Angeles',
  },
  '95410': {
    zip: '95410',
    city: 'Albion',
    state: 'CA',
    latitude: '39.214084',
    longitude: '-123.70535',
    timeZoneId: 'America/Los_Angeles',
  },
  '95412': {
    zip: '95412',
    city: 'Annapolis',
    state: 'CA',
    latitude: '38.694238',
    longitude: '-123.326413',
    timeZoneId: 'America/Los_Angeles',
  },
  '95415': {
    zip: '95415',
    city: 'Boonville',
    state: 'CA',
    latitude: '39.004096',
    longitude: '-123.358036',
    timeZoneId: 'America/Los_Angeles',
  },
  '95416': {
    zip: '95416',
    city: 'Boyes Hot Springs',
    state: 'CA',
    latitude: '38.312407',
    longitude: '-122.482861',
    timeZoneId: 'America/Los_Angeles',
  },
  '95417': {
    zip: '95417',
    city: 'Branscomb',
    state: 'CA',
    latitude: '39.663318',
    longitude: '-123.633905',
    timeZoneId: 'America/Los_Angeles',
  },
  '95418': {
    zip: '95418',
    city: 'Calpella',
    state: 'CA',
    latitude: '39.15132',
    longitude: '-123.2088',
    timeZoneId: 'America/Los_Angeles',
  },
  '95419': {
    zip: '95419',
    city: 'Camp Meeker',
    state: 'CA',
    latitude: '38.426039',
    longitude: '-122.958796',
    timeZoneId: 'America/Los_Angeles',
  },
  '95420': {
    zip: '95420',
    city: 'Caspar',
    state: 'CA',
    latitude: '39.366694',
    longitude: '-123.810276',
    timeZoneId: 'America/Los_Angeles',
  },
  '95421': {
    zip: '95421',
    city: 'Cazadero',
    state: 'CA',
    latitude: '38.535522',
    longitude: '-123.135789',
    timeZoneId: 'America/Los_Angeles',
  },
  '95422': {
    zip: '95422',
    city: 'Clearlake',
    state: 'CA',
    latitude: '38.954845',
    longitude: '-122.642133',
    timeZoneId: 'America/Los_Angeles',
  },
  '95423': {
    zip: '95423',
    city: 'Clearlake Oaks',
    state: 'CA',
    latitude: '39.02534',
    longitude: '-122.667855',
    timeZoneId: 'America/Los_Angeles',
  },
  '95424': {
    zip: '95424',
    city: 'Clearlake Park',
    state: 'CA',
    latitude: '38.968797',
    longitude: '-122.661577',
    timeZoneId: 'America/Los_Angeles',
  },
  '95425': {
    zip: '95425',
    city: 'Cloverdale',
    state: 'CA',
    latitude: '38.76948',
    longitude: '-122.94834',
    timeZoneId: 'America/Los_Angeles',
  },
  '95426': {
    zip: '95426',
    city: 'Cobb',
    state: 'CA',
    latitude: '38.840193',
    longitude: '-122.723858',
    timeZoneId: 'America/Los_Angeles',
  },
  '95427': {
    zip: '95427',
    city: 'Comptche',
    state: 'CA',
    latitude: '39.261694',
    longitude: '-123.582922',
    timeZoneId: 'America/Los_Angeles',
  },
  '95428': {
    zip: '95428',
    city: 'Covelo',
    state: 'CA',
    latitude: '39.795628',
    longitude: '-123.247811',
    timeZoneId: 'America/Los_Angeles',
  },
  '95429': {
    zip: '95429',
    city: 'Dos Rios',
    state: 'CA',
    latitude: '39.70638',
    longitude: '-123.32424',
    timeZoneId: 'America/Los_Angeles',
  },
  '95430': {
    zip: '95430',
    city: 'Duncans Mills',
    state: 'CA',
    latitude: '38.495218',
    longitude: '-123.143138',
    timeZoneId: 'America/Los_Angeles',
  },
  '95431': {
    zip: '95431',
    city: 'Eldridge',
    state: 'CA',
    latitude: '38.329558',
    longitude: '-122.525306',
    timeZoneId: 'America/Los_Angeles',
  },
  '95432': {
    zip: '95432',
    city: 'Elk',
    state: 'CA',
    latitude: '39.143139',
    longitude: '-123.713346',
    timeZoneId: 'America/Los_Angeles',
  },
  '95433': {
    zip: '95433',
    city: 'El Verano',
    state: 'CA',
    latitude: '38.299964',
    longitude: '-122.489265',
    timeZoneId: 'America/Los_Angeles',
  },
  '95435': {
    zip: '95435',
    city: 'Finley',
    state: 'CA',
    latitude: '39.005524',
    longitude: '-122.870799',
    timeZoneId: 'America/Los_Angeles',
  },
  '95436': {
    zip: '95436',
    city: 'Forestville',
    state: 'CA',
    latitude: '38.4909',
    longitude: '-122.90412',
    timeZoneId: 'America/Los_Angeles',
  },
  '95437': {
    zip: '95437',
    city: 'Fort Bragg',
    state: 'CA',
    latitude: '39.441739',
    longitude: '-123.78427',
    timeZoneId: 'America/Los_Angeles',
  },
  '95439': {
    zip: '95439',
    city: 'Fulton',
    state: 'CA',
    latitude: '38.492569',
    longitude: '-122.776272',
    timeZoneId: 'America/Los_Angeles',
  },
  '95441': {
    zip: '95441',
    city: 'Geyserville',
    state: 'CA',
    latitude: '38.7055',
    longitude: '-122.885073',
    timeZoneId: 'America/Los_Angeles',
  },
  '95442': {
    zip: '95442',
    city: 'Glen Ellen',
    state: 'CA',
    latitude: '38.372891',
    longitude: '-122.528168',
    timeZoneId: 'America/Los_Angeles',
  },
  '95443': {
    zip: '95443',
    city: 'Glenhaven',
    state: 'CA',
    latitude: '39.03133',
    longitude: '-122.740821',
    timeZoneId: 'America/Los_Angeles',
  },
  '95444': {
    zip: '95444',
    city: 'Graton',
    state: 'CA',
    latitude: '38.433837',
    longitude: '-122.865481',
    timeZoneId: 'America/Los_Angeles',
  },
  '95445': {
    zip: '95445',
    city: 'Gualala',
    state: 'CA',
    latitude: '38.805573',
    longitude: '-123.562187',
    timeZoneId: 'America/Los_Angeles',
  },
  '95446': {
    zip: '95446',
    city: 'Guerneville',
    state: 'CA',
    latitude: '38.494075',
    longitude: '-122.982674',
    timeZoneId: 'America/Los_Angeles',
  },
  '95448': {
    zip: '95448',
    city: 'Healdsburg',
    state: 'CA',
    latitude: '38.619385',
    longitude: '-122.860367',
    timeZoneId: 'America/Los_Angeles',
  },
  '95449': {
    zip: '95449',
    city: 'Hopland',
    state: 'CA',
    latitude: '38.983549',
    longitude: '-123.11474',
    timeZoneId: 'America/Los_Angeles',
  },
  '95450': {
    zip: '95450',
    city: 'Jenner',
    state: 'CA',
    latitude: '38.49936',
    longitude: '-123.18516',
    timeZoneId: 'America/Los_Angeles',
  },
  '95451': {
    zip: '95451',
    city: 'Kelseyville',
    state: 'CA',
    latitude: '38.966686',
    longitude: '-122.77646',
    timeZoneId: 'America/Los_Angeles',
  },
  '95452': {
    zip: '95452',
    city: 'Kenwood',
    state: 'CA',
    latitude: '38.414881',
    longitude: '-122.55383',
    timeZoneId: 'America/Los_Angeles',
  },
  '95453': {
    zip: '95453',
    city: 'Lakeport',
    state: 'CA',
    latitude: '39.052335',
    longitude: '-122.913246',
    timeZoneId: 'America/Los_Angeles',
  },
  '95454': {
    zip: '95454',
    city: 'Laytonville',
    state: 'CA',
    latitude: '39.731538',
    longitude: '-123.505679',
    timeZoneId: 'America/Los_Angeles',
  },
  '95456': {
    zip: '95456',
    city: 'Little River',
    state: 'CA',
    latitude: '39.266551',
    longitude: '-123.763145',
    timeZoneId: 'America/Los_Angeles',
  },
  '95457': {
    zip: '95457',
    city: 'Lower Lake',
    state: 'CA',
    latitude: '38.901949',
    longitude: '-122.613839',
    timeZoneId: 'America/Los_Angeles',
  },
  '95458': {
    zip: '95458',
    city: 'Lucerne',
    state: 'CA',
    latitude: '39.076936',
    longitude: '-122.782553',
    timeZoneId: 'America/Los_Angeles',
  },
  '95459': {
    zip: '95459',
    city: 'Manchester',
    state: 'CA',
    latitude: '38.989307',
    longitude: '-123.664351',
    timeZoneId: 'America/Los_Angeles',
  },
  '95460': {
    zip: '95460',
    city: 'Mendocino',
    state: 'CA',
    latitude: '39.31944',
    longitude: '-123.789058',
    timeZoneId: 'America/Los_Angeles',
  },
  '95461': {
    zip: '95461',
    city: 'Middletown',
    state: 'CA',
    latitude: '38.78586',
    longitude: '-122.6202',
    timeZoneId: 'America/Los_Angeles',
  },
  '95462': {
    zip: '95462',
    city: 'Monte Rio',
    state: 'CA',
    latitude: '38.469285',
    longitude: '-123.01201',
    timeZoneId: 'America/Los_Angeles',
  },
  '95463': {
    zip: '95463',
    city: 'Navarro',
    state: 'CA',
    latitude: '39.12648',
    longitude: '-123.54702',
    timeZoneId: 'America/Los_Angeles',
  },
  '95464': {
    zip: '95464',
    city: 'Nice',
    state: 'CA',
    latitude: '39.119398',
    longitude: '-122.845078',
    timeZoneId: 'America/Los_Angeles',
  },
  '95465': {
    zip: '95465',
    city: 'Occidental',
    state: 'CA',
    latitude: '38.401354',
    longitude: '-122.965484',
    timeZoneId: 'America/Los_Angeles',
  },
  '95466': {
    zip: '95466',
    city: 'Philo',
    state: 'CA',
    latitude: '39.111417',
    longitude: '-123.51519',
    timeZoneId: 'America/Los_Angeles',
  },
  '95467': {
    zip: '95467',
    city: 'Hidden Valley Lake',
    state: 'CA',
    latitude: '38.810967',
    longitude: '-122.556198',
    timeZoneId: 'America/Los_Angeles',
  },
  '95468': {
    zip: '95468',
    city: 'Point Arena',
    state: 'CA',
    latitude: '38.895072',
    longitude: '-123.653344',
    timeZoneId: 'America/Los_Angeles',
  },
  '95469': {
    zip: '95469',
    city: 'Potter Valley',
    state: 'CA',
    latitude: '39.346143',
    longitude: '-123.112031',
    timeZoneId: 'America/Los_Angeles',
  },
  '95470': {
    zip: '95470',
    city: 'Redwood Valley',
    state: 'CA',
    latitude: '39.280802',
    longitude: '-123.212931',
    timeZoneId: 'America/Los_Angeles',
  },
  '95471': {
    zip: '95471',
    city: 'Rio Nido',
    state: 'CA',
    latitude: '38.522828',
    longitude: '-122.980653',
    timeZoneId: 'America/Los_Angeles',
  },
  '95472': {
    zip: '95472',
    city: 'Sebastopol',
    state: 'CA',
    latitude: '38.40186',
    longitude: '-122.85168',
    timeZoneId: 'America/Los_Angeles',
  },
  '95473': {
    zip: '95473',
    city: 'Sebastopol',
    state: 'CA',
    latitude: '38.400744',
    longitude: '-122.824549',
    timeZoneId: 'America/Los_Angeles',
  },
  '95476': {
    zip: '95476',
    city: 'Sonoma',
    state: 'CA',
    latitude: '38.293075',
    longitude: '-122.470371',
    timeZoneId: 'America/Los_Angeles',
  },
  '95480': {
    zip: '95480',
    city: 'Stewarts Point',
    state: 'CA',
    latitude: '38.619693',
    longitude: '-123.377331',
    timeZoneId: 'America/Los_Angeles',
  },
  '95481': {
    zip: '95481',
    city: 'Talmage',
    state: 'CA',
    latitude: '39.130128',
    longitude: '-123.164095',
    timeZoneId: 'America/Los_Angeles',
  },
  '95482': {
    zip: '95482',
    city: 'Ukiah',
    state: 'CA',
    latitude: '39.152661',
    longitude: '-123.202275',
    timeZoneId: 'America/Los_Angeles',
  },
  '95485': {
    zip: '95485',
    city: 'Upper Lake',
    state: 'CA',
    latitude: '39.157111',
    longitude: '-122.905787',
    timeZoneId: 'America/Los_Angeles',
  },
  '95486': {
    zip: '95486',
    city: 'Villa Grande',
    state: 'CA',
    latitude: '38.47422',
    longitude: '-123.0351',
    timeZoneId: 'America/Los_Angeles',
  },
  '95487': {
    zip: '95487',
    city: 'Vineburg',
    state: 'CA',
    latitude: '38.251972',
    longitude: '-122.453982',
    timeZoneId: 'America/Los_Angeles',
  },
  '95488': {
    zip: '95488',
    city: 'Westport',
    state: 'CA',
    latitude: '39.641401',
    longitude: '-123.785539',
    timeZoneId: 'America/Los_Angeles',
  },
  '95490': {
    zip: '95490',
    city: 'Willits',
    state: 'CA',
    latitude: '39.47028',
    longitude: '-123.35946',
    timeZoneId: 'America/Los_Angeles',
  },
  '95492': {
    zip: '95492',
    city: 'Windsor',
    state: 'CA',
    latitude: '38.539164',
    longitude: '-122.805174',
    timeZoneId: 'America/Los_Angeles',
  },
  '95493': {
    zip: '95493',
    city: 'Witter Springs',
    state: 'CA',
    latitude: '39.186429',
    longitude: '-122.962942',
    timeZoneId: 'America/Los_Angeles',
  },
  '95494': {
    zip: '95494',
    city: 'Yorkville',
    state: 'CA',
    latitude: '38.89625',
    longitude: '-123.229549',
    timeZoneId: 'America/Los_Angeles',
  },
  '95497': {
    zip: '95497',
    city: 'The Sea Ranch',
    state: 'CA',
    latitude: '38.740768',
    longitude: '-123.483539',
    timeZoneId: 'America/Los_Angeles',
  },
  '95501': {
    zip: '95501',
    city: 'Eureka',
    state: 'CA',
    latitude: '40.780564',
    longitude: '-124.148905',
    timeZoneId: 'America/Los_Angeles',
  },
  '95502': {
    zip: '95502',
    city: 'Eureka',
    state: 'CA',
    latitude: '40.786017',
    longitude: '-124.19216',
    timeZoneId: 'America/Los_Angeles',
  },
  '95503': {
    zip: '95503',
    city: 'Eureka',
    state: 'CA',
    latitude: '40.759043',
    longitude: '-124.158947',
    timeZoneId: 'America/Los_Angeles',
  },
  '95511': {
    zip: '95511',
    city: 'Alderpoint',
    state: 'CA',
    latitude: '40.159508',
    longitude: '-123.581713',
    timeZoneId: 'America/Los_Angeles',
  },
  '95514': {
    zip: '95514',
    city: 'Blocksburg',
    state: 'CA',
    latitude: '40.293504',
    longitude: '-123.635475',
    timeZoneId: 'America/Los_Angeles',
  },
  '95518': {
    zip: '95518',
    city: 'Arcata',
    state: 'CA',
    latitude: '40.866107',
    longitude: '-124.080793',
    timeZoneId: 'America/Los_Angeles',
  },
  '95519': {
    zip: '95519',
    city: 'McKinleyville',
    state: 'CA',
    latitude: '40.8726',
    longitude: '-124.07064',
    timeZoneId: 'America/Los_Angeles',
  },
  '95521': {
    zip: '95521',
    city: 'Arcata',
    state: 'CA',
    latitude: '40.92876',
    longitude: '-124.06008',
    timeZoneId: 'America/Los_Angeles',
  },
  '95524': {
    zip: '95524',
    city: 'Bayside',
    state: 'CA',
    latitude: '40.827899',
    longitude: '-124.052999',
    timeZoneId: 'America/Los_Angeles',
  },
  '95525': {
    zip: '95525',
    city: 'Blue Lake',
    state: 'CA',
    latitude: '40.906634',
    longitude: '-123.938458',
    timeZoneId: 'America/Los_Angeles',
  },
  '95526': {
    zip: '95526',
    city: 'Bridgeville',
    state: 'CA',
    latitude: '40.46748',
    longitude: '-123.69342',
    timeZoneId: 'America/Los_Angeles',
  },
  '95527': {
    zip: '95527',
    city: 'Burnt Ranch',
    state: 'CA',
    latitude: '40.775234',
    longitude: '-123.42847',
    timeZoneId: 'America/Los_Angeles',
  },
  '95528': {
    zip: '95528',
    city: 'Carlotta',
    state: 'CA',
    latitude: '40.486485',
    longitude: '-123.88789',
    timeZoneId: 'America/Los_Angeles',
  },
  '95531': {
    zip: '95531',
    city: 'Crescent City',
    state: 'CA',
    latitude: '41.784192',
    longitude: '-124.168263',
    timeZoneId: 'America/Los_Angeles',
  },
  '95532': {
    zip: '95532',
    city: 'Crescent City',
    state: 'CA',
    latitude: '41.756509',
    longitude: '-124.203866',
    timeZoneId: 'America/Los_Angeles',
  },
  '95534': {
    zip: '95534',
    city: 'Cutten',
    state: 'CA',
    latitude: '40.793242',
    longitude: '-124.158139',
    timeZoneId: 'America/Los_Angeles',
  },
  '95536': {
    zip: '95536',
    city: 'Ferndale',
    state: 'CA',
    latitude: '40.565664',
    longitude: '-124.243554',
    timeZoneId: 'America/Los_Angeles',
  },
  '95537': {
    zip: '95537',
    city: 'Fields Landing',
    state: 'CA',
    latitude: '40.727358',
    longitude: '-124.211781',
    timeZoneId: 'America/Los_Angeles',
  },
  '95538': {
    zip: '95538',
    city: 'Fort Dick',
    state: 'CA',
    latitude: '41.838225',
    longitude: '-124.166415',
    timeZoneId: 'America/Los_Angeles',
  },
  '95540': {
    zip: '95540',
    city: 'Fortuna',
    state: 'CA',
    latitude: '40.585216',
    longitude: '-124.138734',
    timeZoneId: 'America/Los_Angeles',
  },
  '95542': {
    zip: '95542',
    city: 'Garberville',
    state: 'CA',
    latitude: '40.173931',
    longitude: '-123.912209',
    timeZoneId: 'America/Los_Angeles',
  },
  '95543': {
    zip: '95543',
    city: 'Gasquet',
    state: 'CA',
    latitude: '41.881896',
    longitude: '-123.874458',
    timeZoneId: 'America/Los_Angeles',
  },
  '95545': {
    zip: '95545',
    city: 'Honeydew',
    state: 'CA',
    latitude: '40.245055',
    longitude: '-124.11643',
    timeZoneId: 'America/Los_Angeles',
  },
  '95546': {
    zip: '95546',
    city: 'Hoopa',
    state: 'CA',
    latitude: '41.168795',
    longitude: '-123.727286',
    timeZoneId: 'America/Los_Angeles',
  },
  '95547': {
    zip: '95547',
    city: 'Hydesville',
    state: 'CA',
    latitude: '40.545772',
    longitude: '-124.092537',
    timeZoneId: 'America/Los_Angeles',
  },
  '95548': {
    zip: '95548',
    city: 'Klamath',
    state: 'CA',
    latitude: '41.537759',
    longitude: '-124.033969',
    timeZoneId: 'America/Los_Angeles',
  },
  '95549': {
    zip: '95549',
    city: 'Kneeland',
    state: 'CA',
    latitude: '40.729684',
    longitude: '-123.95823',
    timeZoneId: 'America/Los_Angeles',
  },
  '95550': {
    zip: '95550',
    city: 'Korbel',
    state: 'CA',
    latitude: '40.777252',
    longitude: '-123.881208',
    timeZoneId: 'America/Los_Angeles',
  },
  '95551': {
    zip: '95551',
    city: 'Loleta',
    state: 'CA',
    latitude: '40.653296',
    longitude: '-124.227048',
    timeZoneId: 'America/Los_Angeles',
  },
  '95552': {
    zip: '95552',
    city: 'Mad River',
    state: 'CA',
    latitude: '40.4992',
    longitude: '-123.552088',
    timeZoneId: 'America/Los_Angeles',
  },
  '95553': {
    zip: '95553',
    city: 'Miranda',
    state: 'CA',
    latitude: '40.212752',
    longitude: '-123.842699',
    timeZoneId: 'America/Los_Angeles',
  },
  '95554': {
    zip: '95554',
    city: 'Myers Flat',
    state: 'CA',
    latitude: '40.296472',
    longitude: '-123.803222',
    timeZoneId: 'America/Los_Angeles',
  },
  '95555': {
    zip: '95555',
    city: 'Orick',
    state: 'CA',
    latitude: '41.286807',
    longitude: '-124.06995',
    timeZoneId: 'America/Los_Angeles',
  },
  '95556': {
    zip: '95556',
    city: 'Orleans',
    state: 'CA',
    latitude: '41.289963',
    longitude: '-123.551367',
    timeZoneId: 'America/Los_Angeles',
  },
  '95558': {
    zip: '95558',
    city: 'Petrolia',
    state: 'CA',
    latitude: '40.260403',
    longitude: '-124.18962',
    timeZoneId: 'America/Los_Angeles',
  },
  '95559': {
    zip: '95559',
    city: 'Phillipsville',
    state: 'CA',
    latitude: '40.211585',
    longitude: '-123.782226',
    timeZoneId: 'America/Los_Angeles',
  },
  '95560': {
    zip: '95560',
    city: 'Redway',
    state: 'CA',
    latitude: '40.120251',
    longitude: '-123.869032',
    timeZoneId: 'America/Los_Angeles',
  },
  '95562': {
    zip: '95562',
    city: 'Rio Dell',
    state: 'CA',
    latitude: '40.504206',
    longitude: '-124.107339',
    timeZoneId: 'America/Los_Angeles',
  },
  '95563': {
    zip: '95563',
    city: 'Salyer',
    state: 'CA',
    latitude: '40.882541',
    longitude: '-123.541231',
    timeZoneId: 'America/Los_Angeles',
  },
  '95564': {
    zip: '95564',
    city: 'Samoa',
    state: 'CA',
    latitude: '40.802899',
    longitude: '-124.191388',
    timeZoneId: 'America/Los_Angeles',
  },
  '95565': {
    zip: '95565',
    city: 'Scotia',
    state: 'CA',
    latitude: '40.449567',
    longitude: '-124.001904',
    timeZoneId: 'America/Los_Angeles',
  },
  '95567': {
    zip: '95567',
    city: 'Smith River',
    state: 'CA',
    latitude: '41.927467',
    longitude: '-124.164768',
    timeZoneId: 'America/Los_Angeles',
  },
  '95568': {
    zip: '95568',
    city: 'Somes Bar',
    state: 'CA',
    latitude: '41.435199',
    longitude: '-123.488452',
    timeZoneId: 'America/Los_Angeles',
  },
  '95569': {
    zip: '95569',
    city: 'Redcrest',
    state: 'CA',
    latitude: '40.390817',
    longitude: '-123.920383',
    timeZoneId: 'America/Los_Angeles',
  },
  '95570': {
    zip: '95570',
    city: 'Trinidad',
    state: 'CA',
    latitude: '41.072862',
    longitude: '-124.128197',
    timeZoneId: 'America/Los_Angeles',
  },
  '95571': {
    zip: '95571',
    city: 'Weott',
    state: 'CA',
    latitude: '40.324624',
    longitude: '-123.920032',
    timeZoneId: 'America/Los_Angeles',
  },
  '95573': {
    zip: '95573',
    city: 'Willow Creek',
    state: 'CA',
    latitude: '40.953614',
    longitude: '-123.648588',
    timeZoneId: 'America/Los_Angeles',
  },
  '95585': {
    zip: '95585',
    city: 'Leggett',
    state: 'CA',
    latitude: '39.845655',
    longitude: '-123.633673',
    timeZoneId: 'America/Los_Angeles',
  },
  '95587': {
    zip: '95587',
    city: 'Piercy',
    state: 'CA',
    latitude: '39.950369',
    longitude: '-123.746566',
    timeZoneId: 'America/Los_Angeles',
  },
  '95589': {
    zip: '95589',
    city: 'Whitethorn',
    state: 'CA',
    latitude: '40.088692',
    longitude: '-124.04871',
    timeZoneId: 'America/Los_Angeles',
  },
  '95595': {
    zip: '95595',
    city: 'Zenia',
    state: 'CA',
    latitude: '40.180955',
    longitude: '-123.416183',
    timeZoneId: 'America/Los_Angeles',
  },
  '95601': {
    zip: '95601',
    city: 'Amador City',
    state: 'CA',
    latitude: '38.423289',
    longitude: '-120.826231',
    timeZoneId: 'America/Los_Angeles',
  },
  '95602': {
    zip: '95602',
    city: 'Auburn',
    state: 'CA',
    latitude: '38.985263',
    longitude: '-121.123044',
    timeZoneId: 'America/Los_Angeles',
  },
  '95603': {
    zip: '95603',
    city: 'Auburn',
    state: 'CA',
    latitude: '38.92182',
    longitude: '-121.09812',
    timeZoneId: 'America/Los_Angeles',
  },
  '95604': {
    zip: '95604',
    city: 'Auburn',
    state: 'CA',
    latitude: '38.90292',
    longitude: '-121.06698',
    timeZoneId: 'America/Los_Angeles',
  },
  '95605': {
    zip: '95605',
    city: 'West Sacramento',
    state: 'CA',
    latitude: '38.594722',
    longitude: '-121.540556',
    timeZoneId: 'America/Los_Angeles',
  },
  '95606': {
    zip: '95606',
    city: 'Brooks',
    state: 'CA',
    latitude: '38.737977',
    longitude: '-122.148254',
    timeZoneId: 'America/Los_Angeles',
  },
  '95607': {
    zip: '95607',
    city: 'Capay',
    state: 'CA',
    latitude: '38.68817',
    longitude: '-122.139102',
    timeZoneId: 'America/Los_Angeles',
  },
  '95608': {
    zip: '95608',
    city: 'Carmichael',
    state: 'CA',
    latitude: '38.628172',
    longitude: '-121.327836',
    timeZoneId: 'America/Los_Angeles',
  },
  '95609': {
    zip: '95609',
    city: 'Carmichael',
    state: 'CA',
    latitude: '38.625313',
    longitude: '-121.32742',
    timeZoneId: 'America/Los_Angeles',
  },
  '95610': {
    zip: '95610',
    city: 'Citrus Heights',
    state: 'CA',
    latitude: '38.696835',
    longitude: '-121.265911',
    timeZoneId: 'America/Los_Angeles',
  },
  '95611': {
    zip: '95611',
    city: 'Citrus Heights',
    state: 'CA',
    latitude: '38.707001',
    longitude: '-121.277971',
    timeZoneId: 'America/Los_Angeles',
  },
  '95612': {
    zip: '95612',
    city: 'Clarksburg',
    state: 'CA',
    latitude: '38.415587',
    longitude: '-121.531796',
    timeZoneId: 'America/Los_Angeles',
  },
  '95613': {
    zip: '95613',
    city: 'Coloma',
    state: 'CA',
    latitude: '38.827378',
    longitude: '-120.881447',
    timeZoneId: 'America/Los_Angeles',
  },
  '95614': {
    zip: '95614',
    city: 'Cool',
    state: 'CA',
    latitude: '38.905494',
    longitude: '-120.956183',
    timeZoneId: 'America/Los_Angeles',
  },
  '95615': {
    zip: '95615',
    city: 'Courtland',
    state: 'CA',
    latitude: '38.316277',
    longitude: '-121.553829',
    timeZoneId: 'America/Los_Angeles',
  },
  '95616': {
    zip: '95616',
    city: 'Davis',
    state: 'CA',
    latitude: '38.556431',
    longitude: '-121.746377',
    timeZoneId: 'America/Los_Angeles',
  },
  '95617': {
    zip: '95617',
    city: 'Davis',
    state: 'CA',
    latitude: '38.547703',
    longitude: '-121.722184',
    timeZoneId: 'America/Los_Angeles',
  },
  '95618': {
    zip: '95618',
    city: 'Davis',
    state: 'CA',
    latitude: '38.545',
    longitude: '-121.739444',
    timeZoneId: 'America/Los_Angeles',
  },
  '95619': {
    zip: '95619',
    city: 'Diamond Springs',
    state: 'CA',
    latitude: '38.665863',
    longitude: '-120.822937',
    timeZoneId: 'America/Los_Angeles',
  },
  '95620': {
    zip: '95620',
    city: 'Dixon',
    state: 'CA',
    latitude: '38.440059',
    longitude: '-121.822861',
    timeZoneId: 'America/Los_Angeles',
  },
  '95621': {
    zip: '95621',
    city: 'Citrus Heights',
    state: 'CA',
    latitude: '38.697816',
    longitude: '-121.311197',
    timeZoneId: 'America/Los_Angeles',
  },
  '95623': {
    zip: '95623',
    city: 'El Dorado',
    state: 'CA',
    latitude: '38.67279',
    longitude: '-120.847975',
    timeZoneId: 'America/Los_Angeles',
  },
  '95624': {
    zip: '95624',
    city: 'Elk Grove',
    state: 'CA',
    latitude: '38.435247',
    longitude: '-121.279837',
    timeZoneId: 'America/Los_Angeles',
  },
  '95625': {
    zip: '95625',
    city: 'Elmira',
    state: 'CA',
    latitude: '38.346263',
    longitude: '-121.90237',
    timeZoneId: 'America/Los_Angeles',
  },
  '95626': {
    zip: '95626',
    city: 'Elverta',
    state: 'CA',
    latitude: '38.723216',
    longitude: '-121.45452',
    timeZoneId: 'America/Los_Angeles',
  },
  '95627': {
    zip: '95627',
    city: 'Esparto',
    state: 'CA',
    latitude: '38.691535',
    longitude: '-122.017443',
    timeZoneId: 'America/Los_Angeles',
  },
  '95628': {
    zip: '95628',
    city: 'Fair Oaks',
    state: 'CA',
    latitude: '38.65224',
    longitude: '-121.255592',
    timeZoneId: 'America/Los_Angeles',
  },
  '95629': {
    zip: '95629',
    city: 'Fiddletown',
    state: 'CA',
    latitude: '38.502916',
    longitude: '-120.725438',
    timeZoneId: 'America/Los_Angeles',
  },
  '95630': {
    zip: '95630',
    city: 'Folsom',
    state: 'CA',
    latitude: '38.683096',
    longitude: '-121.160535',
    timeZoneId: 'America/Los_Angeles',
  },
  '95631': {
    zip: '95631',
    city: 'Foresthill',
    state: 'CA',
    latitude: '39.009228',
    longitude: '-120.852443',
    timeZoneId: 'America/Los_Angeles',
  },
  '95632': {
    zip: '95632',
    city: 'Galt',
    state: 'CA',
    latitude: '38.277565',
    longitude: '-121.294004',
    timeZoneId: 'America/Los_Angeles',
  },
  '95633': {
    zip: '95633',
    city: 'Garden Valley',
    state: 'CA',
    latitude: '38.848412',
    longitude: '-120.843022',
    timeZoneId: 'America/Los_Angeles',
  },
  '95634': {
    zip: '95634',
    city: 'Georgetown',
    state: 'CA',
    latitude: '38.91777',
    longitude: '-120.790653',
    timeZoneId: 'America/Los_Angeles',
  },
  '95635': {
    zip: '95635',
    city: 'Greenwood',
    state: 'CA',
    latitude: '38.917442',
    longitude: '-120.901475',
    timeZoneId: 'America/Los_Angeles',
  },
  '95636': {
    zip: '95636',
    city: 'Grizzly Flats',
    state: 'CA',
    latitude: '38.652667',
    longitude: '-120.488469',
    timeZoneId: 'America/Los_Angeles',
  },
  '95637': {
    zip: '95637',
    city: 'Guinda',
    state: 'CA',
    latitude: '38.810295',
    longitude: '-122.220323',
    timeZoneId: 'America/Los_Angeles',
  },
  '95638': {
    zip: '95638',
    city: 'Herald',
    state: 'CA',
    latitude: '38.347884',
    longitude: '-121.109377',
    timeZoneId: 'America/Los_Angeles',
  },
  '95639': {
    zip: '95639',
    city: 'Hood',
    state: 'CA',
    latitude: '38.368835',
    longitude: '-121.517767',
    timeZoneId: 'America/Los_Angeles',
  },
  '95640': {
    zip: '95640',
    city: 'Ione',
    state: 'CA',
    latitude: '38.329832',
    longitude: '-120.932789',
    timeZoneId: 'America/Los_Angeles',
  },
  '95641': {
    zip: '95641',
    city: 'Isleton',
    state: 'CA',
    latitude: '38.145589',
    longitude: '-121.598217',
    timeZoneId: 'America/Los_Angeles',
  },
  '95642': {
    zip: '95642',
    city: 'Jackson',
    state: 'CA',
    latitude: '38.36072',
    longitude: '-120.742856',
    timeZoneId: 'America/Los_Angeles',
  },
  '95644': {
    zip: '95644',
    city: 'Kit Carson',
    state: 'CA',
    latitude: '38.457312',
    longitude: '-120.530419',
    timeZoneId: 'America/Los_Angeles',
  },
  '95645': {
    zip: '95645',
    city: 'Knights Landing',
    state: 'CA',
    latitude: '38.859828',
    longitude: '-121.732992',
    timeZoneId: 'America/Los_Angeles',
  },
  '95646': {
    zip: '95646',
    city: 'Kirkwood',
    state: 'CA',
    latitude: '38.68986',
    longitude: '-120.080103',
    timeZoneId: 'America/Los_Angeles',
  },
  '95648': {
    zip: '95648',
    city: 'Lincoln',
    state: 'CA',
    latitude: '38.913763',
    longitude: '-121.293916',
    timeZoneId: 'America/Los_Angeles',
  },
  '95650': {
    zip: '95650',
    city: 'Loomis',
    state: 'CA',
    latitude: '38.80886',
    longitude: '-121.167325',
    timeZoneId: 'America/Los_Angeles',
  },
  '95651': {
    zip: '95651',
    city: 'Lotus',
    state: 'CA',
    latitude: '38.83856',
    longitude: '-120.929258',
    timeZoneId: 'America/Los_Angeles',
  },
  '95652': {
    zip: '95652',
    city: 'McClellan',
    state: 'CA',
    latitude: '38.68542',
    longitude: '-121.3818',
    timeZoneId: 'America/Los_Angeles',
  },
  '95653': {
    zip: '95653',
    city: 'Madison',
    state: 'CA',
    latitude: '38.680956',
    longitude: '-121.971051',
    timeZoneId: 'America/Los_Angeles',
  },
  '95654': {
    zip: '95654',
    city: 'Martell',
    state: 'CA',
    latitude: '38.366807',
    longitude: '-120.794619',
    timeZoneId: 'America/Los_Angeles',
  },
  '95655': {
    zip: '95655',
    city: 'Mather',
    state: 'CA',
    latitude: '38.550396',
    longitude: '-121.280814',
    timeZoneId: 'America/Los_Angeles',
  },
  '95656': {
    zip: '95656',
    city: 'Mount Aukum',
    state: 'CA',
    latitude: '38.56338',
    longitude: '-120.714932',
    timeZoneId: 'America/Los_Angeles',
  },
  '95658': {
    zip: '95658',
    city: 'Newcastle',
    state: 'CA',
    latitude: '38.874748',
    longitude: '-121.14634',
    timeZoneId: 'America/Los_Angeles',
  },
  '95659': {
    zip: '95659',
    city: 'Nicolaus',
    state: 'CA',
    latitude: '38.870336',
    longitude: '-121.567175',
    timeZoneId: 'America/Los_Angeles',
  },
  '95660': {
    zip: '95660',
    city: 'North Highlands',
    state: 'CA',
    latitude: '38.687272',
    longitude: '-121.376063',
    timeZoneId: 'America/Los_Angeles',
  },
  '95661': {
    zip: '95661',
    city: 'Roseville',
    state: 'CA',
    latitude: '38.732237',
    longitude: '-121.225981',
    timeZoneId: 'America/Los_Angeles',
  },
  '95662': {
    zip: '95662',
    city: 'Orangevale',
    state: 'CA',
    latitude: '38.68451',
    longitude: '-121.225634',
    timeZoneId: 'America/Los_Angeles',
  },
  '95663': {
    zip: '95663',
    city: 'Penryn',
    state: 'CA',
    latitude: '38.860301',
    longitude: '-121.182507',
    timeZoneId: 'America/Los_Angeles',
  },
  '95664': {
    zip: '95664',
    city: 'Pilot Hill',
    state: 'CA',
    latitude: '38.820434',
    longitude: '-121.023542',
    timeZoneId: 'America/Los_Angeles',
  },
  '95665': {
    zip: '95665',
    city: 'Pine Grove',
    state: 'CA',
    latitude: '38.405489',
    longitude: '-120.657894',
    timeZoneId: 'America/Los_Angeles',
  },
  '95666': {
    zip: '95666',
    city: 'Pioneer',
    state: 'CA',
    latitude: '38.45814',
    longitude: '-120.52002',
    timeZoneId: 'America/Los_Angeles',
  },
  '95667': {
    zip: '95667',
    city: 'Placerville',
    state: 'CA',
    latitude: '38.726152',
    longitude: '-120.803551',
    timeZoneId: 'America/Los_Angeles',
  },
  '95668': {
    zip: '95668',
    city: 'Pleasant Grove',
    state: 'CA',
    latitude: '38.821488',
    longitude: '-121.507596',
    timeZoneId: 'America/Los_Angeles',
  },
  '95669': {
    zip: '95669',
    city: 'Plymouth',
    state: 'CA',
    latitude: '38.492195',
    longitude: '-120.86161',
    timeZoneId: 'America/Los_Angeles',
  },
  '95670': {
    zip: '95670',
    city: 'Rancho Cordova',
    state: 'CA',
    latitude: '38.60352',
    longitude: '-121.24806',
    timeZoneId: 'America/Los_Angeles',
  },
  '95671': {
    zip: '95671',
    city: 'Represa',
    state: 'CA',
    latitude: '38.676709',
    longitude: '-121.147373',
    timeZoneId: 'America/Los_Angeles',
  },
  '95672': {
    zip: '95672',
    city: 'Rescue',
    state: 'CA',
    latitude: '38.719155',
    longitude: '-120.988178',
    timeZoneId: 'America/Los_Angeles',
  },
  '95673': {
    zip: '95673',
    city: 'Rio Linda',
    state: 'CA',
    latitude: '38.690505',
    longitude: '-121.454859',
    timeZoneId: 'America/Los_Angeles',
  },
  '95674': {
    zip: '95674',
    city: 'Rio Oso',
    state: 'CA',
    latitude: '38.961496',
    longitude: '-121.495208',
    timeZoneId: 'America/Los_Angeles',
  },
  '95675': {
    zip: '95675',
    city: 'River Pines',
    state: 'CA',
    latitude: '38.545897',
    longitude: '-120.74125',
    timeZoneId: 'America/Los_Angeles',
  },
  '95676': {
    zip: '95676',
    city: 'Robbins',
    state: 'CA',
    latitude: '38.882324',
    longitude: '-121.71617',
    timeZoneId: 'America/Los_Angeles',
  },
  '95677': {
    zip: '95677',
    city: 'Rocklin',
    state: 'CA',
    latitude: '38.790376',
    longitude: '-121.240241',
    timeZoneId: 'America/Los_Angeles',
  },
  '95678': {
    zip: '95678',
    city: 'Roseville',
    state: 'CA',
    latitude: '38.765901',
    longitude: '-121.289424',
    timeZoneId: 'America/Los_Angeles',
  },
  '95679': {
    zip: '95679',
    city: 'Rumsey',
    state: 'CA',
    latitude: '38.899229',
    longitude: '-122.29085',
    timeZoneId: 'America/Los_Angeles',
  },
  '95680': {
    zip: '95680',
    city: 'Ryde',
    state: 'CA',
    latitude: '38.238604',
    longitude: '-121.560075',
    timeZoneId: 'America/Los_Angeles',
  },
  '95681': {
    zip: '95681',
    city: 'Sheridan',
    state: 'CA',
    latitude: '39.010786',
    longitude: '-121.357453',
    timeZoneId: 'America/Los_Angeles',
  },
  '95682': {
    zip: '95682',
    city: 'Shingle Springs',
    state: 'CA',
    latitude: '38.656055',
    longitude: '-120.961681',
    timeZoneId: 'America/Los_Angeles',
  },
  '95683': {
    zip: '95683',
    city: 'Sloughhouse',
    state: 'CA',
    latitude: '38.49618',
    longitude: '-121.12728',
    timeZoneId: 'America/Los_Angeles',
  },
  '95684': {
    zip: '95684',
    city: 'Somerset',
    state: 'CA',
    latitude: '38.60286',
    longitude: '-120.63',
    timeZoneId: 'America/Los_Angeles',
  },
  '95685': {
    zip: '95685',
    city: 'Sutter Creek',
    state: 'CA',
    latitude: '38.42466',
    longitude: '-120.75336',
    timeZoneId: 'America/Los_Angeles',
  },
  '95686': {
    zip: '95686',
    city: 'Thornton',
    state: 'CA',
    latitude: '38.225441',
    longitude: '-121.438262',
    timeZoneId: 'America/Los_Angeles',
  },
  '95687': {
    zip: '95687',
    city: 'Vacaville',
    state: 'CA',
    latitude: '38.346229',
    longitude: '-121.954425',
    timeZoneId: 'America/Los_Angeles',
  },
  '95688': {
    zip: '95688',
    city: 'Vacaville',
    state: 'CA',
    latitude: '38.39676',
    longitude: '-122.00418',
    timeZoneId: 'America/Los_Angeles',
  },
  '95689': {
    zip: '95689',
    city: 'Volcano',
    state: 'CA',
    latitude: '38.47243',
    longitude: '-120.602994',
    timeZoneId: 'America/Los_Angeles',
  },
  '95690': {
    zip: '95690',
    city: 'Walnut Grove',
    state: 'CA',
    latitude: '38.24208',
    longitude: '-121.566',
    timeZoneId: 'America/Los_Angeles',
  },
  '95691': {
    zip: '95691',
    city: 'West Sacramento',
    state: 'CA',
    latitude: '38.5621',
    longitude: '-121.541198',
    timeZoneId: 'America/Los_Angeles',
  },
  '95692': {
    zip: '95692',
    city: 'Wheatland',
    state: 'CA',
    latitude: '39.025057',
    longitude: '-121.42271',
    timeZoneId: 'America/Los_Angeles',
  },
  '95693': {
    zip: '95693',
    city: 'Wilton',
    state: 'CA',
    latitude: '38.390614',
    longitude: '-121.225678',
    timeZoneId: 'America/Los_Angeles',
  },
  '95694': {
    zip: '95694',
    city: 'Winters',
    state: 'CA',
    latitude: '38.525534',
    longitude: '-121.97365',
    timeZoneId: 'America/Los_Angeles',
  },
  '95695': {
    zip: '95695',
    city: 'Woodland',
    state: 'CA',
    latitude: '38.675457',
    longitude: '-121.776397',
    timeZoneId: 'America/Los_Angeles',
  },
  '95696': {
    zip: '95696',
    city: 'Vacaville',
    state: 'CA',
    latitude: '38.317497',
    longitude: '-121.920793',
    timeZoneId: 'America/Los_Angeles',
  },
  '95697': {
    zip: '95697',
    city: 'Yolo',
    state: 'CA',
    latitude: '38.735793',
    longitude: '-121.805997',
    timeZoneId: 'America/Los_Angeles',
  },
  '95698': {
    zip: '95698',
    city: 'Zamora',
    state: 'CA',
    latitude: '38.789567',
    longitude: '-121.922857',
    timeZoneId: 'America/Los_Angeles',
  },
  '95699': {
    zip: '95699',
    city: 'Drytown',
    state: 'CA',
    latitude: '38.444551',
    longitude: '-120.847909',
    timeZoneId: 'America/Los_Angeles',
  },
  '95701': {
    zip: '95701',
    city: 'Alta',
    state: 'CA',
    latitude: '39.213231',
    longitude: '-120.801982',
    timeZoneId: 'America/Los_Angeles',
  },
  '95703': {
    zip: '95703',
    city: 'Applegate',
    state: 'CA',
    latitude: '38.981227',
    longitude: '-120.987593',
    timeZoneId: 'America/Los_Angeles',
  },
  '95709': {
    zip: '95709',
    city: 'Camino',
    state: 'CA',
    latitude: '38.75136',
    longitude: '-120.66504',
    timeZoneId: 'America/Los_Angeles',
  },
  '95712': {
    zip: '95712',
    city: 'Chicago Park',
    state: 'CA',
    latitude: '39.162319',
    longitude: '-120.956753',
    timeZoneId: 'America/Los_Angeles',
  },
  '95713': {
    zip: '95713',
    city: 'Colfax',
    state: 'CA',
    latitude: '39.08844',
    longitude: '-120.95652',
    timeZoneId: 'America/Los_Angeles',
  },
  '95714': {
    zip: '95714',
    city: 'Dutch Flat',
    state: 'CA',
    latitude: '39.203253',
    longitude: '-120.824838',
    timeZoneId: 'America/Los_Angeles',
  },
  '95715': {
    zip: '95715',
    city: 'Emigrant Gap',
    state: 'CA',
    latitude: '39.27048',
    longitude: '-120.6162',
    timeZoneId: 'America/Los_Angeles',
  },
  '95717': {
    zip: '95717',
    city: 'Gold Run',
    state: 'CA',
    latitude: '39.164539',
    longitude: '-120.859208',
    timeZoneId: 'America/Los_Angeles',
  },
  '95720': {
    zip: '95720',
    city: 'Kyburz',
    state: 'CA',
    latitude: '38.757517',
    longitude: '-120.199076',
    timeZoneId: 'America/Los_Angeles',
  },
  '95721': {
    zip: '95721',
    city: 'Echo Lake',
    state: 'CA',
    latitude: '38.914913',
    longitude: '-120.009699',
    timeZoneId: 'America/Los_Angeles',
  },
  '95722': {
    zip: '95722',
    city: 'Meadow Vista',
    state: 'CA',
    latitude: '39.004269',
    longitude: '-121.028689',
    timeZoneId: 'America/Los_Angeles',
  },
  '95724': {
    zip: '95724',
    city: 'Norden',
    state: 'CA',
    latitude: '39.31038',
    longitude: '-120.43722',
    timeZoneId: 'America/Los_Angeles',
  },
  '95726': {
    zip: '95726',
    city: 'Pollock Pines',
    state: 'CA',
    latitude: '38.75622',
    longitude: '-120.5466',
    timeZoneId: 'America/Los_Angeles',
  },
  '95728': {
    zip: '95728',
    city: 'Soda Springs',
    state: 'CA',
    latitude: '39.320782',
    longitude: '-120.408004',
    timeZoneId: 'America/Los_Angeles',
  },
  '95735': {
    zip: '95735',
    city: 'Twin Bridges',
    state: 'CA',
    latitude: '38.810318',
    longitude: '-120.126317',
    timeZoneId: 'America/Los_Angeles',
  },
  '95736': {
    zip: '95736',
    city: 'Weimar',
    state: 'CA',
    latitude: '39.036076',
    longitude: '-120.957294',
    timeZoneId: 'America/Los_Angeles',
  },
  '95741': {
    zip: '95741',
    city: 'Rancho Cordova',
    state: 'CA',
    latitude: '38.588819',
    longitude: '-121.300677',
    timeZoneId: 'America/Los_Angeles',
  },
  '95742': {
    zip: '95742',
    city: 'Rancho Cordova',
    state: 'CA',
    latitude: '38.587081',
    longitude: '-121.204382',
    timeZoneId: 'America/Los_Angeles',
  },
  '95746': {
    zip: '95746',
    city: 'Granite Bay',
    state: 'CA',
    latitude: '38.74419',
    longitude: '-121.191175',
    timeZoneId: 'America/Los_Angeles',
  },
  '95747': {
    zip: '95747',
    city: 'Roseville',
    state: 'CA',
    latitude: '38.763756',
    longitude: '-121.391419',
    timeZoneId: 'America/Los_Angeles',
  },
  '95757': {
    zip: '95757',
    city: 'Elk Grove',
    state: 'CA',
    latitude: '38.408142',
    longitude: '-121.4294',
    timeZoneId: 'America/Los_Angeles',
  },
  '95758': {
    zip: '95758',
    city: 'Elk Grove',
    state: 'CA',
    latitude: '38.405311',
    longitude: '-121.429597',
    timeZoneId: 'America/Los_Angeles',
  },
  '95759': {
    zip: '95759',
    city: 'Elk Grove',
    state: 'CA',
    latitude: '38.40435',
    longitude: '-121.375312',
    timeZoneId: 'America/Los_Angeles',
  },
  '95762': {
    zip: '95762',
    city: 'El Dorado Hills',
    state: 'CA',
    latitude: '38.715279',
    longitude: '-121.078888',
    timeZoneId: 'America/Los_Angeles',
  },
  '95763': {
    zip: '95763',
    city: 'Folsom',
    state: 'CA',
    latitude: '38.678182',
    longitude: '-121.174368',
    timeZoneId: 'America/Los_Angeles',
  },
  '95765': {
    zip: '95765',
    city: 'Rocklin',
    state: 'CA',
    latitude: '38.822133',
    longitude: '-121.271644',
    timeZoneId: 'America/Los_Angeles',
  },
  '95776': {
    zip: '95776',
    city: 'Woodland',
    state: 'CA',
    latitude: '38.677422',
    longitude: '-121.680298',
    timeZoneId: 'America/Los_Angeles',
  },
  '95798': {
    zip: '95798',
    city: 'West Sacramento',
    state: 'CA',
    latitude: '38.574918',
    longitude: '-121.561857',
    timeZoneId: 'America/Los_Angeles',
  },
  '95799': {
    zip: '95799',
    city: 'West Sacramento',
    state: 'CA',
    latitude: '38.540783',
    longitude: '-121.557001',
    timeZoneId: 'America/Los_Angeles',
  },
  '95811': {
    zip: '95811',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.575345',
    longitude: '-121.507277',
    timeZoneId: 'America/Los_Angeles',
  },
  '95812': {
    zip: '95812',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.581929',
    longitude: '-121.496349',
    timeZoneId: 'America/Los_Angeles',
  },
  '95813': {
    zip: '95813',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.507995',
    longitude: '-121.416783',
    timeZoneId: 'America/Los_Angeles',
  },
  '95814': {
    zip: '95814',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.582714',
    longitude: '-121.489396',
    timeZoneId: 'America/Los_Angeles',
  },
  '95815': {
    zip: '95815',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.612581',
    longitude: '-121.445749',
    timeZoneId: 'America/Los_Angeles',
  },
  '95816': {
    zip: '95816',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.574536',
    longitude: '-121.466593',
    timeZoneId: 'America/Los_Angeles',
  },
  '95817': {
    zip: '95817',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.551191',
    longitude: '-121.457782',
    timeZoneId: 'America/Los_Angeles',
  },
  '95818': {
    zip: '95818',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.560217',
    longitude: '-121.494112',
    timeZoneId: 'America/Los_Angeles',
  },
  '95819': {
    zip: '95819',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.569682',
    longitude: '-121.438018',
    timeZoneId: 'America/Los_Angeles',
  },
  '95820': {
    zip: '95820',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.532877',
    longitude: '-121.449044',
    timeZoneId: 'America/Los_Angeles',
  },
  '95821': {
    zip: '95821',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.621789',
    longitude: '-121.381978',
    timeZoneId: 'America/Los_Angeles',
  },
  '95822': {
    zip: '95822',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.511675',
    longitude: '-121.493259',
    timeZoneId: 'America/Los_Angeles',
  },
  '95823': {
    zip: '95823',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.481025',
    longitude: '-121.444142',
    timeZoneId: 'America/Los_Angeles',
  },
  '95824': {
    zip: '95824',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.517663',
    longitude: '-121.439746',
    timeZoneId: 'America/Los_Angeles',
  },
  '95825': {
    zip: '95825',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.592979',
    longitude: '-121.403108',
    timeZoneId: 'America/Los_Angeles',
  },
  '95826': {
    zip: '95826',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.55113',
    longitude: '-121.368699',
    timeZoneId: 'America/Los_Angeles',
  },
  '95827': {
    zip: '95827',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.552817',
    longitude: '-121.333407',
    timeZoneId: 'America/Los_Angeles',
  },
  '95828': {
    zip: '95828',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.483025',
    longitude: '-121.397358',
    timeZoneId: 'America/Los_Angeles',
  },
  '95829': {
    zip: '95829',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.483542',
    longitude: '-121.351992',
    timeZoneId: 'America/Los_Angeles',
  },
  '95830': {
    zip: '95830',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.492676',
    longitude: '-121.272115',
    timeZoneId: 'America/Los_Angeles',
  },
  '95831': {
    zip: '95831',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.498299',
    longitude: '-121.534017',
    timeZoneId: 'America/Los_Angeles',
  },
  '95832': {
    zip: '95832',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.472573',
    longitude: '-121.484924',
    timeZoneId: 'America/Los_Angeles',
  },
  '95833': {
    zip: '95833',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.6172',
    longitude: '-121.497424',
    timeZoneId: 'America/Los_Angeles',
  },
  '95834': {
    zip: '95834',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.642678',
    longitude: '-121.520969',
    timeZoneId: 'America/Los_Angeles',
  },
  '95835': {
    zip: '95835',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.672191',
    longitude: '-121.527492',
    timeZoneId: 'America/Los_Angeles',
  },
  '95836': {
    zip: '95836',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.704056',
    longitude: '-121.535546',
    timeZoneId: 'America/Los_Angeles',
  },
  '95837': {
    zip: '95837',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.697994',
    longitude: '-121.588047',
    timeZoneId: 'America/Los_Angeles',
  },
  '95838': {
    zip: '95838',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.645117',
    longitude: '-121.443728',
    timeZoneId: 'America/Los_Angeles',
  },
  '95840': {
    zip: '95840',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.583712',
    longitude: '-121.490234',
    timeZoneId: 'America/Los_Angeles',
  },
  '95841': {
    zip: '95841',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.661566',
    longitude: '-121.343467',
    timeZoneId: 'America/Los_Angeles',
  },
  '95842': {
    zip: '95842',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.692841',
    longitude: '-121.345044',
    timeZoneId: 'America/Los_Angeles',
  },
  '95843': {
    zip: '95843',
    city: 'Antelope',
    state: 'CA',
    latitude: '38.686118',
    longitude: '-121.380837',
    timeZoneId: 'America/Los_Angeles',
  },
  '95851': {
    zip: '95851',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.608099',
    longitude: '-121.441544',
    timeZoneId: 'America/Los_Angeles',
  },
  '95852': {
    zip: '95852',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.605587',
    longitude: '-121.441427',
    timeZoneId: 'America/Los_Angeles',
  },
  '95853': {
    zip: '95853',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.607305',
    longitude: '-121.443172',
    timeZoneId: 'America/Los_Angeles',
  },
  '95860': {
    zip: '95860',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.608329',
    longitude: '-121.381689',
    timeZoneId: 'America/Los_Angeles',
  },
  '95864': {
    zip: '95864',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.585978',
    longitude: '-121.376409',
    timeZoneId: 'America/Los_Angeles',
  },
  '95865': {
    zip: '95865',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.588685',
    longitude: '-121.407253',
    timeZoneId: 'America/Los_Angeles',
  },
  '95866': {
    zip: '95866',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.589449',
    longitude: '-121.407836',
    timeZoneId: 'America/Los_Angeles',
  },
  '95867': {
    zip: '95867',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.584856',
    longitude: '-121.493723',
    timeZoneId: 'America/Los_Angeles',
  },
  '95894': {
    zip: '95894',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.556683',
    longitude: '-121.498069',
    timeZoneId: 'America/Los_Angeles',
  },
  '95899': {
    zip: '95899',
    city: 'Sacramento',
    state: 'CA',
    latitude: '38.580708',
    longitude: '-121.526563',
    timeZoneId: 'America/Los_Angeles',
  },
  '95901': {
    zip: '95901',
    city: 'Marysville',
    state: 'CA',
    latitude: '39.2154',
    longitude: '-121.46334',
    timeZoneId: 'America/Los_Angeles',
  },
  '95903': {
    zip: '95903',
    city: 'Beale AFB',
    state: 'CA',
    latitude: '39.11058',
    longitude: '-121.35792',
    timeZoneId: 'America/Los_Angeles',
  },
  '95910': {
    zip: '95910',
    city: 'Alleghany',
    state: 'CA',
    latitude: '39.53622',
    longitude: '-120.66312',
    timeZoneId: 'America/Los_Angeles',
  },
  '95912': {
    zip: '95912',
    city: 'Arbuckle',
    state: 'CA',
    latitude: '39.032646',
    longitude: '-122.014634',
    timeZoneId: 'America/Los_Angeles',
  },
  '95913': {
    zip: '95913',
    city: 'Artois',
    state: 'CA',
    latitude: '39.729068',
    longitude: '-122.144526',
    timeZoneId: 'America/Los_Angeles',
  },
  '95914': {
    zip: '95914',
    city: 'Bangor',
    state: 'CA',
    latitude: '39.407542',
    longitude: '-121.390436',
    timeZoneId: 'America/Los_Angeles',
  },
  '95915': {
    zip: '95915',
    city: 'Belden',
    state: 'CA',
    latitude: '40.006111',
    longitude: '-121.248056',
    timeZoneId: 'America/Los_Angeles',
  },
  '95916': {
    zip: '95916',
    city: 'Berry Creek',
    state: 'CA',
    latitude: '39.638726',
    longitude: '-121.386666',
    timeZoneId: 'America/Los_Angeles',
  },
  '95917': {
    zip: '95917',
    city: 'Biggs',
    state: 'CA',
    latitude: '39.415882',
    longitude: '-121.692678',
    timeZoneId: 'America/Los_Angeles',
  },
  '95918': {
    zip: '95918',
    city: 'Browns Valley',
    state: 'CA',
    latitude: '39.283964',
    longitude: '-121.361589',
    timeZoneId: 'America/Los_Angeles',
  },
  '95919': {
    zip: '95919',
    city: 'Brownsville',
    state: 'CA',
    latitude: '39.446774',
    longitude: '-121.268502',
    timeZoneId: 'America/Los_Angeles',
  },
  '95920': {
    zip: '95920',
    city: 'Butte City',
    state: 'CA',
    latitude: '39.46347',
    longitude: '-121.943844',
    timeZoneId: 'America/Los_Angeles',
  },
  '95922': {
    zip: '95922',
    city: 'Camptonville',
    state: 'CA',
    latitude: '39.440166',
    longitude: '-121.07444',
    timeZoneId: 'America/Los_Angeles',
  },
  '95923': {
    zip: '95923',
    city: 'Canyon Dam',
    state: 'CA',
    latitude: '40.20852',
    longitude: '-121.15548',
    timeZoneId: 'America/Los_Angeles',
  },
  '95924': {
    zip: '95924',
    city: 'Cedar Ridge',
    state: 'CA',
    latitude: '39.198075',
    longitude: '-121.018961',
    timeZoneId: 'America/Los_Angeles',
  },
  '95925': {
    zip: '95925',
    city: 'Challenge',
    state: 'CA',
    latitude: '39.467748',
    longitude: '-121.207569',
    timeZoneId: 'America/Los_Angeles',
  },
  '95926': {
    zip: '95926',
    city: 'Chico',
    state: 'CA',
    latitude: '39.79146',
    longitude: '-121.84764',
    timeZoneId: 'America/Los_Angeles',
  },
  '95927': {
    zip: '95927',
    city: 'Chico',
    state: 'CA',
    latitude: '39.751744',
    longitude: '-121.812533',
    timeZoneId: 'America/Los_Angeles',
  },
  '95928': {
    zip: '95928',
    city: 'Chico',
    state: 'CA',
    latitude: '39.724307',
    longitude: '-121.813534',
    timeZoneId: 'America/Los_Angeles',
  },
  '95929': {
    zip: '95929',
    city: 'Chico',
    state: 'CA',
    latitude: '39.753601',
    longitude: '-121.807403',
    timeZoneId: 'America/Los_Angeles',
  },
  '95930': {
    zip: '95930',
    city: 'Clipper Mills',
    state: 'CA',
    latitude: '39.524451',
    longitude: '-121.230273',
    timeZoneId: 'America/Los_Angeles',
  },
  '95932': {
    zip: '95932',
    city: 'Colusa',
    state: 'CA',
    latitude: '39.211344',
    longitude: '-122.010476',
    timeZoneId: 'America/Los_Angeles',
  },
  '95934': {
    zip: '95934',
    city: 'Crescent Mills',
    state: 'CA',
    latitude: '40.088345',
    longitude: '-120.891835',
    timeZoneId: 'America/Los_Angeles',
  },
  '95935': {
    zip: '95935',
    city: 'Dobbins',
    state: 'CA',
    latitude: '39.375661',
    longitude: '-121.216067',
    timeZoneId: 'America/Los_Angeles',
  },
  '95936': {
    zip: '95936',
    city: 'Downieville',
    state: 'CA',
    latitude: '39.561914',
    longitude: '-120.82502',
    timeZoneId: 'America/Los_Angeles',
  },
  '95937': {
    zip: '95937',
    city: 'Dunnigan',
    state: 'CA',
    latitude: '38.888464',
    longitude: '-121.990452',
    timeZoneId: 'America/Los_Angeles',
  },
  '95938': {
    zip: '95938',
    city: 'Durham',
    state: 'CA',
    latitude: '39.639246',
    longitude: '-121.782939',
    timeZoneId: 'America/Los_Angeles',
  },
  '95939': {
    zip: '95939',
    city: 'Elk Creek',
    state: 'CA',
    latitude: '39.600336',
    longitude: '-122.565896',
    timeZoneId: 'America/Los_Angeles',
  },
  '95940': {
    zip: '95940',
    city: 'Feather Falls',
    state: 'CA',
    latitude: '39.51162',
    longitude: '-121.55652',
    timeZoneId: 'America/Los_Angeles',
  },
  '95941': {
    zip: '95941',
    city: 'Forbestown',
    state: 'CA',
    latitude: '39.486274',
    longitude: '-121.2836',
    timeZoneId: 'America/Los_Angeles',
  },
  '95942': {
    zip: '95942',
    city: 'Forest Ranch',
    state: 'CA',
    latitude: '40.089969',
    longitude: '-121.531089',
    timeZoneId: 'America/Los_Angeles',
  },
  '95943': {
    zip: '95943',
    city: 'Glenn',
    state: 'CA',
    latitude: '39.5937',
    longitude: '-122.05278',
    timeZoneId: 'America/Los_Angeles',
  },
  '95944': {
    zip: '95944',
    city: 'Goodyears Bar',
    state: 'CA',
    latitude: '39.586529',
    longitude: '-120.818557',
    timeZoneId: 'America/Los_Angeles',
  },
  '95945': {
    zip: '95945',
    city: 'Grass Valley',
    state: 'CA',
    latitude: '39.202715',
    longitude: '-121.029155',
    timeZoneId: 'America/Los_Angeles',
  },
  '95946': {
    zip: '95946',
    city: 'Penn Valley',
    state: 'CA',
    latitude: '39.216172',
    longitude: '-121.194087',
    timeZoneId: 'America/Los_Angeles',
  },
  '95947': {
    zip: '95947',
    city: 'Greenville',
    state: 'CA',
    latitude: '40.15434',
    longitude: '-120.924326',
    timeZoneId: 'America/Los_Angeles',
  },
  '95948': {
    zip: '95948',
    city: 'Gridley',
    state: 'CA',
    latitude: '39.358225',
    longitude: '-121.696103',
    timeZoneId: 'America/Los_Angeles',
  },
  '95949': {
    zip: '95949',
    city: 'Grass Valley',
    state: 'CA',
    latitude: '39.121108',
    longitude: '-121.081015',
    timeZoneId: 'America/Los_Angeles',
  },
  '95950': {
    zip: '95950',
    city: 'Grimes',
    state: 'CA',
    latitude: '39.052777',
    longitude: '-121.884418',
    timeZoneId: 'America/Los_Angeles',
  },
  '95951': {
    zip: '95951',
    city: 'Hamilton City',
    state: 'CA',
    latitude: '39.74365',
    longitude: '-122.01096',
    timeZoneId: 'America/Los_Angeles',
  },
  '95953': {
    zip: '95953',
    city: 'Live Oak',
    state: 'CA',
    latitude: '39.259446',
    longitude: '-121.695206',
    timeZoneId: 'America/Los_Angeles',
  },
  '95954': {
    zip: '95954',
    city: 'Magalia',
    state: 'CA',
    latitude: '39.835983',
    longitude: '-121.595496',
    timeZoneId: 'America/Los_Angeles',
  },
  '95955': {
    zip: '95955',
    city: 'Maxwell',
    state: 'CA',
    latitude: '39.276388',
    longitude: '-122.132647',
    timeZoneId: 'America/Los_Angeles',
  },
  '95956': {
    zip: '95956',
    city: 'Meadow Valley',
    state: 'CA',
    latitude: '39.938546',
    longitude: '-121.065083',
    timeZoneId: 'America/Los_Angeles',
  },
  '95957': {
    zip: '95957',
    city: 'Meridian',
    state: 'CA',
    latitude: '39.107422',
    longitude: '-121.863955',
    timeZoneId: 'America/Los_Angeles',
  },
  '95958': {
    zip: '95958',
    city: 'Nelson',
    state: 'CA',
    latitude: '39.555497',
    longitude: '-121.77218',
    timeZoneId: 'America/Los_Angeles',
  },
  '95959': {
    zip: '95959',
    city: 'Nevada City',
    state: 'CA',
    latitude: '39.27344',
    longitude: '-121.022061',
    timeZoneId: 'America/Los_Angeles',
  },
  '95960': {
    zip: '95960',
    city: 'North San Juan',
    state: 'CA',
    latitude: '39.339635',
    longitude: '-121.132146',
    timeZoneId: 'America/Los_Angeles',
  },
  '95961': {
    zip: '95961',
    city: 'Olivehurst',
    state: 'CA',
    latitude: '39.088781',
    longitude: '-121.550818',
    timeZoneId: 'America/Los_Angeles',
  },
  '95962': {
    zip: '95962',
    city: 'Oregon House',
    state: 'CA',
    latitude: '39.355889',
    longitude: '-121.262044',
    timeZoneId: 'America/Los_Angeles',
  },
  '95963': {
    zip: '95963',
    city: 'Orland',
    state: 'CA',
    latitude: '39.745762',
    longitude: '-122.190763',
    timeZoneId: 'America/Los_Angeles',
  },
  '95965': {
    zip: '95965',
    city: 'Oroville',
    state: 'CA',
    latitude: '39.562606',
    longitude: '-121.557974',
    timeZoneId: 'America/Los_Angeles',
  },
  '95966': {
    zip: '95966',
    city: 'Oroville',
    state: 'CA',
    latitude: '39.484101',
    longitude: '-121.486755',
    timeZoneId: 'America/Los_Angeles',
  },
  '95967': {
    zip: '95967',
    city: 'Paradise',
    state: 'CA',
    latitude: '39.742569',
    longitude: '-121.604533',
    timeZoneId: 'America/Los_Angeles',
  },
  '95968': {
    zip: '95968',
    city: 'Palermo',
    state: 'CA',
    latitude: '39.439932',
    longitude: '-121.54706',
    timeZoneId: 'America/Los_Angeles',
  },
  '95969': {
    zip: '95969',
    city: 'Paradise',
    state: 'CA',
    latitude: '39.760113',
    longitude: '-121.602349',
    timeZoneId: 'America/Los_Angeles',
  },
  '95970': {
    zip: '95970',
    city: 'Princeton',
    state: 'CA',
    latitude: '39.42492',
    longitude: '-122.09628',
    timeZoneId: 'America/Los_Angeles',
  },
  '95971': {
    zip: '95971',
    city: 'Quincy',
    state: 'CA',
    latitude: '39.935033',
    longitude: '-120.905059',
    timeZoneId: 'America/Los_Angeles',
  },
  '95972': {
    zip: '95972',
    city: 'Rackerby',
    state: 'CA',
    latitude: '39.433834',
    longitude: '-121.327998',
    timeZoneId: 'America/Los_Angeles',
  },
  '95973': {
    zip: '95973',
    city: 'Chico',
    state: 'CA',
    latitude: '39.737451',
    longitude: '-121.83046',
    timeZoneId: 'America/Los_Angeles',
  },
  '95974': {
    zip: '95974',
    city: 'Richvale',
    state: 'CA',
    latitude: '39.494588',
    longitude: '-121.749311',
    timeZoneId: 'America/Los_Angeles',
  },
  '95975': {
    zip: '95975',
    city: 'Rough and Ready',
    state: 'CA',
    latitude: '39.22335',
    longitude: '-121.143204',
    timeZoneId: 'America/Los_Angeles',
  },
  '95976': {
    zip: '95976',
    city: 'Chico',
    state: 'CA',
    latitude: '39.753844',
    longitude: '-121.870112',
    timeZoneId: 'America/Los_Angeles',
  },
  '95977': {
    zip: '95977',
    city: 'Smartsville',
    state: 'CA',
    latitude: '39.197157',
    longitude: '-121.253817',
    timeZoneId: 'America/Los_Angeles',
  },
  '95978': {
    zip: '95978',
    city: 'Stirling City',
    state: 'CA',
    latitude: '39.95499',
    longitude: '-121.512036',
    timeZoneId: 'America/Los_Angeles',
  },
  '95979': {
    zip: '95979',
    city: 'Stonyford',
    state: 'CA',
    latitude: '39.30821',
    longitude: '-122.511314',
    timeZoneId: 'America/Los_Angeles',
  },
  '95980': {
    zip: '95980',
    city: 'Storrie',
    state: 'CA',
    latitude: '39.918474',
    longitude: '-121.328711',
    timeZoneId: 'America/Los_Angeles',
  },
  '95981': {
    zip: '95981',
    city: 'Strawberry Valley',
    state: 'CA',
    latitude: '39.564167',
    longitude: '-121.105833',
    timeZoneId: 'America/Los_Angeles',
  },
  '95982': {
    zip: '95982',
    city: 'Sutter',
    state: 'CA',
    latitude: '39.164282',
    longitude: '-121.765471',
    timeZoneId: 'America/Los_Angeles',
  },
  '95983': {
    zip: '95983',
    city: 'Taylorsville',
    state: 'CA',
    latitude: '40.05834',
    longitude: '-120.71052',
    timeZoneId: 'America/Los_Angeles',
  },
  '95984': {
    zip: '95984',
    city: 'Twain',
    state: 'CA',
    latitude: '40.04328',
    longitude: '-121.11258',
    timeZoneId: 'America/Los_Angeles',
  },
  '95986': {
    zip: '95986',
    city: 'Washington',
    state: 'CA',
    latitude: '39.35351',
    longitude: '-120.777281',
    timeZoneId: 'America/Los_Angeles',
  },
  '95987': {
    zip: '95987',
    city: 'Williams',
    state: 'CA',
    latitude: '39.148021',
    longitude: '-122.145602',
    timeZoneId: 'America/Los_Angeles',
  },
  '95988': {
    zip: '95988',
    city: 'Willows',
    state: 'CA',
    latitude: '39.524044',
    longitude: '-122.199369',
    timeZoneId: 'America/Los_Angeles',
  },
  '95991': {
    zip: '95991',
    city: 'Yuba City',
    state: 'CA',
    latitude: '38.995493',
    longitude: '-121.621973',
    timeZoneId: 'America/Los_Angeles',
  },
  '95992': {
    zip: '95992',
    city: 'Yuba City',
    state: 'CA',
    latitude: '39.0236',
    longitude: '-121.607036',
    timeZoneId: 'America/Los_Angeles',
  },
  '95993': {
    zip: '95993',
    city: 'Yuba City',
    state: 'CA',
    latitude: '39.126202',
    longitude: '-121.664894',
    timeZoneId: 'America/Los_Angeles',
  },
  '96001': {
    zip: '96001',
    city: 'Redding',
    state: 'CA',
    latitude: '40.564869',
    longitude: '-122.421215',
    timeZoneId: 'America/Los_Angeles',
  },
  '96002': {
    zip: '96002',
    city: 'Redding',
    state: 'CA',
    latitude: '40.540695',
    longitude: '-122.32451',
    timeZoneId: 'America/Los_Angeles',
  },
  '96003': {
    zip: '96003',
    city: 'Redding',
    state: 'CA',
    latitude: '40.6572',
    longitude: '-122.33682',
    timeZoneId: 'America/Los_Angeles',
  },
  '96006': {
    zip: '96006',
    city: 'Adin',
    state: 'CA',
    latitude: '41.202807',
    longitude: '-120.938418',
    timeZoneId: 'America/Los_Angeles',
  },
  '96007': {
    zip: '96007',
    city: 'Anderson',
    state: 'CA',
    latitude: '40.46898',
    longitude: '-122.2995',
    timeZoneId: 'America/Los_Angeles',
  },
  '96008': {
    zip: '96008',
    city: 'Bella Vista',
    state: 'CA',
    latitude: '40.668572',
    longitude: '-122.196037',
    timeZoneId: 'America/Los_Angeles',
  },
  '96009': {
    zip: '96009',
    city: 'Bieber',
    state: 'CA',
    latitude: '41.121327',
    longitude: '-121.137406',
    timeZoneId: 'America/Los_Angeles',
  },
  '96010': {
    zip: '96010',
    city: 'Big Bar',
    state: 'CA',
    latitude: '40.9122',
    longitude: '-123.256187',
    timeZoneId: 'America/Los_Angeles',
  },
  '96011': {
    zip: '96011',
    city: 'Big Bend',
    state: 'CA',
    latitude: '40.96967',
    longitude: '-122.000419',
    timeZoneId: 'America/Los_Angeles',
  },
  '96013': {
    zip: '96013',
    city: 'Burney',
    state: 'CA',
    latitude: '40.93428',
    longitude: '-121.6671',
    timeZoneId: 'America/Los_Angeles',
  },
  '96014': {
    zip: '96014',
    city: 'Callahan',
    state: 'CA',
    latitude: '41.334799',
    longitude: '-122.813345',
    timeZoneId: 'America/Los_Angeles',
  },
  '96015': {
    zip: '96015',
    city: 'Canby',
    state: 'CA',
    latitude: '41.561292',
    longitude: '-121.024824',
    timeZoneId: 'America/Los_Angeles',
  },
  '96016': {
    zip: '96016',
    city: 'Cassel',
    state: 'CA',
    latitude: '40.905106',
    longitude: '-121.498735',
    timeZoneId: 'America/Los_Angeles',
  },
  '96017': {
    zip: '96017',
    city: 'Castella',
    state: 'CA',
    latitude: '41.141859',
    longitude: '-122.318402',
    timeZoneId: 'America/Los_Angeles',
  },
  '96019': {
    zip: '96019',
    city: 'Shasta Lake',
    state: 'CA',
    latitude: '40.68156',
    longitude: '-122.37744',
    timeZoneId: 'America/Los_Angeles',
  },
  '96020': {
    zip: '96020',
    city: 'Chester',
    state: 'CA',
    latitude: '40.296069',
    longitude: '-121.226895',
    timeZoneId: 'America/Los_Angeles',
  },
  '96021': {
    zip: '96021',
    city: 'Corning',
    state: 'CA',
    latitude: '39.926419',
    longitude: '-122.190031',
    timeZoneId: 'America/Los_Angeles',
  },
  '96022': {
    zip: '96022',
    city: 'Cottonwood',
    state: 'CA',
    latitude: '40.324554',
    longitude: '-122.350355',
    timeZoneId: 'America/Los_Angeles',
  },
  '96023': {
    zip: '96023',
    city: 'Dorris',
    state: 'CA',
    latitude: '41.970671',
    longitude: '-121.937185',
    timeZoneId: 'America/Los_Angeles',
  },
  '96024': {
    zip: '96024',
    city: 'Douglas City',
    state: 'CA',
    latitude: '40.671801',
    longitude: '-122.900308',
    timeZoneId: 'America/Los_Angeles',
  },
  '96025': {
    zip: '96025',
    city: 'Dunsmuir',
    state: 'CA',
    latitude: '41.211393',
    longitude: '-122.274427',
    timeZoneId: 'America/Los_Angeles',
  },
  '96027': {
    zip: '96027',
    city: 'Etna',
    state: 'CA',
    latitude: '41.4273',
    longitude: '-122.9166',
    timeZoneId: 'America/Los_Angeles',
  },
  '96028': {
    zip: '96028',
    city: 'Fall River Mills',
    state: 'CA',
    latitude: '41.059235',
    longitude: '-121.4973',
    timeZoneId: 'America/Los_Angeles',
  },
  '96029': {
    zip: '96029',
    city: 'Flournoy',
    state: 'CA',
    latitude: '39.913416',
    longitude: '-122.467956',
    timeZoneId: 'America/Los_Angeles',
  },
  '96031': {
    zip: '96031',
    city: 'Forks of Salmon',
    state: 'CA',
    latitude: '41.218476',
    longitude: '-123.245773',
    timeZoneId: 'America/Los_Angeles',
  },
  '96032': {
    zip: '96032',
    city: 'Fort Jones',
    state: 'CA',
    latitude: '41.606816',
    longitude: '-122.882247',
    timeZoneId: 'America/Los_Angeles',
  },
  '96033': {
    zip: '96033',
    city: 'French Gulch',
    state: 'CA',
    latitude: '40.736021',
    longitude: '-122.619811',
    timeZoneId: 'America/Los_Angeles',
  },
  '96034': {
    zip: '96034',
    city: 'Gazelle',
    state: 'CA',
    latitude: '41.461011',
    longitude: '-122.638049',
    timeZoneId: 'America/Los_Angeles',
  },
  '96035': {
    zip: '96035',
    city: 'Gerber',
    state: 'CA',
    latitude: '40.038799',
    longitude: '-122.172069',
    timeZoneId: 'America/Los_Angeles',
  },
  '96037': {
    zip: '96037',
    city: 'Greenview',
    state: 'CA',
    latitude: '41.549707',
    longitude: '-122.91058',
    timeZoneId: 'America/Los_Angeles',
  },
  '96038': {
    zip: '96038',
    city: 'Grenada',
    state: 'CA',
    latitude: '41.620351',
    longitude: '-122.51964',
    timeZoneId: 'America/Los_Angeles',
  },
  '96039': {
    zip: '96039',
    city: 'Happy Camp',
    state: 'CA',
    latitude: '41.724456',
    longitude: '-123.418504',
    timeZoneId: 'America/Los_Angeles',
  },
  '96040': {
    zip: '96040',
    city: 'Hat Creek',
    state: 'CA',
    latitude: '40.814467',
    longitude: '-121.506933',
    timeZoneId: 'America/Los_Angeles',
  },
  '96041': {
    zip: '96041',
    city: 'Hayfork',
    state: 'CA',
    latitude: '40.51188',
    longitude: '-123.2358',
    timeZoneId: 'America/Los_Angeles',
  },
  '96044': {
    zip: '96044',
    city: 'Hornbrook',
    state: 'CA',
    latitude: '41.93982',
    longitude: '-122.53002',
    timeZoneId: 'America/Los_Angeles',
  },
  '96046': {
    zip: '96046',
    city: 'Hyampom',
    state: 'CA',
    latitude: '40.61783',
    longitude: '-123.45121',
    timeZoneId: 'America/Los_Angeles',
  },
  '96047': {
    zip: '96047',
    city: 'Igo',
    state: 'CA',
    latitude: '40.458121',
    longitude: '-122.588612',
    timeZoneId: 'America/Los_Angeles',
  },
  '96048': {
    zip: '96048',
    city: 'Junction City',
    state: 'CA',
    latitude: '40.78078',
    longitude: '-123.050679',
    timeZoneId: 'America/Los_Angeles',
  },
  '96049': {
    zip: '96049',
    city: 'Redding',
    state: 'CA',
    latitude: '40.564486',
    longitude: '-122.296269',
    timeZoneId: 'America/Los_Angeles',
  },
  '96050': {
    zip: '96050',
    city: 'Klamath River',
    state: 'CA',
    latitude: '41.8296',
    longitude: '-122.80314',
    timeZoneId: 'America/Los_Angeles',
  },
  '96051': {
    zip: '96051',
    city: 'Lakehead',
    state: 'CA',
    latitude: '40.877574',
    longitude: '-122.371334',
    timeZoneId: 'America/Los_Angeles',
  },
  '96052': {
    zip: '96052',
    city: 'Lewiston',
    state: 'CA',
    latitude: '40.703668',
    longitude: '-122.803764',
    timeZoneId: 'America/Los_Angeles',
  },
  '96054': {
    zip: '96054',
    city: 'Lookout',
    state: 'CA',
    latitude: '41.118315',
    longitude: '-121.139856',
    timeZoneId: 'America/Los_Angeles',
  },
  '96055': {
    zip: '96055',
    city: 'Los Molinos',
    state: 'CA',
    latitude: '40.055266',
    longitude: '-122.102585',
    timeZoneId: 'America/Los_Angeles',
  },
  '96056': {
    zip: '96056',
    city: 'McArthur',
    state: 'CA',
    latitude: '41.167186',
    longitude: '-121.356668',
    timeZoneId: 'America/Los_Angeles',
  },
  '96057': {
    zip: '96057',
    city: 'McCloud',
    state: 'CA',
    latitude: '41.3718',
    longitude: '-121.88508',
    timeZoneId: 'America/Los_Angeles',
  },
  '96058': {
    zip: '96058',
    city: 'Macdoel',
    state: 'CA',
    latitude: '41.752385',
    longitude: '-121.974524',
    timeZoneId: 'America/Los_Angeles',
  },
  '96059': {
    zip: '96059',
    city: 'Manton',
    state: 'CA',
    latitude: '40.445559',
    longitude: '-121.844707',
    timeZoneId: 'America/Los_Angeles',
  },
  '96061': {
    zip: '96061',
    city: 'Mill Creek',
    state: 'CA',
    latitude: '40.3209',
    longitude: '-121.50042',
    timeZoneId: 'America/Los_Angeles',
  },
  '96062': {
    zip: '96062',
    city: 'Millville',
    state: 'CA',
    latitude: '40.549344',
    longitude: '-122.14024',
    timeZoneId: 'America/Los_Angeles',
  },
  '96063': {
    zip: '96063',
    city: 'Mineral',
    state: 'CA',
    latitude: '40.360478',
    longitude: '-121.564354',
    timeZoneId: 'America/Los_Angeles',
  },
  '96064': {
    zip: '96064',
    city: 'Montague',
    state: 'CA',
    latitude: '41.735873',
    longitude: '-122.439379',
    timeZoneId: 'America/Los_Angeles',
  },
  '96065': {
    zip: '96065',
    city: 'Montgomery Creek',
    state: 'CA',
    latitude: '40.971399',
    longitude: '-122.000991',
    timeZoneId: 'America/Los_Angeles',
  },
  '96067': {
    zip: '96067',
    city: 'Mount Shasta',
    state: 'CA',
    latitude: '41.356502',
    longitude: '-122.348946',
    timeZoneId: 'America/Los_Angeles',
  },
  '96068': {
    zip: '96068',
    city: 'Nubieber',
    state: 'CA',
    latitude: '40.954132',
    longitude: '-121.114759',
    timeZoneId: 'America/Los_Angeles',
  },
  '96069': {
    zip: '96069',
    city: 'Oak Run',
    state: 'CA',
    latitude: '40.698804',
    longitude: '-122.014457',
    timeZoneId: 'America/Los_Angeles',
  },
  '96070': {
    zip: '96070',
    city: 'Obrien',
    state: 'CA',
    latitude: '40.812222',
    longitude: '-122.323056',
    timeZoneId: 'America/Los_Angeles',
  },
  '96071': {
    zip: '96071',
    city: 'Old Station',
    state: 'CA',
    latitude: '40.661393',
    longitude: '-121.45805',
    timeZoneId: 'America/Los_Angeles',
  },
  '96073': {
    zip: '96073',
    city: 'Palo Cedro',
    state: 'CA',
    latitude: '40.570792',
    longitude: '-122.236172',
    timeZoneId: 'America/Los_Angeles',
  },
  '96074': {
    zip: '96074',
    city: 'Paskenta',
    state: 'CA',
    latitude: '39.930965',
    longitude: '-122.532728',
    timeZoneId: 'America/Los_Angeles',
  },
  '96075': {
    zip: '96075',
    city: 'Paynes Creek',
    state: 'CA',
    latitude: '40.337839',
    longitude: '-121.80212',
    timeZoneId: 'America/Los_Angeles',
  },
  '96076': {
    zip: '96076',
    city: 'Platina',
    state: 'CA',
    latitude: '40.3704',
    longitude: '-122.95272',
    timeZoneId: 'America/Los_Angeles',
  },
  '96078': {
    zip: '96078',
    city: 'Proberta',
    state: 'CA',
    latitude: '40.077572',
    longitude: '-122.170185',
    timeZoneId: 'America/Los_Angeles',
  },
  '96079': {
    zip: '96079',
    city: 'Shasta Lake',
    state: 'CA',
    latitude: '40.691415',
    longitude: '-122.344769',
    timeZoneId: 'America/Los_Angeles',
  },
  '96080': {
    zip: '96080',
    city: 'Red Bluff',
    state: 'CA',
    latitude: '40.177299',
    longitude: '-122.263016',
    timeZoneId: 'America/Los_Angeles',
  },
  '96084': {
    zip: '96084',
    city: 'Round Mountain',
    state: 'CA',
    latitude: '40.774706',
    longitude: '-121.988586',
    timeZoneId: 'America/Los_Angeles',
  },
  '96085': {
    zip: '96085',
    city: 'Scott Bar',
    state: 'CA',
    latitude: '41.75913',
    longitude: '-123.015446',
    timeZoneId: 'America/Los_Angeles',
  },
  '96086': {
    zip: '96086',
    city: 'Seiad Valley',
    state: 'CA',
    latitude: '41.84618',
    longitude: '-123.213116',
    timeZoneId: 'America/Los_Angeles',
  },
  '96087': {
    zip: '96087',
    city: 'Shasta',
    state: 'CA',
    latitude: '40.598045',
    longitude: '-122.489673',
    timeZoneId: 'America/Los_Angeles',
  },
  '96088': {
    zip: '96088',
    city: 'Shingletown',
    state: 'CA',
    latitude: '40.53192',
    longitude: '-121.84524',
    timeZoneId: 'America/Los_Angeles',
  },
  '96089': {
    zip: '96089',
    city: 'Shasta Lake',
    state: 'CA',
    latitude: '40.683097',
    longitude: '-122.400465',
    timeZoneId: 'America/Los_Angeles',
  },
  '96090': {
    zip: '96090',
    city: 'Tehama',
    state: 'CA',
    latitude: '40.025341',
    longitude: '-122.124208',
    timeZoneId: 'America/Los_Angeles',
  },
  '96091': {
    zip: '96091',
    city: 'Trinity Center',
    state: 'CA',
    latitude: '41.08739',
    longitude: '-122.716384',
    timeZoneId: 'America/Los_Angeles',
  },
  '96092': {
    zip: '96092',
    city: 'Vina',
    state: 'CA',
    latitude: '39.937714',
    longitude: '-122.011977',
    timeZoneId: 'America/Los_Angeles',
  },
  '96093': {
    zip: '96093',
    city: 'Weaverville',
    state: 'CA',
    latitude: '40.732607',
    longitude: '-122.935131',
    timeZoneId: 'America/Los_Angeles',
  },
  '96094': {
    zip: '96094',
    city: 'Weed',
    state: 'CA',
    latitude: '41.466318',
    longitude: '-122.383688',
    timeZoneId: 'America/Los_Angeles',
  },
  '96095': {
    zip: '96095',
    city: 'Whiskeytown',
    state: 'CA',
    latitude: '40.711597',
    longitude: '-122.601782',
    timeZoneId: 'America/Los_Angeles',
  },
  '96096': {
    zip: '96096',
    city: 'Whitmore',
    state: 'CA',
    latitude: '40.646251',
    longitude: '-121.913118',
    timeZoneId: 'America/Los_Angeles',
  },
  '96097': {
    zip: '96097',
    city: 'Yreka',
    state: 'CA',
    latitude: '41.729575',
    longitude: '-122.6256',
    timeZoneId: 'America/Los_Angeles',
  },
  '96099': {
    zip: '96099',
    city: 'Redding',
    state: 'CA',
    latitude: '40.641465',
    longitude: '-122.506104',
    timeZoneId: 'America/Los_Angeles',
  },
  '96101': {
    zip: '96101',
    city: 'Alturas',
    state: 'CA',
    latitude: '41.512612',
    longitude: '-120.551753',
    timeZoneId: 'America/Los_Angeles',
  },
  '96103': {
    zip: '96103',
    city: 'Blairsden Graeagle',
    state: 'CA',
    latitude: '39.79104',
    longitude: '-120.67242',
    timeZoneId: 'America/Los_Angeles',
  },
  '96104': {
    zip: '96104',
    city: 'Cedarville',
    state: 'CA',
    latitude: '41.519083',
    longitude: '-120.167083',
    timeZoneId: 'America/Los_Angeles',
  },
  '96105': {
    zip: '96105',
    city: 'Chilcoot',
    state: 'CA',
    latitude: '39.814658',
    longitude: '-120.211585',
    timeZoneId: 'America/Los_Angeles',
  },
  '96106': {
    zip: '96106',
    city: 'Clio',
    state: 'CA',
    latitude: '39.730768',
    longitude: '-120.552796',
    timeZoneId: 'America/Los_Angeles',
  },
  '96107': {
    zip: '96107',
    city: 'Coleville',
    state: 'CA',
    latitude: '38.47806',
    longitude: '-119.47704',
    timeZoneId: 'America/Los_Angeles',
  },
  '96108': {
    zip: '96108',
    city: 'Davis Creek',
    state: 'CA',
    latitude: '41.826704',
    longitude: '-120.31077',
    timeZoneId: 'America/Los_Angeles',
  },
  '96109': {
    zip: '96109',
    city: 'Doyle',
    state: 'CA',
    latitude: '40.041563',
    longitude: '-120.116174',
    timeZoneId: 'America/Los_Angeles',
  },
  '96110': {
    zip: '96110',
    city: 'Eagleville',
    state: 'CA',
    latitude: '41.316969',
    longitude: '-120.117312',
    timeZoneId: 'America/Los_Angeles',
  },
  '96111': {
    zip: '96111',
    city: 'Floriston',
    state: 'CA',
    latitude: '39.391046',
    longitude: '-120.023279',
    timeZoneId: 'America/Los_Angeles',
  },
  '96112': {
    zip: '96112',
    city: 'Fort Bidwell',
    state: 'CA',
    latitude: '41.873979',
    longitude: '-120.117673',
    timeZoneId: 'America/Los_Angeles',
  },
  '96113': {
    zip: '96113',
    city: 'Herlong',
    state: 'CA',
    latitude: '40.138912',
    longitude: '-120.156585',
    timeZoneId: 'America/Los_Angeles',
  },
  '96114': {
    zip: '96114',
    city: 'Janesville',
    state: 'CA',
    latitude: '40.283659',
    longitude: '-120.476457',
    timeZoneId: 'America/Los_Angeles',
  },
  '96115': {
    zip: '96115',
    city: 'Lake City',
    state: 'CA',
    latitude: '41.718144',
    longitude: '-120.190248',
    timeZoneId: 'America/Los_Angeles',
  },
  '96116': {
    zip: '96116',
    city: 'Likely',
    state: 'CA',
    latitude: '41.232112',
    longitude: '-120.503123',
    timeZoneId: 'America/Los_Angeles',
  },
  '96117': {
    zip: '96117',
    city: 'Litchfield',
    state: 'CA',
    latitude: '40.383618',
    longitude: '-120.268598',
    timeZoneId: 'America/Los_Angeles',
  },
  '96118': {
    zip: '96118',
    city: 'Loyalton',
    state: 'CA',
    latitude: '39.670023',
    longitude: '-120.236806',
    timeZoneId: 'America/Los_Angeles',
  },
  '96119': {
    zip: '96119',
    city: 'Madeline',
    state: 'CA',
    latitude: '40.976847',
    longitude: '-120.549695',
    timeZoneId: 'America/Los_Angeles',
  },
  '96120': {
    zip: '96120',
    city: 'Markleeville',
    state: 'CA',
    latitude: '38.63288',
    longitude: '-119.82592',
    timeZoneId: 'America/Los_Angeles',
  },
  '96121': {
    zip: '96121',
    city: 'Milford',
    state: 'CA',
    latitude: '40.192871',
    longitude: '-120.409309',
    timeZoneId: 'America/Los_Angeles',
  },
  '96122': {
    zip: '96122',
    city: 'Portola',
    state: 'CA',
    latitude: '39.8421',
    longitude: '-120.46806',
    timeZoneId: 'America/Los_Angeles',
  },
  '96123': {
    zip: '96123',
    city: 'Ravendale',
    state: 'CA',
    latitude: '40.826923',
    longitude: '-120.276312',
    timeZoneId: 'America/Los_Angeles',
  },
  '96124': {
    zip: '96124',
    city: 'Calpine',
    state: 'CA',
    latitude: '39.6531',
    longitude: '-120.51462',
    timeZoneId: 'America/Los_Angeles',
  },
  '96125': {
    zip: '96125',
    city: 'Sierra City',
    state: 'CA',
    latitude: '39.5703',
    longitude: '-120.647805',
    timeZoneId: 'America/Los_Angeles',
  },
  '96126': {
    zip: '96126',
    city: 'Sierraville',
    state: 'CA',
    latitude: '39.593878',
    longitude: '-120.281794',
    timeZoneId: 'America/Los_Angeles',
  },
  '96127': {
    zip: '96127',
    city: 'Susanville',
    state: 'CA',
    latitude: '40.417374',
    longitude: '-120.649006',
    timeZoneId: 'America/Los_Angeles',
  },
  '96128': {
    zip: '96128',
    city: 'Standish',
    state: 'CA',
    latitude: '40.353727',
    longitude: '-120.429652',
    timeZoneId: 'America/Los_Angeles',
  },
  '96129': {
    zip: '96129',
    city: 'Beckwourth',
    state: 'CA',
    latitude: '39.818308',
    longitude: '-120.376409',
    timeZoneId: 'America/Los_Angeles',
  },
  '96130': {
    zip: '96130',
    city: 'Susanville',
    state: 'CA',
    latitude: '40.41714',
    longitude: '-120.64122',
    timeZoneId: 'America/Los_Angeles',
  },
  '96132': {
    zip: '96132',
    city: 'Termo',
    state: 'CA',
    latitude: '40.920826',
    longitude: '-120.610412',
    timeZoneId: 'America/Los_Angeles',
  },
  '96133': {
    zip: '96133',
    city: 'Topaz',
    state: 'CA',
    latitude: '38.63557',
    longitude: '-119.547082',
    timeZoneId: 'America/Los_Angeles',
  },
  '96134': {
    zip: '96134',
    city: 'Tulelake',
    state: 'CA',
    latitude: '41.949725',
    longitude: '-121.472495',
    timeZoneId: 'America/Los_Angeles',
  },
  '96135': {
    zip: '96135',
    city: 'Vinton',
    state: 'CA',
    latitude: '39.719317',
    longitude: '-120.214213',
    timeZoneId: 'America/Los_Angeles',
  },
  '96136': {
    zip: '96136',
    city: 'Wendel',
    state: 'CA',
    latitude: '40.2867',
    longitude: '-120.08772',
    timeZoneId: 'America/Los_Angeles',
  },
  '96137': {
    zip: '96137',
    city: 'Westwood',
    state: 'CA',
    latitude: '40.3494',
    longitude: '-121.07142',
    timeZoneId: 'America/Los_Angeles',
  },
  '96140': {
    zip: '96140',
    city: 'Carnelian Bay',
    state: 'CA',
    latitude: '39.227511',
    longitude: '-120.08124',
    timeZoneId: 'America/Los_Angeles',
  },
  '96141': {
    zip: '96141',
    city: 'Homewood',
    state: 'CA',
    latitude: '39.097839',
    longitude: '-120.169341',
    timeZoneId: 'America/Los_Angeles',
  },
  '96142': {
    zip: '96142',
    city: 'Tahoma',
    state: 'CA',
    latitude: '39.063924',
    longitude: '-120.138469',
    timeZoneId: 'America/Los_Angeles',
  },
  '96143': {
    zip: '96143',
    city: 'Kings Beach',
    state: 'CA',
    latitude: '39.258133',
    longitude: '-120.036587',
    timeZoneId: 'America/Los_Angeles',
  },
  '96145': {
    zip: '96145',
    city: 'Tahoe City',
    state: 'CA',
    latitude: '39.174073',
    longitude: '-120.155846',
    timeZoneId: 'America/Los_Angeles',
  },
  '96146': {
    zip: '96146',
    city: 'Olympic Valley',
    state: 'CA',
    latitude: '39.15',
    longitude: '-120.15',
    timeZoneId: 'America/Los_Angeles',
  },
  '96148': {
    zip: '96148',
    city: 'Tahoe Vista',
    state: 'CA',
    latitude: '39.240575',
    longitude: '-120.071544',
    timeZoneId: 'America/Los_Angeles',
  },
  '96150': {
    zip: '96150',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.886415',
    longitude: '-120.035272',
    timeZoneId: 'America/Los_Angeles',
  },
  '96151': {
    zip: '96151',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.942141',
    longitude: '-119.981473',
    timeZoneId: 'America/Los_Angeles',
  },
  '96152': {
    zip: '96152',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.94228',
    longitude: '-119.976119',
    timeZoneId: 'America/Los_Angeles',
  },
  '96154': {
    zip: '96154',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.943645',
    longitude: '-119.977876',
    timeZoneId: 'America/Los_Angeles',
  },
  '96155': {
    zip: '96155',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '39.018968',
    longitude: '-120.123053',
    timeZoneId: 'America/Los_Angeles',
  },
  '96156': {
    zip: '96156',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.947065',
    longitude: '-119.970227',
    timeZoneId: 'America/Los_Angeles',
  },
  '96157': {
    zip: '96157',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.940824',
    longitude: '-119.980263',
    timeZoneId: 'America/Los_Angeles',
  },
  '96158': {
    zip: '96158',
    city: 'South Lake Tahoe',
    state: 'CA',
    latitude: '38.925696',
    longitude: '-119.966045',
    timeZoneId: 'America/Los_Angeles',
  },
  '96160': {
    zip: '96160',
    city: 'Truckee',
    state: 'CA',
    latitude: '39.329587',
    longitude: '-120.179308',
    timeZoneId: 'America/Los_Angeles',
  },
  '96161': {
    zip: '96161',
    city: 'Truckee',
    state: 'CA',
    latitude: '39.353299',
    longitude: '-120.189535',
    timeZoneId: 'America/Los_Angeles',
  },
  '96162': {
    zip: '96162',
    city: 'Truckee',
    state: 'CA',
    latitude: '39.307577',
    longitude: '-120.314785',
    timeZoneId: 'America/Los_Angeles',
  },
  '96701': {
    zip: '96701',
    city: 'Aiea',
    state: 'HI',
    latitude: '21.384548',
    longitude: '-157.93373',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96703': {
    zip: '96703',
    city: 'Anahola',
    state: 'HI',
    latitude: '22.129099',
    longitude: '-159.34557',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96704': {
    zip: '96704',
    city: 'Captain Cook',
    state: 'HI',
    latitude: '19.415684',
    longitude: '-155.876176',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96705': {
    zip: '96705',
    city: 'Eleele',
    state: 'HI',
    latitude: '21.916405',
    longitude: '-159.5946',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96706': {
    zip: '96706',
    city: 'Ewa Beach',
    state: 'HI',
    latitude: '21.33266',
    longitude: '-158.021874',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96707': {
    zip: '96707',
    city: 'Kapolei',
    state: 'HI',
    latitude: '21.333426',
    longitude: '-158.094993',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96708': {
    zip: '96708',
    city: 'Haiku',
    state: 'HI',
    latitude: '20.911118',
    longitude: '-156.297453',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96709': {
    zip: '96709',
    city: 'Kapolei',
    state: 'HI',
    latitude: '21.324986',
    longitude: '-158.005024',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96710': {
    zip: '96710',
    city: 'Hakalau',
    state: 'HI',
    latitude: '19.882209',
    longitude: '-155.152535',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96712': {
    zip: '96712',
    city: 'Haleiwa',
    state: 'HI',
    latitude: '21.592861',
    longitude: '-158.103416',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96713': {
    zip: '96713',
    city: 'Hana',
    state: 'HI',
    latitude: '20.746665',
    longitude: '-156.122196',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96714': {
    zip: '96714',
    city: 'Hanalei',
    state: 'HI',
    latitude: '22.216648',
    longitude: '-159.481698',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96715': {
    zip: '96715',
    city: 'Hanamaulu',
    state: 'HI',
    latitude: '21.998592',
    longitude: '-159.356441',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96716': {
    zip: '96716',
    city: 'Hanapepe',
    state: 'HI',
    latitude: '21.920872',
    longitude: '-159.58967',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96717': {
    zip: '96717',
    city: 'Hauula',
    state: 'HI',
    latitude: '21.620407',
    longitude: '-157.9186',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96718': {
    zip: '96718',
    city: 'Hawaii National Park',
    state: 'HI',
    latitude: '19.410522',
    longitude: '-155.274653',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96719': {
    zip: '96719',
    city: 'Hawi',
    state: 'HI',
    latitude: '20.237133',
    longitude: '-155.8502',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96720': {
    zip: '96720',
    city: 'Hilo',
    state: 'HI',
    latitude: '19.701093',
    longitude: '-155.094595',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96721': {
    zip: '96721',
    city: 'Hilo',
    state: 'HI',
    latitude: '19.532167',
    longitude: '-154.955477',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96722': {
    zip: '96722',
    city: 'Princeville',
    state: 'HI',
    latitude: '22.226424',
    longitude: '-159.482542',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96725': {
    zip: '96725',
    city: 'Holualoa',
    state: 'HI',
    latitude: '19.619027',
    longitude: '-155.953335',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96726': {
    zip: '96726',
    city: 'Honaunau',
    state: 'HI',
    latitude: '19.445139',
    longitude: '-155.891182',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96727': {
    zip: '96727',
    city: 'Honokaa',
    state: 'HI',
    latitude: '20.087407',
    longitude: '-155.518923',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96728': {
    zip: '96728',
    city: 'Honomu',
    state: 'HI',
    latitude: '19.857107',
    longitude: '-155.135995',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96729': {
    zip: '96729',
    city: 'Hoolehua',
    state: 'HI',
    latitude: '21.164136',
    longitude: '-157.085991',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96730': {
    zip: '96730',
    city: 'Kaaawa',
    state: 'HI',
    latitude: '21.554256',
    longitude: '-157.857322',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96731': {
    zip: '96731',
    city: 'Kahuku',
    state: 'HI',
    latitude: '21.678499',
    longitude: '-157.957534',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96732': {
    zip: '96732',
    city: 'Kahului',
    state: 'HI',
    latitude: '20.883882',
    longitude: '-156.475338',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96733': {
    zip: '96733',
    city: 'Kahului',
    state: 'HI',
    latitude: '20.894396',
    longitude: '-156.466524',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96734': {
    zip: '96734',
    city: 'Kailua',
    state: 'HI',
    latitude: '21.397507',
    longitude: '-157.744966',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96737': {
    zip: '96737',
    city: 'Ocean View',
    state: 'HI',
    latitude: '19.061249',
    longitude: '-155.716967',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96738': {
    zip: '96738',
    city: 'Waikoloa',
    state: 'HI',
    latitude: '19.826945',
    longitude: '-155.83402',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96739': {
    zip: '96739',
    city: 'Keauhou',
    state: 'HI',
    latitude: '19.64857',
    longitude: '-155.999276',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96740': {
    zip: '96740',
    city: 'Kailua Kona',
    state: 'HI',
    latitude: '19.664709',
    longitude: '-155.981431',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96741': {
    zip: '96741',
    city: 'Kalaheo',
    state: 'HI',
    latitude: '21.925585',
    longitude: '-159.526952',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96742': {
    zip: '96742',
    city: 'Kalaupapa',
    state: 'HI',
    latitude: '21.182329',
    longitude: '-156.974434',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96743': {
    zip: '96743',
    city: 'Kamuela',
    state: 'HI',
    latitude: '19.975127',
    longitude: '-155.76277',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96744': {
    zip: '96744',
    city: 'Kaneohe',
    state: 'HI',
    latitude: '21.417372',
    longitude: '-157.811961',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96745': {
    zip: '96745',
    city: 'Kailua Kona',
    state: 'HI',
    latitude: '19.685677',
    longitude: '-155.970966',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96746': {
    zip: '96746',
    city: 'Kapaa',
    state: 'HI',
    latitude: '22.078817',
    longitude: '-159.35007',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96747': {
    zip: '96747',
    city: 'Kaumakani',
    state: 'HI',
    latitude: '21.933335',
    longitude: '-159.629261',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96748': {
    zip: '96748',
    city: 'Kaunakakai',
    state: 'HI',
    latitude: '21.116967',
    longitude: '-156.88255',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96749': {
    zip: '96749',
    city: 'Keaau',
    state: 'HI',
    latitude: '19.575395',
    longitude: '-154.961816',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96750': {
    zip: '96750',
    city: 'Kealakekua',
    state: 'HI',
    latitude: '19.525821',
    longitude: '-155.92648',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96751': {
    zip: '96751',
    city: 'Kealia',
    state: 'HI',
    latitude: '22.107201',
    longitude: '-159.308621',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96752': {
    zip: '96752',
    city: 'Kekaha',
    state: 'HI',
    latitude: '21.967116',
    longitude: '-159.709834',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96753': {
    zip: '96753',
    city: 'Kihei',
    state: 'HI',
    latitude: '20.733086',
    longitude: '-156.44971',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96754': {
    zip: '96754',
    city: 'Kilauea',
    state: 'HI',
    latitude: '22.215931',
    longitude: '-159.403979',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96755': {
    zip: '96755',
    city: 'Kapaau',
    state: 'HI',
    latitude: '20.167014',
    longitude: '-155.802192',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96756': {
    zip: '96756',
    city: 'Koloa',
    state: 'HI',
    latitude: '21.907994',
    longitude: '-159.489037',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96757': {
    zip: '96757',
    city: 'Kualapuu',
    state: 'HI',
    latitude: '21.156224',
    longitude: '-157.039972',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96759': {
    zip: '96759',
    city: 'Kunia',
    state: 'HI',
    latitude: '21.459024',
    longitude: '-158.064195',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96760': {
    zip: '96760',
    city: 'Kurtistown',
    state: 'HI',
    latitude: '19.580365',
    longitude: '-155.036812',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96761': {
    zip: '96761',
    city: 'Lahaina',
    state: 'HI',
    latitude: '20.920697',
    longitude: '-156.678779',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96762': {
    zip: '96762',
    city: 'Laie',
    state: 'HI',
    latitude: '21.647614',
    longitude: '-157.926602',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96763': {
    zip: '96763',
    city: 'Lanai City',
    state: 'HI',
    latitude: '20.830042',
    longitude: '-156.920857',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96764': {
    zip: '96764',
    city: 'Laupahoehoe',
    state: 'HI',
    latitude: '19.965424',
    longitude: '-155.234398',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96765': {
    zip: '96765',
    city: 'Lawai',
    state: 'HI',
    latitude: '21.926579',
    longitude: '-159.508429',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96766': {
    zip: '96766',
    city: 'Lihue',
    state: 'HI',
    latitude: '21.978912',
    longitude: '-159.36725',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96767': {
    zip: '96767',
    city: 'Lahaina',
    state: 'HI',
    latitude: '20.911065',
    longitude: '-156.684798',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96768': {
    zip: '96768',
    city: 'Makawao',
    state: 'HI',
    latitude: '20.847667',
    longitude: '-156.329587',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96769': {
    zip: '96769',
    city: 'Makaweli',
    state: 'HI',
    latitude: '21.950509',
    longitude: '-159.670051',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96770': {
    zip: '96770',
    city: 'Maunaloa',
    state: 'HI',
    latitude: '21.135794',
    longitude: '-157.214695',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96771': {
    zip: '96771',
    city: 'Mountain View',
    state: 'HI',
    latitude: '19.537962',
    longitude: '-155.08933',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96772': {
    zip: '96772',
    city: 'Naalehu',
    state: 'HI',
    latitude: '19.085968',
    longitude: '-155.708899',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96773': {
    zip: '96773',
    city: 'Ninole',
    state: 'HI',
    latitude: '19.93126',
    longitude: '-155.16151',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96774': {
    zip: '96774',
    city: 'Ookala',
    state: 'HI',
    latitude: '20.003285',
    longitude: '-155.275133',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96776': {
    zip: '96776',
    city: 'Paauilo',
    state: 'HI',
    latitude: '19.925429',
    longitude: '-155.435559',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96777': {
    zip: '96777',
    city: 'Pahala',
    state: 'HI',
    latitude: '19.200662',
    longitude: '-155.525898',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96778': {
    zip: '96778',
    city: 'Pahoa',
    state: 'HI',
    latitude: '19.52796',
    longitude: '-154.909098',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96779': {
    zip: '96779',
    city: 'Paia',
    state: 'HI',
    latitude: '20.916294',
    longitude: '-156.38042',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96780': {
    zip: '96780',
    city: 'Papaaloa',
    state: 'HI',
    latitude: '19.918554',
    longitude: '-155.197133',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96781': {
    zip: '96781',
    city: 'Papaikou',
    state: 'HI',
    latitude: '19.793534',
    longitude: '-155.191151',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96782': {
    zip: '96782',
    city: 'Pearl City',
    state: 'HI',
    latitude: '21.410205',
    longitude: '-157.964616',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96783': {
    zip: '96783',
    city: 'Pepeekeo',
    state: 'HI',
    latitude: '19.830916',
    longitude: '-155.112189',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96784': {
    zip: '96784',
    city: 'Puunene',
    state: 'HI',
    latitude: '20.771617',
    longitude: '-156.330622',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96785': {
    zip: '96785',
    city: 'Volcano',
    state: 'HI',
    latitude: '19.443033',
    longitude: '-155.284434',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96786': {
    zip: '96786',
    city: 'Wahiawa',
    state: 'HI',
    latitude: '21.503956',
    longitude: '-158.032126',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96788': {
    zip: '96788',
    city: 'Pukalani',
    state: 'HI',
    latitude: '20.834756',
    longitude: '-156.338783',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96789': {
    zip: '96789',
    city: 'Mililani',
    state: 'HI',
    latitude: '21.447784',
    longitude: '-158.023645',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96790': {
    zip: '96790',
    city: 'Kula',
    state: 'HI',
    latitude: '20.770144',
    longitude: '-156.325085',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96791': {
    zip: '96791',
    city: 'Waialua',
    state: 'HI',
    latitude: '21.57754',
    longitude: '-158.124575',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96792': {
    zip: '96792',
    city: 'Waianae',
    state: 'HI',
    latitude: '21.440282',
    longitude: '-158.186378',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96793': {
    zip: '96793',
    city: 'Wailuku',
    state: 'HI',
    latitude: '20.896769',
    longitude: '-156.504013',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96795': {
    zip: '96795',
    city: 'Waimanalo',
    state: 'HI',
    latitude: '21.342885',
    longitude: '-157.716302',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96796': {
    zip: '96796',
    city: 'Waimea',
    state: 'HI',
    latitude: '21.962949',
    longitude: '-159.669111',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96797': {
    zip: '96797',
    city: 'Waipahu',
    state: 'HI',
    latitude: '21.393173',
    longitude: '-158.011282',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96801': {
    zip: '96801',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.32548',
    longitude: '-157.831334',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96802': {
    zip: '96802',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.304492',
    longitude: '-157.859879',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96803': {
    zip: '96803',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.308655',
    longitude: '-157.858561',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96804': {
    zip: '96804',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.306209',
    longitude: '-157.860153',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96805': {
    zip: '96805',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.305636',
    longitude: '-157.856474',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96806': {
    zip: '96806',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.304761',
    longitude: '-157.857413',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96807': {
    zip: '96807',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.306339',
    longitude: '-157.861146',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96808': {
    zip: '96808',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.304528',
    longitude: '-157.859432',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96809': {
    zip: '96809',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.305003',
    longitude: '-157.859366',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96810': {
    zip: '96810',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.304604',
    longitude: '-157.858316',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96811': {
    zip: '96811',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.326884',
    longitude: '-157.828158',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96812': {
    zip: '96812',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.304962',
    longitude: '-157.861303',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96813': {
    zip: '96813',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.314718',
    longitude: '-157.853702',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96814': {
    zip: '96814',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.301058',
    longitude: '-157.848253',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96815': {
    zip: '96815',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.278625',
    longitude: '-157.820909',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96816': {
    zip: '96816',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.286442',
    longitude: '-157.801992',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96817': {
    zip: '96817',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.334849',
    longitude: '-157.856702',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96818': {
    zip: '96818',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.35364',
    longitude: '-157.931659',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96819': {
    zip: '96819',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.345402',
    longitude: '-157.880907',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96820': {
    zip: '96820',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.351908',
    longitude: '-157.878057',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96821': {
    zip: '96821',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.289359',
    longitude: '-157.755003',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96822': {
    zip: '96822',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.312545',
    longitude: '-157.821044',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96823': {
    zip: '96823',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.3073',
    longitude: '-157.844392',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96824': {
    zip: '96824',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.280032',
    longitude: '-157.758076',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96825': {
    zip: '96825',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.296254',
    longitude: '-157.696767',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96826': {
    zip: '96826',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.294061',
    longitude: '-157.826123',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96828': {
    zip: '96828',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.291312',
    longitude: '-157.819996',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96830': {
    zip: '96830',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.286315',
    longitude: '-157.834651',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96836': {
    zip: '96836',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.289782',
    longitude: '-157.836148',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96837': {
    zip: '96837',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.312064',
    longitude: '-157.864195',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96838': {
    zip: '96838',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.348058',
    longitude: '-157.876862',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96839': {
    zip: '96839',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.322589',
    longitude: '-157.812705',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96840': {
    zip: '96840',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.306502',
    longitude: '-157.860814',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96841': {
    zip: '96841',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.309296',
    longitude: '-157.85962',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96843': {
    zip: '96843',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.30274',
    longitude: '-157.850969',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96844': {
    zip: '96844',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.295954',
    longitude: '-157.813874',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96846': {
    zip: '96846',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.318332',
    longitude: '-157.869521',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96847': {
    zip: '96847',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.301543',
    longitude: '-157.845453',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96848': {
    zip: '96848',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.321456',
    longitude: '-157.813409',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96849': {
    zip: '96849',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.333358',
    longitude: '-157.916104',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96850': {
    zip: '96850',
    city: 'Honolulu',
    state: 'HI',
    latitude: '21.308944',
    longitude: '-157.86115',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96853': {
    zip: '96853',
    city: 'Jbphh',
    state: 'HI',
    latitude: '21.339051',
    longitude: '-157.943329',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96854': {
    zip: '96854',
    city: 'Wheeler Army Airfield',
    state: 'HI',
    latitude: '21.4863',
    longitude: '-158.034751',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96857': {
    zip: '96857',
    city: 'Schofield Barracks',
    state: 'HI',
    latitude: '21.494722',
    longitude: '-158.066346',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96858': {
    zip: '96858',
    city: 'Fort Shafter',
    state: 'HI',
    latitude: '21.345974',
    longitude: '-157.891047',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96859': {
    zip: '96859',
    city: 'Tripler Army Medical Center',
    state: 'HI',
    latitude: '21.347002',
    longitude: '-157.872476',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96860': {
    zip: '96860',
    city: 'Jbphh',
    state: 'HI',
    latitude: '21.353536',
    longitude: '-157.966185',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96861': {
    zip: '96861',
    city: 'Camp H M Smith',
    state: 'HI',
    latitude: '21.39515',
    longitude: '-157.89922',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96863': {
    zip: '96863',
    city: 'Mcbh Kaneohe Bay',
    state: 'HI',
    latitude: '21.38848',
    longitude: '-157.755014',
    timeZoneId: 'Pacific/Honolulu',
  },
  '96898': {
    zip: '96898',
    city: 'Wake Island',
    state: 'HI',
    latitude: '21.310541',
    longitude: '-157.863115',
    timeZoneId: 'Pacific/Honolulu',
  },
  '97001': {
    zip: '97001',
    city: 'Antelope',
    state: 'OR',
    latitude: '44.8767',
    longitude: '-120.70932',
    timeZoneId: 'America/Los_Angeles',
  },
  '97002': {
    zip: '97002',
    city: 'Aurora',
    state: 'OR',
    latitude: '45.232302',
    longitude: '-122.807794',
    timeZoneId: 'America/Los_Angeles',
  },
  '97003': {
    zip: '97003',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.509669',
    longitude: '-122.879913',
    timeZoneId: 'America/Los_Angeles',
  },
  '97004': {
    zip: '97004',
    city: 'Beavercreek',
    state: 'OR',
    latitude: '45.253113',
    longitude: '-122.477961',
    timeZoneId: 'America/Los_Angeles',
  },
  '97005': {
    zip: '97005',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.488582',
    longitude: '-122.804382',
    timeZoneId: 'America/Los_Angeles',
  },
  '97006': {
    zip: '97006',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.516558',
    longitude: '-122.858874',
    timeZoneId: 'America/Los_Angeles',
  },
  '97007': {
    zip: '97007',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.472666',
    longitude: '-122.856983',
    timeZoneId: 'America/Los_Angeles',
  },
  '97008': {
    zip: '97008',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.458293',
    longitude: '-122.796743',
    timeZoneId: 'America/Los_Angeles',
  },
  '97009': {
    zip: '97009',
    city: 'Boring',
    state: 'OR',
    latitude: '45.424374',
    longitude: '-122.384149',
    timeZoneId: 'America/Los_Angeles',
  },
  '97010': {
    zip: '97010',
    city: 'Bridal Veil',
    state: 'OR',
    latitude: '45.549954',
    longitude: '-122.272013',
    timeZoneId: 'America/Los_Angeles',
  },
  '97011': {
    zip: '97011',
    city: 'Brightwood',
    state: 'OR',
    latitude: '45.363522',
    longitude: '-122.000338',
    timeZoneId: 'America/Los_Angeles',
  },
  '97013': {
    zip: '97013',
    city: 'Canby',
    state: 'OR',
    latitude: '45.241313',
    longitude: '-122.677583',
    timeZoneId: 'America/Los_Angeles',
  },
  '97014': {
    zip: '97014',
    city: 'Cascade Locks',
    state: 'OR',
    latitude: '45.67188',
    longitude: '-121.82148',
    timeZoneId: 'America/Los_Angeles',
  },
  '97015': {
    zip: '97015',
    city: 'Clackamas',
    state: 'OR',
    latitude: '45.41561',
    longitude: '-122.52375',
    timeZoneId: 'America/Los_Angeles',
  },
  '97016': {
    zip: '97016',
    city: 'Clatskanie',
    state: 'OR',
    latitude: '45.996389',
    longitude: '-123.255278',
    timeZoneId: 'America/Los_Angeles',
  },
  '97017': {
    zip: '97017',
    city: 'Colton',
    state: 'OR',
    latitude: '45.156854',
    longitude: '-122.356927',
    timeZoneId: 'America/Los_Angeles',
  },
  '97018': {
    zip: '97018',
    city: 'Columbia City',
    state: 'OR',
    latitude: '45.894545',
    longitude: '-122.815837',
    timeZoneId: 'America/Los_Angeles',
  },
  '97019': {
    zip: '97019',
    city: 'Corbett',
    state: 'OR',
    latitude: '45.520077',
    longitude: '-122.259345',
    timeZoneId: 'America/Los_Angeles',
  },
  '97020': {
    zip: '97020',
    city: 'Donald',
    state: 'OR',
    latitude: '45.224101',
    longitude: '-122.841176',
    timeZoneId: 'America/Los_Angeles',
  },
  '97021': {
    zip: '97021',
    city: 'Dufur',
    state: 'OR',
    latitude: '45.421839',
    longitude: '-121.16282',
    timeZoneId: 'America/Los_Angeles',
  },
  '97022': {
    zip: '97022',
    city: 'Eagle Creek',
    state: 'OR',
    latitude: '45.350882',
    longitude: '-122.339245',
    timeZoneId: 'America/Los_Angeles',
  },
  '97023': {
    zip: '97023',
    city: 'Estacada',
    state: 'OR',
    latitude: '45.280759',
    longitude: '-122.313251',
    timeZoneId: 'America/Los_Angeles',
  },
  '97024': {
    zip: '97024',
    city: 'Fairview',
    state: 'OR',
    latitude: '45.539229',
    longitude: '-122.437076',
    timeZoneId: 'America/Los_Angeles',
  },
  '97026': {
    zip: '97026',
    city: 'Gervais',
    state: 'OR',
    latitude: '45.112684',
    longitude: '-122.92166',
    timeZoneId: 'America/Los_Angeles',
  },
  '97027': {
    zip: '97027',
    city: 'Gladstone',
    state: 'OR',
    latitude: '45.38981',
    longitude: '-122.588846',
    timeZoneId: 'America/Los_Angeles',
  },
  '97028': {
    zip: '97028',
    city: 'Government Camp',
    state: 'OR',
    latitude: '45.306268',
    longitude: '-121.746016',
    timeZoneId: 'America/Los_Angeles',
  },
  '97029': {
    zip: '97029',
    city: 'Grass Valley',
    state: 'OR',
    latitude: '45.273881',
    longitude: '-120.760619',
    timeZoneId: 'America/Los_Angeles',
  },
  '97030': {
    zip: '97030',
    city: 'Gresham',
    state: 'OR',
    latitude: '45.499394',
    longitude: '-122.420435',
    timeZoneId: 'America/Los_Angeles',
  },
  '97031': {
    zip: '97031',
    city: 'Hood River',
    state: 'OR',
    latitude: '45.666629',
    longitude: '-121.539966',
    timeZoneId: 'America/Los_Angeles',
  },
  '97032': {
    zip: '97032',
    city: 'Hubbard',
    state: 'OR',
    latitude: '45.183275',
    longitude: '-122.802741',
    timeZoneId: 'America/Los_Angeles',
  },
  '97033': {
    zip: '97033',
    city: 'Kent',
    state: 'OR',
    latitude: '45.081959',
    longitude: '-120.664996',
    timeZoneId: 'America/Los_Angeles',
  },
  '97034': {
    zip: '97034',
    city: 'Lake Oswego',
    state: 'OR',
    latitude: '45.410367',
    longitude: '-122.689055',
    timeZoneId: 'America/Los_Angeles',
  },
  '97035': {
    zip: '97035',
    city: 'Lake Oswego',
    state: 'OR',
    latitude: '45.411593',
    longitude: '-122.725222',
    timeZoneId: 'America/Los_Angeles',
  },
  '97036': {
    zip: '97036',
    city: 'Marylhurst',
    state: 'OR',
    latitude: '45.397114',
    longitude: '-122.648456',
    timeZoneId: 'America/Los_Angeles',
  },
  '97037': {
    zip: '97037',
    city: 'Maupin',
    state: 'OR',
    latitude: '45.076963',
    longitude: '-121.376099',
    timeZoneId: 'America/Los_Angeles',
  },
  '97038': {
    zip: '97038',
    city: 'Molalla',
    state: 'OR',
    latitude: '45.121182',
    longitude: '-122.569308',
    timeZoneId: 'America/Los_Angeles',
  },
  '97039': {
    zip: '97039',
    city: 'Moro',
    state: 'OR',
    latitude: '45.486939',
    longitude: '-120.7333',
    timeZoneId: 'America/Los_Angeles',
  },
  '97040': {
    zip: '97040',
    city: 'Mosier',
    state: 'OR',
    latitude: '45.664735',
    longitude: '-121.375464',
    timeZoneId: 'America/Los_Angeles',
  },
  '97041': {
    zip: '97041',
    city: 'Mount Hood Parkdale',
    state: 'OR',
    latitude: '45.4983',
    longitude: '-121.59288',
    timeZoneId: 'America/Los_Angeles',
  },
  '97042': {
    zip: '97042',
    city: 'Mulino',
    state: 'OR',
    latitude: '45.210348',
    longitude: '-122.542178',
    timeZoneId: 'America/Los_Angeles',
  },
  '97044': {
    zip: '97044',
    city: 'Odell',
    state: 'OR',
    latitude: '45.625313',
    longitude: '-121.53971',
    timeZoneId: 'America/Los_Angeles',
  },
  '97045': {
    zip: '97045',
    city: 'Oregon City',
    state: 'OR',
    latitude: '45.340277',
    longitude: '-122.560941',
    timeZoneId: 'America/Los_Angeles',
  },
  '97048': {
    zip: '97048',
    city: 'Rainier',
    state: 'OR',
    latitude: '46.058179',
    longitude: '-122.963608',
    timeZoneId: 'America/Los_Angeles',
  },
  '97049': {
    zip: '97049',
    city: 'Rhododendron',
    state: 'OR',
    latitude: '45.36906',
    longitude: '-121.92264',
    timeZoneId: 'America/Los_Angeles',
  },
  '97050': {
    zip: '97050',
    city: 'Rufus',
    state: 'OR',
    latitude: '45.680008',
    longitude: '-120.699206',
    timeZoneId: 'America/Los_Angeles',
  },
  '97051': {
    zip: '97051',
    city: 'Saint Helens',
    state: 'OR',
    latitude: '45.871834',
    longitude: '-122.872699',
    timeZoneId: 'America/Los_Angeles',
  },
  '97053': {
    zip: '97053',
    city: 'Warren',
    state: 'OR',
    latitude: '45.823043',
    longitude: '-122.883264',
    timeZoneId: 'America/Los_Angeles',
  },
  '97054': {
    zip: '97054',
    city: 'Deer Island',
    state: 'OR',
    latitude: '45.929439',
    longitude: '-122.925681',
    timeZoneId: 'America/Los_Angeles',
  },
  '97055': {
    zip: '97055',
    city: 'Sandy',
    state: 'OR',
    latitude: '45.388814',
    longitude: '-122.228513',
    timeZoneId: 'America/Los_Angeles',
  },
  '97056': {
    zip: '97056',
    city: 'Scappoose',
    state: 'OR',
    latitude: '45.760391',
    longitude: '-122.933635',
    timeZoneId: 'America/Los_Angeles',
  },
  '97057': {
    zip: '97057',
    city: 'Shaniko',
    state: 'OR',
    latitude: '45.058157',
    longitude: '-120.808513',
    timeZoneId: 'America/Los_Angeles',
  },
  '97058': {
    zip: '97058',
    city: 'The Dalles',
    state: 'OR',
    latitude: '45.598999',
    longitude: '-121.193323',
    timeZoneId: 'America/Los_Angeles',
  },
  '97060': {
    zip: '97060',
    city: 'Troutdale',
    state: 'OR',
    latitude: '45.527631',
    longitude: '-122.397778',
    timeZoneId: 'America/Los_Angeles',
  },
  '97062': {
    zip: '97062',
    city: 'Tualatin',
    state: 'OR',
    latitude: '45.370826',
    longitude: '-122.769707',
    timeZoneId: 'America/Los_Angeles',
  },
  '97063': {
    zip: '97063',
    city: 'Tygh Valley',
    state: 'OR',
    latitude: '45.23658',
    longitude: '-121.32282',
    timeZoneId: 'America/Los_Angeles',
  },
  '97064': {
    zip: '97064',
    city: 'Vernonia',
    state: 'OR',
    latitude: '45.860625',
    longitude: '-123.201591',
    timeZoneId: 'America/Los_Angeles',
  },
  '97065': {
    zip: '97065',
    city: 'Wasco',
    state: 'OR',
    latitude: '45.593945',
    longitude: '-120.695798',
    timeZoneId: 'America/Los_Angeles',
  },
  '97067': {
    zip: '97067',
    city: 'Welches',
    state: 'OR',
    latitude: '45.339237',
    longitude: '-121.965273',
    timeZoneId: 'America/Los_Angeles',
  },
  '97068': {
    zip: '97068',
    city: 'West Linn',
    state: 'OR',
    latitude: '45.366874',
    longitude: '-122.65036',
    timeZoneId: 'America/Los_Angeles',
  },
  '97070': {
    zip: '97070',
    city: 'Wilsonville',
    state: 'OR',
    latitude: '45.301823',
    longitude: '-122.762197',
    timeZoneId: 'America/Los_Angeles',
  },
  '97071': {
    zip: '97071',
    city: 'Woodburn',
    state: 'OR',
    latitude: '45.14745',
    longitude: '-122.855971',
    timeZoneId: 'America/Los_Angeles',
  },
  '97075': {
    zip: '97075',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.492495',
    longitude: '-122.80465',
    timeZoneId: 'America/Los_Angeles',
  },
  '97076': {
    zip: '97076',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.489342',
    longitude: '-122.799818',
    timeZoneId: 'America/Los_Angeles',
  },
  '97077': {
    zip: '97077',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.49361',
    longitude: '-122.800298',
    timeZoneId: 'America/Los_Angeles',
  },
  '97078': {
    zip: '97078',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.49034',
    longitude: '-122.880401',
    timeZoneId: 'America/Los_Angeles',
  },
  '97079': {
    zip: '97079',
    city: 'Beaverton',
    state: 'OR',
    latitude: '45.490812',
    longitude: '-122.803354',
    timeZoneId: 'America/Los_Angeles',
  },
  '97080': {
    zip: '97080',
    city: 'Gresham',
    state: 'OR',
    latitude: '45.483779',
    longitude: '-122.416754',
    timeZoneId: 'America/Los_Angeles',
  },
  '97086': {
    zip: '97086',
    city: 'Happy Valley',
    state: 'OR',
    latitude: '45.443245',
    longitude: '-122.514694',
    timeZoneId: 'America/Los_Angeles',
  },
  '97089': {
    zip: '97089',
    city: 'Damascus',
    state: 'OR',
    latitude: '45.422127',
    longitude: '-122.449928',
    timeZoneId: 'America/Los_Angeles',
  },
  '97101': {
    zip: '97101',
    city: 'Amity',
    state: 'OR',
    latitude: '45.098528',
    longitude: '-123.207387',
    timeZoneId: 'America/Los_Angeles',
  },
  '97102': {
    zip: '97102',
    city: 'Arch Cape',
    state: 'OR',
    latitude: '45.885137',
    longitude: '-123.944278',
    timeZoneId: 'America/Los_Angeles',
  },
  '97103': {
    zip: '97103',
    city: 'Astoria',
    state: 'OR',
    latitude: '46.181734',
    longitude: '-123.826767',
    timeZoneId: 'America/Los_Angeles',
  },
  '97106': {
    zip: '97106',
    city: 'Banks',
    state: 'OR',
    latitude: '45.620175',
    longitude: '-123.112354',
    timeZoneId: 'America/Los_Angeles',
  },
  '97107': {
    zip: '97107',
    city: 'Bay City',
    state: 'OR',
    latitude: '45.526326',
    longitude: '-123.879733',
    timeZoneId: 'America/Los_Angeles',
  },
  '97108': {
    zip: '97108',
    city: 'Beaver',
    state: 'OR',
    latitude: '45.283796',
    longitude: '-123.753015',
    timeZoneId: 'America/Los_Angeles',
  },
  '97109': {
    zip: '97109',
    city: 'Buxton',
    state: 'OR',
    latitude: '45.688031',
    longitude: '-123.190696',
    timeZoneId: 'America/Los_Angeles',
  },
  '97110': {
    zip: '97110',
    city: 'Cannon Beach',
    state: 'OR',
    latitude: '45.890942',
    longitude: '-123.958453',
    timeZoneId: 'America/Los_Angeles',
  },
  '97111': {
    zip: '97111',
    city: 'Carlton',
    state: 'OR',
    latitude: '45.292939',
    longitude: '-123.18375',
    timeZoneId: 'America/Los_Angeles',
  },
  '97112': {
    zip: '97112',
    city: 'Cloverdale',
    state: 'OR',
    latitude: '45.219748',
    longitude: '-123.926658',
    timeZoneId: 'America/Los_Angeles',
  },
  '97113': {
    zip: '97113',
    city: 'Cornelius',
    state: 'OR',
    latitude: '45.52252',
    longitude: '-123.050458',
    timeZoneId: 'America/Los_Angeles',
  },
  '97114': {
    zip: '97114',
    city: 'Dayton',
    state: 'OR',
    latitude: '45.1746',
    longitude: '-123.06966',
    timeZoneId: 'America/Los_Angeles',
  },
  '97115': {
    zip: '97115',
    city: 'Dundee',
    state: 'OR',
    latitude: '45.276746',
    longitude: '-123.01855',
    timeZoneId: 'America/Los_Angeles',
  },
  '97116': {
    zip: '97116',
    city: 'Forest Grove',
    state: 'OR',
    latitude: '45.522072',
    longitude: '-123.107074',
    timeZoneId: 'America/Los_Angeles',
  },
  '97117': {
    zip: '97117',
    city: 'Gales Creek',
    state: 'OR',
    latitude: '45.597896',
    longitude: '-123.268469',
    timeZoneId: 'America/Los_Angeles',
  },
  '97118': {
    zip: '97118',
    city: 'Garibaldi',
    state: 'OR',
    latitude: '45.561012',
    longitude: '-123.912301',
    timeZoneId: 'America/Los_Angeles',
  },
  '97119': {
    zip: '97119',
    city: 'Gaston',
    state: 'OR',
    latitude: '45.403981',
    longitude: '-123.149289',
    timeZoneId: 'America/Los_Angeles',
  },
  '97121': {
    zip: '97121',
    city: 'Hammond',
    state: 'OR',
    latitude: '46.196897',
    longitude: '-123.949498',
    timeZoneId: 'America/Los_Angeles',
  },
  '97122': {
    zip: '97122',
    city: 'Hebo',
    state: 'OR',
    latitude: '45.193212',
    longitude: '-123.832506',
    timeZoneId: 'America/Los_Angeles',
  },
  '97123': {
    zip: '97123',
    city: 'Hillsboro',
    state: 'OR',
    latitude: '45.506393',
    longitude: '-122.946368',
    timeZoneId: 'America/Los_Angeles',
  },
  '97124': {
    zip: '97124',
    city: 'Hillsboro',
    state: 'OR',
    latitude: '45.535156',
    longitude: '-122.95626',
    timeZoneId: 'America/Los_Angeles',
  },
  '97125': {
    zip: '97125',
    city: 'Manning',
    state: 'OR',
    latitude: '45.618889',
    longitude: '-123.113056',
    timeZoneId: 'America/Los_Angeles',
  },
  '97127': {
    zip: '97127',
    city: 'Lafayette',
    state: 'OR',
    latitude: '45.245825',
    longitude: '-123.113634',
    timeZoneId: 'America/Los_Angeles',
  },
  '97128': {
    zip: '97128',
    city: 'McMinnville',
    state: 'OR',
    latitude: '45.209606',
    longitude: '-123.217803',
    timeZoneId: 'America/Los_Angeles',
  },
  '97129': {
    zip: '97129',
    city: 'Hillsboro',
    state: 'OR',
    latitude: '45.209606',
    longitude: '-123.217803',
    timeZoneId: 'America/Los_Angeles',
  },
  '97130': {
    zip: '97130',
    city: 'Manzanita',
    state: 'OR',
    latitude: '45.720221',
    longitude: '-123.934279',
    timeZoneId: 'America/Los_Angeles',
  },
  '97131': {
    zip: '97131',
    city: 'Nehalem',
    state: 'OR',
    latitude: '45.72',
    longitude: '-123.85998',
    timeZoneId: 'America/Los_Angeles',
  },
  '97132': {
    zip: '97132',
    city: 'Newberg',
    state: 'OR',
    latitude: '45.308916',
    longitude: '-122.977461',
    timeZoneId: 'America/Los_Angeles',
  },
  '97133': {
    zip: '97133',
    city: 'North Plains',
    state: 'OR',
    latitude: '45.597975',
    longitude: '-122.99593',
    timeZoneId: 'America/Los_Angeles',
  },
  '97134': {
    zip: '97134',
    city: 'Oceanside',
    state: 'OR',
    latitude: '45.461968',
    longitude: '-123.966459',
    timeZoneId: 'America/Los_Angeles',
  },
  '97135': {
    zip: '97135',
    city: 'Pacific City',
    state: 'OR',
    latitude: '45.208152',
    longitude: '-123.958657',
    timeZoneId: 'America/Los_Angeles',
  },
  '97136': {
    zip: '97136',
    city: 'Rockaway Beach',
    state: 'OR',
    latitude: '45.6315',
    longitude: '-123.89142',
    timeZoneId: 'America/Los_Angeles',
  },
  '97137': {
    zip: '97137',
    city: 'Saint Paul',
    state: 'OR',
    latitude: '45.220766',
    longitude: '-122.953219',
    timeZoneId: 'America/Los_Angeles',
  },
  '97138': {
    zip: '97138',
    city: 'Seaside',
    state: 'OR',
    latitude: '46.001224',
    longitude: '-123.921677',
    timeZoneId: 'America/Los_Angeles',
  },
  '97140': {
    zip: '97140',
    city: 'Sherwood',
    state: 'OR',
    latitude: '45.355263',
    longitude: '-122.833791',
    timeZoneId: 'America/Los_Angeles',
  },
  '97141': {
    zip: '97141',
    city: 'Tillamook',
    state: 'OR',
    latitude: '45.46638',
    longitude: '-123.71694',
    timeZoneId: 'America/Los_Angeles',
  },
  '97143': {
    zip: '97143',
    city: 'Netarts',
    state: 'OR',
    latitude: '45.436071',
    longitude: '-123.942514',
    timeZoneId: 'America/Los_Angeles',
  },
  '97144': {
    zip: '97144',
    city: 'Timber',
    state: 'OR',
    latitude: '45.729286',
    longitude: '-123.322577',
    timeZoneId: 'America/Los_Angeles',
  },
  '97145': {
    zip: '97145',
    city: 'Tolovana Park',
    state: 'OR',
    latitude: '45.871142',
    longitude: '-123.958868',
    timeZoneId: 'America/Los_Angeles',
  },
  '97146': {
    zip: '97146',
    city: 'Warrenton',
    state: 'OR',
    latitude: '46.146308',
    longitude: '-123.920156',
    timeZoneId: 'America/Los_Angeles',
  },
  '97147': {
    zip: '97147',
    city: 'Wheeler',
    state: 'OR',
    latitude: '45.689851',
    longitude: '-123.883901',
    timeZoneId: 'America/Los_Angeles',
  },
  '97148': {
    zip: '97148',
    city: 'Yamhill',
    state: 'OR',
    latitude: '45.351433',
    longitude: '-123.218167',
    timeZoneId: 'America/Los_Angeles',
  },
  '97149': {
    zip: '97149',
    city: 'Neskowin',
    state: 'OR',
    latitude: '45.104953',
    longitude: '-123.972179',
    timeZoneId: 'America/Los_Angeles',
  },
  '97201': {
    zip: '97201',
    city: 'Portland',
    state: 'OR',
    latitude: '45.498618',
    longitude: '-122.692',
    timeZoneId: 'America/Los_Angeles',
  },
  '97202': {
    zip: '97202',
    city: 'Portland',
    state: 'OR',
    latitude: '45.482618',
    longitude: '-122.636962',
    timeZoneId: 'America/Los_Angeles',
  },
  '97203': {
    zip: '97203',
    city: 'Portland',
    state: 'OR',
    latitude: '45.590951',
    longitude: '-122.740358',
    timeZoneId: 'America/Los_Angeles',
  },
  '97204': {
    zip: '97204',
    city: 'Portland',
    state: 'OR',
    latitude: '45.517959',
    longitude: '-122.672222',
    timeZoneId: 'America/Los_Angeles',
  },
  '97205': {
    zip: '97205',
    city: 'Portland',
    state: 'OR',
    latitude: '45.518251',
    longitude: '-122.687935',
    timeZoneId: 'America/Los_Angeles',
  },
  '97206': {
    zip: '97206',
    city: 'Portland',
    state: 'OR',
    latitude: '45.487218',
    longitude: '-122.598613',
    timeZoneId: 'America/Los_Angeles',
  },
  '97207': {
    zip: '97207',
    city: 'Portland',
    state: 'OR',
    latitude: '45.522892',
    longitude: '-122.684519',
    timeZoneId: 'America/Los_Angeles',
  },
  '97208': {
    zip: '97208',
    city: 'Portland',
    state: 'OR',
    latitude: '45.530692',
    longitude: '-122.565269',
    timeZoneId: 'America/Los_Angeles',
  },
  '97209': {
    zip: '97209',
    city: 'Portland',
    state: 'OR',
    latitude: '45.5318',
    longitude: '-122.681256',
    timeZoneId: 'America/Los_Angeles',
  },
  '97210': {
    zip: '97210',
    city: 'Portland',
    state: 'OR',
    latitude: '45.533955',
    longitude: '-122.714744',
    timeZoneId: 'America/Los_Angeles',
  },
  '97211': {
    zip: '97211',
    city: 'Portland',
    state: 'OR',
    latitude: '45.568836',
    longitude: '-122.642627',
    timeZoneId: 'America/Los_Angeles',
  },
  '97212': {
    zip: '97212',
    city: 'Portland',
    state: 'OR',
    latitude: '45.547387',
    longitude: '-122.637999',
    timeZoneId: 'America/Los_Angeles',
  },
  '97213': {
    zip: '97213',
    city: 'Portland',
    state: 'OR',
    latitude: '45.538',
    longitude: '-122.599744',
    timeZoneId: 'America/Los_Angeles',
  },
  '97214': {
    zip: '97214',
    city: 'Portland',
    state: 'OR',
    latitude: '45.51616',
    longitude: '-122.64234',
    timeZoneId: 'America/Los_Angeles',
  },
  '97215': {
    zip: '97215',
    city: 'Portland',
    state: 'OR',
    latitude: '45.514122',
    longitude: '-122.601049',
    timeZoneId: 'America/Los_Angeles',
  },
  '97216': {
    zip: '97216',
    city: 'Portland',
    state: 'OR',
    latitude: '45.514785',
    longitude: '-122.556713',
    timeZoneId: 'America/Los_Angeles',
  },
  '97217': {
    zip: '97217',
    city: 'Portland',
    state: 'OR',
    latitude: '45.574936',
    longitude: '-122.688735',
    timeZoneId: 'America/Los_Angeles',
  },
  '97218': {
    zip: '97218',
    city: 'Portland',
    state: 'OR',
    latitude: '45.562445',
    longitude: '-122.602923',
    timeZoneId: 'America/Los_Angeles',
  },
  '97219': {
    zip: '97219',
    city: 'Portland',
    state: 'OR',
    latitude: '45.458253',
    longitude: '-122.699292',
    timeZoneId: 'America/Los_Angeles',
  },
  '97220': {
    zip: '97220',
    city: 'Portland',
    state: 'OR',
    latitude: '45.543396',
    longitude: '-122.553975',
    timeZoneId: 'America/Los_Angeles',
  },
  '97221': {
    zip: '97221',
    city: 'Portland',
    state: 'OR',
    latitude: '45.492836',
    longitude: '-122.730269',
    timeZoneId: 'America/Los_Angeles',
  },
  '97222': {
    zip: '97222',
    city: 'Portland',
    state: 'OR',
    latitude: '45.437695',
    longitude: '-122.614273',
    timeZoneId: 'America/Los_Angeles',
  },
  '97223': {
    zip: '97223',
    city: 'Portland',
    state: 'OR',
    latitude: '45.43889',
    longitude: '-122.772276',
    timeZoneId: 'America/Los_Angeles',
  },
  '97224': {
    zip: '97224',
    city: 'Portland',
    state: 'OR',
    latitude: '45.408703',
    longitude: '-122.789445',
    timeZoneId: 'America/Los_Angeles',
  },
  '97225': {
    zip: '97225',
    city: 'Portland',
    state: 'OR',
    latitude: '45.501041',
    longitude: '-122.761269',
    timeZoneId: 'America/Los_Angeles',
  },
  '97227': {
    zip: '97227',
    city: 'Portland',
    state: 'OR',
    latitude: '45.54538',
    longitude: '-122.67553',
    timeZoneId: 'America/Los_Angeles',
  },
  '97228': {
    zip: '97228',
    city: 'Portland',
    state: 'OR',
    latitude: '45.529912',
    longitude: '-122.682581',
    timeZoneId: 'America/Los_Angeles',
  },
  '97229': {
    zip: '97229',
    city: 'Portland',
    state: 'OR',
    latitude: '45.540884',
    longitude: '-122.82998',
    timeZoneId: 'America/Los_Angeles',
  },
  '97230': {
    zip: '97230',
    city: 'Portland',
    state: 'OR',
    latitude: '45.5414',
    longitude: '-122.498239',
    timeZoneId: 'America/Los_Angeles',
  },
  '97231': {
    zip: '97231',
    city: 'Portland',
    state: 'OR',
    latitude: '45.633419',
    longitude: '-122.856362',
    timeZoneId: 'America/Los_Angeles',
  },
  '97232': {
    zip: '97232',
    city: 'Portland',
    state: 'OR',
    latitude: '45.530982',
    longitude: '-122.632832',
    timeZoneId: 'America/Los_Angeles',
  },
  '97233': {
    zip: '97233',
    city: 'Portland',
    state: 'OR',
    latitude: '45.514315',
    longitude: '-122.503676',
    timeZoneId: 'America/Los_Angeles',
  },
  '97236': {
    zip: '97236',
    city: 'Portland',
    state: 'OR',
    latitude: '45.488127',
    longitude: '-122.507963',
    timeZoneId: 'America/Los_Angeles',
  },
  '97238': {
    zip: '97238',
    city: 'Portland',
    state: 'OR',
    latitude: '45.576436',
    longitude: '-122.60045',
    timeZoneId: 'America/Los_Angeles',
  },
  '97239': {
    zip: '97239',
    city: 'Portland',
    state: 'OR',
    latitude: '45.499027',
    longitude: '-122.688364',
    timeZoneId: 'America/Los_Angeles',
  },
  '97240': {
    zip: '97240',
    city: 'Portland',
    state: 'OR',
    latitude: '45.502418',
    longitude: '-122.656443',
    timeZoneId: 'America/Los_Angeles',
  },
  '97242': {
    zip: '97242',
    city: 'Portland',
    state: 'OR',
    latitude: '45.483262',
    longitude: '-122.63843',
    timeZoneId: 'America/Los_Angeles',
  },
  '97250': {
    zip: '97250',
    city: 'Portland',
    state: 'OR',
    latitude: '45.526493',
    longitude: '-122.677228',
    timeZoneId: 'America/Los_Angeles',
  },
  '97251': {
    zip: '97251',
    city: 'Portland',
    state: 'OR',
    latitude: '45.529145',
    longitude: '-122.663278',
    timeZoneId: 'America/Los_Angeles',
  },
  '97252': {
    zip: '97252',
    city: 'Portland',
    state: 'OR',
    latitude: '45.528049',
    longitude: '-122.663158',
    timeZoneId: 'America/Los_Angeles',
  },
  '97253': {
    zip: '97253',
    city: 'Portland',
    state: 'OR',
    latitude: '45.522058',
    longitude: '-122.683912',
    timeZoneId: 'America/Los_Angeles',
  },
  '97254': {
    zip: '97254',
    city: 'Portland',
    state: 'OR',
    latitude: '45.517459',
    longitude: '-122.729548',
    timeZoneId: 'America/Los_Angeles',
  },
  '97256': {
    zip: '97256',
    city: 'Portland',
    state: 'OR',
    latitude: '45.515988',
    longitude: '-122.680938',
    timeZoneId: 'America/Los_Angeles',
  },
  '97258': {
    zip: '97258',
    city: 'Portland',
    state: 'OR',
    latitude: '45.513289',
    longitude: '-122.674551',
    timeZoneId: 'America/Los_Angeles',
  },
  '97266': {
    zip: '97266',
    city: 'Portland',
    state: 'OR',
    latitude: '45.48485',
    longitude: '-122.557005',
    timeZoneId: 'America/Los_Angeles',
  },
  '97267': {
    zip: '97267',
    city: 'Portland',
    state: 'OR',
    latitude: '45.40746',
    longitude: '-122.610386',
    timeZoneId: 'America/Los_Angeles',
  },
  '97268': {
    zip: '97268',
    city: 'Portland',
    state: 'OR',
    latitude: '45.416944',
    longitude: '-122.638889',
    timeZoneId: 'America/Los_Angeles',
  },
  '97269': {
    zip: '97269',
    city: 'Portland',
    state: 'OR',
    latitude: '45.438452',
    longitude: '-122.611322',
    timeZoneId: 'America/Los_Angeles',
  },
  '97280': {
    zip: '97280',
    city: 'Portland',
    state: 'OR',
    latitude: '45.453538',
    longitude: '-122.698843',
    timeZoneId: 'America/Los_Angeles',
  },
  '97281': {
    zip: '97281',
    city: 'Portland',
    state: 'OR',
    latitude: '45.441124',
    longitude: '-122.779329',
    timeZoneId: 'America/Los_Angeles',
  },
  '97282': {
    zip: '97282',
    city: 'Portland',
    state: 'OR',
    latitude: '45.481652',
    longitude: '-122.639279',
    timeZoneId: 'America/Los_Angeles',
  },
  '97283': {
    zip: '97283',
    city: 'Portland',
    state: 'OR',
    latitude: '45.537884',
    longitude: '-122.654515',
    timeZoneId: 'America/Los_Angeles',
  },
  '97286': {
    zip: '97286',
    city: 'Portland',
    state: 'OR',
    latitude: '45.479834',
    longitude: '-122.597889',
    timeZoneId: 'America/Los_Angeles',
  },
  '97290': {
    zip: '97290',
    city: 'Portland',
    state: 'OR',
    latitude: '45.574143',
    longitude: '-122.643315',
    timeZoneId: 'America/Los_Angeles',
  },
  '97291': {
    zip: '97291',
    city: 'Portland',
    state: 'OR',
    latitude: '45.54738',
    longitude: '-122.726171',
    timeZoneId: 'America/Los_Angeles',
  },
  '97292': {
    zip: '97292',
    city: 'Portland',
    state: 'OR',
    latitude: '45.515433',
    longitude: '-122.555436',
    timeZoneId: 'America/Los_Angeles',
  },
  '97293': {
    zip: '97293',
    city: 'Portland',
    state: 'OR',
    latitude: '45.511217',
    longitude: '-122.641339',
    timeZoneId: 'America/Los_Angeles',
  },
  '97294': {
    zip: '97294',
    city: 'Portland',
    state: 'OR',
    latitude: '45.542313',
    longitude: '-122.496847',
    timeZoneId: 'America/Los_Angeles',
  },
  '97296': {
    zip: '97296',
    city: 'Portland',
    state: 'OR',
    latitude: '45.531999',
    longitude: '-122.773414',
    timeZoneId: 'America/Los_Angeles',
  },
  '97298': {
    zip: '97298',
    city: 'Portland',
    state: 'OR',
    latitude: '45.501951',
    longitude: '-122.773041',
    timeZoneId: 'America/Los_Angeles',
  },
  '97301': {
    zip: '97301',
    city: 'Salem',
    state: 'OR',
    latitude: '44.928656',
    longitude: '-122.960707',
    timeZoneId: 'America/Los_Angeles',
  },
  '97302': {
    zip: '97302',
    city: 'Salem',
    state: 'OR',
    latitude: '44.900455',
    longitude: '-123.045639',
    timeZoneId: 'America/Los_Angeles',
  },
  '97303': {
    zip: '97303',
    city: 'Salem',
    state: 'OR',
    latitude: '45.01182',
    longitude: '-123.01566',
    timeZoneId: 'America/Los_Angeles',
  },
  '97304': {
    zip: '97304',
    city: 'Salem',
    state: 'OR',
    latitude: '45.01308',
    longitude: '-123.10128',
    timeZoneId: 'America/Los_Angeles',
  },
  '97305': {
    zip: '97305',
    city: 'Salem',
    state: 'OR',
    latitude: '45.0165',
    longitude: '-122.91222',
    timeZoneId: 'America/Los_Angeles',
  },
  '97306': {
    zip: '97306',
    city: 'Salem',
    state: 'OR',
    latitude: '44.864263',
    longitude: '-123.050408',
    timeZoneId: 'America/Los_Angeles',
  },
  '97307': {
    zip: '97307',
    city: 'Keizer',
    state: 'OR',
    latitude: '45.006936',
    longitude: '-123.019795',
    timeZoneId: 'America/Los_Angeles',
  },
  '97308': {
    zip: '97308',
    city: 'Salem',
    state: 'OR',
    latitude: '44.899188',
    longitude: '-122.928252',
    timeZoneId: 'America/Los_Angeles',
  },
  '97309': {
    zip: '97309',
    city: 'Salem',
    state: 'OR',
    latitude: '44.925296',
    longitude: '-123.01334',
    timeZoneId: 'America/Los_Angeles',
  },
  '97310': {
    zip: '97310',
    city: 'Salem',
    state: 'OR',
    latitude: '44.9105',
    longitude: '-123.009129',
    timeZoneId: 'America/Los_Angeles',
  },
  '97311': {
    zip: '97311',
    city: 'Salem',
    state: 'OR',
    latitude: '44.943754',
    longitude: '-123.02807',
    timeZoneId: 'America/Los_Angeles',
  },
  '97312': {
    zip: '97312',
    city: 'Salem',
    state: 'OR',
    latitude: '44.893155',
    longitude: '-123.060071',
    timeZoneId: 'America/Los_Angeles',
  },
  '97314': {
    zip: '97314',
    city: 'Salem',
    state: 'OR',
    latitude: '44.96696',
    longitude: '-123.004587',
    timeZoneId: 'America/Los_Angeles',
  },
  '97317': {
    zip: '97317',
    city: 'Salem',
    state: 'OR',
    latitude: '44.966504',
    longitude: '-123.003598',
    timeZoneId: 'America/Los_Angeles',
  },
  '97321': {
    zip: '97321',
    city: 'Albany',
    state: 'OR',
    latitude: '44.6256',
    longitude: '-123.06642',
    timeZoneId: 'America/Los_Angeles',
  },
  '97322': {
    zip: '97322',
    city: 'Albany',
    state: 'OR',
    latitude: '44.623227',
    longitude: '-123.058551',
    timeZoneId: 'America/Los_Angeles',
  },
  '97324': {
    zip: '97324',
    city: 'Alsea',
    state: 'OR',
    latitude: '44.366223',
    longitude: '-123.614824',
    timeZoneId: 'America/Los_Angeles',
  },
  '97325': {
    zip: '97325',
    city: 'Aumsville',
    state: 'OR',
    latitude: '44.838831',
    longitude: '-122.858581',
    timeZoneId: 'America/Los_Angeles',
  },
  '97326': {
    zip: '97326',
    city: 'Blodgett',
    state: 'OR',
    latitude: '44.641659',
    longitude: '-123.552667',
    timeZoneId: 'America/Los_Angeles',
  },
  '97327': {
    zip: '97327',
    city: 'Brownsville',
    state: 'OR',
    latitude: '44.388829',
    longitude: '-122.966802',
    timeZoneId: 'America/Los_Angeles',
  },
  '97329': {
    zip: '97329',
    city: 'Cascadia',
    state: 'OR',
    latitude: '44.390734',
    longitude: '-122.505607',
    timeZoneId: 'America/Los_Angeles',
  },
  '97330': {
    zip: '97330',
    city: 'Corvallis',
    state: 'OR',
    latitude: '44.603787',
    longitude: '-123.273273',
    timeZoneId: 'America/Los_Angeles',
  },
  '97331': {
    zip: '97331',
    city: 'Corvallis',
    state: 'OR',
    latitude: '44.565718',
    longitude: '-123.280906',
    timeZoneId: 'America/Los_Angeles',
  },
  '97333': {
    zip: '97333',
    city: 'Corvallis',
    state: 'OR',
    latitude: '44.521146',
    longitude: '-123.295667',
    timeZoneId: 'America/Los_Angeles',
  },
  '97335': {
    zip: '97335',
    city: 'Crabtree',
    state: 'OR',
    latitude: '44.635357',
    longitude: '-122.898705',
    timeZoneId: 'America/Los_Angeles',
  },
  '97336': {
    zip: '97336',
    city: 'Crawfordsville',
    state: 'OR',
    latitude: '44.358322',
    longitude: '-122.851361',
    timeZoneId: 'America/Los_Angeles',
  },
  '97338': {
    zip: '97338',
    city: 'Dallas',
    state: 'OR',
    latitude: '44.922171',
    longitude: '-123.326623',
    timeZoneId: 'America/Los_Angeles',
  },
  '97339': {
    zip: '97339',
    city: 'Corvallis',
    state: 'OR',
    latitude: '44.561601',
    longitude: '-123.268633',
    timeZoneId: 'America/Los_Angeles',
  },
  '97341': {
    zip: '97341',
    city: 'Depoe Bay',
    state: 'OR',
    latitude: '44.82708',
    longitude: '-124.04364',
    timeZoneId: 'America/Los_Angeles',
  },
  '97342': {
    zip: '97342',
    city: 'Detroit',
    state: 'OR',
    latitude: '44.73148',
    longitude: '-122.146785',
    timeZoneId: 'America/Los_Angeles',
  },
  '97343': {
    zip: '97343',
    city: 'Eddyville',
    state: 'OR',
    latitude: '44.58576',
    longitude: '-123.77736',
    timeZoneId: 'America/Los_Angeles',
  },
  '97344': {
    zip: '97344',
    city: 'Falls City',
    state: 'OR',
    latitude: '44.866851',
    longitude: '-123.444396',
    timeZoneId: 'America/Los_Angeles',
  },
  '97345': {
    zip: '97345',
    city: 'Foster',
    state: 'OR',
    latitude: '44.424688',
    longitude: '-122.571043',
    timeZoneId: 'America/Los_Angeles',
  },
  '97346': {
    zip: '97346',
    city: 'Gates',
    state: 'OR',
    latitude: '44.75325',
    longitude: '-122.382624',
    timeZoneId: 'America/Los_Angeles',
  },
  '97347': {
    zip: '97347',
    city: 'Grand Ronde',
    state: 'OR',
    latitude: '45.056652',
    longitude: '-123.632496',
    timeZoneId: 'America/Los_Angeles',
  },
  '97348': {
    zip: '97348',
    city: 'Halsey',
    state: 'OR',
    latitude: '44.384368',
    longitude: '-123.121631',
    timeZoneId: 'America/Los_Angeles',
  },
  '97350': {
    zip: '97350',
    city: 'Idanha',
    state: 'OR',
    latitude: '44.700587',
    longitude: '-122.071486',
    timeZoneId: 'America/Los_Angeles',
  },
  '97351': {
    zip: '97351',
    city: 'Independence',
    state: 'OR',
    latitude: '44.843322',
    longitude: '-123.185997',
    timeZoneId: 'America/Los_Angeles',
  },
  '97352': {
    zip: '97352',
    city: 'Jefferson',
    state: 'OR',
    latitude: '44.740581',
    longitude: '-123.021516',
    timeZoneId: 'America/Los_Angeles',
  },
  '97355': {
    zip: '97355',
    city: 'Lebanon',
    state: 'OR',
    latitude: '44.529419',
    longitude: '-122.872642',
    timeZoneId: 'America/Los_Angeles',
  },
  '97357': {
    zip: '97357',
    city: 'Logsden',
    state: 'OR',
    latitude: '44.75158',
    longitude: '-123.815364',
    timeZoneId: 'America/Los_Angeles',
  },
  '97358': {
    zip: '97358',
    city: 'Lyons',
    state: 'OR',
    latitude: '44.767792',
    longitude: '-122.604763',
    timeZoneId: 'America/Los_Angeles',
  },
  '97360': {
    zip: '97360',
    city: 'Mill City',
    state: 'OR',
    latitude: '44.751239',
    longitude: '-122.480247',
    timeZoneId: 'America/Los_Angeles',
  },
  '97361': {
    zip: '97361',
    city: 'Monmouth',
    state: 'OR',
    latitude: '44.813075',
    longitude: '-123.295883',
    timeZoneId: 'America/Los_Angeles',
  },
  '97362': {
    zip: '97362',
    city: 'Mount Angel',
    state: 'OR',
    latitude: '45.068098',
    longitude: '-122.780735',
    timeZoneId: 'America/Los_Angeles',
  },
  '97364': {
    zip: '97364',
    city: 'Neotsu',
    state: 'OR',
    latitude: '44.996636',
    longitude: '-123.985765',
    timeZoneId: 'America/Los_Angeles',
  },
  '97365': {
    zip: '97365',
    city: 'Newport',
    state: 'OR',
    latitude: '44.687599',
    longitude: '-124.007012',
    timeZoneId: 'America/Los_Angeles',
  },
  '97366': {
    zip: '97366',
    city: 'South Beach',
    state: 'OR',
    latitude: '44.553071',
    longitude: '-124.04175',
    timeZoneId: 'America/Los_Angeles',
  },
  '97367': {
    zip: '97367',
    city: 'Lincoln City',
    state: 'OR',
    latitude: '44.9622',
    longitude: '-123.95274',
    timeZoneId: 'America/Los_Angeles',
  },
  '97368': {
    zip: '97368',
    city: 'Otis',
    state: 'OR',
    latitude: '45.0024',
    longitude: '-123.90528',
    timeZoneId: 'America/Los_Angeles',
  },
  '97369': {
    zip: '97369',
    city: 'Otter Rock',
    state: 'OR',
    latitude: '44.747559',
    longitude: '-124.060606',
    timeZoneId: 'America/Los_Angeles',
  },
  '97370': {
    zip: '97370',
    city: 'Philomath',
    state: 'OR',
    latitude: '44.548384',
    longitude: '-123.4092',
    timeZoneId: 'America/Los_Angeles',
  },
  '97371': {
    zip: '97371',
    city: 'Rickreall',
    state: 'OR',
    latitude: '44.985103',
    longitude: '-123.20254',
    timeZoneId: 'America/Los_Angeles',
  },
  '97373': {
    zip: '97373',
    city: 'Saint Benedict',
    state: 'OR',
    latitude: '45.056509',
    longitude: '-122.784435',
    timeZoneId: 'America/Los_Angeles',
  },
  '97374': {
    zip: '97374',
    city: 'Scio',
    state: 'OR',
    latitude: '44.69275',
    longitude: '-122.803046',
    timeZoneId: 'America/Los_Angeles',
  },
  '97375': {
    zip: '97375',
    city: 'Scotts Mills',
    state: 'OR',
    latitude: '45.005656',
    longitude: '-122.641838',
    timeZoneId: 'America/Los_Angeles',
  },
  '97376': {
    zip: '97376',
    city: 'Seal Rock',
    state: 'OR',
    latitude: '44.499453',
    longitude: '-124.055888',
    timeZoneId: 'America/Los_Angeles',
  },
  '97377': {
    zip: '97377',
    city: 'Shedd',
    state: 'OR',
    latitude: '44.465799',
    longitude: '-123.1106',
    timeZoneId: 'America/Los_Angeles',
  },
  '97378': {
    zip: '97378',
    city: 'Sheridan',
    state: 'OR',
    latitude: '45.116608',
    longitude: '-123.394851',
    timeZoneId: 'America/Los_Angeles',
  },
  '97380': {
    zip: '97380',
    city: 'Siletz',
    state: 'OR',
    latitude: '44.730625',
    longitude: '-123.911269',
    timeZoneId: 'America/Los_Angeles',
  },
  '97381': {
    zip: '97381',
    city: 'Silverton',
    state: 'OR',
    latitude: '44.895069',
    longitude: '-122.564126',
    timeZoneId: 'America/Los_Angeles',
  },
  '97383': {
    zip: '97383',
    city: 'Stayton',
    state: 'OR',
    latitude: '44.803277',
    longitude: '-122.757865',
    timeZoneId: 'America/Los_Angeles',
  },
  '97384': {
    zip: '97384',
    city: 'Mehama',
    state: 'OR',
    latitude: '44.790049',
    longitude: '-122.598524',
    timeZoneId: 'America/Los_Angeles',
  },
  '97385': {
    zip: '97385',
    city: 'Sublimity',
    state: 'OR',
    latitude: '44.861115',
    longitude: '-122.762241',
    timeZoneId: 'America/Los_Angeles',
  },
  '97386': {
    zip: '97386',
    city: 'Sweet Home',
    state: 'OR',
    latitude: '44.393111',
    longitude: '-122.735453',
    timeZoneId: 'America/Los_Angeles',
  },
  '97388': {
    zip: '97388',
    city: 'Gleneden Beach',
    state: 'OR',
    latitude: '44.884662',
    longitude: '-124.031114',
    timeZoneId: 'America/Los_Angeles',
  },
  '97389': {
    zip: '97389',
    city: 'Tangent',
    state: 'OR',
    latitude: '44.537782',
    longitude: '-123.101943',
    timeZoneId: 'America/Los_Angeles',
  },
  '97390': {
    zip: '97390',
    city: 'Tidewater',
    state: 'OR',
    latitude: '44.387184',
    longitude: '-123.893829',
    timeZoneId: 'America/Los_Angeles',
  },
  '97391': {
    zip: '97391',
    city: 'Toledo',
    state: 'OR',
    latitude: '44.627249',
    longitude: '-123.932217',
    timeZoneId: 'America/Los_Angeles',
  },
  '97392': {
    zip: '97392',
    city: 'Turner',
    state: 'OR',
    latitude: '44.810901',
    longitude: '-122.944478',
    timeZoneId: 'America/Los_Angeles',
  },
  '97394': {
    zip: '97394',
    city: 'Waldport',
    state: 'OR',
    latitude: '44.42145',
    longitude: '-124.047173',
    timeZoneId: 'America/Los_Angeles',
  },
  '97396': {
    zip: '97396',
    city: 'Willamina',
    state: 'OR',
    latitude: '45.11986',
    longitude: '-123.538659',
    timeZoneId: 'America/Los_Angeles',
  },
  '97401': {
    zip: '97401',
    city: 'Eugene',
    state: 'OR',
    latitude: '44.080533',
    longitude: '-123.073047',
    timeZoneId: 'America/Los_Angeles',
  },
  '97402': {
    zip: '97402',
    city: 'Eugene',
    state: 'OR',
    latitude: '44.057393',
    longitude: '-123.188424',
    timeZoneId: 'America/Los_Angeles',
  },
  '97403': {
    zip: '97403',
    city: 'Eugene',
    state: 'OR',
    latitude: '44.037363',
    longitude: '-123.052143',
    timeZoneId: 'America/Los_Angeles',
  },
  '97404': {
    zip: '97404',
    city: 'Eugene',
    state: 'OR',
    latitude: '44.103156',
    longitude: '-123.135275',
    timeZoneId: 'America/Los_Angeles',
  },
  '97405': {
    zip: '97405',
    city: 'Eugene',
    state: 'OR',
    latitude: '43.94946',
    longitude: '-123.12804',
    timeZoneId: 'America/Los_Angeles',
  },
  '97406': {
    zip: '97406',
    city: 'Agness',
    state: 'OR',
    latitude: '42.60843',
    longitude: '-124.074122',
    timeZoneId: 'America/Los_Angeles',
  },
  '97407': {
    zip: '97407',
    city: 'Allegany',
    state: 'OR',
    latitude: '43.42519',
    longitude: '-124.033608',
    timeZoneId: 'America/Los_Angeles',
  },
  '97408': {
    zip: '97408',
    city: 'Eugene',
    state: 'OR',
    latitude: '44.0541',
    longitude: '-123.09198',
    timeZoneId: 'America/Los_Angeles',
  },
  '97409': {
    zip: '97409',
    city: 'Alvadore',
    state: 'OR',
    latitude: '44.12855',
    longitude: '-123.267113',
    timeZoneId: 'America/Los_Angeles',
  },
  '97410': {
    zip: '97410',
    city: 'Azalea',
    state: 'OR',
    latitude: '42.792077',
    longitude: '-123.220657',
    timeZoneId: 'America/Los_Angeles',
  },
  '97411': {
    zip: '97411',
    city: 'Bandon',
    state: 'OR',
    latitude: '43.117384',
    longitude: '-124.407217',
    timeZoneId: 'America/Los_Angeles',
  },
  '97412': {
    zip: '97412',
    city: 'Blachly',
    state: 'OR',
    latitude: '44.18586',
    longitude: '-123.53334',
    timeZoneId: 'America/Los_Angeles',
  },
  '97413': {
    zip: '97413',
    city: 'Blue River',
    state: 'OR',
    latitude: '44.179665',
    longitude: '-122.120204',
    timeZoneId: 'America/Los_Angeles',
  },
  '97414': {
    zip: '97414',
    city: 'Broadbent',
    state: 'OR',
    latitude: '42.981009',
    longitude: '-124.122107',
    timeZoneId: 'America/Los_Angeles',
  },
  '97415': {
    zip: '97415',
    city: 'Brookings',
    state: 'OR',
    latitude: '42.054175',
    longitude: '-124.260145',
    timeZoneId: 'America/Los_Angeles',
  },
  '97416': {
    zip: '97416',
    city: 'Camas Valley',
    state: 'OR',
    latitude: '43.039108',
    longitude: '-123.682959',
    timeZoneId: 'America/Los_Angeles',
  },
  '97417': {
    zip: '97417',
    city: 'Canyonville',
    state: 'OR',
    latitude: '42.934716',
    longitude: '-123.271666',
    timeZoneId: 'America/Los_Angeles',
  },
  '97419': {
    zip: '97419',
    city: 'Cheshire',
    state: 'OR',
    latitude: '44.171738',
    longitude: '-123.374306',
    timeZoneId: 'America/Los_Angeles',
  },
  '97420': {
    zip: '97420',
    city: 'Coos Bay',
    state: 'OR',
    latitude: '43.3329',
    longitude: '-124.2339',
    timeZoneId: 'America/Los_Angeles',
  },
  '97423': {
    zip: '97423',
    city: 'Coquille',
    state: 'OR',
    latitude: '43.18374',
    longitude: '-124.2084',
    timeZoneId: 'America/Los_Angeles',
  },
  '97424': {
    zip: '97424',
    city: 'Cottage Grove',
    state: 'OR',
    latitude: '43.784169',
    longitude: '-123.057967',
    timeZoneId: 'America/Los_Angeles',
  },
  '97426': {
    zip: '97426',
    city: 'Creswell',
    state: 'OR',
    latitude: '43.907812',
    longitude: '-123.034345',
    timeZoneId: 'America/Los_Angeles',
  },
  '97429': {
    zip: '97429',
    city: 'Days Creek',
    state: 'OR',
    latitude: '42.980125',
    longitude: '-123.101768',
    timeZoneId: 'America/Los_Angeles',
  },
  '97430': {
    zip: '97430',
    city: 'Deadwood',
    state: 'OR',
    latitude: '44.15142',
    longitude: '-123.71208',
    timeZoneId: 'America/Los_Angeles',
  },
  '97431': {
    zip: '97431',
    city: 'Dexter',
    state: 'OR',
    latitude: '43.907365',
    longitude: '-122.826298',
    timeZoneId: 'America/Los_Angeles',
  },
  '97432': {
    zip: '97432',
    city: 'Dillard',
    state: 'OR',
    latitude: '43.167162',
    longitude: '-123.448322',
    timeZoneId: 'America/Los_Angeles',
  },
  '97434': {
    zip: '97434',
    city: 'Dorena',
    state: 'OR',
    latitude: '43.739244',
    longitude: '-122.888624',
    timeZoneId: 'America/Los_Angeles',
  },
  '97435': {
    zip: '97435',
    city: 'Drain',
    state: 'OR',
    latitude: '43.654055',
    longitude: '-123.362827',
    timeZoneId: 'America/Los_Angeles',
  },
  '97436': {
    zip: '97436',
    city: 'Elkton',
    state: 'OR',
    latitude: '43.638237',
    longitude: '-123.592008',
    timeZoneId: 'America/Los_Angeles',
  },
  '97437': {
    zip: '97437',
    city: 'Elmira',
    state: 'OR',
    latitude: '44.093311',
    longitude: '-123.382288',
    timeZoneId: 'America/Los_Angeles',
  },
  '97438': {
    zip: '97438',
    city: 'Fall Creek',
    state: 'OR',
    latitude: '43.9575',
    longitude: '-122.70342',
    timeZoneId: 'America/Los_Angeles',
  },
  '97439': {
    zip: '97439',
    city: 'Florence',
    state: 'OR',
    latitude: '44.00886',
    longitude: '-124.03626',
    timeZoneId: 'America/Los_Angeles',
  },
  '97440': {
    zip: '97440',
    city: 'Eugene',
    state: 'OR',
    latitude: '44.060728',
    longitude: '-123.113894',
    timeZoneId: 'America/Los_Angeles',
  },
  '97441': {
    zip: '97441',
    city: 'Gardiner',
    state: 'OR',
    latitude: '43.730728',
    longitude: '-124.106157',
    timeZoneId: 'America/Los_Angeles',
  },
  '97442': {
    zip: '97442',
    city: 'Glendale',
    state: 'OR',
    latitude: '42.754694',
    longitude: '-123.382926',
    timeZoneId: 'America/Los_Angeles',
  },
  '97443': {
    zip: '97443',
    city: 'Glide',
    state: 'OR',
    latitude: '43.275871',
    longitude: '-123.07386',
    timeZoneId: 'America/Los_Angeles',
  },
  '97444': {
    zip: '97444',
    city: 'Gold Beach',
    state: 'OR',
    latitude: '42.4344',
    longitude: '-124.36686',
    timeZoneId: 'America/Los_Angeles',
  },
  '97446': {
    zip: '97446',
    city: 'Harrisburg',
    state: 'OR',
    latitude: '44.27283',
    longitude: '-123.113964',
    timeZoneId: 'America/Los_Angeles',
  },
  '97447': {
    zip: '97447',
    city: 'Idleyld Park',
    state: 'OR',
    latitude: '43.326044',
    longitude: '-123.026706',
    timeZoneId: 'America/Los_Angeles',
  },
  '97448': {
    zip: '97448',
    city: 'Junction City',
    state: 'OR',
    latitude: '44.195781',
    longitude: '-123.252848',
    timeZoneId: 'America/Los_Angeles',
  },
  '97449': {
    zip: '97449',
    city: 'Lakeside',
    state: 'OR',
    latitude: '43.581867',
    longitude: '-124.159058',
    timeZoneId: 'America/Los_Angeles',
  },
  '97450': {
    zip: '97450',
    city: 'Langlois',
    state: 'OR',
    latitude: '42.915704',
    longitude: '-124.427829',
    timeZoneId: 'America/Los_Angeles',
  },
  '97451': {
    zip: '97451',
    city: 'Lorane',
    state: 'OR',
    latitude: '43.818474',
    longitude: '-123.243039',
    timeZoneId: 'America/Los_Angeles',
  },
  '97452': {
    zip: '97452',
    city: 'Lowell',
    state: 'OR',
    latitude: '43.913554',
    longitude: '-122.765716',
    timeZoneId: 'America/Los_Angeles',
  },
  '97453': {
    zip: '97453',
    city: 'Mapleton',
    state: 'OR',
    latitude: '44.03532',
    longitude: '-123.862351',
    timeZoneId: 'America/Los_Angeles',
  },
  '97454': {
    zip: '97454',
    city: 'Marcola',
    state: 'OR',
    latitude: '44.207589',
    longitude: '-122.816542',
    timeZoneId: 'America/Los_Angeles',
  },
  '97455': {
    zip: '97455',
    city: 'Pleasant Hill',
    state: 'OR',
    latitude: '43.970221',
    longitude: '-122.916439',
    timeZoneId: 'America/Los_Angeles',
  },
  '97456': {
    zip: '97456',
    city: 'Monroe',
    state: 'OR',
    latitude: '44.32434',
    longitude: '-123.31914',
    timeZoneId: 'America/Los_Angeles',
  },
  '97457': {
    zip: '97457',
    city: 'Myrtle Creek',
    state: 'OR',
    latitude: '43.026142',
    longitude: '-123.281038',
    timeZoneId: 'America/Los_Angeles',
  },
  '97458': {
    zip: '97458',
    city: 'Myrtle Point',
    state: 'OR',
    latitude: '43.1148',
    longitude: '-124.1568',
    timeZoneId: 'America/Los_Angeles',
  },
  '97459': {
    zip: '97459',
    city: 'North Bend',
    state: 'OR',
    latitude: '43.436241',
    longitude: '-124.214978',
    timeZoneId: 'America/Los_Angeles',
  },
  '97461': {
    zip: '97461',
    city: 'Noti',
    state: 'OR',
    latitude: '44.096571',
    longitude: '-123.465682',
    timeZoneId: 'America/Los_Angeles',
  },
  '97462': {
    zip: '97462',
    city: 'Oakland',
    state: 'OR',
    latitude: '43.46688',
    longitude: '-123.36624',
    timeZoneId: 'America/Los_Angeles',
  },
  '97463': {
    zip: '97463',
    city: 'Oakridge',
    state: 'OR',
    latitude: '43.746949',
    longitude: '-122.465772',
    timeZoneId: 'America/Los_Angeles',
  },
  '97464': {
    zip: '97464',
    city: 'Ophir',
    state: 'OR',
    latitude: '42.502068',
    longitude: '-124.412467',
    timeZoneId: 'America/Los_Angeles',
  },
  '97465': {
    zip: '97465',
    city: 'Port Orford',
    state: 'OR',
    latitude: '42.760002',
    longitude: '-124.486419',
    timeZoneId: 'America/Los_Angeles',
  },
  '97466': {
    zip: '97466',
    city: 'Powers',
    state: 'OR',
    latitude: '42.882288',
    longitude: '-124.073274',
    timeZoneId: 'America/Los_Angeles',
  },
  '97467': {
    zip: '97467',
    city: 'Reedsport',
    state: 'OR',
    latitude: '43.70994',
    longitude: '-123.97374',
    timeZoneId: 'America/Los_Angeles',
  },
  '97469': {
    zip: '97469',
    city: 'Riddle',
    state: 'OR',
    latitude: '42.953638',
    longitude: '-123.37115',
    timeZoneId: 'America/Los_Angeles',
  },
  '97470': {
    zip: '97470',
    city: 'Roseburg',
    state: 'OR',
    latitude: '43.223282',
    longitude: '-123.36627',
    timeZoneId: 'America/Los_Angeles',
  },
  '97471': {
    zip: '97471',
    city: 'Roseburg',
    state: 'OR',
    latitude: '43.223734',
    longitude: '-123.367345',
    timeZoneId: 'America/Los_Angeles',
  },
  '97473': {
    zip: '97473',
    city: 'Scottsburg',
    state: 'OR',
    latitude: '43.664497',
    longitude: '-123.79264',
    timeZoneId: 'America/Los_Angeles',
  },
  '97475': {
    zip: '97475',
    city: 'Springfield',
    state: 'OR',
    latitude: '44.065622',
    longitude: '-123.014262',
    timeZoneId: 'America/Los_Angeles',
  },
  '97476': {
    zip: '97476',
    city: 'Sixes',
    state: 'OR',
    latitude: '42.81679',
    longitude: '-124.456383',
    timeZoneId: 'America/Los_Angeles',
  },
  '97477': {
    zip: '97477',
    city: 'Springfield',
    state: 'OR',
    latitude: '44.065622',
    longitude: '-123.014262',
    timeZoneId: 'America/Los_Angeles',
  },
  '97478': {
    zip: '97478',
    city: 'Springfield',
    state: 'OR',
    latitude: '44.059336',
    longitude: '-122.896044',
    timeZoneId: 'America/Los_Angeles',
  },
  '97479': {
    zip: '97479',
    city: 'Sutherlin',
    state: 'OR',
    latitude: '43.390789',
    longitude: '-123.296484',
    timeZoneId: 'America/Los_Angeles',
  },
  '97480': {
    zip: '97480',
    city: 'Swisshome',
    state: 'OR',
    latitude: '44.055425',
    longitude: '-123.740628',
    timeZoneId: 'America/Los_Angeles',
  },
  '97481': {
    zip: '97481',
    city: 'Tenmile',
    state: 'OR',
    latitude: '43.091401',
    longitude: '-123.564457',
    timeZoneId: 'America/Los_Angeles',
  },
  '97484': {
    zip: '97484',
    city: 'Tiller',
    state: 'OR',
    latitude: '42.90696',
    longitude: '-122.94324',
    timeZoneId: 'America/Los_Angeles',
  },
  '97486': {
    zip: '97486',
    city: 'Umpqua',
    state: 'OR',
    latitude: '43.389225',
    longitude: '-123.513406',
    timeZoneId: 'America/Los_Angeles',
  },
  '97487': {
    zip: '97487',
    city: 'Veneta',
    state: 'OR',
    latitude: '44.046249',
    longitude: '-123.349478',
    timeZoneId: 'America/Los_Angeles',
  },
  '97488': {
    zip: '97488',
    city: 'Vida',
    state: 'OR',
    latitude: '44.13312',
    longitude: '-122.46336',
    timeZoneId: 'America/Los_Angeles',
  },
  '97489': {
    zip: '97489',
    city: 'Walterville',
    state: 'OR',
    latitude: '44.126309',
    longitude: '-122.633452',
    timeZoneId: 'America/Los_Angeles',
  },
  '97490': {
    zip: '97490',
    city: 'Walton',
    state: 'OR',
    latitude: '44.033871',
    longitude: '-123.594278',
    timeZoneId: 'America/Los_Angeles',
  },
  '97491': {
    zip: '97491',
    city: 'Wedderburn',
    state: 'OR',
    latitude: '42.456811',
    longitude: '-124.402213',
    timeZoneId: 'America/Los_Angeles',
  },
  '97492': {
    zip: '97492',
    city: 'Westfir',
    state: 'OR',
    latitude: '43.754861',
    longitude: '-122.503722',
    timeZoneId: 'America/Los_Angeles',
  },
  '97493': {
    zip: '97493',
    city: 'Westlake',
    state: 'OR',
    latitude: '43.908045',
    longitude: '-124.068901',
    timeZoneId: 'America/Los_Angeles',
  },
  '97494': {
    zip: '97494',
    city: 'Wilbur',
    state: 'OR',
    latitude: '43.317756',
    longitude: '-123.342676',
    timeZoneId: 'America/Los_Angeles',
  },
  '97495': {
    zip: '97495',
    city: 'Winchester',
    state: 'OR',
    latitude: '43.280412',
    longitude: '-123.356002',
    timeZoneId: 'America/Los_Angeles',
  },
  '97496': {
    zip: '97496',
    city: 'Winston',
    state: 'OR',
    latitude: '43.100381',
    longitude: '-123.454748',
    timeZoneId: 'America/Los_Angeles',
  },
  '97497': {
    zip: '97497',
    city: 'Wolf Creek',
    state: 'OR',
    latitude: '42.66078',
    longitude: '-123.38556',
    timeZoneId: 'America/Los_Angeles',
  },
  '97498': {
    zip: '97498',
    city: 'Yachats',
    state: 'OR',
    latitude: '44.32147',
    longitude: '-124.089215',
    timeZoneId: 'America/Los_Angeles',
  },
  '97499': {
    zip: '97499',
    city: 'Yoncalla',
    state: 'OR',
    latitude: '43.59458',
    longitude: '-123.241089',
    timeZoneId: 'America/Los_Angeles',
  },
  '97501': {
    zip: '97501',
    city: 'Medford',
    state: 'OR',
    latitude: '42.314227',
    longitude: '-122.881634',
    timeZoneId: 'America/Los_Angeles',
  },
  '97502': {
    zip: '97502',
    city: 'Central Point',
    state: 'OR',
    latitude: '42.402193',
    longitude: '-122.914829',
    timeZoneId: 'America/Los_Angeles',
  },
  '97503': {
    zip: '97503',
    city: 'White City',
    state: 'OR',
    latitude: '42.418373',
    longitude: '-122.814932',
    timeZoneId: 'America/Los_Angeles',
  },
  '97504': {
    zip: '97504',
    city: 'Medford',
    state: 'OR',
    latitude: '42.335443',
    longitude: '-122.837108',
    timeZoneId: 'America/Los_Angeles',
  },
  '97520': {
    zip: '97520',
    city: 'Ashland',
    state: 'OR',
    latitude: '42.15834',
    longitude: '-122.63382',
    timeZoneId: 'America/Los_Angeles',
  },
  '97522': {
    zip: '97522',
    city: 'Butte Falls',
    state: 'OR',
    latitude: '42.537298',
    longitude: '-122.551271',
    timeZoneId: 'America/Los_Angeles',
  },
  '97523': {
    zip: '97523',
    city: 'Cave Junction',
    state: 'OR',
    latitude: '42.14023',
    longitude: '-123.623029',
    timeZoneId: 'America/Los_Angeles',
  },
  '97524': {
    zip: '97524',
    city: 'Eagle Point',
    state: 'OR',
    latitude: '42.484176',
    longitude: '-122.771386',
    timeZoneId: 'America/Los_Angeles',
  },
  '97525': {
    zip: '97525',
    city: 'Gold Hill',
    state: 'OR',
    latitude: '42.46254',
    longitude: '-123.0882',
    timeZoneId: 'America/Los_Angeles',
  },
  '97526': {
    zip: '97526',
    city: 'Grants Pass',
    state: 'OR',
    latitude: '42.468747',
    longitude: '-123.348364',
    timeZoneId: 'America/Los_Angeles',
  },
  '97527': {
    zip: '97527',
    city: 'Grants Pass',
    state: 'OR',
    latitude: '42.403181',
    longitude: '-123.358175',
    timeZoneId: 'America/Los_Angeles',
  },
  '97528': {
    zip: '97528',
    city: 'Grants Pass',
    state: 'OR',
    latitude: '42.439686',
    longitude: '-123.324718',
    timeZoneId: 'America/Los_Angeles',
  },
  '97530': {
    zip: '97530',
    city: 'Jacksonville',
    state: 'OR',
    latitude: '42.18618',
    longitude: '-123.05652',
    timeZoneId: 'America/Los_Angeles',
  },
  '97531': {
    zip: '97531',
    city: 'Kerby',
    state: 'OR',
    latitude: '42.163056',
    longitude: '-123.646944',
    timeZoneId: 'America/Los_Angeles',
  },
  '97532': {
    zip: '97532',
    city: 'Merlin',
    state: 'OR',
    latitude: '42.530607',
    longitude: '-123.432611',
    timeZoneId: 'America/Los_Angeles',
  },
  '97533': {
    zip: '97533',
    city: 'Murphy',
    state: 'OR',
    latitude: '42.339015',
    longitude: '-123.286308',
    timeZoneId: 'America/Los_Angeles',
  },
  '97534': {
    zip: '97534',
    city: 'O Brien',
    state: 'OR',
    latitude: '42.04386',
    longitude: '-123.71706',
    timeZoneId: 'America/Los_Angeles',
  },
  '97535': {
    zip: '97535',
    city: 'Phoenix',
    state: 'OR',
    latitude: '42.274463',
    longitude: '-122.818314',
    timeZoneId: 'America/Los_Angeles',
  },
  '97536': {
    zip: '97536',
    city: 'Prospect',
    state: 'OR',
    latitude: '42.81144',
    longitude: '-122.45364',
    timeZoneId: 'America/Los_Angeles',
  },
  '97537': {
    zip: '97537',
    city: 'Rogue River',
    state: 'OR',
    latitude: '42.501647',
    longitude: '-123.150155',
    timeZoneId: 'America/Los_Angeles',
  },
  '97538': {
    zip: '97538',
    city: 'Selma',
    state: 'OR',
    latitude: '42.270682',
    longitude: '-123.584681',
    timeZoneId: 'America/Los_Angeles',
  },
  '97539': {
    zip: '97539',
    city: 'Shady Cove',
    state: 'OR',
    latitude: '42.615032',
    longitude: '-122.812277',
    timeZoneId: 'America/Los_Angeles',
  },
  '97540': {
    zip: '97540',
    city: 'Talent',
    state: 'OR',
    latitude: '42.236056',
    longitude: '-122.788905',
    timeZoneId: 'America/Los_Angeles',
  },
  '97541': {
    zip: '97541',
    city: 'Trail',
    state: 'OR',
    latitude: '42.676521',
    longitude: '-122.759724',
    timeZoneId: 'America/Los_Angeles',
  },
  '97543': {
    zip: '97543',
    city: 'Wilderville',
    state: 'OR',
    latitude: '42.439167',
    longitude: '-123.327222',
    timeZoneId: 'America/Los_Angeles',
  },
  '97544': {
    zip: '97544',
    city: 'Williams',
    state: 'OR',
    latitude: '42.22779',
    longitude: '-123.280356',
    timeZoneId: 'America/Los_Angeles',
  },
  '97601': {
    zip: '97601',
    city: 'Klamath Falls',
    state: 'OR',
    latitude: '42.221688',
    longitude: '-121.796627',
    timeZoneId: 'America/Los_Angeles',
  },
  '97602': {
    zip: '97602',
    city: 'Klamath Falls',
    state: 'OR',
    latitude: '42.194576',
    longitude: '-121.655694',
    timeZoneId: 'America/Los_Angeles',
  },
  '97603': {
    zip: '97603',
    city: 'Klamath Falls',
    state: 'OR',
    latitude: '42.195694',
    longitude: '-121.724225',
    timeZoneId: 'America/Los_Angeles',
  },
  '97604': {
    zip: '97604',
    city: 'Crater Lake',
    state: 'OR',
    latitude: '42.9',
    longitude: '-122.13',
    timeZoneId: 'America/Los_Angeles',
  },
  '97620': {
    zip: '97620',
    city: 'Adel',
    state: 'OR',
    latitude: '42.192887',
    longitude: '-119.719932',
    timeZoneId: 'America/Los_Angeles',
  },
  '97621': {
    zip: '97621',
    city: 'Beatty',
    state: 'OR',
    latitude: '42.425062',
    longitude: '-121.178515',
    timeZoneId: 'America/Los_Angeles',
  },
  '97622': {
    zip: '97622',
    city: 'Bly',
    state: 'OR',
    latitude: '42.424667',
    longitude: '-121.179451',
    timeZoneId: 'America/Los_Angeles',
  },
  '97623': {
    zip: '97623',
    city: 'Bonanza',
    state: 'OR',
    latitude: '42.293249',
    longitude: '-121.185766',
    timeZoneId: 'America/Los_Angeles',
  },
  '97624': {
    zip: '97624',
    city: 'Chiloquin',
    state: 'OR',
    latitude: '42.592349',
    longitude: '-121.872422',
    timeZoneId: 'America/Los_Angeles',
  },
  '97625': {
    zip: '97625',
    city: 'Dairy',
    state: 'OR',
    latitude: '42.26058',
    longitude: '-121.6584',
    timeZoneId: 'America/Los_Angeles',
  },
  '97626': {
    zip: '97626',
    city: 'Fort Klamath',
    state: 'OR',
    latitude: '42.70352',
    longitude: '-121.99471',
    timeZoneId: 'America/Los_Angeles',
  },
  '97627': {
    zip: '97627',
    city: 'Keno',
    state: 'OR',
    latitude: '42.127531',
    longitude: '-121.96405',
    timeZoneId: 'America/Los_Angeles',
  },
  '97630': {
    zip: '97630',
    city: 'Lakeview',
    state: 'OR',
    latitude: '42.187658',
    longitude: '-120.352653',
    timeZoneId: 'America/Los_Angeles',
  },
  '97632': {
    zip: '97632',
    city: 'Malin',
    state: 'OR',
    latitude: '42.019082',
    longitude: '-121.428116',
    timeZoneId: 'America/Los_Angeles',
  },
  '97633': {
    zip: '97633',
    city: 'Merrill',
    state: 'OR',
    latitude: '42.025892',
    longitude: '-121.569831',
    timeZoneId: 'America/Los_Angeles',
  },
  '97634': {
    zip: '97634',
    city: 'Midland',
    state: 'OR',
    latitude: '42.133092',
    longitude: '-121.816443',
    timeZoneId: 'America/Los_Angeles',
  },
  '97635': {
    zip: '97635',
    city: 'New Pine Creek',
    state: 'OR',
    latitude: '42.052488',
    longitude: '-120.234489',
    timeZoneId: 'America/Los_Angeles',
  },
  '97636': {
    zip: '97636',
    city: 'Paisley',
    state: 'OR',
    latitude: '42.710132',
    longitude: '-120.513961',
    timeZoneId: 'America/Los_Angeles',
  },
  '97637': {
    zip: '97637',
    city: 'Plush',
    state: 'OR',
    latitude: '42.60965',
    longitude: '-119.972593',
    timeZoneId: 'America/Los_Angeles',
  },
  '97638': {
    zip: '97638',
    city: 'Silver Lake',
    state: 'OR',
    latitude: '43.13205',
    longitude: '-121.04312',
    timeZoneId: 'America/Los_Angeles',
  },
  '97639': {
    zip: '97639',
    city: 'Sprague River',
    state: 'OR',
    latitude: '42.455534',
    longitude: '-121.503033',
    timeZoneId: 'America/Los_Angeles',
  },
  '97640': {
    zip: '97640',
    city: 'Summer Lake',
    state: 'OR',
    latitude: '42.97786',
    longitude: '-120.77626',
    timeZoneId: 'America/Los_Angeles',
  },
  '97641': {
    zip: '97641',
    city: 'Christmas Valley',
    state: 'OR',
    latitude: '43.240268',
    longitude: '-120.677507',
    timeZoneId: 'America/Los_Angeles',
  },
  '97701': {
    zip: '97701',
    city: 'Bend',
    state: 'OR',
    latitude: '44.098205',
    longitude: '-121.288771',
    timeZoneId: 'America/Los_Angeles',
  },
  '97702': {
    zip: '97702',
    city: 'Bend',
    state: 'OR',
    latitude: '43.972165',
    longitude: '-121.256474',
    timeZoneId: 'America/Los_Angeles',
  },
  '97703': {
    zip: '97703',
    city: 'Bend',
    state: 'OR',
    latitude: '44.022054',
    longitude: '-121.297092',
    timeZoneId: 'America/Los_Angeles',
  },
  '97707': {
    zip: '97707',
    city: 'Bend',
    state: 'OR',
    latitude: '43.843213',
    longitude: '-121.509344',
    timeZoneId: 'America/Los_Angeles',
  },
  '97708': {
    zip: '97708',
    city: 'Bend',
    state: 'OR',
    latitude: '44.074771',
    longitude: '-121.301451',
    timeZoneId: 'America/Los_Angeles',
  },
  '97709': {
    zip: '97709',
    city: 'Bend',
    state: 'OR',
    latitude: '44.069081',
    longitude: '-121.311942',
    timeZoneId: 'America/Los_Angeles',
  },
  '97710': {
    zip: '97710',
    city: 'Fields',
    state: 'OR',
    latitude: '42.272108',
    longitude: '-118.862519',
    timeZoneId: 'America/Los_Angeles',
  },
  '97711': {
    zip: '97711',
    city: 'Ashwood',
    state: 'OR',
    latitude: '44.692443',
    longitude: '-120.653263',
    timeZoneId: 'America/Los_Angeles',
  },
  '97712': {
    zip: '97712',
    city: 'Brothers',
    state: 'OR',
    latitude: '43.71713',
    longitude: '-120.469505',
    timeZoneId: 'America/Los_Angeles',
  },
  '97720': {
    zip: '97720',
    city: 'Burns',
    state: 'OR',
    latitude: '43.524973',
    longitude: '-118.762656',
    timeZoneId: 'America/Los_Angeles',
  },
  '97721': {
    zip: '97721',
    city: 'Princeton',
    state: 'OR',
    latitude: '43.10542',
    longitude: '-118.63174',
    timeZoneId: 'America/Los_Angeles',
  },
  '97722': {
    zip: '97722',
    city: 'Diamond',
    state: 'OR',
    latitude: '43.010406',
    longitude: '-118.664099',
    timeZoneId: 'America/Los_Angeles',
  },
  '97730': {
    zip: '97730',
    city: 'Camp Sherman',
    state: 'OR',
    latitude: '44.445641',
    longitude: '-121.658818',
    timeZoneId: 'America/Los_Angeles',
  },
  '97731': {
    zip: '97731',
    city: 'Chemult',
    state: 'OR',
    latitude: '43.0653',
    longitude: '-121.84992',
    timeZoneId: 'America/Los_Angeles',
  },
  '97732': {
    zip: '97732',
    city: 'Crane',
    state: 'OR',
    latitude: '43.397005',
    longitude: '-118.437308',
    timeZoneId: 'America/Los_Angeles',
  },
  '97733': {
    zip: '97733',
    city: 'Crescent',
    state: 'OR',
    latitude: '43.509329',
    longitude: '-121.741618',
    timeZoneId: 'America/Los_Angeles',
  },
  '97734': {
    zip: '97734',
    city: 'Culver',
    state: 'OR',
    latitude: '44.509523',
    longitude: '-121.21215',
    timeZoneId: 'America/Los_Angeles',
  },
  '97735': {
    zip: '97735',
    city: 'Fort Rock',
    state: 'OR',
    latitude: '43.524354',
    longitude: '-121.168581',
    timeZoneId: 'America/Los_Angeles',
  },
  '97736': {
    zip: '97736',
    city: 'Frenchglen',
    state: 'OR',
    latitude: '42.824059',
    longitude: '-118.917984',
    timeZoneId: 'America/Los_Angeles',
  },
  '97737': {
    zip: '97737',
    city: 'Gilchrist',
    state: 'OR',
    latitude: '43.423495',
    longitude: '-121.702823',
    timeZoneId: 'America/Los_Angeles',
  },
  '97738': {
    zip: '97738',
    city: 'Hines',
    state: 'OR',
    latitude: '43.564589',
    longitude: '-119.080532',
    timeZoneId: 'America/Los_Angeles',
  },
  '97739': {
    zip: '97739',
    city: 'La Pine',
    state: 'OR',
    latitude: '43.704905',
    longitude: '-121.531851',
    timeZoneId: 'America/Los_Angeles',
  },
  '97741': {
    zip: '97741',
    city: 'Madras',
    state: 'OR',
    latitude: '44.650922',
    longitude: '-121.136968',
    timeZoneId: 'America/Los_Angeles',
  },
  '97750': {
    zip: '97750',
    city: 'Mitchell',
    state: 'OR',
    latitude: '44.546826',
    longitude: '-120.009094',
    timeZoneId: 'America/Los_Angeles',
  },
  '97751': {
    zip: '97751',
    city: 'Paulina',
    state: 'OR',
    latitude: '44.194088',
    longitude: '-119.855595',
    timeZoneId: 'America/Los_Angeles',
  },
  '97752': {
    zip: '97752',
    city: 'Post',
    state: 'OR',
    latitude: '43.995731',
    longitude: '-120.23142',
    timeZoneId: 'America/Los_Angeles',
  },
  '97753': {
    zip: '97753',
    city: 'Powell Butte',
    state: 'OR',
    latitude: '44.229143',
    longitude: '-121.0009',
    timeZoneId: 'America/Los_Angeles',
  },
  '97754': {
    zip: '97754',
    city: 'Prineville',
    state: 'OR',
    latitude: '44.300941',
    longitude: '-120.838053',
    timeZoneId: 'America/Los_Angeles',
  },
  '97756': {
    zip: '97756',
    city: 'Redmond',
    state: 'OR',
    latitude: '44.275433',
    longitude: '-121.205705',
    timeZoneId: 'America/Los_Angeles',
  },
  '97758': {
    zip: '97758',
    city: 'Riley',
    state: 'OR',
    latitude: '43.538942',
    longitude: '-119.508741',
    timeZoneId: 'America/Los_Angeles',
  },
  '97759': {
    zip: '97759',
    city: 'Sisters',
    state: 'OR',
    latitude: '44.323931',
    longitude: '-121.575944',
    timeZoneId: 'America/Los_Angeles',
  },
  '97760': {
    zip: '97760',
    city: 'Terrebonne',
    state: 'OR',
    latitude: '44.358649',
    longitude: '-121.184384',
    timeZoneId: 'America/Los_Angeles',
  },
  '97761': {
    zip: '97761',
    city: 'Warm Springs',
    state: 'OR',
    latitude: '44.762816',
    longitude: '-121.30239',
    timeZoneId: 'America/Los_Angeles',
  },
  '97801': {
    zip: '97801',
    city: 'Pendleton',
    state: 'OR',
    latitude: '45.666259',
    longitude: '-118.7938',
    timeZoneId: 'America/Los_Angeles',
  },
  '97810': {
    zip: '97810',
    city: 'Adams',
    state: 'OR',
    latitude: '45.76885',
    longitude: '-118.559394',
    timeZoneId: 'America/Los_Angeles',
  },
  '97812': {
    zip: '97812',
    city: 'Arlington',
    state: 'OR',
    latitude: '45.686001',
    longitude: '-120.18814',
    timeZoneId: 'America/Los_Angeles',
  },
  '97813': {
    zip: '97813',
    city: 'Athena',
    state: 'OR',
    latitude: '45.814185',
    longitude: '-118.488771',
    timeZoneId: 'America/Los_Angeles',
  },
  '97814': {
    zip: '97814',
    city: 'Baker City',
    state: 'OR',
    latitude: '44.779688',
    longitude: '-117.834287',
    timeZoneId: 'America/Los_Angeles',
  },
  '97817': {
    zip: '97817',
    city: 'Bates',
    state: 'OR',
    latitude: '44.511076',
    longitude: '-118.503159',
    timeZoneId: 'America/Los_Angeles',
  },
  '97818': {
    zip: '97818',
    city: 'Boardman',
    state: 'OR',
    latitude: '45.810948',
    longitude: '-119.695417',
    timeZoneId: 'America/Los_Angeles',
  },
  '97819': {
    zip: '97819',
    city: 'Bridgeport',
    state: 'OR',
    latitude: '44.483048',
    longitude: '-117.743386',
    timeZoneId: 'America/Los_Angeles',
  },
  '97820': {
    zip: '97820',
    city: 'Canyon City',
    state: 'OR',
    latitude: '44.387513',
    longitude: '-118.945978',
    timeZoneId: 'America/Los_Angeles',
  },
  '97823': {
    zip: '97823',
    city: 'Condon',
    state: 'OR',
    latitude: '45.234719',
    longitude: '-120.192094',
    timeZoneId: 'America/Los_Angeles',
  },
  '97824': {
    zip: '97824',
    city: 'Cove',
    state: 'OR',
    latitude: '45.33275',
    longitude: '-117.85536',
    timeZoneId: 'America/Los_Angeles',
  },
  '97825': {
    zip: '97825',
    city: 'Dayville',
    state: 'OR',
    latitude: '44.469607',
    longitude: '-119.529778',
    timeZoneId: 'America/Los_Angeles',
  },
  '97826': {
    zip: '97826',
    city: 'Echo',
    state: 'OR',
    latitude: '45.739831',
    longitude: '-119.191208',
    timeZoneId: 'America/Los_Angeles',
  },
  '97827': {
    zip: '97827',
    city: 'Elgin',
    state: 'OR',
    latitude: '45.591741',
    longitude: '-117.893621',
    timeZoneId: 'America/Los_Angeles',
  },
  '97828': {
    zip: '97828',
    city: 'Enterprise',
    state: 'OR',
    latitude: '45.4171',
    longitude: '-117.270806',
    timeZoneId: 'America/Los_Angeles',
  },
  '97830': {
    zip: '97830',
    city: 'Fossil',
    state: 'OR',
    latitude: '44.998369',
    longitude: '-120.212626',
    timeZoneId: 'America/Los_Angeles',
  },
  '97833': {
    zip: '97833',
    city: 'Haines',
    state: 'OR',
    latitude: '44.91438',
    longitude: '-117.938903',
    timeZoneId: 'America/Los_Angeles',
  },
  '97834': {
    zip: '97834',
    city: 'Halfway',
    state: 'OR',
    latitude: '44.960212',
    longitude: '-117.126587',
    timeZoneId: 'America/Los_Angeles',
  },
  '97835': {
    zip: '97835',
    city: 'Helix',
    state: 'OR',
    latitude: '45.911186',
    longitude: '-118.848872',
    timeZoneId: 'America/Los_Angeles',
  },
  '97836': {
    zip: '97836',
    city: 'Heppner',
    state: 'OR',
    latitude: '45.356421',
    longitude: '-119.556835',
    timeZoneId: 'America/Los_Angeles',
  },
  '97837': {
    zip: '97837',
    city: 'Hereford',
    state: 'OR',
    latitude: '44.593477',
    longitude: '-117.98939',
    timeZoneId: 'America/Los_Angeles',
  },
  '97838': {
    zip: '97838',
    city: 'Hermiston',
    state: 'OR',
    latitude: '45.839542',
    longitude: '-119.285752',
    timeZoneId: 'America/Los_Angeles',
  },
  '97839': {
    zip: '97839',
    city: 'Lexington',
    state: 'OR',
    latitude: '45.390327',
    longitude: '-119.808273',
    timeZoneId: 'America/Los_Angeles',
  },
  '97840': {
    zip: '97840',
    city: 'Oxbow',
    state: 'OR',
    latitude: '44.956612',
    longitude: '-116.923026',
    timeZoneId: 'America/Los_Angeles',
  },
  '97841': {
    zip: '97841',
    city: 'Imbler',
    state: 'OR',
    latitude: '45.471602',
    longitude: '-117.962094',
    timeZoneId: 'America/Los_Angeles',
  },
  '97842': {
    zip: '97842',
    city: 'Imnaha',
    state: 'OR',
    latitude: '45.750882',
    longitude: '-116.862928',
    timeZoneId: 'America/Los_Angeles',
  },
  '97843': {
    zip: '97843',
    city: 'Ione',
    state: 'OR',
    latitude: '45.502734',
    longitude: '-119.823955',
    timeZoneId: 'America/Los_Angeles',
  },
  '97844': {
    zip: '97844',
    city: 'Irrigon',
    state: 'OR',
    latitude: '45.89953',
    longitude: '-119.498387',
    timeZoneId: 'America/Los_Angeles',
  },
  '97845': {
    zip: '97845',
    city: 'John Day',
    state: 'OR',
    latitude: '44.427485',
    longitude: '-118.948196',
    timeZoneId: 'America/Los_Angeles',
  },
  '97846': {
    zip: '97846',
    city: 'Joseph',
    state: 'OR',
    latitude: '45.353045',
    longitude: '-117.228443',
    timeZoneId: 'America/Los_Angeles',
  },
  '97848': {
    zip: '97848',
    city: 'Kimberly',
    state: 'OR',
    latitude: '44.695458',
    longitude: '-119.581679',
    timeZoneId: 'America/Los_Angeles',
  },
  '97850': {
    zip: '97850',
    city: 'La Grande',
    state: 'OR',
    latitude: '45.331036',
    longitude: '-118.078078',
    timeZoneId: 'America/Los_Angeles',
  },
  '97856': {
    zip: '97856',
    city: 'Long Creek',
    state: 'OR',
    latitude: '44.715891',
    longitude: '-119.102716',
    timeZoneId: 'America/Los_Angeles',
  },
  '97857': {
    zip: '97857',
    city: 'Lostine',
    state: 'OR',
    latitude: '45.486867',
    longitude: '-117.44248',
    timeZoneId: 'America/Los_Angeles',
  },
  '97859': {
    zip: '97859',
    city: 'Meacham',
    state: 'OR',
    latitude: '45.508204',
    longitude: '-118.418869',
    timeZoneId: 'America/Los_Angeles',
  },
  '97861': {
    zip: '97861',
    city: 'Mikkalo',
    state: 'OR',
    latitude: '45.716832',
    longitude: '-120.199034',
    timeZoneId: 'America/Los_Angeles',
  },
  '97862': {
    zip: '97862',
    city: 'Milton Freewater',
    state: 'OR',
    latitude: '45.932608',
    longitude: '-118.389207',
    timeZoneId: 'America/Los_Angeles',
  },
  '97864': {
    zip: '97864',
    city: 'Monument',
    state: 'OR',
    latitude: '44.870791',
    longitude: '-119.448452',
    timeZoneId: 'America/Los_Angeles',
  },
  '97865': {
    zip: '97865',
    city: 'Mount Vernon',
    state: 'OR',
    latitude: '44.420455',
    longitude: '-119.113641',
    timeZoneId: 'America/Los_Angeles',
  },
  '97867': {
    zip: '97867',
    city: 'North Powder',
    state: 'OR',
    latitude: '45.035797',
    longitude: '-117.937934',
    timeZoneId: 'America/Los_Angeles',
  },
  '97868': {
    zip: '97868',
    city: 'Pilot Rock',
    state: 'OR',
    latitude: '45.484282',
    longitude: '-118.832536',
    timeZoneId: 'America/Los_Angeles',
  },
  '97869': {
    zip: '97869',
    city: 'Prairie City',
    state: 'OR',
    latitude: '44.457199',
    longitude: '-118.711413',
    timeZoneId: 'America/Los_Angeles',
  },
  '97870': {
    zip: '97870',
    city: 'Richland',
    state: 'OR',
    latitude: '44.766508',
    longitude: '-117.168196',
    timeZoneId: 'America/Los_Angeles',
  },
  '97873': {
    zip: '97873',
    city: 'Seneca',
    state: 'OR',
    latitude: '44.129131',
    longitude: '-118.989332',
    timeZoneId: 'America/Los_Angeles',
  },
  '97874': {
    zip: '97874',
    city: 'Spray',
    state: 'OR',
    latitude: '44.811765',
    longitude: '-119.865455',
    timeZoneId: 'America/Los_Angeles',
  },
  '97875': {
    zip: '97875',
    city: 'Stanfield',
    state: 'OR',
    latitude: '45.780672',
    longitude: '-119.217409',
    timeZoneId: 'America/Los_Angeles',
  },
  '97876': {
    zip: '97876',
    city: 'Summerville',
    state: 'OR',
    latitude: '45.51339',
    longitude: '-118.018653',
    timeZoneId: 'America/Los_Angeles',
  },
  '97877': {
    zip: '97877',
    city: 'Sumpter',
    state: 'OR',
    latitude: '44.729195',
    longitude: '-118.264168',
    timeZoneId: 'America/Los_Angeles',
  },
  '97880': {
    zip: '97880',
    city: 'Ukiah',
    state: 'OR',
    latitude: '45.13477',
    longitude: '-118.928389',
    timeZoneId: 'America/Los_Angeles',
  },
  '97882': {
    zip: '97882',
    city: 'Umatilla',
    state: 'OR',
    latitude: '45.915922',
    longitude: '-119.296745',
    timeZoneId: 'America/Los_Angeles',
  },
  '97883': {
    zip: '97883',
    city: 'Union',
    state: 'OR',
    latitude: '45.19181',
    longitude: '-117.835694',
    timeZoneId: 'America/Los_Angeles',
  },
  '97884': {
    zip: '97884',
    city: 'Unity',
    state: 'OR',
    latitude: '44.43513',
    longitude: '-118.191101',
    timeZoneId: 'America/Los_Angeles',
  },
  '97885': {
    zip: '97885',
    city: 'Wallowa',
    state: 'OR',
    latitude: '45.568597',
    longitude: '-117.528014',
    timeZoneId: 'America/Los_Angeles',
  },
  '97886': {
    zip: '97886',
    city: 'Weston',
    state: 'OR',
    latitude: '45.814204',
    longitude: '-118.423866',
    timeZoneId: 'America/Los_Angeles',
  },
  '97901': {
    zip: '97901',
    city: 'Adrian',
    state: 'OR',
    latitude: '43.676162',
    longitude: '-117.058271',
    timeZoneId: 'America/Denver',
  },
  '97902': {
    zip: '97902',
    city: 'Arock',
    state: 'OR',
    latitude: '42.9158',
    longitude: '-117.480396',
    timeZoneId: 'America/Denver',
  },
  '97903': {
    zip: '97903',
    city: 'Brogan',
    state: 'OR',
    latitude: '44.246514',
    longitude: '-117.515103',
    timeZoneId: 'America/Denver',
  },
  '97904': {
    zip: '97904',
    city: 'Drewsey',
    state: 'OR',
    latitude: '43.875912',
    longitude: '-118.53532',
    timeZoneId: 'America/Los_Angeles',
  },
  '97905': {
    zip: '97905',
    city: 'Durkee',
    state: 'OR',
    latitude: '44.582418',
    longitude: '-117.462964',
    timeZoneId: 'America/Los_Angeles',
  },
  '97906': {
    zip: '97906',
    city: 'Harper',
    state: 'OR',
    latitude: '43.8753',
    longitude: '-117.595524',
    timeZoneId: 'America/Denver',
  },
  '97907': {
    zip: '97907',
    city: 'Huntington',
    state: 'OR',
    latitude: '44.347926',
    longitude: '-117.262318',
    timeZoneId: 'America/Los_Angeles',
  },
  '97908': {
    zip: '97908',
    city: 'Ironside',
    state: 'OR',
    latitude: '44.359632',
    longitude: '-117.832776',
    timeZoneId: 'America/Denver',
  },
  '97909': {
    zip: '97909',
    city: 'Jamieson',
    state: 'OR',
    latitude: '44.222643',
    longitude: '-117.488983',
    timeZoneId: 'America/Denver',
  },
  '97910': {
    zip: '97910',
    city: 'Jordan Valley',
    state: 'OR',
    latitude: '42.6828',
    longitude: '-117.43032',
    timeZoneId: 'America/Denver',
  },
  '97911': {
    zip: '97911',
    city: 'Juntura',
    state: 'OR',
    latitude: '43.745601',
    longitude: '-118.076327',
    timeZoneId: 'America/Denver',
  },
  '97913': {
    zip: '97913',
    city: 'Nyssa',
    state: 'OR',
    latitude: '43.838397',
    longitude: '-117.061031',
    timeZoneId: 'America/Denver',
  },
  '97914': {
    zip: '97914',
    city: 'Ontario',
    state: 'OR',
    latitude: '44.052879',
    longitude: '-116.984744',
    timeZoneId: 'America/Denver',
  },
  '97917': {
    zip: '97917',
    city: 'Riverside',
    state: 'OR',
    latitude: '43.508121',
    longitude: '-118.09931',
    timeZoneId: 'America/Denver',
  },
  '97918': {
    zip: '97918',
    city: 'Vale',
    state: 'OR',
    latitude: '44.013408',
    longitude: '-117.288795',
    timeZoneId: 'America/Denver',
  },
  '97920': {
    zip: '97920',
    city: 'Westfall',
    state: 'OR',
    latitude: '43.948095',
    longitude: '-117.749854',
    timeZoneId: 'America/Denver',
  },
  '98001': {
    zip: '98001',
    city: 'Auburn',
    state: 'WA',
    latitude: '47.3068',
    longitude: '-122.260607',
    timeZoneId: 'America/Los_Angeles',
  },
  '98002': {
    zip: '98002',
    city: 'Auburn',
    state: 'WA',
    latitude: '47.302461',
    longitude: '-122.186065',
    timeZoneId: 'America/Los_Angeles',
  },
  '98003': {
    zip: '98003',
    city: 'Federal Way',
    state: 'WA',
    latitude: '47.30994',
    longitude: '-122.3139',
    timeZoneId: 'America/Los_Angeles',
  },
  '98004': {
    zip: '98004',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.617177',
    longitude: '-122.208579',
    timeZoneId: 'America/Los_Angeles',
  },
  '98005': {
    zip: '98005',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.615593',
    longitude: '-122.16992',
    timeZoneId: 'America/Los_Angeles',
  },
  '98006': {
    zip: '98006',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.564251',
    longitude: '-122.150636',
    timeZoneId: 'America/Los_Angeles',
  },
  '98007': {
    zip: '98007',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.612028',
    longitude: '-122.14141',
    timeZoneId: 'America/Los_Angeles',
  },
  '98008': {
    zip: '98008',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.609195',
    longitude: '-122.113604',
    timeZoneId: 'America/Los_Angeles',
  },
  '98009': {
    zip: '98009',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.61802',
    longitude: '-122.20183',
    timeZoneId: 'America/Los_Angeles',
  },
  '98010': {
    zip: '98010',
    city: 'Black Diamond',
    state: 'WA',
    latitude: '47.311403',
    longitude: '-121.997673',
    timeZoneId: 'America/Los_Angeles',
  },
  '98011': {
    zip: '98011',
    city: 'Bothell',
    state: 'WA',
    latitude: '47.7555',
    longitude: '-122.191349',
    timeZoneId: 'America/Los_Angeles',
  },
  '98012': {
    zip: '98012',
    city: 'Bothell',
    state: 'WA',
    latitude: '47.8413',
    longitude: '-122.205819',
    timeZoneId: 'America/Los_Angeles',
  },
  '98013': {
    zip: '98013',
    city: 'Burton',
    state: 'WA',
    latitude: '47.38908',
    longitude: '-122.4594',
    timeZoneId: 'America/Los_Angeles',
  },
  '98014': {
    zip: '98014',
    city: 'Carnation',
    state: 'WA',
    latitude: '47.657278',
    longitude: '-121.912869',
    timeZoneId: 'America/Los_Angeles',
  },
  '98015': {
    zip: '98015',
    city: 'Bellevue',
    state: 'WA',
    latitude: '47.62002',
    longitude: '-122.204989',
    timeZoneId: 'America/Los_Angeles',
  },
  '98019': {
    zip: '98019',
    city: 'Duvall',
    state: 'WA',
    latitude: '47.73775',
    longitude: '-121.848365',
    timeZoneId: 'America/Los_Angeles',
  },
  '98020': {
    zip: '98020',
    city: 'Edmonds',
    state: 'WA',
    latitude: '47.800941',
    longitude: '-122.374292',
    timeZoneId: 'America/Los_Angeles',
  },
  '98021': {
    zip: '98021',
    city: 'Bothell',
    state: 'WA',
    latitude: '47.791648',
    longitude: '-122.217445',
    timeZoneId: 'America/Los_Angeles',
  },
  '98022': {
    zip: '98022',
    city: 'Enumclaw',
    state: 'WA',
    latitude: '47.20872',
    longitude: '-121.86342',
    timeZoneId: 'America/Los_Angeles',
  },
  '98023': {
    zip: '98023',
    city: 'Federal Way',
    state: 'WA',
    latitude: '47.30448',
    longitude: '-122.37258',
    timeZoneId: 'America/Los_Angeles',
  },
  '98024': {
    zip: '98024',
    city: 'Fall City',
    state: 'WA',
    latitude: '47.561848',
    longitude: '-121.901429',
    timeZoneId: 'America/Los_Angeles',
  },
  '98025': {
    zip: '98025',
    city: 'Hobart',
    state: 'WA',
    latitude: '47.418778',
    longitude: '-121.966316',
    timeZoneId: 'America/Los_Angeles',
  },
  '98026': {
    zip: '98026',
    city: 'Edmonds',
    state: 'WA',
    latitude: '47.84077',
    longitude: '-122.336731',
    timeZoneId: 'America/Los_Angeles',
  },
  '98027': {
    zip: '98027',
    city: 'Issaquah',
    state: 'WA',
    latitude: '47.533481',
    longitude: '-122.068701',
    timeZoneId: 'America/Los_Angeles',
  },
  '98028': {
    zip: '98028',
    city: 'Kenmore',
    state: 'WA',
    latitude: '47.75',
    longitude: '-122.24',
    timeZoneId: 'America/Los_Angeles',
  },
  '98029': {
    zip: '98029',
    city: 'Issaquah',
    state: 'WA',
    latitude: '47.555872',
    longitude: '-122.001514',
    timeZoneId: 'America/Los_Angeles',
  },
  '98030': {
    zip: '98030',
    city: 'Kent',
    state: 'WA',
    latitude: '47.383225',
    longitude: '-122.212973',
    timeZoneId: 'America/Los_Angeles',
  },
  '98031': {
    zip: '98031',
    city: 'Kent',
    state: 'WA',
    latitude: '47.386336',
    longitude: '-122.195858',
    timeZoneId: 'America/Los_Angeles',
  },
  '98032': {
    zip: '98032',
    city: 'Kent',
    state: 'WA',
    latitude: '47.392732',
    longitude: '-122.260066',
    timeZoneId: 'America/Los_Angeles',
  },
  '98033': {
    zip: '98033',
    city: 'Kirkland',
    state: 'WA',
    latitude: '47.687703',
    longitude: '-122.192053',
    timeZoneId: 'America/Los_Angeles',
  },
  '98034': {
    zip: '98034',
    city: 'Kirkland',
    state: 'WA',
    latitude: '47.718053',
    longitude: '-122.198946',
    timeZoneId: 'America/Los_Angeles',
  },
  '98035': {
    zip: '98035',
    city: 'Kent',
    state: 'WA',
    latitude: '47.385642',
    longitude: '-122.201891',
    timeZoneId: 'America/Los_Angeles',
  },
  '98036': {
    zip: '98036',
    city: 'Lynnwood',
    state: 'WA',
    latitude: '47.80278',
    longitude: '-122.28486',
    timeZoneId: 'America/Los_Angeles',
  },
  '98037': {
    zip: '98037',
    city: 'Lynnwood',
    state: 'WA',
    latitude: '47.851103',
    longitude: '-122.281511',
    timeZoneId: 'America/Los_Angeles',
  },
  '98038': {
    zip: '98038',
    city: 'Maple Valley',
    state: 'WA',
    latitude: '47.398201',
    longitude: '-122.032234',
    timeZoneId: 'America/Los_Angeles',
  },
  '98039': {
    zip: '98039',
    city: 'Medina',
    state: 'WA',
    latitude: '47.617654',
    longitude: '-122.233674',
    timeZoneId: 'America/Los_Angeles',
  },
  '98040': {
    zip: '98040',
    city: 'Mercer Island',
    state: 'WA',
    latitude: '47.564004',
    longitude: '-122.226476',
    timeZoneId: 'America/Los_Angeles',
  },
  '98041': {
    zip: '98041',
    city: 'Bothell',
    state: 'WA',
    latitude: '47.754018',
    longitude: '-122.216128',
    timeZoneId: 'America/Los_Angeles',
  },
  '98042': {
    zip: '98042',
    city: 'Kent',
    state: 'WA',
    latitude: '47.37648',
    longitude: '-122.0994',
    timeZoneId: 'America/Los_Angeles',
  },
  '98043': {
    zip: '98043',
    city: 'Mountlake Terrace',
    state: 'WA',
    latitude: '47.791785',
    longitude: '-122.304134',
    timeZoneId: 'America/Los_Angeles',
  },
  '98045': {
    zip: '98045',
    city: 'North Bend',
    state: 'WA',
    latitude: '47.475162',
    longitude: '-121.756532',
    timeZoneId: 'America/Los_Angeles',
  },
  '98046': {
    zip: '98046',
    city: 'Lynnwood',
    state: 'WA',
    latitude: '47.830174',
    longitude: '-122.30401',
    timeZoneId: 'America/Los_Angeles',
  },
  '98047': {
    zip: '98047',
    city: 'Pacific',
    state: 'WA',
    latitude: '47.3075',
    longitude: '-122.227222',
    timeZoneId: 'America/Los_Angeles',
  },
  '98050': {
    zip: '98050',
    city: 'Preston',
    state: 'WA',
    latitude: '47.532563',
    longitude: '-121.925654',
    timeZoneId: 'America/Los_Angeles',
  },
  '98051': {
    zip: '98051',
    city: 'Ravensdale',
    state: 'WA',
    latitude: '47.36247',
    longitude: '-121.896169',
    timeZoneId: 'America/Los_Angeles',
  },
  '98052': {
    zip: '98052',
    city: 'Redmond',
    state: 'WA',
    latitude: '47.683648',
    longitude: '-122.121837',
    timeZoneId: 'America/Los_Angeles',
  },
  '98053': {
    zip: '98053',
    city: 'Redmond',
    state: 'WA',
    latitude: '47.666262',
    longitude: '-122.015882',
    timeZoneId: 'America/Los_Angeles',
  },
  '98055': {
    zip: '98055',
    city: 'Renton',
    state: 'WA',
    latitude: '47.458993',
    longitude: '-122.208401',
    timeZoneId: 'America/Los_Angeles',
  },
  '98056': {
    zip: '98056',
    city: 'Renton',
    state: 'WA',
    latitude: '47.51262',
    longitude: '-122.19342',
    timeZoneId: 'America/Los_Angeles',
  },
  '98057': {
    zip: '98057',
    city: 'Renton',
    state: 'WA',
    latitude: '47.485383',
    longitude: '-122.197551',
    timeZoneId: 'America/Los_Angeles',
  },
  '98058': {
    zip: '98058',
    city: 'Renton',
    state: 'WA',
    latitude: '47.444411',
    longitude: '-122.142843',
    timeZoneId: 'America/Los_Angeles',
  },
  '98059': {
    zip: '98059',
    city: 'Renton',
    state: 'WA',
    latitude: '47.4831',
    longitude: '-122.12982',
    timeZoneId: 'America/Los_Angeles',
  },
  '98061': {
    zip: '98061',
    city: 'Rollingbay',
    state: 'WA',
    latitude: '47.652184',
    longitude: '-122.548994',
    timeZoneId: 'America/Los_Angeles',
  },
  '98062': {
    zip: '98062',
    city: 'Seahurst',
    state: 'WA',
    latitude: '47.469145',
    longitude: '-122.360015',
    timeZoneId: 'America/Los_Angeles',
  },
  '98063': {
    zip: '98063',
    city: 'Federal Way',
    state: 'WA',
    latitude: '47.305546',
    longitude: '-122.320901',
    timeZoneId: 'America/Los_Angeles',
  },
  '98064': {
    zip: '98064',
    city: 'Kent',
    state: 'WA',
    latitude: '47.3959',
    longitude: '-122.239201',
    timeZoneId: 'America/Los_Angeles',
  },
  '98065': {
    zip: '98065',
    city: 'Snoqualmie',
    state: 'WA',
    latitude: '47.527102',
    longitude: '-121.823613',
    timeZoneId: 'America/Los_Angeles',
  },
  '98068': {
    zip: '98068',
    city: 'Snoqualmie Pass',
    state: 'WA',
    latitude: '47.4123',
    longitude: '-121.418723',
    timeZoneId: 'America/Los_Angeles',
  },
  '98070': {
    zip: '98070',
    city: 'Vashon',
    state: 'WA',
    latitude: '47.41818',
    longitude: '-122.45178',
    timeZoneId: 'America/Los_Angeles',
  },
  '98071': {
    zip: '98071',
    city: 'Auburn',
    state: 'WA',
    latitude: '47.248768',
    longitude: '-122.116851',
    timeZoneId: 'America/Los_Angeles',
  },
  '98072': {
    zip: '98072',
    city: 'Woodinville',
    state: 'WA',
    latitude: '47.748655',
    longitude: '-122.095293',
    timeZoneId: 'America/Los_Angeles',
  },
  '98073': {
    zip: '98073',
    city: 'Redmond',
    state: 'WA',
    latitude: '47.676555',
    longitude: '-122.114869',
    timeZoneId: 'America/Los_Angeles',
  },
  '98074': {
    zip: '98074',
    city: 'Sammamish',
    state: 'WA',
    latitude: '47.624151',
    longitude: '-122.048612',
    timeZoneId: 'America/Los_Angeles',
  },
  '98075': {
    zip: '98075',
    city: 'Sammamish',
    state: 'WA',
    latitude: '47.586461',
    longitude: '-122.030567',
    timeZoneId: 'America/Los_Angeles',
  },
  '98077': {
    zip: '98077',
    city: 'Woodinville',
    state: 'WA',
    latitude: '47.74987',
    longitude: '-122.098319',
    timeZoneId: 'America/Los_Angeles',
  },
  '98082': {
    zip: '98082',
    city: 'Mill Creek',
    state: 'WA',
    latitude: '47.855245',
    longitude: '-122.221023',
    timeZoneId: 'America/Los_Angeles',
  },
  '98083': {
    zip: '98083',
    city: 'Kirkland',
    state: 'WA',
    latitude: '47.682571',
    longitude: '-122.191069',
    timeZoneId: 'America/Los_Angeles',
  },
  '98087': {
    zip: '98087',
    city: 'Lynnwood',
    state: 'WA',
    latitude: '47.87263',
    longitude: '-122.270929',
    timeZoneId: 'America/Los_Angeles',
  },
  '98089': {
    zip: '98089',
    city: 'Kent',
    state: 'WA',
    latitude: '47.3932',
    longitude: '-122.2579',
    timeZoneId: 'America/Los_Angeles',
  },
  '98092': {
    zip: '98092',
    city: 'Auburn',
    state: 'WA',
    latitude: '47.283585',
    longitude: '-122.128606',
    timeZoneId: 'America/Los_Angeles',
  },
  '98093': {
    zip: '98093',
    city: 'Federal Way',
    state: 'WA',
    latitude: '47.301949',
    longitude: '-122.358826',
    timeZoneId: 'America/Los_Angeles',
  },
  '98101': {
    zip: '98101',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.61133',
    longitude: '-122.333219',
    timeZoneId: 'America/Los_Angeles',
  },
  '98102': {
    zip: '98102',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.632744',
    longitude: '-122.321885',
    timeZoneId: 'America/Los_Angeles',
  },
  '98103': {
    zip: '98103',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.671009',
    longitude: '-122.340102',
    timeZoneId: 'America/Los_Angeles',
  },
  '98104': {
    zip: '98104',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.604727',
    longitude: '-122.325941',
    timeZoneId: 'America/Los_Angeles',
  },
  '98105': {
    zip: '98105',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.661442',
    longitude: '-122.292906',
    timeZoneId: 'America/Los_Angeles',
  },
  '98106': {
    zip: '98106',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.536346',
    longitude: '-122.352802',
    timeZoneId: 'America/Los_Angeles',
  },
  '98107': {
    zip: '98107',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.669508',
    longitude: '-122.383202',
    timeZoneId: 'America/Los_Angeles',
  },
  '98108': {
    zip: '98108',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.53709',
    longitude: '-122.310216',
    timeZoneId: 'America/Los_Angeles',
  },
  '98109': {
    zip: '98109',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.628934',
    longitude: '-122.343181',
    timeZoneId: 'America/Los_Angeles',
  },
  '98110': {
    zip: '98110',
    city: 'Bainbridge Island',
    state: 'WA',
    latitude: '47.648322',
    longitude: '-122.533526',
    timeZoneId: 'America/Los_Angeles',
  },
  '98111': {
    zip: '98111',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.610241',
    longitude: '-122.334698',
    timeZoneId: 'America/Los_Angeles',
  },
  '98112': {
    zip: '98112',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.633515',
    longitude: '-122.292981',
    timeZoneId: 'America/Los_Angeles',
  },
  '98113': {
    zip: '98113',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.675126',
    longitude: '-122.344156',
    timeZoneId: 'America/Los_Angeles',
  },
  '98114': {
    zip: '98114',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.580063',
    longitude: '-122.312552',
    timeZoneId: 'America/Los_Angeles',
  },
  '98115': {
    zip: '98115',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.683441',
    longitude: '-122.291533',
    timeZoneId: 'America/Los_Angeles',
  },
  '98116': {
    zip: '98116',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.5753',
    longitude: '-122.395975',
    timeZoneId: 'America/Los_Angeles',
  },
  '98117': {
    zip: '98117',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.686273',
    longitude: '-122.380393',
    timeZoneId: 'America/Los_Angeles',
  },
  '98118': {
    zip: '98118',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.541963',
    longitude: '-122.267649',
    timeZoneId: 'America/Los_Angeles',
  },
  '98119': {
    zip: '98119',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.638776',
    longitude: '-122.366173',
    timeZoneId: 'America/Los_Angeles',
  },
  '98121': {
    zip: '98121',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.612625',
    longitude: '-122.34446',
    timeZoneId: 'America/Los_Angeles',
  },
  '98122': {
    zip: '98122',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.613497',
    longitude: '-122.297395',
    timeZoneId: 'America/Los_Angeles',
  },
  '98124': {
    zip: '98124',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.577224',
    longitude: '-122.33354',
    timeZoneId: 'America/Los_Angeles',
  },
  '98125': {
    zip: '98125',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.718413',
    longitude: '-122.301588',
    timeZoneId: 'America/Los_Angeles',
  },
  '98126': {
    zip: '98126',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.549039',
    longitude: '-122.376348',
    timeZoneId: 'America/Los_Angeles',
  },
  '98127': {
    zip: '98127',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.606389',
    longitude: '-122.330833',
    timeZoneId: 'America/Los_Angeles',
  },
  '98129': {
    zip: '98129',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.61511',
    longitude: '-122.338189',
    timeZoneId: 'America/Los_Angeles',
  },
  '98131': {
    zip: '98131',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.468588',
    longitude: '-122.291881',
    timeZoneId: 'America/Los_Angeles',
  },
  '98133': {
    zip: '98133',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.73966',
    longitude: '-122.33334',
    timeZoneId: 'America/Los_Angeles',
  },
  '98134': {
    zip: '98134',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.578213',
    longitude: '-122.333073',
    timeZoneId: 'America/Los_Angeles',
  },
  '98136': {
    zip: '98136',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.535393',
    longitude: '-122.386977',
    timeZoneId: 'America/Los_Angeles',
  },
  '98138': {
    zip: '98138',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.474167',
    longitude: '-122.259722',
    timeZoneId: 'America/Los_Angeles',
  },
  '98139': {
    zip: '98139',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.651421',
    longitude: '-122.405153',
    timeZoneId: 'America/Los_Angeles',
  },
  '98141': {
    zip: '98141',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.614953',
    longitude: '-122.345794',
    timeZoneId: 'America/Los_Angeles',
  },
  '98144': {
    zip: '98144',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.586883',
    longitude: '-122.298008',
    timeZoneId: 'America/Los_Angeles',
  },
  '98145': {
    zip: '98145',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.658611',
    longitude: '-122.312482',
    timeZoneId: 'America/Los_Angeles',
  },
  '98146': {
    zip: '98146',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.500943',
    longitude: '-122.3575',
    timeZoneId: 'America/Los_Angeles',
  },
  '98148': {
    zip: '98148',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.425051',
    longitude: '-122.332127',
    timeZoneId: 'America/Los_Angeles',
  },
  '98154': {
    zip: '98154',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.606686',
    longitude: '-122.333804',
    timeZoneId: 'America/Los_Angeles',
  },
  '98155': {
    zip: '98155',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.76313',
    longitude: '-122.29015',
    timeZoneId: 'America/Los_Angeles',
  },
  '98158': {
    zip: '98158',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.445664',
    longitude: '-122.311881',
    timeZoneId: 'America/Los_Angeles',
  },
  '98160': {
    zip: '98160',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.771124',
    longitude: '-122.382371',
    timeZoneId: 'America/Los_Angeles',
  },
  '98161': {
    zip: '98161',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.609554',
    longitude: '-122.337614',
    timeZoneId: 'America/Los_Angeles',
  },
  '98164': {
    zip: '98164',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.607818',
    longitude: '-122.333703',
    timeZoneId: 'America/Los_Angeles',
  },
  '98165': {
    zip: '98165',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.603561',
    longitude: '-122.329437',
    timeZoneId: 'America/Los_Angeles',
  },
  '98166': {
    zip: '98166',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.44746',
    longitude: '-122.35656',
    timeZoneId: 'America/Los_Angeles',
  },
  '98168': {
    zip: '98168',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.490093',
    longitude: '-122.291541',
    timeZoneId: 'America/Los_Angeles',
  },
  '98170': {
    zip: '98170',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.606323',
    longitude: '-122.328863',
    timeZoneId: 'America/Los_Angeles',
  },
  '98174': {
    zip: '98174',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.604103',
    longitude: '-122.337504',
    timeZoneId: 'America/Los_Angeles',
  },
  '98175': {
    zip: '98175',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.603561',
    longitude: '-122.329437',
    timeZoneId: 'America/Los_Angeles',
  },
  '98177': {
    zip: '98177',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.743958',
    longitude: '-122.369875',
    timeZoneId: 'America/Los_Angeles',
  },
  '98178': {
    zip: '98178',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.492862',
    longitude: '-122.249902',
    timeZoneId: 'America/Los_Angeles',
  },
  '98181': {
    zip: '98181',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.610729',
    longitude: '-122.341403',
    timeZoneId: 'America/Los_Angeles',
  },
  '98185': {
    zip: '98185',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.665276',
    longitude: '-122.291979',
    timeZoneId: 'America/Los_Angeles',
  },
  '98188': {
    zip: '98188',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.445834',
    longitude: '-122.268993',
    timeZoneId: 'America/Los_Angeles',
  },
  '98190': {
    zip: '98190',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.503893',
    longitude: '-122.294967',
    timeZoneId: 'America/Los_Angeles',
  },
  '98191': {
    zip: '98191',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.613607',
    longitude: '-122.335017',
    timeZoneId: 'America/Los_Angeles',
  },
  '98194': {
    zip: '98194',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.599793',
    longitude: '-122.32977',
    timeZoneId: 'America/Los_Angeles',
  },
  '98195': {
    zip: '98195',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.651377',
    longitude: '-122.298431',
    timeZoneId: 'America/Los_Angeles',
  },
  '98198': {
    zip: '98198',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.405098',
    longitude: '-122.310024',
    timeZoneId: 'America/Los_Angeles',
  },
  '98199': {
    zip: '98199',
    city: 'Seattle',
    state: 'WA',
    latitude: '47.649774',
    longitude: '-122.398314',
    timeZoneId: 'America/Los_Angeles',
  },
  '98201': {
    zip: '98201',
    city: 'Everett',
    state: 'WA',
    latitude: '47.988594',
    longitude: '-122.201789',
    timeZoneId: 'America/Los_Angeles',
  },
  '98203': {
    zip: '98203',
    city: 'Everett',
    state: 'WA',
    latitude: '47.945956',
    longitude: '-122.229877',
    timeZoneId: 'America/Los_Angeles',
  },
  '98204': {
    zip: '98204',
    city: 'Everett',
    state: 'WA',
    latitude: '47.898431',
    longitude: '-122.254822',
    timeZoneId: 'America/Los_Angeles',
  },
  '98206': {
    zip: '98206',
    city: 'Everett',
    state: 'WA',
    latitude: '47.92563',
    longitude: '-122.22639',
    timeZoneId: 'America/Los_Angeles',
  },
  '98207': {
    zip: '98207',
    city: 'Everett',
    state: 'WA',
    latitude: '47.959977',
    longitude: '-122.196827',
    timeZoneId: 'America/Los_Angeles',
  },
  '98208': {
    zip: '98208',
    city: 'Everett',
    state: 'WA',
    latitude: '47.894914',
    longitude: '-122.193377',
    timeZoneId: 'America/Los_Angeles',
  },
  '98213': {
    zip: '98213',
    city: 'Everett',
    state: 'WA',
    latitude: '47.940819',
    longitude: '-122.234719',
    timeZoneId: 'America/Los_Angeles',
  },
  '98220': {
    zip: '98220',
    city: 'Acme',
    state: 'WA',
    latitude: '48.687032',
    longitude: '-122.194051',
    timeZoneId: 'America/Los_Angeles',
  },
  '98221': {
    zip: '98221',
    city: 'Anacortes',
    state: 'WA',
    latitude: '48.4989',
    longitude: '-122.6073',
    timeZoneId: 'America/Los_Angeles',
  },
  '98222': {
    zip: '98222',
    city: 'Blakely Island',
    state: 'WA',
    latitude: '48.609071',
    longitude: '-122.921563',
    timeZoneId: 'America/Los_Angeles',
  },
  '98223': {
    zip: '98223',
    city: 'Arlington',
    state: 'WA',
    latitude: '48.1818',
    longitude: '-121.97964',
    timeZoneId: 'America/Los_Angeles',
  },
  '98224': {
    zip: '98224',
    city: 'Baring',
    state: 'WA',
    latitude: '47.766661',
    longitude: '-121.479114',
    timeZoneId: 'America/Los_Angeles',
  },
  '98225': {
    zip: '98225',
    city: 'Bellingham',
    state: 'WA',
    latitude: '48.746494',
    longitude: '-122.493289',
    timeZoneId: 'America/Los_Angeles',
  },
  '98226': {
    zip: '98226',
    city: 'Bellingham',
    state: 'WA',
    latitude: '48.763345',
    longitude: '-122.443649',
    timeZoneId: 'America/Los_Angeles',
  },
  '98227': {
    zip: '98227',
    city: 'Bellingham',
    state: 'WA',
    latitude: '48.800066',
    longitude: '-122.407858',
    timeZoneId: 'America/Los_Angeles',
  },
  '98228': {
    zip: '98228',
    city: 'Bellingham',
    state: 'WA',
    latitude: '48.743723',
    longitude: '-122.467665',
    timeZoneId: 'America/Los_Angeles',
  },
  '98229': {
    zip: '98229',
    city: 'Bellingham',
    state: 'WA',
    latitude: '48.765765',
    longitude: '-122.442175',
    timeZoneId: 'America/Los_Angeles',
  },
  '98230': {
    zip: '98230',
    city: 'Blaine',
    state: 'WA',
    latitude: '48.961751',
    longitude: '-122.743093',
    timeZoneId: 'America/Los_Angeles',
  },
  '98231': {
    zip: '98231',
    city: 'Blaine',
    state: 'WA',
    latitude: '48.989956',
    longitude: '-122.761204',
    timeZoneId: 'America/Los_Angeles',
  },
  '98232': {
    zip: '98232',
    city: 'Bow',
    state: 'WA',
    latitude: '48.56238',
    longitude: '-122.44494',
    timeZoneId: 'America/Los_Angeles',
  },
  '98233': {
    zip: '98233',
    city: 'Burlington',
    state: 'WA',
    latitude: '48.50328',
    longitude: '-122.36046',
    timeZoneId: 'America/Los_Angeles',
  },
  '98235': {
    zip: '98235',
    city: 'Clearlake',
    state: 'WA',
    latitude: '48.459276',
    longitude: '-122.237986',
    timeZoneId: 'America/Los_Angeles',
  },
  '98236': {
    zip: '98236',
    city: 'Clinton',
    state: 'WA',
    latitude: '47.9595',
    longitude: '-122.40211',
    timeZoneId: 'America/Los_Angeles',
  },
  '98237': {
    zip: '98237',
    city: 'Concrete',
    state: 'WA',
    latitude: '48.5223',
    longitude: '-121.75335',
    timeZoneId: 'America/Los_Angeles',
  },
  '98238': {
    zip: '98238',
    city: 'Conway',
    state: 'WA',
    latitude: '48.338751',
    longitude: '-122.343828',
    timeZoneId: 'America/Los_Angeles',
  },
  '98239': {
    zip: '98239',
    city: 'Coupeville',
    state: 'WA',
    latitude: '48.184186',
    longitude: '-122.613686',
    timeZoneId: 'America/Los_Angeles',
  },
  '98240': {
    zip: '98240',
    city: 'Custer',
    state: 'WA',
    latitude: '48.943935',
    longitude: '-122.629607',
    timeZoneId: 'America/Los_Angeles',
  },
  '98241': {
    zip: '98241',
    city: 'Darrington',
    state: 'WA',
    latitude: '48.260325',
    longitude: '-121.598034',
    timeZoneId: 'America/Los_Angeles',
  },
  '98243': {
    zip: '98243',
    city: 'Deer Harbor',
    state: 'WA',
    latitude: '48.621122',
    longitude: '-123.006088',
    timeZoneId: 'America/Los_Angeles',
  },
  '98244': {
    zip: '98244',
    city: 'Deming',
    state: 'WA',
    latitude: '48.82422',
    longitude: '-121.95834',
    timeZoneId: 'America/Los_Angeles',
  },
  '98245': {
    zip: '98245',
    city: 'Eastsound',
    state: 'WA',
    latitude: '48.707485',
    longitude: '-122.916139',
    timeZoneId: 'America/Los_Angeles',
  },
  '98247': {
    zip: '98247',
    city: 'Everson',
    state: 'WA',
    latitude: '48.906641',
    longitude: '-122.329968',
    timeZoneId: 'America/Los_Angeles',
  },
  '98248': {
    zip: '98248',
    city: 'Ferndale',
    state: 'WA',
    latitude: '48.8583',
    longitude: '-122.606024',
    timeZoneId: 'America/Los_Angeles',
  },
  '98249': {
    zip: '98249',
    city: 'Freeland',
    state: 'WA',
    latitude: '48.023245',
    longitude: '-122.547172',
    timeZoneId: 'America/Los_Angeles',
  },
  '98250': {
    zip: '98250',
    city: 'Friday Harbor',
    state: 'WA',
    latitude: '48.543044',
    longitude: '-123.068308',
    timeZoneId: 'America/Los_Angeles',
  },
  '98251': {
    zip: '98251',
    city: 'Gold Bar',
    state: 'WA',
    latitude: '47.830143',
    longitude: '-121.627916',
    timeZoneId: 'America/Los_Angeles',
  },
  '98252': {
    zip: '98252',
    city: 'Granite Falls',
    state: 'WA',
    latitude: '48.053157',
    longitude: '-121.683767',
    timeZoneId: 'America/Los_Angeles',
  },
  '98253': {
    zip: '98253',
    city: 'Greenbank',
    state: 'WA',
    latitude: '48.087525',
    longitude: '-122.577441',
    timeZoneId: 'America/Los_Angeles',
  },
  '98255': {
    zip: '98255',
    city: 'Hamilton',
    state: 'WA',
    latitude: '48.522344',
    longitude: '-121.989235',
    timeZoneId: 'America/Los_Angeles',
  },
  '98256': {
    zip: '98256',
    city: 'Index',
    state: 'WA',
    latitude: '47.818639',
    longitude: '-121.545223',
    timeZoneId: 'America/Los_Angeles',
  },
  '98257': {
    zip: '98257',
    city: 'La Conner',
    state: 'WA',
    latitude: '48.391688',
    longitude: '-122.513106',
    timeZoneId: 'America/Los_Angeles',
  },
  '98258': {
    zip: '98258',
    city: 'Lake Stevens',
    state: 'WA',
    latitude: '48.027419',
    longitude: '-122.062363',
    timeZoneId: 'America/Los_Angeles',
  },
  '98259': {
    zip: '98259',
    city: 'North Lakewood',
    state: 'WA',
    latitude: '48.164986',
    longitude: '-122.2992',
    timeZoneId: 'America/Los_Angeles',
  },
  '98260': {
    zip: '98260',
    city: 'Langley',
    state: 'WA',
    latitude: '48.033663',
    longitude: '-122.450203',
    timeZoneId: 'America/Los_Angeles',
  },
  '98261': {
    zip: '98261',
    city: 'Lopez Island',
    state: 'WA',
    latitude: '48.447828',
    longitude: '-122.842452',
    timeZoneId: 'America/Los_Angeles',
  },
  '98262': {
    zip: '98262',
    city: 'Lummi Island',
    state: 'WA',
    latitude: '48.708719',
    longitude: '-122.67649',
    timeZoneId: 'America/Los_Angeles',
  },
  '98263': {
    zip: '98263',
    city: 'Lyman',
    state: 'WA',
    latitude: '48.529335',
    longitude: '-122.064232',
    timeZoneId: 'America/Los_Angeles',
  },
  '98264': {
    zip: '98264',
    city: 'Lynden',
    state: 'WA',
    latitude: '48.94081',
    longitude: '-122.459641',
    timeZoneId: 'America/Los_Angeles',
  },
  '98266': {
    zip: '98266',
    city: 'Maple Falls',
    state: 'WA',
    latitude: '48.928922',
    longitude: '-122.149469',
    timeZoneId: 'America/Los_Angeles',
  },
  '98267': {
    zip: '98267',
    city: 'Marblemount',
    state: 'WA',
    latitude: '48.535003',
    longitude: '-121.419068',
    timeZoneId: 'America/Los_Angeles',
  },
  '98270': {
    zip: '98270',
    city: 'Marysville',
    state: 'WA',
    latitude: '48.06088',
    longitude: '-122.153881',
    timeZoneId: 'America/Los_Angeles',
  },
  '98271': {
    zip: '98271',
    city: 'Marysville',
    state: 'WA',
    latitude: '48.098404',
    longitude: '-122.237318',
    timeZoneId: 'America/Los_Angeles',
  },
  '98272': {
    zip: '98272',
    city: 'Monroe',
    state: 'WA',
    latitude: '47.857085',
    longitude: '-121.937678',
    timeZoneId: 'America/Los_Angeles',
  },
  '98273': {
    zip: '98273',
    city: 'Mount Vernon',
    state: 'WA',
    latitude: '48.407114',
    longitude: '-122.324434',
    timeZoneId: 'America/Los_Angeles',
  },
  '98274': {
    zip: '98274',
    city: 'Mount Vernon',
    state: 'WA',
    latitude: '48.418364',
    longitude: '-122.312369',
    timeZoneId: 'America/Los_Angeles',
  },
  '98275': {
    zip: '98275',
    city: 'Mukilteo',
    state: 'WA',
    latitude: '47.907142',
    longitude: '-122.309121',
    timeZoneId: 'America/Los_Angeles',
  },
  '98276': {
    zip: '98276',
    city: 'Nooksack',
    state: 'WA',
    latitude: '48.929523',
    longitude: '-122.320315',
    timeZoneId: 'America/Los_Angeles',
  },
  '98277': {
    zip: '98277',
    city: 'Oak Harbor',
    state: 'WA',
    latitude: '48.302951',
    longitude: '-122.640714',
    timeZoneId: 'America/Los_Angeles',
  },
  '98278': {
    zip: '98278',
    city: 'Oak Harbor',
    state: 'WA',
    latitude: '48.28152',
    longitude: '-122.625637',
    timeZoneId: 'America/Los_Angeles',
  },
  '98279': {
    zip: '98279',
    city: 'Olga',
    state: 'WA',
    latitude: '48.659198',
    longitude: '-122.826703',
    timeZoneId: 'America/Los_Angeles',
  },
  '98280': {
    zip: '98280',
    city: 'Orcas',
    state: 'WA',
    latitude: '48.605392',
    longitude: '-122.881094',
    timeZoneId: 'America/Los_Angeles',
  },
  '98281': {
    zip: '98281',
    city: 'Point Roberts',
    state: 'WA',
    latitude: '48.99186',
    longitude: '-123.057602',
    timeZoneId: 'America/Los_Angeles',
  },
  '98282': {
    zip: '98282',
    city: 'Camano Island',
    state: 'WA',
    latitude: '48.174882',
    longitude: '-122.521693',
    timeZoneId: 'America/Los_Angeles',
  },
  '98283': {
    zip: '98283',
    city: 'Rockport',
    state: 'WA',
    latitude: '48.4824',
    longitude: '-121.52712',
    timeZoneId: 'America/Los_Angeles',
  },
  '98284': {
    zip: '98284',
    city: 'Sedro Woolley',
    state: 'WA',
    latitude: '48.524748',
    longitude: '-122.181496',
    timeZoneId: 'America/Los_Angeles',
  },
  '98286': {
    zip: '98286',
    city: 'Shaw Island',
    state: 'WA',
    latitude: '48.585278',
    longitude: '-122.926601',
    timeZoneId: 'America/Los_Angeles',
  },
  '98287': {
    zip: '98287',
    city: 'Silvana',
    state: 'WA',
    latitude: '48.190514',
    longitude: '-122.250316',
    timeZoneId: 'America/Los_Angeles',
  },
  '98288': {
    zip: '98288',
    city: 'Skykomish',
    state: 'WA',
    latitude: '47.7039',
    longitude: '-121.30458',
    timeZoneId: 'America/Los_Angeles',
  },
  '98290': {
    zip: '98290',
    city: 'Snohomish',
    state: 'WA',
    latitude: '47.939532',
    longitude: '-122.016706',
    timeZoneId: 'America/Los_Angeles',
  },
  '98291': {
    zip: '98291',
    city: 'Snohomish',
    state: 'WA',
    latitude: '47.928974',
    longitude: '-122.099893',
    timeZoneId: 'America/Los_Angeles',
  },
  '98292': {
    zip: '98292',
    city: 'Stanwood',
    state: 'WA',
    latitude: '48.17928',
    longitude: '-122.37144',
    timeZoneId: 'America/Los_Angeles',
  },
  '98293': {
    zip: '98293',
    city: 'Startup',
    state: 'WA',
    latitude: '47.871806',
    longitude: '-121.746553',
    timeZoneId: 'America/Los_Angeles',
  },
  '98294': {
    zip: '98294',
    city: 'Sultan',
    state: 'WA',
    latitude: '47.866673',
    longitude: '-121.772083',
    timeZoneId: 'America/Los_Angeles',
  },
  '98295': {
    zip: '98295',
    city: 'Sumas',
    state: 'WA',
    latitude: '48.964647',
    longitude: '-122.202847',
    timeZoneId: 'America/Los_Angeles',
  },
  '98296': {
    zip: '98296',
    city: 'Snohomish',
    state: 'WA',
    latitude: '47.822897',
    longitude: '-122.118251',
    timeZoneId: 'America/Los_Angeles',
  },
  '98297': {
    zip: '98297',
    city: 'Waldron',
    state: 'WA',
    latitude: '48.688854',
    longitude: '-123.03716',
    timeZoneId: 'America/Los_Angeles',
  },
  '98303': {
    zip: '98303',
    city: 'Anderson Island',
    state: 'WA',
    latitude: '47.164386',
    longitude: '-122.695768',
    timeZoneId: 'America/Los_Angeles',
  },
  '98304': {
    zip: '98304',
    city: 'Ashford',
    state: 'WA',
    latitude: '46.741603',
    longitude: '-121.974327',
    timeZoneId: 'America/Los_Angeles',
  },
  '98305': {
    zip: '98305',
    city: 'Beaver',
    state: 'WA',
    latitude: '48.06348',
    longitude: '-124.296',
    timeZoneId: 'America/Los_Angeles',
  },
  '98310': {
    zip: '98310',
    city: 'Bremerton',
    state: 'WA',
    latitude: '47.610346',
    longitude: '-122.631677',
    timeZoneId: 'America/Los_Angeles',
  },
  '98311': {
    zip: '98311',
    city: 'Bremerton',
    state: 'WA',
    latitude: '47.625445',
    longitude: '-122.638533',
    timeZoneId: 'America/Los_Angeles',
  },
  '98312': {
    zip: '98312',
    city: 'Bremerton',
    state: 'WA',
    latitude: '47.5746',
    longitude: '-122.81058',
    timeZoneId: 'America/Los_Angeles',
  },
  '98314': {
    zip: '98314',
    city: 'Bremerton',
    state: 'WA',
    latitude: '47.560627',
    longitude: '-122.648934',
    timeZoneId: 'America/Los_Angeles',
  },
  '98315': {
    zip: '98315',
    city: 'Silverdale',
    state: 'WA',
    latitude: '47.69706',
    longitude: '-122.72232',
    timeZoneId: 'America/Los_Angeles',
  },
  '98320': {
    zip: '98320',
    city: 'Brinnon',
    state: 'WA',
    latitude: '47.692975',
    longitude: '-122.921442',
    timeZoneId: 'America/Los_Angeles',
  },
  '98321': {
    zip: '98321',
    city: 'Buckley',
    state: 'WA',
    latitude: '47.157444',
    longitude: '-122.073282',
    timeZoneId: 'America/Los_Angeles',
  },
  '98322': {
    zip: '98322',
    city: 'Burley',
    state: 'WA',
    latitude: '47.412292',
    longitude: '-122.629833',
    timeZoneId: 'America/Los_Angeles',
  },
  '98323': {
    zip: '98323',
    city: 'Carbonado',
    state: 'WA',
    latitude: '47.069692',
    longitude: '-122.046626',
    timeZoneId: 'America/Los_Angeles',
  },
  '98324': {
    zip: '98324',
    city: 'Carlsborg',
    state: 'WA',
    latitude: '48.106925',
    longitude: '-123.175464',
    timeZoneId: 'America/Los_Angeles',
  },
  '98325': {
    zip: '98325',
    city: 'Chimacum',
    state: 'WA',
    latitude: '47.978657',
    longitude: '-122.77697',
    timeZoneId: 'America/Los_Angeles',
  },
  '98326': {
    zip: '98326',
    city: 'Clallam Bay',
    state: 'WA',
    latitude: '48.171357',
    longitude: '-124.2799',
    timeZoneId: 'America/Los_Angeles',
  },
  '98327': {
    zip: '98327',
    city: 'Dupont',
    state: 'WA',
    latitude: '47.094572',
    longitude: '-122.642466',
    timeZoneId: 'America/Los_Angeles',
  },
  '98328': {
    zip: '98328',
    city: 'Eatonville',
    state: 'WA',
    latitude: '46.86714',
    longitude: '-122.289',
    timeZoneId: 'America/Los_Angeles',
  },
  '98329': {
    zip: '98329',
    city: 'Gig Harbor',
    state: 'WA',
    latitude: '47.373068',
    longitude: '-122.712454',
    timeZoneId: 'America/Los_Angeles',
  },
  '98330': {
    zip: '98330',
    city: 'Elbe',
    state: 'WA',
    latitude: '46.764306',
    longitude: '-122.135706',
    timeZoneId: 'America/Los_Angeles',
  },
  '98331': {
    zip: '98331',
    city: 'Forks',
    state: 'WA',
    latitude: '47.92578',
    longitude: '-124.4544',
    timeZoneId: 'America/Los_Angeles',
  },
  '98332': {
    zip: '98332',
    city: 'Gig Harbor',
    state: 'WA',
    latitude: '47.358119',
    longitude: '-122.594158',
    timeZoneId: 'America/Los_Angeles',
  },
  '98333': {
    zip: '98333',
    city: 'Fox Island',
    state: 'WA',
    latitude: '47.250837',
    longitude: '-122.623135',
    timeZoneId: 'America/Los_Angeles',
  },
  '98335': {
    zip: '98335',
    city: 'Gig Harbor',
    state: 'WA',
    latitude: '47.29386',
    longitude: '-122.62182',
    timeZoneId: 'America/Los_Angeles',
  },
  '98336': {
    zip: '98336',
    city: 'Glenoma',
    state: 'WA',
    latitude: '46.526804',
    longitude: '-122.119918',
    timeZoneId: 'America/Los_Angeles',
  },
  '98337': {
    zip: '98337',
    city: 'Bremerton',
    state: 'WA',
    latitude: '47.570043',
    longitude: '-122.637038',
    timeZoneId: 'America/Los_Angeles',
  },
  '98338': {
    zip: '98338',
    city: 'Graham',
    state: 'WA',
    latitude: '47.033524',
    longitude: '-122.293101',
    timeZoneId: 'America/Los_Angeles',
  },
  '98339': {
    zip: '98339',
    city: 'Port Hadlock',
    state: 'WA',
    latitude: '48.027885',
    longitude: '-122.759844',
    timeZoneId: 'America/Los_Angeles',
  },
  '98340': {
    zip: '98340',
    city: 'Hansville',
    state: 'WA',
    latitude: '47.905828',
    longitude: '-122.573462',
    timeZoneId: 'America/Los_Angeles',
  },
  '98342': {
    zip: '98342',
    city: 'Indianola',
    state: 'WA',
    latitude: '47.760123',
    longitude: '-122.531403',
    timeZoneId: 'America/Los_Angeles',
  },
  '98343': {
    zip: '98343',
    city: 'Joyce',
    state: 'WA',
    latitude: '48.139728',
    longitude: '-123.732852',
    timeZoneId: 'America/Los_Angeles',
  },
  '98344': {
    zip: '98344',
    city: 'Kapowsin',
    state: 'WA',
    latitude: '46.986722',
    longitude: '-122.227835',
    timeZoneId: 'America/Los_Angeles',
  },
  '98345': {
    zip: '98345',
    city: 'Keyport',
    state: 'WA',
    latitude: '47.704222',
    longitude: '-122.628278',
    timeZoneId: 'America/Los_Angeles',
  },
  '98346': {
    zip: '98346',
    city: 'Kingston',
    state: 'WA',
    latitude: '47.805964',
    longitude: '-122.524971',
    timeZoneId: 'America/Los_Angeles',
  },
  '98348': {
    zip: '98348',
    city: 'La Grande',
    state: 'WA',
    latitude: '46.819889',
    longitude: '-122.277126',
    timeZoneId: 'America/Los_Angeles',
  },
  '98349': {
    zip: '98349',
    city: 'Lakebay',
    state: 'WA',
    latitude: '47.28078',
    longitude: '-122.77344',
    timeZoneId: 'America/Los_Angeles',
  },
  '98350': {
    zip: '98350',
    city: 'La Push',
    state: 'WA',
    latitude: '47.908674',
    longitude: '-124.636562',
    timeZoneId: 'America/Los_Angeles',
  },
  '98351': {
    zip: '98351',
    city: 'Longbranch',
    state: 'WA',
    latitude: '47.257222',
    longitude: '-122.757222',
    timeZoneId: 'America/Los_Angeles',
  },
  '98352': {
    zip: '98352',
    city: 'Sumner',
    state: 'WA',
    latitude: '47.164325',
    longitude: '-122.242368',
    timeZoneId: 'America/Los_Angeles',
  },
  '98353': {
    zip: '98353',
    city: 'Manchester',
    state: 'WA',
    latitude: '47.554483',
    longitude: '-122.545778',
    timeZoneId: 'America/Los_Angeles',
  },
  '98354': {
    zip: '98354',
    city: 'Milton',
    state: 'WA',
    latitude: '47.249097',
    longitude: '-122.316421',
    timeZoneId: 'America/Los_Angeles',
  },
  '98355': {
    zip: '98355',
    city: 'Mineral',
    state: 'WA',
    latitude: '46.715218',
    longitude: '-122.184152',
    timeZoneId: 'America/Los_Angeles',
  },
  '98356': {
    zip: '98356',
    city: 'Morton',
    state: 'WA',
    latitude: '46.560848',
    longitude: '-122.28734',
    timeZoneId: 'America/Los_Angeles',
  },
  '98357': {
    zip: '98357',
    city: 'Neah Bay',
    state: 'WA',
    latitude: '48.369349',
    longitude: '-124.626924',
    timeZoneId: 'America/Los_Angeles',
  },
  '98358': {
    zip: '98358',
    city: 'Nordland',
    state: 'WA',
    latitude: '48.067708',
    longitude: '-122.694175',
    timeZoneId: 'America/Los_Angeles',
  },
  '98359': {
    zip: '98359',
    city: 'Olalla',
    state: 'WA',
    latitude: '47.427546',
    longitude: '-122.575624',
    timeZoneId: 'America/Los_Angeles',
  },
  '98360': {
    zip: '98360',
    city: 'Orting',
    state: 'WA',
    latitude: '47.087937',
    longitude: '-122.185345',
    timeZoneId: 'America/Los_Angeles',
  },
  '98361': {
    zip: '98361',
    city: 'Packwood',
    state: 'WA',
    latitude: '46.639263',
    longitude: '-121.666784',
    timeZoneId: 'America/Los_Angeles',
  },
  '98362': {
    zip: '98362',
    city: 'Port Angeles',
    state: 'WA',
    latitude: '48.04386',
    longitude: '-123.5052',
    timeZoneId: 'America/Los_Angeles',
  },
  '98363': {
    zip: '98363',
    city: 'Port Angeles',
    state: 'WA',
    latitude: '48.11841',
    longitude: '-123.48061',
    timeZoneId: 'America/Los_Angeles',
  },
  '98364': {
    zip: '98364',
    city: 'Port Gamble',
    state: 'WA',
    latitude: '47.836944',
    longitude: '-122.594391',
    timeZoneId: 'America/Los_Angeles',
  },
  '98365': {
    zip: '98365',
    city: 'Port Ludlow',
    state: 'WA',
    latitude: '47.915179',
    longitude: '-122.688171',
    timeZoneId: 'America/Los_Angeles',
  },
  '98366': {
    zip: '98366',
    city: 'Port Orchard',
    state: 'WA',
    latitude: '47.498978',
    longitude: '-122.620101',
    timeZoneId: 'America/Los_Angeles',
  },
  '98367': {
    zip: '98367',
    city: 'Port Orchard',
    state: 'WA',
    latitude: '47.531216',
    longitude: '-122.640364',
    timeZoneId: 'America/Los_Angeles',
  },
  '98368': {
    zip: '98368',
    city: 'Port Townsend',
    state: 'WA',
    latitude: '48.116667',
    longitude: '-122.783333',
    timeZoneId: 'America/Los_Angeles',
  },
  '98370': {
    zip: '98370',
    city: 'Poulsbo',
    state: 'WA',
    latitude: '47.742377',
    longitude: '-122.634666',
    timeZoneId: 'America/Los_Angeles',
  },
  '98371': {
    zip: '98371',
    city: 'Puyallup',
    state: 'WA',
    latitude: '47.189343',
    longitude: '-122.305994',
    timeZoneId: 'America/Los_Angeles',
  },
  '98372': {
    zip: '98372',
    city: 'Puyallup',
    state: 'WA',
    latitude: '47.205996',
    longitude: '-122.271245',
    timeZoneId: 'America/Los_Angeles',
  },
  '98373': {
    zip: '98373',
    city: 'Puyallup',
    state: 'WA',
    latitude: '47.131712',
    longitude: '-122.32328',
    timeZoneId: 'America/Los_Angeles',
  },
  '98374': {
    zip: '98374',
    city: 'Puyallup',
    state: 'WA',
    latitude: '47.14203',
    longitude: '-122.26679',
    timeZoneId: 'America/Los_Angeles',
  },
  '98375': {
    zip: '98375',
    city: 'Puyallup',
    state: 'WA',
    latitude: '47.11881',
    longitude: '-122.30643',
    timeZoneId: 'America/Los_Angeles',
  },
  '98376': {
    zip: '98376',
    city: 'Quilcene',
    state: 'WA',
    latitude: '47.8137',
    longitude: '-122.85048',
    timeZoneId: 'America/Los_Angeles',
  },
  '98377': {
    zip: '98377',
    city: 'Randle',
    state: 'WA',
    latitude: '46.521842',
    longitude: '-121.903538',
    timeZoneId: 'America/Los_Angeles',
  },
  '98378': {
    zip: '98378',
    city: 'Retsil',
    state: 'WA',
    latitude: '47.544889',
    longitude: '-122.618001',
    timeZoneId: 'America/Los_Angeles',
  },
  '98380': {
    zip: '98380',
    city: 'Seabeck',
    state: 'WA',
    latitude: '47.634664',
    longitude: '-122.844456',
    timeZoneId: 'America/Los_Angeles',
  },
  '98381': {
    zip: '98381',
    city: 'Sekiu',
    state: 'WA',
    latitude: '48.265945',
    longitude: '-124.306682',
    timeZoneId: 'America/Los_Angeles',
  },
  '98382': {
    zip: '98382',
    city: 'Sequim',
    state: 'WA',
    latitude: '48.06858',
    longitude: '-123.05856',
    timeZoneId: 'America/Los_Angeles',
  },
  '98383': {
    zip: '98383',
    city: 'Silverdale',
    state: 'WA',
    latitude: '47.663935',
    longitude: '-122.707438',
    timeZoneId: 'America/Los_Angeles',
  },
  '98384': {
    zip: '98384',
    city: 'South Colby',
    state: 'WA',
    latitude: '47.51947',
    longitude: '-122.538947',
    timeZoneId: 'America/Los_Angeles',
  },
  '98385': {
    zip: '98385',
    city: 'South Prairie',
    state: 'WA',
    latitude: '47.133592',
    longitude: '-122.098445',
    timeZoneId: 'America/Los_Angeles',
  },
  '98386': {
    zip: '98386',
    city: 'Southworth',
    state: 'WA',
    latitude: '47.509454',
    longitude: '-122.501564',
    timeZoneId: 'America/Los_Angeles',
  },
  '98387': {
    zip: '98387',
    city: 'Spanaway',
    state: 'WA',
    latitude: '47.077576',
    longitude: '-122.396089',
    timeZoneId: 'America/Los_Angeles',
  },
  '98388': {
    zip: '98388',
    city: 'Steilacoom',
    state: 'WA',
    latitude: '47.170637',
    longitude: '-122.588055',
    timeZoneId: 'America/Los_Angeles',
  },
  '98390': {
    zip: '98390',
    city: 'Sumner',
    state: 'WA',
    latitude: '47.17926',
    longitude: '-122.19204',
    timeZoneId: 'America/Los_Angeles',
  },
  '98391': {
    zip: '98391',
    city: 'Bonney Lake',
    state: 'WA',
    latitude: '47.1874',
    longitude: '-122.1645',
    timeZoneId: 'America/Los_Angeles',
  },
  '98392': {
    zip: '98392',
    city: 'Suquamish',
    state: 'WA',
    latitude: '47.736954',
    longitude: '-122.560402',
    timeZoneId: 'America/Los_Angeles',
  },
  '98393': {
    zip: '98393',
    city: 'Tracyton',
    state: 'WA',
    latitude: '47.620108',
    longitude: '-122.651269',
    timeZoneId: 'America/Los_Angeles',
  },
  '98394': {
    zip: '98394',
    city: 'Vaughn',
    state: 'WA',
    latitude: '47.329286',
    longitude: '-122.76912',
    timeZoneId: 'America/Los_Angeles',
  },
  '98395': {
    zip: '98395',
    city: 'Wauna',
    state: 'WA',
    latitude: '47.363283',
    longitude: '-122.700059',
    timeZoneId: 'America/Los_Angeles',
  },
  '98396': {
    zip: '98396',
    city: 'Wilkeson',
    state: 'WA',
    latitude: '47.107954',
    longitude: '-122.040929',
    timeZoneId: 'America/Los_Angeles',
  },
  '98397': {
    zip: '98397',
    city: 'Longmire',
    state: 'WA',
    latitude: '46.750763',
    longitude: '-121.812001',
    timeZoneId: 'America/Los_Angeles',
  },
  '98398': {
    zip: '98398',
    city: 'Paradise Inn',
    state: 'WA',
    latitude: '46.782679',
    longitude: '-121.742713',
    timeZoneId: 'America/Los_Angeles',
  },
  '98401': {
    zip: '98401',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.255867',
    longitude: '-122.441654',
    timeZoneId: 'America/Los_Angeles',
  },
  '98402': {
    zip: '98402',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.246812',
    longitude: '-122.44074',
    timeZoneId: 'America/Los_Angeles',
  },
  '98403': {
    zip: '98403',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.267293',
    longitude: '-122.458412',
    timeZoneId: 'America/Los_Angeles',
  },
  '98404': {
    zip: '98404',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.212637',
    longitude: '-122.40959',
    timeZoneId: 'America/Los_Angeles',
  },
  '98405': {
    zip: '98405',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.249001',
    longitude: '-122.46533',
    timeZoneId: 'America/Los_Angeles',
  },
  '98406': {
    zip: '98406',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.263195',
    longitude: '-122.507387',
    timeZoneId: 'America/Los_Angeles',
  },
  '98407': {
    zip: '98407',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.283011',
    longitude: '-122.504689',
    timeZoneId: 'America/Los_Angeles',
  },
  '98408': {
    zip: '98408',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.210649',
    longitude: '-122.452887',
    timeZoneId: 'America/Los_Angeles',
  },
  '98409': {
    zip: '98409',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.207636',
    longitude: '-122.478327',
    timeZoneId: 'America/Los_Angeles',
  },
  '98411': {
    zip: '98411',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.219564',
    longitude: '-122.474047',
    timeZoneId: 'America/Los_Angeles',
  },
  '98412': {
    zip: '98412',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.174534',
    longitude: '-122.441516',
    timeZoneId: 'America/Los_Angeles',
  },
  '98413': {
    zip: '98413',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.208479',
    longitude: '-122.480466',
    timeZoneId: 'America/Los_Angeles',
  },
  '98415': {
    zip: '98415',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.250283',
    longitude: '-122.448755',
    timeZoneId: 'America/Los_Angeles',
  },
  '98416': {
    zip: '98416',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.265185',
    longitude: '-122.482216',
    timeZoneId: 'America/Los_Angeles',
  },
  '98417': {
    zip: '98417',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.255131',
    longitude: '-122.44165',
    timeZoneId: 'America/Los_Angeles',
  },
  '98418': {
    zip: '98418',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.222751',
    longitude: '-122.441183',
    timeZoneId: 'America/Los_Angeles',
  },
  '98419': {
    zip: '98419',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.255131',
    longitude: '-122.44165',
    timeZoneId: 'America/Los_Angeles',
  },
  '98421': {
    zip: '98421',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.250147',
    longitude: '-122.4131',
    timeZoneId: 'America/Los_Angeles',
  },
  '98422': {
    zip: '98422',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.293639',
    longitude: '-122.392048',
    timeZoneId: 'America/Los_Angeles',
  },
  '98424': {
    zip: '98424',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.23836',
    longitude: '-122.36202',
    timeZoneId: 'America/Los_Angeles',
  },
  '98430': {
    zip: '98430',
    city: 'Camp Murray',
    state: 'WA',
    latitude: '47.255899',
    longitude: '-122.440697',
    timeZoneId: 'America/Los_Angeles',
  },
  '98431': {
    zip: '98431',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.055515',
    longitude: '-122.552079',
    timeZoneId: 'America/Los_Angeles',
  },
  '98433': {
    zip: '98433',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.11854',
    longitude: '-122.57898',
    timeZoneId: 'America/Los_Angeles',
  },
  '98438': {
    zip: '98438',
    city: 'McChord AFB',
    state: 'WA',
    latitude: '47.253',
    longitude: '-122.44302',
    timeZoneId: 'America/Los_Angeles',
  },
  '98439': {
    zip: '98439',
    city: 'Lakewood',
    state: 'WA',
    latitude: '47.126912',
    longitude: '-122.522573',
    timeZoneId: 'America/Los_Angeles',
  },
  '98443': {
    zip: '98443',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.205924',
    longitude: '-122.372561',
    timeZoneId: 'America/Los_Angeles',
  },
  '98444': {
    zip: '98444',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.15066',
    longitude: '-122.447434',
    timeZoneId: 'America/Los_Angeles',
  },
  '98445': {
    zip: '98445',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.137362',
    longitude: '-122.409805',
    timeZoneId: 'America/Los_Angeles',
  },
  '98446': {
    zip: '98446',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.149046',
    longitude: '-122.377224',
    timeZoneId: 'America/Los_Angeles',
  },
  '98447': {
    zip: '98447',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.14703',
    longitude: '-122.445643',
    timeZoneId: 'America/Los_Angeles',
  },
  '98448': {
    zip: '98448',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.145996',
    longitude: '-122.436154',
    timeZoneId: 'America/Los_Angeles',
  },
  '98464': {
    zip: '98464',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.22545',
    longitude: '-122.537309',
    timeZoneId: 'America/Los_Angeles',
  },
  '98465': {
    zip: '98465',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.248993',
    longitude: '-122.528516',
    timeZoneId: 'America/Los_Angeles',
  },
  '98466': {
    zip: '98466',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.225803',
    longitude: '-122.534108',
    timeZoneId: 'America/Los_Angeles',
  },
  '98467': {
    zip: '98467',
    city: 'University Place',
    state: 'WA',
    latitude: '47.235833',
    longitude: '-122.549167',
    timeZoneId: 'America/Los_Angeles',
  },
  '98471': {
    zip: '98471',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.252509',
    longitude: '-122.476476',
    timeZoneId: 'America/Los_Angeles',
  },
  '98481': {
    zip: '98481',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.222822',
    longitude: '-122.47211',
    timeZoneId: 'America/Los_Angeles',
  },
  '98490': {
    zip: '98490',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.255131',
    longitude: '-122.44165',
    timeZoneId: 'America/Los_Angeles',
  },
  '98493': {
    zip: '98493',
    city: 'Tacoma',
    state: 'WA',
    latitude: '47.053364',
    longitude: '-122.551238',
    timeZoneId: 'America/Los_Angeles',
  },
  '98496': {
    zip: '98496',
    city: 'Lakewood',
    state: 'WA',
    latitude: '47.16177',
    longitude: '-122.514397',
    timeZoneId: 'America/Los_Angeles',
  },
  '98497': {
    zip: '98497',
    city: 'Lakewood',
    state: 'WA',
    latitude: '47.177943',
    longitude: '-122.547217',
    timeZoneId: 'America/Los_Angeles',
  },
  '98498': {
    zip: '98498',
    city: 'Lakewood',
    state: 'WA',
    latitude: '47.162978',
    longitude: '-122.560507',
    timeZoneId: 'America/Los_Angeles',
  },
  '98499': {
    zip: '98499',
    city: 'Lakewood',
    state: 'WA',
    latitude: '47.162503',
    longitude: '-122.510156',
    timeZoneId: 'America/Los_Angeles',
  },
  '98501': {
    zip: '98501',
    city: 'Olympia',
    state: 'WA',
    latitude: '46.99086',
    longitude: '-122.87388',
    timeZoneId: 'America/Los_Angeles',
  },
  '98502': {
    zip: '98502',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.03425',
    longitude: '-122.969325',
    timeZoneId: 'America/Los_Angeles',
  },
  '98503': {
    zip: '98503',
    city: 'Lacey',
    state: 'WA',
    latitude: '47.02626',
    longitude: '-122.79546',
    timeZoneId: 'America/Los_Angeles',
  },
  '98504': {
    zip: '98504',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.021117',
    longitude: '-122.868044',
    timeZoneId: 'America/Los_Angeles',
  },
  '98505': {
    zip: '98505',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.068915',
    longitude: '-122.979513',
    timeZoneId: 'America/Los_Angeles',
  },
  '98506': {
    zip: '98506',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.10378',
    longitude: '-122.86836',
    timeZoneId: 'America/Los_Angeles',
  },
  '98507': {
    zip: '98507',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.031455',
    longitude: '-122.87048',
    timeZoneId: 'America/Los_Angeles',
  },
  '98508': {
    zip: '98508',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.03552',
    longitude: '-122.936595',
    timeZoneId: 'America/Los_Angeles',
  },
  '98509': {
    zip: '98509',
    city: 'Lacey',
    state: 'WA',
    latitude: '47.036241',
    longitude: '-122.81069',
    timeZoneId: 'America/Los_Angeles',
  },
  '98511': {
    zip: '98511',
    city: 'Tumwater',
    state: 'WA',
    latitude: '47.039781',
    longitude: '-122.900904',
    timeZoneId: 'America/Los_Angeles',
  },
  '98512': {
    zip: '98512',
    city: 'Olympia',
    state: 'WA',
    latitude: '46.951987',
    longitude: '-123.014667',
    timeZoneId: 'America/Los_Angeles',
  },
  '98513': {
    zip: '98513',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.007788',
    longitude: '-122.75015',
    timeZoneId: 'America/Los_Angeles',
  },
  '98516': {
    zip: '98516',
    city: 'Olympia',
    state: 'WA',
    latitude: '47.06509',
    longitude: '-122.793039',
    timeZoneId: 'America/Los_Angeles',
  },
  '98520': {
    zip: '98520',
    city: 'Aberdeen',
    state: 'WA',
    latitude: '47.04336',
    longitude: '-123.76254',
    timeZoneId: 'America/Los_Angeles',
  },
  '98522': {
    zip: '98522',
    city: 'Adna',
    state: 'WA',
    latitude: '46.57414',
    longitude: '-123.296827',
    timeZoneId: 'America/Los_Angeles',
  },
  '98524': {
    zip: '98524',
    city: 'Allyn',
    state: 'WA',
    latitude: '47.36786',
    longitude: '-122.861977',
    timeZoneId: 'America/Los_Angeles',
  },
  '98526': {
    zip: '98526',
    city: 'Amanda Park',
    state: 'WA',
    latitude: '47.431267',
    longitude: '-124.02042',
    timeZoneId: 'America/Los_Angeles',
  },
  '98527': {
    zip: '98527',
    city: 'Bay Center',
    state: 'WA',
    latitude: '46.639753',
    longitude: '-123.939226',
    timeZoneId: 'America/Los_Angeles',
  },
  '98528': {
    zip: '98528',
    city: 'Belfair',
    state: 'WA',
    latitude: '47.444164',
    longitude: '-122.867043',
    timeZoneId: 'America/Los_Angeles',
  },
  '98530': {
    zip: '98530',
    city: 'Bucoda',
    state: 'WA',
    latitude: '46.799211',
    longitude: '-122.867652',
    timeZoneId: 'America/Los_Angeles',
  },
  '98531': {
    zip: '98531',
    city: 'Centralia',
    state: 'WA',
    latitude: '46.723564',
    longitude: '-122.958491',
    timeZoneId: 'America/Los_Angeles',
  },
  '98532': {
    zip: '98532',
    city: 'Chehalis',
    state: 'WA',
    latitude: '46.63908',
    longitude: '-123.01572',
    timeZoneId: 'America/Los_Angeles',
  },
  '98533': {
    zip: '98533',
    city: 'Cinebar',
    state: 'WA',
    latitude: '46.583022',
    longitude: '-122.540618',
    timeZoneId: 'America/Los_Angeles',
  },
  '98535': {
    zip: '98535',
    city: 'Copalis Beach',
    state: 'WA',
    latitude: '47.116999',
    longitude: '-124.155077',
    timeZoneId: 'America/Los_Angeles',
  },
  '98536': {
    zip: '98536',
    city: 'Copalis Crossing',
    state: 'WA',
    latitude: '47.141395',
    longitude: '-124.099068',
    timeZoneId: 'America/Los_Angeles',
  },
  '98537': {
    zip: '98537',
    city: 'Cosmopolis',
    state: 'WA',
    latitude: '46.954004',
    longitude: '-123.777105',
    timeZoneId: 'America/Los_Angeles',
  },
  '98538': {
    zip: '98538',
    city: 'Curtis',
    state: 'WA',
    latitude: '46.522826',
    longitude: '-123.121792',
    timeZoneId: 'America/Los_Angeles',
  },
  '98539': {
    zip: '98539',
    city: 'Doty',
    state: 'WA',
    latitude: '46.634783',
    longitude: '-123.277844',
    timeZoneId: 'America/Los_Angeles',
  },
  '98540': {
    zip: '98540',
    city: 'East Olympia',
    state: 'WA',
    latitude: '46.963393',
    longitude: '-122.823764',
    timeZoneId: 'America/Los_Angeles',
  },
  '98541': {
    zip: '98541',
    city: 'Elma',
    state: 'WA',
    latitude: '47.010231',
    longitude: '-123.411051',
    timeZoneId: 'America/Los_Angeles',
  },
  '98542': {
    zip: '98542',
    city: 'Ethel',
    state: 'WA',
    latitude: '46.528205',
    longitude: '-122.74631',
    timeZoneId: 'America/Los_Angeles',
  },
  '98544': {
    zip: '98544',
    city: 'Galvin',
    state: 'WA',
    latitude: '46.743302',
    longitude: '-123.026229',
    timeZoneId: 'America/Los_Angeles',
  },
  '98546': {
    zip: '98546',
    city: 'Grapeview',
    state: 'WA',
    latitude: '47.331222',
    longitude: '-122.904914',
    timeZoneId: 'America/Los_Angeles',
  },
  '98547': {
    zip: '98547',
    city: 'Grayland',
    state: 'WA',
    latitude: '46.743694',
    longitude: '-124.084377',
    timeZoneId: 'America/Los_Angeles',
  },
  '98548': {
    zip: '98548',
    city: 'Hoodsport',
    state: 'WA',
    latitude: '47.40685',
    longitude: '-123.158638',
    timeZoneId: 'America/Los_Angeles',
  },
  '98550': {
    zip: '98550',
    city: 'Hoquiam',
    state: 'WA',
    latitude: '46.982662',
    longitude: '-123.892233',
    timeZoneId: 'America/Los_Angeles',
  },
  '98552': {
    zip: '98552',
    city: 'Humptulips',
    state: 'WA',
    latitude: '47.196483',
    longitude: '-123.942249',
    timeZoneId: 'America/Los_Angeles',
  },
  '98554': {
    zip: '98554',
    city: 'Lebam',
    state: 'WA',
    latitude: '46.558298',
    longitude: '-123.549845',
    timeZoneId: 'America/Los_Angeles',
  },
  '98555': {
    zip: '98555',
    city: 'Lilliwaup',
    state: 'WA',
    latitude: '47.551115',
    longitude: '-123.186108',
    timeZoneId: 'America/Los_Angeles',
  },
  '98556': {
    zip: '98556',
    city: 'Littlerock',
    state: 'WA',
    latitude: '46.900933',
    longitude: '-123.018977',
    timeZoneId: 'America/Los_Angeles',
  },
  '98557': {
    zip: '98557',
    city: 'McCleary',
    state: 'WA',
    latitude: '47.05314',
    longitude: '-123.28644',
    timeZoneId: 'America/Los_Angeles',
  },
  '98558': {
    zip: '98558',
    city: 'McKenna',
    state: 'WA',
    latitude: '46.935',
    longitude: '-122.55522',
    timeZoneId: 'America/Los_Angeles',
  },
  '98559': {
    zip: '98559',
    city: 'Malone',
    state: 'WA',
    latitude: '46.960635',
    longitude: '-123.332273',
    timeZoneId: 'America/Los_Angeles',
  },
  '98560': {
    zip: '98560',
    city: 'Matlock',
    state: 'WA',
    latitude: '47.223003',
    longitude: '-123.466948',
    timeZoneId: 'America/Los_Angeles',
  },
  '98561': {
    zip: '98561',
    city: 'Menlo',
    state: 'WA',
    latitude: '46.622306',
    longitude: '-123.647856',
    timeZoneId: 'America/Los_Angeles',
  },
  '98562': {
    zip: '98562',
    city: 'Moclips',
    state: 'WA',
    latitude: '47.237863',
    longitude: '-124.097499',
    timeZoneId: 'America/Los_Angeles',
  },
  '98563': {
    zip: '98563',
    city: 'Montesano',
    state: 'WA',
    latitude: '46.999232',
    longitude: '-123.601956',
    timeZoneId: 'America/Los_Angeles',
  },
  '98564': {
    zip: '98564',
    city: 'Mossyrock',
    state: 'WA',
    latitude: '46.510282',
    longitude: '-122.501783',
    timeZoneId: 'America/Los_Angeles',
  },
  '98565': {
    zip: '98565',
    city: 'Napavine',
    state: 'WA',
    latitude: '46.57339',
    longitude: '-122.912653',
    timeZoneId: 'America/Los_Angeles',
  },
  '98566': {
    zip: '98566',
    city: 'Neilton',
    state: 'WA',
    latitude: '47.413543',
    longitude: '-123.880697',
    timeZoneId: 'America/Los_Angeles',
  },
  '98568': {
    zip: '98568',
    city: 'Oakville',
    state: 'WA',
    latitude: '46.843134',
    longitude: '-123.246221',
    timeZoneId: 'America/Los_Angeles',
  },
  '98569': {
    zip: '98569',
    city: 'Ocean Shores',
    state: 'WA',
    latitude: '46.970702',
    longitude: '-124.150812',
    timeZoneId: 'America/Los_Angeles',
  },
  '98570': {
    zip: '98570',
    city: 'Onalaska',
    state: 'WA',
    latitude: '46.581895',
    longitude: '-122.686352',
    timeZoneId: 'America/Los_Angeles',
  },
  '98571': {
    zip: '98571',
    city: 'Pacific Beach',
    state: 'WA',
    latitude: '47.201111',
    longitude: '-124.166667',
    timeZoneId: 'America/Los_Angeles',
  },
  '98572': {
    zip: '98572',
    city: 'Pe Ell',
    state: 'WA',
    latitude: '46.566487',
    longitude: '-123.305284',
    timeZoneId: 'America/Los_Angeles',
  },
  '98575': {
    zip: '98575',
    city: 'Quinault',
    state: 'WA',
    latitude: '47.414224',
    longitude: '-123.743166',
    timeZoneId: 'America/Los_Angeles',
  },
  '98576': {
    zip: '98576',
    city: 'Rainier',
    state: 'WA',
    latitude: '46.880851',
    longitude: '-122.677494',
    timeZoneId: 'America/Los_Angeles',
  },
  '98577': {
    zip: '98577',
    city: 'Raymond',
    state: 'WA',
    latitude: '46.62972',
    longitude: '-123.65844',
    timeZoneId: 'America/Los_Angeles',
  },
  '98579': {
    zip: '98579',
    city: 'Rochester',
    state: 'WA',
    latitude: '46.824952',
    longitude: '-123.052537',
    timeZoneId: 'America/Los_Angeles',
  },
  '98580': {
    zip: '98580',
    city: 'Roy',
    state: 'WA',
    latitude: '46.962746',
    longitude: '-122.483658',
    timeZoneId: 'America/Los_Angeles',
  },
  '98581': {
    zip: '98581',
    city: 'Ryderwood',
    state: 'WA',
    latitude: '46.376684',
    longitude: '-123.045515',
    timeZoneId: 'America/Los_Angeles',
  },
  '98582': {
    zip: '98582',
    city: 'Salkum',
    state: 'WA',
    latitude: '46.526552',
    longitude: '-122.627356',
    timeZoneId: 'America/Los_Angeles',
  },
  '98583': {
    zip: '98583',
    city: 'Satsop',
    state: 'WA',
    latitude: '47.002138',
    longitude: '-123.479491',
    timeZoneId: 'America/Los_Angeles',
  },
  '98584': {
    zip: '98584',
    city: 'Shelton',
    state: 'WA',
    latitude: '47.202699',
    longitude: '-123.098863',
    timeZoneId: 'America/Los_Angeles',
  },
  '98585': {
    zip: '98585',
    city: 'Silver Creek',
    state: 'WA',
    latitude: '46.535252',
    longitude: '-122.576294',
    timeZoneId: 'America/Los_Angeles',
  },
  '98586': {
    zip: '98586',
    city: 'South Bend',
    state: 'WA',
    latitude: '46.661172',
    longitude: '-123.809548',
    timeZoneId: 'America/Los_Angeles',
  },
  '98587': {
    zip: '98587',
    city: 'Taholah',
    state: 'WA',
    latitude: '47.388467',
    longitude: '-124.207752',
    timeZoneId: 'America/Los_Angeles',
  },
  '98588': {
    zip: '98588',
    city: 'Tahuya',
    state: 'WA',
    latitude: '47.403801',
    longitude: '-123.038252',
    timeZoneId: 'America/Los_Angeles',
  },
  '98589': {
    zip: '98589',
    city: 'Tenino',
    state: 'WA',
    latitude: '46.856995',
    longitude: '-122.84495',
    timeZoneId: 'America/Los_Angeles',
  },
  '98590': {
    zip: '98590',
    city: 'Tokeland',
    state: 'WA',
    latitude: '46.73382',
    longitude: '-124.0167',
    timeZoneId: 'America/Los_Angeles',
  },
  '98591': {
    zip: '98591',
    city: 'Toledo',
    state: 'WA',
    latitude: '46.438939',
    longitude: '-122.817048',
    timeZoneId: 'America/Los_Angeles',
  },
  '98592': {
    zip: '98592',
    city: 'Union',
    state: 'WA',
    latitude: '47.345331',
    longitude: '-123.077475',
    timeZoneId: 'America/Los_Angeles',
  },
  '98593': {
    zip: '98593',
    city: 'Vader',
    state: 'WA',
    latitude: '46.404935',
    longitude: '-122.970879',
    timeZoneId: 'America/Los_Angeles',
  },
  '98595': {
    zip: '98595',
    city: 'Westport',
    state: 'WA',
    latitude: '46.881787',
    longitude: '-124.107588',
    timeZoneId: 'America/Los_Angeles',
  },
  '98596': {
    zip: '98596',
    city: 'Winlock',
    state: 'WA',
    latitude: '46.496928',
    longitude: '-122.91921',
    timeZoneId: 'America/Los_Angeles',
  },
  '98597': {
    zip: '98597',
    city: 'Yelm',
    state: 'WA',
    latitude: '46.898012',
    longitude: '-122.559896',
    timeZoneId: 'America/Los_Angeles',
  },
  '98599': {
    zip: '98599',
    city: 'Olympia',
    state: 'WA',
    latitude: '46.997332',
    longitude: '-122.874737',
    timeZoneId: 'America/Los_Angeles',
  },
  '98601': {
    zip: '98601',
    city: 'Amboy',
    state: 'WA',
    latitude: '45.863781',
    longitude: '-122.506327',
    timeZoneId: 'America/Los_Angeles',
  },
  '98602': {
    zip: '98602',
    city: 'Appleton',
    state: 'WA',
    latitude: '45.839361',
    longitude: '-121.285084',
    timeZoneId: 'America/Los_Angeles',
  },
  '98603': {
    zip: '98603',
    city: 'Ariel',
    state: 'WA',
    latitude: '45.996488',
    longitude: '-122.449447',
    timeZoneId: 'America/Los_Angeles',
  },
  '98604': {
    zip: '98604',
    city: 'Battle Ground',
    state: 'WA',
    latitude: '45.79496',
    longitude: '-122.520247',
    timeZoneId: 'America/Los_Angeles',
  },
  '98605': {
    zip: '98605',
    city: 'Bingen',
    state: 'WA',
    latitude: '45.738971',
    longitude: '-121.47569',
    timeZoneId: 'America/Los_Angeles',
  },
  '98606': {
    zip: '98606',
    city: 'Brush Prairie',
    state: 'WA',
    latitude: '45.735779',
    longitude: '-122.475516',
    timeZoneId: 'America/Los_Angeles',
  },
  '98607': {
    zip: '98607',
    city: 'Camas',
    state: 'WA',
    latitude: '45.614294',
    longitude: '-122.413462',
    timeZoneId: 'America/Los_Angeles',
  },
  '98609': {
    zip: '98609',
    city: 'Carrolls',
    state: 'WA',
    latitude: '46.073646',
    longitude: '-122.861996',
    timeZoneId: 'America/Los_Angeles',
  },
  '98610': {
    zip: '98610',
    city: 'Carson',
    state: 'WA',
    latitude: '45.748636',
    longitude: '-121.845884',
    timeZoneId: 'America/Los_Angeles',
  },
  '98611': {
    zip: '98611',
    city: 'Castle Rock',
    state: 'WA',
    latitude: '46.286716',
    longitude: '-122.900412',
    timeZoneId: 'America/Los_Angeles',
  },
  '98612': {
    zip: '98612',
    city: 'Cathlamet',
    state: 'WA',
    latitude: '46.21092',
    longitude: '-123.33504',
    timeZoneId: 'America/Los_Angeles',
  },
  '98613': {
    zip: '98613',
    city: 'Centerville',
    state: 'WA',
    latitude: '45.753779',
    longitude: '-120.938232',
    timeZoneId: 'America/Los_Angeles',
  },
  '98614': {
    zip: '98614',
    city: 'Chinook',
    state: 'WA',
    latitude: '46.271951',
    longitude: '-123.940497',
    timeZoneId: 'America/Los_Angeles',
  },
  '98616': {
    zip: '98616',
    city: 'Cougar',
    state: 'WA',
    latitude: '46.049921',
    longitude: '-122.29882',
    timeZoneId: 'America/Los_Angeles',
  },
  '98617': {
    zip: '98617',
    city: 'Dallesport',
    state: 'WA',
    latitude: '45.619824',
    longitude: '-121.178551',
    timeZoneId: 'America/Los_Angeles',
  },
  '98619': {
    zip: '98619',
    city: 'Glenwood',
    state: 'WA',
    latitude: '46.010833',
    longitude: '-121.284698',
    timeZoneId: 'America/Los_Angeles',
  },
  '98620': {
    zip: '98620',
    city: 'Goldendale',
    state: 'WA',
    latitude: '45.836346',
    longitude: '-120.823149',
    timeZoneId: 'America/Los_Angeles',
  },
  '98621': {
    zip: '98621',
    city: 'Grays River',
    state: 'WA',
    latitude: '46.353507',
    longitude: '-123.584488',
    timeZoneId: 'America/Los_Angeles',
  },
  '98622': {
    zip: '98622',
    city: 'Heisson',
    state: 'WA',
    latitude: '45.825047',
    longitude: '-122.485823',
    timeZoneId: 'America/Los_Angeles',
  },
  '98623': {
    zip: '98623',
    city: 'Husum',
    state: 'WA',
    latitude: '45.809185',
    longitude: '-121.467884',
    timeZoneId: 'America/Los_Angeles',
  },
  '98624': {
    zip: '98624',
    city: 'Ilwaco',
    state: 'WA',
    latitude: '46.317024',
    longitude: '-124.035598',
    timeZoneId: 'America/Los_Angeles',
  },
  '98625': {
    zip: '98625',
    city: 'Kalama',
    state: 'WA',
    latitude: '46.006782',
    longitude: '-122.805876',
    timeZoneId: 'America/Los_Angeles',
  },
  '98626': {
    zip: '98626',
    city: 'Kelso',
    state: 'WA',
    latitude: '46.14476',
    longitude: '-122.88017',
    timeZoneId: 'America/Los_Angeles',
  },
  '98628': {
    zip: '98628',
    city: 'Klickitat',
    state: 'WA',
    latitude: '45.825058',
    longitude: '-121.122512',
    timeZoneId: 'America/Los_Angeles',
  },
  '98629': {
    zip: '98629',
    city: 'La Center',
    state: 'WA',
    latitude: '45.863687',
    longitude: '-122.667532',
    timeZoneId: 'America/Los_Angeles',
  },
  '98631': {
    zip: '98631',
    city: 'Long Beach',
    state: 'WA',
    latitude: '46.34814',
    longitude: '-124.05228',
    timeZoneId: 'America/Los_Angeles',
  },
  '98632': {
    zip: '98632',
    city: 'Longview',
    state: 'WA',
    latitude: '46.157629',
    longitude: '-122.985831',
    timeZoneId: 'America/Los_Angeles',
  },
  '98635': {
    zip: '98635',
    city: 'Lyle',
    state: 'WA',
    latitude: '45.72629',
    longitude: '-121.258918',
    timeZoneId: 'America/Los_Angeles',
  },
  '98637': {
    zip: '98637',
    city: 'Nahcotta',
    state: 'WA',
    latitude: '46.497902',
    longitude: '-124.033568',
    timeZoneId: 'America/Los_Angeles',
  },
  '98638': {
    zip: '98638',
    city: 'Naselle',
    state: 'WA',
    latitude: '46.407326',
    longitude: '-123.708491',
    timeZoneId: 'America/Los_Angeles',
  },
  '98639': {
    zip: '98639',
    city: 'North Bonneville',
    state: 'WA',
    latitude: '45.643202',
    longitude: '-121.968412',
    timeZoneId: 'America/Los_Angeles',
  },
  '98640': {
    zip: '98640',
    city: 'Ocean Park',
    state: 'WA',
    latitude: '46.531235',
    longitude: '-124.048847',
    timeZoneId: 'America/Los_Angeles',
  },
  '98641': {
    zip: '98641',
    city: 'Oysterville',
    state: 'WA',
    latitude: '46.547504',
    longitude: '-124.053228',
    timeZoneId: 'America/Los_Angeles',
  },
  '98642': {
    zip: '98642',
    city: 'Ridgefield',
    state: 'WA',
    latitude: '45.793986',
    longitude: '-122.689276',
    timeZoneId: 'America/Los_Angeles',
  },
  '98643': {
    zip: '98643',
    city: 'Rosburg',
    state: 'WA',
    latitude: '46.314753',
    longitude: '-123.6545',
    timeZoneId: 'America/Los_Angeles',
  },
  '98644': {
    zip: '98644',
    city: 'Seaview',
    state: 'WA',
    latitude: '46.330283',
    longitude: '-124.052924',
    timeZoneId: 'America/Los_Angeles',
  },
  '98645': {
    zip: '98645',
    city: 'Silverlake',
    state: 'WA',
    latitude: '46.309905',
    longitude: '-122.766403',
    timeZoneId: 'America/Los_Angeles',
  },
  '98647': {
    zip: '98647',
    city: 'Skamokawa',
    state: 'WA',
    latitude: '46.296971',
    longitude: '-123.449654',
    timeZoneId: 'America/Los_Angeles',
  },
  '98648': {
    zip: '98648',
    city: 'Stevenson',
    state: 'WA',
    latitude: '45.83448',
    longitude: '-121.84056',
    timeZoneId: 'America/Los_Angeles',
  },
  '98649': {
    zip: '98649',
    city: 'Toutle',
    state: 'WA',
    latitude: '46.317705',
    longitude: '-122.692327',
    timeZoneId: 'America/Los_Angeles',
  },
  '98650': {
    zip: '98650',
    city: 'Trout Lake',
    state: 'WA',
    latitude: '45.986684',
    longitude: '-121.511092',
    timeZoneId: 'America/Los_Angeles',
  },
  '98651': {
    zip: '98651',
    city: 'Underwood',
    state: 'WA',
    latitude: '45.734159',
    longitude: '-121.543123',
    timeZoneId: 'America/Los_Angeles',
  },
  '98660': {
    zip: '98660',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.642953',
    longitude: '-122.675812',
    timeZoneId: 'America/Los_Angeles',
  },
  '98661': {
    zip: '98661',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.644628',
    longitude: '-122.623251',
    timeZoneId: 'America/Los_Angeles',
  },
  '98662': {
    zip: '98662',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.671298',
    longitude: '-122.559764',
    timeZoneId: 'America/Los_Angeles',
  },
  '98663': {
    zip: '98663',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.652447',
    longitude: '-122.656498',
    timeZoneId: 'America/Los_Angeles',
  },
  '98664': {
    zip: '98664',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.622483',
    longitude: '-122.565862',
    timeZoneId: 'America/Los_Angeles',
  },
  '98665': {
    zip: '98665',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.690124',
    longitude: '-122.664338',
    timeZoneId: 'America/Los_Angeles',
  },
  '98666': {
    zip: '98666',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.712413',
    longitude: '-122.597049',
    timeZoneId: 'America/Los_Angeles',
  },
  '98668': {
    zip: '98668',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.721072',
    longitude: '-122.630742',
    timeZoneId: 'America/Los_Angeles',
  },
  '98670': {
    zip: '98670',
    city: 'Wahkiacus',
    state: 'WA',
    latitude: '45.827577',
    longitude: '-121.274857',
    timeZoneId: 'America/Los_Angeles',
  },
  '98671': {
    zip: '98671',
    city: 'Washougal',
    state: 'WA',
    latitude: '45.598919',
    longitude: '-122.333228',
    timeZoneId: 'America/Los_Angeles',
  },
  '98672': {
    zip: '98672',
    city: 'White Salmon',
    state: 'WA',
    latitude: '45.771677',
    longitude: '-121.478771',
    timeZoneId: 'America/Los_Angeles',
  },
  '98673': {
    zip: '98673',
    city: 'Wishram',
    state: 'WA',
    latitude: '45.663424',
    longitude: '-120.971965',
    timeZoneId: 'America/Los_Angeles',
  },
  '98674': {
    zip: '98674',
    city: 'Woodland',
    state: 'WA',
    latitude: '45.926972',
    longitude: '-122.715769',
    timeZoneId: 'America/Los_Angeles',
  },
  '98675': {
    zip: '98675',
    city: 'Yacolt',
    state: 'WA',
    latitude: '45.863047',
    longitude: '-122.435554',
    timeZoneId: 'America/Los_Angeles',
  },
  '98682': {
    zip: '98682',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.671798',
    longitude: '-122.511245',
    timeZoneId: 'America/Los_Angeles',
  },
  '98683': {
    zip: '98683',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.602976',
    longitude: '-122.528874',
    timeZoneId: 'America/Los_Angeles',
  },
  '98684': {
    zip: '98684',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.62124',
    longitude: '-122.55108',
    timeZoneId: 'America/Los_Angeles',
  },
  '98685': {
    zip: '98685',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.709089',
    longitude: '-122.681254',
    timeZoneId: 'America/Los_Angeles',
  },
  '98686': {
    zip: '98686',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.71706',
    longitude: '-122.633301',
    timeZoneId: 'America/Los_Angeles',
  },
  '98687': {
    zip: '98687',
    city: 'Vancouver',
    state: 'WA',
    latitude: '45.629189',
    longitude: '-122.517536',
    timeZoneId: 'America/Los_Angeles',
  },
  '98801': {
    zip: '98801',
    city: 'Wenatchee',
    state: 'WA',
    latitude: '47.427482',
    longitude: '-120.333262',
    timeZoneId: 'America/Los_Angeles',
  },
  '98802': {
    zip: '98802',
    city: 'East Wenatchee',
    state: 'WA',
    latitude: '47.43852',
    longitude: '-120.23496',
    timeZoneId: 'America/Los_Angeles',
  },
  '98807': {
    zip: '98807',
    city: 'Wenatchee',
    state: 'WA',
    latitude: '47.370261',
    longitude: '-120.293544',
    timeZoneId: 'America/Los_Angeles',
  },
  '98811': {
    zip: '98811',
    city: 'Ardenvoir',
    state: 'WA',
    latitude: '47.746154',
    longitude: '-120.3824',
    timeZoneId: 'America/Los_Angeles',
  },
  '98812': {
    zip: '98812',
    city: 'Brewster',
    state: 'WA',
    latitude: '48.1674',
    longitude: '-119.79072',
    timeZoneId: 'America/Los_Angeles',
  },
  '98813': {
    zip: '98813',
    city: 'Bridgeport',
    state: 'WA',
    latitude: '48.011747',
    longitude: '-119.662504',
    timeZoneId: 'America/Los_Angeles',
  },
  '98814': {
    zip: '98814',
    city: 'Carlton',
    state: 'WA',
    latitude: '48.216121',
    longitude: '-120.173751',
    timeZoneId: 'America/Los_Angeles',
  },
  '98815': {
    zip: '98815',
    city: 'Cashmere',
    state: 'WA',
    latitude: '47.529334',
    longitude: '-120.479427',
    timeZoneId: 'America/Los_Angeles',
  },
  '98816': {
    zip: '98816',
    city: 'Chelan',
    state: 'WA',
    latitude: '47.850024',
    longitude: '-120.044073',
    timeZoneId: 'America/Los_Angeles',
  },
  '98817': {
    zip: '98817',
    city: 'Chelan Falls',
    state: 'WA',
    latitude: '47.801069',
    longitude: '-119.986527',
    timeZoneId: 'America/Los_Angeles',
  },
  '98819': {
    zip: '98819',
    city: 'Conconully',
    state: 'WA',
    latitude: '48.52238',
    longitude: '-119.718863',
    timeZoneId: 'America/Los_Angeles',
  },
  '98821': {
    zip: '98821',
    city: 'Dryden',
    state: 'WA',
    latitude: '47.542879',
    longitude: '-120.55892',
    timeZoneId: 'America/Los_Angeles',
  },
  '98822': {
    zip: '98822',
    city: 'Entiat',
    state: 'WA',
    latitude: '47.721297',
    longitude: '-120.295625',
    timeZoneId: 'America/Los_Angeles',
  },
  '98823': {
    zip: '98823',
    city: 'Ephrata',
    state: 'WA',
    latitude: '47.317313',
    longitude: '-119.548',
    timeZoneId: 'America/Los_Angeles',
  },
  '98824': {
    zip: '98824',
    city: 'George',
    state: 'WA',
    latitude: '47.079715',
    longitude: '-119.854072',
    timeZoneId: 'America/Los_Angeles',
  },
  '98826': {
    zip: '98826',
    city: 'Leavenworth',
    state: 'WA',
    latitude: '47.59632',
    longitude: '-120.66018',
    timeZoneId: 'America/Los_Angeles',
  },
  '98827': {
    zip: '98827',
    city: 'Loomis',
    state: 'WA',
    latitude: '48.861023',
    longitude: '-119.675028',
    timeZoneId: 'America/Los_Angeles',
  },
  '98828': {
    zip: '98828',
    city: 'Malaga',
    state: 'WA',
    latitude: '47.35305',
    longitude: '-120.205699',
    timeZoneId: 'America/Los_Angeles',
  },
  '98829': {
    zip: '98829',
    city: 'Malott',
    state: 'WA',
    latitude: '48.283254',
    longitude: '-119.800422',
    timeZoneId: 'America/Los_Angeles',
  },
  '98830': {
    zip: '98830',
    city: 'Mansfield',
    state: 'WA',
    latitude: '47.792917',
    longitude: '-119.638497',
    timeZoneId: 'America/Los_Angeles',
  },
  '98831': {
    zip: '98831',
    city: 'Manson',
    state: 'WA',
    latitude: '47.892373',
    longitude: '-120.146606',
    timeZoneId: 'America/Los_Angeles',
  },
  '98832': {
    zip: '98832',
    city: 'Marlin',
    state: 'WA',
    latitude: '47.461144',
    longitude: '-118.922067',
    timeZoneId: 'America/Los_Angeles',
  },
  '98833': {
    zip: '98833',
    city: 'Mazama',
    state: 'WA',
    latitude: '48.643442',
    longitude: '-120.211281',
    timeZoneId: 'America/Los_Angeles',
  },
  '98834': {
    zip: '98834',
    city: 'Methow',
    state: 'WA',
    latitude: '48.071087',
    longitude: '-120.037904',
    timeZoneId: 'America/Los_Angeles',
  },
  '98836': {
    zip: '98836',
    city: 'Monitor',
    state: 'WA',
    latitude: '47.493945',
    longitude: '-120.434651',
    timeZoneId: 'America/Los_Angeles',
  },
  '98837': {
    zip: '98837',
    city: 'Moses Lake',
    state: 'WA',
    latitude: '47.126359',
    longitude: '-119.296146',
    timeZoneId: 'America/Los_Angeles',
  },
  '98840': {
    zip: '98840',
    city: 'Okanogan',
    state: 'WA',
    latitude: '48.366451',
    longitude: '-119.582819',
    timeZoneId: 'America/Los_Angeles',
  },
  '98841': {
    zip: '98841',
    city: 'Omak',
    state: 'WA',
    latitude: '48.360556',
    longitude: '-119.236111',
    timeZoneId: 'America/Los_Angeles',
  },
  '98843': {
    zip: '98843',
    city: 'Orondo',
    state: 'WA',
    latitude: '47.789243',
    longitude: '-120.032848',
    timeZoneId: 'America/Los_Angeles',
  },
  '98844': {
    zip: '98844',
    city: 'Oroville',
    state: 'WA',
    latitude: '48.946111',
    longitude: '-119.050278',
    timeZoneId: 'America/Los_Angeles',
  },
  '98845': {
    zip: '98845',
    city: 'Palisades',
    state: 'WA',
    latitude: '47.460957',
    longitude: '-119.762352',
    timeZoneId: 'America/Los_Angeles',
  },
  '98846': {
    zip: '98846',
    city: 'Pateros',
    state: 'WA',
    latitude: '48.233413',
    longitude: '-119.814273',
    timeZoneId: 'America/Los_Angeles',
  },
  '98847': {
    zip: '98847',
    city: 'Peshastin',
    state: 'WA',
    latitude: '47.554589',
    longitude: '-120.601631',
    timeZoneId: 'America/Los_Angeles',
  },
  '98848': {
    zip: '98848',
    city: 'Quincy',
    state: 'WA',
    latitude: '47.21085',
    longitude: '-119.861652',
    timeZoneId: 'America/Los_Angeles',
  },
  '98849': {
    zip: '98849',
    city: 'Riverside',
    state: 'WA',
    latitude: '48.480603',
    longitude: '-119.617296',
    timeZoneId: 'America/Los_Angeles',
  },
  '98850': {
    zip: '98850',
    city: 'Rock Island',
    state: 'WA',
    latitude: '47.369739',
    longitude: '-120.138925',
    timeZoneId: 'America/Los_Angeles',
  },
  '98851': {
    zip: '98851',
    city: 'Soap Lake',
    state: 'WA',
    latitude: '47.39514',
    longitude: '-119.45202',
    timeZoneId: 'America/Los_Angeles',
  },
  '98852': {
    zip: '98852',
    city: 'Stehekin',
    state: 'WA',
    latitude: '48.237614',
    longitude: '-120.533184',
    timeZoneId: 'America/Los_Angeles',
  },
  '98853': {
    zip: '98853',
    city: 'Stratford',
    state: 'WA',
    latitude: '47.366763',
    longitude: '-119.278631',
    timeZoneId: 'America/Los_Angeles',
  },
  '98855': {
    zip: '98855',
    city: 'Tonasket',
    state: 'WA',
    latitude: '48.704499',
    longitude: '-119.439348',
    timeZoneId: 'America/Los_Angeles',
  },
  '98856': {
    zip: '98856',
    city: 'Twisp',
    state: 'WA',
    latitude: '48.376015',
    longitude: '-120.121992',
    timeZoneId: 'America/Los_Angeles',
  },
  '98857': {
    zip: '98857',
    city: 'Warden',
    state: 'WA',
    latitude: '46.971374',
    longitude: '-119.079797',
    timeZoneId: 'America/Los_Angeles',
  },
  '98858': {
    zip: '98858',
    city: 'Waterville',
    state: 'WA',
    latitude: '47.67762',
    longitude: '-119.90988',
    timeZoneId: 'America/Los_Angeles',
  },
  '98859': {
    zip: '98859',
    city: 'Wauconda',
    state: 'WA',
    latitude: '48.716453',
    longitude: '-118.950457',
    timeZoneId: 'America/Los_Angeles',
  },
  '98860': {
    zip: '98860',
    city: 'Wilson Creek',
    state: 'WA',
    latitude: '47.425298',
    longitude: '-119.117338',
    timeZoneId: 'America/Los_Angeles',
  },
  '98862': {
    zip: '98862',
    city: 'Winthrop',
    state: 'WA',
    latitude: '48.476221',
    longitude: '-120.181254',
    timeZoneId: 'America/Los_Angeles',
  },
  '98901': {
    zip: '98901',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.606829',
    longitude: '-120.465379',
    timeZoneId: 'America/Los_Angeles',
  },
  '98902': {
    zip: '98902',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.595168',
    longitude: '-120.537142',
    timeZoneId: 'America/Los_Angeles',
  },
  '98903': {
    zip: '98903',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.56198',
    longitude: '-120.6678',
    timeZoneId: 'America/Los_Angeles',
  },
  '98904': {
    zip: '98904',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.60218',
    longitude: '-120.50472',
    timeZoneId: 'America/Los_Angeles',
  },
  '98907': {
    zip: '98907',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.592546',
    longitude: '-120.532091',
    timeZoneId: 'America/Los_Angeles',
  },
  '98908': {
    zip: '98908',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.606563',
    longitude: '-120.620141',
    timeZoneId: 'America/Los_Angeles',
  },
  '98909': {
    zip: '98909',
    city: 'Yakima',
    state: 'WA',
    latitude: '46.547581',
    longitude: '-120.644613',
    timeZoneId: 'America/Los_Angeles',
  },
  '98920': {
    zip: '98920',
    city: 'Brownstown',
    state: 'WA',
    latitude: '46.405389',
    longitude: '-120.607498',
    timeZoneId: 'America/Los_Angeles',
  },
  '98921': {
    zip: '98921',
    city: 'Buena',
    state: 'WA',
    latitude: '46.421724',
    longitude: '-120.296841',
    timeZoneId: 'America/Los_Angeles',
  },
  '98922': {
    zip: '98922',
    city: 'Cle Elum',
    state: 'WA',
    latitude: '47.16312',
    longitude: '-120.8496',
    timeZoneId: 'America/Los_Angeles',
  },
  '98923': {
    zip: '98923',
    city: 'Cowiche',
    state: 'WA',
    latitude: '46.663934',
    longitude: '-120.731123',
    timeZoneId: 'America/Los_Angeles',
  },
  '98925': {
    zip: '98925',
    city: 'Easton',
    state: 'WA',
    latitude: '47.238991',
    longitude: '-121.179797',
    timeZoneId: 'America/Los_Angeles',
  },
  '98926': {
    zip: '98926',
    city: 'Ellensburg',
    state: 'WA',
    latitude: '46.999556',
    longitude: '-120.542589',
    timeZoneId: 'America/Los_Angeles',
  },
  '98930': {
    zip: '98930',
    city: 'Grandview',
    state: 'WA',
    latitude: '46.254858',
    longitude: '-119.918175',
    timeZoneId: 'America/Los_Angeles',
  },
  '98932': {
    zip: '98932',
    city: 'Granger',
    state: 'WA',
    latitude: '46.356411',
    longitude: '-120.177287',
    timeZoneId: 'America/Los_Angeles',
  },
  '98933': {
    zip: '98933',
    city: 'Harrah',
    state: 'WA',
    latitude: '46.389211',
    longitude: '-120.510324',
    timeZoneId: 'America/Los_Angeles',
  },
  '98934': {
    zip: '98934',
    city: 'Kittitas',
    state: 'WA',
    latitude: '46.983996',
    longitude: '-120.421288',
    timeZoneId: 'America/Los_Angeles',
  },
  '98935': {
    zip: '98935',
    city: 'Mabton',
    state: 'WA',
    latitude: '46.232289',
    longitude: '-120.008491',
    timeZoneId: 'America/Los_Angeles',
  },
  '98936': {
    zip: '98936',
    city: 'Moxee',
    state: 'WA',
    latitude: '46.549243',
    longitude: '-120.329764',
    timeZoneId: 'America/Los_Angeles',
  },
  '98937': {
    zip: '98937',
    city: 'Naches',
    state: 'WA',
    latitude: '46.77001',
    longitude: '-120.784651',
    timeZoneId: 'America/Los_Angeles',
  },
  '98938': {
    zip: '98938',
    city: 'Outlook',
    state: 'WA',
    latitude: '46.365661',
    longitude: '-120.099166',
    timeZoneId: 'America/Los_Angeles',
  },
  '98939': {
    zip: '98939',
    city: 'Parker',
    state: 'WA',
    latitude: '46.501567',
    longitude: '-120.463712',
    timeZoneId: 'America/Los_Angeles',
  },
  '98940': {
    zip: '98940',
    city: 'Ronald',
    state: 'WA',
    latitude: '47.229896',
    longitude: '-121.02086',
    timeZoneId: 'America/Los_Angeles',
  },
  '98941': {
    zip: '98941',
    city: 'Roslyn',
    state: 'WA',
    latitude: '47.221722',
    longitude: '-120.993619',
    timeZoneId: 'America/Los_Angeles',
  },
  '98942': {
    zip: '98942',
    city: 'Selah',
    state: 'WA',
    latitude: '46.691124',
    longitude: '-120.5546',
    timeZoneId: 'America/Los_Angeles',
  },
  '98943': {
    zip: '98943',
    city: 'South Cle Elum',
    state: 'WA',
    latitude: '47.190225',
    longitude: '-120.953413',
    timeZoneId: 'America/Los_Angeles',
  },
  '98944': {
    zip: '98944',
    city: 'Sunnyside',
    state: 'WA',
    latitude: '46.328654',
    longitude: '-120.001441',
    timeZoneId: 'America/Los_Angeles',
  },
  '98946': {
    zip: '98946',
    city: 'Thorp',
    state: 'WA',
    latitude: '47.03411',
    longitude: '-120.933721',
    timeZoneId: 'America/Los_Angeles',
  },
  '98947': {
    zip: '98947',
    city: 'Tieton',
    state: 'WA',
    latitude: '46.703002',
    longitude: '-120.74011',
    timeZoneId: 'America/Los_Angeles',
  },
  '98948': {
    zip: '98948',
    city: 'Toppenish',
    state: 'WA',
    latitude: '46.372419',
    longitude: '-120.316397',
    timeZoneId: 'America/Los_Angeles',
  },
  '98950': {
    zip: '98950',
    city: 'Vantage',
    state: 'WA',
    latitude: '46.946109',
    longitude: '-119.985319',
    timeZoneId: 'America/Los_Angeles',
  },
  '98951': {
    zip: '98951',
    city: 'Wapato',
    state: 'WA',
    latitude: '46.456917',
    longitude: '-120.410065',
    timeZoneId: 'America/Los_Angeles',
  },
  '98952': {
    zip: '98952',
    city: 'White Swan',
    state: 'WA',
    latitude: '46.372793',
    longitude: '-120.70123',
    timeZoneId: 'America/Los_Angeles',
  },
  '98953': {
    zip: '98953',
    city: 'Zillah',
    state: 'WA',
    latitude: '46.420015',
    longitude: '-120.259025',
    timeZoneId: 'America/Los_Angeles',
  },
  '99001': {
    zip: '99001',
    city: 'Airway Heights',
    state: 'WA',
    latitude: '47.650357',
    longitude: '-117.616267',
    timeZoneId: 'America/Los_Angeles',
  },
  '99003': {
    zip: '99003',
    city: 'Chattaroy',
    state: 'WA',
    latitude: '47.914506',
    longitude: '-117.304131',
    timeZoneId: 'America/Los_Angeles',
  },
  '99004': {
    zip: '99004',
    city: 'Cheney',
    state: 'WA',
    latitude: '47.43942',
    longitude: '-117.62736',
    timeZoneId: 'America/Los_Angeles',
  },
  '99005': {
    zip: '99005',
    city: 'Colbert',
    state: 'WA',
    latitude: '47.841251',
    longitude: '-117.365314',
    timeZoneId: 'America/Los_Angeles',
  },
  '99006': {
    zip: '99006',
    city: 'Deer Park',
    state: 'WA',
    latitude: '47.95333',
    longitude: '-117.45657',
    timeZoneId: 'America/Los_Angeles',
  },
  '99008': {
    zip: '99008',
    city: 'Edwall',
    state: 'WA',
    latitude: '47.493709',
    longitude: '-117.816492',
    timeZoneId: 'America/Los_Angeles',
  },
  '99009': {
    zip: '99009',
    city: 'Elk',
    state: 'WA',
    latitude: '48.013171',
    longitude: '-117.270307',
    timeZoneId: 'America/Los_Angeles',
  },
  '99011': {
    zip: '99011',
    city: 'Fairchild Air Force Base',
    state: 'WA',
    latitude: '47.636732',
    longitude: '-117.652435',
    timeZoneId: 'America/Los_Angeles',
  },
  '99012': {
    zip: '99012',
    city: 'Fairfield',
    state: 'WA',
    latitude: '47.388242',
    longitude: '-117.206454',
    timeZoneId: 'America/Los_Angeles',
  },
  '99013': {
    zip: '99013',
    city: 'Ford',
    state: 'WA',
    latitude: '47.809752',
    longitude: '-117.770736',
    timeZoneId: 'America/Los_Angeles',
  },
  '99014': {
    zip: '99014',
    city: 'Four Lakes',
    state: 'WA',
    latitude: '47.562362',
    longitude: '-117.591368',
    timeZoneId: 'America/Los_Angeles',
  },
  '99016': {
    zip: '99016',
    city: 'Greenacres',
    state: 'WA',
    latitude: '47.643255',
    longitude: '-117.151477',
    timeZoneId: 'America/Los_Angeles',
  },
  '99017': {
    zip: '99017',
    city: 'Lamont',
    state: 'WA',
    latitude: '47.193321',
    longitude: '-117.800269',
    timeZoneId: 'America/Los_Angeles',
  },
  '99018': {
    zip: '99018',
    city: 'Latah',
    state: 'WA',
    latitude: '47.292903',
    longitude: '-117.132885',
    timeZoneId: 'America/Los_Angeles',
  },
  '99019': {
    zip: '99019',
    city: 'Liberty Lake',
    state: 'WA',
    latitude: '47.650703',
    longitude: '-117.078957',
    timeZoneId: 'America/Los_Angeles',
  },
  '99020': {
    zip: '99020',
    city: 'Marshall',
    state: 'WA',
    latitude: '47.565332',
    longitude: '-117.490826',
    timeZoneId: 'America/Los_Angeles',
  },
  '99021': {
    zip: '99021',
    city: 'Mead',
    state: 'WA',
    latitude: '47.794004',
    longitude: '-117.294238',
    timeZoneId: 'America/Los_Angeles',
  },
  '99022': {
    zip: '99022',
    city: 'Medical Lake',
    state: 'WA',
    latitude: '47.61822',
    longitude: '-117.7134',
    timeZoneId: 'America/Los_Angeles',
  },
  '99023': {
    zip: '99023',
    city: 'Mica',
    state: 'WA',
    latitude: '47.544258',
    longitude: '-117.213397',
    timeZoneId: 'America/Los_Angeles',
  },
  '99025': {
    zip: '99025',
    city: 'Newman Lake',
    state: 'WA',
    latitude: '47.733704',
    longitude: '-117.077767',
    timeZoneId: 'America/Los_Angeles',
  },
  '99026': {
    zip: '99026',
    city: 'Nine Mile Falls',
    state: 'WA',
    latitude: '47.784513',
    longitude: '-117.599788',
    timeZoneId: 'America/Los_Angeles',
  },
  '99027': {
    zip: '99027',
    city: 'Otis Orchards',
    state: 'WA',
    latitude: '47.702089',
    longitude: '-117.110984',
    timeZoneId: 'America/Los_Angeles',
  },
  '99029': {
    zip: '99029',
    city: 'Reardan',
    state: 'WA',
    latitude: '47.732251',
    longitude: '-117.765723',
    timeZoneId: 'America/Los_Angeles',
  },
  '99030': {
    zip: '99030',
    city: 'Rockford',
    state: 'WA',
    latitude: '47.478073',
    longitude: '-117.113089',
    timeZoneId: 'America/Los_Angeles',
  },
  '99031': {
    zip: '99031',
    city: 'Spangle',
    state: 'WA',
    latitude: '47.431932',
    longitude: '-117.37061',
    timeZoneId: 'America/Los_Angeles',
  },
  '99032': {
    zip: '99032',
    city: 'Sprague',
    state: 'WA',
    latitude: '47.300188',
    longitude: '-117.979989',
    timeZoneId: 'America/Los_Angeles',
  },
  '99033': {
    zip: '99033',
    city: 'Tekoa',
    state: 'WA',
    latitude: '47.225203',
    longitude: '-117.070645',
    timeZoneId: 'America/Los_Angeles',
  },
  '99034': {
    zip: '99034',
    city: 'Tumtum',
    state: 'WA',
    latitude: '47.890134',
    longitude: '-117.774983',
    timeZoneId: 'America/Los_Angeles',
  },
  '99036': {
    zip: '99036',
    city: 'Valleyford',
    state: 'WA',
    latitude: '47.531367',
    longitude: '-117.266381',
    timeZoneId: 'America/Los_Angeles',
  },
  '99037': {
    zip: '99037',
    city: 'Veradale',
    state: 'WA',
    latitude: '47.644452',
    longitude: '-117.195238',
    timeZoneId: 'America/Los_Angeles',
  },
  '99039': {
    zip: '99039',
    city: 'Waverly',
    state: 'WA',
    latitude: '47.311882',
    longitude: '-117.246753',
    timeZoneId: 'America/Los_Angeles',
  },
  '99040': {
    zip: '99040',
    city: 'Wellpinit',
    state: 'WA',
    latitude: '47.883455',
    longitude: '-118.033976',
    timeZoneId: 'America/Los_Angeles',
  },
  '99101': {
    zip: '99101',
    city: 'Addy',
    state: 'WA',
    latitude: '48.32501',
    longitude: '-117.95431',
    timeZoneId: 'America/Los_Angeles',
  },
  '99102': {
    zip: '99102',
    city: 'Albion',
    state: 'WA',
    latitude: '46.796284',
    longitude: '-117.248966',
    timeZoneId: 'America/Los_Angeles',
  },
  '99103': {
    zip: '99103',
    city: 'Almira',
    state: 'WA',
    latitude: '47.714362',
    longitude: '-118.941564',
    timeZoneId: 'America/Los_Angeles',
  },
  '99104': {
    zip: '99104',
    city: 'Belmont',
    state: 'WA',
    latitude: '47.085311',
    longitude: '-117.161106',
    timeZoneId: 'America/Los_Angeles',
  },
  '99105': {
    zip: '99105',
    city: 'Benge',
    state: 'WA',
    latitude: '46.884728',
    longitude: '-117.999072',
    timeZoneId: 'America/Los_Angeles',
  },
  '99109': {
    zip: '99109',
    city: 'Chewelah',
    state: 'WA',
    latitude: '48.277353',
    longitude: '-117.729854',
    timeZoneId: 'America/Los_Angeles',
  },
  '99110': {
    zip: '99110',
    city: 'Clayton',
    state: 'WA',
    latitude: '48.011653',
    longitude: '-117.526022',
    timeZoneId: 'America/Los_Angeles',
  },
  '99111': {
    zip: '99111',
    city: 'Colfax',
    state: 'WA',
    latitude: '46.878924',
    longitude: '-117.356883',
    timeZoneId: 'America/Los_Angeles',
  },
  '99113': {
    zip: '99113',
    city: 'Colton',
    state: 'WA',
    latitude: '46.571523',
    longitude: '-117.129163',
    timeZoneId: 'America/Los_Angeles',
  },
  '99114': {
    zip: '99114',
    city: 'Colville',
    state: 'WA',
    latitude: '48.588567',
    longitude: '-117.838533',
    timeZoneId: 'America/Los_Angeles',
  },
  '99115': {
    zip: '99115',
    city: 'Coulee City',
    state: 'WA',
    latitude: '47.634433',
    longitude: '-119.252549',
    timeZoneId: 'America/Los_Angeles',
  },
  '99116': {
    zip: '99116',
    city: 'Coulee Dam',
    state: 'WA',
    latitude: '47.972127',
    longitude: '-118.972135',
    timeZoneId: 'America/Los_Angeles',
  },
  '99117': {
    zip: '99117',
    city: 'Creston',
    state: 'WA',
    latitude: '47.754972',
    longitude: '-118.518513',
    timeZoneId: 'America/Los_Angeles',
  },
  '99118': {
    zip: '99118',
    city: 'Curlew',
    state: 'WA',
    latitude: '48.916644',
    longitude: '-118.626738',
    timeZoneId: 'America/Los_Angeles',
  },
  '99119': {
    zip: '99119',
    city: 'Cusick',
    state: 'WA',
    latitude: '48.439941',
    longitude: '-117.32085',
    timeZoneId: 'America/Los_Angeles',
  },
  '99121': {
    zip: '99121',
    city: 'Danville',
    state: 'WA',
    latitude: '48.979642',
    longitude: '-118.517898',
    timeZoneId: 'America/Los_Angeles',
  },
  '99122': {
    zip: '99122',
    city: 'Davenport',
    state: 'WA',
    latitude: '47.655779',
    longitude: '-118.147962',
    timeZoneId: 'America/Los_Angeles',
  },
  '99123': {
    zip: '99123',
    city: 'Electric City',
    state: 'WA',
    latitude: '47.932095',
    longitude: '-119.028984',
    timeZoneId: 'America/Los_Angeles',
  },
  '99124': {
    zip: '99124',
    city: 'Elmer City',
    state: 'WA',
    latitude: '47.998784',
    longitude: '-118.951803',
    timeZoneId: 'America/Los_Angeles',
  },
  '99125': {
    zip: '99125',
    city: 'Endicott',
    state: 'WA',
    latitude: '46.921399',
    longitude: '-117.701784',
    timeZoneId: 'America/Los_Angeles',
  },
  '99126': {
    zip: '99126',
    city: 'Evans',
    state: 'WA',
    latitude: '48.6939',
    longitude: '-118.0113',
    timeZoneId: 'America/Los_Angeles',
  },
  '99128': {
    zip: '99128',
    city: 'Farmington',
    state: 'WA',
    latitude: '47.089433',
    longitude: '-117.043535',
    timeZoneId: 'America/Los_Angeles',
  },
  '99129': {
    zip: '99129',
    city: 'Fruitland',
    state: 'WA',
    latitude: '48.045656',
    longitude: '-118.195215',
    timeZoneId: 'America/Los_Angeles',
  },
  '99130': {
    zip: '99130',
    city: 'Garfield',
    state: 'WA',
    latitude: '47.006983',
    longitude: '-117.140454',
    timeZoneId: 'America/Los_Angeles',
  },
  '99131': {
    zip: '99131',
    city: 'Gifford',
    state: 'WA',
    latitude: '48.275675',
    longitude: '-118.136018',
    timeZoneId: 'America/Los_Angeles',
  },
  '99133': {
    zip: '99133',
    city: 'Grand Coulee',
    state: 'WA',
    latitude: '47.929898',
    longitude: '-118.999444',
    timeZoneId: 'America/Los_Angeles',
  },
  '99134': {
    zip: '99134',
    city: 'Harrington',
    state: 'WA',
    latitude: '47.418159',
    longitude: '-118.325713',
    timeZoneId: 'America/Los_Angeles',
  },
  '99135': {
    zip: '99135',
    city: 'Hartline',
    state: 'WA',
    latitude: '47.690318',
    longitude: '-119.105256',
    timeZoneId: 'America/Los_Angeles',
  },
  '99136': {
    zip: '99136',
    city: 'Hay',
    state: 'WA',
    latitude: '46.728658',
    longitude: '-117.837934',
    timeZoneId: 'America/Los_Angeles',
  },
  '99137': {
    zip: '99137',
    city: 'Hunters',
    state: 'WA',
    latitude: '48.152402',
    longitude: '-118.128791',
    timeZoneId: 'America/Los_Angeles',
  },
  '99138': {
    zip: '99138',
    city: 'Inchelium',
    state: 'WA',
    latitude: '48.288105',
    longitude: '-118.525606',
    timeZoneId: 'America/Los_Angeles',
  },
  '99139': {
    zip: '99139',
    city: 'Ione',
    state: 'WA',
    latitude: '48.744729',
    longitude: '-117.395807',
    timeZoneId: 'America/Los_Angeles',
  },
  '99140': {
    zip: '99140',
    city: 'Keller',
    state: 'WA',
    latitude: '48.001321',
    longitude: '-118.561189',
    timeZoneId: 'America/Los_Angeles',
  },
  '99141': {
    zip: '99141',
    city: 'Kettle Falls',
    state: 'WA',
    latitude: '48.634583',
    longitude: '-118.061902',
    timeZoneId: 'America/Los_Angeles',
  },
  '99143': {
    zip: '99143',
    city: 'Lacrosse',
    state: 'WA',
    latitude: '46.77354',
    longitude: '-117.86316',
    timeZoneId: 'America/Los_Angeles',
  },
  '99144': {
    zip: '99144',
    city: 'Lamona',
    state: 'WA',
    latitude: '47.356543',
    longitude: '-118.481045',
    timeZoneId: 'America/Los_Angeles',
  },
  '99146': {
    zip: '99146',
    city: 'Laurier',
    state: 'WA',
    latitude: '49.001431',
    longitude: '-118.225126',
    timeZoneId: 'America/Los_Angeles',
  },
  '99147': {
    zip: '99147',
    city: 'Lincoln',
    state: 'WA',
    latitude: '47.822043',
    longitude: '-118.47496',
    timeZoneId: 'America/Los_Angeles',
  },
  '99148': {
    zip: '99148',
    city: 'Loon Lake',
    state: 'WA',
    latitude: '48.055306',
    longitude: '-117.616975',
    timeZoneId: 'America/Los_Angeles',
  },
  '99149': {
    zip: '99149',
    city: 'Malden',
    state: 'WA',
    latitude: '47.195997',
    longitude: '-117.484252',
    timeZoneId: 'America/Los_Angeles',
  },
  '99150': {
    zip: '99150',
    city: 'Malo',
    state: 'WA',
    latitude: '48.805263',
    longitude: '-118.605883',
    timeZoneId: 'America/Los_Angeles',
  },
  '99151': {
    zip: '99151',
    city: 'Marcus',
    state: 'WA',
    latitude: '48.665471',
    longitude: '-118.067892',
    timeZoneId: 'America/Los_Angeles',
  },
  '99152': {
    zip: '99152',
    city: 'Metaline',
    state: 'WA',
    latitude: '48.849212',
    longitude: '-117.387715',
    timeZoneId: 'America/Los_Angeles',
  },
  '99153': {
    zip: '99153',
    city: 'Metaline Falls',
    state: 'WA',
    latitude: '48.8547',
    longitude: '-117.348494',
    timeZoneId: 'America/Los_Angeles',
  },
  '99154': {
    zip: '99154',
    city: 'Mohler',
    state: 'WA',
    latitude: '47.402809',
    longitude: '-118.329726',
    timeZoneId: 'America/Los_Angeles',
  },
  '99155': {
    zip: '99155',
    city: 'Nespelem',
    state: 'WA',
    latitude: '48.255119',
    longitude: '-119.239591',
    timeZoneId: 'America/Los_Angeles',
  },
  '99156': {
    zip: '99156',
    city: 'Newport',
    state: 'WA',
    latitude: '48.166272',
    longitude: '-117.178085',
    timeZoneId: 'America/Los_Angeles',
  },
  '99157': {
    zip: '99157',
    city: 'Northport',
    state: 'WA',
    latitude: '48.925012',
    longitude: '-117.788482',
    timeZoneId: 'America/Los_Angeles',
  },
  '99158': {
    zip: '99158',
    city: 'Oakesdale',
    state: 'WA',
    latitude: '47.131686',
    longitude: '-117.244287',
    timeZoneId: 'America/Los_Angeles',
  },
  '99159': {
    zip: '99159',
    city: 'Odessa',
    state: 'WA',
    latitude: '47.331306',
    longitude: '-118.683124',
    timeZoneId: 'America/Los_Angeles',
  },
  '99160': {
    zip: '99160',
    city: 'Orient',
    state: 'WA',
    latitude: '48.865447',
    longitude: '-118.201993',
    timeZoneId: 'America/Los_Angeles',
  },
  '99161': {
    zip: '99161',
    city: 'Palouse',
    state: 'WA',
    latitude: '46.912803',
    longitude: '-117.07481',
    timeZoneId: 'America/Los_Angeles',
  },
  '99163': {
    zip: '99163',
    city: 'Pullman',
    state: 'WA',
    latitude: '46.765515',
    longitude: '-117.1919',
    timeZoneId: 'America/Los_Angeles',
  },
  '99164': {
    zip: '99164',
    city: 'Pullman',
    state: 'WA',
    latitude: '46.728892',
    longitude: '-117.155742',
    timeZoneId: 'America/Los_Angeles',
  },
  '99166': {
    zip: '99166',
    city: 'Republic',
    state: 'WA',
    latitude: '48.689698',
    longitude: '-118.688669',
    timeZoneId: 'America/Los_Angeles',
  },
  '99167': {
    zip: '99167',
    city: 'Rice',
    state: 'WA',
    latitude: '48.426364',
    longitude: '-118.132218',
    timeZoneId: 'America/Los_Angeles',
  },
  '99169': {
    zip: '99169',
    city: 'Ritzville',
    state: 'WA',
    latitude: '47.125853',
    longitude: '-118.377738',
    timeZoneId: 'America/Los_Angeles',
  },
  '99170': {
    zip: '99170',
    city: 'Rosalia',
    state: 'WA',
    latitude: '47.303675',
    longitude: '-117.357249',
    timeZoneId: 'America/Los_Angeles',
  },
  '99171': {
    zip: '99171',
    city: 'Saint John',
    state: 'WA',
    latitude: '47.092191',
    longitude: '-117.58353',
    timeZoneId: 'America/Los_Angeles',
  },
  '99173': {
    zip: '99173',
    city: 'Springdale',
    state: 'WA',
    latitude: '48.05668',
    longitude: '-117.743339',
    timeZoneId: 'America/Los_Angeles',
  },
  '99174': {
    zip: '99174',
    city: 'Steptoe',
    state: 'WA',
    latitude: '47.007933',
    longitude: '-117.352546',
    timeZoneId: 'America/Los_Angeles',
  },
  '99176': {
    zip: '99176',
    city: 'Thornton',
    state: 'WA',
    latitude: '47.119259',
    longitude: '-117.372762',
    timeZoneId: 'America/Los_Angeles',
  },
  '99179': {
    zip: '99179',
    city: 'Uniontown',
    state: 'WA',
    latitude: '46.538566',
    longitude: '-117.088019',
    timeZoneId: 'America/Los_Angeles',
  },
  '99180': {
    zip: '99180',
    city: 'Usk',
    state: 'WA',
    latitude: '48.279771',
    longitude: '-117.308966',
    timeZoneId: 'America/Los_Angeles',
  },
  '99181': {
    zip: '99181',
    city: 'Valley',
    state: 'WA',
    latitude: '48.167585',
    longitude: '-117.784374',
    timeZoneId: 'America/Los_Angeles',
  },
  '99185': {
    zip: '99185',
    city: 'Wilbur',
    state: 'WA',
    latitude: '47.759063',
    longitude: '-118.709043',
    timeZoneId: 'America/Los_Angeles',
  },
  '99201': {
    zip: '99201',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.668386',
    longitude: '-117.433067',
    timeZoneId: 'America/Los_Angeles',
  },
  '99202': {
    zip: '99202',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.657108',
    longitude: '-117.377255',
    timeZoneId: 'America/Los_Angeles',
  },
  '99203': {
    zip: '99203',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.629799',
    longitude: '-117.389448',
    timeZoneId: 'America/Los_Angeles',
  },
  '99204': {
    zip: '99204',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.63935',
    longitude: '-117.477612',
    timeZoneId: 'America/Los_Angeles',
  },
  '99205': {
    zip: '99205',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.69545',
    longitude: '-117.442334',
    timeZoneId: 'America/Los_Angeles',
  },
  '99206': {
    zip: '99206',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.651979',
    longitude: '-117.27565',
    timeZoneId: 'America/Los_Angeles',
  },
  '99207': {
    zip: '99207',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.692694',
    longitude: '-117.386717',
    timeZoneId: 'America/Los_Angeles',
  },
  '99208': {
    zip: '99208',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.738148',
    longitude: '-117.446326',
    timeZoneId: 'America/Los_Angeles',
  },
  '99209': {
    zip: '99209',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.69904',
    longitude: '-117.445081',
    timeZoneId: 'America/Los_Angeles',
  },
  '99210': {
    zip: '99210',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.650884',
    longitude: '-117.421886',
    timeZoneId: 'America/Los_Angeles',
  },
  '99211': {
    zip: '99211',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.665303',
    longitude: '-117.31012',
    timeZoneId: 'America/Los_Angeles',
  },
  '99212': {
    zip: '99212',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.660806',
    longitude: '-117.314906',
    timeZoneId: 'America/Los_Angeles',
  },
  '99213': {
    zip: '99213',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.656583',
    longitude: '-117.291099',
    timeZoneId: 'America/Los_Angeles',
  },
  '99214': {
    zip: '99214',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.711392',
    longitude: '-117.206773',
    timeZoneId: 'America/Los_Angeles',
  },
  '99215': {
    zip: '99215',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.690874',
    longitude: '-117.209831',
    timeZoneId: 'America/Los_Angeles',
  },
  '99216': {
    zip: '99216',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.664576',
    longitude: '-117.214772',
    timeZoneId: 'America/Los_Angeles',
  },
  '99217': {
    zip: '99217',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.659332',
    longitude: '-117.424425',
    timeZoneId: 'America/Los_Angeles',
  },
  '99218': {
    zip: '99218',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.752457',
    longitude: '-117.412302',
    timeZoneId: 'America/Los_Angeles',
  },
  '99219': {
    zip: '99219',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.629754',
    longitude: '-117.542356',
    timeZoneId: 'America/Los_Angeles',
  },
  '99220': {
    zip: '99220',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.648868',
    longitude: '-117.412449',
    timeZoneId: 'America/Los_Angeles',
  },
  '99223': {
    zip: '99223',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.611523',
    longitude: '-117.358133',
    timeZoneId: 'America/Los_Angeles',
  },
  '99224': {
    zip: '99224',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.692675',
    longitude: '-117.62609',
    timeZoneId: 'America/Los_Angeles',
  },
  '99228': {
    zip: '99228',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.799759',
    longitude: '-117.384682',
    timeZoneId: 'America/Los_Angeles',
  },
  '99251': {
    zip: '99251',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.751705',
    longitude: '-117.41905',
    timeZoneId: 'America/Los_Angeles',
  },
  '99252': {
    zip: '99252',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.657262',
    longitude: '-117.377674',
    timeZoneId: 'America/Los_Angeles',
  },
  '99256': {
    zip: '99256',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.662552',
    longitude: '-117.423159',
    timeZoneId: 'America/Los_Angeles',
  },
  '99258': {
    zip: '99258',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.666477',
    longitude: '-117.40516',
    timeZoneId: 'America/Los_Angeles',
  },
  '99260': {
    zip: '99260',
    city: 'Spokane',
    state: 'WA',
    latitude: '47.663463',
    longitude: '-117.422938',
    timeZoneId: 'America/Los_Angeles',
  },
  '99301': {
    zip: '99301',
    city: 'Pasco',
    state: 'WA',
    latitude: '46.286919',
    longitude: '-119.092773',
    timeZoneId: 'America/Los_Angeles',
  },
  '99302': {
    zip: '99302',
    city: 'Pasco',
    state: 'WA',
    latitude: '46.256423',
    longitude: '-119.079477',
    timeZoneId: 'America/Los_Angeles',
  },
  '99320': {
    zip: '99320',
    city: 'Benton City',
    state: 'WA',
    latitude: '46.256964',
    longitude: '-119.471504',
    timeZoneId: 'America/Los_Angeles',
  },
  '99321': {
    zip: '99321',
    city: 'Beverly',
    state: 'WA',
    latitude: '46.863736',
    longitude: '-119.865074',
    timeZoneId: 'America/Los_Angeles',
  },
  '99322': {
    zip: '99322',
    city: 'Bickleton',
    state: 'WA',
    latitude: '45.960606',
    longitude: '-120.321259',
    timeZoneId: 'America/Los_Angeles',
  },
  '99323': {
    zip: '99323',
    city: 'Burbank',
    state: 'WA',
    latitude: '46.198868',
    longitude: '-119.010103',
    timeZoneId: 'America/Los_Angeles',
  },
  '99324': {
    zip: '99324',
    city: 'College Place',
    state: 'WA',
    latitude: '46.045542',
    longitude: '-118.38567',
    timeZoneId: 'America/Los_Angeles',
  },
  '99326': {
    zip: '99326',
    city: 'Connell',
    state: 'WA',
    latitude: '46.663073',
    longitude: '-118.916642',
    timeZoneId: 'America/Los_Angeles',
  },
  '99328': {
    zip: '99328',
    city: 'Dayton',
    state: 'WA',
    latitude: '46.32506',
    longitude: '-117.974038',
    timeZoneId: 'America/Los_Angeles',
  },
  '99329': {
    zip: '99329',
    city: 'Dixie',
    state: 'WA',
    latitude: '46.140674',
    longitude: '-118.153495',
    timeZoneId: 'America/Los_Angeles',
  },
  '99330': {
    zip: '99330',
    city: 'Eltopia',
    state: 'WA',
    latitude: '46.479358',
    longitude: '-119.082902',
    timeZoneId: 'America/Los_Angeles',
  },
  '99333': {
    zip: '99333',
    city: 'Hooper',
    state: 'WA',
    latitude: '46.754267',
    longitude: '-118.144685',
    timeZoneId: 'America/Los_Angeles',
  },
  '99335': {
    zip: '99335',
    city: 'Kahlotus',
    state: 'WA',
    latitude: '46.680849',
    longitude: '-118.541896',
    timeZoneId: 'America/Los_Angeles',
  },
  '99336': {
    zip: '99336',
    city: 'Kennewick',
    state: 'WA',
    latitude: '46.19472',
    longitude: '-119.13324',
    timeZoneId: 'America/Los_Angeles',
  },
  '99337': {
    zip: '99337',
    city: 'Kennewick',
    state: 'WA',
    latitude: '46.185947',
    longitude: '-119.164247',
    timeZoneId: 'America/Los_Angeles',
  },
  '99338': {
    zip: '99338',
    city: 'Kennewick',
    state: 'WA',
    latitude: '46.211673',
    longitude: '-119.13807',
    timeZoneId: 'America/Los_Angeles',
  },
  '99341': {
    zip: '99341',
    city: 'Lind',
    state: 'WA',
    latitude: '46.966393',
    longitude: '-118.64033',
    timeZoneId: 'America/Los_Angeles',
  },
  '99343': {
    zip: '99343',
    city: 'Mesa',
    state: 'WA',
    latitude: '46.56804',
    longitude: '-119.16762',
    timeZoneId: 'America/Los_Angeles',
  },
  '99344': {
    zip: '99344',
    city: 'Othello',
    state: 'WA',
    latitude: '46.819036',
    longitude: '-119.168555',
    timeZoneId: 'America/Los_Angeles',
  },
  '99345': {
    zip: '99345',
    city: 'Paterson',
    state: 'WA',
    latitude: '46.023614',
    longitude: '-119.778013',
    timeZoneId: 'America/Los_Angeles',
  },
  '99346': {
    zip: '99346',
    city: 'Plymouth',
    state: 'WA',
    latitude: '46.016325',
    longitude: '-119.5846',
    timeZoneId: 'America/Los_Angeles',
  },
  '99347': {
    zip: '99347',
    city: 'Pomeroy',
    state: 'WA',
    latitude: '46.473394',
    longitude: '-117.600009',
    timeZoneId: 'America/Los_Angeles',
  },
  '99348': {
    zip: '99348',
    city: 'Prescott',
    state: 'WA',
    latitude: '46.302913',
    longitude: '-118.311757',
    timeZoneId: 'America/Los_Angeles',
  },
  '99349': {
    zip: '99349',
    city: 'Mattawa',
    state: 'WA',
    latitude: '46.736559',
    longitude: '-119.899537',
    timeZoneId: 'America/Los_Angeles',
  },
  '99350': {
    zip: '99350',
    city: 'Prosser',
    state: 'WA',
    latitude: '46.203527',
    longitude: '-119.766249',
    timeZoneId: 'America/Los_Angeles',
  },
  '99352': {
    zip: '99352',
    city: 'Richland',
    state: 'WA',
    latitude: '46.280602',
    longitude: '-119.291215',
    timeZoneId: 'America/Los_Angeles',
  },
  '99353': {
    zip: '99353',
    city: 'West Richland',
    state: 'WA',
    latitude: '46.285833',
    longitude: '-119.283333',
    timeZoneId: 'America/Los_Angeles',
  },
  '99354': {
    zip: '99354',
    city: 'Richland',
    state: 'WA',
    latitude: '46.280405',
    longitude: '-119.290503',
    timeZoneId: 'America/Los_Angeles',
  },
  '99356': {
    zip: '99356',
    city: 'Roosevelt',
    state: 'WA',
    latitude: '45.806187',
    longitude: '-120.309134',
    timeZoneId: 'America/Los_Angeles',
  },
  '99357': {
    zip: '99357',
    city: 'Royal City',
    state: 'WA',
    latitude: '46.912047',
    longitude: '-119.569706',
    timeZoneId: 'America/Los_Angeles',
  },
  '99359': {
    zip: '99359',
    city: 'Starbuck',
    state: 'WA',
    latitude: '46.520154',
    longitude: '-118.123101',
    timeZoneId: 'America/Los_Angeles',
  },
  '99360': {
    zip: '99360',
    city: 'Touchet',
    state: 'WA',
    latitude: '46.0695',
    longitude: '-118.7163',
    timeZoneId: 'America/Los_Angeles',
  },
  '99361': {
    zip: '99361',
    city: 'Waitsburg',
    state: 'WA',
    latitude: '46.264939',
    longitude: '-118.152543',
    timeZoneId: 'America/Los_Angeles',
  },
  '99362': {
    zip: '99362',
    city: 'Walla Walla',
    state: 'WA',
    latitude: '46.059294',
    longitude: '-118.330015',
    timeZoneId: 'America/Los_Angeles',
  },
  '99363': {
    zip: '99363',
    city: 'Wallula',
    state: 'WA',
    latitude: '46.083748',
    longitude: '-118.907555',
    timeZoneId: 'America/Los_Angeles',
  },
  '99371': {
    zip: '99371',
    city: 'Washtucna',
    state: 'WA',
    latitude: '46.753362',
    longitude: '-118.31519',
    timeZoneId: 'America/Los_Angeles',
  },
  '99401': {
    zip: '99401',
    city: 'Anatone',
    state: 'WA',
    latitude: '46.140853',
    longitude: '-117.069258',
    timeZoneId: 'America/Los_Angeles',
  },
  '99402': {
    zip: '99402',
    city: 'Asotin',
    state: 'WA',
    latitude: '46.340395',
    longitude: '-117.042596',
    timeZoneId: 'America/Los_Angeles',
  },
  '99403': {
    zip: '99403',
    city: 'Clarkston',
    state: 'WA',
    latitude: '46.398309',
    longitude: '-117.067291',
    timeZoneId: 'America/Los_Angeles',
  },
  '99501': {
    zip: '99501',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.218665',
    longitude: '-149.867089',
    timeZoneId: 'America/Anchorage',
  },
  '99502': {
    zip: '99502',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.149085',
    longitude: '-149.94278',
    timeZoneId: 'America/Anchorage',
  },
  '99503': {
    zip: '99503',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.193435',
    longitude: '-149.888062',
    timeZoneId: 'America/Anchorage',
  },
  '99504': {
    zip: '99504',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.204464',
    longitude: '-149.748496',
    timeZoneId: 'America/Anchorage',
  },
  '99505': {
    zip: '99505',
    city: 'JBER',
    state: 'AK',
    latitude: '61.255993',
    longitude: '-149.689138',
    timeZoneId: 'America/Anchorage',
  },
  '99506': {
    zip: '99506',
    city: 'JBER',
    state: 'AK',
    latitude: '61.232052',
    longitude: '-149.78921',
    timeZoneId: 'America/Anchorage',
  },
  '99507': {
    zip: '99507',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.151981',
    longitude: '-149.828176',
    timeZoneId: 'America/Anchorage',
  },
  '99508': {
    zip: '99508',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.201281',
    longitude: '-149.815886',
    timeZoneId: 'America/Anchorage',
  },
  '99509': {
    zip: '99509',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.17564',
    longitude: '-149.878401',
    timeZoneId: 'America/Anchorage',
  },
  '99510': {
    zip: '99510',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.221987',
    longitude: '-149.88169',
    timeZoneId: 'America/Anchorage',
  },
  '99511': {
    zip: '99511',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.11454',
    longitude: '-149.859371',
    timeZoneId: 'America/Anchorage',
  },
  '99513': {
    zip: '99513',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.215754',
    longitude: '-149.867867',
    timeZoneId: 'America/Anchorage',
  },
  '99514': {
    zip: '99514',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.208882',
    longitude: '-149.829945',
    timeZoneId: 'America/Anchorage',
  },
  '99515': {
    zip: '99515',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.120854',
    longitude: '-149.893143',
    timeZoneId: 'America/Anchorage',
  },
  '99516': {
    zip: '99516',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.104106',
    longitude: '-149.778328',
    timeZoneId: 'America/Anchorage',
  },
  '99517': {
    zip: '99517',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.190021',
    longitude: '-149.935563',
    timeZoneId: 'America/Anchorage',
  },
  '99518': {
    zip: '99518',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.158028',
    longitude: '-149.881079',
    timeZoneId: 'America/Anchorage',
  },
  '99519': {
    zip: '99519',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.189183',
    longitude: '-149.987503',
    timeZoneId: 'America/Anchorage',
  },
  '99520': {
    zip: '99520',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.216414',
    longitude: '-149.867078',
    timeZoneId: 'America/Anchorage',
  },
  '99521': {
    zip: '99521',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.205326',
    longitude: '-149.74557',
    timeZoneId: 'America/Anchorage',
  },
  '99522': {
    zip: '99522',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.153899',
    longitude: '-149.919928',
    timeZoneId: 'America/Anchorage',
  },
  '99523': {
    zip: '99523',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.170998',
    longitude: '-149.838325',
    timeZoneId: 'America/Anchorage',
  },
  '99524': {
    zip: '99524',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.184959',
    longitude: '-149.891783',
    timeZoneId: 'America/Anchorage',
  },
  '99529': {
    zip: '99529',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.151491',
    longitude: '-149.944301',
    timeZoneId: 'America/Anchorage',
  },
  '99530': {
    zip: '99530',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.151483',
    longitude: '-149.944356',
    timeZoneId: 'America/Anchorage',
  },
  '99540': {
    zip: '99540',
    city: 'Indian',
    state: 'AK',
    latitude: '61.116878',
    longitude: '-149.899764',
    timeZoneId: 'America/Anchorage',
  },
  '99545': {
    zip: '99545',
    city: 'Kongiganak',
    state: 'AK',
    latitude: '60.940532',
    longitude: '-164.621428',
    timeZoneId: 'America/Anchorage',
  },
  '99546': {
    zip: '99546',
    city: 'Adak',
    state: 'AK',
    latitude: '51.877706',
    longitude: '-176.658889',
    timeZoneId: 'America/Anchorage',
  },
  '99547': {
    zip: '99547',
    city: 'Atka',
    state: 'AK',
    latitude: '52.121142',
    longitude: '-174.42889',
    timeZoneId: 'America/Anchorage',
  },
  '99548': {
    zip: '99548',
    city: 'Chignik Lake',
    state: 'AK',
    latitude: '56.233333',
    longitude: '-158.783333',
    timeZoneId: 'America/Anchorage',
  },
  '99549': {
    zip: '99549',
    city: 'Port Heiden',
    state: 'AK',
    latitude: '56.945595',
    longitude: '-158.633989',
    timeZoneId: 'America/Anchorage',
  },
  '99550': {
    zip: '99550',
    city: 'Port Lions',
    state: 'AK',
    latitude: '57.822751',
    longitude: '-153.076643',
    timeZoneId: 'America/Anchorage',
  },
  '99551': {
    zip: '99551',
    city: 'Akiachak',
    state: 'AK',
    latitude: '60.876431',
    longitude: '-161.402745',
    timeZoneId: 'America/Anchorage',
  },
  '99552': {
    zip: '99552',
    city: 'Akiak',
    state: 'AK',
    latitude: '60.879032',
    longitude: '-161.190193',
    timeZoneId: 'America/Anchorage',
  },
  '99553': {
    zip: '99553',
    city: 'Akutan',
    state: 'AK',
    latitude: '54.127864',
    longitude: '-165.916603',
    timeZoneId: 'America/Anchorage',
  },
  '99554': {
    zip: '99554',
    city: 'Alakanuk',
    state: 'AK',
    latitude: '62.72143',
    longitude: '-164.57981',
    timeZoneId: 'America/Anchorage',
  },
  '99555': {
    zip: '99555',
    city: 'Aleknagik',
    state: 'AK',
    latitude: '59.3209',
    longitude: '-158.64107',
    timeZoneId: 'America/Anchorage',
  },
  '99556': {
    zip: '99556',
    city: 'Anchor Point',
    state: 'AK',
    latitude: '59.77758',
    longitude: '-151.76562',
    timeZoneId: 'America/Anchorage',
  },
  '99557': {
    zip: '99557',
    city: 'Aniak',
    state: 'AK',
    latitude: '61.78703',
    longitude: '-156.61471',
    timeZoneId: 'America/Anchorage',
  },
  '99558': {
    zip: '99558',
    city: 'Anvik',
    state: 'AK',
    latitude: '62.994017',
    longitude: '-160.400282',
    timeZoneId: 'America/Anchorage',
  },
  '99559': {
    zip: '99559',
    city: 'Bethel',
    state: 'AK',
    latitude: '60.94',
    longitude: '-164.62',
    timeZoneId: 'America/Anchorage',
  },
  '99561': {
    zip: '99561',
    city: 'Chefornak',
    state: 'AK',
    latitude: '60.155325',
    longitude: '-164.196627',
    timeZoneId: 'America/Anchorage',
  },
  '99563': {
    zip: '99563',
    city: 'Chevak',
    state: 'AK',
    latitude: '61.3505',
    longitude: '-164.657918',
    timeZoneId: 'America/Anchorage',
  },
  '99564': {
    zip: '99564',
    city: 'Chignik',
    state: 'AK',
    latitude: '56.299937',
    longitude: '-158.406727',
    timeZoneId: 'America/Anchorage',
  },
  '99565': {
    zip: '99565',
    city: 'Chignik Lagoon',
    state: 'AK',
    latitude: '56.187976',
    longitude: '-158.445675',
    timeZoneId: 'America/Anchorage',
  },
  '99566': {
    zip: '99566',
    city: 'Chitina',
    state: 'AK',
    latitude: '61.659272',
    longitude: '-143.868456',
    timeZoneId: 'America/Anchorage',
  },
  '99567': {
    zip: '99567',
    city: 'Chugiak',
    state: 'AK',
    latitude: '61.403984',
    longitude: '-149.460035',
    timeZoneId: 'America/Anchorage',
  },
  '99568': {
    zip: '99568',
    city: 'Clam Gulch',
    state: 'AK',
    latitude: '60.185789',
    longitude: '-151.390457',
    timeZoneId: 'America/Anchorage',
  },
  '99569': {
    zip: '99569',
    city: 'Clarks Point',
    state: 'AK',
    latitude: '58.834619',
    longitude: '-157.98593',
    timeZoneId: 'America/Anchorage',
  },
  '99571': {
    zip: '99571',
    city: 'Cold Bay',
    state: 'AK',
    latitude: '55.18',
    longitude: '-162.72',
    timeZoneId: 'America/Anchorage',
  },
  '99572': {
    zip: '99572',
    city: 'Cooper Landing',
    state: 'AK',
    latitude: '60.448658',
    longitude: '-149.916942',
    timeZoneId: 'America/Anchorage',
  },
  '99573': {
    zip: '99573',
    city: 'Copper Center',
    state: 'AK',
    latitude: '61.920251',
    longitude: '-145.382949',
    timeZoneId: 'America/Anchorage',
  },
  '99574': {
    zip: '99574',
    city: 'Cordova',
    state: 'AK',
    latitude: '60.07439',
    longitude: '-147.99241',
    timeZoneId: 'America/Anchorage',
  },
  '99575': {
    zip: '99575',
    city: 'Crooked Creek',
    state: 'AK',
    latitude: '61.807587',
    longitude: '-157.938213',
    timeZoneId: 'America/Anchorage',
  },
  '99576': {
    zip: '99576',
    city: 'Dillingham',
    state: 'AK',
    latitude: '59.07028',
    longitude: '-160.26687',
    timeZoneId: 'America/Anchorage',
  },
  '99577': {
    zip: '99577',
    city: 'Eagle River',
    state: 'AK',
    latitude: '61.317886',
    longitude: '-149.534978',
    timeZoneId: 'America/Anchorage',
  },
  '99578': {
    zip: '99578',
    city: 'Eek',
    state: 'AK',
    latitude: '59.831463',
    longitude: '-162.01589',
    timeZoneId: 'America/Anchorage',
  },
  '99579': {
    zip: '99579',
    city: 'Egegik',
    state: 'AK',
    latitude: '58.161585',
    longitude: '-157.193513',
    timeZoneId: 'America/Anchorage',
  },
  '99580': {
    zip: '99580',
    city: 'Ekwok',
    state: 'AK',
    latitude: '59.374924',
    longitude: '-157.596749',
    timeZoneId: 'America/Anchorage',
  },
  '99581': {
    zip: '99581',
    city: 'Emmonak',
    state: 'AK',
    latitude: '62.904955',
    longitude: '-164.336695',
    timeZoneId: 'America/Anchorage',
  },
  '99583': {
    zip: '99583',
    city: 'False Pass',
    state: 'AK',
    latitude: '54.843808',
    longitude: '-163.432337',
    timeZoneId: 'America/Anchorage',
  },
  '99585': {
    zip: '99585',
    city: 'Marshall',
    state: 'AK',
    latitude: '61.8465',
    longitude: '-161.9865',
    timeZoneId: 'America/Anchorage',
  },
  '99586': {
    zip: '99586',
    city: 'Gakona',
    state: 'AK',
    latitude: '62.714253',
    longitude: '-143.985789',
    timeZoneId: 'America/Anchorage',
  },
  '99587': {
    zip: '99587',
    city: 'Girdwood',
    state: 'AK',
    latitude: '60.960239',
    longitude: '-149.12619',
    timeZoneId: 'America/Anchorage',
  },
  '99588': {
    zip: '99588',
    city: 'Glennallen',
    state: 'AK',
    latitude: '62.107707',
    longitude: '-145.528461',
    timeZoneId: 'America/Anchorage',
  },
  '99589': {
    zip: '99589',
    city: 'Goodnews Bay',
    state: 'AK',
    latitude: '59.081846',
    longitude: '-161.216424',
    timeZoneId: 'America/Anchorage',
  },
  '99590': {
    zip: '99590',
    city: 'Grayling',
    state: 'AK',
    latitude: '63.27015',
    longitude: '-160.02937',
    timeZoneId: 'America/Anchorage',
  },
  '99591': {
    zip: '99591',
    city: 'Saint George Island',
    state: 'AK',
    latitude: '56.591517',
    longitude: '-169.620701',
    timeZoneId: 'America/Anchorage',
  },
  '99599': {
    zip: '99599',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.215459',
    longitude: '-149.870722',
    timeZoneId: 'America/Anchorage',
  },
  '99602': {
    zip: '99602',
    city: 'Holy Cross',
    state: 'AK',
    latitude: '62.229476',
    longitude: '-160.433416',
    timeZoneId: 'America/Anchorage',
  },
  '99603': {
    zip: '99603',
    city: 'Homer',
    state: 'AK',
    latitude: '59.672642',
    longitude: '-151.481184',
    timeZoneId: 'America/Anchorage',
  },
  '99604': {
    zip: '99604',
    city: 'Hooper Bay',
    state: 'AK',
    latitude: '61.500334',
    longitude: '-165.710965',
    timeZoneId: 'America/Anchorage',
  },
  '99605': {
    zip: '99605',
    city: 'Hope',
    state: 'AK',
    latitude: '60.915835',
    longitude: '-149.635647',
    timeZoneId: 'America/Anchorage',
  },
  '99606': {
    zip: '99606',
    city: 'Iliamna',
    state: 'AK',
    latitude: '59.6133',
    longitude: '-155.27622',
    timeZoneId: 'America/Anchorage',
  },
  '99607': {
    zip: '99607',
    city: 'Kalskag',
    state: 'AK',
    latitude: '61.544775',
    longitude: '-160.334469',
    timeZoneId: 'America/Anchorage',
  },
  '99608': {
    zip: '99608',
    city: 'Karluk',
    state: 'AK',
    latitude: '57.55801',
    longitude: '-154.47665',
    timeZoneId: 'America/Anchorage',
  },
  '99609': {
    zip: '99609',
    city: 'Kasigluk',
    state: 'AK',
    latitude: '60.880393',
    longitude: '-162.042542',
    timeZoneId: 'America/Anchorage',
  },
  '99610': {
    zip: '99610',
    city: 'Kasilof',
    state: 'AK',
    latitude: '60.420179',
    longitude: '-151.072033',
    timeZoneId: 'America/Anchorage',
  },
  '99611': {
    zip: '99611',
    city: 'Kenai',
    state: 'AK',
    latitude: '60.552102',
    longitude: '-151.205064',
    timeZoneId: 'America/Anchorage',
  },
  '99612': {
    zip: '99612',
    city: 'King Cove',
    state: 'AK',
    latitude: '55.376913',
    longitude: '-161.942503',
    timeZoneId: 'America/Anchorage',
  },
  '99613': {
    zip: '99613',
    city: 'King Salmon',
    state: 'AK',
    latitude: '58.709924',
    longitude: '-156.65631',
    timeZoneId: 'America/Anchorage',
  },
  '99614': {
    zip: '99614',
    city: 'Kipnuk',
    state: 'AK',
    latitude: '59.921938',
    longitude: '-164.056522',
    timeZoneId: 'America/Anchorage',
  },
  '99615': {
    zip: '99615',
    city: 'Kodiak',
    state: 'AK',
    latitude: '57.804109',
    longitude: '-152.453963',
    timeZoneId: 'America/Anchorage',
  },
  '99619': {
    zip: '99619',
    city: 'Kodiak',
    state: 'AK',
    latitude: '57.79407',
    longitude: '-152.40407',
    timeZoneId: 'America/Anchorage',
  },
  '99620': {
    zip: '99620',
    city: 'Kotlik',
    state: 'AK',
    latitude: '63.096658',
    longitude: '-164.037979',
    timeZoneId: 'America/Anchorage',
  },
  '99621': {
    zip: '99621',
    city: 'Kwethluk',
    state: 'AK',
    latitude: '60.772649',
    longitude: '-161.387912',
    timeZoneId: 'America/Anchorage',
  },
  '99622': {
    zip: '99622',
    city: 'Kwigillingok',
    state: 'AK',
    latitude: '59.867814',
    longitude: '-163.000591',
    timeZoneId: 'America/Anchorage',
  },
  '99623': {
    zip: '99623',
    city: 'Wasilla',
    state: 'AK',
    latitude: '61.593459',
    longitude: '-149.414728',
    timeZoneId: 'America/Anchorage',
  },
  '99624': {
    zip: '99624',
    city: 'Larsen Bay',
    state: 'AK',
    latitude: '57.416283',
    longitude: '-153.532302',
    timeZoneId: 'America/Anchorage',
  },
  '99625': {
    zip: '99625',
    city: 'Levelock',
    state: 'AK',
    latitude: '59.81751',
    longitude: '-154.10539',
    timeZoneId: 'America/Anchorage',
  },
  '99626': {
    zip: '99626',
    city: 'Lower Kalskag',
    state: 'AK',
    latitude: '61.518855',
    longitude: '-160.354907',
    timeZoneId: 'America/Anchorage',
  },
  '99627': {
    zip: '99627',
    city: 'Mc Grath',
    state: 'AK',
    latitude: '62.912644',
    longitude: '-154.944297',
    timeZoneId: 'America/Anchorage',
  },
  '99628': {
    zip: '99628',
    city: 'Manokotak',
    state: 'AK',
    latitude: '58.863367',
    longitude: '-159.127113',
    timeZoneId: 'America/Anchorage',
  },
  '99629': {
    zip: '99629',
    city: 'Wasilla',
    state: 'AK',
    latitude: '61.593474',
    longitude: '-149.414543',
    timeZoneId: 'America/Anchorage',
  },
  '99630': {
    zip: '99630',
    city: 'Mekoryuk',
    state: 'AK',
    latitude: '60.098493',
    longitude: '-166.379379',
    timeZoneId: 'America/Anchorage',
  },
  '99631': {
    zip: '99631',
    city: 'Moose Pass',
    state: 'AK',
    latitude: '60.60832',
    longitude: '-149.249213',
    timeZoneId: 'America/Anchorage',
  },
  '99632': {
    zip: '99632',
    city: 'Mountain Village',
    state: 'AK',
    latitude: '62.087996',
    longitude: '-163.717879',
    timeZoneId: 'America/Anchorage',
  },
  '99633': {
    zip: '99633',
    city: 'Naknek',
    state: 'AK',
    latitude: '58.859847',
    longitude: '-156.71037',
    timeZoneId: 'America/Anchorage',
  },
  '99634': {
    zip: '99634',
    city: 'Napakiak',
    state: 'AK',
    latitude: '60.67456',
    longitude: '-161.738774',
    timeZoneId: 'America/Anchorage',
  },
  '99635': {
    zip: '99635',
    city: 'Nikiski',
    state: 'AK',
    latitude: '60.69018',
    longitude: '-151.2888',
    timeZoneId: 'America/Anchorage',
  },
  '99636': {
    zip: '99636',
    city: 'New Stuyahok',
    state: 'AK',
    latitude: '59.402591',
    longitude: '-157.279625',
    timeZoneId: 'America/Anchorage',
  },
  '99637': {
    zip: '99637',
    city: 'Toksook Bay',
    state: 'AK',
    latitude: '60.605545',
    longitude: '-164.759764',
    timeZoneId: 'America/Anchorage',
  },
  '99638': {
    zip: '99638',
    city: 'Nikolski',
    state: 'AK',
    latitude: '52.834785',
    longitude: '-169.860029',
    timeZoneId: 'America/Anchorage',
  },
  '99639': {
    zip: '99639',
    city: 'Ninilchik',
    state: 'AK',
    latitude: '59.989648',
    longitude: '-151.697929',
    timeZoneId: 'America/Anchorage',
  },
  '99640': {
    zip: '99640',
    city: 'Nondalton',
    state: 'AK',
    latitude: '59.96659',
    longitude: '-154.848985',
    timeZoneId: 'America/Anchorage',
  },
  '99641': {
    zip: '99641',
    city: 'Nunapitchuk',
    state: 'AK',
    latitude: '61.011329',
    longitude: '-162.059436',
    timeZoneId: 'America/Anchorage',
  },
  '99643': {
    zip: '99643',
    city: 'Old Harbor',
    state: 'AK',
    latitude: '57.415091',
    longitude: '-153.531873',
    timeZoneId: 'America/Anchorage',
  },
  '99644': {
    zip: '99644',
    city: 'Ouzinkie',
    state: 'AK',
    latitude: '57.415483',
    longitude: '-153.532034',
    timeZoneId: 'America/Anchorage',
  },
  '99645': {
    zip: '99645',
    city: 'Palmer',
    state: 'AK',
    latitude: '61.616657',
    longitude: '-149.116091',
    timeZoneId: 'America/Anchorage',
  },
  '99647': {
    zip: '99647',
    city: 'Pedro Bay',
    state: 'AK',
    latitude: '59.75',
    longitude: '-154.916667',
    timeZoneId: 'America/Anchorage',
  },
  '99648': {
    zip: '99648',
    city: 'Perryville',
    state: 'AK',
    latitude: '55.92765',
    longitude: '-159.332132',
    timeZoneId: 'America/Anchorage',
  },
  '99649': {
    zip: '99649',
    city: 'Pilot Point',
    state: 'AK',
    latitude: '57.594513',
    longitude: '-157.486964',
    timeZoneId: 'America/Anchorage',
  },
  '99650': {
    zip: '99650',
    city: 'Pilot Station',
    state: 'AK',
    latitude: '61.680853',
    longitude: '-163.18429',
    timeZoneId: 'America/Anchorage',
  },
  '99651': {
    zip: '99651',
    city: 'Platinum',
    state: 'AK',
    latitude: '58.789288',
    longitude: '-161.481568',
    timeZoneId: 'America/Anchorage',
  },
  '99652': {
    zip: '99652',
    city: 'Big Lake',
    state: 'AK',
    latitude: '61.581389',
    longitude: '-149.439444',
    timeZoneId: 'America/Anchorage',
  },
  '99653': {
    zip: '99653',
    city: 'Port Alsworth',
    state: 'AK',
    latitude: '60.362061',
    longitude: '-154.540609',
    timeZoneId: 'America/Anchorage',
  },
  '99654': {
    zip: '99654',
    city: 'Wasilla',
    state: 'AK',
    latitude: '61.595441',
    longitude: '-149.409008',
    timeZoneId: 'America/Anchorage',
  },
  '99655': {
    zip: '99655',
    city: 'Quinhagak',
    state: 'AK',
    latitude: '59.477794',
    longitude: '-161.149803',
    timeZoneId: 'America/Anchorage',
  },
  '99656': {
    zip: '99656',
    city: 'Red Devil',
    state: 'AK',
    latitude: '61.75049',
    longitude: '-157.259892',
    timeZoneId: 'America/Anchorage',
  },
  '99657': {
    zip: '99657',
    city: 'Russian Mission',
    state: 'AK',
    latitude: '61.768371',
    longitude: '-160.975984',
    timeZoneId: 'America/Anchorage',
  },
  '99658': {
    zip: '99658',
    city: 'Saint Marys',
    state: 'AK',
    latitude: '62.268431',
    longitude: '-163.344319',
    timeZoneId: 'America/Anchorage',
  },
  '99659': {
    zip: '99659',
    city: 'Saint Michael',
    state: 'AK',
    latitude: '63.278964',
    longitude: '-161.772514',
    timeZoneId: 'America/Anchorage',
  },
  '99660': {
    zip: '99660',
    city: 'Saint Paul Island',
    state: 'AK',
    latitude: '57.184029',
    longitude: '-170.273301',
    timeZoneId: 'America/Anchorage',
  },
  '99661': {
    zip: '99661',
    city: 'Sand Point',
    state: 'AK',
    latitude: '55.268136',
    longitude: '-160.681303',
    timeZoneId: 'America/Anchorage',
  },
  '99662': {
    zip: '99662',
    city: 'Scammon Bay',
    state: 'AK',
    latitude: '61.792836',
    longitude: '-165.07163',
    timeZoneId: 'America/Anchorage',
  },
  '99663': {
    zip: '99663',
    city: 'Seldovia',
    state: 'AK',
    latitude: '59.43864',
    longitude: '-151.709612',
    timeZoneId: 'America/Anchorage',
  },
  '99664': {
    zip: '99664',
    city: 'Seward',
    state: 'AK',
    latitude: '60.137917',
    longitude: '-149.43923',
    timeZoneId: 'America/Anchorage',
  },
  '99665': {
    zip: '99665',
    city: 'Shageluk',
    state: 'AK',
    latitude: '62.649355',
    longitude: '-159.792051',
    timeZoneId: 'America/Anchorage',
  },
  '99666': {
    zip: '99666',
    city: 'Nunam Iqua',
    state: 'AK',
    latitude: '62.5092',
    longitude: '-164.45322',
    timeZoneId: 'America/Anchorage',
  },
  '99667': {
    zip: '99667',
    city: 'Skwentna',
    state: 'AK',
    latitude: '61.697031',
    longitude: '-152.081309',
    timeZoneId: 'America/Anchorage',
  },
  '99668': {
    zip: '99668',
    city: 'Sleetmute',
    state: 'AK',
    latitude: '61.596791',
    longitude: '-157.04593',
    timeZoneId: 'America/Anchorage',
  },
  '99669': {
    zip: '99669',
    city: 'Soldotna',
    state: 'AK',
    latitude: '60.493644',
    longitude: '-151.078542',
    timeZoneId: 'America/Anchorage',
  },
  '99670': {
    zip: '99670',
    city: 'South Naknek',
    state: 'AK',
    latitude: '58.71206',
    longitude: '-156.908999',
    timeZoneId: 'America/Anchorage',
  },
  '99671': {
    zip: '99671',
    city: 'Stebbins',
    state: 'AK',
    latitude: '63.470041',
    longitude: '-162.25917',
    timeZoneId: 'America/Anchorage',
  },
  '99672': {
    zip: '99672',
    city: 'Sterling',
    state: 'AK',
    latitude: '60.52615',
    longitude: '-150.830093',
    timeZoneId: 'America/Anchorage',
  },
  '99674': {
    zip: '99674',
    city: 'Sutton',
    state: 'AK',
    latitude: '61.796667',
    longitude: '-148.462778',
    timeZoneId: 'America/Anchorage',
  },
  '99675': {
    zip: '99675',
    city: 'Takotna',
    state: 'AK',
    latitude: '62.98',
    longitude: '-156.06',
    timeZoneId: 'America/Anchorage',
  },
  '99676': {
    zip: '99676',
    city: 'Talkeetna',
    state: 'AK',
    latitude: '62.175505',
    longitude: '-149.900179',
    timeZoneId: 'America/Anchorage',
  },
  '99677': {
    zip: '99677',
    city: 'Tatitlek',
    state: 'AK',
    latitude: '61.105204',
    longitude: '-147.109932',
    timeZoneId: 'America/Anchorage',
  },
  '99678': {
    zip: '99678',
    city: 'Togiak',
    state: 'AK',
    latitude: '59.206606',
    longitude: '-160.502473',
    timeZoneId: 'America/Anchorage',
  },
  '99679': {
    zip: '99679',
    city: 'Tuluksak',
    state: 'AK',
    latitude: '61.108431',
    longitude: '-160.949411',
    timeZoneId: 'America/Anchorage',
  },
  '99680': {
    zip: '99680',
    city: 'Tuntutuliak',
    state: 'AK',
    latitude: '60.36',
    longitude: '-162.63',
    timeZoneId: 'America/Anchorage',
  },
  '99681': {
    zip: '99681',
    city: 'Tununak',
    state: 'AK',
    latitude: '60.60429',
    longitude: '-164.760278',
    timeZoneId: 'America/Anchorage',
  },
  '99682': {
    zip: '99682',
    city: 'Tyonek',
    state: 'AK',
    latitude: '61.06049',
    longitude: '-151.20075',
    timeZoneId: 'America/Anchorage',
  },
  '99683': {
    zip: '99683',
    city: 'Trapper Creek',
    state: 'AK',
    latitude: '61.36728',
    longitude: '-150.61662',
    timeZoneId: 'America/Anchorage',
  },
  '99684': {
    zip: '99684',
    city: 'Unalakleet',
    state: 'AK',
    latitude: '63.724978',
    longitude: '-160.542339',
    timeZoneId: 'America/Anchorage',
  },
  '99685': {
    zip: '99685',
    city: 'Unalaska',
    state: 'AK',
    latitude: '53.864241',
    longitude: '-166.717113',
    timeZoneId: 'America/Anchorage',
  },
  '99686': {
    zip: '99686',
    city: 'Valdez',
    state: 'AK',
    latitude: '61.240531',
    longitude: '-145.994063',
    timeZoneId: 'America/Anchorage',
  },
  '99687': {
    zip: '99687',
    city: 'Wasilla',
    state: 'AK',
    latitude: '61.591714',
    longitude: '-149.416478',
    timeZoneId: 'America/Anchorage',
  },
  '99688': {
    zip: '99688',
    city: 'Willow',
    state: 'AK',
    latitude: '61.644526',
    longitude: '-150.671047',
    timeZoneId: 'America/Anchorage',
  },
  '99689': {
    zip: '99689',
    city: 'Yakutat',
    state: 'AK',
    latitude: '59.80802',
    longitude: '-139.555518',
    timeZoneId: 'America/Anchorage',
  },
  '99690': {
    zip: '99690',
    city: 'Nightmute',
    state: 'AK',
    latitude: '60.47',
    longitude: '-164.72',
    timeZoneId: 'America/Anchorage',
  },
  '99691': {
    zip: '99691',
    city: 'Nikolai',
    state: 'AK',
    latitude: '62.998454',
    longitude: '-154.389565',
    timeZoneId: 'America/Anchorage',
  },
  '99692': {
    zip: '99692',
    city: 'Dutch Harbor',
    state: 'AK',
    latitude: '53.88',
    longitude: '-166.53',
    timeZoneId: 'America/Anchorage',
  },
  '99693': {
    zip: '99693',
    city: 'Whittier',
    state: 'AK',
    latitude: '60.77',
    longitude: '-148.68',
    timeZoneId: 'America/Anchorage',
  },
  '99694': {
    zip: '99694',
    city: 'Houston',
    state: 'AK',
    latitude: '61.491709',
    longitude: '-149.790453',
    timeZoneId: 'America/Anchorage',
  },
  '99695': {
    zip: '99695',
    city: 'Anchorage',
    state: 'AK',
    latitude: '61.215515',
    longitude: '-149.866023',
    timeZoneId: 'America/Anchorage',
  },
  '99697': {
    zip: '99697',
    city: 'Kodiak',
    state: 'AK',
    latitude: '57.412038',
    longitude: '-153.53712',
    timeZoneId: 'America/Anchorage',
  },
  '99701': {
    zip: '99701',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.836296',
    longitude: '-147.715406',
    timeZoneId: 'America/Anchorage',
  },
  '99702': {
    zip: '99702',
    city: 'Eielson AFB',
    state: 'AK',
    latitude: '64.653581',
    longitude: '-147.085013',
    timeZoneId: 'America/Anchorage',
  },
  '99703': {
    zip: '99703',
    city: 'Fort Wainwright',
    state: 'AK',
    latitude: '64.821983',
    longitude: '-147.635602',
    timeZoneId: 'America/Anchorage',
  },
  '99704': {
    zip: '99704',
    city: 'Clear',
    state: 'AK',
    latitude: '64.491996',
    longitude: '-148.678285',
    timeZoneId: 'America/Anchorage',
  },
  '99705': {
    zip: '99705',
    city: 'North Pole',
    state: 'AK',
    latitude: '64.76484',
    longitude: '-147.4173',
    timeZoneId: 'America/Anchorage',
  },
  '99706': {
    zip: '99706',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.833248',
    longitude: '-147.880095',
    timeZoneId: 'America/Anchorage',
  },
  '99707': {
    zip: '99707',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.837257',
    longitude: '-147.698775',
    timeZoneId: 'America/Anchorage',
  },
  '99708': {
    zip: '99708',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.876268',
    longitude: '-147.898163',
    timeZoneId: 'America/Anchorage',
  },
  '99709': {
    zip: '99709',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.859117',
    longitude: '-147.880292',
    timeZoneId: 'America/Anchorage',
  },
  '99710': {
    zip: '99710',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.916817',
    longitude: '-147.567476',
    timeZoneId: 'America/Anchorage',
  },
  '99711': {
    zip: '99711',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.818214',
    longitude: '-147.567698',
    timeZoneId: 'America/Anchorage',
  },
  '99712': {
    zip: '99712',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.891588',
    longitude: '-147.414606',
    timeZoneId: 'America/Anchorage',
  },
  '99714': {
    zip: '99714',
    city: 'Salcha',
    state: 'AK',
    latitude: '64.468564',
    longitude: '-146.936418',
    timeZoneId: 'America/Anchorage',
  },
  '99716': {
    zip: '99716',
    city: 'Two Rivers',
    state: 'AK',
    latitude: '64.875857',
    longitude: '-146.876181',
    timeZoneId: 'America/Anchorage',
  },
  '99720': {
    zip: '99720',
    city: 'Allakaket',
    state: 'AK',
    latitude: '66.543114',
    longitude: '-152.737183',
    timeZoneId: 'America/Anchorage',
  },
  '99721': {
    zip: '99721',
    city: 'Anaktuvuk Pass',
    state: 'AK',
    latitude: '68.240851',
    longitude: '-151.892653',
    timeZoneId: 'America/Anchorage',
  },
  '99722': {
    zip: '99722',
    city: 'Arctic Village',
    state: 'AK',
    latitude: '68.139958',
    longitude: '-145.549133',
    timeZoneId: 'America/Anchorage',
  },
  '99723': {
    zip: '99723',
    city: 'Barrow',
    state: 'AK',
    latitude: '70.66976',
    longitude: '-155.14303',
    timeZoneId: 'America/Anchorage',
  },
  '99724': {
    zip: '99724',
    city: 'Beaver',
    state: 'AK',
    latitude: '66.660573',
    longitude: '-147.574438',
    timeZoneId: 'America/Anchorage',
  },
  '99725': {
    zip: '99725',
    city: 'Ester',
    state: 'AK',
    latitude: '64.945192',
    longitude: '-148.24752',
    timeZoneId: 'America/Anchorage',
  },
  '99726': {
    zip: '99726',
    city: 'Bettles Field',
    state: 'AK',
    latitude: '66.906944',
    longitude: '-151.683056',
    timeZoneId: 'America/Anchorage',
  },
  '99727': {
    zip: '99727',
    city: 'Buckland',
    state: 'AK',
    latitude: '65.842421',
    longitude: '-160.704188',
    timeZoneId: 'America/Anchorage',
  },
  '99729': {
    zip: '99729',
    city: 'Cantwell',
    state: 'AK',
    latitude: '63.436354',
    longitude: '-148.697016',
    timeZoneId: 'America/Anchorage',
  },
  '99730': {
    zip: '99730',
    city: 'Central',
    state: 'AK',
    latitude: '65.345564',
    longitude: '-145.14026',
    timeZoneId: 'America/Anchorage',
  },
  '99731': {
    zip: '99731',
    city: 'Fort Greely',
    state: 'AK',
    latitude: '64.007436',
    longitude: '-145.352807',
    timeZoneId: 'America/Anchorage',
  },
  '99732': {
    zip: '99732',
    city: 'Chicken',
    state: 'AK',
    latitude: '63.826658',
    longitude: '-142.160943',
    timeZoneId: 'America/Anchorage',
  },
  '99733': {
    zip: '99733',
    city: 'Circle',
    state: 'AK',
    latitude: '65.824411',
    longitude: '-144.065198',
    timeZoneId: 'America/Anchorage',
  },
  '99734': {
    zip: '99734',
    city: 'Prudhoe Bay',
    state: 'AK',
    latitude: '70.0152',
    longitude: '-148.884265',
    timeZoneId: 'America/Anchorage',
  },
  '99736': {
    zip: '99736',
    city: 'Deering',
    state: 'AK',
    latitude: '65.784125',
    longitude: '-163.015543',
    timeZoneId: 'America/Anchorage',
  },
  '99737': {
    zip: '99737',
    city: 'Delta Junction',
    state: 'AK',
    latitude: '64.02228',
    longitude: '-145.51608',
    timeZoneId: 'America/Anchorage',
  },
  '99738': {
    zip: '99738',
    city: 'Eagle',
    state: 'AK',
    latitude: '64.789177',
    longitude: '-141.202941',
    timeZoneId: 'America/Anchorage',
  },
  '99739': {
    zip: '99739',
    city: 'Elim',
    state: 'AK',
    latitude: '64.759345',
    longitude: '-162.070639',
    timeZoneId: 'America/Anchorage',
  },
  '99740': {
    zip: '99740',
    city: 'Fort Yukon',
    state: 'AK',
    latitude: '66.435007',
    longitude: '-145.825007',
    timeZoneId: 'America/Anchorage',
  },
  '99741': {
    zip: '99741',
    city: 'Galena',
    state: 'AK',
    latitude: '66.09412',
    longitude: '-154.25323',
    timeZoneId: 'America/Anchorage',
  },
  '99742': {
    zip: '99742',
    city: 'Gambell',
    state: 'AK',
    latitude: '63.508354',
    longitude: '-171.429044',
    timeZoneId: 'America/Anchorage',
  },
  '99743': {
    zip: '99743',
    city: 'Healy',
    state: 'AK',
    latitude: '63.89619',
    longitude: '-148.52963',
    timeZoneId: 'America/Anchorage',
  },
  '99744': {
    zip: '99744',
    city: 'Anderson',
    state: 'AK',
    latitude: '64.293059',
    longitude: '-149.128347',
    timeZoneId: 'America/Anchorage',
  },
  '99745': {
    zip: '99745',
    city: 'Hughes',
    state: 'AK',
    latitude: '65.974584',
    longitude: '-154.194378',
    timeZoneId: 'America/Anchorage',
  },
  '99746': {
    zip: '99746',
    city: 'Huslia',
    state: 'AK',
    latitude: '65.563396',
    longitude: '-156.474369',
    timeZoneId: 'America/Anchorage',
  },
  '99747': {
    zip: '99747',
    city: 'Kaktovik',
    state: 'AK',
    latitude: '69.877219',
    longitude: '-143.681104',
    timeZoneId: 'America/Anchorage',
  },
  '99748': {
    zip: '99748',
    city: 'Kaltag',
    state: 'AK',
    latitude: '64.340945',
    longitude: '-158.721325',
    timeZoneId: 'America/Anchorage',
  },
  '99749': {
    zip: '99749',
    city: 'Kiana',
    state: 'AK',
    latitude: '67.553337',
    longitude: '-160.318483',
    timeZoneId: 'America/Anchorage',
  },
  '99750': {
    zip: '99750',
    city: 'Kivalina',
    state: 'AK',
    latitude: '67.632189',
    longitude: '-163.728466',
    timeZoneId: 'America/Anchorage',
  },
  '99751': {
    zip: '99751',
    city: 'Kobuk',
    state: 'AK',
    latitude: '66.977609',
    longitude: '-155.882969',
    timeZoneId: 'America/Anchorage',
  },
  '99752': {
    zip: '99752',
    city: 'Kotzebue',
    state: 'AK',
    latitude: '66.923181',
    longitude: '-162.036011',
    timeZoneId: 'America/Anchorage',
  },
  '99753': {
    zip: '99753',
    city: 'Koyuk',
    state: 'AK',
    latitude: '65.149946',
    longitude: '-160.778172',
    timeZoneId: 'America/Anchorage',
  },
  '99754': {
    zip: '99754',
    city: 'Koyukuk',
    state: 'AK',
    latitude: '64.891532',
    longitude: '-157.679599',
    timeZoneId: 'America/Anchorage',
  },
  '99755': {
    zip: '99755',
    city: 'Denali National Park',
    state: 'AK',
    latitude: '63.625861',
    longitude: '-150.903059',
    timeZoneId: 'America/Anchorage',
  },
  '99756': {
    zip: '99756',
    city: 'Manley Hot Springs',
    state: 'AK',
    latitude: '65.148185',
    longitude: '-150.871479',
    timeZoneId: 'America/Anchorage',
  },
  '99757': {
    zip: '99757',
    city: 'Lake Minchumina',
    state: 'AK',
    latitude: '63.804795',
    longitude: '-152.415256',
    timeZoneId: 'America/Anchorage',
  },
  '99758': {
    zip: '99758',
    city: 'Minto',
    state: 'AK',
    latitude: '65.041681',
    longitude: '-149.644722',
    timeZoneId: 'America/Anchorage',
  },
  '99759': {
    zip: '99759',
    city: 'Point Lay',
    state: 'AK',
    latitude: '69.538747',
    longitude: '-163.247629',
    timeZoneId: 'America/Anchorage',
  },
  '99760': {
    zip: '99760',
    city: 'Nenana',
    state: 'AK',
    latitude: '64.549649',
    longitude: '-149.104344',
    timeZoneId: 'America/Anchorage',
  },
  '99761': {
    zip: '99761',
    city: 'Noatak',
    state: 'AK',
    latitude: '67.296703',
    longitude: '-163.011832',
    timeZoneId: 'America/Anchorage',
  },
  '99762': {
    zip: '99762',
    city: 'Nome',
    state: 'AK',
    latitude: '64.7874',
    longitude: '-165.19722',
    timeZoneId: 'America/Anchorage',
  },
  '99763': {
    zip: '99763',
    city: 'Noorvik',
    state: 'AK',
    latitude: '66.854813',
    longitude: '-159.873232',
    timeZoneId: 'America/Anchorage',
  },
  '99764': {
    zip: '99764',
    city: 'Northway',
    state: 'AK',
    latitude: '62.96',
    longitude: '-141.93',
    timeZoneId: 'America/Anchorage',
  },
  '99765': {
    zip: '99765',
    city: 'Nulato',
    state: 'AK',
    latitude: '64.612432',
    longitude: '-159.209452',
    timeZoneId: 'America/Anchorage',
  },
  '99766': {
    zip: '99766',
    city: 'Point Hope',
    state: 'AK',
    latitude: '68.530422',
    longitude: '-165.797572',
    timeZoneId: 'America/Anchorage',
  },
  '99767': {
    zip: '99767',
    city: 'Rampart',
    state: 'AK',
    latitude: '65.492007',
    longitude: '-149.85648',
    timeZoneId: 'America/Anchorage',
  },
  '99768': {
    zip: '99768',
    city: 'Ruby',
    state: 'AK',
    latitude: '64.351768',
    longitude: '-155.985094',
    timeZoneId: 'America/Anchorage',
  },
  '99769': {
    zip: '99769',
    city: 'Savoonga',
    state: 'AK',
    latitude: '63.250538',
    longitude: '-169.59721',
    timeZoneId: 'America/Anchorage',
  },
  '99770': {
    zip: '99770',
    city: 'Selawik',
    state: 'AK',
    latitude: '66.293138',
    longitude: '-158.96417',
    timeZoneId: 'America/Anchorage',
  },
  '99771': {
    zip: '99771',
    city: 'Shaktoolik',
    state: 'AK',
    latitude: '64.517738',
    longitude: '-160.478717',
    timeZoneId: 'America/Anchorage',
  },
  '99772': {
    zip: '99772',
    city: 'Shishmaref',
    state: 'AK',
    latitude: '66.039597',
    longitude: '-165.509025',
    timeZoneId: 'America/Anchorage',
  },
  '99773': {
    zip: '99773',
    city: 'Shungnak',
    state: 'AK',
    latitude: '66.715224',
    longitude: '-156.995761',
    timeZoneId: 'America/Anchorage',
  },
  '99774': {
    zip: '99774',
    city: 'Stevens Village',
    state: 'AK',
    latitude: '66.024206',
    longitude: '-149.187155',
    timeZoneId: 'America/Anchorage',
  },
  '99775': {
    zip: '99775',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.817129',
    longitude: '-147.878379',
    timeZoneId: 'America/Anchorage',
  },
  '99776': {
    zip: '99776',
    city: 'Tanacross',
    state: 'AK',
    latitude: '63.354709',
    longitude: '-143.36057',
    timeZoneId: 'America/Anchorage',
  },
  '99777': {
    zip: '99777',
    city: 'Tanana',
    state: 'AK',
    latitude: '65.319847',
    longitude: '-151.889671',
    timeZoneId: 'America/Anchorage',
  },
  '99778': {
    zip: '99778',
    city: 'Teller',
    state: 'AK',
    latitude: '65.179234',
    longitude: '-166.288621',
    timeZoneId: 'America/Anchorage',
  },
  '99780': {
    zip: '99780',
    city: 'Tok',
    state: 'AK',
    latitude: '63.33',
    longitude: '-142.98',
    timeZoneId: 'America/Anchorage',
  },
  '99781': {
    zip: '99781',
    city: 'Venetie',
    state: 'AK',
    latitude: '67.012334',
    longitude: '-146.413119',
    timeZoneId: 'America/Anchorage',
  },
  '99782': {
    zip: '99782',
    city: 'Wainwright',
    state: 'AK',
    latitude: '70.56598',
    longitude: '-159.429874',
    timeZoneId: 'America/Anchorage',
  },
  '99783': {
    zip: '99783',
    city: 'Wales',
    state: 'AK',
    latitude: '65.663808',
    longitude: '-167.435706',
    timeZoneId: 'America/Anchorage',
  },
  '99784': {
    zip: '99784',
    city: 'White Mountain',
    state: 'AK',
    latitude: '64.868292',
    longitude: '-163.260686',
    timeZoneId: 'America/Anchorage',
  },
  '99785': {
    zip: '99785',
    city: 'Brevig Mission',
    state: 'AK',
    latitude: '65.459441',
    longitude: '-166.82545',
    timeZoneId: 'America/Anchorage',
  },
  '99786': {
    zip: '99786',
    city: 'Ambler',
    state: 'AK',
    latitude: '67.084605',
    longitude: '-157.86793',
    timeZoneId: 'America/Anchorage',
  },
  '99788': {
    zip: '99788',
    city: 'Chalkyitsik',
    state: 'AK',
    latitude: '66.564722',
    longitude: '-145.273889',
    timeZoneId: 'America/Anchorage',
  },
  '99789': {
    zip: '99789',
    city: 'Nuiqsut',
    state: 'AK',
    latitude: '70.23577',
    longitude: '-151.126315',
    timeZoneId: 'America/Anchorage',
  },
  '99790': {
    zip: '99790',
    city: 'Fairbanks',
    state: 'AK',
    latitude: '64.84297',
    longitude: '-147.724179',
    timeZoneId: 'America/Anchorage',
  },
  '99791': {
    zip: '99791',
    city: 'Atqasuk',
    state: 'AK',
    latitude: '70.472212',
    longitude: '-157.427665',
    timeZoneId: 'America/Anchorage',
  },
  '99801': {
    zip: '99801',
    city: 'Juneau',
    state: 'AK',
    latitude: '58.356473',
    longitude: '-134.526685',
    timeZoneId: 'America/Anchorage',
  },
  '99802': {
    zip: '99802',
    city: 'Juneau',
    state: 'AK',
    latitude: '58.304182',
    longitude: '-134.427014',
    timeZoneId: 'America/Anchorage',
  },
  '99803': {
    zip: '99803',
    city: 'Juneau',
    state: 'AK',
    latitude: '58.373979',
    longitude: '-134.593339',
    timeZoneId: 'America/Anchorage',
  },
  '99811': {
    zip: '99811',
    city: 'Juneau',
    state: 'AK',
    latitude: '58.475026',
    longitude: '-134.15768',
    timeZoneId: 'America/Anchorage',
  },
  '99812': {
    zip: '99812',
    city: 'Juneau',
    state: 'AK',
    latitude: '58.475528',
    longitude: '-134.14985',
    timeZoneId: 'America/Anchorage',
  },
  '99820': {
    zip: '99820',
    city: 'Angoon',
    state: 'AK',
    latitude: '57.684678',
    longitude: '-134.382269',
    timeZoneId: 'America/Anchorage',
  },
  '99821': {
    zip: '99821',
    city: 'Auke Bay',
    state: 'AK',
    latitude: '58.301944',
    longitude: '-134.419722',
    timeZoneId: 'America/Anchorage',
  },
  '99824': {
    zip: '99824',
    city: 'Douglas',
    state: 'AK',
    latitude: '58.276465',
    longitude: '-134.39169',
    timeZoneId: 'America/Anchorage',
  },
  '99825': {
    zip: '99825',
    city: 'Elfin Cove',
    state: 'AK',
    latitude: '58.184215',
    longitude: '-136.313504',
    timeZoneId: 'America/Anchorage',
  },
  '99826': {
    zip: '99826',
    city: 'Gustavus',
    state: 'AK',
    latitude: '58.824371',
    longitude: '-136.971035',
    timeZoneId: 'America/Anchorage',
  },
  '99827': {
    zip: '99827',
    city: 'Haines',
    state: 'AK',
    latitude: '59.246751',
    longitude: '-135.473655',
    timeZoneId: 'America/Anchorage',
  },
  '99829': {
    zip: '99829',
    city: 'Hoonah',
    state: 'AK',
    latitude: '58.126218',
    longitude: '-135.650802',
    timeZoneId: 'America/Anchorage',
  },
  '99830': {
    zip: '99830',
    city: 'Kake',
    state: 'AK',
    latitude: '56.973379',
    longitude: '-133.949115',
    timeZoneId: 'America/Anchorage',
  },
  '99832': {
    zip: '99832',
    city: 'Pelican',
    state: 'AK',
    latitude: '58.12678',
    longitude: '-135.648668',
    timeZoneId: 'America/Anchorage',
  },
  '99833': {
    zip: '99833',
    city: 'Petersburg',
    state: 'AK',
    latitude: '56.806343',
    longitude: '-132.969633',
    timeZoneId: 'America/Anchorage',
  },
  '99835': {
    zip: '99835',
    city: 'Sitka',
    state: 'AK',
    latitude: '57.061324',
    longitude: '-135.339294',
    timeZoneId: 'America/Anchorage',
  },
  '99836': {
    zip: '99836',
    city: 'Port Alexander',
    state: 'AK',
    latitude: '57.039187',
    longitude: '-132.56395',
    timeZoneId: 'America/Anchorage',
  },
  '99840': {
    zip: '99840',
    city: 'Skagway',
    state: 'AK',
    latitude: '59.566446',
    longitude: '-135.328306',
    timeZoneId: 'America/Anchorage',
  },
  '99841': {
    zip: '99841',
    city: 'Tenakee Springs',
    state: 'AK',
    latitude: '57.779884',
    longitude: '-135.188839',
    timeZoneId: 'America/Anchorage',
  },
  '99850': {
    zip: '99850',
    city: 'Juneau',
    state: 'AK',
    latitude: '58.47548',
    longitude: '-134.156767',
    timeZoneId: 'America/Anchorage',
  },
  '99901': {
    zip: '99901',
    city: 'Ketchikan',
    state: 'AK',
    latitude: '55.373751',
    longitude: '-131.69256',
    timeZoneId: 'America/Anchorage',
  },
  '99903': {
    zip: '99903',
    city: 'Meyers Chuck',
    state: 'AK',
    latitude: '55.74',
    longitude: '-132.25',
    timeZoneId: 'America/Anchorage',
  },
  '99918': {
    zip: '99918',
    city: 'Coffman Cove',
    state: 'AK',
    latitude: '55.35814',
    longitude: '-131.69884',
    timeZoneId: 'America/Anchorage',
  },
  '99919': {
    zip: '99919',
    city: 'Thorne Bay',
    state: 'AK',
    latitude: '55.68947',
    longitude: '-132.52428',
    timeZoneId: 'America/Anchorage',
  },
  '99921': {
    zip: '99921',
    city: 'Craig',
    state: 'AK',
    latitude: '55.474111',
    longitude: '-133.144582',
    timeZoneId: 'America/Anchorage',
  },
  '99922': {
    zip: '99922',
    city: 'Hydaburg',
    state: 'AK',
    latitude: '55.158425',
    longitude: '-132.440454',
    timeZoneId: 'America/Anchorage',
  },
  '99923': {
    zip: '99923',
    city: 'Hyder',
    state: 'AK',
    latitude: '55.963022',
    longitude: '-130.392595',
    timeZoneId: 'America/Anchorage',
  },
  '99925': {
    zip: '99925',
    city: 'Klawock',
    state: 'AK',
    latitude: '55.554962',
    longitude: '-133.096707',
    timeZoneId: 'America/Anchorage',
  },
  '99926': {
    zip: '99926',
    city: 'Metlakatla',
    state: 'AK',
    latitude: '55.130608',
    longitude: '-131.490702',
    timeZoneId: 'America/Anchorage',
  },
  '99927': {
    zip: '99927',
    city: 'Point Baker',
    state: 'AK',
    latitude: '56.246711',
    longitude: '-133.356548',
    timeZoneId: 'America/Anchorage',
  },
  '99928': {
    zip: '99928',
    city: 'Ward Cove',
    state: 'AK',
    latitude: '55.455705',
    longitude: '-131.792537',
    timeZoneId: 'America/Anchorage',
  },
  '99929': {
    zip: '99929',
    city: 'Wrangell',
    state: 'AK',
    latitude: '56.467688',
    longitude: '-132.384597',
    timeZoneId: 'America/Anchorage',
  },
  '99950': {
    zip: '99950',
    city: 'Ketchikan',
    state: 'AK',
    latitude: '55.34',
    longitude: '-131.64',
    timeZoneId: 'America/Anchorage',
  },
  '00501': {
    zip: '00501',
    city: 'Holtsville',
    state: 'NY',
    latitude: '40.813078',
    longitude: '-73.046388',
    timeZoneId: 'America/New_York',
  },
  '00544': {
    zip: '00544',
    city: 'Holtsville',
    state: 'NY',
    latitude: '40.813223',
    longitude: '-73.049288',
    timeZoneId: 'America/New_York',
  },
  '01001': {
    zip: '01001',
    city: 'Agawam',
    state: 'MA',
    latitude: '42.071523',
    longitude: '-72.624257',
    timeZoneId: 'America/New_York',
  },
  '01002': {
    zip: '01002',
    city: 'Amherst',
    state: 'MA',
    latitude: '42.37686',
    longitude: '-72.46914',
    timeZoneId: 'America/New_York',
  },
  '01003': {
    zip: '01003',
    city: 'Amherst',
    state: 'MA',
    latitude: '42.40524',
    longitude: '-72.528427',
    timeZoneId: 'America/New_York',
  },
  '01004': {
    zip: '01004',
    city: 'Amherst',
    state: 'MA',
    latitude: '42.383945',
    longitude: '-72.511834',
    timeZoneId: 'America/New_York',
  },
  '01005': {
    zip: '01005',
    city: 'Barre',
    state: 'MA',
    latitude: '42.433317',
    longitude: '-72.114488',
    timeZoneId: 'America/New_York',
  },
  '01007': {
    zip: '01007',
    city: 'Belchertown',
    state: 'MA',
    latitude: '42.281915',
    longitude: '-72.409531',
    timeZoneId: 'America/New_York',
  },
  '01008': {
    zip: '01008',
    city: 'Blandford',
    state: 'MA',
    latitude: '42.143189',
    longitude: '-72.931858',
    timeZoneId: 'America/New_York',
  },
  '01009': {
    zip: '01009',
    city: 'Bondsville',
    state: 'MA',
    latitude: '42.207963',
    longitude: '-72.344959',
    timeZoneId: 'America/New_York',
  },
  '01010': {
    zip: '01010',
    city: 'Brimfield',
    state: 'MA',
    latitude: '42.11514',
    longitude: '-72.20142',
    timeZoneId: 'America/New_York',
  },
  '01011': {
    zip: '01011',
    city: 'Chester',
    state: 'MA',
    latitude: '42.283726',
    longitude: '-72.9669',
    timeZoneId: 'America/New_York',
  },
  '01012': {
    zip: '01012',
    city: 'Chesterfield',
    state: 'MA',
    latitude: '42.387225',
    longitude: '-72.834041',
    timeZoneId: 'America/New_York',
  },
  '01013': {
    zip: '01013',
    city: 'Chicopee',
    state: 'MA',
    latitude: '42.162301',
    longitude: '-72.604454',
    timeZoneId: 'America/New_York',
  },
  '01014': {
    zip: '01014',
    city: 'Chicopee',
    state: 'MA',
    latitude: '42.174812',
    longitude: '-72.572286',
    timeZoneId: 'America/New_York',
  },
  '01020': {
    zip: '01020',
    city: 'Chicopee',
    state: 'MA',
    latitude: '42.1756',
    longitude: '-72.56999',
    timeZoneId: 'America/New_York',
  },
  '01021': {
    zip: '01021',
    city: 'Chicopee',
    state: 'MA',
    latitude: '42.177959',
    longitude: '-72.572836',
    timeZoneId: 'America/New_York',
  },
  '01022': {
    zip: '01022',
    city: 'Chicopee',
    state: 'MA',
    latitude: '42.194752',
    longitude: '-72.561933',
    timeZoneId: 'America/New_York',
  },
  '01026': {
    zip: '01026',
    city: 'Cummington',
    state: 'MA',
    latitude: '42.42786',
    longitude: '-72.9003',
    timeZoneId: 'America/New_York',
  },
  '01027': {
    zip: '01027',
    city: 'Easthampton',
    state: 'MA',
    latitude: '42.29142',
    longitude: '-72.71772',
    timeZoneId: 'America/New_York',
  },
  '01028': {
    zip: '01028',
    city: 'East Longmeadow',
    state: 'MA',
    latitude: '42.068418',
    longitude: '-72.504285',
    timeZoneId: 'America/New_York',
  },
  '01029': {
    zip: '01029',
    city: 'East Otis',
    state: 'MA',
    latitude: '42.159812',
    longitude: '-73.043385',
    timeZoneId: 'America/New_York',
  },
  '01030': {
    zip: '01030',
    city: 'Feeding Hills',
    state: 'MA',
    latitude: '42.072375',
    longitude: '-72.675163',
    timeZoneId: 'America/New_York',
  },
  '01031': {
    zip: '01031',
    city: 'Gilbertville',
    state: 'MA',
    latitude: '42.33426',
    longitude: '-72.17604',
    timeZoneId: 'America/New_York',
  },
  '01032': {
    zip: '01032',
    city: 'Goshen',
    state: 'MA',
    latitude: '42.4668',
    longitude: '-72.83076',
    timeZoneId: 'America/New_York',
  },
  '01033': {
    zip: '01033',
    city: 'Granby',
    state: 'MA',
    latitude: '42.256411',
    longitude: '-72.519474',
    timeZoneId: 'America/New_York',
  },
  '01034': {
    zip: '01034',
    city: 'Granville',
    state: 'MA',
    latitude: '42.08196',
    longitude: '-72.95058',
    timeZoneId: 'America/New_York',
  },
  '01035': {
    zip: '01035',
    city: 'Hadley',
    state: 'MA',
    latitude: '42.359966',
    longitude: '-72.577472',
    timeZoneId: 'America/New_York',
  },
  '01036': {
    zip: '01036',
    city: 'Hampden',
    state: 'MA',
    latitude: '42.062919',
    longitude: '-72.430251',
    timeZoneId: 'America/New_York',
  },
  '01037': {
    zip: '01037',
    city: 'Hardwick',
    state: 'MA',
    latitude: '42.349404',
    longitude: '-72.20081',
    timeZoneId: 'America/New_York',
  },
  '01038': {
    zip: '01038',
    city: 'Hatfield',
    state: 'MA',
    latitude: '42.381499',
    longitude: '-72.607454',
    timeZoneId: 'America/New_York',
  },
  '01039': {
    zip: '01039',
    city: 'Haydenville',
    state: 'MA',
    latitude: '42.379586',
    longitude: '-72.696757',
    timeZoneId: 'America/New_York',
  },
  '01040': {
    zip: '01040',
    city: 'Holyoke',
    state: 'MA',
    latitude: '42.204025',
    longitude: '-72.630051',
    timeZoneId: 'America/New_York',
  },
  '01041': {
    zip: '01041',
    city: 'Holyoke',
    state: 'MA',
    latitude: '42.207463',
    longitude: '-72.611418',
    timeZoneId: 'America/New_York',
  },
  '01050': {
    zip: '01050',
    city: 'Huntington',
    state: 'MA',
    latitude: '42.272328',
    longitude: '-72.865189',
    timeZoneId: 'America/New_York',
  },
  '01053': {
    zip: '01053',
    city: 'Leeds',
    state: 'MA',
    latitude: '42.354408',
    longitude: '-72.701457',
    timeZoneId: 'America/New_York',
  },
  '01054': {
    zip: '01054',
    city: 'Leverett',
    state: 'MA',
    latitude: '42.474743',
    longitude: '-72.482496',
    timeZoneId: 'America/New_York',
  },
  '01056': {
    zip: '01056',
    city: 'Ludlow',
    state: 'MA',
    latitude: '42.171237',
    longitude: '-72.472507',
    timeZoneId: 'America/New_York',
  },
  '01057': {
    zip: '01057',
    city: 'Monson',
    state: 'MA',
    latitude: '42.094023',
    longitude: '-72.323969',
    timeZoneId: 'America/New_York',
  },
  '01059': {
    zip: '01059',
    city: 'North Amherst',
    state: 'MA',
    latitude: '42.413287',
    longitude: '-72.530513',
    timeZoneId: 'America/New_York',
  },
  '01060': {
    zip: '01060',
    city: 'Northampton',
    state: 'MA',
    latitude: '42.33072',
    longitude: '-72.67506',
    timeZoneId: 'America/New_York',
  },
  '01061': {
    zip: '01061',
    city: 'Northampton',
    state: 'MA',
    latitude: '42.323341',
    longitude: '-72.690352',
    timeZoneId: 'America/New_York',
  },
  '01062': {
    zip: '01062',
    city: 'Florence',
    state: 'MA',
    latitude: '42.331578',
    longitude: '-72.692571',
    timeZoneId: 'America/New_York',
  },
  '01063': {
    zip: '01063',
    city: 'Northampton',
    state: 'MA',
    latitude: '42.319077',
    longitude: '-72.644431',
    timeZoneId: 'America/New_York',
  },
  '01066': {
    zip: '01066',
    city: 'North Hatfield',
    state: 'MA',
    latitude: '42.410613',
    longitude: '-72.625559',
    timeZoneId: 'America/New_York',
  },
  '01068': {
    zip: '01068',
    city: 'Oakham',
    state: 'MA',
    latitude: '42.346443',
    longitude: '-72.049242',
    timeZoneId: 'America/New_York',
  },
  '01069': {
    zip: '01069',
    city: 'Palmer',
    state: 'MA',
    latitude: '42.165487',
    longitude: '-72.3144',
    timeZoneId: 'America/New_York',
  },
  '01070': {
    zip: '01070',
    city: 'Plainfield',
    state: 'MA',
    latitude: '42.53684',
    longitude: '-72.933103',
    timeZoneId: 'America/New_York',
  },
  '01071': {
    zip: '01071',
    city: 'Russell',
    state: 'MA',
    latitude: '42.171278',
    longitude: '-72.86313',
    timeZoneId: 'America/New_York',
  },
  '01072': {
    zip: '01072',
    city: 'Shutesbury',
    state: 'MA',
    latitude: '42.467788',
    longitude: '-72.42989',
    timeZoneId: 'America/New_York',
  },
  '01073': {
    zip: '01073',
    city: 'Southampton',
    state: 'MA',
    latitude: '42.225636',
    longitude: '-72.721743',
    timeZoneId: 'America/New_York',
  },
  '01074': {
    zip: '01074',
    city: 'South Barre',
    state: 'MA',
    latitude: '42.38295',
    longitude: '-72.100531',
    timeZoneId: 'America/New_York',
  },
  '01075': {
    zip: '01075',
    city: 'South Hadley',
    state: 'MA',
    latitude: '42.25332',
    longitude: '-72.5799',
    timeZoneId: 'America/New_York',
  },
  '01077': {
    zip: '01077',
    city: 'Southwick',
    state: 'MA',
    latitude: '42.053564',
    longitude: '-72.771922',
    timeZoneId: 'America/New_York',
  },
  '01079': {
    zip: '01079',
    city: 'Thorndike',
    state: 'MA',
    latitude: '42.1889',
    longitude: '-72.329835',
    timeZoneId: 'America/New_York',
  },
  '01080': {
    zip: '01080',
    city: 'Three Rivers',
    state: 'MA',
    latitude: '42.181538',
    longitude: '-72.372116',
    timeZoneId: 'America/New_York',
  },
  '01081': {
    zip: '01081',
    city: 'Wales',
    state: 'MA',
    latitude: '42.061203',
    longitude: '-72.22759',
    timeZoneId: 'America/New_York',
  },
  '01082': {
    zip: '01082',
    city: 'Ware',
    state: 'MA',
    latitude: '42.262671',
    longitude: '-72.265357',
    timeZoneId: 'America/New_York',
  },
  '01083': {
    zip: '01083',
    city: 'Warren',
    state: 'MA',
    latitude: '42.212656',
    longitude: '-72.194116',
    timeZoneId: 'America/New_York',
  },
  '01084': {
    zip: '01084',
    city: 'West Chesterfield',
    state: 'MA',
    latitude: '42.408674',
    longitude: '-72.889157',
    timeZoneId: 'America/New_York',
  },
  '01085': {
    zip: '01085',
    city: 'Westfield',
    state: 'MA',
    latitude: '42.15636',
    longitude: '-72.77874',
    timeZoneId: 'America/New_York',
  },
  '01086': {
    zip: '01086',
    city: 'Westfield',
    state: 'MA',
    latitude: '42.14018',
    longitude: '-72.756066',
    timeZoneId: 'America/New_York',
  },
  '01088': {
    zip: '01088',
    city: 'West Hatfield',
    state: 'MA',
    latitude: '42.386547',
    longitude: '-72.639767',
    timeZoneId: 'America/New_York',
  },
  '01089': {
    zip: '01089',
    city: 'West Springfield',
    state: 'MA',
    latitude: '42.117059',
    longitude: '-72.639407',
    timeZoneId: 'America/New_York',
  },
  '01090': {
    zip: '01090',
    city: 'West Springfield',
    state: 'MA',
    latitude: '42.12128',
    longitude: '-72.654857',
    timeZoneId: 'America/New_York',
  },
  '01092': {
    zip: '01092',
    city: 'West Warren',
    state: 'MA',
    latitude: '42.2125',
    longitude: '-72.191667',
    timeZoneId: 'America/New_York',
  },
  '01093': {
    zip: '01093',
    city: 'Whately',
    state: 'MA',
    latitude: '42.435682',
    longitude: '-72.648379',
    timeZoneId: 'America/New_York',
  },
  '01094': {
    zip: '01094',
    city: 'Wheelwright',
    state: 'MA',
    latitude: '42.349508',
    longitude: '-72.140099',
    timeZoneId: 'America/New_York',
  },
  '01095': {
    zip: '01095',
    city: 'Wilbraham',
    state: 'MA',
    latitude: '42.120823',
    longitude: '-72.44327',
    timeZoneId: 'America/New_York',
  },
  '01096': {
    zip: '01096',
    city: 'Williamsburg',
    state: 'MA',
    latitude: '42.399296',
    longitude: '-72.749011',
    timeZoneId: 'America/New_York',
  },
  '01097': {
    zip: '01097',
    city: 'Woronoco',
    state: 'MA',
    latitude: '42.160733',
    longitude: '-72.839797',
    timeZoneId: 'America/New_York',
  },
  '01098': {
    zip: '01098',
    city: 'Worthington',
    state: 'MA',
    latitude: '42.307673',
    longitude: '-72.878084',
    timeZoneId: 'America/New_York',
  },
  '01101': {
    zip: '01101',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.099922',
    longitude: '-72.589584',
    timeZoneId: 'America/New_York',
  },
  '01102': {
    zip: '01102',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.116054',
    longitude: '-72.53858',
    timeZoneId: 'America/New_York',
  },
  '01103': {
    zip: '01103',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.102044',
    longitude: '-72.587143',
    timeZoneId: 'America/New_York',
  },
  '01104': {
    zip: '01104',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.128134',
    longitude: '-72.571488',
    timeZoneId: 'America/New_York',
  },
  '01105': {
    zip: '01105',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.101072',
    longitude: '-72.579177',
    timeZoneId: 'America/New_York',
  },
  '01106': {
    zip: '01106',
    city: 'Longmeadow',
    state: 'MA',
    latitude: '42.052405',
    longitude: '-72.567774',
    timeZoneId: 'America/New_York',
  },
  '01107': {
    zip: '01107',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.122891',
    longitude: '-72.607189',
    timeZoneId: 'America/New_York',
  },
  '01108': {
    zip: '01108',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.082083',
    longitude: '-72.554368',
    timeZoneId: 'America/New_York',
  },
  '01109': {
    zip: '01109',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.118736',
    longitude: '-72.548305',
    timeZoneId: 'America/New_York',
  },
  '01111': {
    zip: '01111',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.117977',
    longitude: '-72.547899',
    timeZoneId: 'America/New_York',
  },
  '01115': {
    zip: '01115',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.104032',
    longitude: '-72.591791',
    timeZoneId: 'America/New_York',
  },
  '01116': {
    zip: '01116',
    city: 'Longmeadow',
    state: 'MA',
    latitude: '42.064444',
    longitude: '-72.513056',
    timeZoneId: 'America/New_York',
  },
  '01118': {
    zip: '01118',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.093934',
    longitude: '-72.527899',
    timeZoneId: 'America/New_York',
  },
  '01119': {
    zip: '01119',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.122853',
    longitude: '-72.506417',
    timeZoneId: 'America/New_York',
  },
  '01128': {
    zip: '01128',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.094006',
    longitude: '-72.49483',
    timeZoneId: 'America/New_York',
  },
  '01129': {
    zip: '01129',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.122932',
    longitude: '-72.486989',
    timeZoneId: 'America/New_York',
  },
  '01138': {
    zip: '01138',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.088597',
    longitude: '-72.561028',
    timeZoneId: 'America/New_York',
  },
  '01139': {
    zip: '01139',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.116316',
    longitude: '-72.546932',
    timeZoneId: 'America/New_York',
  },
  '01144': {
    zip: '01144',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.101625',
    longitude: '-72.594817',
    timeZoneId: 'America/New_York',
  },
  '01151': {
    zip: '01151',
    city: 'Indian Orchard',
    state: 'MA',
    latitude: '42.151001',
    longitude: '-72.506454',
    timeZoneId: 'America/New_York',
  },
  '01152': {
    zip: '01152',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.152789',
    longitude: '-72.511258',
    timeZoneId: 'America/New_York',
  },
  '01199': {
    zip: '01199',
    city: 'Springfield',
    state: 'MA',
    latitude: '42.119147',
    longitude: '-72.603412',
    timeZoneId: 'America/New_York',
  },
  '01201': {
    zip: '01201',
    city: 'Pittsfield',
    state: 'MA',
    latitude: '42.455451',
    longitude: '-73.249993',
    timeZoneId: 'America/New_York',
  },
  '01202': {
    zip: '01202',
    city: 'Pittsfield',
    state: 'MA',
    latitude: '42.457889',
    longitude: '-73.322048',
    timeZoneId: 'America/New_York',
  },
  '01203': {
    zip: '01203',
    city: 'Pittsfield',
    state: 'MA',
    latitude: '42.453189',
    longitude: '-73.262785',
    timeZoneId: 'America/New_York',
  },
  '01220': {
    zip: '01220',
    city: 'Adams',
    state: 'MA',
    latitude: '42.619977',
    longitude: '-73.12078',
    timeZoneId: 'America/New_York',
  },
  '01222': {
    zip: '01222',
    city: 'Ashley Falls',
    state: 'MA',
    latitude: '42.056401',
    longitude: '-73.318783',
    timeZoneId: 'America/New_York',
  },
  '01223': {
    zip: '01223',
    city: 'Becket',
    state: 'MA',
    latitude: '42.381306',
    longitude: '-73.143709',
    timeZoneId: 'America/New_York',
  },
  '01224': {
    zip: '01224',
    city: 'Berkshire',
    state: 'MA',
    latitude: '42.489291',
    longitude: '-73.195563',
    timeZoneId: 'America/New_York',
  },
  '01225': {
    zip: '01225',
    city: 'Cheshire',
    state: 'MA',
    latitude: '42.555166',
    longitude: '-73.156002',
    timeZoneId: 'America/New_York',
  },
  '01226': {
    zip: '01226',
    city: 'Dalton',
    state: 'MA',
    latitude: '42.469716',
    longitude: '-73.162714',
    timeZoneId: 'America/New_York',
  },
  '01227': {
    zip: '01227',
    city: 'Dalton',
    state: 'MA',
    latitude: '42.472396',
    longitude: '-73.162019',
    timeZoneId: 'America/New_York',
  },
  '01229': {
    zip: '01229',
    city: 'Glendale',
    state: 'MA',
    latitude: '42.286122',
    longitude: '-73.346812',
    timeZoneId: 'America/New_York',
  },
  '01230': {
    zip: '01230',
    city: 'Great Barrington',
    state: 'MA',
    latitude: '42.18366',
    longitude: '-73.36398',
    timeZoneId: 'America/New_York',
  },
  '01235': {
    zip: '01235',
    city: 'Hinsdale',
    state: 'MA',
    latitude: '42.43086',
    longitude: '-73.06938',
    timeZoneId: 'America/New_York',
  },
  '01236': {
    zip: '01236',
    city: 'Housatonic',
    state: 'MA',
    latitude: '42.27474',
    longitude: '-73.3704',
    timeZoneId: 'America/New_York',
  },
  '01237': {
    zip: '01237',
    city: 'Lanesborough',
    state: 'MA',
    latitude: '42.53934',
    longitude: '-73.2462',
    timeZoneId: 'America/New_York',
  },
  '01238': {
    zip: '01238',
    city: 'Lee',
    state: 'MA',
    latitude: '42.30132',
    longitude: '-73.23198',
    timeZoneId: 'America/New_York',
  },
  '01240': {
    zip: '01240',
    city: 'Lenox',
    state: 'MA',
    latitude: '42.363117',
    longitude: '-73.273909',
    timeZoneId: 'America/New_York',
  },
  '01242': {
    zip: '01242',
    city: 'Lenox Dale',
    state: 'MA',
    latitude: '42.330029',
    longitude: '-73.250299',
    timeZoneId: 'America/New_York',
  },
  '01243': {
    zip: '01243',
    city: 'Middlefield',
    state: 'MA',
    latitude: '42.346522',
    longitude: '-73.008342',
    timeZoneId: 'America/New_York',
  },
  '01244': {
    zip: '01244',
    city: 'Mill River',
    state: 'MA',
    latitude: '42.114506',
    longitude: '-73.267171',
    timeZoneId: 'America/New_York',
  },
  '01245': {
    zip: '01245',
    city: 'Monterey',
    state: 'MA',
    latitude: '42.2088',
    longitude: '-73.19724',
    timeZoneId: 'America/New_York',
  },
  '01247': {
    zip: '01247',
    city: 'North Adams',
    state: 'MA',
    latitude: '42.68712',
    longitude: '-73.07436',
    timeZoneId: 'America/New_York',
  },
  '01252': {
    zip: '01252',
    city: 'North Egremont',
    state: 'MA',
    latitude: '42.196701',
    longitude: '-73.436284',
    timeZoneId: 'America/New_York',
  },
  '01253': {
    zip: '01253',
    city: 'Otis',
    state: 'MA',
    latitude: '42.1923',
    longitude: '-73.06848',
    timeZoneId: 'America/New_York',
  },
  '01254': {
    zip: '01254',
    city: 'Richmond',
    state: 'MA',
    latitude: '42.401705',
    longitude: '-73.348236',
    timeZoneId: 'America/New_York',
  },
  '01255': {
    zip: '01255',
    city: 'Sandisfield',
    state: 'MA',
    latitude: '42.11784',
    longitude: '-73.13412',
    timeZoneId: 'America/New_York',
  },
  '01256': {
    zip: '01256',
    city: 'Savoy',
    state: 'MA',
    latitude: '42.576296',
    longitude: '-73.030921',
    timeZoneId: 'America/New_York',
  },
  '01257': {
    zip: '01257',
    city: 'Sheffield',
    state: 'MA',
    latitude: '42.077092',
    longitude: '-73.395288',
    timeZoneId: 'America/New_York',
  },
  '01258': {
    zip: '01258',
    city: 'South Egremont',
    state: 'MA',
    latitude: '42.10758',
    longitude: '-73.46508',
    timeZoneId: 'America/New_York',
  },
  '01259': {
    zip: '01259',
    city: 'Southfield',
    state: 'MA',
    latitude: '42.068111',
    longitude: '-73.287921',
    timeZoneId: 'America/New_York',
  },
  '01260': {
    zip: '01260',
    city: 'South Lee',
    state: 'MA',
    latitude: '42.281234',
    longitude: '-73.278924',
    timeZoneId: 'America/New_York',
  },
  '01262': {
    zip: '01262',
    city: 'Stockbridge',
    state: 'MA',
    latitude: '42.303969',
    longitude: '-73.315856',
    timeZoneId: 'America/New_York',
  },
  '01263': {
    zip: '01263',
    city: 'Stockbridge',
    state: 'MA',
    latitude: '42.28901',
    longitude: '-73.319529',
    timeZoneId: 'America/New_York',
  },
  '01264': {
    zip: '01264',
    city: 'Tyringham',
    state: 'MA',
    latitude: '42.243506',
    longitude: '-73.205466',
    timeZoneId: 'America/New_York',
  },
  '01266': {
    zip: '01266',
    city: 'West Stockbridge',
    state: 'MA',
    latitude: '42.333061',
    longitude: '-73.380396',
    timeZoneId: 'America/New_York',
  },
  '01267': {
    zip: '01267',
    city: 'Williamstown',
    state: 'MA',
    latitude: '42.699457',
    longitude: '-73.210225',
    timeZoneId: 'America/New_York',
  },
  '01270': {
    zip: '01270',
    city: 'Windsor',
    state: 'MA',
    latitude: '42.336404',
    longitude: '-73.254387',
    timeZoneId: 'America/New_York',
  },
  '01301': {
    zip: '01301',
    city: 'Greenfield',
    state: 'MA',
    latitude: '42.602912',
    longitude: '-72.598574',
    timeZoneId: 'America/New_York',
  },
  '01302': {
    zip: '01302',
    city: 'Greenfield',
    state: 'MA',
    latitude: '42.65202',
    longitude: '-72.609653',
    timeZoneId: 'America/New_York',
  },
  '01330': {
    zip: '01330',
    city: 'Ashfield',
    state: 'MA',
    latitude: '42.5214',
    longitude: '-72.81312',
    timeZoneId: 'America/New_York',
  },
  '01331': {
    zip: '01331',
    city: 'Athol',
    state: 'MA',
    latitude: '42.58886',
    longitude: '-72.224089',
    timeZoneId: 'America/New_York',
  },
  '01337': {
    zip: '01337',
    city: 'Bernardston',
    state: 'MA',
    latitude: '42.68988',
    longitude: '-72.58374',
    timeZoneId: 'America/New_York',
  },
  '01338': {
    zip: '01338',
    city: 'Buckland',
    state: 'MA',
    latitude: '42.598874',
    longitude: '-72.740885',
    timeZoneId: 'America/New_York',
  },
  '01339': {
    zip: '01339',
    city: 'Charlemont',
    state: 'MA',
    latitude: '42.64542',
    longitude: '-72.88026',
    timeZoneId: 'America/New_York',
  },
  '01340': {
    zip: '01340',
    city: 'Colrain',
    state: 'MA',
    latitude: '42.711706',
    longitude: '-72.719125',
    timeZoneId: 'America/New_York',
  },
  '01341': {
    zip: '01341',
    city: 'Conway',
    state: 'MA',
    latitude: '42.511022',
    longitude: '-72.688092',
    timeZoneId: 'America/New_York',
  },
  '01342': {
    zip: '01342',
    city: 'Deerfield',
    state: 'MA',
    latitude: '42.553684',
    longitude: '-72.589272',
    timeZoneId: 'America/New_York',
  },
  '01343': {
    zip: '01343',
    city: 'Drury',
    state: 'MA',
    latitude: '42.6438',
    longitude: '-72.987878',
    timeZoneId: 'America/New_York',
  },
  '01344': {
    zip: '01344',
    city: 'Erving',
    state: 'MA',
    latitude: '42.605957',
    longitude: '-72.410738',
    timeZoneId: 'America/New_York',
  },
  '01346': {
    zip: '01346',
    city: 'Heath',
    state: 'MA',
    latitude: '42.67272',
    longitude: '-72.83442',
    timeZoneId: 'America/New_York',
  },
  '01347': {
    zip: '01347',
    city: 'Lake Pleasant',
    state: 'MA',
    latitude: '42.557611',
    longitude: '-72.518664',
    timeZoneId: 'America/New_York',
  },
  '01349': {
    zip: '01349',
    city: 'Millers Falls',
    state: 'MA',
    latitude: '42.57872',
    longitude: '-72.49214',
    timeZoneId: 'America/New_York',
  },
  '01350': {
    zip: '01350',
    city: 'Monroe Bridge',
    state: 'MA',
    latitude: '42.716667',
    longitude: '-72.983333',
    timeZoneId: 'America/New_York',
  },
  '01351': {
    zip: '01351',
    city: 'Montague',
    state: 'MA',
    latitude: '42.536668',
    longitude: '-72.530141',
    timeZoneId: 'America/New_York',
  },
  '01354': {
    zip: '01354',
    city: 'Gill',
    state: 'MA',
    latitude: '42.71118',
    longitude: '-72.44862',
    timeZoneId: 'America/New_York',
  },
  '01355': {
    zip: '01355',
    city: 'New Salem',
    state: 'MA',
    latitude: '42.485715',
    longitude: '-72.341275',
    timeZoneId: 'America/New_York',
  },
  '01360': {
    zip: '01360',
    city: 'Northfield',
    state: 'MA',
    latitude: '42.700499',
    longitude: '-72.450887',
    timeZoneId: 'America/New_York',
  },
  '01364': {
    zip: '01364',
    city: 'Orange',
    state: 'MA',
    latitude: '42.58908',
    longitude: '-72.31944',
    timeZoneId: 'America/New_York',
  },
  '01366': {
    zip: '01366',
    city: 'Petersham',
    state: 'MA',
    latitude: '42.472326',
    longitude: '-72.204057',
    timeZoneId: 'America/New_York',
  },
  '01367': {
    zip: '01367',
    city: 'Rowe',
    state: 'MA',
    latitude: '42.689349',
    longitude: '-72.935421',
    timeZoneId: 'America/New_York',
  },
  '01368': {
    zip: '01368',
    city: 'Royalston',
    state: 'MA',
    latitude: '42.684928',
    longitude: '-72.196002',
    timeZoneId: 'America/New_York',
  },
  '01370': {
    zip: '01370',
    city: 'Shelburne Falls',
    state: 'MA',
    latitude: '42.58338',
    longitude: '-72.77154',
    timeZoneId: 'America/New_York',
  },
  '01373': {
    zip: '01373',
    city: 'South Deerfield',
    state: 'MA',
    latitude: '42.48624',
    longitude: '-72.6102',
    timeZoneId: 'America/New_York',
  },
  '01375': {
    zip: '01375',
    city: 'Sunderland',
    state: 'MA',
    latitude: '42.461326',
    longitude: '-72.563693',
    timeZoneId: 'America/New_York',
  },
  '01376': {
    zip: '01376',
    city: 'Turners Falls',
    state: 'MA',
    latitude: '42.62724',
    longitude: '-72.52908',
    timeZoneId: 'America/New_York',
  },
  '01378': {
    zip: '01378',
    city: 'Warwick',
    state: 'MA',
    latitude: '42.677282',
    longitude: '-72.343139',
    timeZoneId: 'America/New_York',
  },
  '01379': {
    zip: '01379',
    city: 'Wendell',
    state: 'MA',
    latitude: '42.561643',
    longitude: '-72.385557',
    timeZoneId: 'America/New_York',
  },
  '01380': {
    zip: '01380',
    city: 'Wendell Depot',
    state: 'MA',
    latitude: '42.569537',
    longitude: '-72.406436',
    timeZoneId: 'America/New_York',
  },
  '01420': {
    zip: '01420',
    city: 'Fitchburg',
    state: 'MA',
    latitude: '42.576269',
    longitude: '-71.802089',
    timeZoneId: 'America/New_York',
  },
  '01430': {
    zip: '01430',
    city: 'Ashburnham',
    state: 'MA',
    latitude: '42.6536',
    longitude: '-71.924255',
    timeZoneId: 'America/New_York',
  },
  '01431': {
    zip: '01431',
    city: 'Ashby',
    state: 'MA',
    latitude: '42.677809',
    longitude: '-71.811241',
    timeZoneId: 'America/New_York',
  },
  '01432': {
    zip: '01432',
    city: 'Ayer',
    state: 'MA',
    latitude: '42.559756',
    longitude: '-71.57532',
    timeZoneId: 'America/New_York',
  },
  '01434': {
    zip: '01434',
    city: 'Devens',
    state: 'MA',
    latitude: '42.560949',
    longitude: '-71.576624',
    timeZoneId: 'America/New_York',
  },
  '01436': {
    zip: '01436',
    city: 'Baldwinville',
    state: 'MA',
    latitude: '42.59454',
    longitude: '-72.08628',
    timeZoneId: 'America/New_York',
  },
  '01438': {
    zip: '01438',
    city: 'East Templeton',
    state: 'MA',
    latitude: '42.563733',
    longitude: '-72.040368',
    timeZoneId: 'America/New_York',
  },
  '01440': {
    zip: '01440',
    city: 'Gardner',
    state: 'MA',
    latitude: '42.574542',
    longitude: '-71.995414',
    timeZoneId: 'America/New_York',
  },
  '01441': {
    zip: '01441',
    city: 'Westminster',
    state: 'MA',
    latitude: '42.573616',
    longitude: '-71.994641',
    timeZoneId: 'America/New_York',
  },
  '01450': {
    zip: '01450',
    city: 'Groton',
    state: 'MA',
    latitude: '42.608416',
    longitude: '-71.556651',
    timeZoneId: 'America/New_York',
  },
  '01451': {
    zip: '01451',
    city: 'Harvard',
    state: 'MA',
    latitude: '42.503056',
    longitude: '-71.575944',
    timeZoneId: 'America/New_York',
  },
  '01452': {
    zip: '01452',
    city: 'Hubbardston',
    state: 'MA',
    latitude: '42.487928',
    longitude: '-72.007111',
    timeZoneId: 'America/New_York',
  },
  '01453': {
    zip: '01453',
    city: 'Leominster',
    state: 'MA',
    latitude: '42.525279',
    longitude: '-71.753473',
    timeZoneId: 'America/New_York',
  },
  '01460': {
    zip: '01460',
    city: 'Littleton',
    state: 'MA',
    latitude: '42.543481',
    longitude: '-71.484168',
    timeZoneId: 'America/New_York',
  },
  '01462': {
    zip: '01462',
    city: 'Lunenburg',
    state: 'MA',
    latitude: '42.588874',
    longitude: '-71.731381',
    timeZoneId: 'America/New_York',
  },
  '01463': {
    zip: '01463',
    city: 'Pepperell',
    state: 'MA',
    latitude: '42.6675',
    longitude: '-71.59944',
    timeZoneId: 'America/New_York',
  },
  '01464': {
    zip: '01464',
    city: 'Shirley',
    state: 'MA',
    latitude: '42.57786',
    longitude: '-71.64384',
    timeZoneId: 'America/New_York',
  },
  '01467': {
    zip: '01467',
    city: 'Still River',
    state: 'MA',
    latitude: '42.483655',
    longitude: '-71.621609',
    timeZoneId: 'America/New_York',
  },
  '01468': {
    zip: '01468',
    city: 'Templeton',
    state: 'MA',
    latitude: '42.54986',
    longitude: '-72.065785',
    timeZoneId: 'America/New_York',
  },
  '01469': {
    zip: '01469',
    city: 'Townsend',
    state: 'MA',
    latitude: '42.653698',
    longitude: '-71.691559',
    timeZoneId: 'America/New_York',
  },
  '01470': {
    zip: '01470',
    city: 'Groton',
    state: 'MA',
    latitude: '42.608252',
    longitude: '-71.57189',
    timeZoneId: 'America/New_York',
  },
  '01471': {
    zip: '01471',
    city: 'Groton',
    state: 'MA',
    latitude: '42.607854',
    longitude: '-71.572329',
    timeZoneId: 'America/New_York',
  },
  '01472': {
    zip: '01472',
    city: 'West Groton',
    state: 'MA',
    latitude: '42.606629',
    longitude: '-71.62914',
    timeZoneId: 'America/New_York',
  },
  '01473': {
    zip: '01473',
    city: 'Westminster',
    state: 'MA',
    latitude: '42.540906',
    longitude: '-71.906138',
    timeZoneId: 'America/New_York',
  },
  '01474': {
    zip: '01474',
    city: 'West Townsend',
    state: 'MA',
    latitude: '42.67116',
    longitude: '-71.71308',
    timeZoneId: 'America/New_York',
  },
  '01475': {
    zip: '01475',
    city: 'Winchendon',
    state: 'MA',
    latitude: '42.677984',
    longitude: '-72.045113',
    timeZoneId: 'America/New_York',
  },
  '01501': {
    zip: '01501',
    city: 'Auburn',
    state: 'MA',
    latitude: '42.205001',
    longitude: '-71.840473',
    timeZoneId: 'America/New_York',
  },
  '01503': {
    zip: '01503',
    city: 'Berlin',
    state: 'MA',
    latitude: '42.374084',
    longitude: '-71.632431',
    timeZoneId: 'America/New_York',
  },
  '01504': {
    zip: '01504',
    city: 'Blackstone',
    state: 'MA',
    latitude: '42.026266',
    longitude: '-71.528483',
    timeZoneId: 'America/New_York',
  },
  '01505': {
    zip: '01505',
    city: 'Boylston',
    state: 'MA',
    latitude: '42.331643',
    longitude: '-71.734141',
    timeZoneId: 'America/New_York',
  },
  '01506': {
    zip: '01506',
    city: 'Brookfield',
    state: 'MA',
    latitude: '42.203252',
    longitude: '-72.10005',
    timeZoneId: 'America/New_York',
  },
  '01507': {
    zip: '01507',
    city: 'Charlton',
    state: 'MA',
    latitude: '42.148385',
    longitude: '-71.941347',
    timeZoneId: 'America/New_York',
  },
  '01508': {
    zip: '01508',
    city: 'Charlton City',
    state: 'MA',
    latitude: '42.151392',
    longitude: '-71.989604',
    timeZoneId: 'America/New_York',
  },
  '01509': {
    zip: '01509',
    city: 'Charlton Depot',
    state: 'MA',
    latitude: '42.172996',
    longitude: '-71.97562',
    timeZoneId: 'America/New_York',
  },
  '01510': {
    zip: '01510',
    city: 'Clinton',
    state: 'MA',
    latitude: '42.419337',
    longitude: '-71.685094',
    timeZoneId: 'America/New_York',
  },
  '01515': {
    zip: '01515',
    city: 'East Brookfield',
    state: 'MA',
    latitude: '42.222612',
    longitude: '-72.053004',
    timeZoneId: 'America/New_York',
  },
  '01516': {
    zip: '01516',
    city: 'Douglas',
    state: 'MA',
    latitude: '42.05772',
    longitude: '-71.75502',
    timeZoneId: 'America/New_York',
  },
  '01518': {
    zip: '01518',
    city: 'Fiskdale',
    state: 'MA',
    latitude: '42.120891',
    longitude: '-72.110243',
    timeZoneId: 'America/New_York',
  },
  '01519': {
    zip: '01519',
    city: 'Grafton',
    state: 'MA',
    latitude: '42.201023',
    longitude: '-71.687981',
    timeZoneId: 'America/New_York',
  },
  '01520': {
    zip: '01520',
    city: 'Holden',
    state: 'MA',
    latitude: '42.345803',
    longitude: '-71.846302',
    timeZoneId: 'America/New_York',
  },
  '01521': {
    zip: '01521',
    city: 'Holland',
    state: 'MA',
    latitude: '42.042514',
    longitude: '-72.149839',
    timeZoneId: 'America/New_York',
  },
  '01522': {
    zip: '01522',
    city: 'Jefferson',
    state: 'MA',
    latitude: '42.37458',
    longitude: '-71.866788',
    timeZoneId: 'America/New_York',
  },
  '01523': {
    zip: '01523',
    city: 'Lancaster',
    state: 'MA',
    latitude: '42.477605',
    longitude: '-71.684044',
    timeZoneId: 'America/New_York',
  },
  '01524': {
    zip: '01524',
    city: 'Leicester',
    state: 'MA',
    latitude: '42.232557',
    longitude: '-71.91828',
    timeZoneId: 'America/New_York',
  },
  '01525': {
    zip: '01525',
    city: 'Linwood',
    state: 'MA',
    latitude: '42.098419',
    longitude: '-71.643912',
    timeZoneId: 'America/New_York',
  },
  '01526': {
    zip: '01526',
    city: 'Manchaug',
    state: 'MA',
    latitude: '42.089553',
    longitude: '-71.746666',
    timeZoneId: 'America/New_York',
  },
  '01527': {
    zip: '01527',
    city: 'Millbury',
    state: 'MA',
    latitude: '42.197286',
    longitude: '-71.770146',
    timeZoneId: 'America/New_York',
  },
  '01529': {
    zip: '01529',
    city: 'Millville',
    state: 'MA',
    latitude: '42.023722',
    longitude: '-71.58381',
    timeZoneId: 'America/New_York',
  },
  '01531': {
    zip: '01531',
    city: 'New Braintree',
    state: 'MA',
    latitude: '42.321017',
    longitude: '-72.142586',
    timeZoneId: 'America/New_York',
  },
  '01532': {
    zip: '01532',
    city: 'Northborough',
    state: 'MA',
    latitude: '42.316146',
    longitude: '-71.648168',
    timeZoneId: 'America/New_York',
  },
  '01534': {
    zip: '01534',
    city: 'Northbridge',
    state: 'MA',
    latitude: '42.143607',
    longitude: '-71.654859',
    timeZoneId: 'America/New_York',
  },
  '01535': {
    zip: '01535',
    city: 'North Brookfield',
    state: 'MA',
    latitude: '42.267155',
    longitude: '-72.075987',
    timeZoneId: 'America/New_York',
  },
  '01536': {
    zip: '01536',
    city: 'North Grafton',
    state: 'MA',
    latitude: '42.229493',
    longitude: '-71.703587',
    timeZoneId: 'America/New_York',
  },
  '01537': {
    zip: '01537',
    city: 'North Oxford',
    state: 'MA',
    latitude: '42.167512',
    longitude: '-71.8889',
    timeZoneId: 'America/New_York',
  },
  '01538': {
    zip: '01538',
    city: 'North Uxbridge',
    state: 'MA',
    latitude: '42.090256',
    longitude: '-71.639891',
    timeZoneId: 'America/New_York',
  },
  '01540': {
    zip: '01540',
    city: 'Oxford',
    state: 'MA',
    latitude: '42.112676',
    longitude: '-71.870984',
    timeZoneId: 'America/New_York',
  },
  '01541': {
    zip: '01541',
    city: 'Princeton',
    state: 'MA',
    latitude: '42.450061',
    longitude: '-71.884655',
    timeZoneId: 'America/New_York',
  },
  '01542': {
    zip: '01542',
    city: 'Rochdale',
    state: 'MA',
    latitude: '42.198886',
    longitude: '-71.905902',
    timeZoneId: 'America/New_York',
  },
  '01543': {
    zip: '01543',
    city: 'Rutland',
    state: 'MA',
    latitude: '42.374268',
    longitude: '-71.95045',
    timeZoneId: 'America/New_York',
  },
  '01545': {
    zip: '01545',
    city: 'Shrewsbury',
    state: 'MA',
    latitude: '42.286758',
    longitude: '-71.72154',
    timeZoneId: 'America/New_York',
  },
  '01546': {
    zip: '01546',
    city: 'Shrewsbury',
    state: 'MA',
    latitude: '42.29182',
    longitude: '-71.745989',
    timeZoneId: 'America/New_York',
  },
  '01550': {
    zip: '01550',
    city: 'Southbridge',
    state: 'MA',
    latitude: '42.077174',
    longitude: '-72.032139',
    timeZoneId: 'America/New_York',
  },
  '01560': {
    zip: '01560',
    city: 'South Grafton',
    state: 'MA',
    latitude: '42.177849',
    longitude: '-71.693325',
    timeZoneId: 'America/New_York',
  },
  '01561': {
    zip: '01561',
    city: 'South Lancaster',
    state: 'MA',
    latitude: '42.44104',
    longitude: '-71.689995',
    timeZoneId: 'America/New_York',
  },
  '01562': {
    zip: '01562',
    city: 'Spencer',
    state: 'MA',
    latitude: '42.248647',
    longitude: '-71.995246',
    timeZoneId: 'America/New_York',
  },
  '01564': {
    zip: '01564',
    city: 'Sterling',
    state: 'MA',
    latitude: '42.44166',
    longitude: '-71.77458',
    timeZoneId: 'America/New_York',
  },
  '01566': {
    zip: '01566',
    city: 'Sturbridge',
    state: 'MA',
    latitude: '42.110485',
    longitude: '-72.076312',
    timeZoneId: 'America/New_York',
  },
  '01568': {
    zip: '01568',
    city: 'Upton',
    state: 'MA',
    latitude: '42.171445',
    longitude: '-71.604409',
    timeZoneId: 'America/New_York',
  },
  '01569': {
    zip: '01569',
    city: 'Uxbridge',
    state: 'MA',
    latitude: '42.07843',
    longitude: '-71.634079',
    timeZoneId: 'America/New_York',
  },
  '01570': {
    zip: '01570',
    city: 'Webster',
    state: 'MA',
    latitude: '42.052997',
    longitude: '-71.87971',
    timeZoneId: 'America/New_York',
  },
  '01571': {
    zip: '01571',
    city: 'Dudley',
    state: 'MA',
    latitude: '42.057246',
    longitude: '-71.912522',
    timeZoneId: 'America/New_York',
  },
  '01581': {
    zip: '01581',
    city: 'Westborough',
    state: 'MA',
    latitude: '42.26661',
    longitude: '-71.617194',
    timeZoneId: 'America/New_York',
  },
  '01583': {
    zip: '01583',
    city: 'West Boylston',
    state: 'MA',
    latitude: '42.360502',
    longitude: '-71.787608',
    timeZoneId: 'America/New_York',
  },
  '01585': {
    zip: '01585',
    city: 'West Brookfield',
    state: 'MA',
    latitude: '42.235246',
    longitude: '-72.146449',
    timeZoneId: 'America/New_York',
  },
  '01586': {
    zip: '01586',
    city: 'West Millbury',
    state: 'MA',
    latitude: '42.12631',
    longitude: '-71.646126',
    timeZoneId: 'America/New_York',
  },
  '01588': {
    zip: '01588',
    city: 'Whitinsville',
    state: 'MA',
    latitude: '42.122324',
    longitude: '-71.671383',
    timeZoneId: 'America/New_York',
  },
  '01590': {
    zip: '01590',
    city: 'Sutton',
    state: 'MA',
    latitude: '42.138424',
    longitude: '-71.752496',
    timeZoneId: 'America/New_York',
  },
  '01601': {
    zip: '01601',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.271095',
    longitude: '-71.809743',
    timeZoneId: 'America/New_York',
  },
  '01602': {
    zip: '01602',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.27018',
    longitude: '-71.845149',
    timeZoneId: 'America/New_York',
  },
  '01603': {
    zip: '01603',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.240138',
    longitude: '-71.83701',
    timeZoneId: 'America/New_York',
  },
  '01604': {
    zip: '01604',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.254072',
    longitude: '-71.773068',
    timeZoneId: 'America/New_York',
  },
  '01605': {
    zip: '01605',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.286159',
    longitude: '-71.795924',
    timeZoneId: 'America/New_York',
  },
  '01606': {
    zip: '01606',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.31095',
    longitude: '-71.801404',
    timeZoneId: 'America/New_York',
  },
  '01607': {
    zip: '01607',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.22725',
    longitude: '-71.793758',
    timeZoneId: 'America/New_York',
  },
  '01608': {
    zip: '01608',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.261746',
    longitude: '-71.802267',
    timeZoneId: 'America/New_York',
  },
  '01609': {
    zip: '01609',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.276415',
    longitude: '-71.819491',
    timeZoneId: 'America/New_York',
  },
  '01610': {
    zip: '01610',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.249011',
    longitude: '-71.807956',
    timeZoneId: 'America/New_York',
  },
  '01611': {
    zip: '01611',
    city: 'Cherry Valley',
    state: 'MA',
    latitude: '42.242998',
    longitude: '-71.873372',
    timeZoneId: 'America/New_York',
  },
  '01612': {
    zip: '01612',
    city: 'Paxton',
    state: 'MA',
    latitude: '42.30158',
    longitude: '-71.909525',
    timeZoneId: 'America/New_York',
  },
  '01613': {
    zip: '01613',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.265737',
    longitude: '-71.796527',
    timeZoneId: 'America/New_York',
  },
  '01614': {
    zip: '01614',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.260921',
    longitude: '-71.79949',
    timeZoneId: 'America/New_York',
  },
  '01615': {
    zip: '01615',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.270221',
    longitude: '-71.809332',
    timeZoneId: 'America/New_York',
  },
  '01653': {
    zip: '01653',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.292703',
    longitude: '-71.779043',
    timeZoneId: 'America/New_York',
  },
  '01655': {
    zip: '01655',
    city: 'Worcester',
    state: 'MA',
    latitude: '42.276871',
    longitude: '-71.761225',
    timeZoneId: 'America/New_York',
  },
  '01701': {
    zip: '01701',
    city: 'Framingham',
    state: 'MA',
    latitude: '42.315117',
    longitude: '-71.43412',
    timeZoneId: 'America/New_York',
  },
  '01702': {
    zip: '01702',
    city: 'Framingham',
    state: 'MA',
    latitude: '42.272053',
    longitude: '-71.424769',
    timeZoneId: 'America/New_York',
  },
  '01703': {
    zip: '01703',
    city: 'Framingham',
    state: 'MA',
    latitude: '42.323005',
    longitude: '-71.441791',
    timeZoneId: 'America/New_York',
  },
  '01704': {
    zip: '01704',
    city: 'Framingham',
    state: 'MA',
    latitude: '42.318232',
    longitude: '-71.442658',
    timeZoneId: 'America/New_York',
  },
  '01705': {
    zip: '01705',
    city: 'Framingham',
    state: 'MA',
    latitude: '42.32025',
    longitude: '-71.439495',
    timeZoneId: 'America/New_York',
  },
  '01718': {
    zip: '01718',
    city: 'Acton',
    state: 'MA',
    latitude: '42.517046',
    longitude: '-71.412305',
    timeZoneId: 'America/New_York',
  },
  '01719': {
    zip: '01719',
    city: 'Boxborough',
    state: 'MA',
    latitude: '42.484999',
    longitude: '-71.518519',
    timeZoneId: 'America/New_York',
  },
  '01720': {
    zip: '01720',
    city: 'Acton',
    state: 'MA',
    latitude: '42.477355',
    longitude: '-71.446686',
    timeZoneId: 'America/New_York',
  },
  '01721': {
    zip: '01721',
    city: 'Ashland',
    state: 'MA',
    latitude: '42.253129',
    longitude: '-71.460756',
    timeZoneId: 'America/New_York',
  },
  '01730': {
    zip: '01730',
    city: 'Bedford',
    state: 'MA',
    latitude: '42.493394',
    longitude: '-71.278531',
    timeZoneId: 'America/New_York',
  },
  '01731': {
    zip: '01731',
    city: 'Hanscom AFB',
    state: 'MA',
    latitude: '42.453933',
    longitude: '-71.278811',
    timeZoneId: 'America/New_York',
  },
  '01740': {
    zip: '01740',
    city: 'Bolton',
    state: 'MA',
    latitude: '42.436503',
    longitude: '-71.608599',
    timeZoneId: 'America/New_York',
  },
  '01741': {
    zip: '01741',
    city: 'Carlisle',
    state: 'MA',
    latitude: '42.530569',
    longitude: '-71.348298',
    timeZoneId: 'America/New_York',
  },
  '01742': {
    zip: '01742',
    city: 'Concord',
    state: 'MA',
    latitude: '42.456444',
    longitude: '-71.370954',
    timeZoneId: 'America/New_York',
  },
  '01745': {
    zip: '01745',
    city: 'Fayville',
    state: 'MA',
    latitude: '42.29231',
    longitude: '-71.504095',
    timeZoneId: 'America/New_York',
  },
  '01746': {
    zip: '01746',
    city: 'Holliston',
    state: 'MA',
    latitude: '42.198796',
    longitude: '-71.435066',
    timeZoneId: 'America/New_York',
  },
  '01747': {
    zip: '01747',
    city: 'Hopedale',
    state: 'MA',
    latitude: '42.128675',
    longitude: '-71.536023',
    timeZoneId: 'America/New_York',
  },
  '01748': {
    zip: '01748',
    city: 'Hopkinton',
    state: 'MA',
    latitude: '42.217945',
    longitude: '-71.533938',
    timeZoneId: 'America/New_York',
  },
  '01749': {
    zip: '01749',
    city: 'Hudson',
    state: 'MA',
    latitude: '42.388756',
    longitude: '-71.557437',
    timeZoneId: 'America/New_York',
  },
  '01752': {
    zip: '01752',
    city: 'Marlborough',
    state: 'MA',
    latitude: '42.349703',
    longitude: '-71.546947',
    timeZoneId: 'America/New_York',
  },
  '01754': {
    zip: '01754',
    city: 'Maynard',
    state: 'MA',
    latitude: '42.430993',
    longitude: '-71.451177',
    timeZoneId: 'America/New_York',
  },
  '01756': {
    zip: '01756',
    city: 'Mendon',
    state: 'MA',
    latitude: '42.097875',
    longitude: '-71.549061',
    timeZoneId: 'America/New_York',
  },
  '01757': {
    zip: '01757',
    city: 'Milford',
    state: 'MA',
    latitude: '42.149786',
    longitude: '-71.522396',
    timeZoneId: 'America/New_York',
  },
  '01760': {
    zip: '01760',
    city: 'Natick',
    state: 'MA',
    latitude: '42.287156',
    longitude: '-71.352308',
    timeZoneId: 'America/New_York',
  },
  '01770': {
    zip: '01770',
    city: 'Sherborn',
    state: 'MA',
    latitude: '42.232964',
    longitude: '-71.381395',
    timeZoneId: 'America/New_York',
  },
  '01772': {
    zip: '01772',
    city: 'Southborough',
    state: 'MA',
    latitude: '42.295044',
    longitude: '-71.532273',
    timeZoneId: 'America/New_York',
  },
  '01773': {
    zip: '01773',
    city: 'Lincoln',
    state: 'MA',
    latitude: '42.423884',
    longitude: '-71.314205',
    timeZoneId: 'America/New_York',
  },
  '01775': {
    zip: '01775',
    city: 'Stow',
    state: 'MA',
    latitude: '42.423402',
    longitude: '-71.514638',
    timeZoneId: 'America/New_York',
  },
  '01776': {
    zip: '01776',
    city: 'Sudbury',
    state: 'MA',
    latitude: '42.382955',
    longitude: '-71.427696',
    timeZoneId: 'America/New_York',
  },
  '01778': {
    zip: '01778',
    city: 'Wayland',
    state: 'MA',
    latitude: '42.3612',
    longitude: '-71.36388',
    timeZoneId: 'America/New_York',
  },
  '01784': {
    zip: '01784',
    city: 'Woodville',
    state: 'MA',
    latitude: '42.232334',
    longitude: '-71.559628',
    timeZoneId: 'America/New_York',
  },
  '01801': {
    zip: '01801',
    city: 'Woburn',
    state: 'MA',
    latitude: '42.484238',
    longitude: '-71.15743',
    timeZoneId: 'America/New_York',
  },
  '01803': {
    zip: '01803',
    city: 'Burlington',
    state: 'MA',
    latitude: '42.508545',
    longitude: '-71.201304',
    timeZoneId: 'America/New_York',
  },
  '01805': {
    zip: '01805',
    city: 'Burlington',
    state: 'MA',
    latitude: '42.506346',
    longitude: '-71.198244',
    timeZoneId: 'America/New_York',
  },
  '01810': {
    zip: '01810',
    city: 'Andover',
    state: 'MA',
    latitude: '42.648787',
    longitude: '-71.162026',
    timeZoneId: 'America/New_York',
  },
  '01812': {
    zip: '01812',
    city: 'Andover',
    state: 'MA',
    latitude: '42.648594',
    longitude: '-71.182543',
    timeZoneId: 'America/New_York',
  },
  '01813': {
    zip: '01813',
    city: 'Woburn',
    state: 'MA',
    latitude: '42.508236',
    longitude: '-71.123401',
    timeZoneId: 'America/New_York',
  },
  '01815': {
    zip: '01815',
    city: 'Woburn',
    state: 'MA',
    latitude: '42.505881',
    longitude: '-71.127147',
    timeZoneId: 'America/New_York',
  },
  '01821': {
    zip: '01821',
    city: 'Billerica',
    state: 'MA',
    latitude: '42.549001',
    longitude: '-71.247667',
    timeZoneId: 'America/New_York',
  },
  '01822': {
    zip: '01822',
    city: 'Billerica',
    state: 'MA',
    latitude: '42.541274',
    longitude: '-71.262879',
    timeZoneId: 'America/New_York',
  },
  '01824': {
    zip: '01824',
    city: 'Chelmsford',
    state: 'MA',
    latitude: '42.62328',
    longitude: '-71.36472',
    timeZoneId: 'America/New_York',
  },
  '01826': {
    zip: '01826',
    city: 'Dracut',
    state: 'MA',
    latitude: '42.676829',
    longitude: '-71.314567',
    timeZoneId: 'America/New_York',
  },
  '01827': {
    zip: '01827',
    city: 'Dunstable',
    state: 'MA',
    latitude: '42.669019',
    longitude: '-71.496025',
    timeZoneId: 'America/New_York',
  },
  '01830': {
    zip: '01830',
    city: 'Haverhill',
    state: 'MA',
    latitude: '42.781433',
    longitude: '-71.084197',
    timeZoneId: 'America/New_York',
  },
  '01831': {
    zip: '01831',
    city: 'Haverhill',
    state: 'MA',
    latitude: '42.78576',
    longitude: '-71.084665',
    timeZoneId: 'America/New_York',
  },
  '01832': {
    zip: '01832',
    city: 'Haverhill',
    state: 'MA',
    latitude: '42.781791',
    longitude: '-71.117351',
    timeZoneId: 'America/New_York',
  },
  '01833': {
    zip: '01833',
    city: 'Georgetown',
    state: 'MA',
    latitude: '42.72468',
    longitude: '-70.9758',
    timeZoneId: 'America/New_York',
  },
  '01834': {
    zip: '01834',
    city: 'Groveland',
    state: 'MA',
    latitude: '42.755514',
    longitude: '-71.029608',
    timeZoneId: 'America/New_York',
  },
  '01835': {
    zip: '01835',
    city: 'Haverhill',
    state: 'MA',
    latitude: '42.754563',
    longitude: '-71.087282',
    timeZoneId: 'America/New_York',
  },
  '01840': {
    zip: '01840',
    city: 'Lawrence',
    state: 'MA',
    latitude: '42.710311',
    longitude: '-71.16402',
    timeZoneId: 'America/New_York',
  },
  '01841': {
    zip: '01841',
    city: 'Lawrence',
    state: 'MA',
    latitude: '42.711749',
    longitude: '-71.16384',
    timeZoneId: 'America/New_York',
  },
  '01842': {
    zip: '01842',
    city: 'Lawrence',
    state: 'MA',
    latitude: '42.705479',
    longitude: '-71.159968',
    timeZoneId: 'America/New_York',
  },
  '01843': {
    zip: '01843',
    city: 'Lawrence',
    state: 'MA',
    latitude: '42.692019',
    longitude: '-71.164643',
    timeZoneId: 'America/New_York',
  },
  '01844': {
    zip: '01844',
    city: 'Methuen',
    state: 'MA',
    latitude: '42.728753',
    longitude: '-71.180274',
    timeZoneId: 'America/New_York',
  },
  '01845': {
    zip: '01845',
    city: 'North Andover',
    state: 'MA',
    latitude: '42.686182',
    longitude: '-71.105718',
    timeZoneId: 'America/New_York',
  },
  '01850': {
    zip: '01850',
    city: 'Lowell',
    state: 'MA',
    latitude: '42.654952',
    longitude: '-71.306891',
    timeZoneId: 'America/New_York',
  },
  '01851': {
    zip: '01851',
    city: 'Lowell',
    state: 'MA',
    latitude: '42.631561',
    longitude: '-71.333924',
    timeZoneId: 'America/New_York',
  },
  '01852': {
    zip: '01852',
    city: 'Lowell',
    state: 'MA',
    latitude: '42.634512',
    longitude: '-71.296816',
    timeZoneId: 'America/New_York',
  },
  '01853': {
    zip: '01853',
    city: 'Lowell',
    state: 'MA',
    latitude: '42.658587',
    longitude: '-71.305216',
    timeZoneId: 'America/New_York',
  },
  '01854': {
    zip: '01854',
    city: 'Lowell',
    state: 'MA',
    latitude: '42.647505',
    longitude: '-71.336201',
    timeZoneId: 'America/New_York',
  },
  '01860': {
    zip: '01860',
    city: 'Merrimac',
    state: 'MA',
    latitude: '42.836632',
    longitude: '-71.006424',
    timeZoneId: 'America/New_York',
  },
  '01862': {
    zip: '01862',
    city: 'North Billerica',
    state: 'MA',
    latitude: '42.56736',
    longitude: '-71.29122',
    timeZoneId: 'America/New_York',
  },
  '01863': {
    zip: '01863',
    city: 'North Chelmsford',
    state: 'MA',
    latitude: '42.63329',
    longitude: '-71.384253',
    timeZoneId: 'America/New_York',
  },
  '01864': {
    zip: '01864',
    city: 'North Reading',
    state: 'MA',
    latitude: '42.578246',
    longitude: '-71.093981',
    timeZoneId: 'America/New_York',
  },
  '01865': {
    zip: '01865',
    city: 'Nutting Lake',
    state: 'MA',
    latitude: '42.534713',
    longitude: '-71.269156',
    timeZoneId: 'America/New_York',
  },
  '01866': {
    zip: '01866',
    city: 'Pinehurst',
    state: 'MA',
    latitude: '42.525394',
    longitude: '-71.227935',
    timeZoneId: 'America/New_York',
  },
  '01867': {
    zip: '01867',
    city: 'Reading',
    state: 'MA',
    latitude: '42.529523',
    longitude: '-71.108868',
    timeZoneId: 'America/New_York',
  },
  '01876': {
    zip: '01876',
    city: 'Tewksbury',
    state: 'MA',
    latitude: '42.599815',
    longitude: '-71.222861',
    timeZoneId: 'America/New_York',
  },
  '01879': {
    zip: '01879',
    city: 'Tyngsboro',
    state: 'MA',
    latitude: '42.673414',
    longitude: '-71.416749',
    timeZoneId: 'America/New_York',
  },
  '01880': {
    zip: '01880',
    city: 'Wakefield',
    state: 'MA',
    latitude: '42.502111',
    longitude: '-71.069674',
    timeZoneId: 'America/New_York',
  },
  '01885': {
    zip: '01885',
    city: 'West Boxford',
    state: 'MA',
    latitude: '42.709481',
    longitude: '-71.058483',
    timeZoneId: 'America/New_York',
  },
  '01886': {
    zip: '01886',
    city: 'Westford',
    state: 'MA',
    latitude: '42.589321',
    longitude: '-71.437474',
    timeZoneId: 'America/New_York',
  },
  '01887': {
    zip: '01887',
    city: 'Wilmington',
    state: 'MA',
    latitude: '42.55977',
    longitude: '-71.169957',
    timeZoneId: 'America/New_York',
  },
  '01888': {
    zip: '01888',
    city: 'Woburn',
    state: 'MA',
    latitude: '42.486156',
    longitude: '-71.158082',
    timeZoneId: 'America/New_York',
  },
  '01889': {
    zip: '01889',
    city: 'North Reading',
    state: 'MA',
    latitude: '42.572012',
    longitude: '-71.111231',
    timeZoneId: 'America/New_York',
  },
  '01890': {
    zip: '01890',
    city: 'Winchester',
    state: 'MA',
    latitude: '42.452923',
    longitude: '-71.146003',
    timeZoneId: 'America/New_York',
  },
  '01899': {
    zip: '01899',
    city: 'Andover',
    state: 'MA',
    latitude: '42.650375',
    longitude: '-71.184561',
    timeZoneId: 'America/New_York',
  },
  '01901': {
    zip: '01901',
    city: 'Lynn',
    state: 'MA',
    latitude: '42.463486',
    longitude: '-70.943394',
    timeZoneId: 'America/New_York',
  },
  '01902': {
    zip: '01902',
    city: 'Lynn',
    state: 'MA',
    latitude: '42.472369',
    longitude: '-70.941341',
    timeZoneId: 'America/New_York',
  },
  '01903': {
    zip: '01903',
    city: 'Lynn',
    state: 'MA',
    latitude: '42.466185',
    longitude: '-70.949729',
    timeZoneId: 'America/New_York',
  },
  '01904': {
    zip: '01904',
    city: 'Lynn',
    state: 'MA',
    latitude: '42.48896',
    longitude: '-70.963687',
    timeZoneId: 'America/New_York',
  },
  '01905': {
    zip: '01905',
    city: 'Lynn',
    state: 'MA',
    latitude: '42.464742',
    longitude: '-70.973863',
    timeZoneId: 'America/New_York',
  },
  '01906': {
    zip: '01906',
    city: 'Saugus',
    state: 'MA',
    latitude: '42.459776',
    longitude: '-71.009684',
    timeZoneId: 'America/New_York',
  },
  '01907': {
    zip: '01907',
    city: 'Swampscott',
    state: 'MA',
    latitude: '42.472258',
    longitude: '-70.908157',
    timeZoneId: 'America/New_York',
  },
  '01908': {
    zip: '01908',
    city: 'Nahant',
    state: 'MA',
    latitude: '42.426338',
    longitude: '-70.924198',
    timeZoneId: 'America/New_York',
  },
  '01910': {
    zip: '01910',
    city: 'Lynn',
    state: 'MA',
    latitude: '42.473328',
    longitude: '-70.961315',
    timeZoneId: 'America/New_York',
  },
  '01913': {
    zip: '01913',
    city: 'Amesbury',
    state: 'MA',
    latitude: '42.85592',
    longitude: '-70.93645',
    timeZoneId: 'America/New_York',
  },
  '01915': {
    zip: '01915',
    city: 'Beverly',
    state: 'MA',
    latitude: '42.56646',
    longitude: '-70.85328',
    timeZoneId: 'America/New_York',
  },
  '01921': {
    zip: '01921',
    city: 'Boxford',
    state: 'MA',
    latitude: '42.674498',
    longitude: '-71.003708',
    timeZoneId: 'America/New_York',
  },
  '01922': {
    zip: '01922',
    city: 'Byfield',
    state: 'MA',
    latitude: '42.755852',
    longitude: '-70.931083',
    timeZoneId: 'America/New_York',
  },
  '01923': {
    zip: '01923',
    city: 'Danvers',
    state: 'MA',
    latitude: '42.570577',
    longitude: '-70.944262',
    timeZoneId: 'America/New_York',
  },
  '01929': {
    zip: '01929',
    city: 'Essex',
    state: 'MA',
    latitude: '42.630853',
    longitude: '-70.783172',
    timeZoneId: 'America/New_York',
  },
  '01930': {
    zip: '01930',
    city: 'Gloucester',
    state: 'MA',
    latitude: '42.6285',
    longitude: '-70.68624',
    timeZoneId: 'America/New_York',
  },
  '01931': {
    zip: '01931',
    city: 'Gloucester',
    state: 'MA',
    latitude: '42.630304',
    longitude: '-70.681613',
    timeZoneId: 'America/New_York',
  },
  '01936': {
    zip: '01936',
    city: 'Hamilton',
    state: 'MA',
    latitude: '42.637922',
    longitude: '-70.859398',
    timeZoneId: 'America/New_York',
  },
  '01937': {
    zip: '01937',
    city: 'Hathorne',
    state: 'MA',
    latitude: '42.590815',
    longitude: '-70.97707',
    timeZoneId: 'America/New_York',
  },
  '01938': {
    zip: '01938',
    city: 'Ipswich',
    state: 'MA',
    latitude: '42.682211',
    longitude: '-70.846774',
    timeZoneId: 'America/New_York',
  },
  '01940': {
    zip: '01940',
    city: 'Lynnfield',
    state: 'MA',
    latitude: '42.53609',
    longitude: '-71.026506',
    timeZoneId: 'America/New_York',
  },
  '01944': {
    zip: '01944',
    city: 'Manchester',
    state: 'MA',
    latitude: '42.580512',
    longitude: '-70.767075',
    timeZoneId: 'America/New_York',
  },
  '01945': {
    zip: '01945',
    city: 'Marblehead',
    state: 'MA',
    latitude: '42.49703',
    longitude: '-70.866105',
    timeZoneId: 'America/New_York',
  },
  '01949': {
    zip: '01949',
    city: 'Middleton',
    state: 'MA',
    latitude: '42.596262',
    longitude: '-71.015245',
    timeZoneId: 'America/New_York',
  },
  '01950': {
    zip: '01950',
    city: 'Newburyport',
    state: 'MA',
    latitude: '42.811261',
    longitude: '-70.877264',
    timeZoneId: 'America/New_York',
  },
  '01951': {
    zip: '01951',
    city: 'Newbury',
    state: 'MA',
    latitude: '42.789959',
    longitude: '-70.844019',
    timeZoneId: 'America/New_York',
  },
  '01952': {
    zip: '01952',
    city: 'Salisbury',
    state: 'MA',
    latitude: '42.84348',
    longitude: '-70.82058',
    timeZoneId: 'America/New_York',
  },
  '01960': {
    zip: '01960',
    city: 'Peabody',
    state: 'MA',
    latitude: '42.533241',
    longitude: '-70.972639',
    timeZoneId: 'America/New_York',
  },
  '01961': {
    zip: '01961',
    city: 'Peabody',
    state: 'MA',
    latitude: '42.534558',
    longitude: '-70.968238',
    timeZoneId: 'America/New_York',
  },
  '01965': {
    zip: '01965',
    city: 'Prides Crossing',
    state: 'MA',
    latitude: '42.561561',
    longitude: '-70.833711',
    timeZoneId: 'America/New_York',
  },
  '01966': {
    zip: '01966',
    city: 'Rockport',
    state: 'MA',
    latitude: '42.658742',
    longitude: '-70.618711',
    timeZoneId: 'America/New_York',
  },
  '01969': {
    zip: '01969',
    city: 'Rowley',
    state: 'MA',
    latitude: '42.721075',
    longitude: '-70.898492',
    timeZoneId: 'America/New_York',
  },
  '01970': {
    zip: '01970',
    city: 'Salem',
    state: 'MA',
    latitude: '42.518116',
    longitude: '-70.904469',
    timeZoneId: 'America/New_York',
  },
  '01971': {
    zip: '01971',
    city: 'Salem',
    state: 'MA',
    latitude: '42.527567',
    longitude: '-70.868387',
    timeZoneId: 'America/New_York',
  },
  '01982': {
    zip: '01982',
    city: 'South Hamilton',
    state: 'MA',
    latitude: '42.619543',
    longitude: '-70.86166',
    timeZoneId: 'America/New_York',
  },
  '01983': {
    zip: '01983',
    city: 'Topsfield',
    state: 'MA',
    latitude: '42.640415',
    longitude: '-70.947058',
    timeZoneId: 'America/New_York',
  },
  '01984': {
    zip: '01984',
    city: 'Wenham',
    state: 'MA',
    latitude: '42.603665',
    longitude: '-70.888664',
    timeZoneId: 'America/New_York',
  },
  '01985': {
    zip: '01985',
    city: 'West Newbury',
    state: 'MA',
    latitude: '42.790919',
    longitude: '-70.975482',
    timeZoneId: 'America/New_York',
  },
  '02018': {
    zip: '02018',
    city: 'Accord',
    state: 'MA',
    latitude: '42.175682',
    longitude: '-70.886913',
    timeZoneId: 'America/New_York',
  },
  '02019': {
    zip: '02019',
    city: 'Bellingham',
    state: 'MA',
    latitude: '42.068354',
    longitude: '-71.476042',
    timeZoneId: 'America/New_York',
  },
  '02020': {
    zip: '02020',
    city: 'Brant Rock',
    state: 'MA',
    latitude: '42.08398',
    longitude: '-70.649731',
    timeZoneId: 'America/New_York',
  },
  '02021': {
    zip: '02021',
    city: 'Canton',
    state: 'MA',
    latitude: '42.164447',
    longitude: '-71.131647',
    timeZoneId: 'America/New_York',
  },
  '02025': {
    zip: '02025',
    city: 'Cohasset',
    state: 'MA',
    latitude: '42.237751',
    longitude: '-70.809991',
    timeZoneId: 'America/New_York',
  },
  '02026': {
    zip: '02026',
    city: 'Dedham',
    state: 'MA',
    latitude: '42.244649',
    longitude: '-71.16764',
    timeZoneId: 'America/New_York',
  },
  '02027': {
    zip: '02027',
    city: 'Dedham',
    state: 'MA',
    latitude: '42.246639',
    longitude: '-71.179916',
    timeZoneId: 'America/New_York',
  },
  '02030': {
    zip: '02030',
    city: 'Dover',
    state: 'MA',
    latitude: '42.234095',
    longitude: '-71.28611',
    timeZoneId: 'America/New_York',
  },
  '02032': {
    zip: '02032',
    city: 'East Walpole',
    state: 'MA',
    latitude: '42.154795',
    longitude: '-71.216436',
    timeZoneId: 'America/New_York',
  },
  '02035': {
    zip: '02035',
    city: 'Foxboro',
    state: 'MA',
    latitude: '42.062193',
    longitude: '-71.243476',
    timeZoneId: 'America/New_York',
  },
  '02038': {
    zip: '02038',
    city: 'Franklin',
    state: 'MA',
    latitude: '42.093298',
    longitude: '-71.404394',
    timeZoneId: 'America/New_York',
  },
  '02040': {
    zip: '02040',
    city: 'Greenbush',
    state: 'MA',
    latitude: '42.179589',
    longitude: '-70.749679',
    timeZoneId: 'America/New_York',
  },
  '02041': {
    zip: '02041',
    city: 'Green Harbor',
    state: 'MA',
    latitude: '42.071576',
    longitude: '-70.65438',
    timeZoneId: 'America/New_York',
  },
  '02043': {
    zip: '02043',
    city: 'Hingham',
    state: 'MA',
    latitude: '42.227974',
    longitude: '-70.89115',
    timeZoneId: 'America/New_York',
  },
  '02044': {
    zip: '02044',
    city: 'Hingham',
    state: 'MA',
    latitude: '42.242484',
    longitude: '-70.892883',
    timeZoneId: 'America/New_York',
  },
  '02045': {
    zip: '02045',
    city: 'Hull',
    state: 'MA',
    latitude: '42.230785',
    longitude: '-70.839051',
    timeZoneId: 'America/New_York',
  },
  '02047': {
    zip: '02047',
    city: 'Humarock',
    state: 'MA',
    latitude: '42.13786',
    longitude: '-70.693847',
    timeZoneId: 'America/New_York',
  },
  '02048': {
    zip: '02048',
    city: 'Mansfield',
    state: 'MA',
    latitude: '42.023917',
    longitude: '-71.218787',
    timeZoneId: 'America/New_York',
  },
  '02050': {
    zip: '02050',
    city: 'Marshfield',
    state: 'MA',
    latitude: '42.126517',
    longitude: '-70.73631',
    timeZoneId: 'America/New_York',
  },
  '02051': {
    zip: '02051',
    city: 'Marshfield Hills',
    state: 'MA',
    latitude: '42.145186',
    longitude: '-70.72388',
    timeZoneId: 'America/New_York',
  },
  '02052': {
    zip: '02052',
    city: 'Medfield',
    state: 'MA',
    latitude: '42.177982',
    longitude: '-71.305897',
    timeZoneId: 'America/New_York',
  },
  '02053': {
    zip: '02053',
    city: 'Medway',
    state: 'MA',
    latitude: '42.145235',
    longitude: '-71.407864',
    timeZoneId: 'America/New_York',
  },
  '02054': {
    zip: '02054',
    city: 'Millis',
    state: 'MA',
    latitude: '42.167699',
    longitude: '-71.360144',
    timeZoneId: 'America/New_York',
  },
  '02055': {
    zip: '02055',
    city: 'Minot',
    state: 'MA',
    latitude: '42.238834',
    longitude: '-70.761576',
    timeZoneId: 'America/New_York',
  },
  '02056': {
    zip: '02056',
    city: 'Norfolk',
    state: 'MA',
    latitude: '42.118496',
    longitude: '-71.328676',
    timeZoneId: 'America/New_York',
  },
  '02059': {
    zip: '02059',
    city: 'North Marshfield',
    state: 'MA',
    latitude: '42.142821',
    longitude: '-70.772118',
    timeZoneId: 'America/New_York',
  },
  '02060': {
    zip: '02060',
    city: 'North Scituate',
    state: 'MA',
    latitude: '42.186869',
    longitude: '-71.204063',
    timeZoneId: 'America/New_York',
  },
  '02061': {
    zip: '02061',
    city: 'Norwell',
    state: 'MA',
    latitude: '42.156831',
    longitude: '-70.821267',
    timeZoneId: 'America/New_York',
  },
  '02062': {
    zip: '02062',
    city: 'Norwood',
    state: 'MA',
    latitude: '42.185459',
    longitude: '-71.202181',
    timeZoneId: 'America/New_York',
  },
  '02065': {
    zip: '02065',
    city: 'Ocean Bluff',
    state: 'MA',
    latitude: '42.091667',
    longitude: '-70.706111',
    timeZoneId: 'America/New_York',
  },
  '02066': {
    zip: '02066',
    city: 'Scituate',
    state: 'MA',
    latitude: '42.199513',
    longitude: '-70.746169',
    timeZoneId: 'America/New_York',
  },
  '02067': {
    zip: '02067',
    city: 'Sharon',
    state: 'MA',
    latitude: '42.111505',
    longitude: '-71.179831',
    timeZoneId: 'America/New_York',
  },
  '02070': {
    zip: '02070',
    city: 'Sheldonville',
    state: 'MA',
    latitude: '42.034805',
    longitude: '-71.386',
    timeZoneId: 'America/New_York',
  },
  '02071': {
    zip: '02071',
    city: 'South Walpole',
    state: 'MA',
    latitude: '42.104986',
    longitude: '-71.270463',
    timeZoneId: 'America/New_York',
  },
  '02072': {
    zip: '02072',
    city: 'Stoughton',
    state: 'MA',
    latitude: '42.124896',
    longitude: '-71.109196',
    timeZoneId: 'America/New_York',
  },
  '02081': {
    zip: '02081',
    city: 'Walpole',
    state: 'MA',
    latitude: '42.142932',
    longitude: '-71.25713',
    timeZoneId: 'America/New_York',
  },
  '02090': {
    zip: '02090',
    city: 'Westwood',
    state: 'MA',
    latitude: '42.216043',
    longitude: '-71.204408',
    timeZoneId: 'America/New_York',
  },
  '02093': {
    zip: '02093',
    city: 'Wrentham',
    state: 'MA',
    latitude: '42.057241',
    longitude: '-71.344361',
    timeZoneId: 'America/New_York',
  },
  '02108': {
    zip: '02108',
    city: 'Boston',
    state: 'MA',
    latitude: '42.358664',
    longitude: '-71.071293',
    timeZoneId: 'America/New_York',
  },
  '02109': {
    zip: '02109',
    city: 'Boston',
    state: 'MA',
    latitude: '42.36796',
    longitude: '-71.05478',
    timeZoneId: 'America/New_York',
  },
  '02110': {
    zip: '02110',
    city: 'Boston',
    state: 'MA',
    latitude: '42.35819',
    longitude: '-71.050678',
    timeZoneId: 'America/New_York',
  },
  '02111': {
    zip: '02111',
    city: 'Boston',
    state: 'MA',
    latitude: '42.351267',
    longitude: '-71.064699',
    timeZoneId: 'America/New_York',
  },
  '02112': {
    zip: '02112',
    city: 'Boston',
    state: 'MA',
    latitude: '42.35768',
    longitude: '-71.05763',
    timeZoneId: 'America/New_York',
  },
  '02113': {
    zip: '02113',
    city: 'Boston',
    state: 'MA',
    latitude: '42.365976',
    longitude: '-71.05308',
    timeZoneId: 'America/New_York',
  },
  '02114': {
    zip: '02114',
    city: 'Boston',
    state: 'MA',
    latitude: '42.360587',
    longitude: '-71.065447',
    timeZoneId: 'America/New_York',
  },
  '02115': {
    zip: '02115',
    city: 'Boston',
    state: 'MA',
    latitude: '42.341535',
    longitude: '-71.094458',
    timeZoneId: 'America/New_York',
  },
  '02116': {
    zip: '02116',
    city: 'Boston',
    state: 'MA',
    latitude: '42.347026',
    longitude: '-71.074793',
    timeZoneId: 'America/New_York',
  },
  '02117': {
    zip: '02117',
    city: 'Boston',
    state: 'MA',
    latitude: '42.342166',
    longitude: '-71.073298',
    timeZoneId: 'America/New_York',
  },
  '02118': {
    zip: '02118',
    city: 'Boston',
    state: 'MA',
    latitude: '42.33696',
    longitude: '-71.06376',
    timeZoneId: 'America/New_York',
  },
  '02119': {
    zip: '02119',
    city: 'Roxbury',
    state: 'MA',
    latitude: '42.28824',
    longitude: '-71.04744',
    timeZoneId: 'America/New_York',
  },
  '02120': {
    zip: '02120',
    city: 'Roxbury Crossing',
    state: 'MA',
    latitude: '42.334141',
    longitude: '-71.097433',
    timeZoneId: 'America/New_York',
  },
  '02121': {
    zip: '02121',
    city: 'Dorchester',
    state: 'MA',
    latitude: '42.306015',
    longitude: '-71.084811',
    timeZoneId: 'America/New_York',
  },
  '02122': {
    zip: '02122',
    city: 'Dorchester',
    state: 'MA',
    latitude: '42.297038',
    longitude: '-71.054735',
    timeZoneId: 'America/New_York',
  },
  '02123': {
    zip: '02123',
    city: 'Boston',
    state: 'MA',
    latitude: '42.34394',
    longitude: '-71.085266',
    timeZoneId: 'America/New_York',
  },
  '02124': {
    zip: '02124',
    city: 'Dorchester Center',
    state: 'MA',
    latitude: '42.286139',
    longitude: '-71.070555',
    timeZoneId: 'America/New_York',
  },
  '02125': {
    zip: '02125',
    city: 'Dorchester',
    state: 'MA',
    latitude: '42.313703',
    longitude: '-71.062976',
    timeZoneId: 'America/New_York',
  },
  '02126': {
    zip: '02126',
    city: 'Mattapan',
    state: 'MA',
    latitude: '42.27469',
    longitude: '-71.089937',
    timeZoneId: 'America/New_York',
  },
  '02127': {
    zip: '02127',
    city: 'South Boston',
    state: 'MA',
    latitude: '42.33896',
    longitude: '-71.02529',
    timeZoneId: 'America/New_York',
  },
  '02128': {
    zip: '02128',
    city: 'East Boston',
    state: 'MA',
    latitude: '42.383079',
    longitude: '-71.018049',
    timeZoneId: 'America/New_York',
  },
  '02129': {
    zip: '02129',
    city: 'Charlestown',
    state: 'MA',
    latitude: '42.33618',
    longitude: '-71.10366',
    timeZoneId: 'America/New_York',
  },
  '02130': {
    zip: '02130',
    city: 'Jamaica Plain',
    state: 'MA',
    latitude: '42.29472',
    longitude: '-71.13054',
    timeZoneId: 'America/New_York',
  },
  '02131': {
    zip: '02131',
    city: 'Roslindale',
    state: 'MA',
    latitude: '42.28473',
    longitude: '-71.127697',
    timeZoneId: 'America/New_York',
  },
  '02132': {
    zip: '02132',
    city: 'West Roxbury',
    state: 'MA',
    latitude: '42.27516',
    longitude: '-71.14728',
    timeZoneId: 'America/New_York',
  },
  '02133': {
    zip: '02133',
    city: 'Boston',
    state: 'MA',
    latitude: '42.354273',
    longitude: '-71.065367',
    timeZoneId: 'America/New_York',
  },
  '02134': {
    zip: '02134',
    city: 'Allston',
    state: 'MA',
    latitude: '42.358096',
    longitude: '-71.130571',
    timeZoneId: 'America/New_York',
  },
  '02135': {
    zip: '02135',
    city: 'Brighton',
    state: 'MA',
    latitude: '42.35082',
    longitude: '-71.11842',
    timeZoneId: 'America/New_York',
  },
  '02136': {
    zip: '02136',
    city: 'Hyde Park',
    state: 'MA',
    latitude: '42.25281',
    longitude: '-71.129122',
    timeZoneId: 'America/New_York',
  },
  '02137': {
    zip: '02137',
    city: 'Readville',
    state: 'MA',
    latitude: '42.235076',
    longitude: '-71.134469',
    timeZoneId: 'America/New_York',
  },
  '02138': {
    zip: '02138',
    city: 'Cambridge',
    state: 'MA',
    latitude: '42.377465',
    longitude: '-71.131249',
    timeZoneId: 'America/New_York',
  },
  '02139': {
    zip: '02139',
    city: 'Cambridge',
    state: 'MA',
    latitude: '42.366036',
    longitude: '-71.103492',
    timeZoneId: 'America/New_York',
  },
  '02140': {
    zip: '02140',
    city: 'Cambridge',
    state: 'MA',
    latitude: '42.39235',
    longitude: '-71.131259',
    timeZoneId: 'America/New_York',
  },
  '02141': {
    zip: '02141',
    city: 'Cambridge',
    state: 'MA',
    latitude: '42.368312',
    longitude: '-71.082436',
    timeZoneId: 'America/New_York',
  },
  '02142': {
    zip: '02142',
    city: 'Cambridge',
    state: 'MA',
    latitude: '42.366561',
    longitude: '-71.087556',
    timeZoneId: 'America/New_York',
  },
  '02143': {
    zip: '02143',
    city: 'Somerville',
    state: 'MA',
    latitude: '42.381835',
    longitude: '-71.103174',
    timeZoneId: 'America/New_York',
  },
  '02144': {
    zip: '02144',
    city: 'Somerville',
    state: 'MA',
    latitude: '42.399028',
    longitude: '-71.123138',
    timeZoneId: 'America/New_York',
  },
  '02145': {
    zip: '02145',
    city: 'Somerville',
    state: 'MA',
    latitude: '42.391409',
    longitude: '-71.094721',
    timeZoneId: 'America/New_York',
  },
  '02148': {
    zip: '02148',
    city: 'Malden',
    state: 'MA',
    latitude: '42.43213',
    longitude: '-71.056084',
    timeZoneId: 'America/New_York',
  },
  '02149': {
    zip: '02149',
    city: 'Everett',
    state: 'MA',
    latitude: '42.411501',
    longitude: '-71.052188',
    timeZoneId: 'America/New_York',
  },
  '02150': {
    zip: '02150',
    city: 'Chelsea',
    state: 'MA',
    latitude: '42.397479',
    longitude: '-71.035464',
    timeZoneId: 'America/New_York',
  },
  '02151': {
    zip: '02151',
    city: 'Revere',
    state: 'MA',
    latitude: '42.415223',
    longitude: '-71.005325',
    timeZoneId: 'America/New_York',
  },
  '02152': {
    zip: '02152',
    city: 'Winthrop',
    state: 'MA',
    latitude: '42.37376',
    longitude: '-70.980521',
    timeZoneId: 'America/New_York',
  },
  '02153': {
    zip: '02153',
    city: 'Medford',
    state: 'MA',
    latitude: '42.419023',
    longitude: '-71.109862',
    timeZoneId: 'America/New_York',
  },
  '02155': {
    zip: '02155',
    city: 'Medford',
    state: 'MA',
    latitude: '42.42132',
    longitude: '-71.113443',
    timeZoneId: 'America/New_York',
  },
  '02156': {
    zip: '02156',
    city: 'West Medford',
    state: 'MA',
    latitude: '42.424333',
    longitude: '-71.132274',
    timeZoneId: 'America/New_York',
  },
  '02163': {
    zip: '02163',
    city: 'Boston',
    state: 'MA',
    latitude: '42.369472',
    longitude: '-71.124373',
    timeZoneId: 'America/New_York',
  },
  '02169': {
    zip: '02169',
    city: 'Quincy',
    state: 'MA',
    latitude: '42.240464',
    longitude: '-71.014144',
    timeZoneId: 'America/New_York',
  },
  '02170': {
    zip: '02170',
    city: 'Quincy',
    state: 'MA',
    latitude: '42.268871',
    longitude: '-71.02006',
    timeZoneId: 'America/New_York',
  },
  '02171': {
    zip: '02171',
    city: 'Quincy',
    state: 'MA',
    latitude: '42.285206',
    longitude: '-71.025251',
    timeZoneId: 'America/New_York',
  },
  '02176': {
    zip: '02176',
    city: 'Melrose',
    state: 'MA',
    latitude: '42.459952',
    longitude: '-71.05889',
    timeZoneId: 'America/New_York',
  },
  '02180': {
    zip: '02180',
    city: 'Stoneham',
    state: 'MA',
    latitude: '42.482637',
    longitude: '-71.096392',
    timeZoneId: 'America/New_York',
  },
  '02184': {
    zip: '02184',
    city: 'Braintree',
    state: 'MA',
    latitude: '42.208818',
    longitude: '-71.000729',
    timeZoneId: 'America/New_York',
  },
  '02185': {
    zip: '02185',
    city: 'Braintree',
    state: 'MA',
    latitude: '42.201183',
    longitude: '-71.005109',
    timeZoneId: 'America/New_York',
  },
  '02186': {
    zip: '02186',
    city: 'Milton',
    state: 'MA',
    latitude: '42.249317',
    longitude: '-71.074516',
    timeZoneId: 'America/New_York',
  },
  '02187': {
    zip: '02187',
    city: 'Milton Village',
    state: 'MA',
    latitude: '42.260688',
    longitude: '-71.078672',
    timeZoneId: 'America/New_York',
  },
  '02188': {
    zip: '02188',
    city: 'Weymouth',
    state: 'MA',
    latitude: '42.213792',
    longitude: '-70.959371',
    timeZoneId: 'America/New_York',
  },
  '02189': {
    zip: '02189',
    city: 'East Weymouth',
    state: 'MA',
    latitude: '42.213831',
    longitude: '-70.932791',
    timeZoneId: 'America/New_York',
  },
  '02190': {
    zip: '02190',
    city: 'South Weymouth',
    state: 'MA',
    latitude: '42.171028',
    longitude: '-70.948119',
    timeZoneId: 'America/New_York',
  },
  '02191': {
    zip: '02191',
    city: 'North Weymouth',
    state: 'MA',
    latitude: '42.241032',
    longitude: '-70.94326',
    timeZoneId: 'America/New_York',
  },
  '02196': {
    zip: '02196',
    city: 'Boston',
    state: 'MA',
    latitude: '42.3513',
    longitude: '-71.060861',
    timeZoneId: 'America/New_York',
  },
  '02199': {
    zip: '02199',
    city: 'Boston',
    state: 'MA',
    latitude: '42.345258',
    longitude: '-71.078871',
    timeZoneId: 'America/New_York',
  },
  '02201': {
    zip: '02201',
    city: 'Boston',
    state: 'MA',
    latitude: '42.359359',
    longitude: '-71.059528',
    timeZoneId: 'America/New_York',
  },
  '02203': {
    zip: '02203',
    city: 'Boston',
    state: 'MA',
    latitude: '42.365142',
    longitude: '-71.070244',
    timeZoneId: 'America/New_York',
  },
  '02204': {
    zip: '02204',
    city: 'Boston',
    state: 'MA',
    latitude: '42.39503',
    longitude: '-71.05027',
    timeZoneId: 'America/New_York',
  },
  '02205': {
    zip: '02205',
    city: 'Boston',
    state: 'MA',
    latitude: '42.34875',
    longitude: '-71.05694',
    timeZoneId: 'America/New_York',
  },
  '02206': {
    zip: '02206',
    city: 'Boston',
    state: 'MA',
    latitude: '42.34445',
    longitude: '-71.042166',
    timeZoneId: 'America/New_York',
  },
  '02210': {
    zip: '02210',
    city: 'Boston',
    state: 'MA',
    latitude: '42.345728',
    longitude: '-71.042554',
    timeZoneId: 'America/New_York',
  },
  '02211': {
    zip: '02211',
    city: 'Boston',
    state: 'MA',
    latitude: '42.358069',
    longitude: '-71.056709',
    timeZoneId: 'America/New_York',
  },
  '02212': {
    zip: '02212',
    city: 'Boston',
    state: 'MA',
    latitude: '42.355722',
    longitude: '-71.055772',
    timeZoneId: 'America/New_York',
  },
  '02215': {
    zip: '02215',
    city: 'Boston',
    state: 'MA',
    latitude: '42.344927',
    longitude: '-71.101619',
    timeZoneId: 'America/New_York',
  },
  '02217': {
    zip: '02217',
    city: 'Boston',
    state: 'MA',
    latitude: '42.348526',
    longitude: '-71.072994',
    timeZoneId: 'America/New_York',
  },
  '02222': {
    zip: '02222',
    city: 'Boston',
    state: 'MA',
    latitude: '42.362372',
    longitude: '-71.066194',
    timeZoneId: 'America/New_York',
  },
  '02238': {
    zip: '02238',
    city: 'Cambridge',
    state: 'MA',
    latitude: '42.374067',
    longitude: '-71.117136',
    timeZoneId: 'America/New_York',
  },
  '02241': {
    zip: '02241',
    city: 'Boston',
    state: 'MA',
    latitude: '42.354861',
    longitude: '-71.057266',
    timeZoneId: 'America/New_York',
  },
  '02266': {
    zip: '02266',
    city: 'Boston',
    state: 'MA',
    latitude: '42.349321',
    longitude: '-71.059883',
    timeZoneId: 'America/New_York',
  },
  '02269': {
    zip: '02269',
    city: 'Quincy',
    state: 'MA',
    latitude: '42.251391',
    longitude: '-71.003943',
    timeZoneId: 'America/New_York',
  },
  '02283': {
    zip: '02283',
    city: 'Boston',
    state: 'MA',
    latitude: '42.348748',
    longitude: '-71.056723',
    timeZoneId: 'America/New_York',
  },
  '02284': {
    zip: '02284',
    city: 'Boston',
    state: 'MA',
    latitude: '42.351144',
    longitude: '-71.057065',
    timeZoneId: 'America/New_York',
  },
  '02293': {
    zip: '02293',
    city: 'Boston',
    state: 'MA',
    latitude: '42.358203',
    longitude: '-71.061092',
    timeZoneId: 'America/New_York',
  },
  '02297': {
    zip: '02297',
    city: 'Boston',
    state: 'MA',
    latitude: '42.358631',
    longitude: '-71.056702',
    timeZoneId: 'America/New_York',
  },
  '02298': {
    zip: '02298',
    city: 'Boston',
    state: 'MA',
    latitude: '42.358631',
    longitude: '-71.056702',
    timeZoneId: 'America/New_York',
  },
  '02301': {
    zip: '02301',
    city: 'Brockton',
    state: 'MA',
    latitude: '42.090314',
    longitude: '-71.038674',
    timeZoneId: 'America/New_York',
  },
  '02302': {
    zip: '02302',
    city: 'Brockton',
    state: 'MA',
    latitude: '42.083088',
    longitude: '-70.999902',
    timeZoneId: 'America/New_York',
  },
  '02303': {
    zip: '02303',
    city: 'Brockton',
    state: 'MA',
    latitude: '42.084757',
    longitude: '-71.015162',
    timeZoneId: 'America/New_York',
  },
  '02304': {
    zip: '02304',
    city: 'Brockton',
    state: 'MA',
    latitude: '42.081667',
    longitude: '-71.025966',
    timeZoneId: 'America/New_York',
  },
  '02305': {
    zip: '02305',
    city: 'Brockton',
    state: 'MA',
    latitude: '42.08318',
    longitude: '-71.024619',
    timeZoneId: 'America/New_York',
  },
  '02322': {
    zip: '02322',
    city: 'Avon',
    state: 'MA',
    latitude: '42.122045',
    longitude: '-71.048657',
    timeZoneId: 'America/New_York',
  },
  '02324': {
    zip: '02324',
    city: 'Bridgewater',
    state: 'MA',
    latitude: '41.981411',
    longitude: '-70.974124',
    timeZoneId: 'America/New_York',
  },
  '02325': {
    zip: '02325',
    city: 'Bridgewater',
    state: 'MA',
    latitude: '41.992179',
    longitude: '-70.972402',
    timeZoneId: 'America/New_York',
  },
  '02327': {
    zip: '02327',
    city: 'Bryantville',
    state: 'MA',
    latitude: '42.044742',
    longitude: '-70.836344',
    timeZoneId: 'America/New_York',
  },
  '02330': {
    zip: '02330',
    city: 'Carver',
    state: 'MA',
    latitude: '41.880517',
    longitude: '-70.767478',
    timeZoneId: 'America/New_York',
  },
  '02331': {
    zip: '02331',
    city: 'Duxbury',
    state: 'MA',
    latitude: '42.031844',
    longitude: '-70.694226',
    timeZoneId: 'America/New_York',
  },
  '02332': {
    zip: '02332',
    city: 'Duxbury',
    state: 'MA',
    latitude: '42.037692',
    longitude: '-70.710603',
    timeZoneId: 'America/New_York',
  },
  '02333': {
    zip: '02333',
    city: 'East Bridgewater',
    state: 'MA',
    latitude: '42.029586',
    longitude: '-70.942499',
    timeZoneId: 'America/New_York',
  },
  '02334': {
    zip: '02334',
    city: 'Easton',
    state: 'MA',
    latitude: '42.023847',
    longitude: '-71.129217',
    timeZoneId: 'America/New_York',
  },
  '02337': {
    zip: '02337',
    city: 'Elmwood',
    state: 'MA',
    latitude: '42.011834',
    longitude: '-70.964024',
    timeZoneId: 'America/New_York',
  },
  '02338': {
    zip: '02338',
    city: 'Halifax',
    state: 'MA',
    latitude: '42.001029',
    longitude: '-70.851712',
    timeZoneId: 'America/New_York',
  },
  '02339': {
    zip: '02339',
    city: 'Hanover',
    state: 'MA',
    latitude: '42.130281',
    longitude: '-70.846944',
    timeZoneId: 'America/New_York',
  },
  '02341': {
    zip: '02341',
    city: 'Hanson',
    state: 'MA',
    latitude: '42.055954',
    longitude: '-70.868035',
    timeZoneId: 'America/New_York',
  },
  '02343': {
    zip: '02343',
    city: 'Holbrook',
    state: 'MA',
    latitude: '42.14803',
    longitude: '-71.010637',
    timeZoneId: 'America/New_York',
  },
  '02344': {
    zip: '02344',
    city: 'Middleboro',
    state: 'MA',
    latitude: '41.894218',
    longitude: '-70.920815',
    timeZoneId: 'America/New_York',
  },
  '02345': {
    zip: '02345',
    city: 'Manomet',
    state: 'MA',
    latitude: '41.903376',
    longitude: '-70.552785',
    timeZoneId: 'America/New_York',
  },
  '02346': {
    zip: '02346',
    city: 'Middleboro',
    state: 'MA',
    latitude: '41.887265',
    longitude: '-70.887189',
    timeZoneId: 'America/New_York',
  },
  '02347': {
    zip: '02347',
    city: 'Lakeville',
    state: 'MA',
    latitude: '41.831559',
    longitude: '-70.953643',
    timeZoneId: 'America/New_York',
  },
  '02348': {
    zip: '02348',
    city: 'Lakeville',
    state: 'MA',
    latitude: '41.877314',
    longitude: '-70.946435',
    timeZoneId: 'America/New_York',
  },
  '02349': {
    zip: '02349',
    city: 'Middleboro',
    state: 'MA',
    latitude: '41.870977',
    longitude: '-70.90838',
    timeZoneId: 'America/New_York',
  },
  '02350': {
    zip: '02350',
    city: 'Monponsett',
    state: 'MA',
    latitude: '42.01687',
    longitude: '-70.848897',
    timeZoneId: 'America/New_York',
  },
  '02351': {
    zip: '02351',
    city: 'Abington',
    state: 'MA',
    latitude: '42.117783',
    longitude: '-70.954203',
    timeZoneId: 'America/New_York',
  },
  '02355': {
    zip: '02355',
    city: 'North Carver',
    state: 'MA',
    latitude: '41.917753',
    longitude: '-70.797313',
    timeZoneId: 'America/New_York',
  },
  '02356': {
    zip: '02356',
    city: 'North Easton',
    state: 'MA',
    latitude: '42.057491',
    longitude: '-71.115498',
    timeZoneId: 'America/New_York',
  },
  '02357': {
    zip: '02357',
    city: 'North Easton',
    state: 'MA',
    latitude: '42.059436',
    longitude: '-71.080861',
    timeZoneId: 'America/New_York',
  },
  '02358': {
    zip: '02358',
    city: 'North Pembroke',
    state: 'MA',
    latitude: '42.09441',
    longitude: '-70.787885',
    timeZoneId: 'America/New_York',
  },
  '02359': {
    zip: '02359',
    city: 'Pembroke',
    state: 'MA',
    latitude: '42.063591',
    longitude: '-70.805297',
    timeZoneId: 'America/New_York',
  },
  '02360': {
    zip: '02360',
    city: 'Plymouth',
    state: 'MA',
    latitude: '41.87292',
    longitude: '-70.64142',
    timeZoneId: 'America/New_York',
  },
  '02361': {
    zip: '02361',
    city: 'Plymouth',
    state: 'MA',
    latitude: '41.923344',
    longitude: '-70.655618',
    timeZoneId: 'America/New_York',
  },
  '02362': {
    zip: '02362',
    city: 'Plymouth',
    state: 'MA',
    latitude: '41.959767',
    longitude: '-70.665275',
    timeZoneId: 'America/New_York',
  },
  '02364': {
    zip: '02364',
    city: 'Kingston',
    state: 'MA',
    latitude: '41.97954',
    longitude: '-70.7451',
    timeZoneId: 'America/New_York',
  },
  '02366': {
    zip: '02366',
    city: 'South Carver',
    state: 'MA',
    latitude: '41.840594',
    longitude: '-70.744534',
    timeZoneId: 'America/New_York',
  },
  '02367': {
    zip: '02367',
    city: 'Plympton',
    state: 'MA',
    latitude: '41.955248',
    longitude: '-70.806097',
    timeZoneId: 'America/New_York',
  },
  '02368': {
    zip: '02368',
    city: 'Randolph',
    state: 'MA',
    latitude: '42.173512',
    longitude: '-71.050595',
    timeZoneId: 'America/New_York',
  },
  '02370': {
    zip: '02370',
    city: 'Rockland',
    state: 'MA',
    latitude: '42.131755',
    longitude: '-70.907783',
    timeZoneId: 'America/New_York',
  },
  '02375': {
    zip: '02375',
    city: 'South Easton',
    state: 'MA',
    latitude: '42.028515',
    longitude: '-71.10088',
    timeZoneId: 'America/New_York',
  },
  '02379': {
    zip: '02379',
    city: 'West Bridgewater',
    state: 'MA',
    latitude: '42.018468',
    longitude: '-71.013855',
    timeZoneId: 'America/New_York',
  },
  '02381': {
    zip: '02381',
    city: 'White Horse Beach',
    state: 'MA',
    latitude: '41.930035',
    longitude: '-70.559004',
    timeZoneId: 'America/New_York',
  },
  '02382': {
    zip: '02382',
    city: 'Whitman',
    state: 'MA',
    latitude: '42.081994',
    longitude: '-70.937413',
    timeZoneId: 'America/New_York',
  },
  '02420': {
    zip: '02420',
    city: 'Lexington',
    state: 'MA',
    latitude: '42.455799',
    longitude: '-71.215027',
    timeZoneId: 'America/New_York',
  },
  '02421': {
    zip: '02421',
    city: 'Lexington',
    state: 'MA',
    latitude: '42.448372',
    longitude: '-71.233754',
    timeZoneId: 'America/New_York',
  },
  '02445': {
    zip: '02445',
    city: 'Brookline',
    state: 'MA',
    latitude: '42.332533',
    longitude: '-71.129865',
    timeZoneId: 'America/New_York',
  },
  '02446': {
    zip: '02446',
    city: 'Brookline',
    state: 'MA',
    latitude: '42.344328',
    longitude: '-71.122217',
    timeZoneId: 'America/New_York',
  },
  '02447': {
    zip: '02447',
    city: 'Brookline Village',
    state: 'MA',
    latitude: '42.333546',
    longitude: '-71.118745',
    timeZoneId: 'America/New_York',
  },
  '02451': {
    zip: '02451',
    city: 'Waltham',
    state: 'MA',
    latitude: '42.399203',
    longitude: '-71.26249',
    timeZoneId: 'America/New_York',
  },
  '02452': {
    zip: '02452',
    city: 'Waltham',
    state: 'MA',
    latitude: '42.39802',
    longitude: '-71.220337',
    timeZoneId: 'America/New_York',
  },
  '02453': {
    zip: '02453',
    city: 'Waltham',
    state: 'MA',
    latitude: '42.369917',
    longitude: '-71.236818',
    timeZoneId: 'America/New_York',
  },
  '02454': {
    zip: '02454',
    city: 'Waltham',
    state: 'MA',
    latitude: '42.378002',
    longitude: '-71.239881',
    timeZoneId: 'America/New_York',
  },
  '02455': {
    zip: '02455',
    city: 'North Waltham',
    state: 'MA',
    latitude: '42.357184',
    longitude: '-71.059255',
    timeZoneId: 'America/New_York',
  },
  '02456': {
    zip: '02456',
    city: 'New Town',
    state: 'MA',
    latitude: '42.333573',
    longitude: '-71.204106',
    timeZoneId: 'America/New_York',
  },
  '02457': {
    zip: '02457',
    city: 'Babson Park',
    state: 'MA',
    latitude: '42.29704',
    longitude: '-71.259784',
    timeZoneId: 'America/New_York',
  },
  '02458': {
    zip: '02458',
    city: 'Newton',
    state: 'MA',
    latitude: '42.352515',
    longitude: '-71.190702',
    timeZoneId: 'America/New_York',
  },
  '02459': {
    zip: '02459',
    city: 'Newton Center',
    state: 'MA',
    latitude: '42.308823',
    longitude: '-71.191835',
    timeZoneId: 'America/New_York',
  },
  '02460': {
    zip: '02460',
    city: 'Newtonville',
    state: 'MA',
    latitude: '42.351054',
    longitude: '-71.207393',
    timeZoneId: 'America/New_York',
  },
  '02461': {
    zip: '02461',
    city: 'Newton Highlands',
    state: 'MA',
    latitude: '42.314739',
    longitude: '-71.208012',
    timeZoneId: 'America/New_York',
  },
  '02462': {
    zip: '02462',
    city: 'Newton Lower Falls',
    state: 'MA',
    latitude: '42.3312',
    longitude: '-71.258208',
    timeZoneId: 'America/New_York',
  },
  '02464': {
    zip: '02464',
    city: 'Newton Upper Falls',
    state: 'MA',
    latitude: '42.311972',
    longitude: '-71.220047',
    timeZoneId: 'America/New_York',
  },
  '02465': {
    zip: '02465',
    city: 'West Newton',
    state: 'MA',
    latitude: '42.348882',
    longitude: '-71.225607',
    timeZoneId: 'America/New_York',
  },
  '02466': {
    zip: '02466',
    city: 'Auburndale',
    state: 'MA',
    latitude: '42.345147',
    longitude: '-71.247889',
    timeZoneId: 'America/New_York',
  },
  '02467': {
    zip: '02467',
    city: 'Chestnut Hill',
    state: 'MA',
    latitude: '42.318527',
    longitude: '-71.157052',
    timeZoneId: 'America/New_York',
  },
  '02468': {
    zip: '02468',
    city: 'Waban',
    state: 'MA',
    latitude: '42.326111',
    longitude: '-71.231512',
    timeZoneId: 'America/New_York',
  },
  '02471': {
    zip: '02471',
    city: 'Watertown',
    state: 'MA',
    latitude: '42.369843',
    longitude: '-71.174994',
    timeZoneId: 'America/New_York',
  },
  '02472': {
    zip: '02472',
    city: 'Watertown',
    state: 'MA',
    latitude: '42.371297',
    longitude: '-71.180361',
    timeZoneId: 'America/New_York',
  },
  '02474': {
    zip: '02474',
    city: 'Arlington',
    state: 'MA',
    latitude: '42.420728',
    longitude: '-71.158189',
    timeZoneId: 'America/New_York',
  },
  '02475': {
    zip: '02475',
    city: 'Arlington Heights',
    state: 'MA',
    latitude: '42.420254',
    longitude: '-71.180935',
    timeZoneId: 'America/New_York',
  },
  '02476': {
    zip: '02476',
    city: 'Arlington',
    state: 'MA',
    latitude: '42.411936',
    longitude: '-71.176546',
    timeZoneId: 'America/New_York',
  },
  '02477': {
    zip: '02477',
    city: 'Watertown',
    state: 'MA',
    latitude: '42.377202',
    longitude: '-71.15371',
    timeZoneId: 'America/New_York',
  },
  '02478': {
    zip: '02478',
    city: 'Belmont',
    state: 'MA',
    latitude: '42.394199',
    longitude: '-71.180235',
    timeZoneId: 'America/New_York',
  },
  '02479': {
    zip: '02479',
    city: 'Waverley',
    state: 'MA',
    latitude: '42.388983',
    longitude: '-71.187758',
    timeZoneId: 'America/New_York',
  },
  '02481': {
    zip: '02481',
    city: 'Wellesley Hills',
    state: 'MA',
    latitude: '42.316214',
    longitude: '-71.265402',
    timeZoneId: 'America/New_York',
  },
  '02482': {
    zip: '02482',
    city: 'Wellesley',
    state: 'MA',
    latitude: '42.291931',
    longitude: '-71.298538',
    timeZoneId: 'America/New_York',
  },
  '02492': {
    zip: '02492',
    city: 'Needham',
    state: 'MA',
    latitude: '42.276004',
    longitude: '-71.243064',
    timeZoneId: 'America/New_York',
  },
  '02493': {
    zip: '02493',
    city: 'Weston',
    state: 'MA',
    latitude: '42.365885',
    longitude: '-71.301028',
    timeZoneId: 'America/New_York',
  },
  '02494': {
    zip: '02494',
    city: 'Needham Heights',
    state: 'MA',
    latitude: '42.305022',
    longitude: '-71.231767',
    timeZoneId: 'America/New_York',
  },
  '02495': {
    zip: '02495',
    city: 'Nonantum',
    state: 'MA',
    latitude: '42.332052',
    longitude: '-71.207484',
    timeZoneId: 'America/New_York',
  },
  '02532': {
    zip: '02532',
    city: 'Buzzards Bay',
    state: 'MA',
    latitude: '41.74104',
    longitude: '-70.58892',
    timeZoneId: 'America/New_York',
  },
  '02534': {
    zip: '02534',
    city: 'Cataumet',
    state: 'MA',
    latitude: '41.666022',
    longitude: '-70.61372',
    timeZoneId: 'America/New_York',
  },
  '02535': {
    zip: '02535',
    city: 'Chilmark',
    state: 'MA',
    latitude: '41.334586',
    longitude: '-70.746397',
    timeZoneId: 'America/New_York',
  },
  '02536': {
    zip: '02536',
    city: 'East Falmouth',
    state: 'MA',
    latitude: '41.583018',
    longitude: '-70.565899',
    timeZoneId: 'America/New_York',
  },
  '02537': {
    zip: '02537',
    city: 'East Sandwich',
    state: 'MA',
    latitude: '41.726548',
    longitude: '-70.440357',
    timeZoneId: 'America/New_York',
  },
  '02538': {
    zip: '02538',
    city: 'East Wareham',
    state: 'MA',
    latitude: '41.781003',
    longitude: '-70.649757',
    timeZoneId: 'America/New_York',
  },
  '02539': {
    zip: '02539',
    city: 'Edgartown',
    state: 'MA',
    latitude: '41.391367',
    longitude: '-70.529036',
    timeZoneId: 'America/New_York',
  },
  '02540': {
    zip: '02540',
    city: 'Falmouth',
    state: 'MA',
    latitude: '41.563155',
    longitude: '-70.626053',
    timeZoneId: 'America/New_York',
  },
  '02541': {
    zip: '02541',
    city: 'Falmouth',
    state: 'MA',
    latitude: '41.557159',
    longitude: '-70.632387',
    timeZoneId: 'America/New_York',
  },
  '02542': {
    zip: '02542',
    city: 'Buzzards Bay',
    state: 'MA',
    latitude: '41.65872',
    longitude: '-70.56726',
    timeZoneId: 'America/New_York',
  },
  '02543': {
    zip: '02543',
    city: 'Woods Hole',
    state: 'MA',
    latitude: '41.526561',
    longitude: '-70.667528',
    timeZoneId: 'America/New_York',
  },
  '02552': {
    zip: '02552',
    city: 'Menemsha',
    state: 'MA',
    latitude: '41.356664',
    longitude: '-70.74092',
    timeZoneId: 'America/New_York',
  },
  '02553': {
    zip: '02553',
    city: 'Monument Beach',
    state: 'MA',
    latitude: '41.717679',
    longitude: '-70.614258',
    timeZoneId: 'America/New_York',
  },
  '02554': {
    zip: '02554',
    city: 'Nantucket',
    state: 'MA',
    latitude: '41.275552',
    longitude: '-70.091002',
    timeZoneId: 'America/New_York',
  },
  '02556': {
    zip: '02556',
    city: 'North Falmouth',
    state: 'MA',
    latitude: '41.64996',
    longitude: '-70.624919',
    timeZoneId: 'America/New_York',
  },
  '02557': {
    zip: '02557',
    city: 'Oak Bluffs',
    state: 'MA',
    latitude: '41.453677',
    longitude: '-70.56336',
    timeZoneId: 'America/New_York',
  },
  '02558': {
    zip: '02558',
    city: 'Onset',
    state: 'MA',
    latitude: '41.747314',
    longitude: '-70.660179',
    timeZoneId: 'America/New_York',
  },
  '02559': {
    zip: '02559',
    city: 'Pocasset',
    state: 'MA',
    latitude: '41.687784',
    longitude: '-70.615331',
    timeZoneId: 'America/New_York',
  },
  '02561': {
    zip: '02561',
    city: 'Sagamore',
    state: 'MA',
    latitude: '41.771196',
    longitude: '-70.539161',
    timeZoneId: 'America/New_York',
  },
  '02562': {
    zip: '02562',
    city: 'Sagamore Beach',
    state: 'MA',
    latitude: '41.794946',
    longitude: '-70.527022',
    timeZoneId: 'America/New_York',
  },
  '02563': {
    zip: '02563',
    city: 'Sandwich',
    state: 'MA',
    latitude: '41.726496',
    longitude: '-70.48195',
    timeZoneId: 'America/New_York',
  },
  '02564': {
    zip: '02564',
    city: 'Siasconset',
    state: 'MA',
    latitude: '41.283333',
    longitude: '-70.1',
    timeZoneId: 'America/New_York',
  },
  '02568': {
    zip: '02568',
    city: 'Vineyard Haven',
    state: 'MA',
    latitude: '41.45352',
    longitude: '-70.5894',
    timeZoneId: 'America/New_York',
  },
  '02571': {
    zip: '02571',
    city: 'Wareham',
    state: 'MA',
    latitude: '41.748569',
    longitude: '-70.710818',
    timeZoneId: 'America/New_York',
  },
  '02574': {
    zip: '02574',
    city: 'West Falmouth',
    state: 'MA',
    latitude: '41.60136',
    longitude: '-70.639607',
    timeZoneId: 'America/New_York',
  },
  '02575': {
    zip: '02575',
    city: 'West Tisbury',
    state: 'MA',
    latitude: '41.402414',
    longitude: '-70.630193',
    timeZoneId: 'America/New_York',
  },
  '02576': {
    zip: '02576',
    city: 'West Wareham',
    state: 'MA',
    latitude: '41.772096',
    longitude: '-70.760191',
    timeZoneId: 'America/New_York',
  },
  '02584': {
    zip: '02584',
    city: 'Nantucket',
    state: 'MA',
    latitude: '41.263318',
    longitude: '-70.052567',
    timeZoneId: 'America/New_York',
  },
  '02601': {
    zip: '02601',
    city: 'Hyannis',
    state: 'MA',
    latitude: '41.651481',
    longitude: '-70.295573',
    timeZoneId: 'America/New_York',
  },
  '02630': {
    zip: '02630',
    city: 'Barnstable',
    state: 'MA',
    latitude: '41.700109',
    longitude: '-70.302083',
    timeZoneId: 'America/New_York',
  },
  '02631': {
    zip: '02631',
    city: 'Brewster',
    state: 'MA',
    latitude: '41.749983',
    longitude: '-70.074588',
    timeZoneId: 'America/New_York',
  },
  '02632': {
    zip: '02632',
    city: 'Centerville',
    state: 'MA',
    latitude: '41.657957',
    longitude: '-70.344642',
    timeZoneId: 'America/New_York',
  },
  '02633': {
    zip: '02633',
    city: 'Chatham',
    state: 'MA',
    latitude: '41.68534',
    longitude: '-69.979668',
    timeZoneId: 'America/New_York',
  },
  '02634': {
    zip: '02634',
    city: 'Centerville',
    state: 'MA',
    latitude: '41.657773',
    longitude: '-70.3536',
    timeZoneId: 'America/New_York',
  },
  '02635': {
    zip: '02635',
    city: 'Cotuit',
    state: 'MA',
    latitude: '41.62688',
    longitude: '-70.43475',
    timeZoneId: 'America/New_York',
  },
  '02637': {
    zip: '02637',
    city: 'Cummaquid',
    state: 'MA',
    latitude: '41.704363',
    longitude: '-70.279241',
    timeZoneId: 'America/New_York',
  },
  '02638': {
    zip: '02638',
    city: 'Dennis',
    state: 'MA',
    latitude: '41.733006',
    longitude: '-70.199015',
    timeZoneId: 'America/New_York',
  },
  '02639': {
    zip: '02639',
    city: 'Dennis Port',
    state: 'MA',
    latitude: '41.662775',
    longitude: '-70.133049',
    timeZoneId: 'America/New_York',
  },
  '02641': {
    zip: '02641',
    city: 'East Dennis',
    state: 'MA',
    latitude: '41.740504',
    longitude: '-70.158592',
    timeZoneId: 'America/New_York',
  },
  '02642': {
    zip: '02642',
    city: 'Eastham',
    state: 'MA',
    latitude: '41.823045',
    longitude: '-69.988451',
    timeZoneId: 'America/New_York',
  },
  '02643': {
    zip: '02643',
    city: 'East Orleans',
    state: 'MA',
    latitude: '41.788622',
    longitude: '-69.962488',
    timeZoneId: 'America/New_York',
  },
  '02644': {
    zip: '02644',
    city: 'Forestdale',
    state: 'MA',
    latitude: '41.683249',
    longitude: '-70.512191',
    timeZoneId: 'America/New_York',
  },
  '02645': {
    zip: '02645',
    city: 'Harwich',
    state: 'MA',
    latitude: '41.703441',
    longitude: '-70.062208',
    timeZoneId: 'America/New_York',
  },
  '02646': {
    zip: '02646',
    city: 'Harwich Port',
    state: 'MA',
    latitude: '41.673644',
    longitude: '-70.071486',
    timeZoneId: 'America/New_York',
  },
  '02647': {
    zip: '02647',
    city: 'Hyannis Port',
    state: 'MA',
    latitude: '41.636204',
    longitude: '-70.309762',
    timeZoneId: 'America/New_York',
  },
  '02648': {
    zip: '02648',
    city: 'Marstons Mills',
    state: 'MA',
    latitude: '41.671089',
    longitude: '-70.408963',
    timeZoneId: 'America/New_York',
  },
  '02649': {
    zip: '02649',
    city: 'Mashpee',
    state: 'MA',
    latitude: '41.6328',
    longitude: '-70.48872',
    timeZoneId: 'America/New_York',
  },
  '02650': {
    zip: '02650',
    city: 'North Chatham',
    state: 'MA',
    latitude: '41.702293',
    longitude: '-69.968343',
    timeZoneId: 'America/New_York',
  },
  '02651': {
    zip: '02651',
    city: 'North Eastham',
    state: 'MA',
    latitude: '41.852794',
    longitude: '-69.97358',
    timeZoneId: 'America/New_York',
  },
  '02652': {
    zip: '02652',
    city: 'North Truro',
    state: 'MA',
    latitude: '41.79573',
    longitude: '-70.241552',
    timeZoneId: 'America/New_York',
  },
  '02653': {
    zip: '02653',
    city: 'Orleans',
    state: 'MA',
    latitude: '41.781789',
    longitude: '-69.980489',
    timeZoneId: 'America/New_York',
  },
  '02655': {
    zip: '02655',
    city: 'Osterville',
    state: 'MA',
    latitude: '41.631229',
    longitude: '-70.382283',
    timeZoneId: 'America/New_York',
  },
  '02657': {
    zip: '02657',
    city: 'Provincetown',
    state: 'MA',
    latitude: '42.055479',
    longitude: '-70.187283',
    timeZoneId: 'America/New_York',
  },
  '02659': {
    zip: '02659',
    city: 'South Chatham',
    state: 'MA',
    latitude: '41.680494',
    longitude: '-70.02063',
    timeZoneId: 'America/New_York',
  },
  '02660': {
    zip: '02660',
    city: 'South Dennis',
    state: 'MA',
    latitude: '41.705465',
    longitude: '-70.157465',
    timeZoneId: 'America/New_York',
  },
  '02661': {
    zip: '02661',
    city: 'South Harwich',
    state: 'MA',
    latitude: '41.682731',
    longitude: '-70.043762',
    timeZoneId: 'America/New_York',
  },
  '02662': {
    zip: '02662',
    city: 'South Orleans',
    state: 'MA',
    latitude: '41.747448',
    longitude: '-69.991477',
    timeZoneId: 'America/New_York',
  },
  '02663': {
    zip: '02663',
    city: 'South Wellfleet',
    state: 'MA',
    latitude: '41.742515',
    longitude: '-70.060551',
    timeZoneId: 'America/New_York',
  },
  '02664': {
    zip: '02664',
    city: 'South Yarmouth',
    state: 'MA',
    latitude: '41.668281',
    longitude: '-70.193806',
    timeZoneId: 'America/New_York',
  },
  '02666': {
    zip: '02666',
    city: 'Truro',
    state: 'MA',
    latitude: '41.993876',
    longitude: '-70.044935',
    timeZoneId: 'America/New_York',
  },
  '02667': {
    zip: '02667',
    city: 'Wellfleet',
    state: 'MA',
    latitude: '41.931957',
    longitude: '-70.085896',
    timeZoneId: 'America/New_York',
  },
  '02668': {
    zip: '02668',
    city: 'West Barnstable',
    state: 'MA',
    latitude: '41.70606',
    longitude: '-70.36644',
    timeZoneId: 'America/New_York',
  },
  '02669': {
    zip: '02669',
    city: 'West Chatham',
    state: 'MA',
    latitude: '41.675266',
    longitude: '-69.998143',
    timeZoneId: 'America/New_York',
  },
  '02670': {
    zip: '02670',
    city: 'West Dennis',
    state: 'MA',
    latitude: '41.660127',
    longitude: '-70.167457',
    timeZoneId: 'America/New_York',
  },
  '02671': {
    zip: '02671',
    city: 'West Harwich',
    state: 'MA',
    latitude: '41.664741',
    longitude: '-70.11016',
    timeZoneId: 'America/New_York',
  },
  '02672': {
    zip: '02672',
    city: 'West Hyannisport',
    state: 'MA',
    latitude: '41.633467',
    longitude: '-70.326399',
    timeZoneId: 'America/New_York',
  },
  '02673': {
    zip: '02673',
    city: 'West Yarmouth',
    state: 'MA',
    latitude: '41.65284',
    longitude: '-70.24122',
    timeZoneId: 'America/New_York',
  },
  '02675': {
    zip: '02675',
    city: 'Yarmouth Port',
    state: 'MA',
    latitude: '41.704063',
    longitude: '-70.221541',
    timeZoneId: 'America/New_York',
  },
  '02702': {
    zip: '02702',
    city: 'Assonet',
    state: 'MA',
    latitude: '41.795588',
    longitude: '-71.062877',
    timeZoneId: 'America/New_York',
  },
  '02703': {
    zip: '02703',
    city: 'Attleboro',
    state: 'MA',
    latitude: '41.93916',
    longitude: '-71.3028',
    timeZoneId: 'America/New_York',
  },
  '02712': {
    zip: '02712',
    city: 'Chartley',
    state: 'MA',
    latitude: '41.967333',
    longitude: '-71.241633',
    timeZoneId: 'America/New_York',
  },
  '02713': {
    zip: '02713',
    city: 'Cuttyhunk',
    state: 'MA',
    latitude: '41.421378',
    longitude: '-70.93346',
    timeZoneId: 'America/New_York',
  },
  '02714': {
    zip: '02714',
    city: 'Dartmouth',
    state: 'MA',
    latitude: '41.571165',
    longitude: '-71.002659',
    timeZoneId: 'America/New_York',
  },
  '02715': {
    zip: '02715',
    city: 'Dighton',
    state: 'MA',
    latitude: '41.819013',
    longitude: '-71.135314',
    timeZoneId: 'America/New_York',
  },
  '02717': {
    zip: '02717',
    city: 'East Freetown',
    state: 'MA',
    latitude: '41.768046',
    longitude: '-70.971988',
    timeZoneId: 'America/New_York',
  },
  '02718': {
    zip: '02718',
    city: 'East Taunton',
    state: 'MA',
    latitude: '41.872147',
    longitude: '-71.018465',
    timeZoneId: 'America/New_York',
  },
  '02719': {
    zip: '02719',
    city: 'Fairhaven',
    state: 'MA',
    latitude: '41.636765',
    longitude: '-70.891199',
    timeZoneId: 'America/New_York',
  },
  '02720': {
    zip: '02720',
    city: 'Fall River',
    state: 'MA',
    latitude: '41.716352',
    longitude: '-71.13814',
    timeZoneId: 'America/New_York',
  },
  '02721': {
    zip: '02721',
    city: 'Fall River',
    state: 'MA',
    latitude: '41.685457',
    longitude: '-71.15702',
    timeZoneId: 'America/New_York',
  },
  '02722': {
    zip: '02722',
    city: 'Fall River',
    state: 'MA',
    latitude: '41.69952',
    longitude: '-71.155149',
    timeZoneId: 'America/New_York',
  },
  '02723': {
    zip: '02723',
    city: 'Fall River',
    state: 'MA',
    latitude: '41.694619',
    longitude: '-71.12975',
    timeZoneId: 'America/New_York',
  },
  '02724': {
    zip: '02724',
    city: 'Fall River',
    state: 'MA',
    latitude: '41.683999',
    longitude: '-71.177015',
    timeZoneId: 'America/New_York',
  },
  '02725': {
    zip: '02725',
    city: 'Somerset',
    state: 'MA',
    latitude: '41.723826',
    longitude: '-71.17766',
    timeZoneId: 'America/New_York',
  },
  '02726': {
    zip: '02726',
    city: 'Somerset',
    state: 'MA',
    latitude: '41.753713',
    longitude: '-71.146572',
    timeZoneId: 'America/New_York',
  },
  '02738': {
    zip: '02738',
    city: 'Marion',
    state: 'MA',
    latitude: '41.709414',
    longitude: '-70.758377',
    timeZoneId: 'America/New_York',
  },
  '02739': {
    zip: '02739',
    city: 'Mattapoisett',
    state: 'MA',
    latitude: '41.662281',
    longitude: '-70.805945',
    timeZoneId: 'America/New_York',
  },
  '02740': {
    zip: '02740',
    city: 'New Bedford',
    state: 'MA',
    latitude: '41.636223',
    longitude: '-70.942002',
    timeZoneId: 'America/New_York',
  },
  '02741': {
    zip: '02741',
    city: 'New Bedford',
    state: 'MA',
    latitude: '41.639892',
    longitude: '-70.939996',
    timeZoneId: 'America/New_York',
  },
  '02742': {
    zip: '02742',
    city: 'New Bedford',
    state: 'MA',
    latitude: '41.637919',
    longitude: '-70.929518',
    timeZoneId: 'America/New_York',
  },
  '02743': {
    zip: '02743',
    city: 'Acushnet',
    state: 'MA',
    latitude: '41.71128',
    longitude: '-70.90968',
    timeZoneId: 'America/New_York',
  },
  '02744': {
    zip: '02744',
    city: 'New Bedford',
    state: 'MA',
    latitude: '41.612432',
    longitude: '-70.914928',
    timeZoneId: 'America/New_York',
  },
  '02745': {
    zip: '02745',
    city: 'New Bedford',
    state: 'MA',
    latitude: '41.695977',
    longitude: '-70.938302',
    timeZoneId: 'America/New_York',
  },
  '02746': {
    zip: '02746',
    city: 'New Bedford',
    state: 'MA',
    latitude: '41.663407',
    longitude: '-70.93623',
    timeZoneId: 'America/New_York',
  },
  '02747': {
    zip: '02747',
    city: 'North Dartmouth',
    state: 'MA',
    latitude: '41.64594',
    longitude: '-70.99746',
    timeZoneId: 'America/New_York',
  },
  '02748': {
    zip: '02748',
    city: 'South Dartmouth',
    state: 'MA',
    latitude: '41.598019',
    longitude: '-70.954047',
    timeZoneId: 'America/New_York',
  },
  '02760': {
    zip: '02760',
    city: 'North Attleboro',
    state: 'MA',
    latitude: '41.977034',
    longitude: '-71.324788',
    timeZoneId: 'America/New_York',
  },
  '02761': {
    zip: '02761',
    city: 'North Attleboro',
    state: 'MA',
    latitude: '41.979305',
    longitude: '-71.331434',
    timeZoneId: 'America/New_York',
  },
  '02762': {
    zip: '02762',
    city: 'Plainville',
    state: 'MA',
    latitude: '42.01251',
    longitude: '-71.328629',
    timeZoneId: 'America/New_York',
  },
  '02763': {
    zip: '02763',
    city: 'Attleboro Falls',
    state: 'MA',
    latitude: '41.970895',
    longitude: '-71.3046',
    timeZoneId: 'America/New_York',
  },
  '02764': {
    zip: '02764',
    city: 'North Dighton',
    state: 'MA',
    latitude: '41.856622',
    longitude: '-71.148635',
    timeZoneId: 'America/New_York',
  },
  '02766': {
    zip: '02766',
    city: 'Norton',
    state: 'MA',
    latitude: '41.971692',
    longitude: '-71.18584',
    timeZoneId: 'America/New_York',
  },
  '02767': {
    zip: '02767',
    city: 'Raynham',
    state: 'MA',
    latitude: '41.927678',
    longitude: '-71.046473',
    timeZoneId: 'America/New_York',
  },
  '02768': {
    zip: '02768',
    city: 'Raynham Center',
    state: 'MA',
    latitude: '41.929116',
    longitude: '-71.041703',
    timeZoneId: 'America/New_York',
  },
  '02769': {
    zip: '02769',
    city: 'Rehoboth',
    state: 'MA',
    latitude: '41.847903',
    longitude: '-71.252629',
    timeZoneId: 'America/New_York',
  },
  '02770': {
    zip: '02770',
    city: 'Rochester',
    state: 'MA',
    latitude: '41.761866',
    longitude: '-70.845819',
    timeZoneId: 'America/New_York',
  },
  '02771': {
    zip: '02771',
    city: 'Seekonk',
    state: 'MA',
    latitude: '41.83887',
    longitude: '-71.323757',
    timeZoneId: 'America/New_York',
  },
  '02777': {
    zip: '02777',
    city: 'Swansea',
    state: 'MA',
    latitude: '41.756903',
    longitude: '-71.222215',
    timeZoneId: 'America/New_York',
  },
  '02779': {
    zip: '02779',
    city: 'Berkley',
    state: 'MA',
    latitude: '41.827125',
    longitude: '-71.080384',
    timeZoneId: 'America/New_York',
  },
  '02780': {
    zip: '02780',
    city: 'Taunton',
    state: 'MA',
    latitude: '41.904134',
    longitude: '-71.10592',
    timeZoneId: 'America/New_York',
  },
  '02790': {
    zip: '02790',
    city: 'Westport',
    state: 'MA',
    latitude: '41.607558',
    longitude: '-71.083877',
    timeZoneId: 'America/New_York',
  },
  '02791': {
    zip: '02791',
    city: 'Westport Point',
    state: 'MA',
    latitude: '41.523309',
    longitude: '-71.077717',
    timeZoneId: 'America/New_York',
  },
  '02801': {
    zip: '02801',
    city: 'Adamsville',
    state: 'RI',
    latitude: '41.544002',
    longitude: '-71.137777',
    timeZoneId: 'America/New_York',
  },
  '02802': {
    zip: '02802',
    city: 'Albion',
    state: 'RI',
    latitude: '41.952338',
    longitude: '-71.454151',
    timeZoneId: 'America/New_York',
  },
  '02804': {
    zip: '02804',
    city: 'Ashaway',
    state: 'RI',
    latitude: '41.425785',
    longitude: '-71.774739',
    timeZoneId: 'America/New_York',
  },
  '02806': {
    zip: '02806',
    city: 'Barrington',
    state: 'RI',
    latitude: '41.744068',
    longitude: '-71.315024',
    timeZoneId: 'America/New_York',
  },
  '02807': {
    zip: '02807',
    city: 'Block Island',
    state: 'RI',
    latitude: '41.180709',
    longitude: '-71.573098',
    timeZoneId: 'America/New_York',
  },
  '02808': {
    zip: '02808',
    city: 'Bradford',
    state: 'RI',
    latitude: '41.405039',
    longitude: '-71.750551',
    timeZoneId: 'America/New_York',
  },
  '02809': {
    zip: '02809',
    city: 'Bristol',
    state: 'RI',
    latitude: '41.68745',
    longitude: '-71.270122',
    timeZoneId: 'America/New_York',
  },
  '02812': {
    zip: '02812',
    city: 'Carolina',
    state: 'RI',
    latitude: '41.477353',
    longitude: '-71.646761',
    timeZoneId: 'America/New_York',
  },
  '02813': {
    zip: '02813',
    city: 'Charlestown',
    state: 'RI',
    latitude: '41.411805',
    longitude: '-71.644218',
    timeZoneId: 'America/New_York',
  },
  '02814': {
    zip: '02814',
    city: 'Chepachet',
    state: 'RI',
    latitude: '41.89885',
    longitude: '-71.681285',
    timeZoneId: 'America/New_York',
  },
  '02815': {
    zip: '02815',
    city: 'Clayville',
    state: 'RI',
    latitude: '41.794176',
    longitude: '-71.652718',
    timeZoneId: 'America/New_York',
  },
  '02816': {
    zip: '02816',
    city: 'Coventry',
    state: 'RI',
    latitude: '41.690858',
    longitude: '-71.588592',
    timeZoneId: 'America/New_York',
  },
  '02817': {
    zip: '02817',
    city: 'West Greenwich',
    state: 'RI',
    latitude: '41.624715',
    longitude: '-71.626315',
    timeZoneId: 'America/New_York',
  },
  '02818': {
    zip: '02818',
    city: 'East Greenwich',
    state: 'RI',
    latitude: '41.639217',
    longitude: '-71.502017',
    timeZoneId: 'America/New_York',
  },
  '02822': {
    zip: '02822',
    city: 'Exeter',
    state: 'RI',
    latitude: '41.555618',
    longitude: '-71.626724',
    timeZoneId: 'America/New_York',
  },
  '02823': {
    zip: '02823',
    city: 'Fiskeville',
    state: 'RI',
    latitude: '41.734577',
    longitude: '-71.545955',
    timeZoneId: 'America/New_York',
  },
  '02824': {
    zip: '02824',
    city: 'Forestdale',
    state: 'RI',
    latitude: '41.945312',
    longitude: '-71.699264',
    timeZoneId: 'America/New_York',
  },
  '02825': {
    zip: '02825',
    city: 'Foster',
    state: 'RI',
    latitude: '41.785822',
    longitude: '-71.728016',
    timeZoneId: 'America/New_York',
  },
  '02826': {
    zip: '02826',
    city: 'Glendale',
    state: 'RI',
    latitude: '41.983366',
    longitude: '-71.627803',
    timeZoneId: 'America/New_York',
  },
  '02827': {
    zip: '02827',
    city: 'Greene',
    state: 'RI',
    latitude: '41.697997',
    longitude: '-71.733673',
    timeZoneId: 'America/New_York',
  },
  '02828': {
    zip: '02828',
    city: 'Greenville',
    state: 'RI',
    latitude: '41.874239',
    longitude: '-71.552403',
    timeZoneId: 'America/New_York',
  },
  '02829': {
    zip: '02829',
    city: 'Harmony',
    state: 'RI',
    latitude: '41.882376',
    longitude: '-71.594633',
    timeZoneId: 'America/New_York',
  },
  '02830': {
    zip: '02830',
    city: 'Harrisville',
    state: 'RI',
    latitude: '41.976597',
    longitude: '-71.656277',
    timeZoneId: 'America/New_York',
  },
  '02831': {
    zip: '02831',
    city: 'Hope',
    state: 'RI',
    latitude: '41.745013',
    longitude: '-71.571255',
    timeZoneId: 'America/New_York',
  },
  '02832': {
    zip: '02832',
    city: 'Hope Valley',
    state: 'RI',
    latitude: '41.50272',
    longitude: '-71.73138',
    timeZoneId: 'America/New_York',
  },
  '02833': {
    zip: '02833',
    city: 'Hopkinton',
    state: 'RI',
    latitude: '41.470453',
    longitude: '-71.776086',
    timeZoneId: 'America/New_York',
  },
  '02835': {
    zip: '02835',
    city: 'Jamestown',
    state: 'RI',
    latitude: '41.535161',
    longitude: '-71.377788',
    timeZoneId: 'America/New_York',
  },
  '02836': {
    zip: '02836',
    city: 'Kenyon',
    state: 'RI',
    latitude: '41.448096',
    longitude: '-71.620839',
    timeZoneId: 'America/New_York',
  },
  '02837': {
    zip: '02837',
    city: 'Little Compton',
    state: 'RI',
    latitude: '41.511254',
    longitude: '-71.170707',
    timeZoneId: 'America/New_York',
  },
  '02838': {
    zip: '02838',
    city: 'Manville',
    state: 'RI',
    latitude: '41.968318',
    longitude: '-71.474105',
    timeZoneId: 'America/New_York',
  },
  '02839': {
    zip: '02839',
    city: 'Mapleville',
    state: 'RI',
    latitude: '41.942142',
    longitude: '-71.637583',
    timeZoneId: 'America/New_York',
  },
  '02840': {
    zip: '02840',
    city: 'Newport',
    state: 'RI',
    latitude: '41.475501',
    longitude: '-71.309471',
    timeZoneId: 'America/New_York',
  },
  '02841': {
    zip: '02841',
    city: 'Newport',
    state: 'RI',
    latitude: '41.52842',
    longitude: '-71.310732',
    timeZoneId: 'America/New_York',
  },
  '02842': {
    zip: '02842',
    city: 'Middletown',
    state: 'RI',
    latitude: '41.516863',
    longitude: '-71.286792',
    timeZoneId: 'America/New_York',
  },
  '02852': {
    zip: '02852',
    city: 'North Kingstown',
    state: 'RI',
    latitude: '41.58434',
    longitude: '-71.463799',
    timeZoneId: 'America/New_York',
  },
  '02857': {
    zip: '02857',
    city: 'North Scituate',
    state: 'RI',
    latitude: '41.83368',
    longitude: '-71.64162',
    timeZoneId: 'America/New_York',
  },
  '02858': {
    zip: '02858',
    city: 'Oakland',
    state: 'RI',
    latitude: '41.962823',
    longitude: '-71.645043',
    timeZoneId: 'America/New_York',
  },
  '02859': {
    zip: '02859',
    city: 'Pascoag',
    state: 'RI',
    latitude: '41.963364',
    longitude: '-71.71745',
    timeZoneId: 'America/New_York',
  },
  '02860': {
    zip: '02860',
    city: 'Pawtucket',
    state: 'RI',
    latitude: '41.872972',
    longitude: '-71.39274',
    timeZoneId: 'America/New_York',
  },
  '02861': {
    zip: '02861',
    city: 'Pawtucket',
    state: 'RI',
    latitude: '41.88117',
    longitude: '-71.355799',
    timeZoneId: 'America/New_York',
  },
  '02862': {
    zip: '02862',
    city: 'Pawtucket',
    state: 'RI',
    latitude: '41.871125',
    longitude: '-71.375669',
    timeZoneId: 'America/New_York',
  },
  '02863': {
    zip: '02863',
    city: 'Central Falls',
    state: 'RI',
    latitude: '41.887705',
    longitude: '-71.393308',
    timeZoneId: 'America/New_York',
  },
  '02864': {
    zip: '02864',
    city: 'Cumberland',
    state: 'RI',
    latitude: '41.90328',
    longitude: '-71.39238',
    timeZoneId: 'America/New_York',
  },
  '02865': {
    zip: '02865',
    city: 'Lincoln',
    state: 'RI',
    latitude: '41.910259',
    longitude: '-71.438038',
    timeZoneId: 'America/New_York',
  },
  '02871': {
    zip: '02871',
    city: 'Portsmouth',
    state: 'RI',
    latitude: '41.577598',
    longitude: '-71.266115',
    timeZoneId: 'America/New_York',
  },
  '02872': {
    zip: '02872',
    city: 'Prudence Island',
    state: 'RI',
    latitude: '41.614191',
    longitude: '-71.315427',
    timeZoneId: 'America/New_York',
  },
  '02873': {
    zip: '02873',
    city: 'Rockville',
    state: 'RI',
    latitude: '41.516994',
    longitude: '-71.768409',
    timeZoneId: 'America/New_York',
  },
  '02874': {
    zip: '02874',
    city: 'Saunderstown',
    state: 'RI',
    latitude: '41.51508',
    longitude: '-71.46738',
    timeZoneId: 'America/New_York',
  },
  '02875': {
    zip: '02875',
    city: 'Shannock',
    state: 'RI',
    latitude: '41.457393',
    longitude: '-71.639083',
    timeZoneId: 'America/New_York',
  },
  '02876': {
    zip: '02876',
    city: 'Slatersville',
    state: 'RI',
    latitude: '41.99979',
    longitude: '-71.58854',
    timeZoneId: 'America/New_York',
  },
  '02877': {
    zip: '02877',
    city: 'Slocum',
    state: 'RI',
    latitude: '41.536514',
    longitude: '-71.527238',
    timeZoneId: 'America/New_York',
  },
  '02878': {
    zip: '02878',
    city: 'Tiverton',
    state: 'RI',
    latitude: '41.615063',
    longitude: '-71.168688',
    timeZoneId: 'America/New_York',
  },
  '02879': {
    zip: '02879',
    city: 'Wakefield',
    state: 'RI',
    latitude: '41.419674',
    longitude: '-71.530513',
    timeZoneId: 'America/New_York',
  },
  '02880': {
    zip: '02880',
    city: 'Wakefield',
    state: 'RI',
    latitude: '41.423326',
    longitude: '-71.484163',
    timeZoneId: 'America/New_York',
  },
  '02881': {
    zip: '02881',
    city: 'Kingston',
    state: 'RI',
    latitude: '41.481802',
    longitude: '-71.525113',
    timeZoneId: 'America/New_York',
  },
  '02882': {
    zip: '02882',
    city: 'Narragansett',
    state: 'RI',
    latitude: '41.43214',
    longitude: '-71.468662',
    timeZoneId: 'America/New_York',
  },
  '02883': {
    zip: '02883',
    city: 'Peace Dale',
    state: 'RI',
    latitude: '41.451648',
    longitude: '-71.501278',
    timeZoneId: 'America/New_York',
  },
  '02885': {
    zip: '02885',
    city: 'Warren',
    state: 'RI',
    latitude: '41.721913',
    longitude: '-71.265238',
    timeZoneId: 'America/New_York',
  },
  '02886': {
    zip: '02886',
    city: 'Warwick',
    state: 'RI',
    latitude: '41.70272',
    longitude: '-71.444271',
    timeZoneId: 'America/New_York',
  },
  '02887': {
    zip: '02887',
    city: 'Warwick',
    state: 'RI',
    latitude: '41.70268',
    longitude: '-71.423159',
    timeZoneId: 'America/New_York',
  },
  '02888': {
    zip: '02888',
    city: 'Warwick',
    state: 'RI',
    latitude: '41.7487',
    longitude: '-71.411872',
    timeZoneId: 'America/New_York',
  },
  '02889': {
    zip: '02889',
    city: 'Warwick',
    state: 'RI',
    latitude: '41.709084',
    longitude: '-71.386673',
    timeZoneId: 'America/New_York',
  },
  '02891': {
    zip: '02891',
    city: 'Westerly',
    state: 'RI',
    latitude: '41.3622',
    longitude: '-71.796',
    timeZoneId: 'America/New_York',
  },
  '02892': {
    zip: '02892',
    city: 'West Kingston',
    state: 'RI',
    latitude: '41.502011',
    longitude: '-71.593784',
    timeZoneId: 'America/New_York',
  },
  '02893': {
    zip: '02893',
    city: 'West Warwick',
    state: 'RI',
    latitude: '41.692019',
    longitude: '-71.516302',
    timeZoneId: 'America/New_York',
  },
  '02894': {
    zip: '02894',
    city: 'Wood River Junction',
    state: 'RI',
    latitude: '41.4501',
    longitude: '-71.6916',
    timeZoneId: 'America/New_York',
  },
  '02895': {
    zip: '02895',
    city: 'Woonsocket',
    state: 'RI',
    latitude: '42.0039',
    longitude: '-71.502576',
    timeZoneId: 'America/New_York',
  },
  '02896': {
    zip: '02896',
    city: 'North Smithfield',
    state: 'RI',
    latitude: '41.965954',
    longitude: '-71.552269',
    timeZoneId: 'America/New_York',
  },
  '02898': {
    zip: '02898',
    city: 'Wyoming',
    state: 'RI',
    latitude: '41.523074',
    longitude: '-71.691005',
    timeZoneId: 'America/New_York',
  },
  '02901': {
    zip: '02901',
    city: 'Providence',
    state: 'RI',
    latitude: '41.826825',
    longitude: '-71.407999',
    timeZoneId: 'America/New_York',
  },
  '02902': {
    zip: '02902',
    city: 'Providence',
    state: 'RI',
    latitude: '41.824676',
    longitude: '-71.41079',
    timeZoneId: 'America/New_York',
  },
  '02903': {
    zip: '02903',
    city: 'Providence',
    state: 'RI',
    latitude: '41.821633',
    longitude: '-71.41267',
    timeZoneId: 'America/New_York',
  },
  '02904': {
    zip: '02904',
    city: 'Providence',
    state: 'RI',
    latitude: '41.8329',
    longitude: '-71.43756',
    timeZoneId: 'America/New_York',
  },
  '02905': {
    zip: '02905',
    city: 'Providence',
    state: 'RI',
    latitude: '41.789822',
    longitude: '-71.404779',
    timeZoneId: 'America/New_York',
  },
  '02906': {
    zip: '02906',
    city: 'Providence',
    state: 'RI',
    latitude: '41.837825',
    longitude: '-71.397886',
    timeZoneId: 'America/New_York',
  },
  '02907': {
    zip: '02907',
    city: 'Providence',
    state: 'RI',
    latitude: '41.82144',
    longitude: '-71.4339',
    timeZoneId: 'America/New_York',
  },
  '02908': {
    zip: '02908',
    city: 'Providence',
    state: 'RI',
    latitude: '41.847393',
    longitude: '-71.449537',
    timeZoneId: 'America/New_York',
  },
  '02909': {
    zip: '02909',
    city: 'Providence',
    state: 'RI',
    latitude: '41.820105',
    longitude: '-71.45187',
    timeZoneId: 'America/New_York',
  },
  '02910': {
    zip: '02910',
    city: 'Cranston',
    state: 'RI',
    latitude: '41.777181',
    longitude: '-71.436666',
    timeZoneId: 'America/New_York',
  },
  '02911': {
    zip: '02911',
    city: 'North Providence',
    state: 'RI',
    latitude: '41.852404',
    longitude: '-71.469476',
    timeZoneId: 'America/New_York',
  },
  '02912': {
    zip: '02912',
    city: 'Providence',
    state: 'RI',
    latitude: '41.824987',
    longitude: '-71.400763',
    timeZoneId: 'America/New_York',
  },
  '02914': {
    zip: '02914',
    city: 'East Providence',
    state: 'RI',
    latitude: '41.814172',
    longitude: '-71.373425',
    timeZoneId: 'America/New_York',
  },
  '02915': {
    zip: '02915',
    city: 'Riverside',
    state: 'RI',
    latitude: '41.7852',
    longitude: '-71.40726',
    timeZoneId: 'America/New_York',
  },
  '02916': {
    zip: '02916',
    city: 'Rumford',
    state: 'RI',
    latitude: '41.8398',
    longitude: '-71.4162',
    timeZoneId: 'America/New_York',
  },
  '02917': {
    zip: '02917',
    city: 'Smithfield',
    state: 'RI',
    latitude: '41.85516',
    longitude: '-71.49222',
    timeZoneId: 'America/New_York',
  },
  '02918': {
    zip: '02918',
    city: 'Providence',
    state: 'RI',
    latitude: '41.821945',
    longitude: '-71.41915',
    timeZoneId: 'America/New_York',
  },
  '02919': {
    zip: '02919',
    city: 'Johnston',
    state: 'RI',
    latitude: '41.82083',
    longitude: '-71.505188',
    timeZoneId: 'America/New_York',
  },
  '02920': {
    zip: '02920',
    city: 'Cranston',
    state: 'RI',
    latitude: '41.761799',
    longitude: '-71.464351',
    timeZoneId: 'America/New_York',
  },
  '02921': {
    zip: '02921',
    city: 'Cranston',
    state: 'RI',
    latitude: '41.75918',
    longitude: '-71.506674',
    timeZoneId: 'America/New_York',
  },
  '02940': {
    zip: '02940',
    city: 'Providence',
    state: 'RI',
    latitude: '41.822715',
    longitude: '-71.42064',
    timeZoneId: 'America/New_York',
  },
  '03031': {
    zip: '03031',
    city: 'Amherst',
    state: 'NH',
    latitude: '42.860306',
    longitude: '-71.608545',
    timeZoneId: 'America/New_York',
  },
  '03032': {
    zip: '03032',
    city: 'Auburn',
    state: 'NH',
    latitude: '42.997422',
    longitude: '-71.35159',
    timeZoneId: 'America/New_York',
  },
  '03033': {
    zip: '03033',
    city: 'Brookline',
    state: 'NH',
    latitude: '42.739053',
    longitude: '-71.677821',
    timeZoneId: 'America/New_York',
  },
  '03034': {
    zip: '03034',
    city: 'Candia',
    state: 'NH',
    latitude: '43.050055',
    longitude: '-71.305574',
    timeZoneId: 'America/New_York',
  },
  '03036': {
    zip: '03036',
    city: 'Chester',
    state: 'NH',
    latitude: '42.96656',
    longitude: '-71.23999',
    timeZoneId: 'America/New_York',
  },
  '03037': {
    zip: '03037',
    city: 'Deerfield',
    state: 'NH',
    latitude: '43.140914',
    longitude: '-71.247252',
    timeZoneId: 'America/New_York',
  },
  '03038': {
    zip: '03038',
    city: 'Derry',
    state: 'NH',
    latitude: '42.888158',
    longitude: '-71.298963',
    timeZoneId: 'America/New_York',
  },
  '03040': {
    zip: '03040',
    city: 'East Candia',
    state: 'NH',
    latitude: '43.04548',
    longitude: '-71.247063',
    timeZoneId: 'America/New_York',
  },
  '03041': {
    zip: '03041',
    city: 'East Derry',
    state: 'NH',
    latitude: '42.88964',
    longitude: '-71.297132',
    timeZoneId: 'America/New_York',
  },
  '03042': {
    zip: '03042',
    city: 'Epping',
    state: 'NH',
    latitude: '43.04785',
    longitude: '-71.076667',
    timeZoneId: 'America/New_York',
  },
  '03043': {
    zip: '03043',
    city: 'Francestown',
    state: 'NH',
    latitude: '43.002197',
    longitude: '-71.866335',
    timeZoneId: 'America/New_York',
  },
  '03044': {
    zip: '03044',
    city: 'Fremont',
    state: 'NH',
    latitude: '42.973193',
    longitude: '-71.112276',
    timeZoneId: 'America/New_York',
  },
  '03045': {
    zip: '03045',
    city: 'Goffstown',
    state: 'NH',
    latitude: '43.05924',
    longitude: '-71.5794',
    timeZoneId: 'America/New_York',
  },
  '03046': {
    zip: '03046',
    city: 'Dunbarton',
    state: 'NH',
    latitude: '43.101422',
    longitude: '-71.618832',
    timeZoneId: 'America/New_York',
  },
  '03047': {
    zip: '03047',
    city: 'Greenfield',
    state: 'NH',
    latitude: '42.952011',
    longitude: '-71.909796',
    timeZoneId: 'America/New_York',
  },
  '03048': {
    zip: '03048',
    city: 'Greenville',
    state: 'NH',
    latitude: '42.74562',
    longitude: '-71.7822',
    timeZoneId: 'America/New_York',
  },
  '03049': {
    zip: '03049',
    city: 'Hollis',
    state: 'NH',
    latitude: '42.750536',
    longitude: '-71.577679',
    timeZoneId: 'America/New_York',
  },
  '03051': {
    zip: '03051',
    city: 'Hudson',
    state: 'NH',
    latitude: '42.769764',
    longitude: '-71.420398',
    timeZoneId: 'America/New_York',
  },
  '03052': {
    zip: '03052',
    city: 'Litchfield',
    state: 'NH',
    latitude: '42.846506',
    longitude: '-71.45923',
    timeZoneId: 'America/New_York',
  },
  '03053': {
    zip: '03053',
    city: 'Londonderry',
    state: 'NH',
    latitude: '42.86712',
    longitude: '-71.377586',
    timeZoneId: 'America/New_York',
  },
  '03054': {
    zip: '03054',
    city: 'Merrimack',
    state: 'NH',
    latitude: '42.863038',
    longitude: '-71.516973',
    timeZoneId: 'America/New_York',
  },
  '03055': {
    zip: '03055',
    city: 'Milford',
    state: 'NH',
    latitude: '42.829892',
    longitude: '-71.659493',
    timeZoneId: 'America/New_York',
  },
  '03057': {
    zip: '03057',
    city: 'Mont Vernon',
    state: 'NH',
    latitude: '42.898128',
    longitude: '-71.677913',
    timeZoneId: 'America/New_York',
  },
  '03060': {
    zip: '03060',
    city: 'Nashua',
    state: 'NH',
    latitude: '42.759073',
    longitude: '-71.467632',
    timeZoneId: 'America/New_York',
  },
  '03061': {
    zip: '03061',
    city: 'Nashua',
    state: 'NH',
    latitude: '42.746543',
    longitude: '-71.493005',
    timeZoneId: 'America/New_York',
  },
  '03062': {
    zip: '03062',
    city: 'Nashua',
    state: 'NH',
    latitude: '42.726609',
    longitude: '-71.499556',
    timeZoneId: 'America/New_York',
  },
  '03063': {
    zip: '03063',
    city: 'Nashua',
    state: 'NH',
    latitude: '42.768667',
    longitude: '-71.514276',
    timeZoneId: 'America/New_York',
  },
  '03064': {
    zip: '03064',
    city: 'Nashua',
    state: 'NH',
    latitude: '42.766575',
    longitude: '-71.465576',
    timeZoneId: 'America/New_York',
  },
  '03070': {
    zip: '03070',
    city: 'New Boston',
    state: 'NH',
    latitude: '42.978338',
    longitude: '-71.696741',
    timeZoneId: 'America/New_York',
  },
  '03071': {
    zip: '03071',
    city: 'New Ipswich',
    state: 'NH',
    latitude: '42.776285',
    longitude: '-71.884984',
    timeZoneId: 'America/New_York',
  },
  '03073': {
    zip: '03073',
    city: 'North Salem',
    state: 'NH',
    latitude: '42.835016',
    longitude: '-71.224246',
    timeZoneId: 'America/New_York',
  },
  '03076': {
    zip: '03076',
    city: 'Pelham',
    state: 'NH',
    latitude: '42.733541',
    longitude: '-71.324077',
    timeZoneId: 'America/New_York',
  },
  '03077': {
    zip: '03077',
    city: 'Raymond',
    state: 'NH',
    latitude: '43.034813',
    longitude: '-71.192767',
    timeZoneId: 'America/New_York',
  },
  '03079': {
    zip: '03079',
    city: 'Salem',
    state: 'NH',
    latitude: '42.790395',
    longitude: '-71.219808',
    timeZoneId: 'America/New_York',
  },
  '03082': {
    zip: '03082',
    city: 'Lyndeborough',
    state: 'NH',
    latitude: '42.903214',
    longitude: '-71.778697',
    timeZoneId: 'America/New_York',
  },
  '03084': {
    zip: '03084',
    city: 'Temple',
    state: 'NH',
    latitude: '42.823542',
    longitude: '-71.845359',
    timeZoneId: 'America/New_York',
  },
  '03086': {
    zip: '03086',
    city: 'Wilton',
    state: 'NH',
    latitude: '42.837925',
    longitude: '-71.743677',
    timeZoneId: 'America/New_York',
  },
  '03087': {
    zip: '03087',
    city: 'Windham',
    state: 'NH',
    latitude: '42.801406',
    longitude: '-71.303561',
    timeZoneId: 'America/New_York',
  },
  '03101': {
    zip: '03101',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.988324',
    longitude: '-71.465265',
    timeZoneId: 'America/New_York',
  },
  '03102': {
    zip: '03102',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.975161',
    longitude: '-71.506713',
    timeZoneId: 'America/New_York',
  },
  '03103': {
    zip: '03103',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.965798',
    longitude: '-71.438793',
    timeZoneId: 'America/New_York',
  },
  '03104': {
    zip: '03104',
    city: 'Manchester',
    state: 'NH',
    latitude: '43.003662',
    longitude: '-71.446862',
    timeZoneId: 'America/New_York',
  },
  '03105': {
    zip: '03105',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.967657',
    longitude: '-71.417481',
    timeZoneId: 'America/New_York',
  },
  '03106': {
    zip: '03106',
    city: 'Hooksett',
    state: 'NH',
    latitude: '43.056574',
    longitude: '-71.448553',
    timeZoneId: 'America/New_York',
  },
  '03108': {
    zip: '03108',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.94621',
    longitude: '-71.439047',
    timeZoneId: 'America/New_York',
  },
  '03109': {
    zip: '03109',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.973773',
    longitude: '-71.413591',
    timeZoneId: 'America/New_York',
  },
  '03110': {
    zip: '03110',
    city: 'Bedford',
    state: 'NH',
    latitude: '42.943642',
    longitude: '-71.521201',
    timeZoneId: 'America/New_York',
  },
  '03111': {
    zip: '03111',
    city: 'Manchester',
    state: 'NH',
    latitude: '42.937825',
    longitude: '-71.453825',
    timeZoneId: 'America/New_York',
  },
  '03215': {
    zip: '03215',
    city: 'Waterville Valley',
    state: 'NH',
    latitude: '43.951855',
    longitude: '-71.500298',
    timeZoneId: 'America/New_York',
  },
  '03216': {
    zip: '03216',
    city: 'Andover',
    state: 'NH',
    latitude: '43.434421',
    longitude: '-71.773453',
    timeZoneId: 'America/New_York',
  },
  '03217': {
    zip: '03217',
    city: 'Ashland',
    state: 'NH',
    latitude: '43.693199',
    longitude: '-71.631526',
    timeZoneId: 'America/New_York',
  },
  '03218': {
    zip: '03218',
    city: 'Barnstead',
    state: 'NH',
    latitude: '43.367179',
    longitude: '-71.292304',
    timeZoneId: 'America/New_York',
  },
  '03220': {
    zip: '03220',
    city: 'Belmont',
    state: 'NH',
    latitude: '43.472125',
    longitude: '-71.474141',
    timeZoneId: 'America/New_York',
  },
  '03221': {
    zip: '03221',
    city: 'Bradford',
    state: 'NH',
    latitude: '43.30578',
    longitude: '-71.988731',
    timeZoneId: 'America/New_York',
  },
  '03222': {
    zip: '03222',
    city: 'Bristol',
    state: 'NH',
    latitude: '43.606641',
    longitude: '-71.739886',
    timeZoneId: 'America/New_York',
  },
  '03223': {
    zip: '03223',
    city: 'Campton',
    state: 'NH',
    latitude: '43.876228',
    longitude: '-71.679742',
    timeZoneId: 'America/New_York',
  },
  '03224': {
    zip: '03224',
    city: 'Canterbury',
    state: 'NH',
    latitude: '43.339167',
    longitude: '-71.562374',
    timeZoneId: 'America/New_York',
  },
  '03225': {
    zip: '03225',
    city: 'Center Barnstead',
    state: 'NH',
    latitude: '43.379371',
    longitude: '-71.2311',
    timeZoneId: 'America/New_York',
  },
  '03226': {
    zip: '03226',
    city: 'Center Harbor',
    state: 'NH',
    latitude: '43.715187',
    longitude: '-71.492553',
    timeZoneId: 'America/New_York',
  },
  '03227': {
    zip: '03227',
    city: 'Center Sandwich',
    state: 'NH',
    latitude: '43.834383',
    longitude: '-71.477065',
    timeZoneId: 'America/New_York',
  },
  '03229': {
    zip: '03229',
    city: 'Contoocook',
    state: 'NH',
    latitude: '43.20324',
    longitude: '-71.67306',
    timeZoneId: 'America/New_York',
  },
  '03230': {
    zip: '03230',
    city: 'Danbury',
    state: 'NH',
    latitude: '43.512128',
    longitude: '-71.868512',
    timeZoneId: 'America/New_York',
  },
  '03231': {
    zip: '03231',
    city: 'East Andover',
    state: 'NH',
    latitude: '43.480087',
    longitude: '-71.768205',
    timeZoneId: 'America/New_York',
  },
  '03233': {
    zip: '03233',
    city: 'Elkins',
    state: 'NH',
    latitude: '43.42032',
    longitude: '-71.93873',
    timeZoneId: 'America/New_York',
  },
  '03234': {
    zip: '03234',
    city: 'Epsom',
    state: 'NH',
    latitude: '43.214669',
    longitude: '-71.353386',
    timeZoneId: 'America/New_York',
  },
  '03235': {
    zip: '03235',
    city: 'Franklin',
    state: 'NH',
    latitude: '43.4379',
    longitude: '-71.66466',
    timeZoneId: 'America/New_York',
  },
  '03237': {
    zip: '03237',
    city: 'Gilmanton',
    state: 'NH',
    latitude: '43.415945',
    longitude: '-71.406213',
    timeZoneId: 'America/New_York',
  },
  '03238': {
    zip: '03238',
    city: 'Glencliff',
    state: 'NH',
    latitude: '43.979647',
    longitude: '-71.89651',
    timeZoneId: 'America/New_York',
  },
  '03240': {
    zip: '03240',
    city: 'Grafton',
    state: 'NH',
    latitude: '43.573264',
    longitude: '-71.971029',
    timeZoneId: 'America/New_York',
  },
  '03241': {
    zip: '03241',
    city: 'Hebron',
    state: 'NH',
    latitude: '43.712551',
    longitude: '-71.875491',
    timeZoneId: 'America/New_York',
  },
  '03242': {
    zip: '03242',
    city: 'Henniker',
    state: 'NH',
    latitude: '43.172677',
    longitude: '-71.819805',
    timeZoneId: 'America/New_York',
  },
  '03243': {
    zip: '03243',
    city: 'Hill',
    state: 'NH',
    latitude: '43.511344',
    longitude: '-71.76074',
    timeZoneId: 'America/New_York',
  },
  '03244': {
    zip: '03244',
    city: 'Hillsborough',
    state: 'NH',
    latitude: '43.132803',
    longitude: '-71.929453',
    timeZoneId: 'America/New_York',
  },
  '03245': {
    zip: '03245',
    city: 'Holderness',
    state: 'NH',
    latitude: '43.698865',
    longitude: '-71.551069',
    timeZoneId: 'America/New_York',
  },
  '03246': {
    zip: '03246',
    city: 'Laconia',
    state: 'NH',
    latitude: '43.551',
    longitude: '-71.4276',
    timeZoneId: 'America/New_York',
  },
  '03247': {
    zip: '03247',
    city: 'Laconia',
    state: 'NH',
    latitude: '43.5475',
    longitude: '-71.407222',
    timeZoneId: 'America/New_York',
  },
  '03249': {
    zip: '03249',
    city: 'Gilford',
    state: 'NH',
    latitude: '43.546583',
    longitude: '-71.404039',
    timeZoneId: 'America/New_York',
  },
  '03251': {
    zip: '03251',
    city: 'Lincoln',
    state: 'NH',
    latitude: '44.046117',
    longitude: '-71.659913',
    timeZoneId: 'America/New_York',
  },
  '03252': {
    zip: '03252',
    city: 'Lochmere',
    state: 'NH',
    latitude: '43.471344',
    longitude: '-71.538544',
    timeZoneId: 'America/New_York',
  },
  '03253': {
    zip: '03253',
    city: 'Meredith',
    state: 'NH',
    latitude: '43.600423',
    longitude: '-71.485652',
    timeZoneId: 'America/New_York',
  },
  '03254': {
    zip: '03254',
    city: 'Moultonborough',
    state: 'NH',
    latitude: '43.671415',
    longitude: '-71.378539',
    timeZoneId: 'America/New_York',
  },
  '03255': {
    zip: '03255',
    city: 'Newbury',
    state: 'NH',
    latitude: '43.320148',
    longitude: '-72.02784',
    timeZoneId: 'America/New_York',
  },
  '03256': {
    zip: '03256',
    city: 'New Hampton',
    state: 'NH',
    latitude: '43.615072',
    longitude: '-71.467519',
    timeZoneId: 'America/New_York',
  },
  '03257': {
    zip: '03257',
    city: 'New London',
    state: 'NH',
    latitude: '43.419016',
    longitude: '-71.993387',
    timeZoneId: 'America/New_York',
  },
  '03258': {
    zip: '03258',
    city: 'Chichester',
    state: 'NH',
    latitude: '43.279404',
    longitude: '-71.410433',
    timeZoneId: 'America/New_York',
  },
  '03259': {
    zip: '03259',
    city: 'North Sandwich',
    state: 'NH',
    latitude: '43.862648',
    longitude: '-71.379837',
    timeZoneId: 'America/New_York',
  },
  '03260': {
    zip: '03260',
    city: 'North Sutton',
    state: 'NH',
    latitude: '43.36584',
    longitude: '-71.939164',
    timeZoneId: 'America/New_York',
  },
  '03261': {
    zip: '03261',
    city: 'Northwood',
    state: 'NH',
    latitude: '43.194231',
    longitude: '-71.188653',
    timeZoneId: 'America/New_York',
  },
  '03262': {
    zip: '03262',
    city: 'North Woodstock',
    state: 'NH',
    latitude: '44.033209',
    longitude: '-71.68724',
    timeZoneId: 'America/New_York',
  },
  '03263': {
    zip: '03263',
    city: 'Pittsfield',
    state: 'NH',
    latitude: '43.297121',
    longitude: '-71.331823',
    timeZoneId: 'America/New_York',
  },
  '03264': {
    zip: '03264',
    city: 'Plymouth',
    state: 'NH',
    latitude: '43.753373',
    longitude: '-71.693831',
    timeZoneId: 'America/New_York',
  },
  '03266': {
    zip: '03266',
    city: 'Rumney',
    state: 'NH',
    latitude: '43.787467',
    longitude: '-71.855141',
    timeZoneId: 'America/New_York',
  },
  '03268': {
    zip: '03268',
    city: 'Salisbury',
    state: 'NH',
    latitude: '43.335317',
    longitude: '-71.45913',
    timeZoneId: 'America/New_York',
  },
  '03269': {
    zip: '03269',
    city: 'Sanbornton',
    state: 'NH',
    latitude: '43.54444',
    longitude: '-71.623113',
    timeZoneId: 'America/New_York',
  },
  '03272': {
    zip: '03272',
    city: 'South Newbury',
    state: 'NH',
    latitude: '43.296387',
    longitude: '-71.995639',
    timeZoneId: 'America/New_York',
  },
  '03273': {
    zip: '03273',
    city: 'South Sutton',
    state: 'NH',
    latitude: '43.321255',
    longitude: '-71.936438',
    timeZoneId: 'America/New_York',
  },
  '03275': {
    zip: '03275',
    city: 'Suncook',
    state: 'NH',
    latitude: '43.17324',
    longitude: '-71.40372',
    timeZoneId: 'America/New_York',
  },
  '03276': {
    zip: '03276',
    city: 'Tilton',
    state: 'NH',
    latitude: '43.45644',
    longitude: '-71.57004',
    timeZoneId: 'America/New_York',
  },
  '03278': {
    zip: '03278',
    city: 'Warner',
    state: 'NH',
    latitude: '43.27896',
    longitude: '-71.80398',
    timeZoneId: 'America/New_York',
  },
  '03279': {
    zip: '03279',
    city: 'Warren',
    state: 'NH',
    latitude: '43.964838',
    longitude: '-71.882662',
    timeZoneId: 'America/New_York',
  },
  '03280': {
    zip: '03280',
    city: 'Washington',
    state: 'NH',
    latitude: '43.168019',
    longitude: '-72.073347',
    timeZoneId: 'America/New_York',
  },
  '03281': {
    zip: '03281',
    city: 'Weare',
    state: 'NH',
    latitude: '43.085078',
    longitude: '-71.724757',
    timeZoneId: 'America/New_York',
  },
  '03282': {
    zip: '03282',
    city: 'Wentworth',
    state: 'NH',
    latitude: '43.862876',
    longitude: '-71.931934',
    timeZoneId: 'America/New_York',
  },
  '03284': {
    zip: '03284',
    city: 'Springfield',
    state: 'NH',
    latitude: '43.486437',
    longitude: '-72.056353',
    timeZoneId: 'America/New_York',
  },
  '03285': {
    zip: '03285',
    city: 'Thornton',
    state: 'NH',
    latitude: '43.877218',
    longitude: '-71.67633',
    timeZoneId: 'America/New_York',
  },
  '03287': {
    zip: '03287',
    city: 'Wilmot',
    state: 'NH',
    latitude: '43.443972',
    longitude: '-71.912264',
    timeZoneId: 'America/New_York',
  },
  '03289': {
    zip: '03289',
    city: 'Winnisquam',
    state: 'NH',
    latitude: '43.488007',
    longitude: '-71.527208',
    timeZoneId: 'America/New_York',
  },
  '03290': {
    zip: '03290',
    city: 'Nottingham',
    state: 'NH',
    latitude: '43.092728',
    longitude: '-71.118753',
    timeZoneId: 'America/New_York',
  },
  '03291': {
    zip: '03291',
    city: 'West Nottingham',
    state: 'NH',
    latitude: '43.173142',
    longitude: '-71.136707',
    timeZoneId: 'America/New_York',
  },
  '03293': {
    zip: '03293',
    city: 'Woodstock',
    state: 'NH',
    latitude: '43.97878',
    longitude: '-71.686791',
    timeZoneId: 'America/New_York',
  },
  '03298': {
    zip: '03298',
    city: 'Tilton',
    state: 'NH',
    latitude: '43.634856',
    longitude: '-71.531507',
    timeZoneId: 'America/New_York',
  },
  '03299': {
    zip: '03299',
    city: 'Tilton',
    state: 'NH',
    latitude: '43.639447',
    longitude: '-71.526265',
    timeZoneId: 'America/New_York',
  },
  '03301': {
    zip: '03301',
    city: 'Concord',
    state: 'NH',
    latitude: '43.23876',
    longitude: '-71.511',
    timeZoneId: 'America/New_York',
  },
  '03302': {
    zip: '03302',
    city: 'Concord',
    state: 'NH',
    latitude: '43.23387',
    longitude: '-71.55967',
    timeZoneId: 'America/New_York',
  },
  '03303': {
    zip: '03303',
    city: 'Concord',
    state: 'NH',
    latitude: '43.30368',
    longitude: '-71.6385',
    timeZoneId: 'America/New_York',
  },
  '03304': {
    zip: '03304',
    city: 'Bow',
    state: 'NH',
    latitude: '43.139561',
    longitude: '-71.545695',
    timeZoneId: 'America/New_York',
  },
  '03305': {
    zip: '03305',
    city: 'Concord',
    state: 'NH',
    latitude: '43.206116',
    longitude: '-71.539058',
    timeZoneId: 'America/New_York',
  },
  '03307': {
    zip: '03307',
    city: 'Loudon',
    state: 'NH',
    latitude: '43.286511',
    longitude: '-71.467441',
    timeZoneId: 'America/New_York',
  },
  '03431': {
    zip: '03431',
    city: 'Keene',
    state: 'NH',
    latitude: '42.938975',
    longitude: '-72.285756',
    timeZoneId: 'America/New_York',
  },
  '03435': {
    zip: '03435',
    city: 'Keene',
    state: 'NH',
    latitude: '42.925672',
    longitude: '-72.27635',
    timeZoneId: 'America/New_York',
  },
  '03440': {
    zip: '03440',
    city: 'Antrim',
    state: 'NH',
    latitude: '43.036836',
    longitude: '-71.945973',
    timeZoneId: 'America/New_York',
  },
  '03441': {
    zip: '03441',
    city: 'Ashuelot',
    state: 'NH',
    latitude: '42.785208',
    longitude: '-72.433601',
    timeZoneId: 'America/New_York',
  },
  '03442': {
    zip: '03442',
    city: 'Bennington',
    state: 'NH',
    latitude: '43.001522',
    longitude: '-71.924428',
    timeZoneId: 'America/New_York',
  },
  '03443': {
    zip: '03443',
    city: 'Chesterfield',
    state: 'NH',
    latitude: '42.889809',
    longitude: '-72.462899',
    timeZoneId: 'America/New_York',
  },
  '03444': {
    zip: '03444',
    city: 'Dublin',
    state: 'NH',
    latitude: '42.896912',
    longitude: '-72.042493',
    timeZoneId: 'America/New_York',
  },
  '03445': {
    zip: '03445',
    city: 'Sullivan',
    state: 'NH',
    latitude: '42.995925',
    longitude: '-72.191513',
    timeZoneId: 'America/New_York',
  },
  '03446': {
    zip: '03446',
    city: 'Swanzey',
    state: 'NH',
    latitude: '42.854142',
    longitude: '-72.260794',
    timeZoneId: 'America/New_York',
  },
  '03447': {
    zip: '03447',
    city: 'Fitzwilliam',
    state: 'NH',
    latitude: '42.752214',
    longitude: '-72.147748',
    timeZoneId: 'America/New_York',
  },
  '03448': {
    zip: '03448',
    city: 'Gilsum',
    state: 'NH',
    latitude: '43.041392',
    longitude: '-72.265499',
    timeZoneId: 'America/New_York',
  },
  '03449': {
    zip: '03449',
    city: 'Hancock',
    state: 'NH',
    latitude: '42.965726',
    longitude: '-71.958274',
    timeZoneId: 'America/New_York',
  },
  '03450': {
    zip: '03450',
    city: 'Harrisville',
    state: 'NH',
    latitude: '42.939286',
    longitude: '-72.088989',
    timeZoneId: 'America/New_York',
  },
  '03451': {
    zip: '03451',
    city: 'Hinsdale',
    state: 'NH',
    latitude: '42.799792',
    longitude: '-72.501077',
    timeZoneId: 'America/New_York',
  },
  '03452': {
    zip: '03452',
    city: 'Jaffrey',
    state: 'NH',
    latitude: '42.814093',
    longitude: '-72.031745',
    timeZoneId: 'America/New_York',
  },
  '03455': {
    zip: '03455',
    city: 'Marlborough',
    state: 'NH',
    latitude: '42.89604',
    longitude: '-72.18942',
    timeZoneId: 'America/New_York',
  },
  '03456': {
    zip: '03456',
    city: 'Marlow',
    state: 'NH',
    latitude: '43.130345',
    longitude: '-72.215803',
    timeZoneId: 'America/New_York',
  },
  '03457': {
    zip: '03457',
    city: 'Nelson',
    state: 'NH',
    latitude: '43.002905',
    longitude: '-72.14498',
    timeZoneId: 'America/New_York',
  },
  '03458': {
    zip: '03458',
    city: 'Peterborough',
    state: 'NH',
    latitude: '42.886683',
    longitude: '-71.947147',
    timeZoneId: 'America/New_York',
  },
  '03461': {
    zip: '03461',
    city: 'Rindge',
    state: 'NH',
    latitude: '42.745423',
    longitude: '-72.019047',
    timeZoneId: 'America/New_York',
  },
  '03462': {
    zip: '03462',
    city: 'Spofford',
    state: 'NH',
    latitude: '42.905544',
    longitude: '-72.403189',
    timeZoneId: 'America/New_York',
  },
  '03464': {
    zip: '03464',
    city: 'Stoddard',
    state: 'NH',
    latitude: '43.091956',
    longitude: '-72.086878',
    timeZoneId: 'America/New_York',
  },
  '03465': {
    zip: '03465',
    city: 'Troy',
    state: 'NH',
    latitude: '42.826713',
    longitude: '-72.181022',
    timeZoneId: 'America/New_York',
  },
  '03466': {
    zip: '03466',
    city: 'West Chesterfield',
    state: 'NH',
    latitude: '42.871118',
    longitude: '-72.516627',
    timeZoneId: 'America/New_York',
  },
  '03467': {
    zip: '03467',
    city: 'Westmoreland',
    state: 'NH',
    latitude: '42.956812',
    longitude: '-72.422762',
    timeZoneId: 'America/New_York',
  },
  '03468': {
    zip: '03468',
    city: 'West Peterborough',
    state: 'NH',
    latitude: '42.871737',
    longitude: '-71.969706',
    timeZoneId: 'America/New_York',
  },
  '03469': {
    zip: '03469',
    city: 'West Swanzey',
    state: 'NH',
    latitude: '42.864159',
    longitude: '-72.328061',
    timeZoneId: 'America/New_York',
  },
  '03470': {
    zip: '03470',
    city: 'Winchester',
    state: 'NH',
    latitude: '42.78558',
    longitude: '-72.3348',
    timeZoneId: 'America/New_York',
  },
  '03561': {
    zip: '03561',
    city: 'Littleton',
    state: 'NH',
    latitude: '44.308081',
    longitude: '-71.77106',
    timeZoneId: 'America/New_York',
  },
  '03570': {
    zip: '03570',
    city: 'Berlin',
    state: 'NH',
    latitude: '44.476298',
    longitude: '-71.176748',
    timeZoneId: 'America/New_York',
  },
  '03574': {
    zip: '03574',
    city: 'Bethlehem',
    state: 'NH',
    latitude: '44.290117',
    longitude: '-71.662463',
    timeZoneId: 'America/New_York',
  },
  '03575': {
    zip: '03575',
    city: 'Bretton Woods',
    state: 'NH',
    latitude: '44.258425',
    longitude: '-71.440405',
    timeZoneId: 'America/New_York',
  },
  '03576': {
    zip: '03576',
    city: 'Colebrook',
    state: 'NH',
    latitude: '44.89239',
    longitude: '-71.498526',
    timeZoneId: 'America/New_York',
  },
  '03579': {
    zip: '03579',
    city: 'Errol',
    state: 'NH',
    latitude: '44.775308',
    longitude: '-71.175804',
    timeZoneId: 'America/New_York',
  },
  '03580': {
    zip: '03580',
    city: 'Franconia',
    state: 'NH',
    latitude: '44.228548',
    longitude: '-71.749886',
    timeZoneId: 'America/New_York',
  },
  '03581': {
    zip: '03581',
    city: 'Gorham',
    state: 'NH',
    latitude: '44.400621',
    longitude: '-71.192698',
    timeZoneId: 'America/New_York',
  },
  '03582': {
    zip: '03582',
    city: 'Groveton',
    state: 'NH',
    latitude: '44.600195',
    longitude: '-71.510658',
    timeZoneId: 'America/New_York',
  },
  '03583': {
    zip: '03583',
    city: 'Jefferson',
    state: 'NH',
    latitude: '44.394373',
    longitude: '-71.432883',
    timeZoneId: 'America/New_York',
  },
  '03584': {
    zip: '03584',
    city: 'Lancaster',
    state: 'NH',
    latitude: '44.488551',
    longitude: '-71.567367',
    timeZoneId: 'America/New_York',
  },
  '03585': {
    zip: '03585',
    city: 'Lisbon',
    state: 'NH',
    latitude: '44.210565',
    longitude: '-71.903972',
    timeZoneId: 'America/New_York',
  },
  '03586': {
    zip: '03586',
    city: 'Sugar Hill',
    state: 'NH',
    latitude: '44.221351',
    longitude: '-71.819069',
    timeZoneId: 'America/New_York',
  },
  '03588': {
    zip: '03588',
    city: 'Milan',
    state: 'NH',
    latitude: '44.639911',
    longitude: '-71.148517',
    timeZoneId: 'America/New_York',
  },
  '03589': {
    zip: '03589',
    city: 'Mount Washington',
    state: 'NH',
    latitude: '44.347851',
    longitude: '-71.551849',
    timeZoneId: 'America/New_York',
  },
  '03590': {
    zip: '03590',
    city: 'North Stratford',
    state: 'NH',
    latitude: '44.71919',
    longitude: '-71.465831',
    timeZoneId: 'America/New_York',
  },
  '03592': {
    zip: '03592',
    city: 'Pittsburg',
    state: 'NH',
    latitude: '45.113334',
    longitude: '-71.254468',
    timeZoneId: 'America/New_York',
  },
  '03593': {
    zip: '03593',
    city: 'Randolph',
    state: 'NH',
    latitude: '44.475301',
    longitude: '-71.177733',
    timeZoneId: 'America/New_York',
  },
  '03595': {
    zip: '03595',
    city: 'Twin Mountain',
    state: 'NH',
    latitude: '44.271065',
    longitude: '-71.541509',
    timeZoneId: 'America/New_York',
  },
  '03597': {
    zip: '03597',
    city: 'West Stewartstown',
    state: 'NH',
    latitude: '44.992788',
    longitude: '-71.534456',
    timeZoneId: 'America/New_York',
  },
  '03598': {
    zip: '03598',
    city: 'Whitefield',
    state: 'NH',
    latitude: '44.376161',
    longitude: '-71.618637',
    timeZoneId: 'America/New_York',
  },
  '03601': {
    zip: '03601',
    city: 'Acworth',
    state: 'NH',
    latitude: '43.242247',
    longitude: '-72.38616',
    timeZoneId: 'America/New_York',
  },
  '03602': {
    zip: '03602',
    city: 'Alstead',
    state: 'NH',
    latitude: '43.154442',
    longitude: '-72.304628',
    timeZoneId: 'America/New_York',
  },
  '03603': {
    zip: '03603',
    city: 'Charlestown',
    state: 'NH',
    latitude: '43.236105',
    longitude: '-72.426678',
    timeZoneId: 'America/New_York',
  },
  '03604': {
    zip: '03604',
    city: 'Drewsville',
    state: 'NH',
    latitude: '43.129002',
    longitude: '-72.392011',
    timeZoneId: 'America/New_York',
  },
  '03605': {
    zip: '03605',
    city: 'Lempster',
    state: 'NH',
    latitude: '43.212734',
    longitude: '-72.161103',
    timeZoneId: 'America/New_York',
  },
  '03607': {
    zip: '03607',
    city: 'South Acworth',
    state: 'NH',
    latitude: '43.176228',
    longitude: '-72.332333',
    timeZoneId: 'America/New_York',
  },
  '03608': {
    zip: '03608',
    city: 'Walpole',
    state: 'NH',
    latitude: '43.070183',
    longitude: '-72.407165',
    timeZoneId: 'America/New_York',
  },
  '03609': {
    zip: '03609',
    city: 'North Walpole',
    state: 'NH',
    latitude: '43.141354',
    longitude: '-72.445147',
    timeZoneId: 'America/New_York',
  },
  '03740': {
    zip: '03740',
    city: 'Bath',
    state: 'NH',
    latitude: '44.180623',
    longitude: '-71.95187',
    timeZoneId: 'America/New_York',
  },
  '03741': {
    zip: '03741',
    city: 'Canaan',
    state: 'NH',
    latitude: '43.663654',
    longitude: '-72.004386',
    timeZoneId: 'America/New_York',
  },
  '03743': {
    zip: '03743',
    city: 'Claremont',
    state: 'NH',
    latitude: '43.368783',
    longitude: '-72.345829',
    timeZoneId: 'America/New_York',
  },
  '03745': {
    zip: '03745',
    city: 'Cornish',
    state: 'NH',
    latitude: '43.52781',
    longitude: '-72.36838',
    timeZoneId: 'America/New_York',
  },
  '03746': {
    zip: '03746',
    city: 'Cornish Flat',
    state: 'NH',
    latitude: '43.496568',
    longitude: '-72.279969',
    timeZoneId: 'America/New_York',
  },
  '03748': {
    zip: '03748',
    city: 'Enfield',
    state: 'NH',
    latitude: '43.632905',
    longitude: '-72.135383',
    timeZoneId: 'America/New_York',
  },
  '03749': {
    zip: '03749',
    city: 'Enfield Center',
    state: 'NH',
    latitude: '43.589213',
    longitude: '-72.110433',
    timeZoneId: 'America/New_York',
  },
  '03750': {
    zip: '03750',
    city: 'Etna',
    state: 'NH',
    latitude: '43.715017',
    longitude: '-72.211489',
    timeZoneId: 'America/New_York',
  },
  '03751': {
    zip: '03751',
    city: 'Georges Mills',
    state: 'NH',
    latitude: '43.432435',
    longitude: '-72.069893',
    timeZoneId: 'America/New_York',
  },
  '03752': {
    zip: '03752',
    city: 'Goshen',
    state: 'NH',
    latitude: '43.286343',
    longitude: '-72.113495',
    timeZoneId: 'America/New_York',
  },
  '03753': {
    zip: '03753',
    city: 'Grantham',
    state: 'NH',
    latitude: '43.527803',
    longitude: '-72.137172',
    timeZoneId: 'America/New_York',
  },
  '03754': {
    zip: '03754',
    city: 'Guild',
    state: 'NH',
    latitude: '43.376375',
    longitude: '-72.13297',
    timeZoneId: 'America/New_York',
  },
  '03755': {
    zip: '03755',
    city: 'Hanover',
    state: 'NH',
    latitude: '43.711386',
    longitude: '-72.270611',
    timeZoneId: 'America/New_York',
  },
  '03756': {
    zip: '03756',
    city: 'Lebanon',
    state: 'NH',
    latitude: '43.637006',
    longitude: '-72.252383',
    timeZoneId: 'America/New_York',
  },
  '03765': {
    zip: '03765',
    city: 'Haverhill',
    state: 'NH',
    latitude: '44.042602',
    longitude: '-72.061806',
    timeZoneId: 'America/New_York',
  },
  '03766': {
    zip: '03766',
    city: 'Lebanon',
    state: 'NH',
    latitude: '43.642879',
    longitude: '-72.253714',
    timeZoneId: 'America/New_York',
  },
  '03768': {
    zip: '03768',
    city: 'Lyme',
    state: 'NH',
    latitude: '43.773137',
    longitude: '-72.164254',
    timeZoneId: 'America/New_York',
  },
  '03769': {
    zip: '03769',
    city: 'Lyme Center',
    state: 'NH',
    latitude: '43.794676',
    longitude: '-72.06561',
    timeZoneId: 'America/New_York',
  },
  '03770': {
    zip: '03770',
    city: 'Meriden',
    state: 'NH',
    latitude: '43.517012',
    longitude: '-72.278909',
    timeZoneId: 'America/New_York',
  },
  '03771': {
    zip: '03771',
    city: 'Monroe',
    state: 'NH',
    latitude: '44.271248',
    longitude: '-72.025509',
    timeZoneId: 'America/New_York',
  },
  '03773': {
    zip: '03773',
    city: 'Newport',
    state: 'NH',
    latitude: '43.369645',
    longitude: '-72.158576',
    timeZoneId: 'America/New_York',
  },
  '03774': {
    zip: '03774',
    city: 'North Haverhill',
    state: 'NH',
    latitude: '44.084308',
    longitude: '-72.025912',
    timeZoneId: 'America/New_York',
  },
  '03777': {
    zip: '03777',
    city: 'Orford',
    state: 'NH',
    latitude: '43.895622',
    longitude: '-72.066824',
    timeZoneId: 'America/New_York',
  },
  '03779': {
    zip: '03779',
    city: 'Piermont',
    state: 'NH',
    latitude: '43.976039',
    longitude: '-72.093186',
    timeZoneId: 'America/New_York',
  },
  '03780': {
    zip: '03780',
    city: 'Pike',
    state: 'NH',
    latitude: '43.99987',
    longitude: '-72.000233',
    timeZoneId: 'America/New_York',
  },
  '03781': {
    zip: '03781',
    city: 'Plainfield',
    state: 'NH',
    latitude: '43.381168',
    longitude: '-72.17724',
    timeZoneId: 'America/New_York',
  },
  '03782': {
    zip: '03782',
    city: 'Sunapee',
    state: 'NH',
    latitude: '43.3896',
    longitude: '-72.09138',
    timeZoneId: 'America/New_York',
  },
  '03784': {
    zip: '03784',
    city: 'West Lebanon',
    state: 'NH',
    latitude: '43.652405',
    longitude: '-72.304175',
    timeZoneId: 'America/New_York',
  },
  '03785': {
    zip: '03785',
    city: 'Woodsville',
    state: 'NH',
    latitude: '44.149423',
    longitude: '-72.034577',
    timeZoneId: 'America/New_York',
  },
  '03801': {
    zip: '03801',
    city: 'Portsmouth',
    state: 'NH',
    latitude: '43.067228',
    longitude: '-70.784076',
    timeZoneId: 'America/New_York',
  },
  '03802': {
    zip: '03802',
    city: 'Portsmouth',
    state: 'NH',
    latitude: '43.078761',
    longitude: '-70.757698',
    timeZoneId: 'America/New_York',
  },
  '03803': {
    zip: '03803',
    city: 'Portsmouth',
    state: 'NH',
    latitude: '43.067757',
    longitude: '-70.800532',
    timeZoneId: 'America/New_York',
  },
  '03804': {
    zip: '03804',
    city: 'Portsmouth',
    state: 'NH',
    latitude: '43.075938',
    longitude: '-70.75904',
    timeZoneId: 'America/New_York',
  },
  '03809': {
    zip: '03809',
    city: 'Alton',
    state: 'NH',
    latitude: '43.47321',
    longitude: '-71.240373',
    timeZoneId: 'America/New_York',
  },
  '03810': {
    zip: '03810',
    city: 'Alton Bay',
    state: 'NH',
    latitude: '43.481209',
    longitude: '-71.24763',
    timeZoneId: 'America/New_York',
  },
  '03811': {
    zip: '03811',
    city: 'Atkinson',
    state: 'NH',
    latitude: '42.838549',
    longitude: '-71.159313',
    timeZoneId: 'America/New_York',
  },
  '03812': {
    zip: '03812',
    city: 'Bartlett',
    state: 'NH',
    latitude: '44.088653',
    longitude: '-71.280179',
    timeZoneId: 'America/New_York',
  },
  '03813': {
    zip: '03813',
    city: 'Center Conway',
    state: 'NH',
    latitude: '44.16317',
    longitude: '-71.01233',
    timeZoneId: 'America/New_York',
  },
  '03814': {
    zip: '03814',
    city: 'Center Ossipee',
    state: 'NH',
    latitude: '43.74984',
    longitude: '-71.105236',
    timeZoneId: 'America/New_York',
  },
  '03815': {
    zip: '03815',
    city: 'Center Strafford',
    state: 'NH',
    latitude: '43.258263',
    longitude: '-71.115091',
    timeZoneId: 'America/New_York',
  },
  '03816': {
    zip: '03816',
    city: 'Center Tuftonboro',
    state: 'NH',
    latitude: '43.656557',
    longitude: '-71.251707',
    timeZoneId: 'America/New_York',
  },
  '03817': {
    zip: '03817',
    city: 'Chocorua',
    state: 'NH',
    latitude: '43.894157',
    longitude: '-71.238916',
    timeZoneId: 'America/New_York',
  },
  '03818': {
    zip: '03818',
    city: 'Conway',
    state: 'NH',
    latitude: '43.925298',
    longitude: '-71.134643',
    timeZoneId: 'America/New_York',
  },
  '03819': {
    zip: '03819',
    city: 'Danville',
    state: 'NH',
    latitude: '42.919072',
    longitude: '-71.116979',
    timeZoneId: 'America/New_York',
  },
  '03820': {
    zip: '03820',
    city: 'Dover',
    state: 'NH',
    latitude: '43.18566',
    longitude: '-70.91364',
    timeZoneId: 'America/New_York',
  },
  '03821': {
    zip: '03821',
    city: 'Dover',
    state: 'NH',
    latitude: '43.188748',
    longitude: '-70.902833',
    timeZoneId: 'America/New_York',
  },
  '03822': {
    zip: '03822',
    city: 'Dover',
    state: 'NH',
    latitude: '43.218179',
    longitude: '-70.841093',
    timeZoneId: 'America/New_York',
  },
  '03823': {
    zip: '03823',
    city: 'Madbury',
    state: 'NH',
    latitude: '43.191006',
    longitude: '-70.892244',
    timeZoneId: 'America/New_York',
  },
  '03824': {
    zip: '03824',
    city: 'Durham',
    state: 'NH',
    latitude: '43.1304',
    longitude: '-70.9767',
    timeZoneId: 'America/New_York',
  },
  '03825': {
    zip: '03825',
    city: 'Barrington',
    state: 'NH',
    latitude: '43.21085',
    longitude: '-71.028547',
    timeZoneId: 'America/New_York',
  },
  '03826': {
    zip: '03826',
    city: 'East Hampstead',
    state: 'NH',
    latitude: '42.890279',
    longitude: '-71.137885',
    timeZoneId: 'America/New_York',
  },
  '03827': {
    zip: '03827',
    city: 'East Kingston',
    state: 'NH',
    latitude: '42.911422',
    longitude: '-70.983388',
    timeZoneId: 'America/New_York',
  },
  '03830': {
    zip: '03830',
    city: 'East Wakefield',
    state: 'NH',
    latitude: '43.568056',
    longitude: '-71.030556',
    timeZoneId: 'America/New_York',
  },
  '03832': {
    zip: '03832',
    city: 'Eaton Center',
    state: 'NH',
    latitude: '43.911744',
    longitude: '-71.083715',
    timeZoneId: 'America/New_York',
  },
  '03833': {
    zip: '03833',
    city: 'Exeter',
    state: 'NH',
    latitude: '42.97752',
    longitude: '-70.9923',
    timeZoneId: 'America/New_York',
  },
  '03835': {
    zip: '03835',
    city: 'Farmington',
    state: 'NH',
    latitude: '43.385946',
    longitude: '-71.061069',
    timeZoneId: 'America/New_York',
  },
  '03836': {
    zip: '03836',
    city: 'Freedom',
    state: 'NH',
    latitude: '43.821384',
    longitude: '-71.088403',
    timeZoneId: 'America/New_York',
  },
  '03837': {
    zip: '03837',
    city: 'Gilmanton Iron Works',
    state: 'NH',
    latitude: '43.43124',
    longitude: '-71.29944',
    timeZoneId: 'America/New_York',
  },
  '03838': {
    zip: '03838',
    city: 'Glen',
    state: 'NH',
    latitude: '44.099679',
    longitude: '-71.206218',
    timeZoneId: 'America/New_York',
  },
  '03839': {
    zip: '03839',
    city: 'Rochester',
    state: 'NH',
    latitude: '43.26558',
    longitude: '-71.01042',
    timeZoneId: 'America/New_York',
  },
  '03840': {
    zip: '03840',
    city: 'Greenland',
    state: 'NH',
    latitude: '43.037821',
    longitude: '-70.846351',
    timeZoneId: 'America/New_York',
  },
  '03841': {
    zip: '03841',
    city: 'Hampstead',
    state: 'NH',
    latitude: '42.881399',
    longitude: '-71.178667',
    timeZoneId: 'America/New_York',
  },
  '03842': {
    zip: '03842',
    city: 'Hampton',
    state: 'NH',
    latitude: '42.932595',
    longitude: '-70.823853',
    timeZoneId: 'America/New_York',
  },
  '03843': {
    zip: '03843',
    city: 'Hampton',
    state: 'NH',
    latitude: '42.940988',
    longitude: '-70.825066',
    timeZoneId: 'America/New_York',
  },
  '03844': {
    zip: '03844',
    city: 'Hampton Falls',
    state: 'NH',
    latitude: '42.924487',
    longitude: '-70.885999',
    timeZoneId: 'America/New_York',
  },
  '03845': {
    zip: '03845',
    city: 'Intervale',
    state: 'NH',
    latitude: '44.075687',
    longitude: '-71.131772',
    timeZoneId: 'America/New_York',
  },
  '03846': {
    zip: '03846',
    city: 'Jackson',
    state: 'NH',
    latitude: '44.184322',
    longitude: '-71.189585',
    timeZoneId: 'America/New_York',
  },
  '03847': {
    zip: '03847',
    city: 'Kearsarge',
    state: 'NH',
    latitude: '44.072782',
    longitude: '-71.133007',
    timeZoneId: 'America/New_York',
  },
  '03848': {
    zip: '03848',
    city: 'Kingston',
    state: 'NH',
    latitude: '42.919841',
    longitude: '-71.064382',
    timeZoneId: 'America/New_York',
  },
  '03849': {
    zip: '03849',
    city: 'Madison',
    state: 'NH',
    latitude: '43.890397',
    longitude: '-71.116993',
    timeZoneId: 'America/New_York',
  },
  '03850': {
    zip: '03850',
    city: 'Melvin Village',
    state: 'NH',
    latitude: '43.691873',
    longitude: '-71.296061',
    timeZoneId: 'America/New_York',
  },
  '03851': {
    zip: '03851',
    city: 'Milton',
    state: 'NH',
    latitude: '43.442259',
    longitude: '-71.015713',
    timeZoneId: 'America/New_York',
  },
  '03852': {
    zip: '03852',
    city: 'Milton Mills',
    state: 'NH',
    latitude: '43.504592',
    longitude: '-70.978108',
    timeZoneId: 'America/New_York',
  },
  '03853': {
    zip: '03853',
    city: 'Mirror Lake',
    state: 'NH',
    latitude: '43.62148',
    longitude: '-71.279494',
    timeZoneId: 'America/New_York',
  },
  '03854': {
    zip: '03854',
    city: 'New Castle',
    state: 'NH',
    latitude: '43.06879',
    longitude: '-70.719895',
    timeZoneId: 'America/New_York',
  },
  '03855': {
    zip: '03855',
    city: 'New Durham',
    state: 'NH',
    latitude: '43.440508',
    longitude: '-71.149994',
    timeZoneId: 'America/New_York',
  },
  '03856': {
    zip: '03856',
    city: 'Newfields',
    state: 'NH',
    latitude: '43.037881',
    longitude: '-70.937307',
    timeZoneId: 'America/New_York',
  },
  '03857': {
    zip: '03857',
    city: 'Newmarket',
    state: 'NH',
    latitude: '43.077386',
    longitude: '-70.940347',
    timeZoneId: 'America/New_York',
  },
  '03858': {
    zip: '03858',
    city: 'Newton',
    state: 'NH',
    latitude: '42.871206',
    longitude: '-71.045821',
    timeZoneId: 'America/New_York',
  },
  '03859': {
    zip: '03859',
    city: 'Newton Junction',
    state: 'NH',
    latitude: '42.867214',
    longitude: '-71.065993',
    timeZoneId: 'America/New_York',
  },
  '03860': {
    zip: '03860',
    city: 'North Conway',
    state: 'NH',
    latitude: '44.041258',
    longitude: '-71.131424',
    timeZoneId: 'America/New_York',
  },
  '03861': {
    zip: '03861',
    city: 'Lee',
    state: 'NH',
    latitude: '43.09822',
    longitude: '-71.042089',
    timeZoneId: 'America/New_York',
  },
  '03862': {
    zip: '03862',
    city: 'North Hampton',
    state: 'NH',
    latitude: '42.976443',
    longitude: '-70.820892',
    timeZoneId: 'America/New_York',
  },
  '03864': {
    zip: '03864',
    city: 'Ossipee',
    state: 'NH',
    latitude: '43.692691',
    longitude: '-71.113673',
    timeZoneId: 'America/New_York',
  },
  '03865': {
    zip: '03865',
    city: 'Plaistow',
    state: 'NH',
    latitude: '42.83895',
    longitude: '-71.091887',
    timeZoneId: 'America/New_York',
  },
  '03866': {
    zip: '03866',
    city: 'Rochester',
    state: 'NH',
    latitude: '43.301013',
    longitude: '-70.977337',
    timeZoneId: 'America/New_York',
  },
  '03867': {
    zip: '03867',
    city: 'Rochester',
    state: 'NH',
    latitude: '43.306503',
    longitude: '-70.991609',
    timeZoneId: 'America/New_York',
  },
  '03868': {
    zip: '03868',
    city: 'Rochester',
    state: 'NH',
    latitude: '43.33662',
    longitude: '-70.9473',
    timeZoneId: 'America/New_York',
  },
  '03869': {
    zip: '03869',
    city: 'Rollinsford',
    state: 'NH',
    latitude: '43.231809',
    longitude: '-70.829693',
    timeZoneId: 'America/New_York',
  },
  '03870': {
    zip: '03870',
    city: 'Rye',
    state: 'NH',
    latitude: '43.007023',
    longitude: '-70.767292',
    timeZoneId: 'America/New_York',
  },
  '03871': {
    zip: '03871',
    city: 'Rye Beach',
    state: 'NH',
    latitude: '42.991996',
    longitude: '-70.760985',
    timeZoneId: 'America/New_York',
  },
  '03872': {
    zip: '03872',
    city: 'Sanbornville',
    state: 'NH',
    latitude: '43.551728',
    longitude: '-71.015802',
    timeZoneId: 'America/New_York',
  },
  '03873': {
    zip: '03873',
    city: 'Sandown',
    state: 'NH',
    latitude: '42.92972',
    longitude: '-71.186162',
    timeZoneId: 'America/New_York',
  },
  '03874': {
    zip: '03874',
    city: 'Seabrook',
    state: 'NH',
    latitude: '42.891113',
    longitude: '-70.857854',
    timeZoneId: 'America/New_York',
  },
  '03875': {
    zip: '03875',
    city: 'Silver Lake',
    state: 'NH',
    latitude: '43.849568',
    longitude: '-71.176176',
    timeZoneId: 'America/New_York',
  },
  '03878': {
    zip: '03878',
    city: 'Somersworth',
    state: 'NH',
    latitude: '43.253884',
    longitude: '-70.873359',
    timeZoneId: 'America/New_York',
  },
  '03882': {
    zip: '03882',
    city: 'Effingham',
    state: 'NH',
    latitude: '43.715503',
    longitude: '-71.004712',
    timeZoneId: 'America/New_York',
  },
  '03883': {
    zip: '03883',
    city: 'South Tamworth',
    state: 'NH',
    latitude: '43.839632',
    longitude: '-71.313989',
    timeZoneId: 'America/New_York',
  },
  '03884': {
    zip: '03884',
    city: 'Strafford',
    state: 'NH',
    latitude: '43.232808',
    longitude: '-71.131735',
    timeZoneId: 'America/New_York',
  },
  '03885': {
    zip: '03885',
    city: 'Stratham',
    state: 'NH',
    latitude: '43.017264',
    longitude: '-70.897061',
    timeZoneId: 'America/New_York',
  },
  '03886': {
    zip: '03886',
    city: 'Tamworth',
    state: 'NH',
    latitude: '43.873645',
    longitude: '-71.285814',
    timeZoneId: 'America/New_York',
  },
  '03887': {
    zip: '03887',
    city: 'Union',
    state: 'NH',
    latitude: '43.462117',
    longitude: '-71.07511',
    timeZoneId: 'America/New_York',
  },
  '03890': {
    zip: '03890',
    city: 'West Ossipee',
    state: 'NH',
    latitude: '43.838457',
    longitude: '-71.201602',
    timeZoneId: 'America/New_York',
  },
  '03894': {
    zip: '03894',
    city: 'Wolfeboro',
    state: 'NH',
    latitude: '43.587172',
    longitude: '-71.220009',
    timeZoneId: 'America/New_York',
  },
  '03896': {
    zip: '03896',
    city: 'Wolfeboro Falls',
    state: 'NH',
    latitude: '43.587884',
    longitude: '-71.207648',
    timeZoneId: 'America/New_York',
  },
  '03897': {
    zip: '03897',
    city: 'Wonalancet',
    state: 'NH',
    latitude: '43.906588',
    longitude: '-71.347509',
    timeZoneId: 'America/New_York',
  },
  '03901': {
    zip: '03901',
    city: 'Berwick',
    state: 'ME',
    latitude: '43.289863',
    longitude: '-70.853529',
    timeZoneId: 'America/New_York',
  },
  '03902': {
    zip: '03902',
    city: 'Cape Neddick',
    state: 'ME',
    latitude: '43.211238',
    longitude: '-70.614151',
    timeZoneId: 'America/New_York',
  },
  '03903': {
    zip: '03903',
    city: 'Eliot',
    state: 'ME',
    latitude: '43.130826',
    longitude: '-70.789695',
    timeZoneId: 'America/New_York',
  },
  '03904': {
    zip: '03904',
    city: 'Kittery',
    state: 'ME',
    latitude: '43.097316',
    longitude: '-70.743639',
    timeZoneId: 'America/New_York',
  },
  '03905': {
    zip: '03905',
    city: 'Kittery Point',
    state: 'ME',
    latitude: '43.090653',
    longitude: '-70.699746',
    timeZoneId: 'America/New_York',
  },
  '03906': {
    zip: '03906',
    city: 'North Berwick',
    state: 'ME',
    latitude: '43.305842',
    longitude: '-70.742523',
    timeZoneId: 'America/New_York',
  },
  '03907': {
    zip: '03907',
    city: 'Ogunquit',
    state: 'ME',
    latitude: '43.257416',
    longitude: '-70.60293',
    timeZoneId: 'America/New_York',
  },
  '03908': {
    zip: '03908',
    city: 'South Berwick',
    state: 'ME',
    latitude: '43.230935',
    longitude: '-70.783559',
    timeZoneId: 'America/New_York',
  },
  '03909': {
    zip: '03909',
    city: 'York',
    state: 'ME',
    latitude: '43.154395',
    longitude: '-70.655138',
    timeZoneId: 'America/New_York',
  },
  '03910': {
    zip: '03910',
    city: 'York Beach',
    state: 'ME',
    latitude: '43.177794',
    longitude: '-70.614252',
    timeZoneId: 'America/New_York',
  },
  '03911': {
    zip: '03911',
    city: 'York Harbor',
    state: 'ME',
    latitude: '43.135724',
    longitude: '-70.637154',
    timeZoneId: 'America/New_York',
  },
  '04001': {
    zip: '04001',
    city: 'Acton',
    state: 'ME',
    latitude: '43.566236',
    longitude: '-70.916523',
    timeZoneId: 'America/New_York',
  },
  '04002': {
    zip: '04002',
    city: 'Alfred',
    state: 'ME',
    latitude: '43.492177',
    longitude: '-70.705903',
    timeZoneId: 'America/New_York',
  },
  '04003': {
    zip: '04003',
    city: 'Bailey Island',
    state: 'ME',
    latitude: '43.682169',
    longitude: '-70.153983',
    timeZoneId: 'America/New_York',
  },
  '04004': {
    zip: '04004',
    city: 'Bar Mills',
    state: 'ME',
    latitude: '43.66486',
    longitude: '-70.601219',
    timeZoneId: 'America/New_York',
  },
  '04005': {
    zip: '04005',
    city: 'Biddeford',
    state: 'ME',
    latitude: '43.474441',
    longitude: '-70.452039',
    timeZoneId: 'America/New_York',
  },
  '04006': {
    zip: '04006',
    city: 'Biddeford Pool',
    state: 'ME',
    latitude: '43.4925',
    longitude: '-70.453889',
    timeZoneId: 'America/New_York',
  },
  '04007': {
    zip: '04007',
    city: 'Biddeford',
    state: 'ME',
    latitude: '43.465925',
    longitude: '-70.440735',
    timeZoneId: 'America/New_York',
  },
  '04008': {
    zip: '04008',
    city: 'Bowdoinham',
    state: 'ME',
    latitude: '44.012016',
    longitude: '-69.889526',
    timeZoneId: 'America/New_York',
  },
  '04009': {
    zip: '04009',
    city: 'Bridgton',
    state: 'ME',
    latitude: '44.056766',
    longitude: '-70.719822',
    timeZoneId: 'America/New_York',
  },
  '04010': {
    zip: '04010',
    city: 'Brownfield',
    state: 'ME',
    latitude: '44.184244',
    longitude: '-70.743656',
    timeZoneId: 'America/New_York',
  },
  '04011': {
    zip: '04011',
    city: 'Brunswick',
    state: 'ME',
    latitude: '43.905571',
    longitude: '-69.964566',
    timeZoneId: 'America/New_York',
  },
  '04013': {
    zip: '04013',
    city: 'Bustins Island',
    state: 'ME',
    latitude: '43.798612',
    longitude: '-70.074648',
    timeZoneId: 'America/New_York',
  },
  '04014': {
    zip: '04014',
    city: 'Cape Porpoise',
    state: 'ME',
    latitude: '43.408905',
    longitude: '-70.47573',
    timeZoneId: 'America/New_York',
  },
  '04015': {
    zip: '04015',
    city: 'Casco',
    state: 'ME',
    latitude: '43.987101',
    longitude: '-70.539816',
    timeZoneId: 'America/New_York',
  },
  '04016': {
    zip: '04016',
    city: 'Center Lovell',
    state: 'ME',
    latitude: '44.172349',
    longitude: '-70.873151',
    timeZoneId: 'America/New_York',
  },
  '04017': {
    zip: '04017',
    city: 'Chebeague Island',
    state: 'ME',
    latitude: '43.733941',
    longitude: '-70.127652',
    timeZoneId: 'America/New_York',
  },
  '04019': {
    zip: '04019',
    city: 'Cliff Island',
    state: 'ME',
    latitude: '43.699789',
    longitude: '-70.100719',
    timeZoneId: 'America/New_York',
  },
  '04020': {
    zip: '04020',
    city: 'Cornish',
    state: 'ME',
    latitude: '43.802916',
    longitude: '-70.810084',
    timeZoneId: 'America/New_York',
  },
  '04021': {
    zip: '04021',
    city: 'Cumberland Center',
    state: 'ME',
    latitude: '43.81153',
    longitude: '-70.26328',
    timeZoneId: 'America/New_York',
  },
  '04022': {
    zip: '04022',
    city: 'Denmark',
    state: 'ME',
    latitude: '43.976456',
    longitude: '-70.798917',
    timeZoneId: 'America/New_York',
  },
  '04024': {
    zip: '04024',
    city: 'East Baldwin',
    state: 'ME',
    latitude: '43.8',
    longitude: '-70.666667',
    timeZoneId: 'America/New_York',
  },
  '04027': {
    zip: '04027',
    city: 'Lebanon',
    state: 'ME',
    latitude: '43.42962',
    longitude: '-70.9065',
    timeZoneId: 'America/New_York',
  },
  '04028': {
    zip: '04028',
    city: 'East Parsonsfield',
    state: 'ME',
    latitude: '43.76527',
    longitude: '-70.917943',
    timeZoneId: 'America/New_York',
  },
  '04029': {
    zip: '04029',
    city: 'Sebago',
    state: 'ME',
    latitude: '43.888056',
    longitude: '-70.6325',
    timeZoneId: 'America/New_York',
  },
  '04030': {
    zip: '04030',
    city: 'East Waterboro',
    state: 'ME',
    latitude: '43.581317',
    longitude: '-70.709279',
    timeZoneId: 'America/New_York',
  },
  '04032': {
    zip: '04032',
    city: 'Freeport',
    state: 'ME',
    latitude: '43.853954',
    longitude: '-70.10242',
    timeZoneId: 'America/New_York',
  },
  '04033': {
    zip: '04033',
    city: 'Freeport',
    state: 'ME',
    latitude: '43.856471',
    longitude: '-70.102326',
    timeZoneId: 'America/New_York',
  },
  '04034': {
    zip: '04034',
    city: 'Freeport',
    state: 'ME',
    latitude: '43.856581',
    longitude: '-70.102589',
    timeZoneId: 'America/New_York',
  },
  '04037': {
    zip: '04037',
    city: 'Fryeburg',
    state: 'ME',
    latitude: '44.014772',
    longitude: '-70.967645',
    timeZoneId: 'America/New_York',
  },
  '04038': {
    zip: '04038',
    city: 'Gorham',
    state: 'ME',
    latitude: '43.698538',
    longitude: '-70.456236',
    timeZoneId: 'America/New_York',
  },
  '04039': {
    zip: '04039',
    city: 'Gray',
    state: 'ME',
    latitude: '43.883914',
    longitude: '-70.346256',
    timeZoneId: 'America/New_York',
  },
  '04040': {
    zip: '04040',
    city: 'Harrison',
    state: 'ME',
    latitude: '44.104958',
    longitude: '-70.668459',
    timeZoneId: 'America/New_York',
  },
  '04041': {
    zip: '04041',
    city: 'Hiram',
    state: 'ME',
    latitude: '43.891094',
    longitude: '-70.841258',
    timeZoneId: 'America/New_York',
  },
  '04042': {
    zip: '04042',
    city: 'Hollis Center',
    state: 'ME',
    latitude: '43.630376',
    longitude: '-70.640245',
    timeZoneId: 'America/New_York',
  },
  '04043': {
    zip: '04043',
    city: 'Kennebunk',
    state: 'ME',
    latitude: '43.373725',
    longitude: '-70.528249',
    timeZoneId: 'America/New_York',
  },
  '04046': {
    zip: '04046',
    city: 'Kennebunkport',
    state: 'ME',
    latitude: '43.393701',
    longitude: '-70.45232',
    timeZoneId: 'America/New_York',
  },
  '04047': {
    zip: '04047',
    city: 'Parsonsfield',
    state: 'ME',
    latitude: '43.802761',
    longitude: '-70.891033',
    timeZoneId: 'America/New_York',
  },
  '04048': {
    zip: '04048',
    city: 'Limerick',
    state: 'ME',
    latitude: '43.680355',
    longitude: '-70.756935',
    timeZoneId: 'America/New_York',
  },
  '04049': {
    zip: '04049',
    city: 'Limington',
    state: 'ME',
    latitude: '43.723689',
    longitude: '-70.67775',
    timeZoneId: 'America/New_York',
  },
  '04050': {
    zip: '04050',
    city: 'Long Island',
    state: 'ME',
    latitude: '43.688835',
    longitude: '-70.158644',
    timeZoneId: 'America/New_York',
  },
  '04051': {
    zip: '04051',
    city: 'Lovell',
    state: 'ME',
    latitude: '44.192343',
    longitude: '-70.951093',
    timeZoneId: 'America/New_York',
  },
  '04054': {
    zip: '04054',
    city: 'Moody',
    state: 'ME',
    latitude: '43.330629',
    longitude: '-70.605751',
    timeZoneId: 'America/New_York',
  },
  '04055': {
    zip: '04055',
    city: 'Naples',
    state: 'ME',
    latitude: '43.943882',
    longitude: '-70.591793',
    timeZoneId: 'America/New_York',
  },
  '04056': {
    zip: '04056',
    city: 'Newfield',
    state: 'ME',
    latitude: '43.647796',
    longitude: '-70.920037',
    timeZoneId: 'America/New_York',
  },
  '04057': {
    zip: '04057',
    city: 'North Bridgton',
    state: 'ME',
    latitude: '44.042997',
    longitude: '-70.737469',
    timeZoneId: 'America/New_York',
  },
  '04061': {
    zip: '04061',
    city: 'North Waterboro',
    state: 'ME',
    latitude: '43.652363',
    longitude: '-70.725533',
    timeZoneId: 'America/New_York',
  },
  '04062': {
    zip: '04062',
    city: 'Windham',
    state: 'ME',
    latitude: '43.808062',
    longitude: '-70.420871',
    timeZoneId: 'America/New_York',
  },
  '04063': {
    zip: '04063',
    city: 'Ocean Park',
    state: 'ME',
    latitude: '43.517222',
    longitude: '-70.378056',
    timeZoneId: 'America/New_York',
  },
  '04064': {
    zip: '04064',
    city: 'Old Orchard Beach',
    state: 'ME',
    latitude: '43.5264',
    longitude: '-70.39218',
    timeZoneId: 'America/New_York',
  },
  '04066': {
    zip: '04066',
    city: 'Orrs Island',
    state: 'ME',
    latitude: '43.775368',
    longitude: '-69.956217',
    timeZoneId: 'America/New_York',
  },
  '04068': {
    zip: '04068',
    city: 'Porter',
    state: 'ME',
    latitude: '43.84179',
    longitude: '-70.94396',
    timeZoneId: 'America/New_York',
  },
  '04069': {
    zip: '04069',
    city: 'Pownal',
    state: 'ME',
    latitude: '43.894744',
    longitude: '-70.215812',
    timeZoneId: 'America/New_York',
  },
  '04070': {
    zip: '04070',
    city: 'Scarborough',
    state: 'ME',
    latitude: '43.570278',
    longitude: '-70.388333',
    timeZoneId: 'America/New_York',
  },
  '04071': {
    zip: '04071',
    city: 'Raymond',
    state: 'ME',
    latitude: '43.914064',
    longitude: '-70.44424',
    timeZoneId: 'America/New_York',
  },
  '04072': {
    zip: '04072',
    city: 'Saco',
    state: 'ME',
    latitude: '43.53816',
    longitude: '-70.46934',
    timeZoneId: 'America/New_York',
  },
  '04073': {
    zip: '04073',
    city: 'Sanford',
    state: 'ME',
    latitude: '43.436687',
    longitude: '-70.761335',
    timeZoneId: 'America/New_York',
  },
  '04074': {
    zip: '04074',
    city: 'Scarborough',
    state: 'ME',
    latitude: '43.58496',
    longitude: '-70.37064',
    timeZoneId: 'America/New_York',
  },
  '04076': {
    zip: '04076',
    city: 'Shapleigh',
    state: 'ME',
    latitude: '43.517658',
    longitude: '-70.853521',
    timeZoneId: 'America/New_York',
  },
  '04077': {
    zip: '04077',
    city: 'South Casco',
    state: 'ME',
    latitude: '43.871729',
    longitude: '-70.514006',
    timeZoneId: 'America/New_York',
  },
  '04078': {
    zip: '04078',
    city: 'South Freeport',
    state: 'ME',
    latitude: '43.82136',
    longitude: '-70.111614',
    timeZoneId: 'America/New_York',
  },
  '04079': {
    zip: '04079',
    city: 'Harpswell',
    state: 'ME',
    latitude: '43.82596',
    longitude: '-69.953763',
    timeZoneId: 'America/New_York',
  },
  '04082': {
    zip: '04082',
    city: 'South Windham',
    state: 'ME',
    latitude: '43.733601',
    longitude: '-70.441554',
    timeZoneId: 'America/New_York',
  },
  '04083': {
    zip: '04083',
    city: 'Springvale',
    state: 'ME',
    latitude: '43.464074',
    longitude: '-70.795813',
    timeZoneId: 'America/New_York',
  },
  '04084': {
    zip: '04084',
    city: 'Standish',
    state: 'ME',
    latitude: '43.744118',
    longitude: '-70.411562',
    timeZoneId: 'America/New_York',
  },
  '04085': {
    zip: '04085',
    city: 'Steep Falls',
    state: 'ME',
    latitude: '43.747838',
    longitude: '-70.639534',
    timeZoneId: 'America/New_York',
  },
  '04086': {
    zip: '04086',
    city: 'Topsham',
    state: 'ME',
    latitude: '43.95594',
    longitude: '-69.98634',
    timeZoneId: 'America/New_York',
  },
  '04087': {
    zip: '04087',
    city: 'Waterboro',
    state: 'ME',
    latitude: '43.555304',
    longitude: '-70.741806',
    timeZoneId: 'America/New_York',
  },
  '04088': {
    zip: '04088',
    city: 'Waterford',
    state: 'ME',
    latitude: '44.216087',
    longitude: '-70.474619',
    timeZoneId: 'America/New_York',
  },
  '04090': {
    zip: '04090',
    city: 'Wells',
    state: 'ME',
    latitude: '43.303207',
    longitude: '-70.57537',
    timeZoneId: 'America/New_York',
  },
  '04091': {
    zip: '04091',
    city: 'West Baldwin',
    state: 'ME',
    latitude: '43.831044',
    longitude: '-70.745977',
    timeZoneId: 'America/New_York',
  },
  '04092': {
    zip: '04092',
    city: 'Westbrook',
    state: 'ME',
    latitude: '43.684846',
    longitude: '-70.357119',
    timeZoneId: 'America/New_York',
  },
  '04093': {
    zip: '04093',
    city: 'Buxton',
    state: 'ME',
    latitude: '43.650262',
    longitude: '-70.620203',
    timeZoneId: 'America/New_York',
  },
  '04094': {
    zip: '04094',
    city: 'West Kennebunk',
    state: 'ME',
    latitude: '43.426443',
    longitude: '-70.630455',
    timeZoneId: 'America/New_York',
  },
  '04095': {
    zip: '04095',
    city: 'West Newfield',
    state: 'ME',
    latitude: '43.674167',
    longitude: '-70.911389',
    timeZoneId: 'America/New_York',
  },
  '04096': {
    zip: '04096',
    city: 'Yarmouth',
    state: 'ME',
    latitude: '43.796417',
    longitude: '-70.180511',
    timeZoneId: 'America/New_York',
  },
  '04097': {
    zip: '04097',
    city: 'North Yarmouth',
    state: 'ME',
    latitude: '43.845033',
    longitude: '-70.235599',
    timeZoneId: 'America/New_York',
  },
  '04098': {
    zip: '04098',
    city: 'Westbrook',
    state: 'ME',
    latitude: '43.675955',
    longitude: '-70.365718',
    timeZoneId: 'America/New_York',
  },
  '04101': {
    zip: '04101',
    city: 'Portland',
    state: 'ME',
    latitude: '43.662471',
    longitude: '-70.261426',
    timeZoneId: 'America/New_York',
  },
  '04102': {
    zip: '04102',
    city: 'Portland',
    state: 'ME',
    latitude: '43.663962',
    longitude: '-70.291819',
    timeZoneId: 'America/New_York',
  },
  '04103': {
    zip: '04103',
    city: 'Portland',
    state: 'ME',
    latitude: '43.686849',
    longitude: '-70.291333',
    timeZoneId: 'America/New_York',
  },
  '04104': {
    zip: '04104',
    city: 'Portland',
    state: 'ME',
    latitude: '43.660577',
    longitude: '-70.262378',
    timeZoneId: 'America/New_York',
  },
  '04105': {
    zip: '04105',
    city: 'Falmouth',
    state: 'ME',
    latitude: '43.743978',
    longitude: '-70.281854',
    timeZoneId: 'America/New_York',
  },
  '04106': {
    zip: '04106',
    city: 'South Portland',
    state: 'ME',
    latitude: '43.620751',
    longitude: '-70.291117',
    timeZoneId: 'America/New_York',
  },
  '04107': {
    zip: '04107',
    city: 'Cape Elizabeth',
    state: 'ME',
    latitude: '43.610918',
    longitude: '-70.232964',
    timeZoneId: 'America/New_York',
  },
  '04108': {
    zip: '04108',
    city: 'Peaks Island',
    state: 'ME',
    latitude: '43.663288',
    longitude: '-70.190829',
    timeZoneId: 'America/New_York',
  },
  '04109': {
    zip: '04109',
    city: 'Portland',
    state: 'ME',
    latitude: '43.67851',
    longitude: '-70.20168',
    timeZoneId: 'America/New_York',
  },
  '04110': {
    zip: '04110',
    city: 'Cumberland Foreside',
    state: 'ME',
    latitude: '43.759193',
    longitude: '-70.202112',
    timeZoneId: 'America/New_York',
  },
  '04112': {
    zip: '04112',
    city: 'Portland',
    state: 'ME',
    latitude: '43.666707',
    longitude: '-70.20581',
    timeZoneId: 'America/New_York',
  },
  '04116': {
    zip: '04116',
    city: 'South Portland',
    state: 'ME',
    latitude: '43.659977',
    longitude: '-70.267197',
    timeZoneId: 'America/New_York',
  },
  '04122': {
    zip: '04122',
    city: 'Portland',
    state: 'ME',
    latitude: '43.65331',
    longitude: '-70.331898',
    timeZoneId: 'America/New_York',
  },
  '04123': {
    zip: '04123',
    city: 'Portland',
    state: 'ME',
    latitude: '43.659527',
    longitude: '-70.306',
    timeZoneId: 'America/New_York',
  },
  '04124': {
    zip: '04124',
    city: 'Portland',
    state: 'ME',
    latitude: '43.65207',
    longitude: '-70.330016',
    timeZoneId: 'America/New_York',
  },
  '04210': {
    zip: '04210',
    city: 'Auburn',
    state: 'ME',
    latitude: '44.094188',
    longitude: '-70.244511',
    timeZoneId: 'America/New_York',
  },
  '04211': {
    zip: '04211',
    city: 'Auburn',
    state: 'ME',
    latitude: '44.083325',
    longitude: '-70.247116',
    timeZoneId: 'America/New_York',
  },
  '04212': {
    zip: '04212',
    city: 'Auburn',
    state: 'ME',
    latitude: '44.084323',
    longitude: '-70.25126',
    timeZoneId: 'America/New_York',
  },
  '04216': {
    zip: '04216',
    city: 'Andover',
    state: 'ME',
    latitude: '44.744379',
    longitude: '-70.904409',
    timeZoneId: 'America/New_York',
  },
  '04217': {
    zip: '04217',
    city: 'Bethel',
    state: 'ME',
    latitude: '44.447236',
    longitude: '-70.693616',
    timeZoneId: 'America/New_York',
  },
  '04219': {
    zip: '04219',
    city: 'Bryant Pond',
    state: 'ME',
    latitude: '44.383477',
    longitude: '-70.617546',
    timeZoneId: 'America/New_York',
  },
  '04220': {
    zip: '04220',
    city: 'Buckfield',
    state: 'ME',
    latitude: '44.284737',
    longitude: '-70.369237',
    timeZoneId: 'America/New_York',
  },
  '04221': {
    zip: '04221',
    city: 'Canton',
    state: 'ME',
    latitude: '44.41691',
    longitude: '-70.322598',
    timeZoneId: 'America/New_York',
  },
  '04222': {
    zip: '04222',
    city: 'Durham',
    state: 'ME',
    latitude: '43.931777',
    longitude: '-70.14703',
    timeZoneId: 'America/New_York',
  },
  '04223': {
    zip: '04223',
    city: 'Danville',
    state: 'ME',
    latitude: '44.030305',
    longitude: '-70.274326',
    timeZoneId: 'America/New_York',
  },
  '04224': {
    zip: '04224',
    city: 'Dixfield',
    state: 'ME',
    latitude: '44.542585',
    longitude: '-70.453768',
    timeZoneId: 'America/New_York',
  },
  '04225': {
    zip: '04225',
    city: 'Dryden',
    state: 'ME',
    latitude: '44.584996',
    longitude: '-70.215655',
    timeZoneId: 'America/New_York',
  },
  '04226': {
    zip: '04226',
    city: 'East Andover',
    state: 'ME',
    latitude: '44.605912',
    longitude: '-70.728406',
    timeZoneId: 'America/New_York',
  },
  '04227': {
    zip: '04227',
    city: 'East Dixfield',
    state: 'ME',
    latitude: '44.595169',
    longitude: '-70.303575',
    timeZoneId: 'America/New_York',
  },
  '04228': {
    zip: '04228',
    city: 'East Livermore',
    state: 'ME',
    latitude: '44.400255',
    longitude: '-70.12824',
    timeZoneId: 'America/New_York',
  },
  '04230': {
    zip: '04230',
    city: 'East Poland',
    state: 'ME',
    latitude: '44.025349',
    longitude: '-70.386493',
    timeZoneId: 'America/New_York',
  },
  '04231': {
    zip: '04231',
    city: 'Stoneham',
    state: 'ME',
    latitude: '44.25298',
    longitude: '-70.886744',
    timeZoneId: 'America/New_York',
  },
  '04234': {
    zip: '04234',
    city: 'East Wilton',
    state: 'ME',
    latitude: '44.592447',
    longitude: '-70.304079',
    timeZoneId: 'America/New_York',
  },
  '04236': {
    zip: '04236',
    city: 'Greene',
    state: 'ME',
    latitude: '44.190377',
    longitude: '-70.144586',
    timeZoneId: 'America/New_York',
  },
  '04237': {
    zip: '04237',
    city: 'Hanover',
    state: 'ME',
    latitude: '44.49712',
    longitude: '-70.72684',
    timeZoneId: 'America/New_York',
  },
  '04238': {
    zip: '04238',
    city: 'Hebron',
    state: 'ME',
    latitude: '44.213315',
    longitude: '-70.379196',
    timeZoneId: 'America/New_York',
  },
  '04239': {
    zip: '04239',
    city: 'Jay',
    state: 'ME',
    latitude: '44.481587',
    longitude: '-70.191149',
    timeZoneId: 'America/New_York',
  },
  '04240': {
    zip: '04240',
    city: 'Lewiston',
    state: 'ME',
    latitude: '44.097005',
    longitude: '-70.186774',
    timeZoneId: 'America/New_York',
  },
  '04241': {
    zip: '04241',
    city: 'Lewiston',
    state: 'ME',
    latitude: '44.08585',
    longitude: '-70.170085',
    timeZoneId: 'America/New_York',
  },
  '04243': {
    zip: '04243',
    city: 'Lewiston',
    state: 'ME',
    latitude: '44.092083',
    longitude: '-70.175937',
    timeZoneId: 'America/New_York',
  },
  '04250': {
    zip: '04250',
    city: 'Lisbon',
    state: 'ME',
    latitude: '44.022494',
    longitude: '-70.108463',
    timeZoneId: 'America/New_York',
  },
  '04252': {
    zip: '04252',
    city: 'Lisbon Falls',
    state: 'ME',
    latitude: '44.007098',
    longitude: '-70.061746',
    timeZoneId: 'America/New_York',
  },
  '04253': {
    zip: '04253',
    city: 'Livermore',
    state: 'ME',
    latitude: '44.416685',
    longitude: '-70.198765',
    timeZoneId: 'America/New_York',
  },
  '04254': {
    zip: '04254',
    city: 'Livermore Falls',
    state: 'ME',
    latitude: '44.472043',
    longitude: '-70.184517',
    timeZoneId: 'America/New_York',
  },
  '04255': {
    zip: '04255',
    city: 'Greenwood',
    state: 'ME',
    latitude: '44.320167',
    longitude: '-70.633081',
    timeZoneId: 'America/New_York',
  },
  '04256': {
    zip: '04256',
    city: 'Mechanic Falls',
    state: 'ME',
    latitude: '44.104531',
    longitude: '-70.370014',
    timeZoneId: 'America/New_York',
  },
  '04257': {
    zip: '04257',
    city: 'Mexico',
    state: 'ME',
    latitude: '44.555597',
    longitude: '-70.539231',
    timeZoneId: 'America/New_York',
  },
  '04258': {
    zip: '04258',
    city: 'Minot',
    state: 'ME',
    latitude: '44.061205',
    longitude: '-70.415162',
    timeZoneId: 'America/New_York',
  },
  '04259': {
    zip: '04259',
    city: 'Monmouth',
    state: 'ME',
    latitude: '44.228788',
    longitude: '-70.06371',
    timeZoneId: 'America/New_York',
  },
  '04260': {
    zip: '04260',
    city: 'New Gloucester',
    state: 'ME',
    latitude: '43.97358',
    longitude: '-70.32534',
    timeZoneId: 'America/New_York',
  },
  '04261': {
    zip: '04261',
    city: 'Newry',
    state: 'ME',
    latitude: '44.570737',
    longitude: '-70.88259',
    timeZoneId: 'America/New_York',
  },
  '04262': {
    zip: '04262',
    city: 'North Jay',
    state: 'ME',
    latitude: '44.523739',
    longitude: '-70.210122',
    timeZoneId: 'America/New_York',
  },
  '04263': {
    zip: '04263',
    city: 'Leeds',
    state: 'ME',
    latitude: '44.296106',
    longitude: '-70.129879',
    timeZoneId: 'America/New_York',
  },
  '04265': {
    zip: '04265',
    city: 'North Monmouth',
    state: 'ME',
    latitude: '44.270876',
    longitude: '-70.052031',
    timeZoneId: 'America/New_York',
  },
  '04266': {
    zip: '04266',
    city: 'North Turner',
    state: 'ME',
    latitude: '44.329732',
    longitude: '-70.257159',
    timeZoneId: 'America/New_York',
  },
  '04267': {
    zip: '04267',
    city: 'North Waterford',
    state: 'ME',
    latitude: '44.207261',
    longitude: '-70.716893',
    timeZoneId: 'America/New_York',
  },
  '04268': {
    zip: '04268',
    city: 'Norway',
    state: 'ME',
    latitude: '44.212637',
    longitude: '-70.538351',
    timeZoneId: 'America/New_York',
  },
  '04270': {
    zip: '04270',
    city: 'Oxford',
    state: 'ME',
    latitude: '44.09434',
    longitude: '-70.531443',
    timeZoneId: 'America/New_York',
  },
  '04271': {
    zip: '04271',
    city: 'Paris',
    state: 'ME',
    latitude: '44.220906',
    longitude: '-70.478319',
    timeZoneId: 'America/New_York',
  },
  '04274': {
    zip: '04274',
    city: 'Poland',
    state: 'ME',
    latitude: '44.0187',
    longitude: '-70.371',
    timeZoneId: 'America/New_York',
  },
  '04275': {
    zip: '04275',
    city: 'Roxbury',
    state: 'ME',
    latitude: '44.688667',
    longitude: '-70.639514',
    timeZoneId: 'America/New_York',
  },
  '04276': {
    zip: '04276',
    city: 'Rumford',
    state: 'ME',
    latitude: '44.546376',
    longitude: '-70.554444',
    timeZoneId: 'America/New_York',
  },
  '04280': {
    zip: '04280',
    city: 'Sabattus',
    state: 'ME',
    latitude: '44.112074',
    longitude: '-70.100318',
    timeZoneId: 'America/New_York',
  },
  '04281': {
    zip: '04281',
    city: 'South Paris',
    state: 'ME',
    latitude: '44.226244',
    longitude: '-70.517516',
    timeZoneId: 'America/New_York',
  },
  '04282': {
    zip: '04282',
    city: 'Turner',
    state: 'ME',
    latitude: '44.261042',
    longitude: '-70.189316',
    timeZoneId: 'America/New_York',
  },
  '04284': {
    zip: '04284',
    city: 'Wayne',
    state: 'ME',
    latitude: '44.354609',
    longitude: '-70.078791',
    timeZoneId: 'America/New_York',
  },
  '04285': {
    zip: '04285',
    city: 'Weld',
    state: 'ME',
    latitude: '44.707678',
    longitude: '-70.45341',
    timeZoneId: 'America/New_York',
  },
  '04286': {
    zip: '04286',
    city: 'West Bethel',
    state: 'ME',
    latitude: '44.418541',
    longitude: '-70.844496',
    timeZoneId: 'America/New_York',
  },
  '04287': {
    zip: '04287',
    city: 'Bowdoin',
    state: 'ME',
    latitude: '43.985129',
    longitude: '-70.094212',
    timeZoneId: 'America/New_York',
  },
  '04288': {
    zip: '04288',
    city: 'West Minot',
    state: 'ME',
    latitude: '44.056988',
    longitude: '-70.413466',
    timeZoneId: 'America/New_York',
  },
  '04289': {
    zip: '04289',
    city: 'West Paris',
    state: 'ME',
    latitude: '44.33232',
    longitude: '-70.59732',
    timeZoneId: 'America/New_York',
  },
  '04290': {
    zip: '04290',
    city: 'Peru',
    state: 'ME',
    latitude: '44.474094',
    longitude: '-70.449296',
    timeZoneId: 'America/New_York',
  },
  '04291': {
    zip: '04291',
    city: 'West Poland',
    state: 'ME',
    latitude: '44.04066',
    longitude: '-70.444596',
    timeZoneId: 'America/New_York',
  },
  '04292': {
    zip: '04292',
    city: 'Sumner',
    state: 'ME',
    latitude: '44.374681',
    longitude: '-70.48485',
    timeZoneId: 'America/New_York',
  },
  '04294': {
    zip: '04294',
    city: 'Wilton',
    state: 'ME',
    latitude: '44.59624',
    longitude: '-70.226079',
    timeZoneId: 'America/New_York',
  },
  '04330': {
    zip: '04330',
    city: 'Augusta',
    state: 'ME',
    latitude: '44.353599',
    longitude: '-69.731361',
    timeZoneId: 'America/New_York',
  },
  '04332': {
    zip: '04332',
    city: 'Augusta',
    state: 'ME',
    latitude: '44.335362',
    longitude: '-69.744565',
    timeZoneId: 'America/New_York',
  },
  '04333': {
    zip: '04333',
    city: 'Augusta',
    state: 'ME',
    latitude: '44.309517',
    longitude: '-69.783518',
    timeZoneId: 'America/New_York',
  },
  '04336': {
    zip: '04336',
    city: 'Augusta',
    state: 'ME',
    latitude: '44.3348',
    longitude: '-69.74285',
    timeZoneId: 'America/New_York',
  },
  '04338': {
    zip: '04338',
    city: 'Augusta',
    state: 'ME',
    latitude: '44.319709',
    longitude: '-69.776569',
    timeZoneId: 'America/New_York',
  },
  '04341': {
    zip: '04341',
    city: 'Coopers Mills',
    state: 'ME',
    latitude: '44.274799',
    longitude: '-69.495465',
    timeZoneId: 'America/New_York',
  },
  '04342': {
    zip: '04342',
    city: 'Dresden',
    state: 'ME',
    latitude: '44.079573',
    longitude: '-69.736354',
    timeZoneId: 'America/New_York',
  },
  '04343': {
    zip: '04343',
    city: 'East Winthrop',
    state: 'ME',
    latitude: '44.313439',
    longitude: '-69.978557',
    timeZoneId: 'America/New_York',
  },
  '04344': {
    zip: '04344',
    city: 'Farmingdale',
    state: 'ME',
    latitude: '44.254156',
    longitude: '-69.782143',
    timeZoneId: 'America/New_York',
  },
  '04345': {
    zip: '04345',
    city: 'Gardiner',
    state: 'ME',
    latitude: '44.231489',
    longitude: '-69.779004',
    timeZoneId: 'America/New_York',
  },
  '04346': {
    zip: '04346',
    city: 'Randolph',
    state: 'ME',
    latitude: '44.23044',
    longitude: '-69.76446',
    timeZoneId: 'America/New_York',
  },
  '04347': {
    zip: '04347',
    city: 'Hallowell',
    state: 'ME',
    latitude: '44.28199',
    longitude: '-69.790056',
    timeZoneId: 'America/New_York',
  },
  '04348': {
    zip: '04348',
    city: 'Jefferson',
    state: 'ME',
    latitude: '44.197758',
    longitude: '-69.471419',
    timeZoneId: 'America/New_York',
  },
  '04349': {
    zip: '04349',
    city: 'Kents Hill',
    state: 'ME',
    latitude: '44.436832',
    longitude: '-70.070204',
    timeZoneId: 'America/New_York',
  },
  '04350': {
    zip: '04350',
    city: 'Litchfield',
    state: 'ME',
    latitude: '44.158538',
    longitude: '-69.950907',
    timeZoneId: 'America/New_York',
  },
  '04351': {
    zip: '04351',
    city: 'Manchester',
    state: 'ME',
    latitude: '44.32971',
    longitude: '-69.870346',
    timeZoneId: 'America/New_York',
  },
  '04352': {
    zip: '04352',
    city: 'Mount Vernon',
    state: 'ME',
    latitude: '44.50866',
    longitude: '-69.990496',
    timeZoneId: 'America/New_York',
  },
  '04353': {
    zip: '04353',
    city: 'Whitefield',
    state: 'ME',
    latitude: '44.20198',
    longitude: '-69.612352',
    timeZoneId: 'America/New_York',
  },
  '04354': {
    zip: '04354',
    city: 'Palermo',
    state: 'ME',
    latitude: '44.382089',
    longitude: '-69.430742',
    timeZoneId: 'America/New_York',
  },
  '04355': {
    zip: '04355',
    city: 'Readfield',
    state: 'ME',
    latitude: '44.406716',
    longitude: '-69.939412',
    timeZoneId: 'America/New_York',
  },
  '04357': {
    zip: '04357',
    city: 'Richmond',
    state: 'ME',
    latitude: '44.086454',
    longitude: '-69.801005',
    timeZoneId: 'America/New_York',
  },
  '04358': {
    zip: '04358',
    city: 'South China',
    state: 'ME',
    latitude: '44.398705',
    longitude: '-69.582961',
    timeZoneId: 'America/New_York',
  },
  '04359': {
    zip: '04359',
    city: 'South Gardiner',
    state: 'ME',
    latitude: '44.202673',
    longitude: '-69.781345',
    timeZoneId: 'America/New_York',
  },
  '04360': {
    zip: '04360',
    city: 'Vienna',
    state: 'ME',
    latitude: '44.535941',
    longitude: '-69.982382',
    timeZoneId: 'America/New_York',
  },
  '04363': {
    zip: '04363',
    city: 'Windsor',
    state: 'ME',
    latitude: '44.306838',
    longitude: '-69.572907',
    timeZoneId: 'America/New_York',
  },
  '04364': {
    zip: '04364',
    city: 'Winthrop',
    state: 'ME',
    latitude: '44.30706',
    longitude: '-69.972922',
    timeZoneId: 'America/New_York',
  },
  '04401': {
    zip: '04401',
    city: 'Bangor',
    state: 'ME',
    latitude: '44.814135',
    longitude: '-68.778574',
    timeZoneId: 'America/New_York',
  },
  '04402': {
    zip: '04402',
    city: 'Bangor',
    state: 'ME',
    latitude: '44.832972',
    longitude: '-68.789265',
    timeZoneId: 'America/New_York',
  },
  '04406': {
    zip: '04406',
    city: 'Abbot',
    state: 'ME',
    latitude: '45.30685',
    longitude: '-69.558284',
    timeZoneId: 'America/New_York',
  },
  '04408': {
    zip: '04408',
    city: 'Aurora',
    state: 'ME',
    latitude: '44.92368',
    longitude: '-68.335427',
    timeZoneId: 'America/New_York',
  },
  '04410': {
    zip: '04410',
    city: 'Bradford',
    state: 'ME',
    latitude: '45.090445',
    longitude: '-68.907992',
    timeZoneId: 'America/New_York',
  },
  '04411': {
    zip: '04411',
    city: 'Bradley',
    state: 'ME',
    latitude: '44.910032',
    longitude: '-68.624203',
    timeZoneId: 'America/New_York',
  },
  '04412': {
    zip: '04412',
    city: 'Brewer',
    state: 'ME',
    latitude: '44.785715',
    longitude: '-68.754256',
    timeZoneId: 'America/New_York',
  },
  '04413': {
    zip: '04413',
    city: 'Brookton',
    state: 'ME',
    latitude: '45.57931',
    longitude: '-67.727269',
    timeZoneId: 'America/New_York',
  },
  '04414': {
    zip: '04414',
    city: 'Brownville',
    state: 'ME',
    latitude: '45.307433',
    longitude: '-69.030278',
    timeZoneId: 'America/New_York',
  },
  '04415': {
    zip: '04415',
    city: 'Brownville Junction',
    state: 'ME',
    latitude: '45.349887',
    longitude: '-69.058546',
    timeZoneId: 'America/New_York',
  },
  '04416': {
    zip: '04416',
    city: 'Bucksport',
    state: 'ME',
    latitude: '44.575891',
    longitude: '-68.787997',
    timeZoneId: 'America/New_York',
  },
  '04417': {
    zip: '04417',
    city: 'Burlington',
    state: 'ME',
    latitude: '45.179999',
    longitude: '-68.440203',
    timeZoneId: 'America/New_York',
  },
  '04418': {
    zip: '04418',
    city: 'Greenbush',
    state: 'ME',
    latitude: '45.079164',
    longitude: '-68.592463',
    timeZoneId: 'America/New_York',
  },
  '04419': {
    zip: '04419',
    city: 'Carmel',
    state: 'ME',
    latitude: '44.80071',
    longitude: '-69.009422',
    timeZoneId: 'America/New_York',
  },
  '04420': {
    zip: '04420',
    city: 'Castine',
    state: 'ME',
    latitude: '44.394667',
    longitude: '-68.789459',
    timeZoneId: 'America/New_York',
  },
  '04421': {
    zip: '04421',
    city: 'Castine',
    state: 'ME',
    latitude: '44.392815',
    longitude: '-68.803969',
    timeZoneId: 'America/New_York',
  },
  '04422': {
    zip: '04422',
    city: 'Charleston',
    state: 'ME',
    latitude: '45.068928',
    longitude: '-69.085771',
    timeZoneId: 'America/New_York',
  },
  '04424': {
    zip: '04424',
    city: 'Danforth',
    state: 'ME',
    latitude: '45.659444',
    longitude: '-67.867287',
    timeZoneId: 'America/New_York',
  },
  '04426': {
    zip: '04426',
    city: 'Dover Foxcroft',
    state: 'ME',
    latitude: '45.18445',
    longitude: '-69.22702',
    timeZoneId: 'America/New_York',
  },
  '04427': {
    zip: '04427',
    city: 'Corinth',
    state: 'ME',
    latitude: '44.983476',
    longitude: '-69.013009',
    timeZoneId: 'America/New_York',
  },
  '04428': {
    zip: '04428',
    city: 'Eddington',
    state: 'ME',
    latitude: '44.800839',
    longitude: '-68.56298',
    timeZoneId: 'America/New_York',
  },
  '04429': {
    zip: '04429',
    city: 'Holden',
    state: 'ME',
    latitude: '44.761927',
    longitude: '-68.642786',
    timeZoneId: 'America/New_York',
  },
  '04430': {
    zip: '04430',
    city: 'East Millinocket',
    state: 'ME',
    latitude: '45.626046',
    longitude: '-68.571668',
    timeZoneId: 'America/New_York',
  },
  '04431': {
    zip: '04431',
    city: 'East Orland',
    state: 'ME',
    latitude: '44.586683',
    longitude: '-68.663947',
    timeZoneId: 'America/New_York',
  },
  '04434': {
    zip: '04434',
    city: 'Etna',
    state: 'ME',
    latitude: '44.784792',
    longitude: '-69.133257',
    timeZoneId: 'America/New_York',
  },
  '04435': {
    zip: '04435',
    city: 'Exeter',
    state: 'ME',
    latitude: '44.965565',
    longitude: '-69.108446',
    timeZoneId: 'America/New_York',
  },
  '04438': {
    zip: '04438',
    city: 'Frankfort',
    state: 'ME',
    latitude: '44.596637',
    longitude: '-68.926678',
    timeZoneId: 'America/New_York',
  },
  '04441': {
    zip: '04441',
    city: 'Greenville',
    state: 'ME',
    latitude: '45.501708',
    longitude: '-69.46645',
    timeZoneId: 'America/New_York',
  },
  '04442': {
    zip: '04442',
    city: 'Greenville Junction',
    state: 'ME',
    latitude: '45.460833',
    longitude: '-69.616667',
    timeZoneId: 'America/New_York',
  },
  '04443': {
    zip: '04443',
    city: 'Guilford',
    state: 'ME',
    latitude: '45.214034',
    longitude: '-69.409404',
    timeZoneId: 'America/New_York',
  },
  '04444': {
    zip: '04444',
    city: 'Hampden',
    state: 'ME',
    latitude: '44.72634',
    longitude: '-68.92854',
    timeZoneId: 'America/New_York',
  },
  '04448': {
    zip: '04448',
    city: 'Howland',
    state: 'ME',
    latitude: '45.240115',
    longitude: '-68.661207',
    timeZoneId: 'America/New_York',
  },
  '04449': {
    zip: '04449',
    city: 'Hudson',
    state: 'ME',
    latitude: '44.944137',
    longitude: '-68.941477',
    timeZoneId: 'America/New_York',
  },
  '04450': {
    zip: '04450',
    city: 'Kenduskeag',
    state: 'ME',
    latitude: '44.919584',
    longitude: '-68.929924',
    timeZoneId: 'America/New_York',
  },
  '04451': {
    zip: '04451',
    city: 'Kingman',
    state: 'ME',
    latitude: '45.604763',
    longitude: '-68.227102',
    timeZoneId: 'America/New_York',
  },
  '04453': {
    zip: '04453',
    city: 'Lagrange',
    state: 'ME',
    latitude: '45.211211',
    longitude: '-68.824655',
    timeZoneId: 'America/New_York',
  },
  '04454': {
    zip: '04454',
    city: 'Lambert Lake',
    state: 'ME',
    latitude: '45.535033',
    longitude: '-67.53278',
    timeZoneId: 'America/New_York',
  },
  '04455': {
    zip: '04455',
    city: 'Lee',
    state: 'ME',
    latitude: '45.368332',
    longitude: '-68.291487',
    timeZoneId: 'America/New_York',
  },
  '04456': {
    zip: '04456',
    city: 'Levant',
    state: 'ME',
    latitude: '44.87359',
    longitude: '-68.943452',
    timeZoneId: 'America/New_York',
  },
  '04457': {
    zip: '04457',
    city: 'Lincoln',
    state: 'ME',
    latitude: '45.363403',
    longitude: '-68.50349',
    timeZoneId: 'America/New_York',
  },
  '04459': {
    zip: '04459',
    city: 'Mattawamkeag',
    state: 'ME',
    latitude: '45.544499',
    longitude: '-68.31079',
    timeZoneId: 'America/New_York',
  },
  '04460': {
    zip: '04460',
    city: 'Medway',
    state: 'ME',
    latitude: '45.61795',
    longitude: '-68.497973',
    timeZoneId: 'America/New_York',
  },
  '04461': {
    zip: '04461',
    city: 'Milford',
    state: 'ME',
    latitude: '44.9458',
    longitude: '-68.635601',
    timeZoneId: 'America/New_York',
  },
  '04462': {
    zip: '04462',
    city: 'Millinocket',
    state: 'ME',
    latitude: '45.65465',
    longitude: '-68.702349',
    timeZoneId: 'America/New_York',
  },
  '04463': {
    zip: '04463',
    city: 'Milo',
    state: 'ME',
    latitude: '45.24863',
    longitude: '-68.986198',
    timeZoneId: 'America/New_York',
  },
  '04464': {
    zip: '04464',
    city: 'Monson',
    state: 'ME',
    latitude: '45.302859',
    longitude: '-69.480734',
    timeZoneId: 'America/New_York',
  },
  '04468': {
    zip: '04468',
    city: 'Old Town',
    state: 'ME',
    latitude: '44.930939',
    longitude: '-68.654151',
    timeZoneId: 'America/New_York',
  },
  '04469': {
    zip: '04469',
    city: 'Orono',
    state: 'ME',
    latitude: '44.901113',
    longitude: '-68.672733',
    timeZoneId: 'America/New_York',
  },
  '04471': {
    zip: '04471',
    city: 'Orient',
    state: 'ME',
    latitude: '45.890612',
    longitude: '-67.847255',
    timeZoneId: 'America/New_York',
  },
  '04472': {
    zip: '04472',
    city: 'Orland',
    state: 'ME',
    latitude: '44.535543',
    longitude: '-68.744414',
    timeZoneId: 'America/New_York',
  },
  '04473': {
    zip: '04473',
    city: 'Orono',
    state: 'ME',
    latitude: '44.888696',
    longitude: '-68.676848',
    timeZoneId: 'America/New_York',
  },
  '04474': {
    zip: '04474',
    city: 'Orrington',
    state: 'ME',
    latitude: '44.715764',
    longitude: '-68.777683',
    timeZoneId: 'America/New_York',
  },
  '04475': {
    zip: '04475',
    city: 'Passadumkeag',
    state: 'ME',
    latitude: '45.186271',
    longitude: '-68.592122',
    timeZoneId: 'America/New_York',
  },
  '04476': {
    zip: '04476',
    city: 'Penobscot',
    state: 'ME',
    latitude: '44.468623',
    longitude: '-68.70832',
    timeZoneId: 'America/New_York',
  },
  '04478': {
    zip: '04478',
    city: 'Rockwood',
    state: 'ME',
    latitude: '45.76988',
    longitude: '-69.883299',
    timeZoneId: 'America/New_York',
  },
  '04479': {
    zip: '04479',
    city: 'Sangerville',
    state: 'ME',
    latitude: '45.130069',
    longitude: '-69.309215',
    timeZoneId: 'America/New_York',
  },
  '04481': {
    zip: '04481',
    city: 'Sebec',
    state: 'ME',
    latitude: '45.271763',
    longitude: '-69.117916',
    timeZoneId: 'America/New_York',
  },
  '04485': {
    zip: '04485',
    city: 'Shirley Mills',
    state: 'ME',
    latitude: '45.503846',
    longitude: '-69.469162',
    timeZoneId: 'America/New_York',
  },
  '04487': {
    zip: '04487',
    city: 'Springfield',
    state: 'ME',
    latitude: '45.393599',
    longitude: '-68.101456',
    timeZoneId: 'America/New_York',
  },
  '04488': {
    zip: '04488',
    city: 'Stetson',
    state: 'ME',
    latitude: '44.876852',
    longitude: '-69.088304',
    timeZoneId: 'America/New_York',
  },
  '04489': {
    zip: '04489',
    city: 'Stillwater',
    state: 'ME',
    latitude: '44.91322',
    longitude: '-68.686039',
    timeZoneId: 'America/New_York',
  },
  '04490': {
    zip: '04490',
    city: 'Topsfield',
    state: 'ME',
    latitude: '45.452605',
    longitude: '-67.717492',
    timeZoneId: 'America/New_York',
  },
  '04491': {
    zip: '04491',
    city: 'Vanceboro',
    state: 'ME',
    latitude: '45.556591',
    longitude: '-67.445138',
    timeZoneId: 'America/New_York',
  },
  '04492': {
    zip: '04492',
    city: 'Waite',
    state: 'ME',
    latitude: '45.345472',
    longitude: '-67.694919',
    timeZoneId: 'America/New_York',
  },
  '04493': {
    zip: '04493',
    city: 'West Enfield',
    state: 'ME',
    latitude: '45.243333',
    longitude: '-68.650278',
    timeZoneId: 'America/New_York',
  },
  '04495': {
    zip: '04495',
    city: 'Winn',
    state: 'ME',
    latitude: '45.455265',
    longitude: '-68.332248',
    timeZoneId: 'America/New_York',
  },
  '04496': {
    zip: '04496',
    city: 'Winterport',
    state: 'ME',
    latitude: '44.65786',
    longitude: '-68.915747',
    timeZoneId: 'America/New_York',
  },
  '04497': {
    zip: '04497',
    city: 'Wytopitlock',
    state: 'ME',
    latitude: '45.698333',
    longitude: '-68.101094',
    timeZoneId: 'America/New_York',
  },
  '04530': {
    zip: '04530',
    city: 'Bath',
    state: 'ME',
    latitude: '43.913876',
    longitude: '-69.824845',
    timeZoneId: 'America/New_York',
  },
  '04535': {
    zip: '04535',
    city: 'Alna',
    state: 'ME',
    latitude: '44.080729',
    longitude: '-69.617902',
    timeZoneId: 'America/New_York',
  },
  '04537': {
    zip: '04537',
    city: 'Boothbay',
    state: 'ME',
    latitude: '43.890375',
    longitude: '-69.621719',
    timeZoneId: 'America/New_York',
  },
  '04538': {
    zip: '04538',
    city: 'Boothbay Harbor',
    state: 'ME',
    latitude: '43.851438',
    longitude: '-69.626454',
    timeZoneId: 'America/New_York',
  },
  '04539': {
    zip: '04539',
    city: 'Bristol',
    state: 'ME',
    latitude: '43.954915',
    longitude: '-69.4992',
    timeZoneId: 'America/New_York',
  },
  '04541': {
    zip: '04541',
    city: 'Chamberlain',
    state: 'ME',
    latitude: '43.887245',
    longitude: '-69.499333',
    timeZoneId: 'America/New_York',
  },
  '04543': {
    zip: '04543',
    city: 'Damariscotta',
    state: 'ME',
    latitude: '44.030927',
    longitude: '-69.489468',
    timeZoneId: 'America/New_York',
  },
  '04544': {
    zip: '04544',
    city: 'East Boothbay',
    state: 'ME',
    latitude: '43.821989',
    longitude: '-69.593962',
    timeZoneId: 'America/New_York',
  },
  '04547': {
    zip: '04547',
    city: 'Friendship',
    state: 'ME',
    latitude: '44.054704',
    longitude: '-69.094233',
    timeZoneId: 'America/New_York',
  },
  '04548': {
    zip: '04548',
    city: 'Georgetown',
    state: 'ME',
    latitude: '43.806783',
    longitude: '-69.763606',
    timeZoneId: 'America/New_York',
  },
  '04549': {
    zip: '04549',
    city: 'Isle of Springs',
    state: 'ME',
    latitude: '43.897252',
    longitude: '-69.629746',
    timeZoneId: 'America/New_York',
  },
  '04551': {
    zip: '04551',
    city: 'Bremen',
    state: 'ME',
    latitude: '44.008174',
    longitude: '-69.437857',
    timeZoneId: 'America/New_York',
  },
  '04553': {
    zip: '04553',
    city: 'Newcastle',
    state: 'ME',
    latitude: '44.066869',
    longitude: '-69.547937',
    timeZoneId: 'America/New_York',
  },
  '04554': {
    zip: '04554',
    city: 'New Harbor',
    state: 'ME',
    latitude: '43.856798',
    longitude: '-69.504529',
    timeZoneId: 'America/New_York',
  },
  '04555': {
    zip: '04555',
    city: 'Nobleboro',
    state: 'ME',
    latitude: '44.104064',
    longitude: '-69.479517',
    timeZoneId: 'America/New_York',
  },
  '04556': {
    zip: '04556',
    city: 'Edgecomb',
    state: 'ME',
    latitude: '43.974872',
    longitude: '-69.60907',
    timeZoneId: 'America/New_York',
  },
  '04558': {
    zip: '04558',
    city: 'Pemaquid',
    state: 'ME',
    latitude: '43.875833',
    longitude: '-69.490556',
    timeZoneId: 'America/New_York',
  },
  '04562': {
    zip: '04562',
    city: 'Phippsburg',
    state: 'ME',
    latitude: '43.771013',
    longitude: '-69.811717',
    timeZoneId: 'America/New_York',
  },
  '04563': {
    zip: '04563',
    city: 'Cushing',
    state: 'ME',
    latitude: '43.99153',
    longitude: '-69.264311',
    timeZoneId: 'America/New_York',
  },
  '04564': {
    zip: '04564',
    city: 'Round Pond',
    state: 'ME',
    latitude: '43.918319',
    longitude: '-69.466978',
    timeZoneId: 'America/New_York',
  },
  '04565': {
    zip: '04565',
    city: 'Sebasco Estates',
    state: 'ME',
    latitude: '43.778808',
    longitude: '-69.848458',
    timeZoneId: 'America/New_York',
  },
  '04568': {
    zip: '04568',
    city: 'South Bristol',
    state: 'ME',
    latitude: '43.868138',
    longitude: '-69.559728',
    timeZoneId: 'America/New_York',
  },
  '04570': {
    zip: '04570',
    city: 'Squirrel Island',
    state: 'ME',
    latitude: '43.807043',
    longitude: '-69.634374',
    timeZoneId: 'America/New_York',
  },
  '04571': {
    zip: '04571',
    city: 'Trevett',
    state: 'ME',
    latitude: '43.900265',
    longitude: '-69.676925',
    timeZoneId: 'America/New_York',
  },
  '04572': {
    zip: '04572',
    city: 'Waldoboro',
    state: 'ME',
    latitude: '44.045261',
    longitude: '-69.544349',
    timeZoneId: 'America/New_York',
  },
  '04573': {
    zip: '04573',
    city: 'Walpole',
    state: 'ME',
    latitude: '43.942985',
    longitude: '-69.553344',
    timeZoneId: 'America/New_York',
  },
  '04574': {
    zip: '04574',
    city: 'Washington',
    state: 'ME',
    latitude: '44.27272',
    longitude: '-69.390395',
    timeZoneId: 'America/New_York',
  },
  '04575': {
    zip: '04575',
    city: 'West Boothbay Harbor',
    state: 'ME',
    latitude: '43.848039',
    longitude: '-69.651327',
    timeZoneId: 'America/New_York',
  },
  '04576': {
    zip: '04576',
    city: 'Southport',
    state: 'ME',
    latitude: '43.816792',
    longitude: '-69.663987',
    timeZoneId: 'America/New_York',
  },
  '04578': {
    zip: '04578',
    city: 'Wiscasset',
    state: 'ME',
    latitude: '43.996053',
    longitude: '-69.67151',
    timeZoneId: 'America/New_York',
  },
  '04579': {
    zip: '04579',
    city: 'Woolwich',
    state: 'ME',
    latitude: '43.964645',
    longitude: '-69.79039',
    timeZoneId: 'America/New_York',
  },
  '04605': {
    zip: '04605',
    city: 'Ellsworth',
    state: 'ME',
    latitude: '44.82871',
    longitude: '-68.36516',
    timeZoneId: 'America/New_York',
  },
  '04606': {
    zip: '04606',
    city: 'Addison',
    state: 'ME',
    latitude: '44.584808',
    longitude: '-67.703926',
    timeZoneId: 'America/New_York',
  },
  '04607': {
    zip: '04607',
    city: 'Gouldsboro',
    state: 'ME',
    latitude: '44.4723',
    longitude: '-68.08698',
    timeZoneId: 'America/New_York',
  },
  '04609': {
    zip: '04609',
    city: 'Bar Harbor',
    state: 'ME',
    latitude: '44.38477',
    longitude: '-68.212594',
    timeZoneId: 'America/New_York',
  },
  '04611': {
    zip: '04611',
    city: 'Beals',
    state: 'ME',
    latitude: '44.488403',
    longitude: '-67.587958',
    timeZoneId: 'America/New_York',
  },
  '04612': {
    zip: '04612',
    city: 'Bernard',
    state: 'ME',
    latitude: '44.240559',
    longitude: '-68.362155',
    timeZoneId: 'America/New_York',
  },
  '04613': {
    zip: '04613',
    city: 'Birch Harbor',
    state: 'ME',
    latitude: '44.385517',
    longitude: '-68.044934',
    timeZoneId: 'America/New_York',
  },
  '04614': {
    zip: '04614',
    city: 'Blue Hill',
    state: 'ME',
    latitude: '44.31775',
    longitude: '-68.632382',
    timeZoneId: 'America/New_York',
  },
  '04616': {
    zip: '04616',
    city: 'Brooklin',
    state: 'ME',
    latitude: '44.248351',
    longitude: '-68.548601',
    timeZoneId: 'America/New_York',
  },
  '04617': {
    zip: '04617',
    city: 'Brooksville',
    state: 'ME',
    latitude: '44.370771',
    longitude: '-68.732922',
    timeZoneId: 'America/New_York',
  },
  '04619': {
    zip: '04619',
    city: 'Calais',
    state: 'ME',
    latitude: '45.182779',
    longitude: '-67.280905',
    timeZoneId: 'America/New_York',
  },
  '04622': {
    zip: '04622',
    city: 'Cherryfield',
    state: 'ME',
    latitude: '44.601113',
    longitude: '-67.926225',
    timeZoneId: 'America/New_York',
  },
  '04623': {
    zip: '04623',
    city: 'Columbia Falls',
    state: 'ME',
    latitude: '44.707438',
    longitude: '-67.773645',
    timeZoneId: 'America/New_York',
  },
  '04624': {
    zip: '04624',
    city: 'Corea',
    state: 'ME',
    latitude: '44.417193',
    longitude: '-67.992754',
    timeZoneId: 'America/New_York',
  },
  '04625': {
    zip: '04625',
    city: 'Cranberry Isles',
    state: 'ME',
    latitude: '44.245487',
    longitude: '-68.257422',
    timeZoneId: 'America/New_York',
  },
  '04626': {
    zip: '04626',
    city: 'Cutler',
    state: 'ME',
    latitude: '44.69183',
    longitude: '-67.216792',
    timeZoneId: 'America/New_York',
  },
  '04627': {
    zip: '04627',
    city: 'Deer Isle',
    state: 'ME',
    latitude: '44.236473',
    longitude: '-68.648686',
    timeZoneId: 'America/New_York',
  },
  '04628': {
    zip: '04628',
    city: 'Dennysville',
    state: 'ME',
    latitude: '44.854149',
    longitude: '-67.275517',
    timeZoneId: 'America/New_York',
  },
  '04629': {
    zip: '04629',
    city: 'East Blue Hill',
    state: 'ME',
    latitude: '44.415507',
    longitude: '-68.521466',
    timeZoneId: 'America/New_York',
  },
  '04630': {
    zip: '04630',
    city: 'East Machias',
    state: 'ME',
    latitude: '44.709473',
    longitude: '-67.461926',
    timeZoneId: 'America/New_York',
  },
  '04631': {
    zip: '04631',
    city: 'Eastport',
    state: 'ME',
    latitude: '44.904552',
    longitude: '-66.995779',
    timeZoneId: 'America/New_York',
  },
  '04634': {
    zip: '04634',
    city: 'Franklin',
    state: 'ME',
    latitude: '44.624794',
    longitude: '-68.218829',
    timeZoneId: 'America/New_York',
  },
  '04635': {
    zip: '04635',
    city: 'Frenchboro',
    state: 'ME',
    latitude: '44.110168',
    longitude: '-68.355204',
    timeZoneId: 'America/New_York',
  },
  '04637': {
    zip: '04637',
    city: 'Grand Lake Stream',
    state: 'ME',
    latitude: '45.223333',
    longitude: '-67.572778',
    timeZoneId: 'America/New_York',
  },
  '04640': {
    zip: '04640',
    city: 'Hancock',
    state: 'ME',
    latitude: '44.504622',
    longitude: '-68.244777',
    timeZoneId: 'America/New_York',
  },
  '04642': {
    zip: '04642',
    city: 'Harborside',
    state: 'ME',
    latitude: '44.338181',
    longitude: '-68.797521',
    timeZoneId: 'America/New_York',
  },
  '04643': {
    zip: '04643',
    city: 'Harrington',
    state: 'ME',
    latitude: '44.599715',
    longitude: '-67.815335',
    timeZoneId: 'America/New_York',
  },
  '04644': {
    zip: '04644',
    city: 'Hulls Cove',
    state: 'ME',
    latitude: '44.414398',
    longitude: '-68.250836',
    timeZoneId: 'America/New_York',
  },
  '04645': {
    zip: '04645',
    city: 'Isle au Haut',
    state: 'ME',
    latitude: '44.075278',
    longitude: '-68.633889',
    timeZoneId: 'America/New_York',
  },
  '04646': {
    zip: '04646',
    city: 'Islesford',
    state: 'ME',
    latitude: '44.259058',
    longitude: '-68.226602',
    timeZoneId: 'America/New_York',
  },
  '04648': {
    zip: '04648',
    city: 'Jonesboro',
    state: 'ME',
    latitude: '44.667992',
    longitude: '-67.588823',
    timeZoneId: 'America/New_York',
  },
  '04649': {
    zip: '04649',
    city: 'Jonesport',
    state: 'ME',
    latitude: '44.574423',
    longitude: '-67.601698',
    timeZoneId: 'America/New_York',
  },
  '04650': {
    zip: '04650',
    city: 'Little Deer Isle',
    state: 'ME',
    latitude: '44.290418',
    longitude: '-68.711631',
    timeZoneId: 'America/New_York',
  },
  '04652': {
    zip: '04652',
    city: 'Lubec',
    state: 'ME',
    latitude: '44.864857',
    longitude: '-66.991337',
    timeZoneId: 'America/New_York',
  },
  '04653': {
    zip: '04653',
    city: 'Bass Harbor',
    state: 'ME',
    latitude: '44.156826',
    longitude: '-68.439773',
    timeZoneId: 'America/New_York',
  },
  '04654': {
    zip: '04654',
    city: 'Machias',
    state: 'ME',
    latitude: '44.72053',
    longitude: '-67.467043',
    timeZoneId: 'America/New_York',
  },
  '04655': {
    zip: '04655',
    city: 'Machiasport',
    state: 'ME',
    latitude: '44.669643',
    longitude: '-67.408725',
    timeZoneId: 'America/New_York',
  },
  '04657': {
    zip: '04657',
    city: 'Meddybemps',
    state: 'ME',
    latitude: '45.019171',
    longitude: '-67.386095',
    timeZoneId: 'America/New_York',
  },
  '04658': {
    zip: '04658',
    city: 'Milbridge',
    state: 'ME',
    latitude: '44.531702',
    longitude: '-67.885557',
    timeZoneId: 'America/New_York',
  },
  '04660': {
    zip: '04660',
    city: 'Mount Desert',
    state: 'ME',
    latitude: '44.315723',
    longitude: '-68.366705',
    timeZoneId: 'America/New_York',
  },
  '04662': {
    zip: '04662',
    city: 'Northeast Harbor',
    state: 'ME',
    latitude: '44.29347',
    longitude: '-68.284715',
    timeZoneId: 'America/New_York',
  },
  '04664': {
    zip: '04664',
    city: 'Sullivan',
    state: 'ME',
    latitude: '44.540556',
    longitude: '-68.243611',
    timeZoneId: 'America/New_York',
  },
  '04666': {
    zip: '04666',
    city: 'Pembroke',
    state: 'ME',
    latitude: '44.953808',
    longitude: '-67.162264',
    timeZoneId: 'America/New_York',
  },
  '04667': {
    zip: '04667',
    city: 'Perry',
    state: 'ME',
    latitude: '44.98345',
    longitude: '-67.107224',
    timeZoneId: 'America/New_York',
  },
  '04668': {
    zip: '04668',
    city: 'Princeton',
    state: 'ME',
    latitude: '45.226109',
    longitude: '-67.563119',
    timeZoneId: 'America/New_York',
  },
  '04669': {
    zip: '04669',
    city: 'Prospect Harbor',
    state: 'ME',
    latitude: '44.422425',
    longitude: '-68.036636',
    timeZoneId: 'America/New_York',
  },
  '04671': {
    zip: '04671',
    city: 'Robbinston',
    state: 'ME',
    latitude: '45.064703',
    longitude: '-67.16907',
    timeZoneId: 'America/New_York',
  },
  '04672': {
    zip: '04672',
    city: 'Salsbury Cove',
    state: 'ME',
    latitude: '44.430565',
    longitude: '-68.283955',
    timeZoneId: 'America/New_York',
  },
  '04673': {
    zip: '04673',
    city: 'Sargentville',
    state: 'ME',
    latitude: '44.329116',
    longitude: '-68.708409',
    timeZoneId: 'America/New_York',
  },
  '04674': {
    zip: '04674',
    city: 'Seal Cove',
    state: 'ME',
    latitude: '44.315468',
    longitude: '-68.364237',
    timeZoneId: 'America/New_York',
  },
  '04675': {
    zip: '04675',
    city: 'Seal Harbor',
    state: 'ME',
    latitude: '44.304867',
    longitude: '-68.243629',
    timeZoneId: 'America/New_York',
  },
  '04676': {
    zip: '04676',
    city: 'Sedgwick',
    state: 'ME',
    latitude: '44.34265',
    longitude: '-68.647623',
    timeZoneId: 'America/New_York',
  },
  '04677': {
    zip: '04677',
    city: 'Sorrento',
    state: 'ME',
    latitude: '44.490431',
    longitude: '-68.178524',
    timeZoneId: 'America/New_York',
  },
  '04679': {
    zip: '04679',
    city: 'Southwest Harbor',
    state: 'ME',
    latitude: '44.279865',
    longitude: '-68.330071',
    timeZoneId: 'America/New_York',
  },
  '04680': {
    zip: '04680',
    city: 'Steuben',
    state: 'ME',
    latitude: '44.497544',
    longitude: '-67.946353',
    timeZoneId: 'America/New_York',
  },
  '04681': {
    zip: '04681',
    city: 'Stonington',
    state: 'ME',
    latitude: '44.182327',
    longitude: '-68.672119',
    timeZoneId: 'America/New_York',
  },
  '04683': {
    zip: '04683',
    city: 'Sunset',
    state: 'ME',
    latitude: '44.220643',
    longitude: '-68.706654',
    timeZoneId: 'America/New_York',
  },
  '04684': {
    zip: '04684',
    city: 'Surry',
    state: 'ME',
    latitude: '44.492973',
    longitude: '-68.529516',
    timeZoneId: 'America/New_York',
  },
  '04685': {
    zip: '04685',
    city: 'Swans Island',
    state: 'ME',
    latitude: '44.145',
    longitude: '-68.452222',
    timeZoneId: 'America/New_York',
  },
  '04686': {
    zip: '04686',
    city: 'Wesley',
    state: 'ME',
    latitude: '44.952222',
    longitude: '-67.661389',
    timeZoneId: 'America/New_York',
  },
  '04691': {
    zip: '04691',
    city: 'Whiting',
    state: 'ME',
    latitude: '44.788736',
    longitude: '-67.175252',
    timeZoneId: 'America/New_York',
  },
  '04693': {
    zip: '04693',
    city: 'Winter Harbor',
    state: 'ME',
    latitude: '44.379711',
    longitude: '-68.063757',
    timeZoneId: 'America/New_York',
  },
  '04694': {
    zip: '04694',
    city: 'Baileyville',
    state: 'ME',
    latitude: '45.154958',
    longitude: '-67.407874',
    timeZoneId: 'America/New_York',
  },
  '04730': {
    zip: '04730',
    city: 'Houlton',
    state: 'ME',
    latitude: '46.125041',
    longitude: '-67.839044',
    timeZoneId: 'America/New_York',
  },
  '04732': {
    zip: '04732',
    city: 'Ashland',
    state: 'ME',
    latitude: '46.626925',
    longitude: '-68.418941',
    timeZoneId: 'America/New_York',
  },
  '04733': {
    zip: '04733',
    city: 'Benedicta',
    state: 'ME',
    latitude: '45.802342',
    longitude: '-68.397685',
    timeZoneId: 'America/New_York',
  },
  '04734': {
    zip: '04734',
    city: 'Blaine',
    state: 'ME',
    latitude: '46.503069',
    longitude: '-67.869943',
    timeZoneId: 'America/New_York',
  },
  '04735': {
    zip: '04735',
    city: 'Bridgewater',
    state: 'ME',
    latitude: '46.419626',
    longitude: '-67.849255',
    timeZoneId: 'America/New_York',
  },
  '04736': {
    zip: '04736',
    city: 'Caribou',
    state: 'ME',
    latitude: '46.84778',
    longitude: '-68.010473',
    timeZoneId: 'America/New_York',
  },
  '04737': {
    zip: '04737',
    city: 'Clayton Lake',
    state: 'ME',
    latitude: '46.619729',
    longitude: '-69.520207',
    timeZoneId: 'America/New_York',
  },
  '04738': {
    zip: '04738',
    city: 'Crouseville',
    state: 'ME',
    latitude: '46.758541',
    longitude: '-68.095829',
    timeZoneId: 'America/New_York',
  },
  '04739': {
    zip: '04739',
    city: 'Eagle Lake',
    state: 'ME',
    latitude: '47.040052',
    longitude: '-68.589901',
    timeZoneId: 'America/New_York',
  },
  '04740': {
    zip: '04740',
    city: 'Easton',
    state: 'ME',
    latitude: '46.655132',
    longitude: '-67.937688',
    timeZoneId: 'America/New_York',
  },
  '04741': {
    zip: '04741',
    city: 'Estcourt Station',
    state: 'ME',
    latitude: '47.44235',
    longitude: '-69.210741',
    timeZoneId: 'America/New_York',
  },
  '04742': {
    zip: '04742',
    city: 'Fort Fairfield',
    state: 'ME',
    latitude: '46.769823',
    longitude: '-67.829838',
    timeZoneId: 'America/New_York',
  },
  '04743': {
    zip: '04743',
    city: 'Fort Kent',
    state: 'ME',
    latitude: '47.251669',
    longitude: '-68.593437',
    timeZoneId: 'America/New_York',
  },
  '04744': {
    zip: '04744',
    city: 'Fort Kent Mills',
    state: 'ME',
    latitude: '47.23781',
    longitude: '-68.581454',
    timeZoneId: 'America/New_York',
  },
  '04745': {
    zip: '04745',
    city: 'Frenchville',
    state: 'ME',
    latitude: '47.277889',
    longitude: '-68.386251',
    timeZoneId: 'America/New_York',
  },
  '04746': {
    zip: '04746',
    city: 'Grand Isle',
    state: 'ME',
    latitude: '47.296352',
    longitude: '-68.16229',
    timeZoneId: 'America/New_York',
  },
  '04747': {
    zip: '04747',
    city: 'Island Falls',
    state: 'ME',
    latitude: '46.011654',
    longitude: '-68.271262',
    timeZoneId: 'America/New_York',
  },
  '04750': {
    zip: '04750',
    city: 'Limestone',
    state: 'ME',
    latitude: '46.912547',
    longitude: '-67.826893',
    timeZoneId: 'America/New_York',
  },
  '04751': {
    zip: '04751',
    city: 'Limestone',
    state: 'ME',
    latitude: '46.908611',
    longitude: '-67.826389',
    timeZoneId: 'America/New_York',
  },
  '04756': {
    zip: '04756',
    city: 'Madawaska',
    state: 'ME',
    latitude: '47.341339',
    longitude: '-68.333061',
    timeZoneId: 'America/New_York',
  },
  '04757': {
    zip: '04757',
    city: 'Mapleton',
    state: 'ME',
    latitude: '46.65414',
    longitude: '-68.184981',
    timeZoneId: 'America/New_York',
  },
  '04758': {
    zip: '04758',
    city: 'Mars Hill',
    state: 'ME',
    latitude: '46.518157',
    longitude: '-67.867517',
    timeZoneId: 'America/New_York',
  },
  '04760': {
    zip: '04760',
    city: 'Monticello',
    state: 'ME',
    latitude: '46.308896',
    longitude: '-67.841826',
    timeZoneId: 'America/New_York',
  },
  '04761': {
    zip: '04761',
    city: 'New Limerick',
    state: 'ME',
    latitude: '46.128321',
    longitude: '-67.971164',
    timeZoneId: 'America/New_York',
  },
  '04762': {
    zip: '04762',
    city: 'New Sweden',
    state: 'ME',
    latitude: '46.968188',
    longitude: '-68.118442',
    timeZoneId: 'America/New_York',
  },
  '04763': {
    zip: '04763',
    city: 'Oakfield',
    state: 'ME',
    latitude: '46.106875',
    longitude: '-68.096989',
    timeZoneId: 'America/New_York',
  },
  '04764': {
    zip: '04764',
    city: 'Oxbow',
    state: 'ME',
    latitude: '46.40308',
    longitude: '-68.550291',
    timeZoneId: 'America/New_York',
  },
  '04765': {
    zip: '04765',
    city: 'Patten',
    state: 'ME',
    latitude: '45.997816',
    longitude: '-68.444509',
    timeZoneId: 'America/New_York',
  },
  '04766': {
    zip: '04766',
    city: 'Perham',
    state: 'ME',
    latitude: '46.84652',
    longitude: '-68.200237',
    timeZoneId: 'America/New_York',
  },
  '04768': {
    zip: '04768',
    city: 'Portage',
    state: 'ME',
    latitude: '46.789204',
    longitude: '-68.54171',
    timeZoneId: 'America/New_York',
  },
  '04769': {
    zip: '04769',
    city: 'Presque Isle',
    state: 'ME',
    latitude: '46.679927',
    longitude: '-68.009785',
    timeZoneId: 'America/New_York',
  },
  '04772': {
    zip: '04772',
    city: 'Saint Agatha',
    state: 'ME',
    latitude: '47.235079',
    longitude: '-68.338942',
    timeZoneId: 'America/New_York',
  },
  '04773': {
    zip: '04773',
    city: 'Saint David',
    state: 'ME',
    latitude: '47.321008',
    longitude: '-68.225335',
    timeZoneId: 'America/New_York',
  },
  '04774': {
    zip: '04774',
    city: 'Saint Francis',
    state: 'ME',
    latitude: '47.101533',
    longitude: '-69.051562',
    timeZoneId: 'America/New_York',
  },
  '04775': {
    zip: '04775',
    city: 'Sheridan',
    state: 'ME',
    latitude: '46.656893',
    longitude: '-68.405304',
    timeZoneId: 'America/New_York',
  },
  '04776': {
    zip: '04776',
    city: 'Sherman',
    state: 'ME',
    latitude: '45.815245',
    longitude: '-68.30955',
    timeZoneId: 'America/New_York',
  },
  '04777': {
    zip: '04777',
    city: 'Stacyville',
    state: 'ME',
    latitude: '45.896021',
    longitude: '-68.49207',
    timeZoneId: 'America/New_York',
  },
  '04779': {
    zip: '04779',
    city: 'Sinclair',
    state: 'ME',
    latitude: '47.17098',
    longitude: '-68.304902',
    timeZoneId: 'America/New_York',
  },
  '04780': {
    zip: '04780',
    city: 'Smyrna Mills',
    state: 'ME',
    latitude: '46.16686',
    longitude: '-68.269882',
    timeZoneId: 'America/New_York',
  },
  '04781': {
    zip: '04781',
    city: 'Wallagrass',
    state: 'ME',
    latitude: '47.145899',
    longitude: '-68.632982',
    timeZoneId: 'America/New_York',
  },
  '04783': {
    zip: '04783',
    city: 'Stockholm',
    state: 'ME',
    latitude: '47.055284',
    longitude: '-68.256011',
    timeZoneId: 'America/New_York',
  },
  '04785': {
    zip: '04785',
    city: 'Van Buren',
    state: 'ME',
    latitude: '47.163199',
    longitude: '-67.942329',
    timeZoneId: 'America/New_York',
  },
  '04786': {
    zip: '04786',
    city: 'Washburn',
    state: 'ME',
    latitude: '46.786601',
    longitude: '-68.147503',
    timeZoneId: 'America/New_York',
  },
  '04787': {
    zip: '04787',
    city: 'Westfield',
    state: 'ME',
    latitude: '46.607525',
    longitude: '-67.93561',
    timeZoneId: 'America/New_York',
  },
  '04841': {
    zip: '04841',
    city: 'Rockland',
    state: 'ME',
    latitude: '44.109348',
    longitude: '-69.113281',
    timeZoneId: 'America/New_York',
  },
  '04843': {
    zip: '04843',
    city: 'Camden',
    state: 'ME',
    latitude: '44.210169',
    longitude: '-69.071581',
    timeZoneId: 'America/New_York',
  },
  '04847': {
    zip: '04847',
    city: 'Hope',
    state: 'ME',
    latitude: '44.256919',
    longitude: '-69.143873',
    timeZoneId: 'America/New_York',
  },
  '04848': {
    zip: '04848',
    city: 'Islesboro',
    state: 'ME',
    latitude: '44.30469',
    longitude: '-68.92234',
    timeZoneId: 'America/New_York',
  },
  '04849': {
    zip: '04849',
    city: 'Lincolnville',
    state: 'ME',
    latitude: '44.312909',
    longitude: '-69.092133',
    timeZoneId: 'America/New_York',
  },
  '04850': {
    zip: '04850',
    city: 'Lincolnville Center',
    state: 'ME',
    latitude: '44.294881',
    longitude: '-69.109732',
    timeZoneId: 'America/New_York',
  },
  '04851': {
    zip: '04851',
    city: 'Matinicus',
    state: 'ME',
    latitude: '44.045854',
    longitude: '-68.630969',
    timeZoneId: 'America/New_York',
  },
  '04852': {
    zip: '04852',
    city: 'Monhegan',
    state: 'ME',
    latitude: '43.768737',
    longitude: '-69.31345',
    timeZoneId: 'America/New_York',
  },
  '04853': {
    zip: '04853',
    city: 'North Haven',
    state: 'ME',
    latitude: '44.155875',
    longitude: '-68.865697',
    timeZoneId: 'America/New_York',
  },
  '04854': {
    zip: '04854',
    city: 'Owls Head',
    state: 'ME',
    latitude: '44.081327',
    longitude: '-69.086543',
    timeZoneId: 'America/New_York',
  },
  '04855': {
    zip: '04855',
    city: 'Port Clyde',
    state: 'ME',
    latitude: '44.045405',
    longitude: '-69.145805',
    timeZoneId: 'America/New_York',
  },
  '04856': {
    zip: '04856',
    city: 'Rockport',
    state: 'ME',
    latitude: '44.174901',
    longitude: '-69.087104',
    timeZoneId: 'America/New_York',
  },
  '04858': {
    zip: '04858',
    city: 'South Thomaston',
    state: 'ME',
    latitude: '44.040168',
    longitude: '-69.14655',
    timeZoneId: 'America/New_York',
  },
  '04859': {
    zip: '04859',
    city: 'Spruce Head',
    state: 'ME',
    latitude: '44.01303',
    longitude: '-69.171205',
    timeZoneId: 'America/New_York',
  },
  '04860': {
    zip: '04860',
    city: 'Tenants Harbor',
    state: 'ME',
    latitude: '43.966485',
    longitude: '-69.212306',
    timeZoneId: 'America/New_York',
  },
  '04861': {
    zip: '04861',
    city: 'Thomaston',
    state: 'ME',
    latitude: '44.082',
    longitude: '-69.178594',
    timeZoneId: 'America/New_York',
  },
  '04862': {
    zip: '04862',
    city: 'Union',
    state: 'ME',
    latitude: '44.260518',
    longitude: '-69.256594',
    timeZoneId: 'America/New_York',
  },
  '04863': {
    zip: '04863',
    city: 'Vinalhaven',
    state: 'ME',
    latitude: '44.046856',
    longitude: '-68.816456',
    timeZoneId: 'America/New_York',
  },
  '04864': {
    zip: '04864',
    city: 'Warren',
    state: 'ME',
    latitude: '44.120142',
    longitude: '-69.245163',
    timeZoneId: 'America/New_York',
  },
  '04865': {
    zip: '04865',
    city: 'West Rockport',
    state: 'ME',
    latitude: '44.189569',
    longitude: '-69.151833',
    timeZoneId: 'America/New_York',
  },
  '04901': {
    zip: '04901',
    city: 'Waterville',
    state: 'ME',
    latitude: '44.554203',
    longitude: '-69.630648',
    timeZoneId: 'America/New_York',
  },
  '04903': {
    zip: '04903',
    city: 'Waterville',
    state: 'ME',
    latitude: '44.540326',
    longitude: '-69.635013',
    timeZoneId: 'America/New_York',
  },
  '04910': {
    zip: '04910',
    city: 'Albion',
    state: 'ME',
    latitude: '44.573904',
    longitude: '-69.466422',
    timeZoneId: 'America/New_York',
  },
  '04911': {
    zip: '04911',
    city: 'Anson',
    state: 'ME',
    latitude: '44.796485',
    longitude: '-69.894103',
    timeZoneId: 'America/New_York',
  },
  '04912': {
    zip: '04912',
    city: 'Athens',
    state: 'ME',
    latitude: '44.954513',
    longitude: '-69.662463',
    timeZoneId: 'America/New_York',
  },
  '04915': {
    zip: '04915',
    city: 'Belfast',
    state: 'ME',
    latitude: '44.418374',
    longitude: '-69.007415',
    timeZoneId: 'America/New_York',
  },
  '04917': {
    zip: '04917',
    city: 'Belgrade',
    state: 'ME',
    latitude: '44.4508',
    longitude: '-69.857364',
    timeZoneId: 'America/New_York',
  },
  '04918': {
    zip: '04918',
    city: 'Belgrade Lakes',
    state: 'ME',
    latitude: '44.51298',
    longitude: '-69.86058',
    timeZoneId: 'America/New_York',
  },
  '04920': {
    zip: '04920',
    city: 'Bingham',
    state: 'ME',
    latitude: '45.128441',
    longitude: '-69.858003',
    timeZoneId: 'America/New_York',
  },
  '04921': {
    zip: '04921',
    city: 'Brooks',
    state: 'ME',
    latitude: '44.519428',
    longitude: '-69.099703',
    timeZoneId: 'America/New_York',
  },
  '04922': {
    zip: '04922',
    city: 'Burnham',
    state: 'ME',
    latitude: '44.666298',
    longitude: '-69.33148',
    timeZoneId: 'America/New_York',
  },
  '04923': {
    zip: '04923',
    city: 'Cambridge',
    state: 'ME',
    latitude: '45.05112',
    longitude: '-69.42636',
    timeZoneId: 'America/New_York',
  },
  '04924': {
    zip: '04924',
    city: 'Canaan',
    state: 'ME',
    latitude: '44.755486',
    longitude: '-69.554143',
    timeZoneId: 'America/New_York',
  },
  '04925': {
    zip: '04925',
    city: 'Caratunk',
    state: 'ME',
    latitude: '45.242341',
    longitude: '-69.920146',
    timeZoneId: 'America/New_York',
  },
  '04926': {
    zip: '04926',
    city: 'China Village',
    state: 'ME',
    latitude: '44.476142',
    longitude: '-69.516388',
    timeZoneId: 'America/New_York',
  },
  '04927': {
    zip: '04927',
    city: 'Clinton',
    state: 'ME',
    latitude: '44.594335',
    longitude: '-69.507931',
    timeZoneId: 'America/New_York',
  },
  '04928': {
    zip: '04928',
    city: 'Corinna',
    state: 'ME',
    latitude: '44.932715',
    longitude: '-69.21507',
    timeZoneId: 'America/New_York',
  },
  '04929': {
    zip: '04929',
    city: 'Detroit',
    state: 'ME',
    latitude: '44.760918',
    longitude: '-69.314253',
    timeZoneId: 'America/New_York',
  },
  '04930': {
    zip: '04930',
    city: 'Dexter',
    state: 'ME',
    latitude: '45.025984',
    longitude: '-69.285426',
    timeZoneId: 'America/New_York',
  },
  '04932': {
    zip: '04932',
    city: 'Dixmont',
    state: 'ME',
    latitude: '44.70262',
    longitude: '-69.100598',
    timeZoneId: 'America/New_York',
  },
  '04933': {
    zip: '04933',
    city: 'East Newport',
    state: 'ME',
    latitude: '44.820358',
    longitude: '-69.224292',
    timeZoneId: 'America/New_York',
  },
  '04935': {
    zip: '04935',
    city: 'East Vassalboro',
    state: 'ME',
    latitude: '44.449774',
    longitude: '-69.608359',
    timeZoneId: 'America/New_York',
  },
  '04936': {
    zip: '04936',
    city: 'Eustis',
    state: 'ME',
    latitude: '45.182634',
    longitude: '-70.491461',
    timeZoneId: 'America/New_York',
  },
  '04937': {
    zip: '04937',
    city: 'Fairfield',
    state: 'ME',
    latitude: '44.60238',
    longitude: '-69.631697',
    timeZoneId: 'America/New_York',
  },
  '04938': {
    zip: '04938',
    city: 'Farmington',
    state: 'ME',
    latitude: '44.670853',
    longitude: '-70.146599',
    timeZoneId: 'America/New_York',
  },
  '04939': {
    zip: '04939',
    city: 'Garland',
    state: 'ME',
    latitude: '45.041484',
    longitude: '-69.158676',
    timeZoneId: 'America/New_York',
  },
  '04940': {
    zip: '04940',
    city: 'Farmington Falls',
    state: 'ME',
    latitude: '44.620574',
    longitude: '-70.074052',
    timeZoneId: 'America/New_York',
  },
  '04941': {
    zip: '04941',
    city: 'Freedom',
    state: 'ME',
    latitude: '44.466565',
    longitude: '-69.309221',
    timeZoneId: 'America/New_York',
  },
  '04942': {
    zip: '04942',
    city: 'Harmony',
    state: 'ME',
    latitude: '45.01146',
    longitude: '-69.56976',
    timeZoneId: 'America/New_York',
  },
  '04943': {
    zip: '04943',
    city: 'Hartland',
    state: 'ME',
    latitude: '44.887826',
    longitude: '-69.455219',
    timeZoneId: 'America/New_York',
  },
  '04944': {
    zip: '04944',
    city: 'Hinckley',
    state: 'ME',
    latitude: '44.665618',
    longitude: '-69.634653',
    timeZoneId: 'America/New_York',
  },
  '04945': {
    zip: '04945',
    city: 'Jackman',
    state: 'ME',
    latitude: '45.67379',
    longitude: '-70.266917',
    timeZoneId: 'America/New_York',
  },
  '04947': {
    zip: '04947',
    city: 'Kingfield',
    state: 'ME',
    latitude: '45.062466',
    longitude: '-70.321604',
    timeZoneId: 'America/New_York',
  },
  '04949': {
    zip: '04949',
    city: 'Liberty',
    state: 'ME',
    latitude: '44.36732',
    longitude: '-69.342471',
    timeZoneId: 'America/New_York',
  },
  '04950': {
    zip: '04950',
    city: 'Madison',
    state: 'ME',
    latitude: '44.797501',
    longitude: '-69.878154',
    timeZoneId: 'America/New_York',
  },
  '04951': {
    zip: '04951',
    city: 'Monroe',
    state: 'ME',
    latitude: '44.591373',
    longitude: '-69.042303',
    timeZoneId: 'America/New_York',
  },
  '04952': {
    zip: '04952',
    city: 'Morrill',
    state: 'ME',
    latitude: '44.413305',
    longitude: '-69.15078',
    timeZoneId: 'America/New_York',
  },
  '04953': {
    zip: '04953',
    city: 'Newport',
    state: 'ME',
    latitude: '44.831227',
    longitude: '-69.266093',
    timeZoneId: 'America/New_York',
  },
  '04954': {
    zip: '04954',
    city: 'New Portland',
    state: 'ME',
    latitude: '44.883149',
    longitude: '-70.088461',
    timeZoneId: 'America/New_York',
  },
  '04955': {
    zip: '04955',
    city: 'New Sharon',
    state: 'ME',
    latitude: '44.676689',
    longitude: '-69.98324',
    timeZoneId: 'America/New_York',
  },
  '04956': {
    zip: '04956',
    city: 'New Vineyard',
    state: 'ME',
    latitude: '44.808206',
    longitude: '-70.113262',
    timeZoneId: 'America/New_York',
  },
  '04957': {
    zip: '04957',
    city: 'Norridgewock',
    state: 'ME',
    latitude: '44.6724',
    longitude: '-69.856231',
    timeZoneId: 'America/New_York',
  },
  '04958': {
    zip: '04958',
    city: 'North Anson',
    state: 'ME',
    latitude: '44.953794',
    longitude: '-69.94615',
    timeZoneId: 'America/New_York',
  },
  '04961': {
    zip: '04961',
    city: 'New Portland',
    state: 'ME',
    latitude: '45.013248',
    longitude: '-70.081728',
    timeZoneId: 'America/New_York',
  },
  '04962': {
    zip: '04962',
    city: 'North Vassalboro',
    state: 'ME',
    latitude: '44.475695',
    longitude: '-69.624563',
    timeZoneId: 'America/New_York',
  },
  '04963': {
    zip: '04963',
    city: 'Oakland',
    state: 'ME',
    latitude: '44.544935',
    longitude: '-69.721959',
    timeZoneId: 'America/New_York',
  },
  '04964': {
    zip: '04964',
    city: 'Oquossoc',
    state: 'ME',
    latitude: '44.943193',
    longitude: '-70.653585',
    timeZoneId: 'America/New_York',
  },
  '04965': {
    zip: '04965',
    city: 'Palmyra',
    state: 'ME',
    latitude: '44.859932',
    longitude: '-69.373177',
    timeZoneId: 'America/New_York',
  },
  '04966': {
    zip: '04966',
    city: 'Phillips',
    state: 'ME',
    latitude: '44.843444',
    longitude: '-70.376629',
    timeZoneId: 'America/New_York',
  },
  '04967': {
    zip: '04967',
    city: 'Pittsfield',
    state: 'ME',
    latitude: '44.772452',
    longitude: '-69.399569',
    timeZoneId: 'America/New_York',
  },
  '04969': {
    zip: '04969',
    city: 'Plymouth',
    state: 'ME',
    latitude: '44.765423',
    longitude: '-69.229519',
    timeZoneId: 'America/New_York',
  },
  '04970': {
    zip: '04970',
    city: 'Rangeley',
    state: 'ME',
    latitude: '44.983435',
    longitude: '-70.658693',
    timeZoneId: 'America/New_York',
  },
  '04971': {
    zip: '04971',
    city: 'Saint Albans',
    state: 'ME',
    latitude: '44.920418',
    longitude: '-69.621989',
    timeZoneId: 'America/New_York',
  },
  '04972': {
    zip: '04972',
    city: 'Sandy Point',
    state: 'ME',
    latitude: '44.512516',
    longitude: '-68.811078',
    timeZoneId: 'America/New_York',
  },
  '04973': {
    zip: '04973',
    city: 'Searsmont',
    state: 'ME',
    latitude: '44.361172',
    longitude: '-69.192186',
    timeZoneId: 'America/New_York',
  },
  '04974': {
    zip: '04974',
    city: 'Searsport',
    state: 'ME',
    latitude: '44.467583',
    longitude: '-68.922689',
    timeZoneId: 'America/New_York',
  },
  '04975': {
    zip: '04975',
    city: 'Shawmut',
    state: 'ME',
    latitude: '44.622377',
    longitude: '-69.589233',
    timeZoneId: 'America/New_York',
  },
  '04976': {
    zip: '04976',
    city: 'Skowhegan',
    state: 'ME',
    latitude: '44.757178',
    longitude: '-69.712735',
    timeZoneId: 'America/New_York',
  },
  '04978': {
    zip: '04978',
    city: 'Smithfield',
    state: 'ME',
    latitude: '44.617911',
    longitude: '-69.804118',
    timeZoneId: 'America/New_York',
  },
  '04979': {
    zip: '04979',
    city: 'Solon',
    state: 'ME',
    latitude: '44.95113',
    longitude: '-69.853389',
    timeZoneId: 'America/New_York',
  },
  '04981': {
    zip: '04981',
    city: 'Stockton Springs',
    state: 'ME',
    latitude: '44.533378',
    longitude: '-68.860272',
    timeZoneId: 'America/New_York',
  },
  '04982': {
    zip: '04982',
    city: 'Stratton',
    state: 'ME',
    latitude: '45.094714',
    longitude: '-70.467559',
    timeZoneId: 'America/New_York',
  },
  '04983': {
    zip: '04983',
    city: 'Strong',
    state: 'ME',
    latitude: '44.868494',
    longitude: '-70.23478',
    timeZoneId: 'America/New_York',
  },
  '04984': {
    zip: '04984',
    city: 'Temple',
    state: 'ME',
    latitude: '44.697633',
    longitude: '-70.281549',
    timeZoneId: 'America/New_York',
  },
  '04985': {
    zip: '04985',
    city: 'West Forks',
    state: 'ME',
    latitude: '45.428213',
    longitude: '-69.978255',
    timeZoneId: 'America/New_York',
  },
  '04986': {
    zip: '04986',
    city: 'Thorndike',
    state: 'ME',
    latitude: '44.579334',
    longitude: '-69.243569',
    timeZoneId: 'America/New_York',
  },
  '04987': {
    zip: '04987',
    city: 'Troy',
    state: 'ME',
    latitude: '44.678202',
    longitude: '-69.25376',
    timeZoneId: 'America/New_York',
  },
  '04988': {
    zip: '04988',
    city: 'Unity',
    state: 'ME',
    latitude: '44.610793',
    longitude: '-69.338402',
    timeZoneId: 'America/New_York',
  },
  '04989': {
    zip: '04989',
    city: 'Vassalboro',
    state: 'ME',
    latitude: '44.429961',
    longitude: '-69.652697',
    timeZoneId: 'America/New_York',
  },
  '04992': {
    zip: '04992',
    city: 'West Farmington',
    state: 'ME',
    latitude: '44.664202',
    longitude: '-70.152992',
    timeZoneId: 'America/New_York',
  },
  '05001': {
    zip: '05001',
    city: 'White River Junction',
    state: 'VT',
    latitude: '43.66231',
    longitude: '-72.359191',
    timeZoneId: 'America/New_York',
  },
  '05009': {
    zip: '05009',
    city: 'White River Junction',
    state: 'VT',
    latitude: '43.652072',
    longitude: '-72.32299',
    timeZoneId: 'America/New_York',
  },
  '05030': {
    zip: '05030',
    city: 'Ascutney',
    state: 'VT',
    latitude: '43.446626',
    longitude: '-72.422564',
    timeZoneId: 'America/New_York',
  },
  '05031': {
    zip: '05031',
    city: 'Barnard',
    state: 'VT',
    latitude: '43.726037',
    longitude: '-72.618887',
    timeZoneId: 'America/New_York',
  },
  '05032': {
    zip: '05032',
    city: 'Bethel',
    state: 'VT',
    latitude: '43.81008',
    longitude: '-72.6567',
    timeZoneId: 'America/New_York',
  },
  '05033': {
    zip: '05033',
    city: 'Bradford',
    state: 'VT',
    latitude: '43.99998',
    longitude: '-72.1377',
    timeZoneId: 'America/New_York',
  },
  '05034': {
    zip: '05034',
    city: 'Bridgewater',
    state: 'VT',
    latitude: '43.572856',
    longitude: '-72.617134',
    timeZoneId: 'America/New_York',
  },
  '05035': {
    zip: '05035',
    city: 'Bridgewater Corners',
    state: 'VT',
    latitude: '43.6',
    longitude: '-72.749167',
    timeZoneId: 'America/New_York',
  },
  '05036': {
    zip: '05036',
    city: 'Brookfield',
    state: 'VT',
    latitude: '44.033106',
    longitude: '-72.595924',
    timeZoneId: 'America/New_York',
  },
  '05037': {
    zip: '05037',
    city: 'Brownsville',
    state: 'VT',
    latitude: '43.483333',
    longitude: '-72.483333',
    timeZoneId: 'America/New_York',
  },
  '05038': {
    zip: '05038',
    city: 'Chelsea',
    state: 'VT',
    latitude: '43.986493',
    longitude: '-72.444333',
    timeZoneId: 'America/New_York',
  },
  '05039': {
    zip: '05039',
    city: 'Corinth',
    state: 'VT',
    latitude: '44.034389',
    longitude: '-72.29216',
    timeZoneId: 'America/New_York',
  },
  '05040': {
    zip: '05040',
    city: 'East Corinth',
    state: 'VT',
    latitude: '44.056109',
    longitude: '-72.206479',
    timeZoneId: 'America/New_York',
  },
  '05041': {
    zip: '05041',
    city: 'East Randolph',
    state: 'VT',
    latitude: '43.973889',
    longitude: '-72.556389',
    timeZoneId: 'America/New_York',
  },
  '05042': {
    zip: '05042',
    city: 'East Ryegate',
    state: 'VT',
    latitude: '44.270278',
    longitude: '-72.144444',
    timeZoneId: 'America/New_York',
  },
  '05043': {
    zip: '05043',
    city: 'East Thetford',
    state: 'VT',
    latitude: '43.788611',
    longitude: '-72.255833',
    timeZoneId: 'America/New_York',
  },
  '05045': {
    zip: '05045',
    city: 'Fairlee',
    state: 'VT',
    latitude: '43.916667',
    longitude: '-72.15',
    timeZoneId: 'America/New_York',
  },
  '05046': {
    zip: '05046',
    city: 'Groton',
    state: 'VT',
    latitude: '44.234984',
    longitude: '-72.263574',
    timeZoneId: 'America/New_York',
  },
  '05047': {
    zip: '05047',
    city: 'Hartford',
    state: 'VT',
    latitude: '43.658592',
    longitude: '-72.337881',
    timeZoneId: 'America/New_York',
  },
  '05048': {
    zip: '05048',
    city: 'Hartland',
    state: 'VT',
    latitude: '43.593558',
    longitude: '-72.405864',
    timeZoneId: 'America/New_York',
  },
  '05049': {
    zip: '05049',
    city: 'Hartland Four Corners',
    state: 'VT',
    latitude: '43.54474',
    longitude: '-72.427723',
    timeZoneId: 'America/New_York',
  },
  '05050': {
    zip: '05050',
    city: 'Mc Indoe Falls',
    state: 'VT',
    latitude: '44.262531',
    longitude: '-72.058292',
    timeZoneId: 'America/New_York',
  },
  '05051': {
    zip: '05051',
    city: 'Newbury',
    state: 'VT',
    latitude: '44.046111',
    longitude: '-72.085278',
    timeZoneId: 'America/New_York',
  },
  '05052': {
    zip: '05052',
    city: 'North Hartland',
    state: 'VT',
    latitude: '43.59224',
    longitude: '-72.354055',
    timeZoneId: 'America/New_York',
  },
  '05053': {
    zip: '05053',
    city: 'North Pomfret',
    state: 'VT',
    latitude: '43.722778',
    longitude: '-72.480278',
    timeZoneId: 'America/New_York',
  },
  '05054': {
    zip: '05054',
    city: 'North Thetford',
    state: 'VT',
    latitude: '43.842315',
    longitude: '-72.183801',
    timeZoneId: 'America/New_York',
  },
  '05055': {
    zip: '05055',
    city: 'Norwich',
    state: 'VT',
    latitude: '43.715882',
    longitude: '-72.308168',
    timeZoneId: 'America/New_York',
  },
  '05056': {
    zip: '05056',
    city: 'Plymouth',
    state: 'VT',
    latitude: '43.512',
    longitude: '-72.72',
    timeZoneId: 'America/New_York',
  },
  '05058': {
    zip: '05058',
    city: 'Post Mills',
    state: 'VT',
    latitude: '43.89101',
    longitude: '-72.266882',
    timeZoneId: 'America/New_York',
  },
  '05059': {
    zip: '05059',
    city: 'Quechee',
    state: 'VT',
    latitude: '43.656557',
    longitude: '-72.427413',
    timeZoneId: 'America/New_York',
  },
  '05060': {
    zip: '05060',
    city: 'Randolph',
    state: 'VT',
    latitude: '43.931946',
    longitude: '-72.644794',
    timeZoneId: 'America/New_York',
  },
  '05061': {
    zip: '05061',
    city: 'Randolph Center',
    state: 'VT',
    latitude: '43.928243',
    longitude: '-72.589744',
    timeZoneId: 'America/New_York',
  },
  '05062': {
    zip: '05062',
    city: 'Reading',
    state: 'VT',
    latitude: '43.4943',
    longitude: '-72.58452',
    timeZoneId: 'America/New_York',
  },
  '05065': {
    zip: '05065',
    city: 'Sharon',
    state: 'VT',
    latitude: '43.774789',
    longitude: '-72.419959',
    timeZoneId: 'America/New_York',
  },
  '05067': {
    zip: '05067',
    city: 'South Pomfret',
    state: 'VT',
    latitude: '43.692881',
    longitude: '-72.536671',
    timeZoneId: 'America/New_York',
  },
  '05068': {
    zip: '05068',
    city: 'South Royalton',
    state: 'VT',
    latitude: '43.80162',
    longitude: '-72.53088',
    timeZoneId: 'America/New_York',
  },
  '05069': {
    zip: '05069',
    city: 'South Ryegate',
    state: 'VT',
    latitude: '44.21922',
    longitude: '-72.1296',
    timeZoneId: 'America/New_York',
  },
  '05070': {
    zip: '05070',
    city: 'South Strafford',
    state: 'VT',
    latitude: '43.833285',
    longitude: '-72.377667',
    timeZoneId: 'America/New_York',
  },
  '05071': {
    zip: '05071',
    city: 'South Woodstock',
    state: 'VT',
    latitude: '43.555829',
    longitude: '-72.534771',
    timeZoneId: 'America/New_York',
  },
  '05072': {
    zip: '05072',
    city: 'Strafford',
    state: 'VT',
    latitude: '43.875457',
    longitude: '-72.368589',
    timeZoneId: 'America/New_York',
  },
  '05073': {
    zip: '05073',
    city: 'Taftsville',
    state: 'VT',
    latitude: '43.629922',
    longitude: '-72.463386',
    timeZoneId: 'America/New_York',
  },
  '05074': {
    zip: '05074',
    city: 'Thetford',
    state: 'VT',
    latitude: '43.819172',
    longitude: '-72.228251',
    timeZoneId: 'America/New_York',
  },
  '05075': {
    zip: '05075',
    city: 'Thetford Center',
    state: 'VT',
    latitude: '43.823651',
    longitude: '-72.272568',
    timeZoneId: 'America/New_York',
  },
  '05076': {
    zip: '05076',
    city: 'Topsham',
    state: 'VT',
    latitude: '44.061849',
    longitude: '-72.220363',
    timeZoneId: 'America/New_York',
  },
  '05077': {
    zip: '05077',
    city: 'Tunbridge',
    state: 'VT',
    latitude: '43.915965',
    longitude: '-72.473032',
    timeZoneId: 'America/New_York',
  },
  '05079': {
    zip: '05079',
    city: 'Vershire',
    state: 'VT',
    latitude: '43.922222',
    longitude: '-72.317222',
    timeZoneId: 'America/New_York',
  },
  '05081': {
    zip: '05081',
    city: 'Wells River',
    state: 'VT',
    latitude: '44.1576',
    longitude: '-72.0696',
    timeZoneId: 'America/New_York',
  },
  '05083': {
    zip: '05083',
    city: 'West Fairlee',
    state: 'VT',
    latitude: '43.936397',
    longitude: '-72.248387',
    timeZoneId: 'America/New_York',
  },
  '05084': {
    zip: '05084',
    city: 'West Hartford',
    state: 'VT',
    latitude: '43.712159',
    longitude: '-72.404948',
    timeZoneId: 'America/New_York',
  },
  '05085': {
    zip: '05085',
    city: 'West Newbury',
    state: 'VT',
    latitude: '44.069472',
    longitude: '-72.121627',
    timeZoneId: 'America/New_York',
  },
  '05086': {
    zip: '05086',
    city: 'West Topsham',
    state: 'VT',
    latitude: '44.094722',
    longitude: '-72.341944',
    timeZoneId: 'America/New_York',
  },
  '05088': {
    zip: '05088',
    city: 'Wilder',
    state: 'VT',
    latitude: '43.674874',
    longitude: '-72.317951',
    timeZoneId: 'America/New_York',
  },
  '05089': {
    zip: '05089',
    city: 'Windsor',
    state: 'VT',
    latitude: '43.47504',
    longitude: '-72.42738',
    timeZoneId: 'America/New_York',
  },
  '05091': {
    zip: '05091',
    city: 'Woodstock',
    state: 'VT',
    latitude: '43.62786',
    longitude: '-72.53718',
    timeZoneId: 'America/New_York',
  },
  '05101': {
    zip: '05101',
    city: 'Bellows Falls',
    state: 'VT',
    latitude: '43.18542',
    longitude: '-72.48858',
    timeZoneId: 'America/New_York',
  },
  '05141': {
    zip: '05141',
    city: 'Cambridgeport',
    state: 'VT',
    latitude: '43.151815',
    longitude: '-72.567588',
    timeZoneId: 'America/New_York',
  },
  '05142': {
    zip: '05142',
    city: 'Cavendish',
    state: 'VT',
    latitude: '43.368371',
    longitude: '-72.605995',
    timeZoneId: 'America/New_York',
  },
  '05143': {
    zip: '05143',
    city: 'Chester',
    state: 'VT',
    latitude: '43.279682',
    longitude: '-72.575582',
    timeZoneId: 'America/New_York',
  },
  '05146': {
    zip: '05146',
    city: 'Grafton',
    state: 'VT',
    latitude: '43.147412',
    longitude: '-72.62385',
    timeZoneId: 'America/New_York',
  },
  '05148': {
    zip: '05148',
    city: 'Londonderry',
    state: 'VT',
    latitude: '43.224333',
    longitude: '-72.797903',
    timeZoneId: 'America/New_York',
  },
  '05149': {
    zip: '05149',
    city: 'Ludlow',
    state: 'VT',
    latitude: '43.39242',
    longitude: '-72.69714',
    timeZoneId: 'America/New_York',
  },
  '05150': {
    zip: '05150',
    city: 'North Springfield',
    state: 'VT',
    latitude: '43.335916',
    longitude: '-72.524997',
    timeZoneId: 'America/New_York',
  },
  '05151': {
    zip: '05151',
    city: 'Perkinsville',
    state: 'VT',
    latitude: '43.416944',
    longitude: '-72.518333',
    timeZoneId: 'America/New_York',
  },
  '05152': {
    zip: '05152',
    city: 'Peru',
    state: 'VT',
    latitude: '43.111543',
    longitude: '-73.014331',
    timeZoneId: 'America/New_York',
  },
  '05153': {
    zip: '05153',
    city: 'Proctorsville',
    state: 'VT',
    latitude: '43.474167',
    longitude: '-72.589722',
    timeZoneId: 'America/New_York',
  },
  '05154': {
    zip: '05154',
    city: 'Saxtons River',
    state: 'VT',
    latitude: '43.136924',
    longitude: '-72.507023',
    timeZoneId: 'America/New_York',
  },
  '05155': {
    zip: '05155',
    city: 'South Londonderry',
    state: 'VT',
    latitude: '43.1853',
    longitude: '-72.7989',
    timeZoneId: 'America/New_York',
  },
  '05156': {
    zip: '05156',
    city: 'Springfield',
    state: 'VT',
    latitude: '43.28946',
    longitude: '-72.47478',
    timeZoneId: 'America/New_York',
  },
  '05158': {
    zip: '05158',
    city: 'Westminster',
    state: 'VT',
    latitude: '43.076656',
    longitude: '-72.453377',
    timeZoneId: 'America/New_York',
  },
  '05159': {
    zip: '05159',
    city: 'Westminster Station',
    state: 'VT',
    latitude: '43.085978',
    longitude: '-72.440728',
    timeZoneId: 'America/New_York',
  },
  '05161': {
    zip: '05161',
    city: 'Weston',
    state: 'VT',
    latitude: '43.284563',
    longitude: '-72.793543',
    timeZoneId: 'America/New_York',
  },
  '05201': {
    zip: '05201',
    city: 'Bennington',
    state: 'VT',
    latitude: '42.879586',
    longitude: '-73.197676',
    timeZoneId: 'America/New_York',
  },
  '05250': {
    zip: '05250',
    city: 'Arlington',
    state: 'VT',
    latitude: '43.09878',
    longitude: '-73.15758',
    timeZoneId: 'America/New_York',
  },
  '05251': {
    zip: '05251',
    city: 'Dorset',
    state: 'VT',
    latitude: '43.2225',
    longitude: '-73.073889',
    timeZoneId: 'America/New_York',
  },
  '05252': {
    zip: '05252',
    city: 'East Arlington',
    state: 'VT',
    latitude: '43.057869',
    longitude: '-73.137659',
    timeZoneId: 'America/New_York',
  },
  '05253': {
    zip: '05253',
    city: 'East Dorset',
    state: 'VT',
    latitude: '43.283889',
    longitude: '-73.002778',
    timeZoneId: 'America/New_York',
  },
  '05254': {
    zip: '05254',
    city: 'Manchester',
    state: 'VT',
    latitude: '43.162851',
    longitude: '-73.07033',
    timeZoneId: 'America/New_York',
  },
  '05255': {
    zip: '05255',
    city: 'Manchester Center',
    state: 'VT',
    latitude: '43.194892',
    longitude: '-73.06562',
    timeZoneId: 'America/New_York',
  },
  '05257': {
    zip: '05257',
    city: 'North Bennington',
    state: 'VT',
    latitude: '42.930035',
    longitude: '-73.24625',
    timeZoneId: 'America/New_York',
  },
  '05260': {
    zip: '05260',
    city: 'North Pownal',
    state: 'VT',
    latitude: '42.821495',
    longitude: '-73.250086',
    timeZoneId: 'America/New_York',
  },
  '05261': {
    zip: '05261',
    city: 'Pownal',
    state: 'VT',
    latitude: '42.7977',
    longitude: '-73.21668',
    timeZoneId: 'America/New_York',
  },
  '05262': {
    zip: '05262',
    city: 'Shaftsbury',
    state: 'VT',
    latitude: '43.133333',
    longitude: '-73.066667',
    timeZoneId: 'America/New_York',
  },
  '05301': {
    zip: '05301',
    city: 'Brattleboro',
    state: 'VT',
    latitude: '42.843087',
    longitude: '-72.688635',
    timeZoneId: 'America/New_York',
  },
  '05302': {
    zip: '05302',
    city: 'Brattleboro',
    state: 'VT',
    latitude: '42.855311',
    longitude: '-72.656193',
    timeZoneId: 'America/New_York',
  },
  '05303': {
    zip: '05303',
    city: 'Brattleboro',
    state: 'VT',
    latitude: '42.854637',
    longitude: '-72.558621',
    timeZoneId: 'America/New_York',
  },
  '05304': {
    zip: '05304',
    city: 'Brattleboro',
    state: 'VT',
    latitude: '42.885232',
    longitude: '-72.558728',
    timeZoneId: 'America/New_York',
  },
  '05340': {
    zip: '05340',
    city: 'Bondville',
    state: 'VT',
    latitude: '43.15518',
    longitude: '-72.90798',
    timeZoneId: 'America/New_York',
  },
  '05341': {
    zip: '05341',
    city: 'East Dover',
    state: 'VT',
    latitude: '42.943611',
    longitude: '-72.804444',
    timeZoneId: 'America/New_York',
  },
  '05342': {
    zip: '05342',
    city: 'Jacksonville',
    state: 'VT',
    latitude: '42.770239',
    longitude: '-72.799447',
    timeZoneId: 'America/New_York',
  },
  '05343': {
    zip: '05343',
    city: 'Jamaica',
    state: 'VT',
    latitude: '43.10442',
    longitude: '-72.80718',
    timeZoneId: 'America/New_York',
  },
  '05344': {
    zip: '05344',
    city: 'Marlboro',
    state: 'VT',
    latitude: '42.860125',
    longitude: '-72.728912',
    timeZoneId: 'America/New_York',
  },
  '05345': {
    zip: '05345',
    city: 'Newfane',
    state: 'VT',
    latitude: '42.983232',
    longitude: '-72.656479',
    timeZoneId: 'America/New_York',
  },
  '05346': {
    zip: '05346',
    city: 'Putney',
    state: 'VT',
    latitude: '42.955143',
    longitude: '-72.565046',
    timeZoneId: 'America/New_York',
  },
  '05350': {
    zip: '05350',
    city: 'Readsboro',
    state: 'VT',
    latitude: '42.829722',
    longitude: '-72.988056',
    timeZoneId: 'America/New_York',
  },
  '05351': {
    zip: '05351',
    city: 'South Newfane',
    state: 'VT',
    latitude: '42.938056',
    longitude: '-72.705833',
    timeZoneId: 'America/New_York',
  },
  '05352': {
    zip: '05352',
    city: 'Stamford',
    state: 'VT',
    latitude: '42.77538',
    longitude: '-73.06842',
    timeZoneId: 'America/New_York',
  },
  '05353': {
    zip: '05353',
    city: 'Townshend',
    state: 'VT',
    latitude: '43.064489',
    longitude: '-72.668569',
    timeZoneId: 'America/New_York',
  },
  '05354': {
    zip: '05354',
    city: 'Vernon',
    state: 'VT',
    latitude: '42.760563',
    longitude: '-72.526116',
    timeZoneId: 'America/New_York',
  },
  '05355': {
    zip: '05355',
    city: 'Wardsboro',
    state: 'VT',
    latitude: '43.02948',
    longitude: '-72.81162',
    timeZoneId: 'America/New_York',
  },
  '05356': {
    zip: '05356',
    city: 'West Dover',
    state: 'VT',
    latitude: '42.933333',
    longitude: '-72.833333',
    timeZoneId: 'America/New_York',
  },
  '05357': {
    zip: '05357',
    city: 'West Dummerston',
    state: 'VT',
    latitude: '42.928208',
    longitude: '-72.614048',
    timeZoneId: 'America/New_York',
  },
  '05358': {
    zip: '05358',
    city: 'West Halifax',
    state: 'VT',
    latitude: '42.78558',
    longitude: '-72.7695',
    timeZoneId: 'America/New_York',
  },
  '05359': {
    zip: '05359',
    city: 'West Townshend',
    state: 'VT',
    latitude: '43.163746',
    longitude: '-72.719255',
    timeZoneId: 'America/New_York',
  },
  '05360': {
    zip: '05360',
    city: 'West Wardsboro',
    state: 'VT',
    latitude: '43.07442',
    longitude: '-72.89892',
    timeZoneId: 'America/New_York',
  },
  '05361': {
    zip: '05361',
    city: 'Whitingham',
    state: 'VT',
    latitude: '42.782671',
    longitude: '-72.872828',
    timeZoneId: 'America/New_York',
  },
  '05362': {
    zip: '05362',
    city: 'Williamsville',
    state: 'VT',
    latitude: '42.947772',
    longitude: '-72.659172',
    timeZoneId: 'America/New_York',
  },
  '05363': {
    zip: '05363',
    city: 'Wilmington',
    state: 'VT',
    latitude: '42.883215',
    longitude: '-72.898726',
    timeZoneId: 'America/New_York',
  },
  '05401': {
    zip: '05401',
    city: 'Burlington',
    state: 'VT',
    latitude: '44.48724',
    longitude: '-73.22778',
    timeZoneId: 'America/New_York',
  },
  '05402': {
    zip: '05402',
    city: 'Burlington',
    state: 'VT',
    latitude: '44.505696',
    longitude: '-73.126648',
    timeZoneId: 'America/New_York',
  },
  '05403': {
    zip: '05403',
    city: 'South Burlington',
    state: 'VT',
    latitude: '44.46126',
    longitude: '-73.19718',
    timeZoneId: 'America/New_York',
  },
  '05404': {
    zip: '05404',
    city: 'Winooski',
    state: 'VT',
    latitude: '44.49443',
    longitude: '-73.183111',
    timeZoneId: 'America/New_York',
  },
  '05405': {
    zip: '05405',
    city: 'Burlington',
    state: 'VT',
    latitude: '44.477327',
    longitude: '-73.203176',
    timeZoneId: 'America/New_York',
  },
  '05406': {
    zip: '05406',
    city: 'Burlington',
    state: 'VT',
    latitude: '44.487168',
    longitude: '-73.226267',
    timeZoneId: 'America/New_York',
  },
  '05407': {
    zip: '05407',
    city: 'South Burlington',
    state: 'VT',
    latitude: '44.446346',
    longitude: '-73.175684',
    timeZoneId: 'America/New_York',
  },
  '05408': {
    zip: '05408',
    city: 'Burlington',
    state: 'VT',
    latitude: '44.446628',
    longitude: '-73.175354',
    timeZoneId: 'America/New_York',
  },
  '05439': {
    zip: '05439',
    city: 'Colchester',
    state: 'VT',
    latitude: '44.551291',
    longitude: '-73.179908',
    timeZoneId: 'America/New_York',
  },
  '05440': {
    zip: '05440',
    city: 'Alburgh',
    state: 'VT',
    latitude: '44.957724',
    longitude: '-73.279914',
    timeZoneId: 'America/New_York',
  },
  '05441': {
    zip: '05441',
    city: 'Bakersfield',
    state: 'VT',
    latitude: '44.932075',
    longitude: '-73.094031',
    timeZoneId: 'America/New_York',
  },
  '05442': {
    zip: '05442',
    city: 'Belvidere Center',
    state: 'VT',
    latitude: '44.772686',
    longitude: '-72.66368',
    timeZoneId: 'America/New_York',
  },
  '05443': {
    zip: '05443',
    city: 'Bristol',
    state: 'VT',
    latitude: '44.15784',
    longitude: '-73.0626',
    timeZoneId: 'America/New_York',
  },
  '05444': {
    zip: '05444',
    city: 'Cambridge',
    state: 'VT',
    latitude: '44.6481',
    longitude: '-72.86388',
    timeZoneId: 'America/New_York',
  },
  '05445': {
    zip: '05445',
    city: 'Charlotte',
    state: 'VT',
    latitude: '44.30928',
    longitude: '-73.2387',
    timeZoneId: 'America/New_York',
  },
  '05446': {
    zip: '05446',
    city: 'Colchester',
    state: 'VT',
    latitude: '44.5485',
    longitude: '-73.21548',
    timeZoneId: 'America/New_York',
  },
  '05447': {
    zip: '05447',
    city: 'East Berkshire',
    state: 'VT',
    latitude: '44.930619',
    longitude: '-72.710708',
    timeZoneId: 'America/New_York',
  },
  '05448': {
    zip: '05448',
    city: 'East Fairfield',
    state: 'VT',
    latitude: '44.800384',
    longitude: '-72.91785',
    timeZoneId: 'America/New_York',
  },
  '05449': {
    zip: '05449',
    city: 'Colchester',
    state: 'VT',
    latitude: '44.54505',
    longitude: '-73.145961',
    timeZoneId: 'America/New_York',
  },
  '05450': {
    zip: '05450',
    city: 'Enosburg Falls',
    state: 'VT',
    latitude: '44.90088',
    longitude: '-72.79326',
    timeZoneId: 'America/New_York',
  },
  '05451': {
    zip: '05451',
    city: 'Essex',
    state: 'VT',
    latitude: '44.5075',
    longitude: '-73.069481',
    timeZoneId: 'America/New_York',
  },
  '05452': {
    zip: '05452',
    city: 'Essex Junction',
    state: 'VT',
    latitude: '44.499816',
    longitude: '-73.089496',
    timeZoneId: 'America/New_York',
  },
  '05453': {
    zip: '05453',
    city: 'Essex Junction',
    state: 'VT',
    latitude: '44.492901',
    longitude: '-73.081591',
    timeZoneId: 'America/New_York',
  },
  '05454': {
    zip: '05454',
    city: 'Fairfax',
    state: 'VT',
    latitude: '44.6868',
    longitude: '-73.01562',
    timeZoneId: 'America/New_York',
  },
  '05455': {
    zip: '05455',
    city: 'Fairfield',
    state: 'VT',
    latitude: '44.784985',
    longitude: '-73.017552',
    timeZoneId: 'America/New_York',
  },
  '05456': {
    zip: '05456',
    city: 'Ferrisburgh',
    state: 'VT',
    latitude: '44.214705',
    longitude: '-73.268043',
    timeZoneId: 'America/New_York',
  },
  '05457': {
    zip: '05457',
    city: 'Franklin',
    state: 'VT',
    latitude: '44.9682',
    longitude: '-72.89592',
    timeZoneId: 'America/New_York',
  },
  '05458': {
    zip: '05458',
    city: 'Grand Isle',
    state: 'VT',
    latitude: '44.717862',
    longitude: '-73.300101',
    timeZoneId: 'America/New_York',
  },
  '05459': {
    zip: '05459',
    city: 'Highgate Center',
    state: 'VT',
    latitude: '44.949',
    longitude: '-73.01202',
    timeZoneId: 'America/New_York',
  },
  '05460': {
    zip: '05460',
    city: 'Highgate Springs',
    state: 'VT',
    latitude: '44.979819',
    longitude: '-73.105448',
    timeZoneId: 'America/New_York',
  },
  '05461': {
    zip: '05461',
    city: 'Hinesburg',
    state: 'VT',
    latitude: '44.304622',
    longitude: '-73.11817',
    timeZoneId: 'America/New_York',
  },
  '05462': {
    zip: '05462',
    city: 'Huntington',
    state: 'VT',
    latitude: '44.31072',
    longitude: '-73.0035',
    timeZoneId: 'America/New_York',
  },
  '05463': {
    zip: '05463',
    city: 'Isle La Motte',
    state: 'VT',
    latitude: '44.870004',
    longitude: '-73.34082',
    timeZoneId: 'America/New_York',
  },
  '05464': {
    zip: '05464',
    city: 'Jeffersonville',
    state: 'VT',
    latitude: '44.663827',
    longitude: '-72.783702',
    timeZoneId: 'America/New_York',
  },
  '05465': {
    zip: '05465',
    city: 'Jericho',
    state: 'VT',
    latitude: '44.47614',
    longitude: '-72.9498',
    timeZoneId: 'America/New_York',
  },
  '05466': {
    zip: '05466',
    city: 'Jonesville',
    state: 'VT',
    latitude: '44.540678',
    longitude: '-73.209576',
    timeZoneId: 'America/New_York',
  },
  '05468': {
    zip: '05468',
    city: 'Milton',
    state: 'VT',
    latitude: '44.639211',
    longitude: '-73.136914',
    timeZoneId: 'America/New_York',
  },
  '05469': {
    zip: '05469',
    city: 'Monkton',
    state: 'VT',
    latitude: '44.25395',
    longitude: '-73.120952',
    timeZoneId: 'America/New_York',
  },
  '05470': {
    zip: '05470',
    city: 'Montgomery',
    state: 'VT',
    latitude: '44.901593',
    longitude: '-72.640125',
    timeZoneId: 'America/New_York',
  },
  '05471': {
    zip: '05471',
    city: 'Montgomery Center',
    state: 'VT',
    latitude: '44.860467',
    longitude: '-72.593608',
    timeZoneId: 'America/New_York',
  },
  '05472': {
    zip: '05472',
    city: 'New Haven',
    state: 'VT',
    latitude: '44.115215',
    longitude: '-73.176252',
    timeZoneId: 'America/New_York',
  },
  '05473': {
    zip: '05473',
    city: 'North Ferrisburgh',
    state: 'VT',
    latitude: '44.25468',
    longitude: '-73.2108',
    timeZoneId: 'America/New_York',
  },
  '05474': {
    zip: '05474',
    city: 'North Hero',
    state: 'VT',
    latitude: '44.852461',
    longitude: '-73.278914',
    timeZoneId: 'America/New_York',
  },
  '05476': {
    zip: '05476',
    city: 'Richford',
    state: 'VT',
    latitude: '44.9901',
    longitude: '-72.6627',
    timeZoneId: 'America/New_York',
  },
  '05477': {
    zip: '05477',
    city: 'Richmond',
    state: 'VT',
    latitude: '44.38344',
    longitude: '-72.97356',
    timeZoneId: 'America/New_York',
  },
  '05478': {
    zip: '05478',
    city: 'Saint Albans',
    state: 'VT',
    latitude: '44.80284',
    longitude: '-73.08966',
    timeZoneId: 'America/New_York',
  },
  '05479': {
    zip: '05479',
    city: 'Saint Albans',
    state: 'VT',
    latitude: '44.81206',
    longitude: '-73.08276',
    timeZoneId: 'America/New_York',
  },
  '05481': {
    zip: '05481',
    city: 'Saint Albans Bay',
    state: 'VT',
    latitude: '44.809591',
    longitude: '-73.138866',
    timeZoneId: 'America/New_York',
  },
  '05482': {
    zip: '05482',
    city: 'Shelburne',
    state: 'VT',
    latitude: '44.391173',
    longitude: '-73.214352',
    timeZoneId: 'America/New_York',
  },
  '05483': {
    zip: '05483',
    city: 'Sheldon',
    state: 'VT',
    latitude: '44.884878',
    longitude: '-72.929823',
    timeZoneId: 'America/New_York',
  },
  '05485': {
    zip: '05485',
    city: 'Sheldon Springs',
    state: 'VT',
    latitude: '44.910556',
    longitude: '-72.9075',
    timeZoneId: 'America/New_York',
  },
  '05486': {
    zip: '05486',
    city: 'South Hero',
    state: 'VT',
    latitude: '44.866206',
    longitude: '-73.25137',
    timeZoneId: 'America/New_York',
  },
  '05487': {
    zip: '05487',
    city: 'Starksboro',
    state: 'VT',
    latitude: '44.22972',
    longitude: '-73.0134',
    timeZoneId: 'America/New_York',
  },
  '05488': {
    zip: '05488',
    city: 'Swanton',
    state: 'VT',
    latitude: '44.9298',
    longitude: '-73.1187',
    timeZoneId: 'America/New_York',
  },
  '05489': {
    zip: '05489',
    city: 'Underhill',
    state: 'VT',
    latitude: '44.5389',
    longitude: '-72.92058',
    timeZoneId: 'America/New_York',
  },
  '05490': {
    zip: '05490',
    city: 'Underhill Center',
    state: 'VT',
    latitude: '44.527965',
    longitude: '-72.896952',
    timeZoneId: 'America/New_York',
  },
  '05491': {
    zip: '05491',
    city: 'Vergennes',
    state: 'VT',
    latitude: '44.14854',
    longitude: '-73.27536',
    timeZoneId: 'America/New_York',
  },
  '05492': {
    zip: '05492',
    city: 'Waterville',
    state: 'VT',
    latitude: '44.717268',
    longitude: '-72.75525',
    timeZoneId: 'America/New_York',
  },
  '05494': {
    zip: '05494',
    city: 'Westford',
    state: 'VT',
    latitude: '44.630369',
    longitude: '-73.005676',
    timeZoneId: 'America/New_York',
  },
  '05495': {
    zip: '05495',
    city: 'Williston',
    state: 'VT',
    latitude: '44.42766',
    longitude: '-73.08798',
    timeZoneId: 'America/New_York',
  },
  '05501': {
    zip: '05501',
    city: 'Andover',
    state: 'MA',
    latitude: '42.652272',
    longitude: '-71.183498',
    timeZoneId: 'America/New_York',
  },
  '05601': {
    zip: '05601',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.204239',
    longitude: '-72.5551',
    timeZoneId: 'America/New_York',
  },
  '05602': {
    zip: '05602',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.27022',
    longitude: '-72.57642',
    timeZoneId: 'America/New_York',
  },
  '05603': {
    zip: '05603',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.262128',
    longitude: '-72.584362',
    timeZoneId: 'America/New_York',
  },
  '05604': {
    zip: '05604',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.257063',
    longitude: '-72.587791',
    timeZoneId: 'America/New_York',
  },
  '05609': {
    zip: '05609',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.287423',
    longitude: '-72.600062',
    timeZoneId: 'America/New_York',
  },
  '05620': {
    zip: '05620',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.262683',
    longitude: '-72.583905',
    timeZoneId: 'America/New_York',
  },
  '05633': {
    zip: '05633',
    city: 'Montpelier',
    state: 'VT',
    latitude: '44.259083',
    longitude: '-72.580549',
    timeZoneId: 'America/New_York',
  },
  '05640': {
    zip: '05640',
    city: 'Adamant',
    state: 'VT',
    latitude: '44.329167',
    longitude: '-72.503333',
    timeZoneId: 'America/New_York',
  },
  '05641': {
    zip: '05641',
    city: 'Barre',
    state: 'VT',
    latitude: '44.19102',
    longitude: '-72.48852',
    timeZoneId: 'America/New_York',
  },
  '05647': {
    zip: '05647',
    city: 'Cabot',
    state: 'VT',
    latitude: '44.400126',
    longitude: '-72.297615',
    timeZoneId: 'America/New_York',
  },
  '05648': {
    zip: '05648',
    city: 'Calais',
    state: 'VT',
    latitude: '44.398091',
    longitude: '-72.480629',
    timeZoneId: 'America/New_York',
  },
  '05649': {
    zip: '05649',
    city: 'East Barre',
    state: 'VT',
    latitude: '44.159916',
    longitude: '-72.445332',
    timeZoneId: 'America/New_York',
  },
  '05650': {
    zip: '05650',
    city: 'East Calais',
    state: 'VT',
    latitude: '44.351384',
    longitude: '-72.439832',
    timeZoneId: 'America/New_York',
  },
  '05651': {
    zip: '05651',
    city: 'East Montpelier',
    state: 'VT',
    latitude: '44.250409',
    longitude: '-72.473242',
    timeZoneId: 'America/New_York',
  },
  '05652': {
    zip: '05652',
    city: 'Eden',
    state: 'VT',
    latitude: '44.743658',
    longitude: '-72.621668',
    timeZoneId: 'America/New_York',
  },
  '05653': {
    zip: '05653',
    city: 'Eden Mills',
    state: 'VT',
    latitude: '44.707222',
    longitude: '-72.546111',
    timeZoneId: 'America/New_York',
  },
  '05654': {
    zip: '05654',
    city: 'Graniteville',
    state: 'VT',
    latitude: '44.149872',
    longitude: '-72.473991',
    timeZoneId: 'America/New_York',
  },
  '05655': {
    zip: '05655',
    city: 'Hyde Park',
    state: 'VT',
    latitude: '44.631977',
    longitude: '-72.569348',
    timeZoneId: 'America/New_York',
  },
  '05656': {
    zip: '05656',
    city: 'Johnson',
    state: 'VT',
    latitude: '44.64258',
    longitude: '-72.6699',
    timeZoneId: 'America/New_York',
  },
  '05657': {
    zip: '05657',
    city: 'Lake Elmore',
    state: 'VT',
    latitude: '44.544357',
    longitude: '-72.528615',
    timeZoneId: 'America/New_York',
  },
  '05658': {
    zip: '05658',
    city: 'Marshfield',
    state: 'VT',
    latitude: '44.32842',
    longitude: '-72.37482',
    timeZoneId: 'America/New_York',
  },
  '05660': {
    zip: '05660',
    city: 'Moretown',
    state: 'VT',
    latitude: '44.252162',
    longitude: '-72.748572',
    timeZoneId: 'America/New_York',
  },
  '05661': {
    zip: '05661',
    city: 'Morrisville',
    state: 'VT',
    latitude: '44.559826',
    longitude: '-72.601659',
    timeZoneId: 'America/New_York',
  },
  '05662': {
    zip: '05662',
    city: 'Moscow',
    state: 'VT',
    latitude: '44.441941',
    longitude: '-72.717235',
    timeZoneId: 'America/New_York',
  },
  '05663': {
    zip: '05663',
    city: 'Northfield',
    state: 'VT',
    latitude: '44.149825',
    longitude: '-72.660946',
    timeZoneId: 'America/New_York',
  },
  '05664': {
    zip: '05664',
    city: 'Northfield Falls',
    state: 'VT',
    latitude: '44.171996',
    longitude: '-72.651009',
    timeZoneId: 'America/New_York',
  },
  '05665': {
    zip: '05665',
    city: 'North Hyde Park',
    state: 'VT',
    latitude: '44.671791',
    longitude: '-72.598969',
    timeZoneId: 'America/New_York',
  },
  '05666': {
    zip: '05666',
    city: 'North Montpelier',
    state: 'VT',
    latitude: '44.256675',
    longitude: '-72.485785',
    timeZoneId: 'America/New_York',
  },
  '05667': {
    zip: '05667',
    city: 'Plainfield',
    state: 'VT',
    latitude: '44.243569',
    longitude: '-72.409078',
    timeZoneId: 'America/New_York',
  },
  '05669': {
    zip: '05669',
    city: 'Roxbury',
    state: 'VT',
    latitude: '44.073568',
    longitude: '-72.727483',
    timeZoneId: 'America/New_York',
  },
  '05670': {
    zip: '05670',
    city: 'South Barre',
    state: 'VT',
    latitude: '44.177625',
    longitude: '-72.506552',
    timeZoneId: 'America/New_York',
  },
  '05671': {
    zip: '05671',
    city: 'Waterbury',
    state: 'VT',
    latitude: '44.341294',
    longitude: '-72.758536',
    timeZoneId: 'America/New_York',
  },
  '05672': {
    zip: '05672',
    city: 'Stowe',
    state: 'VT',
    latitude: '44.499684',
    longitude: '-72.646502',
    timeZoneId: 'America/New_York',
  },
  '05673': {
    zip: '05673',
    city: 'Waitsfield',
    state: 'VT',
    latitude: '44.187565',
    longitude: '-72.843339',
    timeZoneId: 'America/New_York',
  },
  '05674': {
    zip: '05674',
    city: 'Warren',
    state: 'VT',
    latitude: '44.129916',
    longitude: '-72.879337',
    timeZoneId: 'America/New_York',
  },
  '05675': {
    zip: '05675',
    city: 'Washington',
    state: 'VT',
    latitude: '44.028056',
    longitude: '-72.413333',
    timeZoneId: 'America/New_York',
  },
  '05676': {
    zip: '05676',
    city: 'Waterbury',
    state: 'VT',
    latitude: '44.34522',
    longitude: '-72.7851',
    timeZoneId: 'America/New_York',
  },
  '05677': {
    zip: '05677',
    city: 'Waterbury Center',
    state: 'VT',
    latitude: '44.392439',
    longitude: '-72.715938',
    timeZoneId: 'America/New_York',
  },
  '05678': {
    zip: '05678',
    city: 'Websterville',
    state: 'VT',
    latitude: '44.16228',
    longitude: '-72.469246',
    timeZoneId: 'America/New_York',
  },
  '05679': {
    zip: '05679',
    city: 'Williamstown',
    state: 'VT',
    latitude: '44.105138',
    longitude: '-72.542504',
    timeZoneId: 'America/New_York',
  },
  '05680': {
    zip: '05680',
    city: 'Wolcott',
    state: 'VT',
    latitude: '44.5467',
    longitude: '-72.49782',
    timeZoneId: 'America/New_York',
  },
  '05681': {
    zip: '05681',
    city: 'Woodbury',
    state: 'VT',
    latitude: '44.445762',
    longitude: '-72.409821',
    timeZoneId: 'America/New_York',
  },
  '05682': {
    zip: '05682',
    city: 'Worcester',
    state: 'VT',
    latitude: '44.408965',
    longitude: '-72.573124',
    timeZoneId: 'America/New_York',
  },
  '05701': {
    zip: '05701',
    city: 'Rutland',
    state: 'VT',
    latitude: '43.606253',
    longitude: '-72.974023',
    timeZoneId: 'America/New_York',
  },
  '05702': {
    zip: '05702',
    city: 'Rutland',
    state: 'VT',
    latitude: '43.608678',
    longitude: '-72.979192',
    timeZoneId: 'America/New_York',
  },
  '05730': {
    zip: '05730',
    city: 'Belmont',
    state: 'VT',
    latitude: '43.421527',
    longitude: '-72.817203',
    timeZoneId: 'America/New_York',
  },
  '05731': {
    zip: '05731',
    city: 'Benson',
    state: 'VT',
    latitude: '43.594722',
    longitude: '-73.266111',
    timeZoneId: 'America/New_York',
  },
  '05732': {
    zip: '05732',
    city: 'Bomoseen',
    state: 'VT',
    latitude: '43.643611',
    longitude: '-73.199167',
    timeZoneId: 'America/New_York',
  },
  '05733': {
    zip: '05733',
    city: 'Brandon',
    state: 'VT',
    latitude: '43.79862',
    longitude: '-73.09452',
    timeZoneId: 'America/New_York',
  },
  '05734': {
    zip: '05734',
    city: 'Bridport',
    state: 'VT',
    latitude: '43.94479',
    longitude: '-73.353652',
    timeZoneId: 'America/New_York',
  },
  '05735': {
    zip: '05735',
    city: 'Castleton',
    state: 'VT',
    latitude: '43.62798',
    longitude: '-73.17078',
    timeZoneId: 'America/New_York',
  },
  '05736': {
    zip: '05736',
    city: 'Center Rutland',
    state: 'VT',
    latitude: '43.595502',
    longitude: '-73.025348',
    timeZoneId: 'America/New_York',
  },
  '05737': {
    zip: '05737',
    city: 'Chittenden',
    state: 'VT',
    latitude: '43.709009',
    longitude: '-72.961526',
    timeZoneId: 'America/New_York',
  },
  '05738': {
    zip: '05738',
    city: 'Cuttingsville',
    state: 'VT',
    latitude: '43.53102',
    longitude: '-72.86448',
    timeZoneId: 'America/New_York',
  },
  '05739': {
    zip: '05739',
    city: 'Danby',
    state: 'VT',
    latitude: '43.35612',
    longitude: '-73.0176',
    timeZoneId: 'America/New_York',
  },
  '05740': {
    zip: '05740',
    city: 'East Middlebury',
    state: 'VT',
    latitude: '43.972989',
    longitude: '-73.102731',
    timeZoneId: 'America/New_York',
  },
  '05741': {
    zip: '05741',
    city: 'East Poultney',
    state: 'VT',
    latitude: '43.527104',
    longitude: '-73.206396',
    timeZoneId: 'America/New_York',
  },
  '05742': {
    zip: '05742',
    city: 'East Wallingford',
    state: 'VT',
    latitude: '43.433994',
    longitude: '-72.877559',
    timeZoneId: 'America/New_York',
  },
  '05743': {
    zip: '05743',
    city: 'Fair Haven',
    state: 'VT',
    latitude: '43.594271',
    longitude: '-73.266808',
    timeZoneId: 'America/New_York',
  },
  '05744': {
    zip: '05744',
    city: 'Florence',
    state: 'VT',
    latitude: '43.595204',
    longitude: '-73.048443',
    timeZoneId: 'America/New_York',
  },
  '05745': {
    zip: '05745',
    city: 'Forest Dale',
    state: 'VT',
    latitude: '43.829518',
    longitude: '-73.054531',
    timeZoneId: 'America/New_York',
  },
  '05746': {
    zip: '05746',
    city: 'Gaysville',
    state: 'VT',
    latitude: '43.734541',
    longitude: '-72.74305',
    timeZoneId: 'America/New_York',
  },
  '05747': {
    zip: '05747',
    city: 'Granville',
    state: 'VT',
    latitude: '44.006169',
    longitude: '-72.841833',
    timeZoneId: 'America/New_York',
  },
  '05748': {
    zip: '05748',
    city: 'Hancock',
    state: 'VT',
    latitude: '43.918448',
    longitude: '-72.916704',
    timeZoneId: 'America/New_York',
  },
  '05750': {
    zip: '05750',
    city: 'Hydeville',
    state: 'VT',
    latitude: '43.606837',
    longitude: '-73.228695',
    timeZoneId: 'America/New_York',
  },
  '05751': {
    zip: '05751',
    city: 'Killington',
    state: 'VT',
    latitude: '43.651386',
    longitude: '-72.791802',
    timeZoneId: 'America/New_York',
  },
  '05753': {
    zip: '05753',
    city: 'Middlebury',
    state: 'VT',
    latitude: '44.012313',
    longitude: '-73.163211',
    timeZoneId: 'America/New_York',
  },
  '05757': {
    zip: '05757',
    city: 'Middletown Springs',
    state: 'VT',
    latitude: '43.50108',
    longitude: '-73.12326',
    timeZoneId: 'America/New_York',
  },
  '05758': {
    zip: '05758',
    city: 'Mount Holly',
    state: 'VT',
    latitude: '43.4508',
    longitude: '-72.79158',
    timeZoneId: 'America/New_York',
  },
  '05759': {
    zip: '05759',
    city: 'North Clarendon',
    state: 'VT',
    latitude: '43.5489',
    longitude: '-72.95808',
    timeZoneId: 'America/New_York',
  },
  '05760': {
    zip: '05760',
    city: 'Orwell',
    state: 'VT',
    latitude: '43.785111',
    longitude: '-73.276135',
    timeZoneId: 'America/New_York',
  },
  '05761': {
    zip: '05761',
    city: 'Pawlet',
    state: 'VT',
    latitude: '43.3617',
    longitude: '-73.1343',
    timeZoneId: 'America/New_York',
  },
  '05762': {
    zip: '05762',
    city: 'Pittsfield',
    state: 'VT',
    latitude: '43.759163',
    longitude: '-72.855098',
    timeZoneId: 'America/New_York',
  },
  '05763': {
    zip: '05763',
    city: 'Pittsford',
    state: 'VT',
    latitude: '43.767228',
    longitude: '-72.969037',
    timeZoneId: 'America/New_York',
  },
  '05764': {
    zip: '05764',
    city: 'Poultney',
    state: 'VT',
    latitude: '43.518045',
    longitude: '-73.236985',
    timeZoneId: 'America/New_York',
  },
  '05765': {
    zip: '05765',
    city: 'Proctor',
    state: 'VT',
    latitude: '43.65757',
    longitude: '-73.034159',
    timeZoneId: 'America/New_York',
  },
  '05766': {
    zip: '05766',
    city: 'Ripton',
    state: 'VT',
    latitude: '44.015278',
    longitude: '-73.167778',
    timeZoneId: 'America/New_York',
  },
  '05767': {
    zip: '05767',
    city: 'Rochester',
    state: 'VT',
    latitude: '43.867841',
    longitude: '-72.825813',
    timeZoneId: 'America/New_York',
  },
  '05768': {
    zip: '05768',
    city: 'Rupert',
    state: 'VT',
    latitude: '43.263597',
    longitude: '-73.22222',
    timeZoneId: 'America/New_York',
  },
  '05769': {
    zip: '05769',
    city: 'Salisbury',
    state: 'VT',
    latitude: '43.90332',
    longitude: '-73.0953',
    timeZoneId: 'America/New_York',
  },
  '05770': {
    zip: '05770',
    city: 'Shoreham',
    state: 'VT',
    latitude: '43.881442',
    longitude: '-73.304785',
    timeZoneId: 'America/New_York',
  },
  '05772': {
    zip: '05772',
    city: 'Stockbridge',
    state: 'VT',
    latitude: '43.770493',
    longitude: '-72.783716',
    timeZoneId: 'America/New_York',
  },
  '05773': {
    zip: '05773',
    city: 'Wallingford',
    state: 'VT',
    latitude: '43.46358',
    longitude: '-72.99036',
    timeZoneId: 'America/New_York',
  },
  '05774': {
    zip: '05774',
    city: 'Wells',
    state: 'VT',
    latitude: '43.432771',
    longitude: '-73.196315',
    timeZoneId: 'America/New_York',
  },
  '05775': {
    zip: '05775',
    city: 'West Pawlet',
    state: 'VT',
    latitude: '43.360535',
    longitude: '-73.225173',
    timeZoneId: 'America/New_York',
  },
  '05776': {
    zip: '05776',
    city: 'West Rupert',
    state: 'VT',
    latitude: '43.26553',
    longitude: '-73.191885',
    timeZoneId: 'America/New_York',
  },
  '05777': {
    zip: '05777',
    city: 'West Rutland',
    state: 'VT',
    latitude: '43.547778',
    longitude: '-73.022778',
    timeZoneId: 'America/New_York',
  },
  '05778': {
    zip: '05778',
    city: 'Whiting',
    state: 'VT',
    latitude: '43.885116',
    longitude: '-73.211804',
    timeZoneId: 'America/New_York',
  },
  '05819': {
    zip: '05819',
    city: 'Saint Johnsbury',
    state: 'VT',
    latitude: '44.43467',
    longitude: '-72.03817',
    timeZoneId: 'America/New_York',
  },
  '05820': {
    zip: '05820',
    city: 'Albany',
    state: 'VT',
    latitude: '44.882839',
    longitude: '-72.215376',
    timeZoneId: 'America/New_York',
  },
  '05821': {
    zip: '05821',
    city: 'Barnet',
    state: 'VT',
    latitude: '44.314722',
    longitude: '-72.166389',
    timeZoneId: 'America/New_York',
  },
  '05822': {
    zip: '05822',
    city: 'Barton',
    state: 'VT',
    latitude: '44.752209',
    longitude: '-72.184037',
    timeZoneId: 'America/New_York',
  },
  '05823': {
    zip: '05823',
    city: 'Beebe Plain',
    state: 'VT',
    latitude: '45.005425',
    longitude: '-72.143158',
    timeZoneId: 'America/New_York',
  },
  '05824': {
    zip: '05824',
    city: 'Concord',
    state: 'VT',
    latitude: '44.4354',
    longitude: '-71.85432',
    timeZoneId: 'America/New_York',
  },
  '05825': {
    zip: '05825',
    city: 'Coventry',
    state: 'VT',
    latitude: '44.869361',
    longitude: '-72.236575',
    timeZoneId: 'America/New_York',
  },
  '05826': {
    zip: '05826',
    city: 'Craftsbury',
    state: 'VT',
    latitude: '44.642564',
    longitude: '-72.352204',
    timeZoneId: 'America/New_York',
  },
  '05827': {
    zip: '05827',
    city: 'Craftsbury Common',
    state: 'VT',
    latitude: '44.65',
    longitude: '-72.383333',
    timeZoneId: 'America/New_York',
  },
  '05828': {
    zip: '05828',
    city: 'Danville',
    state: 'VT',
    latitude: '44.438199',
    longitude: '-72.114702',
    timeZoneId: 'America/New_York',
  },
  '05829': {
    zip: '05829',
    city: 'Derby',
    state: 'VT',
    latitude: '44.93606',
    longitude: '-72.131684',
    timeZoneId: 'America/New_York',
  },
  '05830': {
    zip: '05830',
    city: 'Derby Line',
    state: 'VT',
    latitude: '45.001164',
    longitude: '-72.103249',
    timeZoneId: 'America/New_York',
  },
  '05832': {
    zip: '05832',
    city: 'East Burke',
    state: 'VT',
    latitude: '44.604179',
    longitude: '-71.918969',
    timeZoneId: 'America/New_York',
  },
  '05833': {
    zip: '05833',
    city: 'East Charleston',
    state: 'VT',
    latitude: '44.823299',
    longitude: '-71.986472',
    timeZoneId: 'America/New_York',
  },
  '05836': {
    zip: '05836',
    city: 'East Hardwick',
    state: 'VT',
    latitude: '44.518538',
    longitude: '-72.304708',
    timeZoneId: 'America/New_York',
  },
  '05837': {
    zip: '05837',
    city: 'East Haven',
    state: 'VT',
    latitude: '44.655307',
    longitude: '-71.826045',
    timeZoneId: 'America/New_York',
  },
  '05838': {
    zip: '05838',
    city: 'East Saint Johnsbury',
    state: 'VT',
    latitude: '44.439437',
    longitude: '-71.94369',
    timeZoneId: 'America/New_York',
  },
  '05839': {
    zip: '05839',
    city: 'Glover',
    state: 'VT',
    latitude: '44.70282',
    longitude: '-72.20922',
    timeZoneId: 'America/New_York',
  },
  '05840': {
    zip: '05840',
    city: 'Granby',
    state: 'VT',
    latitude: '44.572522',
    longitude: '-71.760456',
    timeZoneId: 'America/New_York',
  },
  '05841': {
    zip: '05841',
    city: 'Greensboro',
    state: 'VT',
    latitude: '44.582214',
    longitude: '-72.297887',
    timeZoneId: 'America/New_York',
  },
  '05842': {
    zip: '05842',
    city: 'Greensboro Bend',
    state: 'VT',
    latitude: '44.5569',
    longitude: '-72.25134',
    timeZoneId: 'America/New_York',
  },
  '05843': {
    zip: '05843',
    city: 'Hardwick',
    state: 'VT',
    latitude: '44.504809',
    longitude: '-72.357211',
    timeZoneId: 'America/New_York',
  },
  '05845': {
    zip: '05845',
    city: 'Irasburg',
    state: 'VT',
    latitude: '44.8206',
    longitude: '-72.2784',
    timeZoneId: 'America/New_York',
  },
  '05846': {
    zip: '05846',
    city: 'Island Pond',
    state: 'VT',
    latitude: '44.7963',
    longitude: '-71.85558',
    timeZoneId: 'America/New_York',
  },
  '05847': {
    zip: '05847',
    city: 'Lowell',
    state: 'VT',
    latitude: '44.790453',
    longitude: '-72.457109',
    timeZoneId: 'America/New_York',
  },
  '05848': {
    zip: '05848',
    city: 'Lower Waterford',
    state: 'VT',
    latitude: '44.355575',
    longitude: '-71.908056',
    timeZoneId: 'America/New_York',
  },
  '05849': {
    zip: '05849',
    city: 'Lyndon',
    state: 'VT',
    latitude: '44.532888',
    longitude: '-72.000285',
    timeZoneId: 'America/New_York',
  },
  '05850': {
    zip: '05850',
    city: 'Lyndon Center',
    state: 'VT',
    latitude: '44.534562',
    longitude: '-72.010872',
    timeZoneId: 'America/New_York',
  },
  '05851': {
    zip: '05851',
    city: 'Lyndonville',
    state: 'VT',
    latitude: '44.54412',
    longitude: '-72.0213',
    timeZoneId: 'America/New_York',
  },
  '05853': {
    zip: '05853',
    city: 'Morgan',
    state: 'VT',
    latitude: '44.909167',
    longitude: '-71.979444',
    timeZoneId: 'America/New_York',
  },
  '05855': {
    zip: '05855',
    city: 'Newport',
    state: 'VT',
    latitude: '44.941733',
    longitude: '-72.203234',
    timeZoneId: 'America/New_York',
  },
  '05857': {
    zip: '05857',
    city: 'Newport Center',
    state: 'VT',
    latitude: '44.950833',
    longitude: '-72.306944',
    timeZoneId: 'America/New_York',
  },
  '05858': {
    zip: '05858',
    city: 'North Concord',
    state: 'VT',
    latitude: '44.575',
    longitude: '-71.784722',
    timeZoneId: 'America/New_York',
  },
  '05859': {
    zip: '05859',
    city: 'North Troy',
    state: 'VT',
    latitude: '45',
    longitude: '-72.4',
    timeZoneId: 'America/New_York',
  },
  '05860': {
    zip: '05860',
    city: 'Orleans',
    state: 'VT',
    latitude: '44.807079',
    longitude: '-72.201511',
    timeZoneId: 'America/New_York',
  },
  '05861': {
    zip: '05861',
    city: 'Passumpsic',
    state: 'VT',
    latitude: '44.377353',
    longitude: '-72.031425',
    timeZoneId: 'America/New_York',
  },
  '05862': {
    zip: '05862',
    city: 'Peacham',
    state: 'VT',
    latitude: '44.338056',
    longitude: '-72.157778',
    timeZoneId: 'America/New_York',
  },
  '05863': {
    zip: '05863',
    city: 'Saint Johnsbury Center',
    state: 'VT',
    latitude: '44.452899',
    longitude: '-72.012143',
    timeZoneId: 'America/New_York',
  },
  '05866': {
    zip: '05866',
    city: 'Sheffield',
    state: 'VT',
    latitude: '44.63972',
    longitude: '-72.125008',
    timeZoneId: 'America/New_York',
  },
  '05867': {
    zip: '05867',
    city: 'Sutton',
    state: 'VT',
    latitude: '44.663871',
    longitude: '-72.037012',
    timeZoneId: 'America/New_York',
  },
  '05868': {
    zip: '05868',
    city: 'Troy',
    state: 'VT',
    latitude: '44.949273',
    longitude: '-72.393535',
    timeZoneId: 'America/New_York',
  },
  '05871': {
    zip: '05871',
    city: 'West Burke',
    state: 'VT',
    latitude: '44.616667',
    longitude: '-71.933333',
    timeZoneId: 'America/New_York',
  },
  '05872': {
    zip: '05872',
    city: 'West Charleston',
    state: 'VT',
    latitude: '44.898889',
    longitude: '-72.06',
    timeZoneId: 'America/New_York',
  },
  '05873': {
    zip: '05873',
    city: 'West Danville',
    state: 'VT',
    latitude: '44.4459',
    longitude: '-72.22452',
    timeZoneId: 'America/New_York',
  },
  '05874': {
    zip: '05874',
    city: 'Westfield',
    state: 'VT',
    latitude: '44.875475',
    longitude: '-72.465214',
    timeZoneId: 'America/New_York',
  },
  '05875': {
    zip: '05875',
    city: 'Barton',
    state: 'VT',
    latitude: '44.696433',
    longitude: '-72.268832',
    timeZoneId: 'America/New_York',
  },
  '05901': {
    zip: '05901',
    city: 'Averill',
    state: 'VT',
    latitude: '44.942849',
    longitude: '-71.679618',
    timeZoneId: 'America/New_York',
  },
  '05902': {
    zip: '05902',
    city: 'Beecher Falls',
    state: 'VT',
    latitude: '45.005352',
    longitude: '-71.518858',
    timeZoneId: 'America/New_York',
  },
  '05903': {
    zip: '05903',
    city: 'Canaan',
    state: 'VT',
    latitude: '44.972195',
    longitude: '-71.584884',
    timeZoneId: 'America/New_York',
  },
  '05904': {
    zip: '05904',
    city: 'Gilman',
    state: 'VT',
    latitude: '44.417023',
    longitude: '-71.716414',
    timeZoneId: 'America/New_York',
  },
  '05905': {
    zip: '05905',
    city: 'Guildhall',
    state: 'VT',
    latitude: '44.73372',
    longitude: '-71.6415',
    timeZoneId: 'America/New_York',
  },
  '05906': {
    zip: '05906',
    city: 'Lunenburg',
    state: 'VT',
    latitude: '44.429167',
    longitude: '-71.679722',
    timeZoneId: 'America/New_York',
  },
  '05907': {
    zip: '05907',
    city: 'Norton',
    state: 'VT',
    latitude: '44.946724',
    longitude: '-71.827837',
    timeZoneId: 'America/New_York',
  },
  '06001': {
    zip: '06001',
    city: 'Avon',
    state: 'CT',
    latitude: '41.794016',
    longitude: '-72.860016',
    timeZoneId: 'America/New_York',
  },
  '06002': {
    zip: '06002',
    city: 'Bloomfield',
    state: 'CT',
    latitude: '41.839367',
    longitude: '-72.741195',
    timeZoneId: 'America/New_York',
  },
  '06006': {
    zip: '06006',
    city: 'Windsor',
    state: 'CT',
    latitude: '41.854244',
    longitude: '-72.644045',
    timeZoneId: 'America/New_York',
  },
  '06010': {
    zip: '06010',
    city: 'Bristol',
    state: 'CT',
    latitude: '41.679705',
    longitude: '-72.931387',
    timeZoneId: 'America/New_York',
  },
  '06011': {
    zip: '06011',
    city: 'Bristol',
    state: 'CT',
    latitude: '41.677462',
    longitude: '-72.946279',
    timeZoneId: 'America/New_York',
  },
  '06013': {
    zip: '06013',
    city: 'Burlington',
    state: 'CT',
    latitude: '41.760236',
    longitude: '-72.950679',
    timeZoneId: 'America/New_York',
  },
  '06016': {
    zip: '06016',
    city: 'Broad Brook',
    state: 'CT',
    latitude: '41.908366',
    longitude: '-72.542144',
    timeZoneId: 'America/New_York',
  },
  '06018': {
    zip: '06018',
    city: 'Canaan',
    state: 'CT',
    latitude: '42.00978',
    longitude: '-73.29816',
    timeZoneId: 'America/New_York',
  },
  '06019': {
    zip: '06019',
    city: 'Canton',
    state: 'CT',
    latitude: '41.83582',
    longitude: '-72.896405',
    timeZoneId: 'America/New_York',
  },
  '06020': {
    zip: '06020',
    city: 'Canton Center',
    state: 'CT',
    latitude: '41.849426',
    longitude: '-72.922674',
    timeZoneId: 'America/New_York',
  },
  '06021': {
    zip: '06021',
    city: 'Colebrook',
    state: 'CT',
    latitude: '41.999755',
    longitude: '-73.098758',
    timeZoneId: 'America/New_York',
  },
  '06022': {
    zip: '06022',
    city: 'Collinsville',
    state: 'CT',
    latitude: '41.882336',
    longitude: '-72.927691',
    timeZoneId: 'America/New_York',
  },
  '06023': {
    zip: '06023',
    city: 'East Berlin',
    state: 'CT',
    latitude: '41.61589',
    longitude: '-72.717823',
    timeZoneId: 'America/New_York',
  },
  '06024': {
    zip: '06024',
    city: 'East Canaan',
    state: 'CT',
    latitude: '42.014044',
    longitude: '-73.287474',
    timeZoneId: 'America/New_York',
  },
  '06025': {
    zip: '06025',
    city: 'East Glastonbury',
    state: 'CT',
    latitude: '41.690889',
    longitude: '-72.534917',
    timeZoneId: 'America/New_York',
  },
  '06026': {
    zip: '06026',
    city: 'East Granby',
    state: 'CT',
    latitude: '41.934274',
    longitude: '-72.746685',
    timeZoneId: 'America/New_York',
  },
  '06027': {
    zip: '06027',
    city: 'East Hartland',
    state: 'CT',
    latitude: '42.004268',
    longitude: '-72.936417',
    timeZoneId: 'America/New_York',
  },
  '06028': {
    zip: '06028',
    city: 'East Windsor Hill',
    state: 'CT',
    latitude: '41.864145',
    longitude: '-72.599902',
    timeZoneId: 'America/New_York',
  },
  '06029': {
    zip: '06029',
    city: 'Ellington',
    state: 'CT',
    latitude: '41.917142',
    longitude: '-72.443805',
    timeZoneId: 'America/New_York',
  },
  '06030': {
    zip: '06030',
    city: 'Farmington',
    state: 'CT',
    latitude: '41.711768',
    longitude: '-72.839345',
    timeZoneId: 'America/New_York',
  },
  '06031': {
    zip: '06031',
    city: 'Falls Village',
    state: 'CT',
    latitude: '41.96316',
    longitude: '-73.31616',
    timeZoneId: 'America/New_York',
  },
  '06032': {
    zip: '06032',
    city: 'Farmington',
    state: 'CT',
    latitude: '41.725812',
    longitude: '-72.835995',
    timeZoneId: 'America/New_York',
  },
  '06033': {
    zip: '06033',
    city: 'Glastonbury',
    state: 'CT',
    latitude: '41.707148',
    longitude: '-72.563304',
    timeZoneId: 'America/New_York',
  },
  '06034': {
    zip: '06034',
    city: 'Farmington',
    state: 'CT',
    latitude: '41.708905',
    longitude: '-72.873483',
    timeZoneId: 'America/New_York',
  },
  '06035': {
    zip: '06035',
    city: 'Granby',
    state: 'CT',
    latitude: '41.953412',
    longitude: '-72.796813',
    timeZoneId: 'America/New_York',
  },
  '06037': {
    zip: '06037',
    city: 'Berlin',
    state: 'CT',
    latitude: '41.60778',
    longitude: '-72.7701',
    timeZoneId: 'America/New_York',
  },
  '06039': {
    zip: '06039',
    city: 'Lakeville',
    state: 'CT',
    latitude: '41.95205',
    longitude: '-73.433986',
    timeZoneId: 'America/New_York',
  },
  '06040': {
    zip: '06040',
    city: 'Manchester',
    state: 'CT',
    latitude: '41.777443',
    longitude: '-72.524997',
    timeZoneId: 'America/New_York',
  },
  '06041': {
    zip: '06041',
    city: 'Manchester',
    state: 'CT',
    latitude: '41.774955',
    longitude: '-72.521556',
    timeZoneId: 'America/New_York',
  },
  '06042': {
    zip: '06042',
    city: 'Manchester',
    state: 'CT',
    latitude: '41.8008',
    longitude: '-72.5159',
    timeZoneId: 'America/New_York',
  },
  '06043': {
    zip: '06043',
    city: 'Bolton',
    state: 'CT',
    latitude: '41.774734',
    longitude: '-72.438821',
    timeZoneId: 'America/New_York',
  },
  '06045': {
    zip: '06045',
    city: 'Manchester',
    state: 'CT',
    latitude: '41.775734',
    longitude: '-72.5232',
    timeZoneId: 'America/New_York',
  },
  '06050': {
    zip: '06050',
    city: 'New Britain',
    state: 'CT',
    latitude: '41.673633',
    longitude: '-72.778821',
    timeZoneId: 'America/New_York',
  },
  '06051': {
    zip: '06051',
    city: 'New Britain',
    state: 'CT',
    latitude: '41.662408',
    longitude: '-72.770431',
    timeZoneId: 'America/New_York',
  },
  '06052': {
    zip: '06052',
    city: 'New Britain',
    state: 'CT',
    latitude: '41.657801',
    longitude: '-72.802874',
    timeZoneId: 'America/New_York',
  },
  '06053': {
    zip: '06053',
    city: 'New Britain',
    state: 'CT',
    latitude: '41.68461',
    longitude: '-72.790399',
    timeZoneId: 'America/New_York',
  },
  '06057': {
    zip: '06057',
    city: 'New Hartford',
    state: 'CT',
    latitude: '41.84436',
    longitude: '-73.0128',
    timeZoneId: 'America/New_York',
  },
  '06058': {
    zip: '06058',
    city: 'Norfolk',
    state: 'CT',
    latitude: '41.990342',
    longitude: '-73.197249',
    timeZoneId: 'America/New_York',
  },
  '06059': {
    zip: '06059',
    city: 'North Canton',
    state: 'CT',
    latitude: '41.898217',
    longitude: '-72.892627',
    timeZoneId: 'America/New_York',
  },
  '06060': {
    zip: '06060',
    city: 'North Granby',
    state: 'CT',
    latitude: '42.010228',
    longitude: '-72.840536',
    timeZoneId: 'America/New_York',
  },
  '06061': {
    zip: '06061',
    city: 'Pine Meadow',
    state: 'CT',
    latitude: '41.917178',
    longitude: '-73.063706',
    timeZoneId: 'America/New_York',
  },
  '06062': {
    zip: '06062',
    city: 'Plainville',
    state: 'CT',
    latitude: '41.669972',
    longitude: '-72.864805',
    timeZoneId: 'America/New_York',
  },
  '06063': {
    zip: '06063',
    city: 'Barkhamsted',
    state: 'CT',
    latitude: '41.917156',
    longitude: '-72.977392',
    timeZoneId: 'America/New_York',
  },
  '06064': {
    zip: '06064',
    city: 'Poquonock',
    state: 'CT',
    latitude: '41.905959',
    longitude: '-72.682676',
    timeZoneId: 'America/New_York',
  },
  '06065': {
    zip: '06065',
    city: 'Riverton',
    state: 'CT',
    latitude: '41.981983',
    longitude: '-73.004395',
    timeZoneId: 'America/New_York',
  },
  '06066': {
    zip: '06066',
    city: 'Vernon Rockville',
    state: 'CT',
    latitude: '41.844674',
    longitude: '-72.463586',
    timeZoneId: 'America/New_York',
  },
  '06067': {
    zip: '06067',
    city: 'Rocky Hill',
    state: 'CT',
    latitude: '41.662537',
    longitude: '-72.668832',
    timeZoneId: 'America/New_York',
  },
  '06068': {
    zip: '06068',
    city: 'Salisbury',
    state: 'CT',
    latitude: '42.022364',
    longitude: '-73.400985',
    timeZoneId: 'America/New_York',
  },
  '06069': {
    zip: '06069',
    city: 'Sharon',
    state: 'CT',
    latitude: '41.865139',
    longitude: '-73.455872',
    timeZoneId: 'America/New_York',
  },
  '06070': {
    zip: '06070',
    city: 'Simsbury',
    state: 'CT',
    latitude: '41.876721',
    longitude: '-72.822749',
    timeZoneId: 'America/New_York',
  },
  '06071': {
    zip: '06071',
    city: 'Somers',
    state: 'CT',
    latitude: '41.994749',
    longitude: '-72.45126',
    timeZoneId: 'America/New_York',
  },
  '06072': {
    zip: '06072',
    city: 'Somersville',
    state: 'CT',
    latitude: '41.981639',
    longitude: '-72.492023',
    timeZoneId: 'America/New_York',
  },
  '06073': {
    zip: '06073',
    city: 'South Glastonbury',
    state: 'CT',
    latitude: '41.663072',
    longitude: '-72.573262',
    timeZoneId: 'America/New_York',
  },
  '06074': {
    zip: '06074',
    city: 'South Windsor',
    state: 'CT',
    latitude: '41.83488',
    longitude: '-72.57132',
    timeZoneId: 'America/New_York',
  },
  '06075': {
    zip: '06075',
    city: 'Stafford',
    state: 'CT',
    latitude: '41.987932',
    longitude: '-72.287083',
    timeZoneId: 'America/New_York',
  },
  '06076': {
    zip: '06076',
    city: 'Stafford Springs',
    state: 'CT',
    latitude: '41.98512',
    longitude: '-72.25566',
    timeZoneId: 'America/New_York',
  },
  '06077': {
    zip: '06077',
    city: 'Staffordville',
    state: 'CT',
    latitude: '41.994937',
    longitude: '-72.260286',
    timeZoneId: 'America/New_York',
  },
  '06078': {
    zip: '06078',
    city: 'Suffield',
    state: 'CT',
    latitude: '41.986956',
    longitude: '-72.64824',
    timeZoneId: 'America/New_York',
  },
  '06079': {
    zip: '06079',
    city: 'Taconic',
    state: 'CT',
    latitude: '42.035556',
    longitude: '-73.408333',
    timeZoneId: 'America/New_York',
  },
  '06080': {
    zip: '06080',
    city: 'Suffield',
    state: 'CT',
    latitude: '41.982198',
    longitude: '-72.649731',
    timeZoneId: 'America/New_York',
  },
  '06081': {
    zip: '06081',
    city: 'Tariffville',
    state: 'CT',
    latitude: '41.906091',
    longitude: '-72.769185',
    timeZoneId: 'America/New_York',
  },
  '06082': {
    zip: '06082',
    city: 'Enfield',
    state: 'CT',
    latitude: '41.986942',
    longitude: '-72.565275',
    timeZoneId: 'America/New_York',
  },
  '06083': {
    zip: '06083',
    city: 'Enfield',
    state: 'CT',
    latitude: '41.998183',
    longitude: '-72.576392',
    timeZoneId: 'America/New_York',
  },
  '06084': {
    zip: '06084',
    city: 'Tolland',
    state: 'CT',
    latitude: '41.872855',
    longitude: '-72.372213',
    timeZoneId: 'America/New_York',
  },
  '06085': {
    zip: '06085',
    city: 'Unionville',
    state: 'CT',
    latitude: '41.752359',
    longitude: '-72.892851',
    timeZoneId: 'America/New_York',
  },
  '06088': {
    zip: '06088',
    city: 'East Windsor',
    state: 'CT',
    latitude: '41.902833',
    longitude: '-72.596403',
    timeZoneId: 'America/New_York',
  },
  '06089': {
    zip: '06089',
    city: 'Weatogue',
    state: 'CT',
    latitude: '41.841162',
    longitude: '-72.823567',
    timeZoneId: 'America/New_York',
  },
  '06090': {
    zip: '06090',
    city: 'West Granby',
    state: 'CT',
    latitude: '41.953225',
    longitude: '-72.860826',
    timeZoneId: 'America/New_York',
  },
  '06091': {
    zip: '06091',
    city: 'West Hartland',
    state: 'CT',
    latitude: '41.95293',
    longitude: '-72.935237',
    timeZoneId: 'America/New_York',
  },
  '06092': {
    zip: '06092',
    city: 'West Simsbury',
    state: 'CT',
    latitude: '41.870101',
    longitude: '-72.86181',
    timeZoneId: 'America/New_York',
  },
  '06093': {
    zip: '06093',
    city: 'West Suffield',
    state: 'CT',
    latitude: '42.011488',
    longitude: '-72.735632',
    timeZoneId: 'America/New_York',
  },
  '06094': {
    zip: '06094',
    city: 'Winchester Center',
    state: 'CT',
    latitude: '41.901881',
    longitude: '-73.142301',
    timeZoneId: 'America/New_York',
  },
  '06095': {
    zip: '06095',
    city: 'Windsor',
    state: 'CT',
    latitude: '41.863764',
    longitude: '-72.668509',
    timeZoneId: 'America/New_York',
  },
  '06096': {
    zip: '06096',
    city: 'Windsor Locks',
    state: 'CT',
    latitude: '41.925995',
    longitude: '-72.643635',
    timeZoneId: 'America/New_York',
  },
  '06098': {
    zip: '06098',
    city: 'Winsted',
    state: 'CT',
    latitude: '41.921164',
    longitude: '-73.067666',
    timeZoneId: 'America/New_York',
  },
  '06101': {
    zip: '06101',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.788224',
    longitude: '-72.665673',
    timeZoneId: 'America/New_York',
  },
  '06102': {
    zip: '06102',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.765945',
    longitude: '-72.670056',
    timeZoneId: 'America/New_York',
  },
  '06103': {
    zip: '06103',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.767862',
    longitude: '-72.674838',
    timeZoneId: 'America/New_York',
  },
  '06104': {
    zip: '06104',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.788898',
    longitude: '-72.665246',
    timeZoneId: 'America/New_York',
  },
  '06105': {
    zip: '06105',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.769774',
    longitude: '-72.699048',
    timeZoneId: 'America/New_York',
  },
  '06106': {
    zip: '06106',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.74668',
    longitude: '-72.696298',
    timeZoneId: 'America/New_York',
  },
  '06107': {
    zip: '06107',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.756512',
    longitude: '-72.758753',
    timeZoneId: 'America/New_York',
  },
  '06108': {
    zip: '06108',
    city: 'East Hartford',
    state: 'CT',
    latitude: '41.776681',
    longitude: '-72.622928',
    timeZoneId: 'America/New_York',
  },
  '06109': {
    zip: '06109',
    city: 'Wethersfield',
    state: 'CT',
    latitude: '41.702514',
    longitude: '-72.667027',
    timeZoneId: 'America/New_York',
  },
  '06110': {
    zip: '06110',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.73287',
    longitude: '-72.735989',
    timeZoneId: 'America/New_York',
  },
  '06111': {
    zip: '06111',
    city: 'Newington',
    state: 'CT',
    latitude: '41.688552',
    longitude: '-72.729085',
    timeZoneId: 'America/New_York',
  },
  '06112': {
    zip: '06112',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.794394',
    longitude: '-72.696348',
    timeZoneId: 'America/New_York',
  },
  '06114': {
    zip: '06114',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.74105',
    longitude: '-72.669085',
    timeZoneId: 'America/New_York',
  },
  '06115': {
    zip: '06115',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.76056',
    longitude: '-72.67405',
    timeZoneId: 'America/New_York',
  },
  '06117': {
    zip: '06117',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.77787',
    longitude: '-72.756911',
    timeZoneId: 'America/New_York',
  },
  '06118': {
    zip: '06118',
    city: 'East Hartford',
    state: 'CT',
    latitude: '41.748563',
    longitude: '-72.609938',
    timeZoneId: 'America/New_York',
  },
  '06119': {
    zip: '06119',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.760843',
    longitude: '-72.729064',
    timeZoneId: 'America/New_York',
  },
  '06120': {
    zip: '06120',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.78264',
    longitude: '-72.67186',
    timeZoneId: 'America/New_York',
  },
  '06123': {
    zip: '06123',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.765654',
    longitude: '-72.686093',
    timeZoneId: 'America/New_York',
  },
  '06126': {
    zip: '06126',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.766509',
    longitude: '-72.679994',
    timeZoneId: 'America/New_York',
  },
  '06127': {
    zip: '06127',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.766715',
    longitude: '-72.682282',
    timeZoneId: 'America/New_York',
  },
  '06128': {
    zip: '06128',
    city: 'East Hartford',
    state: 'CT',
    latitude: '41.781773',
    longitude: '-72.62246',
    timeZoneId: 'America/New_York',
  },
  '06129': {
    zip: '06129',
    city: 'Wethersfield',
    state: 'CT',
    latitude: '41.708586',
    longitude: '-72.659766',
    timeZoneId: 'America/New_York',
  },
  '06131': {
    zip: '06131',
    city: 'Newington',
    state: 'CT',
    latitude: '41.68869',
    longitude: '-72.729814',
    timeZoneId: 'America/New_York',
  },
  '06132': {
    zip: '06132',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.768695',
    longitude: '-72.683515',
    timeZoneId: 'America/New_York',
  },
  '06133': {
    zip: '06133',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.753216',
    longitude: '-72.704041',
    timeZoneId: 'America/New_York',
  },
  '06134': {
    zip: '06134',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.766457',
    longitude: '-72.682703',
    timeZoneId: 'America/New_York',
  },
  '06137': {
    zip: '06137',
    city: 'West Hartford',
    state: 'CT',
    latitude: '41.584485',
    longitude: '-72.865338',
    timeZoneId: 'America/New_York',
  },
  '06138': {
    zip: '06138',
    city: 'East Hartford',
    state: 'CT',
    latitude: '41.76168',
    longitude: '-72.61261',
    timeZoneId: 'America/New_York',
  },
  '06140': {
    zip: '06140',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.789823',
    longitude: '-72.663704',
    timeZoneId: 'America/New_York',
  },
  '06141': {
    zip: '06141',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.792221',
    longitude: '-72.665323',
    timeZoneId: 'America/New_York',
  },
  '06142': {
    zip: '06142',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.775997',
    longitude: '-72.697466',
    timeZoneId: 'America/New_York',
  },
  '06143': {
    zip: '06143',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.790844',
    longitude: '-72.66892',
    timeZoneId: 'America/New_York',
  },
  '06144': {
    zip: '06144',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.790732',
    longitude: '-72.669992',
    timeZoneId: 'America/New_York',
  },
  '06145': {
    zip: '06145',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.791818',
    longitude: '-72.668284',
    timeZoneId: 'America/New_York',
  },
  '06146': {
    zip: '06146',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.788567',
    longitude: '-72.665122',
    timeZoneId: 'America/New_York',
  },
  '06147': {
    zip: '06147',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.79111',
    longitude: '-72.668684',
    timeZoneId: 'America/New_York',
  },
  '06150': {
    zip: '06150',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.765671',
    longitude: '-72.675155',
    timeZoneId: 'America/New_York',
  },
  '06151': {
    zip: '06151',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.7663',
    longitude: '-72.673122',
    timeZoneId: 'America/New_York',
  },
  '06152': {
    zip: '06152',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.757059',
    longitude: '-72.688643',
    timeZoneId: 'America/New_York',
  },
  '06153': {
    zip: '06153',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.688148',
    longitude: '-72.728859',
    timeZoneId: 'America/New_York',
  },
  '06154': {
    zip: '06154',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.773119',
    longitude: '-72.707051',
    timeZoneId: 'America/New_York',
  },
  '06155': {
    zip: '06155',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.757156',
    longitude: '-72.743576',
    timeZoneId: 'America/New_York',
  },
  '06156': {
    zip: '06156',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.75895',
    longitude: '-72.732086',
    timeZoneId: 'America/New_York',
  },
  '06160': {
    zip: '06160',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.771494',
    longitude: '-72.707813',
    timeZoneId: 'America/New_York',
  },
  '06161': {
    zip: '06161',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.70107',
    longitude: '-72.667463',
    timeZoneId: 'America/New_York',
  },
  '06167': {
    zip: '06167',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.768325',
    longitude: '-72.682921',
    timeZoneId: 'America/New_York',
  },
  '06176': {
    zip: '06176',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.768415',
    longitude: '-72.669185',
    timeZoneId: 'America/New_York',
  },
  '06180': {
    zip: '06180',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.757571',
    longitude: '-72.76472',
    timeZoneId: 'America/New_York',
  },
  '06183': {
    zip: '06183',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.764012',
    longitude: '-72.68595',
    timeZoneId: 'America/New_York',
  },
  '06199': {
    zip: '06199',
    city: 'Hartford',
    state: 'CT',
    latitude: '41.92683',
    longitude: '-72.654423',
    timeZoneId: 'America/New_York',
  },
  '06226': {
    zip: '06226',
    city: 'Willimantic',
    state: 'CT',
    latitude: '41.714269',
    longitude: '-72.211943',
    timeZoneId: 'America/New_York',
  },
  '06230': {
    zip: '06230',
    city: 'Abington',
    state: 'CT',
    latitude: '41.846519',
    longitude: '-72.013672',
    timeZoneId: 'America/New_York',
  },
  '06231': {
    zip: '06231',
    city: 'Amston',
    state: 'CT',
    latitude: '41.627816',
    longitude: '-72.364549',
    timeZoneId: 'America/New_York',
  },
  '06232': {
    zip: '06232',
    city: 'Andover',
    state: 'CT',
    latitude: '41.735446',
    longitude: '-72.375647',
    timeZoneId: 'America/New_York',
  },
  '06233': {
    zip: '06233',
    city: 'Ballouville',
    state: 'CT',
    latitude: '41.87311',
    longitude: '-71.863498',
    timeZoneId: 'America/New_York',
  },
  '06234': {
    zip: '06234',
    city: 'Brooklyn',
    state: 'CT',
    latitude: '41.785357',
    longitude: '-71.954821',
    timeZoneId: 'America/New_York',
  },
  '06235': {
    zip: '06235',
    city: 'Chaplin',
    state: 'CT',
    latitude: '41.808838',
    longitude: '-72.115007',
    timeZoneId: 'America/New_York',
  },
  '06237': {
    zip: '06237',
    city: 'Columbia',
    state: 'CT',
    latitude: '41.702445',
    longitude: '-72.302125',
    timeZoneId: 'America/New_York',
  },
  '06238': {
    zip: '06238',
    city: 'Coventry',
    state: 'CT',
    latitude: '41.780214',
    longitude: '-72.330677',
    timeZoneId: 'America/New_York',
  },
  '06239': {
    zip: '06239',
    city: 'Danielson',
    state: 'CT',
    latitude: '41.79048',
    longitude: '-71.85588',
    timeZoneId: 'America/New_York',
  },
  '06241': {
    zip: '06241',
    city: 'Dayville',
    state: 'CT',
    latitude: '41.851822',
    longitude: '-71.86043',
    timeZoneId: 'America/New_York',
  },
  '06242': {
    zip: '06242',
    city: 'Eastford',
    state: 'CT',
    latitude: '41.893298',
    longitude: '-72.091566',
    timeZoneId: 'America/New_York',
  },
  '06243': {
    zip: '06243',
    city: 'East Killingly',
    state: 'CT',
    latitude: '41.848174',
    longitude: '-71.806468',
    timeZoneId: 'America/New_York',
  },
  '06244': {
    zip: '06244',
    city: 'East Woodstock',
    state: 'CT',
    latitude: '41.986323',
    longitude: '-71.974484',
    timeZoneId: 'America/New_York',
  },
  '06245': {
    zip: '06245',
    city: 'Fabyan',
    state: 'CT',
    latitude: '42.014244',
    longitude: '-71.937164',
    timeZoneId: 'America/New_York',
  },
  '06246': {
    zip: '06246',
    city: 'Grosvenor Dale',
    state: 'CT',
    latitude: '41.969557',
    longitude: '-71.894299',
    timeZoneId: 'America/New_York',
  },
  '06247': {
    zip: '06247',
    city: 'Hampton',
    state: 'CT',
    latitude: '41.765695',
    longitude: '-72.061672',
    timeZoneId: 'America/New_York',
  },
  '06248': {
    zip: '06248',
    city: 'Hebron',
    state: 'CT',
    latitude: '41.685074',
    longitude: '-72.406095',
    timeZoneId: 'America/New_York',
  },
  '06249': {
    zip: '06249',
    city: 'Lebanon',
    state: 'CT',
    latitude: '41.627884',
    longitude: '-72.237773',
    timeZoneId: 'America/New_York',
  },
  '06250': {
    zip: '06250',
    city: 'Mansfield Center',
    state: 'CT',
    latitude: '41.78058',
    longitude: '-72.2103',
    timeZoneId: 'America/New_York',
  },
  '06251': {
    zip: '06251',
    city: 'Mansfield Depot',
    state: 'CT',
    latitude: '41.815888',
    longitude: '-72.285998',
    timeZoneId: 'America/New_York',
  },
  '06254': {
    zip: '06254',
    city: 'North Franklin',
    state: 'CT',
    latitude: '41.618872',
    longitude: '-72.143072',
    timeZoneId: 'America/New_York',
  },
  '06255': {
    zip: '06255',
    city: 'North Grosvenordale',
    state: 'CT',
    latitude: '41.986809',
    longitude: '-71.902586',
    timeZoneId: 'America/New_York',
  },
  '06256': {
    zip: '06256',
    city: 'North Windham',
    state: 'CT',
    latitude: '41.75184',
    longitude: '-72.1428',
    timeZoneId: 'America/New_York',
  },
  '06258': {
    zip: '06258',
    city: 'Pomfret',
    state: 'CT',
    latitude: '41.905344',
    longitude: '-71.959304',
    timeZoneId: 'America/New_York',
  },
  '06259': {
    zip: '06259',
    city: 'Pomfret Center',
    state: 'CT',
    latitude: '41.876521',
    longitude: '-72.011578',
    timeZoneId: 'America/New_York',
  },
  '06260': {
    zip: '06260',
    city: 'Putnam',
    state: 'CT',
    latitude: '41.90676',
    longitude: '-71.86698',
    timeZoneId: 'America/New_York',
  },
  '06262': {
    zip: '06262',
    city: 'Quinebaug',
    state: 'CT',
    latitude: '42.018708',
    longitude: '-71.945107',
    timeZoneId: 'America/New_York',
  },
  '06263': {
    zip: '06263',
    city: 'Rogers',
    state: 'CT',
    latitude: '41.83848',
    longitude: '-71.904924',
    timeZoneId: 'America/New_York',
  },
  '06264': {
    zip: '06264',
    city: 'Scotland',
    state: 'CT',
    latitude: '41.683569',
    longitude: '-72.08398',
    timeZoneId: 'America/New_York',
  },
  '06265': {
    zip: '06265',
    city: 'South Willington',
    state: 'CT',
    latitude: '41.854519',
    longitude: '-72.301887',
    timeZoneId: 'America/New_York',
  },
  '06266': {
    zip: '06266',
    city: 'South Windham',
    state: 'CT',
    latitude: '41.673457',
    longitude: '-72.173186',
    timeZoneId: 'America/New_York',
  },
  '06267': {
    zip: '06267',
    city: 'South Woodstock',
    state: 'CT',
    latitude: '41.930199',
    longitude: '-71.958971',
    timeZoneId: 'America/New_York',
  },
  '06268': {
    zip: '06268',
    city: 'Storrs Mansfield',
    state: 'CT',
    latitude: '41.7936',
    longitude: '-72.2514',
    timeZoneId: 'America/New_York',
  },
  '06269': {
    zip: '06269',
    city: 'Storrs Mansfield',
    state: 'CT',
    latitude: '41.811419',
    longitude: '-72.247553',
    timeZoneId: 'America/New_York',
  },
  '06277': {
    zip: '06277',
    city: 'Thompson',
    state: 'CT',
    latitude: '41.9778',
    longitude: '-71.84718',
    timeZoneId: 'America/New_York',
  },
  '06278': {
    zip: '06278',
    city: 'Ashford',
    state: 'CT',
    latitude: '41.89662',
    longitude: '-72.14124',
    timeZoneId: 'America/New_York',
  },
  '06279': {
    zip: '06279',
    city: 'Willington',
    state: 'CT',
    latitude: '41.89578',
    longitude: '-72.2643',
    timeZoneId: 'America/New_York',
  },
  '06280': {
    zip: '06280',
    city: 'Windham',
    state: 'CT',
    latitude: '41.689241',
    longitude: '-72.134796',
    timeZoneId: 'America/New_York',
  },
  '06281': {
    zip: '06281',
    city: 'Woodstock',
    state: 'CT',
    latitude: '41.946708',
    longitude: '-71.986604',
    timeZoneId: 'America/New_York',
  },
  '06282': {
    zip: '06282',
    city: 'Woodstock Valley',
    state: 'CT',
    latitude: '41.93086',
    longitude: '-72.104123',
    timeZoneId: 'America/New_York',
  },
  '06320': {
    zip: '06320',
    city: 'New London',
    state: 'CT',
    latitude: '41.351139',
    longitude: '-72.108165',
    timeZoneId: 'America/New_York',
  },
  '06330': {
    zip: '06330',
    city: 'Baltic',
    state: 'CT',
    latitude: '41.612919',
    longitude: '-72.070615',
    timeZoneId: 'America/New_York',
  },
  '06331': {
    zip: '06331',
    city: 'Canterbury',
    state: 'CT',
    latitude: '41.693971',
    longitude: '-72.004297',
    timeZoneId: 'America/New_York',
  },
  '06332': {
    zip: '06332',
    city: 'Central Village',
    state: 'CT',
    latitude: '41.724456',
    longitude: '-71.908237',
    timeZoneId: 'America/New_York',
  },
  '06333': {
    zip: '06333',
    city: 'East Lyme',
    state: 'CT',
    latitude: '41.373026',
    longitude: '-72.230753',
    timeZoneId: 'America/New_York',
  },
  '06334': {
    zip: '06334',
    city: 'Bozrah',
    state: 'CT',
    latitude: '41.53947',
    longitude: '-72.176914',
    timeZoneId: 'America/New_York',
  },
  '06335': {
    zip: '06335',
    city: 'Gales Ferry',
    state: 'CT',
    latitude: '41.432117',
    longitude: '-72.069858',
    timeZoneId: 'America/New_York',
  },
  '06336': {
    zip: '06336',
    city: 'Gilman',
    state: 'CT',
    latitude: '41.580991',
    longitude: '-72.19831',
    timeZoneId: 'America/New_York',
  },
  '06338': {
    zip: '06338',
    city: 'Mashantucket',
    state: 'CT',
    latitude: '41.4715',
    longitude: '-71.947304',
    timeZoneId: 'America/New_York',
  },
  '06339': {
    zip: '06339',
    city: 'Ledyard',
    state: 'CT',
    latitude: '41.444713',
    longitude: '-71.995991',
    timeZoneId: 'America/New_York',
  },
  '06340': {
    zip: '06340',
    city: 'Groton',
    state: 'CT',
    latitude: '41.352142',
    longitude: '-72.044199',
    timeZoneId: 'America/New_York',
  },
  '06349': {
    zip: '06349',
    city: 'Groton',
    state: 'CT',
    latitude: '41.401943',
    longitude: '-72.08928',
    timeZoneId: 'America/New_York',
  },
  '06350': {
    zip: '06350',
    city: 'Hanover',
    state: 'CT',
    latitude: '41.64506',
    longitude: '-72.066019',
    timeZoneId: 'America/New_York',
  },
  '06351': {
    zip: '06351',
    city: 'Jewett City',
    state: 'CT',
    latitude: '41.601907',
    longitude: '-71.977075',
    timeZoneId: 'America/New_York',
  },
  '06353': {
    zip: '06353',
    city: 'Montville',
    state: 'CT',
    latitude: '41.451114',
    longitude: '-72.131081',
    timeZoneId: 'America/New_York',
  },
  '06354': {
    zip: '06354',
    city: 'Moosup',
    state: 'CT',
    latitude: '41.71201',
    longitude: '-71.872331',
    timeZoneId: 'America/New_York',
  },
  '06355': {
    zip: '06355',
    city: 'Mystic',
    state: 'CT',
    latitude: '41.358669',
    longitude: '-71.979179',
    timeZoneId: 'America/New_York',
  },
  '06357': {
    zip: '06357',
    city: 'Niantic',
    state: 'CT',
    latitude: '41.320835',
    longitude: '-72.211925',
    timeZoneId: 'America/New_York',
  },
  '06359': {
    zip: '06359',
    city: 'North Stonington',
    state: 'CT',
    latitude: '41.462417',
    longitude: '-71.870272',
    timeZoneId: 'America/New_York',
  },
  '06360': {
    zip: '06360',
    city: 'Norwich',
    state: 'CT',
    latitude: '41.55084',
    longitude: '-72.02736',
    timeZoneId: 'America/New_York',
  },
  '06365': {
    zip: '06365',
    city: 'Preston',
    state: 'CT',
    latitude: '41.513587',
    longitude: '-71.999442',
    timeZoneId: 'America/New_York',
  },
  '06370': {
    zip: '06370',
    city: 'Oakdale',
    state: 'CT',
    latitude: '41.46852',
    longitude: '-72.17676',
    timeZoneId: 'America/New_York',
  },
  '06371': {
    zip: '06371',
    city: 'Old Lyme',
    state: 'CT',
    latitude: '41.328807',
    longitude: '-72.309397',
    timeZoneId: 'America/New_York',
  },
  '06372': {
    zip: '06372',
    city: 'Old Mystic',
    state: 'CT',
    latitude: '41.390591',
    longitude: '-71.962876',
    timeZoneId: 'America/New_York',
  },
  '06373': {
    zip: '06373',
    city: 'Oneco',
    state: 'CT',
    latitude: '41.692041',
    longitude: '-71.811247',
    timeZoneId: 'America/New_York',
  },
  '06374': {
    zip: '06374',
    city: 'Plainfield',
    state: 'CT',
    latitude: '41.67743',
    longitude: '-71.922932',
    timeZoneId: 'America/New_York',
  },
  '06375': {
    zip: '06375',
    city: 'Quaker Hill',
    state: 'CT',
    latitude: '41.398982',
    longitude: '-72.116095',
    timeZoneId: 'America/New_York',
  },
  '06376': {
    zip: '06376',
    city: 'South Lyme',
    state: 'CT',
    latitude: '41.294573',
    longitude: '-72.259086',
    timeZoneId: 'America/New_York',
  },
  '06377': {
    zip: '06377',
    city: 'Sterling',
    state: 'CT',
    latitude: '41.71914',
    longitude: '-71.81856',
    timeZoneId: 'America/New_York',
  },
  '06378': {
    zip: '06378',
    city: 'Stonington',
    state: 'CT',
    latitude: '41.34426',
    longitude: '-71.911442',
    timeZoneId: 'America/New_York',
  },
  '06379': {
    zip: '06379',
    city: 'Pawcatuck',
    state: 'CT',
    latitude: '41.373454',
    longitude: '-71.849357',
    timeZoneId: 'America/New_York',
  },
  '06380': {
    zip: '06380',
    city: 'Taftville',
    state: 'CT',
    latitude: '41.564008',
    longitude: '-72.054926',
    timeZoneId: 'America/New_York',
  },
  '06382': {
    zip: '06382',
    city: 'Uncasville',
    state: 'CT',
    latitude: '41.460941',
    longitude: '-72.11346',
    timeZoneId: 'America/New_York',
  },
  '06383': {
    zip: '06383',
    city: 'Versailles',
    state: 'CT',
    latitude: '41.600591',
    longitude: '-72.043044',
    timeZoneId: 'America/New_York',
  },
  '06384': {
    zip: '06384',
    city: 'Voluntown',
    state: 'CT',
    latitude: '41.57426',
    longitude: '-71.884653',
    timeZoneId: 'America/New_York',
  },
  '06385': {
    zip: '06385',
    city: 'Waterford',
    state: 'CT',
    latitude: '41.347357',
    longitude: '-72.14767',
    timeZoneId: 'America/New_York',
  },
  '06387': {
    zip: '06387',
    city: 'Wauregan',
    state: 'CT',
    latitude: '41.743607',
    longitude: '-71.911848',
    timeZoneId: 'America/New_York',
  },
  '06388': {
    zip: '06388',
    city: 'West Mystic',
    state: 'CT',
    latitude: '41.350556',
    longitude: '-71.9825',
    timeZoneId: 'America/New_York',
  },
  '06389': {
    zip: '06389',
    city: 'Yantic',
    state: 'CT',
    latitude: '41.562601',
    longitude: '-72.122592',
    timeZoneId: 'America/New_York',
  },
  '06390': {
    zip: '06390',
    city: 'Fishers Island',
    state: 'NY',
    latitude: '41.25',
    longitude: '-72.02',
    timeZoneId: 'America/New_York',
  },
  '06401': {
    zip: '06401',
    city: 'Ansonia',
    state: 'CT',
    latitude: '41.342081',
    longitude: '-73.07169',
    timeZoneId: 'America/New_York',
  },
  '06403': {
    zip: '06403',
    city: 'Beacon Falls',
    state: 'CT',
    latitude: '41.436391',
    longitude: '-73.065016',
    timeZoneId: 'America/New_York',
  },
  '06404': {
    zip: '06404',
    city: 'Botsford',
    state: 'CT',
    latitude: '41.361821',
    longitude: '-73.213615',
    timeZoneId: 'America/New_York',
  },
  '06405': {
    zip: '06405',
    city: 'Branford',
    state: 'CT',
    latitude: '41.278295',
    longitude: '-72.796351',
    timeZoneId: 'America/New_York',
  },
  '06408': {
    zip: '06408',
    city: 'Cheshire',
    state: 'CT',
    latitude: '41.498889',
    longitude: '-72.901111',
    timeZoneId: 'America/New_York',
  },
  '06409': {
    zip: '06409',
    city: 'Centerbrook',
    state: 'CT',
    latitude: '41.34594',
    longitude: '-72.416515',
    timeZoneId: 'America/New_York',
  },
  '06410': {
    zip: '06410',
    city: 'Cheshire',
    state: 'CT',
    latitude: '41.504235',
    longitude: '-72.906403',
    timeZoneId: 'America/New_York',
  },
  '06411': {
    zip: '06411',
    city: 'Cheshire',
    state: 'CT',
    latitude: '41.499696',
    longitude: '-72.901815',
    timeZoneId: 'America/New_York',
  },
  '06412': {
    zip: '06412',
    city: 'Chester',
    state: 'CT',
    latitude: '41.406509',
    longitude: '-72.465351',
    timeZoneId: 'America/New_York',
  },
  '06413': {
    zip: '06413',
    city: 'Clinton',
    state: 'CT',
    latitude: '41.291966',
    longitude: '-72.530871',
    timeZoneId: 'America/New_York',
  },
  '06414': {
    zip: '06414',
    city: 'Cobalt',
    state: 'CT',
    latitude: '41.568275',
    longitude: '-72.556386',
    timeZoneId: 'America/New_York',
  },
  '06415': {
    zip: '06415',
    city: 'Colchester',
    state: 'CT',
    latitude: '41.551164',
    longitude: '-72.331473',
    timeZoneId: 'America/New_York',
  },
  '06416': {
    zip: '06416',
    city: 'Cromwell',
    state: 'CT',
    latitude: '41.609671',
    longitude: '-72.663331',
    timeZoneId: 'America/New_York',
  },
  '06417': {
    zip: '06417',
    city: 'Deep River',
    state: 'CT',
    latitude: '41.37355',
    longitude: '-72.495583',
    timeZoneId: 'America/New_York',
  },
  '06418': {
    zip: '06418',
    city: 'Derby',
    state: 'CT',
    latitude: '41.38189',
    longitude: '-73.079662',
    timeZoneId: 'America/New_York',
  },
  '06419': {
    zip: '06419',
    city: 'Killingworth',
    state: 'CT',
    latitude: '41.3757',
    longitude: '-72.58146',
    timeZoneId: 'America/New_York',
  },
  '06420': {
    zip: '06420',
    city: 'Salem',
    state: 'CT',
    latitude: '41.487578',
    longitude: '-72.271646',
    timeZoneId: 'America/New_York',
  },
  '06422': {
    zip: '06422',
    city: 'Durham',
    state: 'CT',
    latitude: '41.45951',
    longitude: '-72.690481',
    timeZoneId: 'America/New_York',
  },
  '06423': {
    zip: '06423',
    city: 'East Haddam',
    state: 'CT',
    latitude: '41.472792',
    longitude: '-72.399917',
    timeZoneId: 'America/New_York',
  },
  '06424': {
    zip: '06424',
    city: 'East Hampton',
    state: 'CT',
    latitude: '41.56008',
    longitude: '-72.50136',
    timeZoneId: 'America/New_York',
  },
  '06426': {
    zip: '06426',
    city: 'Essex',
    state: 'CT',
    latitude: '41.365983',
    longitude: '-72.412721',
    timeZoneId: 'America/New_York',
  },
  '06437': {
    zip: '06437',
    city: 'Guilford',
    state: 'CT',
    latitude: '41.316416',
    longitude: '-72.695683',
    timeZoneId: 'America/New_York',
  },
  '06438': {
    zip: '06438',
    city: 'Haddam',
    state: 'CT',
    latitude: '41.464202',
    longitude: '-72.518015',
    timeZoneId: 'America/New_York',
  },
  '06439': {
    zip: '06439',
    city: 'Hadlyme',
    state: 'CT',
    latitude: '41.418853',
    longitude: '-72.39769',
    timeZoneId: 'America/New_York',
  },
  '06440': {
    zip: '06440',
    city: 'Hawleyville',
    state: 'CT',
    latitude: '41.433021',
    longitude: '-73.353694',
    timeZoneId: 'America/New_York',
  },
  '06441': {
    zip: '06441',
    city: 'Higganum',
    state: 'CT',
    latitude: '41.462791',
    longitude: '-72.57433',
    timeZoneId: 'America/New_York',
  },
  '06442': {
    zip: '06442',
    city: 'Ivoryton',
    state: 'CT',
    latitude: '41.345098',
    longitude: '-72.444625',
    timeZoneId: 'America/New_York',
  },
  '06443': {
    zip: '06443',
    city: 'Madison',
    state: 'CT',
    latitude: '41.302547',
    longitude: '-72.611171',
    timeZoneId: 'America/New_York',
  },
  '06444': {
    zip: '06444',
    city: 'Marion',
    state: 'CT',
    latitude: '41.561975',
    longitude: '-72.929327',
    timeZoneId: 'America/New_York',
  },
  '06447': {
    zip: '06447',
    city: 'Marlborough',
    state: 'CT',
    latitude: '41.641101',
    longitude: '-72.458069',
    timeZoneId: 'America/New_York',
  },
  '06450': {
    zip: '06450',
    city: 'Meriden',
    state: 'CT',
    latitude: '41.533886',
    longitude: '-72.799816',
    timeZoneId: 'America/New_York',
  },
  '06451': {
    zip: '06451',
    city: 'Meriden',
    state: 'CT',
    latitude: '41.535911',
    longitude: '-72.820885',
    timeZoneId: 'America/New_York',
  },
  '06455': {
    zip: '06455',
    city: 'Middlefield',
    state: 'CT',
    latitude: '41.517769',
    longitude: '-72.717522',
    timeZoneId: 'America/New_York',
  },
  '06456': {
    zip: '06456',
    city: 'Middle Haddam',
    state: 'CT',
    latitude: '41.553544',
    longitude: '-72.553312',
    timeZoneId: 'America/New_York',
  },
  '06457': {
    zip: '06457',
    city: 'Middletown',
    state: 'CT',
    latitude: '41.548962',
    longitude: '-72.663524',
    timeZoneId: 'America/New_York',
  },
  '06459': {
    zip: '06459',
    city: 'Middletown',
    state: 'CT',
    latitude: '41.551147',
    longitude: '-72.651828',
    timeZoneId: 'America/New_York',
  },
  '06460': {
    zip: '06460',
    city: 'Milford',
    state: 'CT',
    latitude: '41.232682',
    longitude: '-73.061948',
    timeZoneId: 'America/New_York',
  },
  '06461': {
    zip: '06461',
    city: 'Milford',
    state: 'CT',
    latitude: '41.26453',
    longitude: '-73.078505',
    timeZoneId: 'America/New_York',
  },
  '06467': {
    zip: '06467',
    city: 'Milldale',
    state: 'CT',
    latitude: '41.566761',
    longitude: '-72.903921',
    timeZoneId: 'America/New_York',
  },
  '06468': {
    zip: '06468',
    city: 'Monroe',
    state: 'CT',
    latitude: '41.3409',
    longitude: '-73.23444',
    timeZoneId: 'America/New_York',
  },
  '06469': {
    zip: '06469',
    city: 'Moodus',
    state: 'CT',
    latitude: '41.50526',
    longitude: '-72.450851',
    timeZoneId: 'America/New_York',
  },
  '06470': {
    zip: '06470',
    city: 'Newtown',
    state: 'CT',
    latitude: '41.396689',
    longitude: '-73.313985',
    timeZoneId: 'America/New_York',
  },
  '06471': {
    zip: '06471',
    city: 'North Branford',
    state: 'CT',
    latitude: '41.341138',
    longitude: '-72.776339',
    timeZoneId: 'America/New_York',
  },
  '06472': {
    zip: '06472',
    city: 'Northford',
    state: 'CT',
    latitude: '41.39381',
    longitude: '-72.784752',
    timeZoneId: 'America/New_York',
  },
  '06473': {
    zip: '06473',
    city: 'North Haven',
    state: 'CT',
    latitude: '41.381539',
    longitude: '-72.861606',
    timeZoneId: 'America/New_York',
  },
  '06474': {
    zip: '06474',
    city: 'North Westchester',
    state: 'CT',
    latitude: '41.588161',
    longitude: '-72.397834',
    timeZoneId: 'America/New_York',
  },
  '06475': {
    zip: '06475',
    city: 'Old Saybrook',
    state: 'CT',
    latitude: '41.29974',
    longitude: '-72.39036',
    timeZoneId: 'America/New_York',
  },
  '06477': {
    zip: '06477',
    city: 'Orange',
    state: 'CT',
    latitude: '41.282126',
    longitude: '-73.02581',
    timeZoneId: 'America/New_York',
  },
  '06478': {
    zip: '06478',
    city: 'Oxford',
    state: 'CT',
    latitude: '41.426687',
    longitude: '-73.133697',
    timeZoneId: 'America/New_York',
  },
  '06479': {
    zip: '06479',
    city: 'Plantsville',
    state: 'CT',
    latitude: '41.581652',
    longitude: '-72.897889',
    timeZoneId: 'America/New_York',
  },
  '06480': {
    zip: '06480',
    city: 'Portland',
    state: 'CT',
    latitude: '41.581923',
    longitude: '-72.618579',
    timeZoneId: 'America/New_York',
  },
  '06481': {
    zip: '06481',
    city: 'Rockfall',
    state: 'CT',
    latitude: '41.533167',
    longitude: '-72.700542',
    timeZoneId: 'America/New_York',
  },
  '06482': {
    zip: '06482',
    city: 'Sandy Hook',
    state: 'CT',
    latitude: '41.410179',
    longitude: '-73.250634',
    timeZoneId: 'America/New_York',
  },
  '06483': {
    zip: '06483',
    city: 'Seymour',
    state: 'CT',
    latitude: '41.381477',
    longitude: '-73.085777',
    timeZoneId: 'America/New_York',
  },
  '06484': {
    zip: '06484',
    city: 'Shelton',
    state: 'CT',
    latitude: '41.31354',
    longitude: '-73.1373',
    timeZoneId: 'America/New_York',
  },
  '06487': {
    zip: '06487',
    city: 'South Britain',
    state: 'CT',
    latitude: '41.469673',
    longitude: '-73.251733',
    timeZoneId: 'America/New_York',
  },
  '06488': {
    zip: '06488',
    city: 'Southbury',
    state: 'CT',
    latitude: '41.472721',
    longitude: '-73.220013',
    timeZoneId: 'America/New_York',
  },
  '06489': {
    zip: '06489',
    city: 'Southington',
    state: 'CT',
    latitude: '41.607535',
    longitude: '-72.870394',
    timeZoneId: 'America/New_York',
  },
  '06491': {
    zip: '06491',
    city: 'Stevenson',
    state: 'CT',
    latitude: '41.381403',
    longitude: '-73.187735',
    timeZoneId: 'America/New_York',
  },
  '06492': {
    zip: '06492',
    city: 'Wallingford',
    state: 'CT',
    latitude: '41.459922',
    longitude: '-72.818029',
    timeZoneId: 'America/New_York',
  },
  '06493': {
    zip: '06493',
    city: 'Wallingford',
    state: 'CT',
    latitude: '41.486804',
    longitude: '-72.822757',
    timeZoneId: 'America/New_York',
  },
  '06494': {
    zip: '06494',
    city: 'Wallingford',
    state: 'CT',
    latitude: '41.469621',
    longitude: '-72.818052',
    timeZoneId: 'America/New_York',
  },
  '06495': {
    zip: '06495',
    city: 'Wallingford',
    state: 'CT',
    latitude: '41.458768',
    longitude: '-72.826674',
    timeZoneId: 'America/New_York',
  },
  '06498': {
    zip: '06498',
    city: 'Westbrook',
    state: 'CT',
    latitude: '41.288396',
    longitude: '-72.455846',
    timeZoneId: 'America/New_York',
  },
  '06501': {
    zip: '06501',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.310083',
    longitude: '-72.926314',
    timeZoneId: 'America/New_York',
  },
  '06502': {
    zip: '06502',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.308684',
    longitude: '-72.924444',
    timeZoneId: 'America/New_York',
  },
  '06503': {
    zip: '06503',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.309366',
    longitude: '-72.928189',
    timeZoneId: 'America/New_York',
  },
  '06504': {
    zip: '06504',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.291641',
    longitude: '-72.964292',
    timeZoneId: 'America/New_York',
  },
  '06505': {
    zip: '06505',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.307813',
    longitude: '-72.923064',
    timeZoneId: 'America/New_York',
  },
  '06506': {
    zip: '06506',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.30947',
    longitude: '-72.925324',
    timeZoneId: 'America/New_York',
  },
  '06507': {
    zip: '06507',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.305516',
    longitude: '-72.922831',
    timeZoneId: 'America/New_York',
  },
  '06508': {
    zip: '06508',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.307025',
    longitude: '-72.923348',
    timeZoneId: 'America/New_York',
  },
  '06509': {
    zip: '06509',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.319',
    longitude: '-72.927115',
    timeZoneId: 'America/New_York',
  },
  '06510': {
    zip: '06510',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.310571',
    longitude: '-72.922891',
    timeZoneId: 'America/New_York',
  },
  '06511': {
    zip: '06511',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.327048',
    longitude: '-72.940381',
    timeZoneId: 'America/New_York',
  },
  '06512': {
    zip: '06512',
    city: 'East Haven',
    state: 'CT',
    latitude: '41.28564',
    longitude: '-72.86358',
    timeZoneId: 'America/New_York',
  },
  '06513': {
    zip: '06513',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.321735',
    longitude: '-72.859214',
    timeZoneId: 'America/New_York',
  },
  '06514': {
    zip: '06514',
    city: 'Hamden',
    state: 'CT',
    latitude: '41.3724',
    longitude: '-72.93972',
    timeZoneId: 'America/New_York',
  },
  '06515': {
    zip: '06515',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.327559',
    longitude: '-72.966874',
    timeZoneId: 'America/New_York',
  },
  '06516': {
    zip: '06516',
    city: 'West Haven',
    state: 'CT',
    latitude: '41.269511',
    longitude: '-72.964339',
    timeZoneId: 'America/New_York',
  },
  '06517': {
    zip: '06517',
    city: 'Hamden',
    state: 'CT',
    latitude: '41.349805',
    longitude: '-72.913991',
    timeZoneId: 'America/New_York',
  },
  '06518': {
    zip: '06518',
    city: 'Hamden',
    state: 'CT',
    latitude: '41.415836',
    longitude: '-72.912377',
    timeZoneId: 'America/New_York',
  },
  '06519': {
    zip: '06519',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.294976',
    longitude: '-72.938709',
    timeZoneId: 'America/New_York',
  },
  '06520': {
    zip: '06520',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.308633',
    longitude: '-72.925779',
    timeZoneId: 'America/New_York',
  },
  '06521': {
    zip: '06521',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.309985',
    longitude: '-72.926398',
    timeZoneId: 'America/New_York',
  },
  '06524': {
    zip: '06524',
    city: 'Bethany',
    state: 'CT',
    latitude: '41.412767',
    longitude: '-72.992792',
    timeZoneId: 'America/New_York',
  },
  '06525': {
    zip: '06525',
    city: 'Woodbridge',
    state: 'CT',
    latitude: '41.360503',
    longitude: '-73.012777',
    timeZoneId: 'America/New_York',
  },
  '06530': {
    zip: '06530',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.318176',
    longitude: '-72.923918',
    timeZoneId: 'America/New_York',
  },
  '06531': {
    zip: '06531',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.318194',
    longitude: '-72.929494',
    timeZoneId: 'America/New_York',
  },
  '06532': {
    zip: '06532',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.319206',
    longitude: '-72.926573',
    timeZoneId: 'America/New_York',
  },
  '06533': {
    zip: '06533',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.317731',
    longitude: '-72.929215',
    timeZoneId: 'America/New_York',
  },
  '06534': {
    zip: '06534',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.318581',
    longitude: '-72.92751',
    timeZoneId: 'America/New_York',
  },
  '06535': {
    zip: '06535',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.314321',
    longitude: '-72.929008',
    timeZoneId: 'America/New_York',
  },
  '06536': {
    zip: '06536',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.317391',
    longitude: '-72.926719',
    timeZoneId: 'America/New_York',
  },
  '06537': {
    zip: '06537',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.431433',
    longitude: '-72.910481',
    timeZoneId: 'America/New_York',
  },
  '06538': {
    zip: '06538',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.432208',
    longitude: '-72.90928',
    timeZoneId: 'America/New_York',
  },
  '06540': {
    zip: '06540',
    city: 'New Haven',
    state: 'CT',
    latitude: '41.310015',
    longitude: '-72.921458',
    timeZoneId: 'America/New_York',
  },
  '06601': {
    zip: '06601',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.176452',
    longitude: '-73.202928',
    timeZoneId: 'America/New_York',
  },
  '06602': {
    zip: '06602',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.173645',
    longitude: '-73.200467',
    timeZoneId: 'America/New_York',
  },
  '06604': {
    zip: '06604',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.180721',
    longitude: '-73.192975',
    timeZoneId: 'America/New_York',
  },
  '06605': {
    zip: '06605',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.167918',
    longitude: '-73.216455',
    timeZoneId: 'America/New_York',
  },
  '06606': {
    zip: '06606',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.21302',
    longitude: '-73.211437',
    timeZoneId: 'America/New_York',
  },
  '06607': {
    zip: '06607',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.179009',
    longitude: '-73.16452',
    timeZoneId: 'America/New_York',
  },
  '06608': {
    zip: '06608',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.188008',
    longitude: '-73.17869',
    timeZoneId: 'America/New_York',
  },
  '06610': {
    zip: '06610',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.201974',
    longitude: '-73.166951',
    timeZoneId: 'America/New_York',
  },
  '06611': {
    zip: '06611',
    city: 'Trumbull',
    state: 'CT',
    latitude: '41.255623',
    longitude: '-73.208807',
    timeZoneId: 'America/New_York',
  },
  '06612': {
    zip: '06612',
    city: 'Easton',
    state: 'CT',
    latitude: '41.166944',
    longitude: '-73.205278',
    timeZoneId: 'America/New_York',
  },
  '06614': {
    zip: '06614',
    city: 'Stratford',
    state: 'CT',
    latitude: '41.228282',
    longitude: '-73.130796',
    timeZoneId: 'America/New_York',
  },
  '06615': {
    zip: '06615',
    city: 'Stratford',
    state: 'CT',
    latitude: '41.185034',
    longitude: '-73.14015',
    timeZoneId: 'America/New_York',
  },
  '06673': {
    zip: '06673',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.184273',
    longitude: '-73.148138',
    timeZoneId: 'America/New_York',
  },
  '06699': {
    zip: '06699',
    city: 'Bridgeport',
    state: 'CT',
    latitude: '41.191157',
    longitude: '-73.138012',
    timeZoneId: 'America/New_York',
  },
  '06701': {
    zip: '06701',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.558763',
    longitude: '-73.052179',
    timeZoneId: 'America/New_York',
  },
  '06702': {
    zip: '06702',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.555258',
    longitude: '-73.039627',
    timeZoneId: 'America/New_York',
  },
  '06703': {
    zip: '06703',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.528912',
    longitude: '-73.007869',
    timeZoneId: 'America/New_York',
  },
  '06704': {
    zip: '06704',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.578898',
    longitude: '-73.032746',
    timeZoneId: 'America/New_York',
  },
  '06705': {
    zip: '06705',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.549583',
    longitude: '-72.99589',
    timeZoneId: 'America/New_York',
  },
  '06706': {
    zip: '06706',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.535363',
    longitude: '-73.027806',
    timeZoneId: 'America/New_York',
  },
  '06708': {
    zip: '06708',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.552917',
    longitude: '-73.066488',
    timeZoneId: 'America/New_York',
  },
  '06710': {
    zip: '06710',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.568003',
    longitude: '-73.045373',
    timeZoneId: 'America/New_York',
  },
  '06712': {
    zip: '06712',
    city: 'Prospect',
    state: 'CT',
    latitude: '41.4952',
    longitude: '-72.974161',
    timeZoneId: 'America/New_York',
  },
  '06716': {
    zip: '06716',
    city: 'Wolcott',
    state: 'CT',
    latitude: '41.591802',
    longitude: '-72.972656',
    timeZoneId: 'America/New_York',
  },
  '06720': {
    zip: '06720',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.557028',
    longitude: '-73.038046',
    timeZoneId: 'America/New_York',
  },
  '06721': {
    zip: '06721',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.559848',
    longitude: '-73.059033',
    timeZoneId: 'America/New_York',
  },
  '06722': {
    zip: '06722',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.556468',
    longitude: '-73.038582',
    timeZoneId: 'America/New_York',
  },
  '06723': {
    zip: '06723',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.557069',
    longitude: '-73.036745',
    timeZoneId: 'America/New_York',
  },
  '06724': {
    zip: '06724',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.559608',
    longitude: '-73.041406',
    timeZoneId: 'America/New_York',
  },
  '06725': {
    zip: '06725',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.552662',
    longitude: '-73.040934',
    timeZoneId: 'America/New_York',
  },
  '06726': {
    zip: '06726',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.555029',
    longitude: '-73.037976',
    timeZoneId: 'America/New_York',
  },
  '06749': {
    zip: '06749',
    city: 'Waterbury',
    state: 'CT',
    latitude: '41.504981',
    longitude: '-73.15243',
    timeZoneId: 'America/New_York',
  },
  '06750': {
    zip: '06750',
    city: 'Bantam',
    state: 'CT',
    latitude: '41.713845',
    longitude: '-73.253929',
    timeZoneId: 'America/New_York',
  },
  '06751': {
    zip: '06751',
    city: 'Bethlehem',
    state: 'CT',
    latitude: '41.641051',
    longitude: '-73.212828',
    timeZoneId: 'America/New_York',
  },
  '06752': {
    zip: '06752',
    city: 'Bridgewater',
    state: 'CT',
    latitude: '41.528811',
    longitude: '-73.361384',
    timeZoneId: 'America/New_York',
  },
  '06753': {
    zip: '06753',
    city: 'Cornwall',
    state: 'CT',
    latitude: '41.825192',
    longitude: '-73.333575',
    timeZoneId: 'America/New_York',
  },
  '06754': {
    zip: '06754',
    city: 'Cornwall Bridge',
    state: 'CT',
    latitude: '41.77062',
    longitude: '-73.3674',
    timeZoneId: 'America/New_York',
  },
  '06755': {
    zip: '06755',
    city: 'Gaylordsville',
    state: 'CT',
    latitude: '41.648111',
    longitude: '-73.483674',
    timeZoneId: 'America/New_York',
  },
  '06756': {
    zip: '06756',
    city: 'Goshen',
    state: 'CT',
    latitude: '41.845027',
    longitude: '-73.229231',
    timeZoneId: 'America/New_York',
  },
  '06757': {
    zip: '06757',
    city: 'Kent',
    state: 'CT',
    latitude: '41.735781',
    longitude: '-73.451858',
    timeZoneId: 'America/New_York',
  },
  '06758': {
    zip: '06758',
    city: 'Lakeside',
    state: 'CT',
    latitude: '41.684167',
    longitude: '-73.196667',
    timeZoneId: 'America/New_York',
  },
  '06759': {
    zip: '06759',
    city: 'Litchfield',
    state: 'CT',
    latitude: '41.750193',
    longitude: '-73.189104',
    timeZoneId: 'America/New_York',
  },
  '06762': {
    zip: '06762',
    city: 'Middlebury',
    state: 'CT',
    latitude: '41.528087',
    longitude: '-73.119229',
    timeZoneId: 'America/New_York',
  },
  '06763': {
    zip: '06763',
    city: 'Morris',
    state: 'CT',
    latitude: '41.687716',
    longitude: '-73.198108',
    timeZoneId: 'America/New_York',
  },
  '06770': {
    zip: '06770',
    city: 'Naugatuck',
    state: 'CT',
    latitude: '41.492039',
    longitude: '-73.04855',
    timeZoneId: 'America/New_York',
  },
  '06776': {
    zip: '06776',
    city: 'New Milford',
    state: 'CT',
    latitude: '41.582173',
    longitude: '-73.413047',
    timeZoneId: 'America/New_York',
  },
  '06777': {
    zip: '06777',
    city: 'New Preston Marble Dale',
    state: 'CT',
    latitude: '41.686902',
    longitude: '-73.346373',
    timeZoneId: 'America/New_York',
  },
  '06778': {
    zip: '06778',
    city: 'Northfield',
    state: 'CT',
    latitude: '41.7033',
    longitude: '-73.1187',
    timeZoneId: 'America/New_York',
  },
  '06779': {
    zip: '06779',
    city: 'Oakville',
    state: 'CT',
    latitude: '41.593909',
    longitude: '-73.087086',
    timeZoneId: 'America/New_York',
  },
  '06781': {
    zip: '06781',
    city: 'Pequabuck',
    state: 'CT',
    latitude: '41.671733',
    longitude: '-73.032289',
    timeZoneId: 'America/New_York',
  },
  '06782': {
    zip: '06782',
    city: 'Plymouth',
    state: 'CT',
    latitude: '41.664097',
    longitude: '-73.049922',
    timeZoneId: 'America/New_York',
  },
  '06783': {
    zip: '06783',
    city: 'Roxbury',
    state: 'CT',
    latitude: '41.557292',
    longitude: '-73.302289',
    timeZoneId: 'America/New_York',
  },
  '06784': {
    zip: '06784',
    city: 'Sherman',
    state: 'CT',
    latitude: '41.578039',
    longitude: '-73.499079',
    timeZoneId: 'America/New_York',
  },
  '06785': {
    zip: '06785',
    city: 'South Kent',
    state: 'CT',
    latitude: '41.698481',
    longitude: '-73.440523',
    timeZoneId: 'America/New_York',
  },
  '06786': {
    zip: '06786',
    city: 'Terryville',
    state: 'CT',
    latitude: '41.67647',
    longitude: '-73.008551',
    timeZoneId: 'America/New_York',
  },
  '06787': {
    zip: '06787',
    city: 'Thomaston',
    state: 'CT',
    latitude: '41.65548',
    longitude: '-73.09914',
    timeZoneId: 'America/New_York',
  },
  '06790': {
    zip: '06790',
    city: 'Torrington',
    state: 'CT',
    latitude: '41.812769',
    longitude: '-73.120734',
    timeZoneId: 'America/New_York',
  },
  '06791': {
    zip: '06791',
    city: 'Harwinton',
    state: 'CT',
    latitude: '41.7537',
    longitude: '-73.06152',
    timeZoneId: 'America/New_York',
  },
  '06792': {
    zip: '06792',
    city: 'Torrington',
    state: 'CT',
    latitude: '41.7549',
    longitude: '-73.0582',
    timeZoneId: 'America/New_York',
  },
  '06793': {
    zip: '06793',
    city: 'Washington',
    state: 'CT',
    latitude: '41.640788',
    longitude: '-73.293104',
    timeZoneId: 'America/New_York',
  },
  '06794': {
    zip: '06794',
    city: 'Washington Depot',
    state: 'CT',
    latitude: '41.649297',
    longitude: '-73.322807',
    timeZoneId: 'America/New_York',
  },
  '06795': {
    zip: '06795',
    city: 'Watertown',
    state: 'CT',
    latitude: '41.605821',
    longitude: '-73.1257',
    timeZoneId: 'America/New_York',
  },
  '06796': {
    zip: '06796',
    city: 'West Cornwall',
    state: 'CT',
    latitude: '41.866633',
    longitude: '-73.3332',
    timeZoneId: 'America/New_York',
  },
  '06798': {
    zip: '06798',
    city: 'Woodbury',
    state: 'CT',
    latitude: '41.555937',
    longitude: '-73.203211',
    timeZoneId: 'America/New_York',
  },
  '06801': {
    zip: '06801',
    city: 'Bethel',
    state: 'CT',
    latitude: '41.382468',
    longitude: '-73.401404',
    timeZoneId: 'America/New_York',
  },
  '06804': {
    zip: '06804',
    city: 'Brookfield',
    state: 'CT',
    latitude: '41.466931',
    longitude: '-73.395508',
    timeZoneId: 'America/New_York',
  },
  '06807': {
    zip: '06807',
    city: 'Cos Cob',
    state: 'CT',
    latitude: '41.049767',
    longitude: '-73.589652',
    timeZoneId: 'America/New_York',
  },
  '06810': {
    zip: '06810',
    city: 'Danbury',
    state: 'CT',
    latitude: '41.389689',
    longitude: '-73.454963',
    timeZoneId: 'America/New_York',
  },
  '06811': {
    zip: '06811',
    city: 'Danbury',
    state: 'CT',
    latitude: '41.422764',
    longitude: '-73.473148',
    timeZoneId: 'America/New_York',
  },
  '06812': {
    zip: '06812',
    city: 'New Fairfield',
    state: 'CT',
    latitude: '41.48514',
    longitude: '-73.48752',
    timeZoneId: 'America/New_York',
  },
  '06813': {
    zip: '06813',
    city: 'Danbury',
    state: 'CT',
    latitude: '41.397769',
    longitude: '-73.45523',
    timeZoneId: 'America/New_York',
  },
  '06814': {
    zip: '06814',
    city: 'Danbury',
    state: 'CT',
    latitude: '41.3773',
    longitude: '-73.488123',
    timeZoneId: 'America/New_York',
  },
  '06816': {
    zip: '06816',
    city: 'Danbury',
    state: 'CT',
    latitude: '41.410366',
    longitude: '-73.406983',
    timeZoneId: 'America/New_York',
  },
  '06817': {
    zip: '06817',
    city: 'Danbury',
    state: 'CT',
    latitude: '41.396437',
    longitude: '-73.456032',
    timeZoneId: 'America/New_York',
  },
  '06820': {
    zip: '06820',
    city: 'Darien',
    state: 'CT',
    latitude: '41.079898',
    longitude: '-73.480707',
    timeZoneId: 'America/New_York',
  },
  '06824': {
    zip: '06824',
    city: 'Fairfield',
    state: 'CT',
    latitude: '41.168961',
    longitude: '-73.26513',
    timeZoneId: 'America/New_York',
  },
  '06825': {
    zip: '06825',
    city: 'Fairfield',
    state: 'CT',
    latitude: '41.194876',
    longitude: '-73.240637',
    timeZoneId: 'America/New_York',
  },
  '06828': {
    zip: '06828',
    city: 'Fairfield',
    state: 'CT',
    latitude: '41.218272',
    longitude: '-73.252765',
    timeZoneId: 'America/New_York',
  },
  '06829': {
    zip: '06829',
    city: 'Georgetown',
    state: 'CT',
    latitude: '41.257587',
    longitude: '-73.432452',
    timeZoneId: 'America/New_York',
  },
  '06830': {
    zip: '06830',
    city: 'Greenwich',
    state: 'CT',
    latitude: '41.035494',
    longitude: '-73.628183',
    timeZoneId: 'America/New_York',
  },
  '06831': {
    zip: '06831',
    city: 'Greenwich',
    state: 'CT',
    latitude: '41.058575',
    longitude: '-73.658439',
    timeZoneId: 'America/New_York',
  },
  '06836': {
    zip: '06836',
    city: 'Greenwich',
    state: 'CT',
    latitude: '41.022765',
    longitude: '-73.628447',
    timeZoneId: 'America/New_York',
  },
  '06838': {
    zip: '06838',
    city: 'Greens Farms',
    state: 'CT',
    latitude: '41.124408',
    longitude: '-73.313937',
    timeZoneId: 'America/New_York',
  },
  '06840': {
    zip: '06840',
    city: 'New Canaan',
    state: 'CT',
    latitude: '41.151963',
    longitude: '-73.496253',
    timeZoneId: 'America/New_York',
  },
  '06850': {
    zip: '06850',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.128164',
    longitude: '-73.441537',
    timeZoneId: 'America/New_York',
  },
  '06851': {
    zip: '06851',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.132716',
    longitude: '-73.402689',
    timeZoneId: 'America/New_York',
  },
  '06852': {
    zip: '06852',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.093595',
    longitude: '-73.417257',
    timeZoneId: 'America/New_York',
  },
  '06853': {
    zip: '06853',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.069465',
    longitude: '-73.442358',
    timeZoneId: 'America/New_York',
  },
  '06854': {
    zip: '06854',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.091966',
    longitude: '-73.433011',
    timeZoneId: 'America/New_York',
  },
  '06855': {
    zip: '06855',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.099161',
    longitude: '-73.400423',
    timeZoneId: 'America/New_York',
  },
  '06856': {
    zip: '06856',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.111028',
    longitude: '-73.422612',
    timeZoneId: 'America/New_York',
  },
  '06857': {
    zip: '06857',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.094752',
    longitude: '-73.434074',
    timeZoneId: 'America/New_York',
  },
  '06858': {
    zip: '06858',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.130471',
    longitude: '-73.440439',
    timeZoneId: 'America/New_York',
  },
  '06860': {
    zip: '06860',
    city: 'Norwalk',
    state: 'CT',
    latitude: '41.086401',
    longitude: '-73.47559',
    timeZoneId: 'America/New_York',
  },
  '06870': {
    zip: '06870',
    city: 'Old Greenwich',
    state: 'CT',
    latitude: '41.035892',
    longitude: '-73.567475',
    timeZoneId: 'America/New_York',
  },
  '06875': {
    zip: '06875',
    city: 'Redding Center',
    state: 'CT',
    latitude: '41.324045',
    longitude: '-73.370143',
    timeZoneId: 'America/New_York',
  },
  '06876': {
    zip: '06876',
    city: 'Redding Ridge',
    state: 'CT',
    latitude: '41.319639',
    longitude: '-73.355876',
    timeZoneId: 'America/New_York',
  },
  '06877': {
    zip: '06877',
    city: 'Ridgefield',
    state: 'CT',
    latitude: '41.299046',
    longitude: '-73.49765',
    timeZoneId: 'America/New_York',
  },
  '06878': {
    zip: '06878',
    city: 'Riverside',
    state: 'CT',
    latitude: '41.035745',
    longitude: '-73.581706',
    timeZoneId: 'America/New_York',
  },
  '06879': {
    zip: '06879',
    city: 'Ridgefield',
    state: 'CT',
    latitude: '41.272541',
    longitude: '-73.497016',
    timeZoneId: 'America/New_York',
  },
  '06880': {
    zip: '06880',
    city: 'Westport',
    state: 'CT',
    latitude: '41.138856',
    longitude: '-73.347965',
    timeZoneId: 'America/New_York',
  },
  '06881': {
    zip: '06881',
    city: 'Westport',
    state: 'CT',
    latitude: '41.13776',
    longitude: '-73.365029',
    timeZoneId: 'America/New_York',
  },
  '06883': {
    zip: '06883',
    city: 'Weston',
    state: 'CT',
    latitude: '41.216732',
    longitude: '-73.366948',
    timeZoneId: 'America/New_York',
  },
  '06888': {
    zip: '06888',
    city: 'Westport',
    state: 'CT',
    latitude: '41.120054',
    longitude: '-73.346424',
    timeZoneId: 'America/New_York',
  },
  '06889': {
    zip: '06889',
    city: 'Westport',
    state: 'CT',
    latitude: '41.142373',
    longitude: '-73.360124',
    timeZoneId: 'America/New_York',
  },
  '06890': {
    zip: '06890',
    city: 'Southport',
    state: 'CT',
    latitude: '41.140672',
    longitude: '-73.288227',
    timeZoneId: 'America/New_York',
  },
  '06896': {
    zip: '06896',
    city: 'Redding',
    state: 'CT',
    latitude: '41.299751',
    longitude: '-73.405213',
    timeZoneId: 'America/New_York',
  },
  '06897': {
    zip: '06897',
    city: 'Wilton',
    state: 'CT',
    latitude: '41.204177',
    longitude: '-73.435815',
    timeZoneId: 'America/New_York',
  },
  '06901': {
    zip: '06901',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.051933',
    longitude: '-73.53894',
    timeZoneId: 'America/New_York',
  },
  '06902': {
    zip: '06902',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.054668',
    longitude: '-73.543191',
    timeZoneId: 'America/New_York',
  },
  '06903': {
    zip: '06903',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.136379',
    longitude: '-73.566393',
    timeZoneId: 'America/New_York',
  },
  '06904': {
    zip: '06904',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.05114',
    longitude: '-73.540539',
    timeZoneId: 'America/New_York',
  },
  '06905': {
    zip: '06905',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.085715',
    longitude: '-73.541654',
    timeZoneId: 'America/New_York',
  },
  '06906': {
    zip: '06906',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.070278',
    longitude: '-73.523724',
    timeZoneId: 'America/New_York',
  },
  '06907': {
    zip: '06907',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.094957',
    longitude: '-73.519697',
    timeZoneId: 'America/New_York',
  },
  '06910': {
    zip: '06910',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.044441',
    longitude: '-73.549631',
    timeZoneId: 'America/New_York',
  },
  '06911': {
    zip: '06911',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.041357',
    longitude: '-73.562481',
    timeZoneId: 'America/New_York',
  },
  '06912': {
    zip: '06912',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.055122',
    longitude: '-73.540684',
    timeZoneId: 'America/New_York',
  },
  '06913': {
    zip: '06913',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.050068',
    longitude: '-73.538971',
    timeZoneId: 'America/New_York',
  },
  '06914': {
    zip: '06914',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.099971',
    longitude: '-73.551538',
    timeZoneId: 'America/New_York',
  },
  '06926': {
    zip: '06926',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.040259',
    longitude: '-73.548051',
    timeZoneId: 'America/New_York',
  },
  '06927': {
    zip: '06927',
    city: 'Stamford',
    state: 'CT',
    latitude: '41.043828',
    longitude: '-73.548111',
    timeZoneId: 'America/New_York',
  },
  '07001': {
    zip: '07001',
    city: 'Avenel',
    state: 'NJ',
    latitude: '40.577272',
    longitude: '-74.276173',
    timeZoneId: 'America/New_York',
  },
  '07002': {
    zip: '07002',
    city: 'Bayonne',
    state: 'NJ',
    latitude: '40.664207',
    longitude: '-74.122276',
    timeZoneId: 'America/New_York',
  },
  '07003': {
    zip: '07003',
    city: 'Bloomfield',
    state: 'NJ',
    latitude: '40.805311',
    longitude: '-74.187412',
    timeZoneId: 'America/New_York',
  },
  '07004': {
    zip: '07004',
    city: 'Fairfield',
    state: 'NJ',
    latitude: '40.88287',
    longitude: '-74.309056',
    timeZoneId: 'America/New_York',
  },
  '07005': {
    zip: '07005',
    city: 'Boonton',
    state: 'NJ',
    latitude: '40.91802',
    longitude: '-74.42586',
    timeZoneId: 'America/New_York',
  },
  '07006': {
    zip: '07006',
    city: 'Caldwell',
    state: 'NJ',
    latitude: '40.85502',
    longitude: '-74.27784',
    timeZoneId: 'America/New_York',
  },
  '07007': {
    zip: '07007',
    city: 'Caldwell',
    state: 'NJ',
    latitude: '40.840833',
    longitude: '-74.302222',
    timeZoneId: 'America/New_York',
  },
  '07008': {
    zip: '07008',
    city: 'Carteret',
    state: 'NJ',
    latitude: '40.584894',
    longitude: '-74.234297',
    timeZoneId: 'America/New_York',
  },
  '07009': {
    zip: '07009',
    city: 'Cedar Grove',
    state: 'NJ',
    latitude: '40.854335',
    longitude: '-74.231714',
    timeZoneId: 'America/New_York',
  },
  '07010': {
    zip: '07010',
    city: 'Cliffside Park',
    state: 'NJ',
    latitude: '40.819853',
    longitude: '-73.988554',
    timeZoneId: 'America/New_York',
  },
  '07011': {
    zip: '07011',
    city: 'Clifton',
    state: 'NJ',
    latitude: '40.877054',
    longitude: '-74.145198',
    timeZoneId: 'America/New_York',
  },
  '07012': {
    zip: '07012',
    city: 'Clifton',
    state: 'NJ',
    latitude: '40.846872',
    longitude: '-74.160888',
    timeZoneId: 'America/New_York',
  },
  '07013': {
    zip: '07013',
    city: 'Clifton',
    state: 'NJ',
    latitude: '40.868681',
    longitude: '-74.169979',
    timeZoneId: 'America/New_York',
  },
  '07014': {
    zip: '07014',
    city: 'Clifton',
    state: 'NJ',
    latitude: '40.835346',
    longitude: '-74.135292',
    timeZoneId: 'America/New_York',
  },
  '07015': {
    zip: '07015',
    city: 'Clifton',
    state: 'NJ',
    latitude: '40.891952',
    longitude: '-74.131272',
    timeZoneId: 'America/New_York',
  },
  '07016': {
    zip: '07016',
    city: 'Cranford',
    state: 'NJ',
    latitude: '40.654829',
    longitude: '-74.305974',
    timeZoneId: 'America/New_York',
  },
  '07017': {
    zip: '07017',
    city: 'East Orange',
    state: 'NJ',
    latitude: '40.772043',
    longitude: '-74.209455',
    timeZoneId: 'America/New_York',
  },
  '07018': {
    zip: '07018',
    city: 'East Orange',
    state: 'NJ',
    latitude: '40.754785',
    longitude: '-74.218897',
    timeZoneId: 'America/New_York',
  },
  '07019': {
    zip: '07019',
    city: 'East Orange',
    state: 'NJ',
    latitude: '40.759938',
    longitude: '-74.207934',
    timeZoneId: 'America/New_York',
  },
  '07020': {
    zip: '07020',
    city: 'Edgewater',
    state: 'NJ',
    latitude: '40.832415',
    longitude: '-73.972271',
    timeZoneId: 'America/New_York',
  },
  '07021': {
    zip: '07021',
    city: 'Essex Fells',
    state: 'NJ',
    latitude: '40.830223',
    longitude: '-74.280703',
    timeZoneId: 'America/New_York',
  },
  '07022': {
    zip: '07022',
    city: 'Fairview',
    state: 'NJ',
    latitude: '40.81774',
    longitude: '-74.004196',
    timeZoneId: 'America/New_York',
  },
  '07023': {
    zip: '07023',
    city: 'Fanwood',
    state: 'NJ',
    latitude: '40.641449',
    longitude: '-74.382226',
    timeZoneId: 'America/New_York',
  },
  '07024': {
    zip: '07024',
    city: 'Fort Lee',
    state: 'NJ',
    latitude: '40.851376',
    longitude: '-73.975825',
    timeZoneId: 'America/New_York',
  },
  '07026': {
    zip: '07026',
    city: 'Garfield',
    state: 'NJ',
    latitude: '40.879414',
    longitude: '-74.109454',
    timeZoneId: 'America/New_York',
  },
  '07027': {
    zip: '07027',
    city: 'Garwood',
    state: 'NJ',
    latitude: '40.651917',
    longitude: '-74.323185',
    timeZoneId: 'America/New_York',
  },
  '07028': {
    zip: '07028',
    city: 'Glen Ridge',
    state: 'NJ',
    latitude: '40.808362',
    longitude: '-74.207544',
    timeZoneId: 'America/New_York',
  },
  '07029': {
    zip: '07029',
    city: 'Harrison',
    state: 'NJ',
    latitude: '40.744953',
    longitude: '-74.152306',
    timeZoneId: 'America/New_York',
  },
  '07030': {
    zip: '07030',
    city: 'Hoboken',
    state: 'NJ',
    latitude: '40.747748',
    longitude: '-74.03386',
    timeZoneId: 'America/New_York',
  },
  '07031': {
    zip: '07031',
    city: 'North Arlington',
    state: 'NJ',
    latitude: '40.792821',
    longitude: '-74.128838',
    timeZoneId: 'America/New_York',
  },
  '07032': {
    zip: '07032',
    city: 'Kearny',
    state: 'NJ',
    latitude: '40.76232',
    longitude: '-74.12322',
    timeZoneId: 'America/New_York',
  },
  '07033': {
    zip: '07033',
    city: 'Kenilworth',
    state: 'NJ',
    latitude: '40.678129',
    longitude: '-74.294073',
    timeZoneId: 'America/New_York',
  },
  '07034': {
    zip: '07034',
    city: 'Lake Hiawatha',
    state: 'NJ',
    latitude: '40.879225',
    longitude: '-74.384333',
    timeZoneId: 'America/New_York',
  },
  '07035': {
    zip: '07035',
    city: 'Lincoln Park',
    state: 'NJ',
    latitude: '40.924465',
    longitude: '-74.302643',
    timeZoneId: 'America/New_York',
  },
  '07036': {
    zip: '07036',
    city: 'Linden',
    state: 'NJ',
    latitude: '40.635633',
    longitude: '-74.256169',
    timeZoneId: 'America/New_York',
  },
  '07039': {
    zip: '07039',
    city: 'Livingston',
    state: 'NJ',
    latitude: '40.787368',
    longitude: '-74.319046',
    timeZoneId: 'America/New_York',
  },
  '07040': {
    zip: '07040',
    city: 'Maplewood',
    state: 'NJ',
    latitude: '40.725322',
    longitude: '-74.265292',
    timeZoneId: 'America/New_York',
  },
  '07041': {
    zip: '07041',
    city: 'Millburn',
    state: 'NJ',
    latitude: '40.724998',
    longitude: '-74.30362',
    timeZoneId: 'America/New_York',
  },
  '07042': {
    zip: '07042',
    city: 'Montclair',
    state: 'NJ',
    latitude: '40.812954',
    longitude: '-74.215386',
    timeZoneId: 'America/New_York',
  },
  '07043': {
    zip: '07043',
    city: 'Montclair',
    state: 'NJ',
    latitude: '40.841403',
    longitude: '-74.201411',
    timeZoneId: 'America/New_York',
  },
  '07044': {
    zip: '07044',
    city: 'Verona',
    state: 'NJ',
    latitude: '40.833949',
    longitude: '-74.245427',
    timeZoneId: 'America/New_York',
  },
  '07045': {
    zip: '07045',
    city: 'Montville',
    state: 'NJ',
    latitude: '40.909925',
    longitude: '-74.364898',
    timeZoneId: 'America/New_York',
  },
  '07046': {
    zip: '07046',
    city: 'Mountain Lakes',
    state: 'NJ',
    latitude: '40.891687',
    longitude: '-74.439534',
    timeZoneId: 'America/New_York',
  },
  '07047': {
    zip: '07047',
    city: 'North Bergen',
    state: 'NJ',
    latitude: '40.793459',
    longitude: '-74.022545',
    timeZoneId: 'America/New_York',
  },
  '07050': {
    zip: '07050',
    city: 'Orange',
    state: 'NJ',
    latitude: '40.770499',
    longitude: '-74.23781',
    timeZoneId: 'America/New_York',
  },
  '07051': {
    zip: '07051',
    city: 'Orange',
    state: 'NJ',
    latitude: '40.770586',
    longitude: '-74.239153',
    timeZoneId: 'America/New_York',
  },
  '07052': {
    zip: '07052',
    city: 'West Orange',
    state: 'NJ',
    latitude: '40.784909',
    longitude: '-74.257646',
    timeZoneId: 'America/New_York',
  },
  '07054': {
    zip: '07054',
    city: 'Parsippany',
    state: 'NJ',
    latitude: '40.859388',
    longitude: '-74.413425',
    timeZoneId: 'America/New_York',
  },
  '07055': {
    zip: '07055',
    city: 'Passaic',
    state: 'NJ',
    latitude: '40.859415',
    longitude: '-74.130577',
    timeZoneId: 'America/New_York',
  },
  '07057': {
    zip: '07057',
    city: 'Wallington',
    state: 'NJ',
    latitude: '40.854569',
    longitude: '-74.10754',
    timeZoneId: 'America/New_York',
  },
  '07058': {
    zip: '07058',
    city: 'Pine Brook',
    state: 'NJ',
    latitude: '40.871765',
    longitude: '-74.347469',
    timeZoneId: 'America/New_York',
  },
  '07059': {
    zip: '07059',
    city: 'Warren',
    state: 'NJ',
    latitude: '40.631567',
    longitude: '-74.508064',
    timeZoneId: 'America/New_York',
  },
  '07060': {
    zip: '07060',
    city: 'Plainfield',
    state: 'NJ',
    latitude: '40.63134',
    longitude: '-74.43702',
    timeZoneId: 'America/New_York',
  },
  '07061': {
    zip: '07061',
    city: 'Plainfield',
    state: 'NJ',
    latitude: '40.615053',
    longitude: '-74.414487',
    timeZoneId: 'America/New_York',
  },
  '07062': {
    zip: '07062',
    city: 'Plainfield',
    state: 'NJ',
    latitude: '40.632249',
    longitude: '-74.399691',
    timeZoneId: 'America/New_York',
  },
  '07063': {
    zip: '07063',
    city: 'Plainfield',
    state: 'NJ',
    latitude: '40.609259',
    longitude: '-74.453927',
    timeZoneId: 'America/New_York',
  },
  '07064': {
    zip: '07064',
    city: 'Port Reading',
    state: 'NJ',
    latitude: '40.566503',
    longitude: '-74.257398',
    timeZoneId: 'America/New_York',
  },
  '07065': {
    zip: '07065',
    city: 'Rahway',
    state: 'NJ',
    latitude: '40.6095',
    longitude: '-74.280683',
    timeZoneId: 'America/New_York',
  },
  '07066': {
    zip: '07066',
    city: 'Clark',
    state: 'NJ',
    latitude: '40.623271',
    longitude: '-74.311429',
    timeZoneId: 'America/New_York',
  },
  '07067': {
    zip: '07067',
    city: 'Colonia',
    state: 'NJ',
    latitude: '40.593643',
    longitude: '-74.315267',
    timeZoneId: 'America/New_York',
  },
  '07068': {
    zip: '07068',
    city: 'Roseland',
    state: 'NJ',
    latitude: '40.819209',
    longitude: '-74.298691',
    timeZoneId: 'America/New_York',
  },
  '07069': {
    zip: '07069',
    city: 'Watchung',
    state: 'NJ',
    latitude: '40.636817',
    longitude: '-74.452093',
    timeZoneId: 'America/New_York',
  },
  '07070': {
    zip: '07070',
    city: 'Rutherford',
    state: 'NJ',
    latitude: '40.828658',
    longitude: '-74.111834',
    timeZoneId: 'America/New_York',
  },
  '07071': {
    zip: '07071',
    city: 'Lyndhurst',
    state: 'NJ',
    latitude: '40.807324',
    longitude: '-74.122919',
    timeZoneId: 'America/New_York',
  },
  '07072': {
    zip: '07072',
    city: 'Carlstadt',
    state: 'NJ',
    latitude: '40.83431',
    longitude: '-74.083962',
    timeZoneId: 'America/New_York',
  },
  '07073': {
    zip: '07073',
    city: 'East Rutherford',
    state: 'NJ',
    latitude: '40.839748',
    longitude: '-74.102949',
    timeZoneId: 'America/New_York',
  },
  '07074': {
    zip: '07074',
    city: 'Moonachie',
    state: 'NJ',
    latitude: '40.839122',
    longitude: '-74.05782',
    timeZoneId: 'America/New_York',
  },
  '07075': {
    zip: '07075',
    city: 'Wood Ridge',
    state: 'NJ',
    latitude: '40.847097',
    longitude: '-74.08537',
    timeZoneId: 'America/New_York',
  },
  '07076': {
    zip: '07076',
    city: 'Scotch Plains',
    state: 'NJ',
    latitude: '40.619819',
    longitude: '-74.375205',
    timeZoneId: 'America/New_York',
  },
  '07077': {
    zip: '07077',
    city: 'Sewaren',
    state: 'NJ',
    latitude: '40.554739',
    longitude: '-74.26668',
    timeZoneId: 'America/New_York',
  },
  '07078': {
    zip: '07078',
    city: 'Short Hills',
    state: 'NJ',
    latitude: '40.73726',
    longitude: '-74.329009',
    timeZoneId: 'America/New_York',
  },
  '07079': {
    zip: '07079',
    city: 'South Orange',
    state: 'NJ',
    latitude: '40.746197',
    longitude: '-74.260788',
    timeZoneId: 'America/New_York',
  },
  '07080': {
    zip: '07080',
    city: 'South Plainfield',
    state: 'NJ',
    latitude: '40.580308',
    longitude: '-74.413829',
    timeZoneId: 'America/New_York',
  },
  '07081': {
    zip: '07081',
    city: 'Springfield',
    state: 'NJ',
    latitude: '40.700489',
    longitude: '-74.322044',
    timeZoneId: 'America/New_York',
  },
  '07082': {
    zip: '07082',
    city: 'Towaco',
    state: 'NJ',
    latitude: '40.926548',
    longitude: '-74.344404',
    timeZoneId: 'America/New_York',
  },
  '07083': {
    zip: '07083',
    city: 'Union',
    state: 'NJ',
    latitude: '40.691867',
    longitude: '-74.269598',
    timeZoneId: 'America/New_York',
  },
  '07086': {
    zip: '07086',
    city: 'Weehawken',
    state: 'NJ',
    latitude: '40.766011',
    longitude: '-74.02975',
    timeZoneId: 'America/New_York',
  },
  '07087': {
    zip: '07087',
    city: 'Union City',
    state: 'NJ',
    latitude: '40.770419',
    longitude: '-74.030901',
    timeZoneId: 'America/New_York',
  },
  '07088': {
    zip: '07088',
    city: 'Vauxhall',
    state: 'NJ',
    latitude: '40.717813',
    longitude: '-74.284932',
    timeZoneId: 'America/New_York',
  },
  '07090': {
    zip: '07090',
    city: 'Westfield',
    state: 'NJ',
    latitude: '40.646334',
    longitude: '-74.345624',
    timeZoneId: 'America/New_York',
  },
  '07091': {
    zip: '07091',
    city: 'Westfield',
    state: 'NJ',
    latitude: '40.693796',
    longitude: '-74.382617',
    timeZoneId: 'America/New_York',
  },
  '07092': {
    zip: '07092',
    city: 'Mountainside',
    state: 'NJ',
    latitude: '40.675275',
    longitude: '-74.358365',
    timeZoneId: 'America/New_York',
  },
  '07093': {
    zip: '07093',
    city: 'West New York',
    state: 'NJ',
    latitude: '40.788201',
    longitude: '-74.013854',
    timeZoneId: 'America/New_York',
  },
  '07094': {
    zip: '07094',
    city: 'Secaucus',
    state: 'NJ',
    latitude: '40.789459',
    longitude: '-74.062775',
    timeZoneId: 'America/New_York',
  },
  '07095': {
    zip: '07095',
    city: 'Woodbridge',
    state: 'NJ',
    latitude: '40.558215',
    longitude: '-74.287244',
    timeZoneId: 'America/New_York',
  },
  '07096': {
    zip: '07096',
    city: 'Secaucus',
    state: 'NJ',
    latitude: '40.781235',
    longitude: '-74.06458',
    timeZoneId: 'America/New_York',
  },
  '07097': {
    zip: '07097',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.751433',
    longitude: '-74.050904',
    timeZoneId: 'America/New_York',
  },
  '07099': {
    zip: '07099',
    city: 'Kearny',
    state: 'NJ',
    latitude: '40.753847',
    longitude: '-74.11996',
    timeZoneId: 'America/New_York',
  },
  '07101': {
    zip: '07101',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.725761',
    longitude: '-74.172221',
    timeZoneId: 'America/New_York',
  },
  '07102': {
    zip: '07102',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.732638',
    longitude: '-74.171775',
    timeZoneId: 'America/New_York',
  },
  '07103': {
    zip: '07103',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.737637',
    longitude: '-74.196772',
    timeZoneId: 'America/New_York',
  },
  '07104': {
    zip: '07104',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.768591',
    longitude: '-74.168632',
    timeZoneId: 'America/New_York',
  },
  '07105': {
    zip: '07105',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.72214',
    longitude: '-74.140998',
    timeZoneId: 'America/New_York',
  },
  '07106': {
    zip: '07106',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.740457',
    longitude: '-74.232487',
    timeZoneId: 'America/New_York',
  },
  '07107': {
    zip: '07107',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.759519',
    longitude: '-74.19073',
    timeZoneId: 'America/New_York',
  },
  '07108': {
    zip: '07108',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.722703',
    longitude: '-74.202491',
    timeZoneId: 'America/New_York',
  },
  '07109': {
    zip: '07109',
    city: 'Belleville',
    state: 'NJ',
    latitude: '40.793718',
    longitude: '-74.164895',
    timeZoneId: 'America/New_York',
  },
  '07110': {
    zip: '07110',
    city: 'Nutley',
    state: 'NJ',
    latitude: '40.819621',
    longitude: '-74.163016',
    timeZoneId: 'America/New_York',
  },
  '07111': {
    zip: '07111',
    city: 'Irvington',
    state: 'NJ',
    latitude: '40.72273',
    longitude: '-74.232016',
    timeZoneId: 'America/New_York',
  },
  '07112': {
    zip: '07112',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.709508',
    longitude: '-74.211572',
    timeZoneId: 'America/New_York',
  },
  '07114': {
    zip: '07114',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.713452',
    longitude: '-74.174179',
    timeZoneId: 'America/New_York',
  },
  '07175': {
    zip: '07175',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.735659',
    longitude: '-74.171397',
    timeZoneId: 'America/New_York',
  },
  '07184': {
    zip: '07184',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.725585',
    longitude: '-74.218488',
    timeZoneId: 'America/New_York',
  },
  '07188': {
    zip: '07188',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.730949',
    longitude: '-74.17544',
    timeZoneId: 'America/New_York',
  },
  '07189': {
    zip: '07189',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.797515',
    longitude: '-74.16181',
    timeZoneId: 'America/New_York',
  },
  '07191': {
    zip: '07191',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.702983',
    longitude: '-74.168748',
    timeZoneId: 'America/New_York',
  },
  '07192': {
    zip: '07192',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.73005',
    longitude: '-74.176568',
    timeZoneId: 'America/New_York',
  },
  '07193': {
    zip: '07193',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.729343',
    longitude: '-74.176946',
    timeZoneId: 'America/New_York',
  },
  '07195': {
    zip: '07195',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.729819',
    longitude: '-74.175366',
    timeZoneId: 'America/New_York',
  },
  '07198': {
    zip: '07198',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.73588',
    longitude: '-74.143855',
    timeZoneId: 'America/New_York',
  },
  '07199': {
    zip: '07199',
    city: 'Newark',
    state: 'NJ',
    latitude: '40.72707',
    longitude: '-74.172065',
    timeZoneId: 'America/New_York',
  },
  '07201': {
    zip: '07201',
    city: 'Elizabeth',
    state: 'NJ',
    latitude: '40.664256',
    longitude: '-74.17989',
    timeZoneId: 'America/New_York',
  },
  '07202': {
    zip: '07202',
    city: 'Elizabeth',
    state: 'NJ',
    latitude: '40.656153',
    longitude: '-74.218524',
    timeZoneId: 'America/New_York',
  },
  '07203': {
    zip: '07203',
    city: 'Roselle',
    state: 'NJ',
    latitude: '40.650599',
    longitude: '-74.260873',
    timeZoneId: 'America/New_York',
  },
  '07204': {
    zip: '07204',
    city: 'Roselle Park',
    state: 'NJ',
    latitude: '40.66336',
    longitude: '-74.269618',
    timeZoneId: 'America/New_York',
  },
  '07205': {
    zip: '07205',
    city: 'Hillside',
    state: 'NJ',
    latitude: '40.693929',
    longitude: '-74.228806',
    timeZoneId: 'America/New_York',
  },
  '07206': {
    zip: '07206',
    city: 'Elizabethport',
    state: 'NJ',
    latitude: '40.654517',
    longitude: '-74.189804',
    timeZoneId: 'America/New_York',
  },
  '07207': {
    zip: '07207',
    city: 'Elizabeth',
    state: 'NJ',
    latitude: '40.669662',
    longitude: '-74.217927',
    timeZoneId: 'America/New_York',
  },
  '07208': {
    zip: '07208',
    city: 'Elizabeth',
    state: 'NJ',
    latitude: '40.67442',
    longitude: '-74.225148',
    timeZoneId: 'America/New_York',
  },
  '07302': {
    zip: '07302',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.726243',
    longitude: '-74.048743',
    timeZoneId: 'America/New_York',
  },
  '07303': {
    zip: '07303',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.717652',
    longitude: '-74.045298',
    timeZoneId: 'America/New_York',
  },
  '07304': {
    zip: '07304',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.718014',
    longitude: '-74.075125',
    timeZoneId: 'America/New_York',
  },
  '07305': {
    zip: '07305',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.69386',
    longitude: '-74.07882',
    timeZoneId: 'America/New_York',
  },
  '07306': {
    zip: '07306',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.733605',
    longitude: '-74.065616',
    timeZoneId: 'America/New_York',
  },
  '07307': {
    zip: '07307',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.747679',
    longitude: '-74.049894',
    timeZoneId: 'America/New_York',
  },
  '07308': {
    zip: '07308',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.731521',
    longitude: '-74.061754',
    timeZoneId: 'America/New_York',
  },
  '07310': {
    zip: '07310',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.733081',
    longitude: '-74.041881',
    timeZoneId: 'America/New_York',
  },
  '07311': {
    zip: '07311',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.720815',
    longitude: '-74.045882',
    timeZoneId: 'America/New_York',
  },
  '07395': {
    zip: '07395',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.727933',
    longitude: '-74.077952',
    timeZoneId: 'America/New_York',
  },
  '07399': {
    zip: '07399',
    city: 'Jersey City',
    state: 'NJ',
    latitude: '40.727839',
    longitude: '-74.078433',
    timeZoneId: 'America/New_York',
  },
  '07401': {
    zip: '07401',
    city: 'Allendale',
    state: 'NJ',
    latitude: '41.032472',
    longitude: '-74.13373',
    timeZoneId: 'America/New_York',
  },
  '07403': {
    zip: '07403',
    city: 'Bloomingdale',
    state: 'NJ',
    latitude: '41.013238',
    longitude: '-74.333291',
    timeZoneId: 'America/New_York',
  },
  '07405': {
    zip: '07405',
    city: 'Butler',
    state: 'NJ',
    latitude: '40.988669',
    longitude: '-74.383743',
    timeZoneId: 'America/New_York',
  },
  '07407': {
    zip: '07407',
    city: 'Elmwood Park',
    state: 'NJ',
    latitude: '40.905895',
    longitude: '-74.121413',
    timeZoneId: 'America/New_York',
  },
  '07410': {
    zip: '07410',
    city: 'Fair Lawn',
    state: 'NJ',
    latitude: '40.934411',
    longitude: '-74.117638',
    timeZoneId: 'America/New_York',
  },
  '07416': {
    zip: '07416',
    city: 'Franklin',
    state: 'NJ',
    latitude: '41.117073',
    longitude: '-74.599284',
    timeZoneId: 'America/New_York',
  },
  '07417': {
    zip: '07417',
    city: 'Franklin Lakes',
    state: 'NJ',
    latitude: '41.010518',
    longitude: '-74.208897',
    timeZoneId: 'America/New_York',
  },
  '07418': {
    zip: '07418',
    city: 'Glenwood',
    state: 'NJ',
    latitude: '41.246598',
    longitude: '-74.4847',
    timeZoneId: 'America/New_York',
  },
  '07419': {
    zip: '07419',
    city: 'Hamburg',
    state: 'NJ',
    latitude: '41.15397',
    longitude: '-74.567322',
    timeZoneId: 'America/New_York',
  },
  '07420': {
    zip: '07420',
    city: 'Haskell',
    state: 'NJ',
    latitude: '41.02815',
    longitude: '-74.298139',
    timeZoneId: 'America/New_York',
  },
  '07421': {
    zip: '07421',
    city: 'Hewitt',
    state: 'NJ',
    latitude: '41.15742',
    longitude: '-74.35266',
    timeZoneId: 'America/New_York',
  },
  '07422': {
    zip: '07422',
    city: 'Highland Lakes',
    state: 'NJ',
    latitude: '41.18724',
    longitude: '-74.45376',
    timeZoneId: 'America/New_York',
  },
  '07423': {
    zip: '07423',
    city: 'Ho Ho Kus',
    state: 'NJ',
    latitude: '40.997732',
    longitude: '-74.102172',
    timeZoneId: 'America/New_York',
  },
  '07424': {
    zip: '07424',
    city: 'Little Falls',
    state: 'NJ',
    latitude: '40.887151',
    longitude: '-74.218789',
    timeZoneId: 'America/New_York',
  },
  '07428': {
    zip: '07428',
    city: 'Mc Afee',
    state: 'NJ',
    latitude: '41.185381',
    longitude: '-74.551549',
    timeZoneId: 'America/New_York',
  },
  '07430': {
    zip: '07430',
    city: 'Mahwah',
    state: 'NJ',
    latitude: '41.074187',
    longitude: '-74.153515',
    timeZoneId: 'America/New_York',
  },
  '07432': {
    zip: '07432',
    city: 'Midland Park',
    state: 'NJ',
    latitude: '40.995725',
    longitude: '-74.139296',
    timeZoneId: 'America/New_York',
  },
  '07435': {
    zip: '07435',
    city: 'Newfoundland',
    state: 'NJ',
    latitude: '41.05698',
    longitude: '-74.42892',
    timeZoneId: 'America/New_York',
  },
  '07436': {
    zip: '07436',
    city: 'Oakland',
    state: 'NJ',
    latitude: '41.02974',
    longitude: '-74.233815',
    timeZoneId: 'America/New_York',
  },
  '07438': {
    zip: '07438',
    city: 'Oak Ridge',
    state: 'NJ',
    latitude: '41.03346',
    longitude: '-74.49984',
    timeZoneId: 'America/New_York',
  },
  '07439': {
    zip: '07439',
    city: 'Ogdensburg',
    state: 'NJ',
    latitude: '41.073022',
    longitude: '-74.595302',
    timeZoneId: 'America/New_York',
  },
  '07440': {
    zip: '07440',
    city: 'Pequannock',
    state: 'NJ',
    latitude: '40.946757',
    longitude: '-74.294839',
    timeZoneId: 'America/New_York',
  },
  '07442': {
    zip: '07442',
    city: 'Pompton Lakes',
    state: 'NJ',
    latitude: '41.000492',
    longitude: '-74.28691',
    timeZoneId: 'America/New_York',
  },
  '07444': {
    zip: '07444',
    city: 'Pompton Plains',
    state: 'NJ',
    latitude: '40.965391',
    longitude: '-74.307529',
    timeZoneId: 'America/New_York',
  },
  '07446': {
    zip: '07446',
    city: 'Ramsey',
    state: 'NJ',
    latitude: '41.059559',
    longitude: '-74.14669',
    timeZoneId: 'America/New_York',
  },
  '07450': {
    zip: '07450',
    city: 'Ridgewood',
    state: 'NJ',
    latitude: '40.981234',
    longitude: '-74.11358',
    timeZoneId: 'America/New_York',
  },
  '07451': {
    zip: '07451',
    city: 'Ridgewood',
    state: 'NJ',
    latitude: '40.980549',
    longitude: '-74.119354',
    timeZoneId: 'America/New_York',
  },
  '07452': {
    zip: '07452',
    city: 'Glen Rock',
    state: 'NJ',
    latitude: '40.966029',
    longitude: '-74.123474',
    timeZoneId: 'America/New_York',
  },
  '07456': {
    zip: '07456',
    city: 'Ringwood',
    state: 'NJ',
    latitude: '41.10084',
    longitude: '-74.29026',
    timeZoneId: 'America/New_York',
  },
  '07457': {
    zip: '07457',
    city: 'Riverdale',
    state: 'NJ',
    latitude: '40.99289',
    longitude: '-74.311301',
    timeZoneId: 'America/New_York',
  },
  '07458': {
    zip: '07458',
    city: 'Saddle River',
    state: 'NJ',
    latitude: '41.04546',
    longitude: '-74.09988',
    timeZoneId: 'America/New_York',
  },
  '07460': {
    zip: '07460',
    city: 'Stockholm',
    state: 'NJ',
    latitude: '41.0883',
    longitude: '-74.51442',
    timeZoneId: 'America/New_York',
  },
  '07461': {
    zip: '07461',
    city: 'Sussex',
    state: 'NJ',
    latitude: '41.243526',
    longitude: '-74.597544',
    timeZoneId: 'America/New_York',
  },
  '07462': {
    zip: '07462',
    city: 'Vernon',
    state: 'NJ',
    latitude: '41.182125',
    longitude: '-74.505004',
    timeZoneId: 'America/New_York',
  },
  '07463': {
    zip: '07463',
    city: 'Waldwick',
    state: 'NJ',
    latitude: '41.01205',
    longitude: '-74.127754',
    timeZoneId: 'America/New_York',
  },
  '07465': {
    zip: '07465',
    city: 'Wanaque',
    state: 'NJ',
    latitude: '41.053747',
    longitude: '-74.281246',
    timeZoneId: 'America/New_York',
  },
  '07470': {
    zip: '07470',
    city: 'Wayne',
    state: 'NJ',
    latitude: '40.947255',
    longitude: '-74.245839',
    timeZoneId: 'America/New_York',
  },
  '07474': {
    zip: '07474',
    city: 'Wayne',
    state: 'NJ',
    latitude: '40.949361',
    longitude: '-74.24745',
    timeZoneId: 'America/New_York',
  },
  '07480': {
    zip: '07480',
    city: 'West Milford',
    state: 'NJ',
    latitude: '41.090615',
    longitude: '-74.369917',
    timeZoneId: 'America/New_York',
  },
  '07481': {
    zip: '07481',
    city: 'Wyckoff',
    state: 'NJ',
    latitude: '40.998995',
    longitude: '-74.166584',
    timeZoneId: 'America/New_York',
  },
  '07495': {
    zip: '07495',
    city: 'Mahwah',
    state: 'NJ',
    latitude: '41.081134',
    longitude: '-74.186657',
    timeZoneId: 'America/New_York',
  },
  '07501': {
    zip: '07501',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.913332',
    longitude: '-74.175473',
    timeZoneId: 'America/New_York',
  },
  '07502': {
    zip: '07502',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.918903',
    longitude: '-74.194887',
    timeZoneId: 'America/New_York',
  },
  '07503': {
    zip: '07503',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.897944',
    longitude: '-74.156957',
    timeZoneId: 'America/New_York',
  },
  '07504': {
    zip: '07504',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.910693',
    longitude: '-74.144663',
    timeZoneId: 'America/New_York',
  },
  '07505': {
    zip: '07505',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.913645',
    longitude: '-74.173944',
    timeZoneId: 'America/New_York',
  },
  '07506': {
    zip: '07506',
    city: 'Hawthorne',
    state: 'NJ',
    latitude: '40.95638',
    longitude: '-74.159033',
    timeZoneId: 'America/New_York',
  },
  '07507': {
    zip: '07507',
    city: 'Hawthorne',
    state: 'NJ',
    latitude: '40.916667',
    longitude: '-74.172222',
    timeZoneId: 'America/New_York',
  },
  '07508': {
    zip: '07508',
    city: 'Haledon',
    state: 'NJ',
    latitude: '40.951433',
    longitude: '-74.185795',
    timeZoneId: 'America/New_York',
  },
  '07509': {
    zip: '07509',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.911995',
    longitude: '-74.172226',
    timeZoneId: 'America/New_York',
  },
  '07510': {
    zip: '07510',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.910846',
    longitude: '-74.172169',
    timeZoneId: 'America/New_York',
  },
  '07511': {
    zip: '07511',
    city: 'Totowa',
    state: 'NJ',
    latitude: '40.902429',
    longitude: '-74.22109',
    timeZoneId: 'America/New_York',
  },
  '07512': {
    zip: '07512',
    city: 'Totowa',
    state: 'NJ',
    latitude: '40.902106',
    longitude: '-74.218941',
    timeZoneId: 'America/New_York',
  },
  '07513': {
    zip: '07513',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.907163',
    longitude: '-74.148803',
    timeZoneId: 'America/New_York',
  },
  '07514': {
    zip: '07514',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.923819',
    longitude: '-74.146829',
    timeZoneId: 'America/New_York',
  },
  '07522': {
    zip: '07522',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.924122',
    longitude: '-74.183291',
    timeZoneId: 'America/New_York',
  },
  '07524': {
    zip: '07524',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.928547',
    longitude: '-74.155124',
    timeZoneId: 'America/New_York',
  },
  '07533': {
    zip: '07533',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.897871',
    longitude: '-74.154273',
    timeZoneId: 'America/New_York',
  },
  '07538': {
    zip: '07538',
    city: 'Haledon',
    state: 'NJ',
    latitude: '40.936601',
    longitude: '-74.189611',
    timeZoneId: 'America/New_York',
  },
  '07543': {
    zip: '07543',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.905',
    longitude: '-74.148698',
    timeZoneId: 'America/New_York',
  },
  '07544': {
    zip: '07544',
    city: 'Paterson',
    state: 'NJ',
    latitude: '40.930417',
    longitude: '-74.1575',
    timeZoneId: 'America/New_York',
  },
  '07601': {
    zip: '07601',
    city: 'Hackensack',
    state: 'NJ',
    latitude: '40.888613',
    longitude: '-74.04776',
    timeZoneId: 'America/New_York',
  },
  '07602': {
    zip: '07602',
    city: 'Hackensack',
    state: 'NJ',
    latitude: '40.890436',
    longitude: '-74.047813',
    timeZoneId: 'America/New_York',
  },
  '07603': {
    zip: '07603',
    city: 'Bogota',
    state: 'NJ',
    latitude: '40.874605',
    longitude: '-74.024967',
    timeZoneId: 'America/New_York',
  },
  '07604': {
    zip: '07604',
    city: 'Hasbrouck Heights',
    state: 'NJ',
    latitude: '40.865727',
    longitude: '-74.076151',
    timeZoneId: 'America/New_York',
  },
  '07605': {
    zip: '07605',
    city: 'Leonia',
    state: 'NJ',
    latitude: '40.862411',
    longitude: '-73.985614',
    timeZoneId: 'America/New_York',
  },
  '07606': {
    zip: '07606',
    city: 'South Hackensack',
    state: 'NJ',
    latitude: '40.867994',
    longitude: '-74.049082',
    timeZoneId: 'America/New_York',
  },
  '07607': {
    zip: '07607',
    city: 'Maywood',
    state: 'NJ',
    latitude: '40.903987',
    longitude: '-74.063587',
    timeZoneId: 'America/New_York',
  },
  '07608': {
    zip: '07608',
    city: 'Teterboro',
    state: 'NJ',
    latitude: '40.856525',
    longitude: '-74.064571',
    timeZoneId: 'America/New_York',
  },
  '07620': {
    zip: '07620',
    city: 'Alpine',
    state: 'NJ',
    latitude: '40.954487',
    longitude: '-73.925395',
    timeZoneId: 'America/New_York',
  },
  '07621': {
    zip: '07621',
    city: 'Bergenfield',
    state: 'NJ',
    latitude: '40.925835',
    longitude: '-74.001883',
    timeZoneId: 'America/New_York',
  },
  '07624': {
    zip: '07624',
    city: 'Closter',
    state: 'NJ',
    latitude: '40.971961',
    longitude: '-73.961859',
    timeZoneId: 'America/New_York',
  },
  '07626': {
    zip: '07626',
    city: 'Cresskill',
    state: 'NJ',
    latitude: '40.941788',
    longitude: '-73.964411',
    timeZoneId: 'America/New_York',
  },
  '07627': {
    zip: '07627',
    city: 'Demarest',
    state: 'NJ',
    latitude: '40.951934',
    longitude: '-73.959887',
    timeZoneId: 'America/New_York',
  },
  '07628': {
    zip: '07628',
    city: 'Dumont',
    state: 'NJ',
    latitude: '40.945165',
    longitude: '-73.993651',
    timeZoneId: 'America/New_York',
  },
  '07630': {
    zip: '07630',
    city: 'Emerson',
    state: 'NJ',
    latitude: '40.973523',
    longitude: '-74.030257',
    timeZoneId: 'America/New_York',
  },
  '07631': {
    zip: '07631',
    city: 'Englewood',
    state: 'NJ',
    latitude: '40.894215',
    longitude: '-73.977781',
    timeZoneId: 'America/New_York',
  },
  '07632': {
    zip: '07632',
    city: 'Englewood Cliffs',
    state: 'NJ',
    latitude: '40.883283',
    longitude: '-73.954892',
    timeZoneId: 'America/New_York',
  },
  '07640': {
    zip: '07640',
    city: 'Harrington Park',
    state: 'NJ',
    latitude: '40.991196',
    longitude: '-73.980288',
    timeZoneId: 'America/New_York',
  },
  '07641': {
    zip: '07641',
    city: 'Haworth',
    state: 'NJ',
    latitude: '40.958091',
    longitude: '-73.990667',
    timeZoneId: 'America/New_York',
  },
  '07642': {
    zip: '07642',
    city: 'Hillsdale',
    state: 'NJ',
    latitude: '41.008576',
    longitude: '-74.041923',
    timeZoneId: 'America/New_York',
  },
  '07643': {
    zip: '07643',
    city: 'Little Ferry',
    state: 'NJ',
    latitude: '40.847729',
    longitude: '-74.043006',
    timeZoneId: 'America/New_York',
  },
  '07644': {
    zip: '07644',
    city: 'Lodi',
    state: 'NJ',
    latitude: '40.878259',
    longitude: '-74.080661',
    timeZoneId: 'America/New_York',
  },
  '07645': {
    zip: '07645',
    city: 'Montvale',
    state: 'NJ',
    latitude: '41.046598',
    longitude: '-74.038288',
    timeZoneId: 'America/New_York',
  },
  '07646': {
    zip: '07646',
    city: 'New Milford',
    state: 'NJ',
    latitude: '40.933644',
    longitude: '-74.022176',
    timeZoneId: 'America/New_York',
  },
  '07647': {
    zip: '07647',
    city: 'Northvale',
    state: 'NJ',
    latitude: '41.010086',
    longitude: '-73.950666',
    timeZoneId: 'America/New_York',
  },
  '07648': {
    zip: '07648',
    city: 'Norwood',
    state: 'NJ',
    latitude: '40.994884',
    longitude: '-73.956089',
    timeZoneId: 'America/New_York',
  },
  '07649': {
    zip: '07649',
    city: 'Oradell',
    state: 'NJ',
    latitude: '40.951279',
    longitude: '-74.037564',
    timeZoneId: 'America/New_York',
  },
  '07650': {
    zip: '07650',
    city: 'Palisades Park',
    state: 'NJ',
    latitude: '40.84897',
    longitude: '-73.998019',
    timeZoneId: 'America/New_York',
  },
  '07652': {
    zip: '07652',
    city: 'Paramus',
    state: 'NJ',
    latitude: '40.947842',
    longitude: '-74.070107',
    timeZoneId: 'America/New_York',
  },
  '07653': {
    zip: '07653',
    city: 'Paramus',
    state: 'NJ',
    latitude: '40.947226',
    longitude: '-74.071226',
    timeZoneId: 'America/New_York',
  },
  '07656': {
    zip: '07656',
    city: 'Park Ridge',
    state: 'NJ',
    latitude: '41.032204',
    longitude: '-74.041158',
    timeZoneId: 'America/New_York',
  },
  '07657': {
    zip: '07657',
    city: 'Ridgefield',
    state: 'NJ',
    latitude: '40.83212',
    longitude: '-73.999158',
    timeZoneId: 'America/New_York',
  },
  '07660': {
    zip: '07660',
    city: 'Ridgefield Park',
    state: 'NJ',
    latitude: '40.856838',
    longitude: '-74.01939',
    timeZoneId: 'America/New_York',
  },
  '07661': {
    zip: '07661',
    city: 'River Edge',
    state: 'NJ',
    latitude: '40.929422',
    longitude: '-74.039126',
    timeZoneId: 'America/New_York',
  },
  '07662': {
    zip: '07662',
    city: 'Rochelle Park',
    state: 'NJ',
    latitude: '40.906515',
    longitude: '-74.089155',
    timeZoneId: 'America/New_York',
  },
  '07663': {
    zip: '07663',
    city: 'Saddle Brook',
    state: 'NJ',
    latitude: '40.902033',
    longitude: '-74.100102',
    timeZoneId: 'America/New_York',
  },
  '07666': {
    zip: '07666',
    city: 'Teaneck',
    state: 'NJ',
    latitude: '40.891901',
    longitude: '-74.008379',
    timeZoneId: 'America/New_York',
  },
  '07670': {
    zip: '07670',
    city: 'Tenafly',
    state: 'NJ',
    latitude: '40.923667',
    longitude: '-73.965326',
    timeZoneId: 'America/New_York',
  },
  '07675': {
    zip: '07675',
    city: 'Westwood',
    state: 'NJ',
    latitude: '41.000621',
    longitude: '-74.031463',
    timeZoneId: 'America/New_York',
  },
  '07676': {
    zip: '07676',
    city: 'Township of Washington',
    state: 'NJ',
    latitude: '41.006449',
    longitude: '-74.020718',
    timeZoneId: 'America/New_York',
  },
  '07677': {
    zip: '07677',
    city: 'Woodcliff Lake',
    state: 'NJ',
    latitude: '41.024537',
    longitude: '-74.060304',
    timeZoneId: 'America/New_York',
  },
  '07699': {
    zip: '07699',
    city: 'Teterboro',
    state: 'NJ',
    latitude: '40.859722',
    longitude: '-74.059722',
    timeZoneId: 'America/New_York',
  },
  '07701': {
    zip: '07701',
    city: 'Red Bank',
    state: 'NJ',
    latitude: '40.353122',
    longitude: '-74.074629',
    timeZoneId: 'America/New_York',
  },
  '07702': {
    zip: '07702',
    city: 'Shrewsbury',
    state: 'NJ',
    latitude: '40.346944',
    longitude: '-74.064722',
    timeZoneId: 'America/New_York',
  },
  '07703': {
    zip: '07703',
    city: 'Fort Monmouth',
    state: 'NJ',
    latitude: '40.315026',
    longitude: '-74.037045',
    timeZoneId: 'America/New_York',
  },
  '07704': {
    zip: '07704',
    city: 'Fair Haven',
    state: 'NJ',
    latitude: '40.37142',
    longitude: '-74.03562',
    timeZoneId: 'America/New_York',
  },
  '07710': {
    zip: '07710',
    city: 'Adelphia',
    state: 'NJ',
    latitude: '40.214096',
    longitude: '-74.262255',
    timeZoneId: 'America/New_York',
  },
  '07711': {
    zip: '07711',
    city: 'Allenhurst',
    state: 'NJ',
    latitude: '40.235381',
    longitude: '-74.005003',
    timeZoneId: 'America/New_York',
  },
  '07712': {
    zip: '07712',
    city: 'Asbury Park',
    state: 'NJ',
    latitude: '40.235268',
    longitude: '-74.035669',
    timeZoneId: 'America/New_York',
  },
  '07715': {
    zip: '07715',
    city: 'Belmar',
    state: 'NJ',
    latitude: '40.181879',
    longitude: '-74.022898',
    timeZoneId: 'America/New_York',
  },
  '07716': {
    zip: '07716',
    city: 'Atlantic Highlands',
    state: 'NJ',
    latitude: '40.404595',
    longitude: '-74.035623',
    timeZoneId: 'America/New_York',
  },
  '07717': {
    zip: '07717',
    city: 'Avon by the Sea',
    state: 'NJ',
    latitude: '40.192871',
    longitude: '-74.01717',
    timeZoneId: 'America/New_York',
  },
  '07718': {
    zip: '07718',
    city: 'Belford',
    state: 'NJ',
    latitude: '40.417983',
    longitude: '-74.088112',
    timeZoneId: 'America/New_York',
  },
  '07719': {
    zip: '07719',
    city: 'Belmar',
    state: 'NJ',
    latitude: '40.1655',
    longitude: '-74.07264',
    timeZoneId: 'America/New_York',
  },
  '07720': {
    zip: '07720',
    city: 'Bradley Beach',
    state: 'NJ',
    latitude: '40.203627',
    longitude: '-74.011629',
    timeZoneId: 'America/New_York',
  },
  '07721': {
    zip: '07721',
    city: 'Cliffwood',
    state: 'NJ',
    latitude: '40.433787',
    longitude: '-74.225814',
    timeZoneId: 'America/New_York',
  },
  '07722': {
    zip: '07722',
    city: 'Colts Neck',
    state: 'NJ',
    latitude: '40.309639',
    longitude: '-74.18206',
    timeZoneId: 'America/New_York',
  },
  '07723': {
    zip: '07723',
    city: 'Deal',
    state: 'NJ',
    latitude: '40.247668',
    longitude: '-74.001279',
    timeZoneId: 'America/New_York',
  },
  '07724': {
    zip: '07724',
    city: 'Eatontown',
    state: 'NJ',
    latitude: '40.299859',
    longitude: '-74.070754',
    timeZoneId: 'America/New_York',
  },
  '07726': {
    zip: '07726',
    city: 'Englishtown',
    state: 'NJ',
    latitude: '40.27776',
    longitude: '-74.36112',
    timeZoneId: 'America/New_York',
  },
  '07727': {
    zip: '07727',
    city: 'Farmingdale',
    state: 'NJ',
    latitude: '40.199657',
    longitude: '-74.163542',
    timeZoneId: 'America/New_York',
  },
  '07728': {
    zip: '07728',
    city: 'Freehold',
    state: 'NJ',
    latitude: '40.243519',
    longitude: '-74.277392',
    timeZoneId: 'America/New_York',
  },
  '07730': {
    zip: '07730',
    city: 'Hazlet',
    state: 'NJ',
    latitude: '40.421652',
    longitude: '-74.178151',
    timeZoneId: 'America/New_York',
  },
  '07731': {
    zip: '07731',
    city: 'Howell',
    state: 'NJ',
    latitude: '40.147323',
    longitude: '-74.206531',
    timeZoneId: 'America/New_York',
  },
  '07732': {
    zip: '07732',
    city: 'Highlands',
    state: 'NJ',
    latitude: '40.399752',
    longitude: '-73.997462',
    timeZoneId: 'America/New_York',
  },
  '07733': {
    zip: '07733',
    city: 'Holmdel',
    state: 'NJ',
    latitude: '40.385487',
    longitude: '-74.172575',
    timeZoneId: 'America/New_York',
  },
  '07734': {
    zip: '07734',
    city: 'Keansburg',
    state: 'NJ',
    latitude: '40.439051',
    longitude: '-74.12551',
    timeZoneId: 'America/New_York',
  },
  '07735': {
    zip: '07735',
    city: 'Keyport',
    state: 'NJ',
    latitude: '40.4382',
    longitude: '-74.20194',
    timeZoneId: 'America/New_York',
  },
  '07737': {
    zip: '07737',
    city: 'Leonardo',
    state: 'NJ',
    latitude: '40.411795',
    longitude: '-74.059003',
    timeZoneId: 'America/New_York',
  },
  '07738': {
    zip: '07738',
    city: 'Lincroft',
    state: 'NJ',
    latitude: '40.337902',
    longitude: '-74.126907',
    timeZoneId: 'America/New_York',
  },
  '07739': {
    zip: '07739',
    city: 'Little Silver',
    state: 'NJ',
    latitude: '40.334913',
    longitude: '-74.0449',
    timeZoneId: 'America/New_York',
  },
  '07740': {
    zip: '07740',
    city: 'Long Branch',
    state: 'NJ',
    latitude: '40.295596',
    longitude: '-73.99423',
    timeZoneId: 'America/New_York',
  },
  '07746': {
    zip: '07746',
    city: 'Marlboro',
    state: 'NJ',
    latitude: '40.319065',
    longitude: '-74.263182',
    timeZoneId: 'America/New_York',
  },
  '07747': {
    zip: '07747',
    city: 'Matawan',
    state: 'NJ',
    latitude: '40.4127',
    longitude: '-74.25252',
    timeZoneId: 'America/New_York',
  },
  '07748': {
    zip: '07748',
    city: 'Middletown',
    state: 'NJ',
    latitude: '40.395074',
    longitude: '-74.109292',
    timeZoneId: 'America/New_York',
  },
  '07750': {
    zip: '07750',
    city: 'Monmouth Beach',
    state: 'NJ',
    latitude: '40.332243',
    longitude: '-73.999422',
    timeZoneId: 'America/New_York',
  },
  '07751': {
    zip: '07751',
    city: 'Morganville',
    state: 'NJ',
    latitude: '40.350715',
    longitude: '-74.272724',
    timeZoneId: 'America/New_York',
  },
  '07752': {
    zip: '07752',
    city: 'Navesink',
    state: 'NJ',
    latitude: '40.385764',
    longitude: '-74.086517',
    timeZoneId: 'America/New_York',
  },
  '07753': {
    zip: '07753',
    city: 'Neptune',
    state: 'NJ',
    latitude: '40.209704',
    longitude: '-74.062049',
    timeZoneId: 'America/New_York',
  },
  '07754': {
    zip: '07754',
    city: 'Neptune',
    state: 'NJ',
    latitude: '40.201984',
    longitude: '-74.033686',
    timeZoneId: 'America/New_York',
  },
  '07755': {
    zip: '07755',
    city: 'Oakhurst',
    state: 'NJ',
    latitude: '40.264603',
    longitude: '-74.023335',
    timeZoneId: 'America/New_York',
  },
  '07756': {
    zip: '07756',
    city: 'Ocean Grove',
    state: 'NJ',
    latitude: '40.211956',
    longitude: '-74.008515',
    timeZoneId: 'America/New_York',
  },
  '07757': {
    zip: '07757',
    city: 'Oceanport',
    state: 'NJ',
    latitude: '40.315089',
    longitude: '-74.017467',
    timeZoneId: 'America/New_York',
  },
  '07758': {
    zip: '07758',
    city: 'Port Monmouth',
    state: 'NJ',
    latitude: '40.430608',
    longitude: '-74.106861',
    timeZoneId: 'America/New_York',
  },
  '07760': {
    zip: '07760',
    city: 'Rumson',
    state: 'NJ',
    latitude: '40.368572',
    longitude: '-74.000172',
    timeZoneId: 'America/New_York',
  },
  '07762': {
    zip: '07762',
    city: 'Spring Lake',
    state: 'NJ',
    latitude: '40.153489',
    longitude: '-74.03928',
    timeZoneId: 'America/New_York',
  },
  '07763': {
    zip: '07763',
    city: 'Tennent',
    state: 'NJ',
    latitude: '40.275339',
    longitude: '-74.337089',
    timeZoneId: 'America/New_York',
  },
  '07764': {
    zip: '07764',
    city: 'West Long Branch',
    state: 'NJ',
    latitude: '40.289869',
    longitude: '-74.022463',
    timeZoneId: 'America/New_York',
  },
  '07765': {
    zip: '07765',
    city: 'Wickatunk',
    state: 'NJ',
    latitude: '40.350285',
    longitude: '-74.248034',
    timeZoneId: 'America/New_York',
  },
  '07799': {
    zip: '07799',
    city: 'Eatontown',
    state: 'NJ',
    latitude: '40.36141',
    longitude: '-74.077982',
    timeZoneId: 'America/New_York',
  },
  '07801': {
    zip: '07801',
    city: 'Dover',
    state: 'NJ',
    latitude: '40.943413',
    longitude: '-74.523388',
    timeZoneId: 'America/New_York',
  },
  '07802': {
    zip: '07802',
    city: 'Dover',
    state: 'NJ',
    latitude: '40.854243',
    longitude: '-74.574694',
    timeZoneId: 'America/New_York',
  },
  '07803': {
    zip: '07803',
    city: 'Mine Hill',
    state: 'NJ',
    latitude: '40.880184',
    longitude: '-74.596365',
    timeZoneId: 'America/New_York',
  },
  '07806': {
    zip: '07806',
    city: 'Picatinny Arsenal',
    state: 'NJ',
    latitude: '40.939389',
    longitude: '-74.558453',
    timeZoneId: 'America/New_York',
  },
  '07820': {
    zip: '07820',
    city: 'Allamuchy',
    state: 'NJ',
    latitude: '40.913524',
    longitude: '-74.831169',
    timeZoneId: 'America/New_York',
  },
  '07821': {
    zip: '07821',
    city: 'Andover',
    state: 'NJ',
    latitude: '40.967267',
    longitude: '-74.766575',
    timeZoneId: 'America/New_York',
  },
  '07822': {
    zip: '07822',
    city: 'Augusta',
    state: 'NJ',
    latitude: '41.143214',
    longitude: '-74.696658',
    timeZoneId: 'America/New_York',
  },
  '07823': {
    zip: '07823',
    city: 'Belvidere',
    state: 'NJ',
    latitude: '40.829395',
    longitude: '-75.063548',
    timeZoneId: 'America/New_York',
  },
  '07825': {
    zip: '07825',
    city: 'Blairstown',
    state: 'NJ',
    latitude: '40.982604',
    longitude: '-74.950757',
    timeZoneId: 'America/New_York',
  },
  '07826': {
    zip: '07826',
    city: 'Branchville',
    state: 'NJ',
    latitude: '41.191414',
    longitude: '-74.750519',
    timeZoneId: 'America/New_York',
  },
  '07827': {
    zip: '07827',
    city: 'Montague',
    state: 'NJ',
    latitude: '41.30616',
    longitude: '-74.7489',
    timeZoneId: 'America/New_York',
  },
  '07828': {
    zip: '07828',
    city: 'Budd Lake',
    state: 'NJ',
    latitude: '40.886645',
    longitude: '-74.751763',
    timeZoneId: 'America/New_York',
  },
  '07829': {
    zip: '07829',
    city: 'Buttzville',
    state: 'NJ',
    latitude: '40.830698',
    longitude: '-75.008925',
    timeZoneId: 'America/New_York',
  },
  '07830': {
    zip: '07830',
    city: 'Califon',
    state: 'NJ',
    latitude: '40.71588',
    longitude: '-74.817513',
    timeZoneId: 'America/New_York',
  },
  '07831': {
    zip: '07831',
    city: 'Changewater',
    state: 'NJ',
    latitude: '40.732525',
    longitude: '-74.94154',
    timeZoneId: 'America/New_York',
  },
  '07832': {
    zip: '07832',
    city: 'Columbia',
    state: 'NJ',
    latitude: '40.986264',
    longitude: '-75.068098',
    timeZoneId: 'America/New_York',
  },
  '07833': {
    zip: '07833',
    city: 'Delaware',
    state: 'NJ',
    latitude: '40.891592',
    longitude: '-75.066951',
    timeZoneId: 'America/New_York',
  },
  '07834': {
    zip: '07834',
    city: 'Denville',
    state: 'NJ',
    latitude: '40.888195',
    longitude: '-74.484822',
    timeZoneId: 'America/New_York',
  },
  '07836': {
    zip: '07836',
    city: 'Flanders',
    state: 'NJ',
    latitude: '40.853244',
    longitude: '-74.708204',
    timeZoneId: 'America/New_York',
  },
  '07837': {
    zip: '07837',
    city: 'Glasser',
    state: 'NJ',
    latitude: '40.972319',
    longitude: '-74.636884',
    timeZoneId: 'America/New_York',
  },
  '07838': {
    zip: '07838',
    city: 'Great Meadows',
    state: 'NJ',
    latitude: '40.882833',
    longitude: '-74.929368',
    timeZoneId: 'America/New_York',
  },
  '07839': {
    zip: '07839',
    city: 'Greendell',
    state: 'NJ',
    latitude: '40.974687',
    longitude: '-74.820705',
    timeZoneId: 'America/New_York',
  },
  '07840': {
    zip: '07840',
    city: 'Hackettstown',
    state: 'NJ',
    latitude: '40.855816',
    longitude: '-74.844411',
    timeZoneId: 'America/New_York',
  },
  '07842': {
    zip: '07842',
    city: 'Hibernia',
    state: 'NJ',
    latitude: '40.945607',
    longitude: '-74.494819',
    timeZoneId: 'America/New_York',
  },
  '07843': {
    zip: '07843',
    city: 'Hopatcong',
    state: 'NJ',
    latitude: '40.94132',
    longitude: '-74.668755',
    timeZoneId: 'America/New_York',
  },
  '07844': {
    zip: '07844',
    city: 'Hope',
    state: 'NJ',
    latitude: '40.911267',
    longitude: '-74.966051',
    timeZoneId: 'America/New_York',
  },
  '07845': {
    zip: '07845',
    city: 'Ironia',
    state: 'NJ',
    latitude: '40.830131',
    longitude: '-74.626833',
    timeZoneId: 'America/New_York',
  },
  '07846': {
    zip: '07846',
    city: 'Johnsonburg',
    state: 'NJ',
    latitude: '40.966061',
    longitude: '-74.874409',
    timeZoneId: 'America/New_York',
  },
  '07847': {
    zip: '07847',
    city: 'Kenvil',
    state: 'NJ',
    latitude: '40.887908',
    longitude: '-74.626161',
    timeZoneId: 'America/New_York',
  },
  '07848': {
    zip: '07848',
    city: 'Lafayette',
    state: 'NJ',
    latitude: '41.094554',
    longitude: '-74.696522',
    timeZoneId: 'America/New_York',
  },
  '07849': {
    zip: '07849',
    city: 'Lake Hopatcong',
    state: 'NJ',
    latitude: '40.954339',
    longitude: '-74.614031',
    timeZoneId: 'America/New_York',
  },
  '07850': {
    zip: '07850',
    city: 'Landing',
    state: 'NJ',
    latitude: '40.905169',
    longitude: '-74.661651',
    timeZoneId: 'America/New_York',
  },
  '07851': {
    zip: '07851',
    city: 'Layton',
    state: 'NJ',
    latitude: '41.202354',
    longitude: '-74.835004',
    timeZoneId: 'America/New_York',
  },
  '07852': {
    zip: '07852',
    city: 'Ledgewood',
    state: 'NJ',
    latitude: '40.88382',
    longitude: '-74.655214',
    timeZoneId: 'America/New_York',
  },
  '07853': {
    zip: '07853',
    city: 'Long Valley',
    state: 'NJ',
    latitude: '40.795321',
    longitude: '-74.782913',
    timeZoneId: 'America/New_York',
  },
  '07855': {
    zip: '07855',
    city: 'Middleville',
    state: 'NJ',
    latitude: '41.054435',
    longitude: '-74.862719',
    timeZoneId: 'America/New_York',
  },
  '07856': {
    zip: '07856',
    city: 'Mount Arlington',
    state: 'NJ',
    latitude: '40.928208',
    longitude: '-74.636027',
    timeZoneId: 'America/New_York',
  },
  '07857': {
    zip: '07857',
    city: 'Netcong',
    state: 'NJ',
    latitude: '40.898789',
    longitude: '-74.705094',
    timeZoneId: 'America/New_York',
  },
  '07860': {
    zip: '07860',
    city: 'Newton',
    state: 'NJ',
    latitude: '41.036057',
    longitude: '-74.76455',
    timeZoneId: 'America/New_York',
  },
  '07863': {
    zip: '07863',
    city: 'Oxford',
    state: 'NJ',
    latitude: '40.81432',
    longitude: '-74.96867',
    timeZoneId: 'America/New_York',
  },
  '07865': {
    zip: '07865',
    city: 'Port Murray',
    state: 'NJ',
    latitude: '40.78716',
    longitude: '-74.900483',
    timeZoneId: 'America/New_York',
  },
  '07866': {
    zip: '07866',
    city: 'Rockaway',
    state: 'NJ',
    latitude: '40.943479',
    longitude: '-74.478766',
    timeZoneId: 'America/New_York',
  },
  '07869': {
    zip: '07869',
    city: 'Randolph',
    state: 'NJ',
    latitude: '40.844283',
    longitude: '-74.574152',
    timeZoneId: 'America/New_York',
  },
  '07870': {
    zip: '07870',
    city: 'Schooleys Mountain',
    state: 'NJ',
    latitude: '40.820164',
    longitude: '-74.761778',
    timeZoneId: 'America/New_York',
  },
  '07871': {
    zip: '07871',
    city: 'Sparta',
    state: 'NJ',
    latitude: '41.039507',
    longitude: '-74.623781',
    timeZoneId: 'America/New_York',
  },
  '07874': {
    zip: '07874',
    city: 'Stanhope',
    state: 'NJ',
    latitude: '40.920415',
    longitude: '-74.699194',
    timeZoneId: 'America/New_York',
  },
  '07875': {
    zip: '07875',
    city: 'Stillwater',
    state: 'NJ',
    latitude: '41.035937',
    longitude: '-74.879943',
    timeZoneId: 'America/New_York',
  },
  '07876': {
    zip: '07876',
    city: 'Succasunna',
    state: 'NJ',
    latitude: '40.85842',
    longitude: '-74.651368',
    timeZoneId: 'America/New_York',
  },
  '07877': {
    zip: '07877',
    city: 'Swartswood',
    state: 'NJ',
    latitude: '41.087231',
    longitude: '-74.829003',
    timeZoneId: 'America/New_York',
  },
  '07878': {
    zip: '07878',
    city: 'Mount Tabor',
    state: 'NJ',
    latitude: '40.874494',
    longitude: '-74.477334',
    timeZoneId: 'America/New_York',
  },
  '07879': {
    zip: '07879',
    city: 'Tranquility',
    state: 'NJ',
    latitude: '40.957129',
    longitude: '-74.807196',
    timeZoneId: 'America/New_York',
  },
  '07880': {
    zip: '07880',
    city: 'Vienna',
    state: 'NJ',
    latitude: '40.874466',
    longitude: '-74.89193',
    timeZoneId: 'America/New_York',
  },
  '07881': {
    zip: '07881',
    city: 'Wallpack Center',
    state: 'NJ',
    latitude: '41.204221',
    longitude: '-74.819332',
    timeZoneId: 'America/New_York',
  },
  '07882': {
    zip: '07882',
    city: 'Washington',
    state: 'NJ',
    latitude: '40.759714',
    longitude: '-75.008161',
    timeZoneId: 'America/New_York',
  },
  '07885': {
    zip: '07885',
    city: 'Wharton',
    state: 'NJ',
    latitude: '40.91523',
    longitude: '-74.602507',
    timeZoneId: 'America/New_York',
  },
  '07890': {
    zip: '07890',
    city: 'Branchville',
    state: 'NJ',
    latitude: '41.144891',
    longitude: '-74.752093',
    timeZoneId: 'America/New_York',
  },
  '07901': {
    zip: '07901',
    city: 'Summit',
    state: 'NJ',
    latitude: '40.714694',
    longitude: '-74.36293',
    timeZoneId: 'America/New_York',
  },
  '07902': {
    zip: '07902',
    city: 'Summit',
    state: 'NJ',
    latitude: '40.71414',
    longitude: '-74.365895',
    timeZoneId: 'America/New_York',
  },
  '07920': {
    zip: '07920',
    city: 'Basking Ridge',
    state: 'NJ',
    latitude: '40.685358',
    longitude: '-74.571598',
    timeZoneId: 'America/New_York',
  },
  '07921': {
    zip: '07921',
    city: 'Bedminster',
    state: 'NJ',
    latitude: '40.656519',
    longitude: '-74.654789',
    timeZoneId: 'America/New_York',
  },
  '07922': {
    zip: '07922',
    city: 'Berkeley Heights',
    state: 'NJ',
    latitude: '40.678984',
    longitude: '-74.432692',
    timeZoneId: 'America/New_York',
  },
  '07924': {
    zip: '07924',
    city: 'Bernardsville',
    state: 'NJ',
    latitude: '40.723076',
    longitude: '-74.588522',
    timeZoneId: 'America/New_York',
  },
  '07926': {
    zip: '07926',
    city: 'Brookside',
    state: 'NJ',
    latitude: '40.799951',
    longitude: '-74.571628',
    timeZoneId: 'America/New_York',
  },
  '07927': {
    zip: '07927',
    city: 'Cedar Knolls',
    state: 'NJ',
    latitude: '40.821502',
    longitude: '-74.454637',
    timeZoneId: 'America/New_York',
  },
  '07928': {
    zip: '07928',
    city: 'Chatham',
    state: 'NJ',
    latitude: '40.733044',
    longitude: '-74.403459',
    timeZoneId: 'America/New_York',
  },
  '07930': {
    zip: '07930',
    city: 'Chester',
    state: 'NJ',
    latitude: '40.791787',
    longitude: '-74.674518',
    timeZoneId: 'America/New_York',
  },
  '07931': {
    zip: '07931',
    city: 'Far Hills',
    state: 'NJ',
    latitude: '40.70171',
    longitude: '-74.64161',
    timeZoneId: 'America/New_York',
  },
  '07932': {
    zip: '07932',
    city: 'Florham Park',
    state: 'NJ',
    latitude: '40.775255',
    longitude: '-74.393299',
    timeZoneId: 'America/New_York',
  },
  '07933': {
    zip: '07933',
    city: 'Gillette',
    state: 'NJ',
    latitude: '40.709787',
    longitude: '-74.46855',
    timeZoneId: 'America/New_York',
  },
  '07934': {
    zip: '07934',
    city: 'Gladstone',
    state: 'NJ',
    latitude: '40.720951',
    longitude: '-74.669067',
    timeZoneId: 'America/New_York',
  },
  '07935': {
    zip: '07935',
    city: 'Green Village',
    state: 'NJ',
    latitude: '40.740504',
    longitude: '-74.44825',
    timeZoneId: 'America/New_York',
  },
  '07936': {
    zip: '07936',
    city: 'East Hanover',
    state: 'NJ',
    latitude: '40.818071',
    longitude: '-74.36387',
    timeZoneId: 'America/New_York',
  },
  '07938': {
    zip: '07938',
    city: 'Liberty Corner',
    state: 'NJ',
    latitude: '40.6647',
    longitude: '-74.57772',
    timeZoneId: 'America/New_York',
  },
  '07939': {
    zip: '07939',
    city: 'Lyons',
    state: 'NJ',
    latitude: '40.679559',
    longitude: '-74.567323',
    timeZoneId: 'America/New_York',
  },
  '07940': {
    zip: '07940',
    city: 'Madison',
    state: 'NJ',
    latitude: '40.755677',
    longitude: '-74.418689',
    timeZoneId: 'America/New_York',
  },
  '07945': {
    zip: '07945',
    city: 'Mendham',
    state: 'NJ',
    latitude: '40.782239',
    longitude: '-74.596638',
    timeZoneId: 'America/New_York',
  },
  '07946': {
    zip: '07946',
    city: 'Millington',
    state: 'NJ',
    latitude: '40.685934',
    longitude: '-74.514584',
    timeZoneId: 'America/New_York',
  },
  '07950': {
    zip: '07950',
    city: 'Morris Plains',
    state: 'NJ',
    latitude: '40.844846',
    longitude: '-74.476815',
    timeZoneId: 'America/New_York',
  },
  '07960': {
    zip: '07960',
    city: 'Morristown',
    state: 'NJ',
    latitude: '40.79368',
    longitude: '-74.491343',
    timeZoneId: 'America/New_York',
  },
  '07961': {
    zip: '07961',
    city: 'Convent Station',
    state: 'NJ',
    latitude: '40.796667',
    longitude: '-74.481944',
    timeZoneId: 'America/New_York',
  },
  '07962': {
    zip: '07962',
    city: 'Morristown',
    state: 'NJ',
    latitude: '40.806402',
    longitude: '-74.500611',
    timeZoneId: 'America/New_York',
  },
  '07963': {
    zip: '07963',
    city: 'Morristown',
    state: 'NJ',
    latitude: '40.795474',
    longitude: '-74.477752',
    timeZoneId: 'America/New_York',
  },
  '07970': {
    zip: '07970',
    city: 'Mount Freedom',
    state: 'NJ',
    latitude: '40.81913',
    longitude: '-74.57393',
    timeZoneId: 'America/New_York',
  },
  '07974': {
    zip: '07974',
    city: 'New Providence',
    state: 'NJ',
    latitude: '40.700446',
    longitude: '-74.405937',
    timeZoneId: 'America/New_York',
  },
  '07976': {
    zip: '07976',
    city: 'New Vernon',
    state: 'NJ',
    latitude: '40.731772',
    longitude: '-74.480745',
    timeZoneId: 'America/New_York',
  },
  '07977': {
    zip: '07977',
    city: 'Peapack',
    state: 'NJ',
    latitude: '40.711589',
    longitude: '-74.659396',
    timeZoneId: 'America/New_York',
  },
  '07978': {
    zip: '07978',
    city: 'Pluckemin',
    state: 'NJ',
    latitude: '40.644091',
    longitude: '-74.635527',
    timeZoneId: 'America/New_York',
  },
  '07979': {
    zip: '07979',
    city: 'Pottersville',
    state: 'NJ',
    latitude: '40.713533',
    longitude: '-74.712032',
    timeZoneId: 'America/New_York',
  },
  '07980': {
    zip: '07980',
    city: 'Stirling',
    state: 'NJ',
    latitude: '40.682373',
    longitude: '-74.491575',
    timeZoneId: 'America/New_York',
  },
  '07981': {
    zip: '07981',
    city: 'Whippany',
    state: 'NJ',
    latitude: '40.822442',
    longitude: '-74.420353',
    timeZoneId: 'America/New_York',
  },
  '07999': {
    zip: '07999',
    city: 'Whippany',
    state: 'NJ',
    latitude: '40.821396',
    longitude: '-74.439545',
    timeZoneId: 'America/New_York',
  },
  '08001': {
    zip: '08001',
    city: 'Alloway',
    state: 'NJ',
    latitude: '39.562902',
    longitude: '-75.349456',
    timeZoneId: 'America/New_York',
  },
  '08002': {
    zip: '08002',
    city: 'Cherry Hill',
    state: 'NJ',
    latitude: '39.933589',
    longitude: '-75.018781',
    timeZoneId: 'America/New_York',
  },
  '08003': {
    zip: '08003',
    city: 'Cherry Hill',
    state: 'NJ',
    latitude: '39.881958',
    longitude: '-74.970489',
    timeZoneId: 'America/New_York',
  },
  '08004': {
    zip: '08004',
    city: 'Atco',
    state: 'NJ',
    latitude: '39.77634',
    longitude: '-74.87064',
    timeZoneId: 'America/New_York',
  },
  '08005': {
    zip: '08005',
    city: 'Barnegat',
    state: 'NJ',
    latitude: '39.75888',
    longitude: '-74.28348',
    timeZoneId: 'America/New_York',
  },
  '08006': {
    zip: '08006',
    city: 'Barnegat Light',
    state: 'NJ',
    latitude: '39.7575',
    longitude: '-74.106667',
    timeZoneId: 'America/New_York',
  },
  '08007': {
    zip: '08007',
    city: 'Barrington',
    state: 'NJ',
    latitude: '39.866321',
    longitude: '-75.054278',
    timeZoneId: 'America/New_York',
  },
  '08008': {
    zip: '08008',
    city: 'Beach Haven',
    state: 'NJ',
    latitude: '39.6468',
    longitude: '-74.18586',
    timeZoneId: 'America/New_York',
  },
  '08009': {
    zip: '08009',
    city: 'Berlin',
    state: 'NJ',
    latitude: '39.75942',
    longitude: '-74.92734',
    timeZoneId: 'America/New_York',
  },
  '08010': {
    zip: '08010',
    city: 'Beverly',
    state: 'NJ',
    latitude: '40.058892',
    longitude: '-74.910433',
    timeZoneId: 'America/New_York',
  },
  '08011': {
    zip: '08011',
    city: 'Birmingham',
    state: 'NJ',
    latitude: '39.973028',
    longitude: '-74.713019',
    timeZoneId: 'America/New_York',
  },
  '08012': {
    zip: '08012',
    city: 'Blackwood',
    state: 'NJ',
    latitude: '39.74748',
    longitude: '-75.02232',
    timeZoneId: 'America/New_York',
  },
  '08014': {
    zip: '08014',
    city: 'Bridgeport',
    state: 'NJ',
    latitude: '39.807532',
    longitude: '-75.343044',
    timeZoneId: 'America/New_York',
  },
  '08015': {
    zip: '08015',
    city: 'Browns Mills',
    state: 'NJ',
    latitude: '39.963656',
    longitude: '-74.567804',
    timeZoneId: 'America/New_York',
  },
  '08016': {
    zip: '08016',
    city: 'Burlington',
    state: 'NJ',
    latitude: '40.07026',
    longitude: '-74.841534',
    timeZoneId: 'America/New_York',
  },
  '08018': {
    zip: '08018',
    city: 'Cedar Brook',
    state: 'NJ',
    latitude: '39.710736',
    longitude: '-74.901297',
    timeZoneId: 'America/New_York',
  },
  '08019': {
    zip: '08019',
    city: 'Chatsworth',
    state: 'NJ',
    latitude: '39.769693',
    longitude: '-74.448835',
    timeZoneId: 'America/New_York',
  },
  '08020': {
    zip: '08020',
    city: 'Clarksboro',
    state: 'NJ',
    latitude: '39.804769',
    longitude: '-75.215002',
    timeZoneId: 'America/New_York',
  },
  '08021': {
    zip: '08021',
    city: 'Clementon',
    state: 'NJ',
    latitude: '39.809815',
    longitude: '-75.001119',
    timeZoneId: 'America/New_York',
  },
  '08022': {
    zip: '08022',
    city: 'Columbus',
    state: 'NJ',
    latitude: '40.077182',
    longitude: '-74.691452',
    timeZoneId: 'America/New_York',
  },
  '08023': {
    zip: '08023',
    city: 'Deepwater',
    state: 'NJ',
    latitude: '39.685875',
    longitude: '-75.49096',
    timeZoneId: 'America/New_York',
  },
  '08025': {
    zip: '08025',
    city: 'Ewan',
    state: 'NJ',
    latitude: '39.697566',
    longitude: '-75.182739',
    timeZoneId: 'America/New_York',
  },
  '08026': {
    zip: '08026',
    city: 'Gibbsboro',
    state: 'NJ',
    latitude: '39.83366',
    longitude: '-74.966747',
    timeZoneId: 'America/New_York',
  },
  '08027': {
    zip: '08027',
    city: 'Gibbstown',
    state: 'NJ',
    latitude: '39.826648',
    longitude: '-75.273257',
    timeZoneId: 'America/New_York',
  },
  '08028': {
    zip: '08028',
    city: 'Glassboro',
    state: 'NJ',
    latitude: '39.694694',
    longitude: '-75.121217',
    timeZoneId: 'America/New_York',
  },
  '08029': {
    zip: '08029',
    city: 'Glendora',
    state: 'NJ',
    latitude: '39.839674',
    longitude: '-75.06812',
    timeZoneId: 'America/New_York',
  },
  '08030': {
    zip: '08030',
    city: 'Gloucester City',
    state: 'NJ',
    latitude: '39.887704',
    longitude: '-75.11568',
    timeZoneId: 'America/New_York',
  },
  '08031': {
    zip: '08031',
    city: 'Bellmawr',
    state: 'NJ',
    latitude: '39.867047',
    longitude: '-75.089079',
    timeZoneId: 'America/New_York',
  },
  '08032': {
    zip: '08032',
    city: 'Grenloch',
    state: 'NJ',
    latitude: '39.780413',
    longitude: '-75.05917',
    timeZoneId: 'America/New_York',
  },
  '08033': {
    zip: '08033',
    city: 'Haddonfield',
    state: 'NJ',
    latitude: '39.895208',
    longitude: '-75.039366',
    timeZoneId: 'America/New_York',
  },
  '08034': {
    zip: '08034',
    city: 'Cherry Hill',
    state: 'NJ',
    latitude: '39.908086',
    longitude: '-75.004604',
    timeZoneId: 'America/New_York',
  },
  '08035': {
    zip: '08035',
    city: 'Haddon Heights',
    state: 'NJ',
    latitude: '39.877788',
    longitude: '-75.066565',
    timeZoneId: 'America/New_York',
  },
  '08036': {
    zip: '08036',
    city: 'Hainesport',
    state: 'NJ',
    latitude: '39.984745',
    longitude: '-74.830958',
    timeZoneId: 'America/New_York',
  },
  '08037': {
    zip: '08037',
    city: 'Hammonton',
    state: 'NJ',
    latitude: '39.631485',
    longitude: '-74.771034',
    timeZoneId: 'America/New_York',
  },
  '08038': {
    zip: '08038',
    city: 'Hancocks Bridge',
    state: 'NJ',
    latitude: '39.503201',
    longitude: '-75.461092',
    timeZoneId: 'America/New_York',
  },
  '08039': {
    zip: '08039',
    city: 'Harrisonville',
    state: 'NJ',
    latitude: '39.685686',
    longitude: '-75.265322',
    timeZoneId: 'America/New_York',
  },
  '08041': {
    zip: '08041',
    city: 'Jobstown',
    state: 'NJ',
    latitude: '40.024583',
    longitude: '-74.686183',
    timeZoneId: 'America/New_York',
  },
  '08042': {
    zip: '08042',
    city: 'Juliustown',
    state: 'NJ',
    latitude: '40.013114',
    longitude: '-74.670629',
    timeZoneId: 'America/New_York',
  },
  '08043': {
    zip: '08043',
    city: 'Voorhees',
    state: 'NJ',
    latitude: '39.84652',
    longitude: '-74.960981',
    timeZoneId: 'America/New_York',
  },
  '08045': {
    zip: '08045',
    city: 'Lawnside',
    state: 'NJ',
    latitude: '39.866011',
    longitude: '-75.034254',
    timeZoneId: 'America/New_York',
  },
  '08046': {
    zip: '08046',
    city: 'Willingboro',
    state: 'NJ',
    latitude: '40.030946',
    longitude: '-74.880877',
    timeZoneId: 'America/New_York',
  },
  '08048': {
    zip: '08048',
    city: 'Lumberton',
    state: 'NJ',
    latitude: '39.956414',
    longitude: '-74.804349',
    timeZoneId: 'America/New_York',
  },
  '08049': {
    zip: '08049',
    city: 'Magnolia',
    state: 'NJ',
    latitude: '39.852844',
    longitude: '-75.03873',
    timeZoneId: 'America/New_York',
  },
  '08050': {
    zip: '08050',
    city: 'Manahawkin',
    state: 'NJ',
    latitude: '39.69872',
    longitude: '-74.257382',
    timeZoneId: 'America/New_York',
  },
  '08051': {
    zip: '08051',
    city: 'Mantua',
    state: 'NJ',
    latitude: '39.78798',
    longitude: '-75.181295',
    timeZoneId: 'America/New_York',
  },
  '08052': {
    zip: '08052',
    city: 'Maple Shade',
    state: 'NJ',
    latitude: '39.954582',
    longitude: '-74.996843',
    timeZoneId: 'America/New_York',
  },
  '08053': {
    zip: '08053',
    city: 'Marlton',
    state: 'NJ',
    latitude: '39.888912',
    longitude: '-74.911599',
    timeZoneId: 'America/New_York',
  },
  '08054': {
    zip: '08054',
    city: 'Mount Laurel',
    state: 'NJ',
    latitude: '39.946438',
    longitude: '-74.915955',
    timeZoneId: 'America/New_York',
  },
  '08055': {
    zip: '08055',
    city: 'Medford',
    state: 'NJ',
    latitude: '39.862774',
    longitude: '-74.815854',
    timeZoneId: 'America/New_York',
  },
  '08056': {
    zip: '08056',
    city: 'Mickleton',
    state: 'NJ',
    latitude: '39.785684',
    longitude: '-75.25002',
    timeZoneId: 'America/New_York',
  },
  '08057': {
    zip: '08057',
    city: 'Moorestown',
    state: 'NJ',
    latitude: '39.962222',
    longitude: '-74.982778',
    timeZoneId: 'America/New_York',
  },
  '08059': {
    zip: '08059',
    city: 'Mount Ephraim',
    state: 'NJ',
    latitude: '39.879419',
    longitude: '-75.092601',
    timeZoneId: 'America/New_York',
  },
  '08060': {
    zip: '08060',
    city: 'Mount Holly',
    state: 'NJ',
    latitude: '39.991815',
    longitude: '-74.807251',
    timeZoneId: 'America/New_York',
  },
  '08061': {
    zip: '08061',
    city: 'Mount Royal',
    state: 'NJ',
    latitude: '39.803964',
    longitude: '-75.203019',
    timeZoneId: 'America/New_York',
  },
  '08062': {
    zip: '08062',
    city: 'Mullica Hill',
    state: 'NJ',
    latitude: '39.722021',
    longitude: '-75.221337',
    timeZoneId: 'America/New_York',
  },
  '08063': {
    zip: '08063',
    city: 'National Park',
    state: 'NJ',
    latitude: '39.862622',
    longitude: '-75.176008',
    timeZoneId: 'America/New_York',
  },
  '08064': {
    zip: '08064',
    city: 'New Lisbon',
    state: 'NJ',
    latitude: '39.956127',
    longitude: '-74.628458',
    timeZoneId: 'America/New_York',
  },
  '08065': {
    zip: '08065',
    city: 'Palmyra',
    state: 'NJ',
    latitude: '40.004611',
    longitude: '-75.012134',
    timeZoneId: 'America/New_York',
  },
  '08066': {
    zip: '08066',
    city: 'Paulsboro',
    state: 'NJ',
    latitude: '39.82592',
    longitude: '-75.212157',
    timeZoneId: 'America/New_York',
  },
  '08067': {
    zip: '08067',
    city: 'Pedricktown',
    state: 'NJ',
    latitude: '39.740441',
    longitude: '-75.406456',
    timeZoneId: 'America/New_York',
  },
  '08068': {
    zip: '08068',
    city: 'Pemberton',
    state: 'NJ',
    latitude: '39.96257',
    longitude: '-74.681969',
    timeZoneId: 'America/New_York',
  },
  '08069': {
    zip: '08069',
    city: 'Penns Grove',
    state: 'NJ',
    latitude: '39.70704',
    longitude: '-75.4467',
    timeZoneId: 'America/New_York',
  },
  '08070': {
    zip: '08070',
    city: 'Pennsville',
    state: 'NJ',
    latitude: '39.646661',
    longitude: '-75.517532',
    timeZoneId: 'America/New_York',
  },
  '08071': {
    zip: '08071',
    city: 'Pitman',
    state: 'NJ',
    latitude: '39.728778',
    longitude: '-75.128308',
    timeZoneId: 'America/New_York',
  },
  '08072': {
    zip: '08072',
    city: 'Quinton',
    state: 'NJ',
    latitude: '39.556788',
    longitude: '-75.404719',
    timeZoneId: 'America/New_York',
  },
  '08073': {
    zip: '08073',
    city: 'Rancocas',
    state: 'NJ',
    latitude: '40.009777',
    longitude: '-74.869986',
    timeZoneId: 'America/New_York',
  },
  '08074': {
    zip: '08074',
    city: 'Richwood',
    state: 'NJ',
    latitude: '39.722829',
    longitude: '-75.164757',
    timeZoneId: 'America/New_York',
  },
  '08075': {
    zip: '08075',
    city: 'Riverside',
    state: 'NJ',
    latitude: '40.021944',
    longitude: '-74.932778',
    timeZoneId: 'America/New_York',
  },
  '08076': {
    zip: '08076',
    city: 'Riverton',
    state: 'NJ',
    latitude: '40.01068',
    longitude: '-75.015535',
    timeZoneId: 'America/New_York',
  },
  '08077': {
    zip: '08077',
    city: 'Riverton',
    state: 'NJ',
    latitude: '40.008345',
    longitude: '-75.010068',
    timeZoneId: 'America/New_York',
  },
  '08078': {
    zip: '08078',
    city: 'Runnemede',
    state: 'NJ',
    latitude: '39.848224',
    longitude: '-75.074135',
    timeZoneId: 'America/New_York',
  },
  '08079': {
    zip: '08079',
    city: 'Salem',
    state: 'NJ',
    latitude: '39.548542',
    longitude: '-75.452329',
    timeZoneId: 'America/New_York',
  },
  '08080': {
    zip: '08080',
    city: 'Sewell',
    state: 'NJ',
    latitude: '39.74934',
    longitude: '-75.09666',
    timeZoneId: 'America/New_York',
  },
  '08081': {
    zip: '08081',
    city: 'Sicklerville',
    state: 'NJ',
    latitude: '39.773333',
    longitude: '-75.006667',
    timeZoneId: 'America/New_York',
  },
  '08083': {
    zip: '08083',
    city: 'Somerdale',
    state: 'NJ',
    latitude: '39.843005',
    longitude: '-75.031535',
    timeZoneId: 'America/New_York',
  },
  '08084': {
    zip: '08084',
    city: 'Stratford',
    state: 'NJ',
    latitude: '39.832123',
    longitude: '-75.015935',
    timeZoneId: 'America/New_York',
  },
  '08085': {
    zip: '08085',
    city: 'Swedesboro',
    state: 'NJ',
    latitude: '39.753559',
    longitude: '-75.32163',
    timeZoneId: 'America/New_York',
  },
  '08086': {
    zip: '08086',
    city: 'Thorofare',
    state: 'NJ',
    latitude: '39.836371',
    longitude: '-75.181675',
    timeZoneId: 'America/New_York',
  },
  '08087': {
    zip: '08087',
    city: 'Tuckerton',
    state: 'NJ',
    latitude: '39.589796',
    longitude: '-74.356472',
    timeZoneId: 'America/New_York',
  },
  '08088': {
    zip: '08088',
    city: 'Vincentown',
    state: 'NJ',
    latitude: '39.820757',
    longitude: '-74.694484',
    timeZoneId: 'America/New_York',
  },
  '08089': {
    zip: '08089',
    city: 'Waterford Works',
    state: 'NJ',
    latitude: '39.72258',
    longitude: '-74.859',
    timeZoneId: 'America/New_York',
  },
  '08090': {
    zip: '08090',
    city: 'Wenonah',
    state: 'NJ',
    latitude: '39.799114',
    longitude: '-75.150975',
    timeZoneId: 'America/New_York',
  },
  '08091': {
    zip: '08091',
    city: 'West Berlin',
    state: 'NJ',
    latitude: '39.815979',
    longitude: '-74.940718',
    timeZoneId: 'America/New_York',
  },
  '08092': {
    zip: '08092',
    city: 'West Creek',
    state: 'NJ',
    latitude: '39.65328',
    longitude: '-74.28072',
    timeZoneId: 'America/New_York',
  },
  '08093': {
    zip: '08093',
    city: 'Westville',
    state: 'NJ',
    latitude: '39.85974',
    longitude: '-75.14382',
    timeZoneId: 'America/New_York',
  },
  '08094': {
    zip: '08094',
    city: 'Williamstown',
    state: 'NJ',
    latitude: '39.64284',
    longitude: '-74.95554',
    timeZoneId: 'America/New_York',
  },
  '08095': {
    zip: '08095',
    city: 'Winslow',
    state: 'NJ',
    latitude: '39.657713',
    longitude: '-74.867102',
    timeZoneId: 'America/New_York',
  },
  '08096': {
    zip: '08096',
    city: 'Woodbury',
    state: 'NJ',
    latitude: '39.827998',
    longitude: '-75.134513',
    timeZoneId: 'America/New_York',
  },
  '08097': {
    zip: '08097',
    city: 'Woodbury Heights',
    state: 'NJ',
    latitude: '39.83124',
    longitude: '-75.15102',
    timeZoneId: 'America/New_York',
  },
  '08098': {
    zip: '08098',
    city: 'Woodstown',
    state: 'NJ',
    latitude: '39.651717',
    longitude: '-75.330001',
    timeZoneId: 'America/New_York',
  },
  '08099': {
    zip: '08099',
    city: 'Bellmawr',
    state: 'NJ',
    latitude: '39.868757',
    longitude: '-75.092255',
    timeZoneId: 'America/New_York',
  },
  '08101': {
    zip: '08101',
    city: 'Camden',
    state: 'NJ',
    latitude: '39.940902',
    longitude: '-75.126163',
    timeZoneId: 'America/New_York',
  },
  '08102': {
    zip: '08102',
    city: 'Camden',
    state: 'NJ',
    latitude: '39.945816',
    longitude: '-75.113529',
    timeZoneId: 'America/New_York',
  },
  '08103': {
    zip: '08103',
    city: 'Camden',
    state: 'NJ',
    latitude: '39.935552',
    longitude: '-75.114601',
    timeZoneId: 'America/New_York',
  },
  '08104': {
    zip: '08104',
    city: 'Camden',
    state: 'NJ',
    latitude: '39.919931',
    longitude: '-75.108157',
    timeZoneId: 'America/New_York',
  },
  '08105': {
    zip: '08105',
    city: 'Camden',
    state: 'NJ',
    latitude: '39.94867',
    longitude: '-75.087161',
    timeZoneId: 'America/New_York',
  },
  '08106': {
    zip: '08106',
    city: 'Audubon',
    state: 'NJ',
    latitude: '39.892401',
    longitude: '-75.074652',
    timeZoneId: 'America/New_York',
  },
  '08107': {
    zip: '08107',
    city: 'Oaklyn',
    state: 'NJ',
    latitude: '39.908406',
    longitude: '-75.085817',
    timeZoneId: 'America/New_York',
  },
  '08108': {
    zip: '08108',
    city: 'Collingswood',
    state: 'NJ',
    latitude: '39.913768',
    longitude: '-75.065877',
    timeZoneId: 'America/New_York',
  },
  '08109': {
    zip: '08109',
    city: 'Merchantville',
    state: 'NJ',
    latitude: '39.950935',
    longitude: '-75.052361',
    timeZoneId: 'America/New_York',
  },
  '08110': {
    zip: '08110',
    city: 'Pennsauken',
    state: 'NJ',
    latitude: '39.954445',
    longitude: '-75.074257',
    timeZoneId: 'America/New_York',
  },
  '08201': {
    zip: '08201',
    city: 'Absecon',
    state: 'NJ',
    latitude: '39.441925',
    longitude: '-74.512041',
    timeZoneId: 'America/New_York',
  },
  '08202': {
    zip: '08202',
    city: 'Avalon',
    state: 'NJ',
    latitude: '39.09279',
    longitude: '-74.73287',
    timeZoneId: 'America/New_York',
  },
  '08203': {
    zip: '08203',
    city: 'Brigantine',
    state: 'NJ',
    latitude: '39.400502',
    longitude: '-74.382819',
    timeZoneId: 'America/New_York',
  },
  '08204': {
    zip: '08204',
    city: 'Cape May',
    state: 'NJ',
    latitude: '38.97158',
    longitude: '-74.927691',
    timeZoneId: 'America/New_York',
  },
  '08205': {
    zip: '08205',
    city: 'Absecon',
    state: 'NJ',
    latitude: '39.420254',
    longitude: '-74.502031',
    timeZoneId: 'America/New_York',
  },
  '08210': {
    zip: '08210',
    city: 'Cape May Court House',
    state: 'NJ',
    latitude: '39.11928',
    longitude: '-74.82582',
    timeZoneId: 'America/New_York',
  },
  '08212': {
    zip: '08212',
    city: 'Cape May Point',
    state: 'NJ',
    latitude: '38.939854',
    longitude: '-74.963948',
    timeZoneId: 'America/New_York',
  },
  '08213': {
    zip: '08213',
    city: 'Cologne',
    state: 'NJ',
    latitude: '39.499211',
    longitude: '-74.608247',
    timeZoneId: 'America/New_York',
  },
  '08214': {
    zip: '08214',
    city: 'Dennisville',
    state: 'NJ',
    latitude: '39.191334',
    longitude: '-74.824104',
    timeZoneId: 'America/New_York',
  },
  '08215': {
    zip: '08215',
    city: 'Egg Harbor City',
    state: 'NJ',
    latitude: '39.52728',
    longitude: '-74.6067',
    timeZoneId: 'America/New_York',
  },
  '08217': {
    zip: '08217',
    city: 'Elwood',
    state: 'NJ',
    latitude: '39.578807',
    longitude: '-74.715895',
    timeZoneId: 'America/New_York',
  },
  '08218': {
    zip: '08218',
    city: 'Goshen',
    state: 'NJ',
    latitude: '39.143741',
    longitude: '-74.862001',
    timeZoneId: 'America/New_York',
  },
  '08219': {
    zip: '08219',
    city: 'Green Creek',
    state: 'NJ',
    latitude: '39.038214',
    longitude: '-74.897029',
    timeZoneId: 'America/New_York',
  },
  '08220': {
    zip: '08220',
    city: 'Leeds Point',
    state: 'NJ',
    latitude: '39.49915',
    longitude: '-74.432534',
    timeZoneId: 'America/New_York',
  },
  '08221': {
    zip: '08221',
    city: 'Linwood',
    state: 'NJ',
    latitude: '39.342937',
    longitude: '-74.580402',
    timeZoneId: 'America/New_York',
  },
  '08223': {
    zip: '08223',
    city: 'Marmora',
    state: 'NJ',
    latitude: '39.26292',
    longitude: '-74.66178',
    timeZoneId: 'America/New_York',
  },
  '08224': {
    zip: '08224',
    city: 'New Gretna',
    state: 'NJ',
    latitude: '39.605078',
    longitude: '-74.456484',
    timeZoneId: 'America/New_York',
  },
  '08225': {
    zip: '08225',
    city: 'Northfield',
    state: 'NJ',
    latitude: '39.368215',
    longitude: '-74.55757',
    timeZoneId: 'America/New_York',
  },
  '08226': {
    zip: '08226',
    city: 'Ocean City',
    state: 'NJ',
    latitude: '39.268442',
    longitude: '-74.59353',
    timeZoneId: 'America/New_York',
  },
  '08230': {
    zip: '08230',
    city: 'Ocean View',
    state: 'NJ',
    latitude: '39.21024',
    longitude: '-74.72808',
    timeZoneId: 'America/New_York',
  },
  '08231': {
    zip: '08231',
    city: 'Oceanville',
    state: 'NJ',
    latitude: '39.464796',
    longitude: '-74.460379',
    timeZoneId: 'America/New_York',
  },
  '08232': {
    zip: '08232',
    city: 'Pleasantville',
    state: 'NJ',
    latitude: '39.40764',
    longitude: '-74.5707',
    timeZoneId: 'America/New_York',
  },
  '08234': {
    zip: '08234',
    city: 'Egg Harbor Township',
    state: 'NJ',
    latitude: '39.41323',
    longitude: '-74.575699',
    timeZoneId: 'America/New_York',
  },
  '08240': {
    zip: '08240',
    city: 'Pomona',
    state: 'NJ',
    latitude: '39.479928',
    longitude: '-74.56541',
    timeZoneId: 'America/New_York',
  },
  '08241': {
    zip: '08241',
    city: 'Port Republic',
    state: 'NJ',
    latitude: '39.522108',
    longitude: '-74.511826',
    timeZoneId: 'America/New_York',
  },
  '08242': {
    zip: '08242',
    city: 'Rio Grande',
    state: 'NJ',
    latitude: '39.018436',
    longitude: '-74.869812',
    timeZoneId: 'America/New_York',
  },
  '08243': {
    zip: '08243',
    city: 'Sea Isle City',
    state: 'NJ',
    latitude: '39.15216',
    longitude: '-74.6937',
    timeZoneId: 'America/New_York',
  },
  '08244': {
    zip: '08244',
    city: 'Somers Point',
    state: 'NJ',
    latitude: '39.31976',
    longitude: '-74.600482',
    timeZoneId: 'America/New_York',
  },
  '08245': {
    zip: '08245',
    city: 'South Dennis',
    state: 'NJ',
    latitude: '39.170998',
    longitude: '-74.810448',
    timeZoneId: 'America/New_York',
  },
  '08246': {
    zip: '08246',
    city: 'South Seaville',
    state: 'NJ',
    latitude: '39.176868',
    longitude: '-74.761084',
    timeZoneId: 'America/New_York',
  },
  '08247': {
    zip: '08247',
    city: 'Stone Harbor',
    state: 'NJ',
    latitude: '39.05188',
    longitude: '-74.759867',
    timeZoneId: 'America/New_York',
  },
  '08248': {
    zip: '08248',
    city: 'Strathmere',
    state: 'NJ',
    latitude: '39.195556',
    longitude: '-74.659715',
    timeZoneId: 'America/New_York',
  },
  '08250': {
    zip: '08250',
    city: 'Tuckahoe',
    state: 'NJ',
    latitude: '39.290009',
    longitude: '-74.759054',
    timeZoneId: 'America/New_York',
  },
  '08251': {
    zip: '08251',
    city: 'Villas',
    state: 'NJ',
    latitude: '39.02322',
    longitude: '-74.9313',
    timeZoneId: 'America/New_York',
  },
  '08252': {
    zip: '08252',
    city: 'Whitesboro',
    state: 'NJ',
    latitude: '39.03699',
    longitude: '-74.863423',
    timeZoneId: 'America/New_York',
  },
  '08260': {
    zip: '08260',
    city: 'Wildwood',
    state: 'NJ',
    latitude: '38.986861',
    longitude: '-74.823351',
    timeZoneId: 'America/New_York',
  },
  '08270': {
    zip: '08270',
    city: 'Woodbine',
    state: 'NJ',
    latitude: '39.25554',
    longitude: '-74.80704',
    timeZoneId: 'America/New_York',
  },
  '08302': {
    zip: '08302',
    city: 'Bridgeton',
    state: 'NJ',
    latitude: '39.438881',
    longitude: '-75.228175',
    timeZoneId: 'America/New_York',
  },
  '08310': {
    zip: '08310',
    city: 'Buena',
    state: 'NJ',
    latitude: '39.527337',
    longitude: '-74.912575',
    timeZoneId: 'America/New_York',
  },
  '08311': {
    zip: '08311',
    city: 'Cedarville',
    state: 'NJ',
    latitude: '39.331341',
    longitude: '-75.202275',
    timeZoneId: 'America/New_York',
  },
  '08312': {
    zip: '08312',
    city: 'Clayton',
    state: 'NJ',
    latitude: '39.667629',
    longitude: '-75.084944',
    timeZoneId: 'America/New_York',
  },
  '08313': {
    zip: '08313',
    city: 'Deerfield Street',
    state: 'NJ',
    latitude: '39.530646',
    longitude: '-75.23101',
    timeZoneId: 'America/New_York',
  },
  '08314': {
    zip: '08314',
    city: 'Delmont',
    state: 'NJ',
    latitude: '39.207047',
    longitude: '-74.971309',
    timeZoneId: 'America/New_York',
  },
  '08315': {
    zip: '08315',
    city: 'Dividing Creek',
    state: 'NJ',
    latitude: '39.269693',
    longitude: '-75.10486',
    timeZoneId: 'America/New_York',
  },
  '08316': {
    zip: '08316',
    city: 'Dorchester',
    state: 'NJ',
    latitude: '39.271298',
    longitude: '-74.975752',
    timeZoneId: 'America/New_York',
  },
  '08317': {
    zip: '08317',
    city: 'Dorothy',
    state: 'NJ',
    latitude: '39.409419',
    longitude: '-74.820629',
    timeZoneId: 'America/New_York',
  },
  '08318': {
    zip: '08318',
    city: 'Elmer',
    state: 'NJ',
    latitude: '39.56862',
    longitude: '-75.18186',
    timeZoneId: 'America/New_York',
  },
  '08319': {
    zip: '08319',
    city: 'Estell Manor',
    state: 'NJ',
    latitude: '39.365517',
    longitude: '-74.797079',
    timeZoneId: 'America/New_York',
  },
  '08320': {
    zip: '08320',
    city: 'Fairton',
    state: 'NJ',
    latitude: '39.377963',
    longitude: '-75.224715',
    timeZoneId: 'America/New_York',
  },
  '08321': {
    zip: '08321',
    city: 'Fortescue',
    state: 'NJ',
    latitude: '39.239708',
    longitude: '-75.169233',
    timeZoneId: 'America/New_York',
  },
  '08322': {
    zip: '08322',
    city: 'Franklinville',
    state: 'NJ',
    latitude: '39.629479',
    longitude: '-75.046477',
    timeZoneId: 'America/New_York',
  },
  '08323': {
    zip: '08323',
    city: 'Greenwich',
    state: 'NJ',
    latitude: '39.390568',
    longitude: '-75.341496',
    timeZoneId: 'America/New_York',
  },
  '08324': {
    zip: '08324',
    city: 'Heislerville',
    state: 'NJ',
    latitude: '39.249636',
    longitude: '-74.994012',
    timeZoneId: 'America/New_York',
  },
  '08326': {
    zip: '08326',
    city: 'Landisville',
    state: 'NJ',
    latitude: '39.524575',
    longitude: '-74.936643',
    timeZoneId: 'America/New_York',
  },
  '08327': {
    zip: '08327',
    city: 'Leesburg',
    state: 'NJ',
    latitude: '39.256041',
    longitude: '-74.987716',
    timeZoneId: 'America/New_York',
  },
  '08328': {
    zip: '08328',
    city: 'Malaga',
    state: 'NJ',
    latitude: '39.57522',
    longitude: '-75.056455',
    timeZoneId: 'America/New_York',
  },
  '08329': {
    zip: '08329',
    city: 'Mauricetown',
    state: 'NJ',
    latitude: '39.286753',
    longitude: '-74.997363',
    timeZoneId: 'America/New_York',
  },
  '08330': {
    zip: '08330',
    city: 'Mays Landing',
    state: 'NJ',
    latitude: '39.44076',
    longitude: '-74.72898',
    timeZoneId: 'America/New_York',
  },
  '08332': {
    zip: '08332',
    city: 'Millville',
    state: 'NJ',
    latitude: '39.33024',
    longitude: '-75.02754',
    timeZoneId: 'America/New_York',
  },
  '08340': {
    zip: '08340',
    city: 'Milmay',
    state: 'NJ',
    latitude: '39.440969',
    longitude: '-74.862877',
    timeZoneId: 'America/New_York',
  },
  '08341': {
    zip: '08341',
    city: 'Minotola',
    state: 'NJ',
    latitude: '39.519078',
    longitude: '-74.950352',
    timeZoneId: 'America/New_York',
  },
  '08342': {
    zip: '08342',
    city: 'Mizpah',
    state: 'NJ',
    latitude: '39.488076',
    longitude: '-74.834314',
    timeZoneId: 'America/New_York',
  },
  '08343': {
    zip: '08343',
    city: 'Monroeville',
    state: 'NJ',
    latitude: '39.639113',
    longitude: '-75.16419',
    timeZoneId: 'America/New_York',
  },
  '08344': {
    zip: '08344',
    city: 'Newfield',
    state: 'NJ',
    latitude: '39.563197',
    longitude: '-74.980592',
    timeZoneId: 'America/New_York',
  },
  '08345': {
    zip: '08345',
    city: 'Newport',
    state: 'NJ',
    latitude: '39.288657',
    longitude: '-75.14913',
    timeZoneId: 'America/New_York',
  },
  '08346': {
    zip: '08346',
    city: 'Newtonville',
    state: 'NJ',
    latitude: '39.565187',
    longitude: '-74.857657',
    timeZoneId: 'America/New_York',
  },
  '08347': {
    zip: '08347',
    city: 'Norma',
    state: 'NJ',
    latitude: '39.493778',
    longitude: '-75.087785',
    timeZoneId: 'America/New_York',
  },
  '08348': {
    zip: '08348',
    city: 'Port Elizabeth',
    state: 'NJ',
    latitude: '39.233423',
    longitude: '-75.01039',
    timeZoneId: 'America/New_York',
  },
  '08349': {
    zip: '08349',
    city: 'Port Norris',
    state: 'NJ',
    latitude: '39.25812',
    longitude: '-75.05952',
    timeZoneId: 'America/New_York',
  },
  '08350': {
    zip: '08350',
    city: 'Richland',
    state: 'NJ',
    latitude: '39.490764',
    longitude: '-74.881538',
    timeZoneId: 'America/New_York',
  },
  '08352': {
    zip: '08352',
    city: 'Rosenhayn',
    state: 'NJ',
    latitude: '39.478761',
    longitude: '-75.130541',
    timeZoneId: 'America/New_York',
  },
  '08353': {
    zip: '08353',
    city: 'Shiloh',
    state: 'NJ',
    latitude: '39.454802',
    longitude: '-75.297076',
    timeZoneId: 'America/New_York',
  },
  '08360': {
    zip: '08360',
    city: 'Vineland',
    state: 'NJ',
    latitude: '39.4809',
    longitude: '-75.010192',
    timeZoneId: 'America/New_York',
  },
  '08361': {
    zip: '08361',
    city: 'Vineland',
    state: 'NJ',
    latitude: '39.482485',
    longitude: '-75.023974',
    timeZoneId: 'America/New_York',
  },
  '08362': {
    zip: '08362',
    city: 'Vineland',
    state: 'NJ',
    latitude: '39.465559',
    longitude: '-74.9992',
    timeZoneId: 'America/New_York',
  },
  '08401': {
    zip: '08401',
    city: 'Atlantic City',
    state: 'NJ',
    latitude: '39.362143',
    longitude: '-74.437879',
    timeZoneId: 'America/New_York',
  },
  '08402': {
    zip: '08402',
    city: 'Margate City',
    state: 'NJ',
    latitude: '39.330254',
    longitude: '-74.50623',
    timeZoneId: 'America/New_York',
  },
  '08403': {
    zip: '08403',
    city: 'Longport',
    state: 'NJ',
    latitude: '39.315216',
    longitude: '-74.537153',
    timeZoneId: 'America/New_York',
  },
  '08404': {
    zip: '08404',
    city: 'Atlantic City',
    state: 'NJ',
    latitude: '39.362648',
    longitude: '-74.425943',
    timeZoneId: 'America/New_York',
  },
  '08405': {
    zip: '08405',
    city: 'Atlantic City',
    state: 'NJ',
    latitude: '39.366054',
    longitude: '-74.422413',
    timeZoneId: 'America/New_York',
  },
  '08406': {
    zip: '08406',
    city: 'Ventnor City',
    state: 'NJ',
    latitude: '39.342361',
    longitude: '-74.481532',
    timeZoneId: 'America/New_York',
  },
  '08501': {
    zip: '08501',
    city: 'Allentown',
    state: 'NJ',
    latitude: '40.13406',
    longitude: '-74.59734',
    timeZoneId: 'America/New_York',
  },
  '08502': {
    zip: '08502',
    city: 'Belle Mead',
    state: 'NJ',
    latitude: '40.464764',
    longitude: '-74.630308',
    timeZoneId: 'America/New_York',
  },
  '08504': {
    zip: '08504',
    city: 'Blawenburg',
    state: 'NJ',
    latitude: '40.40104',
    longitude: '-74.704738',
    timeZoneId: 'America/New_York',
  },
  '08505': {
    zip: '08505',
    city: 'Bordentown',
    state: 'NJ',
    latitude: '40.12842',
    longitude: '-74.71686',
    timeZoneId: 'America/New_York',
  },
  '08510': {
    zip: '08510',
    city: 'Millstone Township',
    state: 'NJ',
    latitude: '40.1898',
    longitude: '-74.4414',
    timeZoneId: 'America/New_York',
  },
  '08511': {
    zip: '08511',
    city: 'Cookstown',
    state: 'NJ',
    latitude: '40.043406',
    longitude: '-74.553498',
    timeZoneId: 'America/New_York',
  },
  '08512': {
    zip: '08512',
    city: 'Cranbury',
    state: 'NJ',
    latitude: '40.31118',
    longitude: '-74.52516',
    timeZoneId: 'America/New_York',
  },
  '08514': {
    zip: '08514',
    city: 'Cream Ridge',
    state: 'NJ',
    latitude: '40.164531',
    longitude: '-74.453412',
    timeZoneId: 'America/New_York',
  },
  '08515': {
    zip: '08515',
    city: 'Chesterfield',
    state: 'NJ',
    latitude: '40.153936',
    longitude: '-74.65033',
    timeZoneId: 'America/New_York',
  },
  '08518': {
    zip: '08518',
    city: 'Florence',
    state: 'NJ',
    latitude: '40.113396',
    longitude: '-74.806842',
    timeZoneId: 'America/New_York',
  },
  '08520': {
    zip: '08520',
    city: 'Hightstown',
    state: 'NJ',
    latitude: '40.25508',
    longitude: '-74.51568',
    timeZoneId: 'America/New_York',
  },
  '08525': {
    zip: '08525',
    city: 'Hopewell',
    state: 'NJ',
    latitude: '40.386966',
    longitude: '-74.765926',
    timeZoneId: 'America/New_York',
  },
  '08526': {
    zip: '08526',
    city: 'Imlaystown',
    state: 'NJ',
    latitude: '40.164903',
    longitude: '-74.496062',
    timeZoneId: 'America/New_York',
  },
  '08527': {
    zip: '08527',
    city: 'Jackson',
    state: 'NJ',
    latitude: '40.10682',
    longitude: '-74.34102',
    timeZoneId: 'America/New_York',
  },
  '08528': {
    zip: '08528',
    city: 'Kingston',
    state: 'NJ',
    latitude: '40.376992',
    longitude: '-74.615316',
    timeZoneId: 'America/New_York',
  },
  '08530': {
    zip: '08530',
    city: 'Lambertville',
    state: 'NJ',
    latitude: '40.36793',
    longitude: '-74.933508',
    timeZoneId: 'America/New_York',
  },
  '08533': {
    zip: '08533',
    city: 'New Egypt',
    state: 'NJ',
    latitude: '40.083333',
    longitude: '-74.5',
    timeZoneId: 'America/New_York',
  },
  '08534': {
    zip: '08534',
    city: 'Pennington',
    state: 'NJ',
    latitude: '40.332828',
    longitude: '-74.775218',
    timeZoneId: 'America/New_York',
  },
  '08535': {
    zip: '08535',
    city: 'Millstone Township',
    state: 'NJ',
    latitude: '40.220714',
    longitude: '-74.440497',
    timeZoneId: 'America/New_York',
  },
  '08536': {
    zip: '08536',
    city: 'Plainsboro',
    state: 'NJ',
    latitude: '40.335503',
    longitude: '-74.577831',
    timeZoneId: 'America/New_York',
  },
  '08540': {
    zip: '08540',
    city: 'Princeton',
    state: 'NJ',
    latitude: '40.36746',
    longitude: '-74.66952',
    timeZoneId: 'America/New_York',
  },
  '08541': {
    zip: '08541',
    city: 'Princeton',
    state: 'NJ',
    latitude: '40.348381',
    longitude: '-74.659016',
    timeZoneId: 'America/New_York',
  },
  '08542': {
    zip: '08542',
    city: 'Princeton',
    state: 'NJ',
    latitude: '40.350373',
    longitude: '-74.660999',
    timeZoneId: 'America/New_York',
  },
  '08543': {
    zip: '08543',
    city: 'Princeton',
    state: 'NJ',
    latitude: '40.31117',
    longitude: '-74.651627',
    timeZoneId: 'America/New_York',
  },
  '08544': {
    zip: '08544',
    city: 'Princeton',
    state: 'NJ',
    latitude: '40.345441',
    longitude: '-74.655866',
    timeZoneId: 'America/New_York',
  },
  '08550': {
    zip: '08550',
    city: 'Princeton Junction',
    state: 'NJ',
    latitude: '40.299355',
    longitude: '-74.614868',
    timeZoneId: 'America/New_York',
  },
  '08551': {
    zip: '08551',
    city: 'Ringoes',
    state: 'NJ',
    latitude: '40.441298',
    longitude: '-74.836215',
    timeZoneId: 'America/New_York',
  },
  '08553': {
    zip: '08553',
    city: 'Rocky Hill',
    state: 'NJ',
    latitude: '40.411503',
    longitude: '-74.644626',
    timeZoneId: 'America/New_York',
  },
  '08554': {
    zip: '08554',
    city: 'Roebling',
    state: 'NJ',
    latitude: '40.114239',
    longitude: '-74.779351',
    timeZoneId: 'America/New_York',
  },
  '08555': {
    zip: '08555',
    city: 'Roosevelt',
    state: 'NJ',
    latitude: '40.21649',
    longitude: '-74.473122',
    timeZoneId: 'America/New_York',
  },
  '08556': {
    zip: '08556',
    city: 'Rosemont',
    state: 'NJ',
    latitude: '40.420974',
    longitude: '-74.995102',
    timeZoneId: 'America/New_York',
  },
  '08557': {
    zip: '08557',
    city: 'Sergeantsville',
    state: 'NJ',
    latitude: '40.445407',
    longitude: '-74.943046',
    timeZoneId: 'America/New_York',
  },
  '08558': {
    zip: '08558',
    city: 'Skillman',
    state: 'NJ',
    latitude: '40.416194',
    longitude: '-74.695442',
    timeZoneId: 'America/New_York',
  },
  '08559': {
    zip: '08559',
    city: 'Stockton',
    state: 'NJ',
    latitude: '40.432795',
    longitude: '-74.954631',
    timeZoneId: 'America/New_York',
  },
  '08560': {
    zip: '08560',
    city: 'Titusville',
    state: 'NJ',
    latitude: '40.29601',
    longitude: '-74.856815',
    timeZoneId: 'America/New_York',
  },
  '08561': {
    zip: '08561',
    city: 'Windsor',
    state: 'NJ',
    latitude: '40.244389',
    longitude: '-74.587139',
    timeZoneId: 'America/New_York',
  },
  '08562': {
    zip: '08562',
    city: 'Wrightstown',
    state: 'NJ',
    latitude: '40.063017',
    longitude: '-74.60618',
    timeZoneId: 'America/New_York',
  },
  '08601': {
    zip: '08601',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.219897',
    longitude: '-74.763739',
    timeZoneId: 'America/New_York',
  },
  '08602': {
    zip: '08602',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.218043',
    longitude: '-74.576246',
    timeZoneId: 'America/New_York',
  },
  '08603': {
    zip: '08603',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.220508',
    longitude: '-74.765264',
    timeZoneId: 'America/New_York',
  },
  '08604': {
    zip: '08604',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.219598',
    longitude: '-74.764373',
    timeZoneId: 'America/New_York',
  },
  '08605': {
    zip: '08605',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.219274',
    longitude: '-74.765178',
    timeZoneId: 'America/New_York',
  },
  '08606': {
    zip: '08606',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.220185',
    longitude: '-74.576017',
    timeZoneId: 'America/New_York',
  },
  '08607': {
    zip: '08607',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.22185',
    longitude: '-74.769727',
    timeZoneId: 'America/New_York',
  },
  '08608': {
    zip: '08608',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.220494',
    longitude: '-74.763438',
    timeZoneId: 'America/New_York',
  },
  '08609': {
    zip: '08609',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.222505',
    longitude: '-74.741462',
    timeZoneId: 'America/New_York',
  },
  '08610': {
    zip: '08610',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.2156',
    longitude: '-74.7396',
    timeZoneId: 'America/New_York',
  },
  '08611': {
    zip: '08611',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.209791',
    longitude: '-74.754696',
    timeZoneId: 'America/New_York',
  },
  '08618': {
    zip: '08618',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.2219',
    longitude: '-74.7336',
    timeZoneId: 'America/New_York',
  },
  '08619': {
    zip: '08619',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.23846',
    longitude: '-74.71746',
    timeZoneId: 'America/New_York',
  },
  '08620': {
    zip: '08620',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.178809',
    longitude: '-74.659973',
    timeZoneId: 'America/New_York',
  },
  '08625': {
    zip: '08625',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.205903',
    longitude: '-74.757064',
    timeZoneId: 'America/New_York',
  },
  '08628': {
    zip: '08628',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.27362',
    longitude: '-74.81748',
    timeZoneId: 'America/New_York',
  },
  '08629': {
    zip: '08629',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.218249',
    longitude: '-74.730652',
    timeZoneId: 'America/New_York',
  },
  '08638': {
    zip: '08638',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.26654',
    longitude: '-74.71284',
    timeZoneId: 'America/New_York',
  },
  '08640': {
    zip: '08640',
    city: 'Joint Base Mdl',
    state: 'NJ',
    latitude: '40.00842',
    longitude: '-74.58954',
    timeZoneId: 'America/New_York',
  },
  '08641': {
    zip: '08641',
    city: 'Joint Base Mdl',
    state: 'NJ',
    latitude: '40.027821',
    longitude: '-74.570679',
    timeZoneId: 'America/New_York',
  },
  '08645': {
    zip: '08645',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.21719',
    longitude: '-74.769632',
    timeZoneId: 'America/New_York',
  },
  '08646': {
    zip: '08646',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.217893',
    longitude: '-74.763424',
    timeZoneId: 'America/New_York',
  },
  '08647': {
    zip: '08647',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.218571',
    longitude: '-74.768936',
    timeZoneId: 'America/New_York',
  },
  '08648': {
    zip: '08648',
    city: 'Lawrence Township',
    state: 'NJ',
    latitude: '40.26594',
    longitude: '-74.69586',
    timeZoneId: 'America/New_York',
  },
  '08650': {
    zip: '08650',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.137834',
    longitude: '-74.627816',
    timeZoneId: 'America/New_York',
  },
  '08666': {
    zip: '08666',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.219539',
    longitude: '-74.76969',
    timeZoneId: 'America/New_York',
  },
  '08690': {
    zip: '08690',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.224987',
    longitude: '-74.661288',
    timeZoneId: 'America/New_York',
  },
  '08691': {
    zip: '08691',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.223905',
    longitude: '-74.620394',
    timeZoneId: 'America/New_York',
  },
  '08695': {
    zip: '08695',
    city: 'Trenton',
    state: 'NJ',
    latitude: '40.220861',
    longitude: '-74.764864',
    timeZoneId: 'America/New_York',
  },
  '08701': {
    zip: '08701',
    city: 'Lakewood',
    state: 'NJ',
    latitude: '40.084328',
    longitude: '-74.203837',
    timeZoneId: 'America/New_York',
  },
  '08720': {
    zip: '08720',
    city: 'Allenwood',
    state: 'NJ',
    latitude: '40.141423',
    longitude: '-74.109975',
    timeZoneId: 'America/New_York',
  },
  '08721': {
    zip: '08721',
    city: 'Bayville',
    state: 'NJ',
    latitude: '39.90612',
    longitude: '-74.17422',
    timeZoneId: 'America/New_York',
  },
  '08722': {
    zip: '08722',
    city: 'Beachwood',
    state: 'NJ',
    latitude: '39.934006',
    longitude: '-74.195669',
    timeZoneId: 'America/New_York',
  },
  '08723': {
    zip: '08723',
    city: 'Brick',
    state: 'NJ',
    latitude: '40.05168',
    longitude: '-74.10678',
    timeZoneId: 'America/New_York',
  },
  '08724': {
    zip: '08724',
    city: 'Brick',
    state: 'NJ',
    latitude: '40.086348',
    longitude: '-74.118215',
    timeZoneId: 'America/New_York',
  },
  '08730': {
    zip: '08730',
    city: 'Brielle',
    state: 'NJ',
    latitude: '40.104098',
    longitude: '-74.067637',
    timeZoneId: 'America/New_York',
  },
  '08731': {
    zip: '08731',
    city: 'Forked River',
    state: 'NJ',
    latitude: '39.87186',
    longitude: '-74.23776',
    timeZoneId: 'America/New_York',
  },
  '08732': {
    zip: '08732',
    city: 'Island Heights',
    state: 'NJ',
    latitude: '39.994447',
    longitude: '-74.119283',
    timeZoneId: 'America/New_York',
  },
  '08733': {
    zip: '08733',
    city: 'Lakehurst',
    state: 'NJ',
    latitude: '40.00236',
    longitude: '-74.32014',
    timeZoneId: 'America/New_York',
  },
  '08734': {
    zip: '08734',
    city: 'Lanoka Harbor',
    state: 'NJ',
    latitude: '39.860897',
    longitude: '-74.165767',
    timeZoneId: 'America/New_York',
  },
  '08735': {
    zip: '08735',
    city: 'Lavallette',
    state: 'NJ',
    latitude: '39.980754',
    longitude: '-74.100351',
    timeZoneId: 'America/New_York',
  },
  '08736': {
    zip: '08736',
    city: 'Manasquan',
    state: 'NJ',
    latitude: '40.122204',
    longitude: '-74.063541',
    timeZoneId: 'America/New_York',
  },
  '08738': {
    zip: '08738',
    city: 'Mantoloking',
    state: 'NJ',
    latitude: '40.019646',
    longitude: '-74.059485',
    timeZoneId: 'America/New_York',
  },
  '08739': {
    zip: '08739',
    city: 'Normandy Beach',
    state: 'NJ',
    latitude: '39.999746',
    longitude: '-74.064221',
    timeZoneId: 'America/New_York',
  },
  '08740': {
    zip: '08740',
    city: 'Ocean Gate',
    state: 'NJ',
    latitude: '39.923912',
    longitude: '-74.136557',
    timeZoneId: 'America/New_York',
  },
  '08741': {
    zip: '08741',
    city: 'Pine Beach',
    state: 'NJ',
    latitude: '39.933096',
    longitude: '-74.165383',
    timeZoneId: 'America/New_York',
  },
  '08742': {
    zip: '08742',
    city: 'Point Pleasant Beach',
    state: 'NJ',
    latitude: '40.079185',
    longitude: '-74.06587',
    timeZoneId: 'America/New_York',
  },
  '08750': {
    zip: '08750',
    city: 'Sea Girt',
    state: 'NJ',
    latitude: '40.134492',
    longitude: '-74.045049',
    timeZoneId: 'America/New_York',
  },
  '08751': {
    zip: '08751',
    city: 'Seaside Heights',
    state: 'NJ',
    latitude: '39.93762',
    longitude: '-74.08008',
    timeZoneId: 'America/New_York',
  },
  '08752': {
    zip: '08752',
    city: 'Seaside Park',
    state: 'NJ',
    latitude: '39.922118',
    longitude: '-74.080135',
    timeZoneId: 'America/New_York',
  },
  '08753': {
    zip: '08753',
    city: 'Toms River',
    state: 'NJ',
    latitude: '39.98868',
    longitude: '-74.17956',
    timeZoneId: 'America/New_York',
  },
  '08754': {
    zip: '08754',
    city: 'Toms River',
    state: 'NJ',
    latitude: '39.981977',
    longitude: '-74.158872',
    timeZoneId: 'America/New_York',
  },
  '08755': {
    zip: '08755',
    city: 'Toms River',
    state: 'NJ',
    latitude: '40.00047',
    longitude: '-74.222076',
    timeZoneId: 'America/New_York',
  },
  '08756': {
    zip: '08756',
    city: 'Toms River',
    state: 'NJ',
    latitude: '39.946246',
    longitude: '-74.252466',
    timeZoneId: 'America/New_York',
  },
  '08757': {
    zip: '08757',
    city: 'Toms River',
    state: 'NJ',
    latitude: '39.97194',
    longitude: '-74.21988',
    timeZoneId: 'America/New_York',
  },
  '08758': {
    zip: '08758',
    city: 'Waretown',
    state: 'NJ',
    latitude: '39.793952',
    longitude: '-74.192189',
    timeZoneId: 'America/New_York',
  },
  '08759': {
    zip: '08759',
    city: 'Manchester Township',
    state: 'NJ',
    latitude: '39.952064',
    longitude: '-74.365653',
    timeZoneId: 'America/New_York',
  },
  '08801': {
    zip: '08801',
    city: 'Annandale',
    state: 'NJ',
    latitude: '40.63128',
    longitude: '-74.885604',
    timeZoneId: 'America/New_York',
  },
  '08802': {
    zip: '08802',
    city: 'Asbury',
    state: 'NJ',
    latitude: '40.6653',
    longitude: '-75.02412',
    timeZoneId: 'America/New_York',
  },
  '08803': {
    zip: '08803',
    city: 'Baptistown',
    state: 'NJ',
    latitude: '40.520741',
    longitude: '-75.009187',
    timeZoneId: 'America/New_York',
  },
  '08804': {
    zip: '08804',
    city: 'Bloomsbury',
    state: 'NJ',
    latitude: '40.645635',
    longitude: '-75.076618',
    timeZoneId: 'America/New_York',
  },
  '08805': {
    zip: '08805',
    city: 'Bound Brook',
    state: 'NJ',
    latitude: '40.569275',
    longitude: '-74.539176',
    timeZoneId: 'America/New_York',
  },
  '08807': {
    zip: '08807',
    city: 'Bridgewater',
    state: 'NJ',
    latitude: '40.593164',
    longitude: '-74.619513',
    timeZoneId: 'America/New_York',
  },
  '08808': {
    zip: '08808',
    city: 'Broadway',
    state: 'NJ',
    latitude: '40.725589',
    longitude: '-75.048373',
    timeZoneId: 'America/New_York',
  },
  '08809': {
    zip: '08809',
    city: 'Clinton',
    state: 'NJ',
    latitude: '40.65251',
    longitude: '-74.926901',
    timeZoneId: 'America/New_York',
  },
  '08810': {
    zip: '08810',
    city: 'Dayton',
    state: 'NJ',
    latitude: '40.375752',
    longitude: '-74.513413',
    timeZoneId: 'America/New_York',
  },
  '08812': {
    zip: '08812',
    city: 'Dunellen',
    state: 'NJ',
    latitude: '40.601341',
    longitude: '-74.478647',
    timeZoneId: 'America/New_York',
  },
  '08816': {
    zip: '08816',
    city: 'East Brunswick',
    state: 'NJ',
    latitude: '40.429733',
    longitude: '-74.407288',
    timeZoneId: 'America/New_York',
  },
  '08817': {
    zip: '08817',
    city: 'Edison',
    state: 'NJ',
    latitude: '40.51467',
    longitude: '-74.395296',
    timeZoneId: 'America/New_York',
  },
  '08818': {
    zip: '08818',
    city: 'Edison',
    state: 'NJ',
    latitude: '40.513663',
    longitude: '-74.397993',
    timeZoneId: 'America/New_York',
  },
  '08820': {
    zip: '08820',
    city: 'Edison',
    state: 'NJ',
    latitude: '40.575451',
    longitude: '-74.364495',
    timeZoneId: 'America/New_York',
  },
  '08821': {
    zip: '08821',
    city: 'Flagtown',
    state: 'NJ',
    latitude: '40.521402',
    longitude: '-74.688156',
    timeZoneId: 'America/New_York',
  },
  '08822': {
    zip: '08822',
    city: 'Flemington',
    state: 'NJ',
    latitude: '40.519697',
    longitude: '-74.852845',
    timeZoneId: 'America/New_York',
  },
  '08823': {
    zip: '08823',
    city: 'Franklin Park',
    state: 'NJ',
    latitude: '40.442493',
    longitude: '-74.543499',
    timeZoneId: 'America/New_York',
  },
  '08824': {
    zip: '08824',
    city: 'Kendall Park',
    state: 'NJ',
    latitude: '40.419345',
    longitude: '-74.55009',
    timeZoneId: 'America/New_York',
  },
  '08825': {
    zip: '08825',
    city: 'Frenchtown',
    state: 'NJ',
    latitude: '40.521429',
    longitude: '-75.047754',
    timeZoneId: 'America/New_York',
  },
  '08826': {
    zip: '08826',
    city: 'Glen Gardner',
    state: 'NJ',
    latitude: '40.701442',
    longitude: '-74.928017',
    timeZoneId: 'America/New_York',
  },
  '08827': {
    zip: '08827',
    city: 'Hampton',
    state: 'NJ',
    latitude: '40.678752',
    longitude: '-74.963414',
    timeZoneId: 'America/New_York',
  },
  '08828': {
    zip: '08828',
    city: 'Helmetta',
    state: 'NJ',
    latitude: '40.377937',
    longitude: '-74.419215',
    timeZoneId: 'America/New_York',
  },
  '08829': {
    zip: '08829',
    city: 'High Bridge',
    state: 'NJ',
    latitude: '40.670912',
    longitude: '-74.893718',
    timeZoneId: 'America/New_York',
  },
  '08830': {
    zip: '08830',
    city: 'Iselin',
    state: 'NJ',
    latitude: '40.571397',
    longitude: '-74.314012',
    timeZoneId: 'America/New_York',
  },
  '08831': {
    zip: '08831',
    city: 'Monroe Township',
    state: 'NJ',
    latitude: '40.350085',
    longitude: '-74.441445',
    timeZoneId: 'America/New_York',
  },
  '08832': {
    zip: '08832',
    city: 'Keasbey',
    state: 'NJ',
    latitude: '40.5143',
    longitude: '-74.30215',
    timeZoneId: 'America/New_York',
  },
  '08833': {
    zip: '08833',
    city: 'Lebanon',
    state: 'NJ',
    latitude: '40.644815',
    longitude: '-74.826924',
    timeZoneId: 'America/New_York',
  },
  '08834': {
    zip: '08834',
    city: 'Little York',
    state: 'NJ',
    latitude: '40.607637',
    longitude: '-75.058036',
    timeZoneId: 'America/New_York',
  },
  '08835': {
    zip: '08835',
    city: 'Manville',
    state: 'NJ',
    latitude: '40.536675',
    longitude: '-74.592514',
    timeZoneId: 'America/New_York',
  },
  '08836': {
    zip: '08836',
    city: 'Martinsville',
    state: 'NJ',
    latitude: '40.599919',
    longitude: '-74.559189',
    timeZoneId: 'America/New_York',
  },
  '08837': {
    zip: '08837',
    city: 'Edison',
    state: 'NJ',
    latitude: '40.505704',
    longitude: '-74.354187',
    timeZoneId: 'America/New_York',
  },
  '08840': {
    zip: '08840',
    city: 'Metuchen',
    state: 'NJ',
    latitude: '40.54235',
    longitude: '-74.356832',
    timeZoneId: 'America/New_York',
  },
  '08844': {
    zip: '08844',
    city: 'Hillsborough',
    state: 'NJ',
    latitude: '40.479008',
    longitude: '-74.628315',
    timeZoneId: 'America/New_York',
  },
  '08846': {
    zip: '08846',
    city: 'Middlesex',
    state: 'NJ',
    latitude: '40.574785',
    longitude: '-74.498817',
    timeZoneId: 'America/New_York',
  },
  '08848': {
    zip: '08848',
    city: 'Milford',
    state: 'NJ',
    latitude: '40.596999',
    longitude: '-75.091729',
    timeZoneId: 'America/New_York',
  },
  '08850': {
    zip: '08850',
    city: 'Milltown',
    state: 'NJ',
    latitude: '40.449018',
    longitude: '-74.435113',
    timeZoneId: 'America/New_York',
  },
  '08852': {
    zip: '08852',
    city: 'Monmouth Junction',
    state: 'NJ',
    latitude: '40.394077',
    longitude: '-74.544739',
    timeZoneId: 'America/New_York',
  },
  '08853': {
    zip: '08853',
    city: 'Neshanic Station',
    state: 'NJ',
    latitude: '40.508543',
    longitude: '-74.717562',
    timeZoneId: 'America/New_York',
  },
  '08854': {
    zip: '08854',
    city: 'Piscataway',
    state: 'NJ',
    latitude: '40.552711',
    longitude: '-74.458211',
    timeZoneId: 'America/New_York',
  },
  '08855': {
    zip: '08855',
    city: 'Piscataway',
    state: 'NJ',
    latitude: '40.500254',
    longitude: '-74.399983',
    timeZoneId: 'America/New_York',
  },
  '08857': {
    zip: '08857',
    city: 'Old Bridge',
    state: 'NJ',
    latitude: '40.400392',
    longitude: '-74.324601',
    timeZoneId: 'America/New_York',
  },
  '08858': {
    zip: '08858',
    city: 'Oldwick',
    state: 'NJ',
    latitude: '40.69281',
    longitude: '-74.794463',
    timeZoneId: 'America/New_York',
  },
  '08859': {
    zip: '08859',
    city: 'Parlin',
    state: 'NJ',
    latitude: '40.461255',
    longitude: '-74.310279',
    timeZoneId: 'America/New_York',
  },
  '08861': {
    zip: '08861',
    city: 'Perth Amboy',
    state: 'NJ',
    latitude: '40.519012',
    longitude: '-74.27612',
    timeZoneId: 'America/New_York',
  },
  '08862': {
    zip: '08862',
    city: 'Perth Amboy',
    state: 'NJ',
    latitude: '40.520753',
    longitude: '-74.269434',
    timeZoneId: 'America/New_York',
  },
  '08863': {
    zip: '08863',
    city: 'Fords',
    state: 'NJ',
    latitude: '40.53661',
    longitude: '-74.315728',
    timeZoneId: 'America/New_York',
  },
  '08865': {
    zip: '08865',
    city: 'Phillipsburg',
    state: 'NJ',
    latitude: '40.69818',
    longitude: '-75.13548',
    timeZoneId: 'America/New_York',
  },
  '08867': {
    zip: '08867',
    city: 'Pittstown',
    state: 'NJ',
    latitude: '40.593311',
    longitude: '-74.905975',
    timeZoneId: 'America/New_York',
  },
  '08868': {
    zip: '08868',
    city: 'Quakertown',
    state: 'NJ',
    latitude: '40.566073',
    longitude: '-74.943554',
    timeZoneId: 'America/New_York',
  },
  '08869': {
    zip: '08869',
    city: 'Raritan',
    state: 'NJ',
    latitude: '40.573369',
    longitude: '-74.637747',
    timeZoneId: 'America/New_York',
  },
  '08870': {
    zip: '08870',
    city: 'Readington',
    state: 'NJ',
    latitude: '40.566487',
    longitude: '-74.739942',
    timeZoneId: 'America/New_York',
  },
  '08871': {
    zip: '08871',
    city: 'Sayreville',
    state: 'NJ',
    latitude: '40.465874',
    longitude: '-74.323603',
    timeZoneId: 'America/New_York',
  },
  '08872': {
    zip: '08872',
    city: 'Sayreville',
    state: 'NJ',
    latitude: '40.462576',
    longitude: '-74.345502',
    timeZoneId: 'America/New_York',
  },
  '08873': {
    zip: '08873',
    city: 'Somerset',
    state: 'NJ',
    latitude: '40.49934',
    longitude: '-74.52138',
    timeZoneId: 'America/New_York',
  },
  '08875': {
    zip: '08875',
    city: 'Somerset',
    state: 'NJ',
    latitude: '40.486',
    longitude: '-74.465',
    timeZoneId: 'America/New_York',
  },
  '08876': {
    zip: '08876',
    city: 'Somerville',
    state: 'NJ',
    latitude: '40.54374',
    longitude: '-74.66004',
    timeZoneId: 'America/New_York',
  },
  '08879': {
    zip: '08879',
    city: 'South Amboy',
    state: 'NJ',
    latitude: '40.463991',
    longitude: '-74.275843',
    timeZoneId: 'America/New_York',
  },
  '08880': {
    zip: '08880',
    city: 'South Bound Brook',
    state: 'NJ',
    latitude: '40.553052',
    longitude: '-74.530292',
    timeZoneId: 'America/New_York',
  },
  '08882': {
    zip: '08882',
    city: 'South River',
    state: 'NJ',
    latitude: '40.444355',
    longitude: '-74.383419',
    timeZoneId: 'America/New_York',
  },
  '08884': {
    zip: '08884',
    city: 'Spotswood',
    state: 'NJ',
    latitude: '40.388768',
    longitude: '-74.392244',
    timeZoneId: 'America/New_York',
  },
  '08885': {
    zip: '08885',
    city: 'Stanton',
    state: 'NJ',
    latitude: '40.574268',
    longitude: '-74.838178',
    timeZoneId: 'America/New_York',
  },
  '08886': {
    zip: '08886',
    city: 'Stewartsville',
    state: 'NJ',
    latitude: '40.693256',
    longitude: '-75.111261',
    timeZoneId: 'America/New_York',
  },
  '08887': {
    zip: '08887',
    city: 'Three Bridges',
    state: 'NJ',
    latitude: '40.529051',
    longitude: '-74.779412',
    timeZoneId: 'America/New_York',
  },
  '08888': {
    zip: '08888',
    city: 'Whitehouse',
    state: 'NJ',
    latitude: '40.624996',
    longitude: '-74.741639',
    timeZoneId: 'America/New_York',
  },
  '08889': {
    zip: '08889',
    city: 'Whitehouse Station',
    state: 'NJ',
    latitude: '40.599026',
    longitude: '-74.75872',
    timeZoneId: 'America/New_York',
  },
  '08890': {
    zip: '08890',
    city: 'Zarephath',
    state: 'NJ',
    latitude: '40.538485',
    longitude: '-74.577082',
    timeZoneId: 'America/New_York',
  },
  '08899': {
    zip: '08899',
    city: 'Edison',
    state: 'NJ',
    latitude: '40.518788',
    longitude: '-74.398608',
    timeZoneId: 'America/New_York',
  },
  '08901': {
    zip: '08901',
    city: 'New Brunswick',
    state: 'NJ',
    latitude: '40.48799',
    longitude: '-74.447033',
    timeZoneId: 'America/New_York',
  },
  '08902': {
    zip: '08902',
    city: 'North Brunswick',
    state: 'NJ',
    latitude: '40.44666',
    longitude: '-74.48898',
    timeZoneId: 'America/New_York',
  },
  '08903': {
    zip: '08903',
    city: 'New Brunswick',
    state: 'NJ',
    latitude: '40.509874',
    longitude: '-74.445463',
    timeZoneId: 'America/New_York',
  },
  '08904': {
    zip: '08904',
    city: 'Highland Park',
    state: 'NJ',
    latitude: '40.498795',
    longitude: '-74.425817',
    timeZoneId: 'America/New_York',
  },
  '08906': {
    zip: '08906',
    city: 'New Brunswick',
    state: 'NJ',
    latitude: '40.484296',
    longitude: '-74.444675',
    timeZoneId: 'America/New_York',
  },
  '08933': {
    zip: '08933',
    city: 'New Brunswick',
    state: 'NJ',
    latitude: '40.486376',
    longitude: '-74.44521',
    timeZoneId: 'America/New_York',
  },
  '08989': {
    zip: '08989',
    city: 'New Brunswick',
    state: 'NJ',
    latitude: '40.519417',
    longitude: '-74.414583',
    timeZoneId: 'America/New_York',
  },
};
const stateMap = {
  NY: [
    '10001',
    '10002',
    '10003',
    '10004',
    '10005',
    '10006',
    '10007',
    '10008',
    '10009',
    '10010',
    '10011',
    '10012',
    '10013',
    '10014',
    '10016',
    '10017',
    '10018',
    '10019',
    '10020',
    '10021',
    '10022',
    '10023',
    '10024',
    '10025',
    '10026',
    '10027',
    '10028',
    '10029',
    '10030',
    '10031',
    '10032',
    '10033',
    '10034',
    '10035',
    '10036',
    '10037',
    '10038',
    '10039',
    '10040',
    '10041',
    '10043',
    '10044',
    '10045',
    '10055',
    '10060',
    '10065',
    '10069',
    '10075',
    '10080',
    '10081',
    '10087',
    '10090',
    '10101',
    '10102',
    '10103',
    '10104',
    '10105',
    '10106',
    '10107',
    '10108',
    '10109',
    '10110',
    '10111',
    '10112',
    '10113',
    '10114',
    '10115',
    '10116',
    '10117',
    '10118',
    '10119',
    '10120',
    '10121',
    '10122',
    '10123',
    '10124',
    '10125',
    '10126',
    '10128',
    '10129',
    '10130',
    '10131',
    '10132',
    '10133',
    '10138',
    '10150',
    '10151',
    '10152',
    '10153',
    '10154',
    '10155',
    '10156',
    '10157',
    '10158',
    '10159',
    '10160',
    '10162',
    '10163',
    '10164',
    '10165',
    '10166',
    '10167',
    '10168',
    '10169',
    '10170',
    '10171',
    '10172',
    '10173',
    '10174',
    '10175',
    '10176',
    '10177',
    '10178',
    '10179',
    '10185',
    '10199',
    '10203',
    '10211',
    '10212',
    '10213',
    '10242',
    '10249',
    '10256',
    '10258',
    '10259',
    '10260',
    '10261',
    '10265',
    '10268',
    '10269',
    '10270',
    '10271',
    '10272',
    '10273',
    '10274',
    '10275',
    '10276',
    '10277',
    '10278',
    '10279',
    '10280',
    '10281',
    '10282',
    '10285',
    '10286',
    '10301',
    '10302',
    '10303',
    '10304',
    '10305',
    '10306',
    '10307',
    '10308',
    '10309',
    '10310',
    '10311',
    '10312',
    '10313',
    '10314',
    '10451',
    '10452',
    '10453',
    '10454',
    '10455',
    '10456',
    '10457',
    '10458',
    '10459',
    '10460',
    '10461',
    '10462',
    '10463',
    '10464',
    '10465',
    '10466',
    '10467',
    '10468',
    '10469',
    '10470',
    '10471',
    '10472',
    '10473',
    '10474',
    '10475',
    '10501',
    '10502',
    '10503',
    '10504',
    '10505',
    '10506',
    '10507',
    '10509',
    '10510',
    '10511',
    '10512',
    '10514',
    '10516',
    '10517',
    '10518',
    '10519',
    '10520',
    '10521',
    '10522',
    '10523',
    '10524',
    '10526',
    '10527',
    '10528',
    '10530',
    '10532',
    '10533',
    '10535',
    '10536',
    '10537',
    '10538',
    '10540',
    '10541',
    '10542',
    '10543',
    '10545',
    '10546',
    '10547',
    '10548',
    '10549',
    '10550',
    '10551',
    '10552',
    '10553',
    '10560',
    '10562',
    '10566',
    '10567',
    '10570',
    '10573',
    '10576',
    '10577',
    '10578',
    '10579',
    '10580',
    '10583',
    '10587',
    '10588',
    '10589',
    '10590',
    '10591',
    '10594',
    '10595',
    '10596',
    '10597',
    '10598',
    '10601',
    '10602',
    '10603',
    '10604',
    '10605',
    '10606',
    '10607',
    '10610',
    '10701',
    '10702',
    '10703',
    '10704',
    '10705',
    '10706',
    '10707',
    '10708',
    '10709',
    '10710',
    '10801',
    '10802',
    '10803',
    '10804',
    '10805',
    '10901',
    '10910',
    '10911',
    '10912',
    '10913',
    '10914',
    '10915',
    '10916',
    '10917',
    '10918',
    '10919',
    '10920',
    '10921',
    '10922',
    '10923',
    '10924',
    '10925',
    '10926',
    '10927',
    '10928',
    '10930',
    '10931',
    '10932',
    '10933',
    '10940',
    '10941',
    '10949',
    '10950',
    '10952',
    '10953',
    '10954',
    '10956',
    '10958',
    '10959',
    '10960',
    '10962',
    '10963',
    '10964',
    '10965',
    '10968',
    '10969',
    '10970',
    '10973',
    '10974',
    '10975',
    '10976',
    '10977',
    '10979',
    '10980',
    '10981',
    '10982',
    '10983',
    '10984',
    '10985',
    '10986',
    '10987',
    '10988',
    '10989',
    '10990',
    '10992',
    '10993',
    '10994',
    '10996',
    '10997',
    '10998',
    '11001',
    '11002',
    '11003',
    '11004',
    '11005',
    '11010',
    '11020',
    '11021',
    '11022',
    '11023',
    '11024',
    '11026',
    '11027',
    '11030',
    '11040',
    '11042',
    '11050',
    '11051',
    '11052',
    '11053',
    '11054',
    '11055',
    '11096',
    '11101',
    '11102',
    '11103',
    '11104',
    '11105',
    '11106',
    '11109',
    '11120',
    '11201',
    '11202',
    '11203',
    '11204',
    '11205',
    '11206',
    '11207',
    '11208',
    '11209',
    '11210',
    '11211',
    '11212',
    '11213',
    '11214',
    '11215',
    '11216',
    '11217',
    '11218',
    '11219',
    '11220',
    '11221',
    '11222',
    '11223',
    '11224',
    '11225',
    '11226',
    '11228',
    '11229',
    '11230',
    '11231',
    '11232',
    '11233',
    '11234',
    '11235',
    '11236',
    '11237',
    '11238',
    '11239',
    '11241',
    '11242',
    '11243',
    '11245',
    '11247',
    '11249',
    '11251',
    '11252',
    '11256',
    '11351',
    '11352',
    '11354',
    '11355',
    '11356',
    '11357',
    '11358',
    '11359',
    '11360',
    '11361',
    '11362',
    '11363',
    '11364',
    '11365',
    '11366',
    '11367',
    '11368',
    '11369',
    '11370',
    '11371',
    '11372',
    '11373',
    '11374',
    '11375',
    '11377',
    '11378',
    '11379',
    '11380',
    '11381',
    '11385',
    '11386',
    '11405',
    '11411',
    '11412',
    '11413',
    '11414',
    '11415',
    '11416',
    '11417',
    '11418',
    '11419',
    '11420',
    '11421',
    '11422',
    '11423',
    '11424',
    '11425',
    '11426',
    '11427',
    '11428',
    '11429',
    '11430',
    '11431',
    '11432',
    '11433',
    '11434',
    '11435',
    '11436',
    '11437',
    '11439',
    '11451',
    '11499',
    '11501',
    '11507',
    '11509',
    '11510',
    '11514',
    '11516',
    '11518',
    '11520',
    '11530',
    '11531',
    '11542',
    '11545',
    '11547',
    '11548',
    '11549',
    '11550',
    '11551',
    '11552',
    '11553',
    '11554',
    '11555',
    '11556',
    '11557',
    '11558',
    '11559',
    '11560',
    '11561',
    '11563',
    '11565',
    '11566',
    '11568',
    '11569',
    '11570',
    '11571',
    '11572',
    '11575',
    '11576',
    '11577',
    '11579',
    '11580',
    '11581',
    '11582',
    '11590',
    '11596',
    '11598',
    '11599',
    '11690',
    '11691',
    '11692',
    '11693',
    '11694',
    '11695',
    '11697',
    '11701',
    '11702',
    '11703',
    '11704',
    '11705',
    '11706',
    '11707',
    '11709',
    '11710',
    '11713',
    '11714',
    '11715',
    '11716',
    '11717',
    '11718',
    '11719',
    '11720',
    '11721',
    '11722',
    '11724',
    '11725',
    '11726',
    '11727',
    '11729',
    '11730',
    '11731',
    '11732',
    '11733',
    '11735',
    '11737',
    '11738',
    '11739',
    '11740',
    '11741',
    '11742',
    '11743',
    '11746',
    '11747',
    '11749',
    '11751',
    '11752',
    '11753',
    '11754',
    '11755',
    '11756',
    '11757',
    '11758',
    '11760',
    '11762',
    '11763',
    '11764',
    '11765',
    '11766',
    '11767',
    '11768',
    '11769',
    '11770',
    '11771',
    '11772',
    '11773',
    '11775',
    '11776',
    '11777',
    '11778',
    '11779',
    '11780',
    '11782',
    '11783',
    '11784',
    '11786',
    '11787',
    '11788',
    '11789',
    '11790',
    '11791',
    '11792',
    '11793',
    '11794',
    '11795',
    '11796',
    '11797',
    '11798',
    '11801',
    '11802',
    '11803',
    '11804',
    '11815',
    '11853',
    '11901',
    '11930',
    '11931',
    '11932',
    '11933',
    '11934',
    '11935',
    '11937',
    '11939',
    '11940',
    '11941',
    '11942',
    '11944',
    '11946',
    '11947',
    '11948',
    '11949',
    '11950',
    '11951',
    '11952',
    '11953',
    '11954',
    '11955',
    '11956',
    '11957',
    '11958',
    '11959',
    '11960',
    '11961',
    '11962',
    '11963',
    '11964',
    '11965',
    '11967',
    '11968',
    '11969',
    '11970',
    '11971',
    '11972',
    '11973',
    '11975',
    '11976',
    '11977',
    '11978',
    '11980',
    '12007',
    '12008',
    '12009',
    '12010',
    '12015',
    '12016',
    '12017',
    '12018',
    '12019',
    '12020',
    '12022',
    '12023',
    '12024',
    '12025',
    '12027',
    '12028',
    '12029',
    '12031',
    '12032',
    '12033',
    '12035',
    '12036',
    '12037',
    '12040',
    '12041',
    '12042',
    '12043',
    '12045',
    '12046',
    '12047',
    '12050',
    '12051',
    '12052',
    '12053',
    '12054',
    '12055',
    '12056',
    '12057',
    '12058',
    '12059',
    '12060',
    '12061',
    '12062',
    '12063',
    '12064',
    '12065',
    '12066',
    '12067',
    '12068',
    '12069',
    '12070',
    '12071',
    '12072',
    '12073',
    '12074',
    '12075',
    '12076',
    '12077',
    '12078',
    '12082',
    '12083',
    '12084',
    '12085',
    '12086',
    '12087',
    '12089',
    '12090',
    '12092',
    '12093',
    '12094',
    '12095',
    '12106',
    '12107',
    '12108',
    '12110',
    '12115',
    '12116',
    '12117',
    '12118',
    '12120',
    '12121',
    '12122',
    '12123',
    '12124',
    '12125',
    '12128',
    '12130',
    '12131',
    '12132',
    '12133',
    '12134',
    '12136',
    '12137',
    '12138',
    '12139',
    '12140',
    '12141',
    '12143',
    '12144',
    '12147',
    '12148',
    '12149',
    '12150',
    '12151',
    '12153',
    '12154',
    '12155',
    '12156',
    '12157',
    '12158',
    '12159',
    '12160',
    '12161',
    '12164',
    '12165',
    '12166',
    '12167',
    '12168',
    '12169',
    '12170',
    '12172',
    '12173',
    '12174',
    '12175',
    '12176',
    '12177',
    '12180',
    '12181',
    '12182',
    '12183',
    '12184',
    '12185',
    '12186',
    '12187',
    '12188',
    '12189',
    '12190',
    '12192',
    '12193',
    '12194',
    '12195',
    '12196',
    '12197',
    '12198',
    '12201',
    '12202',
    '12203',
    '12204',
    '12205',
    '12206',
    '12207',
    '12208',
    '12209',
    '12210',
    '12211',
    '12212',
    '12214',
    '12220',
    '12222',
    '12223',
    '12224',
    '12225',
    '12226',
    '12227',
    '12228',
    '12229',
    '12230',
    '12231',
    '12232',
    '12233',
    '12234',
    '12235',
    '12236',
    '12237',
    '12238',
    '12239',
    '12240',
    '12241',
    '12242',
    '12243',
    '12244',
    '12245',
    '12246',
    '12247',
    '12248',
    '12249',
    '12250',
    '12255',
    '12257',
    '12260',
    '12261',
    '12288',
    '12301',
    '12302',
    '12303',
    '12304',
    '12305',
    '12306',
    '12307',
    '12308',
    '12309',
    '12325',
    '12345',
    '12401',
    '12402',
    '12404',
    '12405',
    '12406',
    '12407',
    '12409',
    '12410',
    '12411',
    '12412',
    '12413',
    '12414',
    '12416',
    '12417',
    '12418',
    '12419',
    '12420',
    '12421',
    '12422',
    '12423',
    '12424',
    '12427',
    '12428',
    '12429',
    '12430',
    '12431',
    '12432',
    '12433',
    '12434',
    '12435',
    '12436',
    '12438',
    '12439',
    '12440',
    '12441',
    '12442',
    '12443',
    '12444',
    '12446',
    '12448',
    '12449',
    '12450',
    '12451',
    '12452',
    '12453',
    '12454',
    '12455',
    '12456',
    '12457',
    '12458',
    '12459',
    '12460',
    '12461',
    '12463',
    '12464',
    '12465',
    '12466',
    '12468',
    '12469',
    '12470',
    '12471',
    '12472',
    '12473',
    '12474',
    '12475',
    '12477',
    '12480',
    '12481',
    '12482',
    '12483',
    '12484',
    '12485',
    '12486',
    '12487',
    '12489',
    '12490',
    '12491',
    '12492',
    '12493',
    '12494',
    '12495',
    '12496',
    '12498',
    '12501',
    '12502',
    '12503',
    '12504',
    '12506',
    '12507',
    '12508',
    '12510',
    '12511',
    '12512',
    '12513',
    '12514',
    '12515',
    '12516',
    '12517',
    '12518',
    '12520',
    '12521',
    '12522',
    '12523',
    '12524',
    '12525',
    '12526',
    '12527',
    '12528',
    '12529',
    '12530',
    '12531',
    '12533',
    '12534',
    '12537',
    '12538',
    '12540',
    '12541',
    '12542',
    '12543',
    '12544',
    '12545',
    '12546',
    '12547',
    '12548',
    '12549',
    '12550',
    '12551',
    '12552',
    '12553',
    '12555',
    '12561',
    '12563',
    '12564',
    '12565',
    '12566',
    '12567',
    '12568',
    '12569',
    '12570',
    '12571',
    '12572',
    '12574',
    '12575',
    '12577',
    '12578',
    '12580',
    '12581',
    '12582',
    '12583',
    '12584',
    '12585',
    '12586',
    '12588',
    '12589',
    '12590',
    '12592',
    '12594',
    '12601',
    '12602',
    '12603',
    '12604',
    '12701',
    '12719',
    '12720',
    '12721',
    '12722',
    '12723',
    '12724',
    '12725',
    '12726',
    '12727',
    '12729',
    '12732',
    '12733',
    '12734',
    '12736',
    '12737',
    '12738',
    '12740',
    '12741',
    '12742',
    '12743',
    '12745',
    '12746',
    '12747',
    '12748',
    '12749',
    '12750',
    '12751',
    '12752',
    '12754',
    '12758',
    '12759',
    '12760',
    '12762',
    '12763',
    '12764',
    '12765',
    '12766',
    '12767',
    '12768',
    '12769',
    '12770',
    '12771',
    '12775',
    '12776',
    '12777',
    '12778',
    '12779',
    '12780',
    '12781',
    '12783',
    '12784',
    '12785',
    '12786',
    '12787',
    '12788',
    '12789',
    '12790',
    '12791',
    '12792',
    '12801',
    '12803',
    '12804',
    '12808',
    '12809',
    '12810',
    '12811',
    '12812',
    '12814',
    '12815',
    '12816',
    '12817',
    '12819',
    '12820',
    '12821',
    '12822',
    '12823',
    '12824',
    '12827',
    '12828',
    '12831',
    '12832',
    '12833',
    '12834',
    '12835',
    '12836',
    '12837',
    '12838',
    '12839',
    '12841',
    '12842',
    '12843',
    '12844',
    '12845',
    '12846',
    '12847',
    '12848',
    '12849',
    '12850',
    '12851',
    '12852',
    '12853',
    '12854',
    '12855',
    '12856',
    '12857',
    '12858',
    '12859',
    '12860',
    '12861',
    '12862',
    '12863',
    '12864',
    '12865',
    '12866',
    '12870',
    '12871',
    '12872',
    '12873',
    '12874',
    '12878',
    '12879',
    '12883',
    '12884',
    '12885',
    '12886',
    '12887',
    '12901',
    '12903',
    '12910',
    '12911',
    '12912',
    '12913',
    '12914',
    '12915',
    '12916',
    '12917',
    '12918',
    '12919',
    '12920',
    '12921',
    '12922',
    '12923',
    '12924',
    '12926',
    '12927',
    '12928',
    '12929',
    '12930',
    '12932',
    '12933',
    '12934',
    '12935',
    '12936',
    '12937',
    '12939',
    '12941',
    '12942',
    '12943',
    '12944',
    '12945',
    '12946',
    '12949',
    '12950',
    '12952',
    '12953',
    '12955',
    '12956',
    '12957',
    '12958',
    '12959',
    '12960',
    '12961',
    '12962',
    '12964',
    '12965',
    '12966',
    '12967',
    '12969',
    '12970',
    '12972',
    '12973',
    '12974',
    '12975',
    '12976',
    '12977',
    '12978',
    '12979',
    '12980',
    '12981',
    '12983',
    '12985',
    '12986',
    '12987',
    '12989',
    '12992',
    '12993',
    '12995',
    '12996',
    '12997',
    '12998',
    '13020',
    '13021',
    '13022',
    '13024',
    '13026',
    '13027',
    '13028',
    '13029',
    '13030',
    '13031',
    '13032',
    '13033',
    '13034',
    '13035',
    '13036',
    '13037',
    '13039',
    '13040',
    '13041',
    '13042',
    '13043',
    '13044',
    '13045',
    '13051',
    '13052',
    '13053',
    '13054',
    '13056',
    '13057',
    '13060',
    '13061',
    '13062',
    '13063',
    '13064',
    '13065',
    '13066',
    '13068',
    '13069',
    '13071',
    '13072',
    '13073',
    '13074',
    '13076',
    '13077',
    '13078',
    '13080',
    '13081',
    '13082',
    '13083',
    '13084',
    '13087',
    '13088',
    '13089',
    '13090',
    '13092',
    '13093',
    '13101',
    '13102',
    '13103',
    '13104',
    '13107',
    '13108',
    '13110',
    '13111',
    '13112',
    '13113',
    '13114',
    '13115',
    '13116',
    '13117',
    '13118',
    '13119',
    '13120',
    '13121',
    '13122',
    '13123',
    '13124',
    '13126',
    '13131',
    '13132',
    '13134',
    '13135',
    '13136',
    '13137',
    '13138',
    '13139',
    '13140',
    '13141',
    '13142',
    '13143',
    '13144',
    '13145',
    '13146',
    '13147',
    '13148',
    '13152',
    '13153',
    '13154',
    '13155',
    '13156',
    '13157',
    '13158',
    '13159',
    '13160',
    '13162',
    '13163',
    '13164',
    '13165',
    '13166',
    '13167',
    '13201',
    '13202',
    '13203',
    '13204',
    '13205',
    '13206',
    '13207',
    '13208',
    '13209',
    '13210',
    '13211',
    '13212',
    '13214',
    '13215',
    '13217',
    '13218',
    '13219',
    '13220',
    '13221',
    '13224',
    '13225',
    '13235',
    '13244',
    '13250',
    '13251',
    '13252',
    '13261',
    '13290',
    '13301',
    '13302',
    '13303',
    '13304',
    '13305',
    '13308',
    '13309',
    '13310',
    '13312',
    '13313',
    '13314',
    '13315',
    '13316',
    '13317',
    '13318',
    '13319',
    '13320',
    '13321',
    '13322',
    '13323',
    '13324',
    '13325',
    '13326',
    '13327',
    '13328',
    '13329',
    '13331',
    '13332',
    '13333',
    '13334',
    '13335',
    '13337',
    '13338',
    '13339',
    '13340',
    '13341',
    '13342',
    '13343',
    '13345',
    '13346',
    '13348',
    '13350',
    '13352',
    '13353',
    '13354',
    '13355',
    '13357',
    '13360',
    '13361',
    '13362',
    '13363',
    '13364',
    '13365',
    '13367',
    '13368',
    '13401',
    '13402',
    '13403',
    '13404',
    '13406',
    '13407',
    '13408',
    '13409',
    '13410',
    '13411',
    '13413',
    '13415',
    '13416',
    '13417',
    '13418',
    '13420',
    '13421',
    '13424',
    '13425',
    '13426',
    '13428',
    '13431',
    '13433',
    '13435',
    '13436',
    '13437',
    '13438',
    '13439',
    '13440',
    '13441',
    '13442',
    '13449',
    '13450',
    '13452',
    '13454',
    '13455',
    '13456',
    '13457',
    '13459',
    '13460',
    '13461',
    '13464',
    '13465',
    '13468',
    '13469',
    '13470',
    '13471',
    '13472',
    '13473',
    '13475',
    '13476',
    '13477',
    '13478',
    '13479',
    '13480',
    '13482',
    '13483',
    '13484',
    '13485',
    '13486',
    '13488',
    '13489',
    '13490',
    '13491',
    '13492',
    '13493',
    '13494',
    '13495',
    '13501',
    '13502',
    '13503',
    '13504',
    '13505',
    '13599',
    '13601',
    '13602',
    '13603',
    '13605',
    '13606',
    '13607',
    '13608',
    '13611',
    '13612',
    '13613',
    '13614',
    '13615',
    '13616',
    '13617',
    '13618',
    '13619',
    '13620',
    '13621',
    '13622',
    '13623',
    '13624',
    '13625',
    '13626',
    '13627',
    '13628',
    '13630',
    '13631',
    '13632',
    '13633',
    '13634',
    '13635',
    '13636',
    '13637',
    '13638',
    '13639',
    '13640',
    '13641',
    '13642',
    '13643',
    '13645',
    '13646',
    '13647',
    '13648',
    '13649',
    '13650',
    '13651',
    '13652',
    '13654',
    '13655',
    '13656',
    '13657',
    '13658',
    '13659',
    '13660',
    '13661',
    '13662',
    '13664',
    '13665',
    '13666',
    '13667',
    '13668',
    '13669',
    '13670',
    '13671',
    '13672',
    '13673',
    '13674',
    '13675',
    '13676',
    '13677',
    '13678',
    '13679',
    '13680',
    '13681',
    '13682',
    '13683',
    '13684',
    '13685',
    '13687',
    '13690',
    '13691',
    '13692',
    '13693',
    '13694',
    '13695',
    '13696',
    '13697',
    '13699',
    '13730',
    '13731',
    '13732',
    '13733',
    '13734',
    '13736',
    '13737',
    '13738',
    '13739',
    '13740',
    '13743',
    '13744',
    '13745',
    '13746',
    '13747',
    '13748',
    '13749',
    '13750',
    '13751',
    '13752',
    '13753',
    '13754',
    '13755',
    '13756',
    '13757',
    '13758',
    '13760',
    '13761',
    '13762',
    '13763',
    '13774',
    '13775',
    '13776',
    '13777',
    '13778',
    '13780',
    '13782',
    '13783',
    '13784',
    '13786',
    '13787',
    '13788',
    '13790',
    '13794',
    '13795',
    '13796',
    '13797',
    '13801',
    '13802',
    '13803',
    '13804',
    '13806',
    '13807',
    '13808',
    '13809',
    '13810',
    '13811',
    '13812',
    '13813',
    '13814',
    '13815',
    '13820',
    '13825',
    '13826',
    '13827',
    '13830',
    '13832',
    '13833',
    '13834',
    '13835',
    '13838',
    '13839',
    '13840',
    '13841',
    '13842',
    '13843',
    '13844',
    '13845',
    '13846',
    '13847',
    '13848',
    '13849',
    '13850',
    '13851',
    '13856',
    '13859',
    '13860',
    '13861',
    '13862',
    '13863',
    '13864',
    '13865',
    '13901',
    '13902',
    '13903',
    '13904',
    '13905',
    '14001',
    '14004',
    '14005',
    '14006',
    '14008',
    '14009',
    '14010',
    '14011',
    '14012',
    '14013',
    '14020',
    '14021',
    '14024',
    '14025',
    '14026',
    '14027',
    '14028',
    '14029',
    '14030',
    '14031',
    '14032',
    '14033',
    '14034',
    '14035',
    '14036',
    '14037',
    '14038',
    '14039',
    '14040',
    '14041',
    '14042',
    '14043',
    '14047',
    '14048',
    '14051',
    '14052',
    '14054',
    '14055',
    '14056',
    '14057',
    '14058',
    '14059',
    '14060',
    '14061',
    '14062',
    '14063',
    '14065',
    '14066',
    '14067',
    '14068',
    '14069',
    '14070',
    '14072',
    '14075',
    '14080',
    '14081',
    '14082',
    '14083',
    '14085',
    '14086',
    '14091',
    '14092',
    '14094',
    '14095',
    '14098',
    '14101',
    '14102',
    '14103',
    '14105',
    '14107',
    '14108',
    '14109',
    '14110',
    '14111',
    '14112',
    '14113',
    '14120',
    '14125',
    '14126',
    '14127',
    '14129',
    '14130',
    '14131',
    '14132',
    '14133',
    '14134',
    '14135',
    '14136',
    '14138',
    '14139',
    '14140',
    '14141',
    '14143',
    '14144',
    '14145',
    '14150',
    '14151',
    '14166',
    '14167',
    '14168',
    '14169',
    '14170',
    '14171',
    '14172',
    '14173',
    '14174',
    '14201',
    '14202',
    '14203',
    '14204',
    '14205',
    '14206',
    '14207',
    '14208',
    '14209',
    '14210',
    '14211',
    '14212',
    '14213',
    '14214',
    '14215',
    '14216',
    '14217',
    '14218',
    '14219',
    '14220',
    '14221',
    '14222',
    '14223',
    '14224',
    '14225',
    '14226',
    '14227',
    '14228',
    '14231',
    '14233',
    '14240',
    '14241',
    '14260',
    '14261',
    '14263',
    '14264',
    '14265',
    '14267',
    '14269',
    '14270',
    '14272',
    '14273',
    '14276',
    '14280',
    '14301',
    '14302',
    '14303',
    '14304',
    '14305',
    '14410',
    '14411',
    '14413',
    '14414',
    '14415',
    '14416',
    '14418',
    '14420',
    '14422',
    '14423',
    '14424',
    '14425',
    '14427',
    '14428',
    '14429',
    '14430',
    '14432',
    '14433',
    '14435',
    '14437',
    '14441',
    '14443',
    '14445',
    '14449',
    '14450',
    '14452',
    '14453',
    '14454',
    '14456',
    '14461',
    '14462',
    '14463',
    '14464',
    '14466',
    '14467',
    '14468',
    '14469',
    '14470',
    '14471',
    '14472',
    '14475',
    '14476',
    '14477',
    '14478',
    '14479',
    '14480',
    '14481',
    '14482',
    '14485',
    '14486',
    '14487',
    '14488',
    '14489',
    '14502',
    '14504',
    '14505',
    '14506',
    '14507',
    '14508',
    '14510',
    '14511',
    '14512',
    '14513',
    '14514',
    '14515',
    '14516',
    '14517',
    '14518',
    '14519',
    '14520',
    '14521',
    '14522',
    '14525',
    '14526',
    '14527',
    '14529',
    '14530',
    '14532',
    '14533',
    '14534',
    '14536',
    '14537',
    '14538',
    '14539',
    '14541',
    '14542',
    '14543',
    '14544',
    '14545',
    '14546',
    '14547',
    '14548',
    '14549',
    '14550',
    '14551',
    '14555',
    '14556',
    '14557',
    '14558',
    '14559',
    '14560',
    '14561',
    '14563',
    '14564',
    '14568',
    '14569',
    '14571',
    '14572',
    '14580',
    '14585',
    '14586',
    '14588',
    '14589',
    '14590',
    '14591',
    '14592',
    '14602',
    '14603',
    '14604',
    '14605',
    '14606',
    '14607',
    '14608',
    '14609',
    '14610',
    '14611',
    '14612',
    '14613',
    '14614',
    '14615',
    '14616',
    '14617',
    '14618',
    '14619',
    '14620',
    '14621',
    '14622',
    '14623',
    '14624',
    '14625',
    '14626',
    '14627',
    '14638',
    '14639',
    '14642',
    '14643',
    '14644',
    '14646',
    '14647',
    '14649',
    '14650',
    '14651',
    '14652',
    '14653',
    '14692',
    '14694',
    '14701',
    '14702',
    '14706',
    '14707',
    '14708',
    '14709',
    '14710',
    '14711',
    '14712',
    '14714',
    '14715',
    '14716',
    '14717',
    '14718',
    '14719',
    '14720',
    '14721',
    '14722',
    '14723',
    '14724',
    '14726',
    '14727',
    '14728',
    '14729',
    '14730',
    '14731',
    '14732',
    '14733',
    '14735',
    '14736',
    '14737',
    '14738',
    '14739',
    '14740',
    '14741',
    '14742',
    '14743',
    '14744',
    '14745',
    '14747',
    '14748',
    '14750',
    '14751',
    '14752',
    '14753',
    '14754',
    '14755',
    '14756',
    '14757',
    '14758',
    '14760',
    '14766',
    '14767',
    '14769',
    '14770',
    '14772',
    '14774',
    '14775',
    '14777',
    '14778',
    '14779',
    '14781',
    '14782',
    '14783',
    '14784',
    '14785',
    '14786',
    '14787',
    '14788',
    '14801',
    '14802',
    '14803',
    '14804',
    '14805',
    '14806',
    '14807',
    '14808',
    '14809',
    '14810',
    '14812',
    '14813',
    '14814',
    '14815',
    '14816',
    '14817',
    '14818',
    '14819',
    '14820',
    '14821',
    '14822',
    '14823',
    '14824',
    '14825',
    '14826',
    '14827',
    '14830',
    '14831',
    '14836',
    '14837',
    '14838',
    '14839',
    '14840',
    '14841',
    '14842',
    '14843',
    '14845',
    '14846',
    '14847',
    '14850',
    '14851',
    '14852',
    '14853',
    '14854',
    '14855',
    '14856',
    '14857',
    '14858',
    '14859',
    '14860',
    '14861',
    '14863',
    '14864',
    '14865',
    '14867',
    '14869',
    '14870',
    '14871',
    '14872',
    '14873',
    '14874',
    '14876',
    '14877',
    '14878',
    '14879',
    '14880',
    '14881',
    '14882',
    '14883',
    '14884',
    '14885',
    '14886',
    '14887',
    '14889',
    '14891',
    '14892',
    '14893',
    '14894',
    '14895',
    '14897',
    '14898',
    '14901',
    '14902',
    '14903',
    '14904',
    '14905',
    '00501',
    '00544',
    '06390',
  ],
  PA: [
    '15001',
    '15003',
    '15004',
    '15005',
    '15006',
    '15007',
    '15009',
    '15010',
    '15012',
    '15014',
    '15015',
    '15017',
    '15018',
    '15019',
    '15020',
    '15021',
    '15022',
    '15024',
    '15025',
    '15026',
    '15027',
    '15028',
    '15030',
    '15031',
    '15032',
    '15033',
    '15034',
    '15035',
    '15037',
    '15038',
    '15042',
    '15043',
    '15044',
    '15045',
    '15046',
    '15047',
    '15049',
    '15050',
    '15051',
    '15052',
    '15053',
    '15054',
    '15055',
    '15056',
    '15057',
    '15059',
    '15060',
    '15061',
    '15062',
    '15063',
    '15064',
    '15065',
    '15066',
    '15067',
    '15068',
    '15069',
    '15071',
    '15072',
    '15074',
    '15075',
    '15076',
    '15077',
    '15078',
    '15081',
    '15082',
    '15083',
    '15084',
    '15085',
    '15086',
    '15087',
    '15088',
    '15089',
    '15090',
    '15091',
    '15095',
    '15096',
    '15101',
    '15102',
    '15104',
    '15106',
    '15108',
    '15110',
    '15112',
    '15116',
    '15120',
    '15122',
    '15123',
    '15126',
    '15127',
    '15129',
    '15131',
    '15132',
    '15133',
    '15134',
    '15135',
    '15136',
    '15137',
    '15139',
    '15140',
    '15142',
    '15143',
    '15144',
    '15145',
    '15146',
    '15147',
    '15148',
    '15201',
    '15202',
    '15203',
    '15204',
    '15205',
    '15206',
    '15207',
    '15208',
    '15209',
    '15210',
    '15211',
    '15212',
    '15213',
    '15214',
    '15215',
    '15216',
    '15217',
    '15218',
    '15219',
    '15220',
    '15221',
    '15222',
    '15223',
    '15224',
    '15225',
    '15226',
    '15227',
    '15228',
    '15229',
    '15230',
    '15231',
    '15232',
    '15233',
    '15234',
    '15235',
    '15236',
    '15237',
    '15238',
    '15239',
    '15240',
    '15241',
    '15242',
    '15243',
    '15244',
    '15250',
    '15251',
    '15252',
    '15253',
    '15254',
    '15255',
    '15257',
    '15258',
    '15259',
    '15260',
    '15261',
    '15262',
    '15264',
    '15265',
    '15267',
    '15268',
    '15270',
    '15272',
    '15274',
    '15275',
    '15276',
    '15277',
    '15278',
    '15279',
    '15281',
    '15282',
    '15283',
    '15286',
    '15289',
    '15290',
    '15295',
    '15301',
    '15310',
    '15311',
    '15312',
    '15313',
    '15314',
    '15315',
    '15316',
    '15317',
    '15320',
    '15321',
    '15322',
    '15323',
    '15324',
    '15325',
    '15327',
    '15329',
    '15330',
    '15331',
    '15332',
    '15333',
    '15334',
    '15336',
    '15337',
    '15338',
    '15339',
    '15340',
    '15341',
    '15342',
    '15344',
    '15345',
    '15346',
    '15347',
    '15348',
    '15349',
    '15350',
    '15351',
    '15352',
    '15353',
    '15357',
    '15358',
    '15359',
    '15360',
    '15361',
    '15362',
    '15363',
    '15364',
    '15365',
    '15366',
    '15367',
    '15368',
    '15370',
    '15376',
    '15377',
    '15378',
    '15379',
    '15380',
    '15401',
    '15410',
    '15411',
    '15412',
    '15413',
    '15415',
    '15416',
    '15417',
    '15419',
    '15420',
    '15421',
    '15422',
    '15423',
    '15424',
    '15425',
    '15427',
    '15428',
    '15429',
    '15430',
    '15431',
    '15432',
    '15433',
    '15434',
    '15435',
    '15436',
    '15437',
    '15438',
    '15439',
    '15440',
    '15442',
    '15443',
    '15444',
    '15445',
    '15446',
    '15447',
    '15448',
    '15449',
    '15450',
    '15451',
    '15454',
    '15455',
    '15456',
    '15458',
    '15459',
    '15460',
    '15461',
    '15462',
    '15463',
    '15464',
    '15465',
    '15466',
    '15467',
    '15468',
    '15469',
    '15470',
    '15472',
    '15473',
    '15474',
    '15475',
    '15476',
    '15477',
    '15478',
    '15479',
    '15480',
    '15482',
    '15483',
    '15484',
    '15485',
    '15486',
    '15488',
    '15489',
    '15490',
    '15492',
    '15501',
    '15502',
    '15510',
    '15520',
    '15521',
    '15522',
    '15530',
    '15531',
    '15532',
    '15533',
    '15534',
    '15535',
    '15536',
    '15537',
    '15538',
    '15539',
    '15540',
    '15541',
    '15542',
    '15544',
    '15545',
    '15546',
    '15547',
    '15548',
    '15549',
    '15550',
    '15551',
    '15552',
    '15553',
    '15554',
    '15555',
    '15557',
    '15558',
    '15559',
    '15560',
    '15561',
    '15562',
    '15563',
    '15564',
    '15565',
    '15601',
    '15605',
    '15606',
    '15610',
    '15611',
    '15612',
    '15613',
    '15615',
    '15616',
    '15617',
    '15618',
    '15619',
    '15620',
    '15621',
    '15622',
    '15623',
    '15624',
    '15625',
    '15626',
    '15627',
    '15628',
    '15629',
    '15631',
    '15632',
    '15633',
    '15634',
    '15635',
    '15636',
    '15637',
    '15638',
    '15639',
    '15640',
    '15641',
    '15642',
    '15644',
    '15646',
    '15647',
    '15650',
    '15655',
    '15656',
    '15658',
    '15660',
    '15661',
    '15662',
    '15663',
    '15664',
    '15665',
    '15666',
    '15668',
    '15670',
    '15671',
    '15672',
    '15673',
    '15674',
    '15675',
    '15676',
    '15677',
    '15678',
    '15679',
    '15680',
    '15681',
    '15682',
    '15683',
    '15684',
    '15685',
    '15686',
    '15687',
    '15688',
    '15689',
    '15690',
    '15691',
    '15692',
    '15693',
    '15695',
    '15696',
    '15697',
    '15698',
    '15701',
    '15705',
    '15710',
    '15711',
    '15712',
    '15713',
    '15714',
    '15715',
    '15716',
    '15717',
    '15720',
    '15721',
    '15722',
    '15723',
    '15724',
    '15725',
    '15727',
    '15728',
    '15729',
    '15730',
    '15731',
    '15732',
    '15733',
    '15734',
    '15736',
    '15737',
    '15738',
    '15739',
    '15741',
    '15742',
    '15744',
    '15745',
    '15746',
    '15747',
    '15748',
    '15750',
    '15752',
    '15753',
    '15754',
    '15756',
    '15757',
    '15758',
    '15759',
    '15760',
    '15761',
    '15762',
    '15763',
    '15764',
    '15765',
    '15767',
    '15770',
    '15771',
    '15772',
    '15773',
    '15774',
    '15775',
    '15776',
    '15777',
    '15778',
    '15779',
    '15780',
    '15781',
    '15783',
    '15784',
    '15801',
    '15821',
    '15822',
    '15823',
    '15824',
    '15825',
    '15827',
    '15828',
    '15829',
    '15831',
    '15832',
    '15834',
    '15840',
    '15841',
    '15845',
    '15846',
    '15847',
    '15848',
    '15849',
    '15851',
    '15853',
    '15856',
    '15857',
    '15860',
    '15861',
    '15863',
    '15864',
    '15865',
    '15866',
    '15868',
    '15870',
    '15901',
    '15902',
    '15904',
    '15905',
    '15906',
    '15907',
    '15909',
    '15915',
    '15920',
    '15921',
    '15922',
    '15923',
    '15924',
    '15925',
    '15926',
    '15927',
    '15928',
    '15929',
    '15930',
    '15931',
    '15934',
    '15935',
    '15936',
    '15937',
    '15938',
    '15940',
    '15942',
    '15943',
    '15944',
    '15945',
    '15946',
    '15948',
    '15949',
    '15951',
    '15952',
    '15953',
    '15954',
    '15955',
    '15956',
    '15957',
    '15958',
    '15959',
    '15960',
    '15961',
    '15962',
    '15963',
    '16001',
    '16002',
    '16003',
    '16016',
    '16017',
    '16018',
    '16020',
    '16021',
    '16022',
    '16023',
    '16024',
    '16025',
    '16027',
    '16028',
    '16029',
    '16030',
    '16033',
    '16034',
    '16035',
    '16036',
    '16037',
    '16038',
    '16039',
    '16040',
    '16041',
    '16045',
    '16046',
    '16048',
    '16049',
    '16050',
    '16051',
    '16052',
    '16053',
    '16054',
    '16055',
    '16056',
    '16057',
    '16058',
    '16059',
    '16061',
    '16063',
    '16066',
    '16101',
    '16102',
    '16103',
    '16105',
    '16107',
    '16108',
    '16110',
    '16111',
    '16112',
    '16113',
    '16114',
    '16115',
    '16116',
    '16117',
    '16120',
    '16121',
    '16123',
    '16124',
    '16125',
    '16127',
    '16130',
    '16131',
    '16132',
    '16133',
    '16134',
    '16136',
    '16137',
    '16140',
    '16141',
    '16142',
    '16143',
    '16145',
    '16146',
    '16148',
    '16150',
    '16151',
    '16153',
    '16154',
    '16155',
    '16156',
    '16157',
    '16159',
    '16160',
    '16161',
    '16172',
    '16201',
    '16210',
    '16211',
    '16212',
    '16213',
    '16214',
    '16217',
    '16218',
    '16220',
    '16221',
    '16222',
    '16223',
    '16224',
    '16225',
    '16226',
    '16228',
    '16229',
    '16230',
    '16232',
    '16233',
    '16234',
    '16235',
    '16236',
    '16238',
    '16239',
    '16240',
    '16242',
    '16244',
    '16245',
    '16246',
    '16248',
    '16249',
    '16250',
    '16253',
    '16254',
    '16255',
    '16256',
    '16257',
    '16258',
    '16259',
    '16260',
    '16261',
    '16262',
    '16263',
    '16301',
    '16311',
    '16312',
    '16313',
    '16314',
    '16316',
    '16317',
    '16319',
    '16321',
    '16322',
    '16323',
    '16326',
    '16327',
    '16328',
    '16329',
    '16331',
    '16332',
    '16333',
    '16334',
    '16335',
    '16340',
    '16341',
    '16342',
    '16343',
    '16344',
    '16345',
    '16346',
    '16347',
    '16350',
    '16351',
    '16352',
    '16353',
    '16354',
    '16360',
    '16361',
    '16362',
    '16364',
    '16365',
    '16366',
    '16367',
    '16368',
    '16369',
    '16370',
    '16371',
    '16372',
    '16373',
    '16374',
    '16375',
    '16388',
    '16401',
    '16402',
    '16403',
    '16404',
    '16405',
    '16406',
    '16407',
    '16410',
    '16411',
    '16412',
    '16413',
    '16415',
    '16416',
    '16417',
    '16420',
    '16421',
    '16422',
    '16423',
    '16424',
    '16426',
    '16427',
    '16428',
    '16430',
    '16432',
    '16433',
    '16434',
    '16435',
    '16436',
    '16438',
    '16440',
    '16441',
    '16442',
    '16443',
    '16444',
    '16475',
    '16501',
    '16502',
    '16503',
    '16504',
    '16505',
    '16506',
    '16507',
    '16508',
    '16509',
    '16510',
    '16511',
    '16512',
    '16514',
    '16515',
    '16522',
    '16530',
    '16531',
    '16534',
    '16538',
    '16541',
    '16544',
    '16546',
    '16550',
    '16553',
    '16563',
    '16565',
    '16601',
    '16602',
    '16603',
    '16611',
    '16613',
    '16616',
    '16617',
    '16619',
    '16620',
    '16621',
    '16622',
    '16623',
    '16624',
    '16625',
    '16627',
    '16629',
    '16630',
    '16631',
    '16633',
    '16634',
    '16635',
    '16636',
    '16637',
    '16638',
    '16639',
    '16640',
    '16641',
    '16644',
    '16645',
    '16646',
    '16647',
    '16648',
    '16650',
    '16651',
    '16652',
    '16654',
    '16655',
    '16656',
    '16657',
    '16659',
    '16660',
    '16661',
    '16662',
    '16663',
    '16664',
    '16665',
    '16666',
    '16667',
    '16668',
    '16669',
    '16670',
    '16671',
    '16672',
    '16673',
    '16674',
    '16675',
    '16677',
    '16678',
    '16679',
    '16680',
    '16681',
    '16682',
    '16683',
    '16684',
    '16685',
    '16686',
    '16689',
    '16691',
    '16692',
    '16693',
    '16694',
    '16695',
    '16698',
    '16699',
    '16701',
    '16720',
    '16724',
    '16725',
    '16726',
    '16727',
    '16728',
    '16729',
    '16730',
    '16731',
    '16732',
    '16733',
    '16734',
    '16735',
    '16738',
    '16740',
    '16743',
    '16744',
    '16745',
    '16746',
    '16748',
    '16749',
    '16750',
    '16801',
    '16802',
    '16803',
    '16804',
    '16805',
    '16820',
    '16821',
    '16822',
    '16823',
    '16825',
    '16826',
    '16827',
    '16828',
    '16829',
    '16830',
    '16832',
    '16833',
    '16834',
    '16835',
    '16836',
    '16837',
    '16838',
    '16839',
    '16840',
    '16841',
    '16843',
    '16844',
    '16845',
    '16847',
    '16848',
    '16849',
    '16850',
    '16851',
    '16852',
    '16853',
    '16854',
    '16855',
    '16856',
    '16858',
    '16859',
    '16860',
    '16861',
    '16863',
    '16864',
    '16865',
    '16866',
    '16868',
    '16870',
    '16871',
    '16872',
    '16873',
    '16874',
    '16875',
    '16876',
    '16877',
    '16878',
    '16879',
    '16881',
    '16882',
    '16901',
    '16910',
    '16911',
    '16912',
    '16914',
    '16915',
    '16917',
    '16920',
    '16921',
    '16922',
    '16923',
    '16925',
    '16926',
    '16927',
    '16928',
    '16929',
    '16930',
    '16932',
    '16933',
    '16935',
    '16936',
    '16937',
    '16938',
    '16939',
    '16940',
    '16941',
    '16942',
    '16943',
    '16945',
    '16946',
    '16947',
    '16948',
    '16950',
    '17001',
    '17002',
    '17003',
    '17004',
    '17005',
    '17006',
    '17007',
    '17009',
    '17010',
    '17011',
    '17013',
    '17014',
    '17015',
    '17016',
    '17017',
    '17018',
    '17019',
    '17020',
    '17021',
    '17022',
    '17023',
    '17024',
    '17025',
    '17026',
    '17027',
    '17028',
    '17029',
    '17030',
    '17032',
    '17033',
    '17034',
    '17035',
    '17036',
    '17037',
    '17038',
    '17039',
    '17040',
    '17041',
    '17042',
    '17043',
    '17044',
    '17045',
    '17046',
    '17047',
    '17048',
    '17049',
    '17050',
    '17051',
    '17052',
    '17053',
    '17054',
    '17055',
    '17056',
    '17057',
    '17058',
    '17059',
    '17060',
    '17061',
    '17062',
    '17063',
    '17064',
    '17065',
    '17066',
    '17067',
    '17068',
    '17069',
    '17070',
    '17071',
    '17072',
    '17073',
    '17074',
    '17075',
    '17076',
    '17077',
    '17078',
    '17080',
    '17081',
    '17082',
    '17083',
    '17084',
    '17085',
    '17086',
    '17087',
    '17088',
    '17089',
    '17090',
    '17093',
    '17094',
    '17097',
    '17098',
    '17099',
    '17101',
    '17102',
    '17103',
    '17104',
    '17105',
    '17106',
    '17107',
    '17108',
    '17109',
    '17110',
    '17111',
    '17112',
    '17113',
    '17120',
    '17121',
    '17122',
    '17123',
    '17124',
    '17125',
    '17126',
    '17127',
    '17128',
    '17129',
    '17130',
    '17140',
    '17177',
    '17201',
    '17202',
    '17210',
    '17211',
    '17212',
    '17213',
    '17214',
    '17215',
    '17217',
    '17219',
    '17220',
    '17221',
    '17222',
    '17223',
    '17224',
    '17225',
    '17228',
    '17229',
    '17231',
    '17232',
    '17233',
    '17235',
    '17236',
    '17237',
    '17238',
    '17239',
    '17240',
    '17241',
    '17243',
    '17244',
    '17246',
    '17247',
    '17249',
    '17250',
    '17251',
    '17252',
    '17253',
    '17254',
    '17255',
    '17256',
    '17257',
    '17260',
    '17261',
    '17262',
    '17263',
    '17264',
    '17265',
    '17266',
    '17267',
    '17268',
    '17271',
    '17272',
    '17301',
    '17302',
    '17303',
    '17304',
    '17306',
    '17307',
    '17309',
    '17310',
    '17311',
    '17312',
    '17313',
    '17314',
    '17315',
    '17316',
    '17317',
    '17318',
    '17319',
    '17320',
    '17321',
    '17322',
    '17323',
    '17324',
    '17325',
    '17327',
    '17329',
    '17331',
    '17332',
    '17333',
    '17334',
    '17335',
    '17337',
    '17339',
    '17340',
    '17342',
    '17343',
    '17344',
    '17345',
    '17347',
    '17349',
    '17350',
    '17352',
    '17353',
    '17355',
    '17356',
    '17358',
    '17360',
    '17361',
    '17362',
    '17363',
    '17364',
    '17365',
    '17366',
    '17368',
    '17370',
    '17371',
    '17372',
    '17375',
    '17401',
    '17402',
    '17403',
    '17404',
    '17405',
    '17406',
    '17407',
    '17408',
    '17501',
    '17502',
    '17503',
    '17504',
    '17505',
    '17506',
    '17507',
    '17508',
    '17509',
    '17512',
    '17516',
    '17517',
    '17518',
    '17519',
    '17520',
    '17521',
    '17522',
    '17527',
    '17528',
    '17529',
    '17532',
    '17533',
    '17534',
    '17535',
    '17536',
    '17537',
    '17538',
    '17540',
    '17543',
    '17545',
    '17547',
    '17549',
    '17550',
    '17551',
    '17552',
    '17554',
    '17555',
    '17557',
    '17560',
    '17562',
    '17563',
    '17564',
    '17565',
    '17566',
    '17567',
    '17568',
    '17569',
    '17570',
    '17572',
    '17573',
    '17575',
    '17576',
    '17578',
    '17579',
    '17580',
    '17581',
    '17582',
    '17583',
    '17584',
    '17585',
    '17601',
    '17602',
    '17603',
    '17604',
    '17605',
    '17606',
    '17607',
    '17608',
    '17611',
    '17622',
    '17699',
    '17701',
    '17702',
    '17703',
    '17705',
    '17720',
    '17721',
    '17723',
    '17724',
    '17726',
    '17727',
    '17728',
    '17729',
    '17730',
    '17731',
    '17735',
    '17737',
    '17739',
    '17740',
    '17742',
    '17744',
    '17745',
    '17747',
    '17748',
    '17749',
    '17750',
    '17751',
    '17752',
    '17754',
    '17756',
    '17758',
    '17760',
    '17762',
    '17763',
    '17764',
    '17765',
    '17768',
    '17769',
    '17771',
    '17772',
    '17774',
    '17776',
    '17777',
    '17778',
    '17779',
    '17801',
    '17810',
    '17812',
    '17813',
    '17814',
    '17815',
    '17820',
    '17821',
    '17822',
    '17823',
    '17824',
    '17827',
    '17829',
    '17830',
    '17831',
    '17832',
    '17833',
    '17834',
    '17835',
    '17836',
    '17837',
    '17840',
    '17841',
    '17842',
    '17843',
    '17844',
    '17845',
    '17846',
    '17847',
    '17850',
    '17851',
    '17853',
    '17855',
    '17856',
    '17857',
    '17858',
    '17859',
    '17860',
    '17861',
    '17862',
    '17864',
    '17865',
    '17866',
    '17867',
    '17868',
    '17870',
    '17872',
    '17876',
    '17877',
    '17878',
    '17880',
    '17881',
    '17882',
    '17883',
    '17884',
    '17885',
    '17886',
    '17887',
    '17888',
    '17889',
    '17901',
    '17920',
    '17921',
    '17922',
    '17923',
    '17925',
    '17929',
    '17930',
    '17931',
    '17932',
    '17933',
    '17934',
    '17935',
    '17936',
    '17938',
    '17941',
    '17943',
    '17944',
    '17945',
    '17946',
    '17948',
    '17949',
    '17951',
    '17952',
    '17953',
    '17954',
    '17957',
    '17959',
    '17960',
    '17961',
    '17963',
    '17964',
    '17965',
    '17966',
    '17967',
    '17968',
    '17970',
    '17972',
    '17974',
    '17976',
    '17978',
    '17979',
    '17980',
    '17981',
    '17982',
    '17983',
    '17985',
    '18001',
    '18002',
    '18003',
    '18011',
    '18012',
    '18013',
    '18014',
    '18015',
    '18016',
    '18017',
    '18018',
    '18020',
    '18025',
    '18030',
    '18031',
    '18032',
    '18034',
    '18035',
    '18036',
    '18037',
    '18038',
    '18039',
    '18040',
    '18041',
    '18042',
    '18043',
    '18044',
    '18045',
    '18046',
    '18049',
    '18051',
    '18052',
    '18053',
    '18054',
    '18055',
    '18056',
    '18058',
    '18059',
    '18060',
    '18062',
    '18063',
    '18064',
    '18065',
    '18066',
    '18067',
    '18068',
    '18069',
    '18070',
    '18071',
    '18072',
    '18073',
    '18074',
    '18076',
    '18077',
    '18078',
    '18079',
    '18080',
    '18081',
    '18083',
    '18084',
    '18085',
    '18086',
    '18087',
    '18088',
    '18091',
    '18092',
    '18098',
    '18099',
    '18101',
    '18102',
    '18103',
    '18104',
    '18105',
    '18106',
    '18109',
    '18195',
    '18201',
    '18202',
    '18210',
    '18211',
    '18212',
    '18214',
    '18216',
    '18218',
    '18219',
    '18220',
    '18221',
    '18222',
    '18223',
    '18224',
    '18225',
    '18229',
    '18230',
    '18231',
    '18232',
    '18234',
    '18235',
    '18237',
    '18239',
    '18240',
    '18241',
    '18242',
    '18244',
    '18245',
    '18246',
    '18247',
    '18248',
    '18249',
    '18250',
    '18251',
    '18252',
    '18254',
    '18255',
    '18256',
    '18301',
    '18302',
    '18320',
    '18321',
    '18322',
    '18323',
    '18324',
    '18325',
    '18326',
    '18327',
    '18328',
    '18330',
    '18331',
    '18332',
    '18333',
    '18334',
    '18335',
    '18336',
    '18337',
    '18340',
    '18341',
    '18342',
    '18343',
    '18344',
    '18346',
    '18347',
    '18348',
    '18349',
    '18350',
    '18351',
    '18352',
    '18353',
    '18354',
    '18355',
    '18356',
    '18357',
    '18360',
    '18370',
    '18371',
    '18372',
    '18403',
    '18405',
    '18407',
    '18410',
    '18411',
    '18413',
    '18414',
    '18415',
    '18416',
    '18417',
    '18419',
    '18420',
    '18421',
    '18424',
    '18425',
    '18426',
    '18427',
    '18428',
    '18430',
    '18431',
    '18433',
    '18434',
    '18435',
    '18436',
    '18437',
    '18438',
    '18439',
    '18440',
    '18441',
    '18443',
    '18444',
    '18445',
    '18446',
    '18447',
    '18448',
    '18449',
    '18451',
    '18452',
    '18453',
    '18454',
    '18455',
    '18456',
    '18457',
    '18458',
    '18459',
    '18460',
    '18461',
    '18462',
    '18463',
    '18464',
    '18465',
    '18466',
    '18469',
    '18470',
    '18471',
    '18472',
    '18473',
    '18501',
    '18502',
    '18503',
    '18504',
    '18505',
    '18507',
    '18508',
    '18509',
    '18510',
    '18512',
    '18515',
    '18517',
    '18518',
    '18519',
    '18540',
    '18577',
    '18601',
    '18602',
    '18603',
    '18610',
    '18611',
    '18612',
    '18614',
    '18615',
    '18616',
    '18617',
    '18618',
    '18619',
    '18621',
    '18622',
    '18623',
    '18624',
    '18625',
    '18626',
    '18627',
    '18628',
    '18629',
    '18630',
    '18631',
    '18632',
    '18634',
    '18635',
    '18636',
    '18640',
    '18641',
    '18642',
    '18643',
    '18644',
    '18651',
    '18653',
    '18654',
    '18655',
    '18656',
    '18657',
    '18660',
    '18661',
    '18690',
    '18701',
    '18702',
    '18703',
    '18704',
    '18705',
    '18706',
    '18707',
    '18708',
    '18709',
    '18710',
    '18711',
    '18762',
    '18764',
    '18765',
    '18766',
    '18767',
    '18769',
    '18773',
    '18801',
    '18810',
    '18812',
    '18813',
    '18814',
    '18815',
    '18816',
    '18817',
    '18818',
    '18820',
    '18821',
    '18822',
    '18823',
    '18824',
    '18825',
    '18826',
    '18827',
    '18828',
    '18829',
    '18830',
    '18831',
    '18832',
    '18833',
    '18834',
    '18837',
    '18840',
    '18842',
    '18843',
    '18844',
    '18845',
    '18846',
    '18847',
    '18848',
    '18850',
    '18851',
    '18853',
    '18854',
    '18901',
    '18902',
    '18910',
    '18911',
    '18912',
    '18913',
    '18914',
    '18915',
    '18916',
    '18917',
    '18918',
    '18920',
    '18921',
    '18922',
    '18923',
    '18925',
    '18927',
    '18928',
    '18929',
    '18930',
    '18931',
    '18932',
    '18933',
    '18934',
    '18935',
    '18936',
    '18938',
    '18940',
    '18942',
    '18943',
    '18944',
    '18946',
    '18947',
    '18949',
    '18950',
    '18951',
    '18953',
    '18954',
    '18955',
    '18956',
    '18957',
    '18958',
    '18960',
    '18962',
    '18963',
    '18964',
    '18966',
    '18968',
    '18969',
    '18970',
    '18971',
    '18972',
    '18974',
    '18976',
    '18977',
    '18979',
    '18980',
    '18981',
    '18991',
    '19001',
    '19002',
    '19003',
    '19004',
    '19006',
    '19007',
    '19008',
    '19009',
    '19010',
    '19012',
    '19013',
    '19014',
    '19015',
    '19016',
    '19017',
    '19018',
    '19019',
    '19020',
    '19021',
    '19022',
    '19023',
    '19025',
    '19026',
    '19027',
    '19028',
    '19029',
    '19030',
    '19031',
    '19032',
    '19033',
    '19034',
    '19035',
    '19036',
    '19037',
    '19038',
    '19039',
    '19040',
    '19041',
    '19043',
    '19044',
    '19046',
    '19047',
    '19048',
    '19049',
    '19050',
    '19052',
    '19053',
    '19054',
    '19055',
    '19056',
    '19057',
    '19058',
    '19060',
    '19061',
    '19063',
    '19064',
    '19065',
    '19066',
    '19067',
    '19070',
    '19072',
    '19073',
    '19074',
    '19075',
    '19076',
    '19078',
    '19079',
    '19080',
    '19081',
    '19082',
    '19083',
    '19085',
    '19086',
    '19087',
    '19088',
    '19089',
    '19090',
    '19091',
    '19092',
    '19093',
    '19094',
    '19095',
    '19096',
    '19098',
    '19099',
    '19101',
    '19102',
    '19103',
    '19104',
    '19105',
    '19106',
    '19107',
    '19108',
    '19109',
    '19110',
    '19111',
    '19112',
    '19113',
    '19114',
    '19115',
    '19116',
    '19118',
    '19119',
    '19120',
    '19121',
    '19122',
    '19123',
    '19124',
    '19125',
    '19126',
    '19127',
    '19128',
    '19129',
    '19130',
    '19131',
    '19132',
    '19133',
    '19134',
    '19135',
    '19136',
    '19137',
    '19138',
    '19139',
    '19140',
    '19141',
    '19142',
    '19143',
    '19144',
    '19145',
    '19146',
    '19147',
    '19148',
    '19149',
    '19150',
    '19151',
    '19152',
    '19153',
    '19154',
    '19155',
    '19160',
    '19161',
    '19162',
    '19170',
    '19171',
    '19172',
    '19173',
    '19175',
    '19176',
    '19177',
    '19178',
    '19179',
    '19181',
    '19182',
    '19183',
    '19184',
    '19185',
    '19187',
    '19188',
    '19190',
    '19191',
    '19192',
    '19193',
    '19194',
    '19195',
    '19196',
    '19197',
    '19244',
    '19255',
    '19301',
    '19310',
    '19311',
    '19312',
    '19316',
    '19317',
    '19318',
    '19319',
    '19320',
    '19330',
    '19331',
    '19333',
    '19335',
    '19339',
    '19340',
    '19341',
    '19342',
    '19343',
    '19344',
    '19345',
    '19346',
    '19347',
    '19348',
    '19350',
    '19351',
    '19352',
    '19353',
    '19354',
    '19355',
    '19357',
    '19358',
    '19360',
    '19362',
    '19363',
    '19365',
    '19366',
    '19367',
    '19369',
    '19371',
    '19372',
    '19373',
    '19374',
    '19375',
    '19376',
    '19380',
    '19381',
    '19382',
    '19383',
    '19390',
    '19395',
    '19397',
    '19398',
    '19399',
    '19401',
    '19403',
    '19404',
    '19405',
    '19406',
    '19407',
    '19408',
    '19409',
    '19415',
    '19421',
    '19422',
    '19423',
    '19424',
    '19425',
    '19426',
    '19428',
    '19429',
    '19430',
    '19432',
    '19435',
    '19436',
    '19437',
    '19438',
    '19440',
    '19441',
    '19442',
    '19443',
    '19444',
    '19446',
    '19450',
    '19451',
    '19453',
    '19454',
    '19455',
    '19456',
    '19457',
    '19460',
    '19462',
    '19464',
    '19465',
    '19468',
    '19470',
    '19472',
    '19473',
    '19474',
    '19475',
    '19477',
    '19478',
    '19480',
    '19481',
    '19482',
    '19484',
    '19486',
    '19490',
    '19492',
    '19493',
    '19494',
    '19495',
    '19496',
    '19501',
    '19503',
    '19504',
    '19505',
    '19506',
    '19507',
    '19508',
    '19510',
    '19511',
    '19512',
    '19516',
    '19518',
    '19519',
    '19520',
    '19522',
    '19523',
    '19525',
    '19526',
    '19529',
    '19530',
    '19533',
    '19534',
    '19535',
    '19536',
    '19538',
    '19539',
    '19540',
    '19541',
    '19543',
    '19544',
    '19545',
    '19547',
    '19548',
    '19549',
    '19550',
    '19551',
    '19554',
    '19555',
    '19559',
    '19560',
    '19562',
    '19564',
    '19565',
    '19567',
    '19601',
    '19602',
    '19603',
    '19604',
    '19605',
    '19606',
    '19607',
    '19608',
    '19609',
    '19610',
    '19611',
    '19612',
  ],
  DE: [
    '19701',
    '19702',
    '19703',
    '19706',
    '19707',
    '19708',
    '19709',
    '19710',
    '19711',
    '19712',
    '19713',
    '19714',
    '19715',
    '19716',
    '19717',
    '19718',
    '19720',
    '19721',
    '19725',
    '19726',
    '19730',
    '19731',
    '19732',
    '19733',
    '19734',
    '19735',
    '19736',
    '19801',
    '19802',
    '19803',
    '19804',
    '19805',
    '19806',
    '19807',
    '19808',
    '19809',
    '19810',
    '19850',
    '19880',
    '19884',
    '19885',
    '19886',
    '19890',
    '19891',
    '19892',
    '19893',
    '19894',
    '19895',
    '19896',
    '19897',
    '19898',
    '19899',
    '19901',
    '19902',
    '19903',
    '19904',
    '19905',
    '19906',
    '19930',
    '19931',
    '19933',
    '19934',
    '19936',
    '19938',
    '19939',
    '19940',
    '19941',
    '19943',
    '19944',
    '19945',
    '19946',
    '19947',
    '19950',
    '19951',
    '19952',
    '19953',
    '19954',
    '19955',
    '19956',
    '19958',
    '19960',
    '19961',
    '19962',
    '19963',
    '19964',
    '19966',
    '19967',
    '19968',
    '19969',
    '19970',
    '19971',
    '19973',
    '19975',
    '19977',
    '19979',
    '19980',
  ],
  DC: [
    '20001',
    '20002',
    '20003',
    '20004',
    '20005',
    '20006',
    '20007',
    '20008',
    '20009',
    '20010',
    '20011',
    '20012',
    '20013',
    '20015',
    '20016',
    '20017',
    '20018',
    '20019',
    '20020',
    '20022',
    '20024',
    '20026',
    '20027',
    '20029',
    '20030',
    '20032',
    '20033',
    '20035',
    '20036',
    '20037',
    '20038',
    '20039',
    '20040',
    '20041',
    '20042',
    '20043',
    '20044',
    '20045',
    '20047',
    '20049',
    '20050',
    '20052',
    '20053',
    '20055',
    '20056',
    '20057',
    '20058',
    '20059',
    '20060',
    '20061',
    '20062',
    '20063',
    '20064',
    '20065',
    '20066',
    '20067',
    '20068',
    '20069',
    '20070',
    '20071',
    '20073',
    '20074',
    '20075',
    '20076',
    '20077',
    '20078',
    '20080',
    '20081',
    '20082',
    '20090',
    '20091',
    '20201',
    '20202',
    '20203',
    '20204',
    '20206',
    '20207',
    '20208',
    '20210',
    '20211',
    '20212',
    '20213',
    '20214',
    '20215',
    '20216',
    '20217',
    '20218',
    '20219',
    '20220',
    '20221',
    '20222',
    '20223',
    '20224',
    '20226',
    '20227',
    '20228',
    '20229',
    '20230',
    '20232',
    '20233',
    '20235',
    '20237',
    '20238',
    '20239',
    '20240',
    '20241',
    '20242',
    '20244',
    '20245',
    '20250',
    '20251',
    '20252',
    '20254',
    '20260',
    '20261',
    '20262',
    '20265',
    '20266',
    '20268',
    '20270',
    '20277',
    '20289',
    '20299',
    '20301',
    '20303',
    '20306',
    '20310',
    '20314',
    '20317',
    '20318',
    '20319',
    '20330',
    '20340',
    '20350',
    '20355',
    '20370',
    '20372',
    '20373',
    '20374',
    '20375',
    '20376',
    '20380',
    '20388',
    '20389',
    '20390',
    '20391',
    '20392',
    '20393',
    '20394',
    '20395',
    '20398',
    '20401',
    '20402',
    '20403',
    '20404',
    '20405',
    '20406',
    '20407',
    '20408',
    '20410',
    '20411',
    '20412',
    '20413',
    '20414',
    '20415',
    '20416',
    '20417',
    '20418',
    '20419',
    '20420',
    '20421',
    '20422',
    '20423',
    '20424',
    '20425',
    '20426',
    '20427',
    '20428',
    '20429',
    '20431',
    '20433',
    '20434',
    '20435',
    '20436',
    '20437',
    '20439',
    '20440',
    '20441',
    '20442',
    '20444',
    '20447',
    '20451',
    '20453',
    '20456',
    '20460',
    '20463',
    '20468',
    '20469',
    '20470',
    '20472',
    '20500',
    '20501',
    '20502',
    '20503',
    '20504',
    '20505',
    '20506',
    '20507',
    '20508',
    '20509',
    '20510',
    '20511',
    '20515',
    '20520',
    '20521',
    '20522',
    '20523',
    '20524',
    '20525',
    '20526',
    '20527',
    '20528',
    '20529',
    '20530',
    '20531',
    '20533',
    '20534',
    '20535',
    '20536',
    '20537',
    '20538',
    '20539',
    '20540',
    '20541',
    '20542',
    '20543',
    '20544',
    '20546',
    '20547',
    '20548',
    '20549',
    '20551',
    '20552',
    '20553',
    '20554',
    '20555',
    '20557',
    '20559',
    '20560',
    '20565',
    '20566',
    '20570',
    '20571',
    '20572',
    '20573',
    '20575',
    '20576',
    '20577',
    '20578',
    '20579',
    '20580',
    '20581',
    '20585',
    '20586',
    '20590',
    '20591',
    '20593',
    '20594',
    '20597',
    '20599',
    '56901',
    '56902',
    '56904',
    '56908',
    '56915',
    '56920',
    '56933',
    '56935',
    '56944',
    '56945',
    '56950',
    '56965',
    '56966',
    '56967',
    '56968',
    '56969',
    '56970',
    '56972',
    '56998',
    '56999',
    '88888',
  ],
  VA: [
    '20101',
    '20102',
    '20103',
    '20104',
    '20105',
    '20106',
    '20108',
    '20109',
    '20110',
    '20111',
    '20112',
    '20113',
    '20115',
    '20116',
    '20117',
    '20118',
    '20119',
    '20120',
    '20121',
    '20122',
    '20124',
    '20128',
    '20129',
    '20130',
    '20131',
    '20132',
    '20134',
    '20135',
    '20136',
    '20137',
    '20138',
    '20139',
    '20140',
    '20141',
    '20142',
    '20143',
    '20144',
    '20146',
    '20147',
    '20148',
    '20149',
    '20151',
    '20152',
    '20153',
    '20155',
    '20156',
    '20158',
    '20159',
    '20160',
    '20163',
    '20164',
    '20165',
    '20166',
    '20167',
    '20168',
    '20169',
    '20170',
    '20171',
    '20172',
    '20175',
    '20176',
    '20177',
    '20178',
    '20180',
    '20181',
    '20182',
    '20184',
    '20185',
    '20186',
    '20187',
    '20188',
    '20189',
    '20190',
    '20191',
    '20192',
    '20194',
    '20195',
    '20196',
    '20197',
    '20198',
    '20598',
    '22003',
    '22009',
    '22015',
    '22025',
    '22026',
    '22027',
    '22030',
    '22031',
    '22032',
    '22033',
    '22034',
    '22035',
    '22036',
    '22037',
    '22038',
    '22039',
    '22040',
    '22041',
    '22042',
    '22043',
    '22044',
    '22046',
    '22060',
    '22066',
    '22067',
    '22079',
    '22081',
    '22082',
    '22095',
    '22096',
    '22101',
    '22102',
    '22103',
    '22106',
    '22107',
    '22108',
    '22109',
    '22116',
    '22118',
    '22119',
    '22121',
    '22122',
    '22124',
    '22125',
    '22134',
    '22135',
    '22150',
    '22151',
    '22152',
    '22153',
    '22156',
    '22158',
    '22159',
    '22160',
    '22161',
    '22172',
    '22180',
    '22181',
    '22182',
    '22183',
    '22185',
    '22191',
    '22192',
    '22193',
    '22194',
    '22195',
    '22199',
    '22201',
    '22202',
    '22203',
    '22204',
    '22205',
    '22206',
    '22207',
    '22209',
    '22210',
    '22211',
    '22212',
    '22213',
    '22214',
    '22215',
    '22216',
    '22217',
    '22219',
    '22225',
    '22226',
    '22227',
    '22230',
    '22240',
    '22241',
    '22242',
    '22243',
    '22244',
    '22245',
    '22246',
    '22301',
    '22302',
    '22303',
    '22304',
    '22305',
    '22306',
    '22307',
    '22308',
    '22309',
    '22310',
    '22311',
    '22312',
    '22313',
    '22314',
    '22315',
    '22320',
    '22331',
    '22332',
    '22333',
    '22334',
    '22350',
    '22401',
    '22402',
    '22403',
    '22404',
    '22405',
    '22406',
    '22407',
    '22408',
    '22412',
    '22427',
    '22428',
    '22430',
    '22432',
    '22433',
    '22435',
    '22436',
    '22437',
    '22438',
    '22442',
    '22443',
    '22446',
    '22448',
    '22451',
    '22454',
    '22456',
    '22460',
    '22463',
    '22469',
    '22471',
    '22472',
    '22473',
    '22476',
    '22480',
    '22481',
    '22482',
    '22485',
    '22488',
    '22501',
    '22503',
    '22504',
    '22507',
    '22508',
    '22509',
    '22511',
    '22513',
    '22514',
    '22517',
    '22520',
    '22523',
    '22524',
    '22526',
    '22528',
    '22529',
    '22530',
    '22534',
    '22535',
    '22538',
    '22539',
    '22542',
    '22544',
    '22545',
    '22546',
    '22547',
    '22548',
    '22551',
    '22552',
    '22553',
    '22554',
    '22555',
    '22556',
    '22558',
    '22560',
    '22565',
    '22567',
    '22570',
    '22572',
    '22576',
    '22577',
    '22578',
    '22579',
    '22580',
    '22581',
    '22601',
    '22602',
    '22603',
    '22604',
    '22610',
    '22611',
    '22620',
    '22622',
    '22623',
    '22624',
    '22625',
    '22626',
    '22627',
    '22630',
    '22637',
    '22639',
    '22640',
    '22641',
    '22642',
    '22643',
    '22644',
    '22645',
    '22646',
    '22649',
    '22650',
    '22652',
    '22654',
    '22655',
    '22656',
    '22657',
    '22660',
    '22663',
    '22664',
    '22701',
    '22709',
    '22711',
    '22712',
    '22713',
    '22714',
    '22715',
    '22716',
    '22718',
    '22719',
    '22720',
    '22722',
    '22723',
    '22724',
    '22725',
    '22726',
    '22727',
    '22728',
    '22729',
    '22730',
    '22731',
    '22732',
    '22733',
    '22734',
    '22735',
    '22736',
    '22737',
    '22738',
    '22739',
    '22740',
    '22741',
    '22742',
    '22743',
    '22746',
    '22747',
    '22748',
    '22749',
    '22801',
    '22802',
    '22803',
    '22807',
    '22810',
    '22811',
    '22812',
    '22815',
    '22820',
    '22821',
    '22824',
    '22827',
    '22830',
    '22831',
    '22832',
    '22833',
    '22834',
    '22835',
    '22840',
    '22841',
    '22842',
    '22843',
    '22844',
    '22845',
    '22846',
    '22847',
    '22848',
    '22849',
    '22850',
    '22851',
    '22853',
    '22901',
    '22902',
    '22903',
    '22904',
    '22905',
    '22906',
    '22907',
    '22908',
    '22909',
    '22910',
    '22911',
    '22920',
    '22922',
    '22923',
    '22924',
    '22931',
    '22932',
    '22935',
    '22936',
    '22937',
    '22938',
    '22939',
    '22940',
    '22942',
    '22943',
    '22945',
    '22946',
    '22947',
    '22948',
    '22949',
    '22952',
    '22957',
    '22958',
    '22959',
    '22960',
    '22963',
    '22964',
    '22965',
    '22967',
    '22968',
    '22969',
    '22971',
    '22972',
    '22973',
    '22974',
    '22976',
    '22980',
    '22987',
    '22989',
    '23001',
    '23002',
    '23003',
    '23004',
    '23005',
    '23009',
    '23011',
    '23014',
    '23015',
    '23018',
    '23021',
    '23022',
    '23023',
    '23024',
    '23025',
    '23027',
    '23030',
    '23031',
    '23032',
    '23035',
    '23038',
    '23039',
    '23040',
    '23043',
    '23045',
    '23047',
    '23050',
    '23055',
    '23056',
    '23058',
    '23059',
    '23060',
    '23061',
    '23062',
    '23063',
    '23064',
    '23065',
    '23066',
    '23067',
    '23068',
    '23069',
    '23070',
    '23071',
    '23072',
    '23075',
    '23076',
    '23079',
    '23081',
    '23083',
    '23084',
    '23085',
    '23086',
    '23089',
    '23090',
    '23091',
    '23092',
    '23093',
    '23102',
    '23103',
    '23105',
    '23106',
    '23107',
    '23108',
    '23109',
    '23110',
    '23111',
    '23112',
    '23113',
    '23114',
    '23115',
    '23116',
    '23117',
    '23119',
    '23120',
    '23123',
    '23124',
    '23125',
    '23126',
    '23127',
    '23128',
    '23129',
    '23130',
    '23131',
    '23138',
    '23139',
    '23140',
    '23141',
    '23146',
    '23147',
    '23148',
    '23149',
    '23150',
    '23153',
    '23154',
    '23155',
    '23156',
    '23160',
    '23161',
    '23162',
    '23163',
    '23168',
    '23169',
    '23170',
    '23173',
    '23175',
    '23176',
    '23177',
    '23178',
    '23180',
    '23181',
    '23183',
    '23184',
    '23185',
    '23186',
    '23187',
    '23188',
    '23190',
    '23192',
    '23218',
    '23219',
    '23220',
    '23221',
    '23222',
    '23223',
    '23224',
    '23225',
    '23226',
    '23227',
    '23228',
    '23229',
    '23230',
    '23231',
    '23232',
    '23233',
    '23234',
    '23235',
    '23236',
    '23237',
    '23238',
    '23241',
    '23242',
    '23249',
    '23250',
    '23255',
    '23260',
    '23261',
    '23269',
    '23273',
    '23274',
    '23276',
    '23278',
    '23279',
    '23282',
    '23284',
    '23285',
    '23286',
    '23288',
    '23289',
    '23290',
    '23291',
    '23292',
    '23293',
    '23294',
    '23295',
    '23297',
    '23298',
    '23301',
    '23302',
    '23303',
    '23304',
    '23306',
    '23307',
    '23308',
    '23310',
    '23313',
    '23314',
    '23315',
    '23316',
    '23320',
    '23321',
    '23322',
    '23323',
    '23324',
    '23325',
    '23326',
    '23327',
    '23328',
    '23336',
    '23337',
    '23341',
    '23345',
    '23347',
    '23350',
    '23354',
    '23356',
    '23357',
    '23358',
    '23359',
    '23389',
    '23395',
    '23396',
    '23397',
    '23398',
    '23399',
    '23401',
    '23404',
    '23405',
    '23407',
    '23408',
    '23409',
    '23410',
    '23412',
    '23413',
    '23414',
    '23415',
    '23416',
    '23417',
    '23418',
    '23419',
    '23420',
    '23421',
    '23422',
    '23423',
    '23424',
    '23426',
    '23427',
    '23429',
    '23430',
    '23431',
    '23432',
    '23433',
    '23434',
    '23435',
    '23436',
    '23437',
    '23438',
    '23439',
    '23440',
    '23441',
    '23442',
    '23443',
    '23450',
    '23451',
    '23452',
    '23453',
    '23454',
    '23455',
    '23456',
    '23457',
    '23458',
    '23459',
    '23460',
    '23461',
    '23462',
    '23463',
    '23464',
    '23465',
    '23466',
    '23467',
    '23471',
    '23479',
    '23480',
    '23482',
    '23483',
    '23486',
    '23487',
    '23488',
    '23501',
    '23502',
    '23503',
    '23504',
    '23505',
    '23506',
    '23507',
    '23508',
    '23509',
    '23510',
    '23511',
    '23513',
    '23514',
    '23515',
    '23517',
    '23518',
    '23519',
    '23523',
    '23529',
    '23541',
    '23551',
    '23601',
    '23602',
    '23603',
    '23604',
    '23605',
    '23606',
    '23607',
    '23608',
    '23609',
    '23612',
    '23628',
    '23630',
    '23651',
    '23661',
    '23662',
    '23663',
    '23664',
    '23665',
    '23666',
    '23667',
    '23668',
    '23669',
    '23670',
    '23681',
    '23690',
    '23691',
    '23692',
    '23693',
    '23694',
    '23696',
    '23701',
    '23702',
    '23703',
    '23704',
    '23705',
    '23707',
    '23708',
    '23709',
    '23801',
    '23803',
    '23804',
    '23805',
    '23806',
    '23821',
    '23822',
    '23824',
    '23827',
    '23828',
    '23829',
    '23830',
    '23831',
    '23832',
    '23833',
    '23834',
    '23836',
    '23837',
    '23838',
    '23839',
    '23840',
    '23841',
    '23842',
    '23843',
    '23844',
    '23845',
    '23846',
    '23847',
    '23850',
    '23851',
    '23856',
    '23857',
    '23860',
    '23866',
    '23867',
    '23868',
    '23870',
    '23872',
    '23873',
    '23874',
    '23875',
    '23876',
    '23878',
    '23879',
    '23881',
    '23882',
    '23883',
    '23884',
    '23885',
    '23887',
    '23888',
    '23889',
    '23890',
    '23891',
    '23893',
    '23894',
    '23897',
    '23898',
    '23899',
    '23901',
    '23909',
    '23915',
    '23917',
    '23919',
    '23920',
    '23921',
    '23922',
    '23923',
    '23924',
    '23927',
    '23930',
    '23934',
    '23936',
    '23937',
    '23938',
    '23939',
    '23941',
    '23942',
    '23943',
    '23944',
    '23947',
    '23950',
    '23952',
    '23954',
    '23955',
    '23958',
    '23959',
    '23960',
    '23962',
    '23963',
    '23964',
    '23966',
    '23967',
    '23968',
    '23970',
    '23974',
    '23976',
    '24001',
    '24002',
    '24003',
    '24004',
    '24005',
    '24006',
    '24007',
    '24008',
    '24009',
    '24010',
    '24011',
    '24012',
    '24013',
    '24014',
    '24015',
    '24016',
    '24017',
    '24018',
    '24019',
    '24020',
    '24022',
    '24023',
    '24024',
    '24025',
    '24026',
    '24027',
    '24028',
    '24029',
    '24030',
    '24031',
    '24032',
    '24033',
    '24034',
    '24035',
    '24036',
    '24037',
    '24038',
    '24040',
    '24042',
    '24043',
    '24050',
    '24053',
    '24054',
    '24055',
    '24058',
    '24059',
    '24060',
    '24061',
    '24062',
    '24063',
    '24064',
    '24065',
    '24066',
    '24067',
    '24068',
    '24069',
    '24070',
    '24072',
    '24073',
    '24076',
    '24077',
    '24078',
    '24079',
    '24082',
    '24083',
    '24084',
    '24085',
    '24086',
    '24087',
    '24088',
    '24089',
    '24090',
    '24091',
    '24092',
    '24093',
    '24095',
    '24101',
    '24102',
    '24104',
    '24105',
    '24111',
    '24112',
    '24113',
    '24114',
    '24115',
    '24120',
    '24121',
    '24122',
    '24124',
    '24126',
    '24127',
    '24128',
    '24129',
    '24130',
    '24131',
    '24132',
    '24133',
    '24134',
    '24136',
    '24137',
    '24138',
    '24139',
    '24141',
    '24142',
    '24143',
    '24146',
    '24147',
    '24148',
    '24149',
    '24150',
    '24151',
    '24153',
    '24155',
    '24157',
    '24161',
    '24162',
    '24165',
    '24167',
    '24168',
    '24171',
    '24174',
    '24175',
    '24176',
    '24177',
    '24178',
    '24179',
    '24184',
    '24185',
    '24201',
    '24202',
    '24203',
    '24205',
    '24209',
    '24210',
    '24211',
    '24212',
    '24215',
    '24216',
    '24217',
    '24218',
    '24219',
    '24220',
    '24221',
    '24224',
    '24225',
    '24226',
    '24228',
    '24230',
    '24236',
    '24237',
    '24239',
    '24243',
    '24244',
    '24245',
    '24246',
    '24248',
    '24250',
    '24251',
    '24256',
    '24258',
    '24260',
    '24263',
    '24265',
    '24266',
    '24269',
    '24270',
    '24271',
    '24272',
    '24273',
    '24277',
    '24279',
    '24280',
    '24281',
    '24282',
    '24283',
    '24290',
    '24292',
    '24293',
    '24301',
    '24311',
    '24312',
    '24313',
    '24314',
    '24315',
    '24316',
    '24317',
    '24318',
    '24319',
    '24322',
    '24323',
    '24324',
    '24325',
    '24326',
    '24327',
    '24328',
    '24330',
    '24333',
    '24340',
    '24343',
    '24347',
    '24348',
    '24350',
    '24351',
    '24352',
    '24354',
    '24360',
    '24361',
    '24363',
    '24366',
    '24368',
    '24370',
    '24374',
    '24375',
    '24377',
    '24378',
    '24380',
    '24381',
    '24382',
    '24401',
    '24402',
    '24411',
    '24412',
    '24413',
    '24415',
    '24416',
    '24421',
    '24422',
    '24426',
    '24430',
    '24431',
    '24432',
    '24433',
    '24435',
    '24437',
    '24438',
    '24439',
    '24440',
    '24441',
    '24442',
    '24445',
    '24448',
    '24450',
    '24457',
    '24458',
    '24459',
    '24460',
    '24463',
    '24464',
    '24465',
    '24467',
    '24468',
    '24469',
    '24471',
    '24472',
    '24473',
    '24474',
    '24476',
    '24477',
    '24479',
    '24482',
    '24483',
    '24484',
    '24485',
    '24486',
    '24487',
    '24501',
    '24502',
    '24503',
    '24504',
    '24505',
    '24506',
    '24513',
    '24514',
    '24515',
    '24517',
    '24520',
    '24521',
    '24522',
    '24523',
    '24526',
    '24527',
    '24528',
    '24529',
    '24530',
    '24531',
    '24533',
    '24534',
    '24535',
    '24536',
    '24538',
    '24539',
    '24540',
    '24541',
    '24543',
    '24549',
    '24550',
    '24551',
    '24553',
    '24554',
    '24555',
    '24556',
    '24557',
    '24558',
    '24562',
    '24563',
    '24565',
    '24566',
    '24569',
    '24570',
    '24571',
    '24572',
    '24574',
    '24576',
    '24577',
    '24578',
    '24579',
    '24580',
    '24581',
    '24586',
    '24588',
    '24589',
    '24590',
    '24592',
    '24593',
    '24594',
    '24595',
    '24597',
    '24598',
    '24599',
    '24601',
    '24602',
    '24603',
    '24604',
    '24605',
    '24606',
    '24607',
    '24608',
    '24609',
    '24612',
    '24613',
    '24614',
    '24619',
    '24620',
    '24622',
    '24624',
    '24627',
    '24628',
    '24630',
    '24631',
    '24634',
    '24635',
    '24637',
    '24639',
    '24640',
    '24641',
    '24646',
    '24647',
    '24649',
    '24651',
    '24656',
    '24657',
    '24658',
  ],
  MD: [
    '20588',
    '20601',
    '20602',
    '20603',
    '20604',
    '20606',
    '20607',
    '20608',
    '20609',
    '20610',
    '20611',
    '20612',
    '20613',
    '20615',
    '20616',
    '20617',
    '20618',
    '20619',
    '20620',
    '20621',
    '20622',
    '20623',
    '20624',
    '20625',
    '20626',
    '20627',
    '20628',
    '20629',
    '20630',
    '20632',
    '20634',
    '20635',
    '20636',
    '20637',
    '20639',
    '20640',
    '20643',
    '20645',
    '20646',
    '20650',
    '20653',
    '20656',
    '20657',
    '20658',
    '20659',
    '20660',
    '20661',
    '20662',
    '20664',
    '20667',
    '20670',
    '20674',
    '20675',
    '20676',
    '20677',
    '20678',
    '20680',
    '20682',
    '20684',
    '20685',
    '20686',
    '20687',
    '20688',
    '20689',
    '20690',
    '20692',
    '20693',
    '20695',
    '20697',
    '20701',
    '20703',
    '20704',
    '20705',
    '20706',
    '20707',
    '20708',
    '20709',
    '20710',
    '20711',
    '20712',
    '20714',
    '20715',
    '20716',
    '20717',
    '20718',
    '20719',
    '20720',
    '20721',
    '20722',
    '20723',
    '20724',
    '20725',
    '20726',
    '20731',
    '20732',
    '20733',
    '20735',
    '20736',
    '20737',
    '20738',
    '20740',
    '20741',
    '20742',
    '20743',
    '20744',
    '20745',
    '20746',
    '20747',
    '20748',
    '20749',
    '20750',
    '20751',
    '20752',
    '20753',
    '20754',
    '20755',
    '20757',
    '20758',
    '20759',
    '20762',
    '20763',
    '20764',
    '20765',
    '20768',
    '20769',
    '20770',
    '20771',
    '20772',
    '20773',
    '20774',
    '20775',
    '20776',
    '20777',
    '20778',
    '20779',
    '20781',
    '20782',
    '20783',
    '20784',
    '20785',
    '20787',
    '20788',
    '20790',
    '20791',
    '20792',
    '20794',
    '20797',
    '20799',
    '20810',
    '20811',
    '20812',
    '20813',
    '20814',
    '20815',
    '20816',
    '20817',
    '20818',
    '20824',
    '20825',
    '20827',
    '20830',
    '20832',
    '20833',
    '20837',
    '20838',
    '20839',
    '20841',
    '20842',
    '20847',
    '20848',
    '20849',
    '20850',
    '20851',
    '20852',
    '20853',
    '20854',
    '20855',
    '20857',
    '20859',
    '20860',
    '20861',
    '20862',
    '20866',
    '20868',
    '20871',
    '20872',
    '20874',
    '20875',
    '20876',
    '20877',
    '20878',
    '20879',
    '20880',
    '20882',
    '20883',
    '20884',
    '20885',
    '20886',
    '20889',
    '20891',
    '20892',
    '20894',
    '20895',
    '20896',
    '20897',
    '20898',
    '20899',
    '20901',
    '20902',
    '20903',
    '20904',
    '20905',
    '20906',
    '20907',
    '20908',
    '20910',
    '20911',
    '20912',
    '20913',
    '20914',
    '20915',
    '20916',
    '20918',
    '20993',
    '20997',
    '21001',
    '21005',
    '21009',
    '21010',
    '21012',
    '21013',
    '21014',
    '21015',
    '21017',
    '21018',
    '21020',
    '21022',
    '21023',
    '21027',
    '21028',
    '21029',
    '21030',
    '21031',
    '21032',
    '21034',
    '21035',
    '21036',
    '21037',
    '21040',
    '21041',
    '21042',
    '21043',
    '21044',
    '21045',
    '21046',
    '21047',
    '21048',
    '21050',
    '21051',
    '21052',
    '21053',
    '21054',
    '21056',
    '21057',
    '21060',
    '21061',
    '21062',
    '21065',
    '21071',
    '21074',
    '21075',
    '21076',
    '21077',
    '21078',
    '21082',
    '21084',
    '21085',
    '21087',
    '21088',
    '21090',
    '21092',
    '21093',
    '21094',
    '21102',
    '21104',
    '21105',
    '21106',
    '21108',
    '21111',
    '21113',
    '21114',
    '21117',
    '21120',
    '21122',
    '21123',
    '21128',
    '21130',
    '21131',
    '21132',
    '21133',
    '21136',
    '21139',
    '21140',
    '21144',
    '21146',
    '21150',
    '21152',
    '21153',
    '21154',
    '21155',
    '21156',
    '21157',
    '21158',
    '21160',
    '21161',
    '21162',
    '21163',
    '21201',
    '21202',
    '21203',
    '21204',
    '21205',
    '21206',
    '21207',
    '21208',
    '21209',
    '21210',
    '21211',
    '21212',
    '21213',
    '21214',
    '21215',
    '21216',
    '21217',
    '21218',
    '21219',
    '21220',
    '21221',
    '21222',
    '21223',
    '21224',
    '21225',
    '21226',
    '21227',
    '21228',
    '21229',
    '21230',
    '21231',
    '21233',
    '21234',
    '21235',
    '21236',
    '21237',
    '21239',
    '21240',
    '21241',
    '21244',
    '21250',
    '21251',
    '21252',
    '21263',
    '21264',
    '21270',
    '21273',
    '21275',
    '21278',
    '21279',
    '21281',
    '21282',
    '21284',
    '21285',
    '21286',
    '21287',
    '21289',
    '21290',
    '21297',
    '21298',
    '21401',
    '21402',
    '21403',
    '21404',
    '21405',
    '21409',
    '21411',
    '21412',
    '21501',
    '21502',
    '21503',
    '21504',
    '21505',
    '21520',
    '21521',
    '21522',
    '21523',
    '21524',
    '21528',
    '21529',
    '21530',
    '21531',
    '21532',
    '21536',
    '21538',
    '21539',
    '21540',
    '21541',
    '21542',
    '21543',
    '21545',
    '21550',
    '21555',
    '21556',
    '21557',
    '21560',
    '21561',
    '21562',
    '21601',
    '21607',
    '21609',
    '21610',
    '21612',
    '21613',
    '21617',
    '21619',
    '21620',
    '21622',
    '21623',
    '21624',
    '21625',
    '21626',
    '21627',
    '21628',
    '21629',
    '21631',
    '21632',
    '21634',
    '21635',
    '21636',
    '21638',
    '21639',
    '21640',
    '21641',
    '21643',
    '21644',
    '21645',
    '21647',
    '21648',
    '21649',
    '21650',
    '21651',
    '21652',
    '21653',
    '21654',
    '21655',
    '21656',
    '21657',
    '21658',
    '21659',
    '21660',
    '21661',
    '21662',
    '21663',
    '21664',
    '21665',
    '21666',
    '21667',
    '21668',
    '21669',
    '21670',
    '21671',
    '21672',
    '21673',
    '21675',
    '21676',
    '21677',
    '21678',
    '21679',
    '21690',
    '21701',
    '21702',
    '21703',
    '21704',
    '21705',
    '21709',
    '21710',
    '21711',
    '21713',
    '21714',
    '21715',
    '21716',
    '21717',
    '21718',
    '21719',
    '21720',
    '21721',
    '21722',
    '21723',
    '21727',
    '21733',
    '21734',
    '21737',
    '21738',
    '21740',
    '21741',
    '21742',
    '21746',
    '21747',
    '21749',
    '21750',
    '21754',
    '21755',
    '21756',
    '21757',
    '21758',
    '21759',
    '21762',
    '21765',
    '21766',
    '21767',
    '21769',
    '21770',
    '21771',
    '21773',
    '21774',
    '21775',
    '21776',
    '21777',
    '21778',
    '21779',
    '21780',
    '21781',
    '21782',
    '21783',
    '21784',
    '21787',
    '21788',
    '21790',
    '21791',
    '21792',
    '21793',
    '21794',
    '21795',
    '21797',
    '21798',
    '21801',
    '21802',
    '21803',
    '21804',
    '21810',
    '21811',
    '21813',
    '21814',
    '21817',
    '21821',
    '21822',
    '21824',
    '21826',
    '21829',
    '21830',
    '21835',
    '21836',
    '21837',
    '21838',
    '21840',
    '21841',
    '21842',
    '21843',
    '21849',
    '21850',
    '21851',
    '21852',
    '21853',
    '21856',
    '21857',
    '21861',
    '21862',
    '21863',
    '21864',
    '21865',
    '21866',
    '21867',
    '21869',
    '21871',
    '21872',
    '21874',
    '21875',
    '21890',
    '21901',
    '21902',
    '21903',
    '21904',
    '21911',
    '21912',
    '21913',
    '21914',
    '21915',
    '21916',
    '21917',
    '21918',
    '21919',
    '21920',
    '21921',
    '21922',
    '21930',
  ],
  WV: [
    '24701',
    '24712',
    '24714',
    '24715',
    '24716',
    '24719',
    '24724',
    '24726',
    '24729',
    '24731',
    '24732',
    '24733',
    '24736',
    '24737',
    '24738',
    '24739',
    '24740',
    '24747',
    '24751',
    '24801',
    '24808',
    '24811',
    '24813',
    '24815',
    '24816',
    '24817',
    '24818',
    '24822',
    '24823',
    '24826',
    '24827',
    '24828',
    '24829',
    '24830',
    '24831',
    '24834',
    '24836',
    '24839',
    '24843',
    '24844',
    '24845',
    '24846',
    '24847',
    '24848',
    '24849',
    '24850',
    '24851',
    '24853',
    '24854',
    '24855',
    '24857',
    '24859',
    '24860',
    '24861',
    '24862',
    '24866',
    '24867',
    '24868',
    '24869',
    '24870',
    '24871',
    '24872',
    '24873',
    '24874',
    '24878',
    '24879',
    '24880',
    '24881',
    '24882',
    '24884',
    '24887',
    '24888',
    '24892',
    '24894',
    '24895',
    '24898',
    '24901',
    '24902',
    '24910',
    '24915',
    '24916',
    '24918',
    '24920',
    '24924',
    '24925',
    '24927',
    '24931',
    '24934',
    '24935',
    '24938',
    '24941',
    '24943',
    '24944',
    '24945',
    '24946',
    '24951',
    '24954',
    '24957',
    '24962',
    '24963',
    '24966',
    '24970',
    '24974',
    '24976',
    '24977',
    '24981',
    '24983',
    '24984',
    '24985',
    '24986',
    '24991',
    '24993',
    '25002',
    '25003',
    '25005',
    '25007',
    '25008',
    '25009',
    '25011',
    '25015',
    '25019',
    '25021',
    '25022',
    '25024',
    '25025',
    '25026',
    '25028',
    '25030',
    '25031',
    '25033',
    '25035',
    '25036',
    '25039',
    '25040',
    '25043',
    '25044',
    '25045',
    '25047',
    '25048',
    '25049',
    '25051',
    '25053',
    '25054',
    '25057',
    '25059',
    '25060',
    '25061',
    '25062',
    '25063',
    '25064',
    '25067',
    '25070',
    '25071',
    '25075',
    '25076',
    '25079',
    '25081',
    '25082',
    '25083',
    '25085',
    '25086',
    '25088',
    '25090',
    '25093',
    '25102',
    '25103',
    '25106',
    '25107',
    '25108',
    '25109',
    '25110',
    '25111',
    '25112',
    '25113',
    '25114',
    '25115',
    '25118',
    '25119',
    '25121',
    '25123',
    '25124',
    '25125',
    '25126',
    '25130',
    '25132',
    '25133',
    '25134',
    '25136',
    '25139',
    '25140',
    '25141',
    '25142',
    '25143',
    '25148',
    '25149',
    '25152',
    '25154',
    '25156',
    '25159',
    '25160',
    '25161',
    '25162',
    '25164',
    '25165',
    '25168',
    '25169',
    '25173',
    '25174',
    '25177',
    '25180',
    '25181',
    '25183',
    '25185',
    '25186',
    '25187',
    '25193',
    '25201',
    '25202',
    '25203',
    '25204',
    '25205',
    '25206',
    '25208',
    '25209',
    '25211',
    '25213',
    '25214',
    '25231',
    '25234',
    '25235',
    '25239',
    '25241',
    '25243',
    '25244',
    '25245',
    '25247',
    '25248',
    '25251',
    '25252',
    '25253',
    '25259',
    '25260',
    '25261',
    '25262',
    '25264',
    '25265',
    '25266',
    '25267',
    '25268',
    '25270',
    '25271',
    '25275',
    '25276',
    '25285',
    '25286',
    '25287',
    '25301',
    '25302',
    '25303',
    '25304',
    '25305',
    '25306',
    '25309',
    '25311',
    '25312',
    '25313',
    '25314',
    '25315',
    '25317',
    '25320',
    '25321',
    '25322',
    '25323',
    '25324',
    '25325',
    '25326',
    '25327',
    '25328',
    '25329',
    '25330',
    '25331',
    '25332',
    '25333',
    '25334',
    '25335',
    '25336',
    '25337',
    '25338',
    '25339',
    '25350',
    '25356',
    '25357',
    '25358',
    '25360',
    '25361',
    '25362',
    '25364',
    '25365',
    '25375',
    '25387',
    '25389',
    '25392',
    '25396',
    '25401',
    '25402',
    '25403',
    '25404',
    '25405',
    '25410',
    '25411',
    '25413',
    '25414',
    '25419',
    '25420',
    '25421',
    '25422',
    '25423',
    '25425',
    '25427',
    '25428',
    '25430',
    '25431',
    '25432',
    '25434',
    '25437',
    '25438',
    '25440',
    '25441',
    '25442',
    '25443',
    '25444',
    '25446',
    '25501',
    '25502',
    '25503',
    '25504',
    '25505',
    '25506',
    '25507',
    '25508',
    '25510',
    '25511',
    '25512',
    '25514',
    '25515',
    '25517',
    '25520',
    '25521',
    '25523',
    '25524',
    '25526',
    '25529',
    '25530',
    '25534',
    '25535',
    '25537',
    '25540',
    '25541',
    '25544',
    '25545',
    '25547',
    '25550',
    '25555',
    '25557',
    '25559',
    '25560',
    '25562',
    '25564',
    '25565',
    '25567',
    '25569',
    '25570',
    '25571',
    '25572',
    '25573',
    '25601',
    '25606',
    '25607',
    '25608',
    '25611',
    '25612',
    '25614',
    '25617',
    '25621',
    '25624',
    '25625',
    '25628',
    '25630',
    '25632',
    '25634',
    '25635',
    '25637',
    '25638',
    '25639',
    '25644',
    '25646',
    '25647',
    '25649',
    '25650',
    '25651',
    '25652',
    '25653',
    '25654',
    '25661',
    '25665',
    '25666',
    '25667',
    '25669',
    '25670',
    '25671',
    '25672',
    '25674',
    '25676',
    '25678',
    '25685',
    '25686',
    '25688',
    '25690',
    '25691',
    '25692',
    '25696',
    '25699',
    '25701',
    '25702',
    '25703',
    '25704',
    '25705',
    '25706',
    '25707',
    '25708',
    '25709',
    '25710',
    '25711',
    '25712',
    '25713',
    '25714',
    '25715',
    '25716',
    '25717',
    '25718',
    '25719',
    '25720',
    '25721',
    '25722',
    '25723',
    '25724',
    '25725',
    '25726',
    '25727',
    '25728',
    '25729',
    '25755',
    '25770',
    '25771',
    '25772',
    '25773',
    '25774',
    '25775',
    '25776',
    '25777',
    '25778',
    '25779',
    '25801',
    '25802',
    '25810',
    '25811',
    '25812',
    '25813',
    '25817',
    '25818',
    '25820',
    '25823',
    '25825',
    '25826',
    '25827',
    '25831',
    '25832',
    '25833',
    '25836',
    '25837',
    '25839',
    '25840',
    '25841',
    '25843',
    '25844',
    '25845',
    '25846',
    '25848',
    '25849',
    '25851',
    '25853',
    '25854',
    '25855',
    '25857',
    '25860',
    '25862',
    '25864',
    '25865',
    '25866',
    '25868',
    '25870',
    '25871',
    '25873',
    '25875',
    '25876',
    '25878',
    '25879',
    '25880',
    '25882',
    '25888',
    '25901',
    '25902',
    '25904',
    '25906',
    '25907',
    '25908',
    '25909',
    '25911',
    '25913',
    '25915',
    '25916',
    '25917',
    '25918',
    '25919',
    '25920',
    '25921',
    '25922',
    '25927',
    '25928',
    '25932',
    '25936',
    '25938',
    '25942',
    '25943',
    '25951',
    '25958',
    '25962',
    '25966',
    '25969',
    '25971',
    '25972',
    '25976',
    '25977',
    '25978',
    '25979',
    '25981',
    '25984',
    '25985',
    '25986',
    '25989',
    '26003',
    '26030',
    '26031',
    '26032',
    '26033',
    '26034',
    '26035',
    '26036',
    '26037',
    '26038',
    '26039',
    '26040',
    '26041',
    '26047',
    '26050',
    '26055',
    '26056',
    '26058',
    '26059',
    '26060',
    '26062',
    '26070',
    '26074',
    '26075',
    '26101',
    '26102',
    '26103',
    '26104',
    '26105',
    '26106',
    '26120',
    '26121',
    '26133',
    '26134',
    '26136',
    '26137',
    '26138',
    '26141',
    '26142',
    '26143',
    '26146',
    '26147',
    '26148',
    '26149',
    '26150',
    '26151',
    '26152',
    '26155',
    '26159',
    '26160',
    '26161',
    '26162',
    '26164',
    '26167',
    '26169',
    '26170',
    '26175',
    '26178',
    '26180',
    '26181',
    '26184',
    '26187',
    '26201',
    '26202',
    '26203',
    '26205',
    '26206',
    '26208',
    '26209',
    '26210',
    '26215',
    '26217',
    '26218',
    '26219',
    '26222',
    '26224',
    '26228',
    '26229',
    '26230',
    '26234',
    '26236',
    '26237',
    '26238',
    '26241',
    '26250',
    '26253',
    '26254',
    '26257',
    '26259',
    '26260',
    '26261',
    '26263',
    '26264',
    '26266',
    '26267',
    '26268',
    '26269',
    '26270',
    '26271',
    '26273',
    '26275',
    '26276',
    '26278',
    '26280',
    '26282',
    '26283',
    '26285',
    '26287',
    '26288',
    '26289',
    '26291',
    '26292',
    '26293',
    '26294',
    '26296',
    '26298',
    '26301',
    '26302',
    '26306',
    '26320',
    '26321',
    '26323',
    '26325',
    '26327',
    '26330',
    '26335',
    '26337',
    '26338',
    '26339',
    '26342',
    '26343',
    '26346',
    '26347',
    '26348',
    '26349',
    '26351',
    '26354',
    '26361',
    '26362',
    '26366',
    '26369',
    '26372',
    '26374',
    '26376',
    '26377',
    '26378',
    '26384',
    '26385',
    '26386',
    '26404',
    '26405',
    '26408',
    '26410',
    '26411',
    '26412',
    '26415',
    '26416',
    '26419',
    '26421',
    '26422',
    '26424',
    '26425',
    '26426',
    '26430',
    '26431',
    '26434',
    '26435',
    '26436',
    '26437',
    '26438',
    '26440',
    '26443',
    '26444',
    '26447',
    '26448',
    '26451',
    '26452',
    '26456',
    '26463',
    '26501',
    '26502',
    '26504',
    '26505',
    '26506',
    '26507',
    '26508',
    '26519',
    '26520',
    '26521',
    '26524',
    '26525',
    '26527',
    '26531',
    '26534',
    '26537',
    '26541',
    '26542',
    '26543',
    '26544',
    '26546',
    '26547',
    '26554',
    '26555',
    '26559',
    '26560',
    '26561',
    '26562',
    '26563',
    '26566',
    '26568',
    '26570',
    '26571',
    '26572',
    '26574',
    '26575',
    '26576',
    '26578',
    '26581',
    '26582',
    '26585',
    '26586',
    '26587',
    '26588',
    '26590',
    '26591',
    '26601',
    '26610',
    '26611',
    '26615',
    '26617',
    '26619',
    '26621',
    '26623',
    '26624',
    '26627',
    '26629',
    '26631',
    '26636',
    '26638',
    '26651',
    '26656',
    '26660',
    '26662',
    '26667',
    '26671',
    '26675',
    '26676',
    '26678',
    '26679',
    '26680',
    '26681',
    '26684',
    '26690',
    '26691',
    '26704',
    '26705',
    '26707',
    '26710',
    '26711',
    '26714',
    '26716',
    '26717',
    '26719',
    '26720',
    '26722',
    '26726',
    '26731',
    '26739',
    '26743',
    '26750',
    '26753',
    '26755',
    '26757',
    '26761',
    '26763',
    '26764',
    '26767',
    '26801',
    '26802',
    '26804',
    '26807',
    '26808',
    '26810',
    '26812',
    '26814',
    '26815',
    '26817',
    '26818',
    '26823',
    '26833',
    '26836',
    '26838',
    '26845',
    '26847',
    '26851',
    '26852',
    '26855',
    '26865',
    '26866',
    '26884',
    '26886',
  ],
  NC: [
    '27006',
    '27007',
    '27009',
    '27010',
    '27011',
    '27012',
    '27013',
    '27014',
    '27016',
    '27017',
    '27018',
    '27019',
    '27020',
    '27021',
    '27022',
    '27023',
    '27024',
    '27025',
    '27027',
    '27028',
    '27030',
    '27031',
    '27040',
    '27041',
    '27042',
    '27043',
    '27045',
    '27046',
    '27047',
    '27048',
    '27049',
    '27050',
    '27051',
    '27052',
    '27053',
    '27054',
    '27055',
    '27094',
    '27098',
    '27099',
    '27101',
    '27102',
    '27103',
    '27104',
    '27105',
    '27106',
    '27107',
    '27108',
    '27109',
    '27110',
    '27111',
    '27113',
    '27114',
    '27115',
    '27116',
    '27117',
    '27120',
    '27127',
    '27130',
    '27150',
    '27152',
    '27155',
    '27157',
    '27198',
    '27199',
    '27201',
    '27202',
    '27203',
    '27204',
    '27205',
    '27207',
    '27208',
    '27209',
    '27212',
    '27213',
    '27214',
    '27215',
    '27216',
    '27217',
    '27228',
    '27229',
    '27230',
    '27231',
    '27233',
    '27235',
    '27237',
    '27239',
    '27242',
    '27243',
    '27244',
    '27247',
    '27248',
    '27249',
    '27252',
    '27253',
    '27256',
    '27258',
    '27259',
    '27260',
    '27261',
    '27262',
    '27263',
    '27264',
    '27265',
    '27268',
    '27278',
    '27281',
    '27282',
    '27283',
    '27284',
    '27285',
    '27288',
    '27289',
    '27291',
    '27292',
    '27293',
    '27294',
    '27295',
    '27298',
    '27299',
    '27301',
    '27302',
    '27305',
    '27306',
    '27310',
    '27311',
    '27312',
    '27313',
    '27314',
    '27315',
    '27316',
    '27317',
    '27320',
    '27323',
    '27325',
    '27326',
    '27330',
    '27331',
    '27332',
    '27340',
    '27341',
    '27342',
    '27343',
    '27344',
    '27349',
    '27350',
    '27351',
    '27355',
    '27356',
    '27357',
    '27358',
    '27359',
    '27360',
    '27361',
    '27370',
    '27371',
    '27373',
    '27374',
    '27375',
    '27376',
    '27377',
    '27379',
    '27401',
    '27402',
    '27403',
    '27404',
    '27405',
    '27406',
    '27407',
    '27408',
    '27409',
    '27410',
    '27411',
    '27412',
    '27413',
    '27415',
    '27416',
    '27417',
    '27419',
    '27420',
    '27425',
    '27427',
    '27429',
    '27435',
    '27438',
    '27455',
    '27495',
    '27497',
    '27498',
    '27499',
    '27501',
    '27502',
    '27503',
    '27504',
    '27505',
    '27506',
    '27507',
    '27508',
    '27509',
    '27510',
    '27511',
    '27512',
    '27513',
    '27514',
    '27515',
    '27516',
    '27517',
    '27518',
    '27519',
    '27520',
    '27521',
    '27522',
    '27523',
    '27524',
    '27525',
    '27526',
    '27527',
    '27528',
    '27529',
    '27530',
    '27531',
    '27532',
    '27533',
    '27534',
    '27536',
    '27537',
    '27539',
    '27540',
    '27541',
    '27542',
    '27543',
    '27544',
    '27545',
    '27546',
    '27549',
    '27551',
    '27552',
    '27553',
    '27555',
    '27556',
    '27557',
    '27559',
    '27560',
    '27562',
    '27563',
    '27565',
    '27568',
    '27569',
    '27570',
    '27571',
    '27572',
    '27573',
    '27574',
    '27576',
    '27577',
    '27581',
    '27582',
    '27583',
    '27584',
    '27586',
    '27587',
    '27588',
    '27589',
    '27591',
    '27592',
    '27593',
    '27594',
    '27596',
    '27597',
    '27599',
    '27601',
    '27602',
    '27603',
    '27604',
    '27605',
    '27606',
    '27607',
    '27608',
    '27609',
    '27610',
    '27611',
    '27612',
    '27613',
    '27614',
    '27615',
    '27616',
    '27617',
    '27619',
    '27620',
    '27621',
    '27622',
    '27623',
    '27624',
    '27625',
    '27626',
    '27627',
    '27628',
    '27629',
    '27634',
    '27635',
    '27636',
    '27640',
    '27650',
    '27656',
    '27658',
    '27661',
    '27668',
    '27675',
    '27676',
    '27690',
    '27695',
    '27697',
    '27698',
    '27699',
    '27701',
    '27702',
    '27703',
    '27704',
    '27705',
    '27706',
    '27707',
    '27708',
    '27709',
    '27710',
    '27711',
    '27712',
    '27713',
    '27715',
    '27717',
    '27722',
    '27801',
    '27802',
    '27803',
    '27804',
    '27805',
    '27806',
    '27807',
    '27808',
    '27809',
    '27810',
    '27811',
    '27812',
    '27813',
    '27814',
    '27815',
    '27816',
    '27817',
    '27818',
    '27819',
    '27820',
    '27821',
    '27822',
    '27823',
    '27824',
    '27825',
    '27826',
    '27827',
    '27828',
    '27829',
    '27830',
    '27831',
    '27832',
    '27833',
    '27834',
    '27835',
    '27836',
    '27837',
    '27839',
    '27840',
    '27841',
    '27842',
    '27843',
    '27844',
    '27845',
    '27846',
    '27847',
    '27849',
    '27850',
    '27851',
    '27852',
    '27853',
    '27855',
    '27856',
    '27857',
    '27858',
    '27860',
    '27861',
    '27862',
    '27863',
    '27864',
    '27865',
    '27866',
    '27867',
    '27868',
    '27869',
    '27870',
    '27871',
    '27872',
    '27873',
    '27874',
    '27875',
    '27876',
    '27877',
    '27878',
    '27879',
    '27880',
    '27881',
    '27882',
    '27883',
    '27884',
    '27885',
    '27886',
    '27887',
    '27888',
    '27889',
    '27890',
    '27891',
    '27892',
    '27893',
    '27894',
    '27895',
    '27896',
    '27897',
    '27906',
    '27907',
    '27909',
    '27910',
    '27915',
    '27916',
    '27917',
    '27919',
    '27920',
    '27921',
    '27922',
    '27923',
    '27924',
    '27925',
    '27926',
    '27927',
    '27928',
    '27929',
    '27930',
    '27932',
    '27935',
    '27936',
    '27937',
    '27938',
    '27939',
    '27941',
    '27942',
    '27943',
    '27944',
    '27946',
    '27947',
    '27948',
    '27949',
    '27950',
    '27953',
    '27954',
    '27956',
    '27957',
    '27958',
    '27959',
    '27960',
    '27962',
    '27964',
    '27965',
    '27966',
    '27967',
    '27968',
    '27969',
    '27970',
    '27972',
    '27973',
    '27974',
    '27976',
    '27978',
    '27979',
    '27980',
    '27981',
    '27982',
    '27983',
    '27985',
    '27986',
    '28001',
    '28002',
    '28006',
    '28007',
    '28009',
    '28010',
    '28012',
    '28016',
    '28017',
    '28018',
    '28019',
    '28020',
    '28021',
    '28023',
    '28024',
    '28025',
    '28026',
    '28027',
    '28031',
    '28032',
    '28033',
    '28034',
    '28035',
    '28036',
    '28037',
    '28038',
    '28039',
    '28040',
    '28041',
    '28042',
    '28043',
    '28052',
    '28053',
    '28054',
    '28055',
    '28056',
    '28070',
    '28071',
    '28072',
    '28073',
    '28074',
    '28075',
    '28076',
    '28077',
    '28078',
    '28079',
    '28080',
    '28081',
    '28082',
    '28083',
    '28086',
    '28088',
    '28089',
    '28090',
    '28091',
    '28092',
    '28093',
    '28097',
    '28098',
    '28101',
    '28102',
    '28103',
    '28104',
    '28105',
    '28106',
    '28107',
    '28108',
    '28109',
    '28110',
    '28111',
    '28112',
    '28114',
    '28115',
    '28117',
    '28119',
    '28120',
    '28123',
    '28124',
    '28125',
    '28126',
    '28127',
    '28128',
    '28129',
    '28130',
    '28133',
    '28134',
    '28135',
    '28136',
    '28137',
    '28138',
    '28139',
    '28144',
    '28145',
    '28146',
    '28147',
    '28150',
    '28151',
    '28152',
    '28159',
    '28160',
    '28163',
    '28164',
    '28166',
    '28167',
    '28168',
    '28169',
    '28170',
    '28173',
    '28174',
    '28201',
    '28202',
    '28203',
    '28204',
    '28205',
    '28206',
    '28207',
    '28208',
    '28209',
    '28210',
    '28211',
    '28212',
    '28213',
    '28214',
    '28215',
    '28216',
    '28217',
    '28218',
    '28219',
    '28220',
    '28221',
    '28222',
    '28223',
    '28224',
    '28226',
    '28227',
    '28228',
    '28229',
    '28230',
    '28231',
    '28232',
    '28233',
    '28234',
    '28235',
    '28236',
    '28237',
    '28241',
    '28242',
    '28243',
    '28244',
    '28246',
    '28247',
    '28253',
    '28254',
    '28255',
    '28256',
    '28258',
    '28260',
    '28262',
    '28263',
    '28265',
    '28266',
    '28269',
    '28270',
    '28271',
    '28272',
    '28273',
    '28274',
    '28275',
    '28277',
    '28278',
    '28280',
    '28281',
    '28282',
    '28284',
    '28285',
    '28287',
    '28288',
    '28289',
    '28290',
    '28296',
    '28297',
    '28299',
    '28301',
    '28302',
    '28303',
    '28304',
    '28305',
    '28306',
    '28307',
    '28308',
    '28309',
    '28310',
    '28311',
    '28312',
    '28314',
    '28315',
    '28318',
    '28319',
    '28320',
    '28323',
    '28325',
    '28326',
    '28327',
    '28328',
    '28329',
    '28330',
    '28331',
    '28332',
    '28333',
    '28334',
    '28335',
    '28337',
    '28338',
    '28339',
    '28340',
    '28341',
    '28342',
    '28343',
    '28344',
    '28345',
    '28347',
    '28348',
    '28349',
    '28350',
    '28351',
    '28352',
    '28353',
    '28355',
    '28356',
    '28357',
    '28358',
    '28359',
    '28360',
    '28362',
    '28363',
    '28364',
    '28365',
    '28366',
    '28367',
    '28368',
    '28369',
    '28370',
    '28371',
    '28372',
    '28373',
    '28374',
    '28375',
    '28376',
    '28377',
    '28378',
    '28379',
    '28380',
    '28382',
    '28383',
    '28384',
    '28385',
    '28386',
    '28387',
    '28388',
    '28390',
    '28391',
    '28392',
    '28393',
    '28394',
    '28395',
    '28396',
    '28398',
    '28399',
    '28401',
    '28402',
    '28403',
    '28404',
    '28405',
    '28406',
    '28407',
    '28408',
    '28409',
    '28410',
    '28411',
    '28412',
    '28420',
    '28421',
    '28422',
    '28423',
    '28424',
    '28425',
    '28428',
    '28429',
    '28430',
    '28431',
    '28432',
    '28433',
    '28434',
    '28435',
    '28436',
    '28438',
    '28439',
    '28441',
    '28442',
    '28443',
    '28444',
    '28445',
    '28447',
    '28448',
    '28449',
    '28450',
    '28451',
    '28452',
    '28453',
    '28454',
    '28455',
    '28456',
    '28457',
    '28458',
    '28459',
    '28460',
    '28461',
    '28462',
    '28463',
    '28464',
    '28465',
    '28466',
    '28467',
    '28468',
    '28469',
    '28470',
    '28472',
    '28478',
    '28479',
    '28480',
    '28501',
    '28502',
    '28503',
    '28504',
    '28508',
    '28509',
    '28510',
    '28511',
    '28512',
    '28513',
    '28515',
    '28516',
    '28518',
    '28519',
    '28520',
    '28521',
    '28522',
    '28523',
    '28524',
    '28525',
    '28526',
    '28527',
    '28528',
    '28529',
    '28530',
    '28531',
    '28532',
    '28533',
    '28537',
    '28538',
    '28539',
    '28540',
    '28541',
    '28542',
    '28543',
    '28544',
    '28545',
    '28546',
    '28547',
    '28551',
    '28552',
    '28553',
    '28554',
    '28555',
    '28556',
    '28557',
    '28560',
    '28561',
    '28562',
    '28563',
    '28564',
    '28570',
    '28571',
    '28572',
    '28573',
    '28574',
    '28575',
    '28577',
    '28578',
    '28579',
    '28580',
    '28581',
    '28582',
    '28583',
    '28584',
    '28585',
    '28586',
    '28587',
    '28589',
    '28590',
    '28594',
    '28601',
    '28602',
    '28603',
    '28604',
    '28605',
    '28606',
    '28607',
    '28608',
    '28609',
    '28610',
    '28611',
    '28612',
    '28613',
    '28615',
    '28616',
    '28617',
    '28618',
    '28619',
    '28621',
    '28622',
    '28623',
    '28624',
    '28625',
    '28626',
    '28627',
    '28628',
    '28629',
    '28630',
    '28631',
    '28633',
    '28634',
    '28635',
    '28636',
    '28637',
    '28638',
    '28640',
    '28641',
    '28642',
    '28643',
    '28644',
    '28645',
    '28646',
    '28647',
    '28649',
    '28650',
    '28651',
    '28652',
    '28653',
    '28654',
    '28655',
    '28656',
    '28657',
    '28658',
    '28659',
    '28660',
    '28661',
    '28662',
    '28663',
    '28664',
    '28665',
    '28666',
    '28667',
    '28668',
    '28669',
    '28670',
    '28671',
    '28672',
    '28673',
    '28675',
    '28676',
    '28677',
    '28678',
    '28679',
    '28680',
    '28681',
    '28682',
    '28683',
    '28684',
    '28685',
    '28687',
    '28688',
    '28689',
    '28690',
    '28691',
    '28692',
    '28693',
    '28694',
    '28697',
    '28698',
    '28699',
    '28701',
    '28702',
    '28704',
    '28705',
    '28707',
    '28708',
    '28709',
    '28710',
    '28711',
    '28712',
    '28713',
    '28714',
    '28715',
    '28716',
    '28717',
    '28718',
    '28719',
    '28720',
    '28721',
    '28722',
    '28723',
    '28724',
    '28725',
    '28726',
    '28727',
    '28728',
    '28729',
    '28730',
    '28731',
    '28732',
    '28733',
    '28734',
    '28735',
    '28736',
    '28737',
    '28738',
    '28739',
    '28740',
    '28741',
    '28742',
    '28743',
    '28744',
    '28745',
    '28746',
    '28747',
    '28748',
    '28749',
    '28750',
    '28751',
    '28752',
    '28753',
    '28754',
    '28755',
    '28756',
    '28757',
    '28758',
    '28759',
    '28760',
    '28761',
    '28762',
    '28763',
    '28765',
    '28766',
    '28768',
    '28770',
    '28771',
    '28772',
    '28773',
    '28774',
    '28775',
    '28776',
    '28777',
    '28778',
    '28779',
    '28781',
    '28782',
    '28783',
    '28784',
    '28785',
    '28786',
    '28787',
    '28788',
    '28789',
    '28790',
    '28791',
    '28792',
    '28793',
    '28801',
    '28802',
    '28803',
    '28804',
    '28805',
    '28806',
    '28810',
    '28813',
    '28814',
    '28815',
    '28816',
    '28901',
    '28902',
    '28903',
    '28904',
    '28905',
    '28906',
    '28909',
  ],
  SC: [
    '29001',
    '29002',
    '29003',
    '29006',
    '29009',
    '29010',
    '29014',
    '29015',
    '29016',
    '29018',
    '29020',
    '29021',
    '29030',
    '29031',
    '29032',
    '29033',
    '29036',
    '29037',
    '29038',
    '29039',
    '29040',
    '29041',
    '29042',
    '29044',
    '29045',
    '29046',
    '29047',
    '29048',
    '29051',
    '29052',
    '29053',
    '29054',
    '29055',
    '29056',
    '29058',
    '29059',
    '29061',
    '29062',
    '29063',
    '29065',
    '29067',
    '29069',
    '29070',
    '29071',
    '29072',
    '29073',
    '29074',
    '29075',
    '29078',
    '29079',
    '29080',
    '29081',
    '29082',
    '29101',
    '29102',
    '29104',
    '29105',
    '29107',
    '29108',
    '29111',
    '29112',
    '29113',
    '29114',
    '29115',
    '29116',
    '29117',
    '29118',
    '29122',
    '29123',
    '29125',
    '29126',
    '29127',
    '29128',
    '29129',
    '29130',
    '29132',
    '29133',
    '29135',
    '29137',
    '29138',
    '29142',
    '29143',
    '29145',
    '29146',
    '29147',
    '29148',
    '29150',
    '29151',
    '29152',
    '29153',
    '29154',
    '29160',
    '29161',
    '29162',
    '29163',
    '29164',
    '29166',
    '29168',
    '29169',
    '29170',
    '29171',
    '29172',
    '29175',
    '29177',
    '29178',
    '29180',
    '29201',
    '29202',
    '29203',
    '29204',
    '29205',
    '29206',
    '29207',
    '29208',
    '29209',
    '29210',
    '29211',
    '29212',
    '29214',
    '29215',
    '29216',
    '29217',
    '29218',
    '29219',
    '29220',
    '29221',
    '29222',
    '29223',
    '29224',
    '29225',
    '29226',
    '29227',
    '29228',
    '29229',
    '29230',
    '29240',
    '29250',
    '29260',
    '29290',
    '29292',
    '29301',
    '29302',
    '29303',
    '29304',
    '29305',
    '29306',
    '29307',
    '29316',
    '29319',
    '29320',
    '29321',
    '29322',
    '29323',
    '29324',
    '29325',
    '29329',
    '29330',
    '29331',
    '29332',
    '29333',
    '29334',
    '29335',
    '29336',
    '29338',
    '29340',
    '29341',
    '29342',
    '29346',
    '29348',
    '29349',
    '29351',
    '29353',
    '29355',
    '29356',
    '29360',
    '29364',
    '29365',
    '29368',
    '29369',
    '29370',
    '29372',
    '29373',
    '29374',
    '29375',
    '29376',
    '29377',
    '29378',
    '29379',
    '29384',
    '29385',
    '29386',
    '29388',
    '29395',
    '29401',
    '29402',
    '29403',
    '29404',
    '29405',
    '29406',
    '29407',
    '29409',
    '29410',
    '29412',
    '29413',
    '29414',
    '29415',
    '29416',
    '29417',
    '29418',
    '29419',
    '29420',
    '29422',
    '29423',
    '29424',
    '29425',
    '29426',
    '29429',
    '29431',
    '29432',
    '29433',
    '29434',
    '29435',
    '29436',
    '29437',
    '29438',
    '29439',
    '29440',
    '29442',
    '29445',
    '29446',
    '29447',
    '29448',
    '29449',
    '29450',
    '29451',
    '29452',
    '29453',
    '29455',
    '29456',
    '29457',
    '29458',
    '29461',
    '29464',
    '29465',
    '29466',
    '29468',
    '29469',
    '29470',
    '29471',
    '29472',
    '29474',
    '29475',
    '29476',
    '29477',
    '29479',
    '29481',
    '29482',
    '29483',
    '29484',
    '29485',
    '29486',
    '29487',
    '29488',
    '29492',
    '29493',
    '29501',
    '29502',
    '29503',
    '29504',
    '29505',
    '29506',
    '29510',
    '29511',
    '29512',
    '29516',
    '29518',
    '29519',
    '29520',
    '29525',
    '29526',
    '29527',
    '29528',
    '29530',
    '29532',
    '29536',
    '29540',
    '29541',
    '29543',
    '29544',
    '29545',
    '29546',
    '29547',
    '29550',
    '29551',
    '29554',
    '29555',
    '29556',
    '29560',
    '29563',
    '29564',
    '29565',
    '29566',
    '29567',
    '29568',
    '29569',
    '29570',
    '29571',
    '29572',
    '29574',
    '29575',
    '29576',
    '29577',
    '29578',
    '29579',
    '29580',
    '29581',
    '29582',
    '29583',
    '29584',
    '29585',
    '29587',
    '29588',
    '29589',
    '29590',
    '29591',
    '29592',
    '29593',
    '29594',
    '29596',
    '29597',
    '29598',
    '29601',
    '29602',
    '29603',
    '29604',
    '29605',
    '29606',
    '29607',
    '29608',
    '29609',
    '29610',
    '29611',
    '29612',
    '29613',
    '29614',
    '29615',
    '29616',
    '29617',
    '29620',
    '29621',
    '29622',
    '29623',
    '29624',
    '29625',
    '29626',
    '29627',
    '29628',
    '29630',
    '29631',
    '29632',
    '29633',
    '29634',
    '29635',
    '29636',
    '29638',
    '29639',
    '29640',
    '29641',
    '29642',
    '29643',
    '29644',
    '29645',
    '29646',
    '29647',
    '29648',
    '29649',
    '29650',
    '29651',
    '29652',
    '29653',
    '29654',
    '29655',
    '29656',
    '29657',
    '29658',
    '29659',
    '29661',
    '29662',
    '29664',
    '29665',
    '29666',
    '29667',
    '29669',
    '29670',
    '29671',
    '29672',
    '29673',
    '29675',
    '29676',
    '29677',
    '29678',
    '29679',
    '29680',
    '29681',
    '29682',
    '29683',
    '29684',
    '29685',
    '29686',
    '29687',
    '29688',
    '29689',
    '29690',
    '29691',
    '29692',
    '29693',
    '29695',
    '29696',
    '29697',
    '29702',
    '29703',
    '29704',
    '29706',
    '29707',
    '29708',
    '29709',
    '29710',
    '29712',
    '29714',
    '29715',
    '29716',
    '29717',
    '29718',
    '29720',
    '29721',
    '29722',
    '29724',
    '29726',
    '29727',
    '29728',
    '29729',
    '29730',
    '29731',
    '29732',
    '29733',
    '29734',
    '29741',
    '29742',
    '29743',
    '29744',
    '29745',
    '29801',
    '29802',
    '29803',
    '29804',
    '29805',
    '29808',
    '29809',
    '29810',
    '29812',
    '29813',
    '29816',
    '29817',
    '29819',
    '29821',
    '29822',
    '29824',
    '29826',
    '29827',
    '29828',
    '29829',
    '29831',
    '29832',
    '29834',
    '29835',
    '29836',
    '29838',
    '29839',
    '29840',
    '29841',
    '29842',
    '29843',
    '29844',
    '29845',
    '29846',
    '29847',
    '29848',
    '29849',
    '29850',
    '29851',
    '29853',
    '29856',
    '29860',
    '29861',
    '29899',
    '29901',
    '29902',
    '29903',
    '29904',
    '29905',
    '29906',
    '29907',
    '29909',
    '29910',
    '29911',
    '29912',
    '29913',
    '29914',
    '29915',
    '29916',
    '29918',
    '29920',
    '29921',
    '29922',
    '29923',
    '29924',
    '29925',
    '29926',
    '29927',
    '29928',
    '29929',
    '29931',
    '29932',
    '29933',
    '29934',
    '29935',
    '29936',
    '29938',
    '29939',
    '29940',
    '29941',
    '29943',
    '29944',
    '29945',
  ],
  GA: [
    '30002',
    '30003',
    '30004',
    '30005',
    '30006',
    '30007',
    '30008',
    '30009',
    '30010',
    '30011',
    '30012',
    '30013',
    '30014',
    '30015',
    '30016',
    '30017',
    '30018',
    '30019',
    '30021',
    '30022',
    '30023',
    '30024',
    '30025',
    '30026',
    '30028',
    '30029',
    '30030',
    '30031',
    '30032',
    '30033',
    '30034',
    '30035',
    '30036',
    '30037',
    '30038',
    '30039',
    '30040',
    '30041',
    '30042',
    '30043',
    '30044',
    '30045',
    '30046',
    '30047',
    '30048',
    '30049',
    '30052',
    '30054',
    '30055',
    '30056',
    '30058',
    '30060',
    '30061',
    '30062',
    '30063',
    '30064',
    '30065',
    '30066',
    '30067',
    '30068',
    '30069',
    '30070',
    '30071',
    '30072',
    '30074',
    '30075',
    '30076',
    '30077',
    '30078',
    '30079',
    '30080',
    '30081',
    '30082',
    '30083',
    '30084',
    '30085',
    '30086',
    '30087',
    '30088',
    '30090',
    '30091',
    '30092',
    '30093',
    '30094',
    '30095',
    '30096',
    '30097',
    '30098',
    '30099',
    '30101',
    '30102',
    '30103',
    '30104',
    '30105',
    '30106',
    '30107',
    '30108',
    '30109',
    '30110',
    '30111',
    '30112',
    '30113',
    '30114',
    '30115',
    '30116',
    '30117',
    '30118',
    '30119',
    '30120',
    '30121',
    '30122',
    '30123',
    '30124',
    '30125',
    '30126',
    '30127',
    '30129',
    '30132',
    '30133',
    '30134',
    '30135',
    '30137',
    '30138',
    '30139',
    '30140',
    '30141',
    '30142',
    '30143',
    '30144',
    '30145',
    '30146',
    '30147',
    '30148',
    '30149',
    '30150',
    '30151',
    '30152',
    '30153',
    '30154',
    '30156',
    '30157',
    '30160',
    '30161',
    '30162',
    '30164',
    '30165',
    '30168',
    '30169',
    '30170',
    '30171',
    '30172',
    '30173',
    '30175',
    '30176',
    '30177',
    '30178',
    '30179',
    '30180',
    '30182',
    '30183',
    '30184',
    '30185',
    '30187',
    '30188',
    '30189',
    '30204',
    '30205',
    '30206',
    '30212',
    '30213',
    '30214',
    '30215',
    '30216',
    '30217',
    '30218',
    '30220',
    '30222',
    '30223',
    '30224',
    '30228',
    '30229',
    '30230',
    '30233',
    '30234',
    '30236',
    '30237',
    '30238',
    '30240',
    '30241',
    '30248',
    '30250',
    '30251',
    '30252',
    '30253',
    '30256',
    '30257',
    '30258',
    '30259',
    '30260',
    '30261',
    '30263',
    '30264',
    '30265',
    '30266',
    '30268',
    '30269',
    '30270',
    '30271',
    '30272',
    '30273',
    '30274',
    '30275',
    '30276',
    '30277',
    '30281',
    '30284',
    '30285',
    '30286',
    '30287',
    '30288',
    '30289',
    '30290',
    '30291',
    '30292',
    '30293',
    '30294',
    '30295',
    '30296',
    '30297',
    '30298',
    '30301',
    '30302',
    '30303',
    '30304',
    '30305',
    '30306',
    '30307',
    '30308',
    '30309',
    '30310',
    '30311',
    '30312',
    '30313',
    '30314',
    '30315',
    '30316',
    '30317',
    '30318',
    '30319',
    '30320',
    '30321',
    '30322',
    '30324',
    '30325',
    '30326',
    '30327',
    '30328',
    '30329',
    '30331',
    '30332',
    '30333',
    '30334',
    '30336',
    '30337',
    '30338',
    '30339',
    '30340',
    '30341',
    '30342',
    '30343',
    '30344',
    '30345',
    '30346',
    '30348',
    '30349',
    '30350',
    '30353',
    '30354',
    '30355',
    '30356',
    '30357',
    '30358',
    '30359',
    '30360',
    '30361',
    '30362',
    '30363',
    '30364',
    '30366',
    '30368',
    '30369',
    '30370',
    '30371',
    '30374',
    '30375',
    '30377',
    '30378',
    '30380',
    '30384',
    '30385',
    '30388',
    '30392',
    '30394',
    '30396',
    '30398',
    '30401',
    '30410',
    '30411',
    '30412',
    '30413',
    '30414',
    '30415',
    '30417',
    '30420',
    '30421',
    '30423',
    '30424',
    '30425',
    '30426',
    '30427',
    '30428',
    '30429',
    '30434',
    '30436',
    '30438',
    '30439',
    '30441',
    '30442',
    '30445',
    '30446',
    '30447',
    '30448',
    '30449',
    '30450',
    '30451',
    '30452',
    '30453',
    '30454',
    '30455',
    '30456',
    '30457',
    '30458',
    '30459',
    '30460',
    '30461',
    '30464',
    '30467',
    '30470',
    '30471',
    '30473',
    '30474',
    '30475',
    '30477',
    '30499',
    '30501',
    '30502',
    '30503',
    '30504',
    '30506',
    '30507',
    '30510',
    '30511',
    '30512',
    '30513',
    '30514',
    '30515',
    '30516',
    '30517',
    '30518',
    '30519',
    '30520',
    '30521',
    '30522',
    '30523',
    '30525',
    '30527',
    '30528',
    '30529',
    '30530',
    '30531',
    '30533',
    '30534',
    '30535',
    '30536',
    '30537',
    '30538',
    '30539',
    '30540',
    '30541',
    '30542',
    '30543',
    '30545',
    '30546',
    '30547',
    '30548',
    '30549',
    '30552',
    '30553',
    '30554',
    '30555',
    '30557',
    '30558',
    '30559',
    '30560',
    '30562',
    '30563',
    '30564',
    '30565',
    '30566',
    '30567',
    '30568',
    '30571',
    '30572',
    '30573',
    '30575',
    '30576',
    '30577',
    '30580',
    '30581',
    '30582',
    '30597',
    '30598',
    '30599',
    '30601',
    '30602',
    '30603',
    '30604',
    '30605',
    '30606',
    '30607',
    '30608',
    '30609',
    '30612',
    '30619',
    '30620',
    '30621',
    '30622',
    '30623',
    '30624',
    '30625',
    '30627',
    '30628',
    '30629',
    '30630',
    '30631',
    '30633',
    '30634',
    '30635',
    '30638',
    '30639',
    '30641',
    '30642',
    '30643',
    '30645',
    '30646',
    '30647',
    '30648',
    '30650',
    '30655',
    '30656',
    '30660',
    '30662',
    '30663',
    '30664',
    '30665',
    '30666',
    '30667',
    '30668',
    '30669',
    '30671',
    '30673',
    '30677',
    '30678',
    '30680',
    '30683',
    '30701',
    '30703',
    '30705',
    '30707',
    '30708',
    '30710',
    '30711',
    '30719',
    '30720',
    '30721',
    '30722',
    '30724',
    '30725',
    '30726',
    '30728',
    '30730',
    '30731',
    '30732',
    '30733',
    '30734',
    '30735',
    '30736',
    '30738',
    '30739',
    '30740',
    '30741',
    '30742',
    '30746',
    '30747',
    '30750',
    '30751',
    '30752',
    '30753',
    '30755',
    '30756',
    '30757',
    '30802',
    '30803',
    '30805',
    '30806',
    '30807',
    '30808',
    '30809',
    '30810',
    '30811',
    '30812',
    '30813',
    '30814',
    '30815',
    '30816',
    '30817',
    '30818',
    '30819',
    '30820',
    '30821',
    '30822',
    '30823',
    '30824',
    '30828',
    '30830',
    '30833',
    '30901',
    '30903',
    '30904',
    '30905',
    '30906',
    '30907',
    '30909',
    '30912',
    '30914',
    '30916',
    '30917',
    '30919',
    '30999',
    '31001',
    '31002',
    '31003',
    '31004',
    '31005',
    '31006',
    '31007',
    '31008',
    '31009',
    '31010',
    '31011',
    '31012',
    '31013',
    '31014',
    '31015',
    '31016',
    '31017',
    '31018',
    '31019',
    '31020',
    '31021',
    '31022',
    '31023',
    '31024',
    '31025',
    '31026',
    '31027',
    '31028',
    '31029',
    '31030',
    '31031',
    '31032',
    '31033',
    '31034',
    '31035',
    '31036',
    '31037',
    '31038',
    '31039',
    '31040',
    '31041',
    '31042',
    '31044',
    '31045',
    '31046',
    '31047',
    '31049',
    '31050',
    '31051',
    '31052',
    '31054',
    '31055',
    '31057',
    '31058',
    '31059',
    '31060',
    '31061',
    '31062',
    '31063',
    '31064',
    '31065',
    '31066',
    '31067',
    '31068',
    '31069',
    '31070',
    '31071',
    '31072',
    '31075',
    '31076',
    '31077',
    '31078',
    '31079',
    '31081',
    '31082',
    '31083',
    '31084',
    '31085',
    '31086',
    '31087',
    '31088',
    '31089',
    '31090',
    '31091',
    '31092',
    '31093',
    '31094',
    '31095',
    '31096',
    '31097',
    '31098',
    '31099',
    '31106',
    '31107',
    '31119',
    '31126',
    '31131',
    '31136',
    '31139',
    '31141',
    '31144',
    '31145',
    '31146',
    '31150',
    '31156',
    '31169',
    '31192',
    '31193',
    '31195',
    '31196',
    '31201',
    '31202',
    '31203',
    '31204',
    '31205',
    '31206',
    '31207',
    '31208',
    '31209',
    '31210',
    '31211',
    '31213',
    '31216',
    '31217',
    '31220',
    '31221',
    '31294',
    '31295',
    '31296',
    '31297',
    '31301',
    '31302',
    '31303',
    '31304',
    '31305',
    '31307',
    '31308',
    '31309',
    '31310',
    '31312',
    '31313',
    '31314',
    '31315',
    '31316',
    '31318',
    '31319',
    '31320',
    '31321',
    '31322',
    '31323',
    '31324',
    '31326',
    '31327',
    '31328',
    '31329',
    '31331',
    '31333',
    '31401',
    '31402',
    '31403',
    '31404',
    '31405',
    '31406',
    '31407',
    '31408',
    '31409',
    '31410',
    '31411',
    '31412',
    '31414',
    '31415',
    '31416',
    '31418',
    '31419',
    '31420',
    '31421',
    '31501',
    '31502',
    '31503',
    '31510',
    '31512',
    '31513',
    '31515',
    '31516',
    '31518',
    '31519',
    '31520',
    '31521',
    '31522',
    '31523',
    '31524',
    '31525',
    '31527',
    '31532',
    '31533',
    '31534',
    '31535',
    '31537',
    '31539',
    '31542',
    '31543',
    '31544',
    '31545',
    '31546',
    '31547',
    '31548',
    '31549',
    '31550',
    '31551',
    '31552',
    '31553',
    '31554',
    '31555',
    '31556',
    '31557',
    '31558',
    '31560',
    '31561',
    '31562',
    '31563',
    '31564',
    '31565',
    '31566',
    '31567',
    '31568',
    '31569',
    '31598',
    '31599',
    '31601',
    '31602',
    '31603',
    '31604',
    '31605',
    '31606',
    '31620',
    '31622',
    '31623',
    '31624',
    '31625',
    '31626',
    '31627',
    '31629',
    '31630',
    '31631',
    '31632',
    '31634',
    '31635',
    '31636',
    '31637',
    '31638',
    '31639',
    '31641',
    '31642',
    '31643',
    '31645',
    '31647',
    '31648',
    '31649',
    '31650',
    '31698',
    '31699',
    '31701',
    '31702',
    '31703',
    '31704',
    '31705',
    '31706',
    '31707',
    '31708',
    '31709',
    '31711',
    '31712',
    '31714',
    '31716',
    '31719',
    '31720',
    '31721',
    '31722',
    '31727',
    '31730',
    '31733',
    '31735',
    '31738',
    '31739',
    '31743',
    '31744',
    '31747',
    '31749',
    '31750',
    '31753',
    '31756',
    '31757',
    '31758',
    '31760',
    '31763',
    '31764',
    '31765',
    '31768',
    '31769',
    '31771',
    '31772',
    '31773',
    '31774',
    '31775',
    '31776',
    '31778',
    '31779',
    '31780',
    '31781',
    '31782',
    '31783',
    '31784',
    '31787',
    '31788',
    '31789',
    '31790',
    '31791',
    '31792',
    '31793',
    '31794',
    '31795',
    '31796',
    '31798',
    '31799',
    '31801',
    '31803',
    '31804',
    '31805',
    '31806',
    '31807',
    '31808',
    '31810',
    '31811',
    '31812',
    '31814',
    '31815',
    '31816',
    '31820',
    '31821',
    '31822',
    '31823',
    '31824',
    '31825',
    '31826',
    '31827',
    '31829',
    '31830',
    '31831',
    '31832',
    '31833',
    '31836',
    '31901',
    '31902',
    '31903',
    '31904',
    '31905',
    '31906',
    '31907',
    '31908',
    '31909',
    '31914',
    '31917',
    '31993',
    '31995',
    '31997',
    '31998',
    '31999',
    '39813',
    '39815',
    '39817',
    '39818',
    '39819',
    '39823',
    '39824',
    '39825',
    '39826',
    '39827',
    '39828',
    '39829',
    '39832',
    '39834',
    '39836',
    '39837',
    '39840',
    '39841',
    '39842',
    '39845',
    '39846',
    '39851',
    '39852',
    '39854',
    '39859',
    '39861',
    '39862',
    '39866',
    '39867',
    '39870',
    '39877',
    '39885',
    '39886',
    '39897',
    '39901',
  ],
  FL: [
    '32003',
    '32004',
    '32006',
    '32007',
    '32008',
    '32009',
    '32011',
    '32013',
    '32024',
    '32025',
    '32026',
    '32030',
    '32033',
    '32034',
    '32035',
    '32038',
    '32040',
    '32041',
    '32042',
    '32043',
    '32044',
    '32046',
    '32050',
    '32052',
    '32053',
    '32054',
    '32055',
    '32056',
    '32058',
    '32059',
    '32060',
    '32061',
    '32062',
    '32063',
    '32064',
    '32065',
    '32066',
    '32067',
    '32068',
    '32071',
    '32072',
    '32073',
    '32079',
    '32080',
    '32081',
    '32082',
    '32083',
    '32084',
    '32085',
    '32086',
    '32087',
    '32091',
    '32092',
    '32094',
    '32095',
    '32096',
    '32097',
    '32099',
    '32102',
    '32105',
    '32110',
    '32111',
    '32112',
    '32113',
    '32114',
    '32115',
    '32116',
    '32117',
    '32118',
    '32119',
    '32120',
    '32121',
    '32122',
    '32123',
    '32124',
    '32125',
    '32126',
    '32127',
    '32128',
    '32129',
    '32130',
    '32131',
    '32132',
    '32133',
    '32134',
    '32135',
    '32136',
    '32137',
    '32138',
    '32139',
    '32140',
    '32141',
    '32142',
    '32143',
    '32145',
    '32147',
    '32148',
    '32149',
    '32157',
    '32158',
    '32159',
    '32160',
    '32162',
    '32163',
    '32164',
    '32168',
    '32169',
    '32170',
    '32173',
    '32174',
    '32175',
    '32176',
    '32177',
    '32178',
    '32179',
    '32180',
    '32181',
    '32182',
    '32183',
    '32185',
    '32187',
    '32189',
    '32190',
    '32192',
    '32193',
    '32195',
    '32198',
    '32201',
    '32202',
    '32203',
    '32204',
    '32205',
    '32206',
    '32207',
    '32208',
    '32209',
    '32210',
    '32211',
    '32212',
    '32214',
    '32216',
    '32217',
    '32218',
    '32219',
    '32220',
    '32221',
    '32222',
    '32223',
    '32224',
    '32225',
    '32226',
    '32227',
    '32228',
    '32229',
    '32231',
    '32232',
    '32233',
    '32234',
    '32235',
    '32236',
    '32237',
    '32238',
    '32239',
    '32240',
    '32241',
    '32244',
    '32245',
    '32246',
    '32247',
    '32250',
    '32254',
    '32255',
    '32256',
    '32257',
    '32258',
    '32259',
    '32260',
    '32266',
    '32277',
    '32301',
    '32302',
    '32303',
    '32304',
    '32305',
    '32306',
    '32307',
    '32308',
    '32309',
    '32310',
    '32311',
    '32312',
    '32313',
    '32314',
    '32315',
    '32316',
    '32317',
    '32318',
    '32320',
    '32321',
    '32322',
    '32323',
    '32324',
    '32326',
    '32327',
    '32328',
    '32329',
    '32330',
    '32331',
    '32332',
    '32333',
    '32334',
    '32335',
    '32336',
    '32337',
    '32340',
    '32341',
    '32343',
    '32344',
    '32345',
    '32346',
    '32347',
    '32348',
    '32350',
    '32351',
    '32352',
    '32353',
    '32355',
    '32356',
    '32357',
    '32358',
    '32359',
    '32360',
    '32361',
    '32362',
    '32395',
    '32399',
    '32401',
    '32402',
    '32403',
    '32404',
    '32405',
    '32406',
    '32407',
    '32408',
    '32409',
    '32410',
    '32411',
    '32412',
    '32413',
    '32417',
    '32420',
    '32421',
    '32422',
    '32423',
    '32424',
    '32425',
    '32426',
    '32427',
    '32428',
    '32430',
    '32431',
    '32432',
    '32433',
    '32434',
    '32435',
    '32437',
    '32438',
    '32439',
    '32440',
    '32442',
    '32443',
    '32444',
    '32445',
    '32446',
    '32447',
    '32448',
    '32449',
    '32452',
    '32455',
    '32456',
    '32457',
    '32459',
    '32460',
    '32461',
    '32462',
    '32463',
    '32464',
    '32465',
    '32466',
    '32501',
    '32502',
    '32503',
    '32504',
    '32505',
    '32506',
    '32507',
    '32508',
    '32509',
    '32511',
    '32512',
    '32513',
    '32514',
    '32516',
    '32520',
    '32521',
    '32522',
    '32523',
    '32524',
    '32526',
    '32530',
    '32531',
    '32533',
    '32534',
    '32535',
    '32536',
    '32537',
    '32538',
    '32539',
    '32540',
    '32541',
    '32542',
    '32544',
    '32547',
    '32548',
    '32549',
    '32550',
    '32559',
    '32560',
    '32561',
    '32562',
    '32563',
    '32564',
    '32565',
    '32566',
    '32567',
    '32568',
    '32569',
    '32570',
    '32571',
    '32572',
    '32577',
    '32578',
    '32579',
    '32580',
    '32583',
    '32588',
    '32591',
    '32601',
    '32602',
    '32603',
    '32604',
    '32605',
    '32606',
    '32607',
    '32608',
    '32609',
    '32610',
    '32611',
    '32612',
    '32614',
    '32615',
    '32616',
    '32617',
    '32618',
    '32619',
    '32621',
    '32622',
    '32625',
    '32626',
    '32627',
    '32628',
    '32631',
    '32633',
    '32634',
    '32635',
    '32639',
    '32640',
    '32641',
    '32643',
    '32644',
    '32648',
    '32653',
    '32654',
    '32655',
    '32656',
    '32658',
    '32662',
    '32663',
    '32664',
    '32666',
    '32667',
    '32668',
    '32669',
    '32680',
    '32681',
    '32683',
    '32686',
    '32692',
    '32693',
    '32694',
    '32696',
    '32697',
    '32701',
    '32702',
    '32703',
    '32704',
    '32706',
    '32707',
    '32708',
    '32709',
    '32710',
    '32712',
    '32713',
    '32714',
    '32715',
    '32716',
    '32718',
    '32719',
    '32720',
    '32721',
    '32722',
    '32723',
    '32724',
    '32725',
    '32726',
    '32727',
    '32728',
    '32730',
    '32732',
    '32733',
    '32735',
    '32736',
    '32738',
    '32739',
    '32744',
    '32745',
    '32746',
    '32747',
    '32750',
    '32751',
    '32752',
    '32753',
    '32754',
    '32756',
    '32757',
    '32759',
    '32762',
    '32763',
    '32764',
    '32765',
    '32766',
    '32767',
    '32768',
    '32771',
    '32772',
    '32773',
    '32774',
    '32775',
    '32776',
    '32777',
    '32778',
    '32779',
    '32780',
    '32781',
    '32783',
    '32784',
    '32789',
    '32790',
    '32791',
    '32792',
    '32793',
    '32794',
    '32795',
    '32796',
    '32798',
    '32799',
    '32801',
    '32802',
    '32803',
    '32804',
    '32805',
    '32806',
    '32807',
    '32808',
    '32809',
    '32810',
    '32811',
    '32812',
    '32814',
    '32815',
    '32816',
    '32817',
    '32818',
    '32819',
    '32820',
    '32821',
    '32822',
    '32824',
    '32825',
    '32826',
    '32827',
    '32828',
    '32829',
    '32830',
    '32831',
    '32832',
    '32833',
    '32834',
    '32835',
    '32836',
    '32837',
    '32839',
    '32853',
    '32854',
    '32855',
    '32856',
    '32857',
    '32858',
    '32859',
    '32860',
    '32861',
    '32862',
    '32867',
    '32868',
    '32869',
    '32872',
    '32877',
    '32878',
    '32885',
    '32886',
    '32887',
    '32891',
    '32896',
    '32897',
    '32899',
    '32901',
    '32902',
    '32903',
    '32904',
    '32905',
    '32906',
    '32907',
    '32908',
    '32909',
    '32910',
    '32911',
    '32912',
    '32919',
    '32920',
    '32922',
    '32923',
    '32924',
    '32925',
    '32926',
    '32927',
    '32931',
    '32932',
    '32934',
    '32935',
    '32936',
    '32937',
    '32940',
    '32941',
    '32948',
    '32949',
    '32950',
    '32951',
    '32952',
    '32953',
    '32954',
    '32955',
    '32956',
    '32957',
    '32958',
    '32959',
    '32960',
    '32961',
    '32962',
    '32963',
    '32964',
    '32965',
    '32966',
    '32967',
    '32968',
    '32969',
    '32970',
    '32971',
    '32976',
    '32978',
    '33001',
    '33002',
    '33004',
    '33008',
    '33009',
    '33010',
    '33011',
    '33012',
    '33013',
    '33014',
    '33015',
    '33016',
    '33017',
    '33018',
    '33019',
    '33020',
    '33021',
    '33022',
    '33023',
    '33024',
    '33025',
    '33026',
    '33027',
    '33028',
    '33029',
    '33030',
    '33031',
    '33032',
    '33033',
    '33034',
    '33035',
    '33036',
    '33037',
    '33039',
    '33040',
    '33041',
    '33042',
    '33043',
    '33045',
    '33050',
    '33051',
    '33052',
    '33054',
    '33055',
    '33056',
    '33060',
    '33061',
    '33062',
    '33063',
    '33064',
    '33065',
    '33066',
    '33067',
    '33068',
    '33069',
    '33070',
    '33071',
    '33072',
    '33073',
    '33074',
    '33075',
    '33076',
    '33077',
    '33081',
    '33082',
    '33083',
    '33084',
    '33090',
    '33092',
    '33093',
    '33097',
    '33101',
    '33102',
    '33106',
    '33109',
    '33111',
    '33112',
    '33114',
    '33116',
    '33119',
    '33122',
    '33124',
    '33125',
    '33126',
    '33127',
    '33128',
    '33129',
    '33130',
    '33131',
    '33132',
    '33133',
    '33134',
    '33135',
    '33136',
    '33137',
    '33138',
    '33139',
    '33140',
    '33141',
    '33142',
    '33143',
    '33144',
    '33145',
    '33146',
    '33147',
    '33149',
    '33150',
    '33151',
    '33152',
    '33153',
    '33154',
    '33155',
    '33156',
    '33157',
    '33158',
    '33160',
    '33161',
    '33162',
    '33163',
    '33164',
    '33165',
    '33166',
    '33167',
    '33168',
    '33169',
    '33170',
    '33172',
    '33173',
    '33174',
    '33175',
    '33176',
    '33177',
    '33178',
    '33179',
    '33180',
    '33181',
    '33182',
    '33183',
    '33184',
    '33185',
    '33186',
    '33187',
    '33188',
    '33189',
    '33190',
    '33191',
    '33192',
    '33193',
    '33194',
    '33195',
    '33196',
    '33197',
    '33198',
    '33199',
    '33206',
    '33222',
    '33231',
    '33233',
    '33234',
    '33238',
    '33239',
    '33242',
    '33243',
    '33245',
    '33247',
    '33255',
    '33256',
    '33257',
    '33261',
    '33265',
    '33266',
    '33269',
    '33280',
    '33283',
    '33296',
    '33299',
    '33301',
    '33302',
    '33303',
    '33304',
    '33305',
    '33306',
    '33307',
    '33308',
    '33309',
    '33310',
    '33311',
    '33312',
    '33313',
    '33314',
    '33315',
    '33316',
    '33317',
    '33318',
    '33319',
    '33320',
    '33321',
    '33322',
    '33323',
    '33324',
    '33325',
    '33326',
    '33327',
    '33328',
    '33329',
    '33330',
    '33331',
    '33332',
    '33334',
    '33335',
    '33336',
    '33337',
    '33338',
    '33339',
    '33340',
    '33345',
    '33346',
    '33348',
    '33349',
    '33351',
    '33355',
    '33359',
    '33388',
    '33394',
    '33401',
    '33402',
    '33403',
    '33404',
    '33405',
    '33406',
    '33407',
    '33408',
    '33409',
    '33410',
    '33411',
    '33412',
    '33413',
    '33414',
    '33415',
    '33416',
    '33417',
    '33418',
    '33419',
    '33420',
    '33421',
    '33422',
    '33424',
    '33425',
    '33426',
    '33427',
    '33428',
    '33429',
    '33430',
    '33431',
    '33432',
    '33433',
    '33434',
    '33435',
    '33436',
    '33437',
    '33438',
    '33440',
    '33441',
    '33442',
    '33443',
    '33444',
    '33445',
    '33446',
    '33448',
    '33449',
    '33454',
    '33455',
    '33458',
    '33459',
    '33460',
    '33461',
    '33462',
    '33463',
    '33464',
    '33465',
    '33466',
    '33467',
    '33468',
    '33469',
    '33470',
    '33471',
    '33472',
    '33473',
    '33474',
    '33475',
    '33476',
    '33477',
    '33478',
    '33480',
    '33481',
    '33482',
    '33483',
    '33484',
    '33486',
    '33487',
    '33488',
    '33493',
    '33496',
    '33497',
    '33498',
    '33499',
    '33503',
    '33508',
    '33509',
    '33510',
    '33511',
    '33513',
    '33514',
    '33521',
    '33523',
    '33524',
    '33525',
    '33526',
    '33527',
    '33530',
    '33534',
    '33537',
    '33538',
    '33539',
    '33540',
    '33541',
    '33542',
    '33543',
    '33544',
    '33545',
    '33547',
    '33548',
    '33549',
    '33550',
    '33556',
    '33558',
    '33559',
    '33563',
    '33564',
    '33565',
    '33566',
    '33567',
    '33568',
    '33569',
    '33570',
    '33571',
    '33572',
    '33573',
    '33574',
    '33575',
    '33576',
    '33578',
    '33579',
    '33583',
    '33584',
    '33585',
    '33586',
    '33587',
    '33592',
    '33593',
    '33594',
    '33595',
    '33596',
    '33597',
    '33598',
    '33601',
    '33602',
    '33603',
    '33604',
    '33605',
    '33606',
    '33607',
    '33608',
    '33609',
    '33610',
    '33611',
    '33612',
    '33613',
    '33614',
    '33615',
    '33616',
    '33617',
    '33618',
    '33619',
    '33620',
    '33621',
    '33622',
    '33623',
    '33624',
    '33625',
    '33626',
    '33629',
    '33630',
    '33631',
    '33633',
    '33634',
    '33635',
    '33637',
    '33646',
    '33647',
    '33650',
    '33655',
    '33660',
    '33661',
    '33662',
    '33663',
    '33664',
    '33672',
    '33673',
    '33674',
    '33675',
    '33677',
    '33679',
    '33680',
    '33681',
    '33682',
    '33684',
    '33685',
    '33686',
    '33687',
    '33688',
    '33689',
    '33694',
    '33701',
    '33702',
    '33703',
    '33704',
    '33705',
    '33706',
    '33707',
    '33708',
    '33709',
    '33710',
    '33711',
    '33712',
    '33713',
    '33714',
    '33715',
    '33716',
    '33729',
    '33730',
    '33731',
    '33732',
    '33733',
    '33734',
    '33736',
    '33738',
    '33740',
    '33741',
    '33742',
    '33743',
    '33744',
    '33747',
    '33755',
    '33756',
    '33757',
    '33758',
    '33759',
    '33760',
    '33761',
    '33762',
    '33763',
    '33764',
    '33765',
    '33766',
    '33767',
    '33769',
    '33770',
    '33771',
    '33772',
    '33773',
    '33774',
    '33775',
    '33776',
    '33777',
    '33778',
    '33779',
    '33780',
    '33781',
    '33782',
    '33784',
    '33785',
    '33786',
    '33801',
    '33802',
    '33803',
    '33804',
    '33805',
    '33806',
    '33807',
    '33809',
    '33810',
    '33811',
    '33812',
    '33813',
    '33815',
    '33820',
    '33823',
    '33825',
    '33826',
    '33827',
    '33830',
    '33831',
    '33834',
    '33835',
    '33836',
    '33837',
    '33838',
    '33839',
    '33840',
    '33841',
    '33843',
    '33844',
    '33845',
    '33846',
    '33847',
    '33848',
    '33849',
    '33850',
    '33851',
    '33852',
    '33853',
    '33854',
    '33855',
    '33856',
    '33857',
    '33858',
    '33859',
    '33860',
    '33862',
    '33863',
    '33865',
    '33867',
    '33868',
    '33870',
    '33871',
    '33872',
    '33873',
    '33875',
    '33876',
    '33877',
    '33880',
    '33881',
    '33882',
    '33883',
    '33884',
    '33885',
    '33888',
    '33890',
    '33896',
    '33897',
    '33898',
    '33901',
    '33902',
    '33903',
    '33904',
    '33905',
    '33906',
    '33907',
    '33908',
    '33909',
    '33910',
    '33911',
    '33912',
    '33913',
    '33914',
    '33915',
    '33916',
    '33917',
    '33918',
    '33919',
    '33920',
    '33921',
    '33922',
    '33924',
    '33927',
    '33928',
    '33929',
    '33930',
    '33931',
    '33932',
    '33935',
    '33936',
    '33938',
    '33944',
    '33945',
    '33946',
    '33947',
    '33948',
    '33949',
    '33950',
    '33951',
    '33952',
    '33953',
    '33954',
    '33955',
    '33956',
    '33957',
    '33960',
    '33965',
    '33966',
    '33967',
    '33970',
    '33971',
    '33972',
    '33973',
    '33974',
    '33975',
    '33976',
    '33980',
    '33981',
    '33982',
    '33983',
    '33990',
    '33991',
    '33993',
    '33994',
    '34101',
    '34102',
    '34103',
    '34104',
    '34105',
    '34106',
    '34107',
    '34108',
    '34109',
    '34110',
    '34112',
    '34113',
    '34114',
    '34116',
    '34117',
    '34119',
    '34120',
    '34133',
    '34134',
    '34135',
    '34136',
    '34137',
    '34138',
    '34139',
    '34140',
    '34141',
    '34142',
    '34143',
    '34145',
    '34146',
    '34201',
    '34202',
    '34203',
    '34204',
    '34205',
    '34206',
    '34207',
    '34208',
    '34209',
    '34210',
    '34211',
    '34212',
    '34215',
    '34216',
    '34217',
    '34218',
    '34219',
    '34220',
    '34221',
    '34222',
    '34223',
    '34224',
    '34228',
    '34229',
    '34230',
    '34231',
    '34232',
    '34233',
    '34234',
    '34235',
    '34236',
    '34237',
    '34238',
    '34239',
    '34240',
    '34241',
    '34242',
    '34243',
    '34249',
    '34250',
    '34251',
    '34260',
    '34264',
    '34265',
    '34266',
    '34267',
    '34268',
    '34269',
    '34270',
    '34272',
    '34274',
    '34275',
    '34276',
    '34277',
    '34278',
    '34280',
    '34281',
    '34282',
    '34284',
    '34285',
    '34286',
    '34287',
    '34288',
    '34289',
    '34290',
    '34291',
    '34292',
    '34293',
    '34295',
    '34420',
    '34421',
    '34423',
    '34428',
    '34429',
    '34430',
    '34431',
    '34432',
    '34433',
    '34434',
    '34436',
    '34441',
    '34442',
    '34445',
    '34446',
    '34447',
    '34448',
    '34449',
    '34450',
    '34451',
    '34452',
    '34453',
    '34460',
    '34461',
    '34464',
    '34465',
    '34470',
    '34471',
    '34472',
    '34473',
    '34474',
    '34475',
    '34476',
    '34477',
    '34478',
    '34479',
    '34480',
    '34481',
    '34482',
    '34483',
    '34484',
    '34487',
    '34488',
    '34489',
    '34491',
    '34492',
    '34498',
    '34601',
    '34602',
    '34603',
    '34604',
    '34605',
    '34606',
    '34607',
    '34608',
    '34609',
    '34610',
    '34611',
    '34613',
    '34614',
    '34636',
    '34637',
    '34638',
    '34639',
    '34652',
    '34653',
    '34654',
    '34655',
    '34656',
    '34660',
    '34661',
    '34667',
    '34668',
    '34669',
    '34673',
    '34674',
    '34677',
    '34679',
    '34680',
    '34681',
    '34682',
    '34683',
    '34684',
    '34685',
    '34688',
    '34689',
    '34690',
    '34691',
    '34692',
    '34695',
    '34697',
    '34698',
    '34705',
    '34711',
    '34712',
    '34713',
    '34714',
    '34715',
    '34729',
    '34731',
    '34734',
    '34736',
    '34737',
    '34739',
    '34740',
    '34741',
    '34742',
    '34743',
    '34744',
    '34745',
    '34746',
    '34747',
    '34748',
    '34749',
    '34753',
    '34755',
    '34756',
    '34758',
    '34759',
    '34760',
    '34761',
    '34762',
    '34769',
    '34770',
    '34771',
    '34772',
    '34773',
    '34777',
    '34778',
    '34785',
    '34786',
    '34787',
    '34788',
    '34789',
    '34797',
    '34945',
    '34946',
    '34947',
    '34948',
    '34949',
    '34950',
    '34951',
    '34952',
    '34953',
    '34954',
    '34956',
    '34957',
    '34958',
    '34972',
    '34973',
    '34974',
    '34979',
    '34981',
    '34982',
    '34983',
    '34984',
    '34985',
    '34986',
    '34987',
    '34988',
    '34990',
    '34991',
    '34992',
    '34994',
    '34995',
    '34996',
    '34997',
  ],
  AL: [
    '35004',
    '35005',
    '35006',
    '35007',
    '35010',
    '35011',
    '35013',
    '35014',
    '35015',
    '35016',
    '35019',
    '35020',
    '35021',
    '35022',
    '35023',
    '35031',
    '35032',
    '35033',
    '35034',
    '35035',
    '35036',
    '35038',
    '35040',
    '35042',
    '35043',
    '35044',
    '35045',
    '35046',
    '35048',
    '35049',
    '35051',
    '35052',
    '35053',
    '35054',
    '35055',
    '35056',
    '35057',
    '35058',
    '35060',
    '35061',
    '35062',
    '35063',
    '35064',
    '35068',
    '35070',
    '35071',
    '35072',
    '35073',
    '35074',
    '35077',
    '35078',
    '35079',
    '35080',
    '35082',
    '35083',
    '35085',
    '35087',
    '35089',
    '35091',
    '35094',
    '35096',
    '35097',
    '35098',
    '35111',
    '35112',
    '35114',
    '35115',
    '35116',
    '35117',
    '35118',
    '35119',
    '35120',
    '35121',
    '35123',
    '35124',
    '35125',
    '35126',
    '35127',
    '35128',
    '35130',
    '35131',
    '35133',
    '35135',
    '35136',
    '35137',
    '35139',
    '35142',
    '35143',
    '35144',
    '35146',
    '35147',
    '35148',
    '35149',
    '35150',
    '35151',
    '35160',
    '35161',
    '35171',
    '35172',
    '35173',
    '35175',
    '35176',
    '35178',
    '35179',
    '35180',
    '35181',
    '35182',
    '35183',
    '35184',
    '35185',
    '35186',
    '35187',
    '35188',
    '35201',
    '35202',
    '35203',
    '35204',
    '35205',
    '35206',
    '35207',
    '35208',
    '35209',
    '35210',
    '35211',
    '35212',
    '35213',
    '35214',
    '35215',
    '35216',
    '35217',
    '35218',
    '35219',
    '35220',
    '35221',
    '35222',
    '35223',
    '35224',
    '35226',
    '35228',
    '35229',
    '35231',
    '35232',
    '35233',
    '35234',
    '35235',
    '35236',
    '35237',
    '35238',
    '35242',
    '35243',
    '35244',
    '35246',
    '35249',
    '35253',
    '35254',
    '35255',
    '35259',
    '35260',
    '35261',
    '35266',
    '35270',
    '35282',
    '35283',
    '35285',
    '35287',
    '35288',
    '35290',
    '35291',
    '35292',
    '35293',
    '35294',
    '35295',
    '35296',
    '35297',
    '35298',
    '35401',
    '35402',
    '35403',
    '35404',
    '35405',
    '35406',
    '35407',
    '35440',
    '35441',
    '35442',
    '35443',
    '35444',
    '35446',
    '35447',
    '35448',
    '35449',
    '35452',
    '35453',
    '35456',
    '35457',
    '35458',
    '35459',
    '35460',
    '35461',
    '35462',
    '35463',
    '35464',
    '35466',
    '35468',
    '35469',
    '35470',
    '35471',
    '35473',
    '35474',
    '35475',
    '35476',
    '35477',
    '35478',
    '35480',
    '35481',
    '35482',
    '35486',
    '35487',
    '35490',
    '35491',
    '35501',
    '35502',
    '35503',
    '35504',
    '35540',
    '35541',
    '35542',
    '35543',
    '35544',
    '35545',
    '35546',
    '35548',
    '35549',
    '35550',
    '35551',
    '35552',
    '35553',
    '35554',
    '35555',
    '35559',
    '35560',
    '35563',
    '35564',
    '35565',
    '35570',
    '35571',
    '35572',
    '35573',
    '35574',
    '35575',
    '35576',
    '35577',
    '35578',
    '35579',
    '35580',
    '35581',
    '35582',
    '35584',
    '35585',
    '35586',
    '35587',
    '35592',
    '35593',
    '35594',
    '35601',
    '35602',
    '35603',
    '35609',
    '35610',
    '35611',
    '35612',
    '35613',
    '35614',
    '35615',
    '35616',
    '35617',
    '35618',
    '35619',
    '35620',
    '35621',
    '35622',
    '35630',
    '35631',
    '35632',
    '35633',
    '35634',
    '35640',
    '35643',
    '35645',
    '35646',
    '35647',
    '35648',
    '35649',
    '35650',
    '35651',
    '35652',
    '35653',
    '35654',
    '35660',
    '35661',
    '35662',
    '35670',
    '35671',
    '35672',
    '35673',
    '35674',
    '35677',
    '35699',
    '35739',
    '35740',
    '35741',
    '35742',
    '35744',
    '35745',
    '35746',
    '35747',
    '35748',
    '35749',
    '35750',
    '35751',
    '35752',
    '35754',
    '35755',
    '35756',
    '35757',
    '35758',
    '35759',
    '35760',
    '35761',
    '35762',
    '35763',
    '35764',
    '35765',
    '35766',
    '35767',
    '35768',
    '35769',
    '35771',
    '35772',
    '35773',
    '35774',
    '35775',
    '35776',
    '35801',
    '35802',
    '35803',
    '35804',
    '35805',
    '35806',
    '35807',
    '35808',
    '35809',
    '35810',
    '35811',
    '35812',
    '35813',
    '35814',
    '35815',
    '35816',
    '35824',
    '35893',
    '35894',
    '35895',
    '35896',
    '35897',
    '35898',
    '35899',
    '35901',
    '35902',
    '35903',
    '35904',
    '35905',
    '35906',
    '35907',
    '35950',
    '35951',
    '35952',
    '35953',
    '35954',
    '35956',
    '35957',
    '35958',
    '35959',
    '35960',
    '35961',
    '35962',
    '35963',
    '35964',
    '35966',
    '35967',
    '35968',
    '35971',
    '35972',
    '35973',
    '35974',
    '35975',
    '35976',
    '35978',
    '35979',
    '35980',
    '35981',
    '35983',
    '35984',
    '35986',
    '35987',
    '35988',
    '35989',
    '35990',
    '36003',
    '36005',
    '36006',
    '36008',
    '36009',
    '36010',
    '36013',
    '36015',
    '36016',
    '36017',
    '36020',
    '36022',
    '36023',
    '36024',
    '36025',
    '36026',
    '36027',
    '36028',
    '36029',
    '36030',
    '36031',
    '36032',
    '36033',
    '36034',
    '36035',
    '36036',
    '36037',
    '36038',
    '36039',
    '36040',
    '36041',
    '36042',
    '36043',
    '36045',
    '36046',
    '36047',
    '36048',
    '36049',
    '36051',
    '36052',
    '36053',
    '36054',
    '36057',
    '36061',
    '36062',
    '36064',
    '36065',
    '36066',
    '36067',
    '36068',
    '36069',
    '36071',
    '36072',
    '36075',
    '36078',
    '36079',
    '36080',
    '36081',
    '36082',
    '36083',
    '36087',
    '36088',
    '36089',
    '36091',
    '36092',
    '36093',
    '36101',
    '36102',
    '36103',
    '36104',
    '36105',
    '36106',
    '36107',
    '36108',
    '36109',
    '36110',
    '36111',
    '36112',
    '36113',
    '36114',
    '36115',
    '36116',
    '36117',
    '36118',
    '36119',
    '36120',
    '36121',
    '36123',
    '36124',
    '36125',
    '36130',
    '36131',
    '36132',
    '36135',
    '36140',
    '36141',
    '36142',
    '36177',
    '36191',
    '36201',
    '36202',
    '36203',
    '36204',
    '36205',
    '36206',
    '36207',
    '36250',
    '36251',
    '36253',
    '36254',
    '36255',
    '36256',
    '36257',
    '36258',
    '36260',
    '36261',
    '36262',
    '36263',
    '36264',
    '36265',
    '36266',
    '36267',
    '36268',
    '36269',
    '36271',
    '36272',
    '36273',
    '36274',
    '36275',
    '36276',
    '36277',
    '36278',
    '36279',
    '36280',
    '36301',
    '36302',
    '36303',
    '36304',
    '36305',
    '36310',
    '36311',
    '36312',
    '36313',
    '36314',
    '36316',
    '36317',
    '36318',
    '36319',
    '36320',
    '36321',
    '36322',
    '36323',
    '36330',
    '36331',
    '36340',
    '36343',
    '36344',
    '36345',
    '36346',
    '36349',
    '36350',
    '36351',
    '36352',
    '36353',
    '36360',
    '36361',
    '36362',
    '36370',
    '36371',
    '36373',
    '36374',
    '36375',
    '36376',
    '36401',
    '36420',
    '36421',
    '36425',
    '36426',
    '36427',
    '36429',
    '36432',
    '36435',
    '36436',
    '36439',
    '36441',
    '36442',
    '36444',
    '36445',
    '36446',
    '36449',
    '36451',
    '36453',
    '36454',
    '36455',
    '36456',
    '36457',
    '36458',
    '36460',
    '36461',
    '36467',
    '36470',
    '36471',
    '36473',
    '36474',
    '36475',
    '36476',
    '36477',
    '36480',
    '36481',
    '36482',
    '36483',
    '36502',
    '36503',
    '36504',
    '36505',
    '36507',
    '36509',
    '36511',
    '36512',
    '36513',
    '36518',
    '36521',
    '36522',
    '36523',
    '36524',
    '36525',
    '36526',
    '36527',
    '36528',
    '36529',
    '36530',
    '36532',
    '36533',
    '36535',
    '36536',
    '36538',
    '36539',
    '36540',
    '36541',
    '36542',
    '36543',
    '36544',
    '36545',
    '36547',
    '36548',
    '36549',
    '36550',
    '36551',
    '36553',
    '36555',
    '36556',
    '36558',
    '36559',
    '36560',
    '36561',
    '36562',
    '36564',
    '36567',
    '36568',
    '36569',
    '36571',
    '36572',
    '36574',
    '36575',
    '36576',
    '36577',
    '36578',
    '36579',
    '36580',
    '36581',
    '36582',
    '36583',
    '36584',
    '36585',
    '36587',
    '36590',
    '36601',
    '36602',
    '36603',
    '36604',
    '36605',
    '36606',
    '36607',
    '36608',
    '36609',
    '36610',
    '36611',
    '36612',
    '36613',
    '36615',
    '36616',
    '36617',
    '36618',
    '36619',
    '36628',
    '36633',
    '36640',
    '36641',
    '36644',
    '36652',
    '36660',
    '36663',
    '36670',
    '36671',
    '36675',
    '36685',
    '36688',
    '36689',
    '36691',
    '36693',
    '36695',
    '36701',
    '36702',
    '36703',
    '36720',
    '36722',
    '36723',
    '36726',
    '36727',
    '36728',
    '36732',
    '36736',
    '36738',
    '36740',
    '36741',
    '36742',
    '36744',
    '36745',
    '36748',
    '36749',
    '36750',
    '36751',
    '36752',
    '36753',
    '36754',
    '36756',
    '36758',
    '36759',
    '36761',
    '36763',
    '36764',
    '36765',
    '36766',
    '36767',
    '36768',
    '36769',
    '36773',
    '36775',
    '36776',
    '36782',
    '36783',
    '36784',
    '36785',
    '36786',
    '36790',
    '36792',
    '36793',
    '36801',
    '36802',
    '36803',
    '36804',
    '36830',
    '36831',
    '36832',
    '36849',
    '36850',
    '36851',
    '36852',
    '36853',
    '36854',
    '36855',
    '36856',
    '36858',
    '36859',
    '36860',
    '36861',
    '36862',
    '36863',
    '36865',
    '36866',
    '36867',
    '36868',
    '36869',
    '36870',
    '36871',
    '36872',
    '36874',
    '36875',
    '36877',
    '36879',
    '36901',
    '36904',
    '36907',
    '36908',
    '36910',
    '36912',
    '36913',
    '36915',
    '36916',
    '36919',
    '36921',
    '36922',
    '36925',
  ],
  TN: [
    '37010',
    '37011',
    '37012',
    '37013',
    '37014',
    '37015',
    '37016',
    '37018',
    '37019',
    '37020',
    '37022',
    '37023',
    '37024',
    '37025',
    '37026',
    '37027',
    '37028',
    '37029',
    '37030',
    '37031',
    '37032',
    '37033',
    '37034',
    '37035',
    '37036',
    '37037',
    '37040',
    '37041',
    '37042',
    '37043',
    '37044',
    '37046',
    '37047',
    '37048',
    '37049',
    '37050',
    '37051',
    '37052',
    '37055',
    '37056',
    '37057',
    '37058',
    '37059',
    '37060',
    '37061',
    '37062',
    '37063',
    '37064',
    '37065',
    '37066',
    '37067',
    '37068',
    '37069',
    '37070',
    '37071',
    '37072',
    '37073',
    '37074',
    '37075',
    '37076',
    '37077',
    '37078',
    '37079',
    '37080',
    '37082',
    '37083',
    '37085',
    '37086',
    '37087',
    '37088',
    '37089',
    '37090',
    '37091',
    '37095',
    '37096',
    '37097',
    '37098',
    '37101',
    '37110',
    '37111',
    '37115',
    '37116',
    '37118',
    '37119',
    '37121',
    '37122',
    '37127',
    '37128',
    '37129',
    '37130',
    '37131',
    '37132',
    '37133',
    '37134',
    '37135',
    '37136',
    '37137',
    '37138',
    '37140',
    '37141',
    '37142',
    '37143',
    '37144',
    '37145',
    '37146',
    '37148',
    '37149',
    '37150',
    '37151',
    '37152',
    '37153',
    '37160',
    '37161',
    '37162',
    '37165',
    '37166',
    '37167',
    '37171',
    '37172',
    '37174',
    '37175',
    '37178',
    '37179',
    '37180',
    '37181',
    '37183',
    '37184',
    '37185',
    '37186',
    '37187',
    '37188',
    '37189',
    '37190',
    '37191',
    '37201',
    '37202',
    '37203',
    '37204',
    '37205',
    '37206',
    '37207',
    '37208',
    '37209',
    '37210',
    '37211',
    '37212',
    '37213',
    '37214',
    '37215',
    '37216',
    '37217',
    '37218',
    '37219',
    '37220',
    '37221',
    '37222',
    '37224',
    '37227',
    '37228',
    '37229',
    '37230',
    '37232',
    '37234',
    '37235',
    '37236',
    '37238',
    '37240',
    '37241',
    '37242',
    '37243',
    '37244',
    '37246',
    '37250',
    '37301',
    '37302',
    '37303',
    '37304',
    '37305',
    '37306',
    '37307',
    '37308',
    '37309',
    '37310',
    '37311',
    '37312',
    '37313',
    '37314',
    '37315',
    '37316',
    '37317',
    '37318',
    '37320',
    '37321',
    '37322',
    '37323',
    '37324',
    '37325',
    '37326',
    '37327',
    '37328',
    '37329',
    '37330',
    '37331',
    '37332',
    '37333',
    '37334',
    '37335',
    '37336',
    '37337',
    '37338',
    '37339',
    '37340',
    '37341',
    '37342',
    '37343',
    '37345',
    '37347',
    '37348',
    '37349',
    '37350',
    '37351',
    '37352',
    '37353',
    '37354',
    '37355',
    '37356',
    '37357',
    '37359',
    '37360',
    '37361',
    '37362',
    '37363',
    '37364',
    '37365',
    '37366',
    '37367',
    '37369',
    '37370',
    '37371',
    '37373',
    '37374',
    '37375',
    '37376',
    '37377',
    '37378',
    '37379',
    '37380',
    '37381',
    '37382',
    '37383',
    '37384',
    '37385',
    '37387',
    '37388',
    '37389',
    '37391',
    '37394',
    '37396',
    '37397',
    '37398',
    '37401',
    '37402',
    '37403',
    '37404',
    '37405',
    '37406',
    '37407',
    '37408',
    '37409',
    '37410',
    '37411',
    '37412',
    '37414',
    '37415',
    '37416',
    '37419',
    '37421',
    '37422',
    '37424',
    '37450',
    '37501',
    '37544',
    '37601',
    '37602',
    '37604',
    '37605',
    '37614',
    '37615',
    '37616',
    '37617',
    '37618',
    '37620',
    '37621',
    '37625',
    '37640',
    '37641',
    '37642',
    '37643',
    '37644',
    '37645',
    '37650',
    '37656',
    '37657',
    '37658',
    '37659',
    '37660',
    '37662',
    '37663',
    '37664',
    '37665',
    '37669',
    '37680',
    '37681',
    '37682',
    '37683',
    '37684',
    '37686',
    '37687',
    '37688',
    '37690',
    '37691',
    '37692',
    '37694',
    '37699',
    '37701',
    '37705',
    '37707',
    '37708',
    '37709',
    '37710',
    '37711',
    '37713',
    '37714',
    '37715',
    '37716',
    '37717',
    '37719',
    '37721',
    '37722',
    '37723',
    '37724',
    '37725',
    '37726',
    '37727',
    '37729',
    '37730',
    '37731',
    '37732',
    '37733',
    '37737',
    '37738',
    '37742',
    '37743',
    '37744',
    '37745',
    '37748',
    '37752',
    '37753',
    '37754',
    '37755',
    '37756',
    '37757',
    '37760',
    '37762',
    '37763',
    '37764',
    '37765',
    '37766',
    '37769',
    '37770',
    '37771',
    '37772',
    '37773',
    '37774',
    '37777',
    '37778',
    '37779',
    '37801',
    '37802',
    '37803',
    '37804',
    '37806',
    '37807',
    '37809',
    '37810',
    '37811',
    '37813',
    '37814',
    '37815',
    '37816',
    '37818',
    '37819',
    '37820',
    '37821',
    '37822',
    '37824',
    '37825',
    '37826',
    '37828',
    '37829',
    '37830',
    '37831',
    '37840',
    '37841',
    '37843',
    '37845',
    '37846',
    '37847',
    '37848',
    '37849',
    '37851',
    '37852',
    '37853',
    '37854',
    '37857',
    '37860',
    '37861',
    '37862',
    '37863',
    '37864',
    '37865',
    '37866',
    '37867',
    '37868',
    '37869',
    '37870',
    '37871',
    '37872',
    '37873',
    '37874',
    '37876',
    '37877',
    '37878',
    '37879',
    '37880',
    '37881',
    '37882',
    '37885',
    '37886',
    '37887',
    '37888',
    '37890',
    '37891',
    '37892',
    '37901',
    '37902',
    '37909',
    '37912',
    '37914',
    '37915',
    '37916',
    '37917',
    '37918',
    '37919',
    '37920',
    '37921',
    '37922',
    '37923',
    '37924',
    '37927',
    '37928',
    '37929',
    '37930',
    '37931',
    '37932',
    '37933',
    '37934',
    '37938',
    '37939',
    '37940',
    '37950',
    '37995',
    '37996',
    '37997',
    '37998',
    '38001',
    '38002',
    '38004',
    '38006',
    '38007',
    '38008',
    '38010',
    '38011',
    '38012',
    '38014',
    '38015',
    '38016',
    '38017',
    '38018',
    '38019',
    '38021',
    '38023',
    '38024',
    '38025',
    '38027',
    '38028',
    '38029',
    '38030',
    '38034',
    '38036',
    '38037',
    '38039',
    '38040',
    '38041',
    '38042',
    '38044',
    '38045',
    '38046',
    '38047',
    '38048',
    '38049',
    '38050',
    '38052',
    '38053',
    '38054',
    '38055',
    '38057',
    '38058',
    '38059',
    '38060',
    '38061',
    '38063',
    '38066',
    '38067',
    '38068',
    '38069',
    '38070',
    '38071',
    '38075',
    '38076',
    '38077',
    '38079',
    '38080',
    '38083',
    '38088',
    '38101',
    '38103',
    '38104',
    '38105',
    '38106',
    '38107',
    '38108',
    '38109',
    '38111',
    '38112',
    '38113',
    '38114',
    '38115',
    '38116',
    '38117',
    '38118',
    '38119',
    '38120',
    '38122',
    '38124',
    '38125',
    '38126',
    '38127',
    '38128',
    '38130',
    '38131',
    '38132',
    '38133',
    '38134',
    '38135',
    '38136',
    '38137',
    '38138',
    '38139',
    '38141',
    '38145',
    '38148',
    '38150',
    '38151',
    '38152',
    '38157',
    '38159',
    '38161',
    '38163',
    '38166',
    '38167',
    '38168',
    '38173',
    '38174',
    '38175',
    '38177',
    '38181',
    '38182',
    '38183',
    '38184',
    '38186',
    '38187',
    '38188',
    '38190',
    '38193',
    '38194',
    '38197',
    '38201',
    '38220',
    '38221',
    '38222',
    '38223',
    '38224',
    '38225',
    '38226',
    '38229',
    '38230',
    '38231',
    '38232',
    '38233',
    '38235',
    '38236',
    '38237',
    '38238',
    '38240',
    '38241',
    '38242',
    '38251',
    '38253',
    '38254',
    '38255',
    '38256',
    '38257',
    '38258',
    '38259',
    '38260',
    '38261',
    '38271',
    '38281',
    '38301',
    '38302',
    '38303',
    '38305',
    '38308',
    '38310',
    '38311',
    '38313',
    '38314',
    '38315',
    '38316',
    '38317',
    '38318',
    '38320',
    '38321',
    '38324',
    '38326',
    '38327',
    '38328',
    '38329',
    '38330',
    '38331',
    '38332',
    '38333',
    '38334',
    '38336',
    '38337',
    '38338',
    '38339',
    '38340',
    '38341',
    '38342',
    '38343',
    '38344',
    '38345',
    '38346',
    '38347',
    '38348',
    '38351',
    '38352',
    '38355',
    '38356',
    '38357',
    '38358',
    '38359',
    '38361',
    '38362',
    '38363',
    '38365',
    '38366',
    '38367',
    '38368',
    '38369',
    '38370',
    '38371',
    '38372',
    '38374',
    '38375',
    '38376',
    '38377',
    '38378',
    '38379',
    '38380',
    '38381',
    '38382',
    '38387',
    '38388',
    '38389',
    '38390',
    '38391',
    '38392',
    '38393',
    '38401',
    '38402',
    '38425',
    '38449',
    '38450',
    '38451',
    '38452',
    '38453',
    '38454',
    '38455',
    '38456',
    '38457',
    '38459',
    '38460',
    '38461',
    '38462',
    '38463',
    '38464',
    '38468',
    '38469',
    '38471',
    '38472',
    '38473',
    '38474',
    '38475',
    '38476',
    '38477',
    '38478',
    '38481',
    '38482',
    '38483',
    '38485',
    '38486',
    '38487',
    '38488',
    '38501',
    '38502',
    '38503',
    '38504',
    '38505',
    '38506',
    '38541',
    '38542',
    '38543',
    '38544',
    '38545',
    '38547',
    '38548',
    '38549',
    '38550',
    '38551',
    '38552',
    '38553',
    '38554',
    '38555',
    '38556',
    '38557',
    '38558',
    '38559',
    '38560',
    '38562',
    '38563',
    '38564',
    '38565',
    '38567',
    '38568',
    '38569',
    '38570',
    '38571',
    '38572',
    '38573',
    '38574',
    '38575',
    '38577',
    '38578',
    '38579',
    '38580',
    '38581',
    '38582',
    '38583',
    '38585',
    '38587',
    '38588',
    '38589',
  ],
  MS: [
    '38601',
    '38602',
    '38603',
    '38606',
    '38609',
    '38610',
    '38611',
    '38614',
    '38617',
    '38618',
    '38619',
    '38620',
    '38621',
    '38622',
    '38623',
    '38625',
    '38626',
    '38627',
    '38628',
    '38629',
    '38630',
    '38631',
    '38632',
    '38633',
    '38634',
    '38635',
    '38637',
    '38638',
    '38639',
    '38641',
    '38642',
    '38643',
    '38644',
    '38645',
    '38646',
    '38647',
    '38649',
    '38650',
    '38651',
    '38652',
    '38654',
    '38655',
    '38658',
    '38659',
    '38661',
    '38663',
    '38664',
    '38665',
    '38666',
    '38668',
    '38669',
    '38670',
    '38671',
    '38672',
    '38673',
    '38674',
    '38675',
    '38676',
    '38677',
    '38679',
    '38680',
    '38683',
    '38685',
    '38686',
    '38701',
    '38702',
    '38703',
    '38704',
    '38720',
    '38721',
    '38722',
    '38723',
    '38725',
    '38726',
    '38730',
    '38731',
    '38732',
    '38733',
    '38736',
    '38737',
    '38738',
    '38739',
    '38740',
    '38744',
    '38745',
    '38746',
    '38748',
    '38749',
    '38751',
    '38753',
    '38754',
    '38756',
    '38759',
    '38760',
    '38761',
    '38762',
    '38764',
    '38765',
    '38767',
    '38768',
    '38769',
    '38771',
    '38772',
    '38773',
    '38774',
    '38776',
    '38778',
    '38780',
    '38781',
    '38782',
    '38801',
    '38802',
    '38803',
    '38804',
    '38820',
    '38821',
    '38824',
    '38825',
    '38826',
    '38827',
    '38828',
    '38829',
    '38833',
    '38834',
    '38835',
    '38838',
    '38839',
    '38841',
    '38843',
    '38844',
    '38846',
    '38847',
    '38848',
    '38849',
    '38850',
    '38851',
    '38852',
    '38855',
    '38856',
    '38857',
    '38858',
    '38859',
    '38860',
    '38862',
    '38863',
    '38864',
    '38865',
    '38866',
    '38868',
    '38869',
    '38870',
    '38871',
    '38873',
    '38874',
    '38875',
    '38876',
    '38877',
    '38878',
    '38879',
    '38880',
    '38901',
    '38902',
    '38913',
    '38914',
    '38915',
    '38916',
    '38917',
    '38920',
    '38921',
    '38922',
    '38923',
    '38924',
    '38925',
    '38926',
    '38927',
    '38928',
    '38929',
    '38930',
    '38935',
    '38940',
    '38941',
    '38943',
    '38944',
    '38945',
    '38946',
    '38947',
    '38948',
    '38949',
    '38950',
    '38951',
    '38952',
    '38953',
    '38954',
    '38955',
    '38957',
    '38958',
    '38959',
    '38960',
    '38961',
    '38962',
    '38963',
    '38964',
    '38965',
    '38966',
    '38967',
    '39038',
    '39039',
    '39040',
    '39041',
    '39042',
    '39043',
    '39044',
    '39045',
    '39046',
    '39047',
    '39051',
    '39054',
    '39056',
    '39057',
    '39058',
    '39059',
    '39060',
    '39061',
    '39062',
    '39063',
    '39066',
    '39067',
    '39069',
    '39071',
    '39073',
    '39074',
    '39077',
    '39078',
    '39079',
    '39080',
    '39081',
    '39082',
    '39083',
    '39086',
    '39087',
    '39088',
    '39090',
    '39092',
    '39094',
    '39095',
    '39096',
    '39097',
    '39098',
    '39107',
    '39108',
    '39109',
    '39110',
    '39111',
    '39113',
    '39114',
    '39115',
    '39116',
    '39117',
    '39119',
    '39120',
    '39121',
    '39122',
    '39130',
    '39140',
    '39144',
    '39145',
    '39146',
    '39148',
    '39149',
    '39150',
    '39151',
    '39152',
    '39153',
    '39154',
    '39156',
    '39157',
    '39158',
    '39159',
    '39160',
    '39161',
    '39162',
    '39163',
    '39165',
    '39166',
    '39167',
    '39168',
    '39169',
    '39170',
    '39171',
    '39173',
    '39174',
    '39175',
    '39176',
    '39177',
    '39179',
    '39180',
    '39181',
    '39182',
    '39183',
    '39189',
    '39190',
    '39191',
    '39192',
    '39193',
    '39194',
    '39201',
    '39202',
    '39203',
    '39204',
    '39205',
    '39206',
    '39207',
    '39208',
    '39209',
    '39210',
    '39211',
    '39212',
    '39213',
    '39215',
    '39216',
    '39217',
    '39218',
    '39225',
    '39232',
    '39236',
    '39250',
    '39269',
    '39271',
    '39272',
    '39282',
    '39283',
    '39284',
    '39286',
    '39288',
    '39289',
    '39296',
    '39298',
    '39301',
    '39302',
    '39303',
    '39304',
    '39305',
    '39307',
    '39309',
    '39320',
    '39322',
    '39323',
    '39324',
    '39325',
    '39326',
    '39327',
    '39328',
    '39330',
    '39332',
    '39335',
    '39336',
    '39337',
    '39338',
    '39339',
    '39341',
    '39342',
    '39345',
    '39346',
    '39347',
    '39348',
    '39350',
    '39352',
    '39354',
    '39355',
    '39356',
    '39358',
    '39359',
    '39360',
    '39361',
    '39362',
    '39363',
    '39364',
    '39365',
    '39366',
    '39367',
    '39401',
    '39402',
    '39403',
    '39404',
    '39406',
    '39407',
    '39421',
    '39422',
    '39423',
    '39425',
    '39426',
    '39427',
    '39428',
    '39429',
    '39436',
    '39437',
    '39439',
    '39440',
    '39441',
    '39442',
    '39443',
    '39451',
    '39452',
    '39455',
    '39456',
    '39457',
    '39459',
    '39460',
    '39461',
    '39462',
    '39463',
    '39464',
    '39465',
    '39466',
    '39470',
    '39474',
    '39475',
    '39476',
    '39477',
    '39478',
    '39479',
    '39480',
    '39481',
    '39482',
    '39483',
    '39501',
    '39502',
    '39503',
    '39505',
    '39506',
    '39507',
    '39520',
    '39521',
    '39522',
    '39525',
    '39529',
    '39530',
    '39531',
    '39532',
    '39533',
    '39534',
    '39535',
    '39540',
    '39552',
    '39553',
    '39555',
    '39556',
    '39558',
    '39560',
    '39561',
    '39562',
    '39563',
    '39564',
    '39565',
    '39566',
    '39567',
    '39568',
    '39569',
    '39571',
    '39572',
    '39573',
    '39574',
    '39576',
    '39577',
    '39581',
    '39595',
    '39601',
    '39602',
    '39603',
    '39629',
    '39630',
    '39631',
    '39632',
    '39633',
    '39635',
    '39638',
    '39641',
    '39643',
    '39645',
    '39647',
    '39648',
    '39649',
    '39652',
    '39653',
    '39654',
    '39656',
    '39657',
    '39661',
    '39662',
    '39663',
    '39664',
    '39665',
    '39666',
    '39667',
    '39668',
    '39669',
    '39701',
    '39702',
    '39703',
    '39704',
    '39705',
    '39710',
    '39730',
    '39735',
    '39736',
    '39737',
    '39739',
    '39740',
    '39741',
    '39743',
    '39744',
    '39745',
    '39746',
    '39747',
    '39750',
    '39751',
    '39752',
    '39753',
    '39754',
    '39755',
    '39756',
    '39759',
    '39760',
    '39762',
    '39766',
    '39767',
    '39769',
    '39771',
    '39772',
    '39773',
    '39776',
  ],
  KY: [
    '40003',
    '40004',
    '40006',
    '40007',
    '40008',
    '40009',
    '40010',
    '40011',
    '40012',
    '40013',
    '40014',
    '40018',
    '40019',
    '40020',
    '40022',
    '40023',
    '40025',
    '40026',
    '40027',
    '40031',
    '40032',
    '40033',
    '40036',
    '40037',
    '40040',
    '40041',
    '40045',
    '40046',
    '40047',
    '40048',
    '40049',
    '40050',
    '40051',
    '40052',
    '40055',
    '40056',
    '40057',
    '40058',
    '40059',
    '40060',
    '40061',
    '40062',
    '40063',
    '40065',
    '40066',
    '40067',
    '40068',
    '40069',
    '40070',
    '40071',
    '40075',
    '40076',
    '40077',
    '40078',
    '40104',
    '40107',
    '40108',
    '40109',
    '40110',
    '40111',
    '40115',
    '40117',
    '40118',
    '40119',
    '40121',
    '40122',
    '40129',
    '40140',
    '40142',
    '40143',
    '40144',
    '40145',
    '40146',
    '40150',
    '40152',
    '40153',
    '40155',
    '40157',
    '40159',
    '40160',
    '40161',
    '40162',
    '40165',
    '40166',
    '40170',
    '40171',
    '40175',
    '40176',
    '40177',
    '40178',
    '40201',
    '40202',
    '40203',
    '40204',
    '40205',
    '40206',
    '40207',
    '40208',
    '40209',
    '40210',
    '40211',
    '40212',
    '40213',
    '40214',
    '40215',
    '40216',
    '40217',
    '40218',
    '40219',
    '40220',
    '40221',
    '40222',
    '40223',
    '40224',
    '40225',
    '40228',
    '40229',
    '40231',
    '40232',
    '40233',
    '40241',
    '40242',
    '40243',
    '40245',
    '40250',
    '40251',
    '40252',
    '40253',
    '40255',
    '40256',
    '40257',
    '40258',
    '40259',
    '40261',
    '40266',
    '40268',
    '40269',
    '40270',
    '40272',
    '40280',
    '40281',
    '40282',
    '40283',
    '40285',
    '40287',
    '40289',
    '40290',
    '40291',
    '40292',
    '40293',
    '40294',
    '40295',
    '40296',
    '40297',
    '40298',
    '40299',
    '40310',
    '40311',
    '40312',
    '40313',
    '40316',
    '40317',
    '40319',
    '40322',
    '40324',
    '40328',
    '40330',
    '40334',
    '40336',
    '40337',
    '40339',
    '40340',
    '40342',
    '40346',
    '40347',
    '40348',
    '40350',
    '40351',
    '40353',
    '40355',
    '40356',
    '40357',
    '40358',
    '40359',
    '40360',
    '40361',
    '40362',
    '40363',
    '40366',
    '40370',
    '40371',
    '40372',
    '40374',
    '40376',
    '40379',
    '40380',
    '40383',
    '40384',
    '40385',
    '40387',
    '40390',
    '40391',
    '40392',
    '40402',
    '40403',
    '40404',
    '40405',
    '40409',
    '40410',
    '40419',
    '40422',
    '40423',
    '40434',
    '40437',
    '40440',
    '40442',
    '40444',
    '40445',
    '40447',
    '40448',
    '40452',
    '40456',
    '40460',
    '40461',
    '40464',
    '40468',
    '40472',
    '40473',
    '40475',
    '40476',
    '40481',
    '40484',
    '40486',
    '40488',
    '40489',
    '40492',
    '40502',
    '40503',
    '40504',
    '40505',
    '40506',
    '40507',
    '40508',
    '40509',
    '40510',
    '40511',
    '40512',
    '40513',
    '40514',
    '40515',
    '40516',
    '40517',
    '40522',
    '40523',
    '40524',
    '40526',
    '40533',
    '40536',
    '40544',
    '40546',
    '40550',
    '40555',
    '40574',
    '40575',
    '40576',
    '40577',
    '40578',
    '40579',
    '40580',
    '40581',
    '40582',
    '40583',
    '40588',
    '40591',
    '40598',
    '40601',
    '40602',
    '40603',
    '40604',
    '40618',
    '40619',
    '40620',
    '40621',
    '40622',
    '40701',
    '40702',
    '40724',
    '40729',
    '40730',
    '40734',
    '40737',
    '40740',
    '40741',
    '40742',
    '40743',
    '40744',
    '40745',
    '40750',
    '40755',
    '40759',
    '40763',
    '40769',
    '40771',
    '40801',
    '40803',
    '40806',
    '40807',
    '40808',
    '40810',
    '40813',
    '40815',
    '40816',
    '40818',
    '40819',
    '40820',
    '40823',
    '40824',
    '40826',
    '40827',
    '40828',
    '40829',
    '40830',
    '40831',
    '40840',
    '40843',
    '40844',
    '40845',
    '40847',
    '40849',
    '40854',
    '40855',
    '40856',
    '40858',
    '40862',
    '40863',
    '40865',
    '40868',
    '40870',
    '40873',
    '40874',
    '40902',
    '40903',
    '40906',
    '40913',
    '40914',
    '40915',
    '40921',
    '40923',
    '40927',
    '40930',
    '40932',
    '40935',
    '40939',
    '40940',
    '40941',
    '40943',
    '40944',
    '40946',
    '40949',
    '40951',
    '40953',
    '40955',
    '40958',
    '40962',
    '40964',
    '40965',
    '40972',
    '40977',
    '40979',
    '40981',
    '40982',
    '40983',
    '40988',
    '40995',
    '40997',
    '41001',
    '41002',
    '41003',
    '41004',
    '41005',
    '41006',
    '41007',
    '41008',
    '41010',
    '41011',
    '41012',
    '41014',
    '41015',
    '41016',
    '41017',
    '41018',
    '41019',
    '41021',
    '41022',
    '41025',
    '41030',
    '41031',
    '41033',
    '41034',
    '41035',
    '41037',
    '41039',
    '41040',
    '41041',
    '41042',
    '41043',
    '41044',
    '41045',
    '41046',
    '41048',
    '41049',
    '41051',
    '41052',
    '41053',
    '41054',
    '41055',
    '41056',
    '41059',
    '41061',
    '41062',
    '41063',
    '41064',
    '41071',
    '41072',
    '41073',
    '41074',
    '41075',
    '41076',
    '41080',
    '41081',
    '41083',
    '41085',
    '41086',
    '41091',
    '41092',
    '41093',
    '41094',
    '41095',
    '41096',
    '41097',
    '41098',
    '41099',
    '41101',
    '41102',
    '41105',
    '41114',
    '41121',
    '41124',
    '41128',
    '41129',
    '41132',
    '41135',
    '41139',
    '41141',
    '41142',
    '41143',
    '41144',
    '41146',
    '41149',
    '41159',
    '41160',
    '41164',
    '41166',
    '41168',
    '41169',
    '41171',
    '41173',
    '41174',
    '41175',
    '41179',
    '41180',
    '41181',
    '41183',
    '41189',
    '41201',
    '41203',
    '41204',
    '41214',
    '41216',
    '41219',
    '41222',
    '41224',
    '41226',
    '41230',
    '41231',
    '41232',
    '41234',
    '41238',
    '41240',
    '41250',
    '41254',
    '41255',
    '41256',
    '41257',
    '41260',
    '41262',
    '41263',
    '41264',
    '41265',
    '41267',
    '41268',
    '41271',
    '41274',
    '41301',
    '41310',
    '41311',
    '41314',
    '41317',
    '41332',
    '41339',
    '41347',
    '41348',
    '41351',
    '41352',
    '41360',
    '41364',
    '41365',
    '41366',
    '41367',
    '41368',
    '41385',
    '41386',
    '41390',
    '41397',
    '41408',
    '41413',
    '41421',
    '41425',
    '41426',
    '41451',
    '41464',
    '41465',
    '41472',
    '41477',
    '41501',
    '41502',
    '41503',
    '41512',
    '41513',
    '41514',
    '41517',
    '41519',
    '41520',
    '41522',
    '41524',
    '41526',
    '41527',
    '41528',
    '41531',
    '41534',
    '41535',
    '41537',
    '41538',
    '41539',
    '41540',
    '41542',
    '41543',
    '41544',
    '41547',
    '41548',
    '41549',
    '41553',
    '41554',
    '41555',
    '41557',
    '41558',
    '41559',
    '41560',
    '41561',
    '41562',
    '41563',
    '41564',
    '41566',
    '41567',
    '41568',
    '41571',
    '41572',
    '41601',
    '41602',
    '41603',
    '41604',
    '41605',
    '41606',
    '41607',
    '41612',
    '41615',
    '41616',
    '41619',
    '41621',
    '41622',
    '41630',
    '41631',
    '41632',
    '41635',
    '41636',
    '41640',
    '41642',
    '41643',
    '41645',
    '41647',
    '41649',
    '41650',
    '41651',
    '41653',
    '41655',
    '41659',
    '41660',
    '41663',
    '41666',
    '41667',
    '41669',
    '41701',
    '41702',
    '41712',
    '41713',
    '41714',
    '41719',
    '41721',
    '41722',
    '41723',
    '41725',
    '41727',
    '41729',
    '41731',
    '41735',
    '41736',
    '41739',
    '41740',
    '41743',
    '41745',
    '41746',
    '41749',
    '41751',
    '41754',
    '41759',
    '41760',
    '41762',
    '41763',
    '41764',
    '41766',
    '41772',
    '41773',
    '41774',
    '41775',
    '41776',
    '41777',
    '41778',
    '41804',
    '41810',
    '41812',
    '41815',
    '41817',
    '41819',
    '41821',
    '41822',
    '41824',
    '41825',
    '41826',
    '41828',
    '41831',
    '41832',
    '41833',
    '41834',
    '41835',
    '41836',
    '41837',
    '41838',
    '41839',
    '41840',
    '41843',
    '41844',
    '41845',
    '41847',
    '41848',
    '41849',
    '41855',
    '41858',
    '41859',
    '41861',
    '41862',
    '42001',
    '42002',
    '42003',
    '42020',
    '42021',
    '42022',
    '42023',
    '42024',
    '42025',
    '42027',
    '42028',
    '42029',
    '42031',
    '42032',
    '42033',
    '42035',
    '42036',
    '42037',
    '42038',
    '42039',
    '42040',
    '42041',
    '42044',
    '42045',
    '42047',
    '42048',
    '42049',
    '42050',
    '42051',
    '42053',
    '42054',
    '42055',
    '42056',
    '42058',
    '42060',
    '42061',
    '42063',
    '42064',
    '42066',
    '42069',
    '42070',
    '42071',
    '42076',
    '42078',
    '42079',
    '42081',
    '42082',
    '42083',
    '42085',
    '42086',
    '42087',
    '42088',
    '42101',
    '42102',
    '42103',
    '42104',
    '42120',
    '42122',
    '42123',
    '42124',
    '42127',
    '42128',
    '42129',
    '42130',
    '42131',
    '42133',
    '42134',
    '42135',
    '42140',
    '42141',
    '42142',
    '42151',
    '42152',
    '42153',
    '42154',
    '42156',
    '42157',
    '42159',
    '42160',
    '42163',
    '42164',
    '42166',
    '42167',
    '42170',
    '42171',
    '42201',
    '42202',
    '42204',
    '42206',
    '42207',
    '42210',
    '42211',
    '42214',
    '42215',
    '42216',
    '42217',
    '42219',
    '42220',
    '42221',
    '42223',
    '42232',
    '42234',
    '42236',
    '42240',
    '42241',
    '42252',
    '42254',
    '42256',
    '42259',
    '42261',
    '42262',
    '42265',
    '42266',
    '42273',
    '42274',
    '42275',
    '42276',
    '42280',
    '42285',
    '42286',
    '42288',
    '42301',
    '42302',
    '42303',
    '42304',
    '42320',
    '42321',
    '42322',
    '42323',
    '42324',
    '42325',
    '42326',
    '42327',
    '42328',
    '42330',
    '42332',
    '42333',
    '42334',
    '42337',
    '42338',
    '42339',
    '42343',
    '42344',
    '42345',
    '42347',
    '42348',
    '42349',
    '42350',
    '42351',
    '42352',
    '42354',
    '42355',
    '42356',
    '42361',
    '42364',
    '42366',
    '42367',
    '42368',
    '42369',
    '42370',
    '42371',
    '42372',
    '42374',
    '42376',
    '42377',
    '42378',
    '42402',
    '42404',
    '42406',
    '42408',
    '42409',
    '42410',
    '42411',
    '42413',
    '42419',
    '42420',
    '42431',
    '42436',
    '42437',
    '42440',
    '42441',
    '42442',
    '42444',
    '42445',
    '42450',
    '42451',
    '42452',
    '42453',
    '42455',
    '42456',
    '42457',
    '42458',
    '42459',
    '42460',
    '42461',
    '42462',
    '42463',
    '42464',
    '42501',
    '42502',
    '42503',
    '42516',
    '42518',
    '42519',
    '42528',
    '42533',
    '42539',
    '42541',
    '42544',
    '42553',
    '42558',
    '42564',
    '42565',
    '42566',
    '42567',
    '42602',
    '42603',
    '42629',
    '42631',
    '42633',
    '42634',
    '42635',
    '42638',
    '42642',
    '42647',
    '42649',
    '42653',
    '42701',
    '42702',
    '42712',
    '42713',
    '42715',
    '42716',
    '42717',
    '42718',
    '42719',
    '42720',
    '42721',
    '42722',
    '42724',
    '42726',
    '42728',
    '42729',
    '42732',
    '42733',
    '42740',
    '42741',
    '42742',
    '42743',
    '42746',
    '42748',
    '42749',
    '42753',
    '42754',
    '42755',
    '42757',
    '42758',
    '42759',
    '42762',
    '42764',
    '42765',
    '42776',
    '42782',
    '42784',
    '42788',
  ],
  OH: [
    '43001',
    '43002',
    '43003',
    '43004',
    '43005',
    '43006',
    '43007',
    '43008',
    '43009',
    '43010',
    '43011',
    '43013',
    '43014',
    '43015',
    '43016',
    '43017',
    '43018',
    '43019',
    '43021',
    '43022',
    '43023',
    '43025',
    '43026',
    '43027',
    '43028',
    '43029',
    '43030',
    '43031',
    '43032',
    '43033',
    '43035',
    '43036',
    '43037',
    '43040',
    '43041',
    '43044',
    '43045',
    '43046',
    '43047',
    '43048',
    '43050',
    '43054',
    '43055',
    '43056',
    '43058',
    '43060',
    '43061',
    '43062',
    '43064',
    '43065',
    '43066',
    '43067',
    '43068',
    '43069',
    '43070',
    '43071',
    '43072',
    '43073',
    '43074',
    '43076',
    '43077',
    '43078',
    '43080',
    '43081',
    '43082',
    '43083',
    '43084',
    '43085',
    '43086',
    '43093',
    '43101',
    '43102',
    '43103',
    '43105',
    '43106',
    '43107',
    '43109',
    '43110',
    '43111',
    '43112',
    '43113',
    '43115',
    '43116',
    '43117',
    '43119',
    '43123',
    '43125',
    '43126',
    '43127',
    '43128',
    '43130',
    '43135',
    '43136',
    '43137',
    '43138',
    '43140',
    '43142',
    '43143',
    '43144',
    '43145',
    '43146',
    '43147',
    '43148',
    '43149',
    '43150',
    '43151',
    '43152',
    '43153',
    '43154',
    '43155',
    '43156',
    '43157',
    '43158',
    '43160',
    '43162',
    '43164',
    '43194',
    '43195',
    '43199',
    '43201',
    '43202',
    '43203',
    '43204',
    '43205',
    '43206',
    '43207',
    '43209',
    '43210',
    '43211',
    '43212',
    '43213',
    '43214',
    '43215',
    '43216',
    '43217',
    '43218',
    '43219',
    '43220',
    '43221',
    '43222',
    '43223',
    '43224',
    '43226',
    '43227',
    '43228',
    '43229',
    '43230',
    '43231',
    '43232',
    '43234',
    '43235',
    '43236',
    '43240',
    '43251',
    '43260',
    '43266',
    '43268',
    '43270',
    '43271',
    '43272',
    '43279',
    '43287',
    '43291',
    '43301',
    '43302',
    '43310',
    '43311',
    '43314',
    '43315',
    '43316',
    '43317',
    '43318',
    '43319',
    '43320',
    '43321',
    '43322',
    '43323',
    '43324',
    '43325',
    '43326',
    '43330',
    '43331',
    '43332',
    '43333',
    '43334',
    '43335',
    '43336',
    '43337',
    '43338',
    '43340',
    '43341',
    '43342',
    '43343',
    '43344',
    '43345',
    '43346',
    '43347',
    '43348',
    '43349',
    '43350',
    '43351',
    '43356',
    '43357',
    '43358',
    '43359',
    '43360',
    '43402',
    '43403',
    '43406',
    '43407',
    '43408',
    '43410',
    '43412',
    '43413',
    '43414',
    '43416',
    '43420',
    '43430',
    '43431',
    '43432',
    '43433',
    '43434',
    '43435',
    '43436',
    '43437',
    '43438',
    '43439',
    '43440',
    '43441',
    '43442',
    '43443',
    '43445',
    '43446',
    '43447',
    '43449',
    '43450',
    '43451',
    '43452',
    '43456',
    '43457',
    '43458',
    '43460',
    '43462',
    '43463',
    '43464',
    '43465',
    '43466',
    '43467',
    '43468',
    '43469',
    '43501',
    '43502',
    '43504',
    '43505',
    '43506',
    '43510',
    '43511',
    '43512',
    '43515',
    '43516',
    '43517',
    '43518',
    '43519',
    '43520',
    '43521',
    '43522',
    '43523',
    '43524',
    '43525',
    '43526',
    '43527',
    '43528',
    '43529',
    '43530',
    '43531',
    '43532',
    '43533',
    '43534',
    '43535',
    '43536',
    '43537',
    '43540',
    '43541',
    '43542',
    '43543',
    '43545',
    '43547',
    '43548',
    '43549',
    '43550',
    '43551',
    '43552',
    '43553',
    '43554',
    '43555',
    '43556',
    '43557',
    '43558',
    '43560',
    '43565',
    '43566',
    '43567',
    '43569',
    '43570',
    '43571',
    '43601',
    '43603',
    '43604',
    '43605',
    '43606',
    '43607',
    '43608',
    '43609',
    '43610',
    '43611',
    '43612',
    '43613',
    '43614',
    '43615',
    '43616',
    '43617',
    '43619',
    '43620',
    '43623',
    '43635',
    '43652',
    '43654',
    '43656',
    '43657',
    '43659',
    '43660',
    '43661',
    '43666',
    '43667',
    '43681',
    '43682',
    '43697',
    '43699',
    '43701',
    '43702',
    '43711',
    '43713',
    '43716',
    '43717',
    '43718',
    '43719',
    '43720',
    '43721',
    '43722',
    '43723',
    '43724',
    '43725',
    '43727',
    '43728',
    '43730',
    '43731',
    '43732',
    '43733',
    '43734',
    '43735',
    '43736',
    '43738',
    '43739',
    '43740',
    '43746',
    '43747',
    '43748',
    '43749',
    '43750',
    '43752',
    '43754',
    '43755',
    '43756',
    '43757',
    '43758',
    '43759',
    '43760',
    '43761',
    '43762',
    '43764',
    '43766',
    '43767',
    '43768',
    '43771',
    '43772',
    '43773',
    '43777',
    '43778',
    '43779',
    '43780',
    '43782',
    '43783',
    '43786',
    '43787',
    '43788',
    '43791',
    '43793',
    '43802',
    '43803',
    '43804',
    '43805',
    '43811',
    '43812',
    '43821',
    '43822',
    '43824',
    '43828',
    '43830',
    '43832',
    '43836',
    '43837',
    '43840',
    '43842',
    '43843',
    '43844',
    '43845',
    '43901',
    '43902',
    '43903',
    '43905',
    '43906',
    '43907',
    '43908',
    '43909',
    '43910',
    '43912',
    '43913',
    '43914',
    '43915',
    '43916',
    '43917',
    '43920',
    '43925',
    '43926',
    '43927',
    '43928',
    '43930',
    '43931',
    '43932',
    '43933',
    '43934',
    '43935',
    '43937',
    '43938',
    '43939',
    '43940',
    '43941',
    '43942',
    '43943',
    '43944',
    '43945',
    '43946',
    '43947',
    '43948',
    '43950',
    '43951',
    '43952',
    '43953',
    '43961',
    '43962',
    '43963',
    '43964',
    '43967',
    '43968',
    '43970',
    '43971',
    '43972',
    '43973',
    '43974',
    '43976',
    '43977',
    '43981',
    '43983',
    '43984',
    '43985',
    '43986',
    '43988',
    '44001',
    '44003',
    '44004',
    '44005',
    '44010',
    '44011',
    '44012',
    '44017',
    '44021',
    '44022',
    '44023',
    '44024',
    '44026',
    '44028',
    '44030',
    '44032',
    '44033',
    '44035',
    '44036',
    '44039',
    '44040',
    '44041',
    '44044',
    '44045',
    '44046',
    '44047',
    '44048',
    '44049',
    '44050',
    '44052',
    '44053',
    '44054',
    '44055',
    '44056',
    '44057',
    '44060',
    '44061',
    '44062',
    '44064',
    '44065',
    '44067',
    '44068',
    '44070',
    '44072',
    '44073',
    '44074',
    '44076',
    '44077',
    '44080',
    '44081',
    '44082',
    '44084',
    '44085',
    '44086',
    '44087',
    '44088',
    '44089',
    '44090',
    '44092',
    '44093',
    '44094',
    '44095',
    '44096',
    '44097',
    '44099',
    '44101',
    '44102',
    '44103',
    '44104',
    '44105',
    '44106',
    '44107',
    '44108',
    '44109',
    '44110',
    '44111',
    '44112',
    '44113',
    '44114',
    '44115',
    '44116',
    '44117',
    '44118',
    '44119',
    '44120',
    '44121',
    '44122',
    '44123',
    '44124',
    '44125',
    '44126',
    '44127',
    '44128',
    '44129',
    '44130',
    '44131',
    '44132',
    '44133',
    '44134',
    '44135',
    '44136',
    '44137',
    '44138',
    '44139',
    '44140',
    '44141',
    '44142',
    '44143',
    '44144',
    '44145',
    '44146',
    '44147',
    '44149',
    '44181',
    '44188',
    '44190',
    '44191',
    '44192',
    '44193',
    '44194',
    '44195',
    '44197',
    '44198',
    '44199',
    '44201',
    '44202',
    '44203',
    '44210',
    '44211',
    '44212',
    '44214',
    '44215',
    '44216',
    '44217',
    '44221',
    '44222',
    '44223',
    '44224',
    '44230',
    '44231',
    '44232',
    '44233',
    '44234',
    '44235',
    '44236',
    '44237',
    '44240',
    '44241',
    '44242',
    '44243',
    '44250',
    '44251',
    '44253',
    '44254',
    '44255',
    '44256',
    '44258',
    '44260',
    '44262',
    '44264',
    '44265',
    '44266',
    '44270',
    '44272',
    '44273',
    '44274',
    '44275',
    '44276',
    '44278',
    '44280',
    '44281',
    '44282',
    '44285',
    '44286',
    '44287',
    '44288',
    '44301',
    '44302',
    '44303',
    '44304',
    '44305',
    '44306',
    '44307',
    '44308',
    '44309',
    '44310',
    '44311',
    '44312',
    '44313',
    '44314',
    '44315',
    '44316',
    '44317',
    '44319',
    '44320',
    '44321',
    '44325',
    '44326',
    '44328',
    '44333',
    '44334',
    '44372',
    '44396',
    '44398',
    '44401',
    '44402',
    '44403',
    '44404',
    '44405',
    '44406',
    '44408',
    '44410',
    '44411',
    '44412',
    '44413',
    '44415',
    '44416',
    '44417',
    '44418',
    '44420',
    '44422',
    '44423',
    '44424',
    '44425',
    '44427',
    '44428',
    '44429',
    '44430',
    '44431',
    '44432',
    '44436',
    '44437',
    '44438',
    '44439',
    '44440',
    '44441',
    '44442',
    '44443',
    '44444',
    '44445',
    '44446',
    '44449',
    '44450',
    '44451',
    '44452',
    '44453',
    '44454',
    '44455',
    '44460',
    '44470',
    '44471',
    '44473',
    '44481',
    '44482',
    '44483',
    '44484',
    '44485',
    '44486',
    '44490',
    '44491',
    '44492',
    '44493',
    '44501',
    '44502',
    '44503',
    '44504',
    '44505',
    '44506',
    '44507',
    '44509',
    '44510',
    '44511',
    '44512',
    '44513',
    '44514',
    '44515',
    '44555',
    '44601',
    '44606',
    '44607',
    '44608',
    '44609',
    '44610',
    '44611',
    '44612',
    '44613',
    '44614',
    '44615',
    '44617',
    '44618',
    '44619',
    '44620',
    '44621',
    '44622',
    '44624',
    '44625',
    '44626',
    '44627',
    '44628',
    '44629',
    '44630',
    '44632',
    '44633',
    '44634',
    '44636',
    '44637',
    '44638',
    '44639',
    '44640',
    '44641',
    '44643',
    '44644',
    '44645',
    '44646',
    '44647',
    '44648',
    '44650',
    '44651',
    '44652',
    '44653',
    '44654',
    '44656',
    '44657',
    '44659',
    '44660',
    '44661',
    '44662',
    '44663',
    '44665',
    '44666',
    '44667',
    '44669',
    '44670',
    '44671',
    '44672',
    '44675',
    '44676',
    '44677',
    '44678',
    '44679',
    '44680',
    '44681',
    '44682',
    '44683',
    '44685',
    '44687',
    '44688',
    '44689',
    '44690',
    '44691',
    '44693',
    '44695',
    '44697',
    '44699',
    '44701',
    '44702',
    '44703',
    '44704',
    '44705',
    '44706',
    '44707',
    '44708',
    '44709',
    '44710',
    '44711',
    '44714',
    '44718',
    '44720',
    '44721',
    '44730',
    '44735',
    '44750',
    '44767',
    '44799',
    '44802',
    '44804',
    '44805',
    '44807',
    '44809',
    '44811',
    '44813',
    '44814',
    '44815',
    '44816',
    '44817',
    '44818',
    '44820',
    '44822',
    '44824',
    '44825',
    '44826',
    '44827',
    '44828',
    '44830',
    '44833',
    '44836',
    '44837',
    '44838',
    '44839',
    '44840',
    '44841',
    '44842',
    '44843',
    '44844',
    '44845',
    '44846',
    '44847',
    '44848',
    '44849',
    '44850',
    '44851',
    '44853',
    '44854',
    '44855',
    '44856',
    '44857',
    '44859',
    '44860',
    '44861',
    '44862',
    '44864',
    '44865',
    '44866',
    '44867',
    '44870',
    '44871',
    '44874',
    '44875',
    '44878',
    '44880',
    '44881',
    '44882',
    '44883',
    '44887',
    '44888',
    '44889',
    '44890',
    '44901',
    '44902',
    '44903',
    '44904',
    '44905',
    '44906',
    '44907',
    '45001',
    '45002',
    '45003',
    '45004',
    '45005',
    '45011',
    '45012',
    '45013',
    '45014',
    '45015',
    '45018',
    '45030',
    '45032',
    '45033',
    '45034',
    '45036',
    '45039',
    '45040',
    '45041',
    '45042',
    '45044',
    '45050',
    '45051',
    '45052',
    '45053',
    '45054',
    '45055',
    '45056',
    '45061',
    '45062',
    '45063',
    '45064',
    '45065',
    '45066',
    '45067',
    '45068',
    '45069',
    '45070',
    '45071',
    '45101',
    '45102',
    '45103',
    '45105',
    '45106',
    '45107',
    '45111',
    '45112',
    '45113',
    '45114',
    '45115',
    '45118',
    '45119',
    '45120',
    '45121',
    '45122',
    '45123',
    '45130',
    '45131',
    '45132',
    '45133',
    '45135',
    '45140',
    '45142',
    '45144',
    '45146',
    '45147',
    '45148',
    '45150',
    '45152',
    '45153',
    '45154',
    '45155',
    '45156',
    '45157',
    '45158',
    '45159',
    '45160',
    '45162',
    '45164',
    '45166',
    '45167',
    '45168',
    '45169',
    '45171',
    '45172',
    '45174',
    '45176',
    '45177',
    '45201',
    '45202',
    '45203',
    '45204',
    '45205',
    '45206',
    '45207',
    '45208',
    '45209',
    '45211',
    '45212',
    '45213',
    '45214',
    '45215',
    '45216',
    '45217',
    '45218',
    '45219',
    '45220',
    '45221',
    '45222',
    '45223',
    '45224',
    '45225',
    '45226',
    '45227',
    '45229',
    '45230',
    '45231',
    '45232',
    '45233',
    '45234',
    '45235',
    '45236',
    '45237',
    '45238',
    '45239',
    '45240',
    '45241',
    '45242',
    '45243',
    '45244',
    '45245',
    '45246',
    '45247',
    '45248',
    '45249',
    '45250',
    '45251',
    '45252',
    '45253',
    '45254',
    '45255',
    '45258',
    '45262',
    '45263',
    '45264',
    '45267',
    '45268',
    '45269',
    '45270',
    '45271',
    '45273',
    '45274',
    '45275',
    '45277',
    '45280',
    '45296',
    '45298',
    '45299',
    '45301',
    '45302',
    '45303',
    '45304',
    '45305',
    '45306',
    '45307',
    '45308',
    '45309',
    '45310',
    '45311',
    '45312',
    '45314',
    '45315',
    '45316',
    '45317',
    '45318',
    '45319',
    '45320',
    '45321',
    '45322',
    '45323',
    '45324',
    '45325',
    '45326',
    '45327',
    '45328',
    '45330',
    '45331',
    '45332',
    '45333',
    '45334',
    '45335',
    '45336',
    '45337',
    '45338',
    '45339',
    '45340',
    '45341',
    '45342',
    '45343',
    '45344',
    '45345',
    '45346',
    '45347',
    '45348',
    '45349',
    '45350',
    '45351',
    '45352',
    '45353',
    '45354',
    '45356',
    '45358',
    '45359',
    '45360',
    '45361',
    '45362',
    '45363',
    '45365',
    '45367',
    '45368',
    '45369',
    '45370',
    '45371',
    '45372',
    '45373',
    '45374',
    '45377',
    '45378',
    '45380',
    '45381',
    '45382',
    '45383',
    '45384',
    '45385',
    '45387',
    '45388',
    '45389',
    '45390',
    '45401',
    '45402',
    '45403',
    '45404',
    '45405',
    '45406',
    '45409',
    '45410',
    '45412',
    '45413',
    '45414',
    '45415',
    '45416',
    '45417',
    '45419',
    '45420',
    '45422',
    '45423',
    '45424',
    '45426',
    '45428',
    '45429',
    '45430',
    '45431',
    '45432',
    '45433',
    '45434',
    '45435',
    '45437',
    '45439',
    '45440',
    '45441',
    '45448',
    '45449',
    '45458',
    '45459',
    '45469',
    '45470',
    '45475',
    '45479',
    '45481',
    '45482',
    '45490',
    '45501',
    '45502',
    '45503',
    '45504',
    '45505',
    '45506',
    '45601',
    '45612',
    '45613',
    '45614',
    '45616',
    '45617',
    '45618',
    '45619',
    '45620',
    '45621',
    '45622',
    '45623',
    '45624',
    '45628',
    '45629',
    '45630',
    '45631',
    '45633',
    '45634',
    '45636',
    '45638',
    '45640',
    '45642',
    '45643',
    '45644',
    '45645',
    '45646',
    '45647',
    '45648',
    '45650',
    '45651',
    '45652',
    '45653',
    '45654',
    '45656',
    '45657',
    '45658',
    '45659',
    '45660',
    '45661',
    '45662',
    '45663',
    '45669',
    '45671',
    '45672',
    '45673',
    '45674',
    '45675',
    '45677',
    '45678',
    '45679',
    '45680',
    '45681',
    '45682',
    '45683',
    '45684',
    '45685',
    '45686',
    '45687',
    '45688',
    '45690',
    '45692',
    '45693',
    '45694',
    '45695',
    '45696',
    '45697',
    '45698',
    '45699',
    '45701',
    '45710',
    '45711',
    '45712',
    '45713',
    '45714',
    '45715',
    '45716',
    '45717',
    '45719',
    '45720',
    '45721',
    '45723',
    '45724',
    '45727',
    '45729',
    '45732',
    '45734',
    '45735',
    '45739',
    '45740',
    '45741',
    '45742',
    '45743',
    '45744',
    '45745',
    '45746',
    '45750',
    '45760',
    '45761',
    '45764',
    '45766',
    '45767',
    '45768',
    '45769',
    '45770',
    '45771',
    '45772',
    '45773',
    '45775',
    '45776',
    '45777',
    '45778',
    '45779',
    '45780',
    '45782',
    '45783',
    '45784',
    '45786',
    '45787',
    '45788',
    '45789',
    '45801',
    '45802',
    '45804',
    '45805',
    '45806',
    '45807',
    '45808',
    '45809',
    '45810',
    '45812',
    '45813',
    '45814',
    '45815',
    '45816',
    '45817',
    '45819',
    '45820',
    '45821',
    '45822',
    '45826',
    '45827',
    '45828',
    '45830',
    '45831',
    '45832',
    '45833',
    '45835',
    '45836',
    '45837',
    '45838',
    '45839',
    '45840',
    '45841',
    '45843',
    '45844',
    '45845',
    '45846',
    '45848',
    '45849',
    '45850',
    '45851',
    '45853',
    '45854',
    '45855',
    '45856',
    '45858',
    '45859',
    '45860',
    '45861',
    '45862',
    '45863',
    '45864',
    '45865',
    '45866',
    '45867',
    '45868',
    '45869',
    '45870',
    '45871',
    '45872',
    '45873',
    '45874',
    '45875',
    '45876',
    '45877',
    '45879',
    '45880',
    '45881',
    '45882',
    '45883',
    '45884',
    '45885',
    '45886',
    '45887',
    '45888',
    '45889',
    '45890',
    '45891',
    '45893',
    '45894',
    '45895',
    '45896',
    '45897',
    '45898',
    '45899',
    '45999',
  ],
  IN: [
    '46001',
    '46011',
    '46012',
    '46013',
    '46014',
    '46015',
    '46016',
    '46017',
    '46018',
    '46030',
    '46031',
    '46032',
    '46033',
    '46034',
    '46035',
    '46036',
    '46037',
    '46038',
    '46039',
    '46040',
    '46041',
    '46044',
    '46045',
    '46047',
    '46048',
    '46049',
    '46050',
    '46051',
    '46052',
    '46055',
    '46056',
    '46057',
    '46058',
    '46060',
    '46061',
    '46062',
    '46063',
    '46064',
    '46065',
    '46067',
    '46068',
    '46069',
    '46070',
    '46071',
    '46072',
    '46074',
    '46075',
    '46076',
    '46077',
    '46082',
    '46085',
    '46102',
    '46103',
    '46104',
    '46105',
    '46106',
    '46107',
    '46110',
    '46111',
    '46112',
    '46113',
    '46115',
    '46117',
    '46118',
    '46120',
    '46121',
    '46122',
    '46123',
    '46124',
    '46125',
    '46126',
    '46127',
    '46128',
    '46129',
    '46130',
    '46131',
    '46133',
    '46135',
    '46140',
    '46142',
    '46143',
    '46144',
    '46146',
    '46147',
    '46148',
    '46149',
    '46150',
    '46151',
    '46154',
    '46155',
    '46156',
    '46157',
    '46158',
    '46160',
    '46161',
    '46162',
    '46163',
    '46164',
    '46165',
    '46166',
    '46167',
    '46168',
    '46170',
    '46171',
    '46172',
    '46173',
    '46175',
    '46176',
    '46180',
    '46181',
    '46182',
    '46183',
    '46184',
    '46186',
    '46201',
    '46202',
    '46203',
    '46204',
    '46205',
    '46206',
    '46207',
    '46208',
    '46209',
    '46213',
    '46214',
    '46216',
    '46217',
    '46218',
    '46219',
    '46220',
    '46221',
    '46222',
    '46224',
    '46225',
    '46226',
    '46227',
    '46228',
    '46229',
    '46230',
    '46231',
    '46234',
    '46235',
    '46236',
    '46237',
    '46239',
    '46240',
    '46241',
    '46242',
    '46244',
    '46247',
    '46249',
    '46250',
    '46251',
    '46253',
    '46254',
    '46255',
    '46256',
    '46259',
    '46260',
    '46262',
    '46268',
    '46277',
    '46278',
    '46280',
    '46282',
    '46283',
    '46285',
    '46288',
    '46290',
    '46298',
    '46301',
    '46302',
    '46303',
    '46304',
    '46307',
    '46308',
    '46310',
    '46311',
    '46312',
    '46319',
    '46320',
    '46321',
    '46322',
    '46323',
    '46324',
    '46325',
    '46327',
    '46340',
    '46341',
    '46342',
    '46345',
    '46346',
    '46347',
    '46348',
    '46349',
    '46350',
    '46352',
    '46355',
    '46356',
    '46360',
    '46361',
    '46365',
    '46366',
    '46368',
    '46371',
    '46372',
    '46373',
    '46374',
    '46375',
    '46376',
    '46377',
    '46379',
    '46380',
    '46381',
    '46382',
    '46383',
    '46384',
    '46385',
    '46390',
    '46391',
    '46392',
    '46393',
    '46394',
    '46401',
    '46402',
    '46403',
    '46404',
    '46405',
    '46406',
    '46407',
    '46408',
    '46409',
    '46410',
    '46411',
    '46501',
    '46502',
    '46504',
    '46506',
    '46507',
    '46508',
    '46510',
    '46511',
    '46513',
    '46514',
    '46515',
    '46516',
    '46517',
    '46524',
    '46526',
    '46527',
    '46528',
    '46530',
    '46531',
    '46532',
    '46534',
    '46536',
    '46537',
    '46538',
    '46539',
    '46540',
    '46542',
    '46543',
    '46544',
    '46545',
    '46546',
    '46550',
    '46552',
    '46553',
    '46554',
    '46555',
    '46556',
    '46561',
    '46562',
    '46563',
    '46565',
    '46567',
    '46570',
    '46571',
    '46572',
    '46573',
    '46574',
    '46580',
    '46581',
    '46582',
    '46590',
    '46595',
    '46601',
    '46613',
    '46614',
    '46615',
    '46616',
    '46617',
    '46619',
    '46624',
    '46626',
    '46628',
    '46634',
    '46635',
    '46637',
    '46660',
    '46680',
    '46699',
    '46701',
    '46702',
    '46703',
    '46704',
    '46705',
    '46706',
    '46710',
    '46711',
    '46713',
    '46714',
    '46721',
    '46723',
    '46725',
    '46730',
    '46731',
    '46732',
    '46733',
    '46737',
    '46738',
    '46740',
    '46741',
    '46742',
    '46743',
    '46745',
    '46746',
    '46747',
    '46748',
    '46750',
    '46755',
    '46759',
    '46760',
    '46761',
    '46763',
    '46764',
    '46765',
    '46766',
    '46767',
    '46769',
    '46770',
    '46771',
    '46772',
    '46773',
    '46774',
    '46776',
    '46777',
    '46778',
    '46779',
    '46780',
    '46781',
    '46782',
    '46783',
    '46784',
    '46785',
    '46786',
    '46787',
    '46788',
    '46789',
    '46791',
    '46792',
    '46793',
    '46794',
    '46795',
    '46796',
    '46797',
    '46798',
    '46799',
    '46801',
    '46802',
    '46803',
    '46804',
    '46805',
    '46806',
    '46807',
    '46808',
    '46809',
    '46814',
    '46815',
    '46816',
    '46818',
    '46819',
    '46825',
    '46835',
    '46845',
    '46850',
    '46851',
    '46852',
    '46853',
    '46854',
    '46855',
    '46856',
    '46857',
    '46858',
    '46859',
    '46860',
    '46861',
    '46862',
    '46863',
    '46864',
    '46865',
    '46866',
    '46867',
    '46868',
    '46869',
    '46885',
    '46895',
    '46896',
    '46897',
    '46898',
    '46899',
    '46901',
    '46902',
    '46903',
    '46904',
    '46910',
    '46911',
    '46912',
    '46913',
    '46914',
    '46915',
    '46916',
    '46917',
    '46919',
    '46920',
    '46921',
    '46922',
    '46923',
    '46926',
    '46928',
    '46929',
    '46930',
    '46931',
    '46932',
    '46933',
    '46935',
    '46936',
    '46937',
    '46938',
    '46939',
    '46940',
    '46941',
    '46942',
    '46943',
    '46945',
    '46946',
    '46947',
    '46950',
    '46951',
    '46952',
    '46953',
    '46957',
    '46958',
    '46959',
    '46960',
    '46961',
    '46962',
    '46965',
    '46967',
    '46968',
    '46970',
    '46971',
    '46974',
    '46975',
    '46977',
    '46978',
    '46979',
    '46980',
    '46982',
    '46984',
    '46985',
    '46986',
    '46987',
    '46988',
    '46989',
    '46990',
    '46991',
    '46992',
    '46994',
    '46995',
    '46996',
    '46998',
    '47001',
    '47003',
    '47006',
    '47010',
    '47011',
    '47012',
    '47016',
    '47017',
    '47018',
    '47019',
    '47020',
    '47021',
    '47022',
    '47023',
    '47024',
    '47025',
    '47030',
    '47031',
    '47032',
    '47033',
    '47034',
    '47035',
    '47036',
    '47037',
    '47038',
    '47039',
    '47040',
    '47041',
    '47042',
    '47043',
    '47060',
    '47102',
    '47104',
    '47106',
    '47107',
    '47108',
    '47110',
    '47111',
    '47112',
    '47114',
    '47115',
    '47116',
    '47117',
    '47118',
    '47119',
    '47120',
    '47122',
    '47123',
    '47124',
    '47125',
    '47126',
    '47129',
    '47130',
    '47131',
    '47132',
    '47133',
    '47134',
    '47135',
    '47136',
    '47137',
    '47138',
    '47140',
    '47141',
    '47142',
    '47143',
    '47144',
    '47145',
    '47146',
    '47147',
    '47150',
    '47151',
    '47160',
    '47161',
    '47162',
    '47163',
    '47164',
    '47165',
    '47166',
    '47167',
    '47170',
    '47172',
    '47174',
    '47175',
    '47177',
    '47190',
    '47199',
    '47201',
    '47202',
    '47203',
    '47220',
    '47223',
    '47224',
    '47225',
    '47226',
    '47227',
    '47228',
    '47229',
    '47230',
    '47231',
    '47232',
    '47234',
    '47235',
    '47236',
    '47240',
    '47243',
    '47244',
    '47245',
    '47246',
    '47247',
    '47249',
    '47250',
    '47260',
    '47263',
    '47264',
    '47265',
    '47270',
    '47272',
    '47273',
    '47274',
    '47280',
    '47281',
    '47282',
    '47283',
    '47302',
    '47303',
    '47304',
    '47305',
    '47306',
    '47307',
    '47308',
    '47320',
    '47322',
    '47324',
    '47325',
    '47326',
    '47327',
    '47330',
    '47331',
    '47334',
    '47335',
    '47336',
    '47337',
    '47338',
    '47339',
    '47340',
    '47341',
    '47342',
    '47344',
    '47345',
    '47346',
    '47348',
    '47351',
    '47352',
    '47353',
    '47354',
    '47355',
    '47356',
    '47357',
    '47358',
    '47359',
    '47360',
    '47361',
    '47362',
    '47366',
    '47367',
    '47368',
    '47369',
    '47370',
    '47371',
    '47373',
    '47374',
    '47375',
    '47380',
    '47381',
    '47382',
    '47383',
    '47384',
    '47385',
    '47386',
    '47387',
    '47388',
    '47390',
    '47392',
    '47393',
    '47394',
    '47396',
    '47401',
    '47402',
    '47403',
    '47404',
    '47405',
    '47406',
    '47407',
    '47408',
    '47420',
    '47421',
    '47424',
    '47426',
    '47427',
    '47429',
    '47431',
    '47432',
    '47433',
    '47434',
    '47435',
    '47436',
    '47437',
    '47438',
    '47441',
    '47443',
    '47445',
    '47446',
    '47448',
    '47449',
    '47451',
    '47452',
    '47453',
    '47454',
    '47455',
    '47456',
    '47457',
    '47458',
    '47459',
    '47460',
    '47462',
    '47463',
    '47464',
    '47465',
    '47467',
    '47468',
    '47469',
    '47470',
    '47471',
    '47501',
    '47512',
    '47513',
    '47514',
    '47515',
    '47516',
    '47519',
    '47520',
    '47521',
    '47522',
    '47523',
    '47524',
    '47525',
    '47527',
    '47528',
    '47529',
    '47531',
    '47532',
    '47535',
    '47536',
    '47537',
    '47541',
    '47542',
    '47545',
    '47546',
    '47547',
    '47549',
    '47550',
    '47551',
    '47552',
    '47553',
    '47556',
    '47557',
    '47558',
    '47561',
    '47562',
    '47564',
    '47567',
    '47568',
    '47573',
    '47574',
    '47575',
    '47576',
    '47577',
    '47578',
    '47579',
    '47580',
    '47581',
    '47584',
    '47585',
    '47586',
    '47588',
    '47590',
    '47591',
    '47596',
    '47597',
    '47598',
    '47601',
    '47610',
    '47611',
    '47612',
    '47613',
    '47615',
    '47616',
    '47617',
    '47618',
    '47619',
    '47620',
    '47629',
    '47630',
    '47631',
    '47633',
    '47634',
    '47635',
    '47637',
    '47638',
    '47639',
    '47640',
    '47647',
    '47648',
    '47649',
    '47654',
    '47660',
    '47665',
    '47666',
    '47670',
    '47683',
    '47701',
    '47702',
    '47703',
    '47704',
    '47705',
    '47706',
    '47708',
    '47710',
    '47711',
    '47712',
    '47713',
    '47714',
    '47715',
    '47716',
    '47719',
    '47720',
    '47721',
    '47722',
    '47724',
    '47725',
    '47728',
    '47730',
    '47731',
    '47732',
    '47733',
    '47734',
    '47735',
    '47736',
    '47737',
    '47740',
    '47747',
    '47750',
    '47801',
    '47802',
    '47803',
    '47804',
    '47805',
    '47807',
    '47808',
    '47809',
    '47831',
    '47832',
    '47833',
    '47834',
    '47836',
    '47837',
    '47838',
    '47840',
    '47841',
    '47842',
    '47845',
    '47846',
    '47847',
    '47848',
    '47849',
    '47850',
    '47851',
    '47852',
    '47853',
    '47854',
    '47855',
    '47857',
    '47858',
    '47859',
    '47860',
    '47861',
    '47862',
    '47863',
    '47865',
    '47866',
    '47868',
    '47869',
    '47870',
    '47871',
    '47872',
    '47874',
    '47875',
    '47876',
    '47878',
    '47879',
    '47880',
    '47881',
    '47882',
    '47884',
    '47885',
    '47901',
    '47902',
    '47903',
    '47904',
    '47905',
    '47906',
    '47907',
    '47909',
    '47916',
    '47917',
    '47918',
    '47920',
    '47921',
    '47922',
    '47923',
    '47924',
    '47925',
    '47926',
    '47928',
    '47929',
    '47930',
    '47932',
    '47933',
    '47940',
    '47941',
    '47942',
    '47943',
    '47944',
    '47946',
    '47948',
    '47949',
    '47950',
    '47951',
    '47952',
    '47954',
    '47955',
    '47957',
    '47958',
    '47959',
    '47960',
    '47962',
    '47963',
    '47964',
    '47965',
    '47966',
    '47967',
    '47968',
    '47969',
    '47970',
    '47971',
    '47974',
    '47975',
    '47977',
    '47978',
    '47980',
    '47981',
    '47982',
    '47983',
    '47986',
    '47987',
    '47988',
    '47989',
    '47990',
    '47991',
    '47992',
    '47993',
    '47994',
    '47995',
    '47996',
    '47997',
  ],
  MI: [
    '48001',
    '48002',
    '48003',
    '48004',
    '48005',
    '48006',
    '48007',
    '48009',
    '48012',
    '48014',
    '48015',
    '48017',
    '48021',
    '48022',
    '48023',
    '48025',
    '48026',
    '48027',
    '48028',
    '48030',
    '48032',
    '48033',
    '48034',
    '48035',
    '48036',
    '48037',
    '48038',
    '48039',
    '48040',
    '48041',
    '48042',
    '48043',
    '48044',
    '48045',
    '48046',
    '48047',
    '48048',
    '48049',
    '48050',
    '48051',
    '48054',
    '48059',
    '48060',
    '48061',
    '48062',
    '48063',
    '48064',
    '48065',
    '48066',
    '48067',
    '48068',
    '48069',
    '48070',
    '48071',
    '48072',
    '48073',
    '48074',
    '48075',
    '48076',
    '48079',
    '48080',
    '48081',
    '48082',
    '48083',
    '48084',
    '48085',
    '48086',
    '48088',
    '48089',
    '48090',
    '48091',
    '48092',
    '48093',
    '48094',
    '48095',
    '48096',
    '48097',
    '48098',
    '48099',
    '48101',
    '48103',
    '48104',
    '48105',
    '48106',
    '48107',
    '48108',
    '48109',
    '48110',
    '48111',
    '48112',
    '48113',
    '48114',
    '48115',
    '48116',
    '48117',
    '48118',
    '48120',
    '48121',
    '48122',
    '48123',
    '48124',
    '48125',
    '48126',
    '48127',
    '48128',
    '48130',
    '48131',
    '48133',
    '48134',
    '48135',
    '48136',
    '48137',
    '48138',
    '48139',
    '48140',
    '48141',
    '48143',
    '48144',
    '48145',
    '48146',
    '48150',
    '48151',
    '48152',
    '48153',
    '48154',
    '48157',
    '48158',
    '48159',
    '48160',
    '48161',
    '48162',
    '48164',
    '48165',
    '48166',
    '48167',
    '48168',
    '48169',
    '48170',
    '48173',
    '48174',
    '48175',
    '48176',
    '48177',
    '48178',
    '48179',
    '48180',
    '48182',
    '48183',
    '48184',
    '48185',
    '48186',
    '48187',
    '48188',
    '48189',
    '48190',
    '48191',
    '48192',
    '48193',
    '48195',
    '48197',
    '48198',
    '48201',
    '48202',
    '48203',
    '48204',
    '48205',
    '48206',
    '48207',
    '48208',
    '48209',
    '48210',
    '48211',
    '48212',
    '48213',
    '48214',
    '48215',
    '48216',
    '48217',
    '48218',
    '48219',
    '48220',
    '48221',
    '48222',
    '48223',
    '48224',
    '48225',
    '48226',
    '48227',
    '48228',
    '48229',
    '48230',
    '48231',
    '48232',
    '48233',
    '48234',
    '48235',
    '48236',
    '48237',
    '48238',
    '48239',
    '48240',
    '48242',
    '48243',
    '48244',
    '48255',
    '48260',
    '48264',
    '48265',
    '48266',
    '48267',
    '48268',
    '48269',
    '48272',
    '48275',
    '48277',
    '48278',
    '48279',
    '48288',
    '48301',
    '48302',
    '48303',
    '48304',
    '48306',
    '48307',
    '48308',
    '48309',
    '48310',
    '48311',
    '48312',
    '48313',
    '48314',
    '48315',
    '48316',
    '48317',
    '48318',
    '48320',
    '48321',
    '48322',
    '48323',
    '48324',
    '48325',
    '48326',
    '48327',
    '48328',
    '48329',
    '48330',
    '48331',
    '48332',
    '48333',
    '48334',
    '48335',
    '48336',
    '48340',
    '48341',
    '48342',
    '48343',
    '48346',
    '48347',
    '48348',
    '48350',
    '48353',
    '48356',
    '48357',
    '48359',
    '48360',
    '48361',
    '48362',
    '48363',
    '48366',
    '48367',
    '48370',
    '48371',
    '48374',
    '48375',
    '48376',
    '48377',
    '48380',
    '48381',
    '48382',
    '48383',
    '48386',
    '48387',
    '48390',
    '48393',
    '48397',
    '48401',
    '48410',
    '48411',
    '48412',
    '48413',
    '48414',
    '48415',
    '48416',
    '48417',
    '48418',
    '48419',
    '48420',
    '48421',
    '48422',
    '48423',
    '48426',
    '48427',
    '48428',
    '48429',
    '48430',
    '48432',
    '48433',
    '48434',
    '48435',
    '48436',
    '48437',
    '48438',
    '48439',
    '48440',
    '48441',
    '48442',
    '48444',
    '48445',
    '48446',
    '48449',
    '48450',
    '48451',
    '48453',
    '48454',
    '48455',
    '48456',
    '48457',
    '48458',
    '48460',
    '48461',
    '48462',
    '48463',
    '48464',
    '48465',
    '48466',
    '48467',
    '48468',
    '48469',
    '48470',
    '48471',
    '48472',
    '48473',
    '48475',
    '48476',
    '48480',
    '48501',
    '48502',
    '48503',
    '48504',
    '48505',
    '48506',
    '48507',
    '48509',
    '48519',
    '48529',
    '48531',
    '48532',
    '48550',
    '48551',
    '48552',
    '48553',
    '48554',
    '48555',
    '48556',
    '48557',
    '48601',
    '48602',
    '48603',
    '48604',
    '48605',
    '48606',
    '48607',
    '48608',
    '48609',
    '48610',
    '48611',
    '48612',
    '48613',
    '48614',
    '48615',
    '48616',
    '48617',
    '48618',
    '48619',
    '48620',
    '48621',
    '48622',
    '48623',
    '48624',
    '48625',
    '48626',
    '48627',
    '48628',
    '48629',
    '48630',
    '48631',
    '48632',
    '48633',
    '48634',
    '48635',
    '48636',
    '48637',
    '48638',
    '48640',
    '48641',
    '48642',
    '48647',
    '48649',
    '48650',
    '48651',
    '48652',
    '48653',
    '48654',
    '48655',
    '48656',
    '48657',
    '48658',
    '48659',
    '48661',
    '48662',
    '48663',
    '48667',
    '48670',
    '48674',
    '48686',
    '48701',
    '48703',
    '48705',
    '48706',
    '48707',
    '48708',
    '48710',
    '48720',
    '48721',
    '48722',
    '48723',
    '48724',
    '48725',
    '48726',
    '48727',
    '48728',
    '48729',
    '48730',
    '48731',
    '48732',
    '48733',
    '48734',
    '48735',
    '48737',
    '48738',
    '48739',
    '48740',
    '48741',
    '48742',
    '48743',
    '48744',
    '48745',
    '48746',
    '48747',
    '48748',
    '48749',
    '48750',
    '48754',
    '48755',
    '48756',
    '48757',
    '48758',
    '48759',
    '48760',
    '48761',
    '48762',
    '48763',
    '48764',
    '48765',
    '48766',
    '48767',
    '48768',
    '48770',
    '48787',
    '48801',
    '48804',
    '48805',
    '48806',
    '48807',
    '48808',
    '48809',
    '48811',
    '48812',
    '48813',
    '48815',
    '48816',
    '48817',
    '48818',
    '48819',
    '48820',
    '48821',
    '48822',
    '48823',
    '48824',
    '48825',
    '48826',
    '48827',
    '48829',
    '48830',
    '48831',
    '48832',
    '48833',
    '48834',
    '48835',
    '48836',
    '48837',
    '48838',
    '48840',
    '48841',
    '48842',
    '48843',
    '48844',
    '48845',
    '48846',
    '48847',
    '48848',
    '48849',
    '48850',
    '48851',
    '48852',
    '48853',
    '48854',
    '48855',
    '48856',
    '48857',
    '48858',
    '48859',
    '48860',
    '48861',
    '48862',
    '48864',
    '48865',
    '48866',
    '48867',
    '48870',
    '48871',
    '48872',
    '48873',
    '48874',
    '48875',
    '48876',
    '48877',
    '48878',
    '48879',
    '48880',
    '48881',
    '48882',
    '48883',
    '48884',
    '48885',
    '48886',
    '48887',
    '48888',
    '48889',
    '48890',
    '48891',
    '48892',
    '48893',
    '48894',
    '48895',
    '48896',
    '48897',
    '48901',
    '48906',
    '48908',
    '48909',
    '48910',
    '48911',
    '48912',
    '48913',
    '48915',
    '48916',
    '48917',
    '48918',
    '48919',
    '48922',
    '48924',
    '48929',
    '48930',
    '48933',
    '48937',
    '48951',
    '48956',
    '48980',
    '49001',
    '49002',
    '49003',
    '49004',
    '49005',
    '49006',
    '49007',
    '49008',
    '49009',
    '49010',
    '49011',
    '49012',
    '49013',
    '49014',
    '49015',
    '49016',
    '49017',
    '49018',
    '49019',
    '49020',
    '49021',
    '49022',
    '49023',
    '49024',
    '49026',
    '49027',
    '49028',
    '49029',
    '49030',
    '49031',
    '49032',
    '49033',
    '49034',
    '49035',
    '49036',
    '49037',
    '49038',
    '49039',
    '49040',
    '49041',
    '49042',
    '49043',
    '49045',
    '49046',
    '49047',
    '49048',
    '49050',
    '49051',
    '49052',
    '49053',
    '49055',
    '49056',
    '49057',
    '49058',
    '49060',
    '49061',
    '49062',
    '49063',
    '49064',
    '49065',
    '49066',
    '49067',
    '49068',
    '49070',
    '49071',
    '49072',
    '49073',
    '49074',
    '49075',
    '49076',
    '49077',
    '49078',
    '49079',
    '49080',
    '49081',
    '49082',
    '49083',
    '49084',
    '49085',
    '49087',
    '49088',
    '49089',
    '49090',
    '49091',
    '49092',
    '49093',
    '49094',
    '49095',
    '49096',
    '49097',
    '49098',
    '49099',
    '49101',
    '49102',
    '49103',
    '49104',
    '49106',
    '49107',
    '49111',
    '49112',
    '49113',
    '49115',
    '49116',
    '49117',
    '49119',
    '49120',
    '49125',
    '49126',
    '49127',
    '49128',
    '49129',
    '49130',
    '49201',
    '49202',
    '49203',
    '49204',
    '49220',
    '49221',
    '49224',
    '49227',
    '49228',
    '49229',
    '49230',
    '49232',
    '49233',
    '49234',
    '49235',
    '49236',
    '49237',
    '49238',
    '49239',
    '49240',
    '49241',
    '49242',
    '49245',
    '49246',
    '49247',
    '49248',
    '49249',
    '49250',
    '49251',
    '49252',
    '49253',
    '49254',
    '49255',
    '49256',
    '49257',
    '49258',
    '49259',
    '49261',
    '49262',
    '49263',
    '49264',
    '49265',
    '49266',
    '49267',
    '49268',
    '49269',
    '49270',
    '49271',
    '49272',
    '49274',
    '49276',
    '49277',
    '49279',
    '49281',
    '49282',
    '49283',
    '49284',
    '49285',
    '49286',
    '49287',
    '49288',
    '49289',
    '49301',
    '49302',
    '49303',
    '49304',
    '49305',
    '49306',
    '49307',
    '49309',
    '49310',
    '49311',
    '49312',
    '49314',
    '49315',
    '49316',
    '49317',
    '49318',
    '49319',
    '49320',
    '49321',
    '49322',
    '49323',
    '49325',
    '49326',
    '49327',
    '49328',
    '49329',
    '49330',
    '49331',
    '49332',
    '49333',
    '49335',
    '49336',
    '49337',
    '49338',
    '49339',
    '49340',
    '49341',
    '49342',
    '49343',
    '49344',
    '49345',
    '49346',
    '49347',
    '49348',
    '49349',
    '49351',
    '49355',
    '49356',
    '49357',
    '49401',
    '49402',
    '49403',
    '49404',
    '49405',
    '49406',
    '49408',
    '49409',
    '49410',
    '49411',
    '49412',
    '49413',
    '49415',
    '49416',
    '49417',
    '49418',
    '49419',
    '49420',
    '49421',
    '49422',
    '49423',
    '49424',
    '49425',
    '49426',
    '49427',
    '49428',
    '49429',
    '49430',
    '49431',
    '49434',
    '49435',
    '49436',
    '49437',
    '49440',
    '49441',
    '49442',
    '49443',
    '49444',
    '49445',
    '49446',
    '49448',
    '49449',
    '49450',
    '49451',
    '49452',
    '49453',
    '49454',
    '49455',
    '49456',
    '49457',
    '49458',
    '49459',
    '49460',
    '49461',
    '49463',
    '49464',
    '49468',
    '49501',
    '49502',
    '49503',
    '49504',
    '49505',
    '49506',
    '49507',
    '49508',
    '49509',
    '49510',
    '49512',
    '49514',
    '49515',
    '49516',
    '49518',
    '49519',
    '49523',
    '49525',
    '49528',
    '49530',
    '49534',
    '49544',
    '49546',
    '49548',
    '49555',
    '49560',
    '49588',
    '49599',
    '49601',
    '49610',
    '49611',
    '49612',
    '49613',
    '49614',
    '49615',
    '49616',
    '49617',
    '49618',
    '49619',
    '49620',
    '49621',
    '49622',
    '49623',
    '49625',
    '49626',
    '49627',
    '49628',
    '49629',
    '49630',
    '49631',
    '49632',
    '49633',
    '49634',
    '49635',
    '49636',
    '49637',
    '49638',
    '49639',
    '49640',
    '49642',
    '49643',
    '49644',
    '49645',
    '49646',
    '49648',
    '49649',
    '49650',
    '49651',
    '49653',
    '49654',
    '49655',
    '49656',
    '49657',
    '49659',
    '49660',
    '49663',
    '49664',
    '49665',
    '49666',
    '49667',
    '49668',
    '49670',
    '49673',
    '49674',
    '49675',
    '49676',
    '49677',
    '49679',
    '49680',
    '49682',
    '49683',
    '49684',
    '49685',
    '49686',
    '49688',
    '49689',
    '49690',
    '49696',
    '49701',
    '49705',
    '49706',
    '49707',
    '49709',
    '49710',
    '49711',
    '49712',
    '49713',
    '49715',
    '49716',
    '49717',
    '49718',
    '49719',
    '49720',
    '49721',
    '49722',
    '49723',
    '49724',
    '49725',
    '49726',
    '49727',
    '49728',
    '49729',
    '49730',
    '49733',
    '49734',
    '49735',
    '49736',
    '49737',
    '49738',
    '49739',
    '49740',
    '49743',
    '49744',
    '49745',
    '49746',
    '49747',
    '49748',
    '49749',
    '49751',
    '49752',
    '49753',
    '49755',
    '49756',
    '49757',
    '49759',
    '49760',
    '49761',
    '49762',
    '49764',
    '49765',
    '49766',
    '49768',
    '49769',
    '49770',
    '49774',
    '49775',
    '49776',
    '49777',
    '49779',
    '49780',
    '49781',
    '49782',
    '49783',
    '49784',
    '49785',
    '49786',
    '49788',
    '49791',
    '49792',
    '49793',
    '49795',
    '49796',
    '49797',
    '49799',
    '49801',
    '49802',
    '49805',
    '49806',
    '49807',
    '49808',
    '49812',
    '49814',
    '49815',
    '49816',
    '49817',
    '49818',
    '49819',
    '49820',
    '49821',
    '49822',
    '49825',
    '49826',
    '49827',
    '49829',
    '49831',
    '49833',
    '49834',
    '49835',
    '49836',
    '49837',
    '49838',
    '49839',
    '49840',
    '49841',
    '49845',
    '49847',
    '49848',
    '49849',
    '49852',
    '49853',
    '49854',
    '49855',
    '49858',
    '49861',
    '49862',
    '49863',
    '49864',
    '49865',
    '49866',
    '49868',
    '49870',
    '49871',
    '49872',
    '49873',
    '49874',
    '49876',
    '49877',
    '49878',
    '49879',
    '49880',
    '49881',
    '49883',
    '49884',
    '49885',
    '49886',
    '49887',
    '49891',
    '49892',
    '49893',
    '49894',
    '49895',
    '49896',
    '49901',
    '49902',
    '49903',
    '49905',
    '49908',
    '49910',
    '49911',
    '49912',
    '49913',
    '49915',
    '49916',
    '49917',
    '49918',
    '49919',
    '49920',
    '49921',
    '49922',
    '49925',
    '49927',
    '49929',
    '49930',
    '49931',
    '49934',
    '49935',
    '49938',
    '49942',
    '49945',
    '49946',
    '49947',
    '49948',
    '49950',
    '49952',
    '49953',
    '49955',
    '49958',
    '49959',
    '49960',
    '49961',
    '49962',
    '49963',
    '49964',
    '49965',
    '49967',
    '49968',
    '49969',
    '49970',
    '49971',
  ],
  IA: [
    '50001',
    '50002',
    '50003',
    '50005',
    '50006',
    '50007',
    '50008',
    '50009',
    '50010',
    '50011',
    '50012',
    '50013',
    '50014',
    '50020',
    '50021',
    '50022',
    '50023',
    '50025',
    '50026',
    '50027',
    '50028',
    '50029',
    '50031',
    '50032',
    '50033',
    '50034',
    '50035',
    '50036',
    '50037',
    '50038',
    '50039',
    '50040',
    '50041',
    '50042',
    '50043',
    '50044',
    '50046',
    '50047',
    '50048',
    '50049',
    '50050',
    '50051',
    '50052',
    '50054',
    '50055',
    '50056',
    '50057',
    '50058',
    '50059',
    '50060',
    '50061',
    '50062',
    '50063',
    '50064',
    '50065',
    '50066',
    '50067',
    '50068',
    '50069',
    '50070',
    '50071',
    '50072',
    '50073',
    '50074',
    '50075',
    '50076',
    '50078',
    '50099',
    '50101',
    '50102',
    '50103',
    '50104',
    '50105',
    '50106',
    '50107',
    '50108',
    '50109',
    '50110',
    '50111',
    '50112',
    '50115',
    '50116',
    '50117',
    '50118',
    '50119',
    '50120',
    '50122',
    '50123',
    '50124',
    '50125',
    '50126',
    '50127',
    '50128',
    '50129',
    '50130',
    '50131',
    '50132',
    '50133',
    '50134',
    '50135',
    '50136',
    '50137',
    '50138',
    '50139',
    '50140',
    '50141',
    '50142',
    '50143',
    '50144',
    '50145',
    '50146',
    '50147',
    '50148',
    '50149',
    '50150',
    '50151',
    '50152',
    '50153',
    '50154',
    '50155',
    '50156',
    '50157',
    '50158',
    '50160',
    '50161',
    '50162',
    '50163',
    '50164',
    '50165',
    '50166',
    '50167',
    '50168',
    '50169',
    '50170',
    '50171',
    '50173',
    '50174',
    '50201',
    '50206',
    '50207',
    '50208',
    '50210',
    '50211',
    '50212',
    '50213',
    '50214',
    '50216',
    '50217',
    '50218',
    '50219',
    '50220',
    '50222',
    '50223',
    '50225',
    '50226',
    '50227',
    '50228',
    '50229',
    '50230',
    '50231',
    '50232',
    '50233',
    '50234',
    '50235',
    '50236',
    '50237',
    '50238',
    '50239',
    '50240',
    '50241',
    '50242',
    '50243',
    '50244',
    '50246',
    '50247',
    '50248',
    '50249',
    '50250',
    '50251',
    '50252',
    '50254',
    '50255',
    '50256',
    '50257',
    '50258',
    '50259',
    '50261',
    '50262',
    '50263',
    '50264',
    '50265',
    '50266',
    '50268',
    '50269',
    '50271',
    '50272',
    '50273',
    '50274',
    '50275',
    '50276',
    '50277',
    '50278',
    '50301',
    '50302',
    '50303',
    '50304',
    '50305',
    '50306',
    '50307',
    '50308',
    '50309',
    '50310',
    '50311',
    '50312',
    '50313',
    '50314',
    '50315',
    '50316',
    '50317',
    '50318',
    '50319',
    '50320',
    '50321',
    '50322',
    '50323',
    '50324',
    '50325',
    '50327',
    '50328',
    '50329',
    '50330',
    '50331',
    '50332',
    '50333',
    '50334',
    '50335',
    '50336',
    '50339',
    '50340',
    '50359',
    '50360',
    '50361',
    '50362',
    '50363',
    '50364',
    '50367',
    '50368',
    '50369',
    '50380',
    '50381',
    '50391',
    '50392',
    '50393',
    '50394',
    '50395',
    '50396',
    '50398',
    '50401',
    '50402',
    '50420',
    '50421',
    '50423',
    '50424',
    '50426',
    '50427',
    '50428',
    '50430',
    '50431',
    '50432',
    '50433',
    '50434',
    '50435',
    '50436',
    '50438',
    '50439',
    '50440',
    '50441',
    '50444',
    '50446',
    '50447',
    '50448',
    '50449',
    '50450',
    '50451',
    '50452',
    '50453',
    '50454',
    '50455',
    '50456',
    '50457',
    '50458',
    '50459',
    '50460',
    '50461',
    '50464',
    '50465',
    '50466',
    '50467',
    '50468',
    '50469',
    '50470',
    '50471',
    '50472',
    '50473',
    '50475',
    '50476',
    '50477',
    '50478',
    '50479',
    '50480',
    '50481',
    '50482',
    '50483',
    '50484',
    '50501',
    '50510',
    '50511',
    '50514',
    '50515',
    '50516',
    '50517',
    '50518',
    '50519',
    '50520',
    '50521',
    '50522',
    '50523',
    '50524',
    '50525',
    '50526',
    '50527',
    '50528',
    '50529',
    '50530',
    '50531',
    '50532',
    '50533',
    '50535',
    '50536',
    '50538',
    '50539',
    '50540',
    '50541',
    '50542',
    '50543',
    '50544',
    '50545',
    '50546',
    '50548',
    '50551',
    '50552',
    '50554',
    '50556',
    '50557',
    '50558',
    '50559',
    '50560',
    '50561',
    '50562',
    '50563',
    '50565',
    '50566',
    '50567',
    '50568',
    '50569',
    '50570',
    '50571',
    '50573',
    '50574',
    '50575',
    '50576',
    '50577',
    '50578',
    '50579',
    '50581',
    '50582',
    '50583',
    '50585',
    '50586',
    '50588',
    '50590',
    '50591',
    '50592',
    '50593',
    '50594',
    '50595',
    '50597',
    '50598',
    '50599',
    '50601',
    '50602',
    '50603',
    '50604',
    '50605',
    '50606',
    '50607',
    '50608',
    '50609',
    '50611',
    '50612',
    '50613',
    '50614',
    '50616',
    '50619',
    '50620',
    '50621',
    '50622',
    '50623',
    '50624',
    '50625',
    '50626',
    '50627',
    '50628',
    '50629',
    '50630',
    '50631',
    '50632',
    '50633',
    '50634',
    '50635',
    '50636',
    '50638',
    '50641',
    '50642',
    '50643',
    '50644',
    '50645',
    '50647',
    '50648',
    '50649',
    '50650',
    '50651',
    '50652',
    '50653',
    '50654',
    '50655',
    '50657',
    '50658',
    '50659',
    '50660',
    '50661',
    '50662',
    '50664',
    '50665',
    '50666',
    '50667',
    '50668',
    '50669',
    '50670',
    '50671',
    '50672',
    '50673',
    '50674',
    '50675',
    '50676',
    '50677',
    '50680',
    '50681',
    '50682',
    '50701',
    '50702',
    '50703',
    '50704',
    '50707',
    '50801',
    '50830',
    '50831',
    '50833',
    '50835',
    '50836',
    '50837',
    '50839',
    '50840',
    '50841',
    '50842',
    '50843',
    '50845',
    '50846',
    '50847',
    '50848',
    '50849',
    '50851',
    '50853',
    '50854',
    '50857',
    '50858',
    '50859',
    '50860',
    '50861',
    '50862',
    '50863',
    '50864',
    '50936',
    '50940',
    '50947',
    '50950',
    '50980',
    '50981',
    '50982',
    '50983',
    '51001',
    '51002',
    '51003',
    '51004',
    '51005',
    '51006',
    '51007',
    '51008',
    '51009',
    '51010',
    '51011',
    '51012',
    '51014',
    '51015',
    '51016',
    '51018',
    '51019',
    '51020',
    '51022',
    '51023',
    '51024',
    '51025',
    '51026',
    '51027',
    '51028',
    '51029',
    '51030',
    '51031',
    '51033',
    '51034',
    '51035',
    '51036',
    '51037',
    '51038',
    '51039',
    '51040',
    '51041',
    '51044',
    '51045',
    '51046',
    '51047',
    '51048',
    '51049',
    '51050',
    '51051',
    '51052',
    '51053',
    '51054',
    '51055',
    '51056',
    '51058',
    '51060',
    '51061',
    '51062',
    '51063',
    '51101',
    '51102',
    '51103',
    '51104',
    '51105',
    '51106',
    '51108',
    '51109',
    '51111',
    '51201',
    '51230',
    '51231',
    '51232',
    '51234',
    '51235',
    '51237',
    '51238',
    '51239',
    '51240',
    '51241',
    '51242',
    '51243',
    '51244',
    '51245',
    '51246',
    '51247',
    '51248',
    '51249',
    '51250',
    '51301',
    '51331',
    '51333',
    '51334',
    '51338',
    '51340',
    '51341',
    '51342',
    '51343',
    '51345',
    '51346',
    '51347',
    '51350',
    '51351',
    '51354',
    '51355',
    '51357',
    '51358',
    '51360',
    '51363',
    '51364',
    '51365',
    '51366',
    '51401',
    '51430',
    '51431',
    '51432',
    '51433',
    '51436',
    '51439',
    '51440',
    '51441',
    '51442',
    '51443',
    '51444',
    '51445',
    '51446',
    '51447',
    '51448',
    '51449',
    '51450',
    '51451',
    '51452',
    '51453',
    '51454',
    '51455',
    '51458',
    '51459',
    '51460',
    '51461',
    '51462',
    '51463',
    '51465',
    '51466',
    '51467',
    '51501',
    '51502',
    '51503',
    '51510',
    '51520',
    '51521',
    '51523',
    '51525',
    '51526',
    '51527',
    '51528',
    '51529',
    '51530',
    '51531',
    '51532',
    '51533',
    '51534',
    '51535',
    '51536',
    '51537',
    '51540',
    '51541',
    '51542',
    '51543',
    '51544',
    '51545',
    '51546',
    '51548',
    '51549',
    '51550',
    '51551',
    '51552',
    '51553',
    '51554',
    '51555',
    '51556',
    '51557',
    '51558',
    '51559',
    '51560',
    '51561',
    '51562',
    '51563',
    '51564',
    '51565',
    '51566',
    '51570',
    '51571',
    '51572',
    '51573',
    '51575',
    '51576',
    '51577',
    '51578',
    '51579',
    '51591',
    '51593',
    '51601',
    '51603',
    '51630',
    '51631',
    '51632',
    '51636',
    '51637',
    '51638',
    '51639',
    '51640',
    '51645',
    '51646',
    '51647',
    '51648',
    '51649',
    '51650',
    '51651',
    '51652',
    '51653',
    '51654',
    '51656',
    '52001',
    '52002',
    '52003',
    '52004',
    '52030',
    '52031',
    '52032',
    '52033',
    '52035',
    '52036',
    '52037',
    '52038',
    '52039',
    '52040',
    '52041',
    '52042',
    '52043',
    '52044',
    '52045',
    '52046',
    '52047',
    '52048',
    '52049',
    '52050',
    '52052',
    '52053',
    '52054',
    '52056',
    '52057',
    '52060',
    '52064',
    '52065',
    '52066',
    '52068',
    '52069',
    '52070',
    '52071',
    '52072',
    '52073',
    '52074',
    '52075',
    '52076',
    '52077',
    '52078',
    '52079',
    '52099',
    '52101',
    '52132',
    '52133',
    '52134',
    '52135',
    '52136',
    '52140',
    '52141',
    '52142',
    '52144',
    '52146',
    '52147',
    '52149',
    '52151',
    '52154',
    '52155',
    '52156',
    '52157',
    '52158',
    '52159',
    '52160',
    '52161',
    '52162',
    '52163',
    '52164',
    '52165',
    '52166',
    '52168',
    '52169',
    '52170',
    '52171',
    '52172',
    '52175',
    '52201',
    '52202',
    '52203',
    '52204',
    '52205',
    '52206',
    '52207',
    '52208',
    '52209',
    '52210',
    '52211',
    '52212',
    '52213',
    '52214',
    '52215',
    '52216',
    '52217',
    '52218',
    '52219',
    '52220',
    '52221',
    '52222',
    '52223',
    '52224',
    '52225',
    '52227',
    '52228',
    '52229',
    '52231',
    '52232',
    '52233',
    '52235',
    '52236',
    '52237',
    '52240',
    '52241',
    '52242',
    '52243',
    '52244',
    '52245',
    '52246',
    '52247',
    '52248',
    '52249',
    '52251',
    '52252',
    '52253',
    '52254',
    '52255',
    '52257',
    '52301',
    '52302',
    '52305',
    '52306',
    '52307',
    '52308',
    '52309',
    '52310',
    '52312',
    '52313',
    '52314',
    '52315',
    '52316',
    '52317',
    '52318',
    '52320',
    '52321',
    '52322',
    '52323',
    '52324',
    '52325',
    '52326',
    '52327',
    '52328',
    '52329',
    '52330',
    '52332',
    '52333',
    '52334',
    '52335',
    '52336',
    '52337',
    '52338',
    '52339',
    '52340',
    '52341',
    '52342',
    '52344',
    '52345',
    '52346',
    '52347',
    '52348',
    '52349',
    '52351',
    '52352',
    '52353',
    '52354',
    '52355',
    '52356',
    '52358',
    '52359',
    '52361',
    '52362',
    '52401',
    '52402',
    '52403',
    '52404',
    '52405',
    '52406',
    '52407',
    '52408',
    '52409',
    '52410',
    '52411',
    '52497',
    '52498',
    '52499',
    '52501',
    '52530',
    '52531',
    '52533',
    '52534',
    '52535',
    '52536',
    '52537',
    '52540',
    '52542',
    '52543',
    '52544',
    '52548',
    '52549',
    '52550',
    '52551',
    '52552',
    '52553',
    '52554',
    '52555',
    '52556',
    '52557',
    '52560',
    '52561',
    '52562',
    '52563',
    '52565',
    '52566',
    '52567',
    '52568',
    '52569',
    '52570',
    '52571',
    '52572',
    '52573',
    '52574',
    '52576',
    '52577',
    '52580',
    '52581',
    '52583',
    '52584',
    '52585',
    '52586',
    '52588',
    '52590',
    '52591',
    '52593',
    '52594',
    '52595',
    '52601',
    '52619',
    '52620',
    '52621',
    '52623',
    '52624',
    '52625',
    '52626',
    '52627',
    '52630',
    '52631',
    '52632',
    '52635',
    '52637',
    '52638',
    '52639',
    '52640',
    '52641',
    '52642',
    '52644',
    '52645',
    '52646',
    '52647',
    '52648',
    '52649',
    '52650',
    '52651',
    '52652',
    '52653',
    '52654',
    '52655',
    '52656',
    '52657',
    '52658',
    '52659',
    '52660',
    '52701',
    '52720',
    '52721',
    '52722',
    '52726',
    '52727',
    '52728',
    '52729',
    '52730',
    '52731',
    '52732',
    '52733',
    '52734',
    '52736',
    '52737',
    '52738',
    '52739',
    '52742',
    '52745',
    '52746',
    '52747',
    '52748',
    '52749',
    '52750',
    '52751',
    '52752',
    '52753',
    '52754',
    '52755',
    '52756',
    '52757',
    '52758',
    '52759',
    '52760',
    '52761',
    '52765',
    '52766',
    '52767',
    '52768',
    '52769',
    '52771',
    '52772',
    '52773',
    '52774',
    '52776',
    '52777',
    '52778',
    '52801',
    '52802',
    '52803',
    '52804',
    '52805',
    '52806',
    '52807',
    '52808',
    '52809',
  ],
  WI: [
    '53001',
    '53002',
    '53003',
    '53004',
    '53005',
    '53006',
    '53007',
    '53008',
    '53010',
    '53011',
    '53012',
    '53013',
    '53014',
    '53015',
    '53016',
    '53017',
    '53018',
    '53019',
    '53020',
    '53021',
    '53022',
    '53023',
    '53024',
    '53026',
    '53027',
    '53029',
    '53031',
    '53032',
    '53033',
    '53034',
    '53035',
    '53036',
    '53037',
    '53038',
    '53039',
    '53040',
    '53042',
    '53044',
    '53045',
    '53046',
    '53047',
    '53048',
    '53049',
    '53050',
    '53051',
    '53052',
    '53056',
    '53057',
    '53058',
    '53059',
    '53060',
    '53061',
    '53062',
    '53063',
    '53064',
    '53065',
    '53066',
    '53069',
    '53070',
    '53072',
    '53073',
    '53074',
    '53075',
    '53076',
    '53078',
    '53079',
    '53080',
    '53081',
    '53082',
    '53083',
    '53085',
    '53086',
    '53088',
    '53089',
    '53090',
    '53091',
    '53092',
    '53093',
    '53094',
    '53095',
    '53097',
    '53098',
    '53099',
    '53101',
    '53102',
    '53103',
    '53104',
    '53105',
    '53108',
    '53109',
    '53110',
    '53114',
    '53115',
    '53118',
    '53119',
    '53120',
    '53121',
    '53122',
    '53125',
    '53126',
    '53127',
    '53128',
    '53129',
    '53130',
    '53132',
    '53137',
    '53138',
    '53139',
    '53140',
    '53141',
    '53142',
    '53143',
    '53144',
    '53146',
    '53147',
    '53148',
    '53149',
    '53150',
    '53151',
    '53152',
    '53153',
    '53154',
    '53156',
    '53157',
    '53158',
    '53159',
    '53167',
    '53168',
    '53170',
    '53171',
    '53172',
    '53176',
    '53177',
    '53178',
    '53179',
    '53181',
    '53182',
    '53183',
    '53184',
    '53185',
    '53186',
    '53187',
    '53188',
    '53189',
    '53190',
    '53191',
    '53192',
    '53194',
    '53195',
    '53201',
    '53202',
    '53203',
    '53204',
    '53205',
    '53206',
    '53207',
    '53208',
    '53209',
    '53210',
    '53211',
    '53212',
    '53213',
    '53214',
    '53215',
    '53216',
    '53217',
    '53218',
    '53219',
    '53220',
    '53221',
    '53222',
    '53223',
    '53224',
    '53225',
    '53226',
    '53227',
    '53228',
    '53233',
    '53234',
    '53235',
    '53237',
    '53259',
    '53263',
    '53274',
    '53278',
    '53288',
    '53290',
    '53293',
    '53295',
    '53401',
    '53402',
    '53403',
    '53404',
    '53405',
    '53406',
    '53407',
    '53408',
    '53501',
    '53502',
    '53503',
    '53504',
    '53505',
    '53506',
    '53507',
    '53508',
    '53510',
    '53511',
    '53512',
    '53515',
    '53516',
    '53517',
    '53518',
    '53520',
    '53521',
    '53522',
    '53523',
    '53525',
    '53526',
    '53527',
    '53528',
    '53529',
    '53530',
    '53531',
    '53532',
    '53533',
    '53534',
    '53535',
    '53536',
    '53537',
    '53538',
    '53540',
    '53541',
    '53542',
    '53543',
    '53544',
    '53545',
    '53546',
    '53547',
    '53548',
    '53549',
    '53550',
    '53551',
    '53553',
    '53554',
    '53555',
    '53556',
    '53557',
    '53558',
    '53559',
    '53560',
    '53561',
    '53562',
    '53563',
    '53565',
    '53566',
    '53569',
    '53570',
    '53571',
    '53572',
    '53573',
    '53574',
    '53575',
    '53576',
    '53577',
    '53578',
    '53579',
    '53580',
    '53581',
    '53582',
    '53583',
    '53584',
    '53585',
    '53586',
    '53587',
    '53588',
    '53589',
    '53590',
    '53593',
    '53594',
    '53595',
    '53596',
    '53597',
    '53598',
    '53599',
    '53701',
    '53702',
    '53703',
    '53704',
    '53705',
    '53706',
    '53707',
    '53708',
    '53711',
    '53713',
    '53714',
    '53715',
    '53716',
    '53717',
    '53718',
    '53719',
    '53725',
    '53726',
    '53744',
    '53774',
    '53777',
    '53782',
    '53783',
    '53784',
    '53785',
    '53786',
    '53788',
    '53790',
    '53791',
    '53792',
    '53793',
    '53794',
    '53801',
    '53802',
    '53803',
    '53804',
    '53805',
    '53806',
    '53807',
    '53808',
    '53809',
    '53810',
    '53811',
    '53812',
    '53813',
    '53816',
    '53817',
    '53818',
    '53820',
    '53821',
    '53824',
    '53825',
    '53826',
    '53827',
    '53901',
    '53910',
    '53911',
    '53913',
    '53916',
    '53919',
    '53920',
    '53922',
    '53923',
    '53924',
    '53925',
    '53926',
    '53928',
    '53929',
    '53930',
    '53931',
    '53932',
    '53933',
    '53934',
    '53935',
    '53936',
    '53937',
    '53939',
    '53940',
    '53941',
    '53942',
    '53943',
    '53944',
    '53946',
    '53947',
    '53948',
    '53949',
    '53950',
    '53951',
    '53952',
    '53953',
    '53954',
    '53955',
    '53956',
    '53957',
    '53958',
    '53959',
    '53960',
    '53961',
    '53962',
    '53963',
    '53964',
    '53965',
    '53968',
    '53969',
    '54001',
    '54002',
    '54003',
    '54004',
    '54005',
    '54006',
    '54007',
    '54009',
    '54011',
    '54013',
    '54014',
    '54015',
    '54016',
    '54017',
    '54020',
    '54021',
    '54022',
    '54023',
    '54024',
    '54025',
    '54026',
    '54027',
    '54028',
    '54082',
    '54101',
    '54102',
    '54103',
    '54104',
    '54106',
    '54107',
    '54110',
    '54111',
    '54112',
    '54113',
    '54114',
    '54115',
    '54119',
    '54120',
    '54121',
    '54123',
    '54124',
    '54125',
    '54126',
    '54127',
    '54128',
    '54129',
    '54130',
    '54131',
    '54135',
    '54136',
    '54137',
    '54138',
    '54139',
    '54140',
    '54141',
    '54143',
    '54149',
    '54150',
    '54151',
    '54152',
    '54153',
    '54154',
    '54155',
    '54156',
    '54157',
    '54159',
    '54160',
    '54161',
    '54162',
    '54165',
    '54166',
    '54169',
    '54170',
    '54171',
    '54173',
    '54174',
    '54175',
    '54177',
    '54180',
    '54201',
    '54202',
    '54204',
    '54205',
    '54207',
    '54208',
    '54209',
    '54210',
    '54211',
    '54212',
    '54213',
    '54214',
    '54215',
    '54216',
    '54217',
    '54220',
    '54221',
    '54226',
    '54227',
    '54228',
    '54229',
    '54230',
    '54232',
    '54234',
    '54235',
    '54240',
    '54241',
    '54245',
    '54246',
    '54247',
    '54301',
    '54302',
    '54303',
    '54304',
    '54305',
    '54306',
    '54307',
    '54308',
    '54311',
    '54313',
    '54324',
    '54344',
    '54401',
    '54402',
    '54403',
    '54404',
    '54405',
    '54406',
    '54407',
    '54408',
    '54409',
    '54410',
    '54411',
    '54412',
    '54413',
    '54414',
    '54415',
    '54416',
    '54417',
    '54418',
    '54420',
    '54421',
    '54422',
    '54423',
    '54424',
    '54425',
    '54426',
    '54427',
    '54428',
    '54429',
    '54430',
    '54433',
    '54435',
    '54436',
    '54437',
    '54440',
    '54441',
    '54442',
    '54443',
    '54446',
    '54447',
    '54448',
    '54449',
    '54450',
    '54451',
    '54452',
    '54454',
    '54455',
    '54456',
    '54457',
    '54458',
    '54459',
    '54460',
    '54462',
    '54463',
    '54464',
    '54465',
    '54466',
    '54467',
    '54469',
    '54470',
    '54471',
    '54472',
    '54473',
    '54474',
    '54475',
    '54476',
    '54479',
    '54480',
    '54481',
    '54482',
    '54484',
    '54485',
    '54486',
    '54487',
    '54488',
    '54489',
    '54490',
    '54491',
    '54492',
    '54493',
    '54494',
    '54495',
    '54498',
    '54499',
    '54501',
    '54511',
    '54512',
    '54513',
    '54514',
    '54515',
    '54517',
    '54519',
    '54520',
    '54521',
    '54524',
    '54525',
    '54526',
    '54527',
    '54529',
    '54530',
    '54531',
    '54532',
    '54534',
    '54536',
    '54537',
    '54538',
    '54539',
    '54540',
    '54541',
    '54542',
    '54543',
    '54545',
    '54546',
    '54547',
    '54548',
    '54550',
    '54552',
    '54554',
    '54555',
    '54556',
    '54557',
    '54558',
    '54559',
    '54560',
    '54561',
    '54562',
    '54563',
    '54564',
    '54565',
    '54566',
    '54568',
    '54601',
    '54602',
    '54603',
    '54610',
    '54611',
    '54612',
    '54613',
    '54614',
    '54615',
    '54616',
    '54618',
    '54619',
    '54620',
    '54621',
    '54622',
    '54623',
    '54624',
    '54625',
    '54626',
    '54627',
    '54628',
    '54629',
    '54630',
    '54631',
    '54632',
    '54634',
    '54635',
    '54636',
    '54637',
    '54638',
    '54639',
    '54641',
    '54642',
    '54643',
    '54644',
    '54645',
    '54646',
    '54648',
    '54649',
    '54650',
    '54651',
    '54652',
    '54653',
    '54654',
    '54655',
    '54656',
    '54657',
    '54658',
    '54659',
    '54660',
    '54661',
    '54662',
    '54664',
    '54665',
    '54666',
    '54667',
    '54669',
    '54670',
    '54701',
    '54702',
    '54703',
    '54720',
    '54721',
    '54722',
    '54723',
    '54724',
    '54725',
    '54726',
    '54727',
    '54728',
    '54729',
    '54730',
    '54731',
    '54732',
    '54733',
    '54734',
    '54735',
    '54736',
    '54737',
    '54738',
    '54739',
    '54740',
    '54741',
    '54742',
    '54743',
    '54745',
    '54746',
    '54747',
    '54748',
    '54749',
    '54750',
    '54751',
    '54754',
    '54755',
    '54756',
    '54757',
    '54758',
    '54759',
    '54760',
    '54761',
    '54762',
    '54763',
    '54764',
    '54765',
    '54766',
    '54767',
    '54768',
    '54769',
    '54770',
    '54771',
    '54772',
    '54773',
    '54774',
    '54801',
    '54805',
    '54806',
    '54810',
    '54812',
    '54813',
    '54814',
    '54816',
    '54817',
    '54819',
    '54820',
    '54821',
    '54822',
    '54824',
    '54826',
    '54827',
    '54828',
    '54829',
    '54830',
    '54832',
    '54835',
    '54836',
    '54837',
    '54838',
    '54839',
    '54840',
    '54841',
    '54842',
    '54843',
    '54844',
    '54845',
    '54846',
    '54847',
    '54848',
    '54849',
    '54850',
    '54853',
    '54854',
    '54855',
    '54856',
    '54857',
    '54858',
    '54859',
    '54861',
    '54862',
    '54864',
    '54865',
    '54867',
    '54868',
    '54870',
    '54871',
    '54872',
    '54873',
    '54874',
    '54875',
    '54876',
    '54880',
    '54888',
    '54889',
    '54890',
    '54891',
    '54893',
    '54895',
    '54896',
    '54901',
    '54902',
    '54903',
    '54904',
    '54906',
    '54909',
    '54911',
    '54912',
    '54913',
    '54914',
    '54915',
    '54919',
    '54921',
    '54922',
    '54923',
    '54926',
    '54927',
    '54928',
    '54929',
    '54930',
    '54931',
    '54932',
    '54933',
    '54935',
    '54936',
    '54937',
    '54940',
    '54941',
    '54942',
    '54943',
    '54944',
    '54945',
    '54946',
    '54947',
    '54948',
    '54949',
    '54950',
    '54952',
    '54956',
    '54957',
    '54960',
    '54961',
    '54962',
    '54963',
    '54964',
    '54965',
    '54966',
    '54967',
    '54968',
    '54969',
    '54970',
    '54971',
    '54974',
    '54976',
    '54977',
    '54978',
    '54979',
    '54980',
    '54981',
    '54982',
    '54983',
    '54984',
    '54985',
    '54986',
    '54990',
  ],
  MN: [
    '55001',
    '55003',
    '55005',
    '55006',
    '55007',
    '55008',
    '55009',
    '55010',
    '55011',
    '55012',
    '55013',
    '55014',
    '55016',
    '55017',
    '55018',
    '55019',
    '55020',
    '55021',
    '55024',
    '55025',
    '55026',
    '55027',
    '55029',
    '55030',
    '55031',
    '55032',
    '55033',
    '55036',
    '55037',
    '55038',
    '55040',
    '55041',
    '55042',
    '55043',
    '55044',
    '55045',
    '55046',
    '55047',
    '55049',
    '55051',
    '55052',
    '55053',
    '55054',
    '55055',
    '55056',
    '55057',
    '55060',
    '55063',
    '55065',
    '55066',
    '55067',
    '55068',
    '55069',
    '55070',
    '55071',
    '55072',
    '55073',
    '55074',
    '55075',
    '55076',
    '55077',
    '55078',
    '55079',
    '55080',
    '55082',
    '55083',
    '55084',
    '55085',
    '55087',
    '55088',
    '55089',
    '55090',
    '55092',
    '55101',
    '55102',
    '55103',
    '55104',
    '55105',
    '55106',
    '55107',
    '55108',
    '55109',
    '55110',
    '55111',
    '55112',
    '55113',
    '55114',
    '55115',
    '55116',
    '55117',
    '55118',
    '55119',
    '55120',
    '55121',
    '55122',
    '55123',
    '55124',
    '55125',
    '55126',
    '55127',
    '55128',
    '55129',
    '55130',
    '55131',
    '55133',
    '55144',
    '55145',
    '55146',
    '55150',
    '55155',
    '55164',
    '55165',
    '55170',
    '55175',
    '55301',
    '55302',
    '55303',
    '55304',
    '55305',
    '55306',
    '55307',
    '55308',
    '55309',
    '55310',
    '55311',
    '55312',
    '55313',
    '55314',
    '55315',
    '55316',
    '55317',
    '55318',
    '55319',
    '55320',
    '55321',
    '55322',
    '55323',
    '55324',
    '55325',
    '55327',
    '55328',
    '55329',
    '55330',
    '55331',
    '55332',
    '55333',
    '55334',
    '55335',
    '55336',
    '55337',
    '55338',
    '55339',
    '55340',
    '55341',
    '55342',
    '55343',
    '55344',
    '55345',
    '55346',
    '55347',
    '55349',
    '55350',
    '55352',
    '55353',
    '55354',
    '55355',
    '55356',
    '55357',
    '55358',
    '55359',
    '55360',
    '55361',
    '55362',
    '55363',
    '55364',
    '55366',
    '55367',
    '55368',
    '55369',
    '55370',
    '55371',
    '55372',
    '55373',
    '55374',
    '55375',
    '55376',
    '55377',
    '55378',
    '55379',
    '55381',
    '55382',
    '55384',
    '55385',
    '55386',
    '55387',
    '55388',
    '55389',
    '55390',
    '55391',
    '55392',
    '55395',
    '55396',
    '55397',
    '55398',
    '55401',
    '55402',
    '55403',
    '55404',
    '55405',
    '55406',
    '55407',
    '55408',
    '55409',
    '55410',
    '55411',
    '55412',
    '55413',
    '55414',
    '55415',
    '55416',
    '55417',
    '55418',
    '55419',
    '55420',
    '55421',
    '55422',
    '55423',
    '55424',
    '55425',
    '55426',
    '55427',
    '55428',
    '55429',
    '55430',
    '55431',
    '55432',
    '55433',
    '55434',
    '55435',
    '55436',
    '55437',
    '55438',
    '55439',
    '55440',
    '55441',
    '55442',
    '55443',
    '55444',
    '55445',
    '55446',
    '55447',
    '55448',
    '55449',
    '55450',
    '55454',
    '55455',
    '55458',
    '55459',
    '55460',
    '55467',
    '55470',
    '55472',
    '55474',
    '55478',
    '55479',
    '55480',
    '55483',
    '55484',
    '55485',
    '55486',
    '55487',
    '55488',
    '55554',
    '55569',
    '55572',
    '55573',
    '55575',
    '55577',
    '55592',
    '55593',
    '55599',
    '55601',
    '55602',
    '55603',
    '55604',
    '55605',
    '55606',
    '55607',
    '55609',
    '55612',
    '55613',
    '55614',
    '55615',
    '55616',
    '55702',
    '55703',
    '55704',
    '55705',
    '55706',
    '55707',
    '55708',
    '55709',
    '55710',
    '55711',
    '55712',
    '55713',
    '55716',
    '55717',
    '55718',
    '55719',
    '55720',
    '55721',
    '55722',
    '55723',
    '55724',
    '55725',
    '55726',
    '55730',
    '55731',
    '55732',
    '55733',
    '55734',
    '55735',
    '55736',
    '55738',
    '55741',
    '55742',
    '55744',
    '55745',
    '55746',
    '55748',
    '55749',
    '55750',
    '55751',
    '55752',
    '55753',
    '55756',
    '55757',
    '55758',
    '55760',
    '55763',
    '55764',
    '55765',
    '55766',
    '55767',
    '55768',
    '55769',
    '55771',
    '55772',
    '55775',
    '55777',
    '55779',
    '55780',
    '55781',
    '55782',
    '55783',
    '55784',
    '55785',
    '55786',
    '55787',
    '55790',
    '55791',
    '55792',
    '55793',
    '55795',
    '55796',
    '55797',
    '55798',
    '55801',
    '55802',
    '55803',
    '55804',
    '55805',
    '55806',
    '55807',
    '55808',
    '55810',
    '55811',
    '55812',
    '55814',
    '55815',
    '55816',
    '55901',
    '55902',
    '55903',
    '55904',
    '55905',
    '55906',
    '55909',
    '55910',
    '55912',
    '55917',
    '55918',
    '55919',
    '55920',
    '55921',
    '55922',
    '55923',
    '55924',
    '55925',
    '55926',
    '55927',
    '55929',
    '55931',
    '55932',
    '55933',
    '55934',
    '55935',
    '55936',
    '55939',
    '55940',
    '55941',
    '55942',
    '55943',
    '55944',
    '55945',
    '55946',
    '55947',
    '55949',
    '55950',
    '55951',
    '55952',
    '55953',
    '55954',
    '55955',
    '55956',
    '55957',
    '55959',
    '55960',
    '55961',
    '55962',
    '55963',
    '55964',
    '55965',
    '55967',
    '55968',
    '55969',
    '55970',
    '55971',
    '55972',
    '55973',
    '55974',
    '55975',
    '55976',
    '55977',
    '55979',
    '55981',
    '55982',
    '55983',
    '55985',
    '55987',
    '55988',
    '55990',
    '55991',
    '55992',
    '56001',
    '56002',
    '56003',
    '56007',
    '56009',
    '56010',
    '56011',
    '56013',
    '56014',
    '56016',
    '56017',
    '56019',
    '56020',
    '56021',
    '56022',
    '56023',
    '56024',
    '56025',
    '56026',
    '56027',
    '56028',
    '56029',
    '56030',
    '56031',
    '56032',
    '56033',
    '56034',
    '56035',
    '56036',
    '56037',
    '56039',
    '56041',
    '56042',
    '56043',
    '56044',
    '56045',
    '56046',
    '56047',
    '56048',
    '56050',
    '56051',
    '56052',
    '56054',
    '56055',
    '56056',
    '56057',
    '56058',
    '56060',
    '56062',
    '56063',
    '56065',
    '56068',
    '56069',
    '56071',
    '56072',
    '56073',
    '56074',
    '56075',
    '56078',
    '56080',
    '56081',
    '56082',
    '56083',
    '56084',
    '56085',
    '56087',
    '56088',
    '56089',
    '56090',
    '56091',
    '56093',
    '56096',
    '56097',
    '56098',
    '56101',
    '56110',
    '56111',
    '56113',
    '56114',
    '56115',
    '56116',
    '56117',
    '56118',
    '56119',
    '56120',
    '56121',
    '56122',
    '56123',
    '56125',
    '56127',
    '56128',
    '56129',
    '56131',
    '56132',
    '56134',
    '56136',
    '56137',
    '56138',
    '56139',
    '56140',
    '56141',
    '56142',
    '56143',
    '56144',
    '56145',
    '56146',
    '56147',
    '56149',
    '56150',
    '56151',
    '56152',
    '56153',
    '56155',
    '56156',
    '56157',
    '56158',
    '56159',
    '56160',
    '56161',
    '56162',
    '56164',
    '56165',
    '56166',
    '56167',
    '56168',
    '56169',
    '56170',
    '56171',
    '56172',
    '56173',
    '56174',
    '56175',
    '56176',
    '56178',
    '56180',
    '56181',
    '56183',
    '56185',
    '56186',
    '56187',
    '56201',
    '56207',
    '56208',
    '56209',
    '56210',
    '56211',
    '56212',
    '56214',
    '56215',
    '56216',
    '56218',
    '56219',
    '56220',
    '56221',
    '56222',
    '56223',
    '56224',
    '56225',
    '56226',
    '56227',
    '56228',
    '56229',
    '56230',
    '56231',
    '56232',
    '56235',
    '56236',
    '56237',
    '56239',
    '56240',
    '56241',
    '56243',
    '56244',
    '56245',
    '56248',
    '56249',
    '56251',
    '56252',
    '56253',
    '56255',
    '56256',
    '56257',
    '56258',
    '56260',
    '56262',
    '56263',
    '56264',
    '56265',
    '56266',
    '56267',
    '56270',
    '56271',
    '56273',
    '56274',
    '56276',
    '56277',
    '56278',
    '56279',
    '56280',
    '56281',
    '56282',
    '56283',
    '56284',
    '56285',
    '56287',
    '56288',
    '56289',
    '56291',
    '56292',
    '56293',
    '56294',
    '56295',
    '56296',
    '56297',
    '56301',
    '56302',
    '56303',
    '56304',
    '56307',
    '56308',
    '56309',
    '56310',
    '56311',
    '56312',
    '56313',
    '56314',
    '56315',
    '56316',
    '56317',
    '56318',
    '56319',
    '56320',
    '56321',
    '56323',
    '56324',
    '56325',
    '56326',
    '56327',
    '56328',
    '56329',
    '56330',
    '56331',
    '56332',
    '56333',
    '56334',
    '56335',
    '56336',
    '56338',
    '56339',
    '56340',
    '56341',
    '56342',
    '56343',
    '56344',
    '56345',
    '56347',
    '56349',
    '56350',
    '56352',
    '56353',
    '56354',
    '56355',
    '56356',
    '56357',
    '56358',
    '56359',
    '56360',
    '56361',
    '56362',
    '56363',
    '56364',
    '56367',
    '56368',
    '56369',
    '56371',
    '56372',
    '56373',
    '56374',
    '56375',
    '56376',
    '56377',
    '56378',
    '56379',
    '56381',
    '56382',
    '56384',
    '56385',
    '56386',
    '56387',
    '56388',
    '56389',
    '56393',
    '56395',
    '56396',
    '56397',
    '56398',
    '56399',
    '56401',
    '56425',
    '56431',
    '56433',
    '56434',
    '56435',
    '56436',
    '56437',
    '56438',
    '56440',
    '56441',
    '56442',
    '56443',
    '56444',
    '56446',
    '56447',
    '56448',
    '56449',
    '56450',
    '56452',
    '56453',
    '56455',
    '56456',
    '56458',
    '56459',
    '56461',
    '56464',
    '56465',
    '56466',
    '56467',
    '56468',
    '56469',
    '56470',
    '56472',
    '56473',
    '56474',
    '56475',
    '56477',
    '56479',
    '56481',
    '56482',
    '56484',
    '56501',
    '56502',
    '56510',
    '56511',
    '56514',
    '56515',
    '56516',
    '56517',
    '56518',
    '56519',
    '56520',
    '56521',
    '56522',
    '56523',
    '56524',
    '56525',
    '56527',
    '56528',
    '56529',
    '56531',
    '56533',
    '56534',
    '56535',
    '56536',
    '56537',
    '56538',
    '56540',
    '56541',
    '56542',
    '56543',
    '56544',
    '56545',
    '56546',
    '56547',
    '56548',
    '56549',
    '56550',
    '56551',
    '56552',
    '56553',
    '56554',
    '56556',
    '56557',
    '56560',
    '56561',
    '56562',
    '56563',
    '56565',
    '56566',
    '56567',
    '56568',
    '56569',
    '56570',
    '56571',
    '56572',
    '56573',
    '56574',
    '56575',
    '56576',
    '56577',
    '56578',
    '56579',
    '56580',
    '56581',
    '56583',
    '56584',
    '56585',
    '56586',
    '56587',
    '56588',
    '56589',
    '56590',
    '56591',
    '56592',
    '56593',
    '56594',
    '56601',
    '56619',
    '56621',
    '56623',
    '56626',
    '56627',
    '56628',
    '56629',
    '56630',
    '56631',
    '56633',
    '56634',
    '56636',
    '56637',
    '56639',
    '56641',
    '56644',
    '56646',
    '56647',
    '56649',
    '56650',
    '56651',
    '56652',
    '56653',
    '56654',
    '56655',
    '56657',
    '56658',
    '56659',
    '56660',
    '56661',
    '56662',
    '56663',
    '56666',
    '56667',
    '56668',
    '56669',
    '56670',
    '56671',
    '56672',
    '56673',
    '56676',
    '56678',
    '56679',
    '56680',
    '56681',
    '56683',
    '56684',
    '56685',
    '56686',
    '56687',
    '56688',
    '56701',
    '56710',
    '56711',
    '56713',
    '56714',
    '56715',
    '56716',
    '56720',
    '56721',
    '56722',
    '56723',
    '56724',
    '56725',
    '56726',
    '56727',
    '56728',
    '56729',
    '56731',
    '56732',
    '56733',
    '56734',
    '56735',
    '56736',
    '56737',
    '56738',
    '56740',
    '56741',
    '56742',
    '56744',
    '56748',
    '56750',
    '56751',
    '56754',
    '56755',
    '56756',
    '56757',
    '56758',
    '56759',
    '56760',
    '56761',
    '56762',
    '56763',
  ],
  SD: [
    '57001',
    '57002',
    '57003',
    '57004',
    '57005',
    '57006',
    '57007',
    '57010',
    '57012',
    '57013',
    '57014',
    '57015',
    '57016',
    '57017',
    '57018',
    '57020',
    '57021',
    '57022',
    '57024',
    '57025',
    '57026',
    '57027',
    '57028',
    '57029',
    '57030',
    '57031',
    '57032',
    '57033',
    '57034',
    '57035',
    '57036',
    '57037',
    '57038',
    '57039',
    '57040',
    '57041',
    '57042',
    '57043',
    '57045',
    '57046',
    '57047',
    '57048',
    '57049',
    '57050',
    '57051',
    '57052',
    '57053',
    '57054',
    '57055',
    '57057',
    '57058',
    '57059',
    '57061',
    '57062',
    '57063',
    '57064',
    '57065',
    '57066',
    '57067',
    '57068',
    '57069',
    '57070',
    '57071',
    '57072',
    '57073',
    '57075',
    '57076',
    '57077',
    '57078',
    '57101',
    '57103',
    '57104',
    '57105',
    '57106',
    '57107',
    '57108',
    '57109',
    '57110',
    '57117',
    '57118',
    '57186',
    '57193',
    '57197',
    '57198',
    '57201',
    '57212',
    '57213',
    '57214',
    '57216',
    '57217',
    '57218',
    '57219',
    '57220',
    '57221',
    '57223',
    '57224',
    '57225',
    '57226',
    '57227',
    '57231',
    '57232',
    '57233',
    '57234',
    '57235',
    '57236',
    '57237',
    '57238',
    '57239',
    '57241',
    '57242',
    '57243',
    '57245',
    '57246',
    '57247',
    '57248',
    '57249',
    '57251',
    '57252',
    '57255',
    '57256',
    '57257',
    '57258',
    '57259',
    '57260',
    '57261',
    '57262',
    '57263',
    '57264',
    '57265',
    '57266',
    '57268',
    '57269',
    '57270',
    '57271',
    '57272',
    '57273',
    '57274',
    '57276',
    '57278',
    '57279',
    '57301',
    '57311',
    '57312',
    '57313',
    '57314',
    '57315',
    '57317',
    '57319',
    '57321',
    '57322',
    '57323',
    '57324',
    '57325',
    '57326',
    '57328',
    '57329',
    '57330',
    '57331',
    '57332',
    '57334',
    '57335',
    '57337',
    '57339',
    '57340',
    '57341',
    '57342',
    '57344',
    '57345',
    '57346',
    '57348',
    '57349',
    '57350',
    '57353',
    '57354',
    '57355',
    '57356',
    '57358',
    '57359',
    '57361',
    '57362',
    '57363',
    '57364',
    '57365',
    '57366',
    '57367',
    '57368',
    '57369',
    '57370',
    '57371',
    '57373',
    '57374',
    '57375',
    '57376',
    '57379',
    '57380',
    '57381',
    '57382',
    '57383',
    '57384',
    '57385',
    '57386',
    '57399',
    '57401',
    '57402',
    '57420',
    '57421',
    '57422',
    '57424',
    '57426',
    '57427',
    '57428',
    '57429',
    '57430',
    '57432',
    '57433',
    '57434',
    '57435',
    '57436',
    '57437',
    '57438',
    '57439',
    '57440',
    '57441',
    '57442',
    '57445',
    '57446',
    '57448',
    '57449',
    '57450',
    '57451',
    '57452',
    '57454',
    '57455',
    '57456',
    '57457',
    '57460',
    '57461',
    '57465',
    '57466',
    '57467',
    '57468',
    '57469',
    '57470',
    '57471',
    '57472',
    '57473',
    '57474',
    '57475',
    '57476',
    '57477',
    '57479',
    '57481',
    '57501',
    '57520',
    '57521',
    '57522',
    '57523',
    '57528',
    '57529',
    '57531',
    '57532',
    '57533',
    '57534',
    '57536',
    '57537',
    '57538',
    '57540',
    '57541',
    '57543',
    '57544',
    '57547',
    '57548',
    '57551',
    '57552',
    '57553',
    '57555',
    '57559',
    '57560',
    '57562',
    '57563',
    '57564',
    '57566',
    '57567',
    '57568',
    '57569',
    '57570',
    '57571',
    '57572',
    '57574',
    '57576',
    '57577',
    '57579',
    '57580',
    '57584',
    '57585',
    '57601',
    '57620',
    '57621',
    '57622',
    '57623',
    '57625',
    '57626',
    '57630',
    '57631',
    '57632',
    '57633',
    '57634',
    '57636',
    '57638',
    '57639',
    '57640',
    '57641',
    '57642',
    '57644',
    '57645',
    '57646',
    '57648',
    '57649',
    '57650',
    '57651',
    '57652',
    '57656',
    '57657',
    '57658',
    '57659',
    '57660',
    '57661',
    '57701',
    '57702',
    '57703',
    '57706',
    '57709',
    '57714',
    '57716',
    '57717',
    '57718',
    '57719',
    '57720',
    '57722',
    '57724',
    '57725',
    '57730',
    '57732',
    '57735',
    '57737',
    '57738',
    '57741',
    '57744',
    '57745',
    '57747',
    '57748',
    '57750',
    '57751',
    '57752',
    '57754',
    '57755',
    '57756',
    '57758',
    '57759',
    '57760',
    '57761',
    '57762',
    '57763',
    '57764',
    '57766',
    '57767',
    '57769',
    '57770',
    '57772',
    '57773',
    '57775',
    '57776',
    '57779',
    '57780',
    '57782',
    '57783',
    '57785',
    '57787',
    '57788',
    '57790',
    '57791',
    '57792',
    '57793',
    '57794',
    '57799',
  ],
  ND: [
    '58001',
    '58002',
    '58004',
    '58005',
    '58006',
    '58007',
    '58008',
    '58009',
    '58011',
    '58012',
    '58013',
    '58015',
    '58016',
    '58017',
    '58018',
    '58021',
    '58027',
    '58029',
    '58030',
    '58031',
    '58032',
    '58033',
    '58035',
    '58036',
    '58038',
    '58040',
    '58041',
    '58042',
    '58043',
    '58045',
    '58046',
    '58047',
    '58048',
    '58049',
    '58051',
    '58052',
    '58053',
    '58054',
    '58056',
    '58057',
    '58058',
    '58059',
    '58060',
    '58061',
    '58062',
    '58063',
    '58064',
    '58065',
    '58067',
    '58068',
    '58069',
    '58071',
    '58072',
    '58074',
    '58075',
    '58076',
    '58077',
    '58078',
    '58079',
    '58081',
    '58102',
    '58103',
    '58104',
    '58105',
    '58106',
    '58107',
    '58108',
    '58109',
    '58121',
    '58122',
    '58124',
    '58125',
    '58126',
    '58201',
    '58202',
    '58203',
    '58204',
    '58205',
    '58206',
    '58207',
    '58208',
    '58210',
    '58212',
    '58214',
    '58216',
    '58218',
    '58219',
    '58220',
    '58222',
    '58223',
    '58224',
    '58225',
    '58227',
    '58228',
    '58229',
    '58230',
    '58231',
    '58233',
    '58235',
    '58236',
    '58237',
    '58238',
    '58239',
    '58240',
    '58241',
    '58243',
    '58244',
    '58249',
    '58250',
    '58251',
    '58254',
    '58255',
    '58256',
    '58257',
    '58258',
    '58259',
    '58260',
    '58261',
    '58262',
    '58265',
    '58266',
    '58267',
    '58269',
    '58270',
    '58271',
    '58272',
    '58273',
    '58274',
    '58275',
    '58276',
    '58277',
    '58278',
    '58281',
    '58282',
    '58301',
    '58310',
    '58311',
    '58313',
    '58316',
    '58317',
    '58318',
    '58321',
    '58323',
    '58324',
    '58325',
    '58327',
    '58329',
    '58330',
    '58331',
    '58332',
    '58335',
    '58338',
    '58339',
    '58341',
    '58343',
    '58344',
    '58345',
    '58346',
    '58348',
    '58351',
    '58352',
    '58353',
    '58355',
    '58356',
    '58357',
    '58361',
    '58362',
    '58363',
    '58365',
    '58366',
    '58367',
    '58368',
    '58369',
    '58370',
    '58372',
    '58374',
    '58377',
    '58379',
    '58380',
    '58381',
    '58382',
    '58384',
    '58385',
    '58386',
    '58401',
    '58402',
    '58405',
    '58413',
    '58415',
    '58416',
    '58418',
    '58420',
    '58421',
    '58422',
    '58423',
    '58424',
    '58425',
    '58426',
    '58428',
    '58429',
    '58430',
    '58431',
    '58433',
    '58436',
    '58438',
    '58439',
    '58440',
    '58441',
    '58442',
    '58443',
    '58444',
    '58445',
    '58448',
    '58451',
    '58452',
    '58454',
    '58455',
    '58456',
    '58458',
    '58460',
    '58461',
    '58463',
    '58464',
    '58466',
    '58467',
    '58472',
    '58474',
    '58475',
    '58476',
    '58477',
    '58478',
    '58479',
    '58480',
    '58481',
    '58482',
    '58483',
    '58484',
    '58486',
    '58487',
    '58488',
    '58490',
    '58492',
    '58494',
    '58495',
    '58496',
    '58497',
    '58501',
    '58502',
    '58503',
    '58504',
    '58505',
    '58506',
    '58507',
    '58520',
    '58521',
    '58523',
    '58524',
    '58528',
    '58529',
    '58530',
    '58531',
    '58532',
    '58533',
    '58535',
    '58538',
    '58540',
    '58541',
    '58542',
    '58544',
    '58545',
    '58549',
    '58552',
    '58554',
    '58558',
    '58559',
    '58560',
    '58561',
    '58562',
    '58563',
    '58564',
    '58565',
    '58566',
    '58568',
    '58569',
    '58570',
    '58571',
    '58572',
    '58573',
    '58575',
    '58576',
    '58577',
    '58579',
    '58580',
    '58581',
    '58601',
    '58602',
    '58620',
    '58621',
    '58622',
    '58623',
    '58625',
    '58626',
    '58627',
    '58630',
    '58631',
    '58632',
    '58634',
    '58636',
    '58638',
    '58639',
    '58640',
    '58641',
    '58642',
    '58643',
    '58644',
    '58645',
    '58646',
    '58647',
    '58649',
    '58650',
    '58651',
    '58652',
    '58653',
    '58654',
    '58655',
    '58656',
    '58701',
    '58702',
    '58703',
    '58704',
    '58705',
    '58707',
    '58710',
    '58711',
    '58712',
    '58713',
    '58716',
    '58718',
    '58721',
    '58722',
    '58723',
    '58725',
    '58727',
    '58730',
    '58731',
    '58733',
    '58734',
    '58735',
    '58736',
    '58737',
    '58740',
    '58741',
    '58744',
    '58746',
    '58748',
    '58750',
    '58752',
    '58755',
    '58756',
    '58757',
    '58758',
    '58759',
    '58760',
    '58761',
    '58762',
    '58763',
    '58765',
    '58768',
    '58769',
    '58770',
    '58771',
    '58772',
    '58773',
    '58775',
    '58776',
    '58778',
    '58779',
    '58781',
    '58782',
    '58783',
    '58784',
    '58785',
    '58787',
    '58788',
    '58789',
    '58790',
    '58792',
    '58793',
    '58794',
    '58795',
    '58801',
    '58802',
    '58803',
    '58830',
    '58831',
    '58833',
    '58835',
    '58838',
    '58843',
    '58844',
    '58845',
    '58847',
    '58849',
    '58852',
    '58853',
    '58854',
    '58856',
  ],
  MT: [
    '59001',
    '59002',
    '59003',
    '59004',
    '59006',
    '59007',
    '59008',
    '59010',
    '59011',
    '59012',
    '59013',
    '59014',
    '59015',
    '59016',
    '59018',
    '59019',
    '59020',
    '59022',
    '59024',
    '59025',
    '59026',
    '59027',
    '59028',
    '59029',
    '59030',
    '59031',
    '59032',
    '59033',
    '59034',
    '59035',
    '59036',
    '59037',
    '59038',
    '59039',
    '59041',
    '59043',
    '59044',
    '59046',
    '59047',
    '59050',
    '59052',
    '59053',
    '59054',
    '59055',
    '59057',
    '59058',
    '59059',
    '59061',
    '59062',
    '59063',
    '59064',
    '59065',
    '59066',
    '59067',
    '59068',
    '59069',
    '59070',
    '59071',
    '59072',
    '59073',
    '59074',
    '59075',
    '59076',
    '59077',
    '59078',
    '59079',
    '59081',
    '59082',
    '59083',
    '59084',
    '59085',
    '59086',
    '59087',
    '59088',
    '59089',
    '59101',
    '59102',
    '59103',
    '59104',
    '59105',
    '59106',
    '59107',
    '59108',
    '59111',
    '59112',
    '59114',
    '59115',
    '59116',
    '59117',
    '59201',
    '59211',
    '59212',
    '59213',
    '59214',
    '59215',
    '59217',
    '59218',
    '59219',
    '59221',
    '59222',
    '59223',
    '59225',
    '59226',
    '59230',
    '59231',
    '59240',
    '59241',
    '59242',
    '59243',
    '59244',
    '59247',
    '59248',
    '59250',
    '59252',
    '59253',
    '59254',
    '59255',
    '59256',
    '59257',
    '59258',
    '59259',
    '59260',
    '59261',
    '59262',
    '59263',
    '59270',
    '59273',
    '59274',
    '59275',
    '59276',
    '59301',
    '59311',
    '59312',
    '59313',
    '59314',
    '59315',
    '59316',
    '59317',
    '59318',
    '59319',
    '59322',
    '59323',
    '59324',
    '59326',
    '59327',
    '59330',
    '59332',
    '59333',
    '59336',
    '59337',
    '59338',
    '59339',
    '59341',
    '59343',
    '59344',
    '59345',
    '59347',
    '59349',
    '59351',
    '59353',
    '59354',
    '59401',
    '59402',
    '59403',
    '59404',
    '59405',
    '59406',
    '59410',
    '59411',
    '59412',
    '59414',
    '59416',
    '59417',
    '59418',
    '59419',
    '59420',
    '59421',
    '59422',
    '59424',
    '59425',
    '59427',
    '59430',
    '59432',
    '59433',
    '59434',
    '59435',
    '59436',
    '59440',
    '59441',
    '59442',
    '59443',
    '59444',
    '59446',
    '59447',
    '59448',
    '59450',
    '59451',
    '59452',
    '59453',
    '59454',
    '59456',
    '59457',
    '59460',
    '59461',
    '59462',
    '59463',
    '59464',
    '59465',
    '59466',
    '59467',
    '59468',
    '59469',
    '59471',
    '59472',
    '59474',
    '59477',
    '59479',
    '59480',
    '59482',
    '59483',
    '59484',
    '59485',
    '59486',
    '59487',
    '59489',
    '59501',
    '59520',
    '59521',
    '59522',
    '59523',
    '59524',
    '59525',
    '59526',
    '59527',
    '59528',
    '59529',
    '59530',
    '59531',
    '59532',
    '59535',
    '59537',
    '59538',
    '59540',
    '59542',
    '59544',
    '59545',
    '59546',
    '59547',
    '59601',
    '59602',
    '59604',
    '59620',
    '59623',
    '59624',
    '59625',
    '59626',
    '59631',
    '59632',
    '59633',
    '59634',
    '59635',
    '59636',
    '59638',
    '59639',
    '59640',
    '59641',
    '59642',
    '59643',
    '59644',
    '59645',
    '59647',
    '59648',
    '59701',
    '59702',
    '59703',
    '59707',
    '59710',
    '59711',
    '59713',
    '59714',
    '59715',
    '59716',
    '59717',
    '59718',
    '59719',
    '59720',
    '59721',
    '59722',
    '59724',
    '59725',
    '59727',
    '59728',
    '59729',
    '59730',
    '59731',
    '59732',
    '59733',
    '59735',
    '59736',
    '59739',
    '59740',
    '59741',
    '59743',
    '59745',
    '59746',
    '59747',
    '59748',
    '59749',
    '59750',
    '59751',
    '59752',
    '59754',
    '59755',
    '59756',
    '59758',
    '59759',
    '59760',
    '59761',
    '59762',
    '59771',
    '59772',
    '59801',
    '59802',
    '59803',
    '59804',
    '59806',
    '59807',
    '59808',
    '59812',
    '59820',
    '59821',
    '59823',
    '59824',
    '59825',
    '59826',
    '59827',
    '59828',
    '59829',
    '59830',
    '59831',
    '59832',
    '59833',
    '59834',
    '59835',
    '59837',
    '59840',
    '59841',
    '59842',
    '59843',
    '59844',
    '59845',
    '59846',
    '59847',
    '59848',
    '59851',
    '59853',
    '59854',
    '59855',
    '59856',
    '59858',
    '59859',
    '59860',
    '59863',
    '59864',
    '59865',
    '59866',
    '59867',
    '59868',
    '59870',
    '59871',
    '59872',
    '59873',
    '59874',
    '59875',
    '59901',
    '59903',
    '59904',
    '59910',
    '59911',
    '59912',
    '59913',
    '59914',
    '59915',
    '59916',
    '59917',
    '59918',
    '59919',
    '59920',
    '59921',
    '59922',
    '59923',
    '59925',
    '59926',
    '59927',
    '59928',
    '59929',
    '59930',
    '59931',
    '59932',
    '59933',
    '59934',
    '59935',
    '59936',
    '59937',
  ],
  IL: [
    '60002',
    '60004',
    '60005',
    '60006',
    '60007',
    '60008',
    '60009',
    '60010',
    '60011',
    '60012',
    '60013',
    '60014',
    '60015',
    '60016',
    '60017',
    '60018',
    '60019',
    '60020',
    '60021',
    '60022',
    '60025',
    '60026',
    '60029',
    '60030',
    '60031',
    '60033',
    '60034',
    '60035',
    '60037',
    '60038',
    '60039',
    '60040',
    '60041',
    '60042',
    '60043',
    '60044',
    '60045',
    '60046',
    '60047',
    '60048',
    '60050',
    '60051',
    '60053',
    '60055',
    '60056',
    '60060',
    '60061',
    '60062',
    '60064',
    '60065',
    '60067',
    '60068',
    '60069',
    '60070',
    '60071',
    '60072',
    '60073',
    '60074',
    '60075',
    '60076',
    '60077',
    '60078',
    '60079',
    '60081',
    '60082',
    '60083',
    '60084',
    '60085',
    '60086',
    '60087',
    '60088',
    '60089',
    '60090',
    '60091',
    '60093',
    '60094',
    '60095',
    '60096',
    '60097',
    '60098',
    '60099',
    '60101',
    '60102',
    '60103',
    '60104',
    '60105',
    '60106',
    '60107',
    '60108',
    '60109',
    '60110',
    '60111',
    '60112',
    '60113',
    '60115',
    '60116',
    '60117',
    '60118',
    '60119',
    '60120',
    '60121',
    '60122',
    '60123',
    '60124',
    '60126',
    '60128',
    '60129',
    '60130',
    '60131',
    '60132',
    '60133',
    '60134',
    '60135',
    '60136',
    '60137',
    '60138',
    '60139',
    '60140',
    '60141',
    '60142',
    '60143',
    '60144',
    '60145',
    '60146',
    '60147',
    '60148',
    '60150',
    '60151',
    '60152',
    '60153',
    '60154',
    '60155',
    '60156',
    '60157',
    '60159',
    '60160',
    '60161',
    '60162',
    '60163',
    '60164',
    '60165',
    '60168',
    '60169',
    '60171',
    '60172',
    '60173',
    '60174',
    '60175',
    '60176',
    '60177',
    '60178',
    '60179',
    '60180',
    '60181',
    '60183',
    '60184',
    '60185',
    '60186',
    '60187',
    '60188',
    '60189',
    '60190',
    '60191',
    '60192',
    '60193',
    '60194',
    '60195',
    '60196',
    '60197',
    '60199',
    '60201',
    '60202',
    '60203',
    '60204',
    '60208',
    '60301',
    '60302',
    '60303',
    '60304',
    '60305',
    '60399',
    '60401',
    '60402',
    '60403',
    '60404',
    '60406',
    '60407',
    '60408',
    '60409',
    '60410',
    '60411',
    '60412',
    '60415',
    '60416',
    '60417',
    '60418',
    '60419',
    '60420',
    '60421',
    '60422',
    '60423',
    '60424',
    '60425',
    '60426',
    '60428',
    '60429',
    '60430',
    '60431',
    '60432',
    '60433',
    '60434',
    '60435',
    '60436',
    '60437',
    '60438',
    '60439',
    '60440',
    '60441',
    '60442',
    '60443',
    '60444',
    '60445',
    '60446',
    '60447',
    '60448',
    '60449',
    '60450',
    '60451',
    '60452',
    '60453',
    '60454',
    '60455',
    '60456',
    '60457',
    '60458',
    '60459',
    '60460',
    '60461',
    '60462',
    '60463',
    '60464',
    '60465',
    '60466',
    '60467',
    '60468',
    '60469',
    '60470',
    '60471',
    '60472',
    '60473',
    '60474',
    '60475',
    '60476',
    '60477',
    '60478',
    '60479',
    '60480',
    '60481',
    '60482',
    '60484',
    '60487',
    '60490',
    '60491',
    '60499',
    '60501',
    '60502',
    '60503',
    '60504',
    '60505',
    '60506',
    '60507',
    '60510',
    '60511',
    '60512',
    '60513',
    '60514',
    '60515',
    '60516',
    '60517',
    '60518',
    '60519',
    '60520',
    '60521',
    '60522',
    '60523',
    '60525',
    '60526',
    '60527',
    '60530',
    '60531',
    '60532',
    '60534',
    '60536',
    '60537',
    '60538',
    '60539',
    '60540',
    '60541',
    '60542',
    '60543',
    '60544',
    '60545',
    '60546',
    '60548',
    '60549',
    '60550',
    '60551',
    '60552',
    '60553',
    '60554',
    '60555',
    '60556',
    '60557',
    '60558',
    '60559',
    '60560',
    '60561',
    '60563',
    '60564',
    '60565',
    '60566',
    '60567',
    '60568',
    '60569',
    '60572',
    '60585',
    '60586',
    '60598',
    '60599',
    '60601',
    '60602',
    '60603',
    '60604',
    '60605',
    '60606',
    '60607',
    '60608',
    '60609',
    '60610',
    '60611',
    '60612',
    '60613',
    '60614',
    '60615',
    '60616',
    '60617',
    '60618',
    '60619',
    '60620',
    '60621',
    '60622',
    '60623',
    '60624',
    '60625',
    '60626',
    '60628',
    '60629',
    '60630',
    '60631',
    '60632',
    '60633',
    '60634',
    '60636',
    '60637',
    '60638',
    '60639',
    '60640',
    '60641',
    '60642',
    '60643',
    '60644',
    '60645',
    '60646',
    '60647',
    '60649',
    '60651',
    '60652',
    '60653',
    '60654',
    '60655',
    '60656',
    '60657',
    '60659',
    '60660',
    '60661',
    '60664',
    '60666',
    '60668',
    '60669',
    '60670',
    '60673',
    '60674',
    '60675',
    '60677',
    '60678',
    '60680',
    '60681',
    '60682',
    '60684',
    '60685',
    '60686',
    '60687',
    '60688',
    '60689',
    '60690',
    '60691',
    '60693',
    '60694',
    '60695',
    '60696',
    '60697',
    '60699',
    '60701',
    '60706',
    '60707',
    '60712',
    '60714',
    '60803',
    '60804',
    '60805',
    '60827',
    '60901',
    '60910',
    '60911',
    '60912',
    '60913',
    '60914',
    '60915',
    '60917',
    '60918',
    '60919',
    '60920',
    '60921',
    '60922',
    '60924',
    '60926',
    '60927',
    '60928',
    '60929',
    '60930',
    '60931',
    '60932',
    '60933',
    '60934',
    '60935',
    '60936',
    '60938',
    '60939',
    '60940',
    '60941',
    '60942',
    '60944',
    '60945',
    '60946',
    '60948',
    '60949',
    '60950',
    '60951',
    '60952',
    '60953',
    '60954',
    '60955',
    '60956',
    '60957',
    '60958',
    '60959',
    '60960',
    '60961',
    '60962',
    '60963',
    '60964',
    '60966',
    '60967',
    '60968',
    '60969',
    '60970',
    '60973',
    '60974',
    '61001',
    '61006',
    '61007',
    '61008',
    '61010',
    '61011',
    '61012',
    '61013',
    '61014',
    '61015',
    '61016',
    '61018',
    '61019',
    '61020',
    '61021',
    '61024',
    '61025',
    '61027',
    '61028',
    '61030',
    '61031',
    '61032',
    '61036',
    '61037',
    '61038',
    '61039',
    '61041',
    '61042',
    '61043',
    '61044',
    '61046',
    '61047',
    '61048',
    '61049',
    '61050',
    '61051',
    '61052',
    '61053',
    '61054',
    '61057',
    '61059',
    '61060',
    '61061',
    '61062',
    '61063',
    '61064',
    '61065',
    '61067',
    '61068',
    '61070',
    '61071',
    '61072',
    '61073',
    '61074',
    '61075',
    '61077',
    '61078',
    '61079',
    '61080',
    '61081',
    '61084',
    '61085',
    '61087',
    '61088',
    '61089',
    '61091',
    '61101',
    '61102',
    '61103',
    '61104',
    '61105',
    '61106',
    '61107',
    '61108',
    '61109',
    '61110',
    '61111',
    '61112',
    '61114',
    '61115',
    '61125',
    '61126',
    '61130',
    '61131',
    '61132',
    '61201',
    '61204',
    '61230',
    '61231',
    '61232',
    '61233',
    '61234',
    '61235',
    '61236',
    '61237',
    '61238',
    '61239',
    '61240',
    '61241',
    '61242',
    '61243',
    '61244',
    '61250',
    '61251',
    '61252',
    '61254',
    '61256',
    '61257',
    '61258',
    '61259',
    '61260',
    '61261',
    '61262',
    '61263',
    '61264',
    '61265',
    '61266',
    '61270',
    '61272',
    '61273',
    '61274',
    '61275',
    '61276',
    '61277',
    '61278',
    '61279',
    '61281',
    '61282',
    '61283',
    '61284',
    '61285',
    '61299',
    '61301',
    '61310',
    '61311',
    '61312',
    '61313',
    '61314',
    '61315',
    '61316',
    '61317',
    '61318',
    '61319',
    '61320',
    '61321',
    '61322',
    '61323',
    '61324',
    '61325',
    '61326',
    '61327',
    '61328',
    '61329',
    '61330',
    '61331',
    '61332',
    '61333',
    '61334',
    '61335',
    '61336',
    '61337',
    '61338',
    '61340',
    '61341',
    '61342',
    '61344',
    '61345',
    '61346',
    '61348',
    '61349',
    '61350',
    '61353',
    '61354',
    '61356',
    '61358',
    '61359',
    '61360',
    '61361',
    '61362',
    '61363',
    '61364',
    '61367',
    '61368',
    '61369',
    '61370',
    '61371',
    '61372',
    '61373',
    '61374',
    '61375',
    '61376',
    '61377',
    '61378',
    '61379',
    '61401',
    '61402',
    '61410',
    '61411',
    '61412',
    '61413',
    '61414',
    '61415',
    '61416',
    '61417',
    '61418',
    '61419',
    '61420',
    '61421',
    '61422',
    '61423',
    '61424',
    '61425',
    '61426',
    '61427',
    '61428',
    '61430',
    '61431',
    '61432',
    '61433',
    '61434',
    '61435',
    '61436',
    '61437',
    '61438',
    '61439',
    '61440',
    '61441',
    '61442',
    '61443',
    '61447',
    '61448',
    '61449',
    '61450',
    '61451',
    '61452',
    '61453',
    '61454',
    '61455',
    '61458',
    '61459',
    '61460',
    '61462',
    '61465',
    '61466',
    '61467',
    '61468',
    '61469',
    '61470',
    '61471',
    '61472',
    '61473',
    '61474',
    '61475',
    '61476',
    '61477',
    '61478',
    '61479',
    '61480',
    '61482',
    '61483',
    '61484',
    '61485',
    '61486',
    '61488',
    '61489',
    '61490',
    '61491',
    '61501',
    '61516',
    '61517',
    '61519',
    '61520',
    '61523',
    '61524',
    '61525',
    '61526',
    '61528',
    '61529',
    '61530',
    '61531',
    '61532',
    '61533',
    '61534',
    '61535',
    '61536',
    '61537',
    '61539',
    '61540',
    '61541',
    '61542',
    '61543',
    '61544',
    '61545',
    '61546',
    '61547',
    '61548',
    '61550',
    '61552',
    '61553',
    '61554',
    '61555',
    '61558',
    '61559',
    '61560',
    '61561',
    '61562',
    '61563',
    '61564',
    '61565',
    '61567',
    '61568',
    '61569',
    '61570',
    '61571',
    '61572',
    '61601',
    '61602',
    '61603',
    '61604',
    '61605',
    '61606',
    '61607',
    '61610',
    '61611',
    '61612',
    '61613',
    '61614',
    '61615',
    '61616',
    '61625',
    '61629',
    '61630',
    '61633',
    '61634',
    '61635',
    '61636',
    '61637',
    '61638',
    '61639',
    '61641',
    '61643',
    '61650',
    '61651',
    '61652',
    '61653',
    '61654',
    '61655',
    '61656',
    '61701',
    '61702',
    '61704',
    '61705',
    '61709',
    '61710',
    '61720',
    '61721',
    '61722',
    '61723',
    '61724',
    '61725',
    '61726',
    '61727',
    '61728',
    '61729',
    '61730',
    '61731',
    '61732',
    '61733',
    '61734',
    '61735',
    '61736',
    '61737',
    '61738',
    '61739',
    '61740',
    '61741',
    '61742',
    '61743',
    '61744',
    '61745',
    '61747',
    '61748',
    '61749',
    '61750',
    '61751',
    '61752',
    '61753',
    '61754',
    '61755',
    '61756',
    '61758',
    '61759',
    '61760',
    '61761',
    '61764',
    '61769',
    '61770',
    '61771',
    '61772',
    '61773',
    '61774',
    '61775',
    '61776',
    '61777',
    '61778',
    '61790',
    '61791',
    '61799',
    '61801',
    '61802',
    '61803',
    '61810',
    '61811',
    '61812',
    '61813',
    '61814',
    '61815',
    '61816',
    '61817',
    '61818',
    '61820',
    '61821',
    '61822',
    '61824',
    '61825',
    '61826',
    '61830',
    '61831',
    '61832',
    '61833',
    '61834',
    '61839',
    '61840',
    '61841',
    '61842',
    '61843',
    '61844',
    '61845',
    '61846',
    '61847',
    '61848',
    '61849',
    '61850',
    '61851',
    '61852',
    '61853',
    '61854',
    '61855',
    '61856',
    '61857',
    '61858',
    '61859',
    '61862',
    '61863',
    '61864',
    '61865',
    '61866',
    '61870',
    '61871',
    '61872',
    '61873',
    '61874',
    '61875',
    '61876',
    '61877',
    '61878',
    '61880',
    '61882',
    '61883',
    '61884',
    '61910',
    '61911',
    '61912',
    '61913',
    '61914',
    '61917',
    '61919',
    '61920',
    '61924',
    '61925',
    '61928',
    '61929',
    '61930',
    '61931',
    '61932',
    '61933',
    '61936',
    '61937',
    '61938',
    '61940',
    '61941',
    '61942',
    '61943',
    '61944',
    '61949',
    '61951',
    '61953',
    '61955',
    '61956',
    '61957',
    '62001',
    '62002',
    '62006',
    '62009',
    '62010',
    '62011',
    '62012',
    '62013',
    '62014',
    '62015',
    '62016',
    '62017',
    '62018',
    '62019',
    '62021',
    '62022',
    '62023',
    '62024',
    '62025',
    '62026',
    '62027',
    '62028',
    '62030',
    '62031',
    '62032',
    '62033',
    '62034',
    '62035',
    '62036',
    '62037',
    '62040',
    '62044',
    '62045',
    '62046',
    '62047',
    '62048',
    '62049',
    '62050',
    '62051',
    '62052',
    '62053',
    '62054',
    '62056',
    '62058',
    '62059',
    '62060',
    '62061',
    '62062',
    '62063',
    '62065',
    '62067',
    '62069',
    '62070',
    '62071',
    '62074',
    '62075',
    '62076',
    '62077',
    '62078',
    '62079',
    '62080',
    '62081',
    '62082',
    '62083',
    '62084',
    '62085',
    '62086',
    '62087',
    '62088',
    '62089',
    '62090',
    '62091',
    '62092',
    '62093',
    '62094',
    '62095',
    '62097',
    '62098',
    '62201',
    '62202',
    '62203',
    '62204',
    '62205',
    '62206',
    '62207',
    '62208',
    '62214',
    '62215',
    '62216',
    '62217',
    '62218',
    '62219',
    '62220',
    '62221',
    '62222',
    '62223',
    '62225',
    '62226',
    '62230',
    '62231',
    '62232',
    '62233',
    '62234',
    '62236',
    '62237',
    '62238',
    '62239',
    '62240',
    '62241',
    '62242',
    '62243',
    '62244',
    '62245',
    '62246',
    '62248',
    '62249',
    '62250',
    '62252',
    '62253',
    '62254',
    '62255',
    '62256',
    '62257',
    '62258',
    '62259',
    '62260',
    '62261',
    '62262',
    '62263',
    '62264',
    '62265',
    '62266',
    '62268',
    '62269',
    '62271',
    '62272',
    '62273',
    '62274',
    '62275',
    '62277',
    '62278',
    '62279',
    '62280',
    '62281',
    '62282',
    '62284',
    '62285',
    '62286',
    '62288',
    '62289',
    '62292',
    '62293',
    '62294',
    '62295',
    '62297',
    '62298',
    '62301',
    '62305',
    '62306',
    '62311',
    '62312',
    '62313',
    '62314',
    '62316',
    '62319',
    '62320',
    '62321',
    '62323',
    '62324',
    '62325',
    '62326',
    '62329',
    '62330',
    '62334',
    '62336',
    '62338',
    '62339',
    '62340',
    '62341',
    '62343',
    '62344',
    '62345',
    '62346',
    '62347',
    '62348',
    '62349',
    '62351',
    '62352',
    '62353',
    '62354',
    '62355',
    '62356',
    '62357',
    '62358',
    '62359',
    '62360',
    '62361',
    '62362',
    '62363',
    '62365',
    '62366',
    '62367',
    '62370',
    '62373',
    '62374',
    '62375',
    '62376',
    '62378',
    '62379',
    '62380',
    '62401',
    '62410',
    '62411',
    '62413',
    '62414',
    '62417',
    '62418',
    '62419',
    '62420',
    '62421',
    '62422',
    '62423',
    '62424',
    '62425',
    '62426',
    '62427',
    '62428',
    '62431',
    '62432',
    '62433',
    '62434',
    '62436',
    '62438',
    '62439',
    '62440',
    '62441',
    '62442',
    '62443',
    '62444',
    '62445',
    '62446',
    '62447',
    '62448',
    '62449',
    '62450',
    '62451',
    '62452',
    '62454',
    '62458',
    '62459',
    '62460',
    '62461',
    '62462',
    '62463',
    '62464',
    '62465',
    '62466',
    '62467',
    '62468',
    '62469',
    '62471',
    '62473',
    '62474',
    '62475',
    '62476',
    '62477',
    '62478',
    '62479',
    '62480',
    '62481',
    '62501',
    '62510',
    '62512',
    '62513',
    '62514',
    '62515',
    '62517',
    '62518',
    '62519',
    '62520',
    '62521',
    '62522',
    '62523',
    '62524',
    '62525',
    '62526',
    '62530',
    '62531',
    '62532',
    '62533',
    '62534',
    '62535',
    '62536',
    '62537',
    '62538',
    '62539',
    '62540',
    '62541',
    '62543',
    '62544',
    '62545',
    '62546',
    '62547',
    '62548',
    '62549',
    '62550',
    '62551',
    '62553',
    '62554',
    '62555',
    '62556',
    '62557',
    '62558',
    '62560',
    '62561',
    '62563',
    '62565',
    '62567',
    '62568',
    '62570',
    '62571',
    '62572',
    '62573',
    '62601',
    '62610',
    '62611',
    '62612',
    '62613',
    '62615',
    '62617',
    '62618',
    '62621',
    '62622',
    '62624',
    '62625',
    '62626',
    '62627',
    '62628',
    '62629',
    '62630',
    '62631',
    '62633',
    '62634',
    '62635',
    '62638',
    '62639',
    '62640',
    '62642',
    '62643',
    '62644',
    '62649',
    '62650',
    '62651',
    '62655',
    '62656',
    '62659',
    '62660',
    '62661',
    '62662',
    '62663',
    '62664',
    '62665',
    '62666',
    '62667',
    '62668',
    '62670',
    '62671',
    '62672',
    '62673',
    '62674',
    '62675',
    '62677',
    '62681',
    '62682',
    '62683',
    '62684',
    '62685',
    '62688',
    '62689',
    '62690',
    '62691',
    '62692',
    '62693',
    '62694',
    '62695',
    '62701',
    '62702',
    '62703',
    '62704',
    '62705',
    '62706',
    '62707',
    '62708',
    '62711',
    '62712',
    '62715',
    '62716',
    '62719',
    '62722',
    '62723',
    '62726',
    '62736',
    '62739',
    '62756',
    '62757',
    '62761',
    '62762',
    '62763',
    '62764',
    '62765',
    '62766',
    '62767',
    '62769',
    '62776',
    '62777',
    '62781',
    '62786',
    '62791',
    '62794',
    '62796',
    '62801',
    '62803',
    '62806',
    '62807',
    '62808',
    '62809',
    '62810',
    '62811',
    '62812',
    '62814',
    '62815',
    '62816',
    '62817',
    '62818',
    '62819',
    '62820',
    '62821',
    '62822',
    '62823',
    '62824',
    '62825',
    '62827',
    '62828',
    '62829',
    '62830',
    '62831',
    '62832',
    '62833',
    '62834',
    '62835',
    '62836',
    '62837',
    '62838',
    '62839',
    '62840',
    '62841',
    '62842',
    '62843',
    '62844',
    '62846',
    '62848',
    '62849',
    '62850',
    '62851',
    '62852',
    '62853',
    '62854',
    '62856',
    '62858',
    '62859',
    '62860',
    '62861',
    '62862',
    '62863',
    '62864',
    '62865',
    '62866',
    '62867',
    '62868',
    '62869',
    '62870',
    '62871',
    '62872',
    '62874',
    '62875',
    '62876',
    '62877',
    '62878',
    '62879',
    '62880',
    '62881',
    '62882',
    '62883',
    '62884',
    '62885',
    '62886',
    '62887',
    '62888',
    '62889',
    '62890',
    '62891',
    '62892',
    '62893',
    '62894',
    '62895',
    '62896',
    '62897',
    '62898',
    '62899',
    '62901',
    '62902',
    '62903',
    '62905',
    '62906',
    '62907',
    '62908',
    '62909',
    '62910',
    '62912',
    '62914',
    '62915',
    '62916',
    '62917',
    '62918',
    '62919',
    '62920',
    '62921',
    '62922',
    '62923',
    '62924',
    '62926',
    '62927',
    '62928',
    '62930',
    '62931',
    '62932',
    '62933',
    '62934',
    '62935',
    '62938',
    '62939',
    '62940',
    '62941',
    '62942',
    '62943',
    '62946',
    '62947',
    '62948',
    '62949',
    '62950',
    '62951',
    '62952',
    '62953',
    '62954',
    '62956',
    '62957',
    '62958',
    '62959',
    '62960',
    '62961',
    '62962',
    '62963',
    '62964',
    '62965',
    '62966',
    '62967',
    '62969',
    '62970',
    '62972',
    '62973',
    '62974',
    '62975',
    '62976',
    '62977',
    '62979',
    '62982',
    '62983',
    '62984',
    '62985',
    '62987',
    '62988',
    '62990',
    '62992',
    '62993',
    '62994',
    '62995',
    '62996',
    '62997',
    '62998',
    '62999',
  ],
  MO: [
    '63005',
    '63006',
    '63010',
    '63011',
    '63012',
    '63013',
    '63014',
    '63015',
    '63016',
    '63017',
    '63019',
    '63020',
    '63021',
    '63022',
    '63023',
    '63024',
    '63025',
    '63026',
    '63028',
    '63030',
    '63031',
    '63032',
    '63033',
    '63034',
    '63036',
    '63037',
    '63038',
    '63039',
    '63040',
    '63041',
    '63042',
    '63043',
    '63044',
    '63045',
    '63047',
    '63048',
    '63049',
    '63050',
    '63051',
    '63052',
    '63053',
    '63055',
    '63056',
    '63057',
    '63060',
    '63061',
    '63065',
    '63066',
    '63068',
    '63069',
    '63070',
    '63071',
    '63072',
    '63073',
    '63074',
    '63077',
    '63079',
    '63080',
    '63084',
    '63087',
    '63088',
    '63089',
    '63090',
    '63091',
    '63099',
    '63101',
    '63102',
    '63103',
    '63104',
    '63105',
    '63106',
    '63107',
    '63108',
    '63109',
    '63110',
    '63111',
    '63112',
    '63113',
    '63114',
    '63115',
    '63116',
    '63117',
    '63118',
    '63119',
    '63120',
    '63121',
    '63122',
    '63123',
    '63124',
    '63125',
    '63126',
    '63127',
    '63128',
    '63129',
    '63130',
    '63131',
    '63132',
    '63133',
    '63134',
    '63135',
    '63136',
    '63137',
    '63138',
    '63139',
    '63140',
    '63141',
    '63143',
    '63144',
    '63145',
    '63146',
    '63147',
    '63150',
    '63151',
    '63155',
    '63156',
    '63157',
    '63158',
    '63160',
    '63163',
    '63164',
    '63166',
    '63167',
    '63169',
    '63171',
    '63177',
    '63178',
    '63179',
    '63180',
    '63188',
    '63195',
    '63197',
    '63199',
    '63301',
    '63302',
    '63303',
    '63304',
    '63330',
    '63332',
    '63333',
    '63334',
    '63336',
    '63338',
    '63339',
    '63341',
    '63342',
    '63343',
    '63344',
    '63345',
    '63346',
    '63347',
    '63348',
    '63349',
    '63350',
    '63351',
    '63352',
    '63353',
    '63357',
    '63359',
    '63361',
    '63362',
    '63363',
    '63365',
    '63366',
    '63367',
    '63368',
    '63369',
    '63370',
    '63373',
    '63376',
    '63377',
    '63378',
    '63379',
    '63380',
    '63381',
    '63382',
    '63383',
    '63384',
    '63385',
    '63386',
    '63387',
    '63388',
    '63389',
    '63390',
    '63401',
    '63430',
    '63431',
    '63432',
    '63433',
    '63434',
    '63435',
    '63436',
    '63437',
    '63438',
    '63439',
    '63440',
    '63441',
    '63442',
    '63443',
    '63445',
    '63446',
    '63447',
    '63448',
    '63450',
    '63451',
    '63452',
    '63453',
    '63454',
    '63456',
    '63457',
    '63458',
    '63459',
    '63460',
    '63461',
    '63462',
    '63463',
    '63465',
    '63466',
    '63467',
    '63468',
    '63469',
    '63471',
    '63472',
    '63473',
    '63474',
    '63501',
    '63530',
    '63531',
    '63532',
    '63533',
    '63534',
    '63535',
    '63536',
    '63537',
    '63538',
    '63539',
    '63540',
    '63541',
    '63543',
    '63544',
    '63545',
    '63546',
    '63547',
    '63548',
    '63549',
    '63551',
    '63552',
    '63555',
    '63556',
    '63557',
    '63558',
    '63559',
    '63560',
    '63561',
    '63563',
    '63565',
    '63566',
    '63567',
    '63601',
    '63620',
    '63621',
    '63622',
    '63623',
    '63624',
    '63625',
    '63626',
    '63627',
    '63628',
    '63629',
    '63630',
    '63631',
    '63632',
    '63633',
    '63636',
    '63637',
    '63638',
    '63640',
    '63645',
    '63648',
    '63650',
    '63651',
    '63653',
    '63654',
    '63655',
    '63656',
    '63660',
    '63662',
    '63663',
    '63664',
    '63665',
    '63666',
    '63670',
    '63673',
    '63674',
    '63675',
    '63701',
    '63702',
    '63703',
    '63730',
    '63732',
    '63735',
    '63736',
    '63737',
    '63738',
    '63739',
    '63740',
    '63742',
    '63743',
    '63744',
    '63745',
    '63746',
    '63747',
    '63748',
    '63750',
    '63751',
    '63752',
    '63755',
    '63758',
    '63760',
    '63763',
    '63764',
    '63766',
    '63767',
    '63769',
    '63770',
    '63771',
    '63774',
    '63775',
    '63776',
    '63779',
    '63780',
    '63781',
    '63782',
    '63783',
    '63784',
    '63785',
    '63787',
    '63801',
    '63820',
    '63821',
    '63822',
    '63823',
    '63824',
    '63825',
    '63826',
    '63827',
    '63828',
    '63829',
    '63830',
    '63833',
    '63834',
    '63837',
    '63839',
    '63840',
    '63841',
    '63845',
    '63846',
    '63847',
    '63848',
    '63849',
    '63850',
    '63851',
    '63852',
    '63853',
    '63855',
    '63857',
    '63860',
    '63862',
    '63863',
    '63866',
    '63867',
    '63868',
    '63869',
    '63870',
    '63873',
    '63874',
    '63875',
    '63876',
    '63877',
    '63878',
    '63879',
    '63880',
    '63881',
    '63882',
    '63901',
    '63902',
    '63931',
    '63932',
    '63933',
    '63934',
    '63935',
    '63936',
    '63937',
    '63939',
    '63940',
    '63941',
    '63942',
    '63943',
    '63944',
    '63945',
    '63950',
    '63951',
    '63952',
    '63953',
    '63954',
    '63955',
    '63956',
    '63957',
    '63960',
    '63961',
    '63962',
    '63964',
    '63965',
    '63966',
    '63967',
    '64001',
    '64002',
    '64011',
    '64012',
    '64013',
    '64014',
    '64015',
    '64016',
    '64017',
    '64018',
    '64019',
    '64020',
    '64021',
    '64022',
    '64024',
    '64028',
    '64029',
    '64030',
    '64034',
    '64035',
    '64036',
    '64037',
    '64040',
    '64048',
    '64050',
    '64051',
    '64052',
    '64053',
    '64054',
    '64055',
    '64056',
    '64057',
    '64058',
    '64060',
    '64061',
    '64062',
    '64063',
    '64064',
    '64065',
    '64066',
    '64067',
    '64068',
    '64069',
    '64070',
    '64071',
    '64072',
    '64073',
    '64074',
    '64075',
    '64076',
    '64077',
    '64078',
    '64079',
    '64080',
    '64081',
    '64082',
    '64083',
    '64084',
    '64085',
    '64086',
    '64088',
    '64089',
    '64090',
    '64092',
    '64093',
    '64096',
    '64097',
    '64098',
    '64101',
    '64102',
    '64105',
    '64106',
    '64108',
    '64109',
    '64110',
    '64111',
    '64112',
    '64113',
    '64114',
    '64116',
    '64117',
    '64118',
    '64119',
    '64120',
    '64121',
    '64123',
    '64124',
    '64125',
    '64126',
    '64127',
    '64128',
    '64129',
    '64130',
    '64131',
    '64132',
    '64133',
    '64134',
    '64136',
    '64137',
    '64138',
    '64139',
    '64141',
    '64144',
    '64145',
    '64146',
    '64147',
    '64148',
    '64149',
    '64150',
    '64151',
    '64152',
    '64153',
    '64154',
    '64155',
    '64156',
    '64157',
    '64158',
    '64161',
    '64162',
    '64163',
    '64164',
    '64165',
    '64166',
    '64167',
    '64168',
    '64170',
    '64171',
    '64179',
    '64180',
    '64184',
    '64187',
    '64188',
    '64190',
    '64191',
    '64195',
    '64196',
    '64197',
    '64198',
    '64199',
    '64401',
    '64402',
    '64420',
    '64421',
    '64422',
    '64423',
    '64424',
    '64426',
    '64427',
    '64428',
    '64429',
    '64430',
    '64431',
    '64432',
    '64433',
    '64434',
    '64436',
    '64437',
    '64438',
    '64439',
    '64440',
    '64441',
    '64442',
    '64443',
    '64444',
    '64445',
    '64446',
    '64448',
    '64449',
    '64451',
    '64453',
    '64454',
    '64455',
    '64456',
    '64457',
    '64458',
    '64459',
    '64461',
    '64463',
    '64465',
    '64466',
    '64467',
    '64468',
    '64469',
    '64470',
    '64471',
    '64473',
    '64474',
    '64475',
    '64476',
    '64477',
    '64479',
    '64480',
    '64481',
    '64482',
    '64483',
    '64484',
    '64485',
    '64486',
    '64487',
    '64489',
    '64490',
    '64491',
    '64492',
    '64493',
    '64494',
    '64496',
    '64497',
    '64498',
    '64499',
    '64501',
    '64502',
    '64503',
    '64504',
    '64505',
    '64506',
    '64507',
    '64508',
    '64601',
    '64620',
    '64622',
    '64623',
    '64624',
    '64625',
    '64628',
    '64630',
    '64631',
    '64632',
    '64633',
    '64635',
    '64636',
    '64637',
    '64638',
    '64639',
    '64640',
    '64641',
    '64642',
    '64643',
    '64644',
    '64645',
    '64646',
    '64647',
    '64648',
    '64649',
    '64650',
    '64651',
    '64652',
    '64653',
    '64654',
    '64655',
    '64656',
    '64657',
    '64658',
    '64659',
    '64660',
    '64661',
    '64664',
    '64667',
    '64668',
    '64670',
    '64671',
    '64672',
    '64673',
    '64674',
    '64676',
    '64679',
    '64680',
    '64681',
    '64682',
    '64683',
    '64686',
    '64688',
    '64689',
    '64701',
    '64720',
    '64722',
    '64723',
    '64724',
    '64725',
    '64726',
    '64728',
    '64730',
    '64733',
    '64734',
    '64735',
    '64738',
    '64739',
    '64740',
    '64741',
    '64742',
    '64743',
    '64744',
    '64745',
    '64746',
    '64747',
    '64748',
    '64750',
    '64752',
    '64755',
    '64756',
    '64759',
    '64761',
    '64762',
    '64763',
    '64765',
    '64766',
    '64767',
    '64769',
    '64770',
    '64771',
    '64772',
    '64776',
    '64778',
    '64779',
    '64780',
    '64781',
    '64783',
    '64784',
    '64788',
    '64790',
    '64801',
    '64802',
    '64803',
    '64804',
    '64830',
    '64831',
    '64832',
    '64833',
    '64834',
    '64835',
    '64836',
    '64840',
    '64841',
    '64842',
    '64843',
    '64844',
    '64847',
    '64848',
    '64849',
    '64850',
    '64853',
    '64854',
    '64855',
    '64856',
    '64857',
    '64858',
    '64859',
    '64861',
    '64862',
    '64863',
    '64864',
    '64865',
    '64866',
    '64867',
    '64868',
    '64870',
    '64873',
    '64874',
    '64999',
    '65001',
    '65010',
    '65011',
    '65013',
    '65014',
    '65016',
    '65017',
    '65018',
    '65020',
    '65023',
    '65024',
    '65025',
    '65026',
    '65032',
    '65034',
    '65035',
    '65036',
    '65037',
    '65038',
    '65039',
    '65040',
    '65041',
    '65042',
    '65043',
    '65046',
    '65047',
    '65048',
    '65049',
    '65050',
    '65051',
    '65052',
    '65053',
    '65054',
    '65055',
    '65058',
    '65059',
    '65061',
    '65062',
    '65063',
    '65064',
    '65065',
    '65066',
    '65067',
    '65068',
    '65069',
    '65072',
    '65074',
    '65075',
    '65076',
    '65077',
    '65078',
    '65079',
    '65080',
    '65081',
    '65082',
    '65083',
    '65084',
    '65085',
    '65101',
    '65102',
    '65103',
    '65104',
    '65105',
    '65106',
    '65107',
    '65108',
    '65109',
    '65110',
    '65111',
    '65201',
    '65202',
    '65203',
    '65205',
    '65211',
    '65212',
    '65215',
    '65216',
    '65217',
    '65218',
    '65230',
    '65231',
    '65232',
    '65233',
    '65236',
    '65237',
    '65239',
    '65240',
    '65243',
    '65244',
    '65246',
    '65247',
    '65248',
    '65250',
    '65251',
    '65254',
    '65255',
    '65256',
    '65257',
    '65258',
    '65259',
    '65260',
    '65261',
    '65262',
    '65263',
    '65264',
    '65265',
    '65270',
    '65274',
    '65275',
    '65276',
    '65278',
    '65279',
    '65280',
    '65281',
    '65282',
    '65283',
    '65284',
    '65285',
    '65286',
    '65287',
    '65299',
    '65301',
    '65302',
    '65305',
    '65320',
    '65321',
    '65322',
    '65323',
    '65324',
    '65325',
    '65326',
    '65327',
    '65329',
    '65330',
    '65332',
    '65333',
    '65334',
    '65335',
    '65336',
    '65337',
    '65338',
    '65339',
    '65340',
    '65344',
    '65345',
    '65347',
    '65348',
    '65349',
    '65350',
    '65351',
    '65354',
    '65355',
    '65360',
    '65401',
    '65402',
    '65409',
    '65436',
    '65438',
    '65439',
    '65440',
    '65441',
    '65443',
    '65444',
    '65446',
    '65449',
    '65452',
    '65453',
    '65456',
    '65457',
    '65459',
    '65461',
    '65462',
    '65463',
    '65464',
    '65466',
    '65468',
    '65470',
    '65473',
    '65479',
    '65483',
    '65484',
    '65486',
    '65501',
    '65529',
    '65532',
    '65534',
    '65535',
    '65536',
    '65541',
    '65542',
    '65543',
    '65546',
    '65548',
    '65550',
    '65552',
    '65555',
    '65556',
    '65557',
    '65559',
    '65560',
    '65564',
    '65565',
    '65566',
    '65567',
    '65570',
    '65571',
    '65580',
    '65582',
    '65583',
    '65584',
    '65586',
    '65588',
    '65589',
    '65590',
    '65591',
    '65601',
    '65603',
    '65604',
    '65605',
    '65606',
    '65607',
    '65608',
    '65609',
    '65610',
    '65611',
    '65612',
    '65613',
    '65614',
    '65615',
    '65616',
    '65617',
    '65618',
    '65619',
    '65620',
    '65622',
    '65623',
    '65624',
    '65625',
    '65626',
    '65627',
    '65629',
    '65630',
    '65631',
    '65632',
    '65633',
    '65634',
    '65635',
    '65636',
    '65637',
    '65638',
    '65640',
    '65641',
    '65644',
    '65645',
    '65646',
    '65647',
    '65648',
    '65649',
    '65650',
    '65652',
    '65653',
    '65654',
    '65655',
    '65656',
    '65657',
    '65658',
    '65660',
    '65661',
    '65662',
    '65663',
    '65664',
    '65666',
    '65667',
    '65668',
    '65669',
    '65672',
    '65673',
    '65674',
    '65675',
    '65676',
    '65679',
    '65680',
    '65681',
    '65682',
    '65685',
    '65686',
    '65688',
    '65689',
    '65690',
    '65692',
    '65702',
    '65704',
    '65705',
    '65706',
    '65707',
    '65708',
    '65710',
    '65711',
    '65712',
    '65713',
    '65714',
    '65715',
    '65717',
    '65720',
    '65721',
    '65722',
    '65723',
    '65724',
    '65725',
    '65726',
    '65727',
    '65728',
    '65729',
    '65730',
    '65731',
    '65732',
    '65733',
    '65734',
    '65735',
    '65737',
    '65738',
    '65739',
    '65740',
    '65741',
    '65742',
    '65744',
    '65745',
    '65746',
    '65747',
    '65752',
    '65753',
    '65754',
    '65755',
    '65756',
    '65757',
    '65759',
    '65760',
    '65761',
    '65762',
    '65764',
    '65765',
    '65766',
    '65767',
    '65768',
    '65769',
    '65770',
    '65771',
    '65772',
    '65773',
    '65774',
    '65775',
    '65777',
    '65778',
    '65779',
    '65781',
    '65783',
    '65784',
    '65785',
    '65786',
    '65787',
    '65788',
    '65789',
    '65790',
    '65791',
    '65793',
    '65801',
    '65802',
    '65803',
    '65804',
    '65805',
    '65806',
    '65807',
    '65808',
    '65809',
    '65810',
    '65814',
    '65817',
    '65890',
    '65897',
    '65898',
    '65899',
    '72643',
  ],
  KS: [
    '66002',
    '66006',
    '66007',
    '66008',
    '66010',
    '66012',
    '66013',
    '66014',
    '66015',
    '66016',
    '66017',
    '66018',
    '66020',
    '66021',
    '66023',
    '66024',
    '66025',
    '66026',
    '66027',
    '66030',
    '66031',
    '66032',
    '66033',
    '66035',
    '66036',
    '66039',
    '66040',
    '66041',
    '66042',
    '66043',
    '66044',
    '66045',
    '66046',
    '66047',
    '66048',
    '66049',
    '66050',
    '66051',
    '66052',
    '66053',
    '66054',
    '66056',
    '66058',
    '66060',
    '66061',
    '66062',
    '66063',
    '66064',
    '66066',
    '66067',
    '66070',
    '66071',
    '66072',
    '66073',
    '66075',
    '66076',
    '66078',
    '66079',
    '66080',
    '66083',
    '66085',
    '66086',
    '66087',
    '66088',
    '66090',
    '66091',
    '66092',
    '66093',
    '66094',
    '66095',
    '66097',
    '66101',
    '66102',
    '66103',
    '66104',
    '66105',
    '66106',
    '66109',
    '66110',
    '66111',
    '66112',
    '66113',
    '66115',
    '66117',
    '66118',
    '66119',
    '66160',
    '66201',
    '66202',
    '66203',
    '66204',
    '66205',
    '66206',
    '66207',
    '66208',
    '66209',
    '66210',
    '66211',
    '66212',
    '66213',
    '66214',
    '66215',
    '66216',
    '66217',
    '66218',
    '66219',
    '66220',
    '66221',
    '66222',
    '66223',
    '66224',
    '66225',
    '66226',
    '66227',
    '66250',
    '66251',
    '66276',
    '66282',
    '66283',
    '66285',
    '66286',
    '66401',
    '66402',
    '66403',
    '66404',
    '66406',
    '66407',
    '66408',
    '66409',
    '66411',
    '66412',
    '66413',
    '66414',
    '66415',
    '66416',
    '66417',
    '66418',
    '66419',
    '66420',
    '66422',
    '66423',
    '66424',
    '66425',
    '66426',
    '66427',
    '66428',
    '66429',
    '66431',
    '66432',
    '66434',
    '66436',
    '66438',
    '66439',
    '66440',
    '66441',
    '66442',
    '66449',
    '66451',
    '66501',
    '66502',
    '66503',
    '66505',
    '66506',
    '66507',
    '66508',
    '66509',
    '66510',
    '66512',
    '66514',
    '66515',
    '66516',
    '66517',
    '66518',
    '66520',
    '66521',
    '66522',
    '66523',
    '66524',
    '66526',
    '66527',
    '66528',
    '66531',
    '66532',
    '66533',
    '66534',
    '66535',
    '66536',
    '66537',
    '66538',
    '66539',
    '66540',
    '66541',
    '66542',
    '66543',
    '66544',
    '66546',
    '66547',
    '66548',
    '66549',
    '66550',
    '66552',
    '66554',
    '66601',
    '66603',
    '66604',
    '66605',
    '66606',
    '66607',
    '66608',
    '66609',
    '66610',
    '66611',
    '66612',
    '66614',
    '66615',
    '66616',
    '66617',
    '66618',
    '66619',
    '66620',
    '66621',
    '66622',
    '66624',
    '66625',
    '66626',
    '66629',
    '66630',
    '66636',
    '66647',
    '66667',
    '66675',
    '66683',
    '66699',
    '66701',
    '66710',
    '66711',
    '66712',
    '66713',
    '66714',
    '66716',
    '66717',
    '66720',
    '66724',
    '66725',
    '66728',
    '66732',
    '66733',
    '66734',
    '66735',
    '66736',
    '66738',
    '66739',
    '66740',
    '66741',
    '66742',
    '66743',
    '66746',
    '66748',
    '66749',
    '66751',
    '66753',
    '66754',
    '66755',
    '66756',
    '66757',
    '66758',
    '66760',
    '66761',
    '66762',
    '66763',
    '66767',
    '66769',
    '66770',
    '66771',
    '66772',
    '66773',
    '66775',
    '66776',
    '66777',
    '66778',
    '66779',
    '66780',
    '66781',
    '66782',
    '66783',
    '66801',
    '66830',
    '66833',
    '66834',
    '66835',
    '66838',
    '66839',
    '66840',
    '66842',
    '66843',
    '66845',
    '66846',
    '66849',
    '66850',
    '66851',
    '66852',
    '66853',
    '66854',
    '66855',
    '66856',
    '66857',
    '66858',
    '66859',
    '66860',
    '66861',
    '66862',
    '66863',
    '66864',
    '66865',
    '66866',
    '66868',
    '66869',
    '66870',
    '66871',
    '66872',
    '66873',
    '66901',
    '66930',
    '66932',
    '66933',
    '66935',
    '66936',
    '66937',
    '66938',
    '66939',
    '66940',
    '66941',
    '66942',
    '66943',
    '66944',
    '66945',
    '66946',
    '66948',
    '66949',
    '66951',
    '66952',
    '66953',
    '66955',
    '66956',
    '66958',
    '66959',
    '66960',
    '66961',
    '66962',
    '66963',
    '66964',
    '66966',
    '66967',
    '66968',
    '66970',
    '67001',
    '67002',
    '67003',
    '67004',
    '67005',
    '67008',
    '67009',
    '67010',
    '67012',
    '67013',
    '67016',
    '67017',
    '67018',
    '67019',
    '67020',
    '67021',
    '67022',
    '67023',
    '67024',
    '67025',
    '67026',
    '67028',
    '67029',
    '67030',
    '67031',
    '67035',
    '67036',
    '67037',
    '67038',
    '67039',
    '67041',
    '67042',
    '67045',
    '67047',
    '67049',
    '67050',
    '67051',
    '67052',
    '67053',
    '67054',
    '67055',
    '67056',
    '67057',
    '67058',
    '67059',
    '67060',
    '67061',
    '67062',
    '67063',
    '67065',
    '67066',
    '67067',
    '67068',
    '67070',
    '67071',
    '67072',
    '67073',
    '67074',
    '67101',
    '67102',
    '67103',
    '67104',
    '67105',
    '67106',
    '67107',
    '67108',
    '67109',
    '67110',
    '67111',
    '67112',
    '67114',
    '67117',
    '67118',
    '67119',
    '67120',
    '67122',
    '67123',
    '67124',
    '67127',
    '67131',
    '67132',
    '67133',
    '67134',
    '67135',
    '67137',
    '67138',
    '67140',
    '67142',
    '67143',
    '67144',
    '67146',
    '67147',
    '67149',
    '67150',
    '67151',
    '67152',
    '67154',
    '67155',
    '67156',
    '67159',
    '67201',
    '67202',
    '67203',
    '67204',
    '67205',
    '67206',
    '67207',
    '67208',
    '67209',
    '67210',
    '67211',
    '67212',
    '67213',
    '67214',
    '67215',
    '67216',
    '67217',
    '67218',
    '67219',
    '67220',
    '67221',
    '67223',
    '67226',
    '67227',
    '67228',
    '67230',
    '67232',
    '67235',
    '67260',
    '67275',
    '67276',
    '67277',
    '67278',
    '67301',
    '67330',
    '67332',
    '67333',
    '67334',
    '67335',
    '67336',
    '67337',
    '67340',
    '67341',
    '67342',
    '67344',
    '67345',
    '67346',
    '67347',
    '67349',
    '67351',
    '67352',
    '67353',
    '67354',
    '67355',
    '67356',
    '67357',
    '67360',
    '67361',
    '67363',
    '67364',
    '67401',
    '67402',
    '67410',
    '67416',
    '67417',
    '67418',
    '67420',
    '67422',
    '67423',
    '67425',
    '67427',
    '67428',
    '67430',
    '67431',
    '67432',
    '67436',
    '67437',
    '67438',
    '67439',
    '67441',
    '67442',
    '67443',
    '67444',
    '67445',
    '67446',
    '67447',
    '67448',
    '67449',
    '67450',
    '67451',
    '67452',
    '67454',
    '67455',
    '67456',
    '67457',
    '67458',
    '67459',
    '67460',
    '67464',
    '67466',
    '67467',
    '67468',
    '67470',
    '67473',
    '67474',
    '67475',
    '67476',
    '67478',
    '67480',
    '67481',
    '67482',
    '67483',
    '67484',
    '67485',
    '67487',
    '67490',
    '67491',
    '67492',
    '67501',
    '67502',
    '67504',
    '67505',
    '67510',
    '67511',
    '67512',
    '67513',
    '67514',
    '67515',
    '67516',
    '67518',
    '67519',
    '67520',
    '67521',
    '67522',
    '67523',
    '67524',
    '67525',
    '67526',
    '67529',
    '67530',
    '67543',
    '67544',
    '67545',
    '67546',
    '67547',
    '67548',
    '67550',
    '67552',
    '67553',
    '67554',
    '67556',
    '67557',
    '67559',
    '67560',
    '67561',
    '67563',
    '67564',
    '67565',
    '67566',
    '67567',
    '67568',
    '67570',
    '67572',
    '67573',
    '67574',
    '67575',
    '67576',
    '67578',
    '67579',
    '67581',
    '67583',
    '67584',
    '67585',
    '67601',
    '67621',
    '67622',
    '67623',
    '67625',
    '67626',
    '67627',
    '67628',
    '67629',
    '67631',
    '67632',
    '67634',
    '67635',
    '67637',
    '67638',
    '67639',
    '67640',
    '67642',
    '67643',
    '67644',
    '67645',
    '67646',
    '67647',
    '67648',
    '67649',
    '67650',
    '67651',
    '67653',
    '67654',
    '67656',
    '67657',
    '67658',
    '67659',
    '67660',
    '67661',
    '67663',
    '67664',
    '67665',
    '67667',
    '67669',
    '67671',
    '67672',
    '67673',
    '67674',
    '67675',
    '67701',
    '67730',
    '67731',
    '67732',
    '67733',
    '67734',
    '67735',
    '67736',
    '67737',
    '67738',
    '67739',
    '67740',
    '67741',
    '67743',
    '67744',
    '67745',
    '67747',
    '67748',
    '67749',
    '67751',
    '67752',
    '67753',
    '67756',
    '67757',
    '67758',
    '67761',
    '67762',
    '67764',
    '67801',
    '67831',
    '67834',
    '67835',
    '67836',
    '67837',
    '67838',
    '67839',
    '67840',
    '67841',
    '67842',
    '67843',
    '67844',
    '67846',
    '67849',
    '67850',
    '67851',
    '67853',
    '67854',
    '67855',
    '67857',
    '67859',
    '67860',
    '67861',
    '67862',
    '67863',
    '67864',
    '67865',
    '67867',
    '67868',
    '67869',
    '67870',
    '67871',
    '67876',
    '67877',
    '67878',
    '67879',
    '67880',
    '67882',
    '67901',
    '67905',
    '67950',
    '67951',
    '67952',
    '67953',
    '67954',
  ],
  NE: [
    '68001',
    '68002',
    '68003',
    '68004',
    '68005',
    '68007',
    '68008',
    '68010',
    '68014',
    '68015',
    '68016',
    '68017',
    '68018',
    '68019',
    '68020',
    '68022',
    '68023',
    '68025',
    '68026',
    '68028',
    '68029',
    '68030',
    '68031',
    '68033',
    '68034',
    '68036',
    '68037',
    '68038',
    '68039',
    '68040',
    '68041',
    '68042',
    '68044',
    '68045',
    '68046',
    '68047',
    '68048',
    '68050',
    '68055',
    '68056',
    '68057',
    '68058',
    '68059',
    '68061',
    '68062',
    '68063',
    '68064',
    '68065',
    '68066',
    '68067',
    '68068',
    '68069',
    '68070',
    '68071',
    '68072',
    '68073',
    '68101',
    '68102',
    '68103',
    '68104',
    '68105',
    '68106',
    '68107',
    '68108',
    '68109',
    '68110',
    '68111',
    '68112',
    '68113',
    '68114',
    '68116',
    '68117',
    '68118',
    '68119',
    '68122',
    '68123',
    '68124',
    '68127',
    '68128',
    '68130',
    '68131',
    '68132',
    '68133',
    '68134',
    '68135',
    '68136',
    '68137',
    '68138',
    '68139',
    '68142',
    '68144',
    '68145',
    '68147',
    '68152',
    '68154',
    '68157',
    '68164',
    '68172',
    '68175',
    '68176',
    '68178',
    '68179',
    '68180',
    '68182',
    '68183',
    '68197',
    '68198',
    '68301',
    '68303',
    '68304',
    '68305',
    '68307',
    '68309',
    '68310',
    '68313',
    '68314',
    '68315',
    '68316',
    '68317',
    '68318',
    '68319',
    '68320',
    '68321',
    '68322',
    '68323',
    '68324',
    '68325',
    '68326',
    '68327',
    '68328',
    '68329',
    '68330',
    '68331',
    '68332',
    '68333',
    '68335',
    '68336',
    '68337',
    '68338',
    '68339',
    '68340',
    '68341',
    '68342',
    '68343',
    '68344',
    '68345',
    '68346',
    '68347',
    '68348',
    '68349',
    '68350',
    '68351',
    '68352',
    '68354',
    '68355',
    '68357',
    '68358',
    '68359',
    '68360',
    '68361',
    '68362',
    '68364',
    '68365',
    '68366',
    '68367',
    '68368',
    '68370',
    '68371',
    '68372',
    '68375',
    '68376',
    '68377',
    '68378',
    '68379',
    '68380',
    '68381',
    '68382',
    '68401',
    '68402',
    '68403',
    '68404',
    '68405',
    '68406',
    '68407',
    '68409',
    '68410',
    '68413',
    '68414',
    '68415',
    '68416',
    '68417',
    '68418',
    '68419',
    '68420',
    '68421',
    '68422',
    '68423',
    '68424',
    '68428',
    '68429',
    '68430',
    '68431',
    '68433',
    '68434',
    '68436',
    '68437',
    '68438',
    '68439',
    '68440',
    '68441',
    '68442',
    '68443',
    '68444',
    '68445',
    '68446',
    '68447',
    '68448',
    '68450',
    '68452',
    '68453',
    '68454',
    '68455',
    '68456',
    '68457',
    '68458',
    '68460',
    '68461',
    '68462',
    '68463',
    '68464',
    '68465',
    '68466',
    '68467',
    '68501',
    '68502',
    '68503',
    '68504',
    '68505',
    '68506',
    '68507',
    '68508',
    '68509',
    '68510',
    '68512',
    '68514',
    '68516',
    '68517',
    '68520',
    '68521',
    '68522',
    '68523',
    '68524',
    '68526',
    '68527',
    '68528',
    '68529',
    '68531',
    '68532',
    '68542',
    '68544',
    '68583',
    '68588',
    '68601',
    '68602',
    '68620',
    '68621',
    '68622',
    '68623',
    '68624',
    '68626',
    '68627',
    '68628',
    '68629',
    '68631',
    '68632',
    '68633',
    '68634',
    '68635',
    '68636',
    '68637',
    '68638',
    '68640',
    '68641',
    '68642',
    '68643',
    '68644',
    '68647',
    '68648',
    '68649',
    '68651',
    '68652',
    '68653',
    '68654',
    '68655',
    '68658',
    '68659',
    '68660',
    '68661',
    '68662',
    '68663',
    '68664',
    '68665',
    '68666',
    '68667',
    '68669',
    '68701',
    '68702',
    '68710',
    '68711',
    '68713',
    '68714',
    '68715',
    '68716',
    '68717',
    '68718',
    '68719',
    '68720',
    '68722',
    '68723',
    '68724',
    '68725',
    '68726',
    '68727',
    '68728',
    '68729',
    '68730',
    '68731',
    '68732',
    '68733',
    '68734',
    '68735',
    '68736',
    '68738',
    '68739',
    '68740',
    '68741',
    '68742',
    '68743',
    '68745',
    '68746',
    '68747',
    '68748',
    '68749',
    '68751',
    '68752',
    '68753',
    '68755',
    '68756',
    '68757',
    '68758',
    '68759',
    '68760',
    '68761',
    '68763',
    '68764',
    '68765',
    '68766',
    '68767',
    '68768',
    '68769',
    '68770',
    '68771',
    '68773',
    '68774',
    '68776',
    '68777',
    '68778',
    '68779',
    '68780',
    '68781',
    '68783',
    '68784',
    '68785',
    '68786',
    '68787',
    '68788',
    '68789',
    '68790',
    '68791',
    '68792',
    '68801',
    '68802',
    '68803',
    '68810',
    '68812',
    '68813',
    '68814',
    '68815',
    '68816',
    '68817',
    '68818',
    '68820',
    '68821',
    '68822',
    '68823',
    '68824',
    '68825',
    '68826',
    '68827',
    '68828',
    '68831',
    '68832',
    '68833',
    '68834',
    '68835',
    '68836',
    '68837',
    '68838',
    '68840',
    '68841',
    '68842',
    '68843',
    '68844',
    '68845',
    '68846',
    '68847',
    '68848',
    '68849',
    '68850',
    '68852',
    '68853',
    '68854',
    '68855',
    '68856',
    '68858',
    '68859',
    '68860',
    '68861',
    '68862',
    '68863',
    '68864',
    '68865',
    '68866',
    '68869',
    '68870',
    '68871',
    '68872',
    '68873',
    '68874',
    '68875',
    '68876',
    '68878',
    '68879',
    '68881',
    '68882',
    '68883',
    '68901',
    '68902',
    '68920',
    '68922',
    '68923',
    '68924',
    '68925',
    '68926',
    '68927',
    '68928',
    '68929',
    '68930',
    '68932',
    '68933',
    '68934',
    '68935',
    '68936',
    '68937',
    '68938',
    '68939',
    '68940',
    '68941',
    '68942',
    '68943',
    '68944',
    '68945',
    '68946',
    '68947',
    '68948',
    '68949',
    '68950',
    '68952',
    '68954',
    '68955',
    '68956',
    '68957',
    '68958',
    '68959',
    '68960',
    '68961',
    '68964',
    '68966',
    '68967',
    '68969',
    '68970',
    '68971',
    '68972',
    '68973',
    '68974',
    '68975',
    '68976',
    '68977',
    '68978',
    '68979',
    '68980',
    '68981',
    '68982',
    '69001',
    '69020',
    '69021',
    '69022',
    '69023',
    '69024',
    '69025',
    '69026',
    '69027',
    '69028',
    '69029',
    '69030',
    '69032',
    '69033',
    '69034',
    '69036',
    '69037',
    '69038',
    '69039',
    '69040',
    '69041',
    '69042',
    '69043',
    '69044',
    '69045',
    '69046',
    '69101',
    '69103',
    '69120',
    '69121',
    '69122',
    '69123',
    '69125',
    '69127',
    '69128',
    '69129',
    '69130',
    '69131',
    '69132',
    '69133',
    '69134',
    '69135',
    '69138',
    '69140',
    '69141',
    '69142',
    '69143',
    '69144',
    '69145',
    '69146',
    '69147',
    '69148',
    '69149',
    '69150',
    '69151',
    '69152',
    '69153',
    '69154',
    '69155',
    '69156',
    '69157',
    '69160',
    '69161',
    '69162',
    '69163',
    '69165',
    '69166',
    '69167',
    '69168',
    '69169',
    '69170',
    '69171',
    '69190',
    '69201',
    '69210',
    '69211',
    '69212',
    '69214',
    '69216',
    '69217',
    '69218',
    '69219',
    '69220',
    '69221',
    '69301',
    '69331',
    '69333',
    '69334',
    '69335',
    '69336',
    '69337',
    '69339',
    '69340',
    '69341',
    '69343',
    '69345',
    '69346',
    '69347',
    '69348',
    '69350',
    '69351',
    '69352',
    '69353',
    '69354',
    '69355',
    '69356',
    '69357',
    '69358',
    '69360',
    '69361',
    '69363',
    '69365',
    '69366',
    '69367',
  ],
  LA: [
    '70001',
    '70002',
    '70003',
    '70004',
    '70005',
    '70006',
    '70009',
    '70010',
    '70011',
    '70030',
    '70031',
    '70032',
    '70033',
    '70036',
    '70037',
    '70038',
    '70039',
    '70040',
    '70041',
    '70043',
    '70044',
    '70047',
    '70049',
    '70050',
    '70051',
    '70052',
    '70053',
    '70054',
    '70055',
    '70056',
    '70057',
    '70058',
    '70059',
    '70060',
    '70062',
    '70063',
    '70064',
    '70065',
    '70067',
    '70068',
    '70069',
    '70070',
    '70071',
    '70072',
    '70073',
    '70075',
    '70076',
    '70078',
    '70079',
    '70080',
    '70081',
    '70082',
    '70083',
    '70084',
    '70085',
    '70086',
    '70087',
    '70090',
    '70091',
    '70092',
    '70093',
    '70094',
    '70096',
    '70097',
    '70112',
    '70113',
    '70114',
    '70115',
    '70116',
    '70117',
    '70118',
    '70119',
    '70121',
    '70122',
    '70123',
    '70124',
    '70125',
    '70126',
    '70127',
    '70128',
    '70129',
    '70130',
    '70131',
    '70139',
    '70141',
    '70142',
    '70143',
    '70145',
    '70146',
    '70148',
    '70150',
    '70151',
    '70152',
    '70153',
    '70154',
    '70156',
    '70157',
    '70158',
    '70159',
    '70160',
    '70161',
    '70162',
    '70163',
    '70164',
    '70165',
    '70166',
    '70167',
    '70170',
    '70172',
    '70174',
    '70175',
    '70176',
    '70177',
    '70178',
    '70179',
    '70181',
    '70182',
    '70183',
    '70184',
    '70185',
    '70186',
    '70187',
    '70189',
    '70190',
    '70195',
    '70301',
    '70302',
    '70310',
    '70339',
    '70340',
    '70341',
    '70342',
    '70343',
    '70344',
    '70345',
    '70346',
    '70352',
    '70353',
    '70354',
    '70355',
    '70356',
    '70357',
    '70358',
    '70359',
    '70360',
    '70361',
    '70363',
    '70364',
    '70371',
    '70372',
    '70373',
    '70374',
    '70375',
    '70377',
    '70380',
    '70381',
    '70390',
    '70391',
    '70392',
    '70393',
    '70394',
    '70395',
    '70397',
    '70401',
    '70402',
    '70403',
    '70404',
    '70420',
    '70421',
    '70422',
    '70426',
    '70427',
    '70429',
    '70431',
    '70433',
    '70434',
    '70435',
    '70436',
    '70437',
    '70438',
    '70441',
    '70442',
    '70443',
    '70444',
    '70445',
    '70446',
    '70447',
    '70448',
    '70449',
    '70450',
    '70451',
    '70452',
    '70453',
    '70454',
    '70455',
    '70456',
    '70457',
    '70458',
    '70459',
    '70460',
    '70461',
    '70462',
    '70463',
    '70464',
    '70465',
    '70466',
    '70467',
    '70469',
    '70470',
    '70471',
    '70501',
    '70502',
    '70503',
    '70504',
    '70505',
    '70506',
    '70507',
    '70508',
    '70509',
    '70510',
    '70511',
    '70512',
    '70513',
    '70514',
    '70515',
    '70516',
    '70517',
    '70518',
    '70519',
    '70520',
    '70521',
    '70522',
    '70523',
    '70524',
    '70525',
    '70526',
    '70527',
    '70528',
    '70529',
    '70531',
    '70532',
    '70533',
    '70534',
    '70535',
    '70537',
    '70538',
    '70540',
    '70541',
    '70542',
    '70543',
    '70544',
    '70546',
    '70548',
    '70549',
    '70550',
    '70551',
    '70552',
    '70554',
    '70555',
    '70556',
    '70558',
    '70559',
    '70560',
    '70562',
    '70563',
    '70569',
    '70570',
    '70571',
    '70575',
    '70576',
    '70577',
    '70578',
    '70580',
    '70581',
    '70582',
    '70583',
    '70584',
    '70585',
    '70586',
    '70589',
    '70591',
    '70592',
    '70593',
    '70596',
    '70598',
    '70601',
    '70602',
    '70605',
    '70606',
    '70607',
    '70609',
    '70611',
    '70612',
    '70615',
    '70616',
    '70629',
    '70630',
    '70631',
    '70632',
    '70633',
    '70634',
    '70637',
    '70638',
    '70639',
    '70640',
    '70643',
    '70644',
    '70645',
    '70646',
    '70647',
    '70648',
    '70650',
    '70651',
    '70652',
    '70653',
    '70654',
    '70655',
    '70656',
    '70657',
    '70658',
    '70659',
    '70660',
    '70661',
    '70662',
    '70663',
    '70664',
    '70665',
    '70668',
    '70669',
    '70704',
    '70706',
    '70707',
    '70710',
    '70711',
    '70712',
    '70714',
    '70715',
    '70718',
    '70719',
    '70721',
    '70722',
    '70723',
    '70725',
    '70726',
    '70727',
    '70728',
    '70729',
    '70730',
    '70732',
    '70733',
    '70734',
    '70736',
    '70737',
    '70738',
    '70739',
    '70740',
    '70743',
    '70744',
    '70747',
    '70748',
    '70749',
    '70750',
    '70752',
    '70753',
    '70754',
    '70755',
    '70756',
    '70757',
    '70759',
    '70760',
    '70761',
    '70762',
    '70763',
    '70764',
    '70765',
    '70767',
    '70769',
    '70770',
    '70772',
    '70773',
    '70774',
    '70775',
    '70776',
    '70777',
    '70778',
    '70780',
    '70782',
    '70783',
    '70784',
    '70785',
    '70786',
    '70787',
    '70788',
    '70789',
    '70791',
    '70792',
    '70801',
    '70802',
    '70803',
    '70804',
    '70805',
    '70806',
    '70807',
    '70808',
    '70809',
    '70810',
    '70811',
    '70812',
    '70813',
    '70814',
    '70815',
    '70816',
    '70817',
    '70818',
    '70819',
    '70820',
    '70821',
    '70822',
    '70823',
    '70825',
    '70826',
    '70827',
    '70831',
    '70833',
    '70835',
    '70836',
    '70837',
    '70873',
    '70874',
    '70879',
    '70884',
    '70891',
    '70892',
    '70893',
    '70894',
    '70895',
    '70896',
    '70898',
    '71001',
    '71002',
    '71003',
    '71004',
    '71006',
    '71007',
    '71008',
    '71009',
    '71016',
    '71018',
    '71019',
    '71021',
    '71023',
    '71024',
    '71027',
    '71028',
    '71029',
    '71030',
    '71031',
    '71032',
    '71033',
    '71034',
    '71037',
    '71038',
    '71039',
    '71040',
    '71043',
    '71044',
    '71045',
    '71046',
    '71047',
    '71048',
    '71049',
    '71050',
    '71051',
    '71052',
    '71055',
    '71058',
    '71060',
    '71061',
    '71063',
    '71064',
    '71065',
    '71066',
    '71067',
    '71068',
    '71069',
    '71070',
    '71071',
    '71072',
    '71073',
    '71075',
    '71078',
    '71079',
    '71080',
    '71082',
    '71101',
    '71102',
    '71103',
    '71104',
    '71105',
    '71106',
    '71107',
    '71108',
    '71109',
    '71110',
    '71111',
    '71112',
    '71113',
    '71115',
    '71118',
    '71119',
    '71120',
    '71129',
    '71130',
    '71133',
    '71134',
    '71135',
    '71136',
    '71137',
    '71138',
    '71148',
    '71149',
    '71150',
    '71151',
    '71152',
    '71153',
    '71154',
    '71156',
    '71161',
    '71162',
    '71163',
    '71164',
    '71165',
    '71166',
    '71171',
    '71172',
    '71201',
    '71202',
    '71203',
    '71207',
    '71209',
    '71210',
    '71211',
    '71212',
    '71213',
    '71217',
    '71218',
    '71219',
    '71220',
    '71221',
    '71222',
    '71223',
    '71225',
    '71226',
    '71227',
    '71229',
    '71230',
    '71232',
    '71233',
    '71234',
    '71235',
    '71237',
    '71238',
    '71240',
    '71241',
    '71242',
    '71243',
    '71245',
    '71247',
    '71249',
    '71250',
    '71251',
    '71253',
    '71254',
    '71256',
    '71259',
    '71260',
    '71261',
    '71263',
    '71264',
    '71266',
    '71268',
    '71269',
    '71270',
    '71272',
    '71273',
    '71275',
    '71276',
    '71277',
    '71279',
    '71280',
    '71281',
    '71282',
    '71284',
    '71286',
    '71291',
    '71292',
    '71294',
    '71295',
    '71301',
    '71302',
    '71303',
    '71306',
    '71307',
    '71309',
    '71315',
    '71316',
    '71320',
    '71322',
    '71323',
    '71324',
    '71325',
    '71326',
    '71327',
    '71328',
    '71329',
    '71330',
    '71331',
    '71333',
    '71334',
    '71336',
    '71339',
    '71340',
    '71341',
    '71342',
    '71343',
    '71345',
    '71346',
    '71348',
    '71350',
    '71351',
    '71353',
    '71354',
    '71355',
    '71356',
    '71357',
    '71358',
    '71359',
    '71360',
    '71361',
    '71362',
    '71363',
    '71365',
    '71366',
    '71367',
    '71368',
    '71369',
    '71371',
    '71373',
    '71375',
    '71377',
    '71378',
    '71401',
    '71403',
    '71404',
    '71405',
    '71406',
    '71407',
    '71409',
    '71410',
    '71411',
    '71414',
    '71415',
    '71416',
    '71417',
    '71418',
    '71419',
    '71422',
    '71423',
    '71424',
    '71425',
    '71426',
    '71427',
    '71428',
    '71429',
    '71430',
    '71431',
    '71432',
    '71433',
    '71434',
    '71435',
    '71438',
    '71439',
    '71440',
    '71441',
    '71443',
    '71446',
    '71447',
    '71448',
    '71449',
    '71450',
    '71452',
    '71454',
    '71455',
    '71456',
    '71457',
    '71458',
    '71459',
    '71460',
    '71461',
    '71462',
    '71463',
    '71465',
    '71466',
    '71467',
    '71468',
    '71469',
    '71471',
    '71472',
    '71473',
    '71474',
    '71475',
    '71477',
    '71479',
    '71480',
    '71483',
    '71485',
    '71486',
    '71496',
    '71497',
  ],
  AR: [
    '71601',
    '71602',
    '71603',
    '71611',
    '71612',
    '71613',
    '71630',
    '71631',
    '71635',
    '71638',
    '71639',
    '71640',
    '71642',
    '71643',
    '71644',
    '71646',
    '71647',
    '71651',
    '71652',
    '71653',
    '71654',
    '71655',
    '71656',
    '71657',
    '71658',
    '71659',
    '71660',
    '71661',
    '71662',
    '71663',
    '71665',
    '71666',
    '71667',
    '71670',
    '71671',
    '71674',
    '71675',
    '71676',
    '71677',
    '71678',
    '71701',
    '71711',
    '71720',
    '71721',
    '71722',
    '71724',
    '71725',
    '71726',
    '71728',
    '71730',
    '71731',
    '71740',
    '71742',
    '71743',
    '71744',
    '71745',
    '71747',
    '71748',
    '71749',
    '71750',
    '71751',
    '71752',
    '71753',
    '71754',
    '71758',
    '71759',
    '71762',
    '71763',
    '71764',
    '71765',
    '71766',
    '71770',
    '71772',
    '71801',
    '71802',
    '71820',
    '71822',
    '71823',
    '71825',
    '71826',
    '71827',
    '71828',
    '71831',
    '71832',
    '71833',
    '71834',
    '71835',
    '71836',
    '71837',
    '71838',
    '71839',
    '71840',
    '71841',
    '71842',
    '71845',
    '71846',
    '71847',
    '71851',
    '71852',
    '71853',
    '71854',
    '71855',
    '71857',
    '71858',
    '71859',
    '71860',
    '71861',
    '71862',
    '71864',
    '71865',
    '71866',
    '71901',
    '71902',
    '71903',
    '71909',
    '71910',
    '71913',
    '71914',
    '71920',
    '71921',
    '71922',
    '71923',
    '71929',
    '71932',
    '71933',
    '71935',
    '71937',
    '71940',
    '71941',
    '71942',
    '71943',
    '71944',
    '71945',
    '71949',
    '71950',
    '71952',
    '71953',
    '71956',
    '71957',
    '71958',
    '71959',
    '71960',
    '71961',
    '71962',
    '71964',
    '71965',
    '71966',
    '71968',
    '71969',
    '71970',
    '71971',
    '71972',
    '71973',
    '71998',
    '71999',
    '72001',
    '72002',
    '72003',
    '72004',
    '72005',
    '72006',
    '72007',
    '72010',
    '72011',
    '72012',
    '72013',
    '72014',
    '72015',
    '72016',
    '72017',
    '72018',
    '72019',
    '72020',
    '72021',
    '72022',
    '72023',
    '72024',
    '72025',
    '72026',
    '72027',
    '72028',
    '72029',
    '72030',
    '72031',
    '72032',
    '72033',
    '72034',
    '72035',
    '72036',
    '72037',
    '72038',
    '72039',
    '72040',
    '72041',
    '72042',
    '72043',
    '72044',
    '72045',
    '72046',
    '72047',
    '72048',
    '72051',
    '72052',
    '72053',
    '72055',
    '72057',
    '72058',
    '72059',
    '72060',
    '72061',
    '72063',
    '72064',
    '72065',
    '72066',
    '72067',
    '72068',
    '72069',
    '72070',
    '72072',
    '72073',
    '72074',
    '72075',
    '72076',
    '72078',
    '72079',
    '72080',
    '72081',
    '72082',
    '72083',
    '72084',
    '72085',
    '72086',
    '72087',
    '72088',
    '72089',
    '72099',
    '72101',
    '72102',
    '72103',
    '72104',
    '72106',
    '72107',
    '72108',
    '72110',
    '72111',
    '72112',
    '72113',
    '72114',
    '72115',
    '72116',
    '72117',
    '72118',
    '72119',
    '72120',
    '72121',
    '72122',
    '72123',
    '72124',
    '72125',
    '72126',
    '72127',
    '72128',
    '72129',
    '72130',
    '72131',
    '72132',
    '72133',
    '72134',
    '72135',
    '72136',
    '72137',
    '72139',
    '72140',
    '72141',
    '72142',
    '72143',
    '72145',
    '72149',
    '72150',
    '72152',
    '72153',
    '72156',
    '72157',
    '72158',
    '72160',
    '72164',
    '72165',
    '72166',
    '72167',
    '72168',
    '72169',
    '72170',
    '72173',
    '72175',
    '72176',
    '72178',
    '72179',
    '72180',
    '72181',
    '72182',
    '72183',
    '72190',
    '72199',
    '72201',
    '72202',
    '72203',
    '72204',
    '72205',
    '72206',
    '72207',
    '72209',
    '72210',
    '72211',
    '72212',
    '72214',
    '72215',
    '72216',
    '72217',
    '72219',
    '72221',
    '72222',
    '72223',
    '72225',
    '72227',
    '72231',
    '72255',
    '72260',
    '72295',
    '72301',
    '72303',
    '72310',
    '72311',
    '72312',
    '72313',
    '72315',
    '72316',
    '72319',
    '72320',
    '72321',
    '72322',
    '72324',
    '72325',
    '72326',
    '72327',
    '72328',
    '72329',
    '72330',
    '72331',
    '72332',
    '72333',
    '72335',
    '72336',
    '72338',
    '72339',
    '72340',
    '72341',
    '72342',
    '72346',
    '72347',
    '72348',
    '72350',
    '72351',
    '72352',
    '72353',
    '72354',
    '72355',
    '72358',
    '72359',
    '72360',
    '72364',
    '72365',
    '72366',
    '72367',
    '72368',
    '72369',
    '72370',
    '72372',
    '72373',
    '72374',
    '72376',
    '72377',
    '72379',
    '72383',
    '72384',
    '72386',
    '72387',
    '72389',
    '72390',
    '72391',
    '72392',
    '72394',
    '72395',
    '72396',
    '72401',
    '72402',
    '72403',
    '72404',
    '72405',
    '72410',
    '72411',
    '72412',
    '72413',
    '72414',
    '72415',
    '72416',
    '72417',
    '72419',
    '72421',
    '72422',
    '72424',
    '72425',
    '72426',
    '72427',
    '72428',
    '72429',
    '72430',
    '72431',
    '72432',
    '72433',
    '72434',
    '72435',
    '72436',
    '72437',
    '72438',
    '72440',
    '72441',
    '72442',
    '72443',
    '72444',
    '72445',
    '72447',
    '72449',
    '72450',
    '72451',
    '72453',
    '72454',
    '72455',
    '72456',
    '72457',
    '72458',
    '72459',
    '72460',
    '72461',
    '72462',
    '72464',
    '72465',
    '72466',
    '72467',
    '72469',
    '72470',
    '72471',
    '72472',
    '72473',
    '72474',
    '72475',
    '72476',
    '72478',
    '72479',
    '72482',
    '72501',
    '72503',
    '72512',
    '72513',
    '72515',
    '72517',
    '72519',
    '72520',
    '72521',
    '72522',
    '72523',
    '72524',
    '72525',
    '72526',
    '72527',
    '72528',
    '72529',
    '72530',
    '72531',
    '72532',
    '72533',
    '72534',
    '72536',
    '72537',
    '72538',
    '72539',
    '72540',
    '72542',
    '72543',
    '72544',
    '72545',
    '72546',
    '72550',
    '72553',
    '72554',
    '72555',
    '72556',
    '72560',
    '72561',
    '72562',
    '72564',
    '72565',
    '72566',
    '72567',
    '72568',
    '72569',
    '72571',
    '72572',
    '72573',
    '72575',
    '72576',
    '72577',
    '72578',
    '72579',
    '72581',
    '72583',
    '72584',
    '72585',
    '72587',
    '72601',
    '72602',
    '72611',
    '72613',
    '72615',
    '72616',
    '72617',
    '72619',
    '72623',
    '72624',
    '72626',
    '72628',
    '72629',
    '72630',
    '72631',
    '72632',
    '72633',
    '72634',
    '72635',
    '72636',
    '72638',
    '72639',
    '72640',
    '72641',
    '72642',
    '72644',
    '72645',
    '72648',
    '72650',
    '72651',
    '72653',
    '72654',
    '72655',
    '72657',
    '72658',
    '72659',
    '72660',
    '72661',
    '72662',
    '72663',
    '72666',
    '72668',
    '72669',
    '72670',
    '72672',
    '72675',
    '72677',
    '72679',
    '72680',
    '72682',
    '72683',
    '72685',
    '72686',
    '72687',
    '72701',
    '72702',
    '72703',
    '72704',
    '72711',
    '72712',
    '72713',
    '72714',
    '72715',
    '72716',
    '72717',
    '72718',
    '72719',
    '72721',
    '72722',
    '72727',
    '72728',
    '72729',
    '72730',
    '72732',
    '72733',
    '72734',
    '72735',
    '72736',
    '72737',
    '72738',
    '72739',
    '72740',
    '72741',
    '72742',
    '72744',
    '72745',
    '72747',
    '72749',
    '72751',
    '72752',
    '72753',
    '72756',
    '72757',
    '72758',
    '72760',
    '72761',
    '72762',
    '72764',
    '72765',
    '72766',
    '72768',
    '72769',
    '72770',
    '72773',
    '72774',
    '72776',
    '72801',
    '72802',
    '72811',
    '72812',
    '72820',
    '72821',
    '72823',
    '72824',
    '72826',
    '72827',
    '72828',
    '72829',
    '72830',
    '72832',
    '72833',
    '72834',
    '72835',
    '72837',
    '72838',
    '72839',
    '72840',
    '72841',
    '72842',
    '72843',
    '72845',
    '72846',
    '72847',
    '72851',
    '72852',
    '72853',
    '72854',
    '72855',
    '72856',
    '72857',
    '72858',
    '72860',
    '72863',
    '72865',
    '72901',
    '72902',
    '72903',
    '72904',
    '72905',
    '72906',
    '72908',
    '72913',
    '72914',
    '72916',
    '72917',
    '72918',
    '72919',
    '72921',
    '72923',
    '72926',
    '72927',
    '72928',
    '72930',
    '72932',
    '72933',
    '72934',
    '72935',
    '72936',
    '72937',
    '72938',
    '72940',
    '72941',
    '72943',
    '72944',
    '72945',
    '72946',
    '72947',
    '72948',
    '72949',
    '72950',
    '72951',
    '72952',
    '72955',
    '72956',
    '72957',
    '72958',
    '72959',
  ],
  OK: [
    '73001',
    '73002',
    '73003',
    '73004',
    '73005',
    '73006',
    '73007',
    '73008',
    '73009',
    '73010',
    '73011',
    '73012',
    '73013',
    '73014',
    '73015',
    '73016',
    '73017',
    '73018',
    '73019',
    '73020',
    '73021',
    '73022',
    '73023',
    '73024',
    '73025',
    '73026',
    '73027',
    '73028',
    '73029',
    '73030',
    '73031',
    '73032',
    '73033',
    '73034',
    '73036',
    '73038',
    '73039',
    '73040',
    '73041',
    '73042',
    '73043',
    '73044',
    '73045',
    '73047',
    '73048',
    '73049',
    '73050',
    '73051',
    '73052',
    '73053',
    '73054',
    '73055',
    '73056',
    '73057',
    '73058',
    '73059',
    '73061',
    '73062',
    '73063',
    '73064',
    '73065',
    '73066',
    '73067',
    '73068',
    '73069',
    '73070',
    '73071',
    '73072',
    '73073',
    '73074',
    '73075',
    '73077',
    '73078',
    '73079',
    '73080',
    '73082',
    '73083',
    '73084',
    '73085',
    '73086',
    '73089',
    '73090',
    '73092',
    '73093',
    '73095',
    '73096',
    '73097',
    '73098',
    '73099',
    '73101',
    '73102',
    '73103',
    '73104',
    '73105',
    '73106',
    '73107',
    '73108',
    '73109',
    '73110',
    '73111',
    '73112',
    '73113',
    '73114',
    '73115',
    '73116',
    '73117',
    '73118',
    '73119',
    '73120',
    '73121',
    '73122',
    '73123',
    '73124',
    '73125',
    '73126',
    '73127',
    '73128',
    '73129',
    '73130',
    '73131',
    '73132',
    '73134',
    '73135',
    '73136',
    '73137',
    '73139',
    '73140',
    '73141',
    '73142',
    '73143',
    '73144',
    '73145',
    '73146',
    '73147',
    '73148',
    '73149',
    '73150',
    '73151',
    '73152',
    '73153',
    '73154',
    '73155',
    '73156',
    '73157',
    '73159',
    '73160',
    '73162',
    '73163',
    '73164',
    '73165',
    '73167',
    '73169',
    '73170',
    '73172',
    '73173',
    '73178',
    '73179',
    '73184',
    '73189',
    '73190',
    '73194',
    '73195',
    '73196',
    '73401',
    '73402',
    '73403',
    '73425',
    '73430',
    '73432',
    '73433',
    '73434',
    '73435',
    '73436',
    '73437',
    '73438',
    '73439',
    '73440',
    '73441',
    '73442',
    '73443',
    '73444',
    '73446',
    '73447',
    '73448',
    '73449',
    '73450',
    '73453',
    '73455',
    '73456',
    '73458',
    '73459',
    '73460',
    '73461',
    '73463',
    '73481',
    '73487',
    '73488',
    '73491',
    '73501',
    '73502',
    '73503',
    '73505',
    '73506',
    '73507',
    '73520',
    '73521',
    '73522',
    '73523',
    '73526',
    '73527',
    '73528',
    '73529',
    '73530',
    '73531',
    '73532',
    '73533',
    '73534',
    '73536',
    '73537',
    '73538',
    '73539',
    '73540',
    '73541',
    '73542',
    '73543',
    '73544',
    '73546',
    '73547',
    '73548',
    '73549',
    '73550',
    '73551',
    '73552',
    '73553',
    '73554',
    '73555',
    '73556',
    '73557',
    '73558',
    '73559',
    '73560',
    '73561',
    '73562',
    '73564',
    '73565',
    '73566',
    '73567',
    '73568',
    '73569',
    '73570',
    '73571',
    '73572',
    '73573',
    '73601',
    '73620',
    '73622',
    '73624',
    '73625',
    '73626',
    '73627',
    '73628',
    '73632',
    '73638',
    '73639',
    '73641',
    '73642',
    '73644',
    '73645',
    '73646',
    '73647',
    '73648',
    '73650',
    '73651',
    '73654',
    '73655',
    '73658',
    '73659',
    '73660',
    '73661',
    '73662',
    '73663',
    '73664',
    '73666',
    '73667',
    '73668',
    '73669',
    '73673',
    '73701',
    '73702',
    '73703',
    '73705',
    '73706',
    '73716',
    '73717',
    '73718',
    '73719',
    '73720',
    '73722',
    '73724',
    '73726',
    '73727',
    '73728',
    '73729',
    '73730',
    '73731',
    '73733',
    '73734',
    '73735',
    '73736',
    '73737',
    '73738',
    '73739',
    '73741',
    '73742',
    '73743',
    '73744',
    '73746',
    '73747',
    '73749',
    '73750',
    '73753',
    '73754',
    '73755',
    '73756',
    '73757',
    '73758',
    '73759',
    '73760',
    '73761',
    '73762',
    '73763',
    '73764',
    '73766',
    '73768',
    '73770',
    '73771',
    '73772',
    '73773',
    '73801',
    '73802',
    '73832',
    '73834',
    '73835',
    '73838',
    '73840',
    '73841',
    '73842',
    '73843',
    '73844',
    '73848',
    '73851',
    '73852',
    '73853',
    '73855',
    '73857',
    '73858',
    '73859',
    '73860',
    '73901',
    '73931',
    '73932',
    '73933',
    '73937',
    '73938',
    '73939',
    '73942',
    '73944',
    '73945',
    '73946',
    '73947',
    '73949',
    '73950',
    '73951',
    '74001',
    '74002',
    '74003',
    '74004',
    '74005',
    '74006',
    '74008',
    '74010',
    '74011',
    '74012',
    '74013',
    '74014',
    '74015',
    '74016',
    '74017',
    '74018',
    '74019',
    '74020',
    '74021',
    '74022',
    '74023',
    '74026',
    '74027',
    '74028',
    '74029',
    '74030',
    '74031',
    '74032',
    '74033',
    '74034',
    '74035',
    '74036',
    '74037',
    '74038',
    '74039',
    '74041',
    '74042',
    '74043',
    '74044',
    '74045',
    '74046',
    '74047',
    '74048',
    '74050',
    '74051',
    '74052',
    '74053',
    '74054',
    '74055',
    '74056',
    '74058',
    '74059',
    '74060',
    '74061',
    '74062',
    '74063',
    '74066',
    '74067',
    '74068',
    '74070',
    '74071',
    '74072',
    '74073',
    '74074',
    '74075',
    '74076',
    '74077',
    '74078',
    '74079',
    '74080',
    '74081',
    '74082',
    '74083',
    '74084',
    '74085',
    '74101',
    '74102',
    '74103',
    '74104',
    '74105',
    '74106',
    '74107',
    '74108',
    '74110',
    '74112',
    '74114',
    '74115',
    '74116',
    '74117',
    '74119',
    '74120',
    '74121',
    '74126',
    '74127',
    '74128',
    '74129',
    '74130',
    '74131',
    '74132',
    '74133',
    '74134',
    '74135',
    '74136',
    '74137',
    '74141',
    '74145',
    '74146',
    '74147',
    '74148',
    '74149',
    '74150',
    '74152',
    '74153',
    '74155',
    '74156',
    '74157',
    '74158',
    '74159',
    '74169',
    '74170',
    '74171',
    '74172',
    '74182',
    '74186',
    '74187',
    '74192',
    '74193',
    '74301',
    '74330',
    '74331',
    '74332',
    '74333',
    '74335',
    '74337',
    '74338',
    '74339',
    '74340',
    '74342',
    '74343',
    '74344',
    '74345',
    '74346',
    '74347',
    '74349',
    '74350',
    '74352',
    '74354',
    '74355',
    '74358',
    '74359',
    '74360',
    '74361',
    '74362',
    '74363',
    '74364',
    '74365',
    '74366',
    '74367',
    '74368',
    '74369',
    '74370',
    '74401',
    '74402',
    '74403',
    '74421',
    '74422',
    '74423',
    '74425',
    '74426',
    '74427',
    '74428',
    '74429',
    '74430',
    '74431',
    '74432',
    '74434',
    '74435',
    '74436',
    '74437',
    '74438',
    '74439',
    '74440',
    '74441',
    '74442',
    '74444',
    '74445',
    '74446',
    '74447',
    '74450',
    '74451',
    '74452',
    '74454',
    '74455',
    '74456',
    '74457',
    '74458',
    '74459',
    '74460',
    '74461',
    '74462',
    '74463',
    '74464',
    '74465',
    '74467',
    '74468',
    '74469',
    '74470',
    '74471',
    '74472',
    '74477',
    '74501',
    '74502',
    '74521',
    '74522',
    '74523',
    '74525',
    '74528',
    '74529',
    '74530',
    '74531',
    '74533',
    '74534',
    '74535',
    '74536',
    '74538',
    '74540',
    '74543',
    '74545',
    '74546',
    '74547',
    '74549',
    '74552',
    '74553',
    '74554',
    '74555',
    '74556',
    '74557',
    '74558',
    '74559',
    '74560',
    '74561',
    '74562',
    '74563',
    '74565',
    '74567',
    '74569',
    '74570',
    '74571',
    '74572',
    '74574',
    '74576',
    '74577',
    '74578',
    '74601',
    '74602',
    '74604',
    '74630',
    '74631',
    '74632',
    '74633',
    '74636',
    '74637',
    '74640',
    '74641',
    '74643',
    '74644',
    '74646',
    '74647',
    '74650',
    '74651',
    '74652',
    '74653',
    '74701',
    '74702',
    '74720',
    '74721',
    '74722',
    '74723',
    '74724',
    '74726',
    '74727',
    '74728',
    '74729',
    '74730',
    '74731',
    '74733',
    '74734',
    '74735',
    '74736',
    '74737',
    '74738',
    '74740',
    '74741',
    '74743',
    '74745',
    '74747',
    '74748',
    '74750',
    '74752',
    '74753',
    '74754',
    '74755',
    '74756',
    '74759',
    '74760',
    '74761',
    '74764',
    '74766',
    '74801',
    '74802',
    '74804',
    '74818',
    '74820',
    '74821',
    '74824',
    '74825',
    '74826',
    '74827',
    '74829',
    '74830',
    '74831',
    '74832',
    '74833',
    '74834',
    '74836',
    '74837',
    '74839',
    '74840',
    '74842',
    '74843',
    '74844',
    '74845',
    '74848',
    '74849',
    '74850',
    '74851',
    '74852',
    '74854',
    '74855',
    '74856',
    '74857',
    '74859',
    '74860',
    '74864',
    '74865',
    '74866',
    '74867',
    '74868',
    '74869',
    '74871',
    '74872',
    '74873',
    '74875',
    '74878',
    '74880',
    '74881',
    '74883',
    '74884',
    '74901',
    '74902',
    '74930',
    '74931',
    '74932',
    '74935',
    '74936',
    '74937',
    '74939',
    '74940',
    '74941',
    '74942',
    '74943',
    '74944',
    '74945',
    '74946',
    '74947',
    '74948',
    '74949',
    '74951',
    '74953',
    '74954',
    '74955',
    '74956',
    '74957',
    '74959',
    '74960',
    '74962',
    '74963',
    '74964',
    '74965',
    '74966',
  ],
  TX: [
    '73301',
    '73344',
    '73960',
    '75001',
    '75002',
    '75006',
    '75007',
    '75009',
    '75010',
    '75011',
    '75013',
    '75014',
    '75015',
    '75016',
    '75017',
    '75019',
    '75020',
    '75021',
    '75022',
    '75023',
    '75024',
    '75025',
    '75026',
    '75027',
    '75028',
    '75029',
    '75030',
    '75032',
    '75033',
    '75034',
    '75035',
    '75036',
    '75038',
    '75039',
    '75040',
    '75041',
    '75042',
    '75043',
    '75044',
    '75045',
    '75046',
    '75047',
    '75048',
    '75049',
    '75050',
    '75051',
    '75052',
    '75053',
    '75054',
    '75056',
    '75057',
    '75058',
    '75059',
    '75060',
    '75061',
    '75062',
    '75063',
    '75064',
    '75065',
    '75067',
    '75068',
    '75069',
    '75070',
    '75071',
    '75072',
    '75074',
    '75075',
    '75076',
    '75077',
    '75078',
    '75080',
    '75081',
    '75082',
    '75083',
    '75085',
    '75086',
    '75087',
    '75088',
    '75089',
    '75090',
    '75091',
    '75092',
    '75093',
    '75094',
    '75097',
    '75098',
    '75099',
    '75101',
    '75102',
    '75103',
    '75104',
    '75105',
    '75106',
    '75109',
    '75110',
    '75114',
    '75115',
    '75116',
    '75117',
    '75118',
    '75119',
    '75120',
    '75121',
    '75123',
    '75124',
    '75125',
    '75126',
    '75127',
    '75132',
    '75134',
    '75135',
    '75137',
    '75138',
    '75140',
    '75141',
    '75142',
    '75143',
    '75144',
    '75146',
    '75147',
    '75148',
    '75149',
    '75150',
    '75151',
    '75152',
    '75153',
    '75154',
    '75155',
    '75156',
    '75157',
    '75158',
    '75159',
    '75160',
    '75161',
    '75163',
    '75164',
    '75165',
    '75166',
    '75167',
    '75168',
    '75169',
    '75172',
    '75173',
    '75180',
    '75181',
    '75182',
    '75185',
    '75187',
    '75189',
    '75201',
    '75202',
    '75203',
    '75204',
    '75205',
    '75206',
    '75207',
    '75208',
    '75209',
    '75210',
    '75211',
    '75212',
    '75214',
    '75215',
    '75216',
    '75217',
    '75218',
    '75219',
    '75220',
    '75221',
    '75222',
    '75223',
    '75224',
    '75225',
    '75226',
    '75227',
    '75228',
    '75229',
    '75230',
    '75231',
    '75232',
    '75233',
    '75234',
    '75235',
    '75236',
    '75237',
    '75238',
    '75240',
    '75241',
    '75242',
    '75243',
    '75244',
    '75246',
    '75247',
    '75248',
    '75249',
    '75250',
    '75251',
    '75252',
    '75253',
    '75254',
    '75260',
    '75261',
    '75262',
    '75263',
    '75264',
    '75265',
    '75266',
    '75267',
    '75270',
    '75275',
    '75277',
    '75283',
    '75284',
    '75285',
    '75287',
    '75301',
    '75303',
    '75312',
    '75313',
    '75315',
    '75320',
    '75326',
    '75336',
    '75339',
    '75342',
    '75354',
    '75355',
    '75356',
    '75357',
    '75358',
    '75359',
    '75360',
    '75367',
    '75368',
    '75370',
    '75371',
    '75372',
    '75373',
    '75374',
    '75376',
    '75378',
    '75379',
    '75380',
    '75381',
    '75382',
    '75389',
    '75390',
    '75391',
    '75392',
    '75393',
    '75394',
    '75395',
    '75397',
    '75398',
    '75401',
    '75402',
    '75403',
    '75404',
    '75407',
    '75409',
    '75410',
    '75411',
    '75412',
    '75413',
    '75414',
    '75415',
    '75416',
    '75417',
    '75418',
    '75420',
    '75421',
    '75422',
    '75423',
    '75424',
    '75425',
    '75426',
    '75428',
    '75429',
    '75431',
    '75432',
    '75433',
    '75434',
    '75435',
    '75436',
    '75437',
    '75438',
    '75439',
    '75440',
    '75441',
    '75442',
    '75443',
    '75444',
    '75446',
    '75447',
    '75448',
    '75449',
    '75450',
    '75451',
    '75452',
    '75453',
    '75454',
    '75455',
    '75456',
    '75457',
    '75458',
    '75459',
    '75460',
    '75461',
    '75462',
    '75468',
    '75469',
    '75470',
    '75471',
    '75472',
    '75473',
    '75474',
    '75475',
    '75476',
    '75477',
    '75478',
    '75479',
    '75480',
    '75481',
    '75482',
    '75483',
    '75485',
    '75486',
    '75487',
    '75488',
    '75489',
    '75490',
    '75491',
    '75492',
    '75493',
    '75494',
    '75495',
    '75496',
    '75497',
    '75501',
    '75503',
    '75504',
    '75505',
    '75507',
    '75550',
    '75551',
    '75554',
    '75555',
    '75556',
    '75558',
    '75559',
    '75560',
    '75561',
    '75562',
    '75563',
    '75564',
    '75565',
    '75566',
    '75567',
    '75568',
    '75569',
    '75570',
    '75571',
    '75572',
    '75573',
    '75574',
    '75599',
    '75601',
    '75602',
    '75603',
    '75604',
    '75605',
    '75606',
    '75607',
    '75608',
    '75615',
    '75630',
    '75631',
    '75633',
    '75636',
    '75637',
    '75638',
    '75639',
    '75640',
    '75641',
    '75642',
    '75643',
    '75644',
    '75645',
    '75647',
    '75650',
    '75651',
    '75652',
    '75653',
    '75654',
    '75656',
    '75657',
    '75658',
    '75659',
    '75660',
    '75661',
    '75662',
    '75663',
    '75666',
    '75667',
    '75668',
    '75669',
    '75670',
    '75671',
    '75672',
    '75680',
    '75681',
    '75682',
    '75683',
    '75684',
    '75685',
    '75686',
    '75687',
    '75688',
    '75689',
    '75691',
    '75692',
    '75693',
    '75694',
    '75701',
    '75702',
    '75703',
    '75704',
    '75705',
    '75706',
    '75707',
    '75708',
    '75709',
    '75710',
    '75711',
    '75712',
    '75713',
    '75750',
    '75751',
    '75752',
    '75754',
    '75755',
    '75756',
    '75757',
    '75758',
    '75759',
    '75760',
    '75762',
    '75763',
    '75764',
    '75765',
    '75766',
    '75770',
    '75771',
    '75772',
    '75773',
    '75778',
    '75779',
    '75780',
    '75782',
    '75783',
    '75784',
    '75785',
    '75788',
    '75789',
    '75790',
    '75791',
    '75792',
    '75797',
    '75798',
    '75799',
    '75801',
    '75802',
    '75803',
    '75831',
    '75832',
    '75833',
    '75834',
    '75835',
    '75838',
    '75839',
    '75840',
    '75844',
    '75845',
    '75846',
    '75847',
    '75848',
    '75849',
    '75850',
    '75851',
    '75852',
    '75853',
    '75855',
    '75856',
    '75858',
    '75859',
    '75860',
    '75861',
    '75862',
    '75865',
    '75880',
    '75882',
    '75884',
    '75886',
    '75901',
    '75902',
    '75903',
    '75904',
    '75915',
    '75925',
    '75926',
    '75928',
    '75929',
    '75930',
    '75931',
    '75932',
    '75933',
    '75934',
    '75935',
    '75936',
    '75937',
    '75938',
    '75939',
    '75941',
    '75942',
    '75943',
    '75944',
    '75946',
    '75948',
    '75949',
    '75951',
    '75954',
    '75956',
    '75958',
    '75959',
    '75960',
    '75961',
    '75962',
    '75963',
    '75964',
    '75965',
    '75966',
    '75968',
    '75969',
    '75972',
    '75973',
    '75974',
    '75975',
    '75976',
    '75977',
    '75978',
    '75979',
    '75980',
    '75990',
    '76001',
    '76002',
    '76003',
    '76004',
    '76005',
    '76006',
    '76007',
    '76008',
    '76009',
    '76010',
    '76011',
    '76012',
    '76013',
    '76014',
    '76015',
    '76016',
    '76017',
    '76018',
    '76019',
    '76020',
    '76021',
    '76022',
    '76023',
    '76028',
    '76031',
    '76033',
    '76034',
    '76035',
    '76036',
    '76039',
    '76040',
    '76041',
    '76043',
    '76044',
    '76048',
    '76049',
    '76050',
    '76051',
    '76052',
    '76053',
    '76054',
    '76055',
    '76058',
    '76059',
    '76060',
    '76061',
    '76063',
    '76064',
    '76065',
    '76066',
    '76067',
    '76068',
    '76070',
    '76071',
    '76073',
    '76077',
    '76078',
    '76082',
    '76084',
    '76085',
    '76086',
    '76087',
    '76088',
    '76092',
    '76093',
    '76094',
    '76095',
    '76096',
    '76097',
    '76098',
    '76099',
    '76101',
    '76102',
    '76103',
    '76104',
    '76105',
    '76106',
    '76107',
    '76108',
    '76109',
    '76110',
    '76111',
    '76112',
    '76113',
    '76114',
    '76115',
    '76116',
    '76117',
    '76118',
    '76119',
    '76120',
    '76121',
    '76122',
    '76123',
    '76124',
    '76126',
    '76127',
    '76129',
    '76130',
    '76131',
    '76132',
    '76133',
    '76134',
    '76135',
    '76136',
    '76137',
    '76140',
    '76147',
    '76148',
    '76150',
    '76155',
    '76161',
    '76162',
    '76163',
    '76164',
    '76166',
    '76177',
    '76179',
    '76180',
    '76181',
    '76182',
    '76185',
    '76190',
    '76191',
    '76192',
    '76193',
    '76195',
    '76196',
    '76197',
    '76198',
    '76199',
    '76201',
    '76202',
    '76203',
    '76204',
    '76205',
    '76206',
    '76207',
    '76208',
    '76209',
    '76210',
    '76225',
    '76226',
    '76227',
    '76228',
    '76230',
    '76233',
    '76234',
    '76238',
    '76239',
    '76240',
    '76241',
    '76244',
    '76245',
    '76246',
    '76247',
    '76248',
    '76249',
    '76250',
    '76251',
    '76252',
    '76253',
    '76255',
    '76258',
    '76259',
    '76261',
    '76262',
    '76263',
    '76264',
    '76265',
    '76266',
    '76267',
    '76268',
    '76270',
    '76271',
    '76272',
    '76273',
    '76301',
    '76302',
    '76305',
    '76306',
    '76307',
    '76308',
    '76309',
    '76310',
    '76311',
    '76351',
    '76352',
    '76354',
    '76357',
    '76360',
    '76363',
    '76364',
    '76365',
    '76366',
    '76367',
    '76369',
    '76370',
    '76371',
    '76372',
    '76373',
    '76374',
    '76377',
    '76379',
    '76380',
    '76384',
    '76385',
    '76388',
    '76389',
    '76401',
    '76402',
    '76424',
    '76426',
    '76427',
    '76429',
    '76430',
    '76431',
    '76432',
    '76433',
    '76435',
    '76436',
    '76437',
    '76439',
    '76442',
    '76443',
    '76444',
    '76445',
    '76446',
    '76448',
    '76449',
    '76450',
    '76452',
    '76453',
    '76454',
    '76455',
    '76457',
    '76458',
    '76459',
    '76460',
    '76461',
    '76462',
    '76463',
    '76464',
    '76465',
    '76466',
    '76467',
    '76468',
    '76469',
    '76470',
    '76471',
    '76472',
    '76474',
    '76475',
    '76476',
    '76481',
    '76483',
    '76484',
    '76485',
    '76486',
    '76487',
    '76490',
    '76491',
    '76501',
    '76502',
    '76503',
    '76504',
    '76508',
    '76511',
    '76513',
    '76518',
    '76519',
    '76520',
    '76522',
    '76523',
    '76524',
    '76525',
    '76526',
    '76527',
    '76528',
    '76530',
    '76531',
    '76533',
    '76534',
    '76537',
    '76538',
    '76539',
    '76540',
    '76541',
    '76542',
    '76543',
    '76544',
    '76547',
    '76548',
    '76549',
    '76550',
    '76554',
    '76556',
    '76557',
    '76558',
    '76559',
    '76561',
    '76564',
    '76565',
    '76566',
    '76567',
    '76569',
    '76570',
    '76571',
    '76573',
    '76574',
    '76577',
    '76578',
    '76579',
    '76596',
    '76597',
    '76598',
    '76599',
    '76621',
    '76622',
    '76623',
    '76624',
    '76626',
    '76627',
    '76628',
    '76629',
    '76630',
    '76631',
    '76632',
    '76633',
    '76634',
    '76635',
    '76636',
    '76637',
    '76638',
    '76639',
    '76640',
    '76641',
    '76642',
    '76643',
    '76644',
    '76645',
    '76648',
    '76649',
    '76650',
    '76651',
    '76652',
    '76653',
    '76654',
    '76655',
    '76656',
    '76657',
    '76660',
    '76661',
    '76664',
    '76665',
    '76666',
    '76667',
    '76670',
    '76671',
    '76673',
    '76676',
    '76678',
    '76679',
    '76680',
    '76681',
    '76682',
    '76684',
    '76685',
    '76686',
    '76687',
    '76689',
    '76690',
    '76691',
    '76692',
    '76693',
    '76701',
    '76702',
    '76703',
    '76704',
    '76705',
    '76706',
    '76707',
    '76708',
    '76710',
    '76711',
    '76712',
    '76714',
    '76715',
    '76716',
    '76797',
    '76798',
    '76799',
    '76801',
    '76802',
    '76803',
    '76804',
    '76820',
    '76821',
    '76823',
    '76824',
    '76825',
    '76827',
    '76828',
    '76831',
    '76832',
    '76834',
    '76836',
    '76837',
    '76841',
    '76842',
    '76844',
    '76845',
    '76848',
    '76849',
    '76852',
    '76853',
    '76854',
    '76855',
    '76856',
    '76857',
    '76858',
    '76859',
    '76861',
    '76862',
    '76864',
    '76865',
    '76866',
    '76869',
    '76870',
    '76871',
    '76872',
    '76873',
    '76874',
    '76875',
    '76877',
    '76878',
    '76880',
    '76882',
    '76883',
    '76884',
    '76885',
    '76886',
    '76887',
    '76888',
    '76890',
    '76901',
    '76902',
    '76903',
    '76904',
    '76905',
    '76906',
    '76908',
    '76909',
    '76930',
    '76932',
    '76933',
    '76934',
    '76935',
    '76936',
    '76937',
    '76939',
    '76940',
    '76941',
    '76943',
    '76945',
    '76949',
    '76950',
    '76951',
    '76953',
    '76955',
    '76957',
    '76958',
    '77001',
    '77002',
    '77003',
    '77004',
    '77005',
    '77006',
    '77007',
    '77008',
    '77009',
    '77010',
    '77011',
    '77012',
    '77013',
    '77014',
    '77015',
    '77016',
    '77017',
    '77018',
    '77019',
    '77020',
    '77021',
    '77022',
    '77023',
    '77024',
    '77025',
    '77026',
    '77027',
    '77028',
    '77029',
    '77030',
    '77031',
    '77032',
    '77033',
    '77034',
    '77035',
    '77036',
    '77037',
    '77038',
    '77039',
    '77040',
    '77041',
    '77042',
    '77043',
    '77044',
    '77045',
    '77046',
    '77047',
    '77048',
    '77049',
    '77050',
    '77051',
    '77052',
    '77053',
    '77054',
    '77055',
    '77056',
    '77057',
    '77058',
    '77059',
    '77060',
    '77061',
    '77062',
    '77063',
    '77064',
    '77065',
    '77066',
    '77067',
    '77068',
    '77069',
    '77070',
    '77071',
    '77072',
    '77073',
    '77074',
    '77075',
    '77076',
    '77077',
    '77078',
    '77079',
    '77080',
    '77081',
    '77082',
    '77083',
    '77084',
    '77085',
    '77086',
    '77087',
    '77088',
    '77089',
    '77090',
    '77091',
    '77092',
    '77093',
    '77094',
    '77095',
    '77096',
    '77098',
    '77099',
    '77201',
    '77202',
    '77203',
    '77204',
    '77205',
    '77206',
    '77207',
    '77208',
    '77209',
    '77210',
    '77212',
    '77213',
    '77215',
    '77216',
    '77217',
    '77218',
    '77219',
    '77220',
    '77221',
    '77222',
    '77223',
    '77224',
    '77225',
    '77226',
    '77227',
    '77228',
    '77229',
    '77230',
    '77231',
    '77233',
    '77234',
    '77235',
    '77236',
    '77237',
    '77238',
    '77240',
    '77241',
    '77242',
    '77243',
    '77244',
    '77245',
    '77248',
    '77249',
    '77251',
    '77252',
    '77253',
    '77254',
    '77255',
    '77256',
    '77257',
    '77258',
    '77259',
    '77261',
    '77262',
    '77263',
    '77265',
    '77266',
    '77267',
    '77268',
    '77269',
    '77270',
    '77271',
    '77272',
    '77273',
    '77274',
    '77275',
    '77277',
    '77279',
    '77280',
    '77282',
    '77284',
    '77287',
    '77288',
    '77289',
    '77290',
    '77291',
    '77292',
    '77293',
    '77297',
    '77299',
    '77301',
    '77302',
    '77303',
    '77304',
    '77305',
    '77306',
    '77315',
    '77316',
    '77318',
    '77320',
    '77325',
    '77326',
    '77327',
    '77328',
    '77331',
    '77332',
    '77333',
    '77334',
    '77335',
    '77336',
    '77337',
    '77338',
    '77339',
    '77340',
    '77341',
    '77342',
    '77343',
    '77344',
    '77345',
    '77346',
    '77347',
    '77348',
    '77349',
    '77350',
    '77351',
    '77353',
    '77354',
    '77355',
    '77356',
    '77357',
    '77358',
    '77359',
    '77360',
    '77362',
    '77363',
    '77364',
    '77365',
    '77367',
    '77368',
    '77369',
    '77371',
    '77372',
    '77373',
    '77374',
    '77375',
    '77376',
    '77377',
    '77378',
    '77379',
    '77380',
    '77381',
    '77382',
    '77383',
    '77384',
    '77385',
    '77386',
    '77387',
    '77388',
    '77389',
    '77391',
    '77393',
    '77396',
    '77399',
    '77401',
    '77402',
    '77404',
    '77406',
    '77407',
    '77410',
    '77411',
    '77412',
    '77413',
    '77414',
    '77415',
    '77417',
    '77418',
    '77419',
    '77420',
    '77422',
    '77423',
    '77426',
    '77428',
    '77429',
    '77430',
    '77431',
    '77432',
    '77433',
    '77434',
    '77435',
    '77436',
    '77437',
    '77440',
    '77441',
    '77442',
    '77443',
    '77444',
    '77445',
    '77446',
    '77447',
    '77448',
    '77449',
    '77450',
    '77451',
    '77452',
    '77453',
    '77454',
    '77455',
    '77456',
    '77457',
    '77458',
    '77459',
    '77460',
    '77461',
    '77463',
    '77464',
    '77465',
    '77466',
    '77467',
    '77468',
    '77469',
    '77470',
    '77471',
    '77473',
    '77474',
    '77475',
    '77476',
    '77477',
    '77478',
    '77479',
    '77480',
    '77481',
    '77482',
    '77483',
    '77484',
    '77485',
    '77486',
    '77487',
    '77488',
    '77489',
    '77491',
    '77492',
    '77493',
    '77494',
    '77496',
    '77497',
    '77498',
    '77501',
    '77502',
    '77503',
    '77504',
    '77505',
    '77506',
    '77507',
    '77508',
    '77510',
    '77511',
    '77512',
    '77514',
    '77515',
    '77516',
    '77517',
    '77518',
    '77519',
    '77520',
    '77521',
    '77522',
    '77523',
    '77530',
    '77531',
    '77532',
    '77533',
    '77534',
    '77535',
    '77536',
    '77538',
    '77539',
    '77541',
    '77542',
    '77545',
    '77546',
    '77547',
    '77549',
    '77550',
    '77551',
    '77552',
    '77553',
    '77554',
    '77555',
    '77560',
    '77561',
    '77562',
    '77563',
    '77564',
    '77565',
    '77566',
    '77568',
    '77571',
    '77572',
    '77573',
    '77574',
    '77575',
    '77577',
    '77578',
    '77580',
    '77581',
    '77582',
    '77583',
    '77584',
    '77585',
    '77586',
    '77587',
    '77588',
    '77590',
    '77591',
    '77592',
    '77597',
    '77598',
    '77611',
    '77612',
    '77613',
    '77614',
    '77615',
    '77616',
    '77617',
    '77619',
    '77622',
    '77623',
    '77624',
    '77625',
    '77626',
    '77627',
    '77629',
    '77630',
    '77631',
    '77632',
    '77639',
    '77640',
    '77641',
    '77642',
    '77643',
    '77650',
    '77651',
    '77655',
    '77656',
    '77657',
    '77659',
    '77660',
    '77661',
    '77662',
    '77663',
    '77664',
    '77665',
    '77670',
    '77701',
    '77702',
    '77703',
    '77704',
    '77705',
    '77706',
    '77707',
    '77708',
    '77710',
    '77713',
    '77720',
    '77725',
    '77726',
    '77801',
    '77802',
    '77803',
    '77805',
    '77806',
    '77807',
    '77808',
    '77830',
    '77831',
    '77833',
    '77834',
    '77835',
    '77836',
    '77837',
    '77838',
    '77840',
    '77841',
    '77842',
    '77843',
    '77844',
    '77845',
    '77850',
    '77852',
    '77853',
    '77855',
    '77856',
    '77857',
    '77859',
    '77861',
    '77862',
    '77863',
    '77864',
    '77865',
    '77866',
    '77867',
    '77868',
    '77870',
    '77871',
    '77872',
    '77873',
    '77875',
    '77876',
    '77878',
    '77879',
    '77880',
    '77881',
    '77882',
    '77901',
    '77902',
    '77903',
    '77904',
    '77905',
    '77950',
    '77951',
    '77954',
    '77957',
    '77960',
    '77961',
    '77962',
    '77963',
    '77964',
    '77967',
    '77968',
    '77969',
    '77970',
    '77971',
    '77973',
    '77974',
    '77975',
    '77976',
    '77977',
    '77978',
    '77979',
    '77982',
    '77983',
    '77984',
    '77986',
    '77987',
    '77988',
    '77989',
    '77990',
    '77991',
    '77993',
    '77994',
    '77995',
    '78001',
    '78002',
    '78003',
    '78004',
    '78005',
    '78006',
    '78007',
    '78008',
    '78009',
    '78010',
    '78011',
    '78012',
    '78013',
    '78014',
    '78015',
    '78016',
    '78017',
    '78019',
    '78021',
    '78022',
    '78023',
    '78024',
    '78025',
    '78026',
    '78027',
    '78028',
    '78029',
    '78039',
    '78040',
    '78041',
    '78042',
    '78043',
    '78044',
    '78045',
    '78046',
    '78050',
    '78052',
    '78054',
    '78055',
    '78056',
    '78057',
    '78058',
    '78059',
    '78060',
    '78061',
    '78062',
    '78063',
    '78064',
    '78065',
    '78066',
    '78067',
    '78069',
    '78070',
    '78071',
    '78072',
    '78073',
    '78074',
    '78075',
    '78076',
    '78101',
    '78102',
    '78104',
    '78107',
    '78108',
    '78109',
    '78111',
    '78112',
    '78113',
    '78114',
    '78115',
    '78116',
    '78117',
    '78118',
    '78119',
    '78121',
    '78122',
    '78123',
    '78124',
    '78125',
    '78130',
    '78131',
    '78132',
    '78133',
    '78135',
    '78140',
    '78141',
    '78142',
    '78143',
    '78144',
    '78145',
    '78146',
    '78147',
    '78148',
    '78150',
    '78151',
    '78152',
    '78154',
    '78155',
    '78156',
    '78159',
    '78160',
    '78161',
    '78162',
    '78163',
    '78164',
    '78201',
    '78202',
    '78203',
    '78204',
    '78205',
    '78206',
    '78207',
    '78208',
    '78209',
    '78210',
    '78211',
    '78212',
    '78213',
    '78214',
    '78215',
    '78216',
    '78217',
    '78218',
    '78219',
    '78220',
    '78221',
    '78222',
    '78223',
    '78224',
    '78225',
    '78226',
    '78227',
    '78228',
    '78229',
    '78230',
    '78231',
    '78232',
    '78233',
    '78234',
    '78235',
    '78236',
    '78237',
    '78238',
    '78239',
    '78240',
    '78241',
    '78242',
    '78243',
    '78244',
    '78245',
    '78246',
    '78247',
    '78248',
    '78249',
    '78250',
    '78251',
    '78252',
    '78253',
    '78254',
    '78255',
    '78256',
    '78257',
    '78258',
    '78259',
    '78260',
    '78261',
    '78263',
    '78264',
    '78265',
    '78266',
    '78268',
    '78269',
    '78270',
    '78278',
    '78279',
    '78280',
    '78283',
    '78284',
    '78285',
    '78288',
    '78289',
    '78291',
    '78292',
    '78293',
    '78294',
    '78295',
    '78296',
    '78297',
    '78298',
    '78299',
    '78330',
    '78332',
    '78333',
    '78335',
    '78336',
    '78338',
    '78339',
    '78340',
    '78341',
    '78342',
    '78343',
    '78344',
    '78347',
    '78349',
    '78350',
    '78351',
    '78352',
    '78353',
    '78355',
    '78357',
    '78358',
    '78359',
    '78360',
    '78361',
    '78362',
    '78363',
    '78364',
    '78368',
    '78369',
    '78370',
    '78371',
    '78372',
    '78373',
    '78374',
    '78375',
    '78376',
    '78377',
    '78379',
    '78380',
    '78381',
    '78382',
    '78383',
    '78384',
    '78385',
    '78387',
    '78389',
    '78390',
    '78391',
    '78393',
    '78401',
    '78402',
    '78403',
    '78404',
    '78405',
    '78406',
    '78407',
    '78408',
    '78409',
    '78410',
    '78411',
    '78412',
    '78413',
    '78414',
    '78415',
    '78416',
    '78417',
    '78418',
    '78419',
    '78426',
    '78427',
    '78460',
    '78463',
    '78465',
    '78466',
    '78467',
    '78468',
    '78469',
    '78472',
    '78480',
    '78501',
    '78502',
    '78503',
    '78504',
    '78505',
    '78516',
    '78520',
    '78521',
    '78522',
    '78523',
    '78526',
    '78535',
    '78536',
    '78537',
    '78538',
    '78539',
    '78540',
    '78541',
    '78542',
    '78543',
    '78545',
    '78547',
    '78548',
    '78549',
    '78550',
    '78551',
    '78552',
    '78553',
    '78557',
    '78558',
    '78559',
    '78560',
    '78561',
    '78562',
    '78563',
    '78564',
    '78565',
    '78566',
    '78567',
    '78568',
    '78569',
    '78570',
    '78572',
    '78573',
    '78574',
    '78575',
    '78576',
    '78577',
    '78578',
    '78579',
    '78580',
    '78582',
    '78583',
    '78584',
    '78585',
    '78586',
    '78588',
    '78589',
    '78590',
    '78591',
    '78592',
    '78593',
    '78594',
    '78595',
    '78596',
    '78597',
    '78598',
    '78599',
    '78602',
    '78604',
    '78605',
    '78606',
    '78607',
    '78608',
    '78609',
    '78610',
    '78611',
    '78612',
    '78613',
    '78614',
    '78615',
    '78616',
    '78617',
    '78618',
    '78619',
    '78620',
    '78621',
    '78622',
    '78623',
    '78624',
    '78626',
    '78627',
    '78628',
    '78629',
    '78630',
    '78631',
    '78632',
    '78633',
    '78634',
    '78635',
    '78636',
    '78638',
    '78639',
    '78640',
    '78641',
    '78642',
    '78643',
    '78644',
    '78645',
    '78646',
    '78648',
    '78650',
    '78651',
    '78652',
    '78653',
    '78654',
    '78655',
    '78656',
    '78657',
    '78658',
    '78659',
    '78660',
    '78661',
    '78662',
    '78663',
    '78664',
    '78665',
    '78666',
    '78667',
    '78669',
    '78670',
    '78671',
    '78672',
    '78673',
    '78674',
    '78675',
    '78676',
    '78677',
    '78680',
    '78681',
    '78682',
    '78683',
    '78691',
    '78701',
    '78702',
    '78703',
    '78704',
    '78705',
    '78708',
    '78709',
    '78710',
    '78711',
    '78712',
    '78713',
    '78714',
    '78715',
    '78716',
    '78717',
    '78718',
    '78719',
    '78720',
    '78721',
    '78722',
    '78723',
    '78724',
    '78725',
    '78726',
    '78727',
    '78728',
    '78729',
    '78730',
    '78731',
    '78732',
    '78733',
    '78734',
    '78735',
    '78736',
    '78737',
    '78738',
    '78739',
    '78741',
    '78742',
    '78744',
    '78745',
    '78746',
    '78747',
    '78748',
    '78749',
    '78750',
    '78751',
    '78752',
    '78753',
    '78754',
    '78755',
    '78756',
    '78757',
    '78758',
    '78759',
    '78760',
    '78761',
    '78762',
    '78763',
    '78764',
    '78765',
    '78766',
    '78767',
    '78768',
    '78772',
    '78773',
    '78774',
    '78778',
    '78779',
    '78783',
    '78799',
    '78801',
    '78802',
    '78827',
    '78828',
    '78829',
    '78830',
    '78832',
    '78833',
    '78834',
    '78836',
    '78837',
    '78838',
    '78839',
    '78840',
    '78841',
    '78842',
    '78843',
    '78847',
    '78850',
    '78851',
    '78852',
    '78853',
    '78860',
    '78861',
    '78870',
    '78871',
    '78872',
    '78873',
    '78877',
    '78879',
    '78880',
    '78881',
    '78883',
    '78884',
    '78885',
    '78886',
    '78931',
    '78932',
    '78933',
    '78934',
    '78935',
    '78938',
    '78940',
    '78941',
    '78942',
    '78943',
    '78944',
    '78945',
    '78946',
    '78947',
    '78948',
    '78949',
    '78950',
    '78951',
    '78952',
    '78953',
    '78954',
    '78956',
    '78957',
    '78959',
    '78960',
    '78961',
    '78962',
    '78963',
    '79001',
    '79002',
    '79003',
    '79005',
    '79007',
    '79008',
    '79009',
    '79010',
    '79011',
    '79012',
    '79013',
    '79014',
    '79015',
    '79016',
    '79018',
    '79019',
    '79021',
    '79022',
    '79024',
    '79025',
    '79027',
    '79029',
    '79031',
    '79032',
    '79033',
    '79034',
    '79035',
    '79036',
    '79039',
    '79040',
    '79041',
    '79042',
    '79043',
    '79044',
    '79045',
    '79046',
    '79051',
    '79052',
    '79053',
    '79054',
    '79056',
    '79057',
    '79058',
    '79059',
    '79061',
    '79062',
    '79063',
    '79064',
    '79065',
    '79066',
    '79068',
    '79070',
    '79072',
    '79073',
    '79077',
    '79078',
    '79079',
    '79080',
    '79081',
    '79082',
    '79083',
    '79084',
    '79085',
    '79086',
    '79087',
    '79088',
    '79091',
    '79092',
    '79093',
    '79094',
    '79095',
    '79096',
    '79097',
    '79098',
    '79101',
    '79102',
    '79103',
    '79104',
    '79105',
    '79106',
    '79107',
    '79108',
    '79109',
    '79110',
    '79111',
    '79114',
    '79116',
    '79117',
    '79118',
    '79119',
    '79120',
    '79121',
    '79124',
    '79159',
    '79166',
    '79168',
    '79172',
    '79174',
    '79178',
    '79185',
    '79189',
    '79201',
    '79220',
    '79221',
    '79223',
    '79225',
    '79226',
    '79227',
    '79229',
    '79230',
    '79231',
    '79233',
    '79234',
    '79235',
    '79236',
    '79237',
    '79239',
    '79240',
    '79241',
    '79243',
    '79244',
    '79245',
    '79247',
    '79248',
    '79250',
    '79251',
    '79252',
    '79255',
    '79256',
    '79257',
    '79258',
    '79259',
    '79261',
    '79311',
    '79312',
    '79313',
    '79314',
    '79316',
    '79322',
    '79323',
    '79324',
    '79325',
    '79326',
    '79329',
    '79330',
    '79331',
    '79336',
    '79338',
    '79339',
    '79342',
    '79343',
    '79344',
    '79345',
    '79346',
    '79347',
    '79350',
    '79351',
    '79353',
    '79355',
    '79356',
    '79357',
    '79358',
    '79359',
    '79360',
    '79363',
    '79364',
    '79366',
    '79367',
    '79369',
    '79370',
    '79371',
    '79372',
    '79373',
    '79376',
    '79377',
    '79378',
    '79379',
    '79380',
    '79381',
    '79382',
    '79383',
    '79401',
    '79402',
    '79403',
    '79404',
    '79406',
    '79407',
    '79408',
    '79409',
    '79410',
    '79411',
    '79412',
    '79413',
    '79414',
    '79415',
    '79416',
    '79423',
    '79424',
    '79430',
    '79452',
    '79453',
    '79457',
    '79464',
    '79490',
    '79491',
    '79493',
    '79499',
    '79501',
    '79502',
    '79503',
    '79504',
    '79505',
    '79506',
    '79508',
    '79510',
    '79511',
    '79512',
    '79516',
    '79517',
    '79518',
    '79519',
    '79520',
    '79521',
    '79525',
    '79526',
    '79527',
    '79528',
    '79529',
    '79530',
    '79532',
    '79533',
    '79534',
    '79535',
    '79536',
    '79537',
    '79538',
    '79539',
    '79540',
    '79541',
    '79543',
    '79544',
    '79545',
    '79546',
    '79547',
    '79548',
    '79549',
    '79550',
    '79553',
    '79556',
    '79560',
    '79561',
    '79562',
    '79563',
    '79565',
    '79566',
    '79567',
    '79601',
    '79602',
    '79603',
    '79604',
    '79605',
    '79606',
    '79607',
    '79608',
    '79697',
    '79698',
    '79699',
    '79701',
    '79702',
    '79703',
    '79704',
    '79705',
    '79706',
    '79707',
    '79708',
    '79710',
    '79711',
    '79712',
    '79713',
    '79714',
    '79718',
    '79719',
    '79720',
    '79721',
    '79730',
    '79731',
    '79733',
    '79734',
    '79735',
    '79738',
    '79739',
    '79740',
    '79741',
    '79742',
    '79743',
    '79744',
    '79745',
    '79748',
    '79749',
    '79752',
    '79754',
    '79755',
    '79756',
    '79758',
    '79759',
    '79760',
    '79761',
    '79762',
    '79763',
    '79764',
    '79765',
    '79766',
    '79768',
    '79769',
    '79770',
    '79772',
    '79776',
    '79777',
    '79778',
    '79780',
    '79781',
    '79782',
    '79783',
    '79785',
    '79786',
    '79788',
    '79789',
    '79821',
    '79830',
    '79831',
    '79832',
    '79834',
    '79835',
    '79836',
    '79837',
    '79838',
    '79839',
    '79842',
    '79843',
    '79845',
    '79846',
    '79847',
    '79848',
    '79849',
    '79851',
    '79852',
    '79853',
    '79854',
    '79855',
    '79901',
    '79902',
    '79903',
    '79904',
    '79905',
    '79906',
    '79907',
    '79908',
    '79910',
    '79911',
    '79912',
    '79913',
    '79914',
    '79915',
    '79916',
    '79917',
    '79918',
    '79920',
    '79922',
    '79923',
    '79924',
    '79925',
    '79926',
    '79927',
    '79928',
    '79929',
    '79930',
    '79931',
    '79932',
    '79934',
    '79935',
    '79936',
    '79937',
    '79938',
    '79940',
    '79941',
    '79942',
    '79943',
    '79944',
    '79945',
    '79946',
    '79947',
    '79948',
    '79949',
    '79950',
    '79951',
    '79952',
    '79953',
    '79954',
    '79955',
    '79958',
    '79960',
    '79961',
    '79968',
    '79976',
    '79978',
    '79980',
    '79995',
    '79996',
    '79997',
    '79998',
    '79999',
    '88510',
    '88511',
    '88512',
    '88513',
    '88514',
    '88515',
    '88517',
    '88518',
    '88519',
    '88520',
    '88521',
    '88523',
    '88524',
    '88525',
    '88526',
    '88527',
    '88528',
    '88529',
    '88530',
    '88531',
    '88532',
    '88533',
    '88534',
    '88535',
    '88536',
    '88538',
    '88539',
    '88540',
    '88541',
    '88542',
    '88543',
    '88544',
    '88545',
    '88546',
    '88547',
    '88548',
    '88549',
    '88550',
    '88553',
    '88554',
    '88555',
    '88556',
    '88557',
    '88558',
    '88559',
    '88560',
    '88561',
    '88562',
    '88563',
    '88565',
    '88566',
    '88567',
    '88568',
    '88569',
    '88570',
    '88571',
    '88572',
    '88573',
    '88574',
    '88575',
    '88576',
    '88577',
    '88578',
    '88579',
    '88580',
    '88581',
    '88582',
    '88583',
    '88584',
    '88585',
    '88586',
    '88587',
    '88588',
    '88589',
    '88590',
    '88595',
  ],
  CO: [
    '80001',
    '80002',
    '80003',
    '80004',
    '80005',
    '80006',
    '80007',
    '80010',
    '80011',
    '80012',
    '80013',
    '80014',
    '80015',
    '80016',
    '80017',
    '80018',
    '80019',
    '80020',
    '80021',
    '80022',
    '80023',
    '80024',
    '80025',
    '80026',
    '80027',
    '80030',
    '80031',
    '80033',
    '80034',
    '80035',
    '80036',
    '80037',
    '80038',
    '80040',
    '80041',
    '80042',
    '80044',
    '80045',
    '80046',
    '80047',
    '80101',
    '80102',
    '80103',
    '80104',
    '80105',
    '80106',
    '80107',
    '80108',
    '80109',
    '80110',
    '80111',
    '80112',
    '80113',
    '80116',
    '80117',
    '80118',
    '80120',
    '80121',
    '80122',
    '80123',
    '80124',
    '80125',
    '80126',
    '80127',
    '80128',
    '80129',
    '80130',
    '80131',
    '80132',
    '80133',
    '80134',
    '80135',
    '80136',
    '80137',
    '80138',
    '80150',
    '80151',
    '80155',
    '80160',
    '80161',
    '80162',
    '80163',
    '80165',
    '80166',
    '80201',
    '80202',
    '80203',
    '80204',
    '80205',
    '80206',
    '80207',
    '80208',
    '80209',
    '80210',
    '80211',
    '80212',
    '80214',
    '80215',
    '80216',
    '80217',
    '80218',
    '80219',
    '80220',
    '80221',
    '80222',
    '80223',
    '80224',
    '80225',
    '80226',
    '80227',
    '80228',
    '80229',
    '80230',
    '80231',
    '80232',
    '80233',
    '80234',
    '80235',
    '80236',
    '80237',
    '80238',
    '80239',
    '80241',
    '80243',
    '80244',
    '80246',
    '80247',
    '80248',
    '80249',
    '80250',
    '80251',
    '80256',
    '80257',
    '80259',
    '80260',
    '80261',
    '80262',
    '80263',
    '80264',
    '80265',
    '80266',
    '80271',
    '80273',
    '80274',
    '80281',
    '80290',
    '80291',
    '80293',
    '80294',
    '80299',
    '80301',
    '80302',
    '80303',
    '80304',
    '80305',
    '80306',
    '80307',
    '80308',
    '80309',
    '80310',
    '80314',
    '80401',
    '80402',
    '80403',
    '80419',
    '80420',
    '80421',
    '80422',
    '80423',
    '80424',
    '80425',
    '80426',
    '80427',
    '80428',
    '80429',
    '80430',
    '80432',
    '80433',
    '80434',
    '80435',
    '80436',
    '80437',
    '80438',
    '80439',
    '80440',
    '80442',
    '80443',
    '80444',
    '80446',
    '80447',
    '80448',
    '80449',
    '80451',
    '80452',
    '80453',
    '80454',
    '80455',
    '80456',
    '80457',
    '80459',
    '80461',
    '80463',
    '80465',
    '80466',
    '80467',
    '80468',
    '80469',
    '80470',
    '80471',
    '80473',
    '80474',
    '80475',
    '80476',
    '80477',
    '80478',
    '80479',
    '80480',
    '80481',
    '80482',
    '80483',
    '80487',
    '80488',
    '80497',
    '80498',
    '80501',
    '80502',
    '80503',
    '80504',
    '80510',
    '80511',
    '80512',
    '80513',
    '80514',
    '80515',
    '80516',
    '80517',
    '80520',
    '80521',
    '80522',
    '80523',
    '80524',
    '80525',
    '80526',
    '80527',
    '80528',
    '80530',
    '80532',
    '80533',
    '80534',
    '80535',
    '80536',
    '80537',
    '80538',
    '80539',
    '80540',
    '80541',
    '80542',
    '80543',
    '80544',
    '80545',
    '80546',
    '80547',
    '80549',
    '80550',
    '80551',
    '80553',
    '80601',
    '80602',
    '80603',
    '80610',
    '80611',
    '80612',
    '80614',
    '80615',
    '80620',
    '80621',
    '80622',
    '80623',
    '80624',
    '80631',
    '80632',
    '80633',
    '80634',
    '80638',
    '80639',
    '80640',
    '80642',
    '80643',
    '80644',
    '80645',
    '80646',
    '80648',
    '80649',
    '80650',
    '80651',
    '80652',
    '80653',
    '80654',
    '80701',
    '80705',
    '80720',
    '80721',
    '80722',
    '80723',
    '80726',
    '80727',
    '80728',
    '80729',
    '80731',
    '80732',
    '80733',
    '80734',
    '80735',
    '80736',
    '80737',
    '80740',
    '80741',
    '80742',
    '80743',
    '80744',
    '80745',
    '80746',
    '80747',
    '80749',
    '80750',
    '80751',
    '80754',
    '80755',
    '80757',
    '80758',
    '80759',
    '80801',
    '80802',
    '80804',
    '80805',
    '80807',
    '80808',
    '80809',
    '80810',
    '80812',
    '80813',
    '80814',
    '80815',
    '80816',
    '80817',
    '80818',
    '80819',
    '80820',
    '80821',
    '80822',
    '80823',
    '80824',
    '80825',
    '80826',
    '80827',
    '80828',
    '80829',
    '80830',
    '80831',
    '80832',
    '80833',
    '80834',
    '80835',
    '80836',
    '80840',
    '80841',
    '80860',
    '80861',
    '80862',
    '80863',
    '80864',
    '80866',
    '80901',
    '80902',
    '80903',
    '80904',
    '80905',
    '80906',
    '80907',
    '80908',
    '80909',
    '80910',
    '80911',
    '80912',
    '80913',
    '80914',
    '80915',
    '80916',
    '80917',
    '80918',
    '80919',
    '80920',
    '80921',
    '80922',
    '80923',
    '80924',
    '80925',
    '80926',
    '80927',
    '80928',
    '80929',
    '80930',
    '80931',
    '80932',
    '80933',
    '80934',
    '80935',
    '80936',
    '80937',
    '80938',
    '80939',
    '80941',
    '80942',
    '80946',
    '80947',
    '80949',
    '80950',
    '80951',
    '80960',
    '80962',
    '80970',
    '80977',
    '80995',
    '80997',
    '81001',
    '81002',
    '81003',
    '81004',
    '81005',
    '81006',
    '81007',
    '81008',
    '81009',
    '81010',
    '81011',
    '81012',
    '81019',
    '81020',
    '81021',
    '81022',
    '81023',
    '81024',
    '81025',
    '81027',
    '81029',
    '81030',
    '81033',
    '81034',
    '81036',
    '81038',
    '81039',
    '81040',
    '81041',
    '81043',
    '81044',
    '81045',
    '81046',
    '81047',
    '81049',
    '81050',
    '81052',
    '81054',
    '81055',
    '81057',
    '81058',
    '81059',
    '81062',
    '81063',
    '81064',
    '81067',
    '81069',
    '81071',
    '81073',
    '81076',
    '81077',
    '81081',
    '81082',
    '81084',
    '81087',
    '81089',
    '81090',
    '81091',
    '81092',
    '81101',
    '81102',
    '81120',
    '81121',
    '81122',
    '81123',
    '81124',
    '81125',
    '81126',
    '81128',
    '81129',
    '81130',
    '81131',
    '81132',
    '81133',
    '81135',
    '81136',
    '81137',
    '81138',
    '81140',
    '81141',
    '81143',
    '81144',
    '81146',
    '81147',
    '81148',
    '81149',
    '81151',
    '81152',
    '81154',
    '81155',
    '81157',
    '81201',
    '81210',
    '81211',
    '81212',
    '81215',
    '81220',
    '81221',
    '81222',
    '81223',
    '81224',
    '81225',
    '81226',
    '81227',
    '81228',
    '81230',
    '81231',
    '81232',
    '81233',
    '81235',
    '81236',
    '81237',
    '81239',
    '81240',
    '81241',
    '81242',
    '81243',
    '81244',
    '81248',
    '81251',
    '81252',
    '81253',
    '81290',
    '81301',
    '81302',
    '81303',
    '81320',
    '81321',
    '81323',
    '81324',
    '81325',
    '81326',
    '81327',
    '81328',
    '81329',
    '81330',
    '81331',
    '81332',
    '81334',
    '81335',
    '81401',
    '81402',
    '81403',
    '81410',
    '81411',
    '81413',
    '81414',
    '81415',
    '81416',
    '81418',
    '81419',
    '81420',
    '81422',
    '81423',
    '81424',
    '81425',
    '81426',
    '81427',
    '81428',
    '81429',
    '81430',
    '81431',
    '81432',
    '81433',
    '81434',
    '81435',
    '81501',
    '81502',
    '81503',
    '81504',
    '81505',
    '81506',
    '81507',
    '81520',
    '81521',
    '81522',
    '81523',
    '81524',
    '81525',
    '81526',
    '81527',
    '81601',
    '81602',
    '81610',
    '81611',
    '81612',
    '81615',
    '81620',
    '81621',
    '81623',
    '81624',
    '81625',
    '81626',
    '81630',
    '81631',
    '81632',
    '81633',
    '81635',
    '81636',
    '81637',
    '81638',
    '81639',
    '81640',
    '81641',
    '81642',
    '81643',
    '81645',
    '81646',
    '81647',
    '81648',
    '81649',
    '81650',
    '81652',
    '81653',
    '81654',
    '81655',
    '81656',
    '81657',
    '81658',
  ],
  WY: [
    '82001',
    '82002',
    '82003',
    '82005',
    '82006',
    '82007',
    '82009',
    '82010',
    '82050',
    '82051',
    '82052',
    '82053',
    '82054',
    '82055',
    '82058',
    '82059',
    '82060',
    '82061',
    '82063',
    '82070',
    '82071',
    '82072',
    '82073',
    '82081',
    '82082',
    '82083',
    '82084',
    '82190',
    '82201',
    '82210',
    '82212',
    '82213',
    '82214',
    '82215',
    '82217',
    '82218',
    '82219',
    '82221',
    '82222',
    '82223',
    '82224',
    '82225',
    '82227',
    '82229',
    '82240',
    '82242',
    '82243',
    '82244',
    '82301',
    '82310',
    '82321',
    '82322',
    '82323',
    '82324',
    '82325',
    '82327',
    '82329',
    '82331',
    '82332',
    '82334',
    '82335',
    '82336',
    '82401',
    '82410',
    '82411',
    '82412',
    '82414',
    '82420',
    '82421',
    '82422',
    '82423',
    '82426',
    '82428',
    '82430',
    '82431',
    '82432',
    '82433',
    '82434',
    '82435',
    '82440',
    '82441',
    '82442',
    '82443',
    '82450',
    '82501',
    '82510',
    '82512',
    '82513',
    '82514',
    '82515',
    '82516',
    '82520',
    '82523',
    '82524',
    '82601',
    '82602',
    '82604',
    '82605',
    '82609',
    '82615',
    '82620',
    '82630',
    '82633',
    '82635',
    '82636',
    '82637',
    '82638',
    '82639',
    '82640',
    '82642',
    '82643',
    '82644',
    '82646',
    '82648',
    '82649',
    '82701',
    '82710',
    '82711',
    '82712',
    '82714',
    '82715',
    '82716',
    '82717',
    '82718',
    '82720',
    '82721',
    '82723',
    '82725',
    '82727',
    '82729',
    '82730',
    '82731',
    '82732',
    '82801',
    '82831',
    '82832',
    '82833',
    '82834',
    '82835',
    '82836',
    '82837',
    '82838',
    '82839',
    '82840',
    '82842',
    '82844',
    '82845',
    '82901',
    '82902',
    '82922',
    '82923',
    '82925',
    '82929',
    '82930',
    '82931',
    '82932',
    '82933',
    '82934',
    '82935',
    '82936',
    '82937',
    '82938',
    '82939',
    '82941',
    '82942',
    '82943',
    '82944',
    '82945',
    '83001',
    '83002',
    '83011',
    '83012',
    '83013',
    '83014',
    '83025',
    '83101',
    '83110',
    '83111',
    '83112',
    '83113',
    '83114',
    '83115',
    '83116',
    '83118',
    '83119',
    '83120',
    '83121',
    '83122',
    '83123',
    '83124',
    '83126',
    '83127',
    '83128',
    '83414',
  ],
  ID: [
    '83201',
    '83202',
    '83203',
    '83204',
    '83205',
    '83206',
    '83209',
    '83210',
    '83211',
    '83212',
    '83213',
    '83214',
    '83215',
    '83217',
    '83218',
    '83220',
    '83221',
    '83223',
    '83226',
    '83227',
    '83228',
    '83229',
    '83230',
    '83232',
    '83233',
    '83234',
    '83235',
    '83236',
    '83237',
    '83238',
    '83239',
    '83241',
    '83243',
    '83244',
    '83245',
    '83246',
    '83250',
    '83251',
    '83252',
    '83253',
    '83254',
    '83255',
    '83256',
    '83261',
    '83262',
    '83263',
    '83271',
    '83272',
    '83274',
    '83276',
    '83277',
    '83278',
    '83281',
    '83283',
    '83285',
    '83286',
    '83287',
    '83301',
    '83302',
    '83303',
    '83311',
    '83312',
    '83313',
    '83314',
    '83316',
    '83318',
    '83320',
    '83321',
    '83322',
    '83323',
    '83324',
    '83325',
    '83327',
    '83328',
    '83330',
    '83332',
    '83333',
    '83334',
    '83335',
    '83336',
    '83337',
    '83338',
    '83340',
    '83341',
    '83342',
    '83343',
    '83344',
    '83346',
    '83347',
    '83348',
    '83349',
    '83350',
    '83352',
    '83353',
    '83354',
    '83355',
    '83401',
    '83402',
    '83403',
    '83404',
    '83405',
    '83406',
    '83415',
    '83420',
    '83421',
    '83422',
    '83423',
    '83424',
    '83425',
    '83427',
    '83428',
    '83429',
    '83431',
    '83433',
    '83434',
    '83435',
    '83436',
    '83438',
    '83440',
    '83441',
    '83442',
    '83443',
    '83444',
    '83445',
    '83446',
    '83448',
    '83449',
    '83450',
    '83451',
    '83452',
    '83454',
    '83455',
    '83460',
    '83462',
    '83463',
    '83464',
    '83465',
    '83466',
    '83467',
    '83468',
    '83469',
    '83501',
    '83520',
    '83522',
    '83523',
    '83524',
    '83525',
    '83526',
    '83530',
    '83531',
    '83533',
    '83535',
    '83536',
    '83537',
    '83539',
    '83540',
    '83541',
    '83542',
    '83543',
    '83544',
    '83545',
    '83546',
    '83547',
    '83548',
    '83549',
    '83552',
    '83553',
    '83554',
    '83555',
    '83602',
    '83604',
    '83605',
    '83606',
    '83607',
    '83610',
    '83611',
    '83612',
    '83615',
    '83616',
    '83617',
    '83619',
    '83622',
    '83623',
    '83624',
    '83626',
    '83627',
    '83628',
    '83629',
    '83630',
    '83631',
    '83632',
    '83633',
    '83634',
    '83635',
    '83636',
    '83637',
    '83638',
    '83639',
    '83641',
    '83642',
    '83643',
    '83644',
    '83645',
    '83646',
    '83647',
    '83648',
    '83650',
    '83651',
    '83652',
    '83653',
    '83654',
    '83655',
    '83656',
    '83657',
    '83660',
    '83661',
    '83666',
    '83669',
    '83670',
    '83671',
    '83672',
    '83676',
    '83677',
    '83680',
    '83686',
    '83687',
    '83701',
    '83702',
    '83703',
    '83704',
    '83705',
    '83706',
    '83707',
    '83708',
    '83709',
    '83711',
    '83712',
    '83713',
    '83714',
    '83715',
    '83716',
    '83717',
    '83719',
    '83720',
    '83722',
    '83724',
    '83725',
    '83726',
    '83728',
    '83729',
    '83732',
    '83735',
    '83756',
    '83799',
    '83801',
    '83802',
    '83803',
    '83804',
    '83805',
    '83806',
    '83808',
    '83809',
    '83810',
    '83811',
    '83812',
    '83813',
    '83814',
    '83815',
    '83816',
    '83821',
    '83822',
    '83823',
    '83824',
    '83825',
    '83826',
    '83827',
    '83830',
    '83832',
    '83833',
    '83834',
    '83835',
    '83836',
    '83837',
    '83839',
    '83840',
    '83841',
    '83842',
    '83843',
    '83844',
    '83845',
    '83846',
    '83847',
    '83848',
    '83849',
    '83850',
    '83851',
    '83852',
    '83853',
    '83854',
    '83855',
    '83856',
    '83857',
    '83858',
    '83860',
    '83861',
    '83864',
    '83865',
    '83866',
    '83867',
    '83868',
    '83869',
    '83870',
    '83871',
    '83872',
    '83873',
    '83874',
    '83876',
    '83877',
  ],
  UT: [
    '84001',
    '84002',
    '84003',
    '84004',
    '84005',
    '84006',
    '84007',
    '84008',
    '84009',
    '84010',
    '84011',
    '84013',
    '84014',
    '84015',
    '84016',
    '84017',
    '84018',
    '84020',
    '84021',
    '84022',
    '84023',
    '84024',
    '84025',
    '84026',
    '84027',
    '84028',
    '84029',
    '84031',
    '84032',
    '84033',
    '84034',
    '84035',
    '84036',
    '84037',
    '84038',
    '84039',
    '84040',
    '84041',
    '84042',
    '84043',
    '84044',
    '84045',
    '84046',
    '84047',
    '84049',
    '84050',
    '84051',
    '84052',
    '84053',
    '84054',
    '84055',
    '84056',
    '84057',
    '84058',
    '84059',
    '84060',
    '84061',
    '84062',
    '84063',
    '84064',
    '84065',
    '84066',
    '84067',
    '84068',
    '84069',
    '84070',
    '84071',
    '84072',
    '84073',
    '84074',
    '84075',
    '84076',
    '84078',
    '84079',
    '84080',
    '84081',
    '84082',
    '84083',
    '84084',
    '84085',
    '84086',
    '84087',
    '84088',
    '84089',
    '84090',
    '84091',
    '84092',
    '84093',
    '84094',
    '84095',
    '84096',
    '84097',
    '84098',
    '84101',
    '84102',
    '84103',
    '84104',
    '84105',
    '84106',
    '84107',
    '84108',
    '84109',
    '84110',
    '84111',
    '84112',
    '84113',
    '84114',
    '84115',
    '84116',
    '84117',
    '84118',
    '84119',
    '84120',
    '84121',
    '84122',
    '84123',
    '84124',
    '84125',
    '84126',
    '84127',
    '84128',
    '84129',
    '84130',
    '84131',
    '84132',
    '84133',
    '84134',
    '84138',
    '84139',
    '84141',
    '84143',
    '84145',
    '84147',
    '84148',
    '84150',
    '84151',
    '84152',
    '84157',
    '84158',
    '84165',
    '84170',
    '84171',
    '84180',
    '84184',
    '84190',
    '84199',
    '84201',
    '84244',
    '84301',
    '84302',
    '84304',
    '84305',
    '84306',
    '84307',
    '84308',
    '84309',
    '84310',
    '84311',
    '84312',
    '84313',
    '84314',
    '84315',
    '84316',
    '84317',
    '84318',
    '84319',
    '84320',
    '84321',
    '84322',
    '84323',
    '84324',
    '84325',
    '84326',
    '84327',
    '84328',
    '84329',
    '84330',
    '84331',
    '84332',
    '84333',
    '84334',
    '84335',
    '84336',
    '84337',
    '84338',
    '84339',
    '84340',
    '84341',
    '84401',
    '84402',
    '84403',
    '84404',
    '84405',
    '84407',
    '84408',
    '84409',
    '84412',
    '84414',
    '84415',
    '84501',
    '84510',
    '84511',
    '84512',
    '84513',
    '84515',
    '84516',
    '84518',
    '84520',
    '84521',
    '84522',
    '84523',
    '84525',
    '84526',
    '84528',
    '84529',
    '84530',
    '84531',
    '84532',
    '84533',
    '84534',
    '84535',
    '84536',
    '84537',
    '84539',
    '84540',
    '84542',
    '84601',
    '84602',
    '84603',
    '84604',
    '84605',
    '84606',
    '84620',
    '84621',
    '84622',
    '84623',
    '84624',
    '84626',
    '84627',
    '84628',
    '84629',
    '84630',
    '84631',
    '84632',
    '84633',
    '84634',
    '84635',
    '84636',
    '84637',
    '84638',
    '84639',
    '84640',
    '84642',
    '84643',
    '84644',
    '84645',
    '84646',
    '84647',
    '84648',
    '84649',
    '84651',
    '84652',
    '84653',
    '84654',
    '84655',
    '84656',
    '84657',
    '84660',
    '84662',
    '84663',
    '84664',
    '84665',
    '84667',
    '84701',
    '84710',
    '84711',
    '84712',
    '84713',
    '84714',
    '84715',
    '84716',
    '84718',
    '84719',
    '84720',
    '84721',
    '84722',
    '84723',
    '84724',
    '84725',
    '84726',
    '84728',
    '84729',
    '84730',
    '84731',
    '84732',
    '84733',
    '84734',
    '84735',
    '84736',
    '84737',
    '84738',
    '84739',
    '84740',
    '84741',
    '84742',
    '84743',
    '84744',
    '84745',
    '84746',
    '84747',
    '84749',
    '84750',
    '84751',
    '84752',
    '84753',
    '84754',
    '84755',
    '84756',
    '84757',
    '84758',
    '84759',
    '84760',
    '84761',
    '84762',
    '84763',
    '84764',
    '84765',
    '84766',
    '84767',
    '84770',
    '84771',
    '84772',
    '84773',
    '84774',
    '84775',
    '84776',
    '84779',
    '84780',
    '84781',
    '84782',
    '84783',
    '84784',
    '84790',
    '84791',
  ],
  AZ: [
    '85001',
    '85002',
    '85003',
    '85004',
    '85005',
    '85006',
    '85007',
    '85008',
    '85009',
    '85010',
    '85011',
    '85012',
    '85013',
    '85014',
    '85015',
    '85016',
    '85017',
    '85018',
    '85019',
    '85020',
    '85021',
    '85022',
    '85023',
    '85024',
    '85026',
    '85027',
    '85028',
    '85029',
    '85030',
    '85031',
    '85032',
    '85033',
    '85034',
    '85035',
    '85036',
    '85037',
    '85038',
    '85039',
    '85040',
    '85041',
    '85042',
    '85043',
    '85044',
    '85045',
    '85046',
    '85048',
    '85050',
    '85051',
    '85053',
    '85054',
    '85060',
    '85061',
    '85062',
    '85063',
    '85064',
    '85065',
    '85066',
    '85067',
    '85068',
    '85069',
    '85070',
    '85071',
    '85072',
    '85073',
    '85074',
    '85075',
    '85076',
    '85078',
    '85079',
    '85080',
    '85082',
    '85083',
    '85085',
    '85086',
    '85087',
    '85117',
    '85118',
    '85119',
    '85120',
    '85121',
    '85122',
    '85123',
    '85127',
    '85128',
    '85130',
    '85131',
    '85132',
    '85135',
    '85137',
    '85138',
    '85139',
    '85140',
    '85141',
    '85142',
    '85143',
    '85145',
    '85147',
    '85172',
    '85173',
    '85178',
    '85190',
    '85191',
    '85192',
    '85193',
    '85194',
    '85201',
    '85202',
    '85203',
    '85204',
    '85205',
    '85206',
    '85207',
    '85208',
    '85209',
    '85210',
    '85211',
    '85212',
    '85213',
    '85214',
    '85215',
    '85216',
    '85224',
    '85225',
    '85226',
    '85233',
    '85234',
    '85236',
    '85244',
    '85246',
    '85248',
    '85249',
    '85250',
    '85251',
    '85252',
    '85253',
    '85254',
    '85255',
    '85256',
    '85257',
    '85258',
    '85259',
    '85260',
    '85261',
    '85262',
    '85263',
    '85264',
    '85266',
    '85267',
    '85268',
    '85269',
    '85271',
    '85274',
    '85275',
    '85277',
    '85280',
    '85281',
    '85282',
    '85283',
    '85284',
    '85285',
    '85286',
    '85287',
    '85295',
    '85296',
    '85297',
    '85298',
    '85299',
    '85301',
    '85302',
    '85303',
    '85304',
    '85305',
    '85306',
    '85307',
    '85308',
    '85309',
    '85310',
    '85311',
    '85312',
    '85318',
    '85320',
    '85321',
    '85322',
    '85323',
    '85324',
    '85325',
    '85326',
    '85327',
    '85328',
    '85329',
    '85331',
    '85332',
    '85333',
    '85334',
    '85335',
    '85336',
    '85337',
    '85338',
    '85339',
    '85340',
    '85341',
    '85342',
    '85343',
    '85344',
    '85345',
    '85346',
    '85347',
    '85348',
    '85349',
    '85350',
    '85351',
    '85352',
    '85353',
    '85354',
    '85355',
    '85356',
    '85357',
    '85358',
    '85359',
    '85360',
    '85361',
    '85362',
    '85363',
    '85364',
    '85365',
    '85366',
    '85367',
    '85369',
    '85371',
    '85372',
    '85373',
    '85374',
    '85375',
    '85376',
    '85377',
    '85378',
    '85379',
    '85380',
    '85381',
    '85382',
    '85383',
    '85385',
    '85387',
    '85388',
    '85390',
    '85392',
    '85395',
    '85396',
    '85501',
    '85502',
    '85530',
    '85531',
    '85532',
    '85533',
    '85534',
    '85535',
    '85536',
    '85539',
    '85540',
    '85541',
    '85542',
    '85543',
    '85544',
    '85545',
    '85546',
    '85547',
    '85548',
    '85550',
    '85551',
    '85552',
    '85553',
    '85554',
    '85601',
    '85602',
    '85603',
    '85605',
    '85606',
    '85607',
    '85608',
    '85609',
    '85610',
    '85611',
    '85613',
    '85614',
    '85615',
    '85616',
    '85617',
    '85618',
    '85619',
    '85620',
    '85621',
    '85622',
    '85623',
    '85624',
    '85625',
    '85626',
    '85627',
    '85628',
    '85629',
    '85630',
    '85631',
    '85632',
    '85633',
    '85634',
    '85635',
    '85636',
    '85637',
    '85638',
    '85639',
    '85640',
    '85641',
    '85643',
    '85644',
    '85645',
    '85646',
    '85648',
    '85650',
    '85652',
    '85653',
    '85654',
    '85655',
    '85658',
    '85670',
    '85671',
    '85701',
    '85702',
    '85703',
    '85704',
    '85705',
    '85706',
    '85707',
    '85708',
    '85709',
    '85710',
    '85711',
    '85712',
    '85713',
    '85714',
    '85715',
    '85716',
    '85717',
    '85718',
    '85719',
    '85720',
    '85721',
    '85722',
    '85723',
    '85724',
    '85725',
    '85726',
    '85728',
    '85730',
    '85731',
    '85732',
    '85733',
    '85734',
    '85735',
    '85736',
    '85737',
    '85738',
    '85739',
    '85740',
    '85741',
    '85742',
    '85743',
    '85744',
    '85745',
    '85746',
    '85747',
    '85748',
    '85749',
    '85750',
    '85751',
    '85752',
    '85754',
    '85755',
    '85756',
    '85757',
    '85775',
    '85901',
    '85902',
    '85911',
    '85912',
    '85920',
    '85922',
    '85923',
    '85924',
    '85925',
    '85926',
    '85927',
    '85928',
    '85929',
    '85930',
    '85931',
    '85932',
    '85933',
    '85934',
    '85935',
    '85936',
    '85937',
    '85938',
    '85939',
    '85940',
    '85941',
    '85942',
    '86001',
    '86002',
    '86003',
    '86004',
    '86005',
    '86011',
    '86015',
    '86016',
    '86017',
    '86018',
    '86020',
    '86021',
    '86022',
    '86023',
    '86024',
    '86025',
    '86028',
    '86029',
    '86030',
    '86031',
    '86032',
    '86033',
    '86034',
    '86035',
    '86036',
    '86038',
    '86039',
    '86040',
    '86042',
    '86043',
    '86044',
    '86045',
    '86046',
    '86047',
    '86052',
    '86053',
    '86054',
    '86301',
    '86302',
    '86303',
    '86304',
    '86305',
    '86312',
    '86313',
    '86314',
    '86315',
    '86320',
    '86321',
    '86322',
    '86323',
    '86324',
    '86325',
    '86326',
    '86327',
    '86329',
    '86331',
    '86332',
    '86333',
    '86334',
    '86335',
    '86336',
    '86337',
    '86338',
    '86339',
    '86340',
    '86341',
    '86342',
    '86343',
    '86351',
    '86401',
    '86402',
    '86403',
    '86404',
    '86405',
    '86406',
    '86409',
    '86411',
    '86412',
    '86413',
    '86426',
    '86427',
    '86429',
    '86430',
    '86431',
    '86432',
    '86433',
    '86434',
    '86435',
    '86436',
    '86437',
    '86438',
    '86439',
    '86440',
    '86441',
    '86442',
    '86443',
    '86444',
    '86445',
    '86446',
    '86502',
    '86503',
    '86504',
    '86505',
    '86506',
    '86507',
    '86508',
    '86510',
    '86511',
    '86512',
    '86514',
    '86515',
    '86520',
    '86535',
    '86538',
    '86540',
    '86544',
    '86545',
    '86547',
    '86556',
  ],
  NM: [
    '87001',
    '87002',
    '87004',
    '87005',
    '87006',
    '87007',
    '87008',
    '87009',
    '87010',
    '87011',
    '87012',
    '87013',
    '87014',
    '87015',
    '87016',
    '87017',
    '87018',
    '87020',
    '87021',
    '87022',
    '87023',
    '87024',
    '87025',
    '87026',
    '87027',
    '87028',
    '87029',
    '87031',
    '87032',
    '87034',
    '87035',
    '87036',
    '87037',
    '87038',
    '87040',
    '87041',
    '87042',
    '87043',
    '87044',
    '87045',
    '87046',
    '87047',
    '87048',
    '87049',
    '87051',
    '87052',
    '87053',
    '87056',
    '87059',
    '87060',
    '87061',
    '87062',
    '87063',
    '87064',
    '87068',
    '87070',
    '87072',
    '87083',
    '87101',
    '87102',
    '87103',
    '87104',
    '87105',
    '87106',
    '87107',
    '87108',
    '87109',
    '87110',
    '87111',
    '87112',
    '87113',
    '87114',
    '87115',
    '87116',
    '87117',
    '87119',
    '87120',
    '87121',
    '87122',
    '87123',
    '87124',
    '87125',
    '87131',
    '87144',
    '87151',
    '87153',
    '87154',
    '87158',
    '87174',
    '87176',
    '87181',
    '87184',
    '87185',
    '87187',
    '87190',
    '87191',
    '87192',
    '87193',
    '87194',
    '87195',
    '87196',
    '87197',
    '87198',
    '87199',
    '87301',
    '87302',
    '87305',
    '87310',
    '87311',
    '87312',
    '87313',
    '87315',
    '87316',
    '87317',
    '87319',
    '87320',
    '87321',
    '87322',
    '87323',
    '87325',
    '87326',
    '87327',
    '87328',
    '87347',
    '87357',
    '87364',
    '87365',
    '87375',
    '87401',
    '87402',
    '87410',
    '87412',
    '87413',
    '87415',
    '87416',
    '87417',
    '87418',
    '87419',
    '87420',
    '87421',
    '87455',
    '87461',
    '87499',
    '87501',
    '87502',
    '87503',
    '87504',
    '87505',
    '87506',
    '87507',
    '87508',
    '87509',
    '87510',
    '87511',
    '87512',
    '87513',
    '87514',
    '87515',
    '87516',
    '87517',
    '87518',
    '87519',
    '87520',
    '87521',
    '87522',
    '87523',
    '87524',
    '87525',
    '87527',
    '87528',
    '87529',
    '87530',
    '87531',
    '87532',
    '87533',
    '87535',
    '87537',
    '87538',
    '87539',
    '87540',
    '87543',
    '87544',
    '87545',
    '87547',
    '87548',
    '87549',
    '87551',
    '87552',
    '87553',
    '87554',
    '87556',
    '87557',
    '87558',
    '87560',
    '87562',
    '87564',
    '87565',
    '87566',
    '87567',
    '87569',
    '87571',
    '87573',
    '87574',
    '87575',
    '87576',
    '87577',
    '87578',
    '87579',
    '87580',
    '87581',
    '87582',
    '87583',
    '87592',
    '87594',
    '87654',
    '87701',
    '87710',
    '87711',
    '87712',
    '87713',
    '87714',
    '87715',
    '87718',
    '87722',
    '87723',
    '87724',
    '87728',
    '87729',
    '87730',
    '87731',
    '87732',
    '87733',
    '87734',
    '87735',
    '87736',
    '87740',
    '87742',
    '87743',
    '87745',
    '87746',
    '87747',
    '87749',
    '87750',
    '87752',
    '87753',
    '87801',
    '87820',
    '87821',
    '87823',
    '87824',
    '87825',
    '87827',
    '87828',
    '87829',
    '87830',
    '87831',
    '87832',
    '87901',
    '87930',
    '87931',
    '87933',
    '87935',
    '87936',
    '87937',
    '87939',
    '87940',
    '87941',
    '87942',
    '87943',
    '88001',
    '88002',
    '88003',
    '88004',
    '88005',
    '88006',
    '88007',
    '88008',
    '88009',
    '88011',
    '88012',
    '88013',
    '88020',
    '88021',
    '88022',
    '88023',
    '88024',
    '88025',
    '88026',
    '88027',
    '88028',
    '88029',
    '88030',
    '88031',
    '88032',
    '88033',
    '88034',
    '88036',
    '88038',
    '88039',
    '88040',
    '88041',
    '88042',
    '88043',
    '88044',
    '88045',
    '88046',
    '88047',
    '88048',
    '88049',
    '88051',
    '88052',
    '88053',
    '88054',
    '88055',
    '88056',
    '88058',
    '88061',
    '88062',
    '88063',
    '88065',
    '88072',
    '88081',
    '88101',
    '88102',
    '88103',
    '88112',
    '88113',
    '88114',
    '88115',
    '88116',
    '88118',
    '88119',
    '88120',
    '88121',
    '88122',
    '88123',
    '88124',
    '88125',
    '88126',
    '88130',
    '88132',
    '88133',
    '88134',
    '88135',
    '88136',
    '88201',
    '88202',
    '88203',
    '88210',
    '88211',
    '88213',
    '88220',
    '88221',
    '88230',
    '88231',
    '88232',
    '88240',
    '88241',
    '88242',
    '88244',
    '88250',
    '88252',
    '88253',
    '88254',
    '88255',
    '88256',
    '88260',
    '88262',
    '88263',
    '88264',
    '88265',
    '88267',
    '88268',
    '88301',
    '88310',
    '88311',
    '88312',
    '88314',
    '88316',
    '88317',
    '88318',
    '88321',
    '88323',
    '88324',
    '88325',
    '88330',
    '88336',
    '88337',
    '88338',
    '88339',
    '88340',
    '88341',
    '88342',
    '88343',
    '88344',
    '88345',
    '88346',
    '88347',
    '88348',
    '88349',
    '88350',
    '88351',
    '88352',
    '88353',
    '88354',
    '88355',
    '88401',
    '88410',
    '88411',
    '88414',
    '88415',
    '88416',
    '88417',
    '88418',
    '88419',
    '88421',
    '88422',
    '88424',
    '88426',
    '88427',
    '88430',
    '88431',
    '88433',
    '88434',
    '88435',
    '88436',
    '88439',
  ],
  NV: [
    '88901',
    '88905',
    '89001',
    '89002',
    '89003',
    '89004',
    '89005',
    '89006',
    '89007',
    '89008',
    '89009',
    '89010',
    '89011',
    '89012',
    '89013',
    '89014',
    '89015',
    '89016',
    '89017',
    '89018',
    '89019',
    '89020',
    '89021',
    '89022',
    '89023',
    '89024',
    '89025',
    '89026',
    '89027',
    '89028',
    '89029',
    '89030',
    '89031',
    '89032',
    '89033',
    '89034',
    '89036',
    '89037',
    '89039',
    '89040',
    '89041',
    '89042',
    '89043',
    '89044',
    '89045',
    '89046',
    '89047',
    '89048',
    '89049',
    '89052',
    '89053',
    '89054',
    '89060',
    '89061',
    '89067',
    '89070',
    '89074',
    '89077',
    '89081',
    '89084',
    '89085',
    '89086',
    '89087',
    '89101',
    '89102',
    '89103',
    '89104',
    '89105',
    '89106',
    '89107',
    '89108',
    '89109',
    '89110',
    '89111',
    '89112',
    '89113',
    '89114',
    '89115',
    '89116',
    '89117',
    '89118',
    '89119',
    '89120',
    '89121',
    '89122',
    '89123',
    '89124',
    '89125',
    '89126',
    '89127',
    '89128',
    '89129',
    '89130',
    '89131',
    '89132',
    '89133',
    '89134',
    '89135',
    '89136',
    '89137',
    '89138',
    '89139',
    '89140',
    '89141',
    '89142',
    '89143',
    '89144',
    '89145',
    '89146',
    '89147',
    '89148',
    '89149',
    '89150',
    '89151',
    '89152',
    '89153',
    '89154',
    '89155',
    '89156',
    '89157',
    '89158',
    '89159',
    '89160',
    '89161',
    '89162',
    '89163',
    '89164',
    '89165',
    '89166',
    '89169',
    '89170',
    '89173',
    '89177',
    '89178',
    '89179',
    '89180',
    '89183',
    '89185',
    '89191',
    '89193',
    '89195',
    '89199',
    '89301',
    '89310',
    '89311',
    '89314',
    '89315',
    '89316',
    '89317',
    '89318',
    '89319',
    '89402',
    '89403',
    '89404',
    '89405',
    '89406',
    '89407',
    '89408',
    '89409',
    '89410',
    '89411',
    '89412',
    '89413',
    '89414',
    '89415',
    '89418',
    '89419',
    '89420',
    '89421',
    '89422',
    '89423',
    '89424',
    '89425',
    '89426',
    '89427',
    '89428',
    '89429',
    '89430',
    '89431',
    '89432',
    '89433',
    '89434',
    '89435',
    '89436',
    '89437',
    '89438',
    '89439',
    '89440',
    '89441',
    '89442',
    '89444',
    '89445',
    '89446',
    '89447',
    '89448',
    '89449',
    '89450',
    '89451',
    '89452',
    '89460',
    '89496',
    '89501',
    '89502',
    '89503',
    '89504',
    '89505',
    '89506',
    '89507',
    '89508',
    '89509',
    '89510',
    '89511',
    '89512',
    '89513',
    '89515',
    '89519',
    '89520',
    '89521',
    '89523',
    '89533',
    '89555',
    '89557',
    '89570',
    '89595',
    '89599',
    '89701',
    '89702',
    '89703',
    '89704',
    '89705',
    '89706',
    '89711',
    '89712',
    '89713',
    '89714',
    '89721',
    '89801',
    '89802',
    '89803',
    '89815',
    '89820',
    '89821',
    '89822',
    '89823',
    '89825',
    '89826',
    '89828',
    '89830',
    '89831',
    '89832',
    '89833',
    '89834',
    '89835',
    '89883',
  ],
  CA: [
    '90001',
    '90002',
    '90003',
    '90004',
    '90005',
    '90006',
    '90007',
    '90008',
    '90009',
    '90010',
    '90011',
    '90012',
    '90013',
    '90014',
    '90015',
    '90016',
    '90017',
    '90018',
    '90019',
    '90020',
    '90021',
    '90022',
    '90023',
    '90024',
    '90025',
    '90026',
    '90027',
    '90028',
    '90029',
    '90030',
    '90031',
    '90032',
    '90033',
    '90034',
    '90035',
    '90036',
    '90037',
    '90038',
    '90039',
    '90040',
    '90041',
    '90042',
    '90043',
    '90044',
    '90045',
    '90046',
    '90047',
    '90048',
    '90049',
    '90050',
    '90051',
    '90052',
    '90053',
    '90054',
    '90055',
    '90056',
    '90057',
    '90058',
    '90059',
    '90060',
    '90061',
    '90062',
    '90063',
    '90064',
    '90065',
    '90066',
    '90067',
    '90068',
    '90069',
    '90070',
    '90071',
    '90072',
    '90073',
    '90074',
    '90075',
    '90076',
    '90077',
    '90078',
    '90079',
    '90080',
    '90081',
    '90082',
    '90083',
    '90084',
    '90086',
    '90087',
    '90088',
    '90089',
    '90090',
    '90091',
    '90093',
    '90094',
    '90095',
    '90096',
    '90099',
    '90134',
    '90189',
    '90201',
    '90202',
    '90209',
    '90210',
    '90211',
    '90212',
    '90213',
    '90220',
    '90221',
    '90222',
    '90223',
    '90224',
    '90230',
    '90231',
    '90232',
    '90233',
    '90239',
    '90240',
    '90241',
    '90242',
    '90245',
    '90247',
    '90248',
    '90249',
    '90250',
    '90251',
    '90254',
    '90255',
    '90260',
    '90261',
    '90262',
    '90263',
    '90264',
    '90265',
    '90266',
    '90267',
    '90270',
    '90272',
    '90274',
    '90275',
    '90277',
    '90278',
    '90280',
    '90290',
    '90291',
    '90292',
    '90293',
    '90294',
    '90295',
    '90296',
    '90301',
    '90302',
    '90303',
    '90304',
    '90305',
    '90306',
    '90307',
    '90308',
    '90309',
    '90310',
    '90311',
    '90312',
    '90401',
    '90402',
    '90403',
    '90404',
    '90405',
    '90406',
    '90407',
    '90408',
    '90409',
    '90410',
    '90411',
    '90501',
    '90502',
    '90503',
    '90504',
    '90505',
    '90506',
    '90507',
    '90508',
    '90509',
    '90510',
    '90601',
    '90602',
    '90603',
    '90604',
    '90605',
    '90606',
    '90607',
    '90608',
    '90609',
    '90610',
    '90620',
    '90621',
    '90622',
    '90623',
    '90624',
    '90630',
    '90631',
    '90632',
    '90633',
    '90637',
    '90638',
    '90639',
    '90640',
    '90650',
    '90651',
    '90652',
    '90660',
    '90661',
    '90662',
    '90670',
    '90671',
    '90680',
    '90701',
    '90702',
    '90703',
    '90704',
    '90706',
    '90707',
    '90710',
    '90711',
    '90712',
    '90713',
    '90714',
    '90715',
    '90716',
    '90717',
    '90720',
    '90721',
    '90723',
    '90731',
    '90732',
    '90733',
    '90734',
    '90740',
    '90742',
    '90743',
    '90744',
    '90745',
    '90746',
    '90747',
    '90748',
    '90749',
    '90755',
    '90801',
    '90802',
    '90803',
    '90804',
    '90805',
    '90806',
    '90807',
    '90808',
    '90809',
    '90810',
    '90813',
    '90814',
    '90815',
    '90822',
    '90831',
    '90832',
    '90833',
    '90834',
    '90835',
    '90840',
    '90842',
    '90844',
    '90846',
    '90847',
    '90848',
    '90853',
    '90895',
    '90899',
    '91001',
    '91003',
    '91006',
    '91007',
    '91008',
    '91009',
    '91010',
    '91011',
    '91012',
    '91016',
    '91017',
    '91020',
    '91021',
    '91023',
    '91024',
    '91025',
    '91030',
    '91031',
    '91040',
    '91041',
    '91042',
    '91043',
    '91046',
    '91066',
    '91077',
    '91101',
    '91102',
    '91103',
    '91104',
    '91105',
    '91106',
    '91107',
    '91108',
    '91109',
    '91110',
    '91114',
    '91115',
    '91116',
    '91117',
    '91118',
    '91121',
    '91123',
    '91124',
    '91125',
    '91126',
    '91129',
    '91182',
    '91184',
    '91185',
    '91188',
    '91189',
    '91199',
    '91201',
    '91202',
    '91203',
    '91204',
    '91205',
    '91206',
    '91207',
    '91208',
    '91209',
    '91210',
    '91214',
    '91221',
    '91222',
    '91224',
    '91225',
    '91226',
    '91301',
    '91302',
    '91303',
    '91304',
    '91305',
    '91306',
    '91307',
    '91308',
    '91309',
    '91310',
    '91311',
    '91313',
    '91316',
    '91319',
    '91320',
    '91321',
    '91322',
    '91324',
    '91325',
    '91326',
    '91327',
    '91328',
    '91329',
    '91330',
    '91331',
    '91333',
    '91334',
    '91335',
    '91337',
    '91340',
    '91341',
    '91342',
    '91343',
    '91344',
    '91345',
    '91346',
    '91350',
    '91351',
    '91352',
    '91353',
    '91354',
    '91355',
    '91356',
    '91357',
    '91358',
    '91359',
    '91360',
    '91361',
    '91362',
    '91364',
    '91365',
    '91367',
    '91371',
    '91372',
    '91376',
    '91377',
    '91380',
    '91381',
    '91382',
    '91383',
    '91384',
    '91385',
    '91386',
    '91387',
    '91390',
    '91392',
    '91393',
    '91394',
    '91395',
    '91396',
    '91401',
    '91402',
    '91403',
    '91404',
    '91405',
    '91406',
    '91407',
    '91408',
    '91409',
    '91410',
    '91411',
    '91412',
    '91413',
    '91416',
    '91423',
    '91426',
    '91436',
    '91470',
    '91482',
    '91495',
    '91496',
    '91499',
    '91501',
    '91502',
    '91503',
    '91504',
    '91505',
    '91506',
    '91507',
    '91508',
    '91510',
    '91521',
    '91522',
    '91523',
    '91526',
    '91601',
    '91602',
    '91603',
    '91604',
    '91605',
    '91606',
    '91607',
    '91608',
    '91609',
    '91610',
    '91611',
    '91612',
    '91614',
    '91615',
    '91616',
    '91617',
    '91618',
    '91701',
    '91702',
    '91706',
    '91708',
    '91709',
    '91710',
    '91711',
    '91714',
    '91715',
    '91716',
    '91722',
    '91723',
    '91724',
    '91729',
    '91730',
    '91731',
    '91732',
    '91733',
    '91734',
    '91735',
    '91737',
    '91739',
    '91740',
    '91741',
    '91743',
    '91744',
    '91745',
    '91746',
    '91747',
    '91748',
    '91749',
    '91750',
    '91752',
    '91754',
    '91755',
    '91756',
    '91758',
    '91759',
    '91761',
    '91762',
    '91763',
    '91764',
    '91765',
    '91766',
    '91767',
    '91768',
    '91769',
    '91770',
    '91771',
    '91772',
    '91773',
    '91775',
    '91776',
    '91778',
    '91780',
    '91784',
    '91785',
    '91786',
    '91788',
    '91789',
    '91790',
    '91791',
    '91792',
    '91793',
    '91801',
    '91802',
    '91803',
    '91804',
    '91896',
    '91899',
    '91901',
    '91902',
    '91903',
    '91905',
    '91906',
    '91908',
    '91909',
    '91910',
    '91911',
    '91912',
    '91913',
    '91914',
    '91915',
    '91916',
    '91917',
    '91921',
    '91931',
    '91932',
    '91933',
    '91934',
    '91935',
    '91941',
    '91942',
    '91943',
    '91944',
    '91945',
    '91946',
    '91948',
    '91950',
    '91951',
    '91962',
    '91963',
    '91976',
    '91977',
    '91978',
    '91979',
    '91980',
    '91987',
    '92003',
    '92004',
    '92007',
    '92008',
    '92009',
    '92010',
    '92011',
    '92013',
    '92014',
    '92018',
    '92019',
    '92020',
    '92021',
    '92022',
    '92023',
    '92024',
    '92025',
    '92026',
    '92027',
    '92028',
    '92029',
    '92030',
    '92033',
    '92036',
    '92037',
    '92038',
    '92039',
    '92040',
    '92046',
    '92049',
    '92051',
    '92052',
    '92054',
    '92055',
    '92056',
    '92057',
    '92058',
    '92059',
    '92060',
    '92061',
    '92064',
    '92065',
    '92066',
    '92067',
    '92068',
    '92069',
    '92070',
    '92071',
    '92072',
    '92074',
    '92075',
    '92078',
    '92079',
    '92081',
    '92082',
    '92083',
    '92084',
    '92085',
    '92086',
    '92088',
    '92091',
    '92092',
    '92093',
    '92096',
    '92101',
    '92102',
    '92103',
    '92104',
    '92105',
    '92106',
    '92107',
    '92108',
    '92109',
    '92110',
    '92111',
    '92112',
    '92113',
    '92114',
    '92115',
    '92116',
    '92117',
    '92118',
    '92119',
    '92120',
    '92121',
    '92122',
    '92123',
    '92124',
    '92126',
    '92127',
    '92128',
    '92129',
    '92130',
    '92131',
    '92132',
    '92134',
    '92135',
    '92136',
    '92137',
    '92138',
    '92139',
    '92140',
    '92142',
    '92143',
    '92145',
    '92147',
    '92149',
    '92150',
    '92152',
    '92153',
    '92154',
    '92155',
    '92158',
    '92159',
    '92160',
    '92161',
    '92163',
    '92165',
    '92166',
    '92167',
    '92168',
    '92169',
    '92170',
    '92171',
    '92172',
    '92173',
    '92174',
    '92175',
    '92176',
    '92177',
    '92178',
    '92179',
    '92182',
    '92186',
    '92187',
    '92191',
    '92192',
    '92193',
    '92195',
    '92196',
    '92197',
    '92198',
    '92199',
    '92201',
    '92202',
    '92203',
    '92210',
    '92211',
    '92220',
    '92222',
    '92223',
    '92225',
    '92226',
    '92227',
    '92230',
    '92231',
    '92232',
    '92233',
    '92234',
    '92235',
    '92236',
    '92239',
    '92240',
    '92241',
    '92242',
    '92243',
    '92244',
    '92247',
    '92248',
    '92249',
    '92250',
    '92251',
    '92252',
    '92253',
    '92254',
    '92255',
    '92256',
    '92257',
    '92258',
    '92259',
    '92260',
    '92261',
    '92262',
    '92263',
    '92264',
    '92266',
    '92267',
    '92268',
    '92270',
    '92273',
    '92274',
    '92275',
    '92276',
    '92277',
    '92278',
    '92280',
    '92281',
    '92282',
    '92283',
    '92284',
    '92285',
    '92286',
    '92301',
    '92304',
    '92305',
    '92307',
    '92308',
    '92309',
    '92310',
    '92311',
    '92312',
    '92313',
    '92314',
    '92315',
    '92316',
    '92317',
    '92318',
    '92320',
    '92321',
    '92322',
    '92323',
    '92324',
    '92325',
    '92327',
    '92328',
    '92329',
    '92331',
    '92332',
    '92333',
    '92334',
    '92335',
    '92336',
    '92337',
    '92338',
    '92339',
    '92340',
    '92341',
    '92342',
    '92344',
    '92345',
    '92346',
    '92347',
    '92350',
    '92352',
    '92354',
    '92356',
    '92357',
    '92358',
    '92359',
    '92363',
    '92364',
    '92365',
    '92366',
    '92368',
    '92369',
    '92371',
    '92372',
    '92373',
    '92374',
    '92375',
    '92376',
    '92377',
    '92378',
    '92382',
    '92384',
    '92385',
    '92386',
    '92389',
    '92391',
    '92392',
    '92393',
    '92394',
    '92395',
    '92397',
    '92398',
    '92399',
    '92401',
    '92402',
    '92403',
    '92404',
    '92405',
    '92406',
    '92407',
    '92408',
    '92410',
    '92411',
    '92413',
    '92415',
    '92418',
    '92423',
    '92427',
    '92501',
    '92502',
    '92503',
    '92504',
    '92505',
    '92506',
    '92507',
    '92508',
    '92509',
    '92513',
    '92514',
    '92516',
    '92517',
    '92518',
    '92519',
    '92521',
    '92522',
    '92530',
    '92531',
    '92532',
    '92536',
    '92539',
    '92543',
    '92544',
    '92545',
    '92546',
    '92548',
    '92549',
    '92551',
    '92552',
    '92553',
    '92554',
    '92555',
    '92556',
    '92557',
    '92561',
    '92562',
    '92563',
    '92564',
    '92567',
    '92570',
    '92571',
    '92572',
    '92581',
    '92582',
    '92583',
    '92584',
    '92585',
    '92586',
    '92587',
    '92589',
    '92590',
    '92591',
    '92592',
    '92593',
    '92595',
    '92596',
    '92599',
    '92602',
    '92603',
    '92604',
    '92605',
    '92606',
    '92607',
    '92609',
    '92610',
    '92612',
    '92614',
    '92615',
    '92616',
    '92617',
    '92618',
    '92619',
    '92620',
    '92623',
    '92624',
    '92625',
    '92626',
    '92627',
    '92628',
    '92629',
    '92630',
    '92637',
    '92646',
    '92647',
    '92648',
    '92649',
    '92650',
    '92651',
    '92652',
    '92653',
    '92654',
    '92655',
    '92656',
    '92657',
    '92658',
    '92659',
    '92660',
    '92661',
    '92662',
    '92663',
    '92672',
    '92673',
    '92674',
    '92675',
    '92676',
    '92677',
    '92678',
    '92679',
    '92683',
    '92684',
    '92685',
    '92688',
    '92690',
    '92691',
    '92692',
    '92693',
    '92694',
    '92697',
    '92698',
    '92701',
    '92702',
    '92703',
    '92704',
    '92705',
    '92706',
    '92707',
    '92708',
    '92711',
    '92712',
    '92728',
    '92735',
    '92780',
    '92781',
    '92782',
    '92799',
    '92801',
    '92802',
    '92803',
    '92804',
    '92805',
    '92806',
    '92807',
    '92808',
    '92809',
    '92811',
    '92812',
    '92814',
    '92815',
    '92816',
    '92817',
    '92821',
    '92822',
    '92823',
    '92825',
    '92831',
    '92832',
    '92833',
    '92834',
    '92835',
    '92836',
    '92837',
    '92838',
    '92840',
    '92841',
    '92842',
    '92843',
    '92844',
    '92845',
    '92846',
    '92850',
    '92856',
    '92857',
    '92859',
    '92860',
    '92861',
    '92862',
    '92863',
    '92864',
    '92865',
    '92866',
    '92867',
    '92868',
    '92869',
    '92870',
    '92871',
    '92877',
    '92878',
    '92879',
    '92880',
    '92881',
    '92882',
    '92883',
    '92885',
    '92886',
    '92887',
    '92899',
    '93001',
    '93002',
    '93003',
    '93004',
    '93005',
    '93006',
    '93007',
    '93009',
    '93010',
    '93011',
    '93012',
    '93013',
    '93014',
    '93015',
    '93016',
    '93020',
    '93021',
    '93022',
    '93023',
    '93024',
    '93030',
    '93031',
    '93032',
    '93033',
    '93034',
    '93035',
    '93036',
    '93040',
    '93041',
    '93042',
    '93043',
    '93044',
    '93060',
    '93061',
    '93062',
    '93063',
    '93064',
    '93065',
    '93066',
    '93067',
    '93094',
    '93099',
    '93101',
    '93102',
    '93103',
    '93105',
    '93106',
    '93107',
    '93108',
    '93109',
    '93110',
    '93111',
    '93116',
    '93117',
    '93118',
    '93120',
    '93121',
    '93130',
    '93140',
    '93150',
    '93160',
    '93190',
    '93199',
    '93201',
    '93202',
    '93203',
    '93204',
    '93205',
    '93206',
    '93207',
    '93208',
    '93210',
    '93212',
    '93215',
    '93216',
    '93218',
    '93219',
    '93220',
    '93221',
    '93222',
    '93223',
    '93224',
    '93225',
    '93226',
    '93227',
    '93230',
    '93232',
    '93234',
    '93235',
    '93237',
    '93238',
    '93239',
    '93240',
    '93241',
    '93242',
    '93243',
    '93244',
    '93245',
    '93246',
    '93247',
    '93249',
    '93250',
    '93251',
    '93252',
    '93254',
    '93255',
    '93256',
    '93257',
    '93258',
    '93260',
    '93261',
    '93262',
    '93263',
    '93265',
    '93266',
    '93267',
    '93268',
    '93270',
    '93271',
    '93272',
    '93274',
    '93275',
    '93276',
    '93277',
    '93278',
    '93279',
    '93280',
    '93282',
    '93283',
    '93285',
    '93286',
    '93287',
    '93290',
    '93291',
    '93292',
    '93301',
    '93302',
    '93303',
    '93304',
    '93305',
    '93306',
    '93307',
    '93308',
    '93309',
    '93311',
    '93312',
    '93313',
    '93314',
    '93380',
    '93383',
    '93384',
    '93385',
    '93386',
    '93387',
    '93388',
    '93389',
    '93390',
    '93401',
    '93402',
    '93403',
    '93405',
    '93406',
    '93407',
    '93408',
    '93409',
    '93410',
    '93412',
    '93420',
    '93421',
    '93422',
    '93423',
    '93424',
    '93426',
    '93427',
    '93428',
    '93429',
    '93430',
    '93432',
    '93433',
    '93434',
    '93435',
    '93436',
    '93437',
    '93438',
    '93440',
    '93441',
    '93442',
    '93443',
    '93444',
    '93445',
    '93446',
    '93447',
    '93448',
    '93449',
    '93450',
    '93451',
    '93452',
    '93453',
    '93454',
    '93455',
    '93456',
    '93457',
    '93458',
    '93460',
    '93461',
    '93463',
    '93464',
    '93465',
    '93475',
    '93483',
    '93501',
    '93502',
    '93504',
    '93505',
    '93510',
    '93512',
    '93513',
    '93514',
    '93515',
    '93516',
    '93517',
    '93518',
    '93519',
    '93522',
    '93523',
    '93524',
    '93526',
    '93527',
    '93528',
    '93529',
    '93530',
    '93531',
    '93532',
    '93534',
    '93535',
    '93536',
    '93539',
    '93541',
    '93542',
    '93543',
    '93544',
    '93545',
    '93546',
    '93549',
    '93550',
    '93551',
    '93552',
    '93553',
    '93554',
    '93555',
    '93556',
    '93558',
    '93560',
    '93561',
    '93562',
    '93563',
    '93581',
    '93584',
    '93586',
    '93590',
    '93591',
    '93592',
    '93596',
    '93599',
    '93601',
    '93602',
    '93603',
    '93604',
    '93605',
    '93606',
    '93607',
    '93608',
    '93609',
    '93610',
    '93611',
    '93612',
    '93613',
    '93614',
    '93615',
    '93616',
    '93618',
    '93619',
    '93620',
    '93621',
    '93622',
    '93623',
    '93624',
    '93625',
    '93626',
    '93627',
    '93628',
    '93630',
    '93631',
    '93633',
    '93634',
    '93635',
    '93636',
    '93637',
    '93638',
    '93639',
    '93640',
    '93641',
    '93642',
    '93643',
    '93644',
    '93645',
    '93646',
    '93647',
    '93648',
    '93649',
    '93650',
    '93651',
    '93652',
    '93653',
    '93654',
    '93656',
    '93657',
    '93660',
    '93661',
    '93662',
    '93664',
    '93665',
    '93666',
    '93667',
    '93668',
    '93669',
    '93670',
    '93673',
    '93675',
    '93701',
    '93702',
    '93703',
    '93704',
    '93705',
    '93706',
    '93707',
    '93708',
    '93709',
    '93710',
    '93711',
    '93712',
    '93714',
    '93715',
    '93716',
    '93717',
    '93718',
    '93720',
    '93721',
    '93722',
    '93723',
    '93724',
    '93725',
    '93726',
    '93727',
    '93728',
    '93729',
    '93730',
    '93737',
    '93740',
    '93741',
    '93744',
    '93745',
    '93747',
    '93750',
    '93755',
    '93760',
    '93761',
    '93764',
    '93765',
    '93771',
    '93772',
    '93773',
    '93774',
    '93775',
    '93776',
    '93777',
    '93778',
    '93779',
    '93786',
    '93790',
    '93791',
    '93792',
    '93793',
    '93794',
    '93844',
    '93888',
    '93901',
    '93902',
    '93905',
    '93906',
    '93907',
    '93908',
    '93912',
    '93915',
    '93920',
    '93921',
    '93922',
    '93923',
    '93924',
    '93925',
    '93926',
    '93927',
    '93928',
    '93930',
    '93932',
    '93933',
    '93940',
    '93942',
    '93943',
    '93944',
    '93950',
    '93953',
    '93954',
    '93955',
    '93960',
    '93962',
    '94002',
    '94005',
    '94010',
    '94011',
    '94014',
    '94015',
    '94016',
    '94017',
    '94018',
    '94019',
    '94020',
    '94021',
    '94022',
    '94023',
    '94024',
    '94025',
    '94026',
    '94027',
    '94028',
    '94030',
    '94035',
    '94037',
    '94038',
    '94039',
    '94040',
    '94041',
    '94042',
    '94043',
    '94044',
    '94060',
    '94061',
    '94062',
    '94063',
    '94064',
    '94065',
    '94066',
    '94070',
    '94074',
    '94080',
    '94083',
    '94085',
    '94086',
    '94087',
    '94088',
    '94089',
    '94102',
    '94103',
    '94104',
    '94105',
    '94107',
    '94108',
    '94109',
    '94110',
    '94111',
    '94112',
    '94114',
    '94115',
    '94116',
    '94117',
    '94118',
    '94119',
    '94120',
    '94121',
    '94122',
    '94123',
    '94124',
    '94125',
    '94126',
    '94127',
    '94128',
    '94129',
    '94130',
    '94131',
    '94132',
    '94133',
    '94134',
    '94137',
    '94139',
    '94140',
    '94141',
    '94142',
    '94143',
    '94144',
    '94145',
    '94146',
    '94147',
    '94151',
    '94158',
    '94159',
    '94160',
    '94161',
    '94163',
    '94164',
    '94172',
    '94177',
    '94188',
    '94203',
    '94204',
    '94205',
    '94206',
    '94207',
    '94208',
    '94209',
    '94211',
    '94229',
    '94230',
    '94232',
    '94234',
    '94235',
    '94236',
    '94237',
    '94239',
    '94240',
    '94244',
    '94245',
    '94247',
    '94248',
    '94249',
    '94250',
    '94252',
    '94254',
    '94256',
    '94257',
    '94258',
    '94259',
    '94261',
    '94262',
    '94263',
    '94267',
    '94268',
    '94269',
    '94271',
    '94273',
    '94274',
    '94277',
    '94278',
    '94279',
    '94280',
    '94282',
    '94283',
    '94284',
    '94285',
    '94287',
    '94288',
    '94289',
    '94290',
    '94291',
    '94293',
    '94294',
    '94295',
    '94296',
    '94297',
    '94298',
    '94299',
    '94301',
    '94302',
    '94303',
    '94304',
    '94305',
    '94306',
    '94309',
    '94401',
    '94402',
    '94403',
    '94404',
    '94497',
    '94501',
    '94502',
    '94503',
    '94505',
    '94506',
    '94507',
    '94508',
    '94509',
    '94510',
    '94511',
    '94512',
    '94513',
    '94514',
    '94515',
    '94516',
    '94517',
    '94518',
    '94519',
    '94520',
    '94521',
    '94522',
    '94523',
    '94524',
    '94525',
    '94526',
    '94527',
    '94528',
    '94529',
    '94530',
    '94531',
    '94533',
    '94534',
    '94535',
    '94536',
    '94537',
    '94538',
    '94539',
    '94540',
    '94541',
    '94542',
    '94543',
    '94544',
    '94545',
    '94546',
    '94547',
    '94548',
    '94549',
    '94550',
    '94551',
    '94552',
    '94553',
    '94555',
    '94556',
    '94557',
    '94558',
    '94559',
    '94560',
    '94561',
    '94562',
    '94563',
    '94564',
    '94565',
    '94566',
    '94567',
    '94568',
    '94569',
    '94570',
    '94571',
    '94572',
    '94573',
    '94574',
    '94575',
    '94576',
    '94577',
    '94578',
    '94579',
    '94580',
    '94581',
    '94582',
    '94583',
    '94585',
    '94586',
    '94587',
    '94588',
    '94589',
    '94590',
    '94591',
    '94592',
    '94595',
    '94596',
    '94597',
    '94598',
    '94599',
    '94601',
    '94602',
    '94603',
    '94604',
    '94605',
    '94606',
    '94607',
    '94608',
    '94609',
    '94610',
    '94611',
    '94612',
    '94613',
    '94614',
    '94615',
    '94617',
    '94618',
    '94619',
    '94620',
    '94621',
    '94622',
    '94623',
    '94624',
    '94649',
    '94659',
    '94660',
    '94661',
    '94662',
    '94666',
    '94701',
    '94702',
    '94703',
    '94704',
    '94705',
    '94706',
    '94707',
    '94708',
    '94709',
    '94710',
    '94712',
    '94720',
    '94801',
    '94802',
    '94803',
    '94804',
    '94805',
    '94806',
    '94807',
    '94808',
    '94820',
    '94850',
    '94901',
    '94903',
    '94904',
    '94912',
    '94913',
    '94914',
    '94915',
    '94920',
    '94922',
    '94923',
    '94924',
    '94925',
    '94926',
    '94927',
    '94928',
    '94929',
    '94930',
    '94931',
    '94933',
    '94937',
    '94938',
    '94939',
    '94940',
    '94941',
    '94942',
    '94945',
    '94946',
    '94947',
    '94948',
    '94949',
    '94950',
    '94951',
    '94952',
    '94953',
    '94954',
    '94955',
    '94956',
    '94957',
    '94960',
    '94963',
    '94964',
    '94965',
    '94966',
    '94970',
    '94971',
    '94972',
    '94973',
    '94974',
    '94975',
    '94976',
    '94977',
    '94978',
    '94979',
    '94998',
    '94999',
    '95001',
    '95002',
    '95003',
    '95004',
    '95005',
    '95006',
    '95007',
    '95008',
    '95009',
    '95010',
    '95011',
    '95012',
    '95013',
    '95014',
    '95015',
    '95017',
    '95018',
    '95019',
    '95020',
    '95021',
    '95023',
    '95024',
    '95026',
    '95030',
    '95031',
    '95032',
    '95033',
    '95035',
    '95036',
    '95037',
    '95038',
    '95039',
    '95041',
    '95042',
    '95043',
    '95044',
    '95045',
    '95046',
    '95050',
    '95051',
    '95052',
    '95053',
    '95054',
    '95055',
    '95056',
    '95060',
    '95061',
    '95062',
    '95063',
    '95064',
    '95065',
    '95066',
    '95067',
    '95070',
    '95071',
    '95073',
    '95075',
    '95076',
    '95077',
    '95101',
    '95103',
    '95106',
    '95108',
    '95109',
    '95110',
    '95111',
    '95112',
    '95113',
    '95115',
    '95116',
    '95117',
    '95118',
    '95119',
    '95120',
    '95121',
    '95122',
    '95123',
    '95124',
    '95125',
    '95126',
    '95127',
    '95128',
    '95129',
    '95130',
    '95131',
    '95132',
    '95133',
    '95134',
    '95135',
    '95136',
    '95138',
    '95139',
    '95140',
    '95141',
    '95148',
    '95150',
    '95151',
    '95152',
    '95153',
    '95154',
    '95155',
    '95156',
    '95157',
    '95158',
    '95159',
    '95160',
    '95161',
    '95164',
    '95170',
    '95172',
    '95173',
    '95190',
    '95191',
    '95192',
    '95193',
    '95194',
    '95196',
    '95201',
    '95202',
    '95203',
    '95204',
    '95205',
    '95206',
    '95207',
    '95208',
    '95209',
    '95210',
    '95211',
    '95212',
    '95213',
    '95214',
    '95215',
    '95219',
    '95220',
    '95221',
    '95222',
    '95223',
    '95224',
    '95225',
    '95226',
    '95227',
    '95228',
    '95229',
    '95230',
    '95231',
    '95232',
    '95233',
    '95234',
    '95236',
    '95237',
    '95240',
    '95241',
    '95242',
    '95245',
    '95246',
    '95247',
    '95248',
    '95249',
    '95251',
    '95252',
    '95253',
    '95254',
    '95255',
    '95257',
    '95258',
    '95267',
    '95269',
    '95296',
    '95297',
    '95301',
    '95303',
    '95304',
    '95305',
    '95306',
    '95307',
    '95309',
    '95310',
    '95311',
    '95312',
    '95313',
    '95315',
    '95316',
    '95317',
    '95318',
    '95319',
    '95320',
    '95321',
    '95322',
    '95323',
    '95324',
    '95325',
    '95326',
    '95327',
    '95328',
    '95329',
    '95330',
    '95333',
    '95334',
    '95335',
    '95336',
    '95337',
    '95338',
    '95340',
    '95341',
    '95343',
    '95344',
    '95345',
    '95346',
    '95347',
    '95348',
    '95350',
    '95351',
    '95352',
    '95353',
    '95354',
    '95355',
    '95356',
    '95357',
    '95358',
    '95360',
    '95361',
    '95363',
    '95364',
    '95365',
    '95366',
    '95367',
    '95368',
    '95369',
    '95370',
    '95372',
    '95373',
    '95374',
    '95375',
    '95376',
    '95377',
    '95378',
    '95379',
    '95380',
    '95381',
    '95382',
    '95383',
    '95385',
    '95386',
    '95387',
    '95388',
    '95389',
    '95391',
    '95397',
    '95401',
    '95402',
    '95403',
    '95404',
    '95405',
    '95406',
    '95407',
    '95409',
    '95410',
    '95412',
    '95415',
    '95416',
    '95417',
    '95418',
    '95419',
    '95420',
    '95421',
    '95422',
    '95423',
    '95424',
    '95425',
    '95426',
    '95427',
    '95428',
    '95429',
    '95430',
    '95431',
    '95432',
    '95433',
    '95435',
    '95436',
    '95437',
    '95439',
    '95441',
    '95442',
    '95443',
    '95444',
    '95445',
    '95446',
    '95448',
    '95449',
    '95450',
    '95451',
    '95452',
    '95453',
    '95454',
    '95456',
    '95457',
    '95458',
    '95459',
    '95460',
    '95461',
    '95462',
    '95463',
    '95464',
    '95465',
    '95466',
    '95467',
    '95468',
    '95469',
    '95470',
    '95471',
    '95472',
    '95473',
    '95476',
    '95480',
    '95481',
    '95482',
    '95485',
    '95486',
    '95487',
    '95488',
    '95490',
    '95492',
    '95493',
    '95494',
    '95497',
    '95501',
    '95502',
    '95503',
    '95511',
    '95514',
    '95518',
    '95519',
    '95521',
    '95524',
    '95525',
    '95526',
    '95527',
    '95528',
    '95531',
    '95532',
    '95534',
    '95536',
    '95537',
    '95538',
    '95540',
    '95542',
    '95543',
    '95545',
    '95546',
    '95547',
    '95548',
    '95549',
    '95550',
    '95551',
    '95552',
    '95553',
    '95554',
    '95555',
    '95556',
    '95558',
    '95559',
    '95560',
    '95562',
    '95563',
    '95564',
    '95565',
    '95567',
    '95568',
    '95569',
    '95570',
    '95571',
    '95573',
    '95585',
    '95587',
    '95589',
    '95595',
    '95601',
    '95602',
    '95603',
    '95604',
    '95605',
    '95606',
    '95607',
    '95608',
    '95609',
    '95610',
    '95611',
    '95612',
    '95613',
    '95614',
    '95615',
    '95616',
    '95617',
    '95618',
    '95619',
    '95620',
    '95621',
    '95623',
    '95624',
    '95625',
    '95626',
    '95627',
    '95628',
    '95629',
    '95630',
    '95631',
    '95632',
    '95633',
    '95634',
    '95635',
    '95636',
    '95637',
    '95638',
    '95639',
    '95640',
    '95641',
    '95642',
    '95644',
    '95645',
    '95646',
    '95648',
    '95650',
    '95651',
    '95652',
    '95653',
    '95654',
    '95655',
    '95656',
    '95658',
    '95659',
    '95660',
    '95661',
    '95662',
    '95663',
    '95664',
    '95665',
    '95666',
    '95667',
    '95668',
    '95669',
    '95670',
    '95671',
    '95672',
    '95673',
    '95674',
    '95675',
    '95676',
    '95677',
    '95678',
    '95679',
    '95680',
    '95681',
    '95682',
    '95683',
    '95684',
    '95685',
    '95686',
    '95687',
    '95688',
    '95689',
    '95690',
    '95691',
    '95692',
    '95693',
    '95694',
    '95695',
    '95696',
    '95697',
    '95698',
    '95699',
    '95701',
    '95703',
    '95709',
    '95712',
    '95713',
    '95714',
    '95715',
    '95717',
    '95720',
    '95721',
    '95722',
    '95724',
    '95726',
    '95728',
    '95735',
    '95736',
    '95741',
    '95742',
    '95746',
    '95747',
    '95757',
    '95758',
    '95759',
    '95762',
    '95763',
    '95765',
    '95776',
    '95798',
    '95799',
    '95811',
    '95812',
    '95813',
    '95814',
    '95815',
    '95816',
    '95817',
    '95818',
    '95819',
    '95820',
    '95821',
    '95822',
    '95823',
    '95824',
    '95825',
    '95826',
    '95827',
    '95828',
    '95829',
    '95830',
    '95831',
    '95832',
    '95833',
    '95834',
    '95835',
    '95836',
    '95837',
    '95838',
    '95840',
    '95841',
    '95842',
    '95843',
    '95851',
    '95852',
    '95853',
    '95860',
    '95864',
    '95865',
    '95866',
    '95867',
    '95894',
    '95899',
    '95901',
    '95903',
    '95910',
    '95912',
    '95913',
    '95914',
    '95915',
    '95916',
    '95917',
    '95918',
    '95919',
    '95920',
    '95922',
    '95923',
    '95924',
    '95925',
    '95926',
    '95927',
    '95928',
    '95929',
    '95930',
    '95932',
    '95934',
    '95935',
    '95936',
    '95937',
    '95938',
    '95939',
    '95940',
    '95941',
    '95942',
    '95943',
    '95944',
    '95945',
    '95946',
    '95947',
    '95948',
    '95949',
    '95950',
    '95951',
    '95953',
    '95954',
    '95955',
    '95956',
    '95957',
    '95958',
    '95959',
    '95960',
    '95961',
    '95962',
    '95963',
    '95965',
    '95966',
    '95967',
    '95968',
    '95969',
    '95970',
    '95971',
    '95972',
    '95973',
    '95974',
    '95975',
    '95976',
    '95977',
    '95978',
    '95979',
    '95980',
    '95981',
    '95982',
    '95983',
    '95984',
    '95986',
    '95987',
    '95988',
    '95991',
    '95992',
    '95993',
    '96001',
    '96002',
    '96003',
    '96006',
    '96007',
    '96008',
    '96009',
    '96010',
    '96011',
    '96013',
    '96014',
    '96015',
    '96016',
    '96017',
    '96019',
    '96020',
    '96021',
    '96022',
    '96023',
    '96024',
    '96025',
    '96027',
    '96028',
    '96029',
    '96031',
    '96032',
    '96033',
    '96034',
    '96035',
    '96037',
    '96038',
    '96039',
    '96040',
    '96041',
    '96044',
    '96046',
    '96047',
    '96048',
    '96049',
    '96050',
    '96051',
    '96052',
    '96054',
    '96055',
    '96056',
    '96057',
    '96058',
    '96059',
    '96061',
    '96062',
    '96063',
    '96064',
    '96065',
    '96067',
    '96068',
    '96069',
    '96070',
    '96071',
    '96073',
    '96074',
    '96075',
    '96076',
    '96078',
    '96079',
    '96080',
    '96084',
    '96085',
    '96086',
    '96087',
    '96088',
    '96089',
    '96090',
    '96091',
    '96092',
    '96093',
    '96094',
    '96095',
    '96096',
    '96097',
    '96099',
    '96101',
    '96103',
    '96104',
    '96105',
    '96106',
    '96107',
    '96108',
    '96109',
    '96110',
    '96111',
    '96112',
    '96113',
    '96114',
    '96115',
    '96116',
    '96117',
    '96118',
    '96119',
    '96120',
    '96121',
    '96122',
    '96123',
    '96124',
    '96125',
    '96126',
    '96127',
    '96128',
    '96129',
    '96130',
    '96132',
    '96133',
    '96134',
    '96135',
    '96136',
    '96137',
    '96140',
    '96141',
    '96142',
    '96143',
    '96145',
    '96146',
    '96148',
    '96150',
    '96151',
    '96152',
    '96154',
    '96155',
    '96156',
    '96157',
    '96158',
    '96160',
    '96161',
    '96162',
  ],
  HI: [
    '96701',
    '96703',
    '96704',
    '96705',
    '96706',
    '96707',
    '96708',
    '96709',
    '96710',
    '96712',
    '96713',
    '96714',
    '96715',
    '96716',
    '96717',
    '96718',
    '96719',
    '96720',
    '96721',
    '96722',
    '96725',
    '96726',
    '96727',
    '96728',
    '96729',
    '96730',
    '96731',
    '96732',
    '96733',
    '96734',
    '96737',
    '96738',
    '96739',
    '96740',
    '96741',
    '96742',
    '96743',
    '96744',
    '96745',
    '96746',
    '96747',
    '96748',
    '96749',
    '96750',
    '96751',
    '96752',
    '96753',
    '96754',
    '96755',
    '96756',
    '96757',
    '96759',
    '96760',
    '96761',
    '96762',
    '96763',
    '96764',
    '96765',
    '96766',
    '96767',
    '96768',
    '96769',
    '96770',
    '96771',
    '96772',
    '96773',
    '96774',
    '96776',
    '96777',
    '96778',
    '96779',
    '96780',
    '96781',
    '96782',
    '96783',
    '96784',
    '96785',
    '96786',
    '96788',
    '96789',
    '96790',
    '96791',
    '96792',
    '96793',
    '96795',
    '96796',
    '96797',
    '96801',
    '96802',
    '96803',
    '96804',
    '96805',
    '96806',
    '96807',
    '96808',
    '96809',
    '96810',
    '96811',
    '96812',
    '96813',
    '96814',
    '96815',
    '96816',
    '96817',
    '96818',
    '96819',
    '96820',
    '96821',
    '96822',
    '96823',
    '96824',
    '96825',
    '96826',
    '96828',
    '96830',
    '96836',
    '96837',
    '96838',
    '96839',
    '96840',
    '96841',
    '96843',
    '96844',
    '96846',
    '96847',
    '96848',
    '96849',
    '96850',
    '96853',
    '96854',
    '96857',
    '96858',
    '96859',
    '96860',
    '96861',
    '96863',
    '96898',
  ],
  OR: [
    '97001',
    '97002',
    '97003',
    '97004',
    '97005',
    '97006',
    '97007',
    '97008',
    '97009',
    '97010',
    '97011',
    '97013',
    '97014',
    '97015',
    '97016',
    '97017',
    '97018',
    '97019',
    '97020',
    '97021',
    '97022',
    '97023',
    '97024',
    '97026',
    '97027',
    '97028',
    '97029',
    '97030',
    '97031',
    '97032',
    '97033',
    '97034',
    '97035',
    '97036',
    '97037',
    '97038',
    '97039',
    '97040',
    '97041',
    '97042',
    '97044',
    '97045',
    '97048',
    '97049',
    '97050',
    '97051',
    '97053',
    '97054',
    '97055',
    '97056',
    '97057',
    '97058',
    '97060',
    '97062',
    '97063',
    '97064',
    '97065',
    '97067',
    '97068',
    '97070',
    '97071',
    '97075',
    '97076',
    '97077',
    '97078',
    '97079',
    '97080',
    '97086',
    '97089',
    '97101',
    '97102',
    '97103',
    '97106',
    '97107',
    '97108',
    '97109',
    '97110',
    '97111',
    '97112',
    '97113',
    '97114',
    '97115',
    '97116',
    '97117',
    '97118',
    '97119',
    '97121',
    '97122',
    '97123',
    '97124',
    '97125',
    '97127',
    '97128',
    '97129',
    '97130',
    '97131',
    '97132',
    '97133',
    '97134',
    '97135',
    '97136',
    '97137',
    '97138',
    '97140',
    '97141',
    '97143',
    '97144',
    '97145',
    '97146',
    '97147',
    '97148',
    '97149',
    '97201',
    '97202',
    '97203',
    '97204',
    '97205',
    '97206',
    '97207',
    '97208',
    '97209',
    '97210',
    '97211',
    '97212',
    '97213',
    '97214',
    '97215',
    '97216',
    '97217',
    '97218',
    '97219',
    '97220',
    '97221',
    '97222',
    '97223',
    '97224',
    '97225',
    '97227',
    '97228',
    '97229',
    '97230',
    '97231',
    '97232',
    '97233',
    '97236',
    '97238',
    '97239',
    '97240',
    '97242',
    '97250',
    '97251',
    '97252',
    '97253',
    '97254',
    '97256',
    '97258',
    '97266',
    '97267',
    '97268',
    '97269',
    '97280',
    '97281',
    '97282',
    '97283',
    '97286',
    '97290',
    '97291',
    '97292',
    '97293',
    '97294',
    '97296',
    '97298',
    '97301',
    '97302',
    '97303',
    '97304',
    '97305',
    '97306',
    '97307',
    '97308',
    '97309',
    '97310',
    '97311',
    '97312',
    '97314',
    '97317',
    '97321',
    '97322',
    '97324',
    '97325',
    '97326',
    '97327',
    '97329',
    '97330',
    '97331',
    '97333',
    '97335',
    '97336',
    '97338',
    '97339',
    '97341',
    '97342',
    '97343',
    '97344',
    '97345',
    '97346',
    '97347',
    '97348',
    '97350',
    '97351',
    '97352',
    '97355',
    '97357',
    '97358',
    '97360',
    '97361',
    '97362',
    '97364',
    '97365',
    '97366',
    '97367',
    '97368',
    '97369',
    '97370',
    '97371',
    '97373',
    '97374',
    '97375',
    '97376',
    '97377',
    '97378',
    '97380',
    '97381',
    '97383',
    '97384',
    '97385',
    '97386',
    '97388',
    '97389',
    '97390',
    '97391',
    '97392',
    '97394',
    '97396',
    '97401',
    '97402',
    '97403',
    '97404',
    '97405',
    '97406',
    '97407',
    '97408',
    '97409',
    '97410',
    '97411',
    '97412',
    '97413',
    '97414',
    '97415',
    '97416',
    '97417',
    '97419',
    '97420',
    '97423',
    '97424',
    '97426',
    '97429',
    '97430',
    '97431',
    '97432',
    '97434',
    '97435',
    '97436',
    '97437',
    '97438',
    '97439',
    '97440',
    '97441',
    '97442',
    '97443',
    '97444',
    '97446',
    '97447',
    '97448',
    '97449',
    '97450',
    '97451',
    '97452',
    '97453',
    '97454',
    '97455',
    '97456',
    '97457',
    '97458',
    '97459',
    '97461',
    '97462',
    '97463',
    '97464',
    '97465',
    '97466',
    '97467',
    '97469',
    '97470',
    '97471',
    '97473',
    '97475',
    '97476',
    '97477',
    '97478',
    '97479',
    '97480',
    '97481',
    '97484',
    '97486',
    '97487',
    '97488',
    '97489',
    '97490',
    '97491',
    '97492',
    '97493',
    '97494',
    '97495',
    '97496',
    '97497',
    '97498',
    '97499',
    '97501',
    '97502',
    '97503',
    '97504',
    '97520',
    '97522',
    '97523',
    '97524',
    '97525',
    '97526',
    '97527',
    '97528',
    '97530',
    '97531',
    '97532',
    '97533',
    '97534',
    '97535',
    '97536',
    '97537',
    '97538',
    '97539',
    '97540',
    '97541',
    '97543',
    '97544',
    '97601',
    '97602',
    '97603',
    '97604',
    '97620',
    '97621',
    '97622',
    '97623',
    '97624',
    '97625',
    '97626',
    '97627',
    '97630',
    '97632',
    '97633',
    '97634',
    '97635',
    '97636',
    '97637',
    '97638',
    '97639',
    '97640',
    '97641',
    '97701',
    '97702',
    '97703',
    '97707',
    '97708',
    '97709',
    '97710',
    '97711',
    '97712',
    '97720',
    '97721',
    '97722',
    '97730',
    '97731',
    '97732',
    '97733',
    '97734',
    '97735',
    '97736',
    '97737',
    '97738',
    '97739',
    '97741',
    '97750',
    '97751',
    '97752',
    '97753',
    '97754',
    '97756',
    '97758',
    '97759',
    '97760',
    '97761',
    '97801',
    '97810',
    '97812',
    '97813',
    '97814',
    '97817',
    '97818',
    '97819',
    '97820',
    '97823',
    '97824',
    '97825',
    '97826',
    '97827',
    '97828',
    '97830',
    '97833',
    '97834',
    '97835',
    '97836',
    '97837',
    '97838',
    '97839',
    '97840',
    '97841',
    '97842',
    '97843',
    '97844',
    '97845',
    '97846',
    '97848',
    '97850',
    '97856',
    '97857',
    '97859',
    '97861',
    '97862',
    '97864',
    '97865',
    '97867',
    '97868',
    '97869',
    '97870',
    '97873',
    '97874',
    '97875',
    '97876',
    '97877',
    '97880',
    '97882',
    '97883',
    '97884',
    '97885',
    '97886',
    '97901',
    '97902',
    '97903',
    '97904',
    '97905',
    '97906',
    '97907',
    '97908',
    '97909',
    '97910',
    '97911',
    '97913',
    '97914',
    '97917',
    '97918',
    '97920',
  ],
  WA: [
    '98001',
    '98002',
    '98003',
    '98004',
    '98005',
    '98006',
    '98007',
    '98008',
    '98009',
    '98010',
    '98011',
    '98012',
    '98013',
    '98014',
    '98015',
    '98019',
    '98020',
    '98021',
    '98022',
    '98023',
    '98024',
    '98025',
    '98026',
    '98027',
    '98028',
    '98029',
    '98030',
    '98031',
    '98032',
    '98033',
    '98034',
    '98035',
    '98036',
    '98037',
    '98038',
    '98039',
    '98040',
    '98041',
    '98042',
    '98043',
    '98045',
    '98046',
    '98047',
    '98050',
    '98051',
    '98052',
    '98053',
    '98055',
    '98056',
    '98057',
    '98058',
    '98059',
    '98061',
    '98062',
    '98063',
    '98064',
    '98065',
    '98068',
    '98070',
    '98071',
    '98072',
    '98073',
    '98074',
    '98075',
    '98077',
    '98082',
    '98083',
    '98087',
    '98089',
    '98092',
    '98093',
    '98101',
    '98102',
    '98103',
    '98104',
    '98105',
    '98106',
    '98107',
    '98108',
    '98109',
    '98110',
    '98111',
    '98112',
    '98113',
    '98114',
    '98115',
    '98116',
    '98117',
    '98118',
    '98119',
    '98121',
    '98122',
    '98124',
    '98125',
    '98126',
    '98127',
    '98129',
    '98131',
    '98133',
    '98134',
    '98136',
    '98138',
    '98139',
    '98141',
    '98144',
    '98145',
    '98146',
    '98148',
    '98154',
    '98155',
    '98158',
    '98160',
    '98161',
    '98164',
    '98165',
    '98166',
    '98168',
    '98170',
    '98174',
    '98175',
    '98177',
    '98178',
    '98181',
    '98185',
    '98188',
    '98190',
    '98191',
    '98194',
    '98195',
    '98198',
    '98199',
    '98201',
    '98203',
    '98204',
    '98206',
    '98207',
    '98208',
    '98213',
    '98220',
    '98221',
    '98222',
    '98223',
    '98224',
    '98225',
    '98226',
    '98227',
    '98228',
    '98229',
    '98230',
    '98231',
    '98232',
    '98233',
    '98235',
    '98236',
    '98237',
    '98238',
    '98239',
    '98240',
    '98241',
    '98243',
    '98244',
    '98245',
    '98247',
    '98248',
    '98249',
    '98250',
    '98251',
    '98252',
    '98253',
    '98255',
    '98256',
    '98257',
    '98258',
    '98259',
    '98260',
    '98261',
    '98262',
    '98263',
    '98264',
    '98266',
    '98267',
    '98270',
    '98271',
    '98272',
    '98273',
    '98274',
    '98275',
    '98276',
    '98277',
    '98278',
    '98279',
    '98280',
    '98281',
    '98282',
    '98283',
    '98284',
    '98286',
    '98287',
    '98288',
    '98290',
    '98291',
    '98292',
    '98293',
    '98294',
    '98295',
    '98296',
    '98297',
    '98303',
    '98304',
    '98305',
    '98310',
    '98311',
    '98312',
    '98314',
    '98315',
    '98320',
    '98321',
    '98322',
    '98323',
    '98324',
    '98325',
    '98326',
    '98327',
    '98328',
    '98329',
    '98330',
    '98331',
    '98332',
    '98333',
    '98335',
    '98336',
    '98337',
    '98338',
    '98339',
    '98340',
    '98342',
    '98343',
    '98344',
    '98345',
    '98346',
    '98348',
    '98349',
    '98350',
    '98351',
    '98352',
    '98353',
    '98354',
    '98355',
    '98356',
    '98357',
    '98358',
    '98359',
    '98360',
    '98361',
    '98362',
    '98363',
    '98364',
    '98365',
    '98366',
    '98367',
    '98368',
    '98370',
    '98371',
    '98372',
    '98373',
    '98374',
    '98375',
    '98376',
    '98377',
    '98378',
    '98380',
    '98381',
    '98382',
    '98383',
    '98384',
    '98385',
    '98386',
    '98387',
    '98388',
    '98390',
    '98391',
    '98392',
    '98393',
    '98394',
    '98395',
    '98396',
    '98397',
    '98398',
    '98401',
    '98402',
    '98403',
    '98404',
    '98405',
    '98406',
    '98407',
    '98408',
    '98409',
    '98411',
    '98412',
    '98413',
    '98415',
    '98416',
    '98417',
    '98418',
    '98419',
    '98421',
    '98422',
    '98424',
    '98430',
    '98431',
    '98433',
    '98438',
    '98439',
    '98443',
    '98444',
    '98445',
    '98446',
    '98447',
    '98448',
    '98464',
    '98465',
    '98466',
    '98467',
    '98471',
    '98481',
    '98490',
    '98493',
    '98496',
    '98497',
    '98498',
    '98499',
    '98501',
    '98502',
    '98503',
    '98504',
    '98505',
    '98506',
    '98507',
    '98508',
    '98509',
    '98511',
    '98512',
    '98513',
    '98516',
    '98520',
    '98522',
    '98524',
    '98526',
    '98527',
    '98528',
    '98530',
    '98531',
    '98532',
    '98533',
    '98535',
    '98536',
    '98537',
    '98538',
    '98539',
    '98540',
    '98541',
    '98542',
    '98544',
    '98546',
    '98547',
    '98548',
    '98550',
    '98552',
    '98554',
    '98555',
    '98556',
    '98557',
    '98558',
    '98559',
    '98560',
    '98561',
    '98562',
    '98563',
    '98564',
    '98565',
    '98566',
    '98568',
    '98569',
    '98570',
    '98571',
    '98572',
    '98575',
    '98576',
    '98577',
    '98579',
    '98580',
    '98581',
    '98582',
    '98583',
    '98584',
    '98585',
    '98586',
    '98587',
    '98588',
    '98589',
    '98590',
    '98591',
    '98592',
    '98593',
    '98595',
    '98596',
    '98597',
    '98599',
    '98601',
    '98602',
    '98603',
    '98604',
    '98605',
    '98606',
    '98607',
    '98609',
    '98610',
    '98611',
    '98612',
    '98613',
    '98614',
    '98616',
    '98617',
    '98619',
    '98620',
    '98621',
    '98622',
    '98623',
    '98624',
    '98625',
    '98626',
    '98628',
    '98629',
    '98631',
    '98632',
    '98635',
    '98637',
    '98638',
    '98639',
    '98640',
    '98641',
    '98642',
    '98643',
    '98644',
    '98645',
    '98647',
    '98648',
    '98649',
    '98650',
    '98651',
    '98660',
    '98661',
    '98662',
    '98663',
    '98664',
    '98665',
    '98666',
    '98668',
    '98670',
    '98671',
    '98672',
    '98673',
    '98674',
    '98675',
    '98682',
    '98683',
    '98684',
    '98685',
    '98686',
    '98687',
    '98801',
    '98802',
    '98807',
    '98811',
    '98812',
    '98813',
    '98814',
    '98815',
    '98816',
    '98817',
    '98819',
    '98821',
    '98822',
    '98823',
    '98824',
    '98826',
    '98827',
    '98828',
    '98829',
    '98830',
    '98831',
    '98832',
    '98833',
    '98834',
    '98836',
    '98837',
    '98840',
    '98841',
    '98843',
    '98844',
    '98845',
    '98846',
    '98847',
    '98848',
    '98849',
    '98850',
    '98851',
    '98852',
    '98853',
    '98855',
    '98856',
    '98857',
    '98858',
    '98859',
    '98860',
    '98862',
    '98901',
    '98902',
    '98903',
    '98904',
    '98907',
    '98908',
    '98909',
    '98920',
    '98921',
    '98922',
    '98923',
    '98925',
    '98926',
    '98930',
    '98932',
    '98933',
    '98934',
    '98935',
    '98936',
    '98937',
    '98938',
    '98939',
    '98940',
    '98941',
    '98942',
    '98943',
    '98944',
    '98946',
    '98947',
    '98948',
    '98950',
    '98951',
    '98952',
    '98953',
    '99001',
    '99003',
    '99004',
    '99005',
    '99006',
    '99008',
    '99009',
    '99011',
    '99012',
    '99013',
    '99014',
    '99016',
    '99017',
    '99018',
    '99019',
    '99020',
    '99021',
    '99022',
    '99023',
    '99025',
    '99026',
    '99027',
    '99029',
    '99030',
    '99031',
    '99032',
    '99033',
    '99034',
    '99036',
    '99037',
    '99039',
    '99040',
    '99101',
    '99102',
    '99103',
    '99104',
    '99105',
    '99109',
    '99110',
    '99111',
    '99113',
    '99114',
    '99115',
    '99116',
    '99117',
    '99118',
    '99119',
    '99121',
    '99122',
    '99123',
    '99124',
    '99125',
    '99126',
    '99128',
    '99129',
    '99130',
    '99131',
    '99133',
    '99134',
    '99135',
    '99136',
    '99137',
    '99138',
    '99139',
    '99140',
    '99141',
    '99143',
    '99144',
    '99146',
    '99147',
    '99148',
    '99149',
    '99150',
    '99151',
    '99152',
    '99153',
    '99154',
    '99155',
    '99156',
    '99157',
    '99158',
    '99159',
    '99160',
    '99161',
    '99163',
    '99164',
    '99166',
    '99167',
    '99169',
    '99170',
    '99171',
    '99173',
    '99174',
    '99176',
    '99179',
    '99180',
    '99181',
    '99185',
    '99201',
    '99202',
    '99203',
    '99204',
    '99205',
    '99206',
    '99207',
    '99208',
    '99209',
    '99210',
    '99211',
    '99212',
    '99213',
    '99214',
    '99215',
    '99216',
    '99217',
    '99218',
    '99219',
    '99220',
    '99223',
    '99224',
    '99228',
    '99251',
    '99252',
    '99256',
    '99258',
    '99260',
    '99301',
    '99302',
    '99320',
    '99321',
    '99322',
    '99323',
    '99324',
    '99326',
    '99328',
    '99329',
    '99330',
    '99333',
    '99335',
    '99336',
    '99337',
    '99338',
    '99341',
    '99343',
    '99344',
    '99345',
    '99346',
    '99347',
    '99348',
    '99349',
    '99350',
    '99352',
    '99353',
    '99354',
    '99356',
    '99357',
    '99359',
    '99360',
    '99361',
    '99362',
    '99363',
    '99371',
    '99401',
    '99402',
    '99403',
  ],
  AK: [
    '99501',
    '99502',
    '99503',
    '99504',
    '99505',
    '99506',
    '99507',
    '99508',
    '99509',
    '99510',
    '99511',
    '99513',
    '99514',
    '99515',
    '99516',
    '99517',
    '99518',
    '99519',
    '99520',
    '99521',
    '99522',
    '99523',
    '99524',
    '99529',
    '99530',
    '99540',
    '99545',
    '99546',
    '99547',
    '99548',
    '99549',
    '99550',
    '99551',
    '99552',
    '99553',
    '99554',
    '99555',
    '99556',
    '99557',
    '99558',
    '99559',
    '99561',
    '99563',
    '99564',
    '99565',
    '99566',
    '99567',
    '99568',
    '99569',
    '99571',
    '99572',
    '99573',
    '99574',
    '99575',
    '99576',
    '99577',
    '99578',
    '99579',
    '99580',
    '99581',
    '99583',
    '99585',
    '99586',
    '99587',
    '99588',
    '99589',
    '99590',
    '99591',
    '99599',
    '99602',
    '99603',
    '99604',
    '99605',
    '99606',
    '99607',
    '99608',
    '99609',
    '99610',
    '99611',
    '99612',
    '99613',
    '99614',
    '99615',
    '99619',
    '99620',
    '99621',
    '99622',
    '99623',
    '99624',
    '99625',
    '99626',
    '99627',
    '99628',
    '99629',
    '99630',
    '99631',
    '99632',
    '99633',
    '99634',
    '99635',
    '99636',
    '99637',
    '99638',
    '99639',
    '99640',
    '99641',
    '99643',
    '99644',
    '99645',
    '99647',
    '99648',
    '99649',
    '99650',
    '99651',
    '99652',
    '99653',
    '99654',
    '99655',
    '99656',
    '99657',
    '99658',
    '99659',
    '99660',
    '99661',
    '99662',
    '99663',
    '99664',
    '99665',
    '99666',
    '99667',
    '99668',
    '99669',
    '99670',
    '99671',
    '99672',
    '99674',
    '99675',
    '99676',
    '99677',
    '99678',
    '99679',
    '99680',
    '99681',
    '99682',
    '99683',
    '99684',
    '99685',
    '99686',
    '99687',
    '99688',
    '99689',
    '99690',
    '99691',
    '99692',
    '99693',
    '99694',
    '99695',
    '99697',
    '99701',
    '99702',
    '99703',
    '99704',
    '99705',
    '99706',
    '99707',
    '99708',
    '99709',
    '99710',
    '99711',
    '99712',
    '99714',
    '99716',
    '99720',
    '99721',
    '99722',
    '99723',
    '99724',
    '99725',
    '99726',
    '99727',
    '99729',
    '99730',
    '99731',
    '99732',
    '99733',
    '99734',
    '99736',
    '99737',
    '99738',
    '99739',
    '99740',
    '99741',
    '99742',
    '99743',
    '99744',
    '99745',
    '99746',
    '99747',
    '99748',
    '99749',
    '99750',
    '99751',
    '99752',
    '99753',
    '99754',
    '99755',
    '99756',
    '99757',
    '99758',
    '99759',
    '99760',
    '99761',
    '99762',
    '99763',
    '99764',
    '99765',
    '99766',
    '99767',
    '99768',
    '99769',
    '99770',
    '99771',
    '99772',
    '99773',
    '99774',
    '99775',
    '99776',
    '99777',
    '99778',
    '99780',
    '99781',
    '99782',
    '99783',
    '99784',
    '99785',
    '99786',
    '99788',
    '99789',
    '99790',
    '99791',
    '99801',
    '99802',
    '99803',
    '99811',
    '99812',
    '99820',
    '99821',
    '99824',
    '99825',
    '99826',
    '99827',
    '99829',
    '99830',
    '99832',
    '99833',
    '99835',
    '99836',
    '99840',
    '99841',
    '99850',
    '99901',
    '99903',
    '99918',
    '99919',
    '99921',
    '99922',
    '99923',
    '99925',
    '99926',
    '99927',
    '99928',
    '99929',
    '99950',
  ],
  MA: [
    '01001',
    '01002',
    '01003',
    '01004',
    '01005',
    '01007',
    '01008',
    '01009',
    '01010',
    '01011',
    '01012',
    '01013',
    '01014',
    '01020',
    '01021',
    '01022',
    '01026',
    '01027',
    '01028',
    '01029',
    '01030',
    '01031',
    '01032',
    '01033',
    '01034',
    '01035',
    '01036',
    '01037',
    '01038',
    '01039',
    '01040',
    '01041',
    '01050',
    '01053',
    '01054',
    '01056',
    '01057',
    '01059',
    '01060',
    '01061',
    '01062',
    '01063',
    '01066',
    '01068',
    '01069',
    '01070',
    '01071',
    '01072',
    '01073',
    '01074',
    '01075',
    '01077',
    '01079',
    '01080',
    '01081',
    '01082',
    '01083',
    '01084',
    '01085',
    '01086',
    '01088',
    '01089',
    '01090',
    '01092',
    '01093',
    '01094',
    '01095',
    '01096',
    '01097',
    '01098',
    '01101',
    '01102',
    '01103',
    '01104',
    '01105',
    '01106',
    '01107',
    '01108',
    '01109',
    '01111',
    '01115',
    '01116',
    '01118',
    '01119',
    '01128',
    '01129',
    '01138',
    '01139',
    '01144',
    '01151',
    '01152',
    '01199',
    '01201',
    '01202',
    '01203',
    '01220',
    '01222',
    '01223',
    '01224',
    '01225',
    '01226',
    '01227',
    '01229',
    '01230',
    '01235',
    '01236',
    '01237',
    '01238',
    '01240',
    '01242',
    '01243',
    '01244',
    '01245',
    '01247',
    '01252',
    '01253',
    '01254',
    '01255',
    '01256',
    '01257',
    '01258',
    '01259',
    '01260',
    '01262',
    '01263',
    '01264',
    '01266',
    '01267',
    '01270',
    '01301',
    '01302',
    '01330',
    '01331',
    '01337',
    '01338',
    '01339',
    '01340',
    '01341',
    '01342',
    '01343',
    '01344',
    '01346',
    '01347',
    '01349',
    '01350',
    '01351',
    '01354',
    '01355',
    '01360',
    '01364',
    '01366',
    '01367',
    '01368',
    '01370',
    '01373',
    '01375',
    '01376',
    '01378',
    '01379',
    '01380',
    '01420',
    '01430',
    '01431',
    '01432',
    '01434',
    '01436',
    '01438',
    '01440',
    '01441',
    '01450',
    '01451',
    '01452',
    '01453',
    '01460',
    '01462',
    '01463',
    '01464',
    '01467',
    '01468',
    '01469',
    '01470',
    '01471',
    '01472',
    '01473',
    '01474',
    '01475',
    '01501',
    '01503',
    '01504',
    '01505',
    '01506',
    '01507',
    '01508',
    '01509',
    '01510',
    '01515',
    '01516',
    '01518',
    '01519',
    '01520',
    '01521',
    '01522',
    '01523',
    '01524',
    '01525',
    '01526',
    '01527',
    '01529',
    '01531',
    '01532',
    '01534',
    '01535',
    '01536',
    '01537',
    '01538',
    '01540',
    '01541',
    '01542',
    '01543',
    '01545',
    '01546',
    '01550',
    '01560',
    '01561',
    '01562',
    '01564',
    '01566',
    '01568',
    '01569',
    '01570',
    '01571',
    '01581',
    '01583',
    '01585',
    '01586',
    '01588',
    '01590',
    '01601',
    '01602',
    '01603',
    '01604',
    '01605',
    '01606',
    '01607',
    '01608',
    '01609',
    '01610',
    '01611',
    '01612',
    '01613',
    '01614',
    '01615',
    '01653',
    '01655',
    '01701',
    '01702',
    '01703',
    '01704',
    '01705',
    '01718',
    '01719',
    '01720',
    '01721',
    '01730',
    '01731',
    '01740',
    '01741',
    '01742',
    '01745',
    '01746',
    '01747',
    '01748',
    '01749',
    '01752',
    '01754',
    '01756',
    '01757',
    '01760',
    '01770',
    '01772',
    '01773',
    '01775',
    '01776',
    '01778',
    '01784',
    '01801',
    '01803',
    '01805',
    '01810',
    '01812',
    '01813',
    '01815',
    '01821',
    '01822',
    '01824',
    '01826',
    '01827',
    '01830',
    '01831',
    '01832',
    '01833',
    '01834',
    '01835',
    '01840',
    '01841',
    '01842',
    '01843',
    '01844',
    '01845',
    '01850',
    '01851',
    '01852',
    '01853',
    '01854',
    '01860',
    '01862',
    '01863',
    '01864',
    '01865',
    '01866',
    '01867',
    '01876',
    '01879',
    '01880',
    '01885',
    '01886',
    '01887',
    '01888',
    '01889',
    '01890',
    '01899',
    '01901',
    '01902',
    '01903',
    '01904',
    '01905',
    '01906',
    '01907',
    '01908',
    '01910',
    '01913',
    '01915',
    '01921',
    '01922',
    '01923',
    '01929',
    '01930',
    '01931',
    '01936',
    '01937',
    '01938',
    '01940',
    '01944',
    '01945',
    '01949',
    '01950',
    '01951',
    '01952',
    '01960',
    '01961',
    '01965',
    '01966',
    '01969',
    '01970',
    '01971',
    '01982',
    '01983',
    '01984',
    '01985',
    '02018',
    '02019',
    '02020',
    '02021',
    '02025',
    '02026',
    '02027',
    '02030',
    '02032',
    '02035',
    '02038',
    '02040',
    '02041',
    '02043',
    '02044',
    '02045',
    '02047',
    '02048',
    '02050',
    '02051',
    '02052',
    '02053',
    '02054',
    '02055',
    '02056',
    '02059',
    '02060',
    '02061',
    '02062',
    '02065',
    '02066',
    '02067',
    '02070',
    '02071',
    '02072',
    '02081',
    '02090',
    '02093',
    '02108',
    '02109',
    '02110',
    '02111',
    '02112',
    '02113',
    '02114',
    '02115',
    '02116',
    '02117',
    '02118',
    '02119',
    '02120',
    '02121',
    '02122',
    '02123',
    '02124',
    '02125',
    '02126',
    '02127',
    '02128',
    '02129',
    '02130',
    '02131',
    '02132',
    '02133',
    '02134',
    '02135',
    '02136',
    '02137',
    '02138',
    '02139',
    '02140',
    '02141',
    '02142',
    '02143',
    '02144',
    '02145',
    '02148',
    '02149',
    '02150',
    '02151',
    '02152',
    '02153',
    '02155',
    '02156',
    '02163',
    '02169',
    '02170',
    '02171',
    '02176',
    '02180',
    '02184',
    '02185',
    '02186',
    '02187',
    '02188',
    '02189',
    '02190',
    '02191',
    '02196',
    '02199',
    '02201',
    '02203',
    '02204',
    '02205',
    '02206',
    '02210',
    '02211',
    '02212',
    '02215',
    '02217',
    '02222',
    '02238',
    '02241',
    '02266',
    '02269',
    '02283',
    '02284',
    '02293',
    '02297',
    '02298',
    '02301',
    '02302',
    '02303',
    '02304',
    '02305',
    '02322',
    '02324',
    '02325',
    '02327',
    '02330',
    '02331',
    '02332',
    '02333',
    '02334',
    '02337',
    '02338',
    '02339',
    '02341',
    '02343',
    '02344',
    '02345',
    '02346',
    '02347',
    '02348',
    '02349',
    '02350',
    '02351',
    '02355',
    '02356',
    '02357',
    '02358',
    '02359',
    '02360',
    '02361',
    '02362',
    '02364',
    '02366',
    '02367',
    '02368',
    '02370',
    '02375',
    '02379',
    '02381',
    '02382',
    '02420',
    '02421',
    '02445',
    '02446',
    '02447',
    '02451',
    '02452',
    '02453',
    '02454',
    '02455',
    '02456',
    '02457',
    '02458',
    '02459',
    '02460',
    '02461',
    '02462',
    '02464',
    '02465',
    '02466',
    '02467',
    '02468',
    '02471',
    '02472',
    '02474',
    '02475',
    '02476',
    '02477',
    '02478',
    '02479',
    '02481',
    '02482',
    '02492',
    '02493',
    '02494',
    '02495',
    '02532',
    '02534',
    '02535',
    '02536',
    '02537',
    '02538',
    '02539',
    '02540',
    '02541',
    '02542',
    '02543',
    '02552',
    '02553',
    '02554',
    '02556',
    '02557',
    '02558',
    '02559',
    '02561',
    '02562',
    '02563',
    '02564',
    '02568',
    '02571',
    '02574',
    '02575',
    '02576',
    '02584',
    '02601',
    '02630',
    '02631',
    '02632',
    '02633',
    '02634',
    '02635',
    '02637',
    '02638',
    '02639',
    '02641',
    '02642',
    '02643',
    '02644',
    '02645',
    '02646',
    '02647',
    '02648',
    '02649',
    '02650',
    '02651',
    '02652',
    '02653',
    '02655',
    '02657',
    '02659',
    '02660',
    '02661',
    '02662',
    '02663',
    '02664',
    '02666',
    '02667',
    '02668',
    '02669',
    '02670',
    '02671',
    '02672',
    '02673',
    '02675',
    '02702',
    '02703',
    '02712',
    '02713',
    '02714',
    '02715',
    '02717',
    '02718',
    '02719',
    '02720',
    '02721',
    '02722',
    '02723',
    '02724',
    '02725',
    '02726',
    '02738',
    '02739',
    '02740',
    '02741',
    '02742',
    '02743',
    '02744',
    '02745',
    '02746',
    '02747',
    '02748',
    '02760',
    '02761',
    '02762',
    '02763',
    '02764',
    '02766',
    '02767',
    '02768',
    '02769',
    '02770',
    '02771',
    '02777',
    '02779',
    '02780',
    '02790',
    '02791',
    '05501',
  ],
  RI: [
    '02801',
    '02802',
    '02804',
    '02806',
    '02807',
    '02808',
    '02809',
    '02812',
    '02813',
    '02814',
    '02815',
    '02816',
    '02817',
    '02818',
    '02822',
    '02823',
    '02824',
    '02825',
    '02826',
    '02827',
    '02828',
    '02829',
    '02830',
    '02831',
    '02832',
    '02833',
    '02835',
    '02836',
    '02837',
    '02838',
    '02839',
    '02840',
    '02841',
    '02842',
    '02852',
    '02857',
    '02858',
    '02859',
    '02860',
    '02861',
    '02862',
    '02863',
    '02864',
    '02865',
    '02871',
    '02872',
    '02873',
    '02874',
    '02875',
    '02876',
    '02877',
    '02878',
    '02879',
    '02880',
    '02881',
    '02882',
    '02883',
    '02885',
    '02886',
    '02887',
    '02888',
    '02889',
    '02891',
    '02892',
    '02893',
    '02894',
    '02895',
    '02896',
    '02898',
    '02901',
    '02902',
    '02903',
    '02904',
    '02905',
    '02906',
    '02907',
    '02908',
    '02909',
    '02910',
    '02911',
    '02912',
    '02914',
    '02915',
    '02916',
    '02917',
    '02918',
    '02919',
    '02920',
    '02921',
    '02940',
  ],
  NH: [
    '03031',
    '03032',
    '03033',
    '03034',
    '03036',
    '03037',
    '03038',
    '03040',
    '03041',
    '03042',
    '03043',
    '03044',
    '03045',
    '03046',
    '03047',
    '03048',
    '03049',
    '03051',
    '03052',
    '03053',
    '03054',
    '03055',
    '03057',
    '03060',
    '03061',
    '03062',
    '03063',
    '03064',
    '03070',
    '03071',
    '03073',
    '03076',
    '03077',
    '03079',
    '03082',
    '03084',
    '03086',
    '03087',
    '03101',
    '03102',
    '03103',
    '03104',
    '03105',
    '03106',
    '03108',
    '03109',
    '03110',
    '03111',
    '03215',
    '03216',
    '03217',
    '03218',
    '03220',
    '03221',
    '03222',
    '03223',
    '03224',
    '03225',
    '03226',
    '03227',
    '03229',
    '03230',
    '03231',
    '03233',
    '03234',
    '03235',
    '03237',
    '03238',
    '03240',
    '03241',
    '03242',
    '03243',
    '03244',
    '03245',
    '03246',
    '03247',
    '03249',
    '03251',
    '03252',
    '03253',
    '03254',
    '03255',
    '03256',
    '03257',
    '03258',
    '03259',
    '03260',
    '03261',
    '03262',
    '03263',
    '03264',
    '03266',
    '03268',
    '03269',
    '03272',
    '03273',
    '03275',
    '03276',
    '03278',
    '03279',
    '03280',
    '03281',
    '03282',
    '03284',
    '03285',
    '03287',
    '03289',
    '03290',
    '03291',
    '03293',
    '03298',
    '03299',
    '03301',
    '03302',
    '03303',
    '03304',
    '03305',
    '03307',
    '03431',
    '03435',
    '03440',
    '03441',
    '03442',
    '03443',
    '03444',
    '03445',
    '03446',
    '03447',
    '03448',
    '03449',
    '03450',
    '03451',
    '03452',
    '03455',
    '03456',
    '03457',
    '03458',
    '03461',
    '03462',
    '03464',
    '03465',
    '03466',
    '03467',
    '03468',
    '03469',
    '03470',
    '03561',
    '03570',
    '03574',
    '03575',
    '03576',
    '03579',
    '03580',
    '03581',
    '03582',
    '03583',
    '03584',
    '03585',
    '03586',
    '03588',
    '03589',
    '03590',
    '03592',
    '03593',
    '03595',
    '03597',
    '03598',
    '03601',
    '03602',
    '03603',
    '03604',
    '03605',
    '03607',
    '03608',
    '03609',
    '03740',
    '03741',
    '03743',
    '03745',
    '03746',
    '03748',
    '03749',
    '03750',
    '03751',
    '03752',
    '03753',
    '03754',
    '03755',
    '03756',
    '03765',
    '03766',
    '03768',
    '03769',
    '03770',
    '03771',
    '03773',
    '03774',
    '03777',
    '03779',
    '03780',
    '03781',
    '03782',
    '03784',
    '03785',
    '03801',
    '03802',
    '03803',
    '03804',
    '03809',
    '03810',
    '03811',
    '03812',
    '03813',
    '03814',
    '03815',
    '03816',
    '03817',
    '03818',
    '03819',
    '03820',
    '03821',
    '03822',
    '03823',
    '03824',
    '03825',
    '03826',
    '03827',
    '03830',
    '03832',
    '03833',
    '03835',
    '03836',
    '03837',
    '03838',
    '03839',
    '03840',
    '03841',
    '03842',
    '03843',
    '03844',
    '03845',
    '03846',
    '03847',
    '03848',
    '03849',
    '03850',
    '03851',
    '03852',
    '03853',
    '03854',
    '03855',
    '03856',
    '03857',
    '03858',
    '03859',
    '03860',
    '03861',
    '03862',
    '03864',
    '03865',
    '03866',
    '03867',
    '03868',
    '03869',
    '03870',
    '03871',
    '03872',
    '03873',
    '03874',
    '03875',
    '03878',
    '03882',
    '03883',
    '03884',
    '03885',
    '03886',
    '03887',
    '03890',
    '03894',
    '03896',
    '03897',
  ],
  ME: [
    '03901',
    '03902',
    '03903',
    '03904',
    '03905',
    '03906',
    '03907',
    '03908',
    '03909',
    '03910',
    '03911',
    '04001',
    '04002',
    '04003',
    '04004',
    '04005',
    '04006',
    '04007',
    '04008',
    '04009',
    '04010',
    '04011',
    '04013',
    '04014',
    '04015',
    '04016',
    '04017',
    '04019',
    '04020',
    '04021',
    '04022',
    '04024',
    '04027',
    '04028',
    '04029',
    '04030',
    '04032',
    '04033',
    '04034',
    '04037',
    '04038',
    '04039',
    '04040',
    '04041',
    '04042',
    '04043',
    '04046',
    '04047',
    '04048',
    '04049',
    '04050',
    '04051',
    '04054',
    '04055',
    '04056',
    '04057',
    '04061',
    '04062',
    '04063',
    '04064',
    '04066',
    '04068',
    '04069',
    '04070',
    '04071',
    '04072',
    '04073',
    '04074',
    '04076',
    '04077',
    '04078',
    '04079',
    '04082',
    '04083',
    '04084',
    '04085',
    '04086',
    '04087',
    '04088',
    '04090',
    '04091',
    '04092',
    '04093',
    '04094',
    '04095',
    '04096',
    '04097',
    '04098',
    '04101',
    '04102',
    '04103',
    '04104',
    '04105',
    '04106',
    '04107',
    '04108',
    '04109',
    '04110',
    '04112',
    '04116',
    '04122',
    '04123',
    '04124',
    '04210',
    '04211',
    '04212',
    '04216',
    '04217',
    '04219',
    '04220',
    '04221',
    '04222',
    '04223',
    '04224',
    '04225',
    '04226',
    '04227',
    '04228',
    '04230',
    '04231',
    '04234',
    '04236',
    '04237',
    '04238',
    '04239',
    '04240',
    '04241',
    '04243',
    '04250',
    '04252',
    '04253',
    '04254',
    '04255',
    '04256',
    '04257',
    '04258',
    '04259',
    '04260',
    '04261',
    '04262',
    '04263',
    '04265',
    '04266',
    '04267',
    '04268',
    '04270',
    '04271',
    '04274',
    '04275',
    '04276',
    '04280',
    '04281',
    '04282',
    '04284',
    '04285',
    '04286',
    '04287',
    '04288',
    '04289',
    '04290',
    '04291',
    '04292',
    '04294',
    '04330',
    '04332',
    '04333',
    '04336',
    '04338',
    '04341',
    '04342',
    '04343',
    '04344',
    '04345',
    '04346',
    '04347',
    '04348',
    '04349',
    '04350',
    '04351',
    '04352',
    '04353',
    '04354',
    '04355',
    '04357',
    '04358',
    '04359',
    '04360',
    '04363',
    '04364',
    '04401',
    '04402',
    '04406',
    '04408',
    '04410',
    '04411',
    '04412',
    '04413',
    '04414',
    '04415',
    '04416',
    '04417',
    '04418',
    '04419',
    '04420',
    '04421',
    '04422',
    '04424',
    '04426',
    '04427',
    '04428',
    '04429',
    '04430',
    '04431',
    '04434',
    '04435',
    '04438',
    '04441',
    '04442',
    '04443',
    '04444',
    '04448',
    '04449',
    '04450',
    '04451',
    '04453',
    '04454',
    '04455',
    '04456',
    '04457',
    '04459',
    '04460',
    '04461',
    '04462',
    '04463',
    '04464',
    '04468',
    '04469',
    '04471',
    '04472',
    '04473',
    '04474',
    '04475',
    '04476',
    '04478',
    '04479',
    '04481',
    '04485',
    '04487',
    '04488',
    '04489',
    '04490',
    '04491',
    '04492',
    '04493',
    '04495',
    '04496',
    '04497',
    '04530',
    '04535',
    '04537',
    '04538',
    '04539',
    '04541',
    '04543',
    '04544',
    '04547',
    '04548',
    '04549',
    '04551',
    '04553',
    '04554',
    '04555',
    '04556',
    '04558',
    '04562',
    '04563',
    '04564',
    '04565',
    '04568',
    '04570',
    '04571',
    '04572',
    '04573',
    '04574',
    '04575',
    '04576',
    '04578',
    '04579',
    '04605',
    '04606',
    '04607',
    '04609',
    '04611',
    '04612',
    '04613',
    '04614',
    '04616',
    '04617',
    '04619',
    '04622',
    '04623',
    '04624',
    '04625',
    '04626',
    '04627',
    '04628',
    '04629',
    '04630',
    '04631',
    '04634',
    '04635',
    '04637',
    '04640',
    '04642',
    '04643',
    '04644',
    '04645',
    '04646',
    '04648',
    '04649',
    '04650',
    '04652',
    '04653',
    '04654',
    '04655',
    '04657',
    '04658',
    '04660',
    '04662',
    '04664',
    '04666',
    '04667',
    '04668',
    '04669',
    '04671',
    '04672',
    '04673',
    '04674',
    '04675',
    '04676',
    '04677',
    '04679',
    '04680',
    '04681',
    '04683',
    '04684',
    '04685',
    '04686',
    '04691',
    '04693',
    '04694',
    '04730',
    '04732',
    '04733',
    '04734',
    '04735',
    '04736',
    '04737',
    '04738',
    '04739',
    '04740',
    '04741',
    '04742',
    '04743',
    '04744',
    '04745',
    '04746',
    '04747',
    '04750',
    '04751',
    '04756',
    '04757',
    '04758',
    '04760',
    '04761',
    '04762',
    '04763',
    '04764',
    '04765',
    '04766',
    '04768',
    '04769',
    '04772',
    '04773',
    '04774',
    '04775',
    '04776',
    '04777',
    '04779',
    '04780',
    '04781',
    '04783',
    '04785',
    '04786',
    '04787',
    '04841',
    '04843',
    '04847',
    '04848',
    '04849',
    '04850',
    '04851',
    '04852',
    '04853',
    '04854',
    '04855',
    '04856',
    '04858',
    '04859',
    '04860',
    '04861',
    '04862',
    '04863',
    '04864',
    '04865',
    '04901',
    '04903',
    '04910',
    '04911',
    '04912',
    '04915',
    '04917',
    '04918',
    '04920',
    '04921',
    '04922',
    '04923',
    '04924',
    '04925',
    '04926',
    '04927',
    '04928',
    '04929',
    '04930',
    '04932',
    '04933',
    '04935',
    '04936',
    '04937',
    '04938',
    '04939',
    '04940',
    '04941',
    '04942',
    '04943',
    '04944',
    '04945',
    '04947',
    '04949',
    '04950',
    '04951',
    '04952',
    '04953',
    '04954',
    '04955',
    '04956',
    '04957',
    '04958',
    '04961',
    '04962',
    '04963',
    '04964',
    '04965',
    '04966',
    '04967',
    '04969',
    '04970',
    '04971',
    '04972',
    '04973',
    '04974',
    '04975',
    '04976',
    '04978',
    '04979',
    '04981',
    '04982',
    '04983',
    '04984',
    '04985',
    '04986',
    '04987',
    '04988',
    '04989',
    '04992',
  ],
  VT: [
    '05001',
    '05009',
    '05030',
    '05031',
    '05032',
    '05033',
    '05034',
    '05035',
    '05036',
    '05037',
    '05038',
    '05039',
    '05040',
    '05041',
    '05042',
    '05043',
    '05045',
    '05046',
    '05047',
    '05048',
    '05049',
    '05050',
    '05051',
    '05052',
    '05053',
    '05054',
    '05055',
    '05056',
    '05058',
    '05059',
    '05060',
    '05061',
    '05062',
    '05065',
    '05067',
    '05068',
    '05069',
    '05070',
    '05071',
    '05072',
    '05073',
    '05074',
    '05075',
    '05076',
    '05077',
    '05079',
    '05081',
    '05083',
    '05084',
    '05085',
    '05086',
    '05088',
    '05089',
    '05091',
    '05101',
    '05141',
    '05142',
    '05143',
    '05146',
    '05148',
    '05149',
    '05150',
    '05151',
    '05152',
    '05153',
    '05154',
    '05155',
    '05156',
    '05158',
    '05159',
    '05161',
    '05201',
    '05250',
    '05251',
    '05252',
    '05253',
    '05254',
    '05255',
    '05257',
    '05260',
    '05261',
    '05262',
    '05301',
    '05302',
    '05303',
    '05304',
    '05340',
    '05341',
    '05342',
    '05343',
    '05344',
    '05345',
    '05346',
    '05350',
    '05351',
    '05352',
    '05353',
    '05354',
    '05355',
    '05356',
    '05357',
    '05358',
    '05359',
    '05360',
    '05361',
    '05362',
    '05363',
    '05401',
    '05402',
    '05403',
    '05404',
    '05405',
    '05406',
    '05407',
    '05408',
    '05439',
    '05440',
    '05441',
    '05442',
    '05443',
    '05444',
    '05445',
    '05446',
    '05447',
    '05448',
    '05449',
    '05450',
    '05451',
    '05452',
    '05453',
    '05454',
    '05455',
    '05456',
    '05457',
    '05458',
    '05459',
    '05460',
    '05461',
    '05462',
    '05463',
    '05464',
    '05465',
    '05466',
    '05468',
    '05469',
    '05470',
    '05471',
    '05472',
    '05473',
    '05474',
    '05476',
    '05477',
    '05478',
    '05479',
    '05481',
    '05482',
    '05483',
    '05485',
    '05486',
    '05487',
    '05488',
    '05489',
    '05490',
    '05491',
    '05492',
    '05494',
    '05495',
    '05601',
    '05602',
    '05603',
    '05604',
    '05609',
    '05620',
    '05633',
    '05640',
    '05641',
    '05647',
    '05648',
    '05649',
    '05650',
    '05651',
    '05652',
    '05653',
    '05654',
    '05655',
    '05656',
    '05657',
    '05658',
    '05660',
    '05661',
    '05662',
    '05663',
    '05664',
    '05665',
    '05666',
    '05667',
    '05669',
    '05670',
    '05671',
    '05672',
    '05673',
    '05674',
    '05675',
    '05676',
    '05677',
    '05678',
    '05679',
    '05680',
    '05681',
    '05682',
    '05701',
    '05702',
    '05730',
    '05731',
    '05732',
    '05733',
    '05734',
    '05735',
    '05736',
    '05737',
    '05738',
    '05739',
    '05740',
    '05741',
    '05742',
    '05743',
    '05744',
    '05745',
    '05746',
    '05747',
    '05748',
    '05750',
    '05751',
    '05753',
    '05757',
    '05758',
    '05759',
    '05760',
    '05761',
    '05762',
    '05763',
    '05764',
    '05765',
    '05766',
    '05767',
    '05768',
    '05769',
    '05770',
    '05772',
    '05773',
    '05774',
    '05775',
    '05776',
    '05777',
    '05778',
    '05819',
    '05820',
    '05821',
    '05822',
    '05823',
    '05824',
    '05825',
    '05826',
    '05827',
    '05828',
    '05829',
    '05830',
    '05832',
    '05833',
    '05836',
    '05837',
    '05838',
    '05839',
    '05840',
    '05841',
    '05842',
    '05843',
    '05845',
    '05846',
    '05847',
    '05848',
    '05849',
    '05850',
    '05851',
    '05853',
    '05855',
    '05857',
    '05858',
    '05859',
    '05860',
    '05861',
    '05862',
    '05863',
    '05866',
    '05867',
    '05868',
    '05871',
    '05872',
    '05873',
    '05874',
    '05875',
    '05901',
    '05902',
    '05903',
    '05904',
    '05905',
    '05906',
    '05907',
  ],
  CT: [
    '06001',
    '06002',
    '06006',
    '06010',
    '06011',
    '06013',
    '06016',
    '06018',
    '06019',
    '06020',
    '06021',
    '06022',
    '06023',
    '06024',
    '06025',
    '06026',
    '06027',
    '06028',
    '06029',
    '06030',
    '06031',
    '06032',
    '06033',
    '06034',
    '06035',
    '06037',
    '06039',
    '06040',
    '06041',
    '06042',
    '06043',
    '06045',
    '06050',
    '06051',
    '06052',
    '06053',
    '06057',
    '06058',
    '06059',
    '06060',
    '06061',
    '06062',
    '06063',
    '06064',
    '06065',
    '06066',
    '06067',
    '06068',
    '06069',
    '06070',
    '06071',
    '06072',
    '06073',
    '06074',
    '06075',
    '06076',
    '06077',
    '06078',
    '06079',
    '06080',
    '06081',
    '06082',
    '06083',
    '06084',
    '06085',
    '06088',
    '06089',
    '06090',
    '06091',
    '06092',
    '06093',
    '06094',
    '06095',
    '06096',
    '06098',
    '06101',
    '06102',
    '06103',
    '06104',
    '06105',
    '06106',
    '06107',
    '06108',
    '06109',
    '06110',
    '06111',
    '06112',
    '06114',
    '06115',
    '06117',
    '06118',
    '06119',
    '06120',
    '06123',
    '06126',
    '06127',
    '06128',
    '06129',
    '06131',
    '06132',
    '06133',
    '06134',
    '06137',
    '06138',
    '06140',
    '06141',
    '06142',
    '06143',
    '06144',
    '06145',
    '06146',
    '06147',
    '06150',
    '06151',
    '06152',
    '06153',
    '06154',
    '06155',
    '06156',
    '06160',
    '06161',
    '06167',
    '06176',
    '06180',
    '06183',
    '06199',
    '06226',
    '06230',
    '06231',
    '06232',
    '06233',
    '06234',
    '06235',
    '06237',
    '06238',
    '06239',
    '06241',
    '06242',
    '06243',
    '06244',
    '06245',
    '06246',
    '06247',
    '06248',
    '06249',
    '06250',
    '06251',
    '06254',
    '06255',
    '06256',
    '06258',
    '06259',
    '06260',
    '06262',
    '06263',
    '06264',
    '06265',
    '06266',
    '06267',
    '06268',
    '06269',
    '06277',
    '06278',
    '06279',
    '06280',
    '06281',
    '06282',
    '06320',
    '06330',
    '06331',
    '06332',
    '06333',
    '06334',
    '06335',
    '06336',
    '06338',
    '06339',
    '06340',
    '06349',
    '06350',
    '06351',
    '06353',
    '06354',
    '06355',
    '06357',
    '06359',
    '06360',
    '06365',
    '06370',
    '06371',
    '06372',
    '06373',
    '06374',
    '06375',
    '06376',
    '06377',
    '06378',
    '06379',
    '06380',
    '06382',
    '06383',
    '06384',
    '06385',
    '06387',
    '06388',
    '06389',
    '06401',
    '06403',
    '06404',
    '06405',
    '06408',
    '06409',
    '06410',
    '06411',
    '06412',
    '06413',
    '06414',
    '06415',
    '06416',
    '06417',
    '06418',
    '06419',
    '06420',
    '06422',
    '06423',
    '06424',
    '06426',
    '06437',
    '06438',
    '06439',
    '06440',
    '06441',
    '06442',
    '06443',
    '06444',
    '06447',
    '06450',
    '06451',
    '06455',
    '06456',
    '06457',
    '06459',
    '06460',
    '06461',
    '06467',
    '06468',
    '06469',
    '06470',
    '06471',
    '06472',
    '06473',
    '06474',
    '06475',
    '06477',
    '06478',
    '06479',
    '06480',
    '06481',
    '06482',
    '06483',
    '06484',
    '06487',
    '06488',
    '06489',
    '06491',
    '06492',
    '06493',
    '06494',
    '06495',
    '06498',
    '06501',
    '06502',
    '06503',
    '06504',
    '06505',
    '06506',
    '06507',
    '06508',
    '06509',
    '06510',
    '06511',
    '06512',
    '06513',
    '06514',
    '06515',
    '06516',
    '06517',
    '06518',
    '06519',
    '06520',
    '06521',
    '06524',
    '06525',
    '06530',
    '06531',
    '06532',
    '06533',
    '06534',
    '06535',
    '06536',
    '06537',
    '06538',
    '06540',
    '06601',
    '06602',
    '06604',
    '06605',
    '06606',
    '06607',
    '06608',
    '06610',
    '06611',
    '06612',
    '06614',
    '06615',
    '06673',
    '06699',
    '06701',
    '06702',
    '06703',
    '06704',
    '06705',
    '06706',
    '06708',
    '06710',
    '06712',
    '06716',
    '06720',
    '06721',
    '06722',
    '06723',
    '06724',
    '06725',
    '06726',
    '06749',
    '06750',
    '06751',
    '06752',
    '06753',
    '06754',
    '06755',
    '06756',
    '06757',
    '06758',
    '06759',
    '06762',
    '06763',
    '06770',
    '06776',
    '06777',
    '06778',
    '06779',
    '06781',
    '06782',
    '06783',
    '06784',
    '06785',
    '06786',
    '06787',
    '06790',
    '06791',
    '06792',
    '06793',
    '06794',
    '06795',
    '06796',
    '06798',
    '06801',
    '06804',
    '06807',
    '06810',
    '06811',
    '06812',
    '06813',
    '06814',
    '06816',
    '06817',
    '06820',
    '06824',
    '06825',
    '06828',
    '06829',
    '06830',
    '06831',
    '06836',
    '06838',
    '06840',
    '06850',
    '06851',
    '06852',
    '06853',
    '06854',
    '06855',
    '06856',
    '06857',
    '06858',
    '06860',
    '06870',
    '06875',
    '06876',
    '06877',
    '06878',
    '06879',
    '06880',
    '06881',
    '06883',
    '06888',
    '06889',
    '06890',
    '06896',
    '06897',
    '06901',
    '06902',
    '06903',
    '06904',
    '06905',
    '06906',
    '06907',
    '06910',
    '06911',
    '06912',
    '06913',
    '06914',
    '06926',
    '06927',
  ],
  NJ: [
    '07001',
    '07002',
    '07003',
    '07004',
    '07005',
    '07006',
    '07007',
    '07008',
    '07009',
    '07010',
    '07011',
    '07012',
    '07013',
    '07014',
    '07015',
    '07016',
    '07017',
    '07018',
    '07019',
    '07020',
    '07021',
    '07022',
    '07023',
    '07024',
    '07026',
    '07027',
    '07028',
    '07029',
    '07030',
    '07031',
    '07032',
    '07033',
    '07034',
    '07035',
    '07036',
    '07039',
    '07040',
    '07041',
    '07042',
    '07043',
    '07044',
    '07045',
    '07046',
    '07047',
    '07050',
    '07051',
    '07052',
    '07054',
    '07055',
    '07057',
    '07058',
    '07059',
    '07060',
    '07061',
    '07062',
    '07063',
    '07064',
    '07065',
    '07066',
    '07067',
    '07068',
    '07069',
    '07070',
    '07071',
    '07072',
    '07073',
    '07074',
    '07075',
    '07076',
    '07077',
    '07078',
    '07079',
    '07080',
    '07081',
    '07082',
    '07083',
    '07086',
    '07087',
    '07088',
    '07090',
    '07091',
    '07092',
    '07093',
    '07094',
    '07095',
    '07096',
    '07097',
    '07099',
    '07101',
    '07102',
    '07103',
    '07104',
    '07105',
    '07106',
    '07107',
    '07108',
    '07109',
    '07110',
    '07111',
    '07112',
    '07114',
    '07175',
    '07184',
    '07188',
    '07189',
    '07191',
    '07192',
    '07193',
    '07195',
    '07198',
    '07199',
    '07201',
    '07202',
    '07203',
    '07204',
    '07205',
    '07206',
    '07207',
    '07208',
    '07302',
    '07303',
    '07304',
    '07305',
    '07306',
    '07307',
    '07308',
    '07310',
    '07311',
    '07395',
    '07399',
    '07401',
    '07403',
    '07405',
    '07407',
    '07410',
    '07416',
    '07417',
    '07418',
    '07419',
    '07420',
    '07421',
    '07422',
    '07423',
    '07424',
    '07428',
    '07430',
    '07432',
    '07435',
    '07436',
    '07438',
    '07439',
    '07440',
    '07442',
    '07444',
    '07446',
    '07450',
    '07451',
    '07452',
    '07456',
    '07457',
    '07458',
    '07460',
    '07461',
    '07462',
    '07463',
    '07465',
    '07470',
    '07474',
    '07480',
    '07481',
    '07495',
    '07501',
    '07502',
    '07503',
    '07504',
    '07505',
    '07506',
    '07507',
    '07508',
    '07509',
    '07510',
    '07511',
    '07512',
    '07513',
    '07514',
    '07522',
    '07524',
    '07533',
    '07538',
    '07543',
    '07544',
    '07601',
    '07602',
    '07603',
    '07604',
    '07605',
    '07606',
    '07607',
    '07608',
    '07620',
    '07621',
    '07624',
    '07626',
    '07627',
    '07628',
    '07630',
    '07631',
    '07632',
    '07640',
    '07641',
    '07642',
    '07643',
    '07644',
    '07645',
    '07646',
    '07647',
    '07648',
    '07649',
    '07650',
    '07652',
    '07653',
    '07656',
    '07657',
    '07660',
    '07661',
    '07662',
    '07663',
    '07666',
    '07670',
    '07675',
    '07676',
    '07677',
    '07699',
    '07701',
    '07702',
    '07703',
    '07704',
    '07710',
    '07711',
    '07712',
    '07715',
    '07716',
    '07717',
    '07718',
    '07719',
    '07720',
    '07721',
    '07722',
    '07723',
    '07724',
    '07726',
    '07727',
    '07728',
    '07730',
    '07731',
    '07732',
    '07733',
    '07734',
    '07735',
    '07737',
    '07738',
    '07739',
    '07740',
    '07746',
    '07747',
    '07748',
    '07750',
    '07751',
    '07752',
    '07753',
    '07754',
    '07755',
    '07756',
    '07757',
    '07758',
    '07760',
    '07762',
    '07763',
    '07764',
    '07765',
    '07799',
    '07801',
    '07802',
    '07803',
    '07806',
    '07820',
    '07821',
    '07822',
    '07823',
    '07825',
    '07826',
    '07827',
    '07828',
    '07829',
    '07830',
    '07831',
    '07832',
    '07833',
    '07834',
    '07836',
    '07837',
    '07838',
    '07839',
    '07840',
    '07842',
    '07843',
    '07844',
    '07845',
    '07846',
    '07847',
    '07848',
    '07849',
    '07850',
    '07851',
    '07852',
    '07853',
    '07855',
    '07856',
    '07857',
    '07860',
    '07863',
    '07865',
    '07866',
    '07869',
    '07870',
    '07871',
    '07874',
    '07875',
    '07876',
    '07877',
    '07878',
    '07879',
    '07880',
    '07881',
    '07882',
    '07885',
    '07890',
    '07901',
    '07902',
    '07920',
    '07921',
    '07922',
    '07924',
    '07926',
    '07927',
    '07928',
    '07930',
    '07931',
    '07932',
    '07933',
    '07934',
    '07935',
    '07936',
    '07938',
    '07939',
    '07940',
    '07945',
    '07946',
    '07950',
    '07960',
    '07961',
    '07962',
    '07963',
    '07970',
    '07974',
    '07976',
    '07977',
    '07978',
    '07979',
    '07980',
    '07981',
    '07999',
    '08001',
    '08002',
    '08003',
    '08004',
    '08005',
    '08006',
    '08007',
    '08008',
    '08009',
    '08010',
    '08011',
    '08012',
    '08014',
    '08015',
    '08016',
    '08018',
    '08019',
    '08020',
    '08021',
    '08022',
    '08023',
    '08025',
    '08026',
    '08027',
    '08028',
    '08029',
    '08030',
    '08031',
    '08032',
    '08033',
    '08034',
    '08035',
    '08036',
    '08037',
    '08038',
    '08039',
    '08041',
    '08042',
    '08043',
    '08045',
    '08046',
    '08048',
    '08049',
    '08050',
    '08051',
    '08052',
    '08053',
    '08054',
    '08055',
    '08056',
    '08057',
    '08059',
    '08060',
    '08061',
    '08062',
    '08063',
    '08064',
    '08065',
    '08066',
    '08067',
    '08068',
    '08069',
    '08070',
    '08071',
    '08072',
    '08073',
    '08074',
    '08075',
    '08076',
    '08077',
    '08078',
    '08079',
    '08080',
    '08081',
    '08083',
    '08084',
    '08085',
    '08086',
    '08087',
    '08088',
    '08089',
    '08090',
    '08091',
    '08092',
    '08093',
    '08094',
    '08095',
    '08096',
    '08097',
    '08098',
    '08099',
    '08101',
    '08102',
    '08103',
    '08104',
    '08105',
    '08106',
    '08107',
    '08108',
    '08109',
    '08110',
    '08201',
    '08202',
    '08203',
    '08204',
    '08205',
    '08210',
    '08212',
    '08213',
    '08214',
    '08215',
    '08217',
    '08218',
    '08219',
    '08220',
    '08221',
    '08223',
    '08224',
    '08225',
    '08226',
    '08230',
    '08231',
    '08232',
    '08234',
    '08240',
    '08241',
    '08242',
    '08243',
    '08244',
    '08245',
    '08246',
    '08247',
    '08248',
    '08250',
    '08251',
    '08252',
    '08260',
    '08270',
    '08302',
    '08310',
    '08311',
    '08312',
    '08313',
    '08314',
    '08315',
    '08316',
    '08317',
    '08318',
    '08319',
    '08320',
    '08321',
    '08322',
    '08323',
    '08324',
    '08326',
    '08327',
    '08328',
    '08329',
    '08330',
    '08332',
    '08340',
    '08341',
    '08342',
    '08343',
    '08344',
    '08345',
    '08346',
    '08347',
    '08348',
    '08349',
    '08350',
    '08352',
    '08353',
    '08360',
    '08361',
    '08362',
    '08401',
    '08402',
    '08403',
    '08404',
    '08405',
    '08406',
    '08501',
    '08502',
    '08504',
    '08505',
    '08510',
    '08511',
    '08512',
    '08514',
    '08515',
    '08518',
    '08520',
    '08525',
    '08526',
    '08527',
    '08528',
    '08530',
    '08533',
    '08534',
    '08535',
    '08536',
    '08540',
    '08541',
    '08542',
    '08543',
    '08544',
    '08550',
    '08551',
    '08553',
    '08554',
    '08555',
    '08556',
    '08557',
    '08558',
    '08559',
    '08560',
    '08561',
    '08562',
    '08601',
    '08602',
    '08603',
    '08604',
    '08605',
    '08606',
    '08607',
    '08608',
    '08609',
    '08610',
    '08611',
    '08618',
    '08619',
    '08620',
    '08625',
    '08628',
    '08629',
    '08638',
    '08640',
    '08641',
    '08645',
    '08646',
    '08647',
    '08648',
    '08650',
    '08666',
    '08690',
    '08691',
    '08695',
    '08701',
    '08720',
    '08721',
    '08722',
    '08723',
    '08724',
    '08730',
    '08731',
    '08732',
    '08733',
    '08734',
    '08735',
    '08736',
    '08738',
    '08739',
    '08740',
    '08741',
    '08742',
    '08750',
    '08751',
    '08752',
    '08753',
    '08754',
    '08755',
    '08756',
    '08757',
    '08758',
    '08759',
    '08801',
    '08802',
    '08803',
    '08804',
    '08805',
    '08807',
    '08808',
    '08809',
    '08810',
    '08812',
    '08816',
    '08817',
    '08818',
    '08820',
    '08821',
    '08822',
    '08823',
    '08824',
    '08825',
    '08826',
    '08827',
    '08828',
    '08829',
    '08830',
    '08831',
    '08832',
    '08833',
    '08834',
    '08835',
    '08836',
    '08837',
    '08840',
    '08844',
    '08846',
    '08848',
    '08850',
    '08852',
    '08853',
    '08854',
    '08855',
    '08857',
    '08858',
    '08859',
    '08861',
    '08862',
    '08863',
    '08865',
    '08867',
    '08868',
    '08869',
    '08870',
    '08871',
    '08872',
    '08873',
    '08875',
    '08876',
    '08879',
    '08880',
    '08882',
    '08884',
    '08885',
    '08886',
    '08887',
    '08888',
    '08889',
    '08890',
    '08899',
    '08901',
    '08902',
    '08903',
    '08904',
    '08906',
    '08933',
    '08989',
  ],
};

export type USCode = {
  zip: string;
  latitude: string;
  longitude: string;
  city: string;
  state: string;
  timeZoneId: string;
};

export function lookup_us_codes(zip: string): USCode {
  return codes[zip];
}
