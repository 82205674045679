import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {SchedulerComponent} from "./scheduler/scheduler.component";
import {ErrorPageComponent} from "./error-page/error-page.component";

const routes: Routes = [
  {
    path: 'scheduler',
    component: SchedulerComponent
  },
  {
    path: 'scheduler/:globalWidgetKey',
    component: SchedulerComponent
  },
  {
    path: 'scheduler/:globalWidgetKey/:localWidgetKey',
    component: SchedulerComponent
  },
  {
    path: 'error',
    component: ErrorPageComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SchedulerRoutingModule {
}
