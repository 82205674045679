import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {Widget} from "../../core/domain/widget";
import {ROLES} from "../domain/scheduler-lookup";
import {UserService} from "../../core/services/user.service";
import { parsePhoneNumber } from 'awesome-phonenumber'

@Component({
  selector: 'app-page-three',
  templateUrl: './page-three.component.html',
  styleUrls: ['./page-three.component.scss']
})
export class PageThreeComponent implements OnInit {

  @Input()
  formGroup: FormGroup;
  @Input()
  auxiliaryFormGroup: FormGroup;

  contactPhone: any;
  roles: string[] = ROLES;
  chosenRole = 'Client';
  phoneNumberError = false;
  widget: Widget;
  addInspectorNote: boolean = false;
  addBuyerAgent: boolean = false;
  addListingAgent: boolean = false;

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {

    this.auxiliaryFormGroup.controls['contactType'].setValue(this.chosenRole);

    this.userService.getWidgetSubject().subscribe(widget => {
      this.widget = widget;
    });

  }

  get isValid(): boolean {

    return this.formGroup.valid && this.formGroup.touched;

  }

  changeRole(changeRoleEvent: any): void {

    this.chosenRole = changeRoleEvent.value;
    this.auxiliaryFormGroup.controls['contactType'].setValue(this.chosenRole);

  }

  checkPhone(type, controlName) {

    const cntl = this.formGroup.controls[controlName];

    if (type === 'typing') {
      if (cntl.value.length > 5) {
        const pn = parsePhoneNumber('+1' + cntl.value.toString(), {
          regionCode: 'USA'
        });
        if (pn.valid) {
          cntl.setErrors(null);
        } else {
          cntl.setErrors({ invalidNumber: true });
        }
      } else {
        cntl.setErrors({ invalidNumber: true });
      }
    } else {
      if (cntl.value.length > 5) {
        const pn = parsePhoneNumber('+1' + cntl.value.toString(), {
          regionCode: 'USA'
        });
        if (pn.valid) {
          cntl.setErrors(null);
        } else {
          cntl.setErrors({ invalidNumber: true });
        }
      } else {
        cntl.setErrors({ invalidNumber: true });
      }
    }
  }

  emailIsInvalid(emailType?: string): boolean {

    if (!emailType) emailType = 'contactEmail';

    return this.formGroup.controls[emailType].hasError('email') && !this.fieldIsRequired(emailType);

  }

  fieldIsRequired(fieldName: string): boolean {
    return this.formGroup.controls[fieldName].hasError('required');
  }

  toggleBuyerAgent(event) {

    this.addBuyerAgent = event.checked;

  }

  toggleInspectorNote(event) {

    this.addInspectorNote = event.checked;

  }

  toggleListingAgent(event) {

    this.addListingAgent = event.checked;

  }


}
