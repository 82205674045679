<form [formGroup]="formGroup" *ngIf="widget">

  <div class="d-flex justify-content-start align-items-center mb-2">
    <mat-icon class="mr-3 header-icon">account_circle</mat-icon>
    <div class="sub-heading mt-2">
      <h5>Client Information&nbsp;<small class="text-muted">(required)</small></h5>
    </div>
  </div>

  <div class="field-group">
    <mat-form-field class="col-12 col-sm-5">
      <input matInput placeholder="Your Name" name="contactName" formControlName="contactName" required>

      <mat-error *ngIf="fieldIsRequired('contactName')">
        Full name is <strong>required</strong>
      </mat-error>
    </mat-form-field>
  </div>

  <div class="field-group">
    <mat-form-field class="col-12 col-sm-5">
      <input matInput placeholder="Email" name="contactEmail" formControlName="contactEmail" required>

      <mat-error *ngIf="emailIsInvalid()">
        Please enter a valid email address
      </mat-error>

      <mat-error *ngIf="fieldIsRequired('contactEmail')">
        Full name is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-12 col-sm-5">
      <input matInput placeholder="Phone" name="contactPhone" formControlName="contactPhone" required
             (change)="checkPhone('leave','contactPhone')" (input)="checkPhone('typing','contactPhone')"
             mask="(000) 000-0000">

      <mat-error *ngIf="fieldIsRequired('contactPhone')">
        Phone number is <strong>required</strong>
      </mat-error>

      <mat-error *ngIf="!formGroup.controls.contactPhone.valid && formGroup.controls.contactPhone.touched">
        Phone number is not valid
      </mat-error>

    </mat-form-field>

    <mat-checkbox class="reverse-checkbox col-12 col-sm-2" formControlName="clientSmsConsent" name="clientSmsConsent">
      Please Send Me SMS Updates
    </mat-checkbox>
  </div>

  <mat-checkbox class="reverse-checkbox ml-3 mt-2 d-flex" [checked]="addInspectorNote"
                (change)="toggleInspectorNote($event)">
    Add a Note for the Inspector
  </mat-checkbox>

  <div class="field-group" *ngIf="addInspectorNote">

    <mat-form-field class="col-12">
      <textarea matInput placeholder="Inspector Note" name="clientNotes" formControlName="clientNotes"></textarea>
    </mat-form-field>

  </div>

  <mat-checkbox class="reverse-checkbox ml-3 mt-1 d-flex" [checked]="addBuyerAgent" (change)="toggleBuyerAgent($event)">
    Add
    Buyer Agent Information
  </mat-checkbox>

  <ng-container *ngIf="addBuyerAgent">
    <!-- <div class="d-flex justify-content-start align-items-center mt-3 mb-2">
      <mat-icon class="mr-3 header-icon">perm_contact_calendar</mat-icon>
      <div class="sub-heading mt-2">
        <h5>Buyer Agent Information&nbsp;<small class="text-muted">(optional)</small></h5>
      </div>
    </div> -->

    <div class="field-group">

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="Buyer Agent Name" name="buyerAgentName" formControlName="buyerAgentName">
      </mat-form-field>

    </div>
    <div class="field-group">

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="Buyer Agent Email" name="buyerAgentEmail" formControlName="buyerAgentEmail">

        <mat-error *ngIf="emailIsInvalid('buyerAgentEmail')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="Buyer Agent Phone" name="buyerAgentPhone" formControlName="buyerAgentPhone"
               (change)="checkPhone('leave','buyerAgentPhone')" (input)="checkPhone('typing','buyerAgentPhone')"
               mask="(000) 000-0000">

        <mat-error *ngIf="!formGroup.controls.buyerAgentPhone.valid && formGroup.controls.buyerAgentPhone.touched">
          Phone number is not valid
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <mat-checkbox class="reverse-checkbox ml-3 mt-1 d-flex" [checked]="addListingAgent"
                (change)="toggleListingAgent($event)">Add
    Listing Agent Information
  </mat-checkbox>

  <ng-container *ngIf="addListingAgent">
    <!-- <div class="d-flex justify-content-start align-items-center mt-3 mb-2">
      <mat-icon class="mr-3 header-icon">perm_contact_calendar</mat-icon>
      <div class="sub-heading mt-2">
        <h5>Listing Agent Information&nbsp;<small class="text-muted">(optional)</small></h5>
      </div>
    </div> -->

    <div class="field-group">

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="Listing Agent Name" name="listingAgentName" formControlName="listingAgentName">
      </mat-form-field>

    </div>
    <div class="field-group">

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="Listing Agent Email" name="listingAgentEmail" formControlName="listingAgentEmail">

        <mat-error *ngIf="emailIsInvalid('listingAgentEmail')">
          Please enter a valid email address
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-12 col-sm-6">
        <input matInput placeholder="Listing Agent Phone" name="listingAgentPhone" formControlName="listingAgentPhone"
               (change)="checkPhone('leave','listingAgentPhone')" (input)="checkPhone('typing','listingAgentPhone')"
               mask="(000) 000-0000">

        <mat-error *ngIf="!formGroup.controls.listingAgentPhone.valid && formGroup.controls.listingAgentPhone.touched">
          Phone number is not valid
        </mat-error>
      </mat-form-field>

    </div>
  </ng-container>

</form>
