<form [formGroup]="formGroup" *ngIf="formGroup">

  <div class="d-flex justify-content-start align-items-center mb-2">
    <mat-icon class="mr-3 header-icon">access_time</mat-icon>
    <div class="sub-heading mt-2">
      <h5>Please select an Appointment Time</h5>
    </div>
  </div>

  <div class="field-group row ml-xs-0 ml-sm-2 ml-md-4">

    <ng-container *ngIf="!fieldIsRequired('appointmentDateTime')">
      <mat-form-field style="min-width: 250px; max-width:360px" class="col-xs-12 col-sm-6 mr-3">
        <input matInput placeholder="Your Appointment Time" [readonly]="true" [min]="today"
               [ngModel]="(appointmentDate | date: 'longDate') + ' at ' + (appointmentDate | date: 'shortTime')"
               [ngModelOptions]="{standalone: true}"/>
      </mat-form-field>
    </ng-container>

    <div class="col-auto mb-1">
      <button mat-raised-button color="primary" (click)="openDatePicker()"
              [ngClass]="shakeButton && pageTwoLoaded ? 'shakeit' : ''">
        <mat-icon class="mr-1">today</mat-icon>
        <span *ngIf="fieldIsRequired('appointmentDateTime')">Pick a
          Time</span><span *ngIf="!fieldIsRequired('appointmentDateTime')">Change Time</span>
      </button>
    </div>

    <mat-error class="p-2" *ngIf="appointmentDate==null && dateTouched">
      Appointment Time is <strong>required</strong>
    </mat-error>

  </div>

  <ng-container *ngIf="widget?.propertyType">

    <div class="d-flex justify-content-start align-items-center mt-3 mb-1">
      <mat-icon class="mr-3 header-icon">home_work</mat-icon>
      <div class="sub-heading mt-2">
        <h5>{{ widget.propertyTypeHeading }}&nbsp;<small class="text-muted">(required)</small></h5>
      </div>
    </div>

    <div class="field-group ml-xs-0 ml-sm-2 ml-md-4">
      <mat-radio-group class="example-radio-group" #propertyType name="propertyTypeName"
                       formControlName="propertyTypeName" (change)="typeChange($event)" [required]="true">
        <mat-radio-button class="example-radio-button" *ngFor="let type of widget.propertyType" [value]="type.name">
          {{type.name}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

  </ng-container>

  <ng-container *ngIf="widget?.propertySize">

    <div class="d-flex justify-content-start align-items-center mt-3 mb-1">
      <mat-icon class="mr-3 header-icon">square_foot</mat-icon>
      <div class="sub-heading mt-2">
        <h5>{{ widget.propertySizeHeading }}&nbsp;<small class="text-muted">(required)</small></h5>
      </div>
    </div>

    <div class="field-group ml-xs-0 ml-sm-2 ml-md-4" *ngIf="widget.propertySizePricingMethod === 'tier'">
      <mat-radio-group class="example-radio-group" #propertySize name="propertySizeName"
                       formControlName="propertySizeName" (change)="sizeChange($event)" [required]="true">
        <mat-radio-button class="example-radio-button" *ngFor="let size of widget.propertySize" [value]="size.name">
          {{size.name}}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="field-group ml-xs-0 ml-sm-2 ml-md-4" *ngIf="widget.propertySizePricingMethod === 'area'">
      <mat-form-field class="col-md-3 col-sm-6 col-7 pt-2 pt-sm-0">

        <input matInput placeholder="Building Size (sq. feet)" type="number" inputmode="numeric" name="propertySizeArea"
               formControlName="propertySizeArea" required="true" (change)="sizeAreaChange($event)">

      </mat-form-field>

      <div *ngIf="zillowUrl" class="col-md-9 col-sm-6 col-5 float-right pt-2 pt-sm-0 px-0">

        <a href="http://www.zillow.com/" target="_blank">
          <img class="img-fluid" src="https://www.zillowstatic.com/vstatic/80d5e73/static/logos/Zillowlogo_200x50.gif"
               alt="Zillow Real Estate Search"/>
        </a>

      </div>

    </div>

  </ng-container>

  <ng-container *ngIf="widget?.addInfo && widget.addInfo.length > 0">

    <div class="d-flex justify-content-start align-items-center mt-3 mb-1">
      <mat-icon class="mr-3 header-icon">playlist_add</mat-icon>
      <div class="sub-heading mt-2">
        <h5>{{ widget.addInfoHeading }}</h5>
      </div>
    </div>

    <div class="field-group reverse-checkbox ml-xs-0 ml-sm-2 ml-md-4">
      <mat-selection-list #additionalInformationControl class="p-0"
                          (selectionChange)="onAdditionalFieldChange(additionalInformationControl.selectedOptions.selected, 'addInfo', 'addInfoPrice', 'addInfoTime')">
        <mat-list-option togglePosition="before"  *ngFor="let info of widget.addInfo">
          {{info.name}}
        </mat-list-option>
      </mat-selection-list>
    </div>

  </ng-container>

  <ng-container *ngIf="showPrice">
    <div class="row mt-2">
      <div class="border bg-light col py-1 px-3 d-flex justify-content-between align-items-center">
        <mat-icon class="text-muted">account_balance_wallet</mat-icon>
        <div class="d-flex flex-column align-items-end">
          <ng-container *ngIf="auxiliaryFormGroup.controls.travelSurcharge.value >= 0">
            <div class="d-flex justify-content-end mb-1">
              <div class="mr-3"><span class="text-muted">Travel Surcharge $</span></div>
              <div class="text-right" style="width: 65px;">
                {{ auxiliaryFormGroup.controls.travelSurcharge.value | number : '1.2-2' }}</div>
            </div>
          </ng-container>
          <div class="d-flex justify-content-end align-items-center">
            <div class="mr-3"><span class="text-muted">ESTIMATE TOTAL $</span></div>
            <div class="text-right" style="width: 65px;">
              {{ auxiliaryFormGroup.controls.totalPrice.value | number : '1.2-2' }}</div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="auxiliaryFormGroup.controls.totalPrice.value < 0" class="row justify-content-center mt-3">
      <mat-error>
        Sorry! The ESTIMATE TOTAL $ should be greater than 0.
      </mat-error>
    </div>
  </ng-container>


</form>
