import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SchedulerComponent} from './scheduler/scheduler.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {PageOneComponent} from './page-one/page-one.component';
import {PageTwoComponent} from './page-two/page-two.component';
import {PageThreeComponent} from './page-three/page-three.component';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatStepperModule} from "@angular/material/stepper";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {MatRadioModule} from "@angular/material/radio";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatListModule} from "@angular/material/list";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SchedulerRoutingModule} from "./scheduler-routing.module";
import {MatCardModule} from "@angular/material/card";
import {MaterialDateTimeModule} from "../material-date-time/material-date-time.module";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import {SummaryPageComponent} from './summary-page/summary-page.component';
import {AngularFireModule} from "@angular/fire/compat";
import {environment} from "../../environments/environment";
import {ModalModule} from "ngx-bootstrap/modal";
import { HttpClientModule } from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap/alert';

@NgModule({
  declarations: [
    SchedulerComponent,
    ErrorPageComponent,
    PageOneComponent,
    PageTwoComponent,
    PageThreeComponent,
    SummaryPageComponent
  ],
  imports: [
    SchedulerRoutingModule,
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AlertModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatStepperModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatListModule,
    MatTooltipModule,
    MaterialDateTimeModule,
    MatCardModule,
    ModalModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    NgxMaskDirective
  ],
  providers: [
    provideNgxMask()
  ]
})
export class SchedulerModule {
}
